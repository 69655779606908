
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from "axios";
import { GLOBAL } from '_Globals';

const api = axios.create({
    headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

export function loadTrainingMetricsByObjectiveNameAndOrganizationId(organizationId = "1", objectiveName = "sx_agentnameaws", version = "V4") {
    return (dispatch) => {
        dispatch({ type: 'LOAD_AISM_LOADER_IN_PROGRESS' });
        api.get(GLOBAL.loadAismTrainingMetrics + `/${organizationId}/${objectiveName}/${version}`)
            .then((response) => {
                if (!response) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => {
                if (response.status == 204) {
                    dispatch(loadAismTrainingMetricsDataSuccess([]));
                } else {
                    dispatch(loadAismTrainingMetricsDataSuccess(response.data));
                }
                dispatch({ type: 'LOAD_AISM_LOADER_FINISHED' });
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: 'LOAD_AISM_LOADER_FINISHED' });
            });
    };
}

export function loadAismTrainingMetricsDataSuccess(payload) {
    return {
        type: 'LOAD_AISM_TRAINING_METRICS_DATA_SUCCESS',
        payload
    };
}

export function emptyAismTrainingMetricsData() {
    return (dispatch) => {
        dispatch({ type: 'EMPTY_AISM_TRAINING_METRICS_DATA_SUCCESS' });
    };
}
