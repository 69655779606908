/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import AiIcon from "../../aiIcon";
import { Form, Button } from "react-bootstrap";
import { ImSpinner6 } from "react-icons/im";
import { RiSearch2Line } from "react-icons/ri";
import { useSelector } from "react-redux";

const NlpSearchBarComponent = ({handleOnUserQueryChange, retriveQuerybasedOnUserQuery, maxLength}) => {
  const translator = useSelector(state => state.spcmReducer.tr);
  const showAiSearchLoader = useSelector(state => state.showAiSearchLoader);
  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !showAiSearchLoader) {
      retriveQuerybasedOnUserQuery();
    }
  }
  return (
    <div className="aiSearchBx">
          <div className="sbxicn"><AiIcon iconSize={24} /></div>
          <Form.Control type="text" placeholder={translator['What\'s on your mind today ?']} maxLength={maxLength} onChange={handleOnUserQueryChange} onKeyDown={handleKeyPress} />
          <Button title={translator['AI Search']} bsPrefix=" " onClick={retriveQuerybasedOnUserQuery} disabled={showAiSearchLoader} >{showAiSearchLoader ? <ImSpinner6 className="icn-spinner" /> : <RiSearch2Line />}</Button>
    </div> 
  );
};

export default NlpSearchBarComponent;
