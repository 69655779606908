
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Row } from "react-bootstrap";
import {IoClose} from "react-icons/io5";

class ProcessHierarchy extends React.Component {
  render() {
    return (
      <div>
        <div className="rPageHeadActBtn float-r d-md-none" style={{ marginTop: "-3px" }}>
          <ul>
            <li>
              <button
                  type='button'
                title="Minimize the right panel"
                bsClass=""
                bsStyle=""
                className="closerightPanelBtn processHiClsBtn"
                onClick={() => {
                this.props.rightEditPanel(false);
                }}
                >
                <IoClose/>
                </button>
              </li>
          </ul>
        </div>
        <div className="rPageHeading">
          {this.props.translator["Process Workflow"]}
        </div>

        <div className='text-c'>
          <img
            className="img-fluid marAuto"
            src={require("../../../../images/sla-diagram.png")}
            title={this.props.translator["Process Hierarchy"]}
          />
        </div>
      </div>
    );
  }
}

export default ProcessHierarchy;
