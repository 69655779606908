
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const repetativeRow = (e) => {
  e.DomComponents.addType(repetativeRowComponent.name, repetativeRowAdd);
};

export const repetativeRowComponent = {
  name: "RepetativeRowBlock",
  content: `<text>Content</text>`,
  traits: [
    {
      type: "select",
      label: "Data Source",
      name: "data_source",
      options: [],
      changeProp: 1,
    },
    {
      type: "checkBox",
      label: "Is Refreshable",
      name: "isRefreshable",
      changeProp: 1,
    },
  ],
};

export const repetativeRowAdd = {
  model: {
    defaults: {
      tagName: "div",
      draggable: true,
      attributes: { class: "my-repetativeRowComponent" },
      components: repetativeRowComponent.content,
      traits: repetativeRowComponent.traits,
      editable: true,
    },
  },
};

export const repeatativeRowBlockJson = {
  id: "repetaiveRowBlock",
  label: "Repetative Block",
  attributes: { class: "fa fa-th" },
  media:`<svg fill="#454545" width="40px" height="40px" viewBox="0 0 32 32" id="icon" xmlns="http://www.w3.org/2000/svg" stroke="#454545"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <defs> <style> .cls-1 { fill: none; } </style> </defs> <path d="M28,20H26v2h2v6H4V22H6V20H4a2.0024,2.0024,0,0,0-2,2v6a2.0024,2.0024,0,0,0,2,2H28a2.0024,2.0024,0,0,0,2-2V22A2.0024,2.0024,0,0,0,28,20Z" transform="translate(0 0)"></path> <circle cx="7" cy="25" r="1"></circle> <path d="M15,20H8V13h7Zm-5-2h3V15H10Z" transform="translate(0 0)"></path> <path d="M24,20H17V13h7Zm-5-2h3V15H19Z" transform="translate(0 0)"></path> <path d="M15,11H8V4h7ZM10,9h3V6H10Z" transform="translate(0 0)"></path> <path d="M24,11H17V4h7ZM19,9h3V6H19Z" transform="translate(0 0)"></path></g></svg>`,
  category: "Basic",
  content: {
    type: repetativeRowComponent.name,
  },
};
