
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import {
  ButtonToolbar,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Field, change , reduxForm} from "redux-form";
import { _checkbox, _dropDown, _inputField } from "../../../common/formFields";
import { _dateField } from "../../../serviceDesk/common/formFields";
import { _dateTimeFieldGeneral } from "../../../common/ReduxFormFields/formFields";
import { connect, useDispatch, useSelector } from "react-redux";

// const validate =(values) => {
//   console.log("Addition Date", moment(values.slaStartDate).add(1, "days"))
//   let errors = {}
//   if (
//     values.slaStartTime &&
//     moment(values.slaStartTime, "HH:mm").isValid()
//   ) {
//     if (
//       values.slaEndTime &&
//       moment(values.slaEndTime, "HH:mm").isValid()
//     ) {
//       if (moment(values.slaStartDate, "HH:mm").isSame(moment(values.slaEndDate, "HH:mm"))) {
//         if (
//           moment(values.slaStartTime, "HH:mm").isAfter(
//             moment(values.slaEndTime, "HH:mm")
//           )
//         ) {
//           errors.slaStartTime = "Start time is greater than end time.";
//         }
//       }
//     } else {
//       errors.slaEndTime = "Enter end time";
//     }
//   }
//   if (values.slaStartDate && moment(values.slaStartDate))
//     {
//     if (values.slaEndDate && moment(values.slaEndDate))
//     {
//       if (moment(values.slaStartDate).isAfter(moment(values.slaEndDate))
//       ) {
//         errors.slaStartDate = "Start date is greater than end date.";
//       }
//     } else {
//       errors.slaEndDate = "Enter end date";
//     }
//   }

//   return errors;
// }

const WorkScheduleCraeteRightSide = (props) => {
  const [allDaySchedule, setAllDaySchedule] = useState(false);
  const [repeatsOn, setRepeatsOn] = useState({
    'SU': false,
    'MO': false,
    'TU': false,
    'WE': false,
    'TH': false,
    'FR': false,
    'SA': false
  });
  const [scheduleRepeatType, setScheduleRepeatType] = useState('');
  const dispatch = useDispatch();


  function setRepeatsOnFun(val) {
    let obj = { ...repeatsOn };
    obj[val] = !obj[val];
    setRepeatsOn(obj);
    dispatch( change("createWorkDayScheduleForm", "daysSelected", obj));
  }


  function handleStartTimeChange(time) {
    dispatch(change("createWorkDayScheduleForm", "slaStartTime", moment(time).format("HH:mm")));
    dispatch(change("createWorkDayScheduleForm", "is24by7", false));
   }

   function handleEndTimeChange(time) {
    dispatch( change("createWorkDayScheduleForm", "slaEndTime", moment(time).format("HH:mm")));
   }

   function repeatsOnFun(event) {
    setScheduleRepeatType(event.target.value);
     dispatch(change('createWorkDayScheduleForm', 'scheduleRepeatType', event.target[event.target.selectedIndex].text));
     if (event.target.value == '' || event.target.value == 'Daily') {
      setRepeatsOn({
        'SU': false,
        'MO': false,
        'TU': false,
        'WE': false,
        'TH': false,
        'FR': false,
        'SA': false
      })
     }
     dispatch(change("createWorkDayScheduleForm", "daysSelected", repeatsOn));
  }

  function is24By7Fun() {
    setAllDaySchedule(!allDaySchedule);
    dispatch(change("createWorkDayScheduleForm", "is24by7", allDaySchedule));
    if (allDaySchedule == false || allDaySchedule == undefined) {
      dispatch(change("createWorkDayScheduleForm", "slaStartTime", ''));
      dispatch( change("createWorkDayScheduleForm", "slaEndTime", ''));
    }
  }

  return (
    <>
<div>
       <div className="stickyArea rBoxStyle">
        <Row>
          <Col xs={12}>
            <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{props.translator["Schedule Association"]}</div></div>
          </Col>
        </Row>
        
        <div className="rBoxGap">
                    <div className="rBoxTabs hideTabNav">
        <Row>
      <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label><span className="rStar"></span>{props.translator['Start Date']}</Form.Label>
                  <div className="dateTimeSdiv">
                    <Field
                      name="slaStartDate"
                      component={_dateTimeFieldGeneral}
                      formType={"create"}
                      className="form-control dateTimePickerField"
                    
                    />
                
                  </div>
                </Form.Group>
                </Col>
                {!allDaySchedule && <Col>
                <Form.Group className="form-group">
                  <Form.Label><span className="rStar"></span>{['Start Time']}</Form.Label>
                  <div className="dateTimeSdiv dateTimePosTop">
                  <Field
                   name="slaStartTime"
                   scheduledDay={"Days"}
                   handleTimeChange={handleStartTimeChange}
                   component={_dateField}
                   className="form-control"
                   formType="timePicker"
                   defaultValue={null}
                  />
                
                  </div>
                </Form.Group>
                
                </Col>}
        </Row>
              <Row>
              <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label><span className="rStar"></span>{props.translator['End Date']}</Form.Label>
                  <div className="dateTimeSdiv">
                    <Field
                      name="slaEndDate"
                      component={_dateTimeFieldGeneral}
                      formType={"create"}
                      className="form-control dateTimePickerField"
                    />
                  </div>
                </Form.Group>
                </Col>
        
                {!allDaySchedule && <Col>
                <Form.Group className="form-group">
                  <Form.Label><span className="rStar"></span>{['End Time']}</Form.Label>
                  <div className="dateTimeSdiv dateTimePosTop">
                  <Field
                   name="slaEndTime"
                   scheduledDay={"Days"}
                   handleTimeChange={handleEndTimeChange}
                   component={_dateField}
                   className="form-control"
                   formType="timePicker"
                   defaultValue={null}
                  />
                
                  </div>
                </Form.Group>
                
                </Col>}
                </Row>
                <Row>
                <Col md={12} sm={12} xs={12}>
                <Form.Group className="form-group">
                    <Field
                      name="is24by7"
                      component={_checkbox}
                      className="form-control"
                      label="All day schedule"
                      onChange={is24By7Fun}
                      checked={allDaySchedule} />
                </Form.Group>
              </Col>
            <Col md={12} sm={12} xs={12}>
            <Form.Group className="form-group">
              <Form.Label><span className="rStar"></span>Repeats</Form.Label>
              <Field 
                component={"select"}
                name="scheduleRepeatType"
                onChange={(e)=>repeatsOnFun(e)}
                className="form-control">
              <option value=''>Select</option>
              <option value="Daily">{["Daily"]}</option>
              <option value="Specific">{["Specific"]}</option>
              </Field>
            </Form.Group>
          </Col>
              
          {
             scheduleRepeatType == 'Specific' ?
              <Col md={12} sm={12} xs={12}>
                <Form.Label><span className="rStar"></span>Repeats On</Form.Label>
                <div className="form-group btn-week">
                        <Button title="Sunday" bsSize="small" bsStyle="text"
                          className={repeatsOn.SU ? 'week-btn selected' : 'week-btn'}
                          onClick={() => {setRepeatsOnFun('SU')}}>SU
                        </Button>
                        <Button title="Monday" bsSize="small" bsStyle="text"
                          className={repeatsOn.MO ? 'week-btn selected' : 'week-btn'}
                          onClick={() => {setRepeatsOnFun('MO')}}>MO
                        </Button>
                        <Button title="Tuesday" bsSize="small" bsStyle="text"
                          className={repeatsOn.TU ? 'week-btn selected' : 'week-btn'}
                          onClick={() => {setRepeatsOnFun('TU')}}>TU
                        </Button>
                        <Button title="Wednesday" bsSize="small" bsStyle="text"
                          className={repeatsOn.WE ? 'week-btn selected' : 'week-btn'}
                          onClick={() => {setRepeatsOnFun('WE')}}>WE
                        </Button>
                        <Button title="Thursday" bsSize="small" bsStyle="text"
                          className={repeatsOn.TH ? 'week-btn selected' : 'week-btn'}
                          onClick={() => {setRepeatsOnFun('TH')}}>TH
                        </Button>
                        <Button title="Friday" bsSize="small" bsStyle="text"
                          className={repeatsOn.FR ? 'week-btn selected' : 'week-btn'}
                          onClick={() => {setRepeatsOnFun('FR')}}>FR
                        </Button>
                        <Button title="Saturday" bsSize="small" bsStyle="text"
                          className={repeatsOn.SA ? 'week-btn selected' : 'week-btn'}
                          onClick={() => {setRepeatsOnFun('SA')}}>SA
                        </Button>
                  {/* <br />{repeatsOnError ? <span style={{ color: "red", fontSize: "smaller" }}>Please select atleast one day.</span> : ''} */}
                </div>
            </Col> 
            : ''
              }
            </Row>
        </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default(
  reduxForm({
    form: 'createWorkDayScheduleForm',
    destroyOnUnmount: false,
    enableReinitialize: true,
  })(WorkScheduleCraeteRightSide)
);