
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import ListLoader from "../../common/loaders/ListLoader.js";
import { reduxForm } from "redux-form";
import moment from "moment";
import { useGetAllSchedulersQuery } from "../../../reducers/schedulerUi/index.js";
import { useNavigate } from "react-router";
import TableComponent from "../../common/ReactTable/TableComponent.js";

const SchedulerListView = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const translator = useSelector((state) => state.spcmReducer.tr);

  const { data, isLoading, error } = useGetAllSchedulersQuery({});

  const onRowSelect = (row) => {
    props.setSchedulerData(row);
    props.showRightSideFun(true);
  };
  const tableColumns = [
    {
      name: translator["Scheduler Name"],
      selector: (row) => row.jobName,
      sortable: true,
    },
    {
      name: translator["Frequency"],
      selector: (row) => row.cronExpression,
      sortable: true,
    },
    {
      name: translator["Status"],
      selector: (row) => row.jobStatus,
      sortable: true,
    },
  ];

  const filterColumns = ["jobName", "jobStatus", "cronExpression"];
  const renderTableList = () => {
    return (
      <div className="dtaTableDv integrateHubTable">
        <div
          className={
            props.isRightSideVisible
              ? "leftTrue srchPosTop"
              : "rightTrue srchPosTop"
          }
        >
          <TableComponent
            data={data}
            columns={tableColumns}
            headerColumns={filterColumns}
            onRowSelect={onRowSelect}
            rightDisplay={props.isRightSideVisible}
            uniqueKey={"id"}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="respondv">
        <div className={"tableRgtBor "}>
          {isLoading ? (
            <ListLoader />
          ) : data && data.length === 0 ? (
            translator["There is no matching data available"]
          ) : (
            renderTableList()
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  // shiftListData: state.shiftListData
});

export default reduxForm({
  form: "SchedulerListView",
})(connect(mapStateToProps)(SchedulerListView));
