
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import { createCIResolutionCategory, resetResCatSubCatList} from "../../../../actions/categoryBoard/quickViewAction";
import Cookies from "universal-cookie";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { navigationHooks } from "../../../../helpers/NavigationHook";
import {IoClose, IoSaveOutline} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";
import { MyContext } from '_MyContext';

const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];


  const CreateCiResolutionCategoryHeader= (props) => {
	const dispatch = useDispatch();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const [duplicateCatExists, setDuplicateCatExists] = useState("false");
  const [duplicateSubCatExists, setDuplicateSubCatExists] = useState("false");
  const showLoader = useSelector(state => state.showLoader.loading,);
  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const saveObjRef = useRef(null);

  useEffect(() => {
    context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
    context.newTabPrevent();
  }, []);
 
  // const checkDuplicate = (event) => {
  //   let value = event.target.value.trim();
  //   let duplicates = valuesCategory.filter(
  //     (category) => category.trim() === value
  //   );
  //   if (duplicates.length > 1) {
  //     swal({
  //       text: "Category already exists!",
  //       button: "OK",
  //     });
      
  //     setDuplicateCatExists(true);
  //   } else {
  //     setDuplicateCatExists(false);
  //   }
  // }

 const showHome = () =>  {
    navigationHooks.navigate(homepagelocation);
  }
  const userExists = (arr) =>  {
    return arr.some((element) => element.category_name);
  }
  const saveChange = (values) => {
    console.log("Values:: ", values);
    let dataArray = [];
    props.extraCounts.map((count, index) => {
      let obj = {};
      obj.res_category_name = values["res_category_name" + count];
      obj.res_sub_category_name = values["sub_category_name" + count];
    
      if (Array.isArray(values.status)) {
        obj.status = (values.status[index] === 'true' || values.status[index] == true) ? "active" : "inactive";
      } else {
        obj.status = (values.status == true || values.status == 'true' )? "active" : "inactive";
      }

      dataArray.push(obj);
    });
    let query = {
      category_id: Number(values.ciSubCategory),
    };
   
    dispatch(createCIResolutionCategory(query, dataArray));
    dispatch(resetResCatSubCatList());
  }

    const { handleSubmit } = props;
    return (
      <Row className="margin-b-15">
        <Col lg={8} md={6} sm={4} xs={12}>
          <h2
            aria-label="Page Heading"
            role="contentinfo"
            bsClass=""
            className="sPageHeading1"
          >
            {props.tr["CI Resolution Category"]}
          </h2>
        </Col>
        <Col lg={4} md={6} sm={8} xs={12}>
          <div
            aria-label="Page Filters"
            role="contentinfo"
            className="paHedFilter"
          >
            <Link
              aria-label={props.tr["Save"]}
              role="button"
              bsPrefix=" "
              bsStyle=""
              bsClass=""
              className="myBt plus"
              title={props.tr["Save"]}
              onClick={handleSubmit(saveChange)}
              disabled={showLoader}
              ref={saveObjRef}
            >
               {
                  showLoader
                    ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/>
                }
            </Link>
            <Link
              aria-label={props.tr["Close"]}
              to={'/categoryBoard'}
              state={{activeTab: 'resolution'}}
              // role="button"
              bsPrefix=" "
              className="myBt cancel fillBtn"
              title={props.tr["Close"]}
              //onClick={() => navigationHooks.navigate({ state: {activeTab: 'resolution'}})}
              ref={closeButton}
            >
              <IoClose />
            </Link>
          </div>
        </Col>
      </Row>
    );
  
};

export default reduxForm({
	form: 'CreateCiResolutionCategoryForm',
	destroyOnUnmount: false,
  enableReinitialize: true,
  })(CreateCiResolutionCategoryHeader);
