
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Row, Col, Form, Alert, ButtonToolbar, Button, InputGroup } from 'react-bootstrap';
import { _textArea, _dropDown, _inputField, _dateField } from '../../common/formFields';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import axios from 'axios';
import constants from '../../../utils/constants';
import moment from 'moment';
import { spcmLoader, setLeftEditId, getServiceDetailCounts, getServiceLevels, getSLAWorkDaySchedule, getSLAModule, getSLAGoalType } from '../../../actions/spcmActions';
import { loadSlaModule } from '../../../actions/sla/lovAction';
import {ImSpinner6} from "react-icons/im";

const validate = (values, props) => {
	let errors = {};
	if (!values.moduleId) {
		errors.moduleId = 'Select Type';
	}
	if (!values.goalTypeId) {
		errors.goalTypeId = 'Select Goal Type';
	}
	if (!values.priority) {
		errors.priority = 'Select Priority';
	}

	return errors;
}


class ComponentForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			goal_type: [],
			type: 'Availability',
			hidePriority: false
		}
		this.onModuleChange = this.onModuleChange.bind(this);
		this.handleTimeChange = this.handleTimeChange.bind(this);
		this.componentWillMount = this.componentWillMount.bind(this);
		this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
		this.submitForm = this.submitForm.bind(this);
		this.slaFilter = this.slaFilter.bind(this);
	}

	componentWillMount() {
		this.props.change('working_hours_start', '');

		this.props.change('working_hours_end', '');
		if (this.props.spcmReducer.editLeftId > 0) {
			this.props.getSLAWorkDaySchedule(this.props.initialValues.slaWorkdayScheduleId);
			this.props.getSLAModule();
			this.props.getSLAGoalType(this.props.initialValues.moduleId);
			this.setState({ hidePriority: true });
			/* if(this.props.initialValues.moduleName=='Fulfillment'||this.props.initialValues.moduleName=='Availability')
				this.setState({hidePriority:true});*/
			this.props.loadSlaModule("SLADEF", "module");
		}
		else {
			console.log("component will mount called...");
			this.props.getSLAModule();
			this.props.loadSlaModule("SLADEF", "module");
		}
	}

	componentWillUnmount() {
		this.props.spcmLoader('attribute', 'success');
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.spcmReducer.sla_workday_schedule != nextProps.spcmReducer.sla_workday_schedule) {

			if (nextProps.spcmReducer.sla_workday_schedule.length > 0) {
				this.props.change('working_hours_start', nextProps.spcmReducer.sla_workday_schedule[0].startTime);
				this.props.change('working_hours_end', nextProps.spcmReducer.sla_workday_schedule[0].endTime);
			} else {
				this.props.change('working_hours_start', '');
				this.props.change('working_hours_end', '');
			}
		}


	}

	handleTimeChange(value, key) {
		this.props.change(key, moment(value).format("HH:mm"));

	}
	onModuleChange(e) {
		console.log("onModuleChange called")
		if (e.target.value == 15) {
			this.setState({ hidePriority: true, type: 'Fulfillment' });
			this.props.getSLAGoalType(e.target.value);
		}
		else if (e.target.value == 30) {
			this.setState({ hidePriority: true, type: 'Availability' });
			this.props.change('goal_type', 'Availability')
			this.props.getSLAGoalType(e.target.value);
		}
		else if (e.target.value == 5) {
			this.setState({
				goal_type: [{ text: "response", value: "response" },
				{ text: "resolution", value: "resolution" }], hidePriority: false, type: 'Break Fix'
			});
			this.props.getSLAGoalType(e.target.value);
		} else {
			this.setState({ goal_type: [], hidePriority: true, type: '' })
			this.props.getSLAGoalType();
		}


	}

	slaFilter(slaModule) {
		var newArray = [];
		for (var i in slaModule) {
			if ((slaModule[i].field1Key == 'Fulfillment') || (slaModule[i].field1Key == 'Incident') || (slaModule[i].field1Key == 'Service Availability')) {
				newArray.push(slaModule[i]);
			}
		}

		// Removing Fulfillment if service is incident type
		if (this.props.spcmReducer.currentlySelectedServiceDetails.WORK_ITEM_TYPE == "incidentRequest" || this.props.spcmReducer.currentlySelectedServiceDetails.WORK_ITEM_TYPE == "standardChange"
		) {
			console.log("newArray");
			console.log(newArray);
			newArray = newArray.filter(function (newArray) {
				return newArray.field1Key !== "Fulfillment";
			});
		}


		return newArray;
	}

	submitForm(values) {
		console.log('serviceStatus2--', this.props.serviceStatusState);
		if (this.props.serviceStatusState == "Deployed") {
			throw new SubmissionError({ _error: "Changes are not allowed in Deployed status " });
		} else {



			if (this.props.spcmReducer.editLeftId > 0) {
				values.updatedby_name = "";
				values.updatedby = "";
			} else {
				values.createdby_name = "";
				values.created_by = "";
			}

			values.serviceId = this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID;
			values.companyName = this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_NAME;
			values.companyId = this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID;
			values.slaStatus = 'Draft';
			values.slaStatusId = 15;
			values.serviceName = this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_NAME;
			values.slaType = 'SLA';
			values.slaTypeId = 5;
			// values.goalTime = values.goalTime;
			values.createdBy = "";
			values.createdByName = "";

			this.props.slaModule.forEach((val) => {
				if (val.field1Value == values.moduleId) {
					values.moduleName = val.field1Key;
					values.slaName = this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_NAME + ' ' + val.field1Key;
				}

			})

			this.props.spcmReducer.sla_goal_type.forEach((val) => {
				if (val.field1Value == values.goalTypeId)
					values.goalType = val.field1Key;
			})
			delete (values.created_by);
			delete (values.createdby_name);
			delete (values.goal_duration_unit);
			if (this.props.spcmReducer.editLeftId > 0) {
				this.props.spcmLoader('attribute', 'posting');
				return axios.patch(constants.SLA_DEFINITION_URL + "/" + this.props.spcmReducer.editLeftId, values).then((response) => {
					this.props.getServiceLevels(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
					this.props.componentFormFun4(false);
					this.props.spcmLoader('attribute', 'success');
				})
					.catch((error) => {

						this.props.spcmLoader('attribute', 'success');
						throw new SubmissionError({ _error: error.response.data[0].message });
					});
			}
			else {
				this.props.spcmLoader('attribute', 'posting');
				return axios.post(constants.SLA_DEFINITION_URL, values).then((response) => {
					this.props.getServiceLevels(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
					this.props.getServiceDetailCounts(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
					this.props.componentFormFun4(false);
					this.props.spcmLoader('attribute', 'success');
				})
					.catch((error) => {

						this.props.spcmLoader('attribute', 'success');
						throw new SubmissionError({ _error: error.response.data[0].message });
					});
			}
		}
	}
	render() {
		const tr = this.props.tr;
		const { error, handleSubmit } = this.props;
		this.props.change('goal_duration_unit', 'Minutes');
		const sla_workday_schedule = this.props.spcmReducer.sla_workday_schedule;
		//const sla_module = this.props.spcmReducer.sla_module;
		const sla_moduleAllValues = this.props.slaModule;
		const sla_module = this.slaFilter(sla_moduleAllValues);
		console.log(sla_module);
		const sla_goal_type = this.props.spcmReducer.sla_goal_type;
		const priority = [{ text: "P1", value: "P1" },
		{ text: "P2", value: "P2" },
		{ text: "P3", value: "P3" },
		{ text: "P4", value: "P4" }];
		console.log(this.props.npm);

		return (
			<div className="rBoxGap">
				<form onSubmit={handleSubmit(this.submitForm)}>
					{error == undefined || error == null ? null : <Alert variant="danger" className="errorMessageSec"><p>{error}</p></Alert>}
					<Row>
						<Col md={12}>
							<Form.Group className='form-group'>
								<Form.Label bsClass=""><span className="rStar"></span> {tr['Type']}</Form.Label>
								{
									this.props.spcmReducer.editLeftId > 0 ? <Field component={_inputField} readOnly name='moduleName' className='form-control' />
										: <Field component={_dropDown} options={sla_module} name='moduleId' className='form-control' onChange={(event) => { this.onModuleChange(event) }} />
								}

							</Form.Group>
						</Col>
						<Col md={12}>
							<Form.Group className='form-group'>
								<Form.Label bsClass=""><span className="rStar"></span> {tr['Goal Type']}</Form.Label>
								{
									this.props.spcmReducer.editLeftId > 0 ? <Field component={_inputField} readOnly name='goalType' className='form-control' />
										: <Field component={_dropDown} options={sla_goal_type} name='goalTypeId' className='form-control' onChange={this.onGoalChange} />
								}
							</Form.Group>
						</Col>

						{!this.state.hidePriority ? (<Col md={12}>
							<Form.Group className='form-group'>
								<Form.Label bsClass=""><span className="rStar"></span> {tr['Priority']}</Form.Label>
								<Field component={_dropDown} options={priority} name='priority' className='form-control' disabled={this.props.spcmReducer.editLeftId > 0 ? true : false} />
							</Form.Group>
						</Col>) : ""

						}

						<Col md={12}>
							<Form.Group className="form-group">
								<Form.Label bsClass="">{tr['Goal Duration']}</Form.Label>
								<InputGroup className='inputGrpInlne'>
									{
										this.props.spcmReducer.editLeftId > 0 ? <Field component={_inputField} name="goalTime" className='form-control' readOnly />
											: <Field component={_inputField} name="goalTime" className='form-control' />
									}

									<InputGroup.Text>
										<Form.Label bsClass="">{tr['Minutes']}</Form.Label>
									</InputGroup.Text>
								</InputGroup>
							</Form.Group>
						</Col>

						{/*{
						this.props.spcmReducer.editLeftId>0?(
							<Col md={12}>
						<Form.Group className="priceForm-group">
							<Form.Label bsClass="">{tr['Working Hours']}</Form.Label>

								<InputGroup.Addon >
									<Field name='working_hours_start'  disabledValue={true} component={_dateField} className="form-control" formType="timePicker" defaultValue={sla_workday_schedule.length>0?moment(sla_workday_schedule[0].startTime,'HH:mm'):null} handleTimeChange={this.handleTimeChange} />
									<Field name='working_hours_start' component={_inputField} className="form-control" readOnly/>
 								</InputGroup.Addon>
								<InputGroup.Addon>
									<Field name='working_hours_end'  disabledValue={true} component={_dateField} className="form-control" formType="timePicker" defaultValue={this.props.initialValues.working_hours_end?moment(this.props.initialValues.working_hours_end,'HH:mm'):null} handleTimeChange={this.handleTimeChange} />
									<Field name='working_hours_end' component={_inputField} className="form-control" readOnly/>
								</InputGroup.Addon>

						</Form.Group>
					</Col>
						):''
					}*/}

					</Row>
					<Row>
						<Col md={12}>
							<ButtonToolbar className="black">
								<Button className='rgSidrkBtn' title="Save" disabled={this.props.spcmReducer.spcmPostingAttribute} type="submit" bsSize="small" bsStyle="primary">{this.props.spcmReducer.spcmPostingAttribute ? <ImSpinner6 className="icn-spinner"/> : null}  {tr['Save']}</Button>
								<Button className='rgSiLigBtn' title="Close" onClick={() => { this.props.componentFormFun4(false) }} bsSize="small" bsStyle="text">{tr['Close']}</Button></ButtonToolbar>
						</Col>
					</Row>
				</form>
			</div>
		);
	}
}

const mapStateToProps = ({ spcmReducer, slaModule }) => {
	//let moudleArr = !slaModule || slaModule===undefined ? []:slaModule;
	return {
		spcmReducer,
		slaModule: _.isEmpty(slaModule) ? [] : slaModule,
		initialValues: spcmReducer.editLeftId > 0 ? spcmReducer.editLeftService : ''
	}
};
ComponentForm = reduxForm({
	form: 'serviceLevelForm',
	validate,
	enableReinitialize: true
})(ComponentForm)

export default connect(mapStateToProps, { spcmLoader, setLeftEditId, getServiceDetailCounts, getServiceLevels, getSLAWorkDaySchedule, getSLAModule, getSLAGoalType, loadSlaModule })(ComponentForm);
