
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

import PindCategories from "../../../common/header/pind-categories";
import Breadcrumbs from "../../../common/header/breadcrumbs";
import Header from "./rosterEditHeader";
import FormComponent from "./rosterEditForm";
import RightSide from "./rosterEditRightSide";
import { useParams } from "react-router-dom";
import "_Css/form/_form.scss";

const Index = () => {
  const translator = useSelector((state) => state.spcmReducer.tr);
  const [groupErrorState, setGroupErrorState] = useState('');
  const [shiftErrorState, setShiftErrorState] = useState('');
  const [startDateErrorState, setStartDateErrorState] = useState('');
  const [endDateErrorState, setEndDateErrorState] = useState('');
  const [selectedGroupList, setSelectedGroupList] = useState([]);
  const { rosterId } = useParams();

  const groupCrossClicked = () => {
    setSelectedGroupList([]);
  };

  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Breadcrumbs activePageName="Edit" parentPageurl='/onCallSchedule/roster' parentPageName="On Call Schedule" />
      </Container>

      <Container fluid>
        <Header rosterId={rosterId} setStartDateErrorState={setStartDateErrorState} setEndDateErrorState={setEndDateErrorState} />
        <PanelGroup direction="horizontal" className="panelOverflow">
          <Panel id="sidebar" minSize={30} order={1} defaultSize={67} className={"isShowLeftPanel catBordDv"}>
            <FormComponent rosterId={rosterId} startDateErrorState={startDateErrorState} setStartDateErrorState={setStartDateErrorState} endDateErrorState={endDateErrorState} setEndDateErrorState={setEndDateErrorState} selectedGroupList={selectedGroupList}
        setSelectedGroupList={setSelectedGroupList}
        groupCrossClicked={groupCrossClicked}/>
          </Panel>
          <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
          <Panel minSize={33} order={2} defaultSize={33}>
            <RightSide rosterId={rosterId} />
          </Panel>
        </PanelGroup>

      </Container>
    </main>
  )
}

export default Index;
