
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Form, InputGroup, Image } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  _dateTimeField,
  _dropDown,
} from "../../../../common/ReduxFormFields/formFields";
import { Field, change, reduxForm } from "redux-form";
import { getCostCenterCompanyOptions } from "../../../../../actions/foundation/costCenterAction";
import {
  TypeaheadandDropdown,
  _ckeditor,
  _inputField,
  _number,
  field_file,
} from "../../../../common/formFields";
import moment from "moment";
import ListLoader from "../../../../common/loaders/ListLoader";
import dummyimage from "_Images/service-icons/service-dummy.png";
import Cookies from "universal-cookie";
const cookies = new Cookies();

let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
//console.log("homepage::::"+homepagelocation);
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

const validate = (values) => {
  let errors = {};
  console.log("validate+++++", values);

  if ((values.sequence && values.sequence.toString().includes(".")) || values.sequence == "" || !(parseInt((values.sequence),10) >= 1 && parseInt((values.sequence),10) <= 10)) {
    errors.sequence = "Please enter values between 1 and 10";
  }
  if (!values.status || values.status === "0") {
    errors.status = "Required";
  }
  if (!values.topThree || values.topThree === "0") {
    errors.topThree = "Required";
  }
  if (!values.name || values.name.trim() === "") {
    errors.name = "Select name";
  }
  if (!values.linkUrl || values.linkUrl.trim() === "") {
    errors.linkUrl = "Select linkUrl";
  }
  if (!values.description || values.description.trim() === "") {
    errors.description = "Select Description";
  }
  if (
    (!values.company_Id && values.company_Id !== 0) ||
    values.company_Id === null ||
    !values.company_Name ||
    values.company_Name === null ||
    values.company_Name.trim().length === 0
  ) {
    errors.companyName = "Select Company";
  }

  return errors;
};

let ExternalLinkForm = (props) => {
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [initialValuesSet, setInitialValuesSet] = useState(false);

  const showLoader = useSelector((state) => state.commonLoader.isLoading);
  const tr = useSelector((state) => state.spcmReducer.tr);
  const companyData = useSelector((state) => state.costCenterCompanyDropdown);
  const ExternalLinkImage = useSelector((state) => state.ExternalLinkImage);

  const statusDropdownOptions = [
    { field1Value: "N", field1Key: "Inactive" },
    { field1Value: "Y", field1Key: "Active" },
  ];
  const topThreeDropdownOptions = [
    { value: "N", label: "False" },
    { value: "Y", label: "True" },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCostCenterCompanyOptions());
  }, []);

  useEffect(() => {
    if(props.formType === "Create") {
      dispatch(change("externalLinkForm", "topThree", "N"));
      setInitialValuesSet(true);
    }
    if (props.formType === "Edit") {
      if (
        Object.keys(props.ExternalLinkEditData).length > 0 &&
        initialValuesSet === false
      ) {
        console.log("edited --- ", props.initialValues);
        dispatch(change("externalLinkForm", "company_Id", props.initialValues.company_Id));
        dispatch(change("externalLinkForm","company_Name",props.initialValues.company_Name));
        setSelectedCompany([
          {
            label: props.initialValues.company_Name,
            id: props.initialValues.company_Id,
          },
        ]);
        setInitialValuesSet(true);
      }
    }
  }, [props.initialValues]);

  useEffect(() => {
    if (companyData && companyData.length > 0) {
      setCompanyList(companyData);
    }
  }, [companyData]);

  const onCompanySelection = (selectedCompany) => {
    console.log("selectedOPtion: ", selectedCompany);
    if (selectedCompany.length > 0) {
      setSelectedCompany(selectedCompany);
      dispatch(
        change("externalLinkForm", "company_Name", selectedCompany[0].label)
      );
      dispatch(change("externalLinkForm", "company_Id", selectedCompany[0].id));
    } else {
      setSelectedCompany([]);
      dispatch(change("externalLinkForm", "company_Name", ""));
      dispatch(change("externalLinkForm", "company_Id", ""));
    }
  };

  const onCrossClickCompany = () => {
    setSelectedCompany([]);
    dispatch(change("externalLinkForm", "company_Name", ""));
    dispatch(change("externalLinkForm", "company_Id", ""));
  };

  return (
    <>
      {showLoader ? (
        <ListLoader />
      ) : (
        <Form>
          <Row>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>
                  <span className="rStar"></span>{tr["Company"]}
                </Form.Label>
                <Field
                  id="companyName"
                  name="companyName"
                  className="form-control"
                  component={TypeaheadandDropdown}
                  onSelection={onCompanySelection}
                  options={companyList}
                  selectedOptions={selectedCompany}
                  onCrossClick={onCrossClickCompany}
                />
              </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label><span className="rStar"></span>{tr["Link Name"]}</Form.Label>
                <Field
                  className="form-control"
                  name="name"
                  component={_inputField}
                />
              </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label><span className="rStar"></span>{tr["Link URL"]}</Form.Label>
                <Field
                  className="form-control"
                  name="linkUrl"
                  component={_inputField}
                  maxLength={500}
                />
              </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>
                  <span className="rStar"></span>{tr["Link Sequence"]}
                </Form.Label>
                <Field
                  className="form-control"
                  name="sequence"
                  component={_number}
                  min={1} max={10}
                />
              </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>
                  <span className="rStar"></span>{tr["Status"]}
                </Form.Label>
                <Field
                  name="status"
                  component={_dropDown}
                  options={statusDropdownOptions}
                  className="form-control"
                />
              </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>
                  <span className="rStar"></span>{tr["Top Three"]}
                </Form.Label>
                <Field
                  name="topThree"
                  component={_dropDown}
                  options={topThreeDropdownOptions}
                  className="form-control"
                />
              </Form.Group>
            </Col>
            
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group priceForm-group withImgbox">
                <Form.Label>
                  {tr["Link Icon"]}{" "}
                </Form.Label>
                <InputGroup>
                  <Field className='form-control'  component={ field_file } type="file" name="linkImage"/>
                  <InputGroup.Text>
                    <Image src={ExternalLinkImage !== "" ? ExternalLinkImage : dummyimage} />
                  </InputGroup.Text>
                </InputGroup>
                <span>
                  <b className="margin-l-10 f-size-11">
                    (Accepted file types: .jpg, .jpeg, .png and .gif | Preferred
                    size is 200px X 200px and limit is 5 MB)
                  </b>
                </span>
              </Form.Group>
            </Col>

          </Row>
        </Form>
      )}
    </>
  );
};

ExternalLinkForm = reduxForm({
  form: "externalLinkForm",
  enableReinitialize: true,
  destroyOnUnmount: true,
  validate,
})(ExternalLinkForm);

ExternalLinkForm = connect(({ ExternalLinkEditData }) => ({
  initialValues: {
    ...ExternalLinkEditData,
    status: ExternalLinkEditData?.link_status,
    sequence: ExternalLinkEditData?.linkSequence,
    linkUrl: ExternalLinkEditData?.link_URL,
    topThree: ExternalLinkEditData?.topThree,
    name: ExternalLinkEditData?.link_Name
  },
  ExternalLinkEditData
}))(ExternalLinkForm);

export default ExternalLinkForm;
