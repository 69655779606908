
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { week } from "../Helper";

const WeekButton = ({ weeks, weekHander, setFieldValue }) => {
  return (
    <div className="week week-names">
      <div className="row">
        <div className="col-sm-12 ">
          <label>On</label>
          <div className="form-group  mb-0 pt-1">
            {week.map(({ key, value, name }) => (
              <button
                type="button"
                key={value}
                className={`btn week-btn ${
                  weeks?.includes(value) ? "selected" : ""
                }`}
                onClick={(e) => weekHander(weeks, value, setFieldValue)}
              >
                {key?.charAt(0)}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default WeekButton;
