
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Get, Post, Put } from "../../../utils/axiosUtils";
import { GLOBAL } from "../../../components/Globals";

const initialState = {
  allCompanyList: [],
  allForums: [],
  saveForum: [],
  allTrack: [],
  allSubTrack: [],
  allTracks: [],
  allSubTracks: [],
  forumStatus: [],
  forumType: [],
  regionList: [],
  forumById: [],
  status: "idle",
  error: null,
};

export const getAllCompanyList = createAsyncThunk(
  "forum/allCompany",
  async () => {
    const response = await Get(GLOBAL.getAllCompany);
    return response.data;
  }
);

export const getAllFroums = createAsyncThunk(
  "forum/all",
  async ({ pageNum, pgSize, filtersString = "" }) => {
    const response = await Get(
      `${GLOBAL.getAllForums}/${pageNum}/${pgSize}?sortedBy=desc` +
        filtersString
    );
    return response.data;
  }
);

export const getAllTrack = createAsyncThunk(
  "forum/getAllTrack",
  async (companyId) => {
    const response = await Get(GLOBAL.trackDetails(companyId));
    return response.data;
  }
);

export const getAllSubTrack = createAsyncThunk(
  "forum/getAllSubTrack",
  async (name) => {
    const response = await Get(GLOBAL.subTrackDetailsByTrackName(name));
    return response.data;
  }
);

export const getAllTracks = createAsyncThunk(
  "forum/getAllTracks",
  async (companyId) => {
    const response = await Get(GLOBAL.getAllTracks);
    return response.data;
  }
);

export const getAllSubTracks = createAsyncThunk(
  "forum/getAllSubTracks",
  async (name) => {
    const response = await Get(GLOBAL.getAllSubTracks);
    return response.data;
  }
);

export const getForumType = createAsyncThunk("forum/allTrack", async (name) => {
  const response = await Get(GLOBAL.getForumType);
  return response.data;
});

export const getForumStatus = createAsyncThunk(
  "forum/getForumStatus",
  async (name) => {
    const response = await Get(GLOBAL.getForumStatus);
    return response.data;
  }
);

export const createForum = createAsyncThunk("forum/create", async (params) => {
  const res = await Post(
    `${GLOBAL.createForum}?tenantId=${params?.customerId}`,
    params
  );
  return res.data;
});

export const getRegionList = createAsyncThunk(
  "forum/getRegionList",
  async (name) => {
    const response = await Get(GLOBAL.getRegionListFnd);
    return response.data;
  }
);
export const getForumById = createAsyncThunk(
  "forum/getForumById",
  async (id) => {
    const response = await Get(GLOBAL.getForumById + `?forumId=${id}`);
    return response.data;
  }
);

export const updateForum = createAsyncThunk(
  "forum/updateForum",
  async (params) => {
    const response = await Put(GLOBAL.updateForum, params);
    return response.data;
  }
);

//businessFunctionListData

const forumSlice = createSlice({
  name: "forum",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllFroums.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allForums = action.payload;
      })
      .addCase(getAllFroums.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getAllCompanyList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allCompanyList = action.payload;
      })
      .addCase(getAllCompanyList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getAllTrack.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allTrack = action.payload;
      })
      .addCase(getAllTrack.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getAllSubTrack.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allSubTrack = action.payload;
      })
      .addCase(getAllSubTrack.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getForumType.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.forumType = action.payload;
      })
      .addCase(getForumType.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getForumStatus.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.forumStatus = action.payload;
      })
      .addCase(getForumStatus.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getRegionList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.regionList = action.payload;
      })
      .addCase(getRegionList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createForum.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.saveForum = action.payload;
      })
      .addCase(createForum.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getForumById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.forumById = action.payload;
      })
      .addCase(getForumById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getAllTracks.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allTracks = action.payload;
      })
      .addCase(getAllTracks.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getAllSubTracks.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allSubTracks = action.payload;
      })
      .addCase(getAllSubTracks.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

const { reducer } = forumSlice;
export default reducer;
