
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const reportCompany = (state = {}, action) =>{
	switch (action.type){
		case 'LOAD_REPORTCOMPANY_SUCCESS':
			return action.reportCompany.data;
			case 'LOAD_REPORTCOMPANY_FAILURE':
					return action.failureMessage;
			default:
			return state;
	}
}

export const reportModule = (state = {}, action) =>{
	switch (action.type){
		case 'LOAD_REPORTMODULE_SUCCESS':
			return action.reportModule.data;
			case 'LOAD_REPORTMODULE_FAILURE':
					return action.failureMessage;
			default:
			return state;
	}
}

export const criteriaList = (state = {}, action) =>{
	switch (action.type){
		case 'LOAD_CRITERIALIST_SUCCESS':
			return action.criteriaList.data;
			case 'LOAD_CRITERIALIST_FAILURE':
					return action.failureMessage;
			default:
			return state;
	}
}
export const reportFields = (state = {}, action) =>{
	switch (action.type){
		case 'LOAD_REPORTFIELD_SUCCESS':
			return action.reportFieldsList.data;
			case 'LOAD_REPORTFIELD_FAILURE':
					return action.failureMessage;
			default:
			return state;
	}
}

export const reportListData = (state = {}, action) =>{
	switch (action.type){
		case 'LOAD_REPORTLISTDATA_SUCCESS':
			return action.reportListData.data;
			case 'LOAD_REPORTLISTDATA_FAILURE':
					return action.failureMessage;
			case 'EMPTY_REPORTLISTDATA':
					return {};
			default:
			return state;
	}
}
