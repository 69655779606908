
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { Component, PropTypes } from 'react';
import AutoSuggest from 'react-autosuggest';
import { connect } from 'react-redux';
import axios from 'axios';
import { change } from 'redux-form';
import Cookies from "universal-cookie";

import { GLOBAL } from '_Globals';
import { debounce } from 'lodash';

const api = axios.create({
  headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});
const cookies = new Cookies();
let userInfo = cookies.get('gph');
if (userInfo) userInfo = userInfo.replace('s:', '');
if (userInfo) userInfo = userInfo.substring(0, userInfo.lastIndexOf('.'));
userInfo = userInfo.split("~");
userInfo = userInfo[48];
let selectOptions = [];
const getSuggestions = (value, options) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  if (inputLength > 2) {
    selectOptions = options;
  }
  // return inputLength < 2 ? [] : selectOptions.filter(option => option.label.toLowerCase().indexOf(inputValue) > -1);
  return inputLength < 2 ? [] : selectOptions;
};

function renderSuggestion(suggestion) {
  let suggestionlabel = suggestion.label.split("|");
  return (
    <span>{suggestionlabel[0] + " | " + suggestionlabel[5] + " | " + suggestionlabel[1]}</span>
  );
}


class SearchConsumers extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.onLocationSelection = this.onLocationSelection.bind(this);
    this.getSuggestionValue = this.getSuggestionValue.bind(this);
    this.state = {
      value: '',
      suggestions: []
    };
  }
  getSuggestionValue (suggestion) {
    let suggestionlabel = suggestion.label.split("|");
    GLOBAL.consumerName = suggestionlabel[0];
    GLOBAL.consumerId = suggestionlabel[2];
    GLOBAL.consumerCompanyId = suggestionlabel[3];
    GLOBAL.onBehalfUserLocationId = suggestionlabel[4];
    GLOBAL.recipientContactNumber = suggestionlabel[6];
    GLOBAL.onBehalfcubicle = suggestionlabel[7];
    GLOBAL.onBehalfLocationName = suggestionlabel[8];
    GLOBAL.Vip = suggestionlabel[9];
    GLOBAL.consumerCompanyName = suggestionlabel[10];
    if(this.props.resetLocation) {
      this.props.resetLocation();
    }
    return (
      suggestion.label.indexOf === -1 ? '' : suggestion.label.substring(0, suggestion.label.indexOf('|'))
    );
  };
  onChange(event, { newValue }) {
  if(newValue===""){
      this.props.dispatch(change('orderingForm', 'requesterId', "empty"));
      if(this.props.resetLocation) {
        this.props.resetLocation();
      }
    }
    this.props.setIsOnBehalfUserValid(false);
    this.setState({
      value: newValue
    });

  }

  onSuggestionsFetchRequested = debounce(({ value }) => {
    let consumerData = [];
    let consumerDataDetails = [];
    let requestorObj = [];
    let offeringDetailsData = this.props.offeringDetails;

    if (value.length > 2) {
      let str1 = {};
      if (offeringDetailsData[0].isShared == "Yes") {
        str1.userId = "";
        str1.like = value;
        if (offeringDetailsData[0].company == userInfo) {
          str1.companyId = userInfo + "," + offeringDetailsData[0].sharedCompanyId.toString();
        }
        if (offeringDetailsData[0].company != userInfo) {
          str1.companyId = userInfo;

        }
        // str1.companyId = offeringDetailsData[0].sharedCompanyId.toString();
        str1.isShared = offeringDetailsData[0].isShared;
      }
      else {
        str1.userId = "";
        str1.like = value;
        str1.companyId = offeringDetailsData[0].sharedCompanyId.toString();
      }
      str1 = JSON.stringify(str1);
      api.get(GLOBAL.onbehalfuser, { headers: { 'query': str1 } }).then((response) => {
        consumerData = response.data;
        for (let i = 0; i < consumerData.length; i++) {
          requestorObj = { value: '', label: '' };
          requestorObj.value = consumerData[i].companyId;
          requestorObj.label = consumerData[i].fullname + "|" + consumerData[i].email + "|" + consumerData[i].userId
            + "|" + consumerData[i].companyId + "|" + consumerData[i].locationId + "|" + consumerData[i].employeeNumber + "|"
            + consumerData[i].businessPhone + "|" + consumerData[i].cubicle + "|" + consumerData[i].locationName + "|" + consumerData[i].vip + "|" + consumerData[i].companyName;
          consumerDataDetails.push(requestorObj);
        }
        consumerDataDetails = Array.from(new Set(consumerDataDetails));
        this.setState({
          suggestions: getSuggestions(value, consumerDataDetails)
        });
      });
    }
    else {
      this.setState({
        suggestions: []
      });
    }

  },400)

  onSuggestionsClearRequested() {
    this.setState({
      suggestions: []
    });
  }

  onLocationSelection(e) {
    this.props.dispatch(change('orderingForm', 'requesterLocationId', GLOBAL.onBehalfUserLocationId));
    this.props.dispatch(change('orderingForm', 'requesterLocationName', GLOBAL.onBehalfLocationName));
    this.props.dispatch(change('orderingForm', 'recipientCubicle', GLOBAL.onBehalfcubicle));
    this.props.dispatch(change('orderingForm', 'requesterId', GLOBAL.consumerId));
    this.props.dispatch(change('orderingForm', 'requesterName', GLOBAL.consumerName));
    this.props.setIsOnBehalfUserValid(true, GLOBAL.onBehalfUserLocationId);
  }

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      //  placeholder: 'Enter name or user ID',
      placeholder: this.props.translator["Enter name or Username"],
      className: 'form-control',
      value,
      onChange: this.onChange,
    };
    return (
      <AutoSuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={this.onLocationSelection}
      />
    );
  }
}
const mapStateToProps = ({
  offeringDetails
}) => {
  return { offeringDetails };
};

export default connect(mapStateToProps, null)(SearchConsumers);
