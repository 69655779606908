
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { Row, Col } from "react-bootstrap";
import { Field, Formik, Form, ErrorMessage } from "formik";
import LoadingIcons from "react-loading-icons";
import { useSelector } from "react-redux";
import {
  InputTextField,
  SelectField,
  InputTextFieldNumber,
} from "../../common/forms/FormFields";

import ForumDropdownPlusTypeahead from "../common/ForumDropdownPlusTypeahead";
import { useGetAllCompanyQuery } from "../../../reducers/foundation/forum/forumQuery";
import {
  useGetMenusListQuery,
  useGetQueryRawDataQuery,
  useGetSuppliersQuery,
  useGetAssociatedGrpQuery,
  useGetGroupFunctionQuery,
  useGetEscalationPlanNameListQuery,
  useBusinessFunctionListDataQuery,
  useGetMembersListQuery,
  useGetWorkFlowIdListQuery,
} from "../../../reducers/rules";
import { useQuery } from "@apollo/client";
import { getApprovalSets } from "../../common/graphQLQueries/approvalSet";
import { clientApproval } from "../../..";

const AddEditRule = ({
  ruleStatus,
  values,
  setFieldValue,
  onSubmit,
  errors,
  touched,
  setFields,
  isValid,
  resetForm,
  handleChange,
  submitCount,
  saveForumLoading,
  setQuery,
  initialQuery,
  editFormValues
}) => {
  const formikRef = useRef(null);
  const params = useParams();
  const [companyOptions, setCompanyOptions] = useState([]);
  const [supportCompanyOptions, setSupportCompanyOptions] = useState([]);
  const [supportGroupOptions, setSupportGroupOptions] = useState([]);
  const [groupFnOptions, setGroupFnOptions] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [memebers, setMembers] = useState([]);
  const translator = useSelector((state) => state.spcmReducer.tr);
  const contextualGroups = [
    { label: "Requestor.Location Group", value: "Requestor.Location.Group" },
    { label: "CI.Support Group", value: "CI.Support.Group" },
  ];

  const ruleId = params?.ruleId;
  const isAddMode = !ruleId;

  const { data: allCompany, isFetching: companyFetching } =
    useGetAllCompanyQuery();
  const { data: allSupportCompany, isFetching: supportCompanyFetching } =
    useGetSuppliersQuery(
      { queryString: `?companyId=${values?.consumerCompanyId}` },
      {
        skip: !values?.consumerCompanyId,
      }
    );

  const { data: ruleType } = useGetMenusListQuery({
    module_name: "rule",
    field_name: "rule_type",
  });

  const { data: moduleData } = useGetMenusListQuery({
    module_name: values?.ruleType,
    field_name: "module_name",
  }, { skip: !values?.ruleType });

  const { data: stuatusData } = useGetMenusListQuery({
    module_name: "rule",
    field_name: "status",
  });

  const { data: allRulesForData } = useGetMenusListQuery(
    {
      module_name: "rule",
      field_name: values?.module,
    },
    {
      skip: !values?.module,
    }
  );
  const { data: queryRawData } = useGetQueryRawDataQuery(
    {
      module_name: values?.module,
    },
    {
      skip: !values?.module,
    }
  );

  const { data: sGData } = useGetAssociatedGrpQuery(
    {
      compId: values.assignmentCompanyId,
    },
    {
      skip: !values.assignmentCompanyId,
    }
  );

  const { data: grpFnData } = useGetGroupFunctionQuery(
    {
      alterGCompId: values.assignmentCompanyId,
    },
    {
      skip: !values.assignmentCompanyId,
    }
  );
  const { data: allEscalationData } = useGetEscalationPlanNameListQuery(
    {
      companyId: values?.consumerCompanyId,
    },
    {
      skip:
        !values.module ||
        !values?.consumerCompanyId ||
        values?.ruleType !== "escalation",
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: allBusinessFunctionsData } = useBusinessFunctionListDataQuery(
    {
      companyId: values?.consumerCompanyId,
    },
    {
      skip: !values?.consumerCompanyId,
    }
  );

  const { data: workFlowIdData } = useGetWorkFlowIdListQuery(
    {
      companyId: values?.consumerCompanyId,
      module: values?.module,
    },
    {
      skip:
        !values.module ||
        !values?.consumerCompanyId ||
        values?.ruleType !== "workflow",
        refetchOnMountOrArgChange: true,
    });

  const { data: allMembers } = useGetMembersListQuery(
    {
      associatedCompanyId: values?.consumerCompanyId,
      groupId: values?.alternateGroupId,
    },
    {
      skip: !(values?.consumerCompanyId && values?.alternateGroupId),
    }
  );

  const { data: approvalSets } = useQuery(getApprovalSets, {
    variables: { compId: values?.consumerCompanyId, modId: values?.moduleId, status: "1", bfId: values?.businessFunctionId || "0" },
    skip: !values?.consumerCompanyId || values.ruleType !== "approval" || !values?.moduleId,
    client: clientApproval,
  });

  const allBusinessFunctions = values?.consumerCompanyId
    ? allBusinessFunctionsData
    : [];
  const escalationData = values?.consumerCompanyId ? allEscalationData : [];
  const workFlowData = values?.module ? workFlowIdData : [];
  const rulesForData = values?.module ? allRulesForData : [];

  const onCompanySelection = async (selectedCompany, setFieldValue) => {
    if (selectedCompany[0]?.id) {
      setFieldValue("consumerCompany", selectedCompany[0]?.label);
      setFieldValue("consumerCompanyId", Number(selectedCompany[0]?.id));
      setSelectedCompanyId(selectedCompany[0]?.id);
    } else {
      setFieldValue("consumerCompany", "");
      setFieldValue("consumerCompanyId", "");
      setSelectedCompanyId(null);
      setFieldValue("businessFunction", "");
      setFieldValue("businessFunctionId", "");
      setFieldValue("assignmentCompanyId", "");
      setFieldValue("assignmentCompany", "");
      setFieldValue("responseEntityId", "");
      setFieldValue("responseEntity", "");
      setFieldValue("groupTypeId","");
      setFieldValue("groupType", "");
    }
  };
  const onAssignmentCompany = async (selectedCompany, setFieldValue) => {
    if (selectedCompany?.[0]?.id) {
      setFieldValue("assignmentCompany", selectedCompany[0]?.label);
      setFieldValue("assignmentCompanyId", Number(selectedCompany[0]?.id));
    } else {
      setFieldValue("assignmentCompany", "");
      setFieldValue("assignmentCompanyId", "");
    }
  };

  const onResponseEntity = async (val, setFieldValue) => {
    if (val[0]?.id) {
      setFieldValue("responseEntity", val[0]?.label, true);
      setFieldValue("responseEntityId", Number(val[0]?.id, true));
      setFieldValue("external", val[0]?.isExternal == "true" ? true : false, true);
    } else {
      setFieldValue("responseEntity", "");
      setFieldValue("responseEntityId", "");
    }
  };

  useEffect(() => {
    const companyData = allCompany?.message
      ? []
      : allCompany?.length !== 0 &&
        allCompany?.map((item) => ({
          label: item?.field1Key,
          id: item?.field1Value,
        }));

    setCompanyOptions(companyData || []);
  }, [allCompany]);

  useEffect(() => {
    const supportCompanyData = allSupportCompany?.message
      ? []
      : allSupportCompany?.length !== 0 &&
        allSupportCompany?.map((item) => ({
          label: item?.field1Key,
          id: item?.field1Value,
        }));
    const allsupportCompany = values.consumerCompany ? supportCompanyData : [];
    setSupportCompanyOptions(allsupportCompany || []);
  }, [allSupportCompany, values.consumerCompany]);

  useEffect(() => {
    const supportGrpCompanyData = sGData?.message
      ? []
      : sGData?.length !== 0 &&
        sGData?.map((item) => ({
          label: item?.GROUPNAME,
          id: item?.GROUPID,
          isExternal: item?.ISEXTERNAL
        }));

    const allsupportGrps = values.assignmentCompany
      ? supportGrpCompanyData
      : [];
    setSupportGroupOptions(allsupportGrps || []);
  }, [sGData, values.assignmentCompany]);

  useEffect(() => {
    const grpFunData = grpFnData?.message
      ? []
      : grpFnData?.length !== 0 &&
        grpFnData?.map((item) => ({
          label: item?.field1Key,
          id: item?.field1Value,
        }));
    const allgrpFndata = values?.assignmentCompanyId ? grpFunData : [];
    setGroupFnOptions(allgrpFndata || []);
  }, [grpFnData, values?.assignmentCompanyId]);

  useEffect(() => {
    const mData = allMembers?.message
    ? []
    : allMembers?.length !== 0 &&
    allMembers?.map((item) => ({
        label: item?.membername,
        id: item?.memberid,
      }));
      const allgrpdata = values.consumerCompany ? mData : [];
      setMembers(allgrpdata || []);
  }, [allMembers, values.consumerCompany]);

  useEffect(() => {
    // console.log('queryRawData --- ', queryRawData);
    // let data = [
    //   ...{ name: "Select", value: "", label: "Select", operators: [] },
    //   ...queryRawData,
    // ];
    setFields(queryRawData);
  }, [queryRawData]);

  return (
    <div className="borderForm bFormDiv">
      {saveForumLoading && (
        <Row>
          <Col md={12}>
            <div className="rctLoader">
              <LoadingIcons.Bars
                height="5em"
                stroke="transparent"
                fill="#06bcee"
              />
            </div>
          </Col>
        </Row>
      )}
      <Row className={!isValid && submitCount > 0 ? "" : "d-none"}>
        <Col md={12} sm={12} xs={12}>
          {!isValid && submitCount > 0 && (
            <div className="alert alert-danger">
              The form has missing required fields, please revalidate the
              highlighted fields and update the form !
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={6}>
              <SelectField
                required
                name="ruleType"
                label={translator["Rule Type"]}
                disabled={!isAddMode}
                onChange={(e) => {
                  resetForm();
                  setFieldValue("ruleType", e.target.value);
                }}
              >
                {ruleType?.length
                  ? ruleType?.map((val, index) => (
                      <option
                        value={val?.fieldKey}
                        key={`rt-${index}`}
                        data-id={val?.fieldKey}
                      >
                        {val.fieldValue}
                      </option>
                    ))
                  : null}
              </SelectField>
            </Col>
            <Col md={6}>
              <InputTextField
                disabled={
                  editFormValues?.status === "active" && !isAddMode
                    ? true
                    : false
                }
                name="ruleName"
                label={translator["Rule Name"]}
                required
              />
            </Col>

            <Col md={6}>
              <label>
              {translator["Company"]} <span className="rStar"></span>{" "}
              </label>

              <Field
                name="company"
                id="consumerCompanyId"
                disabled={!isAddMode}
                isInvalid={errors?.consumerCompanyId}
                component={ForumDropdownPlusTypeahead}
                options={companyOptions || []}
                onSelection={(val) => {
                  onCompanySelection(val, setFieldValue);
                  setQuery(initialQuery);
                }}
                {...(!isAddMode &&
                  values?.consumerCompany && {
                    defaultInputValue: values?.consumerCompany,
                  })}
                // {...(!isAddMode && {
                //   selected: values?.consumerCompany
                //     ? [values?.consumerCompany]
                //     : [],
                // })}
                selected={
                  values?.consumerCompany
                    ? companyOptions.filter(
                        (option) =>
                          Number(option.id) === Number(values.consumerCompanyId)
                      )
                    : []
                }
              />
            </Col>
            <Col md={6}>
              <SelectField
                name="businessFunction"
                label={translator["Business Function"]}
                onChange={(e) => {
                  const selectedOption =
                    event.target.options[event.target.selectedIndex];
                  const functionId = selectedOption.getAttribute("data-id");
                  setFieldValue("businessFunction", e.target.value);
                  setFieldValue("businessFunctionId", functionId);
                }}
                disabled={
                  editFormValues?.status === "active" && !isAddMode
                    ? true
                    : false
                }
              >
                {allBusinessFunctions?.length
                  ? allBusinessFunctions?.map((val, index) => (
                      <option
                        value={val?.businessFunctionName}
                        key={`biz-${index}`}
                        data-id={val?.id}
                      >
                        {val.businessFunctionName}
                      </option>
                    ))
                  : null}
              </SelectField>
            </Col>
            <Col md={6}>
              <SelectField
                required
                name="module"
                label={translator["Module"]}
                disabled={!isAddMode}
                onChange={(e) => {
                  const selectedOption = e.target.options[e.target.selectedIndex];
                  const moduleId = selectedOption.getAttribute("data-id");
                  setFieldValue("module", e.target.value, true);
                  setFieldValue("moduleId", moduleId, true);
                  if (
                    values?.assignmentType === "contextual" &&
                    e.target.value === "fulfillment"
                  ) {
                    setFieldValue("responseEntityId", "0");
                    setFieldValue("responseEntity", "Requestor.Location.Group");
                  }
                  setFieldValue("ruleFor", "");
                  setSelectedModule(e.target.value);
                  setQuery(initialQuery);
                }}
              >
                {moduleData?.length
                  ? moduleData?.map((val, index) => (
                      <option
                        value={val?.fieldKey}
                        key={`module-${index}`}
                        data-id={val?.moduleId}
                      >
                        {val.fieldValue}
                      </option>
                    ))
                  : null}
              </SelectField>
            </Col>

            {values.ruleType === "assignment" && (
              <Col md={6}>
                <SelectField
                  required
                  name="ruleFor"
                  label={translator["Rule For"]}
                  disabled={!isAddMode}
                >
                  {rulesForData?.length
                    ? rulesForData?.map((val, index) => (
                        <option
                          value={val?.fieldKey}
                          key={`module-${index}`}
                          data-id={val?.fieldKey}
                        >
                          {val.fieldValue}
                        </option>
                      ))
                    : null}
                </SelectField>
              </Col>
            )}
            {values.ruleType === "escalation" && (
              <Col md={6}>
                <SelectField
                  required
                  name="responseEntityId"
                  label={translator["Escalation Plan"]}
                  disabled={!isAddMode}
                  onChange={(e) => {
                    const selectedOption =
                      e.target.options[e.target.selectedIndex];
                    const responseEntityId =
                      selectedOption.getAttribute("data-id");
                    setFieldValue("responseEntity", responseEntityId);
                    setFieldValue("responseEntityId", e.target.value);
                  }}
                >
                  {escalationData?.data?.length
                    ? escalationData?.data?.map((val, index) => (
                        <option
                          value={val?.id}
                          key={`module-${index}`}
                          data-id={val?.escalationPlanName}
                        >
                          {val.escalationPlanName}
                        </option>
                      ))
                    : null}
                </SelectField>
              </Col>
            )}
            {values.ruleType === "workflow" && (
              <Col md={6}>
                <SelectField
                  required
                  name="responseEntityId"
                  label="Workflow Id"
                  disabled={!isAddMode}
                  onChange={(e) => {
                    const selectedOption =
                      e.target.options[e.target.selectedIndex];
                    const responseEntityId =
                      selectedOption.getAttribute("data-id");
                    setFieldValue("responseEntity", responseEntityId);
                    setFieldValue("responseEntityId", e.target.value);
                  }}
                >
                 {workFlowData ? (
                  <option
                    value={workFlowData.workflowId}
                    key={`module-${workFlowData.id}`}
                    data-id={workFlowData.workflowName}
                  >
                    {workFlowData.workflowName}
                  </option>
                ) : null}
              </SelectField>
              </Col>
            )}
            {values.ruleType === "approval" && (
              <Col md={6}>
                <SelectField
                  required
                  name="responseEntityId"
                  label={translator["Approval Set Id"]}
                  disabled={!isAddMode}
                  onChange={(e) => {
                    const selectedOption =
                      e.target.options[e.target.selectedIndex];
                    const responseEntityId =
                      selectedOption.getAttribute("data-id");
                    setFieldValue("responseEntity", responseEntityId);
                    setFieldValue("responseEntityId", e.target.value);
                  }}
                >
                  {approvalSets?.data?.getApprovalSetByParam?.map((value, index) => (
                    <option
                      value={value.setId}
                      key={`set-${index}`}
                      data-id={value.approvalSetName}
                    >
                      {value.approvalSetName}
                    </option>
                  ))}
                </SelectField>
              </Col>
            )}
          </Row>
          <Row>
            <Col md={6}>
              <InputTextFieldNumber
                min="1"
                name="weight"
                label={translator["Weight"]}
                required
                disabled={
                  !isAddMode &&
                  ruleStatus !== "inactive" &&
                  ruleStatus !== "draft"
                }
                onChange={(e) => {
                  const value = e.target.value;
                  setFieldValue("weight", value > 0 ? value : "");
                }}
              />
            </Col>
            <Col md={6}>
              <SelectField
                required
                name="status"
                label={translator["Status"]}
                onChange={(event) => {
                  if (event.target.value === "") {
                    setFieldValue("status", "");
                  } else {
                    setFieldValue("status", event.target.value);
                  }
                }}
              >
                {stuatusData?.length
                  ? stuatusData?.map((val, index) => (
                      <option
                        value={val?.fieldKey}
                        key={`status-${index}`}
                        data-id={val?.fieldKey}
                      >
                        {val.fieldValue}
                      </option>
                    ))
                  : null}
              </SelectField>
            </Col>
          </Row>
        </Col>
        {values.ruleType === "assignment" && (
          <Col sm={12}>
            <div className="border p-2 rule-sg">
              <Row>
                <Col md={6}>
                  <label>
                  {translator["Support Company"]} <span className="rStar"></span>
                  </label>
                  <div className="dvTypehdropdown">
                    <Field
                      name="assignmentCompanyId"
                      id="assignmentCmp"
                      disabled={
                        !isAddMode &&
                        ruleStatus !== "inactive" &&
                        ruleStatus !== "draft"
                      }
                      // isInvalid={
                      //   touched?.assignmentCompanyId &&
                      //   errors?.assignmentCompanyId
                      // }
                      errorColor={
                        touched?.assignmentCompanyId &&
                        !values?.assignmentCompanyId
                          ? "error"
                          : ""
                      }
                      className="form-control"
                      component={ForumDropdownPlusTypeahead}
                      options={supportCompanyOptions || []}
                      onSelection={(val) => {
                        onAssignmentCompany(val, setFieldValue);
                        if (values?.assignmentType === "predefined") {
                          setFieldValue("responseEntity", "");
                          setFieldValue("responseEntityId", "");
                        }
                        setFieldValue("alternateGroupId", "");
                        setFieldValue("alternateGroupName", "");
                        setFieldValue("groupTypeId", "");
                        setFieldValue("groupType", "");
                      }}
                      onCrossClick={() => {
                        if (values?.assignmentType === "predefined") {
                          setFieldValue("responseEntity", "");
                          setFieldValue("responseEntityId", "");
                        }
                        setFieldValue("alternateGroupId", "");
                        setFieldValue("alternateGroupName", "");
                        setFieldValue("groupTypeId", "");
                        setFieldValue("groupType", "");
                      }}
                      {...(!isAddMode &&
                        values?.assignmentCompany && {
                          defaultInputValue: values?.assignmentCompany,
                        })}
                      selected={
                        values?.assignmentCompany
                          ? supportCompanyOptions.filter(
                              (option) =>
                                Number(option.id) ===
                                Number(values.assignmentCompanyId)
                            )
                          : []
                      }
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <label>
                  {translator["Support Group"]} <span className="rStar"></span>
                  </label>

                  <div className="input-group mb-3 flex-nowrap">
                    <span className="input-group-text p-0 border-0">
                      {values.module !== "change_management" &&
                        values.module !== "problem" && (
                          <Field
                            as="select"
                            required
                            name="assignmentType"
                            label="Predefined"
                            className="group-dd"
                            disabled={!isAddMode}
                            onChange={(e) => {
                              if (
                                e.target.value === "contextual" &&
                                values?.module === "fulfillment"
                              ) {
                                setFieldValue("responseEntityId", "0");
                                setFieldValue(
                                  "responseEntity",
                                  "Requestor.Location.Group"
                                );
                              } else {
                                setFieldValue("responseEntityId", "");
                                setFieldValue("responseEntity", "");
                              }
                              setFieldValue("assignmentType", e.target.value);
                              setFieldValue("alternateGroupId", "");
                              setFieldValue("alternateGroupName", "");
                              setFieldValue("groupTypeId", "");
                              setFieldValue("groupType", "");
                              setQuery(initialQuery);
                            }}
                          >
                            <option value="predefined">{translator["Predefined"]}</option>
                            <option value="contextual">{translator["Contextual"]}</option>
                          </Field>
                        )}
                    </span>
                    {values.assignmentType === "contextual" ? (
                      <Field
                        as="select"
                        required
                        name="responseEntity"
                        label={translator["contextual"]}
                        className="form-control"
                        disabled={
                          (!isAddMode &&
                            ruleStatus !== "inactive" &&
                            ruleStatus !== "draft") ||
                          (values?.assignmentType === "contextual" &&
                            values?.module === "fulfillment")
                        }
                        onChange={(e) => {
                          const selectedOption =
                            e.target.options[e.target.selectedIndex];
                          const responseEntityId =
                            selectedOption.getAttribute("data-id");
                          setFieldValue("responseEntity", e.target.value);
                          setFieldValue("responseEntityId", responseEntityId);
                          setFieldValue("groupTypeId", "");
                          setFieldValue("groupType", "");
                        }}
                      >
                        <option value="">Select</option>
                        <option value="Requestor.Location.Group" data-id="0">
                          Requestor.Location Group
                        </option>
                        <option value="CI.Support.Group" data-id="0">
                          CI.Support Group
                        </option>
                      </Field>
                    ) : (
                      <div className="dvTypehdropdown dvTypehdPos w-100">
                        <Field
                          name="responseEntityId"
                          id="responseEntity"
                          disabled={
                            !isAddMode &&
                            ruleStatus !== "inactive" &&
                            ruleStatus !== "draft"
                          }
                          errorColor={
                            touched?.responseEntityId &&
                            !values?.responseEntityId
                              ? "error"
                              : ""
                          }
                          component={ForumDropdownPlusTypeahead}
                          options={supportGroupOptions || []}
                          onSelection={(val) =>
                            onResponseEntity(val, setFieldValue)
                          }
                          onInputChange={(val) =>
                            onResponseEntity(val, setFieldValue)
                          }
                          {...(!isAddMode &&
                            values?.responseEntity && {
                              defaultInputValue: values?.responseEntity,
                            })}
                          selected={
                            values?.responseEntity
                              ? supportGroupOptions.filter(
                                  (option) =>
                                    Number(option.id) ===
                                    Number(values.responseEntityId)
                                )
                              : []
                          }
                        />
                      </div>
                    )}
                  </div>
                </Col>
                <Col sm={6}>
                  {values.assignmentType === "contextual" && (
                    <>
                      <div className="bgGray padding-5">{translator["Alternate Group"]}</div>
                      <div className="dvTypehdropdown">
                        <Field
                          name="alternateGroupId"
                          id="alternateGroupName"
                          disabled={
                            !isAddMode &&
                            ruleStatus !== "inactive" &&
                            editFormValues?.status !== "draft"
                          }
                          component={ForumDropdownPlusTypeahead}
                          options={supportGroupOptions || []}
                          onSelection={(val) => {
                            setFieldValue(
                              "alternateGroupId",
                              Number(val[0]?.id)
                            );
                            setFieldValue("alternateGroupName", val[0]?.label);
                          }}
                          {...(!isAddMode &&
                            values?.alternateGroupName && {
                              defaultInputValue: values?.alternateGroupName,
                            })}
                          selected={
                            values?.alternateGroupName
                              ? supportGroupOptions.filter(
                                  (option) =>
                                    Number(option.id) ===
                                    Number(values.alternateGroupId)
                                )
                              : []
                          }
                          // {...(!isAddMode && {
                          //   selected: values?.alternateGroupName
                          //     ? [values?.alternateGroupName]
                          //     : [],
                          // })}
                        />
                      </div>
                    </>
                  )}
                </Col>
                <Col sm={6}>
                  {values.assignmentType === "contextual" &&
                    values.responseEntity === "Requestor.Location.Group" && (
                      <>
                        <div className="bgGray padding-5">
                          {translator["Group Function"]} <span className="rStar"></span>
                        </div>
                        <div className="dvTypehdropdown">
                          <Field
                            name="groupTypeId"
                            id="groupType"
                            disabled={
                              !isAddMode &&
                              editFormValues?.status !== "inactive" &&
                              editFormValues?.status !== "draft"
                            }
                            // isInvalid={errors?.groupTypeId}
                            errorColor={
                              touched?.groupTypeId && !values?.groupTypeId
                                ? "error"
                                : ""
                            }
                            component={ForumDropdownPlusTypeahead}
                            options={groupFnOptions || []}
                            onSelection={(val) => {
                              setFieldValue(
                                "groupTypeId",
                                val[0]?.id ? Number(val[0]?.id) : null
                              );
                              setFieldValue("groupType", val[0]?.label);
                            }}
                            onCrossClick={() => {
                              setFieldValue("groupTypeId", "");
                              setFieldValue("groupType", "");
                            }}
                            {...(!isAddMode &&
                              values?.groupType && {
                                defaultInputValue: values?.groupType,
                              })}
                            selected={
                              values?.groupTypeId
                                ? groupFnOptions.filter(
                                    (option) =>
                                      Number(option.id) ===
                                      Number(values.groupTypeId)
                                  )
                                : []
                            }
                          />
                        </div>
                      </>
                    )}
                </Col>
              </Row>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default AddEditRule;
