
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import {loadBusinessFunctionList} from '../../../actions/businessFunction/businessFunctionAction';
import {HiPlus, HiOutlineRefresh} from "react-icons/hi";

const api = Axios.create({
  headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

function BusinessFunctionListHeader(props) {
  const dispatch = useDispatch();
  const [isCiCategoryBoardVisible, setIsCiCategoryBoardVisible] = useState(false);
  const [isCategoryBoardVisible, setIsCategoryBoardVisible] = useState(false);
  const [isCategoryActive, setIsCategoryActive] = useState('');
  const [isServiceActive, setIsServiceActive] = useState('active');

  useEffect(() => {
    let str = {};
    str.user_id = '';
    str = JSON.stringify(str);
    api.get('/api/userInfo', { headers: { query: str } }).then((response) => {
      console.log('response.data.roleId', response.data.role_id);
      let roleArray = response.data.role_id.split(',');
      console.log('Role Array', roleArray.includes('37') || roleArray.includes('38'));
      if (roleArray.includes('37') || roleArray.includes('38')) {
        if ((!roleArray.includes('5')) && (roleArray.includes('37') || roleArray.includes('38'))) {
          //navigationHooks.navigate('/CiCategory')
        }
        setIsCiCategoryBoardVisible(true);
      }
      if (roleArray.includes('5')) {
        setIsCategoryBoardVisible(true);
        setIsServiceActive('active');
        setIsCategoryActive('');
      } else {
        setIsServiceActive('');
        setIsCategoryActive('active');
      }
    });
  }, []);
const refreshCategoryBoardTable =() => {
	dispatch(loadBusinessFunctionList());
}
  const handleActive = (clickedTab) => {
    if (clickedTab === 'service') {
      setIsServiceActive('active');
      setIsCategoryActive('');
    } else {
      setIsServiceActive('');
      setIsCategoryActive('active');
    }
  };

  return (
    <Row className="margin-b-15" style={{ zIndex: 1, position: 'relative' }}>
      <Col lg={8} md={6} sm={6} xs={12}>
        <ul className="wibTabs">
          {isCategoryBoardVisible && (
            <li onClick={() => handleActive('service')} className={isServiceActive}>
              <Link title={props.translator['Business Function']} to="/businessFunction">
                {props.translator['Business Function']}
              </Link>
            </li>
          )}
        </ul>
      </Col>
      <Col lg={4} md={6} sm={6} xs={12}>
        <div className="paHedFilter">
		<Link bsPrefix=' ' role="button" className="myBt plus fillBtn" title={props.translator['Create new Business Function']} to="/createBusinessFunction" ><HiPlus/></Link>
          <Button bsPrefix=' ' title={props.translator["Refresh"]} role="button" bsStyle="" bsClass="" className="myBt" onClick={() => {refreshCategoryBoardTable(); }}><HiOutlineRefresh className="refreshFlip" /></Button>
        </div>
      </Col>
    </Row>
  );
}

export default BusinessFunctionListHeader;
