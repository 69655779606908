
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export default function(state = initialState.welcomeMessage, action){
    switch (action.type){
        case 'GET_WELCOME_MESSAGE':
            return action.welcomeMessage.data[0];
        default:
          return state;
    }
}
