
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useRef, useState, useEffect } from "react";
import { Field, Formik, Form } from "formik";
import moment from "moment";
import { Row, Col, Button } from "react-bootstrap";
import { SelectField } from "../../common/FormFields";
import { useGetUserInfoQuery } from "../../../../reducers/governance/meeting/meeting";
import {
  useGetAllCompanyQuery,
  useOccurrenceStatusQuery,
  useGetAllCategoryQuery,
  useCommitteDetailsQuery,
  useGetMeetingOccurrencesByMonthQuery,
  useGetAllActiveInActiveForumsQuery,
} from "../../../../reducers/foundation/forum/forumQuery";
import MeetingDropdownPlusTypeahead from "../../common/MeetingDropdownPlusTypeahead";
import { useSelector } from "react-redux";

const Filters = ({
  companyId,
  userCompanyName,
  filtersData,
  setFiltersData,
}) => {
  const formikRef = useRef(null);

  const [companyOptions, setCompanyOptions] = useState([]);
  const [selectedCustId, setSelectedCustId] = useState(null);
  const [categoryId, setCategoryId] = useState('');
  const windowSize = useSelector((state) => state.windowSize.width);

  const [initVals, setInitVals] = useState({
    forumId: "",
    formName: "",
    category: "all",
    statusId: "",
    company: [],
    committeeName: "all",
  });

  let initialValues = {
    ...initVals,
  };


  const { data: allForums, isFetching: forumFetching } =
    useGetAllActiveInActiveForumsQuery(
      { queryString: `?tenantId=${filtersData?.tenantId}&customerName=${filtersData?.companyName}` },
      {
        skip: false,
      }
    );
  const { data: allCompany, isFetching: companyFetching } =
    useGetAllCompanyQuery({
      skip: false,
    });

  const { data: allStatus, isFetching: statusFetching } =
    useOccurrenceStatusQuery(
      { queryString: `?tenantId=${companyId}` },
      {
        skip: false,
      }
    );

  const { data: allCats } = useGetAllCategoryQuery(
    { queryString: `?tenantId=${companyId}` },
    {
      skip: false,
    }
  );
  const queryString = `?${filtersData.forumId && filtersData.forumId!=='' ? `forumId=${filtersData.forumId}&` : ''}${filtersData.tenantId && filtersData.tenantId!=='' ? `tenantId=${filtersData.tenantId}&` : ''}${filtersData.categoryId && filtersData.categoryId!=='' ? `categoryId=${filtersData.categoryId}` : ''}`;
  const { data: allcommittee } = useCommitteDetailsQuery(
    {
      queryString: queryString==='?' ? '' : queryString,
    },
    {
      skip: false
    }
  );

  const onSubmit = async (fields, { resetForm, setSubmitting }) => {};

  useEffect(() => {
    const companyData = allCompany?.message
      ? []
      : allCompany?.length !== 0 &&
        allCompany?.map((item) => ({
          label: item?.field1Key,
          id: item?.field1Value,
        }));

    setCompanyOptions(companyData || []);
  }, [allCompany]);

  useEffect(() => {
    if (companyOptions.length > 0 && allForums && allStatus && allcommittee) {
      if (
        typeof initVals.forumId === 'undefined' || initVals.forumId === null &&
        typeof initVals.statusId === 'undefined' || initVals.statusId === null &&
        (typeof initVals.company === 'undefined' || initVals.company === null || initVals.company.length === 0)
      ) {
        setInitVals((prev) => {
          return {
            ...prev,
            forumId: allForums?.[0]?.forumId || "",
            statusId: allStatus?.[0]?.fieldNameValue1 || "",
            company: [companyOptions?.[0] || ""],
            committeeName: allcommittee?.[0]?.name || "",
          };
        });
        setFiltersData((prev) => {
          return {
            ...prev,
            forumId: allForums?.[0]?.forumId,
            forumName: allForums?.[0]?.fieldName,
            tenantId: companyOptions?.[0]?.id,
            companyName: companyOptions?.[0]?.label,
            statusId: allStatus[0]?.fieldNameValue1,
            committeeName: (allcommittee?.[0]?.name) || "all",
          };
        });
      }
    }
  }, [companyOptions.length > 0, allForums, allStatus, allcommittee]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={false}
      enableReinitialize={true}
      onSubmit={onSubmit}
    >
      {({
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        handleBlur,
        values,
        dirty,
        handleChange,
      }) => {
        return (
          <Form noValidate>
            <Row className="console-filter">
              <Col sm={3} xs={12}>
                <div className="filter form-group">
                  <label className="form-label">Company</label>
                  <Field
                    name="company"
                    id="company"
                    component={MeetingDropdownPlusTypeahead}
                    options={companyOptions || []}
                    onSelection={(val) => {
                      setFiltersData((prev) => {
                        return {
                          ...prev,
                          ["companyName"]: val?.[0]?.label,
                          ["tenantId"]: val?.[0]?.id,
                          'eventTitle': '',
                        };
                      });
                      setFieldValue("forumId", '');
                      setFieldValue("forumName", '');
                      setFieldValue("statusId", '');
                      setFieldValue("company", val);
                      setSelectedCustId(val?.[0]?.id);
                    }}
                    selected={
                      values?.company?.length > 0 ? values?.company : []
                    }
                  />
                </div>
              </Col>
              <Col sm={3} xs={12}>
                <div className="colSearDv">
                  <SelectField
                    name="forumId"
                    label="Forum"
                    value={values.forumId}
                    onChange={(e) => {
                      const forumId = e.target.value;
                      const forumName =
                        e.target.options[e.target.selectedIndex].getAttribute(
                          "data-name"
                        );
                      setFieldValue("forumId", forumId);
                      setFieldValue("forumName", forumName);
                      setFiltersData((prev) => ({
                        ...prev,
                        forumName: forumName,
                        forumId: forumId,
                        'eventTitle': '',
                      }));
                    }}
                  >
                    {allForums?.length > 0 &&
                      allForums.map((item) => (                  
                        <option
                          value={item.forumId}
                          title={item.forumName}
                          key={item.forumId}
                          data-name={item.forumName}
                        >
                          {windowSize < 1920 && item.forumName.length > 30 ? (item.forumName.slice(0, 30) + "...") 
                          : (windowSize < 1280 && item.forumName.length > 25 ? (item.forumName.slice(0, 25) + "...") 
                          : (item.forumName))
                          }
                        </option>
                      ))}
                  </SelectField>
                </div>
              </Col>
              <Col sm={2} xs={12}>
                <div className="colSearDv">
                  <SelectField
                    name="category"
                    label="Category"
                    onChange={(e) => {
                      const categoryId = e.target.options[e.target.selectedIndex].dataset.id;
                      setCategoryId(categoryId);
                      setFiltersData((prev) => {
                        return {
                          ...prev,
                          ["category"]: e?.target?.value,
                          'categoryId': categoryId,
                          'eventTitle': '',
                        };
                      });

                      setFieldValue("category", e?.target?.value);
                      setFieldValue("categoryId", categoryId);
                    }}
                  >
                    <option value="all" data-id="">All</option>
                    {allCats?.length > 0 &&
                      allCats?.map((item, index) => {
                        return (
                          <option
                            value={item?.fieldNameKey1}
                            key={`cat-${item?.fieldNameValue1}`}
                            data-id={item?.fieldNameValue1}
                          >
                            {item?.fieldNameKey1}
                          </option>
                        );
                      })}
                  </SelectField>
                </div>
              </Col>
              <Col sm={2} xs={12}>
                <div className="colSearDv">
                  <SelectField
                    name="committeeName"
                    label="Committees"
                    onChange={(e) => {
                      setFieldValue("committeeName", e.target.value);
                      setFiltersData((prev) => {
                        return {
                          ...prev,
                          ["committeeName"]: e?.target?.value,
                          'eventTitle': '',
                        };
                      });
                    }}
                  >
                    <option value="all">All</option>
                    {allcommittee?.length > 0 &&
                      allcommittee?.map((item, index) => {
                        return (
                          <option
                            value={item?.name}
                            key={`cat-${item?.id}`}
                            data-id={item?.id}
                          >
                            {item?.name}
                          </option>
                        );
                      })}
                  </SelectField>
                </div>
              </Col>
              <Col sm={2} xs={12}>
                <div className="colSearDv">
                  <SelectField
                    name="statusId"
                    label="Status"
                    onChange={(e) => {
                      setFieldValue("statusId", e.target.value);
                      setFiltersData((prev) => {
                        return {
                          ...prev,
                          ["statusId"]: e?.target?.value,
                          'eventTitle': '',
                        };
                      });
                    }}
                  >
                    {allStatus?.length > 0 &&
                      allStatus?.map((item, index) => {
                        return (
                          <option
                            value={item?.fieldNameValue1}
                            key={`cat-${item?.fieldNameValue1}`}
                            data-id={item?.fieldNameValue1}
                          >
                            {item?.fieldNameKey1}
                          </option>
                        );
                      })}
                  </SelectField>
                </div>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Filters;
