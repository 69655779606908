
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export default function (state = initialState.cmdbChangeRelatedWorkDetails, action){

	switch (action.type){
		case 'LOAD_CMDBCHANGERELATEDWORKDETAILS_SUCCESS':
			//alert("editReducer:"+action.breakFixEditDetails.data.serviceId);
			return action.changeRelatedWorkDetails.data;
		case 'LOAD_RELATED_ASSETS_DETAILS':
			return action.relatedAssetsWorkDetails.data;
		case 'LOAD_CMDBCHANGERELATEDWORKDETAILS_FAILURE':
			return action.failureMessage; 
		case 'RESET_ASSET_DETAILS' :
			return {data: []};
      default:
			return state;
		}

}
