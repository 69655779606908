
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import {GLOBAL} from '../../../src/components/Globals'
import swal from 'sweetalert';
import { navigationHooks } from '../../helpers/NavigationHook';
import Swal from 'sweetalert2';
const api = axios.create({
    headers: { Pragma: "no-cache", "Cache-control": "no-store" },
  });

export const getCompanyFdnList = (param) => {
    return (dispatch) => {
      dispatch({ type: 'FDN_DATA_FETCHING'})
      api.get("/api/getFdnCompanyList")
        .then((response) => {
          dispatch({ type: 'FDN_DATA_FETCHED_SUCCESS'})
          dispatch({ type: 'COMPANY_FDN_LIST_FETCHED_SUCCESSFULLY', payload: response.data});
        })
        .catch((err) => {
          dispatch({ type: 'FDN_DATA_FETCHED_SUCCESS'})
          console.log(err);
        });
    };
  };
  
  export const setCompanyListData = (payload) => {
    return (dispatch) => {
          dispatch({ type: 'COMPANY_FDN_LIST_FETCHED_SUCCESSFULLY', payload: payload });
    };
  };
  
  export function postFDNCompanyAction(data) {
    return (dispatch) => {
        api.post("/api/saveCompany", data)
            .then((response) => {
              if (response.status == 201) {
                if (response.data == 'Name already exists') {
                  Swal.fire({
                    title: 'Company name already exists',
                  })
                } else {
                  navigationHooks.navigate("/editCompany/" + response.data.CompanyId);
                }
                } 
            })
          .catch((err) => {
              console.log("SmallError",err.response.data)
            });
    };
}

export const getMenuListCompanyAction = () => {
  return (dispatch) => {
    axios.get("/api/getMenulistFDNCompany").then((resp) => {
      let object = [];
      let menuAssociate = [];
      let withoutConsumer = [];
      const menuListoptions = resp.data[1];
      const assocciationTypeMenuList = resp.data[1];
      if (typeof menuListoptions !== "undefined" && menuListoptions.length > 0) {
        menuListoptions.forEach((values) => {
          let val = { id: values.field1Value, label: values.field1Key };
          object.push(val);
        });
      }
      if (typeof assocciationTypeMenuList !== "undefined" && assocciationTypeMenuList.length > 0) {
        assocciationTypeMenuList.forEach((values) => {
          if (values.field1Key == "Service Consumer") {
            let val = { id: values.field1Value, label: values.field1Key };
            menuAssociate.push(val);
          } else {
            let val = { id: values.field1Value, label: values.field1Key };
            withoutConsumer.push(val);
          }
        });
      }  
      dispatch({
        type: "MENULIST_FDN_COMPANY",
        payload: object,
      });
      dispatch({
        type: "SERVICE_CONSUMER_MENULIST_FDN_COMPANY",
        payload: menuAssociate,
      });
      dispatch({
        type: "NOT_SERVICE_CONSUMER_MENULIST_FDN_COMPANY",
        payload: withoutConsumer,
      });
    });
  };
};

export const getEditCompanyPageDetails = (id) => {
  return (dispatch) => {
    dispatch({ type: 'FDN_DATA_FETCHING'})
    api.get("/api/editCompany/"+id)
      .then((response) => {
        let newArray = response.data.company.companyType;
        let assocciationTypeMenuList = []
        newArray.forEach((value) => {
          if (value !== 'Service Consumer') {
            let newObj = {
              field1Value: value,
              field1Key: value
            }
            assocciationTypeMenuList.push(newObj)
          }
        })
        let menuAssociate = [];
        if (typeof assocciationTypeMenuList !== "undefined" && assocciationTypeMenuList.length > 0) {
          assocciationTypeMenuList.forEach((values) => {
            let val = { id: values.field1Value, label: values.field1Key };
            menuAssociate.push(val);
          });
        }
        dispatch({ type: 'FDN_DATA_FETCHED_SUCCESS'})
        dispatch({ type: 'COMPANY_EDIT_LIST_FETCHED_SUCCESSFULLY', payload: response.data });
        dispatch({ type: 'ASSOCIATION_ACTIVE_FLAG_LIST_FETCHED_SUCCESSFULLY', payload: response.data });
        dispatch({ type: "ASSOCIATION_TYPE_MENULIST_FDN_COMPANY", payload: menuAssociate });
        if (response.data.userCount == 0) {
          dispatch({ type: 'SYNC_ALL_NOT_CLICK', payload: `All the users are successfully synced with IAM` });
        } else if (response.data.userCount !== 0) {
          if (response.data.userCount == 1) {
            dispatch({ type: 'SYNC_ALL_CLICK', payload: `${response.data.userCount} record is yet to be synced with IAM. Please click on "Sync All" button to re-initiate the sync.` });
          } else {
            dispatch({ type: 'SYNC_ALL_CLICK', payload: `${response.data.userCount} records are yet to be synced with IAM. Please click on "Sync All" button to re-initiate the sync.` });
          }
        }
        })
      .catch((err) => {
        dispatch({ type: 'FDN_DATA_FETCHED_FAILED'})
        console.log(err);
      });
  };
};
export const getEditCompanyPageDetailsReset = () => {
  return (dispatch) => {
  
        dispatch({ type: 'RESET_COMPANY_EDIT_DETAILS', payload: []});
      
  };
};

export function updateFDNCompanyAction(data) {
  return (dispatch) => {
      api.post("/api/editCompanyDetails", data)
          .then((response) => {
            dispatch({ type: 'ASSOCIATION_FLAG_FETCHED_SUCCESSFULLY', payload: response.data});
          })
        .catch((err) => {
          
            // if(err.response.data[0].message === 'Name already exists') {
            //   Swal.fire({
            //     title: 'Company name already exists',
            //   })
            // }
          });
  };
}

export const getAssociateCompanyDropdown = (companyObj) => {
  return (dispatch) => {
    api.post("/api/getAssociatedCompany",companyObj)
      .then((response) => {
        let companyListoptions = response.data;
        let object = [];
        if (typeof companyListoptions !== "undefined" && companyListoptions.length > 0) {
          companyListoptions.forEach((values) => {
            let val = { id: values.id, label: values.name };
            object.push(val);
          });
        }
        dispatch({ type: 'ASSOCIATE_COMPANY_FETCHED', payload:object });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getAssocitedCompanyList = (id) => {
  return (dispatch) => {
    api.get("/api/viewAssociatedCompaniesList"+id)
      .then((response) => {
        dispatch({ type: 'ASSOCIATE_COMPANY_FETCHED_SUCCESSFULLY', payload: response.data});
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getAssociatedCompanyGroupList = (comapnyid,associateCompanyID) => {
  return (dispatch) => {
    api.get("/api/viewNewAssociationGroups/"+comapnyid+"/"+associateCompanyID)
      .then((response) => {
        let companyListoptions = response.data;
        let object = [];
        if (typeof companyListoptions !== "undefined" && companyListoptions.length > 0) {
          companyListoptions.forEach((values) => {
            let val = { id: values.group_Id, label: values.group_Name };
            object.push(val);
          });
        }
        dispatch({ type: 'ASSOCIATE_GROUP_FETCHED_SUCCESSFULLY', payload: object});
      })
      .catch((err) => {
        console.log(err);
      });
  };
};


export const getAssocitedCompanyDropDownValue = (id) => {
  return (dispatch) => {
    api.get("/api/viewAssociatedCompaniesList/"+id)
      .then((response) => {
        let companyListoptions = response.data;
        let object = [];
        if (typeof companyListoptions !== "undefined" && companyListoptions.length > 0) {
          companyListoptions.forEach((values) => {
            let val = { id: values.id, label: values.name };
            object.push(val);
          });
        }
        dispatch({ type: 'ASSOCIATED_COMPANY_FETCHED_FOR_DROPDOWN', payload: object});
      })
      .catch((err) => {
        console.log(err);
      });
  };
};


export const disassociateCompanyRow = (payload) => {
  return (dispatch) => {
    axios.post("/api/deleteCompaniesAssociationForCompany",payload)
      .then((response) => {
        dispatch({ type: 'ASSOCIATED_COMPANY_DELETED', payload: 'Row Deleted'});
        
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const disassociateGroupRow = (payload) => {
  return (dispatch) => {
    axios.post("/api/deleteGroupAssociationViaAjaxForCompany",payload)
      .then((response) => {
        dispatch({ type: 'ASSOCIATED_GROUP_DELETED', payload: response.data});
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const disassociateConsumerCompanyRow = (payload) => {
  return (dispatch) => {
    axios.post("/api/deleteConsumerCompany",payload)
      .then((response) => {
        dispatch({ type: 'ASSOCIATED_CONSUMER_COMPANY_DELETED', payload: 'Row Deleted'});
        
      })
      .catch((err) => {
        console.log(err);
      });
  };
};