
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React ,{ useState ,useEffect} from 'react'
import { Popover, Card, Button, OverlayTrigger } from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { ImInfo } from "react-icons/im";
import { useSelector ,useDispatch} from 'react-redux';
import XsmUserDetails from '_Commons/WorkItemBoard/xsmEditRequestedBy.js';
import moment from 'moment';
import Cookies from "universal-cookie";
import datetimeConvertor from "../../../../helpers/ISO8601converter.js";
import { useParams } from 'react-router';

import CloseBtnRightPanel from "../../../common/closeBtnRightPanel";
import { getTargetSlaForAudit } from '../../../../actions/sla/quickViewAction.js';
const cookies = new Cookies();
let timezone = cookies.get("gph");
if (timezone) timezone = timezone.replace("s:", "");
if (timezone) timezone = timezone.substring(0, timezone.lastIndexOf("."));
timezone = timezone.split("~");
timezone = timezone[3];

let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));

dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

let cookiesArray = cookies.get('gph');
if (cookiesArray) cookiesArray = cookiesArray.replace('s:', '');
if (cookiesArray) cookiesArray = cookiesArray.substring(0, cookiesArray.lastIndexOf('.'));
cookiesArray = cookiesArray.split("~");
let langDir = cookiesArray[66];

function HolidaySlaAuditLogs(props) {
  const [key, setKey] = useState(1);
  const [combinedAuditData, setCombinedAuditData] = useState([]);
  const auditLogsData = useSelector((state) => state.targetSlaAcitvity);

  const windowSize = useSelector((state) => state.windowSize.width);
  const translator = useSelector((state) => state.spcmReducer.tr);
  const showLoader = useSelector((state) => state.commonLoader.isLoading);
  const dispatch = useDispatch();
  const params = useParams();

  let sourceId = props.ciId;
  let module = "HOLIDAY SCHEDULE";

  console.log("HolidayId",sourceId)
  useEffect(() => {
    dispatch(getTargetSlaForAudit(sourceId, module));
  }, []);
  useEffect(() => {
    if (auditLogsData.length > 0) {
      setCombinedAuditData ([...auditLogsData]);
    } 
  }, [auditLogsData])  

	const renderCombinedData = (collectedData) => {
    return (
      <div id="brkAudLogcomm" className="actCommts">
        {collectedData.map((comment, childKey) => {
			function epochToCurrentTime(epochSeconds) {
				const milliseconds = epochSeconds * 1000;
				const date = new Date(milliseconds);
				return date.toLocaleString("en-US", { timeZone: timezone });
			}
			let actualDate = epochToCurrentTime(comment?.createdOn)
			let newDate = moment(actualDate).format(jwtdf + " HH:mm:ss");
            let actDateTimeFormat = jwtdf + " hh:mm A";
            let myDateObj,
              endDate,
              endDateStr,
              timeago,
              startTime,
              duration,
              hours,
              isoDate;
            isoDate = datetimeConvertor(newDate, jwtdf + " HH:mm:ss");
			myDateObj = moment(isoDate).format(actDateTimeFormat);
            endDate = moment(myDateObj, actDateTimeFormat);
            endDate = moment(myDateObj, actDateTimeFormat);
			endDateStr = endDate._i;
            startTime = moment(
				new Date().toLocaleString("en-US", { timeZone: timezone })
            );
            timeago = moment(endDateStr, actDateTimeFormat).from(startTime);
            duration = moment.duration(startTime.diff(endDate));
            hours = duration.asHours();
          const tooltip = (
            <Popover className="userInfoPopup">
              <XsmUserDetails
                userId={parseInt(comment.createdBy, 10)}
                translator={translator}
                isHeadingVisible={false}
                isPopup={true}
              />
            </Popover>
          );
          return (
            <Card className="actlogCrd" bsPrefix=" " key={childKey}>
              <div className="circleTxt">
                <div className="subhed">
                  { 
                    <OverlayTrigger
                      trigger="click"
                      rootClose
                      placement={windowSize < 768 ? "auto-start" : (langDir === "rtl" ? "right-start" : "left-start")
}
                      overlay={tooltip}
                    >
                      <span className="circleNa" title={comment.createdByName}>
                        {comment?.createdByName} <ImInfo />
                      </span>
                    </OverlayTrigger>
                  }
                  <span
                    className="margin-l-5 timeao"
                    title={hours >= 24 ? "" : endDateStr}
                  >
                    {hours >= 24 ? endDateStr : timeago}
                  </span>
                </div>
                      
                      <div className="desFld">
                          {comment?.auditField == "Created" ?<div className="fw-300 cTxt">Created</div> :
                  <div title="Field Name" className="fenme">
                    {comment?.auditField}
                  </div>}
                  
                  {comment?.auditField == "Created"?"":<>
                  <div title="New Value" className="nwnme commn">
                    {comment?.to}
                  </div>
                  <div title="Old Value" className="oldnme commn">
                    {comment?.from}
                  </div></>}
                </div>
              </div>
              
            </Card>
          );
		})
			}
      </div>
    );
  };

  return (
    <>
      <CloseBtnRightPanel showRightSide={props.showRightSide} />
      <div className="rPageHeading">
        <div className="offNam margin-t-5 margin-b-5">
          {translator["Activity Details"]}
        </div>
      </div>
      <div className="rBoxGap">
        <div className="rBoxTabs">
          <Tabs
            activeKey={key}
            onSelect={(k) => setKey(k)}
            id="tabId"
          >
            <Tab eventKey={1} title={translator["Audit Log"]}>
                          <div className="actCommts">
                              
                                  {combinedAuditData?.length > 0
                                  ?
                                  renderCombinedData(combinedAuditData)
								  :
								  "No Data Found"
							 }
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default HolidaySlaAuditLogs;
