
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import { useGetAuditLogsQuery } from "../../../../../../reducers/governance/meeting/actionItem";
import { getCookiesValuesByPositions } from "../../../../Helper";

import AuditLogs from "./AuditHistory";
import ListLoader from "../../../../../common/loaders/ListLoader";
import WorkNotes from "../WorkNotes/add-edit";
import { IoClose } from "react-icons/io5";
const ActivityDeatils = ({ actData }) => {
  const cookieValues = getCookiesValuesByPositions([2, 3]);
  const dateformat = cookieValues?.value1;
  const timezone = cookieValues?.value2;

  const jwtdf = dateformat?.slice(0, 10)?.toUpperCase();

  const actDateTimeFormat = jwtdf + " hh:mm A";

  const [tabKey, setTabKey] = useState(1);
  const [orderValue, setOrderValue] = useState("desc");

  const actionitemCode = actData[0]?.actionitemCode;

  const { data: auditData = [], isFetching } = useGetAuditLogsQuery(
    { code: actionitemCode, orderValue: orderValue },
    {
      skip: !actionitemCode,
    }
  );

  const handleSelect = (tabKey) => {
    setTabKey(tabKey);
  };

  return (
    <div className="panel-body attachment-body">
      <div className="custom-container-fluid">
        <div className="stickyArea rBoxStyle">
          <div className="rPageHeading d-flex p-3">
            <div className="flex-grow-1 offNam mw-100">Activity Details</div>
            <div>
              <IoClose />
            </div>
          </div>
            <div className="rBoxGap">
              <div className="rBoxTabs">
                <Tabs activeKey={tabKey} onSelect={handleSelect} id="tabId">
                  <Tab eventKey={1} title="Comments">
                    <WorkNotes
                      actionitemCode={actionitemCode}
                      actData={actData}
                    />
                  </Tab>
                  <Tab eventKey={6} title="Audit Log">
                    {isFetching ? (
                      <ListLoader />
                    ) : (
                      <AuditLogs data={auditData} setOrderValue={setOrderValue}/>
                    )}
                  </Tab>
                </Tabs>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityDeatils;
