
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef } from "react";
import { ListGroup, Row, Col, Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { PiPencilSimpleBold } from "react-icons/pi";
import { IoClose } from "react-icons/io5";
import { MyContext } from '_MyContext';

const ForumShortDescriptionList = ({ rowData, setIsEditDetails }) => {
  const translator = useSelector((state) => state.spcmReducer.tr);
  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const editButton = useRef(null);

  useEffect(() => {
      context.keyDownHandler(closeButton.current,editButton.current,"","");
  }, []);

  return (
    <div className="stickyArea rBoxStyle">
      <Row>
        <Col xs={12}>
          <div className="rPageHeadActBtn">
            <ul>
              <li>
                <Link
                  to={`/editforum/${rowData?.forumId}`}
                  title={translator["Edit"]}
                  ref={editButton}
                >
                  <PiPencilSimpleBold />
                </Link>
              </li>
              <li>
                <Link
                  to="javascript:void(0)"
                  title={translator["Minimize the details panel"]}
                  bsClass=""
                  onClick={() => setIsEditDetails(false)}
                  ref={closeButton}
                >
                  <IoClose />
                </Link>
              </li>
            </ul>
          </div>
          <div className="rPageHeading py-3">
            {rowData?.forumName}
            {/* <div className="offNam">--</div> */}
          </div>
        </Col>
      </Row>
      <div className="rBoxGap">
        <div className="rBoxTabs hideTabNav">
          <Tabs id="tabId">
            <Tab eventKey={1} title="Forum Details">
              <ListGroup bsPrefix=" " bsClass="">
                <ListGroup.Item
                  bsPrefix=" "
                  bsClass=""
                  className="rwTable"
                  style={{ "border-top": "none" }}
                >
                  <span className="rwKey">{translator["Name"]} </span>
                  <span className="rwVal">{rowData?.forumName}</span>
                </ListGroup.Item>
                <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                  <span className="rwKey">{translator["Company"]} </span>
                  <span className="rwVal">{rowData?.customerName}</span>
                </ListGroup.Item>
                <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                  <span className="rwKey">
                    {translator["Track"] || "Track"}
                  </span>
                  <span className="rwVal">{rowData?.trackName}</span>
                </ListGroup.Item>
                <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                  <span className="rwKey">
                    {translator["SubTrack"] || "SubTrack"}{" "}
                  </span>
                  <span className="rwVal">{rowData?.subTrackName}</span>
                </ListGroup.Item>
                <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                  <span className="rwKey">
                    {translator["Region"] || "Region"}{" "}
                  </span>
                  <span className="rwVal">{rowData?.regionName}</span>
                </ListGroup.Item>
                <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                  <span className="rwKey">
                    {translator["Status"] || "Status"}{" "}
                  </span>
                  <span className="rwVal">{rowData?.status}</span>
                </ListGroup.Item>
              </ListGroup>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default ForumShortDescriptionList;
