
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
//const getCategoryList = 'https://xsm-f.mybluemix.net/getOfferingsForListRestifySelfService/?categoryId=';
//let cmdbQuickViewListURL='http://cmdb-test.mybluemix.net/api/config_items/ ';
import { GLOBAL } from '_Globals';

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

export function loadSlaDefListSuccess(slaDefList) {
	return {
		type: 'LOAD_SLADEF_LIST_SUCCESS',
		slaDefList
	};
}

export function loadSlaDefList() {
	return (dispatch) => {
		api.get(GLOBAL.slaDefViewUrl)
			.then((slaDefList) => {
				if (!slaDefList) {
					throw Error(slaDefList.statusText);
				}
				return slaDefList;
			})
			.then((slaDefList) => {
				dispatch(loadSlaDefListSuccess(slaDefList))
			})
			.catch((err) => {
				console.log("error in loadSlaDefListSuccess")
				console.log(err);
			})
	};
}

export function loadSlaDefListOnPagination(searchParams,status,target,module) {
	let statusVal = status.length == 0 ? "Active" : status.toString();
	let targetVal=target.toString();
	let moduleVal=module.toString();
	let searchKeyArr=[];
	let searchValueArr=[];
	let patternArr=[];

	if(statusVal.length!=0){
		searchKeyArr.push("slaStatus");
		searchValueArr.push(statusVal);
		patternArr.push("in");
	}
	if(target.length!=0){
		searchKeyArr.push("goalType");
		searchValueArr.push(targetVal);
		patternArr.push("in");
	}
	if(module.length!=0){
		searchKeyArr.push("moduleName");
		searchValueArr.push(moduleVal);
		patternArr.push("in");
	}
	if(searchKeyArr.length!=0){
		searchParams.searchByList = searchParams.searchByList != "" ? searchParams.searchByList + "," + searchKeyArr.join(",") : searchKeyArr.join(",");
		searchParams.multipleValueList = searchParams.multipleValueList != "" ? searchParams.multipleValueList + "|" + searchValueArr.join("|") : searchValueArr.join("|");
		searchParams.patternList = searchParams.patternList != "" ? searchParams.patternList + "," + patternArr.join(","): patternArr.join(",");
		
	}

	return (dispatch) => {
		dispatch({ type: 'LOADER_IN_PROGRESS_SLA_DEFINATIONS' });
		api.post(GLOBAL.slaDefViewUrl, searchParams)
			.then((slaDefList) => {
				if (!slaDefList) {
					//dispatch(loadBreakFixFailure(breakFix.statusText))
					throw Error(slaDefList.statusText);
				}
				return slaDefList;
			})
			.then((slaDefList) => {
				dispatch(loadSlaDefListSuccess(slaDefList));
				dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_SLA_DEFINATIONS' });
			})
			.catch((err) => {
				dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_SLA_DEFINATIONS' });
			});
	};
}

export function loadSlaWorkdaySuccess(slaWorkdayList) {
	return {
		type: 'LOAD_SLAWORKDAY_LIST_SUCCESS',
		slaWorkdayList
	};
}

export function loadSlaWorkdayList() {
	return (dispatch) => {
		api.get(GLOBAL.slaWorkdayListUrl)
			.then((slaWorkdayList) => {
				if (!slaWorkdayList) {
					throw Error(slaWorkdayList.statusText);
				}
				return slaWorkdayList;
			})
			.then((slaWorkdayList) => {
				dispatch(loadSlaWorkdaySuccess(slaWorkdayList))
			})
			.catch((err) => {
				console.log("error in loadSlaWorkdayList")
				console.log(err);
			});
	};
}

export function loadSlaWorkdayListOnPagination(searchParams,status,timeZone) {
	let statusVal = status.length == 0 ? "Active" : status.toString();
	let timeZoneVal=timeZone.toString();
	let searchKeyArr=[];
	let searchValueArr=[];
	let patternArr=[];

	if(statusVal.length!=0){
		searchKeyArr.push("status");
		searchValueArr.push(statusVal);
		patternArr.push("in");
	}

	if(timeZone.length!=0){
		searchKeyArr.push("workdayScheduleTimeZoneLabel");
		searchValueArr.push(timeZoneVal);
		patternArr.push("in");
	}
	
	if(searchKeyArr.length!=0){
		searchParams.searchByList = searchParams.searchByList != "" ? searchParams.searchByList + "," + searchKeyArr.join(",") : searchKeyArr.join(",");
		searchParams.multipleValueList = searchParams.multipleValueList != "" ? searchParams.multipleValueList + "|" + searchValueArr.join("|") : searchValueArr.join("|");
		searchParams.patternList = searchParams.patternList != "" ? searchParams.patternList + "," + patternArr.join(","): patternArr.join(",");
	}

	return (dispatch) => {
		dispatch({ type: 'LOADER_IN_PROGRESS_SLA_WORK_SCHEDULE' });
		api.post(GLOBAL.slaWorkdayListUrl,searchParams)
			.then((slaWorkdayList) => {
				if (!slaWorkdayList) {
					throw Error(slaWorkdayList.statusText);
				}
				dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_WORK_SCHEDULE' });
				return slaWorkdayList;
			})
			.then((slaWorkdayList) => {
				dispatch(loadSlaWorkdaySuccess(slaWorkdayList));
				dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_WORK_SCHEDULE' });
			})
			.catch((err) => {
				dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_WORK_SCHEDULE' });
			});
	};
}



export function loadSlaHolidaySuccess(slaHolidayList) {
	return {
		type: 'LOAD_SLAHOLIDAY_LIST_SUCCESS',
		slaHolidayList
	};
}

export function loadSlaHolidayList() {
	return (dispatch) => {
		api.get(GLOBAL.slaHolidayListUrl)
			.then((slaHolidayList) => {
				if (!slaHolidayList) {
					throw Error(slaHolidayList.statusText);
				}
				return slaHolidayList;
			})
			.then((slaHolidayList) => {
				dispatch(loadSlaHolidaySuccess(slaHolidayList));
			})
			.catch((err) => {
				console.log("error in loadSlaHolidayList");
				console.log(err);
			});
	};
}
export function loadSlaHolidayListOnPagination(searchParams, status) {
	let statusVal = status.length == 0 ? "Active" : status.toString();
	let searchKeyArr=[];
	let searchValueArr=[];
	let patternArr=[];

	if(statusVal.length!=0){
		searchKeyArr.push("status");
		searchValueArr.push(statusVal);
		patternArr.push("in");
	}
	
	if(searchKeyArr.length!=0){
		searchParams.searchByList = searchParams.searchByList != "" ? searchParams.searchByList + "," + searchKeyArr.join(",") : searchKeyArr.join(",");
		searchParams.multipleValueList = searchParams.multipleValueList != "" ? searchParams.multipleValueList + "|" + searchValueArr.join("|") : searchValueArr.join("|");
		searchParams.patternList = searchParams.patternList != "" ? searchParams.patternList + "," + patternArr.join(","): patternArr.join(",");
	}
	return (dispatch) => {
		dispatch({ type: 'LOADER_IN_PROGRESS_SLA_HOLIDAY_SCHEDULE' });
		api.post(GLOBAL.slaHolidayListUrl ,searchParams)
			.then((slaHolidayList) => {
				if (!slaHolidayList) {
					throw Error(slaHolidayList.statusText);
				}
				dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_HOLIDAY_SCHEDULE' });
				return slaHolidayList;
			})
			.then((slaHolidayList) => {
				dispatch(loadSlaHolidaySuccess(slaHolidayList));
				dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_HOLIDAY_SCHEDULE' });
			})
			.catch((err) => {
				dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_HOLIDAY_SCHEDULE' });
			});
	};
}

/*export function loadCmdbItemFilterList(searchParams){
	return(dispatch) =>{
		// console.log(searchParams);
		let params = {};
		if(!_.isEmpty(searchParams)) {params = {params:searchParams};} //lodash isEmpty checks for nulls too
				axios.get(GLOBAL.cmdbQuickViewListURL,params)
					.then((cmdbItemList)=>{
						if(!cmdbItemList){
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							throw Error(cmdbItemList.statusText);
						}
						return cmdbItemList;
					})
					.then((cmdbItemList) =>{
						dispatch(loadCmdbItemListSuccess(cmdbItemList))
					})
					.catch((err) => {
						console.log(err);
					});
					//console.log("not calling loadBreakFixQuickView");

	};
}

export function addStatusToSelectedStatusList(statusId){
	return{
		type: 'ADD_TO_SELECTED_STATUS_LIST',
		payload: statusId
	};
}

export function removeStatusToSelectedStatusList(statusId){
	return{
		type: 'REMOVE_FROM_SELECTED_STATUS_LIST',
		payload: statusId
	};
}

export function addCompanyToSelectedCompanyList(companyId){
	return{
		type: 'ADD_TO_SELECTED_COMPANY_LIST',
		payload: companyId
	};
}

export function removeCompanyToSelectedCompanyList(companyId){
	return{
		type: 'REMOVE_FROM_SELECTED_COMPANY_LIST',
		payload: companyId
	};
}

export function addClassToSelectedClassList(classId){
	return{
		type: 'ADD_TO_SELECTED_CLASS_LIST',
		payload: classId
	};
}

export function removeClassToSelectedClassList(classId){
	return{
		type: 'REMOVE_FROM_SELECTED_CLASS_LIST',
		payload: classId
	};
}*/

//=======
export function loadSlaHolidayEntriesSuccess(slaHolidayEntries) {
	//alert('in success')
	return {
		type: 'LOAD_SLAHOLIDAYENTRIES_SUCCESS',
		slaHolidayEntries
	};
}

export function loadSlaHolidayEntries(slaId) {
	//alert('action called and parameter is '+breakFixId)
	return (dispatch) => {
		dispatch({ type: 'FETCHING_DETAILS_IN_PROGRESS' });
		//alert("loadSlaHolidayEntries"+breakFixId);
		if (slaId == undefined || slaId == "") {
			//console.log("slaId not found");
		} else {
			//alert("my url is "+GLOBAL.breakFixEditUrl+breakFixId);
			api.get(GLOBAL.slaHolidatEntriesGetUrl + slaId)
				.then((slaHolidayEntries) => {
					if (!slaHolidayEntries) {
						throw Error(slaHolidayEntries.statusText);
					}
					return slaHolidayEntries;
				})
				.then((slaHolidayEntries) => {//alert('yes it is')
					dispatch({ type: 'DETAILS_FETCHED_SUCCESSFULLY' });
					dispatch(loadSlaHolidayEntriesSuccess(slaHolidayEntries))
				})
				.catch((err) => {
					console.log(err);
				});
			//console.log("not calling loadSlaDefEditDetails");
		}
	};
}
//=======
export function loadSlaDefActiveMandatorySuccess(slaDefActiveMandatory) {
	return {
		type: 'LOAD_SLADEFMANDATORY_ACTIVE_SUCCESS',
		slaDefActiveMandatory
	};
}

export function loadSlaDefActiveMandatory(slaId) {
	return (dispatch) => {
		axios.get(GLOBAL.slaDefMandatoryActiveUrl + slaId)
			.then((slaDefActiveMandatory) => {
				if (!slaDefActiveMandatory) {
					throw Error(slaDefActiveMandatory.statusText);
				}
				return slaDefActiveMandatory;
			})
			.then((slaDefActiveMandatory) => {
				dispatch(loadSlaDefActiveMandatorySuccess(slaDefActiveMandatory))
			})
			.catch((err) => {
				console.log("error in loadSlaDefActiveMandatory")
				console.log(err);
			});
	};
}

//===
//=======
export function loadSlaModuleStatusSuccess(slaModuleStatus) {
	return {
		type: 'LOAD_SLAMODULE_STATUS_SUCCESS',
		slaModuleStatus
	};
}

export function loadSlaModuleStatus(module) {
	return (dispatch) => {
		axios.get(GLOBAL.slaModuleStatusUrl + module)
			.then((slaModuleStatus) => {
				if (!slaModuleStatus) {
					throw Error(slaModuleStatus.statusText);
				}
				return slaModuleStatus;
			})
			.then((slaModuleStatus) => {
				dispatch(loadSlaModuleStatusSuccess(slaModuleStatus))
			})
			.catch((err) => {
				console.log("error in loadSlaModuleStatus")
				console.log(err);
			});
	};
}
export function loadamsModuleStatus(moduleName,selectedField){
	return (dispatch) => {
		axios.get(GLOBAL.amsModuleStatusUrl+"?module="+moduleName+"&field1Name="+selectedField).then((response)=>{
			dispatch(loadSlaModuleStatusSuccess(response));
		}).catch((err) => {
			console.log("error in loadamsModuleStatus() : ",err);
		});
	};
}

export const getTargetSlaForAudit = (sourceId,module)=>{
	return (dispatch) => {
		  api.get(`/api/sla-audit/${sourceId}/${module}`)
		.then((response) => {
		  dispatch({ type: "TARGET_SLA_ACTIVITY_DETAILS_FETCHED_SUCCESSFULLY", payload: response.data.auditData })
			  })
			  .catch((err) => {
				  dispatch({type:"",failureMessage:err})
		          console.log(err);
			  });
	  };
	}
//===
