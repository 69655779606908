
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState, useEffect } from 'react';
import { Row, Col, Alert, Form } from 'react-bootstrap';
import { getCompanyList } from '_Actions/spcmActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadCategoryDropdown, CategoriesByCompanyId, loadStatusDropdown } from '_Actions/categoryBoard/quickViewAction.js';
import { _inputField, _dropDown } from '../../common/ReduxFormFields/CategoryBoard/formFields';
import { businessFunctionByCompanyId, resetParentFunction } from '../../../actions/businessFunction/businessFunctionAction';
import { change, Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

const validate = (values, props) => {
  const errors = {};
  if (!values.COMPANY_ID)
    errors.COMPANY_ID = "Select Company";

  if (!values.CATEGORY_NAME)
    errors.CATEGORY_NAME = "Required";
  else if (values.CATEGORY_NAME.trim() === "")
    errors.CATEGORY_NAME = 'Enter CategoryName';

  if (values.SUB_CATEGORY_NAME && values.SUB_CATEGORY_NAME.trim() === "")
    errors.SUB_CATEGORY_NAME = 'Enter SubCategoryName';

  if (!values.CATEGORY_ID)
    errors.CATEGORY_ID = "Required";

  return errors;
};

const CreateBusinessFunctionForm = (props) => {
  const [status, setStatus] = useState('');
  const dispatch = useDispatch();
  useEffect(() => {
    props.getCompanyList('');
    return () => {
      props.resetParentFunction();
  };
  }, []);

  const onCompanyChange = (e) => {
    props.businessFunctionByCompanyId(e.target.value);
    dispatch({ type: 'PARENTFUNCTION_BY_COMPANY_ID_REFRESH' });
    console.log("this.props.CategoriesByCompanyIdDropdown;",props.parentFunctionByCompanyIdDropdown)
  };

  const countCharacters = (e) => {
    if (e.target.value.length > 50) {
      e.preventDefault();
    }
  };

  const { error } = props;
  console.log("Options:", props.parentFunctionByCompanyIdDropdown.data);
  return (
    <div>
      {/* {error == null ? null : <Alert variant="danger" className="errorMessageSec"><p>{error}</p></Alert>} */}
      <div className="borderForm bFormDiv">
        <form>
          <Row>
          <Col md={6}>
                <Form.Group className='form-group'>
                  <Form.Label bsClass="">
                    <span className="rStar"></span>
                    {props.translator["Company"]}
                  </Form.Label>
                  <Field
                component={_dropDown}
                name="COMPANY_ID"
                onChange={onCompanyChange}
                options={props.spcmReducer.companyList || []}
                className="form-control"
              />
                </Form.Group>
              </Col>
            <Col md={6}>
            <Form.Group className='form-group'>
                  <Form.Label bsClass="">
                    <span className=""></span>
                    {props.translator["Parent Function"]}
                  </Form.Label>
              <Field
                component={_dropDown}
                name="PARENT_FUNCTION"
                options={props.parentFunctionByCompanyIdDropdown.data || []}
                className="form-control"
              />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
            <Form.Group className='form-group'>
                  <Form.Label bsClass="">
                    <span className="rStar"></span>
                    {props.translator["Name"]}
                  </Form.Label>
              <Field
                name="CATEGORY_NAME"
                className="form-control"
                component={_inputField}
                onPaste={(e) => {
                  countCharacters(e);
                }}
                onChange={(e) => {
                  countCharacters(e);
                }}
              />
              <span className="f-size-10">
                {props.translator["Max Length in 50"]}
              </span>
              </Form.Group>
            </Col>
            <Col md={6}>
            <Form.Group className='form-group'>
                  <Form.Label bsClass="">
                    <span className=""></span>
                    {props.translator["Long Name"]}
                  </Form.Label>
              <Field
                name="SUB_CATEGORY_NAME"
                className="form-control"
                component={_inputField}
                onPaste={(e) => {
                  countCharacters(e);
                }}
                onChange={(e) => {
                  countCharacters(e);
                }}
              />
              <span className="f-size-10">
                {props.translator["Max Length in 50"]}
              </span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
            <Form.Group className='form-group'>
                  <Form.Label bsClass="">
                    <span className="rStar"></span>
                    {props.translator["Status"]}
                  </Form.Label>
                  <Field
                name="STATUS"
                component="select"
                className="form-control"
              >
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </Field>
              </Form.Group>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = ({ spcmReducer, parentFunctionByCompanyIdDropdown }) => ({
  spcmReducer, parentFunctionByCompanyIdDropdown
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  getCompanyList,
  businessFunctionByCompanyId,
  loadStatusDropdown,
  resetParentFunction,
  change
}, dispatch);

const ConnectedCreateBusinessFunctionForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateBusinessFunctionForm);

export default reduxForm({
  form: 'CreateBusinessFunctionForm',
  destroyOnUnmount: false,
  validate
})(ConnectedCreateBusinessFunctionForm);
