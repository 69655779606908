
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
import axios from 'axios';
import {Button} from 'react-bootstrap';
import sanitizeHtml from 'sanitize-html';
import Iframe from 'react-iframe';
// import ChatbotPopup from '../images/chatbotPopup.jpg';
// let ChatbotPopupImg = {backgroundImage:`url(${ChatbotPopup})`};
let lucy_url = cookies.get('gph');

if (lucy_url) lucy_url = lucy_url.replace('s:', '');
if (lucy_url) lucy_url = lucy_url.substring(0, lucy_url.lastIndexOf('.'));
lucy_url = lucy_url.split("~");
lucy_url = lucy_url[31];
// console.log("lucy_url===========>" + lucy_url);

let emailAddress = cookies.get('gph');

if (emailAddress) emailAddress = emailAddress.replace('s:', '');
if (emailAddress) emailAddress = emailAddress.substring(0, emailAddress.lastIndexOf('.'));
let emailAddressArray = emailAddress.split("~");
emailAddress = emailAddressArray[29];
// console.log("emailAddress===========>" + emailAddress);

let userName = cookies.get('gph');

if (userName) userName = userName.replace('s:', '');
if (userName) userName = userName.substring(0, userName.lastIndexOf('.'));
userName = userName.split("~");
userName = userName[32];
// console.log("userName===========>" + userName);

class Chatbot extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showResults: false,
			chatbotToken: ''
		};
		this.callbackFun = this.callbackFun.bind(this);
		this.chatbotIconClick = this.chatbotIconClick.bind(this);
	}

	componentDidMount() {
		// console.log('===================================', `/api/chatbot/token?email=${emailAddress}&lucy_url=${lucy_url}&userName=${userName}`);
		axios.get(`/api/chatbot/token?email=${emailAddress}&lucy_url=${lucy_url}&userName=${userName}`)
			.then(response => {
				// console.log('response==', response);
				this.setState({ chatbotToken: response.data });
			});
	}

	chatbotIconClick() {
		this.setState({ showResults: !this.state.showResults });
	}

	callbackFun(id) {
		this.setState({ showResults: id });
	}
	
	render() {
		let lucy_url_final = lucy_url + '/t/' + this.state.chatbotToken;
		let iframe = '<Iframe class="lucyiFrame" name="lucy" title="Lucy chatbot" src=' + lucy_url_final + ' width="100%" height="100%" />';
		return (
			<div aria-label='Chatbot' role="contentinfo">
				<Button bsPrefix=' ' title={this.state.showResults ? "Close Chatbot" : "Open Chatbot"} className="chatbotIcon" onClick={this.chatbotIconClick}><div className={this.state.showResults ? "inner arrow" : "inner"} >{this.state.showResults ? <svg viewBox="0 0 444.819 444.819"><g><path d="M434.252,114.203l-21.409-21.416c-7.419-7.04-16.084-10.561-25.975-10.561c-10.095,0-18.657,3.521-25.7,10.561L222.41,231.549L83.653,92.791c-7.042-7.04-15.606-10.561-25.697-10.561c-9.896,0-18.559,3.521-25.979,10.561l-21.128,21.416C3.615,121.436,0,130.099,0,140.188c0,10.277,3.619,18.842,10.848,25.693l185.864,185.865c6.855,7.23,15.416,10.848,25.697,10.848c10.088,0,18.75-3.617,25.977-10.848l185.865-185.865c7.043-7.044,10.567-15.608,10.567-25.693C444.819,130.287,441.295,121.629,434.252,114.203z" /></g></svg> : <svg viewBox="0 0 28 32"><path d="M28 32s-4.714-1.855-8.527-3.34H3.437C1.54 28.66 0 27.026 0 25.013V3.644C0 1.633 1.54 0 3.437 0h21.125c1.898 0 3.437 1.632 3.437 3.645v18.404H28V32zm-4.139-11.982a.88.88 0 00-1.292-.105c-.03.026-3.015 2.681-8.57 2.681-5.486 0-8.517-2.636-8.571-2.684a.88.88 0 00-1.29.107 1.01 1.01 0 00-.219.708.992.992 0 00.318.664c.142.128 3.537 3.15 9.762 3.15 6.226 0 9.621-3.022 9.763-3.15a.992.992 0 00.317-.664 1.01 1.01 0 00-.218-.707z"></path></svg>} </div></Button>
				{this.state.showResults ? <Result iframeTag={iframe} fun={this.callbackFun} /> : null}
			</div>
		);
	}
}

class Result extends React.Component {
	handleClickOutside() {
		this.props.fun(false);
	}
	iframe() {
		return {
			__html: this.props.iframeTag
		};
	}
	render() {
		return (
			<div dangerouslySetInnerHTML={this.iframe()} className="chatbotPopup emdeb-responsive" />
		);
	}
}

export default Chatbot;