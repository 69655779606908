
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'

import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import { _inputField, _textArea, _dropDown, TypeaheadandDropdown } from '../formFields.js';

import {
	getCompanyData,
	getCIAssociatedCompany,
	getCIGroupData, getClassData,
	getImpactedCI, getGroupData,
	getAssociatedCompanyDataWithoutServiceSelected,
	getAssociatedCompanyListNew,
	getBreakfixGroupData, getIndividualData,
	getModuleData, getEventData, getCompanyService,
	getcompanyImpacted, getAllRuleConfigurationsData,
	resetFormDropdownValues, getGroupFunctionData
} from '../../../actions/amsActions/amsActions.js';
import { loadComponentPackage } from '../../../actions/categorylist/componentPackageAction.js';
import { connect } from 'react-redux';
import configureStore from '../../../store/configureStore.js';
import {GLOBAL} from '../../Globals.js';
const store = configureStore();

var regex= /^\d*[1-9]\d*$/;

  const validate = values => {
	let errors = {};
	if (!values.ruleName || values.ruleName.trim().length===0) {
		errors.ruleName = 'Enter Rule Name.';
	}
	if(values.ruleName && values.ruleName.length>200){
		errors.ruleName = 'Max. Length 200 characters';
	}
	if (!values.companyStatus) {
		errors.companyStatus = 'Select  Status.';
	}
	if (!values.company) {
		errors.company = 'Select Company.';
	}
	if (!values.module) {
		errors.module = 'Select Module.';
	}
	if (!values.event) {
		errors.event = 'Select Assignment For.';
	}
	if (!values.weight) {
		errors.weight = 'Enter Weight.';
	}
	if(values.weight && (parseInt(values.weight,10) <= 0 || parseInt(values.weight,10) > 500)){
		errors.weight='Please enter values between 1 and 500';
	}
	if (values.weight && !regex.test(values.weight)) {
		errors.weight = 'Invalid Weight.';
	}
	if (!values.associatedCompany) {
		errors.associatedCompany = 'Select Assignment Company.';
	}
	if (!values.group) {
		errors.group = 'Select Assignment Group.';
	  }
	if (typeof values.groupFunctionName=== 'undefined' || values.groupFunctionName === ''|| values.groupFunctionName === null) {
		errors.groupFunction = 'Select Group Function';
	}
	if(values.isContextual && (!values.groupTypeId || values.groupTypeId==="")){
		errors.groupFunction="Select Group Function";
	}else if(!values.isContextual && (!values.group || values.group==="")){
		errors.group="Select Group";
	}
	if (typeof values.contextualGroup=== 'undefined' || values.contextualGroup === ''|| values.contextualGroup === null) {
		errors.contextualGroup = 'Select Support Group';
	}
	return errors;
};

let XsmAmsCreateRulesFormTab = class XsmAmsCreateRulesFormTab extends React.Component {

	constructor(props){
		super(props);
		this.state = {
			ciNameData:[],
			ciNameObject:[],
			getCISupportGroup:false,
			moduleID:'',
			selectedCompany:[],
			typedCompany:'',
			selectedAssociatedCompany:[],
			typedAssociatedCompany:'',
			selectedGroups:[],
			selectedAlternateGroups:[],
			typedGroups:'',
			typedAlternateGroups:'',
			errorCompany:false,
			errorAssoCompany:false,
			errorGroup:false,
			errorService:false,
			companyStatus:[{field1Value:'2',field1Key:'Draft'}],
			isContextual: false,
			selectedGroupFunction: [],
			typedGroupFunction: '',
			errorGroupFunction:false,
			supportGroup: [{field1Value: 'predefined',field1Key: 'Predefined'},
			               {field1Value: 'contextual',field1Key: 'Contextual'} ],
			contextualGroup: ''
		};
		this.onCompanySelect=this.onCompanySelect.bind(this);
		this.setCompanyError=this.setCompanyError.bind(this);
		this.onCrossClickCompany=this.onCrossClickCompany.bind(this);
		this.onCompanyInputChange=this.onCompanyInputChange.bind(this);
		this.onAssoCompanySelect=this.onAssoCompanySelect.bind(this);
		this.setAssoCompanyError=this.setAssoCompanyError.bind(this);
		this.onCrossClickAssoCompany=this.onCrossClickAssoCompany.bind(this);
        this.onAssoCompanyChange=this.onAssoCompanyChange.bind(this); 
		this.onGroupSelect=this.onGroupSelect.bind(this);
		this.setGroupError=this.setGroupError.bind(this);		
		this.onCrossClickGroup=this.onCrossClickGroup.bind(this);
        this.onGroupChange=this.onGroupChange.bind(this);
		this.setErrorInFields = this.setErrorInFields.bind(this);
		this.onGroupFunctionChange = this.onGroupFunctionChange.bind(this);
		this.onGroupFunctionSelect = this.onGroupFunctionSelect.bind(this);
		this.onCrossClickGroupFunction = this.onCrossClickGroupFunction.bind(this);
		this.setGroupFunctionError = this.setGroupFunctionError.bind(this);
		this.onSupportGroupChange = this.onSupportGroupChange.bind(this);
		this.onAlternateGroupSelect=this.onAlternateGroupSelect.bind(this);
		this.onAltGroupChange = this.onAltGroupChange.bind(this);
		this.onCrossClickAltGroup=this.onCrossClickAltGroup.bind(this);
	}
	setErrorInFields(value,fieldName){
		if(fieldName==='Company'){
             this.setState({errorCompany:value}); 
		}else if(fieldName==='AssociatedCompany'){
			 this.setState({errorAssoCompany:value}); 
		}else if(fieldName==='Group'){
			 this.setState({errorGroup:value}); 
		}else if(fieldName==='Service'){
			this.setState({errorService:value}); 
		} else if(fieldName==='GroupFunction'){
			this.setState({errorGroupFunction:value})
		}
	}
	onCompanySelect(selectedCompany){
         if(selectedCompany.length>0){
			this.props.getAssociatedCompanyListNew(selectedCompany[0].value);
			this.props.dispatch(change("xsmAmsCreateRulesForm", "companyName", selectedCompany[0].label));
			this.props.dispatch(change("xsmAmsCreateRulesForm","company",selectedCompany[0].value));
			this.state.typedCompany='';
			this.onCrossClickGroupFunction();
		 }else{
            this.props.dispatch(change("xsmAmsCreateRulesForm", "companyName", ''));
			this.props.dispatch(change("xsmAmsCreateRulesForm","company",''));
		 }
		 let typeaheadData=[];
		 this.props.dispatch({type:'GROUP_LIST_TYPE',typeaheadData});
		 this.props.dispatch({type:'ASSOCIATED_COMPANY_LIST_TYPE',typeaheadData});
		 this.props.dispatch(change("xsmAmsCreateRulesForm","associatedCompany",''));
		 this.props.dispatch(change("xsmAmsCreateRulesForm","associatedCompanyName",''));
		 this.props.dispatch(change("xsmAmsCreateRulesForm","group",''));
		 this.props.dispatch(change("xsmAmsCreateRulesForm","groupName",''));
		 this.props.dispatch(change("xsmAmsCreateRulesForm","alternateGroup",''));
		 this.props.dispatch(change("xsmAmsCreateRulesForm","alternateGroupName",''));
		 /*this.state.typedServices=*/this.state.typedAssociatedCompany=this.state.typedGroups=typeaheadData;
		 this.setState({selectedCompany: selectedCompany, /*selectedServices:[],*/ selectedGroups:[],selectedAssociatedCompany:[], selectedAlternateGroups: [], typedAlternateGroups: '' });
	}
	setCompanyError(){
       if(this.state.selectedCompany.length===0){
           this.setErrorInFields(true,'Company');
	   }else{
		   this.setErrorInFields(false,'Company');
	   }
	}
	onCrossClickCompany(){
		this.props.dispatch(change("xsmAmsCreateRulesForm", "companyName", ''));
		this.props.dispatch(change("xsmAmsCreateRulesForm","company",''));
		this.props.dispatch(change("xsmAmsCreateRulesForm","associatedCompany",''));
		this.props.dispatch(change("xsmAmsCreateRulesForm","associatedCompanyName",''));
		this.props.dispatch(change("xsmAmsCreateRulesForm","alternateGroup",''));
		this.props.dispatch(change("xsmAmsCreateRulesForm","alternateGroupName",''));
		this.setState({selectedCompany:[],typedCompany:'',selectedGroups:[],typedGroups:'',selectedAssociatedCompany:[],typedAssociatedCompany:'', selectedAlternateGroups: [], typedAlternateGroups: '' });
		let typeaheadData=[];
		this.props.dispatch({type:'ASSOCIATED_COMPANY_LIST_TYPE',typeaheadData});
		this.props.dispatch({type:'GROUP_LIST_TYPE',typeaheadData});
		this.onCrossClickGroupFunction();
	}
	onCompanyInputChange(value){
		this.props.dispatch(change("xsmAmsCreateRulesForm", "companyName", ''));
		this.props.dispatch(change("xsmAmsCreateRulesForm","company",''));
		this.props.dispatch(change("xsmAmsCreateRulesForm","associatedCompany",''));
		this.props.dispatch(change("xsmAmsCreateRulesForm","associatedCompanyName",''));
		this.props.dispatch(change("xsmAmsCreateRulesForm","alternateGroup",''));
		this.props.dispatch(change("xsmAmsCreateRulesForm","alternateGroupName",''));
		this.setState({typedCompany:value,/*selectedServices:[], */ selectedGroups:[],selectedAssociatedCompany:[],typedGroups:'',typedAssociatedCompany:'', selectedAlternateGroups: [], typedAlternateGroups: '' });
		let typeaheadData=[];
		this.props.dispatch({type:'ASSOCIATED_COMPANY_LIST_TYPE',typeaheadData});
		this.props.dispatch({type:'GROUP_LIST_TYPE',typeaheadData});
	}
	onAssoCompanySelect(selectedAssociatedCompany){
       if(selectedAssociatedCompany.length>0){
		this.state.getCISupportGroup ? this.props.getCIGroupData(selectedAssociatedCompany[0].value,'AMScreate') : this.props.getGroupData(selectedAssociatedCompany[0].value,'AMScreate');
		this.state.typedAssociatedCompany='';
		this.props.dispatch(change("xsmAmsCreateRulesForm", "associatedCompanyName", selectedAssociatedCompany[0].label));
		this.props.dispatch(change("xsmAmsCreateRulesForm", "associatedCompany", selectedAssociatedCompany[0].value));
		this.props.getGroupFunctionData(selectedAssociatedCompany[0].value);	
		this.onCrossClickGroupFunction();
	   }else{
        this.props.dispatch(change("xsmAmsCreateRulesForm", "associatedCompanyName", ''));
		this.props.dispatch(change("xsmAmsCreateRulesForm", "associatedCompany", ''));
	   }
	    this.state.typedGroups=[];
	    this.setState({selectedAssociatedCompany:selectedAssociatedCompany,selectedGroups:[], selectedAlternateGroups: [], typedAlternateGroups: '' });
	    this.props.dispatch(change("xsmAmsCreateRulesForm", "groupName", "")); 
		this.props.dispatch(change("xsmAmsCreateRulesForm", "group", ""));
		this.props.dispatch(change("xsmAmsCreateRulesForm","alternateGroup",''));
		 this.props.dispatch(change("xsmAmsCreateRulesForm","alternateGroupName",''));
		
	} 
	setAssoCompanyError(){
       if(this.state.selectedAssociatedCompany.length===0){
          this.setErrorInFields(true,'AssociatedCompany');
	   }else{
		  this.setErrorInFields(false,'AssociatedCompany');
	   }
	}
	onCrossClickAssoCompany(){
		this.props.dispatch(change("xsmAmsCreateRulesForm","associatedCompany",''));
		this.props.dispatch(change("xsmAmsCreateRulesForm","associatedCompanyName",''));
		this.props.dispatch(change("xsmAmsCreateRulesForm","group",''));
		this.props.dispatch(change("xsmAmsCreateRulesForm","groupName",''));
		this.props.dispatch(change("xsmAmsCreateRulesForm","alternateGroup",''));
		 this.props.dispatch(change("xsmAmsCreateRulesForm","alternateGroupName",''));
		this.setState({selectedAssociatedCompany:[],typedAssociatedCompany:'',selectedGroups:[],typedGroups:[], selectedAlternateGroups: [], typedAlternateGroups: '' });
		let typeaheadData=[];
		this.props.dispatch({type:'GROUP_LIST_TYPE',typeaheadData});
		this.onCrossClickGroupFunction();
	}
	onAssoCompanyChange(value){
		this.props.dispatch(change("xsmAmsCreateRulesForm","alternateGroup",''));
		this.props.dispatch(change("xsmAmsCreateRulesForm","alternateGroupName",''));
		this.setState({typedAssociatedCompany:value,selectedGroups:[],typedGroups:'', selectedAlternateGroups: [], typedAlternateGroups: '' });
		let typeaheadData=[];
		this.props.dispatch({type:'GROUP_LIST_TYPE',typeaheadData});
	}
	onGroupSelect(selectedGroups) {
		if (selectedGroups.length > 0) {
			this.props.getIndividualData(selectedGroups[0].value, this.props.associatedCompIdParam);
			this.props.dispatch(change("xsmAmsCreateRulesForm", "groupName", selectedGroups[0].label));
			this.props.dispatch(change("xsmAmsCreateRulesForm", "group", selectedGroups[0].value));
			this.state.typedGroups = '';
			GLOBAL.isExternalAmsCreate = selectedGroups[0].isExternal;
		} else {
			this.props.dispatch(change("xsmAmsCreateRulesForm", "groupName", ''));
			this.props.dispatch(change("xsmAmsCreateRulesForm", "group", ''));
			GLOBAL.isExternalAmsCreate = "";
		}
		this.setState({ selectedGroups: selectedGroups });
	}
	setGroupError(){
       if(this.state.selectedGroups.length===0){
		  this.setErrorInFields(true,'Group');
	   }else{
		  this.setErrorInFields(false,'Group');
	   }
	}
	onCrossClickGroup(){
        this.props.dispatch(change("xsmAmsCreateRulesForm","group",''));
        this.props.dispatch(change("xsmAmsCreateRulesForm","groupName",''));
		this.setState({selectedGroups:[],typedGroups:''});
	}
	onGroupChange(value){
		this.setState({typedGroups:value});
	}
	onGroupFunctionChange(value) {
		this.setState({ typedGroupFunction: value, selectedGroupFunction:[] });
		this.props.dispatch(change("xsmAmsCreateRulesForm","groupFunctionName",''));
		this.props.dispatch(change("xsmAmsCreateRulesForm","groupTypeId",''));
	}
	onGroupFunctionSelect(selectedGroupFunctionValue) {
		if (selectedGroupFunctionValue.length > 0){
			this.props.dispatch(change("xsmAmsCreateRulesForm", "groupFunctionName", selectedGroupFunctionValue[0].label));
			this.props.dispatch(change("xsmAmsCreateRulesForm", "groupTypeId", selectedGroupFunctionValue[0].value));
		}else{
			this.props.dispatch(change("xsmAmsCreateRulesForm", "groupFunctionName",''));
			this.props.dispatch(change("xsmAmsCreateRulesForm", "groupTypeId", ''));
		}
		this.setState({ selectedGroupFunction: selectedGroupFunctionValue });
	}
	onCrossClickGroupFunction() {
		this.props.dispatch(change("xsmAmsCreateRulesForm", "groupFunctionName", ''));
		this.props.dispatch(change("xsmAmsCreateRulesForm", "groupTypeId",''));
		this.setState({selectedGroupFunction:[], typedGroupFunction:'' })
	}
	setGroupFunctionError() {
		if(this.state.selectedGroupFunction.length===0){
			this.setErrorInFields(true,'GroupFunction');
		}else{
			this.setErrorInFields(false,'GroupFunction');
		}
	}
	onSupportGroupChange(value) {
		this.props.dispatch(change("xsmAmsCreateRulesForm","alternateGroup",''));
		this.props.dispatch(change("xsmAmsCreateRulesForm","alternateGroupName",''));
		this.setState({ selectedAlternateGroups: [], typedAlternateGroups: '' });
		if (value === 'predefined') {
			this.props.dispatch(change("xsmAmsCreateRulesForm", "isContextual", false));
			this.props.dispatch(change("xsmAmsCreateRulesForm", "groupTypeId", "0"));
			this.setState({
				isContextual: false,
				selectedGroupFunction: [],
				selectedGroups: [],
				typedGroupFunction: '',
				typedGroups: ''
			})
			this.props.dispatch(change("xsmAmsCreateRulesForm", "groupFunctionName", ''));
			this.props.dispatch(change("xsmAmsCreateRulesForm","groupTypeId",''));
			this.props.dispatch(change("xsmAmsCreateRulesForm","contextualGroup",''));
		} else {
			this.props.dispatch(change("xsmAmsCreateRulesForm", "isContextual", true));
			if(this.state.moduleID === '25'){
				this.setState({
					isContextual: true,
					selectedGroupFunction: [],
					selectedGroups: [],
					typedGroupFunction: '',
					typedGroups: '',
					contextualGroup: 'Requestor.Location.Group'
				});
			}
			else {
				this.setState({
					isContextual: true,
					selectedGroupFunction: [],
					selectedGroups: [],
					typedGroupFunction: '',
					typedGroups: '',
					contextualGroup: ''
				})
			}
			this.onCrossClickGroup();
			this.onCrossClickGroupFunction();
			this.props.dispatch(change("xsmAmsCreateRulesForm", "groupFunctionName", ''));
			this.props.dispatch(change("xsmAmsCreateRulesForm","groupTypeId",''));
			if(this.state.moduleID === '25') {
				this.props.dispatch(change("xsmAmsCreateRulesForm", "contextualGroup", 'Requestor.Location.Group'));
			}
			else {
				this.props.dispatch(change("xsmAmsCreateRulesForm", "contextualGroup", ''));
			}
		}
	}
	componentWillMount() {
		let str = {};
		str.searchByList = "";
		str.multipleValueList = "";
		str.patternList = "";
		str.orderBy = "desc";
		str.sortBy = "modifiedOn";
		str.currentPage = 0;
		str.size = 10;
		this.props.resetFormDropdownValues();
		this.props.getAllRuleConfigurationsData(str, [], [], [], []);
		this.props.getCompanyData('AMScreate');
		this.props.getModuleData();
		this.props.dispatch(change("xsmAmsCreateRulesForm","companyStatus","2"));
		this.props.dispatch(change("xsmAmsCreateRulesForm","companyStatusName","Draft"));
		this.props.dispatch(change("xsmAmsCreateRulesForm","isContextual",false));
	}

	componentWillUnmount(){
		//GLOBAL.impactedCIErrorColor='';
	}

	resetComponentField() {
		this.props.dispatch(change("xsmAmsCreateRulesForm", "componentId", ''));
		this.props.dispatch(change("xsmAmsCreateRulesForm", "componentName", 'Select'));
	}

	onAlternateGroupSelect(selectedGroups) {
		if (selectedGroups.length > 0) {
			this.props.getIndividualData(selectedGroups[0].value, this.props.associatedCompIdParam);
			this.props.dispatch(change("xsmAmsCreateRulesForm", "alternateGroupName", selectedGroups[0].label));
			this.props.dispatch(change("xsmAmsCreateRulesForm", "alternateGroup", selectedGroups[0].value));
			this.setState({ typedGroups: '' });
		} else {
			this.props.dispatch(change("xsmAmsCreateRulesForm", "alternateGroupName", ''));
			this.props.dispatch(change("xsmAmsCreateRulesForm", "alternateGroup", ''));
		}
		this.setState({ selectedAlternateGroups: selectedGroups });
	}

	onAltGroupChange(value){
		this.setState({typedAlternateGroups:value});
	}

	onCrossClickAltGroup(){
        this.props.dispatch(change("xsmAmsCreateRulesForm","alternateGroup",''));
        this.props.dispatch(change("xsmAmsCreateRulesForm","alternateGroupName",''));
		this.setState({selectedAlternateGroups:[],typedAlternateGroups:''});
	}

	render() {
		console.log('MMM typedCompany: ', this.state.typedCompany);
		const tr=this.props.tr;
		let contextualGroups = [
			{label: 'Requestor.Location Group', value: 'Requestor.Location.Group'},
			{label: 'CI.Support Group', value: 'CI.Support.Group'}
		];
		return (
			<form>
				<div>
					<div className="borderForm bFormDiv">
						<Row>
							<Col md={6}>
								<Form.Group>
									<Form.Label bsClass=""><span className="rStar" /> {tr['Rule Name']}</Form.Label>
									<Field
											name="ruleName"
											component={_inputField}
											label=""
											className="form-control"
									/>
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group>
									<Form.Label bsClass=""><span className="rStar" /> {tr['Status']}</Form.Label>
									<Field
											name="companyStatus"
											component={_dropDown}
											options={this.state.companyStatus}
											className="form-control"
											onChange={(event) => this.props.dispatch(change("xsmAmsCreateRulesForm", "companyStatusName", event.target[event.target.selectedIndex].text))}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col md={6}>
								<Form.Group validationState={this.state.errorCompany?'error':''}>
									<Form.Label bsClass=""><span className="rStar" /> {tr['Consumer Company']}</Form.Label>
									<div className="dvTypehdropdown1">
									<Field
											name="company"
											component={TypeaheadandDropdown}
											options={this.props.companyDataTypeahead}
											onSelection={this.onCompanySelect}
											selectedOptions={this.state.selectedCompany}
											setErrorColor={this.setCompanyError}
											diableStatus={false}
											onCrossClick={this.onCrossClickCompany}
											onInputChange={this.onCompanyInputChange}
											typedValue={this.state.typedCompany}
											className="form-control"
									/>
									</div>
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group>
									<Form.Label bsClass=""><span className="rStar" /> {tr['Module']}</Form.Label>
									<Field
											name="module"
											component={_dropDown}
											options={this.props.moduleData}
											onChange={(event)=>{ 
											this.props.getEventData(event.target[event.target.selectedIndex].text);
											if(event.target.value !== '20'){this.resetComponentField();}
												 this.setState({moduleID: event.target.value, selectedGroups:[], typedGroups:'', isContextual: false, selectedAlternateGroups: [], typedAlternateGroups: '' });
												 this.props.dispatch(change("xsmAmsCreateRulesForm","group",''));
                                                 this.props.dispatch(change("xsmAmsCreateRulesForm","groupName",''));
												 this.props.dispatch(change("xsmAmsCreateRulesForm", "groupFunctionName", ''));
			                                     this.props.dispatch(change("xsmAmsCreateRulesForm","groupTypeId",''));
												 this.props.dispatch(change("xsmAmsCreateRulesForm","contextualDropdown",'predefined'));
												 this.props.dispatch(change("xsmAmsCreateRulesForm","alternateGroup",''));
		 										 this.props.dispatch(change("xsmAmsCreateRulesForm","alternateGroupName",''));
                                                 this.props.dispatch(change("xsmAmsCreateRulesForm", "moduleName", event.target[event.target.selectedIndex].text));
												 this.props.dispatch(change("xsmAmsCreateRulesForm", "isContextual", false));
												 this.props.dispatch(change("xsmAmsCreateRulesForm", "contextualGroup", ''));
												 this.props.dispatch(change("xsmAmsCreateRulesForm", "event", ''));
												 this.props.dispatch(change("xsmAmsCreateRulesForm", "eventName", 'Select'));
												 this.props.dispatch(change("xsmAmsCreateRulesForm", "weight", ''));
												
										}}
											className="form-control"
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col md={6}>
								<Form.Group>
									<Form.Label bsClass=""><span className="rStar" /> {tr['Assignment For']}</Form.Label>
									<Field
											name="event"
											component={_dropDown}
											options={this.props.eventData}
											className="form-control"
											onChange={(event) => this.props.dispatch(change("xsmAmsCreateRulesForm", "eventName", event.target[event.target.selectedIndex].text))}
									/>
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group>
									<Form.Label bsClass=""><span className="rStar" /> {tr['Weight']}</Form.Label>
									<Field
											name="weight"
											type="number"
											component={_inputField}
											label=""
											className="form-control"
									/>
								</Form.Group>
							</Col>
						</Row>



						<div className="padding-t-10 padding-r-10 padding-l-10 margin-b-15 border">
			 <Row>
              <Col md={6}>
                  <div className="bgGray padding-5"><span className="rStar" /> {tr['Support Company']}</div>
                  <Row>
                    <Col md={12}>
					<Form.Group validationState={this.state.errorAssoCompany?'error':''}>
					<div className="dvTypehdropdown">
										<Field
											name="associatedCompany"
											component={TypeaheadandDropdown}
											options={this.props.associatedCompanyDataTypeahead}
											onSelection={this.onAssoCompanySelect}
											selectedOptions={this.state.selectedAssociatedCompany}
											setErrorColor={this.setAssoCompanyError}
											diableStatus={false}
											onCrossClick={this.onCrossClickAssoCompany}
											onInputChange={this.onAssoCompanyChange}
                                            typedValue={this.state.typedAssociatedCompany}
											// onChange={(event)=>{if(this.props.moduleIdParam != 5 && event.target.value != ''){this.state.getCISupportGroup ? this.props.getCIGroupData(event.target.value) : this.props.getGroupData(event.target.value);} if(this.props.moduleIdParam == 5 && event.target.value != ''){this.state.getCISupportGroup ? this.props.getCIGroupData(event.target.value) : ((this.props.serviceIdParam && this.props.serviceIdParam !='') ? this.props.getBreakfixGroupData(this.props.serviceIdParam) : '');} this.props.dispatch(change("xsmAmsCreateRulesForm", "associatedCompanyName", event.target[event.target.selectedIndex].text)); this.props.dispatch(change("xsmAmsCreateRulesForm", "groupName", "")); this.props.dispatch(change("xsmAmsCreateRulesForm", "group", ""));}}
											className="form-control"
										/>
										</div>
									</Form.Group>
                    </Col>
                  </Row>
              </Col>
			  <Col md={6}>
                  <div className="bgGray padding-5"><span className="rStar" /> {tr['Support Group']} </div>
				  
                  <Row>
                    <Col md={12}>
						<Form.Group className='slectInp'>
							{this.state.moduleID!=='15' && this.state.moduleID!=='10' ?
							<Field name="contextualDropdown"
							       component={_dropDown}
								   className='sleinput grayGradi'
								   options={this.state.supportGroup}
											 onChange={(event)=>{ 
												this.onSupportGroupChange(event.target.value);
											 }}		 
							    />:''}   
							{this.state.isContextual === false ?
									<div className='inpt'><Form.Group validationState={this.state.errorGroup ? 'error' : ''}>
										<div className="dvTypehdropdown">
									<Field
											name="group"
											component={TypeaheadandDropdown}
											options={this.props.groupDataTypeahead}
											onSelection={this.onGroupSelect}
											selectedOptions={this.state.selectedGroups}
											setErrorColor={this.setGroupError}
											onCrossClick={this.onCrossClickGroup}
											diableStatus={false}
											onInputChange={this.onGroupChange}
                                            typedValue={this.state.typedGroups}
											className="form-control"/>
											</div>
									</Form.Group></div> :
									<div className='inpt'><Form.Group>
										{this.state.moduleID != '25' ? <Field
											name="contextualGroup"
											component={_dropDown}
											options={contextualGroups}
											className="form-control"
											onChange={(event) => {
												this.props.dispatch(change("xsmAmsCreateRulesForm", "contextualGroup", event.target.value));
												this.props.dispatch(change("xsmAmsCreateRulesForm","groupTypeId","0"));
												this.setState({ contextualGroup: event.target.value })
												this.onCrossClickGroupFunction();
											}}
										/> : 
										<Field
							            component={_inputField}
										className="form-control"
										name='contextualGroup'
										placeholder='Requestor.Location.Group'
										readOnly
										/>}
									</Form.Group></div>}
						</Form.Group>
						</Col>
                  </Row>
                </Col>
             </Row>

			 <Row>
				{(this.state.isContextual === true) && <Col md={6}>
					<div className="bgGray padding-5">{tr['Alternate Group']}</div>
					<Row>
						<Col md={12}>
							<Form.Group>
							<div className='inpt'><Form.Group>
							<div className="dvTypehdropdown">
									<Field
										name="alternateGroup"
										component={TypeaheadandDropdown}
										options={this.props.groupDataTypeahead}
										onSelection={this.onAlternateGroupSelect}
										selectedOptions={this.state.selectedAlternateGroups}
										onCrossClick={this.onCrossClickAltGroup}
										diableStatus={false}
										onInputChange={this.onAltGroupChange}
										typedValue={this.state.typedAlternateGroups}
										className="form-control"/>
									</div>
								</Form.Group>
							</div>
							</Form.Group>
						</Col>
					</Row>
				</Col>}
				{( (this.state.moduleID!=='15' && this.state.moduleID!=='10') && this.state.isContextual && this.state.contextualGroup === 'Requestor.Location.Group' ) && 
					<Col md={6}>
						<div className="bgGray padding-5"><span className="rStar" /> {tr['Group Function']} </div>
						<Row>
								<Col md={12}>
									<Form.Group validationState={this.state.errorGroupFunction?'error':''}>
									<div className="dvTypehdropdown">
										<Field
											name="groupFunction"
											component={TypeaheadandDropdown}
											options={this.props.groupFunctionDataTypeahead}
											onSelection={this.onGroupFunctionSelect}
											selectedOptions={this.state.selectedGroupFunction}
											setErrorColor={this.setGroupFunctionError}
											diableStatus={false}
											onCrossClick={this.onCrossClickGroupFunction}
											onInputChange={this.onGroupFunctionChange}
                                            typedValue={this.state.typedGroupFunction}
											className="form-control"
										/>
										</div>
									</Form.Group>
								</Col>
						</Row>
					</Col>
				}
			 </Row>


			    </div>
		        </div>
              </div>
	         </form>
		);
	}
};

const mapStateToProps = ({companyDataTypeahead,impactedCIData,groupFunctionDataTypeahead,associatedCompanyDataTypeahead,groupDataTypeahead,individualData,moduleData,eventData,ruleConfiguration,ruleCondition,/*companyServiceTypeahead,*/ciSupCompany,/*componentPackage*/}) => {
	return{
		companyDataTypeahead,
		groupFunctionDataTypeahead,
		associatedCompanyDataTypeahead,
		groupDataTypeahead,
		individualData,
		moduleData,
		eventData,
		ruleConfiguration,
		ruleCondition,
		impactedCIData,
		ciSupCompany,
        initialValues: {"companyStatus":"2"}
	}
}

XsmAmsCreateRulesFormTab = reduxForm({
	form: 'xsmAmsCreateRulesForm',
	validate,
	enableReinitialize: true
})(XsmAmsCreateRulesFormTab);

const selector = formValueSelector('xsmAmsCreateRulesForm');
XsmAmsCreateRulesFormTab = connect((state)=> {
	let companyIdParam = selector(state,'company')
	let companyNameParam = selector(state,'companyName')
	let associatedCompIdParam = selector(state,'associatedCompany')
	let moduleIdParam = selector(state,'module')
	
	return{
		companyIdParam,companyNameParam,associatedCompIdParam,moduleIdParam,
	}
})(XsmAmsCreateRulesFormTab);

export default connect(mapStateToProps, {
	getGroupFunctionData,getCompanyData,getCIAssociatedCompany,getCIGroupData,getClassData,getImpactedCI,getAssociatedCompanyDataWithoutServiceSelected,getAssociatedCompanyListNew,getGroupData,getBreakfixGroupData,getIndividualData,getModuleData,getEventData,getCompanyService,getcompanyImpacted,getAllRuleConfigurationsData,resetFormDropdownValues,loadComponentPackage
})(XsmAmsCreateRulesFormTab);
