
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import PropTypes from "prop-types";
import {
  _inputField,
  _textArea,
  _dropDown,
  _dateField,
} from "../../../serviceDesk/common/formFields";
import { Row, Col, Alert, Form } from "react-bootstrap";
import { loadSlaWrkDayScheduleStatus } from "../../../../actions/sla/lovAction";
import { loadCICompany } from "../../../../actions/cmdb/lovAction";
import { connect } from "react-redux";
import DropdownPlusTypeahead from "../../common/dropdownPlusTypeahead";

import { Field, reduxForm, change } from "redux-form";
import { GLOBAL } from "_Globals";
import _ from "lodash";
import TimePicker from "rc-time-picker";

const validate = (values) => {
  let errors = {};
  if (!values.scheduleName) {
    errors.scheduleName = "enter schedule name";
  }
  if (!values.scheduleStatus) {
    errors.scheduleStatus = "enter schedule status";
  }
  if (!values.scheduleCompany) {
    errors.scheduleCompany = "enter company";
  }

  {
    /*	if(!values.holidayName) {
		errors.holidayName = 'enter holiday schedule name';
	}

	if(!values.holidayDate) {
		errors.holidayDate = 'enter holiday schedule date.';
	}*/
  }

  return errors;
};

let createHolidaySchedule = class createHolidaySchedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      CompanyName: [],
      CompanyError: "",
      scheduleNameError: "",
      statusError: "",
      inputValueCompany: "",
      typedValue: "",
    };
    this.companySelection = this.companySelection.bind(this);
    this.CompanyError = this.CompanyError.bind(this);
    this.scheduleError = this.scheduleError.bind(this);
    this.statusChange = this.statusChange.bind(this);
    this.onCrossCompany = this.onCrossCompany.bind(this);
    this.onCompanyInputChange = this.onCompanyInputChange.bind(this);
    this.onCrossClickCompany = this.onCrossClickCompany.bind(this);
  }
  componentWillMount() {
    this.props.loadCICompany();
    this.props.loadSlaWrkDayScheduleStatus("status");
  }
  companySelection(companySelelected) {
    if (companySelelected.length > 0) {
      this.setState({ CompanyName: companySelelected });

      this.props.dispatch(
        change(
          "createHolidaySchedule",
          "scheduleCompanyName",
          companySelelected[0].label
        )
      );
      this.props.dispatch(
        change(
          "createHolidaySchedule",
          "scheduleCompany",
          companySelelected[0].id
        )
      );
      this.setState({ inputValueCompany: companySelelected[0].label });
      this.setState({ typedValue: companySelelected[0].label });
    } else {
      this.setState({ CompanyName: [] });
      this.props.dispatch(
        change("createHolidaySchedule", "scheduleCompanyName", "")
      );
      this.props.dispatch(
        change("createHolidaySchedule", "scheduleCompany", 0)
      );
    }
  }
  onCompanyInputChange(val) {
    this.setState({ inputValueCompany: val });
    this.setState({ typedValue: val });
  }
  onCrossClickCompany() {
    this.props.dispatch(
      change("createHolidaySchedule", "scheduleCompanyName", "")
    );
    this.props.dispatch(change("createHolidaySchedule", "scheduleCompany", 0));
    this.setState({ inputValueCompany: "", CompanyName: [] });
  }
  statusChange(event) {
    console.log("event.target.value", event.target.value);
    if (event.target.value == "0") {
      this.setState({ statusError: "error" });
    } else {
      this.props.dispatch(
        change(
          "createHolidaySchedule",
          "scheduleStatusName",
          event.target[event.target.selectedIndex].text
        )
      );
      GLOBAL.statusError = "";
      this.setState({ statusError: "" });
    }
  }
  scheduleError(event) {
    //    console.log("events....",event.target.value=="");
    if (event.target.value == "") {
      this.setState({ scheduleNameError: "error" });
    } else {
      GLOBAL.holidayscheduleNameError = "";
      this.setState({ scheduleNameError: "" });
    }
  }

  CompanyError() {
    if (this.state.CompanyName.length == 0) {
      this.setState({ CompanyError: "error" });
    } else {
      this.setState({ CompanyError: "" });
    }
    GLOBAL.holidayCompanyError = "";
  }
  onCrossCompany() {
    this.props.dispatch(
      change("createHolidaySchedule", "scheduleCompanyName", "")
    );
    this.props.dispatch(change("createHolidaySchedule", "scheduleCompany", 0));
  }
  render() {
    let companyData = [];
    this.props.companies.forEach((item, i) => {
      let obj = {};
      obj.id = item.field1Value;
      obj.label = item.field1Key;
      companyData.push(obj);
    });
    let { error } = this.props;

    let errormsg = this.props.translator[error];
    return (
      <form>
        <div>
          {error == undefined || error == null ? null : (
            <Alert variant="danger" className="errorMessageSec">
              <p>{errormsg}</p>
            </Alert>
          )}

          <div className="borderForm bFormDiv">
            <Row>
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">
                    <span className="rStar" />
                    {this.props.translator["Name"]}
                  </Form.Label>
                  <Field
                    name="scheduleName"
                    type="input"
                    component={_inputField}
                    label=""
                    className={`form-control ${
                      this.state.scheduleNameError == ""
                        ? GLOBAL.holidayscheduleNameError
                        : this.state.scheduleNameError
                    }`}
                    onBlur={(e) => this.scheduleError(e)}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">
                    <span className="rStar" />
                    {this.props.translator["Status"]}
                  </Form.Label>
                  <Field
                    name="scheduleStatus"
                    component={_dropDown}
                    options={this.props.status}
                    className={`form-control ${
                      this.state.statusError == ""
                        ? GLOBAL.statusError
                        : this.state.statusError
                    }`}
                    onChange={(event) => this.statusChange(event)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="form-group"
                >
                  <Form.Label bsClass="">
                    <span className="rStar" />
                    {this.props.translator["Company"]}
                  </Form.Label>
                  <Field
                    component={DropdownPlusTypeahead}
                    onSelection={this.companySelection}
                    name="scheduleCompany"
                    id="company"
                    className="form-control"
                    options={companyData}
                    setErrorColor={this.CompanyError}
                    selectedOptions={this.state.CompanyName}
                    onInputChange={this.onCompanyInputChange}
                    onCrossClick={this.onCrossClickCompany}
                    inputValue={this.state.inputValueCompany}
                    typedValue={this.state.typedValue}
                    isError={
                      this.state.CompanyError == ""
                        ? GLOBAL.holidayCompanyError
                        : this.state.CompanyError
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </div>
      </form>
    );
  }
};

createHolidaySchedule.propTypes = {
  loadSlaCompany: PropTypes.func,
  loadSlaWrkDayScheduleStatus: PropTypes.func,
};

const mapStateToProps = ({ ciCompany, slaWorkdaySchedulesStatus }) => {
  let companies =
    ciCompany == null ||
    ciCompany == undefined ||
    ciCompany.length == 0 ||
    _.isEmpty(ciCompany)
      ? []
      : ciCompany;
  let status =
    slaWorkdaySchedulesStatus == null ||
    slaWorkdaySchedulesStatus == undefined ||
    slaWorkdaySchedulesStatus.length == 0 ||
    _.isEmpty(slaWorkdaySchedulesStatus)
      ? []
      : slaWorkdaySchedulesStatus;
  return {
    companies,
    status,
  };
};

createHolidaySchedule = reduxForm({
  form: "createHolidaySchedule",
  fields: ["scheduleCompany", "scheduleStatus", "scheduleName"],
  initialValues: { scheduleCompany: "", scheduleStatus: "", scheduleName: "" },

  enableReinitialize: true,
})(createHolidaySchedule);

export default connect(mapStateToProps, {
  loadCICompany,
  loadSlaWrkDayScheduleStatus,
})(createHolidaySchedule);
