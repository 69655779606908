
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
//below URL used for testing purpose
//const breakFixUrl = 'https://xsm-f.mybluemix.net/api/SX_FAVOURITEOFFERINGs/';
import { GLOBAL }  from '../../components/Globals';
//let breakFixUrl =`https://xsm-f.mybluemix.net/api/breakfixitem?breakfixitemId=`;
//let breakFixUrl=`https://xsm-f.mybluemix.net/groupListSelfService?companyId=2932&group=ResolvedER`
export function loadChangeRelatedWorkDetailsSuccess(changeRelatedWorkDetails){
	//alert('in success')
	return {
		type: 'LOAD_CHANGERELATEDWORKDETAILS_SUCCESS',
		changeRelatedWorkDetails
	};
}

export function loadChangeRelatedWorkDetailsFailure(errorResponse){
	return {
		type: 'LOAD_CHANGERELATEDWORKDETAILS_FAILURE',
		errorResponse
	};
}
export function loadChangeRelatedWorkDetails(filterval,breakFixId,module,itmId='',isRelationReq=''){
	//alert('action called and parameter is '+breakFixId)
	//if(breakFixId=="0")
	//breakFixId="";
	console.log("module cng ",module)
	return(dispatch) =>{
		//alert("EditGroupIdAction123"+breakFixId);
		if(!(breakFixId == -1)){
			dispatch({type: "MENU_LOADER_IN_PROGRESS"});
		}
		let axioscall="";
				//alert("hiaaa::::");
				let relatedurl="";
				if(module=="Breakfix")
				relatedurl=GLOBAL.breakFixRelationQuickViewURL;
				if(module=="Fullfilment")
				relatedurl=GLOBAL.fulfillmentRelationListURL;
				if(module=="Investigation")
				//relatedurl=GLOBAL.investigationQuickViewURL+"?searchByList="+filterval+"&patternList=like&multipleValueList="+breakFixId
				relatedurl=GLOBAL.investigationQuickViewURL;
				if(module=="ChangeManagement")
				relatedurl=GLOBAL.changeQuickViewURL;

				if(module=="Breakfix"){
					let str = {};
					str.searchByList = filterval;
					str.patternList = "like";
					str.multipleValueList = ""+breakFixId;
					str.size = 99;
					str.currentPage = 0;
					if(isRelationReq != '')	str.isRelationsNotRequired = isRelationReq;
					if(itmId != '') {str.itemId = itmId.toString();}
					//str = JSON.stringify(str);
					axioscall = axios.post(relatedurl, str);
				console.log("?searchByList="+filterval+"&patternList=like&multipleValueList="+breakFixId);
				}else if(module=="Fullfilment"){
					let str={};
					str.searchByList=filterval;
					str.isNestedRequired="N";
					str.patternList="like";
					str.multipleValueList=""+breakFixId;
					str.size = 99;
					str.currentPage = 0;
					if(isRelationReq != '')	str.isRelationsNotRequired = isRelationReq;
					if(itmId != '') {str.itemId = itmId.toString();}
				//	str = JSON.stringify(str);
					axioscall=axios.post(relatedurl,str);
					console.log("?searchByList="+filterval+
					"&isNestedRequired=N&patternList=like&multipleValueList="+breakFixId);
				}else if(module=="Investigation"){
					let str={};
					str.searchByList=filterval;
					str.patternList="like";
					str.multipleValueList=""+breakFixId;
					str.size = 99;
					str.currentPage = 0;
					if(isRelationReq != '')	str.isRelationsNotRequired = isRelationReq;
					if(itmId != '') {str.itemId = itmId.toString();}
					axioscall=axios.post(relatedurl,str);
				}else if(module=="ChangeManagement"){
					//"?searchByList="+filterval+"&patternList=like&multipleValueList="+breakFixId
					
					let str={};
					str.searchByList=filterval;
					str.patternList="like";
					str.multipleValueList=""+breakFixId;
					str.size = 99;
					str.currentPage = 0;
					if(isRelationReq != '')	str.isRelationsNotRequired = isRelationReq;
					if(itmId != '') {str.itemId = itmId.toString();}
					axioscall=axios.post(relatedurl,str);
				}else{
					axioscall=axios.get(relatedurl)
				}
				axioscall.then((changeRelatedWorkDetails)=>{
						if(!changeRelatedWorkDetails){//alert('not breakFix')
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							dispatch({type:'MENU_LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
							throw Error(changeRelatedWorkDetails.statusText);
						}
						return changeRelatedWorkDetails;
					})
					.then((changeRelatedWorkDetails) =>{//alert('yes it is')
						dispatch(loadChangeRelatedWorkDetailsSuccess(changeRelatedWorkDetails));
						dispatch({type:'MENU_LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
					})
					.catch((err) => {
						dispatch({type:'MENU_LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
						console.log(err);
					});
					//console.log("not calling loadBreakFixEditDetails");

	};
}

export function clearChangeRelatedWorkDetails(){
	return(dispatch) =>{
		let obj = {
			data: {
				data: [],
				meta: null
			}
		};
		dispatch(loadChangeRelatedWorkDetailsSuccess(obj));
	};
}