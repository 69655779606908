
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Nav, Popover, Table } from "react-bootstrap";
import OnClickOutside from "react-onclickoutside";
import PopUpAttribute from "./attribute";
import PopUpRelatedWork from "./relatedWork";
import PopUpAuditLog from "./AuditLog";

class LeftSidebarMenulist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleClickOutside() {}
  render() {
    const popoverAttributesData = (
      <Popover className="innerBigPopover" id="popoverAttributes">
        <PopUpAttribute
          translator={this.props.translator}
          ciId={this.props.ciId}
        />
      </Popover>
    );
    {
      /*const popoverAuditData = (<Popover className="innerBigPopover" id="popoverAudit"><PopUpAuditLog  ciId={this.props.ciId} module="CMDB" /></Popover>);*/
    }
    {
      /* const popoverRelationshipsData = (<Popover className="innerBigPopover" id="popoverRelationships"><PopUpRelatedWork itemId={this.props.ciId} cieditdetails={this.props.cidetails.COMPANY_ID} /></Popover>);*/
    }
    return (
      <div
        className={
          this.props.newMobileLeftMenuFilter ? "leftNav showNav" : "leftNav"
        }
      >
        <Nav bsClass="" className="leftMenu">
          {/*<OverlayTrigger container={this} trigger="click" rootClose placement="right" overlay={popoverAttributesData}><NavItem href={void(0)} eventKey={1} title={this.props.translator['Specify Attribute Values']}><span className="bgBtn4 lSidebar attbuts"></span></NavItem></OverlayTrigger>*/}

          {/* <OverlayTrigger container={this} trigger="click" rootClose placement="right" overlay={popoverRelationshipsData}><NavItem href={void(0)} eventKey={3} title="Relate Assets/CIs"><span className="bgBtn10 lSidebar relnship"></span></NavItem></OverlayTrigger>
					<OverlayTrigger container={this} trigger="click" rootClose placement="right" overlay={popoverAuditData}><NavItem href={void(0)} eventKey={2} title="History"><span className="bgBtn2 lSidebar brkf10"></span></NavItem></OverlayTrigger> */}
        </Nav>
      </div>
    );
  }
}

class PopoverAuditData extends React.Component {
  render() {
    return (
      <div>
        <div className="f-size-18 margin-b-5">
          {this.props.translator["Audit"]}
        </div>

        <div>
          <Table responsive striped bordered condensed hover>
            <thead>
              <tr>
                <th>{this.props.translator["Attribute"]}</th>
                <th>{this.props.translator["Old Value"]}</th>
                <th>{this.props.translator["New Value"]}</th>
                <th>{this.props.translator["Changed By"]}</th>
                <th>{this.props.translator["Changed On"]}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Attribute 1</td>
                <td>Value 1</td>
                <td>Value 2</td>
                <td>Vijay Sharma</td>
                <td>17 Oct, 2017</td>
              </tr>
              <tr>
                <td>Attribute 2</td>
                <td>Value 2</td>
                <td>Value 22</td>
                <td>Dheeraj Saraswat</td>
                <td>17 Oct, 2017</td>
              </tr>
              <tr>
                <td>Attribute 3</td>
                <td>Value 3</td>
                <td>Value 33</td>
                <td>Pallavi Sood</td>
                <td>17 Oct, 2017</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}
LeftSidebarMenulist = OnClickOutside(LeftSidebarMenulist);
export default LeftSidebarMenulist;
