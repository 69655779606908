
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import moment from "moment";
//import { MenuItem } from 'react-bootstrap';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import NavDropdown from "react-bootstrap/NavDropdown";
import { NavLink } from 'react-router-dom';
import Nav from "react-bootstrap/Nav";
import { reduxForm } from "redux-form";
import axios from "axios";
import _ from "lodash";
import OnClickOutside from "react-onclickoutside";
import { connect } from "react-redux";
import swal from "sweetalert";
import Cookies from "universal-cookie";
import {LuActivitySquare, LuClipboardCheck, LuFileText,LuCalendarClock} from "react-icons/lu";
import {ImAttachment, ImSpinner6} from "react-icons/im";
import {FaRegThumbsUp} from "react-icons/fa";
import {FaRegEye} from "react-icons/fa6";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import {LiaHourglass} from "react-icons/lia";
import {MdKeyboardDoubleArrowRight} from "react-icons/md";
import {HiOutlineSpeakerphone, HiOutlineRefresh} from "react-icons/hi";
import { BiDotsVertical } from "react-icons/bi";
import {PiTagBold} from "react-icons/pi";
import {TbSubtask, TbCirclesRelation, TbTextWrap} from 'react-icons/tb';
import { RiStackshareLine } from "react-icons/ri";

import ListLoader from "../../../common/loaders/ListLoader";
import ExportTo from '_Commons/WorkItemBoard/exportTo.js';

import {
  loadChangeEditData,
  changeFieldStatusData,
  loadChangeManagementmList,
  changeConflict,
  resetrelatedCount,
} from "../../../../actions/changeManagement/changeManagementAction";
import { loadBreakFixAuditLogDetails } from "../../../../actions/breakFix/breakFixAuditLogAction";
import {
  loadStatusBasedHamburgerOptions,
  loadHamburger,
  stopLoadHamburger,
} from "../../../../actions/common/loadStatusBasedHamburgerOptions";
import { loadTimelineData } from "../../../../actions/common/commonApisActions";
import {
  checkRelatedTaskForItem,
  loadBreakFixTaskLogDetails,
  resetrelatedCMDBtasks,
} from "../../../../actions/task/TaskDataAction";
import { loadImplPlanList } from "../../../../actions/common/attachmentAction";
import {
  setBreakfixQuickViewTableCarouselIndex,
  resetBFQVFilters,
  fetchChangeGroupList,
} from "../../../../actions/breakFix/quickViewAction";
import { loadrelatedWorkitems } from "../../../../actions/changeManagement/changeRelatedDataAction";
import { setInitialwibGlobalSearchFilterData } from "../../../../actions/globalSearch/globalSearchAction";

import datetimeConvertor from "../../../../ISO8601converter";
import configureStore from "../../../../store/configureStore";
import constants from "../../../../utils/constants";

import { GLOBAL } from "_Globals";
import { navigationHooks } from "../../../../helpers/NavigationHook.js";
import { Link } from "react-router-dom";

import { getCmdbApprovals } from "../../../../actions/cmdb/lovAction.js";
const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});
import { MyContext } from '_MyContext';

let dateformat = "";
let date_format = "";
let currentStatus = "";
const store = configureStore();
const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
// let LEAD_TIME = homepagelocation[49];
let LEAD_TIME = null;
let dateformatCookie = homepagelocation[2];

let timezone = cookies.get('gph');
if (timezone) timezone = timezone.replace('s:', '');
if (timezone) timezone = timezone.substring(0, timezone.lastIndexOf('.'));
timezone = timezone.split("~");
timezone = timezone[3];


let ChangeEditHeader = class ChangeEditHeader extends React.Component {
  static contextType = MyContext;
  constructor(props) {
    super(props);
    this.state = {
      hamburgerShowHide: false,
      statusHamburgerShowHide: false,
      d2cnumber: "",
      showHamburgerIconFlag: false,
      disabledSave: false,
      isServiceDesk: false,
    };
    this.callbackFun = this.callbackFun.bind(this);
    this.showHideHamburgerIcon = this.showHideHamburgerIcon.bind(this);
    this.saveChange = this.saveChange.bind(this);
    this.callBreakFixApi = this.callBreakFixApi.bind(this);
    this.allowReset = this.allowReset.bind(this);
    this.closeEditChange = this.closeEditChange.bind(this);
    this.getLeadTime = this.getLeadTime.bind(this);
  }

  componentDidMount() {
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
    this.context.newTabPrevent();
	}
	componentDidUpdate() {
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
    this.context.newTabPrevent();
	}

  callbackFun(id) {
    this.setState({ hamburgerShowHide: id });
    this.setState({ statusHamburgerShowHide: id });
  }
  showHideHamburgerIcon(roleid) {
    let userRoleStr = roleid;
    let isChangeMgrRoleExist = -1;
    if (userRoleStr) {
      let userRole = userRoleStr.split(",");
      isChangeMgrRoleExist = userRole.indexOf("20");
      if (isChangeMgrRoleExist >= 0) {
        this.setState({ showHamburgerIconFlag: true });
      }
    }
  }
  saveChange(values) {
    console.log("saveChange() : ",values)
    let count = 0;
    if (this.props.editChangeInitialData.statusId!=='30' &&
      (typeof values.implementationGroup === "undefined" ||
      values.implementationGroup === "0" ||
      values.implementationGroup === 0 ||
      values.implementationGroup === null ||
      !values.impGroupname ||
      values.impGroupname === null ||
      values.impGroupname === "" ||
      values.implementationGroup === "")
    ) {
      this.props.seTypeaheadValidation(true, "implementationGroup");
      count++;
    }
    if (this.props.editChangeInitialData.statusId!=='30' &&
      (typeof values.changeManagementGroup === "undefined" ||
      values.changeManagementGroup === "0" ||
      values.changeManagementGroup === 0 ||
      values.changeManagementGroup === null ||
      !values.changeGroupname ||
      values.changeGroupname === null ||
      values.changeGroupname === "" ||
      values.changeManagementGroup === "")
    ) {
      this.props.seTypeaheadValidation(true, "changeManagementGroup");
      count++;
    }
    if (count > 0) {
      return;
    }
    localStorage.setItem("grpname", values.changeGroupname);
    localStorage.setItem("grpid", values.changeManagementGroup);
    localStorage.setItem("impgrpname", values.impGroupname);
    localStorage.setItem("implgrpid", values.implementationGroup);
    GLOBAL.beforesave = true;
    this.setState({ disabledSave: true });
    let ref = this;
    let ciId = "";
    let ciName = "";
    let ciCode = "";
    let flag = "N";
    let deletedCiRelationId = "";
    let relationRequired = "N";
    let jwtdf = this.props.date_format.substring(0, 10) + " HH:mm:ss";

    let itemId = "";
    let itemNumber = "";
    let reportedOn = "";
    let createdBy = "";
    let riskAssessmentValue = this.props.riskAssessmentValue.overallRisk || '';

    if (typeof values.expectedStartDate == "object") {
      let val = values?.expectedStartDate?._d;
      values.expectedStartDate = moment(val).format(jwtdf);
    } 

    if (typeof values.expectedEndDate == "object") {
      let val = values?.expectedEndDate?._d;
      values.expectedEndDate = moment(val).format(jwtdf);
    }

    if (typeof values.startDate == "object") {
      let val = values?.startDate?._d;
      values.startDate = moment(val).format(jwtdf);
    } 

    if (typeof values.endDate == "object") {
      let val = values?.endDate?._d;
      values.endDate = moment(val).format(jwtdf);
    } 

    let dateFormat = jwtdf.includes("HH:mm:ss") ? jwtdf : jwtdf + " HH:mm:ss";
    if (values && values.actualStartDate) {
      values.actualStartDate = moment(values.actualStartDate).format(
        dateFormat
      );
    }
    if (values && values.actualEndDate) {
      values.actualEndDate = moment(values.actualEndDate).format(dateFormat);
    }
    if (values && values.lStartDate) {
      values.lStartDate = moment(values.lStartDate).format(dateFormat);
    }
    if (values && values.lEndDate) {
      values.lEndDate = moment(values.lEndDate).format(dateFormat);
    }

    if (values.changeTiming === "20") {
      values.startDate = values.lStartDate;
      values.endDate = values.lEndDate;
    }
    if (values.downtimeRad === "N" || values.downtimeRad === undefined) {
      values.startDate = '';
      values.endDate = '';
    }
    if (GLOBAL.ciId == "0" || GLOBAL.ciId == null) {
      if (GLOBAL.ciIdEdit == "") {
        GLOBAL.ciId = 0;
        GLOBAL.ciName = "";
        GLOBAL.ciCode = "";
        flag = "N";
        relationRequired = "N";
      } else {
        ciId = GLOBAL.ciIdEdit;
        ciName = GLOBAL.ciNameEdit;
        ciCode = GLOBAL.ciIdNumberEdit;
        flag = "N";
        relationRequired = "Y";
      }
    } else {
      if (GLOBAL.ciIdEdit == "") {
        ciId = GLOBAL.ciId;
        ciName = GLOBAL.ciName;
        ciCode = GLOBAL.ciIdNumber;
        flag = "N";
        relationRequired = "N";
      } else {
        if (GLOBAL.ciId != GLOBAL.ciIdEdit) {
          ciId = GLOBAL.ciIdEdit;
          ciName = GLOBAL.ciNameEdit;
          ciCode = GLOBAL.ciIdNumberEdit;
          flag = "Y";
          deletedCiRelationId = GLOBAL.ciId;
          relationRequired = "N";
        } else if (GLOBAL.ciId == GLOBAL.ciIdEdit) {
          ciId = GLOBAL.ciIdEdit;
          ciName = GLOBAL.ciNameEdit;
          ciCode = GLOBAL.ciIdNumberEdit;
          flag = "N";
          relationRequired = "N";
        }
      }
    }
    if (ciId == "") ciId = "0";
    if (ciCode == "") ciCode = "0";
    let tempIsExternal = "";
    if (
      this.props.editChangeInitialData.isExternal == true ||
      this.props.editChangeInitialData.isExternal == "true"
    ) {
      tempIsExternal = this.props.editChangeInitialData.isExternal;
    } else if (
      this.props.editChangeInitialData.isExternal == "" ||
      this.props.editChangeInitialData.isExternal == false ||
      this.props.editChangeInitialData.isExternal == undefined ||
      this.props.editChangeInitialData.isExternal == null
    ) {
      tempIsExternal =
        GLOBAL.isExternalChangeEdit == "" ||
        GLOBAL.isExternalChangeEdit == null ||
        GLOBAL.isExternalChangeEdit == undefined
          ? false
          : GLOBAL.isExternalChangeEdit;
    }

    if (values && values.cabDate) {
      if (typeof values.cabDate == "object") {
        values.cabDate = moment(values.cabDate).format(dateFormat);
      }
    }

    let payloadJson = {
      serviceName:
        this.props.editChangeInitialData.serviceName !== null ||
        this.props.editChangeInitialData.serviceName !== ""
          ? this.props.editChangeInitialData.serviceName
          : "",
      requesterName:
        this.props.editChangeInitialData.status === "Draft"
          ? this.props.fieldStatus.requestor === true
            ? GLOBAL.breakFixConsumerName === ""
              ? this.props.editChangeInitialData.requesterName
              : GLOBAL.breakFixConsumerName
            : this.props.editChangeInitialData.requesterName
          : this.props.editChangeInitialData.requesterName,
      requesterId:
        this.props.editChangeInitialData.status === "Draft"
          ? this.props.fieldStatus.requestor === true
            ? GLOBAL.breakFixConsumerID === ""
              ? this.props.editChangeInitialData.requesterId
              : GLOBAL.breakFixConsumerID
            : this.props.editChangeInitialData.requesterId
          : this.props.editChangeInitialData.requesterId,
      changeType: values.type,
      summary: values.summary,
      description: values.description,
      changeTiming:
        values.type == "25" || values.type == "30" || values.type == "35" ? "0" : values.changeTiming,
      technicalRisk:
        values.type == "25" || values.type == "30" || values.type == "35"
          ? "0"
          : values.overallTechnicalRisk,
      businessRisk:
        values.type == "25" || values.type == "30" || values.type == "35"
          ? "0"
          : values.overallBusinessRisk,
      expEndDate: values.expectedEndDate,
      expStartDate: values.expectedStartDate,
      actEndDate: values.actualEndDate,
      actStartDate: values.actualStartDate,
      dEndDate: values.endDate,
      dStartDate: values.startDate,
      changeGroupid: typeof values.changeManagementGroup==='undefined' || values.changeManagementGroup==='' ?'0':values.changeManagementGroup.toString(),
      changeGroupname: typeof values.changeGroupname==='undefined' ?'':values.changeGroupname,
      changeUserid:
        values.changeManagementIndividual === undefined ||
        values.changeManagementIndividual === ""
          ? 0
          : values.changeManagementIndividual.toString(),
      changeUsername:
        values.changeUsername === "" ||
        typeof values.changeUsername === "undefined" ||
        values.changeUsername === "0" ||
        values.changeUsername === null
          ? ""
          : values.changeUsername,
      impGroupid: typeof values.implementationGroup==='undefined' || values.implementationGroup==='' ?'0':values.implementationGroup.toString(),
      impGroupname: typeof values.impGroupname==='undefined'?'':values.impGroupname,
      impUserid:
        values.implementationIndividual === undefined ||
        values.implementationIndividual === ""
          ? 0
          : values.implementationIndividual.toString(),
      impUsername:
        values.impUsername === "" ||
        typeof values.impUsername === "undefined" ||
        values.impUsername === "0" ||
        values.impUsername === null
          ? ""
          : values.impUsername,
      modifiedBy: "",
      modifiedByName: "",
      systemStartDateRequired: "N",
      systemEndDateRequired: "N",
      downtimeRequired: values.downtimeRad,
      ciId: ciId,
      ciName: ciName == "null" ? "" : ciName,
      ciCode: ciCode,
      ciClassName: values.ciClassName == "null" ? "" : values.ciClassName,
      urgency: values.type == "25" || values.type == "35" ? values.urgency : "0",
      reasonForChange:
        values.type == "25" || values.type == "30" || values.type == "35"
          ? values.reasonForChange
          : "0",
      risk:
        values.type == "25" || values.type == "30" || values.type == "35"
          ? riskAssessmentValue
          : "null",
      riskImpact:
        values.type == "25" || values.type == "30" || values.type == "35" ? values.riskImpact : "null",
      riskOccurrenceProbability:
        values.type == "25" || values.type == "30" || values.type == "35"
          ? values.riskOccurrenceProbability
          : "null",
      controlEffectiveness:
        values.type == "25" || values.type == "30" || values.type == "35"
          ? values.controlEffectiveness
          : "null",
      uiRequest:
        values.type == "25" || values.type == "30" || values.type == "35"
          ? values.status == "Under Review" || values.status == "Scheduled"
            ? true
            : false
          : false,
      serviceId:
        this.props.editChangeInitialData.serviceId !== 0
          ? this.props.editChangeInitialData.serviceId
          : 0,
      cmdbUpdateNeeded:
        values.type == "25" || values.type == "30" || values.type == "20" || values.type == "35"
          ? values.cmdbUpdateNeeded
          : "No",
      category: GLOBAL.categoryNameEdit,
      environment: GLOBAL.environmentNameEdit,
      ciConsumerCompany:
        GLOBAL.ciConsumerCompanyEdit == ""
          ? values.ciConsumerCompany
            ? values.ciConsumerCompany
            : "0"
          : GLOBAL.ciConsumerCompanyEdit,
      ciSupportCompany:
        GLOBAL.ciSupportCompanyEdit == ""
          ? values.ciSupportCompany
            ? values.ciSupportCompany
            : "0"
          : GLOBAL.ciSupportCompanyEdit,
      ciClassId:
        GLOBAL.ciClassIdEdit == ""
          ? values.ciClassId
            ? values.ciClassId
            : "0"
          : GLOBAL.ciClassIdEdit,
      ciLocationId:
        GLOBAL.ciLocationIdEdit == ""
          ? values.ciLocationId
            ? values.ciLocationId
            : "0"
          : GLOBAL.ciLocationIdEdit,
      ciLocationName:
        GLOBAL.ciLocationNameEdit == ""
          ? values.ciLocationName
            ? values.ciLocationName
            : "0"
          : GLOBAL.ciLocationNameEdit,
      consumerCompany: this.props.editChangeInitialData.consumerCompany,
      consumerCompanyName: this.props.editChangeInitialData.consumerCompanyName,
      isExternal: tempIsExternal,
      requesterLocationId: GLOBAL.servicelocationid || "",
      requesterLocationName: GLOBAL.servicelocationname || "",
      cabDate: values.cabDate || "",
      businessCriticalityId:
        GLOBAL.businessCriticalityId === ""
          ? this.props.editChangeInitialData.businessCriticalityId
          : GLOBAL.businessCriticalityId,
      businessCriticality:
        GLOBAL.businessCriticality === ""
          ? this.props.editChangeInitialData.businessCriticality
          : GLOBAL.businessCriticality,
        supportCompanyId: values.supportCompanyID,
        supportCompanyName: values.supportCompanyName
    };

    let cabDateError = this.props.validateCABDate({
      statusId: values.statusId,
      type: values.type,
      cabDate: values.cabDate,
      expectedStartDate: values.expectedStartDate,
    });
    if (cabDateError) {
      swal({
        text: this.props.translator[cabDateError],
        button: this.props.translator["OK"],
      });
      this.setState({ disabledSave: false });
      return false;
    }

    payloadJson['residualRisk'] = this.props.riskAssessmentDetails.residualRisk;
    payloadJson['riskAssessment'] = this.props.riskAssessmentDetails.riskAssessment;

    // console.log('*************', {isRiskAssessmentMandatory: this.props.isRiskAssessmentMandatory, isRiskAssessmentFilled: this.props.isRiskAssessmentFilled});

    let dataUnchangedFlag = true;
    
    if(values.type && values.type != '20'){
      if(this.props.isRiskAssessmentMandatory == true && this.props.isRiskAssessmentFilled == false){
        swal({
          text: this.props.translator["Please fill Risk Assessment"] || "Please fill Risk Assessment",
          button: this.props.translator["OK"],
          });
          this.setState({ disabledSave: false });
          return false;
      }
      if (this.props.checkDataChanged_riskAssessment(payloadJson)) {
        dataUnchangedFlag = false;
      }
    }
    
    if (!this.props.checkDataChanged(payloadJson)) {
      // dataUnchangedFlag = true;
      // swal({
      //   text: this.props.translator["There is no change on the form"],
      //   button: this.props.translator["OK"],
      // });
      // this.setState({ disabledSave: false });
      // return false;
    }
    else{
      dataUnchangedFlag = false;
    }
    
    // console.log('dataUnchangedFlag - ', dataUnchangedFlag);

    if(dataUnchangedFlag == true){
      swal({
        text: this.props.translator["There is no change on the form"],
        button: this.props.translator["OK"],
      });
      this.setState({ disabledSave: false });
      return false;
    }

    try{
      if(this.props.riskAssessmentDetails && this.props.riskAssessmentDetails.riskAssessment && Array.isArray(this.props.riskAssessmentDetails.riskAssessment)){
        payloadJson['riskAssessment'] = JSON.stringify(this.props.riskAssessmentDetails.riskAssessment);
      }
    }catch(e){

    }

    const { editChangeInitialData: { type = '' } = {} } = this.props;

    axios
      .patch(GLOBAL.changeEditUrl + values.changeId, payloadJson)
      .then(function (response) {
        // console.log('response - ', response);
        if (typeof response.data === "string") {
          let message = response.data;
          swal({
            text: message,
            button: ref.props.translator["OK"],
          });
          ref.setState({ disabledSave: false });
        } else if (type != '35' && Array.isArray(response.data)) {
          let changeConflictData = response.data;
          let changeConflictJustification = {};
          changeConflictData.forEach((item, i) => {
            let name = "justification" + i;
            changeConflictJustification[name] = item.justification;
          });
          ref.props.changeConflict(
            changeConflictJustification,
            changeConflictData
          );
          ref.props.showConflictForm(true);
          ref.setState({ disabledSave: false });
        } else {
          if (response.status == 200) {
            if (flag == "Y") {
            }
            itemId = response.data.changeId;
            itemNumber = response.data.changeNumber;
            reportedOn = response.data.createdOn;
            createdBy = "";
            if (relationRequired == "Y") {
              let payloadObj ={
                "ci_id": ciId,  //==> ID of CI related
                "wi_id_related": itemId, //==> ID of Work Item related
                "wi_id_number": itemNumber,//  ==> Number of work item related
                "ci_id_reported": reportedOn,
                "created_by": createdBy,
                "relationship_type": "Y",
                "module": "Change"
              }
              axios
                .post(GLOBAL.cmdbRelatedLogDeleteUrl, /*{
                  ci_id: itemId,
                  ci_id_related: ciId,
                  ci_id_number: itemNumber,
                  ci_id_reported: reportedOn,
                  created_by: createdBy,
                  relationship_type: "Y",
                  module: "Change",
                }*/
                payloadObj
                )
                .then((response) => {});
            }
            if (flag == "Y") {
              axios
                .delete(
                  GLOBAL.cmdbRelatedLogDeleteUrl +
                    "?ci_id=" +
                     deletedCiRelationId+
                    "&ci_id_related=" +
                    itemId +
                    "&module=Change"
                )
                .then((response) => {
                  if (!response || response.status != "200") {
                  } else {
                    let payloadObj ={
                      "ci_id": ciId,  //==> ID of CI related
                      "wi_id_related": itemId, //==> ID of Work Item related
                      "wi_id_number": itemNumber,//  ==> Number of work item related
                      "ci_id_reported": reportedOn,
                      "created_by": createdBy,
                      "relationship_type": "Y",
                      "module": "Change"
                    }
                    axios
                      .post(GLOBAL.cmdbRelatedLogDeleteUrl, /*{
                        ci_id: itemId,
                        ci_id_related: ciId,
                        ci_id_number: itemNumber,
                        ci_id_reported: reportedOn,
                        created_by: createdBy,
                        module: "Change",
                        relationship_type: "Y",
                      }*/
                      payloadObj
                      )
                      .then((response) => {});
                  }
                });
            } else {
              ref.setState({ disabledSave: false });
            }
          } else {
          }
          ref.props.loadChangeEditData(response.data.changeId, "");
          ref.props.loadStatusBasedHamburgerOptions(
            "ChangeManagement",
            response.data.status,
            response.data.changeId
          );
        }
        ref.props.changeActionView("");
      })
      .catch(function (error) {});
  }
  callBreakFixApi() {
    // let searchParams = Object.assign({});
    // searchParams.searchByList = "status";
    // searchParams.multipleValueList = "30,35,40,45,55,65,95";
    // searchParams.size = 10;
    // searchParams.currentPage = 0;
    // (searchParams.orderBy = "desc"),
    //   (searchParams.patternList = "in"),
    //   (searchParams.sortBy = "createdOn");
    // //this.props.loadChangeManagementmList(searchParams);
    this.props.resetBFQVFilters();
    this.props.fetchChangeGroupList();
    this.props.setBreakfixQuickViewTableCarouselIndex("3");
    this.props.setInitialwibGlobalSearchFilterData();
  }
  allowReset() {
    this.props.seTypeaheadValidation(true, "Reset");
    this.props.reset();
  }
  closeEditChange() {
    this.callBreakFixApi();
    navigationHooks.navigate("/quickViewBreakFix");
  }
  componentWillMount() {
    this.props.checkRelatedTaskForItem(
      this.props.editChangeInitialData.changeId,
      "ChangeManagement"
    );
    if (
      !(
        this.props.roleIdentification("19") ||
        this.props.roleIdentification("20") ||
        this.props.roleIdentification("51") ||
        this.props.roleIdentification("52")
      )
    ) {
      this.setState({ isServiceDesk: this.props.roleIdentification("48") });
    }
    // if(
    //   this.props.editChangeInitialData.status === "Draft" && this.props.editChangeInitialData.type === "25" && this.props.editChangeInitialData.urgency === "10"
    // ){
    //   this.getLeadTime();
    // }
    if(
      this.props.editChangeInitialData.status === "Draft" && this.props.editChangeInitialData.type === "25"
    ){
      this.getLeadTime();
    }
    if (GLOBAL.slaHeadEnable) {
      this.props.changeActionView('sla')
    }
  }
  
  getLeadTime(){
    try{
      // console.log('this.props - ', this.props.editChangeInitialData);
      const { editChangeInitialData : {
        consumerCompany = '',
        residualRisk = ''
      } = {} } = this.props;
      if(!consumerCompany){
        throw new Error('Consumer Company not found!');
      }
      let str = {};
      str['companyId'] = this.props.editChangeInitialData.consumerCompany;
      str['module'] = 'Change';
      str['propertyId'] = '11';
      str = JSON.stringify(str);
      axios
      .get('/api/getAdminPreferences/', { headers: { 'query': str } })
      .then((response) => {
        // console.log('response - ', response);
        if(response && response.data && response.data[0] && response.data[0].value){
          let tempStr = JSON.parse(response.data[0].value);
          if(!(residualRisk && residualRisk in tempStr)){
            throw new Error(+'Residual Risk not found!');
          }
          const leadTime = parseInt(tempStr[residualRisk], 10);
          LEAD_TIME = leadTime;
        }
      })
      .catch((error) => {
        // console.error('error - ', error);
        throw new Error(error);
      });
    }catch(e){
      console.error('error : ', e);
    }
  }

  componentWillUnmount() {
    this.props.resetrelatedCMDBtasks();
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.editChangeInitialData != null &&
      nextProps.editChangeInitialData.requestId != undefined
    ) {
      currentStatus = nextProps.editChangeInitialData.status;
      axios
        .get(GLOBAL.d2cNumberUrl + nextProps.editChangeInitialData.requestId+'?module=ChangeManagement')
        .then((response) => {
          this.setState({ d2cnumber: response.data });
        });
    }
    dateformat = this.props.date_format;
    date_format = this.props.date_format.substring(0, 10) + " HH:mm:ss";
    this.showHideHamburgerIcon(this.props.role_id);
  }
  hamburgerShowHide(event) {
    this.setState({ hamburgerShowHide: !this.state.hamburgerShowHide });
  }
  statusHamburgerShowHide(event) {
    this.setState({
      statusHamburgerShowHide: !this.state.statusHamburgerShowHide,
    });
  }
  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;
    if (this.props.editChangeInitialData == null) {
      return <div />;
    }
    return (
      <Row className="margin-b-15">
        <Col md={3} xs={12}>
              <h1 bsClass="" className="sPageHeading">
                {this.props.translator["Change"]}
                <div className="nowap">
                <Button title="Open Timeline" bsPrefix=" " onClick={() => { this.props.showTimeline(true); }} className="tinelineicon btn btn-link"><LuCalendarClock /></Button>
                {(['65', '70', '75'].includes(this.props.editChangeInitialData?.statusId) == true && this.props.editChangeInitialData?.type != '20') && <ExportTo exportDocument={this.props.exportDocument} title='Export Change PIR Report' moduleName={'change'}/>}
                </div>

                <div className="order position-re">
                  <div className="lnk">{this.state.d2cnumber} &gt;&gt;{" "}{this.props.editChangeInitialData.changeNumber}</div>
                </div>
              </h1>
        </Col>
        <Col md={9} xs={12}>
        <div className="topRgtNavIcons">
        <div className="toprigLnk">
                <div className="thrlnebtn">
                  <Button
                    title="Statuses"
                    disabled={this.props.disableFieldsForGuestRole}
                    onClick={() => {
                      this.statusHamburgerShowHide();
                    }}
                    className="btnDropMenu"
                  >
                    {currentStatus}
                    <i className="fa fa-angle-down" aria-hidden="true" />
                  </Button>
                  {this.state.statusHamburgerShowHide ? (
                    <BreakFixStatusHamburger
                      date_format={this.props.date_format}
                      getCmdbApprovals={this.props.getCmdbApprovals}
                      fun={this.callbackFun}
                      translator={this.props.translator}
                      changeActionView={this.props.changeActionView}
                      editChangeInitialData={this.props.editChangeInitialData}
                      fieldStatus={this.props.fieldStatus}
                      loadChangeEditData={this.props.loadChangeEditData}
                      changeFieldStatusData={this.props.changeFieldStatusData}
                      loadBreakFixAuditLogDetails={
                        this.props.loadBreakFixAuditLogDetails
                      }
                      realatedTaskCount={this.props.realatedTaskCount}
                      loadImplPlanList={this.props.loadImplPlanList}
                      implememtationPlanList={this.props.implememtationPlanList}
                      showHamburgerIconFlag={this.state.showHamburgerIconFlag}
                      checkRelatedTaskForItem={
                        this.props.checkRelatedTaskForItem
                      }
                      statusBasedHamburgerOptions={
                        this.props.statusBasedHamburgerOptions
                      }
                      loadStatusBasedHamburgerOptions={
                        this.props.loadStatusBasedHamburgerOptions
                      }
                      loadTimelineData={this.props.loadTimelineData}
                      showLoader={this.props.showLoader}
                      loadHamburger={this.props.loadHamburger}
                      changeConflict={this.props.changeConflict}
                      showConflictForm={this.props.showConflictForm}
                      stopLoadHamburger={this.props.stopLoadHamburger}
                      user_id={this.props.user_id}
                      roleIdentification={this.props.roleIdentification}
                      cmbdShowVal={this.props.cmbdShowVal}
                      loadrelatedWorkitems={this.props.loadrelatedWorkitems}
                      resetrelatedCount={this.props.resetrelatedCount}
                      relatedworkitemsCount={this.props.relatedworkitemsCount}
                      checkLeadTime={this.props.checkLeadTime}
                      setErrorExpectedStartDate={
                        this.props.setErrorExpectedStartDate
                      }
                      relatedCMDBTasks={this.props.relatedCMDBTasks}
                      loadBreakFixTaskLogDetails={
                        this.props.loadBreakFixTaskLogDetails
                      }
                      isPendingApproval={this.props.isPendingApproval}
                      rightEditPanel={this.props.rightEditPanel}
                    />
                  ) : null}
                </div>
                <div className="thrlnebtn margin-l-10">
                  <Button
                    bsPrefix=" "
                    title="Actions"
                    onClick={() => {
                      this.hamburgerShowHide();
                    }}
                    className="btnDropMenu bgtran btn-default"
                  >
                    <BiDotsVertical/>
                  </Button>
                  {this.state.hamburgerShowHide ? (
                    <BreakFixHamburger
                      date_format={this.props.date_format}
                      fun={this.callbackFun}
                      translator={this.props.translator}
                      changeActionView={this.props.changeActionView}
                      editChangeInitialData={this.props.editChangeInitialData}
                      fieldStatus={this.props.fieldStatus}
                      loadChangeEditData={this.props.loadChangeEditData}
                      changeFieldStatusData={this.props.changeFieldStatusData}
                      loadBreakFixAuditLogDetails={
                        this.props.loadBreakFixAuditLogDetails
                      }
                      realatedTaskCount={this.props.realatedTaskCount}
                      loadImplPlanList={this.props.loadImplPlanList}
                      implememtationPlanList={this.props.implememtationPlanList}
                      showHamburgerIconFlag={this.state.showHamburgerIconFlag}
                      checkRelatedTaskForItem={
                        this.props.checkRelatedTaskForItem
                      }
                      statusBasedHamburgerOptions={
                        this.props.statusBasedHamburgerOptions
                      }
                      loadStatusBasedHamburgerOptions={
                        this.props.loadStatusBasedHamburgerOptions
                      }
                      loadTimelineData={this.props.loadTimelineData}
                      showLoader={this.props.showLoader}
                      loadHamburger={this.props.loadHamburger}
                      changeConflict={this.props.changeConflict}
                      showConflictForm={this.props.showConflictForm}
                      stopLoadHamburger={this.props.stopLoadHamburger}
                      user_id={this.props.user_id}
                      roleIdentification={this.props.roleIdentification}
                      cmbdShowVal={this.props.cmbdShowVal}
                      loadrelatedWorkitems={this.props.loadrelatedWorkitems}
                      resetrelatedCount={this.props.resetrelatedCount}
                      relatedworkitemsCount={this.props.relatedworkitemsCount}
                      rightEditPanel={this.props.rightEditPanel}
                    />
                  ) : null}
                </div>
              </div>
          <div className={`float-r toprigLnk fulfillHeader`}>
            <Nav className="icnlnk riggep float-l" as="ul">
              <Nav.Item
                title={this.props.translator["Activity Details"]}
                eventKey={4}
                as="li"
              >
                <a className={this.props.actionView == "processHierarchy" ? "active" : ""}
                  href="javascript:void(0)"
                  onClick={() => {
                    this.props.changeActionView("processHierarchy");
                    this.props.rightEditPanel(true);
                  }}
                >
                  <LuActivitySquare/>
                </a>
              </Nav.Item>
              <Nav.Item as="li" title={this.props.translator['Custom Attribute']} eventKey={1}><a href="javascript:void(0)" className={this.props.actionView == "tags" ? "active" : ""} onClick={() => {this.props.changeActionView('tags');this.props.rightEditPanel(true);}}><span><PiTagBold className="svgRotate90"/></span></a></Nav.Item>
              <Nav.Item
                title={this.props.translator["Attachment"]}
                eventKey={4}
                as="li"
              >
                <a className={this.props.actionView == "provideAttachments" ? "active" : ""}
                  href="javascript:void(0)"
                  onClick={() => {
                    this.props.changeActionView("provideAttachments");
                    this.props.rightEditPanel(true);
                  }}
                >
                  <ImAttachment/>
                </a>
              </Nav.Item>
              <Nav.Item
                title={this.props.translator["Tasks"]}
                eventKey={4}
                as="li"
              >
                <a className={this.props.actionView == "task" ? "active" : ""}
                  href="javascript:void(0)"
                  onClick={() => {
                    this.props.changeActionView("task");
                    this.props.rightEditPanel(true);
                  }}
                >
                  <LuClipboardCheck/>
                </a>
              </Nav.Item>
              <Nav.Item
                title={this.props.translator["Approvals"]}
                eventKey={4}
                as="li"
              >
                <a className={this.props.actionView == "approvalStatus" ? "active" : ""}
                  href="javascript:void(0)"
                  onClick={() => {
                    this.props.changeActionView("approvalStatus");
                    this.props.rightEditPanel(true);
                  }}
                >
                  <FaRegThumbsUp/>
                </a>
              </Nav.Item>
              <Nav.Item title={this.props.translator['Watcher Details']} eventKey={1} as="li">
							<a  className={this.props.actionView == "watcher" ? "active" : ""} href="javascript:void(0)" onClick={() => {this.props.changeActionView('watcher');this.props.rightEditPanel(true);}}>
              <span><FaRegEye/><b className="watcher-count">{this.props.watcherCount}</b></span>
							</a>
						</Nav.Item>
              <Nav.Item
                as="li"
                title={this.props.translator["SLA Progress"]}
                eventKey={1}
              >
                <a className={this.props.actionView == "sla" ? "active" : ""}
                  href="javascript:void(0)"
                  onClick={() => {
                    this.props.changeActionView("sla");
                    this.props.rightEditPanel(true);
                  }}
                >
                  <LiaHourglass className="hourGlsWidth" />
                </a>
              </Nav.Item>
              
              <NavDropdown
                className="threeDotul"
                eventKey={5}
                as={"ul"}
                title={
                  <span title="More">
                    <BiDotsVertical/>
                  </span>
                }
                id="threeDotdrpdown"
              >
                <NavDropdown.Item
                  bsPrefix=" "
                  as="li"
                  title="External Attributes"
                  eventKey={1}
                >
                  <Nav.Link onClick={() => {
                    this.props.changeActionView("showExternalSystemAttributes");
                    this.props.rightEditPanel(true);
                  }} href="#"><TbTextWrap />External
                  Attributes</Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item
                  bsPrefix=" "
                  as="li"
                  title="Related Work Items"
                  eventKey={3.1}
                  
                >
                  <Nav.Link href="#" onClick={() => {
                    this.props.changeActionView("relatedWorkItems");
                    this.props.rightEditPanel(true);
                  }}><TbSubtask/>Related Work Items</Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item
                  bsPrefix=" "
                  as="li"
                  title="Related CI"
                  eventKey={3.1}
                 
                >
                 <Nav.Link href="#" onClick={() => {
                    this.props.changeActionView("relatedCI");
                    this.props.rightEditPanel(true);
                  }}><TbCirclesRelation />Related CI</Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item
                  bsPrefix=" "
                  as="li"
                  title="Causal Relationship"
                  eventKey={3.1}
                  
                >
                  <Nav.Link href="#" onClick={() => {
                    this.props.changeActionView("causalRelation");
                    this.props.rightEditPanel(true);
                  }}><RiStackshareLine/>Causal
                  Relationship</Nav.Link>
                </NavDropdown.Item>
                {(this.props.editChangeInitialData.type == "25" ||
                  this.props.editChangeInitialData.type == "30" ||
                  this.props.editChangeInitialData.type == "35") &&
                this.props.editChangeInitialData.status == "Completed" ? (
                  <NavDropdown.Item
                    bsPrefix=" "
                    as="li"
                    title="PIR Details"
                    eventKey={3.1}
                   
                  >
                    <Nav.Link href="#" onClick={() => {
                      this.props.changeActionView("PIRLog");
                      this.props.rightEditPanel(true);
                    }}><LuFileText/>PIR Details</Nav.Link>
                  </NavDropdown.Item>
                ) : null}
                <NavDropdown.Item 
                    bsPrefix=" "
                    as="li"
                    title={this.props.translator["Notify"]}
                    eventKey={3.3}>
                <Nav.Link href="#" onClick={() => {
                    this.props.changeActionView("notify");
                    this.props.rightEditPanel(true);
                  }}><HiOutlineSpeakerphone/>{this.props.translator["Notify"]}</Nav.Link>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Nav className="icnlnk" as="ul">
            <Nav.Item
                bsPrefix=' '
                eventKey={1}
                as="li"
              >
                <a
                  title={this.props.translator["Refresh"]}
                  href="javascript:void(0)"
                  onClick={() => {
                    this.props.RefreshScreen(this.props.editChangeInitialData);
                  }}
                >
                  <HiOutlineRefresh className="refreshFlip"/>
                </a>
              </Nav.Item>
            <Nav.Item bsPrefix=' ' as="li" eventKey={2}>
               {this.props.disableFieldsForGuestRole?
               <Link to="javascript:void(0)" className="anchbtn ctrlKeyPrevent" bsPrefix=' ' title={this.props.translator['Save']} ref={(e) => (this.saveObjRef = e)}>
                  <IoSaveOutline/>
                </Link>
               :<Link to="javascript:void(0)" className="anchbtn ctrlKeyPrevent" bsPrefix=' ' disabled={this.state.showSubmit} title={this.props.translator['Save']}
									onClick={handleSubmit(this.saveChange)} ref={(e) => (this.saveObjRef = e)}>
									{this.state.showSubmit ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/>}
								</Link>}
						</Nav.Item>
              <Nav.Item as="li">
                <NavLink to="/quickViewBreakFix" title={this.props.translator["Close"]} className="ctrlKeyPrevent"
                    onClick={this.closeEditChange} ref={e => this.closeButton = e}><IoClose/>
                </NavLink>
              </Nav.Item>
            </Nav>
          </div>
          </div>
        </Col>
      </Row>
    );
  }
};

let BreakFixStatusHamburger = class BreakFixStatusHamburger extends React.Component {
  constructor(props) {
    super(props);
    this.updateStatus = this.updateStatus.bind(this);
    this.currentAction = this.currentAction.bind(this);
    this.renderHamburgerGOptions = this.renderHamburgerGOptions.bind(this);
    this.onsubmitforReview = this.onsubmitforReview.bind(this);
    this.renderHamburgerSOptions = this.renderHamburgerSOptions.bind(this);
    this.showUnderPIRLatent = this.showUnderPIRLatent.bind(this);
    this.checkUnderPIRValidations = this.checkUnderPIRValidations.bind(this);
  }
  updateStatus(editChangeInitialData, changeFor) {
    let ref = this;
    if (changeFor == "startImplementation") {
      ref.props.loadHamburger();
      axios
        .patch(GLOBAL.changeEditUrl + editChangeInitialData.changeId, {
          status: "55",
          actStartDate: "",
          systemStartDateRequired: "Y",
          modifiedBy: "",
          modifiedByName: "",
        })
        .then((response) => {
          store.dispatch({
            type: "CHANGE_EDIT_DATA_LOAD_SUCCESS",
            editChangeInitialData: {},
          });
          ref.props.loadChangeEditData(editChangeInitialData.changeId);
          ref.props.loadStatusBasedHamburgerOptions(
            "ChangeManagement",
            response.data.status,
            response.data.changeId
          );
        })
        .catch((error) => {
          ref.props.loadHamburger();
        });
    }
    else if (changeFor == "endImplementation") {
      ref.props.loadHamburger();
      axios
        .patch(GLOBAL.changeEditUrl + editChangeInitialData.changeId, {
          status: "60",
          actEndDate: "",
          systemEndDateRequired: "Y",
        })
        .then((resp1) => {
          if (resp1.status == 200 || resp1.status == 201) {
            axios
              .patch(GLOBAL.changeEditUrl + editChangeInitialData.changeId, {
                status:
                  this.props.editChangeInitialData.type == "20" ? "70" : "65",
              })
              .then((resp2) => {
                if (resp2.status == 200 || resp2.status == 201) {
                  store.dispatch({
                    type: "CHANGE_EDIT_DATA_LOAD_SUCCESS",
                    editChangeInitialData: {},
                  });
                  ref.props.loadChangeEditData(
                    editChangeInitialData.changeId
                  );
                  ref.props.loadStatusBasedHamburgerOptions(
                    "ChangeManagement",
                    resp2.data.status,
                    resp2.data.changeId
                  );
                }
              })
              .catch(function (error) {});
          }
        })
        .catch(function (error) {
          ref.props.loadHamburger();
        });
    }
    else if (changeFor == "submitReview") {
      let changeType = (this.props?.editChangeInitialData && this.props?.editChangeInitialData?.type) ? this.props?.editChangeInitialData?.type : '';
      let callApi = true;
      let planList = this.props.implememtationPlanList;
      if(changeType == '20'){
        if (!planList || (planList && (planList.length == 0 || !_.some(planList, ["planType", "Implementation"])) )) {
          callApi = false;
          swal({
            text: "provide implementation plan",
            button: this.props.translator["OK"],
          });
          return;
        }
      }
      else{
        if (planList.length == 0) {
          callApi = false;
          swal({
            text: this.props.translator[
              "provide implementation and backout plan"
            ],
            button: this.props.translator["OK"],
          });
        } else {
          if (!_.some(planList, ["planType", "Implementation"])) {
            callApi = false;
            swal({
              text: this.props.translator["provide implementation plan"],
              button: this.props.translator["OK"],
            });
          } else {
            if (!_.some(planList, ["planType", "Backout"])) {
              callApi = false;
              swal({
                text: this.props.translator["provide backout plan"],
                button: this.props.translator["OK"],
              });
            }
          }
        }
      }
      // if (planList.length == 0) {
      //   callApi = false;
      //   swal({
      //     text: this.props.translator[
      //       "provide implementation and backout plan"
      //     ],
      //     button: this.props.translator["OK"],
      //   });
      // } else {
      //   if (!_.some(planList, ["planType", "Implementation"])) {
      //     callApi = false;
      //     swal({
      //       text: this.props.translator["provide implementation plan"],
      //       button: this.props.translator["OK"],
      //     });
      //   } else {
      //     if (!_.some(planList, ["planType", "Backout"])) {
      //       callApi = false;
      //       swal({
      //         text: this.props.translator["provide backout plan"],
      //         button: this.props.translator["OK"],
      //       });
      //     }
      //   }
      // }
      // console.log('this.props - ', this.props);

      const { editChangeInitialData: { type = '' } = {} } = this.props;
      if (callApi) {
        ref.props.loadHamburger();
        axios
          .patch(GLOBAL.changeEditUrl + editChangeInitialData.changeId, {
            status: "35",
            systemStartDateRequired: "N",
            systemEndDateRequired: "N",
            uiRequest: true,
          })
          .then(function (response) {
            // console.log('response --- ', response);
            if (type != '35' && Array.isArray(response.data)) {
              ref.props.stopLoadHamburger();
              let changeConflictData = response.data;
              let changeConflictJustification = {};
              changeConflictData.forEach((item, i) => {
                let name = "justification" + i;
                changeConflictJustification[name] = item.justification;
              });
              ref.props.changeConflict(
                changeConflictJustification,
                changeConflictData
              );
              ref.props.showConflictForm(true);
            } else {
              store.dispatch({
                type: "CHANGE_EDIT_DATA_LOAD_SUCCESS",
                editChangeInitialData: {},
              });
              ref.props.loadChangeEditData(
                editChangeInitialData.changeId,
                dateformat
              );
              ref.props.loadStatusBasedHamburgerOptions(
                "ChangeManagement",
                response.data.status,
                response.data.changeId
              );
            }
          })
          .catch(function (error) {
            ref.props.loadHamburger();
          });
      }
    }
    else if (changeFor == "cancel") {
      ref.props.loadHamburger();
      axios
        .patch(GLOBAL.changeEditUrl + editChangeInitialData.changeId, {
          status: "75",
          systemStartDateRequired: "N",
          systemEndDateRequired: "N",
        })
        .then(function (response) {
          if (response.status === 200) {
            store.dispatch({
              type: "CHANGE_EDIT_DATA_LOAD_SUCCESS",
              editChangeInitialData: {},
            });
            ref.props.loadChangeEditData(editChangeInitialData.changeId);
            ref.props.loadStatusBasedHamburgerOptions(
              "ChangeManagement",
              response.data.status,
              response.data.changeId
            );
          }
        })
        .catch(function (error) {
          ref.props.loadHamburger();
        });
    }
    else if (changeFor == "underPIR") {
      ref.props.loadHamburger();
      axios
        .patch(GLOBAL.changeEditUrl + editChangeInitialData.changeId, {
          status: "65"
        })
        .then((response) => {
          store.dispatch({
            type: "CHANGE_EDIT_DATA_LOAD_SUCCESS",
            editChangeInitialData: {},
          });
          ref.props.loadChangeEditData(editChangeInitialData.changeId);
          ref.props.loadStatusBasedHamburgerOptions(
            "ChangeManagement",
            response.data.status,
            response.data.changeId
          );
        })
        .catch(function (error) {
          ref.props.loadHamburger();
        });
    }

  }
  currentAction(enableButton) {
    switch (enableButton) {
      case "Provide Attachments":
        {this.props.changeActionView("provideAttachments");
        this.props.rightEditPanel(true);}
        break;
      case "Notify":
        {this.props.changeActionView("notify");
        this.props.rightEditPanel(true);}
        break;
      case "Plans":
        {this.props.changeActionView("implementationPlan");
        this.props.rightEditPanel(true);}
        break;
      case "Approvals":
        {this.props.changeActionView("approvals");
        this.props.rightEditPanel(true);}
        break;
      case "Submit For Review":
        if(this.props.editChangeInitialData.type == '35'){
          this.onsubmitforReview();
          this.props.rightEditPanel(true);
          return;
        }
        if (
          (this.props.editChangeInitialData.type === 30 ||
            this.props.editChangeInitialData.type === "30") &&
          this.props.relatedworkitemsCount === 0
        ) {
          swal({
            text: this.props.translator[
              "Please ensure to select a Incident for submitting Emergency Change"
            ],
            buttons: this.props.translator["OK"],
          });
        } else {
          if (this.props.relatedworkitemsCount !== -2) {
            this.onsubmitforReview();
          }
        }
        break;
      case "Cancel":
        if(this.props.editChangeInitialData.type == "35"){
          if( this.props.editChangeInitialData.statusId !== "30" && this.props.user_id !==
            this.props.editChangeInitialData.changeManagementIndividual){
              swal({
                text: this.props.translator[
                  "Please assign the ticket to yourself before changing the status of the ticket"
                ],
                button: this.props.translator["OK"],
              });
            }
            else{
              this.props.changeActionView("cancel");
              this.props.rightEditPanel(true);
            }
            return;
        }
        if (
          this.props.editChangeInitialData.statusId !== "30" &&
          this.props.user_id !==
            this.props.editChangeInitialData.implementationIndividual &&
          this.props.user_id !==
            this.props.editChangeInitialData.changeManagementIndividual &&
          (this.props.roleIdentification("19") ||
            this.props.roleIdentification("20") ||
            this.props.roleIdentification("51") ||
            this.props.roleIdentification("52"))
        ) {
          swal({
            text: this.props.translator[
              "Please assign the ticket to yourself before changing the status of the ticket"
            ],
            button: this.props.translator["OK"],
          });
        } else if (
          this.props.editChangeInitialData.statusId !== "30" &&
          this.props.user_id ===
            this.props.editChangeInitialData.implementationIndividual &&
          !(
            this.props.roleIdentification("51") ||
            this.props.roleIdentification("52")
          ) &&
          this.props.editChangeInitialData.changeManagementIndividual !==
            this.props.user_id
        ) {
          swal({
            text: this.props.translator[
              "Please assign the ticket to yourself before changing the status of the ticket"
            ],
            button: this.props.translator["OK"],
          });
        } else if (
          this.props.editChangeInitialData.statusId !== "30" &&
          this.props.editChangeInitialData.changeManagementIndividual ===
            this.props.user_id &&
          !(
            this.props.roleIdentification("19") ||
            this.props.roleIdentification("20")
          ) &&
          this.props.user_id !==
            this.props.editChangeInitialData.implementationIndividual
        ) {
          swal({
            text: this.props.translator[
              "Please assign the ticket to yourself before changing the status of the ticket"
            ],
            button: this.props.translator["OK"],
          });
        } else if (
          this.props.editChangeInitialData.statusId !== "30" &&
          this.props.user_id !==
            this.props.editChangeInitialData.implementationIndividual &&
          (this.props.roleIdentification("51") ||
            this.props.roleIdentification("52")) &&
          (this.props.editChangeInitialData.type !== "20" ||
            this.props.editChangeInitialData.type !== 20)
        ) {
          swal({
            text: this.props.translator[
              "Please assign the ticket to yourself before changing the status of the ticket"
            ],
            button: this.props.translator["OK"],
          });
        } else {
            this.props.changeActionView("cancel");
            this.props.rightEditPanel(true);
            // this.updateStatus(this.props.editChangeInitialData, "cancel");
        }
        break;
      case "Re-Plan":
        if (
          this.props.user_id !=
            this.props.editChangeInitialData.changeManagementIndividual &&
          this.props.user_id !=
            this.props.editChangeInitialData.implementationIndividual &&
          this.props.user_id != this.props.editChangeInitialData.requesterId
        ) {
          swal({
            text: this.props.translator[
              "Please assign the ticket to yourself before changing the status of the ticket"
            ],
            button: this.props.translator["OK"],
          });
        } else {
          this.props.changeActionView("replan");
        this.props.rightEditPanel(true);}
        break;
      case "Start Implementation":
        let expectedStartDate = datetimeConvertor(
          this.props.editChangeInitialData.expectedStartDate,
          dateformatCookie
        );
        let isExpectedDatePassed = moment().isBefore(expectedStartDate);
        if (isExpectedDatePassed) {
          swal({
            text: this.props.translator[
              "Please note that the change implementation can not be started before the scheduled start date"
            ],
            button: this.props.translator["OK"],
          });
        } else if (
          this.props.editChangeInitialData.status === "Scheduled" &&
          (this.props.editChangeInitialData.type != "20" ||
            this.props.editChangeInitialData.type != 20) &&
          (this.props.editChangeInitialData.implementationGroup === "" ||
            this.props.editChangeInitialData.implementationGroup === 0 ||
            this.props.editChangeInitialData.implementationGroup === "0")
        )
          swal({
            text: this.props.translator["Please select implementation group"],
            button: this.props.translator["OK"],
          });
        else if (
          this.props.editChangeInitialData.status === "Scheduled" &&
          (this.props.editChangeInitialData.type != "20" ||
            this.props.editChangeInitialData.type != 20) &&
          this.props.editChangeInitialData.implementationIndividual !=
            this.props.user_id
        )
          swal({
            text: this.props.translator[
              "Please assign individual to yourself from implementation group"
            ],
            button: this.props.translator["OK"],
          });
        else if (
          this.props.editChangeInitialData.type == 20 &&
          (GLOBAL.implementationIndividual == "" ||
            GLOBAL.implementationIndividual == 0)
        ) {
          swal({
            text: this.props.translator[
              "Please select individual from implementation group"
            ],
            button: this.props.translator["OK"],
          });
        } else if (
          this.props.editChangeInitialData.type == 20 &&
          GLOBAL.implementationIndividual != this.props.user_id.toString()
        ) {
          swal({
            text: this.props.translator[
              "Please assign individual to yourself from implementation group"
            ],
            button: this.props.translator["OK"],
          });
        } else if (
          this.props.editChangeInitialData.status === "Scheduled" &&
          moment(
            this.props.editChangeInitialData.expectedEndDate,
            date_format
          ) < moment(moment(), date_format) &&
          this.props.editChangeInitialData.type !== "30"
        ) {
          swal({
            text: this.props.translator[
              "Scheduled End Date should be atleast today's date."
            ],
            button: this.props.translator["OK"],
          });
        } else {
          this.props.changeActionView("startImplementation");
          this.updateStatus(
            this.props.editChangeInitialData,
            "startImplementation"
          );
          this.props.rightEditPanel(true);
        }
        break;
      case "End Implementation":
        if (
          this.props.editChangeInitialData.status === "Under Implementation" &&
          (this.props.editChangeInitialData.type != "20" ||
            this.props.editChangeInitialData.type != 20) &&
          this.props.editChangeInitialData.implementationIndividual !==
            this.props.user_id
        )
          swal({
            text: this.props.translator[
              "Please assign individual to yourself from implementation group"
            ],
            button: this.props.translator["OK"],
          });
        else if (
          this.props.editChangeInitialData.status === "Under Implementation" &&
          (this.props.editChangeInitialData.type != "20" ||
            this.props.editChangeInitialData.type != 20) &&
          (this.props.editChangeInitialData.implementationGroup === "" ||
            this.props.editChangeInitialData.implementationGroup === 0 ||
            this.props.editChangeInitialData.implementationGroup === "0")
        )
          swal({
            text: this.props.translator["Please select implementation group"],
            button: this.props.translator["OK"],
          });
        else if (
          this.props.editChangeInitialData.type == 20 &&
          (GLOBAL.implementationIndividual == "" ||
            GLOBAL.implementationIndividual == 0)
        ) {
          swal({
            text: this.props.translator[
              "Please select individual from implementation group"
            ],
            button: this.props.translator["OK"],
          });
        } else if (
          this.props.editChangeInitialData.type == 20 &&
          GLOBAL.implementationIndividual != this.props.user_id.toString()
        ) {
          swal({
            text: this.props.translator[
              "Please assign individual to yourself from implementation group"
            ],
            button: this.props.translator["OK"],
          });
        } else if (
          this.props.editChangeInitialData.cmdbUpdateNeeded === "Yes" &&
          this.props.relatedCMDBTasks === 0
        ) {
          swal({
            text: this.props.translator[
              "Please attach at least one CMDB Update type Task to proceed"
            ],
            button: this.props.translator["OK"],
          });
        } else if (this.props.realatedTaskCount > 0) {
          swal({
            text: this.props.translator[
              "Tasks related to the item are not completed yet"
            ],
            button: this.props.translator["OK"],
          });
        } else if (
          this.props.realatedTaskCount === 0 &&
          this.props.editChangeInitialData.type === "20"
        ) {
          this.props.changeActionView("Sub Status");
          this.props.rightEditPanel(true);
        } else {
          this.props.changeActionView("endImplementation");
          this.updateStatus(
            this.props.editChangeInitialData,
            "endImplementation"
          );
          this.props.rightEditPanel(true);
        }
        break;
      case "Re-Submit For Review":
        if (
          this.props.user_id !=
            this.props.editChangeInitialData.changeManagementIndividual &&
          (this.props.roleIdentification("19") ||
            this.props.roleIdentification("20")) &&
          (this.props.editChangeInitialData.type != "20" ||
            this.props.editChangeInitialData.type != 20) &&
          this.props.editChangeInitialData.status !== "Referred Back"
        ){
          swal({
            text: this.props.translator[
              "Please assign the ticket to yourself before changing the status of the ticket"
            ],
            button: this.props.translator["OK"],
          });
        }
        else if( this.props.editChangeInitialData && this.props.editChangeInitialData.type && (this.props.editChangeInitialData.type == '25' || this.props.editChangeInitialData.type == '30') && !(this.props.editChangeInitialData.riskAssessment && Array.isArray(this.props.editChangeInitialData.riskAssessment) && this.props.editChangeInitialData.riskAssessment.length > 0) ){
          swal({
            text: this.props.translator["Please fill Risk Assessment"] || "Please fill Risk Assessment",
            button: this.props.translator["OK"],
            });
            return false;
        }
        else{ this.props.changeActionView("resubmitReview");
        this.props.rightEditPanel(true);}
        break;
      case "Post Implementation Review":
        if (
          this.props.user_id !=
            this.props.editChangeInitialData.changeManagementIndividual &&
          (this.props.roleIdentification("19") ||
            this.props.roleIdentification("20")) &&
          (this.props.editChangeInitialData.type != "20" ||
            this.props.editChangeInitialData.type != 20)
        ) {
          swal({
            text: this.props.translator[
              "Please assign the ticket to yourself before changing the status of the ticket"
            ],
            button: this.props.translator["OK"],
          });
        } else if (this.props.realatedTaskCount > 0) {
          swal({
            text: this.props.translator[
              "Tasks related to the item are not completed yet"
            ],
            button: this.props.translator["OK"],
          });
        } else {
          this.props.changeActionView("pir");
          this.props.rightEditPanel(true);
        }
        break;
      case "CMDB Update":
        if (
          this.props.editChangeInitialData.status === "Under PIR" &&
          (this.props.editChangeInitialData.type != "20" ||
            this.props.editChangeInitialData.type != 20) &&
          this.props.editChangeInitialData.implementationIndividual !==
            this.props.user_id
        )
          swal({
            text: this.props.translator[
              "Please assign individual to yourself from implementation group"
            ],
            button: this.props.translator["OK"],
          });
        else if (
          this.props.editChangeInitialData.status === "Under PIR" &&
          (this.props.editChangeInitialData.type != "20" ||
            this.props.editChangeInitialData.type != 20) &&
          (this.props.editChangeInitialData.implementationGroup === "" ||
            this.props.editChangeInitialData.implementationGroup === 0 ||
            this.props.editChangeInitialData.implementationGroup === "0")
        )
          swal({
            text: this.props.translator["Please select implementation group"],
            button: this.props.translator["OK"],
          });
        else {this.props.changeActionView("cmdbUpdate");
        this.props.rightEditPanel(true);}
        break;

        case "Under PIR":
          // console.log('this.props.editChangeInitialData - ', this.props.editChangeInitialData);
          if(!(this.props.editChangeInitialData.status === "Under Review" && this.props.editChangeInitialData.type == "35")){
            return;
          }

          const { serviceId = '', changeId = '' } = this.props.editChangeInitialData;
          let { ciId = '', ciClassId = '' } = this.props.editChangeInitialData;
          ciId = (!ciId || ciId == 'null') ? undefined : ciId;
          ciClassId = (!ciClassId || ciClassId == 'null') ? undefined : ciClassId;


          this.props.getCmdbApprovals(ciId, '', '', 1, 10)
          .then((resp) => {
            // console.log('resp - ', resp);
            if(resp && resp.approverList){
              const approverList = structuredClone(resp.approverList);
              const filtered = approverList.filter(item => item.rfcChangeType == '35');
              if(approverList.length && filtered.length){
                let str={};
                str.itemId=changeId;
                str.moduleId=parseInt('14', 10);
                str.statusId='N';
                str = JSON.stringify(str);
                api.get(GLOBAL.changeTotalApprovalUrl,{headers:{'query':str}}).then(res => {
                  // console.log('res - ', res);
                  if(res && res.data && res.data.length > 0 && res.data.length == filtered.length){
                    const approvalArr = res.data;
                    // let approvalArr = [{status: 'Approved'}, {status: 'Approved'}, {status: 'Approved'}];
                    const count = (approvalArr).filter(item => item.status != 'Approved');
                    // console.log('**********', {approvalArr, count});
                    this.checkUnderPIRValidations( (count.length ? true : false) );
                  }
                  else{
                    throw new Error('Error');
                  }
                }).catch(err => {
                  // console.log('err - ', err);
                  this.checkUnderPIRValidations(true);
                });
              }
              else{
                this.checkUnderPIRValidations(false);
              }
            }
            else{
              throw new Error('Error');
            }

          }).catch(error => {
            // console.log('error - ', error);
            this.checkUnderPIRValidations(true);
          });
        break;
    }
  }

  renderHamburgerGOptions(statusBasedHamburgerOptions) {
    let hamburgerOption = statusBasedHamburgerOptions;
    if (statusBasedHamburgerOptions.length == 0) {
      return null;
    }
    return hamburgerOption.map((item, index) => {
      if (item.action !== "L") {
        if (item.action == "G") {
          if (
            (this.props.fieldStatus.notify == false &&
              item.enableButton == "Notify") ||
            (this.props.editChangeInitialData.status == "Completed" &&
              item.enableButton == "Approvals")
          ) {
            item.enableButton = "blank";
          }
          if (
            this.props.editChangeInitialData.status !== "Cancelled" &&
            this.props.editChangeInitialData.status !== "Closed" &&
            this.props.editChangeInitialData.status !== "Rejected" &&
            this.props.editChangeInitialData.status !== "Fixed" &&
            this.props.editChangeInitialData.status !== "Completed" &&
            this.props.fieldStatus.provideAttachments == false &&
            item.enableButton == "Provide Attachments"
          ) {
            item.enableButton = "blank";
          }
          return (
            <ListGroup.Item bsClass="" key={index}>
              <Nav.Item
                title={item.enableButton}
                onClick={() => {
                  this.currentAction(item.enableButton)
                    this.handleClickOutside();
                }}
              >
                {item.enableButton == "blank" ? null : (
                  <MdKeyboardDoubleArrowRight/>
                )}
                {item.enableButton == "blank" ? null : item.enableButton}
              </Nav.Item>
            </ListGroup.Item>
          );
        }
      }
    });
  }
  onsubmitforReview() {
    try{
      if(this.props.editChangeInitialData.status === "Draft" &&
      (this.props.editChangeInitialData.type === "25") &&
      this.props.editChangeInitialData.urgency === "10" && LEAD_TIME == null){
        swal({
          text: this.props.translator['Please fill Risk Assessment'],
          button: this.props.translator["OK"],
        });
        return false;
      }
      let leadTime = moment().tz(timezone).add(LEAD_TIME, "hours");
      // let displayLeadTime = Math.ceil(LEAD_TIME / 24);
      // let showLeadTime =
      //   displayLeadTime > 1
      //     ? displayLeadTime + " Days "
      //     : displayLeadTime + " Day ";
      let showLeadTime = (LEAD_TIME < 24) ? `${LEAD_TIME} hours` : (((LEAD_TIME % 24) == 0) ? `${Math.floor(LEAD_TIME/24)} days` : `${Math.floor(LEAD_TIME/24)} days ${LEAD_TIME%24} hours` );
      let expectedStartDate = moment.tz(this.props.editChangeInitialData.expectedStartDate,timezone).format(this.props.date_format.substring(0, 10) + " HH:mm:ss")
      if (
        this.props.editChangeInitialData.status === "Draft" &&
        (this.props.editChangeInitialData.type === "25") &&
        this.props.editChangeInitialData.urgency === "10" &&
        (this.props.editChangeInitialData.expectedStartDate === "" ||
          moment(expectedStartDate).isBefore(
            leadTime
          )) /*&& (moment(expectedStartDate).isAfter(startDate)) */
      ) {
        swal({
          text:
            this.props.translator[
              "This Change cannot be submitted as the selected 'Scheduled Start Date' is less than the lead time required"
            ] +
            " (" +
            showLeadTime +
            ") " +
            this.props.translator["for this Change"] +
            ". " +
            this.props.translator["Please change the Scheduled Start Date"],
          button: this.props.translator["OK"],
        });
      }
      else if(this.props.editChangeInitialData.statusId==='30' && this.props.editChangeInitialData.type==="35" &&  (this.props.editChangeInitialData.changeManagementGroup==="" || this.props.editChangeInitialData.changeManagementGroup==="0" || this.props.editChangeInitialData.changeManagementGroup===0)){
        swal({
               text: this.props.translator["Please select change management group"],
               button: this.props.translator["OK"],
        });
      }else if(this.props.editChangeInitialData.statusId==='30' & this.props.editChangeInitialData.type==="35" &&  (this.props.editChangeInitialData.implementationGroup==="" || this.props.editChangeInitialData.implementationGroup==="0" || this.props.editChangeInitialData.implementationGroup===0)){
        swal({
           text: this.props.translator["Please select implementation group"],
           button: this.props.translator["OK"],
        });
      } 
      else if( this.props.editChangeInitialData && this.props.editChangeInitialData.type && (this.props.editChangeInitialData.type == '25' || this.props.editChangeInitialData.type == '30') && !(this.props.editChangeInitialData.riskAssessment && Array.isArray(this.props.editChangeInitialData.riskAssessment) && this.props.editChangeInitialData.riskAssessment.length > 0) ){
        swal({
          text: this.props.translator["Please fill Risk Assessment"] || "Please fill Risk Assessment",
          button: this.props.translator["OK"],
          });
          return false;
      }
      else {
        this.props.changeActionView("submitReview");
        this.updateStatus(this.props.editChangeInitialData, "submitReview");
      }
    }catch(e){
      console.error('Error : ', e);
    }
  }
  componentWillMount() {
    let planList = this.props.implememtationPlanList;
    if (
      this.props.editChangeInitialData.type === 30 ||
      this.props.editChangeInitialData.type === "30"
    ) {
      this.props.loadrelatedWorkitems(
        this.props.editChangeInitialData.changeId,
        "Breakfix"
      );
    } else {
      let recordCount = 0;
      this.props.resetrelatedCount(recordCount);
    }
    this.props.loadImplPlanList(
      "ChangeManagement",
      this.props.editChangeInitialData.changeId
    );
    this.props.checkRelatedTaskForItem(
      this.props.editChangeInitialData.changeId,
      "ChangeManagement"
    );

    // if (
    //   (this.props.editChangeInitialData.statusId === "55" &&
    //   this.props.editChangeInitialData.cmdbUpdateNeeded === "Yes" &&
    //   this.props.relatedCMDBTasks === 0) || 
    //   (
    //     (this.props.editChangeInitialData.type === 35 ||
    //       this.props.editChangeInitialData.type === "35") && 
    //       this.props.editChangeInitialData.cmdbUpdateNeeded === "Yes"
    //   )
    // ) {
      let moduleName = "ChangeManagement",
        sort = "asc";
      this.props.loadBreakFixTaskLogDetails(
        this.props.editChangeInitialData.changeId,
        moduleName,
        sort,
        this.props.editChangeInitialData.consumerCompany
      );
      moduleName = sort = null;
   // }
  }
  handleClickOutside() {
    this.props.fun(false);
  }
  renderHamburgerSOptions(statusBasedHamburgerOptions) {
    // console.log('*****************', this.props);
    const { editChangeInitialData: { type } = {} } = this.props;
    if (statusBasedHamburgerOptions.length == 0) {
      return null;
    }

    const isUnderPIRObjExist = statusBasedHamburgerOptions.find(item => item.enableButton == 'Under PIR');
		if(this.showUnderPIRLatent() && !isUnderPIRObjExist){
			statusBasedHamburgerOptions.unshift({action: 'S', enableButton: 'Under PIR'});
		}

    return statusBasedHamburgerOptions.map((item, index) => {
      if (item.action !== "L") {
        if (item.action == "S") {
          if (!this.props.cmbdShowVal && item.enableButton == "CMDB Update") {
            return "";
          }
          if(type == '35' && item.enableButton == 'Re-Plan'){
            return '';
          }
          return (
            <ListGroup.Item as="li" bsClass="" key={index}>
              <Nav.Item
                title={item.enableButton}
                onClick={() => {
                  this.currentAction(item.enableButton)
                    this.handleClickOutside();
                }}
              >
                <MdKeyboardDoubleArrowRight/>
                {item.enableButton}
              </Nav.Item>
            </ListGroup.Item>
          );
        }
      }
    });
  }

  showUnderPIRLatent(){
    try{
      // console.log('this.props - ', this.props);
      const { editChangeInitialData: { statusId = '', type = '' } = {} } = this.props;
      return (statusId == '35' && type == '35');
    }catch(e){
      // console.error(e);
      return false;
    }
  }

  checkUnderPIRValidations(isPendingApproval = true){
    try{
      if(isPendingApproval == true){
        swal({
          text: 'Approvals are still pending for this Change',
          button: this.props.translator["OK"],
        });
        return;
      }
      if(this.props.editChangeInitialData.changeManagementGroup === "" ||
      this.props.editChangeInitialData.changeManagementGroup === "0" ||
      this.props.editChangeInitialData.changeManagementGroup === 0){
        swal({
          text: this.props.translator["Please select change management group"],
          button: this.props.translator["OK"],
        });
        return;
      }

      if (
        this.props.editChangeInitialData.changeManagementIndividual != this.props.user_id.toString()
      ) {
        swal({
          text: "Please assign individual to yourself from change management group",
          button: this.props.translator["OK"],
        });
        return;
      } 

      if(this.props.editChangeInitialData.cmdbUpdateNeeded === "Yes"){
        if(this.props.relatedCMDBTasks === 0){
          swal({
            text: this.props.translator[
              "Please attach at least one CMDB Update type Task to proceed"
            ],
            button: this.props.translator["OK"],
          });
          return;
        }
        if (this.props.realatedTaskCount > 0) {
          swal({
            text: this.props.translator[
              "Tasks related to the item are not completed yet"
            ],
            button: this.props.translator["OK"],
          });
          return;
        }
        if (
          this.props.realatedTaskCount === 0 &&
          this.props.editChangeInitialData.type === "20"
        ) {
          this.props.changeActionView("Sub Status");
          this.props.rightEditPanel(true);
        }
        else {
          this.props.changeActionView("underPIR");
          this.updateStatus(
            this.props.editChangeInitialData,
            "underPIR"
          );
          this.props.rightEditPanel(true);
        }
      }
      else{
        this.props.changeActionView("underPIR");
          this.updateStatus(
            this.props.editChangeInitialData,
            "underPIR"
          );
          this.props.rightEditPanel(true);
      }
    }catch(e){
      console.error(e);
    }
  }



  render() {
    return (
      <div className="breakFixHamburger statusHamburger">
        {this.props.showLoader ? (
          <ListGroup bsClass="" className="myLinks">
            <ListLoader />
          </ListGroup>
        ) : (
          <ListGroup as="ul" bsClass="" className="myLinks">
            {this.renderHamburgerSOptions(
              this.props.statusBasedHamburgerOptions
            )}
          </ListGroup>
        )}
      </div>
    );
  }
};
BreakFixStatusHamburger = OnClickOutside(BreakFixStatusHamburger);

let BreakFixHamburger = class BreakFixHamburger extends React.Component {
  constructor(props) {
    super(props);
    this.updateStatus = this.updateStatus.bind(this);
    this.currentAction = this.currentAction.bind(this);
    this.onsubmitforReview = this.onsubmitforReview.bind(this);
  }
  updateStatus(editChangeInitialData, changeFor) {
    let ref = this;
    if (changeFor == "startImplementation") {
      ref.props.loadHamburger();
      axios
        .patch(GLOBAL.changeEditUrl + editChangeInitialData.changeId, {
          status: "55",
          actStartDate: "",
          systemStartDateRequired: "Y",
          modifiedBy: "",
          modifiedByName: "",
        })
        .then((response) => {
          store.dispatch({
            type: "CHANGE_EDIT_DATA_LOAD_SUCCESS",
            editChangeInitialData: {},
          });
          ref.props.loadChangeEditData(editChangeInitialData.changeId);
          ref.props.loadStatusBasedHamburgerOptions(
            "ChangeManagement",
            response.data.status,
            response.data.changeId
          );
        })
        .catch((error) => {
          ref.props.loadHamburger();
        });
    } else {
      if (changeFor == "endImplementation") {
        ref.props.loadHamburger();
        axios
          .patch(GLOBAL.changeEditUrl + editChangeInitialData.changeId, {
            status: "60",
            actEndDate: "",
            systemEndDateRequired: "Y",
          })
          .then((resp1) => {
            if (resp1.status == 200 || resp1.status == 201) {
              axios
                .patch(GLOBAL.changeEditUrl + editChangeInitialData.changeId, {
                  status:
                    this.props.editChangeInitialData.type == "20" &&
                    this.props.editChangeInitialData.cmdbUpdateDone == true &&
                    this.props.editChangeInitialData.cmdbUpdateNeeded == "Yes"
                      ? "70"
                      : "65",
                })
                .then((resp2) => {
                  if (resp2.status == 200 || resp2.status == 201) {
                    store.dispatch({
                      type: "CHANGE_EDIT_DATA_LOAD_SUCCESS",
                      editChangeInitialData: {},
                    });
                    ref.props.loadChangeEditData(
                      editChangeInitialData.changeId
                    );
                    ref.props.loadStatusBasedHamburgerOptions(
                      "ChangeManagement",
                      resp2.data.status,
                      resp2.data.changeId
                    );
                  }
                })
                .catch(function (error) {});
            }
          })
          .catch(function (error) {
            ref.props.loadHamburger();
          });
      } else {
        if (changeFor == "submitReview") {
          let callApi = true;
          let planList = this.props.implememtationPlanList;
          if (planList.length == 0) {
            callApi = false;
            swal({
              text: this.props.translator[
                "provide implementation and backout plan"
              ],
              button: this.props.translator["OK"],
            });
          } else {
            if (!_.some(planList, ["planType", "Implementation"])) {
              callApi = false;
              swal({
                text: this.props.translator["provide implementation plan"],
                button: this.props.translator["OK"],
              });
            } else {
              if (!_.some(planList, ["planType", "Backout"])) {
                callApi = false;
                swal({
                  text: this.props.translator["provide backout plan"],
                  button: this.props.translator["OK"],
                });
              }
            }
          }

          const { editChangeInitialData: { type = '' } = {} } = this.props;

          if (callApi) {
            ref.props.loadHamburger();
            axios
              .patch(GLOBAL.changeEditUrl + editChangeInitialData.changeId, {
                status: "35",
                systemStartDateRequired: "N",
                systemEndDateRequired: "N",
                uiRequest: true,
              })
              .then(function (response) {
                // console.log('response -- ', response);
                if (type != '35' && Array.isArray(response.data)) {
                  ref.props.stopLoadHamburger();
                  let changeConflictData = response.data;
                  let changeConflictJustification = {};
                  changeConflictData.forEach((item, i) => {
                    let name = "justification" + i;
                    changeConflictJustification[name] = item.justification;
                  });
                  ref.props.changeConflict(
                    changeConflictJustification,
                    changeConflictData
                  );
                  ref.props.showConflictForm(true);
                } else {
                  store.dispatch({
                    type: "CHANGE_EDIT_DATA_LOAD_SUCCESS",
                    editChangeInitialData: {},
                  });
                  ref.props.loadChangeEditData(
                    editChangeInitialData.changeId,
                    dateformat
                  );
                  ref.props.loadStatusBasedHamburgerOptions(
                    "ChangeManagement",
                    response.data.status,
                    response.data.changeId
                  );
                }
              })
              .catch(function (error) {
                ref.props.loadHamburger();
              });
          }
        } else {
          if (changeFor == "cancel") {
            ref.props.loadHamburger();
            axios
              .patch(GLOBAL.changeEditUrl + editChangeInitialData.changeId, {
                status: "75",
                systemStartDateRequired: "N",
                systemEndDateRequired: "N",
              })
              .then(function (response) {
                if (response.status === 200) {
                  store.dispatch({
                    type: "CHANGE_EDIT_DATA_LOAD_SUCCESS",
                    editChangeInitialData: {},
                  });
                  ref.props.loadChangeEditData(editChangeInitialData.changeId);
                  ref.props.loadStatusBasedHamburgerOptions(
                    "ChangeManagement",
                    response.data.status,
                    response.data.changeId
                  );
                }
              })
              .catch(function (error) {
                ref.props.loadHamburger();
              });
          }
        }
      }
    }
  }
  currentAction(enableButton) {
    switch (enableButton) {
      case "Provide Attachments":{
        this.props.changeActionView("provideAttachments");
        this.props.rightEditPanel(true);}
        break;
      case "Notify":
        {this.props.changeActionView("notify");
        this.props.rightEditPanel(true);}
        break;
      case "Plans":
        {this.props.changeActionView("implementationPlan");
        this.props.rightEditPanel(true);}
        break;
      case "Approvals":
        {this.props.changeActionView("approvals");
        this.props.rightEditPanel(true);}
        break;
      case "Submit For Review":
        if(this.props.editChangeInitialData.type == '35'){
          this.onsubmitforReview();
          return;
        }
        if (
          (this.props.editChangeInitialData.type === 30 ||
            this.props.editChangeInitialData.type === "30") &&
          this.props.relatedworkitemsCount === 0
        ) {
          swal({
            text: this.props.translator[
              "Please ensure to select a Incident for submitting Emergency Change"
            ],
            buttons: this.props.translator["OK"],
          });
        } else {
          if (this.props.relatedworkitemsCount !== -2) {
            this.onsubmitforReview();
          }
        }
        break;
      case "Cancel":
        if (
          this.props.editChangeInitialData.statusId !== "30" &&
          this.props.user_id !==
            this.props.editChangeInitialData.implementationIndividual &&
          this.props.user_id !==
            this.props.editChangeInitialData.changeManagementIndividual &&
          (this.props.roleIdentification("19") ||
            this.props.roleIdentification("20") ||
            this.props.roleIdentification("51") ||
            this.props.roleIdentification("52"))
        ) {
          swal({
            text: this.props.translator[
              "Please assign the ticket to yourself before changing the status of the ticket"
            ],
            button: this.props.translator["OK"],
          });
        } else if (
          this.props.editChangeInitialData.statusId !== "30" &&
          this.props.user_id ===
            this.props.editChangeInitialData.implementationIndividual &&
          !(
            this.props.roleIdentification("51") ||
            this.props.roleIdentification("52")
          ) &&
          this.props.editChangeInitialData.changeManagementIndividual !==
            this.props.user_id
        ) {
          swal({
            text: this.props.translator[
              "Please assign the ticket to yourself before changing the status of the ticket"
            ],
            button: this.props.translator["OK"],
          });
        } else if (
          this.props.editChangeInitialData.statusId !== "30" &&
          this.props.editChangeInitialData.changeManagementIndividual ===
            this.props.user_id &&
          !(
            this.props.roleIdentification("19") ||
            this.props.roleIdentification("20")
          ) &&
          this.props.user_id !==
            this.props.editChangeInitialData.implementationIndividual
        ) {
          swal({
            text: this.props.translator[
              "Please assign the ticket to yourself before changing the status of the ticket"
            ],
            button: this.props.translator["OK"],
          });
        } else {
            this.props.changeActionView("cancel");
            this.updateStatus(this.props.editChangeInitialData, "cancel");
            this.props.rightEditPanel(true);
        }
        break;
      case "Re-Plan":
        if (
          this.props.user_id !=
            this.props.editChangeInitialData.changeManagementIndividual &&
          this.props.user_id !=
            this.props.editChangeInitialData.implementationIndividual &&
          this.props.user_id != this.props.editChangeInitialData.requesterId
        ) {
          swal({
            text: this.props.translator[
              "Please assign the ticket to yourself before changing the status of the ticket"
            ],
            button: this.props.translator["OK"],
          });
        } else {
          this.props.changeActionView("replan");
        this.props.rightEditPanel(true);
        }
        break;
      case "Start Implementation":
        let expectedStartDate = datetimeConvertor(
          this.props.editChangeInitialData.expectedStartDate,
          dateformatCookie
        );
        let isExpectedDatePassed = moment().isBefore(expectedStartDate);
        if (isExpectedDatePassed) {
          swal({
            text: this.props.translator[
              "Please note that the change implementation can not be started before the scheduled start date"
            ],
            button: this.props.translator["OK"],
          });
        } else if (
          this.props.editChangeInitialData.status === "Scheduled" &&
          (this.props.editChangeInitialData.type != "20" ||
            this.props.editChangeInitialData.type != 20) &&
          (this.props.editChangeInitialData.implementationGroup === "" ||
            this.props.editChangeInitialData.implementationGroup === 0 ||
            this.props.editChangeInitialData.implementationGroup === "0")
        )
          swal({
            text: this.props.translator["Please select implementation group"],
            button: this.props.translator["OK"],
          });
        else if (
          this.props.editChangeInitialData.status === "Scheduled" &&
          (this.props.editChangeInitialData.type != "20" ||
            this.props.editChangeInitialData.type != 20) &&
          this.props.editChangeInitialData.implementationIndividual !=
            this.props.user_id
        )
          swal({
            text: this.props.translator[
              "Please assign individual to yourself from implementation group"
            ],
            button: this.props.translator["OK"],
          });
        else if (
          this.props.editChangeInitialData.status === "Scheduled" &&
          moment(
            this.props.editChangeInitialData.expectedEndDate,
            date_format
          ) < moment(moment(), date_format) &&
          this.props.editChangeInitialData.type !== "30"
        ) {
          swal({
            text: this.props.translator[
              "Scheduled End Date should be atleast today's date."
            ],
            button: this.props.translator["OK"],
          });
        } else {
          this.props.changeActionView("startImplementation");
          this.updateStatus(
            this.props.editChangeInitialData,
            "startImplementation"
          );
          this.props.rightEditPanel(true);
        }
        break;
      case "End Implementation":
        if (
          this.props.editChangeInitialData.status === "Under Implementation" &&
          (this.props.editChangeInitialData.type != "20" ||
            this.props.editChangeInitialData.type != 20) &&
          this.props.editChangeInitialData.implementationIndividual !==
            this.props.user_id
        )
          swal({
            text: this.props.translator[
              "Please assign individual to yourself from implementation group"
            ],
            button: this.props.translator["OK"],
          });
        else if (
          this.props.editChangeInitialData.status === "Under Implementation" &&
          (this.props.editChangeInitialData.type != "20" ||
            this.props.editChangeInitialData.type != 20) &&
          (this.props.editChangeInitialData.implementationGroup === "" ||
            this.props.editChangeInitialData.implementationGroup === 0 ||
            this.props.editChangeInitialData.implementationGroup === "0")
        )
          swal({
            text: this.props.translator["Please select implementation group"],
            button: this.props.translator["OK"],
          });
        else if (this.props.realatedTaskCount > 0) {
          swal({
            text: this.props.translator[
              "Tasks related to the item are not completed yet"
            ],
            button: this.props.translator["OK"],
          });
        } else if (
          this.props.realatedTaskCount === 0 &&
          this.props.editChangeInitialData.type === "20"
        ) {
          this.props.changeActionView("Sub Status");
          this.props.rightEditPanel(true);
        } else {
          if (
            this.props.editChangeInitialData.type == "20" &&
            this.props.editChangeInitialData.cmdbUpdateDone == false &&
            this.props.editChangeInitialData.cmdbUpdateNeeded == "Yes"
          ) {
            swal({
              text: this.props.translator[
                "Please complete CMDB Update before proceeding to End Implementation."
              ],
              button: this.props.translator["OK"],
            });
            return false;
          }
          if (
            this.props.editChangeInitialData.type == "20" &&
            this.props.editChangeInitialData.cmdbUpdateNeeded == "No"
          ) {
            this.props.changeActionView("cmdbUpdate");
            this.props.rightEditPanel(true);
            return false;
          }
          this.props.changeActionView("endImplementation");
          this.updateStatus(
            this.props.editChangeInitialData,
            "endImplementation"
          );
          this.props.rightEditPanel(true);
        }
        break;
      case "Re-Submit For Review":
        if (
          this.props.user_id !=
            this.props.editChangeInitialData.changeManagementIndividual &&
          (this.props.roleIdentification("19") ||
            this.props.roleIdentification("20")) &&
          (this.props.editChangeInitialData.type != "20" ||
            this.props.editChangeInitialData.type != 20) &&
            this.props.editChangeInitialData.status !== "Referred Back"
        )
          swal({
            text: this.props.translator[
              "Please assign the ticket to yourself before changing the status of the ticket"
            ],
            button: this.props.translator["OK"],
          });
        else {this.props.changeActionView("resubmitReview");
        this.props.rightEditPanel(true);
        }
        break;
      case "Post Implementation Review":
        if (
          this.props.editChangeInitialData.cmdbUpdateNeeded == "Yes" &&
          this.props.cmbdShowVal &&
          !this.props.editChangeInitialData.cmdbUpdateDone
        ) {
          swal({
            text: this.props.translator[
              "Please complete CMDB Update before proceeding to Post Implementation Review"
            ],
            button: this.props.translator["OK"],
          });
        } else {
          this.props.changeActionView("pir");
          this.props.rightEditPanel(true);
        }
        break;
      case "CMDB Update":
        if (
          this.props.editChangeInitialData.status === "Under PIR" &&
          (this.props.editChangeInitialData.type != "20" ||
            this.props.editChangeInitialData.type != 20) &&
          this.props.editChangeInitialData.implementationIndividual !==
            this.props.user_id
        )
          swal({
            text: this.props.translator[
              "Please assign individual to yourself from implementation group"
            ],
            button: this.props.translator["OK"],
          });
        else if (
          this.props.editChangeInitialData.status === "Under PIR" &&
          (this.props.editChangeInitialData.type != "20" ||
            this.props.editChangeInitialData.type != 20) &&
          (this.props.editChangeInitialData.implementationGroup === "" ||
            this.props.editChangeInitialData.implementationGroup === 0 ||
            this.props.editChangeInitialData.implementationGroup === "0")
        )
          swal({
            text: this.props.translator["Please select implementation group"],
            button: this.props.translator["OK"],
          });
        else {this.props.changeActionView("cmdbUpdate");
        this.props.rightEditPanel(true);
        }
        break;
    }
  }
  onsubmitforReview() {
    try{
      if(this.props.editChangeInitialData.status === "Draft" &&
      (this.props.editChangeInitialData.type === "25") &&
      this.props.editChangeInitialData.urgency === "10" && LEAD_TIME == null){
        swal({
          text: this.props.translator['Please fill Risk Assessment'],
          button: this.props.translator["OK"],
        });
        return false;
      }
      let leadTime = moment().tz(timezone).add(LEAD_TIME, "hours");
      // let displayLeadTime = Math.ceil(LEAD_TIME / 24);
      // let showLeadTime =
      //   displayLeadTime > 1
      //     ? displayLeadTime + " Days "
      //     : displayLeadTime + " Day ";
      let showLeadTime = (LEAD_TIME < 24) ? `${LEAD_TIME} hours` : (((LEAD_TIME % 24) == 0) ? `${Math.floor(LEAD_TIME/24)} days` : `${Math.floor(LEAD_TIME/24)} days ${LEAD_TIME%24} hours` );
      let expectedStartDate = moment.tz(this.props.editChangeInitialData.expectedStartDate,timezone).format(this.props.date_format.substring(0, 10) + " HH:mm:ss")
      if (
        this.props.editChangeInitialData.status === "Draft" &&
        (this.props.editChangeInitialData.type === "25") &&
        this.props.editChangeInitialData.urgency === "10" &&
        (this.props.editChangeInitialData.expectedStartDate === "" ||
          moment(expectedStartDate).isBefore(
            leadTime
          )) /*&& (moment(expectedStartDate).isAfter(startDate))*/
      ) {
        swal({
          text:
            this.props.translator[
              "This Change cannot be submitted as the selected 'Scheduled Start Date' is less than the lead time required"
            ] +
            " (" +
            showLeadTime +
            ") " +
            this.props.translator["for this Change"] +
            ". " +
            this.props.translator["Please change the Scheduled Start Date"],
          button: this.props.translator["OK"],
        });
      }
      else if(this.props.editChangeInitialData.statusId==='30' && this.props.editChangeInitialData.type==="35" &&  (this.props.editChangeInitialData.changeManagementGroup==="" || this.props.editChangeInitialData.changeManagementGroup==="0" || this.props.editChangeInitialData.changeManagementGroup===0)){
        swal({
               text: this.props.translator["Please select change management group"],
               button: this.props.translator["OK"],
        });
      }else if(this.props.editChangeInitialData.statusId==='30' & this.props.editChangeInitialData.type==="35" &&  (this.props.editChangeInitialData.implementationGroup==="" || this.props.editChangeInitialData.implementationGroup==="0" || this.props.editChangeInitialData.implementationGroup===0)){
        swal({
           text: this.props.translator["Please select implementation group"],
           button: this.props.translator["OK"],
        });
      }
      else {
        this.props.changeActionView("submitReview");
        this.updateStatus(this.props.editChangeInitialData, "submitReview");
      }
    }catch(e){
      console.error('Error : ', e);
    }
  }
  handleClickOutside() {
    this.props.fun(false);
  }
  componentWillMount() {
    let planList = this.props.implememtationPlanList;
    if (
      this.props.editChangeInitialData.type === 30 ||
      this.props.editChangeInitialData.type === "30"
    ) {
      this.props.loadrelatedWorkitems(
        this.props.editChangeInitialData.changeId,
        "Breakfix"
      );
    } else {
      let recordCount = 0;
      this.props.resetrelatedCount(recordCount);
    }
    this.props.loadImplPlanList(
      "ChangeManagement",
      this.props.editChangeInitialData.changeId
    );
    this.props.checkRelatedTaskForItem(
      this.props.editChangeInitialData.changeId,
      "ChangeManagement"
    );
  }
  renderHamburgerSOptions(statusBasedHamburgerOptions) {
    if (statusBasedHamburgerOptions.length == 0) {
      return null;
    }
    return statusBasedHamburgerOptions.map((item, index) => {
      if (item.action !== "L") {
        if (item.action == "S") {
          if (!this.props.cmbdShowVal && item.enableButton == "CMDB Update") {
            return "";
          }
          return (
            <ListGroup.Item bsClass="" key={index}>
              <Nav.Item
                title={item.enableButton}
                onClick={() => {
                  this.currentAction(item.enableButton)
                    this.handleClickOutside();
                }}
              >
                <MdKeyboardDoubleArrowRight/>
                {item.enableButton}
              </Nav.Item>
            </ListGroup.Item>
          );
        }
      }
    });
  }
  renderHamburgerGOptions(statusBasedHamburgerOptions) {
    let hamburgerOption = statusBasedHamburgerOptions;
    if (statusBasedHamburgerOptions.length == 0) {
      return null;
    }
    return hamburgerOption.map((item, index) => {
      if (item.action !== "L") {
        if (item.action == "G") {
          if (
            (this.props.fieldStatus.provideAttachments == false &&
              item.enableButton == "Provide Attachments") ||
            (this.props.fieldStatus.notify == false &&
              item.enableButton == "Notify")
          ) {
            item.enableButton = "blank";
          }
          if (
            item.enableButton == "Approvals" &&
            !this.props.roleIdentification("10")
          ) {
            item.enableButton = "blank";
          }
          return item.enableButton == "Provide Attachments" ||
            item.enableButton == "Notify" || item.enableButton === "blank"? (
            ""
          ) : (
            <ListGroup.Item bsClass="" key={index}>
              <Nav.Item
                title={item.enableButton}
                onClick={() => {
                  this.currentAction(item.enableButton)
                    this.handleClickOutside();
                }}
              >
                {item.enableButton == "blank" ? null : (
                  <MdKeyboardDoubleArrowRight/>
                )}
                {item.enableButton == "blank" ? null : item.enableButton}
              </Nav.Item>
            </ListGroup.Item>
          );
        }
      }
    });
  }
  render() {
    return (
      <div className="breakFixHamburger statusHamburger">
        {this.props.showLoader ? (
          <ListGroup bsClass="" className="myLinks">
            <ListLoader />
          </ListGroup>
        ) : (
          <ListGroup bsClass="" className="myLinks">
            {this.renderHamburgerGOptions(
              this.props.statusBasedHamburgerOptions
            )}
          </ListGroup>
        )}
      </div>
    );
  }
};
BreakFixHamburger = OnClickOutside(BreakFixHamburger);

ChangeEditHeader = reduxForm({
  form: "changeEditForm",
})(ChangeEditHeader);

const mapStateToProps = ({
  riskAssessmentValue,
  fieldStatus,
  editChangeInitialData,
  realatedTaskCount,
  implememtationPlanList,
  statusBasedHamburgerOptions,
  showLoader3,
  relatedworkitemsCount,
  relatedCMDBTasks,
  watcherDetailsFetched
}) => {
  let watcherCount = watcherDetailsFetched.length;
  return {
    editChangeInitialData,
    fieldStatus,
    realatedTaskCount,
    implememtationPlanList,
    statusBasedHamburgerOptions,
    showLoader: showLoader3.loading,
    riskAssessmentValue,
    relatedworkitemsCount,
    relatedCMDBTasks,
    watcherCount
  };
};

export default connect(mapStateToProps, {
  loadChangeEditData,
  changeFieldStatusData,
  loadBreakFixAuditLogDetails,
  checkRelatedTaskForItem,
  loadBreakFixTaskLogDetails,
  resetrelatedCMDBtasks,
  loadImplPlanList,
  loadStatusBasedHamburgerOptions,
  loadTimelineData,
  loadHamburger,
  loadChangeManagementmList,
  setBreakfixQuickViewTableCarouselIndex,
  resetBFQVFilters,
  fetchChangeGroupList,
  changeConflict,
  stopLoadHamburger,
  loadrelatedWorkitems,
  resetrelatedCount,
  setInitialwibGlobalSearchFilterData,
  getCmdbApprovals
})(ChangeEditHeader);
