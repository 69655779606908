
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { Fragment, useEffect, useState } from "react";
import processImg from "../../../images/process-hierarchy-forum.png";
import { useGetGovAudiLogsQuery } from "../../../reducers/governance/meeting/meeting";
import ListLoader from "../../common/loaders/ListLoader";
import AuditLog from "./auditLog";

const RightSide = ({ isAddMode, editForumData,setOnClose }) => {
  const [orderValue, setOrderValue] = useState("desc");
  const companyId = editForumData?.customerId;
  const forumId = editForumData?.forumId;
  const { data: auditData, isFetching, refetch } = useGetGovAudiLogsQuery(
    {
      queryString: `?forumId=${forumId}&tenantId=${companyId}&module=gov_forum&orderBy=${orderValue}`,
    },
    {
      skip: !forumId,
    }
  );
  useEffect(() => {
    if (forumId) {
      refetch();
    }

    return () => {
    };
  }, [orderValue, forumId, companyId, refetch]);

  return (
    <Fragment>
      {isAddMode && (
        <div>
          <div className="rPageHeading">Process Workflow</div>
          <img className="img-fluid marAuto" src={processImg} />
        </div>
      )}
      {!isAddMode && (
        <div>
          {isFetching ? (
            <ListLoader />
          ) : (
            <AuditLog rowData={auditData} isFetching={isFetching} setOnClose={setOnClose} editForumData={editForumData} setOrderValue={setOrderValue}/>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default RightSide;
