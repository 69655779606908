
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react'
import {Button} from 'react-bootstrap';
// import * as FileSaver from 'file-saver';
import { forEach } from 'lodash';
//import * as XLSX from 'xlsx';
const Joi=require('joi')

// function ExportCSV({tr, csvData, fileName}){
function ExportCSV({ csvData, fileName,columnNames}){

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    // const exportToCSV = (csvData, fileName) => {
    //     if(csvData!=null && csvData!=undefined)
    //     {
    //     //  {/*const ws = XLSX.utils.json_to_sheet(csvData);
    //     //  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    //     //  let obj_prop_col=Object.getOwnPropertyNames(wb)
    //     //  let namedSchema=Joi.string().regex(/^[^!@#$-%^&*}{_+=|\:;\'\",<.>?~]+$/);
    //     //  if(((namedSchema.validate(obj_prop_col[0]).error==null)&&(namedSchema.validate(obj_prop_col[0]).error==null)))
    //     //  {
    //     //     obj_prop_col=Object.values(wb)
    //     //     if((namedSchema.validate(obj_prop_col[obj_prop_col.length-1][0]).error==null))
    //     //     {
    //     //         const wz={ bookType: 'xlsx', type: 'array' }
    //     //         obj_prop_col=Object.getOwnPropertyNames(wz)
    //     //         if(((namedSchema.validate(obj_prop_col[0])).error==null) && ((namedSchema.validate(obj_prop_col[1])).error==null))
    //     //         {
    //     //             obj_prop_col=Object.values(wz)
    //     //             if(((namedSchema.validate(obj_prop_col[0])).error==null) && ((namedSchema.validate(obj_prop_col[1])).error==null))
    //     //             {
    //     //                 const excelBuffer = XLSX.write(wb,wz);
    //     //                 const data = new Blob([excelBuffer], {type: fileType});
    //     //                 FileSaver.saveAs(data, fileName + fileExtension);
    //     //             }
    //     //         }
    //     //     }
    //     //  }*/}
    //     }
    //     // const ws = XLSX.utils.json_to_sheet(csvData);
    //     // const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    //     // const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //     // const data = new Blob([excelBuffer], {type: fileType});
    //     // FileSaver.saveAs(data, fileName + fileExtension);
    // }
    const  convertArrayOfObjectsToCSV=(array)=> {
        let result;

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(array[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;
                let str = item[key] && item[key].toString().replace(/\n/g, ' ');
                result += str;
                ctr++;

            });

            result += lineDelimiter;
        });
        return result;
    }

    const downloadCSV=(csvData, fileName,columnNames)=> {

        if(!Array.isArray(csvData)){
        return false;
        }
        let sortedCSVData = csvData.map((item)=>{
            let obj ={};
            columnNames.forEach((col)=>{
            obj[col] = item[col];
            });
            return obj;
        });

        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(sortedCSVData);

        if (csv == null) return;

        const filename = 'export.csv';
        const universalBOM = "\uFEFF";
        const csvContent = 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM+csv)

        link.setAttribute('href', csvContent);
        link.setAttribute('download', fileName);
        link.click();
    }
    return (
        // <Button className="btn-success btn-sm" onClick={(e) => exportToCSV(csvData,fileName)} title={tr['Export']}>{tr['Export to CSV']}</Button>
        <Button className="btn-success btn-sm float-start" onClick={(e) => downloadCSV(csvData,fileName,columnNames)} title='Export'>Export to CSV</Button>
    );
}
export default ExportCSV;
