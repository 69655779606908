
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
function datetimeConvertor(date,dateFormat){
    let splittedDate = date.split(' ');
    let day='',month='',year='';
    if(dateFormat==='dd-MM-yyyy HH:mm:ss'){
       [day,month,year]=splittedDate[0].split('-');
    }
    else if(dateFormat==='DD-MM-YYYY HH:mm:ss'){
        [day,month,year]=splittedDate[0].split('-');
    }
    else if(dateFormat==='MM-dd-yyyy HH:mm:ss'){
        [month,day,year]=splittedDate[0].split('-');
    }
    else if(dateFormat==='MM-DD-YYYY HH:mm:ss'){
        [month,day,year]=splittedDate[0].split('-');
    }
    else if(dateFormat==='yyyy-MM-dd HH:mm:ss'){
        [year,month,day]=splittedDate[0].split('-');
    }
    else if(dateFormat==='YYYY-MM-DD HH:mm:ss'){
        [year,month,day]=splittedDate[0].split('-');
    }
    return year+'-'+month+'-'+day+' '+splittedDate[1];
}
export default datetimeConvertor;