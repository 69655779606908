
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useRef, useState } from "react";
import { Container,Row, Col, Button,Form} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import PindCategories from "../../common/header/pind-categories";
import Breadcrumbs from "../../common/header/breadcrumbs";
import Header from "./header";
import List from "./list";


import Cookies from "universal-cookie";
import moment from "moment";
const cookies = new Cookies();


const Index = () => {
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.showLoader.loading);

  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Breadcrumbs activePageName="Pending Actions" />
      </Container>
      <Container fluid>
        <Header/>
        <List />
      </Container>
    </main>
  );
};

export default Index;
