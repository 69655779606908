
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';

import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

import { GLOBAL }  from '_Globals';
import axios from 'axios';
import { connect } from 'react-redux';
const MoreArrow = <i title="More" className="fa fa-angle-double-down f-size-15" ></i>;
import { loadBreakFixActivityLogDetails } from '_Actions/breakFix/breakFixActivityLogAction';
import Spinner from 'react-spinkit';
import { bindActionCreators } from 'redux';
import { loadImplPlanList } from "_Actions/common/attachmentAction";
import AttachmentFormTab from "./serviceDeskAttachment";
import ListLoader from "_Commons/loaders/ListLoader";
import swal from 'sweetalert';
import ReactReadMoreLess from '../../helpers/ReactReadMoreLess';
import {ImSpinner6} from "react-icons/im";
import {IoClose} from "react-icons/io5";
import { MyContext } from '_MyContext';
import { Link } from 'react-router-dom';
const Joi= require('joi')
//let user_id=userDetails.user_id;
//let full_name=userDetails.full_name;
class ServiceDeskBreakfixRightSide extends React.Component {
	static contextType = MyContext;
	constructor(props) {
		super(props);
		this.state = {
			activityLogHideShow: 0,
			showUserData: false,
			quickViewOrders: {},
			comments: '',
			showUpdates: 0,
			viewUpdates: 0,
			sortingArrow: 0,
			// openAttachmentOption:false,
			commentsAreEmpty: false,
			showAttachWithoutComments: false,
			reopenState:false
		};
		this.showUserDetails = this.showUserDetails.bind(this);
		this.activityData = this.activityData.bind(this);
		this.postActivityData = this.postActivityData.bind(this);
		this.getActivityLog = this.getActivityLog.bind(this);
		//this.viewUpdateData = this.viewUpdateData.bind(this);
		this.viewUpdateShow = this.viewUpdateShow.bind(this);
		// this.openAttachmentOption = this.openAttachmentOption.bind(this);
		this.postAttachmentWithoutComments = this.postAttachmentWithoutComments.bind(this);
		this.submitReopenData = this.submitReopenData.bind(this);
		this.renderAdditionalDetails = this.renderAdditionalDetails.bind(this);
		this.onChangeCopy=this.onChangeCopy.bind(this)
	}

	componentDidMount() {
		this.context.keyDownHandler(this.closeButton, "", "", "");
	}
	componentDidUpdate() {
		this.context.keyDownHandler(this.closeButton, "", "", "");
	}

	componentWillReceiveProps(newProps) {
		if(typeof newProps.myBreakFixOrderOrderDetails!=='undefined' && typeof this.props.myBreakFixOrderOrderDetails!=='undefined'){
			if(typeof newProps.myBreakFixOrderOrderDetails.status!=='undefined'){
                if(newProps.myBreakFixOrderOrderDetails.status !== this.props.myBreakFixOrderOrderDetails.status){
					this.setState({showAttachWithoutComments: false});
			}
		}
	}
    }
	onChangeCopy(){
		// swal({
        //     text: this.props.tr['Are you sure you want to copy this incident ?'],
		//     buttons: ["Cancel", "OK"]
        //   })
        //   .then((willDelete) => {
        //      if(willDelete)
			    this.props.setShowCopyServiceForm(true)
        //   });
	}
	// openAttachmentOption() {
	// 	this.setState({openAttachmentOption:true});
	// }

	getAttachment(itemId, Module) {
		this.props.loadImplPlanList(Module, itemId);
	}

	renderAttachedItems(attachments) {

		if (_.isEmpty(attachments)) {
			return (
				<div>
					<h4>No File Found</h4>
				</div>
			);
		}

		return attachments.map(attachment => {


			let validated_url=undefined
			if(attachment.fileTempURL!=null || attachment.fileTempURL!="")
			{
				let namedSchema = Joi.string().regex(/^[^<>}{]+$/)
				let validator_to_add_myQbj = namedSchema.validate(attachment.fileTempURL)
				if(validator_to_add_myQbj.error==null)
				{
					validated_url =attachment.fileTempURL
				}
				else
				{
					console.log("Validation failed : ",validator_to_add_myQbj)
					validated_url="\\"
				}
			}
			else
			  validated_url="\\"

			if (attachment.filename !== null) {
				if (attachment.status == "Y") {
					return null;
				} else {
					return (
						<ListGroup.Item
							bsPrefix=' '
							className="attachmentList"
							style={{
								width: "100%",
								"margin-bottom": "5px",
								display: "inline-flex"
							}}
							key={attachment.attachmentId}
						>
							<div style={{ width: "92%", float: "left",wordBreak:"break-all" }}>
								{attachment.filename}
							</div>
							<div style={{width:"8%"}}>
							<a href={validated_url}>
								{/* <a href={attachment.fileTempURL}> */}
									<i className="fa fa-eye" />
								</a>
								&nbsp;&nbsp;
							</div>
						</ListGroup.Item>
					);
				}
			}
		});
	}

	activityLogHideShow() {
		if (this.state.activityLogHideShow == 1)
			this.setState({ comments: '' });
		this.setState({
			activityLogHideShow: !this.state.activityLogHideShow,
			viewUpdates: 0
		});
	}

	viewUpdateShow() {
		this.getActivityLog();
		this.setState({
			activityLogHideShow: 0,
			viewUpdates: !this.state.viewUpdates,
            reopenState: 0
		});
	}
	getActivityLog() {
		let itemId = this.props.itemId;
		//console.log("itemId"+itemId)
		//alert(this.state.quickViewOrders.orderNumber
		this.props.loadBreakFixActivityLogDetails(itemId, "desc", "Breakfix");
	}
	showUserDetails() {
		this.setState({ showUserData: !this.state.showUserData });
	};


	renderApproverList(approverData) {
		//console.log(approverData);
		if (!approverData) {
			//alert("No data")
			return (
				<div></div>
			);
		} else {
			// console.log(breakFixData[0]);
			//this.props.getItemDetails(approverData[0].breakfixId);
			return approverData.map((approverObj, index) => {
				//console.log('breakfixobj'+breakFixObj.breakfixId)
				return (
					<ListGroup.Item bsPrefix=' '><span className="labSpan">{this.props.tr['Approver']}-</span>{approverObj.signatures}</ListGroup.Item>
				);
			});
		}
	}
	brkascactivitysort() {
		this.setState({
			sortingArrow: 0
		})
		let module = this.props.module;
		let itemId = this.props.itemId;
		//console.log("ascactivitysort"+module);
		//alert("brkascactivitysort");
		this.props.loadBreakFixActivityLogDetails(itemId, "asc", "Breakfix");
	}
	brkdescactivitysort() {
		//alert("brkdescactivitysort");
		this.setState({
			sortingArrow: 1
		})

		let module = this.props.module;
		let itemId = this.props.itemId;
		//console.log("descactivitysort"+module+itemId);
		this.props.loadBreakFixActivityLogDetails(itemId, "desc", "Breakfix");


		//this.props.loadBreakFixActivityLogDetails(GLOBAL.breakFixId,"desc");
	}

	renderActivityLog(activityLogData) {
		//alert(""+activityLogData.length)
		if (activityLogData.length == 0) {
			return (
				<div>{this.props.tr['No updates found']}</div>
			);
		}
		else {


			return activityLogData.map((activityObj, index) => {

				let intval = "", activitymsg = "";
				if (activityObj.isInternal == "Y")
					intval = "- Internal"

				if (activityObj.from === activityObj.to)
					activitymsg = "Status set to " + activityObj.to;
				else
					activitymsg = "Changed Status from " + activityObj.from + " to " + activityObj.to

				if (activityObj.isInternal != "Y")
					return (
						<Card className='mediaCrd'>
							<Card.Body>
								<Card.Title className="blue margin-0">{activityObj.createdOn} <span className="colorStOrange cursorPoint" onClick={this.showUserDetails}>{activityObj.createdByName}</span></Card.Title>
								<div className="black fw-300 cTxt">
								<ReactReadMoreLess 
									charLimit={150}
									readMoreText={MoreArrow}
									content={activityObj.from == "" ? activityObj.description : activitymsg}
								/>
								</div>
							</Card.Body>

						</Card>
					);
			});
		}
	}

	activityData(comnts) {
		this.setState({ comments: comnts.target.value });
		//console.log("comments"+this.state.comments)
		if (comnts != '')
			this.setState({ commentsAreEmpty: false });
	}

	postActivityData() {
		//alert(this.state.quickViewOrders.orderNumber);
		let itemId = this.props.itemId;
		//console.log(itemId);
		let module = "Breakfix";
		if (this.state.comments != "") {
			this.setState({ commentsAreEmpty: false });
			//this.props.fun(false,true,true);
			let ref = this;
			this.setState({ disabledSave: true });
			axios.post(GLOBAL.breakFixActivityLogPostUrl, {
				"itemId": itemId,
				"description": this.state.comments,
				"createdOn": "",
				"createdBy": "",
				"isInternal": "N",
				"module": module,
				"createdByName": ""
			}).
				then((response) => {
					//console.log(response.statusText);
					if (response.status === 201) {
						ref.inputComments.value = "";
						ref.setState({ comments: '' });
						if (GLOBAL.filesToBeSent.length > 0) {
							//alert("called")
							let formData = new FormData();
							formData.append('file', GLOBAL.filesToBeSent[0][0]);
							let jsonObject = new Object;
							jsonObject.module = ref.props.module;
							jsonObject.itemId = itemId;
							jsonObject.createdOn = Math.round(new Date().getTime() / 1000.0);
							jsonObject.createdBy = "";
							formData.append("attachment", JSON.stringify(jsonObject));

							axios.post("/change/api/saveImplPlan", formData).then((response) => {
								//debugger
								console.log(response)
								let res = response.data
								if (response.status == 200) {

									ref.setState({
										activityLogHideShow: 0,
										viewUpdates: 0,
										disabledSave: false
									});
									GLOBAL.filesToBeSent = [];
									ref.getAttachment(itemId, "Breakfix");
								}
							}).catch((error) => {
								console.log('Please upload File up to 20 MB size.');
								console.log("error in uploading file.");
							});
						}
						ref.setState({
							activityLogHideShow: 0,
							viewUpdates: 0,
							disabledSave: false
						});
					} else {
						//console.log("response status is not ok:"+response.statusText)
					}
					this.props.loadBreakFixActivityLogDetails(response.data.itemId, "desc", module);
				});
		} else {
			this.setState({ commentsAreEmpty: true });
			// console.log('Please mention activity');
		}
	}

	postAttachmentWithoutComments() {
		let ref = this;
		let itemId = this.props.itemId;
		let module = "Breakfix";
		this.setState({ disabledSave: true });
		if (GLOBAL.filesToBeSent.length > 0) {
			//alert("called")
			let formData = new FormData();
			formData.append('file', GLOBAL.filesToBeSent[0][0]);
			let jsonObject = new Object;
			jsonObject.module = ref.props.module;
			jsonObject.itemId = itemId;
			jsonObject.createdOn = Math.round(new Date().getTime() / 1000.0);
			jsonObject.createdBy = "";
			formData.append("attachment", JSON.stringify(jsonObject));

			axios.post("/change/api/saveImplPlan", formData).then((response) => {
				//debugger
				console.log(response)
				let res = response.data
				if (response.status == 200) {
					//alert('file uploaded successfully.');
					swal({
						title:"File has been uploaded successfully",
						button:{
							text: "OK",
							value: true,
							visible: true,
							className: "",
							closeModal: true,
						  }  
					});
					ref.setState({
						activityLogHideShow: 0,
						viewUpdates: 0,
						disabledSave: false,
						showAttachWithoutComments: false
					});
					GLOBAL.filesToBeSent = [];
					ref.getAttachment(itemId, "Breakfix");
				}
				this.props.loadBreakFixActivityLogDetails(itemId, "desc", module);
			}).catch((error) => {
				console.log('Please upload File up to 20 MB size.');
				console.log("error in uploading file.");
			});
		}
		else {
			this.setState({ disabledSave: false });
			//alert("Please upload some files first");
			swal({
				title:"Please upload some files first",
				button:{
					text: "OK",
					visible: true,
					closeModal: true,
				  }  
			});
		}
	}

	submitReopenData()
	{
		this.setState({disabledSave: true})
			let ref = this;
			axios.patch("/breakfixAction/"+ref.props.itemId,{
				status: "10", statusReopen: true,spUserid:'0',spUsername:"","modifiedBy": "",
				"modifiedByName": "",
				type: null,
				businessImpact: null,
				probableCause: null
			}).then((responseData) => {
				   if(ref.state.comments != "")
				   {
				   axios.post(GLOBAL.breakFixActivityLogPostUrl,
					{"itemId":ref.props.itemId,
					"description":ref.state.comments,
					"createdOn":"",
					"createdBy":"",
					"isInternal":"N",
					"module":ref.props.module,
					"createdByName":""}
				   ).then((response) => {
					    ref.setState({reopenState: 0,comments:'',disabledSave: false})
				   })
				   }
				   else
				   {
					    ref.setState({reopenState: 0,disabledSave: false})
				   }
			}).catch((error) => {
				console.log("PATCH API Request failed")
				this.setState({disabledSave: false})
			})
	}
	
	renderAdditionalDetails(profileDetails) {
		console.log("renderAdditionalDetails....", this.props.profileDetails)
		//let profileDetails = this.props.profileDetails;
		let locationName = '';
		if (profileDetails.cwlName!='' && profileDetails.cwlName!=null && profileDetails.cwlName!='null') {
			locationName = profileDetails.cwlName
		} else {
			locationName = profileDetails.locationName
		}

		return (
			< div className="table-responsive" >
				<Table bordered condensed hover responsive className="tableView sortImg">
					<tbody>
						<tr>
							<td className="bgBtn19 font2" style={{ "width": "50%" }}>{this.props.tr['Permanent Location']}</td>
							<td className="font2">{this.props.myBreakFixOrderOrderDetails.requesterLocationName}</td>
						</tr>
						<tr>
							<td className="bgBtn19 font2" style={{ "width": "50%" }}>{this.props.tr['Current Working Location']}</td>
							<td className="font2">{locationName}</td>
						</tr>
						<tr>
							<td className="bgBtn19 font2" style={{ "width": "50%" }}>{this.props.tr['Alternate Phone']}</td>
							<td className="font2">{profileDetails.alternatePhone}</td>
						</tr>
						<tr>
							<td className="bgBtn19 font2" style={{ "width": "50%" }}>{this.props.tr['Alternate Email']}</td>
							<td className="font2">{profileDetails.alternateEmail}</td>
						</tr>
						<tr>
							<td className="bgBtn19 font2" style={{ "width": "50%" }}>{this.props.tr['Manager Name']}</td>
							<td className="font2">{profileDetails.managerName}</td>
						</tr>
					</tbody>
				</Table>
			</div>
		)
	}

	render() {
    
		// if (!this.props.myBreakFixOrderOrderDetails.breakfixId) {
		// 	//alert("currency")
		// 	return (
		// 		<div><font color="green" ><b>{this.props.tr['There is no matching data available']}</b></font></div>
		// 	);
		// }


		if (this.props.ordersList.length == 0) {
			return (
				<div className="text-c">{this.props.tr['There is no matching data available']}</div>
			);
		}
		if(this.props.showLoader_5 || this.props.showLoader_1 || this.props.showLoader_2 || this.props.showLoader3 || this.props.showLoader){
			return(
			   <div><ListLoader myStyle={{marginTop:"20px"}} /></div>
			   			 );
		}
		let showActivity = "";
		if (this.props.orderStatus == "Fixed" || this.props.orderStatus == "Closed" || this.props.orderStatus == "Cancelled" || this.props.orderStatus == "Fullfiled" || this.props.orderStatus == "Draft") {
			showActivity = <div></div>
		} else {
			showActivity = <Button className="rgSidrkBtn" bsSize="small" title={this.props.tr['Post a Comment']}  onClick={() => { this.activityLogHideShow(); this.setState({ showAttachWithoutComments: false }); }}>{this.props.tr['Post a Comment']}</Button>

		}
		//alert(this.state.activityLogHideShow)
		let showTestAreaForComments = "";
		if (this.state.showUpdates == 0) {
			showTestAreaForComments = <div className="position-re">
				<Form.Control as="textarea" rows="2" ref={(input) => this.inputComments = input} onChange={this.activityData.bind(this)} />

				{this.state.commentsAreEmpty ? <div className="error"><font color="red"><strong>{this.props.tr['Comments should not be left empty.']}</strong></font></div> : ''}
				<ButtonToolbar className="margin-t-15">
					{/* {this.state.openAttachmentOption ? '' : <Button bsSize="small"  onClick={this.openAttachmentOption.bind(this)}>{this.props.tr['Attach Files']}</Button>} */}
					<Button bsSize="small"  disabled={this.state.disabledSave} onClick={this.postActivityData.bind(this)}>{this.props.tr['Post']}</Button>
				</ButtonToolbar>
			</div>
		} else {
			showTestAreaForComments = <div></div>
		}

		let showSoringDescArrow = "";
		if (this.state.sortingArrow == 0 && this.props.breakFixActivityLogDetails.length > 0) {
			showSoringDescArrow = <a href={void(0)} onClick={this.brkdescactivitysort.bind(this)} className="black" title={this.props.tr['Descending']}><i className="fa fa-angle-down" ></i></a>
		}

		let showSoringAscArrow = "";
		if (this.state.sortingArrow == 1) {
			showSoringAscArrow = <a href={void(0)} onClick={this.brkascactivitysort.bind(this)} className="black" title={this.props.tr['Ascending']}><i className="fa fa-angle-up"></i></a>;
		}

		let serviceCIName = ""
		if(this.props.myBreakFixOrderOrderDetails.serviceName === "" || this.props.myBreakFixOrderOrderDetails.serviceName == null) {
			serviceCIName = this.props.myBreakFixOrderOrderDetails.ciName;
		}
		else{
			serviceCIName = this.props.myBreakFixOrderOrderDetails.serviceName
		}

		return (
      <div className="">
        <Row>
          <Col xs={12}>
            <div className="rPageHeadActBtn">
              <ul>
                <li>
                  <Button
                    bsPrefix=' '
                    title={this.props.tr["Copy Incident"]}
                    onClick={(event) => this.onChangeCopy()}
                  >
                    <i
                      style={{ fontSize: "14px" }}
                      className="fa fa-files-o"
                    ></i>
                  </Button>
                </li>
                <li>
					<Link to="javascript:void(0)" className="closeRsBtn" id="closeButton"
						title={this.props.tr["Minimize the details panel"]}
						onClick={() => {
							this.props.showActiveRow(null,0)
							this.props.setRightSideBar(false);
						  }}
						ref={e => this.closeButton = e}
					>
						<IoClose/>
					</Link>
                </li>
              </ul>
            </div>
            <div className="rPageHeading">
              {this.props.myBreakFixOrderOrderDetails.requestNumber +
                "-" +
                this.props.myBreakFixOrderOrderDetails.breakfixNumber}
              <div className="offNam">{serviceCIName}</div>
            </div>
          </Col>
        </Row>
        <div className="rBoxGap">
          <ListGroup bsPrefix=' '>
            <ListGroup.Item
              bsPrefix=' '
              className="rwTable"
              style={{ "border-top": "none" }}
            >
              <span className="rwKey">{this.props.tr["Requested For"]}:</span>
              <span className="rwVal">
                {this.props.myBreakFixOrderOrderDetails.impactedUsername}
              </span>
            </ListGroup.Item>
            {/* <ListGroup.Item bsPrefix=' ' className="rwTable" ><span className="rwKey">{this.props.tr['Issue']}:</span><span className="rwVal1">{this.props.myBreakFixOrderOrderDetails.description.split(/\n/).map(description => <div>{description}</div>)}</span></ListGroup.Item> */}
            <ListGroup.Item bsPrefix=' ' className="rwTable">
              <span className="rwKey">
                {this.props.tr["Additional Information"]}:
              </span>
              <span className="rwVal1">
                {this.props.myBreakFixOrderOrderDetails.additionalInfo
                  ? this.props.myBreakFixOrderOrderDetails.additionalInfo
                      .split(/\n/)
                      .map((additionalInfo) => <div>{additionalInfo}</div>)
                  : ""}
              </span>
            </ListGroup.Item>{" "}
            <ListGroup.Item bsPrefix=' ' className="rwTable">
              <span className="rwKey">{this.props.tr["Urgency"]}:</span>
              <span className="rwVal">
                {this.props.urgencyList[this.props.myBreakFixOrderOrderDetailsInteger.urgency]}
              </span>
            </ListGroup.Item>
            <ListGroup.Item bsPrefix=' ' className="rwTable">
              <span className="rwKey">{this.props.tr["Submit Date"]}:</span>
              <span className="rwVal">
                {this.props.myBreakFixOrderOrderDetails.createdOn}
              </span>
            </ListGroup.Item>
            <ListGroup.Item bsPrefix=' ' className="rwTable">
              <span className="rwKey">{this.props.tr["Item Status"]}:</span>
              <span className="rwVal">
                {this.props.myBreakFixOrderOrderDetails.status}
              </span>
            </ListGroup.Item>
            <ListGroup.Item bsPrefix=' ' className="rwTable">
              <span className="rwKey">{this.props.tr["Order Type"]}:</span>
              <span className="rwVal">Incident</span>
            </ListGroup.Item>
            {this.props.ExternalRequestNumber == "" ? (
              ""
            ) : (
              <ListGroup.Item bsPrefix=' ' className="rwTable">
                <span className="rwKey">
                  {" "}
                  {this.props.tr["External Request Number"]}:
                </span>
                <span className="rwVal">
                  {this.props.ExternalRequestNumber}
                </span>
              </ListGroup.Item>
            )}
          </ListGroup>

		  <ListGroup bsPrefix=' ' className="margin-t-25">
          <ListGroup.Item bsPrefix=' ' style={{ "border-top": "none" }}>
            <Accordion
              id="rightSidePanel6"
              className="brkFix brkFixOrder"
			  defaultActiveKey={0}
            >
              <Accordion.Item eventKey="1" className="collapsed">
                <Accordion.Header>
                  {this.props.tr["Requestor Information"]}
                </Accordion.Header>
                <Accordion.Body>
                  {this.props.showLoader_6 ? (
                    <ListLoader />
                  ) : (
                    this.renderAdditionalDetails(this.props.profileDetails)
                  )}
                </Accordion.Body>
              </Accordion.Item>

          {this.props.orderStatus != "Draft" ? (
                <Accordion.Item eventKey="2" className="collapsed">
                  <Accordion.Header
                    onClick={() => {
                      this.getAttachment(this.props.itemId, "Breakfix");
                    }}
                  >
                    {this.props.tr["Attachments"]}
                  </Accordion.Header>
                  <Accordion.Body collapsible>
                    {this.renderAttachedItems(
                      this.props.implememtationPlanList
                    )}
                  </Accordion.Body>
                </Accordion.Item>
          ) : (
            null
          )}

		</Accordion>
            </ListGroup.Item>
			</ListGroup>

			<ButtonToolbar className="margin-t-15">
            {showActivity}
            {this.props.orderStatus != "Draft" && this.props.myBreakFixOrderOrderDetails.status !== "Cancelled" && this.props.myBreakFixOrderOrderDetails.status !== "Closed" ? (
              <Button
                className="rgSiLigBtn"
                bsSize="small"
                title={this.props.tr["Attach Files"]}
                onClick={() => {
                  this.setState({
                    showAttachWithoutComments:
                      !this.state.showAttachWithoutComments,
                    activityLogHideShow: 0,
                    viewUpdates: 0,
                    reopenState: 0,
                  });
                }}
              >
                {this.props.tr["Attach Files"]}
              </Button>
            ) : (
              ""
            )}
            <Button
              className="rgSiLigBtn"
              bsSize="small"
              title={this.props.tr["View Updates"]}
              onClick={() => {
                this.viewUpdateShow();
                this.setState({ showAttachWithoutComments: false });
              }}
            >
              {this.props.tr["View Updates"]}
            </Button>
            {this.props.orderStatus == "Fixed" &&
            this.props.module == "Breakfix" ? (
              <Button
                className="rgSiLigBtn"
                bsSize="small"
                title={this.props.tr["Reopen"]}
                onClick={() => {
                  this.setState({
                    reopenState: !this.state.reopenState,
                    activityLogHideShow: 0,
                    viewUpdates: 0,
                    showAttachWithoutComments: false,
                  });
                }}
              >
                {this.props.tr["Reopen"]}
              </Button>
            ) : (
              ""
            )}
          </ButtonToolbar>

          {this.state.showAttachWithoutComments ? (
            <div className="margin-t-20">
              <AttachmentFormTab
                translator={this.props.tr}
                itemId={this.state.itemId}
              />
              <Button
                className="margin-t-20"
                bsSize="small"
                disabled={this.state.disabledSave}
                onClick={this.postAttachmentWithoutComments}
              >
                {this.state.disabledSave == true ? (
                  <span>
                    <ImSpinner6 className="icn-spinner"/>{" "}
                  </span>
                ) : (
                  ""
                )}
                {this.props.tr["Attach"]}
              </Button>
            </div>
          ) : (
            ""
          )}
          {this.state.activityLogHideShow == 1 ? (
            <div className="margin-t-20 blueAnq">{showTestAreaForComments}</div>
          ) : null}
          {this.state.viewUpdates == 1 ? (
            <div className="margin-t-15 padding-b-5 border-b">
              <span className="float-r f-size-17">
                {showSoringAscArrow}
                {showSoringDescArrow}
                <span className="float-r margin-r-10"></span>
              </span>
              {this.renderActivityLog(this.props.breakFixActivityLogDetails)}
            </div>
          ) : null}
          {this.state.reopenState == 1 ? (
            <div className="position-re">
              <div className="margin-t-15 f-size-16">
                {this.props.tr["Reason For Reopen"]}
              </div>
              <Form.Control
                as="textarea"
                rows="2"
                ref={(input) => (this.inputComments = input)}
                onChange={this.activityData.bind(this)}
              />
              <ButtonToolbar className="margin-t-15">
                <Button
                  bsSize="small"
                  disabled={this.state.disabledSave}
                  onClick={this.submitReopenData}
                >
                  {this.state.disabledSave == true ? (
                    <span>
                      <ImSpinner6 className="icn-spinner"/>{" "}
                    </span>
                  ) : (
                    ""
                  )}
                  {this.props.tr["Submit"]}
                </Button>
                <Button
                  bsSize="small"
                  onClick={() => {
                    this.setState({
                      reopenState: !this.state.reopenState,
                      activityLogHideShow: 0,
                      viewUpdates: 0,
                      showAttachWithoutComments: false,
                      disabledSave: false,
                      comments: "",
                    });
                  }}
                >
                  {this.props.tr["Cancel"]}
                </Button>
              </ButtonToolbar>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
	}
}


export function mapStateToProps({ breakFixActivityLogDetails, ordersList, myBreakFixOrderOrderDetails, approverListDetails, implememtationPlanList,showLoader_5,showLoader_1,showLoader_2,showLoader3 ,showLoader, showLoader_6, profileDetails, urgencyList, myBreakFixOrderOrderDetailsInteger }) {
	//  alert("breakFixEditDetails:"+breakFixActivityLogDetails.length);
	//console.log(myBreakFixOrderOrderDetails);
	return { breakFixActivityLogDetails, ordersList, myBreakFixOrderOrderDetails, approverListDetails, implememtationPlanList,showLoader_5:showLoader_5.loading,showLoader_1:showLoader_1.loading,showLoader_2:showLoader_2.loading,showLoader3:showLoader3.loading,showLoader:showLoader.loading,showLoader_6:showLoader_6.loading,profileDetails, urgencyList, myBreakFixOrderOrderDetailsInteger
	 };
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ loadBreakFixActivityLogDetails, loadImplPlanList }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceDeskBreakfixRightSide);
