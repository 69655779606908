
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import { Link } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { Field, reduxForm, formValueSelector, change, SubmissionError } from 'redux-form';
import { loadTagDetailsByTagId, emptyAllFields } from '_Actions/foundation/tags/tagsAction';
import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import {LuFileText} from "react-icons/lu";
import { MyContext } from '_MyContext';

const Header = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const tr = useSelector((state) => state.spcmReducer.tr);
	const tagDetailsByTagId = useSelector((state) => state.foundation_tags_reducer.tagDetailsByTagId);
	const foundation_tags_reducer = useSelector((state) => state.foundation_tags_reducer);
	const [saveLoader, setSaveLoader] = useState(false);
	const context = useContext(MyContext);
	const closeButton = useRef(null);
	const saveObjRef = useRef(null);

	useEffect(() => {
		context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
		context.newTabPrevent();
	}, []);

	function buildTreeWithRelationships(data) {
		for (const item of data) {
			item.children = data.filter((child) => child.parentTagKey == item.tagId);
		}
		return data.filter((res) => res.parentTagKey == 0);
	}

	const getChildren = (parent, allData = []) => {
		if (parent.children.length > 0) {
			for (const child of parent.children) {
				allData.push(child);
				getChildren(child, allData);
			}
		}
		return allData;
	};

	const sortDataWithParamKey = (data) => {
		let sortedData = [];
		const dataWithRelationships = buildTreeWithRelationships(data);
		const returnedData = getChildren(dataWithRelationships[0]);
		sortedData = [dataWithRelationships[0], ...returnedData];
		return sortedData;
	};

	function submit(values) {
		if (!values.company) {
			props.setCompanyErrorState('error');
			return false;
		}
		else {
			props.setCompanyErrorState('');
		}
		if (values.tagType == 'Member' && values.compositeTag != '0') {
			let flag = false;
			const sortedData = sortDataWithParamKey(foundation_tags_reducer.memberTagsForComposite);
			sortedData.forEach((item) => {
				if(item.tagId == values.parentTag && item.children.length > 0 && tagDetailsByTagId.parentTag != values.parentTag) {
					flag = true;
				}
			})
			if(flag == true) {
				swal({
					text: tr['An attribute can have only one dependent attribute. The attribute you selected in "Dependent On" seems to have another dependent attribute. Please review and modify the member dependencies before proceeding.'],
					button: tr['OK'],
				});
				return false;
			}
			if (tagDetailsByTagId.tagEnabled.toString() == 'true' && values.tagEnabled.toString() == 'false') {
				Swal.fire({
					title: tr["A member attribute should not be deactivated if it has a dependent member. Please review the member dependencies before deactivation. Do you want to deactivate this member?"],
					width: 550,
					padding: '15px',
					showDenyButton: true,
					confirmButtonText: tr['Deactivate anyway'],
					denyButtonText: tr['Cancel'],
				}).then((result) => {
					if (result.isConfirmed) {
						finalSubmit(values);
					} else if (result.isDenied) {
						return false;
					}
				});
			}
			else {
				finalSubmit(values);
			}
		}
		else {
			finalSubmit(values);
		}
	}

	function finalSubmit(values) {
		setSaveLoader(true);
		axios.patch('/api/patchCreateTagData', {
			"businessFunction": values.businessFunctionName,
			"companyId": values.company,
			"compositeTagKey": values.compositeTag,
			// "language": "1",
			"parentTagKey": values.parentTag,
			"sequence": values.sequence,
			"tagCharLimit": values.character_limit,
			"tagEnabled": values.tagEnabled,
			"tagLabel": values.label.trim(),
			"tagModule": values.module,
			"tagType": values.tagType,
			"tagMandatory": values.tagMandatory,
			// "tagValueDataSource": values.tagDataSource,
			"tagValueDataType": values.tagDataValidation,
			"companyName": values.companyName,
			"businessFunctionId": values.businessFunctionId,
			"tagId": props.tagId
		}).then(function (response) {
			if (response.status == 201) {
				dispatch(loadTagDetailsByTagId(props.tagId));
				setSaveLoader(false);
			}
		}).catch(function (error) {
			setSaveLoader(false);
			if (error.response.status == 409 && error.response.data == 'duplicate tag') {
				swal({
					text: tr["An attribute with the same label already exists. Please provide a different label."],
					button: tr['OK'],
				});
			}
		});
	}

	return (
		<Row className="margin-b-15">
			<Col sm={8} xs={7}>
				<h1 bsClass="" className="sPageHeading1">{tr["Custom Attribute"]}</h1>
			</Col>
			<Col sm={4} xs={5}>
				<div className="paHedFilter">
					<a href="javascript:void(0)"
					onClick={() => { props.rightEditPanel(true); }}
					className="myBt fillBtn d-md-none" >
					<LuFileText/>
					</a>
					<Link bsPrefix=' ' to="javascript:void(0)" id="creTag" className="myBt plus ctrlKeyPrevent" disabled={saveLoader} onClick={props.handleSubmit(submit)} title={tr['Save']} ref={saveObjRef}><span>{saveLoader ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/>}</span></Link>
					<Link bsPrefix=' ' to="/customAttribute" className="myBt cancel fillBtn ctrlKeyPrevent" title={tr['Cancel']} onClick={() => { dispatch(emptyAllFields())}} ref={closeButton} ><IoClose/></Link>
				</div>
			</Col>
		</Row>
	)
}

export default reduxForm({
	form: 'foundationTagsEditForm',
	destroyOnUnmount: true,
	enableReinitialize: false
})(Header);