
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const userListRed = (state = [], action) => {
  if (action.type === 'USER_FDN_LIST') {
    return action.payload;
  } else {
    return state;
  }
};

export const userDepListRed = (state = [], action) => {
  if (action.type == 'COMP_BASED_DEP') {
    return action.payload;
  } else {
    return state;
  }
}

export const userCostCenterRed = (state = [], action) => {
  if (action.type == 'USER_COSTCENTER') {
    return action.payload;
  } else if (action.type == 'RESET_USER_CWL_LOC_CC_MANAGER_COMPANY_DETAILS') {
    return [];
  }
  else {
    return state;
  }
}

export const userLocationRed = (state = [], action) => {
  if (action.type == 'USER_LOCATION') {
    return action.payload;
  } else if (action.type == 'RESET_USER_CWL_LOC_CC_MANAGER_COMPANY_DETAILS') {
    return [];
  }
  else {
    return state;
  }
}

export const userManagerRed = (state = [], action) => {
  if (action.type === 'USER_MANAGER') {
    return action.payload;
  } else if (action.type == 'RESET_USER_CWL_LOC_CC_MANAGER_COMPANY_DETAILS') {
    return [];
  }
  else {
    return state;
  }
}

export const userOrgListRed = (state = [], action) => {

  if (action.type == 'USER_ORGZ') {
    return action.payload;
  }
  else {
    return state;
  }
}

export const languageListRed = (state = [], action) => {
  if (action.type == 'LANGUAGE_LIST') {
    return action.payload;
  } else {
    return state;
  }
}
export const dateListREd = (state = [], action) => {
  if (action.type == 'DATE_FORMAT') {
    return action.payload;
  } else {
    return state;
  }
}
export const timezoneRed = (state = [], action) => {
  if (action.type == 'TIME_ZONE') {
    return action.payload;
  } else {
    return state;
  }
}
export const userTypeRed = (state = [], action) => {
  if (action.type == 'USER_TYPE') {
    return action.payload;
  } else {
    return state;
  }
}
export const authenticationRed = (state = [], action) => {
  if (action.type == 'AUTHENTICATION_LIST') {
    return action.payload;
  } else {
    return state;
  }
}
export const currencyRed = (state = [], action) => {
  if (action.type == 'CURRENCY') {
    return action.payload;
  } else {
    return state;
  }
}
export const editDetailsRed = (state = [], action) => {
  if (action.type == 'USER_PAGE_EDIT_DETAILS') {
    return action.payload;
  } else if (action.type == 'RESET_EDIT_USER_DETAILS') {
    return [];
  } else {
    return state;
  }
}

export const userPageCompanyValues = (state = [], action) => {
  if (action.type === 'USER_EDIT_MAPPED_COMP') {
    return action.payload;
  } else {
    return state;
  }
};
export const userGroupsRed = (state = [], action) => {
  if (action.type === 'USER_GROUP') {
    return action.payload;
  } else {
    return state;
  }
};
export const userRolesRed = (state = [], action) => {
  if (action.type === 'USER_ROLE') {
    return action.payload;
  } else {
    return state;
  }
};
export const accessRestrictRed = (state = [], action) => {
  if (action.type === 'ACCESS_RESTRICT') {
    return action.payload;
  } else {
    return state;
  }
};
export const userCompanyByTypeRed = (state = [], action) => {
  if (action.type === 'USER_COMP_ASSOCIATED_DRPDWN_LIST') {
    return action.payload;
  } else {
    return state;
  }
};
export const disassociateCompRed = (state = [], action) => {
  if (action.type === 'ASSOCIATED_COMPANY_DELETED') {
    return action.payload;
  } else {
    return state;
  }
};
export const mapCWLRed = (state = [], action) => {
  if (action.type === 'EDIT_ORG_MAP_CWL') {
    return action.payload;
  } else {
    return state;
  }
};
export const mapOrganizationRed = (state = [], action) => {
  if (action.type === 'EDIT_ORG_MAP_ORG') {
    return action.payload;
  } else {
    return state;
  }
};
export const mapDepartmentRed = (state = [], action) => {
  if (action.type === 'EDIT_ORG_MAP_DEP') {
    return action.payload;
  } else {
    return state;
  }
};
export const SyncRed = (state = [], action) => {
  if (action.type === 'SYNC_CLICK') {
    return action.payload;
  } else {
    return state;
  }
};
export const notSyncRed = (state = [], action) => {
  if (action.type === 'SYNC_NOT_CLICK') {
    return action.payload;
  } else {
    return state;
  }
};
export const cwlDataRed = (state = [], action) => {
  if (action.type === 'CWL_DATA') {
    return action.payload;
  } else {
    return state;
  }
};
export const userJwtData = (state = "", action) => {
  if (action.type === 'FETCH_USER_JWT_SUCCESS') {
    return action.payload;
  } else {
    return state;
  }
};