
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";

import PindCategories from "../../../common/header/pind-categories";
import Breadcrumbs from "../../../common/header/breadcrumbs";
import Header from "./header";
import List from "./list";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import AddUserToShift from "../addUserToShift/AddUserToShift"; 
import SetOutOfOffice from "../setOutOfOffice/setOutOfOffice";
import SwapShift from "../swapShift/swapShift";
import { loadEventList } from "../../../../actions/onCallSchedule/calendar/onCallCalendarAction";
import { useDispatch } from "react-redux";
import moment from "moment";
import CustomCalendar from "../CustomCalendar";
import "_Css/common/_table.scss";
import "_Css/form/_form.scss";

const Index = () => {
  const dispatch = useDispatch();
  const [isRightSideVisible, setRightSideVisible] = useState("");
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [activePage, setActivePage] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [fullnameSearchInput, setFullnameSearchInput] = useState("");
  const [shiftnameSearchInput, setShiftnameSearchInput] = useState("");
  const [shiftTypeSearchInput, setShiftTypeSearchInput] = useState("");
  const [startDateSearchInput, setStartDateSearchInput] = useState("");
  const [endDateSearchInput, setEndDateSearchInput] = useState("");
  const [availableSearchInput, setAvailableSearchInput] = useState("");
  const [timeZoneErrorState, setTimeZoneErrorState] = useState("");
  const [selectedGroupList, setSelectedGroupList] = useState([]);
  const [users, setUsers] = useState([]);
  const [fromRef, setFromRef] = useState(null);
  const [toRef, setToRef] = useState(null);
  const [isCalendarView, setIsCalendarView] = useState(false);
  const [toggle,setToggle] = useState(false);
  const [panelResize,setPanelResize] = useState(100);

  let colWidth = "";
  isRightSideVisible ? (colWidth = 8) : (colWidth = 12);

  const showRightSideFun = (val) => {
    setRightSideVisible(val);
  };

  const setRef = (type, ref) => {
    if (type == "from") {
      setFromRef(ref);
    } else if ((type = "to")) {
      setToRef(ref);
    }
  };

  const groupCrossClicked = () => {
    setSelectedGroupList([]);
    setRightSideVisible("");
    resetFilters(true);
    setToggle(false);
    setIsCalendarView(false);
  };

  const resetFilters = (crossButton = false) => {
    setFullnameSearchInput("");
    setShiftTypeSearchInput("");
    setShiftnameSearchInput("");
    setStartDateSearchInput("");
    setEndDateSearchInput("");
    setAvailableSearchInput("");
    setActivePage(0);
    setItemsCountPerPage(10);
    setIsFilterApplied(false);
    if (fromRef) {
      fromRef.endDate = moment();
      fromRef.startDate = moment();
    }
    if (toRef) {
      toRef.endDate = moment();
      toRef.startDate = moment();
    }
    if (selectedGroupList && selectedGroupList.length > 0 && !crossButton) {
      dispatch(
        loadEventList({
          page: 0,
          size: 10,
          userId: users.join(","),
        })
      );
    }
  };
  console.log("panelSize123",panelResize);
  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        {/* <div className="tabDatacount">1-1 of 1</div> */}
        <Breadcrumbs activePageName="On Call Schedule" />
      </Container>

      <Container fluid>
        <Header
          selectedGroupList={selectedGroupList}
          setSelectedGroupList={setSelectedGroupList}
          showRightSideFun={showRightSideFun}
          groupCrossClicked={groupCrossClicked}
          resetFilters={resetFilters}
          isFilterApplied={isFilterApplied}
          setIsCalendarView={setIsCalendarView}
          toggle={toggle}
          setToggle={setToggle}
        />
        <PanelGroup direction="horizontal" className="panelOverflow">
          <Panel
            id="sidebar"
            minSize={33}
            order={1}
            defaultSize={isRightSideVisible ? 67 : 100}
            onResize={ (size) => setPanelResize(size)}
            className={
              isRightSideVisible ? "isShowLeftPanel catBordDv" : "catBordDv"
            }
          >
            {!isCalendarView?<List
              rightDisplay={isRightSideVisible}
              showRightSideFun={showRightSideFun}
              pageSize={pageSize}
              setPageSize={setPageSize}
              activePage={activePage}
              setActivePage={setActivePage}
              totalItemsCount={totalItemsCount}
              setTotalItemsCount={setTotalItemsCount}
              itemsCountPerPage={itemsCountPerPage}
              setItemsCountPerPage={setItemsCountPerPage}
              totalPages={totalPages}
              setTotalPages={setTotalPages}
              setRightSideVisible={setRightSideVisible}
              selectedGroupList={selectedGroupList}
              fullnameSearchInput={fullnameSearchInput}
              shiftnameSearchInput={shiftnameSearchInput}
              shiftTypeSearchInput={shiftTypeSearchInput}
              startDateSearchInput={startDateSearchInput}
              endDateSearchInput={endDateSearchInput}
              availableSearchInput={availableSearchInput}
              setFullnameSearchInput={setFullnameSearchInput}
              setShiftnameSearchInput={setShiftnameSearchInput}
              setShiftTypeSearchInput={setShiftTypeSearchInput}
              setStartDateSearchInput={setStartDateSearchInput}
              setEndDateSearchInput={setEndDateSearchInput}
              setAvailableSearchInput={setAvailableSearchInput}
              setIsFilterApplied={setIsFilterApplied}
              setUsers={setUsers}
              users={users}
              setRef={setRef}
            />:<CustomCalendar users={users} setUsers={setUsers} selectedGroupList={selectedGroupList} panelResize={panelResize}/>}
          </Panel>
          {isRightSideVisible ? (
            <>
              <PanelResizeHandle className="isShowLeftPanel resizeHandle">
                <div className="outlne">
                  <div className="handIcn">
                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                  </div>
                </div>
              </PanelResizeHandle>
              <Panel minSize={33} order={2} defaultSize={33}>
                <div className="stickyArea rBoxStyle rBoxRightSide">
                  {isRightSideVisible == "addUserToShift" ? (
                    <AddUserToShift
                      selectedGroupList={selectedGroupList}
                      showRightSideFun={showRightSideFun}
                      resetFilters={resetFilters}
                      users={users}
                    />
                  ) : isRightSideVisible == "setOutOfOffice" ? (
                    <SetOutOfOffice
                      selectedGroupList={selectedGroupList}
                      timeZoneErrorState={timeZoneErrorState}
                      setTimeZoneErrorState={setTimeZoneErrorState}
                      showRightSideFun={showRightSideFun}
                      resetFilters={resetFilters}
                      users={users}
                    />
                  ) : isRightSideVisible == "swapShift" ? (
                    <SwapShift
                      selectedGroupList={selectedGroupList}
                      timeZoneErrorState={timeZoneErrorState}
                      setTimeZoneErrorState={setTimeZoneErrorState}
                      showRightSideFun={showRightSideFun}
                      resetFilters={resetFilters}
                      users={users}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </Panel>
            </>
          ) : (
            ""
          )}
        </PanelGroup>
      </Container>
    </main>
  );
};

export default Index;
