
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
//below URL used for testing purpose
//const breakFixUrl = 'https://xsm-f.mybluemix.net/api/SX_FAVOURITEOFFERINGs/';
import { GLOBAL }  from '../../components/Globals';
//let breakFixUrl =`https://xsm-f.mybluemix.net/api/breakfixitem?breakfixitemId=`;
//let breakFixUrl=`https://xsm-f.mybluemix.net/groupListSelfService?companyId=2932&group=ResolvedER`
export function loadBreakFixStatusDetailsSuccess(breakFixStatusDetails){
	//alert('in success')
	return {
		type: 'LOAD_BREAKFIXSTATUSDETAILS_SUCCESS',
		breakFixStatusDetails
	};
}

export function loadBreakFixStatusDetailsFailure(errorResponse){
	return {
		type: 'LOAD_BREAKFIXSTATUSDETAILS_FAILURE',
		errorResponse
	};
}
export function loadBreakFixStatusDetails(actionbutton,status,itemId){
	//alert('action called and parameter is '+breakFixId)

	return(dispatch) =>{
		//alert("EditGroupIdAction123"+breakFixId);
		if(actionbutton == undefined || actionbutton == ""){
			//alert("H2 breakFixId not found");
		}else{
			dispatch({type:'FETCHING_AUDIT_FOR_TIMELINE'});
				//alert("my url is "+GLOBAL.breakFixStatusActionUrl+"?actionbutton="+actionbutton+"&status="+status);
				
				axios.get(GLOBAL.breakFixStatusActionUrl+"?module=Breakfix"+"&itemId="+itemId+"&actionbutton="+actionbutton+"&status="+status)
					.then((breakFixStatusDetails)=>{
						if(!breakFixStatusDetails){//alert('not breakFix')
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							throw Error(breakFixStatusDetails.statusText);
						}
						return breakFixStatusDetails;
					})
					.then((breakFixStatusDetails) =>{//alert('yes it is')
					dispatch({type:'FETCHED_AUDIT_FOR_TIMELINE'});
						dispatch(loadBreakFixStatusDetailsSuccess(breakFixStatusDetails))
					})
					.catch((err) => {
						console.log(err);
					});
					//console.log("not calling breakFixStatusDetails");
		}
	};
}
