
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector, connect } from 'react-redux';
import { Row, Col, Nav, NavDropdown, MenuItem, Tab, ListGroup } from 'react-bootstrap';

let SearchBoxMenu = (props) => {
  const dispatch = useDispatch();
  const translator = useSelector((state) => state.spcmReducer.tr);

  let renderSearchOptions = props.searchOptions.map((option, index) => {
    if (option === 'Knowledge' && !((props.isKnowledgeEnabled == true || props.isKnowledgeEnabled == "true"))) {
      return false;
    } else {
      return <NavDropdown.Item as='li' bsPrefix=' ' title={option == 'Service' ? translator["Service"] : translator["Knowledge"]} key={index}>
      <Nav.Link       
        style={index === props.searchOptions.length - 1 ? { borderBottom: 'none' } : {}}
        onClick={(e) => {
          e.preventDefault();
          props.changeSearchOn(option);
          // props.changeSearchOn(option == 'Service' ? translator["Service"] : translator["Knowledge"]);
          props.setCategoryId(0);
          if (option === 'Knowledge') {
            props.setCategorySlug('');
          }
          props.setCategoryName('All');
        }}
      >
  {/* {option} */}
{option == 'Service' ? translator["Service"] : translator["Knowledge"] }
</Nav.Link>
      </NavDropdown.Item>;
    }
  });

  return (
    <>
      <Nav className="catList">
        <NavDropdown title={props.searchOn} className="catBox">
          {renderSearchOptions}
        </NavDropdown>
      </Nav>
    </>
  );
}

export default SearchBoxMenu;
