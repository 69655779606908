
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TableComponent from "../../../common/ReactTable/TableComponent";
import ListLoader from "_Commons/loaders/ListLoader";

const BulletinList = (props) => {
  const [bulletinListData, setBulletinListData] = useState([]);

  const bulletinList = useSelector((state) => state.BulletinList);
  const showLoader = useSelector((state) => state.commonLoader.loading);

  const onRowSelect = (row) => {
    props.rowHandler(row);
    props.showRightSideFun(true);
  };

  const tableColumns = [
    {
      name: props.translator["Company"],
      selector: (row) => row.companyId,
      sortable: true,
    },
    {
      name: props.translator["Sequence"],
      selector: (row) => row.bulletinSequence,
      sortable: true,
    },
    {
      name: props.translator["URL"],
      selector: (row) => row.bulletinUrl,
      sortable: true,
    },
    {
      name: props.translator["From Date"],
      selector: (row) => row.fromDate,
      sortable: true,
    },
    {
      name: props.translator["To Date"],
      selector: (row) => row.toDate,
      sortable: true,
    }
  ];

  useEffect(() => {
    if (bulletinList.length > 0) {
      setBulletinListData(bulletinList);
    } 
    if (bulletinList.length == 0) {
      setBulletinListData([]);
    }
  }, [bulletinList]);

  const filterColumns = ["companyId"];

  console.log('Data --- ', bulletinListData);

  const renderBulletinTableData = () => {
    return (
      <div className="dtaTableDv integrateHubTable">
        <div
          className={
            props.isRightSideVisible
              ? "leftTrue srchPosTop fdnPos"
              : "rightTrue srchPosTop fdnPos"
          }
        >
          <TableComponent
            data={bulletinListData}
            columns={tableColumns}
            headerColumns={filterColumns}
            onRowSelect={onRowSelect}
            rightDisplay={props.isRightSideVisible}
            uniqueKey={"bulletinId"}
          />
        </div>
      </div>
    );
  };

  if (!bulletinListData) return null;
  if (bulletinListData.length == undefined) {
    return <ListLoader />;
  } else {
    return (
      <div className="myOrderDiv">
        {showLoader ? (
          <ListLoader />
        ) : (
          renderBulletinTableData()
        )}
      </div>
    );
  }
};

export default BulletinList;