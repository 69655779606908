
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { getCostCenterList } from "../../../../actions/foundation/costCenterAction";
import { HiPlus, HiOutlineRefresh } from "react-icons/hi";
import { LuFilterX } from "react-icons/lu";

const HeaderSec = (props) => {
    const translator = useSelector((state) => state.spcmReducer.tr);
    const dispatch = useDispatch();
    
    const clearAllFilters = () => {
        props.setCostCenterSearchInput('');
        props.setCompanySearchInput('');
        props.setSelectedStatusValue([]);
        props.setMultiSelectParams([]);
        props.setLimit(10);
        props.setPage(1);
        props.setSearchValue('');
        props.setSearchKey('');
		dispatch(getCostCenterList(1, 10, "costcenterName", "asc", "", "", []));
        props.setClearAllFilterToggle(false);
        props.setRightSideVisible(false);
	}
    
    const refreshCostCenterListData = () => {
        dispatch(getCostCenterList(props.page, props.limit, "costcenterName", "asc", props.searchKey, props.searchValue, props.status));
    }

    return (
        <Row className="margin-b-15">
            <Col sm={8} xs={7}>
                <h1 bsClass="" className="sPageHeading1">{translator["Cost Center"]}</h1>
            </Col>
            <Col sm={4} xs={5}>
                <div className="paHedFilter">
                    {props.clearAllFilterToggle ? <div className="overlayDv"><Button title="Clear All Filters" role="button" onClick={() => { clearAllFilters() }} bsPrefix=' ' className="myBt clrbtn"><LuFilterX/></Button></div> : ""}
                    <Link to="/createCostCenter" role="button" className="myBt plus fillBtn" title={translator["Create"]} ><HiPlus /></Link>
                    <Button bsPrefix=' ' title={translator["Refresh"]} onClick={refreshCostCenterListData} role="button" className="myBt"><HiOutlineRefresh className="refreshFlip" /></Button>
                </div>
            </Col>
        </Row>
    )
}

export default HeaderSec;