
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import { GLOBAL }  from '../../components/Globals';

export const loadTaskInputVariables = (workItemId,taskId,workItemType,taskCode) => {
    
    return(dispatch) =>{
        dispatch({
            type:'FETCHING_TASK_INPUTS'
        });
        axios.post(GLOBAL.getTaskInputVariables,{
            "workItemId": workItemId,
            "taskId": taskId,
            "workItemType": workItemType ,
            "taskCode":taskCode
        }).then(response=>{
            if(response.status==200) {
                dispatch({
                    type:'TASK_INPUT_FETCHED',
                    payload:response.data.outputVariables
                });
            }else {
                dispatch({
                    type:'ERROR_LOADING_TASK_INPUTS'
                });
            }
        });
    };
};

export const loadTaskOutputVariables = taskCode => {
    return dispatch => {
        dispatch({type: 'FETCHING_TASK_OUTPUTS'});
        axios.get(GLOBAL.getTaskOutputVariables+taskCode)
            .then(response => {
                if(response.status==200){
                    dispatch({type:'TASK_OUTPUT_FETCHED',data:response.data});
                }else {
                    dispatch({type:'ERROR_LOADING_TASK_OUTPUTS'});
                }
            });
    };
};

export const postTaskOutputVariables = (output,callback) =>{
    return dispatch => {
        dispatch({type: 'POSTING_DATA'});
        axios.post(GLOBAL.postOutputVariables,output)
            .then(response=>{
                if(response.status==201){
                    dispatch({type:'DATA_POSTED_SUCCESSFULLY'});
                    callback();
                }else {
                    dispatch({type:'ERROR_POSTING_DATA'});
                }
            });
    };
};

export const initializeTaskOutputEditForm = (value) => {
    return dispatch => {
        dispatch({type:'INITIALIZE_TASK_OUTPUT_FORM',outputVariable:value});
    };
};

export const updateOutPutVariable = (value,id,callback) =>{
    return dispatch => {
        dispatch({type: 'POSTING_DATA'});
            axios.patch(GLOBAL.updateOutputVariables+id,value)
            .then(response=>{
                if(response.status==201){
                    dispatch({type:'DATA_POSTED_SUCCESSFULLY'});
                    callback();
                }else {
                    dispatch({type:'ERROR_POSTING_DATA'});
                }
            });
    };
};
