
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import { Formik, Form, FieldArray } from "formik";
import { Button } from "react-bootstrap";

import { FiRefreshCcw } from "react-icons/fi";
import ListLoader from "../../../../../common/loaders/ListLoader";
import NoDataAvailable from "../../../../../common/NoDataAvailable";
import CustomTypeHead from "../../../../common/CustomTypeHead";
import {
  useGetTrackingDetailsQuery,
  useSaveOccurranceTrackingMutation,
} from "../../../../../../reducers/governance/meeting/meeting";

const Tracking = ({
  meetingdefata,
  occurenceData,
  companyID,
  onClose,
  isEdit,
  isAuthorized,
}) => {
  const occuranceId = occurenceData?.meetingOccurrananceId;
  const [value, setValue] = useState("");
  const [radioVal, setRadioVal] = useState("");
  const [searchStr, setSearchStr] = useState("");
  const [alert, setAlert] = useState({
    open: false,
    severity: "success",
    msg: "Updated Successfully",
  });

  function handleClick(event, setFieldValue, index) {
    let obj = {};
    let objVal = {};

    if (event.target.value === radioVal[event.target.name]) {
      objVal[event.target.name] = "";
      setRadioVal((prev) => {
        return {
          ...prev,
          ...objVal,
        };
      });
    } else {
      objVal[event.target.name] = event.target.value;
      setRadioVal((prev) => {
        return {
          ...prev,
          ...objVal,
        };
      });
    }
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert({ ...alert, open: false });
  };

  const handleSearchDelegate = (query) => {
    setSearchStr(query);
  };

  const {
    data: trackData,
    error,
    isFetching,
    refetch: refreshTracking,
  } = useGetTrackingDetailsQuery(
    { Id: occuranceId, queryString: `?tenantId=${companyID}` },
    {
      skip: !(companyID && occuranceId),
    }
  );
  const onRefresh = () => {
    refreshTracking();
  }

  const [saveTracking, { isLoading: saveTrackingLoading }] =
    useSaveOccurranceTrackingMutation();

  const initialValue = {
    track: trackData || [],
  };

  const onSubmit = async (payload, { setSubmitting }) => {
    const res = await saveTracking({
      data: [...payload.track],
      queryString: `?tenantId=${companyID}`,
    });
    setSubmitting(false);
    // if (res.status === 200) {
    //   setAlert({ ...alert, open: true });
    // }
  };

  function handleClickR(event, setFieldValue, index, inputValue) {
    if (event.target.value === value) {
      setValue("");
    } else {
      setValue(event.target.value);
    }
  }

  const onlyOne = (e) => {
    const checkboxes = document.getElementsByName(e.target.name);
    checkboxes.forEach((item) => {
      if (item !== e.target) item.checked = false;
    });
  };

  const checkAll = (e, values, setFieldValue, type) => {
    values.track.forEach((item, index) => {
      if (e.target.checked) {
        if (type === "P") {
          setFieldValue(`track.${index}.isPresent`, true);
          setFieldValue(`track.${index}.isAbsent`, false);
          setFieldValue(`track.${index}.delegates`, "");
        } else if (type === "A") {
          setFieldValue(`track.${index}.isPresent`, false);
          setFieldValue(`track.${index}.isAbsent`, true);
          setFieldValue(`track.${index}.delegates`, "");
        }
      } else {
        if (type === "P") {
          setFieldValue(`track.${index}.isPresent`, false);
          setFieldValue(`track.${index}.isAbsent`, false);
          setFieldValue(`track.${index}.delegates`, "");
        } else if (type === "A") {
          setFieldValue(`track.${index}.isPresent`, false);
          setFieldValue(`track.${index}.isAbsent`, false);
          setFieldValue(`track.${index}.delegates`, "");
        }
      }
    });
    if(type==="P"){
      let checkboxA = document.querySelector('input[name="allA"]');
      checkboxA.checked = false;
    }else{
      let checkboxP = document.querySelector('input[name="allP"]');
      checkboxP.checked = false;
    }
  };

  return (
    <div className="stickyArea rBoxStyle tracking">
      <div className="rPageHeading d-flex p-3 align-items-center">
        <div className="offNam mw-100 flex-grow-1">Tracking</div>
        <div className="px-3">
          <a bsPrefix=" " id="creTag" role="button" onClick={onRefresh}>
            <FiRefreshCcw />
          </a>
        </div>
      </div>

      <div className="p-1">
        {isFetching ? (
          <div className="fnloader">
            <ListLoader />
          </div>
        ) : trackData?.length === 0 ? (
          <div className="text-center">
            <NoDataAvailable />
          </div>
        ) : (
          <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={false}
            onSubmit={onSubmit}
          >
            {({
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              handleBlur,
              values,
              handleChange,
              setValues,
              resetForm,
              dirty,
            }) => {
              let checkboxA=false;
              let checkboxP=false;
              let presentCount = 0;
              let absentCount = 0;
              values.track.map((item) => {
                if(item.isAbsent){
                  absentCount++;
                }
                if(item.isPresent){
                  presentCount++;
                }
              });
              if(absentCount===values.track.length){
                checkboxA=true;
              }
              if(presentCount===values.track.length){
                checkboxP=true;
              }
              return (
                <Form
                  noValidate
                  className={isAuthorized ? "disabledallField" : ""}
                >
                  <table className="table default-Tracking">
                    <thead>
                      <tr>
                        <th className="grey-bg" width="40%">
                          Participant
                        </th>
                        <th className="text-center custom-padding2 align-top grey-bg">
                          <div className="d-flex mb-3">
                            <span className="next-block">
                              Present
                              <label className="default-radio-btn mb-0">
                                <input
                                type="checkbox"
                                name="allP"
                                onClick={(e) =>
                                  checkAll(e, values, setFieldValue, "P")
                                }
                                checked={checkboxP}
                              />
                                <span className="radiobtn green-check present-checkbox"></span>
                              </label>
                            </span>
                            <span className="next-block ms-auto">
                              Absent
                              <label className="default-radio-btn mb-0">
                                <input
                                type="checkbox"
                                name="allA"
                                onClick={(e) =>
                                  checkAll(e, values, setFieldValue, "A")
                                }
                                checked={checkboxA}
                              />
                                <span className="radiobtn red-check"></span>
                              </label>
                            </span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <FieldArray name="track">
                        {() =>
                          values.track.map((item, index) => {
                            return (
                              <tr key={item?.userId}>
                                <td
                                  valign="middle"
                                  className="grey-bg text-break"
                                >
                                  {item.participantName}
                                  <span className="user-email-custom">
                                    {item?.participantCompany}
                                    {`${item?.participantEmail ? "|" : ""}`}
                                    {item?.participantEmail}
                                  </span>
                                </td>
                                <td className="grey-bg pt-2">
                                  <div className="d-flex flex-row justify-content-around delegate">
                                    <label className="default-radio-btn">
                                      <input
                                        type="checkbox"
                                        name={`check-${index}`}
                                        onClick={(e) => {
                                          onlyOne(e);
                                          let checkboxP = document.querySelector('input[name="allP"]');
                                          if(!e.target.checked && checkboxP.checked){
                                            checkboxP.checked = false;
                                          }
                                          setFieldValue(
                                            `check-${index}`,
                                            e.target.value
                                          );
                                          setFieldValue(
                                            `track.${index}.isPresent`,
                                            e.target.checked
                                          );
                                          setFieldValue(
                                            `track.${index}.isAbsent`,
                                            false
                                          );

                                          setFieldValue(
                                            `track.${index}.delegates`,
                                            ""
                                          );
                                        }}
                                        value="P"
                                        checked={values.track[index].isPresent}
                                      />
                                      <span className="radiobtn green-check"></span>
                                    </label>
                                    <CustomTypeHead
                                      id={`track.${index}`}
                                      name={`track.${index}.delegates`}
                                      clearButton
                                      label="Delegate"
                                      thfor="personApi"
                                      placeholder="Please type to search"
                                      companyId={companyID}
                                      autoComplete="off"
                                      labelkey={(option) =>
                                        option?.fullname
                                          ? `${option?.fullname} | ${option?.email}`
                                          : option
                                      }
                                      onChange={(selected) => {
                                        if (selected.length !== 0) {
                                          setFieldValue(
                                            `track.${index}.delegates`,
                                            selected[0].fullname
                                          );
                                        } else {
                                          setFieldValue(
                                            `track.${index}.delegates`,
                                            ""
                                          );
                                        }
                                      }}
                                      selected={
                                        values?.track[index]?.delegates
                                          ? [values?.track[index]?.delegates]
                                          : []
                                      }
                                      value={
                                        values?.track[index]?.delegates || ""
                                      }
                                      renderMenuItemChildren={(
                                        option,
                                        props
                                      ) => (
                                        <>
                                          <span>{option?.fullname}</span>
                                        </>
                                      )}
                                      disabled={!values.track[index].isPresent}
                                    />

                                    <label className="default-radio-btn">
                                      <input
                                        type="checkbox"
                                        name={`check-${index}`}
                                        onClick={(e) => {
                                          onlyOne(e);
                                          let checkboxA = document.querySelector('input[name="allA"]');
                                          if(!e.target.checked && checkboxA.checked){
                                            checkboxA.checked = false;
                                          }
                                          setFieldValue(
                                            `check-${index}`,
                                            e.target.value
                                          );
                                          setFieldValue(
                                            `track.${index}.isAbsent`,
                                            e.target.checked
                                          );
                                          setFieldValue(
                                            `track.${index}.isPresent`,
                                            false
                                          );
                                          setFieldValue(
                                            `track.${index}.delegates`,
                                            ""
                                          );
                                        }}
                                        value="A"
                                        checked={values.track[index].isAbsent}
                                      />
                                      <span className="radiobtn red-check"></span>
                                    </label>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        }
                      </FieldArray>
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-end mb-2">
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      title="Submit"
                      className="rgSidrkBtn smallBtn mb-0"
                    >
                      Save
                    </Button>
                    &nbsp;
                    {/* <a className="btn  smallBtn" onClick={onClose}>
                      Cancel
                    </a> */}
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default Tracking;
