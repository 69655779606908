
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export const spcmReducer = (state = initialState, action) => {
  let preEdit = "";
  let edit = "";
  switch (action.type) {
    case types.SLA_DEFINITION:
      return Object.assign({}, state, { slaList: action.payload });
    case types.REMINDER:
      return Object.assign({}, state, { auditList: action.payload });
    case types.COMPANY:
      return Object.assign({}, state, { companyList: action.payload });
    case types.COMPANY_COPY:
        return Object.assign({}, state, { companyListcopy: action.payload });  
    case types.PORTFOLIO_GROUP:
      return Object.assign({}, state, { portfolioGroupList: action.payload });
    case types.PORTFOLIO_MANAGER:
      return Object.assign({}, state, { portfolioManagerList: action.payload });
    case types.CATALOGUE_GROUP:
      return Object.assign({}, state, { catalogueGroupList: action.payload });
    case types.CATALOGUE_MANAGER:
      return Object.assign({}, state, { catalogueManagerList: action.payload });
    case types.SERVICE_DETAILS:
      var asl = state.allServicesList;
      asl.push(action.payload);
      return Object.assign({}, state, {
        serviceDetails: action.payload,
        allServicesList: asl,
      });
    case types.ALL_SERVICES_LIST:
      return Object.assign({}, state, {
        allServicesList: action.payload,
        allServicesMeta: action.meta,
      });
    case types.CURRENTLY_SELECTED_SERVICE:
      return Object.assign({}, state, {
        currentlySelectedServiceDetails: action.payload,
      });
    case types.CRITICALITY_LIST:
      return Object.assign({}, state, { criticalityList: action.payload });
    case types.CATEGORY_LIST:
      return Object.assign({}, state, { categoryList: action.payload });
    case types.CATEGORY_LIST_COPY:
      return Object.assign({}, state, { categoryListCopy: action.payload });
    case types.SERVICE_SUPPORT_COMPANY_GROUPS_LIST:
      return Object.assign({}, state, {
        serviceSupportCompanyGroupsList: action.payload,
      });
    case types.SERVICE_PROVIDER_COMPANY_GROUPS_LIST:
      return Object.assign({}, state, {
        serviceProviderCompanyGroupsList: action.payload,
      });
    case types.EDIT_LEFT_TAB_SERVICE:
      preEdit = action.payload;
      edit = {};
      if (!preEdit.moduleId) {
        Object.keys(preEdit).forEach(function (key) {
          edit[key.toLowerCase()] = preEdit[key];
        });
        return Object.assign({}, state, { editLeftService: edit });
      } else {
        return Object.assign({}, state, { editLeftService: preEdit });
      }

    case types.EDIT_SERVICE:
      preEdit = state.currentlySelectedServiceDetails;
      edit = {};
      Object.keys(preEdit).forEach(function (key) {
        edit[key.toLowerCase()] = preEdit[key];
      });
      /*       edit.ofg_chain.forEach(function(arr){
              if(arr.CHAIN_TYPE=="portfolio"){
                edit['portfolioCompany']=arr.CHAIN_COMPANY_ID;
                edit['portfolioGroup']=arr.CHAIN_GROUP_ID;
                edit['portfolioManager']=arr.CHAIN_PERSON_ID;
              }else{
                edit['catalogueCompany']=arr.CHAIN_COMPANY_ID ;
                edit['catalogueGroup']=arr.CHAIN_GROUP_ID;
                edit['catalogueManager']=arr.CHAIN_PERSON_ID;
              }
            })  */
      //edit.ofg_chain.splice(0,2);
      return Object.assign({}, state, { editService: edit });
    case types.CLEAR_EDIT_SERVICE:
      return Object.assign({}, state, { editService: [], editId: -1 });
    case types.CONSUMPTION_ITEM:
      return Object.assign({}, state, {
        consumptionItems: action.payload,
        meta: action.meta,
        consumptionItemsMeta: action.meta,
      });
    case types.SERVICE_QUESTIONS_LIST:
      return Object.assign({}, state, {
        myQuestionsForService: action.payload,
      });
    case types.ENTITLEMENTS:
      return Object.assign({}, state, {
        entitlements: action.payload,
        meta: action.meta,
      });
    case types.SERVICE_ORDER_CHOICES:
      return Object.assign({}, state, {
        serviceOrderChoices: action.payload,
        requiredQuestionMeta: action.meta,
      });
    case types.ATTRIBUTES:
      return Object.assign({}, state, {
        attributes: action.payload,
        meta: action.meta,
        attributesMeta: action.meta,
      });
    case types.SERVICE_BILLING:
      return Object.assign({}, state, {
        serviceBilling: action.payload,
        meta: action.meta,
      });
    case types.SERVICE_KNOWLEDGE:
      return Object.assign({}, state, {
        serviceKnowledge: action.payload,
        meta: action.meta,
      });
    case types.SERVICE_FULFILMENT_LOV:
      return Object.assign({}, state, {
        serviceFulfilmentPlansLov: action.payload,
      });
    case types.SERVICE_FULFILMENT_PLANS:
      return Object.assign({}, state, {
        serviceFulfilmentPlans: action.payload,
      });
    case types.CI_TASK_PLANS:
      return Object.assign({}, state, {
        ciTaskPlans: action.payload,
      });
    case types.CHAIN_ENTITIES:
      return Object.assign({}, state, {
        chainEntities: action.payload,
        meta: action.meta,
      });
    case types.CI_CHAIN_ENTITIES:
      return Object.assign({}, state, {
        ciChainEntities: action.payload.data,
        ciChainEntitiesMeta: action.payload.meta
      });
    case types.RELATED_OFFERINGS:
      return Object.assign({}, state, {
        relatedOfferings: action.payload,
        meta: action.meta,
      });
    case types.ALL_DEPLOYED_SERVICES_LIST:
      return Object.assign({}, state, {
        allDeployedServicesList: action.payload,
        allServicesMeta: action.meta,
      });

    case types.SERVICE_LEVELS:
      var sl = [];
      if (action.payload !== undefined && action.payload !== "") {
        action.payload.forEach((val) => {
          if (val.moduleName != "Investigation" && val.moduleName != "Change")
            sl.push(val);
        });
      }
      count = state.serviceDetailCounts;
      // count.SERVICELEVEL=sl.length;
      count.SERVICELEVEL = action.meta ? action.meta.rowCount : 0;
      return Object.assign({}, state, {
        serviceLevels: sl,
        meta: action.meta,
        serviceDetailCounts: count,
      });

    case types.SELF_SERVICES:
      return Object.assign({}, state, {
        selfServices: action.payload,
        meta: action.meta,
      });
    case types.EDIT_ID:
      return Object.assign({}, state, { editId: action.payload });
    case types.LEFT_EDIT_ID:
      return Object.assign({}, state, { editLeftId: action.payload });
    case types.CURRENT_FORM:
      return Object.assign({}, state, { form: action.payload });
    case types.GROUPS:
      return Object.assign({}, state, { groups: action.payload });
    case types.MANAGERS:
      return Object.assign({}, state, { managers: action.payload });
    case types.ENTITLEMENT_ENTITIES:
      return Object.assign({}, state, { entitlement_entities: action.payload });
    case types.SERVICE_DETAILS_COUNTS:
      count = action.payload;
      if (state.serviceDetailCounts.APPROVAL)
        count.APPROVAL = state.serviceDetailCounts.APPROVAL;
      if (state.serviceDetailCounts.SERVICELEVEL)
        count.SERVICELEVEL = state.serviceDetailCounts.SERVICELEVEL;
      return Object.assign({}, state, { serviceDetailCounts: count });
    case types.AUDIT:
      return Object.assign({}, state, { audit: action.payload });
    case types.APPROVAL:
      let count = state.serviceDetailCounts;
      count.APPROVAL = action.payload.length;
      return Object.assign({}, state, {
        approval: action.payload.approverList,
        serviceDetailCounts: count,
        approvalMeta: action.payload.meta,


      });
    case types.APPROVER_TYPE:
      return Object.assign({}, state, { approver_type: action.payload });
    case types.APPROVER_CRITERIA:
      return Object.assign({}, state, { approverCriteria: action.payload });
    case types.APPROVER_CRITERIA_OPERATOR:
      return Object.assign({}, state, {
        approverCriteriaOperator: action.payload,
      });
    case types.APPROVER_SCENARIO:
      return Object.assign({}, state, {
        approverCriteriaScenario: action.payload,
      });
    case types.APPROVER_ENTITY:
      return Object.assign({}, state, { approverEntity: action.payload });
    case types.APPROVER_LOB:
      return Object.assign({}, state, { approverLOB: action.payload });
    case types.APPROVER_ORDER_TYPE:
      return Object.assign({}, state, { approverOrderType: action.payload });
    case types.APPROVER_COMPANY_CODE:
      return Object.assign({}, state, { approverCompanyCode: action.payload });
    case types.APPROVAL_MODULE:
      return Object.assign({}, state, { approver_module: action.payload });
    case types.APPROVER_GROUP:
      return Object.assign({}, state, { approver_group: action.payload });
    case types.APPROVER_INDIVIDUAL:
      return Object.assign({}, state, { approver_individual: action.payload });
    case types.APPROVER_GROUP_OR_INDIVIDUAL:
      return Object.assign({}, state, { approver_group_or_individual: action.payload });
    case types.APPROVER_LEVEL:
      return Object.assign({}, state, { approver_level: action.payload });
    case types.CHAIN_ENTITIES_COMPANY:
      let payload = action.payload === "" ? [] : action.payload;
      return Object.assign({}, state, { chain_entities_company: payload });
    case types.ALL_COMPANY_LIST:
      return Object.assign({}, state, { all_company_list: action.payload });
    case types.SERVICES_LIST_FOR_SEARCH:
      return Object.assign({}, state, {
        services_list_for_search: action.payload,
      });
    case types.SLA_MODULE:
      let modArr = [];
      action.payload.forEach((val) => {
        if (
          val.field1Key == "Breakfix" ||
          val.field1Key == "Fulfillment" ||
          val.field1Key == "Service Availability" ||
          val.field1Key == "Incident"
        )
          modArr.push(val);
      });
      return Object.assign({}, state, { sla_module: modArr });
    case types.SLA_GOAL_TYPE:
      let goalTypeArr = [];
      action.payload.forEach((val) => {
        if (val.field1Key != "Approval") goalTypeArr.push(val);
      });
      return Object.assign({}, state, { sla_goal_type: goalTypeArr });
    case types.SLA_WORKDAY_SCHEDULE:
      return Object.assign({}, state, { sla_workday_schedule: action.payload });
    case types.TRANSLATION:
      return Object.assign({}, state, {
        tr: action.payload.data,
        lang: action.payload.lang,
      });
    case types.SELECTED_TASK_TEMPLATE:
      return Object.assign({}, state, { selectedTaskTemplate: action.payload });
    case types.UPDATE_PRICE:
      return Object.assign({}, state, {
        updatedHidePriceStatus: action.payload,
      });
    case types.UPDATE_SLA:
      return Object.assign({}, state, { updatedHideSlaStatus: action.payload });
    case types.REVIEW:
      let rating = [0, 0, 0, 0, 0, 0];
      action.payload.forEach(function (val) {
        rating[val.rating]++;
      });
      return Object.assign({}, state, { reviews: rating });
    case types.SLA_FILTER:
      let sla = state.serviceLevels;
      //let sla_final=sla.filter(function (el) {
      /* return el.sla_id <= 1000 &&
            el.sqft >= 500 &&
            el.num_of_beds >=2 &&
            el.num_of_baths >= 2.5; */
      // return parseInt(el.slaId) <= 33;
      //});
      /* let sla_final=sla.sort(function(a, b){
        return a. == b.last_nom ? 0 : +(a.last_nom > b.last_nom) || -1;
      }); */
      // console.log(sla_final);
      return Object.assign({}, state, { sla: sla });
    case types.EDIT_FULLFILMENT_LEFT_TAB_SERVICE:
      if (
        action.payload.taskOutputs &&
        typeof action.payload.taskOutputs !== "string"
      ) {
        let taskOutput = "";
        action.payload.taskOutputs.forEach(function (item) {
          taskOutput += item.output_key + ";";
        });
        taskOutput = taskOutput.substring(0, taskOutput.length - 1);
        console.log(typeof taskOutput);
        action.payload.taskOutputs = taskOutput;
      }
      return Object.assign({}, state, { editLeftService: action.payload });
    case types.SPCM_POSTING:
      return Object.assign({}, state, {
        spcmPostingAttribute: action.spcmPostingAttribute,
        spcmPostingService: action.spcmPostingService,
      });
    case types.SPCM_FETCHING:
      return Object.assign({}, state, {
        spcmFetchingAttribute: action.spcmFetchingAttribute,
        spcmFetchingService: action.spcmFetchingService,
        spcmFetchingError: action.spcmFetchingError,
        spcmFetchingModule: action.spcmFetchingModule,
        spcmErrorMsg: action.spcmErrorMsg,
      });
    case types.QUESTIONS_CATEGORY:
      return Object.assign({}, state, {
        questions_category: action.payload,
        requiredQuestionGroupMeta: action.meta,
      });

    case types.EDIT_QUESTIONS_CATEGORY:
      edit = action.payload;
      return Object.assign({}, state, { editLeftService: edit });

    case types.QUEST_ACT_CAT_LIST:
      return Object.assign({}, state, { quesActiveCategories: action.payload });

    case types.GET_COMPONENT_NAME:
      console.log(action.payload);
      return Object.assign({}, state, { componentName: action.payload });

    case types.GET_REQUIRED_SLA_STATUS:
      console.log(action.payload);
      return Object.assign({}, state, {
        slaStatusForFulfilment: action.payload,
      });

    case types.DATA_SOURCE_TYPES:
      console.log(action.payload);
      return Object.assign({}, state, { dataSourceTypes: action.payload });

    case types.DATA_SOURCE_RESPONSES:
      console.log(action.payload);
      return Object.assign({}, state, {
        ReadOnlyResponseKeyTypesAndKeys: action.payload,
      });

    case types.ORDERING_PREFERENCES:
      var obj = action.payload[0];
      var key,
        keys = Object.keys(obj);
      var n = keys.length;
      var lowercaseKeyObj = {};
      while (n--) {
        key = keys[n];
        lowercaseKeyObj[key.toLowerCase()] = obj[key];
      }
      console.log("newobj");
      console.log(lowercaseKeyObj);
      return Object.assign({}, state, { orderingPreferences: lowercaseKeyObj });

    // case types.SPCM_DISCLAIMERS:
    // var obj = action.payload[0];
    // var key, keys = Object.keys(obj);
    // var n = keys.length;
    // var lowercaseKeyObj={}
    // while (n--) {
    //   key = keys[n];
    //   lowercaseKeyObj[key.toLowerCase()] = obj[key];
    // }
    // console.log("newobj");
    // console.log(lowercaseKeyObj);
    // return Object.assign({},state,{spcmDisclaimers:lowercaseKeyObj});

    case types.SPCM_DISCLAIMERS:
      console.log(action.payload);
      return Object.assign({}, state, { spcmDisclaimers: action.payload });
    case types.CMDB_DISCLAIMERS:
      console.log(action.payload);
      return Object.assign({}, state, { spcmDisclaimers: action.payload });

    case types.INTEGRATION_PREFERENCES:
      console.log(action.payload[0]);
      return Object.assign({}, state, {
        integrationPreferences: action.payload[0],
      });

    case types.SERVICE_TYPES:
      console.log(action.payload);
      return Object.assign({}, state, { serviceTypes: action.payload });

    case types.INTEGRATION_DATA_KEYWORDS:
      console.log(action.payload);
      return Object.assign({}, state, {
        integrationDataKeywords: action.payload,
      });

    case types.ADDITIONAL_INTEGRATION_DATA:
      console.log(action.payload);
      return Object.assign({}, state, {
        addtional_integration_data: action.payload,
        meta: action.meta,
      });

    case types.APPROVAL_QUESTIONS:
      console.log("approval_questions");
      console.log(action.payload);
      return Object.assign({}, state, { approval_questions: action.payload });

    case types.COPY_OFFERING_FROM_SERVICE_LIST:
      return Object.assign({}, state, {
        copyOfferingFromServiceList: action.payload,
      });

    case types.COPY_OFFERING_FROM_SERVICE_LIST_COPY:
        return Object.assign({}, state, {
          copyOfferingFromServiceListCopy: action.payload,
        });
    case types.SERVICE_SEQUENCING_LIST:
      return Object.assign({}, state, {
        serviceSequencingList: action.payload,
      });

    case types.SERVICE_SEQUENCING_LIST_ON_ADD:
      return Object.assign({}, state, {
        serviceSequencingListOnAdd: action.payload,
      });

    case types.RESET_REDUCER:
      return Object.assign({}, state, {
        dataSourceTypes: action.payload,
        myQuestionsForService: action.payload,
        transAttrName: action.payload,
        transAttrValue: action.payload,
        translatedNameDescSum: action.payload,
        translatedSubcategory: action.payload,
        translatedCategory: action.payload,
        translateQuestCat: action.payload,
        translateQuestText: action.payload,
        translateQuestPlaceholder: "",
        translateQuestHelpText: "",
        translatedResponseValues: "",
        translateCompDesc: "",
        translateDisplayText:"",
        translateTableHeaders:action.payload,
      });

    case types.ATTRIBUTES_TRANSLATION_STATUS:
      return Object.assign({}, state, {
        attrubutesTranslationStatus: action.payload,
      });

    case types.LANGUAGE_LIST_TRANSLATION_STATUS:
      return Object.assign({}, state, {
        languageListTranslationStatus: action.payload,
      });

    case types.TRANS_NAME_DESC_SUMMARY:
      return Object.assign({}, state, {
        nameDescSummaryTranslation: action.payload,
      });

    case types.TRANS_CAT_SUBCAT:
      return Object.assign({}, state, {
        catSubcatTranslation: action.payload,
      });

    case types.NAME_DESC_SUM_TRANSLATION:
      return Object.assign({}, state, {
        translatedNameDescSum: action.payload,
      });

    case types.GET_LIST_FOR_TRANSLATION:
      return Object.assign({}, state, {
        getListForTranslation: action.payload,
      });

    case types.OBJECT_FOR_TRANSLATION:
      let preEdit = action.payload;
      let lowerKeysPayload = {};
      Object.keys(preEdit).forEach((key) => {
        console.log("preedit");
        console.log(key);
        console.log(preEdit);
        lowerKeysPayload[key.toLowerCase()] = preEdit[key]
      })
      console.log(lowerKeysPayload);
      return Object.assign({}, state, { objectForTranslation: lowerKeysPayload });

    case types.TRANS_ATTR_NAME:
      return Object.assign({}, state, { transAttrName: action.payload });

    case types.TRANS_COMP_DESC:
      return Object.assign({}, state, { translateCompDesc: action.payload });

    case types.TRANS_ATTR_VALUE:
      return Object.assign({}, state, { transAttrValue: action.payload });

    case types.TRANS_CATEGORY:
      return Object.assign({}, state, { translatedCategory: action.payload });

    case types.TRANS_SUBCATEGORY:
      return Object.assign({}, state, {
        translatedSubcategory: action.payload,
      });

    case types.TRANS_QUES_CAT:
      return Object.assign({}, state, {
        translateQuestCat: action.payload,
      });

    case types.TRANS_RESPONSE_VALUES:
      return Object.assign({}, state, {
        translatedResponseValues: action.payload,
      });

    case types.TRANS_QUES_TEXT:
      return Object.assign({}, state, {
        translateQuestText: action.payload,
      });
    case types.TRANS_TABLE_HEADERS:
      return Object.assign({}, state, {
        translateTableHeaders:action.payload
      });

    case types.TRANS_QUES_PLACEHOLDER:
      return Object.assign({}, state, {
        translateQuestPlaceholder: action.payload,
      });

    case types.TRANS_QUES_HELPTEXT:
      return Object.assign({}, state, {
        translateQuestHelpText: action.payload,
      });

    case types.TRANS_DISPLAY_TEXT:
      return Object.assign({}, state, {
        translateDisplayText: action.payload,
      });

    case types.SPCM_REMINDER_NOTIFICATION_QUESTIONS:
      return Object.assign({}, state, {
        spcmReminderNotificationQuestions: action.payload,
      });

    case types.SPCM_REMINDER_NOTIFICATIONS:
      return Object.assign({}, state, {
        spcmReminderNotifications: action.payload,
      });
    case types.APPROVER_LIST_DATA_WITH_ORGANIZATION:
      return Object.assign({}, state, { spcmDropDownORG: action.payload });

    case types.APPROVER_LIST_DATA_WITH_DEPARTMENT:
      return Object.assign({}, state, { spcmDropDownDEP: action.payload });

    case types.APPROVER_LIST_DATA_WITH_USER_TYPE:
      return Object.assign({}, state, { spcmDropDownTYPEUSER: action.payload });

    case types.APPROVER_LIST_DATA_WITH_COST_CENTRE:
      return Object.assign({}, state, { spcmDropDownCENTRE: action.payload });

    case types.EMPTY_COST_CENTER:
      return [];

    case types.APPROVER_LIST_DATA_WITH_LOCATION:
      return Object.assign({}, state, { spcmDropDownLOCATION: action.payload });

    case types.SERVICE_AUDIT_LOGS:
      return Object.assign({}, state, { serviceAuditLogs: action.payload });

    case types.SERVICE_LIST_COMPANY:
      console.log("ACTION PAYLOAD", action.payload);
      return Object.assign({}, state, { serviceListCompany: action.payload });

    case types.SERVICE_LIST_COMPANY_FOR_SEARCH:
      return Object.assign({}, state, {
        serviceListCompanyForSearch: action.payload,
      });

    default:
      return state;

    case types.SHARED_OFFERINGS:
      return Object.assign({}, state, {
        sharedOfferings: action.payload,
        meta: action.meta,
      });
      case types.NE_COMPANY:
        // console.log("action.payload ",action.payload );
      return Object.assign({}, state, { necompanydetails: action.payload });
    case types.SERVICELIST_DATA:
      return Object.assign({}, state, {
        serviceListData: action.payload,
        serviceListDataMeta: action.meta,
      }); 
      
    case types.NOTIFICATION_DATA:
      return Object.assign({}, state, {
        notification_data: action.payload,
      });  

    case types.CIBASE_DATA_FOR_STANDARD_CHANGE:
      return Object.assign({}, state, { ciBasedDataDetails: action.payload });


      case types.LOGO_NE:

      return Object.assign({}, state, {
        uploadlogo: action.payload,

      });
      case types.SERVICE_CI:
        return Object.assign({}, state, {
          service_ci_data: action.payload,
        });  
      case types.GET_CONTEXTUAL_RULES:
        return Object.assign({}, state, {
          contextual_rules: action.payload,
        });  
      case types.GET_TASK_CONTEXTUAL_RULES:
        return Object.assign({}, state, {
          task_contextual_rules: action.payload,
      });  
      case types.ENTITLED_OFFERINGS_FETCHED_SUCCESSFULLY:
        return Object.assign({}, state, {
          entitledOnBehalfOfferings: action.payload,
      });  
      case types.COMPANY_USERS_LIST_FETCHED_SUCCESSFULLY:
        return Object.assign({}, state, {
          companyUsersList: action.payload
      });

      case types.ATTACHMENTS_SB:
        return Object.assign({}, state, {
          attachmentsReducer: action.payload,
        }); 
      case types.ATTACHMENTS_PAGINATION:
        return Object.assign({}, state, {
          paginationAttachmentsReducer: action.payload,
           meta: action.meta,

        }); 
        case types.SERVICE_OWNER_GROUP:
          return Object.assign({}, state, {
            serviceOwnerGroupReducer: action.payload, 
          }); 
          case types.NE_COMPANY_MAILBOX:
            return Object.assign({}, state, {mailbox: action.payload});
          case types.MY_VIEW_NOTIFICATION:
            return Object.assign({}, state, {myViewNotificationList: action.payload});
          case types.TOA:
            return Object.assign({}, state, {toaList: action.payload});
          case types.TOT:
            return Object.assign({}, state, {totList: action.payload});
          case types.TOA_EDIT:
            return Object.assign({}, state, {toaEditList: action.payload});
          case types.TOT_EDIT:
            return Object.assign({}, state, {totEditList: action.payload});
  }
};

export const isFetchingDetails_Knowledge = (state = {
  isFetching: false,
  posting:false,
  postSuccess:false,
  postingError:false,
  message: '',
  isFetchingAttachedKA: false
}, action) => {
  switch (action.type) {
    case 'FETCHING_DETAILS_IN_PROGRESS_KA':
      return Object.assign({}, state, {
        isFetching: true,
        message: 'loading...'
      });
    case 'DETAILS_FETCHED_SUCCESSFULLY_KA':
      return Object.assign({}, state, {
        isFetching: false,
        message: ''
      });
    case 'FETCHING_DETAILS_IN_PROGRESS_ATTACHED_KNOWLEDGE_ARTICLES':
      return Object.assign({}, state, {
        isFetchingAttachedKA: true,
        message: 'loading...'
      });
    case 'DETAILS_FETCHED_SUCCESSFULLY_ATTACHED_KNOWLEDGE_ARTICLES':
      return Object.assign({}, state, {
        isFetchingAttachedKA: false,
        message: ''
      });
    default:
      return state;
  }
};

export const attachedKnowledgeArticlesList = (state = [], action) => {
  switch (action.type) {
    case 'LOAD_ATTACHED_KNOWLEDGE_ARTICLES_SUCCESS':
      if(action.articleResultsResponse && action.articleResultsResponse != undefined && action.articleResultsResponse.data && Array.isArray(action.articleResultsResponse.data) && action.articleResultsResponse.data.length) {
          return action.articleResultsResponse.data;
      }
      else{
          return( [] );
      }
    default:
      return state;
  }
};

export const toaAuditData = (state = {loading: false, data: []}, action) => {
  switch (action.type) {
    case "TOA_AUDIT_DATA_FETCHING":
      return {loading: true, data: []};
    case "TOA_AUDIT_DATA_FETCHED":
      return {loading: false, data: action.payload};
    case "TOA_AUDIT_DATA_FETCHED_FAILED":
      return {loading: false, data: action.payload};
    default:
      return state;
  }
};

export const rulesAuditData = (state = {loading: false, data: []}, action) => {
  switch (action.type) {
    case "RULE_AUDIT_DATA_FETCHING":
      return {loading: true, data: []};
    case "RULE_AUDIT_DATA_FETCHED":
      return {loading: false, data: action.payload};
    case "RULE_AUDIT_DATA_FETCHED_FAILED":
      return {loading: false, data: action.payload};
    default:
      return state;
  }
};

export const refreshDataDictionaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'REFRESH_DATA_DICTIONARY': 
      return {
        ...state,
        refreshed: true,
      };

    default:
      return state;
  }
};


