
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, {Component, PropTypes } from 'react';
//import {Row,Col,FormGroup,FormControl,ControlLabel,Button,ListGroup,ListGroupItem} from 'react-bootstrap';
import AutoSuggest from 'react-autosuggest';
import axios from 'axios';
import Button from 'react-bootstrap/Button';

import { GLOBAL } from "_Globals";

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

let selectOptions = [];
const getSuggestions = (value,options) =>{
   const inputValue = value.toLowerCase();
   const inputLength = inputValue.length;
    if(inputLength >=4){
      selectOptions =options;
    }
   return inputLength < 4 ? []: selectOptions.filter(option => option.label.toLowerCase().indexOf(inputValue) > -1);
};

const getSuggestionValue = suggestion => suggestion.label.indexOf == -1 ? '' : suggestion.label.substring(0,suggestion.label.indexOf('|'));

function renderSuggestion(suggestion) {
  let suggestionlabel =[];
  suggestionlabel= suggestion.label.split("|");
  suggestionlabel.push(suggestion.locationId);
  return (
    <Button className='suggBtn' bsClass='' bsStyle=''  onClick = {()=>{
      GLOBAL.breakFixConsumerName = suggestionlabel[0];
      GLOBAL.breakFixConsumerID = suggestion.value;
      GLOBAL.servicelocationid = suggestionlabel[4];
    }}>
      {suggestion.fullname}<div>{suggestion.email}{" - "}{suggestion.companyName}</div>
    </Button>
  );
}


class RFReactSelect extends Component {
    constructor(props){
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.validateField = this.validateField.bind(this);
        this.getOptions = this.getOptions.bind(this);
        this.onConsumerSelected = this.onConsumerSelected.bind(this);
        this.state = {
          value: '',
          suggestions: []
        };
    }
    onChange(event,{newValue}){
        this.setState({
          value: newValue
        });
        if(newValue===''){
          if(typeof this.props.clearGroupandIndividual!=='undefined'){
            this.props.clearGroupandIndividual();
          }
          if(typeof this.props.resettingConsumerInfoState!=='undefined'){
            this.props.resettingConsumerInfoState();
          }
        }
    }
    onSuggestionsFetchRequested ({value}){
        if(typeof this.props.onType!=='undefined'){
          this.props.onType(false);
        }
        if(typeof this.props.updateConsumerInfoState!=='undefined'){
          this.props.updateConsumerInfoState('');
        } 
        if(value === this.state.value){
          return false;
        }
        const {companyId = ''} = this.props;
        if('companyId' in this.props){
          if(!companyId){
            this.setState({
              suggestions:[]
            });
            return false;
          }
          this.getOptions(value);
        }
        else{
          this.setState({
            suggestions: getSuggestions(value,this.props.options)
          });
        }
    }
    onSuggestionsClearRequested (){
        this.setState({
          suggestions: []
        });
        if(this.state.value == ''){
          GLOBAL.breakFixConsumerName ='';
          GLOBAL.breakFixConsumerID ='';
          if(typeof this.props.checkforAMSattributes!=='undefined'){
            this.props.checkforAMSattributes('','');
          }
        }
    }
    validateField(){
        if(typeof this.props.setErrorColor!=='undefined'){
        if(GLOBAL.breakFixConsumerID==""){
            this.props.setErrorColor('error','consumer');
        }else{
            this.props.setErrorColor('','consumer');
          }
        }
    }
    getOptions(value){
		try{
			let companyId = (this.props.companyId) ? parseInt(this.props.companyId, 10) : '';
			let options = [];
			if(value.length >=4){
				let strObj = {};
				strObj.companyId = companyId;
				strObj.like = value.toLowerCase();
				strObj.userId="";
				strObj = JSON.stringify(strObj);
				api.get(GLOBAL.consumerUsersNewUrl,{headers:{'query':strObj}}).then((response) => {
					response.data.map((subItem)=>{
						if(companyId == subItem.companyId){
							let obj = {
								'value':subItem.userId,
								'label':subItem.fullname + "|" + subItem.email + "|" + subItem.userId + "|" + subItem.companyName,
                                 'locationId': subItem['locationId'],
                                 'locationName': subItem['locationName'],
                                 'companyName':subItem.companyName,
                                 'email':subItem.email,
                                 'fullname':subItem.fullname};
							options.push(obj);
						}
					});
					this.setState({suggestions:options});
				});
			}
		}catch(e){
			this.setState({
				suggestions:[]
			});
		}
    }
    onConsumerSelected(event, {suggestion}){
        if(typeof this.props.onConsumerSelected!=='undefined'){
          this.props.onConsumerSelected(suggestion);
        }   
    }
    componentWillReceiveProps(nextProps) {
      if(typeof nextProps.selectedOption!=='undefined'){
        this.setState({ value: nextProps.selectedOption });
      }
    }
    render(){
        const { value = '', suggestions } = this.state;
        const inputProps = {
        placeholder: this.props.placeHolder!='' && this.props.placeHolder!=undefined?this.props.placeHolder:"Enter User Name",
        className: "form-control " + (this.props.errorColor || ""),
        value,
        onChange: this.onChange,
        onBlur: this.validateField,
        disabled:this.props.disabled
      };
        return (
            <AutoSuggest
                suggestions = {suggestions}
                onSuggestionsFetchRequested = {this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue = {getSuggestionValue}
                renderSuggestion = {renderSuggestion}
                inputProps = {inputProps}
                onSuggestionSelected={this.onConsumerSelected}
              />
        );
    }
}
export default RFReactSelect;