
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import {
    ExpansionPanel,
    ExpansionPanelContent
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation"
import StylePropertyField from "./stylePropertyField";

export default function CustomStyleManager({sectors}){
    const [expanded, setExpanded] = useState(null);
    return (
        <div className="gjs-custom-style-manager rightPanelComp text-left">
            <div className="rightPanelGap">
            {sectors.map(sector => (
                <ExpansionPanel 
                    key={sector.getId()} 
                    title={sector.getName()}
                    expanded={expanded === sector.getId()}
                    onAction={(event) => setExpanded(event.expanded? null: sector.getId())}
                >
                    <Reveal>
                        {expanded === sector.getId() && (
                            <ExpansionPanelContent>
                                <div class="row">
                                    {sector.getProperties().map(prop => (
                                        <StylePropertyField key={prop.getId()} prop={prop}></StylePropertyField>
                                    ))}
                                </div>
                            </ExpansionPanelContent>
                        )}
                    </Reveal>
                </ExpansionPanel>
            ))}
            </div>
        </div>
    )
}