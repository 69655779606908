
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const switchComponent = (e) => {
  e.Components.addType("switch-button", {
    model: {
      defaults: {
        tagName: "div",
        traits: [
          {
            type: "text",
            label: "name",
            name: "name",
            changeProp: 1,
          },
          {
            type: "text",
            label: "Title Text",
            name: "titleText",
            changeProp: 1,
          },
          {
            type: "checkbox",
            label: "Checked",
            name: "checked",
            changeProp: 1,
          },
        ],
        attributes: { class: "switch-container" },
        components: `
              <label class="switch" for="switch">
                <input type="checkbox" id="switch">
                <span class="slider round"></span>
              </label>
            `,
        styles: `
              .switch {
                position: relative;
                display: inline-block;
                width: 48px;
                height: 20px;
              }
              .switch input {
                opacity: 0;
                width: 0;
                height: 0;
              }
              .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #ccc;
                transition: .4s;
              }
              .slider:before {
                position: absolute;
                content: "";
                height: 14px;
                width: 14px;
                left: 4px;
                bottom: 3px;
                background-color: white;
                transition: .4s;
              }
              input:checked + .slider {
                background-color: #2196F3;
              }
              input:checked + .slider:before {
                transform: translateX(26px);
              }
              .slider.round {
                border-radius: 20px;
              }
              .slider.round:before {
                border-radius: 50%;
              }
            `,
      },
    },
    view: {
      onRender({ el }) {
        // Custom render logic if needed
      },
    },
  });
};


export const switchButtonBlockJson = {
  label: "Switch Button",
  content: { type: "switch-button" },
  media:`<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.88"><title>switch</title><path d="M61.44,0A61.46,61.46,0,1,1,18,18,61.23,61.23,0,0,1,61.44,0ZM72.91,41.27a5.47,5.47,0,1,1,7.3-8.16L93.72,45.23A5.48,5.48,0,0,1,94.15,53l-.35.35h0l-13,12.12a5.48,5.48,0,0,1-7.47-8L76.1,54.8H62.39a5.5,5.5,0,0,1,0-11H75.75l-2.84-2.54Zm-30.5,16.1a5.47,5.47,0,1,1,7.3,8.16l-2.83,2.54H61.15a5.5,5.5,0,0,1,0,11H46.53l2.8,2.6a5.48,5.48,0,1,1-7.47,8l-13-12.11h0l-.35-.36a5.47,5.47,0,0,1,.42-7.73L42.41,57.37ZM96.86,26a50.08,50.08,0,1,0,14.68,35.43A49.95,49.95,0,0,0,96.86,26Z"/></svg>`,
  category: "Forms",
};