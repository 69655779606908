
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Row, Col, Form, Accordion } from "react-bootstrap";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { Field } from "formik";
import Cookies from "universal-cookie";
import { GLOBAL } from "_Globals";
import {
  loadCIStatusCurrent,
  loadCIMetallic,
  loadCIEnv,
  loadCIManr,
  loadCIDataset,
  loadCICompany,
  loadCIUserBasedLocation,
  loadCIGroup,
  emptyCIGroup,
  loadCIInd,
  loadCICat,
  loadCIType,
  loadCISCat,
  loadCISupCompany,
  emptyCISupCompany,
  emptyCICatReducer,
  emptyCISCatReducer,
  loadCIMenulistStatus,
  loadCIMenulistSubStatus
} from "../../../actions/cmdb/lovAction";

import {
  loadClassByCompanyId,
  resetCiClass,
} from "../../../actions/ham/hamAction";

import {
  InputTextField,
  SelectField,
  StandardTextArea,
} from "../../common/forms/FormFields";
import { resetCMDBQVFilters } from "../../../actions/cmdb/quickViewAction";

import CmdbDropdownPlusTypeahead from "../common/CmdbDropdownPlusTypeahead.js";

import CustomTypeHead from "../add-edit/formfield/CustomTypeHead";
import AssignedToTypeHead from "../add-edit/formfield/AssignedToAsyncTypeahead";

import { TypeaheadSelect, DateTimeField, DatePickerCMDB } from "../common/helper.js";
import {
  useMenuListDropdownOptions,
  useDepartmentOptions,
  useLocationData,
  useDrLocationData,
  useSupportCompany,
  useSupportGroup,
  useCostCenterOptions,
  useVendor,
  useVendorLessor,
  useComplianceRequirement,
  useDiscoverySource,
  useMonitored
} from "../add-edit/formfield/hooks.js";

const cookies = new Cookies();

let cookiesData = cookies.get('gph');
if (cookiesData) cookiesData = cookiesData.replace('s:', '');
if (cookiesData) cookiesData = cookiesData.substring(0, cookiesData.lastIndexOf('.'));
cookiesData = cookiesData.split("~");

let onContractRoleVisible = cookiesData[33] ? cookiesData[33].split(',') : [];
onContractRoleVisible = onContractRoleVisible.includes('68');

let onConfigManagerRoleVisible = cookiesData[33] ? cookiesData[33].split(',') : [];
onConfigManagerRoleVisible = onConfigManagerRoleVisible.includes('37');

let onAssetRoleVisible = cookiesData[33] ? cookiesData[33].split(',') : [];
onAssetRoleVisible = onAssetRoleVisible.includes('38')

let onConfigViewerRoleVisible = cookiesData[33] ? cookiesData[33].split(',') : [];
onConfigViewerRoleVisible = onConfigViewerRoleVisible.includes('39');


let dateformat = cookiesData[2];

const AddEditCmdb = (props) => {
  const {cIEditDetails, setFieldValue, values, isValid, submitCount, errors, touched } =
    props;

  const dispatch = useDispatch();
  const params = useParams();

  const ciId = params?.ciId;
  const isAddMode = !ciId;
  const ciCompany = useSelector((state) => state.ciCompany);
  const translator = useSelector((state) => state.spcmReducer.tr);
  const classOptions = useSelector((state) => state.compClass);
  const ciTypeOptions = useSelector((state) => state.ciType);
  const ciMetallicOptions = useSelector((state) => state.ciMetallic);
  const categoryOptions = useSelector((state) => state.ciCat);
  const subCategoryOptions = useSelector((state) => state.ciSCat);
  const environmentOptions = useSelector((state) => state.ciEnv);
  const statusOptions = useSelector((state) => state?.ciStatus);
  const subStatusOptions = useSelector((state) => state?.ciSubStatus);

  const [companyId, setCompanyId] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [companyTypeaheadOptions, setCompanyTypeaheadOptions] = useState([]);
  const [locationToggle, setLocationToggle] = useState("all");
  const [type, setType] = useState("location");
  const currencyOptions = useMenuListDropdownOptions("currency");
  const chargeCategoryOptions = useMenuListDropdownOptions("chargeCategory");
  const chargeFrequencyOptions = useMenuListDropdownOptions("chargeFrequency");
  const chargeTypeOptions = useMenuListDropdownOptions("chargeType");
  const drTierOptions = useMenuListDropdownOptions("drTier");
  const ownershipTypeOptions = useMenuListDropdownOptions("ownershipType");
  const depreciationScheduledOptions = useMenuListDropdownOptions(
    "depreciationScheduled"
  );
  const dataSetOptions = useMenuListDropdownOptions("Dataset");
  const departmentOptions = useDepartmentOptions(values?.company_id);
  const { locationOptions } = useLocationData(values?.company_id, locationToggle);
  const { drLocationOptions } = useDrLocationData(values?.company_id);

  const supportCompanyOptions = useSupportCompany(values?.company_id);
  const changeGroupOptions = useSupportGroup(values?.company_id);
  const supportGroupOptions = useSupportGroup(values?.support_company_id);
  const costCenterOptions = useCostCenterOptions(values?.company_id, values?.location_id);
  const vendorOptions = useVendor(values?.owned_by_id);
  const vendorLessorOptions = useVendorLessor(values?.owned_by_id);
  const vendorSellerOptions = useSupportCompany(values?.owned_by_id);
  const complianceOptions = useComplianceRequirement(values?.company_id);
  const discoverySourceOptions = useDiscoverySource(values?.company_id);
  const chargeToCcOptions = useCostCenterOptions(values?.owned_by_id, values?.location_id);
  const monitoredOptions = useMonitored(values?.company_id);

  const isDeployedWithSpecificSubStatus = cIEditDetails?.STATUS_NAME === "Deployed" && [
    "Functional",
    "In Maintenance",
    "Inoperable",
    "Monitoring",
    "Out of Service",
    "Planned for Refresh",
    "Swapped",
  ].includes(cIEditDetails.SUB_STATUS_NAME);

  const handleChangeClass = (event) => {
    dispatch(emptyCICatReducer());
    dispatch(emptyCISCatReducer());
    let filter = classOptions.filter((d) => d.CLASS_ID == event.target.value);
    let type = filter?.[0]?.CLASS_TYPE;
    let classId = filter?.[0]?.CLASS_ID;

    if (type?.toLowerCase() == "asset") {
      setFieldValue("type", "5");
      setFieldValue("status", "1");
      setFieldValue("sub_status", "1");
      GLOBAL.cmdbCreateSelectedText["type_name"] = "Asset";
      GLOBAL.cmdbCreateSelectedText["status_name"] = "Requested";
      GLOBAL.cmdbCreateSelectedText["sub_status_name"] = "Planned";
    } else if (type?.toLowerCase() == "ci") {
      setFieldValue("type", "10");
      setFieldValue("status", "6");
      setFieldValue("sub_status", "11");
      GLOBAL.cmdbCreateSelectedText["type_name"] = "CI";
      GLOBAL.cmdbCreateSelectedText["status_name"] = "Build";
      GLOBAL.cmdbCreateSelectedText["sub_status_name"] = "In Progress";
    } else if (type?.toLowerCase() == "both") {
      setFieldValue("type", "15");
      setFieldValue("status", "1");
      setFieldValue("sub_status", "1");
      GLOBAL.cmdbCreateSelectedText["type_name"] = "Both";
      GLOBAL.cmdbCreateSelectedText["status_name"] = "Requested";
      GLOBAL.cmdbCreateSelectedText["sub_status_name"] = "Planned";
    }

    const selectedOption = event.target["selectedIndex"];
    const className = event.target.options[selectedOption].dataset?.name;
    setFieldValue("class_id", event.target.value);
    setFieldValue("class_name", className);
  };


  const now = moment();

  const timeConstraints = {
    hours: { min: moment().hours(), max: 23 },
    minutes: { min: 0, max: 59 },
  };

  const isValidStartTime = (current) => {
    const yesterday = moment().subtract(1, "day");
    return current.isAfter(yesterday);
  };

  const isValidEndTime = (current, startDate) => {
    const start = moment.isMoment(startDate) ? startDate : moment(startDate);
    if (current.isSame(start, "day")) {
      return true;
    }
    return current.isAfter(start);
  };

  const endDateTimeConstraints = (startDate) => {
    const startMoment = moment(startDate);

    if (!startMoment.isValid())
      return { hours: { min: 0, max: 23 }, minutes: { min: 0, max: 59 } };

    return {
      hours: { min: startMoment.hour(), max: 23 },
      minutes: { min: 0, max: 59 },
    };
  };

  const optionsConfig = {
    10: [{ value: "6", label: "Build" }],
    5: [{ value: "1", label: "Requested" }],
    15: [{ value: "1", label: "Requested" }],
  };

  const subStatusConfig = {
    10: [{ value: "11", label: "In Progress" }],
    5: [{ value: "1", label: "Planned" }],
    15: [{ value: "1", label: "Planned" }],
  };

    
  // const statusOptions = optionsConfig[values?.type] || [];
  //const subStatusOptions = subStatusConfig[values?.type] || [];

  useEffect(() => {
    if (onAssetRoleVisible || onConfigManagerRoleVisible) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [onAssetRoleVisible, onConfigManagerRoleVisible]);


  useEffect(() => {
    dispatch(loadCICompany("0"));
  }, [dispatch]);

  useEffect(() => {
    if (values?.company_id) {
      dispatch(loadClassByCompanyId(values?.company_id, false, "ACTIVE"));
    }
  }, [dispatch, values?.company_id]);

  useEffect(() => {
    dispatch(loadCIType());
  }, [dispatch]);

  useEffect(() => {
    if (values?.class_id) {
      dispatch(loadCICat(values?.class_id, values?.company_id));
    }
  }, [dispatch, values?.class_id, values?.company_id]);


  useEffect(() => {
    const category_comp_id = categoryOptions?.find(category => category.category_name === values?.category_name);
    
      if (values?.category_name && values?.class_id && category_comp_id?.cat_company_id !== undefined && category_comp_id?.cat_company_id !== null) {
        dispatch(
          loadCISCat(values?.category_name, values?.class_id, category_comp_id?.cat_company_id)
        );
      } else {
        dispatch(emptyCISCatReducer());
      }
  }, [dispatch,values?.category_name, categoryOptions, values?.class_id]);
  

  useEffect(() => {
    if (ciCompany && ciCompany.length > 0) {
      const options = ciCompany.map((item) => ({
        id: item.field1Value,
        label: item.field1Key,
      }));
      setCompanyTypeaheadOptions(options);
    }
  }, [ciCompany]);

  useEffect(() => {
    if (values?.status) {
    dispatch(loadCIMenulistStatus("Status List"));
    dispatch(loadCIMenulistSubStatus("SubStatus List", values?.status));   
    }
  }, [dispatch, values?.status]);


  useEffect(() => {
    dispatch(resetCiClass());
    dispatch(loadCIStatusCurrent("create", 0));
    dispatch(loadCIMetallic());
    dispatch(loadCIEnv());
    dispatch(loadCIManr());
    dispatch(loadCICompany("0"));
    dispatch(resetCMDBQVFilters());
    dispatch(loadCIType());

    // Set global error colors
    GLOBAL.cinameErrorColor = "";
    GLOBAL.ciclassErrorColor = "";
    GLOBAL.cistatusErrorColor = "";
    GLOBAL.cicompanyErrorColor = "";
    GLOBAL.cibusinessownerErrorColor = "";
    GLOBAL.citechownerErrorColor = "";
    GLOBAL.categoryErrorColor = "";
    GLOBAL.subCategoryErrorColor = "";
    GLOBAL.ciSubStatusErrorColor = "";
    GLOBAL.serialNumberErrorColor = "";
  }, [dispatch]);

  //  console.log(errors, "errors");

  return (
    <div className="borderForm bFormDiv">
      <Row className={!isValid && submitCount > 0 ? "" : "d-none"}>
        <Col md={12} sm={12} xs={12}>
          {!isValid && submitCount > 0 && (
            <div className="alert alert-danger">
              The form has missing required fields, please revalidate the
              highlighted fields and update the form !
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={4} sm={6} xs={12}>
          <InputTextField           
            required
            name="ci_name"
            label={translator["Name"]}
            onChange={(event) => {
              setFieldValue("ci_name", event.target.value);
            }}
            disabled={isDisabled || isDeployedWithSpecificSubStatus}
          />
        </Col>
        <Col lg={4} md={4} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label bsClass="">
              <span className="rStar"></span>
              {translator["Company"]}
            </Form.Label>

            <Field
              name="company_name"
              component={CmdbDropdownPlusTypeahead}
              options={companyTypeaheadOptions || []}
              onSelection={(val) => {
                setCompanyId(val?.[0]?.id);
                setFieldValue("company_id", val?.[0]?.id);
                setFieldValue("company_name", val?.[0]?.label);
              }}
              inputValue={values?.company_name || ""}
              // name="company"
              id="company"
              onInputChange={(val) => {
                console.log(val, "onInputChange");
              }}
              selectedOptions={
                values?.company_name
                  ? [{ id: values.company_id, label: values.company_name }]
                  : []
              }
              errorColor={
                errors?.company_id && touched?.company_id ? "error" : ""
              }
              disabled={!isAddMode}
              // onCrossClick={() => {}}
            />
          </Form.Group>
        </Col>
        <Col lg={4} md={4} sm={6} xs={12}>
          <SelectField
            required
            name="class_id"
            label={translator["Class"]}
            onChange={(event) => {
              handleChangeClass(event);
            }}
            disabled
          >
            {classOptions?.map((classObj, i) => {
              return (
                <option
                  value={classObj.CLASS_ID}
                  key={i}
                  data-name={classObj.CLASS_NAME}
                >
                  {classObj.CLASS_NAME}
                </option>
              );
            })}
          </SelectField>
        </Col>
        <Col lg={4} md={4} sm={6} xs={12}>
          <SelectField
            name="type"
            label="Type"
            required
            onChange={(e) => {
              const val = e.target.value;
              const statusVal =
                val === "10" ? "6" : val === "5" || val === "15" ? "1" : "";
              const subStatusVal =
                val === "10" ? "11" : val === "5" || val === "15" ? "1" : "";
              setFieldValue("type", val);
              setFieldValue("status", statusVal);
              setFieldValue("sub_status", subStatusVal);
            }}
            disabled={isDisabled  || isDeployedWithSpecificSubStatus || cIEditDetails?.END_OF_LIFECYCLE === "true"}
          >
            {ciTypeOptions?.map((typeObj, i) => {
              return (
                <option value={typeObj.FIELD1_VALUE} key={i}>
                  {typeObj.FIELD1_KEY}
                </option>
              );
            })}
          </SelectField>
        </Col>
        <Col lg={4} md={4} sm={6} xs={12}>
          <SelectField
            name="category_name"
            label="Category"
            required
            onChange={(e) => {
              const selectedOption = e.target["selectedIndex"];
              const nameText = e.target.options[selectedOption].dataset?.name;
              const catCompId =
                e.target.options[selectedOption].dataset?.company;
              if (nameText) {
                dispatch(
                  loadCISCat(nameText, values?.class_id, e.target.value)
                );
              } else {
                dispatch(emptyCISCatReducer());
              }

              // setFieldValue("category_id", catCompId);
              setFieldValue("category_name", nameText);
              setFieldValue("category_companyId", catCompId);
              setFieldValue("category_id", "");
            }}
            disabled={isDisabled || isDeployedWithSpecificSubStatus}
          >
            {categoryOptions?.map((catObj, i) => {
              return (
                <option
                  value={catObj?.category_name}
                  data-company={catObj?.cat_company_id}
                  key={`key_cat_${i}`}
                  data-name={catObj?.category_name}
                >
                  {catObj?.category_name}
                </option>
              );
            })}
          </SelectField>
        </Col>
        <Col lg={4} md={4} sm={6} xs={12}>
          <SelectField
            name="sub_category_name"
            label="Sub Category"
            required
            onChange={(e) => {
              const selectedOption = e.target["selectedIndex"];
              const catId = e.target.options[selectedOption].dataset?.id;
              setFieldValue("category_id", catId);
              setFieldValue("sub_category_id", Number(catId || 0));
              setFieldValue("sub_category_name", e.target.value);
            }}
            disabled={isDisabled || isDeployedWithSpecificSubStatus}
          >
            {subCategoryOptions?.map((obj, i) => {
              return (
                <option
                  value={obj?.sub_category_name}
                  key={`key_subcat_${i}`}
                  data-id={obj?.category_id}
                >
                  {obj?.sub_category_name}
                </option>
              );
            })}
          </SelectField>
        </Col>
        <Col lg={4} md={4} sm={6} xs={12}>
          <InputTextField name="ci_id" label="Asset/CI ID" disabled />
        </Col>
        <Col lg={4} md={4} sm={6} xs={12}>
          <InputTextField name="asset_tag" label="Asset Tag" disabled={isDisabled || isDeployedWithSpecificSubStatus}/>
        </Col>
        <Col lg={4} md={4} sm={6} xs={12}>
          <InputTextField
            maxLength={250}
           disabled={isDisabled || isDeployedWithSpecificSubStatus}
            name="serial_number"
            label={translator["Serial Number"]}
          />
        </Col>
        <Col lg={4} md={4} sm={6} xs={12}>
          <SelectField
            name="status"
            label="Status"
            required
            onChange={(e) => {
              const selectedOption = e.target["selectedIndex"];
              const nameText = e.target.options[selectedOption].dataset?.name;

              setFieldValue("status_name", nameText);
              setFieldValue("status", e.target.value);
            }}
            disabled={isDisabled || cIEditDetails?.END_OF_LIFECYCLE === "true"}
          >
            {statusOptions?.map((option) => (
              <option key={option.ID} value={option?.ID} data-name={option.STATUS_NAME}>
                {option.STATUS_NAME}
              </option>
            ))}
          </SelectField>
        </Col>
        <Col lg={4} md={4} sm={6} xs={12}>
          <SelectField
            name="sub_status"
            label="Sub Status"
            required
            onChange={(e) => {
              setFieldValue("sub_status", e.target.value);
            }}
            disabled={isDisabled || cIEditDetails?.END_OF_LIFECYCLE === "true"}
          >
            {subStatusOptions?.map((option) => (
              <option key={`sub_key-${option?.ID}`} value={option?.ID}>
                {option?.SUB_STATUS_NAME}
              </option>
            ))}
          </SelectField>
        </Col>
        <Col lg={4} md={4} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label bsClass="">
              Allocation Date
            </Form.Label>
            <DateTimeField
              name="allocation_date"
              error={errors?.allocation_date && touched?.allocation_date}
              format={dateformat}
              onChange={(value) => {
                setFieldValue("allocation_date", value);
              }}
              value={values?.allocation_date}
            />
          </Form.Group>
        </Col>
        <Col md={12}>
          <StandardTextArea
            characterCount
            mrtop={0}
            rows="2"
            name="description"
            label={translator["Description"]}
            disabled={isDisabled}
          />
        </Col>
        <Col lg={4} md={4} sm={6} xs={12}>
          <InputTextField name="manufacturer" label="Manufacturer" />
        </Col>
        <Col lg={4} md={4} sm={6} xs={12}>
          <InputTextField name="model_name" label="Model Name" />
        </Col>
        <Col lg={4} md={4} sm={6} xs={12}>
          <InputTextField errorMessage name="model_number" label="Model Number" />
        </Col>
        {/* <Col  md={12} sm={6} xs={12}>
        <StandardTextArea
          characterCount
          mrtop={0}
          rows="2"
          name="compliance"
          label="Compliance Requirement"
          />
          </Col> */}
        <Col lg={4} md={4} sm={6} xs={12}>
          <SelectField
            name="metallic"
            label={translator["Business Criticality"]}
            disabled={isDisabled || isDeployedWithSpecificSubStatus}
          >
            {ciMetallicOptions?.map((metallicObj) => {
              return (
                <option value={metallicObj?.FIELD1_VALUE}>
                  {metallicObj?.FIELD1_KEY}
                </option>
              );
            })}
          </SelectField>
        </Col>
        <Col lg={4} md={4} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label bsClass="">Warranty Expiration</Form.Label>
            <DateTimeField
              name="warranty_expiration_date"
              format={dateformat}
              onChange={(value) => {
                setFieldValue("warranty_expiration_date", value);
              }}
              value={values?.warranty_expiration_date}
            />
          </Form.Group>
        </Col>
        <Col lg={4} md={4} sm={6} xs={12} className="rdtDiv">
          <Form.Group className="form-group">
            <Form.Label bsClass="">Compliance Requirement</Form.Label>
            <div className="colSearDv multiSelDv">         
            <ReactMultiSelectCheckboxes
                name="compliance"
                placeholderButtonLabel={translator["Select"]}
                value={values?.compliance ? values.compliance : []}
                rightAligned={false}
                options={complianceOptions || []}
                onChange={(selectedOptions) => {
                  setFieldValue("compliance", selectedOptions);
                }}
              />
            </div>
          </Form.Group>      
        </Col>
        <Col lg={4} md={4} sm={6} xs={12}>
          {/* location_id: "0",
         location_name: "", */}
          <Form.Group className="form-group">
            <a
              className="showwAllGrps margin-t-5 float-r"
              href="javascript:void(0)"
              onClick={() => {
                let newFlag = locationToggle == "all" ? "stockroom" : "all";
                setLocationToggle(newFlag);
                //type: "drLocation",
                setType(locationToggle == "all" ? "location" : "stockroom");
              }}
            >
               {locationToggle == "all"
                ? "Show Stockroom Locations"
                : "Show All Locations"}
            </a>
            <Form.Label bsClass="">{translator["Location"]}</Form.Label>
            <div className="dvTypehd">
              <TypeaheadSelect
                onSelection={(val) => {
                  setFieldValue("location_id", val[0]?.id?.toString());
                  setFieldValue("location_name", val[0]?.label);
                }}
                id="location_id"
                name="location_name"
                className="form-control"
                options={locationOptions || []}
                selectedOptions={
                  values?.location_name
                    ? [{ id: values.location_id, label: values.location_name }]
                    : []
                }
                tr={translator}
                typedValue=""
                onCrossClick={() => {
                  setFieldValue("location_id", "0");
                  setFieldValue("location_name", "");
                }}
                disabled={isDisabled || isDeployedWithSpecificSubStatus}
              />
            </div>
          </Form.Group>
        </Col>
        <Col lg={4} md={4} sm={6} xs={12}>
          <SelectField name="environment" label={translator["Environment"]} disabled={isDisabled || isDeployedWithSpecificSubStatus}>
            {environmentOptions?.map((envObj) => {
              return (
                <option value={envObj?.FIELD1_VALUE}>
                  {envObj?.FIELD1_KEY}
                </option>
              );
            })}
          </SelectField>
        </Col>
        <Col lg={4} md={4} sm={6} xs={12}>
          <InputTextField name="keyword" label="Keywords" />
        </Col>
      </Row>
      <Accordion className="margin-t-15 brkFix brkFixOrder brkFullAccordion">
        <Accordion.Item eventKey="0">
          <Accordion.Header>General Info</Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col lg={4} md={4} sm={6} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">
                    <span className="rStar"></span>
                    {"Is External"}
                  </Form.Label>
                  <div className="radioInlineBox margin-t-5">
                    <label className="radio-inline">
                      <Field
                        type="radio"
                        name="is_external"
                        value="true"
                        disabled={isDisabled || false}
                        onChange={(e) => {
                          setFieldValue("is_external", e.target.value);
                        }}
                        checked={values.is_external === "true"}
                      />
                      True
                    </label>
                    <label className="radio-inline">
                      <Field
                        type="radio"
                        name="is_external"
                        value="false"
                        disabled={isDisabled || false}
                        onChange={(e) => {
                          setFieldValue("is_external", e.target.value);
                        }}
                        checked={values.is_external === "false"}
                      />
                      False
                    </label>
                  </div>
                </Form.Group>
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">Deployment Date</Form.Label>
                  <DateTimeField
                    name="deployement_date"
                    format={dateformat}
                    onChange={(value) => {
                      setFieldValue("deployement_date", value);
                    }}
                    value={values?.deployement_date}
                  />
                </Form.Group>
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">Decommission Date</Form.Label>
                  <DateTimeField
                    name="decommission_date"
                    format={dateformat}
                    onChange={(value) => {
                      setFieldValue("decommission_date", value);
                    }}
                    value={values?.decommission_date}
                  />
                </Form.Group>
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">End of Support</Form.Label>
                  <DateTimeField
                    name="support_end_date"
                    format={dateformat}
                    onChange={(value) => {
                      setFieldValue("support_end_date", value);
                    }}
                    value={values?.support_end_date}
                  />
                </Form.Group>
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">First Deployed Date</Form.Label>
                  <DateTimeField
                    name="first_deployed_date"
                    format={dateformat}
                    onChange={(value) => {
                      setFieldValue("first_deployed_date", value);
                    }}
                    value={values?.first_deployed_date}
                  />
                </Form.Group>
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">Installed Date</Form.Label>
                  <DateTimeField
                    name="installation_date"
                    format={dateformat}
                    onChange={(value) => {
                      setFieldValue("installation_date", value);
                    }}
                    value={values?.installation_date}
                  />
                </Form.Group>
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
              <SelectField name="dataset" label="Dataset" onChange={(e)=>{
                  const selectedOption = e.target["selectedIndex"];
                  const labelText = e.target.options[selectedOption].dataset?.name;
          
                   setFieldValue("dataset_name",labelText);
                   setFieldValue("dataset",e.target.value);
              }}>
                  {dataSetOptions?.map((item) => {
                    return <option value={item["id"]} data-name={item?.label}>{item["label"]}</option>;
                  })}
                </SelectField>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Owner/Support Contacts</Accordion.Header>
          <Accordion.Body>
            <div className="margin-b-15">
              <div className="bgGray padding-5 margin-b-5">
                {translator["Owner Contacts"]}
              </div>
              <Row>
                <Col lg={4} md={4} sm={6} xs={12}>
                  <CustomTypeHead
                    required
                    clearButton
                    id="businessowner"
                    name="business_owner_name"
                    label="Business Owner"
                    thfor="personApi"
                    placeholder="Please type to search"
                    companyId={values?.company_id}
                    autoComplete="off"
                    labelkey={
                      isAddMode
                        ? "fullname"
                        : (option) =>
                            option?.fullname ? `${option?.fullname}` : option
                    }
                    onChange={(selected) => {
                      setFieldValue(
                        "business_owner_id",
                        selected[0]?.userId || 0
                      );
                      setFieldValue(
                        "business_owner_name",
                        selected[0]?.fullname || ""
                      );
                      setFieldValue(
                        "business_owner_email",
                        selected[0]?.email || ""
                      );
                    }}
                    selected={
                      values?.business_owner_name
                        ? [values?.business_owner_name?.trim()]
                        : []
                    }
                    errorColor={
                      errors?.business_owner_name &&
                      touched?.business_owner_name
                        ? "error"
                        : ""
                    }
                  />
                </Col>
                <Col lg={4} md={4} sm={6} xs={12}>
                  <CustomTypeHead
                    required
                    clearButton
                    id="techowner"
                    name="technical_owner_name"
                    label={translator["Technical Owner"]}
                    thfor="personApi"
                    placeholder="Please type to search"
                    companyId={values?.company_id}
                    autoComplete="off"
                    labelkey={
                      isAddMode
                        ? "fullname"
                        : (option) =>
                            option?.fullname ? `${option?.fullname}` : option
                    }
                    onChange={(selected) => {
                      const trimmedFullname = selected[0]?.fullname?.trim() || "";
                      setFieldValue(
                        "technical_owner_id",
                        selected[0]?.userId || 0
                      );
                      setFieldValue(
                        "technical_owner_name", trimmedFullname
                      );
                      setFieldValue(
                        "technical_owner_email",
                        selected[0]?.email || ""
                      );
                    }}
                    selected={
                      values?.technical_owner_name
                        ? [values?.technical_owner_name?.trim()]
                        : []
                    }
                    errorColor={
                      errors?.technical_owner_name &&
                      touched?.technical_owner_name
                        ? "error"
                        : ""
                    }
                  />
                </Col>
                <Col lg={4} md={4} sm={6} xs={12}>
                  <AssignedToTypeHead
                    clearButton
                    id="owner_id"
                    name="owner_name"
                    label={translator["Assigned To"]}
                    thfor="personApi"
                    placeholder="Please type to search"
                    companyId={values?.company_id}
                    autoComplete="off"
                    labelkey={
                      isAddMode
                        ? "fullname"
                        : (option) =>
                            option?.fullname ? `${option?.fullname}` : option
                    }
                    onChange={(selected) => {
                      const trimmedFullname = selected[0]?.fullname?.trim() || "";
                      setFieldValue("owner_id", selected[0]?.userId || 0);
                      setFieldValue("owner_name", trimmedFullname);
                      setFieldValue("owner_email", selected[0]?.email || null);
                      setFieldValue("allocation_date", null);
                    }}
                    selected={values?.owner_name ? [values?.owner_name?.trim()] : []}

                    // errorColor={
                    //   errors?.owner_name && touched?.owner_name ? "error" : ""
                    // }
                  />
                </Col>
                <Col lg={4} md={4} sm={6} xs={12}>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">Assigned Date</Form.Label>
                    <DateTimeField
                      format={dateformat}
                      onChange={(value) => {
                        setFieldValue("assigned_date", value);
                      }}
                      value={values?.assigned_date || ""}
                    />
                  </Form.Group>
                </Col>

                <Col lg={4} md={4} sm={6} xs={12}>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">Department</Form.Label>
                    <div className="dvTypehd">
                      <TypeaheadSelect
                        onSelection={(val) => {
                          setFieldValue("department_id", val[0]?.id);
                          setFieldValue("department_name", val[0]?.label);
                        }}
                        id="department-field"
                        name="department"
                        className="form-control"
                        options={departmentOptions || []}
                        // onInputChange={(e) => {

                        // }}
                        onCrossClick={() => {
                          setFieldValue("department_id", null);
                          setFieldValue("department_name", null);
                        }}
                        tr={translator}
                        selectedOptions={
                          values?.department_name
                            ? [
                                {
                                  id: values.department_id,
                                  label: values.department_name,
                                },
                              ]
                            : []
                        }
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={4} md={4} sm={6} xs={12}>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">Change Group</Form.Label>
                    <div className="dvTypehd">
                      <TypeaheadSelect
                        onSelection={(val) => {
                          setFieldValue("change_group_id", val[0]?.id);
                          setFieldValue("change_group_name", val[0]?.label);
                        }}
                        id="changeGroup-field"
                        name="changeGroup"
                        className="form-control"
                        options={changeGroupOptions || []}
                        selectedOptions={
                          values?.change_group_name
                            ? [
                                {
                                  id: values.change_group_id,
                                  label: values.change_group_name,
                                },
                              ]
                            : []
                        }
                        // onInputChange={(e) => {

                        // }}
                        onCrossClick={() => {
                          setFieldValue("change_group_id", null);
                          setFieldValue("change_group_name", null);
                        }}
                        tr={translator}
                        // typedValue={

                        // }
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={4} md={4} sm={6} xs={12}>
                  <InputTextField
                    maxLength={100}
                    name="availaibility_percentage"
                    label="Availability Percentage (Committed)"
                  />
                </Col>
              </Row>
            </div>

            <div className="">
              <div className="bgGray padding-5 margin-b-5">
                {["Support Contacts"]}
              </div>

              <Row>
                <Col lg={4} md={4} sm={6} xs={12}>
                  <Form.Group className="drpdwntop form-group">
                    <Form.Label bsClass="">
                    {["Support Company"]} 
                    {values?.status_name === 'Deployed' && <span className="rStar px-1"></span>}                      
                    </Form.Label>
                    <Field
                      component={CmdbDropdownPlusTypeahead}
                      onSelection={(val) => {
                        setFieldValue("support_company_id", val[0]?.id);
                        setFieldValue("support_company_name", val[0]?.label);
                      }}
                      name="supcompany"
                      id="supcompany"
                      className="form-control"
                      options={supportCompanyOptions || []}
                      selectedOptions={
                        values?.support_company_name
                          ? [
                              {
                                id: values.support_company_id,
                                label: values.support_company_name,
                              },
                            ]
                          : []
                      }
                      inputValue={values?.support_company_name || ""}
                      onCrossClick={() => {
                        setFieldValue("support_company_id", "0");
                        setFieldValue("support_company_name", "");
                        setFieldValue("group_id", "0");
                        setFieldValue("group_name", "");

                      }}
                      errorColor={
                        errors?.support_company_name && touched?.support_company_name ? "error" : ""
                      }
                    />
                  </Form.Group>
                </Col>
                <Col lg={4} md={4} sm={6} xs={12}>
                  <Form.Group className="drpdwntop form-group">
                    <Form.Label bsClass="">
                    {translator["Support Group"]}
                   {values?.status_name === 'Deployed' && <span className="rStar px-1"></span>}                  
                    </Form.Label>
                    <Field
                      id="group"
                      component={CmdbDropdownPlusTypeahead}
                      onSelection={(val) => {
                        setFieldValue("group_id", val[0]?.id);
                        setFieldValue("group_name", val[0]?.label);
                      }}
                      name="group"
                      // id="group"
                      options={supportGroupOptions || []}
                      selectedOptions={
                        values?.group_name
                          ? [{ id: values.group_id, label: values.group_name }]
                          : []
                      }
                      inputValue={values?.group_name || ""}
                      onCrossClick={() => {
                        setFieldValue("group_id", "0");
                        setFieldValue("group_name", "");
                      }}
                      errorColor={
                        errors?.group_name && touched?.group_name ? "error" : ""
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>Financials</Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col lg={4} md={4} sm={6} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">Owned By</Form.Label>
                  <div className="dvTypehd">
                    <TypeaheadSelect
                      onSelection={(val) => {
                        setFieldValue("owned_by_id", val[0]?.id);
                        setFieldValue("owned_by", val[0]?.label);
                      }}
                      selectedOptions={
                        values?.owned_by_id && values?.owned_by_id !== "0"
                          ? [{ id: values.owned_by_id, label: values.owned_by }]
                          : []
                      }
                      id="ownerBy-field"
                      name="ownerBy"
                      className="form-control"
                      options={companyTypeaheadOptions || []}
                      onCrossClick={() => {
                        setFieldValue("owned_by_id", "0");
                        setFieldValue("owned_by", "");
                        setFieldValue("vendor_seller_id", "0");
                        setFieldValue("vendor_seller", "");
                        setFieldValue("vendor_id", "0");
                        setFieldValue("vendor", "");
                        setFieldValue("charge_to_cc_id", null);
                        setFieldValue("charge_to_cc_name",null);
                      }}
                      tr={translator}
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <SelectField
                  name="ownership_type_id"
                  label="Ownership Type"
                  onChange={(e) => {
                    setFieldValue("ownership_type_id", e.target.value);
                  }}
                >
                  {ownershipTypeOptions?.map((item) => {
                    return <option value={item?.id}>{item?.label}</option>;
                  })}
                </SelectField>
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">Ordered</Form.Label>
                  <DateTimeField
                    name="order_date"
                    format={dateformat}
                    onChange={(value) => {
                      setFieldValue("order_date", value);
                    }}
                    value={values?.order_date}
                  />
                </Form.Group>
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">Ordered Received</Form.Label>
                  <DateTimeField
                    name="order_received_date"
                    format={dateformat}
                    onChange={(value) => {
                      setFieldValue("order_received_date", value);
                    }}
                    value={values?.order_received_date}
                  />
                </Form.Group>
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <InputTextField
                  name="acquisition_cost"
                  label="Acquisition Cost"
                />
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">Cost Center</Form.Label>
                  <div className="dvTypehd">
                    <TypeaheadSelect
                      onSelection={(val) => {
                        setFieldValue("cost_center_id",val[0]?.id);
                        setFieldValue("cost_center", val[0]?.label);
                      }}
                      id="costCenter-field"
                      name="costCenter"
                      className="form-control"
                      options={costCenterOptions || []}
                      onInputChange={(e) => {}}
                      onCrossClick={() => {
                        setFieldValue("cost_center_id", "0");
                        setFieldValue("cost_center", "");
                      }}
                      selectedOptions={
                        values?.cost_center
                          ? [
                              {
                                id: values.cost_center_id,
                                label: values.cost_center,
                              },
                            ]
                          : []
                      }
                      tr={translator}
                    />
                  </div>
                </Form.Group>
              </Col>

              <Col lg={4} md={4} sm={6} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">PO Date</Form.Label>
                  <DateTimeField
                    name="purchase_order_date"
                    format={dateformat}
                    onChange={(value) => {
                      setFieldValue("purchase_order_date", value);
                    }}
                    value={values?.purchase_order_date}
                  />
                </Form.Group>
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <InputTextField
                  errorMessage
                  name="purchase_order_number"
                  label="PO Number"
                />
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <InputTextField name="invoice_number" label="Invoice Number" />
              </Col>
              {/* <Col lg={4} md={4} sm={6} xs={12}>
                <InputTextField name="cost_currency_id" label="Cost Currency" />
              </Col> */}
              <Col lg={4} md={4} sm={6} xs={12}>
                <InputTextField
                  name="general_ledger_account"
                  label="GL Account"
                />
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <InputTextField name="net_book_value" label="Net Book Value" />
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <InputTextField
                  name="depreciation_charged_name"
                  label="Depreciation Charged"
                />
              </Col>

              <Col lg={4} md={4} sm={6} xs={12}>
                <SelectField
                  name="depreciation_schedule"
                  label="Depreciation Scheduled"
                >
                  {depreciationScheduledOptions?.map((item) => {
                    return <option value={item?.id}>{item?.label}</option>;
                  })}
                </SelectField>
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <Form.Group className="form-group">
                <Form.Label bsClass="">Vendor (Seller)</Form.Label>
                  <div className="dvTypehd">
                    <TypeaheadSelect
                      onSelection={(val) => {
                        setFieldValue("vendor_seller_id", val[0]?.id);
                        setFieldValue("vendor_seller", val[0]?.label);
                      }}
                      id="vendor-field"
                      name="vendor"
                      className="form-control"
                      options={vendorSellerOptions || []}
                      selectedOptions={
                        values?.vendor_seller && values.vendor_seller_id !== "0"
                          ? [{ id: values.vendor_seller_id, label: values.vendor_seller }]
                          : []
                      }
                      onCrossClick={() => {
                        setFieldValue("vendor_seller_id", "0");
                        setFieldValue("vendor_seller", "");
                        // setFieldValue("cost_center", "");
                      }}
                      tr={translator}
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <SelectField name="currency_id" label="Currency">
                  {currencyOptions?.map((item) => {
                    return <option value={item["id"]}>{item["label"]}</option>;
                  })}
                </SelectField>
              </Col>
            </Row>
            <div className="bgGray padding-5 margin-b-5 margin-t-10">
              Chargeback
            </div>
            <Row>
              <Col lg={4} md={4} sm={6} xs={12}>
                <SelectField name="charge_category_id" label="Charge Category">
                  {chargeCategoryOptions?.map((item) => {
                    return <option value={item["id"]}>{item["label"]}</option>;
                  })}
                </SelectField>
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
              <DatePickerCMDB                 
                label="Charge Start Date"
                name="charge_start_date"
                className="form-control dateTimePickerField"
                locale="en"
                defaultValue={moment()}
                timeConstraints={timeConstraints}
                isValidDate={isValidStartTime}
                format={dateformat}
                value={
                  values?.charge_start_date
                    ? values?.charge_start_date
                    : ""
                }
                onChange={(value) => {
                  setFieldValue("charge_start_date", value, true);

                  if (values.charge_end_date && moment(value).isAfter(moment(values.charge_end_date))) {
                    setFieldValue("charge_end_date", "", true);
                  }
                }}
                />
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
              <DatePickerCMDB                  
                label="Charge End Date"
                name="charge_end_date"
                className="form-control dateTimePickerField"
                locale="en"
                format={dateformat}
                defaultValue={moment()}
                timeConstraints={endDateTimeConstraints(values.charge_start_date)}
                isValidDate={(currentDate) =>
                  isValidEndTime(currentDate, values.charge_start_date)
                }
                value={
                  values?.charge_end_date
                    ? values.charge_end_date
                    : ""
                }
                onChange={(value) => {
                  const startDate = moment(values.charge_start_date);
                  const endDate = moment(value);
              
                  if (endDate.isSameOrAfter(startDate)) {
                    setFieldValue("charge_end_date", value, true);
                  } else {
                    setFieldValue("charge_end_date", "", true);
                  }
                }}
                />
              </Col>

              <Col lg={4} md={4} sm={6} xs={12}>
                <SelectField name="charge_type_name" label="Charge Type">
                  {chargeTypeOptions?.map((item) => {
                    return <option value={item["id"]}>{item["label"]}</option>;
                  })}
                </SelectField>
              </Col>

              <Col lg={4} md={4} sm={6} xs={12}>
                <SelectField name="charge_frequency" label="Charge Frequency">
                  {chargeFrequencyOptions?.map((item) => {
                    return <option value={item["id"]}>{item["label"]}</option>;
                  })}
                </SelectField>
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                {/* charge_currency: "",
              charge_currency_id: "0", */}
                <InputTextField
                  name="charge_currency"
                  label="Charge Currency Amount"
                />
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">Charge to CC</Form.Label>
                  <div className="dvTypehd">
                    <TypeaheadSelect
                      onSelection={(val) => {
                        setFieldValue("charge_to_cc_id", val[0]?.id);
                        setFieldValue("charge_to_cc_name", val[0]?.label);
                      }}
                      id="chargeCC-field"
                      name="charge_to_cc_id"
                      className="form-control"
                      options={chargeToCcOptions || []}
                      selectedOptions={
                        values?.charge_to_cc_name
                          ? [
                              {
                                id: values.charge_to_cc_id,
                                label: values.charge_to_cc_name,
                              },
                            ]
                          : []
                      }
                      onCrossClick={() => {
                        setFieldValue("charge_to_cc_id", null);
                        setFieldValue("charge_to_cc_name", null);
                      }}
                      tr={translator}
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">Charge to Company</Form.Label>
                  <div className="dvTypehd">
                    <TypeaheadSelect
                      onSelection={(val) => {
                        setFieldValue("charge_to_company_id", val[0]?.id);
                        setFieldValue("charge_to_company_name", val[0]?.label);
                      }}
                      id="chargeToCompany-field"
                      name="chargeToCompany"
                      className="form-control"
                      options={companyTypeaheadOptions}
                      selectedOptions={
                        values?.charge_to_company_name
                          ? [
                              {
                                id: values.charge_to_company_id,
                                label: values.charge_to_company_name,
                              },
                            ]
                          : []
                      }
                      onInputChange={(e) => {
                        console.log(e);
                      }}
                      onCrossClick={() => {
                        setFieldValue("charge_to_company_id", "0");
                        setFieldValue("charge_to_company_name", "");
                      }}
                      tr={translator}
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
              <InputTextField name="charge_to_project_name" label="Charge to Project" />
                {/* <Form.Group className="form-group">
                  <Form.Label bsClass="">Charge to Project</Form.Label>
                  <div className="dvTypehd">
                    <TypeaheadSelect
                      onSelection={(val) => {
                        setFieldValue("charge_to_project_id", val[0]?.id);
                        setFieldValue("charge_to_project_name", val[0]?.label);
                      }}
                      id="chargeToProject-field"
                      name="charge_to_project_id"
                      className="form-control"
                      options={[]}
                      selectedOptions={
                        values?.charge_to_project_name
                          ? [
                              {
                                id: values.charge_to_project_id,
                                label: values.charge_to_project_name,
                              },
                            ]
                          : []
                      }
                      onInputChange={(e) => {
                        console.log(e);
                      }}
                      onCrossClick={() => {
                        setFieldValue("charge_to_project_id", "0");
                        setFieldValue("charge_to_project_name", "");
                      }}
                      tr={translator}
                    />
                  </div>
                </Form.Group> */}
              </Col>
            </Row>
            <div className="bgGray padding-5 margin-b-5 margin-t-10">Lease</div>
            <Row>
              <Col lg={4} md={4} sm={6} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">Actual Return Date</Form.Label>
                  <DateTimeField
                    name="actual_retun_date"
                    format={dateformat}
                    onChange={(value) => {
                      setFieldValue("actual_retun_date", value);
                    }}
                    value={values?.actual_retun_date}
                  />
                </Form.Group>
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">Scheduled Return Date</Form.Label>
                  <DateTimeField
                    format={dateformat}
                    onChange={(value) => {
                      setFieldValue("scheduled_return_date", value);
                    }}
                    value={values?.scheduled_return_date}
                  />
                </Form.Group>
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">Vendor (Lessor)</Form.Label>
                  <div className="dvTypehd">
                    <TypeaheadSelect
                      onSelection={(val) => {
                        setFieldValue("vendor_id", val[0]?.id);
                        setFieldValue("vendor", val[0]?.label);
                      }}
                      id="vendorLessor-field"
                      name="vendor_seller_id"
                      className="form-control"
                      options={vendorLessorOptions || []}
                      selectedOptions={
                        values?.vendor && values.vendor_id !== "0"
                          ? [
                              {
                                id: values.vendor_id,
                                label: values.vendor,
                              },
                            ]
                          : []
                      }
                      onInputChange={(e) => {
                        console.log(e);
                      }}
                      onCrossClick={() => {
                        setFieldValue("vendor_id", "0");
                        setFieldValue("vendor", "");
                      }}
                      tr={translator}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Properties</Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col lg={4} md={4} sm={6} xs={12}>
                <InputTextField name="host_name" label="Host Name" />
              </Col>

              <Col lg={4} md={4} sm={6} xs={12}>
                <InputTextField name="ip_address" label="IP Address" />
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <InputTextField name="mac_address" label="MAC Address" />
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <InputTextField
                  name="backup_retention"
                  label="Backup Retention"
                />
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
               <InputTextField
                  name="backup_schedule"
                  label="Backup Scheduled"
                />

                {/* <Form.Group className="form-group">
                  <Form.Label bsClass="">Backup Scheduled</Form.Label>
                  <DateTimeField
                    format={dateformat}
                    onChange={(value) => {
                      setFieldValue("backup_schedule", value);
                    }}
                    value={values?.backup_schedule}
                  />
                </Form.Group> */}
              </Col>

              <Col lg={4} md={4} sm={6} xs={12}>
               <SelectField                 
                  name="monitored_id"
                  label="Monitor"
                  onChange={(e) => {
                    const selectedOption = e.target["selectedIndex"];
                    const nameText = e.target.options[selectedOption].dataset?.name;
                    setFieldValue("monitored_id", e.target.value)
                    setFieldValue("monitored", nameText)
                  }
                }
                >
                 {monitoredOptions?.map((item) => {
                    return <option value={item?.value} data-name={item?.label}>{item?.label}</option>;
                  })}
                </SelectField>
                {/* <InputTextField maxLength={50}  name="monitored" label="Monitor" /> */}
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
              <InputTextField name="domain_name" label="Fully Qualified Domain Name" />
              </Col>
            </Row>
            <div className="bgGray padding-5 margin-b-5 margin-t-10">
              Capacity
            </div>
            <Row>
              <Col lg={4} md={4} sm={6} xs={12}>
                <InputTextField
                  name="allocated_capacity"
                  label="Allocated Capacity"
                />
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <InputTextField
                  errorMessage
                  name="maximum_capacity"
                  label="Maximum Capacity"
                />
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <InputTextField name="capacity_unit" label="Capacity Unit" />
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <InputTextField
                  name="capacity_utilization"
                  label="Capacity Utilization"
                />
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <InputTextField
                  name="capacity_thresholds"
                  label="Capacity Thresholds"
                />
              </Col>
            </Row>
            <div className="bgGray padding-5 margin-b-5 margin-t-10">
              BCP/DR
            </div>
            <Row>
              <Col lg={4} md={4} sm={6} xs={12}>
                <SelectField
                  showSelect={false}
                  name="disaster_recovery_enabled"
                  label="DR Enabled"
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                </SelectField>
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">DR Location</Form.Label>
                  <div className="dvTypehd">
                    <TypeaheadSelect
                      onSelection={(val) => {
                        setFieldValue(
                          "disaster_recovery_location_id",
                          val[0]?.id
                        );
                        setFieldValue(
                          "disaster_recovery_location_name",
                          val[0]?.label
                        );
                      }}
                      id="drLocation-field"
                      name="disaster_recovery_location_id"
                      className="form-control"
                      options={drLocationOptions || []}
                      selectedOptions={
                        values?.disaster_recovery_location_name
                          ? [
                              {
                                id: values.disaster_recovery_location_id,
                                label: values.disaster_recovery_location_name,
                              },
                            ]
                          : []
                      }
                      onInputChange={(e) => {}}
                      onCrossClick={() => {
                        setFieldValue("disaster_recovery_location_id", "0");
                        setFieldValue("disaster_recovery_location_name", "");
                      }}
                      tr={translator}
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <SelectField name="disaster_tier_id" label="DR Tier">
                  {drTierOptions?.map((item) => {
                    return <option value={item["id"]}>{item["label"]}</option>;
                  })}
                </SelectField>
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <InputTextField
                  maxLength={100}
                  name="recovery_point_objective"
                  label="Recovery Point Objective"
                />
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <InputTextField
                  maxLength={100}
                  name="recovery_time_objective"
                  label="Recovery Time Objective"
                />
              </Col>
            </Row>
            <div className="bgGray padding-5 margin-b-5 margin-t-10">
              Discovery
            </div>
            <Row>
              <Col lg={4} md={4} sm={6} xs={12}>
              <SelectField name="discovery_source_id" label="Discovery Source"
                onChange={(e)=>{
                  const selectedOption = e.target["selectedIndex"];
                  const nameText = e.target.options[selectedOption].dataset?.name;
                  setFieldValue("discovery_source_name", nameText);
                  setFieldValue("discovery_source_id", e.target.value);
                }}
                  >
                {discoverySourceOptions?.map((item) => {
                  return <option value={item["value"]} data-name={item["label"]}>{item["label"]}</option>;
                })}
                </SelectField>
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">First Discovered</Form.Label>
                  <DateTimeField
                    format={dateformat}
                    onChange={(value) => {
                      setFieldValue("first_discovered_at", value);
                    }}
                    value={values?.first_discovered_at}
                  />
                </Form.Group>
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">Most Recent Discovery</Form.Label>
                  <DateTimeField
                    format={dateformat}
                    onChange={(value) => {
                      setFieldValue("most_recent_discovery_at", value);
                    }}
                    value={values?.most_recent_discovery_at}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="bgGray padding-5 margin-b-5 margin-t-10">
              Patching
            </div>
            <Row>
              <Col lg={4} md={4} sm={6} xs={12}>
                <SelectField name="patch_hold" label="Patch Hold">
                  <option value="true">True</option>
                  <option value="false">False</option>
                </SelectField>
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <InputTextField
                  maxLength={50}
                  name="patch_hold_reason"
                  label="Patch Hold Reason"
                />
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <InputTextField
                  name="patch_schedule"
                  label="Patching Schedule"
                />
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
                <InputTextField
                  name="patch_level"
                  label="Release / Patch Level"
                />
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default AddEditCmdb;
