
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {
  loadWorkdayScheduleData,
  loadWorkRelatedSlaData,
} from "_Actions/sla/lovAction";
// import Header from "_Commons/header/Header";
import WorkScheduleTime from "./workScheduleRightSide";
import RightSidebarMenulist from "../../../../components/common/RightSidebarMenuListDynamic";
import XsmSupportHeader from "./header";
import XsmSLAWorkdayEditForm from "./form";
import { connect, useDispatch, useSelector } from "react-redux";
import XsmProcessHierarchy from "../create/processHierarchy";
import AuditLog from "./AuditLog";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../common/Breadcrumbs";
import ViewWorkRelatedSls from "./ViewWorkRelatedSls";
import LoadingIcons from "react-loading-icons";
import { Panel, PanelGroup } from "react-resizable-panels";
import "_Css/form/_form.scss";

const SlaWorkdayScheduleEditIndex = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [subMenu, setSubMenu] = useState("4");
  const [showHideAlfy, setShowHideAlfy] = useState(false);
  const [showHideRightNav, setShowHideRightNav] = useState(false);
  const [actionView, setActionView] = useState("viewWorkScheduleTime");
  const [mobileLeftMenuFilter, setMobileLeftMenuFilter] = useState(false);

  const slaWorkScheduleDetails = useSelector(
    (state) => state.slaWorkScheduleDetails
  );
  const newSlaWorkScheduleAssociation = useSelector(
    (state) => state.newSlaWorkScheduleAssociation
  );
  const translator = useSelector((state) => state.spcmReducer.tr);
  const loadingIcon = <div className="rctLoade">
  <LoadingIcons.Bars height="5em" stroke="transparent" fill="#06bcee" />
</div>;
  useEffect(() => {
    dispatch(loadWorkdayScheduleData(params.scheduleId));
    dispatch(loadWorkRelatedSlaData(params.scheduleId));
  }, [actionView]);

  function mobileLeftMenuFilterSet() {
    //this.setState({ mobileLeftMenuFilter: !mobileLeftMenuFilter });
    setMobileLeftMenuFilter(!mobileLeftMenuFilter);
  }

  function showHideAlfySet(value) {
    setShowHideAlfy(value);
  }
  function showHideRightNavSet(value) {
    setShowHideRightNav(!showHideRightNav);
  }
  function changeActionView(actionSelected) {
    setActionView(actionSelected);
  }

  function showRightPaneAction(actionView) {
    switch (actionView) {
      case "viewWorkScheduleTime":
        return <WorkScheduleTime scheduleId={params.scheduleId} translator={translator} newSlaWorkScheduleAssociation={newSlaWorkScheduleAssociation} />
      case "activityDetails":
        return <AuditLog ciId={params.scheduleId} translator={translator}/>;
      case "servicediagram":
        return <XsmProcessHierarchy translator={translator} />;
      case "viewRelatedSls":
          return <ViewWorkRelatedSls translator={translator} />;
      default:
        return <XsmProcessHierarchy translator={translator} />;
    }
  }

  return (
    <main>
      <div >
        {/* App Header Section Start*/}
        {/* <Header rightNavValue={showHideRightNavSet} ciId={params.ciId} /> */}
        {/* App Header Section End*/}
        {/* <div
          onClick={mobileLeftMenuFilterSet}
          id="mobileLeftMenuFilter"
          className={
            mobileLeftMenuFilter
              ? "mobileLeftMenuFilterMove d-md-none d-lg-none"
              : "d-none d-lg-none"
          }
        >
          <i
            className={
              mobileLeftMenuFilter
                ? "fa fa-angle-double-left"
                : "fa fa-angle-double-right"
            }
          ></i>
        </div> */}
        {/* App Right Side MenuBar Section Start*/}
        {/* <RightSidebarMenulist
          showhideClass={showHideRightNav}
          subMenuNumber={subMenu}
        /> */}
        {/* App Right Side MenuBar Section End*/}

        {/* Support App Left Side MenuBar Section Start*/}
        
        {/* Support App Left Side MenuBar Section End*/}

        <div className="container-fluid padding-t-10">
          <Breadcrumbs translator={translator} />
          <Form>
            {/* Home Page blue bar Section Start*/}
            <XsmSupportHeader
              translator={translator}
              ciId={params.ciId}
              changeActionView={changeActionView}
              slaWorkScheduleDetails={slaWorkScheduleDetails}
              actionView={actionView}
            />
            {/* Home Page blue bar Section end*/}

            <Row className="row-eq-height">
              <Col md={8} sm={12} xs={12}>
                {/* Xsm Break Fix Form tabs Section Start*/}
                {/*<XsmSLAWorkdayEditForm ciId={this.props.params.ciId} />*/}
                {slaWorkScheduleDetails.workdayScheduleDetails?<XsmSLAWorkdayEditForm translator={translator} />:loadingIcon}
              </Col>
              <Col md={4} sm={12} xs={12} className="colRightHeight">
              <PanelGroup direction="horizontal">
              <Panel minSize={33} order={2} defaultSize={33}>
                    <div className="stickyArea rBoxStyle">
                    {showRightPaneAction(actionView)}
                    </div>
                  </Panel>
                  </PanelGroup>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </main>
  );
};

export default SlaWorkdayScheduleEditIndex;
