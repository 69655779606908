
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect } from 'react';
import { Table, ProgressBar, ListGroup } from 'react-bootstrap';
import ListLoader from "_Commons/loaders/ListLoader";
import { useDispatch, useSelector } from 'react-redux';
import ReactStars from 'react-stars';
import { loadTopReviewList } from "_Actions/reviews/reviewAction.js";
import SimpleBar from 'simplebar-react';

const ServiceReview = (props) => {
	const dispatch = useDispatch();
	const serviceReviewShowLoader = useSelector(state => state.serviceReviewShowLoader.loading);
	const topReviewsList = useSelector(state => state.topReviewsList);
	const topReviewLoader = useSelector(state => state.topReviewLoader.loading);
	const appliedThemeData = useSelector((state) => state.appliedThemeData.data);


	useEffect(() => {
		dispatch(loadTopReviewList(props.offeringId))
	}, [])

	const renderOfferingReview = (offeringReview, topReviews) => {
		return (
			<>
				{offeringReview ? <div id="viewMDetails">
					<div className="bgNonTab">
						<Table responsive>
							<thead>
								<tr>
									<th width="25%">{props.translatoroffering['Ratings']}</th>
									<th width="60%"> {props.rating == null ? 0 : props.rating + ' '} {props.translatoroffering['out of 5']} </th>
									<th width="15%">{props.translatoroffering['Total:']} {offeringReview.totalRatings}</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className='serviceReviewRatingColor'>{'★★★★★'}</td>
									<td><ProgressBar bsStyle="warning" variant='warning' now={offeringReview.avgRatingPercentage5} /></td>
									<td className="text-c">{offeringReview.totalRating5}</td>
								</tr>
								<tr>
									<td className='serviceReviewRatingColor'>{'★★★★'}</td>
									<td><ProgressBar bsStyle="warning" variant='warning' now={offeringReview.avgRatingPercentage4} /></td>
									<td className="text-c">{offeringReview.totalRating4}</td>
								</tr>
								<tr>
									<td className='serviceReviewRatingColor'>{'★★★'}</td>
									<td><ProgressBar bsStyle="warning" variant='warning' now={offeringReview.avgRatingPercentage3} /></td>
									<td className="text-c">{offeringReview.totalRating3}</td>
								</tr>
								<tr>
									<td className='serviceReviewRatingColor'>{'★★'}</td>
									<td><ProgressBar bsStyle="warning" variant='warning' now={offeringReview.avgRatingPercentage2} /></td>
									<td className="text-c">{offeringReview.totalRating2}</td>
								</tr>
								<tr>
									<td className='serviceReviewRatingColor'>{'★'}</td>
									<td><ProgressBar bsStyle="warning" variant='warning' now={offeringReview.avgRatingPercentage1} /></td>
									<td className="text-c">{offeringReview.totalRating1}</td>
								</tr>
							</tbody>
						</Table>
					</div>

					<div className="ratRevDec">
						<div className="hd">{props.translatoroffering["Most Recent Reviews"]}</div>
						<SimpleBar style={{ height: 160 }} autoHide={false}>
							<ListGroup as={'ul'} bsPrefix=' ' className="revewLis" bsClass="" bsStyle="">
								{topReviews && topReviews != "No data present for the mentioned offering ID" ?
									topReviews.map(
										(reviews) => {
											return (
												reviews.REMARKS != "" ?
													<ListGroup.Item as={'li'} bsPrefix=' ' bsClass="" bsStyle="">
														{reviews.REMARKS}
														<div><span className="stDtdv">{reviews.CREATEDDATE}</span><ReactStars className="display-inline-block noHandCur" count={reviews.RATING} color1={appliedThemeData?.secondaryColor} color2={appliedThemeData?.secondaryColor} /></div>
													</ListGroup.Item> :
													''
											);
										}
									) :
									<ListGroup.Item bsPrefix=' ' bsClass="" bsStyle="">
										{props.translatoroffering["NO REVIEWS FOUND"]}
									</ListGroup.Item>}
							</ListGroup>
						</SimpleBar>
					</div>
				</div> :
				<ListGroup.Item bsPrefix=' ' bsClass="" bsStyle="">
				{props.translatoroffering["NO REVIEWS FOUND"]}
				</ListGroup.Item>
				}
			</>
		);
	}

	if (topReviewLoader || serviceReviewShowLoader) {
		return (
			<div>
				<ListLoader />
			</div>);
	}
	else {
		return (renderOfferingReview(props.offeringReview, topReviewsList.responseData));
	}
}

export default ServiceReview
