
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Field } from "redux-form";
import { useGetAssociatedRuleMutation } from "../../../reducers/rules";
import { useGetAISMGroupsMutation } from "../../../reducers/aism/aismAPI";
import { GLOBAL } from "../../Globals";

export const RuleAmsDdropDown = ({
  name,
  formData,
  rulesAttribute,
  moduleName,
  ruleType,
  groupData,
  ruleFor,
  serviceCompanyId,
  showAismGroups,
  showAms,
  ...props
}) => {
  const [ruleDataOptions, setRuleDataOptions] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const {
    status,
    priorityId,
    serviceName,
    impactedCi,
    impactedUsername,
    ciClassName,
    userLocation,
    ciLocation,
    serviceId,
    impactedCiId,
    requestorId,
    classId,
    userLocationId,
    ciLocationId,
    serviceCompId,
  } = rulesAttribute || {};

  const [getRulesData, { isLoading: rulesLoading }] =
    useGetAssociatedRuleMutation();

  const [getAismGroups, { isLoading: groupsLoader }] =
    useGetAISMGroupsMutation();

  useEffect(() => {
    if (Object.keys(rulesAttribute).length > 0 && !showAismGroups) {
      let data = {};
      if (moduleName === "incident") {
        data = {
          status,
          priorityId:
            formData?.ItemList?.priorityActualVal?.toString() ||
            priorityId?.toString(),
          serviceName,
          ciName: impactedCi,
          impactedUsername,
          ciClassName,
          requesterLocationName: userLocation,
          ciLocationName: ciLocation,
          ciId: impactedCiId?.toString(),
          serviceId: serviceId?.toString(),
          impactedUserid: requestorId?.toString(),
          ciLocationId: ciLocationId?.toString(),
          ciClassId: classId?.toString(),
          requesterLocationId: userLocationId?.toString(),
        };
      } else if (moduleName === "changemanagement") {
        data = {
          status,
          priorityId:
            formData?.ItemList?.priorityActualVal?.toString() ||
            priorityId?.toString(),
          serviceName,
          ciName: impactedCi,
          requesterName: impactedUsername,
          ciClassName,
          requesterLocationName: userLocation,
          ciLocationName: ciLocation,
          ciLocationId: ciLocationId?.toString(),
          requesterId: requestorId?.toString(),
          requesterLocationId: userLocationId?.toString(),
          ciClassId: classId?.toString(),
          ciId: impactedCiId?.toString(),
          serviceId: serviceId?.toString(),
        };
      } else if (moduleName === "problem") {
        data = {
          status: status?.toString(),
          priority:
            formData?.ItemList?.priorityActualVal?.toString() ||
            priorityId?.toString(),
          serviceName,
          ciName: impactedCi,
          impactedUsername,
          ciClassName,
          requesterLocationName: userLocation,
          ciLocationName: ciLocation,
          ciClassId: classId?.toString(),
          ciLocationId: ciLocationId?.toString(),
          ciId: impactedCiId?.toString(),
          requesterLocationId: userLocationId?.toString(),
          impactedUserid: requestorId?.toString(),
        };
      } else if (moduleName === "task") {
        data = {
          statusid: status?.toString(),
          priority:
            formData?.ItemList?.priorityActualVal?.toString() ||
            priorityId?.toString(),
          serviceName,
          ciName: impactedCi,
          requesterName: impactedUsername,
          ciClassName,
          requesterLocationName: userLocation,
          requesterLocationId: userLocation,
          ciLocation,
          serviceCompanyId: serviceCompId?.toString(),
          serviceid: serviceId?.toString(),
          ciId: impactedCiId?.toString(),
          requesterid: requestorId?.toString(),
        };
      }
      if (formData?.ItemList?.company || formData?.ItemList?.clientId) {
        setLoading(true);
        getRulesData({
          data: data,
          moduleName,
          ruleType,
          companyId:
            formData?.ItemList?.company || formData?.ItemList?.clientId,
          status: "active",
          weight: "N",
          ruleFor,
          serviceCompanyId: serviceCompanyId?.toString(),
        })
          .then((response) => {
            let options =
              response.data?.body?.map((item) => ({
                id: item.responseEntityId,
                label: item.responseEntity,
                isExternal: item?.external,
                supportCompanyID: item?.assignmentCompanyId,
                supportCompanyName: item?.assignmentCompany,
              })) ?? [];

            options = [
              ...new Map(
                options.map((item) => [JSON.stringify(item), item])
              ).values(),
            ];
            setRuleDataOptions(options || []);
          })
          .catch((error) => {
            console.error("API Error:", error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [
    formData?.ItemList?.priorityActualVal,
    formData?.ItemList?.company || formData?.ItemList?.clientId,
    status,
    serviceName,
    impactedCi,
    impactedUsername,
    ciClassName,
    userLocation,
    ciLocation,
    getRulesData,
    moduleName,
    ruleType,
    priorityId,
  ]);

  useEffect(() => {
    if (showAismGroups && formData?.ItemList) {
      setRuleDataOptions([]);
      setLoading(true);
      getAismGroups({
        description: formData?.ItemList?.issueDescription,
        companyId: parseInt(formData?.ItemList?.company, 10),
        companyName: formData?.ItemList?.companyName,
        serviceId: parseInt(formData?.ItemList?.impactedServiceId, 10) || GLOBAL.breakFixServiceID,
        ciId: formData?.ItemList?.ciId,
        fixDetails: "",
      }).then((resp) => {
        let options = resp.data?.data?.map((item) => ({
          id: item.groupId,
          label: item.groupName,
          supportCompanyID: item?.companyId,
          supportCompanyName: item?.companyName,
        }));
        options = [
          ...new Map(
            options.map((item) => [JSON.stringify(item), item])
          ).values(),
        ];
        setRuleDataOptions(options || []);
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false))
    }
  }, [showAismGroups]);

  return (showAms && !showAismGroups) ? (
    <Field
      name={name}
      {...props}
      options={showAms ? groupData : ruleDataOptions}
    />
  ) : (
    <Field name={name} isLoading={isLoading} {...props} options={ruleDataOptions} />
  );
};
