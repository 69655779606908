
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Row, Col, Form, ButtonToolbar, Button, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import axios from 'axios';
import { Field, reduxForm, SubmissionError, change } from 'redux-form';
import { _textArea, _dropDown, _inputField, _number } from '../../../../common/formFields';
import { spcmLoader, translate, getConsumptionItems, getTranslationForOfferingBasedOnType, translateAttrName, translateComponenDesc, resetReducer } from '../../../../../actions/spcmActions';
import {ImSpinner6} from "react-icons/im";

const validate = values => {
	console.log("values");
	console.log(values);
	let errors = {};

	if (values.translated_component_name) {
		if (values.translated_component_name.trim() == "") {
			errors.translated_component_name = "Enter Translated Text";
		}
	}
	else if (!values.translated_component_name) {
		errors.translated_component_name = "Enter Translated Text";
	}


	if (values.translated_component_description) {
		if (values.translated_component_description.trim() == "") {
			errors.translated_component_description = "Enter Translated Component Desc";
		}
	}
	else if (!values.translated_component_description) {
		errors.translated_component_description = "Enter Translated Component Desc";
	}

	console.log("errors");
	return errors;
}


class TranslationForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			attributeValue: "",
			attribute_trans_value: ""
		};
		this.submitForm = this.submitForm.bind(this);
	};


	componentWillReceiveProps(newProps) {
		if (this.props.spcmReducer.transAttrName !== newProps.spcmReducer.transAttrName) {
			this.props.dispatch(change('ComponentForm', 'translated_component_name', newProps.spcmReducer.transAttrName));
		}

		if (this.props.spcmReducer.translateCompDesc !== newProps.spcmReducer.translateCompDesc) {
			this.props.dispatch(change('ComponentForm', 'translated_component_description', newProps.spcmReducer.translateCompDesc));
		}


	}

	componentWillUnmount() {
		this.props.resetReducer();
	}

	submitForm(values) {
		console.log("valuess");
		console.log(values);
		let data = [
			{
				offering_id: this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
				language_id: this.props.lanIdVal2,
				attribute_id: this.props.spcmReducer.objectForTranslation.component_id,
				attribute_type: "component_name",
				attribute_value: this.props.spcmReducer.objectForTranslation.component_name,
				attribute_trans_value: values.translated_component_name
			},
			{
				offering_id: this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
				language_id: this.props.lanIdVal2,
				attribute_id: this.props.spcmReducer.objectForTranslation.component_id,
				attribute_type: "component_description",
				attribute_value: this.props.spcmReducer.objectForTranslation.description,
				attribute_trans_value: values.translated_component_description
			}
		];


		this.props.spcmLoader('attribute', 'posting');
		return axios.post("/api/languageTranslation/postRegardingOfferingTypeTranslation", data).then((response) => {
			this.props.spcmLoader('attribute', 'success');
			this.props.getTranslationForOfferingBasedOnType(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID, this.props.lanIdVal2, "component");
			this.props.getConsumptionItems(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
			this.props.insideTableShowFun6(false, '')
		})
			.catch((error) => {

				this.props.spcmLoader('attribute', 'success');
				throw new SubmissionError({ _error: error.response.data.message });
			});
	}


	render() {
		console.log("objectForTranslation");
		console.log(this.props.spcmReducer.objectForTranslation);
		console.log(this.props.lanIdVal2);
		const { error, handleSubmit } = this.props;
		const tr = this.props.tr;

		return (
			<div>
				<div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">Translate - {this.props.fieldNameVal3} - {this.props.lanNameVal3}</div></div>

				<div className="rBoxGap">
					<form onSubmit={handleSubmit(this.submitForm)}>
						{error == undefined || error == null ? null : <Alert variant="danger" className="errorMessageSec"><p>{error}</p></Alert>}
						<Row>
							<Col md={12}>
								<Form.Group className="form-group" >
									<Form.Label bsClass="">Base Language</Form.Label>
									<Form.Control disabled value="English" type="text" />
								</Form.Group>
							</Col>
							<Col md={12}>
								<Form.Group className="form-group" >
									<Form.Label bsClass="">Component</Form.Label>
									<Form.Control disabled value={this.props.spcmReducer.objectForTranslation.component_name} componentClass="textarea" rows="2" />
								</Form.Group>
							</Col>

							<Col md={12}>
								<Form.Group className="form-group">
									<Form.Label bsClass=""><span className="rStar"></span> Translated Component{/* <Button onClick={() => this.props.translateAttrName(this.props.spcmReducer.objectForTranslation.component_name, this.props.languageCode)} className="margin-l-10 margin-b-5" title="Google Translate" bsSize="xsmall"><i className="fa fa-google"></i> Translate</Button>*/}</Form.Label>
									<Field component={_textArea} name="translated_component_name" className='form-control' rows="3" />
								</Form.Group>
							</Col>

							<Col md={12}>
								<Form.Group className="form-group" >
									<Form.Label bsClass="">Component Description</Form.Label>
									<Form.Control disabled value={this.props.spcmReducer.objectForTranslation.description} componentClass="textarea" rows="2" />
								</Form.Group>
							</Col>

							<Col md={12}>
								<Form.Group className="form-group">
									<Form.Label bsClass=""><span className="rStar"></span> Translated Component Description {/*<Button onClick={() => this.props.translateComponenDesc(this.props.spcmReducer.objectForTranslation.description, this.props.languageCode)} className="margin-l-10 margin-b-5" title="Google Translate" bsSize="xsmall"><i className="fa fa-google"></i> Translate</Button>*/}</Form.Label>
									<Field component={_textArea} name="translated_component_description" className='form-control' rows="3" />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col md={12}>
								<ButtonToolbar className="black">
									<Button className='rgSidrkBtn' title={tr['Save']} type="submit" bsSize="small" bsStyle="primary">{this.props.spcmReducer.spcmPostingAttribute ? <ImSpinner6 className="icn-spinner"/> : null} {tr['Save']}</Button>
									<Button className='rgSiLigBtn' onClick={() => { this.props.insideTableShowFun6(false, '') }} bsSize="small" bsStyle="text">Close</Button></ButtonToolbar>
							</Col>
						</Row>
					</form>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ spcmReducer }) => {
	return {
		spcmReducer,
		initialValues: spcmReducer.objectForTranslation
	}
};


TranslationForm = reduxForm({
	form: 'ComponentForm',
	validate,
	enableReinitialize: true,

})(TranslationForm)

export default connect(mapStateToProps, { spcmLoader, translate, getConsumptionItems, getTranslationForOfferingBasedOnType, translateAttrName, translateComponenDesc, resetReducer })(TranslationForm);