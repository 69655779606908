
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import { navigationHooks } from '../../helpers/NavigationHook';
import Swal from 'sweetalert2';
const api = axios.create({
    headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});



export const getCompanyOptionsList = () => {
    return (dispatch) => {
      api.get("/api/getInitiallistCompany")
          .then((response) => {
            let object = [];
            const menuListoptions = response.data.listCompany;
            if (typeof menuListoptions !== "undefined" && menuListoptions.length > 0) {
              menuListoptions.forEach((values) => {
                let val = { id: values.id, label: values.name };
                object.push(val);
              });
              }
            // console.log("CCResp", response.data)
            dispatch({ type: 'COMPANY_DROPDOWN_LIST', payload: object });
            dispatch({ type: 'CC_STATUS_DROPDOWN_LIST', payload: response.data.statusList });
        })
        .catch((err) => {
          console.log(err);
        });
    };
};


export const setHosts = () => {
  return (dispatch) => {
    api.get("/api/getHostList")
        .then((response) => {
          dispatch({ type: 'SET_HOSTS', hosts: response?.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};