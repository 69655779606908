
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import { Row, Col, Container } from 'react-bootstrap';

import PindCategories from "../../../common/header/pind-categories";
import Breadcrumbs from "../../../common/header/breadcrumbs";
import Header from "./header";
import List from "./list";
import ShortDesc from "./shortDesc";
import { getLocationList } from "../../../../actions/foundation/locationAction";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import "_Css/common/_dataTable.scss";

const Index = () => {
  const [isRightSideVisible, setRightSideVisible] = useState(false);
  const [locationData, setLocationData] = useState({});

  const translator = useSelector((state) => state.spcmReducer.tr);
  let dispatch = useDispatch();
  let colWidth = "";
  isRightSideVisible ? (colWidth = 8) : (colWidth = 12);

  const showRightSideFun = (val) => {
    setRightSideVisible(val);
  };

  const companyDataHandler = (locationData) => {
    setLocationData(locationData)
  }

  useEffect(() => {
    dispatch(getLocationList());
   },[])
  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Row>
          <Col md={8} xs={8}>          
            <Breadcrumbs activePageName={"Location"} />
          </Col>
          <Col md={4} xs={4}>
            <div className="tabDatacount">1-1 of 1</div>
          </Col>
        </Row>
      </Container>

      <Container fluid className="position-re">
        <Header />
        <PanelGroup direction="horizontal">
          <Panel id="sidebar" minSize={33} order={1} defaultSize={isRightSideVisible ? 67 : 100} className={isRightSideVisible ? "isShowLeftPanel catBordDv" : "catBordDv"}>
            <List
              isRightSideVisible={isRightSideVisible}
              showRightSideFun={showRightSideFun}
              companyDataHandler={companyDataHandler}
              translator={translator} />
          </Panel>
          {isRightSideVisible ?
            <>
              <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
              <Panel minSize={33} order={2} defaultSize={33}>
              <div className="stickyArea rBoxStyle">
                <ShortDesc
                  showRightSideFun={showRightSideFun}
                  locationData={locationData}
                />
              </div>
              </Panel>
            </> : ""
          }
        </PanelGroup>
      </Container>
    </main>
  )
}

export default Index;
