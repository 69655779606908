
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState} from "react";
import { Row, Col, Button } from 'react-bootstrap';
import {useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { reduxForm } from "redux-form";
import { saveCostCenterAction } from "../../../../../actions/foundation/costCenterAction";

import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';

const HeaderSec = (props) => {
    const navigate = useNavigate();
    const tr = useSelector((state) => state.spcmReducer.tr);
    const dispatch = useDispatch();
    const context = useContext(MyContext);
    const closeButton = useRef(null);
    const saveObjRef = useRef(null);

	useEffect(() => {
		context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
        context.newTabPrevent();
	}, []);

    const submit = async (values) => {
        console.log("HeaderCC", values);

        let isValid = true;
        if (!values.company_name|| values.company_name == "") {
            props.setCompanyErrorColor();
            isValid = false;
        }     
        if ( !values.costcenter_name || values.costcenter_name == ''|| values.costcenter_name.trim() == '') {
            props.setNameErr("error");
            isValid = false;
          } else {
            props.setNameErr("");
        }
        if ( !values.ccStatus || values.ccStatus == '') {
            props.setStatusErr("error");
            isValid = false;
          } else {
            props.setStatusErr("");
        }
        if (isValid == true) {
        let postjson;
        postjson = {
            costcenter_name: values.costcenter_name,
            status: values.ccStatus,
            company_id: values.company_id,
            company_name: values.company_name,
            pagesize: 0,
            filterby: null,
            filterbytext: null,
            eventNameDefault: null,
            businessFunctionId: 0
        };
        dispatch(saveCostCenterAction(postjson));
          
        }
    };
    let { handleSubmit, submitting } = props;
    return (
        <>
            <Row className="margin-b-15">
                <Col sm={8} xs={7}>
                    <h1 bsClass="" className="sPageHeading1">{tr["Cost Center"]}</h1>
                </Col>
                <Col sm={4} xs={5}>
                    <div className="paHedFilter">
                        <Link
                            bsPrefix=' ' 
                            id="hdID"
                            to="javascript:void(0)"
                            className="myBt plus ctrlKeyPrevent"
                            disabled={submitting}
                            onClick={handleSubmit(submit)}
                            title={tr['Save']}
                            ref={saveObjRef} >
                            <span>{props.loader ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/> }</span>
                        </Link>
                        <Link
                            bsPrefix=' '
                            to="/costCenter"
                            className="myBt cancel fillBtn ctrlKeyPrevent"
                            title={tr['Cancel']}
                            ref={closeButton} >
                            <IoClose/>
                        </Link>
                    </div>
                </Col>
            </Row>
        </>
    )
}


export default reduxForm({
    form: "CreateCostCenter",
  })(HeaderSec);
