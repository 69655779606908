
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector, connect } from "react-redux";
import { _dropDown } from "../../common/ReduxFormFields/CategoryBoard/formFields.js";
import { change, Field, reduxForm } from "redux-form";

const SchedulerListHeader = (props) => {
  const dispatch = useDispatch();
  const translator = useSelector((state) => state.spcmReducer.tr);

  return (
    <Row className="margin-b-15">
      <Col lg={8} md={6} sm={12} xs={12}>
        <h2 className="sPageHeading1">{translator["Scheduled Jobs"]}</h2>
      </Col>
      <Col lg={4} md={6} sm={12} xs={12}>
        <div className="paHedFilter"></div>
      </Col>
    </Row>
  );
};
const mapStateToProps = (state) => ({});

export default reduxForm({
  form: "SchedulerListHeader",
})(connect(mapStateToProps)(SchedulerListHeader));
