
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import { GLOBAL }  from '../../components/Globals';
export function loadCausalRelationshipDataSuccess(causalRelationshipData){
	//alert('in success')
	return {
		type: 'LOAD_CAUSALRELATIONSHIPDATA_SUCCESS',
		causalRelationshipData
	};
}

export function loadCausalRelationshipDataFailure(errorResponse){
	return {
		type: 'LOAD_CAUSALRELATIONSHIPDATA_FAILURE',
		errorResponse
	};
}
export function loadCausalRelationshipData(requestId){
	return(dispatch) =>{
				dispatch({type:'CAUSALRELATIONSHIP_DETAILS_IN_PROGRESS'});
				axios.get(GLOBAL.nestedRelationsDataUrl+"?requestId="+requestId)
					.then((causalRelationshipData)=>{
						if(!causalRelationshipData){//alert('not breakFix')
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							throw Error(causalRelationshipData.statusText);
						}
						return causalRelationshipData;
					})
					.then((causalRelationshipData) =>{//alert('yes it is')
						dispatch(loadCausalRelationshipDataSuccess(causalRelationshipData))
						dispatch({type:'CAUSALRELATIONSHIP_DETAILS_FETCHED_SUCCESSFULLY'});
					})
					.catch((err) => {
						console.log(err);
					});
	};
}
