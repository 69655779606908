
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { div, Row, Col, Form } from 'react-bootstrap';
import CiCategoryHeader from './CiCategoryHeader';
import CiCategoryList from './CiCategoryList';
import CiCategoryRightPart from './CiCategoryRightPart';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTranslation } from '../../../actions/spcmActions';
import { browserHistory } from 'react-router';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import Cookies from 'universal-cookie';
import "_Css/form/_form.scss";
let GLOBAL = require('_Globals');
const cookies = new Cookies();
let homepagelocation = cookies.get('gph');
if (homepagelocation) homepagelocation = homepagelocation.replace('s:', '');
if (homepagelocation) homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation !== "/home") {
  homepagelocation = "/" + homepagelocation;
}

class CiCategoryIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      categoryData: null,
      status: '',
      ciClassName:'',
      page:'',
      limit:'',
      isEditing: false,
      rightDisplay: false
    };
    GLOBAL.searchbarcategory = 'All';
    this.loadHome = this.loadHome.bind(this);
    this.setListMetaData = this.setListMetaData.bind(this);
    this.getCategoryBoardDetails = this.getCategoryBoardDetails.bind(this);
    this.setIsEditing = this.setIsEditing.bind(this);
    this.resetRightSide = this.resetRightSide.bind(this);
    this.showRightSide= this.showRightSide.bind(this);
    let categoryData;
  }
  loadHome() {
    browserHistory.push(homepagelocation);
  }
  showRightSide(val) {
    this.setState({rightDisplay: val});
  };
  componentDidMount() {
    const { ciListings } = this.props;
    if (ciListings && ciListings.length > 0) {
      const [defaultCategory] = ciListings;
      const { category_name, sub_category_name, cat_company_name, status, class_id, category_id, cat_company_id } = defaultCategory;
  
      this.getCategoryBoardDetails(category_name, sub_category_name, cat_company_name, status, class_id, category_id, cat_company_id);
    }
  }
  setListMetaData(status, ciClassName){
    console.log("CILISTING====>",ciClassName)
    this.setState({status: status, ciClassName: ciClassName})
  }
  setIsEditing(value){
   this.setState({isEditing: value})
  }
  resetRightSide(){
    this.setState({categoryData: null})
 }
  getCategoryBoardDetails(category_name, sub_category_name, cat_company_name, status, class_id, category_id, cat_company_id) {
      const categoryData = {
      category_name: category_name,
      sub_category_name: sub_category_name,
      cat_company_name: cat_company_name,
      status: status,
      class_id: class_id,
      category_id: category_id,
      cat_company_id: cat_company_id
    }
    this.setState({
      categoryData: categoryData,
    });
  }

  render() {
    const { categoryData } = this.state;
    const { ciListings } = this.props;

    return (
      <main>
        <div className="minHeight">
          {/* <div className="container-fluid margin-t-10 margin-b-15">
            <nav aria-label="Breadcrumb">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a role="button" href="javascript:void(0)" onClick={this.loadHome}>
                    {this.props.tr["Home"]}
                  </a>
                </li>
                <li className="breadcrumb-item active">{this.props.tr["CI Category"]}</li>
              </ul>
            </nav>
          </div> */}
          <div className="container-fluid" bsClass="">
              {/* <CategoryBoardHeader showLoader={this.state.showLoader} translator={this.props.tr} /> */}              
              <PanelGroup direction="horizontal">
                <Panel id="sidebar" minSize={33} order={1} defaultSize={this.state.rightDisplay ? 67 : 100} className={this.state.rightDisplay ? "isShowLeftPanel rwLeftPart" : "rwLeftPart"}>
                  <CiCategoryList
                    ciListings={ciListings}
                    getCategoryBoardDetails={this.getCategoryBoardDetails}
                    setListMetaData={this.setListMetaData} 
                    setIsEditing={this.setIsEditing}
                    resetRightSide={this.resetRightSide}
                    tr={this.props.tr}
                    showRightSide={this.showRightSide}
                    rightDisplay={this.state.rightDisplay}
                  />
                </Panel>
                {this.state.rightDisplay ?
                    <>
                      <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
                      <Panel minSize={33} order={2} defaultSize={33}>
                        <div className="stickyArea rBoxStyle">
                          <CiCategoryRightPart categoryData={categoryData} 
                          status={this.state.status} 
                          ciClassName={this.state.ciClassName} 
                          setIsEditing={this.setIsEditing} 
                          isEditing= {this.state.isEditing} 
                          resetRightSide={this.resetRightSide} 
                          showRightSide={this.showRightSide}
                          rightDisplay={this.state.rightDisplay}
                          tr={this.props.tr} />
                        </div>
                      </Panel>
                    </> : ("")
                }
              </PanelGroup>
          </div>
        </div>
      </main>
    );
  }
}

export function mapStateToProps({ spcmReducer }) {
  return { lang: spcmReducer.lang, tr: spcmReducer.tr };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getTranslation }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CiCategoryIndex);
