
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';

import { deleteSpecificVendorTicket, getspecificVendorTicket } from '_Actions/investigation/investigationAction.js';
import {GLOBAL} from '_Globals';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm, formValueSelector, change, SubmissionError, reset } from 'redux-form';
import axios from 'axios';
import swal from 'sweetalert';
import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";
class SpecifyVendor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			values: [],
			update: false,
			valuesticket: [],
			valuesVendor: [],
			formToggle: true,
			editVendor: [],
			editTicket: [],
			inputfieldMaximumLength: 50,
			loader: false,
			vendor: '',
			ticket: '',
			UiTotal: '',
			issueErrorColor: "",
			vendorList: [{
				"investigationId": null,
				"vendor": "",
				"ticket": "",
				"status": null
			}],
			updatedVendorList: [
				{
					"specificVendorTicketId": null,
					"investigationId": null,
					"vendor": "",
					"ticket": "",
					"createdOn": "",
					"createdBy": "",
					"createdByName": "",
					"status": null
				}],
			deleteList
				: [
					{
						"specificVendorTicketId": null
					}],
			validateNextField: '',

		};

		this.formToggleFun = this.formToggleFun.bind(this);
		this.saveAtt = this.saveAtt.bind(this);
		this.Editform = this.Editform.bind(this);
		this.addVendorItem = this.addVendorItem.bind(this);
		this.deleteVendoritem = this.deleteVendoritem.bind(this);
		this.validateField = this.validateField.bind(this);
		this.renderVendorTable = this.renderVendorTable.bind(this);
		this.renderVendorList = this.renderVendorList.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.deleteSpecificVendorTicket = this.deleteSpecificVendorTicket.bind(this);
		this.rendervalidateField = this.rendervalidateField.bind(this);
		GLOBAL.errormsg = "";
		GLOBAL.validate = true;
	}
	componentWillMount() {
		GLOBAL.errormsg = "";
		this.props.getspecificVendorTicket(this.props.investigationId);
	}

	Editform(vendor, ticket, specificVendorTicketId, index) {
		GLOBAL.errormsg = "";
		this.state.valuesVendor = [];
		this.state.valuesticket = [];
		let editVendor = [...this.state.editVendor];
		editVendor[0] = vendor;
		this.setState({ editVendor });
		let editTicket = [...this.state.editTicket];
		editTicket[0] = ticket;
		this.setState({ editTicket });
		this.setState({
			updatedVendorList: [{
				"specificVendorTicketId": specificVendorTicketId,
				"investigationId": this.props.investigationId,
				"vendor": vendor,
				"ticket": ticket,
				"createdOn": this.props.specificVendorTicketList[index].createdOn,
				"createdBy": this.props.specificVendorTicketList[index].createdBy,
				"createdByName": this.props.specificVendorTicketList[index].createdByName,
				"status": 1

			}],
			update: true,
			vendorList: [{
				"investigationId": this.props.investigationId,
				"vendor": vendor,
				"ticket": ticket,
				"status": 1
			}]
		})
	}
	formToggleFun(value) {


		this.setState({
			formToggle: value,
			update: false,
			vendorList: [{
				"investigationId": null,
				"vendor": "",
				"ticket": "",
				"status": null
			}],
			valuesVendor: [],
			valuesticket: []
		});
		GLOBAL.errormsg = "";
	}
	handleChange(i, event, type) {
		let ref = this;
		this.state.vendorList.forEach((element, index, array) => {
			if (index == i) {
				if (type == "vendor") {
					this.state.vendorList[i].vendor = event.target.value,
						this.state.vendorList[i].investigationId = ref.props.investigationId,
						this.state.vendorList[i].status = 1
					if (i == 0) {
						this.state.updatedVendorList[i].vendor = event.target.value;


					}
					let valuesVendor = [...this.state.valuesVendor];
					valuesVendor[i] = event.target.value;
					this.setState({ valuesVendor });
				}
				else if (type == "ticket") {
					this.state.vendorList[i].ticket = event.target.value,
						this.state.vendorList[i].investigationId = ref.props.investigationId,
						this.state.vendorList[i].status = 1
					if (i == 0) {
						this.state.updatedVendorList[i].ticket = event.target.value
					}
					let valuesticket = [...this.state.valuesticket];
					valuesticket[i] = event.target.value;
					this.setState({ valuesticket });
				}
			}
		});

	}
	deleteSpecificVendorTicket(investigationId, vendor, ticket) {
		this.state.deleteList[0].specificVendorTicketId = investigationId;
		this.props.deleteSpecificVendorTicket(this.state.deleteList, this.props.investigationId);
		let postjsonactivity = {
			"itemId": this.props.investigationId,
			"description": "Vendor Deleted :" + vendor + ", Ticket " + ticket,
			"isInternal": "",
			"createdOn": "",
			"createdBy": "",
			"module": "Investigation",
			"createdByName": ""
		};
		axios.post(GLOBAL.breakFixActivityLogPostUrl, postjsonactivity)
			.then(function (response) {

			}).catch(function (error) {
				console.log(error);
				//alert("H1"+error);
			});
	}

	saveAtt() {
		let ref = this;
		let value = 1;
		this.setState({
			loader: true
		})

		let postjsonactivity = {
			"itemId": this.props.investigationId,
			"description": " New Vendor Created : " + this.state.valuesVendor + ", New Ticket Created : " + this.state.valuesticket,
			"isInternal": "",
			"createdOn": "",
			"createdBy": "",
			"module": "Investigation",
			"createdByName": ""
		};
		value = this.validateField();
		if (value == 1) {
			if (ref.state.update == false && value == 1) {
				axios.post(GLOBAL.specificVendorTicket, this.state.vendorList)
					.then((resultResponse) => {


						if (!resultResponse) {
							throw Error(resultResponse.statusText);
						}
					}).then(() => {
						ref.props.getspecificVendorTicket(ref.props.investigationId);
						this.setState({
							formToggle: true,
							loader: false
						})
					}).then(() => {
						axios.post(GLOBAL.breakFixActivityLogPostUrl, postjsonactivity).then(function (response) {

						}).catch(function (error) {
							console.log(error);
							//alert("H1"+error);
						});
					})
			}
			if (ref.state.update == true && value == 1) {
				let postjsonactivity = {
					"itemId": this.props.investigationId,
					"description": this.state.editVendor[0] + " Vendor Changed to " + `${this.state.valuesVendor[0] == undefined ? this.state.editVendor[0] : this.state.valuesVendor[0]}` + ", " + this.state.editTicket[0] + " Ticket Changed to " + `${this.state.valuesticket[0] == undefined ? this.state.editTicket[0] : this.state.valuesticket[0]}`,
					"isInternal": "",
					"createdOn": "",
					"createdBy": "",
					"module": "Investigation",
					"createdByName": ""
				};
				axios.patch(GLOBAL.specificVendorTicket, this.state.updatedVendorList)
					.then((resultResponse) => {


						if (!resultResponse) {
							throw Error(resultResponse.statusText);
						}
					}).then(() => {
						ref.props.getspecificVendorTicket(ref.props.investigationId);
						this.setState({
							formToggle: true,
							loader: false
						})
					}).then(() => {
						axios.post(GLOBAL.breakFixActivityLogPostUrl, postjsonactivity).then(function (response) {

						}).catch(function (error) {
							console.log(error);
							//alert("H1"+error);
						});
					})
			}

		}

	}
	validateField() {
		let check = 0;
		if (this.state.update == false) {
			this.state.vendorList.forEach((element, i, array) => {
				if (this.state.vendorList[i].vendor == "" || this.state.vendorList[i].ticket == ""||this.state.vendorList[i].vendor.trim()==""||this.state.vendorList[i].ticket.trim()=="") {

					this.setState({
						loader: false
					})
					check = 1;
					GLOBAL.errormsg = "The form has missing required fields, please revalidate the fields and update the form"
					return (0);
				}else if(this.state.vendorList[i].vendor.length>this.state.inputfieldMaximumLength || this.state.vendorList[i].ticket.length>this.state.inputfieldMaximumLength){
                    this.setState({
						loader: false
					})
					check = 1;
					GLOBAL.errormsg = "The form fields has large values, please revalidate the fields and update the form"
					return (0);
				}

			})
			if (check == 0)
				return (1);
		}
		else {
			this.state.updatedVendorList.forEach((element, i, array) => {
				if (this.state.updatedVendorList[i].vendor == "" || this.state.updatedVendorList[i].ticket == ""||this.state.updatedVendorList[i].vendor.trim()==""||this.state.updatedVendorList[i].ticket.trim()=="") {

					this.setState({
						loader: false
					})
					check = 1;
					GLOBAL.errormsg = "The form has missing required fields, please revalidate the fields and update the form"
					return (0);
				}else if(this.state.vendorList[i].vendor.length>this.state.inputfieldMaximumLength || this.state.vendorList[i].ticket.length>this.state.inputfieldMaximumLength){
                    this.setState({
						loader: false
					})
					check = 1;
					GLOBAL.errormsg = "The form fields has large values, please revalidate the fields and update the form"
					return (0);
				}

			})
			if (check == 0)
				return (1);
		}
	}
	renderVendorTable(specificVendorTicketList) {
		if (specificVendorTicketList == undefined || specificVendorTicketList.length == 0 || !specificVendorTicketList) {
			return (
				<tr><td colSpan={3} className="text-c">No Data Found.</td></tr>
			);
		} else {
			return specificVendorTicketList.map((specificVendorTicketList, index) => {
				return (
					<tr key={index}>
						<td>{specificVendorTicketList.vendor}</td>
						<td>{specificVendorTicketList.ticket}</td>
						{this.props.disableFieldsForGuestRole?'':<td><div className="text-c"><i title={this.props.translator["Edit"]} className="fa fa-pencil-square-o cursorPoint" onClick={(e) => { this.setState({ formToggle: false }); this.Editform(specificVendorTicketList.vendor, specificVendorTicketList.ticket, specificVendorTicketList.specificVendorTicketId, index); }}></i>
							<i title={this.props.translator["Delete"]} className="margin-l-10 fa fa-trash-o cursorPoint" onClick={() => { this.deleteSpecificVendorTicket(specificVendorTicketList.specificVendorTicketId, specificVendorTicketList.vendor, specificVendorTicketList.ticket); }}></i></div></td>}
					</tr>
				);
			});
		}

	}

	renderVendorList() {
		const { vendorList = [] } = this.state;

		return vendorList.map((item, i) => {
			return (
				<Row key={i} className='margin-b-5'>
					<Col className='padding-r-5' md={4}>
						<Form.Group >
							<Form.Control name={"vendor" + i} className={`form-control ${this.state.vendorList[i].vendor.length>this.state.inputfieldMaximumLength?'error':''}`} type="text" component="input" onChange={(event) => { this.handleChange(i, event, "vendor") }} autoComplete="off" value={this.state.vendorList[i].vendor}/>
						</Form.Group>
					</Col>
					<Col className='padding-l-5 padding-r-5 margin-r-10 margin-l-10' md={4}>
						<Form.Group>

							<Form.Control name={"ticket" + i} className={`form-control ${this.state.vendorList[i].ticket.length>this.state.inputfieldMaximumLength?'error':''}`} type="text"  component="input" onChange={(event) => { this.handleChange(i, event, "ticket") }} autoComplete="off" value={this.state.vendorList[i].ticket}/>
						</Form.Group>
					</Col>
					{
						this.state.vendorList.length == i + 1&&this.state.update==false ?
							<Col>
							<ButtonToolbar className="black">
                                {i>0?<Button className='smallBtn' style={{padding:"2px 8px",height:"32px"}} onClick={(e) => { this.deleteVendoritem(i) }}><i className="fa fa-trash" title={this.props.translator['Delete']}></i></Button>:null}
								<Button className='rgSidrkBtn smallBtn' style={{padding:"2px 8px"}} onClick={(e) => { this.addVendorItem(i) }} ><i className="fa fa-plus" title={this.props.translator['Add']}></i></Button>
							</ButtonToolbar>
							</Col>
							:
						i < this.state.vendorList.length && this.state.update==false ?
						    <Col>
							<ButtonToolbar className="black">
							    <Button className='smallBtn' style={{padding:"2px 8px"}} bsSize="small" onClick={(e) => { this.deleteVendoritem(i) }}><i className="fa fa-trash" title={this.props.translator['Delete']}></i></Button>
						    </ButtonToolbar>
							</Col>
						    : null
					}
				</Row>
			);
		});
	}


	rendervalidateField(i) {
		let ref = this;
		this.state.vendorList.forEach((element, index, array) => {
			if (index == i) {
				if (this.state.vendorList[i].vendor == "" || this.state.vendorList[i].ticket == ""||this.state.vendorList[i].vendor.trim()==""||this.state.vendorList[i].ticket.trim()=="") {
					swal({

						text: this.props.translator['Please fill out the required fields'],
						buttons: this.props.translator['OK']
					})
					GLOBAL.validate = false;
					return (0);
				}else if(this.state.vendorList[i].vendor.length>this.state.inputfieldMaximumLength){
					swal({
						text: this.props.translator['Vendor name must be less than']+' '+this.state.inputfieldMaximumLength+' '+this.props.translator['characters'],
						buttons: this.props.translator['OK']
					})
					GLOBAL.validate = false;
					return (0);
				}else if(this.state.vendorList[i].ticket.length>this.state.inputfieldMaximumLength){
                    swal({
						text: this.props.translator['Ticket value must be less than']+' '+this.state.inputfieldMaximumLength+' '+this.props.translator['characters'],
						buttons: this.props.translator['OK']
					})
					GLOBAL.validate = false;
					return (0);
				}
				else {
					console.log("correct");
					GLOBAL.validate = true;
				}
			}
		})
	}
	deleteVendoritem(i){
		if(i===this.state.vendorList.length){
			this.state.valuesVendor.pop();
			this.state.valuesticket.pop();
			this.state.vendorList.pop();
		}else if(i<this.state.vendorList.length){
          this.state.valuesVendor.splice(i,1);
		  this.state.valuesticket.splice(i,1);
		  this.state.vendorList.splice(i,1);
		}
		this.setState({vendorList: this.state.vendorList});
	}
	addVendorItem(i) {

		this.rendervalidateField(i);
		if (GLOBAL.validate == true) {
			this.setState(prevState => {
				return {
					vendorList: [...prevState.vendorList, {
						"investigationId": null,
						"vendor": "",
						"ticket": "",
						"status": null
					}],
					update: false,

				};
			})
		}
		else {
			console.log("error");
		}

	}


	render() {
		console.log("vendorlist.length", this.state.update);
		if (this.state.formToggle == false) {
			return (
				<div className="hambTab">
					<div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Specify Vendor Details']}</div></div>
					<div className='rBoxGap'>

						<div className='f-size-12'>
							<span className='vendor'>{this.props.translator['Vendor']}</span>
							<span className='ticket'>{this.props.translator['Ticket']}</span>
							{this.state.update==false?<span className='actionCss'>{this.props.translator['Action']}</span>:null}
							<div className='borderBottom margin-t-10'></div>
						</div>

						<div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 margin-t-10">
							{GLOBAL.errormsg == "" ? null : <Alert variant="danger" className="errorMessageSec">
								<p>{GLOBAL.errormsg}</p>
							</Alert>}
							{this.renderVendorList()}

							<ButtonToolbar className="margin-t-10">
								<Button className='rgSidrkBtn smallBtn' disabled={this.state.saveLoader}
									onClick={this.saveAtt} >
									{this.state.loader ?
										<ImSpinner6 className="icn-spinner"/> : null} {this.props.translator['Save']}
								</Button>
								<Button className='rgSiLigBtn smallBtn' title="Close" onClick={() => { this.props.changeActionView('processHierarchy'); }}>{this.props.translator['Close']}
								</Button>

							</ButtonToolbar>
							<br/>
							{this.props.specificVendorTicketList == undefined || this.props.specificVendorTicketList.length == 0 || !this.props.specificVendorTicketList ? null :
							<div className="bgGray padding-5" />}
							<br/>
							{this.props.specificVendorTicketList == undefined || this.props.specificVendorTicketList.length == 0 || !this.props.specificVendorTicketList ? null :

								<Table striped bordered condensed responsive className="f-size-13">
								<thead>
								<tr className='f-size-12'>
											<th>{this.props.translator['Vendor']}</th>
											<th>{this.props.translator['Ticket']}</th>
											<th className="text-c">{this.props.translator['Action']}</th>
										</tr>
									</thead>
									<tbody>
										{this.renderVendorTable(this.props.specificVendorTicketList)}
									</tbody>
								</Table>
							}
						</div>
					</div>
				</div>

			);
		}
		else {

			return (
				<div>
					<div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
						<ul>
							<li>
							<button
							    type='button'
								title="Minimize the right panel"
								bsClass=""
								bsStyle=""
								className="closerightPanelBtn"
								onClick={() => {
								this.props.rightEditPanel(false);
								}}
							>
								<IoClose />
							</button>
							</li>
						</ul>
					</div>
					<div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Specify Vendor Details']}</div></div>
					<div className='rBoxGap'>
						{this.props.disableFieldsForGuestRole?'':
						<ButtonToolbar className="black margin-b-10">
							<Button className='rgSidrkBtn smallBtn' disabled={false}
								onClick={() => { this.formToggleFun(false) }}>{this.props.translator['Add']}
							</Button>
						</ButtonToolbar>}

						<Table striped bordered condensed responsive className="f-size-13">
							<thead>
							<tr className='f-size-12'>
									<th>{this.props.translator['Vendor']}</th>
									<th>{this.props.translator['Ticket']}</th>
									{this.props.disableFieldsForGuestRole?'':<th className="text-c">{this.props.translator['Action']}</th>}
								</tr>
							</thead>
							<tbody>
								{this.renderVendorTable(this.props.specificVendorTicketList)}
							</tbody>
						</Table>
					</div>
				</div>
			)
		}


	}
}
// }





const mapStateToProps = ({ specificVendorTicketList, showLoader }) => {

	return {
		specificVendorTicketList,
		showLoader: showLoader.loading
	};
};
function mapDispatchToProps(dispatch) {
	return bindActionCreators({ deleteSpecificVendorTicket, getspecificVendorTicket, }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SpecifyVendor);
