
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export default function rcaTechniques(state=[],action){
    switch(action.type){
        case 'RCA_TECHNIQUES':
             return action.techniques; 
        default:
             return state;      
    }
};