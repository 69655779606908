
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
// import CategoryBoardHeader from "../..//categoryBoard/listViewCategory/CategoryBoardHeader";
import CategoryBoardHeader from "./CategoryBoardHeader";
import CategoryBoardList from "./categoryBoard/listViewCategory/CategoryBoardList.js";
import CategoryBoardShortDescription from "./categoryBoard/listViewCategory/CategoryBoardShortDescription";
import {
  Row,
  Col,
} from "react-bootstrap";
import { loadCategoryList } from "../../actions/categoryBoard/quickViewAction.js";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Cookies from "universal-cookie";
import { GLOBAL } from "_Globals";
import { navigationHooks } from "../../helpers/NavigationHook";
import CiCategoryIndex from "./ciCategory/CiCategoryIndex";
import Axios from 'axios';
import ResolutionCategoryIndex from "./ciResolutionCategory/ResolutionCategoryIndex";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import "_Css/common/_dataTable.scss";

const api = Axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});
const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home") homepagelocation = "/" + homepagelocation;

let user_roleId = cookies.get("gph");
if (user_roleId) user_roleId = user_roleId.replace("s:", "");
if (user_roleId)
  user_roleId = user_roleId.substring(0, user_roleId.lastIndexOf("."));
user_roleId = user_roleId.split("~");
user_roleId = user_roleId[33];
class CategoryBoardIndex extends React.Component {
  constructor(props) {
    console.log("Inside Contructor")
    super(props);
    this.state = {
      subMenu: "4",
      showHideAlfy: false,
      showHideRightNav: false,
      mobileLeftMenuFilter: false,
      categoryId: "",
      categoryName: "",
      subCategoryName: "",
      status: "",
      categoryCode: "",
      catPinned: "",
	    rightDisplay: false,
	    activeTab: '',
	    rolesArray: [],
      loader: false,
      businessFunctionName: ''
    };
    this.showHideAlfy = this.showHideAlfy.bind(this);
    this.showHideRightNav = this.showHideRightNav.bind(this);
    this.getCategoryBoardDetails = this.getCategoryBoardDetails.bind(this);
    this.loadHome = this.loadHome.bind(this);
    this.showRightSide= this.showRightSide.bind(this);
    this.handleTab= this.handleTab.bind(this);
    this.location = navigationHooks.location;
  }
  showRightSide(val) {
    this.setState({rightDisplay: val});
  };
  loadHome() {
    navigationHooks.navigate(homepagelocation);
  }

  showHideAlfy(value) {
    this.setState({ showHideAlfy: value });
  }

  showHideRightNav(value) {
    this.setState({ showHideRightNav: !this.state.showHideRightNav });
  }

  mobileLeftMenuFilter() {
    this.setState({ mobileLeftMenuFilter: !this.state.mobileLeftMenuFilter });
  }

  componentWillReceiveProps(nextProps) {
    console.log('CWRP ---- ', nextProps )
  }

  componentDidMount() {

    if(GLOBAL.activeCategory == 'service'){
      this.setState({ activeTab: "service" });
       this.props.loadCategoryList();
    }else if(GLOBAL.activeCategory == 'category'){
      this.setState({ activeTab: "category" });
        }else if(GLOBAL.activeCategory == 'resolution'){
      this.setState({ activeTab: 'resolution'});
    }

    console.log('this.state.activeTab ---', this.state.activeTab);

    let roleArray = user_roleId.split(',');
    console.log('RoleArray ---', roleArray)
    this.setState({rolesArray:roleArray});
  //   console.log("Role Array",roleArray.includes('37') || roleArray.includes('38'))
  //   if (roleArray.includes('5') && (roleArray.includes('37') || roleArray.includes('38'))) {//Both 
  //     this.setState({ activeTab: "service" });
  //     this.props.loadCategoryList();
  //   } else if (roleArray.includes('37') || roleArray.includes('38')) { //Category Board
  //     this.setState({ activeTab: "category" });
  //   } else if (roleArray.includes('5')) { //Service Board
  //     this.setState({ activeTab: "service" });
  //     this.props.loadCategoryList();
  //   } if(this.location.state?.activeTab === 'category') {
  //     this.setState({ activeTab: 'category'});
  //   } if(this.location.state?.activeTab === 'resolution') {

  //   console.log("Entered");
  //     this.setState({ activeTab: 'resolution'});
  //   } 
  }
handleTab(clickedItem){
  GLOBAL.activeCategory = clickedItem;
  this.setState({
    activeTab:clickedItem
  });
}
  getCategoryBoardDetails(
    categoryId,
    categoryName,
    subCategoryName,
    status,
    categoryCode,
    catPinned,
    businessFunctionName
  ) {
    this.setState({
      categoryId: categoryId,
      categoryName: categoryName,
      subCategoryName: subCategoryName,
      status: status,
      categoryCode: categoryCode,
      catPinned: catPinned,
      businessFunctionName: businessFunctionName
    });
  }

  render() {

    return (
      <div>
        <div className="minHeightQA">
          <div className="container-fluid margin-t-10 margin-b-15">
            {/* <div className="tabDatacount">1 - 10 of 786</div> */}
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <a
                  role="button"
                  href="javascript:void(0)"
                  onClick={this.loadHome}
                  
                >
                  {this.props.translator["Home"]}
                </a>
              </li>
              <li className="breadcrumb-item active">
                {this.props.translator["Category Board"]}
                {/* {this.state.activeTab=='service' ? this.props.translator["Service Category"]: this.props.translator["CI Category"]} */}
                {/* {this.state.activeTab=='service' ? this.props.translator["Service Category"]: this.state.activeTab=='category' ? this.props.translator["CI Category"]: this.props.translator["Resolution Category"]} */}
              </li>
            </ul>
          </div>
          <div className="container-fluid">
            <CategoryBoardHeader translator={this.props.translator} activeTab={this.state.activeTab} handleTab={this.handleTab} roleArray={user_roleId}/>
          </div>
          {!this.state.loader && <>
          {this.state.activeTab=='service' &&
          <div className="container-fluid position-re" bsClass="">
              
            <PanelGroup direction="horizontal" className="panelOverflow">
              <Panel id="sidebar" minSize={33} order={1} defaultSize={this.state.rightDisplay ? 67 : 100} className={this.state.rightDisplay ? "catBordDv isShowLeftPanel rwLeftPart" : "catBordDv rwLeftPart"}>
              <CategoryBoardList
                rightDisplay={this.state.rightDisplay}
                getCategoryBoardDetails={this.getCategoryBoardDetails}
                translator={this.props.translator}
                showRightSide={this.showRightSide}
                />
              </Panel>
              {this.state.rightDisplay ?
                  <>
                    <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
                    <Panel minSize={33} order={2} defaultSize={33}>
                      <div className="stickyArea rBoxStyle">
                        <CategoryBoardShortDescription
                          categoryId={this.state.categoryId}
                          categoryName={this.state.categoryName}
                          subCategoryName={this.state.subCategoryName}
                          status={this.state.status}
                          categoryCode={this.state.categoryCode}
                          translator={this.props.translator}
                          catPinned={this.state.catPinned}
                          businessFunctionName={this.state.businessFunctionName}
                          showRightSide={this.showRightSide}				   
                        />
                      </div>
                    </Panel>
                  </> : ""
              }
            </PanelGroup>
          </div>}
          {this.state.activeTab=='category' &&
          <CiCategoryIndex />}
          {this.state.activeTab=='resolution' &&
          <ResolutionCategoryIndex />}
          </>}
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    slaDefList: state.slaDefList,
    translator: state.spcmReducer.tr,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loadCategoryList }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryBoardIndex);

//export default CMDB;
