
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const navigationHooks = {
    navigate: null,
    location: null,
    params: null,
    useRef: null,
    history: null,
    dispatch: null
};