
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link, NavLink } from "react-router-dom";
import {PiPencilSimpleBold} from "react-icons/pi";
import {IoClose} from "react-icons/io5";
import { MyContext } from '_MyContext';
class XsmQuickViewShortDescription extends React.Component {
  static contextType = MyContext;
  constructor(props) {
    super(props);
    this.state = {
      tabKey: 1,
    };
  }

  componentDidMount() {
		this.context.keyDownHandler(this.closeButton,this.editButton,"","");
    this.context.newTabPrevent();
	}
	componentDidUpdate() {
		this.context.keyDownHandler(this.closeButton,this.editButton,"","");
    this.context.newTabPrevent();
	}

  handleSelect(tabKey) {
    this.setState({ tabKey });
  }
  render() {
    return (
      <div className="">
        <Row>
          <Col xs={12}>
            <div className="rPageHeadActBtn">
              <ul>
                <li>
                  <NavLink to={"/editHolidaySchedule/" + this.props.scheduleId} title={this.props.translator["Edit Holiday Schedule"]} ref={e => this.editButton = e} className="ctrlKeyPrevent">
                    <PiPencilSimpleBold/>
                  </NavLink>
                </li>

                <li>
                  <Link
                    title={this.props.translator["Minimize the details panel"]}
                    bsClass=""
                    to="javascript:void(0)"
                    onClick={() => {
                      this.props.showRightSide(false);
                    }}
                    ref={e => this.closeButton = e}
                    className="ctrlKeyPrevent"
                  >
                    <IoClose/>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="rPageHeading">
              <div className="offNam margin-t-5 margin-b-5">
                {this.props.scheduleName}
              </div>
            </div>
          </Col>
        </Row>
        <div className="rBoxGap">
          <div className="rBoxTabs">
            <Tabs
              activeKey={this.state.tabKey}
              onSelect={this.handleSelect}
              id="tabId"
            >
              <Tab eventKey={1} title="Schedule Details">
                <Table className="f-size-13 tableWordBreak">
                  <thead>
                    <tr>
                      <th>{this.props.translator["Holiday Date"]}</th>
                      <th>{this.props.translator["Holiday Name"]}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.holidayScheduleList != null &&
                    this.props.holidayScheduleList.length > 0
                      ? this.props.holidayScheduleList.map((day, index) => {
                          return (
                            <tr>
                              <td>
                                {day.date}-{day.month}-{day.year}
                              </td>
                              <td>{day.holidayName}</td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </Table>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

export default XsmQuickViewShortDescription;
