
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import {Row} from 'react-bootstrap';
import CloseBtnRightPanel from '../../common/closeBtnRightPanel';

class ProcessHierarchy extends React.Component{
	render(){
		return(
		<div>
			<CloseBtnRightPanel showRightSide={this.props.rightEditPanel} />
			<div className="rPageHeading">
				<div className="offNam margin-t-5 margin-b-5">
					{this.props.translator['Process Workflow']}
				</div>
			</div>
			<div className='text-c'><img className="img-fluid marAuto" src={require('../../../images/sla-diagram.png')} title={this.props.translator['Process Hierarchy']} /></div>
		</div>
		);
	}
}


export default ProcessHierarchy;
