
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { useNavigate } from "react-router-dom";
import MySurveyIndex from "./MySurveyIndex";

export default function MySurveyMain()
{ 
	const navigate = useNavigate();
	return (<MySurveyIndex navigate={navigate}/>)
}