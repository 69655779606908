
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState, useEffect } from "react";
import moment from "moment";
import parse from "html-react-parser";
import { Popover, Card, Button, OverlayTrigger } from "react-bootstrap";
import { ImInfo } from "react-icons/im";
import datetimeConvertor from "../../../../../helpers/ISO8601converter";
import { getCookiesValuesByPositions } from "../../../Helper";
import {
  useProfileDetailsUrlQuery,
  useProfileTimeZoneUrlQuery,
} from "../../../../../reducers/governance/meeting/meeting";
import UserDetailsComponent from "../../../common/userDetails";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";
const cookies = new Cookies();
let cookiesArray = cookies.get('gph');
if (cookiesArray) cookiesArray = cookiesArray.replace('s:', '');
if (cookiesArray) cookiesArray = cookiesArray.substring(0, cookiesArray.lastIndexOf('.'));
cookiesArray = cookiesArray.split("~");
let langDir = cookiesArray[66];

const AuditHistory = ({
  data = [],
  isFetching = false,
  auditLogRefetch,
  setOrderValue,
}) => {
  const [selectedUser, setSelectedUser] = useState("");
  const [timezoneText, setTimezoneText] = useState("");
  const cookieValues = getCookiesValuesByPositions([2, 3]);
  const dateformat = cookieValues?.value1;
  const timezone = cookieValues?.value2;
  const windowSize = useSelector((state) => state.windowSize.width);

  const jwtdf = dateformat?.slice(0, 10).toUpperCase();

  const actDateTimeFormat = jwtdf + " hh:mm A";


  const { data: userDetails, isFetching: isUserFetching } =
    useProfileDetailsUrlQuery(
      {
        userId: selectedUser,
      },
      {
        skip: !selectedUser,
      }
    );

  const {
    data: timezoneData,
    isFetching: isTimezoneFetching,
    refetch: timezoneFetch,
  } = useProfileTimeZoneUrlQuery({
    skip: false,
  });

const parseValue=(data)=>{
  let str = data.replace(/_/g, " ").toLowerCase();
  return str.charAt(0).toUpperCase() + str.slice(1)
}

  return (
    <div id="audHistory" className="actCommts">
      <div className="d-flex justify-content-end">
        {data?.length > 0 && (
          <span className="f-size-17">
            <a
              className="black"
              title={"Ascending"}
              onClick={() => {
              
                setOrderValue('asc');
                auditLogRefetch()
              }}
            >
              <i className="fa fa-angle-up"></i>
            </a>
            &nbsp;&nbsp;
            <a
              className="black"
              title={"Descending"}
              onClick={() => {
               
                setOrderValue('desc');
                auditLogRefetch()
              }}
            >
              <i className="fa fa-angle-down"></i>
            </a>
          </span>
        )}
      </div>
      {data?.map((comment, childKey) => {
        const isoDate = comment?.updatedOn;
        const myDateObj = moment(isoDate).format(actDateTimeFormat);
        const endDate = moment(myDateObj, actDateTimeFormat);
        const endDateStr = endDate._i;
        const startTime = moment(
          new Date().toLocaleString("en-US", { timeZone: timezone })
        );
        const timeago = moment(endDateStr, actDateTimeFormat).from(startTime);
        const duration = moment.duration(startTime.diff(endDate));
        const hours = duration.asHours();

        const tooltip = (
          <Popover className="userInfoPopup govrncInfoPopup">
            <UserDetailsComponent
              userDetails={userDetails}
              isUserFetching={isUserFetching}
              timezoneText={timezoneText}
            />
          </Popover>
        );

        return (
          <Card className="actlogCrd" bsPrefix=" ">
            <div className="circleTxt">
              <div className="subhed">
                {comment.updatedBy !== "System" ? (
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement={windowSize < 768 ? "auto-start" : (langDir === "rtl" ? "right-start" : "left-start")
}
                    overlay={tooltip}
                  >
                    <span className="circleNa" title={comment?.updatedBy}
                    onClick={() => {
                      setSelectedUser(comment?.updatedById);
                      timezoneFetch();
                    }}
                    >
                      {comment?.updatedBy} <ImInfo />
                    </span>
                  </OverlayTrigger>
                ) : (
                  <span className="margin-r-20">{comment?.updatedBy}</span>
                )}

                <span className="margin-l-5 timeao">
                  <span
                    className="margin-l-5 timeao"
                    title={hours >= 24 ? "" : endDateStr}
                  >
                    {hours >= 24 ? endDateStr : timeago}
                  </span>
                </span>
              </div>
              <div className="desFld">
                <div title="Field Name" className="fenme">
                
                  {parseValue(comment?.fieldName)}
                </div>
                <div title="New Value" className="nwnme commn">
                  {comment?.newValue || "NA"}
                </div>
                <div title="Old Value" className="oldnme commn">
                  {comment?.oldValue || "NA"}
                </div>
              </div>
            </div>
          </Card>
        );
      }) || (
        <Card className="actlogCrd text-center" bsPrefix=" ">
          No Data Available
        </Card>
      )}
    </div>
  );
};

export default AuditHistory;
