
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import {
  Row,
  Col,
  Form,
  ButtonToolbar,
  Button,
  Alert,
} from "react-bootstrap";
import { connect } from "react-redux";
import axios from "axios";
import { Field, reduxForm, SubmissionError, change } from "redux-form";
import {
  _textArea,
  _dropDown,
  _inputField,
  _number,
} from "../../../../common/formFields";
import {
  spcmLoader,
  translate,
  getTranslationForOfferingBasedOnType,
  translateQuestCat,
  translateQuestText,translateTableHeaders,
  translateQuestPlaceholder,
  translateQuestHelpText,
  translateDisplayText,
  resetReducer,
  getServiceOrderChoices,
  getQuestionsCategory,
  translateResponseValues
} from "../../../../../actions/spcmActions";
import {ImSpinner6} from "react-icons/im";

const validate = (values) => {
  console.log("values");
  console.log(values);
  let errors = {};
  if (values.translated_category) {
    if (values.translated_category.trim() == "") {
      errors.translated_category = "Enter Translated Category";
    }
  }
  else if (!values.translated_category) {
    errors.translated_category = "Enter Translated Category";
  }

  if (values.translated_help_text) {
    if (values.translated_help_text.trim() == "") {
      errors.translated_help_text = "Enter Translated Help Text";
    }
  }
  else if (!values.translated_help_text) {
    errors.translated_help_text = "Enter Translated Help Text";
  }

  if (values.translated_placeholder) {
    if (values.translated_placeholder.trim() == "") {
      errors.translated_placeholder = "Enter Translated Placeholder";
    }
  }
  else if (!values.translated_placeholder) {
    errors.translated_placeholder = "Enter Translated Placeholder";
  }


  if (values.translated_question_name) {
    if (values.translated_question_name.trim() == "") {
      errors.translated_question_name = "Enter Translated Question Text";
    }
  }
  else if (!values.translated_question_name) {
    errors.translated_question_name = "Enter Translated Question Text";
  }

  if (!values.translated_table_headers) {
    errors.translated_table_headers = "Enter Translated Table Headers";
  }
  if (values.translated_table_headers) {
  if (values.translated_table_headers.trim() == "") {
    errors.translated_table_headers = "Enter Translated Table Headers";
    }
  }
  if (values.translated_response_value) {
    if (values.translated_response_value.trim() == "") {
      errors.translated_response_value = "Enter Translated Response Values";
    }
  }
  else if (!values.translated_response_value) {
    errors.translated_response_value = "Enter Translated Response Values";
  }
  if (values.translated_display_text) {
    if (values.translated_display_text.trim() == "") {
      errors.translated_display_text = "Enter Translated Response Values";
    }
  }
  else if (!values.translated_display_text) {
    errors.translated_display_text = "Enter Translated Response Values";
  }


  console.log("errors");
  console.log(errors);
  return errors;
};

class TranslationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attributeValue: "",
      attribute_trans_value: "",
    };
    this.submitForm = this.submitForm.bind(this);
    this.validateTableHeaders = this.validateTableHeaders.bind(this);
  }  
  componentWillReceiveProps(newProps) {
    console.log("componentWillReceiveProps");
    console.log(newProps.spcmReducer.translateQuestText);
    if (
      this.props.spcmReducer.translateQuestCat !==
      newProps.spcmReducer.translateQuestCat
    ) {
      this.props.dispatch(
        change(
          "QuestionForm",
          "translated_category",
          newProps.spcmReducer.translateQuestCat
        )
      );
    }

    if (
      this.props.spcmReducer.translateQuestHelpText !==
      newProps.spcmReducer.translateQuestHelpText
    ) {
      this.props.dispatch(
        change(
          "QuestionForm",
          "translated_help_text",
          newProps.spcmReducer.translateQuestHelpText
        )
      );
    }

    if (
      this.props.spcmReducer.translateQuestPlaceholder !==
      newProps.spcmReducer.translateQuestPlaceholder
    ) {
      this.props.dispatch(
        change(
          "QuestionForm",
          "translated_placeholder",
          newProps.spcmReducer.translateQuestPlaceholder
        )
      );
    }

    if (
      this.props.spcmReducer.translateQuestText !==
      newProps.spcmReducer.translateQuestText
    ) {
      this.props.dispatch(
        change(
          "QuestionForm",
          "translated_question_name",
          newProps.spcmReducer.translateQuestText
        )
      );
    }
    if (
      this.props.spcmReducer.translateTableHeaders !==
      newProps.spcmReducer.translateTableHeaders
    ) {
      this.props.dispatch(
        change(
          "QuestionForm",
          "translated_table_headers",
          newProps.spcmReducer.translateTableHeaders
        )
      );
    }
    if (
      this.props.spcmReducer.translatedResponseValues !==
      newProps.spcmReducer.translatedResponseValues
    ) {
      this.props.dispatch(
        change(
          "QuestionForm",
          "translated_response_value",
          newProps.spcmReducer.translatedResponseValues
        )
      );
    }

    if (
      this.props.spcmReducer.translateDisplayText !==
      newProps.spcmReducer.translateDisplayText
    ) {
      this.props.dispatch(
        change(
          "QuestionForm",
          "translated_display_text",
          newProps.spcmReducer.translateDisplayText
        )
      );
    }

  }

  componentWillUnmount() {
    this.props.resetReducer();
  }
  validateTableHeaders(e) {
    let str = e.target.value;
    let semicolonCount = str.split(";").length - 1;
    if (semicolonCount > 2) {
      e.preventDefault();
    }
  }


  submitForm(values) {
    console.log("valuess");
    console.log(values);

    if (this.props.currentForm == "Questions_Form") {
      let res = values.translated_response_value;
      if (res) {
        if (res.indexOf("~") > -1) {
          res = res.replace("~", ";");
        }
      }
      console.log("res");
      console.log(res);
      let data = [
        {
          offering_id: this.props.spcmReducer.currentlySelectedServiceDetails
            .OFFERING_ID,
          language_id: this.props.lanIdVal2,
          attribute_id: this.props.spcmReducer.objectForTranslation.question_id,
          attribute_type: "offering_question_name",
          attribute_value: this.props.spcmReducer.objectForTranslation.question_text,
          attribute_trans_value: values.translated_question_name,
        },
        {
          offering_id: this.props.spcmReducer.currentlySelectedServiceDetails
            .OFFERING_ID,
          language_id: this.props.lanIdVal2,
          attribute_id: this.props.spcmReducer.objectForTranslation.question_id,
          attribute_type: "help_text",
          attribute_value: this.props.spcmReducer.objectForTranslation.help_text,
          attribute_trans_value: values.translated_help_text,
        },
        {
          offering_id: this.props.spcmReducer.currentlySelectedServiceDetails
            .OFFERING_ID,
          language_id: this.props.lanIdVal2,
          attribute_id: this.props.spcmReducer.objectForTranslation.question_id,
          attribute_type: "placeholder",
          attribute_value: this.props.spcmReducer.objectForTranslation.placeholder,
          attribute_trans_value: values.translated_placeholder,
        },
        {
          offering_id: this.props.spcmReducer.currentlySelectedServiceDetails
            .OFFERING_ID,
          language_id: this.props.lanIdVal2,
          attribute_id: this.props.spcmReducer.objectForTranslation.question_id,
          attribute_type: "response_value",
          attribute_value: this.props.spcmReducer.objectForTranslation.response_value,
          attribute_trans_value: res,
        },
        {
          offering_id: this.props.spcmReducer.currentlySelectedServiceDetails
            .OFFERING_ID,
          language_id: this.props.lanIdVal2,
          attribute_id: this.props.spcmReducer.objectForTranslation.question_id,
          attribute_type: "display_text",
          attribute_value: this.props.spcmReducer.objectForTranslation.display_text,
          attribute_trans_value: values.translated_display_text,
        },
        {
          offering_id: this.props.spcmReducer.currentlySelectedServiceDetails
            .OFFERING_ID,
          language_id: this.props.lanIdVal2,
          attribute_id: this.props.spcmReducer.objectForTranslation.question_id,
          attribute_type: "table_headers",
          attribute_value: this.props.spcmReducer.objectForTranslation.table_headers,
          attribute_trans_value: values.translated_table_headers,
        }
      ];


      this.props.spcmLoader("attribute", "posting");
      return axios
        .post(
          "/api/languageTranslation/postRegardingOfferingTypeTranslation",
          data
        )
        .then((response) => {
          this.props.spcmLoader("attribute", "success");
          this.props.getTranslationForOfferingBasedOnType(
            this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
            this.props.lanIdVal2,
            "question"
          );
          this.props.getServiceOrderChoices(
            this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
            "",
            "",
            "Question"
          );
          this.props.insideTableShowFun6(false, "");
        })
        .catch((error) => {
          this.props.spcmLoader("attribute", "success");
          throw new SubmissionError({ _error: error.response.data.message });
        });
    }
    else {
      let data = {
        offering_id: this.props.spcmReducer.currentlySelectedServiceDetails
          .OFFERING_ID,
        language_id: this.props.lanIdVal2,
        attribute_id: this.props.spcmReducer.objectForTranslation.category_id,
        attribute_type: "question_category",
        attribute_value: this.props.spcmReducer.objectForTranslation.category_name,
        attribute_trans_value: values.translated_category,
      };

      this.props.spcmLoader("attribute", "posting");
      return axios
        .post("/api/languageTranslation", data)
        .then((response) => {
          this.props.spcmLoader("attribute", "success");
          this.props.getTranslationForOfferingBasedOnType(
            this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
            this.props.lanIdVal2,
            "question_category"
          );
          this.props.getQuestionsCategory(
            this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID
          );
          this.props.insideTableShowFun6(false, "");
        })
        .catch((error) => {
          this.props.spcmLoader("attribute", "success");
          throw new SubmissionError({ _error: error.response.data.message });
        });
    }
  }

  render() {
    console.log("objectForTranslation");
    console.log(this.props.spcmReducer.objectForTranslation);
    console.log(this.props.lanIdVal2);
    const { error, handleSubmit } = this.props;
    const tr = this.props.tr;

    let translateRespValues = this.props.spcmReducer.objectForTranslation.response_value;
    if (translateRespValues) {
      if (translateRespValues.indexOf(";") > -1) {
        translateRespValues = translateRespValues.replace(";", "~");
      }

    }


    return (
      <div>
        <div  className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">
          Translate - {this.props.fieldNameVal3} - {this.props.lanNameVal3}{" "}
        </div></div>

        <div className="rBoxGap">

        {this.props.currentForm == "Questions_Form" ? (
          <div className="borderForm margin-b-10">
            <form onSubmit={handleSubmit(this.submitForm)}>
              {error == undefined || error == null ? null : (
                <Alert bsStyle="danger" className="errorMessageSec">
                  <p>{error}</p>
                </Alert>
              )}
              <Row>
                <Col md={12}>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">Base Language</Form.Label>
                    <Form.Control disabled value="English" type="text" />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">
                      {/* {this.props.fieldName1} */}
                      Information Prompt
                    </Form.Label>
                    <Form.Control
                      disabled
                      value={
                        this.props.spcmReducer.objectForTranslation.question_text
                      }
                      componentClass="textarea"
                      rows="2"
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">
                      <span className="rStar"></span> Translated Information
                      Prompt
                      {/* {this.props.fieldName1}{" "} */}
                     {/* <Button
                        onClick={() =>
                          this.props.translateQuestText(
                            this.props.spcmReducer.objectForTranslation.question_text,
                            this.props.languageCode
                          )
                        }
                        className="margin-l-10 margin-b-5"
                        title="Google Translate"
                        bsSize="xsmall"
                      >
                        <i className="fa fa-google"></i> Translate
                      </Button>*/}
                    </Form.Label>
                    <Field
                      component={_textArea}
                      name="translated_question_name"
                      className="form-control"
                      rows="3"
                    />
                  </Form.Group>
                </Col>
                {
                this.props.spcmReducer.objectForTranslation.table_headers ?
                
                 <Col md={12}>
                 <Form.Group className="form-group">
                   <Form.Label bsClass="">
                     {/* {this.props.fieldName1} */}
                    Table Headers
                   </Form.Label>
                   <Form.Control
                     disabled
                     value={
                       this.props.spcmReducer.objectForTranslation.table_headers
                     }
                     componentClass="textarea"
                     rows="2"
                   />
                 </Form.Group>
               </Col>: null}
               {this.props.spcmReducer.objectForTranslation.table_headers ?
               <Col md={12}>
                 <Form.Group className="form-group">
                   <Form.Label bsClass="">
                     <span className="rStar"></span> Translated Headers
                   </Form.Label>
                   
                    <Field
                        component={_inputField}
                        name="translated_table_headers"
                        className="form-control"
                        onChange={(e) => this.validateTableHeaders(e)}
                      />
                 </Form.Group>
                 {/* <span className="f-size-10">
                      Please enter semicolon separated value (Ex. Value 1 ;
                      Value 2 ; Value 3). Maximum 3 values can be entered
                    </span> */}
               </Col>
                
                :null}
                 
                {
                  this.props.spcmReducer.objectForTranslation.response_value ?
                    <Col md={12}>
                      <Form.Group className="form-group">
                        <Form.Label bsClass="">
                          List of Values
                    </Form.Label>
                        <Form.Control
                          disabled
                          value={
                            this.props.spcmReducer.objectForTranslation.response_value
                          }
                          componentClass="textarea"
                          rows="2"
                        />
                      </Form.Group>
                    </Col> : null
                }
                {
                  this.props.spcmReducer.objectForTranslation.response_value ?
                    <Col md={12}>
                      <Form.Group className="form-group">
                        <Form.Label bsClass="">
                          <span className="rStar"></span> Translated List of Values
                    {/*  <Button
                            onClick={() =>
                              this.props.translateResponseValues(
                                translateRespValues,
                                this.props.languageCode
                              )
                            }
                            className="margin-l-10 margin-b-5"
                            title="Google Translate"
                            bsSize="xsmall"
                          >
                            <i className="fa fa-google"></i> Translate
                      </Button>*/}
                        </Form.Label>
                        <Field
                          component={_textArea}
                          name="translated_response_value"
                          className="form-control"
                          rows="3"
                        />
                      </Form.Group>
                    </Col> : null
                }



                {this.props.spcmReducer.objectForTranslation.help_text ? (
                  <Col md={12}>
                    <Form.Group className="form-group">
                      <Form.Label bsClass="">Help Text</Form.Label>
                      <Form.Control
                        disabled
                        value={
                          this.props.spcmReducer.objectForTranslation.help_text
                        }
                        componentClass="textarea"
                        rows="2"
                      />
                    </Form.Group>
                  </Col>
                ) : null}

                {this.props.spcmReducer.objectForTranslation.help_text ? (
                  <Col md={12}>
                    <Form.Group className="form-group">
                      <Form.Label bsClass="">
                        <span className="rStar"></span> Translated Help Text{" "}
                       {/* <Button
                          onClick={() =>
                            this.props.translateQuestHelpText(
                              this.props.spcmReducer.objectForTranslation.help_text,
                              this.props.languageCode
                            )
                          }
                          className="margin-l-10 margin-b-5"
                          title="Google Translate"
                          bsSize="xsmall"
                        >
                          <i className="fa fa-google"></i> Translate
                        </Button>*/}
                      </Form.Label>
                      <Field
                        component={_textArea}
                        name="translated_help_text"
                        className="form-control"
                        rows="3"
                      />
                    </Form.Group>
                  </Col>
                ) : null}

                {this.props.spcmReducer.objectForTranslation.placeholder ? (
                  <Col md={12}>
                    <Form.Group className="form-group">
                      <Form.Label bsClass="">Placeholder</Form.Label>
                      <Form.Control
                        disabled
                        value={
                          this.props.spcmReducer.objectForTranslation.placeholder
                        }
                        componentClass="textarea"
                        rows="2"
                      />
                    </Form.Group>
                  </Col>
                ) : null}





                {this.props.spcmReducer.objectForTranslation.placeholder ? (
                  <Col md={12}>
                    <Form.Group className="form-group">
                      <Form.Label bsClass="">
                        <span className="rStar"></span> Translated Placeholder{" "}
                       {/* <Button
                          onClick={() =>
                            this.props.translateQuestPlaceholder(
                              this.props.spcmReducer.objectForTranslation.placeholder,
                              this.props.languageCode
                            )
                          }
                          className="margin-l-10 margin-b-5"
                          title="Google Translate"
                          bsSize="xsmall"
                        >
                          <i className="fa fa-google"></i> Translate
                        </Button>*/}
                      </Form.Label>
                      <Field
                        component={_textArea}
                        name="translated_placeholder"
                        className="form-control"
                        rows="3"
                      />
                    </Form.Group>
                  </Col>
                ) : null}
{
                  this.props.spcmReducer.objectForTranslation.display_text ?
                    <Col md={12}>
                      <Form.Group className="form-group">
                      <Form.Label bsClass="">
                          Display Text 
                    </Form.Label>
                        <Form.Control
                          disabled
                          value={
                            this.props.spcmReducer.objectForTranslation.display_text.replace( /(<([^>]+)>)/ig, '')
                          }
                          componentClass="textarea"
                          rows="2"
                        />
                      </Form.Group>
                    </Col> : null
                }
                {
                  this.props.spcmReducer.objectForTranslation.display_text ?
                    <Col md={12}>
                      <Form.Group className="form-group">
                      <Form.Label bsClass="">
                          <span className="rStar"></span> Translated Display Text                    
                        </Form.Label>
                        <Field
                          component={_textArea}
                          name="translated_display_text"
                          className="form-control"
                          rows="3"
                        />
                      </Form.Group>
                    </Col> : null
                }

              </Row>
              <Row>
                <Col md={12}>
                  <ButtonToolbar className="black">
                    <Button
                      title={tr["Save"]}
                      type="submit"
                      bsSize="small"
                      bsStyle="primary"
                      className="rgSidrkBtn"
                    >
                      {this.props.spcmReducer.spcmPostingAttribute ? (
                        <ImSpinner6 className="icn-spinner"/>
                      ) : null}{" "}
                      {tr["Save"]}
                    </Button>
                    <Button
                      onClick={() => {
                        this.props.insideTableShowFun6(false, "");
                      }}
                      bsSize="small"
                      className="rgSiLigBtn"
                      bsStyle="text"
                    >
                      Close
                    </Button>
                  </ButtonToolbar>
                </Col>
              </Row>
            </form>
          </div>
        ) : (
          <div className="borderForm margin-b-10">
            <form onSubmit={handleSubmit(this.submitForm)}>
              {error == undefined || error == null ? null : (
                <Alert variant="danger" className="errorMessageSec">
                  <p>{error}</p>
                </Alert>
              )}
              <Row>
                <Col md={12}>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">Base Language</Form.Label>
                    <Form.Control disabled value="English" type="text" />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">
                      {/* {this.props.fieldName1} */}
                      Group Name
                    </Form.Label>
                    <Form.Control
                      disabled
                      value={
                        this.props.spcmReducer.objectForTranslation.category_name
                      }
                      componentClass="textarea"
                      rows="2"
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">
                      <span className="rStar"></span> Translated Group Name
                      {/* {this.props.fieldName1}{" "} */}
                     {/* <Button
                        onClick={() =>
                          this.props.translateQuestCat(
                            this.props.spcmReducer.objectForTranslation.category_name,
                            this.props.languageCode
                          )
                        }
                        className="margin-l-10 margin-b-5"
                        title="Google Translate"
                        bsSize="xsmall"
                      >
                        <i className="fa fa-google"></i> Translate
                      </Button>*/}
                    </Form.Label>
                    <Field
                      component={_textArea}
                      name="translated_category"
                      className="form-control"
                      rows="3"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <ButtonToolbar className="black">
                    <Button
                      title={tr["Save"]}
                      type="submit"
                      bsSize="small"
                      bsStyle="primary"
                      className="rgSidrkBtn"
                    >
                      {this.props.spcmReducer.spcmPostingAttribute ? (
                        <ImSpinner6 className="icn-spinner"/>
                      ) : null}{" "}
                      {tr["Save"]}
                    </Button>
                    <Button
                      onClick={() => {
                        this.props.insideTableShowFun6(false, "");
                      }}
                      bsSize="small"
                      bsStyle="text"
                      className="rgSiLigBtn"
                    >
                      Close
                    </Button>
                  </ButtonToolbar>
                </Col>
              </Row>
            </form>
          </div>
        )}
          </div>
      </div>
    );
  }
}

const mapStateToProps = ({ spcmReducer }) => {
  return {
    spcmReducer,
    initialValues: spcmReducer.objectForTranslation
  };
};

TranslationForm = reduxForm({
  form: "QuestionForm",
  validate,
  enableReinitialize: true,
})(TranslationForm);

export default connect(mapStateToProps, {
  spcmLoader,
  translate,
  getTranslationForOfferingBasedOnType,
  translateQuestCat,
  translateQuestText,translateTableHeaders,
  translateQuestPlaceholder,
  translateQuestHelpText,
  translateDisplayText,
  resetReducer,
  getServiceOrderChoices,
  getQuestionsCategory,
  translateResponseValues
})(TranslationForm);
