
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from 'react';
import { Table, Form, ButtonToolbar, Button, Popover, OverlayTrigger, ListGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, change, reduxForm } from 'redux-form';
import {TypeaheadandDropdown, _inputField } from '../../../../common/formFields';
import TableComponent from '../../../../common/ReactTable/TableComponent';
import { disassociateGroupMemberRow, getMembersDropdownValues } from '../../../../../actions/foundation/groupAction';
import axios from "axios";
import Swal from 'sweetalert2';
import { usePreviousValue } from '../../../../../helpers/usePreviousValue';
import XsmUserDetails from "_Commons/WorkItemBoard/xsmEditRequestedBy.js";
import { ImInfo } from 'react-icons/im';
import useDebounce from '../../../../../helpers/useDebounce';
import { PiPencilSimpleBold } from "react-icons/pi";
import {IoCheckmarkOutline, IoClose} from "react-icons/io5";
import Cookies from "universal-cookie";
const cookies = new Cookies();
let cookiesArray = cookies.get('gph');
if (cookiesArray) cookiesArray = cookiesArray.replace('s:', '');
if (cookiesArray) cookiesArray = cookiesArray.substring(0, cookiesArray.lastIndexOf('.'));
cookiesArray = cookiesArray.split("~");
let langDir = cookiesArray[66];

const GroupList = (props) => {
    const tr = useSelector((state) => state.spcmReducer.tr);
    const usersListMember = useSelector(state => state.assoMembersValues);
    const disassociatedMemberRow = useSelector((state) => state.disassociatedMember);
    const [memberDataDetails, setMemberDataDetails] = useState([]);
    const [selectedMember, setSelectedMember] = useState([]);
    const [assoGroupValue, setAssoGroupValue] = useState([]);
    const [companyError, setCompanyError] = useState("");
    const [typedChar, setTypedChar] = useState("");
    const [showGroupManagerField, setShowGroupManagerField] = useState({});
    const [groupManagerValue, setGroupManagerValue] = useState({});
    const windowSize = useSelector((state) => state.windowSize.width);
    const previousValue = usePreviousValue(props)
    const dispatch = useDispatch();
    let groupid = props.id;
    let companyId = props.memberValue

    const onMemberSelection = (selectedMember) => {
        if (selectedMember.length > 0) { 
            setSelectedMember(selectedMember);
            dispatch(change('AssociateGroupMembers', 'member_name', selectedMember));
            setCompanyError("")
          } else {
            setSelectedMember([]);
            dispatch(change('AssociateGroupMembers', 'member_name', ''));
          }
  }
  useEffect(() => {
  
    if (assoGroupValue.length > 0) {
      let memberObject = {}
      assoGroupValue.map((member) => {
        memberObject[member?.userId] = member?.manager;
      })
      setGroupManagerValue(memberObject);
    }
    setMemberDataDetails([]);
  },[assoGroupValue])

    useEffect(() => {
        if ((groupid && groupid !== previousValue?.groupid) || (disassociatedMemberRow == 'Row Deleted')) {
            axios.get("/api/viewAssociatedGroupMembersViaAjax/"+groupid)
            .then((resp) => {
                setAssoGroupValue(resp.data);
                dispatch({ type: 'ASSOCIATED_MEMBER_DELETED', payload: ''});
            })
        }
    }, [groupid, disassociatedMemberRow])

    const onCrossClickMember = () => {
        setSelectedMember([]);
    }

    const saveAssociateMembers = () => {
        if (selectedMember.length === 0) {
            setCompanyError("error")
            return;
        }
        let members = []
        let payload = {}
        selectedMember.forEach((values) => {
            let val = {
                userId:  parseInt(values.id, 10), 
                groupId: parseInt(groupid, 10)
            }
            members.push(val);
          })
          payload = {
            groupMemberList: members
          }

          axios
            .post("/api/saveAssociatedGroupMembersViaAjax", payload)
            .then((response) => {
                axios.get("/api/viewAssociatedGroupMembersViaAjax/"+groupid)
                  .then((resp) => {
                      if (resp.status == 200) {
                          setSelectedMember([]);
                          setMemberDataDetails([]);
                          dispatch(change('AssociateGroupMembers', 'member_name', ''));
                        //   setTimeout(()=>{dispatch(getMembersDropdownValues(companyId,groupid))},500);
                      }
                      setAssoGroupValue(resp.data);
              })
            })
    };

  const saveGroupManger = (user_id) => {
    let members = []
    let payload = {}
        let val = {
            userId:  parseInt(user_id, 10), 
            groupId: parseInt(groupid, 10)
        }
        members.push(val);

      payload = {
        groupMemberList: members
      }

      axios
        .post("/api/saveAssociatedGroupManagers", payload)
        .then((response) => {
          axios.get("/api/viewAssociatedGroupMembersViaAjax/" + groupid)
            .then((resp) => {
              setAssoGroupValue(resp.data);
              if (resp.status == 200) {
                let obj = { ...showGroupManagerField, [user_id]: false}
                setShowGroupManagerField(obj)
              }
            })
        })
  }
  
  const deleteGroupManger = (user_id) => {
    let members = []
    let payload = {}
        let val = {
            userId:  parseInt(user_id, 10), 
            groupId: parseInt(groupid, 10)
        }
        members.push(val);
      payload = {
        groupMemberList: members
      }
      axios
        .post("/api/deleteGroupManagerAssociation", payload)
        .then((response) => {
          axios.get("/api/viewAssociatedGroupMembersViaAjax/" + groupid)
            .then((resp) => {
              setAssoGroupValue(resp.data);
              if (resp.status == 200) {
                let obj = { ...showGroupManagerField, [user_id]: false}
                setShowGroupManagerField(obj)
              }
            })
        })
  }
  const addGroupManager = (props) => {
    let Userdetails = props?.userId;   

    const onGroupManagerChange = (e) => {
      let obj = {...groupManagerValue, [props?.userId]:e.target.value};
      setGroupManagerValue(obj)
    }   

    return (
      <>
        {(showGroupManagerField[Userdetails] == false || showGroupManagerField[Userdetails] == undefined)  ? <div>
          <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{props?.manager}</span>
        </div> :
          <div className='groupMangButton'>
            <Form.Group className="form-group">
                <select
                name={"group_manager"+props.userId}
                component="select"
                className="form-control"
                onChange={(e) => onGroupManagerChange(e)}
                value={groupManagerValue[Userdetails]}
              >
                <option value="No">{tr["No"]}</option>
                <option value="Yes">{tr["Yes"]}</option>
                </select>
            </Form.Group>
            <div className="paHedFilter">
              <Button className="myBt plus fillBtn"
                bsPrefix=' '
                title={groupManagerValue[Userdetails] == "Yes" ? tr['Add Group Manager']:tr['Remove Group Manager']}
                onClick={groupManagerValue[Userdetails] == "Yes" ? (() => { saveGroupManger(Userdetails)}) : (() => { deleteGroupManger(Userdetails)})}>
                <IoCheckmarkOutline />
              </Button>
              <Button
                bsPrefix=' '
                className="myBt cancel fillBtn"
                title={tr['Cancel']}
                onClick={() => {
                let obj = { ...showGroupManagerField, [Userdetails]: false}
                  setShowGroupManagerField(obj)
                  setGroupManagerValue({...groupManagerValue, [Userdetails]: props.manager})
                }}>
                <IoClose/>
              </Button>
              </div>
            </div>}
          </>
        )
    }

    const CellName = (props) => {
        let item = props.row;
        return (
            <>
            <div className="paHedFilter">
              <Button
                className="myBt"
                bsPrefix=' '
                title='Edit' onClick={() => {
                addGroupManager();
                  let obj = { ...showGroupManagerField, [item.userId]: true }
                  setShowGroupManagerField(obj)
              }}>
              <PiPencilSimpleBold />
            </Button>
                </div>
                &nbsp;&nbsp;&nbsp;
           <div className="text-c">
            <i title="Disassociate" onClick={()=>{
            disassociateGroupMembers(item)
            }} className="fa fa-chain-broken"></i>
            </div>
        </>
        )
    };

    const disassociateGroupMembers = (item) => {
        let payload =
        {
            groupMemberList:[{
                userId: item.userId,
                groupId: groupid
            }]
        }
        Swal.fire({
            title: ["Are you sure you want to disassociate this member?"],
            width: 550,
            padding: '15px',
            showDenyButton: true,
            confirmButtonText: tr['Yes'],
            denyButtonText: tr['No'],
          }).then((result) => {
            if (result.isConfirmed) {
                dispatch(disassociateGroupMemberRow(payload));
                // setTimeout(()=>{dispatch(getMembersDropdownValues(companyId,groupid))},500);
            } 
          })
    }
    let { handleSubmit } = props;
    const onRowSelect = () => {
        
    }
    const nameInfo = (props) => {
        const tooltip = (
            <Popover className="userInfoPopup">
              <XsmUserDetails
                userId={props?.userId}
                translator={tr}
                isHeadingVisible={false}
                isPopup={true}
              />
            </Popover>
          );
        return (
            
        <OverlayTrigger
                trigger="click"
                rootClose
                placement={windowSize < 768 ? "auto-start" : (langDir === "rtl" ? "right-start" : "left-start")}
                key={props?.userId}
                overlay={tooltip}
              >
                <span title={props?.fullName} className="cursorPoint">
                  {windowSize < 1300 ? (props?.fullName.slice(0, 12) + "...") : (windowSize > 1440 ? (props?.fullName) :  (props?.fullName.slice(0, 15) + "..."))} <span title="info"><ImInfo /></span>
                </span>
            </OverlayTrigger>
        )
    }
    const tableColumns = [
        {
            name: tr["Name"],
            selector: (row) => {return nameInfo(row)},
            sortable: true,
      },
      {
            name: ["Group Manager"],
            selector: (row) => {return addGroupManager(row) },
            sortable: true,
            maxWidth: "100px",
      },
      {
	      		name: tr["Action"],
		      	sortable: false,
            maxWidth: "100px",
	      		cell: (row) => (
	    		  <CellName row={row} 
		    	  />
		      	),
		      	ignoreRowClick: true,
		  },
    ];
    const filterColumns = ["personCode", "userName", "fullName"];
    
    useEffect(() => {
        let membersData = [],
          consumerData = [],
          myObj = {}
        if (usersListMember && usersListMember.length > 0) {
            consumerData = usersListMember;
              if (Array.isArray(consumerData)) {
                  for (const element of consumerData) {
                  myObj = { id: "", label:"" };
                  myObj.id = element.user_id;
                  myObj.label = element.Full_Name +'|'+ element.Email_Address + '|' + element.Employee_Number +'|'+ element.User_Name;
                  membersData.push(myObj);
                }
                  membersData = Array.from(new Set(membersData));
                  
              } else {
                membersData = [];
            }
              setMemberDataDetails(membersData);
        } else {
            setMemberDataDetails([]);
        }
    },[usersListMember])

    const memberDetailsApi = useDebounce(() => {
        let value = typedChar;
        if (value !== "" && value.length > 3) {
            dispatch(getMembersDropdownValues(companyId, groupid, value));
        }
    });
    
    const onMemberChange = (value) => {
        setTypedChar(value);
        if (value.length > 3) {
          memberDetailsApi();
        } else {
          setMemberDataDetails([]);
        }
    };
    
    const onValueChange = (value) => {
        onMemberChange(value);
    };
    
    return (
        <>
            <Form.Group>
                <Form.Label>{tr["Member Name"]}</Form.Label>
                <Field
                      component={TypeaheadandDropdown}
                      onSelection={onMemberSelection}
                      onInputChange={onValueChange}
                      name="member_name"
                      className="form-control"
                      options={memberDataDetails}
                      selectedOptions={selectedMember}
                      onCrossClick={onCrossClickMember}
                      multiple={true}
                      placeholder={"Search by full name, email, username, employee ID"}
                      errorClass={companyError === "error" ? "error" : ""}
                    ></Field>
            </Form.Group>
            <ButtonToolbar className="black margin-t-5">
                <Button className="rgSidrkBtn smallBtn"
                onClick={handleSubmit(saveAssociateMembers)}>
                    {tr["Add"]}
                </Button>
            </ButtonToolbar>
            <div className="catBordDv leftSeardv integrateHubTable">
                <div className='f-size-16 margin-t-20 margin-b-10 border-b padding-b-5'>
                    {tr["Associated Members"]}
                </div>
            <TableComponent
                data={Array.isArray(assoGroupValue)? assoGroupValue : []}
                columns={tableColumns}
                headerColumns={filterColumns}
                onRowSelect={onRowSelect}
                uniqueKey={'userId'}
                paginationText = {"Show"}
            />
            </div>
        </>
    )
}

export default reduxForm({
    form: 'AssociateGroupMembers',
    destroyOnUnmount: false,
    enableReinitialize: true
})(GroupList);