
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { InputPrefix, TextBox } from "@progress/kendo-react-inputs";

export default function KeyTextBoxCell(props) {
    const { dataItem } = props;
    const field = props.field || "";
    const dataValue = dataItem[field] === null ? "" : dataItem[field];

    const handleChange = (e) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value
            })
        }
    }
    return (
        <td>
            {dataItem.inEdit
                ? (<TextBox
                    prefix={() => (
                        <InputPrefix orientation="horizontal">
                            <span className="k-input-prefix-text">#SC-</span>
                        </InputPrefix>
                    )}
                    value={dataValue}
                    onChange={handleChange}
                >
                </TextBox>)
                : dataValue
            }
        </td>
    )
}