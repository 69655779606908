
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { navigationHooks } from '../../../helpers/NavigationHook';
// import { browserHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { getAllRuleConfigurationsData } from '../../../actions/amsActions/amsActions.js';
import Cookies from 'universal-cookie';
import Breadcrumbs from '../../common/header/breadcrumbs';
import {LuFilterX} from "react-icons/lu";
import {HiPlus, HiOutlineRefresh} from "react-icons/hi";

// import { Row, Col, PageHeader,Button} from 'react-bootstrap';

const cookies = new Cookies();
let cookieString = cookies.get('gph');

if (cookieString) cookieString = cookieString.replace('s:', '');
if (cookieString) cookieString = cookieString.substring(0, cookieString.lastIndexOf('.'));
let cookieArray = cookieString.split("~");
let sla_work_holiday = cookieArray[35];
let homepagelocation = cookieArray[0];

if (homepagelocation != "/home")
	homepagelocation = "/" + homepagelocation;
class AmsHomeHeader extends React.Component {
	constructor(props) {
		super(props);
	}

	loadHome() {
		navigationHooks.navigate(homepagelocation);
	}

	clearAllFiltersSLA() {
		// console.log('this.props - ', this.props);
		this.props.setNameSearchInput("");
		this.props.setConsumerCompanySearchInput("");
		this.props.setServiceCiSearchInput("");
		this.props.setSupCompanySearchInput("");
		this.props.setSelectedModuleValue([]);
		this.props.setSelectedStatusValue([]);
		this.props.setSelectedAssignmentForValue([]);
		this.props.setSelectedSupGroupValue([]);
		this.props.setStartAndEndDate({}, {});
		// this.props.dateRangePickerRef != undefined ? this.props.dateRangePickerRef.endDate = moment() : "";
		// this.props.dateRangePickerRef != undefined ? this.props.dateRangePickerRef.startDate = moment() : "";
		if(typeof this.props.dateRangePickerRef == "object"){
			this.props.dateRangePickerRef.endDate = moment();
			this.props.dateRangePickerRef.startDate = moment()
		}
		//(typeof this.props.dateRangePickerRef == "object") ? this.props.dateRangePickerRef.endDate = moment() : "";
		//(typeof this.props.dateRangePickerRef == "object") ? this.props.dateRangePickerRef.startDate = moment() : "";
		this.props.updatePagination(0, 10);
		let str = {};
		str.searchByList = "";
		str.multipleValueList = "";
		str.patternList = "";
		str.orderBy = "desc";
		str.sortBy = "modifiedOn";
		str.currentPage = 0;
		str.size = 10;
		this.props.setSearchParams(str, '', '', '', [], [], [],[]);
		this.props.getAllRuleConfigurationsData(str, [], [], [],[]);
		this.props.showRightSide(false);
		this.props.isClearAllFilterFunc(false);
	}

	refreshTableSLA() {
		this.props.showRightSide(false);
		this.props.updatePagination(0, 10);
		let searchParams = {};
		searchParams.searchByList = this.props.searchKey;
		searchParams.multipleValueList = this.props.searchValue;
		searchParams.patternList = this.props.pattern;
		searchParams.currentPage = 0;
		searchParams.size = 10;
		searchParams.orderBy = "desc";
		searchParams.sortBy = "modifiedOn";
		this.props.getAllRuleConfigurationsData(searchParams,this.props.moduleValue, this.props.assignmentForValue, this.props.supGroupValue, this.props.statusValue);
	}

	render() {
		let metaAssignmentRules = !_.isEmpty(this.props.allRuleConfigurationsData) ? this.props.allRuleConfigurationsData.meta : {};
		let sizeAssignmentRules = metaAssignmentRules && metaAssignmentRules.size ? metaAssignmentRules.size : 0;
		let currentPageAssignmentRules = metaAssignmentRules && metaAssignmentRules.currentPage ? metaAssignmentRules.currentPage : 0;
		let startLimitAssignmentRules = sizeAssignmentRules * (currentPageAssignmentRules) + 1;
		let endLimitAssignmentRules = sizeAssignmentRules * (currentPageAssignmentRules + 1);
		let recordCountAssignmentRules = metaAssignmentRules && metaAssignmentRules.recordCount ? metaAssignmentRules.recordCount : 0;
		const { tr } = this.props;
		return (
			<div>
				<Row className="margin-b-15">
					<Col lg={8} md={8} sm={9} xs={8}>
					<Breadcrumbs activePageName="Assignment Rules"/>
					</Col>

					<Col lg={4} md={4} sm={3} xs={4}>
						{recordCountAssignmentRules != 0 ? <div className="tabDatacount">{startLimitAssignmentRules}-{endLimitAssignmentRules > recordCountAssignmentRules ? endLimitAssignmentRules = recordCountAssignmentRules : endLimitAssignmentRules} of {recordCountAssignmentRules}</div> : null}
					</Col>
				</Row>
				<Row className="margin-b-15">
					<Col lg={4} md={6} sm={6} xs={12}>
						<h2 bsClass="" className="sPageHeading1">{this.props.tr['Assignment Rules']} </h2>
					</Col>
					<Col lg={8} md={6} sm={6} xs={12}>
						<div className="paHedFilter">
							<NavLink role="button" className="myBt plus fillBtn" title={this.props.tr['Add']} to="/amsCreateRules" ><HiPlus/></NavLink>
							<div className="overlayDv">
								{this.props.isClearAllfilter ? <Button title="Clear All Filters" role="button" bsPrefix=' ' className="myBt clrbtn" onClick={() => { this.clearAllFiltersSLA() }}><LuFilterX/></Button> : ""}
							</div>
							<Button bsPrefix=' ' title={this.props.tr["Refresh"]} role="button"  className="myBt" onClick={() => { this.refreshTableSLA() }}><HiOutlineRefresh className="refreshFlip"/></Button>
						</div>
					</Col>
				</Row>
			</div>

		);
	}
}


export function mapStateToProps({ allRuleConfigurationsData, }) {
	return { allRuleConfigurationsData };
}

export default connect(mapStateToProps, { getAllRuleConfigurationsData })(AmsHomeHeader);

