
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';
export const ciClass = (state = initialState.ciClass, action) => {
	switch (action.type) {
		case 'LOAD_CICLASS_SUCCESS':
			return action.ciClass.data;
		case 'LOAD_CICLASS_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}

export const ciName = (state = {}, action) => {
	switch (action.type) {
		case 'LOAD_CICNAME_SUCCESS':
			return action.ciName.data;
		default:
			return state;
	}
}


export const ciStatus = (state = initialState.ciStatus, action) => {
	switch (action.type) {
		case 'LOAD_CISTATUS_SUCCESS':
			return action.ciStatus.data;
		case 'LOAD_CISTATUS_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}

export const ciAllStatus = (state = initialState.ciAllStatus, action) => {
	switch (action.type) {
		case 'LOAD_CIALLSTATUS_SUCCESS':
			return action.ciAllStatus.data;
		case 'LOAD_CIALLSTATUS_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}

export const ciSubStatus = (state = initialState.ciSubStatus, action) => {
	switch (action.type) {
		case 'LOAD_CISUBSTATUS_SUCCESS':
			return action.ciSubStatus.data;
		case 'LOAD_CISUBSTATUS_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}

export const ciMetallic = (state = initialState.ciMetallic, action) => {
	switch (action.type) {
		case 'LOAD_CIMETALLIC_SUCCESS':
			return action.ciMetallic.data;
		case 'LOAD_CIMETALLIC_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}

export const ciEnv = (state = initialState.ciEnv, action) => {
	switch (action.type) {
		case 'LOAD_CIENV_SUCCESS':
			return action.ciEnv.data;
		case 'LOAD_CIENV_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}

export const ciResidualRisk = (state = initialState.ciResidualRisk, action) => {
	switch (action.type) {
		case 'LOAD_CIRESIDUALRISK_SUCCESS':
			return action.ciResidualRisk.data;
		case 'LOAD_CIRESIDUALRISK_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}

export const approvalsAutoAction = (state = initialState.approvalsAutoAction, action) => {
	switch (action.type) {
		case 'LOAD_APPROVALSAUTOACTION_SUCCESS':
			return action.approvalsAutoAction.data;
		case 'LOAD_APPROVALSAUTOACTION_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}

export const ciManr = (state = initialState.ciManr, action) => {
	switch (action.type) {
		case 'LOAD_CIMANR_SUCCESS':
			return action.ciManr.data;
		case 'LOAD_CIMANR_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}

export const ciDataset = (state = initialState.ciDataset, action) => {
	switch (action.type) {
		case 'LOAD_CIDATASET_SUCCESS':
			return action.ciDataset.data;
		case 'LOAD_CIDATASET_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}

export const ciCompany = (state = initialState.ciCompany, action) => {
	switch (action.type) {
		case 'LOAD_CICOMPANY_SUCCESS':
			return action.ciCompany.data;
		case 'LOAD_CICOMPANY_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}


export const companyReportData = (state = initialState.companyReportData, action) => {
	switch (action.type) {
		case 'LOAD_REPORT_COMPANY_SUCCESS':
			return action.companyReportData.data;
		case 'LOAD_REPORT_COMPANY_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}

export const ciLocation = (state = initialState.ciLocation, action) => {
	switch (action.type) {
		case 'LOAD_CILOCATION_SUCCESS':
			return action.ciLocation.data;
		case 'LOAD_CILOCATION_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}
export const ciGroup = (state = initialState.ciGroup, action) => {
	switch (action.type) {
		case 'LOAD_CIGROUP_SUCCESS':
			return action.ciGroup.data;
		case 'LOAD_CIGROUP_FAILURE':
			return action.failureMessage;
		case 'EMPTY_CIGROUP_SUCCESS':
			return [];
		default:
			return state;
	}
}
export const ciGroupForTaskPlan = (state = initialState.ciGroupForTaskPlan, action) => {
	switch (action.type) {
		case 'LOAD_CIGROUP_FORTASKPLAN_SUCCESS':
			return action.ciGroupForTaskPlan.data;
		default:
			return state;
	}
}
export const ciInd = (state = initialState.ciInd, action) => {
	switch (action.type) {
		case 'LOAD_CIIND_SUCCESS':
			return action.ciInd.data;
		case 'LOAD_CIIND_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}

export const CIEditDetails = (state = initialState.CIEditDetails, action) => {
	switch (action.type) {
		case 'LOAD_CIEDITDETAILS_SUCCESS':
			return action.CIEditDetails.data;
		case 'LOAD_CIEDITDETAILS_FAILURE':
			return action.failureMessage;
		case 'EMPTY_CIEDITDETAILS':
			return action.CIEditDetails;
		default:
			return state;
	}
}

export const CIModelName = (state = initialState.CIModelName, action) => {
	switch (action.type) {
		case 'LOAD_CI_MODEL_NAME':
			return action.CIModelName.data;
		default:
			return state;
	}
}

export const ciCat = (state = initialState.ciCat, action) => {
	switch (action.type) {
		case 'LOAD_CICAT_SUCCESS':
			return action.ciCat.data;
		case 'LOAD_CICAT_FAILURE':
			return action.failureMessage;
		case 'EMPTY_CICAT_SUCCESS':
			return [];
		default:
			return state;
	}
}

export const ciType = (state = initialState.ciType, action) => {
	switch (action.type) {
		case 'LOAD_CITYPE_SUCCESS':
			return action.ciType.data;
		case 'LOAD_CITYPE_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}

export const ciSCat = (state = initialState.ciSCat, action) => {
	switch (action.type) {
		case 'LOAD_CISCAT_SUCCESS':
			return action.ciSCat.data;
		case 'LOAD_CISCAT_FAILURE':
			return action.failureMessage;
		case 'EMPTY_CISCAT_SUCCESS':
			return [];
		default:
			return state;
	}
}

export const ciResolutionCat = (state = initialState.ciResolutionCat, action) => {
	switch (action.type) {
		case 'LOAD_CATSUBCAT_SUCCESS':
			return action.CatSubCatRes;
		default:
			return state;
	}
}

export const ciAtt = (state = initialState.ciAtt, action) => {
	switch (action.type) {
		case 'LOAD_CIATT_SUCCESS':
			return action.ciAtt.data;
		case 'LOAD_CIATT_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}
//LOAD_VENDOR_SUCESS
export const vendorSuccess = (state = initialState.ciSupCompany, action) => {
	switch (action.type) {
		case 'LOAD_VENDOR_SUCESS':
			return action.ciSupCompany.data;
		case 'LOAD_CISUPCOMPANY_FAILURE':
			return action.failureMessage;
		case 'EMPTY_CISUPCOMPANY_SUCCESS':
			return [];
		default:
			return state;
	}
}



export const ciSupCompany = (state = initialState.ciSupCompany, action) => {
	switch (action.type) {
		case 'LOAD_CISUPCOMPANY_SUCCESS':
			return action.ciSupCompany.data;
		case 'LOAD_CISUPCOMPANY_FAILURE':
			return action.failureMessage;
		case 'EMPTY_CISUPCOMPANY_SUCCESS':
			return [];
		default:
			return state;
	}
}


export const cmdbSupportCompany = (state = initialState.cmdbSupportCompany, action) => {
	switch (action.type) {
		case 'LOAD_CMDBSUPPORTCOMPANY_SUCCESS':
			return action.cmdbSupportCompany.data;
		default:
			return state;
	}
}

export const cmdAssetsBasedOnClassList = (state = initialState.cmdbClassBasedAssetsList, action) => {
	switch (action.type) {
		case 'LOAD_CMDB_CLASSBASEDASSETS_LIST_SUCCESS':
			return action.cmdbClassBasedAssetsList.data;
		default:
			return state;
	}
}

export const ciCategories = (state = initialState.ciCategories, action) => {
	switch (action.type) {
		case 'LOAD_CMDBCATEGORIES_LIST_SUCCESS':
			return action.ciCategories.data;
		default:
			return state;
	}
}

export const ciUserBasedLocation = (state = initialState.ciUserBasedLocation, action) => {
	switch (action.type) {
		case 'LOAD_CIUSERBASEDLOCATION_SUCCESS':
			return action.ciUserBasedLocation.data;
		case 'LOAD_CIUSERBASEDLOCATION_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}

export const FdnTagList = (state = [], action) => {
	
	if (action.type == 'FDN_TAG_LIST') {
		return action.payload;
	}
	return state;	
};

export const ciSubStatusCopy = (state = initialState.ciSubStatusCopy, action) => {
	switch (action.type) {
		case 'LOAD_CISUBSTATUS_SUCCESS_COPY':
			return action.ciSubStatusCopy.data;
		case 'LOAD_CISUBSTATUS_FAILURE_COPY':
			return action.failureMessage;
		default:
			return state;
	}
}