
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Row, Col, Card, Table } from "react-bootstrap";
// import { loadRelatedAssetWorkDetails } from "../../../actions/changeManagement/cmdbchangeRelatedWorkAction";
import { loadRelatedOfferingDataDetails } from "../../../../actions/changeManagement/cmdbchangeRelatedDataAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { GLOBAL } from "_Globals";
import axios from "axios";
import ListLoader from "../../../common/loaders/ListLoader";
import {SubmissionError } from 'redux-form';
import {
  getAllServiceListForSearch,
  getAllServicesList,
  getAllCompanyServicesList,
  getAllCompanyServicesListForSearch,
} from "../../../../actions/spcmActions";
import swal from "sweetalert";
import {IoClose} from "react-icons/io5";

const MoreArrow = (
  <i title="More" className="fa fa-angle-double-down f-size-15"></i>
);

class CmdbRelateAssets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserData: false,
      defaultServiceSelected: [],
      searchText: "",
      queryArray: [],
      queryBy: "Service",
      query: "",
      toggleList: false,
      postLoader: false,
    };
    this.pushToQueryArray = this.pushToQueryArray.bind(this);
    this.toggleListFunc = this.toggleListFunc.bind(this);
  }

  componentWillMount() {
    let ci_id = this.props.ci_id;
    let company_id = this.props.cieditdetails;
    this.props.loadRelatedOfferingDataDetails(ci_id, "SPCM");
    GLOBAL.relatedtextdisplay = (
      <div className="rPageHeading">
        <div className="offNam margin-t-5 margin-b-5">
          {this.props.translator["Related Offerings"]}
        </div>
      </div>
    );
  }

  toggleListFunc(value) {
    this.setState({ toggleList: value });
  }
  postRelatedData(Offering_id, Offering_name, created_by) {
    this.setState({ postLoader: true });
    let ci_id = this.props.ci_id;

    let payloadObj ={
      "ci_id": parseInt(ci_id, 10),    
      "offering_id_related": Offering_id,
      "offering_id_number": Offering_name,   
      "created_by": created_by,
      "module": "SPCM",     
      "relationship_type": "",
      "created_at": null,
      "ci_id_reported": null
    }
    axios
      .post("/api/config_items/relationship", /*{
        ci_id: parseInt(ci_id, 10),
        ci_id_related: Offering_id,
        ci_name_related: Offering_name,
        r_id: null,
        created_by: created_by,
        module: "SPCM",
        relationship_type: "",
        ci_id_number: Offering_name,
        created_at: null,
        ci_id_reported: null,
      }*/
      payloadObj
      )
      .then((response) => {
        this.setState({ postLoader: false });
        if (response == 400) {
          console.log(
            "message",
            this.props.cmdbChangeRelatedDataDetails.message
          );
        } else {
          GLOBAL.relatedtextdisplay = (
            <div className="rPageHeading">
              <div className="offNam margin-t-5 margin-b-5">
                {this.props.translator["Related Offerings"]}
              </div>
            </div>
          );
          this.props.loadRelatedOfferingDataDetails(ci_id, "SPCM");
          this.toggleListFunc(false);
        }
      })
      .catch((error) => {
        this.setState({ postLoader: false });
        throw new SubmissionError({ _error: error.response.message });
      });
  }

  renderOfferingList(relatedLogData) {
    console.log("relatedLogData", relatedLogData);
    if (relatedLogData.length == 0 && !this.props.showloader) {
      return (
        <tr>
          <td width="100%">
            <div>{this.props.translator["Data not available"]}</div>
          </td>
        </tr>
      );
    } else {
      return relatedLogData.map((relatedObj, index) => {
        return (
          <Card>
            <Card.Body>
              <Row>
                <Col md={10} className="padding-r-0">
                  <Card.Title className="blue margin-0">
                    {relatedObj.OFFERING_NAME} - -
                    <span
                      className="colorStOrange cursorPoint"
                      onClick={this.showUserDetails}
                    >
                      {" "}
                      {relatedObj.CREATEDBY_NAME}
                    </span>
                  </Card.Title>
                  <div className="black fw-300 cTxt"></div>
                </Col>
                <Col md={2} className="text-c">
                  <a
                    onClick={this.postRelatedData.bind(
                      this,
                      relatedObj.OFFERING_ID,
                      relatedObj.OFFERING_NAME,
                      relatedObj.CREATEDBY_NAME
                    )}
                    title={this.props.translator["Relate"]}
                    disabled={this.state.postLoader}
                  >
                    <i className="fa fa-chevron-circle-right f-size-24 colorStBlue"></i>
                  </a>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        );
      });
    }
  }

  deleteRelation(ci_id, ci_id_related) {
    axios
      .delete(
        "/api/config_items/relationship" +
          "?ci_id=" +
          ci_id +
          "&ci_id_related=" +
          ci_id_related +"&module=SPCM"
      )
      .then((response) => {
        if (!response || response.status != 200) {
          swal({
            text: "Deletion failed.",
            button: this.props.translator["OK"],
          });    
        } else {
          swal({
            text: "Deleted Relation",
            button: this.props.translator["OK"],
          });    
          let ci_id = this.props.ci_id;
          this.props.loadRelatedOfferingDataDetails(ci_id, "SPCM");

          GLOBAL.relatedtextdisplay = (
            <div className="rPageHeading">
              <div className="offNam margin-t-5 margin-b-5">
                {this.props.translator["Related Offerings"]}
              </div>
            </div>
          );
        }
      });
  }

  renderAttList(attributeData) {
    if (!attributeData) {
      return null;
    }
    return attributeData.map((objjectData, i) => {
      let reldate = objjectData.CREATED_AT;
      let splitdate = reldate.split(".")[0];

      if (objjectData.CI_ID != null && objjectData.CI_ID != "")
        return (
          <tr>
            <td>{objjectData.CI_ID_NUMBER}</td>
            <td>{splitdate}</td>
            <td>{objjectData.CREATED_BY}</td>
          </tr>
        );
      else return <tr></tr>;
    });
  }

  renderRelatedDataLog(relatedDataLog) {

    if (relatedDataLog.length == 0 && !this.props.showLoader) {
      return <div>{this.props.translator["Data not available"]}</div>;
    } else {
      return (
        <Table responsive striped bordered condensed hover>
          <thead>
            <tr>
              <th>Offering Name</th>
              <th>{this.props.translator["Created On"]}</th>
              <th>{this.props.translator["Related By"]}</th>
            </tr>
          </thead>
          <tbody>{this.renderAttList(relatedDataLog)}</tbody>
        </Table>
      );
    }
  }

  onSearchClick(e) {
    let company_id = this.props.cieditdetails;
    this.props.getAllCompanyServicesListForSearch(
      company_id,
      this.state.queryArray
    );
    this.toggleListFunc(true);
    GLOBAL.relatedtextdisplay = (
      <div className="rPageHeading">
        <div className="offNam margin-t-5 margin-b-5"></div>
      </div>
    );
  }

  pushToQueryArray(arr, obj) {
    if (obj.queryValue !== "") {
      const index = arr.findIndex((e) => e.queryBy === obj.queryBy);
      if (index === -1) {
        arr.push(obj);
      } else {
        arr[index] = obj;
      }
    } else {
      const index = arr.findIndex((e) => e.queryBy === obj.queryBy);

      if (index > -1) {
        arr.splice(index, 1);
      }
    }
  }
  loadrelateddata() {
    this.toggleListFunc(false);
    GLOBAL.relatedtextdisplay = (
      <div className="rPageHeading">
        <div className="offNam margin-t-5 margin-b-5">
          {this.props.translator["Related Offerings"]}
        </div>
      </div>
    );
  }

  onAddFilterTextChange(e, typeAheadInputChange) {
    var obj = {};
    obj.queryBy = "Service";
    if (typeAheadInputChange === "typeAheadInputChange") {
      obj.queryValue = e;

      this.setState({ defaultServiceSelected: [{ OFFERING_NAME: e }] });
    } else {
      if (e.length === 0) {
        obj.queryValue = "";
      } else {
        obj.queryValue = e[0].OFFERING_NAME;
      }
      this.setState({ defaultServiceSelected: e });
    }
    this.setState({ defaultServiceSelected: e });
    let arrayvar = this.state.queryArray.slice();
    this.pushToQueryArray(arrayvar, obj);
    this.setState({ queryArray: arrayvar });
  }

  render() {
    const allserviceListForSearch = this.props.spcmReducer.serviceListCompany;
    return (
      <div>
        <Row>
          <Col md={12}>
          <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
            <ul>
              <li>
              <button
                  type='button'
                  title="Minimize the right panel"
                  bsClass=""
                  bsStyle=""
                  className="closerightPanelBtn"
                  onClick={() => {
                  this.props.rightEditPanel(false);
                  }}
              >
                  <IoClose/>
              </button>
              </li>
            </ul>
          </div>
            {GLOBAL.relatedtextdisplay}</Col>
          <Col md={12}>
            {this.state.toggleList ? (
              <a
                onClick={this.loadrelateddata.bind(this)}
                className="backListRelOff"
              >
                <i className="fa fa-angle-double-left doublearrow"></i>
                {this.props.translator["Back to List"]}
              </a>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <div className="rBoxGap">
          {this.state.toggleList ? (
            this.props.showLoader ? (
              <ListLoader />
            ) : (
              this.renderOfferingList(
                this.props.spcmReducer.serviceListCompanyForSearch
              )
            )
          ) : this.props.showLoader ? (
            <ListLoader />
          ) : (
            this.renderRelatedDataLog(this.props.cmdbChangeRelatedDataDetails)
          )}
        </div>
      </div>
    );
  }
}

export function mapStateToProps({
  relatedAssetsWorkDetails,
  cmdbChangeRelatedDataDetails,
  spcmReducer,
  showLoader,
}) {
  return {
    relatedAssetsWorkDetails,
    cmdbChangeRelatedDataDetails,
    spcmReducer,
    showLoader: showLoader.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getAllCompanyServicesList,
      getAllCompanyServicesListForSearch,
      getAllServicesList,
      loadRelatedOfferingDataDetails,
      getAllServiceListForSearch,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CmdbRelateAssets);
