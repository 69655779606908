
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export const reasonCode = (state = [], action)=> {
	switch (action.type){
		case 'LOAD_BREAKFIXREASONCODE_SUCCESS':
			return action.reasonCode.data;
			default:
			return state;
	}
}

export const complexity = (state = [], action)=> {
	switch (action.type){
		case 'LOAD_BREAKFIXCOMPLEXITY_SUCCESS':
			return action.complexity.data;
			default:
			return state;
	}
}
