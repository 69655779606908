
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import {Row,Col,Form} from 'react-bootstrap';
import CmdbCreateHeader from './CmdbCreateHeader.js';
import CmdbCreateForm from './CmdbCreateForm.js';
//import XsmBreakFixAction from './rightSide.js';
import CmdbProcessHierarchy from './CmdbProcessHierarchy.js';
//import FooterMain from '../../../components/footerCrp.js';
import { getTranslation} from '../../../actions/spcmActions.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {emptyCISupCompany, emptyCIGroup, emptyCICatReducer, emptyCISCatReducer} from '../../../actions/cmdb/lovAction.js';
import { navigationHooks } from '../../../helpers/NavigationHook.js';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import "_Css/form/_form.scss";
const cookies = new Cookies();
import Breadcrumbs from "../../common/header/breadcrumbs.js";
let homepagelocation = cookies.get('gph');
if (homepagelocation) homepagelocation = homepagelocation.replace('s:', '');
if (homepagelocation) homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];

if (homepagelocation != "/home")
  homepagelocation = "/" + homepagelocation;

class CmdbCreateIndex extends React.Component {
	constructor(props) {
		super(props);
		this.state ={
			subMenu: '4',
			showHideAlfy:false,
			showHideRightNav:false,
			actionView:'assetgraph'
		};
		this.showHideAlfy = this.showHideAlfy.bind(this);
		this.showHideRightNav = this.showHideRightNav.bind(this);
		this.changeActionView = this.changeActionView.bind(this);
		this.showRightPaneAction = this.showRightPaneAction.bind(this);
		this.props.getTranslation(this.props.lang);
		
		this.ciId = navigationHooks.params.ciId
	}
	
	componentWillUnmount() {
		this.props.emptyCISupCompany();
		this.props.emptyCIGroup();
		this.props.emptyCICatReducer();
		this.props.emptyCISCatReducer();
	}

	
	showHideAlfy(value){
		this.setState({showHideAlfy: value});
	};
	showHideRightNav(value){
		this.setState({showHideRightNav:!this.state.showHideRightNav});
	};
	changeActionView(actionSelected){
		this.setState({
			actionView:actionSelected
		})
		//alert("actionSelected"+actionSelected);
	}

	showRightPaneAction(actionView) {
		let ref = this;
		switch (actionView) {

			case 'assetgraph':
				return (
					<CmdbProcessHierarchy translator={this.props.tr} ciId={this.ciId}/>
				);

		}
	}
   render() {
      return (
		<main>
			<div className="container-fluid margin-t-10 margin-b-15">
					
					<Breadcrumbs activePageName="Create" parentPageurl='/cmdblist' parentPageName='Asset/Config Board'/>
				</div>
			<div bsClass="" className='container-fluid minHeightWIB'>
			<Form>
				{/* Home Page blue bar Section Start*/}
				<CmdbCreateHeader translator={this.props.tr} changeActionView={this.changeActionView}/>
				{/* Home Page blue bar Section end*/}

				<Row className="row-eq-height">
					<Col md={8} sm={12} xs={12}>
							<CmdbCreateForm translator={this.props.tr}/>
					</Col>
					<Col md={4} sm={12} xs={12} className="colRightHeight">
						<div className="stickyArea">
							{this.showRightPaneAction(this.state.actionView)}
						</div>
					</Col>
				</Row>
			</Form>
			</div>
			{/* {homepagelocation == "/unauthorized" || homepagelocation === "/userActivationPending" ? null : <FooterMain isLogout={GLOBAL.isLogout} />} */}
		</main>
      );
   }
}

export function mapStateToProps(state){

	return {
    //spcmReducer:state.spcmReducer,
	tr:state.spcmReducer.tr,
	lang:state.spcmReducer.lang
  };
}
function mapDispatchToProps(dispatch){
	return bindActionCreators({
    getTranslation, emptyCISupCompany, emptyCIGroup, emptyCICatReducer, emptyCISCatReducer
  }, dispatch);
}
export default connect(mapStateToProps,mapDispatchToProps)(CmdbCreateIndex);

//export default BreakFix;
