
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import {GLOBAL} from '../../components/Globals';

export function loadSpecificUserDetails(){
  let str={};
  str.user_id="";
  str = JSON.stringify(str);
  return function(dispatch){
    return axios.get(GLOBAL.userSpecificDetailsURL,{headers:{'query':str}})
    .then(response => {
      dispatch(
        {type: 'SPECIFIC_USER_DETAILS_SUCCESS', payload: response.data}
      );
    })
    .catch((err) => {
      console.log(err);
    });
  }
}

export function setwindowSize(screenDetail){
	return(dispatch) =>{
		dispatch({
			type:'LOAD_WINDOW_SIZE',
			screenDetail
		});
	};
}
