
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { Component } from "react";
import { Alert } from "react-bootstrap";
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import moment from "moment";
import AutoSuggest from "react-autosuggest";
import axios from "axios";
import swal from "sweetalert";
import { GLOBAL } from "_Globals";
import { getDelegationList } from "_Actions/delegations/delegationsActions";
import { connect } from "react-redux";
import ListLoader from "_Commons/loaders/ListLoader";
let selectOptions = [];
let consumerData = [];
let myObj = [];
let consumerDataDetails = [];
let delegatee = "";
let selectOptions2 = [];
let consumerData2 = [];
let myObj2 = [];
let consumerDataDetails2 = [];
let delegator = "";
import { _dateTimeField } from "../serviceDesk/common/formFields";
import { Field, reduxForm } from "redux-form";
import {ImSpinner6} from "react-icons/im";
import "_Css/form/_form.scss";

const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

const getSuggestions = (value, options) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  if (inputLength > 2) {
    selectOptions = options;
  }
  return inputLength < 2
    ? []
    : selectOptions.filter(
        (option) => option.label.toLowerCase().indexOf(inputValue) > -1
      );
};

const getSuggestionValue = (suggestion) =>
  suggestion.label.indexOf == -1
    ? ""
    : suggestion.label.substring(0, suggestion.label.indexOf("|"));
function renderSuggestion(suggestion) {
  let suggestionlabel = [];
  suggestionlabel = suggestion.label.split("|");
  // delegatee = suggestion.value;
  return <span>{suggestion.label}</span>;
}

class SearchDelegatee extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onSuggestionsFetchRequested =
      this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested =
      this.onSuggestionsClearRequested.bind(this);
    this.state = {
      value: "",
      suggestions: [],
    };
  }
  onChange(event, { newValue }) {
    this.setState({
      value: newValue,
    });
    if (newValue.length == 0) {
      delegatee = "";
    }
  }
  onSuggestionsFetchRequested({ value }) {
    if (value.length > 4) {
      let strObj = {};
      strObj.userId = "";
      strObj.like = value;
      strObj = JSON.stringify(strObj);
      api
        .get(GLOBAL.breakFixConsumerUrlNew, { headers: { query: strObj } })
        .then((response) => {
          consumerData = response.data;
          consumerDataDetails = [];
          for (let i = 0; i < consumerData.length; i++) {
            myObj = { value: "", label: "" };
            myObj.value = consumerData[i].userId;
            myObj.label =
              consumerData[i].fullname +
              "|" +
              consumerData[i].employeeNumber +
              "|" +
              consumerData[i].email;
            consumerDataDetails.push(myObj);
          }
          consumerDataDetails = Array.from(new Set(consumerDataDetails));
          this.setState({
            suggestions: getSuggestions(value, consumerDataDetails),
          });
        });
    } else {
      this.setState({
        suggestions: [],
      });
    }
  }
  onSuggestionsClearRequested() {
    this.setState({
      suggestions: [],
    });
  }
  handleKeyPress(event) {
    // if( event.charCode == "32"){
    //   event.preventDefault();
    // }
  }
  onSelect(event, { suggestion }) {
    // console.log("suggestions",suggestion);
    delegatee = suggestion.value;
  }

  render() {
    const { delegatee } = this.props;
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: delegatee,
      className: "form-control",
      value,
      onChange: this.onChange,
      onBlur: this.validateField,
      onKeyPress: this.handleKeyPress,
    };
    return (
      <AutoSuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={this.onSelect}
      />
    );
  }
}

const getSuggestions2 = (value, options) => {
  const inputValue2 = value.trim().toLowerCase();
  const inputLength2 = inputValue2.length;
  if (inputLength2 > 2) {
    selectOptions2 = options;
  }
  return inputLength2 < 2
    ? []
    : selectOptions2.filter(
        (option) => option.label.toLowerCase().indexOf(inputValue2) > -1
      );
};

const getSuggestionValue2 = (suggestion) =>
  suggestion.label.indexOf == -1
    ? ""
    : suggestion.label.substring(0, suggestion.label.indexOf("|"));
function renderSuggestion2(suggestion) {
  let suggestionlabel = [];
  suggestionlabel = suggestion.label.split("|");
  // delegator = suggestion.value;
  return <span>{suggestion.label}</span>;
}

class SearchDelegator extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onSuggestionsFetchRequested =
      this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested =
      this.onSuggestionsClearRequested.bind(this);
    this.state = {
      value: "",
      suggestions: [],
    };
  }
  onChange(event, { newValue }) {
    this.setState({
      value: newValue,
    });
    if (newValue.length == 0) {
      delegator = "";
    }
  }
  onSuggestionsFetchRequested({ value }) {
    if (value.length > 4) {
      let strObj = {};
      strObj.userId = "";
      strObj.like = value;
      strObj = JSON.stringify(strObj);
      api
        .get(GLOBAL.breakFixConsumerUrlNew, { headers: { query: strObj } })
        .then((response) => {
          consumerData2 = response.data;
          consumerDataDetails2 = [];
          for (let i = 0; i < consumerData2.length; i++) {
            myObj2 = { value: "", label: "" };
            myObj2.value = consumerData2[i].userId;
            myObj2.label =
              consumerData2[i].fullname +
              "|" +
              consumerData2[i].employeeNumber +
              "|" +
              consumerData2[i].email;
            consumerDataDetails2.push(myObj2);
          }
          consumerDataDetails2 = Array.from(new Set(consumerDataDetails2));
          this.setState({
            suggestions: getSuggestions2(value, consumerDataDetails2),
          });
        });
    } else {
      this.setState({
        suggestions: [],
      });
    }
  }
  onSuggestionsClearRequested() {
    this.setState({
      suggestions: [],
    });
  }
  handleKeyPress(event) {
    // if( event.charCode == "32"){
    //   event.preventDefault();
    // }
  }
  onSelect2(event, { suggestion }) {
    // console.log("suggestions",suggestion);
    delegator = suggestion.value;
  }
  render() {
    const { delegator } = this.props;
    const value2 = this.state.value;
    const suggestions2 = this.state.suggestions;
    const inputProps2 = {
      placeholder: delegator,
      className: "form-control",
      value: value2,
      onChange: this.onChange,
      onBlur: this.validateField,
      onKeyPress: this.handleKeyPress,
    };
    return (
      <AutoSuggest
        suggestions={suggestions2}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue2}
        renderSuggestion={renderSuggestion2}
        inputProps={inputProps2}
        onSuggestionSelected={this.onSelect2}
      />
    );
  }
}

let DelegationForm = class DelegationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "",
      delegateFromDate: "",
      delegatee: "",
      delegator: "",
      delegatetoDate: "",
      moduleId: "",
      delegateeSearchError: "",
      delegationForError: "",
      statusError: "",
      showAlertMessage: false,
      delegateeid: "",
      loader: false,
      adminRole: false,
      moduleError: "",
      delegatorid: "",
    };
    this.setFormProperties = this.setFormProperties.bind(this);
    this.saveDelegation = this.saveDelegation.bind(this);
  }

  componentWillUnmount() {
    delegatee = "";
    delegator = "";
  }
  componentDidMount() {
    let ref = this;
    let roleArr = ref.props.role_id.split(",");
    let adminRole = roleArr.includes("1");
    ref.setState({ adminRole: adminRole });

    if (ref.props.delegate) {
      const delegate = ref.props.delegate;
      ref.setState({ status: delegate.statusidforReact });
      ref.setState({ moduleId: delegate.moduleidforReact });
      ref.setState({ delegatee: delegate.delegateeName });
      ref.setState({ delegator: delegate.delegatorName });
      let jwtdf = ref.props.date_format.slice(0, 10).toUpperCase();
      let loggedInUser = ref.props.user_id;

      if (delegate && delegate.delegateDateforFrom) {
        let date = moment(delegate.delegateDateforFrom);
        ref.setState({ delegateFromDate: date });
      } else {
        ref.setState({ delegateFromDate: "" });
      }

      if (delegate && delegate.delegateDateforTo) {
        ref.setState({
          delegatetoDate: moment(delegate.delegateDateforTo),
        });
      } else {
        ref.setState({ delegatetoDate: "" });
      }

      ref.setState({ delegateeid: delegate.delegateeidforReact });
      ref.setState({ delegatorid: delegate.delegatoridforReact });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.role_id && nextProps.role_id != this.props.role_id) {
      let roleArr = nextProps.role_id.split(",");
      let adminRole = roleArr.includes("1");
      this.setState({ adminRole: adminRole });
    }

    if (nextProps.delegate && nextProps.delegate != this.props.delegate) {
      const delegate = nextProps.delegate;
      this.setState({ status: delegate.statusidforReact });
      this.setState({ moduleId: delegate.moduleidforReact });
      this.setState({ delegatee: delegate.delegateeName });
      this.setState({ delegator: delegate.delegatorName });
      let jwtdf = this.props.date_format.slice(0, 10).toUpperCase();
      let loggedInUser = this.props.user_id;

      if (delegate && delegate.delegateDateforFrom) {
        let date = moment(delegate.delegateDateforFrom);
        this.setState({ delegateFromDate: date });
        // this.setState({
        //     delegateFromDate:moment(delegate.delegateFromDate, ["MM-DD-YYYY HH:mm:ss", "DD-MM-YYYY HH:mm:ss", "YYYY-MM-DD HH:mm:ss", "MM/DD/YYYY HH:mm:ss", "DD/MM/YYYY HH:mm:ss", "YYYY/MM/DD HH:mm:ss", (jwtdf+ " HH:mm:ss")])
        // });
      } else {
        this.setState({ delegateFromDate: "" });
      }

      if (delegate && delegate.delegateDateforTo) {
        this.setState({
          delegatetoDate: moment(delegate.delegateDateforTo),
        });
      } else {
        this.setState({ delegatetoDate: "" });
      }

      // this.setState({delegateFromDate:delegate.delegateFromDate});
      // this.setState({delegatetoDate:delegate.delegatetoDate});
      this.setState({ delegateeid: delegate.delegateeidforReact });
      this.setState({ delegatorid: delegate.delegatoridforReact });

      /* this.setState({status:"1"});
      this.setState({moduleId:"13"});
      this.setState({delegatee:"3882"});
      this.setState({delegateFromDate:"2017-01-10 15:56:37.0"});
      this.setState({delegatetoDate:delegate.delegatetoDate}); */
    }
  }

  setFormProperties(value, setPropertyOf) {
    switch (setPropertyOf) {
      case "status":
        this.setState({ status: value });
        break;
      case "moduleId":
        this.setState({ moduleId: value });
        break;
      case "delegateDateforFrom":
        this.setState({ delegateFromDate: value });
        break;
      case "delegateDateforTo":
        this.setState({ delegatetoDate: value });
        break;
    }
  }

  saveDelegation(event) {
    let delegateesave = "";
    if (delegatee == "") delegateesave = this.state.delegateeid;
    else delegateesave = delegatee;

    let delegatorsave = "";
    if (delegator == "") delegatorsave = this.state.delegatorid;
    else delegatorsave = delegator;

    event.preventDefault();
    // this.setState({ delegate: delegatee });
    let delegationObj = {
      status: "",
      delegateFromDate: "",
      delegatee: delegateesave,
      delegator: "",
      delegatetoDate: "",
      moduleId: "",
    };
    if (this.state.adminRole) {
      delegationObj.delegator = delegatorsave;
    }

    if (
      this.state.adminRole &&
      delegateesave != "" &&
      delegatorsave != "" &&
      delegateesave == delegatorsave
    ) {
      swal({
        text: this.props.translator[
          "Delegation for and delegatee cannot be same."
        ],
        button: this.props.translator["OK"],
      });
      return false;
    }
    if (
      !this.state.adminRole &&
      delegateesave != "" &&
      delegateesave == this.props.user_id
    ) {
      swal({
        text: this.props.translator[
          "Logged in user and delegatee cannot be same."
        ],
        button: this.props.translator["OK"],
      });
      return false;
    }
    //alert(this.state.status)
    /*if (this.state.delegatee == '') {
        this.setState({ delegateeSearchError: 'error' });
    } else {
        this.setState({ delegateeSearchError: '' });
    }*/
    if (this.state.status == "") {
      this.setState({ statusError: "error" });
    } else {
      this.setState({ statusError: "" });
    }
    // if (this.state.delegateFromDate == '') {
    //     alert('select delegate from date');
    // } else {
    //     if (this.state.delegatetoDate == '') {
    //         alert('select delegate to date');
    //     } else {
    //         if (this.state.delegateFromDate == this.state.delegatetoDate) {
    //             alert('delegate to date and from date cannot be same');
    //         }
    //     }
    // }

    if (this.state.delegateFromDate == "") {
      swal({
        text: this.props.translator["Select delegate from date"],
        button: this.props.translator["OK"],
      });

      // alert(this.props.translator["Select delegate from date"]);
      this.setState({ delegateFromDateError: "error" });
      return false;
    } else {
      this.setState({ delegateFromDateError: "" });
    }

    if (this.state.delegatetoDate == "") {
      swal({
        text: this.props.translator["Select delegate to date"],
        button: this.props.translator["OK"],
      });
      // alert(this.props.translator["Select delegate to date"]);
      this.setState({ delegatetoDateError: "error" });
      return false;
    } else {
      this.setState({ delegatetoDateError: "" });
    }

    if (this.state.status == 1 && this.state.delegateFromDate && this.state.delegatetoDate) {
      if (moment(this.state.delegateFromDate) < moment()) {
        swal({
          text: this.props.translator[
            "Delegate from date must not be less than current time"
          ],
          button: this.props.translator["OK"],
        });
        // alert(this.props.translator["Delegate from date must not be less than current time"]);
        return false;
      }

      if (moment(this.state.delegatetoDate) < moment()) {
        swal({
          text: this.props.translator[
            "Delegated till date must not be less than current time"
          ],
          button: this.props.translator["OK"],
        });
        return false;
      }

      if ( this.state.status == 1 &&  
        moment(this.state.delegateFromDate) >= moment(this.state.delegatetoDate)
      ) {
        swal({
          text: this.props.translator[
            "Delegate from date must be less than delegate to date"
          ],
          button: this.props.translator["OK"],
        });
        // alert(this.props.translator["Delegate from date must be less than delegate to date"]);
        return false;
      }
    }
    if (this.state.moduleId == "") {
      this.setState({ moduleError: "error" });
    } else {
      this.setState({ moduleError: "" });
    }

    if (
      this.state.delegatee != "" &&
      this.state.status != "" &&
      this.state.delegateFromDate != "" &&
      this.state.delegatetoDate != "" &&
      this.state.moduleId != "" &&
      this.state.delegator != ""
    ) {
      this.setState({
        loader: true,
      });
      this.setState({ showAlertMessage: false });
      delegationObj.status = this.state.status;

      if (delegationObj && this.state.delegateFromDate) {
        delegationObj.delegateFromDate = moment(
          this.state.delegateFromDate
        ).format("MM-DD-YYYY HH:mm:ss");
      } else {
        delegationObj.delegateFromDate = "";
      }

      if (delegationObj && this.state.delegatetoDate) {
        delegationObj.delegatetoDate = moment(this.state.delegatetoDate).format(
          "MM-DD-YYYY HH:mm:ss"
        );
      } else {
        delegationObj.delegateFromDate = "";
      }

      // delegationObj.delegateFromDate = this.state.delegateFromDate;
      //  delegationObj.delegatee = this.state.delegateeid;
      // delegationObj.delegatetoDate = this.state.delegatetoDate;
      delegationObj.moduleId = this.state.moduleId;

      axios
        .patch(
          GLOBAL.editDelegation + this.props.delegate.delegationId,
          delegationObj
        )
        .then((response) => {
          if (response.status == 200 || response.status == 201) {
            //moving to delegate list as no response is coming after new delegate creation
            //browserHistory.push("/delegate");
            this.props.getDelegationList(
              "",
              1,
              10,
              this.props.sortOrder,
              this.props.sortOnColumn,
              this.props.status,
              this.props.modules,
              this.props.searchKey,
              this.props.searchValue
            );
            swal({
              text: this.props.translator["Delegation saved successfully"],
              button: this.props.translator["OK"],
            });
            delegator = "";
            delegatee = "";
            this.props.showRightPanelFun(false);
            this.props.showListPart1(false);
            this.props.showActiveRow(0);
          } else {
            swal({
              text: this.props.translator["Error in creating delegation"],
              button: this.props.translator["OK"],
            });
            // alert(this.props.translator["Error in creating delegation"]);
          }
          this.setState({
            loader: false,
          });
        });
    } else {
      this.setState({
        loader: false,
      });
      this.setState({ showAlertMessage: true });
    }
  }

  render() {
    let delegate = this.props.delegate;
    let delegationList = this.props.delegationList.delegationList;
    if (this.props.delegateShowLoader == true) {
      return (
        <div>
          <ListLoader />
        </div>
      );
    } else if (
      delegationList.length == 0 ||
      delegationList == [] ||
      delegationList == undefined
    ) {
      return (
        <div>
          <font color="green">
            <b>{this.props.translator["No Data Found"]}</b>
          </font>
        </div>
      );
    }
    return (
      <div className="bFormDiv">
        <Row>
          <Col xs={12}>
            <div className="rPageHeading">
              <div className="offNam margin-t-5 margin-b-5">
                {this.props.translator["Edit Delegation"]}
              </div>
            </div>
          </Col>
        </Row>

        <div className="rBoxGap">
          <form>
            {/* <div className="rPageHeading">{this.props.translator['Edit Delegation']}</div> */}
            {this.state.showAlertMessage ? (
              <Alert variant="danger" className="errorMessageSec">
                <p>
                  {
                    this.props.translator[
                      "The form has missing required fields, please revalidate the highlighted fields and update the form."
                    ]
                  }
                </p>
              </Alert>
            ) : null}
            {this.state.adminRole ? (
              <Form.Group className="form-group" validationState={this.state.delegationForError}>
                <Form.Label bsClass="">
                  <span className="rStar"></span>{" "}
                  {this.props.translator["Delegation For"]}
                </Form.Label>
                <div className="searchFawicon">
                  <SearchDelegator delegator={delegate.delegatorName} />
                </div>
              </Form.Group>
            ) : null}
            <Form.Group className="form-group" validationState={this.state.delegateeSearchError}>
              <Form.Label bsClass="">
                <span className="rStar"></span>{" "}
                {this.props.translator["Delegatee"]}
              </Form.Label>
              <div className="searchFawicon">
                <SearchDelegatee delegatee={delegate.delegateeName} />
              </div>
            </Form.Group>
            <Form.Group className="form-group" validationState={this.state.moduleError}>
              <Form.Label bsClass="">
                <span className="rStar"></span>{" "}
                {this.props.translator["Module"]}
              </Form.Label>
              <Form.Select
                aria-label={this.props.translator["Module"]}
                value={this.state.moduleId}
                onChange={(event) => {
                  this.setFormProperties(event.target.value, "moduleId");
                }}
              >
                <option value="10">
                  {this.props.translator["Service Order"]}
                </option>
                <option value="14">{this.props.translator["RFC"]}</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="form-group" validationState={this.state.delegateFromDateError}>
              <Form.Label bsClass="">
                <span className="rStar"></span>{" "}
                {this.props.translator["Delegation Start"]}
              </Form.Label>
              <div className="dateTimeSdiv deledateTimePosTop">
                {/*<DateField expandOnFocus={false} dateFormat="MM-DD-YYYY HH:mm:ss" minDate={moment()} updateOnDateClick={true} forceValidDate={true} defaultValue={this.state.delegateFromDate} value = {this.state.delegateFromDate}><DatePicker navigation={true} locale="en" forceValidDate={true} highlightWeekends={true} highlightToday={true} cancelButton={false} clearButton={false} weekNumbers={false} weekStartDay={1} onChange={(event) => { this.setFormProperties(event, "delegateFromDate"); }} /></DateField>*/}

                <Field
                  name="delegateDateforFrom"
                  component={_dateTimeField}
                  className="form-control dateTimePickerField"
                  formType="editDelegate"
                  disabledValue={false}
                  defaultValue={
                    this.state.delegateFromDate != ""
                      ? this.state.delegateFromDate
                      : moment(delegate.delegateDateforFrom)
                  }
                  onChange={(event) => {
                    this.setFormProperties(event, "delegateDateforFrom");
                  }}
                />
              </div>
            </Form.Group>
            <Form.Group className="form-group" validationState={this.state.delegatetoDateError}>
              <Form.Label bsClass="">
                <span className="rStar"></span>{" "}
                {this.props.translator["Delegation End"]}
              </Form.Label>
              <div className="dateTimeSdiv">
                {/*<DateField expandOnFocus={false} dateFormat="MM-DD-YYYY HH:mm:ss" minDate={moment()} updateOnDateClick={true} forceValidDate={true} defaultValue={this.state.delegatetoDate} value = {this.state.delegatetoDate}><DatePicker navigation={true} locale="en" forceValidDate={true} highlightWeekends={true} highlightToday={true} cancelButton={false} clearButton={false} weekNumbers={false} weekStartDay={1} onChange={(event) => { this.setFormProperties(event, "delegatetoDate"); }} /></DateField>*/}

                <Field
                  name="delegateDateforTo"
                  component={_dateTimeField}
                  className="form-control dateTimePickerField datePckBot"
                  formType="editDelegate"
                  disabledValue={false}
                  defaultValue={
                    this.state.delegatetoDate != ""
                      ? this.state.delegatetoDate
                      : delegate.delegateDateforTo
                  }
                  onChange={(event) => {
                    this.setFormProperties(event, "delegateDateforTo");
                  }}
                />
              </div>
            </Form.Group>
            <Form.Group className="form-group" validationState={this.state.statusError}>
              <Form.Label bsClass="">
                <span className="rStar"></span>{" "}
                {this.props.translator["Status"]}
              </Form.Label>
              <Form.Select
                aria-label={this.props.translator["Status"]}
                value={this.state.status}
                onChange={(event) => {
                  this.setFormProperties(event.target.value, "status");
                }}
              >
                <option value="1">{this.props.translator["Active"]}</option>
                <option value="2">{this.props.translator["Inactive"]}</option>
              </Form.Select>
            </Form.Group>

            <ButtonToolbar className="margin-t-15">
              <Button
                className="rgSidrkBtn"
                variant="primary"
                onClick={(event) => {
                  this.saveDelegation(event);
                }}
              >
                {this.state.loader ? (
                  <ImSpinner6 className="icn-spinner"/>
                ) : null}{" "}
                {this.props.translator["Save"]}
              </Button>
              <Button
                className="rgSiLigBtn"
                variant="primary"
                onClick={() => {
                  this.props.showListPart1(false);
                }}
              >
                {this.props.translator["Cancel"]}
              </Button>
            </ButtonToolbar>
          </form>
        </div>
      </div>
    );
  }
};


DelegationForm = reduxForm({
  form: "addDelegationForm",
  // validate,
  enableReinitialize: true,
})(DelegationForm);

const mapStateToProps = ({ delegationList, delegateShowLoader }) => {
  return {
    delegationList,
    delegateShowLoader: delegateShowLoader.loading,
  };
};
export default connect(mapStateToProps, { getDelegationList })(DelegationForm);
