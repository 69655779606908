
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../../initialState';

export default function (state = initialState.myOrderViewFilters, action){
  let newSelectedOrderTypeArray = [];
  let newselectedStatusArray = [];


	switch (action.type){

    //Change start
    case 'ADD_TO_SELECTEDORDER_STATUS_LIST':
      newselectedStatusArray = state.selectedStatus.concat(action.payload);
			return Object.assign({}, state, {selectedStatus: newselectedStatusArray});
		case 'REMOVE_FROM_SELECTEDORDER_STATUS_LIST':
			newselectedStatusArray = state.selectedStatus.filter((item) => {return item!=action.payload;});
			return Object.assign({}, state, {selectedStatus: newselectedStatusArray});

    case 'ADD_TO_SELECTEDORDER_TYPE_LIST':
    // console.log(state.selectedTags);
    // console.log(action.payload);
  	   newSelectedOrderTypeArray = state.selectedOrderType.concat(action.payload);
  	   return Object.assign({}, state, {selectedOrderType: newSelectedOrderTypeArray});
  	case 'REMOVE_FROM_SELECTEDORDER_TYPE_LIST':
      // console.log(state.selectedTags);
      // console.log(action.payload);
  		newSelectedOrderTypeArray = state.selectedOrderType.filter((item) => {return item!=action.payload;});
  		return Object.assign({}, state, {selectedOrderType: newSelectedOrderTypeArray});

    //my request reset filter
    case 'RESET_MR_QV_FILTERS':
      return Object.assign(
        {},
        state,
        {
          selectedOrderType:[],
          selectedStatus:[]

        }
      );
		default:
				return state;
		}
}
