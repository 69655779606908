
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector, connect } from "react-redux";
import { Link } from "react-router-dom";
import { HiPlus, HiOutlineRefresh } from "react-icons/hi";
import { _dropDown } from "../../../common/ReduxFormFields/CategoryBoard/formFields.js";
import { change, Field, reduxForm } from "redux-form";
import { LuFilterX } from "react-icons/lu";
import { loadEscalationPlans } from "../../../../actions/onCallSchedule/escalationAction.js";

const LanguageListHeader = (props) => {
  const translator = useSelector((state) => state.spcmReducer.tr);

  return (
    <Row className="margin-b-15">
      <Col lg={8} md={6} sm={12} xs={12}>
        <h2 className="sPageHeading1">{"Languages"}</h2>
      </Col>
      <Col lg={4} md={6} sm={12} xs={12}>
        <div className="paHedFilter">
          {props.isFilterApplied && (
            <Button
              bsPrefix=" "
              onClick={() => props.resetFilters()}
              role="button"
              className="myBt"
              title="Reset Filters"
            >
              <LuFilterX />
            </Button>
          )}
          <Link
            to="/addLanguage"
            role="button"
            className="myBt plus fillBtn"
            title="Create"
          >
            <HiPlus />
          </Link>
          <Button
            bsPrefix=" "
            title="Refresh"
            role="button"
            className="myBt"
            onClick={() => {
              props.showRightSideFun(false);
              props?.refreshPage();
            }}
          >
            <HiOutlineRefresh className="refreshFlip" />
          </Button>
        </div>
      </Col>
    </Row>
  );
};
const mapStateToProps = (state) => ({
  onCallGroupList: state.onCallGroupList
    ? state.onCallGroupList.map((list) => {
        return { ...list, label: list.groupName, id: list.groupId };
      })
    : [],
});

export default reduxForm({
  form: "LanguageListHeader",
})(connect(mapStateToProps)(LanguageListHeader));
