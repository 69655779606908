
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export default function (state = initialState.breakFixSGIName, action){

	switch (action.type){
		case 'LOAD_BREAKFIXSGI_SUCCESS':
				//alert(action.breakFix.data.);
				return action.breakFixSGIName.data;
		case 'LOAD_BREAKFIXSGI_FAILURE':
				return action.failureMessage;
		case 'RESET_BREAKFIXSGI':
				return [];	
		default:
				return state;
		}

}
