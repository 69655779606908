
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { connect } from "react-redux";
import axios from "axios";
import { GLOBAL } from '_Globals'
import { Field, reduxForm, change } from "redux-form";
const Joi = require("joi");

class PopUpRectroActive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disableButtons: false,
      checked:
        (this.props.slaDefEditDetails.retroactiveRequired === "Yes" &&
          GLOBAL.slaResponseRecived === "") ||
        (GLOBAL.slaRectroactiveToggle === "Yes" &&
          GLOBAL.slaResponseRecived === "Submitted")
          ? true
          : false,
    };
    this.saveAtt = this.saveAtt.bind(this);
    this.setToggleValue = this.setToggleValue.bind(this);
  }
  componentWillMount() {
    if (
      (this.props.slaDefEditDetails.retroactiveRequired !== "" &&
        GLOBAL.slaRectroactiveToggle == "") ||
      (this.props.slaDefEditDetails.retroactiveRequired !== null &&
        GLOBAL.slaRectroactiveToggle == "")
    ) {
      GLOBAL.slaRectroactiveToggle =
        this.props.slaDefEditDetails.retroactiveRequired;
    }
    this.props.dispatch(
      change(
        "PopUpRectroActive",
        "slaRetroactiveRequired",
        GLOBAL.slaRectroactiveToggle
      )
    );
  }
  setToggleValue(event) {
    GLOBAL.slaRectroactiveToggle = event.target.value;
    this.props.dispatch(
      change("PopUpRectroActive", "slaRetroactiveRequired", event.target.value)
    );
    this.setState({ checked: !this.state.checked });
  }
  saveAtt(values) {
    this.setState({ disableButtons: true });
    let postjson = {
      retroactiveRequired: values.slaRetroactiveRequired,
      companyId: this.props.slaDefEditDetails.companyId,
    };
    let ref = this;
    axios
      .patch(GLOBAL.slaDefPatchUrl + this.props.slaDefId, postjson)
      .then(function (response) {
        ref.setState({ disableButtons: false });
        GLOBAL.slaResponseRecived = "Submitted";
      });
  }
  render() {
    let { handleSubmit } = this.props;
    return (
      <div className="borderForm bFormDiv">
        <div className="f-size-20 margin-b-5">
          {this.props.translator["Retroactive Action Required"]}
        </div>
        <div>
          <div className="bgGray padding-5"></div>
          <br></br>
          <Table responsive className="simTable">
            <tbody>
              <tr>
                <td>
                  <Form.Label bsClass="" className="rStar">
                    {this.props.translator["Retroactive Start"]}
                  </Form.Label>
                </td>
                <td>
                  <div className="retrodv">
                    <label className="fieldgap">
                      <Field
                        name="slaRetroactiveRequired"
                        type="radio"
                        component="input"
                        value="Yes"
                        checked={this.state.checked}
                        onChange={this.setToggleValue}
                      />
                      {this.props.translator["Yes"] + " "}
                    </label>
                    <label>
                      <Field
                        name="slaRetroactiveRequired"
                        type="radio"
                        component="input"
                        checked={!this.state.checked}
                        value="No"
                        onChange={this.setToggleValue}
                      />
                      {this.props.translator["No"]}
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <ButtonGroup>
                    <Button
                      onClick={handleSubmit(this.saveAtt)}
                      size="xs"
                      disabled={this.state.disableButtons}
                    >
                      {this.props.translator["Save"]}
                    </Button>
                  </ButtonGroup>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

PopUpRectroActive = reduxForm({
  form: "PopUpRectroActive",
})(PopUpRectroActive);

export function mapStateToProps({ slaDefEditDetails }) {
  return {
    slaDefEditDetails,
  };
}

export default connect(mapStateToProps, null)(PopUpRectroActive);
