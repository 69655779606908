
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef } from 'react'
import { Button, Col, Row, Nav } from 'react-bootstrap';
import { ImSpinner6 } from 'react-icons/im';
import { updateClassDefinitionAction } from '../../../../actions/ham/hamAction';
import {useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from 'react-router';
import { HiOutlineTrash } from 'react-icons/hi';
import { reduxForm } from "redux-form";
import { IoClose, IoSaveOutline } from "react-icons/io5";
import Cookies from "universal-cookie";
import { MyContext } from "_MyContext";
import { Link } from "react-router-dom";
import { LuCalendarCheck2 } from "react-icons/lu";
const cookies = new Cookies();

let language = cookies.get("gph");
if (language) language = language.replace("s:", "");
if (language) language = language.substring(0, language.lastIndexOf("."));
language = language.split("~");
language = language[20];

const EditClassDefinitionHeader = (props) => {
  const navigate = useNavigate();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const dispatch = useDispatch();
  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const saveObjRef = useRef(null);

  useEffect(() => {
    context.keyDownHandler(closeButton.current, "", "", saveObjRef.current);
    context.newTabPrevent();
  }, []);

  const submit = async (values) => {
    console.log("values----->", values);
    console.log("props.companyId,", props.companyId);
    let isValid = true;

    if (!values.status || values.status == "") {
      props.setStatusErr("error");
      isValid = false;
    } else {
      props.setStatusErr("");
      // isValid = true;
    }

    if (values.parent == "") {
      values.parent = null;
    }

    if (isValid == true) {
      let patchjson;
      patchjson = {
        class_name: values.ClassName,
        company_id: props.companyId.toString(),
        class_type: values.type,
        parent_class_id: values.parent,
        company_name: values.company,
        status: values.status,
        language: language,
      };
      dispatch(updateClassDefinitionAction(patchjson, values.classId));
    }
  };

  let { handleSubmit, submitting } = props;
  return (
    <>
      <Row className="margin-b-15">
        <Col lg={4} md={6} sm={6} xs={7}>
          <h1 bsClass="" className="sPageHeading1">
            {tr["Class Definition"]}
          </h1>
        </Col>
        <Col lg={8} md={6} sm={6} xs={5}>
          <div className={`float-r toprigLnk fulfillHeader`}>
            <Nav className="icnlnk riggep float-l" as="ul">
              <Nav.Item title={tr["Task Plan"]} eventKey={4} as="li">
                <a
                  className={props.view === "taskPlan" ? "active" : ""}
                  href="javascript:void(0)"
                  onClick={() => {
                    props.setView("taskPlan");
                    props.rightEditPanel(true);
                  }}
                >
                  <LuCalendarCheck2 />
                </a>
              </Nav.Item>
            </Nav>

            <Nav as="ul" className="icnlnk">
              <Nav.Item
                as="li"
                eventKey={2}
                disabled={submitting}
                title={tr["Save"]}
              >
                <Link
                  to="javascript:void(0)"
                  bsPrefix=" "
                  id="hdID"
                  className="myBt plus ctrlKeyPrevent"
                  disabled={props.loader}
                  onClick={handleSubmit(submit)}
                  title={tr["Save"]}
                  ref={saveObjRef}
                >
                  <span>
                    {props.loader ? (
                      <ImSpinner6 className="icn-spinner" />
                    ) : (
                      <IoSaveOutline />
                    )}
                  </span>
                </Link>
              </Nav.Item>

              <Nav.Item
                as="li"
                title={tr["Close"]}
                className="ctrlKeyPrevent"
                eventKey={3}
              >
                <Link
                  bsPrefix=" "
                  className="myBt cancel fillBtn ctrlKeyPrevent"
                  title={tr["Cancel"]}
                  to={"/classDefinition"}
                  ref={closeButton}
                >
                  <IoClose />
                </Link>
              </Nav.Item>
            </Nav>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default reduxForm({
  form: "EditClass",
})(EditClassDefinitionHeader);
