
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
//below URL used for testing purpose
//const breakFixUrl = 'https://xsm-f.mybluemix.net/api/SX_FAVOURITEOFFERINGs/';
import { GLOBAL }  from '../../components/Globals';
//let breakFixUrl =`https://xsm-f.mybluemix.net/api/breakfixitem?breakfixitemId=`;
//let breakFixUrl=`https://xsm-f.mybluemix.net/groupListSelfService?companyId=2932&group=ResolvedER`

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

export function loadChangeTotalApprovalDetailsSuccess(changeTotalApprovalDetails){
	//alert('in success')
	return {
		type: 'LOAD_CHANGETOTALAPPROVALDETAILS_SUCCESS',
		changeTotalApprovalDetails
	};
}

export function getRequesterDelegateeForApprovalDetailsSuccess(requesterDelegateeDetails){
	return {
		type: 'LOAD_REQUESTERDELEGATEEDETAILS_SUCCESS',
		requesterDelegateeDetails
	};
}

export function loadChangeTotalApprovalDetailsFailure(errorResponse){
	return {
		type: 'LOAD_CHANGETOTALAPPROVALDETAILS_FAILURE',
		errorResponse
	};
}

export function loadChangeTotalApprovalHistoryDetailsSuccess(changeTotalApprovalHistoryDetails){
	//alert('in success')
	return {
		type: 'LOAD_CHANGETOTALAPPROVALHISTORYDETAILS_SUCCESS',
		changeTotalApprovalHistoryDetails
	};
}

export function loadChangeTotalApprovalHistoryDetailsFailure(errorResponse){
	return {
		type: 'LOAD_CHANGETOTALAPPROVALHISTORYDETAILS_FAILURE',
		errorResponse
	};
}
export function loadChangeTotalApprovalDetails(changeId,module,statusId,status=''){
	console.log('In the Approval Details',status.toString())
	//alert('action called and parameter is '+breakFixId)
	//if(breakFixId=="0")
	//breakFixId="";
	var str={};
	str.itemId=changeId;
	str.moduleId=parseInt(module, 10);
	if(statusId!=='0' && statusId!==''){
	    str.statusId=statusId;
	}else{
		str.statusId="N";
	}
	if( status!=='' && status!=='0'){
	 str.status=status.toString();
	}
	str = JSON.stringify(str);
	return(dispatch) =>{
		let relatedurl="";
		dispatch({
			 type:'MENU_LOADER_IN_PROGRESS',
			 payload:''
		}); 
	console.log("?itemId="+changeId+"&moduleId="+module+"&statusId="+statusId);
				relatedurl=GLOBAL.changeTotalApprovalUrl;

	api.get(relatedurl,{headers:{'query':str}})
		.then((changeTotalApprovalDetails)=>{
			if(!changeTotalApprovalDetails){//alert('not breakFix')
				//dispatch(loadBreakFixFailure(breakFix.statusText))
				dispatch({
					type:'MENU_LOADER_DETAILS_FETCHED_SUCCESSFULLY',
					payload:''
				}); 
				throw Error(changeTotalApprovalDetails.statusText);
			}
			 
			return changeTotalApprovalDetails;
		})
		.then((changeTotalApprovalDetails) =>{//alert('yes it is')
		dispatch({
			type:'MENU_LOADER_DETAILS_FETCHED_SUCCESSFULLY',
			payload:''
		}); 
			dispatch(loadChangeTotalApprovalDetailsSuccess(changeTotalApprovalDetails))
		})
		.catch((err) => {
			dispatch({
				type:'MENU_LOADER_DETAILS_FETCHED_SUCCESSFULLY',
				payload:''
			}); 
			console.log(err);
		});
		//console.log("not calling loadBreakFixEditDetails");

	};
}

export function getRequesterDelegateeForApprovalDetails(requesterId, module){
	let str={};
	str.user_id=requesterId;
	str.module=module;
	str = JSON.stringify(str);
	return(dispatch) =>{
	api.get(GLOBAL.getRequesterDelegateeUrl,{headers:{'query':str}})
		.then((requesterDelegateeDetails)=>{
			if(!requesterDelegateeDetails){
				throw Error(requesterDelegateeDetails.statusText);
			}
			return requesterDelegateeDetails;
		})
		.then((requesterDelegateeDetails) =>{
			dispatch(getRequesterDelegateeForApprovalDetailsSuccess(requesterDelegateeDetails))
		})
		.catch((err) => {
			console.log(err);
		});
	};
}

export function loadChangeTotalApprovalHistoryDetails(changeId,module,statusId){
	console.log('in the approval history')
	//if(breakFixId=="0")
	//breakFixId="";
	var str={};
	str.itemId=changeId;
	str.moduleId=parseInt(module, 10);
	str.statusId=statusId;
	str = JSON.stringify(str);
	return(dispatch) =>{
		let relatedurl="";

				relatedurl=GLOBAL.changeTotalApprovalUrl;

				api.get(relatedurl,{headers:{'query':str}})
					.then((changeTotalApprovalHistoryDetails)=>{
						if(!changeTotalApprovalHistoryDetails){//alert('not breakFix')
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							throw Error(changeTotalApprovalHistoryDetails.statusText);
						}
						return changeTotalApprovalHistoryDetails;
					})
					.then((changeTotalApprovalHistoryDetails) =>{//alert('yes it is')
						dispatch(loadChangeTotalApprovalHistoryDetailsSuccess(changeTotalApprovalHistoryDetails))
					})
					.catch((err) => {
						console.log(err);
					});
					//console.log("not calling loadBreakFixEditDetails");

	};
}
