
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { v4 as uuidv4 } from "uuid";

import ListLoader from "../../common/loaders/ListLoader";
import NoDataAvailable from "../../common/NoDataAvailable";

const DiscussionPointList = ({ data, isFetching }) => {
  return (
    <div className="custom-attachment-table template-ui mb-2">
      {isFetching ? (
        <div className="fnloader">
          <ListLoader />
        </div>
      ) : data?.length === 0 ? (
        <div className="custom-container-fluid mb-2">
          <NoDataAvailable />
        </div>
      ) : (
        data?.map?.((item, index) => {
          const uniquekey = uuidv4();
          return (
            <div className="mt-2" key={`key_${uniquekey}`}>
              <div className="document-body">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="spacing-left5 pt-1  pb-1">
                      <span className="p-1">{item?.discussionPoints}</span>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <span className="whopublished">
                      <i className="fa fa-user" aria-hidden="true"></i> &nbsp;
                      {item?.createdByFullName}&nbsp; | &nbsp;
                      <i className="fa fa-clock-o" aria-hidden="true"></i>
                      &nbsp; {item?.createdOn}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default DiscussionPointList;
