
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

//let user_id=userDetails.user_id;
const advertUrl="/getAdvertisementForRestifySelfService";

export function loadAdevertisementSuccess(advertisement){
	return {
		type: 'FETCH_ADVERTISEMENT',
		advertisement
	};
}

export function loadAdvertisement(){
	var str={};
	str.userId="";
	str = JSON.stringify(str);
	return(dispatch) =>{
		api.get(advertUrl,{headers:{'query':str}})
			.then((advertisement)=>{
				if(!advertisement){
					throw Error(advertisement.statusText);
				}
				return advertisement;
			})
			.then((advertisement) =>{
				dispatch(loadAdevertisementSuccess(advertisement))
			})
			.catch((err) => {
				console.log(err);
			});
	};
}
