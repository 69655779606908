
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState, useEffect, useRef } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import Tree from "react-d3-tree";
import { Row, Col, Nav, Button, Popover, ListGroup } from "react-bootstrap";

import CMDBRelationship from "./rightSideItems/CMDBRelationshipN";
import { loadChangeRelatedDataDetailsN } from "../../../actions/changeManagement/cmdbchangeRelatedDataAction";
import { getRelationshipData } from "../../../actions/cmdb/cmdbTimelineAction";
import { loadCIEditDetails } from "../../../actions/cmdb/lovAction.js";
import { getTranslation } from "../../../actions/spcmActions";
import ListLoader from "../../common/loaders/ListLoader";
import Tooltip from "../common/tooltip";
import PureSvgNodeElement from "../common/PureSvgNodeElement";
import { useCenteredTree } from "../common/useCenteredTree.js";

const containerStyles = {
  width: "100%",
  // height: "80vh",
  position: "relative",
};

const CmdbVisualCI = ({ itemId, translator }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { ciId } = useParams();

  const store = useSelector((state) => state);
  const CIEditDetails = useSelector((state) => state.CIEditDetails);
  const tr = useSelector((state) => state.spcmReducer.tr);
  const lang = useSelector((state) => state.spcmReducer.lang);
  const showLoader = useSelector((state) => state.showLoader);
  const cmdbChangeRelatedDataDetails = useSelector(
    (state) => state.cmdbChangeRelatedDataDetails
  );
  const treeContainerRef = useRef(null);
  const treeRef = useRef(null);
  const overlayRef = useRef(null);
  const [isBusy, setIsBusy] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(0.1);
  const [scaleExtent, setScaleExtent] = useState({ min: 0.1, max: 1 });
  const [tooltipContent, setTooltipContent] = useState(null);
  const [translate, setTranslate] = useState(null);
  const [dimensions, setDimensions] = useState();
  const [orientation, setOrientation] = useState("vertical");
  const [viewFormId, setViewFormId] = useState(null);
  const [showrel, setShowrel] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [zoomable, setZoomable] = useState(1);
  const [translatetree, containerRef] = useCenteredTree();
  const [module, setModule] = useState("Breakfix");
  const [viewFormRelId, setViewFormRelId] = useState(null);

  const [tData, setTData] = useState([
    {
      name: "",
      attributes: "",
      children: [],
    },
  ]);
  const[plottedData,setPlottedData] = useState([]);


  const [popoverData, setPopoverData] = useState(null);
  // const cacheBruster = btoa(new Date().getTime().toString());

  const popover = (
    <Popover.Body className="p-1">
      <ListGroup variant="flush">
        <ListGroup.Item>
          <Link
            to={`/editcmdb/${viewFormId}`}
            target="_blank"
            className="related"
          >
            Show CI Form
          </Link>
        </ListGroup.Item>
        <ListGroup.Item>
          <a
            // to={`/visualCI/${viewFormId}`}
            onClick={() => {
              setShowrel(true);
              dispatch(getRelationshipData(viewFormId, "Breakfix", "No"));
              setShowPopover(false);
            }}
            className="related"
          >
            Show Related Work Items
          </a>
        </ListGroup.Item>
      </ListGroup>
    </Popover.Body>
  );

  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;

  const svgWidth = 400;
  const svgHeight = 500;

  const translateX = screenWidth / 2;
  const translateY = screenHeight / 2;

  const svgSquare = {
    shape: "circle",
    shapeProps: {
      r: 10,
      fill: "#FFCC6D",
      stroke: "#000",
      strokeWidth: 1,
    },
  };
  const [tooltip, setTooltip] = useState({
    isVisible: false,
    x: 0,
    y: 0,
    text: "",
  });

  const handleMouseOverLink = (linkSource, linkTarget, evt) => {
    setTooltip({
      isVisible: true,
      x: evt.pageX - 40 + "px",
      y: evt.pageY - 130 + "px",
      text: linkTarget?.data?.relationbetween || "--",
    });
  };

  const handleMouseOutLink = () => {
    setTimeout(() => {
      setTooltip({
        isVisible: false,
        x: 0,
        y: 0,
        text: "",
      });
    }, 2000);
  };

  const handleShowhide = () => {
    setShowrel(false);
  };

  const handleZoomIn = () => {
    setScaleExtent({ ...scaleExtent, min: scaleExtent.min + 1 });
    //setZoomable(scaleExtent.min + 1);
  };

  const handleZoomOut = () => {
    if (scaleExtent.min > 0) {
      setScaleExtent({ ...scaleExtent, min: scaleExtent.min - 1 });
    }
  };

  // const handleZoomIn = () => {
  //   setZoomLevel(Math.min(zoomLevel + 1, 10));
  // };

  // const handleZoomOut = () => {
  //   setZoomLevel(Math.max(zoomLevel - 1, 0.1));
  // };

  const onUpdate = (args) => {
    const { translate } = args;
    setTranslate(translate);
  };

  function isBranchOpen(node, targetId) {
    if (node.id === targetId && node.children.length > 0) {
      return true;
    }
    for (const child of node.children) {
      if (isBranchOpen(child, targetId)) {
        return true;
      }
    }
    return false;
  }

  const handleNodeClick = async (node) => {
    try {
      if (node?.itemId) {
        const relResp = await dispatch(
          loadChangeRelatedDataDetailsN(node.itemId, "CMDB", "No")
        );
        const editDetailsResp = await dispatch(loadCIEditDetails(node.itemId));
        const isNodeBranchOpen = isBranchOpen(tData, node.itemId);
        const treeData = createTreeData(relResp, editDetailsResp?.data?.data);
        let plottedArr = structuredClone(plottedData);
        plottedArr.push(node.itemId)
        setPlottedData(plottedArr);

        
        const updatedData = updateNodeInTree(
          tData,
          node.name,
          node.itemId,
          treeData,
          isNodeBranchOpen
        );
     

        setTData(updatedData);
      }
    } catch (error) {}
  };

  const addChildNode = async (nodeData) => {
    const target = nodeData.children;
    const relResp = await dispatch(
      loadChangeRelatedDataDetailsN(nodeData.itemId, "CMDB", "No")
    );
    const editDetailsResp = await dispatch(loadCIEditDetails(nodeData.itemId));
    const treeData = createTreeData(relResp, editDetailsResp?.data?.data);
    // Iterate over the children in treeData and push them to target
    treeData.children.forEach((child) => {
      target.push(child);
    });
    // Find the node in tData that you want to update
    const updatedData = updateNodeInTree(
      tData,
      nodeData.name,
      nodeData.id,
      treeData
    );

    setTData(updatedData);
  };

  const handlePopoverClick = (nodeData) => {
    setViewFormId(nodeData.id);
  };

  const updateNodeInTree = (
    currentNode,
    targetNodeName,
    targetId,
    newData,
    openBranch
  ) => {
    if (
      currentNode.name === targetNodeName &&
      currentNode.itemId === targetId
    ) {
      if (openBranch) {
        return {
          ...currentNode,
          children: [],
        };
      } else {
        return {
          ...currentNode,
          ...newData,
        };
      }
    } else if (currentNode.children && currentNode.children.length > 0) {
      return {
        ...currentNode,
        children: currentNode.children.map((childNode) => {
          return updateNodeInTree(
            childNode,
            targetNodeName,
            targetId,
            newData,
            openBranch
          );
        }),
      };
    } else {
      return currentNode;
    }
  };

  const customNodeFnMapping = {
    svg: {
      description: "Default",
      fn: (rd3tProps, appState) => {
        rd3tProps.addChildren = () => {};

        return (
          <PureSvgNodeElement
            overlayRef={overlayRef}
            nodeDatum={rd3tProps.nodeDatum}
            toggleNode={rd3tProps.toggleNode}
            orientation={orientation}
            // addChildNode={rd3tProps.addChildren}
            handleNodeClick={handleNodeClick}
            handlePopoverClick={handlePopoverClick}
            popover={popover}
            setViewFormId={setViewFormId}
            viewFormId={viewFormId}
            setPopoverData={setPopoverData}
            isRootNode={Boolean(
              !rd3tProps?.hierarchyPointNode?.parent &&
                rd3tProps?.hierarchyPointNode?.depth == 0
            )}
            addChildren={() => addChildNode(rd3tProps.nodeDatum)}
            showPopover={showPopover}
            setShowPopover={setShowPopover}
            setShowrel={setShowrel}
            setModule={setModule}
            setViewFormRelId={setViewFormRelId}
          />
        );
      },
    },
  };

  let formattedData = null;
  const createTreeData = (cmdbChangeRelatedDataDetails, cieditdetails) => {
    if (!formattedData) {
      let ci_name;
      if (cieditdetails.SERIAL_NUMBER == '' || cieditdetails.SERIAL_NUMBER==null) {
         ci_name = cieditdetails.CI_NAME;
      } else {
        ci_name = cieditdetails.CI_NAME + '-' + cieditdetails.SERIAL_NUMBER;
      }     
      let childArr = [];
      cmdbChangeRelatedDataDetails.forEach((relation) => 
          {
            if(!plottedData.includes(relation.CI_ID_RELATED) ){
              childArr.push({
                name: relation?.CI_NAME_RELATED || "",
                serialno: relation?.SERIAL_NUMBER || "",
                relationbetween: relation.CI_RELATIONSHIP,
                id: relation.CI_ID_RELATED,
                itemId: relation.CI_ID_RELATED,
                icon: relation.RELATED_ICON_CSS,
                attributes: {
                  Class: relation.CLASS_NAME_RELATED,
                  Status: relation.CI_ID_RELATED_STATUS,
                  "Serial Number": relation.SERIAL_NUMBER_CI_NAME_RELATED || "NA"
                },
                children: [],
              })
              
            }
           
          })
      formattedData = {
        name: cieditdetails?.CI_NAME || "",
        serialno: cieditdetails?.SERIAL_NUMBER || "",
        id: cieditdetails.CI_ID,
        icon: cmdbChangeRelatedDataDetails[0].ICON_CSS,
        attributes: {
          Class: cieditdetails.CLASS_NAME,
          Status: cieditdetails.STATUS_NAME,
          "Serial Number": cieditdetails.SERIAL_NUMBER || "NA"
        },
        children: childArr
      };
    }

    return formattedData;
  };

  const nodeSize = { x: 200, y: 200 };

  useEffect(() => {
    dispatch(getTranslation(lang));
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setIsBusy(true);
        const relResp = await dispatch(
          loadChangeRelatedDataDetailsN(ciId, "CMDB", "No")
        );
        const editDetailsResp = await dispatch(loadCIEditDetails(ciId));

        const treeData = createTreeData(relResp, editDetailsResp?.data?.data);
        console.log("treeDataUseEffe",treeData);
        let plottedArr = structuredClone(plottedData);
        plottedArr.push(treeData.id);
        setPlottedData(plottedArr);
        setTData(treeData);
        setIsBusy(false);
      } catch (error) {
        setIsBusy(false);
      } finally {
        setIsBusy(false);
      }
    })();
  }, []);

  useEffect(() => {
    const dimensions = treeContainerRef?.current?.getBoundingClientRect();

    setDimensions({
      width: dimensions?.width,
      height: dimensions?.height,
    });

    setTranslate({
      x: dimensions?.width / 2.5,
      y: dimensions?.height / 2,
    });
  }, [treeContainerRef.current]);

  useEffect(() => {
    const dimensions = treeContainerRef?.current?.getBoundingClientRect();

    setDimensions({
      width: dimensions?.width,
      height: dimensions?.height,
    });
  }, []);

  // useEffect(() => {
  //   console.log(zoomLevel);
  //   setScaleExtent({ min: zoomLevel, max: 1 });
  // }, [zoomLevel]);

  // useEffect(() => {
  //   console.log("scaleExtent", scaleExtent);
  //   if (scaleExtent.min > 0.1) {
  //     setZoomable(scaleExtent?.min <= 0.1 ? 0.5 : scaleExtent.min);
  //   }
  // }, [translate]);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     const svgElement = document.getElementsByTagName("svg")[0];
  //     console.log(svgElement);
  //     if (svgElement) {
  //       console.log("scale", scaleExtent);
  //       setZoomable(scaleExtent?.min <= 0.1 ? 0.5 : scaleExtent.min);
  //     }
  //   };

  //   document.addEventListener("click", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);

  if (showLoader?.loading || isBusy) {
    return <ListLoader />;
  }
  return (
    <main>
      <div bsClass="" className="container-fluid minHeightWIB">
        <Row>
          <Col xs={9} className="mt-2">
            <h1 bsClass="" className="sPageHeading1">
              Related CIs
            </h1>
          </Col>
          {cmdbChangeRelatedDataDetails?.length !== 0 && (
            <Col xs={3}>
              <div className="toprigLnk">
                <Button
                  onClick={() =>
                    setOrientation(
                      orientation === "horizontal" ? "vertical" : "horizontal"
                    )
                  }
                >
                  {orientation === "horizontal"
                    ? "Vertical View"
                    : "Horizontal View"}
                </Button>
              </div>
            </Col>
          )}
        </Row>
        <Row>
          {tData[0]?.name?.trim() ==="" && cmdbChangeRelatedDataDetails?.length == 0 ? (
            <Col xs={12} className="text-center">
              You don't have access to this CI
            </Col>
          ) : (
            <Col xs={showrel ? 8 : 12}>
              <div
                style={containerStyles}
                id="treeWrapper"
                ref={containerRef}
                className="tree-container position-relative"
              >
                {tData[0]?.name !== "" && (
                  <Tree
                    ref={treeRef}
                    hasInteractiveNodes={true}
                    data={tData}
                    orientation={orientation}
                    nodeSvgShape={svgSquare}
                    collapsible={true}
                    nodeSize={nodeSize}
                    separation={{ siblings: 1, nonSiblings: 1 }}
                    translate={{
                      x:
                        orientation === "horizontal"
                          ? 150
                          : showrel && orientation === "vertical"
                          ? translateX - 200
                          : translateX,
                      y: 200,
                    }}
                    //nodeSize={{ x: 200, y: 120 }}
                    renderCustomNodeElement={customNodeFnMapping["svg"].fn}
                    onLinkMouseOver={handleMouseOverLink}
                    onLinkMouseOut={handleMouseOutLink}
                    zoomable={false}
                    zoom={zoomable}
                    // zoomAndPan={false}
                    scaleExtent={{ min: scaleExtent.min, max: scaleExtent.max }}
                    shouldCollapseNeighborNodes={true}
                  />
                )}

                {tooltip.isVisible && (
                  <Tooltip x={tooltip.x} y={tooltip.y} text={tooltip.text} />
                )}
              </div>
            </Col>
          )}

          {showrel && (
            <Col md={4} sm={12} xs={12} className="colRightHeight">
              <div className="stickyArea rBoxStyle">
                <CMDBRelationship
                  treeview
                  translator={tr}
                  ciId={viewFormRelId}
                  handleShowhide={handleShowhide}
                  module={module}
                  setModule={setModule}
                />
              </div>
            </Col>
          )}
        </Row>
      </div>

      {/* TODO */}
      {/* {cmdbChangeRelatedDataDetails?.length !== 0 && (
        <div className="zoom-in-tree">
          <button
            onClick={() => {
              setScaleExtent({ min: 0.1, max: 1 });
            }}
            className="zoom-btn"
            disabled={scaleExtent?.min <= 0.1}
          >
            <i className="fa fa-undo" />
          </button>
          <button
            onClick={handleZoomIn}
            className="border-top zoom-btn"
            disabled={scaleExtent?.min >= 10}
          >
            <i className="fa fa-plus" />
          </button>
          <button
            className="border-top zoom-btn"
            onClick={handleZoomOut}
            disabled={scaleExtent?.min <= 0.1}
          >
            <i className="fa fa-minus" />
          </button>
        </div>
      )} */}
    </main>
  );
};

export default CmdbVisualCI;
