
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import AutoSuggest from "react-autosuggest";
import { GLOBAL } from "_Globals";
import { connect } from "react-redux";
import {
  loadBreakFixSGAEChangeSuccess,
  loadBreakFixSGAESuccess,
  loadChangeEditData,
} from "../../../actions/changeManagement/changeManagementAction";
import axios from "axios";
let selectOptions = [];

const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

const getSuggestions = (value, options) => {
  //alert(value);
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  if (inputLength > 2) {
    selectOptions = options;
  }
  return inputLength < 3
    ? []
    : selectOptions.filter(
        (option) => option.label.toLowerCase().indexOf(inputValue) > -1
      );
};

const getSuggestionValue = (suggestion) =>
  suggestion.label.indexOf == -1
    ? ""
    : suggestion.label.substring(0, suggestion.label.indexOf("|"));
//alert("getSuggestionValue"+suggestion.value)

class RFReactSelect extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onSuggestionsFetchRequested =
      this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested =
      this.onSuggestionsClearRequested.bind(this);
    this.onServiceNameSelected = this.onServiceNameSelected.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
    this.state = {
      value: "",
      suggestions: [],
    };
    this.validateField = this.validateField.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedOption !== this.props.selectedOption) {
      this.setState({ value: nextProps.selectedOption });
    }
    if(typeof nextProps.onCreateUsingSelect!=='undefined' && typeof this.props.onCreateUsingSelect!=='undefined'){
    if(nextProps.onCreateUsingSelect!=this.props.onCreateUsingSelect){
      this.setState({value:''});
      GLOBAL.ciName="";
      GLOBAL.ciId="";
    }
    }
  }

  renderSuggestion(suggestion) {
    //alert("val::"+suggestion.value+"label"+suggestion.label)
    let suggestionlabel = [];
    suggestionlabel = suggestion.label.split("|");
    //  console.log("val::"+suggestion.value+"label::"+suggestionlabel[3])
    //alert("suggestion.value"+suggestion.value)

    let ciNameKey = "";
    let ciNameStr = "";
    ciNameStr =
      suggestion.ciName != null && suggestion.ciName != ""
        ? suggestion.ciName
        : "";
    ciNameKey =
      ciNameStr != ""
        ? ciNameStr
            .replace(
              /(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/gi,
              ""
            )
            .trim()
        : "";
    ciNameKey = ciNameKey != "" ? ciNameKey.replace(/\s+/g, " ") : "";

    if (this.props.view == "CreateRfc") {
      return (
        <Button
          bsPrefix=" "
          className="suggBtn"
          onClick={() => {
            GLOBAL.ciIdRfc = suggestion.ciId;
            GLOBAL.ciNameRfc = suggestion.ciName;
            GLOBAL.ciIdNumberRfc = suggestion.ciIdNumber;
            GLOBAL.ciClassRfc = suggestion.ciClass;
            GLOBAL.categoryNameRfc = suggestion.categoryName;
            GLOBAL.environmentNameRfc = suggestion.environmentName;
            GLOBAL.ciConsumerCompanyRfc = suggestion.ciConsumerCompany;
            GLOBAL.ciSupportCompanyRfc = suggestion.ciSupportCompany;
            GLOBAL.ciClassIdRfc = suggestion.ciClassId;
            GLOBAL.ciClassNameRfc = suggestion.ciClass;
            GLOBAL.ciLocationIdRfc = suggestion.ciLocationId;
            GLOBAL.ciLocationNameRfc = suggestion.ciLocationName;
            GLOBAL.consumerCompanyNameRfc = suggestion.ciCompany;
            GLOBAL.consumerCompanyIdRfc = suggestion.ciConsumerCompany;
            GLOBAL.consumerCompanybreakfixRfc = suggestion.ciConsumerCompany;
            GLOBAL.businessCriticalityId =
              suggestion.businessCriticalityId === "" ||
              suggestion.businessCriticalityId === null
                ? 0
                : suggestion.businessCriticalityId;
            GLOBAL.businessCriticality = suggestion.businessCriticality;
          }}
        >
          {/* {suggestion.label} */}
          {ciNameKey.slice(0, 70)}
          {ciNameKey.length > 70 ? "..." : ""}
          <div>
            {"Serial Number"}
            {" - "}
            {suggestion.serialNumber == "" || suggestion.serialNumber==null  ? "NA" : suggestion.serialNumber}
          </div>
          <div>
            {suggestion.ciClass}
            {" - "}
            {suggestion.ciCompany}
          </div>
        </Button>
      );
    } else {
      return (
        <Button
          bsPrefix=" "
          className="suggBtn"
          onClick={() => {
            GLOBAL.ciId = suggestion.ciId;
            GLOBAL.ciName = suggestion.ciName;
            GLOBAL.ciIdNumber = suggestion.ciIdNumber;
            GLOBAL.ciClass = suggestion.ciClass;
            GLOBAL.categoryName = suggestion.categoryName;
            GLOBAL.environmentName = suggestion.environmentName;
            GLOBAL.ciConsumerCompany = suggestion.ciConsumerCompany;
            GLOBAL.ciSupportCompany = suggestion.ciSupportCompany;
            GLOBAL.ciClassId = suggestion.ciClassId;
            GLOBAL.ciClassName = suggestion.ciClass;
            GLOBAL.ciLocationId = suggestion.ciLocationId;
            GLOBAL.ciLocationName = suggestion.ciLocationName;
            GLOBAL.consumerCompanyName = suggestion.ciCompany;
            GLOBAL.consumerCompanyId = suggestion.ciConsumerCompany;
            GLOBAL.consumerCompanybreakfix = suggestion.ciConsumerCompany;
            GLOBAL.businessCriticalityId =
              suggestion.businessCriticalityId === "" ||
              suggestion.businessCriticalityId === null
                ? 0
                : suggestion.businessCriticalityId;
            GLOBAL.businessCriticality = suggestion.businessCriticality;
            GLOBAL.serialNumber = suggestion.serialNumber;
          }}
        >
          {/* {suggestion.label} */}
          {ciNameKey.slice(0, 70)}
          {ciNameKey.length > 70 ? "..." : ""}
          <div>
            {"Serial Number"}
            {" - "}
            {suggestion.serialNumber == "" || suggestion.serialNumber==null  ? "NA" : suggestion.serialNumber}
          </div>
          <div>
            {suggestion.ciClass}
            {" - "}
            {suggestion.ciCompany}
          </div>
        </Button>
      );
    }
  }
  onChange(event, { newValue }) {
    this.setState({
      value: newValue,
    });
    if(typeof this.props.onType!=='undefined' && newValue!==''){
      if(newValue.localeCompare(this.state.value)){
        this.props.onType(false);
      }
    }
    if (newValue === "") {
      if (typeof this.props.clearGroupandIndividual !== "undefined") {
        this.props.clearGroupandIndividual();
      }
      if(typeof this.props.showImpactedCIInfoIcon !== 'undefined'){
        this.props.showImpactedCIInfoIcon(false);
      }
      if(typeof this.props.showImpactedCIDetails !== 'undefined'){
        this.props.showImpactedCIDetails(false);
      }
    }
  }
  onSuggestionsFetchRequested({ value }) {
    //alert(value)
    if(this.state.value.length===0 && typeof this.props.onType!=='undefined'){
      this.props.onType(false);
    }
    if (
      (typeof this.props.flagBreafix !== "undefined" &&
        this.props.companyId === "") ||
      (typeof this.props.onCreateUsingSelect !== "undefined" &&
        this.props.companyId === "") ||
      (typeof this.props.createChange !== "undefined" &&
        this.props.companyId === "")
    ) {
      return;
    } else {
      let impactedID;
      let ciNameDataDetails = [];
      let ciNameData = [];
      let ciNameObject = {};
      if (value.length > 2) {
        api
          .get(
            GLOBAL.ciNameDetailsUrl +
              "?searchByList=STATUS,SUB_STATUS&multipleValueList=7|12&searchValue=" +
              value +
              "&companyId=" +
              this.props.companyId
          )
          .then((response) => {
            //alert("hi")
            ciNameData = response.data;
            for (var i = 0; i < ciNameData.length; i++) {
              ciNameObject = {
                ciId: "",
                label: "",
                ciName: "",
                ciClass: "",
                ciCompany: "",
                ciIdNumber: "",
                serialNumber: "",
              };
              ciNameObject.ciId = ciNameData[i].CI_ID;
              ciNameObject.label =
                ciNameData[i].CI_NAME +
                "|" +
                ciNameData[i].CLASS_NAME +
                "|" +
                ciNameData[i].COMPANY_NAME;
              ciNameObject.ciName = ciNameData[i].CI_NAME;
              ciNameObject.ciClass = ciNameData[i].CLASS_NAME;
              ciNameObject.ciCompany = ciNameData[i].COMPANY_NAME;
              ciNameObject.ciIdNumber = ciNameData[i].CI_CODE;
              ciNameObject.categoryName = ciNameData[i].CATEGORY_NAME;
              ciNameObject.environmentName = ciNameData[i].ENVIRONMENT_NAME;
              ciNameObject.serialNumber= ciNameData[i].SERIAL_NUMBER;
              ciNameObject.ciConsumerCompany = ciNameData[i].COMPANY_ID;
              ciNameObject.ciSupportCompany = ciNameData[i].SUPPORT_COMPANY_ID;
              ciNameObject.ciClassId = ciNameData[i].CLASS_ID;
              //GLOBAL.consumerCompanybreakfix=impactedServiceData[i].companyName;
              ciNameObject.ciLocationId = ciNameData[i].LOCATION_ID;
              ciNameObject.ciLocationName = ciNameData[i].LOCATION_NAME;
              ciNameObject.businessCriticalityId = ciNameData[i].METALLIC;
              ciNameObject.businessCriticality = ciNameData[i].METALLIC_NAME;
              ciNameDataDetails.push(ciNameObject);
            }
            ciNameDataDetails = Array.from(new Set(ciNameDataDetails));
            // if(GLOBAL.ciClassBlank!="")
            // {
            //   ciNameDataDetails[3].ciClass="";
            // }
            this.setState({
              suggestions: getSuggestions(value, ciNameDataDetails),
            });
          });
      } else {
        this.setState({
          suggestions: [],
        });
        if (this.state.value == "") {
          GLOBAL.ciId = "";
          GLOBAL.ciName = "";
          GLOBAL.ciIdNumber = "";
          GLOBAL.categoryName = "";
          GLOBAL.environmentName = "";
          GLOBAL.serialNumber = "",
          GLOBAL.ciConsumerCompany = "";
          GLOBAL.ciSupportCompany = "";
          GLOBAL.ciClassId = "";
          GLOBAL.ciClassName = "";
          GLOBAL.ciLocationId = "";
          GLOBAL.ciLocationName = "";
        }
      }
      // this.setState({
      //   suggestions: getSuggestions(value,this.props.options)
      // });
    }
  }

  onServiceNameSelected(event, { suggestion }) {
    if (typeof this.props.onImpactedSelection !== "undefined") {
      this.props.onImpactedSelection(
        GLOBAL.ciId,
        GLOBAL.ciConsumerCompany,
        suggestion
      );
    }
    // if (GLOBAL.ciId != "") {
    //   GLOBAL.ciId = "";
    // }
  }
  validateField() {
    // if(this.props.onCreateUsingSelect=="service")
    // {

    // }
    // else{
    if (this.props.view !== "CreateRfc" && GLOBAL.ciId == "") {
      this.props.setErrorImpactedColor("error");
    } else if (this.props.view === "CreateRfc" && GLOBAL.ciIdRfc == "") {
      this.props.setErrorImpactedColor("error");
    } else {
      this.props.setErrorImpactedColor("");
    }
    //}
  }
  onSuggestionsClearRequested() {
    this.setState({
      suggestions: [],
    });
    if (this.state.value == "") {
      GLOBAL.ciId = "";
      GLOBAL.ciName = "";
      GLOBAL.ciIdNumber = "";
      GLOBAL.categoryName = "";
      GLOBAL.environmentName = "";
      GLOBAL.serialNumber= "";
      GLOBAL.ciConsumerCompany = "";
      GLOBAL.ciSupportCompany = "";
      GLOBAL.ciClassId = "";
      GLOBAL.ciClassName = "";
      GLOBAL.ciLocationId = "";
      GLOBAL.ciLocationName = "";
      if (typeof this.props.checkforAMSattributes !== "undefined") {
        this.props.checkforAMSattributes("", "");
      }
    }
  }
  // componentDidUpdate()
  // {
  //  console.log("looo",this.props,GLOBAL.ciId,GLOBAL.ciName);

  // }

  // componentWillReceiveProps(nextProps)
  // {
  //   // console.log("COMPONENTWILLRECIBVE",nextProps);
  //   // console.log("this.props.onCreateUsingSelect : ",this.props.onCreateUsingSelect)
  //   // console.log("nextProps.onCreateUsingSelect  : ",nextProps.onCreateUsingSelect)
  //   // if(nextProps.onCreateUsingSelect!=this.props.onCreateUsingSelect){
  //   // this.setState({
  //   //   value:''
  //   // })
  //   //GLOBAL.ciName="";
  //   //GLOBAL.ciId="";
  // //}

  //   console.log("nextprops",this.props.onCreateUsingSelect);
  // }
  render() {
    //alert(this.state.value);
    const { value, suggestions } = this.state;
    let ciNamevalue = "";
    if (GLOBAL.ciName != "" || GLOBAL.ciName != undefined) {
      //alert("=="+GLOBAL.ciName)
      ciNamevalue = GLOBAL.ciName;
    } else {
      ciNamevalue = "";
    }
    const inputProps = {
      //placeholder: ciNamevalue,
      placeholder:
        this.props.placeHolder != "" && this.props.placeHolder != undefined
          ? this.props.placeHolder
          : "Enter CI Name",
      className: "form-control " + (this.props.errorColor || ""),
      value: value,
      onChange: this.onChange,
      onBlur: this.validateField,
      disabled: this.props.disabled,
    };

    return (
      <AutoSuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={this.onServiceNameSelected}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return { state };
};
export default connect(mapStateToProps, {
  loadBreakFixSGAEChangeSuccess,
  loadBreakFixSGAESuccess,
  loadChangeEditData,
})(RFReactSelect);
