
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import {
  _inputField,
  _textArea,
  _dropDown,
  _dropDown3,
  _dateField,
  _dropDown2,
} from "../../../serviceDesk/common/formFields";
import { Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { loadSlaWrkDayScheduleStatus } from "../../../../actions/sla/lovAction";
import { loadCICompany } from "../../../../actions/cmdb/lovAction";
import { connect } from "react-redux";
import { loadTimeZone } from "../../../../actions/common/commonApisActions";
import {
  Field,
  reduxForm,
  formValueSelector,
  change,
  SubmissionError,
} from "redux-form";
// import Select from 'react-select';
import { GLOBAL } from "_Globals";
import _ from "lodash";
import LoadingIcons from "react-loading-icons";
//let user_id=userDetails.user_id;
import DropdownPlusTypeahead from "../../common/dropdownPlusTypeahead";
import Swal from "sweetalert2";
import { TypeaheadandDropdown } from "../../../common/formFields";
let SelelectedCompany = [];
/* let user_id = 3978 */ //data not coming for tom_consumer user hardcoding value for now..
const validate = (values) => {
  let errors = {};
  if (values.scheduleCompanyName == "" || values.scheduleCompany == 0 || values.scheduleCompany == undefined) {
    errors.scheduleCompany = "Enter company";
  }
  if (!values.scheduleName || values.scheduleName.trim() == "") {
    errors.scheduleName = "Enter schedule name";
  }
  if (values.scheduleStatus == "0") {
    errors.scheduleStatus = "Enter schedule status";
  }

  if (!values.workdayScheduleTimeZone) {
    errors.workdayScheduleTimeZone = "Enter time zone";
  }

  // if (
  //   values.SundayStartTime &&
  //   moment(values.SundayStartTime, "HH:mm").isValid()
  // ) {
  //   if (
  //     values.SundayEndTime &&
  //     moment(values.SundayEndTime, "HH:mm").isValid()
  //   ) {
  //     if (
  //       moment(values.SundayStartTime, "HH:mm").isAfter(
  //         moment(values.SundayEndTime, "HH:mm")
  //       )
  //     ) {
  //       errors.SundayStartTime = "sunday start time is greater then end time.";
  //     }
  //   } else {
  //     errors.SundayEndTime = "enter end time for sunday.";
  //   }
  // }
  // if (values.SundayEndTime && moment(values.SundayEndTime, "HH:mm").isValid()) {
  //   if (
  //     values.SundayStartTime &&
  //     moment(values.SundayStartTime, "HH:mm").isValid()
  //   ) {
  //     if (
  //       moment(values.SundayStartTime, "HH:mm").isAfter(
  //         moment(values.SundayEndTime, "HH:mm")
  //       )
  //     ) {
  //       errors.SundayStartTime = "sunday start time is greater then end time.";
  //     }
  //   } else {
  //     errors.SundayStartTime = "enter start time for sunday";
  //   }
  // }

  // if (
  //   values.MondayStartTime &&
  //   moment(values.MondayStartTime, "HH:mm").isValid()
  // ) {
  //   if (
  //     values.MondayEndTime &&
  //     moment(values.MondayEndTime, "HH:mm").isValid()
  //   ) {
  //     if (
  //       moment(values.MondayStartTime, "HH:mm").isAfter(
  //         moment(values.MondayEndTime, "HH:mm")
  //       )
  //     ) {
  //       errors.MondayStartTime = "monday start time is greater then end time.";
  //     }
  //   } else {
  //     errors.MondayEndTime = "enter end time for monday.";
  //   }
  // }
  // if (values.MondayEndTime && moment(values.MondayEndTime, "HH:mm").isValid()) {
  //   if (
  //     values.MondayStartTime &&
  //     moment(values.MondayStartTime, "HH:mm").isValid()
  //   ) {
  //     if (
  //       moment(values.MondayStartTime, "HH:mm").isAfter(
  //         moment(values.MondayEndTime, "HH:mm")
  //       )
  //     ) {
  //       errors.MondayStartTime = "Monday start time is greater then end time.";
  //     }
  //   } else {
  //     errors.MondayStartTime = "enter start time for Monday";
  //   }
  // }

  // if (
  //   values.TuesdayStartTime &&
  //   moment(values.TuesdayStartTime, "HH:mm").isValid()
  // ) {
  //   if (
  //     values.TuesdayEndTime &&
  //     moment(values.TuesdayEndTime, "HH:mm").isValid()
  //   ) {
  //     if (
  //       moment(values.TuesdayStartTime, "HH:mm").isAfter(
  //         moment(values.TuesdayEndTime, "HH:mm")
  //       )
  //     ) {
  //       errors.TuesdayStartTime =
  //         "Tuesday start time is greater then end time.";
  //     }
  //   } else {
  //     errors.TuesdayEndTime = "enter end time for Tuesday.";
  //   }
  // }
  // if (
  //   values.TuesdayEndTime &&
  //   moment(values.TuesdayEndTime, "HH:mm").isValid()
  // ) {
  //   if (
  //     values.TuesdayStartTime &&
  //     moment(values.TuesdayStartTime, "HH:mm").isValid()
  //   ) {
  //     if (
  //       moment(values.TuesdayStartTime, "HH:mm").isAfter(
  //         moment(values.TuesdayEndTime, "HH:mm")
  //       )
  //     ) {
  //       errors.TuesdayStartTime =
  //         "Tuesday start time is greater then end time.";
  //     }
  //   } else {
  //     errors.TuesdayStartTime = "enter start time for Tuesday";
  //   }
  // }

  // if (
  //   values.WednesdayStartTime &&
  //   moment(values.WednesdayStartTime, "HH:mm").isValid()
  // ) {
  //   if (
  //     values.WednesdayEndTime &&
  //     moment(values.WednesdayEndTime, "HH:mm").isValid()
  //   ) {
  //     if (
  //       moment(values.WednesdayStartTime, "HH:mm").isAfter(
  //         moment(values.WednesdayEndTime, "HH:mm")
  //       )
  //     ) {
  //       errors.WednesdayStartTime =
  //         "Wednesday start time is greater then end time.";
  //     }
  //   } else {
  //     errors.WednesdayEndTime = "enter end time for Wednesday.";
  //   }
  // }
  // if (
  //   values.WednesdayEndTime &&
  //   moment(values.WednesdayEndTime, "HH:mm").isValid()
  // ) {
  //   if (
  //     values.WednesdayStartTime &&
  //     moment(values.WednesdayStartTime, "HH:mm").isValid()
  //   ) {
  //     if (
  //       moment(values.WednesdayStartTime, "HH:mm").isAfter(
  //         moment(values.WednesdayEndTime, "HH:mm")
  //       )
  //     ) {
  //       errors.WednesdayStartTime =
  //         "Wednesday start time is greater then end time.";
  //     }
  //   } else {
  //     errors.WednesdayStartTime = "enter start time for Wednesday";
  //   }
  // }

  // if (
  //   values.ThursdayStartTime &&
  //   moment(values.ThursdayStartTime, "HH:mm").isValid()
  // ) {
  //   if (
  //     values.ThursdayEndTime &&
  //     moment(values.ThursdayEndTime, "HH:mm").isValid()
  //   ) {
  //     if (
  //       moment(values.ThursdayStartTime, "HH:mm").isAfter(
  //         moment(values.ThursdayEndTime, "HH:mm")
  //       )
  //     ) {
  //       errors.ThursdayStartTime =
  //         "Thursday start time is greater then end time.";
  //     }
  //   } else {
  //     errors.ThursdayEndTime = "enter end time for Thursday.";
  //   }
  // }
  // if (
  //   values.ThursdayEndTime &&
  //   moment(values.ThursdayEndTime, "HH:mm").isValid()
  // ) {
  //   if (
  //     values.ThursdayStartTime &&
  //     moment(values.ThursdayStartTime, "HH:mm").isValid()
  //   ) {
  //     if (
  //       moment(values.ThursdayStartTime, "HH:mm").isAfter(
  //         moment(values.ThursdayEndTime, "HH:mm")
  //       )
  //     ) {
  //       errors.ThursdayStartTime =
  //         "Thursday start time is greater then end time.";
  //     }
  //   } else {
  //     errors.ThursdayStartTime = "enter start time for Thursday";
  //   }
  // }

  // if (
  //   values.FridayStartTime &&
  //   moment(values.FridayStartTime, "HH:mm").isValid()
  // ) {
  //   if (
  //     values.FridayEndTime &&
  //     moment(values.FridayEndTime, "HH:mm").isValid()
  //   ) {
  //     if (
  //       moment(values.FridayStartTime, "HH:mm").isAfter(
  //         moment(values.FridayEndTime, "HH:mm")
  //       )
  //     ) {
  //       errors.FridayStartTime = "Friday start time is greater then end time.";
  //     }
  //   } else {
  //     errors.FridayEndTime = "enter end time for Friday.";
  //   }
  // }
  // if (values.FridayEndTime && moment(values.FridayEndTime, "HH:mm").isValid()) {
  //   if (
  //     values.FridayStartTime &&
  //     moment(values.FridayStartTime, "HH:mm").isValid()
  //   ) {
  //     if (
  //       moment(values.FridayStartTime, "HH:mm").isAfter(
  //         moment(values.FridayEndTime, "HH:mm")
  //       )
  //     ) {
  //       errors.FridayStartTime = "Friday start time is greater then end time.";
  //     }
  //   } else {
  //     errors.FridayStartTime = "enter start time for Friday";
  //   }
  // }

  // if (
  //   values.SaturdayStartTime &&
  //   moment(values.SaturdayStartTime, "HH:mm").isValid()
  // ) {
  //   if (
  //     values.SaturdayEndTime &&
  //     moment(values.SaturdayEndTime, "HH:mm").isValid()
  //   ) {
  //     if (
  //       moment(values.SaturdayStartTime, "HH:mm").isAfter(
  //         moment(values.SaturdayEndTime, "HH:mm")
  //       )
  //     ) {
  //       errors.SaturdayStartTime =
  //         "Saturday start time is greater then end time.";
  //     }
  //   } else {
  //     errors.SaturdayEndTime = "enter end time for Saturday.";
  //   }
  // }
  // if (
  //   values.SaturdayEndTime &&
  //   moment(values.SaturdayEndTime, "HH:mm").isValid()
  // ) {
  //   if (
  //     values.SaturdayStartTime &&
  //     moment(values.SaturdayStartTime, "HH:mm").isValid()
  //   ) {
  //     if (
  //       moment(values.SaturdayStartTime, "HH:mm").isAfter(
  //         moment(values.SaturdayEndTime, "HH:mm")
  //       )
  //     ) {
  //       errors.SaturdayStartTime =
  //         "Saturday start time is greater then end time.";
  //     }
  //   } else {
  //     errors.SaturdayStartTime = "enter start time for Saturday";
  //   }
  // }
  return errors;
};

let workdayScheduleEditForm = class workdayScheduleEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      CompanyError: "",
      CompanyName: [],
      companyCleared: false,
      inputValueCompany: "",
    };
    // this.handleTimeChange = this.handleTimeChange.bind(this);
    // this.renderTimePicker = this.renderTimePicker.bind(this);
    // this.generateTimePickerRows = this.generateTimePickerRows.bind(this);
    // this.showDaysLable = this.showDaysLable.bind(this);
    this.setTimeZoneOffSet = this.setTimeZoneOffSet.bind(this);
    this.companySelection = this.companySelection.bind(this);
    this.CompanyError = this.CompanyError.bind(this);
    this.onCompanyInputChange = this.onCompanyInputChange.bind(this);
    this.onCrossClickCompany = this.onCrossClickCompany.bind(this);
  }
  componentWillMount() {
    this.props.loadTimeZone();
    this.props.loadCICompany();
    this.props.loadSlaWrkDayScheduleStatus("status");
    this.setState({ inputValueCompany: "2" });
  }

  // handleTimeChange(time, scheduledDay) {
  //   this.props.dispatch(
  //     change(
  //       "workdayScheduleEditForm",
  //       scheduledDay,
  //       moment(time).format("HH:mm")
  //     )
  //   );
  // }

  // renderTimePicker(scheduledDay) {
  //   // console.log("OldUIValues",scheduledDay,moment(this.props.initialValues[scheduledDay], "HH:mm"))
  //   return (
  //     <Field
  //       name={scheduledDay}
  //       scheduledDay={scheduledDay}
  //       handleTimeChange={this.handleTimeChange}
  //       component={_dateField}
  //       className="form-control"
  //       formType="timePicker"
  //       defaultValue={
  //         this.props.initialValues[scheduledDay]
  //           ? moment(this.props.initialValues[scheduledDay], "HH:mm")
  //           : null
  //       }
  //     />
  //   );
  // }

  // showDaysLable(day) {
  //   switch (day) {
  //     case 0:
  //       return this.props.translator["Sunday"];
  //     case 1:
  //       return this.props.translator["Monday"];
  //     case 2:
  //       return this.props.translator["Tuesday"];
  //     case 3:
  //       return this.props.translator["Wednesday"];
  //     case 4:
  //       return this.props.translator["Thursday"];
  //     case 5:
  //       return this.props.translator["Friday"];
  //     case 6:
  //       return this.props.translator["Saturday"];
  //   }
  // }
  // generateTimePickerRows() {
  //   let timePickerRows = [];
  //   for (let day = 0; day <= 6; day++) {
  //     let content = (
  //       <Row className="margin-0 worksch">
  //         <Col lg={3} sm={2} md={2} xs={2}>
  //           <Form.Group>
  //             <Form.Label bsClass="">{this.showDaysLable(day)}</Form.Label>
  //           </Form.Group>
  //         </Col>
  //         <Col lg={4} sm={5} md={5} xs={5}>
  //           <Form.Group>
  //             <div className="dateTimeSdiv">
  //               {this.renderTimePicker(this.showDaysLable(day) + "StartTime")}
  //             </div>
  //           </Form.Group>
  //         </Col>
  //         <Col lg={4} sm={5} md={5} xs={5}>
  //           <Form.Group>
  //             <div className="dateTimeSdiv">
  //               {this.renderTimePicker(this.showDaysLable(day) + "EndTime")}
  //             </div>
  //           </Form.Group>
  //         </Col>
  //       </Row>
  //     );
  //     timePickerRows.push(content);
  //   }
  //   return timePickerRows;
  // }
  setTimeZoneOffSet(time, timeZoneData) {
    let offSet = "";
    timeZoneData.forEach((item) => {
      if (item.field1Key == time)
        offSet = item.field1Value;
    });
    if (time == "0") {
      this.props.dispatch(
        change("createWorkDayScheduleForm", "timeZoneOffset", "GMT")
      );
    } else if (offSet == "") {
      Swal.fire("error setting time offset");
    } else {
      this.props.dispatch(
        change("createWorkDayScheduleForm", "timeZoneOffset", offSet)
      );
    }
  }
  companySelection(companySelelected) {
    if (companySelelected.length > 0) {
      SelelectedCompany = companySelelected;
      this.props.dispatch(
        change(
          "workdayScheduleEditForm",
          "scheduleCompanyName",
          companySelelected[0].label
        )
      );
      this.props.dispatch(
        change(
          "workdayScheduleEditForm",
          "scheduleCompany",
          companySelelected[0].id
        )
      );
      GLOBAL.WorkScheduleCompanyEditError = "";
      this.setState({ inputValueCompany: companySelelected[0].label });
    } else {
      this.setState({
        companyCleared: true,
      });
      SelelectedCompany = [];
      this.props.dispatch(
        change("workdayScheduleEditForm", "scheduleCompanyName", "")
      );
      this.props.dispatch(
        change("workdayScheduleEditForm", "scheduleCompany", 0)
      );
      GLOBAL.WorkScheduleCompanyEditError = "error";
    }
  }
  onCrossClickCompany() {
    SelelectedCompany = [];
    this.setState({
      companyCleared: true,
    });
    this.props.dispatch(
      change("workdayScheduleEditForm", "scheduleCompanyName", "")
    );
    this.props.dispatch(
      change("workdayScheduleEditForm", "scheduleCompany", 0)
    );
    GLOBAL.WorkScheduleCompanyEditError = "error";
    this.setState({
      inputValueCompany: "",
      typedValue: '' });
  }
  onCompanyInputChange(val) {
    this.setState({ inputValueCompany: val });
  }
  CompanyError() {
    console.log("SelelectedCompany.length", SelelectedCompany.length);
    if (SelelectedCompany.length == 0) {
      this.setState({ CompanyError: "error" });
    } else {
      this.setState({ CompanyError: "" });
    }
    GLOBAL.WorkScheduleCompanyEditError = "";
  }
  render() {
    if (_.isEmpty(this.props.slaWorkScheduleDetails) || this.props.isFetching) {
      return (
        <div className="rctLoade">
          <LoadingIcons.Bars height="5em" stroke="transparent" fill="#06bcee" />
        </div>
      );
    }
    let CompanySelect = [];
    if (
      this.props.slaWorkScheduleDetails.scheduleCompany != undefined &&
      this.props.slaWorkScheduleDetails.scheduleCompanyName != undefined &&
      this.state.companyCleared == false
    ) {
      let obj = {};
      obj.id = this.props.slaWorkScheduleDetails.scheduleCompany;
      obj.label = this.props.slaWorkScheduleDetails.scheduleCompanyName;
      CompanySelect.push(obj);
      SelelectedCompany = CompanySelect;
    }

    let companyData = [];
    this.props.companies.forEach((item, i) => {
      let obj = {};
      obj.id = item.field1Value;
      obj.label = item.field1Key;
      companyData.push(obj);
    });
    return (
      <form>
        <div>
          <div className="borderForm bFormDiv">
            <Row>
              <Col md={6} sm={6} xs={12}>
                {/* <div className="padding-t-5 padding-r-10  padding-l-10 margin-b-15 border">
                  <Row className="newFbg">
                    <Col xs={12}>
                      <Form.Group>
                        <Form.Label bsClass="">
                          {this.props.translator["Definition"]}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row> */}

                  <div className="margin-b-15">
                    <Form.Group>
                      <Form.Label bsClass="">
                        <span className="rStar" />
                        {this.props.translator["Name"]}
                      </Form.Label>
                      <Field
                        name="scheduleName"
                        type="input"
                        component={_inputField}
                        label=""
                        className="form-control"
                      />
                    </Form.Group>
                  </div>
                  <div className="margin-b-15">
                    <Form.Group
                      validationState={
                        this.state.CompanyError == ""
                          ? GLOBAL.WorkScheduleCompanyEditError
                          : this.state.CompanyError
                      }
                    >
                      <Form.Label bsClass="">
                        <span className="rStar" />
                        {this.props.translator["Company For"]}
                      </Form.Label>
                      <Field
                        component={TypeaheadandDropdown}
                        onSelection={this.companySelection}
                        setErrorColor={this.CompanyError}
                        id="company"
                        name="scheduleCompany"
                        className="form-control"
                        options={companyData}
                        selectedOptions={SelelectedCompany}
                        onInputChange={this.onCompanyInputChange}
                        inputValue={this.state.inputValueCompany}
                        onCrossClick={this.onCrossClickCompany}
                      />
                    </Form.Group>
                  </div>
                  </Col>
                  <Col md={6} sm={6} xs={12}>
                  <div className="margin-b-15">
                    <Form.Group>
                      <Form.Label bsClass="">
                        <span className="rStar" />
                        {this.props.translator["Status"]}
                      </Form.Label>
                      <Field
                        name="scheduleStatus"
                        component={_dropDown}
                        options={this.props.status}
                        className="form-control"
                        onChange={(event) =>
                          this.props.dispatch(
                            change(
                              "workdayScheduleEditForm",
                              "scheduleStatusName",
                              event.target[event.target.selectedIndex].text
                            )
                          )
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className="margin-b-15">
                    <Form.Group>
                      <Form.Label bsClass="">
                        {this.props.translator["Time Zone"]}
                      </Form.Label>

                      <Field
                        name="workdayScheduleTimeZone"
                        component={_dropDown3}
                        options={this.props.timeZone}
                        className="form-control"
                        onChange={(event) => {
                          this.props.dispatch(
                            change(
                              "workdayScheduleEditForm",
                              "workdayScheduleTimeZoneLabel",
                              event.target[event.target.selectedIndex].text
                            )
                          );
                          this.setTimeZoneOffSet(
                            event.target.value,
                            this.props.timeZone
                          );
                        }}
                      />
                    </Form.Group>
                  </div>
                {/* </div> */}
              </Col>
              {/* <Col md={8}>
                <div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
                  <Row className="newFbg">
                    <Col lg={3} sm={2} md={2} xs={2}>
                      <Form.Group>
                        <Form.Label bsClass="">
                          {this.props.translator["Day"]}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <Col lg={4} sm={5} md={5} xs={5}>
                      <Form.Group>
                        <Form.Label bsClass="">
                          {this.props.translator["Start Time"]} (hh:mm)
                          <span className="rStar" />
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <Col lg={4} sm={5} md={5} xs={5}>
                      <Form.Group>
                        <Form.Label bsClass="">
                          {this.props.translator["End Time"]} (hh:mm)
                          <span className="rStar" />
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  {this.generateTimePickerRows()}
                </div>
              </Col> */}
            </Row>
          </div>
        </div>
      </form>
    );
  }
};

workdayScheduleEditForm.propTypes = {
  loadCICompany: PropTypes.func,
  loadSlaWrkDayScheduleStatus: PropTypes.func,
};

const mapStateToProps = ({
  ciCompany,
  slaWorkdaySchedulesStatus,
  slaWorkScheduleDetails,
  timeZoneData,
}) => {
  let companies =
    ciCompany == null ||
    ciCompany == undefined ||
    ciCompany.length == 0 ||
    _.isEmpty(ciCompany)
      ? []
      : ciCompany;
  let status =
    slaWorkdaySchedulesStatus == null ||
    slaWorkdaySchedulesStatus == undefined ||
    slaWorkdaySchedulesStatus.length == 0 ||
    _.isEmpty(slaWorkdaySchedulesStatus)
      ? []
      : slaWorkdaySchedulesStatus;
  let timeZone =
    timeZoneData == null ||
    timeZoneData == undefined ||
    timeZoneData.length == 0 ||
    _.isEmpty(timeZoneData)
      ? []
      : timeZoneData;
  const staticFields = [
        { name: 'slaStartDate' },
        { name: 'slaEndDate' },
        { name: 'slaStartTime' },
        { name: 'slaEndTime' },
        { name: 'is24by7' },
        { name: 'scheduleRepeatType' },
        { name: 'daysSelected' }
      ]
  return {
    companies,
    status,
    initialValues: slaWorkScheduleDetails.workdayScheduleDetails,
    slaWorkScheduleDetails: slaWorkScheduleDetails.workdayScheduleDetails,
    isFetching: slaWorkScheduleDetails.isFetching,
    timeZone,
    fields : staticFields
  };
};

workdayScheduleEditForm = reduxForm({
  form: "workdayScheduleEditForm",
  validate,
  enableReinitialize: true,
})(workdayScheduleEditForm);

export default connect(mapStateToProps, {
  loadCICompany,
  loadSlaWrkDayScheduleStatus,
  loadTimeZone,
})(workdayScheduleEditForm);
