
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import { GLOBAL } from '../../components/Globals';

export function loadBreakFixSGISuccess(breakFixSGIName){
	return {
		type: 'LOAD_BREAKFIXSGI_SUCCESS',
		breakFixSGIName
	};
}

export function loadBreakFixSGIFailure(errorResponse){
	return {
		type: 'LOAD_BREAKFIXSGI_FAILURE',
		errorResponse
	};
}

export function loadBreakFixSGI(groupId){
	//alert("groupId"+groupId)
	return(dispatch) =>{
		if(groupId == "" || groupId == undefined||groupId=='0'){
			console.log("groupId not found");
		}else{
				axios.get(GLOBAL.breakFixSGroupIUrl+groupId)
					.then((breakFixSGIName)=>{
						if(!breakFixSGIName){
							throw Error(breakFixSGIName.statusText);
						}
						return breakFixSGIName;
					})
					.then((breakFixSGIName) =>{
						if(GLOBAL.fulfillmentPageLoad===true){
							let typeaheadData=[];
							if(breakFixSGIName.data!==""){
							  breakFixSGIName.data.map((item)=>{
                                  typeaheadData.push({'value':''+item.field1Value+'','label':item.field1Key}); 
							  });
						    }
							dispatch({type:'LOAD_BREAKFIXSGI_SUCCESS_TYPEAHEAD',typeaheadData})
						}
						dispatch(loadBreakFixSGISuccess(breakFixSGIName));
					})
					.catch((err) => {
						console.log(err);
					});
				}
	};
}

export function loadChangeManagementIndividual(groupId){
	//alert("groupId"+groupId)
	return(dispatch) =>{
		if(groupId == "" || groupId == undefined){
			console.log("groupId not found");
		}else{
				axios.get(GLOBAL.breakFixSGroupIUrl+groupId)
					.then((breakFixSGIName)=>{
						if(!breakFixSGIName){
							throw Error(breakFixSGIName.statusText);
						}
						return breakFixSGIName;
					})
					.then((breakFixSGIName) =>{
						if(GLOBAL.changepageLoad===true){
							let typeaheadData=[];
							if(breakFixSGIName.data!==''){
								breakFixSGIName.data.map((item)=>{
									typeaheadData.push({'value':''+item.field1Value,'label':item.field1Key});
								});
							}
							dispatch({type:'CHANGEMANAGEMENT_INDIVIDUAL_LOAD_SUCCESS_TYPE',typeaheadData});
						}
						dispatch({type:'CHANGEMANAGEMENT_INDIVIDUAL_LOAD_SUCCESS',breakFixSGIName});

					})
					.catch((err) => {
						console.log(err);
					});
				}
	};
}

export function loadChangeManagementImplGroupIndividual(groupId){
	//alert("groupId"+groupId)
	return(dispatch) =>{
		if(groupId == "" || groupId == undefined){
			console.log("groupId not found");
		}else{
				axios.get(GLOBAL.breakFixSGroupIUrl+groupId)
					.then((breakFixSGIName)=>{
						if(!breakFixSGIName){
							throw Error(breakFixSGIName.statusText);
						}
						return breakFixSGIName;
					})
					.then((breakFixSGIName) =>{
						if(GLOBAL.changepageLoad===true){
							let typeaheadData=[];
							if(breakFixSGIName!==''){
								breakFixSGIName.data.map((item)=>{
									  if(item.assignmentMemberId!==null){
                                        typeaheadData.push({'value': ''+item.field1Value,'label':item.field1Key});
									  }
								});
							}
							dispatch({type:'CHANGEMANAGEMENT_IMPL_INDIVIDUAL_LOAD_SUCCESS_TYPE',typeaheadData});
						}
						dispatch({type:'CHANGEMANAGEMENT_IMPL_INDIVIDUAL_LOAD_SUCCESS',breakFixSGIName});

					})
					.catch((err) => {
						console.log(err);
					});
				}
	};
}
