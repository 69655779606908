
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { connect } from "react-redux";
import ReportConsoleHeader from "./ReportConsoleHeader.js";
import ReportCenter from "./ReportCenter.js";
import axios from "axios";
import { GLOBAL } from "../Globals";
import Cookies from "universal-cookie";
import { navigationHooks } from "../../helpers/NavigationHook.js";
import "_Css/common/_table.scss";

const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home") homepagelocation = "/" + homepagelocation;

const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

class ReportConsoleIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenu: "4",
      showHideAlfy: false,
      showHideRightNav: false,
      user_id: "",
      date_format: "",
    };
    GLOBAL.searchbarcategory = "All";
    this.showHideAlfy = this.showHideAlfy.bind(this);
    this.showHideRightNav = this.showHideRightNav.bind(this);
    this.loadHome = this.loadHome.bind(this);
  }
  loadHome() {
    navigationHooks.navigate(homepagelocation);
  }
  showHideAlfy(value) {
    this.setState({ showHideAlfy: value });
  }
  showHideRightNav(value) {
    this.setState({ showHideRightNav: !this.state.showHideRightNav });
  }
  componentWillMount() {
    let str = {};
    str.user_id = "";
    str = JSON.stringify(str);
    api.get("/api/userInfo", { headers: { query: str } }).then((response) => {
      this.setState({
        user_id: response.data.user_id,
        date_format: response.data.dateformat,
      });
    });
  }
  render() {
    return (
      <div>
        <div className="minHeightWIB">
					<div className="container-fluid margin-t-10 margin-b-15">
						<ul className="breadcrumb">
							<li className="breadcrumb-item">
								<a role="button" onClick={this.loadHome} >{this.props.translator['Home']}</a>
							</li>
							<li className="breadcrumb-item active">{this.props.translator['Reports']}
							</li>
						</ul>
					</div>

					<div className="container-fluid" bsClass="">

						<ReportConsoleHeader translator={this.props.translator} />

						<Row className="row-eq-height">
							<Col xs={12}>
								<ReportCenter translator={this.props.translator} user_id={this.state.user_id} date_format={this.state.date_format} />
							</Col>
						</Row>
					</div>
				</div>
      </div>
    );
  }
}

const mapStateToProps = ({ spcmReducer }) => {
  return {
    translator: spcmReducer.tr,
  };
};

export default connect(mapStateToProps, {})(ReportConsoleIndex);
