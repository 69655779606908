
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Row, Col, Table,  Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { resetFulfillmentPlan, getFulfilmentPlans, setEditServiceDetails, setFullfilmentEditServiceDetails, setForm, getServiceDetailCounts, selectedTaskTemplate, getComponentName } from '../../../actions/spcmActions';
import ComponentForm from './editForm.js';
import ListLoader from "_Commons/loaders/ListLoader";
import axios from 'axios';
import configureStore from '../../../store/configureStore';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
const store = configureStore();
import Cookies from "universal-cookie";
const cookies = new Cookies();

let userCompanyID = cookies.get("gph");
if (userCompanyID) userCompanyID = userCompanyID.replace("s:", "");
if (userCompanyID)
	userCompanyID = userCompanyID.substring(
		0,
		userCompanyID.lastIndexOf(".")
	);
userCompanyID = userCompanyID.split("~");
const userCompanyId = userCompanyID[48];

class ListView extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			sortBy: 'Title',
			deleteError: '',
			sortIcons: false,
			class1: 'sort up',
			class2: 'sort',
			class3: 'sort',
			listingTable: 'initial',
			// fPlanOptions: [
			// 	{ "value": "Select", "label": "Please Select" },
			// 	{ "value": "Fullfilment_Template", "label": "Service Orders" },
			// 	{ "value": "ChangeManagement_Template", "label": "Change Requests" },
			// 	{ "value": "CriticalIncident_Template", "label": "Critical Incident" }
			// ],
			componentTaskSelected: "blank",
			faCustomDesc1: "customArrow dropdown",
			faCustomAsc1: "customArrow dropup",
			sort: true,
			sortOrder: 'asc'

		}
		//this.sortFun = this.sortFun.bind(this);
		// this.onDeleteClick=this.onDeleteClick.bind(this);
		this.handleDismiss = this.handleDismiss.bind(this);
		this.getFullfilmentHandler = this.getFullfilmentHandler.bind(this);
		this.onSortAsc = this.onSortAsc.bind(this);
		this.setSortingArrows = this.setSortingArrows.bind(this);

	}
	handleDismiss() {
		this.setState({ deleteError: '' });
	}
	setSortingArrows(x,y) {
		this.setState({ 
			faCustomDesc1: y,
			faCustomAsc1: x,
		});
	}
	
	/* sortFun(myObj){
		this.setState({ sortIcons:!this.state.sortIcons});
		switch(myObj) {
			case 'class1':
				this.setState({ class1:(this.state.sortIcons ? 'sort up' : 'sort down')});
				this.setState({ class2:(this.state.sortIcons ? 'sort' : 'sort'),sortBy:'Task'});
				this.setState({ class3:(this.state.sortIcons ? 'sort' : 'sort')});
				this.props.getFulfilmentPlans(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,'Task',this.state.sortIcons?'asc':'desc');
			break;
			case 'class2':
				this.setState({ class1:(this.state.sortIcons ? 'sort' : 'sort')});
				this.setState({ class2:(this.state.sortIcons ? 'sort up' : 'sort down'),sortBy:'TaskFor'});
				this.setState({ class3:(this.state.sortIcons ? 'sort' : 'sort')});
				this.props.getFulfilmentPlans(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,'TaskFor',this.state.sortIcons?'asc':'desc');
			break;
			case 'class3':
				this.setState({ class1:(this.state.sortIcons ? 'sort' : 'sort')});
				this.setState({ class2:(this.state.sortIcons ? 'sort' : 'sort')});
				this.setState({ class3:(this.state.sortIcons ?  'sort up' : 'sort down'),sortBy:'Seq'});
				this.props.getFulfilmentPlans(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,'Seq',this.state.sortIcons?'asc':'desc');
			break;
		}

	}; */


	onDeleteClick(id) {
		let payload = {}
		payload.userCompanyId = parseInt(userCompanyId,10);

		if (this.props.serviceStatusState == "Deployed") {
			swal({
				text: "Changes are not allowed in deployed status.",
				button: this.props.translator["OK"],
			  });
		}

		else {
			Swal.fire({
				title: 'Are you sure you wish to delete this item?',
				width: 550,

				padding: '15px',
				showDenyButton: true,
				confirmButtonText: 'Yes',
				denyButtonText: 'No',
			  }).then((result) => {
				if (result.isConfirmed) {
						const OFFERING_ID = this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID;
						//axios.delete(constants.SERVER+constants.SERVICE_FULFILMENT_PLANS+"/"+id+'?offering_id='+OFFERING_ID).then((resp)=>{
					// axios.post("/service/api/deleteFulfilmentPlan/" + id + '/' + OFFERING_ID, payload).then((resp) => {
					axios.post("/service/api/deleteFulfilmentPlan/" + id , payload).then((resp) => {
							console.log(resp.data, 'resp')
							this.props.getFulfilmentPlans(OFFERING_ID, this.state.templateSelected);
							this.props.getServiceDetailCounts(OFFERING_ID);
						}).catch((error) => {
							this.setState({ deleteError: error.response.data[0].message })
						})
					} else if (result.isDenied) {
				  
				}
			  })
		}
	}




	getFullfilmentHandler(fulfillmentPlanFor) {
		this.props.getFulfilmentPlans(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID, fulfillmentPlanFor,'asc');
		this.setState({
			templateSelected: fulfillmentPlanFor,
			listingTable: 'onChange'
		});

	}

	componentWillMount() {
		console.log("ENTERD here");
		this.props.setForm("serviceFulfilmentPlanForm");//serviceFulfilmentPlansForm serviceFulfilmentPlanForm
		this.props.toggleAddButtonStatus(false);
		this.props.resetFulfillmentPlan();
		let filterVal
		{this.props.spcmReducer.currentlySelectedServiceDetails.WORK_ITEM_TYPE == "incidentRequest" ? filterVal = 'CriticalIncident_Template' :
		this.props.spcmReducer.currentlySelectedServiceDetails.WORK_ITEM_TYPE == "standardChange" ? filterVal = 'ChangeManagement_Template' :
		filterVal = 'Fullfilment_Template' }
		this.getFullfilmentHandler(filterVal);
		this.props.toggleAddButtonStatus(false, filterVal);
		this.props.selectedTaskTemplate(filterVal)		
	}


	componentWillUnmount() {
		this.props.getFulfilmentPlans(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
		this.props.toggleAddButtonStatus(false)
	}
	onSortAsc(sort, sortValue, sortType) {
		// console.log("sort",sort);
		// console.log("sortValue",sortValue);
		// console.log("sortType",sortType);
		// let sortOrder = "";
		this.setState({ sort: !this.state.sort });
		
		this.setState({ sortOrder: sortType });

		// sortOrder = sortType;
		// this.props.setSortOrder(sortOrder, sortValue);

		switch (sortValue) {
			case 'Seq':
				this.setState({
					faCustomDesc1: sortType == "asc" ? "customArrow dropdown" : sortType == "desc" ? "customArrow dropdown isActive" : "",
					faCustomAsc1: sortType == "asc" ? "customArrow dropup isActive" : sortType == "desc" ? "customArrow dropup" : "",
				})
			}
		
			this.props.getFulfilmentPlans(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.templateSelected,sortType);

	}
	renderDropDown(fPlanOptions, selectedValue) {
		console.log("selectedValue",selectedValue);
		// Removing Service Order Template (Fullfilment_Template) if service is incident type
		if (this.props.spcmReducer.currentlySelectedServiceDetails.WORK_ITEM_TYPE == "incidentRequest" || this.props.spcmReducer.currentlySelectedServiceDetails.WORK_ITEM_TYPE == "standardChange"
		) {
			fPlanOptions = fPlanOptions.filter(function (fPlanOptions) {
				return fPlanOptions.value !== "Fullfilment_Template";
			});
		}



		return fPlanOptions.map((item, i) => {
			if (selectedValue == undefined) {
				return (
					<option value={item.value} >{item.label}</option>
				)
			}
			return (
				<option value={item.value} selected={item.value == selectedValue.name ? true : false}>{item.label}</option>
			);
		})
	}
	render() {

		const tr = this.props.tr;
		const { change, dispatch } = this.props;
		let ascDscToggle = "";
		ascDscToggle = this.state.sort ? "asc" : "desc";
		let fulfillmentTasks;

		const serviceFulfilmentPlans = this.props.spcmReducer.serviceFulfilmentPlans;
		if(serviceFulfilmentPlans[0]){
			fulfillmentTasks = serviceFulfilmentPlans[0].tasks.length
		}
		console.log("Fulfillment Plan", serviceFulfilmentPlans);
		console.log("Reducer", this.props.spcmReducer);
		let filterVal
		{this.props.spcmReducer.currentlySelectedServiceDetails.WORK_ITEM_TYPE == "incidentRequest" ? filterVal = 'CriticalIncident_Template' :
		this.props.spcmReducer.currentlySelectedServiceDetails.WORK_ITEM_TYPE == "standardChange" ? filterVal = 'ChangeManagement_Template' :
		filterVal = 'Fullfilment_Template' }
		let fulfillmentOptions = [];
		if(filterVal == 'Fullfilment_Template'){
			fulfillmentOptions.push(
			{ "value": "Fullfilment_Template", "label": "Service Orders" },
			{ "value": "ChangeManagement_Template", "label": "Change Requests" },
			{ "value": "CriticalIncident_Template", "label": "Critical Incident" }
		)
		}else if(filterVal == 'ChangeManagement_Template'){
			fulfillmentOptions.push(
				{ "value": "ChangeManagement_Template", "label": "Change Requests" },
				{ "value": "Fullfilment_Template", "label": "Service Orders" },
				{ "value": "CriticalIncident_Template", "label": "Critical Incident" }
			)
		}else if(filterVal == 'CriticalIncident_Template'){
			fulfillmentOptions.push(
				{ "value": "CriticalIncident_Template", "label": "Critical Incident" },
				{ "value": "ChangeManagement_Template", "label": "Change Requests" },
				{ "value": "Fullfilment_Template", "label": "Service Orders" }
			)
		}
		console.log("this.state.sortorder", this.state.sortOrder);
		console.log("this.state.faCustomAsc1", this.state.faCustomAsc1);
		console.log("this.state.faCustomDesc1", this.state.faCustomDesc1);

		return (


			<div className="padding-t-10">

				{this.props.componentFormToggle3 ? <ComponentForm tr={tr} componentFormFun4={this.props.componentFormFun3} setServiceStatus={this.props.setServiceStatus} serviceStatusState={this.props.serviceStatusState} 
				setSortingArrows = {this.setSortingArrows} /> : <div className='rBoxGap'>
					{this.state.deleteError == '' ? null : <Alert style={{ "marginBottom": "15px", "fontSize": "13px", "padding": "8px 30px 8px 8px" }} variant="danger" onDismiss={this.handleDismiss}><h4>{this.state.deleteError}</h4></Alert>}

					<Row className="show-grid selectFulPlan">{console.log('serviceStatus--', this.props.serviceStatusState)}
						<Col lg={5} md={12} sm={5} xs={12}>
							{tr['Fulfillment Plan For']}
						</Col>
						<Col lg={7} md={12} sm={7} xs={12}>
							{/* <select className="form-control getFullfilmentHandler" onChange={(event) => { this.getFullfilmentHandler(event.target.value); this.props.toggleAddButtonStatus(false, event.target.value); this.props.selectedTaskTemplate(event.target.value) }}>
								{/* {this.renderDropDown(this.state.fPlanOptions, serviceFulfilmentPlans[0])} */}
								{/* {this.renderDropDown(fulfillmentOptions, serviceFulfilmentPlans[0])} */}
							<select className="form-control getFullfilmentHandler" onChange={(event) => { this.getFullfilmentHandler(event.target.value); this.props.toggleAddButtonStatus(false, event.target.value); this.props.selectedTaskTemplate(event.target.value) }} value={this.state.templateSelected}>
								{this.renderDropDown(fulfillmentOptions, serviceFulfilmentPlans[0])}

							</select>
						</Col>
					</Row>

					{this.props.spcmReducer.spcmFetchingAttribute && this.state.listingTable == 'onChange' ?
						<ListLoader /> :
						<div className=''>
							{serviceFulfilmentPlans.length == 0 && this.state.listingTable == 'initial' ? null : <div className="f-size-12 fulPlans-listing">

								{/* serviceFulfilmentPlans.length<=0?<div>There is no matching data available.</div>: */
									// ((serviceFulfilmentPlans.length == 0) || (serviceFulfilmentPlans[0].tasks.length == 0)) && (this.state.listingTable == 'onChange') ? <div className="text-c">No Data Found</div> :
									((serviceFulfilmentPlans.length == 0) || (serviceFulfilmentPlans.length == "undefined") || (serviceFulfilmentPlans.length == undefined) || fulfillmentTasks == 0) && ((this.state.listingTable == 'onChange')||(this.state.listingTable == 'initial')) ? <div className="text-c">No Data Found</div> :
										(<Table bordered condensed hover responsive className="tableView tableViewMin sortImg tableWithOutPointer">
											<thead>
												<tr>
													{/* <th width="40%" className={this.state.class1} onClick={()=>{this.sortFun('class1')}}>{tr['Task']}</th>
						<th width="35%" className={this.state.class2} onClick={()=>{this.sortFun('class2')}}>{tr['Task For']}</th>
						<th width="10%" className={this.state.class3} onClick={()=>{this.sortFun('class3')}}>{tr['Seq']}</th>
						<th width="15%" className="text-c">{tr['Action']}</th> */}
													<th width="16%">
														<div
															className="sortParArr"
															onClick={() =>
																this.onSortAsc(
																	this.state.sort,
																	"Seq",
																	ascDscToggle,
																)
															}
														>
															{/*{tr['Status']}*/}{tr['Seq']}
															<div className="sortArr">
																{this.state.sortOrder == "asc" ? (
																	<span
																		className={this.state.faCustomAsc1}
																		title={this.props.tr["Ascending"]}
																	>
																		<span className="caret"></span>
																	</span>
																) : (
																	<span
																		className={this.state.faCustomDesc1}
																		title={this.props.tr["Descending"]}
																	>
																		<span className="caret"></span>
																	</span>
																)}
															</div>
														</div></th>
														
													<th width = "40%">{tr['Template Task Code']}</th>
													<th width="34%"  >
														<span>{tr['Task']}</span>{this.props.spcmReducer.selectedTaskTemplate == 'Fullfilment_Template' ? <span>, Component</span> : null}
														</th>
													{/* <th width="10%"  >{tr['Seq']}</th> */}
													<th width="10%" className="text-c">{tr['Action']}</th>
												</tr>
											</thead>
											<tbody>
												{/* serviceFulfilmentPlans.map(function(value,index){
							return (<tr key={index}>
						<td>{value.FPLAN_TEXT}</td>
						<td>{value.FPLAN_DEF_TYPE}</td>
						<td>{value.FPLAN_SEQUENCE}</td>
						<td><div className="text-c"><i title={tr["Edit"]} onClick={()=>{value.FPLAN_DEF_TYPE=="Service"?value.FPLAN_ENTITYID=this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_NAME:value.FPLAN_ENTITYID;this.props.setEditServiceDetails(value),this.props.componentFormFun3(true,'Edit',value.FPLAN_ID)}} className="fa fa-pencil-square-o cursorPoint"></i>
						<i title={tr["Delete"]} className="margin-l-10 fa fa-trash-o cursorPoint" onClick={()=>{this.onDeleteClick(value.FPLAN_ID)}}></i></div></td>
					  </tr>)
						},this) */

													serviceFulfilmentPlans[0].tasks.map(function (value, index) {
														let obj = value;
														obj.name = serviceFulfilmentPlans[0].name;

														return (
														<tr key={index}>
															<td>{value.status == 'Active' ? <span title="Active" className="btnStatus bgBtn2"></span> : <span title="In-Active" className="btnStatus bgBtn17"></span>}
															{value.sequenceNumber}
															</td>
															<td>{value.templateTaskCode}</td>
															<td>
																<div>{value.description}</div>
																{value.componentId ?
																	<div>
																		<div style={{ "cursor": "pointer", "color": "#369" }} data-key={index} 
																		onClick={(e) => { this.props.getComponentName(value.componentId);
																		this.setState({ componentTaskSelected: e.target.getAttribute("data-key") });
																		console.log(this.state.componentTaskSelected, index);
																		}}>



																			Show Component {index == this.state.componentTaskSelected ? <i className=" fa fa-angle-double-up"></i> : <i className=" fa fa-angle-double-down"></i>}

																			{index == this.state.componentTaskSelected ? <div style={{ 'color': '#888888' }}>{this.props.spcmReducer.componentName.COMPONENT_NAME}</div> : ""}


																		</div>

																	</div> :

																	""}
															</td>
															{/* <td>{value.sequenceNumber}</td> */}

															{/* <td><div className="text-c"><i title={tr["Edit"]} onClick={()=>{value.FPLAN_DEF_TYPE=="Service"?value.FPLAN_ENTITYID=this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_NAME:value.FPLAN_ENTITYID;this.props.setEditServiceDetails(value),this.props.componentFormFun3(true,'Edit',value.templateTaskId)}} className="fa fa-pencil-square-o cursorPoint"></i>
						<i title={tr["Delete"]} className="margin-l-10 fa fa-trash-o cursorPoint" onClick={()=>{this.onDeleteClick(value.templateTaskId)}}></i></div></td> */}

															<td><div className="text-c"><i title={tr["Edit"]} 
															onClick={() => { this.props.setFullfilmentEditServiceDetails(obj);
															this.props.componentFormFun3(true, 'Edit', value.templateTaskId);
															}} className="fa fa-pencil-square-o cursorPoint"></i>
																<i title={tr["Delete"]} className="margin-l-10 fa fa-trash-o cursorPoint" onClick={() => { this.onDeleteClick(value.templateTaskId) }}></i></div></td>

														</tr>)
													}, this)

												}
											</tbody>
										</Table>)}
							</div>
							}</div>
					}






				</div>
				}
			</div>
		);

	}
}
const mapStateToProps = ({ spcmReducer }) => {
	return {
		spcmReducer
	}
}

export default connect(mapStateToProps, { resetFulfillmentPlan, getFulfilmentPlans, setEditServiceDetails, setFullfilmentEditServiceDetails, setForm, getServiceDetailCounts, selectedTaskTemplate, getComponentName })(ListView);
