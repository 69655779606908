
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import ListGroup from "react-bootstrap/ListGroup";
import { connect } from 'react-redux';
import {PiPencilSimpleBold} from "react-icons/pi";
import {IoClose} from "react-icons/io5";
import moment from 'moment';
import Cookies from "universal-cookie";
import { MyContext } from '_MyContext';
import { Link } from "react-router-dom";

const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
let dateformatCookie = homepagelocation[2];
class DelegateDescription extends React.Component {
  static contextType = MyContext;
  constructor(props) {
    super(props);
    this.state = {
      adminRole: false
    };
  }

  componentDidMount() {
    let ref = this;
    let roleArr = ref.props.role_id.split(',');
    let adminRole = roleArr.includes('1');
    ref.setState({ adminRole: adminRole });
    this.context.keyDownHandler(this.closeButton,this.editButton,this.globalSearchRef,this.saveObjRef);
  }
  componentDidUpdate() {
    this.context.keyDownHandler(this.closeButton,this.editButton,this.globalSearchRef,this.saveObjRef);
  }

  render() {
    return (
      <div>
        <Row>
          <Col xs={12}>
            <div className="rPageHeadActBtn" style={{ "marginTop": "-3px" }}><ul><li>
              <Link to="javascript:void(0)" className="editIcn" id="editBtnObj"
                  title={this.props.tr["Edit"]}
                  onClick={() => { this.props.showListPart1(true); }}
                  ref={e => this.editButton = e}
                >
                  <PiPencilSimpleBold/>
                </Link>
              
              </li>
              <li>
                <Link to="javascript:void(0)" className="closeRsBtn" id="closeButton"
                  title={this.props.tr["Minimize the details panel"]}
                  onClick={() => { this.props.showRightPanelFun(false); }}
                  ref={e => this.closeButton = e}
                >
                  <IoClose/>
                </Link>
              </li></ul></div>
            <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.tr["Delegation Details"]}</div></div>
          </Col>
        </Row>

        <div className="rBoxGap">
          <ListGroup variant='flush'>
            {this.state.adminRole ?
              <ListGroup.Item bsClass="" className="rwTable" style={{ "border-top": "none" }}>
                <span className="rwKey">{this.props.tr["Delegated For"]}</span>
                <span className="rwVal">{this.props.delegate.delegatorName}</span>
              </ListGroup.Item> : null}
            <ListGroup.Item bsClass="" className="rwTable">
              <span className="rwKey">{this.props.tr["Delegatee"]}</span>
              <span className="rwVal">{this.props.delegate.delegateeName}</span>
            </ListGroup.Item>
            <ListGroup.Item bsClass="" className="rwTable">
              <span className="rwKey">{this.props.tr["Delegation Start"]}</span>
              <span className="rwVal">{moment(this.props.delegate.delegateDateforFrom).format(
                  dateformatCookie.slice(0, 10).toUpperCase() + " HH:mm:ss"
                )}</span>
            </ListGroup.Item>
            <ListGroup.Item bsClass="" className="rwTable">
              <span className="rwKey">{this.props.tr["Delegation End"]}</span>
              <span className="rwVal">{moment(this.props.delegate.delegateDateforTo).format(
                  dateformatCookie.slice(0, 10).toUpperCase() + " HH:mm:ss"
                )}</span>
            </ListGroup.Item>
            <ListGroup.Item bsClass="" className="rwTable">
              <span className="rwKey">{this.props.tr["Module"]}</span>
              <span className="rwVal">{this.props.delegate.moduleId == "Item" ? this.props.tr['Fulfillment'] : this.props.tr['Change']}</span>
            </ListGroup.Item>
            <ListGroup.Item bsClass="" className="rwTable">
              <span className="rwKey">{this.props.tr["Status"]}</span>
              <span className="rwVal">{this.props.delegate.status}</span>
            </ListGroup.Item>
          </ListGroup>
        </div>

      </div>
    );
  }
}
// ListPart.propTypes = {
//   // delegate: PropTypes.func,
//   tr: PropTypes.object,
//   showListPart1: PropTypes.func,
//   showRightPanelFun: PropTypes.func,
//   isRightPanel: PropTypes.bool
// };

const mapStateToProps = ({ delegationList, spcmReducer }) => {
  return {
    delegationList,
    tr: spcmReducer.tr
  };
};
export default connect(mapStateToProps, {})(DelegateDescription);

