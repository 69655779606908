
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export default {
  authors: [],
  courses: [],
  breakFixEditDetails: [],
  breakFixSourceName: [],
  breakFixCriticalityName: [],
  breakFixUrgencyName: [],
  breakFixSGName: [],
  breakFixSGIName: [],
  breakFixCause: [],
  cimUserDetails: [],
  cimUserDetailsForUser: [],
  cimGroupDetails: [],
  quickViewDetails: [],
  fulFillmentList: [],
  selectedFulfillmentItem: null,
  hamburgerCategory: [],
  hamburgerCategoryList: [],
  subHamburgerCategory: [],
  offeringDescription: null,
  componentPackage: [],
  breakFixAuditLogDetails: [],
  breakFixRelatedWorkDetails: [],
  breakFixActivityLogDetails: [],
  breakFixStatusDetails: [],
  breakFixRelatedDataDetails: [],
  timelineDataResult: {},
  ciCategories: [],
  attachmentsReducer: {},
  paginationAttachmentsReducer: {},
  serviceOwnerGroupReducer:[],
  //alfy
  chat: {
    visible: false,
    appResponse: [],
    showAppHelp: false,
    tokens: null,
    appResponseReceived: false,
    micOn: false,
    usrMsg: "",
    chatState: {
      input: {
        text: "",
      },
    },
  },
  breakfixQuickViewFilters: {
    //module:[],
    groups: [],
    selectedgroups: [],
    fulfillmentGroups: [],
    selectedFulfillmentGroups: [],
    priority: [],
    selectedpriorities: [],
    selectedFulfillmentPriorities: [],
    tags: [],
    status: [],
    selectedTags: [],
    selectedFulfillmentTags: [],
    selectedStatus: [],
    selectedFulfillmentStatus: [],
    //=======INV
    selectedInvestigationStatus: [],
    selectedInvestigationTags: [],
    investigationGroups: [],
    selectedInvestigationGroups: [],
    //=========
    //==========CHG
    selectedChangeStatus: [],
    selectedChangeTags: [],
    changeGroups: [],
    selectedChangeGroups: [],
    //============
    //=======Task
    selectedTaskStatus: [],
    selectedTaskTypeStatus: [],
    selectedTaskTemplate: "",
    //===================
    searchText: "",
    searchOption: "10",
    sortBy: "breakfixId",
    orderBy: "desc",
    searchTextForFulfillment: "",
    searchOptionForFulfillment: "10",
    sortByForFulfillment: "itemId",
    orderByForFulfillment: "desc",
  },
  breakfixQuickViewTable: {
    carouselIndex: "4",
  },
  //myProfile
  profileDetails: [],
  dateFormatDetails: [],
  timeZoneDetails: [],
  currencyDetails: [],
  CWLDetails: [],
  languageDetails: [],
  //OrderNow
  deliverytypeList: [],
  // componentPackage: [],
  orderofferingList: [],
  orderquestionnaireList: [],
  ordercoreofferingList: [],
  //==========
  //fulfillmentDetails:[],

  //myOrders
  ordersList: [],
  myOrderViewFilters: {
    status: [],
    selectedStatus: [],
    orderType: [],
    selectedOrderType: [],
  },
  //========
  ordersListGblSearch: [],

  //globalSearchRevamp
  globalSearchDataForRevamp: [],

  //====
  fullfilmentRelatedWorkDetails: [],
  fullfilmentRelatedDataDetails: [],
  functions: [],
  breakFixTaskLogDetails: [],
  breakFixTaskEditDetails: [],
  breakFixSGAEName: [],
  breakFixSGAEChangegroup: [],
  breakFixSLALogDetails: [],
  invCauseName: [],
  invRelatedWorkDetails: [],
  invRelatedDataDetails: [],
  invRelatedDataDetailsCount: 0,
  breakFixSLAResponseLogDetails: [],
  breakFixSLAResolutionLogDetails: [],
  changeRelatedWorkDetails: [],
  changeRelatedDataDetails: [],
  cmdbChangeRelatedWorkDetails: [],
  cmdbChangeRelatedDataDetails: [],
  changeApprovalDetails: [],
  changeTotalApprovalDetails: [],
  requesterDelegateeDetails: [],
  changeTotalApprovalHistoryDetails: [],
  breakFixSGAEChangeName: [],
  breakFixSGAEEditName: [],
  breakFixSGAETaskName: [],
  statusBasedHamburger: [],
  //cmdb
  cmdbItemList: [],
  ciClass: [],
  ciStatus: [],
  ciAllStatus: [],
  ciSubStatus: [],
  ciMetallic: [],
  ciEnv: [],
  ciResidualRisk: [],
  approvalsAutoAction: [],
  ciManr: [],
  ciDataset: [],
  ciCompany: [],
  companyReportData: [],
  ciLocation: [],
  ciUserBasedLocation: [],
  ciGroup: [],
  ciGroupForTaskPlan: [],
  ciInd: [],
  CIEditDetails: [],
  contractEditList: [],
  CIModelName: [],
  ciCat: [],
  ciType: [],
  ciSCat: [],
  ciAtt: [],
  cmdbAuditLogDetails: [],
  ciSubStatusCopy: [],
  ciSupCompany: [],
  cmdbSupportCompany: [],
  companyList: [],
  portfolioGroupList: [],
  catalogueGroupList: [],
  portfolioManagerList: [],
  catalogueManagerList: [],
  serviceDetails: [],
  allServicesList: [],
  currentlySelectedServiceDetails: [],
  criticalityList: [],
  categoryList: [],
  editService: [],
  form: "createSerForm",
  consumptionItems: [],
  myQuestionsForService: [],
  entitlements: [],
  serviceOrderChoices: [],
  attributes: [],
  serviceBilling: [],
  serviceKnowledge: [],
  serviceFulfilmentPlans: [],
  ciTaskPlans: [],
  serviceFulfilmentPlansLov: [],
  serviceSupportCompanyGroupsList: [],
  serviceProviderCompanyGroupsList: [],
  chainEntities: [],
  ciChainEntities: [],
  ciChainEntitiesMeta: [],
  serviceLevels: [],
  selfServices: [],
  entitlement_entities: [],
  editId: -1,
  cmdbQuickViewFilters: {
    //module:[],
    selectedStatus: [],
    selectedCompany: [],
    selectedClass: [],
  },
  statusList: [],
  subStatusList: [],
  groups: [],
  managers: [],
  meta: {},
  serviceDetailCounts: {},
  audit: [],
  reviews: [],
  editLeftService: [],
  editLeftId: -1,
  approver_type: [],
  approver_module: [],
  approver_group: [],
  approver_individual: [],
  approver_group_or_individual: [],
  approver_level: [],
  sla_module: [],
  sla_goal_type: [],
  approval: [],
  sla_workday_schedule: [],
  chain_entities_company: [],
  all_company_list: [],
  services_list_for_search: [],
  notification_data: [],
  tr: {},
  lang: "en",
  breakFixGraphView: true,
  breakFixGraphSlaDetails: [],
  taskGraphView: true,
  investigationGraphView: true,
  changeGraphView: true,
  fullfilmentGraphView: true,
  fullfilmentGraphSlaDetails: [],
  community: [],
  spcmFetchingService: true,
  spcmFetchingAttribute: true,
  spcmPostingAttribute: false,
  spcmPostingService: false,
  spcmFetchingError: false,
  spcmErrorMsg: "",
  spcmFetchingModule: "",
  questions_category: [],
  requiredQuestionGroupMeta: [],
  requiredQuestionMeta: [],
  updatedPriceFromConsumer: "",
  updatedHideSlaStatus: "",
  quesActiveCategories: [],
  componentName: "",
  slaStatusForFulfilment: "",
  dataSourceTypes: [],
  ReadOnlyResponseKeyTypesAndKeys: [],
  orderingPreferences: "",
  serviceTypes: [],
  spcmDisclaimers: [],
  integrationDataKeywords: [],
  addtional_integration_data: [],
  welcomeMessage: "",
  approval_questions: [],
  approverCriteria: [],
  approverCriteriaOperator: [],
  approverCriteriaScenario: [],
  approverEntity: [],
  approverLOB: [],
  approverOrderType: [],
  approverCompanyCode: [],
  relatedOfferings: [],
  allDeployedServicesList: [],
  copyOfferingFromServiceList: [],
  serviceSequencingList: [],
  serviceSequencingListOnAdd: [],
  attrubutesTranslationStatus: [{}],
  languageListTranslationStatus: [],
  nameDescSummaryTranslation: [],
  catSubcatTranslation: [],
  translatedNameDescSum: "",
  getListForTranslation: [],
  objectForTranslation: [],
  transAttrName: "",
  translateCompDesc: "",
  transAttrValue: "",
  translatedCategory: "",
  translatedSubcategory: "",
  spcmReminderNotificationQuestions: [],
  spcmReminderNotifications: [],
  translateQuestCat: "",
  translatedResponseValues: "",
  translateQuestText: "",
  translateQuestPlaceholder: "",
  translateQuestHelpText: "",
  translateDisplayText:"",
  translateTableHeaders:'',
  serviceAuditLogs: [],
  serviceListCompany: [],
  serviceListCompanyForSearch: [],
  bfRelatedWorkCheckList: [],
  externalLinks: [],
  cmdbClassBasedAssetsList: [],
  necompanydetails: [],
  uploadlogo: [],
  multiTenantLogo: {},
  ciBasedDataDetails: [],
  ne_editformDetails:[],
  service_listData:[],
  service_ci_data:[],
  contextual_rules:[],
  task_contextual_rules:[],
  entitledOnBehalfOfferings: [],
  companyUsersList: [],

  // reviews--------------
  reviewFilters: {
    status: [],
    selectedStatus: ['Pending'],
    orderType: [],
    selectedOrderType: ['Service Orders', 'Correction Orders']
  },

  integrationHubData: {
    integrationHubList: [],
    scenarioDropDownList: [],
    initialEditValues:{},
    integrationHubSubmitError: false
  },

  // AMS
  attributesList_ams: [],
  operatorsList_ams: [],
  amsAttributeList_ams: [],
  loaders_ams: false,
  isClassExist_list:false,
  ciResolutionCat: [],

  //foundation tags
  tagCompany: [],
  businessFunction: [],
  tagModule: [],
  tagType: [],
  tagDataValidation: [],
  tagDataSource: [],
  allTagsList: [],
  allCompositeTags: [],
  memberTagsForComposite: [],
  tagDetailsByTagId: null,
  showTagsLoader: false,
  memberDependencyData: [],
  memberDependencyMeta: {},
  showMemberDependencyLoader: false,
  memberDependencyParentData: [],
  createMemberTagInitialDetails: {},
  createChecklistMemberTagInitialDetails: {},
  memberTagsForChecklist: [],
  allChecklistTags: [],

  //dashboard configration
    dashboardConfigData: {
    dashboardConfigList: [],
    dashboardConfigDropDownList: [],
    initialEditValues:{},
    dashboardConfigSubmitError: false
  },

  //aiosem
  scheduleType: [],
  onCallStatus: [],
  onCallFrequency: [],
  escalationLevel: [],
  allScheduleList: [],
  allScheduleListMeta: {},
  scheduleDetailsByScheduleId: null,
  showScheduleListLoader: false,
  showScheduleAssociationLoader: false,
  scheduleAssociationList : [],
  relatedScheduleList: [],
  allRosterList: [],
  allRosterListMeta: {},
  rosterDetailsByRosterId: null,
  showRosterListLoader: false,
  showRosterMembersLoader: false,
  rosterMembersList: [],
  adminPreferencesListData: [],
  adminPreferencesListLoader: false,
  slaViewListRed:[],
  companyMenuListData: [],
  companyMenuListDropDownData:[],
  companyMenuListFieldDropDownData:[],
  myViewNotificationList:[],
  toaList:[],
  totList:[],
  toaEditList:[],
  totEditList:[],
  aismTrainingMetricsData: [],
  aismLoader: false,
  walkupEnabled: false,
  refreshed: false,
};
