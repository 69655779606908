
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import * as Yup from "yup";
import { Row, Col, Button } from "react-bootstrap";
import { Formik, Form } from "formik";
import { StandardTextArea } from "../../../../common/FormFields";
import {
  useGetWorkNotesDetailsQuery,
  useSaveWorkNotesDetailsMutation,
} from "../../../../../../reducers/governance/meeting/meeting";
import WorkNotesList from "./list";

const WorkNotes = ({ occurrananceId, meetingId, companyId }) => {
  const [saveWorkNote, { isLoading }] = useSaveWorkNotesDetailsMutation();

  const validationSchema = Yup.object().shape({
    comments: Yup.string()
      .required("Required")
      .max(1000, "Must be 1000 characters or less"),
  });

  const [initVals, setInitVals] = useState({
    comments: "",
  });
  let initialValues = {
    ...initVals,
  };
  const onSubmit = async (fields, { resetForm, setSubmitting }) => {
    const postData = {
      module: "occurrence",
      ocurrenceId: occurrananceId,
      definationId: meetingId,
      worknoteDesc: fields.comments,
      isAttachment: "N",
      status: "1",
    };

    const resp = await saveWorkNote({
      data: postData,
      queryString: `?tenantId=${companyId}`,
    });
    resetForm();
    setSubmitting(false);
  };

  const { data: notes, isFetching: notesFetching } =
    useGetWorkNotesDetailsQuery(
      {
        queryString: `?id=${occurrananceId}&meetingType=occurrence&sortedBy=desc&tenantId=${companyId}`,
      },
      {
        skip: !(meetingId && companyId),
      }
    );

  return (
    <div id="WorkNotes">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleBlur,
          values,
          handleChange,
          setValues,
        }) => {
          return (
            <Form noValidate id="worknotes">
              <div className=" new-diamenton">
                <Row>
                  <Col sm={12} className="text-right">
                    <div className="form-group position-relative">
                      <StandardTextArea
                        required
                        characterCount
                        as="textarea"
                        rows="4"
                        label="Note"
                        name="comments"
                        classNam="form-control w-100"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} className="text-right">
                    <div className="pull-right custom-note-submit-btn">
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        title="Save"
                        className="rgSidrkBtn smallBtn"
                      >
                        Save
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Form>
          );
        }}
      </Formik>
      <div className="occurnance mt-3">
        <WorkNotesList data={notes} />
      </div>
    </div>
  );
};

export default WorkNotes;
