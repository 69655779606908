
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, ButtonToolbar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import swal from 'sweetalert';
import axios from 'axios';
import {ImInfo} from 'react-icons/im';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import XsmUserDetails from '_Commons/WorkItemBoard/xsmEditRequestedBy.js';
import Cookies from "universal-cookie";
const cookies = new Cookies();
let cookiesArray = cookies.get('gph');
if (cookiesArray) cookiesArray = cookiesArray.replace('s:', '');
if (cookiesArray) cookiesArray = cookiesArray.substring(0, cookiesArray.lastIndexOf('.'));
cookiesArray = cookiesArray.split("~");
let langDir = cookiesArray[66];
const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});
const TypeaheadSelect = (props) => {
	const { tr = {}, options = [], selectedOptions = [], disabled = false, typedValue = '', onSelection = () => {}, onCrossClick = () => {}, errorClass = '', onInputChange = () => {}, style = {}, name = '' } = props || {};
	// let _typeahead;
  let crossIconStyle = style['crossIcon'] || {};
  let errorStyle = {};
  if(errorClass){
    errorStyle = {
      'border': '1px solid red',
      'border-radius': '5px'
    };
  }
  let _typeahead;
  let crossClick = () => {
    _typeahead.clear();
    onCrossClick();
  };
	return(
			<div className="dvTypehd" style={errorStyle}>
				{!disabled && ( (selectedOptions && selectedOptions.length > 0) || (typedValue && typedValue.length > 0) ) ? (
				<span
					title="clear"
					role="img"
					aria-label="Clear"
					className="css-hakgx8 icn"
          onClick={crossClick}
          style={crossIconStyle}
				>
					<svg
					width="18"
					height="18"
					viewBox="0 0 24 24"
					role="presentation"
					>
					<path
						d="M12 10.586L6.707 5.293a1 1 0 00-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 001.414 1.414L12 13.414l5.293 5.293a1 1 0 001.414-1.414L13.414 12l5.293-5.293a1 1 0 10-1.414-1.414L12 10.586z"
						fill="gray"
					></path>
					</svg>
				</span>
				) : null}
				{/* <div className={errorClass ? 'dvTypehd error': 'dvTypehd'}> */}
				<Typeahead
				// ref={(ref) => {
				// 	_typeahead = ref;
				// }}
        ref={(ref) => {
          _typeahead = ref;
          if (_typeahead !== null && selectedOptions.length === 0 && typedValue.length === 0) {
            _typeahead.clear();
          }
        }}
				disabled={ disabled ? true : false }
				onChange={onSelection}
				// onBlur={this.seterrorGroupcolor}
				options={options}
				selected={selectedOptions}
				placeholder={
					tr["Please choose"] + "..."
				}
				onInputChange={onInputChange}
				multiple={false}
				// renderMenuItemChildren={(option, props) => {
				// 	let label;
				// 	let title;
				// 	label =
				// 		option.label.length > 30
				// 		? option.label.slice(0, 30) + "..."
				// 		: option.label;
				// 	title = option.label.length > 30 ? option.label : "";
				// 	if(option?.supportCompanyName){
				// 	return (
				// 		<div>
				// 		<span className="typHdmainHead" title={title} style={{'display': 'block'}}>{label}</span>
				// 		<span className="typHdmainSubHead" style={{'display': 'block', 'margin-top': '6px', 'color': '#777'}}>{option?.supportCompanyName || ''}</span>
				// 		</div>
				// 	)
				// 	}
				// 	return (
				// 	<div>
				// 		<span className="typHdmainHead" title={title}>{label}</span>
				// 	</div>
				// 	);
				// }}
				/>
				{/* </div> */}
			</div>
	);
}

const FormComponent = (props) => {
  const { bulkUpdateData = [], module = '', handler = () => {}, roleId = '' } = props || {};
  // console.log('bulk update - ', {bulkUpdateData, module});
  const dispatch = useDispatch();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const wibListPayload = useSelector((state) => state.wibListPayload);
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [typedGroup, setTypedGroup] = useState('');
  const [individualOptions, setIndividualOptions] = useState([]);
  const [selectedIndividual, setSelectedIndividual] = useState([]);
  const [typedIndividual, setTypedIndividual] = useState('');
  const [groupError, setGroupError] = useState('');
  // const [individualError, setIndividualError] = useState('');
  const [reassignReasonError, setReassignReasonError] = useState('');
  const [isDisabled, setDisabled] = useState(false);
  const [reassignReason, setReassignReason] = useState('');
  const [selectedGroupImp, setSelectedGroupImp] = useState([]);
  const [typedGroupImp, setTypedGroupImp] = useState('');
  const [selectedGroupChange, setSelectedGroupChange] = useState([]);
  const [typedGroupChange, setTypedGroupChange] = useState('');
  const [selectedIndividualImp, setSelectedIndividualImp] = useState([]);
  const [typedIndividualImp, setTypedIndividualImp] = useState('');
  const [selectedIndividualChange, setSelectedIndividualChange] = useState([]);
  const [typedIndividualChange, setTypedIndividualChange] = useState('');
  // const [groupErrorImp, setGroupErrorImp] = useState('');
  // const [groupErrorChange, setGroupErrorChange] = useState('');
  const [individualOptionsImp, setIndividualOptionsImp] = useState([]);
  const [individualOptionsChange, setIndividualOptionsChange] = useState([]);
  const windowSize = useSelector((state) => state.windowSize.width);

  useEffect(() => {
    fetchAllGroups();
  }, []);

  const normalizeOptions = (data, type = '') => {
    try{
      let id = '';
      let label = '';
      switch(type){
        case 'group':
          id = 'groupId';
          label = 'groupName';
          break;
        case 'individual':
          id = 'field1Value';
          label = 'field1Key';
          break;
        case 'assigned-group':
          id = 'field1Key';
          label = 'field1Value';
          break;
      }
      let arr = data.map(item => {
        return(
          { id: item[id], label: item[label] }
        );
      });
      return arr;
    }catch(e){
      console.error(e);
      return [];
    }
  }

  const fetchAllGroups = () => {
    try{
      setGroupOptions([]);
      setIndividualOptions([]);
      setSelectedIndividual([]);
      if( 
        (
          module == 'breakfix' && !( roleId.split(",").includes("13") || roleId.split(",").includes("14") || (roleId.split(",").includes("63") && roleId.split(",").includes("48")) || roleId.split(",").includes("48") )
        ) || 
        (
          module == 'fullfilment' && !( roleId.split(",").includes("44") || (roleId.split(",").includes("62") && roleId.split(",").includes("48")) || roleId.split(",").includes("48") )
        ) || 
        (
          module == 'investigation' && !roleId.split(",").includes("17")
        ) || 
        (
          module == 'changeManagement' && !(roleId.split(",").includes("20") || roleId.split(",").includes("52"))
        ) ||
        (
          module=='task' && !props.only_d2c_manager_roles_enable && (props.only_d2c_user_roles_enabled || props.only_problem_investigator_role_enabled)
        )
      ){
        let url = `/api/breakfixGroupsAction`;
        axios.get(url).then(res => {
          // console.log('res - ', res);
          if(res?.data && Array.isArray(res.data) && res.data.length > 0){
            setGroupOptions(normalizeOptions(res.data, 'assigned-group'));
          }
        }).catch(err => {
          console.error(err);
        });
      }
      else{
        let companyIdMapping = {
          'breakfix': 'consumerCompany',
          'fullfilment': 'companyId',
          'investigation': 'consumerCompany',
          'changeManagement': 'consumerCompany',
          'task':'companyId'
        };
        const companyId = (bulkUpdateData && Array.isArray(bulkUpdateData) && bulkUpdateData.length > 0) ? bulkUpdateData[0][(companyIdMapping[module])] : '';
        let url = `/asset-config/api/getCiSupportAllGroup/id/${companyId}`;
        axios.get(url).then(res => {
          if(res?.data && Array.isArray(res.data) && res.data.length > 0){
            setGroupOptions(normalizeOptions(res.data, 'group'));
          }
        }).catch(err => {
          console.error(err);
        });
      }
      // let str = JSON.stringify({ 'userId': '' });
      // axios.get('/api/users/myprofile', { headers:{ 'query': str } } )
      // .then(res1 => {
      //   if(res1?.data?.companyId){
      //     let url = `/asset-config/api/getCiSupportAllGroup/id/${res1?.data?.companyId || ''}`;
      //     axios.get(url).then(res2 => {
      //       if(res2?.data && Array.isArray(res2.data) && res2.data.length > 0){
      //         setGroupOptions(normalizeOptions(res2.data, 'group'));
      //       }
      //     }).catch(err2 => {
      //       console.error(err2);
      //     });
      //   }
      // }).catch(err1 => {
      //   console.error(err1);
      // });
    }catch(e){
      console.error(e);
    }
  }

  const fetchAllIndividuals = (groupId = '', type = '') => {
    try{
      if(type == 'implementation'){
        // setIndividualOptionsImp([]);
        // setGroupErrorImp('');
      }
      else if(type == 'changeManagement'){
        // setIndividualOptionsChange([]);
        // setGroupErrorChange('');
      }
      else{
        setIndividualOptions([]);
        setGroupError('');
      }
      // setIndividualOptions([]);
      // setGroupError('');
      let url = `/api/userListSelfService?groupId=${groupId || ''}`;
      axios.get(url).then(res => {
        if(res?.data && Array.isArray(res.data) && res.data.length > 0){
          if(type == 'implementation'){
            setIndividualOptionsImp(normalizeOptions(res.data, 'individual'));
          }
          else if(type == 'changeManagement'){
            setIndividualOptionsChange(normalizeOptions(res.data, 'individual'));
          }
          else{
            setIndividualOptions(normalizeOptions(res.data, 'individual'));
          }
          // setIndividualOptions(normalizeOptions(res.data, 'individual'));
        }
      }).catch(err => {
        console.error(err);
      });
    }catch(e){
      console.error(e);
    }
  }

  const validateData = () => {
    try{
      let flag = true;
      if(module == 'changeManagement'){
        if( !( (selectedGroupImp && selectedGroupImp?.length == 1) || (selectedGroupChange && selectedGroupChange?.length == 1) ) ){
          swal({
            text: `Please select either Implementation Group or Change Management Group`,
            button: tr['OK'],
          });
          return false;
        }
        // if(!(selectedGroupImp && selectedGroupImp?.length == 1)){
        //   setGroupErrorImp('error');
        //   flag = false;
        // }
        // else{
        //   setGroupErrorImp('');
        // }
        // if(!(selectedGroupChange && selectedGroupChange?.length == 1)){
        //   setGroupErrorChange('error');
        //   flag = false;
        // }
        // else{
        //   setGroupErrorChange('');
        // }
      }
      else{
        if(!(selectedGroup && selectedGroup?.length == 1)){
          setGroupError('error');
          flag = false;
        }
        else{
          setGroupError('');
        }
        if(module == 'breakfix' || module == 'fullfilment'){
          if(!(reassignReason && reassignReason.length > 0)){
            setReassignReasonError('error');
            flag = false;
          }
          else{
            setReassignReasonError('');
          }
        }
      }
      return flag;
    }catch(e){
      console.error(e);
      return false;
    }
  }

  const saveData = () => {
    try{
      if(validateData() == true){
        setDisabled(true);
        let idMapping = {
          'breakfix': 'breakfixId',
          'fullfilment': 'itemId',
          'investigation': 'investigationId',
          'changeManagement': 'changeId',
          'task':'taskCode'
        };
        let ids = '';
        if(bulkUpdateData && Array.isArray(bulkUpdateData) && bulkUpdateData.length > 0){
          let arr = [];
          bulkUpdateData.forEach(obj => {
            arr.push(obj[(idMapping[module])]);
          });
          ids = arr.join(',');
        }
        let bodyData = {};
        let url = '';
        let successProp = '';
        let otherProp = '';
        if(module == 'breakfix'){
          url = `/serviceManagement/rest/breakfixBulkAction?breakfixIds=${ids}`;
          successProp = 'updatedBreakfix';
          otherProp = 'failedBreakfix';
          bodyData = {
            'spGroupid': selectedGroup[0]['id'] || '0',
            'spGroupname': selectedGroup[0]['label'],
            'spUserid': (selectedIndividual && Array.isArray(selectedIndividual) && selectedIndividual.length == 1) ? selectedIndividual[0]['id'] : '0',
            'spUsername': (selectedIndividual && Array.isArray(selectedIndividual) && selectedIndividual.length == 1) ? selectedIndividual[0]['label'] : '',
            'groupReassignReason': `Group Reassignment Reason - ${reassignReason}`
          };
        }
        if(module == 'fullfilment'){
          url = `/serviceManagement/rest/item-bulk-action?itemIds=${ids}`;
          successProp = 'updatedItemIds';
          otherProp = 'failedItemIds';
          bodyData = {
            'asgGroupid': selectedGroup[0]['id'] || '0',
            'asgGroupname': selectedGroup[0]['label'],
            'asgUserid': (selectedIndividual && Array.isArray(selectedIndividual) && selectedIndividual.length == 1) ? selectedIndividual[0]['id'] : '0',
            'asgUsername': (selectedIndividual && Array.isArray(selectedIndividual) && selectedIndividual.length == 1) ? selectedIndividual[0]['label'] : '',
            'groupReassignReason': `Group Reassignment Reason - ${reassignReason}`
          };
        }
        if(module == 'investigation'){
          url = `/serviceManagement/rest/investigation-bulk-action?investigationIds=${ids}`;
          successProp = 'updatedInvestigation';
          otherProp = 'failedInvestigation';
          bodyData = {
            'asgPmGroupid': selectedGroup[0]['id'] || '0',
            'asgPmGroupname': selectedGroup[0]['label'],
            'asgPmId': (selectedIndividual && Array.isArray(selectedIndividual) && selectedIndividual.length == 1) ? selectedIndividual[0]['id'] : '0',
            'asgPmName': (selectedIndividual && Array.isArray(selectedIndividual) && selectedIndividual.length == 1) ? selectedIndividual[0]['label'] : '',
            // 'groupReassignReason': `Group Reassignment Reason - ${reassignReason}`
          };
        }
        if(module == 'task'){
          url = `/api/bulkTaskAction?taskcodes=${ids}`;
          successProp = 'updatedItemIds';
          otherProp = 'failedItemIds';
          bodyData = {
            'assignedToGroupId': selectedGroup[0]['id'] || '0',
            'assignedToGroup': selectedGroup[0]['label'],
            'assignedToId': (selectedIndividual && Array.isArray(selectedIndividual) && selectedIndividual.length == 1) ? selectedIndividual[0]['id'] : '0',
            'assignedTo': (selectedIndividual && Array.isArray(selectedIndividual) && selectedIndividual.length == 1) ? selectedIndividual[0]['label'] : '',
          };
        }
        if(module == 'changeManagement'){
          url = `/serviceManagement/rest/change-bulk-action?changeIds=${ids}`;
          successProp = 'updatedChange';
          otherProp = 'failedChange';
          if(selectedGroupImp && Array.isArray(selectedGroupImp) && selectedGroupImp.length == 1){
            bodyData = {...bodyData, ...{
              'impGroupid': (selectedGroupImp && Array.isArray(selectedGroupImp) && selectedGroupImp.length == 1) ? selectedGroupImp[0]['id'] : '0',
              'impGroupname': (selectedGroupImp && Array.isArray(selectedGroupImp) && selectedGroupImp.length == 1) ? selectedGroupImp[0]['label'] : '',
              'impUserid': (selectedIndividualImp && Array.isArray(selectedIndividualImp) && selectedIndividualImp.length == 1) ? selectedIndividualImp[0]['id'] : '0',
              'impUsername': (selectedIndividualImp && Array.isArray(selectedIndividualImp) && selectedIndividualImp.length == 1) ? selectedIndividualImp[0]['label'] : '',
            }};
          }
          if(selectedGroupChange && Array.isArray(selectedGroupChange) && selectedGroupChange.length == 1){
            bodyData = {...bodyData, ...{
              'changeGroupid': (selectedGroupChange && Array.isArray(selectedGroupChange) && selectedGroupChange.length == 1) ? selectedGroupChange[0]['id'] : '0',
              'changeGroupname': (selectedGroupChange && Array.isArray(selectedGroupChange) && selectedGroupChange.length == 1) ? selectedGroupChange[0]['label'] : '',
              'changeUserid': (selectedIndividualChange && Array.isArray(selectedIndividualChange) && selectedIndividualChange.length == 1) ? selectedIndividualChange[0]['id'] : '0',
              'changeUsername': (selectedIndividualChange && Array.isArray(selectedIndividualChange) && selectedIndividualChange.length == 1) ? selectedIndividualChange[0]['label'] : '',
            }};
          }
        }
        handler('bulkUpdateInProgress');
        axios.patch(url, bodyData).then(res => {
          // console.log('res - ', res);
          if(res?.data){
            const successCount = res?.data?.[successProp]?.length || '0';
            const otherCount = res?.data?.[otherProp]?.length || '0';
            swal({
              text: `Bulk update completed - Success : ${successCount}, Failed : ${otherCount}`,
              button: tr['OK'],
            });
            handler('bulkUpdateComplete');
            if(wibListPayload && typeof wibListPayload == 'object' && Object.keys(wibListPayload).length > 0){
              handler('fetchListData', wibListPayload);
            }
          }
          setDisabled(false);
        }).catch(err => {
          console.error(err);
          swal({
            text: 'Error in bulk update',
            button: tr['OK'],
          });
          setDisabled(false);
          // handler('bulkUpdateComplete');
        });
      }
    }catch(e){
      console.error(e);
      swal({
        text: 'Error in bulk update',
        button: tr['OK'],
      });
      setDisabled(false);
      handler('bulkUpdateComplete');
    }
  }

  const renderGroupIndividualField = () => {
    try{
      if(!module){
        return null;
      }
      switch(module){
        case 'breakfix':
        case 'fullfilment':
        case 'investigation':
        case 'task':
          let tooltip = '';
          if(selectedIndividual && Array.isArray(selectedIndividual) && selectedIndividual.length == 1 && selectedIndividual[0]['id']){
            tooltip = (
              <Popover className="userInfoPopup">
                <XsmUserDetails userId={selectedIndividual[0]['id']} translator={tr} isHeadingVisible={false} isPopup={true} />
                </Popover>
            );
          }
          let ltrTyphdIcn = ((selectedIndividual && Array.isArray(selectedIndividual) && selectedIndividual.length == 1) ? {'right': '37px'} : {'right': '5px'});
          let rtlTyphdIcn = ((selectedIndividual && Array.isArray(selectedIndividual) && selectedIndividual.length == 1) ? {'left': '37px'} : {'left': '5px'});
          return(
            <>
              <Form.Group className="form-group" >
                <Form.Label bsClass=""><span className="rStar"></span>{(module == 'investigation') ? tr['Problem Mgmt. Group'] : module=== 'task' ? tr['Assignment Group'] : tr['Group']} </Form.Label>
                <div className="dvTypehd">
                  <TypeaheadSelect 
                    onSelection={(val) => {
                      if(val && val[0] && val[0].id){
                        fetchAllIndividuals(val[0].id);
                        setSelectedGroup(val);
                        setSelectedIndividual([]);
                        setTypedIndividual('');
                      }
                    }}
                    id='group'
                    name="group"
                    className="form-control"
                    errorClass={groupError}
                    options={groupOptions}
                    selectedOptions={selectedGroup}
                    // disabled={false}
                    onInputChange={(e) => {
                      setTypedGroup(e);
                      setIndividualOptions([]);
                      setSelectedGroup([]);
                      setSelectedIndividual([]);
                    }}
                    onCrossClick={() => {
                      setSelectedGroup([]);
                      setIndividualOptions([]);
                      setSelectedIndividual([]);
                      setTypedGroup('');
                      setTypedIndividual('');
                    }}
                    tr={tr}
                    typedValue={typedGroup}
                  />
                </div>
              </Form.Group>
              <Form.Group className="form-group infoBtnDiv typeHeadicn downinfobtn" >
                <Form.Label bsClass="">{(module == 'investigation') ? tr['Problem Manager'] : module === 'task' ? tr['Assigned To'] : tr['Individual']} </Form.Label>
                  <TypeaheadSelect 
                    onSelection={(val) => {
                      setSelectedIndividual(val);
                      // setIndividualError('');
                    }}
                    id='individual'
                    name="individual"
                    className="form-control"
                    // errorClass={individualError}
                    options={individualOptions}
                    selectedOptions={selectedIndividual}
                    // disabled={false}
                    onInputChange={(e) => {
                      // setIndividualError('error');
                      setSelectedIndividual([]);
                      setTypedIndividual(e);
                    }}
                    onCrossClick={() => {
                      setSelectedIndividual([]);
                      setTypedIndividual('');
                      // setIndividualError('error');
                    }}
                    typedValue={typedIndividual}
                    tr={tr}
                    style={{crossIcon: ( langDir === "rtl" ? rtlTyphdIcn : ltrTyphdIcn ) }}
                  />
                  {(selectedIndividual && Array.isArray(selectedIndividual) && selectedIndividual.length == 1) && 
                  <div className="indiIcnDv">	
                    <OverlayTrigger
                      trigger="click"
                      rootClose
                      placement={windowSize < 768 ? "auto-start" : (langDir === "rtl" ? "right-start" : "left-start")
}
                      overlay={tooltip}
                    >
                      <Button title="Info" bsPrefix=" " className="asignicn" onClick={() => { }}><ImInfo/></Button>
                    </OverlayTrigger>
                  </div>
                  }
              </Form.Group>
            </>
          );
        case 'changeManagement':
          let tooltip1 = '';
          let tooltip2 = '';
          if(selectedIndividualImp && Array.isArray(selectedIndividualImp) && selectedIndividualImp.length == 1 && selectedIndividualImp[0]['id']){
            tooltip1 = (
              <Popover className="userInfoPopup">
                <XsmUserDetails userId={selectedIndividualImp[0]['id']} translator={tr} isHeadingVisible={false} isPopup={true} />
                </Popover>
            );
          }
          if(selectedIndividualChange && Array.isArray(selectedIndividualChange) && selectedIndividualChange.length == 1 && selectedIndividualChange[0]['id']){
            tooltip2 = (
              <Popover className="userInfoPopup">
                <XsmUserDetails userId={selectedIndividualChange[0]['id']} translator={tr} isHeadingVisible={false} isPopup={true} />
                </Popover>
            );
          }
          let ltrTyphdChngIcn = ((selectedIndividualImp && Array.isArray(selectedIndividualImp) && selectedIndividualImp.length == 1) ? {'right': '37px'} : {'right': '5px'});
          let rtlTyphdChngIcn = ((selectedIndividualImp && Array.isArray(selectedIndividualImp) && selectedIndividualImp.length == 1) ? {'left': '37px'} : {'left': '5px'});
          return(
            <>
              <Form.Group className="form-group" >
                <Form.Label bsClass="">{'Implementation Group'} </Form.Label>
                <div className="dvTypehd">
                  <TypeaheadSelect 
                    onSelection={(val) => {
                      if(val && val[0] && val[0].id){
                        fetchAllIndividuals(val[0].id, 'implementation');
                        setSelectedGroupImp(val);
                        setSelectedIndividualImp([]);
                        setTypedIndividualImp('');
                      }
                    }}
                    id='group'
                    name="group"
                    className="form-control"
                    // errorClass={groupErrorImp}
                    options={groupOptions}
                    selectedOptions={selectedGroupImp}
                    // disabled={false}
                    onInputChange={(e) => {
                      setTypedGroupImp(e);
                      setIndividualOptionsImp([]);
                      setSelectedGroupImp([]);
                      setSelectedIndividualImp([]);
                    }}
                    onCrossClick={() => {
                      setSelectedGroupImp([]);
                      setIndividualOptionsImp([]);
                      setSelectedIndividualImp([]);
                      setTypedGroupImp('');
                      setTypedIndividualImp('');
                    }}
                    tr={tr}
                    typedValue={typedGroupImp}
                  />
                </div>
              </Form.Group>
              <Form.Group className="form-group infoBtnDiv typeHeadicn downinfobtn" >
                <Form.Label bsClass="">{'Implementation Individual'} </Form.Label>
                  <TypeaheadSelect 
                    onSelection={(val) => {
                      setSelectedIndividualImp(val);
                      // setIndividualError('');
                    }}
                    id='individual'
                    name="individual"
                    className="form-control"
                    // errorClass={individualError}
                    options={individualOptionsImp}
                    selectedOptions={selectedIndividualImp}
                    // disabled={false}
                    onInputChange={(e) => {
                      // setIndividualError('error');
                      setSelectedIndividualImp([]);
                      setTypedIndividualImp(e);
                    }}
                    onCrossClick={() => {
                      setSelectedIndividualImp([]);
                      setTypedIndividualImp('');
                      // setIndividualError('error');
                    }}
                    typedValue={typedIndividualImp}
                    tr={tr}
                    style={{crossIcon: ( langDir === "rtl" ? rtlTyphdChngIcn : ltrTyphdChngIcn) }}
                  />
                  {(selectedIndividualImp && Array.isArray(selectedIndividualImp) && selectedIndividualImp.length == 1) && 
                  <div className="indiIcnDv">	
                    <OverlayTrigger
                      trigger="click"
                      rootClose
                      placement={windowSize < 768 ? "auto-start" : (langDir === "rtl" ? "right-start" : "left-start")
}
                      overlay={tooltip1}
                    >
                      <Button title="Info" bsPrefix=" " className="asignicn" onClick={() => { }}><ImInfo/></Button>
                    </OverlayTrigger>
                  </div>
                  }
              </Form.Group>
              <Form.Group className="form-group" >
                <Form.Label bsClass="">{'Change Management Group'} </Form.Label>
                <div className="dvTypehd">
                  <TypeaheadSelect 
                    onSelection={(val) => {
                      if(val && val[0] && val[0].id){
                        fetchAllIndividuals(val[0].id, 'changeManagement');
                        setSelectedGroupChange(val);
                        setSelectedIndividualChange([]);
                        setTypedIndividualChange('');
                      }
                    }}
                    id='group'
                    name="group"
                    className="form-control"
                    // errorClass={groupErrorChange}
                    options={groupOptions}
                    selectedOptions={selectedGroupChange}
                    // disabled={false}
                    onInputChange={(e) => {
                      setTypedGroupChange(e);
                      setIndividualOptionsChange([]);
                      setSelectedGroupChange([]);
                      setSelectedIndividualChange([]);
                    }}
                    onCrossClick={() => {
                      setSelectedGroupChange([]);
                      setIndividualOptionsChange([]);
                      setSelectedIndividualChange([]);
                      setTypedGroupChange('');
                      setTypedIndividualChange('');
                    }}
                    tr={tr}
                    typedValue={typedGroupChange}
                  />
                </div>
              </Form.Group>
              <Form.Group className="form-group infoBtnDiv typeHeadicn downinfobtn" >
                <Form.Label bsClass="">{'Change Management Individual'} </Form.Label>
                  <TypeaheadSelect 
                    onSelection={(val) => {
                      setSelectedIndividualChange(val);
                      // setIndividualError('');
                    }}
                    id='individual'
                    name="individual"
                    className="form-control"
                    // errorClass={individualError}
                    options={individualOptionsChange}
                    selectedOptions={selectedIndividualChange}
                    // disabled={false}
                    onInputChange={(e) => {
                      // setIndividualError('error');
                      setSelectedIndividualChange([]);
                      setTypedIndividualChange(e);
                    }}
                    onCrossClick={() => {
                      setSelectedIndividualChange([]);
                      setTypedIndividualChange('');
                      // setIndividualError('error');
                    }}
                    typedValue={typedIndividualChange}
                    tr={tr}
                    style={{crossIcon: ( langDir === "rtl" ? rtlTyphdChngIcn : ltrTyphdChngIcn ) }}
                  />
                  {(selectedIndividualChange && Array.isArray(selectedIndividualChange) && selectedIndividualChange.length == 1) && 
                  <div className="indiIcnDv">	
                    <OverlayTrigger
                      trigger="click"
                      rootClose
                      placement={windowSize < 768 ? "auto-start" : (langDir === "rtl" ? "right-start" : "left-start")
}
                      overlay={tooltip2}
                    >
                      <Button title="Info" bsPrefix=" " className="asignicn" onClick={() => { }}><ImInfo/></Button>
                    </OverlayTrigger>
                  </div>
                  }
              </Form.Group>
            </>
          );
        default:
          return null;
      }
    }catch(e){
      console.error(e);
      return null;
    }
  }

  // let tooltip = '';
  // if(selectedIndividual && Array.isArray(selectedIndividual) && selectedIndividual.length == 1 && selectedIndividual[0]['id']){
  //   tooltip = (
  //     <Popover className="userInfoPopup">
  //       <XsmUserDetails userId={selectedIndividual[0]['id']} translator={tr} isHeadingVisible={false} isPopup={true} />
  //       </Popover>
  //   );
  // }

  return (
    <div className="stickyArea rBoxStyle">
      <div className="rPageHeading">
        <div className="offNam margin-t-5 margin-b-5">{tr["Bulk Update"]} ({bulkUpdateData?.length} Selected)</div>
      </div>
      <div className="rBoxGap">
        <>
          {renderGroupIndividualField()}
        </>
        {(module == 'breakfix' || module == 'fullfilment') && 
          <Form.Group className="form-group" >
            <Form.Label bsClass=""><span className="rStar"></span>{tr['Group Reassignment Reason']} </Form.Label>
            <Form.Control as="textarea" maxLength="3500" rows={2} 
            className={reassignReasonError}
            onBlur={(e) => {
              let val = e.target.value;
              val = val.trim();
              val = val.substring(0, 3500);
              setReassignReason(val);
            }} 
            onChange={(e) => { 
              setReassignReason(e.target.value); 
            }} value={reassignReason} />
          </Form.Group>
        }
        <ButtonToolbar className="black margin-t-15">
          <Button className="rgSidrkBtn smallBtn" bsSize="small" bsStyle="primary" 
          onClick={() => {
            saveData();
          }}
          disabled={isDisabled}
          >{tr['Save']}</Button>
          <Button className="rgSiLigBtn smallBtn" bsSize="small" bsStyle="primary" onClick={() => handler('clearSection')}>{tr['Cancel']}</Button>
        </ButtonToolbar>
      </div>
    </div>
  );
};

export default FormComponent;
