
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Row, Col, Form,ButtonToolbar,Button} from 'react-bootstrap';
import { useSelector,useDispatch } from "react-redux";
import { change, Field, reduxForm } from 'redux-form';
import { _inputField, _dropDown, _textArea, TypeaheadandDropdown, TypeaheadExampleSingleSelect } from '../../../../common/ReduxFormFields/CategoryBoard/formFields';

const UserInfoForm = (props) => {
  const tr = useSelector((state) => state.spcmReducer.tr);
  const authenticationRedData = useSelector((state) => state.authenticationRed);
  const dateListREdData = useSelector((state) => state.dateListREd);
  const languageListRedData = useSelector((state) => state.languageListRed);
  const userTypeRedData = useSelector((state) => state.userTypeRed);
  const timeZoneData = useSelector((state) => state.timezoneRed);
  const currencyRedData = useSelector((state) => state.currencyRed);
  console.log("timeZoneData+++",currencyRedData);

  const  validateGroupsField = (fieldType, event)=> {
    switch (fieldType) {
      case "userTypeId":
        if (event.target.value == "") {
          props.setUserTypeErr("error");
        } else {
          props.setUserTypeErr("");
        }
        break;
      case "dateformat":
        if (event.target.value == "") {
          props.setDateFormErr("error");
        } else {
          props.setDateFormErr("");
        }
        break;
      case "baseCurrency":
        if (event.target.value == "") {
          props.setCurrencyErr("error");
        } else {
          props.setCurrencyErr("");
        }
        break;
      case "status":
        if (event.target.value == "") {
          props.setStatusErr("error");
        } else {
          props.setStatusErr("");
        }
        break;
      case "timeZone":
        if (event.target.value == "") {
          props.setTimeZoneErr("error");
        } else {
          props.setTimeZoneErr("");
        }
        break;
      case "language":
        if (event.target.value == "") {
          props.setLanguageErr("error");
        } else {
          props.setLanguageErr("");
        }
        break;
      case "authenticationSource":
          if (event.target.value == "") {
            props.setAuthSource("error");
          } else {
            props.setAuthSource("");
          }
          break;
      default:
    }
}
console.log("props.userTypeErr",props.userTypeErr);

    return (
      <Row className="padding-10">
        <Col md={4} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>
            <span className="rStar"></span>User Type 
            </Form.Label>
            {/* <Form.Select aria-label="select" name="userTypeId" className="form-control">
                    <option selected disabled>Select</option>
                </Form.Select> */}
            {/* <Field
                        name="userTypeId"
                        component="select"
                        className={"form-control "+ (props.userTypeErr)}
                        onBlur={(event) => {
                            validateGroupsField("userTypeId", event);
                        }}
                    >
                    <option value="null">{tr["Select"]}</option>
                    <option value="Contractor">Contractor</option>
                    <option value="Extranet">Extranet</option>
                    <option value="Office Employee">Office Employee</option>
                    <option value="Pending Hire">Pending Hire</option>
                    <option value="Student">Student</option>
                    <option value="Supplier">Supplier</option>
                  
                    </Field> */}
            <Field
              component={_dropDown}
              options={userTypeRedData || []}
              name="userTypeId"
              className={"form-control " + props.userTypeErr}
              onBlur={(event) => {
                validateGroupsField("userTypeId", event);
              }}
            ></Field>
          </Form.Group>
        </Col>
        <Col md={4} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>
            <span className="rStar"></span>Date Format
            </Form.Label>
            {/* <Form.Select aria-label="select" name="dateformat" className="form-control">
                    <option selected disabled>Select</option>
                </Form.Select> */}
            <Field
              component={_dropDown}
              options={dateListREdData || []}
              name="dateformat"
              className={"form-control " + props.dateFormErr}
              onBlur={(event) => {
                validateGroupsField("dateformat", event);
              }}
            ></Field>
          </Form.Group>
        </Col>
        <Col md={4} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>
            <span className="rStar"></span>Time Zone 
            </Form.Label>
            <Field
              component={_dropDown}
              options={timeZoneData || []}
              name="timeZone"              
              className={"form-control " + props.timeZoneErr}
              onBlur={(event) => {
                validateGroupsField("timeZone", event);
              }}
            >
            </Field>
          </Form.Group>
        </Col>
        <Col md={4} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>
            <span className="rStar"></span>Preferred Currency 
            </Form.Label>
            {/* <Form.Select aria-label="select" name="baseCurrency" className="form-control">
                    <option selected disabled>Select</option>
                </Form.Select> */}
            {/* <Field
              name="baseCurrency"
              component="select"
              className="form-control"
            >
              <option value="null">{tr["Select"]}</option>
              <option value="5">AUD</option>
              <option value="2">EUR</option>
              <option value="4">GBP</option>
              <option value="6">INR</option>
              <option value="3">JPY</option>
              <option value="1">USD</option>
            </Field> */}
            <Field
              component={_dropDown}
              options={currencyRedData || []}
              name="baseCurrency"
              className={"form-control " + props.currencyErr}
              onBlur={(event) => {
                validateGroupsField("baseCurrency", event);
              }}/>
          </Form.Group>
        </Col>
        <Col md={4} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>
            <span className="rStar"></span>Status 
            </Form.Label>
            {/* <Form.Select aria-label="select" name="" className="form-control">
                    <option selected disabled>Select</option>
                </Form.Select> */}
            <Field name="status" component="select" className={"form-control " + props.statusErr}
              onBlur={(event) => {
                validateGroupsField("status", event);
              }}>
              <option value="">{tr["Select"]}</option>
              <option value="Y">{tr["Active"]}</option>
              <option value="D">Draft</option>
              <option value="N">{tr["Inactive"]}</option>
            </Field>
          </Form.Group>
        </Col>
        <Col md={4} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>
            <span className="rStar"></span>Authentication Source
            </Form.Label>
            {/* <Form.Select aria-label="select" name="authenticationSource" className="form-control">
                    <option selected disabled>Select</option>
                </Form.Select> */}
            {/* <Field
                        name="authenticationSource"
                        component="select"
                        className="form-control"
                    >
                    <option value="null">{tr["Select"]}</option>
                    <option value="ActiveDirectory">ActiveDirectory</option>
                    <option value="CloudDirectory">CloudDirectory</option>
                    <option value="Other">Other</option>                 
                    </Field> */}
            <Field
              component={_dropDown}
              options={authenticationRedData || []}
              name="authenticationSource"
              className={"form-control " + props.authSource}
              onBlur={(event) => {
                validateGroupsField("authenticationSource", event);
              }}
            ></Field>
          </Form.Group>
        </Col>
        <Col md={4} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>Data Source</Form.Label>
            {/* <Form.Select aria-label="select" name="datasource" className="form-control">
                    <option selected disabled>Select</option>
                </Form.Select> */}
            <Field
              name="datasource"
              component="select"
              className="form-control"
            >
              <option value="null">{tr["Select"]}</option>
              <option value="ActiveDirectory">ActiveDirectory</option>
              <option value="Automated">Automated</option>
              <option value="Manual">Manual</option>
              <option value="OtherApplication">OtherApplication</option>
            </Field>
          </Form.Group>
        </Col>
        <Col md={4} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>
            <span className="rStar"></span>Language Preferences 
            </Form.Label>
            {/* <Form.Select aria-label="select" name="language" className="form-control">
                    <option selected disabled>Select</option>
                </Form.Select> */}
            {/* <Field
                        name="language"
                        component="select"
                        className="form-control"
                    >
                    <option value="null">{tr["Select"]}</option>
                    <option value="1">English</option>
                    <option value="4">French</option>
                    <option value="8">German</option>                 
                    </Field> */}
            <Field
              component={_dropDown}
              options={languageListRedData || []}
              name="language"
              className={"form-control " + props.languageErr}
              onBlur={(event) => {
                validateGroupsField("language", event);
              }}
            ></Field>
          </Form.Group>
        </Col>
      </Row>
    );
}

export default reduxForm({
    form: 'userForm',
    destroyOnUnmount: false,
    enableReinitialize: true
})(UserInfoForm);