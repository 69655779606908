
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import sanitizeHtml from "sanitize-html";
import { getArticleById } from "../../../actions/knowledge/knowledgeAction";
import Cookies from "universal-cookie";

const cookies = new Cookies();
let username = cookies.get("gph");
if (username) username = username.replace("s:", "");
if (username) username = username.substring(0, username.lastIndexOf("."));
username = username.split("~");
username = username[32];

const ArticleDetails = (props) => {
  const dispatch = useDispatch();
  const articleDetailsById = useSelector((state) => state.articleDetails);
  const { articleDetails, translator = {} } = props;

  useEffect(() => {
    dispatch(getArticleById(articleDetails.knowledgeId, username));
  }, [dispatch, articleDetails.knowledgeId]);

  const {
    knowledgeId,
    knowledgeArticle,
    type,
    knowledgeDescription,
    articleUrl = "",
  } = articleDetails;

  let iconClass =
    type === "SPCM"
      ? "KA-icon interLnk1"
      : type === "Knowledge" || type === "conversation"
      ? "KA-icon exterLnk1"
      : "KA-icon communityWh";

  return (
    <div className="knowledge-description-section">
      <Row>
        <Col md={12}>
          <div className="rPageHeading">
            <div className="offNam">
              <span
                className={iconClass}
                title={type === "conversation" ? "Knowledge" : ["type"]}
              ></span>
              {props.renderLargeTextEllipse(knowledgeArticle, 25)}
            </div>
          </div>
        </Col>
      </Row>
      <div className="rBoxGap">
        <Row>
          <Col md={12}>
            {type === "SPCM" ? (
              <div className="KA-description">knowledgeDescription</div>
            ) : (
              <div
                className="KA-description"
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(articleDetailsById),
                }}
              />
            )}
          </Col>
          <Col md={12}>
            <Button
              bsSize="small"
              type="button"
              className="myBtn rgSiLigBtn smallBtn"
              onClick={() => {
                props.setStateV1({
                  articleDetails: null,
                });
              }}
            >
              {["Close"]}
            </Button>
            {type === "SPCM" ? (
              <Button
                bsSize="small"
                type="button"
                className="myBtn KA-download-btn rgSidrkBtn smallBtn"
                onClick={() => {
                  props.downloadFile(
                    type,
                    knowledgeArticle,
                    articleUrl,
                    knowledgeId
                  );
                }}
              >
                <i className="fa fa-download margin-r-5"></i>
                {["Download"]}
              </Button>
            ) : (
              <Button
                bsSize="small"
                type="button"
                className="myBtn KA-download-btn rgSidrkBtn smallBtn"
                onClick={() => {
                  props.downloadFile(type, articleUrl);
                }}
              >
                <i className="fa fa-external-link margin-r-5"></i>
                {["Redirect"]}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ArticleDetails;