
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React,{useState} from 'react';
import ListGroup from "react-bootstrap/ListGroup";
import Card from 'react-bootstrap/Card';
import { Scrollbars } from "react-custom-scrollbars";
import ReactReadMoreLess from "../../../helpers/ReactReadMoreLess";
import {IoClose} from "react-icons/io5";

const RCADetails = ({techniqueName,details,translator}) => {
   return (<div>
        <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
            <ul>
                <li>
                  <button title="Minimize the right panel" bsClass="" bsStyle="" className="closerightPanelBtn">
                     <IoClose />
                  </button>
                </li>
            </ul>
        </div>
        <div className="rBoxGap">
        <Card bsPrefix=" " className="cardLeftdv">
               <Card.Body className="padding-l-10">
                  <Card.Header className="f-size-19 margin-0">
                     <div className='padding-t-10 padding-b-10' style={{fontWeight:"600"}}>{translator['RCA Technique']+': '+techniqueName}</div>
                  </Card.Header>
               </Card.Body>
         </Card>
            <div>
               <Scrollbars style={{ height:40*details.length }} autoHide={false} hideTracksWhenNotNeeded={false} className="areaPopup padding-b-0">
                  <ListGroup className="infoPopupSD padding-l-10 padding-r-10 padding-b-0" bsPrefix=" ">
                     {details.map((item,itr)=>{
                        if(itr===details.length-1){
                           return (<ListGroup.Item bsPrefix=" " className="rwTable" style={{borderBottom:"none"}}>
                           <span className="rwKey">{item.key}</span>
                           <span className="rwVal">
                              <ReactReadMoreLess charLimit={40} readMoreText="more" readLessText="...less" content={item.value}/>
                           </span>
                         </ListGroup.Item>);
                        }else{
                           return (<ListGroup.Item bsPrefix=" " className="rwTable">
                                       <span className="rwKey">{item.key}</span>
                                       <span className="rwVal">
                                          <ReactReadMoreLess charLimit={40} readMoreText="more" readLessText="...less" content={item.value}/>
                                       </span>
                            </ListGroup.Item>);
                        }
                     })}
                  </ListGroup>
               </Scrollbars>
            </div>
         </div>
      </div>);
}
export default RCADetails;