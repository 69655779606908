
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const delegationList = (state = [], action) => {
    switch(action.type) {
        case 'DELEGATION_LIST_SUCCESS':
            return action.delegationList.data;
        case 'DELEGATION_LIST_FAILURE':
            return action.errorResponse;
        default:
            return state;
    }
};
