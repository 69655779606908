
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import { GLOBAL } from '_Globals';
//below URL used for testing purpose
//const breakFixUrl = 'https://xsm-f.mybluemix.net/api/SX_FAVOURITEOFFERINGs/';

//let breakFixUrl=`https://xsm-f.mybluemix.net/groupListSelfService?companyId=2932&group=ResolvedER`

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

export function loadProfileCWLSuccess(CWLDetails){
	return {
		type: 'LOAD_CWLDETAILS_SUCCESS',
		CWLDetails
	};
}

export function loadProfileCWLFailure(errorResponse){
	return {
		type: 'LOAD_CWLDETAILS_FAILURE',
		errorResponse
	};
}

export function loadProfileCWL(){
	return(dispatch) =>{
				//alert("edit"+breakFixUrl+breakFixId);
				api.get(GLOBAL.profileCWLUrl+"?myProfile="+true)
					.then((CWLDetails)=>{
						if(!CWLDetails){
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							throw Error(CWLDetails.statusText);
						}
						return CWLDetails;
					})
					.then((CWLDetails) =>{
						dispatch(loadProfileCWLSuccess(CWLDetails))
					})
					.catch((err) => {
						console.log(err);
					});
					//console.log("not calling loadCWLDetails");

	};
}
