
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});
import { GLOBAL } from '../../components/Globals'
export function loadImplPlanListSuccess(implPlanList) {
	return {
		type: 'LOAD_IMPLPLAN_SUCCESS',
		implPlanList:implPlanList.data
	};
}

export function loadCMDBListSuccess(cmdbList) {
	return {
		type: 'LOAD_CMDB_SUCCESS',
		cmdbList:cmdbList.data
	};
}

export function loadImplPlanList(moduleName,itemId){
	//console.log(moduleName+":"+itemId);
  //alert("called")
	return(dispatch) =>{
    if(moduleName == undefined || moduleName == "" || itemId == undefined || itemId == ""){
			console.log("params not found for action loadImplPlanList")
		} else {
			console.log("params found for action loadImplPlanList")
			api.get(GLOBAL.implPlanList + "?module=" + moduleName + "&itemId=" + itemId)
				.then((implPlanList) => {
					if (!implPlanList) {
						throw Error(implPlanList.statusText);
					}
					return implPlanList;
				})
				.then((implPlanList) => {
					dispatch(loadImplPlanListSuccess(implPlanList))
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};
}

export function loadImplementationPlanSuccess(implementationPlan){
	return {
		type: 'LOAD_IMPLEMENTATION_SUCCESS',
		implementationPlan:implementationPlan.data
	};
}

export function loadImplementationPlan(attachmentId,callmeback){
	//alert("called"+attachmentId);
	return(dispatch) =>{
    if(attachmentId == undefined || attachmentId == ""){
			console.log("params not found for action loadImplementationPlan.")
		}else{
      console.log("params found for action loadImplementationPlan.")
  		axios.get(GLOBAL.implPlanURL+attachmentId)
  			.then((implementationPlan)=>{
  				if(!implementationPlan){
  					throw Error(implementationPlan.statusText);
				  }
  				return implementationPlan;
  			})
  			.then((implementationPlan) =>{
					callmeback(implementationPlan)
  				dispatch(loadImplementationPlanSuccess(implementationPlan))
  			})
  			.catch((err) => {
  				console.log(err);
  			});
      }
	};
}

//Action For TMS Attachment
export function loadTmsList(Taskcode){
	//console.log(moduleName+":"+itemId);
  //alert("called")
	return(dispatch) =>{
  
			console.log("params found for action loadImplPlanList")
			api.get(GLOBAL.tmsAttachList +Taskcode)
				.then((implPlanList) => {
					if (!implPlanList) {
						throw Error(implPlanList.statusText);
					}
					return implPlanList;
				})
				.then((implPlanList) => {
					dispatch(loadImplPlanListSuccess(implPlanList))
				})
				.catch((err) => {
					console.log(err);
				});
		
	};
}

// Action for CMDB ATTACHMENT
export function loadCMDBAttachmentList(CI_ID){
	//console.log(moduleName+":"+itemId);
	//console.log("ciId", CI_ID)
  	//alert("called")
	return(dispatch) =>{
  
			console.log("params found for action loadImplPlanList")

			api.get(GLOBAL.loadCMDBAttachmentList + CI_ID)
				.then((cmdbList) => {
					if (!cmdbList) {
						throw Error(cmdbList.statusText);
					}
					return cmdbList;
				})
				.then((cmdbList) => {
					dispatch(loadImplPlanListSuccess(cmdbList))
				})
				.catch((err) => {
					console.log(err);
				});
	};
}