
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import * as types from '_Actions/actionTypes';
import * as api from '_Services/spcmApi';
import React from "react";


 export const setBreakFixGraphView = (values) =>{
    return (dispatch) => {
            dispatch({
                type:'BREAKFIX_GRAPH_VIEW',
                payload:values
            })
    }
 }

 export const setTaskGraphView = (values) =>{
    return (dispatch) => {
            dispatch({
                type:'TASK_GRAPH_VIEW',
                payload:values
            })
    }
 }

 export const setInvestigationGraphView = (values) =>{
    return (dispatch) => {
            dispatch({
                type:'INVESTIGATION_GRAPH_VIEW',
                payload:values
            })
    }
 }

 export const setChangeGraphView = (values) =>{
    return (dispatch) => {
            dispatch({
                type:'CHANGE_GRAPH_VIEW',
                payload:values
            })
    }
 }

 
 export const setFullfilmentGraphView = (values) =>{
    return (dispatch) => {
            dispatch({
                type:'FULLFILMENT_GRAPH_VIEW',
                payload:values
            })
    }
 }



