
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export default function (state=[],action){
    switch (action.type){
		case 'LOAD_BREAKFIXSGI_SUCCESS_TYPEAHEAD':
              return action.typeaheadData;
        default:
		    return state;  
    }          
}