
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Spinner from "react-spinkit";
import axios from "axios";
import constants from "../../../../utils/constants";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import SlaInfoPopup from "../../../common/WorkItemBoard/SlaInfoPopup";
import { getColorCode } from "../../../../utils/common";
import { HiOutlineRefresh } from "react-icons/hi";
import { reprocessingSlaData } from '../../../../actions/breakFix/breakFixSLALogAction';
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { LuTimerReset } from "react-icons/lu";
import { ImInfo } from 'react-icons/im';
import Cookies from "universal-cookie";
const cookies = new Cookies();
let cookiesArray = cookies.get('gph');
if (cookiesArray) cookiesArray = cookiesArray.replace('s:', '');
if (cookiesArray) cookiesArray = cookiesArray.substring(0, cookiesArray.lastIndexOf('.'));
cookiesArray = cookiesArray.split("~");
let langDir = cookiesArray[66];

const SLAProgress = (props) => {
  const { taskId = "", tr = {}, rightEditPanel = () => {} } = props;


  const [isLoading, setIsLoading] = useState(false);
  const [slaData, setSlaData] = useState(null);
  const [isButtonPresent, setIsButtonPresent] = useState(true);

  const windowSize = useSelector((state) => state.windowSize.width);
  const newLoader = useSelector((state) => state.showLoader_6);
  const dispatch = useDispatch();

  useEffect(() => {
    axios.get(`/api/refreshMeasurement/${taskId}`).then(res=>{
    getSLAProgress();
  });
  }, []);

  const renderReprocessingData = () => {
    Swal.fire({
      title: ["Are you sure you want to Reprocess SLA?"],
      width: 400,
      padding: '13px',
      showDenyButton: true,
      confirmButtonText: tr['Yes'],
      denyButtonText: tr['No'],
      customClass: {
        title: "justifyText"
      }
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(reprocessingSlaData(taskId));
        setTimeout(()=>{
          getSLAProgress();
        },500);
      } else if (result.isDenied) {

      }
    })
  }


  const refreshTable = ()=>{
    axios.get(`/api/refreshMeasurement/${taskId}`).then(res=>{
      getSLAProgress();
  });
  setIsButtonPresent(false);
  setTimeout(()=>{
    setIsButtonPresent(true);
  },1000*60);
  }

  const getSLAProgress = () => {
    try {
      let strObj = {};
      strObj.ticketId = taskId;
      strObj.moduleId = constants?.slamodvaltask || "";
      strObj = JSON.stringify(strObj);
      setIsLoading(true);
      setSlaData(null);
      axios
        .get("/sla-def/getSlaInformation", { headers: { query: strObj } })
        .then((res) => {
          setSlaData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setIsLoading(false);
        });
    } catch (e) {
      console.error(e);
    }
  };

  const renderSLATable = (data) => {
    try {
      if (!(data && Array.isArray(data) && data.length)) {
        return null;
      }
      return data.map((sla, index) => {
        let h = Math.floor(sla.allocatedTime / 60);
        if (h < 10 || h == 0) h = "0" + h;
        let m = parseInt(sla.allocatedTime % 60, 10);

        if (m < 10 || m == 0) m = "0" + m;
        //console.log(h+':'+m);
        let time = h + ":" + m;

        let slastatus = "";
        if (!sla.status) {
          slastatus = "";
        } else if (sla.status == "IN PROGRESS") {
          slastatus = "In Progress";
        } else if (sla.status == "ALREADY BREACHED") {
          slastatus = "Breached";
        } else if (sla.status == "MET") {
          slastatus = "Met";
        } else if (sla.status == "DELINKED")
          slastatus = "Delinked";
        else if (
          sla.status != "IN PROGRESS" &&
          sla.status != "ALREADY BREACHED" &&
          sla.status != "MET"
        ) {
          slastatus = sla.status;
        }

        let hleft = Math.floor(sla.timeLeft / 60);
        if (hleft < 10 || hleft == 0) {
          hleft = "0" + hleft;
        }

        let mleft = sla.timeLeft % 60;
        if (mleft < 10 || mleft == 0) {
          mleft = "0" + mleft;
        }
        //console.log(h+':'+m);
        let timeleft = hleft + ":" + mleft;
        const tooltip = (
          <Popover className="userInfoPopup">
            <SlaInfoPopup slaDetail={sla}/>
          </Popover>
        );

        return (
          <tr key={index}>
            <td className="noWrapName"><OverlayTrigger rootClose={true} trigger="click" placement={windowSize < 768 ? "auto-start" : (langDir === "rtl" ? "right-start" : "left-start")
} key={sla.slaId} overlay={tooltip}><span title="info" className="cursorPoint">{sla.slaName}{" "}<ImInfo /><div><small>{sla.slaType}</small></div></span></OverlayTrigger></td>
            <td>{time} Hours</td>
            <td className="noWrapName">{getColorCode(sla.status)}{" "}{slastatus}</td>
            <td>
              {slastatus && slastatus.toLowerCase() === "invalid"
                ? "N/A"
                : sla.duedate}
            </td>
          </tr>
        );
      });
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  let content = null;
  if (isLoading) {
    content = <Spinner spinnerName="three-bounce" />;
  } else if (!(slaData && Array.isArray(slaData) && slaData.length > 0)) {
    content = tr["Currently no sla exist"];
  } else {
    content = (<>
      {newLoader.loading ?<Spinner spinnerName='three-bounce' />: <Table responsive striped bordered condensed hover className="f-size-13">
        <thead>
          <tr className="f-size-12">
            <th>Name</th>
            <th>SLA</th>
            <th>Status</th>
            <th>Due By</th>
          </tr>
        </thead>
        <tbody>{renderSLATable(slaData)}</tbody>
      </Table>}
      </>
    );
  }
  console.log("ConditionSla", slaData?.length >0 )
  return (
    <div>
      <div className="rPageHeadActBtn" style={{ marginTop: "-1px" }}>
        <ul>
        {(slaData?.length > 0) ? (props.role_id.split(",").includes("1") ?(newLoader.loading ? "" : <li><Button onClick={() => { renderReprocessingData() }}
                bsPrefix=' '
                style={{fontSize:"18px",marginTop: "-3px"}}
                title='Reprocess SLA'
                 >
                <LuTimerReset />
        </Button></li>) : ""):""}
          {(slaData?.length > 0) ?  (isButtonPresent && <li>
            {!newLoader.loading ? <a title={"Refresh"} href={void 0} onClick={() => { refreshTable(); }}>
              <HiOutlineRefresh className="refreshFlip" />
            </a> : ""}
          </li>):""}
        </ul>
      </div>
      <div className="rPageHeading">
        <div className="offNam margin-t-5 margin-b-5">SLA</div>
      </div>
      <div className="rBoxGap">{content}</div>
    </div>
  );
};

export default SLAProgress;
