
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { connect } from "react-redux";
import Spinner from "react-spinkit";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import axios from 'axios';
import SearchArticles from "./searchArticles";
import AttachedArticles from "./attachedArticles";
import ArticleDetails from "./articleDetails";
import {
  loadArticleResultsForSearchString_KA,
  loadInsidedArticlesForSearch_KA,
} from "../../../actions/breakFix/knowledgeArticleAction";
import {
  postKnowledgeArticles,
  getKnowledgeArticles,
  knowledgeArticlesLoading_attached_KA,
  knowledgeArticlesSuccess_attached_KA,
  deleteKnowledgeArticle,
} from "../../../actions/spcmActions";
import {
  emptyInsidedReducer,
  loadDiscourseArticlesForSearch,
  loadDiscourseArticlesForSearchByTopicId,
} from "../../../actions/knowledge/knowledgeAction";
import { IoClose } from "react-icons/io5";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
const cookies = new Cookies();

let emailAddress = cookies.get("gph");
if (emailAddress) emailAddress = emailAddress.replace("s:", "");
if (emailAddress)
  emailAddress = emailAddress.substring(0, emailAddress.lastIndexOf("."));
let emailAddressArray = emailAddress.split("~");
emailAddress = emailAddressArray[29];

let isKnowledgeEnabled = cookies.get("gph");
if (isKnowledgeEnabled)
  isKnowledgeEnabled = isKnowledgeEnabled.replace("s:", "");
if (isKnowledgeEnabled)
  isKnowledgeEnabled = isKnowledgeEnabled.substring(
    0,
    isKnowledgeEnabled.lastIndexOf(".")
  );
let cookiesArray = isKnowledgeEnabled.split("~");
isKnowledgeEnabled = cookiesArray[30];
let insided_community_url = cookiesArray[37];
let discourseUrl = cookies.get("gph");
if (discourseUrl) discourseUrl = discourseUrl.replace("s:", "");
if (discourseUrl)
  discourseUrl = discourseUrl.substring(0, discourseUrl.lastIndexOf("."));
discourseUrl = discourseUrl.split("~");
discourseUrl = discourseUrl[50];

let username = cookies.get("gph");
if (username) username = username.replace("s:", "");
if (username) username = username.substring(0, username.lastIndexOf("."));
username = username.split("~");
username = username[32];

class BreakFixKnowledgeArticles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: "",
      insidedError: "",
      searchArticle: "",
      showArticleTable: false,
      messageText: "",
      messageType: "",
      articleDetails: null,
      searchFilter: "all",
      searchBy: "Keyword",
      offering_id: null,
      serviceStatus: this.props.spcmReducer.editService.status,
    };
    this.submitSearchArticle = this.submitSearchArticle.bind(this);
    this.setStateV1 = this.setStateV1.bind(this);
    this.postKnowledgeArticles = this.postKnowledgeArticles.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
    this.deleteKnowledgeArticle = this.deleteKnowledgeArticle.bind(this);
    this.checkEditAllowed = this.checkEditAllowed.bind(this);
    this.showDescription = this.showDescription.bind(this);
    this.setMessageText = this.setMessageText.bind(this);
    this.checkInsidedApplicable = this.checkInsidedApplicable.bind(this);
    this.renderLargeTextEllipse = this.renderLargeTextEllipse.bind(this);
    this.setSearchBy = this.setSearchBy.bind(this);
    this.setServiceStatus = this.setServiceStatus.bind(this); 
  }

  componentDidMount() {
    let OFFERING_ID =
      this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID;
    this.props.emptyInsidedReducer();
    this.props.getKnowledgeArticles(OFFERING_ID);
    this.setState({ offering_id: OFFERING_ID });
    if (isKnowledgeEnabled == true || isKnowledgeEnabled == "true") {
    }
  }

  checkInsidedApplicable() {
    console.log("checkInsidedApplicable");
    console.log(isKnowledgeEnabled);
    if (isKnowledgeEnabled == true || isKnowledgeEnabled == "true") {
      console.log("insidedError");
      console.log(this.state.insidedError);
      if (this.state.insidedError === "") {
        return "";
      } else {
        return !this.state.insidedError;
      }
    }
    return false;
  }

  setServiceStatus(value){
		this.setState({
			serviceStatus: value,
		});
	}

  setStateV1(obj) {
    this.setState((state) => {
      let updatedState = Object.assign({}, state, obj);
      return updatedState;
    });
  }

  setSearchBy(val) {
    this.setState({ searchBy: val });
  }

  submitSearchArticle(searchText) {
    const { searchFilter, searchBy } = this.state;
    if (isKnowledgeEnabled == true || isKnowledgeEnabled == "true") {
      console.log("data should be visible");
      let tempText = searchText
        .replace("?", "")
        .replace("%", "")
        .replace(/#/g, "");
      if (searchBy == "Keyword")
        this.props.loadDiscourseArticlesForSearch(searchText, username);
      else if (searchBy == "Topic ID")
        this.props.loadDiscourseArticlesForSearchByTopicId(
          searchText,
          username
        );
    }
  }
 
  postKnowledgeArticles(linkArticles) {
    let OFFERING_ID =
      this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID;
    this.props.getKnowledgeArticles(OFFERING_ID);
    this.setState({ offering_id: OFFERING_ID });
    const {
      isFetchingDetails_Knowledge: { isFetching, isFetchingAttachedKA },
    } = this.props;
    if (isFetching || isFetchingAttachedKA) {
      Swal.fire("Another process is already running, Please try later!");
      return false;
    }
    this.setState({
      messageText: "",
      messageType: "",
    });
    this.props.knowledgeArticlesLoading_attached_KA();
    this.props
      .postKnowledgeArticles(linkArticles)
      .then((res) => {
        if (res["status"] && res["status"] === 206) {
          this.setMessageText(res["data"], "error");
        } else {
          let alertMessage =
            linkArticles &&
            Array.isArray(linkArticles) &&
            linkArticles.length > 1
              ? res["data"]
              : res.data;
              if(alertMessage == "Articles attached successfully"){
                this.setMessageText(alertMessage, "success");
              }else{
                this.setMessageText(alertMessage, "danger");
              }
          // this.setMessageText(alertMessage, "success");
        }
        this.props.knowledgeArticlesSuccess_attached_KA();
        this.props.getKnowledgeArticles(this.state.offering_id);
      })
      .catch((err) => {
        this.setMessageText("Unable to link article", "error");
        this.props.knowledgeArticlesSuccess_attached_KA();
        this.props.getKnowledgeArticles(this.state.offering_id);
      });
  }
 
  deleteKnowledgeArticle(id) {
    let OFFERING_ID =
      this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID;
    this.props.getKnowledgeArticles(OFFERING_ID);
    this.setState({ offering_id: OFFERING_ID });
    const {
      isFetchingDetails_Knowledge: { isFetching, isFetchingAttachedKA },
    } = this.props;
    if (isFetching || isFetchingAttachedKA) {
      Swal.fire("Another process is already running, Please try later!");
      return false;
    }
    this.setState({
      messageText: "",
      messageType: "",
    });
    this.props.knowledgeArticlesLoading_attached_KA();
    this.props
      .deleteKnowledgeArticle(id)
      .then((res) => {
        if (res["status"] && res["status"] === 206) {
          this.setMessageText("Unable to remove article", "error");
        } else {
          this.setMessageText("Article removed successfully", "success");
        }
        this.props.knowledgeArticlesSuccess_attached_KA();
        this.props.getKnowledgeArticles(this.state.offering_id);
      })
      .catch((err) => {
        this.setMessageText("Unable to remove article", "error");
        this.props.knowledgeArticlesSuccess_attached_KA();
        this.props.getKnowledgeArticles(this.state.offering_id);
      });
  }

  downloadFile(type, slug, id, path) {
    if (type === "SPCM") {
      let link = document.createElement("a");
      link.href = path;
      link.id = "temp_downloadfile";
      link.target = "_self";
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        link.remove();
      }, 1000000);
    } else {
      const ssoUrl = `${discourseUrl}/t/${slug}`;
      const win = window.open(ssoUrl, "_blank");
    }
  }

  checkEditAllowed() {
    const {
      breakFixEditDetails: { status },
    } = this.props;
    if (status === "Fixed" || status === "Closed" || status === "Cancelled") {
      return false;
    }
    return true;
  }

  showDescription(item) {
    this.setState({
      articleDetails: item,
    });
  }

  setMessageText(message, type) {
    const that = this;
    this.setState({
      messageText: message,
      messageType: type,
    });
    setTimeout(() => {
      that.setState({
        messageText: "",
        messageType: "",
      });
    }, 10000);
  }

  renderLargeTextEllipse(text, limit = 21) {
    try {
      return (
        <span title={text}>
          {text.slice(0, limit)}
          {text.length > limit ? "..." : ""}
        </span>
      );
    } catch (e) {
      return null;
    }
  }

  render() {
    // console.log('KA***********', {props: this.props, state: this.state});

    const {
      articleDetails,
      messageText,
      messageType,
      searchArticle,
      showArticleTable,
      searchFilter,
    } = this.state;
    const {
      isFetchingDetails_Knowledge: { isFetchingAttachedKA, isFetching },
      attachedKnowledgeArticlesList,
    } = this.props;

    let checkInsidedApplicable = this.checkInsidedApplicable();

    return (
      <div>
        {articleDetails ? (
          <ArticleDetails
            articleDetails={articleDetails}
            setStateV1={this.setStateV1}
            downloadFile={this.downloadFile}
            translator={this.props.translator}
            renderLargeTextEllipse={this.renderLargeTextEllipse}
          />
        ) : (
          <div>
            <Row>
              <Col md={12}>
                <div
                  className="rPageHeadActBtn d-md-none"
                  style={{ marginTop: "-3px" }}
                >
                  <ul>
                    <li>
                      <button
                        type="button"
                        title="Minimize the right panel"
                        bsClass=""
                        bsStyle=""
                        className="closerightPanelBtn"
                        onClick={() => {
                          this.props.rightEditPanel(false);
                        }}
                      >
                        <IoClose />
                      </button>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
            <div className="rBoxGap">
              <Row>
                {messageText && (
                  <Col md={12}>
                    <div className="inlineFields KA-error-msg">
                      <span
                        style={{
                          color:
                            messageType === "success"
                              ? "rgb(80, 178, 69)"
                              : "#ff4e00",
                        }}
                      >
                        {this.renderLargeTextEllipse(messageText, 50)}
                      </span>
                    </div>
                  </Col>
                )}

                {this.checkEditAllowed() &&
                    <Col md={12}>
                      <SearchArticles
                        searchArticle={searchArticle}
                        showArticleTable={showArticleTable}
                        translator={this.props.translator}
                        submitSearchArticle={this.submitSearchArticle}
                        setStateV1={this.setStateV1}
                        postKnowledgeArticles={this.postKnowledgeArticles}
                        downloadFile={this.downloadFile}
                        showDescription={this.showDescription}
                        searchFilter={searchFilter}
                        checkInsidedApplicable={checkInsidedApplicable}
                        renderLargeTextEllipse={this.renderLargeTextEllipse}
                        searchBy={this.state.searchBy}
                        setSearchBy={this.setSearchBy}
                        setServiceStatus={this.setServiceStatus}
                        serviceStatusState={this.props.serviceStatusState}
                      />
                    </Col>
                  }

                <Col md={12}>
                  {isFetchingAttachedKA ? (
                    <div>
                      <Spinner spinnerName="three-bounce" />
                    </div>
                  ) : (
                    attachedKnowledgeArticlesList &&
                    Array.isArray(attachedKnowledgeArticlesList) &&
                    attachedKnowledgeArticlesList.length > 0 && (
                      <AttachedArticles
                        deleteKnowledgeArticle={this.deleteKnowledgeArticle}
                        downloadFile={this.downloadFile}
                        checkEditAllowed={this.checkEditAllowed}
                        showDescription={this.showDescription}
                        translator={this.props.translator}
                        renderLargeTextEllipse={this.renderLargeTextEllipse}
                        setServiceStatus={this.setServiceStatus}
                        serviceStatusState={this.props.serviceStatusState}
                      />
                    )
                  )}
                </Col>
              </Row>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  spcmReducer,
  externalSystemAttributesList,
  insidedTokenDetails,
  isFetchingDetails_Knowledge,
  breakFixEditDetails,
  attachedKnowledgeArticlesList,
  insidedResultsList,
}) => {
  return {
    tr: spcmReducer.tr,
    spcmReducer,
    externalSystemAttributesList,
    insidedTokenDetails,
    isFetchingDetails_Knowledge,
    breakFixEditDetails,
    attachedKnowledgeArticlesList,
    insidedResultsList,
  };
};

export default connect(mapStateToProps, {
  emptyInsidedReducer,
  loadArticleResultsForSearchString_KA,
  loadInsidedArticlesForSearch_KA,
  getKnowledgeArticles,
  postKnowledgeArticles,
  knowledgeArticlesLoading_attached_KA,
  knowledgeArticlesSuccess_attached_KA,
  deleteKnowledgeArticle,
  loadDiscourseArticlesForSearch,
  loadDiscourseArticlesForSearchByTopicId,
})(BreakFixKnowledgeArticles);
