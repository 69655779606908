
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { Component, PropTypes } from 'react';
import { Row, Col, FormGroup, FormControl, ControlLabel, Button, ListGroup, ListGroupItem } from 'react-bootstrap';
import AutoSuggest from 'react-autosuggest';
import axios from 'axios';


import { GLOBAL } from "_Globals";


let selectOptions = [];
const api = axios.create({
    headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

const getSuggestions = (value, options) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    if (inputLength > 2) {
      selectOptions = options;
    }
    return inputLength < 2 ? [] : selectOptions;
};

const getSuggestionValue = suggestion => suggestion.label.indexOf == -1 ? '' : suggestion.label.substring(0, suggestion.label.indexOf('|'));

function renderSuggestion(suggestion) {
    let suggestionlabel = [];
    return (
      <span onClick={() => {
        suggestionlabel = suggestion.label.split("|");
      }}>{suggestion.label}</span>
    );
}

class RFReactSelect extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.onUserSelected = this.onUserSelected.bind(this);
        this.state = {
          value: '',
          suggestions: []
        };
    }
    onChange(event, { newValue }) {
        this.setState({value: newValue});
        if (this.props.emptyAutosuggestValue == true)
          this.props.changeAutosuggestState(false);
    }
    onSuggestionsFetchRequested({ value }) {     
        let consumerData = [];
        let consumerDataDetails = [];
        let myObj = {};
        if (value.length > 2) {
          api.get('/api/user/userInfo/' + value).then((response) => {
            consumerData = response.data;
            for (var i = 0; i < consumerData.length; i++) {
              myObj = { adhocApproverUserName: '', adhocApproverFullName: '', adhocApproverUserId: '', adhocApproverEmail: '', label: '' };
              myObj.adhocApproverUserName = consumerData[i].User_Name;
              myObj.adhocApproverFullName = consumerData[i].Full_Name;
              myObj.adhocApproverUserId = consumerData[i].user_id;
              myObj.adhocApproverEmail = consumerData[i].Email_Address;
              myObj.label = consumerData[i].Name + "|" + consumerData[i].user_id + "|" + consumerData[i].Email_Address + "|" + consumerData[i].Full_Name;
              consumerDataDetails.push(myObj);
            }
            consumerDataDetails = Array.from(new Set(consumerDataDetails));
            this.setState({
              suggestions: getSuggestions(value, consumerDataDetails)
            });
          });
        } else {
          this.setState({suggestions: []});
        }
    }
    onSuggestionsClearRequested() {
        this.setState({suggestions: []});
    }
    onUserSelected(event, { suggestion }) {
      this.props.onUserNameChange(suggestion);
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.emptyAutosuggestValue != this.props.emptyAutosuggestValue && nextProps.emptyAutosuggestValue == true)
          this.setState({ value: '' });
    }
    render() {
        const { value, suggestions } = this.state;
        const inputProps = {
          name: (this.props && this.props.input && this.props.input.name) ? this.props.input.name : (this.props.name ? this.props.name : ""),
          placeholder: this.props.tr["Enter name or Username"],
          className: 'form-control',
          value,
          onChange: this.onChange,
        };
        return (
          <AutoSuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            onSuggestionSelected={this.onUserSelected}
          />
        );
      }
}
export default RFReactSelect;