
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import Form from "react-bootstrap/Form";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import { GLOBAL } from "../../Globals";
import {
  updateLoadFulfillmentDetails,
  changeFieldStatusData,
} from "../../../actions/fulfillment/fulfillmentDataAction";
import { loadStatusBasedHamburgerOptions } from "../../../actions/common/loadStatusBasedHamburgerOptions";
import { loadTimelineData } from "../../../actions/common/commonApisActions.js";
import swal from "sweetalert";
import Cookies from "universal-cookie";
import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";

const cookies = new Cookies();
let languageSelected = cookies.get("gph");
languageSelected = languageSelected.split("~");
languageSelected = languageSelected[20];
const Joi = require("joi");
class FulfillmentCancel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: "",
      canSavedMessage: "",
      canErrorMessage: "",
      clickOnCalSubmit: 0,
      cancelCode: "",
      cancelValue: "",
      typeList: []
    };
    this.postCancActivityData = this.postCancActivityData.bind(this);
    this.activityData = this.activityData.bind(this);
    this.cancelReason = this.cancelReason.bind(this);
    this.setCancellationType = this.setCancellationType.bind(this);
    this.fetchCancellationType = this.fetchCancellationType.bind(this);
    (GLOBAL.cancelreasonCharLeft = 500), (GLOBAL.cancelreasonCharMax = 500);
  }
  componentWillMount() {
    this.fetchCancellationType(this.props.fulfillmentdetails.companyId,'Fulfillment','Cancelled','1');
  }

  postCancActivityData() {
    if (
      this.props.user_id != this.props.fulfillmentdetails.asgUserid &&
      (this.props.roleIdentification("43") ||
        this.props.roleIdentification("44")) &&
      this.props.roleIdentification("13")
    ) {
      swal({
        text: this.props.translator[
          "Please assign Fulfillment ticket to yourself before you Submit"
        ],
        button: this.props.translator["OK"],
      });
    } else {
      if(this.state.cancelCode===''){
        this.setState({ canErrorMessage: this.props.translator['Please Select Cancellation Type'],});
        return;
      }
      let count_size = 0;
      for (let i = 0; i < this.state.comments.length; i++) {
        if (this.state.comments[i] == " ") count_size = count_size + 1;
      }
      if (this.state.comments.length == count_size) {
        this.setState({
          canErrorMessage:
            this.props.translator["This Field Cannot Be Left Blank"],
        });
        return;
      }
      let self = this;
      if (this.state.comments != "") {
        self.setState({
          canErrorMessage: "",
          clickOnCalSubmit: 1,
        });
        let postjson = {
          itemId: this.props.fulfillmentdetails.itemId,
          description:
          "Cancellation Type: "+this.state.cancelValue+", Cancellation Reason:" +
            this.state.comments
              .replace(/</g, "")
              .replace(/>/g, "")
              .replace(/"/g, "")
              .replace(/\\/g, "\\\\")
              .replace(/(\r\n|\n|\r)/gm, ""),
          isInternal: "N",
          createdOn: "",
          createdBy: "",
          module: "Fullfilment",
          createdByName: "",
        };
        axios
          .post(GLOBAL.breakFixActivityLogPostUrl, postjson)
          .then((response) => {
            if (response.status === 201) {
              axios
                .patch(
                  GLOBAL.fulfillmentpost + this.props.fulfillmentdetails.itemId,
                  {
                    itemStatus: "85",
                    modifiedBy: "",
                    modifiedByName: "",
                    holdingReasonCode: "110",
                    cancellationReason: self.state.comments
                      .replace(/</g, "")
                      .replace(/>/g, "")
                      .replace(/"/g, "")
                      .replace(/\\/g, "\\\\")
                      .replace(/(\r\n|\n|\r)/gm, ""),
                    cancellationReasonCode: this.state.cancelCode,
                    cancellationReasonValue: this.state.cancelValue, 
                  }
                )
                .then(function (response) {
                  self.props.changeFieldStatusData("85");
                  self.props.updateLoadFulfillmentDetails(response);
                  self.props.loadStatusBasedHamburgerOptions(
                    "Item",
                    response.data.status,
                    response.data.itemId
                  );
                  self.props.processHierarchy();
                  self.setState({
                    canSavedMessage: "Cancellation Comments",
                    canErrorMessage: "",
                    clickOnCalSubmit: 0,
                  });
                })
                .catch(function (error) {
                });
            }
          });
      } else {
        self.setState({
          canErrorMessage: "Please provide cancellation reason",
        });
      }
    }
  }
  cancelReason(e, textArea) {
    if (textArea == "cancel") {
      let input = e.target.value.length;
      GLOBAL.cancelreasonCharLeft = GLOBAL.cancelreasonCharMax - input;
      this.forceUpdate();
      this.setState({ comments: e.target.value });
    }
  }
  activityData(e) {
    this.setState({ comments: e.target.value });
  }
  setCancellationType(event){
    if(event.nativeEvent.target.value===''){
      this.setState({canErrorMessage: this.props.translator['Please Select Cancellation Type']});
    }
    this.setState({cancelCode: event.nativeEvent.target.value, cancelValue: event.nativeEvent.target[event.target.selectedIndex].text});
  }
  renderCancellationType(values){
    return values.map(item => <option key={item.field1Key} value={item.field1Value}>{item.field1Key}</option>);
  }
  fetchCancellationType(companyId,modules,fields,status){
    axios.get(`/api/company_menulist?companyId=${companyId}&module=${modules}&field=${fields}&language=${languageSelected}&status=${status}`).then(reterivedReasons => {
      this.setState({typeList: reterivedReasons.data});
   });
  }

  render() {
    return (
      <div className="hambTab">        
        <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
          <ul>
            <li>
            <button
                type='button'
                title="Minimize the right panel"
                bsClass=""
                bsStyle=""
                className="closerightPanelBtn"
                onClick={() => {
                this.props.rightEditPanel(false);
                }}
            >
                <IoClose/>
            </button>
            </li>
          </ul>
        </div>
        <div className="rPageHeading">
          <div className="offNam margin-t-5 margin-b-5">
            {this.props.translator["Cancel Issue"]}
          </div>
        </div>
        <div className="rBoxGap">
          {this.state.canSavedMessage == "" ? (
            <div>
              <Form.Group className="form-group">
              <Form.Label bsClass=""><span className="rStar"/>{this.props.translator["Cancellation Type"]}</Form.Label>
                  <Form.Select required="true" componentClass="select" onChange={this.setCancellationType}>
                      <option value="">Select</option>
                      {this.renderCancellationType(this.state.typeList)}
                  </Form.Select>
              </Form.Group>
              <Form.Group>
                <Form.Label bsClass="">
                  <span className="rStar"></span>
                  {this.props.translator["Reason"]}{" "}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows="2"
                  maxLength="500"
                  inputRef={(input) => (this.activityDescription = input)}
                  onChange={(e) => {
                    this.cancelReason(e, "cancel");
                  }}
                />
                <p
                  className="charLeft"
                  style={{ textAlign: "right", fontSize: "11px" }}
                >
                  ({this.props.translator["Characters Remaining"]}:{" "}
                  {GLOBAL.cancelreasonCharLeft}/500)
                </p>
              </Form.Group>
              <div>
                <font color="red">
                  <strong>{this.state.canErrorMessage}</strong>
                </font>
              </div>

              <ButtonToolbar className="black">
                <Button
                  className="rgSidrkBtn smallBtn"
                  bsSize="small"
                  bsStyle="primary"
                  disabled={this.state.clickOnCalSubmit}
                  onClick={() => {
                    this.postCancActivityData();
                  }}
                >
                  {this.state.clickOnCalSubmit ? (
                    <ImSpinner6 className="icn-spinner"/>
                  ) : null}{" "}
                  {this.props.translator["Submit"]}
                </Button>
              </ButtonToolbar>
            </div>
          ) : (
            <div>
              <font color="green">
                <b>{this.props.translator[this.state.canSavedMessage]}</b>
              </font>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateLoadFulfillmentDetails,
      loadStatusBasedHamburgerOptions,
      loadTimelineData,
      changeFieldStatusData,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(FulfillmentCancel);
