
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import "@babel/polyfill";
import "react-app-polyfill/ie11";
import React from "react";
import { Provider } from "react-redux";
import NRoutes from "./routes";
import Cookies from "universal-cookie";

import registerServiceWorker from "./serviceWorkerRegistration";
import { createRoot } from "react-dom/client";

import { getTranslation } from "./actions/spcmActions";
import { loadVersionData } from "./actions/sreAction/sre_Action.js";
import { storeCategoryName } from "./actions/homepage/hamburgerAction";
import { getKnowledgeAPIKey } from "./actions/knowledge/knowledgeAction.js";

const cookies = new Cookies();
let cookiesArray = cookies.get('gph');
if (cookiesArray) cookiesArray = cookiesArray.replace('s:', '');
if (cookiesArray) cookiesArray = cookiesArray.substring(0, cookiesArray.lastIndexOf('.'));
cookiesArray = cookiesArray.split("~");
let langDir = cookiesArray[66];

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap-daterangepicker/daterangepicker.css";

//import "_Css/react-multiselect-two-sides.css";
import "_Css/normalize.min.css";
import "_Css/style.scss";
import "_Css/dateTime.css";
//import "simplebar-react/dist/simplebar.min.css";
if(langDir === "rtl"){import("_Css/rtl.scss").then(module => {console.log("load successfully rtl", module);}).catch(err => {console.error("Failed to load rtl", err);});}

import { GLOBAL } from "./components/Globals";
import configureStoreFunc from "./store/configureStore.dev";
import { getAppliedThemeData } from "./actions/foundation/themesAction.js";
import { setSlaColors } from "./actions/globalSearch/globalSearchAction.js";
import { validateFulFillerRole } from "./utils/common.js";
import { establishWebSocketConnection } from "./actions/WebSocketAction.js";
import { ApolloClient, InMemoryCache, ApolloProvider, gql } from '@apollo/client';
import { setHosts } from "./actions/foundation/commonAction.js";
const client = new ApolloClient({
  uri: '/graphQl',
  cache: new InMemoryCache({
    resultCaching: false,
  }),
});

export const clientApproval = new ApolloClient({
  uri: '/approvalGraphQl',
  cache: new InMemoryCache({
    resultCaching: false,
  }),
});

let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];

let attachmentDetails = cookies.get("gph");
if (attachmentDetails) attachmentDetails = attachmentDetails.replace("s:", "");
if (attachmentDetails)
  attachmentDetails = attachmentDetails.substring(
    0,
    attachmentDetails.lastIndexOf(".")
  );
attachmentDetails = attachmentDetails.split("~");
const appUrl = attachmentDetails[18];

let languageselection = cookies.get("gph");
if (languageselection) languageselection = languageselection.replace("s:", "");
if (languageselection)
  languageselection = languageselection.substring(
    0,
    languageselection.lastIndexOf(".")
  );
languageselection = languageselection.split("~");
let websocketEnabled = languageselection[68];
languageselection = languageselection[20];

let user_roleId = cookies.get("gph");
if (user_roleId) user_roleId = user_roleId.replace("s:", "");
if (user_roleId)
  user_roleId = user_roleId.substring(0, user_roleId.lastIndexOf("."));
user_roleId = user_roleId.split("~");
user_roleId = user_roleId[33];
const gph = cookies.get("gph").split("~");
const companyId = typeof gph[48] !== "undefined" ? gph[48] : "";

const store = configureStoreFunc();
if (
  homepagelocation != "/unauthorized" &&
  homepagelocation != "/nodomain" &&
  homepagelocation != "/authenticationError" &&
  homepagelocation != "/sessionTimeout" &&
  homepagelocation != "/userActivationPending" &&
  homepagelocation != "/login"
) {
  let tempAppUrl = "'" + appUrl + "'";
  let data = {
    reportId: "100",
    count: "0",
    filter: [
      {
        filterName: "CINAME",
        filterValue: tempAppUrl,
      },
    ],
  };

  store.dispatch(getTranslation(languageselection));
  store.dispatch(loadVersionData(data));
  store.dispatch(getAppliedThemeData(companyId));
  store.dispatch(getKnowledgeAPIKey());
  store.dispatch(setHosts());
  if(websocketEnabled === "true") {
    store.dispatch(establishWebSocketConnection());
  }
  if(validateFulFillerRole(user_roleId.split(","))){
    store.dispatch(setSlaColors(companyId));
  }
  // {
  //   (user_roleId.indexOf("11") > -1 ||
  //   user_roleId.indexOf("10") > -1 ||
  //   user_roleId.indexOf("47") > -1 ||
  //   user_roleId.indexOf("2") > -1 ) && homepagelocation === "/home"
  //     ? (store.dispatch(loadAdvertisement()), store.dispatch(loadBulletin()))
  //     : "";
  // }
}

const container = document.getElementById("app");
const root = createRoot(container);
root.render(
  <ApolloProvider client={client}>
  <Provider store={store}>
    <NRoutes />
  </Provider>
  </ApolloProvider>
);

registerServiceWorker();

let landingLocation = true;
let myapplication = window.location.href;
GLOBAL.isLogout = "false";
sessionStorage.removeItem("filterValues");
// if (myapplication.indexOf("myapp") > -1 && user_roleId != undefined)
//   window.history.pushState({}, "", "/myApprovals");

// if (myapplication.indexOf("myrequest") > -1 && user_roleId != undefined)
//   window.history.pushState({}, "", "/myOrders");
if (myapplication.indexOf("myrequest") != null) {
  if (myapplication.indexOf("myrequest") > -1 && user_roleId != undefined) {
    window.history.pushState({}, "", "/myOrders");
    landingLocation = false;
  }
}
if (myapplication.indexOf("myreview") != null) {
  if (myapplication.indexOf("myreview") > -1 && user_roleId != undefined) {
    window.history.pushState({}, "", "/reviews");
    landingLocation = false;
  }
}
if (myapplication.indexOf("myapp") != null) {
  if (myapplication.indexOf("myapp") > -1 && user_roleId != undefined) {
    window.history.pushState({}, "", "/myApprovals");
    landingLocation = false;
  }
}

// if (myapplication.indexOf("myreview") > -1 && user_roleId != undefined)
//   window.history.pushState({}, "", "/reviews");

if (myapplication.indexOf("offeringdetail") > -1 && user_roleId != undefined) {
  let offeringurl = new URL(myapplication);
  let offeringdetaillink = offeringurl.searchParams.get("offeringdetail");
  let offeringdetailsplit = offeringdetaillink.split("/");
  let serviceId = offeringdetailsplit[0];
  let categoryname = offeringdetailsplit[1];
  categoryname = categoryname.includes("?")
    ? categoryname.replace("?", "")
    : categoryname;
  let subcategoryname = offeringdetailsplit[2];
  store.dispatch(storeCategoryName(categoryname));
  landingLocation = false;
  window.history.pushState(
    {},
    "",
    "/offeringDetails/" + serviceId + "/" + categoryname + "/" + subcategoryname
  );
}
if (myapplication.indexOf("editTask") > -1 && user_roleId != undefined) {
  let taskUrl = new URL(myapplication);
  let editTaskLink = taskUrl.searchParams.get("editTask");
  let editTaskSplit = editTaskLink.split("/");
  let taskId = editTaskSplit[0];
  let clientId = editTaskSplit[1];
  let assignedToGrpId = editTaskSplit[2];
  landingLocation = false;
  window.history.pushState(
    {},
    "",
    "/editTask/" + taskId + "/" + clientId + "/" + assignedToGrpId
  );
}

if (myapplication.indexOf("amsEditRules") != null) {
  if (myapplication.indexOf("amsEditRules") > -1) {
    let amsUrl = new URL(myapplication);
    let amsUrllink = amsUrl.searchParams.get("amsEditRules");
    if (amsUrllink) landingLocation = false;
    window.history.pushState({}, "", "/amsEditRules/" + amsUrllink);
  }
}
console.log('myapplication --- ', myapplication)
if (myapplication.indexOf("editRule") != null) {
  if (myapplication.indexOf("editRule") > -1) {
    let rulesUrl = new URL(myapplication);
    let rulesUrlLink = rulesUrl.searchParams.get("editRule");
    if (rulesUrlLink) landingLocation = false;
    window.history.pushState({}, "", "/editRule/" + rulesUrlLink);
  }
}

if (
  myapplication.indexOf("editcmdb") != null &&
  myapplication.indexOf("editcmdb") > -1
) {
  let cmdbUrl = new URL(myapplication);
  let cmdbUrllink = cmdbUrl.searchParams.get("editcmdb");
  if (cmdbUrllink) landingLocation = false;
  window.history.pushState({}, "", "/editcmdb/" + cmdbUrllink);
}

if (
  myapplication.indexOf("visualCI") != null &&
  myapplication.indexOf("visualCI") > -1
) {
  let cmdbUrl = new URL(myapplication);
  let cmdbId = cmdbUrl.searchParams.get("visualCI");
  let cmdbUrllink = cmdbUrl.searchParams.get("visualCI");
  if (cmdbUrllink) landingLocation = false;
  window.history.pushState({}, "", "/visualCI/" + cmdbId);
}

if (
  myapplication.indexOf("editBreakFix") != null &&
  myapplication.indexOf("editBreakFix") > -1
) {
  let breakfixUrl = new URL(myapplication);
  let breakfixUrllink = breakfixUrl.searchParams.get("editBreakFix");
  if (breakfixUrllink) landingLocation = false;
  window.history.pushState({}, "", "/editBreakFix/" + breakfixUrllink);
}

if (
  myapplication.indexOf("editChange") != null &&
  myapplication.indexOf("editChange") > -1
) {
  let changeUrl = new URL(myapplication);
  let changeUrllink = changeUrl.searchParams.get("editChange");
  if (changeUrllink) landingLocation = false;
  window.history.pushState({}, "", "/editChange/" + changeUrllink);
}

if (
  myapplication.indexOf("investigationEdit") != null &&
  myapplication.indexOf("investigationEdit") > -1
) {
  let investigationUrl = new URL(myapplication);
  let investigationUrllink =
    investigationUrl.searchParams.get("investigationEdit");
  if (investigationUrllink) landingLocation = false;
  window.history.pushState(
    {},
    "",
    "/investigationEdit/" + investigationUrllink
  );
}

if (
  myapplication.indexOf("editCustomAttribute") != null &&
  myapplication.indexOf("editCustomAttribute") > -1
) {
  let tagUrl = new URL(myapplication);
  let tagUrllink = tagUrl.searchParams.get("editCustomAttribute");
  if (tagUrllink) landingLocation = false;
  window.history.pushState({}, "", "/editCustomAttribute/" + tagUrllink);
}

if (
  myapplication.indexOf("onCallScheduleEditRoster") != null &&
  myapplication.indexOf("onCallScheduleEditRoster") > -1
) {
  let rosterUrl = new URL(myapplication);
  let rosterUrllink = rosterUrl.searchParams.get("onCallScheduleEditRoster");
  if (rosterUrllink) landingLocation = false;
  window.history.pushState(
    {},
    "",
    "/onCallSchedule/editRoster/" + rosterUrllink
  );
}

if (
  myapplication.indexOf("onCallScheduleEditSchedule") != null &&
  myapplication.indexOf("onCallScheduleEditSchedule") > -1
) {
  let scheduleUrl = new URL(myapplication);
  let scheduleUrllink = scheduleUrl.searchParams.get(
    "onCallScheduleEditSchedule"
  );
  if (scheduleUrllink) landingLocation = false;
  window.history.pushState(
    {},
    "",
    "/onCallSchedule/editSchedule/" + scheduleUrllink
  );
}

if (myapplication.indexOf("amsHome") != null) {
  if (myapplication.indexOf("amsHome") > -1 && user_roleId != undefined) {
    window.history.pushState({}, "", "/amsHome");
    landingLocation = false;
  }
}
if (myapplication.indexOf("createSlaDef") != null) {
  if (myapplication.indexOf("createSlaDef") > -1 && user_roleId != undefined) {
    window.history.pushState({}, "", "/createSlaDef");
    landingLocation = false;
  }
}
if (myapplication.indexOf("editSlaDef") != null) {
  if (myapplication.indexOf("editSlaDef") > -1) {
    let slaUrl = new URL(myapplication);
    let slaUrlLink = slaUrl.searchParams.get("editSlaDef");
    if (slaUrlLink) landingLocation = false;
    window.history.pushState({}, "", "/editSlaDef/" + slaUrlLink);
  }
}
if (myapplication.indexOf("fulFillment") != null) {
  if (myapplication.indexOf("fulFillment") > -1) {
    let fuifillmentUrl = new URL(myapplication);
    let fuifillmentUrlLink = fuifillmentUrl.searchParams.get("fulFillment");
    if (fuifillmentUrlLink) landingLocation = false;
    window.history.pushState({}, "", "/fulFillment/" + fuifillmentUrlLink);
  }
}
if (
  myapplication.indexOf("editContract") != null &&
  myapplication.indexOf("editContract") > -1
) {
  let contractUrl = new URL(myapplication);
  let contractUrllink =
  contractUrl.searchParams.get("editContract");
  if (contractUrllink) landingLocation = false;
  window.history.pushState(
    {},
    "",
    "/editContract/" + contractUrllink
  );
}
if (myapplication.indexOf("rules") != null) {
  if (myapplication.indexOf("rules") > -1 && user_roleId != undefined) {
    window.history.pushState({}, "", "/rules");
    landingLocation = false;
  }
}

if (myapplication.indexOf("renderedView") != null) {
  if (myapplication.indexOf("renderedView") > -1 && user_roleId != undefined) {
    let renderedViewUrl = new URL(myapplication);
    let renderedView = renderedViewUrl.searchParams.get("renderedView");
    if(renderedView){
      window.history.pushState({}, "", "/renderedView/"+renderedView);
    }
    landingLocation = false;
  }
}

if (myapplication.indexOf("preview") != null) {
  if (myapplication.indexOf("preview") > -1 && user_roleId != undefined) {
    let previewUrl = new URL(myapplication);
    let preview = previewUrl.searchParams.get("preview");
    if(preview){
      window.history.pushState({}, "", "/preview/"+preview);
    }
    landingLocation = false;
  }
}

let homepage = "/";
if (homepagelocation) homepage = homepagelocation;

if (homepage && homepage != "/" && landingLocation) {
  homepage = homepage.split("/");
  homepage = typeof homepage[1] != "undefined" ? homepage[1] : homepage[0];
  window.history.pushState({}, "", "/" + homepage);
}

// if (myapplication.indexOf("editCompany") != null) {
//   if (myapplication.indexOf("editCompany") > -1) {
//     let companyUrl = new URL(myapplication);
//     let companyUrlLink = companyUrl.searchParams.get("editCompany");
//     if (companyUrlLink) landingLocation = false;
//     window.history.pushState({}, "", "/editCompany/" + companyUrlLink);
//   }
// }

// if (myapplication.indexOf("editGroup") != null) {
//   if (myapplication.indexOf("editGroup") > -1) {
//     let groupUrl = new URL(myapplication);
//     let groupUrlLink = groupUrl.searchParams.get("editGroup");
//     if (groupUrlLink) landingLocation = false;
//     window.history.pushState({}, "", "/editGroup/" + groupUrlLink);
//   }
// }