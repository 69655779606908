
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, change, SubmissionError } from 'redux-form';
import { _passwordField, _inputField, _dropDown, _checkbox, _number } from '../../../common/formFields';
import { _dateTimeFieldGeneral } from "../../../common/ReduxFormFields/formFields";
import ListLoader from "../../../common/loaders/ListLoader.js"
import { loadRosterDetailsByRosterId, emptyRosterDetailsByRosterId } from '_Actions/onCallSchedule/roster/onCallRosterAction';
import onCallDropdownPlusTypeahead from "../../common/onCallDropdownPlusTypeahead.js";

const FormComponent = (props) => {
    const dispatch = useDispatch();
    const translator = useSelector((state) => state.spcmReducer.tr);
    const on_call_roster_loader_reducer = useSelector((state) => state.on_call_roster_loader_reducer);
    const [groupErrorState, setGroupErrorState] = useState(false);
    let groupList = useSelector((state) => state.onCallGroupList);
    const [groupInputValue, setGroupInputValue] = useState("");
    const [groupListOptions, setGroupListOptions] = useState([]);
    useEffect(() => {
        dispatch(loadRosterDetailsByRosterId(props.rosterId));
    }, []);

    useEffect(() => {
        return () => {
            dispatch(emptyRosterDetailsByRosterId());
        }
    }, []);

    useEffect(() => {
        if (props.initialValues && props.initialValues.groupName) {
            props.setSelectedGroupList([{ id: props.initialValues.groupId, label: props.initialValues.groupName }]);

        }
    }, [props.initialValues]);

    useEffect(() => {
        if (groupList && groupList.length > 0) {
            groupList = groupList.map((list) => {
                return { id: list.groupId, label: list.groupName };
            });
            setGroupListOptions(groupList);
        }
    }, [groupList]);
    const onGroupSelection = (event) => {
        if (event && event[0]) {
            setGroupInputValue(event[0].label);
            dispatch(change("addUserToShift", "groupName", event[0].label));
        } else {
            dispatch(change("addUserToShift", "groupName", ""));
        }
        props.setSelectedGroupList(event);
    };

    const setGroupError = () => {
        if (props.selectedGroupList.length === 0) {
            setGroupErrorState(true);
        } else {
            setGroupErrorState(false);
        }
    };

    const onGroupInputChange = (value) => {
        if (value) {
            setGroupInputValue(value);
        } else {
            setGroupInputValue("");
            onUGroupCrossClick();
        }
    };

    const onUGroupCrossClick = () => {
        props.groupCrossClicked();
        setGroupInputValue("");
    };

    function validateField(fieldType, event, picker = false) {
        switch (fieldType) {
            case 'startDate':
                if (!picker) {
                    props.setStartDateErrorState('error');
                } else {
                    props.setStartDateErrorState('');
                }
                break;
            case 'endDate':
                if (!picker) {
                    props.setEndDateErrorState('error');
                } else {
                    props.setEndDateErrorState('');
                }
                break;
            default:
        }
    }

    if (on_call_roster_loader_reducer.showRosterListLoader) {
        return <ListLoader />;
    }
    else {
        return (
            <Row>
                <Col md={6} sm={6} xs={12}>
                    <Form.Group className="form-group">
                        <Form.Label><span className="rStar"></span>{translator['Name']}</Form.Label>
                        <Field name="rosterName" component={_inputField} disabled className="form-control" />
                    </Form.Group>
                </Col>
                <Col md={6} sm={6} xs={12}>
                    <Form.Group className="form-group">
                        <Form.Label><span className="rStar"></span>{translator['Group']}</Form.Label>
                        <div className="minHghtdvTypehd">
                        <Field
                            component={onCallDropdownPlusTypeahead}
                            errorColor={groupErrorState ? "error" : ""}
                            onSelection={onGroupSelection}
                            setErrorColor={setGroupError}
                            name="header_group"
                            id="header_group"
                            className="form-control "
                            options={groupListOptions}
                            selectedOptions={props.selectedGroupList}
                            onInputChange={onGroupInputChange}
                            inputValue={groupInputValue}
                            onCrossClick={onUGroupCrossClick}
                            placeholder={translator["Select Group"]}
                            disabled={true}
                        />
                        </div>
                    </Form.Group>
                </Col>
                <Col md={6} sm={6} xs={12}>
                    <Form.Group className="form-group">
                        <Form.Label><span className="rStar"></span>{translator['Shift']}</Form.Label>
                        <Field name="shiftName" component={_inputField} disabled className="form-control" />
                    </Form.Group>
                </Col>
                <Col md={6} sm={6} xs={12}>
                    <Form.Group className="form-group">
                        <Form.Label><span className="rStar"></span>{translator['From']}</Form.Label>
                        <div className="dateTimeSdiv addRosterCalender">
                            <Field
                                name="startDate"
                                disabledValue={true}
                                component={_dateTimeFieldGeneral}
                                onBlur={(event, picker) => { validateField('startDate', event, picker) }}
                                className={`form-control dateTimePickerField ${props.startDateErrorState}`}
                                formType="onCallSchedule"
                                timeFormat="false"
                            />
                        </div>
                    </Form.Group>
                </Col>
                <Col md={6} sm={6} xs={12}>
                    <Form.Group className="form-group">
                        <Form.Label><span className="rStar"></span>{translator['To']}</Form.Label>
                        <div className="dateTimeSdiv addRosterCalender">
                            <Field
                                name="endDate"
                                component={_dateTimeFieldGeneral}
                                disabledValue={true}
                                onBlur={(event, picker) => { validateField('endDate', event, picker) }}
                                className={`form-control dateTimePickerField ${props.endDateErrorState}`}
                                formType="onCallSchedule"
                                timeFormat="false"
                            />
                        </div>
                    </Form.Group>
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state) => ({
    initialValues: state.on_call_roster_reducer.rosterDetailsByRosterId
});

export default connect(mapStateToProps, null)(
    reduxForm({
        form: 'onCallScheduleEditRosterForm',
        destroyOnUnmount: true,
        enableReinitialize: false
    })(FormComponent)
);
