
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useCallback } from "react";
import { Row, Col, Carousel } from "react-bootstrap";
function StatusTimeLine({ setShowTimeLine, actionStatus }) {
  const renderBreakFixButtons = useCallback((timelineDataResult) => {
    let carouselItems = [];
    let allStatuses = timelineDataResult;
    if (allStatuses) {
      let totalCarouselItems;
      let len = allStatuses.length;
      if (len % 5 > 0) totalCarouselItems = len / 5 + 1;
      else totalCarouselItems = Math.ceil(len / 5);

      let carouselCount = 0;
      for (let i = 1; i <= totalCarouselItems; i++) {
        let carouselItem = (
          <Carousel.Item>
            <ul className="progressbarDiv">
              {renderCurrentCarouselItems(allStatuses, carouselCount)}
            </ul>
          </Carousel.Item>
        );
        carouselItems.push(carouselItem);
        carouselCount++;
      }
    }
    return carouselItems;
  }, []);
  const renderCurrentCarouselItems = (allStatuses, carouselCount) => {
    let currentCarouselItems = [];
    for (let i = 1; i <= 5; i++) {
      let currentIndex = carouselCount * 5 + i - 1;
      if (currentIndex < allStatuses.length) {
        let item = (
          <li className={currentIndex == 0 ? "mActive" : "mdisabled"}>
            <span className="box">
              <span className="step1">
                <span className="step2"></span>
              </span>
            </span>
            <div className="txt">
              <div className="head">
                <span>{currentIndex == 1 ? 3 : 1}</span>
                {allStatuses[currentIndex]?.newValue}
              </div>
              <div className="time">{allStatuses[currentIndex].updatedOn}</div>
              <div className="name">{allStatuses[currentIndex].updatedBy}</div>
            </div>
          </li>
        );
        currentCarouselItems.push(item);
      }
    }

    return currentCarouselItems;
  };

  return (
    <Row className="margin-b-20">
      <Col xs={12}>
        <div className="timelineCSS">
          <div className="progressbarDivOuter position-relative">
            <span
              title="Close Timeline"
              className="progressClose cursorPoint"
              onClick={() => setShowTimeLine(false)}
            >
              X
            </span>

            <Carousel controls={false} interval={5000}>
              {renderBreakFixButtons(actionStatus)}
            </Carousel>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default StatusTimeLine;
