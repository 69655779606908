
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { reduxForm } from "redux-form";

import moment from "moment";
import Cookies from "universal-cookie";

import {
  deleteExternalLinkData,
  saveExternalLink,
  updateExternalLink,
} from "../../../../../actions/foundation/general/ExternalLinkAction";

import { ImSpinner6 } from "react-icons/im";
import { IoSaveOutline, IoClose } from "react-icons/io5";
import { HiOutlineTrash } from "react-icons/hi";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';

const cookies = new Cookies();

let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

let ExternalLinkFormHeader = (props) => {
  const navigate = useNavigate();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const showLoader = useSelector((state) => state.commonLoader.isLoading);

  const dispatch = useDispatch();
  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const saveObjRef = useRef(null);

	useEffect(() => {
		context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
    context.newTabPrevent();
	}, []);

  const submitForm = (values) => {
    if (props.formType === "Create") {
      console.log("ValueCreate===",values)
      let payload = {
        linkSequence: values.sequence,
        topThree: values.topThree,
        companyLink: null,
        container: null,
        Link_Container: "image/jpeg",
        checkActionMode: null,
        name: null,
        aliasName: null,
        fileLink: null,
        file: null,
        actionVal: null,
        companyType: null,
        filterBy: null,
        eventNameDefault: null,
        filterbytext: null,
        link_URL: values.linkUrl,
        company_Name: values.company_Name,
        link_status: values.status,
        company_Id: values.company_Id.toString(),
        link_Name: values.name,
        link_Icon: "",
        pagesize: 0,
        link_Container: "image/jpeg",
      };
      console.log("payload ---- 1", payload);

      dispatch(saveExternalLink(payload, values.linkImage));
    } else {
      console.log("values --- ", values, props.formType);

      let payload = {
        id: values.link_Id,
        link_Id: values.link_Id,
        linkSequence: values.sequence,
        topThree: values.topThree,
        companyLink: null,
        container: null,
        Link_Container: "image/jpeg",
        checkActionMode: null,
        name: null,
        aliasName: null,
        fileLink: null,
        file: null,
        actionVal: null,
        companyType: null,
        filterBy: null,
        eventNameDefault: null,
        filterbytext: null,
        link_URL: values.linkUrl,
        company_Name: values.company_Name,
        link_status: values.status,
        company_Id: values.company_Id.toString(),
        link_Name: values.name,
        link_Icon: "",
        pagesize: 0,
        link_Container: "image/jpeg",
      };

      // console.log("Payload --- ", payload, values.linkImage);
      dispatch(updateExternalLink(payload, values.linkImage));
    }
  };

  let id = props.link_Id;
  const onDeleteClick = () => {
    console.log("CheckId", id);
    Swal.fire({
      title: tr["Are you sure you want to delete?"],
      width: 550,
      padding: "15px",
      showDenyButton: true,
      confirmButtonText: tr["Yes"],
      denyButtonText: tr["No"],
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteExternalLinkData(id));
      } else if (result.isDenied) {
      }
    });
  };

  let { handleSubmit, submitting } = props;

  return (
    <Row className="margin-b-15">
      <Col lg={4} md={6} sm={6} xs={12}>
        <h1 bsClass="" className="sPageHeading1">
          {props.name}
        </h1>
      </Col>
      <Col lg={8} md={6} sm={6} xs={12}>
        <div className="paHedFilter">
          {props.formType === "Edit" ? (
            <Button
              role="button"
              bsPrefix=" "
              className="myBt rclose"
              title={tr["Delete"]}
              onClick={onDeleteClick}
            >
              <HiOutlineTrash />
            </Button>
          ) : (
            ""
          )}
          <Link
            bsPrefix=" "
            id="hdID"
            to="javascript:void(0)"
            className="myBt plus ctrlKeyPrevent"
            disabled={showLoader}
            onClick={handleSubmit(submitForm)}
            title={tr["Save"]}
            ref={saveObjRef}
          >
            <span>
              {showLoader ? (
                <ImSpinner6 className="icn-spinner" />
              ) : (
                <IoSaveOutline />
              )}
            </span>
          </Link>
          <Link
            bsPrefix=" "
            to="/externalLink"
            className="myBt cancel fillBtn ctrlKeyPrevent"
            title={tr["Cancel"]}
            ref={closeButton}
          >
            <IoClose/>
          </Link>
        </div>
      </Col>
    </Row>
  );
};

export default reduxForm({
  form: "externalLinkForm",
  enableReinitialize: false,
  destroyOnUnmount: true,
})(ExternalLinkFormHeader);
