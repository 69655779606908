/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/
import { useQuery } from "@apollo/client";
import { useState, useEffect } from "react";
import { GetDataQuery } from "../studio/connectors/graphQl";
import {
  buildQueryParams,
  buildUrlWithParams,
  GQLARRAY,
  updateDataQueryValues,
  validateGraphQlRequest,
  validateRestRequest,
} from "../studio/connectors/utils";
import { useNavigate } from "react-router";
import { replaceVariables as replaceVariablesFn } from "../studio/utils";
import { usePreviousValue } from "../../../helpers/usePreviousValue";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { filter } from "lodash";

export default function useDataQueryWithApi(
  replaceVariables = {},
  fieldAttributes,
  defaultVal = false,
  resetForm,
  tableQuery = {},
  fileData = {}
) {
  const [responseData, setResponseData] = useState(null);
  const [responseStatus, setResponseStatus] = useState(null);
  const [dataSourceId, setDataSourceId] = useState(null);
  const [customLoader, setCustomLoader] = useState(false);
  const [error, setError] = useState(null);
  const [refreshed, setRefreshed] = useState(null);
  const [dependents, setDependents] = useState(null);
  const previousValues = usePreviousValue(defaultVal);
  const hosts = useSelector((state) => state?.hosts);
  const {
    data: dataQuery,
    loading: dataLoading,
    refetch: fetchDataQuery,
    error: dataLoadError,
  } = useQuery(GetDataQuery, {
    variables: { id: dataSourceId },
    skip: dataSourceId == null,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (dataSourceId) {
      fetchDataQuery();
    }
  }, [dataSourceId]);

  useEffect(() => {
    if (dataQuery?.dataQuery && !fieldAttributes.populateData) fetchData();
  }, [dataQuery]);

  useEffect(() => {
    if (dataLoadError) {
      setError(dataLoadError);
    }
  }, [dataLoadError]);

  useEffect(() => {
    if (refreshed) {
      if (dataQuery?.dataQuery) {
        fetchData();
      }
      setRefreshed(false);
    }
  }, [refreshed]);

  useEffect(() => {
    if (defaultVal && dependents) {
      let refetch = false;
      dependents.forEach((key) => {
        if (previousValues[key] !== defaultVal[key]) {
          refetch = true;
        }
      });
      if (refetch) {
        setTimeout(() => {
          fetchData();
          refetch = false;
        }, 200);
      }
    }
  }, [defaultVal]);

  useEffect(() => {
    if (
      responseStatus === 200 ||
      responseStatus === 201 ||
      responseStatus === 204
    ) {
      if (fieldAttributes?.successMessage?.length > 0) {
        Swal.fire({
          title: fieldAttributes?.successMessage,
          icon: "success",
        }).then(() => {
         if(!fieldAttributes?.uploadFile || Object.keys(fileData).length === 0){
          resetForm(true);
          navigate(
            replaceVariablesFn(fieldAttributes?.redirectUrl, {
              ...replaceVariables,
              ...defaultVal,
            })
          );
        }
        });
      } else {
        if(!fieldAttributes?.uploadFile || Object.keys(fileData).length === 0){ 
        resetForm ? resetForm(true) : "";
        navigate(
          replaceVariablesFn(fieldAttributes?.redirectUrl, {
            ...replaceVariables,
            ...defaultVal,
          })
        );
       }
      }
    }
  }, [responseStatus]);

  const getRequestData = () => {
    const data = JSON.parse(dataQuery.dataQuery.data);
    data.body.value = data.dependent
      ? updateDataQueryValues(data.body.value, data.dependent, {
          ...replaceVariables,
          ...defaultVal,
        })
      : data.body.value;
    if (data?.body?.variables) {
      data.body.variables = data.dependent
        ? updateDataQueryValues(data.body.variables, data.dependent, {
            ...replaceVariables,
            ...defaultVal,
          })
        : data.body.variables;
    }
    const restOptions = {
      url: dataQuery.dataQuery.connector.url,
      host:
        dataQuery.dataQuery.connector?.module == "other"
          ? ""
          : hosts.hasOwnProperty(dataQuery.dataQuery.connector?.module)
          ? hosts[dataQuery.dataQuery.connector?.module]
          : "",
      verb: dataQuery.dataQuery.connector.verb,
      module: dataQuery.dataQuery.connector?.module,
      headers: data.dependent
        ? updateDataQueryValues(data.headers, data.dependent, {
            ...replaceVariables,
            ...defaultVal,
          })
        : data.headers,
      queries: data.dependent
        ? updateDataQueryValues(data.query_string, data.dependent, {
            ...replaceVariables,
            ...defaultVal,
          })
        : data.query_string,
      body: data.body,
    };
    setDependents(data.dependent);
    return restOptions;
  };

  const getRequestDataForTable = () => {
    const data = JSON.parse(dataQuery.dataQuery.data);
    if (tableQuery?.dataTransmissionMethod === "body") {
      if (GQLARRAY.includes(dataQuery.dataQuery.connector.verb)) {
        data.body.value = fieldAttributes?.sendDataAsJSON && !data.body?.variables
          ? JSON.stringify(data.body.value)
          : data.body.value;
        if (data?.body?.variables) {
          data.body.variables.map((variable) => {
            if (variable.key === fieldAttributes?.filterValuesKey) {
              if(typeof variable.value === "string") {
                variable.value = JSON.parse(JSON.parse(variable.value));
              }
              if (fieldAttributes?.sendDataAsJSON) {
                variable.value = JSON.stringify(JSON.stringify({
                  ...variable.value,
                  ...tableQuery?.filterValues,
                }));
              } else {
                variable.value = {
                  ...variable.value,
                  ...tableQuery?.filterValues,
                };
              }
            }
          });
        }
        data.body.variables = data.body.variables;
      } else {
        data.body.value = data.dependent
          ? updateDataQueryValues(data.body.value, data.dependent, {
              ...replaceVariables,
              ...defaultVal,
            })
          : data.body.value;
        if (data?.body?.variables) {
          data.body.variables = data.dependent
            ? updateDataQueryValues(data.body.variables, data.dependent, {
                ...replaceVariables,
                ...defaultVal,
              })
            : data.body.variables;
        }
      }
    } else {
      data.body = {};
    }
    const restOptions = {
      url: dataQuery.dataQuery.connector.url,
      host:
        dataQuery.dataQuery.connector?.module == "other"
          ? ""
          : hosts.hasOwnProperty(dataQuery.dataQuery.connector?.module)
          ? hosts[dataQuery.dataQuery.connector?.module]
          : "",
      verb: dataQuery.dataQuery.connector.verb,
      module: dataQuery.dataQuery.connector?.module,
      headers:
        tableQuery?.dataTransmissionMethod === "headers"
          ? tableQuery?.filterValuesKey && tableQuery?.filterValuesKey !== ""
            ? {
                [tableQuery?.filterValuesKey]: JSON.stringify(
                  tableQuery?.filterValues
                ),
              }
            : JSON.stringify(tableQuery?.filterValues)
          : [],
      queries:
        tableQuery?.dataTransmissionMethod === "queryParams"
          ? buildQueryParams(tableQuery?.filterValues)
          : "",
      body: data.body,
    };

    return restOptions;
  };

  const fetchData = () => {
    if (customLoader || dataLoading) {
      return;
    }
    setResponseStatus(null);
    setCustomLoader(true);
    let restOptions = tableQuery?.tableReq
      ? getRequestDataForTable()
      : getRequestData();
    if (GQLARRAY.includes(restOptions?.verb)) {
      validateGraphQlRequest(restOptions)
        .then((response) => { 
          setCustomLoader(false);
          setResponseStatus(response.status);
          if (Array.isArray(response.data)) {
            setResponseData(response?.data);
          } else if (typeof response.data == "object") {
            let keys = Object.keys(response.data);
            if (keys.length == 1) {
              setResponseData(response.data[keys[0]]);
            } else if (Array.isArray(response.data[keys[0]])) {
              setResponseData(response.data[keys[0]]);
            }else if (response.hasOwnProperty("data") && !tableQuery?.tableReq) {
              setResponseData(response.data);
            } else {
              setResponseData(response.data);
            }
          } else {
            setResponseData(response?.data);
          }
        })
        .catch((error) => {
          console.log("An error occured:" + error.response.message);
          if (error?.response?.status === 401) {
            Swal.fire({
              title: error?.response?.data?.message,
            });
          }
          setCustomLoader(false);
          setError(error);
        });
    } else {
      validateRestRequest(restOptions)
        .then((response) => {
          setCustomLoader(false);
          setResponseStatus(response.status);
          if (Array.isArray(response.data)) {
            setResponseData(response?.data);
          } else if (typeof response.data == "object") {
            let keys = Object.keys(response.data);
            if (Array.isArray(response.data[keys[0]])) {
              setResponseData(response.data[keys[0]]);
            }else if (response?.data?.hasOwnProperty("data") && !tableQuery?.tableReq) {
              setResponseData(response.data?.data);
            } else {
              setResponseData(response.data);
            }
          } else {
            setResponseData(response?.data);
          }
        })
        .catch((error) => {
          console.log("An error occured:" + error);
          if (error?.response?.status === 401) {
            Swal.fire({
              title: error?.response?.data?.message,
            });
          }
          setCustomLoader(false);
          setError(error);
        });
    }
  };

  const loading = customLoader || dataLoading ? true : false;
  return [
    responseData,
    loading,
    setDataSourceId,
    error,
    setRefreshed,
  ];
}
