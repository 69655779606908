
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const repeatativeFieldsBlock = (e) => {
  e.DomComponents.addType(repeatativeFieldsComponent.name, repeatativeFieldAdd);
};

export const repeatativeFieldsComponent = {
  name: "RepeatativeFieldsComponent",
  content: `<div class="row">
  <div class ="col-md-10"><input class="form-control"/></div>
  <div class ="col-md-2"><button>Delete</button></div>
  </div>`,
  traits: [
    {
      type: "text",
      label: "Bind Key",
      name: "valueBindKey",
      changeProp: 1,
    },
    {
      type: "checkbox",
      label: "Required",
      name: "required",
      changeProp: 1,
    },
  ],
};

export const repeatativeFieldAdd = {
  model: {
    defaults: {
      tagName: "div",
      draggable: true,
      attributes: { class: "my-repeatativeFieldsComponent" },
      components: repeatativeFieldsComponent.content,
      traits: repeatativeFieldsComponent.traits,
      editable: true,
    },
  },
};

export const repeatativeFieldBlockJson = {
  id: "repetaiveFieldsBlock",
  label: "Repetative Fields",
  attributes: { class: "fa fa-th" },
  media:`<svg fill="#454545" width="40px" height="40px" viewBox="0 0 32 32" id="icon" xmlns="http://www.w3.org/2000/svg" stroke="#454545"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <defs> <style> .cls-1 { fill: none; } </style> </defs> <path d="M9,12V10H4a2,2,0,0,0-2,2V28a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V19H20v9H4V12" transform="translate(0 0)"></path> <path d="M22,12H14V4h8ZM12,4v8a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V4a2,2,0,0,0-2-2H14A2,2,0,0,0,12,4Z" transform="translate(0 0)"></path> <path d="M14,18v4H10V18h4M8,18v4a2,2,0,0,0,2,2h4a2,2,0,0,0,2-2V18a2,2,0,0,0-2-2H10A2,2,0,0,0,8,18Z" transform="translate(0 0)"></path> <path d="M28,17v2H26V17h2m-4-1v4a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V16a1,1,0,0,0-1-1H25A1,1,0,0,0,24,16Z" transform="translate(0 0)"></path></g></svg>`,
  category: "Basic",
  content: {
    type: repeatativeFieldsComponent.name,
  },
};
