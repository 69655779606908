
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Button } from "react-bootstrap";
import { FaPencil, FaTrash, FaFloppyDisk, FaXmark } from "react-icons/fa6";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

export default function CommandCell(props) {
    const tr = useSelector((state) => state.spcmReducer.tr);
    const { dataItem } = props;
    const inEdit = dataItem["inEdit"];
    const isNewItem = dataItem['id'] === undefined;

    const onDeleteClick = () => {
        Swal.fire({
            title: tr["Are you sure you want to delete?"],
            width:400,
            showDenyButton: true,
            confirmButtonText: tr["Yes"],
            denyButtonText: tr["No"],
        }).then((result) => {
            if (result.isConfirmed) {
                props.delete(dataItem);
            }
        });
    }

    return (
        <td className="k-command-cell">
            {dataItem["editable"] && (
                <>
                    <Button
                        bsPrefix=' '
                        className="formBuilBtn margin-r-5"
                        onClick={() => {
                            inEdit
                                ? isNewItem
                                    ? props.add(dataItem)
                                    : props.update(dataItem)
                                : props.edit(dataItem)
                        }}
                        title={inEdit ? "Save" : "Edit"}
                    >
                        {inEdit
                            ? (<FaFloppyDisk />)
                            : (<FaPencil />)
                        }
                    </Button>
                    <Button
                        bsPrefix=' '
                        className="formBuilBtn"
                        onClick={() => {
                            inEdit
                                ? isNewItem
                                    ? props.discard(dataItem)
                                    : props.cancel(dataItem)
                                : onDeleteClick()
                        }}
                        title={inEdit ? "Discard" : "Delete"}
                    >
                        {inEdit
                            ? (<FaXmark />)
                            : (<FaTrash />)
                        }
                    </Button>
                </>
            )}
        </td>
    )
}