
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Form, ButtonToolbar, Button, Alert } from 'react-bootstrap';
import  InputGroup  from 'react-bootstrap/InputGroup';
import Image from 'react-bootstrap/Image';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import { field_file } from '../../common/formFields';
import { uploadPicture,getAllServicesList,getServiceDetailsDataList } from '../../../actions/spcmActions';


const validate = (values,props) => {
	console.log("vlaidate values",values);

	let errors={};
	if (!values.offering_picture && !values.offering_icon) {
			errors.offering_picture = 'Select Service Image';
			errors.offering_icon = 'Select Service Icon';
	}

	return errors;
}

class ServiceImages extends React.Component {	
	constructor(props){
	super(props);
		this.state = {
			uploadMessage: '',
			uploadStatus: ''
		}
		this.fileUpload = this.fileUpload.bind(this);
	}
	fileUpload(values){ 
	
		console.log("values.offering_icon",values.offering_icon);
		console.log("values.offering_picture",values.offering_picture);
		console.log("typeOficon", typeof(values.offering_icon));
		console.log("typeOfImage", typeof(values.offering_picture));
		

		if (this.props.serviceStatusState == "Deployed") {
			throw new SubmissionError({ _error: "Changes are not allowed in Deployed status" });
		}
		else {
			// if((typeof(values.offering_icon) !== 'string' || values.offering_icon.length == 1) || (typeof(values.offering_picture)  !== 'string' || values.offering_picture.length == 1)){
				let formData = new FormData();	
			
					
				if(typeof(values.offering_picture) !== 'string' && typeof(values.offering_icon) !== 'string' && values.offering_icon==null ){
					console.log("first tym image");
					formData.append('offering_picture', values.offering_picture[0]);
				}else if(typeof(values.offering_picture) !== 'string' && (typeof(values.offering_icon) != 'object' || typeof(values.offering_icon) =='string')){
					console.log("existing  icon and on uploading image");
					formData.append('offering_picture', values.offering_picture[0]);
				}
				if(typeof(values.offering_icon) !== 'string' && typeof(values.offering_picture) !== 'string' && values.offering_picture==null ){
					console.log("first tym icon");
					formData.append('offering_icon', values.offering_icon[0]);
				}else if(typeof(values.offering_icon) !== 'string' && (typeof(values.offering_picture) != 'object' || typeof(values.offering_picture) =='string' )){
					console.log("existing  image and on uploading icon");
					formData.append('offering_icon', values.offering_icon[0]);
				}
					if(typeof(values.offering_icon) !== 'string' && typeof(values.offering_picture) !== 'string' &&  values.offering_icon != null && values.offering_picture != null ){

					console.log("on uploading both");
					formData.append('offering_picture', values.offering_picture[0]);
					formData.append('offering_icon', values.offering_icon[0]);
				}
			
				this.props.uploadPicture(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,formData,(uploadMessage,uploadStatus)=>{
					this.setState({
						uploadMessage:uploadMessage,
						uploadStatus:uploadStatus
					});
				});
				this.props.getServiceDetailsDataList("", this.props.status);
			// }
			// else{
			// 	this.setState({
			// 		uploadMessage:"Please upload files if you want to change",
			// 		uploadStatus:"error"
			// 	});
			// }
		}
		
	}	

	render(){

		const tr=this.props.tr;


		const { error, handleSubmit }=this.props;
		console.log("this.props.service++++",this.props.spcmReducer.currentlySelectedServiceDetails);
		return(
			<div className='rBoxGap'>
				<form>
				{error == undefined || error == null ? null : <Alert variant="danger" className="errorMessageSec"><p>{error}</p></Alert>}

				{this.state.uploadMessage == "" ? null :  <Alert variant={this.state.uploadStatus =="success" ? "success" : "danger"} className="errorMessageSec"><p>{this.state.uploadMessage}</p></Alert>}
				<Form.Group className="priceForm-group withImgbox margin-b-15">
					{/* <Form.Label bsClass=""><span className="rStar"></span>{tr['Image']}</Form.Label> */}
					<Form.Label bsClass="">{tr['Image']}(upload only .jpeg,.jpg,.png)</Form.Label>

					<InputGroup>
						<Field className='form-control'  component={ field_file } type="file" name="offering_picture"/>
						<InputGroup.Text><Image  style={{width:'100%'}} src={this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_PICTURE?this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_PICTURE:"./views/images/service-icons/service-dummy.png"} /></InputGroup.Text>
					</InputGroup>
				</Form.Group>
				<Form.Group className="priceForm-group withImgbox">
					{/* <Form.Label bsClass=""><span className="rStar"></span>{tr['Icon']}</Form.Label> */}
					<Form.Label bsClass="">{tr['Icon']}(upload only .jpeg,.jpg,.png)</Form.Label>

					<InputGroup>
					<Field className="form-control" component={ field_file } type="file" name="offering_icon"/>
					<InputGroup.Text><Image style={{width:'100%'}} src={this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ICON?this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ICON:"./views/images/service-icons/service-dummy.png"} /></InputGroup.Text>
					</InputGroup>
				</Form.Group>
				
				<ButtonToolbar className="margin-t-10">
					<Button className='rgSidrkBtn' bsSize="xsmall" bsStyle="primary" onClick={handleSubmit(this.fileUpload)}>{tr['Upload']}</Button>
				</ButtonToolbar>
				</form>
			</div>
		);
	}
}

ServiceImages=reduxForm({
	form:'fileUploadForm',
	validate,
	enableReinitialize : true
})(ServiceImages)

const mapStateToProps = ({ spcmReducer })=>{
	return {
		spcmReducer,
		initialValues:spcmReducer.editService
	}
}

export default connect(mapStateToProps,{ uploadPicture,getAllServicesList,getServiceDetailsDataList })(ServiceImages);
