
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export function statusBasedHamburgerOptions(state = initialState.statusBasedHamburger, action){
  switch (action.type) {
    case 'STATUS_BASED_HAMBURGER_OPTIONS':
      return action.payload;
    default:
    return state;
  }
}

export function loadHamburgerOptions(state = false, action){
  switch (action.type) {
    case 'LOAD_HAMBURGER_OPTIONS':
      return action.payload;
    default:
    return state;
  }
}

export function loadHamburgerOptionsByID(state = false, action){
  switch (action.type) {
    case 'LOAD_HAMBURGER_OPTIONS_BY_ID':
      return action.payload;
    default:
    return state;
  }
}

export const slaViewListRed = (state = [], action) => {
  if (action.type === 'SLA_VIEW_LIST') {
    return action.payload;
  } else {
    return state;
  }
};

export const appointmentCount = (state=0, action) =>{
  if(action.type==='APPOINTMENT_COUNT'){
    return action.count;
  }else{
    return state;
  }
}
export const actionCount = (state=0, action) =>{
  if(action.type==='ACTION_COUNT'){
    return action.count;
  }else{
    return state;
  }
}