
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef } from "react";
import { Row, Col, Accordion, Tab, Tabs, ListGroup, Button } from 'react-bootstrap';
import {useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";
import { MyContext } from '_MyContext';
import { Link } from "react-router-dom";

const CloseBtnRightPanel = (props) => {
    const tr = useSelector((state) => state.spcmReducer.tr);
    const context = useContext(MyContext);
    const closeButton = useRef(null);

    useEffect(() => {
        context.keyDownHandler(closeButton.current);
      }, []);

    return (
        <div className="rPageHeadActBtn d-md-none" style={{ "marginTop": "-3px" }}>
            <ListGroup bsPrefix=" " as="ul">
                <ListGroup.Item as="li" bsPrefix=" ">
                    <Link to="javascript:void(0)" id="closeButton"
                        className="closerightPanelBtn"
                        title={tr["Minimize the details panel"]}
                        onClick={() => { props.showRightSide(false); }}
                        ref={closeButton}
                        >
                        <IoClose/>
                    </Link>
                </ListGroup.Item>
            </ListGroup>
        </div>
    )
}

export default CloseBtnRightPanel;