
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { Component } from "react";
import { Field, reduxForm, change, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Image,
  Alert,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import dummyimage from "_Images/service-icons/service-dummy.png";

import { getEditCompanyPageDetails, getMenuListCompanyAction } from "../../../../../actions/foundation/companyAction";
import { navigationHooks } from "../../../../../helpers/NavigationHook";
import ListLoader from "../../../../common/loaders/ListLoader";
import { _inputField, _textArea, field_file, TypeaheadandDropdown } from '../../../../common/formFields';

class CompanyEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyList: [],
      selectedValueList: [],
    };
    this.onCrossClick = this.onCrossClick.bind(this);
    this.validateEditCompanyField = this.validateEditCompanyField.bind(this);
    this.onValueSelection = this.onValueSelection.bind(this);
    this.onBlurCompanyType = this.onBlurCompanyType.bind(this);
    this.location = navigationHooks.params;
  }


onBlurCompanyType () {
  let error = '';
  if (this.props.companyType.length === 0) {
      error = 'error';
  }
  this.props.companyTypeErrFun(error);
}

  componentWillReceiveProps(nextProps) {
    console.log("PropsData1", nextProps.companyType);
    // this.props.companyData(nextProps?.companyType)
    if (nextProps.initialValues?.companyType !== this.props.initialValues?.companyType) {
      this.props.companyData(nextProps.initialValues)
    }
    if (nextProps.initialValues?.id !== this.props.initialValues?.id) {
      if (nextProps.initialValues) {
        console.log("PropsData2", nextProps.initialValues);
        if ((Object.keys(nextProps.initialValues)?.length > 0) && (this.props.menuListFDNReducer.length > 0)) {
          this.props.dispatch(change("CompanyEditForm", "name", nextProps.initialValues.name));
          this.props.dispatch(change("CompanyEditForm", "company_code", nextProps.initialValues.company_code));
          this.props.dispatch(change("CompanyEditForm", "companyType", nextProps.initialValues.companyType));
          let selected = this.props.menuListFDNReducer.filter((option) => nextProps.initialValues.companyType.includes(option.label))
          console.log("CompanyType", selected);
          //this.setState({ selectedValueList: selected});
          this.onValueSelection(selected);
          this.props.dispatch(change("CompanyEditForm", "aliasName", nextProps.initialValues.aliasName));
          this.props.dispatch(change("CompanyEditForm", "multilingual_flag", nextProps.initialValues.multilingual_flag));
          this.props.dispatch(change("CompanyEditForm", "notification_flag", nextProps.initialValues.notification_flag));
          this.props.dispatch(change("CompanyEditForm", "sms_notification_flag", nextProps.initialValues.knowledge_flag));
          this.props.dispatch(change("CompanyEditForm", "lucyUrl", nextProps.initialValues.lucyUrl));
          this.props.dispatch(change("CompanyEditForm", "status", nextProps.initialValues.status));
          this.props.dispatch(change("CompanyEditForm", "file", nextProps.initialValues.xsmLogoTempURL));
          this.props.companyData(nextProps.initialValues)
        }
      }
    }
  }

  componentDidMount() {
      this.props.dispatch(getEditCompanyPageDetails(this.location.id));
      this.props.dispatch(getMenuListCompanyAction(''));
  }
  onCrossClick = () => {
    this.setState({ selectedValueList: [] });
    this.props.dispatch(change("CompanyEditForm", "companyType", []));
    this.props.companyTypeFun([]);
    this.props.companyTypeErrFun('error');
  };

  onValueSelection = (selected) => {
    try{
      let error = "";
      if (selected.length === 0) {
        error = "error";
        this.props.companyTypeErrFun("error");
      }
      let arr = [];
      if (selected.length > 0) {
        selected.map((val) => {
          if (typeof val === 'string') {
            arr.push(val);
          } else if (typeof val === 'object') {
            arr.push(val.label);
          }
      });
      
        // this.props.dispatch(change("CompanyEditForm", "companyType", arr));
        // this.setState({ selectedValueList: selected });
        // this.props.companyTypeFun(arr);
        // this.props.companyTypeErrFun("error");
        // this.setState({ selectedValueList: arr });
      }
      this.props.dispatch(change("CompanyEditForm", "companyType", arr));
      this.setState({ selectedValueList: selected });
      this.props.companyTypeFun(arr);
    }catch(e){
      console.error(e);
    }
  };

  validateEditCompanyField = (fieldType, event) => {
    switch (fieldType) {
      case "name":
        if (event.target.value == "") {
          this.props.setNameErr("error");
        } else {
          this.props.setNameErr("");
        }
        break;
      case "description":
        if (event.target.value == "") {
          this.props.setDescriptionErr("error");
        } else {
          this.props.setDescriptionErr("");
        }
        break;
      default:
    }
  };
  render() {
    let dummyLogo;
    if(this.props.initialValues != undefined) {
      dummyLogo = this.props.initialValues.xsmLogoTempURL
    }else{
      dummyLogo = "./views/images/service-icons/service-dummy.png"
    }
    // console.log("SelectedValue")

    return (
      <>
        {this.props.loader.isLoading ? (
          <ListLoader/>
        ) : (
          <Form>
            <Row>
              <Col md={6} sm={6} xs={12}>
                  <Form.Group className="form-group">
                  <span className="rStar"></span>
                  <Form.Label>
                    {this.props.tr["Name"]}
                  </Form.Label>
                  <Field
                    name="name"
                    className={"form-control " + (this.props.nameErr)}
                    component={_inputField}
                    disabled={true}
                    onBlur={(event) => {
                      this.validateEditCompanyField("name", event);
                    }}
                  />
                </Form.Group>
              </Col>

              <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label>{this.props.tr["Company Code"]}</Form.Label>
                  <Field
                    name="company_code"
                    className={"form-control"}
                    component={_inputField}
                    disabled
                  />
                </Form.Group>
              </Col>

              <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label>{this.props.tr["Alias"]}</Form.Label>
                  <Field
                    name="aliasName"
                    className="form-control"
                    component={_inputField}
                  />
                </Form.Group>
              </Col>

              <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label>{this.props.tr["Status"]}</Form.Label>
                  <Field name="status" component="select" className="form-control">
                    <option value="1">{this.props.tr["Active"]}</option>
                    <option value="0">{this.props.tr["Inactive"]}</option>
                  </Field>
                </Form.Group>
              </Col>
              <Col md={6} sm={6} xs={12}>
                  <Form.Group className="form-group">
                  <span className="rStar"></span>
                  <Form.Label>
                    {this.props.tr["Company Type"]}
                  </Form.Label>
                  <Field
                    component={TypeaheadandDropdown}
                    onSelection={this.onValueSelection}
                    name="companyType"
                    className="form-control"
                    options={this.props.menuListFDNReducer}
                    selectedOptions={this.state.selectedValueList}
                    onCrossClick={this.onCrossClick}
                    setErrorColor={this.onBlurCompanyType}
                    errorClass={this.props.companyTypeErr}
                    multiple={true}
                  />
                </Form.Group>
              </Col>

              <Col md={6} sm={6} xs={12}>
                  <Form.Group className="form-group">
                  <span className="rStar"></span>
                  <Form.Label>
                    {this.props.tr["Description"]}
                  </Form.Label>
                  <Field
                    name="description"
                    className={"form-control " + this.props.descriptionErr}
                    component={_textArea}
                    rows={2}
                    placeholder="Specify an overview about the company’s business"
                    onBlur={(event) => {
                      this.validateEditCompanyField("description", event);
                    }}
                    maxLength={500}
                  />
                </Form.Group>
              </Col>

              <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label>{this.props.tr["Notifications"]}</Form.Label>
                  <Field
                    name="notification_flag"
                    component="select"
                    className="form-control"
                  >
                    <option value="Y">{this.props.tr["Enabled"]}</option>
                    <option value="N">{this.props.tr["Disabled"]}</option>
                  </Field>
                </Form.Group>
              </Col>

              <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label>{this.props.tr["SMS Notification"]}</Form.Label>
                  <Field
                    name="sms_notification_flag"
                    component="select"
                    className="form-control"
                  >
                    <option value="N">{this.props.tr["Disabled"]}</option>
                    <option value="Y">{this.props.tr["Enabled"]}</option>
                  </Field>
                </Form.Group>
              </Col>

              <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label>{this.props.tr["Multilingual Support"]}</Form.Label>
                  <Field
                    name="multilingual_flag"
                    component="select"
                    className="form-control"
                  >
                    <option value="N">{this.props.tr["Disabled"]}</option>
                    <option value="Y">{this.props.tr["Enabled"]}</option>
                  </Field>
                </Form.Group>
              </Col>
              <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label>{this.props.tr["Lucy Url"]}</Form.Label>
                  <Field
                    name="lucyUrl"
                    className="form-control"
                    component={_inputField}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={6} xs={12}>
                {/* <Form.Group className="form-group priceForm-group withImgbox">
              <Form.Label>
                {this.props.tr["Logo"]}
                <b className="margin-l-10 f-size-11">
                  (upload only .jpeg,.jpg,.png,.giff,.gif)
                </b>
              </Form.Label>
              <InputGroup>
                <div>
                  <Form.Control type="file" />
                </div>
                <InputGroup.Text>
                  <Image src={dummyimage} />
                </InputGroup.Text>
              </InputGroup>
            </Form.Group> */}
                <Form.Group className="priceForm-group withImgbox margin-b-15">
                  <Form.Label>
                    {this.props.tr["Logo"]}
                    <b className="margin-l-10 f-size-11">
                      (upload only .jpeg,.jpg,.png,.giff,.gif)
                    </b>
                  </Form.Label>
                  <InputGroup>
                    <Field className='form-control' component={field_file} type="file" name="file" />
                    <InputGroup.Text><Image style={{ width: '100%' }} src={dummyLogo} /></InputGroup.Text>
                  </InputGroup>
					
                </Form.Group>
              </Col>
            </Row>
          </Form>
        )}
      </>
    );
  }
}

let selector = formValueSelector("CompanyEditForm");
CompanyEditForm = connect((state) => {
  let companyTypeSelector = selector(state, "companyType");
  return { companyTypeSelector };
})(CompanyEditForm);

function mapStateToProps({ editCompanyFdnList, menuListFDNReducer }) {
  return {
    editCompanyFdnList,
      initialValues: editCompanyFdnList.company,
      menuListFDNReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getEditCompanyPageDetails,
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "CompanyEditForm",
    destroyOnUnmount: true,
    enableReinitialize: true,
  })(CompanyEditForm)
);
