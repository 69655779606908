
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useRef, useState } from "react";
import { ListGroup, Form, ButtonToolbar, Button, Modal } from "react-bootstrap";
import { TypeaheadExampleSingleSelect } from "../../formFields";
import { Field, change, reduxForm } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanyList,
  getOfferingsBasedOnUser,
  resetCompanyUsersList,
  resetEntitledOfferingsList,
} from "../../../../actions/spcmActions";
import RequesterTypeAhead from "./FormFields/RequesterTypeAhead";
import { useNavigate } from "react-router";
import Cookies from "universal-cookie";
import { GLOBAL } from "../../../Globals";

const cookies = new Cookies();
let cookiesData = cookies.get("gph");
if (cookiesData) cookiesData = cookiesData.replace("s:", "");
if (cookiesData)
  cookiesData = cookiesData.substring(0, cookiesData.lastIndexOf("."));
cookiesData = cookiesData.split("~");
let loggedInUserCompany = cookiesData[48];

const OnBehalfSection = (props) => {
  const [companyList, setCompanyList] = useState([]);
  const [selectedCompanyList, setSelectedCompanyList] = useState([]);
  const [selectedOfferingList, setSelectedOfferingList] = useState([]);
  const [typedCompany, setTypedCompany] = useState([]);
  const [typedOffering, setTypedOffering] = useState([]);
  const [companyId, setCompanyId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [offeringError, setOfferingError] = useState("");
  const [selectedRequestor, setSelectedRequestor] = useState({});
  const [requestorError, setRequestorError] = useState(false);
  const [offeringUrl, setOfferingUrl] = useState("");

  const menuRef = useRef(null);
  const typeaheadRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const companyReducer = useSelector((state) => state.spcmReducer.companyList);
  const offerings = useSelector(
    (state) => state.spcmReducer.entitledOnBehalfOfferings
  );

  useEffect(() => {
    if (companyReducer && companyReducer.length > 0) {
      setCompanyList(
        companyReducer.filter((data) => data.field1Value != loggedInUserCompany)
      );
    }
  }, [companyReducer]);

  const companyInputChange = (e) => {
    setTypedCompany(e);
    setSelectedCompanyList([]);
    setCompany([]);
  };

  const offeringInputChange = (e) => {
    setTypedOffering(e);
    setSelectedOfferingList([]);
    setOffering([]);
  };

  const setCompany = (selectedCompany) => {
    if (selectedCompany.length > 0) {
      setCompanyId(selectedCompany[0].field1Value);
      setCompanyName(selectedCompany[0].field1Key);
      setSelectedCompanyList(selectedCompany);
    } else {
      setCompanyId("");
      setSelectedCompanyList([]);
    }
  };

  const setOffering = (selectedOffering) => {
    if (selectedOffering.length > 0) {
      setOfferingUrl(
        // `orderNowOnBehalf/offeringDetails/${selectedOffering[0].offeringId}/${selectedOffering[0].category}/${selectedOffering[0].subCategory}`
        `orderNowOnBehalf/offeringDetails/${encodeURIComponent(selectedOffering[0].offeringId)}/${encodeURIComponent(selectedOffering[0].category)}/${encodeURIComponent(selectedOffering[0].subCategory)}`
      );
      setSelectedOfferingList(selectedOffering);
    } else {
      setOfferingUrl("");
      setSelectedOfferingList([]);
    }
  };

  const onCrossClickCompany = () => {
    companyInputChange("");
    setCompanyId("");
    setCompanyName("");
    setSelectedCompanyList([]);
    setSelectedRequestor({});
    onCrossClickOffering();
  };

  const onCrossClickOffering = () => {
    offeringInputChange("");
    setOfferingUrl("");
    setSelectedOfferingList([]);
  };

  const onConsumerSelected = (suggestion) => {
    setSelectedRequestor(suggestion);
  };

  const setCompanyErrorColor = () => {
    if (selectedCompanyList.length === 1) {
      setCompanyError("");
    } else {
      setCompanyError("error");
    }
  };

  const setErrorColor = () => {
    if (Object.keys(selectedRequestor).length > 0) {
      setRequestorError("");
    } else {
      setRequestorError("error");
    }
  };
  const setOfferingErrorColor = () => {
    if (selectedOfferingList.length === 1) {
      setOfferingError("");
    } else {
      setOfferingError("error");
    }
  };

  useEffect(() => {
    if (companyReducer.length === 0) {
      dispatch(getCompanyList(""));
    }

    return () => {
      dispatch(resetEntitledOfferingsList());
      dispatch(resetCompanyUsersList());
    };
  }, []);

  useEffect(() => {
    if (Object.keys(selectedRequestor).length !== 0 && companyId !== "") {
      dispatch(getOfferingsBasedOnUser(companyId, selectedRequestor));
    }
  }, [selectedRequestor, companyId]);

  const handleSubmit = () => {
    if (selectedCompanyList.length === 0) {
      setCompanyError("error");
    }
    if (Object.keys(selectedRequestor).length === 0) {
      setRequestorError("error");
    }
    if (selectedOfferingList.length === 0) {
      setOfferingError("error");
    }
    if (
      selectedCompanyList.length === 1 &&
      Object.keys(selectedRequestor).length > 0 &&
      companyId.length > 0 &&
      selectedOfferingList.length > 0
    ) {
      props.showOnbehalf(false);
      navigate(offeringUrl, {
        state: {
          requestType: "Onbehalf",
          requestorDetails: selectedRequestor,
          requestorCompany: companyId,
        },
      });
      GLOBAL.onBehalfUserCompanyId = companyId;
      GLOBAL.onBehalfUserCompanyName = companyName;
      GLOBAL.onBehalfUserDetails = selectedRequestor;
    }
  };

  return (
    <Modal
      size="sm"
      show={props.showModal}
      onHide={() => props.showOnbehalf(false)}
      aria-labelledby="example-modal-sizes-title-sm"
      className="onBehalfModal"
    >
      <Modal.Body>
        <div>
          <ListGroup bsPrefix=" " as="ul" className="onbehalfpop">
            <ListGroup.Item bsPrefix=" " as="li">
              <div className="hrd">{props.tr["On Behalf of"]}</div>
            </ListGroup.Item>
            <ListGroup.Item bsPrefix=" " as="li">
              <Form>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">
                    <span className="rStar" />
                    {props.tr["Company"]}
                  </Form.Label>
                  <Field
                    id="CompanyField"
                    ref={typeaheadRef}
                    name="company"
                    component={TypeaheadExampleSingleSelect}
                    className="form-control"
                    options={companyList}
                    selectedOptions={selectedCompanyList}
                    onSelection={setCompany}
                    errorClass={companyError}
                    diableStatus={false}
                    onInputChange={companyInputChange}
                    onCrossClick={onCrossClickCompany}
                    typedValue={typedCompany}
                    setErrorColor={setCompanyErrorColor}
                    labelKey={"field1Key"}
                  />
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">
                    <span className="rStar" />
                    {props.tr["Requestor"]}
                  </Form.Label>
                  <div className="position-re">
                    <Field
                      name="requestor"
                      component={RequesterTypeAhead}
                      validateField={setErrorColor}
                      companyId={companyId || ""}
                      companyName={companyName || ""}
                      onConsumerSelected={onConsumerSelected}
                      setSelectedOption={setSelectedRequestor}
                      errorColor={requestorError}
                      resetSelectedOffering={onCrossClickOffering}
                      placeholder= {props.tr["Enter username, name, email or emp_no"]}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>
                    <span className="rStar" />
                    {props.tr["Service"]}
                  </Form.Label>
                  <Field
                    id="OfferingField"
                    ref={typeaheadRef}
                    name="offeringName"
                    component={TypeaheadExampleSingleSelect}
                    className="form-control"
                    options={offerings.data || []}
                    selectedOptions={selectedOfferingList}
                    onSelection={setOffering}
                    errorClass={offeringError}
                    diableStatus={false}
                    onInputChange={offeringInputChange}
                    onCrossClick={onCrossClickOffering}
                    typedValue={typedOffering}
                    setErrorColor={setOfferingErrorColor}
                    labelKey={"offeringName"}
                  />
                </Form.Group>
                <ButtonToolbar
                  aria-label="onbehalf button groups"
                  className="toolbarBtnStyle margin-t-15 margin-b-5"
                >
                  <Button
                    size="sm"
                    className="rgSidrkBtn"
                    onClick={() => handleSubmit()}
                    title={props.tr["Proceed"]}
                  >
                    {props.tr["Proceed"]}
                  </Button>
                  <Button
                    className="rgSiLigBtn"
                    size="sm"
                    title={props.tr["Close"]}
                    onClick={() => {
                      props.showOnbehalf(false);
                    }}
                  >
                    {props.tr["Close"]}
                  </Button>
                </ButtonToolbar>
              </Form>
            </ListGroup.Item>
          </ListGroup>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default reduxForm({
  form: "onBehalfPopupForm",
  enableReinitialize: true,
})(OnBehalfSection);
