
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import moment from 'moment';
import { Row, Col, Alert, Radio } from 'react-bootstrap';
import OnClickOutside from 'react-onclickoutside';
import { DateField, DatePicker, TransitionView, Calendar } from 'react-date-picker';
import { loadCICompany } from '_Actions/cmdb/lovAction';
import { getCompanyList } from '_Actions/spcmActions';
//loadSlaWrkSchedule,loadSlaHolSchedule,loadSlaDefEditDetails
import { connect } from 'react-redux';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import { bindActionCreators } from 'redux';
import { loadCategoryDropdown, CategoriesByCompanyId, loadStatusDropdown } from '_Actions/categoryBoard/quickViewAction.js';
import { businessFunctionByCompanyId, resetBusinessFunctionList } from '../../../../actions/spcmActions';
import { _inputField, field_file, _dropDown, _checkbox, itemQuantityField } from '../../../common/ReduxFormFields/CategoryBoard/formFields';


import { Field, reduxForm, formValueSelector, change, SubmissionError } from 'redux-form';
import { TypeaheadExampleSingleSelect } from '../../../common/formFields';
// import Select from 'react-select';
// import GLOBAL from '../../../components/Globals';

const validate = (values, props) => {
  console.log("validate", values);
  console.log("sequenceId", typeof (values.category_sequence));
  const errors = {};
  if (!values.COMPANY_ID)
    errors.COMPANY_ID = "Select Company";

  if (values.CATEGORY_NAME) {
    if (values.CATEGORY_NAME.trim() == "") {
      errors.CATEGORY_NAME = 'Enter CategoryName';
    }
  }
  else if (!values.CATEGORY_NAME)
    errors.CATEGORY_NAME = "Required";

  if (values.SUB_CATEGORY_NAME) {
    if (values.SUB_CATEGORY_NAME.trim() == "") {
      errors.SUB_CATEGORY_NAME = 'Enter SubCategoryName';
    }
  }
  else if (!values.SUB_CATEGORY_NAME)
    errors.SUB_CATEGORY_NAME = "Required";

  if (!values.CATEGORY_ID)
    errors.CATEGORY_ID = "Required";

    if(values.category_sequence == undefined){
      errors.category_sequence = '';
    } else if ((values.category_sequence && values.category_sequence.includes(".")) || values.category_sequence == "" || !(parseInt((values.category_sequence),10) >= 1 && parseInt((values.category_sequence),10) <= 500)) {
    errors.category_sequence = "Please enter values between 1 and 500";
  }
  console.log(errors);
  return errors;
}

class xsmCategoryCreateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createUsing: 'existing',
      minItemQuantity: "1",
      category_pinned: "false",
      businessFnList: [],
      typedBusinessFn: ""
    };
    this.onCreateUsingSelect = this.onCreateUsingSelect.bind(this);
    this.renderCategory = this.renderCategory.bind(this);
    this.countCharacters = this.countCharacters.bind(this);
    this.onCompanyChange = this.onCompanyChange.bind(this);
    this.onCrossClickBusinessFn = this.onCrossClickBusinessFn.bind(this);
    this.businessFnInputChange = this.businessFnInputChange.bind(this);
    this.setBusinessFn = this.setBusinessFn.bind(this);
  }

  componentWillMount() {
    //this.props.loadCategoryDropdown();
    this.props.CategoriesByCompanyId(0);
    this.props.getCompanyList("");
    this.props.loadStatusDropdown();

  }

  componentDidMount() {
    this.props.dispatch(change("xsmCategoryCreateForm", "STATUS_NAME", 'Active'));
  }

  componentWillUnmount() {
    this.props.resetBusinessFunctionList();
  }

  onCompanyChange(e) {
    this.props.businessFunctionByCompanyId(e.target.value);
    this.props.CategoriesByCompanyId(e.target.value);
    this.props.dispatch(change("xsmCategoryCreateForm", "CATEGORY_NAME", ''));
    this.props.dispatch(change("xsmCategoryCreateForm", "SUB_CATEGORY_NAME", ''));
    this.props.dispatch({ type: 'CATEGORIES_BY_COMPANY_ID_REFRESH' });
    this.props.setSelectedBusinessFn([]);
    this.props.dispatch(change("EditCategoryForm", "BUSINESS_FUNCTION_ID", ''));
    this.props.dispatch(change("EditCategoryForm", "BUSINESS_FUNCTION_NAME", ''));
  }

  businessFnInputChange(value) {
    this.setState({ typedBusinessFn: value });
    this.props.setSelectedBusinessFn([]);
    this.setBusinessFn([]);
  }

  setBusinessFn(selectedBusinessFn) {
    if(selectedBusinessFn.length > 0) {
      this.props.setSelectedBusinessFn(selectedBusinessFn);
      this.props.dispatch(change("xsmCategoryCreateForm", "BUSINESS_FUNCTION_ID", selectedBusinessFn[0].id));
      this.props.dispatch(change("xsmCategoryCreateForm", "BUSINESS_FUNCTION_NAME", selectedBusinessFn[0].businessFunctionName));
    } else {
      this.props.setSelectedBusinessFn([]);
      this.props.dispatch(change("xsmCategoryCreateForm", "BUSINESS_FUNCTION_ID", ''));
      this.props.dispatch(change("xsmCategoryCreateForm", "BUSINESS_FUNCTION_NAME", ''));
    }
  }

  onCrossClickBusinessFn() {
    this.setState({ typedBusinessFn: '' });
    this.setBusinessFn([]);
    this.props.dispatch(change("xsmCategoryCreateForm", "BUSINESS_FUNCTION_ID", ''));
    this.props.dispatch(change("xsmCategoryCreateForm", "BUSINESS_FUNCTION_NAME", ''));
  }

  onCreateUsingSelect(event) {
    if (event.target.value === 'new') {
      this.props.dispatch(change("xsmCategoryCreateForm", "CATEGORY_ID", ''));
      this.props.dispatch(change("xsmCategoryCreateForm", "CATEGORY_NAME", ''));
      this.props.dispatch(change("xsmCategoryCreateForm", "SUB_CATEGORY_NAME", ''));

    }
    else {
      this.props.dispatch(change("xsmCategoryCreateForm", "CATEGORY_NAME", ''));
      this.props.dispatch(change("xsmCategoryCreateForm", "SUB_CATEGORY_NAME", ''));


    }
    this.setState({ createUsing: event.target.value });
  }

  renderCategory({ input, className, disabledValue, meta }) {
    //let categoryDataOptions = this.props.categoryDropdown;
    let categoryDataOptions = this.props.CategoriesByCompanyIdDropdown;
    //console.log(classData)
    if ((categoryDataOptions.length == 0 || categoryDataOptions.length == undefined)) {
      return (
        <div>
          <select {...input} className={(meta.touched && meta.error) ? className + " error" : className} disabled={disabledValue}>
            <option value="">Select</option>
          </select>
        </div>
      );
    }
    return (
      <div>
        <select {...input} className={(meta.touched && meta.error) ? className + " error" : className} disabled={disabledValue}>
          <option value="">Select</option>
          {this.renderDropDownOptions(categoryDataOptions)}
        </select>
      </div>
    )
  }

  renderDropDownOptions(options) {
    return options.map((option, index) => <option value={option.CATEGORY_ID} key={index}>{option.CATEGORY_NAME}</option>);
  }

  renderStatus(statusData) {
    //console.log(classData)
    if (statusData.length == 0 || statusData.length == undefined) {
      return (
        null
      );
    }
    return statusData.map((objectData) => {
      // if (objectData.field1Key != "Active") {
      // return (
      return <option value={objectData.STATUS_ID}>{objectData.STATUS_NAME}</option>
      //);
      //}

    });
  }

  countCharacters(e) {
    if (e.target.value.length > 100) {
      e.preventDefault();
    }
  }

  render() {

    let { error, handleSubmit, pristine, reset, submitting } = this.props;
    console.log("this.props.spcmReducer.companyList.option", this.props.spcmReducer.companyList.field1Value);
    console.log('Reducer Business Function', this.props.parentFunctionByCompanyIdDropdown);
    console.log('Selected Business Function', this.state.selectedBusinessFn);
    // console.log('this.props.categoryDropdown', this.props.categoryDropdown);
    // console.log('this.props.statusDropdown', this.props.statusDropdown);

    return (
      <div>
        {error == undefined || error == null || error == "" ? null : <Alert variant="danger" className="errorMessageSec"><p>{error}</p></Alert>}
        <div className="borderForm bFormDiv">
          <form>
            <Row>
              <Col md={6}>
                <Form.Group className='form-group'>
                  <Form.Label bsClass="">
                    <span className="rStar"></span>{" "}
                    {this.props.translator["Company"]}
                  </Form.Label>
                  <Field
                    component={_dropDown}
                    name="COMPANY_ID"
                    onChange={this.onCompanyChange}
                    options={
                      this.props.spcmReducer.companyList
                        ? this.props.spcmReducer.companyList
                        : []
                    }
                    className="form-control"
                    disabled={
                      this.props.spcmReducer.editService.status &&
                      this.props.spcmReducer.editService.status == "Deployed"
                        ? true
                        : false
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className='form-group'>
                  <Form.Label bsClass="">
                    <span className="rStar"></span>
                    {this.props.translator["Business Function"]}
                  </Form.Label>
                  <div className='dvTypehd'>
                  <Field
                    id="BusinessFnField"
                    name="BUSINESS_FUNCTION"
                    component={TypeaheadExampleSingleSelect}
                    className="form-control"
                    options={this.props.parentFunctionByCompanyIdDropdown}
                    selectedOptions={this.props.selectedBusinessFn}
                    onSelection={this.setBusinessFn}
                    errorClass={this.props.businessFnError}
                    diableStatus={false}
                    onInputChange={this.businessFnInputChange}
                    onCrossClick={this.onCrossClickBusinessFn}
                    typedValue={this.state.typedBusinessFn}
                    setErrorColor={this.props.setBusinessFnErrorColor}
                    labelKey={"businessFunctionName"}
                  />
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className='form-group radioDiv'
                  onChange={this.onCreateUsingSelect.bind(this)}
                >
                  <Form.Label bsClass="">
                    {this.props.translator["Create Using"]}
                  </Form.Label>
                  <div>
                    <Form.Check
                      inline
                      type="radio"
                      value="new"
                      name="createUsing"
                      label={this.props.translator["New Category"]}
                      checked={this.state.createUsing === "new" ? true : false}
                    ></Form.Check>
                    <Form.Check
                      type="radio"
                      inline
                      value="existing"
                      name="createUsing"
                      label={this.props.translator["Existing Category"]}
                      checked={
                        this.state.createUsing === "existing" ? true : false
                      }
                    ></Form.Check>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {this.state.createUsing === "new" ? (
                <Col md={6}>
                  <Form.Group className='form-group'>
                    <Form.Label bsClass="">
                      <span className="rStar"></span>
                      {this.props.translator["Category"]}
                    </Form.Label>
                    <Field
                      name="CATEGORY_NAME"
                      className="form-control"
                      component={_inputField}
                      onpaste={(e) => {
                        this.countCharacters(e);
                      }}
                      onChange={(e) => {
                        this.countCharacters(e);
                      }}
                    ></Field>
                    <span className="f-size-10">
                      {this.props.translator["Max Length in CB"]}
                    </span>
                  </Form.Group>
                </Col>
              ) : (
                <Col md={6}>
                  <Form.Group className='form-group'>
                    <Form.Label bsClass="">
                      <span className="rStar"></span>
                      {this.props.translator["Category"]}
                    </Form.Label>
                    <Field
                      name="CATEGORY_ID"
                      className="form-control"
                      component={this.renderCategory}
                      onChange={(event) => {
                        this.props.dispatch(
                          change(
                            "xsmCategoryCreateForm",
                            "CATEGORY_NAME",
                            event.target[event.target.selectedIndex].text
                          )
                        );
                      }}
                    ></Field>
                  </Form.Group>
                </Col>
              )}
              <Col md={6}>
                <Form.Group className='form-group'>
                  <Form.Label bsClass="">
                    <span className="rStar"></span>
                    {this.props.translator["Sub Category"]}
                  </Form.Label>
                  <Field
                    name="SUB_CATEGORY_NAME"
                    className="form-control"
                    component={_inputField}
                    onpaste={(e) => {
                      this.countCharacters(e);
                    }}
                    onChange={(e) => {
                      this.countCharacters(e);
                    }}
                  ></Field>
                  <span className="f-size-10">
                    {this.props.translator["Max Length in CB"]}
                  </span>
                </Form.Group>
              </Col>
             
            </Row>
              <Row>
                <Col md={6}>
                  <Form.Label className='margin-r-10'>{this.props.translator["Want to pin this category?"]} </Form.Label>
									<Field component={_checkbox} 
                  className='form-control' 
                  name='category_pinned' 
                  // label={this.props.translator["Want to pin this category?"]} 
                  onChange={(e) => {this.setState({category_pinned: e.target.checked.toString()});}}  
                  checked={this.state.category_pinned=="true"} />
								</Col> 
                <Col md={6}>
										<Form.Label style={{"display":"inline-block","margin-right":"15px"}} bsClass="">{this.props.translator["Category Sequence"]}</Form.Label>
                    <div className="catSeqDv"><Field component={itemQuantityField}  min={1} max={500}
                      name="category_sequence"
                      className='form-control' onChange={(e) => {
                        this.setState({ minItemQuantity: e.target.value }); }} /></div>
                </Col>
              </Row> 
             <Row>
              <Col md={6}>
                <Form.Group className='form-group'>
                  <Form.Label bsClass="">
                    <span className="rStar"></span>
                    {this.props.translator["Status"]}
                  </Form.Label>
                  <Field
                    name="STATUS_NAME"
                    className="form-control"
                    component={_inputField}
                    defaultValue="Active"
                    disabled
                  ></Field>
                </Form.Group>
              </Col>
            </Row>
            <Row>
            </Row>
          </form>
        </div>
      </div>
    );
  }
}

xsmCategoryCreateForm = reduxForm({
  form: 'xsmCategoryCreateForm',
  destroyOnUnmount: false,
  validate
})(xsmCategoryCreateForm);

export function mapStateToProps({ spcmReducer, categoryDropdown, CategoriesByCompanyIdDropdown, statusDropdown, parentFunctionByCompanyIdDropdown }) {
  return {
    categoryDropdown,
    CategoriesByCompanyIdDropdown,
    statusDropdown,
    spcmReducer,
    parentFunctionByCompanyIdDropdown
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    loadCategoryDropdown, CategoriesByCompanyId, loadStatusDropdown, getCompanyList, businessFunctionByCompanyId, resetBusinessFunctionList
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(xsmCategoryCreateForm);
//export default xsmCategoryCreateForm
