
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { ListGroup, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {PiPencilSimpleBold} from "react-icons/pi";
import {IoClose} from "react-icons/io5";
import { MyContext } from '_MyContext';
 
class CmdbShortDescriptionList extends React.Component {
	static contextType = MyContext;

	componentDidMount() {
		this.context.keyDownHandler(this.closeButton,this.editButton,"","");
		this.context.newTabPrevent();
	}
	componentDidUpdate() {
		this.context.keyDownHandler(this.closeButton,this.editButton,"","");
		this.context.newTabPrevent();
	}
	render() {
		let businessOwnerName = '';
		businessOwnerName = this.props.BUSINESS_OWNER_NAME.split("|");
		businessOwnerName = businessOwnerName[0];

		let technicalOwnerName = '';
		technicalOwnerName = this.props.TECHNICAL_OWNER_NAME.split("|");
		technicalOwnerName = technicalOwnerName[0];
		return (
			<div className="">
				<Row>
					<Col xs={12}>
						<div className="rPageHeadActBtn">
							<ul>
								<li>
									<Link to={"/editcmdb/" + this.props.CI_ID} className="editIcn ctrlKeyPrevent" id="editBtnObj"
									title={this.props.translator["Edit"]}
									ref={e => this.editButton = e}
									>
									<PiPencilSimpleBold/>
									</Link>								
								</li>
								<li>
									<Link to="javascript:void(0)" className="closeRsBtn ctrlKeyPrevent" id="closeButton"
									title={this.props.translator["Minimize the details panel"]}
									onClick={() => { this.props.showRightSide(false); }}
									ref={e => this.closeButton = e}
									>
									<IoClose/>
									</Link>
								</li>
							</ul>
						</div>
						<div className="rPageHeading">{this.props.CI_CODE}
							<div className="offNam">{this.props.CI_NAME}</div>
						</div>
					</Col>
				</Row>
				<div className="rBoxGap">
					<div className="rBoxTabs hideTabNav">
					<Tabs  id="tabId">
						<Tab eventKey={1} title="ASSET/CI DETAILS">
						
						<ListGroup bsPrefix=' ' bsClass="" >
							<ListGroup.Item bsPrefix=' ' bsClass="" className="rwTable" style={{ "border-top": "none" }}><span className="rwKey">{this.props.translator['Name']} </span><span className="rwVal">{this.props.CI_NAME}</span></ListGroup.Item>
							{this.props.SERIAL_NUMBER != '' ? <ListGroup.Item bsPrefix=' ' bsClass="" className="rwTable"><span className="rwKey">{this.props.translator['Serial Number']} </span><span className="rwVal">{(this.props.SERIAL_NUMBER=="" || this.props.SERIAL_NUMBER == null) ? "NA" : this.props.SERIAL_NUMBER}</span></ListGroup.Item> : ''}
							{this.props.COMPANY_NAME != '' ? <ListGroup.Item bsPrefix=' ' bsClass="" className="rwTable"><span className="rwKey">{this.props.translator['Company']} </span><span className="rwVal">{this.props.COMPANY_NAME}</span></ListGroup.Item> : ''}
							{this.props.CLASS_NAME != '' ? <ListGroup.Item bsPrefix=' ' bsClass="" className="rwTable"><span className="rwKey">{this.props.translator['Class']} </span><span className="rwVal">{this.props.CLASS_NAME}</span></ListGroup.Item> : ''}
							{this.props.TYPE != '' ? <ListGroup.Item bsPrefix=' ' bsClass="" className="rwTable"><span className="rwKey">{this.props.translator['Type']} </span><span className="rwVal">{this.props.TYPE == "5" ? "Asset" : this.props.TYPE == "10" ? "CI" : "Both"}</span></ListGroup.Item> : ''}
							{this.props.STATUS_NAME != '' ? <ListGroup.Item bsPrefix=' ' bsClass="" className="rwTable"><span className="rwKey">{this.props.translator['Status']} </span><span className="rwVal">{this.props.STATUS_NAME}</span></ListGroup.Item> : ''}
							{this.props.SUB_STATUS_NAME != '' ? <ListGroup.Item bsPrefix=' ' bsClass="" className="rwTable"><span className="rwKey">{this.props.translator['Sub-Status']} </span><span className="rwVal">{this.props.SUB_STATUS_NAME}</span></ListGroup.Item> : ''}
							{this.props.BUSINESS_OWNER_NAME != '' ? <ListGroup.Item bsPrefix=' ' bsClass="" className="rwTable"><span className="rwKey">{this.props.translator['Business Owner']} </span><span className="rwVal">{businessOwnerName}</span></ListGroup.Item> : ''}
							{this.props.TECHNICAL_OWNER_NAME != '' ? <ListGroup.Item bsPrefix=' ' bsClass="" className="rwTable"><span className="rwKey">{this.props.translator['Technical Owner']} </span><span className="rwVal">{technicalOwnerName}</span></ListGroup.Item> : ''}
							{this.props.UPDATED_AT != null ? <ListGroup.Item bsPrefix=' ' bsClass="" className="rwTable"><span className="rwKey">{this.props.translator['Modified On']} </span><span className="rwVal">{this.props.UPDATED_AT}</span></ListGroup.Item> : ''}
							{this.props.ASSET_TAG != '' ? <ListGroup.Item bsPrefix=' ' bsClass="" className="rwTable"><span className="rwKey">{this.props.translator['Asset Tag']} </span><span className="rwVal">{this.props.ASSET_TAG}</span></ListGroup.Item> : ''}
							{this.props.CATEGORY_NAME == null||this.props.CATEGORY_NAME=='|'?'': <ListGroup.Item bsPrefix=' ' bsClass="" className="rwTable"><span className="rwKey">{this.props.translator['Categorization']} </span><span className="rwVal">{this.props.CATEGORY_NAME} {(this.props.SUB_CATEGORY_NAME=="") ? "" : "|"+this.props.SUB_CATEGORY_NAME}</span></ListGroup.Item>}
							{this.props.METALLIC_VALUE != null ? <ListGroup.Item bsPrefix=' ' bsClass="" className="rwTable"><span className="rwKey">{this.props.translator['Business Criticality']} </span><span className="rwVal">{this.props.METALLIC_VALUE}</span></ListGroup.Item> : ''}
							{this.props.ENVIRONMENT != null ? <ListGroup.Item bsPrefix=' ' bsClass="" className="rwTable"><span className="rwKey">{this.props.translator['Environment']} </span><span className="rwVal">{this.props.ENVIRONMENT}</span></ListGroup.Item> : ''}
							{this.props.LOCATION_NAME != null ? <ListGroup.Item bsPrefix=' ' bsClass="" className="rwTable"><span className="rwKey">{this.props.translator['Location']} </span><span className="rwVal">{this.props.LOCATION_NAME}</span></ListGroup.Item> : ''}
						</ListGroup>
						</Tab>
					</Tabs>
					</div>
				</div>
				

			</div >
		);
		// }

	}
}

export function mapStateToProps({ showLoader }) {
	return { showLoader: showLoader.loading };
}
export default connect(mapStateToProps, null)(CmdbShortDescriptionList);