
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState, useRef, useContext } from "react";
import _ from "lodash";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Row, Col, Button, ListGroup, Nav } from "react-bootstrap";
import { IoSaveOutline, IoClose } from "react-icons/io5";
import { BiDotsVertical } from "react-icons/bi";
import { ImSpinner6 } from "react-icons/im";
import { BiRefresh } from "react-icons/bi";
import ForumDropdownPlusTypeahead from "../foundation/common/ForumDropdownPlusTypeahead";
import { LuDownload } from "react-icons/lu";
import ExportTo from '_Commons/WorkItemBoard/exportTo.js';

const Header = ({
  refetchQuardantsId,
  companyOptions,
  selectedCompanyId,
  setSelectedCompanyId,
  handleSubmit,
  exportDocument
}) => {
  const menuRef = useRef(null);
  const params = useParams();

  const navigate = useNavigate();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const downloadData = useSelector(state => state.downloadData);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Row className="margin-b-15">
      <Col md={6} sm={6} xs={12}>
        <h1 bsClass="" className="sPageHeading1">
          Consolidate View
        </h1>
      </Col>

      <Col md={6} sm={6} xs={12}>
        <div className="paHedFilter">
          <div className="comp-container me-2">
            <ForumDropdownPlusTypeahead
              name="company"
              id="company"
              className="form-control"
              labelKey="label"
              options={companyOptions || []}
              selectedOptions={
                selectedCompanyId.length > 0 ? selectedCompanyId : []
              }
              onSelection={(val) => {
                setSelectedCompanyId(val);
              }}
            />
          </div>
          <Nav>
            <Button
              bsPrefix=" "
              id="creTag"
              role="button"
              className="myBt fillBtn"
              title="Refresh"
              onClick={refetchQuardantsId}
            >
              <BiRefresh />
            </Button>
          </Nav>

          {/* <Button
            className="myBt fillBtn"
            bsPrefix=" "
            role="button"
            bsStyle="primary"
            title={tr["Save"]}
            type="button"
            onClick={handleSubmit}
          >
            <IoSaveOutline />
          </Button> */}
          {downloadData[selectedCompanyId?.[0]?.id] && 									
            <ExportTo exportDocument={exportDocument} title={tr['Export File']} moduleName={'scorecard'}/>
          }
        </div>
      </Col>
    </Row>
  );
};

export default Header;
