
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import XsmUserDetails from '_Commons/WorkItemBoard/xsmEditRequestedBy.js';
import { Scrollbars } from "react-custom-scrollbars";
import {ImInfo} from 'react-icons/im';

// import { ReadMore } from "react-read-more";
import { loadBreakFixAuditLogDetails } from '_Actions/breakFix/breakFixAuditLogAction';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Spinner from "react-spinkit";
import moment from 'moment';
import { GLOBAL } from '_Globals';
import ListLoader from '_Commons/loaders/ListLoader';
import Cookies from "universal-cookie";
import datetimeConvertor from "../../../helpers/ISO8601converter";
const cookies = new Cookies();

let timezone = cookies.get("gph");
if (timezone) timezone = timezone.replace("s:", "");
if (timezone) timezone = timezone.substring(0, timezone.lastIndexOf("."));
timezone = timezone.split("~");
timezone = timezone[3];

let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
//console.log("homepage::::"+homepagelocation);
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

let cookiesArray = cookies.get('gph');
if (cookiesArray) cookiesArray = cookiesArray.replace('s:', '');
if (cookiesArray) cookiesArray = cookiesArray.substring(0, cookiesArray.lastIndexOf('.'));
cookiesArray = cookiesArray.split("~");
let langDir = cookiesArray[66];

const MoreArrow = (
  <i title="More" className="moreArrow fa fa-angle-double-down"></i>
);
const LessArrow = (
  <i title="Less" className="margin-l-5 lessArrow fa fa-angle-double-up"></i>
);

class PopoverAuditLogData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserData: false,
    };

    //this.showUserDetails = this.showUserDetails.bind(this);
  }

  componentWillMount() {
    //alert("id:::::"+this.props.module)
    let module = this.props.module;
    let itemId = this.props.itemId;
    let getBreakFixItemId = "";
    let BRKItemId = "";
    let breakFixActualItemId = "";
    //	console.log("first::"+this.props.breakFixItemId);
    if (module == "Breakfix") {
      if (this.props.breakFixItemId.indexOf(":") > 0) {
        getBreakFixItemId = this.props.breakFixItemId
          ? this.props.breakFixItemId.split(":")
          : "";
        BRKItemId = getBreakFixItemId[0];
        breakFixActualItemId = getBreakFixItemId[1];
      } else breakFixActualItemId = this.props.breakFixItemId;
    }
    // console.log("breakFixActualItemId:::"+breakFixActualItemId)
    GLOBAL.breakFixId = breakFixActualItemId;
    if (module == "Breakfix") {
      this.props.loadBreakFixAuditLogDetails(
        breakFixActualItemId,
        "desc",
        "Breakfix"
      );
    }
    if (module == "Fullfilment")
      this.props.loadBreakFixAuditLogDetails(itemId, "desc", "Fullfilment");
    if (module == "Investigation")
      this.props.loadBreakFixAuditLogDetails(itemId, "desc", "Investigation");
    if (module == "ChangeManagement")
      this.props.loadBreakFixAuditLogDetails(
        itemId,
        "desc",
        "ChangeManagement"
      );
  }

  ascsort() {
    let module = this.props.module;
    let itemId = this.props.itemId;
    if (module == "Breakfix")
      this.props.loadBreakFixAuditLogDetails(
        GLOBAL.breakFixId,
        "asc",
        "Breakfix"
      );
    if (module == "Fullfilment")
      this.props.loadBreakFixAuditLogDetails(itemId, "asc", "Fullfilment");
    if (module == "Investigation")
      this.props.loadBreakFixAuditLogDetails(itemId, "asc", "Investigation");
    if (module == "ChangeManagement")
      this.props.loadBreakFixAuditLogDetails(itemId, "asc", "ChangeManagement");
  }
  descsort() {
    let module = this.props.module;
    let itemId = this.props.itemId;
    if (module == "Breakfix")
      this.props.loadBreakFixAuditLogDetails(
        GLOBAL.breakFixId,
        "desc",
        "Breakfix"
      );
    if (module == "Fullfilment")
      this.props.loadBreakFixAuditLogDetails(itemId, "desc", "Fullfilment");
    if (module == "Investigation")
      this.props.loadBreakFixAuditLogDetails(itemId, "desc", "Investigation");
    if (module == "ChangeManagement")
      this.props.loadBreakFixAuditLogDetails(
        itemId,
        "desc",
        "ChangeManagement"
      );
  }

  renderAuditLog(auditLogData) {
    console.log("auditLogData", auditLogData);

    const groupActivityLog = auditLogData.reduce((obj, index) => {
      const date = moment(index.createdOn,jwtdf +'HH:mm:ss').format(jwtdf);
      // const date = index.updatedOn.split('T')[0];
      if (!obj[date]) {
        obj[date] = [];
      }
      obj[date].push(index);
      return obj;
    }, {});

    console.log("groupActivityLog_00_11", groupActivityLog);

    // return auditLogData.map((auditObj, index) => {
    return Object.keys(groupActivityLog).map((auditObj, index) => {
      console.log("auditObj_999", auditObj);
      return (
        <div id="brkAudLogcomm" className="actCommts">
          {/* <div className='hed' key={index}>{moment(auditObj).format(jwtdf)}</div> */}
          {groupActivityLog[auditObj].map((comment, childKey) => {
            let actDateTimeFormat = jwtdf + " hh:mm A";
            let myDateObj,
              endDate,
              endDateStr,
              timeago,
              startTime,
              duration,
              hours,
              isoDate;
            isoDate = datetimeConvertor(comment.createdOn, jwtdf + " HH:mm:ss");
            myDateObj = moment(isoDate).format(actDateTimeFormat);
            endDate = moment(myDateObj, actDateTimeFormat);
            endDate = moment(myDateObj, actDateTimeFormat);
            endDateStr = endDate._i;
            startTime = moment(
              new Date().toLocaleString("en-US", { timeZone: timezone })
            );
            timeago = moment(endDateStr, actDateTimeFormat).from(startTime);
            duration = moment.duration(startTime.diff(endDate));
            hours = duration.asHours();

            const tooltip = (
                <Popover className="userInfoPopup">
                <XsmUserDetails userId={comment.createdBy} translator={this.props.translator} isHeadingVisible={false} isPopup={true} />
                </Popover>
            );

            let objName = comment.createdByName;
            objName =  objName == null ? " " : comment.createdByName;
            console.log("objName79",objName);
            // const objNameSplt = (objName?.split(' ') || objName?.split('_')) ;
            const objNameSplt = (objName?.split(' ')) ;
            console.log("objNameSplt79",objNameSplt);
            const objFirstName = objNameSplt[0];
            const objLastName = objNameSplt[2] ? objNameSplt[2] : objNameSplt[1];
            // const randomColor = Math.floor(Math.random()*16777215).toString(16);

            let auditfield = comment.auditField.replace(/_/g, " ");
            if (auditfield === "URGENCY" && Object.keys(this.props.urgencyListKeyValue).length !== 0 ) {
              auditfield = this.props.translator[auditfield];
              if(typeof this.props.urgencyListKeyValue[comment.from]!=='undefined'){
              comment.from = this.props.urgencyListKeyValue[comment.from];
              }
              if(typeof this.props.urgencyListKeyValue[comment.to]!=='undefined'){
              comment.to = this.props.urgencyListKeyValue[comment.to];
              }
            }else if(auditfield === "IMPACT" && Object.keys(this.props.impactListKeyValue).length !== 0){
              if(typeof this.props.impactListKeyValue[comment.from]!=='undefined'){
              comment.from = this.props.impactListKeyValue[comment.from];
              }
              if(typeof this.props.impactListKeyValue[comment.to]!=='undefined'){
              comment.to = this.props.impactListKeyValue[comment.to];
              }
              var fromTag = '';
              var toTag = '';
            }else if(auditfield==="TAG"){
              return;
              // if(comment.from!=='NA' && comment.from.length>0){
              //   fromTag = comment.from.map((item)=>{return (<div><p>{item.tag_name}: {item.tag_value}</p></div>);});
              // }
              // if(comment.to!=='NA' && comment.to.length>0){
              //   toTag = comment.to.map((item)=>{return (<div><p>{item.tag_name}: {item.tag_value}</p></div>);});
              // }
            }
            if (
              comment.from == "" ||
              comment.from == null ||
              comment.from == "0" ||
              comment.from == 0 ||
              comment.from == "Select" ||
              comment.from == "select" ||
              comment.from == "<<blank>>"
            )
              comment.from = "NA";
            if (
              comment.to == "" ||
              comment.to == null ||
              comment.to == "0" ||
              comment.to == 0 ||
              comment.to == "Select" ||
              comment.to == "select" ||
              comment.to == "<<blank>>"
            )
              comment.to = "NA";

            if (auditfield == "CREATE") {
              return (
                <Card className="actlogCrd" bsPrefix=" " key={childKey}>
                  {/* <OverlayTrigger trigger="click" rootClose placement="left" overlay={tooltip}>
                  <div className="circleNaIcn">
                  {objFirstName && Array.from(objFirstName[0])}
                  {objLastName && Array.from(objLastName[0])}
                  </div>
                  </OverlayTrigger> */}
                  <div className="circleTxt">
                  <div className="subhed">
                  {comment.createdByName !== "System" ? <OverlayTrigger trigger="click" rootClose placement={this.props.windowSize.width < 768 ? "auto-start" : (langDir === "rtl" ? "right-start" : "left-start")
} overlay={tooltip}><span className="circleNa" title={comment.createdByName}>{comment.createdByName} <ImInfo/></span></OverlayTrigger> : <span className="margin-r-20">{comment.createdByName}</span>}
                    <span
                      className="margin-l-5 timeao"
                      title={hours >= 24 ? "" : endDateStr}
                    >
                      {hours >= 24 ? endDateStr : timeago}
                    </span>
                  </div>
                  <div className="desFld">
                    <div className="fw-300 cTxt">Created</div>
                  </div>
                  </div>
                  {/* <div className='f-size-12' style={{"marginTop":"2px"}}>{timeago}</div> */}
                </Card>
              );
            } else {
              return (
                <Card className="actlogCrd" bsPrefix=" " key={childKey}>
                  {/* <OverlayTrigger trigger="click" rootClose placement="left" overlay={tooltip}>
                  <div className="circleNaIcn">
                  {objFirstName && Array.from(objFirstName[0])}
                  {objLastName && Array.from(objLastName[0])}
                  </div>
                  </OverlayTrigger> */}
                  <div className="circleTxt">
                  <div className="subhed">
                  {comment.createdByName !== "System" ? <OverlayTrigger trigger="click" rootClose placement={this.props.windowSize.width < 768 ? "auto-start" : (langDir === "rtl" ? "right-start" : "left-start")
} overlay={tooltip}><span className="circleNa" title={comment.createdByName}>{comment.createdByName} <ImInfo/></span></OverlayTrigger> : <span className="margin-r-20">{comment.createdByName}</span>}
                    <span
                      className="margin-l-5 timeao"
                      title={hours >= 24 ? "" : endDateStr}
                    >
                      {hours >= 24 ? endDateStr : timeago}
                    </span>
                  </div>
                  <div className="desFld">
                    <div title="Field Name" className="fenme">
                      {auditfield}
                    </div>
                    <div title="New Value" className="nwnme commn">
                      {auditfield==="TAG"?toTag:comment.to}
                    </div>
                    <div title="Old Value" className="oldnme commn">
                      {auditfield==="TAG"?fromTag:comment.from}
                    </div>
                  </div>
                  </div>
                  {/* <div className='f-size-12' style={{"marginTop":"2px"}}>{timeago}</div> */}
                </Card>
              );
            }
          })}
        </div>
      );
    });
  }

  render() {
    setTimeout(() => {
      if (this.props.breakFixAuditLogDetails.length == 0) {
        this.props.changeCompMessage(
          this.props.translator["Data not available."]
        );
      }
    }, 5000);
    if (this.props.breakFixAuditLogDetails.length == 0) {
      return this.props.message == "" ? (
        <div>
          <h2>{this.props.translator["Loading "]}...</h2>
          <Spinner spinnerName="three-bounce" />
        </div>
      ) : (
        <div>
          <h2>{this.props.message}</h2>
        </div>
      );
    }
    return (
      <div>
        <div className="f-size-18 margin-b-5">
          {this.props.translator[""]}
          {/* {this.props.showLoader ?
				<div className="floatRight">
						<Loader type="Oval" color="green" height={20} width={20} />
					</div>
					: <span></span>
				} */}
        </div>
        {this.props.showLoader ? (
          <div className="bgGray padding-5">
            <span className="float-r f-size-17">
              <a
                href="javascript:void(0)"
                className="black"
                title={this.props.translator["Ascending"]}
              >
                <i className="fa fa-angle-up"></i>
              </a>
              &nbsp;&nbsp;
              <a
                href="javascript:void(0)"
                className="black"
                title={this.props.translator["Descending"]}
              >
                <i className="fa fa-angle-down"></i>
              </a>
            </span>
          </div>
        ) : (
          <div className="margin-t-5 padding-b-5 border-b">
            <span className="float-r f-size-17">
              <a
                href="javascript:void(0)"
                onClick={this.ascsort.bind(this)}
                className="black"
                title={this.props.translator["Ascending"]}
              >
                <i className="fa fa-angle-up"></i>
              </a>
              &nbsp;&nbsp;
              <a
                href="javascript:void(0)"
                onClick={this.descsort.bind(this)}
                className="black"
                title={this.props.translator["Descending"]}
              >
                <i className="fa fa-angle-down"></i>
              </a>
            </span>
          </div>
        )}
        {this.props.showLoader ? (
          <ListLoader />
        ) : (
          this.renderAuditLog(this.props.breakFixAuditLogDetails)
        )}
      </div>
    );
  }
}

export function mapStateToProps({ breakFixAuditLogDetails, showLoader, windowSize }) {
  //  alert("breakFixEditDetails:"+breakFixAuditLogDetails);
  console.log("breakFixEditDetails:" + breakFixAuditLogDetails.from);
  return { breakFixAuditLogDetails, showLoader: showLoader.loading, windowSize };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loadBreakFixAuditLogDetails }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PopoverAuditLogData);
