
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});
//below URL used for testing purpose
//const breakFixUrl = 'https://xsm-f.mybluemix.net/api/SX_FAVOURITEOFFERINGs/';
import { GLOBAL }  from '../../components/Globals';
//let breakFixUrl =`https://xsm-f.mybluemix.net/api/breakfixitem?breakfixitemId=`;
//let breakFixUrl=`https://xsm-f.mybluemix.net/groupListSelfService?companyId=2932&group=ResolvedER`
export function loadChangeRelatedDataDetailsSuccess(changeRelatedDataDetails){
	//alert('in success')
	return {
		type: 'LOAD_CHANGERELATEDDATADETAILS_SUCCESS',
		changeRelatedDataDetails
	};
}

export function loadChangeRelatedDataDetailsFailure(errorResponse){
	return {
		type: 'LOAD_CHANGERELATEDDATADETAILS_FAILURE',
		errorResponse
	};
}
export function loadChangeRelatedDataDetails(breakFixId,description){
	//alert('action called and parameter is '+breakFixId)

	return(dispatch) =>{
		//alert("EditGroupIdAction123"+breakFixId);

				//alert("hiaaa::::");
				api.get(GLOBAL.changeRelatedDataUrl+"?itemId="+breakFixId+"&module=ChangeManagement")
					.then((changeRelatedDataDetails)=>{
						if(!changeRelatedDataDetails){//alert('not breakFix')
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							throw Error(changeRelatedDataDetails.statusText);
						}
						return changeRelatedDataDetails;
					})
					.then((changeRelatedDataDetails) =>{//alert('yes it is')
						dispatch(loadChangeRelatedDataDetailsSuccess(changeRelatedDataDetails))
					})
					.catch((err) => {
						console.log(err);
					});
					//console.log("not calling loadBreakFixEditDetails");

	};
}


export function loadrelatedWorkitems(ticketId,workmoduleName,moduleName='ChangeManagement'){
	let recordCount=-2;
	return(dispatch) =>{
		axios.get(GLOBAL.changeRelatedDataUrl+"?itemId="+ticketId+"&workModule="+workmoduleName+"&module="+moduleName).then((relatedworkItems)=>{
			if(relatedworkItems.data.length<=0)
			{
				recordCount=0;
			}
			relatedworkItems.data.map((record)=>{
				if(record.workModule=="Breakfix")
				{
					recordCount=relatedworkItems.data.length;
				}
				else
				{
					recordCount=0;
				}
			})
			dispatch({type: 'LOAD_RELATED_WORK_ITEMS_COUNT',recordCount});
		}).catch((err)=>{
			console.log(err);
			dispatch({type: 'LOAD_RELATED_WORK_ITEMS_COUNT',recordCount});
		})
	}
}
