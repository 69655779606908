
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { loadCIClass, loadCICompany, loadCICat} from '../../../actions/cmdb/lovAction';
import { Row, Col,FormGroup,ControlLabel,FormControl,Table,Button, PageHeader} from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GLOBAL from '_Globals';
import { Field, reduxForm,change } from 'redux-form';
import Form from 'react-bootstrap/Form';
import { getCICategoryListData, emptyCICategoryListDataReducer,loadCatSubCat,loadCISCat,resetResCatSubCatList,resetSubCatDropdown } from '../../../actions/categoryBoard/quickViewAction';
import ListLoader from '../../../components/common/loaders/ListLoader';
//import getCategoryBoardDetails from './CiCategoryIndex';
import TableComponent from '_Commons/ReactTable/TableComponent';
import {IoSaveOutline, IoClose} from "react-icons/io5";
import { loadClassByCompanyId, resetCiClass } from '../../../actions/ham/hamAction';

const validate = values => {
	let errors = {};
	if(!values.ciCompany || values.ciCompany === "" || values.ciCompany === null) {
		errors.ciCompany = "Select Company"
	}
	if(!values.ciClass || values.ciClass === "" || values.ciClass === null) {
		errors.ciClass = "Select Class"
	}
	return errors;
}

	const ResolutionCategoryList= (props) => {
		const dispatch = useDispatch();
		
		const [ciCompany, setCiCompany] = useState("");
		const [ciCompanyName, setCiCompanyName] = useState("");
		const [ciStatus, setCiStatus] = useState("active");
		const [ciClass, setCiClass] = useState("");
		const [ciCategoryId, setCiCategoryId] = useState("");
		const [ciClassErrorColor, setCiClassErrorColor] = useState("");
		const [ciCompanyErrorColor, setCiCompanyErrorColor] = useState("");
		const [ciStatusErrorColor, setCiStatusErrorColor] = useState("");
		const [editRowIndex, setEditRowIndex] = useState(-1);
		const [ciClassName, setCiClassName] = useState("");
		const [ciCategory, setCiCategory] = useState("");
		const [ciSubCategory, setCiSubCategory] = useState("");
		const [ciSubCategoryName, setCiSubCategoryName] = useState("");
		const [ciCategoryErrorColor, setCiCategoryErrorColor] = useState("");
		const [ciSubCategoryErrorColor, setCiSubCategoryErrorColor] = useState("");
		const ciResolutionCat = useSelector(state => state.ciResolutionCat);
		const ciCategoryListDataLoader = useSelector(state => state.ciCategoryListDataLoader.loading);
		const ciCompanyRed = useSelector(state => state.ciCompany);
		const ciClassRed = useSelector(state => state.ciClass);
		const ciCat = useSelector(state => state.ciCat);
		const ciSCat = useSelector(state => state.ciSCat);
		const compClass = useSelector((state) => state.compClass);
		
		
	
		const tableColumns = [
			{
			  name: props.tr["Resolution Category"],
			  selector: (row) => row.res_category_name,
			  sortable: true,
			},
			{
			  name: props.tr["Resolution Sub Category"],
			  selector: (row) => row.res_sub_category_name,
			  sortable: true,
			},
			{
			  name: props.tr["Status"],
			  selector: (row) => row.status1,
			  sortable:  true,
			},
		  ];
		 const filterColumns = ["res_category_name", "res_sub_category_name", "status"];

	// componentWillMount() {
	// 	props.loadCIClass();
	// 	props.loadCICompany("0");
	// }

	useEffect(() => {
		dispatch(resetCiClass());
			dispatch(loadCICompany("0"));
        return () => {
			dispatch(resetResCatSubCatList());
        }
    }, [])
	// componentWillUnmount() {
	// 	props.emptyCICategoryListDataReducer();
	// }
	const renderCompany = (companyData) => {
		if (!companyData) {
			return (
				<option></option>
			);
		}
		return companyData.map((companyObj, i) => {
			return (
				<option company = {companyObj.field1Key} value={companyObj.field1Value} key={i}>{companyObj.field1Key}</option>
			);
		});
	}
	const renderCategory = (categoryData) => {
		if (!categoryData) {
			return (
				<option></option>
			);
		}
		return categoryData.map((categoryObj, i) => {
			return (
				<option value={categoryObj.category_name} key={i}>{categoryObj.category_name}</option>
			);
		});
	}

	const renderSubCategory = (subCategoryData) => {
		if (!subCategoryData) {
			return (
				<option></option>
			);
		}
		return subCategoryData.map((subCategoryObj, i) => {
			return (
				<option value={JSON.stringify({catId: subCategoryObj.category_id, subCatName: subCategoryObj.sub_category_name})} key={i}>{subCategoryObj.sub_category_name}</option>
			);
		});
	}

	const renderClass = (classData) => {
		if (!classData) {
			return (
				<option></option>
			);
		}
		return classData.map((classObj, i) => {
			return (
				<option value={classObj.CLASS_ID} key={i}>{classObj.CLASS_NAME}</option>
			);
		});
	}
	const validateField = (fieldType, event) =>{
		console.log("EVENT=========>",event.target.value)
		switch (fieldType) {
			case 'ciClass':
				if (event.target.value == "") {
					setCiClassErrorColor('error');
				} else {
					setCiClassErrorColor('');
				}
				break;
				case 'ciCompany':
				if (event.target.value == "") {
				
					setCiCompanyErrorColor('error');
				} else {
					setCiCompanyErrorColor('');
				}
				break;
				case 'ciStatus':
				if (event.target.value == "") {
					//this.setState({ ciStatusErrorColor: 'error' });
					setCiStatusErrorColor('error');
				} else {
					//this.setState({ ciStatusErrorColor: '' });
					setCiStatusErrorColor('');
				}
				break;
                case 'ciCategory':
				if (event.target.value == "") {
					//this.setState({ ciCategoryErrorColor: 'error' });
					setCiCategoryErrorColor('error');
				} else {
					//this.setState({ ciCategoryErrorColor: '' });
					setCiCategoryErrorColor('');
				}
				break;
                case 'ciSubCategory':
				if (event.target.value == "") {
					//this.setState({ ciSubCategoryErrorColor: 'error' });
					setCiSubCategoryErrorColor('error');
				} else {
					//this.setState({ ciSubCategoryErrorColor: '' });
					setCiSubCategoryErrorColor('');
				}
				break;
			default:
		}
	}
	const handleciCompanyChange = (event) => {
		let companyName = ''
		if(event.target.value === "0") {
			companyName = "Default"
		} else if(event.target.value === "") {
			companyName = ""
		} else {
			companyName = ciCompanyRed.filter((obj) => obj.field1Value === event.target.value);
			companyName = companyName[0].field1Key;
			
		}
		dispatch(resetResCatSubCatList());
		dispatch(resetSubCatDropdown());
		let status = 'ALL';
        let hamCall = false;
		dispatch(loadClassByCompanyId(event.target.value,hamCall,status));
		setCiClass("");
		dispatch(change("CiCategoryList", "ciClass", ""));
		setCiCompany(event.target.value);
		setCiCompanyName(companyName);
		props.setIsEditing(false)
		if (ciClass !== "" && event.target.value !== '') {
		dispatch(loadCICat(ciClass,event.target.value,"resolution"));
		props.setListMetaData(ciStatus,ciClassName)
	}
		console.log("event.target.value",event.target.value)
		dispatch (resetResCatSubCatList());
		props.resetRightSide();
		props.showRightSide(false);
	};
	const handleciClassChange = (event) => {
	
		setCiClass(event.target.value);
		const ciClassName = event.target.options[event.target.selectedIndex].text;
	
		console.log("className===>",ciClassName)
		props.setIsEditing(false)
		if (ciCompany !== "" && event.target.value !== "") {
		console.log("Inside if of class",event.target.value)
		dispatch(loadCICat(event.target.value, ciCompany,"resolution"));
		props.setListMetaData(ciStatus, ciClassName);
		
	}
		dispatch(resetResCatSubCatList());
		dispatch(resetSubCatDropdown());
		props.resetRightSide();
		dispatch(resetResCatSubCatList());
		props.showRightSide(false);
	};

		const handleciStatusChange = (event) => {
		setCiStatus(event.target.value);
		console.log("Before if",ciStatus)
		props.setIsEditing(false)
		if (ciCompany !== "" && ciClass !== "" && event.target.value !== "") {
		console.log("Inside if of class")
		dispatch(loadCICat(ciClass,ciCompany,"resolution"));
		
		props.setListMetaData(event.target.value, ciClassName)}
		if (ciCompany !== "" && ciClass !== "" && event.target.value !== "" && ciCategoryId !=="") {
			dispatch(loadCatSubCat(ciCategoryId,event.target.value));

		}
		console.log("ciStatus",event.target.value)
		console.log("For ciStatus",event.target)
		props.resetRightSide();
		dispatch(resetResCatSubCatList());
		props.showRightSide(false);
	};
		
	const handleciCategory = (event) => {
		setCiCategory(event.target.value);
		dispatch(change("CiCategoryList", "ciSubCategory", ""));
		props.setIsEditing(false)
		if (ciCompany !== "" && ciClass !== "" && event.target.value !== "") {
			dispatch(loadCISCat(event.target.value, ciClass, ciCompany));
			console.log("Inside if of class")
		}
		//if(event.target.value == ""){
		dispatch(resetResCatSubCatList());
		dispatch(resetSubCatDropdown());

		//}
		props.resetRightSide();
		props.showRightSide(false);
	};
	
		const handleciSubCategory = (event) => {
			console.log("event.target.value111====",event.target.value);
		let subCategoryName, catId;
		if(event.target.value === "0") {
			subCategoryName = "Default"
		} else if(event.target.value === "") {
			catId = JSON.parse(event.target.value).catId
			subCategoryName = "";
		} else {
			subCategoryName = JSON.parse(event.target.value).subCatName;
			catId = JSON.parse(event.target.value).catId
		}
		console.log('SubCatName ====', subCategoryName);
		
		setCiSubCategory(subCategoryName);
		setCiCategoryId(catId)
		console.log("Before if",ciSubCategory)
		props.setIsEditing(false)
		if (event.target.value !== "") {
		console.log("Inside if of class")
		dispatch(loadCatSubCat(catId,ciStatus));
		dispatch(resetResCatSubCatList());
	}
		//props.loadCICat(this.state.ciClass,this.state.ciCompany)}
		//props.setListMetaData(event.target.value, this.state.ciClassName)}
		console.log("ciSubCategory",event.target.value)
		console.log("For ciSubCategory",event.target)
		props.resetRightSide();
		props.showRightSide(false);
	};

	const onRowSelect = (row, isSelected, e, rowIndex) => {
        let rowStr = '';
        for (const prop in row) {
            if (row.hasOwnProperty(prop)) {
            rowStr += prop + ': "' + row[prop] + '"';
			console.log("Inside row select")
        }
    }	console.log("this.row", row);
		props.setIsEditing(false);
        props.getCategoryBoardDetails(
			row.res_category_name, 
			row.res_sub_category_name, 
			ciCompanyName, 
			row.status, 
			ciClass, 
			row.category_id, 
			ciCompany,
			ciCategory,
			ciSubCategory,
			row.res_category_id
			);
			props.showRightSide(true);
    }
	const handleEditClick = (rowIndex) => {
		//this.setState({ editRowIndex: rowIndex });
		setEditRowIndex(rowIndex);
	  };
	
	 const handleCancelClick = () => {
		//this.setState({ editRowIndex: -1 });
		setEditRowIndex(-1);
	  };
	
	 const handleSaveClick = (rowIndex) => {
		//this.setState({ editRowIndex: -1 });
		setEditRowIndex(-1);
	  };
	//   const handleDeleteClick = (row) => {
    //     return (
    //         <div className='text-c' onClick={() => onDeleteClick(row.id)}><i className="fa fa-trash-o cursorPoint" ></i></div>
    //     );
    // }
	
	const actionFormatter = (cell, row, rowIndex) => {
		if (rowIndex === editRowIndex) {
		  return (
			<div>
				<Button bsStyle="" className='margin-r-5 smbtnsave' title="Save" onClick={() => handleSaveClick(rowIndex)}><IoSaveOutline /></Button>
			  <Button bsStyle="" title="Cancel" onClick={() => handleCancelClick}><IoClose /></Button>
			</div>
		  );
		} else {
		  return (
			<div>
			  <Button className='margin-r-5' bsStyle="" title="Edit" onClick={() => handleEditClick(rowIndex)}><i className="fa fa-pencil" aria-hidden="true"></i></Button>
			  {/* <Button className='myBt rclose' title="Delete" bsStyle="" onClick={() => handleDeleteClick(rowIndex)}><i className="fa fa-trash"></i></Button> */}
			</div>
		  );
		}
	  };
	  const renderCICategoryTableList = (ciCategoryData) => {
		console.log("ciCategoryData===>",ciCategoryData)
		const capitalizedData = ciCategoryData.map(item => {
			return {
			  ...item,
			  status1: item.status.charAt(0).toUpperCase() + item.status.slice(1)
			};
		  });	  
        return (
          <div className="dtaTableDv integrateHubTable margin-xs-t-15">
            <div className={props.rightDisplay ? "leftTrue" : "rightTrue"}>
              <TableComponent
                data={capitalizedData}
                columns={tableColumns}
                headerColumns={filterColumns}
                onRowSelect={onRowSelect}
                rightDisplay={props.rightDisplay}
                uniqueKey={'res_category_id'}
              />
            </div>
         </div>
        );
      };
	const showData = () => {
		
        if (ciSubCategory=="" || ciResolutionCat.data == undefined || ciResolutionCat.data.length<=0 || ciResolutionCat.data.message=="No resolution category/subcategory mapped with input category " || ciResolutionCat.data.message=="Either category_id is not passed or category_id passed may not be an integer")  {
            return (
                <div className='myOrderDiv'>
                    {props.ciCategoryListDataLoader ? <ListLoader /> :renderCICategoryTableList([])}
                </div>
            );
        } else {
            return (
                <div className='myOrderDiv'>
                    {ciCategoryListDataLoader ? <ListLoader /> : renderCICategoryTableList(ciResolutionCat.data)}
                </div>
            );
        }
    }
		const tr=props.tr;
		return (
			<div>
				<Row>
					<Col md={4}>
					<Form.Group className='form-group' validationState={ciCompany == '' ? ciCompanyErrorColor : ''}>
							<Form.Label bsClass=""><span className="rStar"></span> {props.tr['Company']}</Form.Label>
							    <Field name="ciCompany" className={`form-control ${ciCompany == '' ? ciCompanyErrorColor : ''}`} component="select" onBlur={(event) => { validateField('ciCompany', event) }} onChange={(event) => { handleciCompanyChange(event) }} >
								<option value="">Select</option>
									<option company="Default" value="0">Default</option>
									{renderCompany(ciCompanyRed)}
								</Field>									 
						</Form.Group>
					</Col>
					<Col md={4}>
					<Form.Group className='form-group' validationState={ciClass == '' ? ciClassErrorColor : ''}>
							<Form.Label bsClass=""><span className="rStar"></span> {props.tr['Class']}</Form.Label>
							<Field name="ciClass" className={`form-control ${ciClass == '' ? ciClassErrorColor : ''}`} component="select" onBlur={(event) => { validateField('ciClass', event) }} onChange={(event) => { handleciClassChange(event) }}>
									<option value="">Select</option>
									{renderClass(compClass)}
								</Field>
						</Form.Group>
					</Col>
					<Col md={4}>
					<Form.Group className='form-group' validationState={ciStatus == '' ? ciStatusErrorColor : ''}>
							<Form.Label bsClass=""><span className="rStar"></span> {props.tr['Status']}</Form.Label>
							<Field name="ciStatus" className={`form-control ${ciStatus == '' ? ciStatusErrorColor : ''}`} component="select" onBlur={(event) => { validateField('ciStatus', event) }} onChange={(event) => { handleciStatusChange(event) }}>
									<option value="active">Active</option>
    								<option value="inactive">Inactive</option>
    								<option value="both">All</option>
								</Field>
						</Form.Group> 
					</Col>
                    <Col md={4}>
					<Form.Group className='form-group' validationState={ciCategory == '' ? ciCategoryErrorColor : ''}>
							<Form.Label bsClass=""><span className="rStar"></span> {props.tr['CI Category']}</Form.Label>
							    <Field name="ciCategory" className={`form-control ${ciCategory == '' ? ciCategoryErrorColor : ''}`} component="select" onBlur={(event) => { validateField('ciCategory', event) }} onChange={(event) => { handleciCategory(event) }} >
								<option value="">Select</option>
									{/* <option value="0">Default</option> */}
									{renderCategory(ciCat)}
								</Field>									 
						</Form.Group>
					</Col>
                    
                    <Col md={4}>
					<Form.Group className='form-group' validationState={ciSubCategory == '' ? ciSubCategoryErrorColor : ''}>
							<Form.Label bsClass=""><span className="rStar"></span> {props.tr['CI Sub Category']}</Form.Label>
							    <Field name="ciSubCategory" className={`form-control ${ciSubCategory == '' ? ciSubCategoryErrorColor : ''}`} component="select" onBlur={(event) => { validateField('ciSubCategory', event) }} onChange={(event) => { handleciSubCategory(event) }} >
								<option value="">Select</option>
									{/* <option value={JSON.stringify({catId: '0', subCatName: 'Default'})}>Default</option> */}
									{renderSubCategory(ciSCat)}
								</Field>									 
						</Form.Group>
					</Col>
				</Row><div>
					<h2 style={{position:"relative",top:"35px",zIndex:"2"}} className="sPageHeading1 display-inline-block"><span ></span>{tr['Selected Resolution Categories']}</h2>
					<div className>
						<div className="cIcatBordDv">
									{showData()}		
						</div>
					</div>
				</div>
			</div>
		);
	
}
// CiCategoryList = reduxForm({
// 	form: 'CiCategoryList',
// 	validate,
// 	destroyOnUnmount: true

// })(CiCategoryList);

// export default connect(mapStateToProps, mapDispatchToProps)(CiCategoryList)

export default reduxForm({
	form: 'CiCategoryList',
	destroyOnUnmount: true,
	validate,
  })(ResolutionCategoryList);