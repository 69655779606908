
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Spinner from 'react-spinkit';

import { loadCausalRelationshipData } from '../../../../actions/breakFix/causalRelationshipDataAction';
import {getInvestigationItemDetails} from '../../../../actions/investigation/investigationAction.js';
import { loadBreakFixAuditLogDetails } from '../../../../actions/breakFix/breakFixAuditLogAction';
import { loadChangeEditData, getLovValues } from '../../../../actions/changeManagement/changeManagementAction.js';
import {IoClose} from "react-icons/io5";

class RelationshipView extends React.Component {
    constructor(props){
        super(props);
          this.state = {
            loading:0
          };
          this.callItemDetailAction=this.callItemDetailAction.bind(this);
    }
    callItemDetailAction(itemId,module){
        if(module=="Investigation" && this.props.isProblemRolesEnabled){
          this.props.getInvestigationItemDetails(itemId);
          this.props.loadBreakFixAuditLogDetails(itemId, "dsc", "Investigation",'STATUS');
        }
        else if(module=="ChangeManagement" && this.props.isChangedRolesEnabled){
          this.props.loadChangeEditData(itemId,"");
          this.props.loadBreakFixAuditLogDetails(itemId, "dsc", "ChangeManagement", 'STATUS');
          this.props.getLovValues("ChangeManagement", "Type");
          this.props.getLovValues("ChangeManagement", "ChangeTiming");
          this.props.getLovValues("ChangeManagement", "TechnicalRisk");
          this.props.getLovValues("ChangeManagement", "BusinessRisk");
          this.props.getLovValues("ChangeManagement", "PlanType");
        }
    }
    componentWillMount(){
      this.props.loadCausalRelationshipData(this.props.requestId);
    }
    renderCausalRelationship(causalRelationshipList){
        if(causalRelationshipList.length==0 || causalRelationshipList.length==undefined){
          return(
            null
          )
        }else{
            let causalRelationshipDataLength=null;let arrowPosition=null;
            if(causalRelationshipList[0].module=="Breakfix"){
              let proposedInvestigation=[];let proposedChange=[];let proposedChangeChild=[];let initialArrow="";
              let initialDirection="";
              if(causalRelationshipList[0].ChildDetails ==null){
                initialArrow="";
                initialDirection="";
              }else{
                initialArrow="reltxt2";
                initialDirection="relverticalLine";
              }
              if(causalRelationshipList[0].ChildDetails !=null){
                if(causalRelationshipList[0].ChildDetails.length > 0){
                  for( let i=0;i<=causalRelationshipList[0].ChildDetails.length-1;i++){
                    if(causalRelationshipList[0].ChildDetails[i].module=="ChangeManagement"){
                      proposedChange.push(<div data-role="change" className="relTransform" onClick={() => { this.callItemDetailAction(causalRelationshipList[0].ChildDetails[i].itemId,causalRelationshipList[0].ChildDetails[i].module) }}>
                          {this.props.isChangedRolesEnabled?<Link to={"/editChange/"+causalRelationshipList[0].ChildDetails[i].itemId}><span className="cellBor">{causalRelationshipList[0].ChildDetails[i].itemNumber}</span></Link>:<span className="cellBor">{causalRelationshipList[0].ChildDetails[i].itemNumber}</span>}
                      </div>);
                    }else if(causalRelationshipList[0].ChildDetails[i].module=="Investigation"){
                      proposedInvestigation.push(<div data-role="investigation" className="relTransform" onClick={() => { this.callItemDetailAction(causalRelationshipList[0].ChildDetails[i].itemId,causalRelationshipList[0].ChildDetails[i].module) }}>
                          {this.props.isProblemRolesEnabled?<Link to={"/investigationEdit/"+causalRelationshipList[0].ChildDetails[i].itemId}><span className="cellBor">{causalRelationshipList[0].ChildDetails[i].itemNumber}</span></Link>:<span className="cellBor">{causalRelationshipList[0].ChildDetails[i].itemNumber}</span>}
                      </div>);
                      if(causalRelationshipList[0].ChildDetails[i].ChildDetails.length > 0){
                        for( let j=0;j<=causalRelationshipList[0].ChildDetails[i].ChildDetails.length-1;j++){
                          proposedChangeChild.push(<div data-role="change" className="relTransform1" onClick={() => { this.callItemDetailAction(causalRelationshipList[0].ChildDetails[i].ChildDetails[j].itemId,causalRelationshipList[0].ChildDetails[i].ChildDetails[j].module) }}>
                              {this.props.isChangedRolesEnabled?<Link to={"/editChange/"+causalRelationshipList[0].ChildDetails[i].ChildDetails[j].itemId}><span className="cellBor">{causalRelationshipList[0].ChildDetails[i].ChildDetails[j].itemNumber}</span></Link>:<span className="cellBor">{causalRelationshipList[0].ChildDetails[i].ChildDetails[j].itemNumber}</span>}
                          </div>);
                        }
                        proposedInvestigation.push(proposedChangeChild)
                      }
                    }
                    proposedChangeChild =[]
                  }
                }else{
                  proposedInvestigation=[]
                  proposedChangeChild=[];
                  proposedChange=[];
                }
              }
              return(
                  <div>
                    <div data-role="breakFix" className={initialArrow}><span className={initialDirection}></span>{this.props.isIncidentRolesEnabled?<Link to={"/editBreakFix/breakFixItemId:"+causalRelationshipList[0].itemId}><span title="Incident Ticket" className="cellBor">{causalRelationshipList[0].itemNumber}</span></Link>:<span title="Incident Ticket" className="cellBor">{causalRelationshipList[0].itemNumber}</span>}</div>
                    {proposedInvestigation}
                    {proposedChangeChild}
                    {proposedChange}
                  </div>
                )
            }else if(causalRelationshipList[0] && causalRelationshipList[0].module && causalRelationshipList[0].module=="Investigation"){
                let proposedInvestigation=[];let proposedChangeChild=[];
                proposedInvestigation.push(<div data-role="investigation" className="relTransform" onClick={() => { this.callItemDetailAction(causalRelationshipList[0].itemId,causalRelationshipList[0].module) }}>
                    {this.props.isProblemRolesEnabled?<Link to={"/investigationEdit/"+causalRelationshipList[0].itemId}><span className="cellBor">{causalRelationshipList[0].itemNumber}</span></Link>:<span className="cellBor">{causalRelationshipList[0].itemNumber}</span>}</div>)    
                if(causalRelationshipList[0].ChildDetails !=null && causalRelationshipList[0].ChildDetails.length > 0){
                  let causalRelItem = causalRelationshipList[0].ChildDetails;
                  for( let j=0;j<=causalRelItem.length-1;j++){
                    proposedChangeChild.push(<div data-role="change" className="relTransform1" onClick={() => { this.callItemDetailAction(causalRelItem[j].itemId,causalRelItem[j].module) }}>
                        {this.props.isChangedRolesEnabled?<Link to={"/editChange/"+causalRelItem[j].itemId}><span className="cellBor">{causalRelItem[j].itemNumber}</span></Link>:<span className="cellBor">{causalRelItem[j].itemNumber}</span>}</div>);
                  }
                }
                return(
                  <div>
                    {proposedInvestigation}
                    {proposedChangeChild}
                  </div>
                )
              }
              else if(causalRelationshipList[0] && causalRelationshipList[0].module && causalRelationshipList[0].module=="ChangeManagement"){
                let proposedChange=[];
                proposedChange.push(<div data-role="change" className="relTransform" onClick={() => { this.callItemDetailAction(causalRelationshipList[0].itemId,causalRelationshipList[0].module) }}>
                    {this.props.isChangedRolesEnabled?<Link to={"/editChange/"+causalRelationshipList[0].itemId}><span className="cellBor">{causalRelationshipList[0].itemNumber}</span></Link>:causalRelationshipList[0].itemNumber}
                    </div>)
                return(
                  <div>
                    {proposedChange}
                  </div>
                )
              }
        }
      }
    render(){
        if(this.props.isLoading) {
            return (
              <div>
                <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
                  <ul>
                    <li>
                    <button
                        type='button'
                        title="Minimize the right panel"
                        bsClass=""
                        bsStyle=""
                        className="closerightPanelBtn"
                        onClick={() => {
                        this.props.rightEditPanel(false);
                        }}
                    >
                        <IoClose/>
                    </button>
                    </li>
                  </ul>
              </div>
              <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Causal Relationship']}</div></div>
                <div className='rBoxGap'><Spinner name='double-bounce' /></div>
              </div>
            );
          }else{
             return (
               <div>
                <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
                  <ul>
                    <li>
                    <button
                        type='button'
                        title="Minimize the right panel"
                        bsClass=""
                        bsStyle=""
                        className="closerightPanelBtn"
                        onClick={() => {
                        this.props.rightEditPanel(false);
                        }}
                    >
                        <IoClose/>
                    </button>
                    </li>
                  </ul>
                </div>
                <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Causal Relationship']}</div></div>
                <div className='rBoxGap'>
                  <div className="caRelView">
                   <div className="reltxt1"><span className="relverticalLine"></span><span title="D2C Order Ticket" className="cellBor">{this.props.causalRelationshipList.itemNumber}</span></div>
                   <div className="relParentBox">
                      {this.renderCausalRelationship(this.props.causalRelationshipList.ChildDetails)}
                    </div>
                </div>
                </div>
              </div>
            );
          }
    }
}
export function mapStateToProps({ causalRelationshipList,breakfixD2cNumber,isCausalRelationshipData }){
    return { causalRelationshipList,breakfixD2cNumber,isLoading:isCausalRelationshipData.causalRelationData };
} 
function mapDispatchToProps(dispatch){
    return bindActionCreators({ loadBreakFixAuditLogDetails,getInvestigationItemDetails,loadCausalRelationshipData, loadChangeEditData, getLovValues}, dispatch);
}
export default connect(mapStateToProps,mapDispatchToProps)(RelationshipView);
