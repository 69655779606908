
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';

//let user_id=userDetails.user_id;
//let company_id=userDetails.company_id;

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

export function loadBestFeatureSuccess(bestFeature){
	return {
		type: 'FETCH_BESTFEATURE',
		bestFeature
	};
}

export function loadBestFeature(start,end){

	let str={};
	str.companyId="";
	str.userId="";
	str.currency = "USD";
    if(start!=""){
		str.start=start;
	}
	if(end!=""){
		str.end=end;
	}
       
   
    str = JSON.stringify(str);
	const bestFeatureUrl="/getFeaturedOfferingForRestifySelfService"
//	const bestFeatureUrl="/getFeaturedOfferingForRestifySelfService?companyId="+company_id+"&userId="+user_id+"&currency=USD&start=1&end=9"
	return(dispatch) =>{
		dispatch({ type: "FEATURED_LOADER_IN_PROGRESS" });
		api.get(bestFeatureUrl,{headers:{'query':str}})
			.then((bestFeature)=>{
				if(!bestFeature){
					throw Error(bestFeature.statusText);
				}
				return bestFeature;
			})
			.then((bestFeature) =>{
				dispatch(loadBestFeatureSuccess(bestFeature))
				dispatch({ type: 'FEATURED_LOADER_DETAILS_FETCHED_SUCCESSFULLY' });

			})
			.catch((err) => {
				dispatch({ type: 'FEATURED_LOADER_DETAILS_FETCHED_SUCCESSFULLY' });

			});
	};
}
