
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Row, Col, Table, Button } from "react-bootstrap";

import TranslationForm from "./translationForm.js";
import { connect } from "react-redux";
import {
  spcmLoader,
  getTranslationForOfferingBasedOnType,
  getServiceOrderChoices,
  setTranslationObject,
  getQuestionsCategory,
  getAttributesTranslationStatus,
} from "../../../../../actions/spcmActions";
import ListLoader from "_Commons/loaders/ListLoader";
import _ from "lodash";
import Pagination from "react-js-pagination";

class QuestionListing extends React.Component {
  constructor(props) {
    super(props);
    this.whichFormOpen = this.whichFormOpen.bind(this);
    this.getListHandler = this.getListHandler.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.state = {
      currentForm: "Questions_Form",
      pageSelected: 1,
      pageSelected2: 1,
    };
  }

  whichFormOpen(value) {
    switch (value) {
      // case "Form1":
      //   return (
      //     <TextFieldForm1
      //       insideTableShowFun6={this.props.insideTableShowFun5}
      //       fieldName1={this.props.fieldName}
      //       fieldNameVal3={this.props.fieldNameVal2}
      //       lanNameVal3={this.props.lanNameVal2}
      //     />
      //   );
      case "Form2":
        return (
          <TranslationForm
            insideTableShowFun6={this.props.insideTableShowFun5}
            fieldName1={this.props.fieldName}
            fieldNameVal3={this.props.fieldNameVal2}
            lanNameVal3={this.props.lanNameVal2}
            lanIdVal2={this.props.lanIdVal2}
            languageCode={this.props.languageCode}
            tr={this.props.tr}
            currentForm={this.state.currentForm}
          />
        );
    }
  }

  componentDidMount() {
    this.props.getTranslationForOfferingBasedOnType(
      this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
      this.props.lanIdVal2,
      "question"
    );
    this.props.getServiceOrderChoices(
      this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
      "",
      "",
      "Question"
    );
    this.props.getQuestionsCategory(
      this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID
    );
  }

  getListHandler(e) {
    this.setState({ currentForm: e });
    if (e == "Questions_Form") {
      this.props.getServiceOrderChoices(
        this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
        "",
        "",
        "Question"
      );
      this.props.getTranslationForOfferingBasedOnType(
        this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
        this.props.lanIdVal2,
        "question"
      );
    } else if (e == "Category_Form") {
      this.props.getQuestionsCategory(
        this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID
      );

      this.props.getTranslationForOfferingBasedOnType(
        this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
        this.props.lanIdVal2,
        "question_category"
      );
    }
  }

  onPageChange(myObj, e) {
    switch (myObj) {
      case "questList":
        this.setState({ pageSelected: e });
        this.props.getServiceOrderChoices(
          this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
          "",
          "",
          "Question",
          "",
          e,
          ""
        );
        break;
      case "groupList":
        this.setState({ pageSelected2: e });
        this.props.getQuestionsCategory(
          this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
          "",
          "",
          "",
          "",
          e,
          ""
        );
        break;
    }
  }

  render() {
    let translatedList = this.props.spcmReducer.getListForTranslation;
    let baseQuestionsList = this.props.spcmReducer.serviceOrderChoices;
    var baseCategoryList = this.props.spcmReducer.questions_category;

    var meta = this.props.spcmReducer.requiredQuestionMeta;
    var meta2 = this.props.spcmReducer.requiredQuestionGroupMeta;
    console.log("getListForTranslation");
    console.log(translatedList);
    console.log(baseQuestionsList);
    console.log(baseCategoryList);
    console.log(meta);
    console.log(meta2);

    let newTranslatedAttributesList = [];
    let newTranslatedCategoryList = [];

    // Translated list for group
    if (_.some(translatedList, {
      "attribute_type":
      "question_category"
    })) {
      console.log("inside this");
      translatedList = _.map(
        translatedList,
        _.partialRight(_.pick, [
          "attribute_value",
          "attribute_trans_value",
          "attribute_id",
        ])
      );
      translatedList.map((item) => {
        newTranslatedCategoryList.push(
          _.mapKeys(item, (value, key) => {
            let newKey = key;
            if (key === "attribute_trans_value") {
              newKey = "TRANSLATED_CATEGORY";
            }
            if (key === "attribute_value") {
              newKey = "ATTRIBUTE_VALUE";
            }
            if (key === "attribute_id") {
              newKey = "ATTRIBUTE_ID";
            }
            return newKey;
          })
        );
      });
    }
    // Translated list for Question
    else {
      translatedList = _.map(
        translatedList,
        _.partialRight(_.pick, [
          "QUESTION_NAME",
          "RESPONSE_VALUE",
          "HELP_TEXT",
          "PLACEHOLDER",
          "ID",
          "DISPLAY_TEXT",
          "TABLE_HEADERS"
        ])
      );

      translatedList.map((item) => {
        newTranslatedAttributesList.push(
          _.mapKeys(item, (value, key) => {
            let newKey = key;
            if (key === "RESPONSE_VALUE") {
              newKey = "TRANSLATED_RESPONSE_VALUE";
            }

            if (key === "QUESTION_NAME") {
              newKey = "TRANSLATED_QUESTION_NAME";
            }

            if (key === "HELP_TEXT") {
              newKey = "TRANSLATED_HELP_TEXT";
            }

            if (key === "PLACEHOLDER") {
              newKey = "TRANSLATED_PLACEHOLDER";
            }
            if (key === "DISPLAY_TEXT") {
              newKey = "TRANSLATED_DISPLAY_TEXT";
            }
            if (key === "TABLE_HEADERS") {
              newKey = "TRANSLATED_TABLE_HEADERS";
            }
            return newKey;
          })
        );
      });
    }



    var QuestionList = _.map(baseQuestionsList, function (item) {
      return _.merge(
        item,
        _.find(newTranslatedAttributesList, { ID: Number(item.QUESTION_ID) })
      );
    });


    var CategoryList = _.map(baseCategoryList, function (item) {
      return _.merge(
        item,
        _.find(newTranslatedCategoryList, { ATTRIBUTE_ID: Number(item.CATEGORY_ID) })
      );
    });

    console.log("CategoryList");
    console.log(CategoryList);

    return (
      <div>
        {this.props.isInsideTableShow5 ? (
          <div>{this.whichFormOpen(this.props.whichInsideFormOpen5)}</div>
        ) : (
          <div>
            <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">
              Translate - {this.props.fieldNameVal2} - {this.props.lanNameVal2}{" "}
            </div></div>

            <div className="rBoxGap">
            <Row className="margin-b-5">
              <Col md={8}>
                <div className="f-size-18 colorStOrange">
                  {this.props.fieldNameVal2} List
                </div>
              </Col>
              <Col md={4} className="text-r">
                <Button
                  size="sm"
                  className="backtolst"
                  onClick={() => {
                    this.props.lanEditPageFun5(false, "Form2");
                    this.props.getAttributesTranslationStatus(
                      this.props.spcmReducer.currentlySelectedServiceDetails
                        .OFFERING_ID,
                      this.props.lanIdVal2
                    );
                  }}
                >
                  <i className="fa fa-arrow-left margin-r-5"></i> Back to List
                </Button>
              </Col>
            </Row>

            <div className="radioVarBox">
              <label>
                <input
                  type="radio"
                  name="orderingInfo"
                  value="Category_Form"
                  defaultChecked={this.state.currentForm == "Category_Form"}
                  onChange={(event) => {
                    this.getListHandler(event.target.value);
                  }}
                />
                Group
              </label>
              <label>
                <input
                  type="radio"
                  name="orderingInfo"
                  value="Questions_Form"
                  defaultChecked={this.state.currentForm == "Questions_Form"}
                  onChange={(event) => {
                    this.getListHandler(event.target.value);
                  }}
                />
                Information Required
              </label>
            </div>
            {this.state.currentForm == "Questions_Form" ? (
              <div>
                <Table
                  className="langTable tableView"
                  striped
                  bordered
                  responsive
                >
                  <thead>
                    <tr>
                      <th width="20%">Information</th>
                      <th width="30%">Trans. Information</th>
                      <th width="5%"></th>
                    </tr>
                  </thead>
                  {this.props.spcmReducer.spcmFetchingAttribute ? (
                    <tbody>
                      <td colSpan="5">
                        <ListLoader />
                      </td>
                    </tbody>
                  ) : (
                    <tbody>
                      {QuestionList.map(function (value, index) {
                        console.log("value++++",value);
                        return (
                          <tr>
                            <td style={{ "fontSize": "13px" }}>
                              <div style={{ "marginBottom": "5px" }}><font style={{ 'color': '#8a8a8a' }}>Question Text -</font> {value.QUESTION_TEXT}</div>
                              {value.RESPONSE_VALUE ? <div><font style={{ 'color': '#8a8a8a' }}>Response Value -</font> {value.RESPONSE_VALUE}</div> : value.DISPLAY_TEXT ? <div><font style={{ 'color': '#8a8a8a' }}>Response Value -</font> {value.DISPLAY_TEXT.replace( /(<([^>]+)>)/ig, '')}</div>:null}
                              {value.TABLE_HEADERS ? <div><font style={{ 'color': '#8a8a8a' }}>Table Headers -</font> {value.TABLE_HEADERS}</div> : null}
                            
                            </td>
                            <td style={{ "fontSize": "13px" }}>
                              {
                                value.TRANSLATED_QUESTION_NAME ? <div style={{ "marginBottom": "5px" }}><font style={{ 'color': '#8a8a8a' }}>Question Text -</font> {value.TRANSLATED_QUESTION_NAME}</div> : null
                              }
                              {value.TRANSLATED_RESPONSE_VALUE ? <div><font style={{ 'color': '#8a8a8a' }}>Response Value -</font> {value.TRANSLATED_RESPONSE_VALUE}</div> : value.TRANSLATED_DISPLAY_TEXT ? <div><font style={{ 'color': '#8a8a8a' }}>Response Value -</font> {value.TRANSLATED_DISPLAY_TEXT}</div>:null}
                              {value.TRANSLATED_TABLE_HEADERS ? <div><font style={{ 'color': '#8a8a8a' }}>Table Headers -</font> {value.TRANSLATED_TABLE_HEADERS}</div> :null}

                            </td>
                            <td>
                              <a
                                onClick={() => {
                                  this.props.setTranslationObject(value);
                                  this.props.insideTableShowFun5(true, "Form2");
                                }}
                                title="Edit"
                                href="javascript:void(0)"
                              >
                                <i className="fa fa-pencil-square-o"></i>
                              </a>
                            </td>
                          </tr>
                        );
                      }, this)}
                    </tbody>
                  )}
                </Table>
                <div className="text-c margin-t-10">
                <nav aria-label="Pagination" className="display-inline-block">
                  {meta ? (
                    <Pagination
                      activePage={meta ? meta.currentPage : 1}
                      itemsCountPerPage={this.state.pageSize}
                      totalItemsCount={meta ? meta.rowCount : 1}
                      onChange={(e) => {
                        this.onPageChange("questList", e);
                      }}
                    />
                  ) : null}
                  </nav>
                </div>
              </div>
            ) : (
              <div>
                <Table
                  className="langTable tableView"
                  striped
                  bordered
                  responsive
                >
                  <thead>
                    <tr>
                      <th width="20%">Group</th>
                      <th width="30%">Trans. Group</th>
                      <th width="5%"></th>
                    </tr>
                  </thead>
                  {CategoryList[0].message == "No data found."?null:
                  (this.props.spcmReducer.spcmFetchingAttribute ? (
                    <tbody>
                      <td colSpan="5">
                        <ListLoader />
                      </td>
                    </tbody>
                  ) : (
                    <tbody>
                      {CategoryList.map(function (value, index) {
                        return (
                          <tr>
                            <td>{value.CATEGORY_NAME}</td>
                            <td>{value.TRANSLATED_CATEGORY}</td>
                            <td>
                              <a
                                onClick={() => {
                                  this.props.setTranslationObject(value);
                                  this.props.insideTableShowFun5(true, "Form2");
                                }}
                                title="Edit"
                                href="javascript:void(0)"
                              >
                                <i className="fa fa-pencil-square-o"></i>
                              </a>
                            </td>
                          </tr>
                        );
                      }, this)}
                    </tbody>
                  ))
                }
                </Table>
                <div className="text-c margin-t-10">
                <nav aria-label="Pagination" className="display-inline-block">
                  {meta2 ? (
                    <Pagination
                      activePage={meta2 ? meta2.currentPage : 1}
                      itemsCountPerPage={this.state.pageSize}
                      totalItemsCount={meta2 ? meta2.rowCount : 1}
                      onChange={(e) => {
                        this.onPageChange("groupList", e);
                      }}
                    />
                  ) : null}
                  </nav>
                </div>
              </div>
            )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ spcmReducer }) => {
  return {
    spcmReducer,
  };
};

export default connect(mapStateToProps, {
  getTranslationForOfferingBasedOnType,
  getServiceOrderChoices,
  setTranslationObject,
  getQuestionsCategory,
  getAttributesTranslationStatus,
})(QuestionListing);
