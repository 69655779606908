
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import { useSelector } from "react-redux";

import PindCategories from "../../../common/header/pind-categories";
import Breadcrumbs from "../../../common/header/breadcrumbs";
import Header from "./rosterCreateHeader";
import FormComponent from "./rosterCreateForm";
import { useLocation } from "react-router";
import "_Css/form/_form.scss";

const Index = () => {
  const translator = useSelector((state) => state.spcmReducer.tr);
  const [groupErrorState, setGroupErrorState] = useState('');
  const [shiftErrorState, setShiftErrorState] = useState('');
  const [startDateErrorState, setStartDateErrorState] = useState('');
  const [endDateErrorState, setEndDateErrorState] = useState('');

  const location = useLocation();
  const createRosterFromShift = location.state && location.state.createRosterFromShift ? location.state.createRosterFromShift : null;

  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Breadcrumbs activePageName="Create" parentPageurl='/onCallSchedule/roster' parentPageName="On Call Schedule" />
      </Container>

      <Container fluid>
        <Header setShiftErrorState={setShiftErrorState} setGroupErrorState={setGroupErrorState} setStartDateErrorState={setStartDateErrorState} setEndDateErrorState={setEndDateErrorState} />
        <Row>
          <Col md={8} xs={12}>
            <FormComponent createRosterFromShiftInitialValues={createRosterFromShift} shiftErrorState={shiftErrorState} setShiftErrorState={setShiftErrorState} groupErrorState={groupErrorState} setGroupErrorState={setGroupErrorState} startDateErrorState={startDateErrorState} setStartDateErrorState={setStartDateErrorState} endDateErrorState={endDateErrorState} setEndDateErrorState={setEndDateErrorState}  />
          </Col>
        </Row>
      </Container>
    </main>
  )
}

export default Index;
