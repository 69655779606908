
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import PindCategories from "../../common/header/pind-categories";
import Breadcrumbs from "../../common/header/breadcrumbs";
import Header from "./CompanyMenuListHeader";
import List from "./CompanyMenuList";
import ShortDesc from "./CompanyMenuListShortDescription";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { loadAllScheduleList } from "../../../actions/onCallSchedule/schedule/onCallScheduleAction";
import { useDispatch } from "react-redux";
import { getCompanyMenuListViewData } from "../../../actions/companyMenuList/companyMenuListAction";


const CompanyMenuListIndex = () => {
  const dispatch = useDispatch();
  const [isRightSideVisible, setRightSideVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [rowIndex, setRowIndex] = useState(0);
  const [nameSearchInput, setNameSearchInput] = useState("");
  const [moduleSearchInput, setModuleSearchInput] = useState("");
  const [keySearchInput, setKeySearchInput] = useState("");
  const [fieldSearchInput, setFieldSearchInput] = useState("");
  const [typeSearchInput, setTypeSearchInput] = useState("");
  const [timezoneSearchInput, setTimezoneSearchInput] = useState("");
  const [statusSearchInput, setStatusSearchInput] = useState("");
  const [activePage, setActivePage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItemsCount, setTotalItemsCount] = useState(50);
  const [isFilterApplied,setIsFilterApplied]= useState(false);

  let colWidth = "";
  isRightSideVisible ? (colWidth = 8) : (colWidth = 12);

  const showActiveRow = (i) => {
    setRowIndex(i);
  };

  const showRightSideFun = (val) => {
    setRightSideVisible(val);
  };

  const setSelectedItemFun = (data) => {
    setSelectedItem(data);
  };

  const resetFilters = ()=>{
    setIsFilterApplied(false);
    setNameSearchInput("");
    setModuleSearchInput("");
    setKeySearchInput("");
    setFieldSearchInput("");
    setStatusSearchInput("");
    setTimezoneSearchInput("");
    setTypeSearchInput("");
    let filter = { page: 1, limit: 10 };
    dispatch(getCompanyMenuListViewData(filter));
  }

  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Breadcrumbs activePageName="Company MenuList" />
      </Container>

      <Container fluid>
        <Header
          showRightSideFun={showRightSideFun}
          isFilterApplied={isFilterApplied}
          resetFilters={resetFilters}
          nameSearchInput={nameSearchInput}
          moduleSearchInput={moduleSearchInput}
          keySearchInput={keySearchInput}
          fieldSearchInput={fieldSearchInput}
          typeSearchInput={typeSearchInput}
          timezoneSearchInput={timezoneSearchInput}
          statusSearchInput={statusSearchInput}
          setActivePage={setActivePage}
          setTotalItemsCount={setTotalItemsCount}
          setItemsPerPage={setItemsPerPage}
        />
        <PanelGroup direction="horizontal" className="panelOverflow">
          <Panel
            id="sidebar"
            minSize={30}
            order={1}
            defaultSize={isRightSideVisible ? 67 : 100}
            className={
              isRightSideVisible ? "isShowLeftPanel catBordDv" : "catBordDv"
            }
          >
            <List
              isRightSideVisible={isRightSideVisible}
              showRightSideFun={showRightSideFun}
              setSelectedItemFun={setSelectedItemFun}
              rowIndex={rowIndex}
              showActiveRow={showActiveRow}
              nameSearchInput={nameSearchInput}
              moduleSearchInput={moduleSearchInput}
              keySearchInput={keySearchInput}
              fieldSearchInput={fieldSearchInput}
              typeSearchInput={typeSearchInput}
              timezoneSearchInput={timezoneSearchInput}
              statusSearchInput={statusSearchInput}
              activePage={activePage}
              totalItemsCount={totalItemsCount}
              itemsPerPage={itemsPerPage}
              isFilterApplied={isFilterApplied}
              setNameSearchInput={setNameSearchInput}
              setModuleSearchInput={setModuleSearchInput}
              setKeySearchInput={setKeySearchInput}
              setFieldSearchInput={setFieldSearchInput}
              setTypeSearchInput={setTypeSearchInput}
              setTimezoneSearchInput={setTimezoneSearchInput}
              setStatusSearchInput ={setStatusSearchInput}
              setActivePage={setActivePage}
              setTotalItemsCount={setTotalItemsCount}
              setItemsPerPage={setItemsPerPage}
              setIsFilterApplied = {setIsFilterApplied}
              resetFilters={resetFilters}
            />
          </Panel>
          {isRightSideVisible ? (
            <>
              <PanelResizeHandle className="isShowLeftPanel resizeHandle">
                <div className="outlne">
                  <div className="handIcn">
                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                  </div>
                </div>
              </PanelResizeHandle>
              <Panel minSize={33} order={2} defaultSize={33}>
                <div className="stickyArea rBoxStyle">
                  <ShortDesc
                    showRightSideFun={showRightSideFun}
                    selectedItem={selectedItem}
                    setItemsPerPage={setItemsPerPage}
                    resetFilters={resetFilters}
                  />
                </div>
              </Panel>
            </>
          ) : (
            ""
          )}
        </PanelGroup>
      </Container>
    </main>
  );
}

export default CompanyMenuListIndex;
