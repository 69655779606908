
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../../initialState';

export const on_call_common_reducer = (state = initialState, action) => {
	switch (action.type) {
		case 'LOAD_SCHEDULE_TYPE_SUCCESS':
			return Object.assign({}, state, { scheduleType: action.payload });
		case 'LOAD_ON_CALL_STATUS_SUCCESS':
			return Object.assign({}, state, { onCallStatus: action.payload });
        case 'LOAD_ON_CALL_FREQUENCY_SUCCESS':
            return Object.assign({}, state, { onCallFrequency: action.payload });
        case 'LOAD_ON_CALL_ESCALATION_LEVEL_SUCCESS':
            return Object.assign({}, state, { escalationLevel: action.payload });
		default:
			return state;
	}
}

export const on_call_common_loader_reducer = (state = initialState, action) => {
	switch (action.type) {
		default:
			return state;
	}
}
export const onCallGroupList = (state = [], action) => {
    switch (action.type) {
        case 'LOAD_ON_CALL_GROUP_LIST_SUCCESS':
            return action.groupList.data;
        default:
            return state;
    }
}

export const onCallGroupUsersList = (state = [], action) => {
    switch (action.type) {
        case 'LOAD_ON_CALL_GROUP_USERS_LIST':
            return action.usersList.data;
        default:
            return state;
    }
}
export const selectedOnCallGroup = (state = [], action) => {
    switch (action.type) {
        case 'SET_ONCALL_GROUP':
            return action.group;
        default:
            return state;
    }
}

export const availableIndividuals = (state = [], action) => {
    switch (action.type) {
        case 'GET_AVAILABLE_INDIVIDUALS':
            return action.users;
        default:
            return state;
    }
}
