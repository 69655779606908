
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import _ from "lodash";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector, connect } from "react-redux";
import { reduxForm, SubmissionError } from "redux-form";
import { Row, Col, Button } from "react-bootstrap";
import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import { MyContext } from '_MyContext';

import {
  createForum,
  updateForum,
  getAllFroums,
} from "../../../../reducers/foundation/forum/forum";

import { validate } from "./form";

const Header = (props) => {
  const dispatch = useDispatch();
  const params = useParams();

  const { error, handleSubmit } = props;

  const navigate = useNavigate();
  const tr = useSelector((state) => state.spcmReducer.tr);

  const forumId = params?.forumId;
  const isAddMode = !forumId;

  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const saveObjRef = useRef(null);

	useEffect(() => {
		context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
	}, []);

  const submit = async (values) => {
    const emptyvalues = _.isEmpty(values, true);
    if (emptyvalues) {
      throw new SubmissionError({
        _error:
          "The form has missing required fields, please revalidate the highlighted fields and update the form",
      });
    }
    const postData = { file: null, ...values, regionId: 0 };

    if (isAddMode) {
      const resp = await dispatch(createForum(postData));
      if (resp?.payload?.forumId) {
        setTimeout(
          () => navigate(`/editforum/${resp?.payload?.forumId}`),
          1000
        );
      }
    } else {
      const updateRes = await dispatch(updateForum(postData));
      setTimeout(() => navigate(`/forum`), 1000);
    }
  };

  return (
    <Row className="margin-b-15">
      <Col lg={4} md={6} sm={6} xs={5}>
        <h1 bsClass="" className="sPageHeading1">
          {tr["Forum"]}
        </h1>
      </Col>
      <Col lg={8} md={6} sm={6} xs={7}>
        <div className="paHedFilter">
          {!isAddMode && (
            <Button
              bsPrefix=" "
              id="creTag"
              role="button"
              className="myBt fillBtn"
              disabled={props.loader}
              title={tr["Audit Log"]}
            >
              <i aria-hidden="true" className="fa fa-eye"></i>
            </Button>
          )}
          <Link
            bsPrefix=" "
            id="creTag"
            to="javascript:void(0)"
            className="myBt plus fillBtn"
            disabled={props.loader}
            title={tr["Save"]}
            onClick={props.handleSubmit(submit)}
            ref={saveObjRef}
          >
            {
                props.loader ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/>
              }
          </Link>
          <Link
            bsPrefix=" "
            to="/forum"
            className="myBt cancel fillBtn"
            title={tr["Cancel"]}
            ref={closeButton}
          >
            <IoClose />
          </Link>
        </div>
      </Col>
      <Col xs={12}>
        <p className="red py-2">{error && <strong>{error}</strong>}</p>
      </Col>
    </Row>
  );
};
//export default connect()(Header);
export default reduxForm({
  form: "createForum",
  // destroyOnUnmount: true,
  // enableReinitialize: true,
})(Header);
