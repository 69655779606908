
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { freezeNotApplicable } from "../../studio/utils";
import { Form } from "react-bootstrap";
const SwitchButtonComponent = ({
  item,
  attributes,
  renderComponent,
  field,
  defaultValues,
  onChange
}) => {
  if (attributes?.href || attributes?.target) {
    delete attributes["href"];
    delete attributes["target"];
    delete attributes["checked"];
  }
  let editable = true;
  if (
    defaultValues &&
    defaultValues.hasOwnProperty("edittable") &&
    !freezeNotApplicable.includes(item?.buttonType?.id)
  ) {
    editable = defaultValues?.edittable;
    if (!editable) {
      attributes["class"] = attributes?.class + " inactive";
    }
  }
  
  const onInputChange = (e)=>{
    onChange(e.target.checked,item);
  }
  return (
    <Form.Check
      type="switch"
      id="custom-switch"
      onChange={(e) => {
        onInputChange(e);
      }}
      checked={
        defaultValues.hasOwnProperty(item?.name) ? defaultValues[item?.name] : item?.checked
      }
      {...attributes}
    />
  );
};

export default SwitchButtonComponent;
