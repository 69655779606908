
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Container } from 'react-bootstrap';

import PindCategories from "../../../common/header/pind-categories";
import Breadcrumbs from "../../../common/header/breadcrumbs";
import Header from "./header";
import List from "./list";
import ShortDesc from "./shortDesc";
import {getCompanyFdnList} from "../../../../actions/foundation/companyAction"
import { useDispatch, useSelector } from "react-redux";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import Cookies from 'universal-cookie';
import "_Css/common/_dataTable.scss";
const cookies = new Cookies();
let cookiesArray = cookies.get('gph');
if (cookiesArray) cookiesArray = cookiesArray.replace('s:', '');
if (cookiesArray) cookiesArray = cookiesArray.substring(0, cookiesArray.lastIndexOf('.'));
cookiesArray = cookiesArray.split("~");
let isSuperAdmin = cookiesArray[60];

const Index = () => {
  let dispatch = useDispatch();
  const [isRightSideVisible, setRightSideVisible] = useState(false);
  const [companyData, setCompanyData] = useState({});
  
  const translator = useSelector((state) => state.spcmReducer.tr);
  let colWidth = "";
  isRightSideVisible ? (colWidth = 8) : (colWidth = 12);

  const showRightSideFun = (val) => {
    setRightSideVisible(val);
  };
  
  const companyDataHandeler = (companyData) => {
    setCompanyData(companyData)
  }
  useEffect(() => {
    dispatch(getCompanyFdnList());
   },[])
  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        {/* <div className="tabDatacount">1-1 of 1</div> */}
        <Breadcrumbs activePageName={"Company"} />
      </Container>

      <Container fluid className="position-re">
        <Header translator={translator} isSuperAdmin={isSuperAdmin}/>
        <PanelGroup direction="horizontal">
          <Panel id="sidebar" minSize={33} order={1} defaultSize={isRightSideVisible ? 67 : 100} className={isRightSideVisible ? "isShowLeftPanel catBordDv" : "catBordDv"}>
            <List
              isRightSideVisible={isRightSideVisible}
              showRightSideFun={showRightSideFun}
              translator={translator}
              companyDataHandeler={companyDataHandeler }
              isSuperAdmin={isSuperAdmin}
            />
          </Panel>
          {isRightSideVisible ?
            <>
              <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
              <Panel minSize={33} order={2} defaultSize={33}>
                <div className="stickyArea rBoxStyle">
                  <ShortDesc
                    showRightSideFun={showRightSideFun}
                    companyData={companyData} />
                </div>
              </Panel>
            </> : ""
          }
        </PanelGroup>
      </Container>
    </main>
  )
}

export default Index;
