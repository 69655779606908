
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import {Row,Col,Nav,Button} from 'react-bootstrap';
import { Link } from "react-router-dom";
let sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
import { getTimelineData } from '../../../actions/cmdb/cmdbTimelineAction';
import {GLOBAL} from '_Globals';
import axios from 'axios';
import {connect} from  'react-redux';
import Swal from "sweetalert2";
import { reduxForm,SubmissionError } from 'redux-form';
import { navigationHooks } from '../../../helpers/NavigationHook';
import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import {LuSearch, LuBarChart} from 'react-icons/lu';
import { MyContext } from '_MyContext';
class CmdbCreateHeader extends React.Component {
  static contextType = MyContext;
  constructor(props) {
		super(props);
    this.state = {
			showSubmit:true
    };
		this.submit = this.submit.bind(this);
	}

  emptyGlobals() {
    GLOBAL.cinameErrorColor="";
    GLOBAL.ciclassErrorColor="";
    GLOBAL.cistatusErrorColor="";
    GLOBAL.cicompanyErrorColor="";
    GLOBAL.cimanufacturerErrorColor="";
    GLOBAL.cibusinessownerErrorColor="";
    GLOBAL.citechownerErrorColor="";
    GLOBAL.ciSubStatusErrorColor="";
    GLOBAL.categoryErrorColor="";
    GLOBAL.subCategoryErrorColor="";
  }

  componentDidMount() {
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
    this.context.newTabPrevent();
	}
	componentDidUpdate() {
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
    this.context.newTabPrevent();
	}

  submit(values) {

    console.log('values - ', values);
    let obj = structuredClone(values?.createDataObj);
    let resultObj = {};
    resultObj['ci_name'] = obj['ciname'];
    resultObj['company_id'] = (obj['company']?.length > 0) ? obj['company'][0]['id'] : '';
    resultObj['company_name'] = (obj['company']?.length > 0) ? obj['company'][0]['label'] : '';
    resultObj['class_id'] = obj['ciclass'] || '';
    resultObj['class_name'] = obj['ciclass_Name'] || '';
    resultObj['type'] = obj['citype'] || '';
    resultObj['category_id'] = obj['category'] || '';
    resultObj['category_name'] = obj['category_Name'] || '';
    resultObj['sub_category_id'] = obj['subCategory'] || '';
    resultObj['sub_category_name'] = obj['subCategory_Name'] || '';
    resultObj['ci_id'] = obj['ciId'] || '';
    resultObj['asset_tag'] = obj['assettag'] || '';
    resultObj['serial_number'] = obj['serial_number'] || '';
    resultObj['status'] = obj['status'] || '';
    resultObj['status_name'] = obj['status_Name'] || '';
    resultObj['sub_status'] = obj['subStatus'] || '';
    resultObj['sub_status_name'] = obj['subStatus_Name'] || '';
    resultObj['allocation_date'] = obj['allocationDate'] || ''; // not present
    resultObj['description'] = obj['description'] || '';
    resultObj['manufacturer'] = obj['manufacturer'] || '';
    resultObj['model_name'] = obj['modelName'] || '';
    resultObj['model_number'] = obj['modelNumber'] || ''; // not present
    resultObj['metallic'] = obj['businessCriticality'] || '';
    resultObj['warranty_expiration_date'] = obj['warrantyExpiration'] || '';
    resultObj['compliance'] = obj['complianceRequirement'] || '';
    resultObj['location_id'] = (obj['location']?.length > 0) ? obj['location'][0]['id'] : '';
    resultObj['location_name'] = (obj['location']?.length > 0) ? obj['location'][0]['label'] : '';
    resultObj['environment'] = obj['environment'] || '';
    resultObj['keyword'] = obj['keywords'] || '';
    resultObj['is_external'] = (obj['isExternal'] && obj['isExternal'] == 'True') ? true : false;
    resultObj['deployement_date'] = obj['deploymentDate'] || '';
    resultObj['decommission_date'] = obj['decommissionDate'] || '';
    resultObj['support_end_date'] = obj['endOfSupport'] || '';
    resultObj['first_deployed_date'] = obj['firstDeployedDate'] || '';
    resultObj['installation_date'] = obj['installedDate'] || '';
    resultObj['dataset'] = obj['dataset'] || '';
    resultObj['business_owner_id'] = (obj['businessOwner']?.length > 0) ? obj['businessOwner'][0]['id'] : '';
    resultObj['business_owner_name'] = (obj['businessOwner']?.length > 0) ? obj['businessOwner'][0]['value'] : '';
    resultObj['technical_owner_id'] = (obj['technicalOwner']?.length > 0) ? obj['technicalOwner'][0]['id'] : '';
    resultObj['technical_owner_name'] = (obj['technicalOwner']?.length > 0) ? obj['technicalOwner'][0]['value'] : '';
    resultObj['owner_id'] = (obj['assignedTo']?.length > 0) ? obj['assignedTo'][0]['id'] : '';
    resultObj['owner_name'] = (obj['assignedTo']?.length > 0) ? obj['assignedTo'][0]['value'] : '';
    resultObj['assigned_date'] = obj['assignedDate'] || ''; // not present
    resultObj['department_id'] = (obj['department']?.length > 0) ? obj['department'][0]['id'] : '';
    resultObj['department_name'] = (obj['department']?.length > 0) ? obj['department'][0]['label'] : '';
    resultObj['change_group_id'] = (obj['changeGroup']?.length > 0) ? obj['changeGroup'][0]['id'] : '';
    resultObj['change_group_name'] = (obj['changeGroup']?.length > 0) ? obj['changeGroup'][0]['label'] : '';
    resultObj['availaibility_percentage'] = obj['availabilityPercentage'] || '';
    resultObj['support_company_id'] = (obj['supportCompany']?.length > 0) ? obj['supportCompany'][0]['id'] : '';
    resultObj['support_company_name'] = (obj['supportCompany']?.length > 0) ? obj['supportCompany'][0]['label'] : '';
    resultObj['group_id'] = (obj['supportGroup']?.length > 0) ? obj['supportGroup'][0]['id'] : '';
    resultObj['group_name'] = (obj['supportGroup']?.length > 0) ? obj['supportGroup'][0]['label'] : '';
    resultObj['owned_by_id'] = (obj['ownedBy']?.length > 0) ? obj['ownedBy'][0]['id'] : '';
    resultObj['owned_by'] = (obj['ownedBy']?.length > 0) ? obj['ownedBy'][0]['label'] : '';
    resultObj['ownership_type_id'] = obj['ownershipType'] || '';
    resultObj['order_date'] = obj['orderedDate'] || '';
    resultObj['order_received_date'] = obj['orderReceived'] || '';
    resultObj['acquisition_cost'] = obj['acquisitionCost'] || '';
    resultObj['cost_center_id'] = (obj['costCenter']?.length > 0) ? obj['costCenter'][0]['id'] : '';
    resultObj['cost_center'] = (obj['costCenter']?.length > 0) ? obj['costCenter'][0]['label'] : '';
    resultObj['purchase_order_date'] = obj['poDate'] || '';
    resultObj['purchase_order_number'] = obj['poNumber'] || '';
    resultObj['invoice_number'] = obj['invoiceNumber'] || '';
    resultObj['cost_currency_id'] = obj['costCurrency'] || '';
    resultObj['general_ledger_account'] = obj['glAccount'] || '';
    resultObj['net_book_value'] = obj['netBookValue'] || '';
    resultObj['depreciation_charged_id'] = obj['depreciationCharged'] || '';
    resultObj['depreciation_schedule'] = obj['depreciationScheduled'] || '';
    resultObj['vendor_id'] = (obj['vendor']?.length > 0) ? obj['vendor'][0]['id'] : '';
    resultObj['vendor'] = (obj['vendor']?.length > 0) ? obj['vendor'][0]['label'] : '';
    resultObj['currency_id'] = obj['currency'] || ''; // not present
    resultObj['charge_category_id'] = obj['chargeCategory'] || '';
    resultObj['charge_start_date'] = obj['chargeStartDate'] || '';
    resultObj['charge_end_date'] = obj['chargeEndDate'] || '';
    resultObj['charge_type_id'] = obj['chargeType'] || '';
    resultObj['charge_frequency'] = obj['chargeFrequency'] || '';
    resultObj['charge_currency_amount'] = obj['chargeCurrencyAmount'] || ''; // not present
    resultObj['charge_to_cc_id'] = (obj['chargeToCC']?.length > 0) ? obj['chargeToCC'][0]['id'] : '';
    resultObj['charge_to_cc_name'] = (obj['chargeToCC']?.length > 0) ? obj['chargeToCC'][0]['label'] : '';
    resultObj['charge_to_company_id'] = (obj['chargeToCompany']?.length > 0) ? obj['chargeToCompany'][0]['id'] : '';
    resultObj['charge_to_company_name'] = (obj['chargeToCompany']?.length > 0) ? obj['chargeToCompany'][0]['label'] : '';
    resultObj['charge_to_project_id'] = (obj['chargeToProject']?.length > 0) ? obj['chargeToProject'][0]['id'] : '';
    resultObj['charge_to_project_name'] = (obj['chargeToProject']?.length > 0) ? obj['chargeToProject'][0]['label'] : '';
    resultObj['actual_retun_date'] = obj['actualReturnDate'] || '';
    resultObj['scheduled_return_date'] = obj['scheduledReturnDate'] || '';
    resultObj['vendor_seller_id'] = (obj['vendorLessor']?.length > 0) ? obj['vendorLessor'][0]['id'] : '';
    resultObj['vendor_seller'] = (obj['vendorLessor']?.length > 0) ? obj['vendorLessor'][0]['label'] : '';
    resultObj['host_name'] = obj['hostName'] || '';
    resultObj['ip_address'] = obj['ipAddress'] || '';
    resultObj['mac_address'] = obj['macAddress'] || '';
    resultObj['backup_retention'] = obj['backupRetention'] || '';
    resultObj['backup_schedule'] = obj['backupScheduled'] || '';
    resultObj['monitored'] = obj['monitor'] || '';
    resultObj['fqdn'] = obj['fqdn'] || ''; // not present
    resultObj['allocated_capacity'] = obj['allocatedCapacity'] || '';
    resultObj['maximum_capacity'] = obj['maximumCapacity'] || '';
    resultObj['capacity_unit'] = obj['capacityUnit'] || '';
    resultObj['capacity_utilization'] = obj['capacityUtilization'] || '';
    resultObj['capacity_thresholds'] = obj['capacityThresholds'] || '';
    resultObj['disaster_recovery_enabled'] = obj['drEnabled'] || false;
    resultObj['disaster_recovery_location_id'] = (obj['drLocation']?.length > 0) ? obj['drLocation'][0]['id'] : '';
    resultObj['disaster_recovery_location_name'] = (obj['drLocation']?.length > 0) ? obj['drLocation'][0]['label'] : '';
    resultObj['disaster_tier_id'] = obj['drTier'] || '';
    resultObj['recovery_point_objective'] = obj['recoveryPointObjective'] || '';
    resultObj['recovery_time_objective'] = obj['recoveryTimeObjective'] || '';
    resultObj['discovery_source_name'] = obj['discoverySource'] || '';
    resultObj['first_discovered_at'] = obj['firstDiscovered'] || '';
    resultObj['most_recent_discovery_at'] = obj['mostRecentDiscovery'] || '';
    resultObj['patch_hold'] = obj['patchHold'] || '';
    resultObj['patch_hold_reason'] = obj['patchHoldReason'] || '';
    resultObj['patch_schedule'] = obj['patchingSchedule'] || '';
    resultObj['patch_level'] = obj['releasePatchLevel'] || '';

    console.log('resultObj - ', resultObj);


    // let catid="";
    let locid="";
    // if(values.subCategory=="")
    // catid="0";
    // else
    //   catid=values.subCategory;

    if(values.location=="")
    locid="0";
    else
    locid=values.location;

    let groupid ="";
    if (values.group == "") groupid = "0";
    else groupid  = values.group;

    let supportCompanyId="";
    if (values.supcompany == "") supportCompanyId = "0";
    else supportCompanyId = values.supcompany;


    let payload = {
      //======================
      "ci_name":values.ciname,
      "class_id":values.ciclass,
      // "company_id":values.company,
      // "company_name":values.companyName,
      "status":values.status,
      "sub_status":values.subStatus,
      "category_id":values.subCategory,
      //"sub_category_id":values.subCategory,
      "sub_category_id":0,
      "group_id":groupid,
      "group_name":values.groupName ? values.groupName : '',
      "location_id":locid,
      "location_name":values.locationName ? values.locationName : '',
      //  "location_id":0,
      //  "location_name":"null",
      "metallic":values.metallic,
      "environment":values.environment,
      "manufacturer":"0",
      "dataset":"5",
      "description":values.description,
      "created_by":"",
      "business_owner_id":values.businessowner,
      "business_owner_name":values.businessownerName,
      "technical_owner_id":values.techowner,
      "technical_owner_name":values.techownerName,
      "owner_id":values.assignedto ? values.assignedto : 0,
      "owner_name":values.assignedtoName ? values.assignedtoName : '',
      "support_company_id":supportCompanyId,
      "support_company_name":values.supcompanyName ? values.supcompanyName : '',
      "asset_tag":values.assettag,
      "class_name": ( GLOBAL.cmdbCreateSelectedText.class_name) ? GLOBAL.cmdbCreateSelectedText.class_name: "" ,
      "category_name":GLOBAL.cmdbCreateSelectedText.category_name ? GLOBAL.cmdbCreateSelectedText.category_name : "",
      "type_name":GLOBAL.cmdbCreateSelectedText.type_name ? GLOBAL.cmdbCreateSelectedText.type_name : "",
      "environment_name":GLOBAL.cmdbCreateSelectedText.environment_name ? GLOBAL.cmdbCreateSelectedText.environment_name: "",
      "status_name":GLOBAL.cmdbCreateSelectedText.status_name ? GLOBAL.cmdbCreateSelectedText.status_name :"",
      "sub_category_name":GLOBAL.cmdbCreateSelectedText.sub_category_name ?  GLOBAL.cmdbCreateSelectedText.sub_category_name : ":",
      "sub_status_name":GLOBAL.cmdbCreateSelectedText.sub_status_name ? GLOBAL.cmdbCreateSelectedText.sub_status_name : "",
      "is_external":values.is_external !='' ? values.is_external.toLowerCase(): "false",
      "dataset_name": "5",
      "business_owner_email":GLOBAL.cmdbCreateSelectedText.business_owner_email ? GLOBAL.cmdbCreateSelectedText.business_owner_email : "",
      "technical_owner_email":GLOBAL.cmdbCreateSelectedText.technical_owner_email ? GLOBAL.cmdbCreateSelectedText.business_owner_email : "",
      "owner_email":GLOBAL.cmdbCreateSelectedText.owner_email ? GLOBAL.cmdbCreateSelectedText.owner_email : "",
      "metallic_name":GLOBAL.cmdbCreateSelectedText.metallic_name ? GLOBAL.cmdbCreateSelectedText.metallic_name : "",
      "type": values.citype,
      "serial_number": values.serial_number
    };
    // let finalPayload = {...payload, ...resultObj};
    let finalPayload = {...resultObj};

    // return false;

    // alert("submit"+values.ciname)
    let ref = this;
    return sleep(1000) // simulate server latency

      .then(() => {
        //alert(supportPgroupNameValue.length());

        console.log("VALUES",values);
        console.log(JSON.stringify(values, null, 2));
        if(`${JSON.stringify(values, null, 2)}`=="{}"){
          throw new SubmissionError({ _error: 'The form has missing required fields, please revalidate the highlighted fields and update the form' })
        }
        else if (!finalPayload.ci_name || !finalPayload.class_id || finalPayload.class_id == '0' || !finalPayload.status || finalPayload.status == "0" || !finalPayload.sub_status || finalPayload.sub_status == "0" || !finalPayload.company_id || !finalPayload.business_owner_id || !finalPayload.technical_owner_id || !finalPayload.type || !finalPayload.category_id || finalPayload.category_id == "0" || !finalPayload.sub_category_id || finalPayload.sub_category_id == "0" || !finalPayload.allocation_date) {
          if(!finalPayload.ci_name){
            GLOBAL.ci_name_error="error";
          }else{
            GLOBAL.ci_name_error="";
          }

          if(!finalPayload.company_id){
            GLOBAL.ci_company_error="error";
          }else{
            GLOBAL.ci_company_error="";
          }

          if(!finalPayload.class_id || finalPayload.class_id == "0"){
            GLOBAL.ci_class_error="error";
          }else{
            GLOBAL.ci_class_error="";
          }

          if(!finalPayload.type){
            GLOBAL.ci_type_error = 'error';
          }else{
            GLOBAL.ci_type_error = '';
          }

          if(!finalPayload.category_id || finalPayload.category_id == "0"){
            GLOBAL.ci_category_error="error";
          }else{
            GLOBAL.ci_category_error="";
          }

          if(!finalPayload.sub_category_id == "" || finalPayload.sub_category_id == "0" ){
            GLOBAL.ci_sub_category_error="error";
          }else{
            GLOBAL.ci_sub_category_error="";
          }

          if(!finalPayload.status || finalPayload.status == "0"){
            GLOBAL.ci_status_error="error";
          }else{
            GLOBAL.ci_status_error="";
          }

          if(!finalPayload.sub_status || finalPayload.sub_status == "0"){
            GLOBAL.ci_sub_status_error="error";
          }else{
            GLOBAL.ci_sub_status_error="";
          }


          if(!finalPayload.allocation_date){
            GLOBAL.ci_allocation_date_error = 'error';
          }
          else{
            GLOBAL.ci_allocation_date_error = '';
          }


          // if(finalPayload.manufacturer == "" || finalPayload.manufacturer == "0"){
          //   GLOBAL.cimanufacturerErrorColor="error";
          //   GLOBAL.CMDBErrorObj['ciclass'] = 'error';
          // }else{
          //   GLOBAL.cimanufacturerErrorColor="";
          //   GLOBAL.CMDBErrorObj['ciclass'] = '';
          // }

          if(!finalPayload.business_owner_id){
            GLOBAL.ci_business_owner_error="error";
          }else{
            GLOBAL.ci_business_owner_error="";
          }

          if(!finalPayload.technical_owner_id){
            GLOBAL.ci_technical_owner_error="error";
          }else{
            GLOBAL.ci_technical_owner_error="";
          }

          throw new SubmissionError({ _error: this.props.translator['The form has missing required fields, please revalidate the highlighted fields and update the form !'] });
        }

        else{
          


          //  console.log("catid::"+catid+"locid::"+locid);
          this.setState({showSubmit:false});
          
          axios.post(GLOBAL.postCiDataURL, finalPayload)
          .then(function (response) {
            // console.log("response", response)
            // console.log("response", response)
            ref.props.getTimelineData(response.data[0].CI_ID);
            if (response.data[0]?.upsert_status == false || response.data[0]?.upsert_status == "false") {
              Swal.fire(response.data[0]?.ci_name);
            } else { 
              navigationHooks.navigate("/editcmdb/" + response.data[0]?.CI_ID); 
            }
          })
          .catch(function (error) {
            console.log(error);
            //alert("H1"+error);
            //window.location="/BreakFixEditError";
          });
        }
        //===================
      })
  }


   render() {
     let { handleSubmit, pristine, reset, submitting} = this.props
      return (
		<Row className="margin-b-15">
			<Col md={6} sm={6} xs={5}>
				<h1 bsClass="" className="sPageHeading1">{this.props.translator['Asset/Config Board']}</h1>
			</Col>
			<Col md={6} sm={6} xs={7}>
      <div className="status myStatus">
            <Nav bsPrefix=" " as={"ul"} bsClass="">
            <Nav.Item bsPrefix=" " as={"li"}>
              <Link to="javascript:void(0)"
                  bsPrefix=" "
                  className="saveBtnStyle ctrlKeyPrevent"
                  title={this.props.translator["Save"]}
                  onClick={handleSubmit(this.submit)}
                  disabled={this.state.disabledSave}
                  eventKey={1}
                  ref={e => this.saveObjRef = e}
                >
                  <span> {this.state.disabledSave ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/>}
							      </span>
                </Link>
              </Nav.Item>
					{/* {this.state.showSubmit==true?<Nav.Item title={this.props.translator['Save']} onClick={handleSubmit(this.submit)} eventKey={1}><span className="sAction bgBtn15 save"></span></Nav.Item>:<Nav.Item title={this.props.translator['Save']}  eventKey={1}><span className="sAction bgBtn15 save"></span></Nav.Item>} */}
					{/* <LinkContainer to="/cmdblist"><Nav.Item title={this.props.translator['Close']} onClick={this.emptyGlobals} eventKey={2}><span className="sAction bgBtn17 bclose"></span></Nav.Item></LinkContainer> */}
          <Nav.Item bsPrefix=" " as={"li"}>
                <Link
                  to="/cmdblist"
                  bsPrefix=" "
                  title={this.props.translator["Close"]}
                  onClick={this.emptyGlobals}
                  eventKey={2}
                  className="ctrlKeyPrevent"
                  ref={e => this.closeButton = e}
                >
                  <IoClose/>
                </Link>
              </Nav.Item>
        </Nav>
			  </div>
			<div className="status myStatusGap border-r margin-r-10 padding-r-10">
          <Nav bsPrefix=" " as={"ul"} bsClass="">
                <Nav.Item bsPrefix=" " as={"li"}>
                  <Nav.Link bsPrefix=" "
                  bsStyle="primary"
                  title={this.props.translator['Process Workflow']}
                  onClick={() => { this.props.changeActionView('assetgraph')}}
                  eventKey={4}
                  >
                  <LuSearch/><LuBarChart className='processBarIcn'/>
                </Nav.Link>
              </Nav.Item>
					{/*  <span className="sAction bgBtn17 chart"></span></Nav.Item> */}
				</Nav>
			  </div>
			</Col>
		</Row>
      );
   }
}

CmdbCreateHeader = reduxForm({
	form: 'CmdbCreateForm',
	destroyOnUnmount: true

})(CmdbCreateHeader);



export default connect (null,{getTimelineData})(CmdbCreateHeader);
