
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import CommandCell from "./commandCell";

const EditableGrid = forwardRef((props, ref) => {
    const [data, setData] = useState(props.data);

    useImperativeHandle(ref, () => {
        return {
            addRecord
        }
    })

    const addRecord = () => {
        setData([{ inEdit: true, editable: true }, ...data]);
    }

    useEffect(() => {
        setData(props.data);
    }, [props.data])

    const itemChange = (event) => {
        const newData = data.map((item) =>
            item.id === event.dataItem.id
                ? {
                    ...item,
                    [event.field || ""]: event.value,
                }
                : item
        );
        setData(newData);
    };

    const edit = (dataItem) => {
        setData(
            data.map((item) =>
                item.id === dataItem.id
                    ? {
                        ...item,
                        inEdit: true,
                    }
                    : item
            )
        );
    };

    const discard = () => {
        const newData = [...data];
        newData.splice(0, 1);
        setData(newData);
        props.setDisableAdd(false);
    };

    const cancel = (dataItem) => {
        const originalItem = props.data.find(
            (p) => p.id === dataItem.id
        );
        const newData = data.map((item) =>
            item.id === originalItem.id ? originalItem : item
        );
        setData(newData);
    };

    const commandCellProps = {
        add: props.createRecord,
        edit: edit,
        discard: discard,
        cancel: cancel,
        update: props.updateRecord,
        delete: props.deleteRecord
    };



    return (
        <Grid data={data} onItemChange={itemChange} editField="inEdit">
            {
                props.headers.map((item, index) => (
                    <GridColumn key={index} field={item.field} title={item.title} editor={item.editor} cell={item.cell} editable={item.editable}></GridColumn>
                ))
            }
            <GridColumn
                title="Actions"
                cell={(props) => <CommandCell {...props}{...commandCellProps}></CommandCell>}
            ></GridColumn>
        </Grid>
    )
})

export default EditableGrid;