/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

const RadioButtonComponent = ({handleOnChange,value,fieldData,btnData}) => {
    const [selectedValue, setSelectedValue] = useState(btnData[0].id);
    const [fieldName, setFieldName] = useState('');

    const handleValueChange = (value) => { 
      setSelectedValue(value);
      handleOnChange(value);
    }

    const handleChange = (event) => {
        handleValueChange(event.target.value);
    };

    useEffect(() => {
       setFieldName(fieldData.name);
       if(value != '' && value != null && value != undefined){
         handleValueChange(value);
         return;
       }
       handleValueChange(btnData[0].id);
    }, []);

    useEffect(() => {
      if(fieldName !== fieldData.name && (value === '' || value?.length === 0)){
        handleValueChange(btnData[0].id);
        setFieldName(fieldData.name);
      }
    }, [fieldData]);

    return <Form.Group className='d-flex align-items-center'>
            {btnData.map(option => (
                    <Form.Check type="radio" 
                                label={option.label} 
                                value={option.id} 
                                checked={selectedValue == option.id} 
                                onChange={handleChange} />
            ))}
            </Form.Group>
}

export default RadioButtonComponent;
