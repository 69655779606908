
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import moment from 'moment';
import { Col, Row, Nav } from "react-bootstrap";
import axios from "axios";
import CheckoutPISection from "./CheckoutPISection.js";
import { loadCartCount } from "../../../../actions/common/cartCountAction.js";
import { formValueSelector, reduxForm, change } from "redux-form";
import LoadingIcons from 'react-loading-icons';
import PindCategories from '../../../common/header/pind-categories.js';
import Cookies from 'universal-cookie';
import InputNumber from "_Commons/inputNumber.js";
import "_Css/consumer/_checkout.scss";

const cookies = new Cookies();
let homepagelocation = cookies.get('gph');
if (homepagelocation) {
  homepagelocation = homepagelocation.replace('s:', '');
}
if (homepagelocation) {
  homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
}
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation !== '/home')
  homepagelocation = '/' + homepagelocation;

  let dateformat = cookies.get("gph");
  if (dateformat) dateformat = dateformat.replace("s:", "");
  if (dateformat)
    dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
  dateformat = dateformat.split("~");
  dateformat = dateformat[2];
  dateformat = dateformat.slice(0, 10).toUpperCase() + " HH:mm:ss"

  let language = cookies.get("gph");
  if (language) language = language.replace("s:", "");
  if (language)
  language = language.substring(0, language.lastIndexOf("."));
  language = language.split("~");
  language = language[20];

  let loggedInUser = cookies.get("gph");
  if (loggedInUser) loggedInUser = loggedInUser.replace("s:", "");
  if (loggedInUser)
  loggedInUser = loggedInUser.substring(0, loggedInUser.lastIndexOf("."));
  loggedInUser = loggedInUser.split("~");
  loggedInUser = loggedInUser[43];

let timezone = cookies.get('gph');
if (timezone) timezone = timezone.replace('s:', '');
if (timezone) timezone = timezone.substring(0, timezone.lastIndexOf('.'));
timezone = timezone.split("~");
timezone = timezone[3];
import {
  getOfferingsInCart,
  resetCartQuestionsState,
  resetOrderingInfoForm,
  haveMandatoryQuestionFlagForService,
  getBulkPurchaseQuestions,
  resetUrgencyImpactValues
} from "../../../../actions/cart/addToCartActions.js";
import { connect } from "react-redux";
import _ from "lodash";
import { loadRequestorAndServiceData } from "../../../../actions/task/TaskDataAction.js";
import {
  combineValidators,
  composeValidators,
  isRequired,
  createValidator
} from "revalidate";
import swal from "sweetalert";

import { fetchOfferingDetails } from '../../../../actions/offeringDetails/offeringDetailsActions.js';
import { navigationHooks } from "../../../../helpers/NavigationHook.js";

import { GLOBAL } from '_Globals';
import OrderingInformation from "../rightSide/OrderingInformation.js";
import {TbFileInfo} from "react-icons/tb";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import View from "../../../uiBuilder/renderer/View.js";

let piQuestionsMandatError = "";
let haveTypeaheadquestion = "";

let consumerCompanyIDShared;
let consumerCompanyNameShared;

const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

const isQuantityValidation = (curr) =>
  createValidator(
    (message) => (value) => {
      if (value) {
        if (value < curr.minValue) {
          message = "Min Quantity " + curr.minValue;
        } else {
          if (value > curr.maxValue) {
            message = "Max Quantity " + curr.maxValue;
          } else {
            if (value % 1 !== 0) {
              message = "Invalid value";
            }
          }
        }
        return message;
      }
    },
    ""
  );

const isSpinnerValidation = createValidator(
  (message) => (value) => {
    let minMaxValues = message.split("~");
    let minValue = minMaxValues[0];
    let maxValue = minMaxValues[1];
    let questionType = minMaxValues[2];

    if (questionType == "mandatory") {
      if (!value) {
        return " ";
      }

      if ((value && parseInt(value, 10) < minValue) || parseInt(value, 10) > maxValue) {
        return `Value must not be less than ${minValue} or greater than ${maxValue}`;
      }
    } else {
      if ((value && parseInt(value, 10) < minValue) || parseInt(value, 10) > maxValue) {
        return `Value must not be less than ${minValue} or greater than ${maxValue}`;
      }
    }
  },
  ""
);

const lookupTextValidation = createValidator(
  (charLength) => (value) => {
    if (!value) {
      return " ";
    }
    if (value && value.length < parseInt(charLength, 10)) {
      return `minimum ${charLength} characters required`;
    }
  },
  ""
);

const validate = (values, ownProps) => {
  const bulkPurchaseQuestionErrors = {};
  for (let field in values) {
    if (values.hasOwnProperty(field)) {
    /*validation check for bulk purchase type of questions
     * these questions have name starting with "bulkPurchase_" already
     * configured in mapStateToProps.
     * */
    let bulkPurchaseQuestionField = field.substr(0, field.indexOf("_"));
    if (
      bulkPurchaseQuestionField &&
      bulkPurchaseQuestionField === "bulkPurchase"
    ) {
      const question = _.find(ownProps.fields, { name: field });
      if (question) {
        const questionErrors = [];
        let columns = question.columns;
        values[field].forEach((column, column_index) => {
          const _column_errors = {};
          columns.forEach((field_name) => {
            if (question.questionType === "mandatory") {
              if (!column || !column[field_name]) {
                _column_errors[field_name] = isRequired({ message: " " });
                questionErrors[column_index] = _column_errors;
              }
              if (
                column &&
                column[field_name] &&
                column[field_name].replace(/\s/g, "") === ""
              ) {
                _column_errors[field_name] = isRequired({ message: " " });
                questionErrors[column_index] = _column_errors;
              }
            }
            if (question.questionType === "optional") {
              if (
                column &&
                column[field_name] &&
                column[field_name].replace(/\s/g, "") === ""
              ) {
                delete column[field_name];
              }
            }
          });
        });
        /*if any error found in bulkPurchase question tables add it to
         * bulkPurchaseQuestionErrors Array and then append it to combineValidators.
         * */
        if (questionErrors.length) {
          bulkPurchaseQuestionErrors[field] = questionErrors;
        }
      }
    } else {
      /*if question is not of type bulkPurchase and it's a normal question
       * then below validation works for readOnly and other questions by deleting
       * specific question. Validation for these questions happens as it is marked as required field
       * in composeValidators.
       * */
      if (values[field].hasOwnProperty("answer")) {
        if (
          values[field].answer === undefined ||
          values[field].answer === "" ||
          !_.some(ownProps.fields, { name: values[field].originalQuestionName })
        ) {
          delete values[field];
        }
      } else {
        if (!_.some(ownProps.fields, { name: field })) {
          delete values[field];
        } else {
          if (
            Array.isArray(values[field]) &&
            values[field].length === 0 &&
            !field.includes("bulkPurchase_")
          ) {
            delete values[field];
          }
        }
      }
    }
  }
}

  /*Validation for static field i.e Ordering Information section*/
  const staticValidations = {
    requester: composeValidators(isRequired({ message: "Required" }))(),
    shippingLocation: composeValidators(isRequired({ message: "Required" }))(),
    recipient: composeValidators(isRequired({ message: "Required" }))(),
    ownCWLShippingLocationId: composeValidators(
      isRequired({ message: "Required" })
    )(),
  };
  /*Validator applied for Pi Section*/
  const dynamicValidations = ownProps.dynamicFields.reduce((accu, curr) => {
    if (curr.responseType == "Typeahead") {
      haveTypeaheadquestion = false;
    }

    if (curr.responseType == "Numeric Spinner") {
      accu[curr.name] = composeValidators(
        isSpinnerValidation({
          message:
            curr.minValue + "~" + curr.maxValue + "~" + curr.questionType,
        })
      )(curr);
    }

    if (
      curr.responseType !== "Display Text" &&
      curr.questionType === "mandatory" &&
      curr.fieldType !== "bulkPurchase"
    ) {
      if (curr.fieldType === "itemQuantity") {
        accu[curr.name] = composeValidators(
          isQuantityValidation(curr)({ message: "" })
        )();
      } else {
        if (curr.responseType === "Lookup Textbox") {
          accu[curr.name] = composeValidators(
            lookupTextValidation({ message: curr.callApiAfter })
          )(curr);
        } else if (curr.responseType == "Numeric Spinner") {
          accu[curr.name] = composeValidators(
            isSpinnerValidation({
              message:
                curr.minValue + "~" + curr.maxValue + "~" + curr.questionType,
            })
          )(curr);
        } else {
          accu[curr.name] = isRequired({ message: "Required" });
        }
      }
    } else {
      // if (curr.fieldType === "disclaimer") {
      //   accu[curr.name] = isRequired({ message: "Required" });
      // }
    }

    /*Combining validators for Pi Section, Ordering Information Section and Bulk Purchase Questions
     * for page level Error
     * */

    if (
      _.isEmpty(
        combineValidators(
          Object.assign(
            {},
            staticValidations,
            dynamicValidations,
            bulkPurchaseQuestionErrors
          )
        )(values)
      ) === false
    ) {
      // if (haveTypeaheadquestion) {
      //   piQuestionsMandatError =
      //     "Please fill mandatory question and select values from suggested fields only";
      // } else {
      //   piQuestionsMandatError =
      //     ownProps.tr["Please fill the mandatory fields"];
      // }
    } else {
      piQuestionsMandatError = "";
    }
    return accu;
  }, {});

  /*Combining validators for Pi Section, Ordering Information Section and Bulk Purchase Questions
   * for page level Error
   * */
  if (
    _.isEmpty(
      combineValidators(
        Object.assign({}, dynamicValidations, bulkPurchaseQuestionErrors)
      )(values)
    ) === false
  ) {
    // if (haveTypeaheadquestion) {
    //   piQuestionsMandatError =
    //     "Please fill mandatory question and select values from suggested fields only";
    // }
    // else {
    //   piQuestionsMandatError =
    //     ownProps.tr["Please fill all the mandatory questions"];
    // }
  } else {
    piQuestionsMandatError = "";
  }
  /*Combining validators for Pi Section, Ordering Information Section and Bulk Purchase Questions
   * to show field level Errors*/
  return combineValidators(
    Object.assign(
      {},
      staticValidations,
      dynamicValidations,
      bulkPurchaseQuestionErrors
    )
  )(values);
};

let OrderNow = class OrderNow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenu: "9",
      showHideAlfy: false,
      showHideRightNav: false,
      offeringsForOrder: [],
      orderTotal: 0.0,
      selectedQuestion: [],
      deliverOptionsForOfferings: [],
      selectedComponentForOffering: [],
      cartListMessage: "",
      cartActionsMessage: "",
      attachments: [],
      disableOrderNowButton: false,
      isInformationProvided: false,
      hasValidationErrors: false,
      mandatoryFieldsError: false,
      isShippingLocationTypeSelected: false,
      isShippingLocationAddressSelected: false,
      checkPiQuestionsMandatError: "",
      loaderStatus: true,
      isOnBehalfUserValid: false,
      isOtherDeliveryLocationValid: false,
      allServicePrices: {
        itemPriceAndQuantity: [],
        grandTotal: function () {
          let totalPrice = 0;
          this.itemPriceAndQuantity.forEach((service) => {
            totalPrice += service.serviceTotalPrice;
          });
          return totalPrice;
        },
      },
      totalItemQuantity:0,
      seachDiscription: '',
      disclaimerCheckbox: [],
      isSelfSelected: false,
      newOwnerLocId: '',
      newOwnerLocName: '',
      piSectionFormValues: {},
      piSectionFormErrors: {},
      piSectionTableFormValues: {},
      piSectionTableFormErrors: {},
      currentLocationId: '',
      LoggedInUserLocationId: '',
      LoggedInUserLocationName: '',
      loggedINuserID:'',
      loggedINuserName:'',
      loggedINuserCompanyID:'',
      loggedINuserCompanyName:'',
      loggedInUserFullName: '',
      serviceCriticality: '',
      urgencyValues: '',
      isRightEditPanel: true,
    };
    this.showHideAlfy = this.showHideAlfy.bind(this);
    this.showHideRightNav = this.showHideRightNav.bind(this);
    this.loadCart = this.loadCart.bind(this);
    this.placeOrder = this.placeOrder.bind(this);
    this.setDeliveryOptionsForOfferings = this.setDeliveryOptionsForOfferings.bind(
      this
    );
    this.changeCompMessage = this.changeCompMessage.bind(this);
    this.onSubmitClick = this.onSubmitClick.bind(this);
    this.updateServicePrice = this.updateServicePrice.bind(this);
    this.setIsOnBehalfUserValid = this.setIsOnBehalfUserValid.bind(this);
    this.setVipMember = this.setVipMember.bind(this);
    this.setIsOtherDeliveryLocationValid = this.setIsOtherDeliveryLocationValid.bind(
      this
    );
    this.renderKnowledgeArticles = this.renderKnowledgeArticles.bind(this);
    this.updateDisclaimer = this.updateDisclaimer.bind(this);
    this.setShippingLocationType = this.setShippingLocationType.bind(this);
    this.setShippingLocationAddress = this.setShippingLocationAddress.bind(this);
    this.setIsSelfSelected = this.setIsSelfSelected.bind(this);
    this.setConsumerCompany = this.setConsumerCompany.bind(this);
    this.getOptionsFromChild = this.getOptionsFromChild.bind(this);
    this.setPISectionFormValues = this.setPISectionFormValues.bind(this);
    this.setPISectionFormErrors = this.setPISectionFormErrors.bind(this);
    this.setPISectionTableFormValues = this.setPISectionTableFormValues.bind(this);
    this.setPISectionTableFormErrors = this.setPISectionTableFormErrors.bind(this);
    this.setServiceCriticalityandUrgency = this.setServiceCriticalityandUrgency.bind(this);
    this.rightEditPanel=this.rightEditPanel.bind(this);
    this.location = navigationHooks.location;
  }

  setPISectionFormValues(values) {
    this.setState({ piSectionFormValues: values });
  }
  rightEditPanel(value) {
		this.setState({ isRightEditPanel: value });
	}

  setPISectionFormErrors(errors) {
    let errObj = Object.assign({}, this.state.piSectionFormErrors, errors);
    this.setState({ piSectionFormErrors: errObj });
  }
  setPISectionTableFormValues(values) {
    this.setState({ piSectionTableFormValues: values });
  }

  setPISectionTableFormErrors(errors) {
    let errObj = Object.assign({}, this.state.piSectionTableFormErrors, errors);
    this.setState({ piSectionTableFormErrors: errObj });
  }

  getOptionsFromChild(locId, locName) {
    this.setState({ newOwnerLocId: locId, newOwnerLocName: locName });
  }

  setServiceCriticalityandUrgency(serviceCriticality, urgencyValues) {
    this.setState({ serviceCriticality: serviceCriticality, urgencyValues: urgencyValues })
  }

  componentWillMount() {
    this.loadCart();
  }
  componentDidMount(){
    let str={};
    str = JSON.stringify(str);
    axios.get('/api/users/myprofile',{headers:{'query':str}}).then((responseData)=>{
         this.setState({loggedINuserID: responseData.data.userId,
                        loggedINuserName: responseData.data.username,
                        loggedINuserCompanyID: responseData.data.companyId,
                        loggedINuserCompanyName: responseData.data.companyName,
                        loggedInUserFullName: responseData.data.fullname
                      });
    });
  }

  componentWillUnmount() {
    this.props.resetCartQuestionsState();
    this.props.resetUrgencyImpactValues();
    haveTypeaheadquestion = "";
    this.props.resetUrgencyImpactValues();
  }

  showHideAlfy(value) {
    this.setState({
      showHideAlfy: value,
    });
  }

  setIsSelfSelected(value) {
    this.setState({ isSelfSelected: value });
  }

  setConsumerCompany(isShared) {
    if (isShared === 'Yes') {
      if(this.state.isSelfSelected === true) {
        consumerCompanyIDShared = this.state.loggedINuserCompanyID;
        consumerCompanyNameShared = this.state.loggedINuserCompanyName;
      } else {
        if (GLOBAL.consumerCompanyId === '') {
          consumerCompanyIDShared = '';
        } else {
          consumerCompanyIDShared = GLOBAL.consumerCompanyId;
        }
        if (GLOBAL.consumerCompanyName === '') {
          consumerCompanyNameShared = '';
        } else {
          consumerCompanyNameShared = GLOBAL.consumerCompanyName;
        }
      }
    }
    else {
      consumerCompanyIDShared = this.state.loggedINuserCompanyID;
      consumerCompanyNameShared = this.state.loggedINuserCompanyName;
    }
  }

  setIsOnBehalfUserValid(isValid, onBehalfUserLocationId) {
    this.setState({ isOnBehalfUserValid: isValid, currentLocationId: onBehalfUserLocationId});
  }
  setVipMember() {
    let str = {};
    str.user_id = "";
    str = JSON.stringify(str);
    api.get("/api/userInfo", { headers: { query: str } }).then((response) => {
      GLOBAL.Vip = response.data.vip;
      this.props.dispatch(change('orderingForm', 'requesterLocationId', response.data.locationId));
      this.props.dispatch(change('orderingForm', 'requesterLocationName', response.data.locationName));
      this.setState({currentLocationId: response.data.locationId,
        LoggedInUserLocationId: response.data.locationId,
        LoggedInUserLocationName: response.data.locationName});
    });
  }
  setIsOtherDeliveryLocationValid(isValid) {
    this.setState({ isOtherDeliveryLocationValid: isValid });
  }

  onSubmitClick() {
    this.setState({
      checkPiQuestionsMandatError: piQuestionsMandatError,
    });
  }

  showHideRightNav(value) {
    this.setState({
      showHideRightNav: !this.state.showHideRightNav,
    });
  }
  renderKnowledgeArticles(searchString) {
    this.setState({ seachDiscription: searchString })
  }

  setDeliveryOptionsForOfferings(deliverOptions) {
    this.setState({
      deliverOptionsForOfferings: deliverOptions,
    });
  }

  changeCompMessage(message, msgFor) {
    if (msgFor === "list") {
      this.setState({
        cartListMessage: message,
      });
    }
    if (msgFor === "action") {
      this.setState({
        cartActionsMessage: message,
      });
    }
  }

  calculateTotal(offerings, selectedComponentForOffering) {
    let allServicePrices = this.state.allServicePrices;
    offerings.map((offering) => {
      let servicePrice = 0;
      let priceCalculatedForCoreOffering = false;
      offering.componentapiList.forEach((component) => {
        if (
          _.some(selectedComponentForOffering, [
            "componentId",
            component.componentId,
          ]) ||
          (_.some(selectedComponentForOffering, [
            "componentId",
            component.componentId.toString(),
          ]) &&
            offering.hidePrice === "false")
        ) {
          if (
            component.componentType === "Core" &&
            !priceCalculatedForCoreOffering
          ) {
            priceCalculatedForCoreOffering = true;
            servicePrice += parseFloat(component.actualPrice);
          } else {
            if (component.componentType === "Optional") {
              servicePrice += parseFloat(component.actualPrice);
            }
          }
        }
      });
      allServicePrices.itemPriceAndQuantity.push({
        serviceId: offering.offeringId,
        initialPrice: servicePrice,
        serviceTotalPrice: servicePrice * offering.minValue,
      });
    });
    this.setState({ allServicePrices });
  }

  updateServicePrice(serviceId, quantity) {
    let allServicePrices = this.state.allServicePrices;
    let service = _.find(allServicePrices.itemPriceAndQuantity, {
      serviceId: serviceId,
    });
    if (quantity === "") {
      service.serviceTotalPrice = service.initialPrice;
    } else {
      service.serviceTotalPrice = service.initialPrice * quantity;
    }
    this.setState({totalItemQuantity:quantity});
    this.setState({ allServicePrices });
  }

  loadCart() {
    let {
      selectedComponentForOffering,
      offeringId,
      offeringsForOrder,
    } = this.location.state;
    let apiParams = {};
    let selectedComponents = _.uniqBy(
      selectedComponentForOffering,
      "componentId"
    ).map((component) => {
      return component.componentId;
    });
    apiParams.userId = "";
    apiParams.offeringId = [];
    apiParams.offeringId.push(offeringId);
    apiParams.selectedComponents = selectedComponents;
    apiParams = JSON.stringify(apiParams);
    this.setState({
      offeringsForOrder: offeringsForOrder,
      selectedComponentForOffering: _.uniqBy(
        selectedComponentForOffering,
        "componentId"
      ),
    });
    if (offeringId) {
      const request = api.get(GLOBAL.getServicesDetailsAddedInCart, {
        headers: { query: apiParams },
      });
      request.then((response) => {
        this.setState({
          offeringsForOrder: _.uniqBy(response.data, "id"),
          loaderStatus: false,
        },()=>{this.calculateTotal(
          this.state.offeringsForOrder,
          this.state.selectedComponentForOffering
        );});
        this.props.haveMandatoryQuestionFlagForService(
          response.data,
          selectedComponents
        );
        this.props.getBulkPurchaseQuestions(offeringId, selectedComponents);

      });
    } else {
      this.setState({
        loaderStatus: false,
      });
      this.props.haveMandatoryQuestionFlagForService(
        offeringsForOrder,
        selectedComponents
      );
      this.props.getBulkPurchaseQuestions(
        offeringsForOrder,
        selectedComponents
      );
      this.calculateTotal(
        offeringsForOrder,
        _.uniqBy(selectedComponentForOffering, "componentId")
      );
    }
  }

  createQuestionObject(question, value, selectedQuestions) {
    let piQuestion = (question) => {
      let ques = {};
      ques.questionId = question.questionId;
      ques.question = question.questionText;
      ques.questionSequence = question.questionSequence;
      ques.questionApproval = question.questionApproval;
      ques.answer = question.answer;
      ques.responseType = question.responseType;
      ques.categoryId = question.categoryId;
      ques.categoryName = question.categoryName;
      ques.categorySequence = question.categorySequence;
      ques.componentNameSx = question.componentName;
      ques.componentIdSx = question.componentId;
      ques.target = question.target;
      ques.offeringId = question.serviceId;
      ques.attachedFile = question.attachedFile;
      ques.attachmentJSONObject = question.attachmentJSONObject;
      ques.requestorLocationID = question.requestorLocationID;
      ques.requestorLonationName = question.requestorLonationName;
      ques.includeInSummary = question.includeInSummary ? question.includeInSummary : "false";
      return ques;
    };

    let newQuestion = new piQuestion(question);
    switch (question.responseType) {
      case "Datetime":
        newQuestion.answer = value.format(dateformat);
        selectedQuestions.push(newQuestion);
        break;
      case "Calendar":
        newQuestion.answer = value.format(dateformat);
        selectedQuestions.push(newQuestion);
        break;
      case "Checkbox":
      case "Multiselect List":
        /*Multi select and Checkbox event are stored as Arrays if question type is one of them
         * then convert the answer to String format as per D2C payload requirement */
        newQuestion.answer = value.toString();
        selectedQuestions.push(newQuestion);
        break;
      case "Dropdown List":
        /* Setting dropdown question's answer key the label not the id */
        if (question.tildArray && question.tildArray.length > 0) {
          let dropResponseValuesArray = question.responseValue.split(";");
          let dropResponseValuesIDArray = question.responseValueId.split(";");
          let dropValueIndex = dropResponseValuesIDArray.indexOf(value);
          let dropValueText = dropResponseValuesArray[dropValueIndex];
          newQuestion.answer = dropValueText;
          selectedQuestions.push(newQuestion);
        } else {
          newQuestion.answer = value === "_no_data" ? "" : value;
          selectedQuestions.push(newQuestion);
        }

        break;
      case "Radio button":
        /* Setting Radio question's answer key the label not the id */
        if (question.tildArray && question.tildArray.length > 0) {
          let radioResponseValuesArray = question.responseValue.split(";");
          let radioResponseValuesIDArray = question.responseValueId.split(";");
          let radioValueIndex = radioResponseValuesIDArray.indexOf(value);
          let radioValueText = radioResponseValuesArray[radioValueIndex];
          newQuestion.answer = radioValueText;
          selectedQuestions.push(newQuestion);
        } else {
          newQuestion.answer = value === "_no_data" ? "" : value;
          selectedQuestions.push(newQuestion);
        }
        break;
      case "Dropzone":
      case "Attachment":
        /*In case of Attachment type question filed key is also stored in Form Values with main question as value of
         * the key so we need to find the main file from the question's answer key*/
        newQuestion.answer = value.answer;
        newQuestion.attachedFile = value.attachedFile;
        newQuestion.attachmentJSONObject = value.attachmentJSONObject;
        selectedQuestions.push(newQuestion);
        break;
      case "Table":
        if (Array.isArray(value) && value.length) {
          let alteredSequence = 11;

          value.forEach((item, i) => {

            if (!_.isEmpty(item)) {
              let piBulkQuestion = new piQuestion(question);
              let labels = question.columns;
              let answer = "";

              labels.forEach((key, index) => {

                let answerStr = item[key];
                if (answerStr) {

                  answer = answer + key + " : " + answerStr;

                  if (index !== labels.length - 1) {

                    /*unless it's not the last value of the object append ~ to answer string*/
                    answer = answer + " ~ ";

                  }
                  // } else {
                  //   /*if current answerStr is undefined then remove the ~ added earlier to the answer */
                  //   if (answer.includes("~")) {
                  //     answer = answer.substr(0, answer.lastIndexOf("~"));
                  //   }
                }




              });
              /*serviceId is coming as int in response need string as further checks are based on String format*/
              piBulkQuestion.offeringId = question.serviceId.toString();
              piBulkQuestion.questionId = question.qId;
              piBulkQuestion.question = question.questionText;
              piBulkQuestion.questionSequence =
                question.questionSequence + "." + alteredSequence;
              piBulkQuestion.answer = answer;
              alteredSequence = alteredSequence + 1;
              selectedQuestions.push(piBulkQuestion);
            }
          });
        }
        break;
      default:
        /*in rest of the cases simply pick the key's value that is coming from Form values object*/
        newQuestion.answer = value === "_no_data" ? "" : value;
        selectedQuestions.push(newQuestion);
    }
  }

  placeOrder(values, dispatch, props) {
    console.log("VALUEs====",values)
    console.log("loggedInUserID",loggedInUser)
    let newFormValues = Object.assign({}, values, this.state.piSectionFormValues);
    let tableQuesResponse = this.state.piSectionTableFormValues;
    let userLocationID = '';
    let userLocationName = '';
    let ref = this;
    let selectedQuestion = [];
    const offeringForOrder = this.state.offeringsForOrder;
    let isShipmentBased = _.some(this.state.offeringsForOrder, [
      "deliveryMode",
      "Shipment Based",
    ]);
    let isDeliveryModeOther = _.some(this.state.offeringsForOrder, [
      "deliveryMode",
      "Other",
    ]);
    let {
      selectedComponentForOffering,
      offeringId,
    } = this.location.state;
    if (
      values.requester === "onBehalf" &&
      (!values.requesterId || values.requesterId === "empty")
    ) {
      swal({
        text: this.props.tr["Please select user you are ordering for"],
        button: this.props.tr["OK"],
      });
      return false;
    }
    if (!isDeliveryModeOther) {
      if (!this.state.isOnBehalfUserValid) {
        swal({
          text: this.props.tr[
            "Invalid user, please select a valid user you are ordering for"
          ],
          button: this.props.tr["OK"],
        });
        return false;
      }
    }

    if (isShipmentBased) {
      if (values.shippingLocation !== "otherCWL" && (!this.state.isShippingLocationTypeSelected || !this.state.isShippingLocationAddressSelected || values.requesterLocationId == "0")
      ) {
          swal({
            text: this.props.tr["Please select shipping location you are ordering for"],
            button: this.props.tr["OK"],
          });
          return false;
        }
      if (
        values.recipient === "otherAddress" &&
        (!values.recipientId || values.recipientId === "")
      ) {
        swal({
          text: this.props.tr[
            "Please select delivery recipient you are ordering for"
          ],
          button: this.props.tr["OK"],
        });
        return false;
      }
      if (!this.state.isOtherDeliveryLocationValid) {
        swal({
          text: this.props.tr[
            "Invalid delivery recipient, please select a valid delivery recipient"
          ],
          button: this.props.tr["OK"],
        });
        return false;
      }
      if(values.requesterId == loggedInUser){
        swal({
          text: this.props.tr[
            "Could not place order, please try again"
          ],
          button: this.props.tr["OK"],
        });
        return false;
      }
    }

    let errorsObj = {};
    props.questionsArray.forEach(element => {
      if(element.questionType === 'mandatory') {
        let count = 0;
        Object.keys(newFormValues).forEach(questionResp => {
          if(element.name === questionResp) {
            count++;
            if(element.responseType === 'Numeric Spinner') {
              if(newFormValues[questionResp] > Number(element.maxValue) || newFormValues[questionResp] < Number(element.minValue)) {
                errorsObj = Object.assign({}, errorsObj, {[element.name]: 'InvalidInput'})
              }
            } else if(element.responseType === 'Calendar' || element.responseType === 'Datetime') {
              if(newFormValues[questionResp].format() == "Invalid date") {
                errorsObj = Object.assign({}, errorsObj, {[element.name]: 'InvalidInput'})
              }
            } else {
              count++;
            }
          }
        })
        if(count === 0) {
          errorsObj = Object.assign({}, errorsObj, {[element.name]: 'error'})
        }
      }
      else {
        Object.keys(newFormValues).forEach(questionResp => {
          if(element.name === questionResp) {
            if(element.responseType === 'Numeric Spinner') {
              if(newFormValues[questionResp] > Number(element.maxValue) || newFormValues[questionResp] < Number(element.minValue)) {
                errorsObj = Object.assign({}, errorsObj, {[element.name]: 'invalidInput'})
              }
            }
          }
        })
      }
    });
    this.setState({ piSectionFormErrors: errorsObj});

    let tableErrorsObj = {}
    let valid = true;
    props.bulkPurchaseQuestions && props.bulkPurchaseQuestions[0] && props.bulkPurchaseQuestions[0].categoryQuestions &&
    props.bulkPurchaseQuestions[0].categoryQuestions.forEach(element => {
      if(element.questionType === 'mandatory') {
        let cnt = 0;
                Object.keys(tableQuesResponse).forEach(questionResp => {
                    if(element.name === questionResp) {
            cnt++;
          }
        })
        if(cnt === 0) {
          let obj = {}
          element.columns.map((name) => {
            obj[name + '0'] = true
          })
          tableErrorsObj = Object.assign({}, tableErrorsObj, obj);
          valid = false;
        }
        else {
          tableQuesResponse[element.name].map((obj, i) => {
            Object.values(obj).map((key, index) => {
              if(key.trim() == '') {
                tableErrorsObj = Object.assign({}, tableErrorsObj, { [`${Object.keys(obj)[index]}${i}`]: true });
                valid = false;
              }
            })
          })
        }
      }
    })
    this.setState({ piSectionTableFormErrors: tableErrorsObj});

    let allServiceDisclaimers = [];
    this.state.offeringsForOrder.forEach(offering => {
      if(offering.serviceDisclaimer !== null) {
        let offeringDisclaimer = _.find(this.state.disclaimerCheckbox, { serviceId: Number(offering.offeringId) });
        if(!offeringDisclaimer?.value) {
          allServiceDisclaimers.push({ serviceId: Number(offering.offeringId), value: false, touched: true });
        } else {
          allServiceDisclaimers.push({ serviceId: Number(offering.offeringId), value: true, touched: false });
        }
      }
    })
    if (allServiceDisclaimers.length > 0) {
      let allDisclaimersChecked = true;
      allServiceDisclaimers.forEach(disclaimer => disclaimer.value !== true ? allDisclaimersChecked = false : "");
      if(!allDisclaimersChecked) {
        this.setState({ disclaimerCheckbox: allServiceDisclaimers, disableOrderNowButton: false });
        piQuestionsMandatError = this.props.tr["Please fill all the mandatory questions"];
        this.onSubmitClick();
        return;
      }
    }

    if(Object.keys(errorsObj).length !== 0 || !valid) {
      piQuestionsMandatError = this.props.tr["Please fill all the mandatory questions"];
      this.onSubmitClick();
      return;
    }
    else {
      piQuestionsMandatError = "";
      this.onSubmitClick();
    }

    for(let field in tableQuesResponse) {
      if (!tableQuesResponse[field].hasOwnProperty("fieldType")) {
        let question = _.find(props.fields, { name: field });
        if (question && question.qId) {
          this.createQuestionObject(
            Object.assign({}, question),
            tableQuesResponse[field],
            selectedQuestion
          );
        }
      }
    }

    for (let field in newFormValues) {
      if (!newFormValues[field].hasOwnProperty("fieldType")) {
        let question = _.find(props.fields, { name: field });
        if (question && question.qId) {
          /*if question name found in fields Array then create D2C required payload for question using
           * form Values supplied answer for the question*/
          this.createQuestionObject(
            Object.assign({}, question),
            newFormValues[field],
            selectedQuestion
          );
        } else {
          /*if question name doesn't found in fields Array it means it's of type readOnlyQuestion
           * then create D2C required payload using question details coming from Form Values*/
          if (newFormValues[field].qId) {
            this.createQuestionObject(
              Object.assign({}, newFormValues[field]),
              newFormValues[field].answer,
              selectedQuestion
            );
          }
        }
      }
    }

    let components = [];
    let questions = [];

    if(offeringForOrder[0].workItemType === "standardChange" || offeringForOrder[0].workItemType === "incidentRequest") {
      let offering = offeringForOrder[0];
      let offeringTemp = _.find(offeringForOrder, {
        offeringId: offering.offeringId,
      });

      for (let i = 0; i < offeringTemp.componentapiList.length; i++) {
        if (offeringTemp.componentapiList[i].componentType === "Core") {
          let componentObj = {
            componentIdSx: offeringTemp.componentapiList[i].id,
            componentNameSx: offeringTemp.componentapiList[i].componentName,
            provisionedService: "",
            componentPrice: offering.actualPrice,
            subcriptionStatus: "2",
            componentType: offeringTemp.componentapiList[i].componentType,
            actualstartDate: "0",
            expectedstartDate: "0",
            expectedendDate: "",
            actualendDate: "",
          };
          components.push(componentObj);
        }
      }

      for (let i = 0; i < selectedQuestion.length; i++) {
        if (selectedQuestion[i].offeringId === offering.offeringId.toString() && selectedQuestion[i].categoryId !== 1010101010
         &&  selectedQuestion[i].answer != "Invalid date") {
          if (selectedQuestion[i].responseType === "Calendar") {
            selectedQuestion[i].answer = selectedQuestion[i].answer.substring(0,10);
          }
          let question = {
            answer: selectedQuestion[i].answer,
            categoryId: selectedQuestion[i].categoryId,
            categoryName: selectedQuestion[i].categoryName,
            categorySequence: selectedQuestion[i].categorySequence,
            componentIdSx: selectedQuestion[i].componentIdSx,
            componentNameSx: selectedQuestion[i].componentNameSx,
            question: selectedQuestion[i].question,
            referenceQuestionId: selectedQuestion[i].questionId,
            questionSequence: selectedQuestion[i].questionSequence,
            questionApproval: selectedQuestion[i].questionApproval,
            includeInSummary: selectedQuestion[i].includeInSummary,
          };
          questions.push(question);
        }
      }
    }

    let obj = {
      requesterId: newFormValues.requesterId === "empty" ? "" : newFormValues.requesterId,
      requesterName:
        newFormValues.requesterName === "empty" ? "" : newFormValues.requesterName,
      requesterLocationName:
        newFormValues.requesterLocationName === "empty"
          ? ""
          : newFormValues.requesterLocationName,
      requesterLocationId:
        newFormValues.requesterLocationId === "empty"
          ? ""
          : newFormValues.requesterLocationId,
      recipientId: newFormValues.recipient === "empty" ? "" : newFormValues.recipient,
      recipientName:
        newFormValues.recipientName === "empty" ? "" : newFormValues.recipientName,
      locationId:
        newFormValues.requesterLocationId === "empty"
          ? ""
          : newFormValues.requesterLocationId,
      locationName:
        newFormValues.requesterLocationName === "empty"
          ? ""
          : newFormValues.requesterLocationName,
      recipientCubicle:
        newFormValues.recipientCubicle === "empty" ? "" : newFormValues.recipientCubicle,
      recipientContact:
        newFormValues.recipientContactNumber === "empty"
          ? ""
          : newFormValues.recipientContactNumber,
    };

    const selectedOptionalComponents = _.uniqBy(
      selectedComponentForOffering,
      "componentId"
    );
    let deliveryOptionsArray = this.props.selectedServiceDeliveries;
    let postjson;
    let items = [];
    this.props.orderDeliverTypes.deliveryList.forEach((delOpt, index) => {
      let deliveryOptionObj = {
        offeringId: "",
        deliverType: "",
      };
    });
    ref.setState({
      disableOrderNowButton: true,
    });
    if (offeringForOrder[0].workItemType === "standardChange") {
      var today = new Date();

      var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

      let standardChangeService = offeringForOrder[0];
      if(_.find(selectedQuestion, { question: "user id" }) && _.find(selectedQuestion, { question: "user id" }).requestorLocationID!==''){
        userLocationID=_.find(selectedQuestion, { question: "user id" }) && _.find(selectedQuestion, { question: "user id" }).requestorLocationID;
        userLocationName=_.find(selectedQuestion, { question: "user id" }) && _.find(selectedQuestion, { question: "user id" }).requestorLonationName;
      }else{
        userLocationID=values.requesterLocationId==="empty"? '' : values.requesterLocationId;
        userLocationName=values.requesterLocationName==="empty"? '' : values.requesterLocationName;
      }

      let expectedStartDate = moment.tz(_.find(selectedQuestion, { target: "expectedStartDate" }).answer, dateformat, timezone);
      let expectedEndDate = moment.tz(_.find(selectedQuestion, { target: "expectedEndDate" }).answer, dateformat, timezone);
      let datetimeError = false;
      let datetimeErrorText = '';
      if (expectedStartDate.isAfter(expectedEndDate)) {
        datetimeError = true;
        datetimeErrorText = 'Scheduled end date/time should not fall before scheduled start date/time.';
      }
      if (expectedEndDate.isBefore(moment().tz(timezone))) {
        datetimeError = true;
        datetimeErrorText = 'Scheduled end date/time should not be less than current date/time.';
      }
      if (expectedStartDate.isBefore(moment().tz(timezone))) {
        datetimeError = true;
        datetimeErrorText = 'Scheduled start date/time should not be less than current date/time.';
      }
      if (datetimeError) {
        swal({
          text: this.props.tr[datetimeErrorText],
          button: this.props.tr["OK"],
        });
        ref.setState({
          disableOrderNowButton: false,
        });
        return false;
      }
      const date_format = dateformat.slice(0, 10).toUpperCase();
      let postStandardChangeOrderPayload = {

        actEndDate: 0,
        actStartDate: 0,
        businessRisk: "0",
        // businessCriticality:_.find(selectedQuestion, { question: "BUSINESS CRITICALITY" }) && _.find(selectedQuestion, { question: "BUSINESS CRITICALITY" }).answer,
        // businessCriticalityId:_.find(selectedQuestion, { question: "METALLIC" }) && _.find(selectedQuestion, { question: "METALLIC" }).answer,
        category: _.find(selectedQuestion, { question: "CATEGORY NAME" }) && _.find(selectedQuestion, { question: "CATEGORY NAME" }).answer,
        changeGroupid: "0",
        changeGroupname: "0",
        changeTiming: "0",
        changeType: "20",
        changeUserid: "0",
        changeUsername: "0",
        ciClassId: _.find(selectedQuestion, { question: "CLASS ID" }) && _.find(selectedQuestion, { question: "CLASS ID" }).answer,
        ciClassName: _.find(selectedQuestion, { question: "CLASS NAME" }) && _.find(selectedQuestion, { question: "CLASS NAME" }).answer,
        ciCode: "ASTCI0000594097",
        ciConsumerCompany: _.find(selectedQuestion, { question: "COMPANY ID" }) && _.find(selectedQuestion, { question: "COMPANY ID" }).answer,
        ciId: _.find(selectedQuestion, { question: "CI ID" }) && _.find(selectedQuestion, { question: "CI ID" }).answer,
        ciLocationId: _.find(selectedQuestion, { question: "LOCATION ID" }) && _.find(selectedQuestion, { question: "LOCATION ID" }).answer,
        ciLocationName: _.find(selectedQuestion, { question: "LOCATION NAME" }) && _.find(selectedQuestion, { question: "LOCATION NAME" }).answer,
        ciName: _.find(selectedQuestion, { question: "CI NAME" }) && _.find(selectedQuestion, { question: "CI NAME" }).answer,
        ciSupportCompany: _.find(selectedQuestion, { question: "SUPPORT COMPANY_ID" }) && _.find(selectedQuestion, { question: "SUPPORT COMPANY_ID" }).answer,
        cmdbUpdateNeeded: _.find(selectedQuestion, { target: "cmdbUpdateNeeded" }).answer,
        consumerCompany: _.find(selectedQuestion, { question: "COMPANY ID" }) && _.find(selectedQuestion, { question: "COMPANY ID" }).answer,
        consumerCompanyName: _.find(selectedQuestion, { question: "COMPANY NAME" }) && _.find(selectedQuestion, { question: "COMPANY NAME" }).answer,
        controlEffectiveness: "",
        createdBy: "",
        createdByName: "",
        createdOn: 0,
        dEndDate: 0,
        dStartDate: 0,
        description: _.find(selectedQuestion, { target: "description" }).answer,
        downtimeRequired: "N",
        environment: _.find(selectedQuestion, { question: "ENVIRONMENT" }) && _.find(selectedQuestion, { question: "ENVIRONMENT" }).answer,
        // expEndDate: _.find(selectedQuestion, { target: "expectedEndDate" }).answer + " " + time,
        // expStartDate: _.find(selectedQuestion, { target: "expectedStartDate" }).answer + " " + time,
        expEndDate: moment.tz(_.find(selectedQuestion, { target: "expectedEndDate" }).answer,dateformat,timezone).format(dateformat),
        expStartDate: moment.tz(_.find(selectedQuestion, { target: "expectedStartDate" }).answer,dateformat,timezone).format(dateformat),
        impGroupid: "0",
        impGroupname: "0",
        impUserid: "0",
        impUsername: "0",
        modifiedBy: "0",
        modifiedOn: 0,
        plan: 0,
        planType: "20",
        requestId: "",
        requesterId: _.find(selectedQuestion, { question: "user id" }) && _.find(selectedQuestion, { question: "user id" }).answer,
        requesterName: _.find(selectedQuestion, { question: "Full Name" }) && _.find(selectedQuestion, { question: "Full Name" }).answer,
        risk: "",
        riskImpact: "",
        riskOccurrenceProbability: "",
        serviceBased: standardChangeService.ciBaseValue == "Catalog Based" ? "true" : "false",
        serviceId: standardChangeService.offeringId,
        serviceName: standardChangeService.offeringName,
        serviceCompanyId: standardChangeService.serviceCompanyId ? standardChangeService.serviceCompanyId:3215,
        serviceCompanyName : standardChangeService.serviceCompanyName?standardChangeService.serviceCompanyName:"H_U_L",
        status: "35",
        summary: _.find(selectedQuestion, { target: "summary" }).answer,
        technicalRisk: "0",
        type: "0",
        urgency: "0",
        requesterLocationId:newFormValues.shippingLocation === "otherCWL" ?
              (newFormValues.requester === "self" ? this.state.LoggedInUserLocationId : GLOBAL.onBehalfUserLocationId )
              : userLocationID,
        requesterLocationName: newFormValues.shippingLocation === "otherCWL" ?
              (newFormValues.requester === "self" ? this.state.LoggedInUserLocationName : GLOBAL.onBehalfLocationName )
              : userLocationName,
        contextualRuleId: standardChangeService.ams_rule_id,
        changeComponent: components,
        changeQuestion: questions,
        businessCriticality: newFormValues.businessCriticality,
        businessCriticalityId: newFormValues.businessCriticalityId
      };

      axios
        .post(GLOBAL.postStandardChangeOrder, postStandardChangeOrderPayload
        )
        .then(function (response) {

          let changeidresponse = response.data.requestNumber + "-" + response.data.changeNumber;

          if (response.status === 201) {

            if(response && response?.data && typeof response.data == 'object'){
              let obj = response.data;
              let payloadObj ={
                "ci_id": obj?.ciId,
                "wi_id_related": obj?.changeId,
                "wi_id_number": obj?.changeNumber,
                "ci_id_reported": obj?.createdOn,
                "created_by": "",
                "relationship_type": "Y",
                "module": "Change",
                "consumerCompany": obj?.consumerCompany,
                "consumerCompanyName": obj?.consumerCompanyName
                }
              axios.post(GLOBAL.cmdbRelatedLogDeleteUrl, payloadObj)
              .catch((err1) => {
                console.error(err1);
              });
            }


            let dropzoneQuesArr = _.filter(selectedQuestion, { responseType: "Dropzone" });
            let attachmentQuesArr = [...dropzoneQuesArr, ..._.filter(selectedQuestion, { responseType: "Attachment" })];
            if(typeof _.find(selectedQuestion, { target: "implementationPlan" }) === "undefined") {
              attachmentQuesArr.push({ target: "implementationPlan"});
            }
            attachmentQuesArr.forEach(question => {
              let formData = new FormData();
              let jsonObject = {};

              jsonObject.itemId = response.data.changeId;
              jsonObject.module = "ChangeManagement";
              jsonObject.planType = question.target === "implementationPlan" ? "Implementation" : null;
              jsonObject.planDetail = question.target === "implementationPlan" ? _.find(selectedQuestion, { target: "implementationPlanDetails" }).answer : null;
              jsonObject.createdOn = Math.round(new Date().getTime() / 1000.0);
              jsonObject.createdBy = "";
              jsonObject.createdByName = "";
              if (question.attachedFile) {
                if(Array.isArray(question.attachedFile) && question.attachedFile.length > 0) {
                  question.attachedFile.forEach(file => formData.append("file", file, file.name))
                } else {
                  formData.append("file", question.attachedFile, question.attachedFile.name);
                }
                formData.append("attachment", JSON.stringify(jsonObject));
              } else {
                formData.append("file", "");
                formData.append("attachment", JSON.stringify(jsonObject));
              }

              axios
                .post("/change/api/saveImplPlan", formData)
                .then((response) => {

                  if (response.status === 200) {
                    ref.setState({
                      disableOrderNowButton: true,
                    });
                    swal({
                      text: changeidresponse + ref.props.tr["has been submitted for review."],
                      button: ref.props.tr["Proceed to Home"],
                    }).then((willDelete) => {
                      if (willDelete) {
                        navigationHooks.navigate(homepagelocation);
                      }
                    });
                  }
                })
                .catch((error) => {
                  swal({
                    text: this.props.tr["error in uploading file for "],
                    button: this.props.tr["OK"],
                  });
                  ref.setState({
                    disableOrderNowButton: false,
                  });
                });
            })
          } else {
            ref.setState({
              disableOrderNowButton: true,
            });
            if (response.status === 201) {

              swal({
                text: changeidresponse + ref.props.tr["has been submitted for review."],
                button: ref.props.tr["Proceed to Home"],
              }).then((willDelete) => {
                if (willDelete) {
                  navigationHooks.navigate(homepagelocation);
                }
              });

            }
          }
        })
        .catch(function (error) {
          ref.setState({
            disableOrderNowButton: false,
          });
          //alert(this.props.tr['Could not place order, please try again']);
          swal({
            text: this.props.tr["Could not place order, please try again"],
            button: this.props.tr["OK"],
          });
          console.log(error);
        });
    }
    else if (offeringForOrder[0].workItemType === "incidentRequest") {
      let incidentService = offeringForOrder[0];
      let offeringDetailsData = this.props.offeringDetails;
      let urgency, impact, priority, priorityLabel='';

      this.setConsumerCompany(offeringDetailsData[0].isShared);
      if(_.find(selectedQuestion, { question: "user id" }) && _.find(selectedQuestion, { question: "user id" }).requestorLocationID!==''){
        userLocationID=_.find(selectedQuestion, { question: "user id" }) && _.find(selectedQuestion, { question: "user id" }).requestorLocationID;
        userLocationName=_.find(selectedQuestion, { question: "user id" }) && _.find(selectedQuestion, { question: "user id" }).requestorLonationName;
      }else{
        userLocationID=newFormValues.requesterLocationId==="empty"? '' : newFormValues.requesterLocationId;
        userLocationName=newFormValues.requesterLocationName==="empty"? '' : newFormValues.requesterLocationName;
      }
      if(GLOBAL.Vip == "yes" || GLOBAL.Vip == "Yes") {
        impact = this.state.serviceCriticality.at(-2);
        urgency = this.state.urgencyValues[0];
      } else {
        impact = this.state.serviceCriticality.at(-1);
        urgency = _.find(selectedQuestion, { target: "urgency" }).answer;
      }

      axios.get('/api/getCompanyPriorityMatrix?companyId='+ incidentService.company +'&module=Breakfix&impact=' + impact + '&urgency=' + urgency).
      then((respondedPriority)=>{
        if(typeof respondedPriority.data[0].priority!=='undefined'){
            priority = respondedPriority.data[0].priority;
            priorityLabel = respondedPriority.data[0].priorityValue;
            axios
              .post(GLOBAL.postIncidentOrder, {
                serviceId: incidentService.offeringId,
                status: incidentService.useExternalNumber === "true" ? -20 : 10,
                breakfixNumber:
                  incidentService.useExternalNumber === "true" ? "In Process" : "",
                integrationPreferences: incidentService.integrationPreferences,
                serviceCriticality: impact,
                impactTo: "S",
                isShipmentAllowed: incidentService.deliveryMode === "Shipment Based",
                isExternallyFulfilled: incidentService.externallyFulfilled === "true",
                impactedUserid: newFormValues.requester === "self" ? "" : GLOBAL.consumerId,
                impactedUsername:
                newFormValues.requester === "self" ? "" : GLOBAL.consumerName,
                description: _.find(selectedQuestion, { target: "description" })
                  .answer,
                reportedOn: "",
                additionalInfo:
                  _.find(selectedQuestion, { target: "additionalInfo" }) === undefined
                    ? ""
                    : _.find(selectedQuestion, { target: "additionalInfo" }).answer,
                spGroupid: "",
                spGroupname: "",
                spUserid: "0",
                spUsername: "0",
                urgencyJustification: "0",
                soGroupid: "0",
                soUserid: "0",
                priorityId: priority,
                priorityValue: priorityLabel,
                criticalJustification: "0",
                criticality: "No",
                holdJustification: "0",
                cause: 1,
                slaStatus: "0",
                expectedDate: "0",
                createdOn: "0",
                createdBy: "",
                createdByName: "",
                modifiedOn: "0",
                modifiedBy: "",
                reportedThrough: "50",
                urgency: urgency,
                serviceName: incidentService.offeringName,
                serviceCompanyId:incidentService.serviceCompanyId,
                serviceCompanyName:incidentService.serviceCompanyName,
                consumerCompany: consumerCompanyIDShared,
                consumerCompanyName:   consumerCompanyNameShared,
                // consumerCompanyName:"",
                ciId: 0,
                ciName: "",
                // "ciName": "":_.find(selectedQuestion, {target: "additionalInfo"}).urgency,
                ciCode: "",
                holingReasonCode: "0",
                holdingReasonValue: "",
                recipientId:
                  values.recipient === "ownAddress" ? "" : values.recipientId,
                recipientName:
                  values.recipient === "ownAddress" ? "" : values.recipientName,
                locationId:
                  values.shippingLocation === "ownCWL"
                    ? values.requesterLocationId
                    : "",
                locationName:
                  values.shippingLocation === "ownCWL"
                    ? values.requesterLocationName
                    : "",
                recipientCubicle:
                  values.recipientCubicle === "empty" ? "" : values.recipientCubicle,
                recipientContact:
                  values.recipientContact === "empty" ? "" : values.recipientContact,
                isReopenEnabled: incidentService.restrictReopenSR === "true",
                addressLine1: values.addressLine1 ? values.addressLine1 : "",
                addressLine2: values.addressLine2 ? values.addressLine2 : "",
                city: values.city ? values.city : "",
                state: values.state ? values.state : "",
                country: values.country ? values.countryName : "",
                postalCode: values.postalCode ? values.postalCode : "",
                serviceType: incidentService.serviceType,
                requesterLocationId:values.shippingLocation === "otherCWL" ?
                    (values.requester === "self" ? this.state.LoggedInUserLocationId : GLOBAL.onBehalfUserLocationId )
                    : userLocationID,
                requesterLocationName: values.shippingLocation === "otherCWL" ?
                    (values.requester === "self" ? this.state.LoggedInUserLocationName : GLOBAL.onBehalfLocationName )
                    : userLocationName,
                additionalIntegrationData: incidentService.additionalIntegrationData,
                userName: "",
                dateFormat: "",
                timeZone: "",
              contextualRuleId: incidentService.ams_rule_id,
              incComponent: components,
              incQuestion: questions
              })
              .then(function (response) {
                if (
                  (_.some(selectedQuestion, { responseType: "Attachment" }) || _.some(selectedQuestion, { responseType: "Dropzone" })) &&
                  response.status === 201
                ) {
                  let dropzoneQuesArr = _.filter(selectedQuestion, { responseType: "Dropzone" });
                  let attachmentQuesArr = [...dropzoneQuesArr, ..._.filter(selectedQuestion, { responseType: "Attachment" })];
      
                  attachmentQuesArr.forEach(question => {
                    let formData = new FormData();
                    if (question.attachedFile) {
                      if(Array.isArray(question.attachedFile) && question.attachedFile.length > 0) {
                        question.attachedFile.forEach(file => formData.append("file", file, file.name))
                      } else {
                        formData.append("file", question.attachedFile, question.attachedFile.name);
                      }
                    } else {
                      formData.append("file", "");
                    }
                    let jsonObject = {};
                    jsonObject.module = "Breakfix";
                    jsonObject.itemId = response.data.breakfixId;
                    jsonObject.createdOn = Math.round(new Date().getTime() / 1000.0);
                    jsonObject.createdBy = "";
                    jsonObject.createdByName = "";
                    formData.append("attachment", JSON.stringify(jsonObject));
                    axios
                      .post("/change/api/saveImplPlan", formData)
                      .then((response) => {
                        if (response.status === 200) {
                          ref.setState({
                            disableOrderNowButton: false,
                          });
                          navigationHooks.navigate("/myOrders");
                        }
                      })
                      .catch((error) => {
                        // alert(this.props.tr['error in uploading file for ']);
                        swal({
                          text: this.props.tr["error in uploading file for "],
                          button: this.props.tr["OK"],
                        });
                        ref.setState({
                          disableOrderNowButton: false,
                        });
                      });
                  })
                } else {
                  ref.setState({
                    disableOrderNowButton: false,
                  });
                  navigationHooks.navigate("/myOrders");
                }
              })
              .catch(function (error) {
                ref.setState({
                  disableOrderNowButton: false,
                });
                //alert(this.props.tr['Could not place order, please try again']);
                swal({
                  text: this.props.tr["Could not place order, please try again"],
                  button: this.props.tr["OK"],
                });
              });
        }
    })
    } else {
      let offeringDetailsData = this.props.offeringDetails;
      this.setConsumerCompany(offeringDetailsData[0].isShared);
      for (let i = 0; i < offeringForOrder.length; i++) {
        let offering = offeringForOrder[i];
        let servicePriceDetails = _.find(
          this.state.allServicePrices.itemPriceAndQuantity,
          { serviceId: offering.offeringId }
        );

        let components = [];
        let questions = [];
        let deliveryOption = "";
        let priceForItem = 0.0;

        for (let i = 0; i < deliveryOptionsArray.length; i++) {
          if (deliveryOptionsArray[i].offeringId == offering.offeringId) {
            deliveryOption = deliveryOptionsArray[i].deliverType;
          }
        }
        if (selectedOptionalComponents.length !== 0) {
          for (let i = 0; i < selectedOptionalComponents.length; i++) {
            if (
              selectedOptionalComponents[i].offeringId ===
              offering.offeringId ||
              selectedOptionalComponents[i].offeringId ===
              offering.offeringId.toString()
            ) {
              let offeringTemp = _.find(offeringForOrder, {
                offeringId: offering.offeringId,
              });
              let componentTemp = _.find(offeringTemp.componentapiList, {
                componentId: parseInt(
                  selectedOptionalComponents[i].componentId, 10
                ),
              });
              if (
                componentTemp !== undefined &&
                componentTemp.componentType === "Optional"
              ) {
                let componentObj = {
                  componentIdSx: componentTemp.id,
                  componentNameSx: componentTemp.componentName,
                  provisionedService: "",
                  componentPrice: componentTemp.actualPrice,
                  subcriptionStatus: "2",
                  componentType: componentTemp.componentType,
                  actualstartDate: "0",
                  expectedstartDate: "0",
                  expectedendDate: "",
                  actualendDate: "",
                };
                priceForItem += componentTemp.actualPrice;
                components.push(componentObj);
              }
            }
          }
        }
        let offeringTemp = _.find(offeringForOrder, {
          offeringId: offering.offeringId,
        });
        for (let i = 0; i < offeringTemp.componentapiList.length; i++) {
          if (offeringTemp.componentapiList[i].componentType === "Core") {
            let componentObj = {
              componentIdSx: offeringTemp.componentapiList[i].id,
              componentNameSx: offeringTemp.componentapiList[i].componentName,
              provisionedService: "",
              componentPrice: offering.actualPrice,
              subcriptionStatus: "2",
              componentType: offeringTemp.componentapiList[i].componentType,
              actualstartDate: "0",
              expectedstartDate: "0",
              expectedendDate: "",
              actualendDate: "",
            };
            components.push(componentObj);
          }
        }
        priceForItem += offering.actualPrice;
        for (let i = 0; i < selectedQuestion.length; i++) {
          if (
            selectedQuestion[i].offeringId === offering.offeringId.toString() && selectedQuestion[i].answer != "Invalid date"
          ) {
            if (selectedQuestion[i].responseType === "Calendar") {
              selectedQuestion[i].answer = selectedQuestion[i].answer.substring(0,10);
            }
            let question = {
              answer: selectedQuestion[i].answer,
              categoryId: selectedQuestion[i].categoryId,
              categoryName: selectedQuestion[i].categoryName,
              categorySequence: selectedQuestion[i].categorySequence,
              componentIdSx: selectedQuestion[i].componentIdSx,
              componentNameSx: selectedQuestion[i].componentNameSx,
              question: selectedQuestion[i].question,
              referenceQuestionId: selectedQuestion[i].questionId,
              questionSequence: selectedQuestion[i].questionSequence,
              questionApproval: selectedQuestion[i].questionApproval,
              includeInSummary: selectedQuestion[i].includeInSummary
            };
            questions.push(question);
          }
        }
        let offeringObj = {
          offeringIdSx: offering.offeringId,
          offeringNameSx: offering.offeringName,
          itemStatus:
            offering.useExternalNumber === "true"
              ? -20
              : offering.approvalRequired === "no"
                ? 70
                : 65,
          itemNumber: offering.useExternalNumber === "true" ? "In Process" : "",
          isShipmentAllowed: offering.deliveryMode === "Shipment Based",
          isExternallyFulfilled: offering.externallyFulfilled === "true",
          integrationPreferences: offering.integrationPreferences,
          itemType: "60",
          deliveryType:
            deliveryOption === "" || offering.hideSla === "true"
              ? "0"
              : deliveryOption,
          deliveryVal:
            deliveryOption === "" || offering.hideSla === "true"
              ? "0"
              : deliveryOption,
          itemPrice: servicePriceDetails?.serviceTotalPrice?.toFixed(2) === "NaN" ? "0.00" : servicePriceDetails?.serviceTotalPrice?.toFixed(2),
          asgGroupid: offering.assignmentGroupId,
          asgGroupname: offering.assignmentGroupName,
          asgUserid: "0",
          asgUsername: "0",
          expdeliveryDate: "0",
          completionDate: "0",
          closedOn: "0",
          cancelledOn: "0",
          approvedOn: "0",
          rejectedOn: "0",
          createdOn: "0",
          createdBy: "",
          modifiedOn: "0",
          modifiedBy: GLOBAL.consumerId === "" ? "" : GLOBAL.consumerId,
          components: components,
          questions: questions,
          currency: "",
          consumerCompany: consumerCompanyIDShared,
          consumerCompanyName: consumerCompanyNameShared,
          // consumerCompanyName:"",
          companyId:
          consumerCompanyIDShared,
          approvalRequired: offering.approvalRequired,
          approvalStatus: offering.approvalStatus,
          feedbackSubmitted: "N",
          holingReasonCode: "0",
          holdingReasonValue: "",
          complexity: offering.complexity,
          isCompxEditable: offering.complexiyEditable === "false" ? "N" : "Y",
          isExpComplDateEditable:
            offering.editableExpectedBy === "false" ? "N" : "Y",
          isCancelEnabled: offering.restrictCancellationSR === "true",
          isReopenEnabled: offering.restrictReopenSR === "true",
          serviceType: offering.serviceType,
          requesterId: values.requesterId === "empty" ? this.state.loggedINuserID : values.requesterId,
          requesterName:
            values.requesterName === "empty" ? this.state.loggedInUserFullName : values.requesterName,
          recipientId:
            values.recipient === "ownAddress" ? values.requesterId === "empty" ? "" : values.requesterId : values.recipientId,
          recipientName:
            values.recipient === "ownAddress" ? values.requesterName === "empty" ? "" : values.requesterName : values.recipientName,
          locationId:
            values.shippingLocation === "ownCWL"
              ? values.requesterLocationId
              : "",
          locationName:
            values.shippingLocation === "ownCWL"
              ? values.requesterLocationName
              : "",
          recipientCubicle:
            values.recipientCubicle === "empty" ? "" : values.recipientCubicle,
          recipientContact:
            values.recipientContact === "empty" ? "" : values.recipientContact,
          addressLine1: values.addressLine1 ? values.addressLine1 : "",
          addressLine2: values.addressLine2 ? values.addressLine2 : "",
          city: values.city ? values.city : "",
          state: values.state ? values.state : "",
          country: values.country ? values.countryName : "",
          postalCode: values.postalCode ? values.postalCode : "",
          requesterLocationName:
            (values.requesterLocationName === "empty" || typeof values.requesterLocationName === 'undefined')
              ? this.state.newOwnerLocName
              : values.requesterLocationName,
          requesterLocationId:
            (values.requesterLocationId === "empty" || typeof values.requesterLocationId === 'undefined')
              ? this.state.newOwnerLocId
              : values.requesterLocationId,
          additionalIntegrationData: offering.additionalIntegrationData,
          quantity: "0",
          quantityLeft: "0",
          isPriceEnabled: offering.hidePrice !== "true",
          emailAddress: "",
          employeeBand: "",
          employeeNumber: "",
          reminderFlag: offering.isReminder === "true",
          userName: "",
          dateFormat: "",
          timeZone: "",
          reportedThrough: "50",
          servicePrice: Number(offering.actualPrice?.toFixed(2)),
          serviceCompanyId: offering.serviceCompanyId ? offering.serviceCompanyId:"",
          serviceCompanyName : offering.serviceCompanyName?offering.serviceCompanyName:"",
          contextualRuleId: offering.ams_rule_id
        };
        offeringObj.requesterLocationId=values.shippingLocation === "otherCWL" ?
              (values.requester === "self" ? this.state.LoggedInUserLocationId : GLOBAL.onBehalfUserLocationId )
              : offeringObj.requesterLocationId;
        offeringObj.requesterLocationName=values.shippingLocation === "otherCWL" ?
              (values.requester === "self" ? this.state.LoggedInUserLocationName : GLOBAL.onBehalfLocationName )
              : offeringObj.requesterLocationName;
        if (
          offering.hideQuantity === "false" &&
          !values["itemQuantity_" + offering.offeringId]
        ) {
          if(this.state.totalItemQuantity == 0){
            offeringObj.quantity = offering.minValue;
         }else{
           offeringObj.quantity = parseInt(this.state.totalItemQuantity, 10);
         }
          // offeringObj.quantity = offering.minValue;
          offeringObj.quantityLeft = offering.minValue;
        }
        if (
          offering.hideQuantity === "false" &&
          values["itemQuantity_" + offering.offeringId]
        ) {
          offeringObj.quantity = values["itemQuantity_" + offering.offeringId];
          offeringObj.quantityLeft =
            values["itemQuantity_" + offering.offeringId];
        }

        items.push(offeringObj);
      }
      // Userid and Username of pi readonly question for requesterId and requesterName in case of Onbehalf order

      let requesterIdOnBehalfOrder = "";
      let requesterNameOnBehalfOrder = "";

      for (let i = 0; i < items.length; i++) {
        const x = _.find(items[i].questions, ["question", "Full Name"]) ||
          _.find(items[i].questions, ["question", "Nom complet"]) ||
          _.find(items[i].questions, ["question", "Vollständiger Name"]);

        const y = _.find(items[i].questions, ["question", "User ID"]) ||
          _.find(items[i].questions, ["question", "ID utilisateur"]) ||
          _.find(items[i].questions, ["question", "Benutzer-ID"]);

        if (x && y) {
          requesterNameOnBehalfOrder = x.answer;
          requesterIdOnBehalfOrder = y.answer;
          break; // <=== breaks out of the loop early
        }
      }

      for (let i = 0; i < items.length; i++) {
        const projectRequestType = _.some(items[i].questions, [
          "question",
          "Project Type",
        ]) || _.some(items[i].questions, [
          "question",
          "Type de projet",
        ]) || _.some(items[i].questions, [
          "question",
          "Projekttyp",
        ]);

        const projectCodeEmployeeCost = _.some(items[i].questions, [
          "question",
          "Project Code",
        ]) || _.some(items[i].questions, [
          "question",
          "Code de projet",
        ]) || _.some(items[i].questions, [
          "question",
          "Projektnummer",
        ]) || _.some(items[i].questions, [
          "question",
          "Project Code - Cost Center",
        ]) || _.some(items[i].questions, [
          "question",
          "Code de projet - Centre de coûts",
        ]) || _.some(items[i].questions, [
          "question",
          "Projektcode - Kostenstelle",
        ]) || _.some(items[i].questions, [
          "question",
          "Employee Cost Center",
        ]) || _.some(items[i].questions, [
          "question",
          "Centre de coûts des employés",
        ]) || _.some(items[i].questions, [
          "question",
          "Mitarbeiterkostenstelle",
        ]) || _.some(items[i].questions, [
          "question",
          "Employee Cost",
        ]) || _.some(items[i].questions, [
          "question",
          "Coût des employés",
        ]) || _.some(items[i].questions, [
          "question",
          "Mitarbeiterkosten",
        ]);



        const assetIssued = _.some(items[i].questions, [
          "question",
          "Asset Issued from (HCL Location)Location Details",
        ]) || _.some(items[i].questions, [
          "question",
          "Détails de l'emplacement de l'élément (HCL Location)",
        ]) || _.some(items[i].questions, [
          "question",
          "Asset ausgestellt von (HCL-Standort) Standortdetails",
        ]);

        const locationOfFulfilment = _.some(items[i].questions, [
          "question",
          "Location of Fulfilment",
        ]) || _.some(items[i].questions, [
          "question",
          "Lieu d'exécution",
        ]) || _.some(items[i].questions, [
          "question",
          "Ort der Erfüllung",
        ]);


        const assetSurrenderLocation = _.some(items[i].questions, [
          "question",
          "Asset Surrender Location",
        ]) || _.some(items[i].questions, [
          "question",
          "Lieu de rachat des actifs",
        ]) || _.some(items[i].questions, [
          "question",
          "Ort der Rückgabe von Vermögenswerten",
        ]);

        const location = _.some(items[i].questions, [
          "question",
          "Location",
        ]) || _.some(items[i].questions, [
          "question",
          "Lieu",
        ]) || _.some(items[i].questions, [
          "question",
          "Ort",
        ]);


        const resourceJoinedHCL = _.some(items[i].questions, [
          "question",
          "Resource Joined HCL",
        ]) || _.some(items[i].questions, [
          "question",
          "Ressource jointe HCL",
        ]) || _.some(items[i].questions, [
          "question",
          "Ressource beigetreten HCL",
        ]);

        const newJoinerUserBand = _.some(items[i].questions, [
          "question",
          "New Joiner User Band",
        ]) || _.some(items[i].questions, [
          "question",
          "Nouveau groupe d'utilisateurs Joiner",
        ]) || _.some(items[i].questions, [
          "question",
          "Neues Joiner User Band",
        ]);

        const newJoinerJoiningDate = _.some(items[i].questions, [
          "question",
          "New Joiner Joining Date",
        ]) || _.some(items[i].questions, [
          "question",
          "Date d'adhésion du nouveau menuisier",
        ]) || _.some(items[i].questions, [
          "question",
          "Neues Beitrittsdatum für Tischler",
        ]);

        if (projectRequestType) {
          if (!projectCodeEmployeeCost) {
            swal({
              text: this.props.tr["Could not place order, as some information (Project Code/Employee Cost Centre) is missing. Please try again."],
              button: this.props.tr["OK"],
            });
            ref.setState({
              disableOrderNowButton: false,
            });
            return false;
          }

        }

        if (assetIssued) {
          if (!locationOfFulfilment) {
            swal({
              text: this.props.tr["Could not place order, as location of fulfilment is missing. Please try again."],
              button: this.props.tr["OK"],
            });
            ref.setState({
              disableOrderNowButton: false,
            });
            return false;
          }

        }

        if (assetSurrenderLocation) {
          if (!location) {
            swal({
              text: this.props.tr["Could not place order, as location is missing. Please try again."],
              button: this.props.tr["OK"],
            });
            ref.setState({
              disableOrderNowButton: false,
            });
            return false;
          }
        }

        if (resourceJoinedHCL) {
          if (!newJoinerUserBand) {
            swal({
              text: this.props.tr["Could not place order, as new joiner band is missing. Please try again."],
              button: this.props.tr["OK"],
            });
            ref.setState({
              disableOrderNowButton: false,
            });
            return false;
          }

          else if (!newJoinerJoiningDate) {
            swal({
              text: this.props.tr["Could not place order, as new joiner joining date is missing. Please try again."],
              button: this.props.tr["OK"],
            });
            ref.setState({
              disableOrderNowButton: false,
            });
            return false;
          }
        }
      }

      for (let i = 0; i < items.length; i++) {
        items[i].requesterId =
          requesterIdOnBehalfOrder !== ""
            ? requesterIdOnBehalfOrder
            : values.requesterId === "empty"
              ? this.state.loggedINuserID
              : values.requesterId;
        items[i].requesterName =
          requesterNameOnBehalfOrder !== ""
            ? requesterNameOnBehalfOrder
            : values.requesterName === "empty"
              ? this.state.loggedInUserFullName
              : values.requesterName;
      }

      let requesterId =
        requesterIdOnBehalfOrder !== ""
          ? requesterIdOnBehalfOrder
          : values.requesterId === "empty"
            ? this.state.loggedINuserID
            : values.requesterId;
      let requesterName =
        requesterNameOnBehalfOrder !== ""
          ? requesterNameOnBehalfOrder
          : values.requesterName === "empty"
            ? this.state.loggedInUserFullName
            : values.requesterName;

      postjson = {
        orderStatus: "35",
        requesterId: requesterId,
        requesterName: requesterName,
        orderTotal: this.state.allServicePrices.grandTotal(),
        orderCurrency: "",
        description: "order generation",
        ordercompletionDate: "0",
        ordercancelDate: "0",
        ordercloseDate: "0",
        createdOn: "0",
        createdBy: "",
        createdByName: "",
        modifiedOn: "0",
        modifiedBy: "0",
        items: items
      };

      if(_.find(selectedQuestion, { question: "user id" }) && _.find(selectedQuestion, { question: "user id" }).requestorLocationID!==''){
        userLocationID=_.find(selectedQuestion, { question: "user id" }) && _.find(selectedQuestion, { question: "user id" }).requestorLocationID;
        userLocationName=_.find(selectedQuestion, { question: "user id" }) && _.find(selectedQuestion, { question: "user id" }).requestorLonationName;
      }else{
        userLocationID=values.requesterLocationId==="empty"? '' : values.requesterLocationId;
        userLocationName=values.requesterLocationName==="empty"? '' : values.requesterLocationName;
      }
      postjson.requesterLocationId=values.shippingLocation === "otherCWL" ?
              (values.requester === "self" ? this.state.LoggedInUserLocationId : GLOBAL.onBehalfUserLocationId )
              : userLocationID;
      postjson.requesterLocationName=values.shippingLocation === "otherCWL" ?
              (values.requester === "self" ? this.state.LoggedInUserLocationName : GLOBAL.onBehalfLocationName )
              : userLocationName;

      axios
        .post(GLOBAL.postOrderUrl, postjson)
        .then(function (response) {
          let items = response.data.items;
          let attachmentquestion = 0;
          let jval = 0;
          let attachquestion = 0;
          for (let k = 0; k < selectedQuestion.length; k++) {
            if (selectedQuestion[k].responseType === "Attachment" || selectedQuestion[k].responseType === "Dropzone") {
              attachmentquestion++;
            }
          }
          if (attachmentquestion > 0) {
            for (let i = 0; i < items.length; i++) {
              for (let j = 0; j < selectedQuestion.length; j++) {
                jval = j;
                if (selectedQuestion[j].responseType === "Attachment" || selectedQuestion[j].responseType === "Dropzone") {
                  if (
                    items[i].offeringIdSx.toString() ===
                    selectedQuestion[j].offeringId
                  ) {
                    selectedQuestion[j].attachmentJSONObject.itemId =
                      items[i].itemId;
                    let formData = new FormData();
                    //formData.append('file', selectedQuestion[j].attachedFile);
                    /*@Rajan: For Blob file type need to pass file name as third parameter else file name will be taken as
                     * "blob" by default*/
                    if (selectedQuestion[j].attachedFile) {
                      if(Array.isArray(selectedQuestion[j].attachedFile) && selectedQuestion[j].attachedFile.length > 0) {
                        selectedQuestion[j].attachedFile.forEach(file => formData.append("file", file, file.name))
                      } else {
                        formData.append("file", selectedQuestion[j].attachedFile, selectedQuestion[j].attachedFile.name);
                      }
                    } else {
                      formData.append("file", "");
                    }
                    formData.append(
                      "attachment",
                      JSON.stringify(selectedQuestion[j].attachmentJSONObject)
                    );
                    axios
                      .post("/change/api/saveImplPlan", formData)
                      .then((response) => {
                        attachquestion++;
                        if (
                          response.status === 200 &&
                          attachmentquestion === attachquestion &&
                          !offeringId
                        ) {
                          for (let i = 0; i < items.length; i++) {
                            axios
                              .post(GLOBAL.delteOfferingFromCart, {
                                serviceId: items[i].offeringIdSx,
                              })
                              .then((response) => {
                                if (
                                  response.status === 200 &&
                                  i === items.length - 1
                                ) {
                                  // Cart Count
                                  ref.props.loadCartCount();
                                  /* ref.setState({
                                 disableOrderNowButton: false
                               });*/
                                  navigationHooks.navigate("/myOrders");
                                }
                              })
                              .catch((err) => {
                                navigationHooks.navigate("/myOrders");
                              });
                          }
                        }
                        if (
                          response.status === 200 &&
                          attachmentquestion === attachquestion &&
                          offeringId
                        ) {
                          navigationHooks.navigate("/myOrders");
                        }
                      })
                      .catch((error) => {
                        swal({
                          text:
                            ref.props.tr["error in uploading file for "] +
                            items[i].offeringNameSx,
                          button: ref.props.tr["OK"],
                        });
                      });
                  }
                }
              }
            }
            /*  ref.setState({
                disableOrderNowButton: false
              });*/
          } else {
            if (!offeringId) {
              for (let i = 0; i < items.length; i++) {
                axios
                  .post(GLOBAL.delteOfferingFromCart, {
                    serviceId: items[i].offeringIdSx,
                  })
                  .then((response) => {
                    if (response.status === 200 && i === items.length - 1) {
                      ref.props.loadCartCount();
                      ref.setState({
                        disableOrderNowButton: false,
                      });
                      navigationHooks.navigate("/myOrders");
                    }
                  })
                  .catch((err) => {
                    navigationHooks.navigate("/myOrders");
                  });
              }
            } else {
              navigationHooks.navigate("/myOrders");
            }
          }
        })
        .catch(function (error) {
          ref.setState({
            disableOrderNowButton: false,
          });
          //alert(this.props.tr['Could not place order, please try again']);
          swal({
            text: ref.props.tr["Could not place order, please try again"],
            button: ref.props.tr["OK"],
          });
        });
    }
  }

  loadHome() {
    navigationHooks.navigate(homepagelocation);
  }

  updateDisclaimer(value, serviceId) {
    let disclaimerObj = _.find(this.state.disclaimerCheckbox, { serviceId: Number(serviceId) });
    if(disclaimerObj) {
      disclaimerObj.touched = value ? false : true;
      disclaimerObj.value = value;
      let allDisclaimer = _.uniqBy([...this.state.disclaimerCheckbox, disclaimerObj], 'serviceId');
      this.setState({ disclaimerCheckbox: allDisclaimer });
    } else {
      disclaimerObj = { serviceId: Number(serviceId), value, touched: value ? false : true };
      this.setState({ disclaimerCheckbox: [...this.state.disclaimerCheckbox, disclaimerObj] });
    }
  }
  setShippingLocationType(value) {
    this.setState({ isShippingLocationTypeSelected: value });
  }
  setShippingLocationAddress(value, options, selectedOptionId) {
    if (options && options.length > 0) {
      const opt = options.filter(item => item.LOCATIONS == this.state.currentLocationId);
      if(opt.length === 0) {
        this.setState({ isShippingLocationAddressSelected: false });
      } else {
        this.setState({ isShippingLocationAddressSelected: true });
      }
    } else if(selectedOptionId && selectedOptionId != "") {
      this.setState({ isShippingLocationAddressSelected: value,  currentLocationId: selectedOptionId});
    } else {
      this.setState({ isShippingLocationAddressSelected: value });
    }
  }
  render() {
    const { handleSubmit, dispatch } = this.props;
    let { offeringId, smartFormId } = this.location.state;
    if(smartFormId){
      const offering = this.state.offeringsForOrder[0];
      return <View parentValues={{...offering}} pageId={smartFormId} isChildPage={true}/>
    }else{
    return (
      <main>
        {/* <div className="gloSearchDiv"><SxSearch tr={this.props.tr} /></div> */}

        <div className="minHeight">
          <PindCategories />

          {
            this.props.questionnaireList.fetchingChildQuestions == true ? <div className="overlay-container">
              {/* <OverlayLoader color={'white'} loader="ScaleLoader" text="Loading..." active={true} backgroundColor={'black'} opacity=".0" /> */}
              <LoadingIcons.Bars height="5em" stroke="transparent" fill="#06bcee" />
            </div> : null
          }

          <form onSubmit={e => e.preventDefault()}>
            {/* <RightSidebarMenulist
            showhideClass={this.state.showHideRightNav}
            subMenuNumber={this.state.subMenu}
          /> */}
            <div className="container-fluid margin-b-15">
            <nav aria-label="Breadcrumb"><ul className="breadcrumb"><li className="breadcrumb-item"><a href="javascript:void(0)" role="button" onClick={this.loadHome} >{this.props.tr["Home"]}</a></li><li className="breadcrumb-item active">{this.props.tr["Checkout"]}</li></ul></nav>
            </div>

            <div className="container-fluid" bsClass="">
              <Row className="margin-b-15">
                <Col md={8} sm={8} xs={7}>
                  <h1 bsClass="" className="sPageHeading1">
                  {this.props.tr["Checkout"]}
                  </h1>
                </Col>
                <Col md={4} sm={4} xs={5}>
                {this.state.isRightEditPanel ? "" : <div className="float-r toprigLnk margin-t-0 d-md-none">
                  <Nav className="icnlnk" as="ul">              
                    <Nav.Item
                      title={this.props.tr["Ordering Information"]}
                      as="li"
                    >
                      <a href="javascript:void(0)"
                      onClick={() => {
                        this.rightEditPanel(true);
                      }}
                      className="myBt fillBtn"
                      >
                      <TbFileInfo/>
                      </a>
                    </Nav.Item>
                  </Nav>
                </div> }
              </Col>
              </Row>
              {/* <InnerCategorySearch tr={this.props.tr} />*/}
              <PanelGroup direction="horizontal">
              <Panel id="sidebar" minSize={33} order={1} defaultSize={this.state.isRightEditPanel ? 67 : 100} className={this.state.isRightEditPanel ? "isShowLeftPanel" : ""}>
                {/* Xsm Break Fix Form tabs Section Start*/}
                  
                  {/* <InputNumber /> */}
                 <CheckoutPISection
                    cartOfferings={this.state.offeringsForOrder}
                    updateServicePrice={this.updateServicePrice}
                    cartListMessage={this.state.cartListMessage}
                    changeCompMessage={this.changeCompMessage}
                    selectedComponentForOffering={
                      this.state.selectedComponentForOffering
                    }
                    offeringId={offeringId}
                    setDeliveryOptionsForOfferings={
                      this.setDeliveryOptionsForOfferings
                    }
                    translator={this.props.tr}
                    piQuestionsMandatError={
                      this.state.checkPiQuestionsMandatError
                    }
                    loaderStatus={this.state.loaderStatus}
                    allServicePrices={this.state.allServicePrices}
                    haveMandatoryQuestionFlag={
                      this.props.haveMandatQuestionReducer
                        .serviceHaveMandatoryQuestions
                    }
                    bulkPurchaseQuestions={this.props.bulkPurchaseQuestions}
                    renderKnowledgeArticles={this.renderKnowledgeArticles}
                    disclaimerCheckbox={this.state.disclaimerCheckbox}
                    touched={this.state.touched}
                    updateDisclaimer={this.updateDisclaimer}
                    getOptionsFromChild= {this.getOptionsFromChild}
                    dispatch={this.props.dispatch}
                    setPISectionFormValues={this.setPISectionFormValues}
                    piSectionFormValues={this.state.piSectionFormValues}
                    setPISectionFormErrors={this.setPISectionFormErrors}
                    piSectionFormErrors={this.state.piSectionFormErrors}
                    setPISectionTableFormValues={this.setPISectionTableFormValues}
                    piSectionTableFormValues={this.state.piSectionTableFormValues}
                    setPISectionTableFormErrors={this.setPISectionTableFormErrors}
                    piSectionTableFormErrors={this.state.piSectionTableFormErrors}
                    language={language}
                    setServiceCriticalityandUrgency={this.setServiceCriticalityandUrgency}
                    serviceCriticality={this.state.serviceCriticality}
                    rightEditPanel={this.rightEditPanel}
                  />
              </Panel>
              {this.state.isRightEditPanel ?
              <>
              <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
              <Panel minSize={33} order={2} defaultSize={33} className="shopCartloader2">
                <div className="rBoxStyle">
                  <OrderingInformation
                    handleSubmit={handleSubmit}
                    key={this.state.allServicePrices.grandTotal()}
                    offeringsForOrder={this.state.offeringsForOrder}
                    disableOrderNowButton={this.state.disableOrderNowButton}
                    fromOrderNow="true"
                    placeOrder={this.placeOrder}
                    formValues={this.props.formValues}
                    dispatch={this.props.dispatch}
                    selectedComponentForOffering={
                      this.state.selectedComponentForOffering
                    }
                    offeringId={offeringId}
                    translator={this.props.tr}
                    cartActionsMessage={this.state.cartActionsMessage}
                    changeCompMessage={this.changeCompMessage}
                    requestorData={this.props.requestorData}
                    onSubmitClick={this.onSubmitClick}
                    loaderStatus={this.state.loaderStatus}
                    allServicePrices={this.state.allServicePrices}
                    setIsOnBehalfUserValid={this.setIsOnBehalfUserValid}
                    setVipMember={this.setVipMember}
                    setIsOtherDeliveryLocationValid={
                      this.setIsOtherDeliveryLocationValid
                    }
                    seachDiscription={this.state.seachDiscription}
                    setIsShippingLocationType={this.setShippingLocationType}
                    setIsShippingLocationAddress={this.setShippingLocationAddress}
                    setIsSelfSelected={this.setIsSelfSelected}
                    rightEditPanel={this.rightEditPanel}
                    />
                </div>
              </Panel>
              </> : ""
              }
            </PanelGroup>
            </div>
          </form>
        </div>
      </main>
    );
   }
  }
};

const updateFormFields = (question, questionsArray) => {
  questionsArray.push(question);
  for (let i = 0; i < question.childQuestions.length; i++) {
    updateFormFields(question.childQuestions[i], questionsArray);
  }
};

const mapStateToProps = (
  {
    haveMandatQuestionReducer,
    orderDeliverTypes,
    offeringForOrder,
    categoryParentQuestions,
    questionnaireList,
    spcmReducer,
    selectedServiceDeliveries,
    serviceQuestionnaireCategories,
    createTaskFormValues,
    bulkPurchaseQuestions,
    offeringDetails,
    userDefinedServiceQuestionnaireCategories
  },
  ownProps
) => {
  let questionsArray = [];
  if (serviceQuestionnaireCategories.length !== 0) {
    serviceQuestionnaireCategories.forEach((service) => {
      service.categories.forEach((category) => {
        category.parentQuestions.forEach((parentQuestion) => {
          updateFormFields(parentQuestion, questionsArray);
        });
        category.otherCategoryQuestions.forEach((parentQuestion) => {
          updateFormFields(parentQuestion, questionsArray);
        });
      });
    });
  }

  if (userDefinedServiceQuestionnaireCategories.length !== 0) {
    userDefinedServiceQuestionnaireCategories.forEach((service) => {
      service.categories.forEach((category) => {
        category.parentQuestions.forEach((parentQuestion) => {
          updateFormFields(parentQuestion, questionsArray);
        });
        category.otherCategoryQuestions.forEach((parentQuestion) => {
          updateFormFields(parentQuestion, questionsArray);
        });
      });
    });
  }
  const location = navigationHooks.location;
  //setting service disclaimer as redux fields
  let allServices = location?.state?.offeringsForOrder;
  let serviceDisclaimerFields = [];
  let itemQuantityFields = [];
  let bulkPurchaseQuestionInitialValues = [];
  let bulkPurchaseQuestionFields = [];
  if (allServices && allServices.length !== 0) {
    allServices.forEach((service) => {
      if (
        service.hasOwnProperty("serviceDisclaimer") &&
        service.serviceDisclaimer !== null
      ) {
        serviceDisclaimerFields.push({
          name: service.offeringCode,
          fieldType: "disclaimer",
        });
      }
      if (service.hideQuantity === "false") {
        itemQuantityFields.push({
          name: "itemQuantity_" + service.offeringId,
          fieldType: "itemQuantity",
          questionType: "mandatory",
          minValue: service.minValue === null ? 1 : service.minValue,
          maxValue: service.maxValue === null ? 5 : service.maxValue,
        });
      }
    });
  }

  /*creating  dynamic table fields*/
  if (Array.isArray(bulkPurchaseQuestions) && bulkPurchaseQuestions.length) {
    bulkPurchaseQuestions.forEach((category) => {
      category.categoryQuestions.forEach((question) => {
        let bulkQuestion = {};
        let fieldName =
          "bulkPurchase_" +
          category.serviceId +
          "_" +
          category.categoryId +
          "_" +
          question.qId;
        bulkQuestion[fieldName] = [{}];
        /*    bulkQuestion.name=fieldName;
            bulkQuestion.fieldType="bulkPurchase";
            bulkQuestion.columns = question.responseValueList;*/
        question.name = fieldName;
        question.fieldType = "bulkPurchase";
        question.columns = question.responseValueList;
        bulkPurchaseQuestionInitialValues.push(bulkQuestion);
        bulkPurchaseQuestionFields.push(question);
      });
    });
  }

  const staticFields = [
    { name: "requester" },
    { name: "recipient" },
    { name: "requesterId" },
    { name: "requesterName" },
    { name: "shippingLocation" },
    { name: "recipientContact" },
    { name: "recipientCubicle" },
    { name: "recipientId" },
    { name: "addressLine1" },
    { name: "addressLine2" },
    { name: "city" },
    { name: "state" },
    { name: "country" },
    { name: "postalCode" },
    { name: "recipientName" },
    { name: "requesterLocationName" },
    { name: "countryName" },
    { name: "requesterLocationId" },
    { name: "requesterContactNumber" },
    { name: "businessCriticality" },
    { name: "businessCriticalityId" },
  ];
  return {
    offeringDetails,
    haveMandatQuestionReducer,
    orderDeliverTypes,
    questionnaireList,
    categoryParentQuestions,
    tr: spcmReducer.tr,
    selectedServiceDeliveries,
    serviceQuestionnaireCategories,
    bulkPurchaseQuestions,
    requestorData: createTaskFormValues.requestorData,
    fields: [
      ...staticFields,
      ...questionsArray,
      ...serviceDisclaimerFields,
      ...itemQuantityFields,
      ...bulkPurchaseQuestionFields,
    ],
    dynamicFields: [
      ...questionsArray,
      ...serviceDisclaimerFields,
      ...itemQuantityFields,
      ...bulkPurchaseQuestionFields,
    ],
    questionsArray,
    initialValues: Object.assign({}, ...bulkPurchaseQuestionInitialValues),
  };
};

OrderNow = reduxForm({
  form: "orderingForm",
  enableReinitialize: true,
  validate,
  shouldValidate: () => true,
  forceValidate: true,
})(OrderNow);

const selector = formValueSelector("orderingForm");
OrderNow = connect((state) => {
  const formValues = selector(
    state,
    "requester",
    "recipient",
    "requesterId",
    "requesterName",
    "shippingLocation",
    "recipientContact",
    "recipientCubicle",
    "recipientId",
    "addressLine1",
    "addressLine2",
    "city",
    "state",
    "country",
    "postalCode",
    "recipientName",
    "requesterLocationName",
    "countryName",
    "requesterLocationId",
    "requesterContactNumber"
  );
  return {
    formValues,
  };
})(OrderNow);

export default connect(mapStateToProps, {
  resetCartQuestionsState,
  loadRequestorAndServiceData,
  getOfferingsInCart,
  resetOrderingInfoForm,
  loadCartCount,
  haveMandatoryQuestionFlagForService,
  getBulkPurchaseQuestions,
  fetchOfferingDetails,
  resetUrgencyImpactValues
})(OrderNow);
