
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ListLoader from "../common/loaders/ListLoader";
import TableComponent from "../common/ReactTable/TableComponent";

const SystemConfigListview = (props) => {
  const mailboxConfigList = useSelector((state) => state.systemCofigList);
  const showLoader = useSelector((state) => state.showLoader.loading);
  const translator = useSelector((state) => state.spcmReducer.tr);
  const [mailboxData, setMailBoxData] = useState([])
  const dispatch = useDispatch();

  const tableColumns = [
    {
      name: translator["Company"],
      selector: (row) => row.COMPANY_NAME,
      sortable: true,
    },
    {
      name: translator["Mailbox"],
      selector: (row) => row.MAILBOX_ID,
      sortable: true,
    },
    {
      name: translator["Vendor"],
      selector: (row) => row.MAILBOX_PROVIDER,
      sortable: true,
    },
    {
      name: translator["Service"],
      selector: (row) => row.OFFERING_NAME,
      sortable: true,
    },
    {
      name: translator["Status"],
      selector: (row) => row.STATUS,
      sortable: true,
    },
  ];
   
  useEffect(() => {
    if (mailboxConfigList.length > 0) {
      setMailBoxData(mailboxConfigList.map(obj => {
        obj.STATUS = obj.STATUS === 1 ? "Active" : "Inactive"
      }))
    }
  },[mailboxConfigList])

  const filterColumns = ["OFFERING_NAME", "MAILBOX_PROVIDER", "COMPANY_NAME","MAILBOX_ID","STATUS"];

  const onRowSelect = (row) => {
    props.setShortDescValues(row);
    props.showRightSide(true);
  };

  const renderMailboxConfigTableList = () => {
    return (
      <div className="dtaTableDv integrateHubTable">
        <div className={props.rightDisplay ? "leftTrue srchPosTop" : "rightTrue srchPosTop"}>
          <TableComponent
            data={mailboxConfigList}
            columns={tableColumns}
            headerColumns={filterColumns}
            onRowSelect={onRowSelect}
            rightDisplay={props.rightDisplay}
            uniqueKey={'UUID'}
          />
        </div>
      </div>
    );
  };

  if (!mailboxConfigList) return null;
  if (mailboxConfigList.length == undefined) {
    return <ListLoader />;
  } else if (mailboxConfigList.length === 0) {
    return (
        renderMailboxConfigTableList()
    )
  } else {
    return (
      <div className="myOrderDiv">
        {showLoader ? (
          <ListLoader />
        ) : (
            renderMailboxConfigTableList()
        )}
      </div>
    );
  }
};

export default SystemConfigListview;
