
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { loadAllScheduleList } from '_Actions/onCallSchedule/schedule/onCallScheduleAction';
import { HiPlus, HiOutlineRefresh } from "react-icons/hi";
import { _dropDown } from "../../../common/ReduxFormFields/CategoryBoard/formFields.js";
import { change, Field, reduxForm } from 'redux-form';
import { LuFilterX } from "react-icons/lu";
import CommonHeaderMenu from "../../common/CommonHeaderMenu.js";

const Header = (props) => {
  const dispatch = useDispatch();
  const tr = useSelector((state) => state.spcmReducer.tr);

  const prepareFilters = () => {
    let filter = {};
    if (props.nameSearchInput) {
      filter["scheduleName"] = props.nameSearchInput;
    }
    if (props.timezoneSearchInput) {
      filter["timeZone"] = props.timezoneSearchInput;
    }
    if (props.statusSearchInput) {
      filter["status"] = props.statusSearchInput;
    }
    if (props.typeSearchInput) {
      filter["scheduleType"] = props.typeSearchInput;
    }
    return filter;
  };

  return (
    <Row className="margin-b-15">
      <Col lg={8} md={6} sm={12} xs={12}>
        <CommonHeaderMenu active="Schedule"/>
      </Col>
      {/* <Col lg={4} md={6} sm={12} xs={12}>
        <Form.Group className='form-group'>
					<Field
						component={_dropDown}
						name="SHIFT_GROUP"
						options={props.onCallGroupList}
						className="form-control"
					/>
				</Form.Group>
      </Col> */}
      <Col lg={4} md={6} sm={12} xs={12}>
        <div className="paHedFilter">
          {props.isFilterApplied && (
            <Button
              bsPrefix=" "
              onClick={() => props.resetFilters()}
              role="button"
              className="myBt"
              title="Reset Filters"
            >
              <LuFilterX />
            </Button>
          )}
          <Link
            to="/onCallSchedule/createSchedule"
            role="button"
            className="myBt plus fillBtn"
            title="Create"
          >
            <HiPlus />
          </Link>
          <Button
            bsPrefix=" "
            title="Refresh"
            role="button"
            className="myBt"
            onClick={() => {
              props.showRightSideFun(false);
              let filter = prepareFilters();
              filter['page'] = 0;
              filter['size'] = 10;
              props.setActivePage(0);
              props.setItemsPerPage(10);
              dispatch(loadAllScheduleList(filter));
            }}
          >
            <HiOutlineRefresh className="refreshFlip" />
          </Button>
        </div>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => ({});

export default reduxForm({
  form: 'ScheduleListHeader',
})(connect(mapStateToProps)(Header));