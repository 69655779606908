
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import PindCategories from "../../../common/header/pind-categories";
import Breadcrumbs from "../../../common/header/breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import Header from "./header";
import List from "./list";
import ShortDesc from "./shortDesc";
import { getCostCenterList } from "../../../../actions/foundation/costCenterAction";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import "_Css/common/_table.scss";

const Index = () => {
  let dispatch = useDispatch();
  const [isRightSideVisible, setRightSideVisible] = useState(false);
  const [costCenterDetails, setCostCenterDetails] = useState({});
  const [rowIndex, setrowIndex] = useState(0);
  const [panelResize,setPanelResize] = useState();
  const [limit, setLimit] = useState("10");
  const [page, setPage] = useState("1");
  const [costCenterSearchInput, setCostCenterSearchInput] = useState("");
  const [companySearchInput, setCompanySearchInput] = useState("");
  const [selectedStatusValue, setSelectedStatusValue] = useState([]);
  const [status, setStatus] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [clearAllFilterToggle, setClearAllFilterToggle] = useState(false);

  const costCenterListData = useSelector((state) => state.costCenterList);
  const showLoader = useSelector((state) => state.commonLoader.isLoading);

  let colWidth = "";
  isRightSideVisible ? (colWidth = 8) : (colWidth = 12);

  useEffect(() => {
    dispatch(getCostCenterList(page, limit, "costcenterName", "asc", "", "", status));
  }, []);

  const setMultiSelectParams = (status) => {
    setStatus(status);
  }

  let meta = costCenterListData.meta;

  let size = meta && meta.limit ? meta.limit : 0;
  let currentPage = meta && meta.currentPage ? meta.currentPage : 0;

  let startLimit = size * (currentPage - 1) + 1;
  let endLimit = size * currentPage;
  let recordCount = meta && meta.rowCount ? meta.rowCount : 0;

  return (
    <main>
      <div>
        <PindCategories />
        <Container fluid className="margin-b-15">
          <Row>
            <Col md={8} xs={8}>          
              <Breadcrumbs activePageName={"Cost Center"} />
            </Col>
            <Col md={4} xs={4}>
              {recordCount != 0 ? (
                <div className="tabDatacount">
                  {startLimit}-
                  {endLimit > recordCount ? (endLimit = recordCount) : endLimit} of{" "}
                  {recordCount}
                </div>
              ) : null}
            </Col>
          </Row>
        </Container>

        <Container fluid className="position-re">
          <Header
            page={page}
            limit={limit}
            setPage={setPage}
            setLimit={setLimit}
            status={status}
            clearAllFilterToggle={clearAllFilterToggle}
            setClearAllFilterToggle={setClearAllFilterToggle}
            setRightSideVisible={setRightSideVisible}
            costCenterSearchInput={costCenterSearchInput}
            setCostCenterSearchInput={setCostCenterSearchInput}
            companySearchInput={companySearchInput}
            setCompanySearchInput={setCompanySearchInput}
            selectedStatusValue={selectedStatusValue}
            setSelectedStatusValue={setSelectedStatusValue}
            setMultiSelectParams={setMultiSelectParams}
            searchKey={searchKey}
            setSearchKey={setSearchKey}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
          <PanelGroup
            direction="horizontal"
            className={status && (costCenterListData.costCenterList == [] || costCenterListData.costCenterList?.length < 6 || showLoader) ? "formGroupB10 myProfileLabel myOrders overflow-clip" : "formGroupB10 myProfileLabel myOrders"}
          >
            <Panel
              id="sidebar"
              minSize={33}
              order={1}
              defaultSize={isRightSideVisible ? 67 : 100}
              onResize={(size) => setPanelResize(size)}
              className={`${status && (costCenterListData.costCenterList == [] || costCenterListData.costCenterList?.length < 6 || showLoader) ? "overflow-clip" : ""} ${(isRightSideVisible ? "isShowLeftPanel rwLeftPart" : "rwLeftPart")}`}>
              <List 
                page={page}
                limit={limit}
                setPage={setPage}
                setLimit={setLimit}
                status={status}
                clearAllFilterToggle={clearAllFilterToggle}
                setClearAllFilterToggle={setClearAllFilterToggle}
                setRightSideVisible={setRightSideVisible} 
                costCenterSearchInput={costCenterSearchInput}
                setCostCenterSearchInput={setCostCenterSearchInput}
                companySearchInput={companySearchInput}
                setCompanySearchInput={setCompanySearchInput}
                selectedStatusValue={selectedStatusValue}
                setSelectedStatusValue={setSelectedStatusValue}
                setMultiSelectParams={setMultiSelectParams}
                setSearchKey={setSearchKey}
                setSearchValue={setSearchValue}
                searchKey={searchKey}
                searchValue={searchValue}
                setCostCenterDetails={setCostCenterDetails} 
                setrowIndex={setrowIndex}
                rowIndex={rowIndex}
                isRightSideVisible={isRightSideVisible}
                panelResize={panelResize}
              />
            </Panel>
            {isRightSideVisible ? (
              <>
                <PanelResizeHandle className="isShowLeftPanel resizeHandle">
                  <div className="outlne">
                    <div className="handIcn">
                      <i className="fa fa-chevron-left" aria-hidden="true"></i>
                    </div>
                  </div>
                </PanelResizeHandle>
                <Panel minSize={33} order={2} defaultSize={33}>
                  <div className="stickyArea rBoxStyle">
                    <ShortDesc
                      setRightSideVisible={setRightSideVisible}
                      costCenterDetails={costCenterDetails}
                    />
                  </div>
                </Panel>
              </>
            ) : (
              ""
            )}
          </PanelGroup>
        </Container>
      </div>
    </main>
  );
};

export default Index;
