
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";

export function generateLastMonths(monthRange) {
  const currentDate = new Date();
  const lastMonths = [];

  for (let i = monthRange - 1; i >= 0; i--) {
    const monthDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - i,
      1
    );
    const monthName = monthDate.toLocaleString("default", { month: "long" });
    const year = monthDate.getFullYear();
    const numericValue = monthDate.getMonth() + 1;

    //lastMonths.push(`${monthName} ${year}`);
    lastMonths.push({
      monthName,
      year,
      numericValue,
    });
  }

  return lastMonths;
}
