
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { ButtonToolbar, OverlayTrigger, Popover} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import ListGroup  from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import { GLOBAL } from "_Globals";
import axios from 'axios';
import { connect } from 'react-redux';
import { MyContext } from '_MyContext';

const MoreArrow = <i title="More" className="fa fa-angle-double-down f-size-15" ></i>;

import { loadBreakFixActivityLogDetails } from '_Actions/breakFix/breakFixActivityLogAction';
import { loadOrdersList, loadOrderDetails, loadBreakFixOrderDetails, loadApproverList, resetSortingArrows } from '_Actions/serviceDeskActions/myOrdersAction';
import { loadChangeTotalApprovalDetails, loadChangeTotalApprovalHistoryDetails } from '_Actions/changeManagement/changeTotalApprovalAction';
import Spinner from 'react-spinkit';
import { bindActionCreators } from 'redux';
import swal from 'sweetalert';
import {IoClose} from "react-icons/io5";
import { loadImplPlanList } from "_Actions/common/attachmentAction";
import ListLoader from "_Commons/loaders/ListLoader";
import AttachmentFormTab from "./serviceDeskAttachment.js";

import { formatMinutesIntoDaysHoursMinutes } from '_Actions/common/functionActions.js';
import ReactReadMoreLess from '../../helpers/ReactReadMoreLess.js';
import {ImSpinner6} from "react-icons/im";
import { Link } from 'react-router-dom';
const Joi = require('joi')
//let user_id=userDetails.user_id;
//let full_name=userDetails.full_name;
//let user_name=userDetails.user_name;
//let company_id=userDetails.company_id;
//let currency = userDetails.currency;
class ServiceDeskRightSide extends React.Component {
	static contextType = MyContext;
	constructor(props) {
		super(props);
		this.state = {
			activityLogHideShow: 0,
			showUserData: false,
			quickViewOrders: {},
			comments: '',
			showUpdates: 0,
			viewUpdates: 0,
			sortingArrow: 0,
			cancelHideShow: 0,
			issueValidationError: '',
			savedMessage: '',
			issue: '',
			disabledSave: false,
			// openAttachmentOption:false,
			commentsAreEmpty: false,
			showAttachWithoutComments: false,
			displayMessage: "",
			canErrorCode: "",
            cancelCode: "",
            cancelValue: ""
		};
		this.showUserDetails = this.showUserDetails.bind(this);
		this.activityData = this.activityData.bind(this);
		this.postActivityData = this.postActivityData.bind(this);
		this.getActivityLog = this.getActivityLog.bind(this);
		this.viewUpdateShow = this.viewUpdateShow.bind(this);
		this.cancelHideShow = this.cancelHideShow.bind(this);
		this.groupPopoverTable = this.groupPopoverTable.bind(this);
		this.individualPopoverTable = this.individualPopoverTable.bind(this);
		// this.openAttachmentOption = this.openAttachmentOption.bind(this);
		this.renderQuestionCategoryWise = this.renderQuestionCategoryWise.bind(this);
		this.renderIndividualCategoryQuestions = this.renderIndividualCategoryQuestions.bind(this);
		this.postAttachmentWithoutComments = this.postAttachmentWithoutComments.bind(this);
		this.renderOrderingInformation = this.renderOrderingInformation.bind(this);
		this.renderAdditionalDetails = this.renderAdditionalDetails.bind(this);
		this.checkRenderApproverData = this.checkRenderApproverData.bind(this);
		this.setCancellationType = this.setCancellationType.bind(this);

		GLOBAL.filesToBeSent = [];
		/*const initData = {
			"commentTest": this.name.commentTest
			 };
			 this.props.initialize(initData);
			 alert(commentTest)*/
	}


	// openAttachmentOption() {
	// 	this.setState({openAttachmentOption:true});
	// }

	componentDidMount() {
		this.context.keyDownHandler(this.closeButton, "", "", "");
	}
	componentDidUpdate() {
		this.context.keyDownHandler(this.closeButton, "", "", "");
	}

	groupPopoverTable(tabledata) {
		// console.log("grp tabledata :: ",tabledata)
		return tabledata.map((data, index) => {
			let statusapp = data.status;
			if (statusapp == "null" || statusapp == "" || statusapp == null)
				statusapp = "Pending"
			else
				statusapp = data.status;

			return (<tr>
				<td >
					{data.name}
				</td>
				<td >
					{statusapp}
					{data.approvedby != null ? " (" + data.approvedby + ")" : ""}
				</td>
				<td >
					{data.date}
				</td>
				<td >
					{data.reason}
				</td>
			</tr>)
		})
	}

	individualPopoverTable(tabledata) {
		//console.log("ind tabledata :: ", tabledata);
		return (<tr>
			<td >
				{tabledata.name}
			</td>
			<td >
				{tabledata.reason}
			</td>
		</tr>)
	}

	getAttachment(itemId, Module) {
		this.props.loadImplPlanList(Module, itemId);
	}
	renderAttachedItems(attachments) {

		if (_.isEmpty(attachments)) {
			return (
				<div>
					<h4>No File Found</h4>
				</div>
			);
		}

		return attachments.map(attachment => {

			let validated_url = undefined
			if (attachment.fileTempURL != null || attachment.fileTempURL != "") {
				let namedSchema = Joi.string().regex(/^[^<>}{]+$/)
				let validator_to_add_myQbj = namedSchema.validate(attachment.fileTempURL)
				if (validator_to_add_myQbj.error == null) {
					validated_url = attachment.fileTempURL
				}
				else {
					console.log("Validation failed : ", validator_to_add_myQbj)
					validated_url = "\\"
				}
			}
			else
				validated_url = "\\"

			console.log("URL to be displayed : ", validated_url)


			if (attachment.filename !== null) {
				if (attachment.status == "Y") {
					return null;
				} else {
					return (
            <ListGroup.Item
              bsPrefix=" "
              className="attachmentList"
              style={{
                width: "100%",
                "margin-bottom": "5px",
                display: "inline-flex",
              }}
              key={attachment.attachmentId}
            >
              <div
                style={{ width: "92%", float: "left", wordBreak: "break-all" }}
              >
                {attachment.filename}
              </div>{" "}
              <div style={{ width: "8%" }}>
                  <a href={validated_url}>
                    {/* <a href={attachment.fileTempURL}> */}
                    <i className="fa fa-eye" />
                  </a>
                  &nbsp;&nbsp;
              </div>
            </ListGroup.Item>
          );
				}
			}
		});
	}
	cancelHideShow() {
		this.setState({
			activityLogHideShow: 0,
			viewUpdates: 0,
			cancelHideShow: !this.state.cancelHideShow,
			//savedMessage:""
		});
	}
	activityLogHideShow() {
		if (this.state.activityLogHideShow == 1)
			this.setState({ comments: '' });
		this.setState({
			activityLogHideShow: !this.state.activityLogHideShow,
			viewUpdates: 0,
			cancelHideShow: 0,
			savedMessage: ""
		});
	}

	viewUpdateShow() {
		this.getActivityLog();
		this.setState({
			activityLogHideShow: 0,
			viewUpdates: !this.state.viewUpdates,
			cancelHideShow: 0,
			savedMessage: ""

		});
	}
	showUserDetails() {
		this.setState({ showUserData: !this.state.showUserData });
	}

	ascactivitysort() {
		this.setState({
			sortingArrow: 0
		})

		let module = this.props.module;
		let itemId = this.props.itemId;
		this.props.loadBreakFixActivityLogDetails(itemId, "asc", "Fullfilment");
	}
	descactivitysort() {
		this.setState({
			sortingArrow: 1
		})


		let module = this.props.module;
		let itemId = this.props.itemId;
		this.props.loadBreakFixActivityLogDetails(itemId, "desc", "Fullfilment");

		//this.props.loadBreakFixActivityLogDetails(GLOBAL.breakFixId,"desc");
	}



	renderApproverList(approverData) {
		//console.log(approverData);
		if (!approverData) {
			//alert("No data")
			return (
				<div></div>
			);
		} else {
			// console.log(breakFixData[0]);
			//this.props.getItemDetails(approverData[0].breakfixId);
			return approverData.map((approverObj, index) => {
				//console.log('breakfixobj'+breakFixObj.breakfixId)
				return (
					<ListGroup.Item bsPrefix=' '><span className="labSpan">{this.props.tr['Approver Details']}-</span>{approverObj.signatures}</ListGroup.Item>
				);
			});
		}
	}

	renderActivityLog(activityLogData) {
		//alert(""+activityLogData.length)
		if (activityLogData.length == 0) {
			return (
				<div>{this.props.tr['No updates found']}</div>
			);
		}
		else {


			return activityLogData.map((activityObj, index) => {

				let intval = "", activitymsg = "";
				if (activityObj.isInternal == "Y")
					intval = "- Internal"
				//	console.log("from:::"+activityObj.from+"to::::"+activityObj.to)
				if (activityObj.from === activityObj.to)
					activitymsg = "Status set to " + activityObj.to;
				else
					activitymsg = "Changed Status from " + activityObj.from + " to " + activityObj.to
				if (activityObj.isInternal != "Y")
					return (
						<Card className='mediaCrd'>
							<Card.Body>
								<Card.Title className="blue margin-0">{activityObj.createdOn} <span className="colorStOrange cursorPoint" onClick={this.showUserDetails}>{activityObj.createdByName}</span></Card.Title>
								<div className="black fw-300 cTxt">
								<ReactReadMoreLess 
									charLimit={150}
									readMoreText={MoreArrow}
									content={activityObj.from == "" ? activityObj.description : activitymsg}
								/>
								</div>
							</Card.Body>
						</Card>
					);
			});
		}
	}

	activityData(comnts) {
		comnts.preventDefault();
		this.setState({ comments: comnts.target.value });
		if (comnts != '')
			this.setState({ commentsAreEmpty: false });
		//console.log("comments"+this.state.comments)

	}

	getActivityLog() {
		let itemId = this.props.itemId;
		//console.log("itemId"+itemId)
		//alert(this.state.quickViewOrders.orderNumber
		this.props.loadBreakFixActivityLogDetails(itemId, "desc", "Fullfilment");
	}

	postActivityData() {

		let itemId = this.props.itemId;
		//console.log(itemId);
		let module = "Fullfilment";
		//let self=this.commentTest.value;
		//console.log(self)
		if (this.state.comments != "") {
			this.setState({ commentsAreEmpty: false });
			//this.props.fun(false,true,true);
			let ref = this;

			this.setState({ disabledSave: true });
			//	console.log("disabledSave :::: "+this.state.disabledSave);
			// alert(this.state.disabledSave);
			axios.post(GLOBAL.breakFixActivityLogPostUrl, {
				"itemId": itemId,
				"description": this.state.comments,
				"createdOn": "",
				"createdBy": "",
				"isInternal": "N",
				"module": module,
				"createdByName": ""
			}).
				then((response) => {
					//alert("Data successfully submitted")
					//console.log(response.statusText);
					if (response.status === 201) {
						ref.inputComments.value = "";
						ref.setState({ comments: '' });
						if (GLOBAL.filesToBeSent.length > 0) {
							//alert("called")
							let formData = new FormData();
							formData.append('file', GLOBAL.filesToBeSent[0][0]);
							let jsonObject = new Object;
							jsonObject.module = ref.props.module;
							jsonObject.itemId = itemId;
							jsonObject.createdOn = Math.round(new Date().getTime() / 1000.0);
							jsonObject.createdBy = "";
							formData.append("attachment", JSON.stringify(jsonObject));

							axios.post("/change/api/saveImplPlan", formData).then((response) => {
								//debugger
								//	console.log(response)
								let res = response.data
								if (response.status == 200) {
									//this.props.loadImplPlanList(jsonObject.module, jsonObject.itemId)
									//alert('file uploaded successfully.');
									//this.setState({ filesToBeSent: [], filesPreview: [], clickAttachButton: 0 });
									ref.setState({
										activityLogHideShow: 0,
										viewUpdates: 0,
										disabledSave: false
									});
									GLOBAL.filesToBeSent = [];
									ref.getAttachment(itemId, "Item");
								}
							}).catch((error) => {
								//	console.log('Please upload File up to 20 MB size.');
								//console.log("error in uploading file.");
							});
						}
						ref.setState({
							activityLogHideShow: 0,
							viewUpdates: 0,
							disabledSave: false
						});
					} else {
						//console.log("response status is not ok:"+response.statusText)
					}
					this.props.loadBreakFixActivityLogDetails(response.data.itemId, "desc", module);
				});
		} else {
			this.setState({ commentsAreEmpty: true });
			//alert('Please mention activity');
		}

		console.log("disabledSave :::: " + this.state.disabledSave);
	}

	postAttachmentWithoutComments() {
		let ref = this;
		let itemId = this.props.itemId;
		let module = "Fullfilment";
		this.setState({ disabledSave: true });
		if (GLOBAL.filesToBeSent.length > 0) {
			//alert("called")
			let formData = new FormData();
			formData.append('file', GLOBAL.filesToBeSent[0][0]);
			let jsonObject = new Object;
			jsonObject.module = ref.props.module;
			jsonObject.itemId = itemId;
			jsonObject.createdOn = Math.round(new Date().getTime() / 1000.0);
			jsonObject.createdBy = "";
			formData.append("attachment", JSON.stringify(jsonObject));

			axios.post("/change/api/saveImplPlan", formData).then((response) => {
				console.log(response)
				let res = response.data
				if (response.status == 200) {
					//this.props.loadImplPlanList(jsonObject.module, jsonObject.itemId)
					//alert('file uploaded successfully.');
					swal({
						title:"File uploaded successfully.",
						button:{
							text: "OK",
							visible: true,
							closeModal: true,
						  }  
					});
					//this.setState({ filesToBeSent: [], filesPreview: [], clickAttachButton: 0 });
					ref.setState({
						activityLogHideShow: 0,
						viewUpdates: 0,
						disabledSave: false,
						showAttachWithoutComments: false
					});
					GLOBAL.filesToBeSent = [];
					ref.getAttachment(itemId, "Item");
				}
				this.props.loadBreakFixActivityLogDetails(itemId, "desc", module);
			}).catch((error) => {
				console.log('Please upload File up to 20 MB size.');
				console.log("error in uploading file.");
			});
		}
		else {
			this.setState({ disabledSave: false });
			alert("Please upload some files first");
		}
	}

	renderAppHistoryDataLog(relatedAppHistoryDataLog) {
		if (!relatedAppHistoryDataLog) {
			return (
				<tr>
					<td width="100%">
						<div>{this.props.tr['Data not available.']}</div>
					</td>
				</tr>
			);
		}
		else {
			return relatedAppHistoryDataLog.map((relatedAppHistoryData, index) => {
				let statusapp = relatedAppHistoryData.status;
				let orderStatusApp = relatedAppHistoryData.orderStatus;
				let statusappColor = '';
				if (orderStatusApp == 'Cancelled') {
					statusapp = "Cancelled";
				} else if (statusapp == "null" || statusapp == "" || statusapp == null) {
					statusapp = "Pending"
				} else {
					statusapp = relatedAppHistoryData.status;
				}

				if (statusapp == "Approved") {
					statusappColor = "greenDot";
				} else if (statusapp == "Rejected") {
					statusappColor = "redDot";
				} else if (statusapp == "Pending") {
					statusappColor = "blueDot";
				} else if (statusapp == "Refer Back") {
					statusappColor = "orangeDot";
				} else if (statusapp == "Cancelled") {
					statusappColor = "redDot";
				}

				let approverDetails = '';
				approverDetails = relatedAppHistoryData.approverDetails != null ? relatedAppHistoryData.approverDetails : '';
				//	console.log("approverDetails ::: ",relatedAppHistoryData);



				let indData = { name: relatedAppHistoryData.signatures, reason: relatedAppHistoryData.rejectreason };

				let individualPopoverRej = (
					<Popover id={index + "ind"} title="Rejection Reason" className="approvalPopup">
						<Table className="table table-striped table-bordered table-condensed table-hover tablesl ">
							<thead>
								<tr>
									<th >Name</th>
									<th >Comments</th>
								</tr>
							</thead>
							<tbody>
								{this.individualPopoverTable(indData)}
							</tbody>
						</Table>
					</Popover>
				);

				let indApprovalData = { name: relatedAppHistoryData.signatures };
				relatedAppHistoryData.status == "Refer Back" ? indApprovalData.reason = relatedAppHistoryData.referbackComments : indApprovalData.reason = relatedAppHistoryData.approvalComments;
				let individualPopoverApproval = (
					<Popover id={index + "ind" + index} title={relatedAppHistoryData.status == "Refer Back" ? "Refer Back Comment" : "Approver Comment"} className="approvalPopup">
						<Table className="table table-striped table-bordered table-condensed table-hover tablesl ">
							<thead>
								<tr>
									<th >Name</th>
									<th >Comments</th>
								</tr>
							</thead>
							<tbody>
								{this.individualPopoverTable(indApprovalData)}
							</tbody>
						</Table>
					</Popover>
				);

				let groupPopoverRej = (
					<Popover id={index + "groupRej"} title="Rejection Reason" className="approvalPopup">
						<Table className="table table-striped table-bordered table-condensed table-hover tablesl ">
							<thead>
								<tr>
									<th >Name</th>
									<th >Status</th>
									<th >Date & Time</th>
									<th >Comments</th>
								</tr>
							</thead>
							<tbody>
								{approverDetails == '' ?
									<tr>
										<td colspan="4">
											{this.props.tr['Data not available.']}
										</td>
									</tr>
									:
									this.groupPopoverTable(approverDetails)}
							</tbody>
						</Table>
					</Popover>
				);

				let groupPopover = (
					<Popover id={index + "group"} title={(relatedAppHistoryData.adhocApproverFlag == "Y") ? (relatedAppHistoryData.orderStatus == "Cancelled") ? "" : this.props.tr['Adhoc Approvals'] : (relatedAppHistoryData.status == "Cancelled") ? "" : this.props.tr["Group Approvals"]} className="approvalPopup">
						{relatedAppHistoryData.orderStatus == "Cancelled" ?
							this.props.tr['Data not available.'] :
							<Table className="table table-striped table-bordered table-condensed table-hover tablesl ">
								<thead>
									<tr>
										<th >Name</th>
										<th >Status</th>
										<th >Date & Time</th>
										<th >Comments</th>
									</tr>
								</thead>
								<tbody>
									{approverDetails == '' ?
										<tr>
											<td colspan="4">
												{this.props.tr['Data not available.']}
											</td>
										</tr>
										:
										this.groupPopoverTable(approverDetails)}
								</tbody>
							</Table>}
					</Popover>
				);

				return (
					<tr>
						<td >
							{statusapp == "Rejected" ?
								(relatedAppHistoryData.approverType != "Group" && relatedAppHistoryData.approverType != "GBO" && relatedAppHistoryData.adhocApproverFlag != "Y" ?
									<OverlayTrigger
										trigger='click' rootClose placement="bottom" overlay={individualPopoverRej}>
										<Button className={statusappColor} title={statusapp} variant="success"></Button>
									</OverlayTrigger>
									:
									(relatedAppHistoryData.approverType == "Group" || relatedAppHistoryData.adhocApproverFlag == "Y" ?
										<span className={statusappColor} title={this.props.tr[statusapp]}></span>
										:
										<OverlayTrigger
											trigger='click' rootClose placement="bottom" overlay={groupPopoverRej}>
											<Button className={statusappColor} title={statusapp} variant="success"></Button>
										</OverlayTrigger>))
								:
								(relatedAppHistoryData.approverType != "Group" && relatedAppHistoryData.approverType != "GBO" && relatedAppHistoryData.adhocApproverFlag != "Y" ?
									<OverlayTrigger
										trigger='click' rootClose placement="bottom" overlay={individualPopoverApproval}>
										<Button className={statusappColor} title={statusapp} variant="success"></Button>
									</OverlayTrigger>
									:
									<span className={statusappColor} title={statusapp}></span>
								)
							}
						</td>

						<td >
							{relatedAppHistoryData.levelname}
						</td>
						<td >
							{relatedAppHistoryData.approverType.trim() != "Group" || relatedAppHistoryData.approverType.trim() != "GBO" ?
								relatedAppHistoryData.signatures == "null" || relatedAppHistoryData.signatures == null ?
									"Approver does not exist" : relatedAppHistoryData.signatures :
								relatedAppHistoryData.signatures}
							{relatedAppHistoryData.approverType.trim() == "Group" || relatedAppHistoryData.approverType.trim() == "GBO" || relatedAppHistoryData.adhocApproverFlag == "Y" ? <OverlayTrigger
								trigger='click' rootClose placement="bottom" overlay={groupPopover}>
								<Button className="btninfo">i</Button>
							</OverlayTrigger> : ""}
						</td>
						<td >
							{relatedAppHistoryData.lastUpdatedDate}
						</td>
					</tr>
				)
			});




		}
	}

	renderApprovalHistoryDataLog(relatedAppHistoryDataLog) {
		//alert(""+relatedAppHistoryDataLog)
		if (!relatedAppHistoryDataLog) {
			return (
				<tr>
					<td width="100%">
						<div>{this.props.tr['Data not available.']}</div>
					</td>
				</tr>
			);
		}
		else {


			return relatedAppHistoryDataLog.map((relatedAppHistoryData, index) => {

				//alert(index)
				return (

					<div>
						<div className="rwTable" style={{ 'border-top': 'none' }}>
							<div md={4} className="padding-r-0 rwKey">{this.props.tr['Level Name']}</div>
							<div>{relatedAppHistoryData.levelname}</div>

						</div>

						<div className="rwTable">
							{<div md={4} className="padding-r-0 rwKey">{relatedAppHistoryData.approverType.trim() == "Group" ? this.props.tr['Approver Group'] : this.props.tr['Approver Name']}</div>}
							<div>{relatedAppHistoryData.signatures}</div>
						</div>

						<div className="rwTable">
							<div md={4} className="padding-r-0 rwKey">{this.props.tr['Status']}</div>
							<div>{relatedAppHistoryData.status}</div>
						</div>

						<div className="rwTable">
							<div md={4} className="padding-r-0 rwKey">{this.props.tr['App/Rej By']}</div>
							<div>{relatedAppHistoryData.modifiedby}</div>
						</div>


						{relatedAppHistoryData.status == "Rejected" ?
							<div>
								<div className="rwTable" >
									<div md={4} className="padding-r-0 rwKey">{this.props.tr['App/Rej Date']}</div>
									<div>{relatedAppHistoryData.modifieddate}</div>
								</div>
								<div className="rwTable" style={{ 'border-bottom': 'none' }}>
									<div md={4} className="padding-r-0 rwKey">{this.props.tr['Rejected Reason']}</div>
									<div>{relatedAppHistoryData.rejectreason}</div>
								</div>
							</div>
							:
							<div className="rwTable" style={{ 'border-bottom': 'none' }}>
								<div md={4} className="padding-r-0 rwKey">{this.props.tr['App/Rej Date']}</div>
								<div>{relatedAppHistoryData.modifieddate}</div>
							</div>}

						{/* <Card.Body>
					<Row>
						<Col md={10} className="padding-r-0"><Card.Heading className="blue margin-0">{relatedData.createdOn}  - <span className="colorStOrange cursorPoint" onClick={this.showUserDetails}>{relatedData.createdBy}</span></Card.Heading><div className="black fw-300 cTxt"><ReadMore lines={1} onShowMore={this.props.onChange} text={MoreArrow}></ReadMore></div></Col>

					</Row>
				  </Card.Body>*/}

					</div>
				);
			});

		}
	}

	getIssue(event) {
		//issue="";
		this.setState({
			issue: event.target.value,
			issueValidationError: event.target.value.trim().length===0?'error':''
		})
		//issue=event.target.value;
	}

	componentWillReceiveProps(newProps) {
		if(typeof newProps.myOrderOrderDetails[0]!=='undefined' && typeof this.props.myOrderOrderDetails[0]!=='undefined'){
			if(typeof newProps.myOrderOrderDetails[0].status!=='undefined'){
                if(newProps.myOrderOrderDetails[0].status !== this.props.myOrderOrderDetails[0].status){
					this.setState({showAttachWithoutComments: false});
			}
		}
	}
		this.setState({
			savedMessage: ""
			// cancelHideShow:0
		})
	}


	postCancellationRequest() {
		if(this.state.cancelCode===''){
		 this.setState({canErrorCode: "error"});
		 return;
		}
		if (this.state.issue.trim().length === 0) {
			this.setState({
				issueValidationError: "error"
			})
		} else {
			this.setState({
				issueValidationError: ''
			})
		}
		let ref = this;

		if (this.state.issue != "") {
			if (this.props.myOrderOrderDetails[0].itemStatus == "65") {
				// alert('changing state');
				this.setState({ disabledSave: true });
				console.log("disabledSave :::: " + this.state.disabledSave);
				axios.patch(GLOBAL.fulfillmentpost + this.props.itemId, {
					"itemStatus": "85",
					"itemType": "70",
					"cancelledOn": "",
					"modifiedBy": "",
					"modifiedByName": "",
					"cancellationReasonCode": this.state.cancelCode,
                    "cancellationReasonValue": this.state.cancelValue, 
					"cancellationReason": this.state.issue.replace(/</g, "").replace(/>/g, "").replace(/"/g, "").replace(/\\/g, "\\\\").replace(/(\r\n|\n|\r)/gm, "")
				})
					.then(function (response) {
						console.log(response);
						//alert("ABC"+response.data.breakfixId);
						//alert(response.statusText);
						if (response.status == 201) {
							ref.props.loadOrdersList("requesterId", "type", "", "createdOn", "desc", 0, 10);
							//	ref.props.loadApproverList(response.data.itemId, response.data.offeringIdSx);
							ref.props.loadChangeTotalApprovalDetails(response.data.itemId, "10", "N");
							ref.props.loadChangeTotalApprovalHistoryDetails(response.data.itemId, "10", "Y");
							ref.props.resetSortingArrows();
							ref.inputComments.value = "";
							ref.setState({
								//savedMessage:"Requested cteated for order "+response.data.breakfixNumber
								// savedMessage:ref.props.tr['Cancellation request created #']+response.data.orderNumber,
								savedMessage: 'Cancellation request created',
								cancelHideShow: 0,
								disabledSave: false
							})
							axios.post(GLOBAL.breakFixActivityLogPostUrl, {
								"itemId": ref.props.itemId,
								"description": `Cancellation Type: ${ref.state.cancelValue}, Cancellation Reason: ${ref.state.issue}`,
								"createdOn": "",
								"createdBy": "",
								"isInternal": "N",
								"module": ref.props.module==='Item'?'Fullfilment':ref.props.module,
								"createdByName": ""
							})
								.then(function (response) {
									if (response.status == 201) {

									}
									console.log(response);
								})
						} else {
							console.log("error in updating status")
						}
					})
					.catch(function (error) {
						console.log(error);
						//alert("H1"+error);
					});
			}
			else if (this.props.myOrderOrderDetails[0].itemStatus == "70") {
				// alert('changing state');
				this.setState({ disabledSave: true });
				console.log("disabledSave :::: " + this.state.disabledSave);
				axios.patch(GLOBAL.fulfillmentpost + this.props.itemId, {
					"itemStatus": "60",
					"holdingReasonCode": "110",
					"modifiedBy": "",
					"modifiedByName": "",
					"cancellationReasonCode": this.state.cancelCode,
                    "cancellationReasonValue": this.state.cancelValue,
					"cancellationReason": this.state.issue.replace(/</g, "").replace(/>/g, "").replace(/"/g, "").replace(/\\/g, "\\\\").replace(/(\r\n|\n|\r)/gm, "")
				})
					.then(function (response) {
						console.log(response);
						//alert("ABC"+response.data.breakfixId);
						//alert(response.statusText);
						if (response.status == 201) {
							ref.props.loadOrdersList("requesterId", "type", "", "createdOn", "desc", 0, 10);
							//	ref.props.loadApproverList(response.data.itemId, response.data.offeringIdSx);
							ref.props.loadChangeTotalApprovalDetails(response.data.itemId, "10", "N");
							ref.props.loadChangeTotalApprovalHistoryDetails(response.data.itemId, "10", "Y");
							ref.props.resetSortingArrows();
							ref.inputComments.value = "";
							ref.setState({
								//savedMessage:"Requested cteated for order "+response.data.breakfixNumber
								// savedMessage:ref.props.tr['Cancellation request created #']+response.data.orderNumber,
								savedMessage: 'Cancellation request created',
								cancelHideShow: 0,
								disabledSave: false
							})
							axios.post(GLOBAL.breakFixActivityLogPostUrl, {
								"itemId": ref.props.itemId,
								"description": `Cancellation Type: ${ref.state.cancelValue}, Cancellation Reason: ${ref.state.issue}`,
								"createdOn": "",
								"createdBy": "",
								"isInternal": "N",
								"module": ref.props.module==='Item'?'Fullfilment':ref.props.module,
								"createdByName": ""
							})
								.then(function (response) {
									if (response.status == 201) {

									}
									console.log(response);
								})
						} else {
							console.log("error in updating status")
						}
					})
					.catch(function (error) {
						console.log(error);
						//alert("H1"+error);
					});
			}
		} else {
			console.log("Cancellation reason not found");
		}
	}

	renderQuestionCategoryWise(myOrderQuestionDetails) {
		return [].concat(myOrderQuestionDetails).sort((a, b) => a.categorySequence > b.categorySequence ? 1 : -1).map((category, i) => {
			return (
		  <Accordion id="rightSidePanel0" className="brkFix brkFixOrder" defaultActiveKey={0}>
            <Accordion.Item eventKey="1">
              <Accordion.Header>{category.categoryName}</Accordion.Header>
              <Accordion.Body>
                <div key={i}>
                  <Table
                    bordered
                    condensed
                    hover
                    responsive
                    className="tableView addWhiteSpc"
                  >
                    <tbody>
                      {this.renderIndividualCategoryQuestions(
                        category.questions
                      )}
                    </tbody>
                  </Table>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
      );
		})
	}

	renderIndividualCategoryQuestions(categoryQuestions) {
		let answers = "";
			return [].concat(categoryQuestions).sort((a, b) => a.questionSequence > b.questionSequence ? 1 : -1)
			.map((categoryQuestion, i) => {
				if (categoryQuestion.question == "Assets") {
					//	console.log("assets answer");
					//	console.log(categoryQuestion.answer);
					let newstringreplaced = "";
					if(typeof categoryQuestion.answer === 'undefined' || categoryQuestion.answer === null ||  categoryQuestion.answer === ""){
						answers = "NA";
					}else if (categoryQuestion.answer != null && categoryQuestion.answer != "") {
						newstringreplaced = categoryQuestion.answer.replace(/,Name/gi, "~Name");
						answers = newstringreplaced.split('~');
					}
					else {
						answers = categoryQuestion.answer;
					}
	
				}
				return (
	
					<tr key={i}>
						<td className="bgBtn20 f-size-13" style={{ "width": "50%" }}>{categoryQuestion.question}</td>
						<td className="f-size-13">
	
							{/*	{categoryQuestion.question !== "Assets" ?categoryQuestion.answer :<div className="assetsAnswers">{answers.map((a,i)=>{return <p>{a}</p>})};</div>}*/}
							{categoryQuestion.question !== "Assets" ? typeof categoryQuestion.answer === 'undefined' || categoryQuestion.answer === null ||  categoryQuestion.answer === "" ? 'NA' : (
								categoryQuestion.answer.replace(/ ~ /g, "\n").split("\n").map((data) => {
									console.log(data)
									return <p>{data}</p>
								})
							) : answers != null ? (
								<div className="assetsAnswers">
									{answers.map((a, i) => {
										return <p>{a}</p>;
									})}
									;
								</div>
							) : null}
						</td>
					</tr>
				);
			});
	}

	renderOrderingInformation(orderingInfo) {
		return (
			<div className="table-responsive">
				<Table bordered condensed hover responsive className="tableView sortImg">
					<tbody>
						{((orderingInfo.addressLine1 != null && orderingInfo.addressLine1 != undefined && orderingInfo.addressLine1 != "") ||
							(orderingInfo.addressLine2 != null && orderingInfo.addressLine2 != undefined && orderingInfo.addressLine2 != "") ||
							(orderingInfo.city != null && orderingInfo.city != undefined && orderingInfo.city != "") ||
							(orderingInfo.country != null && orderingInfo.country != undefined && orderingInfo.country != "") ||
							(orderingInfo.postalCode != null && orderingInfo.postalCode != undefined && orderingInfo.postalCode != "")
						)
							||
							(orderingInfo.locationName != null && orderingInfo.locationName != undefined && orderingInfo.locationName != "") ||
							(orderingInfo.recipientCubicle != null && orderingInfo.recipientCubicle != "null" && orderingInfo.recipientCubicle != undefined && orderingInfo.recipientCubicle != "")
							?
							<tr>
								<td className="bgBtn19 font2" style={{ "width": "50%" }}>Shipping Location</td>
								<td className="font2">
									{orderingInfo.locationName == null || orderingInfo.locationName == "" ||
										orderingInfo.locationName == undefined ?
										<div>
											{orderingInfo.addressLine1 != null && orderingInfo.addressLine1 != undefined && orderingInfo.addressLine1 != "" ?
												<p>{orderingInfo.addressLine1}</p> : ""}
											{orderingInfo.addressLine2 != null && orderingInfo.addressLine2 != undefined && orderingInfo.addressLine2 != "" ?
												<p>{orderingInfo.addressLine2}</p> : ""}
											{orderingInfo.city != null && orderingInfo.city != undefined && orderingInfo.city != "" ?
												<p>{orderingInfo.city}</p> : ""}
											{orderingInfo.state != null && orderingInfo.state != undefined && orderingInfo.state != "" ?
												<p>{orderingInfo.state}</p> : ""}
											{orderingInfo.country != null && orderingInfo.country != undefined && orderingInfo.country != "" ?
												<p>{orderingInfo.country}</p> : ""}
											{orderingInfo.postalCode != null && orderingInfo.postalCode != undefined && orderingInfo.postalCode != "" ?
												<p>{orderingInfo.postalCode}</p> : ""}
										</div> :
										<div>
											{orderingInfo.locationName != null && orderingInfo.locationName != undefined && orderingInfo.locationName != "" ?
												<p>{orderingInfo.locationName}</p> : ""}
											{orderingInfo.recipientCubicle != null && orderingInfo.recipientCubicle != "null" && orderingInfo.recipientCubicle != undefined && orderingInfo.recipientCubicle != "" ?
												<p>{orderingInfo.recipientCubicle}</p> : ""}
										</div>
									}
								</td>
							</tr>
							: ""}

						{orderingInfo.recipientName != null && orderingInfo.recipientName != undefined && orderingInfo.recipientName != "" ?
							<tr>
								<td className="bgBtn19 font2" style={{ "width": "50%" }}>Delivery Recipient</td>
								<td className="font2">{orderingInfo.recipientName}</td>
							</tr>
							: ""}

						{orderingInfo.recipientContact != null && orderingInfo.recipientContact != undefined && orderingInfo.recipientContact != "" ?
							<tr>
								<td className="bgBtn19 font2" style={{ "width": "50%" }}>Contact Number</td>
								<td className="font2">{orderingInfo.recipientContact}</td>
							</tr> : ""}

					</tbody>
				</Table>
			</div>
		);
	}

	renderAdditionalDetails(profileDetails) {
		console.log("renderAdditionalDetails....", this.props.profileDetails)
		//let profileDetails = this.props.profileDetails;
		let locationName = '';
		if (profileDetails.cwlName != '' && profileDetails.cwlName != null && profileDetails.cwlName != 'null') {
			locationName = profileDetails.cwlName
		} else {
			locationName = profileDetails.locationName
		}

		return (
			< div className="table-responsive" >
				<Table bordered condensed hover responsive className="tableView sortImg">
					<tbody>
						<tr>
							<td className="bgBtn19 font2" style={{ "width": "50%" }}>{this.props.tr['Permanent Location']}</td>
							<td className="font2">
								{this.props.myOrderOrderDetails[0].requesterLocationName == "undefined" || this.props.myOrderOrderDetails[0].requesterLocationName == undefined ||
									this.props.myOrderOrderDetails[0].requesterLocationName == "null" || this.props.myOrderOrderDetails[0].requesterLocationName == null ? "" : this.props.myOrderOrderDetails[0].requesterLocationName}
							</td>
						</tr>
						<tr>
							<td className="bgBtn19 font2" style={{ "width": "50%" }}>{this.props.tr['Current Working Location']}</td>
							<td className="font2">{locationName}</td>
						</tr>
						<tr>
							<td className="bgBtn19 font2" style={{ "width": "50%" }}>{this.props.tr['Alternate Phone']}</td>
							<td className="font2">{profileDetails.alternatePhone}</td>
						</tr>
						<tr>
							<td className="bgBtn19 font2" style={{ "width": "50%" }}>{this.props.tr['Alternate Email']}</td>
							<td className="font2">{profileDetails.alternateEmail}</td>
						</tr>
						<tr>
							<td className="bgBtn19 font2" style={{ "width": "50%" }}>{this.props.tr['Manager Name']}</td>
							<td className="font2">{profileDetails.managerName}</td>
						</tr>
					</tbody>
				</Table>
			</div>
		)
	}

	checkRenderApproverData(offeringId, module, ciId, ciClass, itemid) {
		let str = {};
		let moduleId
		if (module == "RFC") { moduleId = "14" }
		else { moduleId = "10" }
		this.setState({ showApprovalLoader: true });
		axios.get("/api/approval/ruleCount/offeringId/" + offeringId + "/moduleId/" + moduleId + "/ciId/" + ciId + "/ciclass/" + ciClass + "/requestId/" + itemid)
			.then((resp) => {
				if (resp.status = 200) {
					this.setState({ showApprovalLoader: false });
					console.log("resp.data", resp.data);
					if (resp.data.count >= 1) {
						this.props.loadChangeTotalApprovalDetails(this.props.itemId, moduleId, "N");
						this.setState({ displayMessage: "" })
					}
					else if (resp.data.count == -1) {
						this.setState({ showApprovalLoader: false });
						this.setState({
							displayMessage:
							this.props.tr["There are no active approvals as the request is cancelled"],
						  });
					  } 
					else {
						this.setState({ showApprovalLoader: false });
						this.setState({ displayMessage: this.props.tr["This service does not require any approval"] })
					}
				}
			});
	}

	renderItemComponents(orderDetails) {
		let finalArray = [], coreArray = [], optionalArray = [];
		let optionalCompCount = 0;
		let totalUnitPrice = parseFloat(orderDetails.servicePrice);

		finalArray.push(<ListGroup.Item bsPrefix=' ' className="rwTableGap" style={{ 'border-top': 'none' }}><span className="rwKey"><b>{this.props.tr['Service Price']}</b></span><span className="rwGap">-</span><span className="rwVal">{orderDetails.currency != null && orderDetails.itemPrice > 0 && orderDetails.isPriceEnabled ? orderDetails.currency + ' ' + orderDetails.servicePrice : this.props.tr['NA']}</span></ListGroup.Item>);

		orderDetails.components.forEach((component, i) => {
			if (component.componentType == 'Core') {
				coreArray.push(<ListGroup.Item bsPrefix=' ' className="rwTableGap"><span className="rwKey">{component.componentNameSx}</span><span className="rwGap">-</span><span className="rwVal">{this.props.tr['Included']}</span></ListGroup.Item>);
			}
			if (component.componentType == 'Optional') {
				optionalArray.push(<ListGroup.Item bsPrefix=' ' className="rwTableGap"><span className="rwKey">{component.componentNameSx}</span><span className="rwGap">-</span><span className="rwVal">{orderDetails.currency != null && orderDetails.itemPrice > 0 && orderDetails.isPriceEnabled ? orderDetails.currency + ' ' + component.componentPrice : this.props.tr['NA']}</span></ListGroup.Item>);
				optionalCompCount++;
				totalUnitPrice = (totalUnitPrice + parseFloat(component.componentPrice)).toFixed(2);
			}
		});

		finalArray = finalArray.concat(coreArray);

		if (optionalCompCount > 0) {
			finalArray.push(<ListGroup.Item bsPrefix=' ' className="rwTableGap"><span className="rwKey"><b>{this.props.tr['Optional Components']}</b></span><span className="rwGap"></span><span className="rwVal"></span></ListGroup.Item>);
			finalArray = finalArray.concat(optionalArray);
		}

		finalArray.push(<ListGroup.Item bsPrefix=' ' className="rwTableGap"><span className="rwKey"><b>{this.props.tr['Total Unit Price']}</b></span><span className="rwGap">-</span><span className="rwVal">{orderDetails.currency != null && orderDetails.itemPrice > 0 && orderDetails.isPriceEnabled ? orderDetails.currency + ' ' + totalUnitPrice : this.props.tr['NA']}</span></ListGroup.Item>);

		return finalArray;
	}
	setCancellationType(event){
		if(event.nativeEvent.target.value===''){
		  this.setState({canErrorCode: 'error'});
		}else{
		  this.setState({canErrorCode: ''});
		}
		this.setState({cancelCode: event.nativeEvent.target.value, cancelValue: event.nativeEvent.target[event.target.selectedIndex].text});
	  }
	
	  renderCancellationType(values){
		if (values.length == 0) {
			return null;
		  }
		return values.map(item => <option key={item.field1Key} value={item.field1Value}>{item.field1Key}</option>);
	}

	render() {
		//console.log(this.props.myOrderOrderDetails.length) 7968
		// console.log("===========this.props.myOrderOrderDetails");
		// console.log(this.props.myOrderOrderDetails.addressLine1);
		// console.log("isCancelEnabled : ",this.props.myOrderOrderDetails[0]);
		//  console.log("++++++++++++++this.props.myOrderQuestionDetails");
		//  console.log(this.props.myOrderQuestionDetails);
		//console.log('disabledSave========',this.state.disabledSave);
		if (this.props.showLoader || this.props.showLoader_1 || this.props.showLoader2 || this.props.showLoader3 || this.props.showLoader4 || this.props.serviceReviewShowLoader) {
			return (
				<div><ListLoader myStyle={{ marginTop: "20px" }} /></div>
			);
		}

		else if (this.props.myOrderOrderDetails.length == 0 || this.props.myOrderOrderDetails.length == undefined) {
			return (
				<div className="text-c">{this.props.tr['There is no matching data available']}</div>
			);
		}
		{/* let userOrders=[];
		 for(let i=0;i<=this.props.ordersList.length-1;i++){
			 if(this.props.ordersList[i].requesterId=user_id){

				 userOrders.push(this.props.ordersList[i]);
				 //console.log(userOrders)
			 }
		 }
		 console.log("userOrders")
		 console.log(userOrders)
		 //alert(this.props.ordersList.length)
		 if(userOrders.length==0 || this.props.ordersList.length==0){
			 return(
					 <div><font color="green" ><b>There is no matching data available</b></font></div>
			 );
		 } */}

		//alert("dsdsad"+this.props.myOrderOrderDetails.itemNumber)
		// var componentsName = [];
		var questionsData = [];
		var approverName = [];
		var approvalStatus = [];
		var approverLevel = [];
		var cancelOrder = [];
		var itemType = "";
		var orderType = "";
		//alert(this.props.myOrderOrderDetails[0].itemType)

		if (this.props.myOrderOrderDetails[0].itemType == "70") {
			orderType = "Cancel",
				itemType = true
		} else {
			orderType = "Fulfillment",
				itemType = false
		}
		//console.log(itemType)
		//alert("list details : ",this.props.approverListDetails)
		if (this.props.approverListDetails.length > 0) {
			for (let i = 0; i <= this.props.approverListDetails.length - 1; i++) {
				approverName.push(<div className="details"><span className="labSpan">{this.props.tr['Approver Name']}-</span>{this.props.approverListDetails[i].signatures}</div>);

			}
		} else {
			approverName.push(<div className="details"><span className="labSpan">{this.props.tr['Approver Name']}-</span>NA</div>)

		}

		//  console.log("this.props.changeTotalApprovalDetails")
		//  console.log(this.props.changeTotalApprovalDetails)

		//  if(this.props.changeTotalApprovalDetails.length>0){
		// 	 //for(let i=0;i<=this.props.approverListDetails.length-1;i++){
		// 		 if(this.props.approverListDetails[0].status=="" || this.props.approverListDetails[0].status==null){
		// 			 	//alert("cancelOrder");
		// 		 		cancelOrder.push(	<Button bsSize="small"   onClick={()=>{this.cancelHideShow();}} disabled={itemType}>{this.props.tr['Cancel Order']}</Button>)
		// 			}
		// 	 //}
		//  }else{
		// 	 	 cancelOrder.push(null)
		//  }


		if ((this.props.myOrderOrderDetails[0].itemStatus == "65") && (this.props.myOrderOrderDetails[0].isCancelEnabled == true)) {
			cancelOrder.push(<Button bsSize="small" title={this.props.tr['Cancel Order']}  onClick={() => { this.cancelHideShow(); this.setState({ showAttachWithoutComments: false }); }} disabled={itemType}>{this.props.tr['Cancel Order']}</Button>)
		}
		else {
			cancelOrder.push(null)
		}

		if (this.props.approverListDetails.length > 0) {
			for (let i = 0; i <= this.props.approverListDetails.length - 1; i++) {
				approvalStatus.push(<div className="details"><span className="labSpan">{this.props.tr['Status']}-</span>{this.props.approverListDetails[i].status}</div>);
			}
		} else {
			approvalStatus.push(<div className="details"><span className="labSpan">{this.props.tr['Status']}-</span>NA</div>)
		}

		if (this.props.approverListDetails.length > 0) {
			for (let i = 0; i <= this.props.approverListDetails.length - 1; i++) {
				approverLevel.push(<div className="details"><span className="labSpan">{this.props.tr['Approver Level']}-</span>{this.props.approverListDetails[i].levelname}</div>);
			}
		} else {
			approverLevel.push(<div className="details"><span className="labSpan">{this.props.tr['Approver Level']}-</span>NA</div>)
		}
		//console.log("ABC");
		//console.log(this.props.myOrderOrderDetails[0].components);
		if (this.props.myOrderOrderDetails[0].components == 'undefined') {
			//alert("HHM")
		}
		// for (let i = 0; i <= this.props.myOrderOrderDetails[0].components.length - 1; i++) {
		// 	//alert(this.props.myOrderOrderDetails.items[i].components.length);

		// 	componentsName.push(
		// 		<div className="details">
		// 			<i className="fa fa-check bullets dCheckbox"></i>
		// 			{/*	<Checkbox checked disabled className="dCheckbox><span></span></Checkbox>*/}

		// 			{this.props.myOrderOrderDetails[0].components[i].componentNameSx}
		// 		</div>)
		// }

		for (let j = 0; j <= this.props.myOrderOrderDetails[0].questions.length - 1; j++) {
			questionsData.push(
				<div className="details">
					{/*<Checkbox checked disabled className="dCheckbox"><span></span></Checkbox>*/}
					<i className="fa fa-check bullets dCheckbox"></i>
					{this.props.myOrderOrderDetails[0].questions[j].question} : {this.props.myOrderOrderDetails[0].questions[j].answer}
				</div>)
		}
		//console.log("====no components found"+componentsName);
		//console.log("====no components found"+questionsData);
		var approverDetails = ""
		if (this.props.approverListDetails.length > 0) {

		}
		let showActivity = "";
		if (this.props.orderStatus == "Fixed" || this.props.orderStatus == "Closed" || this.props.orderStatus == "Cancelled" || this.props.orderStatus == "Fullfiled" || this.props.orderStatus == "Draft") {
			showActivity = <div></div>
		} else {
			showActivity = <Button className="rgSidrkBtn" bsSize="small" title={this.props.tr['Post a Comment']}  onClick={() => { this.activityLogHideShow(); this.setState({ showAttachWithoutComments: false }); }}>{this.props.tr['Post a Comment']}</Button>

		}

		let showTestAreaForComments = "";
		if (this.state.showUpdates == 0) {
			showTestAreaForComments = <div className="position-re"><Form.Control as="textarea" rows="3" ref={(input) => this.inputComments = input} onChange={this.activityData.bind(this)} />
				{/* <div className="bgGray padding-10 padding-b-0 tbox">
					<div className="fStnNew"><a title="Post" onClick={this.postActivityData.bind(this)}><i className="fa fa-chevron-circle-right fSbtn"></i></a><div className="margin-t-5 display-inline-block"></div></div>
				</div> */}
				{/* <div className="bgGray padding-10 padding-b-0 tbox"> */}
				{/* <div className="fStnNew"><a title="Post"
					disabled={this.state.disabledSave}
					onClick={this.postActivityData.bind(this)}>{this.state.disabledSave ? <i className="f-size-13 fa fa-spinner fa-spin"></i> : <i className="fa fa-chevron-circle-right fSbtn"></i> } */}
				{/* <i className="fa fa-chevron-circle-right fSbtn"> */}
				{/* </i> */}
				{/* </a><div className="margin-t-5 display-inline-block"></div></div> */}
				{/* </div> */}
				{/* {this.state.openAttachmentOption ?
					<AttachmentFormTab
						translator={this.props.tr}
						itemId={this.state.itemId}
					/>
				: ''} */}
				{this.state.commentsAreEmpty ? <div className="error"><font color="red"><strong>Comments should not be left empty.</strong></font></div> : ''}
				<ButtonToolbar className="margin-t-15">
					{/* {this.state.openAttachmentOption ? '' : <Button bsSize="small"  onClick={this.openAttachmentOption.bind(this)}>{this.props.tr['Attach Files']}</Button>} */}
					<Button bsSize="small"  disabled={this.state.disabledSave} onClick={this.postActivityData.bind(this)}>{this.props.tr['Post']}</Button>
				</ButtonToolbar>
				{/* <ButtonToolbar className="margin-t-15"><Button className="popFormBtn"  title={this.props.tr['Post']}
				 disabled={this.state.disabledSave}
				onClick={this.postActivityData.bind(this)}> {this.props.disabledSave ? <i className="f-size-13 fa fa-spinner fa-spin"></i> : <i className="fa fa-angle-right f-bold"></i> } </Button></ButtonToolbar> */}
			</div>
		} else {
			showTestAreaForComments = <div></div>
		}

		let showSoringDescArrow = "";
		if (this.state.sortingArrow == 0 && this.props.breakFixActivityLogDetails.length > 0) {
			showSoringDescArrow = <a href={void(0)} onClick={this.descactivitysort.bind(this)} className="black" title={this.props.tr['Descending']}><i className="fa fa-angle-down" ></i></a>
		}

		let showSoringAscArrow = "";
		if (this.state.sortingArrow == 1) {
			showSoringAscArrow = <a href={void(0)} onClick={this.ascactivitysort.bind(this)} className="black" title={this.props.tr['Ascending']}><i className="fa fa-angle-up"></i></a>;
		}


		let delTime = this.props.myOrderOrderDetails[0].deliveryVal;


		let finalValue = formatMinutesIntoDaysHoursMinutes(delTime);

		console.log("itemstatus::" + this.props.orderStatus)
		let itemStatus = this.props.orderStatus
		if (itemStatus == "Fullfiled")
			itemStatus = "Fulfilled"

		return (
	
      <div className="">
		<div className="rPageHeadActBtn">
              <ul>
                <li>
				<Link to="javascript:void(0)" className="closeRsBtn" id="closeButton"
                    title={this.props.tr["Minimize the details panel"]}
					onClick={() => {
						this.props.showActiveRow(null,0)
                        this.props.setRightSideBar(false);
                      }}
                    ref={e => this.closeButton = e}
                  >
                    <IoClose/>
                  </Link>
                </li>
              </ul>
            </div>
        <div className="rPageHeading">
          {this.props.myOrderOrderDetails[0].orderNumber +
            "-" +
            this.props.myOrderOrderDetails[0].itemNumber}
          <div className="offNam">
            {this.props.myOrderOrderDetails[0].offeringNameSx}
          </div>
        </div>
        <div className="rBoxGap">
          <ListGroup bsPrefix=' '>
            <ListGroup.Item
              bsPrefix=' '
              className="rwTable"
              style={{ "border-top": "none" }}
            >
              <span className="rwKey">{this.props.tr["Requested For"]}</span>
              <span className="rwVal">
                {this.props.myOrderOrderDetails[0].requesterName}
              </span>
            </ListGroup.Item>
            {this.props.myOrderOrderDetails[0].quantity &&
            this.props.myOrderOrderDetails[0].quantity > 0 ? (
              <ListGroup.Item bsPrefix=' ' className="rwTable">
                <span className="rwKey">{this.props.tr["Quantity"]}</span>
                <span className="rwVal">
                  {this.props.myOrderOrderDetails[0].quantity}
                </span>
              </ListGroup.Item>
            ) : null}
            {this.props.myOrderOrderDetails[0].deliveryVal == 0 ? null : (
              <ListGroup.Item bsPrefix=' ' className="rwTable">
                <span className="rwKey">{this.props.tr["Delivery Type"]}</span>
                <span className="rwVal">{finalValue}</span>
              </ListGroup.Item>
            )}
            {this.props.myOrderOrderDetails[0].currency != null &&
            this.props.myOrderOrderDetails[0].itemPrice > 0 &&
            this.props.myOrderOrderDetails[0].isPriceEnabled ? (
              <ListGroup.Item bsPrefix=' ' className="rwTable">
                <span className="rwKey">{this.props.tr["Service Value"]}</span>
                <span className="rwVal">
                  {this.props.myOrderOrderDetails[0].currency}{" "}
                  {this.props.myOrderOrderDetails[0].itemPrice}
                </span>
              </ListGroup.Item>
            ) : (
              <ListGroup.Item bsPrefix=' ' className="rwTable">
                <span className="rwKey">{this.props.tr["Service Value"]}</span>
                <span className="rwVal">{this.props.tr["NA"]}</span>
              </ListGroup.Item>
            )}
            {this.props.myOrderOrderDetails[0].deliveryVal == 0 ? null : (
              <ListGroup.Item bsPrefix=' ' className="rwTable">
                <span className="rwKey">
                  {this.props.tr["Expected Delivery Date"]}
                </span>
                <span className="rwVal">
                  {this.props.myOrderOrderDetails[0].expdeliveryDate}
                </span>
              </ListGroup.Item>
            )}
            <ListGroup.Item bsPrefix=' ' className="rwTable">
              <span className="rwKey">{this.props.tr["Status"]}</span>
              <span className="rwVal">{itemStatus}</span>
            </ListGroup.Item>
            {this.props.myOrderOrderDetails[0].holdingReasonCode == "110" ? (
              <ListGroup.Item bsPrefix=' ' className="rwTable">
                <span className="rwKey">
                  {this.props.tr["Reason for Hold"]}
                </span>
                <span className="rwVal">
                  {this.props.myOrderOrderDetails[0].holdingReasonValue}
                </span>
              </ListGroup.Item>
            ) : (
              <ListGroup.Item
                bsPrefix=' '
                className="rwTable"
                style={{ "border-bottom": "1px solid #b3d4e6" }}
              >
                <span className="rwKey">{this.props.tr["Order Type"]}</span>
                <span className="rwVal">{orderType}</span>
              </ListGroup.Item>
            )}
            {this.props.ExternalRequestNumber == "" ? (
              ""
            ) : (
              <ListGroup.Item
                bsPrefix=' '
                className="rwTable"
                style={{
                  "border-top": "0px solid #b3d4e6",
                  "border-bottom": "1px solid #b3d4e6",
                }}
              >
                <span className="rwKey">
                  {" "}
                  {this.props.tr["External Request Number"]}
                </span>
                <span className="rwVal">
                  {this.props.ExternalRequestNumber}
                </span>
              </ListGroup.Item>
            )}
			</ListGroup>

			<ListGroup bsPrefix=' ' className="margin-t-25">
			<ListGroup.Item bsPrefix=' ' style={{ "border-top": "none" }}>
              <Accordion id="rightSidePanel01" className="brkFix brkFixOrder" defaultActiveKey={0}>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    {this.props.tr["Requestor Information"]}
                  </Accordion.Header>
                  <Accordion.Body>
                    {this.props.showLoader_6 ? (
                      <ListLoader />
                    ) : (
                      this.renderAdditionalDetails(this.props.profileDetails)
                    )}
                  </Accordion.Body>
                </Accordion.Item>
 
                <Accordion.Item eventKey="2">
                  <Accordion.Header
                    onClick={() => {
                      this.checkRenderApproverData(
                        this.props.myOrderOrderDetails[0].offeringIdSx,
                        this.props.module,
                        "0",
                        "0",
                        this.props.myOrderOrderDetails[0].itemId
                      );
                    }}
                  >
                    {this.props.tr["Approval"]}
                  </Accordion.Header>
                  <Accordion.Body>
                    {this.state.showApprovalLoader ? (
                      <div>
                        <ListLoader />
                      </div>
                    ) : this.state.displayMessage != "" ? (
                      this.state.displayMessage
                    ) : this.props.rightMenuShowLoader ? (
                      <div>
                        <ListLoader />
                      </div>
                    ) : this.props.changeTotalApprovalDetails.length > 0 ? (
                      <Table className="table table-striped table-bordered table-condensed table-hover tablesl approvalPopup">
                        <thead>
                          <tr>
                            <th width="10%"></th>
                            <th width="20%">{this.props.tr["Level"]}</th>
                            <th width="42%">{this.props.tr["Approver"]}</th>
                            <th width="28%">{this.props.tr["Date & Time"]}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.renderAppHistoryDataLog(
                            this.props.changeTotalApprovalDetails
                          )}
                        </tbody>
                      </Table>
                    ) : (
                      <div>
                        {
                          this.props.tr[
                            "Approval is being triggered. Please try later!"
                          ]
                        }
                      </div>
                    )}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    {this.props.tr["Item Components"]}
                  </Accordion.Header>
                  <Accordion.Body>
                    <ListGroup bsPrefix=' '>
                      {this.renderItemComponents(
                        this.props.myOrderOrderDetails[0]
                      )}
                    </ListGroup>
                  </Accordion.Body>
                </Accordion.Item>

            {this.props.myOrderQuestionDetails.length != 0 ? (
              this.props.myOrderQuestionDetails[0].categoryId != null ? (
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        {this.props.tr["Information Provided"]}
                      </Accordion.Header>
                      <Accordion.Body>
                        {this.renderQuestionCategoryWise(
                          this.props.myOrderQuestionDetails
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
              ) : (
                null
              )
            ) : (
              null
            )}


                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    {this.props.tr["Ordering Information"]}
                  </Accordion.Header>
                  <Accordion.Body>
                    {this.renderOrderingInformation(
                      this.props.myOrderOrderDetails[0]
                    )}
                  </Accordion.Body>
                </Accordion.Item>

            {this.props.orderStatus != "Draft" ? (
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      {this.props.tr["Attachments"]}
                    </Accordion.Header>
                    <Accordion.Body>
                      {this.renderAttachedItems(
                        this.props.implememtationPlanList
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
            ) : (
              null
            )}
			</Accordion>
			</ListGroup.Item>

          </ListGroup>

          <ButtonToolbar className="margin-t-15">
            {showActivity}
            {this.props.orderStatus != "Draft" &&
            this.props.myOrderOrderDetails[0].itemStatus != "80" && (this.props.myOrderOrderDetails[0].status!=="Cancelled" && this.props.myOrderOrderDetails[0].status!=="Closed" && this.props.myOrderOrderDetails[0].status!=="Rejected") ? (
              <Button
                className="rgSiLigBtn"
                bsSize="small"
                title={this.props.tr["Attach Files"]}
                onClick={() => {
                  this.setState({
                    showAttachWithoutComments:
                      !this.state.showAttachWithoutComments,
                    activityLogHideShow: 0,
                    viewUpdates: 0,
                    cancelHideShow: 0,
                  });
                }}
              >
                {this.props.tr["Attach Files"]}
              </Button>
            ) : (
              ""
            )}
            <Button
              className="rgSiLigBtn"
              bsSize="small"
              title={this.props.tr["View Updates"]}
              onClick={() => {
                this.viewUpdateShow();
                this.setState({ showAttachWithoutComments: false });
              }}
            >
              {this.props.tr["View Updates"]}
            </Button>

            {cancelOrder}
          </ButtonToolbar>

          {this.state.showAttachWithoutComments ? (
            <div className="margin-t-20">
              <AttachmentFormTab
                translator={this.props.tr}
                itemId={this.state.itemId}
              />
              <Button
                className="margin-t-20"
                bsSize="small"
                disabled={this.state.disabledSave}
                onClick={this.postAttachmentWithoutComments}
              >
                {this.state.disabledSave == true ? (
                  <span>
                    <ImSpinner6 className="icn-spinner"/>{" "}
                  </span>
                ) : (
                  ""
                )}
                {this.props.tr["Attach"]}
              </Button>
            </div>
          ) : (
            ""
          )}

          {this.state.activityLogHideShow == 1 ? (
            <div className="margin-t-20 blueAnq">{showTestAreaForComments}</div>
          ) : null}

          {this.state.cancelHideShow == 1 ? (
            <div className="margin-t-20 blueAnq">
				<Form.Group>
                        <Form.Label bsClass="">
                          <span className="rStar"></span>
                          {this.props.tr["Cancellation Type"]}{" "}
                        </Form.Label>
                        <Form.Select required="true" componentClass="select" onChange={this.setCancellationType} className={this.state.canErrorCode}>
                            <option value="">Select</option>
                            {this.renderCancellationType(this.props.loadReasons)}
                        </Form.Select>
                    </Form.Group>
              <Form.Group validationState={this.state.issueValidationError}>
                <Form.Label bsPrefix=' '>
                  <span className="rStar"></span>
                  {this.props.tr["Cancellation Reason"]}{" "}
                </Form.Label>
                <Form.Control
                  as="textarea"
				  className={this.state.issueValidationError}
                  rows="3"
                  ref={(input) => (this.inputComments = input)}
                  onChange={this.getIssue.bind(this)}
                />
                {/*<FormControl as="textarea" rows="2"/>*/}
              </Form.Group>
              {/*<ButtonToolbar className="margin-t-15"><Button  onClick={()=>{this.postCancellationRequest();}}>Submit</Button></ButtonToolbar>*/}
              {this.state.savedMessage == "" ||
              this.state.issueValidationError == "error" ? (
                <ButtonToolbar className="margin-t-15">
                  <Button
                    bsSize="small"
                    disabled={this.state.disabledSave}
                    onClick={() => {
                      this.postCancellationRequest();
                    }}
                  >
                    {
                        this.state.disabledSave ? <ImSpinner6 className="icn-spinner"/> : null
                      }
                    {" "}
                    {this.props.tr["Submit"]}
                  </Button>
                </ButtonToolbar>
              ) : null}
            </div>
          ) : (
            <div>
              <font color="green">
                <b>{this.state.savedMessage}</b>
              </font>
            </div>
          )}

          {this.state.viewUpdates == 1 ? (
            <div className="margin-t-15 padding-b-5 border-b">
              <span className="float-r f-size-17">
                {showSoringAscArrow}
                {showSoringDescArrow}
                <span className="float-r margin-r-10"></span>
              </span>
              {this.renderActivityLog(this.props.breakFixActivityLogDetails)}
            </div>
          ) : null}
        </div>
      </div>
    );
	}
}

export function mapStateToProps({
	breakFixActivityLogDetails,
	ordersList,
	myOrderOrderDetails,
	approverListDetails,
	changeTotalApprovalDetails,
	changeTotalApprovalHistoryDetails,
	implememtationPlanList,
	myOrderQuestionDetails,
	showLoader,
	showLoader_1,
	showLoader_2,
	showLoader_3,
	showLoader4,
	showLoader_5,
	showLoader_6,
	rightMenuShowLoader,
	serviceReviewShowLoader,
	profileDetails,
	spcmReducer,
	loadReasons
}) {
	// let data = ordersList.data;
	// if (data == undefined)
	// 	data = [];
	//  alert("breakFixEditDetails:"+breakFixActivityLogDetails.length);
	//console.log("myOrderOrderDetails");
	return {
		breakFixActivityLogDetails,
		ordersList,
		myOrderOrderDetails,
		approverListDetails,
		changeTotalApprovalDetails,
		changeTotalApprovalHistoryDetails,
		implememtationPlanList,
		myOrderQuestionDetails, showLoader: showLoader.loading,
		showLoader_1: showLoader_1.loading,
		showLoader_2: showLoader_2.loading,
		showLoader_3: showLoader_3.loading,
		showLoader4: showLoader4.loading,
		rightMenuShowLoader: rightMenuShowLoader.loading,
		showLoader_5: showLoader_5.loading,
		showLoader_6: showLoader_6.loading,
		serviceReviewShowLoader: serviceReviewShowLoader.loading,
		profileDetails: profileDetails,
		spcmReducer,
		loadReasons
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			loadBreakFixActivityLogDetails,
			loadOrdersList,
			loadApproverList,
			loadImplPlanList,
			loadChangeTotalApprovalDetails,
			loadChangeTotalApprovalHistoryDetails,
			resetSortingArrows
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceDeskRightSide);
