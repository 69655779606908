
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, InputGroup, Image, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { Field, change, reduxForm } from "redux-form";
import { TypeaheadExampleSingleSelect, _inputField } from "../../../../common/formFields";
import { getCostCenterCompanyOptions } from "../../../../../actions/foundation/costCenterAction";
// import { getOrganizationCompanyOptions } from "../../../../../actions/foundation/organizationAction";


const CreateForm = (props) => {
    const [selectedCompany, setSelectedCompany] = useState([]);
    const [companyList, setCompanyList] = useState([]);

    const tr = useSelector((state) => state.spcmReducer.tr);
    const companyData = useSelector((state) => state.costCenterCompanyDropdown);
    // const comapnyValues = useSelector((state) => state.orgCompanyDropdown);
    const dispatch = useDispatch();

    // console.log("TestCompanyValues",comapnyValues)
    
    useEffect(() => {
        dispatch(getCostCenterCompanyOptions());
        // dispatch(getOrganizationCompanyOptions());
    }, [])

    useEffect(() =>
    {
      if(companyData && companyData.length > 0)
      {
        setCompanyList(companyData)
        }

    }, [companyData])
    const onCompanySelection=(selectedCompany)=>{
        if (selectedCompany.length > 0) { 
            setSelectedCompany(selectedCompany)
            dispatch(change('CreateOrganization', 'company_name', selectedCompany[0].label))
            dispatch(change('CreateOrganization', 'company_id', selectedCompany[0].id))
            props.setCompanyValue(selectedCompany);
            // dispatch(getOrganizationOptions(selectedCompany[0].id));
          } else {
            setSelectedCompany([]);
            dispatch(change('CreateOrganization', 'company_name', ''))
            dispatch(change('CreateOrganization', 'company_id', ''))
          }
    }
    const onCrossClickCompany = () => {
        setSelectedCompany([]);
        props.setCompanyValue([]);
        dispatch(change('CreateOrganization', 'company_name', ''))
        dispatch(change('CreateOrganization', 'company_id', ''))
  }
  const companyInputChange = () => {
    onCrossClickCompany();
  }
    const  validateOrgField = (fieldType, event)=> {
        switch (fieldType) {
          case "name":
            if (event.target.value == "") {
              props.setNameErr("error");
            } else {
              props.setNameErr("");
            }
            break;
          case "status":
            if (event.target.value == "") {
              props.setStatusErr("error");
            } else {
              props.setStatusErr("");
            }
            break;
          default:
        }
      }
    
    return (
        <Form>
        <Row>
            <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
            <span className="rStar"></span>
                    <Form.Label>{tr["Name"]}</Form.Label>
                    <Field
                        component={_inputField}
                        name="name"
                        className={"form-control "+(props.nameErr)}
                        maxLength={50}
                        onBlur={(event) => {
                            validateOrgField("name", event);
                        }}
                    />
                </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
            <span className="rStar"></span>
                    <Form.Label>{tr["Status"]}</Form.Label>
                    <Field
                        name="status"
                        component="select"
                        className={"form-control "+(props.statusErr)}
                        onBlur={(event) => {
                            validateOrgField("status", event);
                        }}
                    >
                    <option value="">{tr["Select"]}</option>
                    <option value="1">{tr["Active"]}</option>
                    <option value="0">{tr["Inactive"]}</option>
                    </Field>
                </Form.Group>
            </Col>
            
            <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
            <span className="rStar"></span>
                    <Form.Label>{tr["Company"]}</Form.Label>
                    <Field
                     name="company_name"
                     className="form-control"
                     component={TypeaheadExampleSingleSelect}
                     onSelection={onCompanySelection}
                     options={companyList}
                     selectedOptions={selectedCompany}
                     onCrossClick={onCrossClickCompany}
                     onInputChange={companyInputChange}
                     errorClass={props.companyTypeErr}
                     setErrorColor={props.setCompanyErrorColor}
                />
                </Form.Group>
            </Col>
            </Row>
            </Form>
    )
}

export default reduxForm({
    form: 'CreateOrganization',
    destroyOnUnmount: false,
    enableReinitialize: true
})(CreateForm);