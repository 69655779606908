
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import {
  Row,
  Col,
  Form,
  ButtonToolbar,
  Button,
  Alert,
} from "react-bootstrap";
import { connect } from "react-redux";
import axios from "axios";
import {
  spcmLoader,
  getAttributesTranslationStatus,
  languageListTranslationStatus,
  translate,
  getTranslationForOfferingNameDescriptionSummary,
  resetReducer,
} from "../../../../actions/spcmActions";
import { Field, reduxForm, SubmissionError, change } from "redux-form";
import {
  _textArea,
  _dropDown,
  _inputField,
  _number,
} from "../../../common/formFields";
import {_ckeditor } from '../../../common/formFields';
import {ImSpinner6} from "react-icons/im";

const validate = (values) => {
  console.log("values");
  console.log(values);
  let errors = {};

  if (values.translated_text) {
    if (values.translated_text.trim() == "") {
      errors.translated_text = "Enter Translated Text";
    }
  }
  else if (!values.translated_text) {
    errors.translated_text = "Enter Translated Text";
  }

  console.log("errors");
  return errors;
};

class TextFieldForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attributeValue: "",
      attribute_trans_value: "",
    };
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    console.log("this.props.fieldNameVal2=====",this.props.fieldNameVal2);
    console.log("this.props.spcmReducer.currentlySelectedServiceDetails.TAGS",this.props.spcmReducer.currentlySelectedServiceDetails.TAGS);
    this.props.dispatch(change(
      "NameDescForm",
      "short_desc",
      this.props.spcmReducer.currentlySelectedServiceDetails
          .SUMMARY
    ))
    if (this.props.fieldNameVal2 == "Name") {
      this.setState({
        attributeValue: this.props.spcmReducer.currentlySelectedServiceDetails
          .OFFERING_NAME,
      });
      this.props.dispatch(change(
        "NameDescForm",
        "translated_text",
        this.props.spcmReducer.objectForTranslation.offering_name
      ))
    } else if (this.props.fieldNameVal2 == "Short Description") {
      this.setState({
        attributeValue: this.props.spcmReducer.currentlySelectedServiceDetails
          .SUMMARY,
      });
      this.props.dispatch(change(
        "NameDescForm",
        "translated_text",
        this.props.spcmReducer.objectForTranslation.summary
      ));
    } else if (this.props.fieldNameVal2 == "Description") {
      this.setState({
        attributeValue: this.props.spcmReducer.currentlySelectedServiceDetails
          .DESCRIPTION,
      });
      this.props.dispatch(change(
        "NameDescForm",
        "translated_text",
        this.props.spcmReducer.objectForTranslation.description
      ));
    } else if (this.props.fieldNameVal2 == "Search Tags") {
      this.setState({
        attributeValue: this.props.spcmReducer.currentlySelectedServiceDetails.TAGS,
      });
      this.props.dispatch(change(
        "NameDescForm",
        "translated_text",
        this.props.spcmReducer.objectForTranslation.tags
      ));
    }else if (this.props.fieldNameVal2 == "Disclaimers") {
      this.setState({
        attributeValue: this.props.spcmReducer.currentlySelectedServiceDetails
          .SERVICE_DISCLAIMER,
      });
    }
  }

  componentWillReceiveProps(newProps) {
    if (
      this.props.spcmReducer.translatedNameDescSum !==
      newProps.spcmReducer.translatedNameDescSum
    ) {
      this.setState({
        translatedText: newProps.spcmReducer.translatedNameDescSum,
      });

      this.props.dispatch(
        change(
          "NameDescForm",
          "translated_text",
          newProps.spcmReducer.translatedNameDescSum
        )
      );
    }
  }

  componentWillUnmount() {
    this.props.resetReducer();
  }

  submitForm(values) {
    console.log("valuess");
    console.log(values);
    let attribute_type =
      this.props.fieldNameVal2 == "Name"
        ? "offering_name"
        : this.props.fieldNameVal2 == "Short Description"
          ? "summary"
          : this.props.fieldNameVal2 == "Description"
            ? "description"
              : this.props.fieldNameVal2 == "Search Tags"
                ? "tags"
            : "service_disclaimer";
    const baseLanguage = this.props.spcmReducer.currentlySelectedServiceDetails
      .BASE_LANGUAGE;

    let data = {
      offering_id: this.props.spcmReducer.currentlySelectedServiceDetails
        .OFFERING_ID,
      language_id: this.props.lanIdVal2,
      attribute_id: this.props.spcmReducer.currentlySelectedServiceDetails
        .OFFERING_ID,
      attribute_type: attribute_type,
      attribute_value: this.state.attributeValue,
      attribute_trans_value: values.translated_text,
    };
    this.props.spcmLoader("attribute", "posting");
    return axios
      .post("/api/languageTranslation", data)
      .then((response) => {
        this.props.spcmLoader("attribute", "success");
        this.props.lanEditPageFun5(false);
        this.props.getAttributesTranslationStatus(
          this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
          this.props.lanIdVal2
        );
        this.props.languageListTranslationStatus(
          this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
          baseLanguage !== null ? baseLanguage : "English"
        );
        this.props.getTranslationForOfferingNameDescriptionSummary(
          this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
          this.props.lanIdVal2
        );
      })
      .catch((error) => {
        this.props.spcmLoader("attribute", "success");
        throw new SubmissionError({ _error: error.response.data.message });
      });
  }

  render() {
    console.log("objectForTranslation",this.props.spcmReducer.objectForTranslation);
    console.log(this.props.spcmReducer.objectForTranslation);
    console.log(this.props.spcmReducer);
    console.log(this.props.spcmReducer.currentlySelectedServiceDetails);
    console.log("translatedText");
    console.log(this.props.spcmReducer.translatedNameDescSum);
    const tr = this.props.tr;
    const { error, handleSubmit } = this.props;

    let attribute_value ;
    if(this.state.attributeValue == null || this.state.attributeValue == undefined){
      attribute_value = "";
    }else{
      attribute_value = this.state.attributeValue;
      attribute_value = attribute_value.replaceAll("&nbsp;", "");

    }

    return (
      <div>
        <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">
          Translate - {this.props.fieldNameVal2} - {this.props.lanNameVal2}{" "}
        </div></div>

        <div className="rBoxGap">
          <form onSubmit={handleSubmit(this.submitForm)}>
            {error == undefined || error == null ? null : (
              <Alert variant="danger" className="errorMessageSec">
                <p>{error}</p>
              </Alert>
            )}
            <Row>
              <Col md={12}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">Base Language</Form.Label>
                  <Form.Control disabled value="English" type="text" />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">
                    {this.props.fieldNameVal2}
                  </Form.Label>
                  {this.props.fieldNameVal2 == "Short Description" || this.props.fieldNameVal2 === "Description"?
								<Field name="short_desc" component={_ckeditor} className="readOnlyClass reactQuillEditor-area" richTextConfig ={"minRichTextEditor"} />
								:
                  <Form.Control
                    disabled
                    value={attribute_value}
                    componentClass="textarea"
                    rows="2"
                  />}
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">
                    <span className="rStar"></span> Translated {this.props.fieldNameVal2} 
                  </Form.Label>

                  {this.props.fieldNameVal2 === "Short Description" || this.props.fieldNameVal2 === "Description" ? (
                    <Field
                      component={_ckeditor}
                      className="reactQuillEditor-area"
                      richTextConfig={"minRichTextEditor"}
                      name="translated_text"
                    />
                  ) : (
                    <Field
                      component={_textArea}
                      name="translated_text"
                      className="form-control"
                      rows="3"
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <ButtonToolbar className="black">
                  <Button
                    title={tr["Save"]}
                    type="submit"
                    bsSize="small"
                    bsStyle="primary"
                    className="rgSidrkBtn"
                  >
                    {this.props.spcmReducer.spcmPostingAttribute ? (
                      <ImSpinner6 className="icn-spinner"/>
                    ) : null}{" "}
                    {tr["Save"]}
                  </Button>
                  <Button
                    onClick={() => {
                      this.props.lanEditPageFun5(false);
                    }}
                    bsSize="small"
                    bsStyle="text"
                    className="rgSiLigBtn"
                  >
                    Close
                  </Button>
                </ButtonToolbar>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ spcmReducer, cartCount }) => {
  return {
    spcmReducer,
    baseLanguage: cartCount.languageText,
  };
};

TextFieldForm = reduxForm({
  form: "NameDescForm",
  validate,
  enableReinitialize: true,
})(TextFieldForm);

export default connect(mapStateToProps, {
  spcmLoader,
  getAttributesTranslationStatus,
  languageListTranslationStatus,
  translate,
  getTranslationForOfferingNameDescriptionSummary,
  resetReducer,
})(TextFieldForm);
