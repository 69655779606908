
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Row, Col, ListGroup } from "react-bootstrap";
import { getTimelineData } from "../../../../actions/cmdb/cmdbTimelineAction";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import {IoClose} from "react-icons/io5";

class CMDBTimeline extends React.Component {
  renderTimeline(itemDetails, timelineData) {
    let timelineArr;
    const bgButtonNo = ["8", "4", "2", "11", "7", "5"];
    const color = ["parrot", "skyBlue", "green1", "orange", "pinkDark", "wgray1"];
    let butoonNoToget = 0;
    let bgBtn = "";
    let bgColor = "";
    if (timelineData.length == 0) {
      return (
        <div className="timeline">
          {" "}
          <span className="spCircle bgBtn8">&nbsp;</span>
          <ListGroup bsPrefix=" " as="ul">
            <ListGroup.Item
              bsPrefix=" "
              as="li"
              className="text-r f-size-14 fw-600 parrot"
            >
              {itemDetails.CREATED_AT}{" "}
            </ListGroup.Item>
            <ListGroup.Item bsPrefix=" " as="li">
              <span className="bgBtn8 display-block white fw-600 f-size-14 border-radius4 padding-5 posBox padding-l-10">
                {" "}
                <i className="fa fa-caret-left posArrowr f-size-20 parrot"></i>{" "}
                {itemDetails.STATUS_NAME}{" "}
              </span>{" "}
              <span className="display-block black f-size-10  marginM-5">
                {itemDetails.CREATED_AT}
              </span>{" "}
              <span className="display-block f-size-10">
                {"By " + itemDetails.CREATED_BY}
              </span>
            </ListGroup.Item>
          </ListGroup>
        </div>
      );
    } else {
      timelineArr = timelineData.map((item, index) => {
        if (butoonNoToget < bgButtonNo.length) {
          bgBtn = "bgBtn" + bgButtonNo[butoonNoToget];
          bgColor = color[butoonNoToget];
          butoonNoToget++;
        } else {
          butoonNoToget = 0;
        }
        if (index % 2 == 0) {
          return (
            <div className="timeline">
              {" "}
              <span className={"spCircle " + bgBtn}>&nbsp;</span>
              <ListGroup bsPrefix=" " as="ul">
                <ListGroup.Item
                  bsPrefix=" "
                  as="li"
                  className={"text-r f-size-14 fw-600 " + bgColor}
                >
                  <div>{item.CREATED_ON}</div>
                  <span className="display-block f-size-10">
                    {"By " + item.CREATED_BY}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item bsPrefix=" " as="li">
                  <span
                    className={
                      bgBtn +
                      " display-block white fw-600 f-size-14 border-radius4 padding-5 posBox padding-l-10"
                    }
                  >
                    {" "}
                    <i
                      className={
                        "fa fa-caret-left posArrowr f-size-20 " + bgColor
                      }
                    ></i>{" "}
                    {item.TONAME}{" "}
                  </span>{" "}
                  {/* <span className="display-block black f-size-10  marginM-5">{item.CREATED_ON}</span> */}{" "}
                </ListGroup.Item>
              </ListGroup>
            </div>
          );
        } else {
          return (
            <div className="timeline">
              {" "}
              <span className={"spCircle " + bgBtn}>&nbsp;</span>
              <ListGroup bsPrefix=" " as="ul">
                <ListGroup.Item bsPrefix=" " as="li">
                  {" "}
                  <span
                    className={
                      bgBtn +
                      " display-block white fw-600 f-size-14 border-radius4 padding-5 text-r posBox padding-r-10"
                    }
                  >
                    <i
                      className={
                        "fa fa-caret-right posArrowl f-size-20 " + bgColor
                      }
                    ></i>{" "}
                    {item.TONAME}
                  </span>{" "}
                  {/* <span className="display-block black f-size-10 text-r marginM-5">{item.CREATED_ON}</span>  */}
                </ListGroup.Item>
                <ListGroup.Item
                  bsPrefix=" "
                  as="li"
                  className={"f-size-14 fw-600 " + bgColor + " text-l"}
                >
                  <div>{item.CREATED_ON}</div>
                  <span className="display-block f-size-10">
                    {"By " + item.CREATED_BY}
                  </span>
                </ListGroup.Item>
              </ListGroup>
            </div>
          );
        }
      });
    }
    let firstItem = (
      <div className="timeline">
        {" "}
        <span className={"spCircle " + bgBtn}>&nbsp;</span>
        <ListGroup bsPrefix=" " as="ul">
          <ListGroup.Item bsPrefix=" " as="li">
            {" "}
            <span
              className={
                bgBtn +
                " display-block white fw-600 f-size-14 border-radius4 padding-5 text-r posBox padding-r-10"
              }
            >
              <i
                className={"fa fa-caret-right posArrowl f-size-20 " + bgColor}
              ></i>{" "}
              {timelineData[0].FROMNAME}
            </span>{" "}
            {/* <span className="display-block black f-size-10 text-r marginM-5">{item.CREATED_ON}</span>  */}
          </ListGroup.Item>
          <ListGroup.Item
            bsPrefix=" "
            as="li"
            className={"f-size-14 fw-600 " + bgColor + " text-l"}
          >
            <div>{itemDetails.CREATED_AT}</div>
            <span className="display-block f-size-10">
              {"By " + itemDetails.CREATED_BY}
            </span>
          </ListGroup.Item>
        </ListGroup>
      </div>
    );
    timelineArr.splice(0, 0, firstItem);
    return timelineArr;
  }
  render() {
    if (this.props.cmdbTimeLineData.isFetching == true) {
      return <div>{this.props.translator["loading..."]}</div>;
    }
    const itemDetails = this.props.CIEditDetails;
    const timelineData = this.props.cmdbTimeLineData.timeLineData;
    return (
      <div className="clDiv Ovrflow StTimeline">
        <Row>
          <Col xs={12}>
            <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
              <ul>
                <li>
                <button
                    type='button'
                    title="Minimize the right panel"
                    bsClass=""
                    bsStyle=""
                    className="closerightPanelBtn"
                    onClick={() => {
                    this.props.rightEditPanel(false);
                    }}
                >
                    <IoClose/>
                </button>
                </li>
              </ul>
            </div>
            <div className="rPageHeading">
              <div className="offNam margin-t-5 margin-b-5">
                {this.props.translator["Status Timeline"]}
              </div>
            </div>
          </Col>
        </Row>

        <div className="rBoxGap">
          <Scrollbars
            style={{ height: 500 }}
            autoHide={true}
            hideTracksWhenNotNeeded={true}
            className="timelineScroll"
          >
            <div className="verBorder">
              {this.renderTimeline(itemDetails, timelineData)}
            </div>
          </Scrollbars>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ cmdbTimeLineData }) => {
  return {
    cmdbTimeLineData,
  };
};

export default connect(mapStateToProps, { getTimelineData })(CMDBTimeline);
