
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Form from 'react-bootstrap/Form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { GLOBAL } from "_Globals";

import { loadBreakFixStatusDetails } from '../../../../actions/breakFix/breakFixStatusAction';

import { loadBreakFixEditDetailsUpdate } from '../../../../actions/breakFix/breakFixEditDetailsAction';
import { loadBreakFixReasonCode } from '../../../../actions/breakFix/reasonCodeAction';
import { loadBreakFixEditDetails } from '../../../../actions/breakFix/breakFixEditDetailsAction';
import { loadStatusBasedHamburgerOptions } from '../../../../actions/common/loadStatusBasedHamburgerOptions';
import { loadTimelineData } from '../../../../actions/common/commonApisActions.js';
import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";

import swal from 'sweetalert'
const Joi = require('joi')
import Cookies from "universal-cookie";
const cookies = new Cookies();
let languageSelected = cookies.get("gph");
languageSelected = languageSelected.split("~");
languageSelected = languageSelected[20];
const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

class XsmBreakFixReason extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      reasonCode: '',
      reasonValue: '',
      comments: '',
      canSavedMessage: '',
      holdSavedMessage: '',
      ReopenSavedMessage: '',
      canErrorMessage: '',
      holdErrorMessage: '',
      ReopenErrorMessage: '',
      clickOnCalSubmit: 0,
      clickOnHoldSubmit: 0,
      clickOnReopenSubmit: 0,
      subStatuses:[]
    };
    this.postCancActivityData = this.postCancActivityData.bind(this);
    this.postHoldActivityData = this.postHoldActivityData.bind(this);
    this.postReopenActivityData = this.postReopenActivityData.bind(this);
    this.activityData = this.activityData.bind(this);
    this.setReasonCode = this.setReasonCode.bind(this);
    //this.props.loadBreakFixReasonCode('Breakfix');
  };

  componentWillMount() {
    // this.state = {
    //   comments: '',
    //   canSavedMessage: '',
    //   holdSavedMessage: '',
    //   ReopenSavedMessage: '',
    //   canErrorMessage: '',
    //   holdErrorMessage: '',
    //   ReopenErrorMessage: '',
    //   clickOnCalSubmit: 0,
    //   clickOnHoldSubmit: 0,
    //   clickOnReopenSubmit: 0
    // };
    let type = "";
    if(this.props.cancelIssue == 1){
      type = "Cancelled";
    }else if(this.props.putOnHold == 1){
      type="On Hold";
    }
    axios.get('/api/company_menulist?companyId='+this.props.breakFixEditDetails.consumerCompany+'&module=Incident&field='+type+'&language='+languageSelected+'&status=1').then((responseDropdownValues)=>{
      this.setState({subStatuses:responseDropdownValues.data});
      });
  }

  //rendering predefined causeName
  renderFixReasonName(breakFixData) {
    if (!(breakFixData && breakFixData.length)) {
      return null;
    }
    return breakFixData.map((obj, i) => {
      return (
        <option value={obj.field1Value}>{obj.field1Key}</option>
      );
    })
  }

  postCancActivityData() {
    if ((this.props.user_id != this.props.breakFixEditDetails.spUserid) && (this.props.roleIdentification('12') || this.props.roleIdentification('13')))
      swal({
        text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
        button: this.props.translator['OK'],
      });
    else {
      //alert(this.props.cancelIssue);
      //alert("comments:"+this.state.comments);
      console.log("this.props.breakFixEditDetails", this.props.breakFixEditDetails);
      let individualUserId = "";
      if (this.props.breakFixEditDetails && this.props.breakFixEditDetails.spUserid) {
        individualUserId = this.props.breakFixEditDetails.spUserid;
      }
      console.log("individualUserId:: ", individualUserId);

      if (this.props.supportPIndividualValue != undefined && individualUserId != this.props.supportPIndividualValue) {
        alert('First save the selected Individual!');
        return false;
      }
      else if (individualUserId == 0 || individualUserId == "" || individualUserId == null
        || this.props.supportPIndividualValue == 0) {
        alert('Please select an Individual!');
        return false;
      }

      let self = this;
      if (this.state.comments != "" && this.state.reasonCode != "select" && this.state.reasonCode != undefined && this.state.reasonCode != "") {
        self.setState({
          canErrorMessage: "",
          clickOnCalSubmit: 1
        });
        //this.props.fun(false,true,true);
        let descriptionValue = 'Cancellation Type:' + this.state.reasonValue
        let postjson = {
          "itemId": GLOBAL.breakFixId,
          "description": descriptionValue+", Cancellation Reason: " + this.state.comments,
          "isInternal": 'N',
          "createdOn": "",
          "createdBy": "",
          "module": 'Breakfix',
          "createdByName": ""
        }
        console.log(postjson);
        axios.post(GLOBAL.breakFixActivityLogPostUrl, postjson).
          then((response) => {

            if (response.status===201) {


              //====changing status=====
              axios.patch(GLOBAL.breakFixPatchUrl + GLOBAL.breakFixId, {
                "status": 35,
                "modifiedBy": "",
                "modifiedByName": "",
                "cancellationReasonCode":this.state.reasonCode,
                "cancellationReasonValue":this.state.reasonValue,
                "cancellationReason": this.state.comments
              })
                .then(function (response) {
                  console.log(response);
                  // self.props.loadBreakFixStatusDetails(30,35,GLOBAL.breakFixId);
                  //self.props.loadBreakFixEditDetailsUpdate(GLOBAL.breakFixId);
                  self.props.loadBreakFixEditDetails(response.data.breakfixId);
                  self.props.loadStatusBasedHamburgerOptions('Breakfix', response.data.status, response.data.breakfixId);
                  self.setState({
                    canSavedMessage: "The Incident has been Cancelled.",
                    canErrorMessage: "",
                    clickOnCalSubmit: 0
                  })
                  // this.activityDescription.value = "";
                  self.setState({comments: ""})
                })
                .catch(function (error) {
                  console.log(error);
                });
              //========================
            } else {
              console.log("Error in executing breakFixActivityLogPostUrl")
            }

          });
      } else {
        
        if (this.state.comments == "") {
          self.setState({
            canErrorMessage: "Please provide cancellation  reason"
          })
        }
        if (this.state.reasonCode == "select" || this.state.reasonCode == undefined || this.state.reasonCode == "") {
          self.setState({ canErrorMessage: 'Please provide Cancellation Type.' })
        }
        //alert('Please mention reason');
      }
    }
  }
  postHoldActivityData() {
    if ((this.props.user_id != this.props.breakFixEditDetails.spUserid) && (this.props.roleIdentification('12') || this.props.roleIdentification('13')))
      swal({
        text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
        button: this.props.translator['OK'],
      });
    else {
      let self = this;
      console.log("this.state.reasonCode " + this.state.reasonCode);
      console.log("this.state.comments " + this.state.comments);
      let actualComments = this.state.comments.trim();
      //alert(actualComments)
      if (actualComments != "" && this.state.reasonCode != "select" && this.state.reasonCode != undefined && this.state.reasonCode !== "") {
        self.setState({
          holdErrorMessage: "",
          clickOnHoldSubmit: 1
        });
        let descriptionValue = 'Hold Type:' + this.state.reasonValue;
        let postjson = {
          "itemId": GLOBAL.breakFixId,
          "description": descriptionValue+', '+'Reason of holding:' + this.state.comments,
          "isInternal": 'N',
          "createdOn": "",
          "createdBy": "",
          "module": 'Breakfix',
          "createdByName": ""
        }

        axios.post(GLOBAL.breakFixActivityLogPostUrl, postjson).
          then((response) => {

            if (response.status===201) {
              // this.activityDescription.value = "";
              this.setState({comments: ""});

              //====changing status=====
              axios.patch(GLOBAL.breakFixPatchUrl + GLOBAL.breakFixId, {
                "status": 20,
                "modifiedBy": "",
                "modifiedByName": "",
                "holdingReasonCode": this.state.reasonCode,
                "holdingReasonValue": this.state.reasonValue,
                "holdingComments": this.state.comments
              })
                .then(function (response) {
                  console.log(response);
                  //self.props.loadBreakFixEditDetailsUpdate(GLOBAL.breakFixId);
                  // self.props.loadBreakFixStatusDetails(20,20,GLOBAL.breakFixId);
                  self.props.loadBreakFixEditDetails(response.data.breakfixId);
                  self.props.loadStatusBasedHamburgerOptions('Breakfix', response.data.status, response.data.breakfixId);
                  self.setState({
                    //savedMessage:"Requested cteated for order "+response.data.breakfixNumber
                    holdSavedMessage: "Comments have beed saved in ",
                    holdErrorMessage: "",
                    clickOnHoldSubmit: 0
                  });
                  self.props.showProcessHierarchy();
                })
                .catch(function (error) {
                  console.log(error);
                });
              //========================
            }

          });
      } else {
        if (actualComments == "") {
          self.setState({ holdErrorMessage: "Please provide reason" })
        }
        if(this.state.reasonCode === ""){
          self.setState({ holdErrorMessage: "Please provide Hold Type" });
        }
        if (this.state.reasonCode == "select" || this.state.reasonCode == undefined) {
          self.setState({ holdErrorMessage: 'Please provide Hold Status.' })
        }

        //alert('Please mention reason');
      }
    }
  }
  postReopenActivityData() {
    // if ((this.props.user_id != this.props.breakFixEditDetails.spUserid) && (this.props.roleIdentification('12') || this.props.roleIdentification('13')))
    //   swal({
    //     text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
    //     button: this.props.translator['OK'],
    //   });
    // else {
      GLOBAL.reopen = true;
      let ref = this;
      if (this.state.comments.length != 0) {
        let count = 0
        for (let i = 0; i < this.state.comments.length; i++) {
          if (this.state.comments[i] == ' ')
            count++
        }
        let code_count = 0
        if (count != this.state.comments.length) {
          let namedSchema = Joi.string().regex(/^[^!@#\-$%\]^&\[/*_+=|\:;\'\"~<,}>{)`(]+$/);
          let validator_to_add_myQbj = namedSchema.validate(this.state.comments)
          if (validator_to_add_myQbj.error != null)
            code_count++
        }

        if (count == this.state.comments.length)
          this.setState({
            ReopenErrorMessage: this.props.translator["Empty string cannot be submitted"]
          })
        else if (code_count != 0)
          this.setState({
            ReopenErrorMessage: this.props.translator["String with special characters cannot be submitted"]
          })
        else {
          this.setState({
            ReopenErrorMessage: "",
            clickOnReopenSubmit: 1
          })
          api.get(GLOBAL.breakFixEditUrl + ref.props.breakFixEditDetails.breakfixId)
          .then((breakFixEditDetails) => {
            if (breakFixEditDetails.data.status === "Fixed") {
            axios.patch("/breakfixAction/" + ref.props.breakFixEditDetails.breakfixId, {
              status: "10", statusReopen: true, spUserid: '0', spUsername: "", reopenReason: ref.state.comments, "modifiedBy": "",
              "modifiedByName": "",
              type: null,
              businessImpact: null,
              probableCause: null,
              "resolutionTime":0
            }).then((responseData) => {
              ref.props.loadBreakFixEditDetails(responseData.data.breakfixId);
              ref.props.loadStatusBasedHamburgerOptions('Breakfix', responseData.data.status, responseData.data.breakfixId);
              axios.post(GLOBAL.breakFixActivityLogPostUrl, {
                "itemId": ref.props.breakFixEditDetails.breakfixId,
                "description": ref.state.comments,
                "createdOn": "",
                "createdBy": "",
                "isInternal": "N",
                "module": "breakfix",
                "createdByName": "",
              }).then((response) => {
                ref.props.showProcessHierarchy();
                this.setState({
                  clickOnReopenSubmit: 0,
                  ReopenSavedMessage: this.props.translator["Reason to Reopen comments saved sucessfully"]
                })
              }).catch((error) => {
                this.setState({
                  clickOnReopenSubmit: 0,
                  ReopenErrorMessage: this.props.translator["Reason Cannot Be Submitted"]
                })
              })
            }).catch((error) => {
              this.setState({
                clickOnReopenSubmit: 0,
                ReopenErrorMessage: this.props.translator["Reason Cannot Be Submitted"]
              })
            })
        } 
        else
        {
          this.setState({
            clickOnReopenSubmit: 0,
            ReopenErrorMessage: this.props.translator["Reason Cannot Be Submitted"]
          })
        }})
        }
      }
      else {
        this.setState({
          ReopenErrorMessage: this.props.translator["Reason cannot be left blank"]
        })
      }
    // }
  }
  activityData(comnts) {
    this.setState({ comments: comnts.target.value });

  }
  setReasonCode(event) {
    //console.log("val :"+value +" "+label);

    this.setState({
      reasonCode: event.target.value,
      reasonValue: event.target[event.target.selectedIndex].text
    })

    //  console.log(event.target.value);
    //    console.log(" label :: "+event.target[event.target.selectedIndex].text);
  }

  render() {

    // return <h1>Hello World</h1>
    //console.log(GLOBAL.breakFixId)
    //alert(this.state.clickOnCalSubmit)
    //alert(this.state.holdSavedMessage)
    if (this.props.breakFixEditDetails && this.props.breakFixEditDetails.status && this.props.breakFixEditDetails.status == "Cancelled") {
      return null;
      // return (
      //   <div>
      //     <div>
      //       <div className="rPageHeading">{this.props.translator['Process Hierarchy']}</div>
      //       <div><img className="img-responsive marAuto" src={require('../../images/change-diagram.png')} title={this.props.translator['Process Hierarchy']} /></div>
      //     </div>
      //   </div>
      // );
    }
    else if (this.props.cancelIssue == 1 && (this.state.canSavedMessage == undefined || this.state.canSavedMessage == "")) {
      return (
        <div className="hambTab">
          <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
            <ul>
              <li>
              <button
                  type='button'
                title="Minimize the right panel"
                bsClass=""
                bsStyle=""
                className="closerightPanelBtn"
                onClick={() => {
                this.props.rightEditPanel(false);
                }}
              >
                <IoClose />
              </button>
              </li>
            </ul>
          </div>
          <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Cancel Issue']}</div></div>
          
          <div className='rBoxGap'>
          <Form.Group className="form-group" >
            <Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Cancellation Type']} </Form.Label>
            <Form.Select required="true" aria-label="select" onChange={(event) => { this.setReasonCode(event) }}>
              <option value="select">Select</option>
              {this.renderFixReasonName(this.state.subStatuses)}
            </Form.Select>
          </Form.Group>
          <Form.Group className="form-group" >
            <Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Reason']} </Form.Label>
            {/*<Form.Control componentClass="textarea" rows="2" inputRef = {(input) => this.inputComments = input } onChange ={this.getIssue.bind(this)}/>*/}
            <Form.Control as="textarea" rows="2" 
            // inputRef={(input) => this.activityDescription = input} 
            value={this.state.comments}
            onChange={this.activityData.bind(this)} />
          </Form.Group>
          <div><font color="red"><strong>{this.state.canErrorMessage}</strong></font></div>

          <ButtonToolbar className="black"><Button className="rgSidrkBtn smallBtn" bsSize="small" bsStyle="primary"
            disabled={this.state.clickOnCalSubmit} onClick={() => { this.postCancActivityData(); }} >
            {this.state.clickOnCalSubmit ? <ImSpinner6 className="icn-spinner"/>
              : null} {this.props.translator['Submit']}</Button>
          </ButtonToolbar>

          { /* this.state.clickOnCalSubmit == '0'? <div>
                <ButtonToolbar className="margin-t-15"><Button bsStyle="primary"
                 onClick={()=>{this.postCancActivityData();}}>{this.props.translator['Submit']}
                 </Button>
                 </ButtonToolbar>
              </div>:
              null */}
          </div>
        </div>
      );
    }

    //============
    if (this.props.putOnHold == 1 && (this.state.holdSavedMessage == undefined || this.state.holdSavedMessage == "")) {
      return (
        <div className="hambTab">
          <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
            <ul>
              <li>
              <button
                  type='button'
                title="Minimize the right panel"
                bsClass=""
                bsStyle=""
                className="closerightPanelBtn"
                onClick={() => {
                this.props.rightEditPanel(false);
                }}
              >
                <IoClose />
              </button>
              </li>
            </ul>
          </div>
          <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Put on hold']}</div></div>
          
          <div className='rBoxGap'>
          <Form.Group className="form-group" >
            <Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Hold Status']} </Form.Label>
            {/*<Form.Control componentClass="textarea" rows="2" inputRef = {(input) => this.inputComments = input } onChange ={this.getIssue.bind(this)}/>*/}
            <Form.Select required="true" aria-label="select" onChange={(event) => { this.setReasonCode(event) }}>
              <option value="select">Select</option>
              {this.renderFixReasonName(this.state.subStatuses)}
            </Form.Select>
          </Form.Group>
          <Form.Group className="form-group" >
            <Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Reason']} </Form.Label>
            {/*<Form.Control componentClass="textarea" rows="2" inputRef = {(input) => this.inputComments = input } onChange ={this.getIssue.bind(this)}/>*/}
            <Form.Control required="true" as="textarea" rows="2" 
            // inputRef={(input) => this.activityDescription = input} 
            value={this.state.comments}
            onChange={this.activityData.bind(this)} />
          </Form.Group>

          <div><font color="red"><strong>{this.state.holdErrorMessage}</strong></font></div>

          <ButtonToolbar className="black"><Button className="rgSidrkBtn smallBtn" bsSize="small" bsStyle="primary" disabled={this.state.clickOnHoldSubmit} onClick={() => { this.postHoldActivityData(); }} >
            {this.state.clickOnHoldSubmit ? <ImSpinner6 className="icn-spinner"/> : null} {this.props.translator['Submit']}</Button>
          </ButtonToolbar>
          </div>
        </div>
      );
    } else if (this.props.putOnHold == 1) {
      return (
        <div>
          <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
            <ul>
              <li>
              <button
                  type='button'
                title="Minimize the right panel"
                bsClass=""
                bsStyle=""
                className="closerightPanelBtn"
                onClick={() => {
                this.props.rightEditPanel(false);
                }}
              >
                <IoClose />
              </button>
              </li>
            </ul>
          </div>
          <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Put on hold']}</div></div>
          <div className='rBoxGap'>
            {this.state.holdSavedMessage}
          </div>
        </div>
      );
    }

    if (this.props.ReOpen == 1 && (this.state.ReopenSavedMessage == undefined || this.state.ReopenSavedMessage == "")) {
      return (
        <div>
          <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
            <ul>
              <li>
              <button
                  type='button'
                title="Minimize the right panel"
                bsClass=""
                bsStyle=""
                className="closerightPanelBtn"
                onClick={() => {
                this.props.rightEditPanel(false);
                }}
              >
                <IoClose />
              </button>
              </li>
            </ul>
          </div>
          <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Reason For Reopen']}</div></div>

          <div className='rBoxGap'>
          <Form.Group className="form-group">
            <Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Reason']} </Form.Label>
            <Form.Control as="textarea" rows="2" 
            // inputRef={(input) => this.activityDescription = input}
            value={this.state.comments}
            onChange={this.activityData.bind(this)} />
          </Form.Group>
          <div><font color="red"><strong>{this.state.ReopenErrorMessage}</strong></font></div>
          <ButtonToolbar className="black"><Button className="rgSidrkBtn smallBtn" bsSize="small" bsStyle="primary"
            disabled={this.state.clickOnReopenSubmit} onClick={() => { this.postReopenActivityData(); }} >
            {this.state.clickOnReopenSubmit ? <ImSpinner6 className="icn-spinner"/>
              : null} {this.props.translator['Submit']}</Button>
            <Button className="smallBtn" bsSize="small" bsStyle="primary"
              disabled={this.state.clickOnReopenSubmit}
              onClick={() => { this.props.showProcessHierarchy(); }} >
              {this.props.translator['Cancel']}</Button>
          </ButtonToolbar>
          </div>
        </div>
      )
    } else if (this.props.ReOpen == 1) {
      return (
        <div>
          <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
            <ul>
              <li>
              <button
                  type='button'
                title="Minimize the right panel"
                bsClass=""
                bsStyle=""
                className="closerightPanelBtn"
                onClick={() => {
                this.props.rightEditPanel(false);
                }}
              >
                <IoClose />
              </button>
              </li>
            </ul>
          </div>
          <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Reason For Reopen']}</div></div>
          <div className='rBoxGap'>{this.state.ReopenSavedMessage}</div>
        </div>
      );
    }

  }
}

/*export function mapStateToProps({ breakFixActivityLogDetails,breakFixEditDetails }){
//  alert("breakFixEditDetails:"+breakFixActivityLogDetails.length);
  //console.log("breakFixEditDetails:"+breakFixActivityLogDetails.from);
  return { breakFixActivityLogDetails,breakFixEditDetails };
}*/
export function mapStateToProps({ reasonCode, breakFixEditDetails }) {
  console.log("breakFixEditDetails : ", breakFixEditDetails)
  return { reasonCode, breakFixEditDetails };
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loadBreakFixReasonCode, loadBreakFixEditDetailsUpdate, loadBreakFixStatusDetails, loadBreakFixEditDetails, loadStatusBasedHamburgerOptions, loadTimelineData }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(XsmBreakFixReason);
//export default XsmBreakFixReason;
