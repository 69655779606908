
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const fullFillmentStatusTimeLine = (state = [], action) =>{
	switch (action.type){
		case 'ORDER_FULFILLMENT_STATUS_TIMELINE_SUCCESS':
				return action.statusTimeLine.data;
		case 'ORDER_FULFILLMENT_STATUS_TIMELINE_FAILURE':
				return action.errorResponse;
		default:
				return state;
		}
};
