
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from 'react'
import ListLoader from "../../../common/loaders/ListLoader";
import { Col, Form, Row } from 'react-bootstrap';
import {connect, useDispatch, useSelector } from 'react-redux';
import {  _inputField } from "../../../common/formFields";
import { Field, change, reduxForm } from "redux-form";
import { useParams } from "react-router";
import { getClassDefinitionDetails, loadClassByCompanyId } from '../../../../actions/ham/hamAction';
import { loadCIClass } from '../../../../actions/cmdb/lovAction';
import { _dropDown } from '../../../common/ReduxFormFields/CategoryBoard/formFields';

let EditClassDefinitionForm = (props) => {


  const tr = useSelector((state) => state.spcmReducer.tr);
  const params = useParams();
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.commonLoader);
  const initialValue = useSelector((state) => state.classEditList);
  const ciClass = useSelector((state) => state.compClass);
  const [ciClassOptions, setCiClassOptions] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  
  useEffect(() => {
    dispatch(getClassDefinitionDetails(params.class_id));
    dispatch(loadCIClass());
  }, []);

  console.log("initialValue---",initialValue);

const handleciClassChange = (event) => {
  //setState({ selectedClass: event.target.value });
  setSelectedClass(event.target.value);
  dispatch(change('EditClassDefinitionForm', 'CLASS_NAME', event.target[event.target.selectedIndex].text))
}

useEffect(() => {

if(initialValue && initialValue.COMPANY_ID){
  props.setCompanyId(initialValue.COMPANY_ID);
  dispatch(loadClassByCompanyId(initialValue.COMPANY_ID, true));
}

}, [initialValue]);

useEffect(() => {
 
  let filteredOptions = ciClass.filter(obj =>obj.CLASS_ID!==initialValue.CLASS_ID)
  console.log("filteredOptions---",filteredOptions);
  setCiClassOptions(filteredOptions);

}, [ciClass]);


const  validateClassFields = (fieldType, event)=> {
  switch (fieldType) {

      case "status":
        if (event.target.value == "") {
          props.setStatusErr("error");
        } else {
          props.setStatusErr("");
        }
        break;
      default:
    }
}
  return (
    <>
     {loader.isLoading ? (
        <ListLoader />
      ) : (
        <Form>
        <Row>
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              <Form.Label>{"Class Name"}</Form.Label>
              <Field
                name="ClassName"
                className={"form-control"}
                component={_inputField}
                disabled
              />
            </Form.Group>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              <Form.Label>{"Class Id"}</Form.Label>
              <Field
                name="classId"
                className={"form-control"}
                component={_inputField}
                disabled
              />
            </Form.Group>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              <Form.Label>{"Type"}</Form.Label>
              <Field
                name="type"
                className={"form-control"}
                component={_inputField}
                disabled
              />
            </Form.Group>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              <Form.Label>{"Company"}</Form.Label>
              <Field
                name="company"
                className={"form-control"}
                component={_inputField}
                disabled
              />
            </Form.Group>
          </Col>
       
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              <Form.Label>{"Parent Class"}</Form.Label>
              <Field
                name="parent"
                className={"form-control"}
                component={_dropDown}
                options={ciClassOptions}
                onChange={(event) => handleciClassChange(event)}
             
              />
            </Form.Group>
          </Col>
          <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                <span className="rStar"></span>
                <Form.Label>
                  {tr["Status"]}
                </Form.Label>
                <Field
                  name="status"
                  component="select"
                  className={"form-control " + props.statusErr}
                  onBlur={(event) => {
                    validateClassFields("status", event);
                }}
                >
                  <option value="">{tr["Select"]}</option>
                  <option value="active">{tr["Active"]}</option>
                  <option value="inactive">{tr["Inactive"]}</option>
                </Field>
              </Form.Group>
            </Col>

          </Row>
          </Form>
        )} 
    </>
  );
}



EditClassDefinitionForm = reduxForm({
  form: "EditClass",
  enableReinitialize: true,
})(EditClassDefinitionForm);

EditClassDefinitionForm = connect(({ classEditList }) => ({
  initialValues: {
    classId: classEditList?.CLASS_ID,
    type:classEditList?.CLASS_TYPE,
    ClassName: classEditList?.CLASS_NAME,
    status: classEditList?.STATUS,
    company: classEditList?.COMPANY_NAME,
    companyId: classEditList?.COMPANY_ID,
    createdBy: classEditList?.CREATED_BY,
    createdDate: classEditList?.CREATED_AT,
    updatedBy: classEditList?.UPDATED_BY,
    updatedDate: classEditList?.UPDATED_AT,
    parent: classEditList?.PARENT_CLASS_ID,
  },
}))(EditClassDefinitionForm);

export default EditClassDefinitionForm;
