
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import { MdAdd, MdArrowBackIos, MdSave } from 'react-icons/md';
import { Button } from "@progress/kendo-react-buttons";
import { useParams } from "react-router-dom";
import RestApiConnector from "../connectors/restApi";
import ListLayout from "./listLayout";
import { GetDataQueries, DeleteDataQuery } from "../connectors/graphQl";
import { useQuery, useMutation } from "@apollo/client";
import { Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

const DataQuery = (props) => {
    const tr = useSelector((state) => state.spcmReducer.tr);
    const [addMode, setAddMode] = useState(false);
    const dataQueryRef = useRef();
    const params = useParams();
    const [validated, setValidated] = useState(true);
    const [submittingForm, setSubmittingForm] = useState(false);
    const { data: dataQueries, refetch: fetchDataQueries } = useQuery(GetDataQueries, {
        variables: { input: { form: params.formId } },
        skip: !params.formId
    });
    const [recordId, setRecordId] = useState(null);
    const [deleteDataQuery, { data: dataQueryData, loading: deleting }] = useMutation(DeleteDataQuery);

    const requestValidated = (value) => {
        setValidated(value);
    }

    const loading = (value) => {
        setSubmittingForm(value);
        if (!value) fetchDataQueries();
    }

    const editItem = (id) => {
        setRecordId(id);
        setAddMode(true);
    }

    const deleteItem = (id) => {
        Swal.fire({
            title: tr["Are you sure you want to delete?"],
            width:400,
            showDenyButton: true,
            confirmButtonText: tr["Yes"],
            denyButtonText: tr["No"],
        }).then((result) => {
            if (result.isConfirmed) {
                deleteDataQuery({variables: {id: id}});
            }
        });
    }

    useEffect(() => {
        if(dataQueryData){fetchDataQueries()}
    }, [deleting])

    return (
        <div className="rightPanelComp clearboth">
            <div className="rPageHeading d-flex">
                <div>Data Query</div>
                {params.formId && (
                    <>
                        <Button fillMode="outline" onClick={() => { setAddMode(!addMode); setRecordId(null) }} className='ms-auto closeIcon' size="small">
                            {addMode ? (<MdArrowBackIos></MdArrowBackIos>) : (<MdAdd></MdAdd>)}
                        </Button>
                        {addMode && (
                            <Button disabled={validated || submittingForm} className='closeIcon' onClick={() => { dataQueryRef.current?.handleSubmit() }} title='Save' fillMode="outline">
                                {submittingForm
                                    ? (<Spinner animation="border" size="sm" />)
                                    : (<MdSave />)
                                }
                            </Button>
                        )}
                    </>
                )}
                <Button className={params.formId ? 'closeIcon' : 'closeIcon ms-auto'} onClick={() => { props.setRightPanelShow(false) }} title='Minimize the details panel' fillMode="outline"><IoClose /></Button>
            </div>
            {params.formId
                ? (<>
                    {addMode
                        ? (<div className="p-2">
                            <RestApiConnector
                                formId={params.formId}
                                ref={dataQueryRef}
                                type="dataQuery"
                                requestValidated={requestValidated}
                                setAddMode={setAddMode}
                                loading={loading}
                                recordId={recordId}
                            ></RestApiConnector>
                        </div>)
                        : (
                            <ListLayout data={dataQueries ? dataQueries.dataQueries : []} title="Data Queries" editItem={editItem} deleteItem={deleteItem}></ListLayout>
                        )
                    }
                </>)
                : (<div className="p-2">
                    Please save the form first.
                </div>)
            }
        </div>
    );
}

export default DataQuery;