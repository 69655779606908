
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Row, Col, Form, Button, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { setEditServiceDetails } from '../../../../actions/spcmActions';
import {getCmdbApprovals} from '../../../../actions/cmdb/lovAction';
import axios from 'axios';
import Pagination from "react-js-pagination";
import CmdbCiApprovalsForm from "./CmdbCiApprovalEditForm.js";
import ListLoader from "../../../../components/common/loaders/ListLoader"
import { SubmissionError } from 'redux-form';
import Swal from 'sweetalert2';
import {IoSearch, IoClose} from "react-icons/io5";
import {HiPlus} from 'react-icons/hi';

class CmdbCiApprovalsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            queryBy: 'Role',
            query: '',
            sortBy: 'Role',
            pageSize: 10,
            showError: false,
            error: 'Changes are not allowed in Deployed status',
            sortByTitle: '',
            currentForm: 'approval_form',
            extraCounts: [0],
            searchBy:"",
            searchByVal:"",
            page:1,
            editToggle:false
        }
        this.onTextChange = this.onTextChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onPageSizeChange = this.onPageSizeChange.bind(this);
        this.onSearchClick = this.onSearchClick.bind(this);
        this.queryBySelect = this.queryBySelect.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.sortHover = this.sortHover.bind(this);
        this.setExtraCount = this.setExtraCount.bind(this);
        this.editToggleFunc=this.editToggleFunc.bind(this);
    }

    editToggleFunc(value,listvlue){
    let obj={};
        this.setState({editToggle:value});
    }
    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick();
            if (e.preventDefault) e.preventDefault();
        }
    }

    setExtraCount(e) {
        this.setState({ extraCounts: e });
    }

    onTextChange(e) {
        this.setState({ searchByVal: e.target.value });
    }

    onPageSizeChange(e) {
        this.setState({ pageSize: e.target.value });
        this.props.getCmdbApprovals(this.props.CI_ID, this.state.searchBy, this.state.searchByVal, this.state.page, e.target.value);
    }

    onPageChange(e) {
        this.props.getCmdbApprovals(this.props.CI_ID, this.state.searchBy, this.state.searchByVal,  e, this.state.pageSize);

    }

    onSearchClick() {
        this.props.getCmdbApprovals(this.props.CI_ID, this.state.searchBy, this.state.searchByVal,this.state.page,this.state.pageSize );
    }

    queryBySelect(e) {
        this.setState({ searchBy: e.target.value });
    }

    sortHover(e) {
        let index = e.nativeEvent.target.selectedIndex;
        this.setState({ sortByTitle: e.nativeEvent.target[index].text })
    }


    onDeleteClick(values) {
        Swal.fire({
            title: "Are you sure you wish to delete this item?",
		    
            width: 550,
            padding: '15px',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
          }).then((result) => {
            if (result.isConfirmed) {
                values.status = 0;
                axios.patch("/service/api/approval/editApprovalRule/" + values.ruleId, values).then((response) => {
                    this.props.getCmdbApprovals(this.props.CI_ID, this.state.searchBy, this.state.searchByVal, this.state.page, this.state.pageSize);
                })
                    .catch((error) => {
                        throw new SubmissionError({ _error: error.response.data[0].message });
                    });
                } else if (result.isDenied) {
              
            }
          })
    }


    componentWillReceiveProps(nextProps) {
        if(nextProps.CI_ID && nextProps.CI_ID != this.props.CI_ID) {
            this.props.getCmdbApprovals(nextProps.CI_ID,this.state.searchBy, this.state.searchByVal, this.state.page,this.state.pageSize);
            this.props.toggleFormListFunc(false);
        }
    }

    render() {
        var approvals = this.props.spcmReducer.approval;
		var meta = this.props.spcmReducer.approvalMeta;
        return (
            <div className="">
                <Row>
                    <Col xs={12}>
                        <div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
                            <ul>
                            <li>
                                {this.props.toggleFormList ? (
                                    ""
                                ) : this.props.isRoleDisabled 
                                ? (
                                    <Button
                                    bsPrefix=" "
                                    disabled={this.state.fulfilmentBtnState}
                                    onClick={() => {
                                        this.props.toggleFormListFunc(true);
                                        this.editToggleFunc(false);
                                    }}
                                    title={this.props.tr["Add"]}
                                    bsClass=""
                                    bsStyle=""
                                    >
                                    <HiPlus />
                                    </Button>
                                ) : null}
                                </li>
                        <li className="d-md-none">
                            <button
                                type='button'
                                title="Minimize the right panel"
                                bsClass=""
                                bsStyle=""
                                className="closerightPanelBtn"
                                onClick={() => {
                                this.props.rightEditPanel(false);
                                }}
                            >
                                <IoClose/>
                            </button>
                        </li>
                        </ul></div>
                        <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.tr["Approvals"]}</div></div>
                    </Col>
                </Row>

                {this.props.toggleFormList==true ? <CmdbCiApprovalsForm setExtraCount={this.setExtraCount} CLASS_ID={this.props.CLASS_ID} searchBy={this.state.searchBy} searchByVal={this.state.searchByVal} page={this.state.page} pageSize={this.state.pageSize} cmdbQuickItemList={this.props.cmdbQuickItemList} COMPANY_ID={this.props.COMPANY_ID} CI_NAME={this.props.CI_NAME} toggleFormList={this.props.toggleFormList} toggleFormListFunc={this.props.toggleFormListFunc} CLASS_NAME={this.props.CLASS_NAME} CI_ID={this.props.CI_ID} editToggle={this.state.editToggle} editToggleFunc={this.editToggleFunc} COMPANY_NAME={this.props.COMPANY_NAME}/>:
                <div className='rBoxGap'>
                        <Row className="margin-b-5">
                            <Col lg={8} md={12} sm={8} xs={12}>
                                <div style={{ width: '100%'}} className="form-inline rightSideFields valurdrpdown d-lg-flex d-md-block d-sm-flex d-block">
                                    <Form.Select bsPrefix=' ' onMouseOver={this.sortHover} title={this.state.sortByTitle} className="myControl mySel mySelectWidth form-control" onChange={this.queryBySelect}>
                                        <option value="">Select</option>
                                        <option value="ruleName">Rule Name</option>
                                        <option value="weightage">Weightage</option>
                                    </Form.Select>
                                    <div className="d-flex sbAttr">
                                    <Form.Control onKeyPress={this.handleKeyPress.bind(this)} className="myControl myInput myInputWidth" type="text" onChange={this.onTextChange} />
                                    <Button title="Search" onClick={this.onSearchClick} className="myBtn btn btn-default"><IoSearch/></Button>
                                    </div>
                                </div>
                            </Col>
                            {approvals == "" || approvals == undefined ? null :
                            <Col lg={4} md={12} sm={4} xs={12} className="text-r rightSideFields ">
                                <Form.Label className="margin-r-5 display-inline-block widthAuto">{this.props.tr["Show"]}</Form.Label>
                                <Form.Select className="display-inline-block widthAuto" onChange={this.onPageSizeChange}>
                                    <option value="10">10</option>
                                    <option value="25" >25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </Form.Select>
                            </Col>}
                        </Row>
                    <div className="f-size-12">
                        <Table bordered condensed hover responsive className="tableView  tableWithOutPointer">
                            <thead>
                                <tr>
                                    <th width="30%" className="sort">{this.props.tr["Rule Name"]}</th>
                                    <th width="20%" className="sort">{this.props.tr["Module"]}</th>
                                    <th width="20%" className="sort">{this.props.tr['Type']}</th>
                                    <th width="20%" className="sort">{this.props.tr['Weightage']}</th>
                                    <th width="10%" className="text-c">{this.props.tr["Action"]}</th>
                                </tr>
                            </thead>
                                {this.props.spcmReducer.spcmFetchingAttribute ?
                                    <tbody><td colSpan="3">
                                        <ListLoader />
                                    </td></tbody> :
                                    <tbody>
                                        {approvals == "" || approvals == undefined ? null : approvals.map(function (value, index) {
                                            return (<tr>
                                                <td>{value.ruleName}</td>
                                                <td>{value.moduleName}</td>
                                                <td>{value.approverType}</td>
                                                <td>{value.weightage}</td>
                                                <td><div className="text-c"><i title={this.props.tr["Edit"]} className="fa fa-pencil-square-o cursorPoint" onClick={(e) => { this.props.setEditServiceDetails(value); this.props.toggleFormListFunc(true); this.editToggleFunc(true) }}></i>
                                                    <i title={this.props.tr["Delete"]} className="margin-l-10 fa fa-trash-o cursorPoint" onClick={() => { this.onDeleteClick(value) }}></i></div></td>
                                            </tr>)
                                        }, this)}
                                        {
                                            (approvals && approvals.length === 0) ? <tr><td colSpan={5} className="text-c">{this.props.tr['No Data Found']}</td></tr> : null
                                        }
                                    </tbody>
                                }
                        </Table>
                    </div>
                        {approvals == "" || approvals == undefined ? null :
                            <div className="text-c">
                                <nav aria-label="Pagination" className="display-inline-block">
                                {meta ? <Pagination
                                    activePage={meta ? meta.currentPage : 1}
                                    itemsCountPerPage={this.state.pageSize}
                                    totalItemsCount={meta ? meta.rowCount : 1}
                                    onChange={this.onPageChange}
                                /> : null}
                                </nav>
                            </div>
                        }
                </div>}
            </div>
        );
    }
}
const mapStateToProps = ({ spcmReducer }) => {
    return {
        tr:spcmReducer.tr,spcmReducer
    };
};

export default connect(mapStateToProps,{getCmdbApprovals,setEditServiceDetails}) (CmdbCiApprovalsList);