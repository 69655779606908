
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { _inputField } from "../../../common/ReduxFormFields/formFields";
import { Row, Col, Table, Button } from "react-bootstrap";
import TaskOutputPostForm from "./postOutputVariableForm";
class OutputVariables extends React.Component {
  componentDidMount() {
    this.props.loadTaskOutputVariables(this.props.taskCode);
  }
  renderOutputVariables(outputs) {
    return outputs.map((output, index) => {
      return (
        <tr key={index}>
          <td>{output.output_key}</td>
          <td>{output.output_value}</td>
          {this.props.disableFieldsForGuestRole?'':<td>
            <div className="text-c">
            <i
                title={this.props.translator["Edit Variable"]}
                onClick={() => {
                  this.props.editVarFun1(true, this.props.translator["Edit"]);
                  this.props.initializeTaskOutputEditForm(output);
                }}
                className="fa fa-pencil-square-o cursorPoint"
              ></i>
            </div>
            </td>}
        </tr>
      );
    });
  }
  render() {
    const { outputs, fetchingOutputs, errorFetchingOutputs, translator } =
      this.props;
    if (errorFetchingOutputs) {
      return (
        <div>
          <h4>error loading output variables</h4>
        </div>
      );
    }
    return (
      <div>
        {this.props.editVar1 ? (
          <TaskOutputPostForm
            translator={this.props.translator}
            editVarFun1={this.props.editVarFun1}
            taskCode={this.props.taskCode}
            loadTaskOutputVariables={this.props.loadTaskOutputVariables}
            formType={this.props.formType}
            updateOutPutVariable={this.props.updateOutPutVariable}
            initializeTaskOutputEditForm={
              this.props.initializeTaskOutputEditForm
            }
            taskDetails={this.props.taskDetails}
            role_id={this.props.role_id}
          />
        ) : (
          <div className="f-size-13">
            <Row className="labelInline rightSideFields">
              <Col md={2} sm={2} xs={2}>
                <Button
                  onClick={() => {
                    this.props.editVarFun1(true, this.props.translator["New"]);
                  }}
                  title={this.props.translator["New Variable"]}
                  className="rgSidrkBtn smallBtn"
                  bsSize="small"
                  bsStyle="info"
                  disabled={this.props.disableFieldsForGuestRole}
                >
                  <i className="fa fa-plus"></i>
                </Button>
              </Col>
            </Row>
            {outputs.length == 0 ? (
              <div>
                <h4>{this.props.translator["No output variables."]}</h4>
              </div>
            ) : (
              <Table
                bordered
                condensed
                hover
                responsive
                className="tableView sortImg"
              >
                <thead>
                  <tr>
                    <th
                      width="50%"
                      className={this.props.classNameVar1}
                      onClick={() => {
                        this.props.sortFun("class1");
                      }}
                    >
                      {this.props.translator["Output Variable"]}
                    </th>
                    <th
                      width="40%"
                      className={this.props.classNameVar2}
                      onClick={() => {
                        this.props.sortFun("class2");
                      }}
                    >
                      {this.props.translator["Value"]}
                    </th>
                    {this.props.disableFieldsForGuestRole ? '' : <th width="10%">{this.props.translator["Actions"]}</th>}
                  </tr>
                </thead>
                <tbody>{this.renderOutputVariables(outputs)}</tbody>
              </Table>
            )}
          </div>
        )}
      </div>
    );
  }
}
export default OutputVariables;
