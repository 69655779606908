
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';

//let company_id=userDetails.company_id;
import constants from '../../../src/utils/constants';
import { GLOBAL }  from '../../../src/components/Globals';

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

const hamburgerCategoryUrl=GLOBAL.getcategoryhomepage;


export function loadHamburgerCategoryListSuccess(hamburgerCategoryList){
	return {
		type: 'FETCH_HAMBURGERCATEGORYLIST',
		hamburgerCategoryList
	};
}

export function loadHamburgerCategoryList(){
	var str={};
	str.companyId="";
	str = JSON.stringify(str);
	return(dispatch) =>{
	//	dispatch({type: "LOADER_IN_PROGRESS"});
		api.get(hamburgerCategoryUrl,{headers:{'query':str}})
			.then((hamburgerCategoryList)=>{
				if(!hamburgerCategoryList){
					//dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
					throw Error(hamburgerCategoryList.statusText);
				}
				return hamburgerCategoryList;
			})
			.then((hamburgerCategoryList) =>{
				dispatch(loadHamburgerCategoryListSuccess(hamburgerCategoryList));
			//	dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
			})
			.catch((err) => {

				console.log(err);
			});
	};
}
