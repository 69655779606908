
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect } from "react";
import { Row, Col, Accordion, Tab, Tabs, ListGroup, Button, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { PiPencilSimpleBold } from "react-icons/pi";
import { HiPlus } from "react-icons/hi"
import { IoClose } from "react-icons/io5";
import { Link, NavLink } from "react-router-dom";
import ListLoader from "../../../common/loaders/ListLoader.js"
import { loadAllRosterList } from '_Actions/onCallSchedule/roster/onCallRosterAction';
import { useParams } from "react-router";
import Cookies from "universal-cookie";
import { openLinkInNewTab } from "../../../common/helper.js";

const cookies = new Cookies();
let app_url = cookies.get("gph");
if (app_url) app_url = app_url.replace("s:", "");
if (app_url) app_url = app_url.substring(0, app_url.lastIndexOf("."));
app_url = app_url.split("~");
app_url = app_url[18];

const ShiftEditRightSide = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const tr = useSelector((state) => state.spcmReducer.tr);
    const on_call_shift_loader_reducer = useSelector((state) => state.on_call_shift_loader_reducer);
    const allRosterList = useSelector((state) => state.on_call_roster_reducer.allRosterList);
    const showRosterListLoader = useSelector((state) => state.on_call_roster_loader_reducer.showRosterListLoader);
    const shiftDetailsByShiftId = useSelector((state) => state.on_call_shift_reducer.shiftDetailsByShiftId);
    
    useEffect(() => {
        dispatch(loadAllRosterList({}, true, 'Active', props.shiftId));
    }, []);

    function renderRosterList(allRosterList) {
        return allRosterList.map((data, index) => {
            return (
                <tr key={index}>
                    <td><Link title="Roster Name"
                        onClick={() => {
                            openLinkInNewTab(app_url + "/home?onCallScheduleEditRoster=" + data.rosterId, "New Tab")
                        }}
                    >{data.rosterName}</Link></td>
                    <td>{data.startDate}</td>
                    <td>{data.endDate}</td>
                    {/* <td>{data.shiftId}</td> */}
                </tr>
            );
        });
    }

    if (on_call_shift_loader_reducer.showShiftListLoader) {
        return <ListLoader />;
    }
    return (
        <div className="stickyArea rBoxStyle">
            <Row>
                <Col xs={12}>
                    <div className="rPageHeadActBtn" style={{ "marginTop": "-3px" }}>
                        <ListGroup bsPrefix=" " as="ul">
                            <Link to="/onCallSchedule/createRoster" state={{ createRosterFromShift: shiftDetailsByShiftId ? { shift: shiftDetailsByShiftId.shiftId, shiftName: shiftDetailsByShiftId.shiftName, group: shiftDetailsByShiftId.groupId, groupName: shiftDetailsByShiftId.groupName, companyId: shiftDetailsByShiftId.companyId, companyName: shiftDetailsByShiftId.companyName } : {} }} role="button" className="myBt plus fillBtn" title="Create" ><HiPlus /></Link>
                            {/* <ListGroup.Item as="li" bsPrefix=" "><Button bsPrefix=" " title={tr["Minimize the details panel"]} onClick={() => { props.showRightSideFun(false) }}><IoClose/></Button></ListGroup.Item> */}
                        </ListGroup>
                    </div>
                    <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{["Roster"]}</div></div>
                </Col>
            </Row>
            <div className="rBoxGap">
                <div className="f-size-12">
                    <Table bordered condensed hover responsive className="tableView  tableWithOutPointer">
                        <thead>
                            <tr>
                                <th width="30%" className="sort">{tr["Roster Name"]}</th>
                                <th width="20%" className="sort">{tr["From"]}</th>
                                <th width="20%" className="sort">{tr['To']}</th>
                                {/* <th width="20%" className="sort">{['Weightage']}</th>
                                    <th width="10%" className="text-c">{["Action"]}</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {showRosterListLoader ?
                                <tr>
                                    <td colSpan="12" style={{ "textAlign": "center" }}><ListLoader /></td>
                                </tr> :
                                allRosterList.length == 0 ?
                                    <tr>
                                        <td colSpan="12" style={{ "textAlign": "center" }}>{tr['No Data Found']}</td>
                                    </tr> :
                                    renderRosterList(allRosterList)
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    )
}

export default ShiftEditRightSide;