
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState} from "react";
import { Row, Col, Button } from 'react-bootstrap';
import {useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import {HiPlus, HiOutlineRefresh} from "react-icons/hi";
import { getTOTList, refreshDataDictionary  } from "../../../actions/spcmActions";
import { LuFilterX } from "react-icons/lu";
import { LuDatabaseBackup } from "react-icons/lu";
import Cookies from "universal-cookie";
const cookies = new Cookies();

let isSuperAdmin = cookies.get('gph');
if (isSuperAdmin) isSuperAdmin = isSuperAdmin.replace('s:', '');
if (isSuperAdmin) isSuperAdmin = isSuperAdmin.substring(0, isSuperAdmin.lastIndexOf('.'));
isSuperAdmin = isSuperAdmin.split("~");
isSuperAdmin = isSuperAdmin[60];

let isAdmin = cookies.get('gph');
if (isAdmin) isAdmin = isAdmin.replace('s:', '');
if (isAdmin) isAdmin = isAdmin.substring(0, isAdmin.lastIndexOf('.'));
isAdmin = isAdmin.split("~");
isAdmin = isAdmin[61];

const Header = (props) => {
    const tr = useSelector((state) => state.spcmReducer.tr);
    const dispatch = useDispatch();

    const refreshHandler = () => {
        dispatch(getTOTList(props.searchKey,props.searchValue,props.usedByRulesOpt,props.page,props.size,props.patternList));
        props.showRightSideFun(false);
        props.setPage(0);
        props.setLimit(10);
	}
    const clearAllFilters = () => {
        props.setTableNameSearchInput('');
        props.setTableLabelSearchInput('');
        props.setModulenameLabelSearchInput('');
        props.setLastModifiedBySearchInput('');
        props.setMicroserviceSearchInput('');
        props.setSelectedUsedbyRuleValue([{label: 'Yes', value: 'YES'}]);
        props.setModifiedStartAndEndDate('');
        props.setClearAllFilterToggle(false);
        dispatch(getTOTList());
        props.setsearchValue('');
        props.setsearchKey('');
        props.modifiedDateRef.startDate = moment();
        props.modifiedDateRef.endDate = moment();

	}

    const handleRefresh = () => {
        dispatch(refreshDataDictionary());
    };

    return (
    <Row className="margin-b-15">
        <Col lg={10} md={10} sm={10} xs={12}>
					<ul className="wibTabs">
						<li className="active"><Link title={"Table of Tables"} to="/tot">{"Table of Tables"}</Link></li>
                        <li><Link title={"Table of Attributes"} to="/toa">{"Table of Attributes"}</Link></li>


					</ul>
				</Col>
        <Col lg={2} md={2} sm={2} xs={12}>
            <div className="paHedFilter">
            {props.clearAllFilterToggle ? <div className="overlayDv"><Button title={tr["Clear All Filters"]} role="button" onClick={() => { clearAllFilters() }} bsPrefix=' ' className="myBt clrbtn"><LuFilterX /></Button></div> : ""}

                <Button bsPrefix=' ' title={tr["Refresh"]}  onClick={refreshHandler} role="button" className="myBt"><HiOutlineRefresh className="refreshFlip" /></Button>
                {isSuperAdmin === 'Y' && (
                    <Button title={tr["Data Refresh"]} onClick={handleRefresh} role="button" className="myBt">
                <LuDatabaseBackup />
                </Button>
                )}
            </div>
        </Col>
    </Row>
    )
}

export default Header;