
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Table, ButtonToolbar } from 'react-bootstrap';
import { Field, reduxForm, SubmissionError, change, formValueSelector, untouch, reset } from "redux-form";
import { _textArea, _dropDown, _inputField, _number, _dynamicNamedropDown } from "../../../../common/formFields.js";
import { useDispatch, useSelector, connect } from 'react-redux';
import { useNavigate } from "react-router";
import axios from 'axios';
import { PiPencilSimpleBold } from "react-icons/pi";
import { HiPlus } from "react-icons/hi"
import { IoClose } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import onCallDropdownPlusTypeahead from '../../../common/onCallDropdownPlusTypeahead';
import ListLoader from "../../../../common/loaders/ListLoader.js"
import { loadRelatedScheduleList, loadAllScheduleList, deleteRelatedScheduleData } from '_Actions/onCallSchedule/schedule/onCallScheduleAction';
import swal from 'sweetalert';
import {ImSpinner6} from "react-icons/im";

const RightSide = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const translator = useSelector((state) => state.spcmReducer.tr);
  const on_call_schedule_loader_reducer = useSelector((state) => state.on_call_schedule_loader_reducer);
  const on_call_schedule_association_reducer = useSelector((state) => state.on_call_schedule_association_reducer);
  const [saveLoader, setSaveLoader] = useState(false);
  const [scheduleNameErrorState, setScheduleNameErrorState] = useState('');
  const [scheduleNameSelectedList, setScheduleNameSelectedList] = useState([]);
  const [inputValueScheduleName, setInputValueScheduleName] = useState('');
  const allScheduleList = useSelector((state) => state.on_call_schedule_reducer.allScheduleList);
  let scheduleListTypeaheadOptions = [];
  if (allScheduleList && allScheduleList.length > 0) {
    allScheduleList.forEach((item, i) => {
      let obj = {};
      obj.id = item.scheduleId;
      obj.label = item.scheduleName;
      scheduleListTypeaheadOptions.push(obj);
    });
  }

  useEffect(() => {
    dispatch(loadRelatedScheduleList(props.scheduleId));
    dispatch(loadAllScheduleList({}, true,'Active'));
  }, []);

  function onScheduleNameSelection(selectedScheduleName) {
    setScheduleNameErrorState('');
    setScheduleNameSelectedList(selectedScheduleName);
    if (selectedScheduleName.length > 0) {
      dispatch(change('onCallRelatedScheduleForm', 'relatedScheduleName', selectedScheduleName[0].label));
      dispatch(change('onCallRelatedScheduleForm', 'relatedScheduleId', selectedScheduleName[0].id));
      setInputValueScheduleName(selectedScheduleName[0].label);
    }
    else {
      dispatch(change('onCallRelatedScheduleForm', 'relatedScheduleName', ''));
      dispatch(change('onCallRelatedScheduleForm', 'relatedScheduleId', ''));
    }
  }

  function setScheduleNameErrorColor() {
    if (scheduleNameSelectedList.length === 0)
      setScheduleNameErrorState('error');
    else
      setScheduleNameErrorState('');
  }

  function onScheduleNameInputChange(val) {
    setInputValueScheduleName(val);
  }

  function onCrossClickScheduleName() {
    dispatch(change('onCallRelatedScheduleForm', 'relatedScheduleName', ''));
    dispatch(change('onCallRelatedScheduleForm', 'relatedScheduleId', ''));
    setInputValueScheduleName('');
    setScheduleNameSelectedList([]);
    setScheduleNameErrorState('error');
  }

  function submit(values) {
    if (!values.relatedScheduleName) {
      setScheduleNameErrorState('error');
      return false;
    }
    let postObj = {
      "scheduleId": props.scheduleId,
      "relatedScheduleId": values.relatedScheduleId
    };
    setSaveLoader(true);
    axios.post('/rest/aiosem/saveRelateScheduleAction', postObj).then(function (response) {
      if (response.status == 200) {
        setSaveLoader(false);
        dispatch(loadRelatedScheduleList(props.scheduleId));
        dispatch(change('onCallRelatedScheduleForm', 'relatedScheduleName', ''));
        dispatch(change('onCallRelatedScheduleForm', 'relatedScheduleId', ''));
        setInputValueScheduleName('');
        setScheduleNameSelectedList([]);
      }
    }).catch(function (error) {
      setSaveLoader(false);
			swal({
				text: error.response.data.businessMessage,
				button: translator['OK'],
			});
    });
  }

  function onDeleteClick(asId) {
    dispatch(deleteRelatedScheduleData(asId, () => {
      dispatch(loadRelatedScheduleList(props.scheduleId));
    }))
  }

  if (on_call_schedule_loader_reducer.showScheduleListLoader) {
    return <ListLoader />;
  }
  else {
    return (
      <div className="stickyArea rBoxStyle">
        <Row>
          <Col xs={12}>
            <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{translator["Related Schedule"]}</div></div>
          </Col>
        </Row>
        <div className="rBoxGap">
          <Row>
            <Col md={12} sm={12} xs={12}>
              <Form.Group className="form-group">
                <Form.Label><span className="rStar"></span>{translator['Schedule']}</Form.Label>
                <Field component={onCallDropdownPlusTypeahead}
                  errorColor={scheduleNameErrorState}
                  onSelection={onScheduleNameSelection}
                  setErrorColor={setScheduleNameErrorColor}
                  name="relatedScheduleName"
                  id="relatedScheduleName"
                  className="form-control"
                  options={scheduleListTypeaheadOptions}
                  selectedOptions={scheduleNameSelectedList}
                  onInputChange={onScheduleNameInputChange}
                  inputValue={inputValueScheduleName}
                  onCrossClick={onCrossClickScheduleName}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ButtonToolbar className="black">
                <Button title="Save" bsSize="small" bsStyle="primary" disabled={saveLoader} onClick={props.handleSubmit(submit)} className="rgSidrkBtn smallBtn">
                  {saveLoader ? (
                    <ImSpinner6 className="icn-spinner"/>
                  ) : null}
                  {translator["Add"]}
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>
          <div className="f-size-12">
            <Table bordered condensed hover responsive className="tableView  tableWithOutPointer">
              <thead>
                <tr>
                  <th width="40%" className="sort">{translator["Schedule Name"]}</th>
                  <th width="40%" className="sort">{translator['Type']}</th>
                  <th width="20%" className="text-c">{translator["Action"]}</th>
                </tr>
              </thead>
              <tbody>
                {
                  on_call_schedule_loader_reducer.showScheduleAssociationLoader ?
                    <tr><td colSpan="3">
                      <ListLoader />
                    </td></tr>
                    :
                    on_call_schedule_association_reducer.relatedScheduleList.length > 0 ?
                      on_call_schedule_association_reducer.relatedScheduleList.map((value, index) => {
                        return (
                          <tr key={index}>
                            <td>{value.scheduleName}</td>
                            <td>{value.scheduleType}</td>
                            <td>
                              <div className="text-c"><i title={translator["Delete"]} className="margin-l-10 fa fa-trash-o cursorPoint" onClick={() => { onDeleteClick(value.asId) }}></i></div>
                            </td>
                          </tr>)
                      })
                      :
                      <tr><td colSpan={3} className="text-c">{translator['No Data Found']}</td></tr>
                }
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: "onCallRelatedScheduleForm",
  destroyOnUnmount: true,
  enableReinitialize: true
})(RightSide);
