
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import {connect, useDispatch, useSelector } from 'react-redux';
import { TypeaheadExampleSingleSelect, _inputField } from "../../../common/formFields";
import { Field, change, reduxForm } from "redux-form";
import { getCompanyList } from "../../../../actions/spcmActions";
import { useParams } from "react-router";
import { _dropDown } from '../../../common/ReduxFormFields/CategoryBoard/formFields';
import { loadCIType,loadCICompany } from '../../../../actions/cmdb/lovAction';
import { loadClassByCompanyId, resetCiClass } from '../../../../actions/ham/hamAction';


const CreateClassDefinitionForm = (props) => {

  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [typedCompany, setTypedCompany] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  
  const tr = useSelector((state) => state.spcmReducer.tr);
  const ciClass = useSelector((state) => state.compClass);
  const ciType = useSelector((state) => state.ciType);
  //const companyReducer = useSelector((state) => state.spcmReducer.companyList);
  const ciCompany = useSelector((state) => state.ciCompany);

  const params = useParams();
  const dispatch = useDispatch();
  
  useEffect(() => {
   dispatch(resetCiClass());
    dispatch(loadCICompany("0"));
    dispatch(loadCIType());
    

}, [])

const handleciClassChange = (event) => {
  //setState({ selectedClass: event.target.value });
  setSelectedClass(event.target.value);
  dispatch(change('CreateClassDefinitionForm', 'CLASS_NAME', event.target[event.target.selectedIndex].text))
    
}

console.log("ciClass==",ciClass);
console.log("selectedClass---",selectedClass);

useEffect(() =>
{
  if(ciCompany && ciCompany.length > 0)
  {
    setCompanyList(ciCompany)
  }
  
},[ciCompany])

const onCompanySelection = (selectedCompany) => {
  if (selectedCompany.length > 0) { 
    //props.setSelectedCompanyList(selectedCompany);
    // setCompanyId(selectedCompany[0].field1Value)
    // setCompanyName(selectedCompany[0].field1Key)
    setSelectedCompany(selectedCompany);
    console.log("selectedCompany==",selectedCompany);
    dispatch(change('CreateClassDefinitionForm', 'COMPANY_ID', selectedCompany[0].field1Value))
    dispatch(change('CreateClassDefinitionForm','company_name', selectedCompany[0].field1Key))
    dispatch(loadClassByCompanyId(selectedCompany[0].field1Value , true));
    props.setCompanyName(selectedCompany);
  } else {
    setSelectedCompany([]);
    dispatch(change('CreateClassDefinitionForm', 'COMPANY_ID', ""))
    dispatch(change('CreateClassDefinitionForm','company_name', ""))
    props.setCompanyName([]);
  }
};

const companyInputChange = (e) => {
  setTypedCompany(e);
  //props.setSelectedCompanyList([]);
  setSelectedCompany([]);
  props.setCompanyName([]);
};

const onCrossClickCompany = () => {
  companyInputChange("");
  dispatch(change('CreateClassDefinitionForm', 'COMPANY_ID', ''))
  dispatch(change('CreateClassDefinitionForm', 'company_name', ''))
  dispatch(change('CreateClassDefinitionForm', 'ClassName', ''))
  dispatch(resetCiClass());
  setSelectedCompany([]);
 
};


const  validateClassFields = (fieldType, event)=> {
  switch (fieldType) {
    case "ClassName":
      if (event.target.value.trim() == "") {
        props.setClassNameError("error");
      } else {
        props.setClassNameError("");
      }
      break;

    case "companyName":
      if (event.target.value.trim() == "") {
        props.setCompanyNameError("error");
      } else {
        props.setCompanyNameError("");
      }
      break;
    case "type":
      if (event.target.value.trim() == "") {
        props.setTypeError("error");
      } else {
        props.setTypeError("");
      }
      break;
    case "status":
      if (event.target.value == "") {
        props.setStatusErr("error");
      } else {
        props.setStatusErr("");
      }
      break;
    default:
  }
}
  return (
    <>
       <Form>
       <Row>
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
            <span className="rStar"></span>
              <Form.Label>{"Class Name"}</Form.Label>
              <Field
                name="ClassName"
                className={"form-control "+ (props.classNameError)}
                component={_inputField}
                maxLength={50}
                onBlur={(event) => {
                    validateClassFields("ClassName", event);
                }}
              />
            </Form.Group>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
            <span className="rStar"></span>
              <Form.Label>{"Type"}</Form.Label>
              <Field
                name="ciType"
                className={"form-control "+ (props.typeError)}
                component={_dropDown}
                options={ciType}
                onChange={(event) => dispatch(change("CreateClassDefinitionForm", "class_type", event.target[event.target.selectedIndex].text)) }
                onBlur={(event) => {
                  validateClassFields("type", event);
              }}
                
              />
            </Form.Group>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
            <span className="rStar"></span>
              <Form.Label>{"Company"}</Form.Label>

             <Field
                     name="company_name"
                     className={"form-control "+ (props.companyNameError)}
                    component={TypeaheadExampleSingleSelect}
                    onSelection={onCompanySelection}
                    options={companyList}
                    selectedOptions={selectedCompany}
                     onCrossClick={onCrossClickCompany}
                     onInputChange={companyInputChange}
                     labelKey={"field1Key"}
                     errorClass={props.companyNameError}
                     setErrorColor={props.setCompanyErrorColor}
                     diableStatus={false}
                     typedValue={typedCompany}
                />
            </Form.Group>
          </Col>
         
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              <Form.Label>{"Parent Class"}</Form.Label>
              <Field
                name="parent"
                className={"form-control"}
                component={_dropDown}
                options={ciClass}
                onChange={(event) => handleciClassChange(event)}

              />
            </Form.Group>
          </Col>
          <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                <span className="rStar"></span>
                <Form.Label>
                  {tr["Status"]}
                </Form.Label>
                <Field
                  name="status"
                  component="select"
                  className={"form-control " + props.statusErr}
                  onBlur={(event) => {
                    validateClassFields("status", event);
                }}
                >
                  <option value="">{tr["Select"]}</option>
                  <option value="active">{tr["Active"]}</option>
                  <option value="inactive">{tr["Inactive"]}</option>
                </Field>
              </Form.Group>
            </Col>

          </Row>
       </Form>
    </>
  )
}

export default reduxForm({
  form: 'CreateClassDefinitionForm',
  destroyOnUnmount: false,
  enableReinitialize: true
})(CreateClassDefinitionForm);