
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import PindCategories from "../../../common/header/pind-categories";
import Breadcrumbs from "../../../common/header/breadcrumbs";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import LanguageListHeader from "./LanguageListHeader";
import LanguageListView from "./LanguageListView";

const LanguageListIndex = () => {
  const [isRightSideVisible, setRightSideVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [rowIndex, setRowIndex] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [refreshPage, setRefreshPage] = useState(false);

  let colWidth = "";
  isRightSideVisible ? (colWidth = 8) : (colWidth = 12);

  const showRightSideFun = (val) => {
    setRightSideVisible(val);
  };

  const setSelectedItemFun = (data) => {
    setSelectedItem(data);
  };

  const showActiveRow = (i) => {
    setRowIndex(i);
  };

  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        {/* <div className="tabDatacount">1-1 of 1</div> */}
        <Breadcrumbs activePageName="Language" />
      </Container>

      <Container fluid>
        <LanguageListHeader
          showRightSideFun={showRightSideFun}
          setActivePage={setActivePage}
          setItemsPerPage={setItemsPerPage}
          refreshPage = {()=>{setRefreshPage(true)}}
        />
        <PanelGroup direction="horizontal" className="panelOverflow">
          <Panel
            id="sidebar"
            minSize={30}
            order={1}
            defaultSize={isRightSideVisible ? 67 : 100}
            className={
              isRightSideVisible ? "isShowLeftPanel catBordDv" : "catBordDv"
            }
          >
            <LanguageListView
              rightDisplay={isRightSideVisible}
              showRightSideFun={showRightSideFun}
              setSelectedItemFun={setSelectedItemFun}
              rowIndex={rowIndex}
              showActiveRow={showActiveRow}
              itemsPerPage={itemsPerPage}
              activePage={activePage}
              setItemsPerPage={setItemsPerPage}
              setActivePage={setActivePage}
              refreshPage={refreshPage}
              setRefreshPage={setRefreshPage}
            />
          </Panel>
        </PanelGroup>
      </Container>
    </main>
  );
};

export default LanguageListIndex;
