
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, change, SubmissionError } from 'redux-form';
import { _inputField, _dropDown } from '../../../common/ReduxFormFields/CategoryBoard/formFields';
// import { loadTagCompany, loadBusinessFunction, emptyBusinessFunction, loadSXMenulistData, loadAllCompositeTags, loadMemberTagsForCompositeTag } from '_Actions/foundation/tags/tagsAction';
import { _passwordField, _checkbox, _number, TypeaheadExampleSingleSelect } from '../../../common/formFields';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Link } from "react-router-dom";
import { loadAllScheduleList } from '../../../../actions/onCallSchedule/schedule/onCallScheduleAction';
import { navigationHooks } from "../../../../helpers/NavigationHook";
import Cookies from "universal-cookie";
import { openLinkInNewTab } from '../../../common/helper'

const cookies = new Cookies();
let app_url = cookies.get("gph");
if (app_url) app_url = app_url.replace("s:", "");
if (app_url) app_url = app_url.substring(0, app_url.lastIndexOf("."));
app_url = app_url.split("~");
app_url = app_url[18];

const validate = (values, props) => {
  const errors = {};

  if (!values.SHIFT_NAME)
    errors.SHIFT_NAME = "Required";
  else if (values.SHIFT_NAME.trim() === "")
    errors.SHIFT_NAME = 'Enter name';

  if (values.SUB_CATEGORY_NAME && values.SUB_CATEGORY_NAME.trim() === "")
    errors.SUB_CATEGORY_NAME = 'Enter SubCategoryName';

  if (!values.CATEGORY_ID)
    errors.CATEGORY_ID = "Required";

  return errors;
};
const CreateShiftForm = (props) => {
  const dispatch = useDispatch();
  const translator = useSelector((state) => state.spcmReducer.tr);
  const allScheduleList = useSelector((state) => state.on_call_schedule_reducer.allScheduleList);
  let group = useSelector((state) => state.selectedOnCallGroup);
  const [typedBusinessFn, setTypedBusinessFn] = useState('');
  const [typedSchedule, setTypedSchedule] = useState('');

  const selector = formValueSelector('CreateShiftForm');
  const scheduleIdParam = useSelector(state => selector(state, 'SCHEDULE_ID'));

  useEffect(() => {
    let filter = { scheduleType: "Workday" };
    dispatch(loadAllScheduleList(filter, true, 'Active'))
    return () => {
      // props.resetBusinessFunctionList();
    };
  }, []);

  useEffect(() => {
    if(group && Object.keys(group).length>0){
      props.setSelectedBusinessFn(group);
      dispatch(change("CreateShiftForm", "GROUP_ID", group[0].groupId));
      dispatch(change("CreateShiftForm", "GROUP_NAME", group[0].groupName));
      dispatch(change("CreateShiftForm", "COMPANY_ID", group[0].companyId));
      dispatch(change("CreateShiftForm", "COMPANY_NAME", group[0].companyName));
    }
  }, [group]);

  const onCrossClickBusinessFn = () => {
    setTypedBusinessFn('');
    dispatch(change("CreateShiftForm", "GROUP_ID", ''));
    dispatch(change("CreateShiftForm", "GROUP_NAME", ''));
    dispatch(change("CreateShiftForm", "COMPANY_ID", ''));
    dispatch(change("CreateShiftForm", "COMPANY_NAME", ''));
    props.setSelectedBusinessFn([]);
  };
  const onCrossClickScheduleFn = () => {
    setTypedSchedule('');
    dispatch(change("CreateShiftForm", "SCHEDULE_ID", ''));
    dispatch(change("CreateShiftForm", "SCHEDULE_NAME", ''));
    props.setSelectedSchedule([]);
  };

  const setBusinessFn = (selectedBusinessFn) => {
    if (selectedBusinessFn.length > 0) {
      props.setSelectedBusinessFn(selectedBusinessFn);
      dispatch(change("CreateShiftForm", "GROUP_ID", selectedBusinessFn[0].groupId));
      dispatch(change("CreateShiftForm", "GROUP_NAME", selectedBusinessFn[0].groupName));
      dispatch(change("CreateShiftForm", "COMPANY_ID", selectedBusinessFn[0].companyId));
      dispatch(change("CreateShiftForm", "COMPANY_NAME", selectedBusinessFn[0].companyName));
    } else {
      props.setSelectedBusinessFn([]);
      dispatch(change("CreateShiftForm", "GROUP_ID", ''));
      dispatch(change("CreateShiftForm", "GROUP_NAME", ''));
      dispatch(change("CreateShiftForm", "COMPANY_ID", ''));
      dispatch(change("CreateShiftForm", "COMPANY_NAME", ''));
    }
  };
  const setSchedule = (selectedSchedule) => {
    if (selectedSchedule.length > 0) {
      props.setSelectedSchedule(selectedSchedule);
      dispatch(change("CreateShiftForm", "SCHEDULE_ID", selectedSchedule[0].scheduleId));
      dispatch(change("CreateShiftForm", "SCHEDULE_NAME", selectedSchedule[0].scheduleName));
    } else {
      props.setSelectedSchedule([]);
      dispatch(change("CreateShiftForm", "SCHEDULE_ID", ''));
      dispatch(change("CreateShiftForm", "SCHEDULE_NAME", ''));
    }
  };

  const businessFnInputChange = (value) => {
    setTypedBusinessFn(value);
    props.setSelectedBusinessFn([]);
  }

  const scheduleInputChange = (value) => {
    setTypedSchedule(value);
    props.setSelectedSchedule([]);
  }

  const countCharacters = (e) => {
    if (e.target.value.length > 50) {
      e.preventDefault();
    }
  }

  return (
    <>
      <Row>
        <Col md={6} sm={6} xs={12}>
          <Form.Group className='form-group'>
            <Form.Label bsClass="">
              <span className="rStar"></span>
              {translator["Name"]}
            </Form.Label>
            <Field
              name="SHIFT_NAME" className="form-control" component={_inputField}
              onPaste={(e) => { countCharacters(e); }}
              onChange={(e) => { countCharacters(e); }}
            />
            <span className="f-size-10">{translator["Max Length in 50"]}</span>
          </Form.Group></Col>
        <Col md={6} sm={6} xs={12}>
          <Form.Group className="dvTypehdropdown-group">
            <Form.Label><span className="rStar"></span>{translator['Group']}</Form.Label>
            <div className="minHghtdvTypehd">
            <Field
              id="BusinessFnField"
              name="SHIFT_GROUP"
              component={TypeaheadExampleSingleSelect}
              className="form-control"
              options={props.onCallGroupList}
              selectedOptions={props.selectedBusinessFn}
              onSelection={setBusinessFn}
              errorClass={props.businessFnError}
              diableStatus={false}
              onInputChange={businessFnInputChange}
              onCrossClick={onCrossClickBusinessFn}
              typedValue={typedBusinessFn}
              setErrorColor={props.setBusinessFnErrorColor}
              labelKey={"groupName"}
              placeholder={translator["Please choose..."]}
            />
            </div>
          </Form.Group>
        </Col>
        <Col md={6} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label><span className="rStar"></span>{translator['Schedule']}</Form.Label>
            {scheduleIdParam ? <Link
              style={{ "margin-left": "5px" }}
              title={translator['Open record']}
              onClick={() => {
                openLinkInNewTab(app_url + "/home?onCallScheduleEditSchedule=" + scheduleIdParam, "New Tab")
              }}
            ><i className="fa fa-external-link" aria-hidden="true"></i></Link>
              : ''}
            {/* <div style={{ display: 'flex', alignItems: 'center' }}> */}
            <div className="heightdvTypehd">
            <Field
              id="Schedule"
              name="Schedule"
              component={TypeaheadExampleSingleSelect}
              className="form-control"
              options={allScheduleList}
              selectedOptions={props.selectedSchedule}
              onSelection={setSchedule}
              errorClass={props.scheduleError}
              diableStatus={false}
              onInputChange={scheduleInputChange}
              onCrossClick={onCrossClickScheduleFn}
              typedValue={typedSchedule}
              setErrorColor={props.setScheduleErrorColor}
              labelKey={"scheduleName"}
              placeholder={translator["Please choose..."]}
            />
            </div>
            <Link
              to="/onCallSchedule/createSchedule"
              style={{ marginLeft: '5px', fontSize: '12px' }}
            >
              {translator["Can't find? Create a new schedule"]}
            </Link>
            {/* </div> */}
          </Form.Group>
        </Col>

      </Row>
    </>
  )
}

const mapStateToProps = (state) => ({
  onCallGroupList: state.onCallGroupList,
  parentFunctionByCompanyIdDropdown: state.parentFunctionByCompanyIdDropdown
});

export default reduxForm({
  form: 'CreateShiftForm',
  destroyOnUnmount: true,
  validate,
  enableReinitialize: true
})(connect(mapStateToProps)(CreateShiftForm));
