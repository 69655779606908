
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { Row, Col } from "react-bootstrap";
import SimpleBar from "simplebar-react";

import NoDataAvailable from "../../../common/NoDataAvailable";

const MeetingEventList = ({
  allEventByDate = [],
  allEvenstData = [],
  setFiltersData,
  filtersData,
}) => {
  const handleEvents = (item) => {
    setFiltersData((prev) => {
      return {
        ...prev,
        ["occurrenceId"]: item?.occuranceId,
        ["definationId"]: item?.definationId,
        ["eventTitle"]: item?.title,
        ["userAuthorized"]: Boolean(item?.userAuthorized),
      };
    });
  };

  const handleSorting = () => {
    setFiltersData((prev) => {
      return {
        ...prev,
        sortedBy: filtersData?.sortedBy === "desc" ? "asc" : "desc",
      };
    });
  };


  return (
    <Row>
      <Col xs={12}>
        <div className="text-end px-2">
          <span className="recrring-icon">
            <span className="recrring-name">R</span> - Recurring
          </span>
        </div>
      </Col>
      <Col xs={12}>
        <div className="meeting-list">
          <div className="meeting-name">
            <Row className="align-items-center">
              <Col sm={9}>
                <h1>
                  Scheduled Meetings <span></span>
                </h1>
              </Col>
              <Col sm={3}>
                <div className="text-end custom-m">
                  <a className="btn icon-btn" onClick={handleSorting}>
                    <i
                      className={`fa fa-sort-amount-${filtersData.sortedBy}`}
                    ></i>
                  </a>
                </div>
              </Col>
            </Row>
          </div>
          <div className="meeting-details meeting-scroll">
            <SimpleBar
              style={{ height: "35vh" }}
              autoHide={true}
              className="simpl"
            >
              {allEventByDate && allEventByDate?.length === 0 ? (
                <div className="container-fluid">
                  <NoDataAvailable />
                </div>
              ) : (
                <ul>
                  {allEventByDate?.map((item, index) => {
                   
                    return (
                      <li
                        key={`key=${item?.occuranceId}`}
                        id={item?.occuranceId}
                        className={
                          (
                            filtersData?.occurrenceId
                              ? filtersData?.occurrenceId === item?.occuranceId
                              : allEventByDate[0]?.occuranceId ===
                                item?.occuranceId
                          )
                            ? "active"
                            : ""
                        }
                      >
                        <a onClick={() => handleEvents(item)}>
                          <div className="p2">
                            <i
                              className="fa fa-clock-o pe-1"
                              aria-hidden="true"
                            ></i>
                            {item.startDateTime} -
                            <i
                              className="fa fa-clock-o px-1"
                              aria-hidden="true"
                            ></i>
                            {item.endDateTime}
                          </div>
                          <div className="timings">
                            {item.title}
                            {item.recurrenceFlag && (
                              <sup>
                                <span className="recrring-name">R</span>
                              </sup>
                            )}
                          </div>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              )}
            </SimpleBar>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default MeetingEventList;
