
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import ListGroup from "react-bootstrap/ListGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import Table from "react-bootstrap/Table";
import {IoClose} from "react-icons/io5";
import {
  useSaveActionItemAttachmentMutation,
  useGetAttachmentQuery,
} from "../../../../../../reducers/governance/meeting/actionItem";

function Attachment({ meetingDefData = [] }) {
  const [saveAttachment] = useSaveActionItemAttachmentMutation();

  const { data: attachment } = useGetAttachmentQuery(
    meetingDefData?.[0]?.actionitemCode
  );

  const [files, setFiles] = useState([]);
  const maxSize = 2097152;
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length) {
      setFiles(acceptedFiles);
    }
  }, []);

  const {
    isDragActive,
    getRootProps,
    getInputProps,
    isDragReject,
    acceptedFiles,
    rejectedFiles,
  } = useDropzone({
    onDrop,
    accept: {
      //   "image/*": [".gif", ".jpeg", ".pjpeg", ".png", ".bmp", ".svg+xml"],
      "application/pdf": [".pdf"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/msword": [".doc"],
      "text/plain": [".txt"],
    },
    minSize: 0,
    maxSize,
  });
  const isFileTooLarge =
    rejectedFiles?.length > 0 && rejectedFiles?.[0].size > maxSize;

  const handleClick = () => {
    if (files?.length === 0) {
      Swal.fire("Please upload some files first");
    }
    if (isFileTooLarge) {
      Swal.fire(`Please upload File up to 2 MB size.`);
    }

    const data = {
      actionitemCode: meetingDefData[0]?.actionitemCode,
      fileName: files[0].name,
      mimeType: files[0].type,
      createdOn: undefined,
      createdBy: meetingDefData[0]?.createdBy,
      createdByName: meetingDefData[0]?.createdByName,
      modifiedOn: undefined,
      modifiedBy: meetingDefData[0]?.modifiedBy,
      modifiedByName: meetingDefData[0]?.modifiedByName,
      encodedString: "",
      status: "Y",
    };

    let formData = new FormData();
    formData.append("file", files[0]);
    formData.append("attachment", JSON.stringify(data));

    saveAttachment({
      actionCode: meetingDefData[0]?.actionitemCode,
      attachmentData: formData,
    });
  };
  const dropzoneStyle = {
    width: "100%",
    height: "60px",
    border: "1px solid #bdc4c9",
    paddingTop: 20,
    marginTop: 5,
    textAlign: "center",
  };

  return (
    <div className="panel-body attachment-body">
      <div className="custom-container-fluid">
        <div className="stickyArea rBoxStyle">
          <div className="rPageHeading d-flex p-3">
            <div className="flex-grow-1 offNam mw-100">Provide Attachments</div>
          </div>
          <div className="rBoxGap">
            <div className="p-3">
              <div>
                <div>
                  <b>Upload your file.</b> (max size 2 MB)
                </div>
                <div {...getRootProps()} style={dropzoneStyle}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Drop the files here ...</p>
                  ) : (
                    <p>
                      Drag 'n' drop some files here, or click to select files
                    </p>
                  )}
                </div>
                <div className="margin-t-20">
                  <div className="padding-b-5 margin-b-0 black f-size-16 fw-400">
                    {files.length > 0 ? "file attached" : "No file attached"}
                  </div>

                  {files.length > 0 && (
                    <ListGroup className="attachment-break">
                      <ListGroup.Item>
                        <span className="float-r"
                            onClick={() => {
                              setFiles([]);
                            }}
                          ><IoClose />
                        </span>
                        {files?.map((elm) => elm.name).toString()}
                      </ListGroup.Item>
                    </ListGroup>
                  )}
                </div>

                <ButtonToolbar className="black margin-t-5">
                  {/* <i className="f-size-13 fa fa-spinner fa-spin"></i> */}
                  <Button
                    className="rgSidrkBtn smallBtn"
                    bsSize="small"
                    bsStyle="primary"
                    onClick={() => handleClick()}
                  >
                    Attach
                  </Button>
                </ButtonToolbar>

                <div>
                  <div className="border-b padding-b-5 margin-b-0 black f-size-16 fw-400">
                    Attached Files
                  </div>
                  <Table
                    responsive
                    striped
                    bordered
                    condensed
                    hover
                    className="f-size-12 wrpAttTab"
                  >
                    <thead>
                      <tr>
                        <th width="60%">Name </th>
                        <th width="25%">Date & Time</th>
                        <th width="15%" className="text-c">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colspan="3" class="text-c">
                          No File Found
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Attachment;
