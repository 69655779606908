
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState, useRef,useContext} from "react";
import { useDispatch, useSelector, connect } from 'react-redux';

import SearchBoxMenu from './SearchBoxMenu';
import CategoryFilter from './CategoryFilter';
import Result from './Result';
import { MyContext } from '_MyContext';

import { loadDiscourseArticlesForGlobalSearch } from '_Actions/knowledge/knowledgeAction';
import { getGlobalSearchDataForRevamp, setGlobalSearchListSearchTextAndCategory } from '_Actions/globalSearch/globalSearchAction';
import { loadKnowledgeCategories } from '_Actions/knowledge/knowledgeAction';
import { debounce } from 'lodash';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

let isKnowledgeEnabled = cookies.get('gph');
if (isKnowledgeEnabled) isKnowledgeEnabled = isKnowledgeEnabled.replace('s:', '');
if (isKnowledgeEnabled) isKnowledgeEnabled = isKnowledgeEnabled.substring(0, isKnowledgeEnabled.lastIndexOf('.'));
isKnowledgeEnabled = isKnowledgeEnabled.split("~");
isKnowledgeEnabled = isKnowledgeEnabled[30];

let username = cookies.get('gph');
if (username) username = username.replace('s:', '');
if (username) username = username.substring(0, username.lastIndexOf('.'));
username = username.split("~");
username = username[32];

let cookiesArray = cookies.get('gph');
if (cookiesArray) cookiesArray = cookiesArray.replace('s:', '');
if (cookiesArray) cookiesArray = cookiesArray.substring(0, cookiesArray.lastIndexOf('.'));
cookiesArray = cookiesArray.split("~");
let isSuperAdmin = cookiesArray[60];

let SXSearchRedesign = (props) => {
  const dispatch = useDispatch();
  const globalSearchRef = useRef(null);
  const context = useContext(MyContext);
  const translator = useSelector((state) => state.spcmReducer.tr);
  const [searchOptions, setSearchOptions] = useState([ 'Service', 'Knowledge']);
  const [searchOn, setSearchOn] = useState(searchOptions[0]);
  const [searchText, setSearchText] = useState('');
  const [categoryId, setCategoryId] = useState(0);
  const [categoryName, setCategoryName] = useState(translator['All']);
  const [categorySlug, setCategorySlug] = useState('');
  const [isOpenResults, setIsOpenResults] = useState(false);
  const [lastSearchedOn, setLastSearchedOn] = useState('');
  const [lastSearchSelectedCategory, setLastSearchSelectedCategory] = useState(0);

  const handleSearchDebounce = useRef(debounce((searchText, searchOn, categoryId, categorySlug) => {
    if (searchOn === 'Service') {
      setLastSearchedOn('Service');
      setLastSearchSelectedCategory(categoryId);
      dispatch(setGlobalSearchListSearchTextAndCategory(searchText, categoryId));
      dispatch(getGlobalSearchDataForRevamp(searchText.trim(), categoryId, "1", "10"));
    } else if (searchOn === 'Knowledge' && (isKnowledgeEnabled == true || isKnowledgeEnabled == "true")) {
      setLastSearchedOn('Knowledge');
      setLastSearchSelectedCategory(categoryId);
      dispatch(loadDiscourseArticlesForGlobalSearch(searchText.trim(), username, categoryId, categorySlug));
    }
  }, 300)
  ).current;

  useEffect(() => {
    return () => {
      handleSearchDebounce.cancel();
    };
  }, [handleSearchDebounce]);

  const changeSearchOn = (option) => {
    setSearchOn(option);
    if(option == 'Knowledge') {
      dispatch(loadKnowledgeCategories(username));
    }
  }
  useEffect(() => {
    context.keyDownHandler("","",globalSearchRef.current);
  }, []);

  const changeSearchText = (searchText) => {
    setSearchText(searchText);
    if (searchText.length > 2) {
      handleSearchDebounce(searchText, searchOn, categoryId, categorySlug);
    }
  }

  const openResultsSection = (searchText) => {
    if (searchText.length > 2) {
      setIsOpenResults(true);
      if(lastSearchedOn != searchOn || lastSearchSelectedCategory != categoryId ) {
        changeSearchText(searchText);
      }
    }
  }

  return (
    <div className="position-re">
      {isSuperAdmin == 'Y' ? '' : <div className="gloSearch">
        <SearchBoxMenu
          isKnowledgeEnabled={isKnowledgeEnabled}
          searchOptions={searchOptions}
          searchOn={searchOn}
          changeSearchOn={changeSearchOn}
          setCategoryId={setCategoryId}
          setCategoryName={setCategoryName}
          setCategorySlug={setCategorySlug}
        />

        <div className="inpBox">
          <input type="text" placeholder={translator['What are you looking for...']}
            value={searchText}
             className="ctrlKeyPrevent"
            onFocus={(e) => openResultsSection(e.target.value)}
            onChange={(e) => changeSearchText(e.target.value)}
            ref={globalSearchRef}
          />
        </div>

        <CategoryFilter
          searchOn={searchOn}
          categoryId={categoryId}
          setCategoryId={setCategoryId}
          categoryName={categoryName}
          setCategoryName={setCategoryName}
          categorySlug={categorySlug}
          setCategorySlug={setCategorySlug}
        />

        <Result
          isKnowledgeEnabled={isKnowledgeEnabled}
          searchOn={searchOn}
          searchText={searchText}
          categoryId={categoryId}
          isOpen={isOpenResults}
          setIsOpen={setIsOpenResults}
          categorySlug={categorySlug}
        />
      </div >}
    </div>
  )
}

export default SXSearchRedesign;
