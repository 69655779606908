
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { Component } from 'react';
import axios from 'axios';
import { loadFulfillmentDetails } from '../../../actions/fulfillment/fulfillmentDataAction';
import { loadBreakFixAuditLogDetails } from '../../../actions/breakFix/breakFixAuditLogAction';
import { checkRelatedTaskForItem } from '../../../actions/task/TaskDataAction';
import { connect } from 'react-redux';
import ListLoader from "../../common/loaders/ListLoader";
import {GLOBAL} from '../../Globals';

class FulfillmentStatusTimeLine extends Component {

  constructor() {
    super();
    this.fullFillItem = this.fullFillItem.bind(this);
  }

  componentWillMount() {

    this.props.checkRelatedTaskForItem(this.props.itemId, "Fullfilment");
  }

  fullFillItem(itemId) {
    let self = this;
    axios.patch(GLOBAL.fulfillmentpost + itemId, {
      "itemStatus": "75",
      "approvalStatus": "75"
    })
      .then(function (response) {
        self.props.loadBreakFixAuditLogDetails(itemId, "dsc", "Fullfilment");
        self.props.loadFulfillmentDetails(itemId);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  putItemOnHold(itemId) {
    let self = this;
    axios.patch(GLOBAL.fulfillmentpost + itemId, {
      "itemStatus": "60",
    })
      .then(function (response) {
        self.props.loadBreakFixAuditLogDetails(itemId, "dsc", "Fullfilment");
        self.props.loadFulfillmentDetails(itemId);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  putUnderFulfillment(itemId) {
    let self = this;
    axios.patch(GLOBAL.fulfillmentpost + itemId, {
      "itemStatus": "70",
    })
      .then(function (response) {
        self.props.loadBreakFixAuditLogDetails(itemId, "dsc", "Fullfilment");
        self.props.loadFulfillmentDetails(itemId);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  render() {
   
    const fulfillmentDetails = this.props.fulfillmentDetails;
    const audit = this.props.breakFixAuditLogDetails;
	//alert(fulfillmentDetails)
	  //console.log(this.props.breakFixAuditLogDetails);
    if (!fulfillmentDetails) {
      return(<div><ListLoader myStyle={{marginTop:"20px"}} /></div>);
    } else {
      if (fulfillmentDetails.approvalRequired == 'yes' || fulfillmentDetails.approvalRequired == 'Y') {
        if(audit == undefined || audit.length==0) {
          return(<div><ListLoader myStyle={{marginTop:"20px"}} /></div>);
        }
        switch (fulfillmentDetails.itemStatus) {
          case '60': //on hold
            return (
              <div className="progressbarDiv">
                <ul>
                  <li className="mdisabled"><span className="box"></span><div className="txt"><div className="head"><span>1</span>Pending Approval</div><div className="time">{fulfillmentDetails.createdOn}</div><div className="name">{fulfillmentDetails.requesterName}</div></div></li>
                  <li className="mdisabled"><span className="box"></span><div className="txt"><div className="head"><span>2</span>Approved</div><div className="time">{audit[0].createdOn}</div><div className="name">{audit[0].createdByName}</div></div></li>
                  <li className="mActive"><span className="box"><i className="fa fa-map-marker"></i></span><div className="txt"><div className="head"><span>3</span>On Hold</div><div className="time"></div><div className="name"></div></div></li>
                {/*  <li className="notActive"> <a href="#" onClick={() => { GLOBAL.fulfillmentindividualval == ""  ? alert("please select group individual") : this.putUnderFulfillment(fulfillmentDetails.itemId); }}><span className="box" title='Put Under Fulfillment'></span><div className="txt"><div className="head"><span>4</span>Put Under Fulfillment</div></div></a></li>*/}
                <li className="notActive"> <a href="#" onClick={() =>  this.putUnderFulfillment(fulfillmentDetails.itemId)}><span className="box" title='Put Under Fulfillment'></span><div className="txt"><div className="head"><span>4</span>Put Under Fulfillment</div></div></a></li>
                </ul>
              </div>
            );
          case '65': //pending approval
            return (
              <div className="progressbarDiv">
                <ul>
                  <li className="mActive"><span className="box"><i className="fa fa-map-marker"></i></span><div className="txt"><div className="head"><span>1</span>Pending Approval</div><div className="time">{fulfillmentDetails.createdOn}</div><div className="name">{fulfillmentDetails.requesterName}</div></div></li>
                  <li className="notActive"><span className="box" title="Fulfill It"></span><div className="txt"><div className="head"><span>2</span>Approved</div></div></li>
                  <li className="notActive"><span className="box" title="Fulfill It"></span><div className="txt"><div className="head"><span>3</span>Rejected</div></div></li>
                </ul>
              </div>
            );
          case '70': //under fulfillment
            if (audit.length == 0) {
              return (  <div><ListLoader myStyle={{marginTop:"20px"}} /></div>)
            }
            return (
              <div className="progressbarDiv">
                <ul>
                  <li className="mdisabled"><span className="box"></span><div className="txt"><div className="head"><span>1</span>Pending Approval</div><div className="time">{fulfillmentDetails.createdOn}</div><div className="name">{fulfillmentDetails.requesterName}</div></div></li>
                  <li className="mdisabled"><span className="box"></span><div className="txt"><div className="head"><span>2</span>Approved</div><div className="time">{audit[0].createdOn}</div><div className="name">{audit[0].createdByName}</div></div></li>
                  <li className="mActive"><span className="box"><i className="fa fa-map-marker"></i></span><div className="txt"><div className="head"><span>3</span>Under Fulfillment</div><div className="time"></div><div className="name"></div></div></li>
                  {/*<li className="notActive"> <a href="#" onClick={() => { GLOBAL.fulfillmentindividualval == "" ? alert("please select group individual") : this.props.showPutOnHold(); }}><span className="box" title='Put On Hold'></span><div className="txt"><div className="head"><span>4</span>Put On Hold</div></div></a></li>*/}

                  <li className="notActive"> <a href="#" onClick={() => { this.props.showPutOnHold(); }}><span className="box" title='Put On Hold'></span><div className="txt"><div className="head"><span>4</span>Put On Hold</div></div></a></li>

                  {/*<li className="notActive"> <a href="#" onClick={() => { this.putItemOnHold(fulfillmentDetails.itemId); this.props.showPutOnHold(); }}><span className="box" title='Put On Hold'></span><div className="txt"><div className="head"><span>4</span>Put On Hold</div></div></a></li>*/}

                  <li className="notActive">{this.props.realatedTaskCount == 0 ? <a href="#" onClick={() => { GLOBAL.fulfillmentindividualval == "" ? alert("please select group individual") :  ( this.props.fulfillmentDetails.asgUserid != GLOBAL.fulfillmentindividualval ? alert("Please save the selected group individual first.") : this.fullFillItem(fulfillmentDetails.itemId)) }}><span className="box" title='Fulfill It'></span><div className="txt"><div className="head"><span>5</span>Fulfill It</div></div></a> : <div onClick={() => { GLOBAL.fulfillmentindividualval == ""  ? alert("please select group individual") :  ( this.props.fulfillmentDetails.asgUserid != GLOBAL.fulfillmentindividualval ? alert("Please save the selected group individual first.") :  alert('Tasks related to the item are not completed yet.')) }}><span className="box" title='Fulfill It'></span><div className="txt"><div className="head"><span>5</span>Fulfill It</div></div></div>}</li>
                </ul>
              </div>
            );
          case '75': //fulfilled
            if (audit.length < 2) {
              return (  <div><ListLoader myStyle={{marginTop:"20px"}} /></div>)
            }
            return (
              <div className="progressbarDiv">
                <ul>
                  <li className="mdisabled"><span className="box"></span><div className="txt"><div className="head"><span>1</span>Pending Approval</div><div className="time">{fulfillmentDetails.createdOn}</div><div className="name">{fulfillmentDetails.requesterName}</div></div></li>
                  <li className="mdisabled"><span className="box"></span><div className="txt"><div className="head"><span>2</span>Approved</div><div className="time">{audit[1].createdOn}</div><div className="name">{audit[1].createdByName}</div></div></li>
                  <li className="mdisabled"><span className="box"></span><div className="txt"><div className="head"><span>3</span>Under Fulfillment</div><div className="time">{audit[0].createdOn}</div><div className="name">{audit[0].createdByName}</div></div></li>
                  <li className="mActive"><span className="box"><i className="fa fa-map-marker"></i></span><div className="txt"><div className="head"><span>4</span>Fulfilled</div><div className="time"></div><div className="name"></div></div></li>
                </ul>
              </div>
            );
          case '95': //rejected
            if (audit.length == 0) {
              return (  <div><ListLoader myStyle={{marginTop:"20px"}} /></div>)
            }
            return (
              <div className="progressbarDiv">
                <ul>
                  <li className="mdisabled"><span className="box"></span><div className="txt"><div className="head"><span>1</span>Pending Approval</div><div className="time">{fulfillmentDetails.createdOn}</div><div className="name">{fulfillmentDetails.requesterName}</div></div></li>
                  <li className="mdisabled"><span className="box"></span><div className="txt"><div className="head"><span>2</span>Rejected</div><div className="time">{audit[0].createdOn}</div><div className="name">{audit[0].createdByName}</div></div></li>
                </ul>
              </div>
            );
            case '80': //Closed
            if (audit.length == 0) {
              return (  <div><ListLoader myStyle={{marginTop:"20px"}} /></div>)
            }
            return (
              <div className="progressbarDiv">
                <ul>
                  <li className="mdisabled"><span className="box"></span><div className="txt"><div className="head"><span>1</span>Pending Approval</div><div className="time">{fulfillmentDetails.createdOn}</div><div className="name">{fulfillmentDetails.requesterName}</div></div></li>
                  <li className="mdisabled"><span className="box"></span><div className="txt"><div className="head"><span>2</span>Under Fulfillment</div><div className="time">{audit[2].createdOn}</div><div className="name">{audit[2].createdByName}</div></div></li>
                  <li className="mdisabled"><span className="box"></span><div className="txt"><div className="head"><span>3</span>Fulfilled</div><div className="time">{audit[1].createdOn}</div><div className="name">{audit[1].createdByName}</div></div></li>
                  <li className="mActive"><span className="box"><i className="fa fa-map-marker"></i></span><div className="txt"><div className="head"><span>4</span>Closed</div><div className="time">{audit[0].createdOn}</div><div className="name">{audit[0].createdByName}</div></div></li>
                </ul>
              </div>
            );
        }
      } else {
        if ((fulfillmentDetails.approvalRequired == 'no' || fulfillmentDetails.approvalRequired == null) && audit.length != 0) { //under fulfillment
          if (fulfillmentDetails.itemStatus == '60') {
            return (
              <div className="progressbarDiv">
                <ul>
                  <li className="mActive"><span className="box"><i className="fa fa-map-marker"></i></span><div className="txt"><div className="head"><span>1</span>Under Fulfillment</div><div className="time">{fulfillmentDetails.createdOn}</div><div className="name">{fulfillmentDetails.requesterName}</div></div></li>
                  <li className="mActive"><span className="box"><i className="fa fa-map-marker"></i></span><div className="txt"><div className="head"><span>2</span>On Hold</div><div className="time">{audit[0].createdOn}</div><div className="name">{audit[0].createdByName}</div></div></li>
                  <li className="notActive"> <a href="#" onClick={() => this.putUnderFulfillment(fulfillmentDetails.itemId)}><span className="box" title='Put Under Fulfillment'></span><div className="txt"><div className="head"><span>3</span>Put Under Fulfillment</div></div></a></li>
                </ul>
              </div>
            )
          }
          if (fulfillmentDetails.itemStatus == '70') {
            return (
              <div className="progressbarDiv">
                <ul>
                  <li className="mActive"><span className="box"><i className="fa fa-map-marker"></i></span><div className="txt"><div className="head"><span>1</span>Under Fulfillment</div><div className="time">{fulfillmentDetails.createdOn}</div><div className="name">{fulfillmentDetails.requesterName}</div></div></li>
                  <li className="notActive"> <a href="#" onClick={() => { this.props.showPutOnHold();}}><span className="box" title='Put On Hold'></span><div className="txt"><div className="head"><span>2</span>Put On Hold</div></div></a></li>
                  <li className="notActive">{this.props.realatedTaskCount == 0 ? <a href="#" onClick={() => { GLOBAL.fulfillmentindividualval == ""  ? alert("please select group individual") :  ( this.props.fulfillmentDetails.asgUserid != GLOBAL.fulfillmentindividualval ? alert("Please save the selected group individual first.") :  this.fullFillItem(fulfillmentDetails.itemId)) }}><span className="box" title='Fulfill It'></span><div className="txt"><div className="head"><span>3</span>Fulfill It</div></div></a> : <div onClick={() => {GLOBAL.fulfillmentindividualval == ""  ? alert("please select group individual") : ( this.props.fulfillmentDetails.asgUserid != GLOBAL.fulfillmentindividualval ? alert("Please save the selected group individual first.") : alert('Tasks related to the item are not completed yet.')) }}><span className="box" title='Fulfill It' ></span><div className="txt"><div className="head"><span>3</span>Fulfill It</div></div></div>}</li>
                </ul>
              </div>
            )

          }
          if (fulfillmentDetails.itemStatus == '75') {
            return (
              <div className="progressbarDiv">
                <ul>
                  <li className="mdisabled"><span className="box"></span><div className="txt"><div className="head"><span>1</span>Under Fulfillment</div><div className="time">{fulfillmentDetails.createdOn}</div><div className="name">{fulfillmentDetails.requesterName}</div></div></li>
                  <li className="mActive"><span className="box"><i className="fa fa-map-marker"></i></span><div className="txt"><div className="head"><span>2</span>Fulfilled</div><div className="time">{audit[0].createdOn}</div><div className="name">{audit[0].createdByName}</div></div></li>
                </ul>
              </div>
            )

          }
          if (fulfillmentDetails.itemStatus == '80') {
            return (
              <div className="progressbarDiv">
                <ul>
                  <li className="mdisabled"><span className="box"></span><div className="txt"><div className="head"><span>1</span>Pending Approval</div><div className="time">{fulfillmentDetails.createdOn}</div><div className="name">{fulfillmentDetails.requesterName}</div></div></li>
                  <li className="mdisabled"><span className="box"></span><div className="txt"><div className="head"><span>2</span>Under Fulfillment</div><div className="time">{audit[2].createdOn}</div><div className="name">{audit[2].createdByName}</div></div></li>
                  <li className="mdisabled"><span className="box"></span><div className="txt"><div className="head"><span>3</span>Fulfilled</div><div className="time">{audit[1].createdOn}</div><div className="name">{audit[1].createdByName}</div></div></li>
                  <li className="mActive"><span className="box"><i className="fa fa-map-marker"></i></span><div className="txt"><div className="head"><span>4</span>Closed</div><div className="time">{audit[0].createdOn}</div><div className="name">{audit[0].createdByName}</div></div></li>
                </ul>
              </div>
            )

          }
          
        } else {
          if ((fulfillmentDetails.approvalRequired == 'no' || fulfillmentDetails.approvalRequired == null) && audit.length == 0) {
            return (
              <div className="progressbarDiv">
                <ul>
                  <li className="mActive"><span className="box"><i className="fa fa-map-marker"></i></span><div className="txt"><div className="head"><span>1</span>Under Fulfillment</div><div className="time">{fulfillmentDetails.createdOn}</div><div className="name">{fulfillmentDetails.requesterName}</div></div></li>
                  <li className="notActive"> <a href="#" onClick={() => { this.props.showPutOnHold(); }}><span className="box" title='Put On Hold'></span><div className="txt"><div className="head"><span>2</span>Put On Hold</div></div></a></li>
                  <li className="notActive">{this.props.realatedTaskCount == 0 ? <a href="#" onClick={() => {GLOBAL.fulfillmentindividualval == ""  ? alert("please select group individual") :  ( this.props.fulfillmentDetails.asgUserid != GLOBAL.fulfillmentindividualval ? alert("Please save the selected group individual first.") :  this.fullFillItem(fulfillmentDetails.itemId)) }}><span className="box" title='Fulfill It'></span><div className="txt"><div className="head"><span>3</span>Fulfill It</div></div></a> : <div onClick={() => {GLOBAL.fulfillmentindividualval == ""  ? alert("please select group individual") :  ( this.props.fulfillmentDetails.asgUserid != GLOBAL.fulfillmentindividualval ? alert("Please save the selected group individual first.") :  alert('Tasks related to the item are not completed yet.')) }}><span className="box" title='Fulfill It' ></span><div className="txt"><div className="head"><span>3</span>Fulfill It</div></div></div>}</li>
                </ul>
              </div>
            )
          }
        }
      }
    }
  }

}
const mapStateToProps = ({ fulfillmentDetails, breakFixAuditLogDetails, realatedTaskCount, breakFixSGIName }) => {
  return { fulfillmentDetails, breakFixAuditLogDetails, realatedTaskCount, breakFixSGIName };
}
export default connect(mapStateToProps, { loadFulfillmentDetails, checkRelatedTaskForItem, loadBreakFixAuditLogDetails })(FulfillmentStatusTimeLine);
