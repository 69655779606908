
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import {Col, Row, Accordion, Form } from 'react-bootstrap';
import { getOfferingsInCart } from "../../../actions/cart/addToCartActions";
import { connect } from "react-redux";
import Spinner from "react-spinkit";
import _ from "lodash";
import ListLoader from "../../common/loaders/ListLoader";
import dummyImage from "_Images/service-images/offering-dummy.png";
import PropTypes from 'prop-types';
import sanitizeHtml from 'sanitize-html';

class ShoppingCartList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey:0
    };
    this.addAndRemoveComponents = this.addAndRemoveComponents.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(activeKey) {
    this.setState({ activeKey });
  }

  addAndRemoveComponents(
    event,
    offeringId,
    componentId,
    actualPrice,
    componentType
  ) {
    if (event === "core_component") {
      this.props.updateCart(
        offeringId,
        componentId,
        actualPrice,
        componentType,
        "add"
      );
    } else {
      if (event.target.checked) {
        this.props.updateCart(
          offeringId,
          componentId,
          actualPrice,
          componentType,
          "add"
        );
      } else {
        this.props.updateCart(
          offeringId,
          componentId,
          actualPrice,
          componentType,
          "remove"
        );
      }
    }
  }

  renderCartList(cartOfferings, selectedComponentForOffering) {
    let corePackage;
    let optionalComponents;
    let delverityTypeRadios;
    if (cartOfferings.length === 0) {
      return (
        <div>
          <h2>loading cart...</h2>
          <Spinner spinnerName="three-bounce" />
        </div>
      );
    }
    return cartOfferings.map((offering,index) => {
      let displayText="";
      let displayStr="";
      displayStr = offering.offeringSummary != null && offering.offeringSummary != "" ? offering.offeringSummary : "";
      displayText = displayStr != "" ? displayStr.replace(/(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/ig, '').trim() : "";
      displayText = displayText != "" ? displayText.replace(/\s+/g, ' ') : "";
      displayText = displayText != "" ? displayText.replace(/&/g, "" ) : "";
      displayText = displayText != "" ? displayText.replace(/;/g, "" ) : "";

      let displayName="";
      let displayOfferingName="";
      displayOfferingName = offering.offeringName != null && offering.offeringName != "" ? offering.offeringName : "";
      displayName = displayOfferingName != "" ? displayOfferingName.replace(/(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/ig, '').trim() : "";
      displayName = displayName != "" ? displayName.replace(/\s+/g, ' ') : "";

      let getInnerWidth = (innerWidth)=>{
        if (innerWidth > 1535) return 100;
        if (innerWidth > 1367) return 82;
        if (innerWidth > 1279) return 85;
        if (innerWidth > 1023) return 65;
        if (innerWidth > 767) return 44;
        if (innerWidth > 640) return 60;
        if (innerWidth > 539) return 48;
        if (innerWidth > 411) return 65;
        if (innerWidth > 279) return 22;
        return 20;
      }

      // let namedSchema = undefined;
      // namedSchema = Joi.string().regex(/^[^}{]+$/);
      // let validator_to_add_myQbj = undefined;
      // let validate_summary = undefined;
      // if (offering.offeringSummary != null || offering.offeringSummary != "") {
      //   validator_to_add_myQbj = namedSchema.validate(offering.offeringSummary);
      //   if (validator_to_add_myQbj.error == null) {
      //     validate_summary = offering.offeringSummary;
      //   } else {
      //     validate_summary = "";
      //   }
      // } else validate_summary = "";
      // let displayStr = offering.offeringSummary;
      // let displayText = displayStr.replace(/(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/ig, '').trim();
      // displayText = displayText.replace(/\s+/g, ' ');
      let offeringCoreComponents = offering.componentapiList.filter(
        (component) => {
          return component.componentType === "Core";
        }
      );
      corePackage = offeringCoreComponents.map((coreOfferings) => {
        if (
          !_.some(selectedComponentForOffering, [
            "componentId",
            coreOfferings.id.toString(),
          ])
        ) {
          this.addAndRemoveComponents(
            "core_component",
            coreOfferings.offerId,
            coreOfferings.id.toString(),
            coreOfferings.actualPrice,
            coreOfferings.componentType
          );
        }
        return (
          <Row className="margin-t-5" key={coreOfferings.id}>
            <Col lg={6} md={6} sm={6} xs={6}>
              <div className="btnChkbox float-l hidden">
                <label>
                  <input
                    type="checkbox"
                    defaultChecked
                    disabled="disabled"
                    name="price"
                    value=""
                    id="price"
                  />
                </label>
              </div>
              <div className="ordCompoNam">
                {coreOfferings.componentName}
              </div>
            </Col>
              {offering.hidePrice == "true" ? null : <Col lg={3} md={3} sm={3} xs={3}><div className="ordCompoNam">{offering.billingMethod}</div></Col>}
              {offering.hidePrice == "true" ? null : <Col lg={3} md={3} sm={3} xs={3}><div className="ordCompoNam">{offering.currencyCode + " "}{Number(offering.actualPrice).toFixed(2)}</div></Col>}
          </Row>
        );
      });
      optionalComponents = offering.componentapiList.map(
        (optionalOfferings, index) => {
          if (index === 0) {
            if (
              !_.some(offering.componentapiList, ["componentType", "Optional"])
            ) {
              return (
                <div>
                  {this.props.translator["No optional component exists"]}
                </div>
              );
            }
          }
          if (optionalOfferings.componentType === "Optional") {
            return (
              <Row className="margin-t-5" key={optionalOfferings.id}>
                  {/* <Col lg={1} md={1} sm={1} xs={1} className=""></Col> */}
                  <Col lg={6} md={6} sm={6} xs={6}>
                  <div className="btnChkboxPos">
                      <label>
                        <input
                          type="checkbox"
                          defaultChecked={
                            _.some(selectedComponentForOffering, [
                              "componentId",
                              optionalOfferings.id.toString(),
                            ])
                              ? true
                              : false
                          }
                          name="price"
                          value={optionalOfferings.actualPrice}
                          id="price"
                          onClick={(event) => {
                            this.addAndRemoveComponents(
                              event,
                              offering.offeringId,
                              optionalOfferings.id,
                              optionalOfferings.actualPrice,
                              optionalOfferings.componentType
                            );
                          }}
                        />
                      </label>
                    </div>
                    <div className="ordCompoNamPos">{optionalOfferings.componentName}</div>
                    <div className="d-none padding-t-5 padding-b-10 padding-l-30">
                      <span className="gray">
                        {optionalOfferings.billing}:</span>
                      <span className="priceValue2 padding-l-5">
                        {optionalOfferings.currencyCode + " "}
                        {Number(optionalOfferings.actualPrice).toFixed(2)}
                      </span>
                    </div>
                  </Col>

                {offering.hidePrice === "true" ? null :
                  <Col lg={3} md={3} sm={3} xs={3} className=""><div className="ordCompoNam">{optionalOfferings.billing}</div></Col>}
                {offering.hidePrice === "true" ? null : <Col lg={3} md={3} sm={3} xs={3} className=""><div className="ordCompoNam">{optionalOfferings.currencyCode + " "}{Number(optionalOfferings.actualPrice).toFixed(2)}</div></Col>}
              </Row>
            );
          /* if (_.some(selectedComponentForOffering, ['componentId', optionalOfferings.id.toString()])) {
             return (
               <Row key={optionalOfferings.id}>
                 <Col md={6} sm={12} xs={12}>
                   <div className="btnChkbox float-l"><label><input type="checkbox" defaultChecked name="price"
                                                                    value={optionalOfferings.actualPrice} id="price"
                                                                    onClick={(event) => {
                                                                      this.addAndRemoveComponents(event, offering.offeringId, optionalOfferings.id, optionalOfferings.actualPrice);
                                                                    }}/><span></span></label></div>
                   <div className="padding-l-30 black">{optionalOfferings.componentName}</div>
                   <div className="hidden-md hidden-lg padding-t-5 padding-b-10 padding-l-30"><span
                     className="gray">{optionalOfferings.billing}:</span> <span
                     className="priceValue2 padding-l-5">{optionalOfferings.currencyCode + " "}{Number(optionalOfferings.actualPrice).toFixed(2)}</span>
                   </div>
                 </Col>
                 {offering.hidePrice === "true" ? null :
                   <Col md={3} sm={6} xs={6} className="hidden-xs hidden-sm">{optionalOfferings.billing}</Col>}
                 {offering.hidePrice === "true" ? null : <Col md={3} sm={6} xs={6}
                                                                                 className="hidden-xs hidden-sm text-r">{optionalOfferings.currencyCode + " "}{Number(optionalOfferings.actualPrice).toFixed(2)}</Col>}
               </Row>
             );

           } else {
             return (
               <Row key={optionalOfferings.id}>
                 <Col md={6} sm={12} xs={12}>
                   <div className="btnChkbox float-l"><label><input type="checkbox" name="price"
                                                                    value={optionalOfferings.actualPrice} id="price"
                                                                    onClick={(event) => {
                                                                      this.addAndRemoveComponents(event, offering.offeringId, optionalOfferings.id, optionalOfferings.actualPrice);
                                                                    }}/><span></span></label></div>
                   <div className="padding-l-30 black">{optionalOfferings.componentName}</div>
                   <div className="hidden-md hidden-lg padding-t-5 padding-b-10 padding-l-30"><span
                     className="gray">{optionalOfferings.billing}:</span> <span
                     className="priceValue2 padding-l-5">{optionalOfferings.currencyCode + " "}{Number(optionalOfferings.actualPrice).toFixed(2)}</span>
                   </div>
                 </Col>
                 {offering.hidePrice === "true" ? null :
                   <Col md={3} sm={6} xs={6} className="hidden-xs hidden-sm">{optionalOfferings.billing}</Col>}
                 {offering.hidePrice === "true" ? null : <Col md={3} sm={6} xs={6}
                                                                                className="hidden-xs hidden-sm text-r">{optionalOfferings.currencyCode + " "}{Number(optionalOfferings.actualPrice).toFixed(2)}</Col>}
               </Row>
             );
           }*/
          }
        }
      );
      delverityTypeRadios = offering.deliverytypeapiList.map(
        (dlvTypeOption, index) => {
          return (
            <Form.Check
              type="radio"
              key={index}
              inline
              value={dlvTypeOption.fulfillsla}
              name="opt"
            >
              {dlvTypeOption.fulfillsla}
            </Form.Check>
          );
        }
      );
      return (
        <Accordion.Item eventKey={index} key={index}>
          <Accordion.Header>
            <h4 toggle>
              <div className="addPenTit">
                <div className="imbx penTd">
                  <div className="addCatIm"><img
                    className="img-responsive"
                    src={
                      offering.offeringImageTempURL
                        ? offering.offeringImageTempURL
                        : dummyImage
                    }
                    title={offering.offeringName}
                  /></div>
                </div>
                <div className="txbx penTd">
                  <div title={offering.offeringName} className="theme1 f-size-17 lineH-22">
                    {displayName.slice(0, getInnerWidth(this.props.windowSize.width))}{displayName.length > getInnerWidth(this.props.windowSize.width) ? "..." : ''}
                  </div>
                  <div className="f-size-13 black1">
                  {displayText.slice(0, 105)}{displayText.length > 105 ? "..." : ''}</div>
                  {/* <div className="f-size-13" dangerouslySetInnerHTML={{ __html: offering.offeringSummary }}></div> */}
                </div>
              </div>
            </h4>
          </Accordion.Header>

          <Accordion.Body collapsible>
            <div className="addBodTxt">{this.props.translator['Package Contains']}</div>
            <div className="addBgRw margin-b-10">
              <Row>
                <Col lg={6} md={6} sm={6} xs={6}>{this.props.translator['Component']}</Col>
                <Col lg={3} md={3} sm={3} xs={3}>{this.props.translator['Billing Frequency']}</Col>
                <Col lg={3} md={3} sm={3} xs={3}>{this.props.translator['Price']}</Col>
              </Row>
              </div>
            <div className="display-block clearboth margin-b-20 padding-l-10 padding-r-10">{corePackage}</div>

            <div className="d-none padding-t-5 padding-l-30">
              <span className="gray">{offering.billingMethod}:</span>
              <span className="padding-l-5">{offering.currencyCode + " "}{Number(offering.actualPrice).toFixed(2)}</span>
            </div>

            <div className="addBodTxt">{this.props.translator['Optional Components']}</div>
            <div className="addBgRw margin-b-10">
              <Row>
                {/* <Col lg={1} md={1} sm={1} xs={1} className="">{this.props.translator['Add']}<div title="Select All" className="btnChkbox float-l"><label><input type="checkbox" name="price" value=""/></label></div></Col> */}
                <Col lg={6} md={6} sm={6} xs={6}>{this.props.translator['Component']}</Col>
                <Col lg={3} md={3} sm={3} xs={3}>{this.props.translator['Billing Frequency']}</Col>
                <Col lg={3} md={3} sm={3} xs={3}>{this.props.translator['Price']}</Col>
              </Row>
            </div>
            <div className="display-block clearboth padding-l-10 padding-r-10">{optionalComponents}</div>
          </Accordion.Body>
        </Accordion.Item>
      );
    });
  }

  render() {
    if (this.props.loaderStatus) {
      return <ListLoader />;
    }
    if (
      this.props.cartOfferings.length == 0 ||
      this.props.cartOfferings instanceof Error
    ) {
      //this.props.getOfferingsInCart("");
      this.props.changeCompMessage(
        this.props.translator[
          "Your cart is looking empty, shop with us to proceed further"
        ],
        "list"
      );
    }

    if (this.props.cartOfferings.errorCode) {
      return (
        <h2>
          {this.props.cartOfferings.errorMessage +
            " " +
            this.props.cartOfferings.errorCode}
        </h2>
      );
    }
    if (this.props.cartOfferings instanceof Error) {
      return this.props.cartListMessage == "" ? (
        <div>
          <h2>
            <div>
              <Spinner spinnerName="three-bounce" />
            </div>
            {this.props.translator['loading...']}
          </h2>
        </div>
      ) : (
        <div>
          <h2>{this.props.cartListMessage}</h2>
        </div>
      );
    }
    if (this.props.cartOfferings.length == 0) {
      return this.props.cartListMessage == "" ? (
        <div>
          <Spinner spinnerName="three-bounce" />
        </div>
      ) : (
        <div>
          <h2>{this.props.cartListMessage}</h2>
        </div>
      );
    }
    return (
      <div className="">
        <Accordion accordion className="brkFix brkFixOrder" id="addOrd" activeKey={this.state.activeKey} onSelect={this.handleSelect} >
        {this.renderCartList(
          this.props.cartOfferings,
          this.props.selectedComponentForOffering
        )}
        </Accordion>
      </div>
    );
  }
}

ShoppingCartList.propTypes = {
	translator: PropTypes.object,
	// selectedComponentForOffering: PropTypes.object,
	// cartOfferings: PropTypes.object,
  changeCompMessage: PropTypes.func,
  loaderStatus: PropTypes.bool,
  cartListMessage: PropTypes.string,
  updateCart: PropTypes.func
};

const mapStateToProps = ({windowSize}) => {
  return{
    windowSize
  };
};
export default connect(mapStateToProps, { getOfferingsInCart })(ShoppingCartList);
