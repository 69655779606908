
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
// import Header from "../../../common/header/Header";
import LeftSidebarMenulist from "../../common/left-sidebar-breakFixCreate";
import RightSidebarMenulist from "../../../common/RightSidebarMenuListDynamic.js";
import XsmSlaSupportHeader from "./header.js";
import XsmSLAHolidayCreateForm from "./form.js";
import { connect, useDispatch, useSelector } from "react-redux";
import XsmProcessHierarchy from "./processHierarchy.js";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../common/Breadcrumbs";
import "_Css/form/_form.scss";

const SlaHolidaySchCreateIndex = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [subMenu, setSubMenu] = useState("4");
  const [showHideAlfy, setShowHideAlfy] = useState(false);
  const [showHideRightNav, setShowHideRightNav] = useState(false);
  const [actionView, setActionView] = useState("assetgraph");
  const translator = useSelector((state) => state.spcmReducer.tr);

  const showHideAlfySet = (value) => {
    setShowHideAlfy(value);
  };
  const showHideRightNavSet = (value) => {
    setShowHideRightNav(!showHideRightNav);
  };
  const changeActionView = (actionSelected) => {
    setActionView(actionSelected);
  };

  const showRightPaneAction = (actionView) => {
    switch (actionView) {
      case "assetgraph":
        return (
          <XsmProcessHierarchy translator={translator} ciId={params.ciId} />
        );
    }
  };

  return (
    <main>
      <div >
        {/* App Header Section Start*/}
        {/* <Header rightNavValue={showHideRightNavSet} /> */}
        {/* App Header Section End*/}

        {/* App Right Side MenuBar Section Start*/}
        {/* <RightSidebarMenulist
          showhideClass={showHideRightNav}
          subMenuNumber={subMenu}
        /> */}
        {/* App Right Side MenuBar Section End*/}

        {/* Support App Left Side MenuBar Section Start*/}
        {/* <LeftSidebarMenulist translator={translator} /> */}
        {/* Support App Left Side MenuBar Section End*/}

        <div className="container-fluid padding-t-10">
          <Breadcrumbs translator={translator} />
          <Form>
            {/* Home Page blue bar Section Start*/}
            <XsmSlaSupportHeader
              translator={translator}
              changeActionView={changeActionView}
            />
            {/* Home Page blue bar Section end*/}

            <Row className="row-eq-height">
              <Col md={8} sm={12} xs={12}>
                {/* Xsm Break Fix Form tabs Section Start*/}
                <XsmSLAHolidayCreateForm translator={translator} />
              </Col>
              <Col md={4} sm={12} xs={12} className="colRightHeight">
                <div className="stickyArea">
                  {/* Xsm BreakFix Action Section Start*/}
                  {/*<XsmBreakFixAction />*/}
                  {showRightPaneAction(actionView)}
                  {/* Xsm BreakFix Action Section End*/}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                {/* Footer Section Start*/}
                {/*<FooterMain />*/}
                {/* Footer Section End*/}
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </main>
  );
};

export default SlaHolidaySchCreateIndex;

// const mapStateToProps = ({ spcmReducer }) => {
//   return {
//     translator: spcmReducer.tr,
//   };
// };
// export default connect(mapStateToProps, null)(XsmSLAHolidayCreate);
