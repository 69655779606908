import React, { useEffect } from "react";
import { Button, ButtonToolbar, Col, Row, Table } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import { FaPencil } from "react-icons/fa6";
import { HiPlus } from "react-icons/hi";
import Swal from "sweetalert2";

const UiBuilderRuleList = (props) => {
  const { list } = props;
  const renderRows = (list) => {
    return list.map((res, index)=>{
        return (
          <tr key={"rule-index"}>
            <td>{res?.name ? res.name : res.query?.id}</td>
            <td>
              <ButtonToolbar>
                <Button
                  bsPrefix=" "
                  className="formBuilBtn margin-r-5"
                  onClick={() => {
                    props?.editRule(index, res);
                  }}
                  title="Edit"
                >
                  <FaPencil />
                </Button>

                <Button
                  bsPrefix=" "
                  className="formBuilBtn"
                  title="Delete"
                  onClick={() => {
                    props?.deleteRule(index);
                  }}
                >
                  <FaTrash />
                </Button>
              </ButtonToolbar>
            </td>
          </tr>
        );
    })
  };
  return (
    <div className="">
      <Row className="">
        <Col lg={6} md={6} sm={6} xs={5}></Col>
        <Col lg={6} md={6} sm={6} xs={7}>
          <div className="paHedFilter">
            <Button
              bsPrefix=" "
              type="button"
              onClick={() => {
                props?.addNewRule();
              }}
              className="myBt btn-xs plus"
              title={"Add"}
            >
              <HiPlus />
            </Button>
          </div>
        </Col>
      </Row>
      <div className="mt-1">
        <div>
          {list && list.length > 0 ? (
            <Table
              responsive
              striped
              bordered
              condensed
              hover
              className="f-size-13"
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>{renderRows(list)}</tbody>
            </Table>
          ) : (
            <Table
              responsive
              striped
              bordered
              condensed
              hover
              className="f-size-13"
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={2} className="text-c">No Data Found</td>
                </tr>
              </tbody>
            </Table>
          )}
        </div>
      </div>
    </div>
  );
};

export default UiBuilderRuleList;
