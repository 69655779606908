
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/

/**
@Description: routes for
**/
export const CHAT_ACTION = "CHAT_ACTION";
export const POST_CHAT = "POST_CHAT";
export const APP_ACTION = "APP_ACTION";
export const TOGGLE_MIC = "TOGGLE_MIC";
export const SIMULATE_USER_MSG = "SIMULATE_USER_MSG";
export const RESET_QUICK_ACTION_VIEW = "RESET_QUICK_ACTION_VIEW";
export const LOAD_TOKEN = "LOAD_TOKEN";
export const SHOW_APP_HELP = "SHOW_APP_HELP";
export const COMPANY = "COMPANY";
export const PORTFOLIO_GROUP = "PORTFOLIO_GROUP";
export const PORTFOLIO_MANAGER = "PORTFOLIO_MANAGER";
export const CATALOGUE_GROUP = "CATALOGUE_GROUP";
export const CATALOGUE_MANAGER = "CATALOGUE_MANAGER";
export const SERVICE_DETAILS = "SERVICE_DETAILS";
export const ALL_SERVICES_LIST = "ALL_SERVICES_LIST";
export const CURRENTLY_SELECTED_SERVICE = "CURRENTLY_SELECTED_SERVICE";
export const CRITICALITY_LIST = "CRITICALITY_LIST";
export const CATEGORY_LIST = "CATEGORY_LIST";
export const EDIT_LEFT_TAB_SERVICE = "EDIT_LEFT_TAB_SERVICE";
export const EDIT_SERVICE = "EDIT_SERVICE";
export const CLEAR_EDIT_SERVICE = "CLEAR_EDIT_SERVICE";
export const CONSUMPTION_ITEM = "CONSUMPTION_ITEM";
export const SERVICE_QUESTIONS_LIST = "SERVICE_QUESTIONS_LIST";
export const ENTITLEMENTS = "ENTITLEMENTS";
export const SERVICE_ORDER_CHOICES = "SERVICE_ORDER_CHOICES";
export const ATTRIBUTES = "ATTRIBUTES";
export const SERVICE_BILLING = "SERVICE_BILLING";
export const SERVICE_KNOWLEDGE = "SERVICE_KNOWLEDGE";
export const CHAIN_ENTITIES = "CHAIN_ENTITIES";
export const SERVICE_LEVELS = "SERVICE_LEVELS";
export const SELF_SERVICES = "SELF_SERVICES";
export const EDIT_ID = "EDIT_ID";
export const CURRENT_FORM = "CURRENT_FORM";
export const GROUPS = "GROUPS";
export const MANAGERS = "MANAGERS";
export const ENTITLEMENT_ENTITIES = "ENTITLEMENT_ENTITIES";
export const SERVICE_DETAILS_COUNTS = "SERVICE_DETAILS_COUNTS";
export const AUDIT = "AUDIT";
export const REVIEW = "REVIEW";
export const SLA_FILTER = "SLA_FILTER";
export const LEFT_EDIT_ID = "LEFT_EDIT_ID";
export const APPROVER_TYPE = "APPROVER_TYPE";
export const APPROVAL_MODULE = "APPROVAL_MODULE";
export const APPROVER_GROUP = "APPROVER_GROUP";
export const APPROVER_INDIVIDUAL = "APPROVER_INDIVIDUAL";
export const APPROVER_GROUP_OR_INDIVIDUAL = "APPROVER_GROUP_OR_INDIVIDUAL";
export const APPROVER_LEVEL = "APPROVER_LEVEL";
export const SLA_MODULE = "SLA_MODULE";
export const SLA_GOAL_TYPE = "SLA_GOAL_TYPE";
export const APPROVAL = "APPROVAL";
export const SLA_WORKDAY_SCHEDULE = "SLA_WORKDAY_SCHEDULE";
export const CHAIN_ENTITIES_COMPANY = "CHAIN_ENTITIES_COMPANY";
export const TRANSLATION = "TRANSLATION";
export const SERVICE_FULFILMENT_PLANS = "SERVICE_FULFILMENT_PLANS";
export const CI_TASK_PLANS = "CI_TASK_PLANS";
export const CI_CHAIN_ENTITIES = "CI_CHAIN_ENTITIES";
export const SERVICE_FULFILMENT_LOV = "SERVICE_FULFILMENT_LOV";
export const SERVICE_SUPPORT_COMPANY_GROUPS_LIST =
  "SERVICE_SUPPORT_COMPANY_GROUPS_LIST";
export const SELECTED_TASK_TEMPLATE = "SELECTED_TASK_TEMPLATE";
export const EDIT_FULLFILMENT_LEFT_TAB_SERVICE =
  "EDIT_FULLFILMENT_LEFT_TAB_SERVICE";
export const SPCM_POSTING = "SPCM_POSTING";
export const SPCM_FETCHING = "SPCM_FETCHING";
export const QUESTIONS_CATEGORY = "QUESTIONS_CATEGORY";
export const EDIT_QUESTIONS_CATEGORY = "EDIT_QUESTIONS_CATEGORY";
export const UPDATE_PRICE = "UPDATE_PRICE";
export const UPDATE_SLA = "UPDATE_SLA";
export const QUEST_ACT_CAT_LIST = "QUEST_ACT_CAT_LIST";
export const GET_COMPONENT_NAME = "GET_COMPONENT_NAME";
export const GET_REQUIRED_SLA_STATUS = "GET_REQUIRED_SLA_STATUS";
export const DATA_SOURCE_TYPES = "DATA_SOURCE_TYPES";
export const DATA_SOURCE_RESPONSES = "DATA_SOURCE_RESPONSES";
export const ORDERING_PREFERENCES = "ORDERING_PREFERENCES";
export const INTEGRATION_PREFERENCES = "INTEGRATION_PREFERENCES";
export const SERVICE_TYPES = "SERVICE_TYPES";
export const SPCM_DISCLAIMERS = "SPCM_DISCLAIMERS";
export const CMDB_DISCLAIMERS = "CMDB_DISCLAIMERS";
export const INTEGRATION_DATA_KEYWORDS = "INTEGRATION_DATA_KEYWORDS";
export const ADDITIONAL_INTEGRATION_DATA = "ADDITIONAL_INTEGRATION_DATA";
export const APPROVAL_QUESTIONS = "APPROVAL_QUESTIONS";
export const ALL_COMPANY_LIST = "ALL_COMPANY_LIST";
export const SERVICES_LIST_FOR_SEARCH = "SERVICES_LIST_FOR_SEARCH";
export const APPROVER_CRITERIA = "APPROVER_CRITERIA";
export const APPROVER_CRITERIA_OPERATOR = "APPROVER_CRITERIA_OPERATOR";
export const APPROVER_SCENARIO = "APPROVER_SCENARIO";
export const APPROVER_ENTITY = "APPROVER_ENTITY";
export const APPROVER_LOB = "APPROVER_LOB";
export const APPROVER_ORDER_TYPE = "APPROVER_ORDER_TYPE";
export const APPROVER_COMPANY_CODE = "APPROVER_COMPANY_CODE";
export const RELATED_OFFERINGS = "RELATED_OFFERINGS";
export const ALL_DEPLOYED_SERVICES_LIST = "ALL_DEPLOYED_SERVICES_LIST";
export const COPY_OFFERING_FROM_SERVICE_LIST =
  "COPY_OFFERING_FROM_SERVICE_LIST";
export const RESET_REDUCER = "RESET_REDUCER";
export const SERVICE_SEQUENCING_LIST = "SERVICE_SEQUENCING_LIST";
export const SERVICE_SEQUENCING_LIST_ON_ADD = "SERVICE_SEQUENCING_LIST_ON_ADD";
export const ATTRIBUTES_TRANSLATION_STATUS = "ATTRIBUTES_TRANSLATION_STATUS";
export const LANGUAGE_LIST_TRANSLATION_STATUS =
  "LANGUAGE_LIST_TRANSLATION_STATUS";
export const TRANS_NAME_DESC_SUMMARY = "TRANS_NAME_DESC_SUMMARY";
export const TRANS_CAT_SUBCAT = "TRANS_CAT_SUBCAT";
export const NAME_DESC_SUM_TRANSLATION = "NAME_DESC_SUM_TRANSLATION";
export const GET_LIST_FOR_TRANSLATION = "GET_LIST_FOR_TRANSLATION";
export const OBJECT_FOR_TRANSLATION = "OBJECT_FOR_TRANSLATION";
export const TRANS_ATTR_NAME = "TRANS_ATTR_NAME";
export const TRANS_ATTR_VALUE = "TRANS_ATTR_VALUE";
export const TRANS_COMP_DESC = "TRANS_COMP_DESC";
export const SPCM_REMINDER_NOTIFICATION_QUESTIONS =
  "SPCM_REMINDER_NOTIFICATION_QUESTIONS";
export const SPCM_REMINDER_NOTIFICATIONS = "SPCM_REMINDER_NOTIFICATIONS";
export const TRANS_CATEGORY = "TRANS_CATEGORY";
export const TRANS_SUBCATEGORY = "TRANS_SUBCATEGORY";
export const TRANS_QUES_CAT = "TRANS_QUES_CAT";
export const TRANS_QUES_TEXT = "TRANS_QUES_TEXT";
export const TRANS_QUES_PLACEHOLDER = "TRANS_QUES_PLACEHOLDER";
export const TRANS_QUES_HELPTEXT = "TRANS_QUES_HELPTEXT";
export const TRANS_DISPLAY_TEXT = "TRANS_DISPLAY_TEXT";
export const TRANS_RESPONSE_VALUES = "TRANS_RESPONSE_VALUES";
export const APPROVER_LIST_DATA_WITH_ORGANIZATION =
  "APPROVER_LIST_DATA_WITH_ORGANIZATION";
export const APPROVER_LIST_DATA_WITH_DEPARTMENT =
  "APPROVER_LIST_DATA_WITH_DEPARTMENT";
export const APPROVER_LIST_DATA_WITH_USER_TYPE =
  "APPROVER_LIST_DATA_WITH_USER_TYPE";
export const APPROVER_LIST_DATA_WITH_COST_CENTRE =
  "APPROVER_LIST_DATA_WITH_COST_CENTRE";
export const APPROVER_LIST_DATA_WITH_LOCATION =
  "APPROVER_LIST_DATA_WITH_LOCATION";
export const SERVICE_AUDIT_LOGS = "SERVICE_AUDIT_LOGS";

//============congig relationship======================

export const SERVICE_LIST_COMPANY = "SERVICE_LIST_COMPANY";
export const SERVICE_LIST_COMPANY_FOR_SEARCH =
  "SERVICE_LIST_COMPANY_FOR_SEARCH";
export const REMINDER = "REMINDER";
export const SLA_DEFINITION = "SLA_DEFINITION";
export const SHARED_OFFERINGS = "SHARED_OFFERINGS"; 
export const NE_COMPANY="NE_COMPANY"; 
export const CATEGORY_LIST_COPY="CATEGORY_LIST_COPY"
export const COMPANY_COPY="COMPANY_COPY"
export const COPY_OFFERING_FROM_SERVICE_LIST_COPY="COPY_OFFERING_FROM_SERVICE_LIST_COPY";
export const LOGO_NE="LOGO_NE";  
export const SERVICE_PROVIDER_COMPANY_GROUPS_LIST="SERVICE_PROVIDER_COMPANY_GROUPS_LIST";
export const CIBASE_DATA_FOR_STANDARD_CHANGE = "CIBASE_DATA_FOR_STANDARD_CHANGE";
export const NOTIFICATION_DATA = "NOTIFICATION_DATA";
export const EDITFORM_NE_DETAILS = "EDITFORM_NE_DETAILS";
export const EDITFORM_TEMPLATE_NE = "EDITFORM_TEMPLATE_NE";
export const SERVICELIST_DATA = "SERVICELIST_DATA";
export const SERVICE_CI = "SERVICE_CI";
export const GET_CONTEXTUAL_RULES = "GET_CONTEXTUAL_RULES";
export const GET_TASK_CONTEXTUAL_RULES = "GET_TASK_CONTEXTUAL_RULES";
export const ENTITLED_OFFERINGS_FETCHED_SUCCESSFULLY = "ENTITLED_OFFERINGS_FETCHED_SUCCESSFULLY";
export const COMPANY_USERS_LIST_FETCHED_SUCCESSFULLY = "COMPANY_USERS_LIST_FETCHED_SUCCESSFULLY";
export const ATTACHMENTS_SB = "ATTACHMENTS_SB";
export const ATTACHMENTS_PAGINATION = "ATTACHMENTS_PAGINATION";
export const TEMPLATE_VARIABLES = "TEMPLATE_VARIABLES";
export const SERVICE_OWNER_GROUP = "SERVICE_OWNER_GROUP";
export const NE_COMPANY_MAILBOX="NE_COMPANY_MAILBOX"; 
export const MY_VIEW_NOTIFICATION="MY_VIEW_NOTIFICATION"; 
export const TOA="TOA"; 
export const TOT="TOT"; 
export const TOA_EDIT="TOA_EDIT"; 
export const TOT_EDIT="TOT_EDIT"; 