
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import { GLOBAL } from '../../components/Globals';

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

export const getPriorityUrgencyCriticalityValues = (moduleName, fieldName) => {

    const response = axios.get(GLOBAL.priorityCriticalityUrgencyUrl + "/module/" + moduleName + "/field/" + fieldName);
    return ((dispatch) => {
        response.then((dropDownValues) => {
            if (!dropDownValues || dropDownValues.status !== 200) {
                throw Error(dropDownValues);
            }
            return dropDownValues;
        }).then((dropDownValues) => {
            switch (moduleName) {
                case 'Investigation':
                    switch (fieldName) {
                        case 'Criticality':
                            dispatch(investigationCriticalityDropDown(dropDownValues));
                            break;
                        case 'Urgency':
                            dispatch(investigationUrgencyDropDown(dropDownValues));
                            break;
                        case 'Priority':
                            dispatch(investigationPriorityDropDown(dropDownValues));
                            break;
                        case 'source':
                            dispatch(investigationSourceDropDown(dropDownValues));
                            break;
                    }
                    break;
                case 'BreakFix':
                    console.log('put entries for breakfix dropdowns');
                    break;
            }
        }).catch((err) => {
            console.log(err);
        });
    });
};

export const loadTimeZone = () => {
    const response = axios.get(GLOBAL.timeZoneURL);
    return ((dispatch) => {
        response.then((timeZoneData) => {
            if(timeZoneData.status != 200 || ! timeZoneData) {
                console.log('error loading timezonedata');
            }else {
                dispatch({
                    type:'TIMEZONE_LOAD_SUCCESS',
                    timeZone:timeZoneData.data
                });
            }
        });
    })
}
const investigationCriticalityDropDown = (investigationCriticality) => {
    return {
        type: 'INVESTIGATION_CRITICALITY_LOAD_SUCCESS',
        investigationCriticality
    };
};
const investigationSourceDropDown=(investigationSource)=>{
    return{
        type: 'INVESTIGATION_SOURCE_LOAD_SUCCESS',
        investigationSource 
    }
}
const investigationUrgencyDropDown = (investigationUrgency) => {
    return {
        type: 'INVESTIGATION_URGENCY_LOAD_SUCCESS',
        investigationUrgency
    };
};

const investigationPriorityDropDown = (investigationPriority) => {
    return {
        type: 'INVESTIGATION_PRIORITY_LOAD_SUCCESS',
        investigationPriority
    };
};


// -------------------------- timeline related actions ------------------------------------

export function loadTimelineDataSuccess(timelineDataResult){
    // console.log('timelineDataResult - ',timelineDataResult);
    return {
        type: 'LOAD_TIMELINEDATA_SUCCESS',
        timelineData: timelineDataResult
    };
}

export function loadTimelineDataFailure(errorResponse){
    return {
        type: 'LOAD_TIMELINEDATA_FAILURE',
        errorResponse
    };
}
export function loadTimelineData(moduleName,itemId){
    //alert('action called and parameter is '+breakFixId)

    // console.log('------------',moduleName,itemId)

    return(dispatch) =>{
        //alert("EditGroupIdAction123"+breakFixId);
        
            dispatch({type:'FETCHING_AUDIT_FOR_TIMELINE'});
                
                api.get(GLOBAL.genericTimelineDataUrl+"?module="+moduleName+"&itemId="+itemId+"&orderBy=createdOn&sortBy=desc")
                    .then((responseData)=>{
                        if(!responseData){
                            //dispatch(loadBreakFixFailure(breakFix.statusText))
                            throw Error(responseData.statusText);
                        }
                        return responseData;
                    })
                    .then((responseData) =>{
                    dispatch({type:'FETCHED_AUDIT_FOR_TIMELINE'});
                        dispatch(loadTimelineDataSuccess(responseData))
                    })
                    .catch((err) => {
                        console.log(err);
                    });
    };
}

// -------------------------- timeline related actions - end ------------------------------------

