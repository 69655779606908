
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import Cookies from "universal-cookie";
const cookies = new Cookies();
const gph = cookies.get("gph").split("~");
export const username = typeof gph[32] !== "undefined" ? gph[32] : "";
export const userId = typeof gph[43] !== "undefined" ? gph[43] : "";
const dateformat = gph[2];
export const jwtDateFormat = dateformat === "dd-MM-yyyy HH:mm:ss"
    ? "DD-MM-YYYY HH:mm:ss"
    : dateformat === "yyyy-MM-dd HH:mm:ss" ? "YYYY-MM-DD HH:mm:ss" : "MM-DD-YYYY HH:mm:ss";


const systemVariable = () => {
    return {
        '#SC-USER-ID' : userId,
        '#SC-USER-NAME': username,
        '#SC-USER-TOKEN': '*****************'
    }
}


export const getDefinitionValue = (item) => {
    if(item.type === 'system'){
        let variable = systemVariable();
        return variable.hasOwnProperty(item.key) ? variable[item.key] : null
    }
    let def = JSON.parse(item.definition);
    if(def.type === 'variable') return def.value;
    return null;
}