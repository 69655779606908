/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Tabs, Tab, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import { getCookiesValuesByPositions } from "../helper";
import moment from "moment";
import ListLoader from "../loaders/ListLoader";
import { useGetNotifPopupListQuery } from "../../../reducers/NotificationApi/NotificationsApi";

const MyPopup = (props) => {
  const [tab, setTab] = useState("direct");
  const [list, setList] = useState(
    new Map([
      ["today", []],
      ["yesterday", []],
      ["older", []],
    ])
  );

  const tr = useSelector((state) => state.spcmReducer.tr);
  const scrollheight = 400;
  const { value1: loggedInEmail } = getCookiesValuesByPositions([29]);
  const {
    data: notificationList,
    isFetching: listLoading,
    refetch,
  } = useGetNotifPopupListQuery({
    email: loggedInEmail,
    type: tab,
  });

  const isSameDay = (d1, d2) => {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  };

  useEffect(() => {
    if (props.showDropdown) {
      refetch();
      props.sendMessage(JSON.stringify({ action: "delete" }));
    }
    setTab("direct");
  }, [props.showDropdown]);

  useEffect(() => {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    const result = new Map([
      [tr["today"], []],
      [tr["yesterday"], []],
      [tr["older"], []],
    ]);

    notificationList?.forEach((item) => {
      const createdDate = new Date(item.CREATED_ON);

      if (isSameDay(createdDate, today)) {
        result.get(tr["today"]).push(item);
      } else if (isSameDay(createdDate, yesterday)) {
        result.get(tr["yesterday"]).push(item);
      } else {
        result.get(tr["older"]).push(item);
      }
    });

    setList(result);
  }, [notificationList]);

  const renderList = () => {
    if(listLoading) {
      return (
        <ListLoader />
      )
    }
    let top = -1;
    if (
      list.get(tr["today"]).length === 0 &&
      list.get(tr["yesterday"]).length === 0 &&
      list.get(tr["older"]).length === 0
    ) {
      return (
        <div className="endObjDate mt-5">
          <div className="cenPart">{tr["No Notifications"]}</div>
        </div>
      );
    }

    return (
      <>
        <Scrollbars
          style={{ height: scrollheight }}
          autoHide={false}
          hideTracksWhenNotNeeded={false}
          className="areaScroll"
        >
          {Array.from(list.entries()).map(([key, value]) => {
            if (value.length > 0) {
              top++;
              return (
                <>
                  <div className={`f-size-13 ${top && "margin-t-20"}`}>
                    <div className="text-uppercase">{key}</div>
                  </div>
                  <div className="twoSecObjDv">
                    {value.map((item) => {
                      return (
                        <div className="twoSecObj">
                          <div className="secObj">
                            <div>
                              <Nav.Link
                                as={Link}
                                to="/myNotifications"
                                className="objName"
                                state={{
                                  workItemId: item.ORDER_NUMBER,
                                  msgId: item.MESSAGE_ID,
                                  type: tab,
                                }}
                                onClick={() => props.handleToggle(false)}
                              >
                                {item.SUBJECT}{" "}
                              </Nav.Link>
                              <div className="dSpan">
                                {moment(item.CREATED_ON).from(moment())}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              );
            }
          })}
        </Scrollbars>
        {top !== -1 && <div className="endObjDate">
          <div className="cenPart">
            <Nav.Link
              to="/myNotifications"
              as={Link}
              onClick={() => props.handleToggle(false)}
            >
              {tr["Show More"]}
            </Nav.Link>
          </div>
        </div>}
      </>
    );
  };

  return (
    <div className="rBoxStyle">
      <div className="mbPopupScrol rBoxGap notiPopupScroll">
        <div className="f-size-18 margin-b-10">
          <div style={{ fontWeight: "600" }}>{tr["Notifications"]}</div>
        </div>
        <div className="rBoxTabs hideTabNav">
          <Tabs onSelect={(value) => setTab(value)} id="tabId" activeKey={tab}>
            <Tab eventKey={"direct"} title={tr["Direct (to)"]}>
              {renderList()}
            </Tab>
            <Tab eventKey={"watcher"} title={tr["Watcher (cc)"]}>
              {renderList()}
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default MyPopup;
