
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
//load all order
export const myReviewOrdersList = (state = {}, action) => {
	switch (action.type) {
		case 'LOAD_REVIEWORDERS_SUCCESS':
			return action.reviewOrdersList.data;
		default:
			return state;
	}
}
//load all pending order
export const myPendingReviewOrdersList = (state = {}, action) => {
	switch (action.type) {
		case 'LOAD_PENDINGREVIEWORDERS_SUCCESS':
			return action.reviewOrdersList.data;
		default:
			return state;
	}
}
export const myPendingReviewOrdersCount = (state = {}, action) => {
	switch (action.type) {
		case 'LOAD_PENDINGREVIEWORDERS_COUNT_SUCCESS':
			return action.reviewOrdersListCount.data;
		default:
			return state;
	}
}
//order details of fulfillment
export const myReviewOrderDetails = (state = {}, action) => {
	switch (action.type) {
		case 'LOAD_REVIEWORDER_DETAILS_SUCCESS':
			return action.reviewOrdersDetails.data;
		default:
			return state;
	}
}
//order details of breakfix
export const myReviewBreakFixOrderDetails = (state = {}, action) => {
	switch (action.type) {
		case 'LOAD_REVIEWBREAKFIXORDER_DETAILS_SUCCESS':
			return action.reviewBreakFixOrderDetails.data;
		default:
			return state;
	}
}
//order details of reviewed order
export const myReviewOrderDetailsFrmEngine = (state = {}, action) => {
	switch (action.type) {
		case 'LOAD_REVIEWORDERENGINE_DETAILS_SUCCESS':
			return action.reviewOrdersEngineDetails.data;
		default:
			return state;
	}
}


export const topReviewsList = (state = {}, action) => {
	switch (action.type) {
		case 'LOAD_TOP_REVIEWS_LIST':
			return action.topReviewList.data;
		default:
			return state;
	}
}