
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import { GLOBAL } from "_Globals";
import AutoSuggest from "react-autosuggest";
import EmailValidator from "email-validator";
import Swal from "sweetalert2";
let consumerDataDetails = [];
let consumerData = [];
let myObj = [];
let specifiedEmail = "";
let selectOptions = [];

const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

const getSuggestions = (value, consumerDataDetails) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  if (inputLength > 2) {
    selectOptions = consumerDataDetails;
  }
  return inputLength < 1
    ? []
    : selectOptions.filter(
        (offering) =>
          offering.email.toLowerCase().slice(0, inputLength) === inputValue
      );
};

const getSuggestionValue = (suggestion) => suggestion.email;

function renderSuggestion(suggestion) {
  specifiedEmail = suggestion.email;
  return <span>{suggestion.email}</span>;
}

class SendToTypeHead extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onSuggestionsFetchRequested =
      this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested =
      this.onSuggestionsClearRequested.bind(this);
    this.state = {
      value: "",
      suggestions: [],
    };
  }

  onChange(event, { newValue }) {
    this.setState({
      value: newValue,
    });
    if (this.state.value == "") {
      specifiedEmail = "";
    }
  }
  onSuggestionsFetchRequested({ value }) {
    this.setState({
      suggestions: getSuggestions(value, consumerDataDetails),
    });
  }
  onSuggestionsClearRequested() {
    this.setState({
      suggestions: [],
    });
  }

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: "search for consumer email",
      className: "form-control",
      value,
      onChange: this.onChange,
    };
    return (
      <AutoSuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
      />
    );
  }
}

class NotifyTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toValue: "",
      mailSubject: "",
      mailBody: "",
      sentToDisableValue: false,
      showConsumerTypeHead: false,
      submitButtonDisabled: false,
      specifiedEmail: "",
      onSuccessMessage: "",
    };
    this.onSendToSelection = this.onSendToSelection.bind(this);
    this.setMailSubject = this.setMailSubject.bind(this);
    this.setMailBody = this.setMailBody.bind(this);
    this.postNotification = this.postNotification.bind(this);
    this.setSpecifiedEmail = this.setSpecifiedEmail.bind(this);
  }
  setSpecifiedEmail(value) {
    this.setState({ specifiedEmail: value });
  }

  onSendToSelection(sentTo) {
    let ref = this;
    ref.setState({ sentToDisableValue: false });
    if (sentTo == "User") {
      ref.setState({ showConsumerTypeHead: false });
      ref.setState({ specifiedEmail: "" });

      let strObj = {};
      strObj.userId = "";
      strObj = JSON.stringify(strObj);
      let response = api.get(GLOBAL.breakFixUserDetailsDataUrl, {
        headers: { query: strObj },
      }); //hardcoding impacedUserId as its coming as string in response on edit page
      response.then((responseData) => {
        if (responseData.status == 200) {
          ref.setState({ toValue: responseData.data.email });
          ref.setState({ sentToDisableValue: true });
        } else {
          Swal.fire("error in fetching user email");
          ref.setState({ sentToDisableValue: false });
        }
      });
    }
    if (sentTo == "Group") {
      let spGroupId = this.props.editChangeInitialData.changeManagementGroup;
      //	nextProps.investigationItemDetails.asgPmId
      ref.setState({ sentToDisableValue: true });
      ref.setState({ showConsumerTypeHead: false });
      api.get("/api/groups?groupId=" + spGroupId).then((response) => {
        if (response.status == 200) {
          ref.setState({ toValue: response.data[0].EMAILADDRESS });
        } else {
          Swal.fire("error in loading group email.");
        }
      });
      ref.setState({ specifiedEmail: "" });
    }
    if (sentTo == "Specify") {
      ref.setState({ showConsumerTypeHead: true });
      /* axios.get(GLOBAL.breakFixConsumerUrl).then((response) => {
				consumerData = response.data;
				for (let i = 0; i < consumerData.length; i++) {
					myObj = { userId: '', email: '' };
					myObj.userId = consumerData[i].userId;
					myObj.email = consumerData[i].email;
					consumerDataDetails.push(myObj);
				}
				consumerDataDetails = Array.from(new Set(consumerDataDetails));
			}); */
    }
    if (sentTo == "select") {
      this.setState({ toValue: "" });
      this.setState({ showConsumerTypeHead: false });
    }
  }

  setMailSubject(subject) {
    this.setState({ mailSubject: subject });
  }

  setMailBody(body) {
    this.setState({ mailBody: body });
  }

  postNotification(event) {
    let ref = this;
    event.preventDefault();
    let brkItemId = this.props.changeId;
    let sendTo;
    if (this.state.specifiedEmail.trim() == "") {
      sendTo = this.state.toValue;
    } else {
      sendTo = this.state.specifiedEmail;
    }
    let NotifyObj = {
      itemId: "",
      subject: "",
      mailBody: "",
      addEmail: "",
    };
    if (
      this.state.toValue.trim() == "" &&
      this.state.specifiedEmail.trim() == ""
    ) {
      Swal.fire(this.props.translator["recipient field cannot be empty"]);
    } else {
      if (this.state.mailBody.trim() == "") {
        Swal.fire(this.props.translator["mail body cannot be empty"]);
      } else {
        if (this.state.mailSubject.trim() == "") {
          Swal.fire(this.props.translator["subject cannot be empty"]);
        } else if (EmailValidator.validate(sendTo) == false) {
          Swal.fire(this.props.translator["Please enter a valid email address!"]);
        } else {
          this.setState({ submitButtonDisabled: true });
          NotifyObj.itemId = brkItemId;
          NotifyObj.subject = this.state.mailSubject;
          NotifyObj.mailBody = this.state.mailBody;
          NotifyObj.addEmail = sendTo;
          axios
            .post(GLOBAL.changeemailNotification, NotifyObj)
            .then((response) => {
              if (response.status == 200) {
                this.setState({ submitButtonDisabled: false });
                this.setState({ mailBody: "" });
                this.setState({ mailSubject: "" });
                this.setState({ toValue: "" });
                ref.setState({
                  onSuccessMessage:
                    this.props.translator["Email sent Successfully."],
                });
              } else {
                Swal.fire("error in sending email");
                this.setState({ submitButtonDisabled: false });
              }
            });
          this.setState({ submitButtonDisabled: false });
        }
      }
    }
  }
  render() {
    return this.state.onSuccessMessage == "" ? (
      <div className="hambTab">
        <div className="border-b padding-b-10 margin-b-10 black f-size-20 fw-300">
          {this.props.translator["Notify"]}
        </div>
        <Form.Group className="form-group">
          <Form.Label>
            <span className="rStar"></span> {this.props.translator["Send"]}
          </Form.Label>
          <Form.Select>
            <option value="select">{this.props.translator["Email"]}</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label>
            <span className="rStar"></span> {this.props.translator["Send To"]}
          </Form.Label>
          <Form.Select
            onChange={(event) => {
              this.onSendToSelection(event.target.value);
            }}
          >
            <option value="select">select</option>
            <option value="User">Requestor</option>
            <option value="Group">Group</option>
            <option value="Specify">Specify</option>
          </Form.Select>
        </Form.Group>
        {this.state.showConsumerTypeHead ? (
          <Form.Group className="form-group">
            <Form.Label>{this.props.translator["To"]}</Form.Label>
            {/* <SendToTypeHead /> Issue #96 require only input type field*/}
            <Form.Control
              type="text"
              value={this.state.specifiedMail}
              onChange={(event) => {
                this.setSpecifiedEmail(event.target.value);
              }}
            />
          </Form.Group>
        ) : (
          <Form.Group className="form-group">
            <Form.Label>{this.props.translator["To"]}</Form.Label>
            <Form.Control
              type="text"
              value={this.state.ToValue}
              placeholder={this.state.toValue}
              readOnly={this.state.sentToDisableValue}
            />
          </Form.Group>
        )}
        <Form.Group className="form-group">
          <Form.Label>{this.props.translator["Subject"]}</Form.Label>
          <Form.Control
            type="text"
            value={this.state.mailSubject}
            onChange={(event) => {
              this.setMailSubject(event.target.value);
            }}
          />
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label>{this.props.translator["Body"]}</Form.Label>
          <Form.Control
            as="textarea"
            rows="5"
            value={this.state.mailBody}
            onChange={(event) => {
              this.setMailBody(event.target.value);
            }}
          />
        </Form.Group>
        <Button
          type="submit"
          onClick={(event) => {
            this.postNotification(event);
          }}
          disabled={this.state.submitButtonDisabled}
        >
          {this.props.translator["Send"]}
        </Button>
      </div>
    ) : (
      <div className="hambTab">
        <div className="border-b padding-b-10 margin-b-10 black f-size-20 fw-300">
          {this.props.translator["Notify"]}
        </div>
        {this.state.onSuccessMessage}
      </div>
    );
  }
}

export default NotifyTab;
