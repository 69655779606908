
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { useNavigate } from "react-router-dom";
import CreateIncidentIndex from "./CreateIncidentIndex";

export default function CreateIncidentMain() {
  const navigate = useNavigate();
  return <CreateIncidentIndex navigate={navigate} />;
}
