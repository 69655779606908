
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import { GLOBAL } from '_Globals';

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

export function loadReportDataSuccess(reportList) {
    return {
        type: 'LOAD_REPORT_DATA_SUCCESS',
        reportList
    };
}

export function loadReportData(data) {
    return (dispatch) => {
        api.post(GLOBAL.postReportVersionData, data)
            .then((response) => {
                if (response.status == 200) {
                    dispatch(loadReportDataSuccess(response.data))
                } else {
                    dispatch(emptyReportData);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
}

export function loadVersionData(data) {
    return (dispatch) => {
        api.get(GLOBAL.getApplicationDetails)
            .then((response) => {
                if (response.status == 200) {
                    dispatch(loadVersionDataSuccess(response.data));
                } else {
                    dispatch(emptyReportData);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
}

export function loadVersionDataSuccess(versionList) {
    return {
        type: 'LOAD_VERSION_DATA_SUCCESS',
         versionList
    };
}

export function emptyReportData() {
    return (dispatch) => {
        dispatch({ type: "EMPTY_REPORT_DATA_REDUCER" });
    };
}