
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useRef, useState } from "react";
import { Button, ButtonToolbar } from "react-bootstrap";
import Swal from "sweetalert2";
import Editor from "@monaco-editor/react";

const CustomScript = (props) => {
  const [customScript, setCustomScript] = useState("");

  useEffect(() => {
    let formDetail = JSON.parse(localStorage.getItem("formDetail"));
    if (formDetail?.customScript) {
      setCustomScript(formDetail.customScript);
    }
  }, []);

  const saveData = () => {
    let formDetail = JSON.parse(localStorage.getItem("formDetail"));
    formDetail.customScript = editorRef.current.getValue();
    localStorage.setItem("formDetail", JSON.stringify(formDetail));
    Swal.fire("Saved successfully");
  };

  const editorRef = useRef(null);

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;
  }

  function showValue() {
    alert(editorRef.current.getValue());
  }

  return (
    <div style={{ overflowX: "auto", background: "#fff" }}>
      <div>
        {/* <textarea
          className="form-control"
          rows="5"
          value={customScript}
          onChange={(e) => setCustomScript(e.target.value)}
        ></textarea> */}
        <Editor
          height="90vh"
          defaultLanguage="javascript"
          defaultValue={customScript}
          onMount={handleEditorDidMount}
          theme="vs-dark"
        />
      </div>
      <div className="mx-2 mb-1">
        <ButtonToolbar className="black" bsPrefix=" ">
          <Button
            className="rgSidrkBtn smallBtn"
            size="xs"
            onClick={() => {
              saveData();
            }}
          >
            Save
          </Button>
        </ButtonToolbar>
      </div>
    </div>
  );
};

export default CustomScript;
