/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, {useEffect, useState} from 'react';
import { Form } from 'react-bootstrap';

const InputFieldComponent = ({handleOnChange,value,fieldData, inputFieldPlaceholder}) => {
    const [text, setText] = useState('');
    const [placeholder, setPlaceHolder] = useState(inputFieldPlaceholder);
    const [fieldName, setFieldName] = useState('');

    const handleValueChange = (value) => { 
      setText(value);
      handleOnChange(value);
    }
    useEffect(() => { 
      if(value != '' && value != null && value != undefined &&  text != value){
        handleValueChange(value);
      }
      setPlaceHolder(inputFieldPlaceholder);
      setFieldName(fieldData.name);
    }, []);

    useEffect(() => {
      if(fieldName !== fieldData.name && (value === '' || value?.length === 0)){
        setFieldName(fieldData.name);
        handleValueChange('');
        setPlaceHolder(inputFieldPlaceholder)
        handleOnChange('');        
      }
    }, [fieldData]);  
 
    const handleInputChange = (event) => {
        handleValueChange(event.target.value);
    };
    
    return <Form.Control type="text" placeholder={placeholder} value={text} onChange={handleInputChange} />
}

export default InputFieldComponent;
