
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import {
  Row,
  Col,
  Button,
  ButtonToolbar,
  Media,
  Image,
  Form,
  Table,
} from "react-bootstrap";
import { loadChangeRelatedWorkDetails } from "../../../../actions/changeManagement/cmdbchangeRelatedWorkAction";
import { loadChangeRelatedDataDetails } from "../../../../actions/changeManagement/cmdbchangeRelatedDataAction";
import {
  getRelationshipTypeData,
  getClassNameData,
  getAssetCIByClassName,
  getCiForTypeahead,
} from "../../../../actions/cmdb/relateAssetCIAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { GLOBAL } from "_Globals";
import axios from "axios";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { Link, redirect } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import { navigationHooks } from "../../../../helpers/NavigationHook";
import { element } from "prop-types";
import Cookies from "universal-cookie";
import AutoSuggest from "react-autosuggest";
import { TbCirclesRelation } from "react-icons/tb";
import { HiPlus } from "react-icons/hi";
import { TbTextWrap } from "react-icons/tb";
import { IoSearch, IoClose } from 'react-icons/io5';
import { openLinkInNewTab } from "../../../common/helper";

const cookies = new Cookies();
let app_url = cookies.get("gph");
if (app_url) app_url = app_url.replace("s:", "");
if (app_url) app_url = app_url.substring(0, app_url.lastIndexOf("."));
app_url = app_url.split("~");
app_url = app_url[18];
const ref = React.createRef();
const MoreArrow = (
  <i title="More" className="fa fa-angle-double-down f-size-15"></i>
);
let selectOptions = [];
const getSuggestions = (value, options) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  if (inputLength > 2) {
    selectOptions = options;
  }
 
  return inputLength < 2
    ? []
    : selectOptions.filter(
      (option) => option.toLowerCase().indexOf(inputValue) > -1
    );
};

const getSuggestionValue = (suggestion) =>
  suggestion.length == 0 ? [] : suggestion;
function renderSuggestion(suggestion) {
  return <span>{suggestion}</span>;
}
class CmdbRelatedWork extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserData: false,
      listTableToggle: "table",
      relationshipTypeId: "",
      relationshipTypeName: "",
      classId: "",
      className: "",
      searchText: "",
      showSearchData: false,
      assetCiList: [],
      value: "",
      suggestions: [],
    };

    GLOBAL.checkmoduleval = "CMDB";
    this.toggleListAndTable = this.toggleListAndTable.bind(this);
    this.onChangeSearchCi = this.onChangeSearchCi.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.setErrorColor = this.setErrorColor.bind(this);
    this.onCrossClickFun = this.onCrossClickFun.bind(this);
    this.onSuggestionsFetchRequested =
      this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested =
      this.onSuggestionsClearRequested.bind(this);
    this.onChangeAssetCi = this.onChangeAssetCi.bind(this);
  }
 

  onChangeAssetCi(event, { newValue }) {
    let orginalValue;
    let searchvalue=newValue.split(" | ");
    // if(searchvalue.length > 1){
    //   orginalValue=searchvalue[0]
    // }else{
    //   orginalValue=searchvalue[0]
    // }
    orginalValue=searchvalue[0]
    this.setState({
      value: orginalValue,
    });
    // if (newValue.length == 0) {
    //     this.props.meta.dispatch(change('CmdbCreateForm', 'businessowner', ''));
    //     this.props.meta.dispatch(change('CmdbCreateForm', 'businessownerName', ''));
    // }
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value, this.state.assetCiList),
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  setErrorColor() { }
  onChangeSearchCi(selectedCi) {
    if (selectedCi.length > 0) {
      this.setState({
        searchText: selectedCi[0],
      });
    }
  }

  onInputChange(event) {
    this.setState({
      searchText: event,
    });
  }

  onCrossClickFun() {
    // ref.current.clear()
    // this._typeahead.clear();
    this.setState({
      value: "",
    });
  }
  componentWillMount() {
    let itemId = this.props.itemId;
    this.props.loadChangeRelatedDataDetails(itemId, "CMDB","No");
  }

  componentWillUnmount() {
    this.props.loadChangeRelatedDataDetails("0", "CMDB","No");
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.relationshipTypes &&
      nextProps.relationshipTypes != this.props.relationshipTypes &&
      nextProps.relationshipTypes.length > 0
    ) {
      this.setState({
        relationshipTypeId: nextProps.relationshipTypes[0].R_ID,
        relationshipTypeName: nextProps.relationshipTypes[0].RELATIONSHIP_TYPE,
      });
      this.props.getClassNameData(
        this.props.cieditdetails.CLASS_ID,
        nextProps.relationshipTypes[0].R_ID
      );
    }
    if (
      nextProps.classNames &&
      nextProps.classNames != this.props.classNames &&
      nextProps.classNames.length > 0 &&
      this.state.classId != ""
    ) {
      let arr = [];
      nextProps.classNames.forEach((item, i) => {
        arr.push(Number(item.CLASS_ID_RELATED));
      });
      this.props.getAssetCIByClassName(arr, "", this.props.companyId);
    }
    if (
      nextProps.assetCIListByClassName &&
      nextProps.assetCIListByClassName != this.props.assetCIListByClassName
    ) {
      // this.setState({ showSearchData: true });
    }
    //console.log("assetCIListByClassName",nextProps.assetCIListByClassName )
    //console.log("assetCiList===",this.state.assetCiList, this.state.assetCiList.length )
      
    if (
      nextProps.ciForTypeahead &&
      nextProps.ciForTypeahead != this.props.ciForTypeahead
    ) {
        let option = nextProps.ciForTypeahead.map((element) => {
        let serialNumber = element.SERIAL_NUMBER ? ` | ${element.SERIAL_NUMBER}` : ` `
        return `${element.CI_NAME}${serialNumber}`;
      })
      this.setState({
        assetCiList: option,
      });
    }
  }

  postRelatedData(brkid, classid) {
    let itemId = this.props.itemId;
    //let r_id = this.props.relationshipTypes.map(item => item.R_ID);
    let r_id = this.state.relationshipTypeId;

    axios
      .post(GLOBAL.cmdbRelatedLogDeleteUrl, {
        ci_id: itemId,
        ci_id_related: brkid,
        created_by: "",
        module: "CMDB",
        r_id: r_id,
        ci_related_class_id: classid
      })
      .then((response) => {
        if (response.data.status === false) {
          Swal.fire({
            title: response.data.message,
            button: "OK",
          });
        } else {
          let arr = [];
          this.props.classNames.forEach((item, i) => {
            arr.push(Number(item.CLASS_ID_RELATED));
          });
          this.setState({
            classId: "",
            className: "",
          });
          this.setState({
            listTableToggle: "table",
            showSearchData: false,
            searchText: "",
          });
          this.props.loadChangeRelatedDataDetails(itemId, "CMDB","No");
        }
      });
  }

  renderRelatedWorkLog(relatedLogData) {
    // console.log("assetCIListByClassName", relatedLogData)
    if (this.state.showSearchData == false) {
      return false;
    }
    if (relatedLogData.length == "0") {
      return <div>{this.props.translator["Data not available"]}</div>;
    } else {
      return (
        <Table
          responsive
          striped
          bordered
          condensed
          hover
          className="f-size-13"
        >
          <thead>
            <tr className="f-size-12">
              <th width="30%">{this.props.translator["Class Name"]}</th>
              <th width="35%">{this.props.translator["CI Name"]}</th>
              <th width="20%">{this.props.translator["Created By"]}</th>
              <th width="5%" className="text-c">
                {this.props.translator["Action"]}
              </th>
            </tr>
          </thead>
          <tbody>{this.renderSearchList(relatedLogData)}</tbody>
        </Table>
      );
    }
  }

  renderSearchList(relatedLogData) {
    return relatedLogData.map((relatedObj, index) => {
      let modnumber = "",
        modid = "",
        classId = "";
      let modulesearchparam = GLOBAL.checkmoduleval;
      if (modulesearchparam == "Breakfix") {
        modnumber = relatedObj.breakfixNumber;
        modid = relatedObj.breakfixId;
      }
      if (modulesearchparam == "Fullfilment") {
        modnumber = relatedObj.itemNumber;
        modid = relatedObj.itemId;
      }
      if (modulesearchparam == "Investigation") {
        modnumber = relatedObj.investigationNumber;
        modid = relatedObj.investigationId;
      }
      if (modulesearchparam == "ChangeManagement") {
        modnumber = relatedObj.changeNumber;
        modid = relatedObj.changeId;
      }
      if (modulesearchparam == "CMDB") {
        modnumber = relatedObj.CI_CODE;
        modid = relatedObj.CI_ID;
        classId = relatedObj.CLASS_ID;
      }
      return (
        <tr>
          <td>{relatedObj.CLASS_NAME}</td>
          <td style={{ wordBreak: "break-word" }}>
            {relatedObj.CI_NAME}
            <div className="tbSerialTxt">Serial Number - {(relatedObj.SERIAL_NUMBER == "" || relatedObj.SERIAL_NUMBER == null)? "NA": relatedObj.SERIAL_NUMBER}</div>
          </td>
          <td>{relatedObj.CREATED_BY}</td>
          <td className="text-c f-size-13">
            <a
              onClick={this.postRelatedData.bind(this, modid, classId)}
              title={this.props.translator["Relate"]}
            >
              <i className="fa fa-chevron-circle-right f-size-16 theme1"></i>
            </a>
          </td>
        </tr>
      );
    });
  }

  deleteRelation(ci_id, ci_id_related, correct_related_ci_data) {
    if (
      correct_related_ci_data == "TRUE" ||
      correct_related_ci_data == "true"
    ) {
      let temp = ci_id;
      ci_id = ci_id_related;
      ci_id_related = temp;
    }

    axios
      .delete(
        GLOBAL.cmdbRelatedLogDeleteUrl +
        "?ci_id=" +
        ci_id +
        "&ci_id_related=" +
        ci_id_related +"&module=CMDB"
      )
      .then((response) => {
        if (!response || response.status != "200") {
          swal({
            text: "Deletion failed.",
            button: this.props.translator["OK"],
          });
        } else {
          swal({
            text: "Deleted Relation",
            button: this.props.translator["OK"],
          });
          let itemId = this.props.itemId;
          this.props.loadChangeRelatedDataDetails(itemId, "CMDB","No");
          let arr = [];
          this.props.classNames.forEach((item, i) => {
            arr.push(Number(item.CLASS_ID_RELATED));
          });
          this.setState({
            classId: "",
            className: "",
          });
        }
      });
  }

  renderAttList(attributeData) {
    if (!attributeData) {
      return null;
    }
    return attributeData.map((objjectData, i) => {
      let reldate = objjectData.CREATED_AT;
      let splitdate = reldate.split(".")[0];
      let urlNew = app_url + "/home?editcmdb=" + objjectData.CI_ID_RELATED;
      if (objjectData.CI_ID != null && objjectData.CI_ID != "")
        return (
          <tr key={i}>
            <td>{objjectData.CORRECT_RELATIONSHIP_NAME}</td>
            <td>{objjectData.CLASS_NAME_RELATED}</td>
            {/* <td><Link to={"/editcmdb/"+objjectData.CI_ID_RELATED}>{objjectData.CI_NAME_RELATED}</Link></td> */}
            <td>
              {/* <Link
										title="Ci Name" rel="noopener noreferrer"
										onClick={() => {
											let newWindow = window.open();
											newWindow.opener = null;
											newWindow.location = app_url+"/home?editcmdb="+objjectData.CI_ID_RELATED;
										}}
									> */}
              {/* {objjectData.CI_NAME_RELATED} */}
              {/* <i className="fa fa-external-link" aria-hidden="true" ></i> */}
              {/* </Link> */}

              <a title="Ci Name" href={urlNew} target="_blank">
                {" "}
                {objjectData.CI_NAME_RELATED}
                
              </a>
              <div className="tbSerialTxt">Serial Number - {(objjectData.SERIAL_NUMBER_CI_NAME_RELATED=='' || objjectData.SERIAL_NUMBER_CI_NAME_RELATED==null) ? "NA" : objjectData.SERIAL_NUMBER_CI_NAME_RELATED} </div>
            </td>
            {/* <td>
              <span onClick={()=>redirect (`/editcmdb/${objjectData.CI_ID_RELATED}`)}>
              {objjectData.CI_NAME_RELATED}
              </span>
            </td> */}
            <td>{splitdate}</td>
            <td>{objjectData.CREATED_BY}</td>
            {this.props.isRoleDisabled  ? (
                        <td className="text-c f-size-13">
                        <i
                          onClick={this.deleteRelation.bind(
                            this,
                            objjectData.CI_ID,
                            objjectData.CI_ID_RELATED,
                            objjectData.CORRECT_RELATED_CI_DATA
                          )}
                          className="fa fa-trash-o cursorPoint"
                        ></i>
                      </td>
                      ) : null}
          </tr>
        );
      else return <tr></tr>;
    });
  }

  renderRelatedDataLog(relatedDataLog) {
    if (relatedDataLog.length == 0) {
      return <div>{this.props.translator["Data not available"]}</div>;
    } else {
      return (
        <Table responsive striped bordered condensed hover>
          <thead>
            <tr>
              <th>{this.props.translator["Relationship Type"]}</th>
              <th>{this.props.translator["Class Name"]}</th>
              <th>{this.props.translator["CI Name"]}</th>
              <th>{this.props.translator["Created On"]}</th>
              <th>{this.props.translator["Related By"]}</th>
              {this.props.isRoleDisabled
							? (
                <th className="text-c">{this.props.translator["Action"]}</th>
							) : null}
            </tr>
          </thead>
          <tbody>{this.renderAttList(relatedDataLog)}</tbody>
        </Table>
      );
    }
  }

  loadrelateddata() {
    let itemId = this.props.itemId;
    let arr = [];
    this.props.classNames.forEach((item, i) => {
      arr.push(Number(item.CLASS_ID_RELATED));
    });
    this.setState({
      classId: "",
      className: "",
    });
    this.props.loadChangeRelatedDataDetails(itemId, "CMDB","No");
    this.setState({
      listTableToggle: "table",
      showSearchData: false,
      value: "",
    });
  }

  toggleListAndTable(value) {
    this.setState({ listTableToggle: value });
  }

  renderRelationshipTypesDropdown(relationshipTypes) {
    return relationshipTypes.map((type, index) => {
      return <option value={type.R_ID}>{type.RELATIONSHIP_TYPE}</option>;
    });
  }

  onRelationshipTypeChange(event) {
    this.setState({
      relationshipTypeId: event.target.value,
      relationshipTypeName:
        event.target.value == ""
          ? ""
          : event.target[event.target.selectedIndex].text,
      classId: "",
      className: "",
    });
    this.props.getClassNameData(
      this.props.cieditdetails.CLASS_ID,
      event.target.value
    );
  }

  renderClassNamesDropdown(classNames) {
    return classNames.map((name, index) => {
      return <option value={name.CLASS_ID_RELATED}>{name.CLASS_NAME}</option>;
    });
  }

  onClassNameChange(event) {
    const selectedValue = event.target.value;
    const selectedText = selectedValue === "" ? "" : event.target[event.target.selectedIndex].text;
  
    this.setState({
      classId: selectedValue,
      className: selectedText,
      searchText: "",
    });
    
    if (selectedValue !== "") {
      this.props.getCiForTypeahead([selectedValue], "", this.props.companyId);
    }

    // let arr = [];
    // let classId = [event.target.value];
    // if(event.target.value!==""){
    //   this.props.getCiForTypeahead(classId, "");
    // }
  
  }

  changeSearchText(event) {
    this.setState({ searchText: event.target.value });
  }

  onSearchClick() {
    let arr = [];
    if (this.state.classId == "") {
      this.props.classNames.forEach((item, i) => {
        arr.push(Number(item.CLASS_ID_RELATED));
      });
      this.props.getAssetCIByClassName(arr, this.state.value?.trim(), this.props.companyId);
    } else {
      arr.push(Number(this.state.classId));
      this.props.getAssetCIByClassName(arr, this.state.value?.trim(), this.props.companyId);
    }
    this.props.loadChangeRelatedDataDetails("0", "CMDB","No");
    this.setState({ showSearchData: true });
  }

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: "Please Choose...",
      className: "form-control",
      value,
      onChange: this.onChangeAssetCi,
      // onBlur: this.validateField
    };
    return (
      <div>
        {this.state.listTableToggle == "list" ? (
          <div>
            <Row>
              <Col xs={12}>
                <div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
                  <ul>
                    <li>
                      <Button
                        bsPrefix=" "
                        onClick={this.loadrelateddata.bind(this)}
                        bsClass=""
                        bsStyle=""
                        title={this.props.translator["Add"]}
                      >
                        <HiPlus />
                      </Button>
                    </li>
                    <li className="d-md-none">
                      <button
                          type='button'
                          title="Minimize the right panel"
                          bsClass=""
                          bsStyle=""
                          className="closerightPanelBtn"
                          onClick={() => {
                          this.props.rightEditPanel(false);
                          }}
                      >
                          <IoClose/>
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="rPageHeading">
                  <div className="offNam margin-t-5 margin-b-5">
                    {this.props.translator["CI Relationships"]}
                  </div>
                </div>
              </Col>
            </Row>

            <div className="rBoxGap">
              <Row>
                <Col md={12}>
                  <Form.Group className="form-group">
                    <Form.Label>
                      {this.props.translator["Relationship Type"]}
                    </Form.Label>
                    <Form.Select
                      componentClass="select"
                      onChange={(event) => this.onRelationshipTypeChange(event)}
                    >
                      {this.renderRelationshipTypesDropdown(
                        this.props.relationshipTypes
                      )}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="form-group">
                    <Form.Label>
                      {this.props.translator["Class Name"]}
                    </Form.Label>
                    <Form.Select
                      componentClass="select"
                      value={this.state.classId}
                      onChange={(event) => this.onClassNameChange(event)}
                    >
                      <option value="">
                        {this.props.translator["Select"]}
                      </option>
                      {this.renderClassNamesDropdown(this.props.classNames)}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="form-group cmdbdvTypehd">
                    <Form.Label>{this.props.translator["Asset/CI"]}</Form.Label>
                    <div className="dvTypehd">
                      {this.state.value && this.state.value.length > 0 ? (
                        <span
                          title="clear"
                          role="img"
                          aria-label="Clear"
                          className="css-hakgx8 icn"
                          onClick={this.onCrossClickFun}
                        >
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            role="presentation"
                          >
                            <path
                              d="M12 10.586L6.707 5.293a1 1 0 00-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 001.414 1.414L12 13.414l5.293 5.293a1 1 0 001.414-1.414L13.414 12l5.293-5.293a1 1 0 10-1.414-1.414L12 10.586z"
                              fill="gray"
                            ></path>
                          </svg>
                        </span>
                      ) : null}
                      {/* <Typeahead 
                    single
                    ref={ref}
                    options={this.state.assetCiList}
                    selected={""}
                    placeholder={"Please Choose..."}
                    onBlur={this.setErrorColor}
                    onInputChange={this.onInputChange}
                    onChange={this.onChangeSearchCi}
					        />    */}
                      <AutoSuggest
                        suggestions={this.state.suggestions}
                        onSuggestionsFetchRequested={
                          this.onSuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          this.onSuggestionsClearRequested
                        }
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                        onSuggestionSelected={this.onSelect}
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>              
              <Row className="margin-t-15">
                <Col md={12}>
                  <ButtonToolbar className="black">
                    <Button
                      onClick={this.loadrelateddata.bind(this)}
                      type="button"
                      bsSize="small"
                      bsStyle="primary"
                      className="rgSiLigBtn smallBtn"
                    >
                      {this.props.translator["Back to List"]}
                    </Button>
                    <Button
                      onClick={this.onSearchClick.bind(this)}
                      bsSize="small"
                      bsStyle="text"
                      className="rgSidrkBtn smallBtn"
                    >
                      <IoSearch />{" "}
                      {this.props.translator["Search"]}
                    </Button>
                  </ButtonToolbar>
                </Col>
              </Row>

              <div className="margin-t-20">
                {this.renderRelatedWorkLog(this.props.assetCIListByClassName)}
              </div>
            </div>
          </div>
        ) : (
          <div>
            <Row>
              <Col xs={12}>
                <div className="rPageHeadActBtn">
                  <ul>
                    <li>
                      {this.props.isRoleDisabled  ? (
                        <Button
                          bsPrefix=" "
                          onClick={() => {
                            this.toggleListAndTable("list");
                            this.props.getRelationshipTypeData(
                              this.props.cieditdetails.CLASS_ID
                            );
                          }}
                          bsClass=""
                          bsStyle=""
                          className="recticn"
                          title={this.props.translator["Add"]}
                        >
                          <i className="fa fa-plus" aria-hidden="true"></i>
                        </Button>
                      ) : null}
                    </li>
                    <li>
                      {this.props.cmdbChangeRelatedDataDetails.length > 0 && (
                        <Button
                          bsPrefix=" "
                          // onClick={this.loadrelateddata.bind(this)}
                          onClick={() => {
                            openLinkInNewTab(app_url +
                              "/home?visualCI=" +
                              this.props.cieditdetails.CI_ID, "New Tab");
                          }}
                          bsClass=""
                          bsStyle=""
                          className="recticn"
                          title={
                            this.props.translator["Visualize CI Relationships"]
                          }
                        >
                          <TbTextWrap />
                        </Button>
                      )}
                    </li>
                    <li className="d-md-none">
                      <button
                          type='button'
                          title="Minimize the right panel"
                          bsClass=""
                          bsStyle=""
                          className="closerightPanelBtn"
                          onClick={() => {
                          this.props.rightEditPanel(false);
                          }}
                      >
                          <IoClose/>
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="rPageHeading">
                  <div className="offNam margin-t-5 margin-b-5">
                    {this.props.translator["CI Relationships"]}
                  </div>
                </div>
              </Col>
            </Row>
            <div className="rBoxGap">
              {this.renderRelatedDataLog(
                this.props.cmdbChangeRelatedDataDetails
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export function mapStateToProps({
  cmdbChangeRelatedWorkDetails,
  cmdbChangeRelatedDataDetails,
  relationshipTypes,
  classNames,
  assetCIListByClassName,
  ciForTypeahead,
}) {
  return {
    cmdbChangeRelatedWorkDetails,
    cmdbChangeRelatedDataDetails,
    relationshipTypes,
    classNames,
    assetCIListByClassName,
    ciForTypeahead,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadChangeRelatedWorkDetails,
      loadChangeRelatedDataDetails,
      getRelationshipTypeData,
      getClassNameData,
      getAssetCIByClassName,
      getCiForTypeahead,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CmdbRelatedWork);
