
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector, connect } from "react-redux";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import {_inputField, _dropDown} from "../../common/ReduxFormFields/CategoryBoard/formFields";
import {_checkbox, TypeaheadandDropdown, _number, TypeaheadExampleSingleSelect} from "../../common/formFields";
import Cookies from "universal-cookie";
import useDebounce from "../../../helpers/useDebounce";
import {getOnCallGroups,loadOnCallSXMenulistData} from "../../../actions/onCallSchedule/common/onCallCommonAction";
import axios from "axios";
import { GLOBAL } from "../../Globals";
import { ImInfo } from "react-icons/im";
import { validateEmail } from "../../../utils/common";
const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

const cookies = new Cookies();
const gph = cookies.get("gph").split("~");
let app_url = cookies.get("gph");
if (app_url) app_url = app_url.replace("s:", "");
if (app_url) app_url = app_url.substring(0, app_url.lastIndexOf("."));
app_url = app_url.split("~");
app_url = app_url[18];
const companyId = typeof gph[48] !== "undefined" ? gph[48] : "";
const modules = [
  { field1Key: "Incident", field1Value: "Incident" }
];
const statusOptions = [
  { field1Key: "Active", field1Value: "Active" },
  { field1Key: "Inactive", field1Value: "Inactive" }
];



const validate = (values, props) => {
  const errors = {};
  if (!values.escalationTimeoutMin) errors.escalationTimeoutMin = " ";
  else if (values.escalationTimeoutMin.trim() === "" || parseInt(values.escalationTimeoutMin.trim(),10)<0)
    errors.escalationTimeoutMin = " ";

  if (!values.escalationLevel)
    errors.escalationLevel = "Please choose escalation level";

  if (!values.plan) errors.plan = "Please choose escalation plan";
  if (!values.escalationId)
    errors.escalationId = "Please choose escalation escalationId";
  if (!values.escalationModule)
    errors.escalationModule = "Please choose escalation module";

  if (!values.escalationStatus)
    errors.escalationStatus = "Please choose escalation status";

  if (values.externalEmail){
    let error = false;
    let emails = values.externalEmail.split(",");
    emails.forEach(email => {
      if(!validateEmail(email)){
        error = true;
      }
    });
    if(error){
      errors.externalEmail = "Please enter a valid email!";
    }
  }


  return errors;
};
const CreateEscalationForm = (props) => {
  const dispatch = useDispatch();
  const translator = useSelector((state) => state.spcmReducer.tr);
  let groupList = useSelector((state) => state.onCallGroupList);
  const onCallCommonReducer = useSelector(
    (state) => state.on_call_common_reducer
  );
  const [usersOptions, setUserOptions] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [channelOptions, setChannelOptions] = useState([
    { label: "Email", id: 1 },
    { label: "SMS", id: 2 },
  ]);
 
  const [typedChar, setTypedChar] = useState("");
  const [typedCompany, setTypedCompany] = useState("");
  const [typedCharChannel, setTypedCharChannel] = useState("");
  const [typedCharGroup, setTypedCharGroup] = useState("");
  const [groupManager, setGroupManager] = useState("No");
  const [companyList, setCompanyList] = useState([]);

  const selector = formValueSelector("CreateEscalationForm");

  const getCompanyList = () => {
    let impactedServiceData;
    api.get("/api/getInitiallistCompany").then((responseCompany) => {
      let companyList = [];
      impactedServiceData = responseCompany.data.listCompany;
      impactedServiceData.map((item) => {
        companyList.push({
          label: item.name,
          id: item.id,
        });
        setCompanyList(companyList);
      });
    });
  };

  useEffect(() => {
    if (groupList && groupList.length > 0) {
      groupList = groupList.map((list) => {
        return { ...list, id: list.groupId, label: list.groupName };
      });
      setGroupOptions(groupList);
    }
  }, [groupList]);

  useEffect(() => {
    dispatch(getOnCallGroups());
    dispatch(loadOnCallSXMenulistData("AIOSEM", "EscalationLevel"));
    dispatch(change("CreateEscalationForm", "escalationLevel", 1));
    getCompanyList();
  }, []);

  const onChannelSelection = (selected) => {
    if (selected.length == 0) {
      props.setChannelError("error");
      props.setSelectedChannels([]);
    } else {
      props.setChannelError("");
    }
    props.setSelectedChannels(selected);
  };

  const onChannelValueChange = (value) => {
    onChannelChange(value);
  };

  const setChannelErrorColor = () => {
    if (props.selectedChannels.length === 0) {
      props.setChannelError("error");
    } else {
      props.setChannelError("");
    }
  };

  const onChannelChange = (value) => {
    setTypedCharChannel(value);
    // if (value.length > 3) {
    //   channelOptionApi();
    // } else {
    //   setChannelOptions([]);
    // }
  };

  const channelOptionApi = useDebounce(() => {
    let value = typedCharChannel;
    let consumerDataDetails = [],
      consumerData = [],
      myObj = {},
      strObj = {};
    strObj.like = value;
    strObj.companyId = companyId;
    strObj = JSON.stringify(strObj);
    if (value !== "" && value.length > 3) {
      axios
        .get(GLOBAL.consumerUsersNewUrl, { headers: { query: strObj } })
        .then((response) => {
          consumerData = response.data;
          if (Array.isArray(response.data)) {
            for (const element of consumerData) {
              myObj = { value: "", label: "" };
              myObj.id = element.userId;
              myObj.label = element.fullname;
              myObj.name = element.fullname;
              myObj.email = element.email;
              consumerDataDetails.push(myObj);
            }
            consumerDataDetails = Array.from(new Set(consumerDataDetails));
          } else {
            consumerDataDetails = [];
          }
          setUserOptions(consumerDataDetails);
        });
    }
  });

  const countCharacters = (e) => {
    if (e.target.value.length > 50) {
      e.preventDefault();
    }
  };

  const onUserValueSelection = (selected) => {
    if (!(Array.isArray(selected) && selected.length)) {
      props.setValueError("error");
      setUserOptions([]);
    } else {
      props.setValueError("");
    }
    props.setSelectedUsers(selected);
  };

  const onUserValueChange = (value) => {
    onUserChange(value);
  };

  const onUserChange = (value) => {
    setTypedChar(value);
    if (value.length > 3) {
      userOptionsApi();
    } else {
      setUserOptions([]);
    }
  };

  const userOptionsApi = useDebounce(() => {
    let value = typedChar;
    let consumerDataDetails = [],
      consumerData = [],
      myObj = {},
      strObj = {};
    strObj.like = value;
    strObj.companyId = props.selectedCompany[0].id;
    strObj = JSON.stringify(strObj);
    if (value !== "" && value.length > 3) {
      axios
        .get("/api/userCompany/companyuserlist", { headers: { query: strObj } })
        .then((response) => {
          consumerData = response.data;
          if (Array.isArray(response.data)) {
            for (const element of consumerData) {
              myObj = { value: "", label: "" };
              myObj.id = element.userId;
              myObj.label = element.fullname;
              myObj.name = element.fullname;
              myObj.email = element.email;
              consumerDataDetails.push(myObj);
            }
            consumerDataDetails = Array.from(new Set(consumerDataDetails));
          } else {
            consumerDataDetails = [];
          }
          setUserOptions(consumerDataDetails);
        });
    }
  });

  const onGroupValueSelection = (selected) => {
    if (!(Array.isArray(selected) && selected.length)) {
      props.setGroupError("error");
    } else {
      props.setGroupError("");
    }
    props.setSelectedGroups(selected);
  };

  const onGroupValueChange = (value) => {
    onGroupChange(value);
  };

  const onGroupChange = (value) => {
    // setTypedCharGroup(value);
    // if (value.length > 3) {
    //   groupOptionsApi();
    // } else {
    //   setGroupOptions([]);
    // }
  };

  const groupOptionsApi = useDebounce(() => {
    let value = typedChar;
    let consumerDataDetails = [],
      consumerData = [],
      myObj = {},
      strObj = {};
    strObj.like = value;
    strObj.companyId = companyId;
    strObj = JSON.stringify(strObj);
    if (value !== "" && value.length > 3) {
      axios
        .get(GLOBAL.consumerUsersNewUrl, { headers: { query: strObj } })
        .then((response) => {
          consumerData = response.data;
          if (Array.isArray(response.data)) {
            for (const element of consumerData) {
              myObj = { value: "", label: "" };
              myObj.id = element.userId;
              myObj.label = element.fullname;
              myObj.name = element.fullname;
              myObj.email = element.email;
              consumerDataDetails.push(myObj);
            }
            consumerDataDetails = Array.from(new Set(consumerDataDetails));
          } else {
            consumerDataDetails = [];
          }
          setUserOptions(consumerDataDetails);
        });
    }
  });

  const onChangeGroupMgr = (e) => {
    if (e.target.checked) {
      setGroupManager("Yes");
    } else {
      setGroupManager("No");
    }
  };

  /// company
  const onCompanySelection = (company) => {
    props.setSelectedCompany(company);
    props.setSelectedUsers([]);
  };

  const setErrorCompanyColor = () => {
    if (props.selectedCompany.length === 0) props.setCompanyError("error");
    else props.setCompanyError("");
  };

  const companyCleared = (e) => {
    setTypedCompany(e);
  };

  const onCrossClickCompany = () => {
    props.setSelectedCompany([]);
    props.setSelectedUsers([]);
    props.setCompanyError("error")
  };

  const onChangeHandle = (e)=>{
    if(e.target.name =="emailIds")
      props.setEmailIds(e.target.value);
  }

  return (
    <>
      <Row>
        <Col md={3} sm={3} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>
              <span className="rStar"></span>
              {translator["Company"]}
            </Form.Label>
            <Field
              id="companyDropdown"
              name="company"
              className="form-control"
              component={TypeaheadExampleSingleSelect}
              onSelection={onCompanySelection}
              setErrorColor={setErrorCompanyColor}
              options={companyList}
              selectedOptions={props.selectedCompany}
              onInputChange={companyCleared}
              onCrossClick={onCrossClickCompany}
              typedValue={typedCompany}
              errorClass={props.companyError}
            />
          </Form.Group>
        </Col>
        <Col md={3} sm={3} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>
              <span className="rStar"></span>
              {"Plan"}
            </Form.Label>
            <div className="heightdvTypehd">
              <Field
                component={_inputField}
                name="plan"
                className="form-control"
              ></Field>
            </div>
          </Form.Group>
        </Col>
        <Col md={3} sm={3} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>
              <span className="rStar"></span>
              {translator["Module"]}
            </Form.Label>
            <div className="heightdvTypehd infoBtnDiv">
              <Field
                component={_dropDown}
                name="escalationModule"
                options={modules}
                className="form-control"
              />
            </div>
          </Form.Group>
        </Col>
        <Col md={3} sm={3} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>
              <span className="rStar"></span>
              {translator["Status"]}
            </Form.Label>
            <div className="heightdvTypehd infoBtnDiv">
              <Field
                component={_dropDown}
                name="escalationStatus"
                options={statusOptions}
                className="form-control"
              />
            </div>
          </Form.Group>
        </Col>
        {/* <Col md={4} sm={4} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>{"Escalation Id "}</Form.Label>
            <div className="heightdvTypehd infoBtnDiv">
              <Field
                component={_inputField}
                name="escalationId"
                className="form-control"
                disabled
              ></Field>
              <Button
                title="Info"
                bsPrefix=" "
                className="infoicn"
                onClick={() => {}}
              >
                <ImInfo />
              </Button>
            </div>
          </Form.Group>
        </Col> */}
        <div className="border margin-b-15"> </div>
      </Row>
      <Row>
        <h3 className="margin-b-15">{translator["Escalation Level"]}</h3>
        <Col md={4} sm={4} xs={12}>
          <Form.Group className="form-group">
            <Form.Label bsClass="">
              <span className="rStar"></span>
              {"Level"}
            </Form.Label>
            <Field
              component={_dropDown}
              name="escalationLevel"
              disabled
              options={onCallCommonReducer.escalationLevel.length>0?[onCallCommonReducer.escalationLevel[0]]:[]}
              className="form-control"
            />
          </Form.Group>
        </Col>
        <Col md={4} sm={4} xs={12}>
          <Form.Group className="dvTypehdropdown-group">
            <Form.Label>
              <span className="rStar"></span>
              {translator["Escalation Timeout (in min)"]}
            </Form.Label>
            <div className="minHghtdvTypehd">
              <Field
                id="hour"
                name="escalationTimeoutMin"
                component={_number}
                className="form-control"
                min={0}
                placeholder={translator["Escalation Timeout (in min)"]}
              />
            </div>
          </Form.Group>
        </Col>
        <Col md={4} sm={4} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>
              <span className="rStar"></span>
              {translator["Communication Channel"]}
            </Form.Label>
            <div className="heightdvTypehd">
              <Field
                component={TypeaheadandDropdown}
                onSelection={onChannelSelection}
                onInputChange={onChannelValueChange}
                name="channels"
                className="form-control"
                errorClass={props.channelError === "error" ? "error" : ""}
                options={channelOptions}
                selectedOptions={props.selectedChannels}
                onCrossClick={() => {
                  props.setSelectedChannels([]);
                }}
                setErrorColor={setChannelErrorColor}
                multiple={true}
                placeholder={"Please choose.."}
              ></Field>
            </div>
          </Form.Group>
        </Col>
        <Col md={12}>
          <div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
            <div className="bgGray padding-5">
              <span> {translator["Notify"]} </span>
            </div>
            <Row className="padding-t-5">
              <Col md={4} sm={4} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label>{translator["User(s)"]}</Form.Label>
                  <div className="heightdvTypehd">
                    <Field
                      component={TypeaheadandDropdown}
                      onSelection={onUserValueSelection}
                      onInputChange={onUserValueChange}
                      name="user"
                      className="form-control"
                      errorClass={props.valueError === "error" ? "error" : ""}
                      options={usersOptions}
                      selectedOptions={props.selectedUsers}
                      onCrossClick={() => {
                        props.setSelectedUsers([]);
                      }}
                      multiple={true}
                      placeholder={"Please choose.."}
                      diableStatus={props.selectedCompany.length === 0}
                    ></Field>
                  </div>
                </Form.Group>
              </Col>
              {/* <Col md={4} sm={4} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label>{"Group(s)"}</Form.Label>
                  <div className="heightdvTypehd">
                    <Field
                      component={TypeaheadandDropdown}
                      onSelection={onGroupValueSelection}
                      onInputChange={onGroupValueChange}
                      name="groups"
                      className="form-control"
                      errorClass={props.groupError === "error" ? "error" : ""}
                      options={groupOptions}
                      selectedOptions={props.selectedGroups}
                      onCrossClick={() => {
                        props.setSelectedGroups([]);
                      }}
                      multiple={true}
                      placeholder={"Please choose.."}
                    ></Field>
                  </div>
                </Form.Group>
              </Col> */}
              <Col md={4} sm={4} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label>{translator["Emails"]}</Form.Label>
                  <Field
                    component={_inputField}
                    name="externalEmail"
                    className="form-control"
                  ></Field>
                  {/* <Form.Control
                    type="text"
                    className={
                      props.emailError ? "error form-control" : "form-control"
                    }
                    placeholder={translator["Enter comma-separated email ids"]}
                    name="emailIds"
                    value={props.emailIds}
                    onChange={onChangeHandle}
                  />
                  {props.emailError ? (
                    <div className="error">{"Please enter valid emails"}</div>
                  ) : (
                    ""
                  )} */}
                </Form.Group>
              </Col>

              {/* <Col md={4} sm={4} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label>{"Group Manager(s)"}</Form.Label>
                  <div className="heightdvTypehd">
                    <Field
                      id="Schedule"
                      name="groupManager"
                      component={_checkbox}
                      className="form-control"
                      checked={groupManager == "Yes"}
                      onChange={onChangeGroupMgr}
                    />
                  </div>
                </Form.Group>
              </Col> */}
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  onCallGroupList: state.onCallGroupList,
  parentFunctionByCompanyIdDropdown: state.parentFunctionByCompanyIdDropdown,
});

export default reduxForm({
  form: "CreateEscalationForm",
  destroyOnUnmount: true,
  validate,
  enableReinitialize: true,
})(connect(mapStateToProps)(CreateEscalationForm));
