
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import { GLOBAL }  from '../../components/Globals';
import constanturl from '../../../src/utils/constants.js';

export function loadBreakFixSGAEChangeSuccess(breakFixSGAEChangeName){
	return {
		type: 'LOAD_BREAKFIXSGAECHANGE_SUCCESS',
		breakFixSGAEChangeName
	};
}

export function loadBreakFixSGAEChangeFailure(errorResponse){
	return {
		type: 'LOAD_BREAKFIXSGAECHANGE_FAILURE',
		errorResponse
	};
}


export function loadBreakFixSGAEChange(serviceId,companyName,mod,service){
let url="";
if(mod=="ChangeManagement")
// if(service=="impactedCI")
// {
url="/rest/ruleConfiguration?companyId="+companyName+"&moduleId=10&eventId=30&ciId="+serviceId;
// }
// else{
// 	url="/rest/ruleConfiguration?companyId="+companyName+"&moduleId=10&eventId=30&serviceId="+serviceId;

// }

	return(dispatch,getState) =>{
		if(serviceId === ""){
			console.log("serviceId not found");
		}else{
			//alert(GLOBAL.breakFixSGroupUrl+serviceId)
			dispatch({
				type: 'LOAD_FIELD_VALUE_FOR_CHANGE',
				isFieldFetching:true
			});
				axios.get(url)
					.then((breakFixSGAEChangeName)=>{
						if(!breakFixSGAEChangeName){
							throw Error(breakFixSGAEChangeName.statusText);
						}
						return breakFixSGAEChangeName;
					})
					.then((breakFixSGAEChangeName) =>{
						if(GLOBAL.changepageLoad===true){
							let typeaheadData=[];//,currentEditChangeDetails=getState().editChangeInitialData, flag=false;
							if(breakFixSGAEChangeName.data!==''){
								breakFixSGAEChangeName.data.map((item)=>{
                                       typeaheadData.push({'value':item.assignmentGroupId,'label':item.assignmentGroupName, 'supportCompanyID': (item?.assignmentCompanyId || ''), 'supportCompanyName': (item?.assignmentCompanyName || '') });
									//    if(typeof currentEditChangeDetails.changeManagementGroup!=='undefined'){
									// 	   if(currentEditChangeDetails.changeManagementGroup+''===item.assignmentGroupId+''){
									// 		   flag=true;
									// 	   }
									//    }
								});
							}
							// if(GLOBAL.changeEdit===true && flag===false && typeof currentEditChangeDetails.changeManagementGroup!=='undefined'){
							// if(currentEditChangeDetails.changeManagementGroup!=='0' && currentEditChangeDetails.changeManagementGroup!=='' && currentEditChangeDetails.changeManagementGroup!==0 && currentEditChangeDetails.changeGroupname!==''){
							// 	typeaheadData.push({'value':currentEditChangeDetails.changeManagementGroup,'label':currentEditChangeDetails.changeGroupname});
							// }}
							dispatch({type:'LOAD_BREAKFIXSGAECHANGE_SUCCESS_TYPEAHED',typeaheadData});
						}
						dispatch(loadBreakFixSGAEChangeSuccess(breakFixSGAEChangeName))
						dispatch({
							type: 'LOAD_FIELD_VALUE_FOR_CHANGE',
							isFieldFetching:false
						});
					})
					.catch((err) => {
						console.log(err);
					});
				}

	};
}
export function loadNewBreakFixSGAEChange(serviceId,companyName,mod,service,amsAttribute={}){
	amsAttribute=JSON.stringify(amsAttribute);
	let url="";
	if(mod=="ChangeManagement")
	// if(service=="impactedCI")
	// {
	url="/rest/getRuleConfiguration?companyId="+companyName+"&moduleId=10&eventId=30";
	// }
	// else{
	// 	url="/rest/ruleConfiguration?companyId="+companyName+"&moduleId=10&eventId=30&serviceId="+serviceId;
	
	// }
	
		return(dispatch,getState) =>{
			if(serviceId === ""){
				console.log("serviceId not found");
			}else{
				//alert(GLOBAL.breakFixSGroupUrl+serviceId)
				dispatch({
					type: 'LOAD_FIELD_VALUE_FOR_CHANGE',
					isFieldFetching:true
				});
					axios.get(url,{ headers: { 'query': amsAttribute }})
						.then((breakFixSGAEChangeName)=>{
							if(!breakFixSGAEChangeName){
								throw Error(breakFixSGAEChangeName.statusText);
							}
							return breakFixSGAEChangeName;
						})
						.then((breakFixSGAEChangeName) =>{
							if(GLOBAL.changepageLoad===true){
								let typeaheadData=[];//,currentEditChangeDetails=getState().editChangeInitialData, flag=false;
								if(breakFixSGAEChangeName.data!==''){
									breakFixSGAEChangeName.data.map((item)=>{
										   typeaheadData.push({'value':item.assignmentGroupId,'label':item.assignmentGroupName, 'supportCompanyID': (item?.assignmentCompanyId || ''), 'supportCompanyName': (item?.assignmentCompanyName || '') });
										//    if(typeof currentEditChangeDetails.changeManagementGroup!=='undefined'){
										// 	   if(currentEditChangeDetails.changeManagementGroup+''===item.assignmentGroupId+''){
										// 		   flag=true;
										// 	   }
										//    }
									});
								}
								// if(GLOBAL.changeEdit===true && flag===false && typeof currentEditChangeDetails.changeManagementGroup!=='undefined'){
								// if(currentEditChangeDetails.changeManagementGroup!=='0' && currentEditChangeDetails.changeManagementGroup!=='' && currentEditChangeDetails.changeManagementGroup!==0 && currentEditChangeDetails.changeGroupname!==''){
								// 	typeaheadData.push({'value':currentEditChangeDetails.changeManagementGroup,'label':currentEditChangeDetails.changeGroupname});
								// }}
								dispatch({type:'LOAD_BREAKFIXSGAECHANGE_SUCCESS_TYPEAHED',typeaheadData});
							}
							dispatch(loadBreakFixSGAEChangeSuccess(breakFixSGAEChangeName))
							dispatch({
								type: 'LOAD_FIELD_VALUE_FOR_CHANGE',
								isFieldFetching:false
							});
						})
						.catch((err) => {
							console.log(err);
						});
					}
	
		};
	}
	