
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import MenuItem from "react-bootstrap/DropdownItem";
import ListGroup from "react-bootstrap/ListGroup";
import { browserHistory } from "react-router";
import swal from "sweetalert";
import axios from "axios";
import InnerPartWIBSearch from "./SearchInnerPart";
import PropTypes from "prop-types";
import EmailValidator from "email-validator";
const Joi = require("joi");
import { connect } from "react-redux";
import {IoSearch} from 'react-icons/io5';
import {
  setFulfillmentData,
  setIncidentData,
  setProblemData,
  setChangeData,
  setBreakfixQuickViewTableCarouselIndex,
} from "../../../../actions/breakFix/quickViewAction";
import { setwibGlobalSearchFilterData } from "../../../../actions/globalSearch/globalSearchAction";
import { updateWIBSearchData as updatePayload } from '../../../../utils/common';
import { navigationHooks } from "../../../../helpers/NavigationHook";
import { setWipSearchApply } from "../../../../actions/globalSearch/globalSearchAction";

import Cookies from "universal-cookie";
import { openLinkInNewTab } from "../../helper";
const cookies = new Cookies();
let app_url = cookies.get("gph");
if (app_url) app_url = app_url.replace("s:", "");
if (app_url) app_url = app_url.substring(0, app_url.lastIndexOf("."));
app_url = app_url.split("~");
app_url = app_url[18];

class WIBSearchInner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchItem: "Work Item ID",
      isModulePopup: false,
      activeModuleName: "",
      selectedModule: "",
      isActiveModuleBox: false,
      searchInput: "",
      calculatedChangeUserid: "",
      workItemIDsize: 15,
      searchCatName: ["Work Item ID", "Requestor Email", "Requestor Employee ID","External Reference Number"],
      modulesPopupName: [],
      enteredEmailID: "",
      enteredEmployeeID: "",
      placeholderMessage: "",
    };
    this.onCategoryMenuClick = this.onCategoryMenuClick.bind(this);
    this.onModulesMenuClick = this.onModulesMenuClick.bind(this);
    this.modulePopupFunction = this.modulePopupFunction.bind(this);
    this.callbackFun = this.callbackFun.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateSearchInputFun = this.updateSearchInputFun.bind(this);
    this.handleInputForWorkItemID = this.handleInputForWorkItemID.bind(this);
    this.getFulfillmentModuleData = this.getFulfillmentModuleData.bind(this);
    this.getIncidentModuleData = this.getIncidentModuleData.bind(this);
    this.getProblemModuleData = this.getProblemModuleData.bind(this);
    this.getChangeModuleData = this.getChangeModuleData.bind(this);
    this.getTaskModuleData = this.getTaskModuleData.bind(this);
    this.generalValidations = this.generalValidations.bind(this);
    this.formatValidations = this.formatValidations.bind(this);
    this.prepareFulfillmentPayload = this.prepareFulfillmentPayload.bind(this);
    this.prepareIncidentPayload = this.prepareIncidentPayload.bind(this);
    this.prepareProblemPayload = this.prepareProblemPayload.bind(this);
    this.prepareChangePayload = this.prepareChangePayload.bind(this);
    this.subChangePayloadPrepration =
      this.subChangePayloadPrepration.bind(this);
    this.handleEnteredEmail = this.handleEnteredEmail.bind(this);
    this.handleEmployeeID = this.handleEmployeeID.bind(this);
    this.handleExternalAttribute = this.handleExternalAttribute.bind(this);
    this.handleWorkItemID = this.handleWorkItemID.bind(this);
    this.selectWIBModule = this.selectWIBModule.bind(this);
    this.getrequestorIviaEmailIDorEmployeeID =
      this.getrequestorIviaEmailIDorEmployeeID.bind(this);
  }
  componentWillMount() {
    let globalWIBmoduleList = [],
      count = 0,
      countforTask=0,countforD2C=0;
      let service_desk_role_enabled = this.props.roleIdentification('48');
      let task_viewer_role_enabled = this.props.roleIdentification('61');
      let task_roles_enabled = service_desk_role_enabled || task_viewer_role_enabled;

      let fulfillment_manager_role_enabled = this.props.roleIdentification("44");
      let fulfillment_user_role_enabled = this.props.roleIdentification("43");
      let fulfillment_viewer_role_enabled = this.props.roleIdentification('62');
      let core_fulfillment_roles_enabled = fulfillment_manager_role_enabled || fulfillment_user_role_enabled || service_desk_role_enabled;
      let fulfillment_roles_enabled = core_fulfillment_roles_enabled || fulfillment_viewer_role_enabled;
      let only_fulfillment_viewer_role_enabled = !core_fulfillment_roles_enabled && fulfillment_viewer_role_enabled;
      let fulfillment_search_all = fulfillment_manager_role_enabled || service_desk_role_enabled || only_fulfillment_viewer_role_enabled;
      this.setState({fulfillment_search_all, fulfillment_roles_enabled});
      
      let incident_manager_role_enabled = this.props.roleIdentification("13");
      let incident_user_role_enabled = this.props.roleIdentification("12");
      let critical_incident_manager_enabled = this.props.roleIdentification("14");
      let incident_viewer_role_enabled = this.props.roleIdentification("63");
      let core_incident_roles_enabled = incident_manager_role_enabled || incident_user_role_enabled || critical_incident_manager_enabled || service_desk_role_enabled;
      let incident_roles_enabled = core_incident_roles_enabled || incident_viewer_role_enabled;
      let only_incident_viewer_role_enabled = !core_incident_roles_enabled && incident_viewer_role_enabled;
      let incident_search_all = incident_manager_role_enabled || critical_incident_manager_enabled || service_desk_role_enabled || only_incident_viewer_role_enabled;
      this.setState({incident_search_all, incident_roles_enabled});

      let problem_manager_role_enabled = this.props.roleIdentification("17");
      let problem_user_role_enabled = this.props.roleIdentification("16");
      let problem_investigator_role_enabled = this.props.roleIdentification("53");
      let problem_viewer_role_enabled = this.props.roleIdentification('64');
      let core_problem_roles_enabled = problem_manager_role_enabled || problem_user_role_enabled || problem_investigator_role_enabled || service_desk_role_enabled;
      let problem_roles_enabled = core_problem_roles_enabled || problem_viewer_role_enabled;
      let only_problem_viewer_role_enabled = !core_problem_roles_enabled || problem_viewer_role_enabled;
      let problem_search_all = problem_manager_role_enabled || service_desk_role_enabled || only_problem_viewer_role_enabled;
      let problem_all_payload = problem_manager_role_enabled || service_desk_role_enabled || problem_investigator_role_enabled || only_problem_viewer_role_enabled;
      this.setState({problem_search_all, problem_all_payload, problem_user_role_enabled, problem_roles_enabled});

      let change_manager_role_enabled = this.props.roleIdentification("20");
      let change_user_role_enabled = this.props.roleIdentification("19");
      let implementation_manager_role_enabled = this.props.roleIdentification("52");
      let implementation_user_role_enabled = this.props.roleIdentification("51");
      let change_viewer_role_enabled = this.props.roleIdentification('65');
      let core_change_roles_enabled = change_manager_role_enabled || change_user_role_enabled || implementation_manager_role_enabled || implementation_user_role_enabled || service_desk_role_enabled; 
      let change_roles_enabled = core_change_roles_enabled || change_viewer_role_enabled;
      let only_change_viewer_role_enabled = !core_change_roles_enabled || change_viewer_role_enabled;
      let change_all_payload = change_manager_role_enabled || implementation_manager_role_enabled || service_desk_role_enabled || only_change_viewer_role_enabled;
      this.setState({change_all_payload, change_roles_enabled, change_user_role_enabled});

      let core_d2c_roles_enabled = core_fulfillment_roles_enabled || core_incident_roles_enabled || core_problem_roles_enabled || core_change_roles_enabled;
      let only_task_viewer_role_enabled = (!core_d2c_roles_enabled) && (task_viewer_role_enabled);
      let show_task = task_roles_enabled || only_task_viewer_role_enabled || core_d2c_roles_enabled;
      this.setState({only_task_viewer_role_enabled, show_task});
      if(show_task){
        globalWIBmoduleList.push({"name":"Task","key":"TSK"});
        count++;
        countforTask++;
    }
    if (fulfillment_roles_enabled) {
      globalWIBmoduleList.push({ name: "Fulfillment", key: "ITM" });
      count++;
      countforD2C++;
    }
    if (incident_roles_enabled) {
      globalWIBmoduleList.push({ name: "Incident", key: "INC" });
      count++;
      countforD2C++;
    }
    if (problem_roles_enabled) {
      globalWIBmoduleList.push({ name: "Problem", key: "PRB" });
      count++;
      countforD2C++;
    }
    if (change_roles_enabled) {
      globalWIBmoduleList.push({ name: "Change", key: "RFC" });
      count++;
      countforD2C++;
    }
    if(!countforD2C){
      this.setState({searchCatName: ["Work Item ID"]});
    }
    if (count > 0) {
      this.setState({
        modulesPopupName: globalWIBmoduleList,
        activeModuleName: globalWIBmoduleList[0].key,
        selectedModule: globalWIBmoduleList[0].name,
        placeholderMessage: 'Search here...',
      });
    }
  }
  getFulfillmentModuleData(fulfillmentPayload) {
    const payload = updatePayload(fulfillmentPayload, 'fulfillment');
    axios
      .post("/api/orderFullfilmentQuickViewAction/search", payload)
      .then((responseFulfillment) => {
        if (responseFulfillment.data.data.meta.recordCount === 0) {
          swal({
            text: this.props.tr["No matching Work Item record found"],
            button: this.props.tr["OK"],
          });
        } else if (
          this.state.searchItem === "Work Item ID" &&
          responseFulfillment.data.data.meta.recordCount === 1
        ) {
          openLinkInNewTab(app_url + "/home?fulFillment=" + responseFulfillment.data.data.data[0].itemId, "New Tab");
        } else {
          this.props.setBreakfixQuickViewTableCarouselIndex("1");
          this.props.setFulfillmentData(responseFulfillment);
          navigationHooks.navigate("/quickViewBreakFix");
        }
      })
      .catch((errorFulfillment) => {});
  }
  prepareFulfillmentPayload(
    partialfullfillmentPayload,
    fulfillmentEmployeeID = ""
  ) {
    let fulfillmentCondition =
      this.state.searchItem === "Requestor Email" ||
      this.state.searchItem === "Requestor Employee ID";
    let isITMnumber =
      this.state.searchInput.substring(0, 3) === "ITM"
        ? "itemNumber"
        : "orderNumber";
    let isExternalAttribute=this.state.searchItem==='External Reference Number'?true:false;
    let fulfillmentSearchIDtype = isExternalAttribute ? 'clientReferenceNumber' : fulfillmentCondition
      ? "requesterId"
      : isITMnumber;
    if (this.state.fulfillment_search_all) {
      partialfullfillmentPayload.searchByList = fulfillmentSearchIDtype;
      partialfullfillmentPayload.multipleValueList = fulfillmentCondition
        ? fulfillmentEmployeeID
        : this.state.searchInput;
    } else {
      partialfullfillmentPayload.searchByList =
        fulfillmentSearchIDtype + ",asgGroupid";
      partialfullfillmentPayload.multipleValueList =
        (fulfillmentCondition
          ? fulfillmentEmployeeID
          : this.state.searchInput) +
        "|" +
        this.props.group_id;
    }
    this.props.setwibGlobalSearchFilterData({
      selectedGlobalDropdown:
        this.state.searchItem === "Work Item ID" ? "" : this.state.searchItem,
      calculatedTypeToSend: fulfillmentSearchIDtype,
      enteredGlobalSearchValue: this.state.searchInput,
      retrivedGlobalSearchEmployeeValue: isExternalAttribute ? this.state.searchInput : fulfillmentEmployeeID,
      filterTabActive:
      this.state.fulfillment_search_all
          ? 3
          : 2,
      selectedGlobalModule: "fullfilment",
      isWIBGlobalSearch: true,
    });
    return partialfullfillmentPayload;
  }
  getIncidentModuleData(incidentPayload) {
    const payload = updatePayload(incidentPayload, 'incident');
    axios
      .post("/api/breakfixQuickViewAction/search", payload)
      .then((responseIncident) => {
        if (responseIncident.data.data.meta.recordCount === 0) {
          swal({
            text: this.props.tr["No matching Work Item record found"],
            button: this.props.tr["OK"],
          });
        } else if (
          this.state.searchItem === "Work Item ID" &&
          responseIncident.data.data.meta.recordCount === 1
        ) {
          openLinkInNewTab(app_url + "/home?editBreakFix=" + responseIncident.data.data.data[0].breakfixId, "New Tab");
        } else {
          this.props.setBreakfixQuickViewTableCarouselIndex("0");
          this.props.setIncidentData(responseIncident);
          navigationHooks.navigate("/quickViewBreakFix");
        }
      })
      .catch((errorIncident) => {});
  }
  prepareIncidentPayload(partialincidentPayload, incidentEmployeeID = "") {
    let incidentCondition =
      this.state.searchItem === "Requestor Email" ||
      this.state.searchItem === "Requestor Employee ID";
    let isINCnumber =
      this.state.searchInput.substring(0, 3) === "INC"
        ? "breakfixNumber"
        : "requestNumber";
    let isExternalAttribute=this.state.searchItem==='External Reference Number'?true:false;
    let incidentSearchIDtype = isExternalAttribute? 'clientReferenceNumber' : incidentCondition
      ? "impactedUserid"
      : isINCnumber;
    if (this.state.incident_search_all) {
      partialincidentPayload.searchByList = incidentSearchIDtype;
      partialincidentPayload.multipleValueList = incidentCondition
        ? incidentEmployeeID
        : this.state.searchInput;
    } else {
      partialincidentPayload.searchByList = incidentSearchIDtype + ",spGroupid";
      partialincidentPayload.multipleValueList =
        (incidentCondition ? incidentEmployeeID : this.state.searchInput) +
        "|" +
        this.props.group_id;
    }
    this.props.setwibGlobalSearchFilterData({
      selectedGlobalDropdown:
        this.state.searchItem === "Work Item ID" ? "" : this.state.searchItem,
      calculatedTypeToSend: incidentSearchIDtype,
      enteredGlobalSearchValue: this.state.searchInput,
      retrivedGlobalSearchEmployeeValue: isExternalAttribute ? this.state.searchInput : incidentEmployeeID,
      filterTabActive:
      this.state.incident_search_all
          ? 3
          : 2,
      selectedGlobalModule: "breakfix",
      isWIBGlobalSearch: true,
    });
    return partialincidentPayload;
  }
  getProblemModuleData(problemPayload) {
    const payload = updatePayload(problemPayload, 'problem');
    axios
      .post("/serviceManagement/rest/investigationList", payload)
      .then((responseProblem) => {
        if (responseProblem.data.meta.recordCount === 0) {
          swal({
            text: this.props.tr["No matching Work Item record found"],
            button: this.props.tr["OK"],
          });
        } else if (
          this.state.searchItem === "Work Item ID" &&
          responseProblem.data.meta.recordCount === 1
        ) {
          openLinkInNewTab(app_url + "/home?investigationEdit=" + responseProblem.data.data[0].investigationId, "New Tab");
        } else {
          this.props.setBreakfixQuickViewTableCarouselIndex("2");
          this.props.setProblemData(responseProblem);
          navigationHooks.navigate("/quickViewBreakFix");
        }
      })
      .catch((errorProblem) => {});
  }
  prepareProblemPayload(partialproblemPayload, problemEmployeeID = "") {
    let problemCondition =
      this.state.searchItem === "Requestor Email" ||
      this.state.searchItem === "Requestor Employee ID";
    let isPRBnumber =
      this.state.searchInput.substring(0, 3) === "PRB"
        ? "investigationNumber"
        : "requestNumber";
    let isExternalAttribute=this.state.searchItem==='External Reference Number'?true:false;
    let problemSearchIDtype = isExternalAttribute ? 'clientReferenceNumber' : problemCondition ? "impactedUserid" : isPRBnumber;
    if (this.state.problem_all_payload) {
      partialproblemPayload.searchByList = problemSearchIDtype;
      partialproblemPayload.multipleValueList = problemCondition
        ? problemEmployeeID
        : this.state.searchInput;
    } else {
      partialproblemPayload.searchByList =
        problemSearchIDtype + ",asgPmGroupid";
      partialproblemPayload.multipleValueList =
        (problemCondition ? problemEmployeeID : this.state.searchInput) +
        "|" +
        this.props.group_id;
    }
    let setSubModuleNumber = this.state.problem_user_role_enabled ? 2 : 1;
    this.props.setwibGlobalSearchFilterData({
      selectedGlobalDropdown:
        this.state.searchItem === "Work Item ID" ? "" : this.state.searchItem,
      calculatedTypeToSend: problemSearchIDtype,
      enteredGlobalSearchValue: this.state.searchInput,
      retrivedGlobalSearchEmployeeValue: isExternalAttribute ? this.state.searchInput : problemEmployeeID,
      filterTabActive:
      this.state.problem_search_all
          ? 3
          : setSubModuleNumber,
      selectedGlobalModule: "investigation",
      isWIBGlobalSearch: true,
    });
    return partialproblemPayload;
  }
  getChangeModuleData(changePayload) {
    const payload = updatePayload(changePayload, 'change');
    axios
      .post("/serviceManagement/rest/getChangeManagementList", payload)
      .then((responseChange) => {
        if (responseChange.data.meta.recordCount === 0) {
          swal({
            text: this.props.tr["No matching Work Item record found"],
            button: this.props.tr["OK"],
          });
        } else if (
          this.state.searchItem === "Work Item ID" &&
          responseChange.data.meta.recordCount === 1
        ) {
          openLinkInNewTab(app_url + "/home?editChange=" + responseChange.data.data[0].changeId, "New Tab");
        } else {
          this.props.setBreakfixQuickViewTableCarouselIndex("3");
          this.props.setChangeData(responseChange);
          navigationHooks.navigate("/quickViewBreakFix");
        }
      })
      .catch((errorChange) => {});
  }
  prepareChangePayload(partialChangePayload, changeEmployeeID = "") {
    let changeCondition =
      this.state.searchItem === "Requestor Email" ||
      this.state.searchItem === "Requestor Employee ID";
    let isRFCnumber =
      this.state.searchInput.substring(0, 3) === "RFC"
        ? "changeNumber"
        : "requestNumber";
    let isExternalAttribute=this.state.searchItem==='External Reference Number'?true:false;
    let changeSearchIDtype = isExternalAttribute ? 'clientReferenceNumber' : changeCondition ? "requesterId" : isRFCnumber;
    if (this.state.change_all_payload) {
      partialChangePayload.searchByList = changeSearchIDtype;
      partialChangePayload.multipleValueList = changeCondition
        ? changeEmployeeID
        : this.state.searchInput;
    } else {
      this.subChangePayloadPrepration(
        partialChangePayload,
        changeCondition,
        changeSearchIDtype,
        changeEmployeeID
      );
    }
    this.props.setwibGlobalSearchFilterData({
      selectedGlobalDropdown:
        this.state.searchItem === "Work Item ID" ? "" : this.state.searchItem,
      calculatedTypeToSend: changeSearchIDtype,
      enteredGlobalSearchValue: this.state.searchInput,
      retrivedGlobalSearchEmployeeValue: isExternalAttribute ? this.state.searchInput : changeEmployeeID,
      filterTabActive:
      this.state.change_all_payload
          ? 3
          : 2,
      selectedGlobalModule: "changeManagement",
      isWIBGlobalSearch: true,
    });
    return partialChangePayload;
  }
  subChangePayloadPrepration(
    subChangePayload,
    changeCondition,
    changeSearchIDtype,
    changeEmployeeID = ""
  ) {
    switch (this.props.changeImplentationRole) {
      case "changeUserid":
        subChangePayload.searchByList = changeSearchIDtype + ",changeGroupid";
        subChangePayload.multipleValueList =
          (changeCondition ? changeEmployeeID : this.state.searchInput) +
          "|" +
          this.props.group_id;
        break;
      case "impUserid":
        subChangePayload.searchByList = changeSearchIDtype + ",impGroupid";
        subChangePayload.multipleValueList =
          (changeCondition ? changeEmployeeID : this.state.searchInput) +
          "|" +
          this.props.group_id;
        break;
      case "impUserid,changeUserid":
        subChangePayload.searchByList =
          changeSearchIDtype + ",impGroupid,changeGroupid";
        subChangePayload.operatorList =
          (changeCondition ? "in" : "and") + ",or";
        subChangePayload.multipleValueList =
          (changeCondition ? changeEmployeeID : this.state.searchInput) +
          "|" +
          this.props.group_id +
          "|" +
          this.props.group_id;
        break;
    }
    return subChangePayload;
  }
  getTaskModuleData(taskHeader) {
    axios
      .get("/api/tasks/", { headers: { query: JSON.stringify(taskHeader) } })
      .then((responseTask) => {
        if (responseTask.data.data.length === 1) {
          openLinkInNewTab(app_url +
            "/home?editTask=" +
            responseTask.data.data[0].taskCode +
            "/" +
            responseTask.data.data[0].clientId +
            "/" +
            responseTask.data.data[0].assignedToGroupId, "New Tab");
        } else {
          swal({
            text: this.props.tr["No matching Work Item record found"],
            button: this.props.tr["OK"],
          });
        }
      })
      .catch((errorTask) => {});
  }
  updateSearchInputFun(e){
    this.setState({ searchInput: e.target.value });
    if(this.state.searchItem === 'Work Item ID'){
        this.handleInputForWorkItemID(e.target.value);
    }
  }
  handleInputForWorkItemID(input){
		if(input.length > 3) {
			if(input.slice(0,3)==='TSK' && input.length===15 && this.state.show_task){
				this.setState({
					activeModuleName: 'TSK',
					selectedModule: 'Task',
				});
			} else if(input.slice(0,3)==='ITM' && input.length===15 && this.state.fulfillment_roles_enabled){
				this.setState({
					activeModuleName: 'ITM',
					selectedModule: 'Fulfillment',
				});
			} else if(input.slice(0,3)==='INC' && input.length===15 && this.state.incident_roles_enabled) {
				this.setState({
					activeModuleName: 'INC',
					selectedModule: 'Incident',
				});
			} else if(input.slice(0,3)==='PRB' && input.length===15 && this.state.problem_roles_enabled) {
				this.setState({
					activeModuleName: 'PRB',
					selectedModule: 'Problem',
				});
			} else if(input.slice(0,3)==='RFC' && input.length===15 && this.state.change_roles_enabled) {
				this.setState({
					activeModuleName: 'RFC',
					selectedModule: 'Change',
				});
			} else {
				this.setState({
					activeModuleName: '',
					selectedModule: '',
				});
			}
		}
	}
  modulePopupFunction(val, fieldType) {
    this.setState({ isModulePopup: this.state.searchItem === "Work Item ID" ? false : val });
    if (fieldType === "crossbutton") {
      this.setState({ searchInput: "" });
    }
    this.setState({ isActiveModuleBox: false });
  }
  onCategoryMenuClick(val) {
    if (val === "Work Item ID") {
      this.setState({
        activeModuleName: this.state.modulesPopupName[0].key,
        selectedModule: this.state.modulesPopupName[0].name,
        placeholderMessage: 'Search here...',
      });
    } else {
      this.setState({
        activeModuleName: this.state.modulesPopupName[this.state.show_task?1:0].key,
        selectedModule: this.state.modulesPopupName[this.state.show_task?1:0].name,
        placeholderMessage:
        val === "External Reference Number" ? this.props.tr['Enter External Reference Number'] : val === "Requestor Email"
            ? this.props.tr["example"] + "@" + this.props.tr["domain"] + ".com"
            : this.props.tr["Enter Employee ID"],
      });
    }
    this.setState({ searchItem: val });
    this.setState({ searchInput: "" });
    this.setState({ isActiveModuleBox: false });
  }
  onModulesMenuClick(val, moduleName) {
    switch (this.state.searchItem) {
      case "Work Item ID":
        this.setState({ placeholderMessage: 'Search here...' });
        break;
      case "Requestor Email":
        this.setState({
          placeholderMessage:
            this.props.tr["example"] + "@" + this.props.tr["domain"] + ".com",
        });
        break;
      case "Requestor Employee ID":
        this.setState({
          placeholderMessage: this.props.tr["Enter Employee ID"],
        });
        break;
      case 'External Reference Number': this.setState({ placeholderMessage: this.props.tr['Enter External Reference Number']});
        break;
    }
    this.setState({ activeModuleName: val });
    this.setState({ selectedModule: moduleName });
    this.setState({ isActiveModuleBox: true });
    this.setState({ isModulePopup: false });
  }
  callbackFun(val) {
    this.setState({ isModulePopup: val });
  }
  generalValidations() {
    let namedSchema = Joi.string().regex(
      /^[^!@#$-%^&*}{\[\]\\\`_+=|\/\:;\'\",<.>?~]+$/
    );
    let validator_to_add_myQbj = namedSchema.validate(this.state.searchInput);
    if (this.state.searchInput.trim().length === 0) {
      swal({
        text: this.props.tr["Work Item ID cannot be empty"],
        button: this.props.tr["OK"],
      });
      return false;
    } else if (this.state.searchInput.length !== this.state.workItemIDsize) {
      swal({
        text:
          this.props.tr["Work Item ID length must be equal to"] +
          " " +
          this.state.workItemIDsize +
          " " +
          this.props.tr["characters (in length)"],
        button: this.props.tr["OK"],
      });
      return false;
    } else if (validator_to_add_myQbj.error !== null) {
      swal({
        text: this.props.tr[
          "Special characters are not allowed in Work Item ID"
        ],
        button: this.props.tr["OK"],
      });
      return false;
    }
    return true;
  }
  formatValidations() {
    let namedSchema = Joi.string().regex(/^[0123456789]+$/);
    let validator_to_add_myQbj = namedSchema.validate(
      this.state.searchInput.substring(4, this.state.searchInput.length)
    );
    if (
      this.state.searchInput.substring(0, 3) !== this.state.activeModuleName
    ) {
      swal({
        text:
          this.props.tr["Work Item ID must have valid format"] +
          " (" +
          this.props.tr["eg"] +
          ": TSK/ITM/INC/PRB/RFC *)",
        button: this.props.tr["OK"],
      });
      return false;
    } else if (validator_to_add_myQbj.error !== null) {
      swal({
        text:
          this.props.tr["Work Item ID must have valid format"] +
          " (" +
          this.props.tr["eg"] +
          ": *01234)",
        button: this.props.tr["OK"],
      });
      return false;
    }
    return true;
  }
  handleWorkItemID() {
    if (this.generalValidations()) {
      if (this.formatValidations()) {
        this.selectWIBModule("");
      }
    }
  }
  handleEnteredEmail() {
    let namedSchema = Joi.string().regex(/^[^#\[\]\\\`|\/\:;\",<>]+$/);
    let validator_to_add_myQbj = namedSchema.validate(this.state.searchInput);
    if (this.state.searchInput.trim().length === 0) {
      swal({
        text: this.props.tr["Requestor Email ID cannot be empty"],
        button: this.props.tr["OK"],
      });
    } else if (validator_to_add_myQbj.error !== null) {
      swal({
        text: this.props.tr[
          "Requestor Email ID cannot contains special characters"
        ],
        button: this.props.tr["OK"],
      });
    } else if (!EmailValidator.validate(this.state.searchInput)) {
      swal({
        text: this.props.tr["Please enter a valid email address"],
        button: this.props.tr["OK"],
      });
    } else {
      this.getrequestorIviaEmailIDorEmployeeID({
        email: this.state.searchInput,
      });
    }
  }
  handleEmployeeID() {
    let namedSchema = Joi.string().regex(
      /^[^!@#$-%^&*}{\[\]\\\`_+=|\/\:;\'\",<.>?~]+$/
    );
    let validator_to_add_myQbj = namedSchema.validate(this.state.searchInput);
    if (this.state.searchInput.trim().length === 0) {
      swal({
        text: this.props.tr["Requestor Employee ID cannot be empty"],
        button: this.props.tr["OK"],
      });
    } else if (validator_to_add_myQbj.error !== null) {
      swal({
        text: this.props.tr[
          "Requestor Employee ID cannot contain special characters"
        ],
        button: this.props.tr["OK"],
      });
    } else {
      this.getrequestorIviaEmailIDorEmployeeID({
        employeeNumber: this.state.searchInput,
      });
    }
  }
  handleExternalAttribute(){
		let namedSchema=Joi.string().regex(/^[^!@#$-%^&*}{\[\]\\\`_+=|\/\:;\'\",<.>?~]+$/);
		let validator_to_add_myQbj=namedSchema.validate(this.state.searchInput);
		if(this.state.searchInput.trim().length===0){
			swal({
				text: this.props.tr['External Reference Number cannot be empty'],
				button: this.props.tr['OK']
			});
		}else if(this.state.searchInput.trim().length>25){
      swal({
        text: this.props.tr['External Reference Number size should be less than 25 characters'],
        button: this.props.tr['OK']
      });
    }else if(validator_to_add_myQbj.error!==null){
            swal({ 
				text: this.props.tr['External Reference Number cannot contain special characters'],
				button: this.props.tr['OK']
			});	
		}else{
        this.selectWIBModule('');
		}
	}
  handleSubmit() {
    this.props.setWipSearchApply(false);
    switch (this.state.searchItem) {
      case "Requestor Email":
        this.handleEnteredEmail();
        break;
      case "Requestor Employee ID":
        this.handleEmployeeID();
        break;
      case "Work Item ID":
        this.handleWorkItemID();
        break;
      case "External Reference Number": this.handleExternalAttribute();
        break;
    }
  }
  selectWIBModule(userID) {
    let commonPayload = {
      patternList: "in",
      size: 10,
      currentPage: 0,
      sortBy: "createdOn",
      orderBy: "desc",
      isNestedRequired: "N",
    };
    if(this.state.searchItem === 'External Reference Number'){
			commonPayload.externalAttributes = true;
		}
    switch (this.state.selectedModule) {
      case "Task": let taskPayload = this.state.only_task_viewer_role_enabled ? {taskCode: this.state.searchInput,  "requestType":"globalSearch" ,companyId: []} : {taskCode: this.state.searchInput, "requestType":"globalSearch" ,assignedToGroupId: [],};
        this.getTaskModuleData(taskPayload);
        break;
      case "Fulfillment":
        commonPayload = this.prepareFulfillmentPayload(commonPayload, userID);
        this.getFulfillmentModuleData(commonPayload);
        break;
      case "Incident":
        commonPayload = this.prepareIncidentPayload(commonPayload, userID);
        this.getIncidentModuleData(commonPayload);
        break;
      case "Problem":
        commonPayload = this.prepareProblemPayload(commonPayload, userID);
        this.getProblemModuleData(commonPayload);
        break;
      case "Change":
        commonPayload = this.prepareChangePayload(commonPayload, userID);
        this.getChangeModuleData(commonPayload);
        break;
    }
  }
  getrequestorIviaEmailIDorEmployeeID(searchedID) {
    axios
      .get("/api/getuserid", { headers: { query: JSON.stringify(searchedID) } })
      .then((retrivedUserID) => {
        if (retrivedUserID.data === "") {
          swal({
            text: this.props.tr["No matching Work Item record found"],
            button: this.props.tr["OK"],
          });
        } else {
          this.selectWIBModule(retrivedUserID.data);
        }
      })
      .catch((errorInRetrivingID) => {});
  }

  render() {
    let renderSearchCatName = this.state.searchCatName.map((category) => {
      return (
        <MenuItem
          title={category}
          href="javascript:void(0)"
          onClick={(e) => {
            this.onCategoryMenuClick(category);
          }}
          key={category}
        >
          {category}
        </MenuItem>
      );
    });
    let renderModulesPopupName = this.state.modulesPopupName.map((module) => {
      return (this.state.searchItem === 'External Reference Number' || this.state.searchItem === "Requestor Email" ||
        this.state.searchItem === "Requestor Employee ID") &&
        module.name === "Task" ? null : (
        <ListGroup.Item
          as="a"
          title={this.props.tr[module.name]}
          href="javascript:void(0)"
          onClick={(e) => {
            this.onModulesMenuClick(module.key, module.name);
          }}
          key={module.name}
        >
          {this.props.tr[module.name]}
        </ListGroup.Item>
      );
    });
    return (
      <div className="wibsrch">
        <div className="innerdv">
          <Nav className="catename">
            <NavDropdown title={this.state.searchItem}>
              {renderSearchCatName}
            </NavDropdown>
          </Nav>
          <InnerPartWIBSearch
            tr={this.props.tr}
            searchItem={this.state.searchItem}
            updateSearchInputFun={this.updateSearchInputFun}
            searchInput={this.state.searchInput}
            renderModulesPopupNameList={renderModulesPopupName}
            fun={this.callbackFun}
            modulePopupFunction={this.modulePopupFunction}
            isModulePopup={this.state.isModulePopup}
            isActiveModuleBox={this.state.isActiveModuleBox}
            activeModuleName={this.state.activeModuleName}
            placeholderMessage={this.state.placeholderMessage}
            selectedModule={this.state.selectedModule}
            handleSubmit={this.handleSubmit}
          />
          <a
            tabIndex="0"
            role="button"
            title={this.props.tr["Search"]}
            className="faicn"
            href="javascript:void(0)"
            onClick={this.handleSubmit}
          >
            <IoSearch/>
          </a>
        </div>
      </div>
    );
  }
}
WIBSearchInner.propTypes = {
  tr: PropTypes.object,
  roleIdentification: PropTypes.func,
  setFulfillmentData: PropTypes.func,
  setIncidentData: PropTypes.func,
  setProblemData: PropTypes.func,
  setChangeData: PropTypes.func,
  group_id: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  changeImplentationRole: PropTypes.string,
  setBreakfixQuickViewTableCarouselIndex: PropTypes.func,
  setwibGlobalSearchFilterData: PropTypes.func,
};
export default connect(null, {
  setFulfillmentData,
  setIncidentData,
  setProblemData,
  setChangeData,
  setwibGlobalSearchFilterData,
  setBreakfixQuickViewTableCarouselIndex,
  setWipSearchApply
})(WIBSearchInner);
