
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { getAllRuleConfigurationsData } from '../../../actions/amsActions/amsActions.js';

import Breadcrumbs from '../../common/header/breadcrumbs.js';

import { LuFilterX } from "react-icons/lu";
import { HiPlus, HiOutlineRefresh } from "react-icons/hi";


const AmsHomeHeader = (props) => {
	const dispatch = useDispatch();
	const tr = useSelector(state => state.spcmReducer.tr);

	return (
		<div>
			<Row className="margin-b-15">
				<Col lg={8} md={8} sm={9} xs={8}>
					<Breadcrumbs activePageName="Assignment Rules" />
				</Col>

				<Col lg={4} md={4} sm={3} xs={4}>
					
				</Col>
			</Row>
			<Row className="margin-b-15">
				<Col lg={4} md={6} sm={6} xs={12}>
					<h2 bsClass="" className="sPageHeading1">{tr['Assignment Rules']} </h2>
				</Col>
				<Col lg={8} md={6} sm={6} xs={12}>
					<div className="paHedFilter">
						{/* <NavLink role="button" className="myBt plus fillBtn" title={tr['Add']} to="/amsCreate" ><HiPlus /></NavLink> */}
						<NavLink role="button" className="myBt plus fillBtn" title={tr['Add']} to="/rulesCreate" ><HiPlus /></NavLink>
						<div className="overlayDv">
							{props.isClearAllfilter ? <Button title="Clear All Filters" role="button" bsPrefix=' ' className="myBt clrbtn"><LuFilterX /></Button> : null}
						</div>
						<Button bsPrefix=' ' title={tr["Refresh"]} role="button" className="myBt"><HiOutlineRefresh className="refreshFlip" /></Button>
					</div>
				</Col>
			</Row>
		</div>
	)
}

export default AmsHomeHeader;

