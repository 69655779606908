
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Form,
} from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { Field, change, reduxForm } from "redux-form";

import { useParams } from "react-router";
import ListLoader from "../../../common/loaders/ListLoader";
import { _inputField, _number,_dropDown } from "../../../common/formFields";
import { getTOAEditFormDetails } from "../../../../actions/spcmActions";

let TOAForm = (props) => {
  const tr = useSelector((state) => state.spcmReducer.tr);
  const params = useParams();
  const dispatch = useDispatch();
  const initialValue = useSelector((state) => state.spcmReducer.toaEditList);

  const loader = useSelector((state) => state.commonLoader);
  let hostOptions = [
    {field1Key: 'Foundation', field1Value: 'xsmf'},
    {field1Key: 'CMDB', field1Value: 'cmdb'},
    {field1Key: 'SPCM', field1Value: 'spcm'},
    {field1Key: 'Rules', field1Value: 'rules'},
    {field1Key: 'D2C', field1Value: 'd2c'},
    {field1Key: 'TMS', field1Value: 'tms'},
    {field1Key: 'AIOSEM', field1Value: 'aiosem'},
    {field1Key: 'Approval Engine', field1Value: 'approval'}
  ];
  let attriTypeOptions = [
    {field1Key: 'Dropdown (Typeahead)', field1Value: 'dropdown+typeahead'},
    {field1Key: 'Dropdown', field1Value: 'dropdown'},
    {field1Key: 'Textbox', field1Value: 'textbox'},
    {field1Key: 'Typeahead', field1Value: 'typeahead'},
    {field1Key: 'Dropdown (multiselect)', field1Value: 'multiselect'}
  ];
  let methodOptions = [
    {field1Key: 'GET', field1Value: 'GET'},
    {field1Key: 'POST', field1Value: 'POST'},
    {field1Key: 'PATCH', field1Value: 'PATCH'},
    {field1Key: 'PUT', field1Value: 'PUT'},
    
  ];

  const validateTOAEditField = (fieldType, event) => {
    console.log("JWM1",event)
    console.log("JWM2",fieldType)

    switch (fieldType) {
      case "status":
        if ( event.target.value == "") {
          props.setStatusErr("error");
        } else {
          props.setStatusErr("");
        }
        break;
      case "methodType":
        if (props.isActive && event.target.value == "") {

          props.setMethodErr("error");
        } else {
          props.setMethodErr("");
        }
        break;
      case "attributeValueType":
        if (props.isActive && event.target.value == "") {

          props.setAttributeValueTypeErr("error");
        } else {
          props.setAttributeValueTypeErr("");
        }
        break;
      case "apiHost":
        if (props.isActive && event.target.value == "") {

          props.setAPIHostErr("error");
        } else {
          props.setAPIHostErr("");
        }
        break;
      case "attributeKey":
        if (props.isActive && event.target.value.trim() == "") {

          props.setAttributeKeyErr("error");
        } else {
          props.setAttributeKeyErr("");
        }
        break;
      case "attrLength":

        if (props.isActive && (!event.target.value || parseInt(event.target.value, 10) < 1 || parseInt(event.target.value, 10) >1000)) {

          props.setAttributeLengthErr("error");
        } else {
          props.setAttributeLengthErr("");
        }
        break;
      case "apiEndPoint":
        if (props.isActive && event.target.value.trim() == "") {

          props.setEndpointErr("error");
        } else {
          props.setEndpointErr("");
        }
        break;
      case "headers":
        if (props.isActive && event.target.value.trim() == "") {

          props.setHeadersErr("error");
        } else {
          props.setHeadersErr("");
        }
        break;
      case "bindAttributes":
        if (props.isActive && event.target.value.trim() == "") {

          props.setBindAttriErr("error");
        } else {
          props.setBindAttriErr("");
        }
        break;
      case "foreignKey":
        if (props.isActive && event.target.value == "") {

          props.setForeignKeyErr("error");
        } else {
          props.setForeignKeyErr("");
        }
        break;
      case "referenceTable":
        if (props.isActive && event.target.value.trim() == "") {

          props.setReferenceTableErr("error");
        } else {
          props.setReferenceTableErr("");
        }
        break;
      case "referenceColumn":
        if (props.isActive && event.target.value.trim() == "") {

          props.setReferenceColumnErr("error");
        } else {
          props.setReferenceColumnErr("");
        }
        break;
      case "referenceMicroservice":
        if (props.isActive && event.target.value.trim() == "") {

          props.setReferenceMSErr("error");
        } else {
          props.setReferenceMSErr("");
        }
        break;
      default:
    }
  };

  useEffect(() => {

    dispatch(getTOAEditFormDetails(params.id));
  }, []);

  // useEffect(()=>{
  //   if(initialValue){
  //     console.log("ACTIVE",initialValue.status)
  //     if(initialValue?.status == 'active'){
  //       props.setActiveHandler(true)
  //     }
  //     else{
  //       props.setActiveHandler(false)
  //       props.setMethodErr("");
  //       props.setAPIHostErr("");
  //       props.setAttributeKeyErr("");
  //       props.setAttributeLengthErr("");
  //       props.setAttributeValueTypeErr("");
  //       props.setEndpointErr("");
  //       props.setHeadersErr("");
  //       props.setBindAttriErr("");
  //       props.setForeignKeyErr("");
  //       props.setReferenceTableErr("");
  //       props.setReferenceColumnErr("");
  //       props.setReferenceMSErr("");
  //     }
  //   }
  // },[initialValue])

  const statusHandler = (e) => {
    console.log("KALKI",e.target.value);
    if(e.target.value == "active"){
      props.setActiveHandler(true)
    }else{
      props.setActiveHandler(false)
      props.setMethodErr("");
      props.setAPIHostErr("");
      props.setAttributeKeyErr("");
      props.setAttributeLengthErr("");
      props.setAttributeValueTypeErr("");
      props.setEndpointErr("");
      props.setHeadersErr("");
      props.setBindAttriErr("");
      props.setForeignKeyErr("");
      props.setReferenceTableErr("");
      props.setReferenceColumnErr("");
      props.setReferenceMSErr("");
    }
  }
  return (
    <>
      {loader.isLoading ? (
        <ListLoader />
      ) : (
        <Form>
          <Row>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>{tr["Table Name"]}</Form.Label>
                <Field
                  name="totLabel"
                  className={"form-control"}
                  component={_inputField}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>{tr["Attribute"]}</Form.Label>
                <Field
                  name="attribute"
                  className={"form-control"}
                  component={_inputField}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>{tr["Attribute Label"]}</Form.Label>
                <Field
                  name="attributeLabel"
                  className={"form-control"}
                  component={_inputField}
                  maxLength={100}
                />
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>{tr["Data Type"]}</Form.Label>
                <Field
                  name="dataType"
                  className={"form-control"}
                  component={_inputField}
                  disabled
                />
              </Form.Group>
            </Col>
            
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>{tr["API Host"]}</Form.Label>
                <Field component={_dropDown} options={hostOptions} name="apiHost" className={'form-control '+ props.apiHostErr} 
                // onBlur={(event) => {
                //     validateTOAEditField("apiHost", event);
                //   }}
                  />

              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>{tr["Attribute Key"]}</Form.Label>
                <Field
                  component={_inputField}
                  name="attributeKey"
                  className={"form-control "+ props.attributeKeyErr}
                  maxLength={100}
                  // onBlur={(event) => {
                  //   validateTOAEditField("attributeKey", event);
                  // }}
                />
              </Form.Group>
            </Col>
              <Col md={6} sm={6} xs={12}>
                <Form.Group className='form-group'>
                    <Form.Label bsClass="">
                    {tr["Attribute Length"]}
                  </Form.Label>
                  <Field
                    component={_number}
                    name="attrLength"
                    className={"form-control "+ props.attributeLengthErr}
                    min={1}
                    max={1000}
                    // onBlur={(event) => {
                    //   validateTOAEditField("attrLength", event);
                    // }}
                  />
                </Form.Group>
              </Col>

            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>{tr["Attribute Value Type"]}</Form.Label>
               
                <Field component={_dropDown} options={attriTypeOptions} name="attributeValueType"  className={'form-control '+ props.attributeValueTypeErr}
                // onBlur={(event) => {
                //   validateTOAEditField("attributeValueType", event);
                // }}
                />
              </Form.Group>
            </Col>
            
           
            <Col md={12}>
              <Form.Group className="form-group">
                <Form.Label>{tr["API Endpoint"]}</Form.Label>
                <Field
                  name="apiEndPoint"
                  component={_inputField}
                  className={"form-control "+ props.endPointErr}
                  maxLength="200"
                  rows={3}
                  // onBlur={(event) => {
                  //   validateTOAEditField("apiEndPoint", event);
                  // }}
                />
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>{tr["Headers"]}</Form.Label>
                <Field
                  component="textarea"
                  name="headers"
                  className={"form-control "+ props.headersErr}
                  maxLength="500"
                  rows={4}
                  // onBlur={(event) => {
                  //   validateTOAEditField("headers", event);
                  // }}
                />
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>{tr["Bind Attributes"]}</Form.Label>
                <Field
                  name="bindAttributes"
                  component="textarea"
                  className={"form-control "+ props.bindAttriErr}
                  maxLength="1000"
                  rows={4}
                  // onBlur={(event) => {
                  //   validateTOAEditField("bindAttributes", event);
                  // }}
                />
              </Form.Group>
            </Col>
            
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>{tr["Method Type"]}</Form.Label>
                 <Field component={_dropDown} options={methodOptions} name="methodType"  className={'form-control '+ props.methodErr} 
                  />
              </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                <span className="rStar"></span>
                <Form.Label>
                  {tr["Status"]}
                </Form.Label>
                <Field
                  name="status"
                  component="select"
                  className={"form-control " + props.statusErr}
                  onBlur={(event) => {
                    validateTOAEditField("status", event);
                  }}
                  onChange={(e)=>{statusHandler(e)}}
                >
                  <option value="">{tr["Select"]}</option>
                  <option value="active">{tr["Active"]}</option>
                  <option value="inactive">{tr["Inactive"]}</option>
                </Field>
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                
                <Form.Label>
                  Foreign Key
                </Form.Label>
                <Field
                  name="foreignKey"
                  component="select"
                  className={"form-control " + props.foreignkeyErr}
                >
                  <option value="">{tr["Select"]}</option>
                  <option value="true">{tr["True"]}</option>
                  <option value="false">{tr["False"]}</option>
                </Field>
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
             
                <Form.Label>Reference Table</Form.Label>
                <Field
                  component={_inputField}
                  name="referenceTable"
                  className={"form-control "+ props.referencetableErr}
                  maxLength={100}
                />
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
              
                <Form.Label>Reference Column</Form.Label>
                <Field
                  component={_inputField}
                  name="referenceColumn"
                  className={"form-control "+ props.referencecolumnErr}
                  maxLength={100}
                />
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
              
                <Form.Label>Reference Microservice</Form.Label>
                <Field
                  component={_inputField}
                  name="referenceMicroservice"
                  className={"form-control "+ props.referencemsErr}
                  maxLength={100}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};
TOAForm = reduxForm({
  form: "EditTOA",
  enableReinitialize: true,
})(TOAForm);
TOAForm = connect(({ spcmReducer}) => (
    {
  initialValues: {
    attribute: spcmReducer.toaEditList?.attribute,
    totLabel: spcmReducer.toaEditList?.totLabel,
    status: spcmReducer.toaEditList?.status,
    attributeLabel: spcmReducer.toaEditList?.attributeLabel,
    dataType: spcmReducer.toaEditList?.dataType,
    apiEndPoint: spcmReducer.toaEditList?.apiEndPoint,
    apiHost: spcmReducer.toaEditList?.apiHost,
    attributeKey: spcmReducer.toaEditList?.attributeKey,
    attrLength: spcmReducer.toaEditList?.attrLength,
    attributeValueType: spcmReducer.toaEditList?.attributeValueType,
    headers: spcmReducer.toaEditList?.headers,
    methodType: spcmReducer.toaEditList?.methodType,
    bindAttributes: spcmReducer.toaEditList?.bindAttributes,
    foreignKey: spcmReducer.toaEditList?.foreignKey,
    referenceTable: spcmReducer.toaEditList?.referenceTable,
    referenceColumn: spcmReducer.toaEditList?.referenceColumn,
    referenceMicroservice: spcmReducer.toaEditList?.referenceMicroservice
  },
}))(TOAForm);

export default TOAForm;

