
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { navigationHooks } from '../../helpers/NavigationHook.js';
import {connect} from 'react-redux';
import AmsCreateRulesHeader from './amsCreateRules/AmsCreateRulesHeader.js';
import AmsCreateRulesFormTab from './amsCreateRules/AmsCreateRulesFormTab.js';
import CreateAttributes from './amsCreateRules/CreateAttributes.js';
import Cookies from 'universal-cookie';
import ConditionBuilder from '../common/condition-builder.js';
const cookies = new Cookies();
let homepagelocation = cookies.get('gph');
if (homepagelocation) homepagelocation = homepagelocation.replace('s:', '');
if (homepagelocation) homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home")
  homepagelocation = "/" + homepagelocation;

class AmsCreateRules extends React.Component {
	constructor(props) {
		super(props);
		this.state ={
			subMenu: '4',
			showHideAlfy:false
		};
		this.showHideAlfy = this.showHideAlfy.bind(this);
		this.loadHome = this.loadHome.bind(this);
	};
	loadHome() {
		navigationHooks.navigate(homepagelocation);
	}
	showHideAlfy(value){
		this.setState({showHideAlfy: value});
	};
	
   render() {
	const  tr=this.props.tr;
	console.log("queryResult79",this.state.queryResult);
      return (
		<div>
		<div className="container-fluid padding-t-10 margin-b-15">
			<ul className="breadcrumb"><li className="breadcrumb-item"><a role="button" onClick={this.loadHome} >{this.props.tr['Home']}</a></li><li className="breadcrumb-item"><a role="button" onClick={() => { navigationHooks.navigate('/amsHome'); }} >{this.props.tr['Assignment Rules']}</a></li><li className="breadcrumb-item active">{this.props.tr['Create']}</li></ul>
		</div>
		<div className="minHeight">
			  <div className="container-fluid" bsClass="">
			
			{/* Home Page blue bar Section Start*/}
			<AmsCreateRulesHeader tr={tr} queryResult={this.state.queryResult}  />
			{/* Home Page blue bar Section end*/}

			<Row className="row-eq-height">
				<Col md={8} sm={12} xs={12}>
						{/* Xsm Break Fix Form tabs Section Start*/}
							  <AmsCreateRulesFormTab tr={tr} />
						{/* Xsm Break Fix Form tabs Section End*/}
				</Col>
				<Col md={4} className="colRightHeight">
					<div className="stickyArea rBoxStyle ">
						{/* Xsm BreakFix Action Section Start*/}
						 <CreateAttributes translator={tr}/>
						 <ConditionBuilder />
						{/* Xsm BreakFix Action Section End*/}
						</div>
				</Col>
			</Row>
			</div>
		</div>
	</div>
      );
   }
}

const mapStateToProps = ({spcmReducer}) => {
	return{		
		tr:spcmReducer.tr
	}
}

export default connect(mapStateToProps,null)(AmsCreateRules);

