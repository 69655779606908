
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import { Link } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { Field, reduxForm, formValueSelector, change, SubmissionError } from 'redux-form';
import { emptyAllFields } from '_Actions/foundation/tags/tagsAction';
import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import { MyContext } from '_MyContext';

const Header = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const tr = useSelector((state) => state.spcmReducer.tr);
	const foundation_tags_reducer = useSelector((state) => state.foundation_tags_reducer);
	const [saveLoader, setSaveLoader] = useState(false);
	const context = useContext(MyContext);
	const closeButton = useRef(null);
	const saveObjRef = useRef(null);

	useEffect(() => {
		context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
		context.newTabPrevent();
	}, []);

	function submit(values) {
		if (!values.company) {
			props.setCompanyErrorState('error');
			return false;
		}
		else {
			props.setCompanyErrorState('');
		}
		setSaveLoader(true);
		let sequence = values.tagType == 'Member' ? (values.parentTag ? foundation_tags_reducer.memberTagsForComposite.length + 1 : 1) : null;
		axios.post('/api/postCreateTagData', {
			"businessFunction": values.businessFunctionName,
			"companyId": values.company,
			"compositeTagKey": values.compositeTag,
			// "language": "1",
			"parentTagKey": values.parentTag,
			"sequence": sequence,
			"tagCharLimit": values.character_limit,
			"tagEnabled": values.tagEnabled,
			"tagLabel": values.label.trim(),
			"tagModule": values.module,
			"tagType": values.tagType,
			"tagMandatory": true,
			// "tagValueDataSource": values.tagDataSource,
			"tagValueDataType": values.tagDataValidation,
			"companyName": values.companyName,
			"businessFunctionId": values.businessFunctionId
		}).then(function (response) {
			if (response.status == 201) {
				setSaveLoader(false);
				dispatch(emptyAllFields());
				navigate("/editCustomAttribute/" + response.data.tagId.toString());
			}
		}).catch(function (error) {
			setSaveLoader(false);
			if (error.response.status == 409 && error.response.data == 'duplicate tag') {
				swal({
					text: tr["An attribute with the same label already exists. Please provide a different label."],
					button: tr['OK'],
				});
			}
		});
	}

	return (
		<Row className="margin-b-15">
			<Col sm={8} xs={7}>
				<h1 bsClass="" className="sPageHeading1">{tr["Custom Attribute"]}</h1>
			</Col>
			<Col sm={4} xs={5}>
				<div className="paHedFilter">
					<Link bsPrefix=' ' to="javascript:void(0)" id="creTag" className="myBt plus ctrlKeyPrevent" disabled={saveLoader} onClick={props.handleSubmit(submit)} title={tr['Save']} ref={saveObjRef}>{saveLoader ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/>}</Link>
					<Link bsPrefix=' ' to="/customAttribute" className="myBt cancel fillBtn ctrlKeyPrevent" title={tr['Cancel']} onClick={() => { dispatch(emptyAllFields())}} ref={closeButton} ><IoClose /></Link>
				</div>
			</Col>
		</Row>
	)
}

export default reduxForm({
	form: 'foundationTagsCreateForm',
	destroyOnUnmount: true,
	enableReinitialize: true
})(Header);