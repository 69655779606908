
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useRef, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Logos from "./Logos";
import LanguageDropDown from "./language-dropDown.js";
import XSMMainMenu from "./XSMMainMenu";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import hotkeys from "hotkeys-js";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";
import ModulesHamburger_Mobile from "./ModulesHamburger_Mobile.js";
import { loadFunctionByPageAndRole } from "_Actions/common/functionActions.js";
import useIdle from "../../common/reactIdleTimer/useIdleTimer";
const cookies = new Cookies();

let SESSION_TIMEOUT = cookies.get("gph");
let app_url = cookies.get("gph");
let homepagelocation = cookies.get("gph");

import dummyimage from "_Images/hcl-sx-logo.png";
import { navigationHooks } from "../../../helpers/NavigationHook";

import { GLOBAL } from "../.././Globals";
import useWebSocketConnection from "../../../helpers/useWebSocketConnection.js";
import { openLinkInNewTab } from "../helper.js";

if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];

if (app_url) app_url = app_url.replace("s:", "");
if (app_url) app_url = app_url.substring(0, app_url.lastIndexOf("."));
app_url = app_url.split("~");
app_url = app_url[18];

if (SESSION_TIMEOUT) SESSION_TIMEOUT = SESSION_TIMEOUT.replace("s:", "");
if (SESSION_TIMEOUT)
  SESSION_TIMEOUT = SESSION_TIMEOUT.substring(
    0,
    SESSION_TIMEOUT.lastIndexOf(".")
  );
SESSION_TIMEOUT = SESSION_TIMEOUT.split("~");
SESSION_TIMEOUT = SESSION_TIMEOUT[17];
if (
  SESSION_TIMEOUT == "" ||
  SESSION_TIMEOUT == "undefined" ||
  SESSION_TIMEOUT == undefined
) {
  SESSION_TIMEOUT = 1800000;
}

let languageselection = cookies.get("gph");
if (languageselection) languageselection = languageselection.replace("s:", "");
if (languageselection)
  languageselection = languageselection.substring(
    0,
    languageselection.lastIndexOf(".")
  );
languageselection = languageselection.split("~");
languageselection = languageselection[20];

if (homepagelocation != "/home") homepagelocation = "/" + homepagelocation;

let user_roleId = cookies.get("gph");
if (user_roleId) user_roleId = user_roleId.replace("s:", "");
if (user_roleId)
  user_roleId = user_roleId.substring(0, user_roleId.lastIndexOf("."));
user_roleId = user_roleId.split("~");
user_roleId = user_roleId[33];
let userRoleArr = new Set(user_roleId?.split(",") || []);

let appurl = app_url + homepagelocation;

let multitenantLogin = cookies.get("gph");
if (multitenantLogin) multitenantLogin = multitenantLogin.replace("s:", "");
if (multitenantLogin)
  multitenantLogin = multitenantLogin.substring(
    0,
    multitenantLogin.lastIndexOf(".")
  );
multitenantLogin = multitenantLogin.split("~");
multitenantLogin = multitenantLogin[41];

let userLogoutUrl = cookies.get("gph");
if (userLogoutUrl) userLogoutUrl = userLogoutUrl.replace("s:", "");
if (userLogoutUrl)
  userLogoutUrl = userLogoutUrl.substring(0, userLogoutUrl.lastIndexOf("."));
userLogoutUrl = userLogoutUrl.split("~");
userLogoutUrl = userLogoutUrl[44];

const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

const allowedRoles = new Set([
  "1", "2", "5", "12", "13", "14", "16", "17", "19", "20", "32", "33", "34", "37", "38", "39", "40", "41", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "57", "60", "61", "62", "63", "64", "65", "66", "67", "68", "71", "89"
]);

const Header = (props) => {
  const [full_name, setFull_name] = useState("");
  const [roleId, setRoleId] = useState("");
  const [companyImage, setCompanyImage] = useState("");
  const [logoImage, setLogoImage] = useState("");
  const [userImage, setUserImage] = useState("");
  const [currentLocation, setCurrentLocation] = useState("");
  const [notificationCount, setNotificationCount] = useState(0);
  
  const windowSize = useSelector((state) => state.windowSize.width);
  const lang = useSelector((state) => state.spcmReducer.lang);
  const tr = useSelector((state) => state.spcmReducer.tr);
  const rightSidebarMenulist = useSelector(
    (state) => state.rightSidebarMenulist
  );

 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const themeData = useSelector((state)=>state.appliedThemeData);
  const location = useLocation().pathname;  
  navigationHooks.navigate = useNavigate();
  navigationHooks.location = useLocation();
  navigationHooks.params = useParams();
  navigationHooks.useRef = useRef(null);
  navigationHooks.history = useLocation();
  navigationHooks.dispatch = useDispatch();
  
  useEffect(() => {
    setCurrentLocation(location);
    axios.post("/api/translate", { sourceLanguage: "en", targetLanguages: ["de"], texts: [
      {
        text: "My Orders",
        context: "string",
      },
    ]}).then((response) => {
      console.log("response", response.data);
    });
  }, [location]);

  const WEB_SOCKET_URL = "ws://localhost:8080";
  const { connectionStatus, lastMessage, sendMessage } = useWebSocketConnection(WEB_SOCKET_URL);

  useEffect(() => {
    if(lastMessage) {
      if(lastMessage.data !== "Connection established." && lastMessage.data !== "Connection closed.") {
        try {
          let count = JSON.parse(lastMessage.data);
          count = JSON.parse(count).count;
          setNotificationCount(count)
        } catch (e) {
          console.log("error", e)
        }
      }
    }
  }, [lastMessage])

  const logout = () => {
    if (multitenantLogin) {
      api.get("/authentication/logout/" + userLogoutUrl).then((response) => {
        GLOBAL.isLogout = "true";
      });
      navigate("/sessionTimeout");
    } else {
      navigate("/authentication/logout/" + userLogoutUrl);
    }
  };

  // const { isIdle } = useIdle({ onIdle: logout, idleTime: SESSION_TIMEOUT });
  const { isIdle, broadcastLogout } = useIdle({ onIdle: logout, idleTime: SESSION_TIMEOUT });

  const handleLogoutClick = () => {
    broadcastLogout();
    logout();
  };


  let clientLogo = {
    backgroundImage: `url(${logoImage ? logoImage : dummyimage})`,
  };
  let hiddenAppModuleArray = [];
  if (rightSidebarMenulist.length > 0) {
    hiddenAppModuleArray = rightSidebarMenulist.filter(function (value) {
      return !["25", "26", "33", "53"].includes(value.functionId);
    });
  }

  useEffect(() => {
    let str = {};
    let imageStr = {};

    str.user_id = "";
    str = JSON.stringify(str);
    axios.get("/api/userInfo", { headers: { query: str } }).then((response) => {
      setFull_name(response.data.full_name);
      setRoleId(response.data.role_id);
      setUserImage(response.data.image_url);
      GLOBAL.Vip = response.data.vip;
    });

    imageStr.companyId = "";
    imageStr = JSON.stringify(imageStr);
    axios
      .get(GLOBAL.companyImageURL, { headers: { query: imageStr } })
      .then((response) => {
        setCompanyImage(response.data[0].companyImageTempURL);
        setLogoImage(response.data[0].xsmLogoTempURL);
      });
    if (
      !(
        user_roleId == "10" || ((user_roleId == "11") == user_roleId) == "10,11"
      )
    ) {
      dispatch(loadFunctionByPageAndRole("24"));
    }
  }, [windowSize]);

  useEffect(() => {
    if (themeData.data && Object.keys(themeData.data).length > 0) {
      const root = document.documentElement;
      Object.keys(themeData.data).forEach((value) => {
        if (value !== "themeName" || value !== "FontFamily") {
          root?.style.setProperty("--" + value, themeData.data[value]);
        }
      });
    }
  }, [themeData]);

  hotkeys("f5,ctrl+r,ctrl+f5,shift+f5", function (event, handler) {
    let location = window.location.href;
    event.preventDefault();
    if (location == appurl) {
      event.preventDefault();
    } else {
      if (window.location.pathname === "/sessionTimeout") {
        window.location.href = "/authentication/logout/" + userLogoutUrl;
      } else {
        if (languageselection == "en") {
          swal({
            text: "Page refresh has been disabled. Refreshing will redirect you to the homepage.",
            buttons: ["Cancel", "OK"],
          }).then((willDelete) => {
            if (willDelete) {
              navigate(homepagelocation);
            }
          });
          return false;
        }
        if (languageselection == "fr") {
          swal({
            text: "L'actualisation de la page a été désactivée. L'actualisation vous redirigera vers la page d'accueil.",
            buttons: ["Annuler", "D'accord"],
          }).then((willDelete) => {
            if (willDelete) {
              navigate(homepagelocation);
            }
          });
          return false;
        }
      }
    }
  });

  const shouldShowModulesHamburger = windowSize < 1024 && [...userRoleArr].some(role => allowedRoles.has(role)) && hiddenAppModuleArray != [];


  return (
    <header className="hfixed container-fluid">
      <a
        aria-label="Skip To Content"
        role="button"
        href="#skipdv"
        id="skiptoContent"
        title="Skip To Content"
      >
        Skip To Content
      </a>
      <Row>
        <Col lg={5} md={7} sm={8} xs={9}>
          <Row>
            <Col lg={5} md={6} sm={12} xs={12}>
              {shouldShowModulesHamburger && (
                  <ModulesHamburger_Mobile
                    clientLogoNew={clientLogo}
                    rightSidebarMenulist={rightSidebarMenulist}
                    setShowSidebar={props.setShowSidebar}
                  />
                )}
              <Logos clientLogo={clientLogo} />
            </Col>
            <Col
              lg={8}
              md={7}
              sm={12}
              xs={12}
              className="d-none d-xl-block"
            ></Col>
          </Row>
        </Col>
        <Col lg={7} md={5} sm={4} xs={3} className="text-r">
          <LanguageDropDown />
          <XSMMainMenu
            userImage={userImage}
            roleId={roleId}
            userInfo={full_name}
            tr={tr}
            pageId={"24"}
            currentLocation={currentLocation}
            notificationCount={notificationCount}
            sendMessage={sendMessage}
            handleLogoutClick={handleLogoutClick}
          />
        </Col>
      </Row>
    </header>
  );
};

export default Header;
