
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
//below URL used for testing purpose
//const breakFixUrl = 'https://xsm-f.mybluemix.net/api/SX_FAVOURITEOFFERINGs/';
import { GLOBAL }  from '../../components/Globals';
//let breakFixUrl =`https://xsm-f.mybluemix.net/api/breakfixitem?breakfixitemId=`;
//let breakFixUrl=`https://xsm-f.mybluemix.net/groupListSelfService?companyId=2932&group=ResolvedER`
export function loadFullfilmentRelatedDataDetailsSuccess(fullfilmentRelatedDataDetails){
    //alert('in success')
    return {
        type: 'LOAD_FULLFILMENTRELATEDDATADETAILS_SUCCESS',
        fullfilmentRelatedDataDetails
    };
}

export function loadFullfilmentRelatedDataDetailsFailure(errorResponse){
    return {
        type: 'LOAD_FULLFILMENTRELATEDDATADETAILS_FAILURE',
        errorResponse
    };
}
export function loadFullfilmentRelatedDataDetails(breakFixId,description){
    //alert('action called and parameter is '+breakFixId)

    return(dispatch) =>{
        //alert("EditGroupIdAction123"+breakFixId);
        dispatch({
            type: 'LOADER_IN_PROGRESS',
            payload: ''
        });
                //alert("hiaaa::::");
                axios.get(GLOBAL.fullfilmentRelatedDataUrl+"?itemId="+breakFixId+"&module=Fullfilment&sortBy=relationId&orderBy=desc")
                    .then((fullfilmentRelatedDataDetails)=>{
                        if(!fullfilmentRelatedDataDetails){//alert('not breakFix')
                            //dispatch(loadBreakFixFailure(breakFix.statusText))
                            throw Error(fullfilmentRelatedDataDetails.statusText);
                        }
                        dispatch({
                            type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY',
                            payload: ''
                        });
                        return fullfilmentRelatedDataDetails;
                    })
                    .then((fullfilmentRelatedDataDetails) =>{//alert('yes it is')
                        dispatch({
                            type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY',
                            payload: ''
                        });
                        dispatch(loadFullfilmentRelatedDataDetailsSuccess(fullfilmentRelatedDataDetails))
                    })
                    .catch((err) => {
                        dispatch({
                            type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY',
                            payload: ''
                        });
                        console.log(err);
                    });
                    //console.log("not calling loadBreakFixEditDetails");

    };
}
