
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
//below URL used for testing purpose
//const breakFixUrl = 'https://xsm-f.mybluemix.net/api/SX_FAVOURITEOFFERINGs/';
// import { GLOBAL }  from '../../components/Globals';
import { GLOBAL } from "_Globals";
//let breakFixUrl =`https://xsm-f.mybluemix.net/api/breakfixitem?breakfixitemId=`;
//let breakFixUrl=`https://xsm-f.mybluemix.net/groupListSelfService?companyId=2932&group=ResolvedER`

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

export function loadBreakFixEditDetailsSuccess(breakFixEditDetails) {

	return {
		type: 'LOAD_BREAKFIXEDITDETAILS_SUCCESS',
		breakFixEditDetails
	};
}

export function loadBreakFixEditDetailsFailure(errorResponse) {
	return {
		type: 'LOAD_BREAKFIXEDITDETAILS_FAILURE',
		errorResponse
	};
}

export function loadExternalSystemResultsSuccess(resultResponse) {
	// console.log('DDDDDDDDDDDDDDDDDDDDDDDDDDDD---------------', resultResponse)
	return {
		type: 'LOAD_BREAKFIXEXTERNALSYSTEMATTRIBUTESRESULTS_SUCCESS',
		resultResponse
	};
}
export const loadBreakFixDataBackground = breakFixId => {
	return dispatch => {
		api.get(GLOBAL.breakFixEditUrl + breakFixId).then(breakfixData => {
			dispatch(loadBreakFixEditDetailsSuccess(breakfixData));
		});
	}
}

export function loadBreakFixEditDetails(breakFixId) {
	//alert('action called and parameter is '+breakFixId)
	return (dispatch) => {
		//alert("EditGroupIdAction123"+breakFixId);
		dispatch({ type: 'FETCHING_DETAILS_IN_PROGRESS' });
		if (breakFixId == undefined || breakFixId == "") {
			//alert("H2 breakFixId not found");
			return Promise.reject('error');
		} else {
			// console.log('breakFixId - ', breakFixId);
			//alert("my url is "+GLOBAL.breakFixEditUrl+breakFixId);
			return api.get(GLOBAL.breakFixEditUrl + breakFixId)
				.then((breakFixEditDetails) => {
					if (!breakFixEditDetails) {//alert('not breakFix')
						//dispatch(loadBreakFixFailure(breakFix.statusText))
						throw Error(breakFixEditDetails.statusText);
					}
					return breakFixEditDetails;
				})
				.then((breakFixEditDetails) => {
					//alert(breakFixEditDetails.data.requestId);
					api.get(GLOBAL.d2cNumberUrl + breakFixEditDetails.data.requestId+'?module=breakfix').then((response) => {
						if (response.status == 200) {
							dispatch({ type: 'D2C_NUMBER_LOADED', d2cNumber: response.data });
						}
					});
					dispatch(loadBreakFixEditDetailsSuccess(breakFixEditDetails));
					dispatch(BreakixFieldStatusData(breakFixEditDetails.data.statusId.toString()));


					dispatch({ type: 'LOAD_HAMBURGER_OPTIONS', payload: true });

					return Promise.resolve(breakFixEditDetails);
					// dispatch({type:'DETAILS_FETCHED_SUCCESSFULLY'});
				})
				.catch((err) => {
					console.log(err);
					return Promise.reject(err);
				});
			//console.log("not calling loadBreakFixEditDetails");
		}
	};
}
export const BreakixFieldStatusData = (status_id) => {

	return (dispatch) => {
		dispatch({ type: 'FETCHING_DETAILS_IN_PROGRESS' });
		api.get(GLOBAL.breakfixFieldStatusData + status_id)
			.then((statusData) => {
				if (!statusData) {
					throw Error(statusData.statusText);
				}
				return statusData;
			})
			.then((statusData) => {
				console.log("changeData.... ", statusData)
				dispatch(FieldstatusData(statusData));
				dispatch({ type: 'DETAILS_FETCHED_SUCCESSFULLY' });

			});
	}
}
export const FieldstatusData = (statusData) => {
	console.log("statusData", statusData);
	// let fieldStatus={
	//   type:statusData.type,
	//   summary:statusData.summary,
	// }
	return {
		type: 'CHANGE_FIELD_STATUS_DATA_LOAD_SUCCESS',
		statusData
	};
}

export function loadBreakFixEditDetailsUpdate(breakFixId) {
	//alert('action called and parameter is '+breakFixId)
	console.log("breakFixIdbreakFixId", breakFixId);
	return (dispatch) => {
		//alert("EditGroupIdAction123"+breakFixId);
		//dispatch({type:'BREAKFIX_EDIT_DETAILS_LOAD_STATUS',status:'loading'});
		if (breakFixId == undefined || breakFixId == "") {
			//alert("H2 breakFixId not found");
		} else {
			//alert("my url is "+GLOBAL.breakFixEditUrl+breakFixId);
			api.get(GLOBAL.breakFixEditUrl + breakFixId)
				.then((breakFixEditDetails) => {
					if (!breakFixEditDetails) {//alert('not breakFix')
						//dispatch(loadBreakFixFailure(breakFix.statusText))
						throw Error(breakFixEditDetails.statusText);
					}
					return breakFixEditDetails;
				})
				.then((breakFixEditDetails) => {

					dispatch(loadBreakFixEditDetailsSuccess(breakFixEditDetails))
					// dispatch(BreakixFieldStatusData(10));
				})
				.catch((err) => {
					console.log(err);
				});
			//console.log("not calling loadBreakFixEditDetails");
		}
	};
}

export function loadExternalAttributesDetails(breakFixId, moduleName) {
	console.log("loadExternalAttributesDetails() : ",breakFixId, moduleName);
	// console.log('breakFixIdbreakFixId',breakFixId)
	//alert('action called and parameter is '+breakFixId)
	return (dispatch) => {
		// dispatch({type:'FETCHING_DETAILS_IN_PROGRESS'});
		api.get(GLOBAL.breakFixExternalSystemAttributesUrl + '/' + breakFixId + '/' + moduleName)
			.then((resultResponse) => {
				// console.log('BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB', resultResponse)
				if (!resultResponse) {
					//dispatch(loadBreakFixFailure(breakFix.statusText))
					throw Error(resultResponse.statusText);
				}
				return resultResponse;
			})
			.then((resultResponse) => {
				// console.log('CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC', resultResponse)
				// dispatch({type:'DETAILS_FETCHED_SUCCESSFULLY'});
				dispatch(loadExternalSystemResultsSuccess(resultResponse));
			})
			.catch((err) => {
				console.log(err);
			});
		//console.log("not calling loadresultResponse");

	};
}

export function dispatchUpdatedBFDetails(breakFixEditDetails) {
	return (dispatch) => {
		dispatch(loadBreakFixEditDetailsSuccess(breakFixEditDetails));
	}
}