
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import {useSelector } from "react-redux";
import HeaderSec from "./header";
import TOTForm from "./form";
import PindCategories from "../../../common/header/pind-categories";
import Breadcrumbs from "../../../common/header/breadcrumbs";
import AuditLog from "./AuditLog";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import "_Css/form/_form.scss";

const TOTEditIndex = () => {
  const tr = useSelector((state) => state.spcmReducer.tr);
  const [labelnameErr,setLabelNameErr] = useState('');
  const [ruleErr,setRuleErr] = useState('');
  const [view, setView] = useState("activity");
  const [isRightEditPanel, setisRightEditPanel] = useState(true);

  const rightEditPanel = (value) => {
    setisRightEditPanel(value);
  };

  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Breadcrumbs activePageName={tr["Edit"]} parentPageurl='/tot' parentPageName="Data Dictionary" />
      </Container>

      <Container fluid>
        <HeaderSec
          labelnameErr={labelnameErr}
          setLabelNameErr={setLabelNameErr}
          ruleErr={ruleErr}
          setRuleErr={setRuleErr}
          view={view}
        />
        <PanelGroup direction="horizontal">
          <Panel
            id="sidebar"
            minSize={33}
            order={1}
            defaultSize={isRightEditPanel ? 67 : 100}
            className={isRightEditPanel ? "isShowLeftPanel" : ""}
          >
            <TOTForm
            tr={tr}
            labelnameErr={labelnameErr}
            setLabelNameErr={setLabelNameErr}
            ruleErr={ruleErr}
            setRuleErr={setRuleErr}/>
          </Panel>
          {isRightEditPanel ? (
            <>
              <PanelResizeHandle className="isShowLeftPanel resizeHandle">
                <div className="outlne">
                  <div className="handIcn">
                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                  </div>
                </div>
              </PanelResizeHandle>
              <Panel minSize={33} order={2} defaultSize={33}>
                {view === "activity" && (
                  <div className="stickyArea rBoxStyle">
                    <AuditLog
                      rightEditPanel={rightEditPanel}
                      setView={setView}
                    />
                  </div>
                )}
              </Panel>
            </>
          ) : (
            ""
          )}
        </PanelGroup>
      </Container>
    </main>
  )
}

export default TOTEditIndex;
