
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from "axios";
import { GLOBAL } from '_Globals';

const api = axios.create({
    headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

export function loadAdminPreferencesListData(companyId, configurableOn, showOverridden = false) {
    return (dispatch) => {
        let strObj = {};
        if (companyId && companyId != "") {
            strObj.companyId = companyId;
        }
        strObj.configurableOn = configurableOn;
        strObj.showOverridden = showOverridden;
        strObj = JSON.stringify(strObj);
        dispatch({ type: 'LOAD_ADMIN_PREFERENCES_LIST_LOADER_IN_PROGRESS' });
        api.get(GLOBAL.loadAdminPreferencesListUrl, { headers: { query: strObj } })
            .then((response) => {
                if (!response) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => {
                if (response.status == 204) {
                    dispatch(loadAdminPreferencesListDataSuccess([]));
                } else {
                    dispatch(loadAdminPreferencesListDataSuccess(response.data));
                }
                dispatch({ type: 'LOAD_ADMIN_PREFERENCES_LIST_LOADER_FINISHED' });
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: 'EMPTY_ADMIN_PREFERENCES_LIST_DATA_SUCCESS' });
                dispatch({ type: 'LOAD_ADMIN_PREFERENCES_LIST_LOADER_FINISHED' });
            });
    };
}
export function getAdminPreferences(companyId, module, propertyId, propertyName) {
    return dispatch => {
        if(companyId && module && propertyId){
            const queryObj = {companyId,module,propertyId};
            const queryString = JSON.stringify(queryObj);
            axios.get('/api/getAdminPreferences', { headers: { query: queryString } }).then(response => {
                if (response.status === 200 && response.data.length) {
                  if(propertyName === 'Agent') {
                      dispatch({ type: 'AISM_AGENT_PREDICTION', payload: response.data[0]});
                  } else if(propertyName === 'Group') {
                      dispatch({ type: 'AISM_GRP_PREDICTION', payload: response.data[0]});
                  } else if(propertyName === 'aiInsight') {
                      dispatch({ type: 'AIINSIGHT_FCR', payload: response.data[0]});
                  }
                  else if(propertyName === 'aiInsightDSAT') {
                      dispatch({ type: 'AIINSIGHT_DSAT', payload: response.data[0]});
                  }
                   else {
                      dispatch(loadAdminPreferencesListDataSuccess(response.data[0]));
                  }
                }
              }).catch(error => {
                console.error("Error fetching admin preferences:", error);
              });
        }
    };
}  

export function loadAdminPreferencesListDataSuccess(payload) {
    return {
        type: 'LOAD_ADMIN_PREFERENCES_LIST_DATA_SUCCESS',
        payload
    };
}

export function emptyAdminPreferencesListData() {
    return (dispatch) => {
        dispatch({ type: 'EMPTY_ADMIN_PREFERENCES_LIST_DATA_SUCCESS' });
    };
}
export function setWalkupEnabled(value) {
    return (dispatch) => {
        dispatch({ type: 'WALK_UP_ENABLED', payload: value });
    };
}
