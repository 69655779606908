
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { reduxForm } from "redux-form";
import { ImSpinner6 } from "react-icons/im";
import View from "../View";
import { getExpandedAttributes } from "../../studio/utils";

const ButtonsComponent = (props) => {
  const tr = useSelector((state) => state.spcmReducer.tr);
  const navigate = useNavigate();
  const { submitting, clickedButton, defaultValues } = props;
  const [isFormView, setIsFormView] = useState(false);
  let returnData = {};
  const getButtons = (buttonProp) => {
    let type;
    if (buttonProp && typeof buttonProp.buttonType == "object") {
      type = buttonProp?.buttonType?.id;
    }
    let showSpinner = false;
    if(buttonProp?.attributes?.id===clickedButton?.attributes?.id){
      showSpinner = true;
     }
    const textNode = buttonProp?.components ? buttonProp?.components[0] : "";
    let buttonAttributes = getExpandedAttributes(buttonProp?.attributes);
    let editable = true;
    if(defaultValues && defaultValues.hasOwnProperty('edittable')){
       editable = defaultValues?.edittable;
       if(!editable){
        buttonAttributes.className = buttonAttributes.className?buttonAttributes.className+" inactive":"inactive";
       }
    }

    if (buttonProp?.passingKeys) {
      const passingKeys = buttonProp?.passingKeys?.split(",");
      if (passingKeys && passingKeys.length > 0) {
        passingKeys.forEach((key) => {
          if (rowData.hasOwnProperty(key)) {
            returnData[key] = rowData[key];
          } else if (defaultValues.hasOwnProperty(key)) {
            returnData[key] = defaultValues[key];
          }
        });
      }
    } else if (props?.rowData) {
      returnData = props?.rowData;
    } else {
      returnData = defaultValues;
    }

    switch (type) {
      case "cancel": {
        return (
          <Button
            bsPrefix=" "
            role="button"
            {...buttonAttributes}
            // className="myBt cancel fillBtn"
            title={tr["Cancel"]}
            onClick={() => {
              navigate(buttonProp?.url);
            }}
          >
            {showSpinner ? (
              <ImSpinner6 style={{ color: "white" }} className="icn-spinner" />
            ) : buttonProp?.iconClass ? (
              <i className={buttonProp?.iconClass} />
            ) : (
              textNode?.content
            )}
          </Button>
        );
      }
      case "delete":
      case "save": {
        return (
          <Button
            bsPrefix=" "
            role="button"
            {...buttonAttributes}
            // className={`myBt ${type == "delete" ? "rclose" : "plus"}`}
            disabled={showSpinner || !editable}
            onClick={() => props.buttonClicked(type, buttonProp, returnData)}
          >
            {props.loader || showSpinner ? (
              <ImSpinner6 className="icn-spinner" />
            ) : type == "delete" ? (
              <i className="fa fa-trash-o"></i>
            ) : (
              <span>
                {buttonProp?.iconClass ? (
                  <i className={buttonProp?.iconClass} />
                ) : (
                  textNode?.content
                )}
              </span>
            )}
          </Button>
        );
      }
      case "refresh": {
        return (
          <Button
            title={textNode?.content}
            role="button"
            bsPrefix=" "
            disabled={showSpinner}
            {...buttonAttributes}
            onClick={() => {
              props.buttonClicked(type, buttonProp, returnData);
            }}
          >
            {showSpinner ? (
              <ImSpinner6 style={{ color: "white" }} className="icn-spinner" />
            ) :buttonProp?.iconClass ? (
              <i className={buttonProp?.iconClass} />
            ) : (
              textNode?.content
            )}
          </Button>
        );
      }
      default: {
        const textNode = buttonProp?.components[0];
        return (
          <Button
            title={textNode?.content}
            role="button"
            bsPrefix=" "
            disabled={showSpinner || !editable}
            {...buttonAttributes}
            onClick={() => {
              buttonProp?.page?.id && buttonProp?.openPageInPopup?.id=="self"
                ? setIsFormView(!isFormView)
                : props.buttonClicked(type, buttonProp, returnData);
            }}
          >
            {showSpinner ? (
              <ImSpinner6 style={{ color: "white" }} className="icn-spinner" />
            ) : buttonProp?.iconClass ? (
              <i className={buttonProp?.iconClass} />
            ) : (
              textNode?.content
            )}
          </Button>
        );
      }
    }
  };

  const renderButtons = () => {
    if (
      props?.defaultValues.hasOwnProperty(props?.buttonsProp?.buttonHideKey) &&
      props?.defaultValues[props?.buttonsProp?.buttonHideKey]
    ) {
      return null;
    }
    return getButtons(props?.buttonsProp);
  };
  return (
    <>
      <span>{renderButtons()}</span>
      {isFormView && (
        <View
          setIsFormView={setIsFormView}
          isFormView={isFormView}
          parantValues={props?.defaultValues}
          pageId={props?.buttonsProp?.page?.id}
          isChildPage={true}
        />
      )}
    </>
  );
};

export default reduxForm({
  form: "FormView",
})(ButtonsComponent);
