
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef } from "react";
import { Row, Col, Accordion, Tab, Tabs, ListGroup, Button } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {PiPencilSimpleBold} from "react-icons/pi";
import {IoClose} from "react-icons/io5";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';

const ShortDescription = (props) => {
    const navigate = useNavigate();
    const tr = useSelector((state) => state.spcmReducer.tr);
    const context = useContext(MyContext);
    const closeButton = useRef(null);
    const editButton = useRef(null);

    useEffect(() => {
        context.keyDownHandler(closeButton.current,editButton.current,"","");
        context.newTabPrevent();
    }, []);

    return (
        <>
            <Row>
                <Col xs={12}>
                    <div className="rPageHeadActBtn" style={{ "marginTop": "-3px" }}>
                        <ListGroup bsPrefix=" " as="ul">
                            <ListGroup.Item as="li" bsPrefix=" "><Link bsPrefix=" " to={"/editCustomAttribute/" + props.selectedItem.tagId} title={tr["Edit"]} className="editIcn ctrlKeyPrevent"  ref={editButton}><PiPencilSimpleBold/></Link></ListGroup.Item>
                            <ListGroup.Item as="li" bsPrefix=" "><Link bsPrefix=" " to="javascript:void(0)" title={tr["Minimize the details panel"]} className="ctrlKeyPrevent" onClick={() => { props.showRightSideFun(false) }} ref={closeButton} ><IoClose/></Link></ListGroup.Item>
                        </ListGroup>
                    </div>
                    <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{tr["Attribute Details"]}</div></div>
                </Col>
            </Row>
            <div className="rBoxGap">
                <div className="rBoxTabs">
                    <ListGroup as="ul" bsPrefix=" ">
                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable" style={{ "borderTop": "none" }}><span className="rwKey">{tr["Label"]}</span><span className="rwVal">{props.selectedItem.tagLabel}</span></ListGroup.Item>
                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Company"]}</span><span className="rwVal">{props.selectedItem.companyName}</span></ListGroup.Item>
                        {/* <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Business Function"]}</span><span className="rwVal">{props.selectedItem.businessFunction}</span></ListGroup.Item> */}
                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Module"]}</span><span className="rwVal">{props.selectedItem.tagModule}</span></ListGroup.Item>
                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Attribute type"]}</span><span className="rwVal">{props.selectedItem.tagType}</span></ListGroup.Item>
                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Enabled"]}</span><span className="rwVal">{props.selectedItem.tagEnabled}</span></ListGroup.Item>
                    </ListGroup>
                </div>
            </div>
        </>
    )
}

export default ShortDescription;