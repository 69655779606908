
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

export const _textArea = ({ input, label, type, className, rows, meta: { touched, error, warning } }) => {
	return (
		<div>
			<textarea {...input} className={(touched && error) ? className + " error" : className} rows={rows} placeholder={label} />
			{/* {touched && error && <span> {error} </span>} */}
			{touched && ((error && <span className='red'>{error}</span>) || (warning && <span>{warning}</span>))}
		</div>
	);
};

export const _dropDown = ({ input, label, type, className, options, disabled, meta: { touched, error, warning },draftEnable }) => {
	return (
		<div>
			{disabled ? 
				<select {...input} className={(touched && error) ? className + " error" : className} disabled>
					{input.name==="contextualDropdown"?"":<option value="" >Select</option>}
					{renderDropDownOptions(input.name, options, typeof draftEnable === 'undefined' ? '' : draftEnable)}
				</select>
			: 
				<select {...input} className={(touched && error) ? className + " error" : className} >
					{input.name==="contextualDropdown"?"":<option value="" >Select</option>}
					{renderDropDownOptions(input.name, options, typeof draftEnable === 'undefined' ? '' : draftEnable)}
				</select>
			}
			{touched && ((error && <span className='red'>{error}</span>) || (warning && <span>{warning}</span>))}
		</div>
	);
};

const renderDropDownOptions = (dropDownFor, options, draftEnable) => {
	// console.log("dropDownFor, options",dropDownFor, options);
	if(options == "" || options == null) {return false}
	switch (dropDownFor) {
    case 'companyStatus':
			return options.map((option, index) => draftEnable!=='' && draftEnable===false && option.field1Value+''==='2' ? '' : <option value={option.field1Value} key={index}>{option.field1Key}</option>);
    case 'company':
			return options.map((option, index) => <option value={option.field1Value} key={index}>{option.field1Key}</option>);
	case 'contextualDropdown':
			return options.map((option, index) => <option value={option.field1Value} key={index}>{option.field1Key}</option>);
	case 'module':
			return options.map((option, index) => <option value={option.field1Value} key={index}>{option.field1Key}</option>);
    case 'event':
			return options.map((option, index) => <option value={option.field1Value} key={index}>{option.displayName}</option>);
    case 'associatedCompany':
			return options.map((option, index) => <option value={option.COMPANY_ID} key={index}>{option.COMPANY_NAME}</option>);
    case 'group':
			return options.map((option, index) => <option value={option.GROUPID} key={index}>{option.GROUPNAME}</option>);
    case 'individual':
			return options.map((option, index) => <option value={option.MEMBERID} key={index}>{option.MEMBERNAME}</option>);
    case 'companyService':
			return options.map((option, index) => <option value={option.OFFERING_ID} key={index}>{option.OFFERING_NAME}</option>);
    case 'paramName':
			return options.map((option, index) => <option value={option.field1Value} key = {index}>{option.field1Value}</option>);
    case 'paramValue':
			return options.map((option, index) => <option value={option.field1Key} key = {index}>{option.field1Key}</option>);
	case 'operator':
			return options.map((option, index) => <option value={option.value} key = {index}>{option.label}</option>);
	case 'contextualGroup':
			return options.map((option, index) => <option value={option.value} key = {index}>{option.label}</option>);
    // case 'paramName':
	// 		return options.map((option, index) => <option value={option.value} key = {index}>{option.key}</option>);
    // case 'paramValue':
	// 		return options.map((option, index) => <option value={option.value} key = {index}>{option.key}</option>);
	case 'class':
			return options.map((option, index) => <option value={option.CLASS_ID} key = {index}>{option.CLASS_NAME}</option>);
	case 'componentId':
			return options.map((option, index) => <option value={option.componentId} key = {index}>{option.componentName}</option>);
	default:
		return null;
	}
};

export const _inputField = ({ input, label, type, className, placeholder, readOnly, meta: { touched, error, warning } }) => {
	return (
		<div>
			<input {...input} placeholder={placeholder} type={type} className={(touched && error) ? className + " error" : className} readOnly={readOnly} />
			{touched && ((error && <span className='red'>{error}</span>) || (warning && <span>{warning}</span>))}
		</div>
	);
};

export const TypeaheadandDropdown = ({options, onSelection, selectedOptions,setErrorColor,diableStatus,meta:{touched,error},onCrossClick,onInputChange,typedValue}) => {
	let _typeahead;
	let crossClick=()=>{
		_typeahead.clear();
		onCrossClick();
	}
	return (
	 <div className={(touched && error) ? "error dvTypehd":'dvTypehd'}>
		 {!diableStatus && selectedOptions && selectedOptions.length > 0 || typedValue && typedValue.length > 0 ?
        <span
          title="clear"
          role="img"
          aria-label="Clear"
          className="css-hakgx8 icn"
          onClick={crossClick}
        >
          <svg width="18" height="18" viewBox="0 0 24 24" role="presentation">
            <path
              d="M12 10.586L6.707 5.293a1 1 0 00-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 001.414 1.414L12 13.414l5.293 5.293a1 1 0 001.414-1.414L13.414 12l5.293-5.293a1 1 0 10-1.414-1.414L12 10.586z"
              fill="gray"
            ></path>
          </svg>
        </span>
		:
		''
        }
	  <Typeahead
        // ref={(ref)=>{_typeahead=ref;if(_typeahead!==null && selectedOptions.length===0 && typedValue.length===0)_typeahead.getInstance().clear();}}
	  //ref={(ref)=>{_typeahead=ref;if(_typeahead!==null && selectedOptions.length===0 && typedValue.length===0 && typeof _typeahead.getInstance === 'function')_typeahead.getInstance().clear();}}
		ref={(ref) => {
			_typeahead = ref;
			if (
			  _typeahead !== null &&
			  selectedOptions.length === 0 &&
			  typedValue.length === 0
			)
			  _typeahead.clear();
		  }}
		// ref={(ref)=>{_typeahead=ref;if(_typeahead!==null && selectedOptions.length===0 && typedValue.length===0)_typeahead.current;}}
		onChange={onSelection}
		options={options}
		selected={selectedOptions}
		placeholder="Please choose..."
		onBlur={setErrorColor}
		disabled={diableStatus}
		onInputChange={onInputChange}
		renderMenuItemChildren={(option, props) => {
			let label;
			let title;
			label =
			  option.label.length > 30
				? option.label.slice(0, 30) + "..."
				: option.label;
			  title = option.label.length > 30 ? option.label : "";
			if(option?.companyName){
			  return (
			  <div>
				<span title={title} style={{'display': 'block'}}>{label}</span>
				<span style={{'display': 'block', 'margin-top': '6px', 'color': '#777'}}>{option?.companyName || ''}</span>
			  </div>
			  )
			}
			return (
			  <div>
			  <span title={title}>{label}</span>
			  </div>
			);
		}}
	  />
	  </div>
	);
  };