
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import {useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { allowedRoles, rosterManagerRoleId } from "./onCallUtils";
const gph = new Cookies();
let roles = gph.get("gph");
if (roles) roles = roles.replace("s:", "");
if (roles) roles = roles.substring(0, roles.lastIndexOf("."));
roles = roles.split("~");
roles = roles[33] ? roles[33] : [];


const CommonHeaderMenu = (props) => {
 const rolesArray = roles.split(",");
 const translator = useSelector((state) => state.spcmReducer.tr);
 let showCalendar = rolesArray.some((val) => allowedRoles.includes(val.toString())) || rolesArray.includes(rosterManagerRoleId);
  return (
    <ul className="wibTabs">
      {rolesArray.includes(rosterManagerRoleId) && (
        <>
          <li className={props.active == "Roster" ? "active" : ""}>
            <Link title={translator["Roster"]} to="/onCallSchedule/roster">
            {translator["Roster"]}
            </Link>
          </li>
          <li className={props.active == "Shift" ? "active" : ""}>
            <Link title={translator["Shift"]} to="/onCallSchedule/shift">
            {translator["Shift"]}
            </Link>
          </li>
          <li className={props.active == "Schedule" ? "active" : ""}>
            <Link title={translator["Schedule"]} to="/onCallSchedule/schedule">
            {translator["Schedule"]}
            </Link>
          </li>
        </>
      )}
      {showCalendar && (
          <li className={props.active == "Calendar" ? "active" : ""}>
            <Link title={translator["Calendar"]} to="/onCallSchedule/calendar">
            {translator["Calendar"]}
            </Link>
          </li>
        )}
    </ul>
  );
};

export default CommonHeaderMenu;
