
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import { Tab, Tabs } from 'react-bootstrap';
import View from "../../View";
import { useEffect } from "react";
const TabRenderer = (props) => {
  const tabs = props?.item?.components[0]?.components;
  const tabContents = props?.item.components[1].components;
  const [tabRefreshed,setTabRefreshed] = useState(false);
  const [openedTabIndex,setOpenedTabIndex] = useState(0); 
  const [openedTabPageId,setOpenedTabPageId] = useState(null); 
  
  useEffect(()=>{
    if(openedTabIndex>0){
      if(tabContents[openedTabIndex].page?.id){
        setOpenedTabPageId(tabContents[openedTabIndex].page?.id);
      }
    }
  },[openedTabIndex])

  function createTab(tab, index) {
    return (
      <Tab
        eventKey={index}
        title={props.renderComponent(tab?.components)}
        key={tab.attributes.id}
      >
        {tabContents[index] && tabContents[index].page?.id ? (
          openedTabIndex == index && !tabContents[index].defaultLoad ? (
            <View
              pageId={openedTabPageId || tabContents[index].page?.id}
              setOpenedTabPageId={setOpenedTabPageId}
              parentValues={props?.defaultValues}
              isChildPage={true}
            />
          ) : null
        ) : (
          props.renderComponent(tabContents[index].components)
        )}
      </Tab>
    );
  }

  return (
    <Tabs defaultActiveKey={0} id="uncontrolled-tab-example" onSelect={setOpenedTabIndex}>
      {tabs.map(createTab)}
    </Tabs>
  );
};
export default TabRenderer