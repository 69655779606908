
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export default function(state = initialState.hamburgerCategory, action){
    switch (action.type){
        case 'FETCH_HAMBCATEGORYLIST':
            return action.hamburgerCategory.data;
        default:
          return state;
    }
}
