
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import {
  Row,
  Col,
  ButtonToolbar,
  Button,
  Table
} from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import {
  spcmLoader,
  getTranslationForOfferingNameDescriptionSummary,
  getLanguageTranslationForCategoryAndSubcategory,
  getAttributes,
  getConsumptionItems,
  getServiceOrderChoices,
  getQuestionsCategory,
  languageListTranslationStatus,
  getTranslationForOfferingBasedOnType,
} from "../../../actions/spcmActions"
import ListLoader from "../../common/loaders/ListLoader";
import _ from "lodash";
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import axios from "axios";
import { SubmissionError } from "redux-form";
import sanitizeHtml from 'sanitize-html';
import {ImSpinner6} from "react-icons/im";
import CloseBtnRightPanel from "../../common/closeBtnRightPanel";

class ReviewPage extends React.Component {
  constructor(props) {
    super(props);
    this.onPageChange = this.onPageChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      currentForm: "Questions_Form",
      publish: false,
    };
  }
  componentDidMount() {
    console.log("this.props.lanNameVal1=====",this.props.lanNameVal1);
    const selectedLanguageTranslationStatus = _.find(
      this.props.spcmReducer.languageListTranslationStatus,
      { languageName: this.props.lanNameVal1 }
    );
    this.props.getTranslationForOfferingNameDescriptionSummary(
      this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
      this.props.lanIdVal1
    );
    if (selectedLanguageTranslationStatus.publish == "false") {
      this.setState({ publish: false });
    } else {
      this.setState({ publish: true });
    }
  }

  submitForm() {
    const baseLanguage = this.props.spcmReducer.currentlySelectedServiceDetails
      .BASE_LANGUAGE;

    let str = {};
    str.offeringId = this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID;
    str.publish = this.state.publish.toString();
    str.languageId = this.props.lanIdVal1;

    str = JSON.stringify(str);
    console.log("str");
    console.log(str);
    this.props.spcmLoader("attribute", "posting");
    return axios
      .patch("/api/updatePublish", {}, { headers: { query: str } })
      .then((response) => {
        this.props.spcmLoader("attribute", "success");
        this.props.languageListTranslationStatus(
          this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
          baseLanguage !== null ? baseLanguage : "English"
        );
        this.props.reviewShowFun4(false);
      })
      .catch((error) => {
        this.props.spcmLoader("attribute", "success");
        throw new SubmissionError({ _error: error.response.data.message });
      });
  }

  onPageChange(myObj, e) {
    switch (myObj) {
      case "questList":
        this.props.getServiceOrderChoices(
          this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
          "",
          "",
          "Question",
          "",
          e,
          ""
        );
        break;
      case "groupList":
        this.props.getQuestionsCategory(
          this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
          "",
          "",
          "",
          "",
          e,
          ""
        );
        break;
      case "componentsList":
        this.props.getConsumptionItems(
          this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
          "",
          "",
          "",
          "",
          e,
          ""
        );

        break;
    }
  }

  render() {
    let workItemType = this.props.spcmReducer.currentlySelectedServiceDetails.WORK_ITEM_TYPE;
    console.log("this.state.publish");
    console.log(this.state.publish);
    const data = [
      { name: "Short Description" },
      { name: "Description" },
      { name: "Search Tags" },
      { name: "Attributes" },
      { name: "Components" },
      { name: "Ordering Information" },
      { name: "Fulfillment Plan" },
      { name: "Disclaimers" },
      { name: "Category" },
    ];

    const nameDescSummaryTranslation = this.props.spcmReducer
      .nameDescSummaryTranslation;
    console.log("nameDescSummaryTranslation====",nameDescSummaryTranslation);

    const tr = this.props.tr;
    const baseLanguage = this.props.spcmReducer.currentlySelectedServiceDetails
      .BASE_LANGUAGE;

    // Attributes listing

    let translatedAttributesList = this.props.spcmReducer.getListForTranslation;
    let baseLanguageAttributeList = this.props.spcmReducer.attributes;

    translatedAttributesList = _.map(
      translatedAttributesList,
      _.partialRight(_.pick, ["ATTRIBUTE_NAME", "ATTRIBUTE_VALUE", "ID"])
    );

    const newTranslatedAttributesList = [];

    translatedAttributesList.map((item) => {
      newTranslatedAttributesList.push(
        _.mapKeys(item, (value, key) => {
          let newKey = key;
          if (key === "ATTRIBUTE_NAME") {
            newKey = "TRANSLATED_ATTR_NAME";
          }

          if (key === "ATTRIBUTE_VALUE") {
            newKey = "TRANSLATED_ATTR_VALUE";
          }

          return newKey;
        })
      );
    });

    var AttributesList = _.map(baseLanguageAttributeList, function (item) {
      return _.merge(
        item,
        _.find(newTranslatedAttributesList, { ID: Number(item.ATTRIBUTE_ID) })
      );
    });

    console.log("AttributesList");
    console.log(AttributesList);

    // Component listing

    let translatedComponentsList = this.props.spcmReducer.getListForTranslation;
    let baseLanguageComponents = this.props.spcmReducer.consumptionItems;

    console.log(translatedComponentsList);
    console.log(baseLanguageComponents);

    translatedComponentsList = _.map(
      translatedComponentsList,
      _.partialRight(_.pick, [
        "COMPONENT_NAME",
        "component_description",
        "ID",
      ])
    );


    let newTranslatedComponentList = [];

    translatedComponentsList.map((item) => {
      newTranslatedComponentList.push(
        _.mapKeys(item, (value, key) => {
          let newKey = key;
          if (key === "COMPONENT_NAME") {
            newKey = "TRANSLATED_COMPONENT_NAME";
          }

          if (key === "component_description") {
            newKey = "TRANSLATED_COMPONENT_DESCRIPTION";
          }

          return newKey;
        })
      );
    });

    var ComponentsList = _.map(baseLanguageComponents, function (item) {
      return _.merge(
        item,
        _.find(newTranslatedComponentList, { ID: Number(item.COMPONENT_ID) })
      );
    });

    console.log("ComponentsList");
    console.log(ComponentsList);

    // Questions List Group List

    let translatedList = this.props.spcmReducer.getListForTranslation;
    let baseQuestionsList = this.props.spcmReducer.serviceOrderChoices;
    var baseCategoryList = this.props.spcmReducer.questions_category;

    var questionsMeta = this.props.spcmReducer.requiredQuestionMeta;
    var groupMeta = this.props.spcmReducer.requiredQuestionGroupMeta;
    var componentsMeta = this.props.spcmReducer.consumptionItemsMeta;

    console.log("translatedList");
    console.log(translatedList);

    let newTranslatedOfferingList = [];
    let newTranslatedCategoryList = [];

    if (translatedList.length > 0) {
      if (_.some(translatedList, {
        "attribute_type":
        "question_category"
      })) {
        translatedList = _.map(
          translatedList,
          _.partialRight(_.pick, [
            "attribute_value",
            "attribute_trans_value",
            "attribute_id",  
          ])
        );
        translatedList.map((item) => {
          newTranslatedCategoryList.push(
            _.mapKeys(item, (value, key) => {
              let newKey = key;
              if (key === "attribute_trans_value") {
                newKey = "ATTRIBUTE_TRANS_VALUE";
              }
              if (key === "attribute_value") {
                newKey = "ATTRIBUTE_VALUE";
              }
              if (key === "attribute_id") {
                newKey = "ATTRIBUTE_ID";
              }
              return newKey;
            })
          );
        });
      }
      // Translated list for Question
      else {
        translatedList = _.map(
          translatedList,
          _.partialRight(_.pick, [
            "QUESTION_NAME",
            "RESPONSE_VALUE",
            "ID",
            "DISPLAY_TEXT",
            "TABLE_HEADERS"
          ])
        );

        translatedList.map((item) => {
          newTranslatedOfferingList.push(
            _.mapKeys(item, (value, key) => {
              let newKey = key;
              if (key === "RESPONSE_VALUE") {
                newKey = "TRANSLATED_RESPONSE_VALUE";
              }

              if (key === "QUESTION_NAME") {
                newKey = "TRANSLATED_QUESTION_NAME";
              }
              if (key === "DISPLAY_TEXT") {
                newKey = "TRANSLATED_DISPLAY_TEXT";
              }
              if (key === "TABLE_HEADERS") {
                newKey = "TRANSLATED_TABLE_HEADERS";
              }
              return newKey;
            })
          );
        });
      }
    }


    var QuestionList = _.map(baseQuestionsList, function (item) {
      return _.merge(
        item,
        _.find(newTranslatedOfferingList, { ID: Number(item.QUESTION_ID) })
      );
    });

    console.log("QuestionList");
    console.log(QuestionList);

    var CategoryList = _.map(baseCategoryList, function (item) {
      return _.merge(
        item,
        _.find(newTranslatedCategoryList, { ATTRIBUTE_ID: Number(item.CATEGORY_ID) })
      );
    });

    console.log("CategoryList");
    console.log(CategoryList);

    // Disclaimer Listing

    let translatedDisclaimerList = this.props.spcmReducer.getListForTranslation;
    let baseLanguageDisclaimers = this.props.spcmReducer.spcmDisclaimers[0];


    if (baseLanguageDisclaimers != undefined) {
      baseLanguageDisclaimers = [
        {
          Disclaimer: "Approver Disclaimer",
          Base_Text: baseLanguageDisclaimers.APPROVER_DISCLAIMER,
          Disclaimer_Type: "approver_disclaimer",
          Offering_Id: baseLanguageDisclaimers.OFFERING_ID,
          Id: baseLanguageDisclaimers.ID,
        },
        {
          Disclaimer: "Service Disclaimer",
          Base_Text: baseLanguageDisclaimers.SERVICE_DISCLAIMER,
          Disclaimer_Type: "service_disclaimer",
          Offering_Id: baseLanguageDisclaimers.OFFERING_ID,
          Id: baseLanguageDisclaimers.ID,
        },
      ];
    }

    console.log(translatedDisclaimerList);
    console.log(baseLanguageDisclaimers);

    translatedDisclaimerList = _.map(
      translatedDisclaimerList,
      _.partialRight(_.pick, [
        "ATTRIBUTE_VALUE",
        "ATTRIBUTE_TRANS_VALUE",
        "ATTRIBUTE_ID",
        "ATTRIBUTE_TYPE",
      ])
    );

    var DisclaimerList = _.map(baseLanguageDisclaimers, function (item) {
      return _.merge(
        item,
        _.find(translatedDisclaimerList, {
          ATTRIBUTE_TYPE: item.Disclaimer_Type,
        })
      );
    });

    console.log("DisclaimerList");
    console.log(DisclaimerList);

    // Category Subcategory Listing

    // Component listing

    let translatedCatSubcat = this.props.spcmReducer.catSubcatTranslation;
    let baseCatSubtranslatedCatSubcatcat = [
      {
        category_name: this.props.spcmReducer.currentlySelectedServiceDetails
          .CATEGORY_NAME,
        id: this.props.spcmReducer.currentlySelectedServiceDetails.CATEGORY_ID,
        attribute_name: "Category",
      },
      {
        sub_category_name: this.props.spcmReducer
          .currentlySelectedServiceDetails.SUB_CATEGORY_NAME,
        id: this.props.spcmReducer.currentlySelectedServiceDetails
          .SUBCATEGORY_ID,
        attribute_name: "Sub-Category",
      },
      {
        business_function_name: this.props.spcmReducer
          .currentlySelectedServiceDetails.BUSINESS_FUNCTION_NAME,
        id: this.props.spcmReducer.currentlySelectedServiceDetails
          .BUSINESS_FUNCTION_ID,
        attribute_name: "Business-Function",
      },
    ];

    console.log(translatedCatSubcat);
    console.log(baseCatSubtranslatedCatSubcatcat);

    translatedCatSubcat = _.map(
      translatedCatSubcat,
      _.partialRight(_.pick, [
        "ATTRIBUTE_VALUE",
        "ATTRIBUTE_TRANS_VALUE",
        "ATTRIBUTE_ID",
      ])
    );

    var CatSubcatList = _.map(
      baseCatSubtranslatedCatSubcatcat,
      function (item) {
        return _.merge(
          item,
          _.find(translatedCatSubcat, { ATTRIBUTE_ID: item.id })
        );
      }
    );

    console.log("CatSubcatList");
    console.log(CatSubcatList);

    console.log(this.props.spcmReducer.currentlySelectedServiceDetails);
    return (
      <div className="rePanelGp">
        <CloseBtnRightPanel showRightSide={this.props.showRightSide}/>
        <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">
          Translation Review for {this.props.lanNameVal1}{" "}
        </div></div>
        <div className="rBoxGap">
        <div className="margin-b-20">
          <div className="keyVal">
            <span className="key">Base Language</span>
            <span className="val">{baseLanguage}</span>
          </div>
          <div className="keyVal">
            <span className="key">Name</span>
            <span className="val">
              {nameDescSummaryTranslation.offering_name}
            </span>
          </div>
        </div>

        <Accordion className="brkFix brkFixOrder" defaultActiveKey="1" id="reviewID2">
          <Accordion.Item key={0} eventKey={0}>
            <Accordion.Header>
             <h4> {"Short Description"} </h4>
            </Accordion.Header>
            <Accordion.Body>
              <Table
                className="langTable tableView reviewTrans tableView"
                striped
                bordered
                responsive
              >
                <thead>
                  <tr>
                    <th width="50%">English</th>
                    <th width="50%">{this.props.lanNameVal1}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td width="50%">
                      {
                        <div
                          className=""
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(this.props.spcmReducer.currentlySelectedServiceDetails.SUMMARY, {allowedAttributes: {
                              '*': ['style', 'class', 'dir', 'bgcolor'],
                              'table': ['border', 'cellpadding', 'cellspacing'],
                              'th': ['scope']
                            }}),
                          }}
                        ></div>
                      }
                    </td>
                    <td width="50%">
                      {
                        <div
                          className=""
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(nameDescSummaryTranslation.summary, {allowedAttributes: {
                              '*': ['style', 'class', 'dir', 'bgcolor'],
                              'table': ['border', 'cellpadding', 'cellspacing'],
                              'th': ['scope']
                            }}),
                          }}
                        ></div>
                      }
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item key={1} eventKey={1}>
            <Accordion.Header>
              {"Description"}
            </Accordion.Header>
            <Accordion.Body>
              <Table
                className="langTable tableView reviewTrans tableView"
                striped
                bordered
                responsive
              >
                <thead>
                  <tr>
                    <th width="50%">English</th>
                    <th width="50%">{this.props.lanNameVal1}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td width="50%">
                      {
                        <div
                          className=""
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(this.props.spcmReducer.currentlySelectedServiceDetails.DESCRIPTION, {allowedAttributes: {
                              '*': ['style', 'class', 'dir', 'bgcolor'],
                              'table': ['border', 'cellpadding', 'cellspacing'],
                              'th': ['scope']
                            }}),
                          }}
                        ></div>
                      }
                    </td>
                    <td width="50%">
                      {
                        <div
                          className=""
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(nameDescSummaryTranslation.description, {allowedAttributes: {
                              '*': ['style', 'class', 'dir', 'bgcolor'],
                              'table': ['border', 'cellpadding', 'cellspacing'],
                              'th': ['scope']
                            }}),
                          }}
                        ></div>
                      }
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item key={8} eventKey={8}>
            <Accordion.Header>
              {"Search Tags"}
            </Accordion.Header>
            <Accordion.Body>
              <Table
                className="langTable tableView reviewTrans tableView"
                striped
                bordered
                responsive
              >
                <thead>
                  <tr>
                    <th width="50%">English</th>
                    <th width="50%">{this.props.lanNameVal1}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td width="50%">
                      {
                        <div
                          className=""
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(this.props.spcmReducer.currentlySelectedServiceDetails.TAGS, {allowedAttributes: {
                              '*': ['style', 'class', 'dir', 'bgcolor'],
                              'table': ['border', 'cellpadding', 'cellspacing'],
                              'th': ['scope']
                            }}),
                          }}
                        ></div>
                      }
                    </td>
                    <td width="50%">
                      {
                        <div
                          className=""
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(nameDescSummaryTranslation.tags, {allowedAttributes: {
                              '*': ['style', 'class', 'dir', 'bgcolor'],
                              'table': ['border', 'cellpadding', 'cellspacing'],
                              'th': ['scope']
                            }}),
                          }}
                        ></div>
                      }
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item key={2} eventKey={2}>
            <Accordion.Header  onClick={() => {
                  this.props.getTranslationForOfferingBasedOnType(
                    this.props.spcmReducer.currentlySelectedServiceDetails
                      .OFFERING_ID,
                    this.props.lanIdVal1,
                    "attributes"
                  );
                  this.props.getAttributes(
                    this.props.spcmReducer.currentlySelectedServiceDetails
                      .OFFERING_ID,
                    "",
                    "",
                    ""
                  );
                }}>
              <h4>
                {"Attributes"}
              </h4>
            </Accordion.Header>
            <Accordion.Body>
              {this.props.spcmReducer.spcmFetchingAttribute ? (
                <ListLoader />
              ) : (
                AttributesList.map((value, index) => {
                  return (
                    <Table
                      className="langTable tableView reviewTrans tableView"
                      striped
                      bordered
                      responsive
                    >
                      <thead>
                        <tr>
                          <th width="30%"></th>
                          <th width="35%">English</th>
                          <th width="35%">{this.props.lanNameVal1}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="bgCell">Attribute</td>
                          <td>{value.ATTRIBUTE_NAME}</td>
                          <td>{value.TRANSLATED_ATTR_NAME}</td>
                        </tr>
                        <tr>
                          <td className="bgCell">Value</td>
                          <td>{value.ATTRIBUTE_VALUE}</td>
                          <td>{value.TRANSLATED_ATTR_VALUE}</td>
                        </tr>
                      </tbody>
                    </Table>
                  );
                })
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item key={3} eventKey={3}>
            <Accordion.Header
                onClick={() => {
                  this.props.getTranslationForOfferingBasedOnType(
                    this.props.spcmReducer.currentlySelectedServiceDetails
                      .OFFERING_ID,
                    this.props.lanIdVal1,
                    "component"
                  );
                  this.props.getConsumptionItems(
                    this.props.spcmReducer.currentlySelectedServiceDetails
                      .OFFERING_ID
                  );
                }}>
              <h4 >
                {"Components"}
              </h4>
            </Accordion.Header>
            <Accordion.Body>
              {this.props.spcmReducer.spcmFetchingAttribute ? (
                <ListLoader />
              ) : (
                <div>
                  <Table
                    className="langTable tableView reviewTrans tableView"
                    striped
                    bordered
                    responsive
                  >
                    <thead>
                      <tr>
                        <th width="50%">English</th>
                        <th width="50%">{this.props.lanNameVal1}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ComponentsList.map((value, index) => {
                        return (

                          <tr>
                            <td style={{ "fontSize": "13px" }} width="50%">
                              <div style={{ "marginBottom": "5px" }}><font style={{ 'color': '#8a8a8a' }}>Component Name -</font> {value.COMPONENT_NAME}</div>
                              <div><font style={{ 'color': '#8a8a8a' }}>Component Description -</font> {value.DESCRIPTION}</div>

                            </td>
                            <td style={{ "fontSize": "13px" }}>
                              {
                                value.TRANSLATED_COMPONENT_NAME ? <div style={{ "marginBottom": "5px" }}><font style={{ 'color': '#8a8a8a' }}>Component Name -</font> {value.TRANSLATED_COMPONENT_NAME}</div> : null
                              }
                              {value.TRANSLATED_COMPONENT_DESCRIPTION ? <div><font style={{ 'color': '#8a8a8a' }}>Component Description -</font> {value.TRANSLATED_COMPONENT_DESCRIPTION}</div> : null}

                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <div className="nBotPagina"><nav aria-label="Pagination" className="display-inline-block">
                    {componentsMeta ? (
                      <Pagination
                        activePage={
                          componentsMeta ? componentsMeta.currentPage : 1
                        }
                        itemsCountPerPage={this.state.pageSize}
                        totalItemsCount={
                          componentsMeta ? componentsMeta.rowCount : 1
                        }
                        onChange={(e) => {
                          this.onPageChange("componentsList", e);
                        }}
                      />
                    ) : null}
                  </nav></div>
                </div>
              )}
            </Accordion.Body>
          </Accordion.Item>
{/* { */}
{/* // workItemType == "incidentRequest"? "": */}
          <Accordion.Item key={4} eventKey={4}>
            <Accordion.Header>
              <h4>{"Ordering Information"}</h4>
            </Accordion.Header>
            <Accordion.Body collapsible>
              <Accordion defaultActiveKey="1" accordion id="orderingInfo">
                <Accordion.Item key={0} eventKey={0}>
                  <Accordion.Header 
                      onClick={() => {
                        this.props.getTranslationForOfferingBasedOnType(
                          this.props.spcmReducer.currentlySelectedServiceDetails
                            .OFFERING_ID,
                          this.props.lanIdVal1,
                          "question"
                        );
                        this.props.getServiceOrderChoices(
                          this.props.spcmReducer.currentlySelectedServiceDetails
                            .OFFERING_ID,
                          "",
                          "",
                          "Question"
                        );
                      }}
                    >
                    <h4>
                      Questions
                    </h4>
                  </Accordion.Header>
                  <Accordion.Body>
                    {this.props.spcmReducer.spcmFetchingAttribute ? (
                      <ListLoader />
                    ) : (
                      <div>
                        <Table
                          className="langTable tableView reviewTrans tableView"
                          striped
                          bordered
                          responsive
                        >
                          <thead>
                            <tr>
                              <th width="35%">English</th>
                              <th width="35%">{this.props.lanNameVal1}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {QuestionList.map((value, index) => {
                              return (
                                <tr>
                                  <td style={{ "fontSize": "13px" }} width="50%">
                                    <div style={{ "marginBottom": "5px" }}><font style={{ 'color': '#8a8a8a' }}>Question Text -</font> {value.QUESTION_TEXT}</div>
                                    {/* {value.RESPONSE_VALUE ? <div><font style={{ 'color': '#8a8a8a' }}>Response Value -</font> {value.RESPONSE_VALUE}</div> : null} */}
                                    {value.RESPONSE_VALUE ? <div><font style={{ 'color': '#8a8a8a' }}>Response Value -</font> {value.RESPONSE_VALUE}</div> : value.DISPLAY_TEXT ? <div><font style={{ 'color': '#8a8a8a' }}>Response Value -</font> {value.DISPLAY_TEXT.replace( /(<([^>]+)>)/ig, '')}</div>:null}
                                    {value.TABLE_HEADERS ? <div><font style={{ 'color': '#8a8a8a' }}>Table Headers -</font> {value.TABLE_HEADERS}</div> : null}

                                  </td>
                                  <td style={{ "fontSize": "13px" }}>
                                    {
                                      value.TRANSLATED_QUESTION_NAME ? <div style={{ "marginBottom": "5px" }}><font style={{ 'color': '#8a8a8a' }}>Question Text -</font> {value.TRANSLATED_QUESTION_NAME}</div> : null
                                    }
                                    {/* {value.TRANSLATED_RESPONSE_VALUE ? <div><font style={{ 'color': '#8a8a8a' }}>Response Value -</font> {value.TRANSLATED_RESPONSE_VALUE}</div> : null} */}
                                    {value.TRANSLATED_RESPONSE_VALUE ? <div><font style={{ 'color': '#8a8a8a' }}>Response Value -</font> {value.TRANSLATED_RESPONSE_VALUE}</div> : value.TRANSLATED_DISPLAY_TEXT ? <div><font style={{ 'color': '#8a8a8a' }}>Response Value -</font> {value.TRANSLATED_DISPLAY_TEXT}</div>:null}
                                    {value.TRANSLATED_TABLE_HEADERS ? <div><font style={{ 'color': '#8a8a8a' }}>Table Headers -</font> {value.TRANSLATED_TABLE_HEADERS}</div> :null}
                                  
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                        <div className="nBotPagina"><nav aria-label="Pagination" className="display-inline-block">
                          {questionsMeta ? (
                            <Pagination
                              activePage={
                                questionsMeta ? questionsMeta.currentPage : 1
                              }
                              itemsCountPerPage={this.state.pageSize}
                              totalItemsCount={
                                questionsMeta ? questionsMeta.rowCount : 1
                              }
                              onChange={(e) => {
                                this.onPageChange("questList", e);
                              }}
                            />
                          ) : null}
                        </nav></div>
                      </div>
                    )}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item key={1} eventKey={1}>
                  <Accordion.Header
                      onClick={() => {
                        this.props.getTranslationForOfferingBasedOnType(
                          this.props.spcmReducer.currentlySelectedServiceDetails
                            .OFFERING_ID,
                          this.props.lanIdVal1,
                          "question_category"
                        );
                        this.props.getQuestionsCategory(
                          this.props.spcmReducer.currentlySelectedServiceDetails
                            .OFFERING_ID
                        );
                      }}
                    >
                    <h4>
                      Group
                    </h4>
                  </Accordion.Header>
                  <Accordion.Body collapsible>
                    {this.props.spcmReducer.spcmFetchingAttribute ? (
                      <ListLoader />
                    ) : (
                      <div>
                        <Table
                          className="langTable tableView reviewTrans tableView"
                          striped
                          bordered
                          responsive
                        >
                          <thead>
                            <tr>
                              <th width="35%">English</th>
                              <th width="35%">{this.props.lanNameVal1}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {CategoryList.map((value, index) => {
                              return (
                                <tr>
                                  <td width="50%">{value.CATEGORY_NAME}</td>
                                  <td>{value.ATTRIBUTE_TRANS_VALUE}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                        <div className="nBotPagina"><nav aria-label="Pagination" className="display-inline-block">
                          {groupMeta ? (
                            <Pagination
                              activePage={groupMeta ? groupMeta.currentPage : 1}
                              itemsCountPerPage={this.state.pageSize}
                              totalItemsCount={
                                groupMeta ? groupMeta.rowCount : 1
                              }
                              onChange={(e) => {
                                this.onPageChange("groupList", e);
                              }}
                            />
                          ) : null}
                        </nav></div>
                      </div>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Accordion.Body>
          </Accordion.Item>
          {/* // } */}

{workItemType == "incidentRequest"? "":
          <Accordion.Item key={5} eventKey={5}>
            <Accordion.Header   onClick={() => {
                this.props.getTranslationForOfferingBasedOnType(
                  this.props.spcmReducer.currentlySelectedServiceDetails
                    .OFFERING_ID,
                  this.props.lanIdVal1,
                  "disclaimer"
                );
                }}>
              
              <h4>
                {"Disclaimer"}
              </h4>
            </Accordion.Header>
            <Accordion.Body>
              {this.props.spcmReducer.spcmFetchingAttribute ? (
                <ListLoader />
              ) : (
                <Table
                  className="langTable tableView reviewTrans tableView"
                  striped
                  bordered
                  responsive
                >
                  <thead>
                    <tr>
                      <th width="30%"></th>
                      <th width="35%">English</th>
                      <th width="35%">{this.props.lanNameVal1}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {DisclaimerList.map((value, index) => {
                      return (
                        <tr>
                          <td className="bgCell">{value.Disclaimer}</td>
                          <td>{
                            <div
                              className=""
                              dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(value.ATTRIBUTE_VALUE, {allowedAttributes: {
                                  '*': ['style', 'class', 'dir', 'bgcolor'],
                                  'table': ['border', 'cellpadding', 'cellspacing'],
                                  'th': ['scope']
                                }}),
                              }}
                            ></div>
                          }
                          </td>
                          <td>
                            {
                              <div
                                className=""
                                dangerouslySetInnerHTML={{
                                  __html: sanitizeHtml(value.ATTRIBUTE_TRANS_VALUE, {allowedAttributes: {
                                    '*': ['style', 'class', 'dir', 'bgcolor'],
                                    'table': ['border', 'cellpadding', 'cellspacing'],
                                    'th': ['scope']
                                  }}),
                                }}
                              ></div>
                            }
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </Accordion.Body>
          </Accordion.Item>
  }
          {/* <Panel key={6} eventKey={6}>
            <Panel.Heading>
              <Panel.Title toggle>Fulfilment Plan</Panel.Title>
            </Panel.Heading>
            <Panel.Body collapsible>
              <Table
                className="langTable tableView reviewTrans tableView"
                striped
                bordered
                responsive
              >
                <thead>
                  <tr>
                    <th width="50%">English</th>
                    <th width="50%">{this.props.lanNameVal1}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Amazon m1.small - Virtual Server Running on Amazon's
                      Computing Environment
                    </td>
                    <td>
                      Amazon m1.small - Serveur virtuel fonctionnant sur l'envi
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Panel.Body>
                    </Panel>*/}
          <Accordion.Item key={7} eventKey={7}>
            <Accordion.Header onClick={() => {
                  this.props.getLanguageTranslationForCategoryAndSubcategory(
                    this.props.spcmReducer.currentlySelectedServiceDetails
                      .OFFERING_ID,
                    this.props.lanIdVal1
                  );
                }}
              >
              <h4>
                {"Category And Business Function"}
              </h4>
            </Accordion.Header>
            <Accordion.Body>
              {this.props.spcmReducer.spcmFetchingAttribute ? (
                <ListLoader />
              ) : (
                <Table
                  className="langTable tableView reviewTrans tableView"
                  striped
                  bordered
                  responsive
                >
                  <thead>
                    <tr>
                      <th width="30%"></th>
                      <th width="35%">English</th>
                      <th width="35%">{this.props.lanNameVal1}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {CatSubcatList.map((value, index) => {
                      return (
                        <tr>
                          <td className="bgCell">{value.attribute_name}</td>
                          <td>{value.ATTRIBUTE_VALUE}</td>
                          <td>{value.ATTRIBUTE_TRANS_VALUE}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        {/* <PanelGroup defaultActiveKey="1" accordion id="reviewID">
          {data.map((item, index) =>
            index == 4 ? (
              <Panel key={index} eventKey={index}>
                <Panel.Heading>
                  <Panel.Title toggle>{item.name}</Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                  <Table
                    className="langTable tableView reviewTrans tableView"
                    striped
                    bordered
                    responsive
                  >
                    <thead>
                      <tr>
                        <th width="30%"></th>
                        <th width="35%">English</th>
                        <th width="35%">{this.props.lanNameVal1}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="bgCell">Model</td>
                        <td>t2.micro</td>
                        <td>fonctionnant sur l'envi</td>
                      </tr>
                      <tr>
                        <td className="bgCell">Storage</td>
                        <td>EBS Only</td>
                        <td>Sur envi</td>
                      </tr>
                    </tbody>
                  </Table>
                </Panel.Body>
              </Panel>
            ) : (
              <Panel key={index} eventKey={index}>
                <Panel.Heading>
                  <Panel.Title toggle>{item.name}</Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                  <Table
                    className="langTable tableView reviewTrans tableView"
                    striped
                    bordered
                    responsive
                  >
                    <thead>
                      <tr>
                        <th width="50%">English</th>
                        <th width="50%">{this.props.lanNameVal1}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Amazon m1.small - Virtual Server Running on Amazon's
                          Computing Environment
                        </td>
                        <td>
                          Amazon m1.small - Serveur virtuel fonctionnant sur
                          l'envi
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Panel.Body>
              </Panel>
            )
          )}
        </PanelGroup> */}

        <Row className="margn-t-10">
          <Col md={12}>
            <div className="checkBoxInput">
              <label>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    console.log("checked");
                    this.setState({ publish: e.target.checked });
                  }}
                  checked={this.state.publish}
                />{" "}
                Translations Reviewed
              </label>
            </div>
          </Col>
          <Col md={12}>
            <ButtonToolbar className="black">
              <Button
                title="Save"
                disabled={this.props.spcmReducer.spcmPostingAttribute}
                type="button"
                bsSize="small"
                bsStyle="primary"
                onClick={() => {
                  this.submitForm();
                }}
              >
                {this.props.spcmReducer.spcmPostingAttribute ? (
                  <ImSpinner6 className="icn-spinner"/>
                ) : null}{" "}
                {tr["Save"]}
              </Button>
              <Button
                onClick={() => {
                  this.props.reviewShowFun4(false);
                }}
                bsSize="small"
                bsStyle="text"
              >
                Close
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>
      </div>
      </div>
    );
  }
}

const mapStateToProps = ({ spcmReducer }) => {
  return {
    spcmReducer,
  };
};

export default connect(mapStateToProps, {
  spcmLoader,
  getTranslationForOfferingNameDescriptionSummary,
  getLanguageTranslationForCategoryAndSubcategory,
  getAttributes,
  getConsumptionItems,
  getServiceOrderChoices,
  getQuestionsCategory,
  languageListTranslationStatus,
  getTranslationForOfferingBasedOnType,
})(ReviewPage);
