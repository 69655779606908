
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import { Row, Col, Container } from 'react-bootstrap';

import PindCategories from "../../../common/header/pind-categories";
import Breadcrumbs from "../../../common/header/breadcrumbs";
import Header from "./rosterListHeader";
import List from "./rosterList";
import ShortDesc from "./rosterListShortDesc";
import { loadAllRosterList } from '_Actions/onCallSchedule/roster/onCallRosterAction';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useDispatch } from "react-redux";
import "_Css/common/_table.scss";

const Index = () => {
  const dispatch = useDispatch();
  const [isRightSideVisible, setRightSideVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [rowIndex, setRowIndex] = useState(0);
  const [nameSearchInput, setNameSearchInput] = useState("");
  const [shiftSearchInput, setShiftSearchInput] = useState("");
  const [fromSearchInput, setFromSearchInput] = useState("");
  const [toSearchInput, setToSearchInput] = useState("");
  const [activePage, setActivePage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filters, setFilters] = useState({});
  const [selectedGroupList, setSelectedGroupList] = useState([]);
  const [fromRef, setFromRef] = useState(null);
  const [toRef, setToRef] = useState(null);

  let colWidth = "";
  isRightSideVisible ? (colWidth = 8) : (colWidth = 12);

  const showActiveRow = (i) => {
    setRowIndex(i);
  };

  const showRightSideFun = (val) => {
    setRightSideVisible(val);
  };

  const setSelectedItemFun = (data) => {
    setSelectedItem(data);
  };

  const setRef = (type, ref) => {
    if (type == "from") {
      setFromRef(ref);
    } else if ((type = "to")) {
      setToRef(ref);
    }
  };

  const resetFilters = (crossButton = false) => {
    setIsFilterApplied(false);
    setNameSearchInput("");
    setShiftSearchInput("");
    setFromSearchInput("");
    setToSearchInput("");
    setFilters({});
    setActivePage(0);
    setItemsPerPage(10);
    if (fromRef) {
      fromRef.endDate = moment();
      fromRef.startDate = moment();
    }
    if (toRef) {
      toRef.endDate = moment();
      toRef.startDate = moment();
    }
    if (selectedGroupList && selectedGroupList.length > 0 && !crossButton) {
      let filter = { page: 0, size: 10, groupId: selectedGroupList[0].id };
      dispatch(loadAllRosterList(filter));
    }
  };

  const groupCrossClicked = () => {
    setSelectedGroupList([]);
    setRightSideVisible(false);
    resetFilters(true);
  };

  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Breadcrumbs activePageName="On Call Schedule" />
      </Container>

      <Container fluid>
        <Header
          showRightSideFun={showRightSideFun}
          isFilterApplied={isFilterApplied}
          resetFilters={resetFilters}
          filters={filters}
          setActivePage={setActivePage}
          selectedGroupList={selectedGroupList}
          setSelectedGroupList={setSelectedGroupList}
          groupCrossClicked={groupCrossClicked}
        />
        <PanelGroup direction="horizontal" className="panelOverflow">
          <Panel
            id="sidebar"
            minSize={30}
            order={1}
            defaultSize={isRightSideVisible ? 67 : 100}
            className={
              isRightSideVisible ? "isShowLeftPanel catBordDv" : "catBordDv"
            }
          >
            <List
              isRightSideVisible={isRightSideVisible}
              showRightSideFun={showRightSideFun}
              setSelectedItemFun={setSelectedItemFun}
              rowIndex={rowIndex}
              showActiveRow={showActiveRow}
              nameSearchInput={nameSearchInput}
              shiftSearchInput={shiftSearchInput}
              fromSearchInput={fromSearchInput}
              toSearchInput={toSearchInput}
              activePage={activePage}
              itemsPerPage={itemsPerPage}
              filters={filters}
              setNameSearchInput={setNameSearchInput}
              setShiftSearchInput={setShiftSearchInput}
              setFromSearchInput={setFromSearchInput}
              setToSearchInput={setToSearchInput}
              setActivePage={setActivePage}
              setItemsPerPage={setItemsPerPage}
              setFilters={setFilters}
              setIsFilterApplied={setIsFilterApplied}
              selectedGroupList={selectedGroupList}
              setRef={setRef}
            />
          </Panel>
          {isRightSideVisible ? (
            <>
              <PanelResizeHandle className="isShowLeftPanel resizeHandle">
                <div className="outlne">
                  <div className="handIcn">
                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                  </div>
                </div>
              </PanelResizeHandle>
              <Panel minSize={33} order={2} defaultSize={33}>
                <div className="stickyArea rBoxStyle">
                  <ShortDesc
                    showRightSideFun={showRightSideFun}
                    selectedItem={selectedItem}
                  />
                </div>
              </Panel>
            </>
          ) : (
            ""
          )}
        </PanelGroup>
      </Container>
    </main>
  );
}

export default Index;
