
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { GLOBAL } from "_Globals";
import axios from "axios";
let sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
import { reduxForm, SubmissionError } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import { updateDashboardData } from "../../../actions/dashboardConfigration/dashboardConfigAction";
import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import { MyContext } from '_MyContext';

const EditDashboardHeader = (props) => {
  const spcmReducer = useSelector((state) => state.spcmReducer);
  const [roles, setRoles] = useState([]);
  const dispatch = useDispatch();
  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const saveObjRef = useRef(null);

  useEffect(() => {
      context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
      context.newTabPrevent();
    }, []);

  let { handleSubmit, submitting } = props;
  const submit = async (values) => {
    let countArr = values.dynamicFieldArr;
    let dynamicFieldValues = countArr.reduce((acc, curr) => {
      acc.push({
        companyIdAttr: values[`companyIdAttr_${curr}`] || "",
        groupIdAttr: values[`groupIdAttr_${curr}`] || "",
        dataSetId: values[`dataSetId_${curr}`]
      })
      return acc;
    }, []);
    // console.log('dynamicFieldValues ---- ', dynamicFieldValues)

    return sleep(1000).then(() => {
      if (values.roles == "" || values.roles.length === 0) {
        props.setValueError('error');
        throw new SubmissionError({
          _error:
            "The form has missing required fields, please revalidate the highlighted fields and update the form",
        });
      } else {
        let postjson;
        const id = window.location.href.split("/").pop();

        let RolesData = [];
        if (typeof values.roles === "string") {
          values.roles = values.roles.split(",");
        }
        values.roles.map((ele) => {
          if (ele.value) {
            RolesData.push(ele.label);
          } else {
            RolesData.push(ele);
          }
        });
        let role = [];
        roles.map((data) => {
          if (RolesData.includes(data.field1Key)) {
            role.push(data.field1Value);
          }
        });

        let masterRoles = [];
        let mRole = [];
        if (values.masterRoles) {
          if (typeof values.masterRoles === "string") {
            values.masterRoles = values.masterRoles?.split(",");
          }
          values.masterRoles.map((ele) => {
            if (ele.value) {
              masterRoles.push(ele.label);
            } else {
              masterRoles.push(ele);
            }
          });
          roles.map((role) => {
            if (masterRoles.includes(role.field1Key)) {
              mRole.push(role.field1Value);
            }
          });
        }

        postjson = {
          id,
          active: values.Active == "True" ? true : false,
          classification: values.Classification,
          dashboardResId: values.DashboardResourceID,
          dashboardUUID: values.DashboardUUID,
          dashboardId: values.Dashboard_ID,
          datasetId: values.DatasetID,
          groupIdAttr: values.GroupIDField,
          companyIdAttr: values.CompanyIDField,
          firstName: values.InstanceUserFirstName,
          lastName: values.InstanceUserLastName,
          userName: values.InstanceUserUserName,
          label: values.Name,
          reportType: values.Report_Type,
          reportFilters: values.ReportFilters,
          masterRoles: mRole.toString(),
          roles: role.toString(),
          widgetType: values.WidgetType,
          compidGrpidDsid: dynamicFieldValues
        };
        dispatch(updateDashboardData(postjson));
      }
    });
  };

  useEffect(() => {
    axios
      .get("/getAllUserRoles", { params: { type: "role" } })
      .then((res) => setRoles(res.data));
  }, []);

  return (
    <Row className="margin-b-15">
      <Col sm={8} xs={12}>
        <h1 bsClass="" className="sPageHeading1">
          {props.translator["Superset Dashboard"]}
        </h1>
      </Col>
      <Col sm={4} xs={12}>
        <div className="paHedFilter">
          {/* <Button role="button" bsPrefix=' '  className="myBt rclose" title={props.translator["Delete"]} onClick={onDeleteClick}>
            <i aria-hidden="true" className="fa fa-trash-o"></i>
          </Button> */}
          <Link to="javascript:void(0)"
            bsPrefix=" "
            className="myBt plus ctrlKeyPrevent"
            onClick={handleSubmit(submit)}
            disabled={submitting}
            title={props.translator["Save"]}
            ref={saveObjRef}
          >
           <span>{submitting ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/>}</span>
          </Link>
          <Link
            bsPrefix=" " 
            className="myBt cancel fillBtn ctrlKeyPrevent"
            title={props.translator["Close"]}
            to={GLOBAL.reportsConfiguration}
            ref={closeButton}
          >
            <IoClose/>
          </Link>
        </div>
      </Col>
    </Row>
  );
};

export default reduxForm({
  form: "supersetDashboardForm",
  destroyOnUnmount: false,
})(EditDashboardHeader);
