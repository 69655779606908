
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Accordion, Tab, Tabs, ListGroup, ButtonToolbar, Button, Form, Alert } from 'react-bootstrap';
import { Field, reduxForm, SubmissionError, change, formValueSelector, untouch } from "redux-form";
import { _textArea, _dropDown, _inputField, _checkbox, _number, _dynamicNamedropDown } from "../../../../common/formFields.js";
import { useDispatch, useSelector, connect } from 'react-redux';
import { useNavigate } from "react-router";
import { PiPencilSimpleBold } from "react-icons/pi";
import { HiPlus } from "react-icons/hi"
import { IoClose } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { loadOnCallSXMenulistData } from '_Actions/onCallSchedule/common/onCallCommonAction';
import ListLoader from "../../../../common/loaders/ListLoader.js"
import { _dateTimeField, _dateTimeFieldGeneral } from "../../../../common/ReduxFormFields/formFields";
import axios from 'axios';
import moment from "moment";
import swal from 'sweetalert';
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import {ImSpinner6} from "react-icons/im";
const cookies = new Cookies();

let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

const validate = (values) => {
  const errors = {};
  if (!values.scheduleName) {
    errors.scheduleName = "Please enter schedule name";
  }
  if (!values.scheduleType) {
    errors.scheduleType = "Please select schedule type";
  }
  if (!values.onCallStatus) {
    errors.onCallStatus = "Please select status";
  }
  if (!values.scheduleStartDate) {
    errors.scheduleStartDate = true;
  }
  if (!values.scheduleEndDate) {
    errors.scheduleEndDate = true;
  }
  if (!values.scheduleRepeatType) {
    errors.scheduleRepeatType = "Please select repeat type";
  }
  return errors;
}

const RightSide = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const translator = useSelector((state) => state.spcmReducer.tr);
  const onCallCommonReducer = useSelector((state) => state.on_call_common_reducer);
  const on_call_schedule_loader_reducer = useSelector((state) => state.on_call_schedule_loader_reducer);
  const scheduleDetailsByScheduleId = useSelector((state) => state.on_call_schedule_reducer.scheduleDetailsByScheduleId);

  const selector = formValueSelector('onCallScheduleAssociationForm');
  const scheduleRepeatTypeParam = useSelector(state => selector(state, 'scheduleRepeatType'));

  const [saveLoader, setSaveLoader] = useState(false);
  const [allDaySchedule, setAllDaySchedule] = useState(false);
  const [repeatsOn, setRepeatsOn] = useState({
    'SU': false,
    'MO': false,
    'TU': false,
    'WE': false,
    'TH': false,
    'FR': false,
    'SA': false
  });
  const [repeatsOnError, setRepeatsOnError] = useState(false);

  let scheduleTypeValues = useSelector((state) => state.on_call_common_reducer.scheduleType);
  // scheduleTypeValues = scheduleTypeValues.filter((item) => item.field1Key == 'Holiday' || item.field1Key == 'Workday');

  useEffect(() => {
    dispatch(loadOnCallSXMenulistData('AIOSEM', 'Frequency'));
    if (props.initialValues && props.initialValues.scheduleType) {
      dispatch(change('onCallScheduleAssociationForm', 'scheduleType', props.initialValues.scheduleType));
    } else {
      dispatch(change('onCallScheduleAssociationForm', 'scheduleType', scheduleDetailsByScheduleId.scheduleType));
    }
    if (props.initialValues && props.initialValues.onCallStatus) {
      dispatch(change('onCallScheduleAssociationForm', 'onCallStatus', props.initialValues.onCallStatus));
    } else {
      dispatch(change('onCallScheduleAssociationForm', 'onCallStatus', 'Active'));
    }
    if (props.initialValues && props.initialValues.is24by7) {
      dispatch(change('onCallScheduleAssociationForm', 'is24by7', true));
      setAllDaySchedule(true);
    } else {
      dispatch(change('onCallScheduleAssociationForm', 'is24by7', false));
      setAllDaySchedule(false);
    }
    if (props.initialValues && props.initialValues.byDay && props.initialValues.byDay.length > 0) {
      let arr = props.initialValues.byDay.split(',');
      let obj = { ...repeatsOn };
      arr.forEach((item) => {
        obj[item] = true;
      })
      setRepeatsOn(obj);
    }
  }, []);

  function setRepeatsOnFun(val) {
    let obj = { ...repeatsOn };
    obj[val] = !obj[val];
    setRepeatsOn(obj)
  }

  function postPatchScheduleAssociation(values) {
    let repeatsOnValue = '';
    if (values.scheduleRepeatType == 'Specific') {
      for (let key in repeatsOn) {
        if (repeatsOn[key] == true)
          repeatsOnValue = repeatsOnValue + key + ',';
      }
      if (repeatsOnValue == '') {
        setRepeatsOnError(true);
        return false;
      } else {
        setRepeatsOnError(false);
        repeatsOnValue = repeatsOnValue.substring(0, repeatsOnValue.lastIndexOf(","));
      }
    } else {
      setRepeatsOnError(false);
    }
    if (props.addEditMode == 'add') {
      if (values.scheduleStartDate.format("X") > values.scheduleEndDate.format("X")) {
        Swal.fire("Schedule start date should be greater than Schedule end date!");
        return false;
      }
      let postObj = {};
      postObj["sxScheduleId"] = props.scheduleId;
      postObj["scheduleAssociationName"] = values.scheduleName;
      postObj["scheduleType"] = values.scheduleType;
      postObj["scheduleStatus"] = values.onCallStatus;
      postObj["scheduleRepeatType"] = values.scheduleRepeatType;
      postObj["scheduleRepeat"] = 'Yes';
      postObj["byDay"] = repeatsOnValue;
      postObj["scheduleStartDate"] = values.scheduleStartDate.valueOf();
      postObj["scheduleEndDate"] = values.scheduleEndDate.valueOf();
      postObj["repeatUntil"] = values.scheduleEndDate.valueOf();
      if (allDaySchedule) {
        postObj["scheduleStartTime"] = "00:00:00";
        postObj["scheduleEndTime"] = "23:59:59";
        postObj["is24by7"] = true;
      } else {
        postObj["scheduleStartTime"] = moment(values.scheduleStartDate).format("HH:mm:ss");
        postObj["scheduleEndTime"] = moment(values.scheduleEndDate).format("HH:mm:ss");
        postObj["is24by7"] = false;
      }
      setSaveLoader(true);
      axios.post('/rest/aiosem/saveScheduleAssociationAction', postObj).then(function (response) {
        if (response.status == 200) {
          setSaveLoader(false);
          props.setActionViewFun("scheduleAssociationList");
        }
      }).catch(function (error) {
        setSaveLoader(false);
        swal({
          text: error.response.data.businessMessage,
          button: translator['OK'],
        });
      });
    }
    else if (props.addEditMode == 'edit') {
      let patchObj = {};
      patchObj["sxScheduleId"] = props.scheduleId;
      patchObj["scheduleAssociationName"] = values.scheduleName;
      patchObj["scheduleType"] = values.scheduleType;
      patchObj["scheduleStatus"] = values.onCallStatus;
      patchObj["scheduleRepeatType"] = values.scheduleRepeatType;
      patchObj["scheduleRepeat"] = 'Yes';
      patchObj["byDay"] = repeatsOnValue;
      patchObj["scheduleStartDate"] = moment(values.scheduleStartDate, jwtdf + ' HH:mm:ss.SSS').valueOf();
      patchObj["scheduleEndDate"] = moment(values.scheduleEndDate, jwtdf + ' HH:mm:ss.SSS').valueOf();
      patchObj["repeatUntil"] = moment(values.scheduleEndDate, jwtdf + ' HH:mm:ss.SSS').valueOf();
      if (allDaySchedule) {
        patchObj["scheduleStartTime"] = "00:00:00";
        patchObj["scheduleEndTime"] = "23:59:59";
        patchObj["is24by7"] = true;
      } else {
        patchObj["scheduleStartTime"] = moment(values.scheduleStartDate, jwtdf + ' HH:mm:ss.SSS').format("HH:mm:ss");
        patchObj["scheduleEndTime"] = moment(values.scheduleEndDate, jwtdf + ' HH:mm:ss.SSS').format("HH:mm:ss");
        patchObj["is24by7"] = false;
      }
      setSaveLoader(true);
      axios.patch(`/rest/aiosem/updateScheduleAssociationAction/${props.initialValues.saId}`, patchObj).then(function (response) {
        if (response.status == 200) {
          setSaveLoader(false);
          props.setActionViewFun("scheduleAssociationList");
        }
      }).catch(function (error) {
        setSaveLoader(false);
        swal({
          text: error.response.data.businessMessage,
          button: translator['OK'],
        });
      });
    }
  }

  if (on_call_schedule_loader_reducer.showScheduleListLoader) {
    return <ListLoader />;
  }
  else {
    return (
      <div className="stickyArea rBoxStyle">
        <Row>
          <Col xs={12}>
            <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{translator["Schedule Association"]}</div></div>
          </Col>
        </Row>
        <div className="rBoxGap">
          {props.error == undefined || props.error == null ? null : (
            <Alert variant="danger" className="errorMessageSec">
              <p>{props.error}</p>
            </Alert>
          )}
          <div>
            <Row>
              <Col md={12} sm={12} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label><span className="rStar"></span>{translator['Name']}</Form.Label>
                  <Field name="scheduleName" component={_inputField} maxLength="50" className="form-control" />
                </Form.Group>
              </Col>
              <Col md={12} sm={12} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label><span className="rStar"></span>{translator['Type']}</Form.Label>
                  <Field component={_dropDown} name="scheduleType" disabled options={scheduleTypeValues} className="form-control" />
                </Form.Group>
              </Col>
              <Col md={12} sm={12} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label><span className="rStar"></span>{translator['Status']}</Form.Label>
                  <Field component={_dropDown} name="onCallStatus" defaultValue="Active" options={onCallCommonReducer.onCallStatus} className="form-control" />
                </Form.Group>
              </Col>
              <Col md={12} sm={12} xs={12}>
                <Form.Group className="form-group">
                  <Field name="is24by7" component={_checkbox} className="form-control" label="All day schedule" onChange={() => props.addEditMode == 'add' ? setAllDaySchedule(!allDaySchedule) : ''} checked={allDaySchedule} />
                </Form.Group>
              </Col>
              <Col md={12} sm={12} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label><span className="rStar"></span>{translator['Start Date']}</Form.Label>
                  <div className="dateTimeSdiv dateTimePosTop">
                    <Field
                      name="scheduleStartDate"
                      component={allDaySchedule ? _dateTimeFieldGeneral : _dateTimeField}
                      formType={allDaySchedule ? "create" : "onCallScheduleDateTime"}
                      className="form-control dateTimePickerField"
                      disabledValue={props.addEditMode == "edit" ? true : false}
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col md={12} sm={12} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label><span className="rStar"></span>{translator['End Date']}</Form.Label>
                  <div className="dateTimeSdiv dateTimePosTop">
                    <Field
                      name="scheduleEndDate"
                      component={allDaySchedule ? _dateTimeFieldGeneral : _dateTimeField}
                      formType={allDaySchedule ? "create" : "onCallScheduleDateTime"}
                      className="form-control dateTimePickerField"
                      disabledValue={props.addEditMode == "edit" ? true : false}
                    />
                  </div>
                </Form.Group>
              </Col>
              {scheduleDetailsByScheduleId.scheduleType != "Holiday" ?
              <Col md={12} sm={12} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label><span className="rStar"></span>Repeats</Form.Label>
                  <Field component={_dropDown} name="scheduleRepeatType" disabled={props.addEditMode == "edit" ? true : false} options={onCallCommonReducer.onCallFrequency} className="form-control" />
                </Form.Group>
              </Col> : ''
              }
              {scheduleDetailsByScheduleId.scheduleType != "Holiday" && scheduleRepeatTypeParam && scheduleRepeatTypeParam == 'Specific' ? <Col md={12} sm={12} xs={12}>
                <Form.Label><span className="rStar"></span>Repeats On</Form.Label>
                <div className="form-group btn-week">
                  <Button title="Sunday" bsSize="small" bsStyle="text" disabled={props.addEditMode == 'edit' ? true : false} className={repeatsOn.SU ? 'week-btn selected' : 'week-btn'} onClick={() => { props.addEditMode == 'add' ? setRepeatsOnFun('SU') : '' }}>SU</Button>
                  <Button title="Monday" bsSize="small" bsStyle="text" disabled={props.addEditMode == 'edit' ? true : false} className={repeatsOn.MO ? 'week-btn selected' : 'week-btn'} onClick={() => { props.addEditMode == 'add'? setRepeatsOnFun('MO') : '' }}>MO</Button>
                  <Button title="Tuesday" bsSize="small" bsStyle="text" disabled={props.addEditMode == 'edit' ? true : false} className={repeatsOn.TU ? 'week-btn selected' : 'week-btn'} onClick={() => { props.addEditMode == 'add'? setRepeatsOnFun('TU') : '' }}>TU</Button>
                  <Button title="Wednesday" bsSize="small" bsStyle="text" disabled={props.addEditMode == 'edit' ? true : false} className={repeatsOn.WE ? 'week-btn selected' : 'week-btn'} onClick={() => { props.addEditMode == 'add'? setRepeatsOnFun('WE') : '' }}>WE</Button>
                  <Button title="Thursday" bsSize="small" bsStyle="text" disabled={props.addEditMode == 'edit' ? true : false} className={repeatsOn.TH ? 'week-btn selected' : 'week-btn'} onClick={() => { props.addEditMode == 'add'? setRepeatsOnFun('TH') : '' }}>TH</Button>
                  <Button title="Friday" bsSize="small" bsStyle="text" disabled={props.addEditMode == 'edit' ? true : false} className={repeatsOn.FR ? 'week-btn selected' : 'week-btn'} onClick={() => { props.addEditMode == 'add'? setRepeatsOnFun('FR') : '' }}>FR</Button>
                  <Button title="Saturday" bsSize="small" bsStyle="text" disabled={props.addEditMode == 'edit' ? true : false} className={repeatsOn.SA ? 'week-btn selected' : 'week-btn'} onClick={() => { props.addEditMode == 'add'? setRepeatsOnFun('SA') : '' }}>SA</Button>
                  <br />{repeatsOnError ? <span style={{ color: "red", fontSize: "smaller" }}>Please select atleast one day.</span> : ''}
                </div>
              </Col> : ''}
            </Row>

            <Row>
              <Col md={12}>
                <ButtonToolbar className="black">
                  <Button
                    title="Save"
                    bsSize="small"
                    bsStyle="primary"
                    disabled={saveLoader}
                    onClick={props.handleSubmit(postPatchScheduleAssociation)}
                    className="rgSidrkBtn smallBtn"
                  >
                    {saveLoader ? (
                      <ImSpinner6 className="icn-spinner"/>
                    ) : null}
                    {translator["Save"]}
                  </Button>
                  <Button
                    title="Close"
                    bsSize="small"
                    bsStyle="text"
                    className="rgSiLigBtn smallBtn"
                    onClick={() => { props.setActionViewFun("scheduleAssociationList") }}
                  >
                    {translator["Close"]}
                  </Button>
                </ButtonToolbar>
              </Col>
            </Row>
          </div>
        </div >
      </div >
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, null)(
  reduxForm({
    form: 'onCallScheduleAssociationForm',
    destroyOnUnmount: true,
    validate,
    enableReinitialize: false
    // enableReinitialize: true
  })(RightSide)
);