
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef } from 'react'
import { Row, Col, Button } from 'react-bootstrap';
import {useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { reduxForm } from "redux-form";
import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import Cookies from 'universal-cookie';
import { saveClassData } from '../../../../actions/ham/hamAction';
import { Link } from 'react-router-dom';
import { MyContext } from '_MyContext';
const cookies = new Cookies();

let language = cookies.get("gph");
if (language) language = language.replace("s:", "");
if (language)
language = language.substring(0, language.lastIndexOf("."));
language = language.split("~");
language = language[20];


const CreateClassDefinitionHeader = (props) => {

  const navigate = useNavigate();
  const tr = useSelector((state) => state.spcmReducer.tr);
  
  const dispatch = useDispatch();
  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const saveObjRef = useRef(null);

  useEffect(() => {
      context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
      context.newTabPrevent();
    }, []);

  const submit = async (values) => {
    console.log("HeaderClass-", values);
    console.log("language-", language);

    let isValid = true;

    if (!values.ClassName|| values.ClassName.trim() == "" ) {
      //props.setClassNameErrorColor();
      props.setClassNameError("error");
      isValid = false;
      console.log("isvalid1",isValid);
  }

if (!values.status || values.status == '') {
  props.setStatusErr("error");
  isValid = false;
  console.log("Status222222");
}
if (!values.class_type|| values.class_type == "") {
  props.setTypeError("error");
  isValid = false;
  console.log("isvalid4",isValid);
}

if (!values.company_name|| values.company_name == "") {
  props.setCompanyErrorColor("error");
  isValid = false;
  console.log("isvalid5",isValid);
} else{
  props.setCompanyErrorColor("");
}

console.log("isvalidddddddddddd",isValid);

if (isValid == true) {
    let postjson;
    
    postjson = {
      class_name: values.ClassName,
      class_type: values.class_type,
      parent_class_id: values.parent,
      company_name: values.company_name,
      language: language,
      status: values.status,
      company_id: values.COMPANY_ID
       
    };
    dispatch(saveClassData(postjson));
  }

  }

  let { handleSubmit, submitting } = props;
  return (
    <div>
       <Row className="margin-b-15">
       <Col lg={4} md={6} sm={6} xs={7}>
                    <h1 bsClass="" className="sPageHeading1">{tr["Class Definition"]}</h1>
                </Col>
                <Col lg={8} md={6} sm={6} xs={5}>
                <div className="paHedFilter">
                        <Link to="javascript:void(0)"
                            bsPrefix=' '
                            id="hdID"
                            className="myBt plus ctrlKeyPrevent"                    
                            // disabled={submitting}
                            onClick={handleSubmit(submit)} 
                            title={tr['Save']}
                            ref={saveObjRef} >
                            <span>{props.loader ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/> }</span>
                        </Link>
                        <Link
                            bsPrefix=' '
                            className="myBt cancel fillBtn ctrlKeyPrevent"
                            title={tr['Cancel']}
                            to={"/classDefinition"}
                            ref={closeButton} >
                            <IoClose/>
                        </Link>
                    </div>

                  </Col>



       </Row>
    </div>
  )
}

export default reduxForm({
  form: "CreateClassDefinitionForm",
})(CreateClassDefinitionHeader);
