
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { Component, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import AutoSuggest from "react-autosuggest";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyUsersList } from "../../../../actions/spcmActions";
import useDebounce from "../../../../helpers/useDebounce";


const RequesterTypeAhead = (props) => {
  const [value, setValue] = useState("");
  const [suggestions, setSuggestion] = useState([]);

  const dispatch = useDispatch();

  const usersList = useSelector(state => state.spcmReducer.companyUsersList);

  useEffect(() => {
    if(usersList && usersList.length > 0) {
      usersList.map(user => {
        let userinformation = user.userinformation.split('|');
        let nameArr = userinformation[0].split(' ');
        let name = "";
        let email = userinformation[1].toLowerCase();
        nameArr.map((word, i) => {
          nameArr[i] = word.charAt(0) + word.slice(1).toLowerCase();
        });
        name = nameArr.join(" ");
        user.name = name.length > 30 ? name.slice(0, 34) + '...' : name;
        let bottomText = email + ' - ' + props.companyName;
        user.bottomText = bottomText.length > 33 ? bottomText.slice(0, 33) + '...' : bottomText
      })
      setSuggestion(usersList);
    }
  }, [usersList])

  useEffect(() => {
    if(props.companyId === "") {
      setValue("");
    }
  }, [props.companyId])
    
  const getSuggestionValue = (suggestion) =>
    suggestion.name.indexOf == -1 ? "" : suggestion.name  ;

  function renderSuggestion(suggestion) {
    return (
      <Button bsPrefix=" " className="suggBtn">
        {suggestion.name}
        <div>
          {suggestion.bottomText}
        </div>
      </Button>
    );
  }

  const onChange = (event, { newValue }) => {
    props.onConsumerSelected({});
    setValue(newValue);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestion([]);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    fetchUsers();
  };

  const fetchUsers = useDebounce(() => {
    const companyId = parseInt(props.companyId, 10);
    dispatch(getCompanyUsersList(companyId, value));
  })

  const onConsumerSelected = (event, { suggestion }) => {
    if (typeof props.onConsumerSelected !== "undefined") {
      props.onConsumerSelected(suggestion);
    }
  };

  const inputProps = {
    placeholder:
      props.placeHolder != "" && props.placeHolder != undefined
        ? props.placeHolder
        : "User Name",
    className: "form-control " + (props.errorColor || ""),
    value : value, 
    onChange: onChange,
    onBlur: props.validateField,
  };

    return (
    <div className="dvTypehd">
      {value.length > 0 ?
      <span
          title="Clear"
          role="img"
          aria-label="Clear"
          className="css-hakgx8 icn"
          onClick={() => {setValue(''); props.onCrossClick();}}
      >
          <svg width="18" height="18" viewBox="0 0 24 24" role="presentation">
              <path
                  d="M12 10.586L6.707 5.293a1 1 0 00-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 001.414 1.414L12 13.414l5.293 5.293a1 1 0 001.414-1.414L13.414 12l5.293-5.293a1 1 0 10-1.414-1.414L12 10.586z"
                  fill="gray"
              ></path>
          </svg>
      </span>
      : null}
    <AutoSuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      onSuggestionSelected={onConsumerSelected}
    />
    </div>
  );
};

export default RequesterTypeAhead;
