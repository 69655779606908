
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
//below URL used for testing purpose
//const breakFixUrl = 'https://xsm-f.mybluemix.net/api/SX_FAVOURITEOFFERINGs/';
import { GLOBAL }  from '../../components/Globals';
//let breakFixUrl =`https://xsm-f.mybluemix.net/api/breakfixitem?breakfixitemId=`;
//let breakFixUrl=`https://xsm-f.mybluemix.net/groupListSelfService?companyId=2932&group=ResolvedER`
export function loadInvRelatedWorkDetailsSuccess(invRelatedWorkDetails){
	//alert('in success')
	return {
		type: 'LOAD_INVRELATEDWORKDETAILS_SUCCESS',
		invRelatedWorkDetails
	};
}

export function loadInvRelatedWorkDetailsFailure(errorResponse){
	return {
		type: 'LOAD_INVRELATEDWORKDETAILS_FAILURE',
		errorResponse
	};
}
export function loadInvRelatedWorkDetails(filterval,breakFixId,module,consumerCompany,itmId='',isRelationReq='',pageSize=10,currentPage=0){
	//alert('action called and parameter is '+breakFixId)
	//if(breakFixId=="0")
	//breakFixId="";
	console.log("module prob ",module,consumerCompany)
	return(dispatch) =>{
		//alert("EditGroupIdAction123"+breakFixId);
		if(!(breakFixId == -1)){
		dispatch({type: "MENU_LOADER_IN_PROGRESS"});
		}
				//alert("hiaaa::::");
				let relatedurl="";
				let axioscall="";
				if(module=="Breakfix")
				relatedurl=GLOBAL.breakFixRelationQuickViewURL;
				if(module=="Fullfilment")
				relatedurl=GLOBAL.fulfillmentRelationListURL;
				if(module=="Investigation")
				relatedurl=GLOBAL.investigationQuickViewURL;
				if(module=="ChangeManagement")
				relatedurl=GLOBAL.changeQuickViewURL;
				if(module=="Breakfix"){
					let str = {};
					str.searchByList = filterval+','+'consumerCompany';
					str.patternList = "like";
					str.multipleValueList = ""+breakFixId+'|'+consumerCompany;
					str.size = 99;
					str.currentPage = parseInt(currentPage,10);
					if(pageSize!='-1'){
					   str.size=parseInt(pageSize,10);	
					}				//	str = JSON.stringify(str);
					axioscall = axios.post(relatedurl, str);
                console.log("?searchByList="+filterval,'consumerCompany'+"&patternList=like&multipleValueList="+breakFixId+'|'+consumerCompany);
				}else if(module=="Fullfilment"){
					let str={};
					str.searchByList=filterval+','+'companyId';
					str.isNestedRequired="N";
					str.patternList="like";
					str.multipleValueList=breakFixId+'|'+consumerCompany;
					str.size = 99;
					str.currentPage = parseInt(currentPage,10);
					if(pageSize!='-1'){
					   str.size=parseInt(pageSize,10);	
					}					//str = JSON.stringify(str);
					axioscall=axios.post(relatedurl,str);
					console.log("?searchByList="+filterval+
					"&isNestedRequired=N&patternList=like&multipleValueList="+breakFixId);
				}else if(module=="Investigation"){
					// "?searchByList="+filterval+
					// "&sortBy=investigationId&orderBy=desc&patternList=like&multipleValueList="+breakFixId
					console.log("investigation++++");
					let str={};
                    str.searchByList=filterval+','+'consumerCompany';
                    str.sortBy="investigationNumber";
                    str.orderBy="desc";
					str.patternList="like";
					str.multipleValueList=breakFixId+'|'+consumerCompany;
					str.size = 99;
					str.currentPage = parseInt(currentPage,10);
					if(pageSize!='-1'){
					   str.size=parseInt(pageSize,10);	
					}					if(isRelationReq != '')	str.isRelationsNotRequired = isRelationReq;
					if(itmId != '') str.itemId = itmId.toString();
                    axioscall=axios.post(relatedurl,str);
				}else if(module=="ChangeManagement"){
					//"?searchByList="+filterval+"&patternList=like&multipleValueList="+breakFixId

					let str={};
					str.searchByList='status'+','+filterval+','+'consumerCompany';
					str.patternList="not in"+","+"like";
					str.multipleValueList='Completed'+'|'+breakFixId+'|'+consumerCompany;
					str.size = 99;
					str.currentPage = parseInt(currentPage,10);
					if(pageSize!='-1'){
					   str.size=parseInt(pageSize,10);	
					}
					axioscall=axios.post(relatedurl,str);
				}else{
					axioscall=axios.get(relatedurl)
				}
				axioscall.then((invRelatedWorkDetails)=>{
						if(!invRelatedWorkDetails){//alert('not breakFix')
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							dispatch({type:'MENU_LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
							throw Error(invRelatedWorkDetails.statusText);
						}
						return invRelatedWorkDetails;
					})
					.then((invRelatedWorkDetails) =>{//alert('yes it is')
						dispatch(loadInvRelatedWorkDetailsSuccess(invRelatedWorkDetails))
						dispatch({type:'MENU_LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
					})
					.catch((err) => {
						dispatch({type:'MENU_LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
						console.log(err);
					});
					//console.log("not calling loadBreakFixEditDetails");

	};
}

export function clearInvRelatedWorkDetails(){
	return(dispatch) =>{
		let obj = {
			data: {
				data: [],
				meta: null
			}
		};
		dispatch(loadInvRelatedWorkDetailsSuccess(obj));
	};
}