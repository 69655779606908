
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { connect } from "react-redux";
import { navigationHooks } from "../../../../helpers/NavigationHook";
import BasicTable from "../../../common/ReactTable/BasicTable";
import { Link } from "react-router-dom";
const CellName = ({ row, selected, isClickable }) => {
  return isClickable ? (
    <Link title={row[selected]} to={`/editSlaDef/${row["slaId"]}`}>
      {row[selected]}
    </Link>
  ) : (
    <span
      title={row[selected]}
      style={{
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      }}
    >
      {row[selected]}
    </span>
  );
};
class ViewWorkRelatedSls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
    this.renderRelatedSls = this.renderRelatedSls.bind(this);
    this.onRowSelect = this.onRowSelect.bind(this);
  }

  onRowSelect(row) {
    // navigationHooks.navigate(`/editSlaDef/${row["slaId"]}`);
  }

  renderRelatedSls(list) {
    if (
      this.props.workRelatedSla &&
      Array.isArray(this.props.workRelatedSla) &&
      this.props.workRelatedSla.length > 0
    ) {
      const tableColumns = [
        {
          name: this.props.translator["Name"],
          selector: (row) => row.slaName,
          cell: (row) => (
            <CellName row={row} selected="slaName" isClickable={true} />
          ),
          sortable: true,
        },
        {
          name: this.props.translator["Created On"],
          selector: (row) => row.modifiedOn,
          cell: (row) => (
            <CellName row={row} selected="modifiedOn" isClickable={false} />
          ),
          sortable: true,
        },
        {
          name: this.props.translator["Related By"],
          selector: (row) => row.modifiedByName,
          cell: (row) => (
            <CellName row={row} selected="modifiedByName" isClickable={false} />
          ),
          sortable: true,
        },
      ];

      return (
        <BasicTable
          tableColumns={tableColumns}
          data={list}
          pagination={true}
          onRowSelect={(row) => {
            this.onRowSelect(row);
          }}
          noPointer={true}
        />
      );
    } else {
      return <div>{this.props.translator["No Data Available"]}</div>;
    }
  }

  render() {
    return (
      <div>
        <div className="stickyArea rBoxStyle">
          <div className="rPageHeading">
            <div className="offNam margin-t-5 margin-b-5">
              {this.props.translator["Related SLAs"]}
            </div>
          </div>
          <div className="rBoxGap">
            {this.renderRelatedSls(this.props.workRelatedSla)}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ slaRelatedDetails, isFetchingDetails }) => {
  return {
    workRelatedSla: slaRelatedDetails.workRelatedSla,
    holidayRelatedSla: slaRelatedDetails.holidayRelatedSla,
    isFetchingDetails,
  };
};

export default connect(mapStateToProps, {})(ViewWorkRelatedSls);
