
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { useTable, useSortBy, useExpanded } from "react-table";
import NoDataAvailable from "./NoDataAvailable";

export default function Table({
  columns,
  data,
  disablesort,
  id,
  tableClass,
  colspan,
  colSpanName,
}) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: {
          hiddenColumns: ["to"],
        },
        disableSortBy: disablesort,
      },
      useSortBy,
      useExpanded
    );

  // Render the UI for your table
  return (
    <div className="table-container">
      {data.length ? (
        <table
          {...getTableProps()}
          className={`table dataTable ${tableClass ? tableClass : ""}`}
          id={id}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => {
                  const {
                    render,
                    getHeaderProps,
                    isSorted,
                    isSortedDesc,
                    disableSortBy,
                    getSortByToggleProps,
                  } = column;
                  const extraClass = isSorted
                    ? isSortedDesc
                      ? "sorting_desc desc"
                      : "sorting_asc asc"
                    : `sorting ${disableSortBy ? "disable-sorting" : ""}`;
                  return (
                    <th
                      className={`${disablesort ? "" : extraClass} ${
                        index === 0 ? "sticky-col" : ""
                      }`}
                      {...getHeaderProps(getSortByToggleProps())}
                    >
                      {render("Header")}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <>
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          className={index === 0 ? "sticky-col" : ""}
                          {...cell.getCellProps()}
                        >
                          {cell?.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      ) : (
        <NoDataAvailable />
      )}
    </div>
  );
}
