
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Link } from "react-router-dom";
import { Form, Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-spinkit";
import { bindActionCreators } from "redux";
import {loadModuleList,setMultiSelectDropDownWithOk} from "../../../actions/breakFix/quickViewAction";

import ConditionBuilder from "../../common/condition-builder";
import SavedQueryDropdown from "../../common/savedQueryDropdown.js";
import AiIcon from "../../common/aiIcon.js";
import { IoClose } from "react-icons/io5";

import { GLOBAL } from "../../Globals";
const overlayStyle = {
  position: "absolute",
  background: "white",
  boxShadow: "0 2px 5px rgba(0, 0, 0, .15)",
  zIndex: 9999,
};
import {
  loadBreakFixQuickView,
  loadFulfillmentList,
} from "../../../actions/breakFix/quickViewAction";
import { loadChangeManagementmList } from "../../../actions/changeManagement/changeManagementAction";
import { loadTaskList } from "../../../actions/task/TaskDataAction";
import {
  loadInvestigationList,
  getslaViewListAction,
} from "../../../actions/investigation/investigationAction";
import Cookies from "universal-cookie";
import { browserHistory } from "react-router";
import Breadcrumbs from "../../common/header/breadcrumbs";

import { setwibGlobalSearchFilterData } from "../../../actions/globalSearch/globalSearchAction";
import { setWipSearchApply } from "../../../actions/globalSearch/globalSearchAction";
import { LuFilterX, LuCalendarDays } from "react-icons/lu";
import { HiPlus, HiOutlineRefresh } from "react-icons/hi";
import { clearBreakfixData } from "../../../actions/breakFix/quickViewAction";
import { resetQuery } from "../../common/helper";

const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home") homepagelocation = "/" + homepagelocation;

let cookiesArray = cookies.get('gph');
if (cookiesArray) cookiesArray = cookiesArray.replace('s:', '');
if (cookiesArray) cookiesArray = cookiesArray.substring(0, cookiesArray.lastIndexOf('.'));
cookiesArray = cookiesArray.split("~");
let langDir = cookiesArray[66];

let savedQueryList = ["GitHub Copilot is a code completion tool developed by GitHub", "Dheeraj Query", "Pallavi testing"];

class XsmQuickViewDetailtHeader extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showPickerOverlay: false,
      rangePicker: {},
      linked: {},
      datePicker: null,
      firstDayOfWeek: null,
      predefined: {},
      selectedModuleName: "",
      slaListViewVal: false,
      isSwitchChecked: false,
    };
    this.showModuleIcons = this.showModuleIcons.bind(this);
    this.showPaginationCount = this.showPaginationCount.bind(this);
    this.refreshFulfillmentTable = this.refreshFulfillmentTable.bind(this);
    // this.fulfillmentClearFilters = this.fulfillmentClearFilters.bind(this);
    this.refreshChangeTable = this.refreshChangeTable.bind(this);
    this.crossHandleSelect = this.crossHandleSelect.bind(this);
    this.restrictSubtypeAll = this.restrictSubtypeAll.bind(this);
    this.restrictSubtypeAssignMyGroup =
      this.restrictSubtypeAssignMyGroup.bind(this);
    // this.ChangeClearFilters = this.ChangeClearFilters.bind(this);
    this.clickedOnHeaderText = this.clickedOnHeaderText.bind(this);
    this.restrictSubtypeAssignedToMe =
      this.restrictSubtypeAssignedToMe.bind(this);
      this.onSubStatusSelect = this.onSubStatusSelect.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedGlobalModule !== "") {
      this.setState({ selectedModuleName: nextProps.selectedGlobalModule });
    } else if (
      nextProps.state_carousel_index !== "" &&
      this.state.selectedModuleName === ""
    ) {
      const objMapping = {
        0: "breakfix",
        1: "fullfilment",
        2: "investigation",
        3: "changeManagement",
        4: "task",
      };
      this.setState({
        selectedModuleName: objMapping[nextProps.state_carousel_index],
      });
      this.props.getslaViewListAction(
        10,
        0,
        objMapping[nextProps.state_carousel_index]
      );
    }
  }

  showPaginationCount() {
    if (this.props.carouselIndex == 0 && this.props.filterTabActive != 4) {
      // console.log("AAA")
      let metaBreakfix = this.props.quickViewDetails.meta;
      let sizeBreakfix =
        metaBreakfix && metaBreakfix.size ? metaBreakfix.size : 0;
      let currentPageBreakfix =
        metaBreakfix && metaBreakfix.currentPage ? metaBreakfix.currentPage : 0;

      let startLimit = sizeBreakfix * currentPageBreakfix + 1;
      let endLimit = sizeBreakfix * (currentPageBreakfix + 1);
      let recordCount =
        metaBreakfix && metaBreakfix.recordCount ? metaBreakfix.recordCount : 0;
      return recordCount != 0 ? (
        <div className="tabDatacount">
          {startLimit}-
          {endLimit > recordCount ? (endLimit = recordCount) : endLimit} of{" "}
          {recordCount}
        </div>
      ) : null;
    } else if (
      this.props.carouselIndex == 1 &&
      this.props.filterTabActive != 4
    ) {
      // console.log("BBBB")
      let metaFulfillment = this.props.fulFillmentList.meta;
      let sizeFulfillment =
        metaFulfillment && metaFulfillment.size ? metaFulfillment.size : 0;
      let currentPageFulfillment =
        metaFulfillment && metaFulfillment.currentPage
          ? metaFulfillment.currentPage
          : 0;
      let startLimitFulfillment = sizeFulfillment * currentPageFulfillment + 1;
      let endLimitFulfillment = sizeFulfillment * (currentPageFulfillment + 1);
      let recordCountFulfillment =
        metaFulfillment && metaFulfillment.recordCount
          ? metaFulfillment.recordCount
          : 0;
      return recordCountFulfillment != 0 ? (
        <div className="tabDatacount">
          {startLimitFulfillment}-
          {endLimitFulfillment > recordCountFulfillment
            ? (endLimitFulfillment = recordCountFulfillment)
            : endLimitFulfillment}{" "}
          of {recordCountFulfillment}
        </div>
      ) : null;
    } else if (
      this.props.carouselIndex == 2 &&
      this.props.filterTabActive != 4
    ) {
      // console.log("CCC")
      let metaInvestigation = this.props.investigationList.meta;
      let sizeInvestigation =
        metaInvestigation && metaInvestigation.size
          ? metaInvestigation.size
          : 0;
      let currentPageInvestigation =
        metaInvestigation && metaInvestigation.currentPage
          ? metaInvestigation.currentPage
          : 0;
      let startLimit = sizeInvestigation * currentPageInvestigation + 1;
      let endLimit = sizeInvestigation * (currentPageInvestigation + 1);
      let recordCount =
        metaInvestigation && metaInvestigation.recordCount
          ? metaInvestigation.recordCount
          : 0;
      return recordCount != 0 ? (
        <div className="tabDatacount">
          {startLimit}-
          {endLimit > recordCount ? (endLimit = recordCount) : endLimit} of{" "}
          {recordCount}
        </div>
      ) : null;
    } else if (
      this.props.carouselIndex == 3 &&
      this.props.filterTabActive != 4
    ) {
      // console.log("DDD")
      let metaChange = this.props.myChangeManagementList.meta;
      let sizeChange = metaChange && metaChange.size ? metaChange.size : 0;
      let currentPageChange =
        metaChange && metaChange.currentPage ? metaChange.currentPage : 0;
      let startLimitChange = sizeChange * currentPageChange + 1;
      let endLimitChange = sizeChange * (currentPageChange + 1);
      let recordCountChange =
        metaChange && metaChange.recordCount ? metaChange.recordCount : 0;
      return recordCountChange != 0 ? (
        <div className="tabDatacount">
          {startLimitChange}-
          {endLimitChange > recordCountChange
            ? (endLimitChange = recordCountChange)
            : endLimitChange}{" "}
          of {recordCountChange}
        </div>
      ) : null;
    } else if (
      this.props.carouselIndex == 4 &&
      this.props.filterTabActive != 4
    ) {
      // console.log("EEE")
      let metaData = this.props.myTaskDetailsListMetaData.meta;
      let size = metaData && metaData.limit ? metaData.limit : 0;
      let currentPage =
        metaData && metaData.currentPage ? metaData.currentPage : 0;
      let startindex = size * (currentPage - 1) + 1;
      let endindex = size * currentPage;
      let rowCount = metaData && metaData.rowCount ? metaData.rowCount : 0;
      return rowCount != 0 ? (
        <div className="tabDatacount">
          {startindex}-{endindex > rowCount ? (endindex = rowCount) : endindex}{" "}
          of {rowCount}
        </div>
      ) : null;
    } else if (this.props.filterTabActive == 4) {
      // console.log("FFFF")
      let metaData = this.props.slaViewListRed.meta;
      let size = metaData && metaData.size ? metaData.size : 0;
      // console.log("FFF SIZE",size)
      let currentPage =
        metaData && metaData.currentPage ? metaData.currentPage : 0;
      let startindex = size * currentPage + 1;
      let endindex = size * (currentPage + 1);
      let rowCount =
        metaData && metaData.recordCount ? metaData.recordCount : 0;
      return rowCount != 0 ? (
        <div className="tabDatacount">
          {startindex}-{endindex > rowCount ? (endindex = rowCount) : endindex}{" "}
          of {rowCount}
        </div>
      ) : null;
    }
  }

  componentWillMount() {
    this.props.loadModuleList();
    if (this.props?.carouselIndex == "5") {
      this.setState({ selectedModuleName: "appointment" });
    }
  }
  loadHome() {
    browserHistory.push(homepagelocation);
  }

  refreshBreakfixTable() {
    let searchParams = Object.assign({});
    searchParams.searchByList = this.props.searchKeyBrk;
    searchParams.multipleValueList = this.props.searchValueBrk;
    searchParams.patternList =
      this.props.patternListBrk != "" ? this.props.patternListBrk : "in";
    searchParams.sortBy = "itemId";
    searchParams.orderBy = "desc";
    searchParams.size = 10;
    searchParams.currentPage = 0;
    this.props.showRightSide(false);
    // this.props.loadBreakFixQuickView(searchParams, this.props.statusValueBrk, this.props.priorityValueBrk, this.props.spGroupValueBrk);

    const { filterTabActive = 1, group_id, user_id } = this.props;
    let statusValueBrk =
      this.props.statusValueBrk &&
      Array.isArray(this.props.statusValueBrk) &&
      this.props.statusValueBrk.length
        ? this.props.statusValueBrk
        : [10, 20, 15];
    if (filterTabActive === 1) {
      let userId = [user_id];
      if (searchParams.searchByList.indexOf("spUserid") >= 0) {
        userId = "";
      }
      this.props.loadBreakFixQuickView(
        searchParams,
        statusValueBrk,
        this.props.priorityValueBrk,
        this.props.spGroupValueBrk,
        userId,
        this.props.criticalityValueBrk,
        this.props.cimgroupsValueBrk
      );
    } else if (filterTabActive === 2) {
      this.props.getGroupValues(this.props.spGroupValueBrk);
      let groups = this.props.getGroupValues(this.props.spGroupValueBrk);
      // let groups = (this.props.spGroupValueBrk && Array.isArray(this.props.spGroupValueBrk) && this.props.spGroupValueBrk.length) ? this.props.spGroupValueBrk : (
      //   (!group_id || group_id == -1 || group_id == '0') ? ['-1'] :
      //   (
      //     (group_id && group_id.length > 0) ? group_id.split(",") : []
      //   )
      //   );
      this.props.loadBreakFixQuickView(
        searchParams,
        statusValueBrk,
        this.props.priorityValueBrk,
        groups,
        "",
        this.props.criticalityValueBrk,
        this.props.cimgroupsValueBrk
      );
    } else if (filterTabActive === 3) {
      this.props.loadBreakFixQuickView(
        searchParams,
        statusValueBrk,
        this.props.priorityValueBrk,
        this.props.spGroupValueBrk,
        "",
        this.props.criticalityValueBrk,
        this.props.cimgroupsValueBrk
      );
    }
  }

  refreshFulfillmentTable() {
    const { filterTabActive = 1, group_id, user_id } = this.props;
    let searchParams = Object.assign({});
    searchParams.searchByList = this.props.fulfillmentSearchKey;
    searchParams.multipleValueList = this.props.fulfillmentSearchValue;
    searchParams.patternList =
      this.props.fulfillmentPattern != ""
        ? this.props.fulfillmentPattern
        : "in";
    searchParams.size = 10;
    searchParams.currentPage = 0;
    searchParams.sortBy = this.props.fulfillmentSortOnColumn;
    searchParams.orderBy = this.props.fulfillmentSortOrder;
    this.props.showRightSide(false);
    if (filterTabActive === 1) {
      let userId = [user_id];
      if (searchParams.searchByList.indexOf("asgUserid") >= 0) {
        userId = "";
      }
      this.props.loadFulfillmentList(
        searchParams,
        [this.props.fulfillmentStatus],
        [this.props.fulfillmentGroup],
        [userId]
      );
    }
    if (filterTabActive === 2) {
      let groups =
        this.props.fulfillmentGroup &&
        Array.isArray(this.props.fulfillmentGroup) &&
        this.props.fulfillmentGroup.length
          ? this.props.fulfillmentGroup
          : group_id && group_id.length > 0
          ? group_id.split(",")
          : [];

      this.props.loadFulfillmentList(
        searchParams,
        [this.props.fulfillmentStatus],
        [groups],
        [this.props.fulfillmentUser]
      );
    }
    if (filterTabActive === 3) {
      this.props.loadFulfillmentList(
        searchParams,
        [this.props.fulfillmentStatus],
        [this.props.fulfillmentGroup],
        [this.props.fulfillmentUser]
      );
    }
  }
  refreshChangeTable() {
    let status = [30, 35, 40, 45, 55, 65, 95];
    const { filterTabActive = 1, group_id, user_id } = this.props;
    let searchParams = Object.assign({});
    searchParams.searchByList = this.props.changeSearchKey;
    searchParams.multipleValueList = this.props.changeSearchValue;
    searchParams.patternList =
      this.props.changePattern != "" ? this.props.changePattern : "";
    searchParams.size = 10;
    searchParams.currentPage = 0;
    searchParams.sortBy = this.props.changeSortOnColumn;
    searchParams.orderBy = this.props.changeSortOrder;
    this.props.showRightSide(false);
    const roles =
      this.props.changeImplentationRole === "impUserid,changeUserid"
        ? "both"
        : "individual";
    if (filterTabActive === 1) {
      let userId = [user_id];
      if (
        (this.props.role_id.split(",").includes("52") &&
          this.props.role_id.split(",").includes("20")) ||
        (this.props.role_id.split(",").includes("51") &&
          this.props.role_id.split(",").includes("19")) ||
        (this.props.role_id.split(",").includes("20") &&
          this.props.role_id.split(",").includes("51")) ||
        (this.props.role_id.split(",").includes("52") &&
          this.props.role_id.split(",").includes("19")) ||
        this.props.role_id.split(",").includes("48")
      ) {
        searchParams.operatorList = "or";
        this.props.loadChangeManagementmList(
          searchParams,
          this.props.changeStatus.length == 0
            ? status
            : this.props.changeStatus,
          [userId],
          [userId],
          this.props.changemngmtGroup,
          this.props.implemntGroup,
          this.props.changeType,
          false,
          roles,
          "assigned_to_me",
          this.props.groupFilterApplied
        );
      } else if (
        this.props.role_id.split(",").includes("20") &&
        !this.props.role_id.split(",").includes("52")
      ) {
        this.props.loadChangeManagementmList(
          searchParams,
          this.props.changeStatus.length == 0
            ? status
            : this.props.changeStatus,
          userId,
          [],
          this.props.changemngmtGroup,
          this.props.implemntGroup,
          this.props.changeType,
          false,
          roles,
          "assigned_to_me",
          this.props.groupFilterApplied
        );
      } else if (
        this.props.role_id.split(",").includes("52") &&
        !this.props.role_id.split(",").includes("20")
      ) {
        this.props.loadChangeManagementmList(
          searchParams,
          this.props.changeStatus.length == 0
            ? status
            : this.props.changeStatus,
          [],
          [userId],
          this.props.changemngmtGroup,
          this.props.implemntGroup,
          this.props.changeType,
          false,
          roles,
          "assigned_to_me",
          this.props.groupFilterApplied
        );
      } else if (
        this.props.role_id.split(",").includes("19") &&
        !this.props.role_id.split(",").includes("51")
      ) {
        this.props.loadChangeManagementmList(
          searchParams,
          this.props.changeStatus.length == 0
            ? status
            : this.props.changeStatus,
          userId,
          [],
          this.props.changemngmtGroup,
          this.props.implemntGroup,
          this.props.changeType,
          false,
          roles,
          "assigned_to_me",
          this.props.groupFilterApplied
        );
      } else if (
        this.props.role_id.split(",").includes("51") &&
        !this.props.role_id.split(",").includes("19")
      ) {
        this.props.loadChangeManagementmList(
          searchParams,
          this.props.changeStatus.length == 0
            ? status
            : this.props.changeStatus,
          [],
          [userId],
          this.props.changemngmtGroup,
          this.props.implemntGroup,
          this.props.changeType,
          false,
          roles,
          "assigned_to_me",
          this.props.groupFilterApplied
        );
      }
    } else if (filterTabActive === 2) {
      let groupImp =
        this.props.implemntGroup &&
        Array.isArray(this.props.implemntGroup) &&
        this.props.implemntGroup.length
          ? this.props.implemntGroup
          : group_id && group_id.length > 0
          ? group_id.split(",")
          : [];

      let groups =
        this.props.changemngmtGroup &&
        Array.isArray(this.props.changemngmtGroup) &&
        this.props.changemngmtGroup.length
          ? this.props.changemngmtGroup
          : group_id && group_id.length > 0
          ? group_id.split(",")
          : [];

      if (
        (this.props.role_id.split(",").includes("52") &&
          this.props.role_id.split(",").includes("20")) ||
        (this.props.role_id.split(",").includes("51") &&
          this.props.role_id.split(",").includes("19")) ||
        (this.props.role_id.split(",").includes("20") &&
          this.props.role_id.split(",").includes("51")) ||
        (this.props.role_id.split(",").includes("52") &&
          this.props.role_id.split(",").includes("19")) ||
        this.props.role_id.split(",").includes("48")
      ) {
          if (GLOBAL.changeMangemtGroup != 0 || GLOBAL.impleGroup != 0) {
            // Do nothing
        } else {
            searchParams.operatorList = "or";
        }
        this.props.loadChangeManagementmList(
          searchParams,
          this.props.changeStatus.length == 0
            ? status
            : this.props.changeStatus,
          [],
          [],
          GLOBAL.changeMangemtGroup == 0 && GLOBAL.impleGroup != 0
            ? null
            : groups,
          GLOBAL.impleGroup == 0 && GLOBAL.changeMangemtGroup != 0
            ? null
            : groupImp,
          this.props.changeType,
          false,
          roles,
          "assigned_to_group",
          this.props.groupFilterApplied
        );
      } else if (
        this.props.role_id.split(",").includes("20") &&
        !this.props.role_id.split(",").includes("52")
      ) {
        this.props.loadChangeManagementmList(
          searchParams,
          this.props.changeStatus.length == 0
            ? status
            : this.props.changeStatus,
          [],
          [],
          this.props.implemntGroup.length != 0
            ? this.props.changemngmtGroup
            : this.props.changemngmtGroup.length != 0
            ? this.props.changemngmtGroup
            : group_id,
          [this.props.implemntGroup],
          this.props.changeType,
          false,
          roles,
          "assigned_to_group",
          this.props.groupFilterApplied
        );
      } else if (
        this.props.role_id.split(",").includes("52") &&
        !this.props.role_id.split(",").includes("20")
      ) {
        this.props.loadChangeManagementmList(
          searchParams,
          this.props.changeStatus.length == 0
            ? status
            : this.props.changeStatus,
          [],
          [],
          this.props.changemngmtGroup,
          [
            this.props.changemngmtGroup.length != 0
              ? this.props.implemntGroup
              : this.props.implemntGroup.length != 0
              ? this.props.implemntGroup
              : group_id,
          ],
          this.props.changeType,
          false,
          roles,
          "assigned_to_group",
          this.props.groupFilterApplied
        );
      } else if (
        this.props.role_id.split(",").includes("19") &&
        !this.props.role_id.split(",").includes("51")
      ) {
        this.props.loadChangeManagementmList(
          searchParams,
          this.props.changeStatus.length == 0
            ? status
            : this.props.changeStatus,
          [],
          [],
          this.props.implemntGroup.length != 0
            ? this.props.changemngmtGroup
            : this.props.changemngmtGroup.length != 0
            ? this.props.changemngmtGroup
            : group_id,
          [this.props.implemntGroup],
          this.props.changeType,
          false,
          roles,
          this.props.changeIsClearAllFilter ? "tab-group" : "assigned_to_group",
          this.props.groupFilterApplied
        );
      } else if (
        this.props.role_id.split(",").includes("51") &&
        !this.props.role_id.split(",").includes("19")
      ) {
        this.props.loadChangeManagementmList(
          searchParams,
          this.props.changeStatus.length == 0
            ? status
            : this.props.changeStatus,
          [],
          [],
          this.props.changemngmtGroup,
          [
            this.props.changemngmtGroup.length != 0
              ? this.props.implemntGroup
              : this.props.implemntGroup.length != 0
              ? this.props.implemntGroup
              : group_id,
          ],
          this.props.changeType,
          false,
          roles,
          "assigned_to_group",
          this.props.groupFilterApplied
        );
      }
    } else if (filterTabActive === 3) {
      this.props.loadChangeManagementmList(
        searchParams,
        this.props.changeStatus.length == 0 ? status : this.props.changeStatus,
        [],
        [],
        [this.props.changemngmtGroup],
        [this.props.implemntGroup],
        this.props.changeType,
        false,
        roles,
        "All"
      );
    }
  }

  refreshInvestigationTable() {
    let searchParams = Object.assign({});
    searchParams.searchByList = this.props.searchKeyInv;
    searchParams.multipleValueList = this.props.searchValueInv;
    searchParams.patternList =
      this.props.patternListInv != "" ? this.props.patternListInv : "in";
    searchParams.sortBy = "itemId";
    searchParams.orderBy = "desc";
    searchParams.size = 10;
    searchParams.currentPage = 0;
    this.props.showRightSide(false);
    // this.props.loadInvestigationList(searchParams, this.props.statusValueInv, this.props.priorityValueInv, this.props.spGroupValueInv);

    const { filterTabActive = 1, group_id, user_id } = this.props;
    let statusValueInv =
      this.props.statusValueInv &&
      Array.isArray(this.props.statusValueInv) &&
      this.props.statusValueInv.length
        ? this.props.statusValueInv
        : ["35", "40", "45", "50", "55", "60"];
    if (filterTabActive === 1) {
      let userId = [user_id];
      if (
        this.props.isProblemInvestigator() ||
        searchParams.searchByList.indexOf("asgPmId") >= 0
      ) {
        userId = "";
      }
      this.props.loadInvestigationList(
        searchParams,
        statusValueInv,
        this.props.priorityValueInv,
        this.props.spGroupValueInv,
        userId
      );
    } else if (filterTabActive === 2) {
      this.props.getGroupValues(this.props.spGroupValueInv);
      let groups = this.props.getGroupValues(this.props.spGroupValueInv);
      this.props.loadInvestigationList(
        searchParams,
        statusValueInv,
        this.props.priorityValueInv,
        groups,
        ""
      );
    } else if (filterTabActive === 3) {
      this.props.loadInvestigationList(
        searchParams,
        statusValueInv,
        this.props.priorityValueInv,
        this.props.spGroupValueInv,
        ""
      );
    }
  }
  crossHandleSelect(selectTabFilter) {
    if (selectTabFilter == 1) {
      this.props.handleSelectMyGroup();
    } else if (selectTabFilter == 2) {
      this.props.handleSelectAllGroup();
    } else if (selectTabFilter == 3) {
      this.props.handleSelect("fullfilment");
    }
  }

  showModuleIcons() {
    const breakFixGraphView = this.props.breakFixGraphView;
    const fullfilmentGraphView = this.props.fullfilmentGraphView;
    const investigationGraphView = this.props.investigationGraphView;
    const showCreateInvestigationButton = !(
      this.props.roleIdentification("17") || this.props.roleIdentification("16")
    )
      ? !this.props.roleIdentification("48")
      : true;
    const showCreateChangeButton = !(
      this.props.roleIdentification("19") ||
      this.props.roleIdentification("20") ||
      this.props.roleIdentification("51") ||
      this.props.roleIdentification("52")
    )
      ? !this.props.roleIdentification("48")
      : true;
    const changeGraphView = this.props.changeGraphView;
    const taskGraphView = this.props.taskGraphView;
    let isproblemInvestigator = this.props.gettingRoleId("53");
    let subFilterTabMapping = { 1: "individual", 2: "group", 3: "all" };

    if (this.props.carouselIndex == "0") {
      return (
        <div
          aria-label="Page Filters"
          role="contentinfo"
          className="paHedFilter"
        >
          {breakFixGraphView &&
            !this.props.only_incident_viewer_role_enabled && (
              <Link
                aria-label="Add"
                role="button"
                className="myBt plus fillBtn"
                title={this.props.translator["Request for Break-fix"]}
                to="/createBreakFix"
              >
                <HiPlus />
              </Link>
            )}
          {/* {breakFixGraphView && <Button title={this.props.translator['Request for Break-fix']} href="/createBreakFix"><span className="sAction bgBtn15 artAdd"></span></Button>} */}
          {this.props.isClearAllFilterBrk ? (
            <div className="overlayDv">
              <Button
                title="Clear All Filters"
                role="button"
                onClick={() => {
                  this.props.multiSelectOpenFun("allFalse", false);
                  this.props.selectAllLabelFun("allFalse", false);
                  this.props.isWIBGlobalSearchEnabled(false);
                  this.props.selectedGlobalSearchModule("");
                  this.props.setOnFulfillmentFilterSelection(false);
                  this.props.setOnBreakfixFilterSelection(false);
                  this.props.setOnInvestigationFilterSelection(false);
                  this.props.setOnChangeMgmtFilterSelection(false);
                  this.props.setDefaultFulfillmentStatusSelected(false);
                  this.props.setDefaultBreakfixStatusSelected(false);
                  this.props.setDefaultInvestigationStatusSelected(false);
                  this.props.setDefaultChangeMgmtStatusSelected(false);
                  this.props.setGlobalSearchInformation("", "", "", "");
                  this.props.handleSelect(
                    "breakfix",
                    subFilterTabMapping[this.props.filterTabActive]
                  );
                  this.props.setBrkResetSelected(true);
                  this.props.bulkUpdateHandler("hideBulkUpdateSection");
                }}
                bsPrefix=" "
                className="myBt clrbtn"
              >
                <LuFilterX />
              </Button>
            </div>
          ) : (
            ""
          )}
          {this.props.filterTabActive != 5 && <Button
            title={this.props.translator["Refresh"]}
            bsPrefix=" "
            className="myBt"
            onClick={() => {
              //incident
              this.props.getslaViewListAction(
                10,
                0,
                this.state.selectedModuleName
              );
              this.props.multiSelectOpenFun("allFalse", false);
              this.props.setMultiSelectDropDownWithOk(true);
              this.props.isWIBGlobalSearchEnabled(false);
              this.props.selectedGlobalSearchModule("");
              this.props.setOnFulfillmentFilterSelection(false);
              this.props.setOnBreakfixFilterSelection(false);
              this.props.setOnInvestigationFilterSelection(false);
              this.props.setOnChangeMgmtFilterSelection(false);
              this.props.setDefaultFulfillmentStatusSelected(false);
              this.props.setDefaultBreakfixStatusSelected(false);
              this.props.setDefaultInvestigationStatusSelected(false);
              this.props.setDefaultChangeMgmtStatusSelected(false);
              this.props.setGlobalSearchInformation("", "", "", "");
              this.refreshBreakfixTable();
              this.props.refreshClickFun(true);
              this.props.bulkUpdateHandler("hideBulkUpdateSection");
            }}
          >
            <HiOutlineRefresh className="refreshFlip" />
          </Button>}
          {/* {breakFixGraphView ?
            <Button title={this.props.translator['Graph View']} role="button" bsStyle="" bsClass="" className="myBt" onClick={() => { this.props.changeToGraphView(false, 'BreakFix'); }}>
              <i className="fa fa-bar-chart" aria-hidden="true"></i>
            </Button> :
            <Button title={this.props.translator['Table View']} role="button" bsStyle="" bsClass="" className="myBt" onClick={() => { this.props.changeToGraphView(true, 'BreakFix'); }}>
              <i className="fa fa-table" aria-hidden="true"></i>
            </Button>
          } */}
        </div>
      );
    } else if (this.props.carouselIndex == "1") {
      return (
        <div
          aria-label="Page Filters"
          role="contentinfo"
          className="paHedFilter"
        >
          {this.props.fulfillmentIsClearAllFilter ? (
            <div className="overlayDv">
              <Button
                title="Clear All Filters"
                role="button"
                onClick={() => {
                  this.props.multiSelectOpenFun("allFalse", false);
                  this.props.selectAllLabelFun("allFalse", false);
                  this.props.isWIBGlobalSearchEnabled(false);
                  this.props.selectedGlobalSearchModule("");
                  this.props.selectedGlobalSearchModule("");
                  this.props.selectedGlobalSearchModule("");
                  this.props.setOnFulfillmentFilterSelection(false);
                  this.props.setOnBreakfixFilterSelection(false);
                  this.props.setOnInvestigationFilterSelection(false);
                  this.props.setOnChangeMgmtFilterSelection(false);
                  this.props.setDefaultFulfillmentStatusSelected(false);
                  this.props.setDefaultBreakfixStatusSelected(false);
                  this.props.setDefaultInvestigationStatusSelected(false);
                  this.props.setDefaultChangeMgmtStatusSelected(false);
                  this.props.setGlobalSearchInformation("", "", "", "");
                  this.crossHandleSelect(this.props.filterTabActive);
                  this.props.setBrkResetSelected(true);
                  this.props.bulkUpdateHandler("hideBulkUpdateSection");
                }}
                bsPrefix=" "
                className="myBt clrbtn"
              >
                <LuFilterX />
              </Button>
            </div>
          ) : (
            ""
          )}
          <Button
            title={this.props.translator["Refresh"]}
            bsPrefix=" "
            className="myBt"
            onClick={() => {
              // fulfillment
              this.props.getslaViewListAction(
                10,
                0,
                this.state.selectedModuleName
              );
              this.props.multiSelectOpenFun("allFalse", false);
              this.props.setMultiSelectDropDownWithOk(true);
              this.setState({ selectedModuleName: "fullfilment" });
              this.props.isWIBGlobalSearchEnabled(false);
              this.props.selectedGlobalSearchModule("");
              this.props.selectedGlobalSearchModule("");
              this.props.selectedGlobalSearchModule("");
              this.props.setOnFulfillmentFilterSelection(false);
              this.props.setOnBreakfixFilterSelection(false);
              this.props.setOnInvestigationFilterSelection(false);
              this.props.setOnChangeMgmtFilterSelection(false);
              this.props.setDefaultFulfillmentStatusSelected(false);
              this.props.setDefaultBreakfixStatusSelected(false);
              this.props.setDefaultInvestigationStatusSelected(false);
              this.props.setDefaultChangeMgmtStatusSelected(false);
              this.props.setGlobalSearchInformation("", "", "", "");
              this.refreshFulfillmentTable();
              this.props.refreshClickFun(true);
              this.props.bulkUpdateHandler("hideBulkUpdateSection");
            }}
          >
            <HiOutlineRefresh className="refreshFlip" />
          </Button>
          {/* {fullfilmentGraphView ?
            <Button title={this.props.translator['Graph View']} role="button" bsStyle="" bsClass="" className="myBt" onClick={() => { this.props.changeToGraphView(false, 'Fullfilment') }}>
              <i className="fa fa-bar-chart" aria-hidden="true"></i>
            </Button>
            :
            <Button title={this.props.translator['Table View']} role="button" bsStyle="" bsClass="" className="myBt" onClick={() => { this.props.changeToGraphView(true, 'Fullfilment') }}>
              <i className="fa fa-table" aria-hidden="true"></i>
           </Button>
          } */}
        </div>
      );
    } else if (this.props.carouselIndex == "2") {
      let subFilterTabMapping = { 1: "individual", 2: "group", 3: "all" };
      return (
        <div
          aria-label="Page Filters"
          role="contentinfo"
          className="paHedFilter"
        >
          {!this.props.only_problem_viewer_role_enabled && isproblemInvestigator
            ? investigationGraphView &&
              showCreateInvestigationButton && (
                <Link
                  aria-label="Add"
                  role="button"
                  className="myBt plus fillBtn"
                  title={this.props.translator["Request for Investigation"]}
                  to="/investigationCreate"
                >
                  <HiPlus />
                </Link>
              )
            : ""}
          {this.props.isClearAllFilterInv ? (
            <div className="overlayDv">
              <Button
                title="Clear All Filters"
                role="button"
                bsPrefix=" "
                className="myBt clrbtn"
                onClick={() => {
                  this.props.multiSelectOpenFun("allFalse", false);
                  this.props.selectAllLabelFun("allFalse", false);
                  this.props.isWIBGlobalSearchEnabled(false);
                  this.props.selectedGlobalSearchModule("");
                  this.props.setOnFulfillmentFilterSelection(false);
                  this.props.setOnBreakfixFilterSelection(false);
                  this.props.setOnInvestigationFilterSelection(false);
                  this.props.setOnChangeMgmtFilterSelection(false);
                  this.props.setDefaultFulfillmentStatusSelected(false);
                  this.props.setDefaultBreakfixStatusSelected(false);
                  this.props.setDefaultInvestigationStatusSelected(false);
                  this.props.setDefaultChangeMgmtStatusSelected(false);
                  this.props.setGlobalSearchInformation("", "", "", "");
                  this.props.handleSelect(
                    "investigation",
                    subFilterTabMapping[this.props.filterTabActive]
                  );
                  this.props.setBrkResetSelected(true);
                  this.props.bulkUpdateHandler("hideBulkUpdateSection");
                }}
              >
                <LuFilterX />
              </Button>
            </div>
          ) : (
            ""
          )}
          <Button
            title={this.props.translator["Refresh"]}
            bsPrefix=" "
            className="myBt"
            onClick={() => {
              // problrm
              this.props.getslaViewListAction(
                10,
                0,
                this.state.selectedModuleName
              );
              this.props.multiSelectOpenFun("allFalse", false);
              this.props.setMultiSelectDropDownWithOk(true);
              this.props.isWIBGlobalSearchEnabled(false);
              this.props.selectedGlobalSearchModule("");
              this.props.setOnFulfillmentFilterSelection(false);
              this.props.setOnBreakfixFilterSelection(false);
              this.props.setOnInvestigationFilterSelection(false);
              this.props.setOnChangeMgmtFilterSelection(false);
              this.props.setDefaultFulfillmentStatusSelected(false);
              this.props.setDefaultBreakfixStatusSelected(false);
              this.props.setDefaultInvestigationStatusSelected(false);
              this.props.setDefaultChangeMgmtStatusSelected(false);
              this.props.setGlobalSearchInformation("", "", "", "");
              this.refreshInvestigationTable();
              this.props.refreshClickFun(true);
              this.props.bulkUpdateHandler("hideBulkUpdateSection");
            }}
          >
            <HiOutlineRefresh className="refreshFlip" />
          </Button>
        </div>
      );
    } else if (this.props.carouselIndex == "3") {
      return (
        <div
          aria-label="Page Filters"
          role="contentinfo"
          className="paHedFilter"
        >
          {investigationGraphView &&
            showCreateChangeButton &&
            !this.props.only_change_viewer_role_enabled && (
              <Link
                aria-label="Add"
                role="button"
                className="myBt plus fillBtn"
                title={this.props.translator["Request for Change"]}
                to="/createChange"
              >
                <HiPlus />
              </Link>
            )}
          {/* {changeGraphView && <Button title={this.props.translator['Request For Change']} href="/createChange"><span className="sAction bgBtn15 artAdd"></span></Button>} */}
          {this.props.changeIsClearAllFilter ? (
            <div className="overlayDv">
              <Button
                title="Clear All Filters"
                role="button"
                onClick={() => {
                  this.props.multiSelectOpenFun("allFalse", false);
                  this.props.selectAllLabelFun("allFalse", false);
                  this.props.isWIBGlobalSearchEnabled(false);
                  this.props.selectedGlobalSearchModule("");
                  this.props.selectedGlobalSearchModule("");
                  this.props.selectedGlobalSearchModule("");
                  this.props.setOnFulfillmentFilterSelection(false);
                  this.props.setOnBreakfixFilterSelection(false);
                  this.props.setOnInvestigationFilterSelection(false);
                  this.props.setOnChangeMgmtFilterSelection(false);
                  this.props.setDefaultFulfillmentStatusSelected(false);
                  this.props.setDefaultBreakfixStatusSelected(false);
                  this.props.setDefaultInvestigationStatusSelected(false);
                  this.props.setDefaultChangeMgmtStatusSelected(false);
                  this.props.setGlobalSearchInformation("", "", "", "");
                  GLOBAL.impleGroup = 0;
                  GLOBAL.changeMangemtGroup = 0;
                  this.props.filterTabClick(
                    this.props.filterTabActive,
                    "changeManagement"
                  );
                  this.props.setChangeMgmtgroupSelected(false);
                  this.props.setImplementationMgmtgroupSelected(false);
                  this.props.setBrkResetSelected(true);
                  this.props.setGroupFilterApplied([]);
                  this.props.bulkUpdateHandler("hideBulkUpdateSection");
                }}
                bsPrefix=" "
                className="myBt clrbtn"
              >
                <LuFilterX />
              </Button>
            </div>
          ) : (
            ""
          )}
          <Button
            title={this.props.translator["Refresh"]}
            bsPrefix=" "
            className="myBt"
            onClick={() => {
              // change
              this.props.getslaViewListAction(
                10,
                0,
                this.state.selectedModuleName
              );
              this.props.multiSelectOpenFun("allFalse", false);
              this.props.setMultiSelectDropDownWithOk(true);
              this.props.isWIBGlobalSearchEnabled(false);
              this.props.selectedGlobalSearchModule("");
              this.props.selectedGlobalSearchModule("");
              this.props.selectedGlobalSearchModule("");
              this.props.setOnFulfillmentFilterSelection(false);
              this.props.setOnBreakfixFilterSelection(false);
              this.props.setOnInvestigationFilterSelection(false);
              this.props.setOnChangeMgmtFilterSelection(false);
              this.props.setDefaultFulfillmentStatusSelected(false);
              this.props.setDefaultBreakfixStatusSelected(false);
              this.props.setDefaultInvestigationStatusSelected(false);
              this.props.setDefaultChangeMgmtStatusSelected(false);
              this.props.setGlobalSearchInformation("", "", "", "");
              this.refreshChangeTable();
              this.props.refreshClickFun(true);
              this.props.bulkUpdateHandler("hideBulkUpdateSection");
            }}
          >
            <HiOutlineRefresh className="refreshFlip" />
          </Button>
          {/* {changeGraphView ?
            <Button title={this.props.translator['Graph View']} role="button" bsStyle="" bsClass="" className="myBt" onClick={() => { this.props.changeToGraphView(false, 'Change') }}>
              <i className="fa fa-bar-chart" aria-hidden="true"></i>
            </Button>
            :
            <Button title={this.props.translator['Table View']} role="button" bsStyle="" bsClass="" className="myBt" onClick={() => { this.props.changeToGraphView(true, 'Change') }}>
              <i className="fa fa-table" aria-hidden="true"></i>
            </Button>
          } */}
        </div>
      );
    } else if (this.props.carouselIndex == "5") {
      return (
        <div
          aria-label="Page Filters"
          role="contentinfo"
          className="paHedFilter"
        >
          <div className="cal-icon">
            <i className="fa fa-calendar" />
            &nbsp;&nbsp;
          </div>
          <Form.Check
            type="switch"
            id="custom-switch"
            checked={this.state.isSwitchChecked}
            onClick={(e) => {
              this.setState({ isSwitchChecked: e.target.checked });
              if (e.target.checked) {
                this.props.setAppointmentAction("console");
              } else {
                // this.props.setAppointmentAction("meetingList");
                // this.props.handleSelect("appointment");
                this.props.selectedFilterTab(1);
                this.props.handleSelect("appointment");
                this.setState({ selectedModuleName: "appointment" });
              }
            }}
          />
          <Link
            aria-label="Add"
            role="button"
            className="myBt plus fillBtn"
            title={"Request for Appointment"}
            to="/add-edit/meeting"
          >
            <HiPlus />
          </Link>
          {this.props.showAppointmentClearFilter == true &&
          !this.state.isSwitchChecked ? (
            <div className="overlayDv">
              <Button
                title="Clear All Filters"
                role="button"
                onClick={() => {
                  this.props.setAppointmentAction("clear");
                  this.props.showRightPanel(null, null);
                }}
                bsPrefix=" "
                className="myBt clrbtn"
              >
                <LuFilterX />
              </Button>
            </div>
          ) : (
            ""
          )}
          {this.state.isSwitchChecked == false && <Button
            title={this.props.translator["Refresh"]}
            bsPrefix=" "
            className="myBt"
            onClick={() => {
              this.setState({ isSwitchChecked: false });
              this.props.setAppointmentAction("refresh");
              this.props.showRightPanel(null, null);
            }}
          >
            <HiOutlineRefresh className="refreshFlip" />
          </Button>}
          {this.state.isSwitchChecked == true && <Button
            title={this.props.translator["Refresh"]}
            bsPrefix=" "
            className="myBt"
            onClick={() => {
              this.setState({ isSwitchChecked: true });
              this.props.setAppointmentAction("console");
              this.props.setConsoleRefreshButton(true);
            }}
          >
            <HiOutlineRefresh className="refreshFlip" />
          </Button>}
        </div>
      );
    } else if (this.props.carouselIndex == "6") {
      return (
        <div
          aria-label="Page Filters"
          role="contentinfo"
          className="paHedFilter"
        >
          <Link
            aria-label="Add"
            role="button"
            className="myBt plus fillBtn"
            title={"Request for Actions"}
            to="/add-edit/action"
          >
            <HiPlus />
          </Link>
          {this.props.showAiClearFilter == true && !this.state.isSwitchChecked ? (
            <div className="overlayDv">
              <Button
                title="Clear All Filters"
                role="button"
                onClick={() => {
                  this.props.showRightPanel(null, null);
                  this.props.setAiAction("clear");
                }}
                bsPrefix=" "
                className="myBt clrbtn"
              >
                <LuFilterX />
              </Button>
            </div>
          ) : (
            ""
          )}
          {!this.state.isSwitchChecked && (<Button
            title={this.props.translator["Refresh"]}
            bsPrefix=" "
            className="myBt"
            onClick={() => {
              this.props.showRightPanel(null, null);
              this.props.setAiAction("refresh");
            }}
          >
            <HiOutlineRefresh className="refreshFlip" />
          </Button>)}
        </div>
      );
    } else if(this.props.carouselIndex == "7"){
      return (
        <div
          aria-label="Page Filters"
          role="contentinfo"
          className="paHedFilter"
        >
          <Button
            title={this.props.translator["Refresh"]}
            bsPrefix=" "
            className="myBt"
            onClick={() => {
              this.props.refreshClickFun(true);
            }}
          >
            <HiOutlineRefresh className="refreshFlip" />
          </Button>
        </div>
      );
    }else {
      let subFilterTabMapping = { 1: "individual", 2: "group", 3: "all" };
      return (
        <div
          aria-label="Page Filters"
          role="contentinfo"
          className="paHedFilter"
        >
          {this.props.taskIsClearAllFilter ? (
            <div className="overlayDv">
              <Button
                title="Clear All Filters"
                role="button"
                onClick={() => {
                  this.props.isWIBGlobalSearchEnabled(false);
                  this.props.selectedGlobalSearchModule("");
                  this.props.setOnFulfillmentFilterSelection(false);
                  this.props.setOnBreakfixFilterSelection(false);
                  this.props.setOnInvestigationFilterSelection(false);
                  this.props.setOnChangeMgmtFilterSelection(false);
                  this.props.setDefaultFulfillmentStatusSelected(false);
                  this.props.setDefaultBreakfixStatusSelected(false);
                  this.props.setDefaultInvestigationStatusSelected(false);
                  this.props.setDefaultChangeMgmtStatusSelected(false);
                  this.props.setGlobalSearchInformation("", "", "", "");
                  this.props.handleSelect(
                    "task",
                    subFilterTabMapping[this.props.filterTabActive]
                  );
                  this.props.setBrkResetSelected(true);
                  this.props.bulkUpdateHandler('hideBulkUpdateSection');
                }}
                bsPrefix=" "
                className="myBt clrbtn"
              >
                <LuFilterX />
              </Button>
            </div>
          ) : (
            ""
          )}
          <Button
            title={this.props.translator["Refresh"]}
            bsPrefix=" "
            className="myBt"
            onClick={() => {
              //task
              this.props.setLimit(10);
              this.props.setPage(0);
              this.props.getslaViewListAction(
                10,
                0,
                this.state.selectedModuleName
              );
              this.props.multiSelectOpenFun("allFalse", false);
              this.props.setMultiSelectDropDownWithOk(true);
              this.props.isWIBGlobalSearchEnabled(false);
              this.props.selectedGlobalSearchModule("");
              this.props.setOnFulfillmentFilterSelection(false);
              this.props.setOnBreakfixFilterSelection(false);
              this.props.setOnInvestigationFilterSelection(false);
              this.props.setOnChangeMgmtFilterSelection(false);
              this.props.setDefaultFulfillmentStatusSelected(false);
              this.props.setDefaultBreakfixStatusSelected(false);
              this.props.setDefaultInvestigationStatusSelected(false);
              this.props.setDefaultChangeMgmtStatusSelected(false);
              this.props.setGlobalSearchInformation("", "", "", "");
              this.refreshTaskTable();
              this.props.refreshClickFun(true);
              this.props.bulkUpdateHandler('hideBulkUpdateSection');
            }}
          >
            <HiOutlineRefresh className="refreshFlip" />
          </Button>
        </div>
      );
    }
  }

  refreshTaskTable() {
    if (this.props.filterTabActive === 1) {
      this.props.loadTaskList(
        "",
        this.props.insideParams,
        this.props.taskTableSize,
        0,
        "individual",
        this.props.user_id
      );
    } else if (this.props.filterTabActive === 2) {
      this.props.loadTaskList(
        "",
        this.props.insideParams,
        this.props.taskTableSize,
        0
      );
    } else if (this.props.filterTabActive === 3) {
      this.props.loadTaskList(
        "",
        this.props.insideParams,
        this.props.taskTableSize,
        0,
        "all",
        ""
      );
    }
  }

  renderTaskList() {
    let taskCount =
      this.props.myTaskDetailsListMetaData.meta &&
      this.props.myTaskDetailsListMetaData.meta.rowCount
        ? "(" + this.props.myTaskDetailsListMetaData.meta.rowCount + ")"
        : "";

    return (
      <li className={this.props.carouselIndex == 4 ? "active" : null}>
        <a
          role="button"
          href="javascript:;"
          title={this.props.translator["Task"]}
          onClick={() => {
            this.props.getslaViewListAction(10, 0, "task");
            GLOBAL.slaHeadEnable = false;
            this.props.slaDisableDateRange(false);
            this.props.isWIBGlobalSearchEnabled(false);
            this.props.selectedGlobalSearchModule("");
            this.props.selectedGlobalSearchModule("");
            this.props.selectedGlobalSearchModule("");
            this.props.setOnFulfillmentFilterSelection(false);
            this.props.setOnBreakfixFilterSelection(false);
            this.props.setOnInvestigationFilterSelection(false);
            this.props.setOnChangeMgmtFilterSelection(false);
            this.props.setDefaultFulfillmentStatusSelected(false);
            this.props.setDefaultBreakfixStatusSelected(false);
            this.props.setDefaultInvestigationStatusSelected(false);
            this.props.setDefaultChangeMgmtStatusSelected(false);
            this.props.setGlobalSearchInformation("", "", "", "");
            if (this.restrictSubtypeAssignedToMe("task")) {
              this.props.selectedFilterTab(1);
              this.props.handleSelect("task");
            } else {
              this.props.selectedFilterTab(3);
              this.props.handleSelect("task", "all");
            }
            this.setState({ selectedModuleName: "task" });
            this.props.bulkUpdateHandler('hideBulkUpdateSection');
            this.props.showConditionBuilder(false);
          }}
        >
          {this.props.translator["Task"]}
          {this.props.carouselIndex == 4 ? taskCount : null}
        </a>
      </li>
    );
  }
  renderFulfillmentList() {
    let fulfillmentCount =
      this.props.fulFillmentList.meta &&
      this.props.fulFillmentList.meta.recordCount
        ? "(" + this.props.fulFillmentList.meta.recordCount + ")"
        : "";

    return this.props.moduleList.data.map((moduleObj, index) => {
      if (moduleObj == "fullfilment") {
        return (
          <li className={this.props.carouselIndex == 1 ? "active" : null}>
            <a
              role="button"
              href="javascript:;"
              title={this.props.translator["Fulfillment"]}
              onClick={() => {
                GLOBAL.slaHeadEnable = false;
                this.props.getslaViewListAction(10, 0, moduleObj);
                this.props.slaDisableDateRange(false);
                this.props.isWIBGlobalSearchEnabled(false);
                this.props.selectedGlobalSearchModule("");
                this.props.setOnFulfillmentFilterSelection(false);
                this.props.setOnBreakfixFilterSelection(false);
                this.props.setOnInvestigationFilterSelection(false);
                this.props.setOnChangeMgmtFilterSelection(false);
                this.props.setDefaultFulfillmentStatusSelected(false);
                this.props.setDefaultBreakfixStatusSelected(false);
                this.props.setDefaultInvestigationStatusSelected(false);
                this.props.setDefaultChangeMgmtStatusSelected(false);
                this.props.setGlobalSearchInformation("", "", "", "");
                if (this.restrictSubtypeAssignedToMe("fullfilment")) {
                  this.props.selectedFilterTab(1);
                  this.props.handleSelectMyGroup();
                } else {
                  this.props.selectedFilterTab(3);
                  this.props.handleSelect("fullfilment");
                }
                this.setState({ selectedModuleName: "fullfilment" });
                this.props.bulkUpdateHandler("hideBulkUpdateSection");
                this.props.showConditionBuilder(false);
              }}
            >
              {this.props.translator["Fulfillment"]}
              {this.props.carouselIndex == 1 ? fulfillmentCount : null}
            </a>
          </li>
        );
      }
    });
  }
  renderInvestigationList() {
    // console.log("function called");
    let investigationStr =
      this.props.investigationList.meta &&
      this.props.investigationList.meta.recordCount
        ? "(" + this.props.investigationList.meta.recordCount + ")"
        : "";
    return this.props.moduleList.data.map((moduleObj, index) => {
      // console.log("investifa+++",moduleObj);
      if (moduleObj == "investigation") {
        return (
          <li className={this.props.carouselIndex == 2 ? "active" : null}>
            <a
              role="button"
              href="javascript:;"
              title={this.props.translator["Problem"]}
              onClick={() => {
                GLOBAL.slaHeadEnable = false;
                this.props.getslaViewListAction(10, 0, moduleObj);
                this.props.slaDisableDateRange(false);
                this.props.isWIBGlobalSearchEnabled(false);
                this.props.selectedGlobalSearchModule("");
                this.props.setOnFulfillmentFilterSelection(false);
                this.props.setOnBreakfixFilterSelection(false);
                this.props.setOnInvestigationFilterSelection(false);
                this.props.setOnChangeMgmtFilterSelection(false);
                this.props.setDefaultFulfillmentStatusSelected(false);
                this.props.setDefaultBreakfixStatusSelected(false);
                this.props.setDefaultInvestigationStatusSelected(false);
                this.props.setDefaultChangeMgmtStatusSelected(false);
                this.props.setGlobalSearchInformation("", "", "", "");
                if (this.restrictSubtypeAssignedToMe("investigation")) {
                  this.props.selectedFilterTab(1);
                  this.props.handleSelect("investigation");
                } else {
                  this.props.selectedFilterTab(3);
                  this.props.handleSelect("investigation", "all");
                }
                this.setState({ selectedModuleName: "investigation" });
                this.props.bulkUpdateHandler("hideBulkUpdateSection");
                this.props.showConditionBuilder(false);
              }}
            >
              {this.props.translator["Problem"]}
              {this.props.carouselIndex == 2 ? investigationStr : null}
            </a>
          </li>
        );
      }
    });
  }
  renderBreakfixList() {
    // console.log("function called");
    let brkfixStr =
      this.props.quickViewDetails.meta &&
      this.props.quickViewDetails.meta.recordCount
        ? "(" + this.props.quickViewDetails.meta.recordCount + ")"
        : "";
    return this.props.moduleList.data.map((moduleObj, index) => {
      // console.log("investifa+++",moduleObj);
      if (moduleObj == "breakfix") {
        return (
          <li className={this.props.carouselIndex == 0 ? "active" : null}>
            <a
              role="button"
              href="javascript:;"
              title={this.props.translator["Incident"]}
              onClick={() => {
                GLOBAL.slaHeadEnable = false;
                this.props.getslaViewListAction(10, 0, moduleObj);
                this.props.slaDisableDateRange(false);
                this.props.isWIBGlobalSearchEnabled(false);
                this.props.selectedGlobalSearchModule("");
                this.props.setOnFulfillmentFilterSelection(false);
                this.props.setOnBreakfixFilterSelection(false);
                this.props.setOnInvestigationFilterSelection(false);
                this.props.setOnChangeMgmtFilterSelection(false);
                this.props.setDefaultFulfillmentStatusSelected(false);
                this.props.setDefaultBreakfixStatusSelected(false);
                this.props.setDefaultInvestigationStatusSelected(false);
                this.props.setDefaultChangeMgmtStatusSelected(false);
                this.props.setGlobalSearchInformation("", "", "", "");
                if (this.restrictSubtypeAssignedToMe("breakfix")) {
                  this.props.selectedFilterTab(1);
                  this.props.handleSelect("breakfix");
                } else {
                  this.props.selectedFilterTab(3);
                  this.props.handleSelect("breakfix", "all");
                }
                this.setState({ selectedModuleName: "breakfix" });
                this.props.setBrkResetSelected(true);
                this.props.bulkUpdateHandler("hideBulkUpdateSection");
                this.props.showConditionBuilder(false);
              }}
            >
              {this.props.translator["Incident"]}
              {this.props.carouselIndex == 0 ? brkfixStr : null}
            </a>
          </li>
        );
      }
    });
  }
  renderChangeManagementList() {
    // console.log("function called");
    let changeCount =
      this.props.myChangeManagementList.meta &&
      this.props.myChangeManagementList.meta.recordCount
        ? "(" + this.props.myChangeManagementList.meta.recordCount + ")"
        : "";
    return this.props.moduleList.data.map((moduleObj, index) => {
      // console.log("investifa+++",moduleObj);
      if (moduleObj == "changeManagement") {
        return (
          <li className={this.props.carouselIndex == 3 ? "active" : null}>
            <a
              role="button"
              href="javascript:;"
              title={this.props.translator["Change"]}
              onClick={() => {
                GLOBAL.slaHeadEnable = false;
                this.props.getslaViewListAction(10, 0, moduleObj);
                this.props.slaDisableDateRange(false);
                this.props.isWIBGlobalSearchEnabled(false);
                this.props.selectedGlobalSearchModule("");
                this.props.setOnFulfillmentFilterSelection(false);
                this.props.setOnBreakfixFilterSelection(false);
                this.props.setOnInvestigationFilterSelection(false);
                this.props.setOnChangeMgmtFilterSelection(false);
                this.props.setDefaultFulfillmentStatusSelected(false);
                this.props.setDefaultBreakfixStatusSelected(false);
                this.props.setDefaultInvestigationStatusSelected(false);
                this.props.setDefaultChangeMgmtStatusSelected(false);
                this.props.setGlobalSearchInformation("", "", "", "");
                GLOBAL.impleGroup = 0;
                GLOBAL.changeMangemtGroup = 0;
                if (this.restrictSubtypeAssignedToMe("changeManagement")) {
                  this.props.selectedFilterTab(1);
                  this.props.filterTabClick(1, "changeManagement");
                } else {
                  this.props.selectedFilterTab(3);
                  this.props.filterTabClick(3, "changeManagement");
                }
                this.setState({ selectedModuleName: "changeManagement" });
                this.props.setChangeMgmtgroupSelected(false);
                this.props.setImplementationMgmtgroupSelected(false);
                this.props.setGroupFilterApplied([]);
                this.props.bulkUpdateHandler("hideBulkUpdateSection");
                this.props.showConditionBuilder(false);
              }}
            >
              {this.props.translator["Change"]}
              {this.props.carouselIndex == 3 ? changeCount : null}
            </a>
          </li>
        );
      }
    });
  }
  restrictSubtypeAll() {
    return (
      (this.state.selectedModuleName == "fullfilment" &&
        (this.props.role_id.split(",").includes("44") ||
          this.props.role_id.split(",").includes("48") ||
          this.props.only_fulfillment_viewer_role_enabled)) ||
      (this.state.selectedModuleName == "breakfix" &&
        (this.props.role_id.split(",").includes("13") ||
          this.props.role_id.split(",").includes("14") ||
          this.props.role_id.split(",").includes("48") ||
          this.props.only_incident_viewer_role_enabled)) ||
      (this.state.selectedModuleName == "investigation" &&
        (this.props.role_id.split(",").includes("17") ||
          this.props.role_id.split(",").includes("48") ||
          this.props.only_problem_viewer_role_enabled)) ||
      (this.state.selectedModuleName == "changeManagement" &&
        (this.props.role_id.split(",").includes("52") ||
          this.props.role_id.split(",").includes("20") ||
          this.props.role_id.split(",").includes("48") ||
          this.props.only_change_viewer_role_enabled)) ||
      (this.state.selectedModuleName == "task" &&
        ((!this.props.core_d2c_roles_enabled && this.props.task_viewer_role_enabled) || this.props.manager_role_enabled || 
        (this.props.core_user_role_enabled && this.props.task_viewer_role_enabled) || 
        this.props.service_desk_role_enabled ||
        this.props.critical_incident_manager_enabled))
    );
  }
  restrictSubtypeAssignMyGroup() {
    if (
      this.state.selectedModuleName == "investigation" &&
      this.props.role_id.split(",").includes("53") &&
      !this.props.role_id.split(",").includes("48") &&
      !(
        this.props.role_id.split(",").includes("16") ||
        this.props.role_id.split(",").includes("17")
      )
    ) {
      return false;
    } else if (
      this.state.selectedModuleName === "investigation" &&
      this.props.only_problem_viewer_role_enabled
    ) {
      return false;
    } else if (
      this.state.selectedModuleName === "breakfix" &&
      this.props.only_incident_viewer_role_enabled
    ) {
      return false;
    } else if (
      this.state.selectedModuleName === "fullfilment" &&
      this.props.only_fulfillment_viewer_role_enabled
    ) {
      return false;
    } else if (
      this.state.selectedModuleName === "changeManagement" &&
      this.props.only_change_viewer_role_enabled
    ) {
      return false;
    } else if (
      this.state.selectedModuleName === "task" &&
      !this.props.core_d2c_roles_enabled &&
      this.props.task_viewer_role_enabled
    ) {
      return false;
    }else {
      return true;
    }
  }
  restrictSubtypeAssignedToMe(moduleName = "") {
    if (
      moduleName === "task" &&
      !this.props.core_d2c_roles_enabled &&
      this.props.task_viewer_role_enabled
    ) {
      return false;
    } else if (
      moduleName === "breakfix" &&
      this.props.only_incident_viewer_role_enabled
    ) {
      return false;
    } else if (
      moduleName === "fullfilment" &&
      this.props.only_fulfillment_viewer_role_enabled
    ) {
      return false;
    } else if (
      moduleName === "investigation" &&
      this.props.only_problem_viewer_role_enabled
    ) {
      return false;
    } else if (
      moduleName === "changeManagement" &&
      this.props.only_change_viewer_role_enabled
    ) {
      return false;
    } else {
      return true;
    }
  }

  clickedOnHeaderText(module) {
    switch (module) {
      case "fullfilment":
        let field = document.getElementById("fulfillmentDate");
        if (typeof field !== "undefined") {
          let FulfillmentTableContainer = document.getElementById("FulfillmentTableContainer");
          if (langDir === "rtl") {
            let rtlScrollLeft = FulfillmentTableContainer.scrollLeft - FulfillmentTableContainer.scrollWidth;
            FulfillmentTableContainer.scrollLeft = rtlScrollLeft;
          }
          else {
            FulfillmentTableContainer.scrollLeft = FulfillmentTableContainer.scrollWidth;
          }
          field.click();
        }
        break;
      case "breakfix":
        let incidentReportedOnField = document.getElementById(
          "incidentReportedOnField"
        );
        if (typeof incidentReportedOnField !== "undefined") {
          let IncidentTableContainer = document.getElementById(
            "IncidentTableContainer"
          );
          if (langDir === "rtl") {
            let rtlScrollLeft = IncidentTableContainer.scrollLeft - IncidentTableContainer.scrollWidth;
            IncidentTableContainer.scrollLeft = rtlScrollLeft;
          }
          else {
            IncidentTableContainer.scrollLeft = IncidentTableContainer.scrollWidth;
          }
          incidentReportedOnField.click();
        }
        break;
      case "investigation":
        let probReportedOnField = document.getElementById(
          "probReportedOnField"
        );
        if (typeof probReportedOnField !== "undefined") {
          let ProbTableContainer =
            document.getElementById("ProbTableContainer");
          if (langDir === "rtl") {
            let rtlScrollLeft = ProbTableContainer.scrollLeft - ProbTableContainer.scrollWidth;
            ProbTableContainer.scrollLeft = rtlScrollLeft;
          }
          else {
            ProbTableContainer.scrollLeft = ProbTableContainer.scrollWidth;
          }
          probReportedOnField.click();
        }
        break;
      case "changeManagement":
        let changeReportedOnField = document.getElementById(
          "changeReportedOnField"
        );
        if (typeof changeReportedOnField !== "undefined") {
          let ChangeTableContainer = document.getElementById(
            "ChangeTableContainer"
          );
          if (langDir === "rtl") {
            let rtlScrollLeft = ChangeTableContainer.scrollLeft - ChangeTableContainer.scrollWidth;
            ChangeTableContainer.scrollLeft = rtlScrollLeft;
          }
          else {
            ChangeTableContainer.scrollLeft = ChangeTableContainer.scrollWidth;
          }
          changeReportedOnField.click();
        }
        break;
      case "task":
        let taskReportedOn = document.getElementById("taskReportedOn");
        if (typeof taskReportedOn !== "undefined") {
          let taskTableContainer =
            document.getElementById("taskTableContainer");
          if (langDir === "rtl") {
            let rtlScrollLeft = taskTableContainer.scrollLeft - taskTableContainer.scrollWidth;
            taskTableContainer.scrollLeft = rtlScrollLeft;
          }
          else {
            taskTableContainer.scrollLeft = taskTableContainer.scrollWidth;
          }
          taskReportedOn.click();
        }
        break;
      case "appointment":
        let appointmentStartOnField = document.getElementById(
          "appointmentStartOnField"
        );
        if (typeof appointmentStartOnField !== "undefined") {
          let AppointmentTableContainer = document.getElementById(
            "AppointmentTableContainer"
          );
          if (langDir === "rtl") {
            let rtlScrollLeft = AppointmentTableContainer.scrollLeft - AppointmentTableContainer.scrollWidth;
            AppointmentTableContainer.scrollLeft = rtlScrollLeft;
          }
          else {
            AppointmentTableContainer.scrollLeft = AppointmentTableContainer.scrollWidth;
          }
          appointmentStartOnField.click();
        }
        break;
      default:
        break;
    }
  }

  renderAppointmentList() {
    // console.log("function called");
    // let brkfixStr =
    //   this.props.quickViewDetails.meta &&
    //   this.props.quickViewDetails.meta.recordCount
    //     ? "(" + this.props.quickViewDetails.meta.recordCount + ")"
    //     : "";
    let brkfixStr = "";
    let moduleData = structuredClone(this.props.moduleList.data);
    // console.log('moduleData - ', moduleData);
    // moduleData.push("appointment");
    return moduleData.map((moduleObj, index) => {
      // console.log("@@@@", moduleObj);
      if (
        moduleObj &&
        typeof moduleObj == "string" &&
        moduleObj.toLowerCase() == "appointment"
      ) {
        return (
          <li className={this.props.carouselIndex == 5 ? "active" : null}>
            <a
              role="button"
              href="javascript:;"
              title={this.props.translator["Appointment"]}
              onClick={() => {
                GLOBAL.slaHeadEnable = false;
                this.setState({ isSwitchChecked: false});
                this.props.selectedFilterTab(1);
                this.props.handleSelect("appointment");
                // this.props.setAppointmentAction("meetingList");
                this.setState({ selectedModuleName: "appointment" });
                this.props.showConditionBuilder(false);
              }}
            >
              {this.props.translator["Appointment"]}
              {this.props.carouselIndex == 5 && this.props.appointmentCount && this.props.appointmentAction != "console" ? '('+this.props.appointmentCount+')' : null}
            </a>
            {/* &nbsp;&nbsp;
            <Link to="/meeting/governance-console">Console</Link> */}
          </li>
        );
      }
    });
  }

  renderAiList() {
    // console.log("function called");
    // let brkfixStr =
    //   this.props.quickViewDetails.meta &&
    //   this.props.quickViewDetails.meta.recordCount
    //     ? "(" + this.props.quickViewDetails.meta.recordCount + ")"
    //     : "";
    let brkfixStr = "";
    let moduleData = structuredClone(this.props.moduleList.data);
    // moduleData.push("actions");
    return moduleData.map((moduleObj, index) => {
      // console.log("!!!", moduleObj);
      if (
        moduleObj &&
        typeof moduleObj == "string" &&
        moduleObj.toLowerCase() == "action"
      ) {
        return (
          <li className={this.props.carouselIndex == 6 ? "active" : null}>
            <a
              role="button"
              href="javascript:;"
              title={this.props.translator["Action"]}
              onClick={() => {
                GLOBAL.slaHeadEnable = false;
                this.props.selectedFilterTab(1);
                this.props.handleSelect("action");
                this.setState({ selectedModuleName: "action" });
                this.props.showConditionBuilder(false);
              }}
            >
              {this.props.translator["Action"]}
              {this.props.carouselIndex == 6 && this.props.actionCount ? '('+this.props.actionCount+')' : null}
            </a>
          </li>
        );
      }
    });
  }

  renderWalkup() {
    let brkfixStr = "";
    let moduleData = structuredClone(this.props.moduleList.data);
    // return moduleData.map((moduleObj, index) => {
    //   // if (
    //   //   moduleObj &&
    //   //   typeof moduleObj == "string" &&
    //   //   moduleObj.toLowerCase() == "walkup"
    //   // ) {
        return (
          <li className={this.props.carouselIndex == 7 ? "active" : null}>
            <a
              role="button"
              href="javascript:;"
              title={this.props.translator["Walk Up"]}
              onClick={() => {
                GLOBAL.slaHeadEnable = false;
                this.props.selectedFilterTab(1);
                this.props.handleSelect("walkup");
                this.setState({ selectedModuleName: "walkup" });
                this.props.showConditionBuilder(false);
              }}
            >{this.props.translator["Walk Up"]}</a>
          </li>
        );
      // }
    // });
  }

  restrictSubtypeSLAView() {
    return (
      (this.state.selectedModuleName == "fullfilment" &&
        (this.props.role_id.split(",").includes("44")))||
      (this.state.selectedModuleName == "breakfix" &&
        (this.props.role_id.split(",").includes("13") ||
          this.props.role_id.split(",").includes("14")))||
      (this.state.selectedModuleName == "investigation" &&
        (this.props.role_id.split(",").includes("17"))) ||
      (this.state.selectedModuleName == "changeManagement" &&
        (this.props.role_id.split(",").includes("52") ||
          this.props.role_id.split(",").includes("20"))) ||
      (this.state.selectedModuleName == "task" &&
        (this.props.role_id.split(",").includes("44") || this.props.role_id.split(",").includes("17")
        || this.props.role_id.split(",").includes("20") || this.props.role_id.split(",").includes("52")
        || this.props.role_id.split(",").includes("13") || this.props.role_id.split(",").includes("14")))
    );
  }

  onSubStatusSelect(subStatusID){
    const propsRef = this.props;
    propsRef.clearBreakfixData();
    propsRef.showRightPanel(null, null);
    propsRef.setwibGlobalSearchFilterData({ filterTabActive: subStatusID, selectedGlobalModule: this.state.selectedModuleName,});
    GLOBAL.slaHeadEnable = false;
    propsRef.slaDisableDateRange(false);
    propsRef.isWIBGlobalSearchEnabled(false);
    propsRef.selectedGlobalSearchModule("");
    propsRef.setOnFulfillmentFilterSelection(false);
    propsRef.setOnBreakfixFilterSelection(false);
    propsRef.setOnInvestigationFilterSelection(false);
    propsRef.setOnChangeMgmtFilterSelection(false);
    propsRef.setDefaultFulfillmentStatusSelected(false);
    propsRef.setDefaultBreakfixStatusSelected(false);
    propsRef.setDefaultInvestigationStatusSelected(false);
    propsRef.setDefaultChangeMgmtStatusSelected(false);
    propsRef.setGlobalSearchInformation("", "", "", "");
    if(subStatusID != 5){
      propsRef.filterTabClick(subStatusID,this.state.selectedModuleName);
    }
    propsRef.setChangeMgmtgroupSelected(false);
    propsRef.setImplementationMgmtgroupSelected(false);
    propsRef.setBrkResetSelected(true);
    propsRef.setGroupFilterApplied([]);
    propsRef.bulkUpdateHandler("hideBulkUpdateSection");
    propsRef.showRightPanel(null, null);
    propsRef.showConditionBuilder(subStatusID == 5 ? true: false);
    propsRef.resetQuery();
    propsRef.isBrkClearAllFilterFunc(false);
  }

  render() {
    let taskCheckedStatus = "";
    if (this.props.carouselIndex == "4") {
      taskCheckedStatus = true;
    } else {
      taskCheckedStatus = false;
    }

    if (this.props.moduleList.data == undefined) {
      return (
        <Row className="margin-b-15">
          <Col lg={8} md={12} sm={12} xs={12}>
            <Row>
              <Col lg={4} md={3} sm={4} xs={12}>
                <h2 bsClass="" className="sPageHeading1">
                  <h3 className="margin-0">
                    {this.props.translator["Work Item Board"]}
                  </h3>
                </h2>
              </Col>
              <Spinner spinnerName="three-bounce" />
            </Row>
          </Col>
        </Row>
      );
    } else {
      return (
        <div>
          <Row className="margin-b-15">
            <Col lg={8} md={8} sm={9} xs={8}>
              <Breadcrumbs activePageName="Work Item Board" />
            </Col>
            <Col lg={4} md={4} sm={3} xs={4}>
              {this.showPaginationCount()}
            </Col>
          </Row>
          <Row className="margin-b-10">
            <Col xs={12}>
              <div aria-label="Modules of Work Item Board" role="contentinfo">
                <ul className="wibTabs wibTabsLastLi">
                  {this.props.disableTaskModule ? "" : this.renderTaskList()}
                  {this.props.fulfillmentModuleEnabled
                    ? this.renderFulfillmentList()
                    : ""}
                  {this.props.incidentModuleEnabled
                    ? this.renderBreakfixList()
                    : ""}
                  {this.props.problemModuleEnabled
                    ? this.renderInvestigationList()
                    : ""}
                  {this.props.changeModuleEnabled
                    ? this.renderChangeManagementList()
                    : ""}
                  {this.props.only_viewer_role_enabled
                    ? ""
                    : this.renderAppointmentList()}
                  {this.props.only_viewer_role_enabled
                    ? ""
                    : this.renderAiList()}   

                  {this.props.walkupRoleEnabled && this.renderWalkup()}
                  <li className="lastli">{this.showModuleIcons()}</li>
                </ul>
              </div>
            </Col>
          </Row>

          {this.state.selectedModuleName == "fullfilment" ||
            this.state.selectedModuleName == "breakfix" ||
            this.state.selectedModuleName == "investigation" ||
            this.state.selectedModuleName == "changeManagement" ||
            this.state.selectedModuleName == "task" ? (
            <Row className="margin-b-5">
              <Col xs={12}>
                <div aria-label="Table Filter" role="contentinfo" id="assFiltr">
                  {/* <div className='shw'>{this.props.translator['Show']}:</div> */}
                  <div className="filtr">
                    {this.restrictSubtypeAssignedToMe(
                      this.state.selectedModuleName
                    ) && (
                        <Button
                          className={
                            this.props.filterTabActive == 1 ? "active" : null
                          }
                          title={this.props.translator["Assigned to me"]}
                          bsPrefix=" "
                          onClick={() => {
                            this.onSubStatusSelect(1);
                          }}
                        >
                          {this.props.translator["Assigned to me"]}
                        </Button>
                      )}
                    {this.restrictSubtypeAssignMyGroup() == true && (
                      <Button
                        className={
                          this.props.filterTabActive == 2 ? "active" : null
                        }
                        title={this.props.translator["Assigned to my groups"]}
                        bsPrefix=" "
                        onClick={() => {
                          this.onSubStatusSelect(2);
                        }}
                      >
                        {this.props.translator["Assigned to my groups"]}
                      </Button>
                    )}
                    {this.restrictSubtypeAll() == true && (
                      <Button
                        className={
                          this.props.filterTabActive == 3 ? "active" : null
                        }
                        title={this.props.translator["All"]}
                        bsPrefix=" "
                        onClick={() => {
                          this.onSubStatusSelect(3);
                        }}
                      >
                        {this.props.translator["All"]}
                      </Button>
                    )}
                    { this.restrictSubtypeSLAView() ? 
                    ( <Button
                        className={
                          this.props.filterTabActive == 4 ? "active" : null
                        }
                        bsPrefix=" "
                        title={this.props.translator["SLA View"]}
                        onClick={() => {
                          this.setState({ slaListViewVal: true });
                          this.props.selectedFilterTab(4);
                          this.props.handleSelect("sla view");
                          this.props.slaDisableDateRange(true);
                          console.log(
                            "this.state.selectedModuleName",
                            this.state.selectedModuleName
                          );
                          this.props.getslaViewListAction(
                            10,
                            0,
                            this.state.selectedModuleName
                          );
                          this.props.setModule(this.state.selectedModuleName);
                          GLOBAL.slaHeadEnable = true;
                          this.props.showConditionBuilder(false);

                        }}
                      >
                       {this.props.translator["SLA View"]}
                      </Button>
                      ) : (
                        ""
                      )}
                    {/* {this.props.slaRole ? <Button
                        className={
                          this.props.filterTabActive == 4 ? "active" : null
                        }
                        bsPrefix=" "
                        title={'SLA View'}
                        onClick={() => {
                          this.setState({ slaListViewVal: true })
                          this.props.selectedFilterTab(4);
                          this.props.handleSelect("sla view");
                          this.props.slaDisableDateRange(true);
                          console.log("this.state.selectedModuleName", this.state.selectedModuleName);
                          this.props.getslaViewListAction(10, 0, this.state.selectedModuleName);
                          this.props.setModule(this.state.selectedModuleName)
                          GLOBAL.slaHeadEnable = true

                        }}>SLA View</Button> : ""} */}

                      {this.props.carouselIndex == 0 && <SavedQueryDropdown isAiTypeENV={this.props.isAiTypeENV} 
                                 showConditionBuilder={this.props.showConditionBuilder} onSubStatusSelect={this.onSubStatusSelect} 
                                 selectedFilterTab={this.props.selectedFilterTab} />}  

                      {this.props.slaDisableDateRangeState ? null : ( this.props.filterTabActive != 5 &&
                        <div className="dateRangeLabel">
                          <a
                            href="javascript:void(0)"
                            onClick={() =>
                              this.clickedOnHeaderText(this.state.selectedModuleName)
                            }
                          >
                            {" "}
                            <LuCalendarDays />
                            {this.state.selectedModuleName == "fullfilment"
                              ? this.props.fulfillDateRangeLabel
                              : null}
                            {this.state.selectedModuleName == "breakfix"
                              ? this.props.breakDateRangeLabel
                              : null}
                            {this.state.selectedModuleName == "investigation"
                              ? this.props.problemDateRangeLabel
                              : null}
                            {this.state.selectedModuleName == "changeManagement"
                              ? this.props.changeDateRangeLabel
                              : null}
                            {this.state.selectedModuleName == "task"
                              ? this.props.taskDateRangeLabel
                              : null}
                          </a>
                        </div>
                      )}
                  </div>
                </div>
                
              </Col>
            </Row>
          ) : null}

          {(this.state.selectedModuleName == "appointment" ||
            this.props.carouselIndex == "5") &&
            this.props?.appointmentAction != "console" ? (
            <Row className="margin-b-10">
              <Col xs={12}>
                <div aria-label="Table Filter" role="contentinfo" id="assFiltr">
                  <div className="filtr">
                    {
                      <Button
                        className={
                          this.props.filterTabActive == 1 ? "active" : null
                        }
                        title={this.props.translator["Assigned to me"]}
                        bsPrefix=" "
                        onClick={() => {
                          this.props.filterTabClick(
                            1,
                            this.state.selectedModuleName
                          );
                          this.props.showRightPanel(null, null);
                        }}
                      >
                        {this.props.translator["Assigned to me"]}
                      </Button>
                    }
                    {
                      <Button
                        className={
                          this.props.filterTabActive == 3 ? "active" : null
                        }
                        title={this.props.translator["All"]}
                        bsPrefix=" "
                        onClick={() => {
                          this.props.showRightPanel(null, null);
                          this.props.filterTabClick(
                            3,
                            this.state.selectedModuleName
                          );
                        }}
                      >
                        {this.props.translator["All"]}
                      </Button>
                    }
                    {this.props.filterTabActive != 5 && <div className="dateRangeLabel">
                  <a
                    href="javascript:void(0)"
                    onClick={() =>
                      this.clickedOnHeaderText(this.state.selectedModuleName)
                    }
                  >
                    {" "}
                    <LuCalendarDays />
                    {this.props.appointmentDateRangeLabel}
                  </a>
                </div>}
                  </div>
                </div>

              </Col>
            </Row>
          ) : null}

          {/* {this.state.selectedModuleName == "action" ? (
            <Row className="margin-b-10">
           
              <Col lg={12} md={12} sm={12} xs={12}>
                <div className="dateRangeLabel">
                  <a
                    href={void 0}
                    onClick={() =>
                      this.clickedOnHeaderText(this.state.selectedModuleName)
                    }
                  >
                    {" "}
                    <LuCalendarDays />
                    {this.props.aiDateRangeLabel}
                  </a>
                </div>
              </Col>
            </Row>
          ) : null} */}

          {this.props.isShowBuilder && (
            <Row className="margin-b-15">
              <Col lg={12} md={12} sm={12} xs={12}>
                <ConditionBuilder
                  moduleName={'incident'}
                  full_name={this.props.full_name}
                  user_id={this.props.user_id}
                  companyId={this.props.companyId}
                  showConditionBuilder={this.props.showConditionBuilder}
                  isAiTypeENV={this.props.isAiTypeENV}
                />
              </Col>
            </Row>
          )}
        </div>
      );
    }
  }
}

export function mapStateToProps(state) {
  return {
    carouselIndex: state.breakfixQuickViewTable.carouselIndex,
    moduleList: state.moduleList,
    quickViewDetails: state.quickViewDetails,
    fulFillmentList: state.fulFillmentList,
    myChangeManagementList: state.myChangeManagementList,
    investigationList: state.investigationList,
    myTaskDetailsListMetaData: state.myTaskDetailsList,
    slaViewListRed: state.slaViewListRed,
    appointmentCount: state.appointmentCount,
    actionCount: state.actionCount
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadModuleList,
      loadBreakFixQuickView,
      loadFulfillmentList,
      loadChangeManagementmList,
      loadInvestigationList,
      loadTaskList,
      setMultiSelectDropDownWithOk,
      setwibGlobalSearchFilterData,
      setWipSearchApply,
      getslaViewListAction,
      clearBreakfixData,
      resetQuery
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(XsmQuickViewDetailtHeader);
