
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export default function(state = [], action){
    switch (action.type){
        case 'FETCH_CARTCOUNT':
            return action.cartCount.data;
        default:
          return state;
    }
}
