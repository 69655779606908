
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { TypeaheadExampleSingleSelect, _inputField } from "../../../../common/formFields";
import { Field, change, reduxForm } from "redux-form";
import { getCostCenterCompanyOptions } from "../../../../../actions/foundation/costCenterAction";
import { getOrganizationOptions, resetOrganizationData } from "../../../../../actions/foundation/departmentAction";


const CreateForm = (props) => {
    const [selectedCompany, setSelectedCompany] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [orgList, setOrgList] = useState([])
  // const [typedCompany, setTypedCompany] = useState('')
    const [selectedOrganization, setSelectedOrgaization] = useState([]);
    const dispatch = useDispatch();
    const tr = useSelector((state) => state.spcmReducer.tr);
    const companyData = useSelector((state) => state.costCenterCompanyDropdown);
    const orgData = useSelector((state) => state.organizationList);

    useEffect(() => {
        dispatch(getCostCenterCompanyOptions());
    }, [])
    
    console.log("TestOrgData", orgData)
   

    useEffect(() =>
    {
      if(companyData && companyData.length > 0)
      {
        setCompanyList(companyData)
        }

    }, [companyData])

    useEffect(() =>
    {
      if(orgData && orgData.length > 0)
      {
        setOrgList(orgData)
        }

    }, [orgData])

    const onCompanySelection=(selectedCompany)=>{
        if (selectedCompany.length > 0) { 
            setSelectedCompany(selectedCompany)
            dispatch(change('CreateDepartment', 'company_name', selectedCompany[0].label))
            dispatch(change('CreateDepartment', 'company_id', selectedCompany[0].id))
            props.setCompanyValue(selectedCompany);
            dispatch(getOrganizationOptions(selectedCompany[0].id));
          } else {
            setSelectedCompany([]);
            dispatch(change('CreateDepartment', 'company_name', ''))
            dispatch(change('CreateDepartment', 'company_id', ''))
            dispatch(resetOrganizationData());
          }
    }

    const onCrossClickCompany = () => {
      setSelectedCompany([]);
      setSelectedOrgaization([]);
      setOrgList([])
      props.setCompanyValue([]);
      props.setOrgValue([]);
      dispatch(change('CreateDepartment', 'company_name', ''))
      dispatch(change('CreateDepartment', 'company_id', ''))
      dispatch(change('CreateDepartment', 'organization_name', ''))
      dispatch(change('CreateDepartment', 'organization_id', ''))
      // dispatch(resetOrganizationData());
  }
  const companyInputChange = () => {
    onCrossClickCompany();
  }
    const onOrgSelection = (selectedOrganization) => {
        if (selectedOrganization.length > 0) { 
            setSelectedOrgaization(selectedOrganization)
            dispatch(change('CreateDepartment', 'organization_name', selectedOrganization[0].label))
            dispatch(change('CreateDepartment', 'organization_id', selectedOrganization[0].id))
            props.setOrgValue(selectedOrganization);
          } else {
            setSelectedOrgaization([]);
            dispatch(change('CreateDepartment', 'organization_name', ''))
            dispatch(change('CreateDepartment', 'organization_id', ''))
          }
    }
    const onCrossClickOrganization = () => {
      setSelectedOrgaization([]);
      dispatch(change('CreateDepartment', 'organization_name', ''))
      dispatch(change('CreateDepartment', 'organization_id', ''))
      props.setOrgValue([]);
    }
    
    const organizationInputChange = (value) => {
      onCrossClickOrganization();
    }
    const  validateDepartmentFields = (fieldType, event)=> {
        switch (fieldType) {
          case "name":
            if (event.target.value == "") {
              props.setNameErr("error");
            } else {
              props.setNameErr("");
            }
            break;
          case "status":
            if (event.target.value == "") {
              props.setStatusErr("error");
            } else {
              props.setStatusErr("");
            }
            break;
          default:
        }
      }
    return (
        <Form>
        <Row>
            <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
            <span className="rStar"></span>
                    <Form.Label>{tr["Name"]}</Form.Label>
                    <Field
                        component={_inputField}
                        name="name"
                        className={"form-control "+(props.nameErr)}
                        maxLength={50}
                        onBlur={(event) => {
                            validateDepartmentFields("name", event);
                        }}
                    />
                </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
            <span className="rStar"></span>
                    <Form.Label>{tr["Status"]}</Form.Label>
                    <Field
                        name="status"
                        component="select"
                        className={"form-control "+(props.statusErr)}
                        onBlur={(event) => {
                            validateDepartmentFields("status", event);
                        }}
                    >
                    <option value="">{tr["Select"]}</option>
                    <option value="1">{tr["Active"]}</option>
                    <option value="0">{tr["Inactive"]}</option>
                    </Field>
                </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
            <span className="rStar"></span>
                    <Form.Label>{tr["Company"]}</Form.Label>
                    <Field
                     name="company_name"
                     className="form-control"
                     component={TypeaheadExampleSingleSelect}
                     onSelection={onCompanySelection}
                     options={companyList}
                     selectedOptions={selectedCompany}
                     onCrossClick={onCrossClickCompany}
                     errorClass={props.companyTypeErr}
                     setErrorColor={props.setCompanyErrorColor}
                     onInputChange={companyInputChange}
                />
                </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
            <span className="rStar"></span>
                    <Form.Label>{tr["Organization"]}</Form.Label>
                    <Field
                     name="organization_name"
                     className="form-control"
                     component={TypeaheadExampleSingleSelect}
                     onSelection={onOrgSelection}
                     options={orgList}
                     selectedOptions={selectedOrganization}
                     onCrossClick={onCrossClickOrganization}
                     errorClass={props.orgTypeErr}
                     setErrorColor={props.setOrgErrorColor}
                     onInputChange={organizationInputChange}
                    />
                </Form.Group>
            </Col>            
            </Row>
        </Form>
    )
}

export default reduxForm({
    form: 'CreateDepartment',
    destroyOnUnmount: false,
    enableReinitialize: true
})(CreateForm);