
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import { Link, NavLink } from "react-router-dom";
let sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
import _ from "lodash";
import { GLOBAL } from "_Globals";
import axios from "axios";
import { connect } from "react-redux";
import swal from "sweetalert";
import { loadWorkdayScheduleData } from "../../../../actions/sla/lovAction";
import { reduxForm } from "redux-form";
import { navigationHooks } from "../../../../helpers/NavigationHook";
import NavDropdown from "react-bootstrap/NavDropdown";
import Dropdown from 'react-bootstrap/Dropdown';
import {IoSaveOutline, IoClose} from "react-icons/io5";
import {LuSearch, LuBarChart, LuFileText, LuActivitySquare} from 'react-icons/lu';
import {TbCirclesRelation} from 'react-icons/tb';
import {BiDotsVertical} from 'react-icons/bi';
import Swal from "sweetalert2";
import moment from "moment";
import Cookies from "universal-cookie";
import { MyContext } from '_MyContext';

const cookies = new Cookies();

let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

let WorkScheduleHeader = class WorkScheduleHeader extends React.Component {
  static contextType = MyContext;
  constructor(props) {
    super(props);
    this.saveChange = this.saveChange.bind(this);
  }

  componentDidMount() {
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
    this.context.newTabPrevent();
	}
	componentDidUpdate() {
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
    this.context.newTabPrevent();
	}
  saveChange(values) {
    console.log("valuesvaluesEdit", values);
    if (typeof values.slaStartDate == "string") {
      values.slaStartDate = moment(values.slaStartDate, jwtdf)
    }
    if (typeof values.slaEndDate == "string") {
      values.slaEndDate = moment(values.slaEndDate, jwtdf)
    }
    const startDate = moment(values.slaStartDate);
    const startTime = moment(values.slaStartTime, 'HH:mm');
    const endDate = moment(values.slaEndDate); 
    const endTime = moment(values.slaEndTime, 'HH:mm'); 
    const combinedStart = startDate.set({
      hour: startTime.get('hour'),
      minute: startTime.get('minute'),
   });
    const combinedEnd = endDate.set({
      hour: endTime.get('hour'),
      minute: endTime.get('minute'),
    });
  
    var duration = moment.duration(combinedEnd.diff(combinedStart));
    var minutes = duration.asMinutes();
    if (values.is24by7 == false || values.is24by7 == undefined) {
      if ((values.slaStartDate == undefined || values.slaStartDate == '') && (values.slaEndDate == undefined || values.slaEndDate == '')
        && (values.slaStartTime == undefined || values.slaStartTime == '') && (values.slaEndTime == undefined || values.slaEndTime == '')) {
        Swal.fire(
          "Please select Schedule Association date and time"
        )
        return;
      } else {
        if ((values.slaStartDate == undefined || values.slaStartDate == '') && (values.slaEndDate == undefined || values.slaEndDate == '')) {
          Swal.fire(
            "Please select Start Date and End Date."
          )
          return;
        }
        else if ((values.slaStartTime == undefined || values.slaStartTime == '' || values.slaStartTime == "Invalid date")
          && (values.slaEndTime == undefined || values.slaEndTime == '' || values.slaEndTime == "Invalid date")) {
          Swal.fire(
            "Please select Start Time and End Time."
          )
          return;
        }
        else if (values.slaStartDate == undefined || values.slaStartDate == '') {
          Swal.fire(
            "Please select Start Date."
          )
          return;
        }
        else if (values.slaEndDate == undefined || values.slaEndDate == '') {
          Swal.fire(
            "Please select End Date."
          )
          return;
        }
        else if (values.slaStartTime == undefined || values.slaStartTime == '' || values.slaStartTime == "Invalid date") {
          Swal.fire(
            "Please select Start Time."
          )
          return;
        }
        else if (values.slaEndTime == undefined || values.slaEndTime == '' || values.slaEndTime == "Invalid date") {
          Swal.fire(
            "Please select End Time."
          )
          return;
        }
        else if (moment(values.slaStartDate).isAfter(moment(values.slaEndDate))) {
          Swal.fire(
            "Start Date cannot be greater than End Date."
          )
          return;
        }
        else if (moment(values.slaStartDate).isSame(moment(values.slaEndDate))
          && (moment(values.slaStartTime, "HH:mm").isAfter(moment(values.slaEndTime, "HH:mm")))) {
            Swal.fire(
              "Start Time cannot be greater than End Time."
            )
            return;
        }
        else if (moment(values.slaStartDate).isSame(moment(values.slaEndDate)) == false) {
          if (minutes >= 1440) {
            Swal.fire(
              "Time difference cannot exceed 24 hours"
            )
            return;
          }
        }
        else if ((moment(values.slaEndDate)).isAfter(moment(values.slaStartDate).add(1, "days"))) {
          Swal.fire(
            "Difference between Start date and End date cannot exceed 24 hours"
          )
          return;
        }
       else if (values.scheduleRepeatType == undefined || values.scheduleRepeatType == '') {
          Swal.fire(
            "Please select Repeat type."
          )
          return;
        }
      }
    } else {
      if ((values.slaStartDate == undefined || values.slaStartDate == '') && (values.slaEndDate == undefined || values.slaEndDate == '')) {
        Swal.fire(
          "Please select Schedule Association Dates"
        )
        return;
      }
      if (values.slaStartDate == undefined || values.slaStartDate == '') {
        Swal.fire(
          "Please select Start Date."
        )
        return;
      }
       if (values.slaEndDate == undefined || values.slaEndDate == '') {
        Swal.fire(
          "Please select End Date."
        )
        return;
       }
        if (moment(values.slaStartDate, "HH:mm").isSame(moment(values.slaEndDate, "HH:mm")) == false) {
          Swal.fire(
            "Start Date and End Date should be same if All Day Schedule is selected"
          )
          return;
        }
      if (values.scheduleRepeatType == undefined || values.scheduleRepeatType == '') {
        Swal.fire(
          "Please select Repeat type."
        )
        return;
      }
    }

    let allDaySchedule = (values.is24by7 == undefined || values.is24by7 == false) ? false : true;
    let ref = this;
    let scheduleTimings = [];
    Object.keys(values).forEach(function (key) {
      if (values[key] == "Invalid date") delete values[key];
    });

    let scheduleStartDate = values.slaStartDate.format(jwtdf);
    let scheduleStartTime = allDaySchedule == true ? "00:00:00" : values.slaStartTime;
    let scheduleEndDate =  values.slaEndDate.format(jwtdf);
    let scheduleEndTime = allDaySchedule == true ? "23:59:59" : values.slaEndTime;

    if (values.scheduleRepeatType == 'Specific') {
      if (values.daysSelected.SU == false && values.daysSelected.MO == false && values.daysSelected.TU == false &&
        values.daysSelected.WE == false && values.daysSelected.TH == false && values.daysSelected.FR == false && values.daysSelected.SA == false) {
        Swal.fire(
          "Please select atleast one day."
        )
        return;
      }
    }
      if (values.scheduleRepeatType == 'Specific') {
        if (values.daysSelected.SU == true) {
          let schedule = {
            day: "SUNDAY",
            startTime: scheduleStartTime,
            startDate: scheduleStartDate,
            endTime: scheduleEndTime,
            endDate: scheduleEndDate
          };
          scheduleTimings.push(schedule);
        }

        if (values.daysSelected.MO == true) {
          let schedule = {
            day: "MONDAY",
            startTime: scheduleStartTime,
            startDate: scheduleStartDate,
            endTime: scheduleEndTime,
            endDate: scheduleEndDate
          };
          scheduleTimings.push(schedule);
        }

        if (values.daysSelected.TU == true) {
          let schedule = {
            day: "TUESDAY",
            startTime: scheduleStartTime,
            startDate: scheduleStartDate,
            endTime: scheduleEndTime,
            endDate: scheduleEndDate
          };
          scheduleTimings.push(schedule);
        }

        if (values.daysSelected.WE == true) {
          let schedule = {
            day: "WEDNESDAY",
            startTime: scheduleStartTime,
            startDate: scheduleStartDate,
            endTime: scheduleEndTime,
            endDate: scheduleEndDate
          };
          scheduleTimings.push(schedule);
        }

        if (values.daysSelected.TH == true) {
          let schedule = {
            day: "THURSDAY",
            startTime: scheduleStartTime,
            startDate: scheduleStartDate,
            endTime: scheduleEndTime,
            endDate: scheduleEndDate
          };
          scheduleTimings.push(schedule);
        }

        if (values.daysSelected.FR == true) {
          let schedule = {
            day: "FRIDAY",
            startTime: scheduleStartTime,
            startDate: scheduleStartDate,
            endTime: scheduleEndTime,
            endDate: scheduleEndDate
          };
          scheduleTimings.push(schedule);
        }

        if (values.daysSelected.SA == true) {
          let schedule = {
            day: "SATURDAY",
            startTime: scheduleStartTime,
            startDate: scheduleStartDate,
            endTime: scheduleEndTime,
            endDate: scheduleEndDate
          };
          scheduleTimings.push(schedule);
        }
      } else {
        for (let days = 0; days <= 6; days++) {
          if (days == 0) {
            let schedule = {
              day: "SUNDAY",
              startTime: scheduleStartTime,
              startDate: scheduleStartDate,
              endTime: scheduleEndTime,
              endDate: scheduleEndDate
            };
            scheduleTimings.push(schedule);
          }
          if (days == 1) {
            let schedule = {
              day: "MONDAY",
              startTime: scheduleStartTime,
              startDate: scheduleStartDate,
              endTime: scheduleEndTime,
              endDate: scheduleEndDate
            };
            scheduleTimings.push(schedule);
          }
          if (days == 2) {
            let schedule = {
              day: "TUESDAY",
              startTime: scheduleStartTime,
              startDate: scheduleStartDate,
              endTime: scheduleEndTime,
              endDate: scheduleEndDate
            };
            scheduleTimings.push(schedule);
          }
          if (days == 3) {
            let schedule = {
              day: "WEDNESDAY",
              startTime: scheduleStartTime,
              startDate: scheduleStartDate,
              endTime: scheduleEndTime,
              endDate: scheduleEndDate
            };
            scheduleTimings.push(schedule);
          }
          if (days == 4) {
            let schedule = {
              day: "THURSDAY",
              startTime: scheduleStartTime,
              startDate: scheduleStartDate,
              endTime: scheduleEndTime,
              endDate: scheduleEndDate
            };
            scheduleTimings.push(schedule);
          }
          if (days == 5) {
            let schedule = {
              day: "FRIDAY",
              startTime: scheduleStartTime,
              startDate: scheduleStartDate,
              endTime: scheduleEndTime,
              endDate: scheduleEndDate
            };
            scheduleTimings.push(schedule);
          }
          if (days == 6) {
            let schedule = {
              day: "SATURDAY",
              startTime: scheduleStartTime,
              startDate: scheduleStartDate,
              endTime: scheduleEndTime,
              endDate: scheduleEndDate
            };
            scheduleTimings.push(schedule);
          }
        }
    }
    
  let selectedDaysString = "";
  if (values.daysSelected.SU == true) {
      selectedDaysString += "Sunday "
  }

  if (values.daysSelected.MO == true) {
    selectedDaysString += "Monday "
  }

  if (values.daysSelected.TU == true) {
    selectedDaysString += "Tuesday "
  }

  if (values.daysSelected.WE == true) {
    selectedDaysString += "Wednesday "
  }

  if (values.daysSelected.TH == true) {
    selectedDaysString += "Thursday "
  }

  if (values.daysSelected.FR == true) {
    selectedDaysString += "Friday "
  }

  if (values.daysSelected.SA == true) {
    selectedDaysString += "Saturday "
  }
    let auditRecord = `Start Date: ${moment(scheduleStartDate+" "+scheduleStartTime,jwtdf+" HH:mm").valueOf()} \nEnd Date : ${moment(scheduleEndDate+" "+scheduleEndTime,jwtdf+" HH:mm").valueOf()} \nRepeats : ${values.scheduleRepeatType} \nRepeats On : ${selectedDaysString}`
    
    axios
      .patch(
        GLOBAL.workdayScheduleUpdate +
          ref.props.slaWorkScheduleDetails.workdayScheduleDetails.scheduleId,
        {
          companyId: values.scheduleCompany,
          companyName: values.scheduleCompanyName,
          scheduleName: values.scheduleName,
          statusId: values.scheduleStatus,
          status: values.scheduleStatusName,
          createdBy: "",
          wdsAssociationData: auditRecord,
          workdayScheduleTimeZone: values.workdayScheduleTimeZone,
          workdayScheduleTimeZoneLabel:
            values.workdayScheduleTimeZone == "0"
              ? "GMT"
              : values.workdayScheduleTimeZoneLabel,
          timeZoneOffset: values.workdayScheduleTimeZone,
          createdByName: "",
          workdayScheduleList: scheduleTimings,
          timeZoneOrigin:
          values.workdayScheduleTimeZone == "0" ? "Requester" : "System",
          repeatsOn: values.scheduleRepeatType,
          allDaySchedule: allDaySchedule,
          /*  "modifiedBy":userDetails.user_name,
      "modifiedByName":"HUL Consumer" */
        }
      )
      .then((response) => {
        if (response.status == 200) {
          if (response.data.error != null) {
            swal(response.data.error);
          } else {
            navigationHooks.navigate("/slaWorkdaylist");
          }
        } else {
          console.log("error creating workday schedule");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;
    return (
      <Row className="margin-b-15">
        <Col lg={7} md={7} sm={6} xs={12}>
          <h1 bsClass="" className="sPageHeading1">
            {this.props.translator["Work Schedule"]}
          </h1>
        </Col>
        <Col lg={5} md={5} sm={6} xs={12}>
          <div className="status myStatus marginT10 margin-t-10">
            <Nav as="ul">
              <NavDropdown
                className="navdrp"
                eventKey={5}
                as={"li"}
                title={
                  <BiDotsVertical title="More"/>
                }
                id="extLnks"
              >
                <Dropdown.Item
                  bsPrefix=" "
                  title={this.props.translator["Related SLAs"]}
                  eventKey={4.1}
                  onClick={() => {
                    this.props.changeActionView("viewRelatedSls");
                  }}
                >
                  <TbCirclesRelation/>
                  {this.props.translator["Related SLAs"]}
                </Dropdown.Item>
              </NavDropdown>
              <Nav.Item
                as="li"
                title={this.props.translator["Save"]}
                eventKey={1}
                onClick={handleSubmit(this.saveChange)}
              >
                <Link to="javascript:void(0)" className="ctrlKeyPrevent" ref={(e) => (this.saveObjRef = e)}>
                  <IoSaveOutline/>
                </Link>
              </Nav.Item>

              <Nav.Item
                as="li"
                title={this.props.translator["Close"]}
                eventKey={2}
              >
                <NavLink to="/slaWorkdaylist" className="ctrlKeyPrevent" ref={e => this.closeButton = e}>
                 <IoClose/>
                </NavLink>
              </Nav.Item>
            </Nav>
          </div>
          <div className="status myStatusGap border-r margin-t-10 margin-r-10 padding-r-10">
            <Nav as={"ul"} bsClass="">
            <Nav.Item as="li" eventKey={2}>
                <a className={this.props.actionView == 'viewWorkScheduleTime' ? "active" : ""}
                  href={void(0)}
                  title={["Time Schedule"]}
                  onClick={() => {
                    this.props.changeActionView('viewWorkScheduleTime');
                  }}
                >
                  <LuFileText />
                </a>
              </Nav.Item>
              <Nav.Item eventKey={1} as="li">
                <a className={this.props.actionView == 'activityDetails' ? 'active' : ''}
                  href={void (0)}
                  title={["Activity Details"]}
                  onClick={() => {
                  this.props.changeActionView("activityDetails"); }}>
                  <LuActivitySquare /></a>
                </Nav.Item>
              <Nav.Item as="li" eventKey={4}>
                <a className={this.props.actionView == 'servicediagram' ? "active" : ""}
                  href={void(0)}
                  title={this.props.translator["Process Hierarchy"]}
                  onClick={() => {
                    this.props.changeActionView('servicediagram');
                  }}
                >
                  <LuSearch/><LuBarChart className='processBarIcn'/>
                </a>
              </Nav.Item>
            </Nav>
          </div>
        </Col>
      </Row>
    );
  }
};

WorkScheduleHeader = reduxForm({
  form: "workdayScheduleEditForm",
})(WorkScheduleHeader);

export default connect(null, null)(WorkScheduleHeader);
