
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState} from "react";
import { Row, Col, Button, Nav } from 'react-bootstrap';
import {useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { reduxForm } from "redux-form";
import { saveGroupAction } from "../../../../../actions/foundation/groupAction";

import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';

const HeaderSec = (props) => {

    const navigate = useNavigate();
    const tr = useSelector((state) => state.spcmReducer.tr);
    const dispatch = useDispatch();
    const context = useContext(MyContext);
    const closeButton = useRef(null);
    const saveObjRef = useRef(null);

	useEffect(() => {
		context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
        context.newTabPrevent();
	}, []);

    const submit = async (values) => {
        console.log("HeaderGrpCreate===", values);
        
        let individualAssignValue = values.IndivAssign ? values.IndivAssign : ""; 
        let autoAssignAlgo = values.autoAssignAlgo ? values.autoAssignAlgo : "";

        let isValid = true;
        if (!values.company_name|| values.company_name == "") {
            props.setCompanyErrorColor();
            isValid = false;
        } 
        if (values.groupTypeLabel == '50') {
            if (!values.forum_name || values.forum_name == "") {
            props.setForumErrorColor();
            isValid = false;
        }
        if (!values.category_name|| values.category_name == "") {
            props.setCategoryErrorColor();
            isValid = false;
            }
        }
        if ( !values.name || values.name == ''|| values.name.trim() == '') {
            props.setNameErr("error");
            isValid = false;
          } else {
            props.setNameErr("");
        }
        if ( !values.status || values.status == '') {
            props.setStatusErr("error");
            isValid = false;
          } else {
            props.setStatusErr("");
        }
        if (values.IndivAssign == "Yes") {
            if (!autoAssignAlgo || autoAssignAlgo == '') {
                props.setAutoAlgoErr("error");
                isValid = false;
            } else {
                props.setAutoAlgoErr("");
            }
        }
        if (isValid == true) {
        let postjson;
        postjson = {
                name: values.name.trim(),
                description: values.description,
                company: values.company_name,
                roles: null,
                active: values.status,
                activeArr: null,
                flagBatchUpdate: false,
                filterby: null,
                filterbytext: null,
                pagesize: 0,
                eventNameDefault: null,
                groupType: values.groupTypeLabel,
                groupTypeId: values.groupTypeLabel,
                forum: values.groupTypeLabel == '50'? values.forum_name: "",
                forumId: values.groupTypeLabel == '50'? values.forum_id : 0,
                category: values.groupTypeLabel == '50'? values.category_name : "",
                categoryId: values.groupTypeLabel == '50'? values.category_id : 0,
                group_function: null,
                businessFunctionId: null,
                managerId: null,
                supportGroup: null,
                emailAddress: values.email_address ? values.email_address.trim() : null,
                objectId: null,
                isexternal: values.isexternal,
                groupFunction: null
            };
            dispatch(saveGroupAction(postjson,individualAssignValue,autoAssignAlgo));
            
        }
    };
    
    let { handleSubmit, submitting } = props;
    return (
        <>
            <Row className="margin-b-15">
                <Col lg={4} md={6} sm={6} xs={5}>
                    <h1 bsClass="" className="sPageHeading1">{tr["Group"]}</h1>
                </Col>
                <Col lg={8} md={6} sm={6} xs={7}>
                    <div className="paHedFilter">
                    <div className="toprigLnk margin-t-0">
                        <Nav className="icnlnk" as="ul">
                        <Nav.Item as="li" eventKey={2} disabled={props.loader} title={tr['Save']}>
                                    <Link to="javascript:void(0)" onClick={handleSubmit(submit)} className="ctrlKeyPrevent" ref={saveObjRef}>
									<span>{props.loader ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/>}</span>
									</Link>
							</Nav.Item>
								<Nav.Item as="li" title={tr['Close']} eventKey={3} >
								<Link to="/group" ref={closeButton} className="ctrlKeyPrevent">
								<IoClose/>
								</Link></Nav.Item>
                            </Nav>
                        </div>
                        </div>
                </Col>
            </Row>
        </>
    )
}

export default reduxForm({
    form: "CreateGroup",
})(HeaderSec);