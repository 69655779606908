
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Row, Col, Form, Alert, ButtonToolbar, Button } from 'react-bootstrap';
import { _textArea, _dropDown, _inputField, _radio } from '../../common/formFields';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import axios from 'axios';
import { spcmLoader, setLeftEditId, getServiceDetailCounts, getEntitlementEntity, getConsumptionItems, getEntitlements } from '../../../actions/spcmActions';
import {ImSpinner6} from "react-icons/im";


const validate = values => {
	let errors = {};
	console.log("Values+++",values);
	if (!values.entitlement_for) {
		errors.entitlement_for = 'Select For';
	}
	if (values.entitledentity_id == "Component") {
		errors.entitledentity_id = 'Select Component';
	}
	if (!values.entitledentity_id) {
		errors.entitledentity_id = 'Select Component';
	}
	if (!values.entitlement_entity) {
		errors.entitlement_entity = 'Select Entitlement Entity';
	}
	if (!values.entitlement_type) {
		errors.entitlement_type = 'Enter Entitled Type';
	}
	if (!values.entitled_to_id) {
		errors.entitled_to_id = 'Enter Entitled To';
	}



	return errors;
}

class ComponentForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dropdown: 0,
			entitlement_entity: "",
			AssociatedToDropdown: ""
		}
		this.onChangeHandle = this.onChangeHandle.bind(this);
		this.onEntityChange = this.onEntityChange.bind(this);
		this.submitForm = this.submitForm.bind(this);
	}

	componentWillMount() {
		console.log(this.props.spcmReducer.editLeftService);
		if (this.props.initialValues.entitlement_for == "Component")
			this.setState({ dropdown: 1 });
		this.props.getConsumptionItems(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID, 'Type', 'optional');
		if (this.props.spcmReducer.editLeftService.entitlement_entity)
			this.props.getEntitlementEntity(this.props.spcmReducer.editLeftService.entitlement_entity.toLowerCase(), this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID);

		if (this.props.initialValues.entitlement_entity) {
			this.setState({ entitlement_entity: this.props.initialValues.entitlement_entity })
		}
		if (this.props.initialValues.entitlement_for) {
			this.setState({ AssociatedToDropdown: this.props.initialValues.entitlement_for });
		}

	}

	componentWillUnmount() {
		this.props.spcmLoader('attribute', 'success');
	}

	onEntityChange(e) {
		const { change } = this.props;
		change('entitled_to_id','');
		this.props.getEntitlementEntity(e.target.value.toLowerCase(), this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID);
		this.setState({ entitlement_entity: e.target.value });
	}

	submitForm(values) {
		if (this.props.spcmReducer.editLeftId > 0) {
			values.updatedby_name = "";
			values.updatedby = "";
		} else {
			values.createdby_name = "";
			values.created_by = "";
		}

		let formData = Object.assign({}, values);
		if (formData.entitlement_for == "Service")
			formData.entitledentity_id = this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID;
		this.props.spcmReducer.entitlement_entities.forEach(function (val) {
			if(formData.entitlement_entity == "EmployeeBand"){
				if (val.field1Key == formData.entitled_to_id) {
					formData.entitled_to = val.field1Key;
				}
			}
			else
			if (val.field1Value == formData.entitled_to_id) {
				formData.entitled_to = val.field1Key;
			}

		}, this);
		if (this.props.spcmReducer.editLeftId > 0) {
			this.props.spcmLoader('attribute', 'posting');
			return axios.patch("/service/api/patchEntitlements/" + this.props.spcmReducer.editLeftId, formData).then((response) => {
				this.props.getEntitlements(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
				this.props.componentFormFun4(false);
				this.props.spcmLoader('attribute', 'success');
			})
				.catch((error) => {

					this.props.spcmLoader('attribute', 'success');
					throw new SubmissionError({ _error: error.response.data[0].message });
				});
		}
		else {
			//return axios.post(constants.SERVER+constants.ENTITLEMENT_API_URL,formData).then((response) => {
			this.props.spcmLoader('attribute', 'posting');
			return axios.post("/service/api/saveEntitlements", formData).then((response) => {
				this.props.getEntitlements(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
				this.props.getServiceDetailCounts(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
				this.props.componentFormFun4(false);
				this.props.spcmLoader('attribute', 'success');
			})
				.catch((error) => {

					this.props.spcmLoader('attribute', 'success');
					throw new SubmissionError({ _error: error.response.data[0].message });
				});
		}
	}

	onChangeHandle(e) {
		console.log("eeee",e.target.value);
		const { change } = this.props
		if (e.target.value == "Service") {
			this.setState({ dropdown: 0, AssociatedToDropdown: "Service" });
			change('entitledentity_id', this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_NAME);
		}
		else if (e.target.value == "Component") {

			this.setState({ dropdown: 1, AssociatedToDropdown: "Component" });
			change('entitledentity_id',e.target.value);
		}

	}

	render() {
		const tr = this.props.tr;
		const { error, handleSubmit } = this.props;
		let components = this.props.spcmReducer.consumptionItems;
		let entitlementEntity = [{ value: 'organization', text: 'Organization' },
		{ value: 'department', text: 'Department' },
		{ value: 'location', text: 'Location' },
		{ value: 'group', text: 'Group' },{ value: 'role', text: 'Role' }];
		let entitlementType = [{ value: 'Order & View', text: 'Order & View' }];
		//,{ value: 'View Only', text: 'View Only' }];
		let entitledTo = this.props.spcmReducer.entitlement_entities;
		return (
			<div className="rBoxGap">
				<form onSubmit={handleSubmit(this.submitForm)}>
					{error == undefined || error == null ? null : <Alert variant="danger" className="errorMessageSec"><p>{error}</p></Alert>}
					<Row>
						<Col md={12}>
							<Form.Group className="radioInlineBox form-group">
								<Form.Label bsClass="" className="float-l margin-r-20"><span className="rStar"></span> {tr['For']}</Form.Label>
								<div className="radioInlineBox float-l">
									<Field component={_radio} className='form-control' type="radio" name='entitlement_for' value='Service' checked={this.state.AssociatedToDropdown == "Service"} onChange={this.onChangeHandle} />
									<Field component={_radio} className='form-control' type="radio" name='entitlement_for' value='Component' checked={this.state.AssociatedToDropdown == "Component"} onChange={this.onChangeHandle} />
								</div>
								{
									this.state.dropdown == 0
										? <Field component={_inputField} name="entitledentity_id" className='form-control' disabled={true} />
										: <Field component={_dropDown} name="entitledentity_id" options={components} className='form-control' />

								}
							</Form.Group>
						</Col>
						<Col md={12}>
							<Form.Group className='form-group'>
								<Form.Label bsClass=""><span className="rStar"></span> {tr['Entitled Type']}</Form.Label>
								<Field component={_dropDown} options={entitlementType} name="entitlement_type" className='form-control'>

								</Field>
							</Form.Group>
						</Col>
						<Col md={12}>
							<Form.Group className="radioInlineBox form-group">
								<Form.Label bsClass="" className="float-l margin-r-20"><span className="rStar"></span> {tr['For']}</Form.Label>
								<div className="radioInlineBox float-l multipleInlineRadios margin-b-10">
									<Field component={_radio} className='form-control' type="radio" name='entitlement_entity' checked={this.state.entitlement_entity == "Organization"} value='Organization' label="Organization" onChange={this.onEntityChange} />
									<Field component={_radio} className='form-control' type="radio" name='entitlement_entity' checked={this.state.entitlement_entity == "Department"} value='Department' label="Department" onChange={this.onEntityChange} />
									<Field component={_radio} className='form-control' type="radio" name='entitlement_entity' checked={this.state.entitlement_entity == "Location"} value='Location' label="Location" onChange={this.onEntityChange} />
									<Field component={_radio} className='form-control' type="radio" name='entitlement_entity' checked={this.state.entitlement_entity == "Group"} value='Group' label="Group" onChange={this.onEntityChange} />
									<Field component={_radio} className='form-control' type="radio" name='entitlement_entity' checked={this.state.entitlement_entity == "EmployeeBand"} value='EmployeeBand' label="Employee Band" onChange={this.onEntityChange} />
									<Field component={_radio} className='form-control' type="radio" name='entitlement_entity' checked={this.state.entitlement_entity == "Country"} value='Country' label="Country" onChange={this.onEntityChange} />						 
									<Field component={_radio} className='form-control' type="radio" name='entitlement_entity' checked={this.state.entitlement_entity == "Role"} value='Role' label="Role" onChange={this.onEntityChange} />						 	

								</div>

								<Field component={_dropDown} options={entitledTo} name="entitled_to_id" className='form-control'></Field>
								
							</Form.Group>
						</Col>

					</Row>
					<Row>
						<Col md={12}>
							<ButtonToolbar className="black"><Button className='rgSidrkBtn' title="Save" disabled={this.props.spcmReducer.spcmPostingAttribute} type="submit" bsSize="small" bsStyle="primary">{this.props.spcmReducer.spcmPostingAttribute ? <ImSpinner6 className="icn-spinner"/> : null}  {tr['Save']}</Button><Button className='rgSiLigBtn' title="Close" onClick={() => { this.props.componentFormFun4(false) }} bsSize="small" bsStyle="text">{tr['Close']}</Button></ButtonToolbar>
						</Col>
					</Row>
				</form>
			</div>
		);
	}
}

const mapStateToProps = ({ spcmReducer }) => {
	return {
		spcmReducer,
		initialValues: spcmReducer.editLeftId > 0 ? spcmReducer.editLeftService : ''
	}
};
ComponentForm = reduxForm({
	form: 'entitlementForm',
	validate,
	enableReinitialize: true
})(ComponentForm)

export default connect(mapStateToProps, { spcmLoader, setLeftEditId, getServiceDetailCounts, getEntitlementEntity, getConsumptionItems, getEntitlements })(ComponentForm);
