
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { getFieldsName, getTag } from "../../../studio/utils";
import { CustomDropdown, GetInputField } from "../common/builderFormFields";

const DependentFieldsWithDefaultValue = (props) => {
  const [valueObject, setValueObject] = useState({});
  const { defaultValues, item, onChange } = props;
  
  useEffect(() => {
    if (props?.components) {
      const fieldArray = getFieldsName(props?.components) || [];
      let fieldObject = {};
      if (fieldArray.length > 0) {
        fieldArray.forEach((element) => {
          fieldObject[element] = "";
        });
        setValueObject(fieldObject);
      }
    }
  }, [props?.components]);

  const onValueChange = (e, name) => {
    let value = {};
    if (defaultValues[item.valueBindKey]) {
      Object.keys(valueObject).forEach((res) => {
        value[res] = defaultValues[item.valueBindKey][res];
      });
    }
    if (name) {
      value[name] = e;
      onChange(value, { name: item.valueBindKey });
    }
  };

  const onDropDownValueChange = (e, name) => {
    let value = {};
    if (defaultValues[item.valueBindKey]) {
      Object.keys(valueObject).forEach((res) => {
        value[res] = "";
      });
    }
    if(name){
      value[name] = e;
      onChange(value, { name: item.valueBindKey });
    }
  };

  const renderComponent = (component, field) => {
    const { type, classes, components, text } = component;
    let className;
    if (Array.isArray(classes)) {
      className = classes.join(" ");
    } else {
      className = classes;
    }
    let attributes = {};
    if (component.attributes) {
      attributes = { ...component.attributes };
    }
    if (component.classes) {
      attributes.className = component.classes
        .map((c) => (c.name ? c.name : c))
        .join(" ");
    }
    if (component?.name && component.name == "Row") {
      attributes.className = attributes.className + "";
    }
    if (component?.name && component.name == "Cell") {
      attributes.className = attributes.className + "";
    }
    const children = components
      ? components.map((res) => renderComponent(res, field))
      : null;
    switch (type) {
      case "input":
        return (
          <GetInputField
            attributes={component?.attributes}
            language={props?.language}
            onChange={(e) =>
              onValueChange(e, component?.name || component?.attributes?.name)
            }
            defaultValues={defaultValues[item.valueBindKey] || {}}
            hiddenFIelds={props.hiddenFIelds}
            errors={props.errors}
            editData={props?.editData || {}}
          />
        );
      case "custom-dropdown": {
        return (
          <CustomDropdown
            props={component}
            language={props?.language}
            onChange={(e) =>
              onDropDownValueChange(
                e,
                component?.name || component?.attributes?.name
              )
            }
            defaultValues={defaultValues[item.valueBindKey] || {}}
            hiddenFIelds={props.hiddenFIelds}
            errors={props.errors}
            editData={props?.editData || {}}
          />
        );
      }
      case "textnode":
        return text || component?.content;
      default:
        return React.createElement(
          component.type ? getTag(component) : "div",
          { ...attributes },
          children
        );
    }
  };

  const renderByComponent = (json) => {
    return json.map((res) => renderComponent(res, props.item.valueBindKey));
  };

  return (
    <div>
      {props?.components &&
        props?.components.length > 0 &&
        renderByComponent(props?.components)}
    </div>
  );
};

export default DependentFieldsWithDefaultValue;
