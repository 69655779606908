
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from 'react';

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Alert from 'react-bootstrap/Alert';

import { useSelector } from 'react-redux';
import { _passwordField, _inputField, _dropDown, _checkbox } from '../../common/formFields';

import { Field, reduxForm } from 'redux-form';

const validate = (values) => {
  const errors = {};
  if (!values.SENARIO)
    errors.SENARIO = "Label Senario";

  if (!values.END_POINT || (values.END_POINT && values.END_POINT.trim() == "")) {
    errors.END_POINT = 'Enter End Point';
  }
  if (!values.COMPANY_NAME) {
    errors.COMPANY_NAME = "Label Company ID";
  }

  if (!values.USER_NAME || (values.USER_NAME && values.USER_NAME.trim() == "")){
    errors.USER_NAME = "Enter User Name";
  }
  if (!values.IDENS || (values.IDENS && values.IDENS.trim() == "")){
    errors.IDENS = "Enter Password";
  }
  if (!values.CRITERIA || (values.CRITERIA && values.CRITERIA.trim() == "")){
    errors.CRITERIA = "Enter Criteria";
  }
  return errors;
}
const requestMethodOption = [{value: 'POST', text: 'POST'}];
const authenticationOptions = [{value: 'Basic', text: 'Basic'}];

const CreateOutBoundConForm = () => {
  const [passwordFieldType, setPasswordFieldType] = useState('password');

  const translator = useSelector(state => state.spcmReducer.tr);
  const spcmReducer = useSelector(state => state.spcmReducer);
  const scenarioDropDownList = useSelector(state => state.getIntegrationHubData.scenarioDropDownList);
  const integrationHubSubmitError = useSelector(state => state.getIntegrationHubData.integrationHubSubmitError);

  let companyList = spcmReducer.companyList ? spcmReducer.companyList.map(item => {return {value: item.field1Value, text: item.field1Key}}) : [];
  const scenarioOption = scenarioDropDownList && scenarioDropDownList.length > 0 ?
  scenarioDropDownList.map (item => ({value: item.scenarioId, text: item.scenarioName})) : [];

  const countCharacters = (e) => {
    if (e.target.value.length > 100) {
      e.preventDefault();
    }
  }

  return (
    <div>
       { !integrationHubSubmitError ? 
       null : 
       <Alert variant="danger" className="errorMessageSec">
        <p>{translator['The form has missing required fields, please revalidate the highlighted fields and update the form !']}</p>
        </Alert>}
       
        <div className="borderForm bFormDiv">
          <form>
            <Row>
             <Col md={6}>
                <Form.Group>
                  <Form.Label bsClass=""><span className="rStar"></span>{translator['Company']}</Form.Label>
                  <Field component={_dropDown} name="COMPANY_NAME" options={companyList} className="form-control" disabled={spcmReducer.editService.status && spcmReducer.editService.status == 'Deployed' ? true : false} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label bsClass=""><span className="rStar"></span>{translator['Scenario']}</Form.Label>
                  <Field component={_dropDown} name="SENARIO" options={scenarioOption} className="form-control" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
            <Col md={6}>
                <Form.Group >
                  <Form.Label bsClass=""><span className="rStar"></span>{translator['End Point']}</Form.Label>
                  <Field name="END_POINT" className="form-control" component={_inputField} onpaste={(e) => { countCharacters(e) }} onChange={(e) => { countCharacters(e) }} >
                  </Field>
                  <span className="f-size-10">{translator['Max Length in CB']}</span>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label bsClass=""><span className="rStar"></span>{translator['Request Method']}</Form.Label>
                  <Field component={_dropDown} name="REQUEST_METHOD" options={requestMethodOption} className="form-control" disabled/>
                </Form.Group>
              </Col>
            </Row>
            <Row>
            <Col md={6}>
                <Form.Group >
                  <Form.Label bsClass=""><span className="rStar"></span>{translator['Headers']}</Form.Label>
                  <Field name="HEADER" className="form-control" component={_inputField} defaultValue="{Content-Type=application/json, Charset=UTF-8}"
                  value="{Content-Type=application/json, Charset=UTF-8}"
                  placeholder="{Content-Type=application/json, Charset=UTF-8}" disabled></Field>
                  <span className="f-size-10">{translator['Max Length in CB']}</span>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label bsClass=""><span className="rStar"></span>{translator['Authentication Method']}</Form.Label>
                  <Field component={_dropDown} name="AUTHENTICATION_NAME" options={authenticationOptions} className="form-control" disabled/>
                </Form.Group>
              </Col>
              
            </Row>
            <Row>
              
            <Col md={6}>
                <Form.Group >
                  <Form.Label bsClass=""><span className="rStar"></span>{translator['Username']}</Form.Label>
                  <Field name="USER_NAME" className="form-control" component={_inputField} onpaste={(e) => { countCharacters(e) }} onChange={(e) => { countCharacters(e) }} >
                  </Field>
                  <span className="f-size-10">{translator['Max Length in CB']}</span>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className='position-re eyepassdv'>
                  <Form.Label bsClass=""><span className="rStar"></span>{translator['Password']} <a role='button' title={passwordFieldType === 'password' ? "Show Password" : "Hide Password"} href="javascript:void(0)" onClick={() => {setPasswordFieldType(passwordFieldType === 'password' ? 'text' : 'password')}} style={{'position':'absolute','top':'28px','right':'6px','padding':'0px 5px 2px',zIndex:"1"}}><i className={passwordFieldType === 'password' ? "fa fa-eye" : "fa fa-eye-slash"} aria-hidden="true"></i></a>
                   </Form.Label>
                 { passwordFieldType === 'password' ? <Field name="IDENS" className="form-control" component={_passwordField} onpaste={(e) => { countCharacters(e) }} onChange={(e) => { countCharacters(e) }} >
                  </Field> :
                  <Field name="IDENS" className="form-control" component={_inputField} onpaste={(e) => { countCharacters(e) }} onChange={(e) => { countCharacters(e) }} >
                  </Field>}
                 <span className="f-size-10">{translator['Max Length in CB']}</span>
                </Form.Group>
              </Col>
            </Row>

            <Row>
            <Col md={6}>
                <Form.Group >
                  <Form.Label bsClass=""><span className="rStar"></span>{translator['Criteria']}</Form.Label>
                  <Field name="CRITERIA" className="form-control" component={_inputField} onpaste={(e) => { countCharacters(e) }} onChange={(e) => { countCharacters(e) }} >
                  </Field>
                  <span className="f-size-10">{translator['Max Length in CB']}</span>
                </Form.Group>
              </Col>
            </Row>
          </form>
        </div>
      </div>
  )
}

export default reduxForm({
  form: 'xsmIntegrationHubCreateForm',
  destroyOnUnmount: false,
  validate,
  enableReinitialize: true
})(CreateOutBoundConForm);