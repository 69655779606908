
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import ListLoader from '_Commons/loaders/ListLoader';
import TableComponent from '_Commons/ReactTable/TableComponent';
import { loadHamList } from '../../../actions/ham/hamAction';

const HamList = (props) => {


  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  const hamList = useSelector(
    (state) => state.hamList,
    console.log("state.hamList",hamList)
  );

//setList(hamList);
  
  const showLoader = useSelector((state) => state.showLoader.loading);
    const translator = useSelector((state) => state.spcmReducer.tr);

    const tableColumns = [
      {
        name: translator["Class Name"],
        selector: (row) => row.CLASS_NAME,
        sortable: true,
      },
      {
        name: translator["Class Type"],
        selector: (row) => row.CLASS_TYPE,
        sortable: true,
      },
      {
        name: translator["Company"],
        selector: (row) => row.COMPANY_NAME,
        sortable: true,
      },
      {
        name: translator["Active/Inactive"],
        selector: (row) => row.STATUS,
        sortable: true,
      },
      {
        name: translator["Created By"],
        selector: (row) => row.CREATED_BY,
        sortable: true,
      },
      // {
      //   name: translator["Created At"],
      //   selector: (row) => row.CREATED_AT,
      //   sortable: true,
      // },
    ];

    useEffect(() => {
      dispatch(loadHamList());    
    }, []);

    // useEffect(() => {
    // if(Array.isArray(hamList)){
    //   setList(hamList);
    // }
    // }, [hamList]);

    useEffect(() => {
      if(hamList.length>0){
        setList(hamList.map((obj)=> {
         return {
          ...obj, STATUS: obj.STATUS == 'active' ? 'Active' : 'Inactive',
         }
        }))
      }
          
        }, [hamList]);

        const onRowSelect = (row) => {
          console.log("Row ====>",row);
          props.getClassListDetails(
            row.CLASS_NAME,
            row.CLASS_TYPE,
            row.COMPANY_NAME,
            row.STATUS,
            row.CLASS_ID,
            row.CREATED_AT,
            row.CREATED_BY,
            row.UPDATED_AT,
            row.UPDATED_BY,
            row.COMPANY_ID,
            row.PARENT_CLASS_ID,
            row.CLASS_ID
          )
         
          props.showRightSide(true);
        };


    const filterColumns = ["CLASS_NAME", "CLASS_TYPE","COMPANY_NAME","STATUS","CREATED_BY","CREATED_AT","PARENT_CLASS_ID","CLASS_ID"];

    const renderHamListTable = () => (
      console.log("hamList====>",hamList),
      <div className="dtaTableDv integrateHubTable">
        <div className={props.rightDisplay ? 'leftTrue srchPosTop' : 'rightTrue srchPosTop'}>
          <TableComponent
            data={list}
            columns={tableColumns}
            headerColumns={filterColumns}
            onRowSelect={onRowSelect}
            rightDisplay={props.rightDisplay}
            uniqueKey={'CLASS_ID'}
          />
        </div>
      </div>
    );

  return (
    <div className='myOrderDiv'>
    {showLoader ? <ListLoader /> : renderHamListTable()}
</div>
  )
}

export default HamList
