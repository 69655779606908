
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/

export default function (state = [], action){

	switch (action.type){
		case 'LOAD_APPROVALHISTORY_DETAIL_SUCCESS':
				return action.payload;
		case 'LOAD_APPROVALHISTORY_DETAIL_FAILURE':
				return action.failureMessage;
		default:
				return state;
		}

}
