
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from './initialState';

export default function courseReducer(state = initialState.courses, action){
  switch (action.type) {
    case 'LOAD_COURSES_SUCCESS':
      return action.courses;

    case 'CREATE_COURSE_SUCCESS':
      return [...state, 
      		Object.assign({}, action.course)
      		];

    case 'UPDATE_COURSE_SUCCESS':
      return [...state.filter(course => {return course.id !== action.course.id}), 
      		Object.assign({}, action.course)
      		];

    default:
    return state;

  }
}
