
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { Component, PropTypes } from 'react';
import { Row, Col, FormGroup, FormControl, ControlLabel, Button, ListGroup, ListGroupItem } from 'react-bootstrap';

import AutoSuggest from 'react-autosuggest';
import GLOBAL from '../../components/Globals';
import axios from 'axios';
let selectOptions = [];

const api = axios.create({
  headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

const getSuggestions = (value, options) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  if (inputLength > 2) {
    selectOptions = options;
  }
  // return inputLength < 2 ? [] : selectOptions.filter(option => option.label.toLowerCase().indexOf(inputValue) > -1);
  return inputLength < 2 ? [] : selectOptions;
};

const getSuggestionValue = suggestion => suggestion.label.indexOf == -1 ? '' : suggestion.label.substring(0, suggestion.label.indexOf('|'));

function renderSuggestion(suggestion) {
  // console.log('suggestion====',suggestion);
  let suggestionlabel = [];
  return (
    <span onClick={() => {
      suggestionlabel = suggestion.label.split("|");
    }}>{suggestion.label}</span>
  );
}


class RFReactSelect extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.onUserSelected = this.onUserSelected.bind(this);
    this.state = {
      value: '',
      suggestions: []
    };
  }

  componentWillReceiveProps(nextProps) {
    // console.log('nextProps==', nextProps);
    if (nextProps.emptyAutosuggestValue != this.props.emptyAutosuggestValue && nextProps.emptyAutosuggestValue == true)
      this.setState({ value: '' });
  }

  onChange(event, { newValue }) {
    // console.log('onchange');
    // console.log({ newValue });
    this.setState({
      value: newValue
    });
    if (this.props.emptyAutosuggestValue == true)
      this.props.changeAutosuggestState(false);
  }
  
  // onSuggestionsFetchRequested ({value}){
  //   this.setState({
  //     suggestions: getSuggestions(value,this.props.options)
  //   });
  // }

  onSuggestionsFetchRequested({ value }) {
    // console.log('value', value);
    // if( !isNaN(parseInt(value, 10)) ){      
    let consumerData = [];
    let consumerDataDetails = [];
    let myObj = {};

    if (value.length > 2) {
      api.get('/api/user/userInfo/' + value).then((response) => {
        consumerData = response.data;
        // console.log("userData::====",consumerData);
        for (var i = 0; i < consumerData.length; i++) {
          myObj = { adhocApproverUserName: '', adhocApproverFullName: '', adhocApproverUserId: '', adhocApproverEmail: '', label: '' };
          myObj.adhocApproverUserName = consumerData[i].User_Name;
          myObj.adhocApproverFullName = consumerData[i].Full_Name;
          myObj.adhocApproverUserId = consumerData[i].user_id;
          myObj.adhocApproverEmail = consumerData[i].Email_Address;
          myObj.label = consumerData[i].Name + "|" + consumerData[i].user_id + "|" + consumerData[i].Email_Address + "|" + consumerData[i].Full_Name;
          consumerDataDetails.push(myObj);
        }
        // console.log('consumerDataDetails - ',consumerDataDetails);
        consumerDataDetails = Array.from(new Set(consumerDataDetails));
        this.setState({
          suggestions: getSuggestions(value, consumerDataDetails)
        });
      });
    } else {
      this.setState({
        suggestions: []
      });
    }
  }

  onSuggestionsClearRequested() {
    this.setState({
      suggestions: []
    });
    if (this.state.value == '') {
      // GLOBAL.breakFixConsumerID = "";
    }
  }
  
  // validateField(){
  //   if(GLOBAL.breakFixConsumerID==""){
  //       this.props.setErrorColor('error','consumer');
  //   }else{
  //       this.props.setErrorColor('','consumer');
  //   }
  // }

  onUserSelected(event, { suggestion }) {
    // console.log('suggestionselected',suggestion)
    this.props.onUserNameChange(suggestion);
  }

  render() {
    const { value, suggestions } = this.state;
    // console.log('this.props==', this.props);
    // console.log('autosuggest value==', value);
    const inputProps = {
      name: this.props.input.name,
      placeholder: this.props.tr["Enter name or Username"],
      className: 'form-control',
      value,
      onChange: this.onChange,
      // onBlur: this.validateField
    };
    return (
      <AutoSuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={this.onUserSelected}
      />
    );
  }
} export default RFReactSelect;
