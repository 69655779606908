
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import {Row, Col, Form, Button, Table, Alert} from 'react-bootstrap';
import { connect } from 'react-redux';
import { getServiceOrderChoices,setEditServiceDetails,setEditQuestionsCategory,setForm,getServiceDetailCounts,getQuestionsCategory} from '../../../actions/spcmActions';
import ComponentForm from './editForm.js';
import axios from 'axios';
import Pagination from "react-js-pagination";
import ListLoader from "_Commons/loaders/ListLoader";
import CopyQuestion from './CopyQuestion'
import Swal from 'sweetalert2';
import {IoSearch} from 'react-icons/io5';

class ListView extends React.Component {
    constructor(props){
        super(props);
        this.state={
            queryBy:'Question',
            sortBy:'Sequence',
            query:'',
            queryBy2:'Group',
            sortBy2:'Group',
            query2:'',
            deleteError:'',
            sortIcons: false ,
            sortIcons2: false ,
            class1: 'sort up',
            class2: 'sort',
            class3: 'sort',
            class4: 'sort',
            class5:'sort up',
            sortByTitle:'',
            pageSelected:1,
            pageSelected2:1,
            currentForm:'',
            pageSize:10,
            pageSize2:10
        }
        this.sortFun = this.sortFun.bind(this);
        this.sortFun2 = this.sortFun2.bind(this);
        this.onTextChange=this.onTextChange.bind(this);
        this.onPageChange=this.onPageChange.bind(this);
        this.onPageSizeChange=this.onPageSizeChange.bind(this);
        this.onSearchClick=this.onSearchClick.bind(this);
        this.queryBySelect=this.queryBySelect.bind(this);
        this.onDeleteClick=this.onDeleteClick.bind(this);
        this.onSort = this.onSort.bind(this);
        this.handleDismiss = this.handleDismiss.bind(this);
        this.sortHover = this.sortHover.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    handleKeyPress(myObj,e){

        if (e.key === 'Enter'){
            this.onSearchClick(myObj);
            if(e.preventDefault) e.preventDefault();
        }

    }

    handleDismiss() {
        this.setState({ deleteError: '' });
      }

    sortFun(myObj){
        this.setState({ sortIcons:!this.state.sortIcons});
        switch(myObj) {
            case 'class2':
                this.setState({ class2:(this.state.sortIcons ? 'sort up' : 'sort down'),sortBy:'Question'});
                this.setState({ class3:'sort'});
                this.setState({ class5:'sort'});
                this.props.getServiceOrderChoices(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy,this.state.query,'Question',this.state.sortIcons?'asc':'desc',this.state.pageSelected,this.state.pageSize);
            break;
            case 'class3':
                this.setState({ class2:'sort'});
                this.setState({ class3:(this.state.sortIcons ?  'sort up' : 'sort down'),sortBy:'Parent'});
                this.setState({ class5:'sort'});
                this.props.getServiceOrderChoices(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy,this.state.query,'Parent',this.state.sortIcons?'asc':'desc',this.state.pageSelected,this.state.pageSize);
            break;
            case 'class5':
            this.setState({ class2:'sort'});
            this.setState({ class3:'sort'});
            this.setState({ class5:(this.state.sortIcons ?  'sort up' : 'sort down'),sortBy:'Sequence'});
            this.props.getServiceOrderChoices(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy,this.state.query,'Sequence',this.state.sortIcons?'asc':'desc',this.state.pageSelected,this.state.pageSize);

            break;
        }

    };

    sortFun2(myObj){
        this.setState({ sortIcons2:!this.state.sortIcons2});
        switch(myObj) {
            case 'class1':
                this.setState({ class1:(this.state.sortIcons2 ? 'sort up' : 'sort down'),sortBy2:'Group'});
                this.setState({ class4: 'sort'});

                this.props.getQuestionsCategory(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy2,this.state.query2,'Group',this.state.sortIcons2?'asc':'desc',this.state.pageSelected2,this.state.pageSize2);

            break;
            case 'class4':
            this.setState({ class1: 'sort'});
            this.setState({ class4:(this.state.sortIcons2 ?  'sort up' : 'sort down'),sortBy2:'Sequence'});
            
            this.props.getQuestionsCategory(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy2,this.state.query2,'Sequence',this.state.sortIcons2?'asc':'desc',this.state.pageSelected2,this.state.pageSize2);
            
            break;
        }

    };

    onTextChange(myObj,e){
        switch(myObj){
            case 'questList' :
                this.setState({query:e.target.value});
                break;
            case 'groupList' :
                this.setState({query2:e.target.value});
                break

        }
        
    }

    onPageSizeChange(myObj,e){

        switch(myObj){
            case 'questList' :
                this.setState({pageSize:e.target.value});
                this.props.getServiceOrderChoices(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy,this.state.query,this.state.sortBy,this.state.sortIcons?'desc':'asc',1,e.target.value);
                break;
            case 'groupList' :
                this.setState({pageSize2:e.target.value});
                this.props.getQuestionsCategory(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy2,this.state.query2,this.state.sortBy2,this.state.sortIcons2?'desc':'asc',1,e.target.value);
                break

        }

    }

    onPageChange(myObj,e){
        switch(myObj){
            case 'questList' : 
                this.setState({pageSelected:e});
                this.props.getServiceOrderChoices(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy,this.state.query,this.state.sortBy,this.state.sortIcons?'desc':'asc',e,this.state.pageSize);
                break;
            case 'groupList' :
                this.setState({pageSelected2:e});
                this.props.getQuestionsCategory(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy2,this.state.query2,this.state.sortBy2,this.state.sortIcons2?'desc':'asc',e,this.state.pageSize2);
                break;

        }
    }

    onSearchClick(myObj){
        switch(myObj){
            case 'questList' : 
                this.props.getServiceOrderChoices(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy,this.state.query,this.state.sortBy,this.state.sortIcons2?'desc':'asc',1,this.state.pageSize);
                break;
            case 'groupList' :
                this.props.getQuestionsCategory(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy2,this.state.query2,this.state.sortBy2,this.state.sortIcons2?'desc':'asc',1,this.state.pageSize2);
                break;

        }
        
    }

    queryBySelect(myObj,e){
        switch(myObj){
            case 'questList' :
              this.setState({queryBy:e.target.value});
              break;
            case 'groupList' :
              this.setState({queryBy2:e.target.value});
              break;
        }
        
    }

    sortHover(e){
        let index = e.nativeEvent.target.selectedIndex;
        this.setState({sortByTitle:e.nativeEvent.target[index].text})
    }

    onDeleteClick(id){
        Swal.fire({
            title: "Are you sure you wish to delete this item?",
		    
            width: 550,
            padding: '15px',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
          }).then((result) => {
            if (result.isConfirmed) {
                {this.state.currentForm == 'Questions_Form' ?  
                
                axios.delete("/service/api/deleteOfferingQuestion/"+id).then((resp)=>{
                    this.props.getServiceOrderChoices(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
                    this.props.getServiceDetailCounts(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
                }).catch((error)=>{
                    this.setState({deleteError:error.response.data[0].message})
                })
                
                : 
                axios.delete("/service/api/deleteQuestionsCategory/"+id).then((resp)=>{
                    this.props.getQuestionsCategory(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
                    this.props.getServiceDetailCounts(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
                }).catch((error)=>{
                    this.setState({deleteError:error.response.data})
                })
                }
            } else if (result.isDenied) {

            }
          })

    }

    onSort(e){
        this.setState({sortBy:e.target.value});
        this.props.getServiceOrderChoices(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy,this.state.query,e.target.value)
    }
    componentWillMount(){
        this.props.setForm('serviceOrderChoicesForm');
       //  this.props.getServiceOrderChoices(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,'','',this.state.sortBy);
         this.props.getQuestionsCategory(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
     }

     componentWillReceiveProps(nextProps) {
     //   let metaData = nextProps.spcmReducer.requiredQuestionGroupMeta.rowCount;
        if ((nextProps.spcmReducer.questions_category) && (nextProps.spcmReducer.questions_category != this.props.spcmReducer.questions_category)) {
            if(nextProps.spcmReducer.requiredQuestionGroupMeta){
            if (this.state.currentForm == '') {
            if (nextProps.spcmReducer.requiredQuestionGroupMeta.rowCount == 0) {
              this.setState({ currentForm: 'Category_Form' });
            }
            else {
              this.setState({ currentForm: 'Questions_Form' });
              this.props.getServiceOrderChoices(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID, '', '', this.state.sortBy);
            }
          }
            }
        }
      }
      
     getListHandler(e){
        this.setState({currentForm:e});
        if(e == 'Questions_Form'){
            this.props.getServiceOrderChoices(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy,this.state.query,this.state.sortBy,this.state.sortIcons?'desc':'asc',this.state.pageSelected,this.state.pageSize2);
        } 
        else if(e == 'Category_Form'){
            this.props.getQuestionsCategory(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy2,this.state.query2,this.state.sortBy2,this.state.sortIcons2?'desc':'asc',this.state.pageSelected2,this.state.pageSize2);
        }
        
     }

   render() {

    const tr=this.props.tr;
     
    var meta=this.props.spcmReducer.requiredQuestionMeta;
    var meta2=this.props.spcmReducer.requiredQuestionGroupMeta;
       var serviceOrderChoices = this.props.spcmReducer.serviceOrderChoices;
       var questions_category = this.props.spcmReducer.questions_category;
       
      return (
            <div className="">

            {this.props.componentFormToggle3=="Copy_Question" ? <CopyQuestion tr={tr} componentFormFun4={this.props.componentFormFun3} formSelection={this.state.currentForm}/>: this.props.componentFormToggle3 ? <ComponentForm tr={tr} componentFormFun4={this.props.componentFormFun3} formSelection={this.state.currentForm} /> : <div className='rBoxGap'>
            {this.state.deleteError == '' ? null :  <Alert style={{"marginBottom":"15px","fontSize":"13px","padding":"8px 30px 8px 8px"}} variant="danger" onDismiss={this.handleDismiss}><h4>{this.state.deleteError}</h4></Alert>}

                <Row className="borderForm  row">
                    <Col xs={12}>
                    <Form.Group className="radioInlineBox">
                            <div className="radioInlineBox float-l">
                                <label className="radio-inline"><input  type="radio" name ='req_info_chk'  value='Category_Form' onChange={(event)=>{this.getListHandler(event.target.value)}} checked={this.state.currentForm == 'Category_Form'}/>Groups</label>
                                <label className="radio-inline"><input type="radio" name ='req_info_chk'  value='Questions_Form' onChange={(event)=>{this.getListHandler(event.target.value)}} checked={this.state.currentForm == 'Questions_Form'}/>Information Required</label>
                                
                             </div>
                         </Form.Group>
                    </Col>
                </Row>
                {this.state.currentForm == 'Questions_Form' ? 
                    <div><Row className="">
                    <Col lg={8} md={12} sm={8} xs={12}>
                        <div className="form-inline rightSideFields d-lg-flex d-md-block d-sm-flex d-block">

                            <Form.Select onMouseOver={this.sortHover}  title={this.state.sortByTitle}   className="myControl mySel mySelectWidth"  onChange={(e)=>{this.queryBySelect('questList',e)}}>
                                <option value="Question">Question Text</option>
                                <option value="Component">Component Name</option>
                                <option value="Category">Group</option>
                                
                            </Form.Select>
                            <div className='d-flex'>
                            <Form.Control onKeyPress={(e)=>{this.handleKeyPress('questList',e)}} className="myControl myInput margin-l-5 myInputWidth" type="text" value={this.state.query} onChange={(e)=>{this.onTextChange('questList',e)}} />
                            <Button type="submit" title="Search"  onClick={(e)=>{this.onSearchClick('questList',e)}}  className="myBtn"><IoSearch/></Button>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} md={12} sm={4} xs={12} className="text-r rightSideFields">

                        <Form.Label className="margin-r-5 display-inline-block widthAuto">{tr['Show']}</Form.Label>
                        <Form.Select className="display-inline-block widthAuto" onChange={(e)=>{this.onPageSizeChange('questList',e)}}>
                        <option value="10" selected={this.state.pageSize==10?true:false}>10</option>
                        <option value="25" selected={this.state.pageSize==25?true:false}>25</option>
                        <option value="50" selected={this.state.pageSize==50?true:false}>50</option>
                        </Form.Select>
                    </Col>

                </Row>

                {this.props.spcmReducer.spcmFetchingError == true  && this.props.spcmReducer.spcmFetchingModule == 'SERVICE_ORDER_CHOICES' && this.props.spcmReducer.spcmErrorMsg ?  <Alert variant="danger" className="errorMessageSec margin-t-20"><p>{this.props.spcmReducer.spcmErrorMsg}</p></Alert> :  this.props.spcmReducer.spcmFetchingError == true  && this.props.spcmReducer.spcmFetchingModule == 'SERVICE_ORDER_CHOICES' && !this.props.spcmReducer.spcmErrorMsg ? <p>Oops! Something went wrong.</p> : null}
                
                <div className="f-size-12">
                    <Table bordered condensed hover responsive className="tableView sortImg tableWithOutPointer">
                    <thead>
                      <tr>
                        <th width="45%" className={this.state.class2}  onClick={()=>{this.sortFun('class2')}}>
                            <span>{tr['Question']}</span>, <span>{tr['Component']}</span>, <span>{tr['Group']}</span>
                        </th>
                        <th width="10%" className={this.state.class5} onClick={()=>{this.sortFun('class5')}}>{tr['Seq']}</th>
                        <th width="15%" title="Dependent" className={this.state.class3} onClick={()=>{this.sortFun('class3')}}>{tr['Dep ?']}</th>
                        <th width="10%" className="text-c">{tr['Action']}</th>
                      </tr>
                    </thead>
                    {this.props.spcmReducer.spcmFetchingAttribute  ? 

                    <tbody><td colSpan="6">
                        <ListLoader />
                    </td></tbody> : 
                    <tbody>
                        {serviceOrderChoices.map(function(value,index){
                            return (<tr key={index}>
                        <td>
                            <div className="f-size-13">{value.QUESTION_TEXT}</div>
                            <div style={{'color':'#8a8a8a'}}>{value.COMPONENT_NAME}</div>
                            <div className="f-size-11">{value.CATEGORY_NAME}</div>
                        </td>
                        <td>{value.QUESTION_SEQUENCE}</td>
                        <td>{value.QUESTION_PARENT_ID==0?<div title="Root">No</div>:<div title="Dependent">Yes</div>}</td>
                        <td><div className="text-c"><i title={tr["Copy"]} className="fa fa-clipboard cursorPoint" onClick={()=>{this.props.setEditServiceDetails(value);this.props.componentFormFun3("Copy_Question",'Edit',value.QUESTION_ID)}}></i><i title={tr["Edit"]} onClick={()=>{this.props.setEditServiceDetails(value);this.props.componentFormFun3(true,'Edit',value.QUESTION_ID)}} className="margin-l-5 fa fa-pencil-square-o cursorPoint"></i>
                        <i title={tr["Delete"]} className="margin-l-5 fa fa-trash-o cursorPoint" onClick={()=>{this.onDeleteClick(value.QUESTION_ID)}}></i></div></td>
                      </tr>)
                        },this)}
                    </tbody>
                    }
                    </Table>
                </div>

                <div className="nBotPagina">
                <nav aria-label="Pagination" className="display-inline-block">
                    {/* <Pagination className="margin-0" prev next boundaryLinks items={meta?meta.totalPageCount:1} maxButtons={3} activePage={meta?meta.currentPage:1}  onSelect={this.onPageChange}/> */}

                    {meta ? <Pagination  
                        activePage={meta ? meta.currentPage : 1}    
                        itemsCountPerPage={this.state.pageSize}
                        totalItemsCount={meta ? meta.rowCount : 1}
                        onChange={(e)=>{this.onPageChange('questList',e)}}
                    /> : null }
                </nav></div></div> 

                  :  <div>
                      <Row className="">
                    <Col md={8} sm={8} xs={12}>
                        <div className="form-inline rightSideFields d-lg-flex d-md-block d-sm-flex d-block">
                            <Form.Select onMouseOver={this.sortHover}  title={this.state.sortByTitle}   className="myControl mySel mySelectWidth" componentClass="select" onChange={(e)=>{this.queryBySelect('groupList',e)}}>
                                <option value="Group">Group</option>
                                <option value="Sequence">Sequence</option>              
                            </Form.Select>
                            <div className='d-flex'>
                            <Form.Control onKeyPress={(e)=>{this.handleKeyPress('groupList',e)}}  className="myControl myInput margin-l-5 myInputWidth" type="text" value={this.state.query2} onChange={(e)=>{this.onTextChange('groupList',e)}}/>
                            <Button type="submit" title="Search" onClick={(e)=>{this.onSearchClick('groupList',e)}} className="myBtn"><IoSearch/></Button>
                            </div>
                        </div>
                    </Col>
                    <Col md={4} sm={4} xs={12} className="text-r rightSideFields">

                        <Form.Label className="margin-r-5 display-inline-block widthAuto">{tr['Show']}</Form.Label>
                        <Form.Select className="padding-5 display-inline-block widthAuto" componentClass="select"  onChange={(e)=>{this.onPageSizeChange('groupList',e)}}>
                        <option value="10" selected={this.state.pageSize2==10?true:false}>10</option>
                        <option value="25" selected={this.state.pageSize2==25?true:false}>25</option>
                        <option value="50" selected={this.state.pageSize2==50?true:false}>50</option>
                        </Form.Select>
                    </Col>

                </Row>
                <div className="f-size-12">
                  <Table bordered condensed hover responsive className="tableView sortImg tableWithOutPointer">
                  <thead>
                    <tr>
                      
                      <th width="37%" onClick={()=>{this.sortFun2('class1')}} className={this.state.class1}>{tr['Group']}</th>
                      
                      <th width="10%" onClick={()=>{this.sortFun2('class4')}} className={this.state.class4}>{tr['Sequence']}</th>
                      <th width="18%" >{tr['Status']}</th>
                      <th width="10%" className="text-c">{tr['Action']}</th>
                    </tr>
                  </thead>
                  {this.props.spcmReducer.spcmFetchingAttribute  ? 

                  <tbody><td colSpan="4">
                      <ListLoader />
                  </td></tbody> : 
                  <tbody>
                      {questions_category.map(function(value,index){
                          
                          return (
                            value.message ? <tr><td colSpan={4} className='text-c'>{value.message}</td></tr> : 
                          <tr key={index}>
                      <td>{value.CATEGORY_NAME}</td>
                      <td>{value.CATEGORY_SEQUENCE}</td>
                      <td>{value.CATEGORY_STATUS}</td>
                      <td><div className="text-c"><i title={tr["Edit"]} onClick={()=>{this.props.setEditQuestionsCategory(value);this.props.componentFormFun3(true,'Edit',value.CATEGORY_ID)}} className="fa fa-pencil-square-o cursorPoint"></i>
                      <i title={tr["Delete"]} className="margin-l-10 fa fa-trash-o cursorPoint" onClick={()=>{this.onDeleteClick(value.CATEGORY_ID)}}></i></div></td>
                    </tr>)
                      },this)}
                  </tbody>
                  }
                  </Table>
              </div>
              <div className="nBotPagina">
                <nav aria-label="Pagination" className="display-inline-block">

                    {meta2 ? <Pagination
                        activePage={meta2 ? meta2.currentPage : 1}
                        itemsCountPerPage={this.state.pageSize2}
                        totalItemsCount={meta2 ? meta2.rowCount : 1}
                        onChange={(e)=>{this.onPageChange('groupList',e)}}
                    /> : null }
                </nav>
                </div>
              </div>}
                
                
                </div>
                }
            </div>
        );
    }
}
const mapStateToProps = ({spcmReducer}) => {
    return {
        spcmReducer
    }
};

export default connect(mapStateToProps,{ getServiceOrderChoices,setEditServiceDetails,setForm,getServiceDetailCounts,getQuestionsCategory,setEditQuestionsCategory })(ListView);

