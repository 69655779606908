
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { memo, useRef, useState, useEffect } from "react";
import moment from "moment";
import { Row, Col } from "react-bootstrap";
import { getCookiesValuesByPositions, filterURL } from "../Helper";
import { EventCalendar } from "./meeting-calendar";
import ConsoleRightSide from "./rightSide";

import {
  useGetMeetingOccurrencesByMonthQuery,
  useGetOccurenceByIdQuery,
  useGetMinutesDetailsQuery,
} from "../../../reducers/governance/meeting/meeting";

const GovernanceConsole = memo(() => {
  const cookieValues = getCookiesValuesByPositions([2, 48, 59]);
  const dateformat = cookieValues?.value1;
  const userCompanyId = cookieValues?.value2;
  const userCompanyName = cookieValues?.value3;

  const filtersDefaultData = {
    forumName: "",
    forumId: "",
    tenantId: "",
    companyName: "",
    sortedBy: "asc",
    eventsSortedBy: "",
    month: moment().format("MM"),
    year: moment().year(),
    date: "",
    category: "all",
    statusId: "",
    committeeName: "all",
    committeeId: "",
    occurrenceId: "",
    eventTitle: "",
    userAuthorized: false,
    definationId: "",
  };

  const [filtersData, setFiltersData] = useState(filtersDefaultData);

  const [selected, setSelected] = useState(moment().format("DD-MM-YYYY"));

  const handleDateSelect = async (val) => {
    const filterDate = moment(val, dateformat).format("DD-MM-YYYY");
    setSelected(filterDate);

    setFiltersData((prev) => {
      return {
        ...prev,
        date: filterDate,
      };
    });
  };

  const { data: allEvenstData, isFetching: isEventsFetching } =
    useGetMeetingOccurrencesByMonthQuery(
      {
        queryString: filterURL({
          ...filtersData,
          date: "",
        }),
      },
      {
        skip: !(filtersData?.tenantId && filtersData?.statusId && filtersData?.category && filtersData?.forumId),
      }
    );

  const { data: allEventByDate, isFetching: isFetchingEvenstByDate } =
    useGetMeetingOccurrencesByMonthQuery(
      {
        queryString: filterURL({
          ...filtersData,
          month: "",
          year: "",
          date: selected,
        }),
      },
      {
        skip: !(filtersData?.tenantId && filtersData?.statusId),
      }
    );

  const { data: occurrenceDetaildata, isFetching: occurrencesDetailFetching } =
    useGetOccurenceByIdQuery(
      { Id: filtersData?.occurrenceId ? filtersData?.occurrenceId : allEventByDate?.[0]?.occuranceId},
      {
        skip: !allEventByDate?.[0]?.occuranceId,
      }
    );

   

    useEffect(()=> {
      setFiltersData((prev) => {
        return {
          ...prev,
          ["occurrenceId"]: allEventByDate?.[0]?.occuranceId || '',
          ["definationId"]: allEventByDate?.[0]?.definationId || '',
          ["eventTitle"]: allEventByDate?.[0]?.title || '',
          ["userAuthorized"]: Boolean(allEventByDate?.[0]?.userAuthorized),
        };
      });
    },[]);

  return (
    <div className="mt-2">
      <Row>
        <Col md={filtersData.eventTitle!==''?8:null} xs={12}>
          <EventCalendar
            handleDateSelect={handleDateSelect}
            companyId={userCompanyId}
            userCompanyName={userCompanyName}
            filtersData={filtersData}
            setFiltersData={setFiltersData}
            allEvenstData={allEvenstData || []}
            dateformat={dateformat}
            allEventByDate={allEventByDate}
          />
        </Col>
        {filtersData.eventTitle!==''?
        <Col md={4} xs={12}>
          <ConsoleRightSide
            companyId={userCompanyId}
            dateformat={dateformat}
            userCompanyName={userCompanyName}
            filtersData={filtersData}
            setFiltersData={setFiltersData}
            occurrenceDetaildata={occurrenceDetaildata}
            occurrencesDetailFetching={occurrencesDetailFetching}
          />
        </Col>
        :null}
      </Row>
    </div>
  );
});

export default GovernanceConsole;
