
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import { TypeaheadandDropdown, _number } from "../../common/formFields";
import {
  _inputField,
  _dropDown,
  _checkbox,
} from "../../common/ReduxFormFields/CategoryBoard/formFields";

import { validateEmail } from "../../../utils/common";
import Cookies from "universal-cookie";
import axios from "axios";
import { useParams } from "react-router";
const cookies = new Cookies();
const gph = cookies.get("gph").split("~");
const companyId = typeof gph[48] !== "undefined" ? gph[48] : "";

function validate(values) {
  const cronRegex = new RegExp(
    /^((((\d+,)+\d+|(\d+(\/|-|#)\d+)|\d+L?|\*(\/\d+)?|L(-\d+)?|\?|[A-Z]{3}(-[A-Z]{3})?) ?){5,7})$|(@(annually|yearly|monthly|weekly|daily|hourly|reboot))|(@every (\d+(ns|us|µs|ms|s|m|h))+)/
  );
  const errors = {};

  if (!values.cronExpression || !cronRegex.test(values.cronExpression))
    errors.cronExpression = "Required";

  if (!values.escalationLevel) errors.escalationLevel = "Required";

  return errors;
}

const SchedulerEditForm = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const translator = useSelector((state) => state.spcmReducer.tr);

  const [statusOptions, setStatusOptions] = useState([
    { field1Value: "ACQUIRED", field1Key: "Acquired" },
    { field1Value: "BLOCKED", field1Key: "Blocked" },
    { field1Value: "PAUSED", field1Key: "Paused" },
    { field1Value: "WAITING", field1Key: "Waiting" },
  ]);

  useEffect(() => {
    if (props?.detail && typeof props.detail == "object") {
      dispatch(change("SchedulerEditForm", "jobName", props.detail?.jobName));
      dispatch(
        change(
          "SchedulerEditForm",
          "cronExpression",
          props.detail?.cronExpression
        )
      );
      dispatch(
        change("SchedulerEditForm", "escalationLevel", props.detail?.jobStatus)
      );
      if (props.detail?.actionStatus == "ACTIVE") {
        props.setActiveChecked(true);
      } else {
        props.setActiveChecked(false);
      }
    }
  }, [props.detail]);
  //// save form

  const onChange = (e) => {
    if (e.target.name == "active") {
      e.target.checked
        ? props.setActiveChecked(true)
        : props.setActiveChecked(false);
    }
  };

  return (
    <>
      <Row>
        <Col md={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label bsClass="">
              <span className="rStar"></span>
              {translator["Scheduler Name"]}
            </Form.Label>
            <Field
              component={_inputField}
              name="jobName"
              disabled
              className="form-control"
            />
          </Form.Group>
        </Col>
        <Col md={6} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label bsClass="">
              <span className="rStar"></span>
              {translator["Status"]}
            </Form.Label>
            <Field
              component={_dropDown}
              name="escalationLevel"
              options={statusOptions}
              className="form-control"
            />
          </Form.Group>
        </Col>
        <Col md={6} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>
              <span className="rStar"></span>
              {translator["Frequency"]}
            </Form.Label>
            <div className="heightdvTypehd">
              <Field
                component={_inputField}
                name="cronExpression"
                className="form-control"
              ></Field>
            </div>
          </Form.Group>
        </Col>
        <Col md={6} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>{translator["Active"]}</Form.Label>
            <div className="heightdvTypehd">
              <Field
                component={_checkbox}
                name="active"
                checked={props.activeChecked}
                onChange={onChange}
              ></Field>
            </div>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};
const mapStateToProps = (state) => ({
  // shiftListData: state.shiftListData
});
export default reduxForm({
  form: "SchedulerEditForm",
  destroyOnUnmount: true,
  validate,
  enableReinitialize: true,
})(connect(mapStateToProps)(SchedulerEditForm));
