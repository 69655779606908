
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import {Row,Col,Table,Button} from 'react-bootstrap';

import TextFieldForm1 from './formField1.js';
import TextFieldForm2 from './formField2.js';

class InsideTable extends React.Component {
	whichFormOpen(value){
		switch(value){
			case 'Form1':
				return <TextFieldForm1 insideTableShowFun6={this.props.insideTableShowFun5} fieldName1={this.props.fieldName} fieldNameVal3={this.props.fieldNameVal2} lanNameVal3={this.props.lanNameVal2} />;
			case 'Form2':
				return <TextFieldForm2 insideTableShowFun6={this.props.insideTableShowFun5} fieldName1={this.props.fieldName} fieldNameVal3={this.props.fieldNameVal2} lanNameVal3={this.props.lanNameVal2} />;
		}
	}
	render(){
		return(
			<div>
				{this.props.isInsideTableShow5 ? <div>{this.whichFormOpen(this.props.whichInsideFormOpen5)}</div> :
				<div><div className="rPageHeading">Translate - {this.props.fieldNameVal2} - {this.props.lanNameVal2} </div>
				
				<div className='rBoxGap'>
				<Row className="margin-b-5">
					<Col md={9}><div className="f-size-18 colorStOrange">{this.props.fieldNameVal2} List</div></Col>
					<Col md={3} className="text-r"><Button size="sm" className="backtolst" onClick={()=>{this.props.lanEditPageFun5(false,'Form2')}}><i className="fa fa-arrow-left margin-r-5"></i> Back to List</Button></Col>
				</Row>

				
                <div className="radioVarBox"><label><input type="radio" name="orderingInfo" defaultChecked  />Group</label><label><input type="radio" name="orderingInfo"  />Information Required</label></div>
				<Table className="langTable" striped bordered responsive>
					<thead>
					<tr>
						<th width="20%">Group</th>
						<th width="30%">Sequence</th>
						<th width="15%">Translated Group</th>
						<th width="5%"></th>
					</tr>
					</thead>
					<tbody>
					<tr>
						<td>General Information</td>
						<td>1</td>
						<td></td>
						<td><a onClick={()=>{this.props.insideTableShowFun5(true,'Form1')}} title="Edit" href="javascript:void(0)"><i className="fa fa-pencil-square-o"></i></a></td>
					</tr>
					<tr>
						<td>Configurations</td>
						<td>2</td>
						<td></td>
						<td><a onClick={()=>{this.props.insideTableShowFun5(true,'Form1')}} title="Edit" href="javascript:void(0)"><i className="fa fa-pencil-square-o"></i></a></td>
					</tr>																													
					</tbody>
				</Table>
				</div>
				</div>
				}
			</div>
		);
	}
}

export default InsideTable;