
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { Component, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import AutoSuggest from "react-autosuggest";
import { GLOBAL } from "_Globals";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

const IncidentServiceTypeAhead = (props) => {
  const [value, setValue] = useState("");
  const [suggestions, setSuggestion] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const dispatch = useDispatch();

  const usersList = useSelector((state) => state.spcmReducer.companyUsersList);

  useEffect(() => {
    if (usersList && usersList.length > 0) {
      usersList.map((user) => {
        let userinformation = user.userinformation.split("|");
        let nameArr = userinformation[0].split(" ");
        let name = "";
        let email = userinformation[1].toLowerCase();
        nameArr.map((word, i) => {
          nameArr[i] = word.charAt(0) + word.slice(1).toLowerCase();
        });
        name = nameArr.join(" ");
        user.name = name.length > 30 ? name.slice(0, 34) + "..." : name;
        let bottomText = email + " - " + props.companyName;
        user.bottomText =
          bottomText.length > 33 ? bottomText.slice(0, 33) + "..." : bottomText;
      });
      setSuggestion(usersList);
    }
  }, [usersList]);

    useEffect(() => {
        if (props.companyId !== '') {
            const companyId = parseInt(props.companyId, 10);
    let str = {},
      impactedServiceData = [];
    str.companyId = companyId;
    str = JSON.stringify(str);
    axios
      .get("/mailBoxConfig/offeringIncidentData/"+ companyId, {
        headers: { query: str },
      })
      .then((response) => {
        impactedServiceData = response.data;
        // props.setServiceList(impactedServiceData);
        let serviceList = [];
        impactedServiceData.map((subItem) => {
          serviceList.push({
            value: subItem.OFFERING_ID,
            label:
              subItem.OFFERING_NAME +
              "|" +
              subItem.CATEGORY_NAME +
              "|" +
              subItem.SUB_CATEGORY_NAME +
              "|" +
              subItem.CATEGORY_NAME ,
            companyname: subItem.COMPANY_NAME,
            categoryid: subItem.CATEGORY_ID,
            categoryname: subItem.CATEGORY_NAME,
            offeringName: subItem.OFFERING_NAME,
            subCategoryname: subItem.SUB_CATEGORY_NAME,
            consumercompanyname: subItem.COMPANY_NAME,
            offeringId: subItem.OFFERING_ID,
            isExternallyFulfilled: subItem.EXTERNALLY_FULFILLED
          });
        });
        setServiceList( serviceList );
      });
        }
    }, [props.companyId])
    


    const getSuggestionValue = (suggestion) =>
    suggestion.label.indexOf == -1
      ? ""
      : suggestion.label.substring(0, suggestion.label.indexOf("|"));

      function renderSuggestion(suggestion) {
      
        let offeringNameKey = "";
        let offeringNameStr = "";
        offeringNameStr =
          suggestion.offeringName != null && suggestion.offeringName != ""
            ? suggestion.offeringName
            : "";
        offeringNameKey =
          offeringNameStr != ""
            ? offeringNameStr
                .replace(
                  /(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/gi,
                  ""
                )
                .trim()
            : "";
        offeringNameKey =
          offeringNameKey != "" ? offeringNameKey.replace(/\s+/g, " ") : "";
      
        let categoryNamekey = "";
        let categoryNameStr = "";
        categoryNameStr =
          suggestion.categoryname != null && suggestion.categoryname != ""
            ? suggestion.categoryname
            : "";
        categoryNamekey =
          categoryNameStr != ""
            ? categoryNameStr
                .replace(
                  /(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/gi,
                  ""
                )
                .trim()
            : "";
        categoryNamekey =
          categoryNamekey != "" ? categoryNamekey.replace(/\s+/g, " ") : "";
      
        let subCategoryNameKey = "";
        let subCategoryNameStr = "";
        subCategoryNameStr =
          suggestion.subCategoryname != null && suggestion.subCategoryname != ""
            ? suggestion.subCategoryname
            : "";
        subCategoryNameKey =
          subCategoryNameStr != ""
            ? subCategoryNameStr
                .replace(
                  /(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/gi,
                  ""
                )
                .trim()
            : "";
        subCategoryNameKey =
          subCategoryNameKey != "" ? subCategoryNameKey.replace(/\s+/g, " ") : "";
      
        let consumerCompanyNameKey = "";
        let consumerCompanyNameStr = "";
        consumerCompanyNameStr =
          suggestion.consumercompanyname != null &&
          suggestion.consumercompanyname != ""
            ? suggestion.consumercompanyname
            : "";
        consumerCompanyNameKey =
          consumerCompanyNameStr != ""
            ? consumerCompanyNameStr
                .replace(
                  /(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/gi,
                  ""
                )
                .trim()
            : "";
        consumerCompanyNameKey =
          consumerCompanyNameKey != ""
            ? consumerCompanyNameKey.replace(/\s+/g, " ")
            : "";
      
        return (
          <Button
            bsPrefix=" "
            className="suggBtn"
          >
            {/* {suggestion.label} */}
            {offeringNameKey.slice(0, 70)}
            {offeringNameKey.length > 70 ? "..." : ""}
            <div>
              {categoryNamekey.slice(0, 30)}
              {categoryNamekey.length > 30 ? "..." : ""}
              {" - "}
              {subCategoryNameKey.slice(0, 30)}
              {subCategoryNameKey.length > 30 ? "..." : ""}
              {" - "}
              {consumerCompanyNameKey.slice(0, 30)}
              {consumerCompanyNameKey.length > 30 ? "..." : ""}
            </div>
          </Button>
        );
      }
      useEffect(() => {
        if(props.companyId === "") {
          setValue("");
        }
      }, [props.companyId])

    
    const getSuggestions = (value, options) => {
        let selectOptions = [];
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    if (inputLength > 2) {
      selectOptions = options;
    }
    return inputLength <= 2
      ? []
      : selectOptions.filter(
          (option) => option.label.toLowerCase().indexOf(inputValue) > -1
        );
    };
    
  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestion([]);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
      setSuggestion(getSuggestions(value, serviceList ));
  };


  const onServiceSelected = (event, { suggestion }) => {
    if (typeof props.onServiceSelected !== "undefined") {
      props.onServiceSelected(suggestion);
    }
  };
    
  const inputProps = {
    placeholder:
      props.placeHolder != "" && props.placeHolder != undefined
        ? props.placeHolder
        : "Enter Service Name",
    className: "form-control " + (props.errorColor || ""),
    value:value,
    onChange: onChange,
    onBlur: props.validateField,
  };

    return (
        <div className="dvTypehd">
        {value.length > 0 ?
      <span
          title="Clear"
          role="img"
          aria-label="Clear"
          className="css-hakgx8 icn"
          onClick={() => {setValue(''); props.onCrossClick();}}
      >
          <svg width="18" height="18" viewBox="0 0 24 24" role="presentation">
              <path
                  d="M12 10.586L6.707 5.293a1 1 0 00-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 001.414 1.414L12 13.414l5.293 5.293a1 1 0 001.414-1.414L13.414 12l5.293-5.293a1 1 0 10-1.414-1.414L12 10.586z"
                  fill="gray"
              ></path>
          </svg>
      </span>
      : null}
    <AutoSuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      onSuggestionSelected={onServiceSelected}
    />
    </div>
  );
};

export default IncidentServiceTypeAhead;
