
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export const slaDefList = (state = {}, action) =>{
	switch (action.type){
		case 'LOAD_SLADEF_LIST_SUCCESS':
			return action.slaDefList;
			 //return action.slaDefList.data;
			default:
			return state;
	}
}
export const slaWorkdayList = (state = {}, action) =>{
	switch (action.type){
		case 'LOAD_SLAWORKDAY_LIST_SUCCESS':
			return action.slaWorkdayList.data;
			default:
			return state;
	}
}

export const slaHolidayList = (state = {}, action) =>{
	switch (action.type){
		case 'LOAD_SLAHOLIDAY_LIST_SUCCESS':
			return action.slaHolidayList.data;
			default:
			return state;
	}
}

//=========
export const slaHolidayEntriesList = (state = {}, action) => {
	switch (action.type) {
		case 'LOAD_SLAHOLIDAYENTRIES_SUCCESS':
			return action.slaHolidayEntries.data;
		default:
			return state;
	}
}
//=========
export const slaDefActiveMandatory = (state = {}, action) =>{
	switch (action.type){
		case 'LOAD_SLADEFMANDATORY_ACTIVE_SUCCESS':
			return action.slaDefActiveMandatory.data;
			default:
			return state;
	}
}
//=========
export const slaModuleStatusValues = (state = {}, action) =>{
	switch (action.type){
		case 'LOAD_SLAMODULE_STATUS_SUCCESS':
			return action.slaModuleStatus.data;
			default:
			return state;
	}
}

//=========
export const targetSlaAcitvity = (state = {}, action) =>{
	switch (action.type){
		case 'TARGET_SLA_ACTIVITY_DETAILS_FETCHED_SUCCESSFULLY':
			return action.payload;
			default:
			return state;
	}
}

//=========
export const opsCategoryLevels = (state = "", action) => {
	if (action.type === "OPS_CAT_LEVELS") {
	  return action.level;
	} else {
	  return state;
	}
  };