
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Link } from 'react-router';
import { Nav, NavItem, ListGroup, ListGroupItem, Media, Image, ButtonToolbar, Button } from 'react-bootstrap';
import axios from 'axios';
//const userDataUrl = `https://xsm-f.mybluemix.net/userdetailSelfService?userId=`;
import {GLOBAL} from '_Globals';
let breakFixServiceDetails = [];

const api = axios.create({
  headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

class XsmServiceQuickView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      breakFixServiceDetails: []
    };
  }

  componentWillMount() {
    //alert("H1"+this.props.categoryID)
    if(this.props.offeringId == "" || this.props.offeringId !== undefined){      
      var str={};
			str.offeringId=this.props.offeringId;
			str = JSON.stringify(str);
      const request = api.get(GLOBAL.breakFixServiceDetailsDataUrl,{headers:{'query':str}})
        request.then((response)=>{
              this.setServiceInfo(response);
        });
      }else{
        //console.log("this.props.categoryID not found1"+ this.props.categoryID)
      }
  }
  componentWillReceiveProps(nextProps) {
    //alert(nextProps.categoryID);
    if (nextProps.categoryID != "") {
      //alert("H3"+this.props.categoryID);
      //const request = axios.get(bfServiceDetailsDataUrl+'1592');
      var str = {};
      str.offeringId = nextProps.categoryID;
      str = JSON.stringify(str);
      const request = api.get(GLOBAL.breakFixServiceDetailsDataUrl, { headers: { 'query': str } });
      request.then((response) => {
        this.setServiceInfo(response);
      });
    } else {
      //console.log("this.props.categoryID not found"+ this.props.categoryID)
    }

  }
  setServiceInfo(service) {
    this.setState({
      breakFixServiceDetails: service.data
    });
    //console.log(this.state.breakFixServiceDetails.data.offeringName)
  }

  //===============

  //===============

  render() {
    if (this.state.breakFixServiceDetails.length == 0) {
      //alert("length"+this.state.breakFixServiceDetails.length)
      return (
        <div></div>
      );
    }
    return (
      <div className="margin-b-20">
        <div className="border-b padding-b-10 margin-b-10 black f-size-20 fw-300">{this.props.translator['Service Quick View']}</div>

        <ListGroup bsClass="" className="ListDetails">
          <ListGroupItem bsClass=""><span className="labSpan">{this.props.translator['Service Name']}</span>{this.state.breakFixServiceDetails[0].offeringName}</ListGroupItem>
          <ListGroupItem bsClass=""><span className="labSpan">{this.props.translator['Summary']}</span>{this.state.breakFixServiceDetails[0].offeringSummary}</ListGroupItem>
          <ListGroupItem bsClass=""><span className="labSpan">{this.props.translator['Category']}</span>{this.state.breakFixServiceDetails[0].category}</ListGroupItem>
          <ListGroupItem bsClass=""><span className="labSpan">{this.props.translator['SubCategory']}</span>{this.state.breakFixServiceDetails[0].subCategory}</ListGroupItem>
          <ListGroupItem bsClass=""><span className="labSpan">{this.props.translator['Company Name']}</span>{this.state.breakFixServiceDetails[0].companyName}</ListGroupItem>

        </ListGroup>
        {/* <ButtonToolbar className="margin-t-15"><Button bsStyle="primary">Component</Button><Button bsStyle="primary">Chain Roles</Button><Button bsStyle="primary">Details</Button></ButtonToolbar> */}
      </div>
    );
  }
}


export default XsmServiceQuickView;
