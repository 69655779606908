
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
//const getCategoryList = 'https://xsm-f.mybluemix.net/getOfferingsForListRestifySelfService/?categoryId=';
import {GLOBAL} from '_Globals';
import _ from 'lodash';

//let cmdbQuickViewListURL='http://cmdb-test.mybluemix.net/api/config_items/ ';
export function loadCmdbItemListSuccess(cmdbItemList) {
	return {
		type: 'LOAD_CMDB_ITEM_LIST_SUCCESS',
		cmdbItemList
	};
}

export function loadCmdbItemList(page, size, searchparams, type, statusName, className,subStatus,tabType) {
	let insideParams = {};
	let queryString = {};
	let newSearchParams = structuredClone(searchparams);

	if (_.isEmpty(newSearchParams)) {
		type.length != 0 ? insideParams.type = type.join(',') : insideParams.type = "5,10,15";
		statusName.length != 0 ? insideParams.status = statusName.join(',') : delete insideParams.status;
		className.length != 0 ? insideParams.class_id = className.join(',') : delete insideParams.class_id;
		Array.isArray(subStatus) && subStatus?.length !== 0 ? insideParams.sub_status_name = subStatus?.join(',') : delete insideParams.subStatus;

		queryString = Object.assign({}, insideParams);
	}
	else {
		type.length != 0 ? newSearchParams.type = type.join(',') : delete newSearchParams.type;
		statusName.length != 0 ? newSearchParams.status = statusName.join(',') : delete newSearchParams.status;
		className.length != 0 ? newSearchParams.class_id = className.join(',') : delete newSearchParams.class_id;
		Array.isArray(subStatus) && subStatus?.length !== 0 ? newSearchParams.sub_status_name = subStatus?.join(',') : delete newSearchParams.subStatus;


		queryString = Object.assign({}, newSearchParams);
	}

	let queryString1 = JSON.stringify(queryString);
	return (dispatch) => {
		dispatch({ type: "LOADER_IN_PROGRESS" });
		axios.get(GLOBAL.cmdbQuickViewListURL + "/limit/" + size + "/page/" + page, { headers: { 'revamp': queryString1 } })
			.then((cmdbItemList) => {
				if (!cmdbItemList) {
					throw Error(cmdbItemList.statusText);
				}
				return cmdbItemList;
			})
			.then((cmdbItemList) => {
				dispatch(loadCmdbItemListSuccess(cmdbItemList));
				dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
			})
			.catch((err) => {
				console.log(err);
				dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
			});

		if (tabType === "technical_owner_id") {
			axios.get(GLOBAL.cmdbQuickViewListURL + "/limit/" + size + "/page/" + page, { headers: { 'revamp': queryString1 } })
				.then((cmdbItemList) => {
					if (!cmdbItemList) {
						throw Error(cmdbItemList.statusText);
					}
					if (cmdbItemList.data === "No record found") {
						dispatch({ type: "T_Owner", data: [] });
					} else {
						dispatch({ type: "T_Owner", data: cmdbItemList?.data?.result });
					}
					dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
				})
				.catch((err) => {
					dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
				});
		} else if (tabType === "business_owner_id") {
			axios.get(GLOBAL.cmdbQuickViewListURL + "/limit/" + size + "/page/" + page, { headers: { 'revamp': queryString1 } })
				.then((cmdbItemList) => {
					if (!cmdbItemList) {
						throw Error(cmdbItemList.statusText);
					}
					if (cmdbItemList.data === "No record found") {
						dispatch({ type: "B_Owner", data: [] });
					} else {
						dispatch({ type: "B_Owner", data: cmdbItemList?.data?.result });
					}
					dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
				})
				.catch((err) => {
					console.log(err);
					dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
				});
		}
	};
}

export function loadCmdbItemFilterList(searchParams, size, page) {
	return (dispatch) => {
		//console.log(searchParams);
		//alert("called")
		dispatch({ type: "LOADER_IN_PROGRESS" });
		let params = {};
		if (!_.isEmpty(searchParams)) { params = { params: searchParams }; } //lodash isEmpty checks for nulls too
		//axios.get(GLOBAL.cmdbQuickViewListFilterURL,params)
		let cmdbfiltersearchurl = GLOBAL.cmdbQuickViewListFilterURL + "/limit/" + size + "/page/" + page;
		axios.get(cmdbfiltersearchurl, params)
			//axios.get(GLOBAL.cmdbQuickViewListFilterURL+"/searchParams/"+{params})
			.then((cmdbItemList) => {
				if (!cmdbItemList) {
					//dispatch(loadBreakFixFailure(breakFix.statusText))
					throw Error(cmdbItemList.statusText);
				}
				return cmdbItemList;
			})
			.then((cmdbItemList) => {
				dispatch(loadCmdbItemListSuccess(cmdbItemList));
				dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
			})
			.catch((err) => {
				console.log(err);
				dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
			});
		//console.log("not calling loadBreakFixQuickView");

	};
}

export function addStatusToSelectedStatusList(statusId) {
	return {
		type: 'ADD_TO_SELECTED_STATUS_LIST',
		payload: statusId
	};
}

export function removeStatusToSelectedStatusList(statusId) {
	return {
		type: 'REMOVE_FROM_SELECTED_STATUS_LIST',
		payload: statusId
	};
}

export function addCompanyToSelectedCompanyList(companyId) {
	return {
		type: 'ADD_TO_SELECTED_COMPANY_LIST',
		payload: companyId
	};
}

export function removeCompanyToSelectedCompanyList(companyId) {
	return {
		type: 'REMOVE_FROM_SELECTED_COMPANY_LIST',
		payload: companyId
	};
}

export function addClassToSelectedClassList(classId) {
	return {
		type: 'ADD_TO_SELECTED_CLASS_LIST',
		payload: classId
	};
}

export function removeClassToSelectedClassList(classId) {
	return {
		type: 'REMOVE_FROM_SELECTED_CLASS_LIST',
		payload: classId
	};
}

export function resetCMDBQVFilters() {
	return {
		type: 'RESET_CMDB_QV_FILTERS',
		payload: null
	};
}

export function loadCmdbFilterStringResultsSuccess(resultsResponse) {
	// console.log('BBBBBBBBBBBBBBBBBB---------------', resultsResponse)
	return {
		type: 'LOAD_ARTICLERESULTS_SUCCESS',
		resultsResponse
	};
}

export function loadCMDBFilterResultsForSearchString(searchDropdownValue, searchString, size, page) {
	// console.log('##########################',searchDropdownValue, searchString);
	let str = {};
	str.searchByName = searchDropdownValue;
	str.searchByValue = searchString;
	const cmdbResultsUrl = GLOBAL.cmdbQuickViewListFilterSearchStringURL + "/limit/" + size + "/page/" + page;
	str = JSON.stringify(str);
	// console.log('articleResultsUrl',articleResultsUrl)

	return (dispatch) => {
		// dispatch({type:'FETCHING_DETAILS_IN_PROGRESS'});
		dispatch({ type: "LOADER_IN_PROGRESS" });
		axios.get(cmdbResultsUrl, { headers: { query: str } })
			.then((resultResponse) => {	
				if (!resultResponse) {
					throw Error(resultResponse.statusText);
				}
				return resultResponse;
			})
			.then((resultResponse) => {
				// dispatch({type:'DETAILS_FETCHED_SUCCESSFULLY'});
				dispatch(loadCmdbItemListSuccess(resultResponse));
				dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
				// dispatch(loadCmdbFilterStringResultsSuccess(resultResponse));
			})
			.catch((err) => {
				dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
			});

	};
}

export function loadStatusOptionsSuccess(statusList) {
	return {
		type: 'LOAD_STATUSOPTONS_SUCCESS',
		statusList
	};
}

export function loadSubstatusOptionsSuccess(subStatusList) {
	return {
		type: 'LOAD_SUBSTATUSOPTONS_SUCCESS',
		subStatusList
	};
}

export function loadStatusAndSubStatusOptions(value) {
	let str = {};
	str.filter = value;
	str = JSON.stringify(str);
	return (dispatch) => {
		axios.get("/cmdbStatus", { headers: { query: str } })
			.then((statusSubstatusList) => {
				if (!statusSubstatusList) {
					throw Error(statusSubstatusList.statusText);
				}
				return statusSubstatusList;
			})
			.then((statusSubstatusList) => {
				if (value == "Status List") {
					dispatch(loadStatusOptionsSuccess(statusSubstatusList));
				}
				if (value == "SubStatus List") {
					dispatch(loadSubstatusOptionsSuccess(statusSubstatusList));
				}
				if (value == "ALL_UNIQUE_SUBSTATUS") {
					dispatch(loadSubstatusOptionsSuccess(statusSubstatusList));
				}
			})
			.catch((err) => {
				/*SKIP*/
			});
	};
}