
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { useGetAssociatedRuleMutation } from "../../../reducers/rules";
import { Typeahead } from "react-bootstrap-typeahead";

export const RuleAmsDdropDownNoReduxForm = ({
  name,
  formData,
  rulesAttribute,
  moduleName,
  ruleType,
  showAms,
  groupData,
  reduxForm,
  ruleFor,
  serviceCompanyId,
  ...props
}) => {

  console.log('showAms --- ', showAms, groupData)

  const [ruleDataOptions, setRuleDataOptions] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const {
    status,
    serviceName,
    impactedCi,
    impactedUsername,
    ciClassName,
    userLocation,
    ciLocation,
    serviceId,
    impactedCiId,
    requestorId,
    classId,
    userLocationId,
    ciLocationId
  } = rulesAttribute || {};

  const [getRulesData, { isLoading: rulesLoading }] =
    useGetAssociatedRuleMutation();

  useEffect(() => {
    if (formData?.ItemList?.companyId) {
      setLoading(true);
      if(status && serviceName) {
        getRulesData({
          data: {
            itemStatus: status?.toString(),
            priorityId: formData?.ItemList?.priorityActualVal?.toString(),
            offeringNameSx: serviceName,
            impactedCi,
            requesterName: impactedUsername,
            ciClassName,
            requesterLocationName: userLocation,
            ciLocation,
            offeringIdSx: serviceId?.toString(),
            requesterId: requestorId?.toString(),
            requesterLocationId: userLocationId?.toString()
          },
          moduleName,
          ruleType,
          companyId: formData?.ItemList?.companyId,
          status: "active",
          weight: "N",
          serviceCompanyId: serviceCompanyId?.toString(),
          ruleFor
        })
          .then((response) => {
            let options = response.data?.body?.map((item) => ({
              id: item.responseEntityId,
              label: item.responseEntity,
              isExternal: item?.external,
              supportCompanyID: item?.assignmentCompanyId,
              supportCompanyName: item?.assignmentCompany,
            }));
            
            options = [...new Map(
              options.map((item) => [JSON.stringify(item), item])
              ).values(),
            ]
            setRuleDataOptions(options || []);
          })
          .catch((error) => {
            console.error("API Error:", error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [
    formData?.ItemList?.priorityActualVal,
    formData?.ItemList?.companyId,
    status,
    serviceName,
    impactedCi,
    impactedUsername,
    ciClassName,
    userLocation,
    ciLocation,
    getRulesData,
    moduleName,
    ruleType,
  ]);

  return (
    <Typeahead
      name={name} 
      {...props}
      options={!showAms ? ruleDataOptions : groupData}
      renderMenuItemChildren={(option, props) => {

        let label;
        let title;
        
        label =
          option.label.length > 30
            ? option.label.slice(0, 30) + "..."
            : option.label;
        title =
          option.label.length > 30 ? option.label : "";
        if (option?.supportCompanyName) {
          return (
            <div>
              <span
                className="typHdmainHead"
                title={title}
                style={{ display: "block" }}
              >
                {label}
              </span>
              <span
                className="typHdmainSubHead"
                style={{
                  display: "block",
                  "margin-top": "6px",
                  color: "#777",
                }}
              >
                {option?.supportCompanyName || ""}
              </span>
            </div>
          );
        }
        return (
          <div>
            <span className="typHdmainHead" title={title}>
              {label}
            </span>
          </div>
        );
      }}
    />
  )
};
