
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Field, reduxForm, formValueSelector, change, SubmissionError } from 'redux-form';
import {createShift} from '../../../../actions/onCallSchedule/shift/onCallShiftAction'
import CommonHeaderMenu from "../../common/CommonHeaderMenu";
import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import { MyContext } from '_MyContext';

let sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const CreateShiftHeader = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const tr = useSelector((state) => state.spcmReducer.tr);
	const context = useContext(MyContext);
    const closeButton = useRef(null);
    const saveObjRef = useRef(null);

    useEffect(() => {
		context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
		context.newTabPrevent();
	}, []);

	const submit = async (values) => {

		return sleep(1000) // simulate server latency
		  .then(() => {
			if (`${JSON.stringify(values, null, 2)}` == "{}") {
			  throw new SubmissionError({ _error: 'The form has missing required fields, please revalidate the highlighted fields and update the form' })
			}
			else { 
				  let postjson;
				  postjson = {
					"groupId": values.GROUP_ID,
					"shiftName": values.SHIFT_NAME.trim(),
					"groupName": (values.GROUP_NAME === "" || typeof values.GROUP_NAME === "undefined") ? "" : values.GROUP_NAME.trim(),
					"status": "Active",
					"sxScheduleId":values.SCHEDULE_ID,
					"companyId": values.COMPANY_ID,
					"companyName": values.COMPANY_NAME,
			};
			console.log("Post JSON",postjson)
			dispatch(createShift(postjson));
		  }
		});
	  };

	return (console.log("Props===>",props),
		<Row className="margin-b-15">
			<Col lg={6} md={6} sm={12} xs={12}>
			<CommonHeaderMenu active="Shift"/>
			</Col>
			<Col lg={6} md={6} sm={12} xs={12}>
				<div className="paHedFilter">
					<Link bsPrefix=' ' id="creTag" to="javascript:void(0)" className="myBt plus ctrlKeyPrevent" disabled={props.submitting} onClick={props.handleSubmit(submit)} ref={saveObjRef} title={tr['Save']}>{props.submitting ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/>}</Link>
					<Link bsPrefix=' ' to="/onCallSchedule/shift" className="myBt cancel fillBtn ctrlKeyPrevent" title={tr['Cancel']} ref={closeButton}><IoClose /></Link>
				</div>
			</Col>
		</Row>
	)
}

export default reduxForm({
	form: 'CreateShiftForm',
	destroyOnUnmount: true,
	enableReinitialize: true
})(CreateShiftHeader);