
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Container, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import LeftPanelIndex from "./LeftPanelView/LeftPanelIndex";
import OfferingsGridIndex from "./OfferingsGrid/OfferingsGridIndex";
import {
  VerticalCatalogApi,
  useGetBusinessFnLabelsQuery,
} from "../../../reducers/VerticalCatalog/VerticalCatalogReducer";
import { useDispatch } from "react-redux";
import { GLOBAL } from "../../Globals";
import "_Css/consumer/_verticalCatalog.scss";

const cookies = new Cookies();
import Cookies from 'universal-cookie';
let hometab_preference = cookies.get('gph');
if (hometab_preference) hometab_preference = hometab_preference.replace('s:', '');
if (hometab_preference) hometab_preference = hometab_preference.substring(0, hometab_preference.lastIndexOf('.'));
hometab_preference = hometab_preference.split("~");
let bestSelling = hometab_preference[10];
let featured = hometab_preference[16];

const VerticalCatalogIndex = ({ pageViewFun, isView }) => {
  const {
    isFetching: isFetchingBusinessFn,
    isError: businessFnApiError,
    data: businessFnLabels,
  } = useGetBusinessFnLabelsQuery();
  const dispatch = useDispatch();
  const tr = useSelector((state) => state.spcmReducer.tr);
  // const [activeTab, setActiveTab] = useState("Featured");
  // const [activeTabEventKey, setActiveTabEventKey] = useState("0.0");
  const [activeTab, setActiveTab] = useState("");
  const [activeTabEventKey, setActiveTabEventKey] = useState("");
  const [activeTabId, setActiveTabId] = useState("");
  const [activeMenuSubMenuItems, setActiveMenuSubMenuItems] = useState({
    businessFn: [],
    categories: [],
    subCategories: [],
  });
  // const [url, setUrl] = useState(GLOBAL.getFeaturedOfferings);
  const [url, setUrl] = useState();
  const [labelType, setLabelType] = useState("");
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(12);
  const [isGeneralSection, setIsGeneralSection] = useState(true);
  const [parentLabelType, setParentLabelType] = useState("");
  const [parentLabelId, setParentLabelId] = useState("");
  const [parentLabel, setParentLabel] = useState("");

  const udpateActiveMenuSubMenuItems = (
    menuType,
    id,
    label,
    key,
    breadCrumbAction = false
  ) => {
    let menuItems = structuredClone(activeMenuSubMenuItems);
    switch (menuType) {
      case "businessFn":
        menuItems.businessFn.forEach((item) => {
          item.active = item.id == id ? breadCrumbAction ? item.active : !item.active : false;
          item.clicked = item.id == id ? true : false;
        });
        if (
          menuItems.businessFn.filter((item) => item.id === id).length === 0
        ) {
          menuItems.businessFn.push({
            id,
            label,
            key,
            active: true,
            clicked: true,
          });
        }
        menuItems.categories.forEach((item) => {
          item.active = false;
          item.clicked = false;
        });
        menuItems.subCategories.forEach((item) => {
          item.active = false;
          item.clicked = false;
        });
        setActiveMenuSubMenuItems(menuItems);
        break;
      case "categories":
        menuItems.categories.forEach((item) => {
          item.active = item.id == id ? !item.active : false;
          item.clicked = item.id == id ? true : false;
        });
        if (
          menuItems.categories.filter((item) => item.id === id).length === 0
        ) {
          menuItems.categories.push({
            id,
            label,
            key,
            active: true,
            clicked: true,
          });
        }
        menuItems.subCategories.forEach((item) => {
          item.active = false;
          item.clicked = false;
        });
        menuItems.businessFn.forEach((item) => {
          item.clicked = false;
        });
        setActiveMenuSubMenuItems(menuItems);
        break;
      case "subCategories":
        menuItems.subCategories.forEach((item) => {
          item.active = item.id == id ? breadCrumbAction ? item.active : !item.active : false;
          item.clicked = item.id == id ? true : false;
        });
        if (
          menuItems.subCategories.filter((item) => item.id === id).length === 0
        ) {
          menuItems.subCategories.push({
            id,
            label,
            key,
            active: true,
            clicked: true,
          });
        }
        menuItems.categories.forEach((item) => {
          item.clicked = false;
        });
        menuItems.businessFn.forEach((item) => {
          item.clicked = false;
        });
        setActiveMenuSubMenuItems(menuItems);
        break;
      default:  
        menuItems.businessFn.forEach((item) => {item.active = false; item.clicked = false;});
        menuItems.categories.forEach((item) => {item.active = false; item.clicked = false;});
        menuItems.subCategories.forEach((item) => {item.active = false; item.clicked = false;});
        setActiveMenuSubMenuItems(menuItems);
        break;
    }
  };

  useEffect(() => {
    if(featured == 'Y' || featured == 'y'){
      setActiveTab(tr['Featured'])
      setActiveTabEventKey("0.0")
      setActiveTabId(1);
      setUrl(GLOBAL.getFeaturedOfferings)
    }else{
    setActiveTab(tr['Best Selling'])
    setActiveTabEventKey("0.1")
    setActiveTabId(2);
    setUrl(GLOBAL.getBestSellingOfferings)
    }

    return () => {
      dispatch(VerticalCatalogApi.util.resetApiState());
    };
  }, []);

  return (
        <Container fluid>
          <Tab.Container
            id="Category-Tabs"
            defaultActiveKey={activeTabEventKey}
          >
            <Row className="verticalRw">
              <Col xl={2} lg={3} md={3} sm={4} xs={12}>
                <LeftPanelIndex
                  businessFnLabels={businessFnLabels}
                  isFetchingBusinessFn={isFetchingBusinessFn}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  setActiveTabEventKey={setActiveTabEventKey}
                  setActiveTabId={setActiveTabId}
                  businessFnApiError={businessFnApiError}
                  activeMenuSubMenuItems={activeMenuSubMenuItems}
                  udpateActiveMenuSubMenuItems={udpateActiveMenuSubMenuItems}
                  url={url}
                  labelType={labelType}
                  isGeneralSection={isGeneralSection}
                  start={start}
                  end={end}
                  parentLabel={parentLabel}
                  parentLabelId={parentLabelId}
                  parentLabelType={parentLabelType}
                  activeTabEventKey={activeTabEventKey}
                />
              </Col>
              <Col xl={10} lg={9} md={9} sm={8} xs={12}>
                <OfferingsGridIndex
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  activeTabEventKey={activeTabEventKey}
                  setActiveTabEventKey={setActiveTabEventKey}
                  activeTabId={activeTabId}
                  setActiveTabId={setActiveTabId}
                  pageViewFun={pageViewFun}
                  activeMenuSubMenuItems={activeMenuSubMenuItems}
                  url={url}
                  setUrl={setUrl}
                  labelType={labelType}
                  setLabelType={setLabelType}
                  isGeneralSection={isGeneralSection}
                  setIsGeneralSection={setIsGeneralSection}
                  start={start}
                  setStart={setStart}
                  end={end}
                  setEnd={setEnd}
                  parentLabel={parentLabel}
                  setParentLabel={setParentLabel}
                  parentLabelId={parentLabelId}
                  setParentLabelId={setParentLabelId}
                  parentLabelType={parentLabelType}
                  setParentLabelType={setParentLabelType}
                  udpateActiveMenuSubMenuItems={udpateActiveMenuSubMenuItems}
                  businessFnLabels={businessFnLabels}
                  isFetchingBusinessFn={isFetchingBusinessFn}
                  businessFnApiError={businessFnApiError}  
                  isView={isView}               
                />
              </Col>
            </Row>
          </Tab.Container>
        </Container>
  );
};

export default VerticalCatalogIndex;
