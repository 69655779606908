
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/

/**
@Description: routes for
**/
import axios from 'axios';
import swal from 'sweetalert';
import { GLOBAL } from '_Globals';
import {getChainCompanyListAPI} from '_Services/spcmApi';
import { navigationHooks } from '../../helpers/NavigationHook';

let searchParamsForSupportGroup = {};

const api = axios.create({
  headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

export const getCompanyData = (modulePageName) => {
  console.log('user details');
  //console.log(userDetails);
  return (dispatch) => {
    axios.get('/api/serviceportfolio/companylist?userId=')
      .then((response) => {
        console.log('company api response');
        console.log(response.data);
        if (typeof modulePageName !== 'undefined' && modulePageName === 'AMScreate') {
          let typeaheadData = [];
          if (response.data !== '') {
            response.data.map((item) => {
              typeaheadData.push({ value: '' + item.field1Value, label: item.field1Key });
            });
          }
          dispatch({ type: 'COMPANY_LIST_TYPE', typeaheadData });
        }
        dispatch({
          type: 'COMPANY_LIST',
          companyList: response.data
        })

      })
  }
};
export const getClassData = (company_id) => {
  console.log('class details');
  //console.log(userDetails);
  return (dispatch) => {
    axios.get('/api/config_items/classesPresentInCITable/' + company_id)
      .then((response) => {
        console.log('class api response');
        console.log(response.data);
        dispatch({
          type: 'CLASS_LIST',
          classList: response.data
        })
      })
  }
};
export const getImpactedCI = (classId) => {
  console.log('impacted ci details action');
  let query = '?class_id=' + classId;
  //console.log(userDetails);
  return (dispatch) => {
    axios.get('/api/config_items/ciDetailsByClassId' + query)
      .then((response) => {
        console.log('impacted ci api response from action');
        console.log(response.data);
        dispatch({
          type: 'IMPACTED_CI_LIST',
          impactedCIList: response.data
        })
      })
  }
};

export const getAssociatedCompanyDataWithoutServiceSelected = (companyId, selectedCompanyName) => {
  console.log('in getAssociatedCompanyData, selected company id is ' + companyId);
  console.log('in getAssociatedCompanyData, selected company name is ' + selectedCompanyName);
  return (dispatch) => {
    axios.get('/api/1/companies/' + companyId + '/associatedcompanies')
      .then((response) => {
        console.log('asociated company api response');
        console.log(response.data);
        let tempArray = [];
        let obj = {
          "COMPANY_ID": companyId,
          "COMPANY_NAME": selectedCompanyName
        }
        tempArray.push(obj);
        let newArray = [];
        newArray = tempArray.concat(response.data);
        dispatch({
          type: 'ASSOCIATED_COMPANY_LIST',
          associatedCompanyList: newArray
        })
      })
  }
};

export const getAssociatedCompanyData = (serviceId, companyId, selectedCompanyName, modulePageName) => {
  console.log('in getAssociatedCompanyData, selected company id is ' + companyId);
  console.log('in getAssociatedCompanyData, selected company name is ' + selectedCompanyName);
  // return (dispatch) => {
  //     axios.get('/api/1/companies/'+companyId+'/associatedcompanies')
  //       .then((response)=>{
  //         console.log('asociated company api response');
  //         console.log(response.data);
  //         let tempArray=[];
  //         let obj = {
  //           "COMPANY_ID": companyId,
  //           "COMPANY_NAME": selectedCompanyName
  //         }
  //         tempArray.push(obj);
  //         let newArray=[];
  //         newArray=tempArray.concat(response.data);
  //           dispatch({
  //             type:'ASSOCIATED_COMPANY_LIST',
  //             associatedCompanyList:newArray
  //           })
  //       })
  // }
  return (dispatch) => {
    axios.get('/api/serviceDetails/' + serviceId + '/chainCompanyDetails')
      .then((response) => {
        console.log('asociated company api response');
        console.log(response.data);
        let flag = 0;
        response.data.forEach(element => {
          if (element.COMPANY_ID == companyId)
            flag = 1;
        })
        let tempArray = [];
        if (flag == 0) {
          let obj = {
            "COMPANY_ID": companyId,
            "COMPANY_NAME": selectedCompanyName
          }
          tempArray.push(obj);
        }
        let newArray = [];
        newArray = tempArray.concat(response.data);
        console.log(newArray);
        if (typeof modulePageName !== 'undefined' && modulePageName === 'AMScreate') {
          let typeaheadData = [], flag = false;
          if (response.data !== "") {
            response.data.map((item) => {
              typeaheadData.push({ value: '' + item.COMPANY_ID, label: item.COMPANY_NAME });
              if ('' + item.COMPANY_ID === '' + companyId) {
                flag = true;
              }
            });
          }
          if (!flag) {
            typeaheadData.push({ value: '' + companyId, label: selectedCompanyName });
          }
          dispatch({ type: 'ASSOCIATED_COMPANY_LIST_TYPE', typeaheadData });
        }
        dispatch({
          type: 'ASSOCIATED_COMPANY_LIST',
          associatedCompanyList: newArray
        })
      })
  }
};

export const getAssociatedCompanyListNew = (companyId) => {
  return (dispatch) =>{
    getChainCompanyListAPI(companyId).then((response)=>{
        let typeaheadData=[];
        response.data.map((item) => {
          typeaheadData.push({ value: '' + item.field1Value, label: item.field1Key });
        });
        dispatch({ type: 'ASSOCIATED_COMPANY_LIST_TYPE', typeaheadData });
    });
  };
};

export const getCIAssociatedCompany = (companyId, selectedCompanyName, modulePageName) => {
  let url = "";
  url = GLOBAL.ciSupportCompanyUrl + "/id/" + companyId;

  return (dispatch) => {

    axios.get(url)
      .then((response) => {
        console.log(' !!!!!!!! CI asociated company api response');
        console.log(response.data);
        let changedArray = [];
        response.data.forEach((element) => {
          let obj = {};
          obj.COMPANY_ID = element.field1Value;
          obj.COMPANY_NAME = element.field1Key;
          changedArray.push(obj);
        })
        let flag = 0;
        response.data.forEach(element => {
          if (element.COMPANY_ID == companyId)
            flag = 1;
        })
        let tempArray = [];
        if (flag == 0) {
          let obj = {
            "COMPANY_ID": companyId,
            "COMPANY_NAME": selectedCompanyName
          }
          tempArray.push(obj);
        }
        let newArray = [];
        newArray = tempArray.concat(changedArray);
        console.log(newArray);
        if (typeof modulePageName !== 'undefined' && modulePageName === 'AMScreate') {
          let typeaheadData = [], flag = false;
          if (response.data !== "") {
            response.data.map((item) => {
              typeaheadData.push({ value: '' + item.field1Value, label: item.field1Key });
              if ('' + item.field1Value === '' + companyId) {
                flag = true;
              }
            });
          }
          if (!flag) {
            typeaheadData.push({ value: '' + companyId, label: selectedCompanyName });
          }
          dispatch({ type: 'ASSOCIATED_COMPANY_LIST_TYPE', typeaheadData });
        }
        dispatch({
          type: 'ASSOCIATED_COMPANY_LIST',
          associatedCompanyList: newArray
        })
      })
      .catch((err) => {
        console.log(err);
      });


  };
}

export const getGroupData = (associatedCompanyId, modulePageName) => {
  console.log('in getGroupData, selected associated company id is ' + associatedCompanyId);
  return (dispatch) => {
    axios.get('/api/1/companies/' + associatedCompanyId + '/groups')
      .then((response) => {
        console.log('group api response');
        console.log(response.data);
        if (typeof modulePageName !== 'undefined' && modulePageName === "AMScreate") {
          let typeaheadData = [];
          if (response.data !== '') {
            response.data.map((item) => {
              typeaheadData.push({value:''+item.GROUPID,label:item.GROUPNAME, isExternal:item.ISEXTERNAL});
            });
          }
          dispatch({ type: 'GROUP_LIST_TYPE', typeaheadData });
        }
        dispatch({
          type: 'GROUP_LIST',
          groupList: response.data
        })
      })
  }
};
export const getAllgroup = (associatedCompanyId) => {
  let url = "";
  url = GLOBAL.ciSupportAllGroupUrl + "/id/" + associatedCompanyId;
  return (dispatch) => {
    axios.get(url)
      .then((response) => {
        console.log('group api response');
        console.log(response.data);
        let changedArray = [];
        if (GLOBAL.fulfillmentPageLoad === true || GLOBAL.changepageLoad === true) {
          let allGroup = [];
          if (response.data !== "") {
            response.data.map((item) => {
              allGroup.push({'value': ''+item.groupId+'', 'label':item.groupName, 'isExternal':item.isExternal, 'supportCompanyID': item.companyId, 'supportCompanyName': item.companyName});
            });
          }
          dispatch({ type: 'GROUP_LIST_TYPEAHEAD', allGroup });
          if (GLOBAL.fulfillmentPageLoad === true || GLOBAL.changepageLoad === true) {
            let typeaheadData = [];
            dispatch({ type: 'LOAD_BREAKFIXSGI_SUCCESS_TYPEAHEAD', typeaheadData });
          }
        }
        if (response.data == "") {
          dispatch({
            type: 'GROUP_LIST',
            groupList: changedArray
          })
        }
        else {
          response.data.forEach((element) => {
            let obj = {};
            obj.groupId = element.groupId;
            obj.groupName = element.groupName;
            obj.isExternal = element.isExternal;
            obj.supportCompanyID = element.companyId;
		        obj.supportCompanyName = element.companyName;
            changedArray.push(obj);
          })
          dispatch({
            type: 'GROUP_LIST',
            groupList: changedArray
          })
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };
};
export const getAllgroupFulfillment = (associatedCompanyId) => {
  let url = "";
  url = GLOBAL.ciSupportAllGroupUrl + "/id/" + associatedCompanyId;
  return (dispatch) => {
    axios.get(url)
      .then((response) => {
        console.log('group api response');
        console.log(response.data);
        let changedArray = [];
        if (GLOBAL.fulfillmentPageLoad === true || GLOBAL.changepageLoad === true) {
          let allGroup = [];
          if (response.data !== "") {
            response.data.map((item) => {
              allGroup.push({'value': ''+item.groupId+'', 'label':item.groupName, 'isExternal':item.isExternal, 'supportCompanyID': item.companyId, 'supportCompanyName': item.companyName});
            });
          }
          dispatch({ type: 'GROUP_LIST_TYPEAHEAD', allGroup });
          if (GLOBAL.fulfillmentPageLoad === true || GLOBAL.changepageLoad === true) {
            let typeaheadData = [];
            dispatch({ type: 'LOAD_BREAKFIXSGI_SUCCESS_TYPEAHEAD', typeaheadData });
          }
        }
        if (response.data == "") {
          dispatch({
            type: 'GROUP_LIST',
            groupList: changedArray
          })
        }
        else {
          response.data.forEach((element) => {
            let obj = {};
            obj.groupId = element.groupId;
            obj.groupName = element.groupName;
            obj.isExternal = element.isExternal;
            obj.supportCompanyID = element.companyId;
		        obj.supportCompanyName = element.companyName;
            changedArray.push(obj);
          })
          dispatch({
            type: 'GROUP_LIST',
            groupList: changedArray
          })
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };
};
export const getQueryAllgroup = (associatedCompanyId, queryGrp) => {
  let url = "";
  url = GLOBAL.ciSupportQueryAllGroupUrl + "/id/" + associatedCompanyId + "/q/" + queryGrp;
  return (dispatch) => {
    axios.get(url)
      .then((response) => {
        console.log('group api response');
        console.log(response.data);
        let changedArray = [];
        if (response.data == "") {
          dispatch({
            type: 'GROUP_LIST',
            groupList: changedArray
          })
        }
        else {
          response.data.forEach((element) => {
            let obj = {};
            obj.groupId = element.groupId;
            obj.groupName = element.groupName;
            changedArray.push(obj);
          })
          dispatch({
            type: 'GROUP_LIST',
            groupList: changedArray
          })
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };
};
export const getCIGroupData = (associatedCompanyId, modulePageName) => {
  let url = "";
  url = GLOBAL.ciSupportGroupUrl + "/id/" + associatedCompanyId;
  return (dispatch) => {
    axios.get(url)
      .then((response) => {
        console.log('group api response');
        console.log(response.data);
        let changedArray = [];
        response.data.forEach((element) => {
          let obj = {};
          obj.GROUPID = element.field1Value;
          obj.GROUPNAME = element.field1Key;
            obj.isExternal=element.isExternal;
          changedArray.push(obj);
        })
        if (typeof (modulePageName) !== 'undefined' && modulePageName === 'AMScreate') {
          let typeaheadData = [];
          if (response.data !== '') {
            response.data.map((item) => {
             typeaheadData.push({value:''+item.field1Value,label:item.field1Key, 'isExternal' : item.isExternal});
            });
          }
          dispatch({ type: 'GROUP_LIST_TYPE', typeaheadData });
        }
        dispatch({
          type: 'GROUP_LIST',
          groupList: changedArray
        })
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getBreakfixGroupData = (serviceId, modulePageName) => {
  console.log('in getBreakfixGroupData, selected service id is ' + serviceId);
  return (dispatch) => {
    axios.get('/getBreakfixGroups/' + serviceId)
      .then((response) => {
        console.log('group api response');
        console.log(response.data);
        if (typeof modulePageName !== 'undefined' && modulePageName === "AMScreate") {
          let typeaheadData = [];
          if (response.data !== '') {
            response.data.map((item) => {
              typeaheadData.push({ value: '' + item.GROUPID, label: item.GROUPNAME,isExternal: item.IS_EXTERNAL });
            });
          }
          dispatch({ type: 'GROUP_LIST_TYPE', typeaheadData });
        }
        dispatch({
          type: 'GROUP_LIST',
          groupList: response.data
        })
      })
  }
};


export const getIndividualData = (groupId, associatedCompanyId) => {
  console.log('in getIndividualData, selected associated company id is ' + associatedCompanyId);
  console.log('in getIndividualData, selected group id is ' + groupId);
  return (dispatch) => {
    axios.get('/api/1/companies/' + associatedCompanyId + '/groups/' + groupId + '/members')
      .then((response) => {
        console.log('individual api response');
        console.log(response.data);
        dispatch({
          type: 'INDIVIDUAL_LIST',
          individualList: response.data
        })
      })
  }
};
export const getFormProblemTeam = (companyId) => {

  return (dispatch) => {
    let str = {};
    str.companyId = companyId, str = JSON.stringify(str);
    axios.post('/api/serviceportfolio/getPITuserList', {}, { headers: { 'query': str } })

      .then((response) => {
        console.log('individual api response');
        console.log(response.data.data);
        dispatch({
          type: 'INDIVIDUAL_LISTS',
          teamlist: response.data
        })
      })
  }
};
// export const getModuleData = () => {
//     return (dispatch) => {
//         axios.get('/api/modules')
//           .then((response)=>{
//             console.log('module api response');
//             console.log(response.data);
//               dispatch({
//                 type:'MODULE_LIST',
//                 moduleList:response.data
//               })
//           })
//     }
// };

export const getModuleData = () => {
  let filter = '?field1Name=module';
  return (dispatch) => {
    axios.get('/rest/menuItems' + filter)
      .then((response) => {
        console.log('module api response');
        console.log(response.data);
        dispatch({
          type: 'MODULE_LIST',
          moduleList: response.data
        })
      })
  }
};

// export const getEventData = (moduleId) => {
//   console.log('in getEventData, selected module id is '+moduleId);
//     return (dispatch) => {
//         axios.get('/api/module/events/'+moduleId)
//           .then((response)=>{
//             console.log('event api response');
//             console.log(response.data);
//             if(response.status == 204) {
//               dispatch({
//                 type:'EVENT_LIST',
//                 eventList:[]
//               })
//             }
//             else {
//               dispatch({
//                 type:'EVENT_LIST',
//                 eventList:response.data
//               })
//             }
//           })
//     }
// };

export const getEventData = (moduleName) => {
  let filter = moduleName != 'event' ? '?field1Name=event&module=' + moduleName : '?field1Name=eventFilter';
  return (dispatch) => {
    axios.get('/rest/menuItems' + filter)
      .then((response) => {
        console.log('event api response');
        console.log(response.data);
        if (response.status == 204) {
          dispatch({
            type: 'EVENT_LIST',
            eventList: []
          })
        }
        else {
          dispatch({
            type: 'EVENT_LIST',
            eventList: response.data
          })
        }
      })
  }
};

// export const getParameterData = (moduleId) => {
//   console.log('in getParameterData, selected module id is '+moduleId);
//     return (dispatch) => {
//         axios.get('/api/'+moduleId+'/parameter')
//           .then((response)=>{
//             console.log('parameter api response');
//             console.log(response.data);
//             if(response.status == 204) {
//               dispatch({
//                 type:'PARAMETER_LIST',
//                 parameterList:[]
//               })
//             }
//             else {
//               dispatch({
//                 type:'PARAMETER_LIST',
//                 parameterList:response.data
//               })
//             }
//           })
//     }
// };

export const getParameterData = (moduleName) => {
  console.log('in getParameterData, selected module name is ' + moduleName);
  // let filter='?field1Name=Parameter&module='+moduleName;
  let filter = '?field1Name=Condition&module=' + moduleName;
  return (dispatch) => {
    axios.get('/rest/menuItems' + filter)
      .then((response) => {
        console.log('parameter api response');
        console.log(response.data);
        if (response.status == 204) {
          dispatch({
            type: 'PARAMETER_LIST',
            parameterList: []
          })
        }
        else {
          dispatch({
            type: 'PARAMETER_LIST',
            parameterList: response.data
          })
        }
      })
  }
};

// export const getParameterValueData = (parameterName,moduleId) => {
// //  console.log('in getParameterValueData, selected module id is '+moduleId);
// //  console.log('in getParameterValueData, selected parameter name is '+parameterName);
//     return (dispatch) => {
//         axios.get('/api/'+moduleId+'/parameter/'+parameterName)
//           .then((response)=>{
//             console.log('parameter value api response');
//             console.log(response.data);
//               dispatch({
//                 type:'PARAMETER_VALUE_LIST',
//                 parameterValueList:response.data
//               })
//           })
//     }
// };

export const getParameterValueData = (parameterName, moduleName) => {
  // console.log('in getParameterValueData, selected module id is '+moduleName);
  // console.log('in getParameterValueData, selected parameter name is '+parameterName);
  if (parameterName == "Location" || parameterName == "LOCATION") {
    return (dispatch) => {
      api.get(GLOBAL.profileCWLUrl)
        .then((response) => {
          console.log('cwl api response');
          console.log(response.data);
          let finalArray = [];
          response.data.forEach((item) => {
            let obj = {};
            obj.field1Key = item.LOCATION_NAME;
            finalArray.push(obj);
          })
          console.log('====finalArray');
          console.log(finalArray);
          dispatch({
            type: 'PARAMETER_VALUE_LIST',
            parameterValueList: finalArray
          })
        })
    }
  }
  else {
    let filter = '?field1Name=' + parameterName + '&module=' + moduleName;
    return (dispatch) => {
      axios.get('/rest/menuItems' + filter)
        .then((response) => {
          console.log('parameter value api response');
          console.log(response.data);
          dispatch({
            type: 'PARAMETER_VALUE_LIST',
            parameterValueList: response.data
          })
        })
    }
  }
};

// export const getCompanyStatus = () => {
//     return (dispatch) => {
//         axios.get('/api/status')
//           .then((response)=>{
//             console.log('company status api response');
//             console.log(response.data);
//               dispatch({
//                 type:'COMPANY_STATUS',
//                 companyStatus:response.data
//               })
//           })
//     }
// };

export const getCompanyStatus = () => {
  let filter = '?field1Name=Status&module=AMS';
  return (dispatch) => {
    axios.get('/rest/menuItems' + filter)
      .then((response) => {
        console.log('company status api response');
        console.log(response.data);
        dispatch({
          type: 'COMPANY_STATUS',
          companyStatus: response.data
        })
      })
  }
};

export const getCompanyService = (companyId, modulePageName) => {
  return (dispatch) => {
    axios.get('/companies/' + companyId)
      .then((response) => {
        console.log('company service api response');
        console.log(response.data);
        if (typeof modulePageName !== 'undefined' && modulePageName === 'AMScreate') {
          let typeaheadData = [];
          if (response.data !== '') {
            response.data.map((item) => {
              typeaheadData.push({ value: '' + item.OFFERING_ID, label: item.OFFERING_NAME });
            });
          }
          dispatch({ type: 'COMPANY_SERVICE_TYPE', typeaheadData });
        }
        dispatch({
          type: 'COMPANY_SERVICE',
          companyService: response.data
        })
      })
  }
};
export const disableValue = () => {
  let saveloader;
  saveloader = false;
  return saveloader;
}

// export const submitRuleConfiguration = (submitData) => {
//   let ruleId;
//   let moduleName = submitData.moduleName;

//   return (dispatch) => {
//     api.post(GLOBAL.sendAMSdata, submitData)
//       .then(function (response) {
//         console.log("Heidelburg University, Germany : ",response.data.data);
//         if (response.status == 201) {
//           console.log("LMU Germany : ",response.data.data,typeof (response.data.data));
//           if (typeof (response.data) == 'string') {
//             swal(response.data.responseMessage);
//             dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_4' });
//             return false;
//           }
//           else {
//             response.data.forEach((rule, i) => {
//               console.log("Item Log : ",rule);
//               if (rule.moduleName == moduleName)
//                 ruleId = rule.ruleId;
//             });
//             console.log("Rule ID : ",ruleId);
//             dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_4' });
//             return api.get(GLOBAL.getamseditdata + "/" + ruleId);
//           }
//         }
//       })
//       .then(function (response) {
//         if (response != false) {
//           let ruleConfigurationDetails = {
//             "ruleId": response.data.ruleId,
//             "ruleName": response.data.ruleName,
//             "event": response.data.eventId,
//             "eventName": response.data.eventName,
//             "module": response.data.moduleId,
//             "moduleName": response.data.moduleName,
//             "group": response.data.assignmentGroupId,
//             "groupName": response.data.assignmentGroupName,
//             "individual": response.data.assignmentMemberId,
//             "individualName": response.data.assignmentMemberName,
//             "company": response.data.companyId,
//             "companyName": response.data.companyName,
//             "associatedCompany": response.data.assignmentCompanyId,
//             "associatedCompanyName": response.data.assignmentCompanyName,
//             "companyStatus": response.data.statusId,
//             "companyStatusName": response.data.statusValue,
//             "weight": response.data.weight,
//             "companyService": response.data.serviceId,
//             "companyServiceName": response.data.serviceName,
//             "class": response.data.ciClassId,
//             "ciClassName": response.data.ciClassName,
//             "impactedCI": response.data.ciId,
//             "ciName": response.data.ciName,
//             "componentId": response.data.componentId,
//             "componentName": response.data.componentName,
//             "serviceId": "0",
//             "serviceBased":response.data.serviceBased
//           }
//           dispatch({
//             type: 'GET_RULE_CONFIGURATION_SUCCESS',
//             ruleConfigurationDetails: ruleConfigurationDetails
//           })
//           if (response.status == 200) {
//             return api.get(GLOBAL.getamseditdata + "/" + ruleId + "/ruleCondition");
//           }
//         }
//       })

//       .then(function (response) {
//         if (response != undefined || response != false) {
//           let keys = Object.keys(response.data);
//           let values = [];
//           for (var i in response.data) {
//             values.push(response.data[i]);
//           }
//           let finalArray = [];
//           for (let i = 0; i < keys.length; i++) {
//             let obj = {};
//             obj.paramName = keys[i];
//             obj.paramValue = values[i];
//             finalArray.push(obj);
//           }
//           dispatch({
//             type: 'GET_ALL_RULE_CONDITIONS',
//             getAllRuleConditions: finalArray
//           })
//           if (response.status == 200 || response.status == 204) {
//             browserHistory.push('/amsEditRules/' + ruleId);
//           }
//         }
//       })
//   }
// };

export const submitRuleConfiguration = (submitData) => {
  let ruleId;
  let moduleName = submitData.moduleName;
  return (dispatch) => {
    api.post(GLOBAL.sendAMSdata, submitData).then((response)=>{
        if (response.status == 201) {
        if(response.data.data === null){
          swal(response.data.responseMessage);
            dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_4' });
            return false;
        }else{
          response.data.data.forEach((rule, i) => {
            if (rule.moduleName == moduleName){
                ruleId = rule.ruleId;
            }
            });
            dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_4' });
            return api.get(GLOBAL.getamseditdata + "/" + ruleId);
          }
        }
    }).then((response)=>{
        if (response != false) {
          let ruleConfigurationDetails = {
            "ruleId": response.data.ruleId,
            "ruleName": response.data.ruleName,
            "event": response.data.eventId,
            "eventName": response.data.eventName,
            "module": response.data.moduleId+"",
            "moduleName": response.data.moduleName,
            "group": response.data.assignmentGroupId,
            "individual": response.data.assignmentMemberId,
            "individualName": response.data.assignmentMemberName,
            "company": response.data.companyId,
            "companyName": response.data.companyName,
            "associatedCompany": response.data.assignmentCompanyId,
            "associatedCompanyName": response.data.assignmentCompanyName,
            "companyStatus": response.data.statusId+'',
            "companyStatusName": response.data.statusValue,
            "weight": response.data.weight,
            "companyService": response.data.serviceId,
            "companyServiceName": response.data.serviceName,
            "class": response.data.ciClassId,
            "ciClassName": response.data.ciClassName,
            "impactedCI": response.data.ciId,
            "ciName": response.data.ciName,
            "componentId": response.data.componentId,
            "componentName": response.data.componentName,
            "serviceId": "0",
            "serviceBased":response.data.serviceBased,
            "isExternal":response.data.isExternal,
            "isContextual":response.data.isContextual,
            "contextualGroupFunctionName": response.data.groupType,
            "groupTypeId": response.data.groupTypeId,
            "contextualDropdown": response.data.isContextual?"contextual":"predefined",
            "alternateGroup": response.data['alternateGroupId'] || "",
            "alternateGroupName": response.data['alternateGroupName'] || "",
        };
        if(response.data.isContextual){
          ruleConfigurationDetails.contextualGroup=response.data.assignmentGroupName;
        }else{
          ruleConfigurationDetails.groupName=response.data.assignmentGroupName;
        }
          dispatch({
            type: 'GET_RULE_CONFIGURATION_SUCCESS',
            ruleConfigurationDetails: ruleConfigurationDetails
        });
        navigationHooks.navigate('/amsEditRules/' + ruleId);

          // if (response.status == 200) {
          //   return api.get(GLOBAL.getamseditdata + "/" + ruleId + "/ruleCondition");
          // }
        }
    })
    // .then((response)=>{
    //     if (response != undefined || response != false) {
    //       let keys = Object.keys(response.data);
    //       let values = [];
    //       for (var i in response.data) {
    //         values.push(response.data[i]);
    //       }
    //       let finalArray = [];
    //       for (let i = 0; i < keys.length; i++) {
    //         let obj = {};
    //         obj.paramName = keys[i];
    //         obj.paramValue = values[i];
    //         finalArray.push(obj);
    //       }
    //       dispatch({
    //         type: 'GET_ALL_RULE_CONDITIONS',
    //         getAllRuleConditions: finalArray
    //       })
    //       if (response.status == 200 || response.status == 204) {
    //         browserHistory.push('/amsEditRules/' + ruleId);
    //       }
    //     }
    //   })
  }
};

// export const editRuleConfiguration = (ruleId, submitData) => {
//   //console.log('date format= ' + date_format);
//   //console.log('time_zone= ' + time_zone);
//   return (dispatch) => {
//     //  axios.patch(constants.amsurl+'/rest/ruleConfiguration/'+ruleId, submitData
//     api.patch(GLOBAL.getamseditdata + "/" + ruleId, submitData

//       // , {
//       //     headers: {
//       //       "date_format": date_format,
//       //       "time_zone": time_zone
//       //     }
//       //   }
//     )
//       .then(function (response) {
//         console.log('edit rule configuration api response');
//         console.log("string", typeof (response.data));
//         if (typeof (response.data) == 'string') {
//           swal("Rule Exists for this Company/Module/Service/Component/Assignment for");
//           browserHistory.push('/amsHome');
//         }
//         else {
//           dispatch({
//             type: 'EDIT_RULE_CONFIGURATION_RESPONSE',
//             editRuleConfigurationResponse: response.data
//           })
//           if (response.status == 200)
//             browserHistory.push('/amsHome');
//         }
//       })
      //  }
// };

export const editRuleConfiguration = (ruleId, submitData, changeSaveStatus=(status)=>{}, updateIsChange = (flag) => {}) => {
  return (dispatch,getState) => {
    api.patch(GLOBAL.getAMSendEditdataNew + "/" + ruleId, submitData).then((response)=>{
      if(response.data.data===null){
        swal(response.data.responseMessage);
          changeSaveStatus(false);
          updateIsChange(false);
        //browserHistory.push('/amsHome');
      }else{
        let ruleCondition = [];
        if(response.data.data && response.data.data.amsAttribute && Array.isArray(response.data.data.amsAttribute)){
          (response.data.data.amsAttribute).forEach(item => {
            let obj = {};
            obj['paramName'] = item['amsAttributeDisplayValue'];
            obj['paramValue'] = item['amsAttributeValue'];
            ruleCondition.push(obj);
          });
        }
        let ruleConfigurationDetails = {
          ruleCondition: ruleCondition,
          "ruleId": response.data.data.ruleId,
          "ruleName": response.data.data.ruleName,
          "event": response.data.data.eventId,
          "eventName": response.data.data.eventName,
          "module": response.data.data.moduleId+"",
          "moduleName": response.data.data.moduleName,
          "group": response.data.data.assignmentGroupId,
          "individual": response.data.data.assignmentMemberId,
          "individualName": response.data.data.assignmentMemberName,
          "company": response.data.data.companyId,
          "companyName": response.data.data.companyName,
          "associatedCompany": response.data.data.assignmentCompanyId,
          "associatedCompanyName": response.data.data.assignmentCompanyName,
          "companyStatus": response.data.data.statusId+'',
          "companyStatusName": response.data.data.statusValue,
          "weight": response.data.data.weight,
          "companyService": response.data.data.serviceId,
          "companyServiceName": response.data.data.serviceName,
          "class": response.data.data.ciClassId,
          "ciClassName": response.data.data.ciClassName,
          "impactedCI": response.data.data.ciId,
          "ciName": response.data.data.ciName,
          "componentId": response.data.data.componentId,
          "componentName": response.data.data.componentName,
          "serviceBased":response.data.serviceBased,
          "isExternal":response.data.data.isExternal,
          "isContextual":response.data.data.isContextual,
          "contextualGroupFunctionName":response.data.data.groupType,
          "groupTypeId":response.data.data.groupTypeId,
          "contextualDropdown": response.data.data.isContextual?"contextual":"predefined",
          "alternateGroup": response.data.data['alternateGroupId'] || "",
          "alternateGroupName": response.data.data['alternateGroupName'] || "",
        }
        if(response.data.data.isContextual){
          ruleConfigurationDetails.contextualGroup=response.data.data.assignmentGroupName;
        }else{
          ruleConfigurationDetails.groupName=response.data.data.assignmentGroupName
        }
        dispatch({
          type: 'GET_RULE_CONFIGURATION_SUCCESS',
          ruleConfigurationDetails: ruleConfigurationDetails
        })
          dispatch({
            type: 'EDIT_RULE_CONFIGURATION_RESPONSE',
            editRuleConfigurationResponse: response.data
        });
        if (response.status == 200){
            // browserHistory.push('/amsHome');
            swal(getState().spcmReducer.tr['The AMS has been updated successfully']);
            changeSaveStatus(false);
            updateIsChange(false);
        }else{
            changeSaveStatus(false);
            updateIsChange(false);
        }
      }
      })
  }
}

export const getRuleConfiguration = (ruleId) => {
  return (dispatch) => {
    //  console.log('in get rule configuration by rule id action');
    api.get(GLOBAL.getamseditdata + "/" + ruleId)
      .then(function (response) {
           console.log('get rule configuration response');
         console.log(response);
        let ruleConfigurationDetails = {
          "ruleId": response.data.ruleId,
          "ruleName": response.data.ruleName,
          "event": response.data.eventId,
          "eventName": response.data.eventName,
          "module": response.data.moduleId+"",
          "moduleName": response.data.moduleName,
          "group": response.data.assignmentGroupId,
          "individual": response.data.assignmentMemberId,
          "individualName": response.data.assignmentMemberName,
          "company": response.data.companyId,
          "companyName": response.data.companyName,
          "associatedCompany": response.data.assignmentCompanyId,
          "associatedCompanyName": response.data.assignmentCompanyName,
          "companyStatus": response.data.statusId+'',
          "companyStatusName": response.data.statusValue,
          "weight": response.data.weight,
          "companyService": response.data.serviceId,
          "companyServiceName": response.data.serviceName,
          "class": response.data.ciClassId,
          "ciClassName": response.data.ciClassName,
          "impactedCI": response.data.ciId,
          "ciName": response.data.ciName,
          "componentId": response.data.componentId,
          "componentName": response.data.componentName,
          "serviceBased":response.data.serviceBased,
          "isExternal":response.data.isExternal,
          "isContextual":response.data.isContextual,
          "contextualGroupFunctionName": response.data.groupType,
          "groupTypeId": response.data.groupTypeId,
          "contextualDropdown": response.data.isContextual?"contextual":"predefined",
          "alternateGroup": response.data['alternateGroupId'] || "",
          "alternateGroupName": response.data['alternateGroupName'] || "",
        }
        if(response.data.isContextual){
          ruleConfigurationDetails.contextualGroup=response.data.assignmentGroupName;
        }else{
          ruleConfigurationDetails.groupName=response.data.assignmentGroupName
        }
        dispatch({
          type: 'GET_RULE_CONFIGURATION_SUCCESS',
          ruleConfigurationDetails: ruleConfigurationDetails
        })
        // if (response.status == 200 || response.status == 201) {
        //   return api.get('/api/serviceportfolio/companylist?userId=')
        // }
      })
      .then((response) => {
        // console.log('company api response');
        // console.log(response.data);
        // dispatch({
        //   type: 'COMPANY_LIST',
        //   companyList: response.data
        // })
        navigationHooks.navigate('/amsEditRules/' + ruleId);

       // if (response.status == 200) {
        //   return api.get(GLOBAL.getamseditdata + "/" + ruleId + "/ruleCondition");
        // }
      })
      // .then(function (response) {
      //   console.log('get all rule conditions api response');
      //   console.log(response);
      //   let keys = Object.keys(response.data);
      //   console.log('keys=', keys);
      //   let values = [];
      //   for (var i in response.data) {
      //     values.push(response.data[i]);
      //   }
      //   console.log('values=', values);
      //   let finalArray = [];
      //   for (let i = 0; i < keys.length; i++) {
      //     let obj = {};
      //     obj.paramName = keys[i];
      //     obj.paramValue = values[i];
      //     finalArray.push(obj);
      //   }
      //   console.log('final array of rule conditions====', finalArray);
      //   dispatch({
      //     type: 'GET_ALL_RULE_CONDITIONS',
      //     getAllRuleConditions: finalArray
      //   })
      //   if (response.status == 200 || response.status == 204) {
      //     browserHistory.push('/amsEditRules/' + ruleId);
      //   }
      // })
  }
};

// export const submitRuleCondition = (submitData) => {
//   console.log('New :::::::: submitRuleCondition. ');
//   return (dispatch) => {

//     dispatch({type: "LOADER_IN_PROGRESS"});
//       axios.post(GLOBAL.getamsruleconditiondata, submitData
//       /*  , {
//           headers: {
//             "date_format": date_format,
//             "time_zone": time_zone
//           }
//         }*/
//       )
//         .then(function (response) {
//           console.log('submit rule condition api response');
//           console.log(response);
//           // dispatch({type: "LOADER_IN_PROGRESS"});
//           return axios.get(GLOBAL.getamseditdata+"/"+submitData.ruleId+"/ruleCondition");

//         })
//         .then(function (response) {
//           console.log('get all rule conditions api response');
//           console.log(response);
//           let keys = Object.keys(response.data);
//           console.log('keys=',keys);
//           let values=[];
//           for(var i in response.data) {
//             values.push(response.data[i]);
//           }
//           console.log('values=',values);
//           let finalArray=[];
//           for(let i=0;i<keys.length;i++) {
//             let obj={};
//             obj.paramName=keys[i];
//             obj.paramValue=values[i];
//             finalArray.push(obj);
//           }
//           console.log('final array of rule conditions====',finalArray);
//           dispatch({
//             type:'GET_ALL_RULE_CONDITIONS',
//             getAllRuleConditions:finalArray
//           })
//           dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY'});

//         })
//         .catch((err) => {
//           dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
//         })
//     }
// };

export const submitRuleCondition = (ruleId, submitData) => {
  console.log('New :::::::: submitRuleCondition. ');
  return (dispatch) => {

    dispatch({ type: "LOADER_IN_PROGRESS" });
    // axios.post(GLOBAL.getamsruleconditiondata, submitData
    axios.patch(GLOBAL.getamseditdata + "/" + ruleId + "/ruleCondition", submitData
      /*  , {
          headers: {
            "date_format": date_format,
            "time_zone": time_zone
          }
        }*/
    )
      // .then(function (response) {
      //   console.log('submit rule condition api response');
      //   console.log(response);
      //   // dispatch({type: "LOADER_IN_PROGRESS"});
      //   return axios.get(GLOBAL.getamseditdata+"/"+ruleId+"/ruleCondition");
      // })
      .then(function (response) {
        // console.log('get all rule conditions api response');
        console.log('submit rule condition api response');
        console.log(response);
        let keys = Object.keys(response.data);
        console.log('keys=', keys);
        let values = [];
        for (var i in response.data) {
          if (response.data.hasOwnProperty(i)) {
          values.push(response.data[i]);
          }
        }
        console.log('values=', values);
        let finalArray = [];
        for (let i = 0; i < keys.length; i++) {
          let obj = {};
          obj.paramName = keys[i];
          obj.paramValue = values[i];
          finalArray.push(obj);
        }
        console.log('final array of rule conditions====', finalArray);
        dispatch({
          type: 'GET_ALL_RULE_CONDITIONS',
          getAllRuleConditions: finalArray
        })
        dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY' });

      })
      .catch((err) => {
        dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
      })
  }
};

export const deleteRuleCondition = (ruleId, paramName) => {
  return (dispatch) => {
    dispatch({ type: "LOADER_IN_PROGRESS" });
    // axios.delete('/rest/ruleCondition/'+conditionId)
    axios.delete(GLOBAL.getamseditdata + "/" + ruleId + "/ruleCondition/parameter/" + paramName)
      .then(function (response) {
        console.log('delete rule condition api response');
        console.log(response);
        // if (response.status == 200)
        //   return axios.get(GLOBAL.getamseditdata + "/" + ruleId + "/ruleCondition");
      })
      // .then(function (response) {
      //   console.log('get all rule conditions api response');
      //   console.log(response);
      //   let keys = Object.keys(response.data);
      //   console.log('keys=', keys);
      //   let values = [];
      //   for (var i in response.data) {
      //     values.push(response.data[i]);
      //   }
      //   console.log('values=', values);
      //   let finalArray = [];
      //   for (let i = 0; i < keys.length; i++) {
      //     let obj = {};
      //     obj.paramName = keys[i];
      //     obj.paramValue = values[i];
      //     finalArray.push(obj);
      //   }
      //   dispatch({
      //     type: 'GET_ALL_RULE_CONDITIONS',
      //     getAllRuleConditions: finalArray
      //   })
      //   dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
      // })
  }
};

// export const getRuleCondition = (conditionId,moduleName) => {
//   console.log('in getRuleCondition, selected module name is '+moduleName);
//   console.log('in getRuleCondition, selected condition id is '+conditionId);
//     return (dispatch) => {
//       axios.get(GLOBAL.getamsruleconditiondata+"/"+conditionId)
//         .then(function (response) {
//           console.log('get rule condition api response');
//           console.log(response);
//           dispatch({
//             type:'GET_RULE_CONDITION_RESPONSE',
//             getRuleConditionResponse:response.data
//           })
//           // return axios.get('/api/'+moduleId+'/parameter/'+response.data.paramName);
//           return axios.get('/rest/menuItems'+'?field1Name='+response.data.paramName+'&module='+moduleName);
//         })
//         .then((response)=>{
//           console.log('parameter value api response');
//           console.log(response.data);
//             dispatch({
//               type:'PARAMETER_VALUE_LIST',
//               parameterValueList:response.data
//             })
//         })
//     }
// };

export const getRuleCondition = (paramName, paramValue, moduleName) => {
  if (paramName == "Location" || paramName == "LOCATION") {
    return (dispatch) => {
      let conditionData = {};
      conditionData.paramName = paramName;
      conditionData.paramValue = paramValue;
      dispatch({
        type: 'GET_RULE_CONDITION_RESPONSE',
        getRuleConditionResponse: conditionData
      })
      api.get(GLOBAL.profileCWLUrl)
        .then((response) => {
          console.log('cwl api response');
          console.log(response.data);
          let finalArray = [];
          response.data.forEach((item) => {
            let obj = {};
            obj.field1Key = item.LOCATION_NAME;
            finalArray.push(obj);
          })
          console.log('====finalArray');
          console.log(finalArray);
          dispatch({
            type: 'PARAMETER_VALUE_LIST',
            parameterValueList: finalArray
          })
        })
    }
  }
  else {
    return (dispatch) => {
      let conditionData = {};
      conditionData.paramName = paramName;
      conditionData.paramValue = paramValue;
      dispatch({
        type: 'GET_RULE_CONDITION_RESPONSE',
        getRuleConditionResponse: conditionData
      })
      axios.get('/rest/menuItems' + '?field1Name=' + paramName + '&module=' + moduleName)
        .then((response) => {
          console.log('parameter value api response');
          console.log(response.data);
          dispatch({
            type: 'PARAMETER_VALUE_LIST',
            parameterValueList: response.data
          })
        })
    }
  }
};

export const editRuleCondition = (ruleId, submitData) => {
  //  console.log('date format= ' + date_format);
  //  console.log('time_zone= ' + time_zone);
  return (dispatch) => {
    dispatch({ type: "LOADER_IN_PROGRESS" });
    // axios.patch(GLOBAL.getamsruleconditiondata+"/"+conditionId, submitData
    axios.patch(GLOBAL.getamseditdata + "/" + ruleId + "/ruleCondition", submitData
      // , {
      //     headers: {
      //       "date_format": date_format,
      //       "time_zone": time_zone
      //     }
      //   }
    )
      // .then(function (response) {
      //   console.log('edit rule condition api response');
      //   console.log(response);
      //   if(response.status==201) {
      //     return axios.get(GLOBAL.getamseditdata+"/"+ruleId+"/ruleCondition");
      //   }
      // })
      .then(function (response) {
        // console.log('get all rule conditions api response');
        console.log('edit rule condition api response');
        console.log(response);
        let keys = Object.keys(response.data);
        console.log('keys=', keys);
        let values = [];
        for (var i in response.data) {
          if (response.data.hasOwnProperty(i)) {
          values.push(response.data[i]);
          }
        }
        console.log('values=', values);
        let finalArray = [];
        for (let i = 0; i < keys.length; i++) {
          let obj = {};
          obj.paramName = keys[i];
          obj.paramValue = values[i];
          finalArray.push(obj);
        }
        dispatch({
          type: 'GET_ALL_RULE_CONDITIONS',
          getAllRuleConditions: finalArray
        })
        dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
      })
  }
};

export const getAllRuleConfigurationsData = (searchParams, module, assignmentFor, supGroup, status, fetchGroups = false) => {
  let statusVal = status.length == 0 ? "Active" : status.toString();
  let moduleVal = module.toString();
  let assignmentForVal = assignmentFor.toString();
  let supGroupVal = supGroup.toString();

  let searchKeyArr = [];
  let searchValueArr = [];
  let patternArr = [];

  if (statusVal.length != 0) {
    searchKeyArr.push("statusValue");
    searchValueArr.push(statusVal);
    patternArr.push("in");
  }
  if (assignmentForVal.length != 0) {
    searchKeyArr.push("eventName");
    searchValueArr.push(assignmentForVal);
    patternArr.push("in");
  }
  if (module.length != 0) {
    searchKeyArr.push("moduleName");
    searchValueArr.push(moduleVal);
    patternArr.push("in");
  }
  if (supGroupVal.length != 0) {
    searchKeyArr.push("assignmentGroupName");
    searchValueArr.push(supGroupVal);
    patternArr.push("in");
  }

  if (searchKeyArr.length != 0) {
    searchParams.searchByList = searchParams.searchByList != "" ? searchParams.searchByList + "," + searchKeyArr.join(",") : searchKeyArr.join(",");
    searchParams.multipleValueList = searchParams.multipleValueList != "" ? searchParams.multipleValueList + "|" + searchValueArr.join("|") : searchValueArr.join("|");
    searchParams.patternList = searchParams.patternList != "" ? searchParams.patternList + "," + patternArr.join(",") : patternArr.join(",");
  }

  searchParamsForSupportGroup = Object.assign({}, searchParams);

  return (dispatch) => {
    dispatch({ type: 'AMS_BOARD_LOADER_IN_PROGRESS' });
    api.post(GLOBAL.getamslist, searchParams)
      .then((amsRuleList) => {
        dispatch({
          type: 'GET_ALL_RULE_CONFIGURATIONS',
          getAllRuleConfigurations: amsRuleList.data
        });
        dispatch({ type: 'AMS_BOARD_LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
        if (fetchGroups) {
          dispatch(getAssignmentGroups());
       }
      })
      .catch((err) => {
        dispatch({ type: 'AMS_BOARD_LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
      });
  };
  // return (dispatch) => {
  //   dispatch({ type: "AMS_BOARD_LOADER_IN_PROGRESS" });
  //   api.post(GLOBAL.getamslist, searchParams)
  //     .then((response) => {
  //       dispatch({
  //         type: 'GET_ALL_RULE_CONFIGURATIONS',
  //         getAllRuleConfigurations: response.data
  //       });
  //       dispatch({ type: 'AMS_BOARD_LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
  //     });
  // };
};

export const getAssignmentGroups = () => {
  searchParamsForSupportGroup.sortBy = "statusValue";
  searchParamsForSupportGroup.multipleValueList = "Active,InActive";
  delete searchParamsForSupportGroup.currentPage;
  delete searchParamsForSupportGroup.size;
  return (dispatch) => {
    api.post("/assignmentGroups", searchParamsForSupportGroup)
      .then((assignmentGroupsList) => {
        dispatch({
          type: 'GET_ALL_ASSIGNEMNT_GROUPS',
          getAllAssignmentGroups: assignmentGroupsList.data
        });
      })
      .catch((err) => {
        /*SKIP*/
      });
  };
};

export const resetFormDropdownValues = () => {
  return (dispatch) => {
    dispatch({
      type: 'CLASS_LIST',
      classList: []
    })
    dispatch({
      type: 'IMPACTED_CI_LIST',
      impactedCIList: []
    })
    dispatch({
      type: 'ASSOCIATED_COMPANY_LIST',
      associatedCompanyList: []
    })
    dispatch({
      type: 'ASSOCIATED_COMPANY_LIST_TYPE',
      typeaheadData: []
    })
    dispatch({
      type: 'GROUP_LIST',
      groupList: []
    })
    dispatch({
      type: 'GROUP_LIST_TYPE',
      typeaheadData: []
    })
    dispatch({
      type: 'INDIVIDUAL_LIST',
      individualList: []
    })
    dispatch({
      type: 'EVENT_LIST',
      eventList: []
    })
    dispatch({
      type: 'PARAMETER_LIST',
      parameterList: []
    })
    dispatch({
      type: 'PARAMETER_VALUE_LIST',
      parameterValueList: []
    })
    dispatch({
      type: 'COMPANY_SERVICE',
      companyService: []
    })
    dispatch({
      type: 'COMPANY_SERVICE_TYPE',
      typeaheadData: []
    })
    dispatch({
      type: 'COMPONENT_PACKAGE',
      components: { data: [] }
    })
    dispatch({
      type: 'GROUP_FUNCTION_LIST',
      groupFunctionList: []
    })
  }
};

export const emptyAssignmentDetails = () => {
  return (dispatch) => {
    dispatch({
      type: 'ASSOCIATED_COMPANY_LIST',
      associatedCompanyList: []
    })
    dispatch({
      type: 'GROUP_LIST',
      groupList: []
    })
    dispatch({
      type: 'INDIVIDUAL_LIST',
      individualList: []
    })
  }
};
export const loaderStarted = () => {
  console.log("LOader started...");
  return (dispatch) => {
    dispatch({ type: "LOADER_IN_PROGRESS_4" });
  }
};
export const loaderEnded = () => {
  return (dispatch) => {
    dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY_4" });
  }
};
export const getAmsFilteredData = (filter) => {
  console.log("getAmsFilteredData::", filter)
  return (dispatch) => {
    dispatch({ type: "AMS_BOARD_LOADER_IN_PROGRESS" });
    api.get(GLOBAL.getAmsFilteredList + "?" + filter)
      .then((response) => {
        console.log('get all rule configurations api response');
        console.log(response.data);
        dispatch({
          type: 'GET_ALL_RULE_CONFIGURATIONS',
          getAllRuleConfigurations: response.data
        })
        dispatch({ type: 'AMS_BOARD_LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
      })

  }
};

const normalizeAttributesList = (attributes) => {
  let arr = [];
  try{
    attributes.forEach(item => {
      arr.push({ id: item.field1Value, name: item.field1Key });
    });
    return arr;
  }catch(e){
    return [];
  }
}

const normalizeOperatorList = (operators) => {
  let arr = [];
  try{
    operators.forEach(item => {
      arr.push({ id: item.field1Value, name: item.field1Key });
    });
    return arr;
  }catch(e){
    return [];
  }
}

const normalizeAMSAttributesList = (amsAttributesList) => {
  let arr = [];
  try{
    amsAttributesList.forEach((item, index) => {
      const obj = {
        // id: item['amsAttributeId'] || '',
        attrName: item['amsAttributeDisplayValue'] || '',
        attrId: item['amsAttributeDisplayId'] || '',
        operatorName: item['amsOperator'] || '',
        operatorId: item['amsOperatorId'] || '',
        attrValue: item['amsAttributeValue'] || '',
        attrValueId: item['amsAttributeValueId'] || '',
        attrJoinCondition: item['amsJoinCondition'] || '',
        id: `${index+1}_||_${item['amsAttributeDisplayValue'] || ''}_||_${item['amsAttributeValue'] || ''}`
      };
      arr.push(obj);
    });
    return arr;
  }catch(e){
    return [];
  }
}
const checkClassAttribute=(list)=>{
  console.log("classcjhec",list);
  // let isClassExist=false;
  let result=[];
  try{
   list.map(item=>{
    console.log("classcjhec",item.attrName=="Class");
    if(item.attrName=="Class")
    {
      // isClassExist=true;
    
    axios.get("/api/config_items/ciDetailsByClassId?class_id=" + item.attrValueId).then((response)=>{
          response.data.map((item)=>{
            result.push({id:item.CI_ID, label:item.CI_NAME});
       });
       
      
    });
    }
   })
   return(result);
  }
  catch(e)
  {
    return false
  }
}
export const getAttributesList = (moduleName) => {
  return (dispatch) => {
    dispatch({
      type: 'ATTRIBUTES_LIST_AMS_SUCCESS',
      attributesList: []
    });
    dispatch({
      type: 'AMS_LOADER_LOADING'
    });
    axios.get(`${GLOBAL.getAttributesList_ams}?module=${moduleName}&field1Name=attribute`)
      .then((response) => {
        // console.log({response});
        dispatch({
          type: 'ATTRIBUTES_LIST_AMS_SUCCESS',
          attributesList: normalizeAttributesList(response.data) || []
        });
        dispatch({
          type: 'AMS_LOADER_SUCCESS'
        });

      }).catch(error => {
        dispatch({
          type: 'ATTRIBUTES_LIST_AMS_SUCCESS',
          attributesList: []
        });
        dispatch({
          type: 'AMS_LOADER_SUCCESS'
        });
      })
  }
};

export const getOperatorsList = (moduleName) => {
  return (dispatch) => {
    dispatch({
      type: 'OPERATORS_LIST_AMS_SUCCESS',
      operatorsList: []
    });
    dispatch({
      type: 'AMS_LOADER_LOADING'
    });
    axios.get(`${GLOBAL.getOperatorsList_ams}?field1Name=operator&module=AMS`)
      .then((response) => {
        // console.log({response});
        dispatch({
          type: 'OPERATORS_LIST_AMS_SUCCESS',
          operatorsList: normalizeOperatorList(response.data) || []
        });
        dispatch({
          type: 'AMS_LOADER_SUCCESS'
        });

      }).catch(error => {
        dispatch({
          type: 'OPERATORS_LIST_AMS_SUCCESS',
          operatorsList: []
        });
        dispatch({
          type: 'AMS_LOADER_SUCCESS'
        });
      })
  }
};

export const getAMSAttributeList = (amsid = "") => {
  return (dispatch) => {
    dispatch({
      type: 'AMS_ATTRIBUTES_LIST_AMS_SUCCESS',
      amsAttributesList: []
    });
    dispatch({
      type: 'AMS_LOADER_LOADING'
    });
    // amsid = "206";
    return axios.get(`${GLOBAL.getAMSAttributeList_ams}/${amsid}`)
      .then((response) => {
        // console.log({response});
        const list = normalizeAMSAttributesList(response.data) || [];
        const isClassExist=checkClassAttribute(list);
        console.log("isClassExist",isClassExist);
        dispatch({
          type: 'AMS_ATTRIBUTES_LIST_AMS_SUCCESS',
          amsAttributesList: list
        });
        dispatch({
          type:'CLASS_EXIST_INLIST',
          isClass:isClassExist
        })
        dispatch({
          type: 'AMS_LOADER_SUCCESS'
        });
        return list;

      }).catch(error => {
        dispatch({
          type: 'AMS_ATTRIBUTES_LIST_AMS_SUCCESS',
          amsAttributesList: []
        });
        dispatch({
          type: 'AMS_LOADER_SUCCESS'
        });
        return Promise.reject(error.message || 'ERROR');
      })
  }
};

export const getAMSAttributesOnly = (ruleId)=>{
  return (dispatch) => {
    axios.get(GLOBAL.getAMSAttributeList_ams+'/'+ruleId).then((response) => {
      let collectedAttributes=[], attributeRecord;
      response.data.map((item)=>{
        attributeRecord={paramName: item.amsAttributeDisplayValue, paramValue: item.amsAttributeValue};
        collectedAttributes.push(attributeRecord);
      })
      dispatch({
        type: 'GET_RULE_CONDITION_RESPONSE',
        getRuleConditionResponse: collectedAttributes
      });
    });
  }
};
export const deleteAMSAttribute = (amsAttrId = "") => {
  return (dispatch) => {
    dispatch({
      type: 'AMS_LOADER_LOADING'
    });
    return axios.delete(`${GLOBAL.deleteAMSAttribute_ams}/${amsAttrId}`)
      .then((response) => {
        // console.log('response', {response});
        dispatch({
          type: 'AMS_LOADER_SUCCESS'
        });
        return response;

      }).catch(error => {
        // console.log('error - ', {error, message: error.message})
        dispatch({
          type: 'AMS_LOADER_SUCCESS'
        });
        return Promise.reject(error.message || 'ERROR');
      })
  }
};

export const resetAttributeList = ()=>{
  return (dispatch) => {
    dispatch({
      type: 'GET_RULE_CONDITION_RESPONSE',
      getRuleConditionResponse: []
    })
  }
};

export const getGroupFunctionData = (companyId) => {
  return (dispatch) => {
    axios.get('/api/getGroupFunction/' + companyId)
      .then((response) => {
        console.log('Group Function api response');
        console.log(response.data);
        let grpFundata = []
        if (response.data !== "") {
          grpFundata = response.data.map((option) => {
            return {
              label: option.field1Key,
              value: option.field1Value
            }
          }) 
        }
        
        dispatch({
          type: 'GROUP_FUNCTION_LIST',
          groupFunctionList: grpFundata
        })
      })
  }
};