
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import {Row,Col, Form } from 'react-bootstrap';
import { connect, useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { _inputField } from '../../../../common/ReduxFormFields/CategoryBoard/formFields';

let SystemInfoForm = () => {
    const tr = useSelector((state) => state.spcmReducer.tr);
    return (

            <Row className="padding-10 tabFormDv">
            <Col md={4} sm={6} xs={12}>
                <Form.Group className="form-group">
                    <Form.Label>Created Date</Form.Label>
                    <Field
                        name="createDate"
                        className={"form-control"}
                        component={_inputField}                       
                        disabled = {true}
                    />
                </Form.Group>
                </Col>
                <Col md={4} sm={6} xs={12}>
                <Form.Group className="form-group">
                    <Form.Label>Created By</Form.Label>
                    <Field
                        name="createdBy"
                        className={"form-control"}
                        component={_inputField}                       
                        disabled = {true}

                    />
                </Form.Group>
                </Col>
                <Col md={4} sm={6} xs={12}>
                <Form.Group className="form-group">
                    <Form.Label>Modified Date</Form.Label>
                    <Field
                        name="lastUpdateDate"
                        className={"form-control"}
                        component={_inputField}                       
                        disabled = {true}

                    />
                </Form.Group>
                </Col>
                <Col md={4} sm={6} xs={12}>
                <Form.Group className="form-group">
                    <Form.Label>Modified By</Form.Label>
                    <Field
                        name="lastUpdatedBy"
                        className={"form-control"}
                        component={_inputField}                       
                        disabled = {true}

                    />
                    </Form.Group>

                </Col>
                </Row>
    )
}

SystemInfoForm = reduxForm({
    form: "EditUserform",
    enableReinitialize: true,
  })(SystemInfoForm);
  SystemInfoForm = connect(({ editDetailsRed }) => ({
    // initialValues: {
    //   lastUpdatedBy: editDetailsRed?.lastUpdatedBy,
    //   lastUpdateDate: editDetailsRed?.lastUpdateDate,
    //   createdBy: editDetailsRed?.createdBy,
    //   createDate: editDetailsRed?.createDate,      
    // },
  }))(SystemInfoForm);
  
export default SystemInfoForm;