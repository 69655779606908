
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import { GLOBAL }  from '../../components/Globals';

export function loadBreakFixSourceNameSuccess(breakFixSourceName){
	return {
		type: 'LOAD_BREAKFIXSOURCENAME_SUCCESS',
		breakFixSourceName
	};
}

export function loadBreakFixSourceNameFailure(errorResponse){
	return {
		type: 'LOAD_BREAKFIXSOURCENAME_FAILURE',
		errorResponse
	};
}
//offering is field1Value
export function loadBreakFixSourceName(){
	return(dispatch) =>{
			axios.get(GLOBAL.breakFixSourceUrl)
				.then((breakFixSourceName)=>{
					if(!breakFixSourceName){
						//dispatch(loadBreakFixFailure(breakFix.statusText))
						throw Error(breakFixSourceName.statusText);
					}
					return breakFixSourceName;
				})
				.then((breakFixSourceName) =>{
					dispatch(loadBreakFixSourceNameSuccess(breakFixSourceName))
				})
				.catch((err) => {
					console.log(err);
				});

	};
}
