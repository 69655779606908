
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Table from 'react-bootstrap/Table';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadBreakFixSLALogDetails, reprocessingSlaData } from '../../../../actions/breakFix/breakFixSLALogAction';
import { loadBreakFixSLAResponseLogDetails } from '../../../../actions/breakFix/breakFixSLAResponseLogAction';
import { loadBreakFixSLAResolutionLogDetails } from '../../../../actions/breakFix/breakFixSLAResolutionLogAction';
import Spinner from 'react-spinkit';
import constants from '../../../../utils/constants';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import SlaInfoPopup from '../../../common/WorkItemBoard/SlaInfoPopup';
import { getColorCode } from '../../../../utils/common';
import { HiOutlineRefresh } from 'react-icons/hi';
import {IoClose} from "react-icons/io5";
import {ImInfo} from "react-icons/im";
import axios from 'axios';
import Swal from 'sweetalert2';
import { LuTimerReset } from "react-icons/lu";
import Cookies from "universal-cookie";
const cookies = new Cookies();
let cookiesArray = cookies.get('gph');
if (cookiesArray) cookiesArray = cookiesArray.replace('s:', '');
if (cookiesArray) cookiesArray = cookiesArray.substring(0, cookiesArray.lastIndexOf('.'));
cookiesArray = cookiesArray.split("~");
let langDir = cookiesArray[66];
class XSMSLA extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      isRefBtnPresent: true,
      reprocessingStatus: "Start",
      
    };
    this.refreshTable = this.refreshTable.bind(this);
    this.renderReprocessingData = this.renderReprocessingData.bind(this);
  }

  componentWillMount() {
    axios.get(`/api/refreshMeasurement/${this.props.breakFixEditDetails.breakfixNumber}`).then(res=>{
    this.props.loadBreakFixSLALogDetails(constants.slamodvalbreakfix, this.props.breakFixEditDetails.breakfixNumber);
    });
    //  this.props.loadBreakFixSLALogDetails("1","1");

  }

  componentDidMount() {
    setTimeout(() => this.setState({ loading: false }), 4500); // simulates an async action, and hides the spinner
  }

  refreshTable(){
   axios.get(`/api/refreshMeasurement/${this.props.breakFixEditDetails.breakfixNumber}`).then(res=>{
    this.props.loadBreakFixSLALogDetails(constants.slamodvalbreakfix, this.props.breakFixEditDetails.breakfixNumber);
    this.setState({loading:true});
   })
   setTimeout(() => this.setState({ loading: false }), 3000);
   this.setState({isRefBtnPresent:false});
  setTimeout(()=>{
    this.setState({isRefBtnPresent:true});
  },1000*60);
  }

  renderReprocessingData() {
    // const id = window.location.href.split("/").pop();
    Swal.fire({
      title: ["Are you sure you want to Reprocess SLA?"],
      width: 400,
      padding: '13px',
      showDenyButton: true,
      confirmButtonText: this.props.translator['Yes'],
      denyButtonText: this.props.translator['No'],
      customClass: {
        title: "justifyText"
      }
    }).then((result) => {
      if (result.isConfirmed) {
        this.props.reprocessingSlaData(this.props.breakFixEditDetails.breakfixNumber, constants.slamodvalbreakfix);
      } else if (result.isDenied) {

      }
    })
    // this.setState({reprocessingStatus:"In Progress"});
  }

  renderTimeElapsed(typeData) {
    //console.log(classData)
    if (!typeData) {
      return (
        <td className="blue"></td>
      );
    }
    return typeData.map((typeObj) => {

      let h = Math.floor(typeObj.timeElapsed / 60);
      if (h < 10 || h == 0)
        h = "0" + h;
      let m = typeObj.timeElapsed % 60;
      //console.log(h+":"+m);
      if (m < 10 || m == 0)
        m = "0" + m;
      let time = h + ":" + m

      return (
        <td className="blue">{time} Hours</td>
      );
    });
  }


  renderSLATable(slaData) {
  
    if (slaData.length == 0) {
      return null;
    }
    else {
      return slaData.map((sla, index) => {


        let h = Math.floor(sla.allocatedTime / 60);
        if (h < 10 || h == 0)
          h = "0" + h;
        let m = parseInt(sla.allocatedTime % 60, 10);

        if (m < 10 || m == 0)
          m = "0" + m;
        //console.log(h+":"+m);
        let time = h + ":" + m

        let slastatus = ""
        if(sla.status==null || sla.status==undefined)
          slastatus=""
        else if (sla.status == "IN PROGRESS")
          slastatus = "In Progress";
        else if (sla.status == "ALREADY BREACHED" || sla.status == "BREACHED")
          slastatus = "Breached";
        else if (sla.status == "MET")
          slastatus = "Met";
        else if (sla.status == "DELINKED")
          slastatus = "Delinked";
        else if (sla.status != "IN PROGRESS" && sla.status != "ALREADY BREACHED" && sla.status != "BREACHED" && sla.status != "MET")
          slastatus = sla.status

        let hleft = Math.floor(sla.timeLeft / 60);

        if (hleft < 10 || hleft == 0)
          hleft = "0" + hleft;
        let mleft = sla.timeLeft % 60;
        if (mleft < 10 || mleft == 0)
          mleft = "0" + mleft;
        //console.log(h+":"+m);
        let timeleft = hleft + ":" + mleft
 
        const tooltip = (
          <Popover className="userInfoPopup">
            <SlaInfoPopup slaDetail={sla}/>
          </Popover>
        );
        return (
          <tr key={index}>
            <td className="noWrapName"><OverlayTrigger trigger="click" rootClose placement={this.props.windowSize.width < 768 ? "auto-start" : (langDir === "rtl" ? "right-start" : "left-start")
} key={sla.slaId} overlay={tooltip}><span title="info" className="cursorPoint">{sla.slaName}{" "}<ImInfo /><div><small>{sla.slaType}</small></div></span></OverlayTrigger></td>
            <td>{time} Hours</td>
            <td className="noWrapName">{getColorCode(sla.status)}{" "}{slastatus}</td>
            <td>
              {(slastatus && slastatus.toLowerCase() === 'invalid') ? 'N/A' : sla.duedate}
            </td>

          </tr>
        );
      });
    }
  }
  render() {
 
    const { loading } = this.state;
    if (loading) {
      return (
        <div>
          <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
            <ul>
              <li>
              <button
                  type='button'
                title="Minimize right Panel"
                bsClass=""
                bsStyle=""
                className="closerightPanelBtn"
                onClick={() => {
                this.props.rightEditPanel(false);
                }}
              >
                <IoClose />
              </button>
              </li>
            </ul>
          </div>
          <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">SLA</div></div>
          <div className='rBoxGap'>
            {/* {this.props.translator['Loading SLA']}... */}
            <Spinner spinnerName='three-bounce' />
          </div>
        </div>
      );
    }
 
    if (loading == false && this.props.breakFixSLALogDetails.length == 0) {
      return (
        <div>
          <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
            <ul>
              <li>
              <button
                  type='button'
                title="Minimize the right panel"
                bsClass=""
                bsStyle=""
                className="closerightPanelBtn"
                onClick={() => {
                this.props.rightEditPanel(false);
                }}
              >
                <IoClose />
              </button>
              </li>
            </ul>
          </div>
          <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">SLA</div></div>
          <div className='rBoxGap'>
            {this.props.translator['Currently no sla exist']}
          </div>
        </div>
      );
    }
    return (
      <div className="">
        <div className="rPageHeadActBtn" style={{ marginTop: "-1px" }}>
          <ul>
         {this.props.role_id.split(",").includes("1") ?( this.props.showLoader_6.loading ? "" : <li><Button onClick={() => { this.renderReprocessingData() }}
                title='Reprocess SLA'
                bsPrefix=' '
                style={{fontSize:"18px",marginTop: "-3px"}}
                >
                <LuTimerReset />
        </Button></li>) : ""}
          {this.state.isRefBtnPresent &&<li>
              {!this.props.showLoader_6.loading ?<a
                title={this.props.translator["Refresh"]}
                href={void 0}
                onClick={() => {
                  this.refreshTable();
                }}
              >
                <HiOutlineRefresh className="refreshFlip" />
              </a> : ""}
            </li>}
          </ul>
        </div>
        <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">SLA</div></div>
        <div className='rBoxGap'>
          {this.props.showLoader_6.loading ?<Spinner spinnerName='three-bounce' />: <Table responsive striped bordered condensed hover className="f-size-13">
            <thead>
              <tr className="f-size-12">
                <th>Name</th>
                <th>SLA</th>
                <th>Status</th>
                <th>Due By</th>
              </tr>
            </thead>
            <tbody>
              {this.renderSLATable(this.props.breakFixSLALogDetails)}
            </tbody>
          </Table>}
        </div>
      </div>
    );
  }
}


export function mapStateToProps({ breakFixSLALogDetails, breakFixSLAResponseLogDetails, breakFixSLAResolutionLogDetails, showLoader_6, windowSize }) {
  //  alert("breakFixEditDetails:"+breakFixAuditLogDetails);
  //console.log("breakFixEditDetails:"+breakFixAuditLogDetails.from);
  return { breakFixSLALogDetails, breakFixSLAResponseLogDetails, breakFixSLAResolutionLogDetails, showLoader_6, windowSize };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loadBreakFixSLALogDetails, loadBreakFixSLAResponseLogDetails, loadBreakFixSLAResolutionLogDetails, reprocessingSlaData }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(XSMSLA);
