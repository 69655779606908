
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import { gql } from "@apollo/client";
import Cookies from "universal-cookie";
const cookie = new Cookies();
let selectedLanguage = cookie.get("gph");
if (selectedLanguage) selectedLanguage = selectedLanguage.replace("s:", "");
if (selectedLanguage)
  selectedLanguage = selectedLanguage.substring(
    0,
    selectedLanguage.lastIndexOf(".")
  );
selectedLanguage = selectedLanguage.split("~");
selectedLanguage = selectedLanguage[20];

export const getForm = gql`
  query GetFormDetail($id: String!) {
    getForm(id: $id) {
      _id
      name
      formObjects
      createdBy
      updatedBy
    }
  }
`;

export const getLanguage = gql`
  {
    getAllLanguages(queryParams: { language: "${selectedLanguage}" }) {
      value
    }
  }
`;

export const languageWithMeta = gql`
  query GetLanguages($offset: Int!, $limit: Int!) {
    getLanguages(queryParams: { limit: $limit, offset: $offset }) {
      count
      data {
        _id
        language
        key
        value
        status
        updatedBy
        updatedAt
      }
    }
  }
`;

export const masterlanguages=gql`
{
    getAllMasterLanguages {
        _id
        name
        code
        status
    }
}
`

export const SaveLanguage = gql`
mutation SaveLanguage($CreateLanguageInput:CreateLanguageInput!) {
    saveLanguage(CreateLanguageInput: $CreateLanguageInput) {
        _id
        language
        key
        value
        status
        createdBy
        updatedBy
        createdAt
        updatedAt
    }
}`;


export const UpdateLanguage = gql`
mutation UpdateLanguage($id:String!,$CreateLanguageInput:CreateLanguageInput!) {
    updateLanguage(id:$id,CreateLanguageInput: $CreateLanguageInput) {
        _id
        language
        key
        value
        status
        createdBy
        updatedBy
        createdAt
        updatedAt
    }
}`;


export const getSingleLanguage = gql`
  query GetLanguage($id: String!) {
    getLanguage(id: $id) {
      _id
      language
      key
      value
      status
    }
  }
`;

export const DeleteLanguage = gql`
  mutation DeleteLanguage($id: String!) {
    deleteLanguage(id: $id) {
      _id
      language
      key
      value
      status
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
