
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Nav, Button, NavDropdown, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Field, reduxForm, formValueSelector, change, SubmissionError } from 'redux-form';
import { loadScheduleDetailsByScheduleId } from '_Actions/onCallSchedule/schedule/onCallScheduleAction';
import {TbSubtask, TbAlignBoxLeftMiddle, TbPencil} from "react-icons/tb";
import {LuFileText, LuHistory} from "react-icons/lu";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import {ImAttachment, ImSpinner6} from "react-icons/im";
import swal from 'sweetalert';
import CommonHeaderMenu from "../../common/CommonHeaderMenu";
import { MyContext } from '_MyContext';

const Header = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const translator = useSelector((state) => state.spcmReducer.tr);
	const [saveLoader, setSaveLoader] = useState(false);
	const context = useContext(MyContext);
    const closeButton = useRef(null);
    const saveObjRef = useRef(null);

    useEffect(() => {
		context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
		context.newTabPrevent();
	}, []);

	function submit(values) {
		let submitFlag = true;
		if (!values.scheduleName || values.scheduleName.trim() == '') {
			props.setScheduleNameErrorState('error');
			submitFlag = false;
		} else {
			props.setScheduleNameErrorState('');
		}
		if (!values.scheduleType) {
			props.setScheduleTypeErrorState('error');
			submitFlag = false;
		} else {
			props.setScheduleTypeErrorState('');
		}
		if (!values.timeZone) {
			props.setTimeZoneErrorState('error');
			submitFlag = false;
		} else {
			props.setTimeZoneErrorState('');
		}
		if(submitFlag == false)
			return false;
		let patchObj = {
			"scheduleName": values.scheduleName,
			"scheduleType": values.scheduleType,
			"status": values.onCallStatus,
			"timeZone": values.timeZone
		};
		setSaveLoader(true);
		axios.patch('/rest/aiosem/updateScheduleAction/' + props.scheduleId, patchObj).then(function (response) {
			if (response.status == 200) {
				setSaveLoader(false);
				dispatch(loadScheduleDetailsByScheduleId(props.scheduleId));
			}
		}).catch(function (error) {
			setSaveLoader(false);
			swal({
				text: error.response.data.businessMessage,
				button: translator['OK'],
			});
		});
	}

	return (
		<Row className="margin-b-15">
			<Col lg={6} md={6} sm={12} xs={12}>
				{/* <h1 bsClass="" className="sPageHeading1 ">{translator["Schedule"]}</h1> */}
				<CommonHeaderMenu active="Schedule"/>
			</Col>
			<Col lg={6} md={6} sm={12} xs={12}>
				<div className="float-r toprigLnk">
					<Nav className="icnlnk riggep float-l" as="ul">
						{props.hideButtons && <Nav.Item
							title={translator["Schedule Association"]}
							eventKey={4}
							as="li"
						>
							<a
								href={void 0}
								onClick={() => {
									props.setActionViewFun("scheduleAssociationList");
								}}
							>
								<TbAlignBoxLeftMiddle /><TbPencil className="cmdbAttrPencil" />
							</a>
						</Nav.Item>}
						{props.hideButtons && <Nav.Item
							title={translator["Related Schedule"]}
							eventKey={4}
							as="li"
						>
							<a
								href={void 0}
								onClick={() => {
									props.setActionViewFun("relatedSchedule");
								}}
							>
								<LuFileText />
							</a>
						</Nav.Item>}
					</Nav>

					<Nav className="icnlnk" as="ul">
						<Nav.Item bsPrefix=" " as="li" eventKey={1}>
							<Link
								to="javascript:void(0)"
								className="anchbtn ctrlKeyPrevent"
								bsPrefix=" "
								onClick={props.handleSubmit(submit)}
								disabled={saveLoader}
								title={translator["Save"]}
								ref={saveObjRef}
							>
								{saveLoader ? <ImSpinner6 className="icn-spinner" /> : <IoSaveOutline />}
							</Link>
						</Nav.Item>
						<Nav.Item as="li">
							<Link to="/onCallSchedule/schedule" ref={closeButton} className="ctrlKeyPrevent">
								<span title={translator["Cancel"]}><IoClose /></span>
							</Link>
						</Nav.Item>
					</Nav>
				</div>
			</Col>
		</Row>
	)
}

export default reduxForm({
	form: 'onCallScheduleEditScheduleForm',
	destroyOnUnmount: true,
	enableReinitialize: false
})(Header);