
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Row, Col, FormGroup, FormControl, Button, Nav, NavItem, Tab, Media } from 'react-bootstrap';

import HomeFeaturedMedia from './listViewFeaturedServices';
import HomeCategoryViewMedia from './listViewCategoryTabServices';
import Cookies from 'universal-cookie';
import { browserHistory } from 'react-router';
import { connect } from "react-redux";
import {PiGridFour} from 'react-icons/pi';

import BestSellingService from './listViewBestSellingServices';
import NewLaunchesService from './listViewNewLaunches';
import ComingSoonService from './listViewComingSoonServices';
import FreeServices from './listViewFreeServices';
import FavouriteServices from './listViewFavouriteServices';

const cookies = new Cookies();
let hometab_required = cookies.get('gph');

if (hometab_required) hometab_required = hometab_required.replace('s:', '');
if (hometab_required) hometab_required = hometab_required.substring(0, hometab_required.lastIndexOf('.'));
hometab_required = hometab_required.split("~");
hometab_required = hometab_required[4];
if (hometab_required == "" || hometab_required == undefined || hometab_required == "undefined")
  hometab_required = "yes";


let hometab_preference = cookies.get('gph');
if (hometab_preference) hometab_preference = hometab_preference.replace('s:', '');
if (hometab_preference) hometab_preference = hometab_preference.substring(0, hometab_preference.lastIndexOf('.'));
hometab_preference = hometab_preference.split("~");
// hometab_preference=hometab_preference[6];
import { GLOBAL } from '_Globals';

let bestSelling = hometab_preference[10];
let newLaunch = hometab_preference[11];
let comingSoon = hometab_preference[12];
let free = hometab_preference[13];
let myFavourite = hometab_preference[14];
let catwise = hometab_preference[15];
let featured = hometab_preference[16];


class HomeListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showBestSelling: '',
      showNewLaunch: '',
      showComingSoon: '',
      showFree: '',
      showMyFavourite: '',
      showCatwise: '',
      showFeatured: '',
      homeactivetab: '',
      ishandleSelect: false
    }
    GLOBAL.searchbarcategory = 'All';
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentWillMount() {
    let myapplocation = window.location.href;
    if (myapplocation.indexOf("myapp") > -1)
      browserHistory.push("/myApprovals");
  }

  componentDidMount() {
    let ref = this;
    ref.setState({
      showBestSelling: bestSelling,
      showNewLaunch: newLaunch,
      showComingSoon: comingSoon,
      showFree: free,
      showMyFavourite: myFavourite,
      showCatwise: catwise,
      showFeatured: featured
    });
  }

  handleSelect(key) {
    this.setState({ homeactivetab: key, ishandleSelect: true });
  }

  render() {
    let activeTab = '';

    if (this.state.showFeatured == 'Y' || this.state.showFeatured == 'y') {
      activeTab = "featured";
    }
    else if (this.state.showBestSelling == 'Y' || this.state.showBestSelling == 'y') {
      activeTab = "bestSelling";
    }
    else if (this.state.showNewLaunch == 'Y' || this.state.showNewLaunch == 'y') {
      activeTab = 'newLaunches';
    }
    else if (this.state.showComingSoon == 'Y' || this.state.showComingSoon == 'y') {
      activeTab = "comingSoon";
    }
    else if (this.state.showFree == 'Y' || this.state.showFree == 'y') {
      activeTab = 'free';
    }
    else if (this.state.showMyFavourite == 'Y' || this.state.showMyFavourite == 'y') {
      activeTab = "favourites";
    }
    else if (this.state.showCatwise == 'Y' || this.state.showCatwise == 'y') {
      activeTab = 'catView';
    }

    let selectedTab = this.state.ishandleSelect ? this.state.homeactivetab : activeTab;
    return (
      <div className="container-fluid">
        <Tab.Container id="SxHomeTablistman" activeKey={selectedTab} onSelect={this.handleSelect}>
          <Row className="sxhometabact sxLiView">
            <Col md={12} sm={12} xs={11}>
              <div className="filterBox">
                <div className="pageviews">
                  <Button className={this.props.isView ? "" : "active"} id="homeGridview" onClick={() => { this.props.pageViewFun(true); }} variant="link" title={this.props.tr["Grid View"]}><PiGridFour/></Button>
                  {/* <Button id="homelistview" bsStyle="link" title={this.props.tr["List View"]}>
                    <i className="fa fa-th-list"></i>
                  </Button> */}
                </div>
                {/* <FormGroup>
										<FormControl componentClass="select" placeholder="All Providers">
											<option>All Providers</option>
											<option>Dell</option>
											<option>HP</option>
											<option>Samsung</option>
											<option>Apple</option>
										</FormControl>
									</FormGroup> */}
                {/* <FormGroup>
									<FormControl componentClass="select" placeholder="Sort By">
										<option>Sort By</option>
										<option>Popularity</option>
										<option>Price -- Low to High</option>
										<option>Price -- High to Low</option>
										<option>Newest First</option>
									</FormControl>
								</FormGroup> */}
              </div>{console.log('selected Tab: ', selectedTab)}
              <Nav as={'ul'} className="nav-tabs nav-tabs-responsive">
                {this.state.showFeatured == "Y" || this.state.showFeatured == "y" ? (<Nav.Item as='li' bsPrefix={selectedTab =="featured" ? "active" : ' '}><Nav.Link bsPrefix=' ' eventKey="featured">{this.props.tr['Featured']}</Nav.Link></Nav.Item>) : null}
                {this.state.showBestSelling == "Y" || this.state.showBestSelling == "y" ? (<Nav.Item  as="li" bsPrefix={selectedTab =="bestSelling" ? "active" : ' '} ><Nav.Link bsPrefix=' ' eventKey="bestSelling">{this.props.tr['Best Selling']}</Nav.Link></Nav.Item>) : null}
                {this.state.showNewLaunch == "Y" || this.state.showNewLaunch == "y" ? (<Nav.Item  as="li" bsPrefix={selectedTab =="newLaunches" ? "active" : ' '} ><Nav.Link bsPrefix=' ' eventKey="newLaunches">{this.props.tr['New Launches']}</Nav.Link></Nav.Item>) : null}
                {this.state.showComingSoon == "Y" || this.state.showComingSoon == "y" ? (<Nav.Item as="li" bsPrefix={selectedTab =="comingSoon" ? "active" : ' '} ><Nav.Link bsPrefix=' ' eventKey="comingSoon">{this.props.tr['Coming Soon']}</Nav.Link></Nav.Item>) : null}
                {this.state.showFree == "Y" || this.state.showFree == "y" ? (<Nav.Item as="li" bsPrefix={selectedTab =="free" ? "active" : ' '} ><Nav.Link bsPrefix=' ' eventKey="free">{this.props.tr['Free']}</Nav.Link></Nav.Item>) : null}
                {this.state.showMyFavourite == "Y" || this.state.showMyFavourite == "y" ? (<Nav.Item as="li" bsPrefix={selectedTab =="favourites" ? "active" : ' '} ><Nav.Link bsPrefix=' ' eventKey="favourites">{this.props.tr['My Favourites']}</Nav.Link></Nav.Item>) : null}
                {this.state.showCatwise == "Y" || this.state.showCatwise == "y" ? (<Nav.Item as='li' bsPrefix={selectedTab =="catView" ? "active" : ' '}><Nav.Link bsPrefix=' ' eventKey="catView">{this.props.tr['Category View']}</Nav.Link></Nav.Item>) : null}
              </Nav>
            </Col>
            <Col md={12}>
              <Tab.Content animation className="tabouter-border">
                <Tab.Pane eventKey="featured">{this.state.showFeatured == "N" || this.state.showFeatured == "n" ? null : <HomeFeaturedMedia homeactivetab={selectedTab} />}</Tab.Pane>
                <Tab.Pane eventKey="bestSelling">{((this.state.showBestSelling == "N" || this.state.showBestSelling == "n")) ? null : (<BestSellingService homeactivetab={selectedTab} />)}</Tab.Pane>
                <Tab.Pane eventKey="newLaunches">{((this.state.showNewLaunch == "N" || this.state.showNewLaunch == "n")) ? null : (<NewLaunchesService homeactivetab={selectedTab} />)} </Tab.Pane>
                <Tab.Pane eventKey="comingSoon">{((this.state.showComingSoon == "N" || this.state.showComingSoon == "n")) ? null : (<ComingSoonService homeactivetab={selectedTab} />)} </Tab.Pane>
                <Tab.Pane eventKey="free">{((this.state.showFree == "N" || this.state.showFree == "n")) ? null : (<FreeServices homeactivetab={selectedTab} />)}</Tab.Pane>
                <Tab.Pane eventKey="favourites">{((this.state.showMyFavourite == "N" || this.state.showMyFavourite == "n")) ? null : (<FavouriteServices homeactivetab={selectedTab} />)}</Tab.Pane>
                <Tab.Pane eventKey="catView">{this.state.showCatwise == "N" || this.state.showCatwise == "n" ? null : <HomeCategoryViewMedia homeactivetab={selectedTab}/>}</Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    );
  }
}

const mapStateToProps = ({ spcmReducer }) => {
  return {
    tr: spcmReducer.tr

  };
};
export default connect(mapStateToProps, {})(HomeListView);
