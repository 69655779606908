
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";
const cookies = new Cookies();

import swal from "sweetalert";
import axios from "axios";
import { useOutletContext } from "react-router-dom";
import { openLinkInNewTab } from "../common/helper";
const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});
let appURL = cookies.get("gph");
if (appURL) appURL = appURL.replace("s:", "");
if (appURL) appURL = appURL.substring(0, appURL.lastIndexOf("."));
appURL = appURL.split("~");
appURL = appURL[18];

let userLogoutUrl = cookies.get("gph");
if (userLogoutUrl) userLogoutUrl = userLogoutUrl.replace("s:", "");
if (userLogoutUrl)
  userLogoutUrl = userLogoutUrl.substring(0, userLogoutUrl.lastIndexOf("."));
userLogoutUrl = userLogoutUrl.split("~");
userLogoutUrl = userLogoutUrl[44];

const SessionTimeout = () => {
  const setShowHeader = useOutletContext();
  const multiTenantLogoReducer = useSelector(
    (state) => state.multiTenantLogoReducer
  );

  useEffect(() => {
    localStorage.removeItem("GlobalSearchDefaultChoice");
    api.get("/authentication/logout/" + userLogoutUrl).then((response) => {
      console.log("Session timeout====", response);
    });

    setShowHeader(false);
  }, []);

  const deleteAllCookies = () => {
    let cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      let eqPos = cookie.indexOf("=");
      let name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
    openLinkInNewTab("/authentication/logout/" + userLogoutUrl, "");
  };

  if (document.getElementsByClassName("swal-modal").length > 0) {
    swal.close();
  }
  window.alert = function () {};
  if (window.performance) {
    console.info("window.performance work's fine on this browser");
  }
  if (window.performance.navigation.type == 1) {
    openLinkInNewTab("/authentication/logout/" + userLogoutUrl, "")
  }
  const head = {
    height: "65px",
    backgroundColor: "#e7ebef",
    borderBottom: "1px #d2d5e8 solid",
    marginBottom: "50px",
  };
  const imgSec = { textAlign: "center", marginBottom: "50px" };
  const txt = {
    fontSize: "17px",
    fontWeight: "400",
    color: "#727272",
    textAlign: "center",
    marginBottom: "30px",
  };
  const txt1 = {
    fontSize: "17px",
    fontWeight: "400",
    color: "#727272",
    textAlign: "center",
    marginBottom: "10px",
  };
  const spanSec = {
    border: "1px #cccccc solid",
    borderRight: "0",
    color: "#555",
    backgroundColor: "#eee",
    padding: "6px 12px",
    display: "table-cell",
  };
  const inputBox = {
    fontSize: "14px",
    border: "1px #cccccc solid",
    color: "#555",
    backgroundColor: "#eee",
    padding: "6px 12px",
    height: "36px",
    display: "table-cell",
    borderRadius: "0",
    width: "210px",
  };
  const btnBox = {
    border: "none",
    boxShadow: "none",
    margin: "initial",
    padding: "initial",
    width: "auto",
    backgroundColor: "transparent",
    overflow: "initial",
    lineHeight: "14px",
    color: "blue",
  };
  const footerSec = {
    fontSize: "12px",
    color: "#9d9d9d",
    textAlign: "center",
    marginBottom: "20px",
  };

  return (
    <div style={{ marginTop: "-58px" }}>
      <div style={head} />
      <div
        className="margin-t-20 text-c"
        style={{ color: "#F8993B", padding: "20px" }}
      >
        <i className="f-size-60 fa fa-sign-out" aria-hidden="true"></i>
      </div>
      <div style={txt1}>Welcome to HCL SX</div>
      <div style={{ textAlign: "center" }}>
        <div style={txt}>
          Your session has timed out. Please{" "}
          <button title="click here" onClick={deleteAllCookies} style={btnBox}>
            click here
          </button>{" "}
          to login.
        </div>
      </div>
    </div>
  );
};

export default SessionTimeout;
