
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SlaListHeader from "./SlaListHeader.js";
import SlaList from "./SlaList.js";
import XsmQuickViewShortDescription from "./short-description.js";
import { loadSlaDefListOnPagination } from "_Actions/sla/quickViewAction.js";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import "_Css/common/_table.scss";

const SlaIndex = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let inputRef;
  const [subMenu, setSubMenu] = useState("4");
  const [showHideAlfy, setShowHideAlfyState] = useState(false);
  const [showHideRightNav, setShowHideRightNavState] = useState(false);
  const [mobileLeftMenuFilter, setMobileLeftMenuFilterState] = useState(false);
  const [slaId, setSlaId] = useState("");
  const [targetCode, setTargetCode] = useState("");
  const [targetName, setTargetName] = useState("");
  const [companyFor, setCompanyFor] = useState("");
  const [service, setService] = useState("");
  const [impacted, setImpacted] = useState("");
  const [type, setType] = useState("");
  const [targetFor, setTargetFor] = useState("");
  const [status, setStatus] = useState("");
  const [slaName, setSlaName] = useState("");
  const [ciServiceName, setCiServiceName] = useState("");
  const [serviceBased, setServiceBased] = useState("");
  const [moduleName, setModuleName] = useState("");
  const [targetTime, setTargetTime] = useState("");
  const [retroactiveRequired, setRetroactiveRequired] = useState("");
  const [slaType, setSlaType] = useState("");
  const [isRightSideVisible, setIsRightSideVisible] = useState(false);
  const [rowIndex, setRowIndex] = useState(0);
  const [nameSearchInput, setNameSearchInputState] = useState("");
  const [companySearchInput, setCompanySearchInputState] = useState("");
  const [selectedTargetValue, setSelectedTargetValueState] = useState([]);
  const [selectedStatusValue, setSelectedStatusValueState] = useState([]);
  const [selectedModuleValue, setSelectedModuleValueState] = useState([]);
  const [searchParamsValue, setSearchParamsValue] = useState({});
  const [searchKey, setSearchKey] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [pattern, setPattern] = useState("");
  const [statusValue, setStatusValue] = useState([]);
  const [targetValue, setTargetValue] = useState([]);
  const [moduleValue, setModuleValue] = useState([]);
  const [isClearAllfilter, setIsClearAllfilter] = useState(false);
  const [activePanelKey, setActivePanelKey] = useState("0");
  const [startDate, setStartDate] = useState({});
  const [endDate, setEndDate] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const slaDefList = useSelector((state) => state.slaDefList);
  const translator = useSelector((state) => state.spcmReducer.tr);
  const show_loader = useSelector((state) => state.showLoader_SLA_Deflist_Pagination.loading);
  const [panelResize,setPanelResize] = useState();
  const [isStatusOpen, setStatusOpen] = useState(false);
  const [isTargetOpen, setTargetOpen] = useState(false);
  const [isModuleOpen, setModuleOpen] = useState(false);

  const handlePanelClick = (activePanelKey) => {
    setActivePanelKey(activePanelKey);
  };

  const setPageDetails = (pageNumber, size) => {
    setPageSize(size);
    setPageNumber(pageNumber);
  };

  const setStartAndEndDate = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const setSearchParams = (
    searchParams,
    key,
    value,
    pattern,
    status,
    target,
    module
  ) => {
    setSearchParamsValue(searchParams);
    setSearchKey(key);
    setSearchValue(value);
    setPattern(pattern);
    setStatusValue(status);
    setTargetValue(target);
    setModuleValue(module);
  };

  const setRef = (ref) => {
    inputRef = ref;
  };

  const isClearAllFilterFunc = (value) => {
    setIsClearAllfilter(value);
  };

  const setNameSearchInput = (value) => {
    setNameSearchInputState(value);
  };

  const setCompanySearchInput = (value) => {
    setCompanySearchInputState(value);
  };

  const setSelectedTargetValue = (value) => {
    setSelectedTargetValueState(value);
  };

  const setSelectedStatusValue = (value) => {
    setSelectedStatusValueState(value);
  };

  const setSelectedModuleValue = (value) => {
    setSelectedModuleValueState(value);
  };

  const showRightSide = (value) => {
    setIsRightSideVisible(value);
  };

  const showActiveRow = (i) => {
    setRowIndex(i);
  };

  const showHideAlfySet = (value) => {
    setShowHideAlfyState(value);
  };
  const showHideRightNavSet = (value) => {
    setShowHideRightNavState(!showHideRightNav);
  };

  const mobileLeftMenuFilterSet = () => {
    setMobileLeftMenuFilterState(!mobileLeftMenuFilter);
  };

  const getSlaDefDetails = (
    slaId,
    targetCode,
    targetName,
    companyFor,
    service,
    impacted,
    ciServiceName,
    serviceBased,
    type,
    targetFor,
    status,
    slaName,
    moduleName,
    goalTime,
    retroactiveRequired,
    slaType
  ) => {
    setSlaId(slaId);
    setTargetCode(targetCode);
    setTargetName(targetName);
    setCompanyFor(companyFor);
    setService(service);
    setImpacted(impacted);
    setCiServiceName(ciServiceName);
    setServiceBased(serviceBased);
    setType(type);
    setTargetFor(targetFor);
    setStatus(status);
    setSlaName(slaName);
    setModuleName(moduleName);
    setTargetTime(goalTime);
    setRetroactiveRequired(retroactiveRequired);
    setSlaType(slaType);
  };

  const buildSearchParamsForCMDBQV = (params) => {
    let searchParams = Object.assign({}, params);

    //======Status Filter Start====
    let selectedStatus = props.cmdbQuickViewFilters.selectedStatus;
    //console.log(selectedpriorities);
    if (!_.isEmpty(selectedStatus)) {
      if (!_.isEmpty(searchParams.searchByList)) {
        searchParams.searchByList = searchParams.searchByList + "," + "STATUS";
        searchParams.multipleValueList =
          searchParams.multipleValueList + "|" + selectedStatus.join();
      } else {
        searchParams.searchByList = "STATUS";
        searchParams.multipleValueList = selectedStatus.join();
      }
    }
    //======Status Filter End===========

    //=======Company Filter Start========
    let selectedCompany = props.cmdbQuickViewFilters.selectedCompany;
    //console.log(selectedpriorities);
    if (!_.isEmpty(selectedCompany)) {
      if (!_.isEmpty(searchParams.searchByList)) {
        //alert("called")
        searchParams.searchByList =
          searchParams.searchByList + "," + "COMPANY_ID";
        searchParams.multipleValueList =
          searchParams.multipleValueList + "|" + selectedCompany.join();
      } else {
        searchParams.searchByList = "COMPANY_ID";
        searchParams.multipleValueList = selectedCompany.join();
      }
    }
    //=======Company Filter End========
    //=======Class Filter Start========
    let selectedClass = props.cmdbQuickViewFilters.selectedClass;
    //console.log(selectedpriorities);
    if (!_.isEmpty(selectedClass)) {
      if (!_.isEmpty(searchParams.searchByList)) {
        //alert("called")
        searchParams.searchByList =
          searchParams.searchByList + "," + "CLASS_ID";
        searchParams.multipleValueList =
          searchParams.multipleValueList + "|" + selectedClass.join();
      } else {
        searchParams.searchByList = "CLASS_ID";
        searchParams.multipleValueList = selectedClass.join();
      }
    }
    //=======Class Filter End========
    return searchParams;
  };

  const multiSelectOpenFun = (keyName, val) => {
		switch (keyName) {
		  case "targetFor": 
      {
        setStatusOpen(false);
        setTargetOpen(val);
        setModuleOpen(false);
      };
			break;
			case "status":
        {
          setStatusOpen(val);
          setTargetOpen(false);
          setModuleOpen(false);
        };
			break;
			case "module":
        {
          setStatusOpen(false);
          setTargetOpen(false);
          setModuleOpen(val);
        };
			break;
			case "allFalse":
        {
          setStatusOpen(false);
          setTargetOpen(false);
          setModuleOpen(false);
        };
			break;
		}
  }

  useEffect(() => {
    let str = {};
    str.searchByList = "";
    str.multipleValueList = "";
    str.patternList = "";
    str.orderBy = "desc";
    str.sortBy = "modifiedOn";
    str.currentPage = 0;
    str.size = 10;
    dispatch(loadSlaDefListOnPagination(str, [], [], []));
  }, []);

  let colWidth = "";
  isRightSideVisible ? (colWidth = 8) : (colWidth = 12);
  return (
    <>
      <div>
        <div className="container-fluid padding-t-10" bsClass="">
          {/* Xsm Quick View Detailt Header Section Start*/}
          <SlaListHeader
            navigate={navigate}
            translator={translator}
            isRightSideVisible={isRightSideVisible}
            showRightSide={showRightSide}
            rowIndex={rowIndex}
            showActiveRow={showActiveRow}
            nameSearchInput={nameSearchInput}
            companySearchInput={companySearchInput}
            selectedTargetValue={selectedTargetValue}
            selectedStatusValue={selectedStatusValue}
            selectedModuleValue={selectedModuleValue}
            setNameSearchInput={setNameSearchInput}
            setCompanySearchInput={setCompanySearchInput}
            setSelectedTargetValue={setSelectedTargetValue}
            setSelectedStatusValue={setSelectedStatusValue}
            setSelectedModuleValue={setSelectedModuleValue}
            setSearchParams={setSearchParams}
            searchKey={searchKey}
            pattern={pattern}
            searchValue={searchValue}
            searchParamsValue={searchParamsValue}
            statusValue={statusValue}
            targetValue={targetValue}
            moduleValue={moduleValue}
            isClearAllfilter={isClearAllfilter}
            isClearAllFilterFunc={isClearAllFilterFunc}
            startDate={startDate}
            endDate={endDate}
            setStartAndEndDate={setStartAndEndDate}
            setRef={setRef}
            dateRangePickerRef={inputRef}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageDetails={setPageDetails}
          />
          {/* Xsm Quick View Detailt Header Section end*/}
            
          <PanelGroup direction="horizontal" className={(isStatusOpen || isTargetOpen || isModuleOpen) && (show_loader == true || slaDefList.data?.data?.length <= 6) ? "overflow-clip" : ""}>
            <Panel id="sidebar" minSize={33} order={1} defaultSize={isRightSideVisible ? 67 : 100} onResize={(size) => setPanelResize(size)} className={(isStatusOpen || isTargetOpen || isModuleOpen) && (show_loader == true || slaDefList.data?.data?.length <= 6) ? "overflow-clip" : (isRightSideVisible ? "isShowLeftPanel rwLeftPart" : "rwLeftPart")}>
              {/* Xsm Quick View List Section Start*/}
              <SlaList
                getSlaDefDetails={getSlaDefDetails}
                translator={translator}
                isRightSideVisible={isRightSideVisible}
                showRightSide={showRightSide}
                rowIndex={rowIndex}
                showActiveRow={showActiveRow}
                nameSearchInput={nameSearchInput}
                companySearchInput={companySearchInput}
                selectedTargetValue={selectedTargetValue}
                selectedStatusValue={selectedStatusValue}
                selectedModuleValue={selectedModuleValue}
                setNameSearchInput={setNameSearchInput}
                setCompanySearchInput={setCompanySearchInput}
                setSelectedTargetValue={setSelectedTargetValue}
                setSelectedStatusValue={setSelectedStatusValue}
                setSelectedModuleValue={setSelectedModuleValue}
                setSearchParams={setSearchParams}
                searchParamsValue={searchParamsValue}
                searchKey={searchKey}
                searchValue={searchValue}
                pattern={pattern}
                statusValue={statusValue}
                targetValue={targetValue}
                moduleValue={moduleValue}
                isClearAllfilter={isClearAllfilter}
                isClearAllFilterFunc={isClearAllFilterFunc}
                activePanelKey={activePanelKey}
                handlePanelClick={handlePanelClick}
                startDate={startDate}
                endDate={endDate}
                setStartAndEndDate={setStartAndEndDate}
                setRef={setRef}
                pageSize={pageSize}
                pageNumber={pageNumber}
                setPageDetails={setPageDetails}
                panelResize={panelResize}
                isStatusOpen={isStatusOpen}
                isTargetOpen={isTargetOpen}
                isModuleOpen={isModuleOpen}
                multiSelectOpenFun={multiSelectOpenFun}
              />
              {/* Xsm Quick View List Section End*/}
            </Panel>
            {isRightSideVisible ?
                <>
                  <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
                  <Panel minSize={33} order={2} defaultSize={33}>
                    <div className="stickyArea rBoxStyle">
                      <XsmQuickViewShortDescription
                        slaId={slaId}
                        targetCode={targetCode}
                        targetName={targetName}
                        companyFor={companyFor}
                        service={service}
                        impacted={impacted}
                        ciServiceName={ciServiceName}
                        serviceBased={serviceBased}
                        type={type}
                        targetFor={targetFor}
                        status={status}
                        slaName={slaName}
                        moduleName={moduleName}
                        targetTime={targetTime}
                        retroactiveRequired={retroactiveRequired}
                        slaType={slaType}
                        translator={translator}
                        isRightSideVisible={isRightSideVisible}
                        showRightSide={showRightSide}
                        rowIndex={rowIndex}
                        showActiveRow={showActiveRow}
                        activePanelKey={activePanelKey}
                        handlePanelClick={handlePanelClick}
                        pageSize={pageSize}
                        pageNumber={pageNumber}
                        setPageDetails={setPageDetails}
                      />
                    </div>
                  </Panel>
                </> : ""
            }
          </PanelGroup>
        </div>
      </div>
    </>
  );
};

export default SlaIndex;
//export default CMDB;
