
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Form from 'react-bootstrap/Form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import swal from 'sweetalert';

import RFReactUser from '../../../components/WorkItemBoard/Change/edit/AutoSearchUser';

import { GLOBAL } from "_Globals";

import { loadChangeApprovalDetails } from '../../../actions/changeManagement/changeApprovalAction';
import { loadChangeEditData}  from '../../../actions/changeManagement/changeManagementAction';
import { loadBreakFixAuditLogDetails } from '../../../actions/breakFix/breakFixAuditLogAction';
import { loadStatusBasedHamburgerOptions, resetLoadHamburgerOptionsReducer } from '../../../actions/common/loadStatusBasedHamburgerOptions';
import { loadTimelineData } from '../../../actions/common/commonApisActions';
import { getAdhocApprovers } from "../../../actions/MyApprovals/MyApprovalActions";
import { loadChangeTotalApprovalDetails, getRequesterDelegateeForApprovalDetails } from "../../../actions/changeManagement/changeTotalApprovalAction";
import { loadFulfillmentDetails } from '../../../actions/fulfillment/fulfillmentDataAction';

import { _inputField, _textArea, _dropDown, _dateField,_latentField, _dateTimeField, _latentDateTimeField } from '../ReduxFormFields/formFields';
import configureStore from '../../../store/configureStore.js';
import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";

const store = configureStore();
const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

import Cookies from "universal-cookie";
import Swal from 'sweetalert2';
const cookies = new Cookies();

let user_id = cookies.get("gph");
if (user_id) user_id = user_id.replace("s:", "");
if (user_id) user_id = user_id.substring(0, user_id.lastIndexOf("."));
user_id = user_id.split("~");
user_id = user_id[43];

class ProvideAttachmentsTab extends React.Component{
    constructor(props) {
		super(props);
		this.state ={
			searchList:false,
			rejectSecHideShow:false,
			approveSecHideShow:false,
			radioval:'',
			apprejbutton:false,
			rejectTextValue: '',
			selectedOption:'',
			approveTextValue:'',
			loader: false,
			cabDateVal: '',
			errorMsg:'',
			cabError:'',
			selectedRow:'',
			date_format: 'DD-MM-YYYY',
			refBackVisible: false,
			refBackTextValue:'',
			showAddAdhoc: false,
			showViewAddhoc: false,
			currentSelectedApprover: {},
			approversArray: [],
      		emptyAutosuggestValue: false,
			adhocButtonDisabled: false,
			adHocSectionVisible: false,
			selectedBtnAction: "",
			adhocTextValue: ""
		};
		this.updateRejectTextField = this.updateRejectTextField.bind(this);
		this.updateApprovedTextField = this.updateApprovedTextField.bind(this);
		this.refBackSecHideShow = this.refBackSecHideShow.bind(this);
		this.postRefBackData = this.postRefBackData.bind(this);
		this.additionalApproversSection = this.additionalApproversSection.bind(this);
		this.renderAdhocApproversTable = this.renderAdhocApproversTable.bind(this);
		this.onUserNameChange = this.onUserNameChange.bind(this);
		this.changeAutosuggestState = this.changeAutosuggestState.bind(this);
		this.addApproverToList = this.addApproverToList.bind(this);
		this.showCreateApproversList = this.showCreateApproversList.bind(this);
		this.deleteApproverFromList = this.deleteApproverFromList.bind(this);
		this.clearAdhocData = this.clearAdhocData.bind(this);
		this.adhocTextField = this.adhocTextField.bind(this);
	}

	componentWillMount(){
		// console.log('this.props - ', this.props);
		const { module = 'Change' } = this.props;
		if(module == 'Change'){
			this.props.loadChangeApprovalDetails("14",this.props.changeId,"");
		}
		else{
			const { itemDetails : { offeringIdSx, itemId } = {} } = this.props;
			this.props.loadChangeApprovalDetails("10",itemId,"");
		}
		let str={};
		str.user_id="";
		str = JSON.stringify(str);
		api.get("/api/userInfo",{headers:{'query':str}}).then((response)=>{
      		this.setState({date_format:response.data.dateformat});
		});
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps && nextProps.changeTotalApprovalDetails && nextProps.changeTotalApprovalDetails != this.props.changeTotalApprovalDetails && nextProps.changeTotalApprovalDetails[0].isRequester == "true" && nextProps.changeTotalApprovalDetails[0].approverDetails) {
			let { module = 'Change' } = this.props;
			if(module == 'Change'){
				this.props.getRequesterDelegateeForApprovalDetails(nextProps.changeTotalApprovalDetails[0].requesterId, "14");
			}
			else {
				this.props.getRequesterDelegateeForApprovalDetails(nextProps.changeTotalApprovalDetails[0].requesterId, "10");
			}
		}
	}

    updateRejectTextField(value) {
		this.setState({ rejectTextValue: value });
	}
    updateApprovedTextField(value) {
		this.setState({ approveTextValue: value });
	}
    refBackSecHideShow(){
		this.setState({rejectSecHideShow:false, approveSecHideShow: false, adHocSectionVisible: false});
		this.setState({refBackVisible:!this.state.refBackVisible,refBackTextValue:'', selectedBtnAction: "referBack" });
		this.setState({showAddAdhoc: false,showViewAddhoc: false,currentSelectedApprover: {},approversArray: [],emptyAutosuggestValue: false,adhocButtonDisabled: false});
	}
    postRefBackData(){
		const { refBackTextValue = "" } = this.state;
		const { module = 'Change' } = this.props;
		const { editChangeInitialData : { type = "" } = {} } = this.props;
		let ref = this;
		ref.setState({ loader: true });
		if (!(refBackTextValue && refBackTextValue.trim().length)){
			ref.setState({ loader: false });
			let rejmsg = ref.props.translator['provide reason for refer back'];
			swal({
			text: rejmsg,
			button: ref.props.translator['OK'],
			});
			return false;
		} else{
			if(module == 'Change'){
				axios.put(GLOBAL.approvalsGeneratorRFCUrl+'/'+this.state.radioval, {
					"status":"Refer Back",
					"moreinfoby":"",
					"moreinforequired":refBackTextValue.trim(),
					"lastUpdatedBy": ""
				}).then((response) => {
					if (response.status == 200 || response.status == 201) {
						let rejsuccessMsg = ref.props.translator['Referred Back Successfully'];
						swal({
							text: rejsuccessMsg,
							button: ref.props.translator['OK'],
						});
						ref.setState({ loader: false });
						ref.props.changeActionView('');
						let patchObj = {"systemStartDateRequired": "N"};
						if(type == '35'){
							patchObj['status'] = '95';
						}
						axios.patch(GLOBAL.changeEditUrl+ref.props.changeId, patchObj).then(function (response) {
										if(response.status === 200){
										store.dispatch({ type: 'CHANGE_EDIT_DATA_LOAD_SUCCESS', editChangeInitialData: {} });
										ref.props.loadChangeEditData(ref.props.changeId);
										ref.props.loadStatusBasedHamburgerOptions('ChangeManagement',response.data.status,response.data.changeId);
										}
						}).catch(function (error) {
						});
						ref.setState({rejectSecHideShow:false, approveSecHideShow: false, refBackVisible: false, adHocSectionVisible: false, selectedBtnAction: ""});
						this.props.loadChangeApprovalDetails("14",this.props.changeId,"");
						ref.setState({apprejbutton:false});
					}
				});
			}
			else{
				let obj = {
					status: "Refer Back",
					moreinfoby: "",
					moreinforequired: refBackTextValue.trim().replace(/"/g, '').replace(/\\/g, '\\\\').replace(/(\r\n|\n|\r)/gm, ""),
					lastUpdatedBy: ""
				};
				this.updateFulfillmentApprovalStatus(obj, 'referBack');
			}
		}
	}
	adhocTextField(value) {
		this.setState({ adhocTextValue: value });
	  }
    additionalApproversSection(){
		try{
			const { adhocApproversList = [], translator: tr = {} } = this.props;
			const { showAddAdhoc = false, showViewAddhoc = false, approversArray = [], adhocButtonDisabled = false } = this.state;
			return(
				<div>
					<div className="f-size-15 margin-t-20 margin-b-5 ">
						{tr['Additional Approvers (Optional)']}

						{(showAddAdhoc == false) && <span className="float-r"><Button className="pad05" title={tr['Add']} onClick={() => {this.setState({showAddAdhoc: true,approveSecHideShow: false});}}>
                            <i className="fa fa-plus"></i></Button>
                        </span>}
						{adhocApproversList && adhocApproversList.length > 0 ?
                            <span className="float-r margin-r-5"><Button className="pad05" title={tr['View']} onClick={() => { this.setState({showViewAddhoc: !showViewAddhoc});}}>
                                <i className="fa fa-eye"></i></Button></span>
                        :''}
					</div>
					<div style={{ "clear": "both", "height": "2px" }}></div>
					{showViewAddhoc == true && adhocApproversList && adhocApproversList.length > 0 ?
						this.renderAdhocApproversTable()
					:null}
					{(showAddAdhoc == true) && <div className="margin-t-20 aproHiSho">
						{/* <Row>
							<Col xs={10}>
								<RFReactUser name="approver_name"  onUserNameChange={this.onUserNameChange} changeAutosuggestState={this.changeAutosuggestState}
                                             emptyAutosuggestValue={this.state.emptyAutosuggestValue} tr={tr}/>
							</Col>
							<Col xs={2}>
							    <Button onClick={() => { this.addApproverToList() }} title={tr['Add']} bsStyle="primary" className="approveBtnBreak" >{tr['Add']}</Button>
							</Col>
						</Row> */}
							<div className='col1v'>
								<RFReactUser name="approver_name"  onUserNameChange={this.onUserNameChange} changeAutosuggestState={this.changeAutosuggestState}
                                             emptyAutosuggestValue={this.state.emptyAutosuggestValue} tr={tr}/>
							</div>
							<div className='col2v'>
							    <Button onClick={() => { this.addApproverToList() }} title={tr['Add']} bsStyle="primary" className="approveBtnBreak" >{tr['Add']}</Button>
							</div>
					</div>}
					{(approversArray && approversArray.length > 0) &&
						(<><Table className="margin-t-10">
							<thead>
							<tr>
								<th >{tr['Approver Name']}</th>
								<th >{tr['Action']}</th>
							</tr>
							</thead>
							<tbody>
							{this.showCreateApproversList()}
							</tbody>
						</Table>
						<Form.Group className='form-group'>
						<div className="f-size-15 margin-t-20 margin-b-5 "><span className="rStar"></span> {['Approval Request Comments']} </div>
						<Form.Control as="textarea" value={this.state.adhocTextValue} rows="3" onChange={event => { this.adhocTextField(event.target.value); }}  maxLength="1000" />
					  </Form.Group></>)
                    }
					{(showAddAdhoc == true) && <div aria-label='Button Toolbar' role="contentinfo" className="margin-t-20">
						<ButtonToolbar>
							<Button className="rgSidrkBtn smallBtn" bsSize="small" bsStyle="primary" onClick={() => { this.submitAdhocApprovers(); }} disabled={adhocButtonDisabled || approversArray.length == 0}>
                                {adhocButtonDisabled == true ? <ImSpinner6 className="icn-spinner"/> : null}
                                {tr['Submit']}
                            </Button>
							<Button className="rgSiLigBtn smallBtn" bsSize="small" bsStyle="text" onClick={() => {
								this.clearAdhocData();
							 }}>{tr['Cancel']}</Button>
						</ButtonToolbar>
                    </div>}

				</div>
			);
		}catch(e){
		}
	}
    renderAdhocApproversTable() {
		const { adhocApproversList = [], translator: tr = {} } = this.props;
		return (
			<Table bordered condensed hover responsive className="tableView  tableWithOutPointer">
			<thead>
				<tr>
				   <th width="60%" className="sort">{tr['Adhoc Approvers']}</th>
				</tr>
			</thead>
			<tbody>
				{adhocApproversList.map((approver, i) => {
				return (
					<tr>
					<td>
						{approver.adhocApproverUserName}
						<Form.Check type="checkbox" className="dCheckbox float-r readOnlyChk" checkedreadOnly>
						     <span />
						</Form.Check>
					</td>
					</tr>
				);
				})}
			</tbody>
			</Table>
		);
	}
    onUserNameChange(value) {
		this.setState({ currentSelectedApprover: value });
	}
    changeAutosuggestState(val) {
		this.setState({ emptyAutosuggestValue: val });
	}
    addApproverToList() {
		const { currentSelectedApprover = {}, approversArray = [] } = this.state;
		if (Object.keys(currentSelectedApprover).length === 0)
		  return false;
		let arr = structuredClone(approversArray);
		let obj = Object.assign({}, currentSelectedApprover);
		arr.push(obj);
		this.setState({ approversArray: arr });
		this.setState({ adhocTextValue: "" });
		this.changeAutosuggestState(true);
		this.setState({ currentSelectedApprover: {} });
	}
    showCreateApproversList() {
		const { approversArray = [] } = this.state;
		const { translator: tr = {} } = this.props;
		if(!(approversArray && approversArray.length)){
			return null;
		}
		return approversArray.map((approver, i) => {
		  return <tr>
			<td>
			  {approver.adhocApproverUserName}
			</td>
			<td>
			  <Button className="pad05" bsSize="small" title={tr['Delete']} onClick={() => { this.deleteApproverFromList(i, approver.adhocApproverUserId) }}><i className="fa fa-trash"></i></Button>
			</td>
		  </tr>;
		});
	}
    deleteApproverFromList(index, adhocApproverUserId) {
		const { approversArray = [] } = this.state;
		let arr = structuredClone(approversArray);
		let result = arr.filter(approver => approver.adhocApproverUserId != adhocApproverUserId);
		this.setState({ approversArray: result });
	}
    clearAdhocData(){
		this.setState({showAddAdhoc: false,currentSelectedApprover: {},approversArray: [],emptyAutosuggestValue: false,adhocButtonDisabled: false,approveSecHideShow: true,selectedBtnAction: "approve"});
	}
    
    searchListFun(){
		this.setState({searchList:true});
	}
    rejectSecHideShow(){
		this.setState({approveSecHideShow:false, refBackVisible: false, adHocSectionVisible: false});
		this.setState({rejectSecHideShow:!this.state.rejectSecHideShow,approveTextValue: '', selectedBtnAction: "reject" });
		this.setState({showAddAdhoc: false,showViewAddhoc: false,currentSelectedApprover: {},approversArray: [],emptyAutosuggestValue: false,adhocButtonDisabled: false});
	}
    approveSecHideShow(){
		this.setState({rejectSecHideShow:false, refBackVisible: false});
		this.setState({approveSecHideShow:!this.state.approveSecHideShow,rejectTextValue:'', adHocSectionVisible: !this.state.adHocSectionVisible, selectedBtnAction: "approve" });
		this.setState({showAddAdhoc: false,showViewAddhoc: false,currentSelectedApprover: {},approversArray: [],emptyAutosuggestValue: false,adhocButtonDisabled: false});
	}
    onRadioChanged(e){
		const {module = 'Change', changeApprovalDetails = []} = this.props;
		let approvalDetails = changeApprovalDetails;
		let row = approvalDetails.find((approvalObj)=>{
			return approvalObj.SIGNATURE_ID == e.target.value;
		});
		this.setState({radioval: e.target.value,apprejbutton:true,selectedOption:e.target.value,selectedRow:row});
	}
    renderApprovalLog(approvalLogData){
		if(!(approvalLogData && approvalLogData.length)){
 		return (
 				<tr>
 					<td width="100%" colSpan={3} className="text-center">
 						<div>{this.props.translator['No pending approvals']}</div>
 					</td>
 				</tr>
 			);
 		}
	return approvalLogData.map((approvalObj,index)=>{
	   let radioname="approvalact"+index;
	   let value = approvalObj.SIGNATURE_ID || "";
	   return(
		 <tr>
		 <td><Form.Check type="radio" value={value} onClick={this.onRadioChanged.bind(this)} checked={this.state.selectedOption == value}></Form.Check></td>
		 <td>{approvalObj.LEVELNAME}</td>
		 <td>Pending</td>
		 </tr>);
	});
    }
    postApprovedData(){
	const { approveTextValue = "" } = this.state;
	const { module = 'Change' } = this.props;
	let ref = this;
	let callApi = false;
	let dateformat = this.state.date_format.slice(0, 10).toUpperCase();
	let cabDateValue = "0";
	ref.setState({ loader: true });
	if (this.props.changeTotalApprovalDetails && this.props.changeTotalApprovalDetails[0].isRequester == "true") {
		if (this.props.requesterDelegateeDetails && this.props.requesterDelegateeDetails.length > 0) {
			let delegateeCheck = false;
			this.props.requesterDelegateeDetails.forEach((delegatee) => {
				if (user_id == delegatee.delegatee) {
					delegateeCheck = true;
				}
			});
			if (delegateeCheck == true || user_id == this.props.changeTotalApprovalDetails[0].requesterId) {
				this.setState({ loader: false });
				swal({
					text: this.props.translator['The requester cannot be the sole approver. Please select at least one other approver'],
					button: this.props.translator['OK'],
				});
				return false;
			}
		}
		else {
			if(user_id == this.props.changeTotalApprovalDetails[0].requesterId) {
				this.setState({ loader: false });
				swal({
					text: this.props.translator['The requester cannot be the sole approver. Please select at least one other approver'],
					button: this.props.translator['OK'],
				});
				return false;
			}
		}
	}
	if (!(approveTextValue && approveTextValue.trim().length)) {
        ref.setState({ loader: false });
        let appmsg = ref.props.translator['provide approval comment'];
        swal({
          text: appmsg,
          button: ref.props.translator['OK'],
        });
        return false;
      } else {
		if(module == 'Change'){
			let approvalObj = {
				"status": "Approved",
				"approvedBy": "",
				"approval_comments": approveTextValue.trim(),
				"lastUpdatedBy": ""
			};
			axios.put(GLOBAL.approvalsGeneratorRFCUrl+'/'+this.state.radioval, approvalObj).then((response) => {
			if (response.status == 200 || response.status == 201) {
				let appsuccessMsg = ref.props.translator['Approved Successfully'];
				swal({
				  text: appsuccessMsg,
				  button: ref.props.translator['OK'],
				});
				ref.setState({ loader: false });
				ref.props.changeActionView('');
				let obj = null;
				obj = {
					"systemStartDateRequired": "N",
					"systemEndDateRequired": "N"
				};
				axios.get(GLOBAL.getLeftApprovalUrl + 'companyId=' + ref.props.itemDetails.consumerCompany + '&module=14&serviceId=' + ref.props.itemDetails.serviceId + '&workitemId=' + ref.props.itemDetails.changeId)
				.then((res) => {
					if(res.status == 200) {
						if(res.data  == '1' && ref.props.itemDetails.status == 'Under Review') {
							callApi = true;
						}else {
							if(res.data == '0') {
								callApi = true;
							}
							callApi = true;
						}
						if(callApi) {
							axios.patch(GLOBAL.changeEditUrl+ref.props.changeId, obj).then(function (response) {
								if(response.status === 200){
								store.dispatch({ type: 'CHANGE_EDIT_DATA_LOAD_SUCCESS', editChangeInitialData: {} });
								ref.props.loadChangeEditData(ref.props.changeId);
								ref.props.loadStatusBasedHamburgerOptions('ChangeManagement',response.data.status,response.data.changeId);
								}
							}).catch(function (error) {
							});
						ref.setState({approveSecHideShow:false, adHocSectionVisible: false, selectedBtnAction: ""});
						ref.setState({rejectSecHideShow:false, refBackVisible: false});
						this.props.loadChangeApprovalDetails("14",this.props.changeId,"");
						ref.setState({apprejbutton:false});
					}
					}
				});
				}
			});
		}
		else{
			let obj = {
				status: "Approved",
				approvedBy: "",
				lastUpdatedBy: "",
				approval_comments: approveTextValue.trim().replace(/"/g, '').replace(/\\/g, '\\\\').replace(/(\r\n|\n|\r)/gm, ""),
			};
			this.updateFulfillmentApprovalStatus(obj, 'approve');
		}
	}
    }
    postRejectedData(){
        const { rejectTextValue = "" } = this.state;
		const { module = 'Change' } = this.props;
		const { editChangeInitialData : { type = "" } = {} } = this.props;
        let ref = this;
        ref.setState({ loader: true });
        if (!(rejectTextValue && rejectTextValue.trim().length)) {
            ref.setState({ loader: false });
            let rejmsg = ref.props.translator['provide reason for rejection'];
            swal({
              text: rejmsg,
              button: ref.props.translator['OK'],
            });
            return false;
          } else{
            if(module == 'Change'){
				axios.put(GLOBAL.approvalsGeneratorRFCUrl+'/'+this.state.radioval, {
					"status":"Rejected",
					"rejectedBy":"",
					"rejectreason":rejectTextValue.trim(),
					"lastUpdatedBy": ""
				}).then((response) => {
					if (response.status == 200 || response.status == 201) {
	
						let rejsuccessMsg = ref.props.translator['Rejected Successfully'];
						swal({
							text: rejsuccessMsg,
							button: ref.props.translator['OK'],
						});
						ref.setState({ loader: false });
						ref.props.changeActionView('');
						let patchObj = {"systemStartDateRequired": "N"};
						if(type == '35'){
							patchObj['status'] = '50';
						}
						axios.patch(GLOBAL.changeEditUrl+ref.props.changeId, patchObj).then(function (response) {
										if(response.status === 200){
										store.dispatch({ type: 'CHANGE_EDIT_DATA_LOAD_SUCCESS', editChangeInitialData: {} });
										ref.props.loadChangeEditData(ref.props.changeId);
										ref.props.loadStatusBasedHamburgerOptions('ChangeManagement',response.data.status,response.data.changeId);
										}
									}).catch(function (error) {
						});
						ref.setState({approveSecHideShow:false, adHocSectionVisible: false, selectedBtnAction: ""});
						ref.setState({rejectSecHideShow:false, refBackVisible: false});
						this.props.loadChangeApprovalDetails("14",this.props.changeId,"");
						ref.setState({apprejbutton:false});
					}
				});
			}
			else{
				let obj = {
					status: "Rejected",
					rejectedBy: "",
					lastUpdatedBy: "",
					rejectreason: rejectTextValue.trim().replace(/"/g, '').replace(/\\/g, '\\\\').replace(/(\r\n|\n|\r)/gm, ""),
				};
				this.updateFulfillmentApprovalStatus(obj, 'reject');
			}
        }
    }
    submitAdhocApprovers() {
		if(this.state.adhocTextValue.trim() == ""){
			Swal.fire({
			  title: 'Please enter Approval Request Comments',
			})
			return;
		  }
		const { radioval = "", approversArray = [] } = this.state;
		let signatureId = radioval;
		let arr = structuredClone(this.state.approversArray);
		let adhocUserName = [];
		for (let i = 0; i < arr.length; i++) {
			let item = arr[i];
			item.approvalRequestComments = this.state.adhocTextValue;
			adhocUserName.push(" "+item.adhocApproverFullName.trim());
		}
		arr.forEach((item) => {
		  delete item.label;
		  delete item.adhocApproverFullName;
		});
		let obj = {};
		obj.adhocApproverFlag = 'Y';
		obj.adhocCreatedBy = '';
		obj.adhocApprovers = arr;
		this.setState({ adhocButtonDisabled: true });
		axios.post("/api/approval/adhocApprovers/" + signatureId, obj).then(response => {
			if (response.status == 200 || response.status == 201) {
			  this.props.getAdhocApprovers(signatureId);
			  this.clearAdhocData();
			  this.setState({ adhocButtonDisabled: false });
			  this.loadApprovalHistoryAndDetails();
			}
		  })
		  .catch(error => {
			this.setState({ adhocButtonDisabled: false });
		  })
		  let activityDetails ={
			"itemId": this.props.module == 'Change' ? this.props.itemDetails.changeId :this.props.itemDetails.itemId,
			"description": `Adhoc approval requested to${adhocUserName}`,
			"isInternal":"N",
			"createdOn":"",
			"createdBy":"",
			"module": this.props.module == 'Change' ? "ChangeManagement" : "Fullfilment",
			"createdByName":"",
			"sendNotification":"true",
			"isExternal":""
		  };
		  axios.post("/serviceManagement/rest/activity", activityDetails);
	}
    loadApprovalHistoryAndDetails() {
		const { module = 'Change' } = this.props;
		if(module == 'Change'){
			const { editChangeInitialData : { changeId = "", changeNumber = "", status = "Pending for approval" } = {} } = this.props;
			let modulenumber = changeNumber;
			let recordStatus = status;
			recordStatus = (recordStatus == "Refer Back" ? "Refer_Back" : recordStatus);
			this.props.loadChangeTotalApprovalDetails(changeId,"14","N", recordStatus);
		}
		else{
			const { itemDetails : { itemId, status } = {} } = this.props;
			let recordStatus = status;
			recordStatus = (recordStatus == "Refer Back" ? "Refer_Back" : recordStatus);
			this.props.loadChangeTotalApprovalDetails(itemId,"10","N", recordStatus);
		}
		
		// const { editChangeInitialData : { changeId = "", changeNumber = "", status = "Pending for approval" } = {} } = this.props;
		// let modulenumber = changeNumber;
		// let recordStatus = status;
		// recordStatus = (recordStatus == "Refer Back" ? "Refer_Back" : recordStatus);
		// if (modulenumber.indexOf("RFC") >= 0) {
		//   this.props.loadChangeTotalApprovalDetails(changeId,"14","N", recordStatus);
		// } else {
		//   this.props.loadChangeTotalApprovalDetails(changeId,"10","N", recordStatus);
		// }
	}

	updateFulfillmentApprovalStatus(obj = {}, status = ''){
		// console.log('*******', {obj, status, props: this.provps, state: this.state});
		const { radioval = '', referBackTextValue = '' } = this.state;
		const { itemDetails : { itemId } = {} } = this.props;
		const ref = this;
		axios
          .put("/api/approval/myapproval/" + radioval, obj)
          .then(response => {
            if (response.status == "201" || response.status == 201) {
                swal({
                  text: ref.props.tr["Another Approver has already taken action on this ticket."],
                  button: ref.props.tr['OK'],
                });
            }
            else if (response.status == "200" || response.status == 200) {
				let msg = "";
				switch(status){
					case 'referBack':
						msg = ref.props.translator['Referred Back Successfully'];
						break;
					case 'approve':
						msg = ref.props.translator['Approved Successfully'];
						break;
					case 'reject':
						msg = ref.props.translator['Rejected Successfully'];
						break;
				}
				swal({
					text: msg,
					button: ref.props.translator['OK'],
				});
				
				// api.post(GLOBAL.breakFixActivityLogPostUrl, {
				// 	"itemId": module.signaturelist[0][0].itemid,
				// 	"description": ref.props.tr["Approved Comments: "] + approveTextValueForActivityLog,
				// 	"isInternal": "N",
				// 	"createdOn": "",
				// 	"createdBy": "",
				// 	"module": module.itemType == "Fulfillment" ? "Fullfilment" : module.itemType,
				// 	"createdByName": "",
				// 	"isReorder": "Y"
				//   }).
				// 	then((response) => {
				// 	  // this.props.loadBreakFixActivityLogDetails(response.data.itemId, "desc", module.itemType, () => { }, "Y","RESPONSEAPPR");
				// 	});

            } else {
				let msg = 'Unable to process the request.';
				swal({
					text: msg,
					button: ref.props.translator['OK'],
				});
            }
			ref.setState({ loader: false });
			ref.setState({approveSecHideShow:false, adHocSectionVisible: false, selectedBtnAction: "", rejectSecHideShow:false, refBackVisible: false});
			ref.setState({apprejbutton:false});
			ref.props.changeActionView('');
			ref.props.loadFulfillmentDetails(itemId).then(res => {
				console.log('res - ', res);
				if(res.status == 200 || res.status == '200' || res.status == 201 || res.status == '201'){
					ref.props.loadStatusBasedHamburgerOptions("Item",res.data.status,res.data.itemId);
					ref.props.resetLoadHamburgerOptionsReducer()
				}
				ref.setState({ loader: false });
				ref.setState({approveSecHideShow:false, adHocSectionVisible: false, selectedBtnAction: "", rejectSecHideShow:false, refBackVisible: false});
				ref.setState({apprejbutton:false});
				ref.props.changeActionView('');
			}).catch(err => {
				console.log('err - ', err);
				ref.setState({ loader: false });
				ref.setState({approveSecHideShow:false, adHocSectionVisible: false, selectedBtnAction: "", rejectSecHideShow:false, refBackVisible: false});
				ref.setState({apprejbutton:false});
				ref.props.changeActionView('');
			});
          }).catch(err => {
			let msg = 'Unable to process the request.';
			swal({
				text: msg,
				button: ref.props.translator['OK'],
			});
			ref.setState({ loader: false });
			ref.setState({approveSecHideShow:false, adHocSectionVisible: false, selectedBtnAction: "", rejectSecHideShow:false, refBackVisible: false});
			ref.setState({apprejbutton:false});
			// ref.props.changeActionView('');
		  });
	}

    render(){
		console.log('*******', this.props);
		const { module = 'Change', changeApprovalDetails = [], changeTotalApprovalDetails = [] } = this.props;
        const { refBackTextValue = "", adHocSectionVisible = false, approveTextValue = "", rejectTextValue = "", selectedBtnAction = "" } = this.state;
		let dateformat = this.state.date_format.slice(0, 10).toUpperCase();
		let apprej="";
		if(this.state.apprejbutton==true){
			apprej=
			<ButtonToolbar style={{'width': 'max-content', 'margin-top': '15px'}}>
				<Button className={(selectedBtnAction == "approve") ? "rgSidrkBtn smallBtn" : "rgSiLigBtn smallBtn"} onClick={this.approveSecHideShow.bind(this)} bsStyle="primary" bsSize="small">{this.props.translator['Approve']}</Button>
				<Button className={(selectedBtnAction == "reject") ? "rgSidrkBtn smallBtn" : "rgSiLigBtn smallBtn"} onClick={this.rejectSecHideShow.bind(this)} bsStyle="primary" bsSize="small">{this.props.translator['Reject']}</Button>
				<Button className={(selectedBtnAction == "referBack") ? "rgSidrkBtn smallBtn" : "rgSiLigBtn smallBtn"} onClick={this.refBackSecHideShow.bind(this)} bsStyle="primary" bsSize="small">{this.props.translator['Refer Back']}</Button>
			</ButtonToolbar>;

		}
		else {
			apprej="";
		}
      return (<div className="hambTab">
		<div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
          <ul>
            <li>
            <button
			    type='button'
                title="Minimize the right panel"
                bsClass=""
                bsStyle=""
                className="closerightPanelBtn"
                onClick={() => {
                this.props.rightEditPanel(false);
                }}
            >
                <IoClose/>
            </button>
            </li>
          </ul>
        </div>
         <div className="rPageHeading">
			<div className="offNam margin-t-5 margin-b-5">{this.props.translator['Approvals']}</div>
		</div>
        <div className='rBoxGap'>
            <Table striped bordered condensed responsive className="f-size-13">
                <thead>
					<tr className='f-size-12'>
						<th width="20%">{this.props.translator['Action']}</th>
						<th width="20%">{this.props.translator['Level Name']}</th>
						<th width="40%">{this.props.translator['Status']}</th>
					</tr>
				</thead>
                <tbody>
					{this.renderApprovalLog(changeApprovalDetails)}
				</tbody>
            </Table>
              {apprej}
                {this.state.rejectSecHideShow ?
					<div className="margin-t-20">
						<div className="f-size-13 margin-t-20 margin-b-5 ">{this.props.translator['Reason for Rejection']}<span className="rStar"></span></div>
						<Form.Group>
							<Form.Control as="textarea" value={rejectTextValue} rows="3" maxLength={1000} onChange={(event) => { this.updateRejectTextField(event.target.value) }}
                                         onBlur={(event) => {
								                  let str = (event && event.target && event.target.value) ? event.target.value : "";
								                  str = str.trim();
								                  str = str.substring(0, 1000);
								                  this.setState({ rejectTextValue: str });}}/>
							<p className="charLeft" style={{textAlign:"right","fontSize":"11px"}}>({this.props.translator['Characters Remaining']}: {(1000 - rejectTextValue.length)}/1000)</p>
						</Form.Group>
						<div className="f-size-13 margin-t-20 margin-b-5 ">{this.props.translator['Are you sure you want to reject?']}</div>
						<ButtonToolbar>
							<Button className="rgSidrkBtn smallBtn" disabled={this.state.loader} bsSize="small" bsStyle="primary" onClick={() => { this.postRejectedData(); }}>
							    {this.state.loader?<ImSpinner6 className="icn-spinner"/> : null}
                                {this.props.translator['Yes']}
                            </Button>
							<Button className="rgSiLigBtn smallBtn" disabled={this.state.loader} bsSize="small" bsStyle="primary" onClick={() => {  this.props.changeActionView(''); }}>{this.props.translator['No']}</Button>
						</ButtonToolbar>
					</div>
			    :null}
                {(adHocSectionVisible == true) && <div className="margin-t-20">{(this.props.roleIdentification("10") == true) && this.additionalApproversSection()}</div>}
                {this.state.approveSecHideShow ?
					<div className="margin-t-20">
							<div className="margin-t-20">
								<div className="f-size-13 margin-t-20 margin-b-5 ">{this.props.translator['Approval Comments']}<span className="rStar"></span></div>
								<Form.Group>
									<Form.Control as="textarea" value={approveTextValue} rows="3" maxLength={1000} onChange={(event) => { this.updateApprovedTextField(event.target.value) }} onBlur={(event) => {
										let str = (event && event.target && event.target.value) ? event.target.value : "";
										str = str.trim();
										str = str.substring(0, 1000);
										this.setState({ approveTextValue: str });
									}} />
									<p className="charLeft" style={{textAlign:"right","fontSize":"11px"}}>({this.props.translator['Characters Remaining']}: {(1000 - approveTextValue.length)}/1000)</p>
								</Form.Group>
							</div>
						    <div className="f-size-13 margin-t-20 margin-b-5 ">{this.props.translator['Are you sure you want to approve?']}</div>
						        <ButtonToolbar>
							        <Button className="rgSidrkBtn smallBtn" disabled={this.state.loader} bsSize="small" bsStyle="primary" onClick={() => { this.postApprovedData(); }}>
								        {this.state.loader  ?<ImSpinner6 className="icn-spinner"/> : null}
                                        {this.props.translator['Yes']}
                                    </Button>
							    <Button className="rgSiLigBtn smallBtn" disabled={this.state.loader} bsSize="small" bsStyle="primary" onClick={() => { this.props.changeActionView(''); }}>{this.props.translator['No']}</Button>
						</ButtonToolbar>
					</div>
			    :null}
                {this.state.refBackVisible ?
					<div className="margin-t-20">
						<div className="f-size-13 margin-t-20 margin-b-5 ">{this.props.translator['Reason for Refer Back']}<span className="rStar"></span></div>
						<Form.Group>
							<Form.Control as="textarea" value={refBackTextValue} maxLength={500} rows="3" onChange={(event) => {
								let str = (event && event.target && event.target.value) ? event.target.value : "";
								this.setState({ refBackTextValue: str });
							}}
							onBlur={(event) => {
								let str = (event && event.target && event.target.value) ? event.target.value : "";
								str = str.trim();
								str = str.substring(0, 500);
								this.setState({ refBackTextValue: str });
							}} />
							<p className="charLeft" style={{textAlign:"right","fontSize":"11px"}}>({this.props.translator['Characters Remaining']}: {(500 - refBackTextValue.length)}/500)</p>
						</Form.Group>
						<ButtonToolbar>
							<Button className="rgSidrkBtn smallBtn" bsSize="small" bsStyle="primary" disabled={this.state.loader} onClick={() => { this.postRefBackData(); }}>
							    {this.state.loader?<ImSpinner6 className="icn-spinner"/> : null}
                                {this.props.translator['Submit']}
                            </Button>
							<Button className="rgSiLigBtn smallBtn" disabled={this.state.loader} bsSize="small" bsStyle="primary" onClick={() => {  this.props.changeActionView(''); }}>{this.props.translator['Cancel']}</Button>
						</ButtonToolbar>
					</div>
			 	:null}
        </div>
      </div>);
    }
}
export function mapStateToProps({ changeApprovalDetails, adhocApproversList, changeTotalApprovalDetails, requesterDelegateeDetails}){
    return { changeApprovalDetails, adhocApproversList, changeTotalApprovalDetails, requesterDelegateeDetails };
}
function mapDispatchToProps(dispatch){
    return bindActionCreators({loadChangeApprovalDetails, loadChangeEditData, loadBreakFixAuditLogDetails, loadStatusBasedHamburgerOptions, loadTimelineData, getAdhocApprovers,
                               loadChangeTotalApprovalDetails, getRequesterDelegateeForApprovalDetails, loadFulfillmentDetails, resetLoadHamburgerOptionsReducer}, dispatch);
}
export default connect(mapStateToProps,mapDispatchToProps)(ProvideAttachmentsTab);
