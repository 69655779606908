
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Link } from 'react-router-dom';
import { loadInvRelatedWorkDetails, clearInvRelatedWorkDetails } from '_Actions/investigation/invRelatedWorkAction';
import { loadInvRelatedDataDetails,loadRelatedBreakFixDataToProblem } from '_Actions/investigation/invRelatedDataAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { GLOBAL } from '_Globals';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';
import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";
import Pagination from "react-js-pagination";
import { HiPlus } from "react-icons/hi";
import { PiListBulletsBold } from 'react-icons/pi';
const MoreArrow = <i title="More" className="fa fa-angle-double-down f-size-15" ></i>;


class PopoverWorkItemsData extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showUserData: false,
			messagedisplay: true,
			toggleFormAndList: 'list',
			textEntered:'',
			disablePostButton: false,
			pageSize: 10,
			pageNumber: 0,
			hideLoader: false
		};
		GLOBAL.relatedfilterval = "";
		GLOBAL.checkmoduleval = "Investigation";
		this.toggleFormAndList = this.toggleFormAndList.bind(this);
		this.checkConditions = this.checkConditions.bind(this);
		this.onPageSizeChange = this.onPageSizeChange.bind(this);
		this.onPageChange = this.onPageChange.bind(this);
		this.parametrizedData = this.parametrizedData.bind(this);
		this.loadDataToRelate = this.loadDataToRelate.bind(this);
	}

	componentWillMount() {
		//let getBreakFixItemId = (this.props.breakFixItemId) ? (this.props.breakFixItemId).split(":") :""
		//let BRKItemId = getBreakFixItemId[0];
		//let breakFixActualItemId = getBreakFixItemId[1];
		let itemId = this.props.itemId;
		this.props.loadInvRelatedDataDetails(itemId);
		GLOBAL.relatedtextdisplay = <div className="f-size-18 margin-b-5">{this.props.translator['Related Work Items']}</div>
		this.setState({ messagedisplay: true });
	}

    componentWillUnmount(){
        this.props.invRelatedWorkDetails.length=0;
        this.renderRelatedWorkLog(this.props.invRelatedWorkDetails);

    }

	toggleFormAndList() {
		GLOBAL.relatedfiltertext = '';
		if (this.state.toggleFormAndList == 'list')
			this.setState({ toggleFormAndList: 'form',textEntered:'' });
		else
			this.setState({ toggleFormAndList: 'list',textEntered:'' });
		this.setState({pageSize: 10, pageNumber: 0, hideLoader: false});
		this.props.clearInvRelatedWorkDetails();
	}

	postRelatedData(brkid, modnumber, modulesearchparam) {
		//let getBreakFixItemId = (this.props.breakFixItemId) ? (this.props.breakFixItemId).split(":") :""
		//let BRKItemId = getBreakFixItemId[0];
		//let breakFixActualItemId = getBreakFixItemId[1];
		//const offering = this.props.offering;
		this.setState({ disablePostButton: true });
		//this.props.fun(false,true,true);
		let itemId = this.props.itemId;
		axios.post(GLOBAL.breakFixRelatedLogPostUrl, {
			"itemId": itemId,
			"status": "A",
			"createdOn": "",
			"createdBy": "",
			"modifiedOn": "",
			"modifiedBy": 0,
			"module": "Investigation",
			"workItemId": brkid,
			"workModule": modulesearchparam,
			"workItemNumber": modnumber,
			"createdByName": this.props.full_name,
			"relationType": "p2p"
		}).
			then((response) => {
				//Swal.fire("Data successfully submitted")
				this.setState({ pageNumber: 0, pageSize: 10, hideLoader: false});
				this.props.clearInvRelatedWorkDetails();
				this.props.loadInvRelatedWorkDetails("investigationNumber", "-1", "Investigation");
				GLOBAL.relatedtextdisplay = <div className="f-size-18 margin-b-5">{this.props.translator['Related Work Items']}</div>
				this.props.loadInvRelatedDataDetails(itemId);
				if(GLOBAL.checkmoduleval==='Breakfix')
				this.setState({ disablePostButton: false });
				this.props.loadRelatedBreakFixDataToProblem(this.props.investigationItemDetails.investigationId,"breakfix","Investigation");
				this.setState({ messagedisplay: true });
				this.setState({ toggleFormAndList: 'list' });
			});

	}

	deleteRelation(relationId,workModule) {
		let itemId = this.props.itemId;
		axios.delete(GLOBAL.relatedLogDeleteUrl + relationId)
			.then((response) => {
				if (!response || response.status != '200') {
					Swal.fire('Deletion failed.');
				}
				else {
					Swal.fire('deleted relation');
					console.log(response);
					this.props.loadInvRelatedWorkDetails("investigationNumber", "-1", "Investigation");
					GLOBAL.relatedtextdisplay = <div className="f-size-18 margin-b-5">{this.props.translator['Related Work Items']}</div>
					if(workModule==="Breakfix"){
						this.props.loadRelatedBreakFixDataToProblem(this.props.investigationItemDetails.investigationId,"breakfix","Investigation")
					}
					this.props.loadInvRelatedDataDetails(itemId);
					this.setState({ messagedisplay: true });
				}
			});
	}

	renderRelatedWorkLog(relatedLogData) {
		//Swal.fire(""+auditLogData)
		//let relatedlogdataactual=[];
		if (relatedLogData.length == 0) {
            return (
                <div></div>
            );
        }
        if (typeof relatedLogData.data==='undefined' || relatedLogData.data.length == 0) {
            return (
                <tr>
                    <td width="100%">
                        <div>{this.props.translator["No Data Available..."]}</div>
                    </td>
                </tr>
            );
        }
		else {
			return <>
			  <Row className='padding-t-10'>
					<Col lg={8} md={12} sm={8} xs={12}>
					    <Form.Label className="margin-r-5 display-inline-block widthAuto">{this.props.translator['Show']}</Form.Label>
						<Form.Select className="display-inline-block widthAuto" onChange={this.onPageSizeChange}>
							<option value="10" selected={this.state.pageSize == 10 ? true : false}>10</option>
							<option value="25" selected={this.state.pageSize == 25 ? true : false}>25</option>
							<option value="50" selected={this.state.pageSize == 50 ? true : false}>50</option>
						  </Form.Select>
					</Col>
			  </Row>
			  <Table  responsive striped bordered condensed hover className='f-size-13'> 
					<thead>
						<tr>
						<th width="80%">{this.props.translator['Related Work Items']}</th>
						<th width="60%">{this.props.translator['Created On']}</th>
						<th width="60%">{this.props.translator['Created By']}</th>
						<th width="60%">{this.props.translator['Action']}</th>
						</tr>
					</thead>
					<tbody>
                        {relatedLogData.data.map((relatedLogData, index) => {
							let modnumber = "", modid = "", modRequester = "";
							let modulesearchparam = GLOBAL.checkmoduleval;

							if (modulesearchparam == "Breakfix") {
								modnumber = relatedLogData.breakfixNumber
								modid = relatedLogData.breakfixId
								modRequester = relatedLogData.impactedUsername

							}
							if (modulesearchparam == "Fullfilment") {
								modnumber = relatedLogData.itemNumber
								modid = relatedLogData.itemId
								modRequester = relatedLogData.requesterName
							}
							if (modulesearchparam == "Investigation") {
								modnumber = relatedLogData.investigationNumber
								modid = relatedLogData.investigationId
								modRequester = relatedLogData.impactedUsername

							}
							if (modulesearchparam == "ChangeManagement") {
								modnumber = relatedLogData.changeNumber
								modid = relatedLogData.changeId
								modRequester = relatedLogData.requesterName

							}
							if(!relatedLogData.description){
								relatedLogData.description = "";
							}
							return (<tr>
								<td>{modnumber}</td>
								<td>{relatedLogData.createdOn}</td>
								<td>{modRequester}</td>
								<td className="text-c f-size-13">
										<a onClick={this.state.disablePostButton?((event=>event.preventDefault())):this.postRelatedData.bind(this, modid, modnumber, modulesearchparam)} title={this.props.translator['Relate']}>
											<i className="fa fa-chevron-circle-right f-size-16 theme1"></i>
										</a>
								</td>
								</tr>)
						})}
					</tbody>
			 </Table>
			 <Row>
					<div className="nBotPagina">
                        <nav aria-label="Pagination" className="display-inline-block">
                          {relatedLogData.meta ? (
                            <Pagination activePage={relatedLogData.meta ? this.state.pageNumber+1 : 0}
                            itemsCountPerPage={this.state.pageSize}
                            totalItemsCount={relatedLogData.meta ? relatedLogData.meta.recordCount : 0}
                            onChange={this.onPageChange}/>) : null}
                        </nav>
                    </div>
			</Row>		
			</>
		}
	}

	renderAttList(attributeData) {
		//console.log(attributeData)
		// console.log('**********', {props: this.props, attributeData});
		if (!attributeData) {
			return (
				null
			);
		}
		return attributeData.map((objjectData) => {
			if (objjectData.itemId != null && objjectData.itemId != "") {
				if ((this.props && this.props.investigationdetail && this.props.investigationdetail.status && (this.props.investigationdetail.status == "Fixed" || this.props.investigationdetail.status == "Closed" || this.props.investigationdetail.status == "Completed" || this.props.investigationdetail.status == "Cancelled"))) {
					if(objjectData.workModule==='ChangeManagement'){
						return (
							<tr>
								<td>{this.props.isChangedRolesEnabled?<Link to={"/editChange/"+objjectData.workItemId}>{objjectData.workItemNumber}</Link>:objjectData.workItemNumber}</td>
								<td>{objjectData.createdOn}</td>
								<td>{objjectData.createdByName}</td>
								{ ( this.checkConditions('RelateWorkItems-Draft') == true && !this.props.disableFieldsForGuestRole )  &&
									(<td></td>)
								}
						</tr>
						);
					}
				else if(objjectData.workModule==='Breakfix'){
					return (
						<tr>
								<td>{this.props.isIncidentRolesEnabled?<Link to={"/editBreakFix/"+objjectData.workItemId}>{objjectData.workItemNumber}</Link>:objjectData.workItemNumber}</td>
								<td>{objjectData.createdOn}</td>
								<td>{objjectData.createdByName}</td>
								{ (this.checkConditions('RelateWorkItems-Draft') == true && !this.props.disableFieldsForGuestRole)  &&
									(<td></td>)
								}
						</tr>
					)
				}
				else if(objjectData.workModule==='Investigation'){
					return (
						<tr>
								<td>{this.props.isProblemRolesEnabled?<Link to={"/investigationEdit/"+objjectData.workItemId}>{objjectData.workItemNumber}</Link>:objjectData.workItemNumber}</td>
								<td>{objjectData.createdOn}</td>
								<td>{objjectData.createdByName}</td>
								{ (this.checkConditions('RelateWorkItems-Draft') == true && !this.props.disableFieldsForGuestRole)  &&
									(<td></td>)
								}
						</tr>
					)
				}
				else if(objjectData.workModule==='Fullfilment'){
					return (
						<tr>
								<td>{this.props.isFulfillmentRolesEnabled?<Link to={"/fulFillment/"+objjectData.workItemId}>{objjectData.workItemNumber}</Link>:objjectData.workItemNumber}</td>
								<td>{objjectData.createdOn}</td>
								<td>{objjectData.createdByName}</td>
								{ ( this.checkConditions('RelateWorkItems-Draft') == true && !this.props.disableFieldsForGuestRole)  &&
									(<td></td>)
								}
						</tr>
					)
				}

					// return (
					// 	<tr>
					// 		<td>{objjectData.workItemNumber}</td>
					// 		<td>{objjectData.createdOn}</td>
					// 		<td>{objjectData.createdByName}</td>
					// 		<td></td>
					// 	</tr>
					// );
				}
				else {
					if(objjectData.workModule==='ChangeManagement'){
						return (
							<tr>
								<td>{this.props.isChangedRolesEnabled?<Link to={"/editChange/"+objjectData.workItemId}>{objjectData.workItemNumber}</Link>:objjectData.workItemNumber}</td>
								<td>{objjectData.createdOn}</td>
								<td>{objjectData.createdByName}</td>
								{ ( this.checkConditions('RelateWorkItems-Draft') == true && !this.props.disableFieldsForGuestRole)  &&
									(<td className="text-c f-size-13">{objjectData.relationType == 'p2p' ? <i onClick={this.deleteRelation.bind(this, objjectData.relationId,objjectData.workModule)} className="fa fa-trash-o cursorPoint"></i> : ''}</td>)
								}
						</tr>
						);
					}
				else if(objjectData.workModule==='Breakfix'){
					return (
						<tr>
								<td>{this.props.isIncidentRolesEnabled?<Link to={"/editBreakFix/"+objjectData.workItemId}>{objjectData.workItemNumber}</Link>:objjectData.workItemNumber}</td>
								<td>{objjectData.createdOn}</td>
								<td>{objjectData.createdByName}</td>
								{ ( this.checkConditions('RelateWorkItems-Draft') == true && !this.props.disableFieldsForGuestRole)  &&
									(<td className="text-c f-size-13">{objjectData.relationType == 'p2p' ? <i onClick={this.deleteRelation.bind(this, objjectData.relationId,objjectData.workModule)} className="fa fa-trash-o cursorPoint"></i> : ''}</td>)
								}
						</tr>
					)
				}
				else if(objjectData.workModule==='Investigation'){
					return (
						<tr>
								<td>{this.props.isProblemRolesEnabled?<Link to={"/investigationEdit/"+objjectData.workItemId}>{objjectData.workItemNumber}</Link>:objjectData.workItemNumber}</td>
								<td>{objjectData.createdOn}</td>
								<td>{objjectData.createdByName}</td>
								{ ( this.checkConditions('RelateWorkItems-Draft') == true && !this.props.disableFieldsForGuestRole)  &&
									(<td className="text-c f-size-13">{objjectData.relationType == 'p2p' ? <i onClick={this.deleteRelation.bind(this, objjectData.relationId,objjectData.workModule)} className="fa fa-trash-o cursorPoint"></i> : null}</td>)
								}
						</tr>
					)
				}
				else if(objjectData.workModule==='Fullfilment'){
					return (
						<tr>
								<td>{this.props.isFulfillmentRolesEnabled?<Link to={"/fulFillment/"+objjectData.workItemId}>{objjectData.workItemNumber}</Link>:objjectData.workItemNumber}</td>
								<td>{objjectData.createdOn}</td>
								<td>{objjectData.createdByName}</td>
								{ ( this.checkConditions('RelateWorkItems-Draft') == true && !this.props.disableFieldsForGuestRole)  &&
									(<td className="text-c f-size-13">{objjectData.relationType == 'p2p' ? <i onClick={this.deleteRelation.bind(this, objjectData.relationId,objjectData.workModule)} className="fa fa-trash-o cursorPoint"></i> : ''}</td>)
								}
						</tr>
					)
				}

					// return (
					// 	<tr>
					// 		<td>{objjectData.workItemNumber}</td>
					// 		<td>{objjectData.createdOn}</td>
					// 		<td>{objjectData.createdByName}</td>
					// 		<td className="text-c f-size-13">{objjectData.relationType == 'p2p' ? <i onClick={this.deleteRelation.bind(this, objjectData.relationId)} className="fa fa-trash-o cursorPoint"></i> : ''}</td>
					// 	</tr>
					// );
				}
			}
			else
				return (
					<tr>
					</tr>
				);
		});
	}

	renderRelatedDataLog(relatedDataLog) {
		console.log('relatedDataLog');
		console.log(relatedDataLog);
		if (relatedDataLog.length == 0) {
			return (
				<div>{this.props.translator['Data not available.']}</div>
			);
		}

		else {
			return (
				<Table responsive striped bordered condensed hover className="f-size-13">
				<thead>
					<tr className="f-size-12">
						<th>{this.props.translator['Work Item Number']}</th>
						<th>{this.props.translator['Created On']}</th>
						<th>{this.props.translator['Related By']}</th>
						{ (this.checkConditions('RelateWorkItems-Draft') == true && !this.props.disableFieldsForGuestRole  ) ?<th className="text-c">{this.props.translator['Action']}</th>:null}
					</tr>
				</thead>
				<tbody>
					{this.renderAttList(relatedDataLog)}
				</tbody>
				</Table>
			);
		}
	}

	// renderRelatedDataLog(relatedDataLog) {
	// 	//alert(""+auditLogData)
	// 	if (!relatedDataLog) {
	// 		return (
	// 			<tr>
	// 				<td width="100%">
	// 					<div>{this.props.translator['Data not available.']}</div>
	// 				</td>
	// 			</tr>
	// 		);
	// 	}
	// 	else {


	// 		return relatedDataLog.map((relatedData, index) => {


	// 			return (
	// 				<Media>
	// 					{/* <Media.Left align="top">
	// 				<Image src="views/images/rahul.jpg" alt="profile image" />
	// 			  </Media.Left>*/}
	// 			  <Media.Body>
	// 				<Row>
	// 					<Col md={10} className="padding-r-0"><Media.Heading className="blue margin-0"> {relatedData.workItemNumber} - {relatedData.createdOn}  - <span className="colorStOrange cursorPoint" onClick={this.showUserDetails}>Related By {relatedData.createdByName}</span></Media.Heading><div className="black fw-300 cTxt"><ReadMoreAndLess lines={1} onShowMore={this.props.onChange} text={MoreArrow}></ReadMoreAndLess></div></Col>

	// 						</Row>
	// 					</Media.Body>

	// 				</Media>
	// 			);
	// 		});

	// 	}
	// }

	loaddata() {
		this.props.clearInvRelatedWorkDetails();
		//let getBreakFixItemId = (this.props.breakFixItemId) ? (this.props.breakFixItemId).split(":") :""
		//let BRKItemId = getBreakFixItemId[0];
		//let breakFixActualItemId = getBreakFixItemId[1];
		let itemId = this.props.itemId
		let modulesearch = GLOBAL.checkmoduleval;
		console.log("GLOBAL.relatedfilterval",GLOBAL.relatedfilterval,this.props.investigationItemDetails.consumerCompany,modulesearch);
		console.log(this.props);
		this.loadDataToRelate(modulesearch,itemId);
		GLOBAL.relatedtextdisplay = <div className="f-size-18 margin-b-5"></div>
		//this.props.loadInvRelatedDataDetails("0");
		this.setState({ messagedisplay: false,  pageNumber: 0, pageSize: 10,  hideLoader: false });

	}
	loadrelateddata() {
		//let getBreakFixItemId = (this.props.breakFixItemId) ? (this.props.breakFixItemId).split(":") :""
		//let BRKItemId = getBreakFixItemId[0];
		//let breakFixActualItemId = getBreakFixItemId[1];
		let itemId = this.props.itemId

		this.props.loadInvRelatedWorkDetails("investigationNumber", "-1", "Investigation");
		GLOBAL.relatedtextdisplay = <div className="f-size-18 margin-b-5">{this.props.translator['Related Work Items']}</div>
		this.props.loadInvRelatedDataDetails(itemId);
		this.setState({ messagedisplay: true });


	}

	checkfilter(i) {
		GLOBAL.relatedfilterval = i.target.value;
		this.setState({textEntered:''})
	}
	filtertext(i) {
		//alert(i.target.value)
		GLOBAL.relatedfiltertext = i.target.value;
		this.setState({textEntered:i.target.value})
	}
	checkmodule(i) {
		GLOBAL.checkmoduleval = i.target.value;
		this.setState({textEntered:''})
		this.setState({ pageNumber: 0, pageSize: 10, hideLoader: false});
		this.props.clearInvRelatedWorkDetails();
	}

	// render() {
	// 	return (
	// 		<div>
	// 			<div className="f-size-18 margin-b-5">{this.props.translator['Relate Work Item']}</div>
	// 			<Row>
	// 				<Col md={12}><Form.Group className="margin-b-5">
	// 				<Form.Label>{this.props.translator['Type']}</Form.Label>
	// 				<Form.Select componentClass="select" onChange={this.checkmodule.bind(this)}>
	// 				<option value="Select">Select</option>
	// 				<option value="Investigation">Investigation</option>
	// 				<option value="Breakfix">Breakfix</option>
	// 				<option value="Fullfilment">Fullfilment</option>
	// 				<option value="ChangeManagement">Change</option>
	// 				</Form.Select>
	// 				</Form.Group>
	// 				</Col>
	// 			</Row>
	// 			<div className="bgGray padding-10 tbox">
	// 				<Row>
	// 					<Col md={6} className="padding-r-0">
	// 					<Form.Label>{this.props.translator['Search by']}</Form.Label>
	// 					<Form.Control componentClass="select" onChange={this.checkfilter.bind(this)} >
	// 					<option value="Select">Select</option>
	// 					{/*<option value="Keyword">Keyword</option>*/}
	// 					<option value="breakfixNumber">Item ID</option>
	// 					<option value="impactedUserid">Same Requestor</option>
	// 					<option value="serviceId">Same Service</option>
	// 					</Form.Control>
	// 					</Col>
	// 					<Col md={6}><Form.Label>&nbsp;</Form.Label><Form.Control type="text" onChange={this.filtertext.bind(this)} placeholder={this.props.translator['search']} /></Col>
	// 				</Row>
	// 				{/*<div className="btnNew padding-5">&nbsp;<a onClick={this.loadrelateddata.bind(this)} title="Back to list" className="srch"><i className="fa fa-search"></i></a></div>
	// 			<div className="btnNew padding-5">&nbsp;<a onClick={this.loaddata.bind(this)} title="Search" className="srch"><i className="fa fa-search"></i></a></div>*/}
	// 			<div className="btnNew padding-5">{this.state.messagedisplay==false?<a onClick={this.loadrelateddata.bind(this)} className="backList"><i className="fa fa-angle-double-left"></i>{this.props.translator['Back to List']} </a>:<a></a>}&nbsp;
	// 			<a onClick={this.loaddata.bind(this)} title={this.props.translator['Search']} className="srch"><i className="fa fa-search"></i></a></div>
	// 			</div>

	// 			{this.renderRelatedWorkLog(this.props.invRelatedWorkDetails)}
	// 			{GLOBAL.relatedtextdisplay}
	// 			{this.renderRelatedDataLog(this.props.invRelatedDataDetails)}



	// 		</div>
	// 	);
	// }

	checkConditions(status = ''){
		try{
			const { fieldStatus : { relateWorkItems = false } = {}, investigationItemDetails : { status_id = "" } = {} } = this.props;
			switch(status){
				case 'RelateWorkItems-Draft':
					return ( relateWorkItems == true || (status_id && status_id.toString() == "30") );
				default:
					return false;
			}
		}catch(e){
			return false;
		}
	}
	onPageSizeChange(event){
		this.setState({pageSize: event.target.value, pageNumber: 0, hideLoader: true});
		this.parametrizedData(event.target.value,'0');
	  }
	  onPageChange(number){
		this.setState({pageNumber:number-1, hideLoader: true});
		this.parametrizedData(this.state.pageSize,number-1);
	  }
	  parametrizedData(pageSize, pageNumber){
		let itemId = this.props.itemId
		let modulesearch = GLOBAL.checkmoduleval;
		this.props.clearInvRelatedWorkDetails();
		this.loadDataToRelate(modulesearch,itemId,pageSize,pageNumber);
	  }
	  loadDataToRelate(modulesearch,itemId,pageSize,pageNumber){
		if (modulesearch == "Breakfix") {
			if (GLOBAL.relatedfilterval == "breakfixNumber")
				this.props.loadInvRelatedWorkDetails(GLOBAL.relatedfilterval, GLOBAL.relatedfiltertext, "Breakfix",this.props.investigationItemDetails.consumerCompany,'','',pageSize,pageNumber);
			if (GLOBAL.relatedfilterval == "impactedUserid")
				this.props.loadInvRelatedWorkDetails(GLOBAL.relatedfilterval, this.props.investigationItemDetails.impactedUserid, "Breakfix",this.props.investigationItemDetails.consumerCompany,'','',pageSize,pageNumber);
			if (GLOBAL.relatedfilterval == "serviceId")
				this.props.loadInvRelatedWorkDetails(GLOBAL.relatedfilterval, this.props.investigationItemDetails.serviceId, "Breakfix",this.props.investigationItemDetails.consumerCompany);
			if(GLOBAL.relatedfilterval=="ciId")
			    this.props.loadInvRelatedWorkDetails(GLOBAL.relatedfilterval, this.props.investigationItemDetails.ciId, "Breakfix",this.props.investigationItemDetails.consumerCompany,'','',pageSize,pageNumber);
		}
		if (modulesearch == "Fullfilment") {
			if (GLOBAL.relatedfilterval == "breakfixNumber")
				this.props.loadInvRelatedWorkDetails("itemNumber", GLOBAL.relatedfiltertext, "Fullfilment",this.props.investigationItemDetails.consumerCompany,'','',pageSize,pageNumber);
			if (GLOBAL.relatedfilterval == "impactedUserid")
				this.props.loadInvRelatedWorkDetails("requesterId", this.props.investigationItemDetails.impactedUserid, "Fullfilment",this.props.investigationItemDetails.consumerCompany,'','',pageSize,pageNumber);
			if (GLOBAL.relatedfilterval == "serviceId")
				this.props.loadInvRelatedWorkDetails("offeringIdSx", this.props.investigationItemDetails.serviceId, "Fullfilment",this.props.investigationItemDetails.consumerCompany);
			if(GLOBAL.relatedfilterval=="ciId")
			    this.props.loadInvRelatedWorkDetails(GLOBAL.relatedfilterval, this.props.investigationItemDetails.ciId, "Fullfilment",this.props.investigationItemDetails.consumerCompany);
		}
		if (modulesearch == "Investigation") {
			if (GLOBAL.relatedfilterval == "breakfixNumber")
				this.props.loadInvRelatedWorkDetails("investigationNumber", GLOBAL.relatedfiltertext, "Investigation",this.props.investigationItemDetails.consumerCompany,itemId,"Y",pageSize,pageNumber);
			if (GLOBAL.relatedfilterval == "impactedUserid")
				this.props.loadInvRelatedWorkDetails(GLOBAL.relatedfilterval, this.props.investigationItemDetails.impactedUserid, "Investigation",this.props.investigationItemDetails.consumerCompany,itemId,"Y",pageSize,pageNumber);
			if (GLOBAL.relatedfilterval == "serviceId")
				this.props.loadInvRelatedWorkDetails(GLOBAL.relatedfilterval, this.props.investigationItemDetails.serviceId, "Investigation",this.props.investigationItemDetails.consumerCompany,itemId,"Y");
			if(GLOBAL.relatedfilterval=="ciId")
			    this.props.loadInvRelatedWorkDetails(GLOBAL.relatedfilterval, this.props.investigationItemDetails.ciId, "Investigation",this.props.investigationItemDetails.consumerCompany,itemId,"Y",pageSize,pageNumber);
		}
		if (modulesearch == "ChangeManagement") {
			if (GLOBAL.relatedfilterval == "breakfixNumber")
				this.props.loadInvRelatedWorkDetails("changeNumber", GLOBAL.relatedfiltertext, "ChangeManagement",this.props.investigationItemDetails.consumerCompany,'','',pageSize,pageNumber);
			if (GLOBAL.relatedfilterval == "impactedUserid")
				this.props.loadInvRelatedWorkDetails("requesterId", this.props.investigationItemDetails.impactedUserid, "ChangeManagement",this.props.investigationItemDetails.consumerCompany,'','',pageSize,pageNumber);
			if (GLOBAL.relatedfilterval == "serviceId")
				this.props.loadInvRelatedWorkDetails(GLOBAL.relatedfilterval, this.props.investigationItemDetails.serviceId, "ChangeManagement",this.props.investigationItemDetails.consumerCompany);
			if(GLOBAL.relatedfilterval=="ciId")
			    this.props.loadInvRelatedWorkDetails(GLOBAL.relatedfilterval, this.props.investigationItemDetails.ciId, "ChangeManagement",this.props.investigationItemDetails.consumerCompany,'','',pageSize,pageNumber);
		}
	  }
	render() {
		console.log("ptprs:::",this.props);
		// console.log("**********",{state: this.state, props: this.props});
		let formVariable = <div>
			<Row>
				<Col md={12}><Form.Group className="margin-b-5">
					<Form.Label>{this.props.translator['Type']}</Form.Label>
					<Form.Select componentClass="select" onChange={this.checkmodule.bind(this)}>
						<option value="Investigation">Problem</option>
						<option value="Breakfix">Incident</option>
						<option value="Fullfilment">Fulfillment</option>
						<option value="ChangeManagement">Change</option>
					</Form.Select>
				</Form.Group>
				</Col>
			</Row>
			<div>
				<Row>
					<Col md={6} className="padding-r-0">
						<Form.Label>{this.props.translator['Search by']}</Form.Label>
						<Form.Select componentClass="select" onChange={this.checkfilter.bind(this)} >
						    <option value="">Select</option>
						{this.props.investigationItemDetails.serviceId!='0' || this.props.investigationItemDetails.serviceId!=0?<option value="serviceId">Same Service</option>:null}
							{/*<option value="Keyword">Keyword</option>*/}
							<option value="breakfixNumber">Item ID</option>
							<option value="impactedUserid">Same Requestor</option>
                            {GLOBAL.checkmoduleval=="Fullfilment"?null:this.props.investigationItemDetails.ciId!='0' || this.props.investigationItemDetails.ciId!=0?<option value="ciId">Same Impacted CI</option>:null}
						</Form.Select>
					</Col>
					<Col md={6}><Form.Label>&nbsp;</Form.Label><Form.Control type="text" onChange={this.filtertext.bind(this)} value={this.state.textEntered} placeholder={this.props.translator['search']} onKeyPress={(e) => {e.key === "Enter" && e.preventDefault();}}/></Col>
				</Row>
				{/*<div className="btnNew padding-5">&nbsp;<a onClick={this.loadrelateddata.bind(this)} title="Back to list" className="srch"><i className="fa fa-search"></i></a></div>
				<div className="btnNew padding-5">&nbsp;<a onClick={this.loaddata.bind(this)} title="Search" className="srch"><i className="fa fa-search"></i></a></div>*/}
				<div className="btnNew margin-t-10">
					{/* {this.state.messagedisplay == false ?<a onClick={this.loadrelateddata.bind(this)} className="backList"> */}
					{/*<i className="fa fa-angle-double-left"></i>{this.props.translator['Back to List']}*/}
					{/* </a> :	<a></a>	} */}

					{/* <a onClick={this.loaddata.bind(this)} title={this.props.translator['Search']} className="srch">
						<i className="fa fa-search"></i></a> */}

					<Button className="rgSidrkBtn smallBtn" title={this.props.translator['Search']}
						disabled={this.props.showLoader && !this.state.hideLoader } onClick={this.loaddata.bind(this)}>
						{this.props.translator['Search']}
						{this.props.showLoader && !this.state.hideLoader ?
							<ImSpinner6 className="icn-spinner"/>
							: null
						}
					</Button>


				</div>
			</div>
            {!this.props.showLoader.loading ? this.renderRelatedWorkLog(this.props.invRelatedWorkDetails) :<div>{""}</div>}
		</div>;
		return (
			<div className="margin-b-10">
				<div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
					<ul>
					{((this.props && this.props.investigationdetail && this.props.investigationdetail.status && (this.props.investigationdetail.status == "Fixed" || this.props.investigationdetail.status == "Closed" || this.props.investigationdetail.status == "Completed" || this.props.investigationdetail.status == "Cancelled" || this.props.disableFieldsForGuestRole)))
					? null :
					<li><Button title={this.state.toggleFormAndList == 'list' ? this.props.translator['Related Work Items'] : this.props.translator['Relate Work Item']}
					 onClick={this.toggleFormAndList}  bsStyle="" bsClass="" bsPrefix=" " className="myBt">
						{ (this.checkConditions('RelateWorkItems-Draft') == true && !this.props.disableFieldsForGuestRole) ? 
						this.state.toggleFormAndList == 'list' ? <HiPlus /> : <PiListBulletsBold /> 
						: null}
					</Button></li>
				    }
						<li className='d-md-none'>
						<button
						    type='button'
							title="Minimize the right panel"
							bsClass=""
							bsStyle=""
							className="closerightPanelBtn"
							onClick={() => {
							this.props.rightEditPanel(false);
							}}
						>
							<IoClose />
						</button>
						</li>
					</ul>
				</div>
				<div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.state.toggleFormAndList == 'list' ? this.props.translator['Related Work Items'] : this.props.translator['Relate Work Item']}</div></div>
				<div className='rBoxGap'>

					{this.state.toggleFormAndList == 'list' || this.props.disableFieldsForGuestRole ?
						this.renderRelatedDataLog(this.props.invRelatedDataDetails)
						:
						formVariable
					}
				</div>
			</div>
		);
	}
}

export function mapStateToProps({ invRelatedWorkDetails, invRelatedDataDetails, rightMenuShowLoader }) {
	//  alert("breakFixEditDetails:"+breakFixAuditLogDetails);
	console.log("rightMenuShowLoader invRelatedWorkDetails>>: ", invRelatedWorkDetails);
	return { invRelatedWorkDetails, invRelatedDataDetails, showLoader: rightMenuShowLoader.loading };
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ loadInvRelatedWorkDetails, loadInvRelatedDataDetails, loadRelatedBreakFixDataToProblem, clearInvRelatedWorkDetails }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PopoverWorkItemsData);
