
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Cookies from "universal-cookie";
import { getDelegationList } from "_Actions/delegations/delegationsActions";
import { connect } from "react-redux";
import Spinner from "react-spinkit";
import ListLoader from "_Commons/loaders/ListLoader";
import {IoSearch} from 'react-icons/io5';
import { DateRangePicker } from "react-bootstrap-daterangepicker";
import _ from "lodash";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

import Pagination from "react-js-pagination";
const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
let dateformatCookie = homepagelocation[2];
let userId = "";
let status = "Active";
let moduleId = "";
class MyDelegationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      faCustomDesc1: "customArrow dropdown",
      faCustomAsc1: "customArrow dropup",
      faCustomDesc2: "customArrow dropdown",
      faCustomAsc2: "customArrow dropup",
      faCustomDesc3: "customArrow dropdown isActive",
      faCustomAsc3: "customArrow dropup",
      faCustomDesc4: "customArrow dropdown",
      faCustomAsc4: "customArrow dropup",
      faCustomDesc5: "customArrow dropdown",
      faCustomAsc5: "customArrow dropup",
      faCustomDesc6: "customArrow dropdown",
      faCustomAsc6: "customArrow dropup",
      adminRole: false,
      sort: true,
      page: 1,
      size: 10,
      // delegatedFromStartDate: '',
      // delegatedFromEndDate: '',
      // delegatedToStartDate: '',
      // delegatedToEndDate: '',
      // delegatorSearchInput: "",
      // delegateeSearchInput: "",
      delegatorSearchFilter: "",
      delegateeSearchFilter: "",
      delegatedFromSearchFilter: "",
      delegatedToSearchFilter: "",
      lastSelectedSearchFilter: "status",
      isDelegatedFromTo: "",
      isMultiSelectOpen: true,
    };
    this.onRowSelect = this.onRowSelect.bind(this);
    this.delegateFromDateFormat = this.delegateFromDateFormat.bind(this);
    this.delegateToDateFormat = this.delegateToDateFormat.bind(this);
    this.onSort = this.onSort.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onPageSizeChange = this.onPageSizeChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleCallback = this.handleCallback.bind(this);
    // this.handleCancel = this.handleCancel.bind(this);
    this.handleCheckBoxStatus = this.handleCheckBoxStatus.bind(this);
    this.handleCheckBoxModule = this.handleCheckBoxModule.bind(this);
    this.clickOutside = this.clickOutside.bind(this);
  }
 
  componentWillMount() {
    this.props.getDelegationList(
      userId,
      this.state.page,
      this.state.size,
      "desc",
      "delegateDateforFrom",
      status,
      moduleId
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.role_id && nextProps.role_id != this.props.role_id) {
      let roleArr = nextProps.role_id.split(",");
      let adminRole = roleArr.includes("1");
      this.setState({ adminRole: adminRole });
    }
  }
  componentDidUpdate(prevProps) {
    if (
      this.multiSelectDropdownRef1 != undefined ||
      this.multiSelectDropdownRef1 != null
    ) {
      ReactDOM.findDOMNode(
        this.multiSelectDropdownRef1
      ).childNodes[0].className = "multiSelectRef";
      let myRef = this.multiSelectDropdownRef1;
      if (this.props.isModuleOpen != prevProps.isModuleOpen) {
        let obj = ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1];
        if (obj != undefined || obj != null) {
          ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1].className =
            "multiSelectPopup";
        }
      }
    }
    if (
      this.multiSelectDropdownRef2 != undefined ||
      this.multiSelectDropdownRef2 != null
    ) {
      ReactDOM.findDOMNode(
        this.multiSelectDropdownRef2
      ).childNodes[0].className = "multiSelectRef";
      let myRef = this.multiSelectDropdownRef2;
      if (this.props.isStatusOpen != prevProps.isStatusOpen) {
        let obj = ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1];
        if (obj != undefined || obj != null) {
          ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1].className =
            "multiSelectPopup";
        }
      }
    }
  }

  clickOutside(event) {
    const statusDv = document.getElementById("statusDv");
    const moduleDv = document.getElementById("moduleDv");

    if (this.multiSelectDropdownRef != undefined || this.multiSelectDropdownRef != null) {
      document.addEventListener("mousedown", (event) => {
        if (statusDv.contains(event.target)) {
          this.setState({ isMultiSelectOpen: true });
          this.props.showRightSide(false);
        }
        else if (moduleDv.contains(event.target)) {
          this.setState({ isMultiSelectOpen: true });
          this.props.showRightSide(false);
        }
        else {
          this.setState({ isMultiSelectOpen: false });
        }
        this.props.multiSelectOpenFun("allFalse", false);
      });
    }
  }

  handleCallback(filter, start, end) {
    switch (filter) {
      case "delegateDateforFrom":
        this.setState({ delegatedFromSearchFilter: "delegateDateforFrom" });
        this.props.setDelegatedFromStartAndDate(
          start.format("MM/DD/YYYY"),
          end.format("MM/DD/YYYY")
        );
        this.setState({ isDelegatedFromTo: "delegateDateforFrom" });
        break;
      case "delegateDateforTo":
        this.setState({ delegatedToSearchFilter: "delegateDateforTo" });
        this.props.setDelegatedToStartAndDate(
          start.format("MM/DD/YYYY"),
          end.format("MM/DD/YYYY")
        );
        this.setState({ isDelegatedFromTo: "delegateDateforTo" });
        break;
    }
  }

  // handleCancel(filter, e, picker) {
  //   // picker.element.val('');
  //   switch (filter) {
  //     case 'delegateDateforFrom':
  //       picker.element.val('');
  //       this.setState({ delegatedFromStartDate: '', delegatedFromEndDate: '' });
  //       break;
  //     case 'delegateDateforTo':
  //       picker.element.val('');
  //       this.setState({ delegatedToStartDate: '', delegatedToEndDate: '' });
  //       break;
  //   }
  // }
  handleCheckBoxStatus(value) {
    this.props.searchKey.length == 0 && this.props.searchValue.length == 0
      ? value.length == 0
        ? this.props.isClearAllFilterFunc(false)
        : this.props.isClearAllFilterFunc(true)
      : this.props.isClearAllFilterFunc(true);

    this.props.setSelectedValueStatus(value);
    let status = "Active";
    let searchOnStatusArr = [];
    value.map((checkBoxValue) => {
      return searchOnStatusArr.push(checkBoxValue.value);
    });

    this.props.setStatusParameter(
      searchOnStatusArr.length == 0 ? status : searchOnStatusArr.join(",")
    );
    this.props.showRightPanelFun(false);
    this.props.getDelegationList(
      userId,
      this.state.page,
      this.state.size,
      this.props.sortOrder,
      this.props.sortOnColumn,
      searchOnStatusArr.length == 0 ? status : searchOnStatusArr.join(","),
      this.props.modules,
      this.props.searchKey,
      this.props.searchValue
    );
    this.props.showActiveRow(0, 0);
  }

  handleCheckBoxModule(value) {
    this.props.searchKey.length == 0 && this.props.searchValue.length == 0
      ? value.length == 0
        ? this.props.isClearAllFilterFunc(false)
        : this.props.isClearAllFilterFunc(true)
      : this.props.isClearAllFilterFunc(true);
    this.props.setSelectedValueModule(value);
    let searchOnModuleArr = [];
    value.map((checkBoxValue) => {
      return searchOnModuleArr.push(checkBoxValue.value);
    });

    this.props.setModulesParameter(searchOnModuleArr.join(","));
    this.props.showRightPanelFun(false);
    this.props.getDelegationList(
      userId,
      this.state.page,
      this.state.size,
      this.props.sortOrder,
      this.props.sortOnColumn,
      this.props.status,
      searchOnModuleArr.join(","),
      this.props.searchKey,
      this.props.searchValue
    );
    this.props.showActiveRow(0, 0);
  }

  onSort(sort, sortValue, sortType, lastFilter) {
    let orderBy = "";
    if (lastFilter == sortValue) {
      this.setState({ sort: !sort });
      orderBy = sortType;
    } else if (lastFilter != sortValue) {
      this.setState({ sort: "desc" });
      orderBy = "desc";
    }
    let modifiedOrderBy =
      sortValue == "moduleId"
        ? orderBy == "desc"
          ? "asc"
          : orderBy == "asc"
          ? "desc"
          : ""
        : orderBy;
    this.props.getDelegationList(
      userId,
      this.state.page,
      this.state.size,
      modifiedOrderBy,
      sortValue,
      this.props.status,
      this.props.modules,
      this.props.searchByVar,
      this.props.searchByValue
    );
    this.props.setSortOrder(modifiedOrderBy, sortValue);
    switch (sortValue) {
      case "delegatorName":
        this.setState({ lastSelectedSearchFilter: "delegatorName" });
        this.setState({
          faCustomDesc1:
            modifiedOrderBy == "asc"
              ? "customArrow dropdown"
              : modifiedOrderBy == "desc"
              ? "customArrow dropdown isActive"
              : "",
          faCustomAsc1:
            modifiedOrderBy == "asc"
              ? "customArrow dropup isActive"
              : modifiedOrderBy == "desc"
              ? "customArrow dropup"
              : "",
          faCustomDesc2: "customArrow dropdown",
          faCustomAsc2: "customArrow dropup",
          faCustomDesc3: "customArrow dropdown",
          faCustomAsc3: "customArrow dropup",
          faCustomDesc4: "customArrow dropdown",
          faCustomAsc4: "customArrow dropup",
          faCustomDesc5: "customArrow dropdown",
          faCustomAsc5: "customArrow dropup",
          faCustomDesc6: "customArrow dropdown",
          faCustomAsc6: "customArrow dropup",
        });
        break;
      case "delegateeName":
        this.setState({ lastSelectedSearchFilter: "delegateeName" });
        this.setState({
          faCustomDesc2:
            modifiedOrderBy == "asc"
              ? "customArrow dropdown"
              : modifiedOrderBy == "desc"
              ? "customArrow dropdown isActive"
              : "",
          faCustomAsc2:
            modifiedOrderBy == "asc"
              ? "customArrow dropup isActive"
              : modifiedOrderBy == "desc"
              ? "customArrow dropup"
              : "",
          faCustomDesc1: "customArrow dropdown",
          faCustomAsc1: "customArrow dropup",
          faCustomDesc3: "customArrow dropdown",
          faCustomAsc3: "customArrow dropup",
          faCustomDesc4: "customArrow dropdown",
          faCustomAsc4: "customArrow dropup",
          faCustomDesc5: "customArrow dropdown",
          faCustomAsc5: "customArrow dropup",
          faCustomDesc6: "customArrow dropdown",
          faCustomAsc6: "customArrow dropup",
        });
        break;
      case "delegateDateforFrom":
        this.setState({ lastSelectedSearchFilter: "delegateDateforFrom" });
        this.setState({
          faCustomDesc3:
            modifiedOrderBy == "asc"
              ? "customArrow dropdown"
              : modifiedOrderBy == "desc"
              ? "customArrow dropdown isActive"
              : "",
          faCustomAsc3:
            modifiedOrderBy == "asc"
              ? "customArrow dropup isActive"
              : modifiedOrderBy == "desc"
              ? "customArrow dropup"
              : "",
          faCustomDesc1: "customArrow dropdown",
          faCustomAsc1: "customArrow dropup",
          faCustomDesc2: "customArrow dropdown",
          faCustomAsc2: "customArrow dropup",
          faCustomDesc4: "customArrow dropdown",
          faCustomAsc4: "customArrow dropup",
          faCustomDesc5: "customArrow dropdown",
          faCustomAsc5: "customArrow dropup",
          faCustomDesc6: "customArrow dropdown",
          faCustomAsc6: "customArrow dropup",
        });
        break;
      case "delegateDateforTo":
        this.setState({ lastSelectedSearchFilter: "delegateDateforTo" });
        this.setState({
          faCustomDesc4:
            modifiedOrderBy == "asc"
              ? "customArrow dropdown"
              : modifiedOrderBy == "desc"
              ? "customArrow dropdown isActive"
              : "",
          faCustomAsc4:
            modifiedOrderBy == "asc"
              ? "customArrow dropup isActive"
              : modifiedOrderBy == "desc"
              ? "customArrow dropup"
              : "",
          faCustomDesc1: "customArrow dropdown",
          faCustomAsc1: "customArrow dropup",
          faCustomDesc2: "customArrow dropdown",
          faCustomAsc2: "customArrow dropup",
          faCustomDesc3: "customArrow dropdown",
          faCustomAsc3: "customArrow dropup",
          faCustomDesc5: "customArrow dropdown",
          faCustomAsc5: "customArrow dropup",
          faCustomDesc6: "customArrow dropdown",
          faCustomAsc6: "customArrow dropup",
        });
        break;
      case "moduleId":
        this.setState({ lastSelectedSearchFilter: "moduleId" });
        this.setState({
          faCustomDesc5:
            modifiedOrderBy == "asc"
              ? "customArrow dropdown"
              : modifiedOrderBy == "desc"
              ? "customArrow dropup isActive"
              : "",
          faCustomAsc5:
            modifiedOrderBy == "asc"
              ? "customArrow dropdown isActive"
              : modifiedOrderBy == "desc"
              ? "customArrow dropup"
              : "",
          faCustomDesc1: "customArrow dropdown",
          faCustomAsc1: "customArrow dropup",
          faCustomDesc2: "customArrow dropdown",
          faCustomAsc2: "customArrow dropup",
          faCustomDesc3: "customArrow dropdown",
          faCustomAsc3: "customArrow dropup",
          faCustomDesc4: "customArrow dropdown",
          faCustomAsc4: "customArrow dropup",
          faCustomDesc6: "customArrow dropdown",
          faCustomAsc6: "customArrow dropup",
        });
        break;
      case "status":
        this.setState({ lastSelectedSearchFilter: "status" });
        this.setState({
          faCustomDesc6:
            modifiedOrderBy == "asc"
              ? "customArrow dropdown"
              : modifiedOrderBy == "desc"
              ? "customArrow dropdown isActive"
              : "",
          faCustomAsc6:
            modifiedOrderBy == "asc"
              ? "customArrow dropup isActive"
              : modifiedOrderBy == "desc"
              ? "customArrow dropup"
              : "",
          faCustomDesc1: "customArrow dropdown",
          faCustomAsc1: "customArrow dropup",
          faCustomDesc2: "customArrow dropdown",
          faCustomAsc2: "customArrow dropup",
          faCustomDesc3: "customArrow dropdown",
          faCustomAsc3: "customArrow dropup",
          faCustomDesc4: "customArrow dropdown",
          faCustomAsc4: "customArrow dropup",
          faCustomDesc5: "customArrow dropdown",
          faCustomAsc5: "customArrow dropup",
        });
        break;
    }
    this.props.showActiveRow(0);
  }
  onPageChange(e) {
    this.props.showRightPanelFun(false);
    this.setState({ page: e });
    this.props.getDelegationList(
      userId,
      e,
      this.state.size,
      this.props.sortOrder,
      this.props.sortOnColumn,
      this.props.status,
      this.props.modules,
      this.props.searchKey,
      this.props.searchValue
    );
    this.props.showActiveRow(0, 0);
  }

  onPageSizeChange(e) {
    this.props.showRightPanelFun(false);
    let size = parseInt(e.target.value, 10);
    this.setState({ size: size });
    this.props.getDelegationList(
      userId,
      1,
      size,
      this.props.sortOrder,
      this.props.sortOnColumn,
      this.props.status,
      this.props.modules,
      this.props.searchKey,
      this.props.searchValue
    );
    this.props.showActiveRow(0, 0);
  }

  delegateFromDateFormat(cell, row) {
    if (row.delegateFromDate != "" || row.delegateFromDate != undefined) {
      return moment(row.delegateFromDate).format(
        dateformatCookie.slice(0, 10).toUpperCase() + " HH:mm:ss"
      );
    } else {
      return "";
    }
  }

  delegateToDateFormat(cell, row) {
    if (row.delegatetoDate != "" || row.delegatetoDate != undefined) {
      return moment(row.delegatetoDate).format(
        dateformatCookie.slice(0, 10).toUpperCase() + " HH:mm:ss"
      );
    } else {
      return "";
    }
  }

  onRowSelect(e, item, index) {
    let rowStr = "";
    for (const prop in item) {
      if (item.hasOwnProperty(prop)) {
        rowStr += prop + ': "' + item[prop] + '"';
      }
    }
    this.props.showRightPanelFun(true);
    this.props.showListPart1(false);
    this.props.setDelegateForEdit(item);
    this.props.showActiveRow(index);
  }
  renderDelegationData(delegationList) {
    return delegationList.map((item, index) => {
      let cls = "";
      // if (index == this.props.rowIndex) {
      //   cls = "myActive";
      //   this.props.setDelegateForEdit(item);

      // } else {
      //   cls = "";
      // }
      return (
        <tr
          className={
            this.props.isRightPanel && index == this.props.rowIndex
              ? (cls = "myActive")
              : (cls = "")
          }
          onClick={(event) => {
            this.onRowSelect(event, item, index);
          }}
        >
          {this.state.adminRole ? <td>{item.delegatorName}</td> : null}
          <td>
            <Button bsClass="" className="rowFocus"></Button>
            <div className="showdv414">
              <div className="keydv414">
                {this.props.translator["Delegatee"]}
              </div>
              <div className="valdv414">{item.delegateeName}</div>
            </div>
          </td>
          <td className="hiddenBe414">
            {item.delegateDateforFrom != "" ||
            item.delegateDateforFrom != undefined
              ? moment(item.delegateDateforFrom).format(
                  dateformatCookie.slice(0, 10).toUpperCase() + " HH:mm:ss"
                )
              : ""}{" "}
          </td>
          <td className="hiddenBe414">
            {item.delegateDateforTo != "" || item.delegateDateforTo != undefined
              ? moment(item.delegateDateforTo).format(
                  dateformatCookie.slice(0, 10).toUpperCase() + " HH:mm:ss"
                )
              : ""}
          </td>
          <td>
            <div className="showdv414">
              <div className="keydv414">{this.props.translator["Module"]}</div>
              <div className="valdv414">
                {item.moduleId == "Item" ? this.props.translator["Fulfillment"] : this.props.translator["Change"]}
              </div>
            </div>
          </td>
          <td>
            <div className="showdv414">
              <div className="keydv414">{this.props.translator["Status"]}</div>
              <div className="valdv414">{item.status}</div>
            </div>
          </td>
        </tr>
      );
    });
  }
  handleSearch(e, picker, delegation = '') {
    this.props.showRightPanelFun(false);
    let searchByVar = [];
    let searchByValue = [];
    let delegatedFromArr = [];
    let delegatedToArr = [];
    let searchInputVarDelegator = this.props.delegatorSearchInput.replace(
      /[&]/g,
      ""
    );
    searchInputVarDelegator = searchInputVarDelegator.replace(/[|]/g, "");
    let searchInputVarDelegatee = this.props.delegateeSearchInput.replace(
      /[&]/g,
      ""
    );
    searchInputVarDelegatee = searchInputVarDelegatee.replace(/[|]/g, "");
    this.props.isClearAllFilterFunc(true);
    if (searchInputVarDelegator != "") {
      searchByValue.push(searchInputVarDelegator.trim());
      searchByVar.push(this.state.delegatorSearchFilter);
    }
    if (searchInputVarDelegatee != "") {
      searchByValue.push(searchInputVarDelegatee.trim());
      searchByVar.push(this.state.delegateeSearchFilter);
    }
    if(delegation == 'delegationStart' && picker) {
      this.props.setDelegatedFromRef(picker);
      delegatedFromArr.push(
        moment(picker.startDate.format("MM/DD/YYYY")).format("YYYY-MM-DD"),
        moment(picker.endDate.format("MM/DD/YYYY")).format("YYYY-MM-DD")
      );
      searchByValue.push(delegatedFromArr.join(","));
      searchByVar.push('delegateDateforFrom');
    }
    else if (!_.isEmpty(this.props.delegatedFromStartDate) && !_.isEmpty(this.props.delegatedFromEndDate)) {
      if (this.state.isDelegatedFromTo == "delegateDateforFrom")
        this.props.setDelegatedFromRef(picker);
      delegatedFromArr.push(
        moment(this.props.delegatedFromStartDate).format("YYYY-MM-DD"),
        moment(this.props.delegatedFromEndDate).format("YYYY-MM-DD")
      );
      searchByValue.push(delegatedFromArr.join(","));
      searchByVar.push(this.state.delegatedFromSearchFilter);
    }
    if(delegation == 'delegationEnd' && picker) {
      this.props.setDelegatedToRef(picker);
      delegatedToArr.push(
        moment(picker.startDate.format("MM/DD/YYYY")).format("YYYY-MM-DD"),
        moment(picker.endDate.format("MM/DD/YYYY")).format("YYYY-MM-DD")
      );
      searchByValue.push(delegatedToArr.join(","));
      searchByVar.push('delegateDateforTo');
    }
    else if (!_.isEmpty(this.props.delegatedToStartDate) && !_.isEmpty(this.props.delegatedToEndDate)) {
      if (this.state.isDelegatedFromTo == "delegateDateforTo")
        this.props.setDelegatedToRef(picker);
      delegatedToArr.push(
        moment(this.props.delegatedToStartDate).format("YYYY-MM-DD"),
        moment(this.props.delegatedToEndDate).format("YYYY-MM-DD")
      );
      searchByValue.push(delegatedToArr.join(","));
      searchByVar.push(this.state.delegatedToSearchFilter);
    }

    this.props.getDelegationList(
      userId,
      this.state.page,
      this.state.size,
      this.props.sortOrder,
      this.props.sortOnColumn,
      this.props.status,
      this.props.modules,
      searchByVar.join(","),
      searchByValue.join("|")
    );
    this.props.setSearchKeyValue(
      searchByVar.join(","),
      searchByValue.join("|")
    );
    this.props.showActiveRow(0);
  }

  handleChange(e, searchValue) {
    // this.props.isClearAllFilterFunc(false);
    this.props.showRightPanelFun(false);
    switch (searchValue) {
      case "delegatorName":
        this.props.setDelegatorSearchInput(e.target.value);
        this.setState({ delegatorSearchFilter: searchValue });
        break;
      case "delegateeName":
        this.props.setDelegateeSearchInput(e.target.value);
        this.setState({ delegateeSearchFilter: searchValue });
        break;
    }
  }

  handleDelegateFromEvent(event, picker) {
    if (event.type == "apply") {
      this.setState({ delegatedFromSearchFilter: "delegateDateforFrom" });
      this.props.setDelegatedFromStartAndDate(
        picker.startDate.format("MM/DD/YYYY"),
        picker.endDate.format("MM/DD/YYYY")
      );
      this.setState({ isDelegatedFromTo: "delegateDateforFrom" });
    }
  }
  handleDelegateToEvent(event, picker) {
    if (event.type == "apply") {
      this.setState({ delegatedToSearchFilter: "delegateDateforTo" });
      this.props.setDelegatedToStartAndDate(
        picker.startDate.format("MM/DD/YYYY"),
        picker.endDate.format("MM/DD/YYYY")
      );
      this.setState({ isDelegatedFromTo: "delegateDateforTo" });
    }
  }

  renderDelegationTableList(delegationList, multiSelectOptions1,multiSelectOptions2) {
    let myDelegatedFromStartDate = !_.isEmpty(this.props.delegatedFromStartDate)
      ? moment(this.props.delegatedFromStartDate).format("MM/DD/YYYY")
      : "";
    let myDelegatedFromEndDate = !_.isEmpty(this.props.delegatedFromEndDate)
      ? moment(this.props.delegatedFromEndDate).format("MM/DD/YYYY")
      : "";
    let myDelegatedToStartDate = !_.isEmpty(this.props.delegatedToStartDate)
      ? moment(this.props.delegatedToStartDate).format("MM/DD/YYYY")
      : "";
    let myDelegatedToEndDate = !_.isEmpty(this.props.delegatedToEndDate)
      ? moment(this.props.delegatedToEndDate).format("MM/DD/YYYY")
      : "";
    let myDelegatedFromNewDate =
      myDelegatedFromStartDate == "" && myDelegatedFromEndDate == ""
        ? ""
        : myDelegatedFromStartDate + "-" + myDelegatedFromEndDate;
    let myDelegatedToNewDate =
      myDelegatedToStartDate == "" && myDelegatedToEndDate == ""
        ? ""
        : myDelegatedToStartDate + "-" + myDelegatedToEndDate;
    let ascDscToggle = "";
    ascDscToggle = this.state.sort ? "asc" : "desc";
    let caseResponsive =
      this.props.delegateShowLoader == true || delegationList == [] || (delegationList?.length <= 6 && this.state.isMultiSelectOpen)
        ? "overFlowClp"
        : "table-responsive";

    if (this.props.isRightPanel == true) {
      const element1 = this.multiSelectDropdownRef1.childNodes[0].childNodes[0];
      const element2 = this.multiSelectDropdownRef2.childNodes[0].childNodes[0];
      element1.addEventListener("click", () => {
        this.props.showRightPanelFun(false);
      });
      element2.addEventListener("click", () => {
        this.props.showRightPanelFun(false);
      });
    }

    // if (delegationList.length == 0 || delegationList == [] || delegationList == undefined) {
    //   return (
    //     <div>{this.props.translator['No Data Found']}</div>
    //   );
    // }
    // else {
    return (
      <div className={"tableRgtBor " + caseResponsive}>
        <Table
          striped
          bordered
          condensed
          hover
          className="tableView nowrapWhright"
          style={{ marginTop: "0px" }}
        >
          <thead>
            <tr>
              {this.state.adminRole ? (
                <th>
                  <div
                    className="sortParArr"
                    onClick={() =>
                      this.onSort(
                        this.state.sort,
                        "delegatorName",
                        ascDscToggle,
                        this.state.lastSelectedSearchFilter
                      )
                    }
                  >
                    {this.props.translator["Delegated For"]}
                    <div className="sortArr">
                      {this.props.sortOrder == "asc" ? (
                        <span
                          className={this.state.faCustomAsc1}
                          title={this.props.translator["Ascending"]}
                        >
                          <span className="caret"></span>
                        </span>
                      ) : (
                        <span
                          className={this.state.faCustomDesc1}
                          title={this.props.translator["Descending"]}
                        >
                          <span className="caret"></span>
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="colSearDv">
                    <Form.Control
                      className="colSearInp"
                      type="text"
                      value={
                        this.props.delegatorSearchInput != ""
                          ? this.props.delegatorSearchInput
                          : ""
                      }
                      placeholder={this.props.translator["Search here"]}
                      onKeyPress={(e) => {
                        e.charCode == "13"
                          ? (this.props.delegatorSearchInput != "" &&
                            this.props.delegatorSearchInput.trim().length > 3
                            ? this.handleSearch()
                            : "")
                          : "";
                      }}
                      onChange={(event) => {
                        this.handleChange(event, "delegatorName");
                      }}
                      onClick={() => this.props.multiSelectOpenFun("allFalse", false)}
                    />
                    {this.props.delegatorSearchInput != "" &&
                    this.props.delegatorSearchInput.trim().length > 3 ? (
                      <a
                        title="search"
                        className="faicn"
                        href={void(0)}
                        onClick={(event) => this.handleSearch()}
                      >
                        <IoSearch/>
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </th>
              ) : null}
              <th>
                <div
                  className="sortParArr"
                  onClick={() =>
                    this.onSort(
                      this.state.sort,
                      "delegateeName",
                      ascDscToggle,
                      this.state.lastSelectedSearchFilter
                    )
                  }
                >
                  {this.props.translator["Delegatee"]}
                  <div className="sortArr">
                    {this.props.sortOrder == "asc" ? (
                      <span
                        className={this.state.faCustomAsc2}
                        title={this.props.translator["Ascending"]}
                      >
                        <span className="caret"></span>
                      </span>
                    ) : (
                      <span
                        className={this.state.faCustomDesc2}
                        title={this.props.translator["Descending"]}
                      >
                        <span className="caret"></span>
                      </span>
                    )}
                  </div>
                </div>
                <div className="colSearDv">
                  <Form.Control
                    className="colSearInp"
                    type="text"
                    value={
                      this.props.delegateeSearchInput != ""
                        ? this.props.delegateeSearchInput
                        : ""
                    }
                    placeholder={this.props.translator["Search here"]}
                    onKeyPress={(e) => {
                      e.charCode == "13"
                        ? (this.props.delegateeSearchInput != "" &&
                          this.props.delegateeSearchInput.trim().length > 3
                          ? this.handleSearch()
                          : "")
                        : "";
                    }}
                    onChange={(event) => {
                      this.handleChange(event, "delegateeName");
                    }}
                    onClick={() => this.props.multiSelectOpenFun("allFalse", false)}
                  />
                  {this.props.delegateeSearchInput != "" &&
                  this.props.delegateeSearchInput.trim().length > 3 ? (
                    <a
                      title="search"
                      className="faicn"
                      href={void(0)}
                      onClick={(event) => this.handleSearch()}
                    >
                      <IoSearch/>
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </th>
              <th className="hiddenBe414">
                <div
                  className="sortParArr"
                  onClick={() =>
                    this.onSort(
                      this.state.sort,
                      "delegateDateforFrom",
                      ascDscToggle,
                      this.state.lastSelectedSearchFilter
                    )
                  }
                >
                  {this.props.translator["Delegation Start"]}{" "}
                  <div className="sortArr">
                    {this.props.sortOrder == "asc" ? (
                      <span
                        className={this.state.faCustomAsc3}
                        title={this.props.translator["Ascending"]}
                      >
                        <span className="caret"></span>
                      </span>
                    ) : (
                      <span
                        className={this.state.faCustomDesc3}
                        title={this.props.translator["Descending"]}
                      >
                        <span className="caret"></span>
                      </span>
                    )}
                  </div>
                </div>
                <div className="colSearDv">
                  <DateRangePicker
                    initialSettings={{
                      autoUpdateInput: false,
                      autoApply: true,
                    }}
                    onApply={(e, picker) => this.handleSearch(e, picker, 'delegationStart')}
                    onEvent={(e, picker) =>
                      this.handleDelegateFromEvent(e, picker)
                    }
                  >
                    <input
                      type="text"
                      value={myDelegatedFromNewDate}
                      placeholder={this.props.translator["Search here"]}
                      readOnly
                      className="form-control col-4"
                      onPaste={this.handleKeyPress}
                      onKeyDown={this.handleKeyPress}
                    />
                  </DateRangePicker>
                </div>
              </th>
              <th className="hiddenBe414">
                <div
                  className="sortParArr"
                  onClick={() =>
                    this.onSort(
                      this.state.sort,
                      "delegateDateforTo",
                      ascDscToggle,
                      this.state.lastSelectedSearchFilter
                    )
                  }
                >
                  {this.props.translator["Delegation End"]}{" "}
                  <div className="sortArr">
                    {this.props.sortOrder == "asc" ? (
                      <span
                        className={this.state.faCustomAsc4}
                        title={this.props.translator["Ascending"]}
                      >
                        <span className="caret"></span>
                      </span>
                    ) : (
                      <span
                        className={this.state.faCustomDesc4}
                        title={this.props.translator["Descending"]}
                      >
                        <span className="caret"></span>
                      </span>
                    )}
                  </div>
                </div>
                <div className="colSearDv">
                  <DateRangePicker
                    initialSettings={{
                      autoUpdateInput: false,
                      autoApply: true,
                    }}
                    onApply={(e, picker) => this.handleSearch(e, picker, 'delegationEnd')}
                    onEvent={(e, picker) =>
                      this.handleDelegateToEvent(e, picker)
                    }
                  >
                    <input
                      type="text"
                      value={myDelegatedToNewDate}
                      placeholder={this.props.translator["Search here"]}
                      readOnly
                      className="form-control col-4"
                      onPaste={this.handleKeyPress}
                      onKeyDown={this.handleKeyPress}
                    />
                  </DateRangePicker>
                </div>
              </th>
              <th className="hiddenBe414">
                <div
                  className="sortParArr"
                  onClick={() =>
                    this.onSort(
                      this.state.sort,
                      "moduleId",
                      ascDscToggle,
                      this.state.lastSelectedSearchFilter
                    )
                  }
                >
                  {this.props.translator["Module"]}{" "}
                  <div className="sortArr">
                    {this.props.sortOrder == "asc" ? (
                      <span
                        className={this.state.faCustomAsc5}
                        title={this.props.translator["Descending"]}
                      >
                        <span className="caret"></span>
                      </span>
                    ) : (
                      <span
                        className={this.state.faCustomDesc5}
                        title={this.props.translator["Ascending"]}
                      >
                        <span className="caret"></span>
                      </span>
                    )}
                  </div>
                </div>
                <div id="moduleDv"
                  className="colSearDv multiSelDv"
                  ref={(e) => (this.multiSelectDropdownRef1 = e)}
                  onClick={() => this.props.multiSelectOpenFun("myDeleModule", true)}
                >
                  <ReactMultiSelectCheckboxes
                    placeholderButtonLabel={this.props.translator["Select"]}
                    value={this.props.selectedValueModule}
                    rightAligned={true}
                    options={multiSelectOptions1}
                    onChange={this.handleCheckBoxModule}
                  />
                </div>
              </th>
              <th>
                <div
                  className="sortParArr"
                  onClick={() =>
                    this.onSort(
                      this.state.sort,
                      "status",
                      ascDscToggle,
                      this.state.lastSelectedSearchFilter
                    )
                  }
                >
                  {this.props.translator["Status"]}{" "}
                  <div className="sortArr">
                    {this.props.sortOrder == "asc" ? (
                      <span
                        className={this.state.faCustomAsc6}
                        title={this.props.translator["Ascending"]}
                      >
                        <span className="caret"></span>
                      </span>
                    ) : (
                      <span
                        className={this.state.faCustomDesc6}
                        title={this.props.translator["Descending"]}
                      >
                        <span className="caret"></span>
                      </span>
                    )}
                  </div>
                </div>
                <div
                  id="statusDv"
                  className="colSearDv multiSelDv"
                  ref={(e) => (this.multiSelectDropdownRef2 = e)}
                  onClick={() => this.props.multiSelectOpenFun("myDeleStatus", true)}
                >
                  <ReactMultiSelectCheckboxes
                    placeholderButtonLabel={this.props.translator["Select"]}
                    value={this.props.selectedValueStatus}
                    rightAligned={true}
                    options={multiSelectOptions2}
                    onChange={this.handleCheckBoxStatus}
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.delegateShowLoader ? (
              <tr>
                <td colSpan="6" style={{ textAlign: "center" }}>
                  <ListLoader />
                </td>
              </tr>
            ) : delegationList.length == 0 || delegationList == [] ? (
              <tr>
                <td colSpan="6" style={{ textAlign: "center" }}>
                  {
                    this.props.translator[
                      "There is no matching data available"
                    ]
                  }
                </td>
              </tr>
            ) : (
              this.renderDelegationData(delegationList)
            )}
          </tbody>
        </Table>
      </div>
    );

    // }
  }

  render() {
    let multiSelectOptions1 = [
      { label: this.props.translator["Fulfillment"], value: "Item" },
      { label: this.props.translator["Change"], value: "Change" },
    ];
    let multiSelectOptions2 = [
      { label: this.props.translator["Active"], value: "Active" },
      { label: this.props.translator["Inactive"], value: "Inactive" },
    ];
    let delegationList = this.props.delegationList.delegationList;
    let meta = this.props.delegationList.meta;
    let panelColSize = this.props.panelResize;
    console.log("panelSize", panelColSize, this.props.panelResize);
    if (delegationList instanceof Error) {
      return (
        <div>
          <h2>{this.props.translator["Error in loading delegation list"]}</h2>
        </div>
      );
    }
    if (delegationList == undefined) {
      return (
        <div>
          <h2>{this.props.translator['Loading delegation list...']}</h2>
          <Spinner spinnerName="three-bounce" />
        </div>
      );
    }
    console.log("===Props",this.props.translator);
    return (      
      <div className={this.props.delegateShowLoader == true || delegationList == [] || delegationList?.length <= 6 ? "respondv responDvMinH" : "respondv"}>
        {this.renderDelegationTableList(delegationList,
          multiSelectOptions1,multiSelectOptions2
        )}
        {delegationList.length != 0 ? (
          <div className="nBotPagina">
            <div className="nShow">
              <div className="margin-r-10">
                {this.props.translator["Show"]}:
              </div>
              <div aria-label="Show Count" role="contentinfo">
                <Form.Select
                  aria-label={this.props.translator["Show"]}
                  onChange={this.onPageSizeChange}
                  value={meta ? meta.limit : 10}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </Form.Select>
              </div>
            </div>
            <nav aria-label="Pagination" className="display-inline-block">
              <Pagination
                prevPageText={this.props.translator["Prev"]}
                nextPageText={this.props.translator["Next"]}
                firstPageText={<i className="glyphicon glyphicon-menu-left" />}
                lastPageText={<i className="glyphicon glyphicon-menu-right" />}
                activePage={meta ? Number(meta.currentPage) : 1}
                itemsCountPerPage={this.state.size}
                totalItemsCount={meta ? meta.rowCount : 1}
                pageRangeDisplayed={panelColSize <= 42 ? 2 : 5}
                onChange={(e) => this.onPageChange(e)}
              />
            </nav>
          </div>
        ) : (
          <span />
        )}
      </div>
    );
  }
}

// DelegationList.propTypes = {
//   translator: PropTypes.object,
//   // delegationList: PropTypes.array,
//   setDelegateForEdit: PropTypes.func,
//   getDelegationList: PropTypes.func,
//   showRightPanelFun: PropTypes.func,
//   showActiveRow: PropTypes.func,
//   rowIndex: PropTypes.number,
//   delegateShowLoader: PropTypes.bool
// };
const mapStateToProps = ({ delegationList, delegateShowLoader }) => {
  return {
    delegationList,
    delegateShowLoader: delegateShowLoader.loading,
  };
};
export default connect(mapStateToProps, { getDelegationList })(
  MyDelegationList
);
