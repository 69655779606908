
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
// import Header from "../../../common/header/Header";
import LeftSidebarMenulist from "./configBoard.js";
import RightSidebarMenulist from "../../../common/RightSidebarMenuListDynamic.js";
import XsmSupportHeader from "./header.js";
import XsmSLAWorkdayEditForm from "./form.js";
import Attribute from "./attribute.js";
import { connect, useDispatch, useSelector } from "react-redux";
import XsmProcessHierarchy from "../create/processHierarchy.js";
import {
  loadHolidayScheduleData,
  loadHolidayRelatedSlaData,
} from "../../../../actions/sla/lovAction";
import configureStore from "../../../../store/configureStore";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../common/Breadcrumbs";
import ViewHolidayRelatedSls from "./ViewHolidayRelatedSls";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import AuditLog from "./AuditLog";
import "_Css/form/_form.scss";
const store = configureStore();

const SlaHolidaySchEditIndex = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [subMenu, setSubMenu] = useState("4");
  const [showHideAlfy, setShowHideAlfy] = useState(false);
  const [isRightEditPanel, setrightEditPanel] = useState(true);
  const [showHideRightNav, setShowHideRightNav] = useState(false);
  const [actionView, setActionView] = useState("holidayEntry");
  const [mobileLeftMenuFilter, setMobileLeftMenuFilter] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const slaHolidayScheduleDetails = useSelector(
    (state) => state.slaHolidayScheduleDetails
  );
  const translator = useSelector((state) => state.spcmReducer.tr);
  console.log("==holidayDetail",slaHolidayScheduleDetails);
  useEffect(() => {
    dispatch(loadHolidayRelatedSlaData(params.scheduleId));
    dispatch(loadHolidayScheduleData(params.scheduleId));
  }, []);

  const selectedForm = (value) => {
    setEditForm(value);
  };

  const rightEditPanel = (value) => {
    setrightEditPanel(value);
  };

  const mobileLeftMenuFilterSet = () => {
    setMobileLeftMenuFilter(!mobileLeftMenuFilter);
  };

  const showHideAlfySet = (value) => {
    setShowHideAlfy(value);
  };
  const showHideRightNavSet = (value) => {
    setShowHideRightNav(!showHideRightNav);
  };
  const changeActionView = (actionSelected) => {
    setActionView(actionSelected);
  };

  const showRightPaneAction = (actionView) => {
    switch (actionView) {
      case "holidayEntry":
        return (
          <Attribute
            scheduleId={params.scheduleId}
            selectedForm={selectedForm}
            translator={translator}
            editForm={editForm}
            rightEditPanel={rightEditPanel}
          />
        );
      case "activityDetails":
          return <AuditLog ciId={params.scheduleId} translator={translator}/>;
      case "servicediagram":
        return <XsmProcessHierarchy translator={translator} rightEditPanel={rightEditPanel} />;

      case "viewRelatedSls":
        return <ViewHolidayRelatedSls translator={translator} rightEditPanel={rightEditPanel} />;

      default:
        return (
          <Attribute
            scheduleId={params.scheduleId}
            selectedForm={selectedForm}
            translator={translator}
            editForm={editForm}
            rightEditPanel={rightEditPanel}
          />
        );
    }
  };

  return (
    <div >
      {/* App Header Section Start*/}
      {/* <Header rightNavValue={showHideRightNavSet} ciId={params.ciId} /> */}
      {/* App Header Section End*/}

      {/* <div
        onClick={mobileLeftMenuFilterSet}
        id="mobileLeftMenuFilter"
        className={
          mobileLeftMenuFilter
            ? "mobileLeftMenuFilterMove d-md-none d-lg-none"
            : "d-none d-lg-none"
        }
      >
        <i
          className={
            mobileLeftMenuFilter
              ? "fa fa-angle-double-left"
              : "fa fa-angle-double-right"
          }
        ></i>
      </div> */}

      {/* App Right Side MenuBar Section Start*/}
      {/* <RightSidebarMenulist
        translator={translator}
        showhideClass={showHideRightNav}
        subMenuNumber={subMenu}
        rightEditPanel={rightEditPanel}
      /> */}
      {/* App Right Side MenuBar Section End*/}

      {/* Support App Left Side MenuBar Section Start*/}
      {/* <LeftSidebarMenulist
        newMobileLeftMenuFilter={mobileLeftMenuFilter}
        translator={translator}
        ciId={params.ciId}
        cidetails={props.CIEditDetails}
      /> */}
      {/* Support App Left Side MenuBar Section End*/}

      <div className="container-fluid padding-t-10">
        <Breadcrumbs translator={translator} />
        <Form>
          {/* Home Page blue bar Section Start*/}
          <XsmSupportHeader
            translator={translator}
            ciId={params.ciId}
            changeActionView={changeActionView}
            slaHolidayScheduleDetails={slaHolidayScheduleDetails}
            rightEditPanel={rightEditPanel}
            actionView={actionView}
          />
          {/* Home Page blue bar Section end*/}
          <PanelGroup direction="horizontal">
            <Panel id="sidebar" minSize={33} order={1} defaultSize={isRightEditPanel ? 67 : 100} className={isRightEditPanel ? "isShowLeftPanel" : ""}>
              {/* Xsm Break Fix Form tabs Section Start*/}			
              {slaHolidayScheduleDetails.slaHolidayScheduleDetails?<XsmSLAWorkdayEditForm
                translator={translator}
                scheduleId={params.scheduleId}
                rightEditPanel={rightEditPanel}
              />:null}
            </Panel>
            {isRightEditPanel ?
            <>
            <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
            <Panel minSize={33} order={2} defaultSize={33} className="shopCartloader2">
                    <div className="stickyArea rBoxStyle">
                    {showRightPaneAction(actionView)}
                    </div>
            </Panel>
            </> : ""
            }
          </PanelGroup>
        </Form>
      </div>
    </div>
  );
};

export default SlaHolidaySchEditIndex;

// const mapStateToProps = ({ slaHolidayScheduleDetails, spcmReducer }) => {
//   return {
//     slaHolidayScheduleDetails,
//     translator: spcmReducer.tr,
//   };
// };
// export default connect(mapStateToProps, { loadHolidayScheduleData })(
//   SlaHolidaySchEditIndex
// );
