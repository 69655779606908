
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import PopUpAuditLog  from '../../../common/WorkItemBoard/breakfixAuditLog';
import PopUpActivityLog  from '../../../common/WorkItemBoard/breakfixActivityLog';
import {IoClose} from "react-icons/io5";
import WIBNotifcation from '../../WIBNotification';
class TabPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabKey: 1,
            isOpen:true,
            message:'',
            messageactivity:'',
            taskeditFlag:'0',
            taskcode:''            
        };
        this.handleSelect = this.handleSelect.bind(this);
        this.changeCompMessage = this.changeCompMessage.bind(this);
		this.changeCompMessageactivity = this.changeCompMessageactivity.bind(this);
		this.clicktaskEdit = this.clicktaskEdit.bind(this);
    }
    handleSelect(tabKey) {
      this.setState({ tabKey });
    }
    changeCompMessage(message){
	  this.setState({message});
	}
    changeCompMessageactivity(messageactivity){
		this.setState({messageactivity});
	}
    clicktaskEdit(i, j, k) {
		this.setState({
			taskeditFlag: i,
			taskcode: j,
			taskregid: k
		});
	}
    render() {
        console.log("porps++++",this.props);
        return (
            <div>
                <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
                    <ul>
                        <li>
                        <button
                            type='button'
                            title="Minimize the right panel"
                            bsClass=""
                            bsStyle=""
                            className="closerightPanelBtn"
                            onClick={() => {
                            this.props.rightEditPanel(false);
                            }}
                        >
                            <IoClose/>
                        </button>
                        </li>
                    </ul>
                </div>
                <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Activity Details']}</div></div>
                <div className="rBoxGap">
                    <div className='rBoxTabs'>
                    <Tabs className="rBoxTabs" activeKey={this.state.tabKey} onSelect={this.handleSelect} id="tabId">
                        <Tab eventKey={1} title={this.props.translator["Comments"]}>
                            <PopUpActivityLog fieldStatus={this.props.fieldStatus} messageactivity={this.state.messageactivity} changeDetails={this.props.changeDetails} 
                                              changeCompMessageactivity={this.changeCompMessageactivity} module="ChangeManagement" itemId={this.props.changeId} translator={this.props.translator} 
                                              date_format={this.props.date_format} disableFieldsForGuestRole={this.props.disableFieldsForGuestRole}/>
                        </Tab>
                        <Tab eventKey={6} title={this.props.translator["Audit Log"]}>
                            <PopUpAuditLog fieldStatus={this.props.fieldStatus} message={this.state.message} changeCompMessage={this.changeCompMessage} itemId={this.props.changeId} 
                                           module="ChangeManagement" translator={this.props.translator} date_format={this.props.date_format} urgencyListKeyValue={this.props.urgencyListKeyValue} impactListKeyValue={this.props.impactListKeyValue}/>
                        </Tab> 
                            <Tab eventKey={3} title={"Notifications"}>
                                <WIBNotifcation
                                    requestNumber={this.props.changeDetails.changeNumber}
                                    orderNumber={""}
                                />
                            </Tab>                       
                    </Tabs>
                    </div>
                </div>
            </div>
        )
    }
}
export default TabPage;