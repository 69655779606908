
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import {Row,Col, Form, Button, Table ,Alert} from 'react-bootstrap';
import { connect } from 'react-redux';
import { getAdditionalIntegrationData,setEditServiceDetails,setForm,getServiceDetailCounts } from '../../../actions/spcmActions';
import ComponentForm from './editForm.js';
import axios from 'axios';
import Pagination from "react-js-pagination";
import ListLoader from "_Commons/loaders/ListLoader";
import Swal from 'sweetalert2';
import {IoSearch} from 'react-icons/io5';

class ListView extends React.Component {
constructor(props){
	super(props);
	this.state={
		queryBy:'Attribute',
		query:'',
		pageSize:10,
		sortBy:'',
		extraCounts:[0],
		deleteError:'',
		sortIcons: false ,
		class1: 'sort up',
		class2: 'sort',
		order : '',
		sortByTitle:''

	}
	this.sortFun = this.sortFun.bind(this);
	this.onTextChange=this.onTextChange.bind(this);
	this.onPageChange=this.onPageChange.bind(this);
	this.onPageSizeChange=this.onPageSizeChange.bind(this);
	this.onSearchClick=this.onSearchClick.bind(this);
	this.queryBySelect=this.queryBySelect.bind(this);
	this.onDeleteClick=this.onDeleteClick.bind(this);
	this.onSort = this.onSort.bind(this);
	this.handleDismiss = this.handleDismiss.bind(this);
	this.sortHover = this.sortHover.bind(this);
}

handleKeyPress(e){
	if (e.key === 'Enter'){
		this.onSearchClick();
		if(e.preventDefault) e.preventDefault();
	}
}

sortFun(myObj){
	this.setState({ sortIcons:!this.state.sortIcons});
	switch(myObj) {
		case 'class1':
			this.setState({ class1:(this.state.sortIcons ? 'sort up' : 'sort down')});
			this.setState({ class2:'sort',sortBy:'Attribute'});
			this.props.getAdditionalIntegrationData(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy,this.state.query,'Attribute',this.state.sortIcons?'asc':'desc');
		break;
		case 'class2':
			this.setState({ class1:'sort'});
			this.setState({ class2:(this.state.sortIcons ? 'sort up' : 'sort down'),sortBy:'Value'});
			this.props.getAdditionalIntegrationData(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy,this.state.query,'Value',this.state.sortIcons?'asc':'desc');
		break;
	}

};


onTextChange(e){
	this.setState({query:e.target.value});
}

onPageSizeChange(e){
	this.setState({pageSize:e.target.value});
	this.props.getAdditionalIntegrationData(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy,this.state.query,this.state.sortBy,this.state.sortIcons?'desc':'asc',1,e.target.value);
}

onPageChange(e){
	console.log(e);
	//this.props.getAdditionalIntegrationData(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy,this.state.query,this.state.sortBy,this.state.sortIcons?'asc':'desc',1,e.target.value);
	this.props.getAdditionalIntegrationData(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy,this.state.query,this.state.sortBy,this.state.sortIcons?'desc':'asc',e,this.state.pageSize);
}

onSearchClick(){
	this.props.getAdditionalIntegrationData(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy,this.state.query,this.state.sortBy);
}

handleDismiss() {
	this.setState({ deleteError: '' });
	}

queryBySelect(e){
	this.setState({queryBy:e.target.value});
}

sortHover(e){
	let index = e.nativeEvent.target.selectedIndex;
	this.setState({sortByTitle:e.nativeEvent.target[index].text})
}

onDeleteClick(id){
	Swal.fire({
		title: 'Are you sure you wish to delete this item?',
		width: 550,
		
		padding: '15px',
		showDenyButton: true,
		confirmButtonText: 'Yes',
		denyButtonText: 'No',
	  }).then((result) => {
		if (result.isConfirmed) {
			axios.delete("/service/api/deleteAdditionalIntegrationData/"+id).then((resp)=>{
				this.props.getAdditionalIntegrationData(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
				this.props.getServiceDetailCounts(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
			}).catch((error)=>{
				this.setState({deleteError:error.response.data[0].message})
			})
		} else if (result.isDenied) {
		  
		}
	  })
}
onSort(e){
	this.setState({sortBy:e.target.value});
	this.props.getAdditionalIntegrationData(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy,this.state.query,e.target.value)
}

componentWillMount(){
	this.props.setForm('attributesForm');
	this.props.getAdditionalIntegrationData(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,'','','');
}
render() {
const tr=this.props.tr;
	var meta=this.props.spcmReducer.meta;
	var attributes = this.props.spcmReducer.addtional_integration_data;
	return (
		<div>
		{this.state.deleteError == '' ? null :  <Alert style={{"marginBottom":"15px","fontSize":"13px","padding":"8px 30px 8px 8px"}} variant="danger" onDismiss={this.handleDismiss}><h4>{this.state.deleteError}</h4></Alert>}
		<div className=''>
			<Row>
				<Col md={8} sm={8} xs={12}>
					<div className="form-inline rightSideFields d-lg-flex d-md-block d-sm-flex d-block">

						<Form.Select onMouseOver={this.sortHover}  title={this.state.sortByTitle}   className="myControl mySel mySelectWidth" onChange={ this.queryBySelect }>
							<option value="Attribute">Keyword</option>
							<option value="Value">Value</option>
						</Form.Select>
						<div className='d-flex'>
						<Form.Control onKeyPress={this.handleKeyPress.bind(this)} className="myControl myInput margin-l-5 myInputWidth" type="text" onChange={ this.onTextChange }/>
						<Button type="submit" title="Search" onClick={ this.onSearchClick } className="myBtn"><IoSearch/></Button>
						</div>
					</div>
				</Col>
				<Col md={4} sm={4} xs={12} className="text-r rightSideFields">
					<Form.Label className="margin-r-5 display-inline-block widthAuto">{tr['Show']}</Form.Label>
					<Form.Select className="padding-5 display-inline-block widthAuto" componentClass="select" onChange={this.onPageSizeChange}>
					<option value="10" selected={this.state.pageSize==10?true:false}>10</option>
					<option value="25" selected={this.state.pageSize==25?true:false}>25</option>
					<option value="50" selected={this.state.pageSize==50?true:false}>50</option>
					<option value="100" selected={this.state.pageSize==100?true:false}>100</option>
					</Form.Select>
			</Col>

			</Row>

			{this.props.spcmReducer.spcmFetchingError == true  && this.props.spcmReducer.spcmFetchingModule == 'ATTRIBUTES' && this.props.spcmReducer.spcmErrorMsg ?  <Alert variant="danger" className="errorMessageSec margin-t-20"><p>{this.props.spcmReducer.spcmErrorMsg}</p></Alert> :  this.props.spcmReducer.spcmFetchingError == true  && this.props.spcmReducer.spcmFetchingModule == 'ATTRIBUTES' && !this.props.spcmReducer.spcmErrorMsg ? <p>Oops! Something went wrong.</p> : null}


			<div className="f-size-12">
				<Table bordered condensed hover responsive className="tableView sortImg tableWithOutPointer">
				<thead>
					<tr>
					<th width="60%" className={this.state.class1} onClick={()=>{this.sortFun('class1')}}>Keyword</th>
					<th width="30%" className={this.state.class2} onClick={()=>{this.sortFun('class2')}}>{tr['Value']}</th>
					<th width="10%" className="text-c">{tr['Action']}</th>
					</tr>
				</thead>
				{this.props.spcmReducer.spcmFetchingAttribute  ? 
				<tbody><td colSpan="3">
					<ListLoader />
				</td></tbody> : 
				


					
				<tbody>
					{attributes.map(function(value,index){
						if(value.message){
							return(<tr><td colSpan="3" className="text-center">{value.message}</td></tr>)
						}
						else{
							return (<tr>
								<td>{value.INTEGRATION_KEYWORD}</td>
								<td>{value.INTEGRATION_VALUE}</td>
								<td><div className="text-c"><i title={tr["Edit"]} onClick={()=>{this.props.setEditServiceDetails(value);this.props.componentFormFun3(true,'Edit',value.ID);this.props.setExtraCount([0]);}} className="fa fa-pencil-square-o cursorPoint"></i>
								<i title={tr["Delete"]}  className="margin-l-10 fa fa-trash-o cursorPoint" onClick={()=>{this.onDeleteClick(value.ID)}}></i></div></td>
								</tr>)
						}
						
					},this)}
				</tbody>
				}
				</Table>
			</div>

			   <div className='nBotPagina'>
			    <nav aria-label="Pagination" className="display-inline-block">
					{meta ? <Pagination  
						activePage={meta ? parseInt(meta.currentPage, 10) : 1}
						itemsCountPerPage={this.state.pageSize}
						totalItemsCount={meta ? meta.rowCount : 1}
						onChange={this.onPageChange}
					/> : null }
					</nav>
				</div>
			</div>
		</div>
	);
}
}

const mapStateToProps = ({spcmReducer}) => {
return {
	spcmReducer
}
};

export default connect(mapStateToProps,{ getAdditionalIntegrationData,setEditServiceDetails,setForm,getServiceDetailCounts })(ListView);
