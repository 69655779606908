
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Button, ListGroup, Nav } from "react-bootstrap";

import listenForOutsideClick from "../../common/clickoutside/listenForOutsideClicks";
import { useGetStatusTimelineQuery } from "../../../reducers/governance/meeting/meeting";

const StatusActions = ({
  toggle,
  data,
  setIsOpen,
  handleOnSelectAction,
  mref,
  icalURL,
  isOpen,
  meetingDefData,
  componentType,
  actionsData,
}) => {
  const [listening, setListening] = useState(false);
  const companyId = meetingDefData?.companyId;
  const definationId = meetingDefData?.meetingDefinationId;

  const removeDownload = (data, icalURL = "") => {
    if (componentType === "MeetingForum") {
      if (icalURL) {
        return data;
      }
      return data.filter((val) => val !== "Download iCAL");
    }

    return data;
  };

  useEffect(listenForOutsideClick(listening, setListening, mref, setIsOpen));

  return (
    <div className="breakFixHamburger statusHamburger" ref={mref}>
      {isOpen && (
        <ListGroup className="myLinks" style={{ top: "5px" }}>
          {actionsData?.data &&
            removeDownload(actionsData?.data, icalURL).map((item, index) => {
              if(item==='Send Minutes'){
                      return null; 
              }else if (item !== null) {
                return (
                  <ListGroup.Item key={`ham_${index}`}>
                    <Nav.Item
                      title={item}
                      onClick={() => {
                        handleOnSelectAction(item);
                      }}
                    >
                      <a alt={item} target="_blank" className="p-0">
                        <i className="fa fa-angle-double-right"></i> {item}
                      </a>
                    </Nav.Item>
                  </ListGroup.Item>
                );
              }
            })}
        </ListGroup>
      )}
    </div>
  );
};

export default StatusActions;
