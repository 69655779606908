
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import { Row, Col, ListGroup, Button, FormGroup, ButtonToolbar } from 'react-bootstrap';
import { IoClose, IoCheckmark } from "react-icons/io5";
import swal from 'sweetalert';
import { editCICategory, getCICategoryListData } from '../../../actions/categoryBoard/quickViewAction';
import {patchCompanyMenuList, getCompanyMenuListViewData} from '../../../actions/companyMenuList/companyMenuListAction.js';
import { ImSpinner6 } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";



const CompanyMenuListShortDescription = (props) => {
    const dispatch = useDispatch();
    const [isEditing, setIsEditing] = useState(false);
    const [module, setModule] = useState('');
    const [key, setKey] = useState('');
    const [companyId, setCompanyId] = useState('');
    const [status, setStatus] = useState('');
    const [sequence, setSequence] = useState('');
    const [businessFunction, setBusinessFunction] = useState('');
    const [sub_category_name, setSubCategoryName] = useState('');
    const [language, setLanguage] = useState('');
    const [field, setField] = useState('');
    const [value, setValue] = useState('');
    const [keyError, setKeyError] = useState('');
    const [sequenceError, setSequenceError] = useState('');
    const tr = useSelector((state) => state.spcmReducer.tr);

    const validateField =(fieldType, event)=> {
        switch (fieldType) {
          case 'keyError':
            if (event.target.value.trim() === "") {
                setKeyError('error' );
            } else {
                setKeyError('');
            }
            break;
          case 'sequenceError':
            if ((event.target.value && event.target.value.includes(".")) || event.target.value == "" || !(parseInt((event.target.value),10) >= 1 && parseInt((event.target.value),10) <= 100)) {
                setSequenceError('error');
            } else {
                setSequenceError('');
            }
            break;
          default:
        }
      }

    const handleEditClick = () => {
        setIsEditing(true);
        setModule(props.selectedItem.module);
        setStatus(props.selectedItem.status);
        setSequence(props.selectedItem.sequence);
        setBusinessFunction(props.selectedItem.business_function);
        setKey(props.selectedItem.key);
        setCompanyId(props.selectedItem.companyId);
        setLanguage(props.selectedItem.language);
        setField(props.selectedItem.field);
        setValue(props.selectedItem.value);
    };

    const handleSaveClick = () => {
        if (status.trim() === "" || key.trim() === "" || sequence.trim() === "" ) {
            swal({
                text: "Please fill all the mandatory fields.",
                button: 'OK',
            });
            return;
        }
        let updatedLanguage = '';
        if (language == "English") {
            updatedLanguage = 'en'
        }
        else if(language == "German"){
            updatedLanguage = 'de'
        }
        else{
            updatedLanguage = 'fr'
        }
        const updatedCategoryData = Object.assign({}, {
            companyId: companyId,
            module: module,
            field: field,
            key: props.selectedItem.key,
            value: value,
            language: updatedLanguage,
            status: status == "Active"? 1 : 0 ,
            sequence: sequence,
            newKey: key
           
        })
        dispatch(patchCompanyMenuList(updatedCategoryData));
        let filter = { page: 1, limit: 10 };
        dispatch(getCompanyMenuListViewData(filter));
        setIsEditing(false);
        props.resetFilters();
        props.setItemsPerPage(10);
        props.showRightSideFun(false);
    };

    const handleCloseClick = () => {
        setIsEditing(false);
    };

    return (
        <>
            <Row>
            <Col xs={12}>
            <div className="rPageHeadActBtn">
                <ul>
                    {!isEditing && (
                        <li>
                        <button title={tr["Edit"]} onClick={handleEditClick} className="fa fa-pencil text-white">         
                        </button>
                    </li>
                    )}
                    <li>
                        <button title={tr["Minimize the details panel"]} bsClass="" onClick={() => { props.showRightSideFun(false); }}>
                            <IoClose />
                        </button>
                    </li>
                </ul>
            </div>
            <div className="rPageHeading">
                <div className="offNam margin-t-5 margin-b-5">{tr["Company Details"]}</div>
            </div>
            </Col>
            </Row>
            <div className="rBoxGap">
                <div className="rBoxTabs">
                    <ListGroup as="ul" bsPrefix=" ">
                    <ListGroup.Item as="li" bsPrefix=" " className="rwTable">
                            <span className="rwKey">{tr["Company"]}</span>
                            <span className="rwVal">{props.selectedItem.companyName}</span>
                        </ListGroup.Item>
                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable">
                            <span className="rwKey">{tr["Module"]}</span>
                            <span className="rwVal">{props.selectedItem.module}</span>
                        </ListGroup.Item>
                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable">
                            <span className="rwKey">{tr["Field"]}</span>
                            <span className="rwVal">{props.selectedItem.field}</span>
                        </ListGroup.Item>
                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable">
                            <span className="rwKey">{tr["Value"]}</span>
                            <span className="rwVal">{props.selectedItem.value}</span>
                        </ListGroup.Item>
                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable">
                            <span className="rwKey">{tr["Language"]}</span>
                            <span className="rwVal">{props.selectedItem.language}</span>
                        </ListGroup.Item>
                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable">
                            <span className="rwKey">{tr["Business Function"]}</span>
                            <span className="rwVal">
                                {/* {isEditing ? (
                                    <FormGroup>
                                        <select
                                            className="form-control"
                                            type="text"
                                            onChange={(e) => setBusinessFunction(e.target.value)}
                                        >
                                            <option value="0">Default</option>
                                        </select>
                                    </FormGroup>
                                ) : ( */}
                                    {props.selectedItem.business_function}
                                {/* )} */}
                            </span>
                        </ListGroup.Item>
                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable">
                            <span className="rwKey">{tr["Sequence"]}</span>
                            <span className="rwVal">
                                {isEditing ? (
                                    <FormGroup>
                                        <input
                                            className={`form-control ${sequenceError}`}
                                            type=  "number"
                                            value={sequence}
                                            onChange={(e) => setSequence(parseInt(e.target.value, 10).toFixed())}
                                            onBlur={(event) => {validateField('sequenceError', event) }}
                                            maxLength={50}
                                        />
                                        {sequenceError && (
                                        <div style={{ color: 'red', fontSize: 11 }}>Please enter values between 1 and 100</div>
                                        )}
                                    </FormGroup>
                                ) : (
                                    props.selectedItem.sequence
                                )}
                            </span>
                        </ListGroup.Item>
                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable">
                            <span className="rwKey">{tr["Key"]}</span>
                            <span className="rwVal">
                                {isEditing ? (
                                    <FormGroup>
                                        <input
                                            className={`form-control ${keyError}`}
                                            type="text"
                                            value={key}
                                            onChange={(e) => setKey(e.target.value)}
                                            maxLength={50}
                                            onBlur={(event) => {validateField('keyError', event) }}
                                        />
                                    </FormGroup>
                                ) : (
                                    props.selectedItem.key
                                )}
                            </span>
                        </ListGroup.Item>
                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable">
                            <span className="rwKey">{tr["Status"]}</span>
                            <span className="rwVal">
                                {isEditing ? (
                                    <FormGroup>
                                        <select
                                            className="form-control"
                                            value={status}
                                            onChange={(e) => setStatus(e.target.value)}
                                        >
                          <>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                          </>
                        )
                      </select>
                                    </FormGroup>
                                ) : (
                                    props.selectedItem.status
                                )}
                            </span>
                        </ListGroup.Item>
                        {isEditing && (
                            <ListGroup.Item style={{ "borderBottom": "none" }} className="rwTable">
                                <span className="rwKey"></span>
                                <span className="offNam margin-t-5 margin-b-5">
                                    <ButtonToolbar className='black'>
                                        <Button
                                            size='sm'
                                            className="rgSidrkBtn smallBtn"
                                            onClick={handleSaveClick}
                                            title={tr['Save']}
                                        >
                                            {props.showLoader ? (<ImSpinner6 className="icn-spinner" />) : (<span>{tr['Save']}</span>)}
                                        </Button>
                                        <Button
                                            size='sm'
                                            className="rgSiLigBtn smallBtn"
                                            onClick={handleCloseClick}
                                            title={tr['Close']}
                                        >
                                            Close
                                        </Button>
                                    </ButtonToolbar>
                                </span>
                            </ListGroup.Item>
                        )}
                    </ListGroup>
                </div>
            </div>
        </>
    )
}

export default CompanyMenuListShortDescription;
