
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { ImInfo } from "react-icons/im";
import { Modal, Popover, Card, Button, OverlayTrigger } from "react-bootstrap";

import datetimeConvertor from "../../../../../../../../helpers/ISO8601converter";
import { getCookiesValuesByPositions } from "../../../../../../Helper";

import ListLoader from "../../../../../../../common/loaders/ListLoader";
// import NoDataAvailable from "components/Custom/NoDataAvailable";

const DiscussionPointListView = ({
  discussionPointsData,
  companyId,
  occurenceData,
  discussionsFetching,
}) => {
  const meetingOccurrananceId = occurenceData?.meetingOccurrananceId;
  const cookieValues = getCookiesValuesByPositions([2, 3]);
  const dateformat = cookieValues?.value1;
  const timezone = cookieValues?.value2;

  const jwtdf = dateformat.slice(0, 10).toUpperCase();

  const actDateTimeFormat = jwtdf + " hh:mm A";

  // const deleteDiscussion = useCallback(
  //   (minutesId, occuranceId, title, impCompID) => {
  //     confirm({
  //       title: `Do you want to delete the file - ${title}?`,
  //       icon: (
  //         <span className="anticon anticon-exclamation-circle">
  //           <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
  //         </span>
  //       ),

  //       onOk() {
  //         Delete(
  //           deleteMinutes(
  //             `?minutesId=${minutesId}&occurrenceId=${occuranceId}&tenantId=${impCompID}`
  //           ),
  //           impCompID
  //         ).then((res) => {});
  //       },
  //       onCancel() {},
  //     });
  //   },
  //   [confirm]
  // );

  return (
    <div className="border-1 p-3 mt-3 mb-2">
      {discussionsFetching ? (
        <ListLoader />
      ) : discussionPointsData?.length === 0 ? (
        <div className="custom-container-fluid mb-2">No data available</div>
      ) : (
        discussionPointsData?.map((item, index) => {
          const isoDate = datetimeConvertor(
            item.createdOn,
            jwtdf + " HH:mm:ss"
          );
          const myDateObj = moment(isoDate).format(actDateTimeFormat);
          const endDate = moment(myDateObj, actDateTimeFormat);
          const endDateStr = endDate._i;
          const startTime = moment(
            new Date().toLocaleString("en-US", { timeZone: timezone })
          );
          const timeago = moment(endDateStr, actDateTimeFormat).from(startTime);
          const duration = moment.duration(startTime.diff(endDate));
          const hours = duration.asHours();

          const tooltip = <Popover className="userInfoPopup">sdsdf </Popover>;
          const uniquekey = uuidv4();

          return (
            <div className="mt-2" key={`key_${uniquekey}`}>
              <Card className="actlogCrd actCommts" bsPrefix=" ">
                <div className="circleTxt">
                  <div className="subhed">
                    <span className="margin-r-20">
                      {item.createdByFullName}
                    </span>
                    <span className="margin-l-5 timeao">
                      <span
                        className="margin-l-5 timeao"
                        title={hours >= 24 ? "" : endDateStr}
                      >
                        {hours >= 24 ? endDateStr : timeago}
                      </span>
                    </span>
                  </div>
                  <div className="desFld">
                    <div title="Field Name">
                      {item?.discussionPoints}
                    </div>
                  </div>
                </div>
              </Card>
              {/* <div className="document-body">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="spacing-left5 pt-1  pb-1">
                      <span>{item.discussionPoints}</span>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <span className="whopublished">
                      <i className="fa fa-user-o" aria-hidden="true"></i>
                      {item.createdByFullName} &nbsp; | &nbsp;
                      <i className="fa fa-clock-o" aria-hidden="true"></i>
                      {item.createdOn}
                      <a
                        // onClick={() =>
                        //   deleteDiscussion(
                        //     item.minutesId,
                        //     item.occuranceId,
                        //     item.discussionPoints,
                        //     companyID
                        //   )
                        // }
                        className="pull-right red multipaperClip text-center"
                      >
                        <i className="fa f14 fa-trash" aria-hidden="true"></i>
                      </a>
                    </span>
                  </div>
                </div>
              </div> */}
            </div>
          );
        })
      )}
    </div>
  );
};

export default DiscussionPointListView;
