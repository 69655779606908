
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const allowedRoles = [
  "44",
  "45",
  "5",
  "13",
  "14",
  "49",
  "53",
  "37",
  "38",
  "39",
  "40",
  "41",
  "43",
  "1",
  "16",
  "17",
  "19",
  "20",
  "2",
  "50",
  "51",
  "52",
  "48",
  "12",
  "54",
  "56",
];
export const rosterManagerRoleId = "60";

function convert(str) {
  let date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

export const countDaysDiff = (start, end) => {
  if (start !== "Invalid Date" && end !== "Invalid Date") {
    let starts = moment(convert(start));
    let ends = moment(convert(end));
    if (starts.valueOf() !== ends.valueOf()) {
      const diff = Math.ceil(moment.duration(ends.diff(starts)).asDays());
      if (diff > 0) {
        return diff;
      }
    }
  }
};

export const getDateAndTime = (dateTime, jwtdf) => {
  return moment(dateTime, `${jwtdf} HH:mm:ss`).toDate();
};

const now = new Date();
export const utilEvent = [
  {
    id: 14,
    title: "Today",
    start: new Date(2024, 0, 3, 0, 0, 0),
    end: new Date(2024, 0, 3, 1, 20, 0),
    resourceId: 6217,
  },
  {
    id: 15,
    title: "Point in Time Event",
    start: new Date(2024, 0, 4, 0, 0, 0),
    end: new Date(2024, 0, 4, 13, 0, 0),
    resourceId: 6217,
  },
];
