
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import { GLOBAL } from '_Globals';
import _ from 'lodash';
import datetimeConvertor from '../../ISO8601converter';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
let homepagelocation = cookies.get('gph');
if (homepagelocation) homepagelocation = homepagelocation.replace('s:', '');
if (homepagelocation) homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
homepagelocation = homepagelocation.split("~");
let dateformatCookie = homepagelocation[2];

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

export const getDelegationList = (userId,page,limit,sortType,sortVal,status,moduleId,searchBy='',searchByValue='') => {
    let statusValue = status.toString();
    let moduleValue = moduleId.toString();
    let searchByArr=[];
    let searchByValueArr=[];
    let data={};
	data.userId="";
   
    if (searchBy != "") {
        searchByArr.push(searchBy);
        searchByValueArr.push(searchByValue);     
    }
    if (status != "" && status!=undefined) {
        searchByArr.push("status");
        searchByValueArr.push(statusValue);
    }
    if (moduleId != "") {
        searchByArr.push("moduleId");
        searchByValueArr.push(moduleValue);
    }

    data.searchBy=searchByArr.join(",");
    data.searchByValue=searchByValueArr.join("|");
    const response = api.post('/api/delegate/delegatelist'+"/page/"+page+"/limit/"+limit+"/sortType/"+sortType+"/sortVal/"+sortVal,data);
    return ((dispatch) => {
        dispatch({ type: "DELEGATE_LOADER_IN_PROGRESS" });
        response.then((delegationList) => {
            if(delegationList.errorCode) {
                dispatch(getDelegationListFailure(delegationList));
            }else {
                delegationList.data.delegationList.map((item)=>{
                     item.delegateDateforFrom=datetimeConvertor(item.delegateDateforFrom,dateformatCookie);
                     item.delegateDateforTo=datetimeConvertor(item.delegateDateforTo,dateformatCookie);
                });
                dispatch(getDelegationListSuccess(delegationList));
            }
                dispatch({ type: 'DELEGATE_LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
        })
        .catch((err) => {
            dispatch(getDelegationListFailure(err));
            dispatch({ type: 'DELEGATE_LOADER_DETAILS_FETCHED_SUCCESSFULLY' });

        });
    });
};

const getDelegationListSuccess = (delegationList) => {
    return {
        type: 'DELEGATION_LIST_SUCCESS',
        delegationList
    };
};

const getDelegationListFailure = (errorResponse) => {
    return {
        type: 'DELEGATION_LIST_FAILURE',
        errorResponse
    };
};
