
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, {Component} from 'react';
import AutoSuggest from 'react-autosuggest';
import {connect} from 'react-redux';
import axios from 'axios';
import {change} from "redux-form";

import { GLOBAL } from '_Globals';

const api = axios.create({
  headers: {'Pragma': 'no-cache', 'Cache-control': 'no-store'},
});

import Cookies from "universal-cookie";
const cookies = new Cookies();
let userInfo = cookies.get('gph');
if (userInfo) userInfo = userInfo.replace('s:', '');
if (userInfo) userInfo = userInfo.substring(0, userInfo.lastIndexOf('.'));
userInfo = userInfo.split("~");
userInfo = userInfo[48];

let selectOptions = [];

const getSuggestions = (value, options) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  if (inputLength > 2) {
    selectOptions = options;
  }
  return inputLength < 2 ? [] : selectOptions.filter(option => option.label.toLowerCase().indexOf(inputValue) > -1);
};

const getSuggestionValue = (suggestion) => {
  let suggestionlabel = [];
  suggestionlabel = suggestion.label.split("|");
  GLOBAL.deliveryRecipientId = suggestionlabel[2];
  GLOBAL.deliveryRecipientName = suggestionlabel[0];
  GLOBAL.recipientContactNumber = suggestionlabel[6];
  return(
    suggestion.label.indexOf == -1 ? '' : suggestion.label.substring(0, suggestion.label.indexOf('|'))
  );
};

function renderSuggestion(suggestion) {
  let suggestionlabel = [];
  suggestionlabel = suggestion.label.split("|");
  return (
    <span>{suggestionlabel[0] + " | " + suggestionlabel[5] + " | " + suggestionlabel[1]}</span>
  );
}


class SearchDeliveryRecipient extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.onDeliveryRecipientUpdate = this.onDeliveryRecipientUpdate.bind(this);
    this.state = {
      value: '',
      suggestions: []
    };
  }

  onChange(event, {newValue}) {
    if(newValue===""){
      this.props.dispatch(change('orderingForm', 'recipientId', ""));
    }
    this.props.setIsOtherDeliveryLocationValid(false);
    this.setState({
      value: newValue
    });
  }

  onSuggestionsFetchRequested({value}) {
    let consumerData = [];
    let consumerDataDetails = [];
    let requestorObj = [];
    let offeringDetailsData = this.props.offeringDetails;

    if (value.length > 2) {
      let str1 = {};
      str1.userId = "";
      str1.like = value;
      if(offeringDetailsData[0].isShared == "Yes"){
      str1.userId = "";
      str1.like = value;
      // str1.companyId = offeringDetailsData[0].sharedCompanyId.toString();
      if (offeringDetailsData[0].company == userInfo) {
        str1.companyId = offeringDetailsData[0].company + "," + offeringDetailsData[0].sharedCompanyId.toString();
      } else if (offeringDetailsData[0].company != userInfo) {
        str1.companyId = userInfo;
      }
      str1.isShared = offeringDetailsData[0].isShared;
      }
      else{
        str1.userId = "";
        str1.like = value;
      }
      str1 = JSON.stringify(str1);
      api.get(GLOBAL.onbehalfuser, {headers: {'query': str1}}).then((response) => {
        consumerData = response.data;
        for (let i = 0; i < consumerData.length; i++) {
          requestorObj = {value: '', label: ''};
          requestorObj.value = consumerData[i].companyId;
          requestorObj.label = consumerData[i].fullname + "|" + consumerData[i].email + "|" + consumerData[i].userId
            + "|" + consumerData[i].companyId + "|" + consumerData[i].locationId + "|" + consumerData[i].employeeNumber + "|"
            + consumerData[i].businessPhone + "|" + consumerData[i].cubicle + "|" + consumerData[i].locationName;
          consumerDataDetails.push(requestorObj);
        }
        consumerDataDetails = Array.from(new Set(consumerDataDetails));
        this.setState({
          suggestions: getSuggestions(value, consumerDataDetails)
        });
      });
    } else {
      this.setState({
        suggestions: []
      });
    }
  }

  onSuggestionsClearRequested() {
    this.setState({
      suggestions: []
    });
  }

  onDeliveryRecipientUpdate() {
    this.props.dispatch(change('orderingForm', 'recipientContact', GLOBAL.recipientContactNumber));
    this.props.dispatch(change('orderingForm', 'recipientId', GLOBAL.deliveryRecipientId));
    this.props.dispatch(change('orderingForm', 'recipientName', GLOBAL.deliveryRecipientName));
    this.props.setIsOtherDeliveryLocationValid(true);
  }

  render() {

    const {value, suggestions} = this.state;
    const inputProps = {
      placeholder: this.props.translator["Enter name or Username"],
      className: 'form-control',
      value,
      onChange: this.onChange,
    };
    return (
      <AutoSuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={this.onDeliveryRecipientUpdate}
      />
    );
  }
}
const mapStateToProps = ({
  offeringDetails
}) => {
  return { offeringDetails };
};

export default connect(mapStateToProps, null)(SearchDeliveryRecipient);
