
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Link } from 'react-router';
import ReactDOM from 'react-dom';
import Spinner from 'react-spinkit';
import { Row, Col, Nav, NavItem, ListGroup, FormGroup, PanelGroup, FormControl, ControlLabel, ListGroupItem, Media, Image, ButtonToolbar, Button, Accordion, Panel, Alert } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { field_file } from '../common/formFields';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { UpdateProfile } from '_Actions/myProfile/profileAction';
import { loadProfile } from '_Actions/myProfile/profileAction';
import EmailValidator from 'email-validator';
import dummyImage from "../../images/profileimg.png";
const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});
import { GLOBAL } from '_Globals';
let updatedAlternatePhone = "";
let updatedAlternateEmail = "";
class ProfileImg extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			inputClassName: true,
			textInputType1: "text",
			textInputType2: "hidden",
			CPErrorColor: "",
			BPErrorColor: "",
			MPErrorColor: "",
			image: "",
			editSelected: 0,
			contactSaveMsg: "",
			// updateContact: 0,
			// updateMobile: 0,
			// updateBusiness: 0,
			updateAlternatePhone: 0,
			updateAlternateEmail: 0,
			spinner: '',
			showLoader: false,
			alternateEmailError: ''
		};
		this.fileChangedHandler = this.fileChangedHandler.bind(this);
	};
	// updateContactPhone(myInput) {
	// 	if (myInput.className == "form-control editInput") {
	// 		myInput.className = "form-control showUpdatebtn";
	// 		myInput.disabled = false;
	// 		myInput.focus();
	// 		this.setState({ updateContact: 1, showSavedMessage: "" })
	// 	}
	// 	else {
	// 		myInput.className = "form-control editInput";
	// 		myInput.disabled = true;
	// 		this.setState({ updateContact: 0 })
	// 	}

	// };

	// updateMobilePhone(myInput) {
	// 	if (myInput.className == "form-control editInput") {
	// 		myInput.className = "form-control showUpdatebtn";
	// 		myInput.disabled = false;
	// 		myInput.focus();
	// 		this.setState({ updateMobile: 1, showSavedMessage: "" })
	// 	}
	// 	else {
	// 		myInput.className = "form-control editInput";
	// 		myInput.disabled = true;
	// 		this.setState({ updateMobile: 0 })
	// 	}

	// };

	// updateBusinessPhone(myInput) {
	// 	if (myInput.className == "form-control editInput") {
	// 		myInput.className = "form-control showUpdatebtn";
	// 		myInput.disabled = false;
	// 		myInput.focus();
	// 		this.setState({ updateBusiness: 1, showSavedMessage: "" })
	// 	}
	// 	else {
	// 		myInput.className = "form-control editInput";
	// 		myInput.disabled = true;
	// 		this.setState({ updateBusiness: 0 })
	// 	}

	// };

	updateAlternatePhone(myInput) {
		if (myInput.className == "form-control editInput") {
			myInput.className = "form-control showUpdatebtn";
			myInput.disabled = false;
			myInput.focus();
			this.setState({ updateAlternatePhone: 1, showSavedMessage: "" })
		}
		else {
			myInput.className = "form-control editInput";
			myInput.disabled = true;
			this.setState({ updateAlternatePhone: 0 })
		}

	};

	updateAlternateEmail(myInput) {
		if (myInput.className == "form-control editInput") {
			myInput.className = "form-control showUpdatebtn";
			myInput.disabled = false;
			myInput.focus();
			this.setState({ updateAlternateEmail: 1, showSavedMessage: "" })
		}
		else {
			myInput.className = "form-control editInput";
			myInput.disabled = true;
			this.setState({ updateAlternateEmail: 0 })
		}

	};

	attchDivFun(myInput) {
		//alert("myInput"+myInput.imgAttchInput)
		if (myInput.className == "imgAttchInput nodisplay") {
			myInput.className = "imgAttchInput";
		}
		else {
			myInput.className = "imgAttchInput nodisplay";
		}


	};

	// changeCP(e) {
	// 	//alert("e.target.value"+e.target.value);
	// 	updatedCP = e.target.value;
	// 	this.setState({
	// 		CPErrorColor: ""
	// 	});
	// 	console.log("titlestate==" + updatedCP)
	// }
	// changeMP(e) {
	// 	updatedMP = e.target.value;
	// 	this.setState({
	// 		MPErrorColor: ""
	// 	});
	// }

	// changeBP(e) {
	// 	updatedBP = e.target.value;
	// 	this.setState({
	// 		BPErrorColor: ""
	// 	});
	// }

	changeAlternatePhone(e) {
		updatedAlternatePhone = e.target.value;
	}

	changeAlternateEmail(e) {
		updatedAlternateEmail = e.target.value;
	}

	updateContactInfo(textInput4, textInput6) {

		// textInput1.className = "form-control editInput";
		// textInput2.className = "form-control editInput";
		// textInput3.className = "form-control editInput";

		let userId = this.props.profileDetails.userId;

		// let BPValue = "";
		// let CPValue = "";
		// let MPValue = "";
		let AlternatePhoneValue = "";
		let AlternateEmailValue = "";
		//=================
		// if (updatedBP == "") {
		// 	//alert("titleValue");
		// 	BPValue = this.props.profileDetails.businessPhone
		// } else {
		// 	BPValue = updatedBP
		// }
		//===================
		// if (updatedCP == "") {
		// 	//alert("titleValue");
		// 	CPValue = this.props.profileDetails.contactPhone
		// } else {
		// 	CPValue = updatedCP
		// }
		//===================
		// if (updatedMP == "") {
		// 	//alert("titleValue");
		// 	MPValue = this.props.profileDetails.mobilePhone
		// } else {
		// 	MPValue = updatedMP
		// }
		//=================
		if (updatedAlternatePhone == "") {
			//alert("titleValue");
			AlternatePhoneValue = this.props.profileDetails.alternatePhone
		} else {
			AlternatePhoneValue = updatedAlternatePhone
		}
		//=================
		if (updatedAlternateEmail == "") {
			//alert("titleValue");
			AlternateEmailValue = this.props.profileDetails.alternateEmail
		} else {
			AlternateEmailValue = updatedAlternateEmail
		}

		if (EmailValidator.validate(AlternateEmailValue) == false) {
			this.setState({ alternateEmailError: this.props.tr['Please enter a valid email address!'] });
			return false;
			// alert("Please enter a valid email address!")
		}
		else {
			this.setState({ alternateEmailError: '' });
		}

		textInput4.className = "form-control editInput";
		textInput6.className = "form-control editInput";
		textInput4.disabled = true;
		textInput6.disabled = true;

		this.setState({ showLoader: true });
		//alert("updateContactInfo=======>"+MPValue+"-userId--"+userId);
		api.put(GLOBAL.profilePostUrl + userId, {
			"userId": userId,
			// "businessPhone": BPValue,
			// "contactPhone": CPValue,
			// "mobilePhone": MPValue,
			"alternatePhone": AlternatePhoneValue,
			"alternateEmail": AlternateEmailValue
		}).
			then((response) => {
				this.props.loadProfile();
				window.history.pushState({},"","/myProfile");
				console.log("Contact details have been updated successfully");
				//console.log("response status:"+response.statusText)
				if (response.status === 200) {
					this.setState({ showLoader: false, showSavedMessage: this.props.tr['Profile has been updated successfully'], updateAlternatePhone: 0, updateAlternateEmail: 0 })
				}
				//this.setState({contactSaveMsg:"Conact details have been updated successfully"})
				//
			});
	}

	// onLoadStart() {
	// 	setTimeout(this.applyApi.bind(this), 3000)
	// }
	applyApi() {
		// alert("Inside APPLYAPI")
		// alert("onLoadStart");
		// console.log(this.props.profileDetails.profilePicTempURL)
		this.setState({
			image: this.props.profileDetails.profilePicTempURL


		});
		// this.setState({
		// 	spinner: 1


		// });
		// // ReactDOM.render()
		// setTimeout(this.forceUpdate.bind(this), 000)
		// this.render();

	}
	// upImgChange(event) {
	// 	const file = event.target.files[0]

	// 	alert("Test")
	// 	debugger
	// }
	fileChangedHandler(event) {
		let companyID = this.props.profileDetails.companyId;
		let userID = this.props.profileDetails.userId
		// alert("Ytyuhb")
		const file = event.target.files[0];
		let formData = new FormData();
		formData.append('imageFiles', file);
		formData.append('companyID', companyID);
		formData.append('userID', userID);
		// this.UpdateProfile()
		// this.onLoadStart()
		axios.post("/upload/profile/Image", formData).then((response) => {

			if (response.status == 200) {

				this.props.loadProfile();
				//this.applyApi()
				console.log(this.props.profileDetails.profilePicTempURL)
				//setTimeout(this.applyApi.bind(this), 4000)
			}

		})
			.catch((error) => {


			});
	}
	render() {
		// debugger
		//alert(this.state.updateCount)
		console.log("my prfile rander......");
		if (this.props.profileDetails.length == 0) {
			//alert("this.props.breakFixEditDetails.length"+this.props.breakFixEditDetails.length)
			return (
				<Spinner spinnerName="three-bounce" />
			);
		}
		let image = "";
		if (this.state.image == "") {
			image = this.props.profileDetails.profilePicTempURL;
		} else {
			image = this.state.image;
		}
		console.log('this.propsthis.props', this.props, GLOBAL.profileDetailsImageUploadUrl)
		// let companyID = this.props.profileDetails.companyId;
		// let userID = this.props.profileDetails.userId

		// let imageURL = GLOBAL.profileDetailsImageUploadUrl + "/" + userID + "/" + companyID
		//alert("imageURL"+imageURL)
		//alert("companyID"+this.props.profileDetails.companyId)
		console.log("this.props.profileDetails.container",this.props.profileDetails,this.props.profileDetails.container)

		return (
			<div className="userInfo text-c padding-b-20">
				<div className="userPImg"><img alt={this.props.profileDetails.username} src={this.props.profileDetails.container!=null && this.props.profileDetails.container!=""?this.props.profileDetails.profilePicTempURL:dummyImage} /></div>
				<div className="position-re" style={{ opacity: "0" }} >
					<input className='hidden' type="file" id="upImg" style={{ width: "108px" }} onChange={this.fileChangedHandler} />
				</div>
				<div className="userNa">{this.props.profileDetails.username}</div>
				<div className="UserDesi">{this.props.profileDetails.role}</div>
				<div className="UserDesi">{this.props.profileDetails.email}</div>
			</div>
		);
	}
}
ProfileImg = reduxForm({
	form: 'fileUploadForm',
	enableReinitialize: true
})(ProfileImg)

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ loadProfile }, dispatch);
}

export default connect(null, mapDispatchToProps)(ProfileImg);
