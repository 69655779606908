
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
const xsm_foundation_path=process.env.XSMF_FOUNDATION_HOST;
const d2c_path=process.env.D2C_FOUNDATION_HOST;
// const spcm_path=process.env.SPCM_HOST;
const ams_path=process.env.AMS_HOST;
const tms_path=process.env.TMS_HOST;
const sla_path=process.env.SLA_HOST;
const review_path=process.env.Review_HOST;
const cmdb_path=process.env.CMDB_HOST;
const community_path=process.env.COMMUNITY_HOST;
// const spcm_service_path=process.env.SPCM_SERVICE_HOST;
const spcm_host = process.env.SPCM_HOST;


//console.log("tms_path"+tms_path+"d2c_path"+d2c_path);

module.exports = {
	"INTERNAL_SERVER_ERROR": 500,
	"INVALID_INPUT": 400,
	"SUCCESS": 200,
	"SUCCESS_NO_CHANGE": 304,
	"ERROR_START_CODE": 400,
	"NO_DATA_FOUND": 404,
	"VCAP_SERVICES_CLOUDANT":"cloudantNoSQLDB",
	"VCAP_SERVICES_SSO":"SingleSignOn",
	"VCAP_SERVICES_STT":"speech_to_text",
  	"VCAP_SERVICES_TTS":"text_to_speech",
	"CHAT_MESSAGE_SOURCE_USER": "U",
	"CHAT_MESSAGE_SOURCE_BOT": "B",
	"CHAT_BOT_NAME": "Alfy",
	"JWT_TOKEN_COOKIE":"access_token",
  	"API_KEY":"d2c-234",
  	"JWT_SECRET":process.env.JWT_SECRET,
  	"JWT_ALGO":"HS256",
  	"JWT_TOKEN_EXPIRY":"7d",
  	"JWT_TOKEN_NAME":"x-auth-token",
	"API_SOURCE_MOCK":"M",
	"API_SOURCE_REAL":"R",
	"CHAT_API_URL":"/api/chat",
  	"CHAT_MAIL_URL":"/api/mail",
	"CHAT_OW_ACTION_URL":"https://service.us.apiconnect.ibmcloud.com/gws/apigateway/api/f17286b9a52c5267bdc764f21c7b6f35bd8d3b4bb8b32b25d4a8c09a33eda598/alfy/postData1",
  	"CHAT_API_KEY": "4e725647-7776-4098-80c4-da6ff095be7a",
	"HTTP_URL": "url",
	"HTTP_CONTENT_TYPE": "Content-Type",
	"HTTP_CONTENT_TYPE_JSON": "application/json",
	"HTTP_AUTHORIZATION_HEADER": "Authorization",
	"HTTP_AUTH_BEARER": "Bearer",
	"CHATBOT_ME_LABEL": "Me",

  //'SERVICE_DETAILS_API_URL':'https://spcm-test.mybluemix.net/api/serviceDetails/v2',
	//'SERVER':'https://spcm-test.mybluemix.net',
	//'FOUNDATAION_SERVER':'https://xsm-f.mybluemix.net',
	'SERVICE_API_URL':'/api/serviceDetails',
	'SERVICEPOST_API_URL':'/service/api/postService',
	'SERVICEPATCH_API_URL':'/service/api/patchService',
	'COMPANY_API_URL':'/api/serviceportfolio/companylist',
	'CHAIN_COMPANY_API_URL':'/api/serviceportfolio/chaincompanylist',
	'SERVICE_GROUP_API_URL':'/api/serviceportfolio/grouplistsp',
	'SERVICE_USER_API_URL':'/api/userListSelfService',
	'CRITICALITY_LIST_API_URL':'/api/serviceportfolio/criticalityListSPSelfService',
	'CATEGORY_LIST_API_URL':'/getSubCategoryListForRestifySelfService',
	'CONSUMPTION_ITEMS_API_URL':'/api/consumptionitems',
	'OFG_QUESTIONS_API_URL':'/api/ofgQuestions',
	'OFG_ATTRIBUTES_API_URL':'/api/ofgAttributes',
	'ENTITLEMENT_API_URL':'/api/entitlement',
	'SERVICE_BILLING_API_URL':'/api/serviceBilling',
	//'xsmurl':'https://xsm-f-qa.mybluemix.net',
	'SERVICE_KNOWLEDGE_API_URL':'/api/serviceKnowledge',
	'SERVICE_FULFILMENT_PLANS':'/api/ofgFulfilmentPlans',
	'CHAIN_ENTITY_API_URL':'/api/chainentities',
	'OFG_PERFORMANCE_TARGETS_API_URL':'/api/ofgPerformanceTargerts',
	'OFG_SELF_SERVICE_ACTIONS_API_URL':'/api/ofgSelfServiceActions',
	'ENTITLEMENT_ENTITY_API_URL':'/api/entitlement/entitledfor',
	'AUDIT_API_URL':'/api/audit/',
	//'slaurl':'https://slaengine-xsm-d2c.mybluemix.net',
	'slamodvalbreakfix':'5',
	'slamodvalitem':'15',
	'slamodvalinv':'10',
	'slamodvalchnge':'20',
	'slamodvaltask':'25',
	//'xsmspcmurl':'https://spcm-test.mybluemix.net/api/foundations',

	//'d2curl':'https://xsm-d2c.mybluemix.net/serviceManagement/rest',
	//'amsurl':'https://xsm-assignment.mybluemix.net',
	//'tmsurl':'https://tasksresttest.mybluemix.net',
	//'cmdburl':'https://cmdb-test.mybluemix.net',
	'communityurl':'https://community-assistance.mybluemix.net',
	'APPROVAL_RULE_URL':'/api/approvalrule/listdata',
	'APPROVAL_GROUP_URL':'/api/taskgroup/grouplist',
	'SLA_MENULIST_URL' : 'api/sla/menulist',
	'APPROVAL_API_URL' : '/api/approval/createapprovalrule',
	'EDIT_APPROVAL_API_URL':'/api/approval/editapprovalRule',
	//'SLA_SERVER':'https://slaengine-xsm-d2c.mybluemix.net',
	'SLA_DEFINITION_URL':'/api/sladefinition',
	'APPROVAL_GET_URL':'/api/approval/ruleDetails',
	'APPROVALS_GET_URL':'/api/approval/rulelist',
	'SLA_DEFINITION_SERVICE_URL':'/api/sladefinitions/service',
	'SLA_WORKDAY_SCHEDULE_API' : '/api/getParticularWorkdayScheduleData',
	'reviewurl':review_path,
	//'spcmserviceurl':'https://spcm-test.mybluemix.net/api/serviceDetails'
}
