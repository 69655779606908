
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Form, InputGroup, Image } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  _dateTimeField,
  _dropDown,
} from "../../../../common/ReduxFormFields/formFields";
import { Field, change, reduxForm } from "redux-form";
import { getCostCenterCompanyOptions } from "../../../../../actions/foundation/costCenterAction";
import {
  TypeaheadandDropdown,
  _ckeditor,
  _inputField,
  _number,
  field_file,
} from "../../../../common/formFields";
import moment from "moment";
import ListLoader from "../../../../common/loaders/ListLoader";
import dummyimage from "_Images/service-icons/service-dummy.png";
import Cookies from "universal-cookie";
const cookies = new Cookies();

let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
//console.log("homepage::::"+homepagelocation);
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

const validate = (values) => {
  let errors = {};
  console.log("validate+++++", values);

  if ((values.sequence && values.sequence.includes(".")) || values.sequence == "" || !(parseInt((values.sequence),10) >= 1 && parseInt((values.sequence),10) <= 10)) {
    errors.sequence = "Please enter values between 1 and 10";
  }
  if (!values.status || values.status === "0") {
    errors.status = "Select Status";
  }
  if (!values.title || values.title.trim() === "") {
    errors.title = "Select Title";
  }
  if (!values.description || values.description.trim() === "") {
    errors.description = "Select Description";
  }
  if (values.toDate && typeof values.toDate === "object") {
    values.toDate = values.toDate.format(jwtdf + " hh:mm A");
  }
  if (values.fromDate && typeof values.fromDate === "object") {
    values.fromDate = values.fromDate.format(jwtdf + " hh:mm A");
  }
  if(values.toDate && values.fromDate) {
    if (moment(values.fromDate, jwtdf + " hh:mm A") > moment(values.toDate, jwtdf + " hh:mm A")) {
			errors.toDate = 'To date cannot be less than From date.';
		}
  }
  if (
    (!values.company_id && values.company_id !== 0) ||
    values.company_id === null ||
    !values.company_name ||
    values.company_name === null ||
    values.company_name.trim().length === 0
  ) {
    errors.companyName = "Select Company";
  }

  return errors;
};

let BulletinForm = (props) => {
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [initialValuesSet, setInitialValuesSet] = useState(false);

  const showLoader = useSelector((state) => state.commonLoader.isLoading);
  const tr = useSelector((state) => state.spcmReducer.tr);
  const companyData = useSelector((state) => state.costCenterCompanyDropdown);

  const statusDropdownOptions = [
    { field1Value: "Inactive", field1Key: "Inactive" },
    { field1Value: "Active", field1Key: "Active" },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCostCenterCompanyOptions());
  }, []);

  useEffect(() => {
    if (props.formType === "Create" && initialValuesSet === false) {
      dispatch(change("bulletinForm", "fromDate", moment()));
      dispatch(change("bulletinForm", "toDate", moment()));
      setInitialValuesSet(true);
    } else if (props.formType === "Edit") {
      if (
        Object.keys(props.BulletinEditData).length > 0 &&
        initialValuesSet === false
      ) {
        console.log("edited --- ", props.initialValues);
        dispatch(
          change("bulletinForm", "company_id", props.initialValues.companyId)
        );
        dispatch(
          change(
            "bulletinForm",
            "company_name",
            props.initialValues.companyName
          )
        );
        dispatch(
          change(
            "bulletinForm",
            "fromDate",
            moment(props.initialValues.fromDateStr, jwtdf + " hh:mm A")
          )
        );
        dispatch(
          change(
            "bulletinForm",
            "toDate",
            moment(props.initialValues.toDateStr, jwtdf + " hh:mm A")
          )
        );
        setSelectedCompany([
          {
            label: props.initialValues.companyName,
            id: props.initialValues.companyId,
          },
        ]);
        setInitialValuesSet(true);
      }
    }
  }, [props.initialValues]);

  useEffect(() => {
    if (companyData && companyData.length > 0) {
      setCompanyList(companyData);
    }
  }, [companyData]);

  const onCompanySelection = (selectedCompany) => {
    console.log("selectedOPtion: ", selectedCompany);
    if (selectedCompany.length > 0) {
      setSelectedCompany(selectedCompany);
      dispatch(
        change("bulletinForm", "company_name", selectedCompany[0].label)
      );
      dispatch(change("bulletinForm", "company_id", selectedCompany[0].id));
    } else {
      setSelectedCompany([]);
      dispatch(change("bulletinForm", "company_name", ""));
      dispatch(change("bulletinForm", "company_id", ""));
    }
  };

  const onCrossClickCompany = () => {
    setSelectedCompany([]);
    dispatch(change("bulletinForm", "company_name", ""));
    dispatch(change("bulletinForm", "company_id", ""));
  };

  return (
    <>
      {showLoader ? (
        <ListLoader />
      ) : (
        <Form>
          <Row>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>
                  <span className="rStar"></span>{tr["Company"]}
                </Form.Label>
                <Field
                  id="companyName"
                  name="companyName"
                  className="form-control"
                  component={TypeaheadandDropdown}
                  onSelection={onCompanySelection}
                  options={companyList}
                  selectedOptions={selectedCompany}
                  onCrossClick={onCrossClickCompany}
                />
              </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>
                  <span className="rStar"></span>{tr["Status"]}
                </Form.Label>
                <Field
                  name="status"
                  component={_dropDown}
                  options={statusDropdownOptions}
                  className="form-control"
                />
              </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>
                  <span className="rStar"></span>{tr["From Date"]}
                </Form.Label>
                <div className="dateTimeSdiv">
                  <Field
                    name="fromDate"
                    component={_dateTimeField}
                    className="calIcon"
                    formType="fdnCreate"
                    disabledValue={false}
                    readOnlyValue={true}
                    isValidDate={(current) =>
                      current.isAfter(moment().subtract(1, "d"))
                    }
                  />
                </div>
              </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>
                  <span className="rStar"></span>{tr["To Date"]}
                </Form.Label>
                <div className="dateTimeSdiv">
                  <Field
                    name="toDate"
                    component={_dateTimeField}
                    className="calIcon"
                    formType="fdnCreate"
                    disabledValue={false}
                    readOnlyValue={true}
                    isValidDate={(current) =>
                      current.isAfter(moment().subtract(1, "d"))
                    }
                  />
                </div>
              </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>
                  <span className="rStar"></span>{tr["Bulletin Sequence"]}
                </Form.Label>
                <Field
                  className="form-control"
                  name="sequence"
                  component={_number}
                  min={1} max={10}
                />
              </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>{tr["URL"]}</Form.Label>
                <Field
                  className="form-control"
                  name="bulletinURL"
                  component={_inputField}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label><span className="rStar"></span>{tr["Title"]}</Form.Label>
                <Field
                  className="form-control"
                  name="title"
                  component={_inputField}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label bsClass=""><span className="rStar"></span>{tr["Description"]}</Form.Label>
                <Field
                  name="description"
                  component={_ckeditor}
                  className={"reactQuillEditor-area"}
                  richTextConfig={"minRichTextEditor"}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

BulletinForm = reduxForm({
  form: "bulletinForm",
  enableReinitialize: true,
  destroyOnUnmount: true,
  validate,
})(BulletinForm);

BulletinForm = connect(({ BulletinEditData }) => ({
  initialValues: {
    ...BulletinEditData,
    status: BulletinEditData?.status,
    sequence: BulletinEditData?.bulletinSequence,
    bulletinURL: BulletinEditData?.bulletinUrl,
    toDateStr: BulletinEditData?.toDate,
    fromDateStr: BulletinEditData?.fromDate,
    description: BulletinEditData?.details,
    title: BulletinEditData?.title
  },
  BulletinEditData,
}))(BulletinForm);

export default BulletinForm;
