
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link } from "react-router-dom";
import { reduxForm } from 'redux-form';
import { Button } from "react-bootstrap";
import {PiPencilSimpleBold} from "react-icons/pi";
import {IoClose} from "react-icons/io5";
import { MyContext } from '_MyContext';

const NotificationBoardShortDesc = (props) => {
  const [tabKey, setTabKey] = useState(1);

  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const editButton = useRef(null);

  useEffect(() => {
    context.keyDownHandler(closeButton.current,editButton.current,"","");
    context.newTabPrevent();
  }, []);

  let languageKey ;
  let statusKey ;
  if(props.LOCALE_ID == "en"){
    languageKey = "English";
  }  
  else if(props.LOCALE_ID == "ar"){ languageKey = "Arabic";}
  else if(props.LOCALE_ID == "zhcn"){ languageKey = "Chinese Simplified";}
  else if(props.LOCALE_ID == "fr"){ languageKey = "French";}
  else if(props.LOCALE_ID == "fil"){ languageKey = "Filipino";}
  else if(props.LOCALE_ID == "hi"){ languageKey = "Hindi";}
  else if(props.LOCALE_ID == "id"){ languageKey = "Indonesian";}
  else if(props.LOCALE_ID == "it"){ languageKey = "Italian";}
  else if(props.LOCALE_ID == "ja"){ languageKey = "Japanese";}
  else if(props.LOCALE_ID == "ko"){ languageKey = "Korean";}
  else if(props.LOCALE_ID == "ms"){ languageKey = "Malay";}
  else if(props.LOCALE_ID == "es"){ languageKey = "Spanish";}
  else if(props.LOCALE_ID == "ta"){ languageKey = "Tamil";}
  else if(props.LOCALE_ID == "th"){ languageKey = "Thai";}
  else if(props.LOCALE_ID == "vi"){ languageKey = "Vietnamese";} 
  else{
    languageKey = "German";
  }
  if(props.STATUS == "ACTIVE"){
    statusKey = "Active";
  }else statusKey = "Inactive";


  return (
    <div>
      <Row>
        <Col xs={12}>
          <div className="rPageHeadActBtn">
            <ul>
              <li><Link to={ "/notificationEditTemplate/" + props.ID + "/" + props.LOCALE_ID } state={{ tenantId: props.COMPANY_NAME }} title={props.tr['Edit']} ref={editButton} className="ctrlKeyPrevent" ><PiPencilSimpleBold/></Link></li>
              <li><Link to="javascript:void(0)" className="ctrlKeyPrevent" onClick={() => { props.onRowClick(false); }} title={props.tr["Minimize the details panel"]} bsClass="" ref={closeButton} >
                <IoClose/>
              </Link>
              </li>
            </ul>
          </div>
          <div className="rPageHeading">Noti0000{props.ID}<div className="offNam">{props.NOTIFICATION_NAME}</div></div>
        </Col>
      </Row>

      <div className="rBoxGap">
      <div className="rBoxTabs hideTabNav">
        <Tabs activeKey={tabKey} onSelect={setTabKey} id="tabId">
          <Tab eventKey={1} title="Notification Details">
            <ListGroup bsPrefix=" ">
              <ListGroup.Item bsPrefix=" " className="rwTable" style={{borderTop:'none' }}><span className="rwKey">{props.tr['Company']}</span><span className="rwVal" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'anywhere' }}>{props.COMPANY_NAME}</span></ListGroup.Item>
              <ListGroup.Item bsPrefix=" " className="rwTable" ><span className="rwKey">{props.tr['Module']}</span><span className="rwVal">{props.MODULE_NAME}</span></ListGroup.Item>
              <ListGroup.Item bsPrefix=" " className="rwTable" ><span className="rwKey">{props.tr["Language"]}</span><span className="rwVal">{languageKey}</span></ListGroup.Item>
              <ListGroup.Item bsPrefix=" " className="rwTable"><span className="rwKey">{props.tr['Status']}</span><span className="rwVal">{statusKey}</span></ListGroup.Item>
              {/* {props.COMPANY_NAME=='Default'? <div class="paHedFilter"><Link to={"/notificationOverrideTemplate/"+props.ID+"/"+props.LOCALE_ID} state={{ tenantId: props.COMPANY_NAME }} type="button" title="Override" className="myBt plus fillBtn">Override</Link></div>:''} */}
              <div className="margin-t-15">
                {props.COMPANY_NAME=='Default'? <Link to={"/notificationOverrideTemplate/"+props.ID+"/"+props.LOCALE_ID} state={{ tenantId: props.COMPANY_NAME }} type="button" size="sm" bsStyle="primary" title="Create Template" className="rgSidrkBtn">Create Template</Link>:''}
              </div>
            </ListGroup >
           
            </Tab>
          </Tabs>
          <div>
         
          </div>
        </div>
      </div>
    </div>
  );
}

export default reduxForm({
  form: 'approvalOfferingDetails',
  enableReinitialize: true,
})(NotificationBoardShortDesc);