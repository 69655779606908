
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import _ from "lodash";
import Cookies from "universal-cookie";

const cookies = new Cookies();
let userInfo = cookies.get('gph');

if (userInfo) userInfo = userInfo.replace('s:', '');
if (userInfo) userInfo = userInfo.substring(0, userInfo.lastIndexOf('.'));
userInfo = userInfo.split("~");
let cubicalNumber = userInfo[7] === undefined || userInfo[7] === "undefined" ? "" : userInfo[7];
let contactNumber = userInfo[8] === undefined || userInfo[8] === "undefined" ? "" : userInfo[8];
export const cartOfferings = (state = [], action) => {
  switch (action.type) {
    case 'CART_OFFERING_LIST_SUCCESS':
      return action.cartOfferings.data;
    case 'CART_OFFERING_LIST_FAILURE':
      return action.errorResponse;
    default:
      return state;
  }
};

export const orderDeliverTypes = (state = {
  isFetching: true,
  isEmpty: false,
  deliveryList: [],
  message: ''
}, action) => {
  switch (action.type) {
    case 'SERVICE_DELIVERY_OPTIONS_NO_DATA':
       return {...state, ...{
        isFetching: action.isFetching,
        isEmpty: action.isEmpty,
        message: action.message,
        deliveryList: []
      }}
    case 'LOADING_SERVICE_DELIVERY_OPTIONS':
      return {...state, ...{
        isFetching: action.isFetching,
        isEmpty: action.isEmpty,
        deliveryList: []
      }};
    case 'SERVICE_DELIVERY_LOADED':
      return {...state, ...{
        isFetching: action.isFetching,
        isEmpty: action.isEmpty,
        deliveryList: action.deliveryOptions
      }}
    default:
      return state;
  }
};

const getFilteredQuestionList = (state, questions) => {
  let dummy = state.list;
  questions.forEach(element => {
    dummy.push(element);
  });
  return dummy;
};
export const questionnaireList = (state = {
  isFetching: true,
  isError: false,
  list: [],
  fetchingQuestions: false,
  fetchingChildQuestions: false,
  fetchingCategories: true
}, action) => {
  switch (action.type) {
    case 'QUESTIONNAIRE_SUCCESS':
      return Object.assign([], state, {
        isFetching: false,
        isError: false,
        isEmpty: action.isEmpty,
        // list:getFilteredQuestionList(state,action.questionnaire),
        list: ((action.questionnaire && Array.isArray(action.questionnaire) && action.questionnaire.length) ? action.questionnaire : [])
      });
    case 'QUESTIONNAIRE_FETCHING':
      return Object.assign({}, state, {
        isFetching: true,
        isError: false
      });

    case 'QUESTIONNAIRE_FAILURE':
      return Object.assign([], state, {
        isFetching: false,
        isError: true
      });
    case 'FETCHING_QUESTIONS':
      return Object.assign({}, state, {
        fetchingQuestions: true
      });
    case 'FETCHING_QUESTIONS_SUCCESS':
      return Object.assign({}, state, {
        fetchingQuestions: false
      });
    case 'FETCHING_CHILD_QUESTIONS':
      return Object.assign({}, state, {
        fetchingChildQuestions: true
      });
    case 'FETCHING_CHILD_QUESTIONS_SUCCESS':
      return Object.assign({}, state, {
        fetchingChildQuestions: false
      });
    case 'FETCHING_CATEGORIES':
      return Object.assign({}, state, {
        fetchingCategories: true
      });
    case 'FETCHING_CATEGORIES_SUCCESS':
      return Object.assign({}, state, {
        fetchingCategories: false
      });
    case 'FETCHING_CATEGORY_COUNT':
      return Object.assign({}, state, {
        fetchingCategoriesCount: true
      });
    case 'FETCHING_CATEGORY_COUNT_SUCCESS':
      return Object.assign({}, state, {
        fetchingCategoriesCount: false
      });



    default:
      return state;
  }
};

export const haveMandatQuestionReducer = (state = {
  serviceHaveMandatoryQuestions: []
}, action) => {
  switch (action.type) {
    case 'HAVE_MANDATORY_QUESTION_FLAG_FOR_SERVICE':
      return {...state, ...{
        serviceHaveMandatoryQuestions: action.payload
      }}

    default:
      return state;
  }
};
export const selectedServiceDeliveries = (state = [], action) => {
  switch (action.type) {
    case 'ADD_UPDATE_DEFAULT_SERVICE_DELIVERY':
      return Object.assign([], getDeliveries(state, action));
    default:
      return state;
  }
};

const getDeliveries = (state, action) => {
  let delivery = state;
  if (!(_.some(delivery, ['offeringId', action.deliveryObj.offeringId]))) {
    delivery.push(action.deliveryObj);
  } else {
    if (_.some(delivery, ['offeringId', action.deliveryObj.offeringId])) {
      delivery = delivery.filter((exitingDelvArr) => {
        return (
          exitingDelvArr.offeringId != action.deliveryObj.offeringId
        );
      });
      delivery.push(action.deliveryObj);
    }
  }
  return delivery;
};
const resetQuestionState = (previousQuestionState) => {
  if (previousQuestionState) {
    let newQuestionState = JSON.parse(JSON.stringify(previousQuestionState));
    newQuestionState.forEach((service) => {
      service.categories.forEach((category) => {
        if (category.parentQuestions.length !== 0 || category.otherCategoryQuestions.length !== 0) {
          category.parentQuestions = [];
          category.otherCategoryQuestions = [];
        }
      });
    });
    return newQuestionState;
  }
};
export const serviceQuestionnaireCategories = (state = [], action) => {
 // console.log("serviceQuestionnaireCategories");
  //console.log(action.payload);
  switch (action.type) {
    case "SERVICE_QUESTIONNAIRE_CATEGORIES_LOAD_SUCCESS":
      return action.payload;
    default:
      return state;
  }
};
export const userDefinedServiceQuestionnaireCategories = (state = [], action) => {
 // console.log("serviceQuestionnaireCategories");
  //console.log(action.payload);
  switch (action.type) {
    case "USER_DEFINED_SERVICE_QUESTIONNAIRE_CATEGORIES_LOAD_SUCCESS":
      return action.payload;
    default:
      return state;
  }
};
// export const master_questions = (state = [], action) => {
//    switch (action.type) {
//      case "MASTER_QUESTIONS_PI":
//        return action.payload;
//      default:
//        return state;
//    }
//  };

export const categoryParentQuestions = (state = [], action) => {
  switch (action.type) {
    case "CATEGORY_PARENT_QUESTION_LOADED":
      return action.payload;
    case "RESET_CART_QUESTION_STATE":
      return [];
    default:
      return state;
  }
};

export const categoryCountList = (state = [], action) => {
  switch (action.type) {
    case "SERVICE_CATEGORY_COUNT_LOADED":
      return action.payload;
    case "RESET_CATEGORY_COUNT_STATE":
      return action.payload;
    default:
      return state;
  }
};

export const orderingInformationDetails = (state = {
  requester: "",
  recipient: "",
  shippingLocation: "",
  requesterId: "",
  requesterName: "",
  requesterLocationId: userInfo[6],
  requesterLocationName: userInfo[9],
  recipientCubicle: cubicalNumber,
  recipientContact: contactNumber


}, action) => {
  return state;
};


export const recipientDeliveryLocations = (state = [], action) => {
  switch (action.type) {
    case 'RECIPIENT_DELIVERY_LOCATIONS':
      return action.recipientLocations;
    default:
      return state;
  }
};
export const recipientDeliveryLocationsIsShared = (state = [], action) => {
  switch (action.type) {
    case 'RECIPIENT_DELIVERY_LOCATIONS_ISSHARED':
      console.log("RECIPIENT_DELIVERY_LOCATIONS_ISSHARED",action.recipientLocationsIsShared);
      return action.recipientLocationsIsShared;
    default:
      return state;
  }
};


export const recipientDeliveryCountries = (state = [], action) => {
  switch (action.type) {
    case 'RECIPIENT_DELIVERY_COUNTRIES':
      return action.recipientCountries;
    default:
      return state;
  }
};

export const bulkPurchaseQuestions = (state = [], action) => {
  if (action.type === 'BULK_PURCHASE_QUESTIONS') {
    return action.payload;
  } else {
    return state;
  }
};

export const urgencyValuesReducer = (state = [], action) => {
  if (action.type === 'URGENCY_FETCHED_SUCCESSFULLY') {
    return action.payload;
  } else {
    return state;
  }
};

export const cartOfferingAttachmentsReducer = (state = [], action) => {
  if (action.type === 'CART_OFFERING_ATTACHMENTS_FETCHED_SUCCESSFULLY') {
    return action.payload;
  } else {
    return state;
  }
};
