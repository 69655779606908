
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
const cookies = new Cookies();
import Cookies from 'universal-cookie';
let hometab_preference = cookies.get('gph');
if (hometab_preference) hometab_preference = hometab_preference.replace('s:', '');
if (hometab_preference) hometab_preference = hometab_preference.substring(0, hometab_preference.lastIndexOf('.'));
hometab_preference = hometab_preference.split("~");
let bestSelling = hometab_preference[10];
let newLaunch = hometab_preference[11];
let comingSoon = hometab_preference[12];
let free = hometab_preference[13];
let myFavourite = hometab_preference[14];
let catwise = hometab_preference[15];
let featured = hometab_preference[16];

const GeneralSection = ({
  businessFnLabels,
  setActiveTab,
  setActiveTabEventKey,
  businessFnApiError,
  setActiveTabId,
  udpateActiveMenuSubMenuItems,
  activeTabEventKey
}) => {
  const tr = useSelector((state) => state.spcmReducer.tr);
  return (
    <>
      <p>{tr['General']}</p>
      <Nav as="ul" className="flex-column">
      {featured == 'Y' || featured == 'y' ?
       <Nav.Item as="li">
       <Nav.Link
         active={activeTabEventKey === "0.0"}
         eventKey='0.0'
         onClick={() => {
           setActiveTab(tr['Featured']);
           setActiveTabEventKey("0.0");
           setActiveTabId(1);
           udpateActiveMenuSubMenuItems("General", "");
         }}
       >
       {tr['Featured']}
       </Nav.Link>
     </Nav.Item>
     : ''}

      {bestSelling == 'Y' || bestSelling == 'y' ?
       <Nav.Item as="li">
       <Nav.Link
         active={activeTabEventKey === "0.1"}
         eventKey='0.1'
         onClick={() => {
           setActiveTab(tr["Best Selling"]);
           setActiveTabEventKey("0.1");
           setActiveTabId(2);
           udpateActiveMenuSubMenuItems("General", "");
         }}
       >
        {tr['Best Selling']}
       </Nav.Link>
     </Nav.Item>
     : ''}

{myFavourite == 'Y' || myFavourite == 'y' ?
       <Nav.Item as="li">
       <Nav.Link
         eventKey='0.2'
         onClick={() => {
           setActiveTab(tr['My Favourites']);
           setActiveTabEventKey("0.2");
           setActiveTabId(3);
           udpateActiveMenuSubMenuItems("General", "");
         }}
       >
       {tr['My Favourites']}
       </Nav.Link>
     </Nav.Item>
     : ''}

      {newLaunch == 'Y' || newLaunch == 'y' ?
       <Nav.Item as="li">
       <Nav.Link
         eventKey='0.3'
         onClick={() => {
           setActiveTab(tr['New Launches']);
           setActiveTabEventKey("0.3");
           setActiveTabId(4);
           udpateActiveMenuSubMenuItems("General", "");
         }}
       >
       {tr['New Launches']}
       </Nav.Link>
     </Nav.Item>
     : ''}
      {comingSoon == 'Y' || comingSoon == 'y' ?
       <Nav.Item as="li">
       <Nav.Link
         eventKey='0.4'
         onClick={() => {
           setActiveTab(tr['Coming Soon']);
           setActiveTabEventKey("0.4");
           setActiveTabId(5);
           udpateActiveMenuSubMenuItems("General", "");
         }}
       >
       {tr['Coming Soon']}
       </Nav.Link>
     </Nav.Item>
     : ''}
      {free == 'Y' || free == 'y' ?
       <Nav.Item as="li">
       <Nav.Link
         eventKey='0.5'
         onClick={() => {
           setActiveTab(tr['Free']);
           setActiveTabEventKey("0.5");
           setActiveTabId(6);
           udpateActiveMenuSubMenuItems("General", "");
         }}
       >
       {tr['Free']}
       </Nav.Link>
     </Nav.Item>
     : ''}
      
     
        {/* {businessFnApiError ? (
          <p>Something Went Wrong</p>
        ) : (
          businessFnLabels &&
          businessFnLabels.General &&
          businessFnLabels.General.map((label, index) => (
            <Nav.Item as="li">
              <Nav.Link
                eventKey={`0.${index}`}
                onClick={() => {
                  setActiveTab(label.label_name);
                  setActiveTabEventKey(`0.${index}`);
                  setActiveTabId(label.label_id);
                  udpateActiveMenuSubMenuItems("General", "");
                }}
              >
                {label.label_name}
              </Nav.Link>
            </Nav.Item>
          ))
        )} */}

      </Nav>
    </>
  );
};

export default GeneralSection;
