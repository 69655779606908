
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export default function (state = initialState.cmdbChangeRelatedDataDetails, action){

	switch (action.type){
		case 'LOAD_CMDBCHANGERELATEDDATADETAILS_SUCCESS':
			return action.changeRelatedDataDetails.data;
				// alert("editReducer:"+action.breakFixEditDetails.data.serviceId);
		case 'LOAD_RELATED_ASSETS_DATA_DETAILS':
			return action.assetrelatedDataDetails.data;
		case 'LOAD_CMDBCHANGERELATEDDATADETAILS_FAILURE':
				return action.failureMessage;
		default:
				return state;
		}

}
