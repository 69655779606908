
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { reduxForm, SubmissionError } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { deleteSystemConfigList, editSystemConfigList, saveSystemConfigList } from "../../../actions/systemConfig/systemConfigAction.js";
import moment from "moment";
import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import Cookies from "universal-cookie";
import axios from "axios";
import { MyContext } from '_MyContext';
import { LuFileText } from "react-icons/lu";
const cookies = new Cookies();

let cookieData = cookies.get("gph");
if (cookieData) cookieData = cookieData.replace("s:", "");
if (cookieData) cookieData = cookieData.substring(0, cookieData.lastIndexOf("."));
cookieData = cookieData.split("~");

let loggedInUserId = cookieData[43];
let loggedInUserName = cookieData[42];

const EditMailboxConfigHeader = (props) => {

  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const saveObjRef = useRef(null);

  useEffect(() => {
    context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
    context.newTabPrevent();
	}, []);

  const dispatch = useDispatch();
  const submit = (values) => {
    let isValid = true;
    if (typeof (values.COMPANY_NAME) == 'undefined' || values.COMPANY_NAME == '' || values.COMPANY_NAME.trim() == '') {
      props.setCompanyErrorColor();
      isValid = false;
    } 
    if (typeof (values.OFFERING_NAME) == 'undefined' || values.OFFERING_NAME == '' || values.OFFERING_NAME.trim() == '') {
      props.setOfferingErrorColor();
      isValid = false;
    } 
    if ((values.ACTIONS ==='Create with Guest Account') && (typeof (values.GUEST_USER_NAME) == 'undefined' || values.GUEST_USER_NAME == '' || values.GUEST_USER_NAME.trim() == '')) {
      props.setRequestorErrorColor();
      isValid = false;
    } 
    if ( !values.MAILBOX_ID || values.MAILBOX_ID == '' || values.MAILBOX_ID.trim().length===0) {
      props.setMailboxIdErrorColor("error");
      isValid = false;
    } else {
      props.setMailboxIdErrorColor("");
    }
    if (!values.MAILBOX_PROVIDER ||values.MAILBOX_PROVIDER == '') {
      props.setMailboxProviderErrorColor("error");
      isValid = false;
    } else {
      props.setMailboxProviderErrorColor("");
    }
    if (!values.WHITELISTED_DOMAINS ||values.WHITELISTED_DOMAINS == '' || values.WHITELISTED_DOMAINS.trim().length===0) {
      props.setWhitelistedDomainErrorColor("error");
      isValid = false;
    } else {
      props.setWhitelistedDomainErrorColor("");
    }
    if (!values.impact ||values.impact == '') {
      props.setImpactErrorColor("error");
      isValid = false;
    } else {
      props.setImpactErrorColor("");
    }
    if (!values.urgency ||values.urgency == '') {
      props.setUrgencyErrorColor("error");
      isValid = false;
    } else {
      props.setUrgencyErrorColor("");
    }
    if (!values.ACTIONS || values.ACTIONS == '') {
      props.setActionsErrorColor("error");
      isValid = false;
    } else {
      props.setActionsErrorColor("");
    }
    if (!values.STATUS || values.STATUS == '') {
      props.setStatusErrorColor("error");
      isValid = false;
    } else {
      props.setStatusErrorColor("");
    }
      
    if (isValid == true) {
      let guestUserValue = null;
      let guestUserId = null;
      if (values.ACTIONS == 'Create with Guest Account') {
        guestUserValue = values.GUEST_USER_NAME
        guestUserId = parseInt(values.GUEST_USER_ID, 10)
      }
      let alwaysException = null;
      let neverException = null;
      if (typeof (values.NO_EXCEPTIONS) !== 'undefined')
        alwaysException = values.NO_EXCEPTIONS
      if (typeof (values.EXCEPTION_MAIL_IDS) !== 'undefined')
        neverException = values.EXCEPTION_MAIL_IDS
      
      axios.get('/api/getCompanyPriorityMatrix?companyId='+ values.COMPANY_ID +'&module=Breakfix&impact=' + values.impact + '&urgency=' + values.urgency).
      then((respondedPriority)=>{
        if(typeof respondedPriority.data[0].priority!=='undefined'){
            let priority = respondedPriority.data[0].priority;
            let postjson;
            postjson = {
              mailbox_provider: values.MAILBOX_PROVIDER,
              mailbox_id: values.MAILBOX_ID,
              company_id: parseInt(values.COMPANY_ID, 10),
              company_name: values.COMPANY_NAME,
              offering_id: parseInt(values.OFFERING_ID, 10),
              offering_name: values.OFFERING_NAME,
              impact: parseInt(values.impact, 10),
              urgency: parseInt(values.urgency, 10),
              business_function: "Customer Request",
              whitelisted_domains: values.WHITELISTED_DOMAINS,
              exception_emailids: neverException,
              status: values.STATUS === "Active" ? 1 : 0,
              updated_by: loggedInUserId,
              updated_by_name: loggedInUserName,
              updated_at: moment().format('YYYY-MM-DD HH:mm:ss'),
              actions: values.ACTIONS,
              no_exceptions: alwaysException,
              guest_user_name: guestUserValue,
              guest_user_id: guestUserId,
              urgency_name: values.urgency_name,
              impact_name: values.impact_name,
              priority_id: priority
            };
            dispatch(editSystemConfigList(postjson, props.id));
        } else {
          Swal.fire('Something went wrong!');
        }
      });
    }
  };
  
  const onDeleteClick = () => {
    const id = window.location.href.split("/").pop();
    Swal.fire({
      title: props.translator["Are you sure you want to delete?"],
      width: 550,
      padding: '15px',
      showDenyButton: true,
      confirmButtonText: props.translator['Yes'],
      denyButtonText: props.translator['No'],
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteSystemConfigList(id))
      } else if (result.isDenied) {
        
      }
    })
  };

  let { handleSubmit, submitting } = props;

  return (
    <Row className="margin-b-15">
      <Col lg={4} md={6} sm={4} xs={4}>
        <h1 bsClass="" className="sPageHeading1">
          {props.translator["Configuration"]}
        </h1>
      </Col>
      <Col lg={8} md={6} sm={8} xs={8}>
        <div className="paHedFilter">
          { !props.isRightEditPanel &&
            <Link
            title={props.translator["Add Credentials"]}
            as="li" to="javascript:void(0)"
            onClick={() => {
              props.rightEditPanel(true);
            }}
            className="myBt fillBtn"
            >
            <LuFileText/>
          </Link>}
          <Button role="button" bsPrefix=' '  className="myBt rclose" title={props.translator["Delete"]} onClick={onDeleteClick} >
            <i aria-hidden="true" className="fa fa-trash-o"></i>
          </Button>
          <Link bsPrefix=' ' to="javascript:void(0)" className="myBt plus ctrlKeyPrevent" onClick={handleSubmit(submit)} disabled={submitting} title={props.translator["Save"]} ref={saveObjRef}>
          {submitting ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/>}
          </Link>
          <Link
            bsPrefix=' '
            className="myBt cancel fillBtn ctrlKeyPrevent"
            title={props.translator["Close"]}
            to="/systemConfig"
            ref={closeButton}
            >
            <IoClose/>
          </Link>
        </div>
      </Col>
    </Row>
  );
};

export default reduxForm({
  form: "xsmSystemConfigurationEditForm",
  destroyOnUnmount: false,
})(EditMailboxConfigHeader);
