
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Row, Col, Form, Alert, ButtonToolbar, Button } from "react-bootstrap";
import {
  _textArea,
  _dropDown,
  _inputField,
  _number,
  _radio,
  _multiSelect,
  _ckeditor,
  TypeaheadandDropdown,
  _checkbox
} from "../../common/formFields";
import { Field, reduxForm, SubmissionError, change } from "redux-form";
import { connect } from "react-redux";
import axios from "axios";
import swal from 'sweetalert';
import {
  spcmLoader,
  setLeftEditId,
  getServiceDetailCounts,
  getServiceOrderChoices,
  getServiceMyQuestionLists,
  getConsumptionItems,
  getQuestionsCategory,
  getQuestionsActiveCategory,
  getDataSourceTypes,
  getReadOnlyResponseKeyTypesAndKeys,
  resetReducer,
} from "../../../actions/spcmActions";
import {ImSpinner6} from "react-icons/im";
import { itemQuantityField } from "../../../components/common/formFields";
import { IoIosInformationCircleOutline } from "react-icons/io";

const validate = (values) => {
  let errors = {};
  console.log("ERRORS", errors.min_value);
  console.log("validate method running");
  console.log(values);

  if (!values.category_name) {
    errors.category_name = "Please select group";
  }

  if (!values.parent_response_id) {
    errors.parent_response_id = "Select Parent Value";
  }
  if (!values.question_parent_id) {
    errors.question_parent_id = "Select Parent Question";
  }
  if (!values.question_entityid) {
    errors.question_entityid = "Select Component Name";
  }

  if (!values.question_sequence) {
    errors.question_sequence = "Select Question Sequence";
  }
  if (!values.question_text || values.question_text.trim().length === 0) {
    errors.question_text = "Enter Question Text";
  }
  if (!values.response_type) {
    errors.response_type = "Select Response Type";
  }

  if (!values.call_api_after) {
    errors.call_api_after = "Enter min. character limit";
  }

  if (!values.response_mode) {
    errors.response_mode = "Select Response Mode";
  }
  if (!values.response_value || values.response_value.trim().length === 0) {
    errors.response_value = "Enter Response Value";
  }
  if (!values.placeholder || values.placeholder.trim().length === 0) {
    errors.placeholder = "Enter Response Type";
  }
  if (!values.datasource_type) {
    errors.datasource_type = "Enter Response Type";
  }

  // if (!values.ro_responses || values.ro_responses.length == 0) {
  //   errors.ro_responses = "Select Source Options";
  // }

  if (!values.max_limit) {
    errors.max_limit = "  ";
  }

  if (!values.table_headers || values.table_headers.trim().length === 0) {
    errors.table_headers = "Enter Table Headers";
  } else if (values.max_limit) {
    let reg = /^\d+$/;
    let result = reg.test(values.max_limit);
    console.log(result);
    if (result == false) {
      errors.max_limit = "Enter numbers only";
    }
    if (values.max_limit == 0) {
      errors.max_limit = "Enter valid number only";
    }
  }

  if (parseInt(values.min_value, 10) >= parseInt(values.max_value, 10)) {
    errors.min_value = "Min Value must be less than Max Value";
  }

  if (parseInt(values.min_value, 10) <= 0) {
    errors.min_value = "Please enter value greater than 1";
  }

  if (parseInt(values.max_value, 10) <= 0) {
    errors.max_value = "Please enter value greater than 1";
  }
  
  if (parseInt(values.min_value, 10) >= 99999999) {
    errors.min_value = "Please enter value less than 99999999";
  }
  if (parseInt(values.max_value, 10) <= 1) {
    errors.max_value = "Please enter value greater than 1";
  }

  if (!values.CATEGORY_NAME || values.CATEGORY_NAME.trim().length === 0) {
    errors.CATEGORY_NAME = "Please enter group name";
  }

  if (!values.CATEGORY_SEQUENCE) {
    errors.CATEGORY_SEQUENCE = "Please enter group sequence";
  }

  if (
    values.call_api_after_typeahead < 4 ||
    values.call_api_after_typeahead > 15
  ) {
    errors.call_api_after_typeahead = "Enter min. character limit";
  }

  if (!values.days_before && values.days_before !== 0) {
    errors.days_before = "Enter min. character limit";
  }

  if (!values.days_after && values.days_after !== 0) {
    errors.days_after = "Enter min. character limit";
  }

  if (!values.data_source_identifier && values.data_source_identifier !== 0) {
    errors.data_source_identifier = "Enter data source identifier";
  }
  console.log("errors");
  console.log(errors);

  return errors;
};

class ComponentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showResponseMode: false,
      DataSourceTypeOpt: "Unset",
      DataSourceOpt: false,
      showMaxLimit: false,
      AssociatedToDropdown: "",
      showAssociatedSelected: false,
      showHideAssociatedSelected: true,
      // associatedSelectedValues: [],
      associatedSelectedValues: 0,
      questionTypeSelected: "No",
      categoryNameInitialStatus: "Active",
      isMandatory: "No",
      parentQuesCatName: "",
      parentQuesCatId: "",
      showStatic: "",
      questionSelectedType: "",
      parentOptional: false,
      selectedComponentsId: "",
      showDisplayTextField: false,
      showTableHeaders: false,
      isTableTypeQuestion: false,
      dynamicDropSelected: false,
      dynamicTextboxSelected: false,
      showDataSourceOption: false,
      showMinMaxLimit: false,
      minItemQuantity: "1",
      maxItemQuantity: "99999999",
      calendarSelected: false,
      calendar_type_id: "10",
      calendar_type_value: "None",
      datasource_type_name: "",
      parent_response_text: "",
      ItamLocationSelected: false,
      searchInvokeField: false,
      selectedValueList:[],
      errorValue:'',
      days_before: '',
      days_after: '',
      include_in_summary: "false",
      show_include_in_summary: false,
      show_data_source_identifier: false,
      showMultiSelectAssociated: false,
      errorMultiValue: '',
      selectedMultiValueList: [],
      multiSelectValuesUpdated: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onResponseModeChange = this.onResponseModeChange.bind(this);
    this.onDataSourceOptChng = this.onDataSourceOptChng.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.onAssociatedToChange = this.onAssociatedToChange.bind(this);
    this.onAssociatedSelected = this.onAssociatedSelected.bind(this);
    this.onCategoryChange = this.onCategoryChange.bind(this);
    this.onComponentChange = this.onComponentChange.bind(this);
    this.onIsMandatoryChange = this.onIsMandatoryChange.bind(this);
    this.countCat = this.countCat.bind(this);
    this.validateTableHeaders = this.validateTableHeaders.bind(this);
    this.onRestrictDateChange = this.onRestrictDateChange.bind(this);
    this.onValueSelection = this.onValueSelection.bind(this);
    this.getValuesDropdownOptions = this.getValuesDropdownOptions.bind(this);
    this.onDaysAfterCurrentDateChange = this.onDaysAfterCurrentDateChange.bind(this);
    this.onDaysBeforeCurrentDateChange = this.onDaysBeforeCurrentDateChange.bind(this);
    this.setIncudeInSummary = this.setIncudeInSummary.bind(this);
    this.onMultiValueSelection = this.onMultiValueSelection.bind(this);
  }

  setIncudeInSummary(value) {
    this.setState({ include_in_summary: this.state.include_in_summary === "false" ? "true" : "false" });
  }

  onDaysBeforeCurrentDateChange(e) {
    this.setState({ days_before: e.target.value });
  }

  onDaysAfterCurrentDateChange(e) {
    this.setState({ days_after: e.target.value });
  }

  onChange(e, changeFunction) {
    const { change } = this.props;
    change("response_mode", "");
    change("response_value", "");
    if (
      e.target.value == "Dropdown List" ||
      e.target.value == "Multiselect List" ||
      e.target.value == "Checkbox" ||
      e.target.value == "Radio button"
    )
      this.setState({
        showMinMaxLimit: false,
        showResponseMode: true,
        showMaxLimit: false,
        showStatic: true,
        DataSourceTypeOpt: "Unset",
        DataSourceOpt: false,
        showDisplayTextField: false,
        isTableTypeQuestion: false,
        showTableHeaders: false,
        dynamicDropSelected: false,
        dynamicTextboxSelected: false,
        calendarSelected: false,
        searchInvokeField: false,
        show_include_in_summary: true,
        show_data_source_identifier: false
      });
    else if (e.target.value == "Typeahead") {
      this.props.getDataSourceTypes("Typeahead");
      change("datasource_type", "");
      change("ro_responses", "");
      change("call_api_after_typeahead", "4");
      this.setState({
        showMinMaxLimit: false,
        showResponseMode: true,
        showMaxLimit: false,
        showStatic: false,
        DataSourceTypeOpt: "Dynamic",
        showDisplayTextField: false,
        isTableTypeQuestion: false,
        showTableHeaders: false,
        dynamicDropSelected: false,
        dynamicTextboxSelected: false,
        calendarSelected: false,
        searchInvokeField: true,
        show_include_in_summary: true,
        show_data_source_identifier: false
      });
    } else if (e.target.value == "multiSelectTypeahead") {
      this.props.getDataSourceTypes("Typeahead");
      change("datasource_type", "");
      change("ro_responses", "");
      change("call_api_after_typeahead", "4");
      this.setState({
        showMinMaxLimit: false,
        showResponseMode: true,
        showMaxLimit: false,
        showStatic: false,
        DataSourceTypeOpt: "Dynamic",
        showDisplayTextField: false,
        isTableTypeQuestion: false,
        showTableHeaders: false,
        dynamicDropSelected: false,
        dynamicTextboxSelected: false,
        calendarSelected: false,
        searchInvokeField: true,
        show_include_in_summary: true,
        show_data_source_identifier: true
      });
    } else if (e.target.value == "Dynamic Dropdown") {
      this.props.getDataSourceTypes("Dynamic");
      change("datasource_type", "");
      change("ro_responses", "");
      this.setState({
        showMinMaxLimit: false,
        showResponseMode: true,
        showMaxLimit: false,
        showStatic: false,
        DataSourceTypeOpt: "Dynamic",
        showDisplayTextField: false,
        isTableTypeQuestion: false,
        showTableHeaders: false,
        dynamicDropSelected: true,
        dynamicTextboxSelected: false,
        calendarSelected: false,
        searchInvokeField: false,
        show_include_in_summary: true,
        show_data_source_identifier: false
      });
    } else if (e.target.value == "Lookup Textbox") {
      this.setState({
        showMinMaxLimit: false,
        showResponseMode: false,
        showMaxLimit: false,
        DataSourceTypeOpt: "Unset",
        DataSourceOpt: false,
        showDisplayTextField: false,
        showTableHeaders: false,
        isTableTypeQuestion: false,
        dynamicDropSelected: false,
        dynamicTextboxSelected: true,
        calendarSelected: false,
        searchInvokeField: false,
        show_include_in_summary: false,
        show_data_source_identifier: false
      });
    } else if (
      e.target.value == "Textbox" ||
      e.target.value == "Multiline Textbox"
    )
      this.setState({
        showMinMaxLimit: false,
        showResponseMode: false,
        showMaxLimit: true,
        DataSourceTypeOpt: "Unset",
        DataSourceOpt: false,
        showDisplayTextField: false,
        isTableTypeQuestion: false,
        showTableHeaders: false,
        dynamicDropSelected: false,
        dynamicTextboxSelected: false,
        calendarSelected: false,
        searchInvokeField: false,
        show_include_in_summary: true,
        show_data_source_identifier: false
      });
    else if (e.target.value == "Display Text")
      this.setState({
        showMinMaxLimit: false,
        showResponseMode: false,
        showMaxLimit: false,
        DataSourceTypeOpt: "Unset",
        DataSourceOpt: false,
        showDisplayTextField: true,
        showTableHeaders: false,
        isTableTypeQuestion: false,
        dynamicDropSelected: false,
        dynamicTextboxSelected: false,
        calendarSelected: false,
        searchInvokeField: false,
        show_include_in_summary: false,
        show_data_source_identifier: false
      });
    else if (e.target.value == "Table") {
      if (this.state.AssociatedToDropdown == 1) {
        swal({
          text: this.props.tr["Table type question cannot be a dependent."],
          buttons: this.props.tr["OK"],
        }).then((willDelete) => {
          this.setState({
            showMinMaxLimit: false,
            showResponseMode: false,
            showMaxLimit: false,
            DataSourceTypeOpt: "Unset",
            DataSourceOpt: false,
            showDisplayTextField: false,
            AssociatedToDropdown: 0,
            showTableHeaders: true,
            isTableTypeQuestion: true,
            dynamicDropSelected: false,
            dynamicTextboxSelected: false,
            calendarSelected: false,
            searchInvokeField: false,
            show_include_in_summary: true,
            show_data_source_identifier: false
          });
        });
      } else {
        this.setState({
          showMinMaxLimit: false,
          showResponseMode: false,
          showMaxLimit: false,
          DataSourceTypeOpt: "Unset",
          DataSourceOpt: false,
          showDisplayTextField: false,
          showTableHeaders: true,
          isTableTypeQuestion: true,
          dynamicDropSelected: false,
          dynamicTextboxSelected: false,
          calendarSelected: false,
          searchInvokeField: false,
          show_include_in_summary: true,
          show_data_source_identifier: false
        });
      }
    } else if (e.target.value == "Numeric Spinner") {
      this.setState({
        showMinMaxLimit: true,
        showResponseMode: false,
        showMaxLimit: false,
        DataSourceTypeOpt: "Unset",
        DataSourceOpt: false,
        showDisplayTextField: false,
        showTableHeaders: false,
        isTableTypeQuestion: false,
        dynamicDropSelected: false,
        dynamicTextboxSelected: false,
        calendarSelected: false,
        searchInvokeField: false,
        show_include_in_summary: true,
        show_data_source_identifier: false
      });
    } else if (e.target.value == "Calendar" || e.target.value == "Datetime") {
      this.setState({
        showMinMaxLimit: false,
        showResponseMode: false,
        showMaxLimit: false,
        DataSourceTypeOpt: "Unset",
        DataSourceOpt: false,
        showDisplayTextField: false,
        showTableHeaders: false,
        isTableTypeQuestion: false,
        dynamicDropSelected: false,
        dynamicTextboxSelected: false,
        calendarSelected: true,
        searchInvokeField: false,
        show_include_in_summary: true,
        show_data_source_identifier: false
      });
    } else
      this.setState({
        showMinMaxLimit: false,
        showResponseMode: false,
        showMaxLimit: false,
        DataSourceTypeOpt: "Unset",
        DataSourceOpt: false,
        showDisplayTextField: false,
        showTableHeaders: false,
        isTableTypeQuestion: false,
        dynamicDropSelected: false,
        dynamicTextboxSelected: false,
        calendarSelected: false,
        searchInvokeField: false,
        show_include_in_summary: false,
        show_data_source_identifier: false
      });
  }

  onResponseModeChange(e) {
    if (e.target.value == "Static")
      this.setState({ DataSourceTypeOpt: "Static" });
    else if (e.target.value == "Dynamic")
      this.setState({ DataSourceTypeOpt: "Dynamic" });
    else this.setState({ DataSourceTypeOpt: "Unset" });
  }

  onRestrictDateChange(e) {
    this.setState({
      calendar_type_id: e.target.value,
      calendar_type_value: e.target[e.target.selectedIndex].text,
    });
  }

  onDataSourceOptChng(e) {
    console.log(e);
    console.log(e.target.value);
    console.log(e.target[e.target.selectedIndex].text);

    this.props.dispatch(change('serviceOrderChoicesForm', 'data_source_identifier', ""));

    if (
      e.target[e.target.selectedIndex].text === "Project Code" ||
      e.target[e.target.selectedIndex].text === "Laptop Serial Number" ||
      e.target[e.target.selectedIndex].text === "Desktop Serial Number"
    ) {
      this.setState({ showDataSourceOption: true });
    } else {
      this.setState({ showDataSourceOption: false });
    }

    if (e.target[e.target.selectedIndex].text === "ITAM Location") {
      this.setState({ ItamLocationSelected: true });
    } else {
      this.setState({ ItamLocationSelected: false });
    }

    if (e.target.value) {
      this.setState({
        DataSourceOpt: true,
        datasource_type_name: e.target[e.target.selectedIndex].text,
      });
    } else {
      this.setState({
        DataSourceOpt: false,
        datasource_type_name: null,
      });
    }
    this.props.getReadOnlyResponseKeyTypesAndKeys(
      e.target[e.target.selectedIndex].text
    );
    this.setState({
      selectedValueList:[]
    })
  }

  onAssociatedToChange(e) {
    const { change } = this.props;
    if (e.target.value == "No") {
      this.setState({ AssociatedToDropdown: 0 });
      //	change('know_parent_id',this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_NAME);
    } else if (e.target.value == "Yes") {
      this.setState({ AssociatedToDropdown: 1 });
    }
  }

  onIsMandatoryChange(e) {
    if (e.target.value == "No") {
      this.setState({ isMandatory: "No" });
    } else if (e.target.value == "Yes") {
      this.setState({ isMandatory: "Yes" });
    }
  }

  onAssociatedSelected(e) {
    console.log(this.props);

    const { change } = this.props;
    this.setState({
      showAssociatedSelected: true,
      associatedSelectedValues: e.target.value,
    });

    console.log(this.props.spcmReducer.myQuestionsForService);
    console.log(e);

    let questionList = this.props.spcmReducer.myQuestionsForService;
    console.log(questionList);

    const category_list = this.props.spcmReducer.quesActiveCategories;

    let outerThis = this;

    questionList.forEach(function (questionList) {
      let questionId = questionList.QUESTION_ID;
      if (questionId == e.target.value) {
        if (
          questionList.RESPONSE_TYPE == "Typeahead" ||
          questionList.RESPONSE_TYPE == "multiSelectTypeahead" ||
          questionList.RESPONSE_TYPE == "Lookup Textbox"
        ) {
          outerThis.setState({ 
            showHideAssociatedSelected: false, 
            showMultiSelectAssociated: false 
          });
        } else if (questionList.RESPONSE_TYPE == "Checkbox") {
          outerThis.setState({ showMultiSelectAssociated: true })
        } else {
          outerThis.setState({ 
            showHideAssociatedSelected: true,
            showMultiSelectAssociated: false 
          });
        }
        outerThis.setState({
          parentQuesCatId: questionList.CATEGORY_ID,
          parentQuesCatName: questionList.CATEGORY_NAME,
          selectedComponentsId: questionList.QUESTION_ENTITYID,
        });

        outerThis.props.dispatch(
          change("category_name", questionList.CATEGORY_ID)
        );
        outerThis.props.dispatch(
          change("question_entityid", questionList.QUESTION_ENTITYID)
        );

        // category_list.forEach(function (category_list) {
        //     let categoryId = category_list.CATEGORY_ID;
        //     console.log('asdfasdf');
        //     console.log(categoryId);
        //     console.log(questionList.CATEGORY_ID);
        //     if(categoryId == questionList.CATEGORY_ID){

        //         outerThis.setState({
        //                 parentQuesCatId:questionList.CATEGORY_ID,
        //                 parentQuesCatName:category_list.CATEGORY_NAME
        //             });
        //     }
        // });
      }
    });

    if (e.target.value) {
      let indexSelected = e.target.selectedIndex;
      console.log(indexSelected);
      this.setState(
        {
          questionSelectedType:
            questionList[indexSelected - 1].QUESTIONDEF_TYPE,
        },
        function () {
          if (this.state.questionSelectedType == "optional") {
            this.setState({ isMandatory: "No", parentOptional: true });
          } else {
            this.setState({ parentOptional: false });
          }
        }
      );
    }

    this.props.dispatch(change('serviceOrderChoicesForm', 'question_parent_value', ''));
    this.props.dispatch(change('serviceOrderChoicesForm', 'parent_response_id', ''));
    this.setState({ 
      selectedValueList: [],
      selectedMultiValueList: [],
      errorValue: '',
      errorMultiValue: '',
    })
  }

  onCategoryChange(e) {
    console.log("selected category");
    console.log(e);
    // this.props.getServiceMyQuestionLists(e);
    this.setState({ parentQuesCatId: e });
  }

  onComponentChange(id) {
    this.setState({ selectedComponentsId: id });
  }

  countCat(e) {
    console.log("char count");
    console.log(e.target.value.length);
    if (e.target.value.length > 200) {
      e.preventDefault();
    }
  }

  componentWillMount() {
    //  console.log(this.props.initialValues,'this.props.initialValues.')

    let value = this.props.initialValues.response_type;
    const parentID = this.props.initialValues.question_parent_id || 0;
    const AssociatedToDropdown = parentID == 0 ? 0 : 1;

    if (this.props.initialValues.questiondef_type == "optional") {
      this.setState({ isMandatory: "No" });
    } else if (this.props.initialValues.questiondef_type == "mandatory") {
      this.setState({ isMandatory: "Yes" });
    }

    if (AssociatedToDropdown == 0) {
      this.setState({ AssociatedToDropdown });
    } else if (AssociatedToDropdown == 1) {
      this.setState({
        AssociatedToDropdown,
        showAssociatedSelected: true,
        associatedSelectedValues: parentID,
      });
    }

    if (
      value == "Dropdown List" ||
      value == "Multiselect List" ||
      value == "Checkbox" ||
      value == "Radio button"
    )
      this.setState({
        showResponseMode: true,
        showMaxLimit: false,
        showStatic: true,
        DataSourceTypeOpt: "Static",
        showDisplayTextField: false,
        show_include_in_summary: true,
        show_data_source_identifier: false
      });
    else if (value == "Typeahead") {
      this.props.getDataSourceTypes("Typeahead");
      this.setState({
        showResponseMode: true,
        showMaxLimit: false,
        showStatic: false,
        DataSourceTypeOpt: "Dynamic",
        DataSourceOpt: true,
        showDisplayTextField: false,
        searchInvokeField: true,
        show_include_in_summary: true,
        show_data_source_identifier: false
      });
    } else if (value == "multiSelectTypeahead") {
      this.props.getDataSourceTypes("Typeahead");
      this.setState({
        showResponseMode: true,
        showMaxLimit: false,
        showStatic: false,
        DataSourceTypeOpt: "Dynamic",
        DataSourceOpt: true,
        showDisplayTextField: false,
        searchInvokeField: true,
        show_include_in_summary: true,
        show_data_source_identifier: true
      });
    } else if (value == "Dynamic Dropdown") {
      this.props.getDataSourceTypes("Dynamic");
      this.setState({
        showResponseMode: true,
        showMaxLimit: false,
        showStatic: false,
        DataSourceTypeOpt: "Dynamic",
        DataSourceOpt: true,
        showDisplayTextField: false,
        dynamicDropSelected: true,
        show_include_in_summary: true,
        show_data_source_identifier: false
      });
    } else if (value == "Lookup Textbox") {
      this.setState({ dynamicTextboxSelected: true, show_include_in_summary: false, show_data_source_identifier: false });
    } else if (value == "Textbox" || value == "Multiline Textbox")
      this.setState({
        showResponseMode: false,
        showMaxLimit: true,
        showDisplayTextField: false,
        show_include_in_summary: true,
        show_data_source_identifier: false
      });
    else if (value == "Display Text")
      this.setState({ showDisplayTextField: true, show_data_source_identifier: false });
    else if (value == "Table")
      this.setState({
        showTableHeaders: true,
        AssociatedToDropdown: 0,
        isTableTypeQuestion: true,
        show_include_in_summary: true,
        show_data_source_identifier: false
      });
    else if (value == "Numeric Spinner") {
      this.setState({ 
        showMinMaxLimit: true, 
        show_include_in_summary: true,
        show_data_source_identifier: false
       });
    } else if (value == "Calendar" || value == "Datetime") {
      this.setState({ 
        calendarSelected: true, 
        show_include_in_summary: true,
        show_data_source_identifier: false });
    } else
      this.setState({
        showResponseMode: false,
        showMaxLimit: false,
        showDisplayTextField: false,
        show_include_in_summary: false,
        show_data_source_identifier: false
      });

    this.props.getQuestionsActiveCategory(
      this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID
    );

    this.props.getServiceMyQuestionLists(
      this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID
    );

    if(this.props.initialValues.datasource_type_name) {
      this.props.getReadOnlyResponseKeyTypesAndKeys(this.props.initialValues.datasource_type_name);
    }

    console.log(this.props.spcmReducer.editLeftId);

    if (this.props.spcmReducer.editLeftId > 0) {
      console.log("INITIAL", this.props.initialValues);
      this.setState({
        parentQuesCatName: this.props.initialValues.category_name_view,
        parentQuesCatId: this.props.initialValues.category_id,
        selectedComponentsId: this.props.initialValues.question_entityid,
        minItemQuantity: this.props.initialValues.min_value,
        maxItemQuantity: this.props.initialValues.max_value,
        calendar_type_id: this.props.initialValues.calendar_type_id,
        calendar_type_value: this.props.initialValues.calendar_type_value,
        datasource_type_name: this.props.initialValues.datasource_type_name,
        days_after: this.props.initialValues.days_after,
        days_before: this.props.initialValues.days_before,
        include_in_summary: this.props.initialValues.include_in_summary ? this.props.initialValues.include_in_summary : "false",
        data_source_identifier: this.props.initialValues.data_source_identifier,
      });

      this.props.dispatch(
        change("category_name", this.props.initialValues.category_id)
      );
      this.props.dispatch(
        change("question_entityid", this.props.initialValues.question_entityid)
      );
      // let outerThis = this;
      // const category_list = this.props.spcmReducer.quesActiveCategories;
      // console.log(category_list);
      // category_list.forEach(function (category_list) {
      //     let categoryId = category_list.CATEGORY_ID;
      //     console.log('asdasdf');
      //     console.log(outerThis.props.initialValues.category_id);
      //     if(outerThis.props.initialValues.category_id && (categoryId == outerThis.props.initialValues.category_id)){

      //         outerThis.setState({
      //                 parentQuesCatName:category_list.CATEGORY_NAME
      //             });

      //             console.log(outerThis.state.parentQuesCatName);
      //     }
      // });
    } else {
      this.props.dispatch(
        change("serviceOrderChoicesForm", "question_approval", "N")
      );
    }
  }

  componentWillReceiveProps(newProps) {
    let datasource_type = this.props.initialValues.datasource_type;

    if (this.props.spcmReducer.editLeftId > 0) {
      console.log("this props");
      console.log(this.props.spcmReducer.dataSourceTypes);
      console.log(newProps.spcmReducer.dataSourceTypes);
      console.log(this.props.spcmReducer.myQuestionsForService);
      console.log(newProps.spcmReducer.myQuestionsForService);

      if (
        newProps.spcmReducer.myQuestionsForService !=
        this.props.spcmReducer.myQuestionsForService
      ) {
        let questionList = newProps.spcmReducer.myQuestionsForService;
        let outerThis = this;

        questionList.forEach(function (questionList) {
          let parentQuestionId = questionList.QUESTION_ID;
          let parentQuestionMandat = questionList.QUESTIONDEF_TYPE;
          let questionId = outerThis.props.initialValues.question_parent_id;

          if (parentQuestionId == questionId) {
            if (parentQuestionMandat == "optional") {
              outerThis.setState({ parentOptional: true });
            } else {
              outerThis.setState({ parentOptional: false });
            }
            if (
              questionList.RESPONSE_TYPE == "Typeahead" ||
              questionList.RESPONSE_TYPE == "multiSelectTypeahead" ||
              questionList.RESPONSE_TYPE == "Lookup Textbox"
            ) {
              outerThis.setState({
                showHideAssociatedSelected: false,
              });
            }
          }
        });
      }

      if (
        this.props.spcmReducer.dataSourceTypes !==
        newProps.spcmReducer.dataSourceTypes
      ) {
        let outerThis = this;

        newProps.spcmReducer.dataSourceTypes.forEach((dataSourceTypesList) => {
          let sourceTypeId = dataSourceTypesList.ID;
          if (
            sourceTypeId === datasource_type &&
            (dataSourceTypesList.FIELD1_NAME === "Project Code" ||
              dataSourceTypesList.FIELD1_NAME === "Laptop Serial Number" ||
              dataSourceTypesList.FIELD1_NAME === "Desktop Serial Number")
          ) {
            outerThis.setState({
              showDataSourceOption: true,
            });
          }

          if (
            sourceTypeId === datasource_type &&
            dataSourceTypesList.FIELD1_NAME === "ITAM Location"
          ) {
            console.log("ItamLocationSelectedItamLocationSelected");
            outerThis.setState({
              ItamLocationSelected: true,
            });
          }
        });
      }

     if(this.props.spcmReducer.dataSourceTypes !== newProps.spcmReducer.dataSourceTypes){
        const { initialValues } = this.props;
        if(initialValues && (typeof initialValues.ro_responses !=="undefined" || initialValues.ro_responses !== null)){
          if(initialValues.ro_responses?.length>0) {
            let selectedValueList = initialValues.ro_responses.map((val)=>{
              return  { id:val, label:val};
            });
            this.setState({selectedValueList:selectedValueList});
          }
        }
      }
      let questionsAssociated = newProps.spcmReducer.myQuestionsForService?.filter(ques => {
        if(ques.QUESTION_ID.toString() === this.props.initialValues.question_parent_id.toString()) {
          return ques;
        }
      });
      if(
        questionsAssociated.length > 0 && 
        this.state.multiSelectValuesUpdated === false && 
        questionsAssociated[0].RESPONSE_TYPE == "Checkbox"
      ) {
        let selectedValueList = this.props.initialValues.parent_response_id.split(';').map((val, index) => {
          return { 
            id: val, 
            label: this.props.initialValues.question_parent_value.split(';')[index] };
        });
        this.setState({ 
          selectedMultiValueList: selectedValueList,
          showMultiSelectAssociated: true, 
          multiSelectValuesUpdated: true
        });
      }
    }
  }

  componentDidMount() {
    this.props.getConsumptionItems(
      this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID
    );
  }

  componentWillUnmount() {
    this.props.resetReducer();
    this.props.spcmLoader("attribute", "success");
  }

  validateTableHeaders(e) {
    let str = e.target.value;
    let semicolonCount = str.split(";").length - 1;
    if (semicolonCount > 2) {
      e.preventDefault();
    }
  }

  onValueSelection(selected){
    let error = '';
    if(selected.length===0){
       error = 'error';
    }
    this.setState({ errorValue:error, selectedValueList: selected });
  }

  onMultiValueSelection(selected) {
    let error = '';
    if(selected.length===0){
       error = 'error';
    }
    let selectedValue = [];
    let selectedId = [];
    selected.map((val) => {
      selectedValue.push(val.label);
      selectedId.push(val.id);
    });
    this.props.dispatch(change('serviceOrderChoicesForm', 'question_parent_value', selectedValue.join(";")));
    this.props.dispatch(change('serviceOrderChoicesForm', 'parent_response_id', selectedId.join(";")));
    this.setState({ errorMultiValue:error, selectedMultiValueList: selected });
  }

  getValuesDropdownOptions(){
  
    let object = [];
    const readOnlyTypes = this.props.spcmReducer.ReadOnlyResponseKeyTypesAndKeys;
    if(typeof readOnlyTypes !=="undefined" && readOnlyTypes.length>0){
      readOnlyTypes.forEach((values)=>{
        let val = { id: values,label:values};
         object.push(val);
      });
    }
    return object;
  }

  submitForm(values) {
    console.log("submit");
    console.log("==option",this.state.showDataSourceOption);
    console.log("VALUES", values);
    if(this.state.DataSourceOpt == true && this.state.ItamLocationSelected == false && (this.state.dynamicDropSelected == false || (this.state.dynamicDropSelected == true && this.state.showDataSourceOption == true)) && this.state.selectedValueList.length==0){
      this.setState({errorValue:'error'});
      return false;
    }else if(this.state.DataSourceOpt == true && this.state.ItamLocationSelected == false && (this.state.dynamicDropSelected == false || (this.state.dynamicDropSelected == true && this.state.showDataSourceOption == true)) && this.state.selectedValueList.length>0){
      values['ro_responses'] = this.state.selectedValueList.map((values)=>{
        return values.id;
      })
    }
    else{
      values.ro_responses = null;
    }
    values.min_value =
      this.state.minItemQuantity == "" ? "1" : this.state.minItemQuantity;
    values.max_value =
      this.state.maxItemQuantity == ""
        ? "99999999"
        : this.state.maxItemQuantity;
    values.datasource_type_name = this.state.datasource_type_name;

    if (values.response_value) {
      values.response_value = values.response_value
        .replace(/\s*;\s*/g, ";")
        .trim();
    }
    // console.log('values.category_name============',values.category_name);
    // if (values.category_name && this.state.AssociatedToDropdown == 0) {
    if (values.category_name) {
      // console.log('values.category_name============',values.category_name);
      values.category_id = values.category_name;
    }
    // else if (this.state.parentQuesCatId && this.state.AssociatedToDropdown == 1) {
    //     console.log(this.state.parentQuesCatId);
    //     values.category_id = this.state.parentQuesCatId;
    // }

    if(this.state.show_include_in_summary) {
      values.include_in_summary = this.state.include_in_summary
    }

    values.questiondef_type =
      this.state.isMandatory === "Yes" && this.state.parentOptional == false
        ? "mandatory"
        : "optional";

    console.log("submit values");
    console.log(values);

    if (values.display_text) {
      values.display_text =
        values.display_text.replace(/<[^>]+>/g, "").trim() == ""
          ? ""
          : values.display_text;
        const actualData = values.display_text.replace(/<[^>]+>/g, "").trim();
        if(actualData.length > 255){
          throw new SubmissionError({
            _error: 'Display text cannot be greater than 255 characters',
          });
          return;
        }
      values.questiondef_type = "optional";
    }

    if (this.state.AssociatedToDropdown == 0) {
      values.question_parent_id = 0;
      values.question_parent_value = null;
      values.parent_response_id = null;
    }

    // else if (this.state.AssociatedToDropdown == 1) {
    //     const questions = this.props.spcmReducer.myQuestionsForService;
    //     const selectedQuestion = questions.filter(que => que.QUESTION_ID == this.state.associatedSelectedValues);
    //     if (selectedQuestion.length > 0) {
    //         values.question_entityid = selectedQuestion[0].QUESTION_ENTITYID
    //     }
    // }

    if (values.response_type !== "Table") {
      values.table_headers = null;
    }
    if (values.response_type == "Dynamic Dropdown") {
      if (this.state.showDataSourceOption == false) {
        values.ro_responses = null;
      }
    }
    if (this.state.showHideAssociatedSelected === false) {
      values.question_parent_value = null;
      values.parent_response_id = null;
    }
    if (
      values.response_type != "Dynamic Dropdown" &&
      values.response_type != "Typeahead" &&
      values.response_type != "multiSelectTypeahead"
    ) {
      values.placeholder = null;
    }

    if (this.props.spcmReducer.editLeftId > 0) {
      values.updatedby_name = "";
      values.updatedby = "";
    } else {
      values.createdby_name = "";
      values.created_by = "";
    }

    if (
      values.response_type == "Calendar" ||
      values.response_type == "Datetime"
    ) {
      values.calendar_type_id = this.state.calendar_type_id;
      values.calendar_type_value = this.state.calendar_type_value;
      values.days_before = parseInt(this.state.days_before,10);
      values.days_after = parseInt(this.state.days_after,10);
    } else {
      values.calendar_type_id = null;
      values.calendar_type_value = null;
    }

    if (this.props.spcmReducer.editLeftId > 0) {
      this.props.spcmLoader("attribute", "posting");
      if (this.props.formSelection == "Questions_Form") {
        values.offering_id =
          this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID;
        let newValues = Object.assign(
          {},
          _.omit(values, ["category_name_view"])
        );
        return axios
          .patch(
            "/service/api/patchOfferingQuestion/" +
              this.props.spcmReducer.editLeftId,
            newValues
          )
          .then((response) => {
            this.props.getServiceOrderChoices(
              this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID
            );
            this.props.componentFormFun4(false);
            this.props.spcmLoader("attribute", "success");
          })
          .catch((error) => {
            this.props.spcmLoader("attribute", "success");
            throw new SubmissionError({
              _error: error.response.data[0].message,
            });
          });
      } else {
        values.offering_id =
          this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID;

        values = _.transform(values, function (result, val, key) {
          result[key.toLowerCase()] = val;
        });

        return axios
          .patch(
            "/service/api/patchQuestionsCategory/" +
              this.props.spcmReducer.editLeftId,
            values
          )
          .then((response) => {
            this.props.getQuestionsCategory(
              this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID
            );
            this.props.componentFormFun4(false);
            this.props.spcmLoader("attribute", "success");
          })
          .catch((error) => {
            this.props.spcmLoader("attribute", "success");
            throw new SubmissionError({ _error: error.response.data.message });
          });
      }
    } else {
      this.props.spcmLoader("attribute", "posting");

      if (this.props.formSelection == "Questions_Form") {
        return axios
          .post("/service/api/saveOfferingQuestion", values)
          .then((response) => {
            this.props.getServiceOrderChoices(
              this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID
            );
            this.props.getServiceDetailCounts(
              this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID
            );
            this.props.componentFormFun4(false);
            this.props.spcmLoader("attribute", "success");
          })
          .catch((error) => {
            this.props.spcmLoader("attribute", "success");
            throw new SubmissionError({
              _error: error.response.data[0].message,
            });
          });
      } else {
        values.offering_id =
          this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID;
        values.CATEGORY_STATUS = this.state.categoryNameInitialStatus;

        values = _.transform(values, function (result, val, key) {
          result[key.toLowerCase()] = val;
        });
        console.log(values);

        return axios
          .post("/service/api/saveQuestionsCategory", values)
          .then((response) => {
            this.props.getQuestionsCategory(
              this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID
            );
            this.props.getServiceDetailCounts(
              this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID
            );
            this.props.componentFormFun4(false);
            this.props.spcmLoader("attribute", "success");
          })
          .catch((error) => {
            this.props.spcmLoader("attribute", "success");
            throw new SubmissionError({ _error: error.response.data.message });
          });
      }
    }
  }

  render() {
    console.log("this.props.initialValues.", this.props.initialValues);

    console.log("ItamLocationSelected");
    console.log(this.state.ItamLocationSelected);
    const tr = this.props.tr;
    const { error, handleSubmit, change } = this.props;
    const { selectedValueList, selectedMultiValueList, errorValue, errorMultiValue } = this.state;
    const components = this.props.spcmReducer.consumptionItems;
    const questions = this.props.spcmReducer.myQuestionsForService;
    const datasource_type = this.props.spcmReducer.dataSourceTypes;
    const ReadOnlyResponseKeyTypesAndKeys =
      this.props.spcmReducer.ReadOnlyResponseKeyTypesAndKeys;

    const category_list = this.props.spcmReducer.quesActiveCategories;
    const selectedQuestion = questions.filter(
      (que) => que.QUESTION_ID == this.state.associatedSelectedValues
    );

    let selectedComponents = "";
    let responseArr = [];
    if (selectedQuestion.length > 0) {
      // responseArr = selectedQuestion[0].RESPONSE_VALUE !== null ? selectedQuestion[0].RESPONSE_VALUE.split(";") : [];
      // console.log("responseArr");
      // console.log(responseArr);

      const responseValueArray =
        selectedQuestion[0].RESPONSE_VALUE !== null
          ? selectedQuestion[0].RESPONSE_VALUE.split(";")
          : [];
      const responseValueIdArray =
        selectedQuestion[0].RESPONSE_VALUE_ID !== null
          ? selectedQuestion[0].RESPONSE_VALUE_ID.split(";")
          : [];
      responseArr = responseValueArray.map((i, index) => ({
        label: i,
        id: responseValueIdArray[index],
      }));
      console.log("responseArr");
      console.log(responseArr);
    }

    let questionType = [
      { value: "optional", text: "Optional" },
      { value: "mandatory", text: "Mandatory" },
    ];

    let responseType = [
      { value: "Attachment", text: "Attachment" },
      { value: "Calendar", text: "Calendar" },
      { value: "Checkbox", text: "Checkbox" },
      { value: "Datetime", text: "Datetime" },
      { value: "Display Text", text: "Display Text" },
      { value: "Dropdown List", text: "Dropdown List" },
      { value: "Dropzone", text: "Dropzone" },
      { value: "Dynamic Dropdown", text: "Dynamic Dropdown" },
      { value: "Lookup Textbox", text: "Lookup Textbox" },
      { value: "Multiline Textbox", text: "Multiline Textbox" },
      { value: "Multiselect List", text: "Multiselect List" },
      { value: "Numeric Spinner", text: "Numeric Spinner" },
      { value: "Radio button", text: "Radio button" },
      { value: "Table", text: "Table" },
      { value: "Textbox", text: "Textbox" },
      { value: "Typeahead", text: "Typeahead" },
      { value: "multiSelectTypeahead", text: "MultiSelect Typeahead" },
    ];
    let respTypeDynamicDrop = [
      { value: "Dynamic Dropdown", text: "Dynamic Dropdown" },
    ];

    let triggerApprovalOptions = [
      { value: "Y", key: "Yes" },
      { value: "N", key: "No" },
    ];
    let numbers = [];
    for (let i = 1; i <= 10; i++) {
      numbers.push({ value: i, text: i });
    }

    console.log("StateCheck");
    console.log(this.state.DataSourceOpt);
    console.log(this.state.dynamicDropSelected);
    console.log(this.state.showDataSourceOption);

    return (
      <div className="rBoxGap">
        <form onSubmit={handleSubmit(this.submitForm)}>
          {error == undefined || error == null ? null : (
            <Alert variant="danger" className="errorMessageSec">
              <p>{error}</p>
            </Alert>
          )}

          {this.props.formSelection == "Questions_Form" ? (
            <div>
              <div className="rSubhdTxt">Questions</div>
              <Row>
                <Col md={12} className="margin-b-15">
                  <Form.Group className="radioInlineBox form-group">
                    <Form.Label bsClass="" className="float-l margin-r-20">
                      <span className="rStar"></span> {tr["Dependent ?"]}
                    </Form.Label>
                    <div className="radioInlineBox float-l">
                      <Field
                        component={_radio}
                        className="form-control"
                        type="radio"
                        checked={this.state.AssociatedToDropdown == 1}
                        name="question_parent_type"
                        value="Yes"
                        onChange={this.onAssociatedToChange}
                        disabled={this.state.isTableTypeQuestion}
                      />

                      <Field
                        component={_radio}
                        className="form-control"
                        type="radio"
                        checked={this.state.AssociatedToDropdown == 0}
                        name="question_parent_type"
                        value="No"
                        onChange={this.onAssociatedToChange}
                      />
                    </div>
                  </Form.Group>
                </Col>

                {/* {
                                    this.state.AssociatedToDropdown == 0 ?

                                        <Col md={12}>
                                            <Form.Group >
                                                <Form.Label bsClass=""><span className="rStar"></span> {'Group Name'}</Form.Label>
                                                <Field component={_dropDown} name="category_name" options={category_list} className='form-control' onChange={(e) => { this.onCategoryChange(e.target.value) }} />
                                            </Form.Group>
                                        </Col> :

                                        null

                                } */}

                {this.state.AssociatedToDropdown == 0 ? (
                  <Col md={12}>
                    <Form.Group className='form-group'>
                      <Form.Label bsClass="">
                        <span className="rStar"></span> {"Group Name"}
                      </Form.Label>
                      <Field
                        component={_dropDown}
                        name="category_name"
                        options={category_list}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                ) : null}

                {this.state.AssociatedToDropdown == 1 && (
                  <Col md={12}>
                    <Form.Group className='form-group'>
                      <Form.Label bsClass="">
                        <span className="rStar"></span> {"Dependent on/Value"}
                      </Form.Label>
                      <Field
                        component={_dropDown}
                        name="question_parent_id"
                        options={questions}
                        onChange={this.onAssociatedSelected}
                        className="form-control"
                        disabled={this.props.spcmReducer.editLeftId > 0}
                      />
                    </Form.Group>
                  </Col>
                )}

                {this.state.AssociatedToDropdown == 1 &&
                  this.state.showAssociatedSelected &&
                  this.state.showHideAssociatedSelected &&
                  !this.state.showMultiSelectAssociated && (
                    <Col md={12}>
                      <Form.Group className='form-group'>
                        {/* <Form.Label bsClass=""><span className="rStar"></span> {'Parent Value'}</Form.Label> */}
                        <Field
                          component={_dropDown}
                          name="parent_response_id"
                          options={responseArr}
                          className="form-control"
                          onChange={(e) => {
                            change(
                              "question_parent_value",
                              e.target[e.target.selectedIndex].text
                            );
                          }}
                        />
                      </Form.Group>
                    </Col>
                  )}

                {this.state.AssociatedToDropdown == 1 &&
                  this.state.showAssociatedSelected &&
                  this.state.showHideAssociatedSelected &&
                  this.state.showMultiSelectAssociated && (
                    <Col md={12}>
                      <Form.Group className="form-group">
                        {console.log("Response Arr: ", responseArr)}
                        <Field
                          id="parent_response_id"
                          component={TypeaheadandDropdown}
                          onSelection={this.onMultiValueSelection}
                          name="parent_response_id"
                          className="form-control"
                          options={responseArr}
                          selectedOptions={selectedMultiValueList}
                          onCrossClick={() => this.onMultiValueSelection([])}
                          errorClass={
                            errorMultiValue === "error" ? "error" : ""
                          }
                          multiple={true}
                        />
                      </Form.Group>
                    </Col>
                  )}

                {/* {this.state.AssociatedToDropdown == 1 && this.state.showAssociatedSelected && (
                                    <Col md={12}>
                                        <Form.Group >
                                            <Form.Label bsClass=""><span className="rStar"></span> {'Group Name'}</Form.Label>
                                            <Field component={_inputField} placeholder={this.state.parentQuesCatName} name="category_name_view_only" className='form-control' readOnly />
                                        </Form.Group>
                                    </Col>
                                )} */}

                {this.state.AssociatedToDropdown == 1 ? (
                  <Col md={12}>
                    <Form.Group className='form-group'>
                      <Form.Label bsClass="">
                        <span className="rStar"></span> {"Group Name"}
                      </Form.Label>
                      <Field
                        component={_dropDown}
                        name="category_name"
                        options={category_list}
                        defaultSelect={this.state.parentQuesCatId}
                        className="form-control"
                        onChange={(e) => {
                          this.onCategoryChange(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                ) : null}

                {/* {this.state.AssociatedToDropdown == 1 ? <Field component={_inputField} placeholder={selectedComponents} name="question_entityid_view_only" className='form-control' readOnly />
                                    : <Field component={_dropDown} options={components} className='form-control' name="question_entityid" />} */}

                {this.state.AssociatedToDropdown == 0 ? (
                  <Col md={12}>
                    <Form.Group className='form-group'>
                      <Form.Label bsClass="">
                        <span className="rStar"></span>{" "}
                        {tr["Against which component"]}
                      </Form.Label>
                      <Field
                        component={_dropDown}
                        options={components}
                        className="form-control"
                        name="question_entityid"
                      />
                    </Form.Group>
                  </Col>
                ) : (
                  <Col md={12}>
                    <Form.Group className='form-group'>
                      <Form.Label bsClass="">
                        <span className="rStar"></span>{" "}
                        {tr["Against which component"]}
                      </Form.Label>
                      <Field
                        component={_dropDown}
                        options={components}
                        className="form-control"
                        name="question_entityid"
                        defaultSelect={this.state.selectedComponentsId}
                        onChange={(e) => {
                          this.onComponentChange(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                )}

                <Col md={12}>
                  <Form.Group className='form-group'>
                    <Form.Label bsClass="">
                      <span className="rStar"></span> {tr["Information Prompt"]}
                    </Form.Label>
                    <Field
                      component={_textArea}
                      rows="1"
                      name="question_text"
                      className="form-control"
                    />
                  </Form.Group>
                </Col>

                <Col md={12}>
                  <Form.Group className='form-group'>
                    <Form.Label bsClass="">
                      {tr["Help Text"]}{" "}
                      <span className="f-size-10">
                        (For example : Comments/Guidance)
                      </span>
                    </Form.Label>
                    <Field
                      component={_inputField}
                      rows="1"
                      name="help_text"
                      className="form-control"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="margin-b-10">
                <Col md={12}>
                  <Form.Group className='form-group'>
                    <Form.Label bsClass="" className="float-l margin-r-20">
                      {" "}
                      <span className=""></span> {tr["Mandatory ?"]}
                    </Form.Label>
                    {/* <Field component={_dropDown} name="questiondef_type" options={questionType} className='form-control'/> */}

                    <div className="radioInlineBox float-l">
                      <Field
                        component={_radio}
                        className="form-control"
                        type="radio"
                        name="questiondef_type"
                        value="Yes"
                        checked={this.state.isMandatory === "Yes"}
                        onChange={this.onIsMandatoryChange}
                        disabled={this.state.parentOptional}
                      />

                      <Field
                        component={_radio}
                        className="form-control"
                        type="radio"
                        name="questiondef_type"
                        value="No"
                        checked={this.state.isMandatory === "No"}
                        onChange={this.onIsMandatoryChange}
                        disabled={
                          this.props.initialValues.have_mandatory_child ==
                          "true"
                        }
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Group className='form-group'>
                    <Form.Label bsClass="">
                      <span className="rStar"></span> {tr["Information Type"]}
                    </Form.Label>
                    <Field
                      component={_dropDown}
                      rows="1"
                      name="response_type"
                      options={
                        this.state.showHideAssociatedSelected == false &&
                        this.state.AssociatedToDropdown == 1
                          ? respTypeDynamicDrop
                          : responseType
                      }
                      className="form-control"
                      disabled={this.props.initialValues.have_child == "true"}
                      onChange={this.onChange}
                    />
                  </Form.Group>
                </Col>

                {this.state.searchInvokeField == true ? (
                  <Col md={12}>
                    <Form.Group className='form-group'>
                      <Form.Label bsClass="">
                        {tr["Search to be invoked after"]}
                      </Form.Label>
                      <Field
                        component={_number}
                        name="call_api_after_typeahead"
                        className="form-control"
                        min={4}
                        max={15}
                      />
                    </Form.Group>
                  </Col>
                ) : (
                  ""
                )}

                {this.state.calendarSelected == true ? (
                  <Col md={12}>
                    <Form.Group className='form-group'>
                      <Form.Label bsClass="">
                        <span className="rStar"></span> {tr["Restrict Date"]}
                      </Form.Label>
                      <Field
                        component={_dropDown}
                        name="calendar_type_id"
                        options={[
                          { text: "None", value: "10" },
                          {
                            text: "Past Dates",
                            value: "20",
                          },
                          {
                            text: "Future Dates",
                            value: "30",
                          },
                          {
                            text: "Date Range",
                            value: "40",
                          },
                        ]}
                        className="form-control"
                        onChange={this.onRestrictDateChange}
                        defaultValue={this.state.calendar_type_id}
                      />
                    </Form.Group>
                  </Col>
                ) : null}

                {this.state.calendar_type_id === "40" && (
                  <>
                    <Row>
                      <Col md={12}>
                        <Form.Group className='form-group'>
                          <Form.Label bsClass="">
                            <span className="rStar"></span> {tr["Days before Current Date"]}
                          </Form.Label>
                          <Field
                            component={_number}
                            name="days_before"
                            className="form-control"
                            onChange={this.onDaysBeforeCurrentDateChange}
                            defaultValue={this.state.days_before}
                            min={0}
                            max={9999}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Form.Group className='form-group'>
                          <Form.Label bsClass="">
                            <span className="rStar"></span> {tr["Days after Current Date"]}
                          </Form.Label>
                          <Field
                            component={_number}
                            name="days_after"
                            className="form-control"
                            onChange={this.onDaysAfterCurrentDateChange}
                            defaultValue={this.state.days_before}
                            min={0}
                            max={9999}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                )}

                {this.state.dynamicTextboxSelected == true ? (
                  <Col md={12}>
                    <Form.Group className='form-group'>
                      <Form.Label bsClass="">
                        <span className="rStar"></span>{" "}
                        {tr["Min. Character Limit"]}
                      </Form.Label>
                      <Field
                        component={_number}
                        name="call_api_after"
                        className="form-control"
                        min={1}
                        max={20}
                      />
                    </Form.Group>
                  </Col>
                ) : (
                  ""
                )}

                {this.state.showTableHeaders == true ? (
                  <Col md={12}>
                    <Form.Group className='form-group'>
                      <Form.Label bsClass="">
                        <span className="rStar"></span> {tr["Table Headers"]}
                      </Form.Label>
                      <Field
                        component={_inputField}
                        name="table_headers"
                        className="form-control"
                        onChange={(e) => this.validateTableHeaders(e)}
                      />
                    </Form.Group>
                    <span className="f-size-10">
                      Please enter semicolon separated value (Ex. Value 1 ;
                      Value 2 ; Value 3). Maximum 3 values can be entered
                    </span>
                  </Col>
                ) : (
                  ""
                )}

                {this.state.showResponseMode ? (
                  <Col md={12}>
                    <Form.Group className='form-group'>
                      <Form.Label bsClass="">
                        <span className="rStar"></span> {tr["Response Mode"]}
                      </Form.Label>
                      <Field
                        component={_dropDown}
                        name="response_mode"
                        options={
                          this.state.showStatic
                            ? [{ text: "Static", value: "Static" }]
                            : [{ text: "Dynamic", value: "Dynamic" }]
                        }
                        className="form-control"
                        onChange={this.onResponseModeChange}
                      />
                    </Form.Group>
                  </Col>
                ) : (
                  ""
                )}

                {this.state.DataSourceTypeOpt == "Dynamic" ? (
                  <Col md={12}>
                    <Form.Group className='form-group'>
                      <Form.Label bsClass="">
                        <span className="rStar"></span> Placeholder
                      </Form.Label>
                      <Field
                        component={_inputField}
                        name="placeholder"
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                ) : (
                  ""
                )}

                {this.state.DataSourceTypeOpt == "Dynamic" ? (
                  <Col md={12}>
                    <Form.Group className='form-group'>
                      <Form.Label bsClass="">
                        <span className="rStar"></span> Data Source Type
                      </Form.Label>
                      <Field
                        component={_dropDown}
                        onChange={this.onDataSourceOptChng}
                        name="datasource_type"
                        options={datasource_type}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                ) : (
                  ""
                )}

                {this.state.DataSourceTypeOpt == "Static" ? (
                  <Col md={12}>
                    <Form.Group className='form-group'>
                      <Form.Label bsClass="">
                        <span className="rStar"></span> {tr["List of values"]}
                      </Form.Label>
                      <Field
                        component={_inputField}
                        name="response_value"
                        className="form-control"
                      />
                    </Form.Group>
                    <span className="f-size-10">
                      Please enter semicolon separated value (Ex. Value 1 ;
                      Value 2 ; Value 3)
                    </span>
                  </Col>
                ) : (
                  ""
                )}

                {this.state.DataSourceOpt == true &&
                this.state.ItamLocationSelected == false &&
                (this.state.dynamicDropSelected == false ||
                  (this.state.dynamicDropSelected == true &&
                    this.state.showDataSourceOption == true)) ? (
                  <Col md={12}>
                    <Form.Group className='form-group'>
                      <Form.Label bsClass="">
                        <span className="rStar"></span> Data Source Options
                      </Form.Label>
                      {/* <Field
                        component={_multiSelect}
                        name="ro_responses"
                        data={ReadOnlyResponseKeyTypesAndKeys}
                        className="form-control"
                      /> */}
                      <Field 
                      component={TypeaheadandDropdown}
                      onSelection={this.onValueSelection}
                      name="ro_responses"
                      className="form-control"
                      options={this.getValuesDropdownOptions()}
                      selectedOptions={selectedValueList}
                      onCrossClick={() => this.setState({ errorValue: '', selectedValueList: [] }) }
                      errorClass={errorValue === 'error' ? 'error' : ''}
                      multiple={true}
                      />
                    </Form.Group>
                  </Col>
                ) : (
                  ""
                )}

                {this.state.show_data_source_identifier && this.state.DataSourceOpt == true &&
                this.state.ItamLocationSelected == false &&
                (this.state.dynamicDropSelected == false ||
                  (this.state.dynamicDropSelected == true &&
                    this.state.showDataSourceOption == true)) && (
                  <Col md={12}>
                    <Form.Group className='form-group'>
                      <Form.Label bsClass="">
                        <span className="rStar"></span> {"ID Field Mapping"}
                      </Form.Label>
                      <Field
                        component={_dropDown}
                        name="data_source_identifier"
                        className="form-control"
                        options={this.getValuesDropdownOptions()}
                      />
                    </Form.Group>
                  </Col>
                )}

                {this.state.showMaxLimit ? (
                  <Col md={12}>
                    <Form.Group className='form-group'>
                      <Form.Label bsClass="">
                        <span className="rStar"></span> {tr["Maximum Length"]}
                      </Form.Label>
                      <Field
                        component={_inputField}
                        name="max_limit"
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                ) : (
                  ""
                )}

                {this.state.showDisplayTextField ? (
                  <Col md={12}>
                    <Form.Group className='form-group'>
                      <Form.Label bsClass=""> {tr["Display Text"]}
                      <a href="javascript:void(0)"
                        title={'Display text should not be more than 255 characters'}
                      >
                        <IoIosInformationCircleOutline style={{'font-size': '17px', 'margin-left': '5px', 'margin-top': '-2px'}} title="" />
                      </a>
                      </Form.Label>
                      <Field
                        name="display_text"
                        component={_ckeditor}
                        className="reactQuillEditor-area"
                        richTextConfig ={"minRichTextEditor"}
                      />
                    </Form.Group>
                  </Col>
                ) : (
                  ""
                )}

                {this.state.showMinMaxLimit ? (
                  <Row className="minItemLeftMargin">
                    <Col md={3}>
                      <Form.Group className='form-group'>
                        <Form.Label bsClass="" className="minItemFontSize">
                          {this.props.tr["Min. Value"]}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group className='form-group'>
                        <Field
                          component={itemQuantityField}
                          max={99999999}
                          min={1}
                          name="min_value"
                          className="form-control shotSpin"
                          onChange={(e) => {
                            this.setState({ minItemQuantity: e.target.value });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group className='form-group'>
                        <Form.Label bsClass="" className="minItemFontSize">
                          {this.props.tr["Max. Value"]}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group className='form-group'>
                        <Field
                          component={itemQuantityField}
                          max={99999999}
                          min={1}
                          name="max_value"
                          className="form-control shotSpin"
                          onChange={(e) => {
                            this.setState({ maxItemQuantity: e.target.value });
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </Row>

              <Row>
                <Col md={12}>
                  <Form.Group className='form-group'>
                    <Form.Label bsClass="">{tr["Trigger Approval"]}</Form.Label>
                    <Field
                      component={_dropDown}
                      rows="1"
                      name="question_approval"
                      options={triggerApprovalOptions}
                      className="form-control"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Group className='form-group'>
                    <Form.Label bsClass="">
                      <span className="rStar"></span>{" "}
                      {tr["Visibility Sequence"]}
                    </Form.Label>
                    <Field
                      component={_number}
                      name="question_sequence"
                      className="form-control"
                      min={1}
                      max={50}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {this.state.show_include_in_summary && <Row>
                <Col md={6} >
                  <Form.Group className="form-group" >
                    <Form.Check name="include_in_summary" type="checkbox"
                      checked={this.state.include_in_summary === "true"}
                      onClick={() => this.setIncudeInSummary()}
                      label={tr["Include In Summary"]}>
                    </Form.Check>
                  </Form.Group>
                </Col>
              </Row>}
              <Row>
                <Col md={12}>
                  <ButtonToolbar className="black">
                    <Button
                      title="Save"
                      disabled={this.props.spcmReducer.spcmPostingAttribute}
                      type="submit"
                      bsSize="small"
                      bsStyle="primary"
                      className="rgSidrkBtn"
                    >
                      {this.props.spcmReducer.spcmPostingAttribute ? (
                        <ImSpinner6 className="icn-spinner"/>
                      ) : null}{" "}
                      {tr["Save"]}
                    </Button>
                    <Button
                      title="Close"
                      onClick={() => {
                        this.props.componentFormFun4(false);
                      }}
                      bsSize="small"
                      bsStyle="text"
                      className="rgSiLigBtn"
                    >
                      {tr["Close"]}
                    </Button>
                  </ButtonToolbar>
                </Col>
              </Row>
            </div>
          ) : (
            <div>
              <div className="rSubhdTxt">Question Grouping</div>
              <Row>
                <Col md={12}>
                  <Form.Group className='form-group'>
                    <Form.Label bsClass="">
                      <span className="rStar"></span> {tr["Group Name"]}
                    </Form.Label>
                    <Field
                      component={_inputField}
                      name="CATEGORY_NAME"
                      className="form-control"
                      onpaste={(e) => {
                        this.countCat(e);
                      }}
                      onChange={(e) => {
                        this.countCat(e);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  {this.props.haveActiveQuestionsForCategory &&
                  (this.props.haveActiveQuestionsForCategory == "Yes" ||
                    this.props.haveActiveQuestionsForCategory == "yes") ? (
                    <Form.Group className='form-group'>
                      <Form.Label bsClass="">
                        <span className="rStar"></span>{" "}
                        {tr["Visibility Sequence"]}
                      </Form.Label>
                      <Field
                        component={_number}
                        name="CATEGORY_SEQUENCE"
                        className="form-control"
                        min={1}
                        max={50}
                        disabled
                      />
                    </Form.Group>
                  ) : (
                    <Form.Group className='form-group'>
                      <Form.Label bsClass="">
                        <span className="rStar"></span>{" "}
                        {tr["Visibility Sequence"]}
                      </Form.Label>
                      <Field
                        component={_number}
                        name="CATEGORY_SEQUENCE"
                        className="form-control"
                        min={1}
                        max={50}
                      />
                    </Form.Group>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Group className='form-group'>
                    <Form.Label bsClass="">{tr["Status"]}</Form.Label>
                    <Field
                      component={_dropDown}
                      name="CATEGORY_STATUS"
                      className="form-control"
                      onChange={(e) => {
                        this.setState({
                          categoryNameInitialStatus: e.target.value,
                        });
                      }}
                      options={[
                        { value: "Active", text: "Active" },
                        { value: "Inactive", text: "Inactive" },
                      ]}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <ButtonToolbar className="black">
                    <Button
                      title="Save"
                      disabled={this.props.spcmReducer.spcmPostingAttribute}
                      type="submit"
                      bsSize="small"
                      bsStyle="primary"
                      className="rgSidrkBtn"
                    >
                      {this.props.spcmReducer.spcmPostingAttribute ? (
                        <ImSpinner6 className="icn-spinner"/>
                      ) : null}{" "}
                      {tr["Save"]}
                    </Button>
                    <Button
                      title="Close"
                      onClick={() => {
                        this.props.componentFormFun4(false);
                      }}
                      bsSize="small"
                      bsStyle="text"
                      className="rgSiLigBtn"
                    >
                      {tr["Close"]}
                    </Button>
                  </ButtonToolbar>
                </Col>
              </Row>
            </div>
          )}
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({ spcmReducer }) => {
  if (spcmReducer.editLeftService.component_name) {
    spcmReducer.editLeftService.category_name_view =
      spcmReducer.editLeftService.category_name;
    spcmReducer.editLeftService.category_name =
      spcmReducer.editLeftService.category_id;
  }
  return {
    spcmReducer,
    initialValues:
      spcmReducer.editLeftId > 0 ? spcmReducer.editLeftService : "",
    haveActiveQuestionsForCategory:
      spcmReducer.editLeftId > 0
        ? spcmReducer.editLeftService.component_name
          ? null
          : spcmReducer.editLeftService.HAVE_ACTIVE_QUESTIONS_FOR_CATEGORY
        : null,
  };
};
ComponentForm = reduxForm({
  form: "serviceOrderChoicesForm",
  validate,
  enableReinitialize: true,
})(ComponentForm);

export default connect(mapStateToProps, {
  spcmLoader,
  setLeftEditId,
  getServiceDetailCounts,
  getServiceOrderChoices,
  getServiceMyQuestionLists,
  getConsumptionItems,
  getQuestionsCategory,
  getQuestionsActiveCategory,
  getDataSourceTypes,
  getReadOnlyResponseKeyTypesAndKeys,
  resetReducer,
})(ComponentForm);
