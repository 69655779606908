
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { loadAllTagsList } from '_Actions/foundation/tags/tagsAction';
import {HiPlus, HiOutlineRefresh} from "react-icons/hi";

const Header = (props) => {
	const dispatch = useDispatch();
	const tr = useSelector((state) => state.spcmReducer.tr);
	return (
		<Row className="margin-b-15">
			<Col lg={4} md={6} sm={6} xs={12}>
				<h1 bsClass="" className="sPageHeading1">{tr["Custom Attribute"]}</h1>
			</Col>
			<Col lg={8} md={6} sm={6} xs={12}>
				<div className="paHedFilter">
					<Link to="/createCustomAttribute" role="button" className="myBt plus fillBtn" title="Create" ><HiPlus/></Link>
					<Button bsPrefix=' ' title="Refresh" role="button" className="myBt" onClick={() => { props.showRightSideFun(false); dispatch(loadAllTagsList()); }}><HiOutlineRefresh className="refreshFlip" /></Button>
				</div>
			</Col>
		</Row>
	)
}

export default Header;