
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { reduxForm } from "redux-form";
import { saveContractData, resetVendor, resetCostCenter, resetAdminList } from "../../../../actions/ham/contractAction";
import { ImSpinner6 } from "react-icons/im";
import { IoSaveOutline, IoClose } from "react-icons/io5";
import {GLOBAL} from '_Globals';
import datetimeConvertor from "../../../../helpers/ISO8601converter";
import moment from "moment";
import momentTz from "moment-timezone";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';
const cookies = new Cookies();

let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));

dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();
const HeaderSec = (props) => {
  const navigate = useNavigate();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const saveObjRef = useRef(null);

	useEffect(() => {
		context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
    context.newTabPrevent();
	}, []);

  const dispatch = useDispatch();
  const submit = async (values) => {
    console.log("Conract=======", values);
    var phoneno = /^[0-9;+]*$/;
    let isValid = true;

    let dateRevision, lastReviewDate, nextReviewDate 

    dateRevision = momentTz(values.date_revision, jwtdf + " hh:mm A").format(
      "DD-MM-YYYY HH:mm:ss"
    );

    lastReviewDate = momentTz(values.last_review_date, jwtdf + " hh:mm A").format(
      "DD-MM-YYYY HH:mm:ss"
    );

    nextReviewDate = momentTz(values.next_review_date, jwtdf + " hh:mm A").format(
      "DD-MM-YYYY HH:mm:ss"
    );

    if((values.last_review_date && values.next_review_date)){

      lastReviewDate = momentTz(values.last_review_date, jwtdf + " hh:mm A").format(
        "DD-MM-YYYY HH:mm:ss"
      );
      nextReviewDate = momentTz(values.next_review_date, jwtdf + " hh:mm A").format(
        "DD-MM-YYYY HH:mm:ss"
      );
    }

    let endDate = momentTz(values.end_date, jwtdf + " hh:mm A").format(
      "DD-MM-YYYY HH:mm:ss"
    );
    let startDate = momentTz(values.start_date, jwtdf + " hh:mm A").format(
      "DD-MM-YYYY HH:mm:ss"
    );
    
    if (!values.name || values.name == "" || values.name.trim() == "") {
      props.setNameErr("error");
      isValid = false;
    } else {
      props.setNameErr("");
    }

    if (!values.business_function || values.business_function == "") {
      props.setBusinessErr("error");
      isValid = false;
    } else {
      props.setBusinessErr("");
    }

    if (!values.start_date || values.start_date == "") {
      props.setStartDateError("error");
      isValid = false;
    } else {
      props.setStartDateError("");
    }

    if (!values.end_date || values.start_date == "") {
      props.setEndDateError("error");
      isValid = false;
    } else {
      props.setEndDateError("");
    }
    if (values.start_date == values.end_date) {
      props.setStartDateError("error");
      props.setEndDateError("error");
      isValid = false;
    } else {
      props.setEndDateError("");
      props.setStartDateError("");
    }

    if(moment(values.start_date).isSameOrAfter(values.end_date)){
      props.setStartDateError("error");
      props.setEndDateError("error");
      isValid = false;
    }else{
      props.setStartDateError('');
      props.setEndDateError('');
    }

    if ((values.last_review_date && values.next_review_date) && (values.last_review_date == values.next_review_date)) {
      props.setlastReviewDateError("error");
      props.setnextReviewDateError("error");
      isValid = false;
    } else {
      props.setnextReviewDateError("");
      props.setlastReviewDateError("");
    }

    if((values.last_review_date && values.next_review_date) &&  (moment(values.last_review_date).isSameOrAfter(values.next_review_date))){
      props.setlastReviewDateError("error");
      props.setnextReviewDateError("error");
      isValid = false;
    }else{
      props.setlastReviewDateError('');
      props.setnextReviewDateError('');
    }

    if (!values.company) {
      props.setCompanyErrorState("error");
      // return false;
    } else {
      props.setCompanyErrorState("");
    }
 

    if ( !values.administrator_name ||  values.administrator_name == '' || values.administrator_name.trim() == '') {
      GLOBAL.adminownerErrorColor="error";
      isValid = false;
    } else {
      GLOBAL.adminownerErrorColor="";
    }

    if(!values.administrator_grp_name || values.administrator_grp_name.length == 0){
      //console.log("inside admin")
      props.setAdminErrorColor('error');
      isValid = false ;
    }

    // if ( !values.budget_sponsor || values.budget_sponsor == '' || values.budget_sponsor.trim() == '') {
    //   props.setBudgetErrorColor();
    //   isValid = false;
    // }else {
    //   props.setBudgetErrorColor("");
    // } 

    if (!values.status_name || values.status_name == '') {
      props.setStatusErr("error");
      isValid = false;
    }

    if (!values.sub_status_name || values.sub_status_name == '') {
      props.setSubStatusErr("error");
      isValid = false;
    }

    if(values.vendor_contact && values.vendor_contact.trim() !== "") {
      if (values.vendor_contact.match(phoneno)) {
        props.setNumberErr("");
      } else {
        props.setNumberErr("error");
        isValid = false;
      }
    } else {
      props.setNumberErr("");
    }

    if (isValid == true) {
      let postjson;
      postjson = {
        // contract_num: values.contract_num,
        contract_name: values.name,
        contract_type: (values.contract_type == "" || values.contract_type == null) ? "" : values.contract_type,
        business_function: values.business_function,
        vendor: (values.vendor_name == "" || values.vendor_name == null) ? "" : values.vendor_name,
        vendor_id: (values.vendor_id == "" || values.vendor_id == null) ? null : values.vendor_id,
        vendor_contact: (values.vendor_contact == "" || values.vendor_contact == null) ? "" : values.vendor_contact,
        company: values.companyName,
        company_id: values.company,
        start_date: startDate,
        end_date: endDate,
        administrator: values.administrator_name,
        administrator_id: values.administrator_id,
        cost_center: (values.cost_center_Name == "" || values.cost_center_Name == null) ? "" : values.cost_center_Name,
        cost_center_id: (values.cost_center_Id == "" || values.cost_center_Id == null) ? 0 : values.cost_center_Id,
        budget_sponsor: (values.budget_sponsor == "" || values.budget_sponsor == null) ? "" : values.budget_sponsor,
        budget_sponsor_id: (values.budget_Id == "" || values.budget_Id == null) ? null : values.budget_Id,
        status: values.status_id,
        status_name: values.status_name,
        sub_status: values.sub_status_id,
        sub_status_name: values.sub_status_name,
        description: (values.description == "" || values.description == null) ? "" : values.description,
        version: (values.version == "" || values.version == null) ? "" : values.version,
        last_review_date:  lastReviewDate=="Invalid date" ? null : lastReviewDate,
        next_review_date: nextReviewDate=="Invalid date" ? null : nextReviewDate,
        date_revision: dateRevision=="Invalid date" ? null : dateRevision,
        administrator_group: values.administrator_grp_name,
        administrator_group_id: values.administrator_grp_Id
      };
      dispatch(saveContractData(postjson));
      dispatch(resetVendor());
      dispatch(resetCostCenter());
      dispatch(resetAdminList());
    }
  };

  let { handleSubmit, submitting } = props;
  return (
    <>
      <Row className="margin-b-15">
        <Col lg={4} md={6} sm={6} xs={5}>
          <h1 bsClass="" className="sPageHeading1">
            {tr["Contract"]}
          </h1>
        </Col>
        <Col lg={8} md={6} sm={6} xs={7}>
          <div className="paHedFilter">
            <Link
              bsPrefix=" "
              id="hdID"
              to="javascript:void(0)"
              className="myBt plus ctrlKeyPrevent"
              disabled={submitting}
              onClick={handleSubmit(submit)}
              title={tr["Save"]}
              ref={saveObjRef}
            >
              <span>
                {props.loader ? (
                  <ImSpinner6 className="icn-spinner" />
                ) : (
                  <IoSaveOutline />
                )}
              </span>
            </Link>
            <Link
              bsPrefix=" "
              to="/contracts"
              className="myBt cancel fillBtn ctrlKeyPrevent"
              title={tr["Cancel"]}
              ref={closeButton}
            >
              <IoClose />
            </Link>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default reduxForm({
  form: "CreateContract",
})(HeaderSec);
