import React, { useState, useEffect } from "react";
import View from "../../View";

const PageContainerComponent = ({ item, ...props }) => {
  const { buttonProp, data } = props?.buttonProp;
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    if (
      buttonProp?.container_name?.id === item?.containerName &&
      buttonProp?.page?.id
    ) {
      setLoader(true);
      setTimeout(() => {
        setLoader(false);
      }, 300);
    }
  }, [buttonProp?.page?.id]);

  const renderContainer = () => {
    if (
      buttonProp?.container_name?.id === item?.containerName &&
      buttonProp?.page?.id
    ) {
      return (
        <View
          parantValues={{ ...data, ...props?.defaultValues }}
          pageId={buttonProp?.page?.id}
          isChildPage={true}
        />
      );
    } else if (item?.page && item?.page?.id) {
      return (
        <View
          parantValues={{ ...data, ...props?.defaultValues }}
          pageId={item?.page?.id}
          isChildPage={true}
        />
      );
    }
  };
  return <>{!loader && renderContainer()}</>;
};

export default PageContainerComponent;
