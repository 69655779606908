
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, {Component} from 'react';
import {Button, ButtonToolbar} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import moment from 'moment';
import axios from 'axios';
import {change, Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import swal from 'sweetalert'
import datetimeConvertor from "../../../../ISO8601converter";
import { GLOBAL } from "_Globals";

import {loadChangeEditData} from '../../../../actions/changeManagement/changeManagementAction.js';
import {loadBreakFixAuditLogDetails} from '../../../../actions/breakFix/breakFixAuditLogAction';
import { loadStatusBasedHamburgerOptions } from '../../../../actions/common/loadStatusBasedHamburgerOptions';
import { loadTimelineData } from '../../../../actions/common/commonApisActions.js';
import {_dateTimeField,_latentDateTimeField} from '../../../common/ReduxFormFields/formFields';
import {IoClose} from "react-icons/io5";

import configureStore from '../../../../store/configureStore';
import Swal from 'sweetalert2';
import Cookies from "universal-cookie";
const cookies = new Cookies();

let timezone = cookies.get("gph");
if (timezone) timezone = timezone.replace("s:", "");
if (timezone) timezone = timezone.substring(0, timezone.lastIndexOf("."));
timezone = timezone.split("~");
timezone = timezone[3];

let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
//console.log("homepage::::"+homepagelocation);
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();
let excludeSecondFormat = dateformat.slice(0, 10).toUpperCase() + ' HH:mm';
const store = configureStore();

const validate = (valuesObj, props) => {
    let values = JSON.stringify(valuesObj);
    values = JSON.parse(values);
    if(values.startDate){
      values.startDate = moment(values.startDate).format(jwtdf+" HH:mm:ss");
    }
    if(values.endDate){
      values.endDate = moment(values.endDate).format(jwtdf+" HH:mm:ss");
    }
    if(values.expectedStartDate){
      values.expectedStartDate = moment(values.expectedStartDate).format(jwtdf+" HH:mm:ss");
    }
    if(values.expectedEndDate){
      values.expectedEndDate = moment(values.expectedEndDate).format(jwtdf+" HH:mm:ss");
    }

    let errors = {};

    if (!values.expectedStartDate) {
      errors.expectedStartDate = ' ';
    }
    if (!values.expectedEndDate) {
      errors.expectedEndDate = ' ';
    }
    if (values.expectedStartDate || values.expectedEndDate) {
      if (moment.tz(values.expectedStartDate,jwtdf+" HH:mm:ss",timezone).isBefore(moment().tz(timezone))) {
        if (props.editChangeInitialData.type !== '30') {
          errors.expectedStartDate = 'Scheduled Start Date should be atleast today\'s date.';
        }
      }
      if (moment.tz(values.expectedEndDate,jwtdf+" HH:mm:ss",timezone).isBefore(moment().tz(timezone))) {
        if (props.editChangeInitialData.type !== '30') {
          errors.expectedEndDate = 'Scheduled End Date should be atleast today\'s date.';
        }
      }
      if (moment.tz(values.expectedStartDate,jwtdf+" HH:mm:ss",timezone).isAfter(moment.tz(values.expectedEndDate,jwtdf+" HH:mm:ss",timezone))) {
        errors.expectedStartDate = 'Scheduled Start date should be same or fall before End date.';
      }
      if(values.expectedStartDate=="Invalid date")
          {
              errors.expectedStartDate = 'Invalid date.';
          }
          if(values.expectedEndDate=="Invalid date")
          {
              errors.expectedEndDate = 'Invalid date.';
          }
    }
      if(values.downtimeRad && values.downtimeRad === 'Y'){
        if (!values.startDate) {
          errors.startDate = ' ';
        }
        if (!values.endDate) {
          errors.endDate = ' ';
        }
        if(moment(values.startDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid()){
          values.startDate = moment(values.startDate).format(jwtdf+' HH:mm:ss');
        }
        if(moment(values.endDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid()){
          values.endDate = moment(values.endDate).format(jwtdf+' HH:mm:ss');
        }
        if(moment(values.expectedStartDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid()){
          values.expectedStartDate = moment(values.expectedStartDate).format(jwtdf+' HH:mm:ss');
        }
        if(moment(values.expectedEndDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid()){
          values.expectedEndDate = moment(values.expectedEndDate).format(jwtdf+' HH:mm:ss');
        }

        if (values.startDate || values.endDate) {
          let downtimeStartDate, downtimeEndTime, expectedStartDate, expectedEndDate, currentDate;
          if(moment(values.startDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid()){
            downtimeStartDate = moment.tz(values.startDate, timezone).format(jwtdf+' HH:mm:ss');
          }else{
            downtimeStartDate = values.startDate;
          }
          if(moment(values.endDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid()){
            downtimeEndTime = moment.tz(values.endDate, timezone).format(jwtdf+' HH:mm:ss');
          }else{
            downtimeEndTime = values.endDate;
          }
          if(moment(values.expectedStartDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid()){
            expectedStartDate = moment.tz(values.expectedStartDate, timezone).format(jwtdf+' HH:mm:ss');
          }else{
            expectedStartDate = values.expectedStartDate;
          }
          if(moment(values.expectedEndDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid()){
            expectedEndDate = moment.tz(values.expectedEndDate, timezone).format(jwtdf+' HH:mm:ss');
          }else{
            expectedEndDate = values.expectedEndDate;
          }
          downtimeStartDate = moment.tz(downtimeStartDate, jwtdf+' HH:mm:ss', timezone).format(excludeSecondFormat);
          downtimeEndTime = moment.tz(downtimeEndTime, jwtdf+' HH:mm:ss', timezone).format(excludeSecondFormat);
          expectedStartDate = moment.tz(expectedStartDate, jwtdf+' HH:mm:ss', timezone).format(excludeSecondFormat);
          expectedEndDate = moment.tz(expectedEndDate , jwtdf+' HH:mm:ss', timezone).format(excludeSecondFormat);
          currentDate = moment.tz(timezone).format(excludeSecondFormat);
            if(props.editChangeInitialData.type !== '30'){
             if(moment.tz(downtimeStartDate,jwtdf+" HH:mm:ss",timezone).isBefore(moment.tz(currentDate,jwtdf+" HH:mm:ss",timezone))){
               errors.startDate = 'Downtime Start Date should be atleast today\'s date.';
             }
             if(moment.tz(downtimeEndTime,jwtdf+" HH:mm:ss",timezone).isBefore(moment.tz(currentDate,jwtdf+" HH:mm:ss",timezone))){
               errors.endDate = 'Downtime End Date should be atleast today\'s date.';
             }
          }
          if(moment.tz(downtimeStartDate,jwtdf+" HH:mm:ss",timezone).isBefore(moment.tz(expectedStartDate,jwtdf+" HH:mm:ss",timezone)) || moment.tz(downtimeStartDate,jwtdf+" HH:mm:ss",timezone).isAfter(moment.tz(expectedEndDate,jwtdf+" HH:mm:ss",timezone))){
            errors.startDate = 'Downtime Start Date should be same or between Scheduled start and end date.';
          }
          if(moment.tz(downtimeEndTime,jwtdf+" HH:mm:ss",timezone).isBefore(moment.tz(expectedStartDate,jwtdf+" HH:mm:ss",timezone)) || moment.tz(downtimeEndTime,jwtdf+" HH:mm:ss",timezone).isAfter(moment.tz(expectedEndDate,jwtdf+" HH:mm:ss",timezone))){
            errors.endDate = 'Downtime End Date should be same or between Scheduled start and end date.';
          }
          if(moment.tz(downtimeStartDate,jwtdf+" HH:mm:ss",timezone).isAfter(moment.tz(downtimeEndTime,jwtdf+" HH:mm:ss",timezone))){
            errors.startDate = 'Downtime start date should be same or fall before end date.';
          }
          if(values.startDate=="Invalid date")
          {
              errors.startDate = 'Invalid date.';
          }
          if(values.endDate=="Invalid date")
          {
              errors.endDate = 'Invalid date.';
          }
        }
      }
    return errors;
};

let Replan = class Replan extends Component {
    constructor(props) {
        super(props);
        this.state = {
          selectedOption: 'N'
        };
        this.disableEnableDate = this.disableEnableDate.bind(this);
    }
    disableEnableDate(value) {
        this.setState({selectedOption: value});
    }
    handleFormSubmit(props) {
        let ref = this;
        let expectedStartDateValid='';
        let expectedEndDateValid ='';
        let startDateValid ='';
        let endDateValid ='';
        let dateformat = this.props.dateformat;
        dateformat = dateformat.substring(0,10) +" HH:mm:ss";
        if (typeof props.expectedStartDate == 'object') {
          let a = props.expectedStartDate._d;
          expectedStartDateValid = moment(a).format(dateformat);
        } else {
          expectedStartDateValid = props.expectedStartDateVal;
        }
        if (typeof props.expectedEndDate == 'object') {
          let a = props.expectedEndDate._d;
          expectedEndDateValid = moment(a).format(dateformat);
        } else {
          expectedEndDateValid = props.expectedEndDate;
        }
        if (props.downtimeRad == 'Y') {
          if (typeof props.startDate == 'object') {
            let a = props.startDate._d;
            startDateValid = moment(a).format(dateformat);
          } else {
            startDateValid = props.expectedStartDateVal;
          }
          if (typeof props.endDate == 'object') {
            let a = props.endDate._d;
            endDateValid = moment(a).format(dateformat);
          } else {
            endDateValid = props.expectedEndDate;
          }
        }
        return axios.patch(GLOBAL.changeEditUrl+this.props.changeId, {
                "expEndDate": expectedEndDateValid,
                "expStartDate": expectedStartDateValid,
                "dEndDate": endDateValid,
                "dStartDate": startDateValid,
                "status":'30',
                "modifiedBy":"",
                "modifiedByName":"",
                "downtimeRequired":this.state.selectedOption
            }).then(function (response) {
                    if(response.status === 200){
                    store.dispatch({ type: 'CHANGE_EDIT_DATA_LOAD_SUCCESS', editChangeInitialData: {} });
                    ref.props.loadChangeEditData(ref.props.changeId);
                    ref.props.loadStatusBasedHamburgerOptions('ChangeManagement',response.data.status,response.data.changeId);
                    ref.props.changeActionView('');
                    }
                }).catch(function (error) {
                  Swal.fire(this.props.translator['error saving data.']);
                });
    }
  render(){
    if(this.props.user_id!=this.props.editChangeInitialData.changeManagementIndividual&&this.props.user_id!=this.props.editChangeInitialData.implementationIndividual&&this.props.user_id!=this.props.editChangeInitialData.requesterId)
        swal({
          text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
          button: this.props.translator['OK'],
        });
    else{
        const { handleSubmit, pristine, submitting} = this.props;
       return (<form>
         <div className="hambTab">
          <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
            <ul>
              <li>
              <button
                  type='button'
                  title="Minimize the right panel"
                  bsClass=""
                  bsStyle=""
                  className="closerightPanelBtn"
                  onClick={() => {
                  this.props.rightEditPanel(false);
                  }}
              >
                  <IoClose/>
              </button>
              </li>
            </ul>
          </div>
          <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Re-Plan']}</div>
        </div>
        <div className='rBoxGap'>
            <Form.Group className="form-group">
               <Form.Label bsClass="">{this.props.translator['Scheduled Start Date']}</Form.Label>
                <div className="dateTimeSdiv">
                   <Field name="expectedStartDate" component={_dateTimeField} className="form-control dateTimePickerField" formType="create" defaultValue={moment().tz(timezone)}/>
                </div>
            </Form.Group>
            <Form.Group className="form-group">
            <Form.Label bsClass="">{this.props.translator['Scheduled End Date']}</Form.Label>
                <div className="dateTimeSdiv">
                    <Field name="expectedEndDate" component={_dateTimeField} className="form-control dateTimePickerField" formType="create" defaultValue={moment().tz(timezone)}/>
                </div>
            </Form.Group>
            <div className="black">{this.props.translator['Downtime Required']}</div>
            <Form.Group className="margin-0 form-group">
                <Field name="downtimeRad" component="input" type="radio" inline value="Y"checked={this.state.selectedOption === 'Y'} onClick={() => {
                    this.disableEnableDate('Y');
                    this.props.dispatch(change("replanForm", "startDate", moment().tz(timezone).format(jwtdf+' hh:mm A')));
                    this.props.dispatch(change("replanForm", "endDate", moment().tz(timezone).format(jwtdf+' hh:mm A')));
                    this.props.dispatch(change("replanForm", "downtimeRad", 'Y'));}}/>
                {this.props.translator['Yes']}
                &nbsp;&nbsp;&nbsp;
                <Field defaultChecked component="input" type="radio" name="downtimeRad" inline value="N" checked={this.state.selectedOption === 'N'} onClick={() => {
                    this.disableEnableDate('N');
                    this.props.dispatch(change("replanForm", "downtimeRad", 'N'));
                    this.props.dispatch(change("replanForm", "startDate", ''));
                    this.props.dispatch(change("replanForm", "endDate", ''));}}/>
                    {this.props.translator['No']}
            </Form.Group>
            {this.state.selectedOption==='Y'?
            <Form.Group className="form-group">
               <Form.Label bsClass="">{this.props.translator['Downtime Start Date']}</Form.Label>
                  <div className="dateTimeSdiv">
                    <Field name="startDate" component={_latentDateTimeField} className="form-control dateTimePickerField" formType="create" disabledValue={this.state.selectedOption === 'N'}/>
                  </div>
            </Form.Group>
            :
            <Form.Group className="form-group">
               <Form.Label bsClass="">{this.props.translator['Downtime Start Date']}</Form.Label>
                  <div className="dateTimeSdiv">
                    <Field name="startDate" component={_dateTimeField} className="form-control dateTimePickerField" formType="createdowntime" disabledValue={this.state.selectedOption === 'N'}/>
                  </div>
            </Form.Group>}
            {this.state.selectedOption==='Y'?
            <Form.Group className="form-group">
               <Form.Label bsClass="">{this.props.translator['Downtime End Date']}</Form.Label>
                   <div className="dateTimeSdiv datePckBot">
                      <Field name="endDate" component={_latentDateTimeField} className="form-control dateTimePickerField" formType="create" disabledValue={this.state.selectedOption === 'N'}/>
                   </div>
            </Form.Group>
            :
            <Form.Group className="form-group">
              <Form.Label bsClass="">{this.props.translator['Downtime End Date']}</Form.Label>
                <div className="dateTimeSdiv datePckBot">
                    <Field name="endDate" component={_dateTimeField} className="form-control dateTimePickerField" formType="createdowntime" disabledValue={this.state.selectedOption === 'N'}/>
                </div>
            </Form.Group>}
            <ButtonToolbar className="margin-t-10">
                <Button className="rgSidrkBtn smallBtn" bsStyle="primary" type="submit" onClick={handleSubmit(this.handleFormSubmit.bind(this))} disabled={submitting}>{this.props.translator['Save']}</Button>
            </ButtonToolbar>
        </div>
        </div>
    </form>);
    }
  }
}
Replan = reduxForm({
    form: 'replanForm',
    validate
  })(Replan);
const mapStateToProps = ({editChangeInitialData}) => {
    return {editChangeInitialData};
};
export default connect(mapStateToProps, {loadChangeEditData, loadBreakFixAuditLogDetails, loadStatusBasedHamburgerOptions, loadTimelineData})(Replan);
