
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Table } from "react-bootstrap";
import Loader from "../../../common/loaders/ListLoader";

const renderInputVariables = (inputs) => {
  return inputs.map((value, index) => {
    return (
      <tr key={index}>
        <td>{value.taskCode}</td>
        <td>{value.outputKey}</td>
        <td>{value.outputValue}</td>
      </tr>
    );
  });
};

export const InputVariables = (props) => {
  const {
    inputs,
    classNameVar1Input,
    classNameVar2Input,
    sortFunInput,
    errorFetchingInputs,
    fetchingInputs,
    translator,
  } = props;

  if (errorFetchingInputs) {
    return (
      <div>
        <h4>error loading input variables</h4>
      </div>
    );
  }
  if (inputs == "No Record Found") {
    return (
      <div>
        <h4>{translator["No input variables."]}</h4>
      </div>
    );
  }

  if (fetchingInputs) {
    console.log("fetched IP var");
    return (
      <div>
        {/* <Spinner spinnerName="three-bounce" /><h4>loading...</h4> */}
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <div className="f-size-13">
        <Table
          bordered
          condensed
          hover
          responsive
          className="tableView sortImg"
        >
          <thead>
            <tr>
              <th
                width="30%"
                className={classNameVar1Input}
                onClick={() => {
                  sortFunInput("class1");
                }}
              >
                Task
              </th>
              <th
                width="50%"
                className={classNameVar2Input}
                onClick={() => {
                  sortFunInput("class2");
                }}
              >
                Variable
              </th>
              <th width="20%">Value</th>
            </tr>
          </thead>
          <tbody>{renderInputVariables(inputs)}</tbody>
        </Table>
      </div>
    </div>
  );
};
