
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector, connect } from "react-redux";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import { _inputField, _dropDown } from "../../../common/ReduxFormFields/CategoryBoard/formFields";
import { _checkbox, _number } from "../../../common/formFields";
import { useQuery } from "@apollo/client";
import { getSingleLanguage ,masterlanguages} from "../../graphQl/GraphqlQueries";

// const languages = [
//   { field1Value: "en", field1Key: "English" },
//   { field1Value: "de", field1Key: "German" },
//   { field1Value: "fr", field1Key: "French" },
//   { field1Value: "es", field1Key: "Spanish" },
// ];
const statusOptions = [
  { text: "Select", value: "" },
  { text: "Active", value: "Active" },
  { text: "Inactive", value: "Inactive" },
];

const validate = (values, props) => {
  const errors = {};
  if (!values.language) errors.language = "Please choose language";
  if (!values.key) errors.key = " ";

  if (!values.value)
    errors.value = "Please fill value";

  

  if (!values.status)
    errors.status = "Please choose escalation status";

  return errors;
};
const AddLanguageForm = (props) => {
  const dispatch = useDispatch();
  const translator = useSelector((state) => state.spcmReducer.tr);
  const [languageList, setLanguageList]=useState([]);

  if(props?.params && props?.params?.languageId){
     const { data, loading  } = useQuery(getSingleLanguage, {
       variables: { id: props?.params.languageId },onError(error){
        console.log(error);
       },onCompleted(data){
        console.log(data);
        const language = data?.getLanguage;
        Object.keys(language).forEach(res=>{
          dispatch(change("AddLanguageForm", res, language[res]));
        })
       }
     });
  }

  const { data, loading  } = useQuery(masterlanguages, {
    onCompleted(data){
     let languageArray=data?.getAllMasterLanguages ?? [];
     let languageListArray=languageArray.map(language=>{
        return {
          "field1Key":language.name,
          "field1Value":language.code
        }
     })
     setLanguageList([...languageListArray])
    }
  });


  return (
    <>
      <Row>
        <Col md={6} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>
              <span className="rStar"></span>
              {"Language"}
            </Form.Label>
            <div className="heightdvTypehd infoBtnDiv">
              <Field
                component={_dropDown}
                name="language"
                options={languageList}
                className="form-control"
              />
            </div>
          </Form.Group>
        </Col>
        <Col md={6} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>
              <span className="rStar"></span>
              {"Key"}
            </Form.Label>
            <div className="heightdvTypehd">
              <Field
                component={_inputField}
                name="key"
                className="form-control"
              ></Field>
            </div>
          </Form.Group>
        </Col>
        <Col md={6} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>
              <span className="rStar"></span>
              {"Value"}
            </Form.Label>
            <div className="heightdvTypehd">
              <Field
                component={_inputField}
                name="value"
                className="form-control"
              ></Field>
            </div>
          </Form.Group>
        </Col>

        <Col md={6} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>
              <span className="rStar"></span>
              {translator["Status"]}
            </Form.Label>
            <div className="heightdvTypehd infoBtnDiv">
              <Field
                component={_dropDown}
                name="status"
                options={statusOptions}
                className="form-control"
              />
            </div>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({});

export default reduxForm({
  form: "AddLanguageForm",
  destroyOnUnmount: true,
  validate,
  enableReinitialize: true,
})(connect(mapStateToProps)(AddLanguageForm));
