
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
//below URL used for testing purpose
//const breakFixUrl = 'https://xsm-f.mybluemix.net/api/SX_FAVOURITEOFFERINGs/';
import { GLOBAL }  from '../../components/Globals';
//let breakFixUrl =`https://xsm-f.mybluemix.net/api/breakfixitem?breakfixitemId=`;
//let breakFixUrl=`https://xsm-f.mybluemix.net/groupListSelfService?companyId=2932&group=ResolvedER`
export function loadBreakFixRelatedWorkDetailsSuccess(breakFixRelatedWorkDetails){
	//alert('in success')
	return {
		type: 'LOAD_BREAKFIXRELATEDWORKDETAILS_SUCCESS',
		breakFixRelatedWorkDetails
	};
}

export function loadBreakFixRelatedWorkDetailsFailure(errorResponse){
	return {
		type: 'LOAD_BREAKFIXRELATEDWORKDETAILS_FAILURE',
		errorResponse
	};
}
export function loadBreakFixRelatedWorkDetails(filterval,breakFixId,module,isRelationReq='',itmId='',status='',pageSize=10,currentPage=0){
	console.log('action called and parameter is ',filterval,breakFixId,module)
	console.log("module ",module)
	
	if(breakFixId=="0")
	breakFixId="";

	return(dispatch) =>{
		//alert("EditGroupIdAction123"+breakFixId);
		           if(!(breakFixId == -1)){
			            dispatch({type: "MENU_LOADER_IN_PROGRESS"});
			    }
				//alert("hiaaa::::");
				let relatedurl="";
				let axioscall="";

				if(module=="Breakfix")
				relatedurl=GLOBAL.breakFixRelationQuickViewURL;
				if(module=="Fullfilment")
				relatedurl=GLOBAL.fulfillmentRelationListURL;
				if(module=="Investigation")
				relatedurl=GLOBAL.investigationQuickViewURL;
				//relatedurl=GLOBAL.investigationQuickViewURL+"?searchByList="+filterval+"&patternList=like&multipleValueList="+breakFixId
				if(module=="ChangeManagement")
				relatedurl=GLOBAL.changeQuickViewURL;
				if(module=="Breakfix"){
					let str = {};
					str.searchByList = filterval+((status != '')?",status" :"");
					str.patternList = "like";
					str.multipleValueList = ""+breakFixId+((status != '')?("|"+status) :"");
					str.orderBy="desc";
					str.sortBy="breakfixId";
					str.currentPage = parseInt(currentPage,10);
					if(pageSize!='-1'){
					   str.size=parseInt(pageSize,10);	
					}	
					if(isRelationReq != '')	str.isRelationsNotRequired = isRelationReq;
					if(itmId != '') str.itemId = itmId.toString();
				//	str = JSON.stringify(str);
				console.log("strrrrng....",JSON.stringify(str))
					axioscall = axios.post(relatedurl, str);
				console.log("?searchByList="+filterval+"&patternList=like&multipleValueList="+breakFixId);
				}else if(module=="Fullfilment"){
					let str={};
					str.searchByList=filterval;
					str.isNestedRequired="N";
					str.patternList="like";
					str.multipleValueList=""+breakFixId;
					//str = JSON.stringify(str);	
					str.size = 99;
					str.currentPage = parseInt(currentPage,10);
					if(pageSize!='-1'){
						str.size=parseInt(pageSize,10);	
					}	
					axioscall=axios.post(relatedurl,str);
					console.log("?searchByList="+filterval+
					"&isNestedRequired=N&patternList=like&multipleValueList="+breakFixId);
				}else if(module=="Investigation"){
					// ?searchByList="+filterval+"&patternList=like&
					// multipleValueList="+breakFixId
					let str={};
					str.searchByList=filterval;
					str.patternList="like";
					str.multipleValueList=""+breakFixId;
					str.size = 99;
					str.currentPage = parseInt(currentPage,10);
					if(pageSize!='-1'){
						str.size=parseInt(pageSize,10);	
					}			
					axioscall=axios.post(relatedurl,str);
				}else if(module=="ChangeManagement"){
					// "?searchByList="+filterval+"&patternList=like&multipleValueList="+breakFixId
					let str={};
					str.searchByList=filterval;
					str.patternList="like";
					str.multipleValueList=""+breakFixId;
					str.size = 99;
					str.currentPage = parseInt(currentPage,10);
					if(pageSize!='-1'){
						str.size=parseInt(pageSize,10);	
					}					
					axioscall=axios.post(relatedurl,str);
				}else{
					axioscall=axios.post(relatedurl);
				}
				axioscall.then((breakFixRelatedWorkDetails)=>{
						if(!breakFixRelatedWorkDetails){//alert('not breakFix')
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							dispatch({type:'MENU_LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
							throw Error(breakFixRelatedWorkDetails.statusText);
						}
						return breakFixRelatedWorkDetails;
					})
					.then((breakFixRelatedWorkDetails) =>{
						console.log('yes it is');
						dispatch(loadBreakFixRelatedWorkDetailsSuccess(breakFixRelatedWorkDetails));
						dispatch({type:'MENU_LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
					})
					.catch((err) => {
						console.log(err);
						 dispatch({type:'MENU_LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
					});
					//console.log("not calling loadBreakFixEditDetails");

	};
}

export function addToRelatedWorkItmList(bfItmObj){
	//alert("addStatusToSelectedInvestigationStatusList"+status)
	return{
		type: 'ADD_TO_RELATEDWORK_CHECK_LIST',
		payload: bfItmObj
	};
}

export function removeFromRelatedWorkItmList(bfItmObj){
	return{
		type: 'REMOVE_FROM_RELATEDWORK_CHECK_LIST',
		payload: bfItmObj
	};
}

export function resetRelatedWorkItmList(){
	return{
		type: 'RESET_RELATEDWORK_CHECK_LIST'
	};
}

export function addAllToRelatedWorkItmList(bfItmObj){
	//alert("addStatusToSelectedInvestigationStatusList"+status)
	// console.log("bfItmObj ",bfItmObj)
	return{
		type: 'ADD_ALL_TO_RELATEDWORK_CHECK_LIST',
		payload: bfItmObj
	};
}

export function clearBreakFixRelatedWorkDetails(){
	return(dispatch) =>{
		let obj = {
			data: {
				data: [],
				meta: null
			}
		};
		dispatch(loadBreakFixRelatedWorkDetailsSuccess(obj));
	};
}