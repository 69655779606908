
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";
import { Button } from '@progress/kendo-react-buttons';
import { MdAddBox, MdBrush, MdSettings, MdLayers, MdLibraryBooks, MdDeviceHub } from 'react-icons/md';
import { PiDatabaseBold } from 'react-icons/pi';

export default function RightPanelIcons({setPanelShow,setRightPanelShow,isRightPanelShow}) {
    return (
        <div className="rightPanelIcons">
            <ul>
                <li><Link to="javascript:void(0)" onClick={() => { setPanelShow(0); setRightPanelShow(!isRightPanelShow) }}>
                    <Button title='Selectors' fillMode="outline"><MdBrush /></Button>
                </Link></li>
                <li><Link to="javascript:void(0)" onClick={() => { setPanelShow(1); setRightPanelShow(!isRightPanelShow) }}>
                    <Button title='Properties' fillMode="outline"><MdSettings /></Button>
                </Link></li>
                <li><Link to="javascript:void(0)" onClick={() => { setPanelShow(2); setRightPanelShow(!isRightPanelShow) }}>
                    <Button title='Layers' fillMode="outline"><MdLayers /></Button>
                </Link></li>
                <li><Link to="javascript:void(0)" onClick={() => { setPanelShow(3); setRightPanelShow(!isRightPanelShow) }}>
                    <Button title='Components' fillMode="outline"><MdAddBox /></Button>
                </Link></li>
                {/* <li><Link to="javascript:void(0)" onClick={() => { setPanelShow(4); setRightPanelShow(!isRightPanelShow) }}>
                    <Button title='Pages' fillMode="outline"><MdLibraryBooks /></Button>
                </Link></li> */}
                <li><Link to="javascript:void(0)" onClick={() => { setPanelShow(4); setRightPanelShow(!isRightPanelShow) }}>
                    <Button title='Data Query' fillMode="outline"><PiDatabaseBold /></Button>
                </Link></li>
            </ul>
        </div>
    );
}