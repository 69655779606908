
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Alert } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {
  loadSlaModule,
  loadSlaStatus,
  loadSlaService,
  loadSlaImpacted,
  loadSlaType,
  loadSlaTarget,
  loadSlaWrkSchedule,
  loadSlaHolSchedule,
} from "../../../actions/sla/lovAction";
import { loadCICompany } from "../../../actions/cmdb/lovAction";
import { connect } from "react-redux";
import axios from "axios";
import { bindActionCreators } from "redux";
import DropdownPlusTypeahead from "../common/dropdownPlusTypeahead";

import {
  Field,
  reduxForm,
  formValueSelector,
  change,
  SubmissionError,
} from "redux-form";
// import Select from 'react-select';
import { GLOBAL } from "../../../components/Globals";
import LoadingIcons from "react-loading-icons";

class XsmSlaDefForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchResultsDiv: false,
      slaName: "",
      slaCompanyName: "",
      slaModuleName: "",
      slaStatus: "",
      slaServiceName: "",
      slaImpactedName: "",
      slaSrviceName: "",
      slaType: "",
      slaTargetFor: "",
      slaTargetTime: "",
      slaWorkdaySchedule: "",
      slaHolidaySchedule: "",
      slaRetroactiveRequired: "No",
      radioButton: 0,
      module: 2,
      disable: 1,
      onCreateUsingSelect: "",
      CompanyName: [],
      inputValueCompany: "",
      typedValue: "",
      moduleName: "",
      impactOnStart:'',
      descriptionCount: 4000,
      describeIssue: "",
    };
    GLOBAL.serviceBased = true;
    this.validateField = this.validateField.bind(this);
    this.companySelection = this.companySelection.bind(this);
    this.setCompanErrorColor = this.setCompanErrorColor.bind(this);
    this.onCompanyInputChange = this.onCompanyInputChange.bind(this);
    this.onCrossClickCompany = this.onCrossClickCompany.bind(this);
    this.onSiteChanged = this.onSiteChanged.bind(this);
    this.impactStart = this.impactStart.bind(this);
    const api = axios.create({
      headers: { Pragma: "no-cache", "Cache-control": "no-store" },
    });
  }
  searchResults(event) {
    this.setState({ searchResultsDiv: true });
  }
  callbackFun(id) {
    this.setState({ searchResultsDiv: id });
  }

  componentWillMount() {
    this.props.loadCICompany();
    this.props.loadSlaModule("SLADEF", "Module");
    this.props.loadSlaStatus("SLADEF", "Status");
    this.props.loadSlaType("SLADEF", "Type");
    //this.props.loadSlaService();
    //this.props.loadSlaTarget();
    //this.props.loadSlaWrkSchedule(user_id);
    //this.props.loadSlaHolSchedule();
    //loadSlaCompany,loadSlaModule,loadSlaStatus,loadSlaService,loadSlaType,loadSlaTarget,
    //loadSlaWrkSchedule,loadSlaHolSchedule,loadSlaDefEditDetails
  }
  // componentDidUpdate(prevProps) {
  //   // Typical usage (don't forget to compare props):
  //   // if (this.props.userID !== prevProps.userID) {
  //   //   this.fetchData(this.props.userID);
  //   // }
  //   console.log("COMPONENTWILLRECI123444444444444444",prevProps);
  // }
  onCreateUsingSelect(event) {
    // GLOBAL.ciClass="";
    // this.props.dispatch(change('changeForm', 'ciClassName', GLOBAL.ciClass));
    // console.log("isse blank hua");
    this.setState({ onCreateUsingSelect: event.target.value });
    this.setState({ disable: 0 });
    if (this.state.onCreateUsingSelect == "service") {
      this.setState({ slaServiceName: "" });
      GLOBAL.slaServiceNameErrorColor = "";
      this.props.dispatch(
        change("XsmSlaDefForm", "slaServiceActualName", "select")
      );
      this.props.dispatch(change("XsmSlaDefForm", "slaServiceName", ""));
      this.props.dispatch(
        change("XsmSlaDefForm", "slaImpactedActualName", "select")
      );
      this.props.dispatch(change("XsmSlaDefForm", "slaImpactedName", ""));
    }
    if (this.state.onCreateUsingSelect == "impactedCI") {
      this.setState({ slaImpactedName: "" });
      GLOBAL.slaImpactedNameErrorColor = "";
      this.props.dispatch(
        change("XsmSlaDefForm", "slaServiceActualName", "select")
      );
      this.props.dispatch(change("XsmSlaDefForm", "slaServiceName", ""));
      this.props.dispatch(
        change("XsmSlaDefForm", "slaImpactedActualName", "select")
      );
      this.props.dispatch(change("XsmSlaDefForm", "slaImpactedName", ""));
    }
  }
  validateField(fieldType, event) {
    switch (fieldType) {
      case "slaName":
        if (event.currentTarget.defaultValue == "") {
          this.setState({ slaName: "error" });
          GLOBAL.slaNameErrorColor = "";
        } else {
          this.setState({ slaName: "" });
          GLOBAL.slaNameErrorColor = "";
        }
        break;
      case "slaCompanyName":
        if (event.target.value == "") {
          this.setState({ slaCompanyName: "error" });
          GLOBAL.slaCompanyNameErrorColor = "";
        } else {
          this.setState({ slaCompanyName: "" });
          GLOBAL.slaCompanyNameErrorColor = "";
        }
        break;
      case "slaModuleName":
        if (event.target.value == 0 || event.target.value == "") {
          this.setState({ slaModuleName: "error" });
          GLOBAL.slaModuleNameErrorColor = "";
        } else {
          this.setState({ slaModuleName: "" });
          GLOBAL.slaModuleNameErrorColor = "";
        }
        break;
      case "slaStatusValue":
        if (event.target.value == 0 || event.target.value == "") {
          this.setState({ slaStatus: "error" });
          GLOBAL.slaStatusErrorColor = "";
        } else {
          this.setState({ slaStatus: "" });
          GLOBAL.slaStatusErrorColor = "";
        }
        break;
      case "slaServiceName":
        if (
          (event.target.value == 0 || event.target.value == "") &&
          this.state.onCreateUsingSelect == "service"
        ) {
          this.setState({ slaServiceName: "error" });
          GLOBAL.slaServiceNameErrorColor = "";
        } else {
          this.setState({ slaServiceName: "" });
          GLOBAL.slaServiceNameErrorColor = "";
        }
        break;
      // case 'slaSrviceName':
      //   if ((event.target.value == 0 || event.target.value == "")&&(this.state.onCreateUsingSelect=="service")) {

      //     this.setState({ slaSrviceName: 'error' });
      //     GLOBAL.slaSrviceNameErrorColor = "";
      //   } else {
      //     this.setState({ slaSrviceName: '' });
      //     GLOBAL.slaSrviceNameErrorColor = "";
      //   }
      //   break;
      case "slaImpactedName":
        if (
          (event.target.value == 0 || event.target.value == "") &&
          this.state.onCreateUsingSelect == "impactedCI"
        ) {
          this.setState({ slaImpactedName: "error" });
          GLOBAL.slaImpactedNameErrorColor = "";
        } else {
          this.setState({ slaImpactedName: "" });
          GLOBAL.slaImpactedNameErrorColor = "";
        }
        break;
      case "slaType":
        if (event.target.value == 0 || event.target.value == "") {
          this.setState({ slaType: "error" });
          GLOBAL.slaTypeErrorColor = "";
        } else {
          this.setState({ slaType: "" });
          GLOBAL.slaTypeErrorColor = "";
        }
        break;
      case "slaTargetFor":
        if (event.target.value == 0 || event.target.value == "") {
          this.setState({ slaTargetFor: "error" });
          GLOBAL.slaTargetForErrorColor = "";
        } else {
          this.setState({ slaTargetFor: "" });
          GLOBAL.slaTargetForErrorColor = "";
        }
        break;
      case "slaTargetTime":
        if (event.target.value == 0 || event.target.value == "") {
          this.setState({ slaTargetTime: "error" });
          GLOBAL.slaTargetTimeErrorColor = "";
        } else {
          this.setState({ slaTargetTime: "" });
          GLOBAL.slaTargetTimeErrorColor = "";
        }
        break;

      case "slaWorkdaySchedule":
        if (event.target.value == 0 || event.target.value == "") {
          this.setState({ slaWorkdaySchedule: "error" });
          GLOBAL.slaWorkdayScheduleErrorColor = "";
        } else {
          this.setState({ slaWorkdaySchedule: "" });
          GLOBAL.slaWorkdayScheduleErrorColor = "";
        }
        break;

      case "slaRetroactiveRequired":
        if (event.target.value == 0 || event.target.value == "") {
          this.setState({ slaRetroactiveRequired: "error" });
          GLOBAL.slaRetroactiveRequiredErrorColor = "";
        } else {
          this.setState({ slaRetroactiveRequired: "" });
          GLOBAL.slaRetroactiveRequiredErrorColor = "";
        }
        break;
        case "impactOnStart":
          if (event.target.value == 0 || event.target.value == "") {
            this.setState({ impactOnStart: "error" });
            GLOBAL.slaImpactErrorColor = "";
          } else {
            this.setState({ impactOnStart: "" });
            GLOBAL.slaImpactErrorColor = "";
          }
          break;
      default:
    }
  }
  setCompanErrorColor() {
    console.log("company Error", this.state.CompanyName.length);
    if (this.state.CompanyName.length === 0) {
      this.setState({ slaCompanyName: "error" });
    } else {
      this.setState({ slaCompanyName: "" });
    }
    GLOBAL.slaCompanyNameErrorColor = "";
  }
  companySelection(companySelelected) {
    console.log("companySelelected", companySelelected);
    if (companySelelected.length > 0) {
      this.setState({ slaCompanyName: "", CompanyName: companySelelected });

      // this.setDropDownValue('supportPIndividual',)
      this.props.dispatch(
        change(
          "XsmSlaDefForm",
          "slaCompanyActualName",
          companySelelected[0].label
        )
      );
      this.props.dispatch(
        change("XsmSlaDefForm", "slaCompanyName", companySelelected[0].id)
      );
      this.props.loadSlaService(companySelelected[0].id);

      this.props.loadSlaImpacted(companySelelected[0].id);
      //this.props.loadSlaWrkSchedule("1");
      //this.props.loadSlaHolSchedule("1");
      //alert(i.target.value)
      this.props.loadSlaWrkSchedule(companySelelected[0].id);
      this.props.loadSlaHolSchedule(companySelelected[0].id);
      this.setState({ inputValueCompany: companySelelected[0].label });
      // this.setState({typedValue:companySelelected[0].label});
    } else {
      this.props.loadSlaService(0);
      this.props.loadSlaImpacted(0);
      this.props.loadSlaWrkSchedule(0);
      this.props.loadSlaHolSchedule(0);
      this.setState({ slaCompanyName: "error", CompanyName: [] });
      this.props.dispatch(change("XsmSlaDefForm", "slaCompanyActualName", ""));
      this.props.dispatch(change("XsmSlaDefForm", "slaCompanyName", 0));
    }
  }
  onCompanyInputChange(val) {
    console.log("typedValue", val);
    this.setState({ inputValueCompany: val });
    this.setState({ typedValue: val });
  }
  onCrossClickCompany() {
    this.props.dispatch(change("XsmSlaDefForm", "slaCompanyActualName", ""));
    this.props.dispatch(change("XsmSlaDefForm", "slaCompanyName", 0));
    this.setState({ inputValueCompany: "", CompanyName: [] });
    this.props.loadSlaService(0);
    this.props.loadSlaImpacted(0);
    this.props.loadSlaWrkSchedule(0);
    this.props.loadSlaHolSchedule(0);
    this.setState({ typedValue: "" });
  }
  renderCompany(slaCompanyData) {
    //console.log(classData)
    if (slaCompanyData.length == 0 || slaCompanyData.length == undefined) {
      return null;
    }
    return slaCompanyData.map((objectData) => {
      return (
        <option value={objectData.field1Value}>{objectData.field1Key}</option>
      );
    });
  }
  onSiteChanged(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  impactStart(e) {
    console.log("impact dd", e.target.value);
    this.setState({impactOnStart:e.target.value})
  }
  renderModule(slaModuleData) {
    //console.log(classData)
    if (slaModuleData.length == 0 || slaModuleData.length == undefined) {
      return null;
    }
    return slaModuleData.map((objectData) => {
      return (
        <option value={objectData.field1Value}>{objectData.field1Key}</option>
      );
    });
  }

  renderStatus(slaStatusData) {
    //console.log(classData)
    if (slaStatusData.length == 0 || slaStatusData.length == undefined) {
      return null;
    }
    return slaStatusData.map((objectData) => {
      if (objectData.field1Key != "Active") {
        return (
          <option value={objectData.field1Value}>{objectData.field1Key}</option>
        );
      }
    });
  }

  renderImpacted(slaServiceData) {
    console.log("slaServiceData", slaServiceData);
    //alert(slaServiceData.length);
    if (slaServiceData == null) {
      return null;
    }
    if (slaServiceData.length == 0 || slaServiceData.length == undefined) {
      return null;
    }
    return slaServiceData.map((objectData) => {
      return <option value={objectData.CI_ID}>{objectData.CI_NAME}</option>;
    });
  }

  renderService(slaServiceData) {
    console.log("slaServiceData", slaServiceData);
    //alert(slaServiceData.length);
    if (slaServiceData == null) {
      return null;
    }
    if (slaServiceData.length == 0 || slaServiceData.length == undefined) {
      return null;
    }
    return slaServiceData.map((objectData) => {
      return (
        <option value={objectData.OFFERING_ID}>
          {objectData.OFFERING_NAME}
        </option>
      );
    });
  }
  renderTarget(slaTargetData) {
    //console.log(classData)
    //alert(slaServiceData.length);
    if (slaTargetData == null) {
      return null;
    }
    if (slaTargetData.length == 0 || slaTargetData.length == undefined) {
      return null;
    }
    return slaTargetData.map((objectData) => {
      return (
        <option value={objectData.field1Value}>{objectData.field1Key}</option>
      );
    });
  }

  renderType(slaTypeData) {
    //console.log(classData)
    if (slaTypeData.length == 0 || slaTypeData.length == undefined) {
      return null;
    }
    return slaTypeData.map((objectData) => {
      return (
        <option value={objectData.field1Value}>{objectData.field1Key}</option>
      );
    });
  }

  renderWorkdaySchedule(workdayScheduleData) {
    console.log(workdayScheduleData);
    if (workdayScheduleData == null) {
      console.log("workdayScheduleData not exist");
    } else {
      if (workdayScheduleData.data == null || workdayScheduleData.data == "") {
        return null;
      } else {
        return workdayScheduleData.data.map((objectData) => {
          return (
            <option value={objectData.scheduleId}>
              {objectData.scheduleName}
            </option>
          );
        });
      }
    }
  }

  renderHolidaySchedule(holidayScheduleData) {
    if (holidayScheduleData == null) {
      console.log("holidayScheduleData not exist");
    } else {
      if (holidayScheduleData.data == null || holidayScheduleData.data == "") {
        return null;
      } else {
        return holidayScheduleData.data.map((objectData) => {
          return (
            <option value={objectData.scheduleId}>
              {objectData.scheduleName}
            </option>
          );
        });
      }
    }
  }

  setCompany(i) {
    //alert(i.target.value)
    if (i.target.value == "" || i.target.value == undefined) {
      this.props.loadSlaService("0");
      this.props.loadSlaImpacted("0");
      this.props.loadSlaWrkSchedule("0");
      this.props.loadSlaHolSchedule("0");
    } else {
      // axios.get(GLOBAL.ciNameDetailsUrl+'?searchByList=STATUS&multipleValueList=7&companyId='+i.target.value ).then((response)=>{
      //   //alert("hi")
      //  this.renderImpacted(response);
      // });
      this.props.loadSlaService(i.target.value);
      console.log("this.props.loadSlaImpacted", this.props);
      this.props.loadSlaImpacted(i.target.value);
      //this.props.loadSlaWrkSchedule("1");
      //this.props.loadSlaHolSchedule("1");
      //alert(i.target.value)
      this.props.loadSlaWrkSchedule(i.target.value);
      this.props.loadSlaHolSchedule(i.target.value);
      //loadSlaWrkSchedule,loadSlaHolSchedule
    }
  }

  onDescriptionChange(e){
    this.setState({describeIssue:e.target.value, descriptionCount:4000-e.target.value.length})
}
  setModule(i) {
    let module = i.target[i.target.selectedIndex].text;
    //alert(module);
    this.setState({
      moduleName: module,
    });

    // this.setState({ slaImpactedName: '' });
    // this.setState({ slaServiceName: '' });

    if (i.target[i.target.selectedIndex].text == "select") {
      this.setState({
        module: 2,
        radioButton: 0,
        disable: 1,
      });
    }
    if (i.target[i.target.selectedIndex].text == "Change") {
      module = "ChangeManagement";
      this.setState({
        radioButton: 1,
        module: 1,
        disable: 0,
        onCreateUsingSelect: "impactedCI",
      });
      this.props.dispatch(
        change("XsmSlaDefForm", "slaServiceActualName", "select")
      );
      this.props.dispatch(change("XsmSlaDefForm", "slaServiceName", ""));
      this.props.dispatch(
        change("XsmSlaDefForm", "slaImpactedActualName", "select")
      );
      this.props.dispatch(change("XsmSlaDefForm", "slaImpactedName", ""));
    }
    if (i.target[i.target.selectedIndex].text == "Fulfillment") {
      module = "Fullfilment";
      this.setState({
        radioButton: 0,
        module: 2,
        disable: 0,
        onCreateUsingSelect: "service",
      });
      this.props.dispatch(
        change("XsmSlaDefForm", "slaServiceActualName", "select")
      );
      this.props.dispatch(change("XsmSlaDefForm", "slaServiceName", ""));
      this.props.dispatch(
        change("XsmSlaDefForm", "slaImpactedActualName", "select")
      );
      this.props.dispatch(change("XsmSlaDefForm", "slaImpactedName", ""));
    }

    if (i.target[i.target.selectedIndex].text == "Incident") {
      module = "Breakfix";
      this.setState({
        radioButton: 1,
        module: 0,
        disable: 1,
      });
      this.props.dispatch(
        change("XsmSlaDefForm", "slaServiceActualName", "select")
      );
      this.props.dispatch(change("XsmSlaDefForm", "slaServiceName", ""));
      this.props.dispatch(
        change("XsmSlaDefForm", "slaImpactedActualName", "select")
      );
      this.props.dispatch(change("XsmSlaDefForm", "slaImpactedName", ""));
    }
    if (i.target[i.target.selectedIndex].text == "Problem") {
      module = "Investigation";
      this.setState({
        radioButton: 1,
        module: 1,
        disable: 0,
        onCreateUsingSelect: "impactedCI",
      });
      this.props.dispatch(
        change("XsmSlaDefForm", "slaServiceActualName", "select")
      );
      this.props.dispatch(change("XsmSlaDefForm", "slaServiceName", ""));
      this.props.dispatch(
        change("XsmSlaDefForm", "slaImpactedActualName", "select")
      );
      this.props.dispatch(change("XsmSlaDefForm", "slaImpactedName", ""));
    }
    if (i.target[i.target.selectedIndex].text == "Service Availability") {
      module = "Servive Availability";
      this.setState({
        radioButton: 0,
        module: 0,
        disable: 0,
      });
      this.props.dispatch(
        change("XsmSlaDefForm", "slaServiceActualName", "select")
      );
      this.props.dispatch(change("XsmSlaDefForm", "slaServiceName", ""));
      this.props.dispatch(
        change("XsmSlaDefForm", "slaImpactedActualName", "select")
      );
      this.props.dispatch(change("XsmSlaDefForm", "slaImpactedName", ""));
    }
    //alert(i.target.value)
    if (i.target.value == "" || i.target.value == undefined) {
      this.props.loadSlaTarget("0");
    } else {
      this.props.loadSlaTarget(module, "Target");
    }
  }

  render() {
    const { slaRetroactiveRequired,impactOnStart } = this.state;
    let companyData = [];
    console.log(slaCompanyName, GLOBAL.slaCompanyNameErrorColor);
    //alert(this.props.slaStatus.length)
    this.props.ciCompany.forEach((item, i) => {
      let obj = {};
      obj.id = item.field1Value;
      obj.label = item.field1Key;
      companyData.push(obj);
    });
    if (
      this.props.ciCompany.length == 0 ||
      this.props.ciCompany.length == undefined ||
      this.props.slaModule.length == 0 ||
      this.props.slaModule.length == undefined ||
      this.props.slaStatus.length == 0 ||
      this.props.slaStatus.length == undefined ||
      this.props.slaType.length == 0 ||
      this.props.slaType.length == undefined
    ) {
      return (
        <LoadingIcons.Bars height="5em" stroke="transparent" fill="#06bcee" />
      );
    }
    //,this.props.slaModule,this.props.slaStatus,this.props.slaService,this.props.slaType,this.props.slaTarget
    let {
      error,
      handleSubmit,
      pristine,
      reset,
      submitting,
      slaName,
      slaCompanyName,
      slaModuleName,
      slaStatusValue,
      slaServiceName,
      slaType,
      slaTargetFor,
      slaTargetTime,
      slaWorkdaySchedule,
      slaHolidaySchedule,
      
    } = this.props;
    let slaSchedule = "";
    if (this.props.slaStatusValue == 15) {
      slaSchedule = null;
    } else {
      slaSchedule = (
        <Row>
          <Col md={6}>
            <Form.Group>
              <Form.Label bsClass="">
                <span className="rStar"></span>
                {this.props.translator["Work Schedule"]}
              </Form.Label>
              <Field
                name="slaWorkdaySchedule"
                className={`form-control ${
                  this.state.slaWorkdaySchedule == ""
                    ? GLOBAL.slaWorkdayScheduleErrorColor
                    : this.state.slaWorkdaySchedule
                }`}
                component="select"
                onBlur={(event) => {
                  this.validateField("slaWorkdaySchedule", event);
                }}
                onChange={(event) => {
                  this.props.dispatch(
                    change(
                      "XsmSlaDefForm",
                      "slaWorkdayScheduleName",
                      event.target[event.target.selectedIndex].text
                    )
                  );
                }}
              >
                <option value="">select</option>
                {/*<option value="001">select1..</option>*/}
                {this.renderWorkdaySchedule(this.props.slaWorkdaySchedules)}
              </Field>
            </Form.Group>
          </Col>
          <Col md={6}>
            {/*<Form.Group validationState={this.state.slaHolidaySchedule == '' ? GLOBAL.slaHolidayScheduleErrorColor : this.state.slaHolidaySchedule}>
					<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Holiday Schedule']}</Form.Label>*/}
            <Form.Group>
              <Form.Label bsClass="">
                {this.props.translator["Holiday Schedule"]}
              </Form.Label>
              <Field
                name="slaHolidaySchedule"
                className="form-control"
                component="select"
                onChange={(event) => {
                  this.props.dispatch(
                    change(
                      "XsmSlaDefForm",
                      "slaHolidayScheduleName",
                      event.target[event.target.selectedIndex].text
                    )
                  );
                }}
              >
                <option value="">select</option>
                {/*<option value="002">select1..</option>*/}
                {this.renderHolidaySchedule(this.props.slaHolidaySchedules)}
              </Field>
            </Form.Group>
          </Col>
        </Row>
      );
    }

    // console.log('****************', {state: this.state, props: this.props});
    return (
      <div>
        {error == undefined || error == null || error == "" ? null : (
          <Alert variant="danger" className="errorMessageSec">
            <p>{error}</p>
          </Alert>
        )}
        {/*Error message section, please use classes like success, danger, warning, info*/}
        {/*<Alert variant="danger" className="errorMessageSec">
						<p>The form has missing required fields, please revalidate the highlighted fields and update the form.</p>
					</Alert>*/}
        <div className="borderForm bFormDiv">
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label bsClass="">
                  <span className="rStar"></span>
                  {this.props.translator["Name"]}
                </Form.Label>
                <Field
                  name="slaName"
                  className={`form-control ${
                    this.state.slaName == ""
                      ? GLOBAL.slaNameErrorColor
                      : this.state.slaName
                  }`}
                  component="input"
                  onBlur={(event) => {
                    this.validateField("slaName", event);
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label bsClass="">
                  <span className="rStar"></span>
                  {this.props.translator["Consumer Company"]}
                </Form.Label>
                <Field
                  component={DropdownPlusTypeahead}
                  onSelection={this.companySelection}
                  setErrorColor={this.setCompanErrorColor}
                  name="slaCompanyName"
                  id="company"
                  className={`form-control`}
                  options={companyData}
                  selectedOptions={this.state.CompanyName}
                  onInputChange={this.onCompanyInputChange}
                  onCrossClick={this.onCrossClickCompany}
                  inputValue={this.state.inputValueCompany}
                  typedValue={this.state.typedValue}
                  isError={`${
                    this.state.slaCompanyName == ""
                      ? GLOBAL.slaCompanyNameErrorColor
                      : this.state.slaCompanyName
                  }`}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label bsClass="">
                  <span className="rStar"></span>
                  {this.props.translator["Module"]}
                </Form.Label>
                <Field
                  name="slaModuleName"
                  className={`form-control ${
                    this.state.slaModuleName == ""
                      ? GLOBAL.slaModuleNameErrorColor
                      : this.state.slaModuleName
                  }`}
                  component="select"
                  onBlur={(event) => {
                    this.validateField("slaModuleName", event);
                  }}
                  onChange={(event) => {
                    this.setState({ slaRetroactiveRequired: "No" });
                    this.setModule(event);
                    this.props.dispatch(
                      change(
                        "XsmSlaDefForm",
                        "slaModuleActualName",
                        event.target[event.target.selectedIndex].text
                      )
                    );
                  }}
                >
                  <option value="">select</option>
                  {this.renderModule(this.props.slaModule)}
                </Field>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label bsClass="">
                  <span className="rStar"></span>
                  {this.props.translator["Status"]}
                </Form.Label>
                <Field
                  name="slaStatusValue"
                  className={`form-control ${
                    this.state.slaStatus == ""
                      ? GLOBAL.slaStatusErrorColor
                      : this.state.slaStatus
                  }`}
                  component="select"
                  onBlur={(event) => {
                    this.validateField("slaStatusValue", event);
                  }}
                  onChange={(event) => {
                    this.props.dispatch(
                      change(
                        "XsmSlaDefForm",
                        "slaStatusActualName",
                        event.target[event.target.selectedIndex].text
                      )
                    );
                  }}
                >
                  <option value="">select</option>
                  {this.renderStatus(this.props.slaStatus)}
                </Field>
              </Form.Group>
            </Col>
          </Row>
          {/* {this.state.module==0?
         <Row>
							<Col md={4}>
								<Form.Group className="radInpCs margin-0"  onChange={this.onCreateUsingSelect.bind(this)}>
									
									<Field name="service" type="radio" component="input" value="service"/>{this.props.translator['Service']}
									
									
									<Field name="service"  type="radio" component="input" value="impactedCI"/>{this.props.translator['Impacted CI']}
									
								</Form.Group>
							</Col>
						</Row>
  :null} */}
          <Row>
            {/* {this.state.module==0||this.state.module==2?
        <Col md={6}>
          
        <Form.Group validationState={this.state.slaServiceName == '' ? GLOBAL.slaServiceNameErrorColor : this.state.slaServiceName}>
                <Form.Label bsClass="">{this.state.onCreateUsingSelect=="service"?<span className="rStar" />:null}{this.props.translator['Service']}</Form.Label>
                <Field name="slaServiceName" className="form-control"  disabled={ this.state.disable==1? true : false}  component="select" onBlur={(event) => { this.validateField('slaServiceName', event) }} onChange={(event) => { this.props.dispatch(change("XsmSlaDefForm", "slaServiceActualName", event.target[event.target.selectedIndex].text)); }}>
                  <option value="">select</option>
                  {this.renderService(this.props.slaService)}
                </Field>
              </Form.Group>
          
          </Col>
           :null} */}
            {this.state.radioButton == 1 ? (
              <div>
                {/* <Col md={6}>
          <Form.Group validationState={this.state.slaImpactedName == '' ? GLOBAL.slaImpactedNameErrorColor : this.state.slaImpactedName}>
            <Form.Label bsClass="">{this.state.onCreateUsingSelect=="impactedCI"?<span className="rStar" />:null}{this.props.translator['Impacted CI']}</Form.Label>
            <Field name="slaImpactedName" className="form-control" disabled={this.state.disable==1? true : false} component="select" onBlur={(event) => { this.validateField('slaImpactedName', event) }} onChange={(event) => { this.props.dispatch(change("XsmSlaDefForm", "slaImpactedActualName", event.target[event.target.selectedIndex].text)); }}>
              <option value="">select</option>
              {this.renderImpacted(this.props.slaImpacted)}
            </Field>
          </Form.Group>
        </Col> */}
              </div>
            ) : (
              <Col md={6}>
                <Form.Group>
                  <Form.Label bsClass="">
                    <span className="rStar"></span>
                    {this.props.translator["Type"]}
                  </Form.Label>
                  <Field
                    name="slaType"
                    className={`form-control ${
                      this.state.slaType == ""
                        ? GLOBAL.slaTypeErrorColor
                        : this.state.slaType
                    }`}
                    component="select"
                    onBlur={(event) => {
                      this.validateField("slaType", event);
                    }}
                    onChange={(event) => {
                      this.props.dispatch(
                        change(
                          "XsmSlaDefForm",
                          "slaTypeName",
                          event.target[event.target.selectedIndex].text
                        )
                      );
                    }}
                  >
                    <option value="">select</option>
                    {this.renderType(this.props.slaType)}
                  </Field>
                </Form.Group>
              </Col>
            )}
            {this.state.module == 1 ? (
              <Col md={6}>
                <Form.Group>
                  <Form.Label bsClass="">
                    <span className="rStar"></span>
                    {this.props.translator["Type"]}
                  </Form.Label>
                  <Field
                    name="slaType"
                    className={`form-control ${
                      this.state.slaType == ""
                        ? GLOBAL.slaTypeErrorColor
                        : this.state.slaType
                    }`}
                    component="select"
                    onBlur={(event) => {
                      this.validateField("slaType", event);
                    }}
                    onChange={(event) => {
                      this.props.dispatch(
                        change(
                          "XsmSlaDefForm",
                          "slaTypeName",
                          event.target[event.target.selectedIndex].text
                        )
                      );
                    }}
                  >
                    <option value="">select</option>
                    {this.renderType(this.props.slaType)}
                  </Field>
                </Form.Group>
              </Col>
            ) : null}
            {this.state.module == 0 ? (
              <Col md={6}>
                <Form.Group>
                  <Form.Label bsClass="">
                    <span className="rStar"></span>
                    {this.props.translator["Type"]}
                  </Form.Label>
                  <Field
                    name="slaType"
                    className={`form-control ${
                      this.state.slaType == ""
                        ? GLOBAL.slaTypeErrorColor
                        : this.state.slaType
                    }`}
                    component="select"
                    onBlur={(event) => {
                      this.validateField("slaType", event);
                    }}
                    onChange={(event) => {
                      this.props.dispatch(
                        change(
                          "XsmSlaDefForm",
                          "slaTypeName",
                          event.target[event.target.selectedIndex].text
                        )
                      );
                    }}
                  >
                    <option value="">select</option>
                    {this.renderType(this.props.slaType)}
                  </Field>
                </Form.Group>
              </Col>
            ) : null}

            <Col md={6}>
              <Form.Group>
                <Form.Label bsClass="">
                  <span className="rStar"></span>
                  {this.props.translator["Target For"]}
                </Form.Label>
                <Field
                  name="slaTargetFor"
                  className={`form-control ${
                    this.state.slaTargetFor == ""
                      ? GLOBAL.slaTargetForErrorColor
                      : this.state.slaTargetFor
                  }`}
                  component="select"
                  onBlur={(event) => {
                    this.validateField("slaTargetFor", event);
                  }}
                  onChange={(event) => {
                    this.props.dispatch(
                      change(
                        "XsmSlaDefForm",
                        "slaTargetForName",
                        event.target[event.target.selectedIndex].text
                      )
                    );
                  }}
                >
                  <option value="">select</option>
                  {this.renderTarget(this.props.slaTarget)}
                </Field>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group>
                <Form.Label bsClass="">
                  <span className="rStar"></span>
                  {this.props.translator["Target Time(Mins)"]}
                </Form.Label>
                <Field
                  name="slaTargetTime"
                  className={`form-control ${
                    this.state.slaTargetTime == ""
                      ? GLOBAL.slaTargetTimeErrorColor
                      : this.state.slaTargetTime
                  }`}
                  component="input"
                  onBlur={(event) => {
                    this.validateField("slaTargetTime", event);
                  }}
                ></Field>
              </Form.Group>
            </Col>

            {!(this.props.slaStatusValue == 15) && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label bsClass="">
                    <span className="rStar"></span>
                    {this.props.translator["Work Schedule"]}
                  </Form.Label>
                  <Field
                    name="slaWorkdaySchedule"
                    className={`form-control ${
                      this.state.slaWorkdaySchedule == ""
                        ? GLOBAL.slaWorkdayScheduleErrorColor
                        : this.state.slaWorkdaySchedule
                    }`}
                    component="select"
                    onBlur={(event) => {
                      this.validateField("slaWorkdaySchedule", event);
                    }}
                    onChange={(event) => {
                      this.props.dispatch(
                        change(
                          "XsmSlaDefForm",
                          "slaWorkdayScheduleName",
                          event.target[event.target.selectedIndex].text
                        )
                      );
                    }}
                  >
                    <option value="">select</option>
                    {/*<option value="001">select1..</option>*/}
                    {this.renderWorkdaySchedule(this.props.slaWorkdaySchedules)}
                  </Field>
                </Form.Group>
              </Col>
            )}

            {!(this.props.slaStatusValue == 15) && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label bsClass="">
                    {this.props.translator["Holiday Schedule"]}
                  </Form.Label>
                  <Field
                    name="slaHolidaySchedule"
                    className="form-control"
                    component="select"
                    onChange={(event) => {
                      this.props.dispatch(
                        change(
                          "XsmSlaDefForm",
                          "slaHolidayScheduleName",
                          event.target[event.target.selectedIndex].text
                        )
                      );
                    }}
                  >
                    <option value="">select</option>
                    {this.renderHolidaySchedule(this.props.slaHolidaySchedules)}
                  </Field>
                </Form.Group>
              </Col>
            )}
            <Col md={6}>
              <Form.Group>
                <Form.Label bsClass="">
                  {this.props.translator["Retroactive Start"]}
                </Form.Label>

                <div className="clear">
                  <Field
                    name="slaRetroactiveRequired"
                    type="radio"
                    component="input"
                    value="Yes"
                    className="mx-2"
                    checked={slaRetroactiveRequired === "Yes"}
                    onChange={(event) => {
                      this.onSiteChanged(event);
                    }}
                  />
                  Yes
                  <Field
                    name="slaRetroactiveRequired"
                    type="radio"
                    component="input"
                    value="No"
                    className="mx-2"
                    checked={slaRetroactiveRequired === "No"}
                    onChange={(event) => {
                      this.onSiteChanged(event);
                    }}
                  />
                  No
                </div>
              </Form.Group>
            </Col>
            <Col md={6}>&nbsp;</Col>
            <Col md={6}>
              <Form.Group>
                {(this.state.moduleName == "Problem" ||
                  this.state.moduleName == "Incident") &&
                  slaRetroactiveRequired === "Yes" && (
                    <div className="form-group">
                       <span className="rStar"></span>
                      <Form.Label bsClass="">
                        <label>Impact on Start</label>
                      </Form.Label>
                      <Form.Group  className="btMargin10">
                        <Field
                          component="select"
                          className={`form-control ${
                            this.state.impactOnStart == ""
                              ? GLOBAL.slaImpactErrorColor
                              : this.state.impactOnStart
                          }`}
                          name="impactOnStart"
                          value={impactOnStart}
                          onBlur={(event) => {
                            this.validateField("impactOnStart", event);
                          }}
                          onChange={(event) => {
                            this.impactStart(event);
                          }}
                        >
                          <option value="">select</option>
                          <option value="Always">Always</option>
                          <option value="Downgrade">
                            Priority Downgrade
                          </option>
                        </Field>
                      </Form.Group>
                    </div>
                  )}
              </Form.Group>
            </Col>
            <Col>
            <Form.Group className='form-group'>
               <Form.Label bsPrefix=' '>{this.props.translator["Description"]} </Form.Label>
               <Field 
               name="description" 
               component="textarea" 
               className={`form-control`} 
               maxLength="4000" 
               rows="4" 
               onChange={(e)=>{this.onDescriptionChange(e)}}
               />
               <p className="charLeft" style={{textAlign:"right","fontSize":"11px"}}>({this.props.translator['Characters Remaining']}: {(this.state.descriptionCount)}/4000)</p>
               </Form.Group>
            </Col>
            {/* <Col md={6}>
              <Form.Group validationState={this.state.slaRetroactiveRequired == '' ? GLOBAL.slaRetroactiveRequiredErrorColor : this.state.slaRetroactiveRequired}>
                <Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Retroactive Required']}</Form.Label>
                <Field name="slaRetroactiveRequired" className="form-control" component="select" onBlur={(event) => { this.validateField('slaRetroactiveRequired', event) }} onChange={(event) => { this.props.dispatch(change("XsmSlaDefForm", "slaRetroactiveRequired", event.target[event.target.selectedIndex].text)); }}>
                  <option value="">select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Field>
              </Form.Group>
              </Col> */}
            {/* {this.state.radioButton==1?
              <Col md={6}>
              <Form.Group validationState={this.state.slaType == '' ? GLOBAL.slaTypeErrorColor : this.state.slaType}>
                <Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Type']}</Form.Label>
                <Field name="slaType" className="form-control" component="select" onBlur={(event) => { this.validateField('slaType', event) }} onChange={(event) => { this.props.dispatch(change("XsmSlaDefForm", "slaTypeName", event.target[event.target.selectedIndex].text)); }}>
                  <option value="">select</option>
                  {this.renderType(this.props.slaType)}
                </Field>
              </Form.Group>
            </Col>:null} */}
          </Row>
        </div>
      </div>
    );
  }
}

XsmSlaDefForm = reduxForm({
  form: "XsmSlaDefForm",
  fields: [
    "slaName",
    "slaCompanyName",
    "slaModuleName",
    "slaStatusValue",
    "slaServiceName",
    "slaImpactedName",
    "slaType",
    "slaTargetFor",
    "slaTargetTime",
    "slaWorkdaySchedule",
    "slaHolidaySchedule",
    "slaRetroactiveRequired",
    "impactOnStart"
    
  ],
  initialValues: {
    slaName: "",
    slaCompanyName: "",
    slaModuleName: "",
    slaStatusValue: "",
    slaImpactedName: "",
    slaServiceName: "",
    slaType: "",
    slaTargetFor: "",
    slaTargetTime: "",
    slaWorkdaySchedule: "",
    slaHolidaySchedule: "",
    slaRetroactiveRequired: "No",
    impactOnStart:""
    
  },
  destroyOnUnmount: false,
})(XsmSlaDefForm);

let selector = formValueSelector("XsmSlaDefForm");
XsmSlaDefForm = connect((state) => {
  let slaStatusValue = selector(state, "slaStatusValue");
  return {
    slaStatusValue,
  };
})(XsmSlaDefForm);

export function mapStateToProps({
  ciCompany,
  slaModule,
  slaStatus,
  slaService,
  slaImpacted,
  slaType,
  slaTarget,
  slaWorkdaySchedules,
  slaHolidaySchedules,
  spcmReducer,
}) {
  //console.log(ciInd);
  //console.log(ciSupCompany);
  return {
    ciCompany,
    slaModule,
    slaStatus,
    slaService,
    slaImpacted,
    slaType,
    slaTarget,
    slaWorkdaySchedules,
    slaHolidaySchedules,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadCICompany,
      loadSlaModule,
      loadSlaStatus,
      loadSlaService,
      loadSlaImpacted,
      loadSlaType,
      loadSlaTarget,
      loadSlaWrkSchedule,
      loadSlaHolSchedule,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(XsmSlaDefForm);
//export default XsmSLADefForm
