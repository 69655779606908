
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Row, Col, Nav, Tab, Carousel } from 'react-bootstrap';

import ListLoader from "../../common/loaders/ListLoader";
import ListViewCategoryServices from './listViewCategoryServices';
import { loadHamburgerCategoryList } from '_Actions/homepage/hamburgercatAction';
import { loadCatSelling } from '_Actions/homepage/categoryofferingAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { GLOBAL } from '_Globals';
import PropTypes from 'prop-types';
import {Scrollbars} from 'react-custom-scrollbars';


let controlsFlag = false;
class SxHomeTabOffering extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      indicators: false,
      direction: null,
      activetab: '',
      flag: 0,
      firstIndexCallFlag: 0,
      cateDataId: '',
      pageSize: 5
    };
    GLOBAL.startindex = 1;
    GLOBAL.endindex = 5;
    this.showcatservicelist = this.showcatservicelist.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.setPageSize = this.setPageSize.bind(this);
  }
  componentWillMount() {
    this.props.loadHamburgerCategoryList();
    //  this.props.loadCatSelling(1632);
  }
  showcatservicelist(id) {
    this.setState({ cateDataId: id });
    this.props.loadCatSelling(id, GLOBAL.startindex, GLOBAL.endindex);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.homeactivetab == "catView" && nextProps.homeactivetab != this.props.homeactivetab) {
      this.setState({ pageSize: 5 });
  	}
  }


  renderactuallist(startpoint, endpoint, catdata) {
    return catdata.slice(startpoint, endpoint).map((catObj, index) => {
      return (
        <Nav.Item as={'li'} bsPrefix={this.state.activetab == catObj.categoryName ? "active" : ' '}><Nav.Link eventKey={catObj.categoryName} onClick={() => { this.showcatservicelist(catObj.id) }}>{catObj.categoryName}</Nav.Link></Nav.Item>
      );
    });
  }

  renderCategory(catdata) {
    let size = '';
    if (catdata) {
      size = catdata.length;
    }
    let indents = [];
    for (let i = 0; i < size; i++) {
      indents.push(<Nav.Item as="li" bsPrefix=' '><Nav.Link bsPrefix=' ' eventKey={catdata[i].categoryName} onKeyUp={() => { this.showcatservicelist(catdata[i].id); }} onClick={() => { this.showcatservicelist(catdata[i].id); }}>{catdata[i].categoryName}</Nav.Link></Nav.Item>);
    }
    return (
      <Scrollbars className="customScroll" autoHide={true} hideTracksWhenNotNeeded={true} autoHideTimeout={1000}>
        <Nav bsPrefix=' ' as="ul" className="nav-tabs nav-tabs-responsive">{indents}</Nav>
      </Scrollbars>
    );
  }

  setPageSize(data) {
    this.setState({pageSize: data})
    this.props.loadCatSelling(this.state.cateDataId,1, data);
    
  }
  renderCategorytab(catdata, pageSize) {
    if (catdata.length > 0 && this.state.firstIndexCallFlag == 0) {
      this.props.loadCatSelling(catdata[0].id, GLOBAL.startindex, GLOBAL.endindex);
      this.setState({ cateDataId: catdata[0].id });
      this.setState({ firstIndexCallFlag: 1 });
    }
    if (this.props.showLoader2) {
      return (
        <ListLoader />
      );
    } else {
      let catactualtab = "";
      return catdata.map((catObj, index) => {
        catactualtab = <ListViewCategoryServices cateDataId={this.state.cateDataId} pageSize={pageSize} setPageSize = {this.setPageSize}/>;
        return (
          <Tab.Pane eventKey={catObj.categoryName}>{catactualtab}</Tab.Pane>
        );
      });
    }


  }

  handleSelect(key) {
    this.setState({ activetab: key, pageSize: 5 });
  }

  render() {
    if (this.props.hamburgerCategoryList.length > 0 && this.state.flag == 0) {
      this.setState({ activetab: this.props.hamburgerCategoryList[0].categoryName });
      this.setState({ flag: 1 });

    }
    return (

      <Tab.Container activeKey={this.state.activetab} onSelect={this.handleSelect}>
        <Row id="SxHomeTablistcate" className="sxhometabact sxLiView" >
          <Col md={12}>
            {this.renderCategory(this.props.hamburgerCategoryList)}
          </Col>
          <Col md={12}>
            <Tab.Content className="tabouter-border">
              {this.renderCategorytab(this.props.hamburgerCategoryList, this.state.pageSize)}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    );
  }
}


SxHomeTabOffering.propTypes = {
  loadCatSelling: PropTypes.func,
  // hamburgerCategoryList: PropTypes.object,
  loadHamburgerCategoryList: PropTypes.func,
  showLoader2: PropTypes.bool,
  // catSelling: PropTypes.object
};

function mapStateToProps({hamburgerCategoryList,catSelling,showLoader2}){
  return{ hamburgerCategoryList,catSelling,showLoader2:showLoader2.loading};
}

function mapDispatchToProps(dispatch){
 return bindActionCreators({ loadHamburgerCategoryList,loadCatSelling}, dispatch);
}
export default connect(mapStateToProps,mapDispatchToProps)(SxHomeTabOffering);
