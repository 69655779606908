
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import "react-querybuilder/dist/query-builder.css";
import { useDispatch, useSelector} from 'react-redux';
import { useGetAllServicesQuery, useGetAllCIsQuery, 
         useGetAllClassesQuery, useGetAllCimGroupsQuery, useGetAllSupportGroupsQuery, 
         useGetAllUsersQuery, useGetAllCompaniesQuery, useGetAllReportedThroughQuery,
         useGetAllUrgencyAndImpantQuery } from "../../reducers/NLP-Related-Query/WorkItemBoardQuerries";
import { tempFields, initialQuery } from "../../components/common/helper";
import { getQueryBasedOnUserText, saveRetrivedBreakfixRecords } from "../../components/common/helperForD2CQueryBuilder";

import { displayMessage } from "../../components/common/componentsForQueryBuilder";
import NlpSearchBarComponent from "../../components/common/WorkItemBoard/QueryComponent/NlpSearchBarComponent";
import QueryActionComponent from "../../components/common/WorkItemBoard/QueryComponent/QueryActionComponent";
import CustomQueryBuilder from "../../components/common/WorkItemBoard/QueryComponent/CustomQueryBuilder";
import Cookies from "universal-cookie";
const cookies = new Cookies();

let extractedCookiesValue = cookies.get("gph");
if (extractedCookiesValue) extractedCookiesValue = extractedCookiesValue.replace("s:", "");
if (extractedCookiesValue){
  extractedCookiesValue = extractedCookiesValue.substring(0, extractedCookiesValue.lastIndexOf("."));
}
extractedCookiesValue = extractedCookiesValue.split("~");

const languageSelected = extractedCookiesValue[20];
const companyId = extractedCookiesValue[48];

const ConditionBuilder = (props) => {
  const dispatch = useDispatch();
  const query = useSelector(state => state.queryData);
  const translator = useSelector(state => state.spcmReducer.tr);
  const [userQuery, setUserQuery] = useState('');
  const [fields, setFields] = useState(tempFields);
  const [isResult, setResult] = useState(false);
  const [maxLength, setMaxLength] = useState(1024);

  const handleOnUserQueryChange = (query) => {
    setUserQuery(query.target.value);
  }

  const retriveQuerybasedOnUserQuery = async () => {
    if(userQuery.trim().length===0){
       displayMessage(translator['Please enter a valid search string'], translator['OK']);
       return;
    }
    try {
      dispatch({type: 'PRODUCED_QUERY', payload: initialQuery});
      dispatch({type: 'FINAL_QUERY', payload: initialQuery});
      dispatch({ type: 'ON_AI_SEARCH_LOADER' });
      const retrivedQuery = await getQueryBasedOnUserText('breakfix', props.companyId, userQuery);
      dispatch({ type: 'OFF_AI_SEARCH_LOADER' });
      if(retrivedQuery){
        dispatch({type: 'PRODUCED_QUERY', payload: retrivedQuery});
        dispatch({type: 'FINAL_QUERY', payload: retrivedQuery});
      } else {
        displayMessage(translator['Failed to retrive querry, Please try again'], translator['OK']);
      }
    }catch(error){
      dispatch({ type: 'OFF_AI_SEARCH_LOADER' });
      console.error('Error fetching query:', error);
    }  
  }

  const retrieveBreakfixRecordOnQuery = async () => {
    if(query.rules.length === 0){
      displayMessage(translator['Please create some rules to retrieve the records'], translator['OK']);
      return;
    }
    try {
      saveRetrivedBreakfixRecords(dispatch, query, 0, 10, 'asc');
    }catch(error){
      console.error('error : ',error);
    } 
  };

  // const { data: queryRawData } = useGetQueryRawDataQuery({module_name: props.moduleName}, { skip: !props.moduleName});

  const { data: cimGroups} = useGetAllCimGroupsQuery({query: JSON.stringify({ moduleName: props.moduleName, companyId: props.companyId, ruleFor: 'criticalIncidentManager'}) }, { skip: !props.moduleName});
  
  useEffect(() => { 
    if(typeof cimGroups === 'undefined' || cimGroups.length === 0 || cimGroups === null){
      dispatch(({ type: 'SET_ALL_CIM_GROUPS', payload: [] }));
      return;
    }
    const cimGroupList = cimGroups.body.map(retrivedCimGroup => { 
        return {id: retrivedCimGroup.responseEntityId, label: retrivedCimGroup.responseEntity} });
    dispatch({ type: 'SET_ALL_CIM_GROUPS', payload: cimGroupList });
  },[cimGroups])

  const { data: supportGroups } = useGetAllSupportGroupsQuery(props.companyId);

  useEffect(() => { 
    if(typeof supportGroups === 'undefined' || supportGroups.length === 0 || supportGroups === null){
      dispatch(({ type: 'SET_ALL_SUPPORT_GROUPS', payload: [] }));
      return;
    }
    const  supportGroupList = supportGroups.map(retrivedSupportGroup => { return {id: retrivedSupportGroup.groupId, label: retrivedSupportGroup.groupName} });
    dispatch({ type: 'SET_ALL_SUPPORT_GROUPS', payload: supportGroupList });
  }, [supportGroups]);

  const { data: allServices } = useGetAllServicesQuery({query: JSON.stringify({ moduleType: props.moduleName, companyId: props.companyId}) }, { skip: !props.moduleName});

  useEffect(() => {
     if(typeof allServices === 'undefined' || allServices.length === 0 || allServices === null){
       dispatch(({ type: 'SET_ALL_SERVICES', payload: [] }));
       return;
     }
     const serviceList = allServices.map(retrivedService => { return {id: retrivedService.OFFERING_ID, label: retrivedService.OFFERING_NAME} });
     dispatch({ type: 'SET_ALL_SERVICES', payload: serviceList });
  },[allServices])

  const { data: allCIs } = useGetAllCIsQuery({searchList: 'STATUS,SUB_STATUS', multipleValueList: '7|12'});

  useEffect(() => {
      if(typeof allCIs === 'undefined' || allCIs.length === 0 || allCIs === null){
        dispatch(({ type: 'SET_ALL_CIs', payload: [] }));
        return;
      }
      const ciList = allCIs.map(retrieveCI => { return {id: retrieveCI.CI_ID, label: retrieveCI.CI_NAME, companyName: retrieveCI.COMPANY_NAME, className: retrieveCI.CLASS_NAME} });
      dispatch({ type: 'SET_ALL_CIs', payload: ciList});
  }, [allCIs]);

  const { data: allClasses } = useGetAllClassesQuery();

  useEffect(() => {
     if(typeof allClasses === 'undefined' || allClasses.length === 0 || allClasses === null){
       dispatch(({ type: 'SET_ALL_CLASSES', payload: [] }));
       return;
     }
     const classList = allClasses.map(retrivedClass => { return {id: retrivedClass.CLASS_ID, label: retrivedClass.CLASS_NAME} }); 
     dispatch({ type: 'SET_ALL_CLASSES', payload: classList });
  }, [allClasses]); 

  const { data: allUsers } = useGetAllUsersQuery({query: JSON.stringify({ companyId: props.companyId})} );

  useEffect(() => {
    if(typeof allUsers === 'undefined' || allUsers.length === 0 || allUsers === null){
      dispatch(({ type: 'SET_ALL_USERS', payload: [] }));
      return;
    }
    const userList = allUsers.map(retrivedUser => { return {id: retrivedUser.userId, label: retrivedUser.fullname } });
    const loggedInUser = [{id: props.user_id, label: props.full_name.trim()}];
    const completeUsers = [...loggedInUser, ...userList];
    dispatch(({ type: 'SET_ALL_USERS', payload: completeUsers })); 
  }, [allUsers]);

  const {data: allCompanies } = useGetAllCompaniesQuery();
  useEffect(() => { 
    if(typeof allCompanies === 'undefined' || allCompanies.length === 0 || allCompanies === null){
       dispatch(({ type: 'SET_ALL_COMPANIES', payload: [] }));
       return;
    }
    const companyList = allCompanies.map(retrivedCompany => { return {id: retrivedCompany.field1Value, label: retrivedCompany.field1Key} });
    dispatch(({ type: 'SET_ALL_COMPANIES', payload: companyList }));
  }, [allCompanies]);

  const { data: allReportedThrough } = useGetAllReportedThroughQuery();

  useEffect(() => { 
    if(typeof allReportedThrough === 'undefined' || allReportedThrough.length === 0 || allReportedThrough === null){
      dispatch(({ type: 'SET_ALL_REPORTED_THROUGH', payload: [] }));
      return;
    }
    const reportedThroughList = allReportedThrough.map(retrivedReportedThrough => { return {id: retrivedReportedThrough.field1Value, label: retrivedReportedThrough.field1Key } });
    dispatch(({ type: 'SET_ALL_REPORTED_THROUGH', payload: reportedThroughList }));
  }, [allReportedThrough]);

  const details = {
    companyId, moduleName: 'Breakfix', fieldName: 'Impact,Urgency', languageSelected, status: '1'
  };
  
  const { data: allUrgencyImpact } = useGetAllUrgencyAndImpantQuery(details); 

  useEffect(() => {
    if(typeof allUrgencyImpact === 'undefined' || allUrgencyImpact.length === 0 || allUrgencyImpact === null){
      return;
    } 
    let urgencyList = [], impactList = [];
    allUrgencyImpact.map((item)=>{
      if(item.field2Name==='Impact'){
        impactList.push({id: item.field1Value, label: item.field1Key});
		  }else{
        urgencyList.push({id: item.field1Value, label: item.field1Key});
		  }
	  });
    dispatch({type: 'SET_ALL_URGENCY', payload: urgencyList});
    dispatch({type: 'SET_ALL_IMPACT', payload: impactList});
  }, [allUrgencyImpact]);
  // useEffect(() => {
  //   if(!queryRawData) return;
  //   let extractedFields = constructDropdownFields(queryRawData);
  //   // extractedFields = [...extractedFields, ...tempFields];
  //   extractedFields = [...tempFields];
  //   setFields(extractedFields);
  // }, [queryRawData]);
  
  return (
    <div>
      {props.isAiTypeENV && <NlpSearchBarComponent handleOnUserQueryChange={handleOnUserQueryChange} retriveQuerybasedOnUserQuery={retriveQuerybasedOnUserQuery} maxLength={maxLength}/>}
      <div className="position-re">
        <QueryActionComponent retrieveBreakfixRecordOnQuery={retrieveBreakfixRecordOnQuery} showConditionBuilder={props.showConditionBuilder} />
        <CustomQueryBuilder fields={fields} query={query} setResult={setResult} />
      </div>
    </div>
  );
};

export default ConditionBuilder;
