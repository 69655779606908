
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState} from "react";
import {Tab,Tabs} from 'react-bootstrap';
import {useSelector } from "react-redux";

import AssociateMembers from "./associate-groups";
import AssociateRoles from "./associate-roles";
import { useParams } from "react-router";
import GroupsAuditLogs from "./groupsAuditLog";
import CloseBtnRightPanel from "../../../../common/closeBtnRightPanel";

const TabsComponent = (props) => {
    const tr = useSelector((state) => state.spcmReducer.tr);
    const [key, setKey] = useState(1);
    const params = useParams();
    
    let id = params.id;
    return (
        <>
            <div className="hambTab userInfoPopupParent">
                {props.showAuditLog == true ?<>
                        <div className="rBoxTabs hideTabNav">
                            <GroupsAuditLogs showRightSide={props.showRightSideFun}/>
                    </div></>:
          <>          
          <CloseBtnRightPanel showRightSide={props.showRightSideFun} />
          <div className="rPageHeading">
                <div className="offNam margin-t-5 margin-b-5">
                    {tr["Group Details"]}
                </div>
            </div>
                <div className="rBoxGap">
                    <div className="rBoxTabs hideTabNav">
                        <Tabs activeKey={key} onSelect={(k) => setKey(k)} id="tabId">
                            <Tab eventKey={1} title={tr["Associate Members"]}>
                                <AssociateMembers
                                    id={id}
                                    memberValue={props.memberValue}
                                />
                            </Tab>
                            <Tab eventKey={2} title={tr["Associate Roles"]}>
                                <AssociateRoles
                                    id={id}
                                /></Tab>
                        </Tabs>
                    </div>
                </div></>}
        </div >
            </>
    )
}

export default TabsComponent;