
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Row, Col, Form, Alert, ButtonToolbar, Button, InputGroup } from 'react-bootstrap';
import { _textArea, _dropDown, _inputField, _radio } from '../../common/formFields';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import axios from 'axios';
import { spcmLoader, setLeftEditId, getServiceDetailCounts, getConsumptionItems, getServiceBilling } from '../../../actions/spcmActions';
import {ImSpinner6} from "react-icons/im";


const validate = values => {
	let errors={};
	console.log("values",values);
	console.log("values",typeof values.price);

	if (!values.billingdef_type) {
		errors.billingdef_type = 'Select Associated To';
	}
	if (!values.parent_id) {
		errors.parent_id = 'Select Component';
	}
	
	if (values.price && typeof values.price === "string" && values.price.trim() == "") {
		errors.price = 'Enter Price';
	}
	else if (!values.price) {
		errors.price = 'Enter Price';
	}
	if (!values.billing_method) {
		errors.billing_method = 'Select Billing Method';
	}
	return errors;
}

class ComponentForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dropdown: 0,
			AssociatedToDropdown: ""
		}
		this.onChangeHandle = this.onChangeHandle.bind(this);
		this.submitForm = this.submitForm.bind(this);
	}
	componentWillMount() {
		if (this.props.spcmReducer.editLeftId > 0) {
			let AssociatedToDropdown = 0;

			if (this.props.initialValues.billingdef_type == "Component") {
				AssociatedToDropdown = 1;
			}

			this.setState({ AssociatedToDropdown });
		}

		if (!this.props.initialValues)
			this.props.change('billing_method', 'Min');
		if (this.props.initialValues.billingdef_type == "Component")
			this.setState({ dropdown: 1 });
		if (this.props.spcmReducer.serviceBilling.length > 0)
			this.props.change('base_currency', this.props.spcmReducer.serviceBilling[0].BASE_CURRENCY);
		else
			this.props.change('base_currency', 'USD');


		this.props.getConsumptionItems(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID, 'Type', 'optional');
	}

	componentWillUnmount() {
		this.props.spcmLoader('attribute', 'success');
	}

	onChangeHandle(e) {
		const { change } = this.props
		if (e.target.value == "Service") {
			this.setState({ dropdown: 0, AssociatedToDropdown: 0 });
			change('parent_id', this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_NAME);
		}
		else if (e.target.value == "Component") {
			this.setState({ dropdown: 1, AssociatedToDropdown: 1 });
		}

	}

	submitForm(values) {
		if (this.props.spcmReducer.editLeftId > 0) {
			values.updatedby_name = "";
			values.updatedby = "";
		} else {
			values.createdby_name = "";
			values.created_by = "";
		}

		let formData = Object.assign({}, values);
		if (formData.billingdef_type == "Service")
			formData.parent_id = this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID;
		if (this.props.spcmReducer.editLeftId > 0) {
			this.props.spcmLoader('attribute', 'posting');
			return axios.patch("/service/api/patchServiceBilling/" + this.props.spcmReducer.editLeftId, formData).then((response) => {
				this.props.getServiceBilling(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
				this.props.componentFormFun4(false);
				this.props.spcmLoader('attribute', 'success');
			})
				.catch((error) => {
					console.log(error);
					this.props.spcmLoader('attribute', 'success');
					console.log(error.response.data[0].message);
					throw new SubmissionError({ _error: error.response.data[0].message });
				});
		}
		else {
			this.props.spcmLoader('attribute', 'posting');
			return axios.post("/service/api/saveServiceBilling", formData).then((response) => {
				this.props.getServiceBilling(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
				this.props.getServiceDetailCounts(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
				this.props.componentFormFun4(false);
				this.props.spcmLoader('attribute', 'success');
			})
				.catch((error) => {

					this.props.spcmLoader('attribute', 'success');
					throw new SubmissionError({ _error: error.response.data[0].message });
				});
		}
	}
	render() {
		const tr = this.props.tr;
		const { error, handleSubmit } = this.props;
		let components = this.props.spcmReducer.consumptionItems;
		let billingMethod = [{ value: "Sec", text: "Sec" },
		{ value: "Minutes", text: "Minutes" },
		{ value: "Hour", text: "Hour" },
		{ value: "Daily", text: "Daily" },
		{ value: "Bi-Weekly", text: "Bi-Weekly" },
		{ value: "Weekly", text: "Weekly" },
		{ value: "Monthly", text: "Monthly" },
		{ value: "Quarterly", text: "Quarterly" },
		{ value: "Half-Yearly", text: "Half-Yearly" },
		{ value: "Yearly", text: "Yearly" },
		{ value: "One-Time Charge", text: "One-Time Charge" }]

		return (
			<div className="rBoxGap">
				<form onSubmit={handleSubmit(this.submitForm)}>
					{error == undefined || error == null ? null : <Alert variant="danger" className="errorMessageSec"><p>{error}</p></Alert>}
					<Row>
						<Col md={12}>
							<Form.Group className="radioInlineBox form-group">
								<Form.Label bsClass="" className="float-l margin-r-20"><span className="rStar"></span> {tr['Associated To']}</Form.Label>
								<div className="radioInlineBox float-l">
									<Field component={_radio} checked={this.state.AssociatedToDropdown == 0} disabled={this.props.spcmReducer.serviceBilling.length == 0 || this.props.initialValues.billingdef_type == 'Service' ? false : true} className='form-control' type="radio" name='billingdef_type' value='Service' onChange={this.onChangeHandle} />
									<Field component={_radio} checked={this.state.AssociatedToDropdown == 1} disabled={this.props.spcmReducer.serviceBilling.length == 0 || this.props.initialValues.billingdef_type == 'Service' ? true : false} className='form-control' type="radio" name='billingdef_type' value='Component' onChange={this.onChangeHandle} />
								</div>
								{
									this.state.dropdown == 0
										? <Field component={_inputField} name="parent_id" className='form-control' readOnly />
										: <Field component={_dropDown} name="parent_id" options={components} className='form-control' />

								}
							</Form.Group>
						</Col>
						<Col md={12}>
							<Form.Group className="priceForm-group form-group">
								<Form.Label bsClass=""><span className="rStar"></span> {tr['Specify']}</Form.Label>
								<InputGroup className='inputGrp2Inlne'>
									<InputGroup.Text>{
										this.props.spcmReducer.serviceBilling.length == 0 || this.props.initialValues.billingdef_type == 'Service' ?
											<Field component={_dropDown} options={[{ text: "USD", value: "USD" }, { text: "AUD", value: "AUD" }, { text: "INR", value: "INR" }]} name='base_currency' className='form-control'></Field> :
											<Field component={_inputField} name='base_currency' className='form-control readOnlyInputheight' readOnly></Field>


									}
									</InputGroup.Text>
									<Field component={_inputField} name="price" className='form-control' />
									<InputGroup.Text>
										<Field component={_dropDown} options={billingMethod} name='billing_method' className='form-control'></Field>
									</InputGroup.Text>
								</InputGroup>
							</Form.Group>
						</Col>
					</Row>
					<Row className='margin-t-10'>
						<Col md={12}>
							<ButtonToolbar className="black">
								<Button className='rgSidrkBtn' title="Save" disabled={this.props.spcmReducer.spcmPostingAttribute} type="submit" bsSize="small" bsStyle="primary">{this.props.spcmReducer.spcmPostingAttribute ? <ImSpinner6 className="icn-spinner"/> : null}  {tr['Save']}</Button>
								<Button className='rgSiLigBtn' title="Close" onClick={() => { this.props.componentFormFun4(false) }} bsSize="small" bsStyle="text">{tr['Close']}</Button></ButtonToolbar>
						</Col>
					</Row>
				</form>
			</div>
		);
	}
}

const mapStateToProps = ({ spcmReducer }) => {
	return {
		spcmReducer,
		initialValues: spcmReducer.editLeftId > 0 ? spcmReducer.editLeftService : ''
	}
};
ComponentForm = reduxForm({
	form: 'serviceBillingForm',
	validate,
	enableReinitialize: true
})(ComponentForm)

export default connect(mapStateToProps, { spcmLoader, setLeftEditId, getServiceDetailCounts, getConsumptionItems, getServiceBilling })(ComponentForm);
