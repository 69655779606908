
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import {Row, Col, Button,Form, ListGroup,ButtonToolbar } from "react-bootstrap";
import { useDispatch, useSelector, connect } from "react-redux";
import { Field, reduxForm, formValueSelector, change, SubmissionError } from "redux-form";
import {_passwordField, _inputField, _dropDown, _checkbox, _number } from "../../../common/formFields";
import moment from "moment";
import Cookies from "universal-cookie";
import { _dateTimeFieldGeneral } from "../../../common/ReduxFormFields/formFields";
import onCallDropdownPlusTypeahead from "../../common/onCallDropdownPlusTypeahead";
import { onCallGroupUsersByGroupId } from "../../../../actions/onCallSchedule/common/onCallCommonAction";
import { IoClose } from "react-icons/io5";
import { loadShiftListByGroup } from "../../../../actions/onCallSchedule/calendar/onCallCalendarAction";
import Swal from "sweetalert2";
import axios from "axios";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';
const cookies = new Cookies();
let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:","");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
  dateformat = dateformat.split("~");
  dateformat = dateformat[2];
  dateformat = dateformat.slice(0, 10).toUpperCase();

const validate = (values) => {
  const errors = {};
  if (!values.from) {
    errors.from = true;
  }
  if (!values.to) {
    errors.to = true;
  }
  if (!values.shift) {
    errors.shift = "Plesse select the shift";
  }
  if (!values.user) {
    errors.user = "Plesse select the user";
  }
  return errors;
};

const AddUserToShift = (props) => {
  const dispatch = useDispatch();
  const translator = useSelector((state) => state.spcmReducer.tr);
  const [userErrorState, setUserErrorState] = useState(false);
  const [selectedUserList, setSelectedUserList] = useState([]);
  const [userInputValue, setUserInputValue] = useState("");
  const [userListOptions, setUserListOptions] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { handleSubmit, error } = props;

  const userList = useSelector((state) => state.onCallGroupUsersList);
  const shiftList = useSelector((state) => state.shiftListByGroupId);
  const context = useContext(MyContext);
  const closeButton = useRef(null);

  useEffect(() => {
      context.keyDownHandler(closeButton.current,"","","");
  }, []);

  useEffect(() => {
    if (userList && userList.length > 0) {
      let userListFields = [];
      userList.forEach((list) => {
        userListFields.push({ id: list.field1Value, label: list.field1Key });
      });
      setUserListOptions(userListFields);
    }
  }, [userList]);

  useEffect(() => {
    // dispatch(change("addUserToShift", "from", moment()));
    // dispatch(change("addUserToShift", "to", moment()));
    dispatch(change("addUserToShift", "user", ""));
  }, []);

  useEffect(() => {
    if (props.selectedGroupList && props.selectedGroupList.length > 0) {
      dispatch(loadShiftListByGroup(props.selectedGroupList[0].id));
    } else {
      setUserListOptions([]);
      dispatch({
        type: "ON_CALL_CALENDER_SHIFT_LIST_DATA_BY_GROUP",
        events: { data: [] },
      });
    }
  }, [props.selectedGroupList]);

  const onUserSelection = (event) => {
    if (event && event[0]) {
      setUserInputValue(event[0].label);
      dispatch(change("addUserToShift", "user", event[0].label));
    } else {
      dispatch(change("addUserToShift", "user", ""));
    }
    setSelectedUserList(event);
  };

  const setCompanyError = () => {
    if (selectedUserList.length === 0) {
      setUserErrorState(true);
    } else {
      setUserErrorState(false);
    }
  };

  const onUserInputChange = (value) => {
    if (value) {
      setUserInputValue(value);
    } else {
      setUserInputValue("");
      onUserCrossClick();
    }
  };

  const onUserCrossClick = () => {
    setSelectedUserList([]);
    setUserInputValue("");
  };

  const popMessage = (text) => {
    Swal.fire({ title: text });
  };

  const formSubmit = (values) => {
    
    setCompanyError();
    if (values.from.format("X") > values.to.format("X")) {
      Swal.fire("To should be greater than from!");
      return;
    }

    if (moment(values.from.format("YYYY-MM-DD")).format("X") < moment(moment().format("YYYY-MM-DD")).format("X")) {
      Swal.fire("Past dates are not allowed!");
      return;
    }
    let postJson = {};
    postJson["userId"] = selectedUserList[0].id;
    postJson["userFullName"] = selectedUserList[0].label;
    postJson["groupId"] = props.selectedGroupList[0].id;
    postJson["groupName"] = props.selectedGroupList[0].label;
    postJson["fromDate"] = values.from.format("x");
    postJson["toDate"] = values.to.format("x");
    postJson["shiftId"] = values.shift;
    postJson["companyId"] = props.selectedGroupList[0].companyId;
    postJson["companyName"] = props.selectedGroupList[0].companyName;
    setIsButtonDisabled(true);
    axios
      .post("/rest/aiosem/assignShiftToUserAction", postJson)
      .then((shiftAssigned) => {
        if (!shiftAssigned) {
          throw Error("Shift assigned details not found.");
        }
        return shiftAssigned;
      })
      .then((shiftAssigned) => {
        Swal.fire({ title: "User has been assigned to a shift successfully!" });
        props.resetFilters();
        props.showRightSideFun("");
        setIsButtonDisabled(true);
      })
      .catch((error) => {
        setIsButtonDisabled(false);
        Swal.fire({ title: error.message });
        console.log("error", error);
      });
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
            <ListGroup bsPrefix=" " as="ul">
              <ListGroup.Item as="li" bsPrefix=" ">
                <Link
                  to="javascript:void(0)"
                  bsPrefix=" "
                  title={translator["Minimize the details panel"]}
                  onClick={() => {
                    props.showRightSideFun("");
                  }}
                  ref={closeButton}
                >
                  <IoClose />
                </Link>
              </ListGroup.Item>
            </ListGroup>
          </div>
          <div className="rPageHeading">
            <div className="offNam margin-t-5 margin-b-5">
              {translator["Add coverage"]}
            </div>
          </div>
        </Col>
      </Row>
      <div className="rBoxGap">
        <div className="rBoxTabs">
          <div>
            <font color="red">{error && <strong>{error}</strong>}</font>
          </div>
          <Row>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>
                  <span className="rStar"></span>
                  {"User"}
                </Form.Label>
                <Field
                  component={onCallDropdownPlusTypeahead}
                  errorColor={userErrorState ? "error" : ""}
                  onSelection={onUserSelection}
                  setErrorColor={setCompanyError}
                  name="user"
                  id="user"
                  className="form-control"
                  options={userListOptions}
                  selectedOptions={selectedUserList}
                  onInputChange={onUserInputChange}
                  inputValue={userInputValue}
                  onCrossClick={onUserCrossClick}
                />
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>
                  <span className="rStar"></span>
                  {translator["Shift"]}
                </Form.Label>
                <Field
                  name="shift"
                  component={_dropDown}
                  options={Array.isArray(shiftList) ? shiftList : []}
                  className="form-control"
                />
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>
                  <span className="rStar"></span>
                  {"From"}
                </Form.Label>
                <div className="dateTimeSdiv">
                  <Field
                    name="from"
                    component={_dateTimeFieldGeneral}
                    formType="onCallSchedule"
                    className="form-control dateTimePickerField"
                    defaultValue={moment().format(dateformat)}
                    timeFormat="false"
                  />
                </div>
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>
                  <span className="rStar"></span>
                  {"To"}
                </Form.Label>
                <div className="dateTimeSdiv dateTimePosLeft">
                  <Field
                    name="to"
                    component={_dateTimeFieldGeneral}
                    formType="onCallSchedule"
                    className="form-control dateTimePickerField"
                    defaultValue={moment().format(dateformat)}
                    timeFormat="false"
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ButtonToolbar>
                <Button
                  onClick={handleSubmit(formSubmit)}
                  bsSize="xsmall"
                  className="rgSidrkBtn smallBtn"
                  title={translator["Add"]}
                  disabled={isButtonDisabled}
                >
                  {translator["Add"]}
                </Button>
                <Button
                  onClick={() => {
                    props.showRightSideFun("");
                  }}
                  bsSize="xsmall"
                  className="rgSiLigBtn smallBtn"
                  title={translator["Cancel"]}
                  disabled={isButtonDisabled}
                >
                  {translator["Cancel"]}
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default reduxForm({
  form: "addUserToShift",
  destroyOnUnmount: true,
  validate,
  enableReinitialize: true,
})(AddUserToShift);
