
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Accordion } from "react-bootstrap";

import Table from "../../../../common/tableView";
import NoDataAvailable from "../../../../common/NoDataAvailable";
import parse from "html-react-parser";

export const OccurrencesListView = ({
  occurrencesdata = [],
  meetingDefData,
}) => {
  const columns = useMemo(() => {
    return [
      {
        accessor: "from",
        Header: "Date (From & To)",
        // sortType: _sortBy,
        Cell: ({ row: { original } }) => {
          return (
            <Link
              to={`/add-edit/meeting/occurence-details/${original?.meetingOccurrananceId}`}
              className="Occurrence-link blue-txt"
            >
              {original.recurrenceStartDateTime} <br />
              {original.recurrenceEndDateTime}
            </Link>
          );
        },
      },
      {
        accessor: "agenda",
        Header: "Agenda",
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
          return <div>{parse(original?.agenda)}</div>;
        },
      },
    ];
  }, []);

  const Occurrences = useCallback(
    ({ occurrencesdata, type, noText, disablesort }) => {
      return occurrencesdata?.[type]?.length === 0 ? (
        <>
          <NoDataAvailable />
        </>
      ) : (
        <div
          id="scrollableDiv_"
          className="mobile-scroll-height"
          style={{ maxWidth: "100%" }}
        >
          <Table
            columns={columns}
            data={occurrencesdata[type] || []}
            tableClass="occurrene-table"
            disablesort={disablesort}
          />
        </div>
      );
    },
    [columns]
  );

  return (
    <div className="stickyArea rBoxStyle">
      <div className="rPageHeading d-flex p-3">
        <div className="flex-grow-1 offNam mw-100">Occurrence</div>
      </div>

      <div className="p-3 occurnance">
        <Accordion
          accordion
          id="rightSidePane-occurrence"
          // defaultActiveKey={["0"]}
          // onSelect=""
          className=""
        >
          <Accordion.Item eventKey="0" className="mb-3">
            <Accordion.Header>In Progress</Accordion.Header>
            <Accordion.Body>
              <Occurrences
                disablesort
                type="current"
                occurrencesdata={occurrencesdata || []}
                noText="No meeting is in progress"
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1" className="mb-3">
            <Accordion.Header> In Future</Accordion.Header>
            <Accordion.Body>
              <Occurrences
                disablesort={false}
                type="future"
                occurrencesdata={occurrencesdata}
                noText="No meeting is scheduled for future"
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2" className="mb-3">
            <Accordion.Header> In Past</Accordion.Header>
            <Accordion.Body>
              <Occurrences
                disablesort={false}
                type="past"
                occurrencesdata={occurrencesdata}
                noText="No meeting has occurred in the past"
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3" className="mb-3">
            <Accordion.Header>Cancelled</Accordion.Header>
            <Accordion.Body>
              <Occurrences
                disablesort={false}
                type="cancelled"
                occurrencesdata={occurrencesdata}
                noText="No meeting has been cancelled"
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};
