
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from "axios";
import Cookies from "universal-cookie";
import { getFilteredFields } from "../../common/helper";
const cookies = new Cookies();
let gph = cookies.get("gph");
if (gph) gph = gph.replace("s:", "");
if (gph) gph = gph.substring(0, gph.lastIndexOf("."));
gph = gph.split("~");
export const envVariables = {envAttachmentSize:gph[21], envUsername:gph[32], envUserFullname:gph[42], envUserId:gph[43], envCompanyId:gph[48], envCompanyName:gph[59]}
const formFields = [
  "input",
  "textarea",
  "custom-dropdown",
  "typeaheadComponent",
  "uploadComponent",
  'custom-checkbox',
  "custom-radio",
  "dropdownConnector",
  "select",
  "checkbox",
  "radio"
];
export const  freezeNotApplicable = ['cancel','link','refresh','repeatativePlus','repeatativeDelete',undefined]


const convertToObject = (arrayValue) => {
  let obj = {};
  arrayValue.forEach((item) => {
    obj[item.key] = item.value;
  });
  return obj;
};

export function validateRestRequest(request) {
  const headerValues = request.headers
    ? convertToObject(request.headers)
    : null;
    if(headerValues && headerValues['x-user-payload']){
      delete headerValues['x-user-payload'];
    }
  let data = {
    url: request.url,
    method: request.verb,
    headers: headerValues,
  };
  return axios({
    url: "/myApis",
    method: "POST",
    //headers: headerValues,
    data: data,
  });
}

export function cx(...inputs) {
  const inp = Array.isArray(inputs[0]) ? inputs[0] : [...inputs];
  return inp.filter(Boolean).join(" ");
}

export function isJson(str) {
  if (str.toString() == "null") {
    return false;
  }

  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function checkErrors(filteredFields, defaultValues, touchedFields = {}, hiddenFields = []) {
  let errors = {};
  if (filteredFields && filteredFields.length > 0) {
    const filteredInputFields = filteredFields.filter((field) =>
      formFields.includes(field.type)
    );

    filteredInputFields.forEach((field) => {
      if (field.required && !hiddenFields.includes(field.name)) {
        if (!defaultValues[field.name]?.toString()?.replace(/\s+/g, "")) {
          errors[field.name] = "This field is required";
        } else {
          delete errors[field.name];
        }
      } else if (field.attributes && field.attributes.required && !hiddenFields.includes(field.name || field.attributes.name)) {
        if (field.attributes.required) {
          if (
            !defaultValues[
              field.hasOwnProperty("name")
                ? field?.name
                : field.attributes?.name
            ]?.toString()?.replace(/\s+/g, "")
          ) {
            errors[
              field.hasOwnProperty("name")
                ? field?.name
                : field.attributes?.name
            ] = "This field is required";
          } else {
            delete errors[
              field.hasOwnProperty("name")
                ? field?.name
                : field.attributes?.name
            ];
          }
        }
      } else {
        delete errors[
          field.hasOwnProperty("name") ? field?.name : field.attributes?.name
        ];
      }
    });

    const repetativeFields = filteredFields.filter(
      (field) => field.type == "RepeatativeFieldsComponent"
    );

    if (repetativeFields.length > 0) {
      repetativeFields.forEach((field) => {
        if (field.required) {
          if (!defaultValues[field.valueBindKey]?.toString()?.replace(/\s+/g, "")) {
            errors[field.valueBindKey] = "This field is required";
          } else if (
            !checkJsonFieldsNotEmpty(defaultValues[field.valueBindKey]?.toString()?.replace(/\s+/g, ""))
          ) {
            errors[field.valueBindKey] = "This field is required";
          } else {
            delete errors[field.valueBindKey];
          }
        } else {
          delete errors[field.valueBindKey];
        }
      });
    }
    return errors;
  }
}

const checkErrorsDependentFields = ()=>{

}

const checkJsonFieldsNotEmpty = (arr) => {
  let error = true;
  for (let item of arr) {
    for (let key in item) {
      if (item[key] === "") {
        error = false;
      }
    }
  }
  return error;
};

export function replaceVariables(input, replacements) {
  if (input && input.length > 0) {
    return input.replace(/{{(.*?)}}/g, (match, p1) => {
      return replacements[p1] || "";
    });
  }
}

export function getMatchedKeys(input){
  let matchedKeys = [];
  if (input && input.length > 0) {
    return input.replace(/{{(.*?)}}/g, (match, p1) => {
      return matchedKeys[p1];
    });
  }
}

export function generateFilterOptions(
  data,
  label,
  value,
  defaultLabelKey = "label",
  defaultValueKey = "id"
) {
  return data.map((res) => {
    return { [defaultValueKey]: res[value], [defaultLabelKey]: res[label] };
  });
}

export const getTag = (item) => {
  switch (item?.tagName || item.type) {
    case "link":
      return "a";
    case "form":
      return "div";
    case "titleComponent":
      return "h2";
    case "CustomIcon":
      return "div";
    case "RepetativeRowBlock":
      return "div";
    case "th":
      return "th";
    case "cell":
      return "td";
    case "row":
      return "tr";
    case "text":
    case "Text":
      return item?.tagName || item.type;
    default:
      return item?.tagName || item.type;
  }
};

export const checkIfHiddenField = (components, hiddenFIelds) => {
  for (let i = 0; i < components.length; i++) {
    if (
      hiddenFIelds.includes(
        components[i].name || components[i]?.attributes?.name
      )
    ) {
      return true;
    }
    if (
      components[i].components &&
      checkIfHiddenField(components[i].components, hiddenFIelds)
    ) {
      return true;
    }
  }
  return false;
};


export const getFieldsName = (components, fields = []) => {
  for (let i = 0; i < components.length; i++) {
    if (
      (components[i].name || components[i]?.attributes?.name) &&
      !["Row", "Cell"].includes(
        components[i].name || components[i]?.attributes?.name
      )
    ) {
      fields.push(components[i].name || components[i]?.attributes?.name);
    } else if (components[i].components) {
      getFieldsName(components[i].components, fields);
    }
  }
  return fields;
};

export const getPublishedPageUrl = (pages,functionAction,currentUrl) => {
  if(!pages || pages.length == 0){
    return currentUrl;
  }
  const filteredPages = pages?.getAllPublishedPages.filter((page) => page.functionAction == functionAction);
  if(filteredPages.length > 0){
    return filteredPages[0].pageRoute;
  }else{
    return currentUrl;
  }
} 

export const traitInstructions = {
  "options":"Ex-:value::Label,value1::Label1,...",
  "label":"Ex-:Home,Edit,...",
  "extraKeys":"Ex-:key1,key2,...",
  "extraFields":"Ex-:field1,field2,...",
  "labels":"Ex-:Label1,Label2,...",
  "values":"Ex-:Value1,Value2,...",
  "rightSideKeys":"Ex-:Key1,Key2,...",
  "rightSideLabels":"Ex-:Label1,Label2,...",
  "breadCrumbLinks":"Ex-:Link1,Link2,...",
  "extraKeysForVariable":"Ex-:Key1,Key2,...",
  "paasingKeys":"Ex-Key1,Key2..."
}

export const getValueFromKey = (obj, key)=> {
  if (key in obj) {
      return obj[key];
  } else {
      for (let i in obj) {
          if (typeof obj[i] === 'object') {
              let result = getValueFromKey(obj[i], key);
              if (result !== null) {
                  return result;
              }
          }
      }
  }
  return null;
}

export const getExpandedAttributes = (attributes)=>{
  let newAttributes ={};
   if(attributes && Object.keys(attributes).length>0){
       Object.keys(attributes).forEach(key=>{
         if(typeof attributes[key] === "object" && attributes[key].id){
          newAttributes[key] = attributes[key].id;
         }else{
          newAttributes[key] = attributes[key];
         }
      });
   }
   return newAttributes;
}

export const jsonEscape = (str) => {
  if (typeof str === "string") {
    return str
      .replace(/\\/gi, "\\\\")
      .replace(/\r/g, "\\r")
      .replace(/\t/g, "\\t")
      .replace(/\n/g, "\\n")
      .replace(/"/g, `\\"`);
  }
  return str;
};





export function getClassesAndsIds(input) {
  let returArray = [];
  if (input && input.length > 0) {
    input.replace(/id="(.*?)"/g, (match, p1) => {
      returArray.push("#" + p1);
    });

    input.replace(/class="(.*?)"/g, (match, p1) => {
      returArray.push("." + p1);
    });

    return returArray;
  }
}

export function findVariablesInQuery(input, ) {
  let keys = [];
  if (input && input.length > 0) {
    return input.replace(/$(.*?):/g, (match, p1) => {
      keys.push(p1);
    });
  }
}

export const hasDuplicates = (array)=> {
  const count = {};
  for (const value of array) {
      if (count[value]) {
          return true;
      }
      count[value] = 1;
  }
  return false;
}

export const getFormNamesFields = () => {
  const forms = [];
  if (localStorage.getItem("gjsProject")) {
    const data = JSON.parse(localStorage.getItem("gjsProject"));
    if (typeof data == "object" && Object.keys(data).length > 0) {
      const filteredFields = getFilteredFields(data);
      filteredFields.forEach((form) => {
        if (form?.attributes?.name) {
          forms.push(form?.attributes?.name);
        } else if (form.name) {
          forms.push(form?.name);
        }
      });
    }
  }
  return forms;
};
