
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";

import { useGetGovAudiLogsQuery } from "../../../../../../reducers/governance/meeting/meeting";

import AuditLogs from "../../../../../governance-meeting/common/AuditLogs";
import ListLoader from "../../../../../common/loaders/ListLoader";

const OccurrenceAuditLogs = ({ data, occurenceData, companyId, auditData,setOrderValue }) => {
  const meetingOccurrananceId = occurenceData?.meetingOccurrananceId;
  const meetingId = occurenceData?.meetingDefinationId;

  return (
    <div className="panel-body attachment-body">
      <div className="custom-container-fluid">
        <div className="stickyArea rBoxStyle">
          <div className="p-3">
            <AuditLogs data={data} setOrderValue={setOrderValue}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OccurrenceAuditLogs;
