
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';

//let user_id=userDetails.user_id;
//let company_id=userDetails.company_id;

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});



export function loadBestSellingSuccess(bestSelling){
	return {
		type: 'FETCH_BESTSELLING',
		bestSelling
	};
}

export function loadBestSelling(start,end){
	var str={};
str.companyId="";
str.userId="";
str.currency="USD";
if(start!=""){
	str.start=start;
}
if(end!=""){
	str.end=end;
}
str = JSON.stringify(str);

const bestSellingUrl="/getBestSellingOfferingForRestifySelfService"
	return(dispatch) =>{
		dispatch({ type: "BEST_SELLING_LOADER_IN_PROGRESS" });
		api.get(bestSellingUrl,{headers:{'query':str}})
			.then((bestSelling)=>{
				if(!bestSelling){
					throw Error(bestSelling.statusText);
				}
				return bestSelling;
			})
			.then((bestSelling) =>{
				dispatch(loadBestSellingSuccess(bestSelling))
				dispatch({ type: 'BEST_SELLING_LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
			})
			.catch((err) => {
				console.log(err);
				dispatch({ type: 'BEST_SELLING_LOADER_DETAILS_FETCHED_SUCCESSFULLY' });

			});
	};
}
