
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from "axios";
import { GLOBAL } from '_Globals';
import swal from 'sweetalert';
import { navigationHooks } from '../../../helpers/NavigationHook';

const api = axios.create({
    headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});



export function createShift(data) {
    console.log("Data===>",data)
    return (dispatch) => {
        console.log("GLOBAL.createShift",GLOBAL.createShift)
        api.post(GLOBAL.createShift, data)
            .then((response) => {
                console.log("response===>",response);
                if(response.status == 200 && response.data == 'Duplicate record.'){
                    swal({
                        text: "Record already exists",
                        button: 'OK',
                    });
                    return false;

                } else if (response.status == 200) {
                    navigationHooks.navigate("/onCallSchedule/editShift/" + response.data.data.shiftId);  
                }
            })
            .catch((err) => {
                 swal({
                    text: err.response.data.businessMessage,
                    button: 'OK'
                 });
            });
    };
}
export function getShiftListData(params={}, page = 0, size = 10) {
  return (dispatch) => {
    console.log("Inside list data api", params);
    dispatch({ type: "LOAD_SHIFT_LIST_LOADER_IN_PROGRESS" });
    let url;
    if (params && typeof params === "object" && Object.keys(params).length > 0) {
      let queryParams = "";
      let i = 1;
      params["sort"] = "createdOn,desc";
      if (size > 10) {
        params["size"] = size;
        params["page"] = page;
      }
        queryParams += "?";
        Object.keys(params).forEach((key) => {
          queryParams += key + "=" + params[key];
          if (i !== Object.keys(params).length) {
            queryParams += "&";
          }
          i++;
        });
        url = GLOBAL.getShiftListData + queryParams;
    } else {
      url = GLOBAL.getShiftListData +"?groupId=" +params +"&page=" +page + "&size=" + size;
    }
    api
      .get(url)
      .then((getShiftListData) => {
        if (!getShiftListData) {
          throw Error(getShiftListData.statusText);
        }
        return getShiftListData;
      })
      .then((getShiftListData) => {
        dispatch({
          type: "LOAD_ALL_SHIFT_LIST_META",
          payload: {
            currentPage: getShiftListData.data.currentPage,
            numberOfItems: getShiftListData.data.numberOfItems,
          },
        });
        dispatch({
          type: "LOAD_SHIFT_LIST_SUCCESS",
          getShiftListData: getShiftListData.data.data,
        });

        dispatch({ type: "LOAD_SHIFT_LIST_LOADER_FINISHED" });
      })
      .catch((err) => {
        dispatch({ type: "LOAD_SHIFT_LIST_LOADER_FINISHED" });
        console.log("error in loadCategoryListSuccess");
        console.log(err);
      });
  };
}
export function patchOnCallShiftData(shiftId, data) {
      
    console.log("Data===>",data)
    console.log("shiftId===>",shiftId)
    return (dispatch) => {
        console.log("GLOBAL.patchShiftData",GLOBAL.patchShiftData)
        axios.patch(GLOBAL.patchShiftData + '/' + shiftId, data)
            .then((response) => {
                if(response.status == 200 && response.data == 'Duplicate record.'){
                    swal({
                        text: "Record already exists",
                        button: 'OK',
                    });
                    return false;

                } else if (response.status == 200) {
                    // navigationHooks.navigate("/onCallSchedule/shift");    
                }
                dispatch(loadShiftDetailsByShiftId(shiftId));
            })
            .catch((err) => {
                 swal({
                  text: err.response.data.businessMessage,
                  button: 'OK'
                 });
            });
    };
}
export function resetShiftListData(){
    return (dispatch)=>{
        dispatch({type: 'LOAD_SHIFT_LIST_SUCCESS', getShiftListData:[]});
        dispatch({
            type: "LOAD_ALL_SHIFT_LIST_META",
            payload: {
              currentPage: 0,
              numberOfItems: 1,
            },
          });
    }
}
export function loadShiftDetailsByShiftId(shiftId) {
    return (dispatch) => {
        dispatch({ type: 'LOAD_SHIFT_LIST_LOADER_IN_PROGRESS' });
        api.get(GLOBAL.getShiftByShiftId + '/' + shiftId)
            .then((response) => {
                if (!response) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => {
                if (response.status == 204) {
                    dispatch(loadShiftDetailsByShiftIdSuccess([]));
                } else {
                    console.log(" ACTION response",response)
                    let initialValuesObj = {};
                    initialValuesObj.shiftId = response.data.data.shiftId;
                    initialValuesObj.shiftName = response.data.data.shiftName;
                    initialValuesObj.groupName = response.data.data.groupName;
                    initialValuesObj.groupId = response.data.data.groupId;
                    initialValuesObj.scheduleId = response.data.data.scheduleId;
                    initialValuesObj.scheduleName = response.data.data.scheduleName;
                    initialValuesObj.companyId = response.data.data.companyId;
                    initialValuesObj.companyName = response.data.data.companyName;
                    dispatch(loadShiftDetailsByShiftIdSuccess(initialValuesObj));
                }
                dispatch({ type: 'LOAD_SHIFT_LIST_LOADER_FINISHED' });
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: 'LOAD_SHIFT_LIST_LOADER_FINISHED' });
            });
    };
}
export function loadShiftDetailsByShiftIdSuccess(payload) {
    return {
        type: 'LOAD_SHIFT_DETAILS_BY_SHIFT_ID_SUCCESS',
        payload
    };
}