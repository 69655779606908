
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { reduxForm } from "redux-form";
import { updateFDNCompanyAction ,getEditCompanyPageDetailsReset, getEditCompanyPageDetails} from "../../../../../actions/foundation/companyAction";
import swal from "sweetalert";
import axios from 'axios';

import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import { PiUserSwitch } from "react-icons/pi";
import {LuFileText} from "react-icons/lu";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';

const HeaderSec = (props) => {
  const navigate = useNavigate();
  const [syncEnable, setSyncEnable] = useState(true);
  const [message, setMessage] = useState();
  const params = useParams();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const clickSync = useSelector((state) => state?.syncedData);
  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const saveObjRef = useRef(null);

	useEffect(() => {
		context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
    context.newTabPrevent();
	}, []);

  const dispatch = useDispatch();
    let { handleSubmit, submitting } = props;
    
    const submit = (values) => {
    console.log("ValuessEDIT+++++", values);
    let isValid = true;
    let formData = new FormData();

    if (values.file) {
      formData.append('file', values.file[0]);

    } else {
      formData.append('file', "");
    }
    
    if (!values.name || values.name.trim() == "") {
      props.setNameErr("error");
      isValid = false;
    } else {
      props.setNameErr("");
    }
    

      // values.companyType = props.companyType
     values.companyType = props.companyType;
    if(values.companyType.length == 0){
        props.companyTypeErrFun('error');
        isValid = false;
    }else{
        props.companyTypeErrFun("");
    }

    if (!values.description || values.description.trim() == "") {
      props.setDescriptionErr("error");
      isValid = false;
    } else {
      props.setDescriptionErr("");
    }
   

    if (isValid == true) {
      let postjson;
      postjson = {
        file: null,
        fileLogo: null,
        id: values.id,
        companyImageTempURL: null,
        xsmLogoTempURL: null,
        container: null,
        imagename: null,
        company_code: values.company_code,
        actionVal: "Save",
        company_prefix_req: values.company_prefix_req
          ? values.company_prefix_req
          : null,
        notification_flag: values.notification_flag
          ? values.notification_flag
          : "Y",
        knowledge_flag: values.sms_notification_flag
        ? values.sms_notification_flag
        : "N",
        field1Key: null,
        field1Value: null,
        group_Id: null,
        group_Name: null,
        company_Name: null,
        sequence: null,
        urgency: null,
        impact: null,
        priority: null,
        label: null,
        businessFunctionId: null,
        multilingual_flag: values.multilingual_flag
          ? values.multilingual_flag
          : "N",
        sms_notification_flag: values.sms_notification_flag
          ? values.sms_notification_flag
          : "N",
        filterby: null,
        pagesize: 0,
        vendorId: null,
        vendorIds: null,
        aliasName: values.aliasName ? values.aliasName.trim() : "",
        name: values.name,
        description: values.description,
        companyType: values.companyType,
        companyTypeName: null,
        eventNameDefault: null,
        associateCompanyType: null,
        associatedCompanies: null,
        companyTypes: null,
        associationActiveFlag: null,
        associationActiveFlagWithOtherCompanies: null,
        userId: 0,
        status: values.status ? values.status : "1",
        associatedAction: null,
        filterbytext: null,
        checkActionMode: null,
        welcomeMessage: "Welcome To XSM",
        product_container: null,
        groupList: null,
        logo: null,
        footerNote: null,
        portalName: null,
        otherInfo: null,
        footerImg: null,
        footerSignature: null,
        lucyUrl: values.lucyUrl ? values.lucyUrl.trim() : null,
        module: null,
        field: null,
        key: null,
        newKey: null,
        value: 0,
        language: null,
      };
      // dispatch(updateFDNCompanyAction(postjson));
      if (typeof values.file == "string") {

        return axios
          .post("/api/editCompanyDetails", postjson)
          .then((response) => {
            if (
              response.data.CompanyData ==
                "Company is Updated with by id/name" &&
              response.status == 201
            ) {
              swal({
                text: "Company has been Updated",
                button: "OK",
              });
              dispatch(getEditCompanyPageDetails(params.id));
              // navigate("/Company");
              // dispatch(getEditCompanyPageDetailsReset());
            } else if (response.data == "Name already exists") {
              swal({
                text: response.data,
                button: "OK",
              });
              return false;
            }
          })
          .catch((error) => {});
      } else if (typeof values.file == "object" && values.file == null) {
        return axios
          .post("/api/editCompanyDetails", postjson)
          .then((response) => {
            if (
              response.data.CompanyData ==
                "Company is Updated with by id/name" &&
              response.status == 201
            ) {
              swal({
                text: "Company has been Updated",
                button: "OK",
              });
              // navigate("/Company");
              // dispatch(getEditCompanyPageDetailsReset());
              dispatch(getEditCompanyPageDetails(params.id));
            } else if (response.data == "Name already exists") {
              swal({
                text: response.data,
                button: "OK",
              });
              return false;
            }
          })
          .catch((error) => {});
      } else if (typeof values.file == "object" && values.file.length > 0) {

        return axios
          .post("/api/uploadCompanyLogoSelfService" + "/" + values.id, formData)
          .then((resp) => {
            if (resp.data == "File type not supported") {
              swal({
                text: "Please upload an image of supported format",
                button: "OK",
              });
            } else {
              return axios
                .post("/api/editCompanyDetails", postjson)
                .then((response) => {
                  if (
                    response.data.CompanyData ==
                      "Company is Updated with by id/name" &&
                    response.status == 201
                  ) {
                    swal({
                      text: "Company has been Updated",
                      button: "OK",
                    });
                    dispatch(getEditCompanyPageDetails(params.id));
                    // navigate("/Company");
                    // dispatch(getEditCompanyPageDetailsReset());
                  }
                })
                .catch((error) => {});
            }
          });
      } else if (typeof values.file == "object" && values.file.length == 0) {
        return axios
          .post("/api/editCompanyDetails", postjson)
          .then((response) => {
            if (
              response.data.CompanyData ==
                "Company is Updated with by id/name" &&
              response.status == 201
            ) {
              swal({
                text: "Company has been Updated",
                button: "OK",
              });
              dispatch(getEditCompanyPageDetails(params.id));
            }
          })
          .catch((error) => {});
      }
    }
  };

  useEffect(() => {
    if(clickSync && typeof clickSync == 'object' && Object.keys(clickSync).length > 0 && 'show' in clickSync) {
      setSyncEnable(clickSync?.show);
      setMessage(clickSync?.msg || '');
    }
  }, [clickSync]);

  return (
    <>
      <Row className="margin-b-15">
        <Col lg={6} md={6} sm={5} xs={12}>
          <h1 bsClass="" className="sPageHeading1">
            {tr["Company"]}
          </h1>
        </Col>
        <Col lg={6} md={6} sm={7} xs={12}>
          <div className="paHedDisplay text-r">
            { message != '' ? <span className="f-size-10 f-bold">{message}</span> : ''}  
            <div class="paHedFilter">        
                { !props.isRightSideVisible &&
                  <Button
                  bsPrefix=" "
                  role="button"
                  className="myBt d-md-none fillBtn"
                  title="Details"
                  onClick={() => {
                   props.showRightSideFun(true);
                   props.setCurrentView('tabView');
                  }}
                >
                  <LuFileText/>
                </Button>}
              { syncEnable == true && 
                  <Button
                    bsPrefix=" "
                    role="button"
                    className="myBt fillBtn"
                    title="Sync All"
                    onClick={()=>{props.showRightSideFun(true);props.setCurrentView('syncAll')}}
                  >
                    <PiUserSwitch />
                </Button>}
              <Link
                bsPrefix=" "
                id="hdID"
                to="javascript:void(0)"
                className="myBt plus ctrlKeyPrevent"
                disabled={submitting}
                onClick={handleSubmit(submit)}
                title={tr["Save"]}
                ref={saveObjRef}
              >
               <span>{props.loader ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/> }</span>
              </Link>
              <Link
                bsPrefix=" "
                to="/Company"
                className="myBt cancel fillBtn ctrlKeyPrevent"
                title={tr["Cancel"]}
                onClick={() => {
                  dispatch(getEditCompanyPageDetailsReset());
                }}
                ref={closeButton}
              >
                <IoClose/>
              </Link>
            </div>
            </div>
        </Col>
      </Row>
    </>
  );
};

export default reduxForm({
  form: "CompanyEditForm",
  destroyOnUnmount: false,
})(HeaderSec);
