
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
/**
@Description: routes for
**/

import axios from 'axios';
const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});
import Cookies from "universal-cookie";
const cookie = new Cookies();
let dateformat = cookie.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
dateformat = dateformat.split("~");
dateformat = dateformat[2];
//below URL used for testing purpose
//const breakFixUrl = 'https://xsm-f.mybluemix.net/api/SX_FAVOURITEOFFERINGs/';
import { GLOBAL }  from '../../components/Globals';
//let breakFixUrl =`https://xsm-f.mybluemix.net/api/breakfixitem?breakfixitemId=`;
//let breakFixUrl=`https://xsm-f.mybluemix.net/groupListSelfService?companyId=2932&group=ResolvedER`
export function loadBreakFixAuditLogDetailsSuccess(breakFixAuditLogDetails){
	//alert('in success')
	return {
		type: 'LOAD_BREAKFIXAUDITLOGDETAILS_SUCCESS',
		breakFixAuditLogDetails
	};
}

export function loadBreakFixAuditLogDetailsFailure(errorResponse){
	return {
		type: 'LOAD_BREAKFIXAUDITLOGDETAILS_FAILURE',
		errorResponse
	};
}

export function loadBreakFixAuditLogDetails(breakFixId,orderType,module,field){
	//alert('action called and parameter is '+breakFixId)
	let url = '';
	return(dispatch) =>{
		//alert("EditGroupIdAction123"+breakFixId);
		dispatch({type: "LOADER_IN_PROGRESS"});
		dispatch({type:'FETCHING_AUDIT_FOR_TIMELINE'});
		if(breakFixId == undefined || breakFixId == ""){
			//alert("H2 breakFixId not found");
		}else{
				//alert("my url is "+GLOBAL.breakFixEditUrl+breakFixId);
				if(field == 'STATUS') {
					url = GLOBAL.breakFixAuditLogUrl+"?itemId="+breakFixId+"&orderBy="+orderType+"&module="+module+"&auditField=STATUS";
				}else {
					url = GLOBAL.breakFixAuditLogUrl+"?itemId="+breakFixId+"&orderBy="+orderType+"&module="+module;
				}
				api.get(url)
					.then((breakFixAuditLogDetails)=>{
						if(!breakFixAuditLogDetails){//alert('not breakFix')
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
							throw Error(breakFixAuditLogDetails.statusText);
						}
						return breakFixAuditLogDetails;
					})
					.then(breakFixAuditLogDetails=>{
					dispatch({type:'FETCHED_AUDIT_FOR_TIMELINE'});
					    let fromList=[], toList=[], proxyRecord={}, duplicateTags=0, i=0, j=0,jwtdf = dateformat.slice(0, 10).toUpperCase(),captureFormat=false,k=0,l=0;
						breakFixAuditLogDetails.data.map((item=>{
							 if(item.auditField==="TAG"){
								item.from=JSON.parse(item.from);
							    item.to=JSON.parse(item.to);
								fromList=[];
								toList=[]; 
								proxyRecord={}; 
								duplicateTags=0; 
								i=0; 
								j=0;
								k=0;
								l=0;
								captureFormat=false;
									if(item.from.length===item.to.length){
                                      for(i=0;i<item.from.length;i++){
										for(j=0;j<item.to.length;j++){
										   if(item.from[i].tag_key!==item.to[j].tag_key){
                                              continue;
										   }
										   if(item.from[i].tag_data_type!=='DateTime'||item.from[i].tag_data_type!=='Date'){
											item.from[i].tag_data_type=null;
										   }
										   if(item.to[j].tag_data_type!=='DateTime'||item.to[j].tag_data_type!=='Date'){
											item.to[j].tag_data_type=null;
										   }
										   if(item.from[i].tag_type==='Simple' && item.to[j].tag_type==='Simple' && item.from[i].tag_value!==item.to[j].tag_value){
											fromList.push(item.from[i]);
											toList.push(item.to[j]);
											break;
										   }
										   if((item.from[i].tag_data_type===null||item.from[i].tag_data_type==="null") && (item.to[j].tag_data_type===null||item.to[j].tag_data_type==="null") && item.from[i].tag_data_type===item.to[j].tag_data_type){
											if(item.from[i].tag_type==='Composite' && item.to[j].tag_type==='Composite'){
											for(k=0;k<item.from[i].tag_value.length;k++){
												if(item.from[i].tag_value[k].tag_key===item.to[j].tag_value[k].tag_key && item.from[i].tag_value[k].tag_value!==item.to[j].tag_value[k].tag_value){
												fromList.push(item.from[i].tag_value[k]);
												toList.push(item.to[j].tag_value[k]);
												}
											}
											break;
										    }
										   }
                                           if(item.from[i].tag_key===item.to[j].tag_key && item.from[i].tag_value!==item.to[j].tag_value){
											if(item.from[i].tag_data_type==="DateTime"||item.from[i].tag_data_type==="Date"){
											  captureFormat=item.from[i].tag_data_type==="DateTime"?true:false;
                                              item.from[i].tag_value=moment(item.from[i].tag_value).format(captureFormat?jwtdf+" hh:mm A":jwtdf);
											  item.to[j].tag_value=moment(item.to[j].tag_value).format(captureFormat?jwtdf+" hh:mm A":jwtdf);
											}
											fromList.push(item.from[i]);
											toList.push(item.to[j]);						
											break;
										   }
										}
									  }
									}else if(item.from.length<item.to.length){
                                      for(j=0;j<item.to.length;j++){
										duplicateTags=0;
										for(i=0;i<item.from.length;i++){
											if(item.from[i].tag_key===item.to[j].tag_key){
												duplicateTags++;
											}
										}
										if(duplicateTags===0){
										 if(item.to[j].tag_data_type!=='DateTime'||item.to[j].tag_data_type!=='Date'){
											item.to[j].tag_data_type=null;
										 }		
										 if(item.to[j].tag_data_type!==null && item.to[j].tag_data_type!=="null"){
                                         proxyRecord=JSON.parse(JSON.stringify(item.to[j]));
										 proxyRecord.tag_value='NA';
										 fromList.push(proxyRecord);
										 if(item.to[j].tag_data_type==="DateTime"||item.to[j].tag_data_type==="Date"){
											captureFormat=item.to[j].tag_data_type==="DateTime"?true:false;
											item.to[j].tag_value=moment(item.to[j].tag_value).format(captureFormat?jwtdf+" hh:mm A":jwtdf);
										 }
										 toList.push(item.to[j]);
										 continue;
										}
										if(item.to[j].tag_type==='Simple'){
											proxyRecord=JSON.parse(JSON.stringify(item.to[j]));
											proxyRecord.tag_value='NA';
											fromList.push(proxyRecord);
											toList.push(item.to[j]);
											continue;
										}else if(item.to[j].tag_data_type===null||item.to[j].tag_data_type==="null"){
                                          for(k=0;k<item.to[j].tag_value.length;k++){
											proxyRecord=JSON.parse(JSON.stringify(item.to[j].tag_value[k]));
											proxyRecord.tag_value='NA';
											fromList.push(proxyRecord);
											toList.push(item.to[j].tag_value[k]);
											continue;
										  }  
										}
									    }
									  }	
									}else if(item.from.length>item.to.length){
									  duplicateTags=0;
									  for(i=0;i<item.from.length;i++){
										duplicateTags=0;
										for(j=0;j<item.to.length;j++){
                                          if(item.from[i].tag_key===item.to[j].tag_key){
											duplicateTags++;
										  }
										}
										if(duplicateTags===0){
											if(item.from[i].tag_data_type!=='DateTime'||item.from[i].tag_data_type!=='Date'){
												item.from[i].tag_data_type=null;
											}
											if(item.from[i].tag_data_type!==null && item.from[i].tag_data_type!=="null"){
											proxyRecord=JSON.parse(JSON.stringify(item.from[i]));
											proxyRecord.tag_value='NA';
											if(item.from[i].tag_data_type==="DateTime"||item.from[i].tag_data_type==="Date"){
												captureFormat=item.from[i].tag_data_type==="DateTime"?true:false;
												item.from[i].tag_value=moment(item.from[i].tag_value).format(captureFormat?jwtdf+" hh:mm A":jwtdf);
											}
											fromList.push(item.from[i]);
											toList.push(proxyRecord);
											continue;
										   } 
										   if(item.from[i].tag_type==='Simple'){
											    proxyRecord=JSON.parse(JSON.stringify(item.from[i]));
											    proxyRecord.tag_value='NA';
											    toList.push(proxyRecord);
											    fromList.push(item.from[i]);
												continue;
										   }else if(item.from[i].tag_data_type===null){
											for(k=0;k<item.from[i].tag_value.length;k++){
                                                proxyRecord=JSON.parse(JSON.stringify(item.from[i].tag_value[k]));
											    proxyRecord.tag_value='NA';
											    toList.push(proxyRecord);
											    fromList.push(item.from[i].tag_value[k]);
												continue;
										    }
										   }
										}
									  }
									}
									item.from=fromList;
									item.to=toList;
								}
						}));
						dispatch(loadBreakFixAuditLogDetailsSuccess(breakFixAuditLogDetails))
						dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
					})
					.catch(err => {
						console.log(err);
					});
					//console.log("not calling loadBreakFixEditDetails");
		}
	};
}

