
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import { ListGroup, Row, Col, Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AuditLogs from "../../governance-meeting/common/AuditLogs";
import { PiPencilSimpleBold } from "react-icons/pi";
import { IoClose } from "react-icons/io5";

const AuditLog = ({ rowData, isFetching, setOnClose, setOrderValue}) => {
  const translator = useSelector((state) => state.spcmReducer.tr);
  return (
    <div className="stickyArea rBoxStyle">
      <Row>
        <Col xs={12}>
          <div className="rPageHeadActBtn d-md-none">
            <ul>
              <li>
                <button
                  title={translator["Minimize the details panel"]}
                  bsClass=""
                  onClick={() => setOnClose(false)}
                >
                  <IoClose />
                </button>
              </li>
            </ul>
          </div>
          <div className="rPageHeading py-3">
            <div className="offNam">Audit Log</div>
          </div>
        </Col>
      </Row>
      <div className="rBoxGap">
        <div className="rBoxTabs hideTabNav">
          <AuditLogs data={rowData} setOrderValue={setOrderValue}/>
        </div>
      </div>
    </div>
  );
};

export default AuditLog;
