
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
// import BasicTable from "../../../common/ReactTable/BasicTable";
import { useSelector } from "react-redux";
import ListLoader from "../../../common/loaders/ListLoader";
import TableComponent from "../../../common/ReactTable/TableComponent";
import CloseBtnRightPanel from "../../../common/closeBtnRightPanel";

const EditCategoryShortDesc = (props) => {
  const offeringList = useSelector((state) => state.spcmReducer.serviceListData);
  const isLoading = useSelector((state) => state.isFetchingDetails.isFetching);
  const translator = useSelector(state => state.spcmReducer.tr)

  const tableColumns = [
    {
      name: "Service Name",
      selector: (row) => row.OFFERING_NAME,
      sortable: true,
    },
    {
      name: "Work Item Type",
      selector: (row) => row.WORK_ITEM_TYPE,
      format: (row) =>
        row.WORK_ITEM_TYPE === "serviceRequest"
          ? "Service Request"
          : row.WORK_ITEM_TYPE === "standardChange"
          ? "Standard Change"
          : "Incident",
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.STATUS,
      sortable: true,
    },
  ];

  const filterColumns = ["OFFERING_NAME", "WORK_ITEM_TYPE", "STATUS"];
  return (
    <>
      <CloseBtnRightPanel showRightSide={props.rightEditPanel} />
      <div className="rPageHeading">
        <div className="offNam margin-t-5 margin-b-5">
          {translator["Services"]}
        </div>
      </div>
      <div className="rBoxGap">
        {isLoading ? (
          <ListLoader />
        ) : (
          <div className="dtaTableDv integrateHubTable">
          <TableComponent
            data={offeringList}
            columns={tableColumns}
            headerColumns={filterColumns}
            pagination={true}
            onRowSelect={() => {}}
            uniqueKey={"OFFERING_ID"}
          />
          </div>
        )}
      </div>
    </>
  );
};

export default EditCategoryShortDesc;
