
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Row, Col, Form, Alert, ButtonToolbar, Button } from "react-bootstrap";
import {
  _textArea,
  _dropDown,
  _inputField,
  _radio,
  _checkbox,
  _ckeditor,
} from "../../../common/formFields";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import axios from "axios";
import { getAssessDisclaimiers } from "../../../../actions/cmdb/relateAssetCIAction";
import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";

class CmdbDisclaimerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentForm: "approver_disclaimer",
      postingAttribute: false,
      initial_disclaimer: null,
    };
    this.submitForm = this.submitForm.bind(this);
  }

  getFormHandler(e) {
    this.setState({ currentForm: e });
  }

  submitForm(values) {
    if (this.props.STATUS == "7" && this.props.SUB_STATUS == "12") {
      throw new SubmissionError({
        _error:
          this.props.tr[
            "Changes are not allowed in Status = Deployed and Sub-Status = Functional."
          ],
      });
    }
    console.log("console values");
    console.log(values);
    //let CI_ID = 594552;
    this.setState({ postingAttribute: true });
    if (values.APPROVER_DISCLAIMER) {
      values.APPROVER_DISCLAIMER =
        values.APPROVER_DISCLAIMER.replace(/<[^>]+>/g, "").trim() == ""
          ? ""
          : values.APPROVER_DISCLAIMER.trim();
    }

    if (this.state.currentForm == "approver_disclaimer") {
      //alert(JSON.stringify(this.props.assetCIDisclaimer));
      if (
        this.props.assetCIDisclaimer.disclaimer &&
        this.props.assetCIDisclaimer.disclaimer.length > 0
      ) {
        let valuesObj = {};
        valuesObj.approver_disclaimer = values.APPROVER_DISCLAIMER;
        return axios
          .patch("/api/patchAssesstsDisclaimers/" + this.props.CI_ID, valuesObj)
          .then((response) => {
            console.log(response);
            this.setState({ postingAttribute: false });
            this.props.changeActionView("assetCiDetails");
          })
          .catch((error) => {
            console.log(error);
            this.setState({ postingAttribute: false });
            throw new SubmissionError({
              _error: error.response.data[0].message,
            });
          });
      } else {
        let valuesObj = {};
        valuesObj.approver_disclaimer = values.APPROVER_DISCLAIMER;
        valuesObj.ci_id = this.props.CI_ID;
        // return swal({
        //   text: this.props.tr['Do you want the disclaimer to be enabled from now on?'],
        //   buttons: [this.props.tr['Yes'], this.props.tr['No']]
        // })
        //   .then((willVisible) => {
        //     // alert(willVisible);
        //     if (willVisible == true) {
        //       valuesObj.ISVISIBLE = "N";
        //     } else {
        valuesObj.ISVISIBLE = "Y";
        // }
        return axios
          .post("/api/postAssesstsDisclaimers", valuesObj)
          .then((response) => {
            console.log(response);
            this.setState({ postingAttribute: false });
            this.props.changeActionView("assetCiDetails");
          })
          .catch((error) => {
            console.log(error);
            this.setState({ postingAttribute: false });
            throw new SubmissionError({
              _error: error.response.data[0].message,
            });
          });
        //});
      }
    }
  }

  componentDidMount() {
    this.props.getAssessDisclaimiers(this.props.CI_ID);
  }

  render() {
    //alert()
    const { error, handleSubmit } = this.props;
    return (
      <div className="borderForm margin-b-10">
        <Row>
          <Col xs={12}>
            <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
              <ul>
                <li>
                <button
                    type='button'
                    title="Minimize the right panel"
                    bsClass=""
                    bsStyle=""
                    className="closerightPanelBtn"
                    onClick={() => {
                    this.props.rightEditPanel(false);
                    }}
                >
                    <IoClose/>
                </button>
                </li>
              </ul>
            </div>
            <div className="rPageHeading">
              <div className="offNam margin-t-5 margin-b-5">
                {this.props.tr["Approver Disclaimer"]}
              </div>
            </div>
          </Col>
        </Row>

        <div className="rBoxGap">
          <form onSubmit={handleSubmit(this.submitForm)}>
            {error == undefined || error == null ? null : (
              <Alert variant="danger" className="errorMessageSec">
                <p>{error}</p>
              </Alert>
            )}
            {this.state.currentForm == "approver_disclaimer" ? (
              <div>
                <Row>
                  <Col md={12}>
                    <Form.Group>
                      <Form.Label bsClass="">
                        {this.props.tr["Approver Disclaimer"]}
                      </Form.Label>
                      <Field
                        name="APPROVER_DISCLAIMER"
                        component={_ckeditor}
                        className="reactQuillEditor-area"
                        maxlength={1500}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <ButtonToolbar className="black">
                      {this.props.isRoleDisabled 
                      ? (
                        <Button
                        title="Save"
                        disabled={this.state.postingAttribute}
                        bsSize="small"
                        bsStyle="primary"
                        className="rgSidrkBtn smallBtn"
                        onClick={handleSubmit(this.submitForm)}
                      >
                        {this.state.postingAttribute ? (
                          <ImSpinner6 className="icn-spinner"/>
                        ) : null}{" "}
                        {this.props.tr["Save"]}
                      </Button>
                      ) : null}
                      <Button
                        title="Close"
                        onClick={() => {
                          this.props.changeActionView("assetCiDetails");
                        }}
                        bsSize="small"
                        bsStyle="text"
                        className="rgSiLigBtn smallBtn"
                      >
                        {this.props.tr["Close"]}
                      </Button>
                    </ButtonToolbar>
                  </Col>
                </Row>
              </div>
            ) : (
              <div>
                <Row>
                  <Col md={12}>
                    <Form.Group>
                      <Form.Label bsClass="">
                        {this.props.tr["Service Disclaimer"]}
                      </Form.Label>
                      <Field
                        name="SERVICE_DISCLAIMER"
                        component={_ckeditor}
                        className="reactQuillEditor-area"
                        maxlength={1500}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <ButtonToolbar className="black">
                      <Button
                        title="Save"
                        disabled={this.state.postingAttribute}
                        bsSize="small"
                        bsStyle="primary"
                        className="rgSidrkBtn smallBtn"
                        onClick={handleSubmit(this.submitForm)}
                      >
                        {this.state.postingAttribute ? (
                          <ImSpinner6 className="icn-spinner"/>
                        ) : null}{" "}
                        {this.props.tr["Save"]}
                      </Button>
                      <Button
                        title="Close"
                        bsSize="small"
                        bsStyle="text"
                        className="rgSiLigBtn smallBtn"
                      >
                        {this.props.tr["Close"]}
                      </Button>
                    </ButtonToolbar>
                  </Col>
                </Row>
              </div>
            )}
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ assetCIDisclaimer }) => {
  return {
    assetCIDisclaimer,
    initialValues:
      assetCIDisclaimer &&
      assetCIDisclaimer.disclaimer &&
      assetCIDisclaimer.disclaimer.length > 0
        ? {
            APPROVER_DISCLAIMER:
              assetCIDisclaimer.disclaimer[0].APPROVER_DISCLAIMER,
          }
        : null,
    //initialValues: {"APPROVER_DISCLAIMER":assetCIDisclaimer[0].APPROVER_DISCLAIMER}
  };
};

CmdbDisclaimerForm = reduxForm({
  form: "disclaimersForm",
  enableReinitialize: true,
})(CmdbDisclaimerForm);
export default connect(mapStateToProps, { getAssessDisclaimiers })(
  CmdbDisclaimerForm
);
