
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { updateContractAction } from "../../../../actions/ham/contractAction";
import { reduxForm } from "redux-form";
import { ImAttachment, ImSpinner6 } from "react-icons/im";
import { IoSaveOutline, IoClose } from "react-icons/io5";
import { LuActivitySquare } from "react-icons/lu";
import { Link } from "react-router-dom";
import { TbSubtask } from "react-icons/tb";
import { getContractPageDetails } from "../../../../actions/ham/contractAction";
import Cookies from "universal-cookie";
import moment from "moment";
import momentTz from "moment-timezone";
import datetimeConvertor from "../../../../helpers/ISO8601converter";
import {GLOBAL} from '_Globals';
import { MyContext } from '_MyContext';
const cookies = new Cookies();

let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));

dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

const HeaderSec = (props) => {
  const tr = useSelector((state) => state.spcmReducer.tr);
  const dispatch = useDispatch();
  const params = useParams();
  const initialValue = useSelector((state) => state.contractEditList);
  //console.log("CheckInitialVALUES===1234=", initialValue);
  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const saveObjRef = useRef(null);

  useEffect(() => {
    if(!(initialValue && ((Array.isArray(initialValue) && initialValue.length == 0) ||
        ("data" in initialValue && initialValue.data.length == 0)))) {
         context.keyDownHandler("","","",saveObjRef.current);
        }
        else {
          context.keyDownHandler(closeButton.current,"","","");
        }
        context.newTabPrevent();
	}, [initialValue]);

  useEffect(() => {
    dispatch(getContractPageDetails(params.CONTRACT_NUM));
  }, []);
  const submit = async (values) => {
    var phoneno = /^[0-9;+]*$/;
    let CONTRACT_NUM = params.CONTRACT_NUM;
    let isValid = true;
    console.log("EditHeaderDept", values);
    let endDate = typeof values.end_date === "string" ? moment(datetimeConvertor(values.end_date, jwtdf + " HH:mm:ss")).format('DD-MM-YYYY HH:mm:ss') : values.end_date.format('DD-MM-YYYY HH:mm:ss');
    let startDate = typeof values.start_date === "string" ? moment(datetimeConvertor(values.start_date, jwtdf + " HH:mm:ss")).format('DD-MM-YYYY HH:mm:ss') : values.start_date.format('DD-MM-YYYY HH:mm:ss');
    let lastReviewDate = values.last_review_date
      ? typeof values.last_review_date === "string"
        ? moment(
            datetimeConvertor(values.last_review_date, jwtdf + " HH:mm:ss")
          ).format("DD-MM-YYYY HH:mm:ss")
        : values.last_review_date.format("DD-MM-YYYY HH:mm:ss")
      : null;
    let nextReviewDate = values.next_review_date
      ? typeof values.next_review_date === "string"
        ? moment(
            datetimeConvertor(values.next_review_date, jwtdf + " HH:mm:ss")
          ).format("DD-MM-YYYY HH:mm:ss")
        : values.next_review_date.format("DD-MM-YYYY HH:mm:ss")
      : null;

    let dateRevision = values.date_revision
      ? typeof values.date_revision === "string"
        ? moment(
            datetimeConvertor(values.date_revision, jwtdf + " HH:mm:ss")
          ).format("DD-MM-YYYY HH:mm:ss")
        : values.date_revision.format("DD-MM-YYYY HH:mm:ss")
      : null;
    let create_date = typeof values.createDate === "string" ? moment(datetimeConvertor(values.createDate, jwtdf + " HH:mm:ss")).format('DD-MM-YYYY HH:mm:ss') : values.createDate.format('DD-MM-YYYY HH:mm:ss');
    let updated_date = typeof values.updatedDate === "string" ? moment(datetimeConvertor(values.updatedDate, jwtdf + " HH:mm:ss")).format('DD-MM-YYYY HH:mm:ss') : values.updatedDate.format('DD-MM-YYYY HH:mm:ss');
    
    if (!values.name || values.name == "" || values.name.trim() == "") {
      props.setNameErr("error");
      isValid = false;
    } else {
      props.setNameErr("");
    }

    if (!values.business_function || values.business_function == "") {
      props.setBusinessErr("error");
      isValid = false;
    } else {
      props.setBusinessErr("");
      // isValid = true;
    }

    if (!values.company) {
      props.setCompanyErrorState("error");
      isValid = false;
    } else {
      props.setCompanyErrorState("");
    }

    if (!values.administrator_name || values.administrator_name== "" || values.administrator_name.trim() == "") {
      GLOBAL.adminownerErrorColor = "error";
      isValid = false;
    } else {
      GLOBAL.adminownerErrorColor = "";
    }

    if (!values.status || values.status == "") {
      props.setStatusErr("error");
      isValid = false;
    } else {
      props.setStatusErr("");
      // isValid = true;
    }

    if (
      !values.sub_status ||
      values.sub_status == "" ||
      values.sub_status_name == "" ||
      !values.sub_status_name
    ) {
      props.setSubStatusErr("error");
      isValid = false;
    }

    if (values.vendor_contact && values.vendor_contact.trim() !== "") {
      if (values.vendor_contact.match(phoneno)) {
        props.setNumberErr("");
      } else {
        props.setNumberErr("error");
        isValid = false;
      }
    } else {
      props.setNumberErr("");
    }

    if (!values.start_date || values.start_date == "") {
      props.setStartDateError("error");
      isValid = false;
    } else {
      props.setStartDateError("");
    }

    if (!values.end_date || values.start_date == "") {
      props.setEndDateError("error");
      isValid = false;
    } else {
      props.setEndDateError("");
    }

    if (values.start_date == values.end_date) {
      props.setStartDateError("error");
      props.setEndDateError("error");
      isValid = false;
    } else {
      props.setEndDateError("");
      props.setStartDateError("");
    }
    if ((values.last_review_date && values.next_review_date) && (values.last_review_date == values.next_review_date)) {
      props.setlastReviewDateError("error");
      props.setnextReviewDateError("error");
      isValid = false;
    } else {
      props.setnextReviewDateError("");
      props.setlastReviewDateError("");
    }

    if((values.last_review_date && values.next_review_date) &&  (moment(values.last_review_date).isSameOrAfter(values.next_review_date))){
      props.setlastReviewDateError("error");
      props.setnextReviewDateError("error");
      isValid = false;
    }else{
      props.setlastReviewDateError('');
      props.setnextReviewDateError('');
    }
    let valid_start_date = momentTz(values.start_date, jwtdf + " hh:mm A");
    let valid_end_date = momentTz(values.end_date, jwtdf + " hh:mm A");
    if(momentTz(valid_start_date).isSameOrAfter(valid_end_date)){ 
      props.setStartDateError("error");
      isValid = false;
    } else { 
      props.setStartDateError('');
    }
    let valid_last_review_date = momentTz(values.last_review_date, jwtdf + " hh:mm A");
    let valid_next_review_date = momentTz(values.next_review_date, jwtdf + " hh:mm A");
    if(momentTz(valid_last_review_date).isSameOrAfter(valid_next_review_date)){
      props.setlastReviewDateError("error")
      isValid = false;
    } else {
      props.setlastReviewDateError('')
    }

    if(!values.administrator_group || values.administrator_group.length == 0){
      //console.log("inside admin")
      props.setAdminErrorColor('error');
      isValid = false ;
    }
    if (isValid == true) {
      let postjson;
      postjson = {
        CONTRACT_NUM: CONTRACT_NUM,
        // contract_num: values.contract_num,
        contract_name: values.name,
        contract_type: (values.contract_type == "" || values.contract_type == null) ? "" : values.contract_type,
        business_function: values.business_function,
        vendor: (values.vendor == "" || values.vendor == null) ? "" : values.vendor,
        vendor_id: values.vendor_id == "" || values.vendor_id == null ? null : values.vendor_id,
        vendor_contact: (values.vendor_contact == "" || values.vendor_contact == null) ? "" : (values.vendor_contact.trim() == "" ? "" : values.vendor_contact.trim()),
        company: values.company,
        company_id: values.company_id,
        start_date: startDate,
        end_date: endDate,
        administrator: values.administratorName,
        administrator_id: values.administrator_id.toString(),
        cost_center: (values.cost_center_name == "" || values.cost_center_name == null) ? "" : values.cost_center_name,
        cost_center_id: (values.cost_center_id == "" || values.cost_center_id == null) ? 0 : values.cost_center_id,
        budget_sponsor: (values.budget_sponsor == "" || values.budget_sponsor == null) ? "" : values.budget_sponsor,
        budget_sponsor_id: (values.budget_id == "" || values.budget_id == null) ? null : values.budget_id,
        status: values.status,
        status_name: values.status_name,
        sub_status: values.sub_status,
        sub_status_name: values.sub_status_name,
        description: (values.description == "" || values.description == null) ? "" : (values.description.trim() == "" ? "" : values.description.trim()),
        version: (values.version == "" || values.version == null) ? "" : (values.version.trim() == "" ? "" : values.version.trim()),
        last_review_date: lastReviewDate,
        next_review_date: nextReviewDate,
        date_revision: dateRevision,
        createDate: create_date,
        createdBy: values.createdBy ? values.createdBy : null,
        created_by_id: values.created_by_id,
        updatedBy: values.updatedBy ? values.updatedBy : null,
        updatedDate: updated_date,
        updated_by_id: values.updated_by_id,
        administrator_group: values.administrator_group,
        administrator_group_id: values.administrator_group_id
      };
      dispatch(updateContractAction(postjson));
    }
  };

  let { handleSubmit, submitting } = props;
  return (
    <>
      <Row className="margin-b-15">
        <Col lg={4} md={6} sm={6} xs={5}>
          <h1 bsClass="" className="sPageHeading1">
            {tr["Contract"]}
            <div className="order positsion-re">{params.CONTRACT_NUM}</div>
          </h1>
        </Col>
        <Col lg={8} md={6} sm={6} xs={7}>
          <div className={`float-r toprigLnk fulfillHeader`}>
            {initialValue &&
            ((Array.isArray(initialValue) && initialValue.length == 0) ||
              ("data" in initialValue && initialValue.data.length == 0)) ? (
              <div></div>
            ) : (
              <Nav className="icnlnk riggep float-l" as="ul">
                <Nav.Item as="li" title={tr["Activity Details"]} eventKey={2}>
                  <a
                    className={props.view === "activity" ? "active" : ""}
                    href="javascript:void(0)"
                    onClick={() => {
                      props.setView("activity");
                      props.rightEditPanel(true);
                    }}
                  >
                    <LuActivitySquare />
                  </a>
                </Nav.Item>
                <Nav.Item as="li" title={tr["Attachment"]} eventKey={0}>
                  <a
                    href="javascript:void(0)"
                    className={props.view === "attachment" ? "active" : ""}
                    onClick={() => {
                      props.setView("attachment");
                      props.rightEditPanel(true);
                    }}
                  >
                    <ImAttachment />
                  </a>
                </Nav.Item>
                <Nav.Item
                  title={tr["Relationships"]}
                  eventKey={4}
                  as="li"
                >
                  <a
                    className={props.view === "relationship" ? "active" : ""}
                    href="javascript:void(0)"
                    onClick={() => {
                      props.setView("relationship");
                      props.rightEditPanel(true);
                    }}
                  >
                    <TbSubtask />
                  </a>
                </Nav.Item>
              </Nav>
            )}
            <Nav as="ul" className="icnlnk">
            {initialValue &&
            ((Array.isArray(initialValue) && initialValue.length == 0) ||
              ("data" in initialValue && initialValue.data.length == 0)) ? (
              <div></div>
            ) : (
              <Nav.Item
                as="li"
                eventKey={2}
                disabled={submitting}
                title={tr["Save"]}
              >
                <Link to="javascript:void(0)" className="ctrlKeyPrevent" onClick={handleSubmit(submit)} ref={saveObjRef}>
                  <span>
                    {props.loader ? (
                      <ImSpinner6 className="icn-spinner" />
                    ) : (
                      <IoSaveOutline />
                    )}
                  </span>
                </Link>
              </Nav.Item>
              )}
              <Nav.Item as="li" title={tr["Close"]} className="ctrlKeyPrevent" eventKey={3}>
                <Link to="/contracts" ref={closeButton}>
                  <IoClose />
                </Link>
              </Nav.Item>
            </Nav>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default reduxForm({
  form: "EditContract",
})(HeaderSec);
