
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, {Component, PropTypes } from 'react';
import {Button} from 'react-bootstrap';
import AutoSuggest from 'react-autosuggest';
import {GLOBAL} from '_Globals';
import axios from 'axios';
let selectOptions = [];
let renderFor = '';

const api = axios.create({
  headers: {'Pragma': 'no-cache', 'Cache-control': 'no-store'},
});

const getSuggestions = (value,options) =>{
   const inputValue = value.trim().toLowerCase();
   const inputLength = inputValue.length;
    if(inputLength >2){
      selectOptions =options;
    }
    // return inputLength < 2 ? []: selectOptions.filter(option => option.label.toLowerCase().indexOf(inputValue) > -1);
   return inputLength < 2 ? []: selectOptions.filter(option => option.fullname.toLowerCase());
};

  // const getSuggestionValue = suggestion => suggestion.label.indexOf == -1 ? '' : suggestion.label.substring(0,suggestion.label.indexOf('|'));
  const getSuggestionValue = suggestion => suggestion.fullname.indexOf == -1 ? '' : suggestion.fullname;

  function renderSuggestion(suggestion) {
    console.log("suggestion++++++",suggestion);
    let suggestionlabel =[];
    return (
      <Button className='suggBtn' bsPrefix=' '  onClick = {()=>{
        // suggestionlabel= suggestion.label.split("|");
        // GLOBAL.breakFixConsumerName = suggestionlabel[0];
        GLOBAL.breakFixConsumerName = suggestion.fullname;
        // GLOBAL.breakFixConsumerID = suggestion.value;
        GLOBAL.breakFixConsumerID = suggestion.userId;
        GLOBAL.servicelocationid=suggestion.locationId;
        GLOBAL.servicelocationname=suggestion.locationName;
        GLOBAL.Vip=suggestion.vip;
        // GLOBAL.servicedepartmentid=suggestion.deptid;
        GLOBAL.servicedepartmentid=suggestion.departmentId;
        GLOBAL.servicedepartmentname=suggestion.deptname;
      }}>
        {/* {suggestion.label} */}
        {suggestion.fullname}<div>{suggestion.email}{" - "}{suggestion.companyName}</div>
        </Button>
    );
  }


class RFReactSelect extends Component {
  constructor(props){
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.onConsumerSelected = this.onConsumerSelected.bind(this);
    this.validateField = this.validateField.bind(this);
    this.state = {
      value: '',
      suggestions: []
    };
  }
  onChange(event,{newValue}){
    if(typeof this.props.onType!=='undefined' && newValue!==''){
      if(newValue.localeCompare(this.state.value)){
        this.props.onType(false);
      }
    }
    this.setState({
      value: newValue
    });
    if(newValue===''){
      if(typeof this.props.clearGroupandIndividual!=='undefined'){
        this.props.clearGroupandIndividual();
      }
      if(typeof this.props.showConsumerInfoIcon !== 'undefined'){
        this.props.showConsumerInfoIcon(false);
      }
    }
  }
  // onSuggestionsFetchRequested ({value}){
  //   this.setState({
  //     suggestions: getSuggestions(value,this.props.options)
  //   });
  // }

  componentWillReceiveProps(nextProps) {
    if (this.props.onCreateUsingSelect != undefined && nextProps.onCreateUsingSelect != undefined) {
      if (nextProps.onCreateUsingSelect != this.props.onCreateUsingSelect) {
        this.setState({
          value: ''
        })
      }
    } 
  }


  onSuggestionsFetchRequested({value}) {
    if(this.state.value.length===0 && typeof this.props.onType!=='undefined'){
      this.props.onType(false);
    }
    console.log('value', value);

    if(typeof this.props.updateConsumerInfoState!=='undefined'){
      this.props.updateConsumerInfoState('');
    }
    // if( !isNaN(parseInt(value, 10)) ){
      let companyId = parseInt(this.props.companyId, 10);
      
      let consumerData=[];
      let consumerDataDetails=[];
      let myObj={};

      if (value.length > 3) {
        let str1 = {};
        str1.companyId = companyId;
        str1.like = value;
        str1 = JSON.stringify(str1);
        api.get(GLOBAL.consumerUsersNewUrl, {headers: {'query': str1}}).then((response)=>{
          //alert("hi123")
          consumerData = response.data;
          console.log("consumerData::",consumerData);
          for (var i = 0; i < consumerData.length; i++) {
            myObj = {value:'',label:'',locationid:'',locationname:'',deptid:'',deptname:'',vip:''};
            myObj.value=consumerData[i].userId;
            // myObj.label=consumerData[i].fullname+"|"+consumerData[i].email+"|"+consumerData[i].companyName;
            myObj.label=consumerData[i].fullname;
            myObj.locationid=consumerData[i].locationId
            myObj.locationname=consumerData[i].locationName
            myObj.deptid=consumerData[i].departmentId
            myObj.deptname=consumerData[i].departmentName
            myObj.vip=consumerData[i].vip
            //GLOBAL.consumerCompanybreakfix=consumerData[i].companyId;
            consumerDataDetails.push(myObj);
          }
          console.log('consumerDataDetails - ',consumerDataDetails);
          consumerDataDetails = Array.from(new Set(consumerDataDetails));
          this.setState({
            // suggestions: getSuggestions(value, consumerDataDetails)
            suggestions: getSuggestions(value, consumerData)
          });
        });
      } else {
        this.setState({
          suggestions: []
        });
      }
    // }
  }

  onSuggestionsClearRequested (){
    this.setState({
      suggestions: []
    });
    if(this.state.value==''){
      GLOBAL.breakFixConsumerID="";
      GLOBAL.breakFixConsumerName='';
      GLOBAL.Vip='';
      if(typeof this.props.setshowVIPIcon!=='undefined'){
          this.props.setshowVIPIcon(GLOBAL.Vip);
      }
      if(typeof this.props.checkforAMSattributes!=='undefined'){
        this.props.checkforAMSattributes('','');
      }
    }
  }
  validateField(){
    if(GLOBAL.breakFixConsumerID==""){
        this.props.setErrorColor('error','consumer');
    }else{
        this.props.setErrorColor('','consumer');
    }
  }

  onConsumerSelected(event, {suggestion}){
    if(typeof this.props.afterConsumerSelected!=='undefined'){
      this.props.afterConsumerSelected();
    }
    if(typeof this.props.onConsumerSelected!=='undefined'){
      this.props.onConsumerSelected(suggestion);
    }

    // console.log('suggestion',suggestion)
    // this.props.onConsumerNameChange(suggestion.companyname);
    

  }

  render(){
    console.log("GLOBAL.breakFixConsumerID",GLOBAL.Vip);
    const { value, suggestions } = this.state;
    if(this.props.renderFor == "investigationTeam") {
      renderFor = "investigationTeam";
    }
    const inputProps = {
    placeholder: this.props.placeHolder!='' && this.props.placeHolder!=undefined?this.props.placeHolder:this.props.renderFor == 'investigationTeam' ? "Name, UserId, E-mail" : "Enter User Name",
    className: `form-control ${this.props.errorColor}`,
    value,
    onChange: this.onChange,
    onBlur: this.validateField
  };
    return (
        <AutoSuggest
            suggestions = {suggestions}
            onSuggestionsFetchRequested = {this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue = {getSuggestionValue}
            renderSuggestion = {renderSuggestion}
            inputProps = {inputProps}
            onSuggestionSelected={this.onConsumerSelected}

          />
    );
}
}
export default RFReactSelect;
