
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState} from "react";
import { Row, Col, Button } from 'react-bootstrap';
import {useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import {getUserListAction} from "../../../../actions/foundation/userAction";
import {HiPlus, HiOutlineRefresh} from "react-icons/hi";
import { LuFilterX } from "react-icons/lu";

const UserHeader = (props) => {
    const tr = useSelector((state) => state.spcmReducer.tr);
    const dispatch = useDispatch();
    
    const clearAllFilters = () => {
        props.setCompanySearchInput('');
        props.setFullNameSearchInput('');
        props.setEmailSearchInput('');
        props.setUserNameSearchInput('');
        props.setEmployeeIdSearchInput('');
        props.setSelectedStatusValue([]);
        props.setSelectedUserTypeValue([]);
        props.setMultiSelectParams([], []);
        props.setLimit(10);
        props.setPage(1);
        props.setsearchValue('');
        props.setsearchKey('');

		dispatch(getUserListAction(1, 10, "fullName", "asc", "", "", [], []));
        props.setClearAllFilterToggle(false);
        props.setRightSideVisible(false);
	}

    const refreshUserList = () => {
        dispatch(
          getUserListAction(
            props.page,
            props.limit,
            "fullname",
            "asc",
            props.searchKey,
            props.searchValue,
            props.userType,
            props.status
          )
        );
	}
    return (
    <Row className="margin-b-15">
        <Col sm={8} xs={7}>
            <h1 bsClass="" className="sPageHeading1">{tr["User"]}</h1>
        </Col>
        <Col sm={4} xs={5}>
            <div className="paHedFilter">
                {props.clearAllFilterToggle ? <div className="overlayDv"><Button title="Clear All Filters" role="button" onClick={() => { clearAllFilters() }} bsPrefix=' ' className="myBt clrbtn"><LuFilterX/></Button></div> : ""}
                <Link to="/createUser" role="button" className="myBt plus fillBtn" title={tr["Create"]} ><HiPlus/></Link>
                <Button bsPrefix=' ' title={tr["Refresh"]} onClick={refreshUserList} role="button" className="myBt"><HiOutlineRefresh className="refreshFlip" /></Button>
            </div>
        </Col>
    </Row>
    )
}

export default UserHeader;