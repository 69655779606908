
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import _ from "lodash";
import ListLoader from "../../common/loaders/ListLoader";
import NoDataAvailable from "../../common/NoDataAvailable";

const Participants = ({ trackData, isFetching }) => {
  return isFetching ? (
    <div className="fnloader">
      <ListLoader />
    </div>
  ) : trackData?.length === 0 || !trackData ? (
    <div className="custom-container-fluid">
      <NoDataAvailable />
    </div>
  ) : (
    <table cellpadding="0" cellspacing="0" className="table participants-table">
      <tbody>
        {trackData?.map((item, index) => {
          return _.isEmpty(item?.participantName) &&
            _.isEmpty(item?.participantCompany) &&
            _.isEmpty(item?.isPresent) &&
            _.isEmpty(item?.isAbsent) &&
            _.isEmpty(item?.delegates) ? null : (
            <tr>
              <td>
                {item?.participantName || ""}
                {item?.participantCompany
                  ? `| ${item?.participantCompany}`
                  : ""}
                <span className="participant-email">
                  {item?.participantEmail || ""}
                </span>
              </td>
              <td className="participant-fa" align="right" valign="middle">
                {item?.isPresent && (
                  <>
                    <span className="present"></span>Present
                  </>
                )}
                {item?.isAbsent && (
                  <>
                    <span className="absent"></span>Absent
                  </>
                )}
                {item?.delegates !== "" && item?.delegates && (
                  <span className="delegate-user">
                    (Delegate: {item?.delegates})
                  </span>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Participants;
