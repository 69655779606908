
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table'
import ListGroup from 'react-bootstrap/ListGroup';
import Accordion from 'react-bootstrap/Accordion';
import { MyContext } from '_MyContext';
import { Link } from "react-router-dom";

import axios from 'axios';
import { connect } from 'react-redux';
import { loadImplPlanList } from "_Actions/common/attachmentAction";
import { loadReviewOrderList, loadPendingReviewOrderList, loadReviewOrderDetails, loadReviewBreakFixOrderDetails, loadReviewOrderEngineDetails } from '_Actions/reviews/reviewAction';
import { GLOBAL } from '_Globals';
import { bindActionCreators } from 'redux';
import ReactStars from 'react-stars';
import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";

let remarkData = "";
let rating = "";

import ListLoader from '../common/loaders/ListLoader';
import StarRatingComponent from 'react-star-rating-component';
import _ from 'lodash';
import { navigationHooks } from '../../helpers/NavigationHook';
import { PiEyeBold } from 'react-icons/pi';

const Joi = require('joi');
class MySurveyActions extends React.Component {
	static contextType = MyContext;
	constructor(props) {
		super(props);
		this.state = {
			checkedYes: false,
			commentAreaForReorder: '',
			issueValidationError: '',
			postStatusFlag: 0,
			postErrorMessage: '',
			remarks: '',
			commentsErrorMessage: '',
			reOrderIndicator: 0,
			ratingCount: '',
			checkedNo: false,
			reasonData: '',
			tabKey: 1,
			ratingHoverCount:null
		};
		this.remarkData = this.remarkData.bind(this);
		this.postRemarkData = this.postRemarkData.bind(this);
		//this.props.loadReviewOrderEngineDetails(this.props.quickViewItemId, "", "Item");
		this.onChangeReOrder = this.onChangeReOrder.bind(this);
		this.ratingChanged = this.ratingChanged.bind(this);
		this.onChangeReOrderNo = this.onChangeReOrderNo.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
		this.renderQuestionCategoryWise = this.renderQuestionCategoryWise.bind(this);
		this.renderIndividualCategoryQuestions = this.renderIndividualCategoryQuestions.bind(this);
	}

	componentDidMount() {
		this.context.keyDownHandler(this.closeButton,this.editButton,this.globalSearchRef,this.saveObjRef);
	}
	componentDidUpdate() {
		this.context.keyDownHandler(this.closeButton,this.editButton,this.globalSearchRef,this.saveObjRef);
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			ratingCount: '',
			checkedYes: false,
			checkedNo: false,
			commentsErrorMessage: '',
			postErrorMessage: '',
			commetValidationError: ''

		});
		if (this.props.reviewDisplayNumber !== nextProps.reviewDisplayNumber) {
			remarkData = '';
			this.setState({
				reasonData: ''
			});
		}
	}
	getReOpenReason(reason) {
		// reasonData = reason.target.value;
		this.setState({ reasonData: reason.target.value.trim().replace(/['"]/g, "") });

	}
	postReOrder() {
		//alert(this.props.quickViewItemId);
		let listFilter = this.props.listFilter;
		let ref = this;
		if (this.state.reasonData == "") {
			//alert("true");
			this.setState({
				issueValidationError: "error",
				postErrorMessage: this.props.tr['Please provide reason']
			});
		} else {
			//alert("false");
			this.setState({
				issueValidationError: "",
				postErrorMessage: ""
			});
			//=================post start
			//alert(this.props.reviewModuleValue);
			//alert(this.props.quickViewItemId);
			ref.setState({
				reOrderIndicator: 1
			});
			let itemId = this.props.quickViewItemId;
			//let module=this.props.reviewModuleValue;
			let module = "Fullfilment";
			axios.patch(GLOBAL.fulfillmentpost + itemId, {

				"itemStatus": "70",
				"statusReopen": true,
				"reopenReason":ref.state.reasonData,
				"asgUserid":"0",
				"asgUsername":"",
				"modifiedBy": "",
				"modifiedByName": "",
				"feedbackRating": this.state.ratingCount


			})
				.then(function (response) {
					//alert(response.statusText);
					//browserHistory.push("/reviews");
					//postActivityStart here
					//alert(response.statusText)
					if (response.status === 201) {
						axios.post(GLOBAL.breakFixActivityLogPostUrl, {

							"itemId": itemId,
							"description": ref.state.reasonData,
							"isInternal": "N",
							"createdOn": "",
							"createdBy": "",
							"module": module,
							"createdByName": ""

						})
							.then(function (response) {
								//alert(response.statusText);
								ref.props.loadReviewOrderList("", "Breakfix,Item", ref.props.status, ref.props.sortOnColumn, ref.props.sortOrder, ref.props.listFilter, ref.props.searchKey, ref.props.searchValue, ref.props.pattern, ref.props.listPageNumberValue, ref.props.listSize, ref.props.isSelf);
								//ref.props.loadPendingReviewOrderList("", "Breakfix,Item", "N", "displayNumber", "desc", listFilter, "", "", "");
								// ref.props.loadReviewOrderList("", "Breakfix,Item", "75,25", "N", "desc", listFilter);
								ref.props.showRightSide(false);
								//console.log("ref.props.myReviewOrdersList.length"+ref.props.myReviewOrdersList.length)
								ref.setState({
									//postStatusFlag:1,
									reOrderIndicator: 0,
									ratingCount: '',
									checkedYes: false,
									checkedNo: false,
									reasonData: ''
								});
								navigationHooks.navigate("/reviews");

							})
							.catch(function (error) {
								ref.setState({
									reOrderIndicator: 0
								});

							});
					}
					//postActivityStart here
				})
				.catch(function (error) {


				});
			//=================post end

		}

	}

	onChangeReOrder(event) {
		//alert(event.target.checked)
		//alert(checkedStatus)
		if (event.target.checked == true) {
			//alert("if"+event.target.checked)
			this.setState({
				checkedYes: true,
				checkedNo: false,
				postErrorMessage: '',
				commentsErrorMessage: '',
				issueValidationError: '',
				commetValidationError: ''
			});

			//this.props.flagForReopen=true;
			//alert(this.props.flagForReopen)
		} else {
			//alert("false")
			this.setState({
				checkedYes: false,

			});
		}

		//alert(this.state.checkedYes)
	}
	//=========
	onChangeReOrderNo(event) {
		//alert(event.target.checked)
		//alert(checkedStatus)
		if (event.target.checked == true) {
			//alert("if"+event.target.checked)
			this.setState({
				checkedNo: true,
				checkedYes: false,
				postErrorMessage: '',
				commentsErrorMessage: '',
				issueValidationError: '',
				commetValidationError: ''
			});

			//this.props.flagForReopen=true;
			//alert(this.props.flagForReopen)
		} else {
			//alert("false")
			this.setState({
				checkedNo: false,

			});
		}

		//alert(this.state.checkedYes)
	}
	ratingChanged(newRating) {
		//alert("M123");
		//console.log(newRating);
		rating = newRating;
		this.setState({
			ratingCount: newRating,
			checkedYes: false,
			checkedNo: false,
			postErrorMessage: '',
			commentsErrorMessage: '',
			issueValidationError: '',
			commetValidationError: ''
		});
	}
	remarkData(comments) {
		this.setState({ remarks: comments.target.value });
		//console.log("comments"+comments.target.value)
		remarkData = comments.target.value.trim().replace(/['"]/g, "");
	}

	postRemarkData() {
		//alert(this.state.quickViewOrders.orderNumber);
		//let orderId=this.props.quickViewItemId;
		//alert(orderId);
		//alert("workItemId"+this.props.quickViewItemId)
		//alert("workItemType"+this.props.reviewModuleValue);
		//let workItemType = this.props.reviewModuleValue
		let listFilter = this.props.listFilter;
		if (remarkData == "") {
			this.setState({
				commetValidationError: "error"
			});
		} else {
			this.setState({
				commetValidationError: ""
			});
		}
		let ref = this;
		if (this.state.ratingCount != "" && remarkData != "") {
			let postData;
			if (this.props.isCloseEnabled == true) {
				postData = {
					"feedbackSubmitted": "Y",
					"feedbackRemarks": remarkData,
					"itemStatus": "80",
					"modifiedByName": "",
					"modifiedBy": "",
					"feedbackRating": this.state.ratingCount
				};
			}
			else {
				postData = {
					"feedbackSubmitted": "Y",
					"feedbackRemarks": remarkData,
					"modifiedByName": "",
					"modifiedBy": "",
					"feedbackRating": this.state.ratingCount
				};
			}
			//this.props.fun(false,true,true);
			ref.setState({
				reOrderIndicator: 1
			});
			axios.post(GLOBAL.feedbackPostUrl, {
				"rating": rating,
				"remarks": remarkData,
				"requesterId": "",
				"workItemId": this.props.quickViewItemId,
				"workItemType": this.props.reviewModuleValue,
				"offeringId": this.props.offeringId,
				"requesterName": "",
				"orderNumber": this.props.orderNumber,
				"itemNumber": this.props.itemNumber,
				"offeringName": this.props.offeringName,
				"employeeNumber": "",
				"locationId": 0,
				"locationName": ""

			}).then((response) => {

				if (response.status === 201) {
				
					axios.patch(GLOBAL.fulfillmentpost + ref.props.quickViewItemId, postData)
						.then(function (response) {
							//self.props.loadReviewOrderList(user_id,"Breakfix,Item","Fullfiled,Fixed","Y","desc");
							ref.setState({
								reOrderIndicator: 0,
								checkedYes: false,
								checkedNo: false
							});
							ref.props.loadReviewOrderList("", "Breakfix,Item", ref.props.status, ref.props.sortOnColumn, ref.props.sortOrder, ref.props.listFilter, ref.props.searchKey, ref.props.searchValue, ref.props.pattern, ref.props.listPageNumberValue, ref.props.listSize, ref.props.isSelf);
							//ref.props.loadPendingReviewOrderList("", "Breakfix,Item", "N", "displayNumber", "desc", ref.props.listFilter, "", "", "");
							// ref.props.loadReviewOrderList("", "Breakfix,Item", "75,25", "N", "desc", listFilter);
							ref.props.showRightSide(false);
							navigationHooks.navigate("/reviews");
							remarkData='';
						})
						.catch(function (error) {
							ref.setState({
								reOrderIndicator: 0
							});
						});
				}
				this.props.loadReviewOrderEngineDetails(response.data.workItemId, response.data.requesterId, "Item");
				navigationHooks.navigate("/reviews");

			});
		} else {
			this.setState({
				commentsErrorMessage: this.props.tr['Please provide comments']
			});
		}
	}
	handleSelect(tabKey) {
		this.setState({ tabKey });
	}
	renderIndividualCategoryQuestions(categoryQuestions) {
		return [].concat(categoryQuestions).sort((a, b) => a.questionSequence > b.questionSequence ? 1 : -1).map((categoryQuestion, i) => {
			let answers = "";

			if (categoryQuestion.question == "Assets") {
				//	console.log("assets answer");
				//	console.log(categoryQuestion.answer);
				let newstringreplaced = "";
				if(typeof categoryQuestion.answer === 'undefined' || categoryQuestion.answer === null ||  categoryQuestion.answer === ""){
                    answers = 'NA';
				}else if (categoryQuestion.answer != null && categoryQuestion.answer != "") {
					newstringreplaced = categoryQuestion.answer.replace(/,Name/gi, "~Name");
					answers = newstringreplaced.split('~');
				}
				else {
					answers = categoryQuestion.answer;
				}

			}
			return (

				<tr key={i}>
					<td className="bgBtn20 f-size-13" style={{ "width": "50%" }}>{categoryQuestion.question}</td>
					<td className="f-size-13">

						{/*	{categoryQuestion.question !== "Assets" ?categoryQuestion.answer :<div className="assetsAnswers">{answers.map((a,i)=>{return <p>{a}</p>})};</div>}*/}
						{categoryQuestion.question !== "Assets" ? typeof categoryQuestion.answer === 'undefined' || categoryQuestion.answer === null ||  categoryQuestion.answer === "" ? 'NA' : (
							categoryQuestion.answer.replace(/ ~ /g, "\n").split("\n").map((data) => {
								console.log(data)
								return <p>{data}</p>
							})
						) : answers != null ? (
							<div className="assetsAnswers">
								{answers.map((a, i) => {
									return <p>{a}</p>;
								})}
								;
							</div>
						) : null}
					</td>
				</tr>
			);
		})
	}
	renderQuestionCategoryWise(myOrderQuestionDetails) {
		return [].concat(myOrderQuestionDetails).sort((a, b) => a.categorySequence > b.categorySequence ? 1 : -1).map((category, i) => {
			return (
					<Accordion.Item eventKey={i} className="collapsed">
						<Accordion.Header className="font1">{category.categoryName}</Accordion.Header>
						<Accordion.Body collapsible>
							<div className="table-responsive" key={i}>
								<Table bordered condensed hover responsive className="tableView sortImg">
									<tbody>
										{this.renderIndividualCategoryQuestions(category.questions)}
									</tbody>
								</Table>
							</div>
						</Accordion.Body>
					</Accordion.Item>
			);
		});
	}



	getAttachment(itemId, Module) {
		this.props.loadImplPlanList(Module, itemId);
	}
	renderAttachedItems(attachments) {
		if (_.isEmpty(attachments)) {
			return (
				<div>
					<h4>{this.props.tr['No File Found']}</h4>
				</div>
			);
		}
		return attachments.map(attachment => {
			let validated_url = undefined;
			if (attachment != null && attachment.fileTempURL != "") {
				let namedSchema = Joi.string().regex(/^[^<>}{]+$/);
				let validator_to_add_myQbj = namedSchema.validate(attachment.fileTempURL);
				if (validator_to_add_myQbj.error == null) {
					validated_url = attachment.fileTempURL;
				}
				else {
					validated_url = "\\";
				}
			}
			else
				validated_url = "\\";

			if (attachment.filename !== null && attachment != undefined) {
				let attachfilename = attachment.filename;
				if (attachfilename.indexOf("##") > -1)
					attachfilename = attachfilename.slice(15);

				if (attachment.status == "Y") {
					return null;
				} else {
					return (
						<ListGroup.Item
							className="attachmentList surveyAttchmnt"
							key={attachment.attachmentId}
						>							
							<div>
								{attachfilename}
							</div>
							<span>
								<a href={validated_url} target="_blank">
									{/* <a href={attachment.fileTempURL} target="_blank"> */}
									<PiEyeBold />
								</a>
							</span>
						</ListGroup.Item>
					);
				}
			}
		});
	}

	render() {
		let commentArea, ratingValue, postButton, commentAreaForReorder, reorderArea, submitButton;
		//console.log("myReviewOrdersList",this.props.myReviewOrderDetailsFrmEngine);
		//alert(this.props.myReviewOrderDetails.length)
		if (this.props.myReviewOrdersList == undefined || this.props.myReviewOrdersList.length == 0 || this.props.myReviewOrdersList.length == undefined) {
			return (
				<div>
					{this.props.showLoader ? <ListLoader /> :
						<div>
							<font color="green"><strong>{this.props.tr['No matching data found']}</strong></font>
						</div>
					}
				</div>
			);
		}
		//console.log(this.props.myReviewOrderDetailsFrmEngine.length);
		if (this.props.checkedStatus != "") {

			return (
				<div>
					<font color="green"><strong>{this.props.checkedStatus}</strong></font>
				</div>
			);
		}
		else {

			if (this.props.myReviewOrderDetailsFrmEngine.length > 0) {
				if (this.props.quickViewItemId == this.props.myReviewOrderDetailsFrmEngine[0].workItemId) {
					postButton = <div />;
					ratingValue = <ReactStars edit={false} count={5} size={24} value={this.props.myReviewOrderDetailsFrmEngine[0].rating} color1={'gray'} color2={this.props.appliedThemeData?.secondaryColor} />;
					commentArea = <Form.Control className={this.state.commetValidationError} as="textarea" placeholder={this.props.myReviewOrderDetailsFrmEngine[0].remarks} disabled />;
					reorderArea = <div />;
					commentAreaForReorder = <div />;
					submitButton = <div />;
				}
			} else {
				    ratingValue = <StarRatingComponent
					name="rate1"
					starCount={5}
					value={this.state.ratingCount}
					starColor={this.props.appliedThemeData?.secondaryColor} /* color of selected icons, default `#ffb400` */
					emptyStarColor={'gray'} /* color of non-selected icons, default `#333` */
					onStarClick={this.ratingChanged}
					onStarHover={(nextValue, prevValue, name)=>{this.setState({ratingHoverCount: nextValue});}}
					renderStarIcon={() => <span title={this.state.ratingHoverCount + " " + this.props.tr['Stars']} style={{ fontSize: 24 }}>★</span>}
				/>
				if (this.state.ratingCount > 3 || this.state.checkedNo == true) {
					commentArea = (<div><div className="f-size-15">{this.props.tr['Please provide your comments here']}<span className="rStar" />:
						<Form.Control className={this.state.commetValidationError} as="textarea" rows={4} onChange={this.remarkData.bind(this)} />
						<div className="f-size-13 color-lightgrey margin-b-10">{this.props.tr['Please do not include: HTML, references to suppliers, pricing, personal information, any profane, inflammatory or copyrighted comments.']}</div>
					</div></div>);
					postButton = (<ButtonToolbar className="black margin-t-10"><Button className="rgSidrkBtn" bsSize="small" variant="primary" disabled={!this.state.reOrderIndicator == 0} onClick={this.postRemarkData} >
						{!this.state.reOrderIndicator == 0 ? <ImSpinner6 className="icn-spinner"/> : null} {this.props.tr['Submit']}</Button>
					</ButtonToolbar>);
				}
				if (this.state.ratingCount == "") {
					reorderArea = <div />;
				}
				else if (this.state.ratingCount <= 3 && this.props.isReopenEnabled == true) {
					reorderArea = (<div className="f-size-15">{this.props.tr['Do you want to reopen the order']}:
						<div className="black margin-t-5"><label className="cursorPoint display-inline-block margin-r-10"><input style={{ "position": "relative", "top": "-2px" }} type="checkbox" checked={this.state.checkedYes} onClick={(event) => { this.onChangeReOrder(event); }} />&nbsp;
							{this.props.tr['Yes']}</label><label className="cursorPoint display-inline-block margin-l-10"><input style={{ "position": "relative", "top": "-2px" }} type="checkbox" checked={this.state.checkedNo} onClick={(event) => { this.onChangeReOrderNo(event); }} />&nbsp;{this.props.tr['No']}</label></div>
						<div className="f-size-15 margin-b-10 border-b padding-b-15" /></div>);
				}
				else if (this.state.ratingCount >= 1 && this.props.isReopenEnabled == false) {
					commentArea = (<div><div className="f-size-15 margin-b-10 border-b padding-b-15" /><div className="f-size-15"><br />{this.props.tr['Please provide your comments here']}<span className="rStar" />:
						<Form.Control className={this.state.commetValidationError} as="textarea" rows={4} onChange={this.remarkData.bind(this)} />
						<div className="f-size-13 color-lightgrey margin-b-10">{this.props.tr['Please do not include: HTML, references to suppliers, pricing, personal information, any profane, inflammatory or copyrighted comments.']}</div>
					</div></div>);
					postButton = (<ButtonToolbar className="black margin-t-10"><Button className="rgSidrkBtn" bsSize="small" variant="primary" disabled={!this.state.reOrderIndicator == 0} onClick={this.postRemarkData} >
						{!this.state.reOrderIndicator == 0 ? <ImSpinner6 className="icn-spinner"/> : null} {this.props.tr['Submit']}</Button>
					</ButtonToolbar>);
				}

			}
		}

		if (this.state.checkedYes == true) {
			if (this.state.ratingCount <= 3) {
				commentAreaForReorder = (<div className="f-size-15">{this.props.tr['Reason for reopening']}
					<span className="rStar" />:
					<Form.Control className={this.state.issueValidationError} as="textarea" onChange={this.getReOpenReason.bind(this)} />
				</div>);
				submitButton = (<ButtonToolbar className="black margin-t-10"><Button className="rgSidrkBtn" bsSize="small" variant="primary" disabled={!this.state.reOrderIndicator == 0} onClick={this.postReOrder.bind(this)} >
					{!this.state.reOrderIndicator == 0 ? <ImSpinner6 className="icn-spinner"/> : null} {this.props.tr['Submit']}</Button>
				</ButtonToolbar>);
			}
		} else {
			commentAreaForReorder = <div />;
			submitButton = <div />;
		}


		let displayName="";
		let displayNameStr="";
		displayNameStr = this.props.serviceName != null && this.props.serviceName != "" ? this.props.serviceName : "";
		displayName = displayNameStr != "" ? displayNameStr.replace(/(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/ig, '').trim() : "";
		displayName = displayName != "" ? displayName.replace(/\s+/g, ' ') : "";
  
		let getInnerWidth = (innerWidth)=>{
		  if (innerWidth > 1535) return 92;
		  if (innerWidth > 1367) return 80;
		  if (innerWidth > 1279) return 70;
		  if (innerWidth > 1023) return 50;
		  if (innerWidth > 767) return 38;
		  if (innerWidth > 640) return 62;
		  if (innerWidth > 539) return 48;
		  if (innerWidth > 411) return 70;
		  if (innerWidth > 279) return 40;
		  return 20;
		}

		return (
			<div>
				{this.props.showLoader ? <ListLoader />
					:
					<div>
						<Row>
							<Col xs={12}>
								<div className="rPageHeadActBtn">
									<ul>
										<li>
										<Link to="javascript:void(0)" className="closeRsBtn" id="closeButton"
											title={this.props.tr["Minimize the details panel"]}
											onClick={() => { this.props.showRightSide(false); }}
											ref={e => this.closeButton = e}
										>
											<IoClose/>
										</Link>
										</li>
									</ul>
								</div>
								<div className="rPageHeading">{this.props.orderNumber + '-' + this.props.itemNumber}<div className="offNam" title={this.props.serviceName}>
									{displayName.slice(0, getInnerWidth(this.props.windowSize.width))}{displayName.length > getInnerWidth(this.props.windowSize.width) ? "..." : ''}
									</div></div>
							</Col>
						</Row>


						{(this.props.isAutoClosed == true || this.props.feedbackSubmitted==='N') && this.props.itemStatus == "Closed" ? <div className="rBoxGap">{this.props.tr['No feedback was captured as this request was automatically closed.']}</div> :
							<div className="rBoxGap">
		<div className="rBoxTabs">
		<Tabs activeKey={this.state.tabKey} onSelect={this.handleSelect} id="tabId">
			<Tab eventKey={1} title={this.props.tr["Rating & Reviews"]}>
                <Row className="margin-b-10">
					<Col xs={12}>
					    <div className="f-size-15 margin-b-10">{this.props.tr['Tell Us About Your Service Experience']}</div>
						<div className="f-size-13">{this.props.tr['If you have liked the way we served you or have any suggestions for us,  please share it with us here.']}</div>
					</Col>
				</Row>
				<div className="f-size-15 margin-b-10 border-b " />
				<Row className="margin-b-10">
				<Col xs={12}>
				<div className="">
                     { this.props.myReviewOrderDetailsFrmEngine.length == undefined ? '' : this.props.myReviewOrderDetailsFrmEngine.length > 0 ?
					   <div className="f-size-15">{this.props.tr['Service Ratings']}:</div>
					  : <div className="f-size-15">{this.props.tr['Please rate the quality of service']}<span className="color-red">*</span>:</div>
				     }
				<div className="margin-t-5 starSecDv">{ratingValue}</div>
				</div>
											</Col>
										</Row>
										<div className="f-size-15 margin-b-10 border-b " />

										<Row className="margin-b-10">
											<Col xs={12}>
												{reorderArea}
												<Form.Group controlId="formControlsTextarea" validationState={this.state.commetValidationError}>
													{commentArea}
													<font color="red"><strong>{this.state.commentsErrorMessage}</strong></font>
												</Form.Group>
												{postButton}
												<Form.Group controlId="formControlsTextarea" validationState={this.state.issueValidationError}>
													{commentAreaForReorder}
													<font color="red"><strong>{this.state.postErrorMessage}</strong></font>
												</Form.Group>
												{submitButton}
											</Col>
										</Row>

									</Tab>
			<Tab eventKey={2} title={this.props.tr["Request Details"]}>
				<ListGroup variant="flush" bsPrefix=' '>
					<ListGroup.Item bsPrefix=' ' className="rwTable" style={{ "border-top": "none" }}>
						<span className="rwKey">{this.props.tr["Submit Date"]}</span>
						<span className="rwVal">{this.props.submittedDate}</span>
					</ListGroup.Item>
					<ListGroup.Item bsPrefix=' ' className="rwTable">
						<span className="rwKey">{this.props.tr["Status"]}</span>
						<span className="rwVal">{this.props.tr[this.props.itemStatus]}</span>
				    </ListGroup.Item>
					<ListGroup.Item className="margin-t-20" bsPrefix=' '>
						<Accordion id="rightSidePanel4" className="brkFix brkFixOrder" defaultActiveKey="1">
								{this.props.myOrderQuestionDetails.length != 0 ? this.props.myOrderQuestionDetails[0].categoryId != null ?
								<Accordion.Item eventKey='5' className="collapsed">
										<Accordion.Header>
											{this.props.tr['Information Provided']}
										</Accordion.Header>
								<Accordion.Body collapsible>
								<Accordion id="questions" className="brkFix brkFixOrder">{this.renderQuestionCategoryWise(this.props.myOrderQuestionDetails)}</Accordion>
									</Accordion.Body>
								</Accordion.Item> : null : null}
												
							<Accordion.Item eventKey="2" className="collapsed">
								<Accordion.Header onClick={() => { this.getAttachment(this.props.quickViewItemId, "Item"); }}>
								   {this.props.tr['Attachments']}
								</Accordion.Header>
								<Accordion.Body collapsible>
								<ListGroup>
									{this.renderAttachedItems(this.props.implememtationPlanList)}</ListGroup>
								</Accordion.Body>
							</Accordion.Item>
							</Accordion>
							</ListGroup.Item>
							</ListGroup>
			</Tab>
		</Tabs>
		</div>
							</div>
						}
					</div>
				}
			</div>
		);
	}
}

export function mapStateToProps({ myReviewOrdersList, showLoader, serviceReviewShowLoader, myReviewOrderDetails, myReviewBreakFixOrderDetails, myReviewOrderDetailsFrmEngine, myOrderQuestionDetails, implememtationPlanList,windowSize,appliedThemeData}) {
	return { myReviewOrdersList: myReviewOrdersList.data, showLoader: showLoader.loading, serviceReviewShowLoader: serviceReviewShowLoader.loading, myReviewOrderDetails, myReviewBreakFixOrderDetails, myReviewOrderDetailsFrmEngine, myOrderQuestionDetails, implememtationPlanList,windowSize, appliedThemeData: appliedThemeData.data};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ loadReviewOrderList, loadPendingReviewOrderList, loadReviewOrderDetails, loadReviewBreakFixOrderDetails, loadReviewOrderEngineDetails, loadImplPlanList }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MySurveyActions);
//export default XsmReviewsActions;
