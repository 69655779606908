
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState , useEffect, useContext, useRef} from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { reduxForm } from "redux-form";
import { patchTemplateFormNE, getAuditLogsNEData, getEditFormNEDetails } from "../../../../actions/spcmActions";
import Swal from "sweetalert2";
import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import { VscOpenPreview } from "react-icons/vsc";
import { LuActivitySquare } from "react-icons/lu";
import {LuFileText} from "react-icons/lu";
import axios from 'axios';
import { navigationHooks } from '../../../../helpers/NavigationHook';
import Cookies from "universal-cookie";
import NotificationTemplateEditForm from "./NotificationTemplateEditForm";
import NotificationTemplateEditShortDesc from "./NotificationTemplateEditShortDesc";
import  NotificationAuditLogs from "./NotificationAuditLogs"
import Breadcrumbs from "../../../common/header/breadcrumbs";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { MyContext } from '_MyContext';
import "_Css/form/_form.scss"
const cookies = new Cookies();
let parsedCookie =  cookies.get("gph");
parsedCookie = parsedCookie.split("~");
let modifiedby = parsedCookie[43];
let modifiedbyName = parsedCookie[42];

import { AiOutlineAudit } from "react-icons/ai";
import { Media } from 'react-bootstrap'
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );

const NotificationTemplateEditIndex = (props) => {
  const translator = useSelector((state) => state.spcmReducer.tr);
  const neReducer = useSelector((state) => state.neReducer);
  const customVariablesReducer = useSelector((state) => state.customVariablesReducer);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showLoader, setShowLoader] = useState(false);
  const [changedStatus, setChangedStatus] = useState("");
  const [changedCompanyName, setChangedCompanyName] = useState("");
  const [changedName, setChangedName] = useState("");
  const [subjectContent, setSubjectContent] = useState("");
  const [changedBody, setChangedBody] = useState("");
  const [changedCompanyID, setChangedCompanyID] = useState("");
  const [STATUS, setSTATUS] = useState("");
  const [MODULE_NAME, setMODULE_NAME] = useState("");
  const [NOTIFICATION_NAME, setNOTIFICATION_NAME] = useState("");
  const [SUBJECT, setSUBJECT] = useState("");
  const [MESSAGE, setMESSAGE] = useState("");
  const [TO_EMAIL, setTO_EMAIL] = useState("");
  const [CC_EMAIL, setCC_EMAIL] = useState("");
  const [COMPANY_NAME, setCOMPANY_NAME] = useState("");
  const [EVENT_TYPE, setEVENT_TYPE] = useState("");
  const [TEMPLATE_BODY, setTEMPLATE_BODY] = useState("");
  const [COMPANY_ID, setCOMPANY_ID] = useState("");
  const [MODID, setMODID] = useState("");
  const [BODY, setBODY] = useState("");
  const { id } = useParams();
  const { localeId } = useParams();
  const [isRightEditPanel, setrightEditPanel] = useState(true);
  const [isAuditPanel,setAuditPanel]=useState(false);
  const [isPreviewPanel,setPreviewPanel]=useState(true);
  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const saveObjRef = useRef(null);

  useEffect(() => {
    context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
    context.newTabPrevent();
	}, []);

  const loadHome = () => {
    navigate(homepagelocation);
  };

  const rightEditPanel = (value) => {
    setrightEditPanel(value);
  };
  const ValidateSubject = (string) => {
console.log("PICCHI",string);
    const array = string.split("<%");
    let variables = [];
    array.forEach((res) => {
      let startIndex = res.indexOf("=");
      let newString = res.replace("=", "");
      let endIndex = newString.indexOf("%>");
      let variable = newString.substr(startIndex, endIndex);
      if (variable.length > 0) {
        variables.push("<%=" + variable + "%>");
      }
    });

    if (variables.length == 0) {
      return "plainText";
    } else {
      let availabilityCount = 0;
      variables.forEach((variable) => {
        customVariablesReducer.forEach((extVar) => {
          if (extVar.key.toString() == variable.toString()) {
            availabilityCount++;
          }
        });
      });
      if (availabilityCount == variables.length) {
        //when Subject variables matches with the variables in the list
        return "passed";
      }
      else {
        //when Subject variables do not get matched with the variables in the list
        return "failed";
      }
    }
  }

  const renderEditForm = (
    STATUS,
    MODULE_NAME,
    NOTIFICATION_NAME,
    SUBJECT,
    MESSAGE,
    TO_EMAIL,
    CC_EMAIL,
    COMPANY_NAME,
    EVENT_TYPE,
    MODID,
    TEMPLATE_BODY,
    COMPANY_ID
  ) => {
    setSTATUS(STATUS);
    setMESSAGE(MESSAGE);
    setMODULE_NAME(MODULE_NAME);
    setNOTIFICATION_NAME(NOTIFICATION_NAME);
    setSUBJECT(SUBJECT);
    setMODID(MODID);
    setTO_EMAIL(TO_EMAIL);
    setCC_EMAIL(CC_EMAIL);
    setCOMPANY_NAME(COMPANY_NAME);
    setEVENT_TYPE(EVENT_TYPE);
    setTEMPLATE_BODY(TEMPLATE_BODY);
    setCOMPANY_ID(COMPANY_ID);
  };

  const onDeleteClick = () => {
    Swal.fire({
      title: translator["Are you sure you want to delete?"],
      width: 550,
      padding: '15px',
      showDenyButton: true,
      confirmButtonText: translator['Yes'],
      denyButtonText: translator['No'],
    }).then((result) => {
      if (result.isConfirmed) {
        let str = {};
        str.notificationId = id;
        str.localeId = localeId;
        str.tenantId = COMPANY_NAME;
        str = JSON.stringify(str);
        axios.delete("/api/notif/v1/customnotif", { headers: { query: str }}).then((resp) => {
          navigationHooks.navigate('/notificationboard')
      
        }).catch((error) => {
        })
      } else if (result.isDenied) {
        
      }
    })
  };

  const handleAuditBtn=(val)=>{
    setAuditPanel(val);
  };
  const handlePreviewBtn=(val)=>{
    setPreviewPanel(val)
  }


  const submit = (values) => {

    let templateId=neReducer[0]?.TEMPLATE_ID
    let audit_type=neReducer[0]?.COMPANY_ID == '0' ? 'ne_templates' : 'ne_custom_templates'
    
  
    if(COMPANY_NAME == "Default" && neReducer[0]?.LOCALE_ID == "en"){
      let data;
      if (changedStatus == "" || changedStatus == null) {
        data = {
          ne_status: STATUS,
          locale_id:localeId,
          "modifiedby":modifiedby,
          "modifiedbyName":modifiedbyName
        };
      } else {
        data = {
          ne_status: changedStatus,
          locale_id:localeId,
          "modifiedby":modifiedby,
          "modifiedbyName":modifiedbyName
        };
      }
     
      dispatch(
        patchTemplateFormNE(
          id,
          data,
          templateId,
          audit_type,
          Swal.fire(
            translator["Notification Template submitted successfully"],
            "",
            ""
          ),
        )
      );
     
      
    }
    else{
      // setBODY(changedBody);
    //   console.log("IKKADA",values.SUBJECT)
    //   console.log("AKKADA",SUBJECT)
    //   console.log("changedBody",changedBody)
    // console.log("values.TEMPLATE_BODY",values.TEMPLATE_BODY);
    // console.log("TEMPLATE_BODY",TEMPLATE_BODY);
    console.log("VALUES ELSE",values);
      let validateSubject ;

      let final; 
      if (changedBody.includes(`<table border="0" cellpadding="0" cellspacing="0" width="100%" bgcolor="#ffffff" style="padding:0;color:#000;font-size:12px;font-family:Verdana,Geneva,sans-serif;line-height:20px;"><tbody><tr><td style="padding:20px 15px 10px 15px"><div id="bodyPart"></div></td></tr></tbody></table>`) || changedBody.includes(`<tableborder="0"cellpadding="0"cellspacing="0"width="100%"bgcolor="#ffffff"style="padding:0;color:#000;font-size:12px;font-family:Verdana,Geneva,sans-serif;line-height:20px;"><tbody><tr><tdstyle="padding:20px15px10px15px"><divid="bodyPart"></div></td></tr></tbody></table>`)) {
        Swal.fire(
          "Please Add the Body Text"
        )
        return false;
      } else if (changedBody.includes("div1")) {
        let part1 = changedBody.split("<select");
        let part2 = changedBody.split("</select>");
  
        final = part1[0] + part2[1];
        if(final.includes(`<span tabindex="0" role="link" class="varModify" style="font-weight: 600;">&lt;%=Select%&gt;</span>`)){
          final = final.replace(`<span tabindex="0" role="link" class="varModify" style="font-weight: 600;">&lt;%=Select%&gt;</span>`,'');
        }
  
      } else {
        final = changedBody;
        if(final.includes(`<span tabindex="0" role="link" class="varModify" style="font-weight: 600;">&lt;%=Select%&gt;</span>`)){
          final = final.replace(`<span tabindex="0" role="link" class="varModify" style="font-weight: 600;">&lt;%=Select%&gt;</span>`,'');
        }
      }
      if (subjectContent){
        console.log("PREMO")
         validateSubject = ValidateSubject(subjectContent);
        if (validateSubject == "failed") {
          Swal.fire(
            "Invalid Variables"
          )
          return;
        } 
      }
      else {
        console.log("YEMO")

         validateSubject = ValidateSubject(SUBJECT);
        if (validateSubject == "failed") {
          Swal.fire(
            "Invalid Variables"
          )
          return;
        } 
      }

      let postJson;
      postJson = {
        
          "notificationName":changedName == "" || changedName == null ? NOTIFICATION_NAME : changedName ,
          "tenantId":COMPANY_NAME,
          "localeId": localeId,
        "subject": subjectContent == "" || subjectContent == null ? SUBJECT : subjectContent,
          "notificationId":id,
          "status":changedStatus == "" || changedStatus == null ? STATUS :changedStatus ,   
          // "templateBody" : final == "" || final == null ? values.TEMPLATE_BODY : final,
          "templateBody" : final,
          "companyId": COMPANY_ID,
          "modifiedby":modifiedby,
          "modifiedbyName":modifiedbyName 
          
      
      }
      console.log("validateSubject", validateSubject);
      console.log("subjectContent", subjectContent);

      if (validateSubject == "plainText") {
        Swal.fire({
          title: 'Do you want to proceed without variables in the Subject?',
          width: 550,

          padding: '15px',
          showDenyButton: true,
          confirmButtonText: 'Yes',
          denyButtonText: 'No',
        }).then((result) => {
          if (result.isConfirmed) {
            axios.patch("/api/notif/v1/customnotif", postJson).then((resp) => {
              Swal.fire(
                translator["Notification Template submitted successfully"],
                "",
                ""
              )
              // setBODY(changedBody);
              setBODY(final);
              dispatch(getAuditLogsNEData(templateId, audit_type))
              dispatch(getEditFormNEDetails(id, localeId, COMPANY_NAME));


            }).catch((error) => {
              Swal.fire(
                error.response.data.Message,
              )
            })
          }
          else if (result.isDenied) {
            return false;

          }
        })
      }else{
        axios.patch("/api/notif/v1/customnotif", postJson).then((resp) => {
          Swal.fire(
            translator["Notification Template submitted successfully"],
            "",
            ""
          )
          // setBODY(changedBody);
          setBODY(final);
          dispatch(getAuditLogsNEData(templateId, audit_type))
        
          dispatch(getEditFormNEDetails(id, localeId, COMPANY_NAME));

        }).catch((error) => {
          Swal.fire(
            error.response.data.Message,
          )
        })
      }
      
    }
  
  };

  let { handleSubmit } = props;
  return (
    <div>
      <div className="minHeightWIB">
        <div className="container-fluid margin-t-10 margin-b-15">
          <Breadcrumbs activePageName={"Edit"} parentPageurl='/notificationboard' parentPageName={translator["Notification Board"]} />
        </div>

        <div className="container-fluid" >
          <Row className="margin-b-15">
            {/* {COMPANY_NAME && COMPANY_NAME == "Default" ?  */}
              {location?.state?.tenantId == "Default" ? 
            <Col lg={4} md={6} sm={6} xs={5}>
              <h1  className="sPageHeading1">
                {translator["Template"]}
              </h1>
            </Col>
            :
              <Col lg={4} md={6} sm={6} xs={5}>
                <h1 className="sPageHeading1">
                  {translator["Custom Template"]}
                </h1>
              </Col>
            }
            <Col lg={8} md={6} sm={6} xs={7}>       
              <div className="paHedFilter">            
              <Button
                 role="button"
                 bsPrefix=" "
                  className={isPreviewPanel ? "myBt cancel active" : "myBt cancel"}
                  title={"Preview"}
                  onClick={() => {handlePreviewBtn(true);handleAuditBtn(false);rightEditPanel(true)}}
                >
                <VscOpenPreview/> 
                  
                </Button>                    
                <Button
                 role="button"
                 bsPrefix=" "
                  className={isAuditPanel ? "myBt cancel active" : "myBt cancel"}
                  title={"Activity Details"}
                  onClick={() => {handleAuditBtn(true);handlePreviewBtn(false);rightEditPanel(true)}}
                >
                <LuActivitySquare />
                  
                </Button>
                {COMPANY_NAME && COMPANY_NAME == "Default" && neReducer[0]?.LOCALE_ID == "en" ? "" :
                  <Button role="button" bsPrefix=' ' className="myBt rclose" title={translator["Delete"]} onClick={onDeleteClick} >
                    <i aria-hidden="true" className="fa fa-trash-o"></i>
                  </Button>
                }  
                <Link
                  to="javascript:void(0)"
                  bsPrefix=" "
                  className="myBt plus ctrlKeyPrevent"
                  onClick={handleSubmit(submit)}
                  disabled={showLoader}
                  title={translator["Save"]}
                  ref={saveObjRef}
                >
                  {showLoader ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/>}
                </Link>
               
                <Link                  
                  className="myBt cancel ctrlKeyPrevent"
                  title={translator["Close"]}
                  to="/notificationboard"
                  ref={closeButton}
                >
                  <IoClose/>
                </Link>
              </div>
            </Col>
          </Row>

          <PanelGroup direction="horizontal" className="formGroupB10 myProfileLabel myOrders panelOverflow">
            <Panel id="sidebar" minSize={33} order={1} defaultSize={isRightEditPanel ? 67 : 100} className={isRightEditPanel ? "isShowLeftPanel rwLeftPart" : "rwLeftPart"}>
              <NotificationTemplateEditForm
                neReducer={neReducer}
                translator={translator}
                ID={id}
                LOCALEID = {localeId}
                statusValueNE={setChangedStatus}
                renderEditForm={renderEditForm}
                STATUS={STATUS}
                MODULE_NAME={MODULE_NAME}
                NOTIFICATION_NAME={NOTIFICATION_NAME}
                SUBJECT={SUBJECT}
                MESSAGE={MESSAGE}
                TO_EMAIL={TO_EMAIL}
                CC_EMAIL={CC_EMAIL}
                COMPANY_NAME={COMPANY_NAME}
                EVENT_TYPE={EVENT_TYPE}
                MODID={MODID}
                rightEditPanel={rightEditPanel}
                setChangedBody={setChangedBody}
                setChangedName={setChangedName}
                setSubjectContent={setSubjectContent}
                setChangedCompanyName={setChangedCompanyName}
                setChangedCompanyID={setChangedCompanyID}   
              />
            </Panel>
            {isRightEditPanel ?
                <>
                  <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
                  <Panel minSize={33} order={2} defaultSize={33}>
                    <div className="stickyArea rBoxStyle">
                      {isPreviewPanel ? 
                        <NotificationTemplateEditShortDesc
                        tr={translator}
                        STATUS={STATUS}
                        MODULE_NAME={MODULE_NAME}
                        NOTIFICATION_NAME={NOTIFICATION_NAME}
                        SUBJECT={SUBJECT}
                        MESSAGE={MESSAGE}
                        TO_EMAIL={TO_EMAIL}
                        CC_EMAIL={CC_EMAIL}
                        COMPANY_NAME={COMPANY_NAME}
                        EVENT_TYPE={EVENT_TYPE}
                        MODID={MODID}
                        LOCALEID = {localeId}
                        rightEditPanel={rightEditPanel}
                        BODY = {BODY}
                        TEMPLATE_BODY = {TEMPLATE_BODY}
                        subjectContent={subjectContent}
                      /> : ""}
                      {isAuditPanel ?
                        <NotificationAuditLogs notificationData = {neReducer} rightEditPanel={rightEditPanel}/>
                         : "" }
                    </div>
                  </Panel>
                </> : "" }
              
      
          </PanelGroup>
        </div>
      </div>
    </div>
  );
};

export default reduxForm({
  form: "editFormNE",
  enableReinitialize: true,
})(NotificationTemplateEditIndex);
