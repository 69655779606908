
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import NavItem from 'react-bootstrap/NavItem';
import {ImInfo} from 'react-icons/im';
import OnClickOutside from 'react-onclickoutside';
import { GLOBAL } from '_Globals';
import { Field, reduxForm, formValueSelector, change, SubmissionError, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import axios from 'axios';
import { bindActionCreators } from 'redux';
// import Select from 'react-select';
import _ from 'lodash';
//import { DateField, DatePicker, TransitionView, Calendar } from 'react-date-picker';
import moment from 'moment';
import RFReactConsumer from '_Commons/WorkItemBoard/AutoSearchConsumer';
//import RFReactService from '../../../../common/WorkItemBoard/AutoSearchService.js';
import { loadBreakFixSourceName } from '_Actions/breakFix/sourceAction';
import { loadBreakFixCriticality } from '_Actions/breakFix/criticalityAction';
import { getPriorityUrgencyCriticalityValues } from '_Actions/common/commonApisActions';
import { loadBreakFixSG } from '_Actions/breakFix/serviceGroupAction';
import { loadBreakFixSGI, loadBreakFixSGISuccess } from '_Actions/breakFix/serviceGroupIAction';
import { loadNewBreakFixSGAE } from '_Actions/breakFix/serviceGroupAEAction';
import CIDetailsService from '_Commons/WorkItemBoard/ciNameSelect';
import {getBreakfixGroupData,getCIGroupData,getAllgroup} from '_Actions/amsActions/amsActions';
import { RuleAmsDdropDown } from "../../../common/forms/ruleDropDown";
import {
	TypeaheadExampleSingleSelect
  } from "_Commons/formFields";
//   import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import swal from 'sweetalert';
import { RiUserSearchLine } from 'react-icons/ri';
const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

// let impactedServiceData = [];
// let impactedServiceDataDetails = [];
// let nameIndex = "";
// let consumerData = [];
// let consumerDataDetails = [];
// let myObj = [];
// let myObj0 = [];
let ciNameData = [];
let ciNameDataDetails=[];
let ciNameObject=[];

let CreateInvestigationForm = class CreateInvestigationForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			startDate: moment(),
			priorityValue: '',
			priorityColorValue: '',
			priorityId:'',
			reportedThrough: '',
			supportPGroup: '',
			supportPIndividual: '',
			criticality: '',
			urgencyMode: '',
			source:'',
			consumer: '',
			issueDescription: '',
			reportedThroughErrorColor: '',
			supportPIndividualErrorColor: '',
			criticalityErrorColor: '',
			urgencyModeErrorColor: '',
			sgErrorColor: '',
			sgIErrorColor: '',
			sourceErrorColor:'',
			urgencyErrorColor: '',
			impactedServiceDataDetails:[],
			consumerDataDetails:[],
			//ciNameDataDetails:[],
			companyId:'',
			RadioValue:'',
			impactedCIeName:'',
			AllAMSGrps:false,
			ShowAllAMSGRP:true,
            companyList:[],
			companyError:'',
			selectedCompanyList: [],
			selectedGroups: [],
			selectedIndividualList: [],
			typedCompany: '',
			typedGroup: '',
			typedIndividual: '',
			cancelreasonCharLeft:2000,
			selectedConsumer: '',
			selectedImpactedCi: '',
			disableTypeAhead: true,
			rulesAttribute: {},
		};
		// impactedServiceDataDetails=[];
		// impactedServiceData=[];
		// consumerDataDetails=[];
		// consumerData=[];
		ciNameData = [];
		ciNameDataDetails=[];
		ciNameObject=[];

		//this.onServiceNameChange = this.onServiceNameChange.bind(this);
		this.onGroupNameChange = this.onGroupNameChange.bind(this);
		//this.handleChange = this.handleChange.bind(this);
		this.onChangeUrgency = this.onChangeUrgency.bind(this);
		this.setErrorColor = this.setErrorColor.bind(this);
		this.validateField = this.validateField.bind(this);
		this.setDropDownValue = this.setDropDownValue.bind(this);
		this.onChangeCriticality = this.onChangeCriticality.bind(this);
		this.getLablel = this.getLabel.bind(this);
		//this.onRadioSelect= this.onRadioSelect.bind(this);
		this.onImpactedSelection = this.onImpactedSelection.bind(this);
		this.setErrorImpactedColor = this.setErrorImpactedColor.bind(this)
		this.AllAMSGrps=this.AllAMSGrps.bind(this);
    this.ShowAMSGroup=this.ShowAMSGroup.bind(this);
	this.AllGrps=this.AllGrps.bind(this);
	this.setCompany=this.setCompany.bind(this); 
		this.renderCompany=this.renderCompany.bind(this);
		this.validateFieldAll=this.validateFieldAll.bind(this);
		this.onCompanySelection = this.onCompanySelection.bind(this);
		this.getGroupOptions = this.getGroupOptions.bind(this);
		this.setSelectGroup = this.setSelectGroup.bind(this);
		this.seterrorGroupcolor = this.seterrorGroupcolor.bind(this);
		this.setErrorCompanyColor = this.setErrorCompanyColor.bind(this);
		this.onIndividualSelection = this.onIndividualSelection.bind(this);
        this.descriptionCharacter=this.descriptionCharacter.bind(this);
		this.companyInputChange = this.companyInputChange.bind(this);
		this.onCrossClickCompany = this.onCrossClickCompany.bind(this);
		this.onGroupInputChange = this.onGroupInputChange.bind(this);
		this.onCrossClickGroup = this.onCrossClickGroup.bind(this);
		this.onIndividualInputChange = this.onIndividualInputChange.bind(this);
		this.onCrossClickIndividual = this.onCrossClickIndividual.bind(this);
		this.checkforAMSattributes=this.checkforAMSattributes.bind(this);
		this.afterConsumerSelected=this.afterConsumerSelected.bind(this);
		this.clearGroupandIndividual=this.clearGroupandIndividual.bind(this);
		this.onConsumerSelected=this.onConsumerSelected.bind(this);
		this.resetCIRequesterFields=this.resetCIRequesterFields.bind(this);

		GLOBAL.ciName="";GLOBAL.ciId="";GLOBAL.ciIdNumber="";
		GLOBAL.cancelreasonCharLeft=2000,
        GLOBAL.cancelreasonCharMax=2000
		this.showConsumerInfoIcon = this.showConsumerInfoIcon.bind(this);
		this.showImpactedCIInfoIcon = this.showImpactedCIInfoIcon.bind(this);
		this.showIndividualInfoIcon =  this.showIndividualInfoIcon.bind(this);
	}
	onConsumerSelected(suggestion){ 
		// const suggestionlabel= suggestion.label.split("|")[0];
		const suggestionlabel= suggestion.fullname;
		this.setState({selectedConsumer: suggestionlabel})
		GLOBAL.consumerErrorColor='';
	  }
	setErrorColor(errorColor, fieldType) {
		switch (fieldType) {
			// case 'impactedServiceName':
			// 	if(this.state.RadioValue=='service')
			// 	   this.setState({ impactedServiceName: errorColor });
			// 	GLOBAL.serviceErrorColor = "";

			// 	break;
			case 'consumer':
				this.setState({ consumer: errorColor });
				GLOBAL.consumerErrorColor = "";
				break;
			default:

		}

	}
	setDropDownValue(event, fieldType) {
		switch (fieldType) {
			case 'reportedThrough':
				this.setState({ reportedThrough: event.target.value });
				break;
			case 'supportPGroup':
				this.setState({ supportPGroup: event.target.value });
				break;
			case 'supportPIndividual':
				this.setState({ supportPIndividual: event.target.value });
				break;
			case 'criticality':
				this.setState({ criticality: event.target.value });
				break;
			case 'urgencyMode':
				this.setState({ urgencyMode: event.target.value });
				break;

			default:

		}
	}

	onChangeUrgency(e) {
		let criticalityValue = this.props.criticality===null?0:this.props.criticality;
		let urgencyValue =e.target.value===''?0:e.target.value;
		let priorityData = '';
		let priorityActualValue = '';
		let priorityActualColor = '';
		let priorityUrl = `/serviceManagement/rest/priority?module=Investigation&criticality=${criticalityValue}&urgency=${urgencyValue}`;
		if (criticalityValue != "") {
			axios.get(priorityUrl).then((response) => {
				priorityData = response.data;
				priorityActualValue = priorityData.priorityValue;
				priorityActualColor = priorityData.priorityColor;
				GLOBAL.priorityColorType = priorityActualColor;
				this.setState({
					priorityValue: priorityActualValue,
					priorityColorValue: priorityActualColor,
					priorityId: priorityData.priority
				});
				if(typeof priorityActualValue!=='undefined' && priorityData.priority!==''){
					this.checkforAMSattributes('urgency',priorityData.priority);
				}
				else{
					this.checkforAMSattributes('urgency',-1);
				}
			});
		}
	}

	onChangeCriticality(e) {
		let criticalityValue =  e.target.value===''?0:e.target.value;
		let urgencyValue = this.props.urgencyMode===null?0:this.props.urgencyMode;
		let priorityData = '';
		let priorityActualValue = '';
		let priorityActualColor = '';
		let priorityUrl = `/serviceManagement/rest/priority?module=Investigation&criticality=${criticalityValue}&urgency=${urgencyValue}`;
		if (urgencyValue != "") {
			axios.get(priorityUrl).then((response) => {
				priorityData = response.data;
				priorityActualValue = priorityData.priorityValue;
				priorityActualColor = priorityData.priorityColor;
				GLOBAL.priorityColorType = priorityActualColor;
				this.setState({
					priorityValue: priorityActualValue,
					priorityColorValue: priorityActualColor,
					priorityId: priorityData.priority
				});
				if(typeof priorityActualValue!=='undefined' && priorityData.priority!==''){
					this.checkforAMSattributes('impact',priorityData.priority);
				}else{
					this.checkforAMSattributes('impact',-1);
				}
			});
		}
	}

	validateFieldAll(fieldType, event) {
		switch (fieldType) {
			case 'issueDescription':
				if (event.currentTarget.defaultValue == "") {
					this.setState({ issueDescription: 'error' });
					GLOBAL.issueErrorColor = "";
				} else {
					this.setState({ issueDescription: '' });
					GLOBAL.issueErrorColor = "";
				}
				break;
			case 'reportedThrough':
				if (event.target.value == "") {
					this.setState({ reportedThroughErrorColor: 'error' });
					GLOBAL.throughErrorColor = "";
				} else {
					this.setState({ reportedThroughErrorColor: '' });
					GLOBAL.throughErrorColor = "";
				}
				break;
			// case 'supportPIndividual':
			// 	if (event.target.value == 0) {
			// 		this.setState({ supportPIndividualErrorColor: 'error' });
			// 		GLOBAL.sgIErrorColor = "";
			// 	} else {
			// 		this.setState({ supportPIndividualErrorColor: '' });
			// 		GLOBAL.sgIErrorColor = "";
			// 	}
			// 	break;
			case 'criticality':
				console.log("joo",event.target.value);
				if (event.target.value == 0) {
					this.setState({ criticalityErrorColor: 'error' });
					GLOBAL.criticalityErrorColor = "";
				} else {
					this.setState({ criticalityErrorColor: '' });
					GLOBAL.criticalityErrorColor = "";
				}
				break;
			case 'urgencyMode':
				if (event.target.value == "") {
					this.setState({ urgencyModeErrorColor: 'error' });
					GLOBAL.urgencyErrorColor = "";
				} else {
					this.setState({ urgencyModeErrorColor: '' });
					GLOBAL.urgencyErrorColor = "";
				}
				break;
			case 'source':
				console.log("hiii",event.target.value);
				if(event.target.value==0){
					this.setState({sourceErrorColor:'error'});
					GLOBAL.sourceErrorColor="";
				}
				else{
					this.setState({sourceErrorColor:''});
					GLOBAL.sourceErrorColor="";
				}
				break;
			default:

		}
	}

	searchResultsValue() {
		this.setState({ searchResults: 0 });
	}


	componentDidMount() {
		GLOBAL.breakFixServiceID=GLOBAL.ciId=GLOBAL.breakFixConsumerID=GLOBAL.servicelocationid=GLOBAL.ciLocationId=GLOBAL.ciClassId=GLOBAL.businessCriticalityId=GLOBAL.businessCriticality='';

		this.setState({
			AllAMSGrps:false
		  });
		GLOBAL.serviceErrorColor=''
		GLOBAL.CIError=''
		GLOBAL.consumerErrorColor=''
		GLOBAL.sourceErrorColor=''
		GLOBAL.issueErrorColor=''
		GLOBAL.criticalityErrorColor=''
		GLOBAL.urgencyErrorColor=''
		this.props.dispatch({
			type:'LOAD_BREAKFIXSGAE_SUCCESS',
			breakFixSGAEName: ""
		})
		this.props.dispatch({
				type: 'LOAD_BREAKFIXSGI_SUCCESS',
				breakFixSGIName: {data: ''}
			})
		let consumerData = [];
		//let consumerDataDetails = [];
		let impactedServiceData=[];
		let impactedServiceDataDetails=[];
		let myObj = [];
		let myObj0 = [];
		// let strObj={};
		// strObj.userId="";
		// strObj = JSON.stringify(strObj);
        api.get('/api/serviceportfolio/usercompanylist').then((responseCompany)=>{
			// console.log('responseCompany.data - ', responseCompany.data);
			let arr = [];
			responseCompany.data.forEach(item => {
				let obj = item;
				obj['label'] = item['field1Key'];
				obj['id'] = item['field1Value'];
				arr.push(obj);
			});
			// console.log('arr 8 ', arr);
			// this.state.companyList=responseCompany.data;
			this.state.companyList=arr;
		// api.get(GLOBAL.breakFixConsumerUrl,{headers:{'query':strObj}}).then((response) => {
		// 	consumerData = response.data;

		// 	// for (var i = 0; i < consumerData.length; i++) {
		// 	// 	myObj = { value: '', label: '' };
		// 	// 	myObj.value = consumerData[i].userId;
		// 	// 	myObj.label = consumerData[i].fullname + "|" + consumerData[i].userId + "|" + consumerData[i].email + "|" + consumerData[i].companyName;
		// 	// 	GLOBAL.consumerCompanybreakfix = consumerData[i].companyId;
		// 	// 	consumerDataDetails.push(myObj);
		// 	// }
		// 	// consumerDataDetails = Array.from(new Set(consumerDataDetails));
		// 	responseCompany.data.map((item)=>{
		// 		consumerData.map((subItem)=>{
		// 			if(item.field1Value==subItem.companyId){
		// 				if(typeof this.state.consumerDataDetails[item.field1Value]==='undefined')
        //                 this.state.consumerDataDetails[item.field1Value]=[{
        //                       'value':subItem.userId,
		// 					  'label':subItem.fullname + "|" + subItem.email + "|" + subItem.userId + "|" + subItem.companyName
		// 				}];
		// 				else
		// 				this.state.consumerDataDetails[item.field1Value].push({
		// 					'value':subItem.userId,
		// 					'label':subItem.fullname + "|" + subItem.email + "|" + subItem.userId + "|" + subItem.companyName
		// 				});
		// 			}
		// 		});
		// 	});
		// 	this.setState({
		// 		consumerDataDetails: this.state.consumerDataDetails
		// 	});
		// });
	});
		
		var str={};
		str.userId="";
		str.companyId="";
		str.associatedCompanyId="";
		str = JSON.stringify(str);

		api.get(GLOBAL.breakFixServiceWithoutDomainUrl,{headers:{'query':str}}).then((response) => {
			impactedServiceData = response.data;
			for (var i = 0; i < impactedServiceData.length; i++) {
				myObj0 = { value: '', label: '', companyname: '' };
				myObj0.value = impactedServiceData[i].offeringId;
				myObj0.label = impactedServiceData[i].offeringName + "|" + impactedServiceData[i].categoryName + "|" + impactedServiceData[i].subCategoryName+ "|" + impactedServiceData[i].companyName;
				myObj0.companyname = impactedServiceData[i].company
				myObj0.consumercompanyname=impactedServiceData[i].companyName;
				//GLOBAL.consumerCompanybreakfix=impactedServiceData[i].companyName;
				//GLOBAL.consumerCompanybreakfix = consumerData[i].companyId;
				impactedServiceDataDetails.push(myObj0);
			}
			impactedServiceDataDetails = Array.from(new Set(impactedServiceDataDetails));
			this.setState({
				impactedServiceDataDetails:impactedServiceDataDetails
			});
		});

		// axios.get(GLOBAL.ciNameUrl+userDetails.company_id).then((response)=>{
		// 	//alert("hi")
		// 	ciNameData = response.data;
		// 	for (var i = 0; i < ciNameData.length; i++) {
		// 	  ciNameObject = {ciId:'',label:'',ciName:'',ciClass:'',ciCompany:'',ciIdNumber:''};
		// 	  ciNameObject.ciId=ciNameData[i].CI_ID;
		// 	  ciNameObject.label=ciNameData[i].CI_NAME+"|"+ciNameData[i].CLASS_NAME+"|"+ciNameData[i].COMPANY_NAME;
		// 	  ciNameObject.ciName=ciNameData[i].CI_NAME
		// 	  ciNameObject.ciClass=ciNameData[i].CLASS_NAME
		// 	  ciNameObject.ciCompany=ciNameData[i].COMPANY_NAME
		// 			  ciNameObject.ciIdNumber=ciNameData[i].CI_CODE;
		// 	  //GLOBAL.consumerCompanybreakfix=impactedServiceData[i].companyName;
		// 	  ciNameDataDetails.push(ciNameObject);
		// 	}
		// 	//ciNameDataDetails = Array.from(new Set(ciNameDataDetails));
		// 		  console.log("ciNameDataDetails")
		// 		  console.log(ciNameDataDetails)
		//   });

		this.props.loadBreakFixSourceName();
		this.props.getPriorityUrgencyCriticalityValues("Investigation","Criticality");
		this.props.getPriorityUrgencyCriticalityValues("Investigation","Urgency");
		this.props.getPriorityUrgencyCriticalityValues("Investigation","source");
	}
    AllGrps(e)
	{
	  if(this.state.disableTypeAhead){
		return;
	  }
	  e.preventDefault();
	  console.log("dbdjhc");
	  this.props.getAllgroup(GLOBAL.ciConsumerCompany===''?this.state.companyId:GLOBAL.ciConsumerCompany);
	  this.setState({
		AllAMSGrps:true,
		ShowAllAMSGRP:false
	  });
	//   GLOBAL.AMSGRP="yes";
	  GLOBAL.AMSEXIST="yes";

	  this.setSelectGroup([]);
	}
	componentWillUnmount()
	{
		let groupList=[],breakFixSGAEName=[],breakFixSGIName={'data':''};
		GLOBAL.AMSEXIST="yes";
		this.props.dispatch(change('createInvestigationForm','supportPIndividualName',''));
		this.props.dispatch(change('createInvestigationForm','supportPIndividual',''));
		this.props.dispatch(change('createInvestigationForm','implementationIndividual',''));
		this.props.dispatch(change('createInvestigationForm','supportPGroupName',''));
		this.props.dispatch(change('createInvestigationForm','supportPGroup',''));
		this.props.dispatch({type:'GROUP_LIST',groupList});
		this.props.dispatch({type:'LOAD_BREAKFIXSGAE_SUCCESS',breakFixSGAEName});
		this.props.dispatch({type: 'LOAD_BREAKFIXSGI_SUCCESS',breakFixSGIName});
		GLOBAL.ciId=GLOBAL.ciConsumerCompany=GLOBAL.businessCriticalityId=GLOBAL.businessCriticality='';
	}

	componentDidUpdate(previousProp){
		if(this.props.selectedAssignmentUser  !==  previousProp.selectedAssignmentUser && this.props.selectedAssignmentUser.length>0){
		  this.onIndividualSelection(this.props.selectedAssignmentUser);
		}
	  }

	AllAMSGrps(e)
	{
		if(this.state.disableTypeAhead){
		   return;
		}
		this.checkforAMSattributes('','');
		e.preventDefault();
		console.log("ALLAMSGRP ")
        this.setState({
			ShowAllAMSGRP:true,
			AllAMSGrps:false,
		})
		GLOBAL.AMSEXIST="";

		this.setSelectGroup([]);
	}
	renderSourceName(breakFixData) {
		if (!breakFixData) {
			return (
				<option></option>
			);
		}

		return breakFixData.map((breakFixObj) => {
			return (
				<option value={breakFixObj.field1Value}>{breakFixObj.field1Key}</option>
			);
		});
	}

	renderPriorityName(breakFixData) {
		if (!breakFixData) {
			return (
				<option></option>
			);
		}

		return breakFixData.map((breakFixObj) => {
			breakFixObj.field1Key = this.state.priorityValue;
			return (
				<option value={breakFixObj.field1Value}>{breakFixObj.field1Key}</option>
			);
		});
	}

	renderCriticalityName(breakFixData) {
		breakFixData.map((a)=>{
			a.field1Value=parseInt(a.field1Value);
		
		})
		breakFixData.sort((a, b) => (a.field1Value > b.field1Value) ? 1 : -1);

		if (!breakFixData) {
			return (
				<option></option>
			);
		}
		return breakFixData.map((breakFixObj) => {

			return (
				<option value={breakFixObj.field1Value}>{breakFixObj.field1Key}</option>
			);
		});
	}
    renderSourceData(breakFixData)
	{
		console.log("breakFixData: ", breakFixData);
		if (!breakFixData) {
			return (
				<option></option>
			);
		}
		return breakFixData.map((breakFixObj) => {

			return (
				<option value={breakFixObj.field1Value}>{breakFixObj.field1Key}</option>
			);
		});
	}
	renderUrgencyName(breakFixData) {
		
		  let sorteddata = [];
		if (!breakFixData) {
			return (
				<option></option>
			);
		}
		sorteddata = _.sortBy(breakFixData, o => parseInt(o.field1Value, 10));
		//console.log(sorteddata)
		return sorteddata.map((breakFixObj) => {
					//alert(breakFixObj.field1Value)
				return(
					<option value={breakFixObj.field1Value}>{breakFixObj.field1Key}</option>
				)
		});

	}
	// AllAMSGrps(e)
	// {
	//   e.preventDefault();
	//   console.log("dbdjhc");
	//   this.props.getCIGroupData(GLOBAL.ciConsumerCompany);
	//   this.setState({
	// 	AllAMSGrps:true
	//   });
	// }
	
	// onServiceNameChange(serviceIdValue, companyNameVal) {
	// 	let consumerData = [];
	// 	let consumerDataDetails = [];
	// 	let myObj = [];
	// 	if(this.state.RadioValue=='service'){
	// 	  GLOBAL.breakFixConsumerID = "";
	// 	  GLOBAL.breakFixConsumerName = "";
	// 	}
	// 	// consumerDataDetails = [];
	// 	ciNameDataDetails = [];

	// 	if (serviceIdValue != "") {
	// 		//  this.props.loadBreakFixSG(GLOBAL.breakFixServiceID);
	// 		if(this.state.RadioValue=='service')
	// 		   this.props.loadBreakFixSGAE(GLOBAL.breakFixServiceID, GLOBAL.breakFixCompanyName, "Investigation");
	// 	} else {
	// 		console.log("Error in loading loadBreakFixSG");
	// 	}

    //     if(this.state.RadioValue=='service'){
	// 		this.props.dispatch(change("createInvestigationForm", "supportPGroup", ''))
	// 	    this.props.dispatch({
	// 			type: 'LOAD_BREAKFIXSGI_SUCCESS',
	// 			breakFixSGIName: {data: ''}
	// 		})
	// 	if( !isNaN(parseInt(companyNameVal, 10)) ){
	//       let companyId = parseInt(companyNameVal, 10);
	//       // console.log('companyId',companyId)
	//     //   api.get(GLOBAL.ciNameUrl+ companyId).then((response)=>{
	// 	// 	//alert("hi")
	// 	// 	ciNameData = response.data;
	// 	// 	for (var i = 0; i < ciNameData.length; i++) {
	// 	// 	  ciNameObject = {ciId:'',label:'',ciName:'',ciClass:'',ciCompany:'',ciIdNumber:''};
	// 	// 	  ciNameObject.ciId=ciNameData[i].CI_ID;
	// 	// 	  ciNameObject.label=ciNameData[i].CI_NAME+"|"+ciNameData[i].CLASS_NAME+"|"+ciNameData[i].COMPANY_NAME;
	// 	// 	  ciNameObject.ciName=ciNameData[i].CI_NAME
	// 	// 	  ciNameObject.ciClass=ciNameData[i].CLASS_NAME
	// 	// 	  ciNameObject.ciCompany=ciNameData[i].COMPANY_NAME
	// 	// 			  ciNameObject.ciIdNumber=ciNameData[i].CI_CODE;
	// 	// 	  //GLOBAL.consumerCompanybreakfix=impactedServiceData[i].companyName;
	// 	// 	  ciNameDataDetails.push(ciNameObject);
	// 	// 	}
	// 	// 	//ciNameDataDetails = Array.from(new Set(ciNameDataDetails));
	// 	// 		  console.log("ciNameDataDetails")
	// 	// 		  console.log(ciNameDataDetails)
	// 	// 		this.setState({
	// 	// 			ciNameDataDetails:ciNameDataDetails
	// 	// 		});
	// 	//   });
		
	// 	this.setState({
	// 		companyId:companyId
	// 	});

	// 	//   axios.get(GLOBAL.consumerUsersNewUrl + '?companyId=' + companyId).then((response)=>{
	// 	// 	//alert("hi123")
	// 	// 	consumerData = response.data;
	// 	// 	console.log("consumerData::",consumerData);
	// 	// 	console.log("consumerData.length::",consumerData.length);
			
	// 	// 	for (var i = 0; i < consumerData.length; i++) {
	// 	// 	  myObj = {value:'',label:'',locationid:'',locationname:'',deptid:'',deptname:''};
	// 	// 	  myObj.value=consumerData[i].userId;
	// 	// 	  myObj.label=consumerData[i].fullname+"|"+consumerData[i].userId+"|"+consumerData[i].email+"|"+consumerData[i].companyName;
	// 	// 	  myObj.locationid=consumerData[i].locationId
	// 	// 	  myObj.locationname=consumerData[i].locationName
	// 	// 	  myObj.deptid=consumerData[i].departmentId
	// 	// 	  myObj.deptname=consumerData[i].departmentName
	// 	// 	  //GLOBAL.consumerCompanybreakfix=consumerData[i].companyId;
	// 	// 	  consumerDataDetails.push(myObj);
	// 	// 	}
	// 	// 	console.log('consumerDataDetails - ',consumerDataDetails);
	// 	// 	consumerDataDetails = Array.from(new Set(consumerDataDetails));
	// 	// 	this.setState({
	// 	// 		consumerDataDetails:consumerDataDetails
	// 	// 	});
	// 	//   });
		  
	//     }
	//     else{
	//       console.log('error in loading impacted ci as no company id found.');
	//     }
	//   }
	// }

	onGroupNameChange(e) {
		
		this.props.loadBreakFixSGI(e.target.value);
		/*if (this.props.supportPGroup != '') {
			this.props.loadBreakFixSGI(e.target.value);
		} else {
			console.log("Error in loading loadBreakFixSGI");
		}*/
		this.props.dispatch(change("createInvestigationForm", "supportPIndividualName", 'Select..'));
		this.props.dispatch(change("createInvestigationForm", "supportPIndividual", '0'));
		
	}
	ShowAMSGroup(breakFixData)
	{
	
	  console.log("breakFixDatagropup",breakFixData=="")
		let grouplist = [];
		let type=typeof(breakFixData);
		console.log("sabc",type);
		if(breakFixData=="" || breakFixData.length==0||type=="string"){
		  return(
			  null
		  );
		}
	else{
	breakFixData.map((breakFixObj)=>{
	  grouplist.push(breakFixObj.groupName+"~"+breakFixObj.groupId);
	});
	  //  console.log(grouplist.toString());
	
		// grouplist = grouplist.filter( function( item, index, inputArray ) {
		//        return inputArray.indexOf(item) == index;
		// });
		//console.log("b:::"+grouplist.toString());

		// console.log('grouplist - ', grouplist);
	
		return grouplist.map((breakFixObj)=>{
		  let breakfixobj1=breakFixObj.split("~");
		 return(
				 <option value={breakfixobj1[1]}>{breakfixobj1[0]}</option>
			   );
		 });
		}
	}
	renderSupportPGroup(breakFixData) {
		console.log("breakFixDatabreakFixData",breakFixData);
		let grouplist = [];
		if (!breakFixData || breakFixData.length == 0) {
			return (
				null
			);
		}
		if((breakFixData!=""||breakFixData.length!= 0))
		breakFixData.map((breakFixObj) => {
			grouplist.push(breakFixObj.assignmentGroupId + "~" + breakFixObj.assignmentGroupName)
		});
		//  console.log(grouplist.toString());

		grouplist = grouplist.filter(function (item, index, inputArray) {
			return inputArray.indexOf(item) == index;
		});
		//console.log("b:::"+grouplist.toString());
		// console.log('grouplist - ', grouplist);

		return grouplist.map((breakFixObj) => {
			let breakfixobj1 = breakFixObj.split("~");
			return (
				<option value={breakfixobj1[0]}>{breakfixobj1[1]}</option>
			);
		});
	}
	renderSupportAMSGroup(breakFixData)
	{
		console.log("breakFixDatabreakFixData",breakFixData);
		let grouplist = [];
		if (!breakFixData || breakFixData.length == 0) {
			return (
				null
			);
		}
		if((breakFixData!=""||breakFixData.length!= 0))
		breakFixData.map((breakFixObj) => {
			grouplist.push(breakFixObj.assignmentGroupId + "~" + breakFixObj.assignmentGroupName)
		});
		//  console.log(grouplist.toString());

		grouplist = grouplist.filter(function (item, index, inputArray) {
			return inputArray.indexOf(item) == index;
		});
		//console.log("b:::"+grouplist.toString());

		return grouplist.map((breakFixObj) => {
			let breakfixobj1 = breakFixObj.split("~");
			return (
				<option value={breakfixobj1[0]}>{breakfixobj1[1]}</option>
			);
		});
	}
	renderSupportPIAEGroup(breakFixData) {

		if (!breakFixData || breakFixData.length == 0) {
			return (
				null
			);
		}
		return breakFixData.map((breakFixObj) => {
			if (breakFixObj.assignmentGroupId == GLOBAL.breakfixgroupid && breakFixObj.assignmentMemberId != 0 && breakFixObj.assignmentMemberId != null)
				return (
					<option value={breakFixObj.assignmentMemberId}>{breakFixObj.assignmentMemberName}</option>
				);
		});
	}

	getLabel(event) {
		GLOBAL.supportPIGroupName = '';
		this.props.breakFixSGIName.map((obj) => {
			//alert(obj.field1Value);
			if (obj.field1Value == event.target.value) {
				GLOBAL.supportPIGroupName = obj.field1Key;

			}
		})
		//  console.log(GLOBAL.supportPIGroupName);
	}

	renderSupportPIGroup(breakFixData) {
		if (!breakFixData || breakFixData.length == 0) {
			return (
				null
			);
		}
		return breakFixData.map((breakFixObj) => {
			return (
				<option value={breakFixObj.field1Value}>{breakFixObj.field1Key}</option>
			);
		});
	}
	// onRadioSelect(event){
	// 	this.setState({RadioValue: event.target.value,impactedServiceName:'',impactedCIeName:''})
	// 	GLOBAL.serviceErrorColor=''
	// 	GLOBAL.CIError=''
	// 	if(event.target.value=='service'){
	// 		GLOBAL.ciId='',GLOBAL.ciName='',GLOBAL.ciIdNumber=''
	// 	}
	// 	else if(event.target.value=='impactedCI'){
	// 		GLOBAL.breakFixServiceID='',GLOBAL.breakFixCompanyName=''
	// 	}
	// }
	onImpactedSelection(impactedID,companyNameVal, suggestion)
	{
		this.checkforAMSattributes('','');
		this.setState({
			AllAMSGrps:false,
			selectedImpactedCi: suggestion.ciName
		  });
		  if(GLOBAL.ciId!==''){
			this.showImpactedCIInfoIcon(true);
		  }else{
			this.showImpactedCIInfoIcon(false);
		  }
		if(GLOBAL.ciId!='')
		{
			//if(this.state.RadioValue=='impactedCI'){
			    // this.props.loadBreakFixSGAE(GLOBAL.ciId, GLOBAL.ciConsumerCompany, "Investigation","impactedCI");
				 //this.setState({companyId:GLOBAL.ciConsumerCompany})
				 this.props.dispatch(change("createInvestigationForm", "supportPGroup", ''))
		         this.props.dispatch({
					 type: 'LOAD_BREAKFIXSGI_SUCCESS',
					 breakFixSGIName: {data: ''}
				 })
				 //GLOBAL.breakFixConsumerID = "";
		         //GLOBAL.breakFixConsumerName = "";
				 GLOBAL.CIError ='';
			//}
		}
	}
	setErrorImpactedColor(value){
		     this.setState({impactedCIeName: value})
	}
	setCompany(event){
		let groupList=[];
		this.setState({companyId: event.target.value});
		GLOBAL.breakFixConsumerID='',GLOBAL.ciId='',GLOBAL.ciConsumerCompany='';
		this.props.dispatch(change('createInvestigationForm','companyName',event.target[event.target.selectedIndex].text));
		this.props.dispatch(change('createInvestigationForm','supportPIndividualName',''));
		this.props.dispatch(change('createInvestigationForm','supportPIndividual',''));
		this.props.dispatch(change('createInvestigationForm','implementationIndividual',''));
		this.props.dispatch(change('createInvestigationForm','supportPGroupName',''));
		this.props.dispatch(change('createInvestigationForm','supportPGroup',''));
		this.props.dispatch({type:'GROUP_LIST',groupList});
		this.showConsumerInfoIcon(false);
		this.props.showConsumerDetails(false);
		this.showImpactedCIInfoIcon(false);
        this.showIndividualInfoIcon(false);
		this.props.showConsumerDetails(false);
		this.props.showImpactCI(false);
		this.props.showImplementIndividualDetails(false);
	}

	onCompanySelection(selectedCompany) {
		// console.log('selectedCompany - ',selectedCompany);
		this.setState({ selectedCompanyList: selectedCompany });
		let event = {
			target: {
				value: '',
				text: ''
			}
		};
		if(selectedCompany && selectedCompany.length === 1){
			this.checkforAMSattributes('company',selectedCompany);
			event['target']['value'] = selectedCompany[0]['id'];
			event['target']['text'] = selectedCompany[0]['label'];
		}

		let groupList=[];
		this.setState({companyId: event.target.value});
		GLOBAL.breakFixConsumerID='',GLOBAL.ciId='',GLOBAL.ciConsumerCompany='';
		this.props.dispatch(change('createInvestigationForm','company',event.target.value));
		this.props.dispatch(change('createInvestigationForm','companyName',event.target.text));
		this.props.dispatch(change('createInvestigationForm','supportPIndividualName',''));
		this.props.dispatch(change('createInvestigationForm','supportPIndividual',''));
		this.props.dispatch(change('createInvestigationForm','implementationIndividual',''));
		this.props.dispatch(change('createInvestigationForm','supportPGroupName',''));
		this.props.dispatch(change('createInvestigationForm','supportPGroup',''));
		this.props.dispatch({type:'GROUP_LIST',groupList});
		this.resetCIRequesterFields();
		this.showConsumerInfoIcon(false);
		this.props.showConsumerDetails(false);
		this.showImpactedCIInfoIcon(false);
		
	  }

	renderCompany(companyList){
		if(companyList==='') return;
		return companyList.map((item)=>{
			  return <option value={item.field1Value}>{item.field1Key}</option>
		});
   }

   validateField(event){
	if(event.target.value === "") {
		this.setState({companyError:'error'});
	 }else{
		this.setState({companyError:''});
  }
   }

   getGroupOptions(){
	if(this.state.AllAMSGrps==false){
		let grouplist = [];
		let breakFixData = JSON.parse(JSON.stringify(this.props.breakFixSGAEName));
		if (!breakFixData || breakFixData.length == 0) {
			return (
				[]
			);
		}
		if((breakFixData!=""||breakFixData.length!= 0))
		breakFixData.map((breakFixObj) => {
			let obj = {};
			obj['label'] = breakFixObj.assignmentGroupName;
			obj['id'] = breakFixObj.assignmentGroupId;
			obj['isExternal']= breakFixObj.isExternal;
			obj['supportCompanyID']= breakFixObj.assignmentCompanyId;
			obj['supportCompanyName']= breakFixObj.assignmentCompanyName;
			grouplist.push(obj);
		});
		//  console.log(grouplist.toString());

		grouplist = grouplist.filter(function (item, index, inputArray) {
			return inputArray.indexOf(item) == index;
		});
		//console.log("b:::"+grouplist.toString());
		// console.log('grouplist - 1 ', grouplist);
		return grouplist;
	}
	else{
		let breakFixData = JSON.parse(JSON.stringify(this.props.allgroupdata));
		let grouplist = [];
		let type=typeof(breakFixData);
		// console.log("sabc",type);
		if(breakFixData=="" || breakFixData.length==0||type=="string"){
		  return(
			  []
		  );
		}
		else{
		breakFixData.map((breakFixObj)=>{
			let obj = {};
			obj['label'] = breakFixObj.groupName;
			obj['id'] = breakFixObj.groupId;
			obj['isExternal']= breakFixObj.isExternal;
			obj['supportCompanyID']= breakFixObj.supportCompanyID;
			obj['supportCompanyName']= breakFixObj.supportCompanyName;
			grouplist.push(obj);
		});
	}
	// console.log('grouplist - 2 ', grouplist);
	return grouplist;
   }
	}

	setSelectGroup(selected){
		// console.log('selected - ',selected);
		this.props.loadBreakFixSGISuccess({data: []});
		this.setState({selectedGroups: selected});
		this.props.setAssignmentGrpUsr(selected,"group");
		let event = {
			target: {
				text: '',
				value: ''
			}
		};
		if(selected && selected.length === 1){
			event['target']['value'] = selected[0]['id'];
			event['target']['text'] = selected[0]['label'];
			GLOBAL.isExternalInvestigationCreate=(selected[0]['isExternal']==null || selected[0]['isExternal']==undefined || selected[0]['isExternal']=="")? false : selected[0]['isExternal'];
			this.props.dispatch(change("createInvestigationForm","supportCompanyID",selected[0]['supportCompanyID']));
		    this.props.dispatch(change("createInvestigationForm","supportCompanyName",selected[0]['supportCompanyName']));
			this.showIndividualInfoIcon(false);
		    this.props.showIndividualDetails(false);
		} else {
			GLOBAL.isExternalInvestigationCreate="";
		}
		// this.setDropDownValue('supportPGroup', event);
		this.setState({ supportPGroup: event.target.value });
		this.onGroupNameChange(event);
		this.props.dispatch(change("createInvestigationForm","supportPGroup",event.target.value));
		this.props.dispatch(change("createInvestigationForm","supportPGroupName",event.target.text));
		GLOBAL.breakfixgroupid = event.target.value;
		
		this.setState({selectedIndividualList: [], supportPIndividual: ''});
		this.props.dispatch(change("createInvestigationForm","supportPIndividual",'0'));
		this.props.dispatch(change("createInvestigationForm","supportPIndividualName",'Select..'));

	}

	seterrorGroupcolor(){
	}

	setErrorCompanyColor(){
		if(this.state.selectedCompanyList.length === 1){
			this.setState({companyError: ''});
		}
		else{
			this.setState({companyError: 'error'});
		}
	}

	onIndividualSelection(selected){
		// console.log('selected - ', selected);
		this.setState({selectedIndividualList: selected});
		let event = {
			target: {
				text: 'Select..',
				value: '0'
			}
		};
		if(selected && selected.length === 1){
			this.showIndividualInfoIcon(true);
			this.props.showIndividualDetails(false);
			this.props.setIndividualDetails(selected[0].id);
			event['target']['value'] = selected[0]['id'];
			event['target']['text'] = selected[0]['label'];
		}
		// this.setDropDownValue('supportPIndividual', event);
		this.setState({ supportPIndividual: event.target.value });
		this.props.dispatch(change("createInvestigationForm","supportPIndividual",event.target.value));
		this.props.dispatch(change("createInvestigationForm","supportPIndividualName",event.target.text));
	}

	getIndividualOptions(){
		const {breakFixSGIName} = this.props;
		let arr = [];
		if (!breakFixSGIName || breakFixSGIName.length == 0) {
			return ( [] );
		}
		if(this.state.selectedGroups && this.state.selectedGroups.length === 1){
			breakFixSGIName.map((item) => {
				let obj = item;
				obj['id'] = item.field1Value;
				obj['label'] = item.field1Key;
				arr.push(obj);
			});
			return arr;
		}
		return arr;
	}

	companyInputChange(e){
		GLOBAL.breakFixConsumerID='',GLOBAL.ciId='';
		this.setState({
			typedCompany: e,
			selectedCompanyList: []
		});
		this.onCompanySelection([]);
		}
	resetCIRequesterFields(){
		const { change } = this.props;
		change('consumer', '');
		change('ciName', '');
		this.setState({consumerDataDetails: [], ciNameDataDetails: [], selectedConsumer: '', selectedImpactedCi: '', disableTypeAhead: true})
	}
	descriptionCharacter(e)
	{
		    let input = e.target.value.length;
			GLOBAL.cancelreasonCharLeft = GLOBAL.cancelreasonCharMax-input;
			this.setState({
				cancelreasonCharLeft:GLOBAL.cancelreasonCharMax-input
			})
            console.log("e.target.value",input,GLOBAL.cancelreasonCharLeft);
	}
	onCrossClickCompany(){
		GLOBAL.breakFixConsumerID='',GLOBAL.ciId='';
		this.companyInputChange('');
		this.checkforAMSattributes('company',-1);
		this.props.showImpactedCIDetails(false)
		this.props.showIndividualDetails(false)
		this.showImpactedCIInfoIcon(false);
        this.showIndividualInfoIcon(false);
		this.props.showConsumerDetails(false);
		this.props.showImpactCI(false);
		this.props.showImplementIndividualDetails(false);
	}

	onGroupInputChange(e){
		this.setState({
		  typedGroup: e
		 });
		 this.setSelectGroup([]);
		 this.showIndividualInfoIcon(false);
		 this.props.showIndividualDetails(false)
	  }
	  onCrossClickGroup(){
		this.onGroupInputChange('');
		this.setState({typedIndividual: ''});
		this.showIndividualInfoIcon(false);
		this.props.showIndividualDetails(false)
		// this.onSupGroupSelection([]);
	  }
	  
	  onIndividualInputChange(e){
		// console.log('e - ',e);
		this.setState({
		  typedIndividual: e
		 });
		 this.onIndividualSelection([]);
	  }
	  onCrossClickIndividual(){
		this.onIndividualInputChange('');
		// this.onIndivSelection([]);
		this.showIndividualInfoIcon(false);
		this.props.showIndividualDetails(false)
	  }
	  checkforAMSattributes(fieldName,value){
		let count=0;
        if(fieldName==='company' && value.length>0){
           count++;
        }else if(value===-1){
           count--;
        }else if(this.state.selectedCompanyList.length>0){
           count++;
        }

		if((fieldName==='urgency'||fieldName==='impact') && value!==''){
			count++;
		}else if(value===-1){
		   count--;
		}else if(this.state.priorityValue!==''){
			count++;
		}

		if(GLOBAL.ciId!==''){
			count++;
		}

		if(GLOBAL.breakFixConsumerID!==''){
			count++;
		}

		if(count===4){
			let amsAttribute={
				"Status":'0',
				"Priority":(fieldName==='urgency'||fieldName==='impact') && value!==''?value:this.state.priorityValue!==''?this.state.priorityValue:'0',
				"Service":'0',
				"Impacted CI":this.state.onCreateUsingSelect==='impactedCI' && GLOBAL.ciId!==''?GLOBAL.ciId:GLOBAL.ciId!==''?GLOBAL.ciId:'0',
				"Requestor":GLOBAL.breakFixConsumerID==""?'0':GLOBAL.breakFixConsumerID,
				"Class":GLOBAL.ciClassId===''?'0':GLOBAL.ciClassId,
				"User.Location":GLOBAL.servicelocationid==""?'0':GLOBAL.servicelocationid,
				"CI.Location": GLOBAL.ciLocationId==""?'0': GLOBAL.ciLocationId
			};

			let rulesAttribute={
				"status":  undefined,
				"priorityId":(fieldName==='urgency'||fieldName==='impact') && value!==''?value:this.state.priorityId!==''?this.state.priorityId:'0',
				"serviceName": undefined,
				"impactedCi":  this.state.selectedImpactedCi,
				"impactedUsername":this.state.selectedConsumer,
				"ciClassName": GLOBAL.ciClassName ? GLOBAL.ciClassName : "",
				"userLocation": GLOBAL.servicelocationname==""?'0':GLOBAL.servicelocationname,
				"ciLocation": GLOBAL.ciLocationName==""?'0': GLOBAL.ciLocationName,
				"impactedCiId": this.state.onCreateUsingSelect==='impactedCI' && GLOBAL.ciId!==''?GLOBAL.ciId:GLOBAL.ciId!==''?GLOBAL.ciId:'0',
				"requestorId": GLOBAL.breakFixConsumerID==""?'0':GLOBAL.breakFixConsumerID,
				"classId": GLOBAL.ciClassId===''?'0':GLOBAL.ciClassId,
				"userLocationId": GLOBAL.servicelocationid==""?'0':GLOBAL.servicelocationid,
				"ciLocationId":  GLOBAL.ciLocationId==""?'0': GLOBAL.ciLocationId
			};	
			console.log('Priority --- ', fieldName, ' --- ', value, ' --- ', this.state.priorityId)	
			this.setState({ rulesAttribute });
			// this.props.loadNewBreakFixSGAE(GLOBAL.ciId===''?'0':GLOBAL.ciId, fieldName==='company' && value.length>0?value[0].id:this.state.selectedCompanyList[0].id, "Investigation","impactedCI",amsAttribute);
			this.setState({disableTypeAhead: false});
		}else{
			this.setState({disableTypeAhead: true});
		}
		this.props.dispatch({type:'GROUP_LIST',groupList: []});
		this.props.dispatch(change('createInvestigationForm','supportPIndividualName',''));
		this.props.dispatch(change('createInvestigationForm','supportPIndividual',''));
		this.props.dispatch(change('createInvestigationForm','implementationIndividual',''));
		this.props.dispatch(change('createInvestigationForm','supportPGroupName',''));
		this.props.dispatch(change('createInvestigationForm','supportPGroup',''));
		this.setState({typedIndividual: '', selectedIndividualList: [], typedGroup: '', selectedGroups: [],AllAMSGrps: false});
		this.props.setAssignmentGrpUsr([],"group");
	  }
	  clearGroupandIndividual(){
		this.props.dispatch({type:'GROUP_LIST',groupList: []});
		this.props.dispatch(change('createInvestigationForm','supportPIndividualName',''));
		this.props.dispatch(change('createInvestigationForm','supportPIndividual',''));
		this.props.dispatch(change('createInvestigationForm','implementationIndividual',''));
		this.props.dispatch(change('createInvestigationForm','supportPGroupName',''));
		this.props.dispatch(change('createInvestigationForm','supportPGroup',''));
		this.setState({typedIndividual: '', selectedIndividualList: [], typedGroup: '', selectedGroups: [], disableTypeAhead: true, AllAMSGrps: false});
		this.props.setAssignmentGrpUsr([],"group");
	  }
	  afterConsumerSelected(){
		if(GLOBAL.breakFixConsumerID!==''){
			this.showConsumerInfoIcon(true);
		  }else{
			this.showConsumerInfoIcon(false);
		  }
        this.checkforAMSattributes('','');
	  }

	  showConsumerInfoIcon(value){
		if(!value){
          this.props.showIndividualDetails(false);
		  this.showIndividualInfoIcon(false);
		}
		this.setState({showConsumerInfoButton: value});
	   }

	   showImpactedCIInfoIcon(value){
		if(!value){
			this.props.showIndividualDetails(false);
			this.showIndividualInfoIcon(false);
		}
		this.setState({showImpactedCIInfoButton: value});
	   }
	   showIndividualInfoIcon(value){
		this.setState({showIndividualInfoButton: value});
	   }
	render() {
		console.log("this.props.investigationSource",this.state.cancelreasonCharLeft);
		let { error, handleSubmit, pristine, reset, submitting, consumer, issueDescription, additionalInfo, reportedOn, reportedThrough, supportPGroup, supportPIndividual, service, impactedUsers, priority } = this.props;
		GLOBAL.priorityValueType = this.state.priorityValue
		if (this.props.breakFixSGName instanceof Error) {
			return (
				<h2>
					Network Error Occured...Break Fix will be here soon....
    						</h2>
			);
		}

		if (this.props.breakFixSGIName instanceof Error) {
			return (
				<h2>
					Network Error Occured...Break Fix will be here soon....
    						</h2>
			);
		}

		const errormsg=this.props.translator[error];

		// console.log('****************', {state: this.state, props: this.props});

		let groupOptions = this.getGroupOptions();
		let individualOptions = this.getIndividualOptions();

		return (
			<div>
				<font color="red">{errormsg && <strong>{errormsg}</strong>}</font>
				<div className="borderForm bFormDiv">
					<Row>
						<Col md={6}>
						     <Form.Group>
							      <Form.Label bsClass=""><span className="rStar" />{this.props.translator['Company']}</Form.Label>
								  <Field component={TypeaheadExampleSingleSelect}
									onSelection={this.onCompanySelection}
									setErrorColor={this.setErrorCompanyColor}
									name="company"
									id="company"
									className='form-control'
									errorClass={this.state.companyError==='error'?'error':''}
									options={this.state.companyList}
									selectedOptions={this.state.selectedCompanyList}
									onInputChange={this.companyInputChange}
									onCrossClick={this.onCrossClickCompany}
									typedValue={this.state.typedCompany}
									>
								  </Field>
						     </Form.Group>
						</Col>

						<Col md={6}>
							<Form.Group>
								<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Requestor']}</Form.Label>
								<div className="position-re infoBtnDiv">
									<Field name="consumer"
									id="impactedCI"
									    errorColor={this.state.consumer==='error' || GLOBAL.consumerErrorColor==='error'?'error':''}
										options={this.state.consumerDataDetails}
										companyId={this.state.companyId}
										onCreateUsingSelect={'impactedCI'}
										afterConsumerSelected={this.afterConsumerSelected}
										checkforAMSattributes={this.checkforAMSattributes}
										clearGroupandIndividual={this.clearGroupandIndividual}
										selectedOption={this.state.selectedConsumer}
              							onConsumerSelected={this.onConsumerSelected}
										component={RFReactConsumer} setErrorColor={this.setErrorColor}
										showConsumerInfoIcon = {this.showConsumerInfoIcon}
										showConsumerDetails={this.props.showConsumerDetails}>
										
									</Field>
									{this.state.showConsumerInfoButton?<Button title="Info" bsPrefix=" " className="infoBtnDiv infoicn" onClick={() => { this.props.showConsumerDetails(true); }}><ImInfo/></Button>:null}
								</div>
							</Form.Group>
						</Col>
						
						<Col md={6}>
							<Form.Group>
								<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Impacted CI']}</Form.Label>
								<div className="position-re infoBtnDiv">
									<Field name="ciName"
									    errorColor={this.state.impactedCIeName==='error' || GLOBAL.CIError==='error'?'error':''}
										companyId={this.state.companyId}
										component={CIDetailsService}
										onImpactedSelection={this.onImpactedSelection}
										setErrorImpactedColor={this.setErrorImpactedColor}
										checkforAMSattributes={this.checkforAMSattributes}
										clearGroupandIndividual={this.clearGroupandIndividual}
										selectedOption={this.state.selectedImpactedCi}
										onCreateUsingSelect={'impactedCI'}
										showImpactedCIInfoIcon={this.showImpactedCIInfoIcon}
										showImpactedCIDetails={this.props.showImpactedCIDetails}
									>
									</Field>
									{this.state.showImpactedCIInfoButton?<Button title="Info" bsPrefix=" " className="infoBtnDiv infoicn" onClick={() => { this.props.showImpactedCIDetails(true); }}><ImInfo/></Button>:null}
								</div>
							</Form.Group>
					</Col>
						
						<Col md={6}>
								<Form.Group>
									<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Source']}</Form.Label>
									<Field name="source" component="select" type="select" className={this.state.sourceErrorColor==='error'|| GLOBAL.sourceErrorColor==='error' ?'form-control error':"form-control"} onBlur={(event) => { this.validateFieldAll('source', event); this.props.dispatch(change("createInvestigationForm","source",event.target[event.target.selectedIndex].text));}}>
									<option value="">Select</option>
												{this.renderSourceData(this.props.investigationSource)}
									</Field>

								</Form.Group>
							</Col>
					</Row>
					<Row>
						<Col md={6}>
							<Form.Group>
								<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator[' Problem Statement']}</Form.Label>
								<div className="position-re">
									<Field name="issueDescription" component="textarea"  maxLength="2000" className={this.state.issueDescription==='error'|| GLOBAL.issueErrorColor==='error' ?'form-control error':"form-control"} rows={2}  onChange={(event)=>{this.descriptionCharacter(event)}} onBlur={(event) => { this.validateFieldAll('issueDescription', event) }}  />
								</div>
								<p className="charLeft" style={{textAlign:"right","fontSize":"11px"}}>({this.props.translator['Characters Remaining']}: {this.state.cancelreasonCharLeft}/2000)</p>

							</Form.Group>
						</Col>
						<Col md={6}>
							<Form.Group>
								<Form.Label bsClass="">{this.props.translator['Problem Symptoms']}</Form.Label>
								<Field name="additionalInfo" component="textarea" className="form-control" rows={2} />
							</Form.Group>
						</Col>
					</Row>

					<div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
						<div className="bgGray padding-5">{this.props.translator['Prioritization']}</div>
						<Row>
							<Col md={6}>
								<Row>
								<Col md={6}>
										<Form.Group>
											<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Urgency']}</Form.Label>
											<Field name="urgencyMode" component="select" type="select" className={this.state.urgencyModeErrorColor==='error' || GLOBAL.urgencyErrorColor==='error' ?'form-control error':"form-control"} value={this.state.urgencyMode} onBlur={(event) => { this.validateFieldAll('urgencyMode', event) }} onChange={(event) => { this.setDropDownValue('urgencyMode', event); this.onChangeUrgency(event); }}>
												<option value="">Select</option>
												{this.renderUrgencyName(this.props.investigationUrgency)}
											</Field>
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group>
											<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Impact']}</Form.Label>
											<Field name="criticality" component="select" type="select" className={this.state.criticalityErrorColor==='error' || GLOBAL.criticalityErrorColor==='error' ?'form-control error':"form-control"} value={this.state.criticality} onBlur={(event) => { this.validateFieldAll('criticality', event) }} onChange={(event) => { this.setDropDownValue('criticality', event); this.onChangeCriticality(event); }}>
												<option value="">Select</option>
												{this.renderCriticalityName(this.props.investigationCriticality)}
											</Field>
										</Form.Group>
									</Col>
									
								</Row>
							</Col>
							<Col md={6}>
								<Form.Group>
									<Form.Label bsClass="">{this.props.translator['Priority']}</Form.Label>
									<Field name="priority" component="input" type="text" className="form-control" disabled="true" placeholder={this.state.priorityValue} value={this.state.criticality}>
									</Field>
								</Form.Group>
							</Col>
						</Row>
					</div>
					<div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
						<div className="bgGray padding-5 inciSupProviderDv">{this.props.translator['Assignment']}{this.props.roleIdentification('17')?<span><span> {this.state.AllAMSGrps==false?<a className="showwAllGrps"  href='javascript:void(0)' onClick={this.AllGrps}>Show ALL Groups</a>:<a className="showwAllGrps"  href='javascript:void(0)' onClick={this.AllAMSGrps}>Show AMS Groups</a>}</span></span>:null}</div>
						<Row>
							<Col md={6}>
								<Form.Group>
									<Form.Label bsClass="">{this.props.translator['Problem Mgmt. Group']}</Form.Label>
									<div className="dvTypehdropdown">
									<RuleAmsDdropDown 
									component={TypeaheadExampleSingleSelect}
									showAms={this.state?.AllAMSGrps}
									onSelection={this.setSelectGroup}
									setErrorColor={this.seterrorGroupcolor}
									name="supportPGroup"
									className="form-control"					
									groupData={groupOptions}
									selectedOptions={this.state.selectedGroups}
									disabled={this.state.disableTypeAhead}	
									onInputChange={this.onGroupInputChange}
									onCrossClick={this.onCrossClickGroup}
									typedValue={this.state.typedGroup}
									moduleName="problem"
									ruleType="assignment"
									formData={this.props}
									rulesAttribute={this.state?.rulesAttribute}
									ruleFor="problemManager"
									/> 
									{/* <Field component={TypeaheadExampleSingleSelect}
										onSelection={this.setSelectGroup}
										setErrorColor={this.seterrorGroupcolor}
										name="supportPGroup"
										className="form-control"
										options={groupOptions}
										selectedOptions={this.state.selectedGroups}
										onInputChange={this.onGroupInputChange}
										onCrossClick={this.onCrossClickGroup}
										typedValue={this.state.typedGroup}
										disabled={this.state.disableTypeAhead}
										>
									</Field> */}
									</div>
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group className="prblmBtnDv infoBtnDiv typeHeadicn downinfobtn form-group">
									<Form.Label bsClass="">{this.props.translator['Problem Manager']}</Form.Label>
									<div className="dvTypehdropdown">
									<Field component={TypeaheadExampleSingleSelect}
										onSelection={this.onIndividualSelection}
										// setErrorColor={this.setErrorIndividualColor}
										name="supportPIndividual"
										className="form-control"
										options={individualOptions}
										selectedOptions={this.state.selectedIndividualList}
										onInputChange={this.onIndividualInputChange}
										onCrossClick={this.onCrossClickIndividual}
										typedValue={this.state.typedIndividual}
										disabled={this.state.disableTypeAhead}

										>
											
									</Field>
									</div>
									{!this.state.disableTypeAhead && 
									<div className={this.state.selectedGroups.length>0 || this.state.showIndividualInfoButton?'indiIcnDv':''}>
									{this.state.selectedGroups.length>0?(
										<Button 
										title={this.props.translator["Show available individuals"]}
										bsPrefix=" " 
										className="asignicn" 
										onClick={() => { 
										  this.props.showAvailableIndividual();}}>
										  <RiUserSearchLine />
										  </Button>
										):""
										} 
									    {this.state.showIndividualInfoButton?<Button title="Info" bsPrefix=" " className="asignicn" onClick={() => { this.props.showIndividualDetails(true); }}><ImInfo/></Button>:null}	
										</div>
										}					
								</Form.Group>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		);
	}
}

CreateInvestigationForm = reduxForm({
	form: 'createInvestigationForm',
	fields: ['consumer', 'issueDescription', 'additionalInfo', 'reportedOn', 'reportedThrough', 'supportPGroup', 'supportPIndividual', 'criticality', 'urgencyMode','ciName','source','company'],
	initialValues: { consumer: "", issueDescription: "", additionalInfo: "", reportedOn: "", reportedThrough: "", supportPGroup: "", source:"",supportPIndividual: "", criticality: "", urgencyMode: "",'ciName':'','company':'' },
	destroyOnUnmount: true

})(CreateInvestigationForm);

let selector = formValueSelector('createInvestigationForm')
CreateInvestigationForm = connect(
	state => {
		let supportPGroup = selector(state, 'supportPGroup')
		let criticality = selector(state, 'criticality')
		let urgencyMode = selector(state, 'urgencyMode')
		let source=selector(state,'source');
		let ItemList = getFormValues("createInvestigationForm")(state);
		return {
			supportPGroup,
			criticality,
			urgencyMode,
			source,
			ItemList
		}

	}
)(CreateInvestigationForm)
export function mapStateToProps({ breakFixSourceName,
	investigationCriticality,investigationSource,
	investigationUrgency, breakFixSGName, breakFixSGIName, breakFixSGAEName,allgroupdata,ItemList}) {
	return {
		breakFixSourceName, investigationCriticality,investigationSource,
		investigationUrgency, breakFixSGName, breakFixSGIName, breakFixSGAEName,allgroupdata,ItemList
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		loadBreakFixSourceName,
		getPriorityUrgencyCriticalityValues, loadBreakFixSG, loadBreakFixSGI, loadNewBreakFixSGAE,getAllgroup, loadBreakFixSGISuccess
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateInvestigationForm);