
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button, ButtonToolbar, NavDropdown, ListGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { HiPlus, HiEye, HiOutlineRefresh } from "react-icons/hi";
import { change, reduxForm } from "redux-form";
import { TypeaheadExampleSingleSelect } from "../../common/formFields";
import { FaFileExport } from "react-icons/fa";
import Cookies from "universal-cookie";
import { validateRestRequest } from "../studio/connectors/utils";
import { ImSpinner6 } from "react-icons/im";
import { CiExport, CiImport } from "react-icons/ci";
import Dropzone from "react-dropzone";
import { IoClose } from "react-icons/io5";
import Swal from "sweetalert2";
import axios from "axios";
const cookies = new Cookies();
const gph = cookies.get("gph").split("~");
const username = typeof gph[32] !== "undefined" ? gph[32] : "";
const userId = typeof gph[43] !== "undefined" ? gph[43] : "";
const userFullName = typeof gph[42] !== "undefined" ? gph[42] : "";
const roles = typeof gph[33] !== "undefined" ? gph[33].split(",") : [];
const attachmentSize = 120;
let FormHeader = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const hosts = useSelector((state)=>state?.hosts);
  const [saveLoader, setSaveLoader] = useState(false);
  const [typedCompany, setTypedCompany] = useState("");
  const [expBtnDisable, setExpBtnDisable] = useState(false);
  const [impBtnDisable, setImpBtnDisable] = useState(false);
  const [filesPreview, setFilesPreview] = useState([]);
  const [filesToBeSent, setFilesToBeSent] = useState([]);
  const [clickAttachButton, setClickAttachButton] = useState(0);
  const [printcount,setPrintcount] = useState(1);

  const [companyError, setCompanyError] = useState("");
  const addForm = () => {
    localStorage.removeItem("gjsProject");
    navigate("/uiBuilder", {
      state: {
        companyId: props.selectedCompany[0]?.id,
        companyName: props.selectedCompany[0]?.label,
        activeTab: props?.filterTabActive,
      },
    });
  };

  ////// company

  const onCompanySelection = (company) => {
    props.setSelectedCompany(company);
  };

  const onCrossClickCompany = () => {
    setTypedCompany("");
    props.setSelectedCompany([]);
  };

  const companyCleared = (e) => {
    setTypedCompany(e);
  };

  const exportFile = () => {
    let requestOptions = {};
    requestOptions.host = hosts?.FORMENGINE_HOST;
    requestOptions.body = "";
    requestOptions.url = "/export-forms";
    requestOptions.verb = "get";
    setExpBtnDisable(true);

    validateRestRequest(requestOptions)
      .then((res) => {
        const fileName = "forms";
        const json = JSON.stringify(res?.data, null, 2);
        const blob = new Blob([json], { type: "application/json" });
        const href = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = href;
        setExpBtnDisable(false);
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((error) => {
        console.log("error", error);
        setExpBtnDisable(false);
      });
  };

  const removeFile = ()=> {
    setFilesToBeSent([])
    setFilesPreview([])
  }

 const onDrop = (acceptedFiles, rejectedFiles)=> {
    if (acceptedFiles.length > 0) {
    
      if (filesToBeSent.length < printcount) {
        let toBeSentFiles = [];
        toBeSentFiles.push(acceptedFiles);
        let previewFiles = [];
        for (let i in toBeSentFiles) {
          if (toBeSentFiles.hasOwnProperty(i)) {
            previewFiles.push(
            <ListGroup.Item bsPrefix=" ">
              <span className="float-r" style={{ cursor: 'pointer' }}
                  onClick={() => {
                    removeFile();
                  }}
                ><IoClose />
              </span>
              {toBeSentFiles[i][0].name}
            </ListGroup.Item>
          );
        }
      }
        setFilesPreview(previewFiles);
        setFilesToBeSent(toBeSentFiles);
      } else {
        Swal.fire({
          text: "File already attached.",
          button: "OK",
        });
      }
    } else {
    }
  }

  const onDropRejected = (rejectedFiles)=> {
    if (rejectedFiles && rejectedFiles[0] && rejectedFiles[0].file && rejectedFiles[0].file.size && rejectedFiles[0].file.size > 1048576 * attachmentSize) {
      Swal.fire({
        text: "Please upload File up to " + attachmentSize + " MB size.",
        button: "OK",
      });
    } else {
      Swal.fire({
        text: "File not supported.",
        button: "OK",
      });
    }
  }

 const  handleClick = (event)=> {
    if (filesPreview.length > 0) {
      setClickAttachButton(1)
    }
    if (filesToBeSent.length > 0) {
      let formData = new FormData();
      const obj = {username, userFullName,userId}
      formData.append("attachment",JSON.stringify(obj));
      formData.append("file", filesToBeSent[0][0]);
      setImpBtnDisable(true);
      axios
        .post("/api/import-form", formData)
        .then((response) => {
          if (response.status == 200) {
            Swal.fire({
              text: "File imported successfully.",
              button: "OK",
            });
            setImpBtnDisable(false);
            setFilesToBeSent([]);
            setFilesPreview([]);
            setClickAttachButton(0);
          } else if (
            response.data.auth == "false" ||
            response.data.auth == false
          ) {
            Swal.fire({
              text: "This user does not have access to upload attachments.",
              button: "OK",
            });
            setImpBtnDisable(false);
            setFilesToBeSent([]);
            setFilesPreview([]);
            setClickAttachButton(0);
          }else if(response?.statusCode !== 200){
            Swal.fire({
              text: "Some error occurred please check your json file!",
              button: "OK",
            });
            setImpBtnDisable(false);
            setFilesToBeSent([]);
            setFilesPreview([]);
            setClickAttachButton(0);
          }
        })
        .catch((error) => {
          Swal.fire({
            text:
            tr["Please upload File up to "] +
              attachmentSize +
              tr[" MB size."],
            button: "OK",
          });
          console.log("error in uploading file.");
          this.setState({ clickAttachButton: 0 });
        });
    } else {
      Swal.fire({
        text: tr["Please upload some files first"],
        button: tr["OK"],
      });
    }
  }

  return (
    <>
      <Row className="margin-b-15">
        <Col lg={6} md={6} sm={6} xs={5}>
          <h1 bsClass="" className="sPageHeading1">
            {"Forms"}
          </h1>
        </Col>
        <Col lg={6} md={6} sm={6} xs={7}>
          <div className="paHedFilterSecDv">
            <div className="paHedFilter">

              <Button
                type="button"
                onClick={() => {
                  addForm();
                }}
                bsPrefix=" "
                id="addTheme"
                className="myBt plus"
                disabled={saveLoader}
                title={tr["Create"]}
              >
                <HiPlus />
              </Button>
             {roles.includes("70") && <NavDropdown
                className="myBt noArrow mybtImprt"
                bsPrefix=" "
                as="div"
                title={
                  <CiImport title={"Import"} style={{ strokeWidth: "1.2px" }} />
                }
              >
                <Dropzone
                  onDrop={(files) => onDrop(files)}
                  onDropRejected={(files) => onDropRejected(files)}
                  maxSize={1048576 * attachmentSize}
                  accept={{ "application/json": [".json"] }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzoneAreacss">
                      <div
                        className="form-control"
                        {...getRootProps()}
                        title={tr["Drop files or click here to upload."]}
                      >
                        <input {...getInputProps()} />
                        {tr["Drop files or click here to upload."]}
                      </div>

                      {filesPreview.length > 0 && (
                        <ListGroup bsPrefix=" " className="attachment-break">
                          {filesPreview}
                        </ListGroup>
                      )}

                      <div className="dropzoneAreacssSiz">
                        <span>Supported format: JSON</span>
                        <span className="chld2">{"Max size"}: 120MB</span>
                      </div>
                      <div className="margin-t-5">
                        <button
                          bsPrefix=" "
                          title="Import Forms"
                          onClick={() => handleClick()}
                          disabled={impBtnDisable || filesPreview.length == 0}
                        >
                          <span>
                            {impBtnDisable ? (
                              <ImSpinner6 className="icn-spinner" />
                            ) : (
                              "Import"
                            )}
                          </span>
                        </button>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </NavDropdown>}
              {roles.includes("70") && (
                <Button
                  type="button"
                  onClick={() => {
                    exportFile();
                  }}
                  bsPrefix=" "
                  id="addTheme"
                  className="myBt plus"
                  disabled={expBtnDisable}
                  title={"Export Forms"}
                >
                  <span>
                    {expBtnDisable ? (
                      <ImSpinner6 className="icn-spinner" />
                    ) : (
                      <CiExport />
                    )}
                  </span>
                </Button>
              )}
              <Button
                bsPrefix=" "
                title="Refresh"
                onClick={() => {
                  props.refreshPage();
                }}
                role="button"
                className="myBt"
                disabled={props?.refreshedClicked}
              >
                <HiOutlineRefresh className="refreshFlip" />
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="margin-b-10">
        <Col lg={8} md={9} sm={9} xs={12}>
          <div aria-label="Table Filter" role="contentinfo" id="assFiltr">
            <div className="filtr">
              <Button
                className={props.filterTabActive == 1 ? "active" : null}
                title={"Default"}
                bsPrefix=" "
                onClick={() => {
                  props?.setFilterTabActive(1);
                }}
              >
                Default
              </Button>
              <Button
                className={props.filterTabActive == 2 ? "active" : null}
                title={"Custom"}
                bsPrefix=" "
                onClick={() => {
                  props?.setFilterTabActive(2);
                }}
              >
                Custom
              </Button>
            </div>
          </div>
        </Col>
        <Col lg={4} md={3} sm={3} xs={12} className="text-r">
          {props?.filterTabActive === 2 && (
            <div className="display-inline-block inlinefnd">
              <Form.Label className="margin-r-5">Company</Form.Label>
              <TypeaheadExampleSingleSelect
                id="companyDropdown"
                name="company"
                onSelection={onCompanySelection}
                setErrorColor={() => {}}
                options={props?.companyList || []}
                selectedOptions={props.selectedCompany}
                onInputChange={companyCleared}
                onCrossClick={onCrossClickCompany}
                typedValue={typedCompany}
                errorClass={companyError}
              />
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};
FormHeader = reduxForm({
  form: "formComponent",
  enableReinitialize: true,
})(FormHeader);
export default FormHeader;
