
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { ListGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import moment from "moment";
import Cookies from "universal-cookie";

const cookies = new Cookies();
let timezone = cookies.get("gph");
if (timezone) timezone = timezone.replace("s:", "");
if (timezone) timezone = timezone.substring(0, timezone.lastIndexOf("."));
timezone = timezone.split("~");
timezone = timezone[3];
let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

const SlaInfoPopup = ({ slaDetail }) => {
  const translator = useSelector((state) => state.spcmReducer.tr);

  const convertInHours = (minutes) => {
    let h = Math.floor(minutes / 60);
    if (h < 10 || h == 0) h = "0" + h;
    let m = parseInt(minutes % 60, 10);
    if (m < 10 || m == 0) m = "0" + m;
    let time = h + ":" + m;
    return time + " Hours";
  };

  return (
    <div className="popup-info-sla">
      <h2 className="text-x">{slaDetail?.slaName}</h2>
      <ListGroup bsPrefix=" ">
        <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
          <span className="rwKey">Start Time</span>
          <span className="rwVal">
            {moment(slaDetail?.slaStartTime * 1000)
              .tz(timezone)
              .format(jwtdf+" HH:mm:ss")}
          </span>
        </ListGroup.Item>
        <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
          <span className="rwKey">End Time</span>
          <span className="rwVal">
            {slaDetail.slaStopTime > 0
              ? moment(slaDetail?.slaStopTime * 1000)
                  .tz(timezone)
                  .format(jwtdf+" HH:mm:ss")
              : ""}
          </span>
        </ListGroup.Item>
        <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
          <span className="rwKey">Time Left </span>
          <span className="rwVal">{convertInHours(slaDetail?.timeLeft)}</span>
        </ListGroup.Item>
        <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
          <span className="rwKey">Elapsed Time</span>
          <span className="rwVal">
            {convertInHours(slaDetail?.timeElapsed)}
          </span>
        </ListGroup.Item>
        <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
          <span className="rwKey">Pause Duration</span>
          <span className="rwVal">{convertInHours(parseInt(slaDetail?.totalPausedTime,10) ||  0)}</span>
        </ListGroup.Item>
      </ListGroup>
    </div>
  );
};

export default SlaInfoPopup;
