
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import { useSelector } from "react-redux";

import PindCategories from "../../../common/header/pind-categories";
import Breadcrumbs from "../../../common/header/breadcrumbs";
import ShiftEditHeader from "./ShiftEditHeader";
import ShiftEditForm from "./ShiftEditForm";
import { useParams } from "react-router";
import ShiftEditRightSide from "./ShiftEditRightSide";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import "_Css/form/_form.scss";

const ShiftEditIndex = () => {
  const tr = useSelector((state) => state.spcmReducer.tr);
  const [selectedBusinessFn, setSelectedBusinessFn] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState([]);
  const [businessFnError, setBusinessFnError] = useState('');
  const [scheduleError, setScheduleError] = useState('');
  const {shiftId} = useParams()

  const showRightSideFun = (val) => {
  };

  const setBusinessFnErrorColor = () => {
    if (selectedBusinessFn.length > 0) {
      setBusinessFnError("");
    } else {
      setBusinessFnError("error");
    }
  };
  const setScheduleErrorColor = () => {
    if (selectedSchedule.length > 0) {
      setScheduleError("");
    } else {
      setScheduleError("error");
    }
  };
  
  const updateSelectedBusinessFn = (value) => {
    setSelectedBusinessFn(value);
  };
  const updateSchedule = (value) => {
    setSelectedSchedule(value);
  };


  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Breadcrumbs activePageName="Edit" parentPageurl='/onCallSchedule/shift' parentPageName="On Call Schedule" />
      </Container>

      <Container fluid>
        <ShiftEditHeader 
        shiftId={shiftId}/>
        <PanelGroup direction="horizontal" className="panelOverflow">
        <Panel minSize={33} order={2} defaultSize={67}>
          <ShiftEditForm
          translator={tr}
          setBusinessFnErrorColor={setBusinessFnErrorColor}
          setScheduleErrorColor={setScheduleErrorColor}
          businessFnError={businessFnError}
          scheduleError={scheduleError}
          setSelectedBusinessFn={updateSelectedBusinessFn}
          setSelectedSchedule={updateSchedule}
          selectedBusinessFn={selectedBusinessFn}
          selectedSchedule={selectedSchedule}
          shiftId={shiftId}
          />
          </Panel>
                <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
                <Panel minSize={33} order={2} defaultSize={33}>
                  <ShiftEditRightSide showRightSideFun={showRightSideFun} shiftId={shiftId}/>
                </Panel>
        </PanelGroup>
          
      </Container>
    </main>
  )
}

export default ShiftEditIndex;
