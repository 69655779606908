
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import {GLOBAL} from '../../../src/components/Globals'
import swal from 'sweetalert';
import { navigationHooks } from '../../helpers/NavigationHook';
import Swal from 'sweetalert2';

const api = axios.create({
    headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});
    export function resetParentFunction(){
        return(dispatch) =>{
        dispatch({ type: "PARENTFUNCTION_BY_COMPANY_ID", categoryByCompanyIdDropdownList: [] });
    }
    }
    export function postBusinessFunction(data) {
        console.log("Data===>",data)
        return (dispatch) => {
            console.log("GLOBAL.postBusinessFunctionUrl",GLOBAL.postBusinessFunctionUrl)
            api.post(GLOBAL.postBusinessFunctionUrl, data)
                .then((response) => {
                    console.log("response===>",response);
                    if(response.status == 200 && response.data == 'Duplicate record.'){
                        swal({
                            text: "Record already exists",
                            button: 'OK',
                        });
                        return false;

                    } else if (response.status == 200) {
                        navigationHooks.navigate("/businessFunction");    
                    }
                    swal({
                        text: "Successfully Created",
                        button: "OK",
                      });
                })
                .catch((err) => {
                     swal({
                         text: err.response.data[0].message,
                         button: 'OK',
                     });
                });
        };
    }
   
      export function businessFunctionByCompanyId(companyId) {
        let strObj = {};
        strObj.companyId = companyId;
        strObj = JSON.stringify(strObj);
        console.log(strObj);
        return (dispatch) => {
            api.get(GLOBAL.getBusinessFunctionUrl, { headers: { query: strObj } })
                .then((categoryByCompanyIdDropdownList) => {
                    if (!categoryByCompanyIdDropdownList) {
                        throw Error(categoryByCompanyIdDropdownList.statusText);
                    }
                    return categoryByCompanyIdDropdownList;
                })
                .then((categoryByCompanyIdDropdownList) => {
                    dispatch({
                        type: 'PARENTFUNCTION_BY_COMPANY_ID',
                        categoryByCompanyIdDropdownList
                    })
                    console.log("categoryByCompanyIdDropdownList",categoryByCompanyIdDropdownList)
                })
                .catch((err) => {
                    console.log("error in categories by company id")
                    console.log(err);
                });
        };
    }
    export function loadBusinessFunctionList() {
        return (dispatch) => {
            dispatch({type:'LOADER_IN_PROGRESS'});
            api.get(GLOBAL.businessFunctionListData)
                .then((categoryBoardList) => {
                    if (!categoryBoardList) {
                        throw Error(categoryBoardList.statusText);
                    }
                    return categoryBoardList;
                })
                .then((categoryBoardList) => {
                    dispatch({
                        type: 'LOAD_BUSINESS_FUNCTION_LIST_SUCCESS',
                        categoryBoardList
                    })
                    dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
                })
                .catch((err) => {
                    dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
                    console.log("error in loadCategoryListSuccess");
                    console.log(err);
                });
        };
    }
    export function patchBusinessFunction(data) {
      
        console.log("Data===>",data)
        return (dispatch) => {
            console.log("GLOBAL.patchBusinessFunction",GLOBAL.patchBusinessFunction)
            api.patch(GLOBAL.patchBusinessFunction, data)
                .then((response) => {
                    if(response.status == 200 && response.data == 'Duplicate record.'){
                        swal({
                            text: "Record already exists",
                            button: 'OK',
                        });
                        return false;

                    } else if (response.status == 200) {
                        navigationHooks.navigate("/businessFunction");    
                    }
                    swal({
                        text: "Successfully Updated",
                        button: "OK",
                      });
                })
                .catch((err) => {
                     swal({
                         text: err.response.data[0].message,
                         button: 'OK',
                     });
                });
        };
    }
    