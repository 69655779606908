
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Container } from 'react-bootstrap';

import PindCategories from "../../../common/header/pind-categories";
import Breadcrumbs from "../../../common/header/breadcrumbs";
import Header from "./ExternalLinkHeader";
import List from "./ExternalLinkListView";
import ShortDesc from "./ExternalLinkShortDesc";
import { useDispatch, useSelector } from "react-redux";
import { getExternalLinkList } from "../../../../actions/foundation/general/ExternalLinkAction";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import "_Css/common/_dataTable.scss";

const Index = () => {
  const [isRightSideVisible, setRightSideVisible] = useState(false);
  const [extLinkData, setExtLinkData] = useState();
  const translator = useSelector((state) => state.spcmReducer.tr);

  const showRightSideFun = (val) => {
    setRightSideVisible(val);
  };

  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(getExternalLinkList());
  }, [])

  const rowHandler = (extLinkData) => {
    setExtLinkData(extLinkData);
  };

  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Breadcrumbs activePageName={"External Link"} />
      </Container>

      <Container fluid className="position-re">
        <Header />
        <Row>
        <PanelGroup direction="horizontal" className="panelOverflow">
            <Panel id="sidebar" minSize={36} order={1} defaultSize={isRightSideVisible ? 67 : 100} className={isRightSideVisible ? "catBordDv isShowLeftPanel rwLeftPart" : "catBordDv rwLeftPart"}>
            <List
              isRightSideVisible={isRightSideVisible}
              showRightSideFun={showRightSideFun}
              rowHandler={rowHandler}
              translator={translator}/>
          </Panel>
            {isRightSideVisible ?
              <>
             <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
             <Panel minSize={33} order={2} defaultSize={33}>
            <div className="stickyArea rBoxStyle">
              <ShortDesc showRightSideFun={showRightSideFun}
                extLinkData={extLinkData} />
            </div>
                </Panel> </> : null}
            </PanelGroup>
        </Row>
      </Container>
    </main>
  )
}

export default Index;
