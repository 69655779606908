
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export const companyMenuListDataLoader = (state = {  
    loading: false,
    message: ''
  }, action) => {
    switch (action.type) {
      case 'COMPANY_MENULIST_LOADER_IN_PROGRESS':
        return Object.assign({},
          state, {
          loading: true,
          message: 'loading...'
        });
      case 'COMPANY_MENULIST_DETAILS_FETCHED_SUCCESSFULLY':
        return Object.assign({}, state, {
          loading: false,
          message: ''
        });
      default:
        return state;
    }
  };

  export const companyMenuListData = (state = initialState, action) => {
    switch (action.type) {
      case 'COMPANY_MENULIST_DATA_FETCHED_SUCCESSFULLY':
        return Object.assign({}, state, { allScheduleList: action.payload });
      case 'COMPANY_MENULIST_META':
        return Object.assign({}, state, { allScheduleListMeta: action.payload });
      case 'COMPANY_MENULIST_DROPDOWN_DATA':
        return Object.assign({}, state, { companyMenuListDropDownData: action.payload });
      case 'COMPANY_MENULIST_FIELD_DROPDOWN_DATA':
        return Object.assign({}, state, { companyMenuListFieldDropDownData: action.payload });
      case 'EMPTY_SCHEDULE_DETAILS_BY_SCHEDULE_ID_SUCCESS':
        return Object.assign({}, state, { scheduleDetailsByScheduleId: null });
      default:
        return state;
    }
  }
