
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import LoadingIcons from 'react-loading-icons';
import Cookies from 'universal-cookie';
import Swal from "sweetalert2";

import { navigationHooks } from '../../../../helpers/NavigationHook';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Header from '../../../common/header/Header';
import XsmSupportHeader from './header.js';
import ChangeEditForm from './form.js';
import ImplementationFormTab from './implementationPlan.js';
import AttachmentFormTab from '../../../common/WorkItemBoard/attachment';
//import ApprovalsFormTab from './approvals.js';
import ApprovalsFormTab from '../../../common/WorkItemBoard/approvals';
import ReplanFormTab from './re-plan.js';
import PIRFormTab from './PIR.js';
import CancelFormTab from './cancel.js';
import XsmServiceDetails from './xsm-service-quickView.js';
import XsmUserDetails from './xsmEditRequestedBy.js';
import XsmCiDetails from "./xsm-ci-editquickView.js";
import XSMNotify from './notify.js';
import XsmReSubmitReview from './reSubmitReview.js';
import XSMBreakCausalRelation from './causalRelationship.js';
import XsmBreakFixExternalSystemAttributes from './xsm-breakFix-externalSystemAttribute.js';
import XSMSLA from './sla';
import CmdbUpdate from './cmdbUpdate.js';
import TabPage from './changeEdit-TabPage';
import PopUpRelatedWork  from './changeRelatedWork';
import PopUpCMDBRelatedWork  from './cmdbchangeRelatedWork';
import PopUpTask  from './breakfixTask';
import ApprovalStatus from './changeApprovalStatus';
import PIRLog from './changePIRLog';
import ClosureCode from './ClosureCode';
import WIBSearchInner from "../../../common/WorkItemBoard/WIBSearch/WIBSearchInner";
import AiInsights from '../../../../components/common/aiInsights';
import AiInsightsBar from '../../../../components/common/aiInsightsBar';

import { loadTimelineData } from "../../../../actions/common/commonApisActions";


import { GLOBAL } from "_Globals";

import { loadChangeEditData,resetSomeFieldsEditChange}  from '../../../../actions/changeManagement/changeManagementAction';
import { loadBreakFixAuditLogDetails } from '../../../../actions/breakFix/breakFixAuditLogAction';
import { loadStatusBasedHamburgerOptions, resetLoadHamburgerOptionsReducer } from '../../../../actions/common/loadStatusBasedHamburgerOptions';
import { getTranslation} from '../../../../actions/spcmActions';
import {resetRelatedTaskCount} from '../../../../actions/task/TaskDataAction';
import { loadExternalAttributesDetails } from '../../../../actions/breakFix/breakFixEditDetailsAction';
import Breadcrumbs from '../../../common/header/breadcrumbs';

import { loadChangeTotalApprovalDetails } from "../../../../actions/changeManagement/changeTotalApprovalAction";
import { fetchWatcherDetails } from '../../../../actions/fulfillment/watcherActions';
import WatcherDetails from '../../../common/WorkItemBoard/watcher/WatcherDetails';
import { getCmdbApprovals } from "../../../../actions/cmdb/lovAction.js";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { constructRiskAssessmentPayload, getData_DataChangedValidate, checkArrayEqual_RiskAssessment } from '../../../common/WorkItemBoard/RiskAssessment/helper';
import TagDetails from '../../../common/tags/TagDetails.js';

import { loadrelatedWorkitems } from "../../../../actions/changeManagement/changeRelatedDataAction";
import { resetrelatedCount } from "../../../../actions/changeManagement/changeManagementAction";
import { loadImplPlanList } from "../../../../actions/common/attachmentAction";
import { checkRelatedTaskForItem, loadBreakFixTaskLogDetails } from "../../../../actions/task/TaskDataAction";
import AvailableIndividuals from '../../../common/WorkItemBoard/availableIndividuals/AvailableIndividuals.js';
import "_Css/form/_form.scss";
import { exportDoc } from './helper';

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

const cookies = new Cookies();
let homepagelocation = cookies.get('gph');
if (homepagelocation) homepagelocation = homepagelocation.replace('s:', '');
if (homepagelocation) homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
homepagelocation = homepagelocation.split("~");
const LEAD_TIME = homepagelocation[49];
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home")
	homepagelocation = "/" + homepagelocation;
let timezone = cookies.get('gph');
if (timezone) timezone = timezone.replace('s:', '');
if (timezone) timezone = timezone.substring(0, timezone.lastIndexOf('.'));
timezone = timezone.split("~");
timezone = timezone[3];

const checkEmptyValue = (val) => {
	if(!val || val == "" || val == "0" || val == "null"){
		return "";
	}
	return val;
}

function isObject(obj) {
	return (Object.prototype.toString.call(obj) === '[object Object]');
}

function compareObject(obj1, obj2){
	if(!isObject(obj1) || !isObject(obj2)){
		return false;
	}
	let len = null;
	if(Object.keys(obj1).length != Object.keys(obj2).length){
		return false;
	}else{
		len = Object.keys(obj1).length;
	}
	let match = 0;
	Object.keys(obj1).forEach(i => {
		if(obj1[i] == obj2[i]){
			match++;
		}
		// if( i == 'riskAssessment' ){
		// 	let isEqual = checkArrayEqual_RiskAssessment(obj1[i], obj2[i]);
		// 	if(isEqual == true){
		// 		match++;
		// 	}
		// }
		// else{
		// 	if(obj1[i] == obj2[i]){
		// 		match++;
		// 	}
		// }
	})
	if(match === len){
		return true;
	}
	return false;
}

function isIsoDate(str) {
	try{
		if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
		const d = new Date(str);
		return d instanceof Date && !isNaN(d) && d.toISOString()===str;
	}catch(e){
		return false;
	}
}


class EditChangeIndex extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
			subMenu: '4',
			showHideAlfy: false,
			showHideRightNav: false,
			actionView: 'processHierarchy',
			mobileLeftMenuFilter: false,
			user_id: '',
			role_id: '',
			groupID:'',
		    roleID:'',
			changeImplentationRole:'',
			date_format: '',
			full_name: '',
			conflictFormVal: false,
			cmbdShowVal: true,
			serviceErrorColor: '',
			ImpactedErrorColor: '',
			SLA: 0,
			showNotifyForm: true,
			impGroupErr: false,
			chgGroupErr: false,
			resetChangeEdit: false,
			setErrorExpectedStartDate: false,
			unchangedData: null,
			userDetailsID: '',
			userDetailsLabel: '',
			checkCABDateValidation: false,
			urgencyListKeyValue:{},
			impactListKeyValue:{},
			isPendingApproval: true,
			riskAssessmentDetails: {
				'residualRisk': '',
		  		'riskAssessment': null
			},
			unchangedData_riskAssessment: {
				'residualRisk': '',
		  		'riskAssessment': null
			},
			isRiskAssessmentMandatory: false,
			isRiskAssessmentChanged: false,
			isRiskAssessmentFilled: false,
			isRightEditPanel: true,
			panelResize: "",
			selectedAssignmentGroup:[],
			selectedAssignmentUser:[],
			selectedImpGroup:[],
			selectedImpUser:[],
			isVisible: false,
			aiaismChangeRateEnabled: false,
			isChangeRateCalled: false,
		};
        this.baseState = this.state;
		this.showHideAlfy = this.showHideAlfy.bind(this);
        this.showHideRightNav = this.showHideRightNav.bind(this);
		this.changeActionView = this.changeActionView.bind(this);
        this.showRightPaneAction = this.showRightPaneAction.bind(this);
		this.props.getTranslation(this.props.lang);
		this.showExternalSystemAttributes = this.showExternalSystemAttributes.bind(this);
		this.showTimeline = this.showTimeline.bind(this);
		this.showConflictForm = this.showConflictForm.bind(this);
		this.roleIdentification = this.roleIdentification.bind(this);
		this.cmbdShow = this.cmbdShow.bind(this);
		this.setErrorServiceColor = this.setErrorServiceColor.bind(this);
		this.setErrorImpactedColor = this.setErrorImpactedColor.bind(this);
		this.loadHome = this.loadHome.bind(this);
		this.showSLA = this.showSLA.bind(this);
		this.seTypeaheadValidation = this.seTypeaheadValidation.bind(this);
		this.setUnchangedData = this.setUnchangedData.bind(this);
		this.checkDataChanged = this.checkDataChanged.bind(this);
		this.checkDataChanged_riskAssessment = this.checkDataChanged_riskAssessment.bind(this);
		this.validateCABDate = this.validateCABDate.bind(this);
		this.loadWorkItemPage = this.loadWorkItemPage.bind(this);
		this.checkPendingApprovalsExist = this.checkPendingApprovalsExist.bind(this);
		this.updateRiskAssessmentDetails = this.updateRiskAssessmentDetails.bind(this);
		this.rightEditPanel=this.rightEditPanel.bind(this);
		this.RefreshScreen = this.RefreshScreen.bind(this);
		this.roleCalculatorForGuestUser = this.roleCalculatorForGuestUser.bind(this);
		this.setAssignmentGrpUsr = this.setAssignmentGrpUsr.bind(this);
		this.setAssignmentGrpUsrImp = this.setAssignmentGrpUsrImp.bind(this);
		this.exportDocument = this.exportDocument.bind(this);
		this.aiInsightsShowFun = this.aiInsightsShowFun.bind(this);
		this.resetRightSide = this.resetRightSide.bind(this);
		this.getAdminParameters = this.getAdminParameters.bind(this);
	}

	setAssignmentGrpUsr(selectd,type){
		switch(type){
		  case "user":
			this.setState({selectedAssignmentUser:selectd});
			break;
		  case  "group":
			this.setState({selectedAssignmentGroup:selectd});
			break;
		}
	}

	setAssignmentGrpUsrImp(selectd,type){
		switch(type){
		  case "user":
			this.setState({selectedImpUser:selectd});
			break;
		  case  "group":
			this.setState({selectedImpGroup:selectd});
			break;
		}
	}

    showHideAlfy(value) {
		this.setState({ showHideAlfy: value });
	}
    showHideRightNav(value) {
		this.setState({ showHideRightNav: !this.state.showHideRightNav });
	}
	rightEditPanel(value) {
		this.setState({ isRightEditPanel: value });
	}
    changeActionView(actionSelected, userID = '', label = '') {
		if(['consumer', 'individual'].includes(actionSelected) && !( userID && !isNaN(userID) && !!parseInt(userID, 10) ) ){
			return;
		}
		if (actionSelected && actionSelected == 'showExternalSystemAttributes' && this.props && this.props.params && this.props.params.changeId) {
			this.props.loadExternalAttributesDetails(this.props.params.changeId, 'change');
		}
		this.setState({actionView: ''}, () => {
			if(actionSelected==='individualChange'){
			  this.setState({changerID: userID});
		    }else if(actionSelected==='individual'){
			  this.setState({implementerID: userID});
			}else if(actionSelected==='ciId'){
			  this.setState({impactedCIID: userID});
			}else{
              this.setState({userDetailsID: userID});
			}
			this.setState({ actionView:actionSelected,  userDetailsLabel: label});
		});
		if (actionSelected === 'notify') {
			this.setState({showNotifyForm: true});
		}
	}
    showRightPaneAction(actionView) {
		switch (actionView) {
			case 'implementationPlan':
				return (
					<ImplementationFormTab translator={this.props.tr} changeId={this.props.params.changeId} user_id={this.state.user_id}
                                           role_id={this.state.role_id} date_format={this.state.date_format} roleIdentification={this.roleIdentification}
                                           editChangeInitialData={this.props.editChangeInitialData}
										   rightEditPanel={this.rightEditPanel} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}/>
				);
			case 'approvals':
				return (
					<ApprovalsFormTab translator={this.props.tr} changeId={this.props.params.changeId} itemDetails={this.props.editChangeInitialData}
                                      changeActionView={this.changeActionView} roleIdentification={this.roleIdentification} editChangeInitialData={this.props.editChangeInitialData} module="Change" rightEditPanel={this.rightEditPanel}/>
				);
			case 'replan':
				return (
					<ReplanFormTab user_id={this.state.user_id} role_id={this.state.role_id} date_format={this.state.date_format}
                    translator={this.props.tr} changeId={this.props.params.changeId} changeActionView={this.changeActionView} dateformat={this.state.date_format}
                    roleIdentification={this.roleIdentification} editChangeInitialData={this.props.editChangeInitialData} rightEditPanel={this.rightEditPanel}/>
				);
			case 'pir':
				return (
					<PIRFormTab translator={this.props.tr} changeId={this.props.params.changeId} itemDetails={this.props.editChangeInitialData}
                    changeActionView={this.changeActionView} roleIdentification={this.roleIdentification} editChangeInitialData={this.props.editChangeInitialData}
                    user_id={this.state.user_id} rightEditPanel={this.rightEditPanel}/>
				);
			case 'provideAttachments':
				return (
					<AttachmentFormTab translator={this.props.tr} itemDetails={this.props.editChangeInitialData} roleIdentification={this.roleIdentification}
                    editChangeInitialData={this.props.editChangeInitialData} user_id={this.state.user_id} rightEditPanel={this.rightEditPanel} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}/>
				);
			case 'cancel':
				return (
					<CancelFormTab translator={this.props.tr} editChangeInitialData={this.props.editChangeInitialData} changeActionView={this.changeActionView}
                    roleIdentification={this.roleIdentification} user_id={this.state.user_id} rightEditPanel={this.rightEditPanel}/>
				);
			case 'notify':
				return (
					<XSMNotify translator={this.props.tr} changeId={this.props.params.changeId} editChangeInitialData={this.props.editChangeInitialData}
                    full_name={this.state.full_name} roleIdentification={this.roleIdentification} showNotifyForm={this.state.showNotifyForm}
					setShowNotifyForm={(value) => {this.setState({ showNotifyForm: value })}} rightEditPanel={this.rightEditPanel} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}/>
				);
			case 'service':
				return (
					<XsmServiceDetails translator={this.props.tr} offeringId={this.props.editChangeInitialData.ciId} rightEditPanel={this.rightEditPanel}/>
				);
			case 'ciId':
				return (
					<XsmCiDetails ciId={this.state.impactedCIID} translator={this.props.tr} rightEditPanel={this.rightEditPanel}/>
				);
			case 'consumer':
				return (
					<XsmUserDetails translator={this.props.tr} userId={this.state.userDetailsID} userDetailsLabel={this.state.userDetailsLabel} rightEditPanel={this.rightEditPanel}/>
				);
			case 'individualChange':
				return (
					<XsmUserDetails translator={this.props.tr} userId={this.state.changerID} userDetailsLabel={this.state.userDetailsLabel} rightEditPanel={this.rightEditPanel}/>
				);
			case 'individual':
				return (
					<XsmUserDetails translator={this.props.tr} userId={this.state.implementerID} userDetailsLabel={this.state.userDetailsLabel} rightEditPanel={this.rightEditPanel}/>
				);
			case 'causalRelation':
				return (
					<XSMBreakCausalRelation requestId={this.props.editChangeInitialData.requestId} translator={this.props.tr} rightEditPanel={this.rightEditPanel} isChangedRolesEnabled={this.state.isChangedRolesEnabled} isProblemRolesEnabled={this.state.isProblemRolesEnabled} isIncidentRolesEnabled={this.state.isIncidentRolesEnabled} isFulfillmentRolesEnabled={this.state.isFulfillmentRolesEnabled}/>
				);
			case 'resubmitReview':
				return (
					<XsmReSubmitReview translator={this.props.tr} changeId={this.props.params.changeId} full_name={this.state.full_name}
                    roleIdentification={this.roleIdentification} editChangeInitialData={this.props.editChangeInitialData} changeActionView={this.changeActionView} rightEditPanel={this.rightEditPanel}/>
				);
			case 'showExternalSystemAttributes':
				return (
					<XsmBreakFixExternalSystemAttributes translator={this.props.tr} rightEditPanel={this.rightEditPanel}/>
				);
			case 'cmdbUpdate':
				return (
					<CmdbUpdate editChangeInitialData={this.props.editChangeInitialData} date_format={this.state.date_format} translator={this.props.tr}
                    cmbdShow={this.cmbdShow} changeActionView={this.changeActionView} rightEditPanel={this.rightEditPanel}/>
				);
			case 'sla':
				return (
					<XSMSLA translator={this.props.tr} editChangeInitialData={this.props.editChangeInitialData} rightEditPanel={this.rightEditPanel} role_id={this.state.role_id}/>
				);
			case 'relatedWorkItems':
				return (
					<PopUpRelatedWork translator={this.props.tr} itemId={this.props.params.changeId} fieldStatus={this.props.fieldStatus} editChangeInitialData={this.props.editChangeInitialData} rightEditPanel={this.rightEditPanel} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole} isChangedRolesEnabled={this.state.isChangedRolesEnabled} isProblemRolesEnabled={this.state.isProblemRolesEnabled} isIncidentRolesEnabled={this.state.isIncidentRolesEnabled} isFulfillmentRolesEnabled={this.state.isFulfillmentRolesEnabled}/>
				);
			case 'relatedCI':
				return (
					<PopUpCMDBRelatedWork translator={this.props.tr} itemId={this.props.params.changeId} fieldStatus={this.props.fieldStatus} editChangeInitialData={this.props.editChangeInitialData}
                    changeNumber={this.props.editChangeInitialData.changeNumber} createdOn={this.props.editChangeInitialData.createdOn} rightEditPanel={this.rightEditPanel} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole} isCMDBRoleEnabled={this.state.isCMDBRoleEnabled}/>
				);
			case 'task':
				return (
					<PopUpTask translator={this.props.tr} fieldStatus={this.props.fieldStatus} clicktaskEdit={this.clicktaskEdit} itemId={this.props.params.changeId}
                    editChangeInitialData={this.props.editChangeInitialData} module="ChangeManagement" roleIdentification={this.roleIdentification} rightEditPanel={this.rightEditPanel} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole} isTaskRoleEnabled={this.state.isTaskRoleEnabled}/>
				);
			case 'approvalStatus':
				return (
					<ApprovalStatus editChangeInitialData={this.props.editChangeInitialData} translator={this.props.tr} itemId={this.props.params.changeId} rightEditPanel={this.rightEditPanel}/>
				);
			case 'PIRLog':
				return (
					<PIRLog editChangeInitialData={this.props.editChangeInitialData} translator={this.props.tr} changeId={this.props.params.changeId} rightEditPanel={this.rightEditPanel}/>
				);
			case 'Sub Status':
				return (<ClosureCode editChangeInitialData={this.props.editChangeInitialData} translator={this.props.tr} changeActionView={this.changeActionView} rightEditPanel={this.rightEditPanel}/>);
			case 'watcher':
				return (<WatcherDetails itemId={this.props.params.changeId} module='Change' itemDetails={this.props.editChangeInitialData} status={this.props.editChangeInitialData.status} rightEditPanel={this.rightEditPanel} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}/>);
			case 'tags':
				const isCancelledStatus = this.props.editChangeInitialData.statusId == '75';
				const isClosedStatus = this.props.editChangeInitialData.statusId == '90';
				const isTerminalStatus = isCancelledStatus || isClosedStatus;
				return (<TagDetails companyId={this.props.editChangeInitialData.consumerCompany} itemId={this.props.params.changeId} itemDetails={this.props.editChangeInitialData} module="Change" businessFunction="" mainModule="Change" rightEditPanel={this.rightEditPanel} userId={this.state.user_id} userName={this.state.full_name} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole} isD2CRolesEnabled={this.state.isD2CRolesEnabled} isTerminalStatus={isTerminalStatus}/>);
			case 'showAvailableIndividuals':
				return (<AvailableIndividuals setAssignmentGrpUsr={this.setAssignmentGrpUsr}  selectedAssignmentGroup={this.state.selectedAssignmentGroup} selectedAssignmentUser={this.state.selectedAssignmentUser} module="Change" disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}/>)
			case 'showAvailableIndividualsImp':
				return (<AvailableIndividuals setAssignmentGrpUsrImp={this.setAssignmentGrpUsrImp}  selectedAssignmentGroup={this.state.selectedImpGroup} selectedAssignmentUser={this.state.selectedImpUser} module="Implementation" disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}/>)
		    case 'showChangeSucessRate':
				return (<AiInsights aiInsightsShowFun={this.aiInsightsShowFun} isVisible={this.state.isVisible} aismChangeRate={this.state.aiaismChangeRateEnabled} resetRightSide={this.resetRightSide} />);
			case 'submitReview':
		    case 'startImplementation':
			case 'endImplementation':
			case 'processHierarchy':
			case 'underPIR':
			default:
				return (
					<TabPage translator={this.props.tr} changeDetails={this.props.editChangeInitialData} changeId={this.props.params.changeId}
				    fieldStatus={this.props.fieldStatus} date_format={this.state.date_format} urgencyListKeyValue={this.state.urgencyListKeyValue} impactListKeyValue={this.state.impactListKeyValue} rightEditPanel={this.rightEditPanel} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}/>
				);
		}
	}
    showExternalSystemAttributes() {
	}
    showTimeline(flagValue) {
		this.setState({ showTimelineFlag: flagValue });
	}
    showConflictForm(val) {
		this.setState({ conflictFormVal: val });
		const root = document.documentElement;
        root?.style.setProperty("--modalBackDrop", 0.5);
	}
    roleIdentification(Rolevalue) {
		let user_roles = this.state.role_id.split(',');
		let flag = false;
		for (let i = 0; i < user_roles.length; i++) {
			if (user_roles[i] == Rolevalue) {
				flag = true;
				break;
			}
		}
		if (flag)
			return true;
		else
			return false;
	}
    cmbdShow() {
		this.setState({ cmbdShowVal: false });
	}
    setErrorServiceColor(value) {
		this.setState({serviceErrorColor: value});
	}
    setErrorImpactedColor(value) {
		this.setState({ImpactedErrorColor: value});
	}
    loadHome() {
        navigationHooks.navigate(homepagelocation);
	}
    showSLA() {
		GLOBAL.proposeCritical = 0;
		GLOBAL.processhierarchy = 0;
		GLOBAL.acceptCritical = 0;
		GLOBAL.rejectCritical = 0
		this.setState({SLA: 1});
	}
    seTypeaheadValidation(value, fieldName) {
		if (this.props.editChangeInitialData.statusId!=='30' && fieldName === 'implementationGroup') {
			this.setState({ impGroupErr: value });
		} else if (this.props.editChangeInitialData.statusId!=='30' && fieldName === 'changeManagementGroup') {
			this.setState({ chgGroupErr: value });
		} else if (fieldName === 'Reset') {
			this.setState({ resetChangeEdit: value });
		}
	}
	//  checkEmptyValue(val) {
	// 	if (!val || val === "0" || val === "null") {
	// 		return "";
	// 	}
	// 	return val;
	// }
    setUnchangedData(obj){
		try{
			let tempObj = {};
			tempObj['consumerCompany'] = checkEmptyValue(obj['consumerCompany']);
			tempObj['changeType'] = checkEmptyValue(obj['type']);
			tempObj['summary'] = checkEmptyValue(obj['summary']);
			tempObj['requesterId'] = checkEmptyValue(obj['requesterId']);
			tempObj['ciId'] = checkEmptyValue(obj['ciId']);
			tempObj['ciClassId'] = checkEmptyValue(obj['ciClassId']);
			tempObj['reasonForChange'] = checkEmptyValue(obj['reasonForChange']);
			tempObj['cmdbUpdateNeeded'] = checkEmptyValue(obj['cmdbUpdateNeeded']);
			tempObj['urgency'] = checkEmptyValue(obj['urgency']);
			tempObj['description'] = checkEmptyValue(obj['description']);
			tempObj['riskOccurrenceProbability'] = checkEmptyValue(obj['riskOccurrenceProbability']);
			tempObj['riskImpact'] = checkEmptyValue(obj['riskImpact']);
			tempObj['controlEffectiveness'] = checkEmptyValue(obj['controlEffectiveness']);
			tempObj['expStartDate'] = checkEmptyValue(obj?.expectedStartDate || "");
			tempObj['expEndDate'] = checkEmptyValue(obj?.expectedEndDate || "");
			tempObj['actStartDate'] = checkEmptyValue(obj?.actualStartDate || "");
			tempObj['actEndDate'] = checkEmptyValue(obj?.actualEndDate || "");
			tempObj['dStartDate'] = checkEmptyValue(obj?.startDate || "");
			tempObj['dEndDate'] = checkEmptyValue(obj?.endDate || "");
			tempObj['impGroupname'] = checkEmptyValue(obj['impGroupname']);
			tempObj['impUsername'] = checkEmptyValue(obj['impUsername']);
			tempObj['changeGroupname'] = checkEmptyValue(obj['changeGroupname']);
			tempObj['changeUsername'] = checkEmptyValue(obj['changeUsername']);
			tempObj['cabDate'] = checkEmptyValue(obj['cabDate']);
			// tempObj['residualRisk'] = checkEmptyValue(obj['residualRisk']);
			// tempObj['riskAssessment'] = obj['riskAssessment'] ? getData_DataChangedValidate(obj['riskAssessment']) : null;

			let tempObj_riskAssessment = {
				'residualRisk': checkEmptyValue(obj['residualRisk']),
				'riskAssessment': obj['riskAssessment'] ? getData_DataChangedValidate(obj['riskAssessment']) : null
			}
			this.setState({unchangedData: tempObj, unchangedData_riskAssessment: tempObj_riskAssessment});
		}catch(e){
			console.error(e);
		}
	}
    checkDataChanged(obj){
		try{
			const { unchangedData } = this.state;
			let tempObj = {};
			tempObj['consumerCompany'] = checkEmptyValue(obj['consumerCompany']);
			tempObj['changeType'] = checkEmptyValue(obj['changeType']);
			tempObj['summary'] = checkEmptyValue(obj['summary']);
			tempObj['requesterId'] = checkEmptyValue(obj['requesterId']);
			tempObj['ciId'] = checkEmptyValue(obj['ciId']);
			tempObj['ciClassId'] = checkEmptyValue(obj['ciClassId']);
			tempObj['reasonForChange'] = checkEmptyValue(obj['reasonForChange']);
			tempObj['cmdbUpdateNeeded'] = checkEmptyValue(obj['cmdbUpdateNeeded']);
			tempObj['urgency'] = checkEmptyValue(obj['urgency']);
			tempObj['description'] = checkEmptyValue(obj['description']);
			tempObj['riskOccurrenceProbability'] = checkEmptyValue(obj['riskOccurrenceProbability']);
			tempObj['riskImpact'] = checkEmptyValue(obj['riskImpact']);
			tempObj['controlEffectiveness'] = checkEmptyValue(obj['controlEffectiveness']);
			tempObj['expStartDate'] = checkEmptyValue(obj?.expStartDate || "");
			tempObj['expEndDate'] = checkEmptyValue(obj?.expEndDate || "");
			tempObj['actStartDate'] = checkEmptyValue(obj?.actStartDate || "");
			tempObj['actEndDate'] = checkEmptyValue(obj?.actEndDate || "");
			tempObj['dStartDate'] = checkEmptyValue(obj?.dStartDate || "");
			tempObj['dEndDate'] = checkEmptyValue(obj?.dEndDate || "");
			tempObj['impGroupname'] = checkEmptyValue(obj['impGroupname']);
			tempObj['impUsername'] = checkEmptyValue(obj['impUsername']);
			tempObj['changeGroupname'] = checkEmptyValue(obj['changeGroupname']);
			tempObj['changeUsername'] = checkEmptyValue(obj['changeUsername']);
			tempObj['cabDate'] = checkEmptyValue(obj['cabDate']);
			// tempObj['residualRisk'] = checkEmptyValue(obj['residualRisk']);
			// tempObj['riskAssessment'] = obj['riskAssessment'] ? getData_DataChangedValidate(obj['riskAssessment']) : null;
			const isEqual = compareObject(unchangedData, tempObj);
			return !isEqual;
		}catch(e){
			console.error(e);
		}
	}

	checkDataChanged_riskAssessment(obj){
		try{
			const { unchangedData_riskAssessment } = this.state;
			
			let tempObj = {};
			tempObj['residualRisk'] = checkEmptyValue(obj['residualRisk']);
			tempObj['riskAssessment'] = obj['riskAssessment'] ? getData_DataChangedValidate(obj['riskAssessment']) : null;

			if(unchangedData_riskAssessment['residualRisk'] !== tempObj['residualRisk']){
				return true;
			}
			let isEqual = checkArrayEqual_RiskAssessment(unchangedData_riskAssessment['riskAssessment'], tempObj['riskAssessment']);
			// console.log('isEqual - ', isEqual);
			return !isEqual;
		}catch(e){
			console.error(e);
		}
	}

    validateCABDate(obj){
		try{
			const { statusId, type, cabDate, expectedStartDate } = obj;
			const { checkCABDateValidation } = this.props;
			const { date_format } = this.state;
			let dateFormat = date_format.substring(0,10)+" HH:mm:ss";
			let modifiedCABdate = '';
			if(isIsoDate(cabDate)){
				modifiedCABdate = moment(moment( new Date(cabDate) ).format(dateFormat), dateFormat).format(dateFormat);
			}else{
				modifiedCABdate = cabDate;
			}
			let cab_date_value = moment.tz(modifiedCABdate,dateFormat,timezone).format(dateFormat);
			let current_date_value = moment().tz(timezone).format(dateFormat);
			let expected_start_date_value = moment.tz(expectedStartDate,dateFormat,timezone).format(dateFormat);
			if(  statusId && statusId.toString() == '35' && type ){
				if(checkCABDateValidation == true && !cabDate){
					return 'Please select valid CAB date.';
				}
				if( ['25', '30'].includes((type).toString()) ){
					if(
						cabDate &&
						( moment.tz(cab_date_value,dateFormat,timezone).isBefore(moment.tz(current_date_value,dateFormat,timezone)) )
					){
						return "CAB Date should be atleast today's date.";
					}
					if(
						cabDate && (type).toString() == '25' &&
						( moment.tz(cab_date_value,dateFormat,timezone).isAfter(moment.tz(expected_start_date_value,dateFormat,timezone)) )
					) {
						return 'CAB date should fall before Scheduled Start date.';
					}
				}
				if(cabDate && (type).toString() == '35'){
					if(moment.tz(cab_date_value,dateFormat,timezone).isAfter(moment.tz(expected_start_date_value,dateFormat,timezone))) {
						return 'CAB date should fall before Scheduled Start date.';
					}
				}
			}
			return '';
		}catch(e){
			return '';
		}
	}
    loadWorkItemPage(){
		navigationHooks.navigate('/quickViewBreakFix');
	}
    mobileLeftMenuFilter() {
		this.setState({ mobileLeftMenuFilter: !this.state.mobileLeftMenuFilter });
	};
    componentWillMount() {
		GLOBAL.changepageLoad = true, GLOBAL.changeEdit = true;
		this.props.resetSomeFieldsEditChange();
		this.props.resetLoadHamburgerOptionsReducer();
		this.props.resetRelatedTaskCount();
		this.props.fetchWatcherDetails('Change',this.props.params.changeId);
		let str = {};
		str.user_id = "";
		str = JSON.stringify(str);
		api.get("/api/userInfo", { headers: { 'query': str } }).then((response) => {
			let changeUserid='';
			if(response.data.role_id.split(",").includes("20")&&(!response.data.role_id.split(",").includes("52")))
			{
			  changeUserid="changeUserid";
			}
			else if(response.data.role_id.split(",").includes("52")&&(!response.data.role_id.split(",").includes("20")))
			{
			  changeUserid="impUserid";
			}
			else if(response.data.role_id.split(",").includes("20")&&response.data.role_id.split(",").includes("52")||response.data.role_id.split(",").includes("20")&&response.data.role_id.split(",").includes("51")||response.data.role_id.split(",").includes("52")&&response.data.role_id.split(",").includes("19"))
			{
			  changeUserid="impUserid,changeUserid";
			}
			else if(response.data.role_id.split(",").includes("19")&&(!response.data.role_id.split(",").includes("51")))
			{
			  changeUserid="changeUserid";
			}
			else if(response.data.role_id.split(",").includes("51")&&(!response.data.role_id.split(",").includes("19")))
			{
			  changeUserid="impUserid";
			}
			else if(response.data.role_id.split(",").includes("19")&&response.data.role_id.split(",").includes("51"))
			{
			  changeUserid="impUserid,changeUserid";
			}
			this.setState({ user_id: response.data.user_id, role_id: response.data.role_id, groupID: response.data.groupId, roleID: response.data.role_id,
            changeImplentationRole: changeUserid,date_format: (response.data.dateformat).toUpperCase(), full_name: response.data.full_name });
			this.roleCalculatorForGuestUser(response.data.role_id);
			this.props.loadChangeEditData(this.props.params.changeId, this.state.date_format).then(res => {
				// console.log('res - ', res);
				// if(res && Object.keys(res).length > 0){
				// 	this.checkPendingApprovalsExist(res);
				// }
			}).catch(err => {
				// console.log('err - ', err);
			});
		});

		if (GLOBAL.slaHeadEnable) {
			this.changeActionView('sla');
			this.setState({
				actionView: 'sla'
			})
			this.rightEditPanel(true);
		}
	}
    componentWillUnmount() {
		GLOBAL.changeEdit = false;
	}
    componentWillReceiveProps(nextProps) {
		let ref = this;
		if(!this.state.isChangeRateCalled && nextProps.editChangeInitialData.consumerCompany){
			this.setState({isChangeRateCalled: true});
			if(nextProps.editChangeInitialData.statusId == '30' || nextProps.editChangeInitialData.statusId == '50' || nextProps.editChangeInitialData.statusId == '70' || nextProps.editChangeInitialData.statusId == '75'){
				return;
			}
			this.getAdminParameters(nextProps.editChangeInitialData.consumerCompany,'Change','33');
		}
		if ((this.props.params.changeId != nextProps.editChangeInitialData.changeId) && (nextProps.editChangeInitialData.changeId != undefined)) {
			// if (this.props.params.changeId != this.props.params.changeId) {
			// 	this.setState(this.baseState);
			// 	this.props.resetLoadHamburgerOptionsReducer();
			// 	let str = {};
			// 	str.user_id = "";
			// 	str = JSON.stringify(str);
			// 	api.get("/api/userInfo", { headers: { 'query': str } }).then((response) => {
			// 		let changeUserid='';
			// if(response.data.role_id.split(",").includes("20")&&(!response.data.role_id.split(",").includes("52")))
			// {
			//   changeUserid="changeUserid";
			// }
			// else if(response.data.role_id.split(",").includes("52")&&(!response.data.role_id.split(",").includes("20")))
			// {
			//   changeUserid="impUserid";
			// }
			// else if(response.data.role_id.split(",").includes("20")&&response.data.role_id.split(",").includes("52")||response.data.role_id.split(",").includes("20")&&response.data.role_id.split(",").includes("51")||response.data.role_id.split(",").includes("52")&&response.data.role_id.split(",").includes("19"))
			// {
			//   changeUserid="impUserid,changeUserid";
			// }
			// else if(response.data.role_id.split(",").includes("19")&&(!response.data.role_id.split(",").includes("51")))
			// {
			//   changeUserid="changeUserid";
			// }
			// else if(response.data.role_id.split(",").includes("51")&&(!response.data.role_id.split(",").includes("19")))
			// {
			//   changeUserid="impUserid";
			// }
			// else if(response.data.role_id.split(",").includes("19")&&response.data.role_id.split(",").includes("51"))
			// {
			//   changeUserid="impUserid,changeUserid";
			// }
			// 		ref.setState({ user_id: response.data.user_id, role_id: response.data.role_id, groupID:response.data.groupId, roleID: response.data.role_id,
            //         changeImplentationRole: changeUserid,date_format: (response.data.dateformat).toUpperCase(), full_name: response.data.full_name });
			// 		ref.props.loadChangeEditData(ref.props.params.changeId, ref.state.date_format);
			// 	});
			// }
		}
		if ((nextProps.loadHamburgerOptions !== this.props.loadHamburgerOptions && nextProps.loadHamburgerOptions === true) && Object.keys(nextProps.editChangeInitialData).length != 0) {
			this.props.loadStatusBasedHamburgerOptions('ChangeManagement', nextProps.editChangeInitialData.status, nextProps.editChangeInitialData.changeId);
		}
		this.setUnchangedData(nextProps.editChangeInitialData);
	}
	
	checkPendingApprovalsExist(obj){
		try{
			// console.log('obj - ', obj);
			const { serviceId = '', changeId = '' } = obj;
			let { ciId = '', ciClassId = '' } = obj;
			ciId = (!ciId || ciId == 'null') ? undefined : ciId;
			ciClassId = (!ciClassId || ciClassId == 'null') ? undefined : ciClassId;

			// axios
			// .get(
			// 	"/api/approval/ruleCount/offeringId/" +
			// 	serviceId +
			// 	"/moduleId/" +
			// 	"14" +
			// 	"/ciId/" +
			// 	ciId +
			// 	"/ciclass/" +
			// 	ciClassId +
			// 	"/requestId/" +
			// 	changeId
			// )
			// .then((resp) => {
			// 	console.log('resp - ', resp);
			// 	if ((resp.status = 200)) {
			// 		if (resp.data.count > 0) {
			// 			let str={};
			// 			str.itemId=changeId;
			// 			str.moduleId=parseInt('14', 10);
			// 			str.statusId='N';
			// 			str = JSON.stringify(str);
			// 			api.get(GLOBAL.changeTotalApprovalUrl,{headers:{'query':str}}).then(res => {
			// 				console.log('res - ', res);
			// 				if(res && res.data && res.data.length > 0){
			// 					const approvalArr = res.data;
			// 					// let approvalArr = [{status: 'Approved'}, {status: 'Approved'}, {status: 'Approved'}];
			// 					const count = (approvalArr).filter(item => item.status != 'Approved');
			// 					console.log('**********', {approvalArr, count});
			// 					this.setState({isPendingApproval: (count.length ? true : false)});
			// 				}
			// 				else{
			// 					this.setState({isPendingApproval: false});
			// 				}
			// 			}).catch(err => {
			// 				// console.log('err - ', err);
			// 				this.setState({isPendingApproval: true});
			// 			});
			// 			this.setState({ displayMessage: "" });
			// 		} else {
			// 			this.setState({isPendingApproval: false});
			// 		}
			// 	}
			// 	else{
			// 		this.setState({isPendingApproval: true});
			// 	}
			// }).catch(error => {
			// 	// console.log('error - ', error);
			// 	this.setState({isPendingApproval: true});
			// });


			this.props.getCmdbApprovals(ciId, '', '', 1, 10)
			.then((resp) => {
				console.log('resp - ', resp);
				if(resp && resp.approverList){
					const approverList = structuredClone(resp.approverList);
					const filtered = approverList.filter(item => item.rfcChangeType == '35');
					if(approverList.length && filtered.length){
						let str={};
						str.itemId=changeId;
						str.moduleId=parseInt('14', 10);
						str.statusId='N';
						str = JSON.stringify(str);
						api.get(GLOBAL.changeTotalApprovalUrl,{headers:{'query':str}}).then(res => {
							console.log('res - ', res);
							if(res && res.data && res.data.length > 0){
								const approvalArr = res.data;
								// let approvalArr = [{status: 'Approved'}, {status: 'Approved'}, {status: 'Approved'}];
								const count = (approvalArr).filter(item => item.status != 'Approved');
								console.log('**********', {approvalArr, count});
								this.setState({isPendingApproval: (count.length ? true : false)});
							}
							else{
								throw new Error('Error');
							}
						}).catch(err => {
							// console.log('err - ', err);
							this.setState({isPendingApproval: true});
						});
					}
					else{
						this.setState({isPendingApproval: false});
					}
				}
				else{
					throw new Error('Error');
				}

			}).catch(error => {
				// console.log('error - ', error);
				this.setState({isPendingApproval: true});
			});

			// axios
			// .get(
			// 	"/api/approval/ruleCount/offeringId/" +
			// 	serviceId +
			// 	"/moduleId/" +
			// 	"14" +
			// 	"/ciId/" +
			// 	ciId +
			// 	"/ciclass/" +
			// 	ciClassId +
			// 	"/requestId/" +
			// 	changeId
			// )
			// .then((resp) => {
			// 	// console.log('resp - ', resp);
			// 	if ((resp.status = 200)) {
			// 		if (resp.data.count != 0) {
			// 			let str={};
			// 			str.itemId=changeId;
			// 			str.moduleId=parseInt('14', 10);
			// 			str.statusId='N';
			// 			str = JSON.stringify(str);
			// 			api.get(GLOBAL.changeTotalApprovalUrl,{headers:{'query':str}}).then(res => {
			// 				// console.log('res - ', res);
			// 				if(res && res.data && res.data.length > 0){
			// 					const approvalArr = res.data;
			// 					// let approvalArr = [{status: 'Approved'}, {status: 'Approved'}, {status: 'Approved'}];
			// 					const count = (approvalArr).filter(item => item.status != 'Approved');
			// 					this.setState({isPendingApproval: (count.length ? true : false)});
			// 				}
			// 				else{
			// 					this.setState({isPendingApproval: false});
			// 				}
			// 			}).catch(err => {
			// 				// console.log('err - ', err);
			// 				this.setState({isPendingApproval: true});
			// 			});
			// 			this.setState({ displayMessage: "" });
			// 		} else {
			// 			this.setState({isPendingApproval: false});
			// 		}
			// 	}
			// 	else{
			// 		this.setState({isPendingApproval: true});
			// 	}
			// }).catch(error => {
			// 	// console.log('error - ', error);
			// 	this.setState({isPendingApproval: true});
			// });
			
		}catch(e){
			// console.error('Error : ', e);
			this.setState({isPendingApproval: true});
		}
	}

	updateRiskAssessmentDetails({actionType = '', methodology = '', data = '', residualRisk = ''}){
		// console.log('Edit Change Index - ', {actionType, methodology, data, residualRisk});
		try{
			switch(actionType){
				case 'updatePayload':
					let riskArr = constructRiskAssessmentPayload(methodology, data);
					const obj = {
						'residualRisk': residualRisk,
						'riskAssessment': riskArr
					}
					this.setState({riskAssessmentDetails: obj});
					break;
				case 'isMandatory':
					this.setState({isRiskAssessmentMandatory: data});
					break;
				case 'isChanged':
					this.setState({isRiskAssessmentChanged: data});
					break;
				case 'isDataFilled':
					this.setState({isRiskAssessmentFilled: data});
					break;
			}
		  }catch(e){}
	}

	RefreshScreen(obj){
		console.log("obj79",obj);
		Swal.fire({
			title:this.props.tr["Are you sure your want to refresh ?"],
			showDenyButton:true,
			confirmButtonText:this.props.tr['Yes'],
			denyButtonText:this.props.tr['No'],
		  }).then((result) => {
			if (result.isConfirmed) {
				try{
					// this.props.resetLoadHamburgerOptionsReducer();
					// this.props.loadChangeEditData(obj.changeId, this.state.date_format);
					// if ( obj.type === 30 || obj.type === "30" ) {
					// 	this.props.loadrelatedWorkitems(obj.changeId, "Breakfix");
					// } 
					// else {
					// 	let recordCount = 0;
					// 	this.props.resetrelatedCount(recordCount);
					// }
					// this.props.loadImplPlanList( "ChangeManagement", obj.changeId );
					// this.props.checkRelatedTaskForItem( obj.changeId, "ChangeManagement" );
					// let moduleName = "ChangeManagement", sort = "asc";
					// this.props.loadBreakFixTaskLogDetails( obj.changeId, moduleName, sort, obj.consumerCompany );
					// this.props.loadStatusBasedHamburgerOptions('ChangeManagement',currentStatus, obj.changeId);
					
					this.changeActionView("processHierarchy");
					this.props.loadChangeEditData(obj.changeId,this.state.date_format);
					// this.props.loadStatusBasedHamburgerOptions("ChangeManagement",obj.status,obj.changeId);
				} catch(e){
					console.error(e);
				}
			} else if (result.isDenied) {}
		  })
	}
	roleCalculatorForGuestUser(rolesList){
		let roles = rolesList.split(",");
		let isChangedRolesEnabled = roles.includes('19') || roles.includes('20') || roles.includes('51') || roles.includes('52');
		let isProblemRolesEnabled = roles.includes('16') || roles.includes('17') || roles.includes('53');
		let isIncidentRolesEnabled = roles.includes('12') || roles.includes('13') || roles.includes('14');
		let isFulfillmentRolesEnabled = roles.includes('43') || roles.includes('44');
		let isServiceDeskRolesEnabled = roles.includes('48');
		let isD2CRolesEnabled = isChangedRolesEnabled || isProblemRolesEnabled || isIncidentRolesEnabled || isFulfillmentRolesEnabled;
		let isGuestUserRoleEnabled = !(isChangedRolesEnabled || isServiceDeskRolesEnabled) && roles.includes('65');
		this.setState({disableFieldsForGuestRole: isGuestUserRoleEnabled});
		isChangedRolesEnabled=isChangedRolesEnabled|| isServiceDeskRolesEnabled|| roles.includes('65');
		isProblemRolesEnabled=isProblemRolesEnabled|| isServiceDeskRolesEnabled|| roles.includes('64');
		isIncidentRolesEnabled=isIncidentRolesEnabled || isServiceDeskRolesEnabled|| roles.includes('63');
		isFulfillmentRolesEnabled=isFulfillmentRolesEnabled|| isServiceDeskRolesEnabled|| roles.includes('62');
		let isTaskRoleEnabled = isD2CRolesEnabled || isServiceDeskRolesEnabled || roles.includes('61');
		let isCMDBRoleEnabled = roles.includes('37') || roles.includes('38') || roles.includes('39');
		this.setState({isChangedRolesEnabled, isProblemRolesEnabled, isIncidentRolesEnabled, isFulfillmentRolesEnabled, isTaskRoleEnabled, isCMDBRoleEnabled, isD2CRolesEnabled});
	}

	exportDocument(type = ''){
		try{
			if(!type){
				throw new Error('Error in exporting document');
			}
			let typeName = '';
			let reasonForChangeName = '';
			let urgencyName = '';
			if(this.props?.typeArr && Array.isArray(this.props.typeArr) && this.props.typeArr.length > 0){
				let find = (this.props.typeArr).find(item => item['field1Value'] == this.props?.editChangeInitialData?.type);
				if(find){
					typeName = find['field1Key'];
				}
			}
			if(this.props?.reasonForChangeArr && Array.isArray(this.props.reasonForChangeArr) && this.props.reasonForChangeArr.length > 0){
				let find = (this.props.reasonForChangeArr).find(item => item['field1Value'] == this.props?.editChangeInitialData?.reasonForChange);
				if(find){
					reasonForChangeName = find['field1Key'];
				}
			}
			if(this.props?.urgencyArr && Array.isArray(this.props.urgencyArr) && this.props.urgencyArr.length > 0){
				let find = (this.props.urgencyArr).find(item => item['field1Value'] == this.props?.editChangeInitialData?.urgency);
				if(find){
					urgencyName = find['field1Key'];
				}
			}
			let date_format = this.state.date_format;
			let obj = {...this.props.editChangeInitialData, ...{typeName, reasonForChangeName, urgencyName, date_format}};
			exportDoc(type, obj);
		}catch(e){
			console.error(e);
		}
	}
	aiInsightsShowFun(){
		this.setState({actionView: 'showChangeSucessRate'});
	}
	resetRightSide(){
		this.changeActionView('processHierarchy');
	}
	getAdminParameters(companyId, module, propertyId){
		const queryObj = {companyId,module,propertyId};
        const queryString = JSON.stringify(queryObj);
		axios.get('/api/getAdminPreferences', { headers: { query: queryString } }).then(retrivedFlags => {
			const retrivedFlag =  JSON.parse(retrivedFlags.data[0].value)?.aism;
			 this.setState({isVisible: retrivedFlag, aiaismChangeRateEnabled: retrivedFlag});
		});
	}

    render() {

		// console.log('************', {state: this.state, props: this.props});
		const { isPendingApproval } = this.state;

        if (this.props.isFetchingDetails) {
            return (<div className='rctLoader'>
            <LoadingIcons.Bars height="5em" stroke="transparent" fill="#06bcee" />
            </div>);
        } else {
            GLOBAL.ciId = this.props.editChangeInitialData.ciId;
            GLOBAL.ciName = this.props.editChangeInitialData.ciName;
        }
        return (
            <div className='container-fluid'>
                {this.state.role_id!==''?<WIBSearchInner tr={this.props.tr} roleIdentification={this.roleIdentification} group_id={this.state.groupID}
                                                         changeImplentationRole={this.state.changeImplentationRole}/>:null}
                    <div className="margin-t-10 margin-b-15">
					<Breadcrumbs activePageName={this.props.tr['Edit']} parentPageurl="/quickViewBreakFix" parentPageName={this.props.tr["Work Item Board"]} />
					<AiInsightsBar aiInsightsShowFun={this.aiInsightsShowFun} isVisible={this.state.isVisible} aismChangeRate={this.state.aiaismChangeRateEnabled} />
					</div>
                    <div className="" bsClass="container-fluid minHeightWIB">
                    <XsmSupportHeader showSLA={this.showSLA} cmbdShowVal={this.state.cmbdShowVal} changeId={this.props.params.changeId}
						showConflictForm={this.showConflictForm} user_id={this.state.user_id} role_id={this.state.role_id} date_format={this.state.date_format}
						translator={this.props.tr} changeActionView={this.changeActionView} showExternalSystemAttributes={this.showExternalSystemAttributes}
						showTimeline={this.showTimeline} roleIdentification={this.roleIdentification} editChangeInitialData={this.props.editChangeInitialData}
						fieldStatus={this.props.fieldStatus} seTypeaheadValidation={this.seTypeaheadValidation} checkDataChanged={this.checkDataChanged}
						checkDataChanged_riskAssessment={this.checkDataChanged_riskAssessment}
						validateCABDate={this.validateCABDate}
						isPendingApproval={isPendingApproval}
						riskAssessmentDetails = {this.state.riskAssessmentDetails}
						isRiskAssessmentMandatory = {this.state.isRiskAssessmentMandatory}
						isRiskAssessmentFilled = {this.state.isRiskAssessmentFilled}
						rightEditPanel={this.rightEditPanel}
						RefreshScreen={this.RefreshScreen}
						disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}
						exportDocument={this.exportDocument}
						actionView={this.state.actionView}
						/>

					<PanelGroup direction="horizontal">
						<Panel id="sidebar" minSize={50} order={1} defaultSize={this.state.isRightEditPanel ? 67 : 100} onResize={(size) => this.setState({ panelResize : size})} className={this.state.isRightEditPanel ? "isShowLeftPanel" : ""}>
							<ChangeEditForm roleIdentification={this.roleIdentification}  setErrorImpactedColor={this.setErrorImpactedColor} ImpactedErrorColor={this.state.ImpactedErrorColor}
							setErrorServiceColor={this.setErrorServiceColor} serviceErrorColor={this.state.serviceErrorColor}  showConflictForm={this.showConflictForm}
							conflictFormVal={this.state.conflictFormVal} user_id={this.state.user_id} role_id={this.state.role_id} date_format={this.state.date_format}
							translator={this.props.tr} changeId={this.props.params.changeId} changeActionView={this.changeActionView} actionView = {this.state.actionView}
							showTimeline={this.showTimeline} showTimelineFlag={this.state.showTimelineFlag} seTypeaheadValidation={this.seTypeaheadValidation}
							impGroupErr={this.state.impGroupErr} chgGroupErr={this.state.chgGroupErr} resetChangeEdit={this.state.resetChangeEdit} validateCABDate={this.validateCABDate}
							setState={(obj) => {this.setState(obj);}}
							updateRiskAssessmentDetails = {this.updateRiskAssessmentDetails}
							rightEditPanel={this.rightEditPanel}
							panelResize={this.state.panelResize}
							disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}
							setAssignmentGrpUsr={this.setAssignmentGrpUsr}
							selectedAssignmentGroup={this.state.selectedAssignmentGroup}
							selectedAssignmentUser={this.state.selectedAssignmentUser}
							setAssignmentGrpUsrImp={this.setAssignmentGrpUsrImp}
							selectedImpGroup={this.state.selectedImpGroup}
							selectedImpUser={this.state.selectedImpUser}
							/>
						</Panel>
						{this.state.isRightEditPanel ?
						<>
						<PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
						<Panel minSize={33} order={2} defaultSize={33}>
						<div className="stickyArea rBoxStyle">
										{this.showRightPaneAction(this.state.actionView)}
									</div>
						</Panel>
						</> : ""
						}
					</PanelGroup>
				</div>
            </div>
        );
    }

}
export function mapStateToProps(state){
	let type = state?.lovForType == "undefined" || state?.lovForType.length == 0 ? [] : state?.lovForType;
	let reasonForChange = state?.lovForReasonForChange == "undefined" || state?.lovForReasonForChange.length == 0 ? [] : state?.lovForReasonForChange;
	let urgency = state?.lovForUrgency == "undefined" || state?.lovForUrgency.length == 0 ? [] : state?.lovForUrgency;
	return {
		editChangeInitialData:state.editChangeInitialData,
		fieldStatus:state.fieldStatus,
	tr:state.spcmReducer.tr,
	lang:state.spcmReducer.lang,
	loadHamburgerOptions:state.loadHamburgerOptions,
	isFetchingDetails:state.isFetchingDetails.isFetching,
	changeTotalApprovalDetails:state.changeTotalApprovalDetails,
		reasonForChangeArr: reasonForChange,
		typeArr: type,
		urgencyArr: urgency
	};
}

function mapDispatchToProps(dispatch){
	return bindActionCreators({ getTranslation,loadChangeEditData,loadBreakFixAuditLogDetails, loadExternalAttributesDetails, loadStatusBasedHamburgerOptions, resetLoadHamburgerOptionsReducer, resetSomeFieldsEditChange, loadChangeTotalApprovalDetails, fetchWatcherDetails, getCmdbApprovals, resetRelatedTaskCount, loadrelatedWorkitems, resetrelatedCount, loadImplPlanList, checkRelatedTaskForItem, loadBreakFixTaskLogDetails,loadTimelineData}, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(EditChangeIndex);

