
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { browserHistory } from 'react-router';
import { Link, useNavigate } from "react-router-dom";
import CreateCategoryHeader from './CreateCategoryHeader.js';
import CreateCategoryForm from './CreateCategoryForm.js';
import { getTranslation } from '_Actions/spcmActions';
import { connect } from 'react-redux';
import {GLOBAL} from '_Globals';
import "_Css/form/_form.scss";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
let homepagelocation = cookies.get('gph');
if (homepagelocation) homepagelocation = homepagelocation.replace('s:', '');
if (homepagelocation) homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home") homepagelocation = "/" + homepagelocation;

class CreateCategoryIndex extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			subMenu: '4',
			showHideAlfy: false,
			showHideRightNav: false,
			actionView: 'assetgraph',
			businessFnError: "",
			selectedBusinessFn: []
		};
		this.showHideAlfy = this.showHideAlfy.bind(this);
		this.showHideRightNav = this.showHideRightNav.bind(this);
		this.changeActionView = this.changeActionView.bind(this);
		this.showRightPaneAction = this.showRightPaneAction.bind(this);
		this.loadHome = this.loadHome.bind(this);
		this.setBusinessFnErrorColor = this.setBusinessFnErrorColor.bind(this);
		this.setSelectedBusinessFn = this.setSelectedBusinessFn.bind(this);
	}

	loadHome() {
		browserHistory.push(homepagelocation);
	}

	setBusinessFnErrorColor() {
		if(this.state.selectedBusinessFn.length > 0) {
		  this.setState({ businessFnError: "" });
		} else {
		  this.setState({ businessFnError: "error" });
		}
	}

	setSelectedBusinessFn(value) {
		this.setState({ selectedBusinessFn: value })
	}

	showHideAlfy(value) {
		this.setState({ showHideAlfy: value });
	}

	showHideRightNav(value) {
		this.setState({ showHideRightNav: !this.state.showHideRightNav });
	}

	componentWillMount() {

	}

	changeActionView(actionSelected) {
		this.setState({
			actionView: actionSelected
		})
		//alert("actionSelected"+actionSelected);
	}

	showRightPaneAction(actionView) {
		let ref = this;
	}
	render() {
		return (
			<div>
				<div className="minHeightQA">
					<div className="container-fluid margin-t-10 margin-b-15">
						<ul className="breadcrumb">
							<li className="breadcrumb-item">
								<a role="button" href="javascript:void(0)" onClick={this.loadHome}>
									{this.props.translator["Home"]}
								</a>
							</li>
							<li className="breadcrumb-item"><Link title={this.props.translator["Category Board"]} to="/categoryBoard" >{this.props.translator["Category Board"]}</Link></li>
							<li className="breadcrumb-item active">{this.props.translator["Create"]}</li>
						</ul>
					</div>

					<div className="container-fluid" bsClass="">
						<Form>
							{/* Home Page blue bar Section Start*/}
							<CreateCategoryHeader changeActionView={this.changeActionView} translator={this.props.translator} setBusinessFnErrorColor={this.setBusinessFnErrorColor} />
							{/* Home Page blue bar Section end*/}

							<Row className="row-eq-height">
								<Col md={8} xs={12}>
									{/* Xsm Break Fix Form tabs Section Start */}
									<CreateCategoryForm translator={this.props.translator} 
										setBusinessFnErrorColor={this.setBusinessFnErrorColor}
										businessFnError={this.state.businessFnError}
										setSelectedBusinessFn={this.setSelectedBusinessFn}
										selectedBusinessFn={this.state.selectedBusinessFn}
									/>
								</Col>
							</Row>
						</Form>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ spcmReducer }) => {
	return {

		translator: spcmReducer.tr
	}
}
export default connect(mapStateToProps, { getTranslation })(CreateCategoryIndex);
