
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import SxMainMenuHamburger from './SXHamburger';
import PinnedItems from './pinned-items';
// import SxSearch from './GlobalSearch/SXSearch';
import SXSearchRedesign from './GlobalSearch/SXSearchRedesign';

class PindCategories extends React.Component {
	render() {
		return (
			<div className="pindCateBg">
				<div className="container-fluid">
					<Row className="">
						<Col lg={12} md={12} sm={12} xs={12}>
							{/* <div aria-label='Global Search' role="contentinfo" className="gloSearchDiv"><SxSearch tr={this.props.tr} /></div> */}
							<div aria-label='Global Search' role="contentinfo" className="gloSearchDiv"><SXSearchRedesign tr={this.props.tr} /></div>
							<PinnedItems />
							<SxMainMenuHamburger />
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}
export default PindCategories;


