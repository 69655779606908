
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';
import { GLOBAL } from "_Globals";
import ListLoader from '../../../common/loaders/ListLoader'
import swal from 'sweetalert';

import EmailValidator from 'email-validator';
import { Typeahead } from 'react-bootstrap-typeahead';
import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

// const getSuggestions = (value, consumerDataDetails) => {
// 	const inputValue = value.trim().toLowerCase();
// 	const inputLength = inputValue.length;
// 	if (inputLength > 2) {
// 		selectOptions = consumerDataDetails;
// 	}
// 	return inputLength < 1 ? [] : selectOptions.filter(offering => offering.email.toLowerCase().slice(0, inputLength) === inputValue);
// };

//const getSuggestionValue = suggestion => suggestion.email;

// function renderSuggestion(suggestion) {
// 	specifiedEmail = suggestion.email;
// 	return (
// 		<span>{suggestion.email}</span>
// 	);
// }


// class SendToTypeHead extends Component {
// 	constructor(props) {
// 		super(props);
// 		this.onChange = this.onChange.bind(this);
// 		this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
// 		this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
// 		this.state = {
// 			value: '',
// 			suggestions: []
// 		};
// 	}
// 	onChange(event, { newValue }) {
// 		this.setState({
// 			value: newValue
// 		});
// 		if (this.state.value == '') {
// 			specifiedEmail = '';
// 		}
// 	}
// 	onSuggestionsFetchRequested({ value }) {
// 		this.setState({
// 			suggestions: getSuggestions(value, consumerDataDetails)
// 		});
// 	}
// 	onSuggestionsClearRequested() {
// 		this.setState({
// 			suggestions: []
// 		});
// 	}

// 	render() {
// 		const { value, suggestions } = this.state;
// 		const inputProps = {
// 			placeholder: 'search for consumer email',
// 			className: 'form-control',
// 			value,
// 			onChange: this.onChange
// 		};
// 		return (
// 			<AutoSuggest
// 				suggestions={suggestions}
// 				onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
// 				onSuggestionsClearRequested={this.onSuggestionsClearRequested}
// 				getSuggestionValue={getSuggestionValue}
// 				renderSuggestion={renderSuggestion}
// 				inputProps={inputProps}
// 			/>
// 		);
// 	}
// }


class NotifyTab extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			toValue: '',
			mailSubject: '',
			mailBody: '',
			sentToDisableValue: false,
			showConsumerTypeHead: false,
			submitButtonDisabled: false,
			specifiedEmail:'',
			onSuccessMessage:'',
			sentToValue: 'select',
            sendValue: 'email',
			showLoading: true,
			showSMSoption: false,
			allGroups:[],
			selectedOptions:[],
			disableButton: false,
			maxSelectedgroups: 5
		}
		this.onSendToSelection = this.onSendToSelection.bind(this);
		this.onSendSelection = this.onSendSelection.bind(this);
		this.setMailSubject = this.setMailSubject.bind(this);
		this.setMailBody = this.setMailBody.bind(this);
		this.postNotification = this.postNotification.bind(this);
		this.setSpecifiedEmail = this.setSpecifiedEmail.bind(this);
		this.setErrorColor = this.setErrorColor.bind(this);
		this.onGroupInputChange = this.onGroupInputChange.bind(this);
		this.onGroupSelected = this.onGroupSelected.bind(this);
	}
	setErrorColor(){
	}
	onGroupInputChange(value){
	}
	onGroupSelected(groupsSelected){
		if(groupsSelected.length<=5){
			this.setState({selectedOptions: groupsSelected});
		}else{
			this.setState({selectedOptions: this.state.selectedOptions});
		}
	}
	componentWillMount(){
		if(this.props.disableFieldsForGuestRole){
           this.setState({showLoading: false});
		}else{
		let str={'userId': this.props.breakFixEditDetails.impactedUserid,'company_id': this.props.breakFixEditDetails.consumerCompany};
		str = JSON.stringify(str);
		axios.get('/api/user/cartCount',{headers:{'query':str}}).then((responseData) =>{
               this.setState({showLoading: false, showSMSoption: ((this.props.roleIdentification('14') || this.props.roleIdentification('48')) && responseData.data.isSMSNotification) });
		});
		axios.get(GLOBAL.ciSupportAllGroupUrl+'/id/'+this.props.breakFixEditDetails.consumerCompany).then((responseGroupdata)=>{
            let allGroup=[];
			if (responseGroupdata.data !== "") {
				responseGroupdata.data.map((item) => {
				  allGroup.push({'value': ''+item.groupId+'', 'label':item.groupName, companyName: (item['companyName'] || '') });
				});
			} 
			this.setState({allGroups: allGroup});
		});
	    }
	}
	setSpecifiedEmail(value) {
		this.setState({specifiedEmail:value});
	}
	onSendSelection(value){
			this.setState({sendValue: value, 
				toValue: '',
				mailSubject: '',
				mailBody: '',
				sentToDisableValue: false,
				showConsumerTypeHead: false,
				submitButtonDisabled: false,
				specifiedEmail:'',
				onSuccessMessage:'',
				sentToValue: 'select',
				selectedOptions: []
            });
	}

	onSendToSelection(sentTo) {
		let ref = this;
		ref.setState({ sentToDisableValue: false });
		ref.setState({sentToValue:sentTo});
		ref.setState({
			specifiedEmail: '',
			toValue: '',
			selectedOptions: []
		});
		let str={};
		str.userid=this.props.breakFixEditDetails.impactedUserid;
		str = JSON.stringify(str);
		if (sentTo == "User") {
			ref.setState({ showConsumerTypeHead: false });
			ref.setState({specifiedEmail:''});
			ref.setState({toValue:''});
			ref.setState({ sentToDisableValue: true });
			let response = api.get(GLOBAL.profileDetailsUrl,{headers:{'query':str}}); //hardcoding impacedUserId as its coming as string in response on edit page
			response.then((responseData) => {
				if (responseData.status == 200) {
					if(this.state.sendValue==='sms'){
                        ref.setState({ toValue: responseData.data.mobilePhone });
					}else{
                        ref.setState({ toValue: responseData.data.email });
					}
				//	ref.setState({ sentToDisableValue: true });
				} else {
					swal({
						text: this.state.sendValue==='email'?this.props.translator['Error in fetching user Email']:this.props.translator['Error in fetching user Phone number'],
						button: this.props.translator['OK']
					});
					ref.setState({ sentToDisableValue: false });
				}
			});
		}
		if (sentTo == "Group") {
			let spGroupId = this.props.breakFixEditDetails.spGroupid;
			ref.setState({specifiedEmail:''});
			ref.setState({toValue:''});
			ref.setState({ sentToDisableValue: true });
			ref.setState({ showConsumerTypeHead: false });
			api.get("/api/groups?groupId="+spGroupId)
				.then((response) => {
					if (response.status == 200) {
						if(this.state.sendValue==='sms'){
							ref.setState({ toValue: response.data[0].GROUP_MEMBER_MOBILE_NUMBERS }); 
						}else{
							ref.setState({ toValue: response.data[0].GROUP_MEMBER_EMAILS });
						}
					} else {
						swal({
							test: this.state.sendValue==='email'?this.props.translator['Error in loading group Email']:this.props.translator['Error in loading group Phone numbers'],
							button: this.props.translator['OK']
						});
					}
				});
			ref.setState({specifiedEmail:''});
		}
		if (sentTo == "Specify") {
			ref.setState({ showConsumerTypeHead: true });
			/* axios.get(GLOBAL.breakFixConsumerUrl).then((response) => {
				consumerData = response.data;
				for (let i = 0; i < consumerData.length; i++) {
					myObj = { userId: '', email: '' };
					myObj.userId = consumerData[i].userId;
					myObj.email = consumerData[i].email;
					consumerDataDetails.push(myObj);
				}
				consumerDataDetails = Array.from(new Set(consumerDataDetails));
			}); */
		}
		if(sentTo==='specifyGroup'){
          this.setState({selectedOptions: []});
		  this.setState({ toValue: '' });
		  this.setState({ showConsumerTypeHead: false });
		}
		if (sentTo == "select") {
			this.setState({ toValue: '' });
			this.setState({ showConsumerTypeHead: false });
		}
	}

	setMailSubject(subject) {
		this.setState({ mailSubject: subject });
	}

	setMailBody(body) {
		if(this.state.sendValue==="sms" && body.length<=160 || this.state.sendValue==="email" && body.length<=1000)
		    this.setState({ mailBody: body });
	}

	postNotification(event) {

		let ref = this;
		event.preventDefault();
		let brkItemId = this.props.breakFixEditDetails.breakfixId;
		let sendTo;
		if (!this.state.specifiedEmail || this.state.specifiedEmail.trim() == '') {

			sendTo = this.state.toValue;
		}
		else {
			sendTo = this.state.specifiedEmail;
		}

		let NotifyObj = {
			"itemId": "",
			"subject": "",
			"mailBody": "",
			"addEmail": "",
			"orderNumber":this.props.breakfixD2cNumber,
			"itemNumber": this.props.breakFixEditDetails.breakfixNumber,
			"requesterName":this.props.breakFixEditDetails.impactedUsername,
			"senderName":this.props.full_name,
			"type":this.state.sendValue+"~"+this.state.sentToValue
		};
		let tiltPresent=false;
		if(this.state.sendValue==='sms'){
		  for(let i=0;i<this.state.mailBody.length;i++){
             if(this.state.mailBody[i]==='~'){
			     tiltPresent=true;  
			    	break;
			 }
		  } 
	    }
        if (this.state.sendValue==='sms' && tiltPresent) {
            swal({
				text: this.props.translator['Please remove tilde character (i.e. ~) from SMS body'],
				button: this.props.translator['OK']
			});
        }
		else if( (!this.state.sentToValue || this.state.sentToValue == 'select') || (this.state.sentToValue==='specifyGroup' && this.state.selectedOptions.length===0) || ( this.state.sentToValue!=='specifyGroup' && (!this.state.toValue || this.state.toValue.trim() == '') && (!this.state.specifiedEmail || this.state.specifiedEmail.trim() == '') ) ){
            swal({
               text: this.props.translator['Recipient field cannot be empty'],
			   button: this.props.translator['OK']
			});
		}
		// else if((this.state.sendValue==='sms' || this.state.sendValue==='email') && this.state.sentToValue==='specifyGroup' && this.state.selectedOptions.length===0){
        //     swal({
        //        text: this.props.translator['Recipient field cannot be empty'],
		// 	   button: this.props.translator['OK']
		// 	});
		// }else if (this.state.sentToValue!=='specifyGroup' && this.state.toValue.trim() == '' && this.state.specifiedEmail.trim() == '') {
		// 	swal({
		// 		text: this.props.translator['Recipient field cannot be empty'],
		// 		button: this.props.translator['OK']
		// 	 });
		// }
		else {
			if (this.state.mailBody.trim() == '') {
				// swal({
				// 	text: this.state.sendValue==='email'?this.props.translator['Mail body cannot be empty']:this.props.translator['SMS body cannot be empty'],
				// 	button: this.props.translator['OK']
				// });
				swal({
					text: 'Please specify the message.',
					button: this.props.translator['OK']
				});
			}else {
				if (this.state.sendValue==='email' && this.state.mailSubject.trim() == '') {
					// swal({
                    //      text: this.props.translator['Subject cannot be empty'],
					// 	 button: this.props.translator['OK']
					// });
					swal({
						text: 'Please specify the subject.',
						button: this.props.translator['OK']
				   });
				}else if(this.state.sendValue==='email' && EmailValidator.validate(sendTo) == false  && this.state.sentToValue=="Specify"){
					swal({
                        text: this.props.translator['Please enter a valid email address'],
						button: this.props.translator['OK']
					});
				}
				else {
					ref.setState({ submitButtonDisabled: true });
					NotifyObj.itemId = brkItemId;
					NotifyObj.subject = this.state.mailSubject.replace(/</g,'').replace(/>/g,'');
					if(this.state.sendValue==='sms'){
                      NotifyObj.mailBody=this.props.breakFixEditDetails.priorityValue+"~"+
					                     this.props.breakFixEditDetails.status+"~"+
										 this.props.breakFixEditDetails.breakfixNumber+"~"+
										 this.state.mailBody.replace(/</g,'').replace(/>/g,'');
					}else{
					  NotifyObj.mailBody = this.state.mailBody.replace(/</g,'').replace(/>/g,'');
					}  
					if(this.state.sentToValue==='specifyGroup'){
                      let groupID='',groupName='';
					  this.state.selectedOptions.forEach((selectedGroup,itr)=>{
						  if(itr===this.state.selectedOptions.length-1){
							 groupID+=selectedGroup.value;
							 groupName+=selectedGroup.label;
						  }else{
                             groupID+=selectedGroup.value+',';
                             groupName+=selectedGroup.label+',';
						  }	 
					  });
					  NotifyObj.addEmail = groupID;
					}else{
						NotifyObj.addEmail = sendTo;
					}
					axios.post(GLOBAL.emailNotification,
						NotifyObj
					)
						.then((response) => {
							if (response.status == 200) {
								ref.setState({ submitButtonDisabled: false });
								ref.setState({
									onSuccessMessage: this.state.sendValue==='email'?this.props.translator['Email sent Successfully']:this.props.translator['SMS sent Successfully'],
									toValue: '',
									mailSubject: '',
									mailBody: '',
									specifiedEmail:'',
			                        sentToDisableValue: false,
			                        showConsumerypeHead: false,
			                        submitButtonDisabled: false,
			                        sentToValue: 'select',
                                    sendValue: 'email',
									showLoading: false,
			                        selectedOptions:[]
								});
								ref.props.setShowNotifyForm(false);
							} else {
								swal({
                                    text: this.state.sendValue==='email'?this.props.translator['Error in sending Email']:this.props.translator['Error in sending SMS'],
									button: this.props.translator['OK']
								});
								this.setState({ submitButtonDisabled: false });
							}
						}).catch((err)=>{
							console.log("Error in calling API : ",err);
							this.setState({ submitButtonDisabled: false });
						});
				}
			}
		}
	}
	render() {
  
		return (
			(this.props.showNotifyForm === true) ?
			<div className="hambTab">
				<div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
					<ul>
					<li>
					<button
					    type='button'
						title="Minimize the right panel"
						bsClass=""
						bsStyle=""
						className="closerightPanelBtn"
						onClick={() => {
						this.props.rightEditPanel(false);
						}}
					>
						<IoClose />
					</button>
					</li>
					</ul>
				</div>
				<div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Notify']}</div></div>
				
				{this.state.showLoading ? 
				    <div className='rBoxGap'><ListLoader /></div> 
				:
				<div className='rBoxGap'>
				<Form.Group className="form-group" >
					<Form.Label><span className="rStar"></span>{this.props.translator['Send']} </Form.Label>
					<Form.Select aria-label="select" onChange={(event)=> {this.onSendSelection(event.target.value)}} disabled={this.props.disableFieldsForGuestRole}>
						<option value="email">Email</option>
						{this.state.showSMSoption?<option value="sms">SMS</option>:null}
					</Form.Select>
				</Form.Group>
				<Form.Group className="form-group" >
					<Form.Label><span className="rStar"></span>{this.props.translator['Send To']} </Form.Label>
					<Form.Select aria-label="select" value={this.state.sentToValue} onChange={(event) => { this.onSendToSelection(event.target.value) }} disabled={this.props.disableFieldsForGuestRole}>
						<option value="select">select</option>
						<option value="User">Requestor</option>
						{(this.state.sendValue==="email") ?
							<option value="Group">Assigned Group</option>
						:	<option value="Group">Group</option>
						}
						{this.state.sendValue==="email"?<option value="Specify">Specify User</option>:""}
						{/* {this.state.sendValue==="sms"?<option value="specifyGroup">Specify Group</option>:""} */}
						<option value="specifyGroup">Specify Group</option>
						{/* {this.state.sendValue==="sms"?<option value="specifyUser">Specify User</option>:""} */}
					</Form.Select>
				</Form.Group>
				{this.state.sentToValue==='specifyGroup'?
				    <Form.Group className="typeaheadInput form-group notifydvTypehd">
				         <Form.Label>{this.props.translator['To']+" ("+this.props.translator['Maximum']+" "+this.state.maxSelectedgroups+" "+this.props.translator['can be selected']+")"}</Form.Label>
					     <Typeahead 
						      multiple
							  disabled={this.props.disableFieldsForGuestRole}
						      options={this.state.allGroups}
						      selected={this.state.selectedOptions}
						      placeholder={this.props.translator["Please choose"]+"..."}
						      onBlur={this.setErrorColor}
						      onInputChange={this.onGroupInputChange}
						      onChange={this.onGroupSelected}
							  renderMenuItemChildren={(option, props) => {
								let label;
								let title;
								label =
									option.label.length > 30
									  ? option.label.slice(0, 30) + "..."
									  : option.label;
								  title = option.label.length > 30 ? option.label : "";
								if(option?.companyName){
								  return (
									<div>
									  <span className="typHdmainHead" title={title} style={{'display': 'block'}}>{label}</span>
									  <span className="typHdmainSubHead" style={{'display': 'block', 'margin-top': '6px', 'color': '#777'}}>{option?.companyName || ''}</span>
									</div>
								  )
								}
								return (
								  <div>
									<span className="typHdmainHead" title={title}>{label}</span>
								  </div>
								);
							  }}
					      />   
		                </Form.Group>
				:	
				this.state.showConsumerTypeHead ?
					<Form.Group className="form-group" >
						<Form.Label>{this.props.translator['To']}</Form.Label>
						{/* <SendToTypeHead /> Issue #96 require only input type field*/}
						<Form.Control type="text" tooltip value={this.state.specifiedEmail} onChange = {(event)=>{this.setSpecifiedEmail(event.target.value);}} readOnly={this.props.disableFieldsForGuestRole} onKeyPress={(e) => {e.key === "Enter" && e.preventDefault();}}/>
					</Form.Group> :
					<Form.Group className="form-group" >
						<Form.Label>{this.props.translator['To']}</Form.Label>
						<Form.Control type="text" tooltip value={this.state.specifiedEmail} placeholder={this.state.toValue} readOnly={this.state.sentToDisableValue||this.props.disableFieldsForGuestRole} title={this.state.toValue} onKeyPress={(e) => {e.key === "Enter" && e.preventDefault();}}/>
					</Form.Group>
				}
				{this.state.sendValue==='email' ? <Form.Group className="form-group" >
					<Form.Label>{this.props.translator['Subject']}</Form.Label>
					<Form.Control type="text" maxLength="250" value={this.state.mailSubject} onChange={(event) => { this.setMailSubject(event.target.value) }} readOnly={this.props.disableFieldsForGuestRole} onKeyPress={(e) => {e.key === "Enter" && e.preventDefault();}}/>
				</Form.Group>: ''}
				<Form.Group className="form-group" >
					<Form.Label>{this.props.translator['Body']}</Form.Label>
					<Form.Control as="textarea" maxLength={this.state.sendValue==="sms"?"160":"1000"} rows="5" value={this.state.mailBody} onChange={(event) => { this.setMailBody(event.target.value) }} readOnly={this.props.disableFieldsForGuestRole}/>
					<p className="charLeft" style={{textAlign:"right","fontSize":"11px"}}>({this.props.translator['Characters Remaining']}: {this.state.sendValue==="sms"?160-this.state.mailBody.length:1000-this.state.mailBody.length}/{this.state.sendValue==="sms"?160:1000})</p>
				</Form.Group>
				
				<ButtonToolbar className="black">
				<Button className="rgSidrkBtn smallBtn" bsSize="small" bsStyle="primary"  disabled={this.state.submitButtonDisabled||this.props.disableFieldsForGuestRole} onClick={(event) => { this.postNotification(event); }} >
                  {this.state.submitButtonDisabled ? <ImSpinner6 className="icn-spinner"/> : null} {this.props.translator['Send']}</Button>
                </ButtonToolbar>

				{/* <Button type="submit" onClick={(event) => { this.postNotification(event); }} disabled={this.state.submitButtonDisabled}>{this.props.translator['Send']}</Button> */}
				</div>
			}
			</div>
			:
			<div className="hambTab">
				<div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
					<ul>
					<li>
					<button
					    type='button'
						title="Minimize the right panel"
						bsClass=""
						bsStyle=""
						className="closerightPanelBtn"
						onClick={() => {
						this.props.rightEditPanel(false);
						}}
					>
						<IoClose />
					</button>
					</li>
					</ul>
				</div>
				<div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Notify']}</div></div>
				<div className='rBoxGap'>
				    <Alert variant="success" className="errorMessageSec"><p>{this.state.onSuccessMessage}</p></Alert>
				</div>
			</div>
		);
	}
}

export default NotifyTab;
