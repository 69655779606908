
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export default function (state = initialState.cmdbQuickViewFilters, action){
  //let newselectedgroupsarray = [];
  let newSelectedStatusArray = [];
  let newSelectedCompanyArray = [];
  let newSelectedClassArray = [];


	switch (action.type){
    case 'REMOVE_FROM_SELECTED_STATUS_LIST':
			newSelectedStatusArray = state.selectedStatus.filter((item) => {return item!=action.payload;});
			return Object.assign({}, state, {selectedStatus: newSelectedStatusArray});
		case 'ADD_TO_SELECTED_STATUS_LIST':
			newSelectedStatusArray = state.selectedStatus.concat(action.payload);
			return Object.assign({}, state, {selectedStatus: newSelectedStatusArray});
    //===
    case 'REMOVE_FROM_SELECTED_COMPANY_LIST':
			newSelectedCompanyArray = state.selectedCompany.filter((item) => {return item!=action.payload;});
			return Object.assign({}, state, {selectedCompany: newSelectedCompanyArray});
		case 'ADD_TO_SELECTED_COMPANY_LIST':
			newSelectedCompanyArray = state.selectedCompany.concat(action.payload);
			return Object.assign({}, state, {selectedCompany: newSelectedCompanyArray});
    //===
    //===
    case 'REMOVE_FROM_SELECTED_CLASS_LIST':
			newSelectedClassArray = state.selectedClass.filter((item) => {return item!=action.payload;});
			return Object.assign({}, state, {selectedClass: newSelectedClassArray});
		case 'ADD_TO_SELECTED_CLASS_LIST':
			newSelectedClassArray = state.selectedClass.concat(action.payload);
			return Object.assign({}, state, {selectedClass: newSelectedClassArray});
    //===

    //reset selectedFilter

    case 'RESET_CMDB_QV_FILTERS':
      return Object.assign(
        {},
        state,
        {
          selectedStatus:[],
          selectedCompany:[],
          selectedClass:[],

        }
      );

		default:
				return state;
		}
}
