
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import { Row, Col, Container } from 'react-bootstrap';

import PindCategories from "../../common/header/pind-categories";
import Breadcrumbs from "../../common/header/breadcrumbs";
import Header from "./header";
import List from "./list";
import ShortDesc from "./shortDesc";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import "_Css/common/_dataTable.scss";

const Index = () => {
  const [isRightSideVisible, setRightSideVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  let colWidth = "";
  isRightSideVisible ? (colWidth = 8) : (colWidth = 12);

  const showRightSideFun = (val) => {
    setRightSideVisible(val);
  };

  const setSelectedItemFun = (data) => {
    setSelectedItem(data);
  };

  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        {/* <div className="tabDatacount">1-1 of 1</div> */}
        <Breadcrumbs activePageName="Custom Attribute" />
      </Container>

      <Container fluid className="position-re">
        <Header showRightSideFun={showRightSideFun} />
        <PanelGroup direction="horizontal" className="panelOverflow">
          <Panel id="sidebar" minSize={33} order={1} defaultSize={isRightSideVisible ? 67 : 100} className={isRightSideVisible ? "isShowLeftPanel catBordDv" : "catBordDv"}>
             <List rightDisplay={isRightSideVisible} showRightSideFun={showRightSideFun} setSelectedItemFun={setSelectedItemFun} />
          </Panel>
          {isRightSideVisible ?
              <>
                <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
                <Panel minSize={33} order={2} defaultSize={33}>
                  <div className="stickyArea rBoxStyle">
                    <ShortDesc showRightSideFun={showRightSideFun} selectedItem={selectedItem} />
                  </div> 
                </Panel>
              </> : ""
          }
        </PanelGroup>
      </Container>
    </main>
  )
}

export default Index;
