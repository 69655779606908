
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Upload } from "@progress/kendo-react-upload";
import Cookies from "universal-cookie";
import { fileobjectGenerator } from "../../../../common/MIMEObjGenerator";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
const cookies = new Cookies();
let attachmentDetails = cookies.get("gph");
if (attachmentDetails) attachmentDetails = attachmentDetails.replace("s:", "");
if (attachmentDetails)
  attachmentDetails = attachmentDetails.substring(
    0,
    attachmentDetails.lastIndexOf(".")
  );
attachmentDetails = attachmentDetails.split("~");
const attachmentSize = attachmentDetails[21];
const attachmentType = attachmentDetails[22].split(', ');

const DropzoneField = (props) => {
  const [files, setFiles] = useState([]);
  const [filePreviews, setFilePreviews] = useState({});
  const { onFileChange, attributes, defaultValues } = props;
  const tr = useSelector((state) => state.spcmReducer.tr);

  useEffect(() => {
    let previews = {};
    let binaryFiles = [];
    if (files.length > 0) {
      files.forEach((file) => {
        const reader = new FileReader();
        reader.onloadend = (ev) => {
          previews[file.uid] = ev.target.result;
          setFilePreviews(previews);
        };
        if (file && file.getRawFile) {
          reader.readAsDataURL(file.getRawFile());
        }
        binaryFiles.push(file.getRawFile());
      });
      onFileChange(binaryFiles);
    }else{
      onFileChange([]);
    }
  }, [files]);

  const onAdd = (event) => {
    let size = 1048576 * attachmentSize;
    let sizeError = false;
    let typeError = false;
    if(event.newState && event.newState.length>0){
      event.newState.forEach(file=>{
        if(file?.size>size){
          sizeError = true;
        }
      })
    }

    if(event.newState && event.newState.length>0){
      event.newState.forEach(file=>{
        if(!attachmentType.includes(file?.extension)){
          typeError = true;
        }
      })
    }

    if (sizeError) {
      Swal.fire({
        title:
          tr["Please upload File up to "] + attachmentSize + tr[" MB size."],
        button: tr["OK"],
      });
    } else if (typeError) {
      Swal.fire({
        title: tr["File not supported."],
        button: tr["OK"],
      });
    } else {
      setFiles(event.newState);
    }
    
  };
  const onRemove = (event) => {
    setFiles(event.newState);
  };
  const onProgress = (event) => {
    setFiles(event.newState);
  };
  const onStatusChange = (event) => {
    setFiles(event.newState);
  };
  function onSaveRequest(files, options, onProgress) {
    const currentFile = files[0];
    const uid = currentFile.uid;
    return new Promise((resolve, reject) => {
      if (
        currentFile.validationErrors &&
        currentFile.validationErrors.length > 0
      ) {
        reject({
          uid: uid,
        });
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result && typeof reader.result === "string") {
            const base64Result = reader.result.split(",")[1];
            resolve({
              uid: uid,
            });
          } else {
            reject({
              uid: uid,
            });
          }
        };
        reader.onprogress = (data) => {
          onProgress(uid, data);
        };
        reader.onabort = () => {
          reject({
            uid: uid,
          });
        };
        reader.onerror = () => {
          reject({
            uid: uid,
          });
        };
        reader.readAsDataURL(currentFile.getRawFile());
      }
    });
  }
  function onRemoveRequest(files, options) {
    const currentFile = files[0];
    const uid = currentFile.uid;
    return new Promise((resolve) => {
      resolve({
        uid: uid,
      });
    });
  }
  return (
    <div>
      <Upload
        batch={false}
        accept={attachmentDetails[22]}
        multiple={attributes?.multiSelect || false}
        files={files}
        restrictions={{
          maxFileSize: 1048576 * attachmentSize
        }}
        onAdd={onAdd}
        onRemove={onRemove}
        onProgress={onProgress}
        onStatusChange={onStatusChange}
        withCredentials={false}
        saveUrl={onSaveRequest}
        removeUrl={onRemoveRequest}
        disabled={attributes?.disabled || props?.nonEditable}
      />
      {files.length && attributes?.preview ? (
        <div className={"img-preview example-config"}>
          <h3>Selected images</h3>
          {Object.keys(filePreviews).map((fileKey, index) => (
            <img
              src={filePreviews[fileKey]}
              alt={""}
              style={{
                width: 200,
                margin: 10,
              }}
              key={index}
            />
          ))}
        </div>
      ) : undefined}
    </div>
  );
};

export default DropzoneField;
