
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react'
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm,  formValueSelector} from 'redux-form';
import axios from 'axios';
import swal from 'sweetalert';

import { _inputField, _textArea, _dropDown, _dropDown1, _dateField, _latentField, _dateTimeField, _latentDateTimeField } from '../../../common/ReduxFormFields/formFields';
import { GLOBAL } from "_Globals";
import configureStore from '../../../../store/configureStore';

import { loadBreakFixActivityLogDetails } from '../../../../actions/breakFix/breakFixActivityLogAction';
import {ImSpinner6} from "react-icons/im";

const store = configureStore();
const required = value => value ? undefined : 'Required';

class DialogForm extends React.Component {
    constructor() {
        super();
        this.state = {
          showHide: true,
          conflictVal:false,
          loader:false
        }
        this.submitConflict = this.submitConflict.bind(this);
        this.changeConflictVal = this.changeConflictVal.bind(this);
        this.renderMembers = this.renderMembers.bind(this);
    }
    submitConflict(val) {
        let data = this.props.conflictData;
        let changeFormData = this.props.initialData;
        let ref = this;
        let payload = [];
        let description =[];    
        let justfyArray = Object.values(val);    
        data.forEach((element,i) => {
          let conflict = {};         
          conflict.changeId = changeFormData.changeId;
          conflict.conflictChangeId= element.changeId;
          conflict.ignoreConflict = true;
          conflict.justification = justfyArray[i];
          conflict.createdOn = "";
          payload.push(conflict);
          description.push(element.changeNumber+'-'+justfyArray[i]);
        });   
        axios.post(GLOBAL.breakFixActivityLogPostUrl, {
          "itemId":changeFormData.changeId,
          "description":`Conflict Justification:${description}`,
          "isInternal":"Y",
          "createdOn": "",
          "createdBy": "",
          "module": "ChangeManagement",
          "createdByName": "",
          "sendNotification":"true"
        }).then((response) => {
            this.props.loadBreakFixActivityLogDetails(response.data.itemId, "desc", "ChangeManagement");
        });
        axios.post(GLOBAL.changeConflictSubmit + changeFormData.changeId, payload).then((response) => {
          ref.setState({loader:true});
          if (response.status == 200) {
            swal({
              text: "Justification(s) submitted successfully.",
              button: ref.props.translator['OK']
            });
            ref.props.showConflictForm(false);
            ref.setState({loader:false});
            }
        }).catch((err) => {
          ref.setState({loader:false});
        });
    }
    changeConflictVal(val){
        this.setState({conflictVal:val})
    }
    renderMembers(){
        return <div>
          <p>This Change</p>
          <Table responsive striped bordered condensed hover>
            <thead>
              <tr>
                <th width="30%">{this.props.translator['Work Item ID']}</th>
                <th width="30%">{this.props.translator['Summary']}</th>
                <th width="20%">{this.props.translator['Scheduled Start Date']}</th>
                <th width="20%">{this.props.translator['Scheduled End Date']}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{this.props.initialData.requestNumber + "-" + this.props.initialData.changeNumber}</td>
                <td>{this.props.initialData.summary}</td>
                <td>{this.props.expectedStartDate}</td>
                <td>{this.props.expectedEndDate}</td>
              </tr>
            </tbody>
          </Table>
          <p>{this.props.translator['has conflict with the following Scheduled Change(s)']}:-</p>
          <Table responsive striped bordered condensed hover>
            <thead>
              <tr>
                <th width="27%">{this.props.translator['Work Item ID']}</th>
                <th>{this.props.translator['Impacted Service/CI']}</th>
                <th >{this.props.translator['Summary']}</th>
                <th >{this.props.translator['Scheduled Start Date']}</th>
                <th >{this.props.translator['Scheduled End Date']}</th>
                {this.state.conflictVal ? <th width="30%">Justification</th> : ""}
              </tr>
            </thead>
            <tbody>
              {this.props.conflictData.map((item, index) =>
                <tr>
                  <td>{item.requestNumber + "-" + item.changeNumber}</td>
                  <td>{item.serviceName}</td>
                  <td>{item.summary}</td>
                  <td>{item.expStartDate}</td>
                  <td>{item.expEndDate}</td>
                  {this.state.conflictVal ? <td>
                    <Field name={"justification" + index}
                      component={_inputField} className="form-control" validate={required}
                    />
                  </td> : ""}
                </tr>
              )}
            </tbody>
          </Table>
          <p>{this.props.translator["To modify the dates of this Change, Please click on 'Cancel'"]}</p>
          <p>{this.props.translator["To ignore the Conflict(s), Please click on 'Ignore Conflict', provide the justification for each conflict and click on 'OK' to proceed"]}</p>
        </div>
    }
    handleModalShowHide() {
        this.setState({ showHide: !this.state.showHide })
    }
    render() {
        const { handleSubmit } = this.props;
        return (
          <div className="modal-dialog">
            <Modal  show={this.state.showHide} className="changeModalCss">
              <Modal.Header>
               
                <Modal.Title><span className="conflictHeading">{this.props.translator['Conflict Change']}</span></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  {this.renderMembers()}
              </Modal.Body>
              <Modal.Footer>

                {this.state.conflictVal ?
                  <Button variant="primary" type="submit" onClick={handleSubmit(this.submitConflict)} disabled={this.state.loader}>
                   {this.state.loader ? <ImSpinner6 className="icn-spinner"/>:""} {this.props.translator['Ok']}
                 </Button>
                  :
                  <Button variant="primary" type="submit" onClick={() => this.changeConflictVal(true)} disabled={this.state.loader}>
                    {this.props.translator['Ignore Conflict(s)']}
                  </Button>
                }
                <Button variant="primary" onClick={() => {this.props.showConflictForm(false);const root = document.documentElement; root?.style.setProperty("--modalBackDrop", 0);}} disabled={this.state.loader}>
                {this.props.translator['Cancel']}
                 </Button>
                 
              </Modal.Footer>
            </Modal>
          </div>
        )
      }
}
const selector = formValueSelector('changeEditForm');
const mapStateToProps = (state) => {
  let expectedStartDate = selector(state,'expectedStartDate');
  let expectedEndDate = selector(state,'expectedEndDate');
	return{
    initialValues: state.changeConflictJustification == undefined ? {}: state.changeConflictJustification,
    conflictData: state.changeConflictData == undefined ? []: state.changeConflictData,
    expectedStartDate, expectedEndDate
	}
}
function mapDispatchToProps(dispatch){
	return bindActionCreators({ loadBreakFixActivityLogDetails}, dispatch);
}
DialogForm = reduxForm({
	form: 'conflictDialogForm'
})(DialogForm);
export default connect(mapStateToProps,mapDispatchToProps)(DialogForm);