
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { deleteLocationData, updateLocationAction } from "../../../../../actions/foundation/locationAction";
import { reduxForm } from "redux-form";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';

import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import {HiOutlineTrash} from "react-icons/hi";

const HeaderSec = (props) => {
  const navigate = useNavigate();
  const tr = useSelector((state) => state.spcmReducer.tr);

  const dispatch = useDispatch();
  const params = useParams();
  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const saveObjRef = useRef(null);

	useEffect(() => {
		context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
    context.newTabPrevent();
	}, []);

  const submit = async (values) => {
    let id = params.id;
    let arrRov = [];
    console.log("EditHeaderLOC", values, id);
    let isValid = true;
    if (!values.company_name || values.company_name == "") {
      console.log("company_name1");
        props.setCompanyErrorColor();
        isValid = false;
    }
    if (!values.name || values.name == '' || values.name.trim() == '') {
      console.log("name1");
        props.setNameErr("error");
        isValid = false;
      } else {
        props.setNameErr("");
    }
    if (!values.address_first || values.address_first == '' || values.address_first.trim() == '') {
      console.log("address_first1");
        props.setAddressErr("error");
        isValid = false;
      } else {
        props.setAddressErr("");
    }
    if (!values.status || values.status == '') {
      console.log("status1");
        props.setStatusErr("error");
        isValid = false;
    } else {
        props.setStatusErr("");
    }
    if (!values.country || values.country == '') {
      console.log("country1");
        props.setCountryErr("error");
        isValid = false;
      } else {
        props.setCountryErr("");
    }
    if (!values.state || values.state == '') {
      console.log("state1");
        props.setStateErr("error");
        isValid = false;
      } else {
        props.setStateErr("");
    }
    if (!values.city || values.city == '') {
      console.log("city1");
        props.setCityErr("error");
        isValid = false;
      } else {
        props.setCityErr("");
    }
    if (!values.zip || values.zip == '' || values.zip.trim() == '') {
        props.setZipErr("error");
        isValid = false;
      } else {
        props.setZipErr("");
    }
    if (values.locationType == "Walk-up" && values.supportHours) {
      let str = values.supportHours;
      str = str.replace(/&nbsp;/g, "");
      str = str.replace(/(<([^>]+)>)/ig, '');
      str = str.trim();
      if (str.length == "0") {
         props.setSupportHrsErr('Enter Support Hours');
         isValid = false;
      }
    }
 
    else if (values.locationType == "Walk-up" && (!values.supportHours|| values.supportHours == ''|| values.supportHours.trim() == '')) {
      props.setSupportHrsErr('Enter Support Hours');
      isValid = false;
    }
    else {
      props.setSupportHrsErr("");
    }
    if (values.locationType == "Walk-up" &&  (!values.reasonOfVisit || values.reasonOfVisit.length == 0)) {
      props.setValueError("error");
      isValid = false;
    } else {
      props.setValueError("");
      if(values.reasonOfVisit){
      values.reasonOfVisit.map((val)=>{
        arrRov.push(val.id)
    })
    }
  }
    if (isValid == true) {
      let postjson;
      postjson = {
        id: id,
        location_name: values.name,
        location_address1: values.address_first,
        location_address2: values.address_second,
        location_address3: values.address_third,
        location_zip: values.zip,
        company_id: values.company_id,
        company_name: values.company_name,
        country_id:  values.country,
        country_name: values.countryText,
        state_id: values.state,
        state_name: values.stateText,
        city_id: values.city,
        city_name: values.cityText,
        actionVal: "actionVal",
        status: values.status,
        filterby: "location_name",
        filterbytext: "IndiaAustraliaHCL",
        pagesize: 0,
        eventNameDefault: null,
        locationnew: null,
        location_type: values.locationType,
        create_date: values.createdDate,
        created_by: values.created_by,
        last_updated_date: values.modified_date,
        last_updated_by: values.modified_by,
        name: null,
        accessibility: values.accessInstruction,
        description: values.description,
        support_hours: values.supportHours,
        reasonOfVisit: values.locationType == "Walk-up" ? arrRov: null
      };

      dispatch(updateLocationAction(postjson));
    }
  };

  const onDeleteClick = () => {
    const id = params.id;
    Swal.fire({
      title: tr["Are you sure you want to delete?"],
      width: 550,
      padding: "15px",
      showDenyButton: true,
      confirmButtonText: tr["Yes"],
      denyButtonText: tr["No"],
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteLocationData(id));
      } else if (result.isDenied) {
      }
    });
  };
  let { handleSubmit } = props;
  return (
    <>
      <Row className="margin-b-15">
        <Col sm={8} xs={7}>
          <h1 bsClass="" className="sPageHeading1">
            {tr["Location"]}
          </h1>
        </Col>
        <Col sm={4} xs={5}>
          <div className="paHedFilter">
            <Button
              role="button"
              bsPrefix=" "
              className="myBt rclose"
              title={tr["Delete"]}
              onClick={onDeleteClick}
            >
              <HiOutlineTrash/>
            </Button>
            <Link
              bsPrefix=" "
              id="hdID"
              to="javascript:void(0)"
              className="myBt plus ctrlKeyPrevent"
              disabled={props.loader}
              onClick={handleSubmit(submit)}
              title={tr["Save"]}
              ref={saveObjRef}
            >
              <span>{props.loader ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/>}</span>
            </Link>
            <Link
              bsPrefix=" "
              to="/location"
              className="myBt cancel fillBtn ctrlKeyPrevent"
              title={tr["Cancel"]}
              ref={closeButton}
            >
              <IoClose/>
            </Link>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default reduxForm({
  form: "EditLocationForm",
})(HeaderSec);
