
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { useGetGovAudiLogsQuery } from "../../../../../../reducers/governance/meeting/meeting";
import ListLoader from "../../../../../common/loaders/ListLoader";
import WorkNotes from "../WorkNotes/add-edit";
import OccurrenceAuditLogs from "../AuditHistory";

const ActivityDetails = ({ occurenceData, companyId }) => {
  const [tabKey, setTabKey] = useState(1);
  const [orderValue, setOrderValue]=useState("desc");
  const meetingId = occurenceData?.meetingDefinationId;
  const occurrananceId = occurenceData?.meetingOccurrananceId;

  const {
    data: auditData,
    isFetching,
    refetch: auditDataRefetch,
  } = useGetGovAudiLogsQuery(
    {
      queryString: `?definationId=${meetingId}&tenantId=${companyId}&module=occurrence&occurrananceId=${occurrananceId}&orderBy=${orderValue}`,
    },
    {
      skip: !(occurrananceId && companyId),
    }
  );

  const handleSelect = (tabKey) => {
    setTabKey(tabKey);
    auditDataRefetch();
  };

  return (
    <div className="panel-body attachment-body">
      <div className="custom-container-fluid">
        <div className="stickyArea rBoxStyle">
          <div className="rPageHeading d-flex p-3 align-items-center">
            <div className="flex-grow-1 offNam mw-100">Activity Details</div>
          </div>
            <div className="rBoxGap">
              <div className="rBoxTabs">
                <Tabs activeKey={tabKey} onSelect={handleSelect} id="tabId">
                  <Tab eventKey={1} title="Comments">
                    <WorkNotes
                      meetingId={meetingId}
                      occurrananceId={occurrananceId}
                      companyId={companyId}
                    />
                  </Tab>
                  <Tab eventKey={6} title="Audit Log">
                    {isFetching ? (
                      <ListLoader />
                    ) : (
                      <OccurrenceAuditLogs data={auditData} setOrderValue={setOrderValue}/>
                    )}
                  </Tab>
                </Tabs>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityDetails;
