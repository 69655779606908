
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState} from "react";
import { Row, Col, Button } from 'react-bootstrap';
import {useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { getOrganizationList } from "../../../../actions/foundation/organizationAction";
import {HiPlus, HiOutlineRefresh} from "react-icons/hi";

const HeaderSec = (props) => {
    const tr = useSelector((state) => state.spcmReducer.tr);
    const dispatch = useDispatch()
    const refreshOrgData = () => {
		dispatch(getOrganizationList());
	}
    return (
    <Row className="margin-b-15">
        <Col lg={4} md={6} sm={6} xs={12}>
            <h1 bsClass="" className="sPageHeading1">{tr["Organization"]}</h1>
        </Col>
        <Col lg={8} md={6} sm={6} xs={12}>
            <div className="paHedFilter">
                <Link to="/createOrganization" role="button" className="myBt plus fillBtn" title={tr["Create"]} ><HiPlus/></Link>
                <Button bsPrefix=' ' title={tr["Refresh"]} role="button" onClick={refreshOrgData} className="myBt"><HiOutlineRefresh className="refreshFlip" /></Button>
            </div>
        </Col>
    </Row>
    )
}

export default HeaderSec;