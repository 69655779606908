
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import rootReducer from '../reducers/index';
import thunk from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';

export default function configureStoreFunc(){
  return configureStore({ 
    reducer: rootReducer,
    middleware: [thunk]
  })
}
