
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import { useSelector,useDispatch } from "react-redux";
import { useParams } from "react-router";
import { change, Field, reduxForm } from 'redux-form';
import axios from 'axios';
const api = axios.create({
    headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});
import PindCategories from "../../../../common/header/pind-categories";
import Breadcrumbs from "../../../../common/header/breadcrumbs";
import Header from "./header";
import FormComponent from "./form";
import TabsComponent from "./tabs";
import {getUserInformationListAction, getEditedUserDetails,getCompanyBasedUserInfoAction,getComapanyBasedDepartment,getOrgInfoMapList} from "../../../../../actions/foundation/userAction";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import "_Css/form/_form.scss";

const Index = () => {
  const [isRightPage, setRightPage] = useState('');
  const tr = useSelector((state) => state.spcmReducer.tr);
  const EDITUSERData = useSelector((state) => state.editDetailsRed);
  const [firstNameErr,setFirstName] = useState('');
  const [userNameErr, setUsernameErr] = useState('');
  const [middlenameErr, setMiddlenameErr] = useState('');
  const [lastnameErr, setLastnameErr] = useState('');
  const [emailErr, setEmailErr] = useState('');
  const [timeZoneErr, setTimeZoneErr] = useState('');
  const [userTypeErr, setUserTypeErr] = useState('');
  const [languageErr, setLanguageErr] = useState('');
  const [statusErr, setStatusErr] = useState('');
  const [authSource, setAuthSource] = useState('');
  const [dateFormErr, setDateFormErr] = useState('');
  const [currencyErr, setCurrencyErr] = useState('');
  const [companyValue, setCompanyValue] = useState([]);
  const [companyTypeErr, setCompanyTypeErr] = useState('');
  const [companyType,setCompanyType] = useState([]);
  const [orgzErr, setOrgzErr] = useState('');
  const [depErr, setDepErr] = useState('');
  const [businessErr, setBusinessErr] = useState('');
  const [mobileErr, setMobileErr] = useState('');
  const [contactErr, setcontactErr] = useState(''); 
  const [locationErr, setLocationErr] = useState('');
  const [ccErr, setCCErr] = useState('');
  const [cwlErr, setCWLErr] = useState(''); 
  const [fileURL, setFileURL] = useState('');
  const [resetPasswordVal, setResetPasswordVal] = useState();
  const [autoGeneratePasswordVal, setAutoGeneratePasswordVal] = useState();
  const [forcePasswordChangeVal, setforcePasswordChangeVal] = useState();
  const [isOrgInfoMandatory, setOrgInfoMandatory] = useState(true);
  const [profilePassErr, setProfilePassErr] = useState('');
  const [panelResize, setPanelResize] = useState('');
  const [isRightSideVisible, setRightSideVisible] = useState(true);
  const dispatch = useDispatch();
  const params = useParams();
  let id = params.id;

  const setCompanyErrorColor = (err) => {
    if (companyValue.length == 1) {
      setCompanyTypeErr('');
    } else {
      setCompanyTypeErr(err);
    }
  };
  const showRightSideFun = (val) => {
    setRightSideVisible(val);
  };
  const companyTypeFun = (list) =>{
    console.log("LALALA1",list);
    setCompanyType(list);

  };
  const setLocationErrColor = (err) => {
    setLocationErr(err);
  };
  const setCCErrColor = (err) => {
    setCCErr(err);
  };
  const setCWLColorErr = (err) => {
    setCWLErr(err);
  };
  const setProfileErr = (err) => {
    setProfilePassErr(err);
  };
  // const renderPage = (pageName) => {
  //   switch(pageName) {
  //     case 'TabsComponent':
  //       return <TabsComponent />;
  //     default:
  //       return <TabsComponent />;
  //   }
  // }
  useEffect(() => {
    dispatch(getUserInformationListAction());   
    // setCompanyType([{id:3217,label:'HCL'}]);

  }, [])

  const updateCompanyOrgInfoReq = (id, type = '') => {
    try{
      if(type == 'clear'){
        setOrgInfoMandatory(true);
        return;
      }
      api.get("/api/editCompany/" + id)
      .then((response) => {
        // console.log('response - ', response);
        const companyType = response?.data?.company?.companyType;
        if( companyType && Array.isArray(companyType) ){
          if(companyType.length > 0){
            const found = companyType.find(item => item.toLowerCase() == 'service consumer');
            setOrgInfoMandatory(!!found);
          }
          else{
            setOrgInfoMandatory(false);
          }
        }
        else{
          setOrgInfoMandatory(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    }catch(e){
      console.error(e);
    }
  };

  const validatePassword = (val) => {
    try{
      if(!(val && typeof  val == 'string' && val.trim().length > 0)){
        return false;
      }
      const oneCapitalLetter = /[A-Z]/;
      const oneNumber = /.*[0-9].*/;
      const specialChar = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      const value = val.trim();
      if(value.length < 8){
        return false;
      }
      if( oneCapitalLetter.test(value) && oneNumber.test(value) && specialChar.test(value) ){
        return true;
      }
      return false;
    }catch(e){
      console.error(e);
      return false;
    }
  }

  const updateAllData = () => {
    try{
      // console.log('*********', {id, EDITUSERData});
      dispatch(getEditedUserDetails(id));
      dispatch(getOrgInfoMapList(id));
      if(EDITUSERData?.companyId){
        dispatch(getComapanyBasedDepartment(EDITUSERData.organizationId));        
        dispatch(getCompanyBasedUserInfoAction(EDITUSERData.companyId));
        dispatch(change("EditUserform", "locationName", EDITUSERData.locationName));
        dispatch(change("EditUserform", "locationId", EDITUSERData.locationId));
        dispatch(change("EditUserform", "costcenterName", EDITUSERData.costcenterName));
        dispatch(change("EditUserform", "costcenterId", EDITUSERData.costcenterId));
        dispatch(change("EditUserform", "cwlName", EDITUSERData.cwlName));
        dispatch(change("EditUserform", "cwlId", EDITUSERData.cwlId));
        dispatch(change("EditUserform", "managerName", EDITUSERData.managerName));
        dispatch(change("EditUserform", "managerId", EDITUSERData.managerId));
      } 

    }catch(e){
      console.error(e);
    }
  }

  console.log("isRightPage",isRightPage);
  // console.log('EDITUSERData - ', EDITUSERData);

  return (
    <main>
      <PindCategories />
      
      <Container fluid className="margin-t-10">
        <div className="margin-b-15">
          <Breadcrumbs activePageName={tr["Edit"]} parentPageurl='/user' parentPageName={tr["User"]} />
        </div>
        <Header 
        setRightPage={setRightPage} 
        setFirstName={setFirstName}
        setUsernameErr={setUsernameErr}
        setMiddlenameErr={setMiddlenameErr} 
        setLastnameErr={setLastnameErr}
        setEmailErr={setEmailErr}
        setUserTypeErr={setUserTypeErr}
        setLanguageErr={setLanguageErr}
        setAuthSource={setAuthSource}
        setTimeZoneErr={setTimeZoneErr}
        setDateFormErr={setDateFormErr}
        setStatusErr={setStatusErr}
        setCurrencyErr={setCurrencyErr}
        companyValue={companyValue}
        setCompanyValue={setCompanyValue}
        setCompanyErrorColor={setCompanyErrorColor}
        companyType={companyType}
        setOrgzErr={setOrgzErr}
        setDepErr={setDepErr}
        setBusinessErr={setBusinessErr}
        setMobileErr={setMobileErr}
        setcontactErr={setcontactErr}
        setLocationErr={setLocationErr}
        setLocationErrColor={setLocationErrColor}
        etCCErr={setCCErr}
        setCCErrColor={setCCErrColor}
        setCWLErr={setCWLErr}
        setCWLColorErr={setCWLColorErr}
        setFileURL={setFileURL}
        isOrgInfoMandatory={isOrgInfoMandatory}
        forcePasswordChangeVal={forcePasswordChangeVal}
        resetPasswordVal={resetPasswordVal}
        autoGeneratePasswordVal={autoGeneratePasswordVal}
        setProfileErr={setProfileErr}
        validatePassword={validatePassword}
        updateAllData={updateAllData}
        isRightSideVisible={isRightSideVisible}
        showRightSideFun={showRightSideFun}
        EDITUSERData={EDITUSERData}
        />
        <PanelGroup direction="horizontal">
          <Panel id="sidebar" minSize={33} order={1} onResize={(size) => setPanelResize(size)} defaultSize={isRightSideVisible ? 67 : 100} className={isRightSideVisible ? "isShowLeftPanel catBordDv" : "catBordDv"}>
          <FormComponent 
            setEmailErr={setEmailErr}
            emailErr={emailErr}
            setFirstName={setFirstName}
            setMiddlenameErr={setMiddlenameErr} 
            setUsernameErr={setUsernameErr} 
            setLastnameErr={setLastnameErr} 
            firstNameErr={firstNameErr}
            middlenameErr={middlenameErr}
            userNameErr={userNameErr}
            lastnameErr={lastnameErr}
            setLanguageErr={setLanguageErr}
            setAuthSource={setAuthSource}
            authSource={authSource}
            languageErr={languageErr}
            setTimeZoneErr={setTimeZoneErr}
            timeZoneErr={timeZoneErr}
            setStatusErr={setStatusErr}
            statusErr={statusErr}
            setDateFormErr={setDateFormErr}
            dateFormErr={dateFormErr}
            setCurrencyErr={setCurrencyErr}
            currencyErr={currencyErr}
            setUserTypeErr={setUserTypeErr}
            userTypeErr={userTypeErr}
            companyValue={companyValue}
            setCompanyValue={setCompanyValue}
           setCompanyErrorColor={setCompanyErrorColor}
           companyTypeErr={companyTypeErr}
           companyTypeFun = {companyTypeFun}  
           setOrgzErr={setOrgzErr}
            orgzErr={orgzErr}
            setDepErr={setDepErr}
            depErr={depErr}
            setBusinessErr={setBusinessErr}
            businessErr={businessErr}
            setMobileErr={setMobileErr}
            mobileErr={mobileErr}
            setcontactErr={setcontactErr}
            contactErr={contactErr}
            locationErr={locationErr}
            setLocationErrColor={setLocationErrColor}
            setCCErr={setCCErr}
            setCCErrColor={setCCErrColor}
            ccErr={ccErr}
            setCWLErr={setCWLErr}
            setCWLColorErr={setCWLColorErr}
            cwlErr={cwlErr}
            setFileURL={setFileURL}
            fileURL={fileURL}
            isOrgInfoMandatory={isOrgInfoMandatory}
            setResetPasswordVal={setResetPasswordVal}           
            setAutoGeneratePasswordVal={setAutoGeneratePasswordVal}
            setforcePasswordChangeVal={setforcePasswordChangeVal}
            forcePasswordChangeVal={forcePasswordChangeVal}
            resetPasswordVal={resetPasswordVal}
            autoGeneratePasswordVal={autoGeneratePasswordVal}
            updateCompanyOrgInfoReq={updateCompanyOrgInfoReq}
            profilePassErr={profilePassErr}
            setProfileErr={setProfileErr}
            validatePassword={validatePassword}
            showRightSideFun={showRightSideFun}            
            />
          </Panel>
          {isRightSideVisible ?
            <>
              <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
              <Panel minSize={33} order={2} defaultSize={33}>
                <div className="stickyArea rBoxStyle">
                  <TabsComponent 
                    companyType ={companyType}  
                    showRightSideFun={showRightSideFun}      
                    />
                </div>
              </Panel>
            </> : ""
          }
        </PanelGroup>
      </Container>
    </main>
  )
}

export default Index;
