
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

export function getMultiTenantLogo(){
	return(dispatch) =>{
		api.get('/api/getMultiSSOLogo')
			.then((multiTenantLogo) =>{
				dispatch({
					type: 'MULTI_TENANT_LOGO',
					multiTenantLogo
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
}
