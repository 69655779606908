
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Row, Col, Form, Alert, ButtonToolbar, Button } from 'react-bootstrap';
import { _textArea, _dropDown, _inputField, _radio, _checkbox, _ckeditor } from '../../common/formFields';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import axios from 'axios';
import { spcmLoader, setLeftEditId, getOrderingPreferences, getAllServicesList, getDisclaimiers } from '../../../actions/spcmActions';
import swal from 'sweetalert';
import {ImSpinner6} from "react-icons/im";

class ComponentForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentForm: "approver_disclaimer"
		}
		this.submitForm = this.submitForm.bind(this);
		this.getFormHandler = this.getFormHandler.bind(this);
		this.handleChangeEvent = this.handleChangeEvent.bind(this);
	}

	componentDidMount() {
		console.log(this.props.initialValues);
	}

	componentWillUnmount() {
		this.props.spcmLoader('attribute', 'success');
	}

	getFormHandler(e) {
		this.setState({ currentForm: e });
	}

	handleChangeEvent(e){
		console.log("sd");
		console.log(e);
	}

	// componentWillMount() {
	// 	this.props.getDisclaimiers(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
	// }

	submitForm(values) {
		console.log('console values');
		console.log(values);

		if(values.APPROVER_DISCLAIMER){
			values.APPROVER_DISCLAIMER = values.APPROVER_DISCLAIMER.replace(/<[^>]+>/g, '').trim() == '' ? '' : values.APPROVER_DISCLAIMER;
		}

		if(values.SERVICE_DISCLAIMER){
			values.SERVICE_DISCLAIMER = values.SERVICE_DISCLAIMER.replace(/<[^>]+>/g, '').trim() == '' ? '' : values.SERVICE_DISCLAIMER.trim();
		}

		let valuesObj={};
		let OrderingPrefValues={};
		valuesObj.offering_id = this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID;
		OrderingPrefValues.offering_id = this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID;
		if (this.state.currentForm == "approver_disclaimer") {
			valuesObj.approver_disclaimer = values.APPROVER_DISCLAIMER.trim();
			// return swal({
			// 	text: "Do you want the disclaimer to be enabled from now on?",
			// 	buttons: ["Yes", "No"]
			// })
			// .then((willDelete) => {
			// 	if (willDelete) {
			// 		//TODO when pressed no
			// 		OrderingPrefValues.hide_approver_disclaimer = "true";
			// 	} else {
			// 		//TODO when pressed yes
			// 		OrderingPrefValues.hide_approver_disclaimer = "false";
			// 	}
			// })
			// .then(() => {
				this.props.spcmLoader('attribute', 'posting');
				// return axios.patch(constants.SERVICEPATCH_API_URL + "/" + this.props.spcmReducer.editId, valuesObj)
				if(this.props.spcmReducer.spcmDisclaimers.length > 0) {
					let id = this.props.spcmReducer.spcmDisclaimers[0].ID;
					return axios.patch('/api/serviceDisclaimers/'+id, valuesObj)
					.then((response)=>{
						this.props.getDisclaimiers(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
						return swal({
							text: this.props.tr['Do you want the disclaimer to be enabled from now on?'],
							buttons: [this.props.tr['Yes'], this.props.tr['No']]
						})
						.then((willDelete) => {
							if (willDelete) {
								//TODO when pressed no
								OrderingPrefValues.hide_approver_disclaimer = "true";
							} else {
								//TODO when pressed yes
								OrderingPrefValues.hide_approver_disclaimer = "false";
							}
						})
					})
					.then((response) =>{
						// this.props.getDisclaimiers(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
						// this.props.spcmLoader('attribute', 'posting');
						return axios.patch("/service/api/orderPreferences", OrderingPrefValues)
						.then((response) => {
							this.props.getOrderingPreferences(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
							this.props.boardPageInActive4(false);
							this.props.spcmLoader('attribute', 'success');
						})
						.catch((error) => {
							this.props.spcmLoader('attribute', 'success');
							console.log(error);
							throw new SubmissionError({ _error: error.response.data.message });
						});
					})
					.catch((error) => {
						this.props.spcmLoader('attribute', 'success');
						// console.log("patch");
						// console.log('---------------error----------');
						// console.log(error.response.data[0].message);
						console.log(error);
						throw new SubmissionError({ _error: error.response.data[0].message });
					});
				}
				else {
					return axios.post('/api/serviceDisclaimers', valuesObj)
					.then((response)=>{
						this.props.getDisclaimiers(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
						return swal({
							text: this.props.tr['Do you want the disclaimer to be enabled from now on?'],
							buttons: [this.props.tr['Yes'], this.props.tr['No']]
						})
						.then((willDelete) => {
							if (willDelete) {
								//TODO when pressed no
								OrderingPrefValues.hide_approver_disclaimer = "true";
							} else {
								//TODO when pressed yes
								OrderingPrefValues.hide_approver_disclaimer = "false";
							}
						})
					})
					.then((response) =>{
						// this.props.getDisclaimiers(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
						// this.props.spcmLoader('attribute', 'posting');
						return axios.patch("/service/api/orderPreferences", OrderingPrefValues)
						.then((response) => {
							this.props.getOrderingPreferences(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
							this.props.boardPageInActive4(false);
							this.props.spcmLoader('attribute', 'success');
						})
						.catch((error) => {
							this.props.spcmLoader('attribute', 'success');
							console.log(error);
							throw new SubmissionError({ _error: error.response.data.message });
						});
					})
					.catch((error) => {
						this.props.spcmLoader('attribute', 'success');
						// console.log("post");
						//  console.log('---------------error----------');
						//  console.log(error.response.data[0].message);
						console.log(error);
						throw new SubmissionError({ _error: error.response.data[0].message });
					});
				}
			// })
		}
		else {
			valuesObj.service_disclaimer = values.SERVICE_DISCLAIMER.trim();
			// return swal({
			// 	text: "Do you want the disclaimer to be enabled from now on?",
			// 	buttons: ["Yes", "No"]
			// })
			// .then((willDelete) => {
			// 	if (willDelete) {
			// 		//TODO when pressed no
			// 		OrderingPrefValues.hide_service_disclaimer = "true";
			// 	} else {
			// 		//TODO when pressed yes
			// 		OrderingPrefValues.hide_service_disclaimer = "false";
			// 	}
			// })
			// .then(() => {
				this.props.spcmLoader('attribute', 'posting');
				// return axios.patch(constants.SERVICEPATCH_API_URL + "/" + this.props.spcmReducer.editId, valuesObj)
				if(this.props.spcmReducer.spcmDisclaimers.length > 0) {
					let id = this.props.spcmReducer.spcmDisclaimers[0].ID;
					return axios.patch('/api/serviceDisclaimers/'+id, valuesObj)
					.then((response)=>{
						this.props.getDisclaimiers(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
						return swal({
							text: this.props.tr['Do you want the disclaimer to be enabled from now on?'],
							buttons: [this.props.tr['Yes'], this.props.tr['No']]
						})
						.then((willDelete) => {
							if (willDelete) {
								//TODO when pressed no
								OrderingPrefValues.hide_service_disclaimer = "true";
							} else {
								//TODO when pressed yes
								OrderingPrefValues.hide_service_disclaimer = "false";
							}
						})
					})
					.then((response) =>{
						// this.props.getDisclaimiers(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
						// this.props.spcmLoader('attribute', 'posting');
						return axios.patch("/service/api/orderPreferences", OrderingPrefValues)
						.then((response) => {
							this.props.getOrderingPreferences(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
							this.props.boardPageInActive4(false);
							this.props.spcmLoader('attribute', 'success');
						})
						.catch((error) => {
							this.props.spcmLoader('attribute', 'success');
							console.log(error);
							throw new SubmissionError({ _error: error.response.data.message });
						});
					})
					.catch((error) => {
						this.props.spcmLoader('attribute', 'success');
						// console.log('---------------error----------');
						// console.log(error.response.data[0].message);
						console.log(error);
						throw new SubmissionError({ _error: error.response.data[0].message });
					});
				}
				else {
					return axios.post('/api/serviceDisclaimers', valuesObj)
					.then((response)=>{
						this.props.getDisclaimiers(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
						return swal({
							text: this.props.tr['Do you want the disclaimer to be enabled from now on?'],
							buttons: [this.props.tr['Yes'], this.props.tr['No']]
						})
						.then((willDelete) => {
							if (willDelete) {
								//TODO when pressed no
								OrderingPrefValues.hide_service_disclaimer = "true";
							} else {
								//TODO when pressed yes
								OrderingPrefValues.hide_service_disclaimer = "false";
							}
						})
					})
					.then((response) =>{
						// this.props.getDisclaimiers(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
						// this.props.spcmLoader('attribute', 'posting');
						return axios.patch("/service/api/orderPreferences", OrderingPrefValues)
						.then((response) => {
							this.props.getOrderingPreferences(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
							this.props.boardPageInActive4(false);
							this.props.spcmLoader('attribute', 'success');
						})
						.catch((error) => {
							this.props.spcmLoader('attribute', 'success');
							console.log(error);
							throw new SubmissionError({ _error: error.response.data.message });
						});
					})
					.catch((error) => {
						this.props.spcmLoader('attribute', 'success');
						// console.log('---------------error----------');
						// console.log(error.response.data[0].message);
						console.log(error);
						throw new SubmissionError({ _error: error.response.data[0].message });
					});
				}
			// })
		}

		// this.props.setLeftEditId(-1);

	}


	render() {

		// console.log('spcmDisclaimers====',this.props.spcmReducer.spcmDisclaimers);
		// console.log('initialValues====',this.props.initialValues);

		const tr = this.props.tr;
		const { error, handleSubmit } = this.props;
		console.log(this.props.spcmReducer);
		console.log("states");
		console.log(this.state);
		console.log("props");
		console.log(this.props);
		return (
			<div className="rBoxGap">
				{error == undefined || error == null ? null : <Alert variant="danger" className="errorMessageSec"><p>{error}</p></Alert>}

				<Row className="borderForm  row">
					<Col xs={12}>
						<Form.Group className="radioInlineBox formSelectionRadioBox form-group">
							<div className="radioInlineBox float-l radioInlineRightMarg">
								<label className="radio-inline"><input type="radio" name='req_info_chk' value='approver_disclaimer' onChange={(event) => { this.getFormHandler(event.target.value) }} checked={this.state.currentForm == 'approver_disclaimer'} />{tr['Approver Disclaimer']}</label>
								<label className="radio-inline"><input type="radio" name='req_info_chk' value='service_disclaimer' onChange={(event) => { this.getFormHandler(event.target.value) }} checked={this.state.currentForm == 'service_disclaimer'} />{tr['Service Disclaimer']}</label>

							</div>
						</Form.Group>
					</Col>
				</Row>

				<form onSubmit={handleSubmit(this.submitForm)}>

					{this.state.currentForm == "approver_disclaimer" ?

						<div>
							<Row>
								<Col md={12}>
									<Form.Group className='form-group'>
										<Form.Label bsClass="">{tr['Approver Disclaimer']}</Form.Label>
										<Field name="APPROVER_DISCLAIMER" component={_ckeditor} className="reactQuillEditor-area" maxlength={1500} richTextConfig ={"minRichTextEditor"}/>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col md={12}>
									<ButtonToolbar className="black">
										<Button className='rgSidrkBtn' title="Save" disabled={this.props.spcmReducer.spcmPostingAttribute} type="submit" bsSize="small" bsStyle="primary">{this.props.spcmReducer.spcmPostingAttribute ? <ImSpinner6 className="icn-spinner"/> : null} {tr['Save']}</Button>
										<Button className='rgSiLigBtn' title="Close" onClick={() => { this.props.boardPageInActive4(false); }} bsSize="small" bsStyle="text">{tr['Close']}</Button></ButtonToolbar>
								</Col>
							</Row>
						</div>
						:
						<div>
							<Row>
								<Col md={12}>
									<Form.Group className='form-group'>
										<Form.Label bsClass="">{tr['Service Disclaimer']}</Form.Label>
										<Field name="SERVICE_DISCLAIMER" component={_ckeditor} className="reactQuillEditor-area" maxlength={1500} richTextConfig ={"minRichTextEditor"}/>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col md={12}>
									<ButtonToolbar className="black">
										<Button className='rgSidrkBtn' title="Save" disabled={this.props.spcmReducer.spcmPostingAttribute} type="submit" bsSize="small" bsStyle="primary">{this.props.spcmReducer.spcmPostingAttribute ? <ImSpinner6 className="icn-spinner"/> : null} {tr['Save']}</Button>
										<Button className='rgSiLigBtn' title="Close" onClick={() => { this.props.boardPageInActive4(false); }} bsSize="small" bsStyle="text">{tr['Close']}</Button></ButtonToolbar>
								</Col>
							</Row>
						</div>
					}

				</form>
			</div>
		);
	}
}

const mapStateToProps = ({ spcmReducer }) => {

	return {
		spcmReducer,
		initialValues: spcmReducer.spcmDisclaimers.length > 0 ? spcmReducer.spcmDisclaimers[0] : null
	}
};

ComponentForm = reduxForm({
	form: 'disclaimersForm',
	enableReinitialize: true
})(ComponentForm)
export default connect(mapStateToProps, { spcmLoader, getOrderingPreferences, setLeftEditId, getAllServicesList, getDisclaimiers })(ComponentForm);
