
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import moment from "moment";
import momenttz from "moment-timezone";
import Cookies from "universal-cookie";
import { useSelector, useDispatch } from "react-redux";
import Accordion from "react-bootstrap/Accordion";

import { getNotificationByWorkItemId } from "../../../../actions/task/TaskDataAction.js";
import ListLoader from "../../../../components/common/loaders/ListLoader";
import datetimeConvertor from "../../../../helpers/ISO8601converter";
import NotificationAccord from "./notificationAccord.js";

const cookies = new Cookies();

let timezone = cookies.get("gph");
if (timezone) timezone = timezone.replace("s:", "");
if (timezone) timezone = timezone.substring(0, timezone.lastIndexOf("."));
timezone = timezone.split("~");
timezone = timezone[3];

let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

function WIBNotification(props) {
  const NotificationData = useSelector((state) => state.NotificationListByWID);
  const showLoader_ne = useSelector((state) => state.showLoader_ne.loading);
  const tr = useSelector((state) => state.spcmReducer.tr);
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      props.requestNumber != "" &&
      typeof props.requestNumber != "undefined" &&
      props.orderNumber != null
    ) {
      dispatch(
        getNotificationByWorkItemId(props.requestNumber, props.orderNumber)
      );
    }
  }, [props.requestNumber]);

  const convertToStandardDateTime = (actualdate, type) => {
    if (type == "ISOSTRING") {
      let date = moment(actualdate);
      let dateComponent = date.utc().format("YYYY-MM-DD");
      let timeComponent = date.utc().format("HH:mm:ss");
      return {
        fulldate: `${dateComponent} ${timeComponent}`,
      };
    }
  };

  const renderNotificationData = (data) => {
    if (data && data.length > 0) {
      return data.map((notif, index) => {
        let actDateTimeFormat = jwtdf + " hh:mm A";
        let myDateObj,
          endDate,
          endDateStr,
          timeago,
          startTime,
          duration,
          hours,
          isoDate;
        let date = momenttz(notif?.CREATED_ON)
          .tz(timezone)
          .format(jwtdf + " HH:mm:ss");
        isoDate = datetimeConvertor(date, jwtdf + " HH:mm:ss");

        myDateObj = moment(isoDate).format(actDateTimeFormat);

        endDate = moment(myDateObj, actDateTimeFormat);
        endDateStr = endDate._i;
        startTime = moment(
          new Date().toLocaleString("en-US", { timeZone: timezone })
        );
        timeago = moment(endDateStr, actDateTimeFormat).from(startTime);
        duration = moment.duration(startTime.diff(endDate));
        hours = duration.asHours();

        return (
          <NotificationAccord
            hours={hours}
            endDateStr={endDateStr}
            timeago={timeago}
            notif={notif}
            index={index}
          />
        );
      });
    }
  };

  return (
    <div class="py-2">
      <div className="rBoxTabs hideTabNav">
        {(NotificationData && NotificationData.length < 0) || showLoader_ne ? (
          <div className="text-center py-2">
            <ListLoader />
          </div>
        ) : NotificationData?.length === 0 ? (
          <div className="text-center py-2">
              There is no data available
          </div>
        ) : (
          <Accordion accordion className="brkFix brkFixOrder margin-t-10">
            {renderNotificationData(NotificationData)}
          </Accordion>
        )}
      </div>
    </div>
  );
}

export default WIBNotification;
