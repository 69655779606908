
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { browserHistory } from 'react-router';
import { Link, useNavigate } from "react-router-dom";
import CreateCategoryHeader from './CreateBusinessFunctionHeader.js';
import CreateCategoryForm from './CreateBusinessFunctionForm.js';
import { getTranslation } from '_Actions/spcmActions';
import { connect, useSelector } from 'react-redux';
import {GLOBAL} from '_Globals';
import Breadcrumbs from "../../common/header/breadcrumbs.js";
import Cookies from 'universal-cookie';
import { reduxForm } from 'redux-form';
import "_Css/form/_form.scss";
const cookies = new Cookies();
let homepagelocation = cookies.get('gph');
if (homepagelocation) homepagelocation = homepagelocation.replace('s:', '');
if (homepagelocation) homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home") homepagelocation = "/" + homepagelocation;


const CreateBusinessFunctionIndex = () => {
	const [actionView, setActionView] = useState("assetgraph");
	const translator = useSelector((state) => state.spcmReducer.tr);
  
	return (
	  <div>
		<div>
		  <div className="container-fluid padding-t-10 margin-b-15">
			<Breadcrumbs
			  activePageName={"Create"}
			  parentPageurl="/businessFunction"
			  parentPageName={translator["Business Function"]}
			/>
		  </div>
		  <div className="container-fluid" bsClass="">
			<Form>
			  <CreateCategoryHeader
				changeActionView={setActionView}
				translator={translator}
			  />
			  <Row className="row-eq-height">
				<Col md={8} xs={12}>
				  <CreateCategoryForm translator={translator} />
				</Col>
			  </Row>
			</Form>
		  </div>
		</div>
	  </div>
	);
  };
  
  export default CreateBusinessFunctionIndex;