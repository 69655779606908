
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Row, Col, Form, Button, Table, ControlLabel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { setEditServiceDetails } from '../../../actions/spcmActions';
import {getCmdbApprovals} from '../../../actions/cmdb/lovAction';
import axios from 'axios';
import Pagination from "react-js-pagination";
import CmdbCiApprovalsForm from "./CmdbCiApprovalsForm.js";
import ListLoader from "../../common/loaders/ListLoader"
import { SubmissionError } from 'redux-form';
import Swal from 'sweetalert2';
import {IoSearch} from 'react-icons/io5';

class CmdbApprovalList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            queryBy: 'Role',
            query: '',
            sortBy: 'Role',
            pageSize: 10,
            showError: false,
            error: 'Changes are not allowed in Deployed status',
            sortByTitle: '',
            currentForm: 'approval_form',
            extraCounts: [0],
            searchBy:"",
            searchByVal:"",
            page:1,
            editToggle:false
        }
        this.onTextChange = this.onTextChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onPageSizeChange = this.onPageSizeChange.bind(this);
        this.onSearchClick = this.onSearchClick.bind(this);
        this.queryBySelect = this.queryBySelect.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.sortHover = this.sortHover.bind(this);
        this.setExtraCount = this.setExtraCount.bind(this);
        this.editToggleFunc=this.editToggleFunc.bind(this);
    }

    editToggleFunc(value,listvlue){
    let obj={};
        this.setState({editToggle:value});
    }
    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick();
            if (e.preventDefault) e.preventDefault();
        }
    }

    setExtraCount(e) {
        this.setState({ extraCounts: e });
    }

    onTextChange(e) {
        this.setState({ searchByVal: e.target.value });
    }

    onPageSizeChange(e) {
        this.setState({ pageSize: e.target.value });
        this.props.getCmdbApprovals(this.props.CI_ID, this.state.searchBy, this.state.searchByVal, this.state.page, e.target.value);
    }

    onPageChange(e) {
        this.props.getCmdbApprovals(this.props.CI_ID, this.state.searchBy, this.state.searchByVal,  e, this.state.pageSize);

    }

    onSearchClick() {
        this.props.getCmdbApprovals(this.props.CI_ID, this.state.searchBy, this.state.searchByVal,this.state.page,this.state.pageSize );
    }

    queryBySelect(e) {
        this.setState({ searchBy: e.target.value });
    }

    sortHover(e) {
        let index = e.nativeEvent.target.selectedIndex;
        this.setState({ sortByTitle: e.nativeEvent.target[index].text })
    }


    onDeleteClick(values) {
        Swal.fire({
            title: "Are you sure you wish to delete this item?",
		                
            width: 550,
            padding: '15px',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
          }).then((result) => {
            if (result.isConfirmed) {
                values.status = 0;
                axios.patch("/service/api/approval/editApprovalRule/" + values.ruleId, values).then((response) => {
                    this.props.getCmdbApprovals(this.props.CI_ID, this.state.searchBy, this.state.searchByVal, this.state.page, this.state.pageSize);
                })
                .catch((error) => {
                    throw new SubmissionError({ _error: error.response.data[0].message });
                });
            } else if (result.isDenied) {
              
            }
          })
    }

    componentWillMount() {
        this.props.getCmdbApprovals(this.props.CI_ID,this.state.searchBy, this.state.searchByVal, this.state.page,this.state.pageSize);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.CI_ID && nextProps.CI_ID != this.props.CI_ID) {
            this.props.getCmdbApprovals(nextProps.CI_ID,this.state.searchBy, this.state.searchByVal, this.state.page,this.state.pageSize);
            this.props.toggleFormListFunc(false);
        }
    }

    render() {
        var approvals = this.props.spcmReducer.approval;
		var meta = this.props.spcmReducer.approvalMeta;
        return (
            <div className="">
                    <div className="rPageHeading">
                        {this.props.toggleFormList ?"":<Button
                            disabled={this.state.fulfilmentBtnState}
                            onClick={() => { this.props.toggleFormListFunc(true);this.editToggleFunc(false); }}
                            title={this.props.tr["Add"]}
                            bsSize="xsmall"
                            bsStyle="primary"
                            className="float-r">
                            {this.props.tr["Add"]}
                        </Button>}
                        {this.props.tr["Approvals"]}
                    </div>
                {this.props.toggleFormList==true ? <CmdbCiApprovalsForm setExtraCount={this.setExtraCount} CLASS_ID={this.props.CLASS_ID} searchBy={this.state.searchBy} searchByVal={this.state.searchByVal} page={this.state.page} pageSize={this.state.pageSize} cmdbQuickItemList={this.props.cmdbQuickItemList} COMPANY_ID={this.props.COMPANY_ID} CI_NAME={this.props.CI_NAME} toggleFormList={this.props.toggleFormList} toggleFormListFunc={this.props.toggleFormListFunc} CLASS_NAME={this.props.CLASS_NAME} CI_ID={this.props.CI_ID} editToggle={this.state.editToggle} editToggleFunc={this.editToggleFunc}/>:
                
                
                <div>
                        <Row className="">
                            <Col md={8} sm={8} xs={12}>
                                <div className="form-inline rightSideFields display-inline-block">
                                    <Form.Control onMouseOver={this.sortHover} title={this.state.sortByTitle} className="myControl mySel mySelectWidth" componentClass="select" onChange={this.queryBySelect}>
                                        <option value="">Select</option>
                                        <option value="ruleName">Rule Name</option>
                                        <option value="class"> Class</option>
                                        <option value="assetName">Asset/CI Name</option>
                                        <option value="weightage">Weightage</option>
                                        <option value="module">Module</option>
                                    </Form.Control>
                                    <Form.Control onKeyPress={this.handleKeyPress.bind(this)} className="myControl myInput margin-l-5 myInputWidth" style={{width:"123px"}} type="text" onChange={this.onTextChange} />
                                    <Button type="submit" title="Search" onClick={this.onSearchClick} className="myBtn"><IoSearch/></Button>
                                </div>
                            </Col>
                            {approvals == "" || approvals == undefined ? null :
                            <Col md={4} sm={4} xs={12} className="text-r rightSideFields ">
                                <ControlLabel className="margin-r-5 display-inline-block widthAuto">{this.props.tr["Show"]}</ControlLabel>
                                <Form.Control className="padding-5 display-inline-block widthAuto" componentClass="select" onChange={this.onPageSizeChange}>
                                    <option value="10">10</option>
                                    <option value="25" >25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </Form.Control>
                            </Col>}
                        </Row>
                    <div className="f-size-12">
                        <Table bordered condensed hover responsive className="tableView  tableWithOutPointer">
                            <thead>
                                <tr>
                                    <th width="30%" className="sort">{this.props.tr["Rule Name"]}</th>
                                    <th width="20%" className="sort">{this.props.tr["Module"]}</th>
                                    <th width="20%" className="sort">{this.props.tr['Type']}</th>
                                    <th width="20%" className="sort">{this.props.tr['Weightage']}</th>
                                    <th width="10%" className="text-c">{this.props.tr["Action"]}</th>
                                </tr>
                            </thead>
                                {this.props.spcmReducer.spcmFetchingAttribute ?
                                    <tbody><td colSpan="3">
                                        <ListLoader />
                                    </td></tbody> :
                                    <tbody>
                                        {approvals == "" || approvals == undefined ? null : approvals.map(function (value, index) {
                                            return (<tr>
                                                <td>{value.ruleName}</td>
                                                <td>{value.moduleName}</td>
                                                <td>{value.approverType}</td>
                                                <td>{value.weightage}</td>
                                                <td><div className="text-c"><i title={this.props.tr["Edit"]} className="fa fa-pencil-square-o cursorPoint" onClick={(e) => { this.props.setEditServiceDetails(value); this.props.toggleFormListFunc(true); this.editToggleFunc(true) }}></i>
                                                    <i title={this.props.tr["Delete"]} className="margin-l-10 fa fa-trash-o cursorPoint" onClick={() => { this.onDeleteClick(value) }}></i></div></td>
                                            </tr>)
                                        }, this)}
                                        {
                                            approvals.length == 0 ? <tr><td colSpan={5} className="text-c">{this.props.tr['No Data Found']}</td></tr> : null
                                        }
                                    </tbody>
                                }
                        </Table>
                    </div>
                        {approvals == "" || approvals == undefined ? null :
                            <div className="text-c">
                                <nav aria-label='Pagination' className='display-inline-block'>
                                {meta ? 
                                <Pagination
                                    activePage={meta ? meta.currentPage : 1}
                                    itemsCountPerPage={this.state.pageSize}
                                    totalItemsCount={meta ? meta.rowCount : 1}
                                    onChange={this.onPageChange}
                                /> : null}
                                </nav>
                            </div>
                        }
                </div>}
            </div>
        );
    }
}
const mapStateToProps = ({ spcmReducer }) => {
    return {
        tr:spcmReducer.tr,spcmReducer

    }
};

export default connect(mapStateToProps,{getCmdbApprovals,setEditServiceDetails}) (CmdbApprovalList);