
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';

//let user_id=userDetails.user_id;
//let company_id=userDetails.company_id;
//let COMPANY_ID=company_id;
let CIM='CIM';
//let breakFixUrl =`https://xsm-f.mybluemix.net/groupListSelfService?companyId=${COMPANY_ID}&group=${CIM}`;
import { GLOBAL }  from '../../components/Globals';

export function loadBreakFixSupportCIMGroupSuccess(cimGroupDetails){
	return {
		type: 'LOAD_BREAKFIXSUPPORTCIMGROUP_SUCCESS',
		cimGroupDetails
	};
}

export function loadBreakFixSupportCIMGroupFailure(errorResponse){
	return {
		type: 'LOAD_BREAKFIXSUPPORTCIMGROUP_FAILURE',
		errorResponse
	};
}

export function loadBreakFixSupportCIMGroup(cimServiceId,type){
	let cimurl="";
	//if (type=="service")
	cimurl=GLOBAL.breakFixSGroupUrl+cimServiceId
	//else
	//cimurl=GLOBAL.breakFixSGroupNameUrl+cimServiceId


	//alert(cimServiceId)

	return(dispatch) =>{
		if(cimServiceId == "" || cimServiceId == undefined){

			}else{
				axios.get(cimurl)
					.then((cimGroupDetails)=>{
						if(!cimGroupDetails){
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							throw Error(cimGroupDetails.statusText);
						}
						return cimGroupDetails;
					})
					.then((cimGroupDetails) =>{
						dispatch(loadBreakFixSupportCIMGroupSuccess(cimGroupDetails))
					})
					.catch((err) => {
						console.log(err);
					});
			}
	};
}
