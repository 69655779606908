
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect } from "react";
import { Row, Col, ListGroup, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { PiPencilSimpleBold } from "react-icons/pi";
import { IoClose } from "react-icons/io5";

const ShortDescription = (props) => {
  const navigate = useNavigate();
  const tr = useSelector((state) => state.spcmReducer.tr);
  return (
    <>
      <Row>
        <Col xs={12}>
          <div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
            <ListGroup bsPrefix=" " as="ul">
              <ListGroup.Item as="li" bsPrefix=" ">
                <Button
                  bsPrefix=" "
                  onClick={() => {
                    navigate("/schedulerEdit/" + props.schedulerData.id);
                  }}
                  title={tr["Edit"]}
                  className="editIcn"
                >
                  <PiPencilSimpleBold />
                </Button>
              </ListGroup.Item>
              <ListGroup.Item as="li" bsPrefix=" ">
                <Button
                  bsPrefix=" "
                  title={tr["Minimize the details panel"]}
                  onClick={() => {
                    props.showRightSideFun(false);
                  }}
                >
                  <IoClose />
                </Button>
              </ListGroup.Item>
            </ListGroup>
          </div>
          <div className="rPageHeading">
            <div className="offNam margin-t-5 margin-b-5">
              {tr["Details"]}
            </div>
          </div>
        </Col>
      </Row>
      <div className="rBoxGap">
        <div className="rBoxTabs">
          <ListGroup as="ul" bsPrefix=" ">
            <ListGroup.Item
              as="li"
              bsPrefix=" "
              className="rwTable"
              style={{ borderTop: "none" }}
            >
              <span className="rwKey">{tr["Scheduler Name"]}</span>
              <span className="rwVal">{props.schedulerData.jobName}</span>
            </ListGroup.Item>
            <ListGroup.Item as="li" bsPrefix=" " className="rwTable">
              <span className="rwKey">{tr["Status"]}</span>
              <span className="rwVal">{props.schedulerData?.jobStatus?.toLowerCase()}</span>
            </ListGroup.Item>
          </ListGroup>
        </div>
      </div>
    </>
  );
};

export default ShortDescription;
