
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, ButtonToolbar, Container, Row } from "react-bootstrap";
import { _inputField, _textArea, _number } from "../../common/formFields";
import Breadcrumbs from "../../common/header/breadcrumbs";
import BasicTable from "../../common/ReactTable/BasicTable";
import { useMutation, useQuery } from "@apollo/client";
import FormHeader from "./header";
import { useLocation, useNavigate } from "react-router";
import { FaEye, FaPencil,FaTrash} from "react-icons/fa6";
import { TbCopy } from "react-icons/tb";
import { DeleteForm, GET_FORMS } from "../graphQl/GraphqlQueries";
import Cookies from "universal-cookie";
import { SaveFormComponent } from "../studio/components/topbarButtons";
import Swal from "sweetalert2";
import axios from "axios";
import ListLoader from "../../common/loaders/ListLoader";


const cookies = new Cookies();
const gph = cookies.get("gph").split("~");
const roles = typeof gph[33] !== "undefined" ? gph[33].split(",") : [];
const dateformat = gph[2];
const loggedInCompany = gph[48];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

const ActionComponent = (props) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [deleteForm, { data: deleteResp, loading, reset }] =
    useMutation(DeleteForm);
  const [availableCompany, setAvailableCompany] = useState([]);
  useEffect(() => {
    if (deleteResp?.deleteForm?._id) {
      Swal.fire("Form deleted successfully.");
      reset();
      props.refetchFormList();
    }
  }, [deleteResp]);

  useEffect(() => {
    if (props?.companyList && props.companyList.length > 0) {
      let cmpIds = [];
      props.companyList.forEach((res) => {
        if (res.id !== 0) {
          cmpIds.push(res.id);
        }
      });
      setAvailableCompany(cmpIds);
    }
  }, [props?.companyList]);

  return (
    <ButtonToolbar>
      <Button
        bsPrefix=" "
        className="formBuilBtn margin-r-5"
        onClick={() => {
          props.navigate("/uiBuilder/" + props?.row._id, {
            state: {
              companyId: props?.row?.companyId,
              companyName: props?.row?.companyName,
              activeTab: props?.filterTabActive,
            },
          });
        }}
        title="Edit"
      >
        <FaPencil />
      </Button>
      <Button
        bsPrefix=" "
        className="formBuilBtn"
        onClick={() => props.onRowSelect(props?.row)}
        title="Preview"
      >
        <FaEye />
      </Button>
      <Button
        bsPrefix=" "
        style={{ marginLeft: "20px" }}
        className="formBuilBtn margin-r-5"
        title="Copy"
        onClick={() => {
          setIsEditMode(true);
        }}
      >
        <TbCopy className="f-size-18" />
      </Button>
      {((props?.row.companyId == 0 && roles.includes("70")) ||
        availableCompany.includes(props?.row.companyId)) && (
        <Button
          bsPrefix=" "
          className="formBuilBtn"
          title="Delete"
          onClick={() => {
            const formObj = props?.row;
            Swal.fire({
              title: props?.tr["Are you sure you want to delete?"],
              width:400,
              showDenyButton: true,
              confirmButtonText: props?.tr["Yes"],
              denyButtonText: props?.tr["No"],
            }).then((result) => {
              if (result.isConfirmed) {
                deleteForm({ variables: { id: formObj._id } });
              }
            });
          }}
        >
          <FaTrash />
        </Button>
      )}
      <SaveFormComponent
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        mode="copy"
        formValues={props.row}
        companyList={props?.companyList}
        refetchFormList={props.refetchFormList}
      />
    </ButtonToolbar>
  );
};

const FormsList = (props) => {
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [filterTabActive, setFilterTabActive] = useState(1);

  const navigate = useNavigate();
  const { state: navigationProps } = useLocation();
  const translator = useSelector((state) => state.spcmReducer.tr);
  const [refreshedClicked, setRefreshedClicked] = useState(false);

  useEffect(() => {
    if (companyList.length == 0) {
      getCompanyList();
    }
    if(navigationProps) {
      if(navigationProps?.companyId) {
        setSelectedCompany([{ id: navigationProps?.companyId, label: navigationProps?.companyName }]);
      }
      setFilterTabActive(navigationProps?.activeTab)
    }
  }, []);

  useEffect(() => {
    if(!navigationProps?.companyId && companyList.length > 0 && filterTabActive === 2) {
      let companyName = companyList.filter(item => item?.id?.toString() === loggedInCompany)?.[0]?.label;
      setSelectedCompany([{ id: loggedInCompany, label: companyName}])  
    }
  }, [filterTabActive])

  const getCompanyList = () => {
    let impactedServiceData;
    api.get("/api/getFdnCompanyList").then((responseCompany) => {
      let companyList1 = [{ id: 0, label: "Default Company" }];
      impactedServiceData = responseCompany.data;
      impactedServiceData.forEach((item) => {
        if (item.name && item.name !== null) {
          companyList1.push({
            label: item.name,
            id: item.id,
          });
        }
      });

      setCompanyList(companyList1);
    });
  };

  const { loading, error, data, refetch } = useQuery(GET_FORMS, {
    variables: {
      companyId:
        filterTabActive === 1 ? 0 : selectedCompany.length > 0 ? parseInt(selectedCompany[0].id, 10) : "",
      limit: parseInt(5000, 10),
      offset: 0,
    },
  });

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (selectedCompany.length > 0) {
      refetch();
    }
  }, [selectedCompany]);

  const refreshPage = ()=>{
    setRefreshedClicked(true);
    setTimeout(() => {
      setRefreshedClicked(false);
    }, 500);
    refetch();
  }

  const tableColumns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Company",
      selector: (row) => row.companyName,
      sortable: true,
    },
    {
      name: "Modified By",
      selector: (row) => row?.updatedByFullname || row.updatedBy,
      sortable: true,
    },
    {
      name: "Modified Date",
      selector: (row) => moment(row.updatedAt).format(jwtdf),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.publishedPage?row.publishedPage?.status:"Draft",
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <ActionComponent
          row={row}
          navigate={navigate}
          onRowSelect={() => onRowSelect(row)}
          refetchFormList={refetch}
          tr={translator}
          companyList={companyList}
          filterTabActive={filterTabActive}
        />
      ),
      sortable: true,
    },
  ];

  const onRowSelect = (row) => {
    if (row?.pageType == "form") {
      navigate("/preview/" + row._id, {
        state: {
          companyId: row?.companyId,
          companyName: row?.companyName,
          activeTab: filterTabActive,
        },
      });
    } else if (row?.pageType == "list") {
      navigate("/preview/" + row?._id, {
        state: {
          companyId: row?.companyId,
          companyName: row?.companyName,
          activeTab: filterTabActive,
        },
      });
    }
  };

  const renderTableList = () => {
    return (
      <div className="dtaTableDv integrateHubTable">
        <div className={"rightTrue srchPosTop"}>
          <BasicTable
            tableColumns={tableColumns}
            data={data?.getAllForms}
            pagination={true}
            onRowSelect={(row) => {
             // onRowSelect(row);
            }}
          />
        </div>
      </div>
    );
  };


  ///main render partla
  return (
    <div>
      <div className="container-fluid margin-t-10 margin-b-15">
        <Breadcrumbs activePageName="Forms" />
      </div>
      <Container fluid className="margin-t-10 margin-b-15">
        <FormHeader
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
          companyList={companyList}
          refreshPage={refreshPage}
          setFilterTabActive={setFilterTabActive}
          filterTabActive={filterTabActive}
          refreshedClicked={refreshedClicked}
        />
          {<Row>{!loading && !refreshedClicked?renderTableList():<ListLoader/>}</Row>}
      </Container>
    </div>
  );
};

export default FormsList;
