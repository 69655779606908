
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Form,
} from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { Field, change, reduxForm } from "redux-form";
import {
  getDepartmentPageDetails,
  getOrganizationOptions,
} from "../../../../../actions/foundation/departmentAction";
import { useParams } from "react-router";
import { _inputField } from "../../../../common/formFields";
import { getCostCenterCompanyOptions } from "../../../../../actions/foundation/costCenterAction";
import ListLoader from "../../../../common/loaders/ListLoader";

let EditForm = (props) => {
  // const [companyList, setCompanyList] = useState([]);
  // const [selectedCompanyList, setSelectedCompanyList] = useState([]);
  // const id = window.location.href.split("/").pop();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const params = useParams();
  const dispatch = useDispatch();
  const initialValue = useSelector((state) => state.departmentEditList);

  console.log("CheckInitialVALUES", initialValue);

  const loader = useSelector((state) => state.commonLoader);

  const validateEditDepartmentField = (fieldType, event) => {
    switch (fieldType) {
      case "name":
        if (event.target.value == "") {
          props.setNameErr("error");
        } else {
          props.setNameErr("");
        }
        break;
      case "status":
        if (event.target.value == "") {
          props.setStatusErr("error");
        } else {
          props.setStatusErr("");
        }
        break;
      default:
    }
  };

  useEffect(() => {
    dispatch(getDepartmentPageDetails(params.id));
  }, []);
  // console.log("Testini",orgMap[initialValue.organizationId])
  return (
    <>
      {loader.isLoading ? (
        <ListLoader />
      ) : (
        <Form>
          <Row>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>{"Code"}</Form.Label>
                <Field
                  name="code"
                  className={"form-control"}
                  component={_inputField}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                <span className="rStar"></span>
                <Form.Label>
                  {tr["Name"]} 
                </Form.Label>
                <Field
                  component={_inputField}
                  name="name"
                  className={"form-control " + props.nameErr}
                  maxLength={50}
                  onBlur={(event) => {
                    validateEditDepartmentField("name", event);
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                {/* <span className="rStar"></span> */}
                <Form.Label>
                  {tr["Company"]}
                </Form.Label>
                <Field
                  name="company_name"
                  component={_inputField}
                  className="form-control"
                  disabled
                />
              </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                {/* <span className="rStar"></span> */}
                <Form.Label>
                  {tr["Organization"]}
                </Form.Label>
                <Field
                  name="organization_name"
                  className="form-control"
                  component={_inputField}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                <span className="rStar"></span>
                <Form.Label>
                  {tr["Status"]}
                </Form.Label>
                <Field
                  name="status"
                  component="select"
                  className={"form-control " + props.statusErr}
                  onBlur={(event) => {
                    validateEditDepartmentField("status", event);
                  }}
                >
                  <option value="">{tr["Select"]}</option>
                  <option value="1">{tr["Active"]}</option>
                  <option value="0">{tr["Inactive"]}</option>
                </Field>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};
EditForm = reduxForm({
  form: "EditDepartment",
  enableReinitialize: true,
})(EditForm);
EditForm = connect(({ departmentEditList }) => ({
  initialValues: {
    code: departmentEditList?.dept_code,
    name: departmentEditList?.dept_name,
    status: departmentEditList?.status,
    company_name: departmentEditList?.company_name,
    organization_name: departmentEditList?.orgn_name,
    initialDeptName: departmentEditList?.dept_name,
    company_id: departmentEditList?.companyId,
    org_id: departmentEditList?.organizationId
  },
}))(EditForm);

export default EditForm;
