
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import Modal from 'react-bootstrap/Modal';
let GLOBAL = require('../../components/Globals');
import axios from 'axios';
import { connect } from 'react-redux';
import moment from 'moment';

import { loadImplPlanList } from '../../actions/common/attachmentAction';
import _ from 'lodash';
import configureStore from '../../store/configureStore';
import constanturl from '../../../src/utils/constants.js';
const store = configureStore();

import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import ListGroup from 'react-bootstrap/ListGroup';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Swal from "sweetalert2";
import {IoClose} from "react-icons/io5";
import { PiEyeBold } from "react-icons/pi";
import {ImSpinner6} from "react-icons/im";

import Cookies from 'universal-cookie';
const cookies = new Cookies();

let dateformat = cookies.get('gph');
if (dateformat) dateformat = dateformat.replace('s:', '');
if (dateformat) dateformat = dateformat.substring(0, dateformat.lastIndexOf('.'));
//console.log("homepage::::"+homepagelocation);
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

let timezone = cookies.get('gph');
if (timezone) timezone = timezone.replace('s:', '');
if (timezone) timezone = timezone.substring(0, timezone.lastIndexOf('.'));
timezone = timezone.split("~");
timezone = timezone[3];

let attachmentDetails = cookies.get('gph');
if (attachmentDetails) attachmentDetails = attachmentDetails.replace('s:', '');
if (attachmentDetails) attachmentDetails = attachmentDetails.substring(0, attachmentDetails.lastIndexOf('.'));
attachmentDetails = attachmentDetails.split("~");
const attachmentSize = attachmentDetails[21];
const attachmentType = fileobjectGenerator(attachmentDetails[22].split(', '));
const Joi = require('joi')
import swal from 'sweetalert';
import datetimeConvertor from '../../ISO8601converter';
import { fileobjectGenerator, validateFile } from './MIMEObjGenerator';

class UploadScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filesPreview: [],
            filesToBeSent: [],
            printcount: 1,
            clickAttachButton: 0,
            showConfirmation: false,
            attachmentId: null,
            module: null,
            itemId: null
        };
        this.deleteFileAction = this.deleteFileAction.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }
    componentWillMount() {
        let itemId;
        let module;
        //alert("==="+module);
        if (_.has(this.props.itemDetails, 'breakfixNumber')) {
            module = 'Breakfix';
            itemId = this.props.itemDetails.breakfixId;
        } else if (_.has(this.props.itemDetails, 'changeNumber')) {
            module = 'ChangeManagement';
            itemId = this.props.itemDetails.changeId;
        } else if (_.has(this.props.itemDetails, 'investigationNumber')) {
            module = 'Investigation';
            itemId = this.props.itemDetails.investigationId;
        } else if (_.has(this.props.itemDetails, 'itemNumber')) {
            module = 'Item';
            itemId = this.props.itemDetails.itemId;
        }
        this.props.loadImplPlanList(module, itemId);
    }


    removeFile() {

        this.setState({ filesToBeSent: [], filesPreview: [] });
    }
    onDrop(acceptedFiles, rejectedFiles) {
        let filesToBeSent = this.state.filesToBeSent;
        if (acceptedFiles.length > 0) {
            if (filesToBeSent.length < this.state.printcount) {
                filesToBeSent.push(acceptedFiles);
                let filesPreview = [];
                for (let i in filesToBeSent) {
                    if (filesToBeSent.hasOwnProperty(i)) {
                        console.log(filesToBeSent)
                        console.log(filesToBeSent[i][0])
                        filesPreview.push(
                            <ListGroup.Item><span className="float-r" style={{ cursor: "pointer" }} onClick={() => { this.removeFile(); }}><IoClose /></span>
                                {filesToBeSent[i][0].name}
                            </ListGroup.Item>
                        )
                    }
                }

                this.setState({ filesToBeSent, filesPreview });
            }
            else {
                swal({
                    text: this.props.translator["File already attached"],
                    button: this.props.translator["OK"],
                });
            }
        }
        else {
            // alert("Maximum File Size for upload is 10Mb");
            // alert("File not supported.");
        }
    }

    onDropRejected(rejectedFiles) {

        if (rejectedFiles && rejectedFiles[0] && rejectedFiles[0].file && rejectedFiles[0].file.size && rejectedFiles[0].file.size > 1048576 * attachmentSize) {
            swal({
                text: this.props.translator['Please upload File up to '] + attachmentSize + this.props.translator[' MB size.'],
                button: this.props.translator["OK"],
            });
        }
        else {
            swal({
                text: this.props.translator["File not supported."],
                button: this.props.translator["OK"],
            });
        }
    }

    handleClick(event, itemDetails) {
        let self = this;
        if (this.state.filesPreview.length > 0) {
            self.setState({
                clickAttachButton: 1
            })
        }

        if (this.state.filesToBeSent.length > 0) {
            let formData = new FormData();
            formData.append('file', this.state.filesToBeSent[0][0]);
            let jsonObject = new Object;
            if (_.has(this.props.itemDetails, 'breakfixNumber')) {
                jsonObject.module = 'Breakfix';
                jsonObject.itemId = this.props.itemDetails.breakfixId;
            } else if (_.has(this.props.itemDetails, 'changeNumber')) {
                jsonObject.module = 'ChangeManagement';
                jsonObject.itemId = this.props.itemDetails.changeId;
            } else if (_.has(this.props.itemDetails, 'investigationNumber')) {
                jsonObject.module = 'Investigation';
                jsonObject.itemId = this.props.itemDetails.investigationId;
            } else if (_.has(this.props.itemDetails, 'itemNumber')) {
                jsonObject.module = 'Item';
                jsonObject.itemId = this.props.itemDetails.itemId;
            }

            jsonObject.createdOn = Math.round(new Date().getTime() / 1000.0);
            jsonObject.createdBy = "";
            store.dispatch({ type: 'LOAD_IMPLPLAN_SUCCESS', implPlanList: {} });
            formData.append("attachment", JSON.stringify(jsonObject));

            axios.post("/change/api/saveImplPlan", formData).then((response) => {
                if (response.status == 200) {
                    this.props.loadImplPlanList(jsonObject.module, jsonObject.itemId)
                    console.log("MMM : 2")
                    swal({
                        text: this.props.translator["file uploaded successfully."],
                        button: this.props.translator["OK"],
                    });
                    this.setState({ filesToBeSent: [], filesPreview: [], clickAttachButton: 0 });
                }
            })
                .catch((error) => {
                    swal({
                        text: this.props.translator['Please upload File up to '] + attachmentSize + this.props.translator[' MB size.'],
                        button: this.props.translator["OK"],
                    });
                    this.setState({ clickAttachButton: 0 });
                });
        }
        else {
            swal({
                text: this.props.translator["Please upload some files first"],
                button: this.props.translator["OK"],
              });
        }
        //}
    }
    handleClose() {
		this.setState({ showConfirmation: false, attachmentId: null, module: null, itemId: null });
	}
    confirmDelete() {
        const {attachmentId, module, itemId} = this.state;
        // console.log('this.props - ', this.props);
		let ref = this;
        const d2c_path = process.env.D2C_FOUNDATION_HOST;
        const url = "/serviceManagement/rest/updateAttachmentAction/";
        axios.patch(url + attachmentId,
            {}).then(function (response) {
                console.log(response);
                if (response.status === 200) {
                    swal({
                        text: ref.props.translator["File removed successfully."] || "File removed successfully.",
                        button: ref.props.translator["OK"],
                    });
                    ref.props.loadImplPlanList(module, itemId);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
		this.setState({ showConfirmation: false, attachmentId: null, module: null, itemId: null });
	}
    deleteFileAction(attachmentId, module, itemId) {
        Swal.fire({
            title: this.props.translator["Are you sure you want to delete?"],
            width: 400,
            padding: '30px',
            showDenyButton: true,
            confirmButtonText: this.props.translator['Yes'],
            denyButtonText: this.props.translator['No'],
        }).then((result) => {
            if (result.isConfirmed) {
              this.confirmDelete();
            } else if (result.isDenied) {
              this.handleClose();
            }
        });
        this.setState({ showConfirmation: true, attachmentId: attachmentId, module: module, itemId: itemId });
    }

    renderAttachedItems(attachments) {
        if (_.isEmpty(attachments)) {
            return (
                <tr><td colSpan={3} className="text-c">{this.props.translator['No File Found']}</td></tr>
                );
            }

            return attachments.map((attachment) => {
            if (attachment.filename !== null && attachment != undefined) {
                let attachfilename = attachment.filename || attachment.fileName;
                if (attachfilename.indexOf("##") > -1)
                    attachfilename = attachfilename.slice(15);

                let actDateTimeFormat = jwtdf + " hh:mm A";
                let newDate = new Date(attachment.createdOn*1000).toLocaleString("en-US", {timeZone: timezone});
                newDate = moment(newDate).format(jwtdf + " HH:mm:ss");
                let isoDate = datetimeConvertor(newDate, jwtdf + " HH:mm:ss");
                let myDateObj = moment(isoDate).format(actDateTimeFormat);

                let validated_url = undefined;
                const isCreatedByUser = this.props.user_id === attachment.createdBy;
                const isDeleteDisabled = !(this.props.itemDetails.status == "Cancelled" || this.props.itemDetails.status == "Closed");
                if (attachment && attachment.fileTempURL != "") {
                    let namedSchema = Joi.string().regex(/^[^<>}{]+$/)
                    let validator_to_add_myQbj = namedSchema.validate(attachment.fileTempURL)
                    if (validator_to_add_myQbj.error == null) {
                        validated_url = attachment.fileTempURL;
                    }
                    else {
                        validated_url = "\\";
                    }
                }
                else
                    validated_url = "\\";

                if (attachment.status == "Y") {
                    return null;
                } else if (attachment.planType == null) {
                    return (
                    <tr bsPrefix=' ' key={attachment.attachmentId}>
                        <td>
                            <div>{attachfilename}</div>
                            <div style={{"color":"rgb(0 0 0 / 50%)"}}>{attachment.createdByName}</div>
                        </td>
                        <td>{myDateObj}</td>
                        <td className='text-c'>
                            <a className='margin-r-10' href={validated_url} ><PiEyeBold /></a>
                            {isCreatedByUser && isDeleteDisabled && !this.props.disableFieldsForGuestRole && <span style={{ cursor: "pointer" }} onClick={() => { this.deleteFileAction(attachment.attachmentId, attachment.module, attachment.itemId) }}>
                            <IoClose /></span>}
                        </td>
                    </tr>
                    );
                }
            }
        });
    }

    render() {


        return (
            <div className="App">
                <div className="rPageHeadActBtn d-md-none">
                    <ul>
                        <li>
                        <button
                            type='button'
                            title="Minimize the right panel"
                            bsClass=""
                            bsStyle=""
                            className="closerightPanelBtn"
                            onClick={() => {
                            this.props.rightEditPanel(false);
                            }}
                        >
                            <IoClose />
                        </button>
                        </li>
                    </ul>
                </div>
                <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Provide Attachments']}</div></div>

                <div className='rBoxGap'>
                    {this.props.itemDetails.status == "Cancelled" || this.props.itemDetails.status == "Closed" || this.props.itemDetails.status == "Rejected" || this.props.disableFieldsForGuestRole ?
                        null :
                        <div>
                            <div style={{ "text-align": "left" }}>
                                <b>{this.props.translator['Upload your file.']}</b> {this.props.translator['(max size']} {attachmentSize} {this.props.translator['MB)']}
                                {/* <br/> */}
                                {/* <b>{this.props.translator['Supported file types:']}</b> .doc, .docx, .xls, .xlsx, .pdf, .jpeg, .jpg, .png, .ico, .xml, .tif, .msg, .zip, .rar, .txt, .csv, .ldif, .log, .sql */}
                            </div>
                            {/* <div className="margin-t-20">
                        <div className="border-b padding-b-5 margin-b-10 black f-size-16 fw-400"></div>
                    </div> */}
                            {/* <Dropzone className="text-c cursorPoint margin-t-5 margin-b-5 grayBg" onDrop={(files) => this.onDrop(files)} onDropRejected={(files) => this.onDropRejected(files)} style={{ "width": "100%", "height": "60", "padding-top": "20px", "border": "1px solid black", "border-top-color": "#bdc4c9", "border-right-color": "#bdc4c9", "border-bottom-color": "#bdc4c9", "border-left-color": "#bdc4c9" }} maxSize={1048576 * attachmentSize} accept={attachmentType}>
                                <div>{this.props.translator['Drop files or click here to upload.']}</div>
                            </Dropzone> */}

                            {/* <Dropzone
                                className="text-c cursorPoint margin-t-5 margin-b-5 grayBg"
                                onDrop={(files) => this.onDrop(files)}
                                onDropRejected={(files) => this.onDropRejected(files)}
                                style={{
                                width: "100%",
                                height: "60",
                                "padding-top": "20px",
                                border: "1px solid black",
                                "border-top-color": "#bdc4c9",
                                "border-right-color": "#bdc4c9",
                                "border-bottom-color": "#bdc4c9",
                                "border-left-color": "#bdc4c9",
                                }}
                                maxSize={1048576 * attachmentSize}
                                accept={attachmentType}
                            >
                                {({ getRootProps, getInputProps }) => (
                                <section className="form-control dropFileBtn">
                                    <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {
                                        this.props.translator[
                                        "Drop files or click here to upload."
                                        ]
                                    }
                                    {this.props.translator["(max size"]} {attachmentSize}
                                    {this.props.translator["MB)"]}
                                    <button onClick={(e) => e.preventDefault()}>{this.props.translator["Browse"]}</button>
                                    </div>
                                </section>
                                )}
                            </Dropzone> */}

                            <Dropzone
                                onDrop={(files) => this.onDrop(files)}
                                onDropRejected={(files) => this.onDropRejected(files)}
                                maxSize={1048576 * attachmentSize}
                                accept={attachmentType}
                                validator={(file)=>{ return validateFile(file,this.props.translator);}}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()}>
                                      <div className="text-c cursorPoint margin-t-5 margin-b-5 grayBg" style={{
                                          width: "100%",
                                          height: "60px",
                                          "padding-top": "20px",
                                          border: "1px solid black",
                                          "border-top-color": "#bdc4c9",
                                          "border-right-color": "#bdc4c9",
                                          "border-bottom-color": "#bdc4c9",
                                          "border-left-color": "#bdc4c9",
                                      }}>
                                    <input {...getInputProps()} />
                                    {
                                        this.props.translator[
                                        "Drop files or click here to upload."
                                        ]
                                    }
                                    {/* <button onClick={(e) => e.preventDefault()}>{this.props.translator["Browse"]}</button> */}
                                    </div>
                                </div>
                                )}
                            </Dropzone>

                            {/* <span className="f-size-12" style={{"marginTop":"4px","display":"inline-block"}}><i>(<b>{this.props.translator['Supported file types:']}</b> .doc, .docx, .xls, .xlsx, .pdf, .jpeg, .jpg, .png, .ico, .xml, .tif, .msg, .zip, .rar, .txt, .csv, .ldif, .log, .sql)</i> </span> */}
                            {/* <span className="f-size-12" style={{"marginTop":"4px","display":"inline-block"}}><i>(<b>{this.props.translator['Supported file types:']}</b> {attachmentType})</i> </span> */}
                            <div className="margin-t-20">
                                {/* <div className="border-b padding-b-5 margin-b-10 black f-size-16 fw-400">{this.state.filesPreview.length > 0 ? this.props.translator['file attached'] : this.props.translator['file not attached']}</div> */}
                                <div className="padding-b-5 margin-b-0 black f-size-16 fw-400">{this.state.filesPreview.length > 0 ? this.props.translator['file attached'] : this.props.translator['No file attached']}</div>
                                <ListGroup className="attachment-break">
                                    {this.state.filesPreview}
                                </ListGroup>
                            </div>
                        </div>
                    }
                    {
                        this.props.itemDetails.status == "Cancelled" || this.props.itemDetails.status == "Closed" || this.props.itemDetails.status == "Rejected" || this.props.disableFieldsForGuestRole ?
                            null :

                            <>
                                {/*  <RaisedButton label="attach" className="btn btn-default" primary={true} style={style} onClick={(event) => this.handleClick(event, this.props.itemDetails)} />*/}

                                <ButtonToolbar className="black margin-t-5">
                                    <Button className='rgSidrkBtn smallBtn' bsSize="small" variant="primary" disabled={this.state.clickAttachButton == 1} onClick={(event) => this.handleClick(event, this.props.itemDetails)} >
                                        {this.state.clickAttachButton == 1 ? <ImSpinner6 className="icn-spinner"/> : null} {this.props.translator['Attach']}</Button>
                                </ButtonToolbar>

                                {/* {this.state.clickAttachButton == 1 ? <Button type="button" disabled>{this.props.translator['Attach']}</Button> :
                        <Button type="button" onClick={(event) => this.handleClick(event, this.props.itemDetails)} >{this.props.translator['Attach']}</Button>} */}
                            </>
                    }
                    {
                        // this.props.itemDetails.status == "Cancelled" || this.props.itemDetails.status == "Closed" || this.props.itemDetails.status == "Rejected" ?
                        //     null : null
                        // <div className="margin-t-20">
                        //     <div className="border-b padding-b-5 margin-b-10 black f-size-16 fw-400"></div>
                        // </div>
                    }
                    <div className={this.props.itemDetails.status == "Cancelled" || this.props.itemDetails.status == "Closed" || this.props.itemDetails.status == "Rejected" ? null : "margin-t-20"} >
                        <div className="border-b padding-b-5 margin-b-0 black f-size-16 fw-400">{this.props.translator['Attached Files']}</div>
                        <Table responsive striped bordered condensed hover className="f-size-12 wrpAttTab">
                            <thead><tr><th width="60%">Name & {this.props.translator['Linked By']}</th><th width="25%">Date & Time</th><th width="15%" className="text-c">{this.props.translator['Action']}</th></tr></thead>
                            <tbody>{this.renderAttachedItems(this.props.attachmentDetails)}</tbody>
                        </Table>
                    </div>
                </div>
            </div>
        );
    }
}

const style = {
    margin: 15,
};
const mapStateToProps = ({ implememtationPlanList }) => {
    return {
        attachmentDetails: implememtationPlanList
    };
};
export default connect(mapStateToProps, { loadImplPlanList })(UploadScreen);
