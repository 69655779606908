
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";

const NoDataAvailable = ({ text }) => {
  const displayText = text || "No data available";
  return <div className="no-data text-center">{displayText}</div>;
};

export default NoDataAvailable;
