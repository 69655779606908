
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import { GLOBAL }  from '../../components/Globals';

export function loadArticleResultsSuccess(articleResultsResponse){
    // console.log('BBBBBBBBBBBBBBBBBB---------------', articleResultsResponse)
	return {
		type: 'LOAD_ARTICLERESULTS_SUCCESS',
		articleResultsResponse
	};
}

export function clearSPCMArticles(){
    return (dispatch) => {
		dispatch(loadArticleResultsSuccess([]));
	};
}

export function clearInsidedArticles(){
    return (dispatch) => {
		dispatch({ type: 'LOAD_INSIDED_RESULT_SUCCESS', insidedResult: [] });
	};
}

export function loadAttachedArticles(articleResultsResponse){
    // console.log('BBBBBBBBBBBBBBBBBB---------------', articleResultsResponse)
	return {
		type: 'LOAD_ATTACHED_ARTICLES_SUCCESS',
		articleResultsResponse
	};
}

export function articlesLoading_attached_KA(){
	return(dispatch) =>{
		dispatch({type:'FETCHING_DETAILS_IN_PROGRESS_ATTACHED_KA'});
	};
}

export function articlesSuccess_attached_KA(){
	return(dispatch) =>{
		dispatch({type:'DETAILS_FETCHED_SUCCESSFULLY_ATTACHED_KA'});
	};
}


export function loadArticleResultsForSearchString_KA(searchString){
	let searchStringactual=searchString.replace(' ','');
	const articleResultsUrl = GLOBAL.articleSearchStringUrl;
    let str = {};
	str.search_query = searchStringactual;
	str = JSON.stringify(str);

	return(dispatch) =>{
		dispatch({type:'FETCHING_DETAILS_IN_PROGRESS_KA'});
				axios.get(articleResultsUrl, { headers: { 'query': str } })
					.then((resultResponse)=>{
						if(!resultResponse){
							throw Error(resultResponse.statusText);
						}
						return resultResponse;
					})
					.then((resultResponse) =>{
						dispatch({type:'DETAILS_FETCHED_SUCCESSFULLY_KA'});
                        dispatch(loadArticleResultsSuccess(resultResponse));
					})
					.catch((err) => {
						// console.log(err);
						dispatch({type:'DETAILS_FETCHED_SUCCESSFULLY_KA'});
					});

	};
}

export function loadInsidedArticlesForSearch_KA(categories, searchText, token, searchFilter, callFromMyArticlesRoute = false) {
	return (dispatch) => {
		dispatch({ type: 'FETCHING_DETAILS_IN_PROGRESS_KA' });
		axios.get('/search/' + categories + '/' + searchText, { headers: { 'authorization': `bearer ${token}` } })
			.then((res) => {
				try{
					let allData = res.data.community;
					let displayData = [];

					if (callFromMyArticlesRoute == false) {
						if (allData.length > 5)
						allData = allData.slice(0, 5);
					}

					let filterMapping = {
						'knowledge': 'article',
						'community': 'question',
						'conversation':'conversation'
					};
					// console.log('displayData#################', allData);
					if(allData && Array.isArray(allData)){
						for(let item of allData){
							if(searchFilter === 'knowledge' ) {
								if(item['contentType'] === 'article' || item['contentType'] === 'conversation'){
									displayData.push(item);
								}
							} else if (searchFilter === 'community') {
								if(item['contentType'] === 'question'){
									displayData.push(item);
								}
							} else if(searchFilter === 'all'){
								displayData.push(item);
							}
						}
					}
					// console.log('**************', displayData);

					dispatch({ type: 'LOAD_INSIDED_RESULT_SUCCESS', insidedResult: displayData });
					dispatch({ type: 'DETAILS_FETCHED_SUCCESSFULLY_KA' });
				}
				catch(e){
					dispatch({ type: 'LOAD_INSIDED_RESULT_SUCCESS', insidedResult: [] });
					dispatch({ type: 'DETAILS_FETCHED_SUCCESSFULLY_KA' });
				}
			});
	};
}

export function getAttachedArticles(breakfixId, breakfixNumber){
	const articleResultsUrl = GLOBAL.getAttachedArticlesUrl;
	return(dispatch) =>{
		dispatch({type:'FETCHING_DETAILS_IN_PROGRESS_ATTACHED_KA'});
				axios.get(`${articleResultsUrl}?itemId=${breakfixId}&itemNumber=${breakfixNumber}&module=Breakfix&sortBy=knowledgeId&orderBy=asc`)
					.then((resultResponse)=>{
						if(!resultResponse){
							throw Error(resultResponse.statusText);
						}
						return resultResponse;
					})
					.then((resultResponse) =>{
						dispatch({type:'DETAILS_FETCHED_SUCCESSFULLY_ATTACHED_KA'});
                        dispatch(loadAttachedArticles(resultResponse));
					})
					.catch((err) => {
						// console.log(err);
						dispatch({type:'DETAILS_FETCHED_SUCCESSFULLY_ATTACHED_KA'});
					});

	};
}

export function linkKnowledgeArticles(linkedItems){
    // console.log('***', {linkedItems});
	const articleResultsUrl = GLOBAL.linkKnowledgeArticlesUrl;
	return(dispatch) =>{
		// dispatch({type:'LINKING_ARTICLES_KA'});
				return axios.post(articleResultsUrl, linkedItems)
					.then((resultResponse)=>{
						if(!resultResponse){
							throw Error(resultResponse.statusText);
						}
						return resultResponse;
					})
					.then((resultResponse) =>{
						// console.log('resultResponse - ', resultResponse);
						// dispatch({type:'ARTICLES_LINKED_SUCCESSFULLY_KA'});
                        // dispatch(loadAttachedArticles(resultResponse));
                        return resultResponse;
					})
					.catch((err) => {
						// console.log('err - ', err);
						// console.log(err);
						// dispatch({type:'ARTICLES_LINKED_SUCCESSFULLY_KA'});
                        return err;
					});

	};
}

export function removeAttachedArticle(breakfixId = '', knowledgeId){
	const articleResultsUrl = GLOBAL.removeAttachedArticlesUrl;
	return(dispatch) =>{
		// dispatch({type:'LINKING_ARTICLES_KA'});
				return axios.patch(`${articleResultsUrl}?knowledgeId=${knowledgeId}&itemId=${breakfixId}`, {})
					.then((resultResponse)=>{
						if(!resultResponse){
							throw Error(resultResponse.statusText);
						}
						return resultResponse;
					})
					.then((resultResponse) =>{
						// dispatch({type:'ARTICLES_LINKED_SUCCESSFULLY_KA'});
                        // dispatch(loadAttachedArticles(resultResponse));
                        return resultResponse;
					})
					.catch((err) => {
						// console.log(err);
						// dispatch({type:'ARTICLES_LINKED_SUCCESSFULLY_KA'});
                        return err;
					});

	};
}