
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import { GLOBAL } from '../../../src/components/Globals';

const subHamburgerCategoryUrl=GLOBAL.getsubcategoryhomepage+"?companyId=";

export function loadSubHamburgerCategorySuccess(subHamburgerCategory){
	return {
		type: 'FETCH_SUBHAMBCATEGORYLIST',
		subHamburgerCategory
	};
}

export function loadSubHamburgerCategory(){
	return(dispatch) =>{
		axios.get(subHamburgerCategoryUrl)
			.then((subHamburgerCategory)=>{
				if(!subHamburgerCategory){
					throw Error(subHamburgerCategory.statusText);
				}
				return subHamburgerCategory;
			})
			.then((subHamburgerCategory) =>{
				dispatch(loadSubHamburgerCategorySuccess(subHamburgerCategory))
			})
			.catch((err) => {
				console.log(err);
			});
	};
}
