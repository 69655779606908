import { options } from "joi";
import { change } from "redux-form";

export const repetativelevel = (e) => {
  e.DomComponents.addType(repetativelevelComponent.name, repetativelevelAdd);
};

export const repetativelevelComponent = {
  name: "repetativeLevelBlock",
  content: `<li prefix=" " class="list-group-item">
   <div class="Escalation-level-list-data">
      <div class="table-responsive">
         <table class="table custom-border-1 table">
            <tr>
               <th width="15%"><label>Level</label></th>
               <th width="15%"><label> User</label></th>
               <th><label>Emails</label></th>
               <th width="15%"><label>Timeout</label></th>
               <th width="15%"><label>Communication Channel</label></th>
               <td width="5%" rowspan="2" style="border-left:1px solid #ddd"><span class="edit-level"><a title="Edit Level"><i class="fa fa-pencil"></i></a></span><span class="trash-level"><a title="Delete Level"><i class="fa fa-trash-o"></i></a></span></td>
            </tr>
            <tr>
               <td><label>Level 1</label></td>
               <td title="2021"><label> 2021</label></td>
               <td><label>---</label></td>
               <td><label>10 mins</label></td>
               <td><label>Email</label></td>
            </tr>
         </table>
      </div>
   </div>
</li>`,
  traits: [
    {
      type: "select",
      label: "Data Source",
      name: "data_source",
      options: [],
      changeProp: 1,
    },
    {
      type: "text",
      label: "Key (Pick From Default)",
      name: "defaultValueKey",
      changeProp: 1,
    },
    {
      type: "select",
      label: "View",
      name: "view",
      changeProp: 1,
      options: [
        { id: "unorderedView", name: "Unordered View" },
        { id: "orderedView", name: "Ordered View" },
      ],
    },
  ],
};

export const repetativelevelAdd = {
  model: {
    defaults: {
      draggable: true,
      // attributes: { class: "my-repeatativeFieldsComponent" },
      components: repetativelevelComponent.content,
      traits: repetativelevelComponent.traits,
      editable: true,
      styles: ``,
    },
  },
};

export const levelFormBlock = {
  id: "repetaiveLevelBlock",
  label: "Repetative level",
  // attributes: { class: "fa fa-th" },
  media: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"><path d="M17.4 10L21 12L17.4 14M17.4 10L12 13L6.6 10M17.4 10L21 8L12 3L3 8L6.6 10M6.6 10L3 12L6.6 14M17.4 14L21 16L12 21L3 16L6.6 14M17.4 14L12 17L6.6 14" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
  category: "Basic",
  content: {
    type: repetativelevelComponent.name,
  },
};
