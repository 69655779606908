
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Row, Col, Table, Button } from "react-bootstrap";

import TranslationForm from "./translationForm.js";
import { connect } from "react-redux";
import {
  spcmLoader,
  getTranslationForOfferingBasedOnType,
  getConsumptionItems,
  setTranslationObject,
  getAttributesTranslationStatus,
} from "../../../../../actions/spcmActions";
import ListLoader from "../../../../common/loaders/ListLoader";
import _ from "lodash";
import sanitizeHtml from 'sanitize-html';

class ComponentsListing extends React.Component {
  constructor(props) {
    super(props);
    this.whichFormOpen = this.whichFormOpen.bind(this);
  }

  whichFormOpen(value) {
    switch (value) {
      // case "Form1":
      //   return (
      //     <TextFieldForm1
      //       insideTableShowFun6={this.props.insideTableShowFun5}
      //       fieldName1={this.props.fieldName}
      //       fieldNameVal3={this.props.fieldNameVal2}
      //       lanNameVal3={this.props.lanNameVal2}
      //     />
      //   );
      case "Form2":
        return (
          <TranslationForm
            insideTableShowFun6={this.props.insideTableShowFun5}
            fieldName1={this.props.fieldName}
            fieldNameVal3={this.props.fieldNameVal2}
            lanNameVal3={this.props.lanNameVal2}
            lanIdVal2={this.props.lanIdVal2}
            languageCode={this.props.languageCode}
            tr={this.props.tr}
          />
        );
    }
  }

  componentDidMount() {
    this.props.getTranslationForOfferingBasedOnType(
      this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
      this.props.lanIdVal2,
      "disclaimer"
    );
  }

  render() {
    console.log("getListForTranslation");

    let translatedDisclaimerList = this.props.spcmReducer.getListForTranslation;
    let baseLanguageDisclaimers = this.props.spcmReducer.spcmDisclaimers[0];

    baseLanguageDisclaimers = [
      {
        Disclaimer: "Approver Disclaimer",
        Base_Text: baseLanguageDisclaimers
          ? baseLanguageDisclaimers.APPROVER_DISCLAIMER
          : "",
        Disclaimer_Type: "approver_disclaimer",
        Offering_Id: baseLanguageDisclaimers
          ? baseLanguageDisclaimers.OFFERING_ID
          : "",
        Id: baseLanguageDisclaimers ? baseLanguageDisclaimers.ID : "",
      },
      {
        Disclaimer: "Service Disclaimer",
        Base_Text: baseLanguageDisclaimers
          ? baseLanguageDisclaimers.SERVICE_DISCLAIMER
          : "",
        Disclaimer_Type: "service_disclaimer",
        Offering_Id: baseLanguageDisclaimers
          ? baseLanguageDisclaimers.OFFERING_ID
          : "",
        Id: baseLanguageDisclaimers ? baseLanguageDisclaimers.ID : "",
      },
    ];

    console.log(translatedDisclaimerList);
    console.log(baseLanguageDisclaimers);

    translatedDisclaimerList = _.map(
      translatedDisclaimerList,
      _.partialRight(_.pick, [
        "ATTRIBUTE_VALUE",
        "ATTRIBUTE_TRANS_VALUE",
        "ATTRIBUTE_ID",
        "ATTRIBUTE_TYPE",
      ])
    );

    var ComponentsList = _.map(baseLanguageDisclaimers, function (item) {
      return _.merge(
        item,
        _.find(translatedDisclaimerList, {
          ATTRIBUTE_TYPE: item.Disclaimer_Type,
        })
      );
    });

    console.log("ComponentsList");
    console.log(ComponentsList);

    return (
      <div>
        {this.props.isInsideTableShow5 ? (
          <div>{this.whichFormOpen(this.props.whichInsideFormOpen5)}</div>
        ) : (
          <div>
            <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">
              Translate - {this.props.fieldNameVal2} - {this.props.lanNameVal2}{" "}
            </div></div>

            <div className="rBoxGap">
            <Row className="margin-b-5">
              <Col md={6}>
                <div className="f-size-18 colorStOrange">
                  {this.props.fieldNameVal2} List
                </div>
              </Col>
              <Col md={6} className="text-r">
                <Button
                  size="sm" className="backtolst"
                  onClick={() => {
                    this.props.lanEditPageFun5(false, "Form2");
                    this.props.getAttributesTranslationStatus(
                      this.props.spcmReducer.currentlySelectedServiceDetails
                        .OFFERING_ID,
                      this.props.lanIdVal2
                    );
                  }}
                >
                  <i className="fa fa-arrow-left margin-r-5"></i> Back to List
                </Button>
              </Col>
            </Row>
            <Table className="langTable tableView" striped bordered responsive>
              <thead>
                <tr>
                  <th width="20%">Disclaimer</th>
                  <th width="30%">Translated Disclaimer</th>
                  <th width="5%"></th>
                </tr>
              </thead>
              {this.props.spcmReducer.spcmFetchingAttribute ? (
                <tbody>
                  <td colSpan="5">
                    <ListLoader />
                  </td>
                </tbody>
              ) : (
                <tbody>
                  {ComponentsList.map(function (value, index) {
                    return (
                      <tr>
                        <td>{value.Disclaimer}</td>
                        <td>
                          {
                            <div
                              className=""
                              dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(value.ATTRIBUTE_TRANS_VALUE, {allowedAttributes: {
                                  '*': ['style', 'class', 'dir', 'bgcolor'],
                                  'table': ['border', 'cellpadding', 'cellspacing'],
                                  'th': ['scope']
                                }}),
                              }}
                            ></div>
                          }
                        </td>
                        <td>
                          <a
                            onClick={() => {
                              this.props.setTranslationObject(value);
                              this.props.insideTableShowFun5(true, "Form2");
                            }}
                            title="Edit"
                            href="javascript:void(0)"
                          >
                            <i className="fa fa-pencil-square-o"></i>
                          </a>
                        </td>
                      </tr>
                    );
                  }, this)}
                </tbody>
              )}
            </Table>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ spcmReducer }) => {
  return {
    spcmReducer,
  };
};

export default connect(mapStateToProps, {
  getTranslationForOfferingBasedOnType,
  getConsumptionItems,
  setTranslationObject,
  getAttributesTranslationStatus,
})(ComponentsListing);
