
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { Field, change, formValueSelector, reduxForm } from "redux-form";
import { getContractPageDetails } from "../../../../actions/ham/contractAction";
import CmdbDropdownPlusTypeahead from "../../../cmdb/common/CmdbDropdownPlusTypeahead.js";
import { useParams } from "react-router";
import moment from 'moment';
import momentTz from "moment-timezone";
import {
  _inputField,
  TypeaheadExampleSingleSelect,
  TypeaheadandDropdown,
} from "../../../common/formFields";
import ListLoader from "../../../common/loaders/ListLoader";
import { listDataWithType } from "../../../../actions/spcmActions";
import BudgetTypeAhead from "../edit/BudgetTypeAhead.js";
import AdministratorTypeAhead from "../edit/AdministratorTypeAhead.js";
import { GLOBAL } from "../../../Globals.js";
import { getadmingroup } from "../../../../actions/ham/contractAction";
import {
  _dateField,
  _dateTimeField,
  _latentDateTimeField,
} from "../../../common/ReduxFormFields/formFields";
import { loadVendorCompany, loadCICompany, loadCIMenulistStatus, loadCIMenulistSubStatus } from "../../../../actions/cmdb/lovAction";
import Cookies from "universal-cookie";
const cookies = new Cookies();
let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat) dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

let EditForm = (props) => {
  const tr = useSelector((state) => state.spcmReducer.tr);
  const params = useParams();
  const dispatch = useDispatch();
  const [ciCompanySelectedList, setCiCompanySelectedList] = useState([]);
  const [inputValueCompany, setInputValueCompany] = useState("");
  const ciCompany = useSelector((state) => state.ciCompany);
  const initialValue = useSelector((state) => state.contractEditList);
  
  const loader = useSelector((state) => state.contractEditLoader);
  const [companyId, setCompanyId] = useState("");
  //VENDOR
  const vendorDetails = useSelector((state) => state.vendorSuccess);
  const [vendorList, setVendorList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState([]);
  const [selectedVendorList, setSelectedVendorList] = useState([]);

    // ADMINISTRATOR
    const adminGrpDetails = useSelector((state) => state.administratorGroupList);
    const [adminGrpList, setAdminGrpList] = useState([]);
    const [selectedAdminGrp, setSelectedAdminGrp] = useState([]);
    const [typedAdminGrp, setTypedAdminGrp] = useState("");
    const [administrator_id, setadministrator_id] = useState("");
  //COST CENTER
  const costCenterDetails = useSelector(
    (state) => state.spcmReducer.spcmDropDownCENTRE
  );
  const [costCenterList, setCostCenterList] = useState([]);
  const [selectedCostCenter, setSelectedCostCenter] = useState([]);

  //STATUS
  const statusDetails = useSelector((state) => state.ciStatus);
  const subStatusDetails = useSelector((state) => state.ciSubStatus);
  // console.log(statusDetails, "statusDetails");

  const [admin, setadmin]=useState('')
  const [budget, setbudget]=useState('')
  const [startDate, setStartDate]=useState('')
  const [endDate, setEndDate] = useState('')
  const [startDateError,setStartDateError]=useState('')
  const [endDateError,setEndDateError]=useState('')
  // const [typedVendor, setTypedVendor] = useState("");
  const [typedCostCenter, setTypedCostCenter] = useState("");
  const [initialValuesUpdated, setInitialValuesUpdated] = useState(false);
  const [lastReviewDate, setlastReviewDate] = useState("");
  const [lastReviewDateError, setlastReviewDateError] = useState("");
  const [nextReviewDate, setnextReviewDate] = useState("");
  const [nextReviewDateError, setnextReviewDateError] = useState("");
  //console.log(adminGrpDetails, "adminGrpDetails");
  // console.log(vendorList, "vendorList");
  useEffect(() => {
    if (Object.keys(props.contractEditList).length > 0 && initialValuesUpdated === false) {
      setCompanyId(props.contractEditList.COMPANY_ID)
      dispatch(
        loadCIMenulistSubStatus("CONTRACT_SUBSTATUS", props.contractEditList.STATUS)
      );
      dispatch(loadVendorCompany(props.contractEditList.COMPANY_ID));
      dispatch(listDataWithType("Cost Center", props.contractEditList.COMPANY_ID));
      dispatch(getadmingroup(props.contractEditList.COMPANY_ID))
      setCiCompanySelectedList([
        { id: props.contractEditList.COMPANY_ID, label: props.contractEditList.COMPANY },
      ]);
      if (props.contractEditList.COST_CENTER && props.contractEditList.COST_CENTER_ID ){
        setSelectedCostCenter([{
          id: props.contractEditList.COST_CENTER_ID, label: props.contractEditList.COST_CENTER
        }])
      }
      if (props.contractEditList && props.contractEditList.VENDOR && props.contractEditList.VENDOR.length > 0){
        setSelectedVendor(props.contractEditList.VENDOR);
      }
      if(props.contractEditList.ADMINISTRATOR_GROUP && props.contractEditList.ADMINISTRATOR_GROUP_ID ){
        setSelectedAdminGrp([{
          id: props.contractEditList.ADMINISTRATOR_GROUP_ID, label: props.contractEditList.ADMINISTRATOR_GROUP
        }])
      }
      setadmin(props.contractEditList.ADMINISTRATOR)
      // setbudget(props.contractEditList.BUDGET_SPONSOR)
      setInitialValuesUpdated(true);
      setStartDate(props.contractEditList.START_DATE)
      setEndDate(props.contractEditList.END_DATE);
      setlastReviewDate(props.contractEditList.LAST_REVIEW_DATE)
      setnextReviewDate(props.contractEditList.NEXT_REVIEW_DATE)
      GLOBAL.adminownerErrorColor = ''
    }
  }, [props.contractEditList]);


  useEffect(() => {
    dispatch(loadCICompany());
    dispatch(loadCIMenulistStatus("CONTRACT_STATUS", "", ""));

    return () => {
      dispatch({ type: 'CONTRACT_EDIT_DETAILS_FETCHED', payload: [] });
    }
  }, []);

  useEffect(() => {
    if (vendorDetails && vendorDetails.length > 0) {
      let venList = vendorDetails.map((element) => {
        return {
          id: element?.field1Value ?? "",
          label: element?.field1Key ?? "",
        };
      });
      setVendorList(venList);
    }
  }, [vendorDetails]);

  useEffect(() => {
    if (costCenterDetails && costCenterDetails.length > 0) {
      let ccList = costCenterDetails.map((element, index) => {
        return {
          id: index,
          label: element?.costCenterName ?? "",
        };
      });
      setCostCenterList(ccList);
    }
  }, [costCenterDetails]);

  useEffect(() => {
    if (adminGrpDetails && adminGrpDetails.length > 0) {
      let adminList = adminGrpDetails.map((element) => {
        return {
          id: element?.id ?? "",
          label: element?.name ?? "",
        };
      });
      setAdminGrpList(adminList);
    }
  }, [adminGrpDetails]);

  function onCompanySelection(selectedCompany) {
    props.setCompanyErrorState("");
    setCiCompanySelectedList(selectedCompany);
    setAdminGrpList([]);
    if (selectedCompany.length > 0) {
      dispatch(change("EditContract", "company", selectedCompany[0].id));
      dispatch(change("EditContract", "companyName", selectedCompany[0].label));
      dispatch(loadVendorCompany(selectedCompany[0].id));
      dispatch(getadmingroup(selectedCompany[0].id));
      dispatch(listDataWithType("Cost Center", selectedCompany[0].id));
      setCompanyId(selectedCompany[0].id);
      setInputValueCompany(selectedCompany[0].label);
    } else {
      dispatch(change("EditContract", "company", ""));
      dispatch(change("EditContract", "companyName", ""));
    }
  }

  function setCompanyErrorColor() {
    if (ciCompanySelectedList.length === 0) props.setCompanyErrorState("error");
    else props.setCompanyErrorState("");
  }

  function onCompanyInputChange(val) {
    setInputValueCompany(val);
    setAdminGrpList([]);
    setSelectedVendor([]);
  }

  function onCrossClickCompany() {
    dispatch(change("EditContract", "company", ""));
    dispatch(change("EditContract", "companyName", ""));

    setInputValueCompany("");
    setCiCompanySelectedList([]);
    dispatch(change("EditContract", "module", ""));
    props.setCompanyErrorState("error");
    onCrossClickVendor();
    onCostCenterCrossClick();
    onCrossClickBudget();
    onAdminstrationCrossClick();
    onCrossClickAdminGrp();
    setCompanyId("");
  }

  const onVendorSelection = (selectedVendor) => {
    if (selectedVendor.length > 0) {
      setSelectedVendorList([...selectedVendorList, selectedVendor])
      setSelectedVendor(selectedVendor);
      dispatch(change("EditContract", "vendor", selectedVendor));
      dispatch(change("EditContract", "vendor_id", selectedVendor[0].id));
    } else {
      setSelectedVendor([]);
      dispatch(change("EditContract", "vendor", ""));
      dispatch(change("EditContract", "vendor_id", ""));
    }
  };

  // const vendorInputChange = (value) => {
  //   setTypedVendor(value)
  //   setSelectedVendor([])
  //   onVendorSelection([])
  // };

  const onCrossClickVendor = () => {
    // setTypedVendor('')
    setSelectedVendor([]);
    dispatch(change("EditContract", "vendor", ""));
    dispatch(change("EditContract", "vendor_id", ""));
  };

  const onCostCenterSelection = (selectedCostCenter) => {
    if (selectedCostCenter.length > 0) {
      setSelectedCostCenter(selectedCostCenter);
      dispatch(
        change("EditContract", "cost_center_name", selectedCostCenter[0].label)
      );
      dispatch(
        change("EditContract", "cost_center_id", selectedCostCenter[0].id)
      );
    } else {
      setSelectedCostCenter([]);
      dispatch(change("EditContract", "cost_center_name", ""));
      dispatch(change("EditContract", "cost_center_id", ""));
    }
  };

  const costCenterInputChange = (value) => {
    setTypedCostCenter(value)
    setSelectedCostCenter([]);
    onCostCenterSelection([])
  };

  const onCostCenterCrossClick = () => {
    setTypedCostCenter('')
    setSelectedCostCenter([]);
    dispatch(change("EditContract", "cost_center_name", ""));
    dispatch(change("EditContract", "cost_center_id", ""));
  };

  const onBudgetSelected = (suggestion) => {
    // console.log(suggestion, "suggestion0000");
    props.setSelectedBudget(suggestion);
    if (suggestion.length > 0) {
      dispatch(change("EditContract", "budget_id", suggestion[0].id));
      dispatch(change("EditContract", "budget_sponsor", suggestion[0].label));
    } else {
      dispatch(change("EditContract", "budget_id", ""));
      dispatch(change("EditContract", "budget_sponsor", ""));
    }
  };

  const onCrossClickBudget = () => {
    props.setSelectedBudget({});
    dispatch(change("EditContract", "budget_id", ""));
    dispatch(change("EditContract", "budget_sponsor", ""));
  };

  const onAdminGrpSelection = (selectedAdminGrp) => {
    // console.log(selectedAdminGrp, "selectedAdminGrp");
    let error = "";
    if (selectedAdminGrp.length === 0) {
      error = "error";
      props.setAdminErrorColor(error);
    }
    if (selectedAdminGrp.length > 0) {
      setSelectedAdminGrp(selectedAdminGrp);
      dispatch(
        change("EditContract", "administrator_group", selectedAdminGrp[0].label)
      );
      dispatch(
        change("EditContract", "administrator_group_id", selectedAdminGrp[0].id)
      );
      props.setAdminValue(selectedAdminGrp);
      props.setAdminErrorColor("");
    } else {
      setSelectedAdminGrp([]);
      dispatch(change("EditContract", "administrator_group", ""));
      dispatch(change("EditContract", "administrator_group_id", ""));
    }
  };


  const adminInputChange = (value) => {
    setTypedAdminGrp(value)
    setSelectedAdminGrp([])
    onAdminGrpSelection([])
  };
  
  const onCrossClickAdminGrp = () => {
    props.setAdminErrorColor('error')
    setTypedAdminGrp("");
    setSelectedAdminGrp([]);
    dispatch(change("EditContract", "administrator_group", ""));
    dispatch(change("EditContract", "administrator_group_id", ""));
  };

  const renderStatus = () => {
    if (statusDetails && statusDetails.length > 0) {
      return (
        <>
          {statusDetails.map((element, index) => {
            return (
              <option value={element.ID}>
                {element.STATUS_NAME}
              </option>
            );
          })}
        </>
      );
    }
  };

  const renderSubStatus = () => {
    if (subStatusDetails && subStatusDetails.length > 0) {
      // console.log(subStatusDetails, "subStatusDetails");
      return (
        <>
          {subStatusDetails.map((element, index) => {
            return( 
            <option value={element.ID}>
            {element.SUB_STATUS_NAME}
            </option>
            )
          })}
        </>
      );
    }
  };

  
  const setErrorColor = (errorVal,field) => {
    switch (field) {
			case 'administrator_id':
        setadministrator_id(errorVal);
        GLOBAL.adminownerErrorColor = errorVal;
        break;
    }
  };

  const onAdminstrationCrossClick = () => {
    props.setSelectedAdministrator({});
    dispatch(change("EditContract", "administrator_id", ""));
    dispatch(change("EditContract", "administrator_name", ""));
  };

  let companyTypeaheadOptions = [];
  if (ciCompany && ciCompany.length > 0) {
    ciCompany.forEach((item, i) => {
      let obj = {};
      obj.id = item.field1Value;
      obj.label = item.field1Key;
      companyTypeaheadOptions.push(obj);
    });
  }


  useEffect(() => {
    dispatch(getContractPageDetails(params.CONTRACT_NUM));
  }, []);


  const validateEndDate=(date)=>{
    // console.log("Inside ValidateEnd")
    // console.log(date)
    setEndDate(date?._d)
    // console.table({
    //   "EValue":moment().isBefore(startDate),
    //   startDate,
    //   "endDate":date?._d
 
    // })
    if(moment(date?._d).isSameOrBefore(startDate)){
      setEndDateError("error")
    }else{
      setEndDateError('')
    }
 
  }


  const validateStartDate = (date) => {

    // let start_date = momentTz(date, jwtdf + " hh:mm A");
    setStartDate(date?._d);
    // console.log("Inside ValidateStart");
    // console.log(date);
    // console.log("Inside ValidateEnd");
    // console.log(moment(endDate));
    let start_date = momentTz(date, jwtdf + " hh:mm A");
    let end_date = momentTz(endDate, jwtdf + " hh:mm A");
    if (momentTz(start_date).isSameOrAfter(end_date)) {
      setStartDateError("error");
    } else {
      setStartDateError("");
    }
  };

  const validatelastReviewDate=(date)=>{
    setlastReviewDate(date?._d)
    let last_review_date = momentTz(date, jwtdf + " hh:mm A");
    let next_review_date = momentTz(nextReviewDate, jwtdf + " hh:mm A");
    if (momentTz(last_review_date).isSameOrAfter(next_review_date)){
      setlastReviewDateError("error")
      } else {
      setlastReviewDateError('')
      }
    }

  const validatenextReviewDate=(date)=>{
    setnextReviewDate(date?._d)
    if(moment(date?._d).isSameOrBefore(lastReviewDate)){
      setnextReviewDateError("error")
    }else{
      setnextReviewDateError('')
    }
  }

  const validateEditContractFields = (fieldType, event) => {
    var phoneno = /^[0-9;+]*$/;
    
    switch (fieldType) {

      case "vendor_contact":
        if (event.target.value.trim() != "" && !event.target.value.match(phoneno)) {
          props.setNumberErr("error");
        } else {
          props.setNumberErr("");
        }
        break;
        
      case "name":
        if (event.target.value == "") {
          props.setNameErr("error");
        } else {
          props.setNameErr("");
        }
        break;

      case "business_function":
        if (event.target.value == "") {
          props.setBusinessErr("error");
        } else {
          props.setBusinessErr("");
        }
        break;

      case "start_date":
        if (event.target.value == "") {
          props.setStartErr("error");
        } else {
          props.setStartErr("");
        }
        break;

      case "end_date":
        if (event.target.value == "") {
          props.setEndErr("error");
        } else {
          props.setEndErr("");
        }
        break;

      case "status":
        if (event.target.value == "") {
          props.setStatusErr("error");
        } else {
          props.setStatusErr("");
        }
        break;

      case "sub_status":
      if (event.target.value == "") {
        props.setSubStatusErr("error");
        dispatch(change("EditContract", "sub_status", ""));
        dispatch(change("EditContract", "sub_status_name", ""))
      } else {
        props.setSubStatusErr("");
      }
        break;
      default:
    }
  };

  return (
    <>
      {loader.isLoading ? (
        <ListLoader />
      ) : (
        !props.initialValues?.company_id  ? (
          <div className="text-center">You don't have access to view this Contract</div>
      ) : (
       <>
       <Form>
         
          <Row>
          <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <span className="rStar"></span>
                <Form.Label>{tr["Company"]}</Form.Label>
                <Field
                  component={CmdbDropdownPlusTypeahead}
                  onSelection={onCompanySelection}
                  errorColor={props.companyErrorState}
                  setErrorColor={setCompanyErrorColor}
                  name="company"
                  id="company"
                  disabled
                  className="form-control"
                  options={companyTypeaheadOptions}
                  selectedOptions={ciCompanySelectedList}
                  onInputChange={onCompanyInputChange}
                  inputValue={inputValueCompany}
                  onCrossClick={onCrossClickCompany}
                />
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <span className="rStar"></span>
                <Form.Label>{tr["Contract Name"]}</Form.Label>
                <Field
                  component={_inputField}
                  name="name"
                  className={"form-control " + props.nameErr}
                  maxLength={50}
                  onBlur={(event) => {
                    validateEditContractFields("name", event);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
          <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>{tr["Contract Type"]}</Form.Label>
                <Field
                  name="contract_type"
                  component="select"
                  className={"form-control "}
                >
                  <option value="">{tr["Select"]}</option>
                  <option value="Insurance">{tr["Insurance"]}</option>
                  <option value="Lease">{tr["Lease"]}</option>
                  <option value="Maintenance">{tr["Maintenance"]}</option>
                  <option value="Service">{tr["Service"]}</option>
                  <option value="Software">{tr["Software"]}</option>
                </Field>
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <span className="rStar"></span>
                <Form.Label>{tr["Business Function"]}</Form.Label>
                <Field
                  name="business_function"
                  component="select"
                  className={"form-control " + props?.businessErr}
                  onBlur={(event) => {
                    validateEditContractFields("business_function", event);
                  }}
                >
                  <option value="">{tr["Select"]}</option>
                  <option value="Admin">{tr["Admin"]}</option>
                  <option value="Finance">{tr["Finance"]}</option>
                  <option value="HR">{tr["HR"]}</option>
                  <option value="IT">{tr["IT"]}</option>
                  <option value="Support">{tr["Support"]}</option>
                </Field>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <span className="rStar"></span>
                <Form.Label>{tr["Start Date"]}</Form.Label>
                <div className="dateTimeSdiv dateTimeRightPos dateTimeBot">
                  <Field
                    name="start_date"
                    component={_dateTimeField}
                    readOnlyValue={true}
                    className={
                    "form-control dateTimePickerField calIcon " + startDateError
                    }
                    onClick={validateStartDate}
                    onChange={validateStartDate}
                    formType="edit"
                  />
                  {startDateError == "error" ? "Start Date should be same or fall before end date." : ""}
                </div>
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <span className="rStar"></span>
                <Form.Label>{tr["End Date"]}</Form.Label>
                <div className="dateTimeSdiv dateTimeRightPos dateTimeBot">
                  <Field
                    name="end_date"
                    component={_dateTimeField}
                    readOnlyValue={true}
                    className={
                      "form-control dateTimePickerField calIcon " + endDateError
                    }
                    onClick={validateEndDate}
                    onChange={validateEndDate}
                    formType="edit"
                  />
                  {endDateError == "error" ? "End Date should fall after Start Date." : ""}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
              <span className="rStar"></span>
                <Form.Label>{tr["Status"]}</Form.Label>
                <Field
                  name="status"
                  className={"form-control " + props.statusErr}
                  component="select"
                  onBlur={(event) => {
                    validateEditContractFields("status", event);
                  }}
                  onChange={(event) => {
                    // console.log(event.target.value, "llllllllllll");
                    let statusDetails = event.target.value;
                    let statusDetailsArray = statusDetails.split("|");
                    let statusId = statusDetailsArray[0];
                    let statusName = statusDetailsArray[1];
                    dispatch(change("EditContract", "status_id", statusId));
                    dispatch(change("EditContract", "status_name", statusName));
                    dispatch(change("EditContract", "sub_status", ""));
                  dispatch(change("EditContract", "sub_status_name", ""));
                    dispatch(
                      loadCIMenulistSubStatus("CONTRACT_SUBSTATUS", statusId)
                    );
                  }}
                >
                  <option value="">Select</option>
                  {renderStatus()}
                </Field>
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
              <span className="rStar"></span>
                <Form.Label>{tr["Sub Status"]}</Form.Label>
                <Field
                name="sub_status"
                className={"form-control " + props.substatusErr}
                component="select"
                onBlur={(event) => {
                  validateEditContractFields("sub_status", event);
                }}
                onChange={(event) => {
                  // console.log(event.target.value, "llllllllllll");
                  dispatch(change("EditContract", "sub_status", event.target.value));
                  dispatch(change("EditContract", "sub_status_name", event.target[event.target.selectedIndex].text));
                }}
              >
                <option value="">Select</option>
                {renderSubStatus()}
              </Field>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">       
                <Form.Label>{tr["Vendor"]}</Form.Label>
                <div className="dvTypehdropdown">
                <Field
                  name="vendor"
                  className="form-control"
                  component={TypeaheadandDropdown}
                  onSelection={onVendorSelection}
                  options={vendorList}
                  selectedOptions={selectedVendor}
                  // diableStatus={false}
                  // onInputChange={vendorInputChange}
                  onCrossClick={onCrossClickVendor}
                  // typedValue={typedVendor}
                  multiple={true}
              />
                </div>
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>{tr["Vendor Contact"]}</Form.Label>
                <Field
                component={_inputField}
                name="vendor_contact"
                className={"form-control "+(props.numberErr) }
                placeholder="e.g. +919000000000"
                onBlur={(event) => {
                  validateEditContractFields("vendor_contact", event);
                }}
                maxLength={100} 
              />
              <span className="f-size-10">
                Please enter semicolon separated value (Ex. Value 1 ; Value 2 ;
                Value 3)
              </span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
            <span className="rStar"></span>
              <Form.Label>{tr["Contract Group"]}</Form.Label>
              <div className="dvTypehdropdown">
                 {/* {console.log(adminGrpList, "adminGrpListadminGrpList")}
                 {console.log(selectedAdminGrp, "selectedAdminGrp")}
                 {console.log(props.adminTypeErr, "adminTypeErr")} */}
              <Field
                name="administrator_group"
                id="administrator_group_id"
                className="form-control"
                component={TypeaheadandDropdown}
                onSelection={onAdminGrpSelection}
                options={adminGrpList}
                selectedOptions={selectedAdminGrp}
                diableStatus={false}
                onInputChange={adminInputChange}
                onCrossClick={onCrossClickAdminGrp}
                typedValue={typedAdminGrp}
                errorClass={props.adminTypeErr}
              />
              </div>
            </Form.Group>
          </Col>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
              <span className="rStar"></span>
                <Form.Label>{tr["Contract Manager"]}</Form.Label>
                <div className="dvTypehdropdown">
                <AdministratorTypeAhead
                  id="administrator_id"
                  errorColor={administrator_id}
                  administrator_id={administrator_id}
                  setErrorColor={setErrorColor}
                  administratorName={props.initialValues.administrator_name || ""}
                  dispatch={props.dispatch}
                  companyId={props.initialValues.company_id}
                />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row> 
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>{tr["Cost Center"]}</Form.Label>
                <div className="dvTypehdropdown">
                <Field
                  component={TypeaheadandDropdown}
                  onSelection={onCostCenterSelection}
                  name="cost_center_name"
                  className="form-control"
                  options={costCenterList}
                  diableStatus={false}
                  selectedOptions={selectedCostCenter}
                  onCrossClick={onCostCenterCrossClick}
                  onInputChange={costCenterInputChange}
                  typedValue={typedCostCenter}
                />
                </div>
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
              {/* <span className="rStar"></span> */}
                <Form.Label>{tr["Budget Sponsor"]}</Form.Label>
                <div className="dvTypehdropdown">
                <Field
                  component={BudgetTypeAhead}
                  onBudgetSelected={onBudgetSelected}
                  name="budget_sponsor"
                  className="form-control"
                  companyId={props.initialValues.company_id}
                  resetSelectedOffering={onCrossClickBudget}
                  budgetSponser={props.initialValues.budget_sponsor || ""}
                />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              <Form.Label>{tr["Version"]}</Form.Label>
              <Field
                component={"input"}
                name="version"
                className={"form-control "}
                maxLength={50}
              />
            </Form.Group>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              <Form.Label>{tr["Date of Revision"]}</Form.Label>
              <div className="dateTimeSdiv dateTimePosTop">
              <Field
                name="date_revision"
                component={_dateTimeField}
                readOnlyValue={true}
                className={
                  "form-control dateTimePickerField calIcon "
                  }
                formType="edit"
              />
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              <Form.Label>{tr["Last Review Date"]}</Form.Label>
              <div className="dateTimeSdiv dateTimePosTop">
              <Field
               name="last_review_date"
               component={_dateTimeField}
               readOnlyValue={true}
               className={"form-control dateTimePickerField calIcon " + lastReviewDateError}
               onClick={validatelastReviewDate}
               onChange={validatelastReviewDate}
               formType="edit"
              />
              {lastReviewDateError == "error" || props.lastReviewDateError == "error" ? "Last Review Date should fall before Next Review Date." : ""}
              </div>
            </Form.Group>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              <Form.Label>{tr["Next Review Date"]}</Form.Label>
              <div className="dateTimeSdiv dateTimePosTop">
              <Field
               name="next_review_date"
               component={_dateTimeField}
               readOnlyValue={true}
               className={"form-control dateTimePickerField calIcon " + nextReviewDateError}
               formType="edit"
               onClick={validatenextReviewDate}
               onChange={validatenextReviewDate}
              />
              {nextReviewDateError == "error" || props.nextReviewDateError == "error" ? "Next Review Date should fall after Last Review Date." : ""}
              </div>
            </Form.Group>
          </Col>
        </Row>
          <Row>
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              <Form.Label>{tr["Created By"]}</Form.Label>
              <Field
                name="createdBy"
                className={"form-control"}
                component={_inputField}                       
                disabled = {true}
              />
            </Form.Group>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              <Form.Label>{tr["Created Date"]}</Form.Label>
              <Field
                  name="createDate"
                  component={_dateTimeField}
                  readOnly
                  className={"form-control dateTimePickerField calIcon "}
                  formType="edit"
                  disabledValue={true}
                />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              <Form.Label>{tr["Updated By"]}</Form.Label>
              <Field
                name="updatedBy"
                className={"form-control"}
                component={_inputField}                       
                disabled = {true}
              />
            </Form.Group>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              <Form.Label>{tr["Updated Date"]}</Form.Label>
              <Field
                  name="updatedDate"
                  component={_dateTimeField}
                  readOnly
                  className={"form-control dateTimePickerField calIcon "}
                  formType="edit"
                  disabledValue={true}
                />
            </Form.Group>
          </Col>
        </Row>
          <Row>
            <Col md={12} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>{tr["Description"]}</Form.Label>
                <Field
                  component="textarea" 
                  rows="3"
                  name="description"
                  className={"form-control "}
                  maxLength={2000}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
       
       </>
      )
      )}
    </>
  );
};
EditForm = reduxForm({
  form: "EditContract",
  enableReinitialize: true,
})(EditForm);
EditForm = connect(({ contractEditList }) => {
  let formValues = structuredClone(contractEditList);
  return {
    initialValues: {
      name: formValues?.CONTRACT_NAME,
      contract_num: formValues?.CONTRACT_NUM,
      contract_type: formValues?.CONTRACT_TYPE,
      business_function: formValues?.BUSINESS_FUNCTION,
      company: formValues?.COMPANY,
      company_id: formValues?.COMPANY_ID,
      administrator_name: formValues?.ADMINISTRATOR,
      administrator_id: formValues?.ADMINISTRATOR_ID,
      vendor: formValues?.VENDOR,
      vendor_id: formValues?.VENDOR_ID,
      vendor_contact: formValues?.VEDNOR_CONTACT,
      cost_center_name: formValues?.COST_CENTER,
      budget_sponsor: formValues?.BUDGET_SPONSOR,
      budget_id: formValues?.BUDGET_SPONSOR_ID,
      cost_center_id: formValues?.COST_CENTER_ID,
      description: formValues?.DESCRIPTION,
      start_date: formValues?.START_DATE,
      end_date: formValues?.END_DATE,
      status: formValues?.STATUS,
      status_name: formValues?.STATUS_NAME, 
      sub_status: formValues?.SUB_STATUS,
      sub_status_name: formValues?.SUB_STATUS_NAME,
      createdBy: formValues?.CREATED_BY,
      createDate: formValues?.CREATED_AT,
      created_by_id: formValues?.CREATED_BY_ID,
      updatedBy: formValues?.UPDATED_BY,
      updatedDate: formValues?.UPDATED_AT,
      updated_by_id: formValues?.UPDATED_BY_ID,
      version: formValues?.VERSION,
      date_revision: formValues?.DATE_REVISION,
      last_review_date: formValues?.LAST_REVIEW_DATE,
      next_review_date: formValues?.NEXT_REVIEW_DATE,
      administrator_group: formValues?.ADMINISTRATOR_GROUP,
      administrator_group_id: formValues?.ADMINISTRATOR_GROUP_ID
    },
    contractEditList
  }
})(EditForm);

export default EditForm;
