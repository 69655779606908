/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { HiOutlineTrash, HiPlus } from "react-icons/hi";
import { IoClose } from "react-icons/io5";

export const CustomAddRuleButton = ({ handleOnClick }) => {
    const translator = useSelector(state => state.spcmReducer.tr);
    return <>
       <Button className='add_rule' onClick={handleOnClick}>
            {translator['Add Rule']}
       </Button>  
    </>
};

export const CustomAddGroupButton = ({ handleOnClick, level }) => {
    const translator = useSelector(state => state.spcmReducer.tr);
    if(level > 0){
      return;
    }
    return <>
       <Button className='add_rule' onClick={handleOnClick} title={translator['Add Group']}>
            <HiPlus />
       </Button>  
    </>
};

export const CustomRemoveRuleButton = ({ handleOnClick }) => {
    const translator = useSelector(state => state.spcmReducer.tr);
    return <>
       <Button className="ruleGrpBtn" onClick={handleOnClick} title={translator['Remove Rule']}>
            <IoClose />
       </Button>  
    </>
};

export const CustomRemoveGroupButton = ({ handleOnClick }) => {
    const translator = useSelector(state => state.spcmReducer.tr);
    return <>
       <Button className="ruleGrpBtn" onClick={handleOnClick} title={translator['Remove Group']}>
            <HiOutlineTrash />
       </Button>  
    </>
};