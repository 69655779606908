
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState, useRef,useContext} from "react";
import { MyContext } from '_MyContext';
import { ListGroup, Row, Col, Tabs, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";

const MyAssetShortDesc = (props) => {
  const closeButton = useRef(null);
  const context = useContext(MyContext);

  const tr = useSelector((state) => state.spcmReducer.tr);

  let businessOwnerName = '';
  businessOwnerName = props.BUSINESS_OWNER_NAME.split("|");
  businessOwnerName = businessOwnerName[0];

  let technicalOwnerName = '';
  technicalOwnerName = props.TECHNICAL_OWNER_NAME.split("|");
  technicalOwnerName = technicalOwnerName[0];

  useEffect(() => {
    context.keyDownHandler(closeButton.current);
  }, []);

  return (
    <div className="">
      <Row>
        <Col xs={12}>
          <div className="rPageHeadActBtn">
            <ul>
              <li>
                <Link to="javascript:void(0)" className="closeRsBtn" id="closeButton"
                    title={tr["Minimize the details panel"]}
                    onClick={() => {
                      props.showRightSideFun(false);
                    }}
                    ref={closeButton}
                  ><IoClose /></Link>
              </li>
            </ul>
          </div>
          <div className="rPageHeading">
            {props.CI_CODE}
            <div className="offNam">{props.CI_NAME}</div>
          </div>
        </Col>
      </Row>
      <div className="rBoxGap">
        <div className="rBoxTabs hideTabNav">
          <Tabs id="tabId">
            <Tab eventKey={1} title="ASSET/CI DETAILS">
              <ListGroup bsPrefix=" " bsClass="">
                <ListGroup.Item
                  bsPrefix=" "
                  bsClass=""
                  className="rwTable"
                  style={{ "border-top": "none" }}
                >
                  <span className="rwKey">
                    {tr["Name"]}{" "}
                  </span>
                  <span className="rwVal">{props.CI_NAME}</span>
                </ListGroup.Item>
                {props.COMPANY_NAME != "" ? (
                  <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                    <span className="rwKey">
                      {tr["Company"]}{" "}
                    </span>
                    <span className="rwVal">{props.COMPANY_NAME}</span>
                  </ListGroup.Item>
                ) : (
                  ""
                )}
                {props.CLASS_NAME != "" ? (
                  <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                    <span className="rwKey">
                      {tr["Class"]}{" "}
                    </span>
                    <span className="rwVal">{props.CLASS_NAME}</span>
                  </ListGroup.Item>
                ) : (
                  ""
                )}
                {props.TYPE != "" ? (
                  <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                    <span className="rwKey">
                      {tr["Type"]}{" "}
                    </span>
                    <span className="rwVal">
                      {props.TYPE == "5"
                        ? "Asset"
                        : props.TYPE == "10"
                        ? "CI"
                        : "Both"}
                    </span>
                  </ListGroup.Item>
                ) : (
                  ""
                )}
                {props.STATUS_NAME != "" ? (
                  <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                    <span className="rwKey">
                      {tr["Status"]}{" "}
                    </span>
                    <span className="rwVal">{props.STATUS_NAME}</span>
                  </ListGroup.Item>
                ) : (
                  ""
                )}
                {props.SUB_STATUS_NAME != "" ? (
                  <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                    <span className="rwKey">
                      {tr["Sub-Status"]}{" "}
                    </span>
                    <span className="rwVal">{props.SUB_STATUS_NAME}</span>
                  </ListGroup.Item>
                ) : (
                  ""
                )}
                {props.BUSINESS_OWNER_NAME != "" ? (
                  <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                    <span className="rwKey">
                      {tr["Business Owner"]}{" "}
                    </span>
                    <span className="rwVal">{businessOwnerName}</span>
                  </ListGroup.Item>
                ) : (
                  ""
                )}
                {props.TECHNICAL_OWNER_NAME != "" ? (
                  <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                    <span className="rwKey">
                      {tr["Technical Owner"]}{" "}
                    </span>
                    <span className="rwVal">{technicalOwnerName}</span>
                  </ListGroup.Item>
                ) : (
                  ""
                )}
                {props.UPDATED_AT != null ? (
                  <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                    <span className="rwKey">
                      {tr["Modified On"]}{" "}
                    </span>
                    <span className="rwVal">{props.UPDATED_AT}</span>
                  </ListGroup.Item>
                ) : (
                  ""
                )}
                {props.ASSET_TAG != "" ? (
                  <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                    <span className="rwKey">
                      {tr["Asset Tag"]}{" "}
                    </span>
                    <span className="rwVal">{props.ASSET_TAG}</span>
                  </ListGroup.Item>
                ) : (
                  ""
                )}
                {props.CATEGORY_NAME == null ||
                props.CATEGORY_NAME == "|" ? (
                  ""
                ) : (
                  <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                    <span className="rwKey">
                      {tr["Categorization"]}{" "}
                    </span>
                    <span className="rwVal">
                      {props.CATEGORY_NAME}{" "}
                      {props.SUB_CATEGORY_NAME == ""
                        ? ""
                        : "|" + props.SUB_CATEGORY_NAME}
                    </span>
                  </ListGroup.Item>
                )}
                {props.METALLIC_VALUE != null ? (
                  <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                    <span className="rwKey">
                      {tr["Business Criticality"]}{" "}
                    </span>
                    <span className="rwVal">{props.METALLIC_VALUE}</span>
                  </ListGroup.Item>
                ) : (
                  ""
                )}
                {props.ENVIRONMENT != null ? (
                  <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                    <span className="rwKey">
                      {tr["Environment"]}{" "}
                    </span>
                    <span className="rwVal">{props.ENVIRONMENT}</span>
                  </ListGroup.Item>
                ) : (
                  ""
                )}
                {props.LOCATION_NAME != null ? (
                  <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                    <span className="rwKey">
                      {tr["Location"]}{" "}
                    </span>
                    <span className="rwVal">{props.LOCATION_NAME}</span>
                  </ListGroup.Item>
                ) : (
                  ""
                )}
              </ListGroup>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default MyAssetShortDesc;
