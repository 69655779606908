
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Row, Col, PageHeader, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
// import { push } from 'react-router-redux';
let sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

import { GLOBAL } from "_Globals";
import axios from "axios";

//let user_id = userDetails.user_id;
//let user_name = userDetails.user_name
import { reduxForm, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createCategory } from "_Actions/categoryBoard/quickViewAction.js";
import { getCompanyList } from "_Actions/spcmActions";
import { ImSpinner6 } from "react-icons/im";
import { IoSaveOutline, IoClose } from "react-icons/io5";
import { MyContext } from '_MyContext';

class CreateCategoryHeader extends React.Component {
  static contextType = MyContext;
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
    this.state = {};
  }

  componentDidMount() {
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
    this.context.newTabPrevent();
	}
	componentDidUpdate() {
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
    this.context.newTabPrevent();
	}

  submit(values) {
    console.log("values --- ", values);
    if((!values.BUSINESS_FUNCTION_ID && (values.BUSINESS_FUNCTION_ID && values.BUSINESS_FUNCTION_ID !== 0)) || !values.BUSINESS_FUNCTION_NAME || values.BUSINESS_FUNCTION_NAME.trim() === "") {
      this.props.setBusinessFnErrorColor();
      return false;
    }
    let companyName;
    this.props.spcmReducer.companyList.map(function (item, index) {
      if (values.COMPANY_ID == item.field1Value) {
        companyName = item.field1Key;
      }
    });
    return sleep(1000) // simulate server latency
      .then(() => {
        if (`${JSON.stringify(values, null, 2)}` == "{}") {
          throw new SubmissionError({
            _error:
              "The form has missing required fields, please revalidate the highlighted fields and update the form",
          });
        }
        // else if (values.slaStatusValue == "" || values.slaServiceName == "") {
        //   if (values.slaStatusValue == "") {
        //     GLOBAL.slaStatusErrorColor = "error";
        //   } else {
        //     GLOBAL.slaStatusErrorColor = "";
        //   }
        //   if (values.slaServiceName == "") {
        //     GLOBAL.slaServiceNameErrorColor = "error";
        //   } else {
        //     GLOBAL.slaServiceNameErrorColor = "";
        //   }
        //   throw new SubmissionError({ _error: 'The form has missing required fields, please revalidate the highlighted fields and update the form !' });
        // }
        else {
          let postjson;
          // if (values.CATEGORY_ID == undefined) {
          if (
            values.category_sequence == undefined &&
            values.category_pinned == undefined
          ) {
            postjson = {
              category_name: values.CATEGORY_NAME,
              sub_category_name: values.SUB_CATEGORY_NAME,
              status: 1,
              status_name: values.STATUS_NAME,
              isvisible: "Y",
              company_name: companyName,
              company_id: values.COMPANY_ID,
              category_pinned: "false",
              category_sequence: "1",
              business_function_id: values.BUSINESS_FUNCTION_ID,
              business_function_name: values.BUSINESS_FUNCTION_NAME
            };
          } else if (values.category_pinned == undefined) {
            postjson = {
              category_name: values.CATEGORY_NAME,
              sub_category_name: values.SUB_CATEGORY_NAME,
              status: 1,
              status_name: values.STATUS_NAME,
              isvisible: "Y",
              company_name: companyName,
              company_id: values.COMPANY_ID,
              category_pinned: "false",
              category_sequence: values.category_sequence.toString(),
              business_function_id: values.BUSINESS_FUNCTION_ID,
              business_function_name: values.BUSINESS_FUNCTION_NAME
            };
          } else if (
            values.category_pinned == true &&
            values.category_sequence == undefined
          ) {
            postjson = {
              category_name: values.CATEGORY_NAME,
              sub_category_name: values.SUB_CATEGORY_NAME,
              status: 1,
              status_name: values.STATUS_NAME,
              isvisible: "Y",
              company_name: companyName,
              company_id: values.COMPANY_ID,
              category_pinned: "true",
              category_sequence: "1",
              business_function_id: values.BUSINESS_FUNCTION_ID,
              business_function_name: values.BUSINESS_FUNCTION_NAME
            };
          } else {
            postjson = {
              category_name: values.CATEGORY_NAME,
              sub_category_name: values.SUB_CATEGORY_NAME,
              status: 1,
              status_name: values.STATUS_NAME,
              isvisible: "Y",
              company_name: companyName,
              company_id: values.COMPANY_ID,
              category_pinned: "true",
              category_sequence: values.category_sequence.toString(),
              business_function_id: values.BUSINESS_FUNCTION_ID,
              business_function_name: values.BUSINESS_FUNCTION_NAME
            };
          }
          // }
          // else {
          //   postjson = {
          //     "category_id": values.CATEGORY_ID,
          //     "category_name": values.CATEGORY_NAME,
          //     "sub_category_name": values.SUB_CATEGORY_NAME,
          //     "status": 0,
          //     "status_name": values.STATUS_NAME,
          //     "isvisible": 'Y'
          //   };
          // }
          console.log(postjson);
          this.props.createCategory(postjson);
        }
      });
  }

  render() {
    let { handleSubmit, pristine, reset, submitting } = this.props;
    return (
      <Row className="margin-b-15">
        <Col lg={4} md={6} sm={4} xs={8}>
          <h2 bsClass="" className="sPageHeading1">
            {this.props.translator["Service Category"]}
          </h2>
        </Col>
        <Col lg={8} md={6} sm={8} xs={4}>
          <div className="paHedFilter">
            <Link
              bsPrefix=" "
              bsStyle=""
              bsClass=""
              to="javascript:void(0)"
              className="myBt plus"
              onClick={handleSubmit(this.submit)}
              disabled={submitting}
              title={this.props.translator["Save"]}
              ref={(e) => (this.saveObjRef = e)}
            >
              {submitting ? (
                <ImSpinner6 className="icn-spinner" />
              ) : (
                <IoSaveOutline />
              )}
            </Link>
            <Link
              bsPrefix=" "
              className="myBt cancel fillBtn"
              title={this.props.translator["Close"]}
              to="/categoryBoard"
              ref={e => this.closeButton = e}
            >
              <IoClose />
            </Link>
          </div>
        </Col>
      </Row>
    );
  }
}

CreateCategoryHeader = reduxForm({
  form: "xsmCategoryCreateForm",
  destroyOnUnmount: true,
})(CreateCategoryHeader);

export function mapStateToProps({ showLoader, spcmReducer }) {
  return {
    showLoader: showLoader.loading,
    spcmReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ createCategory, getCompanyList }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateCategoryHeader);
// export default XsmSupportHeader;
