
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef } from "react";
import { Row, Col, Accordion, Tab, Tabs, ListGroup, Button } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { PiPencilSimpleBold } from "react-icons/pi";
import { IoClose } from "react-icons/io5";
import { Link, NavLink } from "react-router-dom";
import { MyContext } from '_MyContext';

const ShortDescription = (props) => {
    const navigate = useNavigate();
    const tr = useSelector((state) => state.spcmReducer.tr);
    const context = useContext(MyContext);
    const closeButton = useRef(null);
    const editButton = useRef(null);

    useEffect(() => {
        context.keyDownHandler(closeButton.current,editButton.current,"","");
        context.newTabPrevent();
    }, []);

    return (
        <>
            <Row>
                <Col xs={12}>
                    <div className="rPageHeadActBtn" style={{ "marginTop": "-3px" }}>
                        <ul>
                            <li>
                                <NavLink to={"/onCallSchedule/editSchedule/" + props.selectedItem.scheduleId} title={tr["Edit"]} ref={editButton} className="ctrlKeyPrevent" >
                                    <PiPencilSimpleBold />
                                </NavLink>
                            </li>
                            <li>
                                <Link to="javascript:void(0)" title={tr["Minimize the details panel"]} className="ctrlKeyPrevent" onClick={()=>{props.showRightSideFun(false)}} ref={closeButton} >
                                    <IoClose/>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="rPageHeading">
                        <div className="offNam margin-t-5 margin-b-5">{tr["Schedule Details"]}</div>
                    </div>
                </Col>
            </Row>
            <div className="rBoxGap">
                <div className="rBoxTabs">
                    <ListGroup as="ul" bsPrefix=" ">
                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable" style={{ "borderTop": "none" }}><span className="rwKey">{tr["Name"]}</span><span className="rwVal">{props.selectedItem.scheduleName}</span></ListGroup.Item>
                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Type"]}</span><span className="rwVal">{props.selectedItem.scheduleType}</span></ListGroup.Item>
                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Timezone"]}</span><span className="rwVal">{props.selectedItem.timeZone}</span></ListGroup.Item>
                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Status"]}</span><span className="rwVal">{props.selectedItem.status}</span></ListGroup.Item>
                    </ListGroup>
                </div>
            </div>
        </>
    )
}

export default ShortDescription;