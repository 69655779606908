
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Container, Form, Nav, NavDropdown, FormControl, Button, Table, ButtonToolbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { change, Field, reduxForm, formValueSelector } from 'redux-form';
import { useDispatch, useSelector, connect } from 'react-redux';
import { _textArea, _dropDown, _inputField, _checkbox, _number, _dynamicNamedropDown } from "../common/formFields.js";
import { loadAdminPreferencesListData } from '_Actions/adminPreferences/adminPreferencesAction';
import { loadTrainingMetricsByObjectiveNameAndOrganizationId } from '_Actions/aism/aismAction';
import ListLoader from "../common/loaders/ListLoader.js"
import Cookies from 'universal-cookie';
import { IoCheckmarkSharp, IoClose } from "react-icons/io5";
import axios from "axios";
import crypto from "crypto-js";
import { PiToggleRightFill, PiToggleLeftFill } from 'react-icons/pi';
import { IoMdOptions } from "react-icons/io";
import Modal from "react-bootstrap/Modal";

const cookies = new Cookies();
let companyIdLoggedIn = cookies.get('gph');
if (companyIdLoggedIn) companyIdLoggedIn = companyIdLoggedIn.replace('s:', '');
if (companyIdLoggedIn) companyIdLoggedIn = companyIdLoggedIn.substring(0, companyIdLoggedIn.lastIndexOf('.'));
companyIdLoggedIn = companyIdLoggedIn.split("~");
companyIdLoggedIn = companyIdLoggedIn[48];

let cookiesArray = cookies.get('gph');
if (cookiesArray) cookiesArray = cookiesArray.replace('s:', '');
if (cookiesArray) cookiesArray = cookiesArray.substring(0, cookiesArray.lastIndexOf('.'));
cookiesArray = cookiesArray.split("~");
let isSuperAdmin = cookiesArray[60];

const encrypt = (text) => {
    let secret_key = "xsm-ciphertext";
    const result = crypto.AES.encrypt(text, secret_key);
    return result.toString();
}

const decrypt = (text) => {
    let secret_key = "xsm-ciphertext";
    const bytes = crypto.AES.decrypt(text, secret_key);
    return bytes.toString(crypto.enc.Utf8);
}

const styles = {
    grid: {
        paddingLeft: 0,
        paddingRight: 0
    },
    row: {
        marginLeft: 0,
        marginRight: 0
    },
    col: {
        paddingLeft: 0,
        paddingRight: 0
    }
};

const IndividualPropertyByModuleAISM = (props) => {
    const dispatch = useDispatch();
    const [selectedValue, setSelectedValue] = useState(props.propertyData.overriddenValue ? props.propertyData.overriddenValue : props.propertyData.defaultValue);
    const [selectedSYOValue, setSelectedSYOValue] = useState(props.propertyData.overrideSYO ? props.propertyData.overrideSYO : (props.propertyData.defaultSYO ?? ''));
    const [hasUnsavedValue, setHasUnsavedValue] = useState(false);
    const [hasUnsavedSYOValue, setHasUnsavedSYOValue] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [saveLoader, setSaveLoader] = useState(false);
    // const [enabled, setEnabled] = useState(false);
    const [showModelPopup, setShowModelPopup] = useState(false);

    const selector = formValueSelector('adminPreferencesListForm');
    const companyIdParam = useSelector(state => selector(state, 'company'));

    const translator = useSelector((state) => state.spcmReducer.tr);

    useEffect(() => {
        setSelectedValue(props.propertyData.overriddenValue ? props.propertyData.overriddenValue : props.propertyData.defaultValue);
        setSelectedSYOValue(props.propertyData.overrideSYO ? (props.moduleName == "Knowledge" && props.propertyData.propertyId == "14" ? decrypt(props.propertyData.overrideSYO) : props.propertyData.overrideSYO) : (props.propertyData.defaultSYO ?? ''));
        setHasUnsavedValue(false);
        setHasUnsavedSYOValue(false);
        setErrorText('');
    }, [props.propertyData]);

    useEffect(() => {
        dispatch(loadTrainingMetricsByObjectiveNameAndOrganizationId());
    },[]);

    function changeHasUnsavedValue(val) {
        let data = props.propertyData.overriddenValue ? props.propertyData.overriddenValue : props.propertyData.defaultValue;
        if (val != data) {
            setHasUnsavedValue(true);
        } else {
            setHasUnsavedValue(false);
        }
    }

    function changeHasUnsavedSYOValue(val) {
        let data = props.propertyData.overrideSYO ? props.propertyData.overrideSYO : (props.propertyData.defaultSYO ?? '');
        if (val != data) {
            setHasUnsavedSYOValue(true);
        } else {
            setHasUnsavedSYOValue(false);
        }
    }

    function refreshAdminPreferencesList() {
        props.setRetainSelectedModule(true);
        if (props.instanceOrCompanySelection == 'Instance') {
            dispatch(loadAdminPreferencesListData("", props.instanceOrCompanySelection, props.onlyShowOverridden));
        } else if (props.instanceOrCompanySelection == 'Company') {
            if (isSuperAdmin == 'Y') {
                dispatch(loadAdminPreferencesListData(companyIdParam, props.instanceOrCompanySelection, props.onlyShowOverridden));
            } else {
                dispatch(loadAdminPreferencesListData(companyIdLoggedIn, props.instanceOrCompanySelection, props.onlyShowOverridden));
            }
        }
    }

    function deletePropertyData(companyId) {
        let arr = [];
        let obj = {};
        obj["companyId"] = companyId;
        obj["module"] = props.moduleName;
        obj["property"] = props.propertyData.propertyName;
        arr.push(obj);
        setSaveLoader(true);
        axios.delete('/api/deleteAdminPreferences', { data: arr })
            .then(function (response) {
                if (response.status == 200) {
                    refreshAdminPreferencesList();
                }
                setSaveLoader(false);
            })
            .catch(function (error) {
                setSaveLoader(false);
            });
    }

    function postPropertyData(companyId, selectedValue, selectedSYOValue) {
        let arr = [];
        let obj = {};
        obj["companyId"] = companyId;
        obj["module"] = props.moduleName;
        obj["property"] = props.propertyData.propertyName;
        obj["configurableOn"] = props.instanceOrCompanySelection;
        obj["value"] = selectedValue;
        if(selectedSYOValue){
            obj["specifyYourOwn"] = selectedSYOValue
        }
        arr.push(obj);
        setSaveLoader(true);
        axios.post('/api/postAdminPreferences', arr)
            .then(function (response) {
                if (response.status == 200) {
                    refreshAdminPreferencesList();
                }
                setSaveLoader(false);
            })
            .catch(function (error) {
                setSaveLoader(false);
            });
    }

    function updatePropertyData() {
        let companyId;
        if (props.instanceOrCompanySelection == 'Instance') {
            companyId = '0';
        } else if (props.instanceOrCompanySelection == 'Company') {
            if (isSuperAdmin == 'Y') {
                companyId = companyIdParam;
            } else {
                companyId = companyIdLoggedIn;
            }
        }
        // if (props.propertyData.fieldType == "Textbox") {
        // } else 
        if (props.propertyData.fieldType == "Dropdown" && selectedValue == "Specify Your Own") {
            if (props.moduleName == "Knowledge" && props.propertyData.propertyId == "14") {
                if (selectedSYOValue == (props.propertyData.defaultSYO ?? '')) {
                    deletePropertyData(companyId);
                } else {
                    postPropertyData(companyId, selectedValue, encrypt(selectedSYOValue));
                }
            } else {
                let regex = new RegExp(props.propertyData.regex);
                // let regex = /^[0-9\s]*$/;
                if (!selectedSYOValue || selectedSYOValue.trim() == '' || !regex.test(selectedSYOValue)) {
                    setErrorText(props.propertyData.message);
                } else {
                    setErrorText('');
                    if (selectedSYOValue == (props.propertyData.defaultSYO ?? '')) {
                        deletePropertyData(companyId);
                    } else {
                        postPropertyData(companyId, selectedValue, selectedSYOValue);
                    }
                }
            }
        } else {
            if (selectedValue == props.propertyData.defaultValue) {
                deletePropertyData(companyId);
            } else {
                postPropertyData(companyId, selectedValue);
            }
        }
    }

    function resetUnsavedValue() {
        setSelectedValue(props.propertyData.overriddenValue ? props.propertyData.overriddenValue : props.propertyData.defaultValue);
        setSelectedSYOValue(props.propertyData.overrideSYO ? (props.moduleName == "Knowledge" && props.propertyData.propertyId == "14" ? decrypt(props.propertyData.overrideSYO) : props.propertyData.overrideSYO) : (props.propertyData.defaultSYO ?? ''));
        setHasUnsavedValue(false);
        setHasUnsavedSYOValue(false);
        setErrorText('');
    }

    function resetPropertyData() {
        setSelectedValue(props.propertyData.defaultValue);
        setSelectedSYOValue(props.propertyData.defaultSYO ?? '');
        setHasUnsavedValue(true);
        setHasUnsavedSYOValue(true);
    }

    function renderSYOField() {
        return (
            <Row className="pt-7" style={styles.row}>
                <Col lg={12} md={12} sm={12} xs={12} style={styles.col}>
                    <Form.Control
                        name={"property_SYO_" + props.propertyData.propertyId}
                        type="text"
                        placeholder="Threshold:$number, JobId:$number"
                        onChange={(event) => { setSelectedSYOValue(event.target.value); changeHasUnsavedSYOValue(event.target.value) }}
                        value={hasUnsavedSYOValue ? selectedSYOValue : ''}
                        disabled={isSuperAdmin != 'Y' && props.instanceOrCompanySelection == 'Instance' ? true : false}
                    />
                </Col>
            </Row>
        );
    }

    function renderField() {
            return (
                <>
                    <Form.Control
                        name={"property_" + props.propertyData.propertyId}
                        type="text"
                        onClick={() => {setShowModelPopup(true);
                            const root = document.documentElement;
                            root?.style.setProperty("--modalBackDrop", 0.5);
                        }}
                        placeholder="Click here to select model"
                        value={selectedValue?.trim().length > 0 ? selectedValue : ''}
                        disabled={isSuperAdmin != 'Y' && props.instanceOrCompanySelection == 'Instance' ? true : false}
                    />
                    {
                        selectedValue && selectedValue.trim() != '' ? renderSYOField() : null
                    }
                </>
            );
    }

    function renderActionButtons() {
        if (hasUnsavedValue || hasUnsavedSYOValue) {
            return (
                <>
                    <span className="changes-reset-btn"><a href="javascript:void(0)" onClick={() => { if(!saveLoader) {updatePropertyData()} }}><IoCheckmarkSharp /></a></span>
                    &nbsp;
                    <span className="changes-reset-btn"><a href="javascript:void(0)" onClick={() => { if(!saveLoader) {resetUnsavedValue()} }}><IoClose /></a></span>
                </>
            );
        } else {
            if ((props.propertyData.overriddenValue && props.propertyData.overriddenValue != props.propertyData.defaultValue) || (props.propertyData.overrideSYO && props.propertyData.overrideSYO != (props.propertyData.defaultSYO ?? ''))) {
                return (
                    <span className="changes-reset-btn"><a href="javascript:void(0)" onClick={() => { if(!saveLoader) {resetPropertyData()} }}><i className="fa fa-undo"></i> {translator['Reset']}</a></span>
                )
            } else {
                return null;
            }
        }
    }

    console.log('selectedValue------------', selectedValue)
    return (
        <Row style={styles.row}>
            <Col lg={3} md={3} sm={12} xs={12} style={styles.col}>
                <div className="p-10">
                    <label>{props.propertyData.propertyName}</label>
                </div>
            </Col>
            <Col lg={9} md={9} sm={12} xs={12} style={styles.col} className="bg-lightBlue">
                <div className="p-10">
                    {showModelPopup ?
                        <Modal
                            show={showModelPopup}
                            {...props}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            className="escalation-level-form"
                        >
                            <Modal.Header>
                                <Row>
                                    <Col lg={6} md={6} sm={12} xs={12}>
                                        <h2 className="sPageHeading1">Select Model</h2>
                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12}>
                                        <div className="paHedFilter">
                                            <Button
                                                bsPrefix=" "
                                                role="button"
                                                className="myBt cancel fillBtn"
                                                title={translator["Cancel"]}
                                                onClick={() => { setShowModelPopup(false); }}
                                            >
                                                <IoClose />
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col md={12} sm={12} xs={12}>
                                        <Form.Label><span className="rStar"></span>Model List</Form.Label>
                                        <div style={{ marginTop: "10px" }}>
                                            Model 1 <Button title="Select" bsSize="small" bsStyle="text" onClick={() => { setSelectedValue('Model 1'); setShowModelPopup(false); changeHasUnsavedValue('Model 1'); }}>Select</Button>
                                            Model 2 <Button title="Select" bsSize="small" bsStyle="text" onClick={() => { setSelectedValue('Model 2'); setShowModelPopup(false); changeHasUnsavedValue('Model 2'); }}>Select</Button>
                                            Model 3 <Button title="Select" bsSize="small" bsStyle="text" onClick={() => { setSelectedValue('Model 3'); setShowModelPopup(false); changeHasUnsavedValue('Model 3'); }}>Select</Button>
                                            {/* <br />{repeatsOnError ? <span style={{ color: "red", fontSize: "smaller" }}>Please select atleast one day.</span> : ''} */}
                                        </div>
                                    </Col>
                                </Row>
                            </Modal.Body>
                        </Modal>
                        : null}
                    <Row style={styles.row}>
                        <Col lg={9} md={9} sm={12} xs={12} style={styles.col}>
                            <div className="w-80">
                                {renderField()}
                            </div>
                            {/* {errorText && errorText != "" ? <span style={{ color: "red", fontSize: "smaller" }}>{errorText}</span> : null} */}
                            <span className="mt-1 inst-text">{props.propertyData.description}</span>
                        </Col>
                        <Col lg={3} md={3} sm={12} xs={12} style={styles.col}>
                            {
                                (isSuperAdmin == 'Y' || (isSuperAdmin != 'Y' && props.instanceOrCompanySelection == 'Company')) ?
                                    renderActionButtons()
                                    : null
                            }
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    );
}

export default reduxForm({
    form: "adminPreferencesListForm",
    destroyOnUnmount: true,
    enableReinitialize: true
})(IndividualPropertyByModuleAISM);