
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const tableBlockJson = {
  label: "Table data grid",
  category: "Basic",
  // attributes: { class: "fa fa-table" },
  media:`<svg viewBox="0 0 16.933333 21.1666675" version="1.1" x="0px" y="0px"><g><path style="color:#000000;" d="m 2.0711928,0.79375167 c -0.7031275,0 -1.2774427,0.57431003 -1.2774427,1.27744003 V 14.862142 c 0,0.70313 0.5743152,1.27744 1.2774427,1.27744 H 14.862143 c 0.703125,0 1.27744,-0.57431 1.27744,-1.27744 V 2.0711917 c 0,-0.70313 -0.574315,-1.27744003 -1.27744,-1.27744003 z m 0,0.52710003 H 14.862143 c 0.419119,0 0.748274,0.33122 0.748274,0.75034 v 2.33991 H 1.3229168 v -2.33991 c 0,-0.41912 0.3291548,-0.75034 0.748276,-0.75034 z m -0.748276,3.61941 h 3.1739654 v 3.20291 H 1.3229168 Z m 3.7031321,0 h 3.1760344 v 3.20291 H 5.0260489 Z m 3.7052011,0 h 3.173965 v 3.20291 H 8.73125 Z m 3.703132,0 h 3.176035 v 3.20291 H 12.434382 Z M 1.3229168,8.6728517 H 4.4968822 V 11.875762 H 1.3229168 Z m 3.7031321,0 H 8.2020833 V 11.875762 H 5.0260489 Z m 3.7052011,0 h 3.173965 V 11.875762 H 8.73125 Z m 3.703132,0 h 3.176035 V 11.875762 H 12.434382 Z M 1.3229168,12.404922 h 3.1739654 v 3.20498 H 2.0711928 c -0.4191212,0 -0.748276,-0.32864 -0.748276,-0.74776 z m 3.7031321,0 h 3.1760344 v 3.20498 H 5.0260489 Z m 3.7052011,0 h 3.173965 v 3.20498 H 8.73125 Z m 3.703132,0 h 3.176035 v 2.45722 c 0,0.41912 -0.329155,0.74776 -0.748274,0.74776 h -2.427761 z" fill="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/></g><text x="0" y="31.933334" fill="#000000" font-size="5px" font-weight="bold" font-family="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif">Created by Larea</text><text x="0" y="36.933334" fill="#000000" font-size="5px" font-weight="bold" font-family="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif">from the Noun Project</text></svg>`,
  content: {
    tagName: "div",
    type: "table",
    style: {
      width: "100%",
      height: "100%",
      borderCollapse: "collapse",
    },
    editable: true,
    droppable: false,
    components: `
        <div class="table-responsive"><table style="width: 100%; border: 1px solid #ddd; border-collapse: collapse;" border="1">
          <thead style="background-color: #eaeaea; color: #000;">
            <tr>
              <th data-gjs-type="custom-th" style="padding: 8px; border: 1px solid #ddd;">Name</th>
              <th data-gjs-type="custom-th" style="padding: 8px; border: 1px solid #ddd;">Company</th>
              <th data-gjs-type="custom-th" style="padding: 8px; border: 1px solid #ddd;">Track</th>
              <th data-gjs-type="custom-th" style="padding: 8px; border: 1px solid #ddd;">Sub Track</th>
              <th data-gjs-type="custom-th" style="padding: 8px; border: 1px solid #ddd;">Region</th>
              <th data-gjs-type="custom-th" style="padding: 8px; border: 1px solid #ddd;">Type</th>
              <th data-gjs-type="custom-th" style="padding: 8px; border: 1px solid #ddd;">Status</th>
              <th data-gjs-type="custom-th" style="padding: 8px; border: 1px solid #ddd;">Created On</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td data-gjs-type="custom-td" style="padding: 8px; border: 1px solid #fff;">Test</td>
              <td data-gjs-type="custom-td" style="padding: 8px; border: 1px solid #fff;">HCL Tech</td>
              <td data-gjs-type="custom-td" style="padding: 8px; border: 1px solid #fff;">ABC</td>
              <td data-gjs-type="custom-td" style="padding: 8px; border: 1px solid #fff;">Test Sub-T</td>
              <td data-gjs-type="custom-td" style="padding: 8px; border: 1px solid #fff;">ap-east-12</td>
              <td data-gjs-type="custom-td" style="padding: 8px; border: 1px solid #fff;">Test</td>
              <td data-gjs-type="custom-td" style="padding: 8px; border: 1px solid #fff;">Active</td>
              <td data-gjs-type="custom-td" style="padding: 8px; border: 1px solid #fff;">12-07-2024</td>
            </tr>
          </tbody>
        </table></div>
      `,
  }
}; /// end of table block json
