
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import {Row,Col,FormGroup,ControlLabel,FormControl,Table,Alert,ButtonToolbar,Button} from 'react-bootstrap';
import { Field, reduxForm,SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import axios from 'axios';
import constants from '../../../utils/constants';
import { _textArea, _dropDown, _inputField} from '../../common/formFields';
import { spcmLoader, setLeftEditId,getServiceDetailCounts,getSelfServices } from '../../../actions/spcmActions';
import {ImSpinner6} from "react-icons/im";

const validate = (values,props) => {
	const errors={};
	if(!values.action)
			errors.action="Required";
	if(!values.description)
			errors.description="Required";
	props.extraCounts.forEach(val=>{
		if(!values["action"+val.toString()])
			errors["action"+val.toString()]="Required";
		if(!values["description"+val.toString()])
			errors["description"+val.toString()]="Required";
	})
	return errors;

}

class ComponentForm extends React.Component{
		constructor(props){
	super(props);
		this.state = {
			arrayCounts:1,
			extraCounts:[0]
		};
		this.loadCount = this.loadCount.bind(this);
		this.addRow = this.addRow.bind(this);
		this.deleteRow = this.deleteRow.bind(this);
		this.componentWillMount = this.componentWillMount.bind(this);
		this.submitForm = this.submitForm.bind(this);
	};

	addRow(){
		this.setState({ arrayCounts: this.state.arrayCounts+1 });
		let arrayvar = this.state.extraCounts.slice();
		arrayvar.push(this.state.arrayCounts);
		this.setState({ extraCounts: arrayvar });
		this.props.setExtraCount(arrayvar);
	};
	deleteRow(myIndex){
		let arrayvar = this.state.extraCounts;
		let index = arrayvar.indexOf(myIndex);
		arrayvar.splice(index,1);
		this.setState({extraCounts: arrayvar });
		this.props.setExtraCount(arrayvar);
	};

	componentWillMount(){
		this.props.setExtraCount([0]);
	}

	componentWillUnmount(){
		this.props.spcmLoader('attribute','success');
	}

	submitForm(values){
		let temp = values;
		 Object.keys(temp).forEach(val=>{
			if(val[0]=='a'){
				if(val.substring(6)&&this.state.extraCounts.indexOf(parseInt(val.substring(6),10)) < 0){
					delete(values['action'+val.substring(6)]);
					delete(values['description'+val.substring(6)]);
				}

			}


		})
		  if(this.props.spcmReducer.editLeftId>0){
						values.updatedby_name="";
						values.updatedby="";
					}else{
						values.createdby_name="";
						values.created_by="";
				}

		if(this.props.spcmReducer.editLeftId>0){
					//return axios.patch(constants.SERVER+constants.OFG_SELF_SERVICE_ACTIONS_API_URL+"/"+this.props.spcmReducer.editLeftId,values).then((response) => {
					this.props.spcmLoader('attribute','posting');
					return axios.patch("/service/api/patchSelfService/"+this.props.spcmReducer.editLeftId,values).then((response) => {
						this.props.getSelfServices(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
						this.props.componentFormFun4(false);
						this.props.spcmLoader('attribute','success');
					})
					.catch((error) => {
						
						this.props.spcmLoader('attribute','success');
						throw new SubmissionError({_error: error.response.data[0].message });
					});
				}
				else{
					var name=[];
					var value=[];
					var final=[];
					Object.keys(values).forEach(function(key){
						if(key[0]=='a'){
							key.substring(6)?value[key.substring(6)]=values[key]:value[0]=values[key];

						}
						else if(key[0]=='d'){
							key.substring(11)?name[key.substring(11)]=values[key]:name[0]=values[key];
							final.push({description:name[key.substring(11)],action:value[key.substring(11)],offering_id:this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID})

						}
					},this)
					//return axios.post(constants.SERVER+constants.OFG_SELF_SERVICE_ACTIONS_API_URL,final).then((response) => {
					this.props.spcmLoader('attribute','posting');
					return axios.post("/service/api/saveSelfService",final).then((response) => {
						this.props.getSelfServices(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
						this.props.componentFormFun4(false);
						this.props.getServiceDetailCounts(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
						this.props.spcmLoader('attribute','success');
					})
					.catch((error) => {
						
						this.props.spcmLoader('attribute','success');
						throw new SubmissionError({_error: error.response.data[0].message });
					});
				}
	}
	loadCount(){
		const rowlength = this.state.extraCounts.length;
		return this.state.extraCounts.map((extraCount,i)=>{
			if(rowlength === i + 1 && this.props.spcmReducer.editLeftId && this.props.spcmReducer.editLeftId>0){
				return <tr><td><Field component={_inputField} name={"action"} className='form-control' /></td><td><Field component={_inputField} name={"description"} className='form-control'/></td></tr>;
			}else if(rowlength === i + 1){
				return <tr><td><Field component={_inputField} name={"action"+extraCount} className='form-control' /></td><td><Field component={_inputField} name={"description"+extraCount} className='form-control'/></td><td><Button title="Add" onClick={()=>{this.addRow()}}><i className="fa fa-plus"></i></Button></td></tr>;
			}
			else{
				return <tr key={i}><td><Field component={_inputField} name={"action"+extraCount} className='form-control'/></td><td><Field component={_inputField} name={"description"+extraCount} className='form-control'/></td><td><Button title="Delete" onClick={()=>{this.deleteRow(extraCount)}}><i className="fa fa-trash"></i></Button></td></tr>;
			}
		});
	}
	render(){
		const tr=this.props.tr;
		const {error,handleSubmit} = this.props;
		return(
			<div className="rBoxGap">
				<form  onSubmit={handleSubmit(this.submitForm)}>
				{error == undefined || error == null ? null :  <Alert variant="danger" className="errorMessageSec"><p>{error}</p></Alert>}
				<Table>
					<thead>
						<tr>
							<th width="43%">{tr['Action']}</th>
							<th width="43%">{tr['Description']}</th>
							<th width="14%">{tr['Action']}</th>
						</tr>
					</thead>
					<tbody>
						{this.loadCount()}
					</tbody>
				</Table>
				<Row>
						<Col md={12}>
							<ButtonToolbar className="black">
								<Button className='rgSidrkBtn' title="Save" disabled={this.props.spcmReducer.spcmPostingAttribute} type="submit" bsSize="small" bsStyle="primary">{this.props.spcmReducer.spcmPostingAttribute ? <ImSpinner6 className="icn-spinner"/> : null } {tr['Save']}</Button>
								<Button className='rgSiLigBtn' title="Close" onClick={()=>{this.props.componentFormFun4(false)}} bsSize="small" bsStyle="text">{tr['Close']}</Button></ButtonToolbar>
						</Col>
					</Row>
				</form>
			</div>
		);
	}
}

/* 	render(){
		return(
			<div className="borderForm margin-b-10">
				<Row>
					<Col md={6}>
						<FormGroup >
						  <ControlLabel bsClass="">Action</ControlLabel>
						  <Field component={_textArea} name="action" className='form-control' rows="1" />
						 </FormGroup>
					</Col>
					<Col md={6}>
						<FormGroup >
						  <ControlLabel bsClass="">Description</ControlLabel>
						  <Field component={_textArea} name="description" className='form-control' rows="1" />
						 </FormGroup>
					</Col>
				  </Row>

			</div>
		);
	}
} */

const mapStateToProps = ({spcmReducer}) => {
	return {
		spcmReducer,
		initialValues:spcmReducer.editLeftId>0?spcmReducer.editLeftService:''
	}
};

ComponentForm=reduxForm({
	form:'selfServiceForm',
	validate,
	enableReinitialize : true
})(ComponentForm)

export default connect(mapStateToProps,{ spcmLoader, setLeftEditId,getServiceDetailCounts,getSelfServices})(ComponentForm);
