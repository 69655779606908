
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { HiPlus } from "react-icons/hi";
import View from "../uiBuilder/renderer/View";
import { RiArrowGoBackLine } from "react-icons/ri";

const FormView = (props) => {
  const [showBackButton, setShowButton] = useState(false);
  const { state: navigationProps } = useLocation();

  return (
    <>
      <div className="backtolist">
        <Link
          role="button"
          className="fillBtn"
          title="back to editor list"
          to={"/formLists"}
          state={navigationProps}
        >
          <RiArrowGoBackLine />
        </Link>
      </div>
      <View />
    </>
  );
};

export default FormView;
