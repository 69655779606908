
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Row, Col, Media, FormControl, ControlLabel } from "react-bootstrap";
import { loadChangeRelatedWorkDetails } from "../../../../actions/changeManagement/cmdbchangeRelatedWorkAction";
import { loadChangeRelatedDataDetails } from "../../../../actions/changeManagement/cmdbchangeRelatedDataAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { GLOBAL } from "_Globals";
import axios from "axios";
import {IoSearch} from 'react-icons/io5';

import constanturl from "../../../../../src/utils/constants.js";
import ReactReadMoreLess from "../../../../helpers/ReactReadMoreLess";
//let user_id=userDetails.user_id;
const MoreArrow = (
  <i title="More" className="fa fa-angle-double-down f-size-15"></i>
);

class PopoverWorkItemsData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserData: false,
    };

    GLOBAL.checkmoduleval = "CMDB";
  }

  componentWillMount() {
    //let getBreakFixItemId = (this.props.breakFixItemId) ? (this.props.breakFixItemId).split(":") :""
    //let BRKItemId = getBreakFixItemId[0];
    //let breakFixActualItemId = getBreakFixItemId[1];
    //alert(this.props.cieditdetails)
    let itemId = this.props.itemId;
    this.props.loadChangeRelatedDataDetails(itemId, "CMDB");
    this.props.loadChangeRelatedWorkDetails(
      "ci_name",
      "-",
      "CMDB",
      this.props.cieditdetails
    );
    GLOBAL.relatedtextdisplay = (
      <div className="f-size-18 margin-b-5">Related Assets/CIs</div>
    );
  }

  postRelatedData(brkid) {
    //let getBreakFixItemId = (this.props.breakFixItemId) ? (this.props.breakFixItemId).split(":") :""
    //let BRKItemId = getBreakFixItemId[0];
    //let breakFixActualItemId = getBreakFixItemId[1];
    //const offering = this.props.offering;

    //this.props.fun(false,true,true);
    let itemId = this.props.itemId;
    axios
      .post(constanturl.cmdburl + "/api/config_items/relationship", {
        ci_id: itemId,
        ci_id_related: brkid,
        created_by: "",
        module: "CMDB",
      })
      .then((response) => {
        //alert("Data successfully submitted")

        this.props.loadChangeRelatedWorkDetails(
          "ci_name",
          "-",
          "CMDB",
          this.props.cieditdetails
        );
        GLOBAL.relatedtextdisplay = (
          <div className="f-size-18 margin-b-5">Related Assets/CIs</div>
        );
        this.props.loadChangeRelatedDataDetails(itemId, "CMDB");
      });
  }

  renderRelatedWorkLog(relatedLogData) {
    //alert(""+relatedLogData.length)
    //	console.log("123::::"+relatedLogData.length)
    if (relatedLogData.length == "0") {
      return (
        <tr>
          <td width="100%">
            <div></div>
          </td>
        </tr>
      );
    } else {
      return relatedLogData.map((relatedObj, index) => {
        let reldate = relatedObj.CREATED_AT;
        let splitdate = reldate.split(".")[0];
        //console.log(splitdate)

        let modnumber = "",
          modid = "";
        let modulesearchparam = GLOBAL.checkmoduleval;
        if (modulesearchparam == "Breakfix") {
          modnumber = relatedObj.breakfixNumber;
          modid = relatedObj.breakfixId;
        }
        if (modulesearchparam == "Fullfilment") {
          modnumber = relatedObj.itemNumber;
          modid = relatedObj.itemId;
        }
        if (modulesearchparam == "Investigation") {
          modnumber = relatedObj.investigationNumber;
          modid = relatedObj.investigationId;
        }
        if (modulesearchparam == "ChangeManagement") {
          modnumber = relatedObj.changeNumber;
          modid = relatedObj.changeId;
        }
        if (modulesearchparam == "CMDB") {
          modnumber = relatedObj.CI_CODE;
          modid = relatedObj.CI_ID;
        }
        return (
          <Media>
            {/* <Media.Left align="top">
					<Image src="views/images/rahul.jpg" alt="profile image" />
				  </Media.Left>*/}
            <Media.Body>
              <Row>
                <Col md={10} className="padding-r-0">
                  <span
                    className="colorStOrange cursorPoint"
                    onClick={this.showUserDetails}
                  >
                    {relatedObj.CI_NAME}
                  </span>
                  <Media.Heading className="blue margin-0">
                    {" "}
                    - {relatedObj.DESCRIPTION}{" "}
                    <span
                      className="colorStOrange cursorPoint"
                      onClick={this.showUserDetails}
                    >
                      {relatedObj.CREATED_BY}
                    </span>
                  </Media.Heading>
                  <div className="black fw-300 cTxt">
                    <ReactReadMoreLess 
                      charLimit={150}
                      readMoreText={MoreArrow}
                      content={relatedObj.description}
                    />
                  </div>
                </Col>
                <Col md={2} className="text-c">
                  <a
                    onClick={this.postRelatedData.bind(this, modid)}
                    title="Relate"
                  >
                    <i className="fa fa-chevron-circle-right f-size-24 colorStBlue"></i>
                  </a>
                </Col>
              </Row>
            </Media.Body>
          </Media>
        );
      });
    }
  }

  renderRelatedDataLog(relatedDataLog) {
    //alert(""+auditLogData)
    if (!relatedDataLog) {
      return (
        <tr>
          <td width="100%">
            <div>Data not available.</div>
          </td>
        </tr>
      );
    } else {
      return relatedDataLog.map((relatedData, index) => {
        let reldate = relatedData.CREATED_AT;
        let splitdate = reldate.split(".")[0];

        return (
          <Media>
            {/* <Media.Left align="top">
					<Image src="views/images/rahul.jpg" alt="profile image" />
				  </Media.Left>*/}
            <Media.Body>
              <Row>
                <Col md={10} className="padding-r-0">
                  <span
                    className="colorStOrange cursorPoint"
                    onClick={this.showUserDetails}
                  >
                    {relatedData.CI_NAME}
                  </span>
                  <div className="black fw-300 cTxt">
                  <ReactReadMoreLess 
                    charLimit={150}
                    readMoreText={MoreArrow}
                    content={`- ${relatedData.DESCRIPTION} - ${splitdate} - Related By-{" "}
                    ${relatedData.CREATED_BY}`}
                  />
                  </div>
                </Col>
              </Row>
            </Media.Body>
          </Media>
        );
      });
    }
  }

  loaddata() {
    //let getBreakFixItemId = (this.props.breakFixItemId) ? (this.props.breakFixItemId).split(":") :""
    //let BRKItemId = getBreakFixItemId[0];
    //let breakFixActualItemId = getBreakFixItemId[1];
    let itemId = this.props.itemId;
    let modulesearch = GLOBAL.checkmoduleval;
    console.log("modulesearch::::" + modulesearch);
    if (modulesearch == "Breakfix") {
      if (GLOBAL.relatedfilterval == "breakfixNumber")
        this.props.loadChangeRelatedWorkDetails(
          GLOBAL.relatedfilterval,
          GLOBAL.relatedfiltertext,
          "Breakfix"
        );
      if (GLOBAL.relatedfilterval == "impactedUserid")
        this.props.loadChangeRelatedWorkDetails(
          GLOBAL.relatedfilterval,
          this.props.editChangeInitialData.requesterId,
          "Breakfix"
        );
      if (GLOBAL.relatedfilterval == "serviceId")
        this.props.loadChangeRelatedWorkDetails(
          GLOBAL.relatedfilterval,
          this.props.editChangeInitialData.serviceId,
          "Breakfix"
        );
    }
    if (modulesearch == "Fullfilment") {
      if (GLOBAL.relatedfilterval == "breakfixNumber")
        this.props.loadChangeRelatedWorkDetails(
          "itemNumber",
          GLOBAL.relatedfiltertext,
          "Fullfilment"
        );
      if (GLOBAL.relatedfilterval == "impactedUserid")
        this.props.loadChangeRelatedWorkDetails(
          "requesterId",
          this.props.editChangeInitialData.requesterId,
          "Fullfilment"
        );
      if (GLOBAL.relatedfilterval == "serviceId")
        this.props.loadChangeRelatedWorkDetails(
          GLOBAL.relatedfilterval,
          this.props.editChangeInitialData.serviceId,
          "Fullfilment"
        );
    }
    if (modulesearch == "Investigation") {
      if (GLOBAL.relatedfilterval == "breakfixNumber")
        this.props.loadChangeRelatedWorkDetails(
          "investigationNumber",
          GLOBAL.relatedfiltertext,
          "Investigation"
        );
      if (GLOBAL.relatedfilterval == "impactedUserid")
        this.props.loadChangeRelatedWorkDetails(
          GLOBAL.relatedfilterval,
          this.props.editChangeInitialData.requesterId,
          "Investigation"
        );
      if (GLOBAL.relatedfilterval == "serviceId")
        this.props.loadChangeRelatedWorkDetails(
          GLOBAL.relatedfilterval,
          this.props.editChangeInitialData.serviceId,
          "Investigation"
        );
    }

    if (modulesearch == "ChangeManagement") {
      //alert("inside::::")
      if (GLOBAL.relatedfilterval == "breakfixNumber")
        this.props.loadChangeRelatedWorkDetails(
          "changeNumber",
          GLOBAL.relatedfiltertext,
          "ChangeManagement"
        );
      if (GLOBAL.relatedfilterval == "impactedUserid")
        this.props.loadChangeRelatedWorkDetails(
          "requesterId",
          this.props.editChangeInitialData.requesterId,
          "ChangeManagement"
        );
      if (GLOBAL.relatedfilterval == "serviceId")
        this.props.loadChangeRelatedWorkDetails(
          GLOBAL.relatedfilterval,
          this.props.editChangeInitialData.serviceId,
          "ChangeManagement"
        );
    }

    if (modulesearch == "CMDB") {
      //alert("inside::::")
      if (GLOBAL.relatedfilterval == "ci_name")
        this.props.loadChangeRelatedWorkDetails(
          "ci_name",
          GLOBAL.relatedfiltertext,
          "CMDB",
          this.props.cieditdetails
        );
      if (GLOBAL.relatedfilterval == "class_name")
        this.props.loadChangeRelatedWorkDetails(
          "class_name",
          GLOBAL.relatedfiltertext,
          "CMDB",
          this.props.cieditdetails
        );
    }

    GLOBAL.relatedtextdisplay = <div className="f-size-18 margin-b-5"></div>;
    this.props.loadChangeRelatedDataDetails("0", "CMDB");
  }
  loadrelateddata() {
    //let getBreakFixItemId = (this.props.breakFixItemId) ? (this.props.breakFixItemId).split(":") :""
    //let BRKItemId = getBreakFixItemId[0];
    //let breakFixActualItemId = getBreakFixItemId[1];
    let itemId = this.props.itemId;

    this.props.loadChangeRelatedWorkDetails(
      "ci_name",
      "-",
      "CMDB",
      this.props.cieditdetails
    );
    GLOBAL.relatedtextdisplay = (
      <div className="f-size-18 margin-b-5">Related Work Items</div>
    );
    this.props.loadChangeRelatedDataDetails(itemId, "CMDB");
  }

  checkfilter(i) {
    GLOBAL.relatedfilterval = i.target.value;
  }
  filtertext(i) {
    //alert(i.target.value)
    GLOBAL.relatedfiltertext = i.target.value;
  }
  checkmodule(i) {
    //alert("hi"+i.target.value)
    //GLOBAL.checkmoduleval=i.target.value;
    GLOBAL.checkmoduleval = "CMDB";
  }

  render() {
    return (
      <div>
        <div className="f-size-18 margin-b-5">Relate Assets/CIs</div>
        {/*<Row>
				<Col md={12}><FormGroup className="margin-b-5">
				<ControlLabel>Type</ControlLabel>
				<FormControl componentClass="select" onChange={this.checkmodule.bind(this)}>
				<option value="Select">Select</option>

				<option value="CMDB">CMDB</option>

				</FormControl>
				</FormGroup>
				</Col>
			</Row>*/}
        <div className="bgGray padding-10 tbox">
          <Row>
            <Col md={6} className="padding-r-0">
              <ControlLabel>Search by</ControlLabel>
              <FormControl
                componentClass="select"
                onChange={this.checkfilter.bind(this)}
              >
                {/*<option value="Keyword">Keyword</option>*/}
                <option value="Select">Select</option>
                <option value="ci_name">Name</option>
                <option value="class_name">Class</option>
              </FormControl>
            </Col>
            <Col md={6}>
              <ControlLabel>&nbsp;</ControlLabel>
              <FormControl
                type="text"
                onChange={this.filtertext.bind(this)}
                placeholder="search"
              />
            </Col>
          </Row>
          {/*<div className="btnNew padding-5">&nbsp;<a onClick={this.loadrelateddata.bind(this)} title="Back to list" className="srch"><i className="fa fa-search"></i></a></div>
				<div className="btnNew padding-5">&nbsp;<a onClick={this.loaddata.bind(this)} title="Search" className="srch"><i className="fa fa-search"></i></a></div>*/}
          <div className="btnNew padding-5">
            <a onClick={this.loadrelateddata.bind(this)} className="backList">
              <i className="fa fa-angle-double-left"></i> Back to List
            </a>
            &nbsp;
            <a
              onClick={this.loaddata.bind(this)}
              title="Search"
              className="srch"
            >
              <IoSearch/>
            </a>
          </div>
        </div>

        {this.renderRelatedWorkLog(this.props.changeRelatedWorkDetails)}
        {GLOBAL.relatedtextdisplay}
        {this.renderRelatedDataLog(this.props.changeRelatedDataDetails)}
      </div>
    );
  }
}

export function mapStateToProps({
  changeRelatedWorkDetails,
  changeRelatedDataDetails,
}) {
  //  alert("breakFixEditDetails:"+breakFixAuditLogDetails);
  //console.log("breakFixEditDetails:"+breakFixAuditLogDetails.from);
  return { changeRelatedWorkDetails, changeRelatedDataDetails };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { loadChangeRelatedWorkDetails, loadChangeRelatedDataDetails },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PopoverWorkItemsData);
