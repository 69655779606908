
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { useState, useEffect } from "react";
import { Button, ButtonToolbar, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import { _dateTimeFieldGeneral } from "../../../common/ReduxFormFields/formFields";
import { Field, change, reduxForm } from "redux-form";
import { ImSpinner6 } from "react-icons/im";
import { _checkbox, _dropDown, _inputField } from "../../../common/formFields";
import { _dateField } from "../../../serviceDesk/common/formFields";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import LoadingIcons from "react-loading-icons";
import { loadWorkdayScheduleData } from "../../../../actions/sla/lovAction";

// const validate = (values) => {
//   let errors = {};
//   if (values.slaStartTime && moment(values.slaStartTime, "HH:mm").isValid()) {
//     if (values.slaEndTime && moment(values.slaEndTime, "HH:mm").isValid()) {
//       if (moment(values.slaStartDate, "HH:mm").isSame(moment(values.slaEndDate, "HH:mm"))) {
//         if (
//           moment(values.slaStartTime, "HH:mm").isAfter(
//             moment(values.slaEndTime, "HH:mm")
//           )
//         ) {
//           errors.slaStartTime = "Start time is greater than end time.";
//         }
//       }
//     } else {
//       errors.slaEndTime = "Enter end time";
//     }
//   }
//   if (values.slaStartDate && moment(values.slaStartDate)) {
//     console.log("TestDate Values ===", moment(values.slaStartDate));
//     if (values.slaEndDate && moment(values.slaEndDate)) {
//       if (moment(values.slaStartDate).isAfter(moment(values.slaEndDate))) {
//         errors.slaStartDate = "Start date is greater than end date.";
//       }
//     } else {
//       errors.slaEndDate = "Enter end date";
//     }
//   }

//   return errors;
// };
let WorkScheduleTime = (props) => {
  const [allDaySchedule, setAllDaySchedule] = useState(false);
  const [repeatsOn, setRepeatsOn] = useState({
    'SU': false,
    'MO': false,
    'TU': false,
    'WE': false,
    'TH': false,
    'FR': false,
    'SA': false,
  });
  const [initialStartTime, setInitialStartTime] = useState("");
  const [initialEndTime, setInitialEndTime] = useState('');
  const [initialStartDate, setInitialStartDate] = useState('');
  const [initialEndDate, setInitialEndDate] = useState('');
  const [scheduleRepeatType, setScheduleRepeatType] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch({
        type: "NEW_WORK_SCHEDULE_LOAD_SUCCESS",
        workdayScheduleData: {},
        isFetching: false,
      });
    };
  }, []);


  useEffect(() => {
    if (Array.isArray(props?.newSlaWorkScheduleAssociation?.workdayScheduleData?.workdayScheduleList))
    {
      let initialValuesDays = props?.newSlaWorkScheduleAssociation?.workdayScheduleData?.workdayScheduleList;
      let initialValues = props?.newSlaWorkScheduleAssociation?.workdayScheduleData;
      let startTime = props?.newSlaWorkScheduleAssociation?.workdayScheduleData?.workdayScheduleList[0].startTime;
      let endTime = props?.newSlaWorkScheduleAssociation?.workdayScheduleData?.workdayScheduleList[0].endTime;
      let startDate = props?.newSlaWorkScheduleAssociation?.workdayScheduleData?.workdayScheduleList[0].startDate;
      let endDate = props?.newSlaWorkScheduleAssociation?.workdayScheduleData?.workdayScheduleList[0].endDate;

      dispatch(change("workdayScheduleEditForm", "slaStartTime", startTime));
      setInitialStartTime(startTime ? startTime : null);

      dispatch(change("workdayScheduleEditForm", "slaEndTime" , endTime));
      setInitialEndTime(endTime ? endTime : null)

      dispatch(change("workdayScheduleEditForm", "slaStartDate" , startDate));
      setInitialStartDate(startDate ? startDate : null)

      dispatch(change("workdayScheduleEditForm", "slaEndDate" , endDate));
      setInitialEndDate(endDate ? endDate : null)
      
      if (initialValues && initialValues.allDaySchedule == true) {
        dispatch(change('workdayScheduleEditForm', 'is24by7', true));
        setAllDaySchedule(true);
      } else {
        dispatch(change('workdayScheduleEditForm', 'is24by7', false));
        setAllDaySchedule(false);
      }
      dispatch(change('workdayScheduleEditForm', 'scheduleRepeatType',initialValues?.repeatsOn));
      setScheduleRepeatType(initialValues?.repeatsOn);
      let initialDays = []
      initialValuesDays.forEach((item) => {
          if (item.day == 'SUNDAY') {
            initialDays.push('SU');
          } else if (item.day == 'MONDAY') {
            initialDays.push('MO');
          }
          else if (item.day == 'TUESDAY') {
            initialDays.push('TU');
          }
          else if (item.day == 'WEDNESDAY') {
            initialDays.push('WE');
          }
          else if (item.day == 'THURSDAY') {
            initialDays.push('TH');
          }
          else if (item.day == 'FRIDAY') {
            initialDays.push('FR');
          }
          else if (item.day == 'SATURDAY') {
            initialDays.push('SA');
          }
      });

      if (initialDays && initialDays.length > 0) {
        let obj = { ...repeatsOn };
        initialDays.forEach((item) => {
          obj[item] = true;
        })
         setRepeatsOn(obj);
         dispatch( change("workdayScheduleEditForm", "daysSelected", obj));
      }

    }
  }, [props?.newSlaWorkScheduleAssociation]);

  function setRepeatsOnFun(val) {
    let obj = { ...repeatsOn };
    obj[val] = !obj[val];
    setRepeatsOn(obj);
    dispatch( change("workdayScheduleEditForm", "daysSelected", obj));
  }


  function handleStartTimeChange(time) {
    dispatch(change("workdayScheduleEditForm", "slaStartTime", moment(time).format("HH:mm")));
    dispatch(change("workdayScheduleEditForm", "is24by7", false));
   }

   function handleEndTimeChange(time) {
    dispatch( change("workdayScheduleEditForm", "slaEndTime", moment(time).format("HH:mm")));
   }



  function is24By7Fun() {
    setAllDaySchedule(!allDaySchedule);
    dispatch(change("workdayScheduleEditForm", "is24by7", allDaySchedule));
    if (allDaySchedule == false || allDaySchedule == undefined) {
      dispatch(change("workdayScheduleEditForm", "slaStartTime", ""));
      dispatch(change("workdayScheduleEditForm", "slaEndTime", ""));
    }
  }


  function repeatsOnFun(event) {
    setScheduleRepeatType(event.target.value);
     dispatch(change('workdayScheduleEditForm', 'scheduleRepeatType', event.target[event.target.selectedIndex].text));
     if (event.target.value == '' || event.target.value == 'Daily') {
      setRepeatsOn({
        'SU': false,
        'MO': false,
        'TU': false,
        'WE': false,
        'TH': false,
        'FR': false,
        'SA': false
      })
     }
     dispatch(change("workdayScheduleEditForm", "daysSelected", repeatsOn));
  }

  if (
    _.isEmpty(props.slaWorkScheduleDetails) ||
    props.isFetching || !Array.isArray(props?.newSlaWorkScheduleAssociation?.workdayScheduleData?.workdayScheduleList)
  ) {
    return (
      <div className="rctLoade">
        <LoadingIcons.Bars height="5em" stroke="transparent" fill="#06bcee" />
      </div>
    );
  } else {
    return (
      <>
        <div>
          <div className="stickyArea rBoxStyle">
            <Row>
              <Col xs={12}>
                <div className="rPageHeading">
                  <div className="offNam margin-t-5 margin-b-5">
                    {props.translator["Schedule Association"]}
                  </div>
                </div>
              </Col>
            </Row>

            <div className="rBoxGap">
              <div className="rBoxTabs hideTabNav">
                <Row>
                  <Col md={6} sm={6} xs={12}>
                    <Form.Group className="form-group">
                      <Form.Label>
                        <span className="rStar"></span>
                        {props.translator["Start Date"]}
                      </Form.Label>
                      <div className="dateTimeSdiv">
                        {initialStartDate ? (<Field
                          name="slaStartDate"
                          component={_dateTimeFieldGeneral}
                          formType={"create"}
                          className="form-control dateTimePickerField"
                          defaultValue={initialStartDate}
                        />):('')}
                      </div>
                    </Form.Group>
                  </Col>
                  {!allDaySchedule && (
                    <Col>
                      <Form.Group className="form-group">
                        <Form.Label>
                          <span className="rStar"></span>
                          {["Start Time"]}
                        </Form.Label>
                        <div className="dateTimeSdiv dateTimePosTop">
                          {initialStartTime ? (
                            <Field
                              name="slaStartTime"
                              scheduledDay={"startTime"}
                              handleTimeChange={handleStartTimeChange}
                              component={_dateField}
                              className="form-control"
                              formType="timePicker"
                              defaultValue={
                                initialStartTime
                                  ? moment(initialStartTime, "HH:mm")
                                  : null
                              }
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </Form.Group>
                    </Col>
                  )}
                </Row>
                <Row>

                <Col md={6} sm={6} xs={12}>
                    <Form.Group className="form-group">
                      <Form.Label>
                        <span className="rStar"></span>
                        {props.translator["End Date"]}
                      </Form.Label>
                      <div className="dateTimeSdiv">
                        {initialEndDate ?(<Field
                          name="slaEndDate"
                          component={_dateTimeFieldGeneral}
                          formType={"create"}
                          className="form-control dateTimePickerField"
                          defaultValue={initialEndDate}
                        />): ('')}
                      </div>
                    </Form.Group>
                  </Col>
                
                  {!allDaySchedule && (
                    <Col>
                      <Form.Group className="form-group">
                        <Form.Label>
                          <span className="rStar"></span>
                          {["End Time"]}
                        </Form.Label>
                        <div className="dateTimeSdiv dateTimePosTop">
                          {initialEndTime ? (<Field
                            name="slaEndTime"
                            scheduledDay={"endTime"}
                            handleTimeChange={handleEndTimeChange}
                            component={_dateField}
                            className="form-control"
                            formType="timePicker"
                            defaultValue={initialEndTime ?
                              moment(initialEndTime, "HH:mm")
                              : null}
                          />):('')}
                        </div>
                      </Form.Group>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col md={12} sm={12} xs={12}>
                    <Form.Group className="form-group">
                      <Field
                        name="is24by7"
                        component={_checkbox}
                        className="form-control"
                        label="All day schedule"
                        onChange={is24By7Fun}
                        checked={allDaySchedule}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12} sm={12} xs={12}>
                    <Form.Group className="form-group">
                      <Form.Label>
                        <span className="rStar"></span>Repeats
                      </Form.Label>
                      <Field
                        component={"select"}
                        name="scheduleRepeatType"
                        onChange={(e) => repeatsOnFun(e)}
                        className="form-control"
                      >
                        <option value="">Select</option>
                        <option value="Daily">{["Daily"]}</option>
                        <option value="Specific">{["Specific"]}</option>
                      </Field>
                    </Form.Group>
                  </Col>

                  {
                    scheduleRepeatType == "Specific" ?
                    (
                    <Col md={12} sm={12} xs={12}>
                      <Form.Label>
                        <span className="rStar"></span>Repeats On
                      </Form.Label>
                      <div className="form-group btn-week">
                        <Button
                          title="Sunday"
                          bsSize="small"
                          bsStyle="text"
                          className={
                            repeatsOn.SU ? "week-btn selected" : "week-btn"
                          }
                          onClick={() => {
                            setRepeatsOnFun("SU");
                          }}
                        >
                          SU
                        </Button>
                        <Button
                          title="Monday"
                          bsSize="small"
                          bsStyle="text"
                          className={
                            repeatsOn.MO ? "week-btn selected" : "week-btn"
                          }
                          onClick={() => {
                            setRepeatsOnFun("MO");
                          }}
                        >
                          MO
                        </Button>
                        <Button
                          title="Tuesday"
                          bsSize="small"
                          bsStyle="text"
                          className={
                            repeatsOn.TU ? "week-btn selected" : "week-btn"
                          }
                          onClick={() => {
                            setRepeatsOnFun("TU");
                          }}
                        >
                          TU
                        </Button>
                        <Button
                          title="Wednesday"
                          bsSize="small"
                          bsStyle="text"
                          className={
                            repeatsOn.WE ? "week-btn selected" : "week-btn"
                          }
                          onClick={() => {
                            setRepeatsOnFun("WE");
                          }}
                        >
                          WE
                        </Button>
                        <Button
                          title="Thursday"
                          bsSize="small"
                          bsStyle="text"
                          className={
                            repeatsOn.TH ? "week-btn selected" : "week-btn"
                          }
                          onClick={() => {
                            setRepeatsOnFun("TH");
                          }}
                        >
                          TH
                        </Button>
                        <Button
                          title="Friday"
                          bsSize="small"
                          bsStyle="text"
                          className={
                            repeatsOn.FR ? "week-btn selected" : "week-btn"
                          }
                          onClick={() => {
                            setRepeatsOnFun("FR");
                          }}
                        >
                          FR
                        </Button>
                        <Button
                          title="Saturday"
                          bsSize="small"
                          bsStyle="text"
                          className={
                            repeatsOn.SA ? "week-btn selected" : "week-btn"
                          }
                          onClick={() => {
                            setRepeatsOnFun("SA");
                          }}
                        >
                          SA
                        </Button>
                      </div>
                    </Col>
                    ) 
                  : (
                    ""
                  )
                  }
                </Row>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

const mapStateToProps = ({ slaWorkScheduleDetails }) => {
  return {
    slaWorkScheduleDetails: slaWorkScheduleDetails.workdayScheduleDetails,
    isFetching: slaWorkScheduleDetails.isFetching,
  };
};
WorkScheduleTime = reduxForm({
  form: "workdayScheduleEditForm",
  destroyOnUnmount: false,
  enableReinitialize: true,
})(WorkScheduleTime);

export default connect(mapStateToProps, {})(WorkScheduleTime);
