
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const companyData = (state = [],action) => {
  switch (action.type) {
    case 'COMPANY_LIST':
      return action.companyList;
    default:
      return state;
  }
};

export const companyDataTypeahead = (state=[],action) =>{
  switch (action.type) {
    case 'COMPANY_LIST_TYPE':
      return action.typeaheadData;
    default:
      return state;
  }
}
export const associatedCompanyData = (state = [],action) => {
  switch (action.type) {
    case 'ASSOCIATED_COMPANY_LIST':
      return action.associatedCompanyList;
    default:
      return state;
  }
};

export const associatedCompanyDataTypeahead = (state = [],action) => {
  switch (action.type) {
    case 'ASSOCIATED_COMPANY_LIST_TYPE':
      return action.typeaheadData;
    default:
      return state;
  }
};
export const allGroups =(state=[],action) =>{
  switch(action.type){
    case 'GROUP_LIST_TYPEAHEAD':
       return action.allGroup;
    default:
       return state;   
  }
}
export const groupData = (state = [],action) => {
  switch (action.type) {
    case 'GROUP_LIST':
      return action.groupList;
    default:
      return state;
  }
};
export const groupDataTypeahead = (state = [],action) => {
  switch (action.type) {
    case 'GROUP_LIST_TYPE':
      return action.typeaheadData;
    default:
      return state;
  }
};
export const allgroupdata=(state = [],action)=>{
  
  switch (action.type) {
    case 'GROUP_LIST':
      return action.groupList;
    default:
      return state;
  }
}
export const allquerygroupdata=(state = [],action)=>{
  
  switch (action.type) {
    case 'GROUP_LIST':
      return action.groupList;
    default:
      return state;
  }
}
export const individualData = (state = [],action) => {
  switch (action.type) {
    case 'INDIVIDUAL_LIST':
      return action.individualList;
    default:
      return state;
  }
};
export const problemTeam = (state = [],action) => {
  switch (action.type) {
    case 'INDIVIDUAL_LISTS':
      return action.teamlist;
    default:
      return state;
  }
};
export const moduleData = (state = [],action) => {
  switch (action.type) {
    case 'MODULE_LIST':
      return action.moduleList;
    default:
      return state;
  }
};

export const eventData = (state = [],action) => {
  switch (action.type) {
    case 'EVENT_LIST':
      return action.eventList;
    default:
      return state;
  }
};

export const parameterData = (state = [],action) => {
  switch (action.type) {
    case 'PARAMETER_LIST':
      return action.parameterList;
    default:
      return state;
  }
};

export const parameterValueData = (state = [],action) => {
  switch (action.type) {
    case 'PARAMETER_VALUE_LIST':
      return action.parameterValueList;
    default:
      return state;
  }
};

export const companyStatus = (state = [],action) => {
  switch (action.type) {
    case 'COMPANY_STATUS':
      return action.companyStatus;
    default:
      return state;
  }
};

export const companyService = (state = [],action) => {
  switch (action.type) {
    case 'COMPANY_SERVICE':
      return action.companyService;
    default:
      return state;
  }
};

export const companyServiceTypeahead = (state = [],action) => {
  switch (action.type) {
    case 'COMPANY_SERVICE_TYPE':  
      return action.typeaheadData;
    default:
      return state;
  }
};
export const ruleConfiguration = (state = [],action) => {
  switch (action.type) {
    case 'GET_RULE_CONFIGURATION_SUCCESS':
      return action.ruleConfigurationDetails;
    default:
      return state;
  }
};

export const editRuleConfiguration = (state = [],action) => {
  switch (action.type) {
    case 'EDIT_RULE_CONFIGURATION_RESPONSE':
      return action.editRuleConfigurationResponse;
    default:
      return state;
  }
};

export const ruleCondition = (state = [],action) => {
  switch (action.type) {
    case 'GET_RULE_CONDITION_RESPONSE':
      return action.getRuleConditionResponse;
    default:
      return state;
  }
};

export const allRuleConditions = (state = [],action) => {
  switch (action.type) {
    case 'GET_ALL_RULE_CONDITIONS':
      return action.getAllRuleConditions;
    default:
      return state;
  }
};

export const allAssignmentGroups = (state = [],action) => {
  switch (action.type) {
    case 'GET_ALL_ASSIGNEMNT_GROUPS':
      return action.getAllAssignmentGroups;
    default:
      return state;
  }
};
export const allRuleConfigurationsData = (state = [],action) => {
  switch (action.type) {
    case 'GET_ALL_RULE_CONFIGURATIONS':
      return action.getAllRuleConfigurations;
    default:
      return state;
  }
};
export const classData = (state = [],action) => {
  switch (action.type) {
    case 'CLASS_LIST':
      return action.classList;
    default:
      return state;
  }
};
export const impactedCIData = (state = [],action) => {
  switch (action.type) {
    case 'IMPACTED_CI_LIST':
      return action.impactedCIList;
    default:
      return state;
  }
};

export const attributesList_ams = (state = [],action) => {
  switch (action.type) {
    case 'ATTRIBUTES_LIST_AMS_SUCCESS':
      return action.attributesList;
    default:
      return state;
  }
};

export const operatorsList_ams = (state = [],action) => {
  switch (action.type) {
    case 'OPERATORS_LIST_AMS_SUCCESS':
      return action.operatorsList;
    default:
      return state;
  }
};

export const amsAttributeList_ams = (state = [],action) => {
  switch (action.type) {
    case 'AMS_ATTRIBUTES_LIST_AMS_SUCCESS':
      return action.amsAttributesList;
    default:
      return state;
  }
};
export const isClassExist_list=(state=[],action)=>{
  switch(action.type)
  {
    case 'CLASS_EXIST_INLIST':
      return action.isClass;
      default:
        return state;
  }
}
export const loaders_ams = (state = false,action) => {
  switch (action.type) {
    case 'AMS_LOADER_LOADING':
      return true;
    case 'AMS_LOADER_SUCCESS':
      return false;
    default:
      return state;
  }
};
export const groupFunctionDataTypeahead = (state = [],action) => {
  switch (action.type) {
    case 'GROUP_FUNCTION_LIST':
      return action.groupFunctionList;
    default:
      return state;
  }
};