
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useMemo } from "react";
import moment from "moment";

import ListLoader from "../../../../../common/loaders/ListLoader";
import Table from "../../../../../common/governance/tableView";
import AuditLogs from "../../../../actionItem/rightSide/AuditLog/auditLog";

const AuditHistory = ({ data = [], isFetching = false,setOrderValue }) => {
  const columns = useMemo(() => [
    {
      accessor: "fieldName",
      Header: "Attribute",
      Cell: ({ row: { original } }) => {
        let str = original.fieldName.replace(/_/g, " ").toLowerCase();
        return <span>{str.charAt(0).toUpperCase() + str.slice(1)}</span>;
      },
    },
    {
      accessor: "oldValue",
      Header: "Change",
      Cell: ({ row: { original } }) => {
        return (
          <>
            <strong>{original?.oldValue || "Set"}</strong>
            &nbsp;to &nbsp;<strong>{original?.newValue || "Null"}</strong>
          </>
        );
      },
    },

    {
      accessor: "updatedBy",
      Header: "By",
    },
    {
      accessor: "updatedOn",
      Header: "On",
      Cell: ({ row: { original } }) => {
        return (
          <span>
            {/* {moment(original.updatedOn)
              .tz(prefData.timeZoneVal !== undefined && prefData.timeZoneVal)
              .format(dateFormat)} */}
          </span>
        );
      },
    },
  ]);

  return isFetching ? (
    <div className="fnloader">
      <ListLoader />
    </div>
  ) : (
    <div className={`data-on-click notes-list px-1`}>
      <AuditLogs data={data} setOrderValue={setOrderValue}/>
    </div>
  );
};

export default AuditHistory;
