
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { reduxForm } from "redux-form";
import { useQuery } from "@apollo/client";
import { GetDataQuery } from "../../studio/connectors/graphQl";
import { GQLARRAY, updateDataQueryValues, validateGraphQlRequest, validateRestRequest } from "../../studio/connectors/utils";
import { useSelector } from "react-redux";

const DropDownConnector = (props) => {
  let { attributes, updateValues, formValues, className, readOnly, atr, disabledKey } = props;
  const hosts = useSelector((state)=>state?.hosts);
  const [options, setOptions] = useState([]);
  const [dataSourceId, setDataSourceId] = useState(null);
  const { data: dataQuery, refetch: fetchDataQuery } = useQuery(GetDataQuery, {
    variables: { id: dataSourceId },
    skip: dataSourceId == null,
  });
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (attributes.data_source) {
      setDataSourceId(attributes.data_source.id);
      fetchDataQuery;
    }
  }, []);

  useEffect(() => {
    if (
      props?.formValues.hasOwnProperty(props?.attributes?.name) &&
      options.length > 0 && props?.formValues[props?.attributes?.name] &&
      !selectedOption
    ) {
      onSelection({
        target: { value: props?.formValues[props?.attributes?.name] },
      });
    }
  }, [props?.formValues, options]);

  useEffect(() => {
    if (dataQuery && !props?.attributes?.populateData)
      fetchOptions();
    else if(dataQuery && props?.formValues && props?.formValues[props?.attributes?.populateData]){
      fetchOptions();
    }
  }, [dataQuery]);

  useEffect(() => {
    if (formValues && dataQuery) {
      fetchOptions();
    }else if(!formValues[props?.attributes?.populateData]){
      setOptions([]);
    }
  }, [formValues[props?.attributes?.populateData]]);

  const fetchOptions = () => {
    const data = JSON.parse(dataQuery.dataQuery.data);
    data.body.value = data.dependent ? updateDataQueryValues(data.body.value, data.dependent, formValues) : data.body.value;
    if(data?.body?.variables){
      data.body.variables = data.dependent
      ? updateDataQueryValues(data.body.variables, data.dependent, formValues)
      : data.body.variables;
    }
    const restOptions = {
      url: dataQuery.dataQuery.connector.url,
      host:
        dataQuery.dataQuery.connector?.module == "other"
          ? ""
          : hosts.hasOwnProperty(dataQuery.dataQuery.connector?.module)
          ? hosts[dataQuery.dataQuery.connector?.module]
          : "",
      verb: dataQuery.dataQuery.connector.verb,
      headers: data.dependent ? updateDataQueryValues(data.headers, data.dependent, formValues) : data.headers,
      queries: data.dependent ? updateDataQueryValues(data.query_string, data.dependent, formValues) : data.query_string,
      body: data.body
    };
    if (GQLARRAY.includes(restOptions?.verb)) {
      validateGraphQlRequest(restOptions)
        .then((response) => {
          if (Array.isArray(response.data)) {
            setOptions(response?.data);
          } else if (typeof response.data == "object") {
            let keys = Object.keys(response.data);
            if (keys.length == 1) {
              setOptions(response.data[keys[0]]);
            } else if (Array.isArray(response.data[keys[0]])) {
              setOptions(response.data[keys[0]]);
            } else {
              setOptions(response.data);
            }
          } else {
            setOptions(response?.data);
          }
        })
        .catch((error) => {
          setOptions([]);
        });
    } else {
      validateRestRequest(restOptions)
        .then((response) => {
          if (Array.isArray(response.data)) {
            setOptions(response.data);
          } else if (typeof response.data == "object") {
            let keys = Object.keys(response.data);
            if (Array.isArray(response.data[keys[0]])) {
              setOptions(response.data[keys[0]]);
            }
          }
        })
        .catch(() => {
          setOptions([]);
        });
    }
  };

  const onSelection = (e) => {
    setSelectedOption(e.target.value);
    if (e.target.value == "") {
      updateValues(
        props?.attributes?.name,
        "",
        props?.attributes?.labelKeyMapping,
        ""
      );
    } else {
      let selectedOption = options.filter((option) => {
        if (option && option.hasOwnProperty(attributes?.valueKey?.id)) {
          return option[attributes?.valueKey?.id] == e.target.value;
        }
        return false;
      });
      if (selectedOption && selectedOption.length > 0) {
        updateValues(
          props?.attributes?.name,
          e.target.value,
          props?.attributes?.labelKeyMapping,
          selectedOption[0][
            attributes.labelKey.id || attributes.labelKey["label"]
          ]
        );
      }
    }
    //dispatch(change("FormView", props?.attributes?.name, e.target.value));
  };
  return (
    <Form.Select
      id={atr?.id || ""}
      className={className}
      name="connector_id"
      onChange={onSelection}
      disabled={props?.attributes?.disabled || disabledKey}
      value={selectedOption || ""}
      readOnly={readOnly}
    >
      <option value="">Select</option>
      {options &&
        options.map((option, index) => (
          <option
            key={index}
            value={
              attributes.valueKey
                ? option[attributes.valueKey.id]
                : option["value"]
            }
            selected={selectedOption == option[attributes.valueKey.id]}
          >
            {attributes.labelKey
              ? option[attributes.labelKey.id]
              : option[attributes.labelKey["label"]]}
          </option>
        ))}
    </Form.Select>
  );
};

// export default reduxForm({
//   form: "FormView",
// })(DropDownConnector);

export default DropDownConnector
