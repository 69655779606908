
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Row, Col, Form, Table, Nav } from "react-bootstrap";
import { getRelationshipData } from "../../../../actions/cmdb/cmdbTimelineAction";
import {loadIncDetails} from "../../../../actions/breakFix/quickViewAction";
import {loadChangeDetails} from "../../../../actions/changeManagement/changeManagementAction";
import {loadPrbDetails} from "../../../../actions/investigation/investigationAction";
import {loadItmDetails} from "../../../../actions/myServices/myServicesActions";
import { connect,useDispatch } from "react-redux";
import { IoClose } from "react-icons/io5";
import Swal from "sweetalert2";
import swal from 'sweetalert';
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import {ImSpinner6} from "react-icons/im";
import Pagination from "react-js-pagination";
const cookies = new Cookies();
let app_url = cookies.get("gph");
if (app_url) app_url = app_url.replace("s:", "");
if (app_url) app_url = app_url.substring(0, app_url.lastIndexOf("."));
app_url = app_url.split("~");
app_url = app_url[18];
import { GLOBAL } from "_Globals";
import axios from 'axios';
import TableComponent from "../../../common/ReactTable/TableComponent";
import { openLinkInNewTab } from "../../../common/helper";
let cookiesData = cookies.get('gph');
if (cookiesData) cookiesData = cookiesData.replace('s:', '');
if (cookiesData) cookiesData = cookiesData.substring(0, cookiesData.lastIndexOf('.'));
cookiesData = cookiesData.split("~");
let onRoleVisible = cookiesData[33] ? cookiesData[33].split(',') : [];
let assignedGrpId = cookiesData[58] ? cookiesData[58].split(',') : [];


let onContractRoleVisible = cookiesData[33] ? cookiesData[33].split(',') : [];
onContractRoleVisible = onContractRoleVisible.includes('68')

let onConfigManagerRoleVisible = cookiesData[33] ? cookiesData[33].split(',') : [];
onConfigManagerRoleVisible = onConfigManagerRoleVisible.includes('37')

let onAssetRoleVisible = cookiesData[33] ? cookiesData[33].split(',') : [];
onAssetRoleVisible = onAssetRoleVisible.includes('38')

let onConfigViewerRoleVisible = cookiesData[33] ? cookiesData[33].split(',') : [];
onConfigViewerRoleVisible = onConfigViewerRoleVisible.includes('39')
const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});
async function checkTicketExistenceWIB(api_URL, payload, url){ 
  let response = await api.post(api_URL, payload)
 
  if(response?.data?.meta?.recordCount == 0 || response?.data?.meta?.recordCount == "0" || response?.data?.data?.meta?.recordCount == 0){
    Swal.fire("You do not have the required role to view this ticket");
  }else{
    openLinkInNewTab(url, "New Tab")
  }
}
  
const CellDeleteAction = (data) => {
  console.log("data CellDeleteAction=======");
  console.log(data.row);
   let props=data.row;
   const dispatch = useDispatch();

   const deleteCiWiRelation = (ci_id, ci_id_related,module) => {
    
    axios
    .delete(
      GLOBAL.cmdbRelatedLogDeleteUrl +
      "?ci_id=" +
      ci_id +
      "&ci_id_related=" +
      ci_id_related +"&module=" + module
    )
    .then((response) => {
      if (!response || response.status != "200") {
        swal({
          text: "Deletion failed.",
          button: "OK",
        });
      } else {
        swal({
          text: "Deleted Relation",
          button: "OK",
        });
       // let itemId = this.props.itemId;
       dispatch(getRelationshipData(ci_id, module,"No"));
       
      }
    });
   
  };
  return (
     <div className="text-c" style={{width:"100%"}}>
      {(data.row.RELATIONSHIP_TYPE=='null'||data.row.RELATIONSHIP_TYPE==null)?
              (onAssetRoleVisible || onConfigManagerRoleVisible) ? (

                <i
                onClick={()=>{deleteCiWiRelation(
                  props.CI_ID,
                  props.CI_ID_RELATED,
                  data.module
                )}}
                className="fa fa-trash-o cursorPoint"
              ></i>
              
              )
              :null
            
         :''
          }
  </div>


    )
}
const CellName = (props) => {
	let payload = {};
    let api_URL = "";
    let url = "";
    let hasAccess = false;
    if (props.module == "Item") {
      hasAccess = !(
        onRoleVisible.includes("43") ||
        onRoleVisible.includes("44") ||
        onRoleVisible.includes("48")
      );
      api_URL = GLOBAL.quickViewOrdersUrl;
      let queryObjects = [];

      if (
        onRoleVisible.includes("44") ||
        onRoleVisible.includes("62") ||
        onRoleVisible.includes("48")
      ) {
        queryObjects = [
          {
            key: "itemNumber",
            operator: "like",
            value: [`${props.row.CI_ID_NUMBER}`],
          },
        ];
      } else {
        queryObjects = [
          {
            key: "itemNumber",
            operator: "like",
            value: [`${props.row.CI_ID_NUMBER}`],
          },
          {
            key: "asgGroupid",
            operator: "in",
            value: assignedGrpId.map((item) => parseInt(item, 10)),
          },
        ];
      }

      payload = {
        data: {
          currentPage: 0,
          orderBy: "desc",
          queryType: "2",
          searchQueryDTOs: queryObjects,
          size: 10,
          sortBy: "itemId",
        },
        newAPI: true,
      };
      url = app_url + "/home?fulFillment=" + props.row.CI_ID_RELATED;
    } else if (props.module == "ChangeManagement") {
      hasAccess = !(
        onRoleVisible.includes("19") ||
        onRoleVisible.includes("20") ||
        onRoleVisible.includes("65") ||
        onRoleVisible.includes("51") ||
        onRoleVisible.includes("52") ||
        onRoleVisible.includes("48")
      );
      api_URL = GLOBAL.changeManagementListURL;
      let queryObjects = [];
      if (
        onRoleVisible.includes("20") ||
        onRoleVisible.includes("52") ||
        onRoleVisible.includes("65")
      ) {
        queryObjects = [
          {
            key: "changeNumber",
            operator: "like",
            value: [`${props.row.CI_ID_NUMBER}`],
          },
        ];
      } else {
        queryObjects = [
          {
            key: "changeNumber",
            operator: "like",
            value: [`${props.row.CI_ID_NUMBER}`],
          },
          {
            key: "changeGroupid",
            operator: "in",
            value: assignedGrpId.map((item) => parseInt(item, 10)),
          },
        ];
      }
      payload = {
        data: {
          currentPage: 0,
          orderBy: "desc",
          queryType: "2",
          searchQueryDTOs: queryObjects,
          size: 10,
          sortBy: "changeId",
        },
        newAPI: true,
      };
      url = app_url + "/home?editChange=" + props.row.CI_ID_RELATED;
    } else if (props.module== "Investigation") {
      hasAccess = !(
        onRoleVisible.includes("16") ||
        onRoleVisible.includes("17") ||
        onRoleVisible.includes("64") ||
        onRoleVisible.includes("48") ||
        onRoleVisible.includes("53")
      );
      api_URL = GLOBAL.innvestigationGetUrl;

      let queryObjects = [];

      if (
        onRoleVisible.includes("17") ||
        onRoleVisible.includes("64") ||
        onRoleVisible.includes("48") ||
        onRoleVisible.includes("53")
      ) {
        queryObjects = [
          {
            key: "investigationNumber",
            operator: "like",
            value: [`${props.row.CI_ID_NUMBER}`],
          },
        ];
      } else {
        queryObjects = [
          {
            key: "investigationNumber",
            operator: "like",
            value: [`${props.row.CI_ID_NUMBER}`],
          },
          {
            key: "asgPmGroupid",
            operator: "in",
            value: assignedGrpId.map((item) => parseInt(item, 10)),
          },
        ];
      }

      payload = {
        data: {
          currentPage: 0,
          orderBy: "desc",
          queryType: "2",
          searchQueryDTOs: queryObjects,
          size: 10,
          sortBy: "investigationId",
        },
        newAPI: true,
      };
      url = app_url + "/home?investigationEdit=" + props.row.CI_ID_RELATED;
    } else if (props.module == "Breakfix") {
      hasAccess = !(
        onRoleVisible.includes("12") ||
        onRoleVisible.includes("13") ||
        onRoleVisible.includes("14") ||
        onRoleVisible.includes("48") ||
        onRoleVisible.includes("63")
      );
      api_URL = GLOBAL.breakFixQuickViewURL;

      let queryObjects = [];

      if (
        onRoleVisible.includes("13") ||
        onRoleVisible.includes("63") 
      ) {
        queryObjects = [
          {
            key: "breakfixNumber",
            operator: "like",
            value: [`${props.row.CI_ID_NUMBER}`],
          },
        ];
      } else {
        queryObjects = [
          {
            key: "breakfixNumber",
            operator: "like",
            value: [`${props.row.CI_ID_NUMBER}`],
          },
          {
            key: "spGroupid",
            operator: "in",
            value: assignedGrpId.map((item) => parseInt(item, 10)),
          },
        ];
      }

      payload = {
        data: {
          currentPage: 0,
          orderBy: "desc",
          queryType: "2",
          searchQueryDTOs: queryObjects,
          size: 10,
          sortBy: "breakfixId",
        },
        newAPI: true,
      };
      url = app_url + "/home?editBreakFix=" + props.row.CI_ID_RELATED;
    }  
	return (
	<div className="text-c">

		{/* <i title="Edit in CMDB Board" onClick={()=>{
		// if(slaID){
		// 	var newWindow = window.open(); newWindow.opener = null; newWindow.location = app_url+"/home?editSlaDef="+slaID; 
		// }
		}} className="fa fa-pencil-square-o cursorPoint"></i> */}
	<Link to={void(0)} onClick={ ()=> {
      if(!hasAccess) {
        checkTicketExistenceWIB(api_URL, payload, url)
      }else {
        Swal.fire(
          "You do not have the required role to view this ticket"
        );
      } 
  }}>
    {props.row.CI_ID_NUMBER}
  </Link>
	</div>
	)
  };
class CMDBRelationship extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      module: null,
      searchModule: null,
      toggleFormAndList: "list",
      searchWorkItemId: "",
      selectedModule: "",
      defaultModuleValue: "Select",
      isErrorVisible: false,
      showSize: 10,
      pageNumber: 1,
      showTable: false
    };
    this.toggleFormAndList = this.toggleFormAndList.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeModule = this.changeModule.bind(this);
    this.changeSearchModule = this.changeSearchModule.bind(this);
    this.changeWorkItemId = this.changeWorkItemId.bind(this);
    this.renderWorkItemList = this.renderWorkItemList.bind(this);
    this.showError = this.showError.bind(this);
    this.checkTicketExistenceWIB = this.checkTicketExistenceWIB.bind(this);
    this.onPageSizeChange = this.onPageSizeChange.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    // this.onRowSelect = this.onRowSelect.bind(this);
  }

  componentDidMount() {
    this.props.getRelationshipData(this.props.ciId, this.state.module,"No");
    // document.addEventListener('contextmenu', (e) => {
    //   e.preventDefault();
    // });
    // this.props.loadIncDetails({}, 'reset');
  }

  changeModule(e) {
    this.setState({
      module: e.target.value, showTable: false
    }, () => this.setState({ showTable: true }));
    this.props.getRelationshipData(this.props.ciId, e.target.value,"No");
  }

  toggleFormAndList() {
    this.props.loadIncDetails({}, 'reset');
    this.props.loadChangeDetails({}, 'reset');
    this.props.loadItmDetails({}, 'reset');
    this.props.loadPrbDetails({}, 'reset');
    this.setState({
      searchModule: null,
    });
    this.setState({
      isErrorVisible: false,
    });
    this.setState({
      module: null,
      defaultModuleValue: "Select",
    })

    if (this.state.toggleFormAndList == "list")
      this.setState({ toggleFormAndList: "form" });
    else this.setState({ toggleFormAndList: "list" });
  }

  onPageChange(e) {
    // console.log(e, "eeeeeee") 
    this.setState({
        pageNumber: parseInt(e, 10)
      })
        if (this.state.searchModule == null || this.state.searchModule == "Select") {
          this.setState({
            isErrorVisible: true,
          });
          return ;
        }
        this.setState({
          isErrorVisible: false,
        });
    
        let submittedModule = this.state.searchModule;
        this.setState({
          selectedModule: submittedModule,
        });
    
        if (this.state.searchModule == "Investigation") {
          let queryArray = [];
          if (
            onRoleVisible.includes("17") ||
            onRoleVisible.includes("64") ||
            onRoleVisible.includes("48") ||
            onRoleVisible.includes("53")
          ) {
            queryArray = [
              {
                key: "investigationNumber",
                operator: "like",
                value: [`${this.state.searchWorkItemId.toUpperCase()}`],
              },
            ];
          } else {
            queryArray = [
              {
                key: "investigationNumber",
                operator: "like",
                value: [`${this.state.searchWorkItemId.toUpperCase()}`],
              },
              {
                key: "asgPmGroupid",
                operator: "in",
                value: assignedGrpId.map((item) => parseInt(item, 10)),
              },
            ];
            queryArray = [
              {
                key: "investigationNumber",
                operator: "like",
                value: [`${this.state.searchWorkItemId.toUpperCase()}`],
              },
              {
                key: "asgPmGroupid",
                operator: "in",
                value: assignedGrpId.map((item) => parseInt(item, 10)),
              },
            ];
          }
        
          let problemPayload = {
            data: {
              currentPage: e - 1,
              orderBy: "desc",
              queryType: "2",
              searchQueryDTOs: queryArray,
              size: this.state.showSize,
              sortBy: "investigationId",
            },
            newAPI: true,
          };
          this.props.loadPrbDetails(problemPayload);
        }
    
        if (this.state.searchModule == "Item") {
          let queryArray = [];
          if (
            onRoleVisible.includes("44") ||
            onRoleVisible.includes("62") ||
            onRoleVisible.includes("48")  
          ) {
            queryArray = [
              {
                key: "itemNumber",
                operator: "like",
                value: [`${this.state.searchWorkItemId.toUpperCase()}`],
              },
            ];
          } else {
            queryArray = [
              {
                key: "itemNumber",
                operator: "like",
                value: [`${this.state.searchWorkItemId.toUpperCase()}`],
              },
              {
                key: "asgGroupid",
                operator: "in",
                value: assignedGrpId.map((item) => parseInt(item, 10)),
              },
            ];
            queryArray = [
              {
                key: "itemNumber",
                operator: "like",
                value: [`${this.state.searchWorkItemId.toUpperCase()}`],
              },
              {
                key: "asgGroupid",
                operator: "in",
                value: assignedGrpId.map((item) => parseInt(item, 10)),
              },
            ];
          }
    
          let fulfillmentPayload = {
            data: {
              currentPage: e - 1,
              orderBy: "desc",
              queryType: "2",
              searchQueryDTOs: queryArray,
              size: this.state.showSize,
              sortBy: "itemId",
            },
            newAPI: true,
          };
          this.props.loadItmDetails(fulfillmentPayload);
        }
    
        if (this.state.searchModule == "ChangeManagement") {
          let queryArray = [];
          if (
            onRoleVisible.includes("20") ||
            onRoleVisible.includes("52") ||
            onRoleVisible.includes("65")
          ) {
            queryArray = [
              {
                key: "changeNumber",
                operator: "like",
                value: [`${this.state.searchWorkItemId.toUpperCase()}`],
              },
            ];
          } else {
            queryArray = [
              {
                key: "changeNumber",
                operator: "like",
                value: [`${this.state.searchWorkItemId.toUpperCase()}`],
              },
              {
                key: "changeGroupid",
                operator: "in",
                value: assignedGrpId.map((item) => parseInt(item, 10)),
              },
            ];
            queryArray = [
              {
                key: "changeNumber",
                operator: "like",
                value: [`${this.state.searchWorkItemId.toUpperCase()}`],
              },
              {
                key: "changeGroupid",
                operator: "in",
                value: assignedGrpId.map((item) => parseInt(item, 10)),
              },
            ];
          }
        
          let changePayload = {
            data: {
              currentPage: e - 1,
              orderBy: "desc",
              queryType: "2",
              searchQueryDTOs: queryArray,
              size: this.state.showSize,
              sortBy: "changeId",
            },
            newAPI: true,
          };
          this.props.loadChangeDetails(changePayload);
        }
    
        if (this.state.searchModule == "Breakfix") {
          let queryArray = [];
          if (
            onRoleVisible.includes("13") ||
            onRoleVisible.includes("63") 
          ) {
            queryArray = [
              {
                key: "breakfixNumber",
                operator: "like",
                value: [`${this.state.searchWorkItemId.toUpperCase()}`],
              },
            ];
          } else {
            queryArray = [
              {
                key: "breakfixNumber",
                operator: "like",
                value: [`${this.state.searchWorkItemId.toUpperCase()}`],
              },
              {
                key: "spGroupid",
                operator: "in",
                value: assignedGrpId.map((item) => parseInt(item, 10)),
              },
            ];
            queryArray = [
              {
                key: "breakfixNumber",
                operator: "like",
                value: [`${this.state.searchWorkItemId.toUpperCase()}`],
              },
              {
                key: "spGroupid",
                operator: "in",
                value: assignedGrpId.map((item) => parseInt(item, 10)),
              },
            ];
          }
        
          let incidentPayload = {
            data: {
              currentPage: e - 1,
              orderBy: "desc",
              queryType: "2",
              searchQueryDTOs: queryArray,
              size: this.state.showSize,
              sortBy: "breakfixId",
            },
            newAPI: true,
          };
          this.props.loadIncDetails(incidentPayload);
        }
      
  }

  onPageSizeChange(e) {
    // console.log(e, "fffffffff")
    this.setState({
      showSize: parseInt(e.target.value, 10), 
      pageNumber: 0
      })

      // console.log("object")
      if (this.state.searchModule == null || this.state.searchModule == "Select") {
        this.setState({
          isErrorVisible: true,
        });
        return ;
      }
      this.setState({
        isErrorVisible: false,
      });
  
      let submittedModule = this.state.searchModule;
      this.setState({
        selectedModule: submittedModule,
      });
  
      if (this.state.searchModule == "Investigation") {
        let queryArray = [];
        if (
          onRoleVisible.includes("17") ||
          onRoleVisible.includes("64") ||
          onRoleVisible.includes("48") ||
          onRoleVisible.includes("53")
        ) {
          queryArray = [
            {
              key: "investigationNumber",
              operator: "like",
              value: [`${this.state.searchWorkItemId.toUpperCase()}`],
            },
          ];
        } else {
          queryArray = [
            {
              key: "investigationNumber",
              operator: "like",
              value: [`${this.state.searchWorkItemId.toUpperCase()}`],
            },
            {
              key: "asgPmGroupid",
              operator: "in",
              value: assignedGrpId.map((item) => parseInt(item, 10)),
            },
          ];
          queryArray = [
            {
              key: "investigationNumber",
              operator: "like",
              value: [`${this.state.searchWorkItemId.toUpperCase()}`],
            },
            {
              key: "asgPmGroupid",
              operator: "in",
              value: assignedGrpId.map((item) => parseInt(item, 10)),
            },
          ];
        }
      
        let problemPayload = {
          data: {
            currentPage: 0,
            orderBy: "desc",
            queryType: "2",
            searchQueryDTOs: queryArray,
            size: e.target.value,
            sortBy: "investigationId",
          },
          newAPI: true,
        };
        this.props.loadPrbDetails(problemPayload);
      }
  
      if (this.state.searchModule == "Item") {
        let queryArray = [];
        if (
          onRoleVisible.includes("44") ||
          onRoleVisible.includes("62") ||
          onRoleVisible.includes("48")  
        ) {
          queryArray = [
            {
              key: "itemNumber",
              operator: "like",
              value: [`${this.state.searchWorkItemId.toUpperCase()}`],
            },
          ];
        } else {
          queryArray = [
            {
              key: "itemNumber",
              operator: "like",
              value: [`${this.state.searchWorkItemId.toUpperCase()}`],
            },
            {
              key: "asgGroupid",
              operator: "in",
              value: assignedGrpId.map((item) => parseInt(item, 10)),
            },
          ];
          queryArray = [
            {
              key: "itemNumber",
              operator: "like",
              value: [`${this.state.searchWorkItemId.toUpperCase()}`],
            },
            {
              key: "asgGroupid",
              operator: "in",
              value: assignedGrpId.map((item) => parseInt(item, 10)),
            },
          ];
        }
  
        let fulfillmentPayload = {
          data: {
            currentPage: 0,
            orderBy: "desc",
            queryType: "2",
            searchQueryDTOs: queryArray,
            size: e.target.value,
            sortBy: "itemId",
          },
          newAPI: true,
        };
        this.props.loadItmDetails(fulfillmentPayload);
      }
  
      if (this.state.searchModule == "ChangeManagement") {
        let queryArray = [];
        if (
          onRoleVisible.includes("20") ||
          onRoleVisible.includes("52") ||
          onRoleVisible.includes("65")
        ) {
          queryArray = [
            {
              key: "changeNumber",
              operator: "like",
              value: [`${this.state.searchWorkItemId.toUpperCase()}`],
            },
          ];
        } else {
          queryArray = [
            {
              key: "changeNumber",
              operator: "like",
              value: [`${this.state.searchWorkItemId.toUpperCase()}`],
            },
            {
              key: "changeGroupid",
              operator: "in",
              value: assignedGrpId.map((item) => parseInt(item, 10)),
            },
          ];
          queryArray = [
            {
              key: "changeNumber",
              operator: "like",
              value: [`${this.state.searchWorkItemId.toUpperCase()}`],
            },
            {
              key: "changeGroupid",
              operator: "in",
              value: assignedGrpId.map((item) => parseInt(item, 10)),
            },
          ];
        }
      
        let changePayload = {
          data: {
            currentPage: 0,
            orderBy: "desc",
            queryType: "2",
            searchQueryDTOs: queryArray,
            size: e.target.value,
            sortBy: "changeId",
          },
          newAPI: true,
        };
        this.props.loadChangeDetails(changePayload);
      }
  
      if (this.state.searchModule == "Breakfix") {
        let queryArray = [];
        if (
          onRoleVisible.includes("13") ||
          onRoleVisible.includes("63") 
        ) {
          queryArray = [
            {
              key: "breakfixNumber",
              operator: "like",
              value: [`${this.state.searchWorkItemId.toUpperCase()}`],
            },
          ];
        } else {
          queryArray = [
            {
              key: "breakfixNumber",
              operator: "like",
              value: [`${this.state.searchWorkItemId.toUpperCase()}`],
            },
            {
              key: "spGroupid",
              operator: "in",
              value: assignedGrpId.map((item) => parseInt(item, 10)),
            },
          ];
          queryArray = [
            {
              key: "breakfixNumber",
              operator: "like",
              value: [`${this.state.searchWorkItemId.toUpperCase()}`],
            },
            {
              key: "spGroupid",
              operator: "in",
              value: assignedGrpId.map((item) => parseInt(item, 10)),
            },
          ];
        }
      
        let incidentPayload = {
          data: {
            currentPage: 0,
            orderBy: "desc",
            queryType: "2",
            searchQueryDTOs: queryArray,
            size: e.target.value,
            sortBy: "breakfixId",
          },
          newAPI: true,
        };
        this.props.loadIncDetails(incidentPayload);
      }
  }

  handleSubmit() {
    // console.log("handle----", this.state.searchModule);
    // console.log("searchWorkItemId", this.state.searchWorkItemId);

    if (this.state.searchModule == null || this.state.searchModule == "Select") {
      this.setState({
        isErrorVisible: true,
      });
      return ;
    }
    this.setState({
      isErrorVisible: false,
    });

    let submittedModule = this.state.searchModule;
    this.setState({
      selectedModule: submittedModule,
      pageNumber: 1,
      showSize: 10
    });

    if (this.state.searchModule == "Investigation") {
      let queryArray = [];
      if (
        onRoleVisible.includes("17") ||
        onRoleVisible.includes("64") ||
        onRoleVisible.includes("48") ||
        onRoleVisible.includes("53")
      ) {
        queryArray = [
          {
            key: "investigationNumber",
            operator: "like",
            value: [`${this.state.searchWorkItemId.toUpperCase()}`],
          },
        ];
      } else {
        queryArray = [
          {
            key: "investigationNumber",
            operator: "like",
            value: [`${this.state.searchWorkItemId.toUpperCase()}`],
          },
          {
            key: "asgPmGroupid",
            operator: "in",
            value: assignedGrpId.map((item) => parseInt(item, 10)),
          },
        ];
        queryArray = [
          {
            key: "investigationNumber",
            operator: "like",
            value: [`${this.state.searchWorkItemId.toUpperCase()}`],
          },
          {
            key: "asgPmGroupid",
            operator: "in",
            value: assignedGrpId.map((item) => parseInt(item, 10)),
          },
        ];
      }
    
      let problemPayload = {
        data: {
          currentPage: 0,
          orderBy: "desc",
          queryType: "2",
          searchQueryDTOs: queryArray,
          size: 10,
          sortBy: "investigationId",
        },
        newAPI: true,
      };
      this.props.loadPrbDetails(problemPayload);
    }

    if (this.state.searchModule == "Item") {
      let queryArray = [];
      if (
        onRoleVisible.includes("44") ||
        onRoleVisible.includes("62") ||
        onRoleVisible.includes("48")  
      ) {
        queryArray = [
          {
            key: "itemNumber",
            operator: "like",
            value: [`${this.state.searchWorkItemId.toUpperCase()}`],
          },
        ];
      } else {
        queryArray = [
          {
            key: "itemNumber",
            operator: "like",
            value: [`${this.state.searchWorkItemId.toUpperCase()}`],
          },
          {
            key: "asgGroupid",
            operator: "in",
            value: assignedGrpId.map((item) => parseInt(item, 10)),
          },
        ];
        queryArray = [
          {
            key: "itemNumber",
            operator: "like",
            value: [`${this.state.searchWorkItemId.toUpperCase()}`],
          },
          {
            key: "asgGroupid",
            operator: "in",
            value: assignedGrpId.map((item) => parseInt(item, 10)),
          },
        ];
      }

      let fulfillmentPayload = {
        data: {
          currentPage: 0,
          orderBy: "desc",
          queryType: "2",
          searchQueryDTOs: queryArray,
          size: 10,
          sortBy: "itemId",
        },
        newAPI: true,
      };
      this.props.loadItmDetails(fulfillmentPayload);
    }

    if (this.state.searchModule == "ChangeManagement") {
      let queryArray = [];
      if (
        onRoleVisible.includes("20") ||
        onRoleVisible.includes("52") ||
        onRoleVisible.includes("65")
      ) {
        queryArray = [
          {
            key: "changeNumber",
            operator: "like",
            value: [`${this.state.searchWorkItemId.toUpperCase()}`],
          },
        ];
      } else {
        queryArray = [
          {
            key: "changeNumber",
            operator: "like",
            value: [`${this.state.searchWorkItemId.toUpperCase()}`],
          },
          {
            key: "changeGroupid",
            operator: "in",
            value: assignedGrpId.map((item) => parseInt(item, 10)),
          },
        ];
        queryArray = [
          {
            key: "changeNumber",
            operator: "like",
            value: [`${this.state.searchWorkItemId.toUpperCase()}`],
          },
          {
            key: "changeGroupid",
            operator: "in",
            value: assignedGrpId.map((item) => parseInt(item, 10)),
          },
        ];
      }
    
      let changePayload = {
        data: {
          currentPage: 0,
          orderBy: "desc",
          queryType: "2",
          searchQueryDTOs: queryArray,
          size: 10,
          sortBy: "changeId",
        },
        newAPI: true,
      };
      this.props.loadChangeDetails(changePayload);
    }

    if (this.state.searchModule == "Breakfix") {
      let queryArray = [];
      if (
        onRoleVisible.includes("13") ||
        onRoleVisible.includes("63") 
      ) {
        queryArray = [
          {
            key: "breakfixNumber",
            operator: "like",
            value: [`${this.state.searchWorkItemId.toUpperCase()}`],
          },
        ];
      } else {
        queryArray = [
          {
            key: "breakfixNumber",
            operator: "like",
            value: [`${this.state.searchWorkItemId.toUpperCase()}`],
          },
          {
            key: "spGroupid",
            operator: "in",
            value: assignedGrpId.map((item) => parseInt(item, 10)),
          },
        ];
        queryArray = [
          {
            key: "breakfixNumber",
            operator: "like",
            value: [`${this.state.searchWorkItemId.toUpperCase()}`],
          },
          {
            key: "spGroupid",
            operator: "in",
            value: assignedGrpId.map((item) => parseInt(item, 10)),
          },
        ];
      }
    
      let incidentPayload = {
        data: {
          currentPage: 0,
          orderBy: "desc",
          queryType: "2",
          searchQueryDTOs: queryArray,
          size: 10,
          sortBy: "breakfixId",
        },
        newAPI: true,
      };
      this.props.loadIncDetails(incidentPayload);
    }
  }

  async checkTicketExistenceWIB(api_URL, payload, url){ 
    let response = await api.post(api_URL, payload)
    // console.log("response", response)
    // console.log(response?.data?.meta?.recordCount, "RFC PRB")
    // console.log(response?.data?.data?.meta?.recordCount, "ITM INC")
    if(response?.data?.meta?.recordCount == 0 || response?.data?.meta?.recordCount == "0" || response?.data?.data?.meta?.recordCount == 0){
      console.log("inside if")
      Swal.fire("You do not have the required role to view this ticket");
    }else{
      // console.log("URLITM", url)
      // window.location.href = url;
      openLinkInNewTab(url, "New Tab");
    }
  }

  showError() {
    return <p>No Data Available</p>;
  }

  postRelatedData(payload, module) {
    // console.log("inside post related");
    let payloadObj = {
      ci_id: this.props.ciId, //==> ID of CI related
      wi_id_related:
        payload.breakfixId ??
        payload.itemId ??
        payload.changeId ??
        payload.investigationId, //==> ID of Work Item related
      wi_id_number:
        payload.breakfixNumber ??
        payload.itemNumber ??
        payload.changeNumber ??
        payload.investigationNumber, //  ==> Number of work item related
      ci_id_reported: payload.createdOn,
      created_by: payload.impactedUsername ?? payload.requesterName,
      module: module,
    };

    axios.post(GLOBAL.cmdbRelatedLogDeleteUrl, payloadObj).then((response) => {
      if(response.data.status == false || response.data.status == "false"){
        Swal.fire("This Work Item is already related");
      return ;
      }
      this.setState({
      toggleFormAndList: "list",
      defaultModuleValue: module,
      module: module
      })
      this.props.getRelationshipData(this.props.ciId, module , "No");
      this.setState({
        showTable: true
      })
      // console.log(response, "response");
    })
    // console.log("payload inc", payloadObj);
  }

  changeSearchModule(e) {
    // console.log("inside change mod", e.target.value);
    this.setState({
      searchModule: e.target.value,
      pageNumber: 1,
      showSize: 10
    });
  }

  changeWorkItemId(e) {
    // console.log("inside changeWorkItemId ", e.target.value);
    this.setState({
      searchWorkItemId: e.target.value,
    });
  }


  renderWorkItemList(cmdbRelationshipData, meta=[]) {
    // console.log("cmdbRelationshipData", cmdbRelationshipData);
    // console.log("selected-----", this.state.selectedModule);
    // console.log("renderWorkItemList");
    if (!Array.isArray(cmdbRelationshipData)) cmdbRelationshipData = [];
    // console.log("cmdbRelationshipData------------", cmdbRelationshipData);
    if (!cmdbRelationshipData) {
      return (
        <tr>
          <td className="text-c" colSpan={3}>
            No Data Available
          </td>
        </tr>
      );
    }

    if (cmdbRelationshipData.length == 0) {
      // console.log(this.props.showLoader2.loading, "showLoader2")
      return (
        <tr>
          {this.props.showLoader2.loading ? <ImSpinner6 className="icn-spinner"/> : <td className="text-c" colSpan={3}>No Data Available</td>}
        </tr>
      );
    }
    console.log(meta, "meta")
    let totalRecordCount = meta.recordCount ?? 10
    return (
      <>
      {
        cmdbRelationshipData.map((objectData) => {
          if (this.state.selectedModule == "Breakfix") {
            let hasAccess = !(
              onRoleVisible.includes("12") ||
              onRoleVisible.includes("13") ||
              onRoleVisible.includes("14") ||
              onRoleVisible.includes("48") ||
              onRoleVisible.includes("63")
            );
            let url = app_url + "/home?editBreakFix=" + objectData.breakfixId;
            return (
              <>
                <div className="margin-t-5 margin-b-10 border-b padding-b-5">
                  <Row>
                    <Col md={10} sm={10} xs={10}>
                      {!hasAccess && (
                        <a target="_blank" href={url} onClick={() => {}}>
                          {" "}
                          {objectData.breakfixNumber}
                        </a>
                      )}
                      {hasAccess && (
                        <a
                          href="#"
                          onClick={() => {
                            Swal.fire("You do not have the required role to view this ticket");
                          }}
                        >
                          {" "}
                          {objectData.breakfixNumber}
                        </a>
                      )}
                      <span>
                        {" "}
                        -- {objectData.reportedOn} -- {objectData.impactedUsername}{" "}
                        -- {objectData.description}
                      </span>
                      <span
                        className="cursorPoint fw-300 cTxt"
                        // onClick={this.showUserDetails}
                      ></span>
                    </Col>
                    <Col md={2} sm={2} xs={2} className="text-c">
                      <a
                        onClick={this.postRelatedData.bind(
                          this,
                          objectData,
                          "Breakfix"
                        )}
                        title={this.props.translator["Relate"]}
                      >
                        <i className="fa fa-chevron-circle-right f-size-16 theme1"></i>
                      </a>
                    </Col>
                  </Row>
                </div>
              </>
            );
          }
          if (this.state.selectedModule == "Item") {
            let hasAccess = !(
              onRoleVisible.includes("43") ||
              onRoleVisible.includes("44") ||
              onRoleVisible.includes("62") ||
              onRoleVisible.includes("48")
            );
            let url = app_url + "/home?fulFillment=" + objectData.itemId;
            return (
              <>
                <div className="margin-t-5 margin-b-10 border-b padding-b-5">
                  <Row>
                    <Col md={10} sm={10} xs={10}>
                      {!hasAccess && (
                        <a target="_blank" href={url} onClick={() => {}}>
                          {" "}
                          {objectData.itemNumber}
                        </a>
                      )}
                      {hasAccess && (
                        <a
                          href="#"
                          onClick={() => {
                            Swal.fire("You do not have the required role to view this ticket");
                          }}
                        >
                          {" "}
                          {objectData.itemNumber}
                        </a>
                      )}
                      <span>
                        {" "}
                        -- {objectData.createdOn} -- {objectData.requesterName}
                      </span>
                      <span
                        className="cursorPoint fw-300 cTxt"
                        // onClick={this.showUserDetails}
                      ></span>
                    </Col>
                    <Col md={2} sm={2} xs={2} className="text-c">
                      <a
                        onClick={this.postRelatedData.bind(
                          this,
                          objectData,
                          "Item"
                        )}
                        title={this.props.translator["Relate"]}
                      >
                        <i className="fa fa-chevron-circle-right f-size-16 theme1"></i>
                      </a>
                    </Col>
                  </Row>
                </div>
              </>
            );
          }
          if (this.state.selectedModule == "ChangeManagement") {
            let hasAccess = !(
              onRoleVisible.includes("19") ||
              onRoleVisible.includes("20") ||
              onRoleVisible.includes("65") ||
              onRoleVisible.includes("51") ||
              onRoleVisible.includes("52") ||
              onRoleVisible.includes("48")
            );
            let url = app_url + "/home?editChange=" + objectData.changeId;
            return (
              <>
                <div className="margin-t-5 margin-b-10 border-b padding-b-5">
                  <Row>
                    <Col md={10} sm={10} xs={10}>
                      {!hasAccess && (
                        <a target="_blank" href={url} onClick={() => {}}>
                          {" "}
                          {objectData.changeNumber}
                        </a>
                      )}
                      {hasAccess && (
                        <a
                          href="#"
                          onClick={() => {
                            Swal.fire("You do not have the required role to view this ticket");
                          }}
                        >
                          {" "}
                          {objectData.changeNumber}
                        </a>
                      )}
                      <span>
                        {" "}
                        -- {objectData.createdOn} -- {objectData.requesterName} --{" "}
                        {objectData.summary}
                      </span>
                      <span
                        className="cursorPoint fw-300 cTxt"
                        // onClick={this.showUserDetails}
                      ></span>
                    </Col>
                    <Col md={2} sm={2} xs={2} className="text-c">
                      <a
                        onClick={this.postRelatedData.bind(
                          this,
                          objectData,
                          "ChangeManagement"
                        )}
                        title={this.props.translator["Relate"]}
                      >
                        <i className="fa fa-chevron-circle-right f-size-16 theme1"></i>
                      </a>
                    </Col>
                  </Row>
                </div>
              </>
            );
          }
          if (this.state.selectedModule == "Investigation") {
            let hasAccess = !(
              onRoleVisible.includes("16") ||
              onRoleVisible.includes("17") ||
              onRoleVisible.includes("64") ||
              onRoleVisible.includes("48") ||
              onRoleVisible.includes("53")
            );
            let url =
              app_url + "/home?investigationEdit=" + objectData.investigationId;
            return (
              <>
                <div className="margin-t-5 margin-b-10 border-b padding-b-5">
                  <Row>
                    <Col md={10} sm={10} xs={10}>
                      {!hasAccess && (
                        <a target="_blank" href={url} onClick={() => {}}>
                          {" "}
                          {objectData.investigationNumber}
                        </a>
                      )}
                      {hasAccess && (
                        <a
                          href="#"
                          onClick={() => {
                            Swal.fire("You do not have the required role to view this ticket");
                          }}
                        >
                          {" "}
                          {objectData.investigationNumber}
                        </a>
                      )}
                      <span>
                        {" "}
                        -- {objectData.createdOn} -- {objectData.impactedUsername}{" "}
                        -- {objectData.description}
                      </span>
                      <span
                        className="cursorPoint fw-300 cTxt"
                        // onClick={this.showUserDetails}
                      ></span>
                    </Col>
                    <Col md={2} sm={2} xs={2} className="text-c">
                      <a
                        onClick={this.postRelatedData.bind(
                          this,
                          objectData,
                          "Investigation"
                        )}
                        title={this.props.translator["Relate"]}
                      >
                        <i className="fa fa-chevron-circle-right f-size-16 theme1"></i>
                      </a>
                    </Col>
                  </Row>
                </div>
              </>
            );
          }
        })
      }
      {cmdbRelationshipData.length > 0 && <div className="nBotPagina">
          <div className="nShow">
            <div className="margin-r-10" componentClass={Form.Label}>
              {this.props.translator["Show"]}:
            </div>
            {console.log( "show000")}
            <Form.Select value={this.state.showSize} onChange={this.onPageSizeChange}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </Form.Select>
          </div>
          <nav aria-label="Pagination" className="display-inline-block">
            <Pagination
              prevPageText="Prev"
              nextPageText="Next"
              firstPageText={<i className="glyphicon glyphicon-menu-left" />}
              lastPageText={<i className="glyphicon glyphicon-menu-right" />}
              activePage={this.state.pageNumber}
              itemsCountPerPage={this.state.showSize}
              totalItemsCount={totalRecordCount}
              pageRangeDisplayed={1}
              onChange={(e) => this.onPageChange(e)}
            />
          </nav>
        </div>}
      </>
    );
  }
  
  renderRelationshipList2(cmdbRelationshipData){
    let isReadRole = (onAssetRoleVisible || onConfigManagerRoleVisible);

    console.log("cmdbRelationSHup",cmdbRelationshipData)
    let tableColumns = [
      {
        name: 'Ticket No.',
        selector: (row) => row.CI_ID_NUMBER,
        sortable: false,
        cell: (row) => (
          <CellName row={row}
          module = {this.state.module}
           {...this.props}
          />
          
        ),
        ignoreRowClick: true,
      },
      {
        name: "Related On",
        selector: (row) => row.CREATED_AT,
        sortable: false,
      },
      {
        name: "Related By",
        selector: (row) => row.CREATED_BY,
        sortable: false,
      }
    ];

    if(isReadRole) {
      tableColumns.push({
        name: "Action",
        sortable: false,
        cell: (row) => (
          <CellDeleteAction row={row}
          module = {this.state.module}
          {...this.props}
          />
          
        ),
        ignoreRowClick: true,
    })
    }
let filterColumns = ["CI_ID_NUMBER", "CREATED_AT", "CREATED_BY"]
    return (
      <div className="catBordDv leftSeardv hdprt bsTableRelate dtaTableDv integrateHubTable">
      
        <TableComponent
        data={cmdbRelationshipData.relationshipData || []}
        columns={tableColumns}
        headerColumns={filterColumns}
        uniqueKey={"CI_ID_NUMBER"}
        noPointer={true}
        // onRowSelect={this.onRowSelect}
        />
        </div>
     
    )

  }

  renderDataFulfillment() {
    return (
      <div>
        <Row>
          <Col md={6}>
            <Form.Label>{this.props.translator["Search by"]}</Form.Label>
          </Col>
          <Col md={6}>
            <Form.Select
              defaultValue={this.state.defaultModuleValue}
              componentClass="select"
              onChange={this.changeModule}
            >
              <option value="Select">Select</option>
              <option value="Breakfix">
                {this.props.translator["Break-Fix"]}
              </option>
              <option value="Investigation">
                {this.props.translator["Problem"]}
              </option>
              <option value="ChangeManagement">
                {this.props.translator["Change"]}
              </option>
              <option value="Item">
                {this.props.translator["Fulfillment"]}
              </option>
            </Form.Select>
          </Col>
        </Row>
        { (this.state.module == "Select" || this.state.module == null) ? <Table
          condensed
          responsive
          className="tableView sortImg margin-xs-t-15"
        >
          <tbody>
            <tr>
              <th width="40%">{"Please select the module to view the related items"}</th>
            </tr>
          </tbody>
        </Table>: this.state.showTable && <div> 
        {this.renderRelationshipList2(this.props.cmdbRelationshipData)}
        {/* {console.log(this.props.cmdbRelationshipData, "cmdbRelationshipData")}
        {this.props.cmdbRelationshipData.relationshipData.length > 10} */}
        </div> }
      </div>
    );
  }

  render() {
    // console.log("loadIncData", this.props.loadIncData);
    let formVariable = (
      <div>
        <div>
          <Row>
            <Col md={6}>
              <Form.Label>{this.props.translator["Search By"]}</Form.Label>
              <Form.Select
                defaultValue={null}
                componentClass="select"
                onChange={this.changeSearchModule}
              >
                <option value={null}>Select</option>
                <option value="Breakfix">
                  {this.props.translator["Break-Fix"]}
                </option>
                <option value="Investigation">
                  {this.props.translator["Problem"]}
                </option>
                <option value="ChangeManagement">
                  {this.props.translator["Change"]}
                </option>
                <option value="Item">
                  {this.props.translator["Fulfillment"]}
                </option>
              </Form.Select>
            </Col>
            <Col md={6}>
              <Form.Label>{this.props.translator["Work Item ID"]}</Form.Label>
              <Form.Control
                type="text"
                onChange={this.changeWorkItemId}
                placeholder={this.props.translator["search"]}
              />
            </Col>
          </Row> 
          <div className="btnNew margin-t-10"> 
            <Button
              className="rgSidrkBtn smallBtn"
              size="sm"
              bsStyle="primary"
              title={this.props.translator["Search"]}
              onClick={this.handleSubmit}
            >
              {this.props.translator["Search"]}{" "}
            </Button> 
          </div>
        </div>
        {this.state.isErrorVisible && this.showError()}
        {!this.state.isErrorVisible && this.state.selectedModule == "Breakfix" &&
          this.renderWorkItemList(this.props.loadIncData.data, this.props.loadIncData.meta)}
        {!this.state.isErrorVisible && this.state.selectedModule == "Investigation" &&
          this.renderWorkItemList(this.props.loadPrbData.data ,this.props.loadPrbData.meta )}
        {!this.state.isErrorVisible && this.state.selectedModule == "Item" &&
          this.renderWorkItemList(this.props.loadItmData.data, this.props.loadItmData.meta)}
        {!this.state.isErrorVisible && this.state.selectedModule == "ChangeManagement" &&
          this.renderWorkItemList(this.props.loadCngData.data, this.props.loadCngData.meta)}
      </div>
    );

    return (
      <div className="clDiv Ovrflow StTimeline">
        <Row>
          <Col xs={12}>
            <div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
              <ul>
                <li>
                {(onAssetRoleVisible || onConfigManagerRoleVisible) 
							? (
							<Button
                    bsPrefix=" "
                    onClick={this.toggleFormAndList}
                    bsClass=""
                    bsStyle=""
                    title={
                      this.state.toggleFormAndList == "list"
                        ? this.props.translator["Add"]
                        : this.props.translator["Related Work Items"]
                    }
                  >
                    <i
                      className={
                        this.state.toggleFormAndList == "list"
                          ? "fa fa-plus-square f-size-16"
                          : "fa fa-list-ul f-size-16"
                      }
                    ></i>
                  </Button>
							) : null}
                </li>
                <li className="d-md-none">
                  <button
                      type='button'
                    title="Minimize the right panel"
                    bsClass=""
                    bsStyle=""
                    className="closerightPanelBtn"
                    onClick={() => {
                      this.props.rightEditPanel(false);
                    }}
                  >
                    <IoClose />
                  </button>
                </li>
              </ul>
            </div>
            <div
              className={
                this.props?.treeview ? "rPageHeading d-flex" : "rPageHeading"
              }
            >
              {/* <div className="rPageHeading"> */}
                <div className="offNam margin-t-5 margin-b-5">
                  {this.state.toggleFormAndList == "list"
                    ? this.props.translator["Related Work Items"]
                    : this.props.translator["Relate Work Item"]}
                {/* </div> */}
              </div>
              {this.props?.treeview && (
                <div className="toprigLnk mt-0 ms-auto">
                  <Nav className="icnlnk" as="ul">
                    <Nav.Item as="li">
                      <a
                        className="border-none"
                        onClick={this.props?.handleShowhide}
                      >
                        <i className="fa fa-close text-white" />
                      </a>
                    </Nav.Item>
                  </Nav>
                </div>
              )}
            </div>
          </Col>
        </Row>

        <div className="rBoxGap">
          {this.state.toggleFormAndList == "list"
            ? this.renderDataFulfillment()
            : formVariable}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ cmdbRelationshipData, loadIncData, loadCngData, loadPrbData, loadItmData, showLoader2 }) => {
  // console.log("showLoader2", showLoader2)
  return {
    cmdbRelationshipData, loadIncData, loadCngData, loadPrbData, loadItmData, showLoader2
  };
};

export default connect(mapStateToProps, { getRelationshipData, loadIncDetails, loadChangeDetails, loadPrbDetails, loadItmDetails })(
  CMDBRelationship
);
