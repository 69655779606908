
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Typeahead } from 'react-bootstrap-typeahead';
import "react-bootstrap-typeahead/css/Typeahead.css";

class DropdownPlusTypeahead extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.onCrossClickFun = this.onCrossClickFun.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.inputValue != this.props.inputValue && nextProps.inputValue == '') {
            this._typeahead.clear()
        }
    }

    onCrossClickFun() {
        this.props.onCrossClick();
        this._typeahead.clear()
    }

    render() {
        let { options, onSelection, selectedOptions, setErrorColor, disabled = false, onInputChange, inputValue, onCrossClick, id, errorColor } = this.props;

        const inputProps = {
            value: inputValue ? inputValue : ''
        };

        return (
            <div className={"dvTypehd "+ errorColor}>
                {inputValue && inputValue.length > 0 ?
                    ""
                    : null}
                <Typeahead
                    id={id}
                    ref={(ref) => (this._typeahead = ref)}
                    onChange={onSelection}
                    onInputChange={onInputChange}
                    options={options}
                    selected={selectedOptions}
                    placeholder="Please choose..."
                    onBlur={setErrorColor}
                    disabled={disabled}
                    inputProps={inputProps}
                />
            </div>
        );
    }
}

export default DropdownPlusTypeahead;
