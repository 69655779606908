
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from "axios";
import { navigationHooks } from "../../../helpers/NavigationHook";
import Swal from "sweetalert2";

const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

export const getExternalLinkList = () => {
  return (dispatch) => {
    dispatch({ type: "FDN_DATA_FETCHING" });
    api
      .get("/api/linkHome")
      .then((response) => {
        console.log("ExternalLinkData", response.data);
        dispatch({
          type: "EXTERNAL_LINK_LIST_FETCHED_SUCCESSFULLY",
          payload: response.data,
        });
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
      });
  };
};

export const resetExternalLinksData = () => {
  return (dispatch) => {
    dispatch({ type: "EXTERNAL_LINK_DATA_FETCHED_SUCCESSFULLY", payload: [] });
    dispatch({ type: "EXTERNAL_LINK_IMAGE_UPLOADED_SUCCESSFULLY", payload: '' });
  };
};

export const getExternalLinkEditData = (id) => {
  return (dispatch) => {
    dispatch({ type: "FDN_DATA_FETCHING" });
    api
      .get(`/api/getExternalLinkEditData?id=${id}`)
      .then((response) => {
        console.log("ExternalLinkData", response.data.link);
        dispatch({
          type: "EXTERNAL_LINK_DATA_FETCHED_SUCCESSFULLY",
          payload: response.data.link,
        });
        dispatch({
          type: "EXTERNAL_LINK_IMAGE_UPLOADED_SUCCESSFULLY",
          payload: response.data.link.link_Icon,
        });
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
      });
  };
};

export const saveExternalLink = (payload, linkImage) => {
  return (dispatch) => {
    dispatch({ type: "FDN_DATA_FETCHING" });
    api
      .post(`/api/saveExternalLink`, payload)
      .then((response) => {
        console.log("ExternalLinkData", response.data.link.id);
        dispatch({
          type: "EXTERNAL_LINK_DATA_SAVED_SUCCESSFULLY",
          payload: response.data,
        });
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });

        let formData;
        if (linkImage && linkImage.length > 0) {
          formData = new FormData();
          if (linkImage) {
            formData.append("file", linkImage[0]);
          } else {
            formData.append("file", "");
          }
          dispatch(uploadExternalLinkImage(response.data.link.id, formData));
        }
        navigationHooks.navigate("/externalLink");
      })
      .catch((err) => {
        if(err.response.data.linkSequenceErrorMsg === "sequence already exists for selected company.") {
          Swal.fire("Link with selected sequence already exists.");
        }
        if(err.response.data.top_three_link_error === "Maximum of three links can be added as top link.") {
          Swal.fire("Maximum of three links can be added as top link.");
        }
        console.log(err.response.data.linkSequenceErrorMsg);
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
      });
  };
};

export const updateExternalLink = (payload, linkImage) => {
  return (dispatch) => {
    dispatch({ type: "FDN_DATA_FETCHING" });
    api
      .post(`/api/updateExternalLink`, payload)
      .then((response) => {
        console.log("ExternalLinkData", response.data);
        dispatch({
          type: "EXTERNAL_LINK_DATA_SAVED_SUCCESSFULLY",
          payload: response.data,
        });
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
        let formData;
        if (linkImage && linkImage.length > 0) {
          formData = new FormData();
          if (linkImage) {
            formData.append("file", linkImage[0]);
          } else {
            formData.append("file", "");
          }
          dispatch(uploadExternalLinkImage(response.data.link.id, formData));
        }
        navigationHooks.navigate("/externalLink");
      })
      .catch((err) => {
        if(err.response.data.linkSequenceErrorMsg === "sequence already exists for selected company.") {
          Swal.fire("Link with selected sequence already exists.");
        }
        if(err.response.data.top_three_link_error === "Maximum of three links can be added as top link.") {
          Swal.fire("Maximum of three links can be added as top link.");
        }
        console.log(err);
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
      });
  };
};

export const uploadExternalLinkImage = (linkId, payload) => {
  return (dispatch) => {
    dispatch({ type: "FDN_DATA_FETCHING" });
    api
      .post(`/fdn-img/api/updateExternalLinkImage?id=${linkId}`, payload)
      .then((response) => {
        console.log("ExternalLinkData", response.data);
        dispatch({
          type: "EXTERNAL_LINK_IMAGE_UPLOADED_SUCCESSFULLY",
          payload: response.data,
        });
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
      });
  };
};


export const deleteExternalLinkData = (id) => {
  return (dispatch) => {
    api.delete("/api/deleteLinkSelfService/"+id)
      .then((response) => {
        console.log("BulletinDelete",response)
        Swal.fire({
          title: 'Record deleted successfully',
        }).then(result => {
          if (result.isDismissed || result.isConfirmed) {
            navigationHooks.navigate('/externalLink')
          }
        })
      })
      .catch((err) => {
        console.log(err);
      });
  };
};