/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { useDispatch } from "react-redux";
import { QueryBuilder, defaultValidator } from "react-querybuilder";
import { FieldValueSelector, FieldValueSelector as OperatorValueSelector, ValueEditor } from "../../../../components/common/componentsForQueryBuilder";
import { useSelector } from "react-redux";
import { CustomAddGroupButton, CustomAddRuleButton, CustomRemoveGroupButton, 
         CustomRemoveRuleButton } from "./Custom-Button-Components";


const CustomQueryBuilder = ({fields, query, setResult}) => {
  const dispatch = useDispatch();  
  const testFieldSelector = {
    addGroupAction: CustomAddGroupButton,
    addRuleAction: CustomAddRuleButton,
    removeRuleAction: CustomRemoveRuleButton,
    removeGroupAction: CustomRemoveGroupButton,

    fieldSelector: FieldValueSelector,
    operatorSelector: OperatorValueSelector,
    valueEditor: ValueEditor,
  };

  return (
        <QueryBuilder
          controlClassnames={{ queryBuilder: "queryBuilder-branches" }}
          fields={fields}
          query={query}
          onQueryChange={(q) => { dispatch({type: 'PRODUCED_QUERY', payload: q}); setResult(false); }}
          validator={defaultValidator}
          controlElements={testFieldSelector}
        />
    );
};

export default CustomQueryBuilder;