
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import ListLoader from '_Commons/loaders/ListLoader';
import { Form, Table } from "react-bootstrap";
import { IoSearch } from "react-icons/io5";
import Pagination from "react-js-pagination";
import { getCostCenterList } from "../../../../actions/foundation/costCenterAction";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import ReactDOM from "react-dom";

const ListSec = (props) => {
  const dispatch = useDispatch();
  const costCenterListData = useSelector((state) => state.costCenterList);
  const showLoader = useSelector((state) => state.commonLoader.isLoading);
  const translator = useSelector((state) => state.spcmReducer.tr);

  const [costCenterSearchFilter, setCostCenterSearchFilter] = useState("");
  const [companySearchFilter, setCompanySearchFilter] = useState("");
  const [isMultiSelectOpen, setIsMultiSelectOpen] = useState(false);

  let statusOptions = [
    { label: 'Active', value: '1' },
    { label: 'Inactive', value: '0' }
  ];

  const multiSelectStatusDropdownRef = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', clickOutside);
    if (multiSelectStatusDropdownRef != undefined || multiSelectStatusDropdownRef != null) {
      multiSelectStatusDropdownRef.current.childNodes[0].className = "multiSelectRef";
      let myRef = multiSelectStatusDropdownRef;
      if (isMultiSelectOpen) {
        let obj = ReactDOM.findDOMNode(myRef.current).childNodes[0].childNodes[1];
        if (obj != undefined || obj != null) {
          ReactDOM.findDOMNode(myRef.current).childNodes[0].childNodes[1].className = "multiSelectPopup";
          //ReactDOM.findDOMNode(myRef.current).childNodes[0].childNodes[1].style = "right:" + objRightPos + "px";
        }
      }
    }
  }, [isMultiSelectOpen])

  useEffect(() => {
    return () => {
      document.removeEventListener('mousedown', clickOutside);
    }
  }, [])

  const clickOutside = (event) => {
    const statusDiv = document.getElementById("statusDiv");
    if (multiSelectStatusDropdownRef.current != undefined || multiSelectStatusDropdownRef.current != null) {
      document.addEventListener("mousedown", (event) => {
        if (statusDiv.contains(event.target)) {
          setIsMultiSelectOpen(true);
          props.setRightSideVisible(false);
        }
        else {
          setIsMultiSelectOpen(false);
        }
      });
    }
  }

  const handleSizePerPageChange = (event) => {
    props.setrowIndex(0);
    props.setLimit(event.target.value);
    props.setRightSideVisible(false);
    dispatch(getCostCenterList(props.page, event.target.value, "costcenterName", "asc", props.searchKey, props.searchValue, props.status));
  };

  const handlePageChange = (event) => {
    props.setrowIndex(0);
    props.setRightSideVisible(false);
    dispatch(getCostCenterList(event, props.limit, "costcenterName", "asc", props.searchKey, props.searchValue, props.status));
  };

  const onRowSelect = (item, index) => {
    props.setrowIndex(index);
    props.setRightSideVisible(true);
    props.setCostCenterDetails(item);
  };

  const handleChange = (e, searchValue) => {
    switch (searchValue) {
      case "costCenterName":
        props.setCostCenterSearchInput(e.target.value);
        setCostCenterSearchFilter(searchValue);
        break;
      case "companyName":
        props.setCompanySearchInput(e.target.value);
        setCompanySearchFilter(searchValue);
        break;
    }
  };

  const handleSearch = () => {
    let searchByVar = [];
    let searchByValue = [];
    props.setClearAllFilterToggle(true);
    if (props.costCenterSearchInput != "") {
      searchByValue.push(props.costCenterSearchInput.trim());
      searchByVar.push(costCenterSearchFilter);
    }
    if (props.companySearchInput != "") {
      searchByValue.push(props.companySearchInput.trim());
      searchByVar.push(companySearchFilter);
    }
    props.setSearchValue(searchByValue.join("|"));
    props.setSearchKey(searchByVar.join(","));
    props.setRightSideVisible(false);
    dispatch(getCostCenterList(props.page, props.limit, "costcenterName", "asc", searchByVar.join(","), searchByValue.join("|"), props.status));
  };

  const handleStatusChange = (value) => {
    let searchOnStatusArr = [];
    props.setSelectedStatusValue(value);
    (props.searchKey.length === 0) ?
      value.length == 0 ?
        props.setClearAllFilterToggle(false) :
        props.setClearAllFilterToggle(true) :
      props.setClearAllFilterToggle(true);

    props.setRightSideVisible(false);
    value.map((checkBoxValue) => {
      return (
        searchOnStatusArr.push(checkBoxValue.value)
      );
    }
    );
    props.setMultiSelectParams(searchOnStatusArr);
    dispatch(getCostCenterList(props.page, props.limit, "costcenterName", "asc", props.searchKey, props.searchValue, searchOnStatusArr));
  }

  const renderCostCenterList = (costCenterList) => {
    return costCenterList.map((item, index) => {
      let cls = index == props.rowIndex ? "myActive" : "";
      let status = item.STATUS === '1' ? "Active" : "Inactive"
      return (
        <tr
          className={
            props.isRightSideVisible && index == props.rowIndex
              ? "myActive"
              : ""
          }
          onClick={(e) => onRowSelect(item, index)}
        >
          <td title={item.costCenterName}>
            <div className="showdv414">
              <div className="valdv414">
                {item.costCenterName}
              </div>
            </div>
          </td>
          <td title={item.companyName}>
            <div className="showdv414">
              <div className="valdv414">
                {item.companyName}
              </div>
            </div>
          </td>
          <td title={status}>
            <div className="showdv414">
              <div className="valdv414">
                {status}
              </div>
            </div>
          </td>
        </tr>
      );
    });
  };

  let meta = costCenterListData.meta;
  let caseResponsive =
    showLoader || costCenterListData.costCenterList == [] || costCenterListData.costCenterList?.length < 6 ? "overFlowClp" : "table-responsive";

  return (
    <div>
      <div aria-label="Table" role="contentinfo" className={(showLoader || costCenterListData.costCenterList == [] || costCenterListData.costCenterList?.length < 6) ? "respondv responDvMinH" : "respondv"}>
        <div className={"tableRgtBor " + caseResponsive}>
          <Table
            striped
            bordered
            condensed
            hover
            className="tableView nowrapWhright"
            style={{ marginTop: "0px" }}
          >
            <thead>
              <tr>
                <th>
                  <div className="sortParArr">{translator["Cost Center"]}</div>
                  <div className="colSearDv ">
                    <Form.Control
                      className="colSearInp"
                      type="text"
                      value={
                        props.costCenterSearchInput != ""
                          ? props.costCenterSearchInput
                          : ""
                      }
                      placeholder={translator["Search here"]}
                      onKeyPress={(e) => {
                        if (e.charCode == "13") {
                          if (
                            props.costCenterSearchInput != "" &&
                            props.costCenterSearchInput.trim().length > 2
                          )
                            handleSearch();
                        }
                      }}
                      onChange={(event) => {
                        handleChange(event, "costCenterName");
                      }}
                    />
                    {props.costCenterSearchInput.trim().length > 2 ? (
                      <a
                        title="search"
                        className="faicn"
                        href={void 0}
                        onClick={(event) => handleSearch()}
                      >
                        <IoSearch />
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </th>
                <th>
                  <div className="sortParArr">{translator["Company"]}</div>
                  <div className="colSearDv ">
                    <Form.Control
                      className="colSearInp"
                      type="text"
                      value={
                        props.companySearchInput != ""
                          ? props.companySearchInput
                          : ""
                      }
                      placeholder={translator["Search here"]}
                      onKeyPress={(e) => {
                        if (e.charCode == "13") {
                          if (
                            props.companySearchInput != "" &&
                            props.companySearchInput.trim().length > 2
                          )
                            handleSearch();
                        }
                      }}
                      onChange={(event) => {
                        handleChange(event, "companyName");
                      }}
                    />
                    {props.companySearchInput.trim().length > 2 ? (
                      <a
                        title="search"
                        className="faicn"
                        href={void 0}
                        onClick={(event) => handleSearch()}
                      >
                        <IoSearch />
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </th>
                <th>
                  <div className="sortParArr">{translator["Status"]}</div>
                  <div
                    id="statusDiv"
                    className="colSearDv"
                    ref={multiSelectStatusDropdownRef}
                  >
                    <ReactMultiSelectCheckboxes
                      placeholderButtonLabel={translator["Select"]}
                      rightAligned={true}
                      options={statusOptions}
                      onChange={handleStatusChange}
                      value={props.selectedStatusValue}
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {costCenterListData.length === 0 || showLoader ? (
                <tr>
                  <td colSpan="7" style={{ textAlign: "center" }}>
                    <ListLoader />
                  </td>
                </tr>
              ) : costCenterListData.costCenterList.length == 0 ? (
                <tr>
                  <td colSpan="7" style={{ textAlign: "center" }}>
                    {translator["There is no matching data available"]}
                  </td>
                </tr>
              ) : (
                renderCostCenterList(costCenterListData.costCenterList)
              )}
            </tbody>
          </Table>
        </div>

        {costCenterListData.costCenterList && costCenterListData.costCenterList.length !== 0 ? (
          <div className="nBotPagina">
            <div className="nShow">
              <div className="margin-r-10" componentClass={Form.Label}>
                {translator["Show"]}:
              </div>
              <div aria-label="Show Count" role="contentinfo">
                <Form.Select
                  aria-label={translator["Show"]}
                  className=""
                  onChange={handleSizePerPageChange}
                  value={props.limit}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </Form.Select>
              </div>
            </div>
            <nav aria-label="Pagination" className="display-inline-block">
              <Pagination
                prevPageText={translator["Prev"]}
                nextPageText={translator["Next"]}
                firstPageText={<i className="glyphicon glyphicon-menu-left" />}
                lastPageText={<i className="glyphicon glyphicon-menu-right" />}
                activePage={meta ? Number(meta.currentPage) : 1}
                itemsCountPerPage={props.limit}
                totalItemsCount={meta ? meta.rowCount : 1}
                pageRangeDisplayed={props.panelResize <= 42 ? 2 : 5}
                onChange={(e) => handlePageChange(e)}
              />
            </nav>
          </div>
        ) : (
          <span />
        )}
      </div>
    </div>
  );
}

export default ListSec;