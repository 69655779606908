
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Tabs, Tab} from 'react-bootstrap';

import RootCauseFormTab from './speRootCause.js';
import RecordInvestigationFindings from './RecordInvestigationFindings.js';
import {IoClose} from "react-icons/io5";

class RootCauseDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabKey: 1,
        };
        this.handleSelect = this.handleSelect.bind(this);
    }

	// overlayExit(){
	// 	document.body.style.overflow = "visible";
	// }    
    handleSelect(tabKey) {
        this.setState({ tabKey });
    }
    render() {

        return (
            <div>
                <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
                    <ul>
                        <li>
                        <button
                            type='button'
                            title="Minimize the right panel"
                            bsClass=""
                            bsStyle=""
                            className="closerightPanelBtn"
                            onClick={() => {
                            this.props.rightEditPanel(false);
                            }}
                        >
                            <IoClose />
                        </button>
                        </li>
                    </ul>
                </div>
                <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Root Cause Details']}</div></div>
                <div className="rBoxGap">
                    {( ['45', '50', '55', '60', '65', '70'].includes(this.props?.status) ) ? 
                        <RootCauseFormTab investigationId={this.props.investigationId} translator={this.props.translator} user_id={this.props.user_id} roleIdentification={this.props.roleIdentification} changeActionView={this.props.changeActionView} showTitle={false} status={this.props?.status} isProblemManagerEnabled={this.props.isProblemManagerEnabled}/>
                    :
                        <div className="rBoxTabs">
                            <Tabs activeKey={this.state.tabKey} onSelect={this.handleSelect} id="tabId">
                                <Tab eventKey={1} title={this.props.translator["Specify Root Cause"]}>
                                    <RootCauseFormTab investigationId={this.props.investigationId} translator={this.props.translator} user_id={this.props.user_id} roleIdentification={this.props.roleIdentification} changeActionView={this.props.changeActionView}
                                    showTitle={false}
                                    status={this.props?.status}
                                    disableFieldsForGuestRole={this.props.disableFieldsForGuestRole}
                                    isServiceDeskRolesEnabled={this.props.isServiceDeskRolesEnabled}
                                    isProblemManagerEnabled={this.props.isProblemManagerEnabled}
                                    />
                                </Tab>
                                <Tab eventKey={2} title={this.props.translator["Record Problem Findings"]}>
                                    <RecordInvestigationFindings translator={this.props.translator} showTitle={false} disableFieldsForGuestRole={this.props.disableFieldsForGuestRole} isServiceDeskRolesEnabled={this.props.isServiceDeskRolesEnabled}/>
                                </Tab>                        
                            </Tabs>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default RootCauseDetails;


