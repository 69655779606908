
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Accordion,
  Tab,
  Tabs,
  ListGroup,
  Button,
} from "react-bootstrap";
import ReactReadMoreLess from "../../../../helpers/ReactReadMoreLess";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {PiPencilSimpleBold} from "react-icons/pi";
import {IoClose} from "react-icons/io5";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';

const ShortDescription = (props) => {
  const navigate = useNavigate();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const context = useContext(MyContext);
    const closeButton = useRef(null);
    const editButton = useRef(null);

    useEffect(() => {
        context.keyDownHandler(closeButton.current,editButton.current,"","");
        context.newTabPrevent();
    }, []);

  //console.log("ShortData", props.extLinkData);
  let lessKey = "...";
  let lessKeyTr = tr["less"];
  lessKey = lessKey.concat(lessKeyTr);
  return (
    <>
      <Row>
        <Col xs={12}>
          <div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
            <ListGroup bsPrefix=" " as="ul">
              <ListGroup.Item as="li" bsPrefix=" ">
                <Link
                  bsPrefix=" "
                  to="/editExternalLink"
                  state= {{
                    formType: "Edit",
                    link_Id: props.extLinkData.link_Id,
                  }}
                  title={tr["Edit"]}
                  className="editIcn ctrlKeyPrevent"
                  ref={editButton}
                >
                  <PiPencilSimpleBold/>
                </Link>
              </ListGroup.Item>
              <ListGroup.Item as="li" bsPrefix=" ">
                <Link to="javascript:void(0)" bsPrefix=" " title={tr["Minimize the details panel"]} className="ctrlKeyPrevent" onClick={() => { props.showRightSideFun(false); }} ref={closeButton} >
                    <IoClose/>
                </Link>
              </ListGroup.Item>
            </ListGroup>
          </div>
          <div className="rPageHeading">
            <div className="offNam margin-t-5 margin-b-5">
              {tr["External Link Details"]}
            </div>
          </div>
        </Col>
      </Row>
      <div className="rBoxGap">
        <div className="rBoxTabs">
          <ListGroup as="ul" bsPrefix=" ">
            <ListGroup.Item
              as="li"
              bsPrefix=" "
              className="rwTable"
              style={{ borderTop: "none" }}
            >
              <span className="rwKey">{tr["Link Name"]}</span>
              <span className="rwVal">{props.extLinkData.link_Name}</span>
            </ListGroup.Item>

            <ListGroup.Item as="li" bsPrefix=" " className="rwTable">
              <span className="rwKey">{tr["Company"]}</span>
              <span className="rwVal">{props.extLinkData.company_Name}</span>
            </ListGroup.Item>

            <ListGroup.Item as="li" bsPrefix=" " className="rwTable">
              <span className="rwKey">{tr["URL"]}</span>
              <span className="rwVal">
                <ReactReadMoreLess charLimit={60}
                  readMoreText={tr["more"]}
                  readLessText={lessKey}
                  content={props.extLinkData.link_URL} />
              </span>
            </ListGroup.Item>

            <ListGroup.Item as="li" bsPrefix=" " className="rwTable">
              <span className="rwKey">{tr["Sequence"]}</span>
              <span className="rwVal">{props.extLinkData.linkSequence}</span>
            </ListGroup.Item>

            <ListGroup.Item as="li" bsPrefix=" " className="rwTable">
              <span className="rwKey">{tr["Status"]}</span>
              <span className="rwVal">{props.extLinkData.link_status}</span>
            </ListGroup.Item>
          </ListGroup>
        </div>
      </div>
    </>
  );
};

export default ShortDescription;
