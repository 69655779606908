
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import { FETCH_SERVICELIST } from '../../actions/categorylist/servicecategoryfilterAction'

export default function(state=[], action){
    //console.log('Action received:',action);

    switch (action.type){
        case FETCH_SERVICELIST:
            return action.servicelist.data;
            default:
            return state;
    }



}
