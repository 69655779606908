
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import Cookies from "universal-cookie";
import _ from "lodash";

const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home") homepagelocation = "/" + homepagelocation;

import { useDispatch, useSelector } from "react-redux";
import { navigationHooks } from "../../helpers/NavigationHook";
import NotificationBoardHeader from "./Template/ListView/NotificationBoardHeader";
import NotificationBoardList from "./Template/ListView/NotificationBoardList";
import NotificationBoardShortDesc from "./Template/ListView/NotificationBoardShortDesc";
import NotificationBoardConf from "./Configuration/NotificationBoardConf";
import Breadcrumbs from "../common/header/breadcrumbs";
import "_Css/common/_table.scss";

const NotificationBoardIndex = () => {
  const [isCrossClicked, setIsCrossClicked] = useState(false);
  const [subMenu, setSubMenu] = useState("4");
  const [showHideAlfy, setShowHideAlfy] = useState(false);
  const [showHideRightNav, setShowHideRightNav] = useState(false);
  const [user_id, setUser_id] = useState("");
  const [date_format, setDate_format] = useState("");
  const [activeKey, setActiveKey] = useState(1);
  const [isRightSideVisible, setisRightSideVisible] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);
  const [rowIndex, setRowIndex] = useState(0);
  const [ID, setID] = useState("");
  const [COMPANY_NAME, setCOMPANY_NAME] = useState("");
  const [MODULE_NAME, setMODULE_NAME] = useState([]);
  const [STATUS, setSTATUS] = useState([]);
  const [LOCALE_ID, setLOCALE_ID] = useState([]);
  const [NOTIFICATION_NAME, setNOTIFICATION_NAME] = useState("");
  const [UPDATED_AT, setUPDATED_AT] = useState("");
  const [module, setModule] = useState("");
  const [status, setStatus] = useState("");
  const [locale,setLocale] = useState("");
  const [searchParamsValue, setsearchParamsValue] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [searchParams, setSearchParams] = useState({});
  const [pattern, setPattern] = useState("");
  const [isClearAllFilter, setIsClearAllFilter] = useState(false);
  const [companySearchInput, setCompanySearchInput] = useState("");
  const [selectedModuleValue, setSelectedModuleValue] = useState([]);
  const [selectedDate, setSelectedDate] = useState([]);
  const [nameSearchInput, setNameSearchInput] = useState("");
  const [selectedStatusValue, setSelectedStatusValue] = useState([]);
  const [selectedLangaugeValue,setSelectedLanguageValue] = useState([]);
  const [panelResize,setPanelResize] = useState();
  const [filterTabActive, setFilterTabActive] = useState(1);
  const [type, setType] = useState('default');
  const [modifiedStartDate, setModifiedStartDate] = useState({});
  const [modifiedEndDate, setModifiedEndDate] = useState({});
  const [modifiedDateFilterValues, setModifiedDateFilterValues] = useState([]);
  const [isStatusOpen, setStatusOpen] = useState(false);
  const [isLangOpen, setLangOpen] = useState(false);
  const [isModuleOpen, setModuleOpen] = useState(false);
  const [modifiedDateRef, setModifiedDateRef] = useState(null);

  const translator = useSelector((state) => state.spcmReducer.tr);
  const spcmReducer = useSelector((state) => state.spcmReducer);
  const showLoader_ne = useSelector(state => state.showLoader_ne.loading);

  const dispatch = useDispatch();

  const setMultiSelectParams = (locale, module, status) => {
    setModule(module);
    setStatus(status);
    setLocale(locale);
  };
  const setActiveKeyVal = (value) => {
    setActiveKey(value);
    setisRightSideVisible(false);
    setIsClearAllFilter(false);
    setMODULE_NAME([]);
    setSTATUS([]);
    setLOCALE_ID([]);
    setNameSearchInput("");
    setSelectedModuleValue("");
    setSelectedStatusValue("");
    setSelectedDate("");
    setCompanySearchInput("");
    setSearchParams({});
    setSelectedLanguageValue("");
  };
  const getNotificationDetails = (
    ID,
    COMPANY_NAME,
    MODULE_NAME,
    LOCALE_ID,
    STATUS,
    NOTIFICATION_NAME,
    UPDATED_AT
    
  ) => {
    setID(ID);
    setCOMPANY_NAME(COMPANY_NAME);
    setMODULE_NAME(MODULE_NAME);
    setLOCALE_ID(LOCALE_ID);
    setSTATUS(STATUS);    
    setNOTIFICATION_NAME(NOTIFICATION_NAME);
    setUPDATED_AT(UPDATED_AT)
   
  };
  const setTypeFun = (val) =>{
    if(val == 'default'){
      setFilterTabActive(1);
      setType(val)
    }
    else if(val == 'custom'){
      setFilterTabActive(2);
      setType(val)
    }
  }
  const setModifiedStartAndEndDate = (startDate, endDate, filterValue)=> {
    setModifiedStartDate(startDate)
    setModifiedEndDate(endDate)
    setModifiedDateFilterValues(filterValue)
  }
  const showPage = (val) => {
    if (val == 1) {
      return (
        <NotificationBoardList
          setModifiedStartAndEndDate={setModifiedStartAndEndDate}
          modifiedDateFilterValues={modifiedDateFilterValues}
          modifiedEndDate={modifiedEndDate}
          modifiedStartDate={modifiedStartDate}
          type ={type}
          setTypeFun={setTypeFun}
          spcmReducer={spcmReducer}
          tr={translator}
          setActiveKeyVal={setActiveKeyVal}
          onRowClick={setisRightSideVisible}
          page={page}
          size={size}
          isRightSideVisible={isRightSideVisible}
          showRightSide={setisRightSideVisible}
          setPage={setPage}
          setLimit={setSize}
          showActiveRow={setRowIndex}
          rowIndex={rowIndex}
          getNotificationDetails={getNotificationDetails}
          setNameSearchInput={setNameSearchInput}
          setCompanySearchInput={setCompanySearchInput}
          setSelectedStatusValue={setSelectedStatusValue}
          setSelectedLanguageValue={setSelectedLanguageValue}
          setSelectedModuleValue={setSelectedModuleValue}
          setSelectedDate={setSelectedDate}
          setSearchParams={setSearchParams}
          setMultiSelectParams={setMultiSelectParams}
          status={status}
          module={module}
          locale={locale}
          nameSearchInput={nameSearchInput}
          companySearchInput={companySearchInput}
          selectedModuleValue={selectedModuleValue}
          selectedDate={selectedDate}
          selectedStatusValue={selectedStatusValue}
          selectedLangaugeValue={selectedLangaugeValue}
          isClearAllFilterFunc={setIsClearAllFilter}
          searchParams={searchParams}
          COMPANY_NAME={COMPANY_NAME}
          ID={ID}
          MODULE_NAME={MODULE_NAME}
          STATUS={STATUS}
          NOTIFICATION_NAME={NOTIFICATION_NAME}
          LOCALE_ID={LOCALE_ID}
          panelResize={panelResize}
          isStatusOpen={isStatusOpen}
          isLangOpen={isLangOpen}
          isModuleOpen={isModuleOpen}
          multiSelectOpenFun={multiSelectOpenFun}
          setModifiedDateRef={setModifiedDateRef}
        />
      );
    }
    else if (val == 2) {
      return (
        <NotificationBoardConf
          translator={translator}
          user_id={user_id}
          date_format={date_format}
          isCrossClicked={isCrossClicked}
          isCrossClickedFunc={setIsCrossClicked}
          dispatch={dispatch}
          LOCALE_ID={LOCALE_ID}
          isRightSideVisible={isRightSideVisible}
        />
      );
    }
    //  else {
    //   return <EditTemplate translator={translator} ID={ID} />;
    // }
  };

  const multiSelectOpenFun = (keyName, val) => {
		switch (keyName) {
		  case "notiBoardModule": 
      {
        setStatusOpen(false);
        setLangOpen(false);
        setModuleOpen(val);
      };
			break;
			case "notiBoardstatus":
        {
          setStatusOpen(val);
          setLangOpen(false);
          setModuleOpen(false);
        };
			break;
			case "notiBoardLang":
        {
          setStatusOpen(false);
          setLangOpen(val);
          setModuleOpen(false);
        };
			break;
			case "allFalse":
        {
          setStatusOpen(false);
          setLangOpen(false);
          setModuleOpen(false);
        };
			break;
		}
  }

  let notificData = spcmReducer.notification_data ? spcmReducer.notification_data : { data: [] };
  let meta = !_.isEmpty(spcmReducer.notification_data)
    ? spcmReducer.notification_data.meta
    : {};
  let pageSize = meta && meta.limit ? meta.limit : 0;
  let currentPage = meta && meta.currentPage ? meta.currentPage : 1;
  let startLimit = pageSize * (currentPage - 1) + 1;
  let endLimit = pageSize * currentPage;
  let recordCount = meta && meta.rowCount ? meta.rowCount : 0;
  // console.log("modifiedStartDate", modifiedStartDate)
  // console.log("modifiedEndDate", modifiedEndDate)
  return (
    <div>
      <div className="minHeightWIB">
        <div className="container-fluid padding-t-10 margin-b-15">
          <Row className="">
            <Col lg={8} md={8} sm={9} xs={9}>
              <Breadcrumbs activePageName={translator["Notification Board"]} />
            </Col>
            {activeKey == 1 ? (
              <Col lg={4} md={4} sm={3} xs={3}>
                {recordCount != 0 ? (
                  <div className="tabDatacount">
                    {startLimit}-
                    {endLimit > recordCount
                      ? (endLimit = recordCount)
                      : endLimit}{" "}
                    of {recordCount}
                  </div>
                ) : null}
              </Col>
            ) : (
              ""
            )}
          </Row>
        </div>

        <div className="container-fluid" >
          {/* Xsm All Quick View Header Section Start*/}
          <NotificationBoardHeader
            setModifiedStartAndEndDate={setModifiedStartAndEndDate}
            modifiedDateFilterValues={modifiedDateFilterValues}
            setModifiedEndDate={setModifiedEndDate}
            setModifiedStartDate={setModifiedStartDate}
            setTypeFun={setTypeFun}
            type={type}
            filterTabActive={filterTabActive}
            setFilterTabActive={setFilterTabActive}
            translator={translator}
            isCrossClicked={isCrossClicked}
            isCrossClickedFunc={setIsCrossClicked}
            activeKey={activeKey}
            setActiveKeyVal={setActiveKeyVal}
            showRightSide={setisRightSideVisible}
            setPage={setPage}
            setLimit={setSize}
            isClearAllFilter={isClearAllFilter}
            setNameSearchInput={setNameSearchInput}
            setCompanySearchInput={setCompanySearchInput}
            setSelectedStatusValue={setSelectedStatusValue}
            setSelectedLanguageValue={setSelectedLanguageValue}
            setSelectedModuleValue={setSelectedModuleValue}
            setSelectedDate={setSelectedDate}
            setSearchParams={setSearchParams}
            setMultiSelectParams={setMultiSelectParams}
            isClearAllFilterFunc={setIsClearAllFilter}
            isRightSideVisible={isRightSideVisible}
            status={status}
            module={module}
            locale={locale}
            page={page}
            size={size}
            searchParams={searchParams}
            modifiedDateRef={modifiedDateRef}
            setModifiedDateRef={setModifiedDateRef}
          />
          {/* Xsm All Quick View Header Section end*/}
          <PanelGroup direction="horizontal" className={(isModuleOpen || isLangOpen || isStatusOpen) && (showLoader_ne || notificData.data?.length <= 6) ? "formGroupB10 myProfileLabel myOrders overflow-clip" : "formGroupB10 myProfileLabel myOrders"}>
            <Panel id="sidebar" minSize={33} order={1} defaultSize={isRightSideVisible ? 67 : 100}  onResize={(size) => setPanelResize(size)} className={(isModuleOpen || isLangOpen || isStatusOpen) && (showLoader_ne || notificData.data?.length <= 6) ? "overflow-clip" : (isRightSideVisible ? "isShowLeftPanel rwLeftPart" : "rwLeftPart")}>
              {showPage(activeKey)}
            </Panel>
            {isRightSideVisible ?
                <>
                  <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
                  <Panel minSize={33} order={2} defaultSize={33}>
                    <div className="stickyArea rBoxStyle">
                      <NotificationBoardShortDesc
                        tr={translator}
                        onRowClick={setisRightSideVisible}
                        showActiveRow={setRowIndex}
                        rowIndex={rowIndex}
                        isRightSideVisible={isRightSideVisible}
                        COMPANY_NAME={COMPANY_NAME}
                        ID={ID}
                        MODULE_NAME={MODULE_NAME}
                        STATUS={STATUS}
                        NOTIFICATION_NAME={NOTIFICATION_NAME}
                        LOCALE_ID={LOCALE_ID}
                      />
                    </div>
                  </Panel>
                </> : ""
            }
          </PanelGroup>
        </div>
      </div>
    </div>
  );
};

export default NotificationBoardIndex;
