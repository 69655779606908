
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const titleComponent = (e) => {
  e.DomComponents.addType("titleComponent", {
    model: {
      defaults: {
        tagName: "div",
        traits: [
          {
            type: "text",
            label: "Title Text",
            name: "titleText",
            changeProp: 1,
          },
        ],
      },
      init() {
        this.on("change:titleText", this.handleInnerTextChange);
      },
      handleInnerTextChange() {
        const tag = this.find("span")[0];
        const label = this.get("titleText");
        if (tag) {
          tag.components(label);
        }
      },
    },
  });
};

export const titleBlockJson = {
  label: "My Title",
  id: "myTitleBlock",
  // attributes: { class: "fa fa-circle" },
  media:`<svg fill="#212121" width="40px" height="40px" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg" stroke="#212121"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M1751 0v1920H169V0h1582Zm-115 112H290v9h-1v1678h1v20h1346V112Zm-234 235v321H514V347h888Z" fill-rule="evenodd"></path> </g></svg>`,
  category: "Basic",
  content: `
      <div><h3 data-gjs-type="titleComponent">
      <span>Title<span></h3>
      <div>
    `,
};
