
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";

import PindCategories from "../../common/header/pind-categories";
import Breadcrumbs from "../../common/header/breadcrumbs";
import SchedulerListView from "./SchedulerListView";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useDispatch } from "react-redux";
import SchedulerListHeader from "./SchedulerListHeader";
import ShortDescription from "./shortDesc";

const SchedulerListIndex = () => {
  const dispatch = useDispatch();
  const [isRightSideVisible, setRightSideVisible] = useState(false);
  const [schedulerData, setSchedulerData] = useState({});
  let colWidth = "";
  isRightSideVisible ? (colWidth = 8) : (colWidth = 12);

  const showRightSideFun = (val) => {
    setRightSideVisible(val);
  };

  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        {/* <div className="tabDatacount">1-1 of 1</div> */}
        <Breadcrumbs activePageName="Scheduler" />
      </Container>

      <Container fluid>
        <SchedulerListHeader showRightSideFun={showRightSideFun} />
        <PanelGroup direction="horizontal" className="panelOverflow">
          <Panel
            id="sidebar"
            minSize={30}
            order={1}
            defaultSize={isRightSideVisible ? 67 : 100}
            className={
              isRightSideVisible ? "isShowLeftPanel catBordDv" : "catBordDv"
            }
          >
            <SchedulerListView
              rightDisplay={isRightSideVisible}
              showRightSideFun={showRightSideFun}
              schedulerData={schedulerData}
              setSchedulerData={setSchedulerData}
              isRightSideVisible={isRightSideVisible}
            />
          </Panel>
          {isRightSideVisible ? (
            <>
              <PanelResizeHandle className="isShowLeftPanel resizeHandle">
                <div className="outlne">
                  <div className="handIcn">
                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                  </div>
                </div>
              </PanelResizeHandle>
              <Panel minSize={33} order={2} defaultSize={33}>
                <div className="stickyArea rBoxStyle">
                  <ShortDescription
                    showRightSideFun={showRightSideFun}
                    schedulerData={schedulerData}
                  />
                </div>
              </Panel>
            </>
          ) : (
            ""
          )}
        </PanelGroup>
      </Container>
    </main>
  );
};

export default SchedulerListIndex;
