
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import moment from "moment";
import { DateField, DatePicker } from "react-date-picker";
import TimePicker from "rc-time-picker";
import ReactQuill from "react-quill";
import CKEditor from "react-ckeditor-component";
import { Typeahead } from "react-bootstrap-typeahead";
import { Multiselect } from "react-widgets";
import { InputGroup, OverlayTrigger, Popover } from "react-bootstrap";
import { HexColorInput, HexColorPicker } from "react-colorful";
import { useSelector } from "react-redux";
import "react-quill/dist/quill.snow.css";

export const _textArea = ({
  input,
  label,
  type,
  className,
  placeholder,
  maxLength,
  disabled,
  rows,
  meta: { touched, error },
}) => {
  return (
    <div>
      <textarea
        {...input}
        className={touched && error ? className + " error" : className}
        rows={rows}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disabled}
      />
      {/* {touched && error && <span> {error} </span>} */}
      {}
    </div>
  );
};

export const _number = ({
  input,
  label,
  type,
  min,
  max,
  className,
  disabled,
  rows,
  placeholder = "",
  meta: { touched, error },
}) => {
  return (
    <div>
      <input
        {...input}
        type="number"
        min={min}
        max={max}
        placeholder={placeholder}
        className={touched && error ? className + " error" : className}
        disabled={disabled}
      />
      {touched && error && (
        <span style={{ color: "red", fontSize: "smaller" }}>{error}</span>
      )}
    </div>
  );
};

export const itemQuantityField = ({
  input,
  label,
  type,
  min,
  max,
  className,
  disabled,
  rows,
  meta: { touched, error },
}) => {
  if (input.name === "featured_sequence")
    return (
      <div>
        <input
          {...input}
          type="number"
          min={min}
          max={max}
          className={touched && error ? className + " error" : className}
          disabled={disabled}
          value={input.value === "" ? min.toString() : input.value}
        />
        {touched && error && (
          <span style={{ color: "red", fontSize: "smaller" }}></span>
        )}
      </div>
    );
  else if (input.name === "min_value")
    return (
      <div>
        <input
          {...input}
          type="number"
          min={min}
          max={max}
          className={touched && error ? className + " error" : className}
          disabled={disabled}
          value={input.value}
        />
        {touched && error && (
          <div
            style={{
              color: "red",
              fontSize: "smaller",
              position: "absolute",
              bottom: "-30px",
              left: "20px",
              width: "100px",
            }}
          >
            {error}
          </div>
        )}
      </div>
    );
  else if (input.name === "max_value")
    return (
      <div>
        <input
          {...input}
          type="number"
          min={min}
          max={max}
          className={touched && error ? className + " error" : className}
          disabled={disabled}
          value={input.value}
        />
        {touched && error && (
          <div
            style={{
              color: "red",
              fontSize: "smaller",
              position: "absolute",
              bottom: "-30px",
              right: "36px",
              width: "100px",
            }}
          >
            {error}
          </div>
        )}
      </div>
    );
  else if (input.name === "CATEGORY_SEQUENCE")
    return (
      //input.name === "category_sequence" ||
      <div>
        <input
          {...input}
          type="number"
          min={min}
          max={max}
          className={touched && error ? className + " error" : className}
          disabled={disabled}
          value={input.value === "" ? min.toString() : input.value}
        />
        {touched && error && (
          <div
            style={{
              color: "red",
              fontSize: "12px",
              position: "absolute",
              bottom: "4px",
              left: "246px",
              width: "auto",
            }}
          >
            {error}
          </div>
        )}
      </div>
    );
  else if (input.name === "category_sequence")
    return (
      <div>
        <input
          {...input}
          type="number"
          min={min}
          max={max}
          className={touched && error ? className + " error" : className}
          disabled={disabled}
          value={input.value === "" ? min.toString() : input.value}
        />
        {touched && error && (
          <div
            style={{
              color: "red",
              fontSize: "12px",
              position: "absolute",
              bottom: "4px",
              left: "246px",
              width: "auto",
            }}
          >
            {error}
          </div>
        )}
      </div>
    );
  else
    return (
      <div>
        <input
          {...input}
          type="number"
          min={min}
          max={max}
          className={touched && error ? className + " error" : className}
          disabled={disabled}
          value={
            input.value === "" ? (min ? min.toString() : "1") : input.value
          }
        />
        {touched && error && (
          <span style={{ color: "red", fontSize: "smaller" }}> {error} </span>
        )}
      </div>
    );
};

export const _dropDown = ({
  input,
  label,
  multi,
  defaultValue,
  type,
  disabled,
  className,
  options,
  defaultSelect,
  meta: { touched, error },
}) => {
  // console.log('input.name,value==',input.name,defaultSelect);
  if (input.name === "category_name" || input.name === "question_entityid")
    return (
      <div>
        <select
          {...input}
          disabled={disabled}
          multi={multi}
          className={touched && error ? className + " error" : className}
        >
          {input.name != "base_currency" &&
          input.name != "billing_method" &&
          input.name != "status" &&
          input.name != "CATEGORY_STATUS" &&
          input.name != "work_item_type" &&
          input.name != "editable_expectedby" &&
          input.name !== "use_external_number" &&
          input.name !== "externally_fulfilled" &&
          input.name !== "delivery_mode" ? (
            <option value="">Select</option>
          ) : (
            ""
          )}
          {renderDropDownOptions(input.name, options)}
        </select>
        {/* {touched && error && <span> {error} </span>} */}
      </div>
    );
  else if (input.name === "question_approval")
    return (
      <div>
        <select
          {...input}
          disabled={disabled}
          multi={multi}
          className={touched && error ? className + " error" : className}
        >
          {renderDropDownOptions(input.name, options)}
        </select>
        {/* {touched && error && <span> {error} </span>} */}
      </div>
    );
  else if (input.name === "reminderNotificationEndDate")
    return (
      <div>
        <select
          {...input}
          disabled={disabled}
          multi={multi}
          className={touched && error ? className + " error" : className}
        >
          <option value="">Select PI Question</option>
          {renderDropDownOptions(input.name, options)}
        </select>
        {/* {touched && error && <span> {error} </span>} */}
      </div>
    );
  else if (input.name === "reminderNotificationInitiation")
    return (
      <div>
        <select
          {...input}
          disabled={disabled}
          multi={multi}
          className={touched && error ? className + " error" : className}
        >
          <option value="">Select Days before End Date</option>
          {renderDropDownOptions(input.name, options)}
        </select>
        {/* {touched && error && <span> {error} </span>} */}
      </div>
    );
  else if (input.name === "reminderNotificationInterval")
    return (
      <div>
        <select
          {...input}
          disabled={disabled}
          multi={multi}
          className={touched && error ? className + " error" : className}
        >
          <option value="">Select Frequency</option>
          {renderDropDownOptions(input.name, options)}
        </select>
        {/* {touched && error && <span> {error} </span>} */}
      </div>
    );
  else if (input.name === "service_criticality")
    return (
      <div>
        <select
          {...input}
          disabled={disabled}
          multi={multi}
          className={touched && error ? className + " error" : className}
        >
          <option value="">Select</option>
          <option value="20">High</option>
          <option value="25">Medium</option>
          <option value="30">Low</option>
        </select>
        {/* {touched && error && <span> {error} </span>} */}
      </div>
    );
  else if (input.name === "calendar_type_id") {
    console.log(defaultValue);
    console.log("defaultValue");
    return (
      <div>
        <select
          {...input}
          disabled={disabled}
          multi={multi}
          className={touched && error ? className + " error" : className}
          value={defaultValue}
        >
          {renderDropDownOptions(input.name, options)}
        </select>
        {/* {touched && error && <span> {error} </span>} */}
      </div>
    );
  } else if (
    input.name == "mod" ||
    input.name == "ciClass" ||
    input.name == "asset" ||
    input.name == "ciAsset"
  ) {
    return (
      <div>
        <select
          {...input}
          disabled={disabled}
          multi={multi}
          className={touched && error ? className + " error" : className}
          value={defaultValue}
        >
          {input.name !== "mod" &&
          input.name !== "ciClass" &&
          input.name !== "asset" &&
          input.name !== "ciAsset" ? (
            <option value="">Select</option>
          ) : (
            ""
          )}
          {renderDropDownOptions(input.name, options)}
        </select>
        {/* {touched && error && <span> {error} </span>} */}
      </div>
    );
  } else if (input.name === "FontFamily") {
    return (
      <div>
        <select
          {...input}
          disabled={disabled}
          multi={multi}
          className={touched && error ? className + " error" : className}
          value={defaultValue}
        >
          {renderDropDownOptions(input.name, options)}
        </select>
        {/* {touched && error && <span> {error} </span>} */}
      </div>
    );
  } else if (input.name === "service_ci_base_id")
    return (
      <div>
        <select
          {...input}
          disabled={disabled}
          multi={multi}
          className={touched && error ? className + " error" : className}
        >
          <option value="">Select</option>
          <option value="10">CI Based</option>
          <option value="5">Catalog Based</option>
        </select>
      </div>
    );
  else
    return (
      <div>
        <select
          {...input}
          disabled={disabled}
          multi={multi}
          className={touched && error ? className + " error" : className}
        >
          {input.name != "base_currency" &&
          input.name != "billing_method" &&
          input.name != "status" &&
          input.name != "CATEGORY_STATUS" &&
          input.name != "work_item_type" &&
          input.name != "editable_expectedby" &&
          input.name !== "use_external_number" &&
          input.name !== "externally_fulfilled" &&
          input.name !== "delivery_mode" &&
          input.name !== "AUTHENTICATION_NAME" &&
          input.name !== "REQUEST_METHOD" &&
          input.name !== "taskType" &&
          input.name !== "onCallStatus" ? (
            <option value="">Select</option>
          ) : (
            ""
          )}
          {renderDropDownOptions(input.name, options)}
        </select>
        {/* {touched && error && <span> {error} </span>} */}
      </div>
    );
};

const renderDropDownOptions = (dropDownFor, options) => {
  if (!Array.isArray(options)) {
    return null; 
  }

  switch (dropDownFor) {
    /* change management drop down starts */
    case "type":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "changeTiming":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "overallTechnicalRisk":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "overallBusinessRisk":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "changeManagementGroup":
      return options.map((option, index) => (
        <option value={option.assignmentGroupId} key={index}>
          {option.assignmentGroupName}
        </option>
      ));
    case "changeManagementIndividual":
      return options.map((option, index) => (
        <option value={option.assignmentMemberId} key={index}>
          {option.assignmentMemberName}
        </option>
      ));
    case "implementationGroup":
      return options.map((option, index) => (
        <option value={option.assignmentGroupId} key={index}>
          {option.assignmentGroupName}
        </option>
      ));
    case "implementationIndividual":
      return options.map((option, index) => (
        <option value={option.assignmentMemberId} key={index}>
          {option.assignmentMemberName}
        </option>
      ));
    /* change management drop down ends */
    /*CategoryBoard company dropdown starts*/
    /*CategoryBoard company dropdown ends*/
    /*Service Details dropdown start*/
    case "portfolioCompany":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "catalogueCompany":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "portfolioGroup":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "catalogueGroup":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "portfolioManager":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "catalogueManager":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    // case "service_criticality":
    //   return options.map((option, index) => (
    //     <option value={option.field1Value} key={index}>
    //       {option.field1Key}
    //     </option>
    //   ));
    case "subcategory_id":
      return options.map((option, index) => (
        <option value={option.id} key={index}>
          {option.categoryName + "/" + option.subCategoryName}
        </option>
      ));
    case "to_subcategory_id":
      return options.map((option, index) => (
        <option value={option.id} key={index}>
          {option.categoryName + "/" + option.subCategoryName}
        </option>
      ));
    case "category_id":
      return options.map((option, index) => (
        <option value={option.id} key={index}>
          {option.categoryName + "/" + option.subCategoryName}
        </option>
      ));
    case "company_id":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "COMPANY_ID":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "to_company_id":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "templateNames":
      return options.map((option, index) => (
        <option value={option.value} key={index}>
          {option.key}
        </option>
      ));
    /* Service Details dropdown ends */

    /* task dropdown starts */
    case "assignmentGroupId":
      return options.map((option, index) => (
        <option value={option.name} key={index}>
          {option.id}
        </option>
      ));
    case "assignedToId":
      return options.map((option, index) => (
        <option value={option.name} key={index}>
          {option.id}
        </option>
      ));
      case "taskTypeId":
        return options.map((option, index) => (
          <option value={option.field1Value} key={index}>
            {option.field1Key}
          </option>
        ));
    /* task dropdown ends */

    /* service order choices dropdown starts */
    case "question_entityid":
      return options.map((option, index) => (
        <option value={option.COMPONENT_ID} key={index}>
          {option.COMPONENT_NAME}
        </option>
      ));

    case "question_parent_id":
      return options.map((option, index) => (
        <option value={option.QUESTION_ID} key={index}>
          {option.QUESTION_TEXT}
        </option>
      ));

    case "question_parent_value":
      return options.map((option, index) => (
        <option value={option.id ? option.id : option.label} key={index}>
          {option.label}
        </option>
      ));

    case "parent_response_id":
      return options.map((option, index) => (
        <option value={option.id ? option.id : option.label} key={index}>
          {option.label}
        </option>
      ));

    case "question_approval":
      return options.map((option, index) => (
        <option value={option.value} key={index}>
          {option.key}
        </option>
      ));
    /* service order choices dropdown ends */

    /* service order choices dropdown starts */
    case "parent_id":
      return options.map((option, index) => (
        <option value={option.COMPONENT_ID} key={index}>
          {option.COMPONENT_NAME}
        </option>
      ));
    /* service order choices dropdown ends */

    /* service Knowledge dropdown starts */
    case "know_parent_id":
      return options.map((option, index) => (
        <option value={option.COMPONENT_ID} key={index}>
          {option.COMPONENT_NAME}
        </option>
      ));

    /* service Knowledge dropdown ends */

    /* service Knowledge dropdown starts */
    case "entitledentity_id":
      return options.map((option, index) => (
        <option value={option.COMPONENT_ID} key={index}>
          {option.COMPONENT_NAME}
        </option>
      ));

    /* service Knowledge dropdown ends */
    /* service Chain Entities dropdown begins */
    case "chain_company_id":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "chain_group_id":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "chain_person_id":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    /* service Chain Entities dropdown ends */
    /*Copy Offering Dropdown starts here*/
    case "from_offering_id":
      return options.map((option, index) => (
        <option value={option.OFFERING_ID} key={index}>
          {option.OFFERING_NAME?.length > 50 ? (option.OFFERING_NAME.slice(0,50) + "...") : option.OFFERING_NAME}
        </option>
      ));
    /*Copy Offering Dropdown ends here*/
    /* Entitlement dropdown begins */
    case "entitled_to_id":
      return options.map((option, index) => {
        if (option.field1Key !== "") {
          return (
            <option value={option.field1Value} key={index}>
              {option.field1Key}
            </option>
          );
        }
      });

    /* Entitlement Entities dropdown begins */

    /* Approvals dropdown begins */
    case "approverType":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "moduleId":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "module":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "level":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "approverGroup":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "approverIndividual":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    /* Approvals dropdown Ends */

    /* Service Level Targets dropdown begins */
    case "goalTypeId":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    /* Service Level Targets dropdown Ends */
    /* Fulfillment Plan dropdown Start */
    case "fplan_entityid":
      return options.map((option, index) => (
        <option value={option.COMPONENT_ID} key={index}>
          {option.COMPONENT_NAME}
        </option>
      ));
    case "companyId":
      return options.map((option, index) => (
        <option value={option.value} key={index}>
          {option.text}
        </option>
      ));
    /* Fulfillment Plan dropdown Ends */

    /* Fulfillment Plan component based task dropdown   */
    case "componentId":
      return options.map((option, index) => (
        <option value={option.COMPONENT_ID} key={index}>
          {option.COMPONENT_NAME}
        </option>
      ));

    /* Fulfillment Plan component based task dropdown Ends  */

    /* Questions Category Dropdown */
    case "category_name":
      return options.map((option, index) => (
        <option value={option.CATEGORY_ID} key={index}>
          {option.CATEGORY_NAME}
        </option>
      ));
    /* Questions Category Dropdown Ends */

    /* Typehead Data Source Types */
    case "datasource_type":
      return options.map((option, index) => {
        if (option.FIELD1_NAME !== null && option.ID !== null) {
          return (
            <option value={option.ID} key={index}>
              {option.FIELD1_NAME}
            </option>
          );
        }
      });
    /* Questions Category Dropdown Ends */

    /* SPCM Form Complexity Dropdown */
    case "complexity":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    /* Questions Category Dropdown Ends */
    case "apiHost":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "attributeValueType":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "methodType":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    /* SPCM Form Service Types */
    case "offering_type":
      return options.map((option, index) => (
        <option value={option.SERVICE_TYPE_NAME} key={index}>
          {option.SERVICE_TYPE_NAME}
        </option>
      ));
    /* Questions Category Dropdown Ends */
    case "offering_id":
      return options.map((option, index) => (
        <option value={option.OFFERING_ID} key={index} title={option.OFFERING_NAME}>
          {/* {option.OFFERING_NAME} */}
          {option.OFFERING_NAME.slice(0, 25)}{option.OFFERING_NAME.length > 25 ? "..." : ''}
        </option>
      ));

    case "reminderNotificationEndDate":
      return options.map((option, index) => (
        <option value={option.QUESTION_ID} key={index}>
          {option.QUESTION_TEXT}
        </option>
      ));

    case "reminderNotificationInitiation":
      return options.map((option, index) => (
        <option value={option.value} key={index}>
          {option.text}
        </option>
      ));

    case "reminderNotificationInterval":
      return options.map((option, index) => (
        <option value={option.value} key={index}>
          {option.text}
        </option>
      ));

    case "urgency":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));

    case "impact":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));

    case "ciClass":
      return options.map((option, index) => (
        <option value={option.CLASS_ID} key={index}>
          {option.CLASS_NAME}
        </option>
      ));
    case "ciAsset":
      return options.map((option, index) => (
        <option value={option.CI_ID} key={index}>
          {option.CI_NAME}
        </option>
      ));
    case "taskType":
      return options.map((option, index) => (
        <option value={option.field1Key} key={index}>
          {option.field1Key}
        </option>
      ));
    case "chain_module":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "assignment_for":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "businessFunctionId":
      return options.map((option, index) => (
        <option value={option.id} key={index}>
          {option.businessFunctionName}
        </option>
      ));
    case "tagType":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "tagDataValidation":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "tagDataSource":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "tagEnabled":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "tagMandatory":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "compositeTag":
      return options.map((option, index) => (
        <option value={option.tagId} key={index}>
          {option.tagLabel}
        </option>
      ));
    case "parentTag":
      return options.map((option, index) => (
        <option value={option.tagId} key={index}>
          {option.tagLabel}
        </option>
      ));
    case "autoAction":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "ccStatus":
      return options.map((option, index) => (
        <option value={option.id} key={index}>
          {option.label}
        </option>
      ));
    case "scheduleType":
      return options.map((option, index) => (
        <option value={option.field1Key} key={index}>
          {option.field1Key}
        </option>
      ));
    case "onCallStatus":
      return options.map((option, index) => (
        <option value={option.field1Key} key={index}>
          {option.field1Key}
        </option>
      ));
    case "scheduleRepeatType":
      return options.map((option, index) => (
        <option value={option.field1Key} key={index}>
          {option.field1Key}
        </option>
      ));
    case "shift":
      return options.map((option, index) => (
        <option value={option.shiftId} key={index}>
          {option.shiftName}
        </option>
      ));
    case "user_shift":
      return options.map((option, index) => (
        <option value={option.shiftId} key={index}>
          {option.shiftName}
        </option>
      ));
    case "swap_shift":
      return options.map((option, index) => (
        <option value={option.shiftId} key={index}>
          {option.shiftName}
        </option>
      ));
    case "groupTypeLabel":
      return options.map((option, index) => (
        <option value={option.id} key={index}>
          {option.label}
        </option>
      ));
    case "FontFamily":
      return options.map((option, index) => (
        <option value={option.value} key={index}>
          {option.label}
        </option>
      ));
    case "data_source_identifier":
      return options.map((option, index) => (
        <option value={option.id} key={index}>
          {option.label}
        </option>
      ));
    default:
      return options.map((option, index) => {
        return getFixedLengthOption(option, index);
      });
  }
};

/*
 * If the text is too long, then we can show substring with max length 100 with tooltip showing the long text.
 */
const getFixedLengthOption = (option, index) => {
  if (option && option.text && option.text.length > 100) {
    return (
      <option value={option.value} key={index} title={option.text}>
        {`${option.text.substring(0, 100)}...`}
      </option>
    );
  } else {
    return (
      <option value={option.value} key={index}>
        {option.text}
      </option>
    );
  }
};
export const _inputField = ({
  input,
  disabled,
  defaultValue,
  label,
  type,
  className,
  placeholder,
  max,
  readOnly,
  maxLength,
  minLength,
  meta: { touched, error },
  title,
}) => {
  if (
    input.name == "offering_id" ||
    input.name == "base_language" ||
    input.name == "from_editOffering_id"
  ) {
    // For Read only input field with default value
    return (
      <div>
        <input
          {...input}
          placeholder={placeholder}
          disabled={disabled}
          type={type}
          max={max}
          maxLength={maxLength}
          minLength={minLength}
          value={defaultValue}
          className={touched && error ? className + " error" : className}
          readOnly={readOnly}
          title={title}
        />
        {input.name == "max_limit" ? (
          <div style={{ fontSize: "13px", color: "red", paddingTop: "2px" }}>
            {touched && error && <span>{error}</span>}
          </div>
        ) : null}
      </div>
    );
  } else {
    return (
      <div>
        <input
          {...input}
          placeholder={placeholder}
          disabled={disabled}
          type={type}
          max={max}
          maxLength={maxLength}
          className={touched && error ? className + " error" : className}
          readOnly={readOnly}
          title={title}
        />
        {input.name == "max_limit" ||
        input.name == "to_offering_name" ||
        input.name == "offering_name" ? (
          <div style={{ fontSize: "13px", color: "red", paddingTop: "2px" }}>
            {touched && error && <span> {error} </span>}
          </div>
        ) : null}
      </div>
    );
  }
};
export const _passwordField = ({
  input,
  disabled,
  className,
  placeholder,
  max,
  readOnly,
  maxLength,
  minLength,
  meta: { touched, error },
}) => {
  return (
    <div>
      <input
        {...input}
        placeholder={placeholder}
        disabled={disabled}
        type="password"
        max={max}
        minLength={minLength}
        maxLength={maxLength}
        className={touched && error ? className + " error" : className}
        readOnly={readOnly}
      />
    </div>
  );
};

export const _dateField = ({
  input,
  placeholder,
  defaultValue,
  className,
  handleTimeChange,
  formType,
  disabledValue,
  meta: { touched, error },
}) => {
  // let jwtdf = userDetails.date_format.slice(0, 10).toUpperCase();
  switch (formType) {
    case "edit":
      defaultValue = defaultValue.substring(0, 19);
      return (
        <div className="dateTimeSdiv">
          <DateField
            className={touched && error ? className + " error" : className}
            expandOnFocus={false}
            dateFormat="YYYY-MM-DD HH:mm:ss"
            updateOnDateClick
            forceValidDate
            defaultValue={defaultValue}
            minDate={moment()}
            disabled={disabledValue}
          >
            <DatePicker
              {...input}
              selected={input.value ? moment(input.value) : null}
              navigation
              locale="en"
              forceValidDate
              highlightWeekends
              highlightToday
              weekNumbers={false}
              weekStartDay={1}
              todayButton={false}
            ></DatePicker>
          </DateField>
          {touched && error && <span> {error} </span>}
        </div>
      );
    case "create":
      return (
        <div className="dateTimeSdiv">
          <DateField
            className={touched && error ? className + " error" : className}
            expandOnFocus={false}
            dateFormat="YYYY-MM-DD HH:mm:ss"
            updateOnDateClick
            forceValidDate
            defaultValue={moment()}
            minDate={moment()}
            disabled={disabledValue}
          >
            <DatePicker
              {...input}
              selected={input.value ? moment(input.value) : null}
              navigation
              locale="en"
              forceValidDate={false}
              highlightWeekends
              highlightToday
              weekNumbers={false}
              weekStartDay={1}
              todayButton={false}
            ></DatePicker>
          </DateField>
          {touched && error && <span> {error} </span>}
        </div>
      );
    case "timePicker":
      return (
        <div className="dateTimeSdiv">
          <TimePicker
            defaultValue={defaultValue}
            format={"HH:mm"}
            showSecond={false}
            onChange={(time) => {
              handleTimeChange(time, input.name);
            }}
            disabled={disabledValue}
          />
          {error ? <span>{error}</span> : null}
        </div>
      );
  }
};

export const _radio = ({
  input,
  label,
  type,
  disabled,
  className,
  value,
  checked,
  placeholder,
  readOnly,
  meta: { touched, error },
}) => {
  return (
    <label className="radio-inline">
      <input
        {...input}
        type={type}
        disabled={disabled}
        checked={checked}
        value={input.value}
      />
      {input.name == "complexity_editable" ||
      input.name == "recipient" ||
      input.name == "entitlement_entity"
        ? label
        : input.value}
    </label>
  );
};

export const _checkbox = ({
  input,
  label,
  disabled,
  className,
  value,
  checked,
  placeholder,
  readOnly,
  meta: { touched, error },
}) => {
  if (
    input.name &&
    (input.name === "fullDayLeave" ||
      input.name === "is24by7" ||
      input.name === "onlyShowOverridden")
  ) {
    return (
      <label>
        <input
          {...input}
          type="checkbox"
          disabled={disabled}
          checked={checked}
          readOnly={readOnly}
        />{" "}
        <span>{label}</span>
      </label>
    );
  } else {
    return (
      <label>
        <input
          {...input}
          type="checkbox"
          disabled={disabled}
          checked={checked}
          readOnly={readOnly}
        />{" "}
        <span></span> <p className="custmChkLbl">{label}</p>
      </label>
    );
  }
};

export const field_file = ({
  input,
  type,
  className,
  meta: { touched, error },
}) => {
  delete input.value;
  //console.log(meta);
  //console.log(error)
  return (
    <div>
      <label htmlFor={input.name}>
        <input
          {...input}
          type={type}
          className={touched && error ? className + " error" : className}
        />
      </label>
    </div>
  );
};

export const _renderQuill = ({ input, className, maxlength }) => {
  var toolbarOptions = [
    //[{'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"], // toggled buttons
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    //[{'header': 1 }, {'header': 2 }],               // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    // [{'direction': 'rtl' }],                         // text direction

    ["link"], // Link
    [{ align: [] }],
    ["clean"], // remove formatting button
  ];

  const removeTags = (str) => {
    if (str === null || str === "") return false;
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, "");
  };

  const handleChange = (e, editor) => {
    let str = removeTags(e.target.innerHTML);
    console.log(str.length);
    console.log(e);
    console.log(e.keyCode);
    if (str.length > maxlength) {
      console.log("exceeded");
      if (e.keyCode !== 8) {
        e.preventDefault();
      }
    }
  };

  return (
    // <ReactQuill
    //   {...input}
    //   modules={{
    //     toolbar: toolbarOptions,
    //   }}
    //   /*  formats={[
    //        'header',
    //        'bold', 'italic', 'underline', 'strike', 'blockquote',
    //        'list', 'bullet', 'indent',
    //        'link', 'image',
    //        'color'
    //    ]} */

    //   /* 		onChange={(newValue, delta, source) => {
    //   if (source === 'user') {
    //   input.onChange(newValue);
    //   }
    //   }} */
    //   onBlur={(range, source, quill) => {
    //     input.onBlur(quill.getHTML());
    //   }}
    //   onKeyPress={(e) => {
    //     handleChange(e);
    //   }}
    // >
    //   <div className={className}></div>
    // </ReactQuill>
    <></>
  );
};

export const _multiSelect = ({
  input,
  data,
  valueField,
  textField,
  className,
  meta: { touched, error },
}) => (
  <Multiselect
    {...input}
    onBlur={() => input.onBlur()}
    value={input.value || []} // requires value to be an array
    data={data}
    valueField={valueField}
    textField={textField}
    className={touched && error ? className + " error" : className}
  />
);

// For dymanically generated dropdown field like in preferences - additional integration data

export const _dynamicNamedropDown = ({
  input,
  label,
  multi,
  nameforOptionsList,
  type,
  disabled,
  className,
  options,
  defaultSelect,
  meta: { touched, error },
}) => {
  return (
    <div>
      <select
        {...input}
        disabled={disabled}
        multi={multi}
        className={touched && error ? className + " error" : className}
      >
        <option value="">Select</option>
        {renderDynamicNameDropDownOptions(nameforOptionsList, options)}
      </select>
      {/* {touched && error && <span> {error} </span>} */}
    </div>
  );
};

const renderDynamicNameDropDownOptions = (dropDownFor, options) => {
  switch (dropDownFor) {
    case "integrationDataKeywords":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));

    /* Approver Criteria Dropdown */
    case "key":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));

    case "operator":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));

    case "value":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));

    default:
      return options.map((option, index) => (
        <option value={option.value} key={index}>
          {option.text}
        </option>
      ));
  }
};

export const _ckeditor = ({
  input,
  className,
  meta: { touched, error },
  richTextConfig,
}) => {
  let class_render;
  if (className === "readOnlyClass reactQuillEditor-area") class_render = true;
  else class_render = false;
  

  const config = {
    toolbar:[
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline"],
        ['link'],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        ],
    clipboard: {
      matchVisual: false,
    },
  };

  const configNew = {
    toolbar:[
          [{ header: '1' }, { header: '2' }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['bold', 'italic', 'underline', 'strike'],
          ['link'],
          [{ align: [] }],
          ['clean'],
        ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "list",
    "bullet",
    "indent",
    "align",
    "bold",
    "italic",
    "underline",
    "script",
    "color",
    "background",
    "link",
  ];

  const handleChange = (value) => {
    input.onChange(value);
  };

  return (
    <div>
      <ReactQuill
        value={input.value}
        onChange={handleChange}
        modules={richTextConfig === 'minRichTextEditor' ? configNew : config}
        formats={formats}
        readOnly={class_render}
        theme="snow"
        className="ck-quil"
        />
      <div style={{ fontSize: '13px', color: 'red', paddingTop: '2px' }}>
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  );
};

export const _ckeditorssss = ({
  input,
  className,
  meta: { touched, error },
  richTextConfig,
}) => {
  let class_render;
  if (className === "readOnlyClass reactQuillEditor-area") class_render = true;
  else class_render = false;
  let config = {
    removePlugins: "elementspath",
    resize_enabled: false,
    readOnly: class_render,
    removeButtons:
      "Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Indent,Language,Anchor,Iframe,PageBreak,SpecialChar,Flash,HorizontalRule,Smiley,Image,ShowBlocks,About,Scayt,Templates,Print,Source,Save,Replace,Copy,NewPage,Preview",
  };
  let configNew = {
    removePlugins: "elementspath",
    resize_enabled: false,
    readOnly: class_render,
    toolbarGroups: [
      { name: "document", groups: ["mode", "document", "doctools"] },
      { name: "clipboard", groups: ["clipboard", "undo"] },
      {
        name: "editing",
        groups: ["find", "selection", "spellchecker", "editing"],
      },
      { name: "forms", groups: ["forms"] },
      { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
      {
        name: "paragraph",
        groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"],
      },
      { name: "links", groups: ["links", "unlink", "fontSize"] },
      { name: "insert", groups: ["insert"] },
      { name: "styles", groups: ["styles", "colors"] },
    ],
    removeButtons:
      "Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Indent,Language,Anchor,Iframe,PageBreak,SpecialChar,Flash,HorizontalRule,Smiley,Image,ShowBlocks,About,Scayt,Templates,Print,Source,Save,Replace,NewPage,Preview,PasteFromWord,PasteText,Find,SelectAll,Strike,Subscript,Superscript,CopyFormatting,Outdent,Blockquote,CreateDiv,BidiLtr,BidiRtl,Styles,Maximize,Table,Format,Font,BGColor",
  };

  let onChange = (event) => {
    input.onChange(event.editor.getData());
  };
  return (
    <div>
      <CKEditor
        content={input.value}
        events={{
          change: onChange,
        }}
        config={richTextConfig == "minRichTextEditor" ? configNew : config}
        scriptUrl={"https://cdn.ckeditor.com/4.14.0/full-all/ckeditor.js"}
      ></CKEditor>
      <div style={{ fontSize: "13px", color: "red", paddingTop: "2px" }}>
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  );
};

export const TypeaheadExample = ({
  options,
  onSelection,
  selectedOptions,
  setErrorColor,
  editEnable,
  onInputChange,
  errorClass = "",
  multiple = true,
}) => {
  let flag = false,
    insideFlag = false,
    newOptionList = [],
    j = 0,
    i = 0;
  if (selectedOptions.length > 0 && editEnable) {
    flag = true;
    for (i = 0; i < options.length; i++) {
      insideFlag = false;
      for (j = 0; j < selectedOptions.length; j++) {
        if (options[i].label === selectedOptions[j].label) {
          insideFlag = true;
          break;
        }
      }
      if (!insideFlag) {
        newOptionList.push(options[i]);
      }
    }
  }
  return (
    <div className={"dvTypehd " + errorClass}>
      <Typeahead
        multiple={multiple}
        onChange={onSelection}
        options={flag ? newOptionList : options}
        selected={selectedOptions}
        placeholder="Please choose..."
        onBlur={setErrorColor}
        onInputChange={onInputChange}
        renderMenuItemChildren={(option, props) => {
          let label;
          let title;
          label =
            option.label.length > 30
              ? option.label.slice(0, 30) + "..."
              : option.label;
          title = option.label.length > 30 ? option.label : "";
          if (option?.companyName) {
            return (
              <div>
                <span
                  className="typHdmainHead"
                  title={title}
                  style={{ display: "block" }}
                >
                  {label}
                </span>
                <span
                  className="typHdmainSubHead"
                  style={{
                    display: "block",
                    "margin-top": "6px",
                    color: "#777",
                  }}
                >
                  {option?.companyName || ""}
                </span>
              </div>
            );
          }
          return (
            <div>
              <span className="typHdmainHead" title={title}>
                {label}
              </span>
            </div>
          );
        }}
      ></Typeahead>
    </div>
  );
};

export const TypeaheadExampleSingleSelect = ({
  options,
  onSelection,
  selectedOptions,
  setErrorColor,
  disabled = false,
  onInputChange,
  inputValue,
  onCrossClick,
  id,
  typedValue = 0,
  errorClass,
  labelKey = null,
  placeholder = "",
  isLoading = false
}) => {
  const tr = useSelector((state) => state.spcmReducer.tr);

  // console.log('options 1 -', options);
  let props = labelKey === null ? {} : { labelKey };

  let _typeahead;
  let crossClick = () => {
    _typeahead.clear();
    onCrossClick();
  };

  return (
    <div className={"dvTypehd " + errorClass}>
      {!disabled &&
      ((selectedOptions && selectedOptions.length > 0) ||
        (typedValue && typedValue.length > 0) && !isLoading) ? (
        <span
          title={tr["Clear"]}
          role="img"
          aria-label="Clear"
          className="css-hakgx8 icn"
          onClick={crossClick}
        >
          <svg width="18" height="18" viewBox="0 0 24 24" role="presentation">
            <path
              d="M12 10.586L6.707 5.293a1 1 0 00-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 001.414 1.414L12 13.414l5.293 5.293a1 1 0 001.414-1.414L13.414 12l5.293-5.293a1 1 0 10-1.414-1.414L12 10.586z"
              fill="gray"
            ></path>
          </svg>
        </span>
      ) : null}
      <Typeahead
        //  ref={(ref)=>{if(ref!==null && (selectedOptions.length===0 || typedValue.length===0))ref.state.text=''}}
        ref={(ref) => {
          _typeahead = ref;
          if (
            _typeahead !== null &&
            selectedOptions?.length === 0 &&
            typedValue?.length === 0
          )
            _typeahead.clear();
        }}
        {...props}
        id={id}
        onChange={onSelection}
        onInputChange={onInputChange}
        options={options}
        selected={selectedOptions}
        placeholder={placeholder ? placeholder : tr["Please choose..."]}
        onBlur={setErrorColor}
        disabled={disabled}
        isLoading={isLoading}
        renderMenuItemChildren={(option, props) => {
          let label;
          let title;
          if (labelKey !== null) {
            label =
              option[labelKey].length > 30
                ? option[labelKey].slice(0, 30) + "..."
                : option[labelKey];
            title = option[labelKey].length > 30 ? option[labelKey] : "";
          } else {
            label =
              option.label.length > 30
                ? option.label.slice(0, 30) + "..."
                : option.label;
            title = option.label?.length > 30 ? option.label : "";
          }
          if (option?.supportCompanyName) {
            return (
              <div>
                <span
                  className="typHdmainHead"
                  title={title}
                  style={{ display: "block" }}
                >
                  {label}
                </span>
                <span
                  className="typHdmainSubHead"
                  style={{
                    display: "block",
                    "margin-top": "6px",
                    color: "#777",
                  }}
                >
                  {option?.supportCompanyName || ""}
                </span>
              </div>
            );
          }
          return (
            <div>
              <span className="typHdmainHead" title={title}>
                {label}
              </span>
            </div>
          );
        }}
      />
    </div>
  );
};

export const TypeaheadExampleSingle = ({
  options,
  onSelection,
  selectedOptions,
  setErrorColor,
  disabled = false,
  onInputChange,
  onCrossClick,
  typedValue = 0,
  errorColor = "",
}) => {
  // console.log('options - ', options);

  let _typeahead;
  let crossClick = () => {
    _typeahead.clear();
    onCrossClick();
  };

  return (
    <div className={"dvTypehd " + errorColor}>
      {(selectedOptions && selectedOptions.length > 0) ||
      (typedValue && typedValue.length > 0) ? (
        <span
          title="clear"
          role="img"
          aria-label="Clear"
          className="css-hakgx8 icn"
          onClick={crossClick}
        >
          <svg width="18" height="18" viewBox="0 0 24 24" role="presentation">
            <path
              d="M12 10.586L6.707 5.293a1 1 0 00-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 001.414 1.414L12 13.414l5.293 5.293a1 1 0 001.414-1.414L13.414 12l5.293-5.293a1 1 0 10-1.414-1.414L12 10.586z"
              fill="gray"
            ></path>
          </svg>
        </span>
      ) : null}
      <Typeahead
        ref={(ref) => {
          _typeahead = ref;
          if (
            _typeahead !== null &&
            selectedOptions.length === 0 &&
            typedValue.length === 0
          )
            _typeahead.clear();
        }}
        onChange={onSelection}
        options={options}
        selected={selectedOptions}
        placeholder="Please choose..."
        onBlur={setErrorColor}
        disabled={disabled}
        onInputChange={onInputChange}
        renderMenuItemChildren={(option, props) => {
          let label;
          let title;
          label =
            option.label.length > 30
              ? option.label.slice(0, 30) + "..."
              : option.label;
          title = option.label.length > 30 ? option.label : "";
          if (option?.supportCompanyName) {
            return (
              <div>
                <span
                  className="typHdmainHead"
                  title={title}
                  style={{ display: "block" }}
                >
                  {label}
                </span>
                <span
                  className="typHdmainSubHead"
                  style={{
                    display: "block",
                    "margin-top": "6px",
                    color: "#777",
                  }}
                >
                  {option?.supportCompanyName || ""}
                </span>
              </div>
            );
          }
          return (
            <div>
              <span className="typHdmainHead" title={title}>
                {label}
              </span>
            </div>
          );
        }}
      />
    </div>
  );
};
export const SlaTypeahead = ({
  options,
  onSelection,
  selectedOptions,
  setErrorColor,
  disabled = false,
}) => {
  return (
    <Typeahead
      onChange={onSelection}
      options={options}
      selected={selectedOptions}
      placeholder="Please choose..."
      onBlur={setErrorColor}
      disabled={disabled}
      clearButton
    />
  );
};
export const TypeaheadandDropdown = ({
  options,
  onSelection,
  selectedOptions,
  setErrorColor,
  diableStatus,
  meta: { touched, error },
  onCrossClick,
  onInputChange,
  typedValue = "",
  multiple = false,
  errorClass = "",
  placeholder = "Please choose...",
  labelKey = null,
}) => {
  let props = labelKey === null ? {} : { labelKey };
  let _typeahead;
  let crossClick = () => {
    onCrossClick();
    // _typeahead.getInstance().clear();
    _typeahead.clear();
  };
  return (
    <div
      className={
        (touched && error) || errorClass !== "" ? "error dvTypehd" : multiple == false ? "dvTypehd":"notifydvTypehd"
      }
    >
      {(!diableStatus && selectedOptions && selectedOptions.length > 0) ||
      (typedValue && typedValue.length > 0) ? (
        <span
          title="clear"
          role="img"
          aria-label="Clear"
          className="css-hakgx8 icn"
          style={{ cursor: "pointer" }}
          onClick={crossClick}
        >
          <svg width="18" height="18" viewBox="0 0 24 24" role="presentation">
            <path
              d="M12 10.586L6.707 5.293a1 1 0 00-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 001.414 1.414L12 13.414l5.293 5.293a1 1 0 001.414-1.414L13.414 12l5.293-5.293a1 1 0 10-1.414-1.414L12 10.586z"
              fill="gray"
            ></path>
          </svg>
        </span>
      ) : (
        ""
      )}
      <Typeahead
        {...props}
        ref={(ref) => {
          _typeahead = ref;
        }} /*;if(_typeahead!==null && selectedOptions.length===0 && typedValue.length===0)_typeahead.getInstance().clear();*/ //}} */
        onChange={onSelection}
        options={options}
        selected={selectedOptions}
        placeholder={placeholder}
        onBlur={setErrorColor}
        disabled={diableStatus}
        onInputChange={onInputChange}
        multiple={multiple}
        renderMenuItemChildren={(option, props) => {
          let label;
          let title;
          if (labelKey !== null) {
            label =
              option[labelKey].length > 30
                ? option[labelKey].slice(0, 30) + "..."
                : option[labelKey];
            title = option[labelKey].length > 30 ? option[labelKey] : "";
          } else {
            label =
              option.label.length > 30
                ? option.label.slice(0, 30) + "..."
                : option.label;
            title = option.label.length > 30 ? option.label : "";
          }
          if (option?.companyName) {
            return (
              <div>
                <span
                  className="typHdmainHead"
                  title={title}
                  style={{ display: "block" }}
                >
                  {label}
                </span>
                <span
                  className="typHdmainSubHead"
                  style={{
                    display: "block",
                    "margin-top": "6px",
                    color: "#777",
                  }}
                >
                  {option?.companyName || ""}
                </span>
              </div>
            );
          }
          if (option?.CILabel) {
            return (
              <div>
                <span
                  className="typHdmainHead"
                  title={label}
                  style={{ display: "block" }}
                >
                  {label}
                </span>
                <div>Serial Number - {option?.serialLabel ?? "NA"}</div>
                <span
                  className="typHdmainSubHead"
                  style={{
                    display: "block",
                    "margin-top": "6px",
                    color: "#777",
                  }}
                >
                  {option?.CILabel}
                </span>
              </div>
            );
          }
          return (
            <div>
              <span className="typHdmainHead" title={title}>
                {label}
              </span>
              <span></span>
            </div>
          );
        }}
      />
    </div>
  );
};

export const ColorInputField = (props) => {
  const {
    input: { value, onChange },
    defaultValue,
    disabled,
  } = props;

  if (disabled) {
    return (
      <InputGroup
        className="controlInputdv"
        title={value ? value : defaultValue}
      >
        <InputGroup.Text>
          <div
            className="cls"
            style={{ backgroundColor: value ? value : defaultValue }}
          ></div>
        </InputGroup.Text>
        <HexColorInput
          className="form-control"
          color={value ? value : defaultValue}
          onChange={onChange}
          disabled={disabled}
        />
      </InputGroup>
    );
  } else {
    return (
      <OverlayTrigger
        container={this}
        trigger="click"
        disabled={disabled}
        rootClose
        placement="bottom"
        overlay={
          <Popover className="controlInputPop">
            <HexColorPicker
              color={value ? value : defaultValue}
              onChange={disabled ? "" : onChange}
              disabled
            />
          </Popover>
        }
      >
        <InputGroup
          className="controlInputdv"
          title={value ? value : defaultValue}
        >
          <InputGroup.Text>
            <div
              className="cls"
              style={{ backgroundColor: value ? value : defaultValue }}
            ></div>
          </InputGroup.Text>
          <HexColorInput
            className="form-control"
            color={value ? value : defaultValue}
            onChange={onChange}
            disabled={disabled}
          />
        </InputGroup>
      </OverlayTrigger>
    );
  }
};
