
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});
import { GLOBAL }  from '../../components/Globals';
import constanturl from '../../../src/utils/constants.js';

export const getRelationshipTypeData = (classId) => {
	return (dispatch) => {
		api.get('/api/config_items/relationshipTypes/' + classId)
			.then((response) => {
				// console.log(response.data);
				dispatch({
					type: 'RELATIONSHIP_TYPES',
					relationshipTypes: response.data
				})
			})
	}
};

export const getClassNameData = (classId,relationshipid) => {
	return (dispatch) => {
        if(relationshipid == '') {
            dispatch({
                type:'CLASS_NAMES',
                classNames:[]
              })
        }
        else {
            api.get('/api/config_items/relationshipTypes/' + classId, { headers: { "relationshipid": relationshipid } })
			.then((response) => {
				// console.log(response.data);
				dispatch({
					type: 'CLASS_NAMES',
					classNames: response.data
				})
			})
        }
	}
};

export const getAssetCIByClassName = (classArray,ciName, companyId) => {
  let str = {};
	str.classArray = classArray;
	str.ciName = ciName;
	str.companyId = companyId;
	str = JSON.stringify(str);
	return (dispatch) => {
		api.get('/api/config_items/ciByClassId', { headers: { 'queryrel': str } })
        .then((response) => {
            // console.log(response.data);
            dispatch({
                type: 'ASSET_CI_LIST_BY_CLASS_NAME',
                assetCIListByClassName: response.data
            })
        })
	}
};
export const getAssessDisclaimiers = (CI_ID) => { //alert('getAssessDisclaimiers'+CI_ID);
	if(typeof CI_ID !== 'undefined' && CI_ID!= null ){
		//alert(CI_ID);
		return (dispatch) => {
			api.get(`/api/getAssesstsDisclaimers/${CI_ID}`)
			.then((response) => { //alert(JSON.stringify(response.data));
				// console.log(response.data);
				dispatch({
					type: 'ASSET_CI_DISCLAIMER',
					assetCIDisclaimer: response.data
				});
			});
		};	
	}
};
export const getCiForTypeahead = (classArray,ciName, companyId) => {
  let str = {};
	str.classArray = classArray;
	str.companyId=companyId;
	str.ciName = ciName;
	str = JSON.stringify(str);
	return (dispatch) => {
        api.get('/api/config_items/ciByClassId', { headers: { 'queryrel': str } })
        .then((response) => {
            // console.log(response.data);
            dispatch({
                type: 'CI_LIST_FOR_TYPEAHEAD',
                assetCIListByClassName: response.data
            })
        })
	}
};
