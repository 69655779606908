
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from "axios";
import { GLOBAL } from '_Globals';

const api = axios.create({
    headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

export function loadTagCompany(userId='') {
    return (dispatch) => {
        let str = "";
        if (userId != "") {
            str += "?userId=" + userId;
        }
        api.get(GLOBAL.loadTagCompanyUrl + str)
            .then((response) => {
                if (!response) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => {
                if (response.status == 204) {
                    dispatch(loadTagCompanySuccess([]));
                } else {
                    dispatch(loadTagCompanySuccess(response.data));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
}

export function loadTagCompanySuccess(payload) {
    return {
        type: 'LOAD_TAG_COMPANY_SUCCESS',
        payload
    };
}

export function loadBusinessFunction(companyId) {
    let strObj = {};
    strObj.companyId = companyId;
    strObj = JSON.stringify(strObj);
    return (dispatch) => {
        api.get(GLOBAL.loadBusinessFunctionUrl, { headers: { query: strObj } })
            .then((response) => {
                if (!response) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => {
                if (response.status == 204) {
                    dispatch(loadBusinessFunctionSuccess([]));
                } else {
                    dispatch(loadBusinessFunctionSuccess(response.data));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
}

export function loadBusinessFunctionSuccess(payload) {
    return {
        type: 'LOAD_BUSINESS_FUNCTION_SUCCESS',
        payload
    };
}

export function emptyBusinessFunction() {
    return (dispatch) => {
        dispatch({ type: 'EMPTY_BUSINESS_FUNCTION_SUCCESS' });
    };
}

export function loadSXMenulistData(moduleName, menuName) {
    let strObj = {};
    strObj.moduleName = moduleName;
    strObj.menuName = menuName;
    strObj = JSON.stringify(strObj);
    return (dispatch) => {
        api.get(GLOBAL.sxMenulistUrl, { headers: { query: strObj } })
            .then((response) => {
                if (!response) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => {
                if (menuName === 'Module') {
                    if (response.status == 204) {
                        dispatch(loadTagModuleSuccess([]));
                    } else {
                        dispatch(loadTagModuleSuccess(response.data));
                    }
                }
                else if (menuName === 'Type') {
                    if (response.status == 204) {
                        dispatch(loadTagTypeSuccess([]));
                    } else {
                        dispatch(loadTagTypeSuccess(response.data));
                    }
                }
                else if (menuName === 'DataType') {
                    if (response.status == 204) {
                        dispatch(loadTagDataValidationSuccess([]));
                    } else {
                        dispatch(loadTagDataValidationSuccess(response.data));
                    }
                }
                else if (menuName === 'DataSource') {
                    if (response.status == 204) {
                        dispatch(loadTagDataSourceSuccess([]));
                    } else {
                        dispatch(loadTagDataSourceSuccess(response.data));
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
}

export function loadTagModuleSuccess(payload) {
    return {
        type: 'LOAD_TAG_MODULE_SUCCESS',
        payload
    };
}

export function loadTagTypeSuccess(payload) {
    return {
        type: 'LOAD_TAG_TYPE_SUCCESS',
        payload
    };
}

export function loadTagDataValidationSuccess(payload) {
    return {
        type: 'LOAD_TAG_DATA_VALIDATION_SUCCESS',
        payload
    };
}

export function loadTagDataSourceSuccess(payload) {
    return {
        type: 'LOAD_TAG_DATA_SOURCE_SUCCESS',
        payload
    };
}

export function loadAllCompositeTags(companyId, businessFunction, tagModule, tagType) {
    let strObj = {};
    strObj.companyId = companyId;
    strObj.businessFunction = businessFunction;
    strObj.tagModule = tagModule;
    strObj.tagType = tagType;
    strObj = JSON.stringify(strObj);
    return (dispatch) => {
        api.get(GLOBAL.loadAllCompositeTagsUrl, { headers: { query: strObj } })
            .then((response) => {
                if (!response) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => {
                if (response.status == 204) {
                    dispatch(loadAllCompositeTagsSuccess([]));
                } else {
                    dispatch(loadAllCompositeTagsSuccess(response.data));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
}

export function loadAllCompositeTagsSuccess(payload) {
    return {
        type: 'LOAD_ALL_COMPOSITE_TAGS_SUCCESS',
        payload
    };
}

export function loadMemberTagsForCompositeTag(tagId) {
    return (dispatch) => {
        if(tagId == '') {
            dispatch(loadMemberTagsForCompositeTagSuccess([]));
        }
        else {
            dispatch({ type: 'LOAD_MEMBER_DEPENDENCY_LOADER_IN_PROGRESS' });
            api.get(GLOBAL.loadMemberTagsForCompositeTagUrl + '/' + tagId)
            .then((response) => {
                if (!response) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => {
                if (response.status == 204) {
                    dispatch(loadMemberTagsForCompositeTagSuccess([]));
                } else {
                    dispatch(loadMemberTagsForCompositeTagSuccess(response.data));
                }
                dispatch({ type: 'LOAD_MEMBER_DEPENDENCY_LOADER_FINISHED' });
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: 'LOAD_MEMBER_DEPENDENCY_LOADER_FINISHED' });
            });
        }
    };
}

export function loadMemberTagsForCompositeTagSuccess(payload) {
    return {
        type: 'LOAD_MEMBER_TAGS_FOR_COMPOSITE_SUCCESS',
        payload
    };
}

export function emptyAllFields() {
    return (dispatch) => {
        dispatch({ type: 'EMPTY_ALL_TAG_FORM_FIELDS_SUCCESS' });
    };
}

export function loadAllTagsList() {
    return (dispatch) => {
        dispatch({ type: 'LOAD_TAGS_LOADER_IN_PROGRESS' });
        api.get(GLOBAL.loadAllTagsListUrl)
            .then((response) => {
                if (!response) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => {
                if (response.status == 204) {
                    dispatch(loadAllTagsListSuccess([]));
                } else {
                    let responseValue = response.data.map((row)=>{
                        row.tagEnabled = row.tagEnabled == true ? "True": "False"
                        return row;
                    })
                    dispatch(loadAllTagsListSuccess(responseValue));

                }
                dispatch({ type: 'LOAD_TAGS_LOADER_FINISHED' });
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: 'LOAD_TAGS_LOADER_FINISHED' });
            });
    };
}

export function loadAllTagsListSuccess(payload) {
    return {
        type: 'LOAD_ALL_TAGS_LIST_SUCCESS',
        payload
    };
}

export function loadTagDetailsByTagId(tagId) {
    return (dispatch) => {
        dispatch({ type: 'LOAD_TAGS_LOADER_IN_PROGRESS' });
        api.get(GLOBAL.loadTagDetailsByTagIdUrl + '/' + tagId)
            .then((response) => {
                if (!response) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => {
                if (response.status == 204) {
                    dispatch(loadTagDetailsByTagIdSuccess([]));
                } else {
                    let initialValuesObj = {
                        company: response.data.companyId,
                        companyName: response.data.companyName,
                        businessFunctionId: response.data.businessFunctionId,
                        businessFunctionName: response.data.businessFunction,
                        module: response.data.tagModule,
                        label: response.data.tagLabel,
                        tagType: response.data.tagType,
                        tagDataValidation: response.data.tagValueDataType,
                        compositeTag: response.data.compositeTagKey,
                        parentTag: response.data.parentTagKey,
                        parentTagName: response.data.parent,
                        tagEnabled: response.data.tagEnabled,
                        tagDataSource: response.data.tagValueDataSource,
                        character_limit: response.data.tagCharLimit,
                        tagId: response.data.tagId,
                        sequence: response.data.sequence,
                        tagMandatory: response.data.tagMandatory
                    };
                    dispatch(loadTagDetailsByTagIdSuccess(initialValuesObj));
                }
                dispatch({ type: 'LOAD_TAGS_LOADER_FINISHED' });
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: 'LOAD_TAGS_LOADER_FINISHED' });
            });
    };
}

export function loadTagDetailsByTagIdSuccess(payload) {
    return {
        type: 'LOAD_TAG_DETAILS_BY_TAG_ID_SUCCESS',
        payload
    };
}

export function emptyTagDetailsByTagId() {
    return (dispatch) => {
        dispatch({ type: 'EMPTY_TAG_DETAILS_BY_TAG_ID_SUCCESS' });
    };
}

export function loadTagMemberDependencyData(tagId, page = 1, limit = 10, callback, queryBy='', queryValue='') {
    let strObj = {};
    strObj.tagId = tagId;
    strObj = JSON.stringify(strObj);
    return (dispatch) => {
    let url;
    if (queryBy && queryValue != "") {
        url = GLOBAL.loadTagMemberDependencyDataUrl + '?page=' + page + '&limit=' + limit +'&queryBy=' + queryBy + '&queryValue=' + queryValue
    } else {
        url = GLOBAL.loadTagMemberDependencyDataUrl + '?page=' + page + '&limit=' + limit
    }
        dispatch({ type: 'LOAD_MEMBER_DEPENDENCY_LOADER_IN_PROGRESS' });
        api.get(url, { headers: { query: strObj } })
            .then((response) => {
                if (!response) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => {
                if (response.status == 204) {
                    dispatch(loadMemberDependencyDataSuccess({data:[]}));
                } else {
                    dispatch(loadMemberDependencyDataSuccess(response.data));
                    if(callback) {
                        callback(response.data.data);
                    }
                }
                dispatch({ type: 'LOAD_MEMBER_DEPENDENCY_LOADER_FINISHED' });
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: 'LOAD_MEMBER_DEPENDENCY_LOADER_FINISHED' });
            });
    };
}

export function loadMemberDependencyDataSuccess(payload) {
    return {
        type: 'LOAD_MEMBER_DEPENDENCY_DATA_SUCCESS',
        payload
    };
}

export function loadTagMemberDependencyParentData(parentTagId) {
    let strObj = {};
    strObj.tagId = parentTagId;
    strObj = JSON.stringify(strObj);
    return (dispatch) => {
        api.get(GLOBAL.loadTagMemberDependencyDataUrl, { headers: { query: strObj } })
            .then((response) => {
                if (!response) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => {
                if (response.status == 204) {
                    dispatch(loadMemberDependencyParentDataSuccess({data:[]}));
                } else {
                    dispatch(loadMemberDependencyParentDataSuccess(response.data));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
}

export function loadMemberDependencyParentDataSuccess(payload) {
    return {
        type: 'LOAD_MEMBER_DEPENDENCY_PARENT_DATA_SUCCESS',
        payload
    };
}

export function deleteTagMemberDependencyData(id, callback) {
    let strObj = {};
    strObj.id = id;
    strObj = JSON.stringify(strObj);
    return (dispatch) => {
        api.delete(GLOBAL.deleteTagMemberDependencyDataUrl, { headers: { query: strObj } })
            .then((response) => {
                if (!response) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => {
                if (response.status == 201) {
                    callback();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
}

export function createMemberTagInitialDetails(createMemberTagInitialDetails) {
    return (dispatch) => {
        dispatch({ type: 'CREATE_MEMBER_TAG_INITIAL_DETAILS_SUCCESS', createMemberTagInitialDetails });
    };
}

export function createChecklistMemberTagInitialDetails(createChecklistMemberTagInitialDetails) {
    return (dispatch) => {
        dispatch({ type: 'CREATE_CHECKLIST_MEMBER_TAG_INITIAL_DETAILS_SUCCESS', createChecklistMemberTagInitialDetails });
    };
}

export function loadMemberTagsForChecklistTag(tagId) {
    return (dispatch) => {
        if(tagId == '') {
            dispatch(loadMemberTagsForChecklistTagSuccess([]));
        }
        else {
            dispatch({ type: 'LOAD_MEMBER_DEPENDENCY_LOADER_IN_PROGRESS' });
            api.get(GLOBAL.loadMemberTagsForChecklistTagUrl + '/' + tagId)
            .then((response) => {
                if (!response) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => {
                if (response.status == 204) {
                    dispatch(loadMemberTagsForChecklistTagSuccess([]));
                } else {
                    dispatch(loadMemberTagsForChecklistTagSuccess(response.data));
                }
                dispatch({ type: 'LOAD_MEMBER_DEPENDENCY_LOADER_FINISHED' });
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: 'LOAD_MEMBER_DEPENDENCY_LOADER_FINISHED' });
            });
        }
    };
}

export function loadMemberTagsForChecklistTagSuccess(payload) {
    return {
        type: 'LOAD_MEMBER_TAGS_FOR_CHECKLIST_SUCCESS',
        payload
    };
}

export function loadAllChecklistTags(companyId, businessFunction, tagModule, tagType) {
    let strObj = {};
    strObj.companyId = companyId;
    strObj.businessFunction = businessFunction;
    strObj.tagModule = tagModule;
    strObj.tagType = tagType;
    strObj = JSON.stringify(strObj);
    return (dispatch) => {
        api.get(GLOBAL.loadAllChecklistTagsUrl, { headers: { query: strObj } })
            .then((response) => {
                if (!response) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => {
                if (response.status == 204) {
                    dispatch(loadAllChecklistTagsSuccess([]));
                } else {
                    dispatch(loadAllChecklistTagsSuccess(response.data));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
}

export function loadAllChecklistTagsSuccess(payload) {
    return {
        type: 'LOAD_ALL_CHECKLIST_TAGS_SUCCESS',
        payload
    };
}