
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { ColorPicker } from "@progress/kendo-react-inputs";
import { getLanguageString } from "../../../../common/helper";

const ColorPickerField = (props) => {
  const { attributes, onValueChange, defaultValue, language, readOnly, nonEditable } = props;
  const gradientSettings = {
    opacity: false,
  };
  return (
    <ColorPicker
      value={defaultValue || new Date()}
      placeholder={getLanguageString(language,attributes.placeholder)}
      disabled={attributes.disabled || nonEditable}
      gradientSettings={gradientSettings}
      view={"gradient"}
      onChange={onValueChange}
      className="form-control"
      readOnly={readOnly}
    />
  );
};

export default ColorPickerField;
