
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Carousel, Row, Col, Form, Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { loadBestSelling } from "_Actions/homepage/bestsellingofferingAction";
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
import { resetStateInStore } from '_Actions/offeringDetails/offeringDetailsActions';
import { storeCategoryName } from '_Actions/homepage/hamburgerAction';
import { loadCartCount } from '_Actions/common/cartCountAction';
import dummyImage from "_Images/service-icons/service-dummy.png";
import ReactStars from 'react-stars';
import ListLoader from "../../common/loaders/ListLoader";
import PropTypes from 'prop-types';
import axios from 'axios';
import { GLOBAL } from '_Globals';
import _ from 'lodash';
import Cookies from 'universal-cookie';
import swal from 'sweetalert';
import {LiaHeartSolid} from 'react-icons/lia';
import { navigationHooks } from '../../../helpers/NavigationHook';
import Swal from 'sweetalert2';
const Joi = require('joi');
const api = axios.create({
  headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});
const cookies = new Cookies();
let cart_limit = cookies.get('gph');

if (cart_limit) cart_limit = cart_limit.replace('s:', '');
if (cart_limit) cart_limit = cart_limit.substring(0, cart_limit.lastIndexOf('.'));
cart_limit = cart_limit.split("~");

cart_limit = cart_limit[19];
if (cart_limit === "" || cart_limit === "undefined" || cart_limit === undefined)
  cart_limit = 5;

let homepagelocation = cookies.get('gph');

if (homepagelocation) homepagelocation = homepagelocation.replace('s:', '');
if (homepagelocation) homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
//console.log("homepage::::"+homepagelocation);
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
let counter = 0;
class BestSelling extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      addCardButtonDisableStatus: false,
      checkoutButtonDisableStatus: false,
      selfCheckoutButtonStatus: false,
      selectedCoreComponentForOffering: [],
      selectedOfferingIds: "",
      activePage: 1,
      index: 0,
      pageSize: 5,
      listBestData: []

    };
    this.onPageChange = this.onPageChange.bind(this);
    this.onPageSizeChange = this.onPageSizeChange.bind(this);
    GLOBAL.startindex = 1;
    GLOBAL.endindex = 5;
    this.saveOfferingAndComponentToCart = this.saveOfferingAndComponentToCart.bind(this);

  }

  componentWillMount() {
    let ref = this;
    ref.props.resetStateInStore('selected_components');
    if (ref.props.homeactivetab == "bestSelling")
      ref.props.loadBestSelling(GLOBAL.startindex, GLOBAL.endindex);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.homeactivetab == "bestSelling" && nextProps.homeactivetab != this.props.homeactivetab) {
      this.props.loadBestSelling(GLOBAL.startindex, GLOBAL.endindex);
      this.setState({pageSize: 5});
    }
    if (nextProps.bestSelling && nextProps.bestSelling !== this.props.bestSelling){
      this.setState({ listBestData: nextProps.bestSelling});

    }
  }

  onAddFavourite(object, index) {
    let ref = this;
    let arr = [...ref.state.listBestData.data];
    let obj = ref.state.listBestData;
    arr[index].isFavourite = 1;
    axios.post(GLOBAL.postOfferingFavUrl, {
      entitledUser: "",
      offeringId: object
    })
      .then(function (response) {
        if (response.status == 200) {
          obj.data = arr;
          ref.setState({ listBestData: obj });
        }
      })
      .catch(function (error) {
      });
  }

  onRemoveFavourite(object, index) {
    let ref = this;
    let arr = [...ref.state.listBestData.data];
    let obj = ref.state.listBestData;
    arr[index].isFavourite = 0;
    axios.post(GLOBAL.postOfferingFavRemoveUrl, {
      entitledUser: "",
      offeringId: object
    })
      .then(function (response) {
        if (response.status == 200) {
          obj.data = arr;
          ref.setState({ listBestData: obj });

        }
      })
      .catch(function (error) {
      });
  }


  onPageSizeChange(e) {
    this.setState({ pageSize: e.target.value });
    this.props.loadBestSelling(1, e.target.value);
  }

  onPageChange(e) {
    let startindex = GLOBAL.startindex;
    let endindex = GLOBAL.endindex;
    let selectedStartIndex = 1;
    counter = e - 1;
    selectedStartIndex = startindex + this.state.pageSize * counter;
    this.props.loadBestSelling(selectedStartIndex, this.state.pageSize);
  }


  onOffering(i, j, k, l) {
    //alert("ival::::"+j)
    let categoryName = k;
    if (j != "Suspended" && j != "Under Repair") {
      this.props.storeCategoryName(k);
      categoryName = categoryName.includes("?") ? categoryName.replace("?", "") : categoryName;
      // navigationHooks.navigate("/offeringDetails/" + i + "/" + categoryName + "/" + l);
      navigationHooks.navigate(
        `/offeringDetails/${encodeURIComponent(i)}/${encodeURIComponent(
          categoryName
        )}/${encodeURIComponent(l)}`
      );
    }
  }

  loadAllComponentsInCart(offeringId) {
    let ref = this;
    ref.setState({ addCardButtonDisableStatus: true });
    ref.setState({ checkoutButtonDisableStatus: true });
    ref.setState({ selfCheckoutButtonStatus: true });
    let str = {};
    str.userId = "";
    str = JSON.stringify(str);
    const offeringsInCartResponse = api.get(GLOBAL.cartOfferingsUrl, { headers: { 'query': str } });
    offeringsInCartResponse.then((allOfferingsInCart) => {
      if (allOfferingsInCart.data.length >= cart_limit) {
        swal({
          text: this.props.tr["Maximum number of offerings are already added in cart."],
          button: this.props.tr['OK'],
        });

        // alert(this.props.tr["Maximum number of offerings are already added in cart."]);
        ref.setState({
          checkoutButtonDisableStatus: false,
          selfCheckoutButtonStatus: false,
          addCardButtonDisableStatus: false,
          selectedOfferingIds: ""
        });
      }
      else {
        if (allOfferingsInCart.data.length !== 0 && _.some(allOfferingsInCart.data, { "offeringId": offeringId.toString() })) {
          swal({
            text: this.props.tr["Offering already added in cart."],
            button: this.props.tr['OK'],
          });

          // alert(this.props.tr["Offering already added in cart."]);
          ref.setState({
            checkoutButtonDisableStatus: false,
            selfCheckoutButtonStatus: false,
            addCardButtonDisableStatus: false,
            selectedOfferingIds: ""
          });

        } else {
          this.loadComponentPackage(offeringId, allOfferingsInCart);

        }
      }

    });
  }


  loadComponentPackage(offeringId, allOfferingsInCart) {
    //each time an offering is click in categoryList an new api call with offeringId as parameter wiil be hit to get new response corresponding to offering hit.
    let str = {};
    str.userId = "";
    str.offeringId = offeringId;
    str = JSON.stringify(str);
    api.get(GLOBAL.getComponentDetails, { headers: { 'query': str } })
      .then((response) => {
        if (!response) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then((response) => {
        if (response.status == 200) {
          let { selectedCoreComponentForOffering } = this.state;
          selectedCoreComponentForOffering = [];
          response.data.map((componentType) => {
            if (componentType.componentType == "Core") {
              if (!(_.some(selectedCoreComponentForOffering, ['id', componentType.id]))) {
                selectedCoreComponentForOffering.push(componentType);

              }
            }
          });
          this.setState({ selectedCoreComponentForOffering });
          this.saveOfferingAndComponentToCart(offeringId, selectedCoreComponentForOffering, allOfferingsInCart);


        }

      });

  }


  saveOfferingAndComponentToCart(offeringId, offeringComponentsForCart, allOfferingsInCart) {
    let ref = this;
    ref.setState({ addCardButtonDisableStatus: true });
    ref.setState({ checkoutButtonDisableStatus: true });
    ref.setState({ selfCheckoutButtonStatus: true });
    api.post(GLOBAL.addOfferingToCartUrl, {
      "offeringId": offeringId,
      "userId": ""
    })
      .then((response) => {
        if (response.status == 200) {
          if (offeringComponentsForCart.length !== 0) {
            for (let i = 0; i < offeringComponentsForCart.length; i++) {
              api.post(GLOBAL.addComponentsToCart, {
                "offeringId": offeringId,
                "componentId": offeringComponentsForCart[i].id
              }).then((response) => {
                if (response.status === 200) {
                  ref.setState({ addCardButtonDisableStatus: true });


                } else {
                  ref.setState({
                    addCardButtonDisableStatus: false,
                    checkoutButtonDisableStatus: false,
                    selfCheckoutButtonStatus: false,
                    selectedOfferingIds: ""
                  });
                  swal({
                    text: this.props.tr["Attached components could not be added, please try again."],
                    button: this.props.tr['OK'],
                  });

                  // alert(this.props.tr["Attached components could not be added, please try again."])
                }
              });

            }
          }

          this.props.loadCartCount();
          ref.setState({ addCardButtonDisableStatus: true });
          //navigationHooks.navigate("/cart");
          Swal.fire({
            title: this.props.tr["Item is added to cart"],
            showCancelButton: true,
            confirmButtonText: this.props.tr["Cart"],
            cancelButtonText: this.props.tr["Proceed to Home"]
          })
          .then((result) => {
            if (result.isDismissed && result.dismiss === "cancel") {
              navigationHooks.navigate(homepagelocation);
              ref.setState({
                addCardButtonDisableStatus: false,
                selectedOfferingIds: ""
              });
            } else if(result.isConfirmed) {
              navigationHooks.navigate("/cart");
            }
          });
        } else {
          ref.setState({
            addCardButtonDisableStatus: false,
            checkoutButtonDisableStatus: false,
            selfCheckoutButtonStatus: false,
            selectedOfferingIds: ""
          });
          swal({
            text: this.props.tr["There is a problem adding your recent offering to the cart try again."],
            button: this.props.tr['OK'],
          });

          // alert(this.props.tr["There is a problem adding your recent offering to the cart try again."]);
        }
        ref.setState({
          checkoutButtonDisableStatus: false,
          selfCheckoutButtonStatus: false,
          addCardButtonDisableStatus: false,
          selectedOfferingIds: ""
        });
      });


  }
  renderactuallist() {
    return this.state.listBestData.data
      .map((bestsellingoffObj, index) => {
        let favouriteexist = bestsellingoffObj.isFavourite;
        let displayText="";
        let displayStr="";
        displayStr = bestsellingoffObj.offeringSummary != null && bestsellingoffObj.offeringSummary != "" ? bestsellingoffObj.offeringSummary : "";
        displayText = displayStr != "" ? displayStr.replace(/(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/ig, '').trim() : "";
        displayText = displayText != "" ? displayText.replace(/\s+/g, ' ') : "";

        let displayName="";
        let displayNameStr="";
        displayNameStr = bestsellingoffObj.offeringName != null && bestsellingoffObj.offeringName != "" ? bestsellingoffObj.offeringName : "";
        displayName = displayNameStr != "" ? displayNameStr.replace(/(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/ig, '').trim() : "";
        displayName = displayName != "" ? displayName.replace(/\s+/g, ' ') : "";

        let getInnerWidth = (innerWidth)=>{
          if (innerWidth > 1535) return 170;
          if (innerWidth > 1367) return 82;
          if (innerWidth > 1279) return 75;
          if (innerWidth > 1023) return 50;
          if (innerWidth > 767) return 38;
          if (innerWidth > 640) return 26;
          if (innerWidth > 539) return 33;
          if (innerWidth > 411) return 50;
          if (innerWidth > 279) return 25;
          return 20;
        }
  
        // let namedSchema = undefined;
        // namedSchema = Joi.string().regex(/^[^}{]+$/);
        // let validator_to_add_myQbj = undefined;
        // let validate_summary = undefined;

        // if (bestsellingoffObj.description != null || bestsellingoffObj.description != "") {
        //   validator_to_add_myQbj = namedSchema.validate(bestsellingoffObj.description);
        //   if (validator_to_add_myQbj.error == null) {
        //     validate_summary = bestsellingoffObj.description;
        //   } else {
        //     validate_summary = "";
        //   }
        // } else validate_summary = "";
        // let displayStr = bestsellingoffObj.description;
        // let displayText = displayStr.replace(/(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/ig, '').trim();
        // displayText = displayText.replace(/\s+/g, ' ');
        return (
          <Card key={index}>
            <div className='card-left'>
            <Card.Link bsPrefix=' ' className='imgSec' 
                title={bestsellingoffObj.offeringName}
                role="button"
                onClick={this.onOffering.bind(
                  this,
                  bestsellingoffObj.offeringId,
                  bestsellingoffObj.subStatus,
                  bestsellingoffObj.category,
                  bestsellingoffObj.subCategory
                )}
                href="javascript:void(0)" 
              >
                <Card.Img bsPrefix=' '
                  title={bestsellingoffObj.offeringName}
                  className="proimg"
                  alt={displayName.slice(0, 10)+"..."}
                  src={
                    bestsellingoffObj.offeringIconTempURL
                      ? bestsellingoffObj.offeringIconTempURL
                      : dummyImage
                  }
                />
              </Card.Link>
            </div>
            <Card.Body>
              <div className="catListSec">
              <div className="bodyCont bodv">
                  <Card.Header>
                    
                    <a
                      href="javascript:void(0)" 
                      id={"listBestSell_"+index}
                      title={bestsellingoffObj.offeringName}
                      role="button"
                      onClick={this.onOffering.bind(
                        this,
                        bestsellingoffObj.offeringId,
                        bestsellingoffObj.subStatus,
                        bestsellingoffObj.category,
                        bestsellingoffObj.subCategory
                      )}
                    >
                      {displayName.slice(0, getInnerWidth(this.props.windowSize.width))}{displayName.length > getInnerWidth(this.props.windowSize.width) ? "..." : ''}
                    </a>
                    <span
                      style={{ position: "relative", top: "4px" }}
                      className="icons display-inline-block"
                    >
                      <ReactStars
                        value={bestsellingoffObj.rating}
                        edit={false}
                        count={5}
                        size={18}
                        color1={"gray"}
                        color2={this.props.appliedThemeData?.secondaryColor}
                      />
                    </span>
                    {bestsellingoffObj.hidePrice == "true" ? null : (
                      <span className="margin-l-10 f-size-14">
                        {bestsellingoffObj.currencyCode}&nbsp;
                        {bestsellingoffObj.actualPrice}
                      </span>
                    )}{" "}
                    {bestsellingoffObj.hidePrice == "true" ? null : (
                      <span className="f-size-14">
                        {bestsellingoffObj.billingMethod}
                      </span>
                    )}
                  </Card.Header>
                  <div className="txtDec">{displayText.slice(0, 250)}{displayText.length > 250 ? "..." : ''}</div>
                </div>
                <div className="headIcon bodv">
                  <div className="gap">
                    <a role="button" href="javascript:void(0)"  id={"listBestSellshare_"+index}>
                      <i className="fa fa-share-alt"/>
                    </a>
                    {favouriteexist == "0" ? (
                      <a
                        href="javascript:void(0)" 
                        role="button"
                        id={"listBestSellheart_"+index}
                        onClick={this.onAddFavourite.bind(
                          this,
                          bestsellingoffObj.offeringId,
                          index
                        )}
                        title={this.props.tr["Add to Favorites"]}
                        ><LiaHeartSolid/></a>
                    ) : (
                      <a
                        href="javascript:void(0)" 
                        role="button"
                        id={"listBestSellheart_"+index}
                        className="active"
                        onClick={this.onRemoveFavourite.bind(
                          this,
                          bestsellingoffObj.offeringId,
                          index
                        )}
                        title={this.props.tr["Remove from Favorites"]}
                        ><LiaHeartSolid/></a>
                    )}
                  </div>
                </div>
                <div className="footBtn bodv">
                <div className='footBtnParnt'>
                {bestsellingoffObj.workItemType === "incidentRequest" ||
								bestsellingoffObj.workItemType === "standardChange" ||
								bestsellingoffObj.status === "Proposed" ||
								bestsellingoffObj.deliveryMode == "Other" ? <span className='disSpan' title='Disabled'>{this.props.tr["Add to Cart"].slice(0, 20)}{this.props.tr["Add to Cart"].length > 20 ? "..." : ''}</span> : (	                  
                  <Link
                    href="javascript:void(0)" 
                    title={this.props.tr["Add to Cart"]}
                    id={"listBestSellcart_"+index}
                    className={
                      this.state.selectedOfferingIds ==
                        bestsellingoffObj.offeringId
                        ? this.state.addCardButtonDisableStatus
                          ? "disSpan"
                          : "cartBtn"
                        : "cartBtn"
                    }
                    role="button"
                    onClick={() => {
                      let selectedOfferingIds = bestsellingoffObj.offeringId;
                      this.setState({ selectedOfferingIds });
                      this.loadAllComponentsInCart(
                        bestsellingoffObj.offeringId
                      );
                    }}
                  >
                    {this.props.tr["Add to Cart"].slice(0, 20)}{this.props.tr["Add to Cart"].length > 20 ? "..." : ''}
                  </Link> )}
                  <a
                    title={this.props.tr["Order Now"]}
                    href="javascript:void(0)" 
                    className="orderBtn"
                    id={"listBestSellorder_"+index}
                    role="button"
                    onClick={this.onOffering.bind(
                      this,
                      bestsellingoffObj.offeringId,
                      bestsellingoffObj.subStatus,
                      bestsellingoffObj.category,
                      bestsellingoffObj.subCategory
                    )}
                  >
                    {this.props.tr["Order Now"].slice(0, 15)}{this.props.tr["Order Now"].length > 15 ? "..." : ''}
                  </a>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        );
      });
  }

  renderList(bestSellingData) {
    let indents = [];
    if (this.props.bestSellingShowLoader == true) {
      return (
        <div>
          <ListLoader />
        </div>
      );
    } else if (bestSellingData.length <= 0) {
      return (
        <div><font color="green" >{this.props.tr['No Data Found']}</font></div>
      );
    } else {
      let size = '';
      if (bestSellingData && bestSellingData.data) {
        size = bestSellingData.data.length;
      }
      // console.log("size==",size);
      // let caraousel_item_count = Math.ceil(size / 5);
      // if (caraousel_item_count > 1) {
      //   controlsFlag = true;
      // } else {
      //   controlsFlag = false
      // }
      // let indents = [];
      // let startpoint = "";
      // let endpoint = "";
      // for (let i = 1; i <= caraousel_item_count; i++) {
      //   if (i == 1) {
      //     startpoint = 0;
      //     endpoint = 5;
      //   } else {
      //     startpoint = endpoint;
      //     endpoint = endpoint + 5;
      //   }
      indents.push(<div className='homListCard'>{this.renderactuallist()}</div>);
    }
    return (
      <>
        {indents}
      </>
    );
  }

  render() {
    let meta = this.state.listBestData.meta;
    return (
      <div id="categoryListingTabBest" className="cateList categoryListingTab">
        {this.renderList(this.state.listBestData)}
        {meta ?
        <div className="nBotPagina">
          <div className="nShow">
            <div className="margin-r-10">{this.props.tr['Show']}:</div>
            <div aria-label='Show Count' role="contentinfo">
            <Form.Select aria-label={this.props.tr['Show']} onChange={this.onPageSizeChange}>
              <option value="5" selected={this.state.pageSize == 5 ? true : false}>5</option>
              <option value="10" selected={this.state.pageSize == 10 ? true : false}>10</option>
              <option value="50" selected={this.state.pageSize == 50 ? true : false}>50</option>
              <option value="100" selected={this.state.pageSize == 100 ? true : false}>100</option>
            </Form.Select></div>
          </div>
          <nav aria-label='Pagination' className='display-inline-block'>
             <Pagination
              prevPageText={this.props.tr['Prev']}
              nextPageText={this.props.tr['Next']}
              firstPageText={<i className="glyphicon glyphicon-menu-left" />}
              lastPageText={<i className="glyphicon glyphicon-menu-right" />}
              activePage={meta ? meta.currentPage : 1}
              itemsCountPerPage={this.state.pageSize}
              totalItemsCount={meta ? meta.rowCount : 1}
              onChange={this.onPageChange}
            /></nav>
        </div>
 : null}
      </div>
    );
  }
}

BestSelling.propTypes = {
	tr: PropTypes.object,
	resetStateInStore: PropTypes.func,
	storeCategoryName: PropTypes.func,
  loadCartCount:PropTypes.func,
  loadBestSelling:PropTypes.func,
  homeactivetab:PropTypes.string,
  bestSelling:PropTypes.object,
  bestSellingShowLoader:PropTypes.bool
};

function mapStateToProps({ bestSelling, bestSellingShowLoader, spcmReducer,windowSize, appliedThemeData}) {
  return {
    bestSelling, bestSellingShowLoader: bestSellingShowLoader.loading,
    tr: spcmReducer.tr,
    windowSize,
    appliedThemeData: appliedThemeData.data
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loadBestSelling, storeCategoryName, resetStateInStore, loadCartCount }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BestSelling);
