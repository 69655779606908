
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Button, Nav } from "react-bootstrap";
import {IoClose} from "react-icons/io5";
const TabHeader = ({ heading, options, close = false, otherProps }) => {
  return (
    <div className="paHedFilter" id="testingone">
      <Nav>
        {options?.map((items, index) => {
          return (
            !items.isVisible && (
              <Button
                bsPrefix=" "
                id="creTag"
                role="button"
                className={"myBt fillBtn" + items.className}
                onClick={items?.onClick}
                key={`_key_${index}`}
                title={items?.title}
              >
                <i className={`fa ${items?.icon}`} aria-hidden="true"></i>
              </Button>
            )
          );
        })}

        {close && (
          <div className="pull-right">
            <Button
              bsPrefix=" "
              id="creTag"
              role="button"
              className="myBt fillBtn"
              title="Close"
              onClick={() => {
                otherProps.close(otherProps.activeTab);
              }}
            >
              <IoClose />
            </Button>
          </div>
        )}
      </Nav>
    </div>
  );
};

export default TabHeader;
