
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/

/**
@Description: routes for
**/
import axios from 'axios';
import { GLOBAL } from "_Globals";
import constants from '../../../src/utils/constants';


const articleCategoryUrl="/getCategoryListForRestifySelfService";


export function loadArticleCategoryDropdownSuccess(articleCategoryDropdown){
	return {
		type: 'LOAD_ARTICLECATEGORYDROPDOWN_SUCCESS',
		articleCategoryDropdown
	};
}

export function loadArticleServicesDropdownSuccess(articleServicesDropdown){
	return {
		type: 'LOAD_ARTICLESERVICESDROPDOWN_SUCCESS',
		articleServicesDropdown
	};
}

export function refreshArticleResultSuccess(articleServicesDropdown){
	return {
		type: 'REFRESH_ARTICLE_RESULT',
		articleResult: []
	};
}

export function loadArticleResultsSuccess(articleResultsResponse){
	return {
		type: 'LOAD_ARTICLERESULTS_SUCCESS',
		articleResultsResponse
	};
}

export function loadArticleCategoryDropdown(){
	var str={};
	str.companyId="";
	str = JSON.stringify(str);
	
	return(dispatch) =>{
		dispatch({type:'DETAILS_FETCHED_SUCCESSFULLY'});
				axios.get(articleCategoryUrl,{headers:{'query':str}})
					.then((articleCategoryDropdown)=>{
						if(!articleCategoryDropdown){
							throw Error(articleCategoryDropdown.statusText);
						}
						return articleCategoryDropdown;
					})
					.then((articleCategoryDropdown) =>{
                        dispatch(loadArticleCategoryDropdownSuccess(articleCategoryDropdown));
					})
					.catch((err) => {
						console.log(err);
					});

	};
}

export function loadArticleServicesDropdown(categoryName){

	return(dispatch) =>{
				axios.get(GLOBAL.articleServicesLoadUrl+'/'+categoryName)
					.then((articleServicesDropdown)=>{
						if(!articleServicesDropdown){
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							throw Error(articleServicesDropdown.statusText);
						}
						return articleServicesDropdown;
					})
					.then((articleServicesDropdown) =>{
						// console.log('articleServicesDropdownarticleServicesDropdown',articleServicesDropdown)
						if( !(articleServicesDropdown && articleServicesDropdown.data && Array.isArray(articleServicesDropdown.data) && articleServicesDropdown.data.length) ){
							dispatch(refreshArticleResultSuccess());
						}
						dispatch(loadArticleServicesDropdownSuccess(articleServicesDropdown));
					})
					.catch((err) => {
						console.log(err);
					});
					//console.log("not calling loadarticleServicesDropdown");

	};
}


export function loadArticleResults(serviceVal){
	// console.log('##########################',serviceVal, userDetails, constants);

	// const articleResultsUrl = constants.xsmspcmurl+'/serviceknowledge/'+serviceVal;

	return(dispatch) =>{
		dispatch({type:'FETCHING_DETAILS_IN_PROGRESS'});
				//alert("edit"+breakFixUrl+breakFixId);
				// axios.get(GLOBAL.articleResultsUrl+'/'+serviceVal)
				axios.get(GLOBAL.articleResultsLoadUrl+'/'+serviceVal)
					.then((articleResultsResponse)=>{
						// console.log('BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB', articleResultsResponse)
						if(!articleResultsResponse){
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							throw Error(articleResultsResponse.statusText);
						}
						return articleResultsResponse;
					})
					.then((articleResultsResponse) =>{
						dispatch({type:'DETAILS_FETCHED_SUCCESSFULLY'});
                        dispatch(loadArticleResultsSuccess(articleResultsResponse));
					})
					.catch((err) => {
						console.log(err);
						dispatch({type:'DETAILS_FETCHED_SUCCESSFULLY'});
					});
					//console.log("not calling loadarticleResultsResponse");

	};
}

export function loadArticleResultsForSearchString(searchString){
	// console.log('##########################',searchString);

	// const articleResultsUrl = constants.xsmspcmurl+'/serviceknowledge/0?searchText='+searchString;

	// let searchStringactual=searchString.replace(' ','');
	let searchStringactual=searchString.trim();

	const articleResultsUrl = GLOBAL.articleSearchStringUrl;

	// console.log('articleResultsUrl',articleResultsUrl)
    let str = {};
	str.search_query = searchStringactual;
	str = JSON.stringify(str);

	return(dispatch) =>{
		dispatch({type:'FETCHING_DETAILS_IN_PROGRESS'});
				//alert("edit"+breakFixUrl+breakFixId);
				// axios.get(GLOBAL.articleResultsUrl+'/'+serviceVal)
				axios.get(articleResultsUrl, { headers: { 'query': str } })
					.then((resultResponse)=>{
						// console.log('BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB', resultResponse)
						if(!resultResponse){
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							throw Error(resultResponse.statusText);
						}
						return resultResponse;
					})
					.then((resultResponse) =>{
						dispatch({type:'DETAILS_FETCHED_SUCCESSFULLY'});
                        dispatch(loadArticleResultsSuccess(resultResponse));
					})
					.catch((err) => {
						console.log(err);
						dispatch({type:'DETAILS_FETCHED_SUCCESSFULLY'});
					});

	};
}

export function loadHomeArticleResultsForSearchString(searchString){
	return(dispatch) =>{
			dispatch({ type: 'LOAD_ARTICLERESULTS_SUCCESS', articleResultsResponse: {data: [searchString]} });

	};
}