
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Form from 'react-bootstrap/Form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import axios from 'axios';
import { GLOBAL } from '_Globals';
//let issue="";
import { loadBreakFixAuditLogDetails } from '_Actions/breakFix/breakFixAuditLogAction';
import { getInvestigationItemDetails,getInvestigationItemDetailsById,changeFieldStatusData } from '_Actions/investigation/investigationAction.js';
import { loadStatusBasedHamburgerOptions } from '_Actions/common/loadStatusBasedHamburgerOptions';
import { loadTimelineData } from '_Actions/common/commonApisActions.js';
import {ImSpinner6} from "react-icons/im";
import {IoClose} from "react-icons/io5";
import swal from 'sweetalert'
//let issueDescription="";
class ProposeCriticalTab extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			issueValidationError: '',
			savedMessage: '',
			issue: '',
			saveLoader:false,
			isChoosenDropDown : '',
			dropDownValue : ''
		};
		this.getIssue = this.getIssue.bind(this);
		this.onDropDownSelected = this.onDropDownSelected.bind(this);
	}

	getIssue(event) {
		//issue="";
		this.setState({
			issue: event.target.value
		})
		console.log(event.target.value);
	}

	//posting request
	postRequest() {
		if(this.props.invRelatedDataDetailsCount===0 && (this.props.investigationItemDetails.source==='Unresolved Incident'||this.props.investigationItemDetails.source==='Recurring Incident'||this.props.investigationItemDetails.source==='High Priority Incident'))
				swal({
					text: this.props.translator['Please link an appropriate Incident(s) with this Problem to proceed'],
					button: this.props.translator['OK'],
				  });
        // else if(this.props.user_id!=this.props.investigationItemDetails.asgPmId && (this.props.roleIdentification('16') || this.props.roleIdentification('17') ))
		// 		   swal({
		// 			  text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
		// 			  button: this.props.translator['OK'],
		// 		    });
		else if(this.props.user_id!=this.props.investigationItemDetails.asgPmId)
				{
				  swal({
					  text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
					  button: this.props.translator['OK'],
					});
				}
		else
		{
		//this.onChangeUrgency();
		//alert(this.props.investigationId)
		//alert(this.state.issue)
		let ref = this;

		//alert(this.state.issue);
		if (this.state.issue == "") {
			this.setState({
				issueValidationError: "error"
			})
		} else {
			this.setState({
				issueValidationError: ''
			})
		}

		if(this.state.dropDownValue == "") {
			this.setState({
				isChoosenDropDown: "error"
			})
		}
		else {
			this.setState({
				isChoosenDropDown: ''
			})
		}
		//insert request start
		let postjson = {
			//======================JSON Start
			"closureComments": this.state.issue,
			"status": "65",
			"modifiedBy":"",
			"modifiedByName":""
			//==============JSON End
		};
		let postjsonactivity = {
			"itemId":this.props.investigationId,
			"description":this.state.issue,
			"isInternal":"",
			"createdOn":"",
			"createdBy":"",
			"module":"Investigation",
			"createdByName":""
		};
		if (this.state.issue != "" && this.state.dropDownValue!="") {
			this.setState({saveLoader:true});
			let user_drop_down_val=this.state.dropDownValue
			axios.patch(GLOBAL.investigationEditUrl + this.props.investigationId, postjson)
				.then(function (response) {
					console.log(response);
					//alert("ABC"+response.data.breakfixId);
					//alert(response.statusText);
					if (response.status === 200) {
						//alert("M12")
						// ref.inputComments.value = "";
						ref.setState({issue: ""});
						ref.setState({
							//savedMessage:"Requested cteated for order "+response.data.breakfixNumber
							savedMessage: "Investigation Closed Successfully",
							saveLoader:false
						})
						ref.props.getInvestigationItemDetailsById(response.data.investigationId.toString());
						ref.props.changeActionView('processHierarchy');
						// ref.props.loadBreakFixAuditLogDetails(response.data.investigationId.toString(), "dsc", "Investigation",'STATUS');
						ref.props.changeFieldStatusData("65");
						ref.props.loadStatusBasedHamburgerOptions('Investigation',response.data.status,response.data.investigationId);
						//boc post in activity log
						axios.post(GLOBAL.breakFixActivityLogPostUrl, postjsonactivity)
							.then(function (response) {
								postjsonactivity.description="Closure Reason : "+user_drop_down_val
						axios.post(GLOBAL.breakFixActivityLogPostUrl, postjsonactivity).then(function (response){
								console.log("response.statusText:::2 " + response.status)
							}).catch(function (error) {
								console.log(error);
								//alert("H1"+error);
							});
							})
							.catch(function (error) {
								console.log(error);
								//alert("H1"+error);
							});
						//eoc

						ref.props.retireKnowledgeArticle();
					} else {
						ref.setState({saveLoader:false});
						console.log("response status is not ok:" + response.statusText)
					}

				})
				.catch(function (error) {
					ref.setState({saveLoader:false});
					console.log(error);
					//alert("H1"+error);
				});
		} else {
			console.log("Closure reason not found");
		}
	}
	}
	onDropDownSelected (e)   {
	  this.setState({isChoosenDropDown : '',dropDownValue : e.target.value})
	};
	//==============
	render() {

		if (this.state.savedMessage == "") {
			return (
				<div className="hambTab">
				<div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
					<ul>
						<li>
						<button
						    type='button'
							title="Minimize the right panel"
							bsClass=""
							bsStyle=""
							className="closerightPanelBtn"
							onClick={() => {
							this.props.rightEditPanel(false);
							}}
						>
							<IoClose />
						</button>
						</li>
					</ul>
				</div>
				<div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Problem Closure']}</div></div>
				<div className='rBoxGap'>
					<Form.Group className="margin-b-5">
					    <Form.Label><span className="rStar"></span>Closure Reason</Form.Label>
						<Form.Select as="select" onChange={(e) => this.onDropDownSelected(e)} className={this.state.isChoosenDropDown==='error'?'error':''}>
						        <option value="">Select Closure Reason</option>
								<option value="Closed Disqualified">Closed Disqualified</option>
								<option value="Closed Solution Investigation Withdrawn">Closed Solution Investigation Withdrawn</option>
								<option value="Closed Not Implemented">Closed Not Implemented</option>
								<option value="Closed Implemented">Closed Implemented</option>
						</Form.Select>
					</Form.Group>
					{this.state.isChoosenDropDown == "error" ? <div><font color="red"><strong>Please Select some Closure Reason !</strong></font></div> : <div></div>}


					<Form.Group className="margin-b-5">
						<Form.Label><span className="rStar"></span> Comments</Form.Label>
						<Form.Control as="textarea" rows="4" className={this.state.issueValidationError==='error'?'error':''}
						// inputRef={(input) => this.inputComments = input} 
						value={this.state.issue}
						onChange={this.getIssue.bind(this)} />
					</Form.Group>
					{this.state.issueValidationError == "error" ? <div><font color="red"><strong>Please provide Closure Comments !</strong></font></div> : <div></div>}
					{this.state.savedMessage == "" || this.state.issueValidationError == "error" ?


						<ButtonToolbar className="black margin-t-10">
							<Button className='rgSidrkBtn smallBtn' disabled={this.state.saveLoader}
							onClick={() => { this.postRequest(); }} >
							{this.state.saveLoader ?
								<ImSpinner6 className="icn-spinner"/> : null} {this.props.translator['Save']}
							</Button>
						</ButtonToolbar>


						: <div></div>}
						</div>
				</div>
			);
		} else {
			return (
				<div>
					<div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
						<ul>
							<li>
							<button
							    type='button'
								title="Minimize the right panel"
								bsClass=""
								bsStyle=""
								className="closerightPanelBtn"
								onClick={() => {
								this.props.rightEditPanel(false);
								}}
							>
								<IoClose />
							</button>
							</li>
						</ul>
					</div>
					<div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Problem Closure']}</div></div>
					<div className='rBoxGap'>
						<div><font color="green" ><b>{this.state.savedMessage}</b></font></div>
					</div>
				</div>
			);
		}
	}
}

export default connect(null, { loadBreakFixAuditLogDetails, getInvestigationItemDetails, getInvestigationItemDetailsById,changeFieldStatusData, loadStatusBasedHamburgerOptions, loadTimelineData })(ProposeCriticalTab);
