
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";

import { useShowHide } from "../../../../../common/useShowHide";
import GridLayout from "../../../../../common/GridLayout";
import TabHeader from "../../../../../common/TabHeader";

import DiscussionPointListView from "./list";
import AddEdit from "./addEdit";
import axios from "axios";

const DiscussionPoint = ({
  discussionPoints,
  occurenceData,
  companyId,
  orderby,
  sorting,
  refetchDicison,
  discussionsFetching,
  activeTabs,
}) => {
  const [isVisible, show, hide] = useShowHide(false);
  const meetingOccurrananceId = occurenceData?.meetingOccurrananceId;
  const [userId, setUserId] = useState("");
  const otherHeaderProps = {
    hide,
    isVisible,
  };
  const api = axios.create({
    headers: { Pragma: "no-cache", "Cache-control": "no-store" },
  });
  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = () => {
    try {
      let str = {};
      str.user_id = "";
      str = JSON.stringify(str);
      api
        .get("/api/userInfo", { headers: { query: str } })
        .then((res) => {
          setUserId(res?.data?.user_id);
        })
        .catch((err) => {
          console.error(err);
          setUserId("");
        });
    } catch (e) {
      console.error(e);
    }
  };

  const options = [
    occurenceData.status != "Cancelled" && occurenceData.primaryChairPerson == userId ?
    {
      //isVisible: !isEdit ? true : isVisible,
      isVisible,
      title: "Add",
      onClick: show,
      icon: "fa-plus",
      sorting: false,
      className: "add-message",
    } : {isVisible: true},
    {
      isVisible,
      title: "Sorting",
      onClick: sorting,
      icon: `custom-fa fa-sort-amount-${orderby}`,
      sorting: false,
      className: "custom-sorting",
    },
    {
      isVisible,
      title: "Refresh",
      onClick: refetchDicison,
      icon: "fa-refresh",
      sorting: false,
      className: "filter-icon mx-1",
    },
  ];

  return (
    <GridLayout cols={1}>
      <TabHeader
        options={options}
        close={false}
        otherProps={otherHeaderProps}
      />

      {!isVisible && (
        <DiscussionPointListView
          companyId={companyId}
          occurenceData={occurenceData}
          discussionPointsData={discussionPoints || []}
          discussionsFetching={discussionsFetching}
        />
      )}
      {isVisible && (
        <AddEdit
          companyID={companyId}
          occurenceData={occurenceData}
          hide={hide}
          activeTabs={activeTabs}
        />
      )}
    </GridLayout>
  );
};

export default DiscussionPoint;
