
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reduxForm, reset } from "redux-form";
import {
  allowedFields,
  filterAllowedFields,
  generateCondition,
  filterFromArray,
  getComponents,
  getLanguageString,
} from "../../common/helper";
import { Alert, Button, ButtonToolbar, Col, Row } from "react-bootstrap";
import {
  _inputField,
  _textArea,
  _number,
  _dropDown,
} from "../../common/formFields";
import axios from "axios";
import swal from "sweetalert";
import { useLocation, useNavigate, useParams } from "react-router";
import { useQuery } from "@apollo/client";
import {
  checkErrors,
  checkIfHiddenField,
  envVariables,
  getTag,
  isJson,
  jsonEscape,
  replaceVariables,
} from "../studio/utils";
import { getForm, getLanguage } from "../graphQl/GraphqlQueries";
import FormViewHeader from "./components/headers/formViewHeader";
import BuilderBreadcrumbs from "./components/bredCrumb/builderBreadcrumbs";
import {
  GetInputField,
  CustomDropdown,
  GetCheckBoxField,
  GetDropdownConnectorField,
  GetRadioButtonField,
  GetTextareaField,
  GetTypeaheadField,
  GetUploadDropzoneField,
  ITagComponent,
  InputCheckboxMainField
} from "./components/common/builderFormFields";
import ListLoader from "../../common/loaders/ListLoader";
import { Helmet } from "react-helmet";
import ViewTableComponent from "../../formBuilderTemplate/generatedListingComponents/tableComponent";
import ButtonsComponent from "./components/ButtonsComponent";
import RepeatativeField from "./components/subComponents/RepeatativeFieldsViewComponent";
import DependentFieldsWithDefaultValue from "./components/subComponents/DependentFieldsWithDefaultValue";
import RenderMap from "./components/subComponents/renderMap";
import RepeatativeBlock from "./components/subComponents/RepeatativeBlocks";
import useDataQueryWithApi from "../hooks/useDataQueryWithApi";
import TabRenderer from "./components/subComponents/tabRenderer";
import LinkComponent from "./components/LinkComponent";
import AccordionRenderer from "./components/subComponents/customAccordionRenderer";
import SwitchButtonComponent from "./components/SwitchButtonComponent";
import PopupModal from "./components/modals/modalPopup";
import PageContainerComponent from "./components/subComponents/Containers";
import RepetativeLevel from "./components/repetativeLevels";
import HorizontalSlider from "./components/HorizontalSlider";

function childernText(text) {
  return <span dangerouslySetInnerHTML={{ __html: text }}></span>;
}

const View = (props) => {
  const translator = useSelector((state) => state.spcmReducer.tr);
  const navigate = useNavigate();
  const params = useParams();
  const [forms, setForms] = useState({});
  const [ruleData, setRuleData] = useState({});
  const [defaultValues, setDefaultValues] = useState({});
  const [errors, setErrors] = useState({});
  const [hiddenFIelds, setHiddenFields] = useState([]);
  let { handleSubmit } = props;
  const [language, setLanguage] = useState({});
  const [myTitle, setMyTitle] = useState({});
  const [myBreadCrumb, setMyBreadCrumb] = useState({});
  const [isEditForm, setIsEditForm] = useState(false);
  const [editData, setEditData] = useState({});
  const [disableBtn, setDisableBtn] = useState(false);
  const [touchedFields, setTouchedFields] = useState({});
  const [formObjects, setFormObjects] = useState(null);
  const [cssString, setCss] = useState(null);
  const [isSubmitClicked,setIsSubmitClicked] = useState(false);
  const [formValues,setFormValues] = useState({});
  const [buttonProp,setButtonProps] = useState({});
  const [isDataRefreshed,setIsDataRefreshed] = useState(false);
  const [clearFilterClicked,setClearFilterClicked] = useState(false);
  const [mainPageId,setMainPageId] = useState(null);
  const [resetForm,setResetForm] = useState(false);
  const [formAttributes,setFormAttritutes] = useState({});
  const [buttonReplacableValue, setButtonReplacableValue] = useState({});
  const [clickedButton,setClickedButton] = useState(null)
  const [readOnlyFields,setReadOnlyFields] = useState([])
  const [openPopup,setOpenPopup] = useState(false);
  const [fileData,setFileData] = useState({});
  
  let { state: previousPageState } = useLocation();
  const dispatch = useDispatch();
  let defaultVal={};
  const {
    loading: languageLoading,
    error: languageError,
    data: languageData,
  } = useQuery(getLanguage);

  const parentValues = props?.parentValues || {};

  const [responseData , loading, setDataSourceId, error, setRefreshed] = useDataQueryWithApi({...buttonReplacableValue,...formValues,...parentValues,...envVariables},buttonProp, false, setResetForm, {}, fileData);

  const [editResponseData , editLoading, setEditResourceId, editError, setEditRefresh] = useDataQueryWithApi({dataId:params?.dataId,...parentValues,...envVariables},{}, false, setResetForm);

  useEffect(() => {
    if (error) {
      setClickedButton(null);
      setDataSourceId(null);
    }
  }, [error]);
  
  useEffect(() => {
    if (!languageLoading && Object.keys(languageData).length > 0) {
      //setLanguage(JSON.parse(languageData?.getAllLanguages?.value));
    }
  }, [languageData]);
  const {
    data: formData,
    loading: formDataLoading,
    error: formDataError,
    refetch: formDataRefetch,
  } = useQuery(getForm, { variables: { id: mainPageId },skip:mainPageId == null, fetchPolicy: 'no-cache' });

  useEffect(()=>{
       if((params && params?.id) || props?.pageId){
        setMainPageId(props?.pageId || params?.id);
       }
  },[params.id,props?.pageId]);

  useEffect(()=>{
    if(mainPageId){
      formDataRefetch()
    }
},[mainPageId]);

useEffect(()=>{
  if(props?.parentValues){
    setDefaultValues({...parentValues,...defaultValues})
  }
},[props?.parentValues]);



  useEffect(() => {
    formDataRefetch();
  }, []);
  const updateValues = (name, value, extraMappedKey = "", secondValue = "") => {
    let mappedValue = {};
    mappedValue[name] = value;
    if (extraMappedKey && secondValue) {
      mappedValue[extraMappedKey] = secondValue;
    }
    setDefaultValues({ ...defaultValues, ...mappedValue });
  };

  const checkRules = () => {
    let hidden = structuredClone(hiddenFIelds);
    let readOnly = structuredClone(readOnlyFields);
    if (ruleData && ruleData.length) {
      ruleData.forEach((ruleRes) => {
        const condition = generateCondition(ruleRes?.query, defaultValues)
          .replace(/\band\b/g, "&&")
          .replace(/\bor\b/g, "||");
        if (condition && eval(condition)) {
          if (ruleRes?.conditionTrue && ruleRes.conditionTrue.length > 0) {
            ruleRes.conditionTrue.forEach((res) => {
              if (res.operation == "show") {
                const index = hidden.indexOf(res.field);
                if (index > -1) {
                  hidden.splice(index, 1);
                }
              } else if (res.operation == "hide") {
                if (!hidden.includes(res.field)) {
                  hidden.push(res.field);
                }
              }
              //// starts readOnly if condition true
              if (res.operation == "readOnly") {
                if (!readOnly.includes(res.field)) {
                  readOnly.push(res.field);
                }
              } else if(res.operation == "removeReadOnly") {
                const index = readOnly.indexOf(res.field);
                if (index > -1) {
                  readOnly.splice(index, 1);
                }
              }
            });
          }
        } else {
          if (ruleRes?.conditionFalse && ruleRes.conditionFalse.length > 0) {
            ruleRes.conditionFalse.forEach((res) => {
              if (res.operation == "show") {
                const index = hidden.indexOf(res.field);
                if (index > -1) {
                  hidden.splice(index, 1);
                }
              } else if (res.operation == "hide") {
                if (!hidden.includes(res.field)) {
                  hidden.push(res.field);
                }
              }
              //// starts readOnly if condition false
              if (res.operation == "readOnly") {
                if (!readOnly.includes(res.field)) {
                  readOnly.push(res.field);
                }
              } else if(res.operation == "removeReadOnly"){
                const index = readOnly.indexOf(res.field);
                if (index > -1) {
                  readOnly.splice(index, 1);
                }
              }
            });
          }
        }
      });
    }
    setReadOnlyFields(readOnly);
    setHiddenFields(hidden);
  };

  useEffect(() => {
    if (ruleData && Object.keys(ruleData).length > 0) {
      checkRules();
    }
    if (defaultValues && Object.keys(defaultValues).length > 0 && isSubmitClicked) {
      let errors = checkErrors(forms, defaultValues, touchedFields, hiddenFIelds);
      setErrors(errors);
    }
  }, [ruleData, defaultValues]);

  useEffect(() => {
    if (hiddenFIelds && hiddenFIelds.length > 0) {
      hiddenFIelds.forEach((res) => {
        if (defaultValues.hasOwnProperty(res)) {
          delete defaultValues[res];
        }
      });
    }
  }, [hiddenFIelds]);


  // const removeHiddenFields = (fieldName)=> {
  //   if (defaultValues.hasOwnProperty(fieldName)) {
  //     delete defaultValues[fieldName];
  //   }
  // }

  /// useEffect for checking values and conditions before rendering
  useEffect(() => {
    if (formData && Object.keys(formData).length > 0 && Object.keys(forms).length === 0) {
      if (formData?.getForm?.customScript) {
        const script = formData?.getForm?.customScript;
        setTimeout(() => {
          eval(script);
        }, 2000);
      }
      const data = JSON.parse(formData?.getForm?.formObjects);
      if (formData?.getForm?.ruleData && isJson(formData?.getForm.ruleData)) {
        //setRuleData(JSON.parse(formData?.getForm?.ruleData));
        const ruleData = JSON.parse(formData?.getForm.ruleData);
        if (Array.isArray(ruleData)) {
          setRuleData(ruleData);
        } else if (
          typeof ruleData == "object" &&
          Object.keys(ruleData).length > 0
        ) {
          const newRule = [];
          newRule.push(ruleData);
          setRuleData(newRule);
        }
      }
      if (typeof data == "object" && Object.keys(data).length > 0) {
        let components = [];
        for (let i = 0; i < data.pages.length; i++) {
          for (let j = 0; j < data.pages[i].frames.length; j++) {
            components = [
              ...components,
              getComponents(data.pages[i].frames[j].component.components),
            ];
          }
        }
        const filteredFields = filterAllowedFields(components, allowedFields);
        if(filteredFields.length>0){
          setForms(filteredFields);
        }
        setFormObjects(data);
        let buttons = filterFromArray(filteredFields, "type", "button");
        const title = filterFromArray(filteredFields, "type", "titleComponent");
        const formAttributes = filterFromArray(filteredFields, "type", "form");
        const breadCrumb = filterFromArray(
          filteredFields,
          "type",
          "myBreadCrumb"
        );
        if (title.length > 0) {
          setMyTitle(title[0]);
        }
        if (breadCrumb.length > 0) {
          setMyBreadCrumb(breadCrumb[0]);
        }

        if (formAttributes.length > 0) {
          setFormAttritutes(formAttributes[0]);
        }

        if (params?.dataId) {
          setIsEditForm(true);
        }
      }
    }
  }, [formData, forms]);
  useEffect(() => {
    setErrors({});
    setIsSubmitClicked(false);
    setClickedButton(null);
    setButtonProps({});
    setFormValues({});
    setDataSourceId(null);
    setRefreshed(null);
    setHiddenFields([]);
    setForms({});
    setEditData({});
    setIsDataRefreshed(false);
    setButtonReplacableValue({});
    if(!params?.dataId && !isEditForm) {
      setIsEditForm(false);
      setDefaultValues({});
    }
    setResetForm(false);
    setFileData({});
  }, [params?.id, resetForm])

  useEffect(()=>{
     if(resetForm && props?.isFormView){
        props?.setIsFormView(false);
     } 
  },[resetForm])

  useEffect(()=>{
    if(resetForm && props?.isChildPage){
      setDefaultValues({});
    } 
 },[resetForm])

  useEffect(()=>{
     if(isEditForm){
        setIsEditForm(false);
        setDefaultValues({});
     }
  },[params?.id,params?.dataId])

  useEffect(() => {
    if (isEditForm && Object.keys(editData).length == 0) {
      if (previousPageState?.editData) {
        setDefaultValues(previousPageState?.editData);
      }
    }
  }, [isEditForm, editData]);

  useEffect(()=>{
     if(formAttributes && Object.keys(formAttributes).length>0){
       setEditResourceId(formAttributes.data_source?.id);
     }
  },[formAttributes]);

  useEffect(() => {
    if(editResponseData){
       setDefaultValues(editResponseData);
    }
  }, [editResponseData]);

  //////// end of use effect of edit response

  useEffect(() => {
    if (responseData && typeof responseData == "object" && isEditForm) {
      setDefaultValues({ ...defaultValues, ...responseData });
    }
  }, [responseData]);

  useEffect(() => {
    if (responseData && typeof responseData == "object" && fileData && Object.keys(fileData).length > 0 && buttonProp?.uploadFile) {
       const buttonPropData = structuredClone(buttonProp);
       delete buttonPropData["uploadFile"];
       setButtonProps(buttonPropData);
       setDefaultValues({ ...defaultValues, ...responseData});
       setErrors({});
       submit("save",{...buttonPropData,data_source:fileData?.attributes?.data_source});
    }
  }, [responseData]);

  //// end of use button api response ----


  //// if page is gets refreshed 

  useEffect(()=>{
     if(props?.pageRefreshed){
      setIsDataRefreshed(true);
      if(props?.refreshClickFun && typeof props?.refreshClickFun =="function"){
        props?.refreshClickFun(false);
      }
     }
  },[props?.pageRefreshed])

  /// end of form data change useEffect

  const jsonToCss = (json) => {
    if (json) {
      let css = "";
      json.forEach((item) => {
        let inCss = "";
        item.selectors.forEach((selector) => {
          let name = typeof selector === "string" ? selector : selector.name;
          if (name.indexOf("col-") == -1) {
            if (name.charAt(0) == "#") {
              inCss += `${name} {\n`;
            } else {
              inCss += `.${name} {\n`;
            }

            for (let property in item.style) {
              inCss += `  ${property}: ${item.style[property]};\n`;
            }
            inCss += "}\n";
          }
        });
        if (item.atRuleType === "media") {
          css += `@media ${item.mediaText} {\n${inCss}}\n`;
        } else {
          css += inCss;
        }
      });

      return css;
    }
  };

  useEffect(() => {
    if (formObjects) {
      let css = jsonToCss(formObjects?.styles);
      setCss(css);
    }
  }, [formObjects]);

  const onChange = (e, attributes) => {
    if(attributes?.defaultValue){
      defaultVal = {...defaultVal, [attributes.name]: e};
      setDefaultValues({...defaultValues,...defaultVal});
    }else{
      setDefaultValues({ ...defaultValues, [attributes.name]: e });
    }
  };

  const submit = (type, buttonProp) => {
    if (type === "save") {
        let postJson = {};
        let error = false;
        let values= {};
        setIsSubmitClicked(true);
        let combinedValues = { ...values, ...defaultValues };
        if(!buttonProp?.skipValidation){
          let errors = checkErrors(forms, combinedValues, {}, hiddenFIelds);
          if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
          } else {
            error = false;
            setErrors(errors);
          }
        }
        if (
          combinedValues &&
          typeof combinedValues == "object" &&
          Object.keys(combinedValues).length > 0
        ) {
          Object.keys(combinedValues).forEach((key) => {
            if (
              combinedValues[key] &&
              typeof combinedValues[key] == "object" &&
              combinedValues[key].hasOwnProperty("dataType") &&
              combinedValues[key]?.dataType == "file"
            ) {
              postJson[key] = combinedValues[key]?.data;
            } else if (
              typeof combinedValues[key] == "object" ||
              Array.isArray(combinedValues[key])
            ) {
              postJson[key] = JSON.stringify(combinedValues[key]);
            } else {
              postJson[key] = jsonEscape(combinedValues[key]);
            }
          });
        }
        if(!error) {
          setClickedButton(buttonProp);
          setFormValues(postJson);
          setButtonProps(structuredClone(buttonProp));
          setDataSourceId(buttonProp?.data_source?.id);
          // setRefreshed(true);
        }
      // handleSubmit((values) => {
        
      // })();
    } else if(type === 'refresh') {
      setRefreshed(true);
    }else{
      if(buttonProp?.data_source && buttonProp?.data_source?.id){
        setFormValues(defaultValues);
        setButtonProps(structuredClone(buttonProp));
        setDataSourceId(buttonProp?.data_source?.id);
      }
    }
  }

  const buttonClicked = (type, buttonProp, replacableValues={}) => {
    setButtonReplacableValue(replacableValues);

    if (type === "link") {
      let url = replaceVariables(buttonProp?.redirectUrl, replacableValues);
      if (buttonProp?.confirmationMessage) {
        swal({
          title: buttonProp?.confirmationMessage,
          icon: "warning",
          buttons: ["No", "Yes"],
          dangerMode: true,
        }).then(function (isConfirm) {
          if (isConfirm) {
            navigate(url);
          }
        });
      } else {
        navigate(url);
      }
    } else if (type === "refresh") {
      setIsDataRefreshed(true);
    } else if (type === "clearAllFilter") {
      setClearFilterClicked(true);
    } else if (type === "newPageOpen") {
      if (
        props?.setOpenedTabPageId &&
        typeof props?.setOpenedTabPageId == "function"
      ) {
        props?.setOpenedTabPageId(buttonProp?.page?.id);
      } else if (
        buttonProp?.openPageInPopup?.id == "bootstrapPopup" ||
        buttonProp?.openPageInPopup?.id == "kendoPopup"
      ) {
        setOpenPopup(true);
        setButtonProps({ buttonProp, data: replacableValues });
      } else if (buttonProp?.openPageInPopup?.id == "container") {
        setButtonProps({ buttonProp, data: replacableValues });
      }
      return;
    }
    if (buttonProp?.confirmationMessage && type !== "link") {
      swal({
        title: buttonProp?.confirmationMessage,
        icon: "warning",
        buttons: ["No", "Yes"],
        dangerMode: true,
      }).then(function (isConfirm) {
        if (isConfirm) {
          submit(type, buttonProp);
          // setButtonProps(buttonProp);
          // setDataSourceId(buttonProp?.data_source?.id);
        }
      });
    } else {
      submit(type, buttonProp);
      // setButtonProps(buttonProp);
      // setDataSourceId(buttonProp?.data_source?.id);
    }
  };

  const renderComponent = (json, isRowBased = false, rowData={}) => {
    if(resetForm) return <ListLoader />
    else {
      return json.map((item, index) => {
        let attributes = {};
        if (item.attributes) {
          attributes = { ...item.attributes };
        }
        if (item.classes) {
          attributes.className = item.classes
            .map((c) => (c.name ? c.name : c))
            .join(" ");
        }
        if (item?.name && item.name == "Row") {
          attributes.className = attributes.className + "";
        }
        if (
          item?.name &&
          item.name == "Row" &&
          item?.attributes &&
          item?.attributes?.title == "form"
        ) {
          if (checkIfHiddenField(item.components, hiddenFIelds)) {
            return null;
          }
        }
        if (item?.name && item.name == "Cell") {
          attributes.className = attributes.className + "";
        }
        if (
          item?.name &&
          item.name == "Cell" &&
          item?.attributes &&
          item?.attributes?.title == "form"
        ) {
          if (checkIfHiddenField(item.components, hiddenFIelds)) {
            return null;
          }
        }

        if (attributes && attributes.title == "form") {
          delete attributes["title"];
        }
        let children = null;
        if (item.components) {
          children = renderComponent(item.components, isRowBased, rowData);
        } else if (item.content) {
          children = childernText(replaceVariables(item.content,{...rowData,...parentValues,...defaultValues,...envVariables}));
        }
        if (item.type === "textnode") {
          return children;
        } else if (item.type == "input") {
          return (
            <GetInputField
              attributes={item.attributes}
              language={language}
              onChange={onChange}
              defaultValues={{...parentValues,...defaultValues}}
              hiddenFIelds={hiddenFIelds}
              readOnlyFields={readOnlyFields}
              errors={errors}
              atr={{id:attributes?.id,className:attributes?.className}}
            />
          );
        }else if (item.type == "myDateTimeComponent") {
          return (
            <GetInputField
              attributes={item.attributes}
              language={language}
              onChange={onChange}
              defaultValues={{...parentValues,...defaultValues}}
              hiddenFIelds={hiddenFIelds}
              readOnlyFields={readOnlyFields}
              errors={errors}
              atr={{id:attributes?.id,className:attributes?.className}}
            />
          );
        } else if (item.type == "textarea") {
          return (
            <GetTextareaField
              attributes={item.attributes}
              language={language}
              onChange={onChange}
              defaultValues={{...parentValues,...defaultValues}}
              hiddenFIelds={hiddenFIelds}
              readOnlyFields={readOnlyFields}
              errors={errors}
              atr={{id:attributes?.id,className:attributes?.className}}
            />
          );
        } else if (item.type == "custom-dropdown" || item.type == "select") {
          return (
            <CustomDropdown
              props={item}
              language={language}
              onChange={onChange}
              defaultValues={{...parentValues,...defaultValues}}
              hiddenFIelds={hiddenFIelds}
              readOnlyFields={readOnlyFields}
              setDefaultValues={setDefaultValues}
              errors={errors}
              atr={{id:attributes?.id}}
            />
          );
        } else if (item.type == "typeaheadComponent") {
          return (
            <GetTypeaheadField
              attributes={item}
              language={language}
              onChange={onChange}
              setDefaultValues={setDefaultValues}
              defaultValues={{...parentValues,...defaultValues}}
              hiddenFIelds={hiddenFIelds}
              readOnlyFields={readOnlyFields}
              errors={errors}
              resetForm={resetForm}
              isEditForm={isEditForm}
              atr={{id:attributes?.id,className:attributes?.className}}
            />
          );
        } else if (item.type == "uploadComponent") {
          return (
            <GetUploadDropzoneField
              attributes={item}
              language={language}
              onChange={onChange}
              defaultValues={{...parentValues,...defaultValues}}
              hiddenFIelds={hiddenFIelds}
              readOnlyFields={readOnlyFields}
              errors={errors}
              atr={{id:attributes?.id,className:attributes?.className}}
              setFileData={setFileData}
            />
          );
        } else if (item.type == "custom-checkbox") {
          return (
            <GetCheckBoxField
              attributes={item}
              language={language}
              onChange={onChange}
              defaultValues={{...parentValues,...defaultValues}}
              hiddenFIelds={hiddenFIelds}
              readOnlyFields={readOnlyFields}
              errors={errors}
              atr={{id:attributes?.id,className:attributes?.className}}
            />
          );
        } else if (item.type == "custom-radio" && item.labels) {
          return (
            <GetRadioButtonField
              attributes={item}
              language={language}
              onChange={onChange}
              defaultValues={{...parentValues,...defaultValues}}
              hiddenFIelds={hiddenFIelds}
              readOnlyFields={readOnlyFields}
              errors={errors}
              atr={{id:attributes?.id,className:attributes?.className}}
            />
          );
        } else if (item.type == "dropdownConnector") {
          return (
            <GetDropdownConnectorField
              attributes={item}
              language={language}
              onChange={onChange}
              updateValues={updateValues}
              defaultValues={{...parentValues,...defaultValues}}
              hiddenFIelds={hiddenFIelds}
              readOnlyFields={readOnlyFields}
              errors={errors}
              isEditForm={isEditForm}
              atr={{id:attributes?.id,className:attributes?.className}}
            />
          );
        } else if (item.type === "myBreadCrumb") {
          return (
            <BuilderBreadcrumbs
              breadcrumbs={item?.label}
              links={item?.breadCrumbLinks}
              attributes={attributes}
            />
          );
        } else if (item.tagName === "i") {
          return <ITagComponent attributes={attributes} />;
        } else if (item.type === "RepeatativeFieldsComponent") {
          return (
            <RepeatativeField
              components={item.components}
              item={item}
              language={language}
              onChange={onChange}
              defaultValues={{...parentValues,...defaultValues}}
              hiddenFIelds={hiddenFIelds}
              readOnlyFields={readOnlyFields}
              errors={errors}
            />
          );
        } else if (item.type === "RepetativeRowBlock") {
          return (
            <RepeatativeBlock
              components={item.components}
              item={item}
              language={language}
              languageData={languageData}
              defaultValues={{...parentValues,...defaultValues}}
              isDataRefreshed={isDataRefreshed}
              setIsDataRefreshed={setIsDataRefreshed}
              buttonClicked={buttonClicked}
              renderComponent={renderComponent}
            />
          );
        } else if (item.type === "dependentFieldsBlockComponent") {
          return (
            <DependentFieldsWithDefaultValue
              components={item.components}
              item={item}
              language={language}
              onChange={onChange}
              defaultValues={{...parentValues,...defaultValues}}
              hiddenFIelds={hiddenFIelds}
              readOnlyFields={readOnlyFields}
              errors={errors}
            />
          );
        } else if (item.type === "button") {
          return (
            <ButtonsComponent
              buttonsProp={item}
              buttonClicked={buttonClicked}
              disabled={loading}
              defaultValues={{...parentValues,...defaultValues}}
              clickedButton={clickedButton}
              isRowBased={isRowBased}
              rowData={rowData}
            />
          );
        } else if (item.type == "table" && item?.attributes && item?.attributes.data_source) {
          return (
            <ViewTableComponent
              renderComponent={renderComponent}
              languageData={languageData}
              table={item}
              title={myTitle}
              defaultValues={{...parentValues,...defaultValues}}
              isDataRefreshed={isDataRefreshed}
              setIsDataRefreshed={setIsDataRefreshed}
              clearFilterClicked={clearFilterClicked}
              setClearFilterClicked={setClearFilterClicked}
              setFormValues={setFormValues}
              setButtonReplacableValue={setButtonReplacableValue}
              setDefaultValues={setDefaultValues}
            />
          );
        } else if (item.type == "image") {
          return <img {...attributes} />;
        } else if (item.type == "checkbox") {
          return (
            <InputCheckboxMainField
              attributes={item?.attributes}
              language={language}
              onChange={onChange}
              defaultValues={{...parentValues,...defaultValues}}
              hiddenFIelds={hiddenFIelds}
              readOnlyFields={readOnlyFields}
              errors={errors}
            />
          );
        } else if (item.type == "map" || item.type == "video") {
          return (
            <RenderMap
              attributes={attributes}
              src={item.src}
              title={item.address}
              languageData={languageData}
            />
          );
        } else if (item.type == "tabs") {
          return (
            <TabRenderer
              item={item}
              languageData={languageData}
              renderComponent={renderComponent}
              defaultValues={{...parentValues,...defaultValues}}
            />
          );
        } else if (item.type == "CustomAccordion") {
          return (
            <AccordionRenderer
              item={item}
              languageData={languageData}
              renderComponent={renderComponent}
              defaultValues={{...parentValues,...defaultValues}}
              setDefaultValues={ setDefaultValues }
            />
          );
        } else if (item.type == "link") {
          return (
            <LinkComponent
              item={item}
              languageData={languageData}
              renderComponent={renderComponent}
              buttonClicked={buttonClicked}
              attributes={attributes}
              clickedButton={clickedButton}
              defaultValues={{...parentValues,...defaultValues}}
              isRowBased={isRowBased}
              rowData={rowData}
            />
          );
        }else if (item.type == "switch-button") {
          return (
            <SwitchButtonComponent
              item={item}
              languageData={languageData}
              renderComponent={renderComponent}
              attributes={attributes}
              onChange={onChange}
              defaultValues={{...parentValues,...defaultValues}}
            />
          );
        }else if (item.type == "pageContainer") {
          return (
            <PageContainerComponent
              item={item}
              languageData={languageData}
              buttonProp={buttonProp}
              defaultValues={{ ...parentValues, ...defaultValues }}
            />
          );
        } else if (item.type == "repetativeLevelBlock") {
          return (
            <RepetativeLevel
            components={item.components}
            item={item}
            language={language}
            languageData={languageData}
            defaultValues={{...parentValues,...defaultValues}}
            isDataRefreshed={isDataRefreshed}
            setIsDataRefreshed={setIsDataRefreshed}
            buttonClicked={buttonClicked}
            renderComponent={renderComponent}
            />
          );
        } else if (item.type == "horizontal-slider") {
          return (
            <HorizontalSlider
            components={item.components}
            item={item}
            language={language}
            languageData={languageData}
            defaultValues={{...parentValues,...defaultValues}}
            isDataRefreshed={isDataRefreshed}
            setDefaultValues={setDefaultValues}
            setIsDataRefreshed={setIsDataRefreshed}
            buttonClicked={buttonClicked}
            renderComponent={renderComponent}
            />
          );
        } else {
          return React.createElement(
            item.type || item?.tagName ? getTag(item) : "div",
            { ...attributes, key: index },
            children
          );
        }
      });
    }
  };

  if (languageLoading || formDataLoading || editLoading) {
    return (
      <div>
        <Row>
          <Col className="margin-t-10">
            <ListLoader />
          </Col>
        </Row>
      </div>
    );
  } else {
    ///main render part
    return (
      <div>
        <Helmet>
          <style>{cssString}</style>
        </Helmet>
        {/* <div className="container-fluid margin-t-10 margin-b-15">
          {Object.keys(myBreadCrumb).length > 0 && (
            <BuilderBreadcrumbs breadcrumbs={myBreadCrumb?.label} />
          )}
        </div> */}
        <div className="container-fluid">
          <>
            {errors && Object.keys(errors).length > 0 && (
              <Alert variant="danger" className="errorMessageSec margin-t-15">
                Please checkout the required fields!
              </Alert>
            )}
          </>
          <>
            {formObjects &&
              renderComponent(
                formObjects.pages[0].frames[0].component?.components
              )}
          </>
        </div>
        {openPopup && (
          <PopupModal
            open={openPopup}
            buttonProp={buttonProp}
            setOpenPopup={setOpenPopup}
          />
        )}
      </div>
    );
  }
};

// export default reduxForm({
//   form: "FormView",
// })(View);

export default View;
