
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
/* eslint-disable no-class-assign */
import React from 'react';
import Spinner from 'react-spinkit';
import { Field, reduxForm } from 'redux-form';
import {Row,Col,Form,Button} from "react-bootstrap";
import { connect } from 'react-redux';
import axios from 'axios';
import { bindActionCreators } from 'redux';
// import { UpdateProfile } from '_Actions/myProfile/profileAction';
import { loadProfile } from '_Actions/myProfile/profileAction';
import EmailValidator from 'email-validator';

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

import { GLOBAL } from '_Globals';
import ReactReadMoreLess from '../../helpers/ReactReadMoreLess';
import { HiOutlinePencilAlt } from 'react-icons/hi';

let updatedAlternatePhone = "";
let updatedAlternateEmail = "";
class RightSideAction extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			inputClassName: true,
			textInputType1: "text",
			textInputType2: "hidden",
			CPErrorColor: "",
			BPErrorColor: "",
			MPErrorColor: "",
			image: "",
			editSelected: 0,
			contactSaveMsg: "",
			// updateContact: 0,
			// updateMobile: 0,
			// updateBusiness: 0,
			updateAlternatePhone: 0,
			updateAlternateEmail: 0,
			spinner: '',
			showLoader: false,
			alternateEmailError: '',
			alternatePhoneError: '',
			alternatePhoneInputClassNameValue:'form-control editInput',
			alternateEmailInputClassNameValue:'form-control editInput',
			isLessMoreGroup: false,
			isLessMore: false
		};
		this.fileChangedHandler = this.fileChangedHandler.bind(this);
		this.setLessMoreGroupFun = this.setLessMoreGroupFun.bind(this);
		this.setLessMoreFun = this.setLessMoreFun.bind(this);
	}
	setLessMoreGroupFun() {
		this.setState({ isLessMoreGroup: !this.state.isLessMoreGroup });
	}
	setLessMoreFun() {
		this.setState({ isLessMore: !this.state.isLessMore });
	}

	// updateContactPhone(myInput) {
	// 	if (myInput.className == "form-control editInput") {
	// 		myInput.className = "form-control showUpdatebtn";
	// 		myInput.disabled = false;
	// 		myInput.focus();
	// 		this.setState({ updateContact: 1, showSavedMessage: "" })
	// 	}
	// 	else {
	// 		myInput.className = "form-control editInput";
	// 		myInput.disabled = true;
	// 		this.setState({ updateContact: 0 })
	// 	}

	// };

	// updateMobilePhone(myInput) {
	// 	if (myInput.className == "form-control editInput") {
	// 		myInput.className = "form-control showUpdatebtn";
	// 		myInput.disabled = false;
	// 		myInput.focus();
	// 		this.setState({ updateMobile: 1, showSavedMessage: "" })
	// 	}
	// 	else {
	// 		myInput.className = "form-control editInput";
	// 		myInput.disabled = true;
	// 		this.setState({ updateMobile: 0 })
	// 	}

	// };

	// updateBusinessPhone(myInput) {
	// 	if (myInput.className == "form-control editInput") {
	// 		myInput.className = "form-control showUpdatebtn";
	// 		myInput.disabled = false;
	// 		myInput.focus();
	// 		this.setState({ updateBusiness: 1, showSavedMessage: "" })
	// 	}
	// 	else {
	// 		myInput.className = "form-control editInput";
	// 		myInput.disabled = true;
	// 		this.setState({ updateBusiness: 0 })
	// 	}

	// };

	updateAlternatePhone(myInput) {
		if (myInput.className == "form-control editInput") {
			myInput.className = "form-control showUpdatebtn";
			myInput.disabled = false;
			myInput.tabIndex = 0
			myInput.focus();
			this.setState({ updateAlternatePhone: 1});
			this.props.showErrorMessage("");
		}
		else {
			myInput.className = "form-control editInput";
			myInput.disabled = true;
			myInput.tabIndex = -1
			this.setState({ updateAlternatePhone: 0 });
		}
		this.setState({alternatePhoneInputClassNameValue:myInput.className});
		this.props.isSaveAlternatePhoneClicked(false);
	}

	updateAlternateEmail(myInput) {
		if (myInput.className == "form-control editInput") {
			myInput.className = "form-control showUpdatebtn";
			myInput.disabled = false;
			myInput.tabIndex = 0
			myInput.focus();
			this.setState({ updateAlternateEmail: 1 });
			this.props.showErrorMessage("");
		}
		else {
			myInput.className = "form-control editInput";
			myInput.disabled = true;
			myInput.tabIndex = -1
			this.setState({ updateAlternateEmail: 0 });
		}
		this.setState({alternateEmailInputClassNameValue:myInput.className});
		this.props.isSaveAlternateEmailClicked(false);
	}

	attchDivFun(myInput) {
		//alert("myInput"+myInput.imgAttchInput)
		if (myInput.className == "imgAttchInput nodisplay") {
			myInput.className = "imgAttchInput";
		}
		else {
			myInput.className = "imgAttchInput nodisplay";
		}
	}

	// changeCP(e) {
	// 	//alert("e.target.value"+e.target.value);
	// 	updatedCP = e.target.value;
	// 	this.setState({
	// 		CPErrorColor: ""
	// 	});
	// 	console.log("titlestate==" + updatedCP)
	// }
	// changeMP(e) {
	// 	updatedMP = e.target.value;
	// 	this.setState({
	// 		MPErrorColor: ""
	// 	});
	// }

	// changeBP(e) {
	// 	updatedBP = e.target.value;
	// 	this.setState({
	// 		BPErrorColor: ""
	// 	});
	// }

	changeAlternatePhone(e) {
		// updatedAlternatePhone = e.target.value;
		this.props.updateAlternatePhone(e.target.value);
	}

	changeAlternateEmail(e) {
		// updatedAlternateEmail = e.target.value;
		this.props.updateAlternateEmail(e.target.value);
	}

	updateContactInfo(textInput4, textInput6) {

		// textInput1.className = "form-control editInput";
		// textInput2.className = "form-control editInput";
		// textInput3.className = "form-control editInput";

		

		// let BPValue = "";
		// let CPValue = "";
		// let MPValue = "";

		//=================
		// if (updatedBP == "") {
		// 	//alert("titleValue");
		// 	BPValue = this.props.profileDetails.businessPhone
		// } else {
		// 	BPValue = updatedBP
		// }
		//===================
		// if (updatedCP == "") {
		// 	//alert("titleValue");
		// 	CPValue = this.props.profileDetails.contactPhone
		// } else {
		// 	CPValue = updatedCP
		// }
		//===================
		// if (updatedMP == "") {
		// 	//alert("titleValue");
		// 	MPValue = this.props.profileDetails.mobilePhone
		// } else {
		// 	MPValue = updatedMP
		// }
		//=================
		let userId = this.props.profileDetails.userId;
		let AlternatePhoneValue = "";
		let AlternateEmailValue = "";
		if (this.state.updateAlternatePhone == "") {
			//alert("titleValue");
			AlternatePhoneValue = this.props.profileDetails.alternatePhone;
		}
		else {
			AlternatePhoneValue = this.state.updateAlternatePhone;
			let phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
			if (phoneRegex.test(AlternatePhoneValue) != true) {
				this.setState({ alternatePhoneError: this.props.tr['Please enter a valid phone number!'] });
				return false;
			} else {
				this.setState({ alternatePhoneError: '' });
			}
		}
		//=================
		if (this.state.updatedAlternateEmail == "") {
			//alert("titleValue");
			AlternateEmailValue = this.props.profileDetails.alternateEmail;
		}
		else {
			AlternateEmailValue = this.state.updatedAlternateEmail;
			if (EmailValidator.validate(AlternateEmailValue) == false) {
				this.props.showErrorMessage('Profile has been updated successfully');
				return false;
				// alert("Please enter a valid email address!")
			}
			else {
				this.setState({ alternateEmailError: '' });
			}
		}

		textInput4.className = "form-control editInput";
		textInput6.className = "form-control editInput";
		textInput4.disabled = true;
		textInput6.disabled = true;

		this.setState({ showLoader: true });
		//alert("updateContactInfo=======>"+MPValue+"-userId--"+userId);
		api.put(GLOBAL.profilePostUrl + userId, {
			// "businessPhone": BPValue,
			// "contactPhone": CPValue,
			// "mobilePhone": MPValue,
			"userId": userId,

			"alternatePhone": AlternatePhoneValue,
			"alternateEmail": AlternateEmailValue
		}).
			then((response) => {
				this.props.loadProfile();
				window.history.pushState({},"","/myProfile");
				//console.log("response status:"+response.statusText)
				if (response.status === 200) {
					this.setState({ showLoader: false, updateAlternatePhone: 0, updateAlternateEmail: 0 });
				}
				this.props.showErrorMessage('Profile has been updated successfully');
				//this.setState({contactSaveMsg:"Conact details have been updated successfully"})
				//
			});
	}

	// onLoadStart() {
	// 	setTimeout(this.applyApi.bind(this), 3000)
	// }
	applyApi() {
		// alert("Inside APPLYAPI")
		// alert("onLoadStart");
		// console.log(this.props.profileDetails.profilePicTempURL)
		this.setState({
			image: this.props.profileDetails.profilePicTempURL


		});
		// this.setState({
		// 	spinner: 1


		// });
		// // ReactDOM.render()
		// setTimeout(this.forceUpdate.bind(this), 000)
		// this.render();

	}
	// upImgChange(event) {
	// 	const file = event.target.files[0]

	// 	alert("Test")
	// 	debugger
	// }
	fileChangedHandler(event) {
		let companyID = this.props.profileDetails.companyId;
		let userID = this.props.profileDetails.userId
		// alert("Ytyuhb")
		const file = event.target.files[0];
		let formData = new FormData();
		formData.append('imageFiles', file);
		formData.append('companyID', companyID);
		formData.append('userID', userID);
		// this.UpdateProfile()
		// this.onLoadStart()
		axios.post("/upload/profile/Image", formData).then((response) => {
			if (response.status == 200) {
				this.props.loadProfile();
				//this.applyApi()
				//setTimeout(this.applyApi.bind(this), 4000)
			}
		})
			// .catch((error) => {


			// });
	}

	render() {
		let lessKey = "...";
		let lessKeyTr = this.props.tr["less"];
		lessKey = lessKey.concat(lessKeyTr);
		console.log("lesKey---",lessKey);
		// debugger
		//alert(this.state.updateCount)
		if (this.props.profileDetails.length == 0) {
			//alert("this.props.breakFixEditDetails.length"+this.props.breakFixEditDetails.length)
			return (
				<Spinner spinnerName="three-bounce" />
			);
		}
		let image = "";
		if (this.state.image == "") {
			image = this.props.profileDetails.profilePicTempURL;
		} else {
			image = this.state.image;
		}
		// let companyID = this.props.profileDetails.companyId;
		// let userID = this.props.profileDetails.userId

		// let imageURL = GLOBAL.profileDetailsImageUploadUrl + "/" + userID + "/" + companyID
		//alert("imageURL"+imageURL)
		//alert("companyID"+this.props.profileDetails.companyId)

		let groupNameTxt = ""
		if (this.props.profileDetails.groupName) {
			groupNameTxt = this.props.profileDetails.groupName.replace(/,/g, '<br>');
		}
		let groupNameCount = groupNameTxt.length;

		let roleTxt = this.props.profileDetails.roleName.replace(/,/g, '<br>');
		let roleTxtCount = roleTxt.length;
		const mystyle = {height: "60px",overflow: "hidden"};

		return (
      <div className="inLineLabel inLineLabDv1">
        {/* {this.props.showSavedMessage == "" || this.state.showSavedMessage == undefined ? null : <Alert variant="success" className="errorMessageSec margin-t-10"><p> {this.state.showSavedMessage}</p></Alert>} */}

        <h4>{this.props.tr["Contact Information"]}</h4>
        {/* <Form.Group className='form-group'>
					<Form.Label bsClass="">{this.props.tr['Contact Phone']}  {this.props.profileDetails.datasource!='Automated'?<i onClick={() => { this.updateContactPhone(this.textInput1) }} className="fa fa-pencil-square-o cursorPoint"></i>:""}</Form.Label>
					<input className="form-control editInput" disabled={this.props.profileDetails.datasource!='Automated'?false:true}  defaultValue={this.props.profileDetails.contactPhone} type="text" ref={(input) => { this.textInput1 = input; }} onChange={this.changeCP.bind(this)} />
				</Form.Group> */}
        <Form.Group className="form-group">
          <Form.Label bsClass="">{this.props.tr["Mobile Phone"]} </Form.Label>
          <input
            aria-label={this.props.tr["Mobile Phone"]}
            className="form-control editInput"
            disabled
            defaultValue={this.props.profileDetails.mobilePhone}
            type="text"
            ref={(input) => {
              this.textInput2 = input;
            }}
          />
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label bsClass="">{this.props.tr["Business Phone"]} </Form.Label>
          <input
            aria-label={this.props.tr["Business Phone"]}
            className="form-control editInput"
            disabled
            defaultValue={this.props.profileDetails.businessPhone}
            type="text"
            ref={(input) => {
              this.textInput3 = input;
            }}
          />
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label bsClass="">
            {this.props.tr["Alternate Phone"]}{" "}
            {this.props.profileDetails.datasource != "Automated" ? (
              <a
                title={this.props.tr["Edit"]}
                role="button"
                href={void 0}
                onClick={() => {
                  this.updateAlternatePhone(this.textInput4);
                }}
              >
               <HiOutlinePencilAlt />
              </a>
            ) : (
              ""
            )}
          </Form.Label>
          <input
            aria-label={this.props.tr["Alternate Phone"]}
            tabIndex={-1}
            className={
              this.props.saveAlternatePhoneClicked
                ? "form-control editInput"
                : this.state.alternatePhoneInputClassNameValue
            }
            disabled
            maxLength="16"
            defaultValue={this.props.profileDetails.alternatePhone}
            type="text"
            ref={(input) => {
              this.textInput4 = input;
            }}
            onChange={this.changeAlternatePhone.bind(this)}
          />
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label bsClass="">{this.props.tr["Email"]} </Form.Label>
          {/* <input className="form-control editInput" disabled defaultValue={this.props.profileDetails.email} type="text" ref={(input) => { this.textInput5 = input; }} /> */}
          <div className="formOpt">{this.props.profileDetails.email}</div>
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label bsClass="">
            {this.props.tr["Alternate Email"]}{" "}
            {this.props.profileDetails.datasource != "Automated" ? (
              <a
                title={this.props.tr["Edit"]}
                role="button"
                href={void 0}
                onClick={() => {
                  this.updateAlternateEmail(this.textInput6);
                }}
              >
                <HiOutlinePencilAlt />
              </a>
            ) : (
              ""
            )}
          </Form.Label>
          <input
            aria-label={this.props.tr["Alternate Email"]}
            tabIndex={-1}
            className={
              this.props.saveAlternateEmailClicked
                ? "form-control editInput"
                : this.state.alternateEmailInputClassNameValue
            }
            disabled
            defaultValue={this.props.profileDetails.alternateEmail}
            type="text"
            ref={(input) => {
              this.textInput6 = input;
            }}
            onChange={this.changeAlternateEmail.bind(this)}
          />
        </Form.Group>

        {/* <Form.Group className="form-group">
			<Form.Label bsClass="">{this.props.tr["Current Group Membership"]}</Form.Label>
			<div className="formOpt position-re">
				{this.props.profileDetails.groupName != null ?
					<>
						<div style={this.state.isLessMoreGroup ? null : (groupNameCount > 200 ? mystyle : null)} className="offTxt" dangerouslySetInnerHTML={{ __html: groupNameTxt }}></div>

						{groupNameCount > 200 ? <Button bsPrefix=" " title={this.state.isLessMoreGroup ? this.props.tr["less"] : this.props.tr["more"]} className="lessMoreBtnInline" onClick={this.setLessMoreGroupFun} bsClass="" >{this.state.isLessMoreGroup ? <span><i className='fa fa-angle-up margin-r-5'></i>{this.props.tr["less"]}</span> : <span><i className='fa fa-angle-down margin-r-5'></i>{this.props.tr["more"]}</span>}</Button> : null}
					</>
					// <ReactReadMoreLess 
					// 	charLimit={75}
					//     readMoreText={this.props.tr["more"]}
					//     readLessText={lessKey}
					// 	content={this.props.profileDetails.groupName}
					// />
				: null}
			</div>
        </Form.Group> */}

        {/* <Form.Group className="form-group">
        	<Form.Label bsClass="">{this.props.tr["Current Roles"]}</Form.Label>
			<div className="formOpt position-re">
				<div style={this.state.isLessMore ? null : (roleTxtCount > 200 ? mystyle : null)} className="offTxt" dangerouslySetInnerHTML={{ __html: roleTxt }}></div>

				{roleTxtCount > 200 ? <Button bsPrefix=" " title={this.state.isLessMore ? this.props.tr["less"] : this.props.tr["more"]} className="lessMoreBtnInline" onClick={this.setLessMoreFun} bsClass="" >{this.state.isLessMore ? <span><i className='fa fa-angle-up margin-r-5'></i>{this.props.tr["less"]}</span> : <span><i className='fa fa-angle-down margin-r-5'></i>{this.props.tr["more"]}</span>}</Button> : null} */}

				{/* <ReactReadMoreLess 
					charLimit={75}
					onShowMore={this.props.onChange}
					readMoreText={this.props.tr["more"]}
					readLessText={lessKey} 
					content={this.props.profileDetails.roleName}
				/> */}
			{/* </div>
        </Form.Group> */}

        {/* {this.state.alternatePhoneError === '' ? null : <Alert variant="danger" className="errorMessageSec">{this.state.alternatePhoneError}</Alert>}
				{this.state.alternateEmailError === '' ? null : <Alert variant="danger" className="errorMessageSec">{this.state.alternateEmailError}</Alert>} */}

        {/* {this.state.updateAlternatePhone == 1 || this.state.updateAlternateEmail == 1 ?

					<ButtonToolbar className="black"><Button bsSize="small" variant="primary"
						disabled={this.state.showLoader}
						onClick={() => this.updateContactInfo(this.textInput4, this.textInput6)} >
						{this.state.showLoader ? <i className="f-size-13 fa fa-spinner fa-spin"></i> : null} {this.props.tr['Update']}</Button>
					</ButtonToolbar>

					// <ButtonToolbar className="margin-t-15">
					// 	<Button variant="primary" onClick={() => { this.updateContactInfo(); }}>Update</Button>
					// </ButtonToolbar>
					: null} */}
      </div>
    );
	}
}
RightSideAction = reduxForm({
	form: 'fileUploadForm',
	enableReinitialize: true
})(RightSideAction)

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ loadProfile }, dispatch);
}

export default connect(null, mapDispatchToProps)(RightSideAction);
