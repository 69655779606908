
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import { navigationHooks } from '../../helpers/NavigationHook';
import Swal from 'sweetalert2';
const api = axios.create({
    headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

export const getCostCenterList = (page=0, limit=10, sortBy = "costcenterName", sortType = "asc", searchBy = "", searchByValue = "", status) => {
  return (dispatch) => {
    if(status && status.length > 0) {
      searchByValue = searchByValue.concat(searchByValue !== "" ? "|" : "", status.join(","));
      searchBy = searchBy.concat(searchBy !== "" ? "," : "", "status");
    }
    let payload = {
      sortValue: sortBy,
      sortType: sortType,
      page: page,
      limit: limit,
      searchBy: searchBy,
      searchByValue: searchByValue,
    };
    dispatch({ type: "FDN_DATA_FETCHING" });
    api
      .post("/api/searchCostCenterList", payload)
      .then((response) => {
        dispatch({ type: "COST_CENTER_LIST_FETCHED_SUCCESSFULLY", payload: response.data });
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
      });
  };
};
  
export function saveCostCenterAction(data) {
    return (dispatch) => {
        api.post("/api/saveCostcenter", data)
            .then((response) => {
              console.log("respomseCC+++",response);
              if (response.status == 201) {
                if (response.data.nameAlreadyExist === 'Name already exists') {
                  Swal.fire({
                    title: 'Cost Center already exists',
                  })
                }
                else {
                  navigationHooks.navigate("/costCenter");
                }
                } 
            })
            .catch((err) => {
                console.log("ResponseCCError", err)
            });
    };
}

export function updateCostCenterAction(data) {
    return (dispatch) => {
        api.post("/api/updateCostcenter", data)
            .then((response) => {
              console.log("respomseEDitCc+++",response);
              if (response.status == 201) {
                if (response.data.nameAlreadyExist === 'Name already exists') {
                  Swal.fire({
                    title: 'Cost Center already exists',
                  })
                } else {
                  navigationHooks.navigate("/costCenter");
                }
                } 
            })
            .catch((err) => {
                console.log("EditCCError", err)
            });
    };
  }


export const getEditCostCenterPageDetails = (id) => {
  return (dispatch) => {
    dispatch({ type: 'FDN_DATA_FETCHING'})
      api.get("/api/editCostcenter/"+id)
        .then((response) => {
          dispatch({ type: 'FDN_DATA_FETCHED_SUCCESS' })
              let data = {
                  ...response.data,
              }
              console.log("CheckData123",data)
          dispatch({ type: 'COST_CENTER_EDIT_DETAILS_FETCHED', payload:data.costcenter});
          dispatch({ type: 'CC_STATUS_DROPDOWN_LIST', payload: data.statusList });
        })
        .catch((err) => {
          dispatch({ type: 'FDN_DATA_FETCHED_FAILED'})
          console.log(err);
        });
    };
};
  
export const getCostCenterCompanyOptions = () => {
    return (dispatch) => {
      api.get("/api/getInitiallistCostCenter")
          .then((response) => {
            let object = [];
            const menuListoptions = response.data.listCompany;
            if (typeof menuListoptions !== "undefined" && menuListoptions.length > 0) {
              menuListoptions.forEach((values) => {
                let val = { id: values.id, label: values.name };
                object.push(val);
              });
              }
            console.log("CCResp", response.data)
            dispatch({ type: 'COMPANY_DROPDOWN_LIST', payload: object });
            dispatch({ type: 'CC_STATUS_DROPDOWN_LIST', payload: response.data.statusList });
        })
        .catch((err) => {
          console.log(err);
        });
    };
};
  

export const deleteCostCenterList = (id) => {
    return (dispatch) => {
      api.delete("/api/deleteCostcenter"+id)
        .then((response) => {
          Swal.fire({
            title: 'Record deleted successfully',
          }).then(result => {
            if (result.isDismissed || result.isConfirmed) {
              navigationHooks.navigate('/costCenter')
            }
          })
        })
        .catch((err) => {
          console.log(err);
        });
    };
  };
