
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export default function bulletinReducer(state = [], action){

	switch (action.type){
		case 'LOAD_BULLETIN_SUCCESS':
			return action.bulletin.data;
			default:
			return state;
	}

}
