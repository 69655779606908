
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import {GLOBAL} from '../../components/Globals';

export function loadBreakFixSGSuccess(breakFixSGName){
	return {
		type: 'LOAD_BREAKFIXSG_SUCCESS',
		breakFixSGName
	};
}

export function loadBreakFixSGFailure(errorResponse){
	return {
		type: 'LOAD_BREAKFIXSG_FAILURE',
		errorResponse
	};
}

export function loadBreakFixSG(serviceId){
	//alert("serviceId"+serviceId)
	return(dispatch) =>{
		if(serviceId == ""){
			console.log("serviceId not found");
		}else{
			//alert(GLOBAL.breakFixSGroupUrl+serviceId)
				axios.get(GLOBAL.breakFixSGroupUrl+serviceId)
					.then((breakFixSGName)=>{
						if(!breakFixSGName){
							throw Error(breakFixSGName.statusText);
						}
						return breakFixSGName;
					})
					.then((breakFixSGName) =>{
						dispatch(loadBreakFixSGSuccess(breakFixSGName))
					})
					.catch((err) => {
						console.log(err);
					});
				}

	};
}
