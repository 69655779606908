
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from "axios";
import { GLOBAL } from '_Globals';
import Cookies from 'universal-cookie';
import { navigationHooks } from "../../helpers/NavigationHook";
import swal from "sweetalert";
const cookies = new Cookies();
let companyId = cookies.get('gph');
if (companyId) companyId = companyId.replace('s:', '');
if (companyId) companyId = companyId.substring(0, companyId.lastIndexOf('.'));
companyId = companyId.split("~");
companyId = companyId[48];

const api = axios.create({
    headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});
export function postCompanyMenuList(data) {
    return (dispatch) => {
        api.post(GLOBAL.postCompanyMenuList, data)
            .then((response) => {
                if (response.status == 200 && response.data == 'Duplicate record.') {
                    swal({
                        text: "Record already exists",
                        button: 'OK',
                    });
                    return false;

                } else if (response.status == 200) {
                    navigationHooks.navigate("/CompanyMenulist");
                }
                swal({
                    text: "Successfully Created",
                    button: "OK",
                });
            })
            .catch((err) => {
                swal({
                    text: err.response.data,
                    button: 'OK',
                });
            });
    };
}
export function getCompanyMenuListData(companyId, module) {
    return (dispatch) => {
        let config = {
            headers: {
                'query': JSON.stringify({ "companyId": companyId, 'module': module}),
            }
        }
        if(module != undefined){
            dispatch({ type: "COMPANY_MENULIST_LOADER_IN_PROGRESS", payload: true });
            api.get(GLOBAL.getCompanyMenulistData, config)
                .then((response) => {
                    if (response.status == 200) {
                        dispatch({ type: "COMPANY_MENULIST_FIELD_DROPDOWN_DATA", payload: response.data });
                    }
                })
        }
        else{
        dispatch({ type: "COMPANY_MENULIST_LOADER_IN_PROGRESS", payload: true });
        api.get(GLOBAL.getCompanyMenulistData, config)
            .then((response) => {
                if (response.status == 200) {
                    dispatch({ type: "COMPANY_MENULIST_DROPDOWN_DATA", payload: response.data });
                    dispatch({ type: "COMPANY_MENULIST_DETAILS_FETCHED_SUCCESSFULLY", payload: false });
                }
            })
            .catch((err) => {
                dispatch({ type: "COMPANY_MENULIST_DETAILS_FETCHED_SUCCESSFULLY", payload: false });
                swal({
                    text: err.response.data,
                    button: 'OK',
                });
            });
    }};
}
export function getCompanyMenuListViewData(data) {
    return (dispatch) => {
        dispatch({ type: "COMPANY_MENULIST_LOADER_IN_PROGRESS", payload: true });
        api.post(GLOBAL.getCompanyMenuListView, data)
            .then((response) => {
                if (response.status == 200) {
                    dispatch({ type: "COMPANY_MENULIST_DATA_FETCHED_SUCCESSFULLY", payload: response.data.companyMenuList });
                    dispatch({
                        type: 'COMPANY_MENULIST_META',
                        payload: {
                            currentPage: parseInt(response.data.meta.currentPage, 10),
                            numberOfItems: parseInt(response.data.meta.rowCount, 10)
                        }
                    });
                    dispatch({ type: "COMPANY_MENULIST_DETAILS_FETCHED_SUCCESSFULLY", payload: false });
                }
            })
            .catch((err) => {
                dispatch({ type: "COMPANY_MENULIST_DETAILS_FETCHED_SUCCESSFULLY", payload: false });
                swal({
                    text: err.response.data,
                    button: 'OK',
                });
            });
    };
}
export function patchCompanyMenuList(data) {
    return (dispatch) => {
        dispatch({ type: "COMPANY_MENULIST_LOADER_IN_PROGRESS", payload: true });
        api.patch(GLOBAL.patchCompanyMenuList, data)
            .then((response) => {
                if (response.status == 200 && response.data == 'Duplicate record.') {
                    swal({
                        text: "Record already exists",
                        button: 'OK',
                    });
                    return false;

                } else if (response.status == 200) {
                    let filter = { page: 1, limit: 10 };
                    dispatch(getCompanyMenuListViewData(filter));
                }
                swal({
                    text: response.data,
                    button: "OK",
                });
                dispatch({ type: "COMPANY_MENULIST_DETAILS_FETCHED_SUCCESSFULLY", payload: false });
            })
            .catch((err) => {
                dispatch({ type: "COMPANY_MENULIST_DETAILS_FETCHED_SUCCESSFULLY", payload: false });
                swal({
                    text: err.response.data,
                    button: 'OK',
                });
            });
        }   
    }