
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from 'react';

import TableComponent from '../../../common/ReactTable/TableComponent';
import ListLoader from '../../../common/loaders/ListLoader';
import { useSelector } from 'react-redux';

const ListSec = (props) => {
    const [organizationData, setOrganizationData] = useState([]);

    const organizationListData = useSelector((state) => state.organizationData);
    const showLoader = useSelector((state) => state.commonLoader.isLoading);
  
    const onRowSelect = (row) => {
        props.companyDataHandler(row);
        props.showRightSideFun(true);
    }
    const tableColumns = [
        {
            name: props.translator["Name"],
            selector: (row) => row.orgn_name,
            sortable: true,
        },
        {
            name: props.translator["Company"],
            selector: (row) => row.company_name,
            sortable: true,
        },
        {
          name: props.translator["Status"],
          selector: (row) => row.status,
          sortable: true,
        }
    ];
    useEffect(() => {
        if (organizationListData.length > 0) {
          setOrganizationData(organizationListData.map(obj => {
            obj.status = obj.status === '1' ? "Active" : "Inactive" 
          }))
        }
    }, [organizationListData])




    const filterColumns = ["orgn_name","status","company_name","dept_name"];
    // console.log("OrgListData",organizationListData)
    const renderOrganizationTableData = () => {
        return (
            <div className="dtaTableDv integrateHubTable">
                <div className={props.isRightSideVisible ? "leftTrue srchPosTop fdnPos" : "rightTrue srchPosTop fdnPos"}>
                    <TableComponent
                        data={organizationListData}
                        columns={tableColumns}
                        headerColumns={filterColumns}
                        onRowSelect={onRowSelect}
                        rightDisplay={props.isRightSideVisible}
                        uniqueKey={'id'}
                    />
                </div>
            </div>
        );
    }

    if (!organizationListData) return null;
    if (organizationListData.length == undefined) {
      return <ListLoader />;
    } else if (organizationListData.length === 0) {
      return (
        renderOrganizationTableData()
      )
    } else {
      return (
        <div className="myOrderDiv">
          {
          showLoader ? (
            <ListLoader />
          ) : (
            renderOrganizationTableData()
          )
          }
        </div>
      );
    }
}

export default ListSec;