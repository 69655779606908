
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, ListGroup } from 'react-bootstrap';
import DotsMenu from '_Images/dotsMenu.png';
import {useLocation, useNavigate, useParams,Link } from 'react-router-dom';
import axios from 'axios';
import { GLOBAL } from "_Globals";
import { LuChevronRight, LuChevronLeft } from "react-icons/lu";
import { LuSearch } from "react-icons/lu";

import listenForOutsideClicks from "../clickoutside/listenForOutsideClicks.js";
import { setWipSearchApply } from "../../../actions/globalSearch/globalSearchAction.js";
import { TbArrowRampRight, TbHourglassEmpty } from "react-icons/tb";
import { TbArrowRampLeft } from "react-icons/tb";
import { TbArrowsMinimize } from "react-icons/tb";
import { VscPinned } from "react-icons/vsc";
import { TbPinnedOff } from "react-icons/tb";
import { GiHamburgerMenu } from "react-icons/gi";

import Cookies from 'universal-cookie';
const cookies = new Cookies();
let cookieString = cookies.get('gph');
if (cookieString) cookieString = cookieString.replace('s:', '');
if (cookieString) cookieString = cookieString.substring(0, cookieString.lastIndexOf('.'));

let cookieArray = cookieString.split("~");
let user_roleId = cookieArray[33];
let foundationUrl = cookieArray[35];
let sla_work_holiday = cookieArray[36];
let emailAddress = cookieArray[29];
let roarUrl = cookieArray[54];

import { CgMenuGridO, CgArrowTopRightR } from "react-icons/cg";
import { HiOutlineShoppingBag, HiOutlineDatabase, HiOutlineBriefcase, HiOutlineChartPie } from 'react-icons/hi';
import { LuUmbrella, LuGalleryVertical, LuArchive, LuListTodo, LuCalendarClock, LuLayoutPanelTop } from 'react-icons/lu';
import { BiChalkboard, BiBell, BiCube, BiHourglass } from 'react-icons/bi';
import { TbDatabaseSearch, TbReportSearch, TbChartDots, TbMailCog, TbLayersLinked } from 'react-icons/tb';
import { PiCommand, PiGoogleCardboardLogoBold, PiGearSixBold } from 'react-icons/pi';
import { RiFilePaper2Line, RiPencilRuler2Line, RiUserSettingsLine, RiBookmark3Line, RiSearch2Line } from 'react-icons/ri';
import { MdOutlineColorLens } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { GET_PUBLISHED_PAGES } from "../../uiBuilder/graphQl/GraphqlQueries.js";
import { useQuery } from "@apollo/client";
import { openLinkInNewTab } from "../helper.js";
import _ from "lodash";

const DynamicIcon = ({ iconName }) => {
    switch (iconName) {
        case 'HiOutlineShoppingBag':
            return <HiOutlineShoppingBag />
        case 'HiOutlineDatabase':
            return <HiOutlineDatabase />
        case 'HiOutlineBriefcase':
            return <HiOutlineBriefcase />
        case 'LuUmbrella':
            return <LuUmbrella />
        case 'LuGalleryVertical':
            return <LuGalleryVertical />
        case 'LuArchive':
            return <LuArchive />
        case 'LuListTodo':
            return <LuListTodo />
        case 'BiChalkboard':
            return <BiChalkboard />
        case 'PiCommand':
            return <PiCommand className="customIcon" />
        case 'BiBell':
            return <BiBell />
        case 'BiCube':
            return <BiCube />
        case 'TbDatabaseSearch':
            // return <TbReportSearch className="customIcon3" />
            return <HiOutlineChartPie/>
        case 'TbReportSearch':
            return <TbReportSearch />
        case 'TbChartDots':
            return <TbChartDots />
        case 'TbMailCog':
            return <TbMailCog />
        case 'LiaHourglass':
            return <BiHourglass />
        case 'TbLayersLinked':
            return <TbLayersLinked className="customIcon" />
        case 'RiPencilRuler2Line':
            return <RiPencilRuler2Line />
        case 'LuCalendarClock':
            return <LuCalendarClock />
        case 'MdOutlineColorLens':
            return <MdOutlineColorLens />
        case 'RiFilePaper2Line':
            return <RiFilePaper2Line className="customIcon2" />
        case 'RiUserSettingsLine':
            return <RiUserSettingsLine />
        case 'PiGoogleCardboardLogoBold':
            return <PiGoogleCardboardLogoBold />
        case 'RiBookmark3Line':
            return <RiBookmark3Line />
        case 'CgArrowTopRightR':
            return <CgArrowTopRightR />
        case 'PiGearSixBold':
            return <PiGearSixBold />
        case 'LuLayoutPanelTop':
            return <LuLayoutPanelTop />
        default:
            return <HiOutlineShoppingBag />
    }
}

const ModulesHamburger = (props) => {
    const menuRef = useRef(null);
    const [listening, setListening] = useState(false);
    const [showResults, setShowResults] = useState(false);
    const [myString, setMyString] = useState("");
    const [showMenuName, setMenuName] = useState(false);
    const [showMenuPopup, setOnClickMenuPopup] = useState(false);
    const [currentLocation, setCurrentLocation] = useState("");

    const location = useLocation().pathname.slice(1);
    const tr = useSelector((state) => state.spcmReducer.tr);
    const rightSidebarMenulist = useSelector((state) => state.rightSidebarMenulist);
    const { data: publishedPages, loading: publishedPagesLoading, error: publishedPagesError } = useQuery(GET_PUBLISHED_PAGES, { variables: { status: 'Deployed' } });

    let menuName = (value) => {
        setMenuName(value);
    }

    let onHamburgerBtnClick = (id) => {
        setOnClickMenuPopup(id);
        setShowResults(id);
    }
    let callbackFun = (id) => {
        setOnClickMenuPopup(id);
        setShowResults(id);
    }

    useEffect(() => {
        if (!showMenuPopup) {
            setMyString("");
        }
    }, [showMenuPopup]);

    useEffect(() => {
        setCurrentLocation(location);
      }, [location]);

    let handleKeyPress = (e) => {
        if (
            e.code == "Space" ||
            e.which == 32 ||
            e.code == "Enter" ||
            e.which == 13
        ) {
            onHamburgerBtnClick();
        }
    }
    useEffect(listenForOutsideClicks(listening, setListening, menuRef, setShowResults));


    return (
        <div ref={menuRef} className={showMenuPopup ? "transAppy hide" : (showMenuName ? "transAppy show":"transAppy hide")}>
            {/* <Button
                id="AppModulesBtn"
                title={tr["Application Menu"]}
                bsPrefix=" "
                onKeyPress={() => { handleKeyPress(); }}
                onClick={onHamburgerBtnClick}
            >
                <CgMenuGridO className="dotsMenu" />
            </Button> */}
            <HamburgerResult publishedPages={publishedPages} tr={tr} isOpen={showResults} rightSidebarMenulist={rightSidebarMenulist} fun={callbackFun} clientLogoNew={props.clientLogoNew} showMenuName={showMenuName} menuName={menuName} setMyString={setMyString} myString={myString} showMenuPopup={showMenuPopup} setOnClickMenuPopup={setOnClickMenuPopup} onHamburgerBtnClick={onHamburgerBtnClick} currentLocation={currentLocation} />
        </div>
    );
}

let NestedComponent = (props) => {
    const [showSubMenu, setShowSubMenu] = useState(false);
    const [activeChild, setActiveChild] = useState(null);
    let iconArray = props.module.functionIcon.split(" ");
    const { publishedPages } = props;

    useEffect(() => {
        if (!props.showMenuName && props.myString == "") {
            setShowSubMenu(false);
        }
    }, [props.showMenuName]);

    useEffect(() => {
        if (!props.isOpen) {
            props.setOnClickMenuPopup(false);
            setShowSubMenu(false);
        }
    }, [props.isOpen]);

    useEffect(() => {
        if (_.filter(props.module.children, { isMatched: true }).length > 0) {
            setShowSubMenu(true);
        } else {
            setShowSubMenu(false);
        }
    }, [props.module.children]);

    useEffect(() => {
        const activeChild = props.module.children.find(child => child.functionAction === props.currentLocation);
        setActiveChild(activeChild);
    }, [props.currentLocation, props.module.children]);

    return (
        <ListGroup.Item key={props.key} as="li" bsPrefix=' ' className={showSubMenu ? "rghArow rghArowRotate" : "rghArow"}>
            <Link className={activeChild ? "active" : ""} id={"appMods_" + props.module.functionId} onClick={() => { setShowSubMenu(!showSubMenu);props.inputRef.current.focus();if(!props.showMenuName){props.onHamburgerBtnClick(true);}}}  to={void (0)} title={props.module.functionName}>
                <span className="appIcon"><DynamicIcon iconName={props.module.functionIcon} /></span>
                <span className={props.showMenuName || props.myString != "" ? "apptransTxt show" : "apptransTxt"}>{props.module.functionName.length > 25 ?
                    (props.module.functionName.slice(0, 25) + "...") : props.module.functionName}</span>
            </Link>
            {showSubMenu && <ListGroup key="1" as="ul" bsPrefix=' ' className='sublnk'>
                {props.module.children.map(child => {
                    let iconArrayChild = child.functionIcon.split(" ");
                    const isActive = child.functionAction === props.currentLocation;
                    if (props.module.functionId == 159) {
                        if (props.userRoles.includes("60") && child.functionId !== 161) {
                            return (
                                <ListGroup.Item as="li" bsPrefix=' ' className={isActive ? "active" : ""}>
                                    <Link id={"appMods_" + child.functionId} onClick={() => { props.setMyString(""); props.fun(false); props.refLogo.current.focus();}}  to={`/${child.functionAction}`} title={child.functionName}>
                                        <span>
                                            {child.functionName.length > 25 ?
                                                (child.functionName.slice(0, 25) + "...") : child.functionName}
                                        </span>
                                    </Link>
                                </ListGroup.Item>
                            )
                        }
                        else if (!props.userRoles.includes("60") && child.functionId == 161) {
                            return (
                                <ListGroup.Item as="li" bsPrefix=' ' className={isActive ? "active" : ""}>
                                    <Link id={"appMods_" + child.functionId} onClick={() => { props.setMyString(""); props.fun(false); props.refLogo.current.focus(); }}  to={`/${child.functionAction}`} title={child.functionName}>
                                        <span>
                                            {child.functionName.length > 25 ?
                                                (child.functionName.slice(0, 25) + "...") : child.functionName}
                                        </span>
                                    </Link>
                                </ListGroup.Item>
                            )
                        }
                    }
                    else {
                        return (
                            <ListGroup.Item as="li" bsPrefix=' ' className={isActive ? "active" : ""}>
                                <Link id={"appMods_" + child.functionId} onClick={() => { props.setMyString(""); props.fun(false); props.refLogo.current.focus(); }}  to={`/${child.functionAction}`} title={child.functionName}>
                                    <span>
                                        {child.functionName.length > 25 ?
                                            (child.functionName.slice(0, 25) + "...") : child.functionName}{child.functionId === 66 && <sup style={{ color: 'red' }}>(Beta)</sup>}
                                    </span>
                                </Link>
                            </ListGroup.Item>
                        )
                    }
                })}
            </ListGroup>}
        </ListGroup.Item>
    )
}

let HamburgerResult = (props) => {
    const refLogo = useRef(null);
    const inputRef = useRef(null);
    const [encryptedCode, setEncryptedCode] = useState('');
    const [modules, setModules] = useState([])
    const [filteredModules, setFilteredModules] = useState([])
    const dispatch = useDispatch();
    const { publishedPages } = props;

    const [isShow, setIsShow] = useState(false);

    const searchMyString = (e) => {
        props.setMyString(e.target.value);
        const list = findChild(modules, e.target.value);
        setFilteredModules(list);
    }
    const clearSearchString = (e) => {
        e.stopPropagation();
        props.setMyString("");
    }

    const findChild = (arr, str) => {
        const lowerStr = str.toLowerCase();

        const search = (nodes) => {
            return _.reduce(
                nodes,
                (acc, node) => {
                    let matchedNode = null;
                    if (
                        node.functionName &&
                        node.functionName.toLowerCase().includes(lowerStr)
                    ) {
                        matchedNode = _.cloneDeep(node);
                        matchedNode.isMatched = true;
                    }
                    if (node.children && node.children.length > 0) {
                        const childResults = search(node.children);

                        if (!_.isEmpty(childResults)) {
                            if (!matchedNode) {
                                matchedNode = _.cloneDeep(node);
                            }
                            matchedNode.children = childResults;
                        }
                    }
                    if (matchedNode) {
                        acc.push(matchedNode);
                    }

                    return acc;
                },
                []
            );
        };

        return search(arr);
    };

    useEffect(() => {
        axios.get(`/api/getEnryptedData`).then(resp => {
            setEncryptedCode(resp.data.encryptedCode);
        });
    }, []);

    useEffect(() => {
        let modules = props.rightSidebarMenulist;
        modules.map(element => {
            let childArray = modules.filter(child => Number(child.parentFunctionId) === element.functionId);
            element.children = childArray;
            if (childArray.length > 0) {
                childArray.map(child => {
                    modules.map((module, index) => {
                        if (child.functionId === module.functionId) {
                            modules.splice(index, 1);
                        }
                    })
                })
            }
        });

        setModules(modules);
    }, [props.rightSidebarMenulist]);

    let handleOnClick = (functionId) => {
        props.setMyString("");
        if (user_roleId.includes('5')) {
            GLOBAL.activeCategory = 'service';
        } else {
            GLOBAL.activeCategory = 'category';
        }

        dispatch(setWipSearchApply(false));
        if (functionId == 65) {
            window.open(roarUrl + "/login?token=" + encryptedCode, "_blank");
            // openLinkInNewTab(roarUrl + "/login?token=" + encryptedCode, "New Tab", "origin");
            props.fun(false);
            refLogo.current.focus();
        }
        else if (functionId == 63) {
            openLinkInNewTab(foundationUrl, "New Tab")
            props.fun(false);
            refLogo.current.focus();
        }
        else {
            props.fun(false);
            refLogo.current.focus();
        }
    }

    useEffect(() => {
        if (!props.isOpen) {
            props.setOnClickMenuPopup(false);
        }
    }, [props.isOpen])

    let iconArray = [];
    let isWorkScheduleTrue;

    return (
        <div id="" className={props.showMenuPopup  ? "appMenuPopup" : (props.showMenuName ? "iconSidebar show" : "iconSidebar")}>
            <div>
                <div className="popupLogo">
                    <input aria-label="Remove Focus" className='foucInpt' type="text" autoComplete="off" ref={refLogo} />
                    <div title="XSM Logo" className="pLogo" style={props.clientLogoNew}></div>
                </div>

                {props.showMenuPopup || props.showMenuName ?
                <div className="collapseBtnDv">
                    {props.showMenuPopup || props.showMenuName || props.myString != "" ?
                    (props.showMenuPopup ? <Button onClick={() => {props.menuName(true);props.setMyString("");props.onHamburgerBtnClick(false); }} ><VscPinned /></Button>:<Button onClick={() => {props.menuName(false);props.setMyString(""); }} ><TbPinnedOff /></Button>)
                    : null }
                </div>:null}
                
                <div className={props.showMenuPopup || props.showMenuName?"modulePopupSerch show":"modulePopupSerch"}>
                    <div className="luSrch"><Button className={props.showMenuPopup || props.showMenuName ? "visibleFalse bnmenu" :"visibleTrue bnmenu"} title={props.tr["Application Menu"]} bsPrefix=" " onClick={()=>{if(!props.showMenuName){props.onHamburgerBtnClick(true);inputRef.current.focus();}}}><GiHamburgerMenu /></Button><LuSearch className={props.showMenuPopup || props.showMenuName ? "visibleTrue" : "visibleFalse"} /></div>

                    <Form.Control readOnly={props.showMenuPopup || props.showMenuName?false:true} ref={inputRef}  value={props.myString} onChange={(e) => { searchMyString(e) }} type="text" />
                    {props.myString == "" ? null : <Button title="Clear" bsPrefix=" " onClick={clearSearchString}  ><IoClose /></Button>}
                </div>

                <ListGroup as={"ul"} bsPrefix=' ' className="othrLnkNav">
                    {(props.myString == "" ? modules : filteredModules).map(function (value, index) {
                        iconArray = value.functionIcon.split(" ");
                        isWorkScheduleTrue = ((value.functionId == "42" || value.functionId == "43") && (sla_work_holiday.toLowerCase() == false || sla_work_holiday.toLowerCase() == 'false'))
                        return (
                            (value.functionId == "25" || value.functionId == "26" || value.functionId == "33" || value.functionId == "53" || (user_roleId.includes('45') && value.functionId == "52") || (user_roleId.includes('5') && value.functionId == "52") || isWorkScheduleTrue) ? "" :
                                value.children.length > 0 ?
                                    <NestedComponent publishedPages={publishedPages} key={index} module={value} fun={props.fun} refLogo={refLogo} isOpen={props.isOpen} userRoles={user_roleId} setMyString={props.setMyString} showMenuName={props.showMenuName} myString={props.myString} setOnClickMenuPopup={props.setOnClickMenuPopup} onHamburgerBtnClick={props.onHamburgerBtnClick} showMenuPopup={props.showMenuPopup} currentLocation={props.currentLocation} inputRef={inputRef} />
                                    :
                                    <ListGroup.Item key={index} as="li" bsPrefix=' ' className={value.functionAction == (props.currentLocation) ? "active" : ""}>
                                        <Link id={"appMods_" + index} onClick={() => { handleOnClick(value.functionId);props.fun(false); refLogo.current.focus(); }} to={(value.functionId !== 65 && value.functionId !== 63) ? "/" + value.functionAction : void (0)} title={value.functionName}>
                                            <span className="appIcon"><DynamicIcon iconName={value.functionIcon} /></span>
                                            <span className={props.showMenuName || props.myString != "" ? "apptransTxt show" : "apptransTxt"}>{value.functionName.length > 25 ? (value.functionName.slice(0, 25) + "...") : value.functionName}</span>
                                        </Link>
                                    </ListGroup.Item>
                        );
                    }, this)}
                </ListGroup>

                
            
            </div>
        </div>
    );
};

export default ModulesHamburger;