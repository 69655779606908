
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import { gql } from "@apollo/client";

export const GetSystemConstants = gql`
    query systemConstants {
        systemConstants {
            _id
            title
            key
            type
            definition
            modifiedBy
            updatedAt
        }
    }
`

export const CreateSystemConstant = gql`
    mutation CreateSystemConstant($input: CreateSystemConstantInput!){
        createSystemConstant(systemConstant: $input){
            title
        }
    }
`

export const UpdateSystemConstant = gql`
    mutation UpdateSystemConstant($id: String!, $input: UpdateSystemConstantInput!){
        updateSystemConstant(id: $id, systemConstant: $input){
            title
        }
    }
`

export const DeleteSystemConstant = gql`
    mutation DeleteSystemConstant($id: String!){
        deleteSystemConstant(id: $id){
            _id
        }
    }
`