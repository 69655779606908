
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import {Row, Col, Button,Form, ListGroup,ButtonToolbar } from "react-bootstrap";
import { useDispatch, useSelector, connect } from "react-redux";
import { Field, reduxForm, formValueSelector, change, SubmissionError, reset } from "redux-form";
import {_passwordField, _inputField, _dropDown, _checkbox, _number } from "../../../common/formFields";
import moment from "moment";
import Cookies from "universal-cookie";
import { _dateTimeFieldGeneral } from "../../../common/ReduxFormFields/formFields";
import onCallDropdownPlusTypeahead from "../../common/onCallDropdownPlusTypeahead";
import { onCallGroupUsersByGroupId } from "../../../../actions/onCallSchedule/common/onCallCommonAction";
import { IoClose, IoSwapHorizontal } from "react-icons/io5";
import { loadShiftListByGroup } from "../../../../actions/onCallSchedule/calendar/onCallCalendarAction";
import { loadProfileTimeZone } from "_Actions/myProfile/profileTimeZoneAction";
import Swal from "sweetalert2";
import axios from "axios";
import { PiSwap } from "react-icons/pi";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';
const cookies = new Cookies();
let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:","");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
  dateformat = dateformat.split("~");
  dateformat = dateformat[2];
  dateformat = dateformat.slice(0, 10).toUpperCase();

const validate = (values) => {
  const errors = {};
  if (!values.swap_on) {
    errors.swap_on = true;
  }
  if (!values.user_shift) {
    errors.user_shift = "Plesse select the shift";
  }
  if (!values.swap_shift) {
    errors.swap_shift = "Plesse select the shift";
  }
  return errors;
};

const SwapShift = (props) => {
  const dispatch = useDispatch();
  const translator = useSelector((state) => state.spcmReducer.tr);
  const [userErrorState, setUserErrorState] = useState(false);
  const [selectedUserList, setSelectedUserList] = useState([]);
  const [userInputValue, setUserInputValue] = useState("");
  const [userListOptions, setUserListOptions] = useState([]);
  const [swapWithError, setSwapWithError] = useState(false);
  const [selectedSwapWithList, setSelectedSwapWithList] = useState([]);
  const [swapWithInputValue, setSwapWithInputValue] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [userShiftOptions,setUserShiftOptions] = useState([]);
  const [swapUserShiftOptions,setSwapUserShiftOptions] = useState([]);
  const [selectedDate,setSelectedDate] = useState(moment());
  const { handleSubmit, error } = props;

  const userList = useSelector((state) => state.onCallGroupUsersList);
  const shiftList = useSelector((state) => state.shiftListByGroupId);
  const context = useContext(MyContext);
  const closeButton = useRef(null);

  useEffect(() => {
      context.keyDownHandler(closeButton.current,"","","");
  }, []);

  useEffect(() => {
    if (userList && userList.length > 0) {
      let userListFields = [];
      userList.forEach((list) => {
        userListFields.push({ id: list.field1Value, label: list.field1Key });
      });
      setUserListOptions(userListFields);
    }
  }, [userList]);

  useEffect(() => {
    dispatch(change("SwapShift", "user", ""));
    dispatch(change("SwapShift", "swap_on", moment()));
  }, []);

  useEffect(() => {
    if (props.selectedGroupList && props.selectedGroupList.length > 0) {
      dispatch(loadShiftListByGroup(props.selectedGroupList[0].id));
    } else {
      setUserListOptions([]);
      dispatch({
        type: "ON_CALL_CALENDER_SHIFT_LIST_DATA_BY_GROUP",
        events: { data: [] },
      });
    }
  }, [props.selectedGroupList]);

  function onSwapWithSelection(selectedSwapUser) {
    setSwapWithError("");
    setSelectedSwapWithList(selectedSwapUser);
    if (selectedSwapUser.length > 0) {
      dispatch(change("SwapShift", "swap_user", selectedSwapUser[0].id));
      setSwapUserShiftOptions([]);
      getShiftDetails("swapShift",selectedSwapUser[0].id);
      setSwapWithInputValue(selectedSwapUser[0].label);
    } else {
      setSwapUserShiftOptions([]);
      dispatch(change("SwapShift", "swap_user", ""));
    }
  }

  function setSwapWithErrorColor() {
    if (selectedSwapWithList.length === 0) setSwapWithError("error");
    else setSwapWithError("");
  }

  function onSwapWithInputChange(val) {
    setSwapWithInputValue(val);
  }

  function onCrossClickSwapWith() {
    dispatch(change("SwapShift", "swap_user", ""));
    setSwapWithInputValue("");
    setSelectedSwapWithList([]);
    setSwapUserShiftOptions([]);
    dispatch(change("SwapShift", "swap_shift", ""));
    setSwapWithError("error");
  }

  const getShiftDetails = (type, userId) => {
    if (props.selectedGroupList && props.selectedGroupList.length > 0) {
      const groupId = props.selectedGroupList[0].groupId;
      if (selectedDate) {
        const date = selectedDate.valueOf();
        axios
          .get(`/rest/aiosem/getUserShiftList/${groupId}/${userId}/${date}`)
          .then((res) => {
            let data = res.data?.data;
            if (type == "swapShift") {
              setSwapUserShiftOptions(data);
            } else if (type == "userShift") {
              setUserShiftOptions(data);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      else{
        Swal.fire({title:"Please select swap on date first!"});
      }
    }
  };

  const onUserSelection = (event) => {
    if (event && event[0]) {
      setUserInputValue(event[0].label);
      setUserShiftOptions([]);
      getShiftDetails("userShift",event[0].id);
      dispatch(change("SwapShift", "user", event[0].id));
    } else {
      setUserShiftOptions([]);
      dispatch(change("SwapShift", "user", ""));
    }
    setSelectedUserList(event);
  };

  const setCompanyError = () => {
    if (selectedUserList.length === 0) {
      setUserErrorState(true);
    } else {
      setUserErrorState(false);
    }
  };

  const onUserInputChange = (value) => {
    if (value) {
      setUserInputValue(value);
    } else {
      setUserInputValue("");
      onUserCrossClick();
    }
  };

  const onUserCrossClick = () => {
    setSelectedUserList([]);
    setUserShiftOptions([]);
    dispatch(change("SwapShift", "user_shift", ""));
    dispatch(change("SwapShift", "user", ""));
    setUserInputValue("");
  };

  const validateFields = (values) => {
    let error = false;

    if (!values.user) {
      setCompanyError();
      error = true;
    }
    if (!values.swap_user) {
      setSwapWithErrorColor();
      error = true;
    }
    return error;
  };

  const formSubmit = (values) => {
    if (validateFields(values)) {
      throw new SubmissionError({
        _error:
          "The form has missing required fields, please revalidate the highlighted fields and update the form",
      });
    }

    if (values.swap_user == values.user) {
      Swal.fire({ title: "Both users should not be same!" });
      return;
    }

    if (values.user_shift == values.swap_shift) {
      Swal.fire({ title: "Both shifts should not be same!" });
      return;
    }

    let postJson = {};

    postJson["currentUserId"] = selectedUserList[0].id;
    postJson["currentUserName"] = selectedUserList[0].label;
    postJson["currentGroupId"] = props.selectedGroupList[0].id;
    postJson["currentGroupName"] = props.selectedGroupList[0].label;
    postJson["currentShiftId"] = values.user_shift;
    postJson["swapWithShiftId"] = values.swap_shift;
    postJson["swapWithUserName"] = selectedSwapWithList[0].label;
    postJson["swapWithUserId"] = selectedSwapWithList[0].id;
    postJson["swapWithGroupId"] = props.selectedGroupList[0].id;
    postJson["swapWithGroupName"] = props.selectedGroupList[0].label;
    postJson["swapOnDate"] = values.swap_on.format("x");

    setIsButtonDisabled(true);
    axios
      .patch("/rest/aiosem/shiftSwapAction", postJson)
      .then((shiftAssigned) => {
        if (!shiftAssigned) {
          throw Error("Watcher details not found.");
        }
        return shiftAssigned;
      })
      .then((shiftAssigned) => {
        Swal.fire({
          title: "Shift has been swaped successfully!",
        });
        props.resetFilters();
        props.showRightSideFun("");
        setIsButtonDisabled(true);
      })
      .catch((error) => {
        setIsButtonDisabled(false);
        Swal.fire({ title: error.message });
        console.log("error", error);
      });
  };

  const onDateChange = (e)=>{
    setSelectedDate(e);
  }

  return (
    <>
      <Row>
        <Col xs={12}>
          <div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
            <ListGroup bsPrefix=" " as="ul">
              <ListGroup.Item as="li" bsPrefix=" ">
              <Link
                  to="javascript:void(0)"
                  bsPrefix=" "
                  title={translator["Minimize the details panel"]}
                  onClick={() => {
                    props.showRightSideFun("");
                  }}
                  ref={closeButton}
                >
                  <IoClose />
                </Link>
              </ListGroup.Item>
            </ListGroup>
          </div>
          <div className="rPageHeading">
            <div className="offNam margin-t-5 margin-b-5">{"Swap Shift"}</div>
          </div>
        </Col>
      </Row>
      <div className="rBoxGap">
        <div className="rBoxTabs">
          <div>
            <font color="red">{error && <strong>{error}</strong>}</font>
          </div>
          <Row>
            <Col md={12} sm={12} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>
                  <span className="rStar"></span>
                  {"Swap On"}
                </Form.Label>
                <div className="dateTimeSdiv">
                  <Field
                    name="swap_on"
                    component={_dateTimeFieldGeneral}
                    formType="create"
                    className="form-control dateTimePickerField"
                    defaultValue={moment().format(dateformat)}
                    timeFormat="false"
                    onChange={onDateChange}
                  />
                </div>
              </Form.Group>
            </Col>
            <Col md={6} sm={12} xs={12}>
              <Row>
                <Col md={12} sm={12} xs={12}>
                  <Form.Group className="form-group">
                    <Form.Label>
                      <span className="rStar"></span>
                      {"User"}
                    </Form.Label>
                    <Field
                      component={onCallDropdownPlusTypeahead}
                      errorColor={userErrorState ? "error" : ""}
                      onSelection={onUserSelection}
                      setErrorColor={setCompanyError}
                      name="user"
                      id="user"
                      className="form-control"
                      options={userListOptions}
                      selectedOptions={selectedUserList}
                      onInputChange={onUserInputChange}
                      inputValue={userInputValue}
                      onCrossClick={onUserCrossClick}
                    />
                  </Form.Group>
                </Col>
                <Col md={12} sm={12} xs={12}>
                  <Form.Group className="form-group">
                    <Form.Label>
                      <span className="rStar"></span>
                      {translator["Shift"]}
                    </Form.Label>
                    <Field
                      name="user_shift"
                      component={_dropDown}
                      options={Array.isArray(userShiftOptions) ? userShiftOptions : []}
                      className="form-control"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col md={6} sm={12} xs={12}>
              
              <Row>
                <Col md={12} sm={12} xs={12} style={{position:"relative"}}>
                <span className="custom-swap-separator"><IoSwapHorizontal/></span>
                  <Form.Group className="form-group">
                    <Form.Label>
                      <span className="rStar"></span>
                      {"User"}
                    </Form.Label>
                    <Field
                      component={onCallDropdownPlusTypeahead}
                      errorColor={swapWithError ? "error" : ""}
                      onSelection={onSwapWithSelection}
                      setErrorColor={setSwapWithErrorColor}
                      name="swap_user"
                      id="swap_user"
                      className="form-control"
                      options={userListOptions}
                      selectedOptions={selectedSwapWithList}
                      onInputChange={onSwapWithInputChange}
                      inputValue={swapWithInputValue}
                      onCrossClick={onCrossClickSwapWith}
                    />
                  </Form.Group>
                </Col>
                <Col md={12} sm={12} xs={12}>
                  <Form.Group className="form-group">
                    <Form.Label>
                      <span className="rStar"></span>
                      {translator["Shift"]}
                    </Form.Label>
                    <Field
                      name="swap_shift"
                      component={_dropDown}
                      options={Array.isArray(swapUserShiftOptions) ? swapUserShiftOptions : []}
                      className="form-control"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ButtonToolbar>
                <Button
                  onClick={handleSubmit(formSubmit)}
                  bsSize="xsmall"
                  className="rgSidrkBtn smallBtn"
                  title={"Swap"}
                  disabled={isButtonDisabled}
                >
                  {"Swap"}
                </Button>
                <Button
                  onClick={() => {
                    props.showRightSideFun("");
                  }}
                  bsSize="xsmall"
                  className="rgSiLigBtn smallBtn"
                  title={translator["Cancel"]}
                  disabled={isButtonDisabled}
                >
                  {translator["Cancel"]}
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default reduxForm({
  form: "SwapShift",
  destroyOnUnmount: true,
  validate,
  enableReinitialize: true,
})(SwapShift);
