
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState, useRef } from 'react';

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Alert from 'react-bootstrap/Alert';
import { useDispatch, useSelector } from 'react-redux';
import {_inputField, _dropDown, _checkbox,TypeaheadExampleSingleSelect } from '../../common/formFields';
import {getUrgencyImpactForSystemConfig, resetUrgencyImpactForSystemConfig} from '../../../actions/systemConfig/systemConfigAction'
import axios from 'axios';
import { Field, change, formValueSelector, reduxForm } from 'redux-form';
import { getCompanyList } from '../../../actions/spcmActions';
import IncidentServiceTypeAhead from './Common/IncidentServiceTypeAhead';
import RequesterTypeAhead from './Common/RequestorTypeAhead';

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

// const validate = (values) => {
//   // const errors = {};
//   // if (!values.MAILBOX_ID || (values.MAILBOX_ID && values.MAILBOX_ID.trim() == ""))
//   //   errors.MAILBOX_ID = "Enter value in Mailbox Id/Name";

//   // if (!values.MAILBOX_PROVIDER ) {
//   //   errors.MAILBOX_PROVIDER = 'Select MailBox Type';
//   // }
//   // if (!values.WHITELISTED_DOMAINS || (values.WHITELISTED_DOMAINS && values.WHITELISTED_DOMAINS.trim() == "")){
//   //   errors.WHITELISTED_DOMAINS = "Enter Whitelisted Domains";
//   // }
//   // if (!values.COMPANY_NAME || (values.COMPANY_NAME && values.COMPANY_NAME.trim() == "")) {
//   //   errors.COMPANY_NAME = "Enter Company Name";
//   // }
//   // if (!values.impact){
//   //   errors.impact = "Enter Default Impact";
//   // }
//   // if (!values.urgency){
//   //   errors.urgency = "Enter Default Urgency";
//   // }
//   // if (!values.ACTIONS){
//   //   errors.ACTIONS = "Select Actions";
//   // }
//   // return errors;
// }

const CreateMailboxConfigForm = (props) => {
  const [companyList, setCompanyList] = useState([]);
  const [typedCompany, setTypedCompany] = useState([]);
  const [companyId, setCompanyId] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [disableGuestUser, setDisableGuestUser] = useState(true);
  // const [mailboxIdErrorColor, setMailboxIdErrorColor] = useState("");
  // const [mailboxProviderErrorColor, setMailboxProviderErrorColor] = useState("");
  // const [whitelistedDomainErrorColor, setWhitelistedDomainErrorColor] = useState("");
  // const [urgencyErrorColor, setUrgencyErrorColor] = useState("");
  // const [impactErrorColor, setImpactErrorColor] = useState("");
  // const [actionsErrorColor, setActionsErrorColor] = useState("");
  const dispatch = useDispatch()
  
  const translator = useSelector(state => state.spcmReducer.tr);
  const urImpReducer = useSelector(state => state.urgencyImpactReducer);
  const companyReducer = useSelector((state) => state.spcmReducer.companyList);
  const typeaheadRef = useRef(null);
  const selector = formValueSelector('xsmMailboxConfigCreateForm');
  const mailBoxType = useSelector(state=>selector(state,'MAILBOX_PROVIDER'));
  
  useEffect(() =>
  {
    if(companyReducer && companyReducer.length > 0)
    {
      setCompanyList(companyReducer)
    }
    
  },[companyReducer])

  useEffect(() =>
  {
    if (props.selectedCompanyList && props.selectedCompanyList.length > 0) {
      let data = { "companyId": props.selectedCompanyList[0].field1Value, "module": "Breakfix", "field": "Urgency,Impact", "status": "1", 'language': 'en' }
      dispatch(getUrgencyImpactForSystemConfig(data))
    }
  },[props.selectedCompanyList])

    let urgencyList = urImpReducer.filter((data)=>{
            return  data.field2Name=="Urgency"
    });
    let impactList = urImpReducer.filter((data)=>{
        return  data.field2Name=="Impact"
    });
  
  let mailBox_type = [
    { text: 'Gmail', value: 'Gmail' },
    {text: 'Outlook', value : 'Outlook'},
  ]
  let actionsValue = [
    {text: 'Discard the Record',
      value: 'Discard the Record'},
    {text: 'Create with Guest Account',
      value: 'Create with Guest Account'},
    {text: 'Create User Profile',
      value: 'Create User Profile'}
  ]
  let status = [
    {
      text: 'Active',
      value: 'Active'
    },
    {
      text: 'Inactive',
      value: 'Inactive'
    },
  ]

  useEffect(() => {
    if (typeof (mailBoxType) !== 'undefined') {
      props.setMailBoxType(mailBoxType);
    }
    else {
      props.setMailBoxType(''); 
    }
  }, [mailBoxType])
  useEffect(() => {
    dispatch(getCompanyList(""));
    dispatch(resetUrgencyImpactForSystemConfig());
  }, []);

  const onConsumerSelected = (suggestion) => {
    props.setSelectedRequestor(suggestion);
    if (Object.keys(suggestion).length > 0) {
      let valuesArr = suggestion.userinformation.split('|');
      let userId = valuesArr[1];
      let userName = valuesArr[0];
      dispatch(change('xsmMailboxConfigCreateForm', 'GUEST_USER_ID', userId));
      dispatch(change('xsmMailboxConfigCreateForm', 'GUEST_USER_NAME', userName));
    } else {
      dispatch(change('xsmMailboxConfigCreateForm', 'GUEST_USER_ID', ''));
      dispatch(change('xsmMailboxConfigCreateForm', 'GUEST_USER_NAME', ''));
    }
  };

  const onServiceSelected = (suggestion) => {
    if (Object.keys(suggestion).length > 0) {
      props.setSelectedOffering(suggestion);
      dispatch(change('xsmMailboxConfigCreateForm', 'OFFERING_ID', suggestion.offeringId))
      dispatch(change('xsmMailboxConfigCreateForm', 'OFFERING_NAME', suggestion.offeringName))
      props.isFulfilledSet(suggestion.isExternallyFulfilled)
      // dispatch(change('xsmMailboxConfigCreateForm', 'EXTERNALLY_FULFILLED', suggestion.EXTERNALLY_FULFILLED))
    } else {
      dispatch(change('xsmMailboxConfigCreateForm', 'OFFERING_ID', ''))
      dispatch(change('xsmMailboxConfigCreateForm', 'OFFERING_NAME', ''))
      props.setSelectedOffering({});
    }
  }
  const setCompany = (selectedCompany) => {
    if (selectedCompany.length > 0) { 
      props.setSelectedCompanyList(selectedCompany);
      setCompanyId(selectedCompany[0].field1Value)
      setCompanyName(selectedCompany[0].field1Key)
      dispatch(change('xsmMailboxConfigCreateForm', 'COMPANY_ID', selectedCompany[0].field1Value))
      dispatch(change('xsmMailboxConfigCreateForm','COMPANY_NAME', selectedCompany[0].field1Key))
    } else {
      dispatch(resetUrgencyImpactForSystemConfig());
      props.setSelectedCompanyList([]);
    }
  };
  const companyInputChange = (e) => {
    setTypedCompany(e);
    props.setSelectedCompanyList([]);
    setCompany([]);
    dispatch(resetUrgencyImpactForSystemConfig());
  };

  const onCrossClickCompany = () => {
    companyInputChange("");
    props.setSelectedCompanyList([]);
    props.setSelectedRequestor('');
    setCompanyId('')
    setCompanyName('');
    dispatch(change('xsmMailboxConfigCreateForm', 'COMPANY_ID', ''))
    dispatch(change('xsmMailboxConfigCreateForm', 'COMPANY_NAME', ''))
    dispatch(change('xsmMailboxConfigCreateForm', 'OFFERING_ID', ''))
    dispatch(change('xsmMailboxConfigCreateForm', 'OFFERING_NAME', ''))
    props.setSelectedOffering({});
    dispatch(change('xsmMailboxConfigCreateForm', 'GUEST_USER_ID', ''));
    dispatch(change('xsmMailboxConfigCreateForm', 'GUEST_USER_NAME', ''));
    dispatch(change('xsmMailboxConfigCreateForm', 'impact', ''));
    dispatch(change('xsmMailboxConfigCreateForm', 'urgency', ''));
    dispatch(resetUrgencyImpactForSystemConfig());
  };

  const onCrossClickService = () => {
    props.setSelectedRequestor({});
    dispatch(change('xsmMailboxConfigCreateForm', 'GUEST_USER_ID', ''));
    dispatch(change('xsmMailboxConfigCreateForm', 'GUEST_USER_NAME', ''));
  }
  const onCrossClickOffering = () => { 
    props.setSelectedOffering({});
    dispatch(change('xsmMailboxConfigCreateForm', 'OFFERING_ID', ''))
    dispatch(change('xsmMailboxConfigCreateForm', 'OFFERING_NAME', ''))
  };
  const handleOnChangeAction = (e) => {
    if (e.target.value === 'Create with Guest Account') {
      setDisableGuestUser(false);
    }
    else {
      setDisableGuestUser(true);
      dispatch(change('xsmMailboxConfigCreateForm', 'GUEST_USER_NAME', ''));
    }
  }

 const  validateFieldConfig = (fieldType, event)=> {
    switch (fieldType) {
      case "MAILBOX_ID":
        if (event.target.value == "") {
          props.setMailboxIdErrorColor("error");
        } else {
          props.setMailboxIdErrorColor("");
        }
        break;
      case "MAILBOX_PROVIDER":
        if (event.target.value == "") {
          props.setMailboxProviderErrorColor("error");
        } else {
          props.setMailboxProviderErrorColor("");
        }
        break;
      case "WHITELISTED_DOMAINS":
        if (event.target.value == "") {
          props.setWhitelistedDomainErrorColor("error");
        } else {
          props.setWhitelistedDomainErrorColor("");
        }
        break;
      case "urgency":
        if (event.target.value == "") {
          props.setUrgencyErrorColor("error");
        } else {
          props.setUrgencyErrorColor("");
        }
        break;

      case "impact":
        if (event.target.value == "") {
          props.setImpactErrorColor("error")
        } else {
          props.setImpactErrorColor("");
        }
        break;
        case "ACTIONS":
          if (event.target.value == "") {
            props.setActionsErrorColor("error");
          } else {
            props.setActionsErrorColor("");
          }
        break;
        case "STATUS":
          if (event.target.value == "") {
            props.setStatusErrorColor("error");
          } else {
            props.setStatusErrorColor("");
          }
          break;
      default:
    }
  }
  return (
    <div>
        <div className="borderForm bFormDiv">
          <form>
            <Row>
            <Col md={6}>
                <Form.Group >
                  <Form.Label bsClass=""><span className="rStar"></span>{translator['Mailbox']}</Form.Label>
                <Field name="MAILBOX_ID" className={"form-control " + (props.mailboxIdErrorColor )} component={_inputField} maxLength={120}
                onBlur={(event) => {
                  validateFieldConfig("MAILBOX_ID", event);
                }}>
                  </Field>
                </Form.Group>
              </Col>
              <Col md={6}>
              <Form.Group >
                  <Form.Label bsClass=""><span className="rStar"></span>{translator['Vendor']}</Form.Label>
                <Field name="MAILBOX_PROVIDER" className={"form-control " + (props.mailboxProviderErrorColor)} component={_dropDown} options={mailBox_type}
                onBlur={(event) => {
                  validateFieldConfig("MAILBOX_PROVIDER", event);
                }}>
                  </Field>
                </Form.Group>
              </Col>
            </Row>
            <Row>
            <Col md={6}>
            <Form.Group className="form-group">
                <Form.Label bsClass="">
                  <span className="rStar" />
                  {translator["Company"]}
                </Form.Label>
                <Field
                  ref={typeaheadRef}
                  name="COMPANY_NAME"
                  component={TypeaheadExampleSingleSelect}
                  className="form-control"
                  options={companyList}
                  selectedOptions={props.selectedCompanyList}
                  onSelection={setCompany}
                  errorClass={props.companyError}
                  diableStatus={false}
                  onInputChange={companyInputChange}
                  onCrossClick={onCrossClickCompany}
                  typedValue={typedCompany}
                  setErrorColor={props.setCompanyErrorColor}
                  labelKey={"field1Key"}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
                <Form.Group >
                  <Form.Label bsClass=""><span className="rStar"></span>{translator['Service']}</Form.Label>
                  <div className="cnfgdvTypehd">
                <Field
                  ref={typeaheadRef}
                  name="OFFERING_NAME"
                  component={IncidentServiceTypeAhead}
                  className="form-control"
                  companyId={companyId}
                  onServiceSelected={onServiceSelected}
                  validateField={props.setOfferingErrorColor}
                  errorColor={props.offeringError}
                  onCrossClick={onCrossClickOffering}
                  />
                  </div>  
                </Form.Group>
              </Col>
              {/* <Col md={4}>
              <Form.Group >
                  <Form.Label bsClass=""><span className="rStar"></span>{translator['Business Function']}</Form.Label>
                  <Field name="business_function" className="form-control" component={_inputField} onpaste={(e) => { countCharacters(e) }} onChange={(e) => { countCharacters(e) }} >
                  </Field>
                </Form.Group>
              </Col> */}
          </Row>
          <div className="margin-t-5 padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
            <div className="bgGray padding-5">
              <span className="rStar"></span>
              {translator["Mailbox Filters"]}
            </div>
            <Row>
            <Col md={4}>
                <Form.Group >
                  <Form.Label bsClass=""><span className="rStar"></span>{translator['Whitelisted Domains']}</Form.Label>
                  <Field name="WHITELISTED_DOMAINS" className={"form-control " +(props.whitelistedDomainErrorColor )} component={_inputField} maxLength={120} placeholder={`gmail.com,yahoo.com`}
                  onBlur={(event) => {
                    validateFieldConfig("WHITELISTED_DOMAINS", event);
                  }}>
                  </Field>
                </Form.Group>
              </Col>
              <Col md={4}>
              <Form.Group >
                  <Form.Label bsClass="">{translator[`Always Process Exception`]}</Form.Label>
                <Field name="ALWAYS_EXCEPTION" className="form-control" component={_inputField} maxLength={120} placeholder={`xyz@gmail.com,abc@yahoo.com`}>
                  </Field>
                </Form.Group>
              </Col>
              <Col md={4}>
              <Form.Group >
                  <Form.Label bsClass="">{translator[`Never Process Exception`]}</Form.Label>
                <Field name="NEVER_EXCEPTION" className="form-control" component={_inputField} maxLength={120} placeholder={`abc@gmail.com,xyz@yahoo.com`}>
                  </Field>
                </Form.Group>
              </Col>
            </Row>
          </div>
          <div className="margin-t-5 padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
            <div className="bgGray padding-5">
              <span className="rStar"></span>
              {translator["Defaults"]}
            </div>
            <Row>
            <Col md={6}>
                <Form.Group>
                  <Form.Label bsClass=""><span className="rStar"></span>{translator['Default Impact']}</Form.Label>
                  <Field component={_dropDown} name="impact" options={impactList} className={"form-control "+ (props.impactErrorColor)}
                  onBlur={(event) => {
                    validateFieldConfig("impact", event);
                    }}
                  onChange={(event)=>{dispatch(change('xsmMailboxConfigCreateForm', 'impact_name', event.target[event.target.selectedIndex].text));}}/>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label bsClass=""><span className="rStar"></span>{translator['Default Urgency']}</Form.Label>
                  <Field component={_dropDown} name="urgency" options={urgencyList} className={"form-control " + (props.urgencyErrorColor)}
                  onBlur={(event) => {
                    validateFieldConfig("urgency", event);
                    }}
                  onChange={(event)=>{dispatch(change('xsmMailboxConfigCreateForm', 'urgency_name', event.target[event.target.selectedIndex].text));}}/>
                </Form.Group>
              </Col>
            </Row>
          </div>
          <div className="margin-t-5 padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
            <div className="bgGray padding-5">
              <span className="rStar"></span>
              {translator["User Verification"]}
            </div>
          <Row>
          <Col md={6}>
              <Form.Group >
                  <Form.Label bsClass=""><span className="rStar"></span>{translator['User Verification Type']}</Form.Label>
                  <Field name="ACTIONS" className={"form-control " + (props.actionsErrorColor)} component={_dropDown} options={actionsValue} onChange={(e) => { handleOnChangeAction(e) }}
                  onBlur={(event) =>{ validateFieldConfig("ACTIONS", event);}}>
                  </Field>
                </Form.Group>
          </Col>
          <Col md={6}>
              <Form.Group >
                  <Form.Label bsClass=""><span className="rStar"></span>{translator['Guest User']}</Form.Label>
                  <div className="dvTypehdropdown">
                {!disableGuestUser ? <Field
                    name="GUEST_USER_NAME"
                    className="form-control"
                    validateField={props.setRequestorErrorColor}
                    component={RequesterTypeAhead}
                    companyId={companyId || ""}
                    companyName={companyName || ""}
                    onConsumerSelected={onConsumerSelected}
                    selectedOption={props.selectedRequestor}
                    errorColor={props.requestorError}
                    resetSelectedOffering={onCrossClickService}
                    placeholder="User Name"
                    >
                    </Field> :
                    <Field
                      className="form-control"
                      component='input'
                      name='GUEST_USER_NAME'
                      disabled
                      placeholder="User Name"
                      />}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            </div>
          <Row>
            <Col md={6}>
              <Form.Group >
              <span className="rStar" />
                  <Form.Label bsClass="" className='margin-r-10'>{translator['Status']}</Form.Label>
                  <Field name="STATUS" className={"form-control " + (props.statusErrorColor)} component={_dropDown} options={status}
                  onBlur={(event) =>{validateFieldConfig("STATUS", event);}}>
                  </Field>
                </Form.Group>
            </Col>
            </Row>
          </form>
        </div>
      </div>
  )
}

export default reduxForm({
  form: 'xsmMailboxConfigCreateForm',
  destroyOnUnmount: false,
  // validate,
  enableReinitialize: true
})(CreateMailboxConfigForm);