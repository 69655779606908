
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { week, weekCount } from "../Helper";
import { InputTextFieldNumber } from "./FormFields";

const MonthComponent = ({ values, activeTab, setFieldValue }) => {
  return (
    <div className="month month-names" id="month-component">
      <div className="row align-items-center">
        <div className={activeTab ? "col-sm-1" : "col-sm-2"}>
          <div className="form-group  mt-3">
            <label className="d-block mb-2">On Day</label>
            <input
              type="radio"
              name="month-radio"
              checked={values.redioButtonSelection === "On Day" ? true : false}
              onChange={(e) => setFieldValue("redioButtonSelection", "On Day")}
            />
          </div>
        </div>
        <div className="col-sm-2">
          <InputTextFieldNumber
            name="monthDaysNo"
            label=""
            prefixClassName="test"
            disabled={values.redioButtonSelection === "On The" ? true : false}
            min={1}
            max={31}
            onChange={(e) => {
              const val = e.target.value;
              if (val) {
                if (val <= 31 && val > 0) {
                  setFieldValue("monthDaysNo", val, true);
                }
              } else {
                setFieldValue("monthDaysNo", "", true);
              }
            }}
          />
        </div>
        <div className={activeTab ? "col-sm-1" : "col-sm-2"}>
          <div className="form-group mt-3">
            <label className="d-block mb-2">On The</label>
            <input
              type="radio"
              name="month-radio"
              onChange={(e) => setFieldValue("redioButtonSelection", "On The")}
              checked={values.redioButtonSelection === "On The" ? true : false}
            />
          </div>
        </div>
        <div className="col-sm-3">
          <div className="form-group">
            <label className="d-block mb-2"></label>
            <select
              disabled={values.redioButtonSelection === "On The" ? false : true}
              className="custom-selected custom-select-text form-control"
              onChange={(e) => setFieldValue("weekNo", e.target.value)}
              value={values.weekNo}
            >
              {weekCount.map(({ key, value }) => (
                <option value={value} key={value}>
                  {key}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="form-group">
            <label className="d-block mb-2"></label>
            <select
              disabled={values.redioButtonSelection === "On The" ? false : true}
              className="custom-select-text custom-selected form-control"
              required=""
              onChange={(e) => setFieldValue("weekDay", e.target.value)}
              value={values.weekDay}
            >
              {week.map(({ key, value }) => (
                <option value={value} key={value}>
                  {key}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MonthComponent;
