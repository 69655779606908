
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from "axios";

const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

export function loadEscalationPlans(filters) {
  let queryParams = "";
  let i = 1;
  filters["sort"] = "createdOn,desc";
  if (filters && Object.keys(filters).length > 0) {
    queryParams += "?";
    Object.keys(filters).forEach((key) => {
      queryParams += key + "=" + filters[key];
      if (i !== Object.keys(filters).length) {
        queryParams += "&";
      }
      i++;
    });
  }
  return (dispatch) => {
    dispatch({ type: "LOADER_IN_PROGRESS" });
    axios
      .get("/rest/aiosem/searchEscalationPlanAction" + queryParams)
      .then((escalationPlans) => {
        if (!escalationPlans) {
          throw Error(escalationPlans.statusText);
        }
        return escalationPlans;
      })
      .then((escalationPlans) => {
        dispatch(loadEscalationListSuccess(escalationPlans.data));
        dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
      })
      .catch((err) => {
        dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
        dispatch(loadEscalationListSuccess([]));
        console.log(err);
      });
  };
}

export function loadEscalationListSuccess(payload) {
  return {
    type: "LOAD_ESCALATION_PLAN_LIST_SUCCESS",
    payload,
  };
}

export function loadPlanLevels(planId) {
  return (dispatch) => {
    dispatch({ type: "LOADER_IN_PROGRESS" });
    axios
      .get("/rest/aiosem/getEscalationLevelByPlanIdAction/" + planId)
      .then((escalationLevels) => {
        if (!escalationLevels) {
          throw Error(escalationLevels.statusText);
        }
        return escalationLevels;
      })
      .then((escalationLevels) => {
        dispatch(loadPlanLevelsSuccess(escalationLevels.data.data));
        dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
      })
      .catch((err) => {
        dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
        dispatch(loadPlanLevelsSuccess([]));
        console.log(err);
      });
  };
}

export function loadPlanLevelsSuccess(payload) {
  return {
    type: "LOAD_ESCALATION_PLAN_LEVEL_SUCCESS",
    payload,
  };
}

export function loadEscalationPlansDetail(planId) {
  return (dispatch) => {
    dispatch({ type: "LOADER_IN_PROGRESS" });
    axios
      .get("/rest/aiosem/getEscalationPlanAction/" + planId)
      .then((planDetail) => {
        if (!planDetail) {
          throw Error(planDetail.statusText);
        }
        return planDetail;
      })
      .then((planDetail) => {
        dispatch(loadPlanDetailSUccess(planDetail?.data?.data));
        dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
      })
      .catch((err) => {
        dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
        dispatch(loadPlanDetailSUccess([]));
        console.log(err);
      });
  };
}

export function loadPlanDetailSUccess(payload) {
  return {
    type: "LOAD_ESCALATION_PLAN_DETAIL_SUCCESS",
    payload,
  };
}

export function loadEscalationPlansLevelDetail(levelId) {
  return (dispatch) => {
    axios
      .get("/rest/aiosem/getEscalationLevelAction/" + levelId)
      .then((level) => {
        if (!level) {
          throw Error(level.statusText);
        }
        return level;
      })
      .then((level) => {
        dispatch(loadPlanLevelDetailSUccess(level?.data?.data));
        dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
      })
      .catch((err) => {
        dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
        dispatch(loadPlanLevelDetailSUccess([]));
        console.log(err);
      });
  };
}

export function loadPlanLevelDetailSUccess(payload) {
  return {
    type: "LOAD_ESCALATION_PLAN_LEVEL_DETAIL_SUCCESS",
    payload,
  };
}

export function deleteLevels(levelId,planId=0) {
  return (dispatch) => {
    dispatch({ type: "LOADER_IN_PROGRESS" });
    axios
      .delete("/rest/aiosem/deleteEscalationLevelAction/" + levelId)
      .then((planDetail) => {
        if (!planDetail) {
          throw Error(planDetail.statusText);
        }
        return planDetail;
      })
      .then((planDetail) => {
        dispatch(loadPlanLevels(planId));
        dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
      })
      .catch((err) => {
        dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
        console.log(err);
      });
  };
}
