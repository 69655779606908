
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import {Button} from 'react-bootstrap';

import FeaturedServices from './SXFeaturedServices';
import SxHomeTabOffering from './SXHomeTabServices';
import SxCategoryTabOffering from './SXCategoryTabServices';

import { connect } from 'react-redux';
import { getTranslation } from '_Actions/spcmActions';
import Cookies from 'universal-cookie';
import {PiListBulletsBold} from 'react-icons/pi';

const cookies = new Cookies();
let hometab_required = cookies.get('gph');
if (hometab_required) hometab_required = hometab_required.replace('s:', '');
if (hometab_required) hometab_required = hometab_required.substring(0, hometab_required.lastIndexOf('.'));
hometab_required = hometab_required.split("~");
hometab_required = hometab_required[4];
if (hometab_required == "" || hometab_required == undefined || hometab_required == "undefined")
	hometab_required = "yes";


let hometab_preference = cookies.get('gph');
if (hometab_preference) hometab_preference = hometab_preference.replace('s:', '');
if (hometab_preference) hometab_preference = hometab_preference.substring(0, hometab_preference.lastIndexOf('.'));
hometab_preference = hometab_preference.split("~");
import { GLOBAL } from '_Globals';
import { navigationHooks } from '../../../helpers/NavigationHook';
let bestSelling = hometab_preference[10];
let newLaunch = hometab_preference[11];
let comingSoon = hometab_preference[12];
let free = hometab_preference[13];
let myFavourite = hometab_preference[14];
let catwise = hometab_preference[15];
let featured = hometab_preference[16];

class HomeMain extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checkboxVisible: "isHidden",
			showBestSelling: '',
			showNewLaunch: '',
			showComingSoon: '',
			showFree: '',
			showMyFavourite: '',
			showCatwise: '',
			showFeatured: ''
		};
		GLOBAL.searchbarcategory = 'All';
	}
	componentWillMount() {

		let myapplocation = window.location.href;
		if (myapplocation.indexOf("myapp") > -1)
			navigationHooks.navigate("/myApprovals");

	}

	componentDidMount() {
		this.setState({
			showBestSelling: bestSelling,
			showNewLaunch: newLaunch,
			showComingSoon: comingSoon,
			showFree: free,
			showMyFavourite: myFavourite,
			showCatwise: catwise,
			showFeatured: featured
		});
		// this.props.getWelcomeMessage();
	}

	onArrowClick() {
		if (this.state.checkboxVisible == "isVisible") {
			this.setState({ checkboxVisible: "isHidden" })
		}
		else {
			this.setState({ checkboxVisible: "isVisible" })
		}
	}

	render() {
		return (
      <div className="container-fluid">
        <div className="homeservices">

              <div className="filterBox">
                <div className="pageviews">
                  {/* <Button id="homeGridview" className="" bsStyle="link" title={this.props.tr["Grid View"]}>
                    <i className="fa fa-th-large"></i>
                  </Button> */}
                  <Button
                    className={this.props.isView ? "active" : ""}
                    id="homelistview"
                    onClick={() => {
                      this.props.pageViewFun(false);
                      this.props.dispatch({ type: "LIST_VIEW_CATALOG" });
                    }}
                    variant="link"
                    title={this.props.tr["List View"]}
                  >
                    <PiListBulletsBold/>
                  </Button>
                </div>
                {/* <FormGroup>
										<FormControl componentClass="select" placeholder="All Providers">
											<option>All Providers</option>
											<option>Dell</option>
											<option>HP</option>
											<option>Samsung</option>
											<option>Apple</option>
										</FormControl>
									</FormGroup> */}
                {/* <FormGroup>
										<FormControl componentClass="select" placeholder="Sort By">
											<option>Sort By</option>
											<option>Popularity</option>
											<option>Price -- Low to High</option>
											<option>Price -- High to Low</option>
											<option>Newest First</option>
										</FormControl>
									</FormGroup>						 */}
              </div>


          {this.state.showFeatured == "Y" || this.state.showFeatured == "y" ? (
            <div className="margin-b-20">
              <div className="hTabhed">
              <h3 className="tabActive">{this.props.tr["Featured"]}</h3>
              </div>
              <FeaturedServices tr={this.props.tr} />
            </div>
          ) : null}

          {
          (
            (this.state.showBestSelling == "N" || this.state.showBestSelling == "n") &&
            (this.state.showNewLaunch == "N" || this.state.showNewLaunch == "n") &&
            (this.state.showComingSoon == "N" || this.state.showComingSoon == "n") &&
            (this.state.showFree == "N" || this.state.showFree == "n") &&
            (this.state.showMyFavourite == "N" || this.state.showMyFavourite == "n")
          )
          //   ||
          // (
          //   (this.state.showBestSelling == "N" || this.state.showBestSelling == "n") &&
          //   (this.state.showNewLaunch == "N" || this.state.showNewLaunch == "n") &&
          //   (this.state.showComingSoon == "Y" || this.state.showComingSoon == "y") &&
          //   (this.state.showFree == "Y" || this.state.showFree == "y") &&
          //   (this.state.showMyFavourite == "N" || this.state.showMyFavourite == "n") &&
          //   (hometab_required !== "yes" || hometab_required !== "YES")
          // ) 
          ? null : 
          (
            <SxHomeTabOffering
              showBestSelling={this.state.showBestSelling}
              showNewLaunch={this.state.showNewLaunch}
              showComingSoon={this.state.showComingSoon}
              showFree={this.state.showFree}
              showMyFavourite={this.state.showMyFavourite}
            />
          )
          }

          {this.state.showCatwise == "Y" || this.state.showCatwise == "y" ? (
            <div className="margin-t-25 margin-b-20">
              <div className="hTabhed">
                <h3 className="tabActive">{this.props.tr["Category View"]}</h3>
              </div>
              <SxCategoryTabOffering />
            </div>
          ) : null}
        </div>
      </div>
    );
	}
}
const mapStateToProps = ({ spcmReducer, welcomeMessageReducer }) => {
	return {
		lang: spcmReducer.lang,
		tr: spcmReducer.tr,
		welcomeMessageReducer
	};
};

export default connect(mapStateToProps)(HomeMain);
