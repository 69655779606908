
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState, useEffect, createRef, useRef } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Field, Formik, Form } from "formik";
import { Row, Col, Button, Form as BootstrapForm } from "react-bootstrap";
import { ImInfo } from "react-icons/im";
import LoadingIcons from "react-loading-icons";
import {
  InputTextField,
  SelectField,
  DatePickerF,
  InputTextFieldNumber,
  InputTextFieldForChips,
  StandardTextArea,
} from "../../common/FormFields";
import MeetingDropdownPlusTypeahead from "../../common/MeetingDropdownPlusTypeahead";
import ReccuranceComponent from "../../common/ReccuranceBottomComponent";
import Editor from "../../../common/Editor/Editor";
import CustomTypeHead from "../../common/CustomTypeHead";
import Header from "./header";
import GridLayout from "../../common/GridLayout";
import datetimeConverter from '../../../../helpers/ISO8601converter';
import {
  utis,
  returnServerPayload,
  ending,
  getWeekDayAccordingDate,
  findDuration,
  convertDate,
  week,
  weekCount,
  createUIPayload,
  yearMonthName,
  checkDateWithIn24Hr,
  createPayloadForChips,
  filterGroupUser,
  getCookiesValuesByPositions,
  setSelectValueByText,
} from "../../Helper";

import {
  getAllTrack,
  useGetUserInfoQuery,
  useGetAllForumQuery,
  useGetRegionListQuery,
  useGetAllCategoryQuery,
  useGetAllTrackQuery,
  useGetAllSubTrackQuery,
  useCommitteDetailsByFrmNameQuery,
} from "../../../../reducers/governance/meeting/meeting";
import { useGetAllCompanyQuery } from "../../../../reducers/foundation/forum/forumQuery";
import {
  findTimeFormate,
  prefDateFormatAccordingUser,
  getDateWord,
  disabledTime,
} from "../../common/CommonMethod";

import SystemInfo from "../../common/SystemInfo";
import StatusTimeLine from "../StatusTimeLine";
import RightSide from "../rightSide/index";

import {
  useGetActionMenuDdQuery,
  useCreateActionItemMutation,
  useGetAuditLogsQuery,
  useGetActionItemByIdQuery,
  useUpdateActionItemMutation,
  useSaveStatusMutation,
  useGetActionStatusQuery,
} from "../../../../reducers/governance/meeting/actionItem";
import swal from 'sweetalert';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
let timezone = cookies.get('gph');
if (timezone) timezone = timezone.replace('s:', '');
if (timezone) timezone = timezone.substring(0, timezone.lastIndexOf('.'));
timezone = timezone.split("~");
timezone = timezone[3];
let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

const AddEditMeeting = () => {
  const navigate = useNavigate();

  const formikRef = useRef();
  let descRef = createRef();
  let agendaRef = createRef();
  let meetingRecurrenceSummary = "";
  const params = useParams();
  const actionId = params?.actionId;
  const isAddMode = !actionId;
  const characterLimit = 501;
  const [trackName, setTrackName] = useState("");
  const [opentray, setOpentray] = useState(false);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [descLength, setDescLength] = useState(0);
  const [agendaLength, setAgendaLength] = useState(0);
  const [selectedCustId, setSelectedCustId] = useState(null);
  const [selectedForum, setSelectedForum] = useState(null);
  const [category, setCategory] = useState(null);
  const [allRegions, setAllRegions] = useState([]);
  const [activeTab, setActiveTab] = useState(true);
  const [activeTabText, setActiveTabText] = useState("");
  const [showTimeLine, setShowTimeLine] = useState(false);
  const [userFullName, setUserFullName] = useState(null);
  const [committeOptions, setCommitteOptions] = useState([]);
  const cookieValues = getCookiesValuesByPositions([2, 3, 57]);
  const dateformat = cookieValues?.value1;
  const timezone = cookieValues?.value2;
  const [orderValue, setOrderValue] = useState("desc");
  const [saveReasonPayload, setSaveReasonPayload] = useState({
    actionitemid: 0,
    status_id: 0,
    reason_code: "",
    actionitemcode: "",
    reason: "",
  });
  const [checkReasionCode, setCheckReasionCode] = useState(false);
  const [activeNav, setActiveNav] = useState(true);
  const [activeTabs, setActiveTabs] = useState("1");
  const [companyValue, setCompanyValue] = useState();
  const [selectedForumId, setSlectedForumId] = useState("");
  const [selectedSubtrack, setSlectedSubtrack] = useState("");
  const [isHemStatus, setisHemStatus] = useState("");
  const [status, setStatus] = useState(true);

  const toggle = (tab) => {
    setActiveNav(!tab);
    if (activeTabs !== tab) setActiveTabs(tab);
  };

  //ALL QUERY
  const { data: impactddList } = useGetActionMenuDdQuery("Impact");
  const { data: urgencyddList } = useGetActionMenuDdQuery("Urgency");
  const { data: priorityddList } = useGetActionMenuDdQuery("Priority");
  const { data: typeddList } = useGetActionMenuDdQuery("ActionType");
  const { data: forumList = [] } = useGetAllForumQuery(selectedCustId, {
    skip: !selectedCustId,
  });



  // ALL MUTATION

  const [addActionItem, {isLoading: saveActionItemLoading}] = useCreateActionItemMutation();
  const [saveStatus] = useSaveStatusMutation();
  const [updateActionItem, { isLoading: updateActionLoading }] =
    useUpdateActionItemMutation();

  const { data: userInfo, isFetching: userInfoFetching } =
    useGetUserInfoQuery();

  const { data: allCompany, isFetching: companyFetching } =
    useGetAllCompanyQuery();

  const {
    data: forumData,
    error,
    isLoading,
  } = useGetAllForumQuery(selectedCustId, {
    skip: !selectedCustId,
  });

  const {
    data: allCats,
    error: catError,
    isLoading: catIsLoading,
  } = useGetAllCategoryQuery(selectedCustId, {
    skip: !selectedCustId,
  });

  const {
    data: allCommittee,
    error: committeError,
    isLoading: committeLoading,
  } = useCommitteDetailsByFrmNameQuery(
    {
      name: selectedForum,
      custId: selectedCustId,
      category: category,
      group_type: 50,
    },
    {
      skip: !(selectedForum && selectedCustId && category),
    }
  );

  const {
    data: allRegionsData,
    error: regionsError,
    isLoading: regionsLoading,
  } = useGetRegionListQuery();

  const {
    data: allTrack,
    error: trackError,
    isLoading: trackLoading,
  } = useGetAllTrackQuery(
    { custId: selectedCustId },
    {
      skip: !selectedCustId,
    }
  );

  const {
    data: allSubTrack,
    error: subTrackError,
    isLoading: subTrackLoading,
  } = useGetAllSubTrackQuery(
    { name: trackName },
    {
      skip: !trackName,
    }
  );

  const {
    data: editActionData = [],
    isFetching: editFetching,
    isLoading: actionLoading,
  } = useGetActionItemByIdQuery(actionId, {
    skip: !actionId,
  });
  const actionitemCode = editActionData[0]?.actionitemCode;

  const { data: actionStatus = [] } = useGetActionStatusQuery({code:actionitemCode,orderValue:orderValue}, {
    skip: !(actionitemCode&&orderValue),
  });

  const timeConstraints = {
    hours: { min: moment().hours(), max: 23 },
    minutes: { min: 0, max: 59 },
  };

  const isValidStartTime = (current) => {
    const yesterday = moment().subtract(1, "day");
    return current.isAfter(yesterday);
  };
  const validationSchema = Yup.object().shape({
    companyId: Yup.string().required("Required"),
    requestedBy: Yup.string().nullable(),
    workItemType: Yup.string().required("Required"),
    regionValue: Yup.string().nullable(),
    impact: Yup.string().nullable(),
    urgency: Yup.string().nullable(),
    priority: Yup.string().nullable(),
    expectedDueDate: Yup.date()
      .required()
      .test(
        "validate start date",
        "start date should be at-least today's date",
        (value) => {
          let dateObj = new Date(value);
          let momentObj = moment(dateObj).startOf("minute");
          if (momentObj.isBefore(moment()) && isAddMode) {
            return false;
          }
          return true;
        }
      ),
    additionalParams: Yup.string().required("Required"),
    assignedToGroupId: Yup.string().nullable(),
    actionOwnerChangeReason: checkReasionCode
      ? Yup.string().required('Required')
      : Yup.string().nullable(),
    forumId: Yup.string().required("Required"),
    committeeId: Yup.string().required("Required"),
    supplierList: Yup.array(),
    performerList: Yup.array(),
    assignedToId: Yup.string().required("Required"),
    categoryId: Yup.string().required("Required"),
  });

  const [initVals, setInitVals] = useState({
    companyId: "",
    companyName: "",
    requestedBy: "",
    workItemType: "",
    forum: "",
    committee: "",
    track: "",
    subtrack: "",
    regionValue: "",
    regionCode: "",
    actionOwnerChangeReason: "",
    impact: "",
    urgency: "",
    priority: "",
    expectedDueDate: "",
    additionalParams: "",
    description: "",
    sequenceNumber: 1,
    assignedToGroupId: "",
    assignedToGroup: "",
    performerList: [],
    supplierList: [],
    assignedToId: "",
    assignedTo: "",
    assignedToFullName: "",
    forumId: "",
    committeeId: "",
    categoryId: "",
    category: "",
    assignedToCompanyId: 0,
    assignedToCompany: 0,
    assignedToGroupCompanyId: 0,
    assignedToGroupCompany: 0,
  });

  let initialValues = {
    ...initVals,
  };

  const onSubmit = async (values, { setSubmitting, setFieldError, ...options }) => {
    if(editActionData.length>0){
      let count=0;

      const editImpact = values.impact === '' || values.impact === null ? null : values.impact;
      const savedImpact = editActionData[0].impact === '' || editActionData[0].impact === null ? null : editActionData[0].impact;
      if(editImpact != savedImpact){
        count++;
      }

      const editUrgency = values.urgency === '' || values.urgency === null ? null : values.urgency;
      const savedUrgency = editActionData[0].urgency === '' || editActionData[0].urgency === null ? null : editActionData[0].urgency;
      if(editUrgency != savedUrgency){
        count++;
      }

      const editPriority = values.priority === '' || values.priority === null ? null : values.priority;
      const savedPriority = editActionData[0].priority === '' || editActionData[0].priority === null ? null : editActionData[0].priority;
      if(editPriority != savedPriority){
        count++;
      }
      if(values.additionalParams!=editActionData[0].additionalParams){
        count++;
      }
      if(!(editActionData[0].description===null && values.description==='') && values.description!=editActionData[0].description){
        count++;
      }
      if(values.assignedToId!=editActionData[0].assignedToId){
        count++;
      }
      if(values.performerList.length!=editActionData[0].performers.length){
        count++;
      }else if(values.performerList.length==editActionData[0].performers.length && values.performerList.length>0){
        for(let i=0;i<values.performerList.length;i++){
            if(values.performerList[i].userId!=editActionData[0].performers[i].performerId){
              count++;
            }      
        }
      }
      if(count==0){
        swal({text: "There is no change on the form",button: "OK",});
        return;
      }
    }
    let time = new Date().getTime();

    // const PostData = {
    //   companyId: 1,
    //   companyName: "ACME North America",
    //   requestedBy: "Abhishek Jain",
    //   workItemType: "General",
    //   forum: "1527_TestGovForum",
    //   committee: "1527_Committee",
    //   track: "Database",
    //   subtrack: "MYSQL",
    //   regionValue: "Africa",
    //   regionCode: 2,
    //   actionOwnerChangeReason: "",
    //   impact: "1",
    //   urgency: "1",
    //   priority: "1",
    //   expectedDueDate: 1703447889804,
    //   additionalParams: "Summary",
    //   description: "Description",
    //   sequenceNumber: 1,
    //   assignedToGroupId: "",
    //   assignedToGroup: "",
    //   performerList: [
    //     { performerName: "Abhishek Jain", performerId: "hul_consumer" },
    //   ],
    //   supplierList: [],
    //   assignedToId: 45,
    //   assignedTo: "hul_consumer",
    //   assignedToFullName: "Abhishek Jain",
    //   forumId: 32,
    //   committeeId: 63,
    //   categoryId: "1",
    //   category: "Operational",
    //   assignedToCompanyId: 1,
    //   assignedToCompany: "ACME North America",
    //   assignedToGroupCompanyId: 0,
    //   assignedToGroupCompany: 0,
    //   subTrack: "",
    //   assignedToEmailAddress: "hul_consumer@test.com",
    //   meetingDropId: "hul_",
    // };

    const PostActionData = {
      ...values,
      'expectedDueDate': values.expectedDueDate.format("YYYY-MM-DD H:mm:ss"),
      performerList: filterPerformerList(values.performerList),
    };

    try {
      if (isAddMode) {
        const res = await addActionItem({ data: PostActionData }).unwrap().then((payload) => {
        navigate(`/add-edit/action/${payload?.actionitemId}`);
        })
          .catch((error) =>{
            if(error.status == 400){
              swal({
                  text: error.data.message,
                  button: 'OK',
              })
  }});
      } else {
        const updatedData = {
          ...values,
          'expectedDueDate': values.expectedDueDate.format("YYYY-MM-DD H:mm:ss"),
          actionitemId: editActionData?.[0]?.actionitemId,
          actionitemCode: editActionData?.[0]?.actionitemCode,
          performerList: filterPerformerList(values.performerList),
        };
        if(params?.actionId){
          delete updatedData.createdDate;
          delete updatedData.createdBy;
          delete updatedData.createdByFullName;
          delete updatedData.createdById;
          delete updatedData.requesterId;
          delete updatedData.requesterName;
          delete updatedData.requesterFullName;
        }

        const res = await updateActionItem({ data: updatedData }).unwrap();
       
        if (res) {         
          setCheckReasionCode(false);
        }

      }
    } catch (err) {
      setSubmitting(false);
      if(err.status == 400){
        swal({
            text: err.data.message,
            button: 'OK',
        })};
    } finally {
      setSubmitting(false);
    }
  };

  const onCompanySelection = async (selectedCompany, setFieldValue) => {
    if (selectedCompany[0]?.id) {
      setSelectedCustId(selectedCompany?.[0]?.id);
      setFieldValue("customerId", selectedCompany?.[0]?.id);
      setFieldValue("customer", selectedCompany?.[0]?.id);
      setFieldValue("customerName", selectedCompany?.[0]?.label);
    } else {
      setSelectedCustId(null);
      setFieldValue("customerId", "");
      setFieldValue("customer", "");
      setFieldValue("customerName", "");
      setFieldValue("forumId", "");
      setFieldValue("forumName", "", true);
      setFieldValue("categoryId", "");
      setFieldValue("category", "");
    }
  };

  const resetValues = (setFieldValue, values) => {
    setFieldValue("weeks", [getWeekDayAccordingDate(values.startOn)]);
    setFieldValue("redioButtonSelection", "On Day");
    setFieldValue("monthDaysNo", 1);
    setFieldValue("weekNo", getWeekDayAccordingDate(values.startOn));
    setFieldValue("weekDay", getWeekDayAccordingDate(values.startOn));
    setFieldValue("yearMonthName", "1");
    setFieldValue("yearMonthNameForOnDay", "1");
  };

  const weekHander = (weeksArray, val, setFieldValue) => {
    var index = weeksArray.indexOf(val);
    if (index !== -1) {
      weeksArray.splice(index, 1);
    } else {
      weeksArray.push(val);
    }

    setFieldValue("weeks", [...weeksArray]);
  };

  const handleOnSelectAction = (val) => {
    let payload = {
      actionitemcode: editActionData?.[0]?.actionitemCode,
      actionitemid: editActionData?.[0]?.actionitemId,
      status_id: val?.id,
      reason_code: val?.status,
      reason: "",
    };
    if (
      val?.alias !== "Cancel" &&
      val?.alias !== "Re-open" &&
      val?.alias !== "Re-assign" &&
      val?.status !== "Completed" &&
      val?.status !== "Closed" &&
      val?.status !== "Pending"
    ) {
      saveStatus({ data: payload });
    } else {
      setSaveReasonPayload(payload);
      toggle("10");
      setisHemStatus(val?.alias);
    }
  };

  const onSubmitReason = (values, setSubmitting, resetForm) => {
    setSubmitting(true);
    let payload = {
      ...saveReasonPayload,
      reason: values?.description,
    };
    saveStatus({ data: payload });
    toggle("1");
    setSubmitting(false);
    resetForm();
  };

  const resetDependentValues = (setFieldValue) => {
    setFieldValue("committee", "");
    setFieldValue("forum", "");
    setFieldValue("track", "");
    setFieldValue("subtrack", "");
  };

  useEffect(() => {
    const companyData = allCompany?.message
      ? []
      : allCompany?.length !== 0 &&
        allCompany?.map((item) => ({
          label: item?.field1Key,
          id: item?.field1Value,
        }));

    setCompanyOptions(companyData || []);
  }, [allCompany]);

  useEffect(() => {
    const allCommitteeData = allCommittee?.message
      ? []
      : allCommittee?.length !== 0 &&
        allCommittee?.map((item) => ({
          label: item?.name,
          id: item?.id,
        }));

    setCommitteOptions(allCommitteeData || []);
  }, [allCommittee]);

  useEffect(() => {
    setInitVals((prev) => {
      return {
        ...prev,
        requestedBy: userInfo?.full_name,
      };
    });
  }, [userInfoFetching]);

  useEffect(() => {
    if(initVals.statusName == "Cancelled" || initVals.statusName == "Closed" || initVals.statusName == "Completed"){
      setStatus(false)
    }
  }, [initVals.statusName]);

  useEffect(() => {
    setInitVals((prev) => {
      return {
        ...prev,
        ...editActionData[0],
        'expectedDueDate':  editActionData[0]?.expectedDueDate?moment(datetimeConverter(editActionData[0]?.expectedDueDate,jwtdf+" HH:mm:ss")):'',
        performerList: filterPerformers(
          editActionData[0]?.performers
        ),
        'description':  editActionData.length>0 && typeof editActionData[0].description!=='undefined' && editActionData[0].description!=null ? editActionData[0].description : '',
      };
    });

    setSelectedCustId(editActionData[0]?.companyId);

    setTrackName(editActionData[0]?.track);
  }, [editFetching]);

  const filterPerformerList = (data) => {
    let result = [];
    data.map((item) =>
      result.push({
        performerName: item.fullname,
        performerId: item.userId,
      })
    );
    return result;
  };

  const filterPerformers = (data) => {
    let result = [];
    data?.map((item) =>
      result.push({
        fullname: item.performerName,
        userId: item.performerId,
      })
    );
    return result;
  };

  return (
    <div id="GovernanceMeeting">
      {actionLoading && (
        <Row>
          <Col md={12}>
            <div className="rctLoader">
              <LoadingIcons.Bars
                height="5em"
                stroke="transparent"
                fill="#06bcee"
              />
            </div>
          </Col>
        </Row>
      )}

      <Row>
        <Col md={12} sm={12} xs={12}>
          <Header
            isAddMode={isAddMode}
            isSubmitting={formikRef?.current?.isSubmitting}
            saveActionItemLoading={saveActionItemLoading}
            updateActionLoading={updateActionLoading}
            dirty={formikRef?.current?.dirty}
            submitForm={formikRef?.current?.handleSubmit}
            formikref={formikRef}
            handleOnSelectAction={handleOnSelectAction}
            editActionData={editActionData}
            setShowTimeLine={setShowTimeLine}
            showTimeLine={showTimeLine}
            toggle={toggle}
            activeTabs={activeTabs}
            actionStatus={actionStatus}
          />
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={onSubmit}
          >
            {({
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              handleBlur,
              values,
              dirty,
              handleChange,
              isValid,
              submitCount,
            }) => {
              return (
                <Form noValidate className="bFormDiv">
                  <Row>
                    <Col md={12}>
                      {!isAddMode && showTimeLine && (
                        <Row>
                          <Col md={12}>
                            <StatusTimeLine
                              showTimeLine={showTimeLine}
                              setShowTimeLine={setShowTimeLine}
                              actionStatus={actionStatus}
                            />
                          </Col>
                        </Row>
                      )}
                      <Col md={12} sm={12} xs={12}>
                        {!isValid && submitCount > 0 && (
                          <div className="alert alert-danger">
                            The form has missing required fields, please
                            revalidate the highlighted fields and update the
                            form !
                          </div>
                        )}
                      </Col>
                      <GridLayout cols={2}>
                        <BootstrapForm.Group className="form-group">
                          <BootstrapForm.Label>
                            <span className="rStar"></span> Company
                          </BootstrapForm.Label>

                          <Field
                            name="companyId"
                            id="company"
                            disabled={!isAddMode}
                            errorColor={
                              errors.companyId && touched.companyId
                                ? "error"
                                : ""
                            }
                            component={MeetingDropdownPlusTypeahead}
                            options={companyOptions || []}
                            onSelection={(val) => {
                              onCompanySelection(val, setFieldValue);
                              setFieldValue(
                                "companyName",
                                val?.[0]?.label || "",
                                true
                              );

                              resetDependentValues(setFieldValue);
                              setFieldValue(
                                "companyId",
                                val?.[0]?.id || "",
                                true
                              );
                            }}
                            {...(!isAddMode && {
                              selected: values?.companyName
                                ? [
                                    {
                                      label: values.companyName,
                                      value: values?.companyId,
                                    },
                                  ]
                                : [],
                            })}
                          />
                        </BootstrapForm.Group>
                        <InputTextField
                          type="text"
                          name="requestedBy"
                          disabled
                          label="Requested By"
                        />
                        <BootstrapForm.Group className="form-group">
                          <BootstrapForm.Label>
                            <span className="rStar"></span>Type
                          </BootstrapForm.Label>
                          <Field
                            name="workItemType"
                            className={
                              errors.workItemType && touched.workItemType
                                ? "form-control error"
                                : "form-control"
                            }
                            component="select"
                            disabled={!isAddMode}
                          >
                            <option value="">Select</option>
                            {typeddList?.length > 0 &&
                              typeddList?.map((type, index) => {
                                return (
                                  <option
                                    value={type?.label}
                                    key={`frmtype-${index}`}
                                  >
                                    {type?.label}
                                  </option>
                                );
                              })}
                          </Field>
                        </BootstrapForm.Group>
                        <SelectField
                          required
                          name="forumId"
                          label="Forum"
                          onChange={(e) => {
                            setFieldValue("forumId", e.target.value, true);
                            setFieldValue(
                              "forum",
                              e.target[e.target.selectedIndex].text,
                              true
                            );
                            setSelectedForum(e.target.value);
                          }}
                          disabled={!isAddMode}
                        >
                          {forumData?.map((frm, index) => {
                            return (
                              <option value={frm?.forumId} key={`frm-${index}`}>
                                {frm?.forumName}
                              </option>
                            );
                          })}
                        </SelectField>
                        <SelectField
                          required
                          name="categoryId"
                          label="Category"
                          onChange={(e) => {
                            const selectedOption =
                              e.target.options[e.target.selectedIndex];
                            const catName =
                              selectedOption.getAttribute("data-name");
                            setCategory(e.target.value);
                            setFieldValue("categoryId", e.target.value);
                            setFieldValue("category", catName);
                          }}
                          disabled={!isAddMode}
                        >
                          {allCats?.map((cat, index) => {
                            return (
                              <option
                                value={cat?.fieldNameValue1}
                                key={`cat-${index}`}
                                data-name={cat?.fieldNameKey1}
                              >
                                {cat?.fieldNameKey1}
                              </option>
                            );
                          })}
                        </SelectField>
                        <BootstrapForm.Group className="form-group">
                          <BootstrapForm.Label>
                            <span className="rStar"></span> Committee
                          </BootstrapForm.Label>

                          <Field
                            name="committeeId"
                            id="committeeId"
                            disabled={!isAddMode}
                            errorColor={
                              errors.committeeId && touched.committeeId
                                ? "error"
                                : ""
                            }
                            component={MeetingDropdownPlusTypeahead}
                            options={committeOptions || []}
                            onSelection={(value) => {
                              setFieldValue(
                                "committee",
                                value[0]?.label || "",
                                true
                              );
                              setFieldValue("committeeId", value[0]?.id || "");
                            }}
                            {...(!isAddMode && {
                              selected: values?.committee
                                ? [
                                    {
                                      label: values.committee,
                                      value: values?.committeeId,
                                    },
                                  ]
                                : [],
                            })}
                          />
                        </BootstrapForm.Group>
                      </GridLayout>
                      <GridLayout cols={2}>
                        <SelectField
                          name="track"
                          id="trackId"
                          label="Track"
                          onChange={(e) => {
                            const selectedOption =
                              event.target.options[event.target.selectedIndex];
                            const trackId =
                              selectedOption.getAttribute("data-id");
                            setFieldValue("trackId", trackId);
                            setFieldValue("track", e.target.value);
                            setFieldValue("subtrack", "");
                            setTrackName(e.target.value);
                            //showRightPanel(null, null);
                          }}
                          disabled={!isAddMode}
                        >
                          {allTrack?.length > 0 &&
                            allTrack?.map((track, index) => {
                              return (
                                <option
                                  value={track?.setTrackName}
                                  key={`track-${index}`}
                                  data-id={track?.trackId}
                                >
                                  {track?.trackName}
                                </option>
                              );
                            })}
                        </SelectField>
                        <SelectField
                          name="subtrack"
                          label="Sub-track"
                          disabled={!isAddMode}
                        >
                          {allSubTrack?.length > 0 &&
                            allSubTrack?.map((track, index) => {
                              return (
                                <option
                                  value={track?.subTrackName}
                                  key={`subtrk-${index}`}
                                  data-name={track?.subTrackId}
                                >
                                  {track?.subTrackName}
                                </option>
                              );
                            })}
                        </SelectField>
                        <DatePickerF
                          required
                          label="Expected By"
                          name="expectedDueDate"
                          // className={
                          //   errors.expectedDueDate && touched.expectedDueDate
                          //     ? "form-control dateTimePickerField error"
                          //     : "form-control dateTimePickerField"
                          // }
                          className="form-control dateTimePickerField"
                          locale="en"
                          initialValue={moment()}
                          timeConstraints={timeConstraints}
                          isValidDate={isValidStartTime}
                          defaultValue={moment().tz(timezone)}
                          value={
                            values?.expectedDueDate
                              ? moment(
                                  values.expectedDueDate,
                                  jwtdf+ " HH:mm:ss"
                                )
                              : ""
                          }
                          onChange={(value) => {
                            setFieldValue("expectedDueDate", value);
                          }}
                          disabled={!isAddMode}
                        />
                        <SelectField
                          name="regionValue"
                          label="Region"
                          onChange={(e) => {
                            const selectedOption =
                              e.target.options[e.target.selectedIndex];
                            const regionName =
                              selectedOption.getAttribute("data-name");

                            setFieldValue("regionValue", e.target.value);
                            setFieldValue("regionCode", regionName);
                          }}
                          disabled={!isAddMode}
                        >
                          {allRegionsData?.result?.length > 0 &&
                            allRegionsData?.result?.map((region, index) => {
                              return (
                                <option
                                  value={region?.regionName}
                                  key={`region-${index}`}
                                  data-name={region?.regionId}
                                >
                                  {region?.regionName}
                                </option>
                              );
                            })}
                        </SelectField>
                      </GridLayout>
                      <GridLayout cols={3}>
                        <SelectField
                          name="impact"
                          label="Risk"
                          disabled={!isAddMode && editActionData[0]?.top === "V" || !status}
                        >
                          {impactddList?.length > 0 &&
                            impactddList?.map((type, index) => {
                              return (
                                <option
                                  value={type?.value}
                                  key={`frmtype-${index}`}
                                >
                                  {type?.label}
                                </option>
                              );
                            })}
                        </SelectField>
                        <SelectField
                          name="urgency"
                          label="Urgency"
                          disabled={!isAddMode && editActionData[0]?.top === "V" || !status}
                        >
                          {urgencyddList?.length > 0 &&
                            urgencyddList?.map((type, index) => {
                              return (
                                <option
                                  value={type?.value}
                                  key={`frmtype-${index}`}
                                >
                                  {type?.label}
                                </option>
                              );
                            })}
                        </SelectField>
                        <SelectField
                          name="priority"
                          label="Priority"
                          disabled={!isAddMode && editActionData[0]?.top === "V" || !status}
                        >
                          {priorityddList?.length > 0 &&
                            priorityddList?.map((type, index) => {
                              return (
                                <option
                                  value={type?.value}
                                  key={`frmtype-${index}`}
                                >
                                  {type?.label}
                                </option>
                              );
                            })}
                        </SelectField>
                      </GridLayout>
                      <GridLayout cols={1} className="mt-3">
                        <StandardTextArea
                          mrtop={0}
                          characterLimit={200}
                          characterCount={true}
                          name="additionalParams"
                          mandatory={"true"}
                          required
                          label="Summary"
                          disabled={!isAddMode && editActionData[0]?.top === "V" || !status}
                        />
                      </GridLayout>

                      <GridLayout cols={1} className="mt-2">
                        <StandardTextArea
                        characterLimit={1000}
                          characterCount={true}
                          name="description"
                          label="Description"
                          disabled={
                            !isAddMode && editActionData[0]?.top === "V" || !status
                          }
                        />
                      </GridLayout>
                      <GridLayout cols={2} className="mt-3">
                      <div className="dvTypehdropdown">
                        <CustomTypeHead
                          required
                          clearButton
                          id="assignedToId"
                          name="assignedToId"
                          label="Action Owner"
                          thfor="personApi"
                          placeholder="Please type to search"
                          onBlur={() => setOpentray(false)}
                          setOpentray={setOpentray}
                          companyId={values?.companyId}
                          autoComplete="off"
                          labelkey={(option) =>
                            option?.fullname
                              ? `${option?.fullname}`
                              : option
                          }
                          
                          onChange={(selected) => {
                          
                            !isAddMode &&
                            editActionData[0]?.assignedToId !==
                            Number(selected?.[0]?.userId)
                              ? setCheckReasionCode(true)
                              : setCheckReasionCode(false);


                            setFieldValue("actionOwner", selected, true);
                            setFieldValue(
                              "assignedTo",
                              selected?.[0]?.fullname || ""
                            );
                            setFieldValue(
                              "assignedToId",
                              Number(selected?.[0]?.userId) || ""
                            );
                            setFieldValue(
                              "assignedToEmailAddress",
                              selected?.[0]?.email || ""
                            );
                            setFieldValue(
                              "assignedToFullName",
                              selected?.[0]?.fullname || ""
                            );
                            setFieldValue(
                              "assignedToCompanyId",
                              selected?.[0]?.companyId || ""
                            );
                            setFieldValue(
                              "assignedToCompany",
                              selected?.[0]?.companyName || ""
                            );
                          }}
                          // selected={values?.actionOwner || []}
                          open={opentray}
                          selected={
                            values?.assignedToFullName
                              ? [
                                  `${values?.assignedToFullName} `,
                                ]
                              : []
                          }
                          // {...(isAddMode
                          //   ? {
                          //       selected: values?.assignedToFullName
                          //         ? [
                          //             `${values?.assignedToFullName} | ${values?.assignedToEmailAddress} | ${values?.assignedToCompany}`,
                          //           ]
                          //         : [],
                          //     }
                          //   : {
                          //       selected: values?.actionOwner || [],
                          //     })}
                          disabled={
                            !isAddMode && editActionData[0]?.top === "V" || !status
                          }
                        /></div>
                        <div className="dvTypehdropdown">
                        <CustomTypeHead
                          multiple
                          clearButton
                          id="performerlist"
                          name="performerList"
                          label="Action Performer"
                          thfor="personApi"
                          placeholder="Please type to search"
                          companyId={selectedCustId || values?.customer}
                          autoComplete="off"
                          labelkey={
                            isAddMode
                              ? "fullname"
                              : (option) =>
                                  option?.fullname
                                    ? `${option?.fullname}`
                                    : option
                          }
                          onChange={(selected) => {
                           
                            setFieldValue("performerList", selected, true);
                          }}
                          selected={values?.performerList || []}
                          disabled={
                            !isAddMode && editActionData[0]?.top === "V" || !status
                          }
                        /></div>
                      </GridLayout>
                      <Row>
                      {checkReasionCode && (
                        <Col xs={12}>
                          <div className="mb-4 mt-n3">
                            <StandardTextArea
                              characterCount={true}
                              name="actionOwnerChangeReason"
                              required={true}
                              label="Reason"
                              value={values?.actionOwnerChangeReason || ''}
                            />
                          </div>
                        </Col>
                      )}
                      </Row>
                      {!isAddMode && (
                        <Row className="mt-3">
                          <Col md={12}>
                            <SystemInfo
                              header="System Information"
                              cusClass="system-info-panel"
                              headers={[
                                "Created On",
                                "Created By",
                                "Modified On",
                                "Modified By",
                              ]}
                              values={[
                                editActionData[0]?.createdDate,
                                editActionData[0]?.createdByFullName,
                                editActionData[0]?.lastModificationDate,
                                editActionData[0]?.modifiedByFullName,
                              ]}
                            />
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </Col>
        {!isAddMode && (
          <Col md={4}>
            <RightSide
              editActionData={editActionData}
              activeTabs={activeTabs}
              isHemStatus={isHemStatus}
              onSubmitReason={onSubmitReason}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default AddEditMeeting;
