
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Link } from "react-router-dom";
import Tree from "react-d3-tree";
import { Row, Col, Button } from "react-bootstrap";
import { loadChangeRelatedDataDetails } from "../../../../actions/changeManagement/cmdbchangeRelatedDataAction";
import ListLoader from "../../../common/loaders/ListLoader";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Cookies from "universal-cookie";

const containerStyles = {
  width: "100%",
  height: "460px",
};

const cookies = new Cookies();
let app_url = cookies.get("gph");
if (app_url) app_url = app_url.replace("s:", "");
if (app_url) app_url = app_url.substring(0, app_url.lastIndexOf("."));
app_url = app_url.split("~");
app_url = app_url[18];

class CmdbRelatedCIGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: true,
      currentView: "vertical",
      translate: { x: 155, y: 100 },
      svgSquare: {
        shape: "circle",
        shapeProps: {
          r: 10,
          fill: "#FFCC6D",
          stroke: "#000",
          strokeWidth: 1,
        },
      },
      zoom: 1,
    };
    this.editView = this.editView.bind(this);
    this.createTreeData = this.createTreeData.bind(this);
  }

  componentWillMount() {
    let itemId = this.props.itemId;
    this.props.loadChangeRelatedDataDetails(itemId, "CMDB");
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.cmdbChangeRelatedDataDetails &&
      nextProps.cmdbChangeRelatedDataDetails !=
        this.state.cmdbChangeRelatedDataDetails
    ) {
      let len = nextProps.cmdbChangeRelatedDataDetails.length;
      if (this.state.currentView == "vertical") {
        if (len <= 3) this.setState({ zoom: 1 });
        else if (len > 3 && len <= 6) this.setState({ zoom: 0.9 });
        else if (len > 6 && len <= 10) this.setState({ zoom: 0.8 });
        else if (len > 10 && len <= 15) this.setState({ zoom: 0.65 });
        else if (len > 15) this.setState({ zoom: 0.5 });
      } else {
        if (len <= 3) this.setState({ zoom: 1 });
        else if (len > 3 && len <= 6) this.setState({ zoom: 0.75 });
        else if (len > 6 && len <= 10) this.setState({ zoom: 0.6 });
        else if (len > 10 && len <= 15) this.setState({ zoom: 0.5 });
        else if (len > 15) this.setState({ zoom: 0.45 });
      }
    }
  }

  editView(value) {
    const dimensions = this.treeContainer.getBoundingClientRect();
    let len = this.props.cmdbChangeRelatedDataDetails.length;
    if (value == "horizontal") {
      if (len <= 3) this.setState({ zoom: 1 });
      else if (len > 3 && len <= 6) this.setState({ zoom: 0.75 });
      else if (len > 6 && len <= 10) this.setState({ zoom: 0.6 });
      else if (len > 10 && len <= 15) this.setState({ zoom: 0.5 });
      else if (len > 15) this.setState({ zoom: 0.45 });
      this.setState({ translate: { x: 60, y: dimensions.height / 2 } });
    } else {
      if (len <= 3) this.setState({ zoom: 1 });
      else if (len > 3 && len <= 6) this.setState({ zoom: 0.9 });
      else if (len > 6 && len <= 10) this.setState({ zoom: 0.8 });
      else if (len > 10 && len <= 15) this.setState({ zoom: 0.65 });
      else if (len > 15) this.setState({ zoom: 0.5 });
      this.setState({ translate: { x: dimensions.width / 2 - 50, y: 100 } });
    }
    this.setState({ status: !this.state.status });
    this.setState({ currentView: value });
  }

  createTreeData(cmdbChangeRelatedDataDetails, cieditdetails) {
    let children = [];
    cmdbChangeRelatedDataDetails.forEach((relation, i) => {
      let obj = {};
      let temp =
        relation.CI_NAME_RELATED.length > 22
          ? relation.CI_NAME_RELATED.substring(0, 21) + "..."
          : relation.CI_NAME_RELATED;
      obj.name = temp;
      obj.title = relation.CI_NAME_RELATED;
      obj.attributes = { Status: relation.CI_ID_RELATED_STATUS };
      children.push(obj);
    });

    let treeData = [];
    let obj = {};
    let temp =
      cieditdetails.CI_NAME.length > 22
        ? cieditdetails.CI_NAME.substring(0, 21) + "..."
        : cieditdetails.CI_NAME;
    obj.name = temp;
    obj.title = cieditdetails.CI_NAME;
    obj.attributes = { Status: cieditdetails.STATUS_NAME };
    obj.children = children;
    treeData.push(obj);

    return treeData;
  }

  render() {
    if (this.props.showLoader) {
      return <ListLoader />;
    }
    if (this.props.cmdbChangeRelatedDataDetails.length == 0) {
      return (
        <div>
          <Row>
            <Col xs={12}>
              <div className="rPageHeading">
                <div className="offNam margin-t-5 margin-b-5">
                  {this.props.translator["Related CIs"]}
                </div>
              </div>
            </Col>
          </Row>

          <div className="rBoxGap">
            {this.props.translator["Data not available"]}
          </div>
        </div>
      );
    } else {
      let myTreeData = this.createTreeData(
        this.props.cmdbChangeRelatedDataDetails,
        this.props.cieditdetails
      );
      const nodeSize = { x: 160, y: 160 };
      const foreignObjectProps = {
        width: nodeSize.x,
        height: nodeSize.y,
        x: 20,
        y: -20,
      };
      const translator = this.props.translator;
      return (
        <div>
          <Row>
            <Col xs={12}>
              <div className="rPageHeading  d-flex align-items-center">
                <div className="offNam flex-grow-1">
                  {this.props.translator["Related CIs"]}
                </div>
                <div className="rPageHeadActBtn d-flex top-0">
                  <button
                    type="button"
                    bsclass=""
                    bsstyle=""
                    title={"Full View"}
                    className="-primary"
                    onClick={() => {
                      let newWindow = window.open();
                      newWindow.opener = null;
                      newWindow.location =
                        app_url + "/home?visualCI=" + this.props.itemId;
                    }}
                    // onClick={() =>
                    //   window.open(
                    //     `${app_url}/home?visualCI=${this.props.itemId}`
                    //   )
                    // }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                    >
                      <path
                        fill="currentColor"
                        fill-rule="evenodd"
                        d="M12 13a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v3.5a.5.5 0 0 0 1 0V3h9v9H8.5a.5.5 0 0 0 0 1H12ZM9 6.5v3a.5.5 0 0 1-1 0V7.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 7H5.5a.5.5 0 0 1 0-1h3a.498.498 0 0 1 .5.497"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                  {/* <Link
                    to={`${app_url}/home?visualCI=true`}
                    target="_blank"
                    className="pe-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                    >
                      <path
                        fill="currentColor"
                        fill-rule="evenodd"
                        d="M12 13a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v3.5a.5.5 0 0 0 1 0V3h9v9H8.5a.5.5 0 0 0 0 1H12ZM9 6.5v3a.5.5 0 0 1-1 0V7.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 7H5.5a.5.5 0 0 1 0-1h3a.498.498 0 0 1 .5.497"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </Link> */}

                  {this.state.status ? (
                    <Button
                      size="xs"
                      onClick={() => {
                        this.editView("horizontal");
                      }}
                      title={this.props.translator["Vertical View"]}
                      style={{ width: "auto", height: "auto" }}
                    >
                      {this.props.translator["Vertical View"]}
                    </Button>
                  ) : (
                    <Button
                      size="xs"
                      onClick={() => {
                        this.editView("vertical");
                      }}
                      title={this.props.translator["Horizontal View"]}
                      style={{ width: "auto", height: "auto" }}
                    >
                      {this.props.translator["Horizontal View"]}
                    </Button>
                  )}
                </div>
              </div>
              {/* <div className="rPageHeading">
                <div className="offNam margin-t-5 margin-b-5">
                  {this.props.translator["Related CIs"]}
                </div>
              </div> */}
            </Col>
          </Row>

          <div className="rBoxGap">
            <div
              style={containerStyles}
              ref={(tc) => (this.treeContainer = tc)}
              id="treeWrapper"
            >
              <Tree
                data={myTreeData}
                zoom={this.state.zoom}
                orientation={this.state.currentView}
                translate={this.state.translate}
                nodeSvgShape={this.state.svgSquare}
                collapsible={false}
                nodeSize={nodeSize}
                separation={{ siblings: 1.2, nonSiblings: 1.2 }}
                translator={this.props.translator}
                // Here we're using `renderCustomNodeElement` render a component that uses
                // both SVG and HTML tags side-by-side.
                // This is made possible by `foreignObject`, which wraps the HTML tags to
                // allow for them to be injected into the SVG namespace.
                renderCustomNodeElement={(rd3tProps) =>
                  renderForeignObjectNode({
                    ...rd3tProps,
                    foreignObjectProps,
                    translator,
                  })
                }
                // allowForeignObjects
                // nodeLabelComponent={{
                //     render: <NodeLabel translator={this.props.translator} />,
                //     foreignObjectWrapper: {
                //         height: '90px',
                //         // width: '125px',
                //         x: 12,
                //         y: -15
                //         }
                //     }}
              />
            </div>
          </div>
        </div>
      );
    }
  }
}

const renderForeignObjectNode = ({
  nodeDatum,
  toggleNode,
  foreignObjectProps,
  translator,
}) => (
  <g>
    <circle r={15}></circle>
    {/* `foreignObject` requires width & height to be explicitly set. */}
    <foreignObject {...foreignObjectProps}>
      <div className="" title={nodeDatum.title}>
        <div>{nodeDatum.name}</div>
        <div>
          {translator["Status"]}: {nodeDatum.attributes.Status}
        </div>
      </div>
    </foreignObject>
  </g>
);

// class NodeLabel extends React.PureComponent {
//     render() {
//       const {nodeData} = this.props;
//       return (
//         <div className="" title={nodeData.title}>
//           <div>{nodeData.name}</div>
//           <div>{this.props.translator['Status']}: {nodeData.attributes.Status}</div>
//         </div>
//       );
//     }
//   }

export function mapStateToProps({ cmdbChangeRelatedDataDetails, showLoader }) {
  return {
    cmdbChangeRelatedDataDetails,
    showLoader: showLoader.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loadChangeRelatedDataDetails }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CmdbRelatedCIGraph);
