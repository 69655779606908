
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from 'react-redux';
import {loadReviewOrderList} from '../actions/reviews/reviewAction.js';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

let tableHeadColumn = [{name:'ciConsumerCompany'},{name:'ciLocationName'},{name:'ciName'},{name:'ciSupportCompany'},{name:'createdOn'},{name:'itemNumber'},{name:'orderNumber'},{name:'displayNumber'},{name:'ciServiceName'},{name:'createdByName'},{name:'requesterName'},{name:'requesterId'},{name:'requesterLocationId'},{name:'requesterLocationName'},{name:'itemStatus'},{name:'modifiedOn'},{name:'type'}];

const Breadcrumb = (props)=>{
  return (
    <Container fluid className="padding-t-10">
      <nav aria-label="Breadcrumb">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="javascript:void(0)" role="button">Home</a>
            </li>
            <li className="breadcrumb-item active">Test</li>
          </ul>
        </nav>
      </Container>
  );
}

const onPageSizeChange = (e) => {
  let pageSize = parseInt(e.target.value, 10);
  loadReviewOrderList("", "Breakfix,Item", "N", "displayNumber", "desc", "requesterId", "", "", pageSize);
}
const onPageChange = (e) => {
  let page = e - 1;
  loadReviewOrderList("", "Breakfix,Item", "N", "displayNumber", "desc", "requesterId", "", page, "");
}

const TableSec = (props)=>{
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(loadReviewOrderList("", "Breakfix,Item", "N", "displayNumber", "desc", "requesterId", "", "", ""));
    }, []);

    const tr = useSelector((state) => state.spcmReducer.tr);
    const myReviewOrdersList = useSelector((state) =>state.myReviewOrdersList);
    const meta = myReviewOrdersList.meta;

    const keyColumn = typeof myReviewOrdersList !=="undefined" && typeof myReviewOrdersList.data !=="undefined" ? Object.fromEntries(Object.entries(myReviewOrdersList.data[0]).filter(([key, value]) => value !== null && value !==0 && value !=="0" && value !=="" && value !==true && value !==false && value !==undefined && value !=="undefined" && value !=="N")) : null;
    
    const keyObject = typeof myReviewOrdersList !=="undefined" && typeof myReviewOrdersList.data !=="undefined" ? Object.keys(keyColumn) : null;

    const SelectOptions = keyObject && keyObject.map((item,index) => {
        return (<option key={index} value={item}>{item}</option>);
      });

    let headTr = keyObject && keyObject.map((item,index) => {
      return (<th key={index}>{item}</th>);
    });

    let bodypart = myReviewOrdersList.data && myReviewOrdersList.data.map((item, index) => {
      let bodyTD = keyObject && keyObject.map((keyName,indexchk) => {
        const fieldval=keyName==="ciServiceName" && keyName!==null && keyName !==""?
        item[keyName].toString().length>70?(item[keyName].toString().slice(0,70)+"..."):item[keyName] : item[keyName];
        return (<td key={indexchk}>{fieldval}</td>);
      });
      return <tr key={index}>{bodyTD}</tr>;
    });

  return (
    <div>
      <Form.Select><option>Select Columns</option>{SelectOptions}</Form.Select>

      <div aria-label='Table' role="contentinfo" className="respondv"><div className="tableRgtBor"><Table responsive striped bordered hover className="tableView nowrapWhright">
      <thead>
        <tr>{headTr}</tr>
      </thead>
      <tbody>
        {bodypart}
      </tbody>
    </Table></div></div>
    
    </div>
  );
}

const Notes =({ data })=>{
  return data.map((value) => <p>{value}</p>);
}

  const MyData = (props)=>{
  let [list,setList]=useState([]);
  let [newNote,setNewNote]=useState('');

  const addNotes=(e)=>{
    const addedNote = list.concat(newNote);
    setList(addedNote);
  }

  const handleChange=(e)=>{
    setNewNote(e.target.value);
  }
  return(
    <div>
      <h1>WIB Dynamic List</h1>
      <div className="margin-t-10 margin-b-10"><input type="text" value={newNote} onChange={handleChange} style={{border:"1px #ddd solid"}} />
      <Button onClick={addNotes} size="xs" variant="primary">Save</Button>
      <Notes data={list} />
      </div>
    </div>
  )
}

const Hello = (props)=>{
    return (
      <main>
        <Breadcrumb />
        <Container fluid>
          <MyData/>
          <TableSec />
        </Container>
      </main>
    );
}

export default Hello;