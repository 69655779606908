
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const aismAPI = createApi({
  reducerPath: "aismApi",
  baseQuery: fetchBaseQuery({
    baseUrl: window.location.origin,
  }),
  tagTypes: ["fetchGroups"],
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getAISMGroups: builder.mutation({
      query: (data) => {
        return {
          url: "/api/getAISMGroups",
          method: "POST",
          body: data,
        };
      },
    }),
    getAISMFcrDetails: builder.mutation({
      query: (data) => {
        return {
          url: "/api/getAISMBasedFCR",
          method: "POST",
          body: data,
        };
      },
    }),
    getAISMDsatDetails: builder.mutation({
      query: (data) => {
        return {
          url: "/api/getAISMBasedDSAT",
          method: "POST",
          body: data,
        };
      },
    }),
    getAISMIndividuals: builder.mutation({
      query: (data) => {
        return {
          url: "/api/getAISMIndividuals",
          method: "POST",
          body: data,
        };
      },
    }),
    getChangeSucessRate: builder.mutation({
      query: (data) => {
        return {
          url: "/api/getChangeSucessRate",
          method: "POST",
          body: data,
        }
      }
    })
  }),
});

export const { useGetAISMGroupsMutation, useGetAISMIndividualsMutation, useGetAISMFcrDetailsMutation, useGetAISMDsatDetailsMutation, useGetChangeSucessRateMutation } =
  aismAPI;
const { reducer } = aismAPI;
export default reducer;
