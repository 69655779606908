
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import { GLOBAL }  from '../../components/Globals';
import constanturl from '../../../src/utils/constants';
import * as cmdbApi from "../../services/spcmApi";

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

export function loadCmdbClassBasedListSuccess(cmdbClassBasedAssetsList) {
	return {
		type: 'LOAD_CMDB_CLASSBASEDASSETS_LIST_SUCCESS',
		cmdbClassBasedAssetsList
	};
}

export function loadCmdbCategoriesSuccess(ciCategories) {
	return {
		type: 'LOAD_CMDBCATEGORIES_LIST_SUCCESS',
		ciCategories
	};
}
export function loadCIClassSuccess(ciClass) {
	return {
		type: 'LOAD_CICLASS_SUCCESS',
		ciClass
	};
}

export function loadCIClassFailure(errorResponse) {
	return {
		type: 'LOAD_CICLASS_FAILURE',
		errorResponse
	};
}

export function loadCIStatusSuccess(ciStatus) {
	return {
		type: 'LOAD_CISTATUS_SUCCESS',
		ciStatus
	};
}

export function loadCISubStatusSuccess(ciSubStatus) {
	return {
		type: 'LOAD_CISUBSTATUS_SUCCESS',
		ciSubStatus
	};
}

export function loadCIStatusFailure(errorResponse) {
	return {
		type: 'LOAD_CISTATUS_FAILURE',
		errorResponse
	};
}

export function loadCISubStatusFailure(errorResponse) {
	return {
		type: 'LOAD_CISUBSTATUS_FAILURE',
		errorResponse
	};
}

export function loadCIMetallicSuccess(ciMetallic) {
	return {
		type: 'LOAD_CIMETALLIC_SUCCESS',
		ciMetallic
	};
}

export function loadCIMetallicFailure(errorResponse) {
	return {
		type: 'LOAD_CIMETALLIC_FAILURE',
		errorResponse
	};
}

export function loadCIEnvSuccess(ciEnv) {
	return {
		type: 'LOAD_CIENV_SUCCESS',
		ciEnv
	};
}

export function loadCIResidualRiskSuccess(ciResidualRisk) {
	return {
		type: 'LOAD_CIRESIDUALRISK_SUCCESS',
		ciResidualRisk
	};
}

export function loadApprovalsAutoActionSuccess(approvalsAutoAction) {
	return {
		type: 'LOAD_APPROVALSAUTOACTION_SUCCESS',
		approvalsAutoAction
	};
}

export function loadCIEnvFailure(errorResponse) {
	return {
		type: 'LOAD_CIENV_FAILURE',
		errorResponse
	};
}

export function loadCIManrSuccess(ciManr) {
	return {
		type: 'LOAD_CIMANR_SUCCESS',
		ciManr
	};
}

export function loadCIManrFailure(errorResponse) {
	return {
		type: 'LOAD_CIMANR_FAILURE',
		errorResponse
	};
}

export function loadCIDatasetSuccess(ciDataset) {
	return {
		type: 'LOAD_CIDATASET_SUCCESS',
		ciDataset
	};
}

export function loadCIDatasetFailure(errorResponse) {
	return {
		type: 'LOAD_CIDATASET_FAILURE',
		errorResponse
	};
}

export function loadCICompanySuccess(ciCompany) {
	return {
		type: 'LOAD_CICOMPANY_SUCCESS',
		ciCompany
	};
}

export function load_Report_CompanySuccess(companyReportData) {
	//console.log("load_Report_CompanySuccess() has been called !!!!!")
	return {
		type: 'LOAD_REPORT_COMPANY_SUCCESS',
		companyReportData
	};
}

export function loadCICompanyFailure(errorResponse) {
	return {
		type: 'LOAD_CICOMPANY_FAILURE',
		errorResponse
	};
}

export function loadCILocationSuccess(ciLocation) {
	return {
		type: 'LOAD_CILOCATION_SUCCESS',
		ciLocation
	};
}
export function loadCIUserBasedLocationSuccess(ciUserBasedLocation){
	return{
		type:'LOAD_CIUSERBASEDLOCATION_SUCCESS',
		ciUserBasedLocation
	};
}
export function loadCIUserBasedLocationFailure(errorResponse) {
	return {
		type: 'LOAD_CIUSERBASEDLOCATION_FAILURE',
		errorResponse
	};
}
export function loadCILocationFailure(errorResponse) {
	return {
		type: 'LOAD_CILOCATION_FAILURE',
		errorResponse
	};
}

export function loadCIGroupSuccess(ciGroup) {
	return {
		type: 'LOAD_CIGROUP_SUCCESS',
		ciGroup
	};
}

export function loadCIGroupForTaskPlanSuccess(ciGroupForTaskPlan) {
	return {
		type: 'LOAD_CIGROUP_FORTASKPLAN_SUCCESS',
		ciGroupForTaskPlan
	};
}

export function loadCIGroupFailure(errorResponse) {
	return {
		type: 'LOAD_CIGROUP_FAILURE',
		errorResponse
	};
}

export function loadCIIndSuccess(ciInd) {
	return {
		type: 'LOAD_CIIND_SUCCESS',
		ciInd
	};
}

export function loadCIIndFailure(errorResponse) {
	return {
		type: 'LOAD_CIIND_FAILURE',
		errorResponse
	};
}

export function loadCIEditDetailsSuccess(CIEditDetails) {
	//alert('in success')
	return {
		type: 'LOAD_CIEDITDETAILS_SUCCESS',
		CIEditDetails
	};
}

export function loadCIModelNameSuccess(CIModelName) {
	//alert('in success')
	return {
		type: 'LOAD_CI_MODEL_NAME',
		CIModelName
	};
}

export function loadCIEditDetailsFailure(errorResponse) {
	return {
		type: 'LOAD_CIEDITDETAILS_FAILURE',
		errorResponse
	};
}

export function loadCICatSuccess(ciCat) {
	return {
		type: 'LOAD_CICAT_SUCCESS',
		ciCat
	};
}

export function loadCICatFailure(errorResponse) {
	return {
		type: 'LOAD_CICAT_FAILURE',
		errorResponse
	};
}


export function loadCITypeSuccess(ciType) {
	return {
		type: 'LOAD_CITYPE_SUCCESS',
		ciType
	};
}



export function loadCITypeFailure(errorResponse) {
	return {
		type: 'LOAD_CITYPE_FAILURE',
		errorResponse
	};
}

export function loadCISCatSuccess(ciSCat) {
	return {
		type: 'LOAD_CISCAT_SUCCESS',
		ciSCat
	};
}

export function loadCISCatFailure(errorResponse) {
	return {
		type: 'LOAD_CISCAT_FAILURE',
		errorResponse
	};
}

export function loadCIAttSuccess(ciAtt) {
	return {
		type: 'LOAD_CIATT_SUCCESS',
		ciAtt
	};
}

export function loadCIAttFailure(errorResponse) {
	return {
		type: 'LOAD_CIATT_FAILURE',
		errorResponse
	};
}

export function loadCISupCompanySuccess(ciSupCompany) {
	return {
		type: 'LOAD_CISUPCOMPANY_SUCCESS',
		ciSupCompany
	};
}

export function loadVendorComSuccess(ciSupCompany) {
	return {
		type: 'LOAD_VENDOR_SUCESS',
		ciSupCompany
	};
}

export function loadCISupCompanyFailure(errorResponse) {
	return {
		type: 'LOAD_CISUPCOMPANY_FAILURE',
		errorResponse
	};
}

export function loadCMDBSupportCompanySuccess(cmdbSupportCompany) {
	return {
		type: 'LOAD_CMDBSUPPORTCOMPANY_SUCCESS',
		cmdbSupportCompany
	};
}

export function loadCMDBSupportCompanyFailure(errorResponse) {
	return {
		type: 'LOAD_CMDBSUPPORTCOMPANY_FAILURE',
		errorResponse
	};
}

export function loadCIClass(status = 'ALL') {
	let strObj = {};
	strObj.status = status;
	strObj = JSON.stringify(strObj);
	let headerObj = { headers: { query: strObj } };
	return (dispatch) => {

		api.get(GLOBAL.ciClassUrl,headerObj)
			.then((ciClass) => {
				if (!ciClass) {
					throw Error(ciClass.statusText);
				}
				return ciClass;
			})
			.then((ciClass) => {
				//console.log('success in ciClass...');
				dispatch(loadCIClassSuccess(ciClass));
			})
			.catch((err) => {
				//console.log(err);
			});
	};
}



export function loadCIStatus() {
	let url = "";
	//if(mod=="Breakfix")
	//url=constanturl.cmdburl+"/api/menulist?field1_name=Status&module=CI";

	return (dispatch) => {
		api.get(GLOBAL.ciStatusUrl)
			.then((ciStatus) => {
				if (!ciStatus) {
					throw Error(ciStatus.statusText);
				}
				return ciStatus;
			})
			.then((ciStatus) => {
				//console.log('success in group...');
				dispatch(loadCIStatusSuccess(ciStatus));
			})
			.catch((err) => {
				//console.log(err);
			});
	};
}

export function loadCIStatusCurrent(type, selectedStatus) {
	//let url="";
	//if(mod=="Breakfix")
	//url="requestId/"+requestId+"/selectedStatus/"+selectedStatus"
	{/*if(type == 'create')
	url=constanturl.cmdburl+"/api/menulist/statusTransitions";
	else if(type == 'edit')
	url=constanturl.cmdburl+"/api/menulist/statusTransitions?statusId="+selectedStatus;*/}

	return (dispatch) => {

		axios.get(GLOBAL.ciCurrentStatusUrl + "/type/" + type + "/selectedStatus/" + selectedStatus)
			.then((ciStatus) => {
				if (!ciStatus) {
					throw Error(ciStatus.statusText);
				}
				return ciStatus;
			})
			.then((ciStatus) => {
				//console.log('success in group...');
				dispatch(loadCIStatusSuccess(ciStatus));
			})
			.catch((err) => {
				//console.log(err);
			});


	};
}

export function loadCIMenulistStatus(filter) {
	let str = {};
	str.filter = filter;
	// str.currentStatus = currentStatus;
	// str.type = type;
	str = JSON.stringify(str);
	return (dispatch) => {
		axios.get(GLOBAL.ciMenulistStatus, { headers: { 'query': str } })
			.then((ciStatus) => {
				if (!ciStatus) {
					throw Error(ciStatus.statusText);
				}
				return ciStatus;
			})
			.then((ciStatus) => {
				//console.log('loadCIMenulistStatus...', ciStatus);
				dispatch(loadCIStatusSuccess(ciStatus));
			})
			.catch((err) => {
				//console.log(err);
			});
	};
}

export function loadCIMenulistSubStatus(filter, statusId) {
	let str = {};
	str.filter = filter;
	str.statusId = statusId;
	str = JSON.stringify(str);
	return (dispatch) => {
		axios.get(GLOBAL.ciMenulistStatus, { headers: { 'query': str } })
			.then((ciSubStatus) => {
				if (!ciSubStatus) {
					throw Error(ciSubStatus.statusText);
				}
				return ciSubStatus;
			})
			.then((ciSubStatus) => {
				//console.log('loadCIMenulistSubStatus...', ciSubStatus);
				dispatch(loadCISubStatusSuccess(ciSubStatus));
			})
			.catch((err) => {
				//console.log(err);
			});
	};
}

export function loadCIMenulistAllStatuses() {
	let str = {};
	str.filter = "Status List";
	str = JSON.stringify(str);
	return (dispatch) => {
		axios.get(GLOBAL.ciMenulistStatus, { headers: { 'query': str } })
			.then((ciAllStatus) => {
				if (!ciAllStatus) {
					throw Error(ciAllStatus.statusText);
				}
				return ciAllStatus;
			})
			.then((ciAllStatus) => {
				//console.log('loadCIMenulistStatus...', ciAllStatus);
				dispatch({
					type: 'LOAD_CIALLSTATUS_SUCCESS',
					ciAllStatus
				});
			})
			.catch((err) => {
				//console.log(err);
			});
	};
}

export function loadCIMenulistAllSubStatuses() {
	let str = {};
	str.filter = "SubStatus List";
	str = JSON.stringify(str);
	return (dispatch) => {
		axios.get(GLOBAL.ciMenulistStatus, { headers: { 'query': str } })
			.then((ciSubStatus) => {
				if (!ciSubStatus) {
					throw Error(ciSubStatus.statusText);
				}
				return ciSubStatus;
			})
			.then((ciSubStatus) => {
				//console.log('loadCIMenulistSubStatus...', ciSubStatus);
				dispatch(loadCISubStatusSuccess(ciSubStatus));
			})
			.catch((err) => {
				//console.log(err);
			});
	};
}

export function loadCIMetallic() {
	let url = "";
	//if(mod=="Breakfix")
	//url=constanturl.cmdburl+"/api/menulist?field1_name=Metallic&module=CI";
	url = GLOBAL.ciBillingUrl;

	return (dispatch) => {

		api.get(url)
			.then((ciMetallic) => {
				if (!ciMetallic) {
					throw Error(ciMetallic.statusText);
				}
				return ciMetallic;
			})
			.then((ciMetallic) => {
				//console.log('success in metallic...');
				dispatch(loadCIMetallicSuccess(ciMetallic));
			})
			.catch((err) => {
				//console.log(err);
			});

	};
}

export function loadCIEnv() {
	let url = "";
	//if(mod=="Breakfix")
	//url=constanturl.cmdburl+"/api/menulist?field1_name=Environment&module=CI";
	url = GLOBAL.ciEnvUrl;

	return (dispatch) => {

		api.get(url)
			.then((ciEnv) => {
				if (!ciEnv) {
					throw Error(ciEnv.statusText);
				}
				return ciEnv;
			})
			.then((ciEnv) => {
				//console.log('success in env...');
				dispatch(loadCIEnvSuccess(ciEnv));
			})
			.catch((err) => {
				//console.log(err);
			});


	};
}

export function loadCIResidualRisk(companyId) {
	return (dispatch) => {
		api.get(GLOBAL.ciResidualRiskUrl + '?companyId=' + companyId)
			.then((ciResidualRisk) => {
				if (!ciResidualRisk) {
					throw Error(ciResidualRisk.statusText);
				}
				return ciResidualRisk;
			})
			.then((ciResidualRisk) => {
				//console.log('success in ciResidualRisk...');
				dispatch(loadCIResidualRiskSuccess(ciResidualRisk));
			})
			.catch((err) => {
				//console.log(err);
			});
	};
}

export function loadApprovalsAutoAction(moduleName, menuName) {
    let strObj = {};
    strObj.moduleName = moduleName;
    strObj.menuName = menuName;
    strObj = JSON.stringify(strObj);
	return (dispatch) => {
		api.get(GLOBAL.sxMenulistUrl, { headers: { query: strObj } })
			.then((approvalsAutoAction) => {
				if (!approvalsAutoAction) {
					throw Error(approvalsAutoAction.statusText);
				}
				return approvalsAutoAction;
			})
			.then((approvalsAutoAction) => {
				//console.log('success in loadApprovalsAutoAction...');
				if (approvalsAutoAction.status == 204) {
					dispatch(loadApprovalsAutoActionSuccess({data: []}));
				} else {
					dispatch(loadApprovalsAutoActionSuccess(approvalsAutoAction));
				}
			})
			.catch((err) => {
				//console.log(err);
			});
	};
}

export function loadCIManr() {
	let url = "";
	//if(mod=="Breakfix")
	url = GLOBAL.ciManufacturerUrl;

	return (dispatch) => {

		api.get(url)
			.then((ciManr) => {
				if (!ciManr) {
					throw Error(ciManr.statusText);
				}
				return ciManr;
			})
			.then((ciManr) => {
				//console.log('success in manufacturer...');
				dispatch(loadCIManrSuccess(ciManr));
			})
			.catch((err) => {
				//console.log(err);
			});


	};
}

export function loadCIDataset() {
	let url = "";
	//if(mod=="Breakfix")
	url = constanturl.cmdburl + "/api/menulist?field1_name=Dataset&module=CI";

	return (dispatch) => {

		axios.get(url)
			.then((ciDataset) => {
				if (!ciDataset) {
					throw Error(ciDataset.statusText);
				}
				return ciDataset;
			})
			.then((ciDataset) => {
				//console.log('success in dataset...');
				dispatch(loadCIDatasetSuccess(ciDataset));
			})
			.catch((err) => {
				//console.log(err);
			});


	};
}

export function loadCICompany(userid) {
	let url = "";
	//if(mod=="Breakfix")
	url = GLOBAL.ciCompanyUrl + "/userId/" + userid
	//GLOBAL.ciCompanyUrl+"/userId/"+userid

	return (dispatch) => {

		axios.get(url)
			.then((ciCompany) => {
				if (!ciCompany) {
					throw Error(ciCompany.statusText);
				}
				return ciCompany;
			})
			.then((ciCompany) => {
				//console.log('success in company...');
				dispatch(loadCICompanySuccess(ciCompany));
			})
			.catch((err) => {
				//console.log(err);
			});


	};
}


export function loadReportDataCompany(userid) {
	//console.log("loadCICompany_1() has been called !!!!!")
	let url = "";
	//if(mod=="Breakfix")
	url = GLOBAL.ciCompanyUrl_1 + "/userId/" + userid
	//GLOBAL.ciCompanyUrl+"/userId/"+userid

	return (dispatch) => {

		axios.get(url)
			.then((companyReportData) => {
				if (!companyReportData) {
					throw Error(companyReportData.statusText);
				}
				return companyReportData;
			})
			.then((companyReportData) => {
				//console.log('success in company...');
				dispatch(load_Report_CompanySuccess(companyReportData));
			})
			.catch((err) => {
				//console.log(err);
			});


	};
}


export function loadCILocation() {
	let url = "";
	//if(mod=="Breakfix")

	url = GLOBAL.ciLocationUrl

	return (dispatch) => {

		api.get(url)
			.then((ciLocation) => {
				if (!ciLocation) {
					throw Error(ciLocation.statusText);
				}
				return ciLocation;
			})
			.then((ciLocation) => {
				//console.log('success in location...');
				dispatch(loadCILocationSuccess(ciLocation));
			})
			.catch((err) => {
				//console.log(err);
			});


	};
}

export function loadCIGroup(companyid) {
	let url = "";
	//if(mod=="Breakfix")

	url = GLOBAL.ciSupportGroupUrl + "/id/" + companyid;

	return (dispatch) => {

		axios.get(url)
			.then((ciGroup) => {
				if (!ciGroup) {
					throw Error(ciGroup.statusText);
				}
				return ciGroup;
			})
			.then((ciGroup) => {
				//console.log('success in group...');
				dispatch(loadCIGroupSuccess(ciGroup));
			})
			.catch((err) => {
				//console.log(err);
			});


	};
}

export function emptyCIGroup() {
	return (dispatch) => {
		dispatch({ type: 'EMPTY_CIGROUP_SUCCESS' });
	};
}

export function loadCIGroupForTaskPlan(companyid) {
	let url = "";
	//if(mod=="Breakfix")

	url = GLOBAL.ciSupportGroupUrl + "/id/" + companyid;

	return (dispatch) => {

		axios.get(url)
			.then((ciGroup) => {
				if (!ciGroup) {
					throw Error(ciGroup.statusText);
				}
				return ciGroup;
			})
			.then((ciGroup) => {
				//console.log('success in group...');
				dispatch(loadCIGroupForTaskPlanSuccess(ciGroup));
			})
			.catch((err) => {
				//console.log(err);
			});


	};
}

export function loadCIInd(companyid) {
	let url = "";
	//if(mod=="Breakfix")
	//url="https://xsm-f.mybluemix.net/api/userListSelfService?groupId="+groupid;

	url = GLOBAL.ciBusinessOwnerUrl + "/id/" + companyid;


	return (dispatch) => {

		axios.get(url)
			.then((ciInd) => {
				if (!ciInd) {
					throw Error(ciInd.statusText);
				}
				return ciInd;
			})
			.then((ciInd) => {
				//console.log('success in group...');
				dispatch(loadCIIndSuccess(ciInd));
			})
			.catch((err) => {
				//console.log(err);
			});


	};
}

export function loadCIEditDetails(ciId) {
	//alert('action called and parameter is '+breakFixId)
	return (dispatch) => {
		//alert("EditGroupIdAction123"+breakFixId);
		if (ciId == undefined || ciId == "") {
			//alert("H2 breakFixId not found");
			return Promise.resolve({status: 400, data: 'error'});
		} else {
			//alert("my url is "+GLOBAL.breakFixEditUrl+breakFixId);
			//axios.get(constanturl.cmdburl+"/api/config_items/"+ciId)
			dispatch({ type: 'FETCHING_DETAILS_IN_PROGRESS' });
			return axios.get(GLOBAL.ciEditDetailsUrl + "/id/" + ciId)
				.then((CIEditDetails) => {
					if (!CIEditDetails) {//alert('not breakFix')
						//dispatch(loadBreakFixFailure(breakFix.statusText))
						throw Error(CIEditDetails.statusText);
					}
					return CIEditDetails;
				})
				.then((CIEditDetails) => {//alert('yes it is')
					dispatch(loadCIEditDetailsSuccess(CIEditDetails));
					dispatch({ type: 'DETAILS_FETCHED_SUCCESSFULLY' });
					return Promise.resolve({status: 200, data: CIEditDetails});
				})
				.catch((err) => {
					//console.log(err);
					return Promise.resolve({status: 400, data: err});
				});
			////console.log("not calling loadBreakFixEditDetails");
		}
	};
}

export function emptyCIEditDetailsReducer() {
	//alert('action called and parameter is '+breakFixId)
	return (dispatch) => {
		dispatch({ type: 'EMPTY_CIEDITDETAILS', CIEditDetails: [] });
		dispatch({ type: 'FETCHING_DETAILS_IN_PROGRESS' });
	};
}

export function emptyCICatReducer() {
	return (dispatch) => {
		dispatch({ type: 'EMPTY_CICAT_SUCCESS' });
	};
}

export function emptyCISCatReducer() {
	return (dispatch) => {
		dispatch({ type: 'EMPTY_CISCAT_SUCCESS' });
	};
}

export function loadModelName(ciId) {
	//alert('action called and parameter is '+breakFixId)
	let str = {};
	str = JSON.stringify(ciId);
	return (dispatch) => {
		if (ciId == undefined || ciId == "") {
		} else {
			//dispatch({ type: 'FETCHING_DETAILS_IN_PROGRESS' });
			axios.get(GLOBAL.ciModelName, { headers: { "ciid": ciId } })
				.then((CIEditDetails) => {
					if (!CIEditDetails) {//alert('not breakFix')
						//dispatch(loadBreakFixFailure(breakFix.statusText))
						throw Error(CIEditDetails.statusText);
					}
					return CIEditDetails;
				})
				.then((CIEditDetails) => {//alert('yes it is')
					dispatch(loadCIModelNameSuccess(CIEditDetails));
					//dispatch({ type: 'DETAILS_FETCHED_SUCCESSFULLY' });
				})
				.catch((err) => {
					//console.log(err);
				});
			////console.log("not calling loadBreakFixEditDetails");
		}
	};
}

export function loadCICat(classId, companyId, board='') {
	let data = {};
	data.classId = classId;
	data.companyId = companyId;

	let str = {};
	if(board != '') {	
		str.board = board;
	}
	str = JSON.stringify(str);
	
	return (dispatch) => {
		axios.post(GLOBAL.ciCatUrl, data, { headers: { 'query': str } })
		
			.then((ciCat) => {
				if (!ciCat) {
					throw Error(ciCat.statusText);
				}
				return ciCat;
			})
			.then((ciCat) => {
				//console.log('success in cat...');
				dispatch(loadCICatSuccess(ciCat));
			})
			.catch((err) => {
				//console.log(err);
			});
	};
}

export function loadCICatInitial(classId, companyId, selectedCategoryName, callback, board='') {
	let data = {};
	data.classId = classId;
	data.companyId = companyId;
	let selectedCompanyId = 0;

	let str = {};
	if(board != '') {	
		str.board = board;
	}
	str = JSON.stringify(str);

	return (dispatch) => {
		axios.post(GLOBAL.ciCatUrl, data, { headers: { 'query': str } })
			.then((ciCat) => {
				if (!ciCat) {
					throw Error(ciCat.statusText);
				}
				return ciCat;
			})
			.then((ciCat) => {
				//console.log('success in cat...');
				dispatch(loadCICatSuccess(ciCat));
				ciCat.data.forEach((item,i)=>{
					if(selectedCategoryName == item.category_name) {
						selectedCompanyId = item.cat_company_id;
					}
				})
				callback(selectedCompanyId);
			})
			.catch((err) => {
				//console.log(err);
			});
	};
}

export function loadCIType() {
	let url = "";
	//if(mod=="Breakfix")
	///api/menulist?field1_name=Type&module=CI
	url = "/api/menulist/getCIType";
	//console.log("URL", url);
	return (dispatch) => {

		axios.get(url)
			.then((ciType) => {
				if (!ciType) {
					throw Error(ciType.statusText);
				}
				return ciType;
			})
			.then((ciType) => {
				//console.log('success in cat...');
				dispatch(loadCITypeSuccess(ciType));
			})
			.catch((err) => {
				//console.log(err);
			});


	};
}

export function loadCISCat(categoryName, classId, companyId) {
	let data = {};
	data.classId = classId;
	data.companyId = companyId;
	data.categoryName = categoryName;

	return (dispatch) => {
		axios.post(GLOBAL.ciSubCatUrl, data)
			.then((ciSCat) => {
				if (!ciSCat) {
					throw Error(ciSCat.statusText);
				}
				return ciSCat;
			})
			.then((ciSCat) => {
				//console.log('success in subcat...');
				dispatch(loadCISCatSuccess(ciSCat));
			})
			.catch((err) => {
				//console.log(err);
			});
	};
}

export function loadCIAtt(ciId) {
	let url = "";
	//if(mod=="Breakfix")
	//url=constanturl.cmdburl+"/api/config_items/"+ciId+"/attributes";
	url = GLOBAL.ciArrtibuteUrl + "/id/" + ciId;
	return (dispatch) => {

		axios.get(url)
			.then((ciAtt) => {
				if (!ciAtt) {
					throw Error(ciAtt.statusText);
				}
				return ciAtt;
			})
			.then((ciAtt) => {
				//console.log('success in subcat...');
				dispatch(loadCIAttSuccess(ciAtt));
			})
			.catch((err) => {
				//console.log(err);
			});


	};
}
//contractVendorUrl
export function loadVendorCompany(companyid) {
	let url = "";
	//if(mod=="Breakfix")

	url = GLOBAL.contractVendorUrl + "/" + companyid;

	return (dispatch) => {

		axios.get(url)
			.then((ciSupCompany) => {
				if (!ciSupCompany) {
					throw Error(ciSupCompany.statusText);
				}
				return ciSupCompany;
			})
			.then((ciSupCompany) => {
				//console.log('success in sup company...');
				dispatch(loadVendorComSuccess(ciSupCompany));
			})
			.catch((err) => {
				//console.log(err);
			});


	};
}
export function loadCISupCompany(companyid) {
	let url = "";
	//if(mod=="Breakfix")

	url = GLOBAL.ciSupportCompanyUrl + "/id/" + companyid;

	return (dispatch) => {

		axios.get(url)
			.then((ciSupCompany) => {
				if (!ciSupCompany) {
					throw Error(ciSupCompany.statusText);
				}
				return ciSupCompany;
			})
			.then((ciSupCompany) => {
				//console.log('success in sup company...');
				dispatch(loadCISupCompanySuccess(ciSupCompany));
			})
			.catch((err) => {
				//console.log(err);
			});


	};
}

export function emptyCISupCompany() {
	return (dispatch) => {
		dispatch({ type: 'EMPTY_CISUPCOMPANY_SUCCESS' });
	};
}

//========
export function loadCINameSuccess(ciName) {
	return {
		type: 'LOAD_CICNAME_SUCCESS',
		ciName
	};
}
export function loadCiName(companyid) {
	let url = "";
	//if(mod=="Breakfix")
	url = GLOBAL.ciNameUrl + companyid
	//GLOBAL.ciCompanyUrl+"/userId/"+userid

	return (dispatch) => {

		api.get(url)
			.then((ciName) => {
				if (!ciName) {
					throw Error(ciName.statusText);
				}
				return ciName;
			})
			.then((ciName) => {
				//console.log('success in ciName...');
				dispatch(loadCINameSuccess(ciName));
			})
			.catch((err) => {
				//console.log(err);
			});
	};
}
//========

export function loadCMDBSupportCompany() {
	let url = "";
	//if(mod=="Breakfix")

	url = "/asset-config/api/getCiSupportCompany";

	return (dispatch) => {

		axios.get(url)
			.then((cmdbSupportCompany) => {
				//console.log('success in sup company...');
				dispatch(loadCMDBSupportCompanySuccess(cmdbSupportCompany));
			})
			.catch((err) => {
				//console.log(err);
			});


	};
}

export function loadCmdbClassBasedList(class_id, type) {
	return (dispatch) => {
		// dispatch({ type: "LOADER_IN_PROGRESS" });
		axios.get("/api/config_items/ciDetailsByClassId?class_id=" + class_id, { headers: { "type": type } })
			.then((cmdbClassBasedAssetsList) => {
				if (!cmdbClassBasedAssetsList) {
					throw Error(cmdbClassBasedAssetsList.statusText);
				}
				return cmdbClassBasedAssetsList;
			})
			.then((cmdbClassBasedAssetsList) => {
				dispatch(loadCmdbClassBasedListSuccess(cmdbClassBasedAssetsList));
				// dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
			})
			.catch((err) => {
				//console.log(err);
				// dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
			});
	};
}


export const getCmdbApproverCriteria = () => {
	return (dispatch) => {
		cmdbApi
			.getApprovalLOVAPI("approverCriteriaCMDB", 0, 0)
			.then((resp) => {
				dispatch({
					type: "APPROVER_CRITERIA",
					payload: resp.data,
				});
			})
			.catch((err) => {
				dispatch({
					type: "APPROVER_CRITERIA",
					payload: [],
				});
			});
	};
};

export const getCmdbApproverLevel = (companyId) => {
	return (dispatch) => {
		cmdbApi
			.getApprovalLOVAPI("level", companyId, "14")
			.then((resp) => {
				dispatch({
					type: "APPROVER_LEVEL",
					payload: resp.data,
				});
			})
			.catch((err) => {
				dispatch({
					type: "APPROVER_LEVEL",
					payload: [],
				});
			});
	};
};

export const getCmdbApprovals = (
	ciid,
	searchParam = "",
	searchtext = "",
	pageNo,
	pageSize
) => {
	return (dispatch) => {
		dispatch({
			type: "SPCM_FETCHING",
			spcmFetchingService: false,
			spcmFetchingAttribute: true,
			spcmFetchingError: false,
			spcmFetchingModule: "APPROVAL",
		});
		return cmdbApi
			.getCmdbApprovalsAPI(ciid, searchParam, searchtext, pageNo, pageSize)
			.then((resp) => {
				if (resp.status == 200)
					dispatch({
						type: "APPROVAL",
						payload: resp.data,
					});
				else
					dispatch({
						type: "APPROVAL",
						payload: [],
					});
				dispatch({
					type: "SPCM_FETCHING",
					spcmFetchingService: false,
					spcmFetchingAttribute: false,
					spcmFetchingError: false,
					spcmFetchingModule: "APPROVAL",
				});
				if (resp.status == 200){
					return Promise.resolve(resp.data);
				}
				else{
					return Promise.reject('Error');
				}
			})
			.catch((err) => {
				dispatch({
					type: "APPROVAL",
					payload: [],
				});
				dispatch({
					type: "SPCM_FETCHING",
					spcmFetchingService: false,
					spcmFetchingAttribute: false,
					spcmFetchingError: true,
					spcmFetchingModule: "APPROVAL",
					spcmErrorMsg: (err && err.response && err.response.data) ? err.response.data : 'Error',
				});
				return Promise.reject('Error');
			});
	};
};

export function loadCmdbCategories() {
	return (dispatch) => {
		//console.log("I am here");
		axios.get("/api/allCategories/")
			.then((ciCategories) => {
				//console.log("CICATEGORIES", ciCategories);
				dispatch(loadCmdbCategoriesSuccess(ciCategories));
			})
			.catch((err) => {
				//console.log(err);
			});
	};
}

export function loadCIUserBasedLocation() {
	let url = "";
	url = GLOBAL.getCIUserBasedLocationUrl;
	return (dispatch) => {
		api.get(url)
			.then((ciUserBasedLocation) => {
				if (!ciUserBasedLocation) {
					throw Error(ciUserBasedLocation.statusText);
				}
				return ciUserBasedLocation;
			})
			.then((ciUserBasedLocation) => {
				//console.log('success in location...');
				dispatch(loadCIUserBasedLocationSuccess(ciUserBasedLocation));
			})
			.catch((err) => {
				//console.log(err);
			});
	};
}

export const getCmdbChainEntities = (ci_id, queryBy = "", query = "", page, limit) => {
	
	let str = {};
	if (queryBy != "") {
		str[queryBy] = query;
	}
	// if (sort != "") {
	// 	str.sortBy = sort;
	// }
	str = JSON.stringify(str);
	
	return (dispatch) => {
		dispatch({
			type: "SPCM_FETCHING",
			spcmFetchingService: false,
			spcmFetchingAttribute: true,
			spcmFetchingError: false,
			spcmFetchingModule: "CI_CHAIN_ENTITIES",
		});
		api.get("/api/chain/" + ci_id + "/limit/" + limit + "/page/" + page, queryBy != ""? { headers: { query: str } } : '')
			.then((resp) => {
				//console.log(resp);
				dispatch({
					type: "CI_CHAIN_ENTITIES",
					payload: resp.data,
				});

				dispatch({
					type: "SPCM_FETCHING",
					spcmFetchingService: false,
					spcmFetchingAttribute: false,
					spcmFetchingError: false,
					spcmFetchingModule: "CI_CHAIN_ENTITIES",
				});
			})
			.catch((err) => {
				dispatch({
					type: "CI_CHAIN_ENTITIES",
					payload: [],
				});

				dispatch({
					type: "SPCM_FETCHING",
					spcmFetchingService: false,
					spcmFetchingAttribute: false,
					spcmFetchingError: true,
					spcmFetchingModule: "CI_CHAIN_ENTITIES",
					//spcmErrorMsg:err.response.data
				});
			});
	};
};

export const getFdnTagData = (reqParams) => {
	let config = {
	  headers: {
		'query': JSON.stringify(reqParams)
	  }
	};
	const response = axios.get(GLOBAL.getFdnTagDataUrl,config);
	return dispatch => {
	  response.then(data => {
			//console.log("newdata====", data )
		dispatch({
		  type: "FDN_TAG_LIST",
		  payload: data.data
		});
	  });
	};

  };

  export function loadCISubStatusSuccessCopy(ciSubStatusCopy) {
	return {
		type: 'LOAD_CISUBSTATUS_SUCCESS_COPY',
		ciSubStatusCopy
	};
}

  export function loadCIMenulistSubStatusCopy(filter, statusId) {
	let str = {};
	str.filter = filter;
	str.statusId = statusId;
	str = JSON.stringify(str);
	return (dispatch) => {
		axios.get(GLOBAL.ciMenulistStatus, { headers: { 'query': str } })
			.then((ciSubStatusCopy) => {
				if (!ciSubStatusCopy) {
					throw Error(ciSubStatusCopy.statusText);
				}
				return ciSubStatusCopy;
			})
			.then((ciSubStatusCopy) => {
				//console.log('loadCIMenulistSubStatus...', ciSubStatusCopy);
				dispatch(loadCISubStatusSuccessCopy(ciSubStatusCopy));
			})
			.catch((err) => {
				//console.log(err);
			});
	};
}