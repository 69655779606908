
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import { Field, Formik, Form } from "formik";
import { useParams, useNavigate } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  useCreateForumMutation,
  useUpdateForumMutation,
  useGetForumByIdQuery,
} from "../../../../reducers/foundation/forum/forumQuery";

import { loadBusinessFunctionList } from "../../../../actions/businessFunction/businessFunctionAction";
import PindCategories from "../../../common/header/pind-categories";
import Breadcrumbs from "../../../common/header/breadcrumbs";
import Header from "./header";
import FormComponent from "./add-edit";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import "_Css/form/_form.scss";
import RightSide from "../rightside";

const Index = () => {
  const formikRef = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tr = useSelector((state) => state.spcmReducer.tr);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [companyValue, setCompanyValue] = useState();
  const [submitError, setSubmitError] = useState(null);
  const [onClose, setOnClose] = useState(true);

  const [saveForum, { isLoading: saveForumLoading }] = useCreateForumMutation();

  const [updateForum, { isLoading: editFrmLoading }] = useUpdateForumMutation();

  const forumId = params?.forumId;
  const isAddMode = !forumId;

  const [initVals, setInitVals] = useState({
    status: "",
    forumName: "",
    customerName: "",
    trackName: "",
    subTrackName: "",
    regionName: "",
    type: "IT",
    objective: "",
    functionName: "",
    functionId: "",
    customerId: "",
  });

  let initialValues = {
    ...initVals,
  };
  const validationSchema = Yup.object().shape({
    forumName: Yup.string().required("Required"),
    customerName: Yup.string().required("Required"),
    status: Yup.string().required("Required"),
    type: Yup.string().required("Required"),
    objective: Yup.string().required("Required"),
    customerId: Yup.string().required("Required"),
    functionName: Yup.string().required("Required"),
  });

  const onSubmit = async (fields, { resetForm, setSubmitting, errors }) => {
    const postData = {
      ...fields,
    };

    if (isAddMode) {
      try {
        const savefrm = await saveForum({
          data: postData,
          queryString: `?tenantId=${fields?.customerId}`,
        }).unwrap();
        setSubmitting(false);
        if (savefrm?.forumId) {
          setTimeout(() => navigate(`/editforum/${savefrm?.forumId}`), 500);
        }
      } catch (err) {
        setSubmitError(err?.data || "Error");
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        const reponse = await updateForum({
          data: postData,
          queryString: `?tenantId=${fields?.customerId}`,
        }).unwrap();
        setSubmitting(false);
        if (reponse?.forumId) {
          setTimeout(() => navigate(`/forum`), 1000);
        }
      } catch (err) {
        setSubmitError(err?.data || "Error");
      } finally {
        setSubmitting(false);
      }
    }
  };

  const { data: editForumData, isLoading: editForumLoading } =
    useGetForumByIdQuery(
      {
        id: forumId,
        queryString: `?forumId=${forumId}`,
      },
      {
        skip: !forumId,
      }
    );

  useEffect(() => {
    if (!isAddMode) {
      setInitVals((prev) => {
        return {
          ...prev,
          ...editForumData,
        };
      });
      setSelectedCompanyId(editForumData?.customerId);
      setCompanyValue(editForumData?.customerName);
    }
  }, [editForumData]);

  useEffect(() => {
    (async function () {})();
  }, []);

  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Breadcrumbs
          activePageName={"Create"}
          parentPageurl="/forum"
          parentPageName={tr["Forum"]}
        />
      </Container>

      <Container fluid>
        <Row>
          <Col xs={12}>
            <Header
              isSubmitting={formikRef?.current?.isSubmitting}
              dirty={formikRef?.current?.dirty}
              submitForm={formikRef?.current?.handleSubmit}
              formikref={formikRef}
              errors={formikRef?.current?.errors}
              submitError={submitError}
              setOnClose={setOnClose}
            />
          </Col>
        </Row>
        <PanelGroup direction="horizontal">
          <Panel id="sidebar" minSize={33} order={1} defaultSize={!isAddMode && onClose ? 67 : 100} className={!isAddMode && onClose ? "isShowLeftPanel catBordDv" : "catBordDv"}>
          <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={onSubmit}
            >
              {({
                errors,
                touched,
                isSubmitting,
                setFieldValue,
                handleBlur,
                values,
                dirty,
                handleChange,
              }) => {
                return (
                  <Form noValidate>
                    <FormComponent
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      values={values}
                      setSelectedCompanyId={setSelectedCompanyId}
                      selectedCompanyId={selectedCompanyId}
                      companyValue={companyValue}
                    />
                  </Form>
                );
              }}
            </Formik>
          </Panel>
          {!isAddMode && onClose ?
            <>
              <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
              <Panel minSize={33} order={2} defaultSize={33}>
                <RightSide isAddMode={isAddMode} editForumData={editForumData}  setOnClose={setOnClose}/>
              </Panel>
            </> : ""
          }
        </PanelGroup>
      </Container>
    </main>
  );
};

export default Index;
