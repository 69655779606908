
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import axios from 'axios';
import { jsPDF } from "jspdf";
import { sanitizeHTMLForDocExport } from '../../../common/helper';

const updateDateFormat = (format = '', value = '') => {
    try{
        if(!value){
            return '';
        }
        let date_format =(format && typeof format == 'string') ? (format.substring(0, 10) + " LT") : 'DD-MM-YYYY LT';
        let temp1 = (value && typeof value == 'string') ? (value).substring(0, 10) : '';
        let temp2 = moment(value).format(date_format);
        temp2 = (temp2 && typeof temp2 == 'string') ? temp2.substring(11) : '';
        // console.log('******** - ', {format, value, date_format, temp1, temp2})
        if(temp1 && temp2){
            return (temp1 + ' ' + temp2);
        }
        else{
            return value;
        }
    }catch(e){
        console.error(e);
        return value;
    }
}

const getRelateCIData = (obj) => {
    try{
        return new Promise((resolve, reject) => {
            const { breakfixId = '' } = obj || {};
            axios
            .get(`/api/config_items/relationship/${breakfixId}/Breakfix/Yes`, {timeout: 7000})
            .then(res => {
                // console.log('getRelateCIData res - ', res);
                let result = '';
                if(res?.data && Array.isArray(res.data) && res.data.length > 0){
                    let arr = [];
                    (res.data).forEach(item => {
                        if(item['CI_NAME_RELATED']){
                            arr.push(item['CI_NAME_RELATED']);
                        }
                    });
                    result += arr.join(', ');
                }
                resolve(result);
            })
            .catch(err => {
                console.error(err);
                // return err;
                // reject('error');
                resolve('');
            });
        });
    }catch(e){
        console.error(e);
        // return Promise.reject('error');
        return Promise.resolve('');
    }
}

const getTaskData = (obj) => {
    try{
        return new Promise((resolve, reject) => {
            const { breakfixId = '', consumerCompany = '' } = obj || {};
            let insideParams =  JSON.stringify({"workItemId":[breakfixId],"applicationName":"XSM","workItemType":['Breakfix'],"moduleName":'Breakfix',"clientId":consumerCompany,"orderBY":"sequenceNumber asc , taskId asc","requestType":"viewTask" });
            let config = {
                timeout: 7000,
                headers: {
                'X-IBM-Client-Id': '5a78384c-5316-42ad-a23e-56bc2eb76d7d ',
                'query': insideParams
                }
            };
            axios
            .get('/api/tasks/', config)
            .then(res => {
                // console.log('getTaskData res - ', res);
                let result = '';
                if(res?.data && Array.isArray(res.data) && res.data.length > 0){
                    (res.data).forEach(item => {
                        result += `<tr><td class="wwrap">${item['taskCode'] || ''}</td><td class="wwrap">${item['sequenceNumber'] || ''}</td><td class="wwrap">${item['description'] || ''}</td><td class="wwrap">${item['additionalParams'] || ''}</td><td class="wwrap">${item['statusName'] || ''}</td><td class="wwrap">${item['createdDate'] || ''}</td><td class="wwrap">${item['actualEndDate'] || ''}</td><td class="wwrap">${item['closeNotes'] || ''}</td></tr>`;
                    });
                }
                else{
                    result = '<tr style="text-align: center;"><td colspan="8">No data available.</td></tr>';
                }
                resolve(result);
            })
            .catch(err => {
                console.error(err);
                // return err;
                // reject('error');
                resolve('<tr style="text-align: center;"><td colspan="8">No data available.</td></tr>');
            });
        });
    }catch(e){
        console.error(e);
        // return Promise.reject('error');
        return Promise.resolve('<tr style="text-align: center;"><td colspan="8">No data available.</td></tr>');
    }
}

const getSLAData = (obj) => {
    try{
        return new Promise((resolve, reject) => {
            const { breakfixNumber = '' } = obj || {};
            let strObj={};
            strObj.ticketId = breakfixNumber;
            strObj.moduleId = '5';
            strObj = JSON.stringify(strObj);
            let config = {
                timeout: 7000,
                headers: { 'query':strObj }
            };
            axios
            .get('/sla-def/getSlaInformation', config)
            .then(res => {
                // console.log('getSLAData res - ', res);
                let result = '';
                if(res?.data && Array.isArray(res.data) && res.data.length > 0){
                    (res.data).forEach(item => {

                        let h = Math.floor(item['allocatedTime'] / 60);
                        if (h < 10 || h == 0){
                            h = "0" + h;
                        }
                        let m = parseInt(item['allocatedTime'] % 60, 10);
                        if (m < 10 || m == 0){
                            m = "0" + m;
                        }
                        let time = h + ":" + m;

                        let slastatus = "";
                        if(item['status']){
                            if(item['status'] == 'IN PROGRESS'){
                                slastatus = "In Progress";
                            }
                            if(item['status'] == 'ALREADY BREACHED'){
                                slastatus = "Breached";
                            }
                            if(item['status'] == 'MET'){
                                slastatus = "Met";
                            }
                            if(item['status'] != 'IN PROGRESS' && item['status'] != 'ALREADY BREACHED' && item['status'] != 'MET'){
                                slastatus = item['status'];
                            }
                        }
                        result += `<tr><td class="wwrap">${item['slaType'] || ''}</td><td class="wwrap">${item['slaName'] || ''}</td><td class="wwrap">${(time) ? (time + ' Hours') : ''}</td><td class="wwrap">${slastatus || ''}</td><td class="wwrap">${item['duedate'] || ''}</td></tr>`;
                    });
                }
                else{
                    result = '<tr style="text-align: center;"><td colspan="5">No data available.</td></tr>';
                }
                resolve(result);
            })
            .catch(err => {
                console.error(err);
                // return err;
                // reject('error');
                resolve('<tr style="text-align: center;"><td colspan="5">No data available.</td></tr>');
            });
        });
    }catch(e){
        console.error(e);
        // return Promise.reject('error');
        return Promise.resolve('<tr style="text-align: center;"><td colspan="5">No data available.</td></tr>');
    }
}

const getAuditLogData = (obj) => {
    try{
        return new Promise((resolve, reject) => {
            const { breakfixId = '', date_format = '' } = obj || {};
            let url = `/serviceManagement/rest/auditAction?itemId=${breakfixId}&orderBy=desc&module=Breakfix`;
            axios
            .get(url, {timeout: 7000})
            .then(res => {
                // console.log('getAuditLogData res - ', res);
                let result = '';
                if(res?.data && Array.isArray(res.data) && res.data.length > 0){
                    (res.data).forEach(item => {
                        let update = '';
                        if(item['auditField'] && typeof item['auditField'] == 'string'){
                            if(item['auditField'] == 'CREATE'){
                                update = 'Created';
                            }
                            else{
                                let type = item['auditField'].split(' ').map(item => ( item.charAt(0).toUpperCase() + item.slice(1).toLowerCase() )).join(' ');
                                if(type == 'Tag'){
                                    let fromUpdate = '';
                                    let toUpdate = '';
                                    let fromArr = ( item['from'] && typeof item['from'] == 'string' && Array.isArray(JSON.parse(item['from'])) ) ? JSON.parse(item['from']) : [];
                                    let toArr = ( item['to'] && typeof item['to'] == 'string' && Array.isArray(JSON.parse(item['to'])) ) ? JSON.parse(item['to']) : [];
                                    if(fromArr && Array.isArray(fromArr) && (fromArr).length>0){
                                        let arr = [];
                                        fromArr.forEach((x)=>{
                                            arr.push((`${x.tag_name}: ${x.tag_value}`));
                                        });
                                        if(arr.length > 0){
                                            fromUpdate = `( ${arr.join(', ')} )`;
                                        }
                                    }
                                    if(toArr && Array.isArray(toArr) && toArr.length>0){
                                        let arr = [];
                                        toArr.forEach((x)=>{
                                            arr.push((`${x.tag_name}: ${x.tag_value}`));
                                        });
                                        if(arr.length > 0){
                                            toUpdate = `( ${arr.join(', ')} )`;
                                        }
                                    }
                                    update = `${type} changed from ${fromUpdate || 'NA'} to ${toUpdate || 'NA'}`;
                                }else{
                                    update = `${type} changed from ${item['from'] || '-'} to ${item['to'] || '-'}`;
                                }
                            }
                        }
                        result += `<tr><td class="wwrap">${item['createdByName'] || ''}</td><td class="wwrap">${updateDateFormat(date_format, item['createdOn']) || ''}</td><td class="wwrap">${update}</td></tr>`;
                    });
                }
                else{
                    result = '<tr style="text-align: center;"><td colspan="3">No data available.</td></tr>';
                }
                resolve(result);
            })
            .catch(err => {
                console.error(err);
                // return err;
                // reject('error');
                resolve('<tr style="text-align: center;"><td colspan="3">No data available.</td></tr>');
            });
        });
    }catch(e){
        console.error(e);
        // return Promise.reject('error');
        return Promise.resolve('<tr style="text-align: center;"><td colspan="3">No data available.</td></tr>');
    }
}

const getActivityLogData = (obj) => {
    try{
        return new Promise((resolve, reject) => {
            const { breakfixId = '', date_format = '' } = obj || {};
            let url = `/serviceManagement/rest/activityAction?itemId=${breakfixId}&orderBy=desc&requireStatusValue=Y&module=Breakfix`;
            axios
            .get(url, {timeout: 7000})
            .then(res => {
                // console.log('getActivityLogData res - ', res);
                let result = '';
                if(res?.data && Array.isArray(res.data) && res.data.length > 0){
                    (res.data).forEach(item => {
                        let update = '';
                        if(item['description'] && typeof item['description'] == 'string'){
                            if(item['description'] == 'Changed Status'){
                                update = `${item['description']} from ${item['from'] || '-'} to ${item['to'] || '-'}`;
                            }
                            else{
                                update = item['description'];
                            }
                        }
                        result += `<tr><td class="wwrap">${item['createdByName'] || ''}</td><td class="wwrap">${updateDateFormat(date_format, item['createdOn']) || ''}</td><td class="wwrap">${update}</td></tr>`;
                    });
                }
                else{
                    result = '<tr style="text-align: center;"><td colspan="3">No data available.</td></tr>';
                }
                resolve(result);
            })
            .catch(err => {
                console.error(err);
                // return err;
                // reject('error');
                resolve('<tr style="text-align: center;"><td colspan="3">No data available.</td></tr>');
            });
        });
    }catch(e){
        console.error(e);
        // return Promise.reject('error');
        return Promise.resolve('<tr style="text-align: center;"><td colspan="3">No data available.</td></tr>');
    }
}

const getImpactedData = (obj) => {
    try{
        return new Promise((resolve, reject) => {
            const { breakfixId = '' } = obj || {};
            let url = `/serviceManagement/rest/impactedArea?breakfixId=${breakfixId}`;
            axios
            .get(url, {timeout: 7000})
            .then(res => {
                // console.log('getImpactedData res - ', res);
                let result = {
                    impactedArea: '',
                    value: ''
                };
                if(res?.data && Array.isArray(res.data) && res.data.length > 0){
                    let impactedAreaArr = [];
                    let valueArr = [];
                    (res.data).forEach(item => {
                        impactedAreaArr.push(item['impactArea']);
                        valueArr.push(item['value']);
                    });
                    result['impactedArea'] = impactedAreaArr.join(', ');
                    result['value'] = valueArr.join(', ');
                }
                resolve(result);
            })
            .catch(err => {
                console.error(err);
                // return err;
                // reject('error');
                resolve({
                    impactedArea: '',
                    value: ''
                });
            });
        });
    }catch(e){
        console.error(e);
        // return Promise.reject('error');
        return Promise.resolve('<tr style="text-align: center;"><td colspan="3">No data available.</td></tr>');
    }
}

const getRelatedWorkItemData = (obj) => {
    try{
        return new Promise((resolve, reject) => {
            const { breakfixId = '' } = obj || {};
            axios
            .get(`/serviceManagement/rest/breakfixRelationsAction?itemId=${breakfixId}&module=Breakfix&sortBy=relationId&orderBy=desc`, {timeout: 7000})
            .then(res => {
                // console.log('getRelatedWorkItemData res - ', res);
                let result = '';
                if(res?.data && Array.isArray(res.data) && res.data.length > 0){
                    let arr = [];
                    (res.data).forEach(item => {
                        if(item['workItemNumber']){
                            arr.push(item['workItemNumber']);
                        }
                    });
                    result += arr.join(', ');
                }
                resolve(result);
            })
            .catch(err => {
                console.error(err);
                // return err;
                // reject('error');
                resolve('');
            });
        });
    }catch(e){
        console.error(e);
        // return Promise.reject('error');
        return Promise.resolve('');
    }
}

const getDependentData_KA = (obj) => {
    try{
        const promises = [ getRelateCIData(obj), getSLAData(obj), getTaskData(obj), getAuditLogData(obj), getActivityLogData(obj), getImpactedData(obj), getRelatedWorkItemData(obj) ];
        return Promise.allSettled(promises)
        .then(res => {
            // console.log('res - ', res);
            return Promise.resolve(res);
        })
        .catch(err => {
            console.error(err);
            return Promise.resolve('error');
        });
    }catch(e){
        console.error(e);
        return Promise.resolve('error');
    }
}

export const exportDoc = async (type = '', obj = {}) => {
    try{
        if(!type){
            throw new Error('Error in exporting document');
        }

        // console.log('*************', obj);
        const { breakfixNumber = '',
            requestNumber = '',
            consumerCompanyName = '',
            impactedUsername = '',
            serviceName = '',
            ciName = '',
            criticality = '',
            asgGroupHopCount = '',
            cimGroupname = '',
            createdOn = '',
            incidentClosureTime = '',
            description = '',
            additionalInfo = '',
            reportedOn = '',
            modeOfReporting = '',
            spGroupname = '',
            spUsername = '',
            priorityId = '',
            category = '',
            subCategory = '',
            reasonValue = '',
            fixDetails = '',
            type : incidentReviewType = '',
            businessImpact = '',
            probableCause = '',
            serviceImpactStartTime = '',
            serviceImpactEndTime = '',
            outage = '' } = obj || {};
        let relatedCI = '';
        let taskData = '';
        let slaData = '';
        let auditLogData = '';
        let activityLogData = '';
        let impactAreaData = '';
        let impactValueData = '';
        let relatedWorkItem = '';

        const dataRes = await getDependentData_KA(obj);
        // console.log('dataRes - ', dataRes);
        if(dataRes && Array.isArray(dataRes)){
            if(dataRes[0] && dataRes[0]?.value){
                relatedCI = dataRes[0]?.value || '';
            }
            if(dataRes[1] && dataRes[1]?.value){
                slaData = dataRes[1]?.value || '';
            }
            if(dataRes[2] && dataRes[2]?.value){
                taskData = dataRes[2]?.value || '';
            }
            if(dataRes[3] && dataRes[3]?.value){
                auditLogData = dataRes[3]?.value || '';
            }
            if(dataRes[4] && dataRes[4]?.value){
                activityLogData = dataRes[4]?.value || '';
            }
            if(dataRes[5] && dataRes[5]?.value){
                let obj = dataRes[5]?.value || {};
                impactAreaData = obj['impactedArea'] || '';
                impactValueData = obj['value'] || '';
            }
            if(dataRes[6] && dataRes[6]?.value){
                relatedWorkItem = dataRes[6]?.value || '';
            }
        }

        let htmlData = `<head>
            <meta charset='utf-8'>
            <style>
            #exportDocument table {
                font-family: arial, sans-serif;
                border-collapse: collapse;
                width: 100%;
            }

            #exportDocument td,
            #exportDocument th {
                border: 1px solid #000000;
                text-align: left;
                padding: 8px;
            }
            #exportDocument .wwrap {
                word-wrap: break-word;
            }
            #exportDocument .tbStyle {
                margin-left: auto;
                margin-right: auto;
                table-layout: fixed;
            }
            #exportDocument .w40 {
                width: 40%;
            }
            #exportDocument .w50 {
                width: 50%;
            }
            #exportDocument .w60 {
                width: 60%;
            }
            #exportDocument .w70 {
                width: 70%;
            }
            #exportDocument .tbHeading {
                width: 100%;
                text-align: center;
                font-weight: bold;
                background-color: #d0cece;
            }
            #exportDocument .fontBold {
                font-weight: bold;
            }
            </style>
            <style>
            @page WordSection1{size: 841.95pt 595.35pt;mso-page-orientation: landscape;}
            div.WordSection1 {page: WordSection1;}
            table{border-collapse:collapse;}td{border:1px gray solid;width:5em;padding:2px;}
            </style>
        </head>
        <body>
            <div id="exportDocument" class="WordSection1">
            <div style="text-align: center"><h2>Incident Report</h2></div>

            <br /><br /><br />

            <div>
                <table class="tbStyle w50">
                <tr>
                    <td class="wwrap w50">Request ID >> Incident ID</td>
                    <td class="wwrap w50">${requestNumber + ' >> ' + breakfixNumber}</td>
                </tr>
                <tr>
                    <td class="wwrap w50">Company</td>
                    <td class="wwrap w50">${consumerCompanyName || ''}</td>
                </tr>
                <tr>
                    <td class="wwrap w50">Consumer</td>
                    <td class="wwrap w50">${impactedUsername || ''}</td>
                </tr>
                </table>
            </div>

            <br /><br /><br />

            <div>
                <table class="tbStyle w70">
                <tr>
                    <td colspan="2" class="tbHeading">Ticket Details</td>
                </tr>
                <tr>
                    <td class="wwrap fontBold w40">Impacted Service</td>
                    <td class="wwrap w60">${serviceName || ''}</td>
                </tr>
                <tr>
                    <td class="wwrap fontBold w40">Impacted CI</td>
                    <td class="wwrap w60">${ciName || ''}</td>
                </tr>
                <tr>
                    <td class="wwrap fontBold w40">Criticality</td>
                    <td class="wwrap w60">${criticality || ''}</td>
                </tr>
                <tr>
                    <td class="wwrap fontBold w40">Hop Count</td>
                    <td class="wwrap w60">${asgGroupHopCount}</td>
                </tr>
                <tr>
                    <td class="wwrap fontBold w40">Issue Description</td>
                    <td class="wwrap w60">${description || ''}</td>
                </tr>
                <tr>
                    <td class="wwrap fontBold w40">Additional Information</td>
                    <td class="wwrap w60">${additionalInfo || ''}</td>
                </tr>
                <tr>
                    <td class="wwrap fontBold w40">Reported On</td>
                    <td class="wwrap w60">${reportedOn || ''}</td>
                </tr>
                <tr>
                    <td class="wwrap fontBold w40">Mode of Reporting</td>
                    <td class="wwrap w60">${modeOfReporting || ''}</td>
                </tr>
                <tr>
                    <td class="wwrap fontBold w40">CIM Group</td>
                    <td class="wwrap w60">${cimGroupname || ''}</td>
                </tr>
                <tr>
                    <td class="wwrap fontBold w40">Incident Reported Time</td>
                    <td class="wwrap w60">${createdOn || ''}</td>
                </tr>
                <tr>
                    <td class="wwrap fontBold w40">Incident Closure Time</td>
                    <td class="wwrap w60">${incidentClosureTime || ''}</td>
                </tr>
                <tr>
                    <td class="wwrap fontBold w40">Support Provider</td>
                    <td class="wwrap w60">${spGroupname || ''}</td>
                </tr>
                <tr>
                    <td class="wwrap fontBold w40">Individual</td>
                    <td class="wwrap w60">${spUsername || ''}</td>
                </tr>
                <tr>
                    <td class="wwrap fontBold w40">Priority</td>
                    <td class="wwrap w60">${priorityId || ''}</td>
                </tr>
                <tr>
                    <td class="wwrap fontBold w40">Related CI</td>
                    <td class="wwrap w60">${relatedCI || ''}</td>
                </tr>
                <tr>
                    <td class="wwrap fontBold w40">Related Work Items</td>
                    <td class="wwrap w60">${relatedWorkItem || ''}</td>
                </tr>
                </table>
            </div>

            <br /><br /><br /><br /><br /><br /><br /><br /><br/><br />

            <div>
                <table class="tbStyle w50">
                <tr>
                    <td colspan="2" class="tbHeading">Fix Details</td>
                </tr>
                <tr>
                    <td class="wwrap fontBold w40">Category</td>
                    <td class="wwrap w60">${category || ''}</td>
                </tr>
                <tr>
                    <td class="wwrap fontBold w40">Sub-Category</td>
                    <td class="wwrap w60">${subCategory || ''}</td>
                </tr>
                <tr>
                    <td class="wwrap fontBold w40">Type of Fix</td>
                    <td class="wwrap w60">${reasonValue || ''}</td>
                </tr>
                <tr>
                    <td class="wwrap fontBold w40">Resolution Details</td>
                    <td class="wwrap w60">${fixDetails || ''}</td>
                </tr>
                </table>
            </div>

            <br /><br /><br />

            <div>
                <table class="tbStyle w70">
                <tr>
                    <td colspan="5" class="tbHeading">SLA Details</td>
                </tr>
                <tr>
                    <th class="wwrap">Type</th>
                    <th class="wwrap">Name</th>
                    <th class="wwrap">SLA</th>
                    <th class="wwrap">Status</th>
                    <th class="wwrap">Due By</th>
                </tr>
                ${slaData}
                </table>
            </div>

            <br /><br /><br />

            <div>
                <table class="tbStyle w70">
                <tr>
                    <td colspan="8" class="tbHeading">Task Details</td>
                </tr>
                <tr>
                    <th class="wwrap">Task Code</th>
                    <th class="wwrap">Task Sequence</th>
                    <th class="wwrap">Summary</th>
                    <th class="wwrap">Additional Information</th>
                    <th class="wwrap">Status</th>
                    <th class="wwrap">Created On</th>
                    <th class="wwrap">Completed On</th>
                    <th class="wwrap">Completion Notes</th>
                </tr>
                ${taskData}
                </table>
            </div>

            ${(incidentReviewType) ? `<br /><br /><br />
            <div>
                <table class="tbStyle w60">
                <tr>
                    <td colspan="2" class="tbHeading">Critical Incident Review Details</td>
                </tr>
                <tr>
                    <td class="wwrap fontBold w40">Type</td>
                    <td class="wwrap w60">${incidentReviewType || '-'}</td>
                </tr>
                <tr>
                    <td class="wwrap fontBold w40">Business Impact</td>
                    <td class="wwrap w60">${businessImpact || '-'}</td>
                </tr>
                <tr>
                    <td class="wwrap fontBold w40">Probable Cause</td>
                    <td class="wwrap w60">${probableCause || '-'}</td>
                </tr>
                <tr>
                    <td class="wwrap fontBold w40">Service Impact Start Time</td>
                    <td class="wwrap w60">${serviceImpactStartTime || '-'}</td>
                </tr>
                <tr>
                    <td class="wwrap fontBold w40">Service Impact End Time</td>
                    <td class="wwrap w60">${serviceImpactEndTime || '-'}</td>
                </tr>
                <tr>
                    <td class="wwrap fontBold w40">Outage</td>
                    <td class="wwrap w60">${(outage) ? (outage + ' minutes') : '-'}</td>
                </tr>
                <tr>
                    <td class="wwrap fontBold w40">Impact Area</td>
                    <td class="wwrap w60">${impactAreaData || '-'}</td>
                </tr>
                <tr>
                    <td class="wwrap fontBold w40">Value</td>
                    <td class="wwrap w60">${impactValueData || '-'}</td>
                </tr>
                </table>
            </div>` : ''}

            <br /><br /><br />

            <div>
                <table class="tbStyle w70">
                <tr>
                    <td colspan="3" class="tbHeading">Audit Log</td>
                </tr>
                <tr>
                    <th class="wwrap">Update Done By</th>
                    <th class="wwrap">Date/Time of Update</th>
                    <th class="wwrap">Update</th>
                </tr>
                ${auditLogData}
                </table>
            </div>

            <br /><br /><br />

            <div>
                <table class="tbStyle w70">
                <tr>
                    <td colspan="3" class="tbHeading">Comments/Activity Update</td>
                </tr>
                <tr>
                    <th class="wwrap">Update Done By</th>
                    <th class="wwrap">Date/Time of Update</th>
                    <th class="wwrap">Comment</th>
                </tr>
                ${activityLogData}
                </table>
            </div>

            <br /><br /><br />
            </div>
        </body>`;
        if(type == 'pdf'){

            let htmlStr = `<!DOCTYPE html><html>${htmlData}</html>`;

            let virtualEl = document.createElement('div');

            virtualEl.innerHTML = sanitizeHTMLForDocExport(htmlStr);

            const doc = new jsPDF();

            doc.html(virtualEl, {

                callback: function(doc) {

                    let filename = `Incident Report - ${requestNumber}-${breakfixNumber}.pdf`;

                    doc.save(filename);

                },

                margin: [10, 10, 10, 10],

                // autoPaging: 'text',

                x: 0,

                y: 0,

                width: 190, //target width in the PDF document

                windowWidth: 675, //window width in CSS pixels

                pagesplit: true

            });

        }
        if(type == 'word'){
            let htmlStr = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>${htmlData}</html>`;
            let blob = new Blob(['\ufeff', htmlStr], {
                type: 'application/msword'
            });
            // let url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(htmlStr);
            let url = URL.createObjectURL(blob);
            let filename = `Incident Report - ${requestNumber}-${breakfixNumber}.doc`;
            let downloadLink = document.createElement("a");
            document.body.appendChild(downloadLink);
            
            if(navigator?.msSaveOrOpenBlob ){
                navigator.msSaveOrOpenBlob(blob, filename);
            }else{
                downloadLink.href = url;
                downloadLink.download = filename;
                downloadLink.click();
            }
            document.body.removeChild(downloadLink);

        }
    }catch(e){
        console.error(e);
    }
}