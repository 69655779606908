
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export default function listenForOutsideClicks(
  listening,
  setListening,
  ref,
  setIsOpen
) {
  return () => {
    if (listening) return;
    if (!ref.current) return;
    setListening(true);
    [`click`, `touchstart`].forEach((type) => {
      document.addEventListener(`click`, (evt) => {
        const cur = ref?.current;
        const node = evt.target;
        if (cur && cur.contains(node)) return;
        setIsOpen(false);
      });
    });
  };
}
