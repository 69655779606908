
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Link, NavLink } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
let sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
import { GLOBAL } from "_Globals";
import axios from "axios";
import { reduxForm, SubmissionError } from "redux-form";
import swal from "sweetalert";
import { navigationHooks } from "../../../helpers/NavigationHook";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import {LuSearch, LuBarChart, LuActivitySquare} from 'react-icons/lu';
import { TbAlignBoxLeftMiddle, TbRouteSquare, TbPencil } from "react-icons/tb";
import { MyContext } from '_MyContext';
class SlaEditHeader extends React.Component {
  static contextType = MyContext;
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
    };
    this.submit = this.submit.bind(this);
    this.submitEdit = this.submitEdit.bind(this);
    this.submitEditValidate = this.submitEditValidate.bind(this);
    this.submitEditValid = this.submitEditValid.bind(this);
  }
  // componentWillMount() {
  //   //this.props.loadCIEditDetails(this.props.ciId);
  //   //alert("this.props.slaDefId"+this.props.slaDefId)
  // }
  componentDidMount() {
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
    this.context.newTabPrevent();
	}
	componentDidUpdate() {
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
    this.context.newTabPrevent();
	}

  submitEditValid(values) {
    if (values.isCompanyChanged) {
      swal({
        text: this.props.translator[
          this.props.translator[
            "Are you sure you want to change the company as it will delete all the existing attributes"
          ]
        ],
        buttons: ["Cancel", "OK"],
      }).then((afterhandling) => {
        if (afterhandling) {
          this.submit(values);
        }
      });
    } else {
      this.submit(values);
    }
  }
  submit(values) {
    this.setState({
      loader: true,
    });
    this.props.setIsFormValid(true);
    return sleep(1000) // simulate server latency
      .then(() => {
        let slaWorkdayScheduleName = "";
        let slaWorkdaySchedule = "";
        let slaHolidayScheduleName = "";
        let slaHolidaySchedule = "";
        //alert(values.slaStatusValue);
        //alert(supportPgroupNameValue.length());
        if (`${JSON.stringify(values, null, 2)}` == "{}") {
          this.setState({ loader: false });
          throw new SubmissionError({
            _error:
              "The form has missing required fields, please revalidate the highlighted fields and update the form",
          });
        } else if (values.slaStatusValue == "" || values.slaTargetTime == ""|| ((values.slaStatusValue != 15) && (values.slaWorkdaySchedule == "" || values.slaWorkdaySchedule == "select")) || ((values.slaModuleActualName==='Problem'||values.slaModuleActualName==='Incident') && values.impactOnStart == "" && values.slaRetroactiveRequired == "Yes")) {
          if (values.slaStatusValue == "") {
            GLOBAL.slaStatusErrorColor = "error";
          } else {
            GLOBAL.slaStatusErrorColor = "";
          }
          if (values.slaTargetTime == "") {
            GLOBAL.slaTargetTimeErrorColor = "error";
          } else {
            GLOBAL.slaTargetTimeErrorColor = "";
          }
          if (values.impactOnStart == "") {
            GLOBAL.slaImpactErrorColor = "error";
          } else {
            GLOBAL.slaImpactErrorColor = "";
          }
          if ((values.slaStatusValue != 15) && (values.slaWorkdaySchedule == "" || values.slaWorkdaySchedule == "select")) {
            GLOBAL.slaWorkdayScheduleErrorColor = "error";
          } else {
            GLOBAL.slaWorkdayScheduleErrorColor = "";
          }
          this.setState({ loader: false });
          this.props.setIsFormValid(false);
          // throw new SubmissionError({
          //   _error:
          //     "The form has missing required fields, please revalidate the highlighted fields and update the form",
          // });
        } else if (
          parseInt(values.slaTargetTime, 10) < 0 ||
          values.slaTargetTime.toString().includes(" ") ||
          parseInt(values.slaTargetTime, 10) > 525600 ||
          Number.isNaN(parseInt(values.slaTargetTime, 10)) ||
          !Number.isInteger(parseFloat(values.slaTargetTime))
        ) {
          if (!Number.isInteger(parseFloat(values.slaTargetTime))) {
            GLOBAL.slaTargetTimeErrorColor = "error";
            this.props.setTargetTimeError("error");
            swal(
              "Target time field should be integer value!"
            );
          }

          if (parseInt(values.slaTargetTime, 10) < 0) {
            GLOBAL.slaTargetTimeErrorColor = "error";
            this.setState({ loader: false });
            throw new SubmissionError({
              _error: "Time should not be less than 0",
            });
          } else if (parseInt(values.slaTargetTime, 10) > 525600) {
            GLOBAL.slaTargetTimeErrorColor = "error";
            this.setState({ loader: false });
            throw new SubmissionError({
              _error: "Time should not be greater than 525600",
            });
          } else if (
            values.slaTargetTime.toString().includes(" ") ||
            Number.isNaN(parseInt(values.slaTargetTime, 10))
          ) {
            GLOBAL.slaTargetTimeErrorColor = "error";
            this.setState({ loader: false });
            throw new SubmissionError({
              _error: "Value should be a Positive Number",
            });
          }
        } else {
          if (values.slaStatusValue == 15) {
            slaWorkdayScheduleName = "";
            slaWorkdaySchedule = "0";
            slaHolidayScheduleName = "";
            slaHolidaySchedule = "0";
          } else {
            slaWorkdayScheduleName = values.slaWorkdayScheduleName == "select" ? "":values.slaWorkdayScheduleName;
            slaWorkdaySchedule = values.slaWorkdaySchedule;
            slaHolidayScheduleName = values.slaHolidayScheduleName;
            slaHolidaySchedule = values.slaHolidaySchedule;
          }
          let postjson = {
            slaStatus: values.slaStatusActualName,
            slaStatusId: values.slaStatusValue,
            goalTime: values.slaTargetTime,
            slaWorkdaySchedule: slaWorkdayScheduleName,
            slaWorkdayScheduleId: slaWorkdaySchedule,
            slaHolidaySchedule: slaHolidayScheduleName,
            slaHolidayScheduleId: slaHolidaySchedule,
            slaUpdatedBy: "",
            slaUpdatedByName: "",
            serviceBased: GLOBAL.serviceBased,
            isCompanyChanged: values.isCompanyChanged,
            companyId: values.slaCompanyName,
            "retroactiveRequired": values.slaRetroactiveRequired,
            impactOnStart:(values.slaModuleActualName==='Problem'||values.slaModuleActualName==='Incident')&&values.slaRetroactiveRequired==='Yes'?values.impactOnStart:"",
            description: values.description ? values.description : "",
          };
          axios
            .patch(GLOBAL.slaDefPatchUrl + this.props.slaDefId, postjson)
            .then(function (response) {
              if (response.status == 200) {
                console.log("editing ci record");
                GLOBAL.slaTargetTimeErrorColor = "";
                GLOBAL.slaStatusErrorColor = "";
                navigationHooks.navigate("/slaDeflist");
              } else {
                console.log("error in editing ci record");
              }

            })
            .catch(function (error) {
              // this.setState({
              //   loader: false
              // });
              swal(
                "You are not authorized to create/update SLA for other companies"
              );
            });
        }

      });
  }

  submitEditValidate(values) {
    if (values.isCompanyChanged) {
      swal({
        text: this.props.translator[
          "Are you sure you want to change the company as it will delete all the existing attributes"
        ],
        buttons: ["Cancel", "OK"],
      }).then((afterhandling) => {
        if (afterhandling) {
          this.submitEdit(values);
        }
      });
    } else {
      this.submitEdit(values);
    }
  }

  submitEdit(values) {
    // debugger
    this.setState({
      loader: true,
    });
    this.props.setIsFormValid(true);

    return sleep(1000) // simulate server latency
      .then(() => {

        if (`${JSON.stringify(values, null, 2)}` == "{}") {
          throw new SubmissionError({
            _error:
              "The form has missing required fields, please revalidate the highlighted fields and update the form",
          });
        } else if (
          values.slaName == "" ||
          values.slaName.trim() == "" ||
          values.slaCompanyName == "" ||
          values.slaCompanyName == 0 ||
          values.slaModuleName == "" ||
          values.slaStatusValue == "" ||
          values.slaTypeName == "select.." ||
          values.slaType == "" ||
          values.slaTypeName == "select.." ||
          values.slaTargetFor == "" ||
          values.slaTargetTime == "" ||
          ((values.slaStatusValue != 15) && (values.slaWorkdaySchedule == "" || values.slaWorkdaySchedule == "select" || values.slaWorkdaySchedule == 0))||
          ((values.slaModuleName == "5" || values.slaModuleName == "10") && values.slaRetroactiveRequired == "Yes" && values.impactOnStart == "")
        ) {

          if (values.slaName == "" || values.slaName.trim() == "") {
            GLOBAL.slaNameErrorColor = "error";
          } else {
            GLOBAL.slaNameErrorColor = "";
          }
          if (values.slaCompanyName == "" || values.slaCompanyName == 0) {
            GLOBAL.slaCompanyNameErrorColor = "error";
          } else {
            GLOBAL.slaCompanyNameErrorColor = "";
          }

          if (values.slaModuleName == "") {
            GLOBAL.slaModuleNameErrorColor = "error";
          } else {
            GLOBAL.slaModuleNameErrorColor = "";
          }

          if (values.slaStatusValue == "") {
            GLOBAL.slaStatusErrorColor = "error";
          } else {
            GLOBAL.slaStatusErrorColor = "";
          }
          if ((values.slaStatusValue != 15) && (values.slaWorkdaySchedule == "" || values.slaWorkdaySchedule == "select" || values.slaWorkdaySchedule == 0)) {
            GLOBAL.slaWorkdayScheduleErrorColor = "error";
          } else {
            GLOBAL.slaWorkdayScheduleErrorColor = "";
          }
          // if (values.slaServiceName == "0") {
          //   GLOBAL.slaServiceNameErrorColor = "error";
          // } else {
          //   GLOBAL.slaServiceNameErrorColor = "";
          // }
          // if(values.slaImpactedName=="0"&&GLOBAL.serviceBased==false)
          // {
          //   console.log("values.slaImpactedName==",values.slaImpactedName==""&&GLOBAL.serviceBased==false);
          //   GLOBAL.slaImpactedNameErrorColor ="error";
          //   // if(values.service=="impactedCI"&&values.serviceName=="")
          //   // {
          //   //   values.serviceName='0';
          //   // }

          // }
          // else
          // {
          //   // console.log("values.slaImpactedName==",values.slaImpactedName==""&&values.service=="impactedCI");
          //   GLOBAL.slaImpactedNameErrorColor ="";
          // }
          if (values.slaType == "" || values.slaTypeName == "select..") {
            GLOBAL.slaTypeErrorColor = "error";
          } else {
            GLOBAL.slaTypeErrorColor = "";
          }

          if (values.slaTargetFor == "") {
            GLOBAL.slaTargetForErrorColor = "error";
          } else {
            GLOBAL.slaTargetForErrorColor = "";
          }

          if (values.slaTargetTime == "") {
            GLOBAL.slaTargetTimeErrorColor = "error";
          } else {
            GLOBAL.slaTargetTimeErrorColor = "";
          }
          if ((values.slaModuleName == "5" || values.slaModuleName == "10") && values.slaRetroactiveRequired == "Yes" && values.impactOnStart == "") {
            GLOBAL.slaImpactErrorColor = "error";
          } else {
            GLOBAL.slaImpactErrorColor = "";
          }
          this.setState({ loader: false });
          this.props.setIsFormValid(false);
          // throw new SubmissionError({
          //   _error:
          //     "The form has missing required fields, please revalidate the highlighted fields and update the form !",
          // });
        } else if (
          parseInt(values.slaTargetTime, 10) < 0 ||
          values.slaTargetTime.toString().includes(" ") ||
          parseInt(values.slaTargetTime, 10) > 525600 ||
          Number.isNaN(parseInt(values.slaTargetTime, 10))
        ) {
          if (parseInt(values.slaTargetTime, 10) < 0) {
            GLOBAL.slaTargetTimeErrorColor = "error";
            this.setState({ loader: false });
            throw new SubmissionError({
              _error: "Time should not be less than 0",
            });
          } else if (parseInt(values.slaTargetTime, 10) > 525600) {
            GLOBAL.slaTargetTimeErrorColor = "error";
            this.setState({ loader: false });
            throw new SubmissionError({
              _error: "Time should not be greater than 525600",
            });
          } else if (
            values.slaTargetTime.toString().includes(" ") ||
            Number.isNaN(parseInt(values.slaTargetTime, 10))
          ) {
            GLOBAL.slaTargetTimeErrorColor = "error";
            this.setState({ loader: false });
            throw new SubmissionError({
              _error: "Value should be a Positive Number",
            });
          }
        } else if (
          (values.slaWorkdaySchedule == "" ||
            values.slaWorkdaySchedule == "0") &&
          (values.slaStatusValue == 5 || values.slaStatusValue == 10)
        ) {
          //alert("values.slaWorkdaySchedule"+values.slaWorkdaySchedule)
          if ((values.slaStatusValue != 15) &&
            (values.slaWorkdaySchedule == "" ||
            values.slaWorkdaySchedule == "0")
          ) {
            GLOBAL.slaWorkdayScheduleErrorColor = "error";
          } else {
            GLOBAL.slaWorkdayScheduleErrorColor = "";
          }
          this.setState({ loader: false });
          throw new SubmissionError({
            _error:
              "The form has missing required fields, please revalidate the highlighted fields and update the form !",
          });
        } else {
          this.setState({ showSubmit: true });
          let slaWorkdayScheduleName = "";
          let slaWorkdaySchedule = "";
          let slaHolidayScheduleName = "";
          let slaHolidaySchedule = "";
          //alert(values.slaStatusValue)
          if (values.slaStatusValue == 15) {
            slaWorkdayScheduleName = "";
            slaWorkdaySchedule = "0";
            slaHolidayScheduleName = "";
            slaHolidaySchedule = "0";
          } else if (
            values.slaHolidaySchedule == "" ||
            values.slaHolidaySchedule == "0"
          ) {
            slaWorkdayScheduleName = values.slaWorkdayScheduleName;
            slaWorkdaySchedule = values.slaWorkdaySchedule;
            slaHolidayScheduleName = "";
            slaHolidaySchedule = "0";
          } else {
            slaWorkdayScheduleName = values.slaWorkdayScheduleName;
            slaWorkdaySchedule = values.slaWorkdaySchedule;
            slaHolidayScheduleName = values.slaHolidayScheduleName;
            slaHolidaySchedule = values.slaHolidaySchedule;
          }
          let impactStart = values.impactOnStart;
          if (values.slaModuleActualName == "Change" || values.slaModuleActualName == "Task" || values.slaModuleActualName == "Fulfillment") {
             impactStart = "";
          }
          let postjson = {
            slaName: values.slaName,
            companyName: values.slaCompanyActualName,
            companyId: values.slaCompanyName,
            moduleName: values.slaModuleActualName,
            moduleId: values.slaModuleName,
            slaStatus: values.slaStatusActualName,
            slaStatusId: values.slaStatusValue,
            // "serviceName": values.slaServiceActualName=="select"?"":values.slaServiceActualName,
            // "serviceId": values.slaServiceName,
            serviceBased: GLOBAL.serviceBased,
            // "ciId":values.slaImpactedName==""?'0':values.slaImpactedName,
            ciName:
              values.slaImpactedActualName == "select"
                ? ""
                : values.slaImpactedActualName,
            slaType: values.slaTypeName,
            slaTypeId: values.slaTypeId,
            goalType: values.slaTargetForName,
            goalTypeId: values.slaTargetFor,
            goalTime: values.slaTargetTime,
            slaWorkdaySchedule: slaWorkdayScheduleName,
            slaWorkdayScheduleId: slaWorkdaySchedule,
            slaHolidaySchedule: slaHolidayScheduleName,
            slaHolidayScheduleId: slaHolidaySchedule,
            retroactiveRequired: values.slaRetroactiveRequired,
            createdBy: "",
            createdByName: "",
            isCompanyChanged: values.isCompanyChanged,
            impactOnStart: values.slaRetroactiveRequired == "No"?"": impactStart,
            description: values.description ? values.description : "",
          };
          console.log(postjson);

          axios
            .patch(GLOBAL.slaDefPatchUrl + this.props.slaDefId, postjson)
            .then(function (response) {
              //alert(response.status)
              if (response.status == 200) {
                console.log("undating slaDef record");
                navigationHooks.navigate("/slaDeflist");
              } else {
                console.log("error");
              }
              // this.setState({
              //   loader: false
              // });
            })
            .catch(function (error) {
              console.log("error", error);
              swal(
                "You are not authorized to create/update SLA for other companies"
              );

              // alert("H1");
              //window.location="/BreakFixEditError";
            });
        }
        //===================
      });
  }
  render() {
    let { handleSubmit, pristine, reset, submitting } = this.props;
    return (
      <Row className="margin-b-15">
        <Col md={7} sm={7} xs={12}>
          <h1 bsClass="" className="sPageHeading1">
            {this.props.translator["Target Definition"]}
          </h1>
          {/*<div className="sPageHeading">{this.props.CIEditDetails.CI_CODE}</div>  */}
          <div className="sPageHeading"></div>
        </Col>
        <Col md={5} sm={5} xs={12}>
          <div className="status myStatus">
            <Nav as="ul">
              {this.props.slaStatusId == "15" ? (
                <Nav.Item
                  as="li"
                  title={this.props.translator["Save"]}
                  onClick={handleSubmit(this.submitEditValidate)}
                  eventKey={1}
                >
                  <Link to="javascript:void(0)" className="ctrlKeyPrevent" ref={(e) => (this.saveObjRef = e)}>
                    {" "}
                    <IoSaveOutline/>
                  </Link>
                </Nav.Item>
              ) : (
                <Nav.Item
                  as="li"
                  title={this.props.translator["Save"]}
                  onClick={handleSubmit(this.submitEditValid)}
                  eventKey={1}
                >
                  <Link to="javascript:void(0)" className="ctrlKeyPrevent"ref={(e) => (this.saveObjRef = e)}>
                  <IoSaveOutline/>
                  </Link>
                </Nav.Item>
              )}
              {/*<Nav.Item title="Save" eventKey={1}><span className="sAction bgBtn15 save"></span></Nav.Item>*/}
              <NavLink to="/slaDeflist">
                <Nav.Item
                  as="li"
                  title={this.props.translator["Close"]}
                  eventKey={2}
                >
                  <Link to="javascript:void(0)" className="ctrlKeyPrevent" ref={e => this.closeButton = e}>
                  <IoClose/>
                  </Link>
                </Nav.Item>
              </NavLink>
            </Nav>
          </div>
          <div className="status myStatusGap border-r margin-r-10 padding-r-10">
            <Nav as="ul" bsClass="">
            <Nav.Item
                as="li"
                title={this.props.translator["Specify Attribute Values"]}
                onClick={() => {
                  this.props.changeActionView("specifyAttribute");
                  this.props.rightEditPanel(true);
                }}
                eventKey={5}
              >
                <a className={this.props.actionView == 'specifyAttribute' ? "active" : ""} href={void(0)}>
                <TbAlignBoxLeftMiddle/><TbPencil className="slaAttrPencil"/>
                </a>
              </Nav.Item>
            <Nav.Item
                  title={["Activity Details"]}
                  eventKey={5}
                  as="li"
                  onClick={() => {
                  this.props.changeActionView("activityDetails");
                  this.props.rightEditPanel(true); }}
                >
                <a className={this.props.actionView == 'activityDetails' ? 'active' : ''}>
                  <LuActivitySquare /></a>
                </Nav.Item>

              <Nav.Item
                as="li"
                title={this.props.translator["Specify Milestone Values"]}
                onClick={() => {
                  this.props.changeActionView("specifyMilestone");
                  this.props.rightEditPanel(true);
                }}
                eventKey={6}
              >
                <a className={this.props.actionView == 'specifyMilestone' ? "active" : ""} href={void(0)}>
                <TbRouteSquare />
                </a>
              </Nav.Item>
              <Nav.Item 
                as="li"
                title={this.props.translator["Process Workflow"]}
                onClick={() => {
                  this.props.changeActionView("servicediagram");
                  this.props.rightEditPanel(true);
                }}
                eventKey={4}
              >
                <a className={this.props.actionView == 'servicediagram' ? "active" : ""} href={void(0)}>
                <LuSearch/><LuBarChart className='processBarIcn'/>
                </a>
              </Nav.Item>
              {/*<Nav.Item title="Service Diagram" onClick={() => { this.props.changeActionView('servicegraph')}} eventKey={4}><span className="sAction bgBtn1 flowChart"></span></Nav.Item>*/}
            </Nav>
          </div>
        </Col>
      </Row>
    );
  }
}

SlaEditHeader = reduxForm({
  form: "XsmSLADefEditForm",
  destroyOnUnmount: true,
})(SlaEditHeader);

/* function mapDispatchToProps(dispatch){
	return bindActionCreators({loadCIEditDetails}, dispatch);
} */
export function mapStateToProps({ slaDefEditDetails }) {
  return {
    slaDefEditDetails,
  };
}
export default SlaEditHeader;
//export default XsmSupportHeader;
