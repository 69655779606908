
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { Component } from "react";
import ListGroup from "react-bootstrap/ListGroup";

import { connect } from "react-redux";
import { Link } from "react-router-dom";

class FooterCopyright extends React.Component {
  render() {
    let currentYear = new Date().getFullYear();

    return (
      <div role="contentinfo" aria-label="footer" className="copyCrpdv">
        <footer className="copyCrp">
          <div className="container-fluid">
            <div className="copysym">
              {/* &#xA9; HCL Technologies Ltd., <span>2019 - {currentYear}</span> */}
              Copyright &#xA9; <span>2016, {currentYear}</span> HCL Technologies Limited
            </div>
						<ListGroup className="cpLnk" bsPrefix=' ' as="ul">
							<ListGroup.Item bsPrefix=' ' as="li">
								<Link to="/conditions-of-use" title={this.props.tr['Conditions of Use']}>{this.props.tr['Conditions of Use']}</Link>
							</ListGroup.Item>
							<ListGroup.Item bsPrefix=' ' as="li">
								<Link to="/privacy-policy" title={this.props.tr['Privacy Notice']}>{this.props.tr['Privacy Notice']}</Link>
							</ListGroup.Item>
							&nbsp;&nbsp;
						</ListGroup>
						<div className='copyVer'>{this.props.versionList.version && this.props.versionList.version.length > 0 ? `${this.props.tr['Version']}: ${this.props.versionList.version}` : ''}</div>
					</div>
				</footer>
			</div>
		);
	}
}

const mapStateToProps = ({
  spcmReducer,
  reportList,
  versionList,
  windowSize,
}) => {
  return {
    spcmReducer,
    tr: spcmReducer.tr,
    reportList,
    versionList,
    windowSize,
  };
};

export default connect(mapStateToProps, null)(FooterCopyright);
