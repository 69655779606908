
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState, useRef,useContext} from "react";
import { MyContext } from '_MyContext';
import { Button, Accordion, useAccordionButton } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";

import ShortDescription from "./rightSideShortDesc";
import ListLoader from "../../common/loaders/ListLoader";
import { filterURL } from "../Helper";
import {
  useGetMinutesDetailsQuery,
  useGetTrackingDetailsQuery,
} from "../../../reducers/governance/meeting/meeting";
import { useGetActionItemsQuery } from "../../../reducers/governance/meeting/actionItem";
import DiscussionPointList from "./decisions";
import Participants from "./participant";
import ActionItems from "./actionItems";
import { Link } from "react-router-dom";
import {IoClose} from "react-icons/io5";

const ConsoleRightSide = ({
  companyId,
  filtersData,
  setFiltersData,
  occurrenceDetaildata,
  occurrencesDetailFetching,
  dateformat,
}) => {
  const closeButton = useRef(null);
  const context = useContext(MyContext);
  useEffect(() => {
    context.keyDownHandler(closeButton.current);
  }, []);
  const { data: decisionsData, isFetching: deciFetching } =
    useGetMinutesDetailsQuery(
      {
        queryString: filterURL(filtersData) + "&sortOrder=asc",
      },
      {
        skip: !(companyId && filtersData?.occurrenceId),
      }
    );
  const [loading, setLoading]=useState(false);

      useEffect(()=>{
        setLoading(true)
        setTimeout(() => {
          setLoading(false)
        }, 1000);
      },[occurrenceDetaildata])

  const {
    data: actionList = [],
    isFetching: actionItemsFetching,
    refetch,
  } = useGetActionItemsQuery(
    {
      queryString: `?filter[where][workItemId]=${filtersData?.occurrenceId}&filter[where][companyId]=${companyId}&filter[include][]=priorityDetails&filter[include][]=performers&filter[order]=createdDate desc`,
    },
    { skip: !(companyId && filtersData?.occurrenceId) }
  );

  //

  const {
    data: trackData,
    isFetching: trackDataFetching,
    refetch: refreshTracking,
  } = useGetTrackingDetailsQuery(
    { Id: filtersData?.occurrenceId, queryString: `?tenantId=${companyId}` },
    {
      skip: !(companyId && filtersData?.occurrenceId),
    }
  );

  return (
    <div className="panel-body attachment-body">
      <div className="custom-container-fluid">
        <div className="stickyArea rBoxStyle">
        <Row>
                            <Col xs={12}>
                                <div className="rPageHeadActBtn">
                                    <ul>
                                        <li>
                                        <Link to="javascript:void(0)" className="closeRsBtn" id="closeButton"
                                            title={"Minimize the details panel"}
                                            onClick={()=>{
                                              setFiltersData((prev)=>{
                                                return {
                                                  ...prev,
                                                  'eventTitle': ''
                                                }
                                              })
                                            }}
                                            ref={closeButton}
                                        >
                                            <IoClose/>
                                        </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">
                                    Meeting Details
                                </div></div>
                            </Col>
                        </Row>
          <div className="p-3 occurnance">
            {(occurrencesDetailFetching || loading) ? (
              <ListLoader />
            ) : (
              <>
              <ShortDescription
                rowData={occurrenceDetaildata}
                dateformat={dateformat}
                setFiltersData={setFiltersData}
              />
          
            <div className="pt-2">
              <Accordion
                accordion
                id="rightSidePane-occurrence"
                // defaultActiveKey={0}
                className=""
              >
                <Accordion.Item eventKey="1" className="mb-2">
                  <Accordion.Header>Participant</Accordion.Header>
                  <Accordion.Body>
                    <Participants
                      trackData={trackData}
                      isFetching={trackDataFetching}
                    />
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2" className="mb-2">
                  <Accordion.Header>Action Items</Accordion.Header>
                  <Accordion.Body>
                    <ActionItems
                      actData={actionList}
                      dateFormat={dateformat}
                      actDataFetching={actionItemsFetching}
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" className="mb-2">
                  <Accordion.Header>Decisions</Accordion.Header>
                  <Accordion.Body>
                    <DiscussionPointList
                      data={decisionsData?.discussionPoints || []}
                      isFetching={deciFetching}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            </>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsoleRightSide;
