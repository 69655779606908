
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, InputGroup, Image, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { TypeaheadExampleSingleSelect, _dropDown, _inputField } from "../../../../common/formFields";
import { Field, change, reduxForm } from "redux-form";
import { getCostCenterCompanyOptions } from "../../../../../actions/foundation/costCenterAction";


const CreateForm = (props) => {
    const [companyList, setCompanyList] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState([]);
    const [statusValue, setStatusValue] = useState([]);

    const tr = useSelector((state) => state.spcmReducer.tr);
    const companyData = useSelector((state) => state.costCenterCompanyDropdown);
    const statusSelect = useSelector((state) => state.costCenterStatusValues);

    const dispatch = useDispatch();
    console.log("CostCenterReducers", companyData);

    useEffect(() => {
        dispatch(getCostCenterCompanyOptions())
    },[])

    useEffect(() =>
    {
      if(companyData && companyData.length > 0)
      {
        setCompanyList(companyData)
        }
        if (statusSelect && statusSelect.length > 0) {
            let statusList = [];
            statusSelect.map((status) => {
                statusList.push({label: status.field1Key, id:status.field1Value})
            })
            setStatusValue(statusList);
        }

    }, [companyData])

    const onCompanySelection=(selectedCompany)=>{
        if (selectedCompany.length > 0) { 
            setSelectedCompany(selectedCompany)
            dispatch(change('CreateCostCenter', 'company_name', selectedCompany[0].label))
            dispatch(change('CreateCostCenter', 'company_id', selectedCompany[0].id))
            props.setCompanyValue(selectedCompany)
          } else {
            setSelectedCompany([]);
            dispatch(change('CreateCostCenter', 'company_name', ''))
            dispatch(change('CreateCostCenter', 'company_id', ''))
          }
    }

    const onCrossClickCompany = () => {
      setSelectedCompany([]);
      props.setCompanyValue([]);
      dispatch(change('CreateCostCenter', 'company_name', ''))
      dispatch(change('CreateCostCenter', 'company_id', ''))
    }
    const companyInputChange = () => {
      onCrossClickCompany();
    }
  
    const  validateCostCenterField = (fieldType, event)=> {
        switch (fieldType) {
          case "costcenter_name":
            if (event.target.value == "") {
              props.setNameErr("error");
            } else {
              props.setNameErr("");
            }
            break;
          case "ccStatus":
            if (event.target.value == "") {
              props.setStatusErr("error");
            } else {
              props.setStatusErr("");
            }
            break;
          default:
        }
      }


    return (
        <Form>
        <Row>
            <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
            <span className="rStar"></span>
                    <Form.Label>{tr["Name"]}</Form.Label>
                    <Field
                        component={_inputField}
                        name="costcenter_name"
                        className={"form-control "+(props.nameErr)}
                        maxLength={50}
                        onBlur={(event) => {
                            validateCostCenterField("costcenter_name", event);
                        }}
                    />
                </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
            <span className="rStar" />
                <Form.Label bsClass="">
                  {tr["Company"]}
                </Form.Label>
                <Field
                  name="company_name"
                  className="form-control"
                  component={TypeaheadExampleSingleSelect}
                  onSelection={onCompanySelection}
                  options={companyList}
                  selectedOptions={selectedCompany}
                  onCrossClick={onCrossClickCompany}
                  errorClass={props.companyTypeErr}
                  setErrorColor={props.setCompanyErrorColor}
                  onInputChange={companyInputChange}
                />
                </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                <span className="rStar" />
                <Form.Label>{tr["Status"]}</Form.Label>
                    <Field
                        name="ccStatus"
                        className={"form-control "+(props.statusErr)}
                        component={_dropDown}
                        options={statusValue}
                        onBlur={(event) => {
                            validateCostCenterField("ccStatus", event);
                        }}>
                  </Field>
                </Form.Group>
            </Col>
            </Row>
    </Form>
    )
}

export default reduxForm({
    form: 'CreateCostCenter',
    destroyOnUnmount: false,
    enableReinitialize: true
})(CreateForm);