
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { reduxForm } from "redux-form";
import { filterAllowedFields, getComponents } from "../../common/helper";
import { Button, Col, Row } from "react-bootstrap";
import axios from "axios";
import { getDataInTable } from "../blocksHelper";
import GeneratedListDescription from "../generatedListingComponents/generatedListDescription";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import ListLoader from "../../common/loaders/ListLoader";
import GeneratedCustomTableView from "../generatedListingComponents/customTable";
import BasicTable from "../../common/ReactTable/BasicTable";
import useDataQueryWithApi from "../../uiBuilder/hooks/useDataQueryWithApi";
import TableComponent from "../../common/ReactTable/TableComponent";
import { getValueFromKey, isJson } from "../../uiBuilder/studio/utils";
import _ from "lodash";
import { LuFilterX } from "react-icons/lu";
import { HiOutlineRefresh } from "react-icons/hi";

const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

const ViewTableComponent = (props) => {
  const [theaders, setTheaders] = useState([]);
  const [tRow, setTRow] = useState([]);
  const [tableProperties, setTableProperties] = useState({});
  const [data, setData] = useState([]);
  const [language, setLanguage] = useState({});
  const [rightDisplay, setRightDisplay] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [myTitle, setMyTitle] = useState({});
  const [loader, setLoader] = useState(false);
  const [rowIndex, setRowIndex] = useState(0);
  const [headerKeys, setHeaderKeys] = useState([]);
  const [filterValues, setFilterValues] = useState({});
  const [metaData, setMetaData] = useState({});
  const [perPageData, setPerPageData] = useState(10);
  const [activePage, setActivePage] = useState(0);
  const [sort, setSort] = useState("");
  const [tableQuery, setTableQuery] = useState({});
  const [showClearFilter, setShowClearFilter] = useState(false);

  const [
    responseData,
    tableDataLoading,
    setDataSourceId,
    dataError,
    setRefreshed,
  ] = useDataQueryWithApi(
    filterValues,
    tableProperties,
    props?.defaultValues,
    () => {},
    tableQuery
  );
  useEffect(() => {
    if (isJson(props.languageData?.getAllLanguages?.value) && Object.keys(props?.languageData).length > 0) {
      setLanguage(JSON.parse(props.languageData?.getAllLanguages?.value));
    }
  }, [props.languageData]);

  useEffect(() => {
    if (props?.isDataRefreshed) {
      setRefreshed(true);
      props?.setIsDataRefreshed(false);
    }
  }, [props?.isDataRefreshed]);
  
  useEffect(() => {
    if (props?.clearFilterClicked) {
      clearAllFilters();
      props?.setClearFilterClicked(false);
    }
  }, [props?.clearFilterClicked]);

  useEffect(() => {
    if (props?.table && props?.table?.attributes?.data_source) {
      let filteredTable = props?.table;
      const tableData = filteredTable;
      const tableComponents = getComponents(tableData?.components);
      const thComponents = filterAllowedFields(tableComponents, ["custom-th"]);
      const tdComponents = filterAllowedFields(tableComponents, ["custom-td"]);
      setTheaders(thComponents);
      setTRow(tdComponents);
      setTableProperties(tableData?.attributes);
    }
  }, [props.table]);

  useEffect(() => {
    if (tableProperties && Object.keys(tableProperties).length > 0) {
      if (tableProperties?.data_source) {
        if (tableProperties?.data_source && tableProperties?.tableType?.id == "customTable") {
          setFilterValues({
            [tableProperties?.currPageKey ?? "page"]: 0,
            [tableProperties?.sizeKey ?? "size"]:
              tableProperties?.itemsPerPageOptions?.split(",")?.[0] ?? 10,
          });
          setTableQuery({
            filterValues: {
              [tableProperties?.currPageKey ?? "page"]: 0,
              [tableProperties?.sizeKey ?? "size"]:
                tableProperties?.itemsPerPageOptions?.split(",")?.[0] ?? 10,
            },
            dataTransmissionMethod: tableProperties?.dataTransmissionMethod?.id,
            tableReq: true,
          });
        }
        setDataSourceId(tableProperties?.data_source.id);
      }
    }
  }, [tableProperties]);

  console.log('tableQuery --- ', tableQuery);
  console.log('tableProps --- ', tableProperties);
  useEffect(() => {
    let headerKeys = [];
    if (theaders && Array.isArray(theaders)) {
      theaders.forEach((header) => {
        headerKeys.push(header?.bindingKey);
      });
      setHeaderKeys(headerKeys);
    }
  }, [theaders]);

  useEffect(() => {
    if (responseData) {
      if (Array.isArray(responseData)) {
        setData(responseData);
      } else if (
        tableProperties?.listingKey &&
        responseData.hasOwnProperty(tableProperties?.listingKey)
      ) {
        if (
          tableProperties?.metaKey &&
          responseData.hasOwnProperty(tableProperties?.listingKey)
        ) {
          const meta = getValueFromKey(responseData, tableProperties?.metaKey);
          setMetaData(meta);
          props.setDefaultValues(meta);
        } else {
          let data = structuredClone(responseData);
          delete data[tableProperties?.listingKey];
          setMetaData(data);
          props.setDefaultValues(data);
        }
        const list = getValueFromKey(responseData, tableProperties?.listingKey);
        setData(list);
      }
    }
  }, [responseData]);

  useEffect(() => {
    setDataSourceId(tableProperties?.data_source?.id);
    setRefreshed(true);
  }, [tableQuery]);

  useEffect(() => {
    if (tableQuery?.filterValues) {
      let filterQuery = structuredClone(tableQuery?.filterValues);
      delete filterQuery?.[tableProperties?.sortingKey];
      delete filterQuery?.[tableProperties?.currPageKey ?? "page"];
      delete filterQuery?.[tableProperties?.sizeKey ?? "size"];
      if (_.isEmpty(filterQuery)) {
        setShowClearFilter(false);
        props?.setDefaultValues({...props.defaultValues, showClearFilter: false});
      }
      else {
        setShowClearFilter(true);
        props.setDefaultValues({...props.defaultValues, showClearFilter: true});
      } 
    } else {
      setShowClearFilter(true);
      props.setDefaultValues({...props.defaultValues, showClearFilter: true});
    }
  }, [tableQuery]);

  const onRowSelect = (row, iconClick = false) => {
    setSelectedRow(row);
    props.setFormValues(row);
    props.setDefaultValues({...props.defaultValues, ...row});
    props.setButtonReplacableValue(row);
    if (tableProperties?.isDetailViewAvailable && !iconClick) {
      setRightDisplay(true);
    }
  };

  const applyFilter = (filters) => {
    let filterObj = {};
    if (filters) {
      for (let key in filters) {
        if (typeof filters[key] === "object" && filters[key]?.dateRange) {
          filterObj[key] = filters[key]?.dateRange;
        } else {
          if (typeof filters[key] === "number" || filters[key].length > 0)
            filterObj[key] = filters[key];
        }
      }
    } else {
      for (let key in filterValues) {
        if (
          typeof filterValues[key] === "object" &&
          filterValues[key]?.dateRange
        ) {
          filterObj[key] = filterValues[key]?.dateRange;
        } else {
          if (
            typeof filterValues[key] === "number" ||
            filterValues[key].length > 0
          )
            filterObj[key] = filterValues[key];
        }
      }
    }
    setTableQuery(
      structuredClone({
        filterValues: structuredClone(filterObj),
        dataTransmissionMethod: tableProperties?.dataTransmissionMethod?.id,
        tableReq: true,
      })
    );
  };

  const clearAllFilters = () => {
    setFilterValues({
      [tableProperties?.currPageKey ?? "page"]: 0,
      [tableProperties?.sizeKey ?? "size"]:
        tableProperties?.itemsPerPageOptions?.split(",")?.[0] ?? 10,
    });
    setTableQuery({
      filterValues: {
        [tableProperties?.currPageKey ?? "page"]: 0,
        [tableProperties?.sizeKey ?? "size"]:
          tableProperties?.itemsPerPageOptions?.split(",")?.[0] ?? 10,
      },
      dataTransmissionMethod: tableProperties?.dataTransmissionMethod?.id,
      tableReq: true,
    });
    setSort("");
  };

  const buttonClicked = (event) => {
    switch (event.type) {
      case "rightSideMinimize":
        setRightDisplay(false);
      case "button":
        switch (event?.buttonType?.id) {
          case "refresh":
            setRefreshed(true);
            break;
        }
        break;
      default:
        break;
    }
  };

  const renderTable = () => {
    if (tableProperties && tableProperties?.tableType?.id == "bootstrapTable") {
      let tableColumns = [];
      let filterColumns = [];
      if (theaders && theaders.length > 0) {
        theaders.forEach((res, index) => {
          let column = {
            name: language.hasOwnProperty(res?.innerText)
              ? language[res?.innerText]
              : res?.innerText,
            selector: (row) => getDataInTable(row[res.bindingKey]),
            sortable: res?.isSortable,
            cell: (row) => {
              if(tRow?.[index]?.isCustomCol) {
                return (
                  <div data-tag="allowRowEvents" onClick={() => onRowSelect(row, true)}>
                    {props.renderComponent(tRow?.[index]?.components)}
                  </div>
                );
              } else {
                return (
                  <div title={row[res.bindingKey]} data-tag="allowRowEvents">
                    {row[res.bindingKey]}
                  </div>
                );
              }
            },
          };
          tableColumns.push(column);
          if (res?.isFilterable) {
            filterColumns.push(res.bindingKey);
          }
        });
        return (
          <>
            {data && data.length > 0 ? (
              <div className="dtaTableDv integrateHubTable">
                <div className={"rightTrue"}>
                  <TableComponent
                    data={data}
                    columns={tableColumns}
                    headerColumns={filterColumns}
                    onRowSelect={onRowSelect}
                    rightDisplay={rightDisplay}
                    uniqueKey={tableProperties?.uniqueKey}
                    hideSearch={!tableProperties?.showSearchBox}
                  />
                </div>
              </div>
            ) : (
              <div className="f-size-14 text-c"></div>
            )}
          </>
        );
      }
    } else if (tableProperties?.tableType?.id == "customTable") {
      return (
        <div className="dtaTableDv integrateHubTable">
          <div className={"rightTrue"}>
            <GeneratedCustomTableView
              theaders={theaders}
              headerKeys={headerKeys}
              filterValues={filterValues}
              setFilterValues={setFilterValues}
              perPageData={perPageData}
              setPerPageData={setPerPageData}
              activePage={activePage}
              setActivePage={setActivePage}
              metaData={metaData}
              setMetaData={setMetaData}
              rowIndex={rowIndex}
              setRowIndex={setRowIndex}
              rightDisplay={rightDisplay}
              setRightDisplay={setRightDisplay}
              data={data}
              applyFilter={applyFilter}
              itemsPerPageOptions={
                tableProperties?.itemsPerPageOptions ?? "10,25,50"
              }
              itemsPerPageText={tableProperties?.itemsPerPageText ?? "Show"}
              tRow={tRow}
              renderComponent={props.renderComponent}
              setFormValues={props.setFormValues}
              setButtonReplacableValue={props.setButtonReplacableValue}
              setSort={setSort}
              sort={sort}
              tableProperties={tableProperties}
              clearAllFilters={clearAllFilters}
              showClearFilter={showClearFilter}
              setSelectedRow={setSelectedRow}
              tableDataLoading={tableDataLoading}
            />
          </div>
        </div>
      );
    }
  };

  ///main render part

  return (
    <>
      <PanelGroup direction="horizontal" className="panelOverflow">
        <Panel
          id="sidebar"
          minSize={36}
          order={1}
          defaultSize={rightDisplay ? 67 : 100}
          className={
            rightDisplay
              ? "catBordDv isShowLeftPanel rwLeftPart"
              : "catBordDv rwLeftPart"
          }
        >
          {data && data.length === 0 && tableProperties?.notDataFound && tableProperties?.tableType?.id == "bootstrapTable" ? (
            <div className="text-c padding-t-10 padding-b-10 border">
              No Data Found
            </div>
          ) : (
            renderTable()
          )}
        </Panel>
        {rightDisplay ? (
          <>
            <PanelResizeHandle className="isShowLeftPanel resizeHandle">
              <div className="outlne">
                <div className="handIcn">
                  <i className="fa fa-chevron-left" aria-hidden="true"></i>
                </div>
              </div>
            </PanelResizeHandle>
            <Panel minSize={33} order={2} defaultSize={33}>
              <div className="stickyArea rBoxStyle">
                <GeneratedListDescription
                  title={props?.title}
                  selectedRow={selectedRow}
                  rightSideKeys={tableProperties?.rightSideKeys}
                  rightSideLabels={tableProperties?.rightSideLabels}
                  headerText={tableProperties?.rightSideKeys}
                  buttonClicked={buttonClicked}
                  tableProperties={tableProperties}
                />
              </div>
            </Panel>
          </>
        ) : (
          ""
        )}
      </PanelGroup>
    </>
  );
};

export default reduxForm({
  form: "ViewTableComponent",
})(ViewTableComponent);
