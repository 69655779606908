
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import DataTable from "react-data-table-component";
const paginationComponentOptions = {
  rowsPerPageText: "Rows",
 // rangeSeparatorText: "from",
};
const BasicTable = (props) => {
  return (
    <div className="dtaTableDv integrateHubTable">
      <DataTable
        className="rdt_Table"
        columns={props.tableColumns}
        data={props.data}
        pagination={props.pagination}
        onRowClicked={(row) => {
          props.onRowSelect(row);
        }}
        striped
        highlightOnHover
        pointerOnHover={props.noPointer?false:true}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        paginationComponentOptions={paginationComponentOptions}
      />
    </div>
  );
};

export default BasicTable;
