
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { HiPlus, HiOutlineRefresh } from "react-icons/hi";
import { getOnCallGroups } from "../../../../actions/onCallSchedule/common/onCallCommonAction";
import onCallDropdownPlusTypeahead from "../../common/onCallDropdownPlusTypeahead";
import { Field, change, reduxForm } from "redux-form";
import { IoSwapHorizontal } from "react-icons/io5";
import Swal from "sweetalert2";
import { LuFilterX, LuCalendarDays, LuCalendarX, LuUserPlus2, LuUserSquare2 } from "react-icons/lu";
import CommonHeaderMenu from "../../common/CommonHeaderMenu";
import { allowedRoles, rosterManagerRoleId } from "../../common/onCallUtils";
import Cookies from "universal-cookie";
const gph = new Cookies();
let roles = gph.get("gph");
if (roles) roles = roles.replace("s:", "");
if (roles) roles = roles.substring(0, roles.lastIndexOf("."));
roles = roles.split("~");
roles = roles[33] ? roles[33] : [];

const Header = (props) => {
  const dispatch = useDispatch();
  const tr = useSelector((state) => state.spcmReducer.tr);
  let groupList = useSelector((state) => state.onCallGroupList);
  let group = useSelector((state) => state.selectedOnCallGroup);
  const [groupErrorState, setGroupErrorState] = useState(false);
  const [groupInputValue, setGroupInputValue] = useState("");
  const [groupListOptions, setGroupListOptions] = useState([]);
  const [showGroupField,setShowGroupField] = useState(false);
  const [toggle,setToggle] = useState(false);
  const rolesArray = roles.split(",");
  let showOutOfOfficeButton = rolesArray.some((val) => allowedRoles.includes(val.toString())) || rolesArray.includes(rosterManagerRoleId);
  useEffect(() => {
    if (groupList && groupList.length > 0) {
      groupList = groupList.map((list) => {
        return {...list, id: list.groupId, label: list.groupName };
      });
      setGroupListOptions(groupList);
    }
  }, [groupList]);

  useEffect(() => {
    if(group && Object.keys(group).length>0){
      props.setSelectedGroupList(group);
      setGroupInputValue(group[0].label);
      setShowGroupField(true);
    }
  }, [group]);

  useEffect(() => {
    dispatch(getOnCallGroups());
  }, []);

  const onGroupSelection = (event) => {
    if (event && event[0]) {
      setGroupInputValue(event[0].label);
      dispatch(change("addUserToShift", "groupName", event[0].label));
      dispatch({ type: "SET_ONCALL_GROUP", group: event });
    } else {
      dispatch(change("addUserToShift", "groupName", ""));
      dispatch({ type: "SET_ONCALL_GROUP", group: "" });
      setShowGroupField(false);
    }
  };

  const setGroupError = () => {
    if (props.selectedGroupList.length === 0) {
      setGroupErrorState(true);
    } else {
      setGroupErrorState(false);
    }
  };

  const onGroupInputChange = (value) => {
    props.setSelectedGroupList([]);
    if (value) {
      setGroupInputValue(value);
    } else {
      setGroupInputValue("");
      onUGroupCrossClick();
    }
  };

  const onUGroupCrossClick = () => {
    props.groupCrossClicked();
    dispatch({ type: "SET_ONCALL_GROUP", group: "" });
    setShowGroupField(false);
    setGroupInputValue("");
  };

  const changeView = (type) => {
    if (props.selectedGroupList && props.selectedGroupList.length > 0) {
      props.showRightSideFun(type);
    } else {
      Swal.fire({ title: "Please select group first" });
    }
  };

  const onInputChange = (e) => {
    if (e.target.checked) {
      props.setToggle(true);
      props.setIsCalendarView(true);
    } else {
      props.setToggle(false);
      props.setIsCalendarView(false);
    }
  };

  const rosterButtons = ()=>{
    if(rolesArray.includes(rosterManagerRoleId))
    return (
      <Fragment>
        <Button
          bsPrefix=" "
          onClick={() => changeView("addUserToShift")}
          role="button"
          className="myBt"
          title={tr["Add coverage"]}
        >
          <LuUserPlus2 />
        </Button>
        <Button
          bsPrefix=" "
          title="Swap Shift"
          role="button"
          className="myBt"
          onClick={() => {
            changeView("swapShift");
          }}
        >
          <IoSwapHorizontal className="refreshFlip" />
        </Button>
      </Fragment>
    );
  }

  return (
    <Row className="margin-b-15">
      <Col lg={7} md={6} sm={12} xs={12}>
        <CommonHeaderMenu active="Calendar" />
      </Col>

      <Col lg={5} md={6} sm={12} xs={12}>
        <div className="paHedFilter">
          <Field
            component={onCallDropdownPlusTypeahead}
            errorColor={groupErrorState ? "error" : ""}
            onSelection={onGroupSelection}
            setErrorColor={setGroupError}
            name="header_group"
            id="header_group"
            className="form-control "
            options={groupListOptions}
            selectedOptions={props.selectedGroupList}
            onInputChange={onGroupInputChange}
            inputValue={groupInputValue}
            onCrossClick={onUGroupCrossClick}
            placeholder={tr["Select Group"]}
          />

          {props.isFilterApplied && (
            <Button
              bsPrefix=" "
              onClick={() => props.resetFilters()}
              role="button"
              className="myBt"
              title="Reset Filters"
            >
              <LuFilterX />
            </Button>
          )}
          {showGroupField && (
            <>
              <div className="cal-icon ms-2">
                <LuCalendarDays/>
                &nbsp;&nbsp;
              </div>
              <Form.Check
                type="switch"
                id="custom-switch"
                onChange={(e) => {
                  onInputChange(e);
                }}
                checked={props.toggle}
                title="Calendar View"
              />
              {rosterButtons()}
              {showOutOfOfficeButton && (
                <Button
                  bsPrefix=" "
                  title="Set Out Of Office"
                  role="button"
                  className="myBt"
                  onClick={() => {
                    changeView("setOutOfOffice");
                  }}
                >
                  <LuCalendarX/>
                </Button>
              )}
            </>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default reduxForm({
  form: "Header",
})(Header);