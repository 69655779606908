
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from 'react-redux';

import AmsHomeHeader from './rulesList/rulesHeader.js';
import AmsHomeListView from './rulesList/rulesListView.js';
import AmsHomeShortDesc from './rulesList/rulesShortDesc.js';
import { getAllRuleConfigurationsData } from '../../actions/amsActions/amsActions.js';



const AmsHomeIndex = (props) => {
	const dispatch = useDispatch();
	const tr = useSelector(state => state.spcmReducer.tr);

	const [isRightSideVisible, setIsRightSideVisible] = useState(false);
	const [isClearAllfilter, setIsClearAllfilter] = useState(false);
	const [rowIndex, setRowIndex] = useState(0);
	const [activePanelKey, setActivePanelKey] = useState('0');

	const [ruleId, setRuleId] = useState('');
	const [companyName, setCompanyName] = useState('');
	const [moduleName, setModuleName] = useState('');
	const [ruleName, setRuleName] = useState('');
	const [weight, setWeight] = useState('');
	const [assignmentCompanyName, setAssignmentCompanyName] = useState('');
	const [assignmentGroupName, setAssignmentGroupName] = useState('');
	const [serviceNameState, setServiceNameState] = useState('');
	const [ciServiceName, setCiServiceName] = useState('');
	const [serviceBased, setServiceBased] = useState('');
	const [status, setStatus] = useState('');
	const [eventName, setEventName] = useState('');
	const [ciClassName, setCiClassName] = useState('');
	const [ruleCondition, setRuleCondition] = useState({});
	const [isContextual, setIsContextual] = useState(true);


	const showRightSide = (value)=> {
		setIsRightSideVisible(value);
	}
	const isClearAllFilterFunc =(value)=>{
		setIsClearAllfilter(value);
	}
	const handlePanelClick=(activePanelKey)=>{
		setActivePanelKey(activePanelKey);
     }

	const showActiveRow = (index) => {
		setRowIndex(index);
	}

	const getTaskDetails=(ruleId, companyName, moduleName, ruleName, weight, assignmentCompanyName, assignmentGroupName, serviceName, ciServiceName, serviceBased, status,eventName,ciClassName,ruleCondition,isContextual) =>{

		console.log("ruleName79",ruleName);
		console.log("companyName79",companyName);
		
		setRuleId(ruleId);
		setCompanyName(companyName);
		setModuleName(moduleName);
		setRuleName(ruleName);
		setWeight(weight);
		setAssignmentCompanyName(assignmentCompanyName);
		setAssignmentGroupName(assignmentGroupName);
		setServiceNameState(serviceName);
		setCiServiceName(ciServiceName);
		setServiceBased(serviceBased);
		setStatus(status);
		setEventName(eventName);
		setCiClassName(ciClassName);
		setRuleCondition(ruleCondition);
		setIsContextual(isContextual);
		
	}

		let colWidth = "";
		isRightSideVisible ? colWidth = 8 : colWidth = 12;
		return (
			<div>

				<div className="container-fluid margin-t-10 minHeightWIB" bsClass="">
					<AmsHomeHeader
						tr={tr}
						showRightSide={showRightSide}
						isClearAllfilter={isClearAllfilter}
						isClearAllFilterFunc={isClearAllFilterFunc}
						rowIndex={rowIndex}
						showActiveRow={showActiveRow}
					/>

					<Row className="row-eq-height formGroupB10 myProfileLabel myOrders">
						<Col md={colWidth} sm={12} xs={12} className={isRightSideVisible ? " isShowLeftPanel rwLeftPart" : "rwLeftPart"}>
							<AmsHomeListView
								getTaskDetails={getTaskDetails}
								isRightSideVisible={isRightSideVisible}
								showRightSide={showRightSide}
								rowIndex={rowIndex}
								showActiveRow={showActiveRow}
								activePanelKey={activePanelKey}
								handlePanelClick={handlePanelClick}
							/>
						</Col>
						{isRightSideVisible ?
							<Col md={4} sm={12} xs={12} className="colRightHeight">
								<div className="stickyArea rBoxStyle">
									<AmsHomeShortDesc
										tr={tr}
										isRightSideVisible={isRightSideVisible}
										showRightSide={showRightSide}
										activePanelKey={activePanelKey}
										handlePanelClick={handlePanelClick}

										ruleId={ruleId}
										companyName={companyName}
										moduleName={moduleName}
										ruleName={ruleName}
										weight={weight}
										assignmentCompanyName={assignmentCompanyName}
										assignmentGroupName={assignmentGroupName}
										serviceName={serviceNameState}
										ciServiceName={ciServiceName}
										serviceBased={serviceBased}
										status={status}
										eventName={eventName}
										// ciClassName={ciClassName}
										ruleCondition={ruleCondition}
										isContextual={isContextual}
									/>
								</div>
							</Col> : ""}
					</Row>
				</div>
			</div>
		);
	
}



export default AmsHomeIndex;
