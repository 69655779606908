
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { reduxForm } from "redux-form";
import moment from "moment";
import Cookies from "universal-cookie";
import { createBulletin, deleteBulletinData, updateBulletin } from "../../../../../actions/foundation/general/BulletinAction";
import axios from "axios";
import Swal from "sweetalert2";
import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import { HiOutlineTrash } from "react-icons/hi";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';

const cookies = new Cookies();

let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

const BulletinHeader = (props) => {
  const navigate = useNavigate();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const showLoader = useSelector((state) => state.commonLoader.isLoading);

  const dispatch = useDispatch();
  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const saveObjRef = useRef(null);

	useEffect(() => {
		context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
    context.newTabPrevent();
	}, []);

  const submitForm = (values) => {
    if (props.formType === "Create") {
      axios
      .get(
        `/api/countOfCreatedBulletin?companyID=${values.company_id}&bulletinSequence=${parseInt(values.sequence, 10)}`
      )
      .then((response) => {
        let bulletinSequence = response.data.bulletinSequence;

        if (bulletinSequence > 0) {
          Swal.fire("Bulletin with same sequence already exists.");
          return;
        }
        let payload = {
          companyId: values.company_id.toString(),
          fromDate: moment(values.fromDate, jwtdf + "hh:mm A").format(jwtdf + " HH:mm:ss"),
          toDate: moment(values.toDate, jwtdf + "hh:mm A").format(jwtdf + " HH:mm:ss"),
          status: values.status,
          summary: null,
          details: values.description,
          title: values.title,
          bulletinIconTempURL: null,
          bulletinSequence: values.sequence,
          filterby: null,
          filterbytext: null,
          pagesize: 0,
          eventNameDefault: null,
          bulletinUrl: values.bulletinURL,
        };
        console.log("payload ---- 1", payload);
  
        dispatch(createBulletin(payload));
  
      })
    } else {
      axios
      .get(
        `/api/countOfCreatedBulletin?companyID=${values.company_id}&bulletinSequence=${parseInt(values.sequence, 10)}&id=${values.bulletinId}`
      )
      .then((response) => {
        let bulletinSequence = response.data.bulletinSequence;

        if (bulletinSequence > 0) {
          Swal.fire("Bulletin with same sequence already exists.");
          return;
        }

        console.log("values --- ", values, props.formType);

        let payload = {
          bulletinId: values.bulletinId,
          companyId: values.company_id.toString(),
          fromDate: moment(values.fromDate, jwtdf + "hh:mm A").format(jwtdf + " HH:mm:ss"),
          toDate: moment(values.toDate, jwtdf + "hh:mm A").format(jwtdf + " HH:mm:ss"),
          status: values.status,
          summary: null,
          details: values.description,
          title: values.title,
          bulletinIconTempURL: null,
          bulletinSequence: values.sequence,
          filterby: null,
          filterbytext: null,
          pagesize: 0,
          eventNameDefault: null,
          bulletinUrl: values.bulletinURL,
        };
  
        console.log("Payload --- ", payload);
        dispatch(updateBulletin(payload));
  
      })
    }
  };
   
  let id = props.bulletinId;
  const onDeleteClick = () => {
    console.log("CheckId", id)
      Swal.fire({
        title: tr["Are you sure you want to delete?"],
        width: 550,
        padding: '15px',
        showDenyButton: true,
        confirmButtonText: tr['Yes'],
        denyButtonText: tr['No'],
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteBulletinData(id))
        } else if (result.isDenied) {
          
        }
      })
    };
  let { handleSubmit, submitting } = props;

  return (
    <Row className="margin-b-15">
      <Col lg={4} md={6} sm={6} xs={12}>
        <h1 bsClass="" className="sPageHeading1">
          {props.name}
        </h1>
      </Col>
      <Col lg={8} md={6} sm={6} xs={12}>
        <div className="paHedFilter">
        {props.formType === 'Edit' ?(<Button role="button" bsPrefix=' ' className="myBt rclose" title={tr["Delete"]} onClick={onDeleteClick}>
             <HiOutlineTrash/>
          </Button>):''}
          <Link
            bsPrefix=" "
            id="hdID"
            to="javascript:void(0)"
            className="myBt plus ctrlKeyPrevent"
            disabled={showLoader}
            onClick={handleSubmit(submitForm)}
            title={tr["Save"]}
            ref={saveObjRef}
          >
            <span>{showLoader ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/> }</span>
          </Link>
          <Link
            bsPrefix=" "
            to="/bulletin"
            className="myBt cancel fillBtn ctrlKeyPrevent"
            title={tr["Cancel"]}
            ref={closeButton}
          >
            <IoClose/>
          </Link>
        </div>
      </Col>
    </Row>
  );
};

export default reduxForm({
  form: "bulletinForm",
  enableReinitialize: false,
  destroyOnUnmount: true,
})(BulletinHeader);
