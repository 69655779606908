
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm, change } from 'redux-form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import {
  _inputField,
  _dropDown
} from '../../common/ReduxFormFields/CategoryBoard/formFields';
import {
  businessFunctionByCompanyId,
  businessFunctionListIntialValues,
  resetParentFunction
} from '../../../actions/businessFunction/businessFunctionAction';
import { getCompanyList } from '../../../actions/spcmActions';

const validate = (values, props) => {
  const errors = {};
  if (!values.companyName) errors.companyName = 'Select Company';

  if (!values.businessFunctionName) errors.businessFunctionName = 'Required';
  else if (values.businessFunctionName.trim() === '')
    errors.businessFunctionName = 'Enter businessFunctionName';

  if (values.businessFunctionDesc && values.businessFunctionDesc.trim() === '')
    errors.businessFunctionDesc = 'Enter businessFunctionDesc';

  return errors;
}; 

class EditBusinessFunctionForm extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { initialValues, dispatch } = this.props;

    dispatch(change('EditBusinessFunctionForm', 'id', initialValues.id));
    const companyId = initialValues.companyId;
    dispatch(getCompanyList(''));
    dispatch(businessFunctionByCompanyId(companyId));
  }
  componentWillUnmount(){
    this.props.resetParentFunction();
  }
  render() {
    const {
      parentFunctionByCompanyIdDropdown,
      spcmReducer,
      dispatch
    } = this.props;

    const onCompanyChange = (e) => {
      dispatch(businessFunctionByCompanyId(e.target.value));
      dispatch({ type: 'PARENTFUNCTION_BY_COMPANY_ID_REFRESH' });
    };

    const countCharacters = (e) => {
      if (e.target.value.length > 50) {
        e.preventDefault();
      }
    };
    const filterDropdownData =() => {
      if (parentFunctionByCompanyIdDropdown && parentFunctionByCompanyIdDropdown.data && Array.isArray(parentFunctionByCompanyIdDropdown.data)) {
        const filteredData = parentFunctionByCompanyIdDropdown.data.filter(item => (
          item.businessFunctionName !== this.props.initialValues.businessFunctionName
        ));
        parentFunctionByCompanyIdDropdown.data = filteredData;
      } 
    }
    const companyList =
      spcmReducer.companyList?.map((item) => ({
        value: item.field1Value,
        text: item.field1Key
      })) || [];

    return (
      filterDropdownData(),
      <div>
        {/* {error == null ? null : <Alert variant="danger" className="errorMessageSec"><p>{error}</p></Alert>} */}
        <div className="borderForm bFormDiv">
          <form>
            <Row>
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">
                    <span className="rStar"></span>
                    {this.props.translator['Company']}
                  </Form.Label>
                  <Field
                    component={_dropDown}
                    name="companyId"
                    onChange={onCompanyChange}
                    options={spcmReducer.companyList || []}
                    className="form-control"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">
                    <span className=""></span>
                    {this.props.translator['Parent Function']}
                  </Form.Label>
                  {console.log("parentFunctionByCompanyIdDropdown.data==",parentFunctionByCompanyIdDropdown.data)}
                  <Field
                    component={_dropDown}
                    name="parentFunction"
                    options={parentFunctionByCompanyIdDropdown.data || []}
                    className="form-control"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">
                    <span className="rStar"></span>
                    {this.props.translator['Name']}
                  </Form.Label>
                  <Field
                    name="businessFunctionName"
                    className="form-control"
                    component={_inputField}
                    onPaste={(e) => {
                      countCharacters(e);
                    }}
                    onChange={(e) => {
                      countCharacters(e);
                    }}
                  />
                  <span className="f-size-10">
                    {this.props.translator['Max Length in 50']}
                  </span>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">
                    <span className=""></span>
                    {this.props.translator['Long Name']}
                  </Form.Label>
                  <Field
                    name="businessFunctionDesc"
                    className="form-control"
                    component={_inputField}
                    onPaste={(e) => {
                      countCharacters(e);
                    }}
                    onChange={(e) => {
                      countCharacters(e);
                    }}
                  />
                  <span className="f-size-10">
                    {this.props.translator['Max Length in 50']}
                  </span>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">
                    <span className="rStar"></span>
                    {this.props.translator['Status']}
                  </Form.Label>
                  <Field
                    name="status"
                    component="select"
                    className="form-control"
                  >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </Field>
                </Form.Group>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  initialValues: {
    parentFunction: state.businessFunctionListIntialValues.parentFunctionId, 
    ...state.businessFunctionListIntialValues
  },
  parentFunctionByCompanyIdDropdown: state.parentFunctionByCompanyIdDropdown,
  spcmReducer: state.spcmReducer
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      resetParentFunction,
      change
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'EditBusinessFunctionForm',
    destroyOnUnmount: true,
    validate
  })(EditBusinessFunctionForm)
);
