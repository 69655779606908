
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";

export const SvgIcon = ({ iconData }) => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox={iconData?.viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      {iconData?.path.map((path, index) => (
        <path key={index} d={path} />
      ))}
    </svg>
  );
};
