
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Field, reduxForm, formValueSelector, change, SubmissionError } from 'redux-form';
import swal from 'sweetalert';
import CommonHeaderMenu from "../../common/CommonHeaderMenu";
import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import { MyContext } from '_MyContext';

const Header = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const translator = useSelector((state) => state.spcmReducer.tr);
	const [saveLoader, setSaveLoader] = useState(false);
	const context = useContext(MyContext);
    const closeButton = useRef(null);
    const saveObjRef = useRef(null);

    useEffect(() => {
		context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
		context.newTabPrevent();
	}, []);

	function submit(values) {
		let submitFlag = true;
		if (!values.scheduleName || values.scheduleName.trim() == '') {
			props.setScheduleNameErrorState('error');
			submitFlag = false;
		} else {
			props.setScheduleNameErrorState('');
		}
		if (!values.scheduleType) {
			props.setScheduleTypeErrorState('error');
			submitFlag = false;
		} else {
			props.setScheduleTypeErrorState('');
		}
		if (!values.timeZone) {
			props.setTimeZoneErrorState('error');
			submitFlag = false;
		} else {
			props.setTimeZoneErrorState('');
		}
		if(submitFlag == false)
			return false;
		let postObj = {
			"scheduleName": values.scheduleName,
			"scheduleType": values.scheduleType,
			"status": values.onCallStatus,
			"timeZone": values.timeZone
		};
		setSaveLoader(true);
		axios.post('/rest/aiosem/saveScheduleAction', postObj).then(function (response) {
			if (response.status == 200) {
				setSaveLoader(false);
				navigate("/onCallSchedule/editSchedule/" + response.data.data.scheduleId.toString());
			}
		}).catch(function (error) {
			setSaveLoader(false);
			swal({
				text: error.response.data.businessMessage,
				button: translator['OK'],
			});
		});
	}

	return (
		<Row className="margin-b-15">
			<Col lg={6} md={6} sm={12} xs={12}>
				{/* <h1 bsClass="" className="sPageHeading1">Schedule</h1> */}
				<CommonHeaderMenu active="Schedule"/>
			</Col>
			<Col lg={6} md={6} sm={12} xs={12}>
				<div className="paHedFilter">
					<Link to="javascript:void(0)" bsPrefix=' ' id="creTag" className="myBt plus ctrlKeyPrevent" disabled={saveLoader} onClick={props.handleSubmit(submit)} title={translator['Save']} ref={saveObjRef}>{saveLoader ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/>}</Link>
					<Link to="/onCallSchedule/schedule" bsPrefix=' ' className="myBt cancel fillBtn ctrlKeyPrevent" title={translator['Cancel']} ref={closeButton} ><IoClose /></Link>
				</div>
			</Col>
		</Row>
	)
}

export default reduxForm({
	form: 'onCallScheduleCreateScheduleForm',
	destroyOnUnmount: true,
	enableReinitialize: true
})(Header);