
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, {useEffect, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import EditIncidentIndex from "./EditIncidentIndex";

export default function EditIncidentMain() {
  const [incidentParams, setIncidentParams] = useState({breakFixItemId: ""});
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  useEffect(() => {
    // console.log('params - ', params);
    setIncidentParams({breakFixItemId: params.breakFixItemId});
  }, []);
  useEffect(() => {
    // console.log('updated params - ', params);
    if(params.breakFixItemId !== incidentParams.breakFixItemId){
      setLoading(true);
      setTimeout(() => {
        setIncidentParams({breakFixItemId: params.breakFixItemId});
        setLoading(false);
      }, 200);
    }
  }, [params.breakFixItemId]);
  // console.log('incidentParams - ', incidentParams);
  // console.log('params - ', params);
  if(isLoading){
    return null;
  }
  return <EditIncidentIndex navigate={navigate} params={incidentParams} />;
}
