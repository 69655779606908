
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import constants from '../utils/constants';

export function getQuestionsAPI(start,end,query,userId,offeringId){
    return axios.get(constants.communityurl+'/api/community?&start='+start
    +'&end='+end+'&searchText='+query+'&user_id='+userId+'&offeringId='+offeringId);
}

export function getQuestionsAPIForCommunityGlobalSearch(start,end,query,userId,sortBy,sortType){
    return axios.get(constants.communityurl+'/api/community?&start='+start
    +'&end='+end+'&searchText='+query+'&user_id='+userId+'&Type='+sortBy+"&sortBy="+sortType);
}
