
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';

//let user_id=userDetails.user_id;
//let company_id=userDetails.company_id;

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});




export function loadCatServices(catSelling){
	return {
		type: 'FETCH_CATSELLING',
		catSelling
	};
}

export function loadCatSelling(id,start,end){
	//alert(id)
	let str={};
	str.categoryId=id;
	if(start!=""){
		str.start=start;
	}
	if(end!=""){
		str.end=end;
	}
	str = JSON.stringify(str);
	const catSellingUrl="/getOfferingsListForCategory";
	return(dispatch) =>{
		dispatch({type: "LOADER_IN_PROGRESS_2"});
		api.get(catSellingUrl,{headers:{'query':str}})
			.then((catSelling) =>{
				dispatch(loadCatServices(catSelling));
				dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY_2'});
			})
			.catch((err) => {
				// console.log(err.response.data);
				dispatch(loadCatServices(err.response));
				dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY_2'});
			});
	};
}
