
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';

import MultiselectTwoSides from 'react-multiselect-two-sides';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';

//import MultiSelect from 'react-select';
import { loadReportCompany, loadReportModule, loadReportFields } from '../../actions/reports/reportsAction';
import { loadReportDataCompany } from '../../actions/cmdb/lovAction';
import { connect } from 'react-redux';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import LoadingIcons from 'react-loading-icons';
import GenerateCriteria from './ReportConsoleCriteria.js';
import GenerateTable from './GenerateReport';
//let user_id=userDetails.user_id;
//let company_id=userDetails.company_id;

const FLAVOURS = [
	{ label: 'Work Item ID', value: 'Work Item ID' },
	{ label: 'Service Name', value: 'Service Name' },
	{ label: 'Status', value: 'Status' },
	{ label: 'Priority', value: 'Priority' },
	{ label: 'Urgency', value: 'Urgency' },
	{ label: 'Group', value: 'Group' },
	{ label: 'Requested by', value: 'Requested by' }
];

class ReportCenter extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			genReport: false,
			selectCriteria: false,
			selectFields: false,
			showReport: false,
			value: [],
			modulename: '',
			searchbylist: '',
			operatorlist: '',
			valuelist: '',
			criteriaObject: '',
			moduleid: '',
			companyId: '',
			companyName: '',
			selectfield: '',
			selectcriteria: '',
			generatereport: ''
		};
		this.handleChange = this.handleChange.bind(this);
		this.genReportView = this.genReportView.bind(this);
		this.getCriteria = this.getCriteria.bind(this);
	}

	// 	componentWillMount(){
	//     //this.props.loadReportCompany(user_id);
	// 		this.props.loadCICompany();
	// 		//this.props.loadReportModule("module");
	// 		this.props.loadReportModule();
	// 		//this.props.loadReportFields("breakfix");
	//   }
	componentWillMount() {
		//this.props.loadReportCompany(user_id);
		this.props.loadReportDataCompany();
		//this.props.loadReportModule("module");
		this.props.loadReportModule();
		//this.props.loadReportFields("breakfix");
	}

	handleChange(value) {
		// console.log("values>>>", value);
		this.setState({ value });
	}

	genReportView(firstVal, SecVal) {
		this.setState({ genReport: firstVal });
		this.setState({ showReport: SecVal });
		this.setState({ selectCriteria: false });
		this.setState({ selectFields: false });
	}

	showCriteria() {
		this.setState({ selectCriteria: true });
		this.setState({ selectFields: false });
	}

	showFields() {
		this.setState({ selectCriteria: false });
		this.setState({ selectFields: true });
	}

	/*****--START MODIFIED FOR PRE-SELECTED FIELDS IN REPORT GENERATION [ADIL SALEEM - 10-11-2021]-----*******/
	setModuleName(e) {
		this.setState({ value: [] });
		this.setState({ modulename: e.target[e.target.selectedIndex].text });
		this.setState({ moduleid: e.target.value });
		//alert(e.target[e.target.selectedIndex].text);
		//this.props.loadReportFields(e.target[e.target.selectedIndex].text);
		// console.log("e.target.value : ", e.target.value);
		if (e.target.value != "")
			this.props.loadReportFields(e.target.value);
		if (e.target.value != "" && (this.state.companyName != "" && this.state.companyName != "Select")) {
			this.setState({ selectfield: true });
			this.setState({ selectcriteria: true });
			this.setState({ generatereport: true });
			let initialSelectedValue = [];
			if (e.target.value == 'cmdb') {
				initialSelectedValue = ['CI_ID', 'CI_NAME', 'CLASS_ID', 'COMPANY_NAME', 'STATUS'];
			} else if (e.target.value == 'xsm_change') {//RFC
				initialSelectedValue = ['change_number', 'service_name', 'description', 'status', 'created_by_name', 'created_on', 'exp_end_date'];
			} else if (e.target.value == 'xsm_investigation') { //Problem
				initialSelectedValue = ['investigation_number', 'service_name', 'description', 'status', 'priority', 'created_by_name', 'created_on'];
			} else if (e.target.value == 'xsm_item') { //Fulfillment
				initialSelectedValue = ['item_number', 'offering_name_sx', 'consumer_company_name', 'approval_status', 'requester_name', 'created_on'];
			} else if (e.target.value == 'task') {//Task
				initialSelectedValue = ['parentWorkItemId','taskCode', 'serviceName', 'companyName', 'description', 'status', 'createdDate', 'createdBy'];
			} else if (e.target.value == 'breakfix') {//Incident
				initialSelectedValue = ['breakfix_number', 'service_name', 'description', 'status', 'priority_id', 'created_by_name', 'created_on'];
			}
			this.setState({ value: initialSelectedValue });
		} else {
			this.setState({ selectfield: "" });
			this.setState({ selectcriteria: "" });
			this.setState({ generatereport: "" });
		}
	}
	/*****--END MODIFIED FOR PRE-SELECTED FIELDS IN REPORT GENERATION [ADIL SALEEM - 10-11-2021]-----*******/

	setCompany(e) {
		this.setState({ value: [] });
		this.setState({ companyId: e.target.value });
		this.setState({ companyName: e.target[e.target.selectedIndex].text });
		if (e.target.value != "" && (this.state.modulename != "" && this.state.modulename != "Select")) {
			this.setState({ selectfield: true });
			this.setState({ selectcriteria: true });
			this.setState({ generatereport: true });
			let initialSelectedValue = [];
			if (this.state.moduleid == 'cmdb') {
				initialSelectedValue = ['CI_ID', 'CI_NAME', 'CLASS_ID', 'COMPANY_NAME', 'STATUS'];
			} else if (this.state.moduleid == 'xsm_change') {//RFC
				initialSelectedValue = ['change_number', 'service_name', 'description', 'status', 'created_by_name', 'created_on', 'exp_end_date'];
			} else if (this.state.moduleid == 'xsm_investigation') { //Problem
				initialSelectedValue = ['investigation_number', 'service_name', 'description', 'status', 'priority', 'created_by_name', 'created_on'];
			} else if (this.state.moduleid == 'xsm_item') { //Fulfillment
				initialSelectedValue = ['item_number', 'offering_name_sx', 'consumer_company_name', 'approval_status', 'requester_name', 'created_on'];
			} else if (this.state.moduleid == 'task') {//Task
				initialSelectedValue = ['parentWorkItemId','taskCode', 'serviceName', 'companyName', 'description', 'status', 'createdDate', 'createdBy'];
			} else if (this.state.moduleid == 'breakfix') {//Incident
				initialSelectedValue = ['breakfix_number', 'service_name', 'description', 'status', 'priority_id', 'created_by_name', 'created_on'];
			}
			this.setState({ value: initialSelectedValue });
		}
		else {
			this.setState({ selectfield: "" });
			this.setState({ selectcriteria: "" });
			this.setState({ generatereport: "" });
		}
	}

	getCriteria(searchbylist, patternlist, multiplevaluelist, criteriaObj) {
		this.setState({ searchbylist: searchbylist });
		this.setState({ operatorlist: patternlist });
		this.setState({ valuelist: multiplevaluelist.replace(/,/g, "|").replace(/~/g, ",") });
		this.setState({ criteriaObject: criteriaObj });
	}

	renderCompany(slaCompanyData) {
		//console.log(classData)
		if (slaCompanyData.length == 0 || slaCompanyData.length == undefined) {
			return (
				null
			);
		}
		return slaCompanyData.map((objectData) => {

			return (
				<option value={objectData.field1Value}>{objectData.field1Key}</option>
			);
		});
	}

	renderModule(slaModuleData) {
		//console.log(classData)
		if (slaModuleData.length == 0 || slaModuleData.length == undefined) {
			return (
				null
			);
		}
		return slaModuleData.map((objectData) => {
			if (objectData.field1Key != "") {
				return (
					<option value={objectData.field1Key}>{objectData.field1Value}</option>);
			}
		});
	}

	render() {
		if (this.props.companyReportData.length == 0 || this.props.companyReportData == undefined ||
			this.props.reportModule.length == 0 || this.props.reportModule == undefined) {
			return (
				<LoadingIcons.Bars height="5em" stroke="transparent" fill="#06bcee" />
			);
		}
	
		let myObj = []; let reportDataDetails = []; let reportLabel = ""; let reportValue = "";
		if (this.props.reportFields.length != 0 || this.props.reportFields != undefined) {
			let reportData = this.props.reportFields;

			for (let i = 0; i < reportData.length; i++) {
				let reportFilterData = (reportData[i]) ? (reportData[i]).split("|") : "";
				reportLabel = reportFilterData[1];
				reportValue = reportFilterData[0];
				if (reportLabel == "Sla Status")
					reportLabel = "SLA Status";
				if (reportValue == "statusName")
					reportValue = "status";
				myObj = { name: '', value: '' };
				myObj.name = reportLabel;
				myObj.value = reportValue;
				if(!(reportValue == 'cause')){
					reportDataDetails.push(myObj);
				}
			}
		} else {
			reportDataDetails = [];
		}

		let selectedCount = this.state.value.length;

		console.log('***********', {reportDataDetails});

		return (
			<div className="reports">

				{this.state.showReport ? null :
					<div>
						<div className="bgActive padding-10 bFormDiv">
							<Row>
								<Col md={4} sm={6} xs={12}>
									<Form.Label bsClass=""><span className="rStar"></span> {this.props.translator['Company']}</Form.Label>
									<Form.Select componentClass="select" defaultValue={this.state.companyId} onChange={(event) => { this.setCompany(event) }}>
										<option value="">Select</option>
										{this.renderCompany(this.props.companyReportData)}
									</Form.Select>
								</Col>
								<Col md={4} sm={6} xs={12}>
									<Form.Label bsClass=""><span className="rStar"></span> {this.props.translator['Module']}</Form.Label>
									<Form.Select componentClass="select" defaultValue={this.state.moduleid} onChange={(event) => { this.setModuleName(event) }}>
										<option value="">Select</option>
										{this.renderModule(this.props.reportModule)}
										<option value="task">Task</option>										
									</Form.Select>
								</Col>								
							</Row>
						</div>

						{this.state.selectFields ?
							<div className="margin-t-20">
								<h2 className="margin-0 margin-b-5">{this.props.translator['Select Fields']}</h2>
								<MultiselectTwoSides
									options={reportDataDetails}
									value={this.state.value}
									className="msts_theme_example"
									onChange={this.handleChange}
									availableHeader={this.props.translator['Available']}
									availableFooter={this.props.translator['Available'] + ': ' + +(reportDataDetails.length - selectedCount)}
									selectedHeader={this.props.translator['Selected']}
									selectedFooter={this.props.translator['Selected'] + ': ' + +selectedCount}
									labelKey="name"
									//valueKey="value"
									showControls={false}
									searchable
								/>
							</div>
							: null}

						{this.state.selectCriteria ?
							<div className="margin-t-20">
								<GenerateCriteria translator={this.props.translator} module={this.state.modulename} getCriteria={this.getCriteria} moduleid={this.state.moduleid} user_id={this.props.user_id} date_format={this.props.date_format} />
							</div>
							: null}


						<div className="padding-10">
							<Row>
								<Col md={12}>
									<div className="text-c">
										<ButtonToolbar className="mybtnToolbar reportBtnGrp">
											{this.state.selectfield == "" ? <Button onClick={this.showFields.bind(this)} variant="primary" bsSize="small" disabled="true">{this.props.translator['Select Fields']}</Button> : <Button onClick={this.showFields.bind(this)} variant="primary" bsSize="small">{this.props.translator['Select Fields']}</Button>}
											{this.state.selectcriteria == "" ? <Button onClick={this.showCriteria.bind(this)} variant="primary" bsSize="small" disabled="true">{this.props.translator['Last Used Search Criteria']}</Button> : <Button onClick={this.showCriteria.bind(this)} variant="primary" bsSize="small">{this.props.translator['Last Used Search Criteria']}</Button>}
											{this.state.generatereport == "" ? <Button onClick={() => { this.genReportView(true, true) }} variant="primary" bsSize="small" disabled="true">{this.props.translator['Generate Report']}</Button> : <Button onClick={() => { this.genReportView(true, true) }} variant={this.state.genReport ? 'primary' : 'default'} bsSize="small" disabled={this.state.genReport ? true : false}>{this.props.translator['Generate Report']}</Button>}
										</ButtonToolbar>
									</div>
								</Col>
							</Row>
						</div>
					</div>
				}

				<div className="">{this.state.genReport ?
					<GenerateTable translator={this.props.translator} module={this.state.modulename} selectedFields={this.state.value} callParFun={this.genReportView}
						searchbylist={this.state.searchbylist} operatorlist={this.state.operatorlist} valuelist={this.state.valuelist}
						criteriaObject={this.state.criteriaObject} companyId={this.state.companyId} companyName={this.state.companyName}
						reportFields={this.props.reportFields} /> : null}
				</div>

			</div>
		)
	}
}



export function mapStateToProps({ reportModule, reportFields, companyReportData }) {
	
	return { reportModule, reportFields, companyReportData };
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ loadReportModule, loadReportFields, loadReportDataCompany }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportCenter);

