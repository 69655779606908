
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const customeAccordionComponent = {
    name: "CustomAccordion",
    traits: [
      {
        type: "select",
        label: "Data Source",
        name: "data_source",
        options: [],
        changeProp: 1,
      },
      {
        type: "text",
        label: "Anchor Title",
        name: "anchor-title",
        placeholder: "Title",
        changeProp: 1,
      },
      {
        type: "text",
        label: "Icon Name",
        name: "iconName",
        placeholder: "icon name",
        changeProp: 1,
      },
      {
        type: "text",
        label: "Anchor Url",
        name: "anchor-url",
        placeholder: "Url",
        changeProp: 1,
      },
      {
        type: "checkbox",
        label: "Is External",
        name: "isExternal",
        changeProp: 1,
      }
    ],
  };
  
  
  export const customAccordionAdd = {
    model: {
      defaults: {
        tagName: "div",
        draggable: true,
        attributes: {
          class: "custom-accordion accordion",
          id: "accordionExample",
          "data-gjs-type": "accordions",
        },
        type: "accordions",
        components: [
          {
            tagName: "div",
            type: "accordion",
            attributes: { class: "card" },
            components: [
              {
                tagName: "div",
                type: "accordion-title",
                attributes: { class: "card-header", id: "headingOne" },
                components: [
                  {
                    tagName: "h2",
                    attributes: { class: "mb-0" },
                    components: [
                      {
                        tagName: "button",
                        content: "Collapsible Group Item #1",
                        attributes: {
                          class: "btn btn-link btn-block text-left",
                          type: "button",
                          "data-toggle": "collapse",
                          "data-target": "#collapseOne",
                          "aria-expanded": "true",
                          "aria-controls": "collapseOne",
                        },
                      },
                    ],
                  },
                ],
              },
              {
                tagName: "div",
                type: "accordion-body",
                attributes: {
                  class: "collapse show",
                  id: "collapseOne",
                  "aria-labelledby": "headingOne",
                  "data-parent": "#accordionExample",
                  "data-gjs-type": "accordian-body",
                },
                components: [
                  {
                    tagName: "div",
                    attributes: { class: "card-body" },
                    content:
                      "Some placeholder content for the first accordion panel. This panel is shown by default",
                  },
                ],
              },
            ],
          },
        ],

        traits: customeAccordionComponent.traits,
        editable: true,
      },
      init() {
        this.on("change:iconName", this.updateAccordionClass);
      },
      updateAccordionClass() {
        const model = this.find("i")[0];
        const iconClass = this.get("iconName");
        model.setClass(iconClass);
      },
    },
  };
  
  export const addCustomAccordionComponent = (e) => {
    e.DomComponents.addType(customeAccordionComponent.name, customAccordionAdd);
  };
  
  export const customAccordionBlockJson = {
    id: "customAccordion",
    label: "Custom Accordion",
    media:`<svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 100 125" x="0px" y="0px"><title>Userinterface</title><path d="M87,9H13a4,4,0,0,0-4,4V31a4,4,0,0,0,4,4H87a4,4,0,0,0,4-4V13A4,4,0,0,0,87,9Zm2,22a2,2,0,0,1-2,2H13a2,2,0,0,1-2-2V13a2,2,0,0,1,2-2H87a2,2,0,0,1,2,2ZM52,22a1,1,0,0,1-1,1H18a1,1,0,0,1,0-2H51A1,1,0,0,1,52,22Zm30.33-4.07a1,1,0,0,1,.14,1.41l-5,6.12a1,1,0,0,1-.77.37,1,1,0,0,1-.78-.37l-5-6.12a1,1,0,0,1,1.55-1.27l4.23,5.18,4.22-5.18A1,1,0,0,1,82.33,17.93ZM87,37H13a4,4,0,0,0-4,4V59a4,4,0,0,0,4,4H87a4,4,0,0,0,4-4V41A4,4,0,0,0,87,37Zm2,22a2,2,0,0,1-2,2H13a2,2,0,0,1-2-2V41a2,2,0,0,1,2-2H87a2,2,0,0,1,2,2ZM52,50a1,1,0,0,1-1,1H18a1,1,0,0,1,0-2H51A1,1,0,0,1,52,50Zm30.33-4.07a1,1,0,0,1,.14,1.41l-5,6.12a1,1,0,0,1-.77.37,1,1,0,0,1-.78-.37l-5-6.12a1,1,0,0,1,1.55-1.27l4.23,5.18,4.22-5.18A1,1,0,0,1,82.33,45.93ZM87,65H13a4,4,0,0,0-4,4V87a4,4,0,0,0,4,4H87a4,4,0,0,0,4-4V69A4,4,0,0,0,87,65Zm2,22a2,2,0,0,1-2,2H13a2,2,0,0,1-2-2V69a2,2,0,0,1,2-2H87a2,2,0,0,1,2,2ZM52,78a1,1,0,0,1-1,1H18a1,1,0,0,1,0-2H51A1,1,0,0,1,52,78Zm30.33-4.07a1,1,0,0,1,.14,1.41l-5,6.12a1,1,0,0,1-.77.37,1,1,0,0,1-.78-.37l-5-6.12a1,1,0,0,1,1.55-1.27l4.23,5.18,4.22-5.18A1,1,0,0,1,82.33,73.93Z"/></svg>`,
    // attributes: { class: "fa fa-eye" },
    category: "Basic",
    content: {
      type: customeAccordionComponent.name,
    },
  };
 