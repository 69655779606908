
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Row, Col, Alert, Form, Table, ButtonToolbar, Button } from "react-bootstrap";
import { loadCIAtt, loadModelName } from "../../../../actions/cmdb/lovAction";
import { connect } from "react-redux";
import axios from "axios";
import { bindActionCreators } from "redux";
import { GLOBAL } from "_Globals";
import constanturl from "../../../../utils/constants.js";
import Datetime from "react-datetime";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import moment from "moment";
import Cookies from "universal-cookie";
import {HiPlus} from 'react-icons/hi';
import {IoClose} from "react-icons/io5";

const cookies = new Cookies();
let languageSelected = cookies.get("gph");
languageSelected = languageSelected.split("~");
languageSelected = languageSelected[20];

let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
dateformat = dateformat.split("~");
dateformat = dateformat[2];

class CmdbAttribute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formToggle: false,
      formeditToggle: false,
      formaddToggle: true,
      attname: "",
      attval: "",
      // attkey: "",
      attid: "",
      attributeInputType: "Textbox",
      dropdownOptions: [],
      ciAttMapId: "",
      selectedValueMultiselect: [],
			selectedValueTextbox: "",
			selectedValueDropdown: "",
			selectedValueDatetime: ""
    };
		// GLOBAL.editattval = "";
    GLOBAL.attid = "";
    GLOBAL.attval = "";
    GLOBAL.attkey = "";
		// GLOBAL.editattkey = "";
    this.formToggleFun = this.formToggleFun.bind(this);
    this.formCancelFun = this.formCancelFun.bind(this);
    this.formeditToggleFun = this.formeditToggleFun.bind(this);
  }

  formToggleFun(value) {
    this.setState({ formToggle: value });
    this.setState({ formeditToggle: false });
    this.setState({ formaddToggle: false });
  }

  formCancelFun(value1) {
    this.setState({ formToggle: false });
    this.setState({ formeditToggle: false });
    this.setState({ formaddToggle: value1 });
    this.setState({ selectedValueMultiselect: [] });
    this.setState({ dropdownOptions: [] });
		this.setState({ selectedValueTextbox: "" });
		this.setState({ selectedValueDropdown: "" });
		this.setState({ selectedValueDatetime: "" });
		this.setState({ showError: false, showErrorText: "" });
    GLOBAL.attid = "";
    GLOBAL.attval = "";
		// GLOBAL.editattval = "";
    GLOBAL.attkey = "";
		// GLOBAL.editattkey = "";
  }

  formeditToggleFun(value, attrObj) {
    this.setState({ formeditToggle: value });
    this.setState({ formToggle: false });
    this.setState({ formaddToggle: false });
		this.setState({ attname: attrObj.DISPLAY_NAME });
    this.setState({ attval: attrObj.VALUE });
    // this.setState({ attkey: attrObj.KEY });
    this.setState({ attid: attrObj.ATTRIBUTE_ID });
    this.setState({ ciAttMapId: attrObj.CI_ATT_MAP_ID });
    this.setState({ attributeInputType: attrObj.INPUT_TYPE });
		GLOBAL.attval = attrObj.VALUE;
		GLOBAL.attkey = attrObj.KEY;
    if (attrObj.INPUT_TYPE == "Dropdown") {
      this.setState({ selectedValueDropdown: attrObj.KEY });
      let apiInfo = attrObj.API_INFORMATION;
      axios
        .get(GLOBAL.getCiAttributeValues + "/" + this.props.COMPANY_ID, {
          headers: { query: JSON.stringify(apiInfo) }
        })
        .then((response) => {
          this.setState({ dropdownOptions: response.data });
        });
    } else if (attrObj.INPUT_TYPE == "Multiselect List") {
      let apiInfo = attrObj.API_INFORMATION;
      axios
        .get(GLOBAL.getCiAttributeValues + "/" + this.props.COMPANY_ID, {
          headers: { query: JSON.stringify(apiInfo) }
        })
        .then((response) => {
          let options = [];
          response.data.forEach((element) => {
            let obj = {};
            obj.value = element.field1Value;
            obj.label = element.field1Key;
            options.push(obj);
          });
          let arr = attrObj.KEY.split(";");
          let newArr = [];
          arr.forEach((value) => {
            let obj = options.find((item) => item.value == value);
            newArr.push(obj);
          });
          // console.log('newArr==',newArr);
          this.setState({ selectedValueMultiselect: newArr });
          this.setState({ dropdownOptions: options });
        });
    }
  }

  componentWillMount() {
    this.props.loadCIAtt(this.props.ciId);
  }

  renderAtt(attData) {
    if (!attData) {
      return <option></option>;
    }
    return attData.map((attObj) => {
      if (
        attObj.VALUE != null &&
        attObj.VALUE != "" &&
        attObj.IS_DELETED == 1
      ) {
        return (
          <tr>
						<td>{attObj.DISPLAY_NAME}</td>
            <td>{attObj.VALUE}</td>
            {this.props.isRoleDisabled 
							? (
                <td className="text-c f-size-13">
                <i
                  onClick={() => {
                    this.formeditToggleFun(true, attObj);
                  }}
                  className="fa fa-edit margin-r-10 cursorPoint"
                ></i>
                <i
                  onClick={() => {
                    this.attdelete(attObj.CI_ATT_MAP_ID);
                  }}
                  className="fa fa-trash-o cursorPoint"
                ></i>
              </td>
							) : null}
          </tr>
        );
      } else return null;
    });
  }

  renderAttList(attlistData) {
    if (!attlistData) {
      return <option></option>;
    }
    return attlistData.map((attObj) => {
      if (
        (attObj.VALUE == null || attObj.VALUE == "") &&
        attObj.IS_DELETED != 1
      )
        return (
					<option value={attObj.ATTRIBUTE_ID}>{attObj.DISPLAY_NAME}</option>
        );
    });
  }

  saveatt() {
    let ref = this;
    if(this.state.attributeInputType == "DateTime" && this.state.showErrorText == this.props.translator["Please Provide Valid Date Format."])
			return false;

		if(GLOBAL.attid.trim() == "") {
			this.setState({ showError: true, showErrorText: this.props.translator["Please select Attribute Name"] });
			return false;
		} else if(GLOBAL.attval.trim() == "") {
			this.setState({ showError: true, showErrorText: this.props.translator["Value cannot be blank"] });
			return false;
		} else {
			this.setState({ showError: false, showErrorText: "" });
		}
    
    axios
      .post(GLOBAL.postCiArrtibuteUrl + "/" + this.props.ciId, {
        attr_id: GLOBAL.attid,
        value: GLOBAL.attval,
        key: GLOBAL.attkey,
      })
      .then((response) => {
        ref.setState({ formToggle: false });
        ref.setState({ formeditToggle: false });
        this.setState({ formaddToggle: true });
        GLOBAL.attid = "";
        GLOBAL.attval = "";
        GLOBAL.attkey = "";
        this.setState({ attributeInputType: "Textbox" });
        this.setState({ selectedValueMultiselect: [] });
        this.setState({ dropdownOptions: [] });
				this.setState({ selectedValueTextbox: "" });
				this.setState({ selectedValueDropdown: "" });
				this.setState({ selectedValueDatetime: "" });
        this.props.loadCIAtt(this.props.ciId);
        this.props.loadModelName(this.props.ciId);
      });
  }

  submitatt() {
    let ref = this;
		if(this.state.attributeInputType == "DateTime" && this.state.showErrorText == this.props.translator["Please Provide Valid Date Format."])
		return false;

		if(GLOBAL.attval.trim() == "") {
			this.setState({ showError: true, showErrorText: this.props.translator["Value cannot be blank"] });
			return false;
		} else {
			this.setState({ showError: false, showErrorText: "" });
		}

    // if (GLOBAL.attval == "") GLOBAL.attval = GLOBAL.editattval;

    // if (GLOBAL.attkey == "") GLOBAL.attkey = GLOBAL.editattkey;

    axios
      .patch(GLOBAL.patchCiArrtibuteUrl + "/" + this.state.ciAttMapId, {
        value: GLOBAL.attval,
        key: GLOBAL.attkey,
      })
      .then((response) => {
        GLOBAL.attid = "";
        GLOBAL.attval = "";
        GLOBAL.attkey = "";
        ref.setState({ formToggle: false });
        ref.setState({ formeditToggle: false });
        this.setState({ formaddToggle: true });
        this.setState({ attributeInputType: "Textbox" });
        this.setState({ selectedValueMultiselect: [] });
        this.setState({ dropdownOptions: [] });
				this.setState({ selectedValueTextbox: "" });
				this.setState({ selectedValueDropdown: "" });
				this.setState({ selectedValueDatetime: "" });
        this.props.loadCIAtt(this.props.ciId);
        this.props.loadModelName(this.props.ciId);
      });
  }

  attdelete(ciAttMapId) {
    let ref = this;

    axios
      .patch(GLOBAL.deleteCiArrtibuteUrl + "/" + ciAttMapId, {
        isDeleted: "0",
      })
      .then((response) => {
        ref.setState({ formToggle: false });
        ref.setState({ formeditToggle: false });
        this.props.loadCIAtt(this.props.ciId);
        this.props.loadModelName(this.props.ciId);
      });
  }

  setAttId(e) {
    GLOBAL.attid = e.target.value;
		this.setState({ showError: false, showErrorText: "" });
		GLOBAL.attval = "";
		GLOBAL.attkey = "";
    let result = this.props.ciAtt.filter(
      (element) => element.ATTRIBUTE_ID == e.target.value
    );
		this.setState({ selectedValueTextbox: "", selectedValueDropdown: "", selectedValueDatetime: "", selectedValueMultiselect: [] });
    if (e.target.value != "") {
      this.setState({ attributeInputType: result[0].INPUT_TYPE });
      if (result[0].INPUT_TYPE == "Dropdown") {
        let apiInfo = result[0].API_INFORMATION;
        apiInfo.language = languageSelected
        axios
          .get(GLOBAL.getCiAttributeValues + "/" + this.props.COMPANY_ID, {
            headers: { query: JSON.stringify(apiInfo) },
          })
          .then((response) => {
            this.setState({ dropdownOptions: response.data });
          });
      } else if (result[0].INPUT_TYPE == "Multiselect List") {
        let apiInfo = result[0].API_INFORMATION;
        axios
          .get(GLOBAL.getCiAttributeValues + "/" + this.props.COMPANY_ID, {
            headers: { query: JSON.stringify(apiInfo) },
          })
          .then((response) => {
            let options = [];
            response.data.forEach((element) => {
              let obj = {};
              obj.value = element.field1Value;
              obj.label = element.field1Key;
              options.push(obj);
            });
            this.setState({ dropdownOptions: options });
          });
      }
    }
  }

  setAttVal(e, type) {
		this.setState({ showError: false, showErrorText: "" });
    if (type == "DateTime") {
      if (moment(e).format(dateformat.slice(0, 10).toUpperCase() + " HH:mm:ss") != "Invalid date") {
        GLOBAL.attval = moment(e, dateformat.slice(0, 10).toUpperCase() + " HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");
				this.setState({ showError: false, showErrorText: "" });
				this.setState({ selectedValueDatetime: moment(e) });
      } else {
				GLOBAL.attval = "";
				this.setState({ selectedValueDatetime: e });
				this.setState({ showError: true, showErrorText: this.props.translator["Please Provide Valid Date Format."] });
			}
    } else if (type == "Multiselect List") {
      let temp1 = "";
      let temp2 = "";
      e.forEach((item, i) => {
        temp1 = temp1 + item.value;
        temp2 = temp2 + item.label;
        if (i != e.length - 1) {
          temp1 = temp1 + ";";
          temp2 = temp2 + ";";
        }
      });
      this.setState({ selectedValueMultiselect: e });
      GLOBAL.attkey = temp1;
      GLOBAL.attval = temp2;
    } else if (type == "Dropdown") {
      GLOBAL.attkey = e.target.value;
			this.setState({ selectedValueDropdown: e.target.value });
      if (e.target.value == "") GLOBAL.attval = "";
      else GLOBAL.attval = e.target[e.target.selectedIndex].text;
    } else {
      GLOBAL.attval = e.target.value;
			this.setState({ selectedValueTextbox: e.target.value });
    }
  }

  renderDropdownList(dropdownOptions) {
    if (!dropdownOptions) {
      return <option></option>;
    }
    return dropdownOptions.map((option) => {
      return <option value={option.field1Value}>{option.field1Key}</option>;
    });
  }

  render() {
    let successMessage = "";
    let failureMessage = "";
    let msg = "";
    let header = "";
    for (let i = 0; i <= this.props.ciAtt.length - 1; i++) {
      if (
        this.props.ciAtt[i].VALUE != null &&
        this.props.ciAtt[i].VALUE != ""
      ) {
        successMessage = this.props.translator["Data AVailable"];
      } else {
        failureMessage = this.props.translator["Data not available"];
      }
    }
    if (successMessage != "") {
      msg = "";
      header = (
        <thead>
          <tr>
            <th>{this.props.translator["Name"]}</th>
            <th>{this.props.translator["Value"]}</th>
            {this.props.isRoleDisabled 
							? (
                <th className="text-c">{this.props.translator["Action"]}</th>
							) : null}
          </tr>
        </thead>
      );
    } else {
      msg = this.props.translator["Data not available"];
      header = null;
    }

    let attributeValueInputType;
    if (this.state.formToggle == true) {
      if (this.state.attributeInputType == "Textbox") {
        attributeValueInputType = (
          <Form.Control
            type="text"
            value={this.state.selectedValueTextbox}
            onChange={(event) => {
              this.setAttVal(event, "Textbox");
            }}
          />
        );
      } else if (this.state.attributeInputType == "Dropdown") {
        attributeValueInputType = (
          <Form.Select
            componentClass="select"
            value={this.state.selectedValueDropdown}
            onChange={(event) => {
              this.setAttVal(event, "Dropdown");
            }}
          >
            <option value="">select</option>
            {this.renderDropdownList(this.state.dropdownOptions)}
          </Form.Select>
        );
      } else if (this.state.attributeInputType == "DateTime") {
        attributeValueInputType = (
          <Datetime
            value={moment(this.state.selectedValueDatetime)}
            onChange={(event) => {
              this.setAttVal(event, "DateTime");
            }}
            locale="en"
            dateFormat={dateformat.slice(0, 10).toUpperCase()}
            timeFormat={true}
          />
        );
      } else if (this.state.attributeInputType == "Multiselect List") {
        attributeValueInputType = (
          <div>
            <div className="colSearDv multiSelDv">
              <ReactMultiSelectCheckboxes
                placeholderButtonLabel={this.props.translator["Select"]}
                value={this.state.selectedValueMultiselect}
                rightAligned={false}
                options={this.state.dropdownOptions}
                onChange={(event) => {
                  this.setAttVal(event, "Multiselect List");
                }}
              />
            </div>
          </div>
        );
      }
    }

    let attributeValueInputTypeEdit;
    if (this.state.formeditToggle == true) {
      if (this.state.attributeInputType == "Textbox") {
        attributeValueInputTypeEdit = (
          <Form.Control
            type="text"
            defaultValue={this.state.attval}
            onChange={(event) => {
              this.setAttVal(event, "Textbox");
            }}
          />
        );
      } else if (this.state.attributeInputType == "Dropdown") {
        attributeValueInputTypeEdit = (
          <Form.Select
            // defaultValue={this.state.attkey}
            value={this.state.selectedValueDropdown}
            componentClass="select"
            onChange={(event) => {
              this.setAttVal(event, "Dropdown");
            }}
          >
            <option value="">select</option>
            {this.renderDropdownList(this.state.dropdownOptions)}
          </Form.Select>
        );
      } else if (this.state.attributeInputType == "DateTime") {
          attributeValueInputTypeEdit = (
          <Datetime
            defaultValue={this.state.attval}
            onChange={(event) => {
              this.setAttVal(event, "DateTime");
            }}
            locale="en"
            dateFormat={dateformat.slice(0, 10).toUpperCase()}
            timeFormat={true}
            value={moment(this.state.attval)}
           
          />
        );
      } else if (this.state.attributeInputType == "Multiselect List") {
        attributeValueInputTypeEdit = (
          <div>
            <div className="colSearDv multiSelDv">
              <ReactMultiSelectCheckboxes
                placeholderButtonLabel={this.props.translator["Select"]}
                value={this.state.selectedValueMultiselect}
                rightAligned={false}
                options={this.state.dropdownOptions}
                onChange={(event) => {
                  this.setAttVal(event, "Multiselect List");
                }}
              />
            </div>
          </div>
        );
      }
    }

    return (
      <div>
        <Row>
          <Col xs={12}>
            <div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
              <ul>
                <li>
                  {this.props.ciStatusName == "In stock" ||
                  this.props.ciStatusName == "Decommissioned" ||
                  this.props.ciStatusName == "Disposed" ||
                  this.props.ciStatusName == "Deleted" ? (
                    ""
                  ) : this.props.isRoleDisabled ? (
                    <Button
                      onClick={() => {
                        this.formToggleFun(true);
                      }}
                      bsPrefix=" "
                      bsClass=""
                      bsStyle=""
                      title={this.props.translator["Add"]}
                    >
                      <HiPlus />
                    </Button>
                  ) : null}
                </li>
                <li className="d-md-none">
                  <button
                      type='button'
                      title="Minimize the right panel"
                      bsClass=""
                      bsStyle=""
                      className="closerightPanelBtn"
                      onClick={() => {
                      this.props.rightEditPanel(false);
                      }}
                  >
                      <IoClose/>
                  </button>
                </li>
              </ul>
            </div>
            <div className="rPageHeading">
              <div className="offNam margin-t-5 margin-b-5">
                {this.props.translator["Specify Attribute Values"]}
              </div>
            </div>
          </Col>
        </Row>

        <div className="rBoxGap">
          {this.state.formaddToggle ? (
            <div>
              <Table responsive striped bordered condensed hover>
                {header}
                <tbody>{this.renderAtt(this.props.ciAtt)}</tbody>
              </Table>
            </div>
          ) : null}

          {this.state.formToggle ? (
            <div>
              {this.state.showError ? <Alert style={{ "marginBottom": "15px", "fontSize": "13px", "padding": "8px 30px 8px 8px" }} variant="danger"><h4>{this.state.showErrorText}</h4></Alert> : ""}
              <Table className="simTable">
                <tbody>
                  <tr>
                    <td>{this.props.translator["Name"]}</td>
                    <td>
                      <Form.Select
                        componentClass="select"
                        onChange={(event) => {
                          this.setAttId(event);
                        }}
                      >
                        <option value="">select</option>
                        {this.renderAttList(this.props.ciAtt)}
                      </Form.Select>
                    </td>
                  </tr>
                  <tr>
                    <td>{this.props.translator["Value"]}</td>
                    <td className="rdtDiv">{attributeValueInputType}</td>
                  </tr>
                  <tr>
                  <td colSpan={2}>
                      <ButtonToolbar>
                        <Button
                          onClick={() => {
                            this.saveatt();
                          }}
                          bsSize="xsmall"
                          className="rgSidrkBtn smallBtn"
                        >
                          {this.props.translator["Save"]}
                        </Button>
                        <Button
                          onClick={() => {
                            this.formCancelFun(true);
                          }}
                          bsSize="xsmall"
                          className="rgSiLigBtn smallBtn"
                        >
                          {this.props.translator["Cancel"]}
                        </Button>
                      </ButtonToolbar>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          ) : (
            <div>{msg}</div>
          )}

          {this.state.formeditToggle ? (
            <div>
              {this.state.showError ? <Alert style={{ "marginBottom": "15px", "fontSize": "13px", "padding": "8px 30px 8px 8px" }} variant="danger"><h4>{this.state.showErrorText}</h4></Alert> : ""}
              <div className="f-size-14 margin-b-5 border-b">
                {this.props.translator["Edit Attribute"]}
              </div>
              <Table className="simTable">
                <tbody>
                  <tr>
                    <td>{this.props.translator["Name"]}</td>
                    <td>
                      <Form.Control
                        type="text"
                        value={this.state.attname}
                        readOnly="true"
                      ></Form.Control>
                    </td>
                  </tr>
                  <tr>
                    <td>{this.props.translator["Value"]}</td>
                    <td className="rdtDiv">{attributeValueInputTypeEdit}</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <ButtonToolbar>
                        <Button
                          onClick={() => {
                            this.submitatt();
                          }}
                          bsSize="xsmall"
                          className="rgSidrkBtn smallBtn"
                        >
                          {this.props.translator["Update"]}
                        </Button>
                        <Button
                          onClick={() => {
                            this.formCancelFun(true);
                          }}
                          bsSize="xsmall"
                          className="rgSiLigBtn smallBtn"
                        >
                          {this.props.translator["Cancel"]}
                        </Button>
                      </ButtonToolbar>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export function mapStateToProps({ ciAtt }) {
  return {
    ciAtt,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loadCIAtt, loadModelName }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CmdbAttribute);
