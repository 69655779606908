
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState, useRef, useContext } from "react";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector, connect } from "react-redux";

import { Row, Col, Button, ListGroup, Nav } from "react-bootstrap";
import { IoSaveOutline, IoClose } from "react-icons/io5";
import { BiDotsVertical } from "react-icons/bi";
import { ImSpinner6 } from "react-icons/im";
import StatausActions from "./statusAction";
import {
  useGetStatusTimelineQuery,
  useGetActionQuery,
} from "../../../reducers/governance/meeting/meeting";

import timeline from "../../../images/timeline-icon.png";
import { LuActivitySquare, LuCalendarClock, LuLaptop2 } from "react-icons/lu";
import axios from "axios";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';

const Header = ({
  submitForm,
  formikref,
  toggle,
  activeTabs,
  isAddMode,
  isSubmitting,
  setShowTimeLine,
  showTimeLine,
  meetingDefData,
  handleOnSelectAction,
  errorMessage,
  dirty,
  ...props
}) => {
  const menuRef = useRef(null);
  const dispatch = useDispatch();
  const params = useParams();

  const navigate = useNavigate();
  const tr = useSelector((state) => state.spcmReducer.tr);

  const companyId = meetingDefData?.companyId;
  const definationId = meetingDefData?.meetingDefinationId;

  const [isOpen, setIsOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const saveObjRef = useRef(null);

	useEffect(() => {
		context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
    context.newTabPrevent();
	}, []);

  const submit = async (values) => {};
  const statusHamburgerToggle = () => {
    fetechActions();
    setIsOpen(!isOpen);
  };

  const api = axios.create({
    headers: { Pragma: "no-cache", "Cache-control": "no-store" },
  });

  const {
    data: timelines,
    error: timelineError,
    isLoading: timelineLoading,
  } = useGetStatusTimelineQuery(
    {
      queryString: `?module=meeting&definationId=${definationId}&tenantId=${companyId}`,
    },
    {
      skip: !definationId && !companyId,
    }
  );

  const currentStatus = timelines?.data?.currentStatus?.["to"];

  const {
    data: actionsData,
    error: actionsError,
    refetch: fetechActions,
    isLoading: actionsLoading,
  } = useGetActionQuery(
    {
      queryString: `?currentStatus=${currentStatus}&governanceType=meeting&tenantId=${companyId}&id=${definationId}`,
    },
    {
      skip: !(definationId && companyId && currentStatus),
    }
  );

  const handleSubmit = () => {
    formikref.current?.submitForm();
  };

  useEffect(() => {}, [formikref?.current]);

  useEffect(() => {
    getUserInfo();
  }, []);


  const getUserInfo = () => {
    try {
      let str = {};
      str.user_id = "";
      str = JSON.stringify(str);
      api
        .get("/api/userInfo", { headers: { query: str } })
        .then((res) => {
          setUserId(res?.data?.user_id);
        })
        .catch((err) => {
          console.error(err);
          setUserId("");
        });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Row className="margin-b-15">
      <Col md={3} xs={12}>
        <h1 bsClass="" className="sPageHeading1">
          Appointment
          {!isAddMode && (
            <Button
              title="Open Timeline"
              bsPrefix=" "
              className="barDropMenu tinelineicon"
              bsStyle="link"
              onClick={() => setShowTimeLine(!showTimeLine)}
            >
              <LuCalendarClock />
              {/* <img className="img-responsive" src={timeline} /> */}
            </Button>
          )}
        </h1>
      </Col>
      <Col md={9} xs={12}>
          <div className="topRgtNavIcons stusGap">
          {!isAddMode && (
        <div className="toprigLnk">
        <div className="thrlnebtn" ref={menuRef}>
          <Button
            title="Statuses"
            className="btnDropMenu btn btn-default"
            bsPrefix=" "
            onClick={
              (currentStatus !== "Cancelled" || currentStatus !== "Completed") && meetingDefData.primaryChairPerson == userId 
                ? statusHamburgerToggle
                : undefined
            }
          >
            {timelines?.data?.currentStatus?.["to"]}
            {!(
              currentStatus === "Cancelled" || currentStatus === "Completed"
            ) && <i className="fa fa-angle-down" aria-hidden="true" />}
          </Button>

          <StatausActions
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            mref={menuRef}
            handleOnSelectAction={handleOnSelectAction}
            componentType="MeetingForum"
            meetingDefData={meetingDefData}
            data={timelines}
            actionsData={actionsData}
            icalURL={actionsData?.downloadIcsUrl || ""}
          />
        </div>
      </div>
      )}
        <div style={{position:"relative",top:"-2px"}} className="paHedFilter">
          {!isAddMode && (
            <Nav>
              <Button
                bsPrefix=" "
                id="creTag"
                role="button"
                className={
                  activeTabs === "1" ? "myBt active" : "myBt"
                }
                title={"Activity Details"}
                onClick={() => {
                  toggle("1");
                }}
              >
                <LuActivitySquare/>
              </Button>

              {meetingDefData?.status !== "Draft" && (
                <Button
                  bsPrefix=" "
                  id="creTag"
                  role="button"
                  title={"Occurrence"}
                  className={
                    activeTabs === "3" ? "myBt active" : "myBt"
                  }
                  onClick={() => {
                    toggle("3");
                  }}
                >
                  <LuLaptop2 />
                </Button>
              )}
            </Nav>
          )}

          <Link
            // disabled={formikref?.current?.isSubmitting || !dirty}
            disabled={
              currentStatus === "Cancelled" || currentStatus === "Completed"
            }
            className={
              currentStatus === "Cancelled" || currentStatus === "Completed"
                ? "disabled myBt fillBtn ctrlKeyPrevent"
                : "myBt fillBtn ctrlKeyPrevent"
            }
            bsPrefix=" "
            to="javascript:void(0)"
            bsStyle="primary"
            title={isAddMode ? "Update " : tr["Save"]}
            onClick={() => handleSubmit()}
            ref={saveObjRef}
          >
            {formikref?.current?.isSubmitting ? (
              <ImSpinner6 className="icn-spinner" />
            ) : (
              <IoSaveOutline />
            )}
          </Link>
          <Link
            bsPrefix=" "
            to="/quickViewBreakFix"
            className="myBt cancel fillBtn ctrlKeyPrevent"
            title={tr["Cancel"]}
            ref={closeButton}
          >
            <IoClose />
          </Link>
        </div>
        </div>
      </Col>
    </Row>
  );
};

export default Header;
