
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { useEditor } from "@grapesjs/react";
import { Button } from '@progress/kendo-react-buttons';
import { MdClose, MdAdd, MdArrowCircleUp, MdArrowCircleDown, MdDelete } from "react-icons/md";
import { ColorPicker, RadioGroup, Slider, TextBox, InputSuffix } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";

export default function StylePropertyField({ prop, ...rest }) {
    const editor = useEditor();
    
    const handleChange = (value) => {
        prop.upValue(value);
    }

    const onChange = (ev) => {
        const newValue = type==='select' ? ev.target.value.value : ev.target.value;
        handleChange(newValue);
    }

    const openAssets = () => {
        const { Assets } = editor;
        Assets.open({
            select: (asset, complete) => {
                prop.upValue(asset.getSrc(), { partial: !complete });
                complete && Assets.close();
            },
            types: ['image'],
            accept: 'image/*',
        });
    }

    const type = prop.getType();
    const defValue = prop.getDefaultValue();
    const canClear = prop.canClear();
    const hasValue = prop.hasValue();
    const value = prop.getValue();
    const valueString = hasValue ? value : '';
    const valueWithDef = hasValue ? value : defValue;
    const units = type==='number'?prop.getUnits():[];
    const unit = type==='number'?prop.getUnit():'';

    const [ tempValue, setTempValue] = useState(value)

    let inputToRender = (
        <TextBox placeholder={defValue} value={valueString} onChange={onChange} size="small"></TextBox>
    )

    const handleEnter = (e) => {
        if(e.key === "Enter"){
            handleBlur(e);
            e.stopPropagation();
        }
    }

    const handleBlur = (e) => {
        onChange(e);
        setTempValue(prop.getValue() ?? '');
    }

    const updateUnit = (e) => {
        prop.upUnit(e.target.value);
    }

    const changeInputValue = (e) => {
        setTempValue(e.target.value);
    }

    useEffect(() => {
        setTempValue(valueString);
    }, [valueString])

    switch (type) {
        case 'number': {
            inputToRender = (
                <TextBox
                    placeholder={defValue}
                    value={tempValue}
                    size="small"
                    onChange={changeInputValue}
                    onBlur={handleBlur}
                    onKeyDown={handleEnter}
                    suffix={
                        () => (
                            <InputSuffix>
                                <DropDownList onChange={updateUnit} value={unit} data={units}>                                    
                                </DropDownList>
                            </InputSuffix>
                        )
                    }
                ></TextBox>
            )
        } break;
        case 'radio': {
            inputToRender = (
                <RadioGroup
                    data={prop.getOptions().map(option => {
                        return { label: prop.getOptionLabel(option), value: prop.getOptionId(option) }
                    })}
                    value={value}
                    onChange={e => { handleChange(e.value) }}
                    layout="horizontal"
                ></RadioGroup>
            )
        } break;
        case 'select': {
            const options = prop.getOptions().map(option => {
                return { label: prop.getOptionLabel(option), value: prop.getOptionId(option) }
            });
            const selectedValue = options.find(option => { return value === prop.getOptionId(option)});
            inputToRender = (
                <DropDownList
                    value={selectedValue}
                    onChange={onChange}
                    data={options}
                    dataItemKey="value"
                    textField="label"
                ></DropDownList>
            )
        } break;
        case 'color': {
            inputToRender = (
                <ColorPicker view="gradient" value={valueString} onChange={ev => handleChange(ev.value)}></ColorPicker>
            )
        } break;
        case 'slider': {
            inputToRender = (
                <Slider
                    value={parseFloat(value)}
                    min={prop.getMin()}
                    max={prop.getMax()}
                    onChange={onChange}
                ></Slider>
            )
        } break;
        case 'file': {
            inputToRender = (
                <div className="d-flex flex-column align-middle gap-3">
                    {
                        value && value !== defValue && (
                            <div
                                className="rounded"
                                style={{ backgroundImage: `url("${value}")` }}
                                onClick={() => handleChange('')}
                            ></div>
                        )
                    }
                    <Button size="small" fillMode="flat" onClick={openAssets}>
                        Select Image
                    </Button>
                </div>
            )
        } break;
        case 'composite': {
            inputToRender = (
                <div className="d-flex flex-wrap p-2">
                    {prop.getProperties().map(prop => (
                        <StylePropertyField key={prop.getId()} prop={prop} />
                    ))}
                </div>
            )
        } break;
        case 'stack': {
            const layers = prop.getLayers();
            const isTextShadow = prop.getName() === 'text-shadow';
            inputToRender = (
                <div className="d-flex flex-column p-2 gap-2">
                    {
                        layers.map(layer => (
                            <div key={layer.getId()}>
                                <div className="d-flex gap-1 py-1 align-middle">
                                    <Button size="small" fillMode="flat" onClick={() => layer.move(layer.getIndex() - 1)}>
                                        <MdArrowCircleUp></MdArrowCircleUp>
                                    </Button>
                                    <Button size="small" fillMode="flat" onClick={() => layer.move(layer.getIndex() + 1)}>
                                        <MdArrowCircleDown></MdArrowCircleDown>
                                    </Button>
                                    <Button className="flex-grow-1" onClick={() => layer.select()}>
                                        {layer.getLabel()}
                                    </Button>
                                    <div
                                        style={layer.getStylePreview({ number: { min: -3, max: 3 }, camelCase: true })}
                                    >
                                        {isTextShadow && 'T'}
                                    </div>
                                    <Button size="small" fillMode="flat" onClick={() => layer.remove()}>
                                        <MdDelete></MdDelete>
                                    </Button>
                                </div>
                                {
                                    layer.isSelected() && (
                                        <div className="p-2 d-flex flex-wrap">
                                            {prop.getProperties().map(prop => (
                                                <StylePropertyField key={prop.getId()} prop={prop}></StylePropertyField>
                                            ))}
                                        </div>
                                    )
                                }
                            </div>
                        ))
                    }
                </div>
            )
        } break;
    }

    return (
        <div {...rest} className={prop.isFull() ? 'col-12' : 'col-6'}>
            <div className="d-flex mb-2 align-middle">
                <div className="d-flex text-uppercase">{prop.getLabel()}</div>
                {
                    canClear && (
                        <Button className="ms-auto" size="small" fillMode="flat" onClick={() => prop.clear()}>
                            <MdClose></MdClose>
                        </Button>
                    )}
                {
                    type === 'stack' && (
                        <Button size="small" fillMode="flat" onClick={() => (prop).addLayer({}, { at: 0 })}>
                            <MdAdd></MdAdd>
                        </Button>
                    )}
            </div>
            {inputToRender}
        </div>
    )
}