
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import NavItem from 'react-bootstrap/NavItem';
import { LinkContainer } from 'react-router-bootstrap';

class LeftSidebarMenulist extends React.Component {
   render() {
      return (
		<div className="leftNav">
			<Nav bsClass="" className="leftMenu">
				<NavItem href="#" eventKey={1} title={this.props.translator['Details']}><span className="bgBtn9 lSidebar brkf1"></span></NavItem>
			</Nav>
		</div>

      );
   }
}


export default LeftSidebarMenulist;

