
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { getTag, replaceVariables } from "../../../studio/utils";
import useDataQueryWithApi from "../../../hooks/useDataQueryWithApi";
import ListLoader from "../../../../common/loaders/ListLoader";
import LinkComponent from "../LinkComponent";

const RepeatativeBlock = (props) => {
  const [data, setData] = useState([]);
  const { item, components, defaultValues } = props;
  const [responseData, optionsLoading, setDataSourceId, dataError, setRefreshed] = useDataQueryWithApi({...defaultValues},item,{...defaultValues});

  useEffect(() => {
   if (item?.data_source) {
     setDataSourceId(item?.data_source.id);
   }
  }, []);

  useEffect(() => {
    if (responseData) {
      setData(responseData);
    }
  }, [responseData]);

  useEffect(() => {
    if(props?.isDataRefreshed) {
      setRefreshed(true);
      props?.setIsDataRefreshed(false);
    }
  }, [props?.isDataRefreshed])
 
  const renderComponent = (component, field, index) => {
    const { type, classes, components, text } = component;
    let className;
    if (Array.isArray(classes)) {
      className = classes.join(" ");
    } else {
      className = classes;
    }
    let attributes = {};
    if (component.attributes) {
      attributes = { ...component.attributes };
    }
    if (component.classes) {
      attributes.className = component.classes
        .map((c) => (c.name ? c.name : c))
        .join(" ");
    }
    if (component?.name && component.name == "Row") {
      attributes.className = attributes.className + "";
    }
    if (component?.name && component.name == "Cell") {
      attributes.className = attributes.className + "";
    }
    const children = components
      ? components.map((res) => renderComponent(res, field, index))
      : null;
    switch (type) {
      case "button":
        {
          return (
            <button className={className} {...attributes} onClick={() => {}}>
              {component?.iconClass ? (
                <i className={component?.iconClass}></i>
              ) : (
                replaceVariables(text, field)
              )}
            </button>
          );
        }
      case "link": {
        return (
          <LinkComponent
            item={component}
            languageData={props?.languageData}
            renderComponent={props?.renderComponent}
            buttonClicked={props?.buttonClicked}
            attributes={attributes}
            field={field}
          />
        );
      }
      case "textnode":
        return replaceVariables(text || component?.content, field);
      default:
        return React.createElement(
          component.type ? getTag(component) : "div",
          { ...attributes },
          children
        );
    }
  };

  const renderByComponent = (json) => {
    if (data.length > 0) {
      return data.map((field, index) =>
        json.map((res) => renderComponent(res, field, index))
      );
    }
  };

  if(optionsLoading){
    return (
      <ListLoader />
    );
  }else{
    return !data || data.length == 0 ? (
      <div className="text-c padding-t-10 padding-b-10 border">No Data Found</div>
    ) : (
      data && data.length > 0 && renderByComponent(components)
    );
  }
};


export default RepeatativeBlock;