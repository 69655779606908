
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
//const getCategoryList = 'https://xsmapi.mybluemix.net/getOfferingsForListRestifySelfService/?categoryId=';
export function loadCategoryListSuccess(categoryList){
	return {
			type: 'LOAD_CATEGORY_LIST_SUCCESS',
			categoryList:categoryList,
			isFetching:false,
			isError:false
	};
}

export function loadCategoryListFailure(categoryList){
	return {
			type: 'LOAD_CATEGORY_LIST_FAILURE',
			categoryList:categoryList,
			isFetching:false,
			isError:false
	};
}

export function currentOfferingDescriptionSuccess(selectedOffering){
  return{
    type:'SELECTED_OFFERING',
    selectedOffering
  };
}

export function loadComponentPackageSuccess(componentPackage){
  return{
      type: 'COMPONENT_PACKAGE',
      componentPackage
  };
}
export function loadCategoryList(url,start,end){
	let str={};
    if(start!=""){
		str.start=start;
	}
	if(end!=""){
		str.end=end;
	}
    str = JSON.stringify(str);
	return(dispatch) =>{
		dispatch({
			type: 'LOAD_CATEGORY_LIST_SUCCESS',
			categoryList:[],
			isFetching:true,
			isError:false
		});
		dispatch({type:'SELECTED_OFFERING',selectedOffering:null});
		axios.get(url,{headers:{'query':str}})
			.then((categoryList)=>{
				if(!categoryList){
					dispatch({
						type: 'LOAD_CATEGORY_LIST_SUCCESS',
						categoryList:[],
						isFetching:false,
						isError:true
					});
					throw Error(categoryList.statusText);
				}
				return categoryList;
			})
			.then((categoryList) =>{
				dispatch(loadCategoryListSuccess(categoryList));
			})
			.catch((err) => {
				dispatch(loadCategoryListFailure(err.response.data[0].message));
			});
	};
}

export function currentOfferingDescription(selectedOffering){
    return (dispatch) =>{
      dispatch(currentOfferingDescriptionSuccess(selectedOffering ));
    };
}
