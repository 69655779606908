
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, {Component, PropTypes } from 'react';
import Button from 'react-bootstrap/Button';
import AutoSuggest from 'react-autosuggest';
import { GLOBAL } from "_Globals";
import axios from 'axios';
let selectOptions = [];

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

const getSuggestions = (value,options) =>{
  
   const inputValue = value.trim().toLowerCase();
   const inputLength = inputValue.length;
    if(inputLength >2){
      selectOptions =options;
    }
   return inputLength < 3 ? []: selectOptions.filter(option => option.label.toLowerCase().indexOf(inputValue) > -1);
};

  const getSuggestionValue = suggestion => suggestion.label.indexOf == -1 ? '' : suggestion.label.substring(0,suggestion.label.indexOf('|'));
  //alert("getSuggestionValue"+suggestion.value)

  function renderSuggestion(suggestion) {
    //alert("val::"+suggestion.value+"label"+suggestion.label)
    let suggestionlabel =[];
    suggestionlabel= suggestion.label.split("|");
      //  console.log("val::"+suggestion.value+"label::"+suggestionlabel[3])
        //alert("suggestion.value"+suggestion.value)
    console.log("suggestion768",suggestion);
    let ciNameKey="";
    let ciNameStr="";
    ciNameStr = suggestion.ciName != null && suggestion.ciName != "" ? suggestion.ciName : "";
    ciNameKey = ciNameStr != "" ? ciNameStr.replace(/(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/ig, '').trim() : "";
    ciNameKey = ciNameKey != "" ? ciNameKey.replace(/\s+/g, ' ') : "";

        return (
          <Button bsPrefix=" " className='suggBtn' onClick = {()=>{
            GLOBAL.ciIdEdit=suggestion.ciId;
            GLOBAL.ciNameEdit=suggestion.ciName;
            GLOBAL.ciNameCopy=suggestion.ciName;
            //GLOBAL.ciClass=suggestion.ciId;
            //GLOBAL.ciCompany=suggestion.ciCompany;
            GLOBAL.ciIdNumberEdit=suggestion.ciIdNumber;
            GLOBAL.ciClass=suggestion.ciClass;
            GLOBAL.ciClassNameSelected=suggestion.ciClass;
            GLOBAL.categoryNameEdit= suggestion.categoryName;
            GLOBAL.environmentNameEdit= suggestion.environmentName;
            GLOBAL.ciConsumerCompanyEdit= suggestion.ciConsumerCompany;
            GLOBAL.ciSupportCompanyEdit= suggestion.ciSupportCompany;
            GLOBAL.ciClassIdEdit= suggestion.ciClassId;
            GLOBAL.ciLocationIdEdit= suggestion.ciLocationId;
            GLOBAL.ciLocationNameEdit= suggestion.ciLocationName;
            GLOBAL.businessCriticalityId = suggestion.businessCriticalityId===''||suggestion.businessCriticalityId===null?0:suggestion.businessCriticalityId;
            GLOBAL.businessCriticality = suggestion.businessCriticality;
            GLOBAL.serialNumberEdit= suggestion.serialNumber;
          }}>
            {/* {suggestion.label} */}
            {ciNameKey.slice(0,70)}{ciNameKey.length > 70 ? "..." : ''}<div>{"Serial Number"}{" - "}{suggestion.serialNumber=="" || suggestion.serialNumber==null ? "NA":  suggestion.serialNumber }</div><div>{suggestion.ciClass}{" - "}{suggestion.ciCompany}</div>
        </Button>
        );
    }



class RFReactSelect extends Component {
  constructor(props){
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.validateField=this.validateField.bind(this);
    this.onServiceNameSelected = this.onServiceNameSelected.bind(this);
    this.state = {
      value: '',
      suggestions: []
    };
    //this.validateField = this.validateField.bind(this);
  }
  onChange(event,{newValue}){
    
    this.setState({
      value: newValue,
    });
    if(newValue===''){
      if(typeof this.props.showImpactedCIInfoIcon!=='undefined'){
      this.props.showImpactedCIInfoIcon();
      }
    }
    if(typeof this.props.onType!=='undefined' && newValue!==''){
      if(newValue.localeCompare(this.state.value)){
        this.props.onType(false);
      }
    }
    if(newValue.length===0){
      this.props.isCiNameFun && this.props.isCiNameFun(true);
    }else{
      this.props.isCiNameFun && this.props.isCiNameFun(false);
    }
    
  }
  
  onSuggestionsFetchRequested ({value}){
    if(this.state.value.length===0 && typeof this.props.onType!=='undefined'){
      this.props.onType(false);
    }

    let ciNameDataDetails = [];
    let ciNameData=[];
    let ciNameObject={};
    if(value.length > 2) {
      api.get(GLOBAL.ciNameDetailsUrl+'?searchByList=STATUS,SUB_STATUS&multipleValueList=7|12&searchValue='+value+'&companyId='+this.props.companyId).then((response)=>{
        //alert("hi")
        ciNameData = response.data;
        for (var i = 0; i < ciNameData.length; i++) {
          ciNameObject = {ciId:'',label:'',ciName:'',ciClass:'',ciCompany:'',ciIdNumber:'',ciIdRelated:'',serialNumber:''};
          ciNameObject.ciId=ciNameData[i].CI_ID;
          ciNameObject.label=ciNameData[i].CI_NAME+"|"+ciNameData[i].CLASS_NAME+"|"+ciNameData[i].COMPANY_NAME;
          ciNameObject.ciName=ciNameData[i].CI_NAME
          ciNameObject.ciClass=ciNameData[i].CLASS_NAME
          ciNameObject.ciCompany=ciNameData[i].COMPANY_NAME
          ciNameObject.categoryName=ciNameData[i].CATEGORY_NAME;
          ciNameObject.environmentName=ciNameData[i].ENVIRONMENT_NAME;
          ciNameObject.serialNumber=ciNameData[i].SERIAL_NUMBER;
          ciNameObject.ciConsumerCompany=ciNameData[i].COMPANY_ID;
          ciNameObject.ciSupportCompany=ciNameData[i].SUPPORT_COMPANY_ID;
          ciNameObject.ciClassId=ciNameData[i].CLASS_ID;
          ciNameObject.ciLocationId=ciNameData[i].LOCATION_ID;
          ciNameObject.ciLocationName=ciNameData[i].LOCATION_NAME;
          ciNameObject.businessCriticalityId=ciNameData[i].METALLIC;
          ciNameObject.businessCriticality=ciNameData[i].METALLIC_NAME;
          //GLOBAL.consumerCompanybreakfix=impactedServiceData[i].companyName;
          ciNameDataDetails.push(ciNameObject);
        }
        ciNameDataDetails = Array.from(new Set(ciNameDataDetails));
        console.log("ciNameDataDetails");
        console.log(ciNameDataDetails);
        this.setState({
          suggestions: getSuggestions(value,ciNameDataDetails)
        });
      });
    }
    else {
      this.setState({
        suggestions: []
      });
      if(this.state.value=='') {
        GLOBAL.ciIdEdit='';
        GLOBAL.ciNameEdit='';
        GLOBAL.ciIdNumberEdit='';
        GLOBAL.categoryNameEdit= '';
        GLOBAL.environmentNameEdit= '';
        GLOBAL.serialNumberEdit= '';
        GLOBAL.ciConsumerCompanyEdit= '';
        GLOBAL.ciSupportCompanyEdit= '';
        GLOBAL.ciClassIdEdit='';
        GLOBAL.ciLocationIdEdit= '';
        GLOBAL.ciLocationNameEdit= '';
      }
    }
    // this.setState({
    //   suggestions: getSuggestions(value,this.props.options)
    // });
  }
  validateField() {
    if(typeof this.props.setErrorServiceColor != 'undefined'){
      if(GLOBAL.ciIdEdit===''){
        this.props.setErrorServiceColor('error');
      }else{
        this.props.setErrorServiceColor('');
      }
    }
  }
//   validateField(){
//     console.log("this.props.onCreateUsingSelect",this.props.onCreateUsingSelect,GLOBAL.ciId);
//     if(this.props.onCreateUsingSelect=="service")
//     {
            
//     }
//     else{
//     if(GLOBAL.ciId==""){
//         console.log("COLORLINE")
//         this.props.setErrorImpactedColor('error');
//     }else{
//       this.props.setErrorImpactedColor('');
//     }
//   }
// }
onServiceNameSelected(event, { suggestion }){
  console.log("Peopsssssssssssaaaaaaaaaaaaaaaaa",this.props);
  if(typeof this.props.onServiceSelect!=='undefined'){
     this.props.onServiceSelect();
  }
  if(typeof this.props.onCISelect!=='undefined'){
     this.props.onCISelect(suggestion);
  }

  if(this.props.onServiceSelection!=undefined)
     this.props.onServiceSelection('imapactedCI');
  // // if(GLOBAL.breakFixServiceID!=''){
  // //   GLOBAL.breakFixServiceID=='';
  // // }
  if(this.props.module=="breakfix edit"){
  this.props.onImpactedSelection(GLOBAL.ciIdEdit,GLOBAL.ciConsumerCompany);
  }
   
}
  onSuggestionsClearRequested (){
    this.setState({
      suggestions: []
    });
    if(this.state.value=='') {
      GLOBAL.ciIdEdit='';
      GLOBAL.ciNameEdit='';
      GLOBAL.ciIdNumberEdit='';
      GLOBAL.categoryNameEdit= '';
      GLOBAL.environmentNameEdit= '';
      GLOBAL.serialNumberEdit= '';
      GLOBAL.ciConsumerCompanyEdit= '';
      GLOBAL.ciSupportCompanyEdit= '';
      GLOBAL.ciClassIdEdit='';
      GLOBAL.ciLocationIdEdit= '';
      GLOBAL.ciLocationNameEdit= '';
      if(typeof this.props.checkforAMSattributes!=='undefined'){
        this.props.checkforAMSattributes('','');
      }
    }
  }

  // componentWillMount(){
  //   console.log('&&*******', this.props);
  //   try{
  //     const { input = {} } = this.props;
  //     this.setState({value: (input["value"] || "")});
  //   }catch(e){
  //     console.error(e);
  //   }
  // }

  componentWillReceiveProps(nextProps){
    try{
      // console.log('&&*******', nextProps, this.props);
      if(this.props.input && (this.props.input.value !== nextProps.input.value)){
        this.setState({value: (nextProps.input.value || "")});
      }
    }catch(e){
      console.error(e);
    }
  }



  render(){
    //alert(this.state.value);
    // console.log("Peopsssssssssss",this.props);
    const { value, suggestions } = this.state;
    let ciNamevalue="";
    if(GLOBAL.ciName !="" || GLOBAL.ciName != undefined){
      //alert("=="+GLOBAL.ciName)
      ciNamevalue=GLOBAL.ciName
    }else{
      ciNamevalue="";
    }
    
    // console.log('&&&&&&&', this.state, this.props);

    const inputProps = {
    placeholder: ciNamevalue != '' && ciNamevalue != undefined && ciNamevalue != 'null' ? ciNamevalue : this.props.placeHolder ? this.props.placeHolder : "Enter CI Name",
    className: 'form-control ' + (this.props.errorColor || ""),
    value,
    onChange: this.onChange,
    onBlur: this.validateField,
    disabled: this.props.disabled,
  };
    return (
    <AutoSuggest
        suggestions = {suggestions}
        onSuggestionsFetchRequested = {this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue = {getSuggestionValue}
        renderSuggestion = {renderSuggestion}
        inputProps = {inputProps}
        onSuggestionSelected={this.onServiceNameSelected}
    />
  );
}
}export default RFReactSelect;
