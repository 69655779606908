
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import GrapeJsEditor, { Canvas, ModalProvider } from "@grapesjs/react";
import grapesjs from "grapesjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RightSidebar from "./components/rightSidebar";
import Topbar from "./components/topbar";
import { gql, useQuery } from "@apollo/client";
import gjsBlocksBasic from "grapesjs-blocks-basic";
import gjsForms from "grapesjs-plugin-forms";
import * as blocks from "./blocks";
import ReactDOM from "react-dom";
import RuleIndex from "./components/rule";
import CustomScript from "./components/CustomScript";
import { useLocation, useParams } from "react-router";
import CustomModal from "./components/customModal";
import Breadcrumbs from '../../common/header/breadcrumbs';
import "./app.scss";
import 'grapesjs/dist/css/grapes.min.css';
import "@progress/kendo-theme-default/dist/all.css";
import GrapesjsTabs from "./grapesjs-tabs.min.js";
import gjsNavbar from 'grapesjs-navbar';
import pluginTooltip from 'grapesjs-tooltip';
// import gjsPresetWebpage from "grapesjs-preset-webpage";
import customCodePlugin from 'grapesjs-custom-code';
import RightPanelIcons from "./components/rightPanelIconSet";
import axios from "axios";
import { GetDataQueries } from "./connectors/graphQl.js";
import ListLoader from "../../common/loaders/ListLoader.js";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import Swal from "sweetalert2";
import { NotificationGroup, Notification } from "@progress/kendo-react-notification"
import { Fade } from "@progress/kendo-react-animation";
import { GET_ASSET_URLS } from "../graphQl/GraphqlQueries.js";
import { GET_FORMS } from "../graphQl/GraphqlQueries.js";
import { getClassesAndsIds } from "./utils.js";
import { filterFromArray, getFilteredFields } from "../../common/helper.js";

const getForm = gql`
  query GetFormDetail($id: String!) {
    getForm(id: $id) {
      _id
      name
      formObjects
      companyName
      companyId
      ruleData
      pageType
      customScript
      createdBy
      updatedBy
      publishedPage{
        status
      }

    }
  }
`;

const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

function Studio({ onSave }) {
  const params = useParams();
  const windowSize = useSelector((state) => state.windowSize.width);
  const [editor, setEditor] = useState(null);
  const [isLeftPanelShow, setLeftPanelShow] = useState(false);
  const [isRightPanelShow, setRightPanelShow] = useState(false);
  const [isPanelShow, setPanelShow] = useState(3);
  const [companyList, setCompanyList] = useState([]);
  const [pageDetail, setPageDetail] = useState({});
  const [editorProps, setEditorProps] = useState();
  const [uploaded, setUploaded] = useState(false);
  const [companyId, setCompanyId] = useState(null);
  const [pageContainers, setPageContainers] = useState([]);
  const { state: navigationProps } = useLocation();

  useEffect(() => {
    if (windowSize < 767) {
      if (isRightPanelShow) {
        setLeftPanelShow(true);
      }else{
        setLeftPanelShow(false);
      }
    }
  }, [isRightPanelShow,windowSize]);
  

  const pageChanged = () => {
    if (localStorage.getItem("gjsProject")) {
      const data = JSON.parse(localStorage.getItem("gjsProject"));
      if (typeof data == "object" && Object.keys(data).length > 0) {
        const filteredFields = getFilteredFields(data);
        const containers = filterFromArray(
          filteredFields,
          "type",
          "pageContainer"
        );
        setPageContainers(containers);
      }
    }
  };

  useEffect(() => {
    if(pageContainers.length>0) updateTrait();
  }, [pageContainers]);
  

  const {
    loading: formsLoading,
    error: formError,
    data: getForms,
    refetch:refetchForms,
  } = useQuery(GET_FORMS, {
    variables: {
      companyId: pageDetail ? parseInt(pageDetail?.companyId, 10) : "",
      limit: parseInt(5000,10),
      offset: 0,
    },
    skip: !pageDetail,
  });
  useEffect(() => {
    if (companyList.length == 0) {
      getCompanyList();
    }
    setEditorProps(defaultEditorProps);
  }, []);

  useEffect(() => {
    if (pageDetail.hasOwnProperty('companyId')) {
      setCompanyId(pageDetail.companyId);
      let tempProp = editorProps;
      tempProp.options.assetManager.params = { companyId: pageDetail.companyId };
      setEditorProps(editorProps);
    }
  }, [pageDetail])

  useEffect(() => {fetchAssetURLs();}, [companyId])

  const getCompanyList = () => {
    let impactedServiceData;
    api.get("/api/getFdnCompanyList").then((responseCompany) => {
      let companyList1 = [];
      impactedServiceData = responseCompany.data;
      impactedServiceData.forEach((item) => {
        if (item.name && item.name !== null) {
          companyList1.push({
            label: item.name,
            id: item.id,
          });
        }
      });
      setCompanyList(companyList1);
    });
  };

  const { data: dataQueries, refetch: fetchDataQueries } = useQuery(GetDataQueries, {
    variables: { input: { form: params.formId } }
  });

  const { data: assetURLs, refetch: fetchAssetURLs, loading: fetchingAssetUrls } = useQuery(GET_ASSET_URLS, {
    variables: { companyId: `${companyId}` }
  }, {skip: !companyId});

  const {
    data: formDetailData,
    loading: formDetailLoading,
    error: formDetailError,
    refetch: formDetailRefetch,
  } = useQuery(getForm, { variables: { id: params.formId } });


  const defaultEditorProps = {
    grapesjs: grapesjs,
    grapesjsCss: [
      "https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css",
    ],
    plugins: [
      gjsBlocksBasic,
      gjsForms,
      GrapesjsTabs,
      gjsNavbar,
      pluginTooltip,
      // gjsPresetWebpage,
      customCodePlugin,
      blocks.addCustomAccordionComponent,
      blocks.addCustomIconComponent,
      blocks.myInputComponent,
      blocks.mySelect,
      //blocks.addActivityComponent,
      blocks.addTypeaheadComponent,
      blocks.myThComponent,
      blocks.buttonExtendTraits,
      blocks.tableExtendTraits,
      blocks.addUploadComponent,
      blocks.myCheckboxComponent,
      blocks.myRadioComponent,
      blocks.dropdownConnector,
      blocks.titleComponent,
      blocks._myBreadCrumbComponent,
      blocks.formTraitExtends,
      blocks.repetativeRow,
      blocks.repeatativeFieldsBlock,
      blocks.dependentFieldsBlock,
      blocks.linkExtendTraits,
      blocks.tabContentExtendTrait,
      blocks.extendSelectTraits,
      blocks.myDateTimeComponent,
      blocks.accordionExtendTrait,
      blocks.switchComponent,
      blocks.myTdComponent,
      blocks.PageContainer,
      blocks.repetativelevel,
      blocks.horizontalSlider
    ],
    pluginsOpts: {
      [gjsNavbar]: {},
      [pluginTooltip]: {},
      [customCodePlugin]: {},
    },
    options: {
      assetManager: {
        upload: "/uibuilder/asset/upload",
        uploadName: "files",
        assets: [],
      },
      canvas: {
        styles: [
          "https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css",
          "https://sx-issue-app.s3.ap-south-1.amazonaws.com/canvas.css",
          "https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css",
        ],
        scripts: [
          "https://cdn.jsdelivr.net/npm/jquery@3.7.1/dist/jquery.slim.min.js",
          "https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/js/bootstrap.bundle.min.js",
        ],
      },
      blockManager: {
        Extra: {
          [GrapesjsTabs]: {},
        },
      },
      storageManager: {
        type: "local",
        autosave: true,
        autoload: true,
        stepsBeforeSave: 1,
        options: {
          local: {
            key: "gjsProject",
          },
        },
      },
      undoManager: {
        trackSelection: false,
      },
      selectorManager: {
        componentFirst: true,
      },
      commands: {
        defaults: [
          {
            id: "rule-modal",
            run(editor) {
              editor.Modal.setTitle("Rules");
              const modalContent = document.createElement("div");
              ReactDOM.render(<RuleIndex />, modalContent);
              editor.Modal.setContent(modalContent);
              editor.Modal.open();
            },
          },
          {
            id: "customScript-modal",
            run(editor) {
              editor.Modal.setTitle("Custom Script");
              const modalContent = document.createElement("div");
              ReactDOM.render(<CustomScript />, modalContent);
              editor.Modal.setContent(modalContent);
              editor.Modal.open();
            },
          },
          {
            id: "copy-component",
            run: function (editor, sender) {
              let component = editor.getSelected();
              let html = component.toHTML();
              const selectorsC = getClassesAndsIds(html);
              let cssRules = editor.CssComposer.getAll();
              let css = "";
              cssRules.each((rule) => {
                if (selectorsC.includes(rule.selectorsToString())) {
                  css += rule.toCSS();
                }
              });
              navigator.clipboard.writeText(JSON.stringify({ html:component, css }));
              Swal.fire({
                toast: true,
                timer: 1000,
                position: top,
                showConfirmButton: false,
                icon: "success",
                title: "Component copied!",
              });
            },
          },
          {
            id: "paste-component",
            run: function (editor, sender) {
              navigator.clipboard.readText().then((clipText) => {
                if (clipText) {
                  let loadedData = JSON.parse(
                    //localStorage.getItem("copiedComponent")
                    clipText
                  );
                  let loadedHtml = loadedData.html;
                  let loadedCss = loadedData.css;
                  var selectedComponent = editor.getSelected();
                  if(selectedComponent){
                    const css = editor.Css;
                    css.addRules(loadedCss);
                    selectedComponent.append(loadedHtml);
                    localStorage.removeItem("copiedComponent");
                  }
                }
              });
            },
          },
        ],
      },
    },
  };

  editor?.on('asset:open', () => {
    if (!pageDetail.hasOwnProperty('companyId')) {
      Swal.fire({
        title: "Please save the form first!",
        width:400,
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        customClass: {
          container: 'customswal'
        }
      }).then(() => {
        const assetManager = editor.AssetManager;
        assetManager.close();
      });
    }
    else{
      const assetManager = editor.AssetManager;
      if(assetURLs){
        assetManager.add(assetURLs.assetUrls);
      }
    }
  })

  editor?.on('asset:upload:response', () => {
    setUploaded(true);
    setTimeout(() => { setUploaded(false); }, 3000)
  })

  useEffect(() => {
    formDetailRefetch();
  }, []);

  useEffect(() => {
    if (formDetailData) {
      const res = formDetailData.getForm;
      setPageDetail(res);
      localStorage.setItem("gjsProject", res?.formObjects);
      localStorage.setItem(
        "formDetail",
        JSON.stringify({
          name: res.name,
          customScript: res?.customScript,
          companyId: res?.companyId,
          companyName: res?.company,
          createdBy: res.createdBy,
          updatedBy: res.updatedBy,
        })
      );
      localStorage.setItem("ruleData", res.ruleData);
    }
  }, [formDetailData]);

  useEffect(()=>{
     if(pageDetail){
      refetchForms();
     }
  },[pageDetail])

  useEffect(() => {
    fetchDataQueries();
  }, [params.formId])

  const updateTrait = () => {
    let options = dataQueries && dataQueries?.dataQueries
      ? dataQueries.dataQueries.map(dataQuery => { return { id: dataQuery["_id"], label: dataQuery.title } })
      : [];
    updateTraitOptions('data_source', options, false);
    let pageOptions = getForms && getForms?.getAllForms
      ? getForms?.getAllForms.map(form => { return { id: form["_id"], label: form.name } })
      : [];
    updateTraitOptions("page",pageOptions, false);
    let containers = [];
    if (pageContainers && pageContainers.length > 0) {
      pageContainers.forEach((container) => {
        if (container?.containerName)
          containers.push({
            id: `${container?.containerName}`,
            label: `${container.containerName}`,
          });
      });
    }
    updateTraitOptions("container_name",containers, false);
  }

  const updateDataSourceKeys = (selected) => {
    let options = dataQueries && dataQueries?.dataQueries
      ? dataQueries.dataQueries.find(dataQuery => { return dataQuery["_id"] === selected })?.data
      : null;
    if (options) {
      options = JSON.parse(options);
      options = options.responseKeys
        ? options.responseKeys.map(item => { return { id: item, label: item } })
        : [];
    }
    updateTraitOptions('valueKey', options, true);
    updateTraitOptions('labelKey', options, true);
  }

  const updateTraitOptions = (name, options, resetValue) => {
    const component = editor.getSelected();
    if (component) {
      const trait = component.getTrait(name);
      if (trait) {
        trait.set('options', options);
        if (resetValue) trait.setValue('');
      }
    }
  }

  const onEditor = (e) => {
    setEditor(e);
    e.BlockManager.remove('video');
    e.BlockManager.remove('map');
    e.BlockManager.remove('radio');
    e.BlockManager.remove('tooltip');
    // e.BlockManager.remove('column3');
    e.BlockManager.add(blocks.dropdownConnectorJson.id, blocks.dropdownConnectorJson);
    // e.BlockManager.add(blocks.activityBlockJson.id, blocks.activityBlockJson);
    e.BlockManager.add(blocks.typeaheadBlockJson.id, blocks.typeaheadBlockJson);
    e.BlockManager.add(blocks.uploadBlockJson.id, blocks.uploadBlockJson);
    e.BlockManager.add(blocks.titleBlockJson.id, blocks.titleBlockJson);
    e.BlockManager.add("custom-block", blocks.myCheckboxBlockJson);
    e.BlockManager.add("custom--radio-block", blocks.myRadioBlockJson);
    e.BlockManager.add("table-block", blocks.tableBlockJson);
    e.BlockManager.add("mybreadcrumb-block", blocks.myBreadCrumbBlockJson);
    e.BlockManager.add(blocks.titleBlockJson.id, blocks.titleBlockJson);
    e.BlockManager.add(blocks.customIconBlockJson.id, blocks.customIconBlockJson);
    e.BlockManager.add(blocks.customAccordionBlockJson.id, blocks.customAccordionBlockJson);
    e.BlockManager.add(blocks.repeatativeRowBlockJson.id, blocks.repeatativeRowBlockJson);
    e.BlockManager.add(blocks.repeatativeFieldBlockJson.id, blocks.repeatativeFieldBlockJson);
    e.BlockManager.add(blocks.dependentFieldBlockJson.id, blocks.dependentFieldBlockJson);
    e.BlockManager.add(blocks.myDateTimeBlockJson.id, blocks.myDateTimeBlockJson);
    e.BlockManager.add(blocks.switchButtonBlockJson.id, blocks.switchButtonBlockJson);
    e.BlockManager.add(blocks.pageContainerBlockJson.id, blocks.pageContainerBlockJson);
    
    e.BlockManager.add(blocks.levelFormBlock.id, blocks.levelFormBlock);
    e.BlockManager.add(blocks.horizontalSliderBlockJson.id, blocks.horizontalSliderBlockJson);
  }

  if (formDetailLoading) {
    return <ListLoader />;
  } else {
    return (
      <div className="container-fluid">
        <div className="margin-b-15 margin-t-10">
          <Breadcrumbs parentPageName="Forms" parentPageurl="/formLists" activePageName={params?.formId ? "Edit" : "Create"} />
        </div>
        <GrapeJsEditor
          onEditor={onEditor}
          className="minVh d-flex gjs-custom-editor"
          {...editorProps}
        >
          {/* <div className="d-flex flex-grow-1 gap-4">
            <div className="gjs-column-m d-flex flex-column flex-grow-1">
              <Header />
              <Topbar
                className="d-flex min-h-[48px]"
                companyList={companyList}
                pageDetail={pageDetail}
                formDetailRefetch={formDetailRefetch}
                navigationProps={navigationProps}
              />
              <Canvas className="flex-grow-1 gjs-custom-editor-canvas" />
            </div>
            {isRightPanelShow ? null : (
              <RightPanelIcons
                setPanelShow={setPanelShow}
                setRightPanelShow={setRightPanelShow}
                isRightPanelShow={isRightPanelShow}
              />
            )}
            {isRightPanelShow ? (
              <RightSidebar
                setRightPanelShow={setRightPanelShow}
                isPanelShow={isPanelShow}
                editor={editor}
                className="gjs-column-r"
                updateTrait={updateTrait}
                updateDataSourceKeys={updateDataSourceKeys}
              />
            ) : null}
          </div> */}

            <PanelGroup direction="horizontal">
              <Panel id="sidebar" minSize={60} order={1} defaultSize={isRightPanelShow ? 67:100}>
                <div className={isRightPanelShow ? (isLeftPanelShow ? "hide":"show") :"show leftPanelCanvasDv"}>
                  <Topbar
                    className="d-flex min-h-[48px]"
                    companyList={companyList}
                    pageDetail={pageDetail}
                    formDetailRefetch={formDetailRefetch}
                    navigationProps={navigationProps}
                  />
                  <Canvas className="flex-grow-1 gjs-custom-editor-canvas" />
                </div>
                {isRightPanelShow ? null : (
                  <RightPanelIcons
                    setPanelShow={setPanelShow}
                    setRightPanelShow={setRightPanelShow}
                    isRightPanelShow={isRightPanelShow}
                  />
                )}
                </Panel>
                {isRightPanelShow ? <>
              <PanelResizeHandle className={isRightPanelShow ? (isLeftPanelShow ? "hide":"isShowLeftPanel resizeHandle") :"isShowLeftPanel resizeHandle"}><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
              <Panel minSize={25} order={2} defaultSize={33} className={isRightPanelShow ? (isLeftPanelShow ? "fullWd":"show") :"show"}>
                <RightSidebar
                  setRightPanelShow={setRightPanelShow}
                  isPanelShow={isPanelShow}
                  editor={editor}
                  className="gjs-column-r"
                  updateTrait={updateTrait}
                  updateDataSourceKeys={updateDataSourceKeys}
                  pageChanged={pageChanged}
                />
              </Panel> </> : null}
            </PanelGroup>




          <ModalProvider>
            {({ open, title, content, close }) => (
              <CustomModal
                open={open}
                title={title}
                children={content}
                close={close}
              />
            )}
          </ModalProvider>
        </GrapeJsEditor>
        <NotificationGroup
          style={{
            top: 0,
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 99999
          }}
        >
          <Fade>
            {uploaded && (
              <Notification
                onClose={() => setUploaded(false)}
                type={{
                  style: "success",
                  icon: true,
                }}
              >
                File uploaded successfully.
              </Notification>
            )}
          </Fade>
        </NotificationGroup>
      </div>
    );
  }
}

export default Studio
