
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Pagination from "react-js-pagination";
import Swal from "sweetalert2";
import Alert from "react-bootstrap/Alert";

const AttachedArticles = (props) => {
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(
    "Changes are not allowed in Deployed status"
  );
  const dispatch = useDispatch();
  const attachedKnowledgeArticlesList = useSelector(
    (state) => state.attachedKnowledgeArticlesList
  );

  const deleteKnowledgeArticle = (id) => {
    props.deleteKnowledgeArticle(id);
  };

  const renderArticleHTML = () => {
    const allArticlesList = getItemsBasedOnPagination(
      attachedKnowledgeArticlesList
    );
    if (allArticlesList.length === 0) {
      return (
        <tr>
          <td colSpan={2} className="article-table-td-align-center">
            {["No Attached Knowledge References Available"]}
          </td>
        </tr>
      );
    }

    return allArticlesList.map((item, index) => {
      let iconClass =
        item.type === "SPCM"
          ? "KA-icon interLnk"
          : item.type === "Knowledge" || item.type === "conversation"
          ? "KA-icon exterLnk"
          : "KA-icon community";
      return (
        <tr key={`attachedArticles_${index}`}>
          <td className="text-c">{item.knowledge_id}</td>
          <td className="kn-title">
            <span>
              <span
                style={{
                  verticalAlign: "middle",
                  backgroundPosition: "0px 1px",
                }}
                className={iconClass}
                title={item.type === "conversation" ? "Knowledge" : [item.type]}
              ></span>
              {props.renderLargeTextEllipse(item.knowledge_article)}
            </span>
          </td>
          <td className="text-c">
            {props.checkEditAllowed() && (
              <a
                title="Remove"
                className="margin-r-10"
                href="javascript:void(0)"
                onClick={() => {
                  onDeleteIconClicked(item.id);
                }}
              >
                <i className="f-size-16 fa fa-trash-o colorStBlue"></i>
              </a>
            )}
            <a
              onClick={() => {
                props.downloadFile(item.type, item["article_url"], item["id"]);
              }}
              href="javascript:void(0)"
            >
              <i
                style={{ position: "relative", top: "2px" }}
                className={
                  item.type === "SPCM"
                    ? "fa fa-download f-size-16 colorStBlue"
                    : "fa fa-external-link f-size-15 colorStBlue"
                }
                title={item.type === "SPCM" ? ["Download"] : ["Redirect"]}
              ></i>
            </a>
          </td>
        </tr>
      );
    });
  };

  const onPageSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentPage(1);
  };

  const onPageChange = (value) => {
    setCurrentPage(value);
  };

  const getItemsBasedOnPagination = (totalItems) => {
    try {
      const startIndex =
        Number(currentPage) * Number(pageSize) - Number(pageSize);
      return totalItems.slice(startIndex, startIndex + Number(pageSize));
    } catch (e) {
      return totalItems || [];
    }
  };

  const onDeleteIconClicked = (id) => {
    if (props.serviceStatusState === "Deployed") {
      setShowError(true);
    } else {
      Swal.fire({
        title: "Are you sure you wish to delete this Knowledge Article?",
        confirmButtonText: "Yes",
        showCancelButton: true,
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteKnowledgeArticle(id);
        }
      });
    }
  };

  return (
    <div className="margin-t-20 knowledge-search-section attached-articles-section">
      {showError && (
        <Col md={12}>
          <Alert
            style={{
              marginBottom: "15px",
              fontSize: "13px",
              padding: "8px 30px 8px 8px",
            }}
            variant="danger"
          >
            <h4>{error}</h4>
          </Alert>
        </Col>
      )}
      <Row>
        <Col md={12}>
          <h2 style={{ marginBottom: "0px" }}>{["Attached Articles"]}</h2>
        </Col>
        <Col md={12}>
          <div>
            <Table
              className="table table-striped table-bordered table-condensed table-hover"
              style={{ marginTop: "10px", marginBottom: "0px" }}
            >
              <thead>
                <tr>
                  <th width="21%" className="">
                    <span>{["Topic ID"]}</span>
                  </th>
                  <th width="58%" className="">
                    {["Knowledge Articles"]}
                  </th>
                  <th width="21%" className="text-c">
                    {["Actions"]}
                  </th>
                </tr>
              </thead>
              <tbody>{renderArticleHTML()}</tbody>
            </Table>
            {attachedKnowledgeArticlesList &&
              attachedKnowledgeArticlesList.length > 0 && (
                <Row className="margin-t-20">
                  <Col lg={5} md={7} sm={7} xs={7} className="">
                    <div className="">
                      <Form.Label className="margin-r-5 display-inline-block widthAuto">
                        {["Show"]}
                      </Form.Label>
                      <Form.Select
                        componentClass="select"
                        aria-label="select"
                        className="display-inline-block widthAuto"
                        onChange={(e) => onPageSizeChange(e)}
                        value={pageSize}
                      >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={9999}>All</option>
                      </Form.Select>
                    </div>
                  </Col>
                  <Col lg={7} md={5} sm={5} xs={5}>
                    <div className="text-r">
                      <Pagination
                        activePage={currentPage}
                        itemsCountPerPage={pageSize}
                        totalItemsCount={attachedKnowledgeArticlesList.length}
                        onChange={onPageChange}
                        pageRangeDisplayed={1}
                      />
                    </div>
                  </Col>
                </Row>
              )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AttachedArticles;