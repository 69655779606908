
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useRef, useState } from "react";
import Breadcrumbs from "_Commons/header/breadcrumbs";
import { Button, Container, Row, Col } from "react-bootstrap";
import { CreateSystemConstant, DeleteSystemConstant, GetSystemConstants, UpdateSystemConstant } from "./graphQl";
import { useMutation, useQuery } from "@apollo/client";
import { getDefinitionValue, userId, username, jwtDateFormat } from "./definition";
import ListLoader from '_Commons/loaders/ListLoader';
import { HiPlus, HiOutlineRefresh } from "react-icons/hi";
import { useSelector } from "react-redux";
import EditableGrid from "./editableGrid";
import KeyTextBoxCell from "./keyTextBoxCell";

export default function SystemConstantIndex() {
    const gridRef = useRef();
    const tr = useSelector((state) => state.spcmReducer.tr);
    const { loading, data, refetch } = useQuery(GetSystemConstants, {notifyOnNetworkStatusChange: true});
    const [gridData, setGridData] = useState([]);
    const gridHeaders = [
        { field: "title", title: "Variable Name", editor: "text", editable: true },
        { field: "key", title: "Key", cell: KeyTextBoxCell, editable: true },
        { field: "value", title: "Value", editor: "text", editable: true },
        { field: "modifiedBy", title: "Modified By", editable: false},
        { field: "updatedAt", title: "Modified At", editable: false}
    ];
    const [disableAdd, setDisableAdd] = useState(false);

    const [createSystemConstant, { data: createdSystemConstant, loading: creating, error: createError }] = useMutation(CreateSystemConstant);
    const [updateSystemConstant, { data: updatedSystemConstant, loading: updating, error: updateError }] = useMutation(UpdateSystemConstant);
    const [deleteSystemConstant, { data: deletedSystemConstant, loading: deleting }] = useMutation(DeleteSystemConstant);

    useEffect(() => {
        if (data) {
            setGridData(data.systemConstants.map(item => {
                return {
                    id: item._id,
                    title: item.title,
                    key: item.key 
                        ? item.key.substring(0, 4) === '#SC-' ?  item.key.substring(4) : item.key
                        : item.key,
                    value: getDefinitionValue(item),
                    editable: item.type !== 'system',
                    modifiedBy: item.modifiedBy,
                    updatedAt: moment(item.updatedAt).format(jwtDateFormat)
                }
            }))
        }
    }, [loading])

    useEffect(() => { refetch() }, []);

    const formatRecord = (item) => {
        return {
            title: item.title,
            key: item.key,
            type: 'user-defined',
            definition: JSON.stringify({
                type: 'variable',
                value: item.value
            }),
            modifiedBy: username,
            modifiedById: userId
        }
    }

    const createRecord = (item) => {
        let input = formatRecord(item);
        input.createdBy = username;
        input.createdById = userId;
        createSystemConstant({variables : {input: input}})
    }

    const updateRecord = (item) => {
        let input = formatRecord(item);
        updateSystemConstant({variables : {input: input, id: item.id}});
    }

    const deleteRecord = (item) => {
        deleteSystemConstant({variables: {id: item.id}})
    }

    useEffect(() => {
        if (createdSystemConstant) { refetch(); setDisableAdd(false); }
    }, [creating])

    useEffect(() => {
        if (updatedSystemConstant) { refetch(); setDisableAdd(false); }
    }, [updating])

    useEffect(() => {
        if (deletedSystemConstant) { refetch(); setDisableAdd(false);}
    }, [deleting])

    return (
        <div>
            <div className="container-fluid margin-t-10 margin-b-15">
                <Breadcrumbs activePageName="System Constants" />
            </div>
            <Container fluid className="margin-t-10 margin-b-15">
                <Row className="margin-b-15">
                    <Col lg={6} md={6} sm={6} xs={5}>
                        <h1 className="sPageHeading1">
                            {"System Constants"}
                        </h1>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={7}>
                        <div className="paHedFilterSecDv">
                            <div className="paHedFilter">
                                <Button
                                    bsPrefix=" "
                                    type="button"
                                    onClick={() => { gridRef.current.addRecord(); setDisableAdd(true) }}
                                    className="myBt plus"
                                    title={tr["Create"]}
                                    disabled={disableAdd}
                                >
                                    <HiPlus />
                                </Button>
                                <Button
                                    bsPrefix=" "
                                    title="Refresh"
                                    type="button"
                                    className="myBt"
                                    onClick={() => { refetch()}}
                                >
                                    <HiOutlineRefresh className="refreshFlip" />
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="dtaTableDv integrateHubTable">
                    <div className={"rightTrue srchPosTop"}>
                        {
                            loading
                                ? (<ListLoader></ListLoader>)
                                : (<EditableGrid
                                    ref={gridRef}
                                    data={gridData}
                                    headers={gridHeaders}
                                    createRecord={createRecord}
                                    updateRecord={updateRecord}
                                    deleteRecord={deleteRecord}
                                    setDisableAdd={setDisableAdd}
                                ></EditableGrid>)
                        }
                    </div>
                </div>
            </Container>
        </div>
    )
}