
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
/**
@Description: routes for
**/

import axios from 'axios';
//below URL used for testing purpose
//const breakFixUrl = 'https://xsm-f.mybluemix.net/api/SX_FAVOURITEOFFERINGs/';
import { GLOBAL } from "_Globals";
const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});
//let breakFixUrl =`https://xsm-f.mybluemix.net/api/breakfixitem?breakfixitemId=`;
//let breakFixUrl=`https://xsm-f.mybluemix.net/groupListSelfService?companyId=2932&group=ResolvedER`
export function loadBreakFixActivityLogDetailsSuccess(breakFixActivityLogDetails) {
	// console.log("activity",breakFixActivityLogDetails);
	return {
		type: 'LOAD_BREAKFIXACTIVITYLOGDETAILS_SUCCESS',
		breakFixActivityLogDetails
	};
}

export function loadBreakFixActivityLogDetailsFailure(errorResponse) {
	return {
		type: 'LOAD_BREAKFIXACTIVITYLOGDETAILS_FAILURE',
		errorResponse
	};
}

export function loadBreakFixActivityLogDetails(breakFixId, orderType, module, callback, isReorder) {
	if (isReorder == "")
		isReorder = "N"
	let params = '';
	return (dispatch) => {
		// alert("EditGroupIdAction123"+breakFixId);
		if(!breakFixId){
			dispatch(loadBreakFixActivityLogDetailsSuccess({
				data: []
			}));
			return;
		}
		if (breakFixId == undefined || breakFixId == "") {
			dispatch({
				type: 'LOADER_IN_PROGRESS',
				payload: ''
			});
			//alert("H2 breakFixId not found");
		} else {
			if (isReorder === 'Y') {
				if (module === "Fulfillment")
					module = "Fullfilment";

				params = "?isReorder=Y&itemId=" + breakFixId + "&module=" + module;
				// params =  4863 "?itemId="+breakFixId+"&orderBy="+orderType+"&requireStatusValue=Y&module="+module;
			} else {
				params = "?itemId=" + breakFixId + "&orderBy=" + orderType + "&requireStatusValue=Y&module=" + module;
			}
			axios.get(GLOBAL.breakFixActivityLogUrl + params)
				.then((breakFixActivityLogDetails) => {
					// console.log("activityiesss",breakFixActivityLogDetails);

					if (!breakFixActivityLogDetails) {//alert('not breakFix')
						// dispatch(loadBreakFixFailure(breakFix.statusText))
						// console.log("activityiesss",breakFixActivityLogDetails);
						dispatch({
							type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY',
							payload: ''
						});
						callback();
						throw Error(breakFixActivityLogDetails.statusText);
					}

					return breakFixActivityLogDetails;
				})
				.then((breakFixActivityLogDetails) => {
					//alert('yes it is')
					// console.log("activityiesss",breakFixActivityLogDetails);

					dispatch({
						type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY',
						payload: ''
					});
					// console.log("activityiesss",breakFixActivityLogDetails);

					dispatch(loadBreakFixActivityLogDetailsSuccess(breakFixActivityLogDetails));
					callback();
				})
				.catch((err) => {
					dispatch({
						type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY',
						payload: ''
					});
					console.log(err);
				});
			//console.log("not calling loadBreakFixEditDetails");
		}
	};
}

export function loadTMSActivityLogDetails(taskCode, order, callback) {
	let config = {
		// params: {
		// 	'filter': { "where": { "taskCode": taskCode }, "order": order }
		// }
		headers: {
			'query':JSON.stringify({"taskCode":taskCode,"order":order})
	
		  }
	};
	
	return (dispatch) => {
		dispatch({
			type: 'LOADER_IN_PROGRESS',
			payload: ''
		});
		// axios.get(GLOBAL.TMSActivityLogUrl + '?filter=' + filter)
		axios.get(GLOBAL.TMSActivityLogUrl, config)
			.then((TMSActivityLogDetails) => {
				if (!TMSActivityLogDetails) {
					dispatch({
						type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY',
						payload: ''
					});
					throw Error(TMSActivityLogDetails.statusText);
				}
				return TMSActivityLogDetails;
			})
			.then((TMSActivityLogDetails) => {
				dispatch({
					type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY',
					payload: ''
				});
				dispatch(loadBreakFixActivityLogDetailsSuccess(TMSActivityLogDetails));
				callback();
			})
			.catch((err) => {
				dispatch({
					type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY',
					payload: ''
				});
				// console.log(err);
			});
	};
}


export function loadTMSActivityLogDetailsForRequest(taskCode, order, callback,requestType="") {
	let config = {
		// params: {
		// 	'filter': { "where": { "taskCode": taskCode }, "order": order }
		// }
		headers: {
			'query':JSON.stringify({"taskCode":taskCode,"order":order,"requestType":requestType})
	
		  }
	};
	
	return (dispatch) => {
		// axios.get(GLOBAL.TMSActivityLogUrl + '?filter=' + filter)
		axios.get(GLOBAL.TMSActivityLogUrl, config)
			.then((TMSActivityLogDetails) => {
				if (!TMSActivityLogDetails) {
					dispatch({
						type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY',
						payload: ''
					});
					throw Error(TMSActivityLogDetails.statusText);
				}
				return TMSActivityLogDetails;
			})
			.then((TMSActivityLogDetails) => {
				dispatch({
					type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY',
					payload: ''
				});
				dispatch(loadBreakFixActivityLogDetailsSuccess(TMSActivityLogDetails));
				callback();
			})
			.catch((err) => {
				dispatch({
					type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY',
					payload: ''
				});
				// console.log(err);
			});
	};
}


export function clearBreakfixActivityLogDetails(){
	let breakFixActivityLogDetails={ data: []};
	  return (dispatch) => {dispatch({
        type: 'LOAD_BREAKFIXACTIVITYLOGDETAILS_SUCCESS',
		breakFixActivityLogDetails
	  });
    };
}