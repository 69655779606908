
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, ButtonToolbar, Button, Table, Form, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import { Link } from 'react-router-dom';
import ListLoader from "../../../common/loaders/ListLoader.js"
import Pagination from "react-js-pagination";
import { _passwordField, _inputField, _dropDown, _checkbox, _number } from '../../../common/formFields';
import { loadTagMemberDependencyData, loadTagMemberDependencyParentData, deleteTagMemberDependencyData, createMemberTagInitialDetails, createChecklistMemberTagInitialDetails } from '_Actions/foundation/tags/tagsAction';
import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from "universal-cookie";
import { PiPencilSimpleBold } from "react-icons/pi"
import { HiPlus } from "react-icons/hi"
import { IoClose, IoSearch } from "react-icons/io5"
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { AiOutlineQuestionCircle } from "react-icons/ai";
import {ImSpinner6} from "react-icons/im";

const cookies = new Cookies();
let app_url = cookies.get("gph");
if (app_url) app_url = app_url.replace("s:", "");
if (app_url) app_url = app_url.substring(0, app_url.lastIndexOf("."));
app_url = app_url.split("~");
app_url = app_url[18];

const ValuesPart = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const translator = useSelector((state) => state.spcmReducer.tr);
    const foundation_tags_loader_reducer = useSelector((state) => state.foundation_tags_loader_reducer);
    const tagDetailsByTagId = useSelector((state) => state.foundation_tags_reducer.tagDetailsByTagId);
    const memberDependencyData = useSelector((state) => state.foundation_tags_reducer.memberDependencyData);
    const memberDependencyMeta = useSelector((state) => state.foundation_tags_reducer.memberDependencyMeta);
    const memberDependencyParentData = useSelector((state) => state.foundation_tags_reducer.memberDependencyParentData);
    const memberTagsForComposite = useSelector((state) => state.foundation_tags_reducer.memberTagsForComposite);
    const memberTagsForChecklist = useSelector((state) => state.foundation_tags_reducer.memberTagsForChecklist);

    const [toggleView, setToggleView] = useState('list');
    const [alertMessage, setAlertMessage] = useState('');
    const [createUpdateLoader, setCreateUpdateLoader] = useState(false);
    const [rowCountAdd, setRowCountAdd] = useState([0]);
    const [addFormValuesTempValue, setAddFormValuesTempValue] = useState({});
    const [addFormValuesTempParent, setAddFormValuesTempParent] = useState({});
    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [rowCountEdit, setRowCountEdit] = useState([]);
    const [editFormValuesTempId, setEditFormValuesTempId] = useState({});
    const [editFormValuesTempValue, setEditFormValuesTempValue] = useState({});
    const [editFormValuesTempParent, setEditFormValuesTempParent] = useState({});
    const [editValuesSubmitData, setEditValuesSubmitData] = useState([]);
    const [sortByTitle, setSortByTitle] = useState('');
    const [searchBy, setSearchBy] = useState('tag_value');
    const [searchByVal, setSearchByVal] = useState('');
    let hasParentTag = false;
    if (tagDetailsByTagId?.tagType == 'Member' && tagDetailsByTagId.sequence > 1) {
        hasParentTag = true;
    }

    useEffect(() => {
        dispatch(loadTagMemberDependencyData(props.tagId));
    }, [props.tagId]);

    function renderList(memberDependencyData) {
        return (
            memberDependencyData.length == 0 ?
                <tr><td colSpan={3} className="text-c">{translator['No Data Found']}</td></tr>
                :
                memberDependencyData.map((item, i) => {
                    return (
                        <tr key={i}>
                            <td>{item.tag_value}</td>
                            {hasParentTag ? <td>{item.parent}</td> : null}
                            <td>
                                <div className="text-c">
                                    <i title={translator["Delete"]} className="margin-l-10 fa fa-trash-o cursorPoint" onClick={() => deleteMemberDependencyData(item.id)}></i>
                                </div>
                            </td>
                        </tr>
                    )
                })
        )
    }

    function sortHover (e) {
        let index = e.nativeEvent.target.selectedIndex;
        setSortByTitle(e.nativeEvent.target[index].text);
    }

    function onSearchClick() {
        dispatch(loadTagMemberDependencyData(props.tagId, 1, pageSize, '', searchBy, searchByVal))
    }

    function handleKeyPress(e) {
        if (e.key === 'Enter') {
            onSearchClick();
            if (e.preventDefault) e.preventDefault();
        }
    }

    function queryBySelect(e) {
        setSearchBy(e.target.value);
        setSearchByVal('');
        dispatch(loadTagMemberDependencyData(props.tagId, 1, pageSize, '', '', ''));
    }

    function onTextChange(e) {
        setSearchByVal(e.target.value );
    }
    function renderMemberTagsList(memberTagsData) {
        return (
            memberTagsData.length == 0 ?
                <tr><td colSpan={3} className="text-c">{translator['No Data Found']}</td></tr>
                :
                memberTagsData.map((item, i) => {
                    let url = app_url + "/home?editCustomAttribute=" + item.tagId;
                    return (
                        <tr key={i}>
                            {/* <td><Link to={"/editCustomAttribute/" + item.tagId}>{item.tagLabel}</Link></td> */}
                            <td>
                            <a 
                            title={translator['Attribute']}
                            target="_blank" href={url} onClick={() => {}}>
                            {" "}
                            {item.tagLabel}
                             </a>
                             </td>
                            <td>{item.parent}</td>
                            <td>{item.tagEnabled == true ? 'True' : 'False'}</td>
                        </tr>
                    );
                })
        )
    }
    
    function renderChecklistMemberTagsList(memberTagsData) {
        return (
            memberTagsData.length == 0 ?
                <tr><td colSpan={3} className="text-c">{translator['No Data Found']}</td></tr>
                :
                memberTagsData.map((item, i) => {
                    let url = app_url + "/home?editCustomAttribute=" + item.tagId;
                    return (
                        <tr key={i}>
                            {/* <td><Link to={"/editCustomAttribute/" + item.tagId}>{item.tagLabel}</Link></td> */}
                            <td>
                            <a 
                            title={translator['Attribute']}
                            target="_blank" href={url} onClick={() => {}}>
                            {" "}
                            {item.tagLabel}
                             </a>
                             </td>
                            <td>{item.tagType}</td>
                            <td>{item.tagEnabled == true ? 'True' : 'False'}</td>
                        </tr>
                    );
                })
        )
    }

    function deleteMemberDependencyData(id) {
        dispatch(deleteTagMemberDependencyData(id, () => {
            dispatch(loadTagMemberDependencyData(props.tagId));
        }));
    }

    function onSpecifyValuesAddClick() {
        setToggleView('add');
        if (hasParentTag) {
            dispatch(loadTagMemberDependencyParentData(tagDetailsByTagId.parentTag));
        }
    }

    function onSpecifyValuesEditClick() {
        setToggleView('edit');
        let arr = [...rowCountEdit];
        let idObj = {};
        let obj1 = {};
        let obj2 = {};
        for (let i = 0; i < memberDependencyData.length; i++) {
            arr.push(i);
            idObj['id' + i] = memberDependencyData[i].id;
            obj1['value' + i] = memberDependencyData[i].tag_value;
            if (hasParentTag) {
                obj2['parent' + i] = memberDependencyData[i].parent;
            }
        }
        setRowCountEdit(arr);
        setEditFormValuesTempId(idObj);
        setEditFormValuesTempValue(obj1);
        setEditFormValuesTempParent(obj2);
        if (hasParentTag) {
            dispatch(loadTagMemberDependencyParentData(tagDetailsByTagId.parentTag));
        }
    }

    function onPageChange(e) {
        if (toggleView == 'edit') {
            if (editValuesSubmitData.length > 0) {
                Swal.fire({
                    title: "Do you want to proceed without saving the changes?",
                    width: 550,
                    padding: '15px',
                    showDenyButton: true,
                    confirmButtonText: 'Yes',
                    denyButtonText: 'No',
                }).then((result) => {
                    if (result.isConfirmed) {
                        setAlertMessage('');
                        setPageNumber(e);
                        dispatch(loadTagMemberDependencyData(props.tagId, e, pageSize, (memberDependencyEditData) => {
                            let arr = [];
                            let idObj = {};
                            let obj1 = {};
                            let obj2 = {};
                            for (let i = 0; i < memberDependencyEditData.length; i++) {
                                arr.push(i);
                                idObj['id' + i] = memberDependencyEditData[i].id;
                                obj1['value' + i] = memberDependencyEditData[i].tag_value;
                                if (hasParentTag) {
                                    obj2['parent' + i] = memberDependencyEditData[i].parent;
                                }
                            }
                            setRowCountEdit(arr);
                            setEditFormValuesTempId(idObj);
                            setEditFormValuesTempValue(obj1);
                            setEditFormValuesTempParent(obj2);
                        }));
                        setEditValuesSubmitData([]);
                    } else if (result.isDenied) {
                        setAlertMessage(translator['Please submit edited records before changing page.']);
                        e.preventDefault();
                        return false;
                    }
                });
            }
            else {
                setAlertMessage('');
                setPageNumber(e);
                dispatch(loadTagMemberDependencyData(props.tagId, e, pageSize, (memberDependencyEditData) => {
                    let arr = [];
                    let idObj = {};
                    let obj1 = {};
                    let obj2 = {};
                    for (let i = 0; i < memberDependencyEditData.length; i++) {
                        arr.push(i);
                        idObj['id' + i] = memberDependencyEditData[i].id;
                        obj1['value' + i] = memberDependencyEditData[i].tag_value;
                        if (hasParentTag) {
                            obj2['parent' + i] = memberDependencyEditData[i].parent;
                        }
                    }
                    setRowCountEdit(arr);
                    setEditFormValuesTempId(idObj);
                    setEditFormValuesTempValue(obj1);
                    setEditFormValuesTempParent(obj2);
                }));
            }
        }
        else {
            setAlertMessage('');
            setPageNumber(e);
            dispatch(loadTagMemberDependencyData(props.tagId, e, pageSize));
        }
    }

    function onPageSizeChange(e) {
        if (toggleView == 'edit') {
            if (editValuesSubmitData.length > 0) {
                Swal.fire({
                    title: "Do you want to proceed without saving the changes?",
                    width: 550,
                    padding: '15px',
                    showDenyButton: true,
                    confirmButtonText: 'Yes',
                    denyButtonText: 'No',
                }).then((result) => {
                    if (result.isConfirmed) {
                        setAlertMessage('');
                        setPageSize(e.target.value);
                        dispatch(loadTagMemberDependencyData(props.tagId, 1, e.target.value, (memberDependencyEditData) => {
                            let arr = [];
                            let idObj = {};
                            let obj1 = {};
                            let obj2 = {};
                            for (let i = 0; i < memberDependencyEditData.length; i++) {
                                arr.push(i);
                                idObj['id' + i] = memberDependencyEditData[i].id;
                                obj1['value' + i] = memberDependencyEditData[i].tag_value;
                                if (hasParentTag) {
                                    obj2['parent' + i] = memberDependencyEditData[i].parent;
                                }
                            }
                            setRowCountEdit(arr);
                            setEditFormValuesTempId(idObj);
                            setEditFormValuesTempValue(obj1);
                            setEditFormValuesTempParent(obj2);
                        }));
                        setEditValuesSubmitData([]);
                    } else if (result.isDenied) {
                        setAlertMessage(translator['Please submit edited records before changing page size.']);
                        e.preventDefault();
                        return false;
                    }
                });
            }
            else {
                setAlertMessage('');
                setPageSize(e.target.value);
                dispatch(loadTagMemberDependencyData(props.tagId, 1, e.target.value, (memberDependencyEditData) => {
                    let arr = [];
                    let idObj = {};
                    let obj1 = {};
                    let obj2 = {};
                    for (let i = 0; i < memberDependencyEditData.length; i++) {
                        arr.push(i);
                        idObj['id' + i] = memberDependencyEditData[i].id;
                        obj1['value' + i] = memberDependencyEditData[i].tag_value;
                        if (hasParentTag) {
                            obj2['parent' + i] = memberDependencyEditData[i].parent;
                        }
                    }
                    setRowCountEdit(arr);
                    setEditFormValuesTempId(idObj);
                    setEditFormValuesTempValue(obj1);
                    setEditFormValuesTempParent(obj2);
                }));
            }
        }
        else {
            setAlertMessage('');
            setPageSize(e.target.value);
            dispatch(loadTagMemberDependencyData(props.tagId, 1, e.target.value, '', searchBy, searchByVal));
        }
    }

    function addRow() {
        let arr = [...rowCountAdd];
        arr.push(rowCountAdd[rowCountAdd.length - 1] + 1);
        setRowCountAdd(arr);
    }

    function deleteRow(myIndex) {
        let arr = [...rowCountAdd];
        let index = arr.indexOf(myIndex);
        arr.splice(index, 1);
        setRowCountAdd(arr);
    }

    function onChangeAddFormValue(val, key) {
        let obj = { ...addFormValuesTempValue };
        obj[key] = val;
        setAddFormValuesTempValue(obj);
    }

    function onChangeAddFormParent(val, key) {
        let obj = { ...addFormValuesTempParent };
        obj[key] = val;
        setAddFormValuesTempParent(obj);
    }

    function loadAddValueForm() {
        let rowlength = rowCountAdd.length;
        return rowCountAdd.map((count, i) => {
            if (rowlength === i + 1) {
                return <tr key={i}>
                    <td><Form.Control type="text" value={addFormValuesTempValue['value' + count] ?? ''} onChange={(event) => onChangeAddFormValue(event.target.value, 'value' + count)} /></td>
                    {hasParentTag ? <td>
                        <Form.Select componentClass="select" value={addFormValuesTempParent['parent' + count] ?? ''} onChange={(event) => onChangeAddFormParent(event.target.value, 'parent' + count)} >
                            <option value="">select</option>
                            {memberDependencyParentData.map((item) => <option value={item.tag_value}>{item.tag_value}</option>)}
                        </Form.Select>
                    </td> : ''}
                    <td className="text-c" style={{ whiteSpace: "pre" }}>
                        {i == 0 ? '' : <Button className="margin-r-5" title="Delete" onClick={() => { deleteRow(count) }}><i className="fa fa-trash-o"></i></Button>}
                        <Button title="Add" onClick={() => { addRow() }}><HiPlus /></Button>
                    </td>
                </tr>;
            }
            else {
                return <tr key={i}>
                    <td><Form.Control type="text" value={addFormValuesTempValue['value' + count] ?? ''} onChange={(event) => onChangeAddFormValue(event.target.value, 'value' + count)} /></td>
                    {hasParentTag ? <td>
                        <Form.Select componentClass="select" value={addFormValuesTempParent['parent' + count] ?? ''} onChange={(event) => onChangeAddFormParent(event.target.value, 'parent' + count)} >
                            <option value="">select</option>
                            {memberDependencyParentData.map((item) => <option value={item.tag_value}>{item.tag_value}</option>)}
                        </Form.Select>
                    </td> : ''}
                    <td className="text-c"><Button title="Delete" onClick={() => { deleteRow(count) }}><i className="fa fa-trash-o"></i></Button></td>
                </tr>;
            }
        });
    }

    function createMemberDependencyData() {
        let submitArr = [];
        let len = rowCountAdd.length;
        let lastCount = rowCountAdd[len - 1];
        let submitFlag = false;
        for (let i = 0; i < rowCountAdd.length; i++) {
            submitFlag = false;
            let count = rowCountAdd[i];
            if (len === 1) {
                if (!(addFormValuesTempValue['value' + count]) || (addFormValuesTempValue['value' + count] && addFormValuesTempValue['value' + count].trim() == '')) {
                    setAlertMessage(translator['Please add atleast one value.']);
                    break;
                }
                if (hasParentTag) {
                    if (!(addFormValuesTempParent['parent' + count]) || (addFormValuesTempParent['parent' + count] && addFormValuesTempParent['parent' + count].trim() == '')) {
                        setAlertMessage(translator['Value and Dependent on are mandatory.']);
                        break;
                    }
                }
            }
            if (count !== lastCount) {
                if (!(addFormValuesTempValue['value' + count]) || (addFormValuesTempValue['value' + count] && addFormValuesTempValue['value' + count].trim() == '')) {
                    setAlertMessage(translator['Please enter a value or delete blank rows.']);
                    break;
                }
                else if (addFormValuesTempValue['value' + count].trim() != '') {
                    if (hasParentTag) {
                        if (addFormValuesTempParent['parent' + count] && addFormValuesTempParent['parent' + count].trim() != '') {
                            let obj = {};
                            obj.tagLabel = tagDetailsByTagId.label;
                            obj.tagValue = addFormValuesTempValue['value' + count].trim();
                            obj.parent = addFormValuesTempParent['parent' + count].trim();
                            submitArr.push(obj);
                            submitFlag = true;
                        }
                        else {
                            setAlertMessage(translator['Value and Dependent on are mandatory.']);
                            break;
                        }
                    }
                    else {
                        let obj = {};
                        obj.tagLabel = tagDetailsByTagId.label;
                        obj.tagValue = addFormValuesTempValue['value' + count].trim();
                        submitArr.push(obj);
                        submitFlag = true;
                    }
                }
            }
            if (count === lastCount) {
                if (!(addFormValuesTempValue['value' + count]) || (addFormValuesTempValue['value' + count] && addFormValuesTempValue['value' + count].trim() == '')) {
                    if (hasParentTag) {
                        if (addFormValuesTempParent['parent' + count] && addFormValuesTempParent['parent' + count].trim() != '') {
                            setAlertMessage(translator['Value and Dependent on are mandatory.']);
                            break;
                        }
                        else {
                            submitFlag = true;
                        }
                    }
                    else {
                        submitFlag = true;
                    }
                }
                else if (addFormValuesTempValue['value' + count].trim() != '') {
                    if (hasParentTag) {
                        if (addFormValuesTempParent['parent' + count] && addFormValuesTempParent['parent' + count].trim() != '') {
                            let obj = {};
                            obj.tagLabel = tagDetailsByTagId.label;
                            obj.tagValue = addFormValuesTempValue['value' + count].trim();
                            obj.parent = addFormValuesTempParent['parent' + count].trim();
                            submitArr.push(obj);
                            submitFlag = true;
                        }
                        else {
                            setAlertMessage(translator['Value and Dependent on are mandatory.']);
                            break;
                        }
                    }
                    else {
                        let obj = {};
                        obj.tagLabel = tagDetailsByTagId.label;
                        obj.tagValue = addFormValuesTempValue['value' + count].trim();
                        submitArr.push(obj);
                        submitFlag = true;
                    }
                }
            }
        }
        if (submitFlag == true) {
            setAlertMessage('');
            let strObj = {};
            strObj.tagId = tagDetailsByTagId.tagId;
            strObj = JSON.stringify(strObj);
            setCreateUpdateLoader(true);
            axios.post('/api/createTagMemberData', submitArr, { headers: { query: strObj } })
                .then(function (response) {
                    if (response.status == 201) {
                        dispatch(loadTagMemberDependencyData(props.tagId));
                        setCreateUpdateLoader(false);
                        setAddFormValuesTempValue({});
                        setAddFormValuesTempParent({});
                        setRowCountAdd([0]);
                        setToggleView('list');
                        setSearchBy('tag_value')
                        setSearchByVal(''); 
                        dispatch(loadTagMemberDependencyData(props.tagId, 1, 10, '', '', ''));
                    }
                }).catch(function (error) {
                    setCreateUpdateLoader(false);
                });
        }
    }

    function onChangeEditFormValue(val, key, count) {
        let obj = { ...editFormValuesTempValue };
        obj[key] = val;
        setEditFormValuesTempValue(obj);
        let itemId = editFormValuesTempId['id' + count];
        let updateArr = [...editValuesSubmitData];
        let flag = false;
        updateArr.forEach((item) => {
            if (item.id == itemId) {
                item.tagValue = val.trim();
                flag = true;
            }
        });
        if (flag == false) {
            let updateObj = {};
            updateObj.id = itemId;
            updateObj.tagValue = val.trim();
            if (hasParentTag) {
                updateObj.parent = editFormValuesTempParent['parent' + count].trim();
            }
            updateArr.push(updateObj);
        }
        setEditValuesSubmitData(updateArr);
    }

    function onChangeEditFormParent(val, key, count) {
        let obj = { ...editFormValuesTempParent };
        obj[key] = val;
        setEditFormValuesTempParent(obj);
        let itemId = editFormValuesTempId['id' + count];
        let updateArr = [...editValuesSubmitData];
        let flag = false;
        updateArr.forEach((item) => {
            if (item.id == itemId) {
                item.parent = val.trim();
                flag = true;
            }
        });
        if (flag == false) {
            let updateObj = {};
            updateObj.id = itemId;
            updateObj.tagValue = editFormValuesTempValue['value' + count].trim();
            updateObj.parent = val.trim();
            updateArr.push(updateObj);
        }
        setEditValuesSubmitData(updateArr);
    }

    function loadEditValueForm() {
        return rowCountEdit.map((count, i) => {
            return <tr key={i}>
                <td><Form.Control type="text" value={editFormValuesTempValue['value' + count] ?? ''} onChange={(event) => onChangeEditFormValue(event.target.value, 'value' + count, count)} /></td>
                {hasParentTag ? <td>
                    <Form.Select componentClass="select" value={editFormValuesTempParent['parent' + count] ?? ''} onChange={(event) => onChangeEditFormParent(event.target.value, 'parent' + count, count)} >
                        <option value="">select</option>
                        {memberDependencyParentData.map((item) => <option value={item.tag_value}>{item.tag_value}</option>)}
                    </Form.Select>
                </td> : ''}
            </tr>;
        });
    }

    function updateMemberDependencyData() {
        if (editValuesSubmitData.length == 0) {
            setAlertMessage(translator['Please update atleast one record.']);
            return false;
        }
        else {
            let valueFlag = false;
            let parentFlag = false;
            editValuesSubmitData.forEach((item) => {
                if (item.tagValue.trim() == '') {
                    valueFlag = true;
                }
                if (hasParentTag && item.parent.trim() == '') {
                    parentFlag = true;
                }
            })
            if (valueFlag == true) {
                setAlertMessage(translator['Cannot save blank values. Values can be deleted from list view.']);
                return false;
            }
            if (parentFlag == true) {
                setAlertMessage(translator['Dependent on cannot be blank.']);
                return false;
            }
            setAlertMessage('');
            setCreateUpdateLoader(true);
            axios.patch('/api/updateTagMemberData', editValuesSubmitData)
                .then(function (response) {
                    if (response.status == 201) {
                        dispatch(loadTagMemberDependencyData(props.tagId, pageNumber, pageSize));
                        setEditValuesSubmitData([]);
                        setCreateUpdateLoader(false);
                        setRowCountEdit([]);
                        setToggleView('list');
                        setSearchBy('tag_value')
                        setSearchByVal(''); 
                        dispatch(loadTagMemberDependencyData(props.tagId, 1, 10, '', '', ''));
                    }
                }).catch(function (error) {
                    setCreateUpdateLoader(false);
                });
        }
    }

    const tooltip = (
        <Popover id="popover-positioned-top" className="padding-10" title="Popover top">
            {translator['Specify Tooltip']}
        </Popover>
    );

    if (toggleView == 'list') {
        if (foundation_tags_loader_reducer.showTagsLoader) {
            return <ListLoader />;
        }
        else {
            if (tagDetailsByTagId?.tagType == 'Composite') {
                return (
                    <div className="stickyArea rBoxStyle">
                        <Row>
                            <Col xs={12}>
                                <div className="rPageHeadActBtn" style={{ "marginTop": "-3px" }}>
                                    <ul>
                                        <li>
                                            <Button bsStyle="" bsClass="" bsPrefix=" " className="myBt" onClick={() => { dispatch(createMemberTagInitialDetails(tagDetailsByTagId)); navigate("/createCompositeMemberAttribute"); }} title={"Add Member"} >
                                                <HiPlus />
                                            </Button>
                                        </li>
                                        <li className="d-md-none">
                                            <button
                                                type="button"
                                                title="Minimize the right panel"
                                                bsClass=""
                                                bsStyle=""
                                                className="closerightPanelBtn"
                                                onClick={() => {
                                                    props.rightEditPanel(false);
                                                }}
                                            >
                                                <IoClose />
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{translator['Member Attributes']}</div></div>
                            </Col>
                            <Col xs={12}>
                                <div className="rBoxGap">
                                    <div className="f-size-12">
                                        <Table responsive striped bordered condensed hover className='f-size-13'>
                                            <thead>
                                                <tr className='f-size-12'>
                                                    <th>{translator['Member']}</th>
                                                    <th>{translator['Dependent on']}</th>
                                                    <th>{translator['Enabled']}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    foundation_tags_loader_reducer.showMemberDependencyLoader ?
                                                        <tr><td colSpan="3">
                                                            <ListLoader />
                                                        </td></tr>
                                                        :
                                                        renderMemberTagsList(memberTagsForComposite)
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                );
            }
            else if (tagDetailsByTagId?.tagType == 'Checklist') {
                return (
                    <div className="stickyArea rBoxStyle">
                        <Row>
                            <Col xs={12}>
                                <div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
                                    <ul>
                                        <li>
                                            <Button bsStyle="" bsClass="" bsPrefix=" " className="myBt" onClick={() => { dispatch(createChecklistMemberTagInitialDetails(tagDetailsByTagId)); navigate("/createChecklistMemberAttribute"); }} title={"Add Checklist Member"} >
                                                <HiPlus />
                                            </Button>
                                        </li>
                                        <li className="d-md-none">
                                            <button
                                                type="button"
                                                title="Minimize the right panel"
                                                bsClass=""
                                                bsStyle=""
                                                className="closerightPanelBtn"
                                                onClick={() => {
                                                    props.rightEditPanel(false);
                                                }}
                                            >
                                                <IoClose />
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{translator['Member Attributes']}</div></div>
                            </Col>
                            <Col xs={12}>
                                <div className="rBoxGap">
                                    <div className="f-size-12">
                                        <Table responsive striped bordered condensed hover className='f-size-13'>
                                            <thead>
                                                <tr className='f-size-12'>
                                                    <th>{translator['Member']}</th>
                                                    <th>{translator['Type']}</th>
                                                    <th>{translator['Enabled']}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    foundation_tags_loader_reducer.showMemberDependencyLoader ?
                                                        <tr><td colSpan="3">
                                                            <ListLoader />
                                                        </td></tr>
                                                        :
                                                        renderChecklistMemberTagsList(memberTagsForChecklist)
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                );
            }
            else if (tagDetailsByTagId?.tagType == 'Simple' && tagDetailsByTagId.tagDataValidation != 'Dropdown' && tagDetailsByTagId.tagDataValidation != 'Typeahead') {
                return null;
            }
            else {
                return (
                    <div className="stickyArea rBoxStyle">
                        <Row>
                            <Col xs={12}>
                                <div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
                                    <ul>
                                        <li>
                                            <Button bsStyle="" bsClass="" bsPrefix=" " className="myBt" onClick={onSpecifyValuesAddClick} title={translator["Add"]} >
                                                <HiPlus />
                                            </Button>
                                        </li>
                                        <li>
                                            {memberDependencyData.length != 0 ? <Button bsStyle="" bsClass="" bsPrefix=" " className="myBt" onClick={onSpecifyValuesEditClick} disabled={foundation_tags_loader_reducer.showMemberDependencyLoader} title={translator["Edit"]} >
                                                <PiPencilSimpleBold />
                                            </Button> : ''}
                                        </li>
                                        <li className="d-md-none">
                                            <button
                                                type="button"
                                                title="Minimize the right panel"
                                                bsClass=""
                                                bsStyle=""
                                                className="closerightPanelBtn"
                                                onClick={() => {
                                                    props.rightEditPanel(false);
                                                }}
                                            >
                                                <IoClose />
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{translator['Specify Values']}
                                    <OverlayTrigger trigger={['hover', 'focus']} rootClose placement="bottom" overlay={tooltip}>
                                        <span><AiOutlineQuestionCircle className="questionSvg" style={{ fontSize: "18px" }}/></span>
                                    </OverlayTrigger>
                                </div></div>
                            </Col>
                        </Row>
                        <div className="rBoxGap">
                            <Row className="margin-b-5">
                                <Col lg={8} md={12} sm={8} xs={12}>
                                    <div style={{ width: '100%'}} className="form-inline rightSideFields valurdrpdown  d-lg-flex d-md-block d-sm-flex d-block">
                                        <Form.Select bsPrefix=' ' onMouseOver={sortHover} title={sortByTitle} className="myControl mySel mySelectWidth form-control" onChange={queryBySelect}>
                                            {hasParentTag?
                                                <>
                                                    <option value="tag_value">Value</option>
                                                    <option value="parent">Dependent on</option>
                                                </> :
                                                <>
                                                    <option value="tag_value">Value</option>
                                                </>}
                                        </Form.Select>
                                        <div className="d-flex sbAttr">
                                        <Form.Control onKeyPress={handleKeyPress} className="myControl myInput myInputWidth" type="text" onChange={onTextChange} value={searchByVal} />
                                        <Button title="Search" onClick={onSearchClick} className="myBtn btn btn-default"><IoSearch /></Button>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={4} md={12} sm={4} xs={12} className="text-r rightSideFields">
                                    <Form.Label className="margin-r-5 display-inline-block widthAuto">
                                        {translator["Show"]}
                                    </Form.Label>
                                    <Form.Select onChange={onPageSizeChange} className="display-inline-block widthAuto">
                                        <option value="10" selected={pageSize == 10 ? true : false}> 10 </option>
                                        <option value="25" selected={pageSize == 25 ? true : false}> 25 </option>
                                        <option value="50" selected={pageSize == 50 ? true : false}> 50 </option>
                                        <option value="100" selected={pageSize == 100 ? true : false}> 100 </option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            <div className="f-size-12">
                                <Table bordered condensed hover responsive className="tableView tableWithOutPointer">
                                    <thead>
                                        <tr>
                                            <th className="sort">{translator["Value"]}</th>
                                            {hasParentTag ?
                                                <>
                                                    <th width="38%" className="sort">{translator["Dependent on"]}</th>
                                                    <th width="20%" className="text-c">{translator["Action"]}</th>
                                                </>
                                                : <th className="text-c">{translator["Action"]}</th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            foundation_tags_loader_reducer.showMemberDependencyLoader ?
                                                <tr><td colSpan="3">
                                                    <ListLoader />
                                                </td></tr>
                                                :
                                                renderList(memberDependencyData)
                                        }
                                    </tbody>
                                </Table>
                            </div>
                            <div className="nBotPagina">
                                <nav aria-label="Pagination" className="display-inline-block">
                                    {memberDependencyMeta && memberDependencyMeta.currentPage ? (
                                        <Pagination
                                            activePage={parseInt(memberDependencyMeta.currentPage, 10)}
                                            itemsCountPerPage={pageSize}
                                            totalItemsCount={memberDependencyMeta.rowCount}
                                            onChange={onPageChange}
                                        />
                                    ) : null}
                                </nav>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }
    else if (toggleView == 'add') {
        return (
            <div className="stickyArea rBoxStyle">
                <Row>
                    <Col xs={12}>
                        <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
                            <ul>
                                <li className="">
                                    <button
                                        type="button"
                                        title="Minimize the right panel"
                                        bsClass=""
                                        bsStyle=""
                                        className="closerightPanelBtn"
                                        onClick={() => {
                                            props.rightEditPanel(false);
                                        }}
                                    >
                                        <IoClose />
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{translator['Specify Values']}</div></div>
                    </Col>
                </Row>
                <div className="rBoxGap">
                    <div>
                        {alertMessage != '' ? <Alert style={{ "marginBottom": "15px", "fontSize": "13px", "padding": "8px 30px 8px 8px" }} variant="danger"><h4>{alertMessage}</h4></Alert> : ''}
                        <Table>
                            <thead>
                                <tr>
                                    <th className="sort">{translator["Value"]}</th>
                                    {hasParentTag ? <th width="40%" className="sort">{translator["Dependent on"]}</th> : ''}
                                    <th className="text-c">{translator["Action"]}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loadAddValueForm()}
                            </tbody>
                        </Table>
                    </div>
                    <ButtonToolbar className="black margin-t-10">
                        <Button className='rgSidrkBtn' title="Submit" bsSize="small" bsStyle="primary" disabled={createUpdateLoader} onClick={createMemberDependencyData} >
                            {createUpdateLoader ? <ImSpinner6 className="icn-spinner"/> : null} {translator['Submit']}
                        </Button>
                        <Button className='rgSiLigBtn' title="Close" bsSize="small" bsStyle="text" onClick={() => { setToggleView('list'); setAlertMessage(''); setRowCountAdd([0]); setSearchBy('tag_value'); setSearchByVal(''); dispatch(loadTagMemberDependencyData(props.tagId, 1, 10, '', '', '')); }}>{translator['Close']}</Button>
                    </ButtonToolbar>
                </div>
            </div>
        );
    }
    else if (toggleView == 'edit') {
        return (
            <div className="stickyArea rBoxStyle">
                <Row>
                    <Col xs={12}>
                        <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
                            <ul>
                                <li className="">
                                    <button
                                        type="button"
                                        title="Minimize the right panel"
                                        bsClass=""
                                        bsStyle=""
                                        className="closerightPanelBtn"
                                        onClick={() => {
                                            props.rightEditPanel(false);
                                        }}
                                    >
                                        <IoClose />
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{translator['Specify Values']}
                            <OverlayTrigger trigger={['hover', 'focus']} rootClose placement="bottom" overlay={tooltip}>
                                <span><AiOutlineQuestionCircle className="questionSvg" style={{ fontSize: "18px" }}/></span>
                            </OverlayTrigger>
                        </div></div>
                    </Col>
                </Row>
                <div className="rBoxGap">
                    {alertMessage != '' ? <Alert style={{ "marginBottom": "15px", "fontSize": "13px", "padding": "8px 30px 8px 8px" }} variant="danger"><h4>{alertMessage}</h4></Alert> : ''}
                    <Row>
                        <Col lg={8} md={12} sm={8} xs={12}></Col>
                        <Col lg={4} md={12} sm={4} xs={12} className="text-r rightSideFields">
                            <Form.Label className="margin-r-5 display-inline-block widthAuto">
                                {translator["Show"]}
                            </Form.Label>
                            <Form.Select onChange={onPageSizeChange} className="display-inline-block widthAuto">
                                <option value="10" selected={pageSize == 10 ? true : false}> 10 </option>
                                <option value="25" selected={pageSize == 25 ? true : false}> 25 </option>
                                <option value="50" selected={pageSize == 50 ? true : false}> 50 </option>
                                <option value="100" selected={pageSize == 100 ? true : false}> 100 </option>
                            </Form.Select>
                        </Col>
                    </Row>
                    <Table>
                        <thead>
                            <tr>
                                <th className="sort">{translator["Value"]}</th>
                                {hasParentTag ? <th width="50%" className="sort">{translator["Dependent on"]}</th> : ''}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                foundation_tags_loader_reducer.showMemberDependencyLoader ?
                                    <tr><td colSpan="3">
                                        <ListLoader />
                                    </td></tr>
                                    :
                                    loadEditValueForm()
                            }
                        </tbody>
                    </Table>
                    <div className="nBotPagina">
                        <nav aria-label="Pagination" className="display-inline-block">
                            {memberDependencyMeta ? (
                                <Pagination
                                    activePage={memberDependencyMeta ? parseInt(memberDependencyMeta.currentPage, 10) : 1}
                                    itemsCountPerPage={pageSize}
                                    totalItemsCount={memberDependencyMeta ? memberDependencyMeta.rowCount : 1}
                                    onChange={onPageChange}
                                />
                            ) : null}
                        </nav>
                    </div>
                    <ButtonToolbar className="black margin-t-10">
                        <Button className='rgSidrkBtn' title="Submit" bsSize="small" bsStyle="primary" disabled={createUpdateLoader} onClick={updateMemberDependencyData} >
                            {createUpdateLoader ? <ImSpinner6 className="icn-spinner"/> : null} {translator['Submit']}
                        </Button>
                        <Button className='rgSiLigBtn' title="Close" bsSize="small" bsStyle="text" onClick={() => { setToggleView('list'); setAlertMessage(''); setRowCountEdit([]); setEditFormValuesTempValue({}); setEditFormValuesTempParent({}); setEditValuesSubmitData([]); setSearchByVal(''); dispatch(loadTagMemberDependencyData(props.tagId, 1, 10, '', '', '')); }}>{translator['Close']}</Button>
                    </ButtonToolbar>
                </div>
            </div>
        );
    }
}

export default ValuesPart;