
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState, useEffect } from "react";
import moment from "moment";
import { Field, ErrorMessage, useField } from "formik";
import { Typeahead } from "react-bootstrap-typeahead";
import Datetime from "react-datetime";
import Cookies from "universal-cookie";

const cookies = new Cookies();

let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));

dateformat = dateformat.split("~");
dateformat = dateformat[2];

export const InputTextField = ({ label, name, variant, ...props }) => {
  const [field, meta] = useField(name);

  return (
    <>
      <div className="form-group">
        <label htmlFor={name}>
          {label} {props.required && <span className="rStar"></span>}&nbsp;
        </label>
        <Field
          {...field}
          type="text"
          className={`form-control ${
            meta.touched && meta.error ? "is-invalid" : ""
          }`}
          {...props}
        />
        <ErrorMessage
          name={name}
          component="span"
          className="invalid-feedback text-right"
        />
      </div>
    </>
  );
};

export function SelectField({ name, label, required, children, ...props }) {
  const [field, meta] = useField(name);
  const showError = meta.touched && !!meta.error;

  return (
    <div className="form-group">
      <label htmlFor={name}>
        {label} {required && <span className="rStar"></span>}&nbsp;
      </label>
      <Field
        {...field}
        {...props}
        as="select"
        className={`form-control ${
          meta.touched && meta.error ? "is-invalid" : ""
        }`}
      >
        <option value="">Select</option>
        {children}
      </Field>

      <ErrorMessage name={name} component="div" className="invalid-feedback" />
    </div>
  );
}

export function SelectFiel1111({
  name,
  label,
  required,
  mandatory,
  children,
  ...props
}) {
  const [field, meta] = useField(props);
  const showError = meta.touched && !!meta.error;

  return (
    <>
      <div className="form-group">
        <label
          className={
            meta.touched && meta.error?.name
              ? "select-label is-invalid"
              : "select-label"
          }
        >
          {label} {required && <span className="rStar"></span>}
        </label>
        <Field
          className={`select-text form-control`}
          {...field}
          {...props}
          as="select"
        >
          <option value=""></option>
          {children}
        </Field>
        {showError && (
          <ErrorMessage
            name={name}
            component="span"
            className="invalid-feedback text-right"
          />
        )}
      </div>
    </>
  );
}

export const DatePickerF = ({ label, name, required, ...props }) => {
  const [field, meta] = useField(name);
  const showError = meta.touched && !!meta.error;
  const jwtdf = dateformat?.slice(0, 10).toUpperCase();

  return (
    <div className="dateTimeSdiv dateTimePosTop">
      <label className={`select-label ${showError ? "is-invalid" : ""}`}>
        {label} {required && <span className="rStar"></span>}
      </label>
      <Datetime
        {...props}
        dateFormat={jwtdf}
        timeFormat={true}
        inputProps={{ className: "form-control" }}
      />
      {showError && <ErrorMessage name={name} component="span" />}
    </div>
  );
};

export const InputTextFieldNumber = ({ label, name, variant, ...props }) => {
  const [field, meta] = useField(name);
  const showError = meta.touched && !!meta.error;
  return (
    <>
      <div className="form-group">
        {label && (
          <label htmlFor={name}>
            {label} {props.required && <span className="rStar"></span>}&nbsp;
          </label>
        )}
        <Field
          {...field}
          type="number"
          className={`form-control ${
            meta.touched && meta.error ? "is-invalid" : ""
          }`}
          {...props}
        />
        {showError && (
          <ErrorMessage
            name={name}
            component="span"
            className="invalid-feedback text-right"
          />
        )}
      </div>
    </>
  );
};

export const InputTextFieldForChips = ({ label, name, variant, ...props }) => {
  const [field, meta] = useField(name);
  const showError = meta.touched && !!meta.error;

  return (
    <div className="form-group">
      {label && (
        <label htmlFor={name}>
          {label} {props.required && <span className="rStar"></span>}&nbsp;
        </label>
      )}
      <Typeahead
        name={name}
        {...props}
        id={`${name}-custom-selections`}
        isInvalid={showError}
      />
      <ErrorMessage
        name={name}
        component="span"
        className="invalid-feedback text-right"
      />
    </div>
  );
};

export function StandardTextArea({
  label,
  required,
  mrtop,
  mrbtm,
  characterCount,
  characterLimit,
  ...props
}) {
  const [field, meta, helpers] = useField(props);
  const showError = meta.touched && !!meta.error;

  const [cstate, setCstate] = useState(false);
  const characterLength = characterLimit ? characterLimit : 500;
  const { value } = meta;
  const { setValue } = helpers;

  const handleFocus = () => {
    if (value?.length >= 0) {
      setCstate(true);
    }
  };

  const handleBlur = () => {
    if (value?.length === 0) {
      setCstate(false);
    }
  };

  useEffect(() => {
    if (value?.length > 0) {
      setCstate(true);
    }
  }, [value]);

  return (
    <div
      className="mt-20 position-relative"
      style={{ marginTop: mrtop, marginBottom: mrbtm }}
    >
      {cstate && characterCount && value.length >= 0 && (
        <span className="descCount">
          ({characterLength - value.length} Characters left)
        </span>
      )}
      <label className={`select-label ${showError ? "is-invalid" : ""}`}>
        {label} {required && <span className="rStar"></span>}
      </label>
      <textarea
        className={`form-control ${showError ? "is-invalid" : ""}`}
        {...field}
        {...props}
        rows={4}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onFocus={handleFocus}
        onBlur={handleBlur}
      ></textarea>
      <ErrorMessage
        name={props?.name}
        component="span"
        className="invalid-feedback text-right"
      />
    </div>
  );
}
