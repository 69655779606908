/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/
import React, { useEffect, useState } from "react";
import { Alert, Button, ButtonToolbar, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import ForumDropdownPlusTypeahead from "../../Rules-query/common/ForumDropdownPlusTypeahead";
import { useQuery } from "@apollo/client";
import { GET_FORMS } from "../../uiBuilder/graphQl/GraphqlQueries";
import { useSaveFormMutation } from "../../../reducers/smartForm/smartFormApi";
import { ImSpinner6 } from "react-icons/im";

const EditSmartForm = (props) => {
  const [errorClass, setErrorClass] = useState("");
  const [selectedMultiValueList, setSelectedMultiValueList] = useState([]);
  const [options, setOptions] = useState([]);
  const initialValues = {
    form_id: "",
    form_name: "",
  };

  const validationSchema = Yup.object().shape({
    form_id: Yup.string().required("Required"),
    form_name: Yup.string().required("Required"),
  });

  const [saveForm, { isLoading, isError, error: postError }] = useSaveFormMutation();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const spcmReducer = useSelector((state) => state.spcmReducer);
  const dispatch = useDispatch();

  const { loading, error, data, refetch } = useQuery(GET_FORMS, {
    variables: {
      companyId: spcmReducer?.currentlySelectedServiceDetails?.COMPANY_ID || 0,
      limit: parseInt(5000, 10),
      offset: 0,
    },
  });

  const onMultiValueSelection = (selected, setFieldValue) => {
    if (selected.length === 0) {
      setFieldValue("form_name", "");
      setFieldValue("form_id", "");
      setErrorClass("error");
      setSelectedMultiValueList([]);
    } else {
      setErrorClass("");
      setFieldValue("form_name", selected[0]?.label);
      setFieldValue("form_id", selected[0]?.id);
      setSelectedMultiValueList(selected);
    }
  };

  const submitForm = async (values, { errors, setSubmitting }) => {
    setSubmitting(true);
    let payload = [{
      form_id: values.form_id,
      form_name: values.form_name,
      offering_id: spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
      form_description: "test form desc",
    }];
    saveForm(payload)
      .unwrap()
      .then((res) => {
        props.componentFormFun4(false);
        props.setSearchClicked(true);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    if (data?.getAllForms && data?.getAllForms.length > 0) {
      let formOptions = data?.getAllForms.map((item) => {
        return {
          id: item._id,
          label: item.name,
        };
      });
      setOptions(formOptions);
    } else {
      setOptions([]);
    }
  }, [data]);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitForm}
        enableReinitialize={true}
      >
        {({
          errors,
          setFieldValue,
          isSubmitting,
          touched,
        }) => (
          <Form noValidate>
            <div className="borderForm bFormDiv">
              {isError && (
                <Alert variant="danger" className="errorMessageSec">
                  <p>{postError?.data?.[0]?.message || "Something went wrong!"}</p>
                </Alert>
              )}
              <Row>
                <Col md={12}>
                  <label>
                    <span className="rStar"></span>{tr["Select Form"]}
                  </label>
                  <Field
                    id="form_id"
                    component={ForumDropdownPlusTypeahead}
                    onSelection={(selected) =>
                      onMultiValueSelection(selected, setFieldValue)
                    }
                    errorColor={
                      errors?.form_id && touched?.form_id ? "error" : ""
                    }
                    name="form_id"
                    className="form-control"
                    options={options}
                    selected={selectedMultiValueList}
                    labelKey="name"
                    placeHolder={loading ? "Loading..." : "Select Form"}
                    onCrossClick={() =>
                      onMultiValueSelection([], setFieldValue)
                    }
                  />
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <ButtonToolbar className="black mt-3">
                    <Button
                      title={tr["Save"]}
                      disabled={isSubmitting}
                      type="submit"
                      bsSize="small"
                      bsStyle="primary"
                      className="rgSidrkBtn"
                    >
                      {isLoading ? (
                        <ImSpinner6 className="icn-spinner" />
                      ) : null}{" "}
                      {tr["Save"]}
                    </Button>
                    <Button
                      title="Close"
                      className="rgSiLigBtn"
                      onClick={() => {
                        props.componentFormFun4(false);
                        props.setSearchClicked(true);
                      }}
                      bsSize="small"
                      bsStyle="text"
                    >
                      {tr["Close"]}
                    </Button>
                  </ButtonToolbar>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditSmartForm;
