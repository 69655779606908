
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { connect, useDispatch, useSelector } from "react-redux";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import { TypeaheadandDropdown, _number } from "../../../common/formFields";
import {
  _inputField,
  _dropDown,
} from "../../../common/ReduxFormFields/CategoryBoard/formFields";
import { loadOnCallSXMenulistData } from "../../../../actions/onCallSchedule/common/onCallCommonAction";
import useDebounce from "../../../../helpers/useDebounce";
import { IoClose, IoSaveOutline } from "react-icons/io5";
import { ImSpinner6 } from "react-icons/im";
import { validateEmail } from "../../../../utils/common";
import Cookies from "universal-cookie";
import axios from "axios";
import { GLOBAL } from "../../../Globals";
import { useParams } from "react-router";
import {
  loadEscalationPlansLevelDetail,
  loadPlanLevels,
} from "../../../../actions/onCallSchedule/escalationAction";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';
const cookies = new Cookies();
const gph = cookies.get("gph").split("~");
const companyId = typeof gph[48] !== "undefined" ? gph[48] : "";

const AddLevelForm = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const translator = useSelector((state) => state.spcmReducer.tr);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [typedChar, setTypedChar] = useState("");
  const [usersOptions, setUserOptions] = useState([]);
  const [emailIds, setEmailIds] = useState("");
  const [valueError, setValueError] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [escalationTimeoutError, setEscalationTimeoutError] = useState("");
  const [channelError, setChannelError] = useState("");
  const [disableBtn, setDisableBtn] = useState();
  const [typedCharChannel, setTypedCharChannel] = useState("");
  const [escalationLevelOptions,setEscalationLevelOptions] = useState([])
  const [channelOptions, setChannelOptions] = useState([
    { label: "Email", id: 1 },
    { label: "SMS", id: 2 },
  ]);

  const planId = params?.planId;
  const onCallCommonReducer = useSelector(
    (state) => state.on_call_common_reducer
  );

  const escalationPlanLevel = useSelector((state) => state.escalationPlanLevel);
  const escalationPlansLevelDetail = useSelector(
    (state) => state.escalationPlansLevelDetail
  );
  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const saveObjRef = useRef(null);

	useEffect(() => {
		context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
	}, []);


  useEffect(() => {
    if (
      escalationPlansLevelDetail &&
      Object.keys(escalationPlansLevelDetail).length > 0 &&
      props.formType == "edit"
    ) {
      if (escalationPlansLevelDetail.sxEscalationCommChannelList.length > 0) {
        let channels =
          escalationPlansLevelDetail.sxEscalationCommChannelList.map(
            (channel) => {
              return {
                ...channel,
                id: channel.channelId,
                label: channel.channelName,
              };
            }
          );
        setSelectedChannels(channels);
      }

      if (escalationPlansLevelDetail.sxEscalationLevelMemberList.length > 0) {
        let users = escalationPlansLevelDetail.sxEscalationLevelMemberList.map(
          (user) => {
            return {
              ...user,
              id: user.memberId,
              label: user.memberName,
            };
          }
        );
        setSelectedUsers(users);
      } else {
        setSelectedUsers([]);
      }
      addEscLvlOpt(escalationPlansLevelDetail?.escalationLevel);
      dispatch(
        change(
          "AddLevelForm",
          "escalationLevel",
          escalationPlansLevelDetail?.escalationLevel
        )
      );
      dispatch(
        change(
          "AddLevelForm",
          "escalationTimeoutMin",
          escalationPlansLevelDetail?.escalationTimeout
        )
      );
      dispatch(
        change(
          "AddLevelForm",
          "externalEmail",
          escalationPlansLevelDetail?.externalEmail
        )
      );
    }
  }, [escalationPlansLevelDetail]);

  useEffect(() => {
    if (
      escalationPlanLevel &&
      escalationPlanLevel.length > 0 &&
      props.formType == "add"
    ) {
      const level = escalationPlanLevel.length + 1;
      addEscLvlOpt(level);
      dispatch(change("AddLevelForm", "escalationLevel", level));

      dispatch(change("AddLevelForm", "escalationTimeoutMin", ""));
      dispatch(change("AddLevelForm", "externalEmail", ""));
      setSelectedUsers([]);
      setSelectedChannels([]);
    } else {
      addEscLvlOpt(1);
      dispatch(change("AddLevelForm", "escalationLevel", 1));

    }
  }, [escalationPlanLevel]);

  useEffect(() => {
    if (props.formType == "edit") {
      dispatch(loadEscalationPlansLevelDetail(props?.levelId));
    }
  }, []);

  ////////// channel functions
  const onChannelSelection = (selected) => {
    if (selected.length == 0) {
      setChannelError("error");
      setSelectedChannels([]);
    } else {
      setChannelError("");
    }
    setSelectedChannels(selected);
  };

  const onChannelValueChange = (value) => {
    onChannelChange(value);
  };

  const setChannelErrorColor = () => {
    if (selectedChannels.length === 0) {
      setChannelError("error");
    } else {
      setChannelError("");
    }
  };

  const onChannelChange = (value) => {
    setTypedCharChannel(value);
  };

  //// user field functions

  const countCharacters = (e) => {
    if (e.target.value.length > 50) {
      e.preventDefault();
    }
  };

  const onUserValueSelection = (selected) => {
    if (!(Array.isArray(selected) && selected.length)) {
      setValueError("error");
      setUserOptions([]);
    } else {
      setValueError("");
    }
    setSelectedUsers(selected);
  };

  const onUserValueChange = (value) => {
    onUserChange(value);
  };

  const onUserChange = (value) => {
    setTypedChar(value);
    if (value.length > 3) {
      userOptionsApi();
    } else {
      setUserOptions([]);
    }
  };

  const addEscLvlOpt = (level)=>{
    setEscalationLevelOptions([{field1Key:"Escalation Level "+level,field1Value:level}]);
  }
  const userOptionsApi = useDebounce(() => {
    let value = typedChar;
    let consumerDataDetails = [],
      consumerData = [],
      myObj = {},
      strObj = {};
    strObj.like = value;
    strObj.companyId = props.selectedCompany[0].id;
    strObj = JSON.stringify(strObj);
    if (value !== "" && value.length > 3) {
      axios
        .get("/api/userCompany/companyuserlist", { headers: { query: strObj } })
        .then((response) => {
          consumerData = response.data;
          if (Array.isArray(response.data)) {
            for (const element of consumerData) {
              myObj = { value: "", label: "" };
              myObj.id = element.userId;
              myObj.label = element.fullname;
              myObj.name = element.fullname;
              myObj.email = element.email;
              consumerDataDetails.push(myObj);
            }
            consumerDataDetails = Array.from(new Set(consumerDataDetails));
          } else {
            consumerDataDetails = [];
          }
          setUserOptions(consumerDataDetails);
        });
    }
  });

  //// save form
  const emailValidate = (emails) => {
    let error = false;

    if (emails) {
      let email = emails.split(",");
      email.forEach((email) => {
        if (!validateEmail(email)) {
          error = true;
        }
      });
      return error;
    }
  };

  const onChangeHandle = (e) => {
    if (e.target.name == "externalEmail") {
      if (e.target.value) {
        setValueError("");
      } else {
        setValueError("error");
      }
      setEmailIds(e.target.value);
    }

    if (e.target.name == "escalationTimeoutMin") {
      if (e.target.value) {
        setEscalationTimeoutError("");
      } else {
        setEscalationTimeoutError("error");
      }
    }
  };

  const submit = (values) => {
    let error = false;

    if (selectedChannels.length == 0) {
      setChannelError("error");
      error = true;
    }

    if (selectedUsers.length == 0 && !values.externalEmail) {
      setValueError("error");
      error = true;
    }

    if (!values.escalationTimeoutMin || values.escalationTimeoutMin < 0) {
      setEscalationTimeoutError("error");
      error = true;
    } else {
      setEscalationTimeoutError("");
    }

    if (emailValidate(values.externalEmail)) {
      setEmailError("error");
      error = true;
    } else {
      setEmailError("");
    }

    let userJson = [];
    let channelJson = [];
    if (selectedUsers.length > 0) {
      selectedUsers.forEach((user) => {
        userJson.push({
          memberId: user.id,
          memberName: user.label,
          escalationPlanId: planId,
        });
      });
    }

    if (selectedChannels.length > 0) {
      selectedChannels.forEach((channel) => {
        channelJson.push({
          channelId: channel.id,
          channelName: channel.label,
        });
      });
    }

    if (error) {
      return;
    } else {
      setDisableBtn(true);
      let postJson = {
        status: "Active",
        escalationPlanId: params.planId,
        escalationLevel: values.escalationLevel,
        notifyUserIds: userJson,
        notifyGroupIds: [],
        notifyGroupManager: "No",
        escalationTimeout: values.escalationTimeoutMin,
        escalationPlanChannel: channelJson,
        externalEmail: values.externalEmail,
      };

      if (props.formType == "add") {
        axios
          .post("/rest/aiosem/saveEscalationLevelAction", postJson)
          .then((resp) => {
            setDisableBtn(false);
            props.setViewForm(false);
            dispatch(loadPlanLevels(params?.planId));
          })
          .catch((error) => {
            setDisableBtn(false);
          });
      } else if (props.formType == "edit") {
        axios
          .patch(
            "/rest/aiosem/updateEscalationLevelActio/" + props?.levelId,
            postJson
          )
          .then((resp) => {
            setDisableBtn(false);
            props.setViewForm(false);
            dispatch(loadPlanLevels(params?.planId));
          })
          .catch((error) => {
            setDisableBtn(false);
          });
      }
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="escalation-level-form"
    >
      <Modal.Header>
        <Row>
          <Col lg={6} md={6} sm={12} xs={12}>
            <h2 className="sPageHeading1">{translator["Escalation Plan"]}</h2>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <div className="paHedFilter">
              <Link
                bsPrefix=" "
                id="creTag"
                to="javascript:void(0)"
                className="myBt plus"
                disabled={props.submitting}
                onClick={props.handleSubmit(submit)}
                title={translator["Save"]}
                ref={saveObjRef}
              >
                {disableBtn ? (
                  <ImSpinner6 className="icn-spinner" />
                ) : (
                  <IoSaveOutline />
                )}
              </Link>
              <Link
                bsPrefix=" "
                to="javascript:void(0)"
                className="myBt cancel fillBtn"
                title={translator["Cancel"]}
                onClick={() => {
                  const root = document.documentElement;
                  root?.style.setProperty("--modalBackDrop", 0);
                  props.setViewForm(false);
                }}
                ref={closeButton}
              >
                <IoClose />
              </Link>
            </div>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={4}>
            <Form.Group className="form-group">
              <Form.Label bsClass="">
                <span className="rStar"></span>
                {"Level"}
              </Form.Label>
              <Field
                component={_dropDown}
                name="escalationLevel"
                disabled
                options={escalationLevelOptions}
                className="form-control"
              />
            </Form.Group>
          </Col>
          <Col md={4} sm={4} xs={12}>
            <Form.Group className="dvTypehdropdown-group">
              <Form.Label>
                <span className="rStar"></span>
                {translator["Escalation Timeout (in min)"]}
              </Form.Label>
              <div className="minHghtdvTypehd">
                <Field
                  id="hour"
                  name="escalationTimeoutMin"
                  component={_number}
                  className={`form-control ${
                    escalationTimeoutError ? "error" : ""
                  }`}
                  min={0}
                  onChange={(e) => onChangeHandle(e)}
                  placeholder={translator["Escalation Timeout (in min)"]}
                />
              </div>
            </Form.Group>
          </Col>
          <Col md={4} sm={4} xs={12}>
            <Form.Group className="form-group">
              <Form.Label>
                <span className="rStar"></span>
                {translator["Communication Channel"]}
              </Form.Label>
              <div className="heightdvTypehd">
                <Field
                  component={TypeaheadandDropdown}
                  onSelection={onChannelSelection}
                  onInputChange={onChannelValueChange}
                  name="channels"
                  className="form-control"
                  errorClass={channelError === "error" ? "error" : ""}
                  options={channelOptions}
                  selectedOptions={selectedChannels}
                  onCrossClick={() => {
                    setSelectedChannels([]);
                  }}
                  setErrorColor={setChannelErrorColor}
                  multiple={true}
                  placeholder={"Please choose.."}
                ></Field>
              </div>
            </Form.Group>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              <Form.Label>{translator["User(s)"]}</Form.Label>
              <div className="heightdvTypehd">
                <Field
                  component={TypeaheadandDropdown}
                  onSelection={onUserValueSelection}
                  onInputChange={onUserValueChange}
                  name="user"
                  className="form-control"
                  errorClass={valueError === "error" ? "error" : ""}
                  options={usersOptions}
                  selectedOptions={selectedUsers}
                  onCrossClick={() => {
                    setSelectedUsers([]);
                  }}
                  multiple={true}
                  placeholder={"Please choose.."}
                  diableStatus={props.selectedCompany.length === 0}
                ></Field>
              </div>
            </Form.Group>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              <Form.Label>{translator["Emails"]}</Form.Label>
              <Field
                component={_inputField}
                name="externalEmail"
                className={`form-control ${emailError ? "error" : ""}`}
                onChange={(e) => {
                  onChangeHandle(e);
                }}
              ></Field>
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};
const mapStateToProps = (state) => ({
  // shiftListData: state.shiftListData
});
export default reduxForm({
  form: "AddLevelForm",
})(connect(mapStateToProps)(AddLevelForm));
