
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import * as Yup from "yup";
import { Field, Formik, Form } from "formik";
import { TabContent, TabPane } from "react-bootstrap";
import OccurrenceAuditLogs from "./AuditHistory";
import ActivityDetails from "./ActivityDeatils";
import Minutes from "./Minutes";
import Tracking from "./Tracking";
import ActionItems from "../actionItems";
import ReasonComponent from "../../../common/reason";
import {IoClose} from "react-icons/io5";
const OccurrencesRightSide = ({
  meetingDefData,
  activeTabs,
  occurenceData,
  companyId,
  onSubmitReason,
  actionStatus,
  isAuthorized,
}) => {
  return (
    <TabContent activeTab={activeTabs}>
      <TabPane tabId="1" active={activeTabs === "1"}>
        <ActivityDetails occurenceData={occurenceData} companyId={companyId} />
      </TabPane>
      <TabPane tabId="2" active={activeTabs === "2"}>
        <div className="panel-body attachment-body">
          <div className="custom-container-fluid">
            <div className="stickyArea rBoxStyle">
              <div className="rPageHeading d-flex p-3">
                <div className="flex-grow-1 offNam mw-100">Attachment</div>
                <div>
                <IoClose />
                </div>
              </div>

              <div className="p-3 occurnance">Coming soon..</div>
            </div>
          </div>
        </div>
      </TabPane>
      <TabPane tabId="3" active={activeTabs === "3"}>
        <Tracking
          occurenceData={occurenceData}
          companyID={companyId}
          isAuthorized={isAuthorized}
          activeTabs={activeTabs}
        />
      </TabPane>
      <TabPane tabId="4" active={activeTabs === "4"}>
        <ActionItems
          occurenceData={occurenceData}
          companyID={companyId}
          meetingDefData={meetingDefData}
          activeTabs={activeTabs}
        />
      </TabPane>
      <TabPane tabId="5" active={activeTabs === "5"}>
        <Minutes
          occurenceData={occurenceData}
          companyId={companyId}
          isAuthorized={isAuthorized}
          activeTabs={activeTabs}
        />
      </TabPane>
      <TabPane tabId="6" active={activeTabs === "6"}>
        <ReasonComponent module="occurrences" onSubmitReason={onSubmitReason} />
      </TabPane>
    </TabContent>
  );
};

export default OccurrencesRightSide;
