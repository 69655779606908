
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import {Row,Col,Form,ButtonToolbar,Button} from 'react-bootstrap';

class TextFieldForm extends React.Component {	
	render(){
		return(
			<div>
				<div className="rPageHeading">Translate - {this.props.fieldNameVal2} - {this.props.lanNameVal2} </div>

				<div className="rBoxGap">
					<Row>
					<Col md={12}>
						<Form.Group className="form-group" >
							<Form.Label bsClass="">Base Language</Form.Label>
							<Form.Control disabled value="English" type="text" />
						</Form.Group>
					</Col>	
					<Col md={12}>
						<Form.Group className="form-group" >
							<Form.Label bsClass="">Category/Sub-Category</Form.Label>
							<Form.Control disabled value="Information Technology/Cloud"  type="text" />
						</Form.Group>
					</Col>
					<Col md={12}>
						<Form.Group className="form-group">
							<Form.Label bsClass=""><span className="rStar"></span> Select Category/Sub-Category {/*<Button className="margin-l-10 margin-b-5" title="Google Translate" bsSize="xsmall"><i className="fa fa-google"></i> Translate</Button>*/}</Form.Label>
							<Form.Select componentClass="select">
								<option>P1</option>
								<option>P2 </option>
								<option>P3</option>
								<option>P4</option>
							</Form.Select>
							</Form.Group>
					</Col>
					</Row>
					<Row>
						<Col md={12}>
							<ButtonToolbar className="black">
								<Button className='rgSidrkBtn' onClick={()=>{this.props.lanEditPageFun5(false)}} bsSize="small" bsStyle="primary">Save</Button>
								<Button className='rgSiLigBtn' onClick={()=>{this.props.lanEditPageFun5(false)}} bsSize="small" bsStyle="text">Close</Button></ButtonToolbar>
						</Col>	
					</Row>
				</div>
			</div>
		);
	}
}

export default TextFieldForm;