
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { connect } from "react-redux";
import BasicTable from "../../common/ReactTable/BasicTable";
import {IoClose} from "react-icons/io5";

class CMDBExternalSystemAttributes extends React.Component {
  constructor(props) {
    super(props);
    this.renderExternalSystemAttributeHTML =
    this.renderExternalSystemAttributeHTML.bind(this);

   
  }

  componentDidMount() {
    console.log("Inside CMDB External---",this.props.CIEditDetails);
   
  }
  
   
  renderExternalSystemAttributeHTML(list) {
    
    if (this.props.CIEditDetails.CLIENT_NAME!== "null" && this.props.CIEditDetails.CLIENT_CI_NUMBER!== "null"){ 
      console.log("enteredddddd");
      let result = [list];
     
		  const tableColumns = [    
      {
			  name: "Client Name",
			  selector: (row) => row.CLIENT_NAME,
			  sortable: true,
			},
			{
			  name: "Attribute",
			  selector: (row) => "clientCiNumber",
			  sortable: true,
			},
			{
			  name: "Value",
			  selector: (row) => row.CLIENT_CI_NUMBER,
			  sortable: true,
			},
		  ];
      return (
        <div>
          <div className="knowledgeDiv">
		  <BasicTable
              tableColumns={tableColumns}
              data={result}
              pagination={true}
              onRowSelect={(row) => {}}
            />
          </div>
        </div>
      );
    }else  if ((this.props.CIEditDetails.CLIENT_NAME== "null" && this.props.CIEditDetails.CLIENT_CI_NUMBER == "null")||this.props.CIEditDetails.CLIENT_NAME== null && this.props.CIEditDetails.CLIENT_CI_NUMBER == null){ 
      console.log("entered123");
      
      let result = [list];
     
		  const tableColumns = [    
      {
			  name: "Client Name",
			  selector: (row) => "",
			  sortable: true,
			},
			{
			  name: "Attribute",
			  selector: (row) => "clientCiNumber",
			  sortable: true,
			},
			{
			  name: "Value",
			  selector: (row) => "",
			  sortable: true,
			},
		  ];
      return (
        <div>
          <div className="knowledgeDiv">
		  <BasicTable
              tableColumns={tableColumns}
              data={result}
              pagination={true}
              onRowSelect={(row) => {}}
            />
          </div>
        </div>
      );
    }
    
    else {
      return <div>{this.props.translator["No Data Available"]}</div>;
    }
  
  }

  render() {
   
  
    return (
        <div>
          <div>
            <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
              <ul>
                <li>
                <button
                    type='button'
                    title="Minimize the right panel"
                    bsClass=""
                    bsStyle=""
                    className="closerightPanelBtn"
                    onClick={() => {
                    this.props.rightEditPanel(false);
                    }}
                >
                    <IoClose/>
                </button>
                </li>
              </ul>
            </div>
            <div className="rPageHeading">
              <div className="offNam margin-t-5 margin-b-5">
                {this.props.translator["External Attributes"]}
              </div>
            </div>
            <div className="rBoxGap">
              {this.renderExternalSystemAttributeHTML(
                this.props.CIEditDetails
              )}
            </div>
          </div>
        </div>
      );
  }
}

// export default BreakFix;
const mapStateToProps = ({
    externalSystemAttributesList,
    isFetchingDetails,
  }) => {
    return {
      externalSystemAttributesList,
      isFetchingDetails,
    };
  };
  
  export default connect(mapStateToProps, {})(CMDBExternalSystemAttributes);
