
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';

export function loadExternalLinkActionSuccess(externalLinks){
	return {
        type: 'LOAD_EXTERNAL_LINK',
        externalLinks
	};
}


export function loadExternalLink(){
	const url="/api/CompanyLinkDetails"
	return(dispatch) =>{
		axios.get(url)
			.then((externalLinks) =>{
				dispatch(loadExternalLinkActionSuccess(externalLinks))
			})
			.catch((err) => {
				console.log("error",err);
			});
	};
}