
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from 'react';
import { Table, Form, ButtonToolbar, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Field, change, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { TypeaheadandDropdown, _inputField } from '../../../../common/formFields';
import TableComponent from '../../../../common/ReactTable/TableComponent';
import {getUserGroups} from "../../../../../actions/foundation/userAction";
import { useParams } from "react-router";
import axios from "axios";
import Swal from 'sweetalert2';
const api = axios.create({
    headers: { Pragma: "no-cache", "Cache-control": "no-store" },
  });

const GroupList = (props) => {
    const tr = useSelector((state) => state.spcmReducer.tr);
    const [groupError, setGroupError] = useState("");
    const [groupData, setGroupData] = useState([])
    const [associatedGroupValue, setAssociatedGroupValue] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);
    const groupDataList = useSelector((state) => state.userGroupsRed);

    const dispatch = useDispatch();
    const params = useParams();
    let userId = params.id;
    let { handleSubmit } = props;
    let isValid = true;

    useEffect(() => {
            // dispatch(getUserGroups(userId)); 
          
     if(props.companyType && props.companyType.length>0){
        dispatch(getUserGroups({
            "companyId": props.companyType[0].id,
            "userId": userId
        }));      
     }            
    }, [props.companyType])
    useEffect(() => {
        axios.get("/api/viewAssociatedGroupMemberViaAjaxForUser/"+userId)
        .then((resp) => {
            if (resp.status == 200) {
               
                setSelectedGroup([]);
            }
        setAssociatedGroupValue(resp.data);
    })     
    }, [])
    
    useEffect(() =>{
      if(groupDataList && groupDataList.length > 0){
        setGroupData(groupDataList);
      }
      else{
        setGroupData([]);
      }      
    }, [groupDataList])

    const saveAssociateGroups = () => {     
        
        if (selectedGroup.length === 0) {
             setGroupError("error")
             isValid = false;
         }

         let groups = []
         let payload = {}
         selectedGroup.forEach((values) => {

           let val = {
              groupId: values.id,
              userId : userId
             }
             groups.push(val);
           })
        payload = {
            userMemberList: groups
        }

        if(isValid === true){
            axios
            .post("/api/saveAddAssociateGroupsModalDataForUser", payload)
            .then((response) => {
              axios.get("/api/viewAssociatedGroupMemberViaAjaxForUser/"+userId)
                  .then((resp) => {
                      if (resp.status == 200) {
                         
                          setSelectedGroup([]);
                      }
                  setAssociatedGroupValue(resp.data);
                //   dispatch(getUserGroups({
                //     "companyId": props.companyType,
                //     "userId": userId
                // }));   
                dispatch(getUserGroups({
                    "companyId": props.companyType[0].id,
                    "userId": userId
                })); 
              })
            })
        }
       
    };
    const CellName = (props) => {
        let item = props.row;
        return (
        <div className="text-c">
            <i title="Disassociate" onClick={()=>{
            disassociateGroupMembers(item)
            }} className="fa fa-chain-broken"></i>
        </div>
        )
    };

    const disassociateGroupMembers = (item) => {
        let payload =
        {
            userMemberList:[{
                userId: userId,
                groupId: item.groupId.toString()
            }]
        }
        Swal.fire({
            title: ["Are you sure you want to disassociate this member?"],
            width: 550,
            padding: '15px',
            showDenyButton: true,
            confirmButtonText: tr['Yes'],
            denyButtonText: tr['No'],
          }).then((result) => {
            if (result.isConfirmed) {
                axios.post("/api/deleteGroupAssociationViaAjaxForUser",payload).then((resp) => {
                    axios.get("/api/viewAssociatedGroupMemberViaAjaxForUser/"+userId)
                    .then((resp) => {
                        if (resp.status == 200) {
                           
                            setSelectedGroup([]);
                        }
                    setAssociatedGroupValue(resp.data);
                    // dispatch(getUserGroups({
                    //     "companyId": "3215",
                    //     "userId": "13354"
                    // }));
                    dispatch(getUserGroups({
                        "companyId": props.companyType[0].id,
                        "userId": userId
                    }));  
                })
                      })
            } 
          })
    }

  
    const onGroupSelection = (selectedGroupData) => {
        if (selectedGroupData.length > 0) { 
            setSelectedGroup(selectedGroupData)
            dispatch(change('GroupList', 'groupId', selectedGroupData))
            setGroupError("")
          } else {
            setSelectedGroup([]);
          }
    }
 
    const onCrossClickGroup = () => {
        setSelectedGroup([]);
        setGroupError("");
        isValid = false;
    }
    const onRowSelect = () => {
        
    }
    const tableColumns = [
        {
            name: "ID",
            selector: (row) => row.groupId,
            sortable: true,
        },
        {
            name: "Name",
            cell: (row) => row.groupName,
            sortable: true,
            cell: (row) => (<div title={row.groupName}>{row.groupName}</div>)
         },
         {
            name: "Description",
            cell: (row) => row.groupDescription,
          sortable: true,
            cell: (row) => (<div title={row.groupDescription}>{row.groupDescription}</div>)
         },  
        {
			name: tr["Disassociate"],
			sortable: false,
			cell: (row) => (
			  <CellName row={row} 
			  />
			),
			ignoreRowClick: true,
		  },
    ];
    const filterColumns = ["groupId","groupName"];

    return (
        <>
            <Form.Group className="form-group">
                <Form.Label>Group Name</Form.Label>
                <Field
                  name="groupId"
                  className="form-control"
                  component={TypeaheadandDropdown}
                  onSelection={onGroupSelection}
                  options={groupData}
                  selectedOptions={selectedGroup}
                  errorClass={groupError === "error" ? "error" : ""}
                  onCrossClick={onCrossClickGroup}
                  multiple={true}
                />
            </Form.Group>

            <ButtonToolbar className="black margin-t-5">
            <Button className="rgSidrkBtn smallBtn"
                onClick={handleSubmit(saveAssociateGroups)}>
               Add
                </Button>
            </ButtonToolbar>

            <div className="catBordDv leftSeardv integrateHubTable">
            <div className='f-size-16 margin-t-20 margin-b-10'>Associated Groups</div>
            <TableComponent
                data={Array.isArray(associatedGroupValue)? associatedGroupValue : []}
                columns={tableColumns}
                headerColumns={filterColumns}
                onRowSelect={onRowSelect}
                uniqueKey={'groupId'}
                paginationText = {"Show"}
            />
            </div>
        </>
    )
}

// export default GroupList;
export default reduxForm({
    form: 'GroupList',
    destroyOnUnmount: false,
    enableReinitialize: true
})(GroupList);