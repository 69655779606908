
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const tabContentExtendTrait = (e) => {
    e.DomComponents.addType("tab-content", {
      model: e.DomComponents.getType("tab-content").model.extend({
        init() {
          const traits = this.get("traits");
          traits.add([
            {
              type: "select",
              label: "Page",
              name: "page",
              options: [],
              changeProp: 1,
            },
            {
              type: "checkbox",
              label: "Default Load",
              name: "defaultLoad",
              changeProp: 1,
            },
            // {
            //   type: "text",
            //   label: "Page",
            //   name: "page",
            //   changeProp:1
            // },
          ]);
        }
      }),
    });
  };
  