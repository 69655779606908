
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import {useSelector } from "react-redux";

import PindCategories from "../../../../common/header/pind-categories";
import Breadcrumbs from "../../../../common/header/breadcrumbs";
import Header from "./header";
import FormComponent from "./form";
import "_Css/form/_form.scss";

const Index = () => {
  const tr = useSelector((state) => state.spcmReducer.tr);
  const [companyTypeErr, setCompanyTypeErr] = useState('');
  const [companyValue, setCompanyValue] = useState([]);
  const [forumErr, setForumErr] = useState('');
  const [forumValue, setForumValue] = useState([]);
  const [categoryErr, setCategoryErr] = useState('');
  const [categoryValue, setCategoryValue] = useState([]);
  const [nameErr,setNameErr] = useState('');
  const [statusErr, setStatusErr] = useState('');
  const [autoAlgoErr, setAutoAlgoErr] = useState('');
  
  const setCompanyErrorColor = () => {
    if (companyValue.length > 0) {
      setCompanyTypeErr("");
    } else {
      setCompanyTypeErr("error");
    }
  };
  const setForumErrorColor = () => {
    if (forumValue.length > 0) {
      setForumErr("");
    } else {
      setForumErr("error");
    }
  };
  const setCategoryErrorColor = () => {
    if (categoryValue.length > 0) {
      setCategoryErr("");
    } else {
      setCategoryErr("error");
    }
  };
  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Breadcrumbs activePageName={"Create"} parentPageurl='/group' parentPageName={tr["Group"]} />
      </Container>

      <Container fluid>
        <Header
          setCompanyErrorColor={setCompanyErrorColor}
          setForumErrorColor={setForumErrorColor}
          setCategoryErrorColor={setCategoryErrorColor}
          setNameErr={setNameErr}
          setStatusErr={setStatusErr}
          setAutoAlgoErr={setAutoAlgoErr} />
         <Row>
          <Col md={8} xs={12}>
            <FormComponent
              setCompanyErrorColor={setCompanyErrorColor}
              companyTypeErr={companyTypeErr}
              setCompanyValue={setCompanyValue}
              forumErr={forumErr}
              setForumValue={setForumValue}
              setForumErrorColor={setForumErrorColor}
              categoryErr={categoryErr}
              setCategoryValue={setCategoryValue}
              setCategoryErrorColor={setCategoryErrorColor}
              nameErr={nameErr}
              setNameErr={setNameErr}
              statusErr={statusErr}
              autoAlgoErr={autoAlgoErr}
              setStatusErr={setStatusErr}
              setAutoAlgoErr={setAutoAlgoErr} />
          </Col>
        </Row>
      </Container>
    </main>
  )
}

export default Index;
