
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form,NavDropdown} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

const VariantComponent = (props) => {
  const dispatch = useDispatch();
  const tr = useSelector((state) => state.spcmReducer.tr);

  const [variant1, setVariant1] = useState("Business");
  const [variant2, setVariant2] = useState("Basic");
  const [variant3, setVariant3] = useState("Billed Monthly");
  return (
    <div className="variantDv">
      {/* <div>Please select the options below.</div> */}
      <div className="innervardv">
        <NavDropdown title={variant1}>
          <NavDropdown.Item onClick={(e) => {setVariant1(e.target.text)}} href="javascript:void(0)">Business</NavDropdown.Item>
        </NavDropdown>
        <NavDropdown className="active" title={variant2}>
          <NavDropdown.Item onClick={(e) => {setVariant2(e.target.text)}} href="javascript:void(0)">Basic</NavDropdown.Item>
          <NavDropdown.Item onClick={(e) => {setVariant2(e.target.text)}} href="javascript:void(0)">Standard</NavDropdown.Item>
          <NavDropdown.Item onClick={(e) => {setVariant2(e.target.text)}} href="javascript:void(0)">Premium</NavDropdown.Item>
          <NavDropdown.Item onClick={(e) => {setVariant2(e.target.text)}} href="javascript:void(0)">Add On Pack</NavDropdown.Item>
        </NavDropdown>
        <NavDropdown title={variant3}>
          <NavDropdown.Item onClick={(e) => {setVariant3(e.target.text)}} href="javascript:void(0)">Billed Monthly</NavDropdown.Item>
          <NavDropdown.Item onClick={(e) => {setVariant3(e.target.text)}} href="javascript:void(0)">Billed Annually</NavDropdown.Item>
        </NavDropdown>
      </div>
    </div>
  );
};

export default VariantComponent;
