
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/

import * as api from '_Services/communityApi';

export function getQuestions(start=1,end=10,query='',userId,offeringId,callback){
        return (dispatch) => {
            api.getQuestionsAPI(start,end,query,userId,offeringId).then((resp) => {
               callback(null,resp);
            })
        }
}

export function getQuestionsAPIForCommunityGlobalSearch(start=1,end=10,query='',userId,sortBy,sortType,callback){
    return (dispatch) => {
        api.getQuestionsAPIForCommunityGlobalSearch(start,end,query,userId,sortBy,sortType).then((resp) => {
           callback(null,resp);
        })
    }
}

