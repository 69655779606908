
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import { loadBreakFixCause } from '../../../../actions/breakFix/causeAction';
import { loadBreakFixCauseDropdown } from '../../../../actions/breakFix/provideFixAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm, formValueSelector, change, SubmissionError, reset } from 'redux-form';
import { loadBreakFixEditDetails } from '../../../../actions/breakFix/breakFixEditDetailsAction';
import { _radio } from '../../../common/formFields';
import { GLOBAL } from "_Globals";
import axios from 'axios';
import _ from 'lodash';
let sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
import { loadBreakFixStatusDetails } from '../../../../actions/breakFix/breakFixStatusAction';
import { loadBreakFixEditDetailsUpdate } from '../../../../actions/breakFix/breakFixEditDetailsAction';
import { loadBreakFixSGAE,loadNewBreakFixSGAE} from '../../../../actions/breakFix/serviceGroupAEAction';
import { loadStatusBasedHamburgerOptions } from '../../../../actions/common/loadStatusBasedHamburgerOptions';
import { loadTimelineData } from '../../../../actions/common/commonApisActions.js';
import swal from 'sweetalert'
import CIDetailsService from '../../../common/WorkItemBoard/ciNameSelect';
import Cookies from 'universal-cookie';
import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import CIDEtails from "../../../common/WorkItemBoard/xsm-ci-editquickView";
import {ImInfo} from 'react-icons/im';
import { getCookiesValuesByPositions } from '../../../common/helper.js';
const cookies = new Cookies();

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

let ciSupercedesOffering = cookies.get('gph');
if (ciSupercedesOffering) ciSupercedesOffering = ciSupercedesOffering.replace('s:', '');
if (ciSupercedesOffering) ciSupercedesOffering = ciSupercedesOffering.substring(0, ciSupercedesOffering.lastIndexOf('.'));
ciSupercedesOffering = ciSupercedesOffering.split("~");
const languageSelected = ciSupercedesOffering[20];
ciSupercedesOffering = ciSupercedesOffering[52];


class ProvideFixTab extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			createProblemTicket: 'No',
			successMessage: '',
			companyId:'',
			errorColor:'',
			valuesImpacted:'',
			valuesImpactedId:'',
			ciLocation:'',
		    ciLocationName:'',
		    clClassID:'',
		    ciClassName:'',
			category: '',
			categoryText: '',
			subCategory: '',
			subCategoryText: '',
			typeOfFix: '',
			typeOfFixText: '',
			categoryOptions: [],
			subCategoryOptions: [],
			hideInvestigationRadioBtn: false,
			oldbusinessCriticalityId: GLOBAL.businessCriticalityId,
			oldbusinessCriticality: GLOBAL.businessCriticality,
			newbusinessCriticalityId: '',
			newbusinessCriticality: '',
			calculatedPriority: '',
			urgencyList: [],
			impactList: [],
			urgencyValue:'',
			impactValue:'',
			calculatedPriorityList: {},
			urgencyError: '',
			impactError: '',
			subStatuses:[],
			resolutionList: []
		};
		this.props.loadBreakFixCause();
		//this.props.loadBreakFixCauseDropdown();
		const initData = {
			"category": "",
			"subCategory": "",
			"typeOfFix": ""
		};
		this.props.initialize(initData);
		this.onhandleSumit = this.onhandleSumit.bind(this);
		//this.onChangeHandle = this.onChangeHandle.bind(this);
		this.clearForm = this.clearForm.bind(this);
		this.onImpactedSelection=this.onImpactedSelection.bind(this);
		this.getCategories = this.getCategories.bind(this);
		this.getsubCategoriesForCategory = this.getsubCategoriesForCategory.bind(this);
		this.updateCategory = this.updateCategory.bind(this);
		this.updateSubCategory = this.updateSubCategory.bind(this);
		this.updateTypeOfFix = this.updateTypeOfFix.bind(this);
		this.updateCreateProblemTicket = this.updateCreateProblemTicket.bind(this);
		this.calculatePriorityforInvestigation = this.calculatePriorityforInvestigation.bind(this);
		this.onChangeUrgency = this.onChangeUrgency.bind(this);
		this.onChangeCriticality = this.onChangeCriticality.bind(this);
		this.loadUrgencyList = this.loadUrgencyList.bind(this);
		this.loadImpactList = this.loadImpactList.bind(this);
		this.showImpactedCIInfoIcon = this.showImpactedCIInfoIcon.bind(this);
		this.onResolutionTypeChange = this.onResolutionTypeChange.bind(this);

    GLOBAL.breakFixServiceID = this.props.breakFixEditDetails.serviceId;
    GLOBAL.breakFixServiceName = this.props.breakFixEditDetails.serviceName;
	}

	componentWillMount() {
		// console.log("supportPIndividualValue>><>>< ",this.props.supportPIndividualValue);
		let amsAttribute={
			"Status":this.props.breakFixEditDetails.statusId,
			"Priority":this.props.breakFixEditDetails.priorityValue,
			"Service":this.props.breakFixEditDetails.serviceId,
			"Impacted CI":this.props.breakFixEditDetails.ciId,
			"Requestor":this.props.breakFixEditDetails.impactedUserid,
			"Class":this.props.breakFixEditDetails.ciClassId,
			"User.Location":this.props.breakFixEditDetails.requesterLocationId,
			"CI.Location":this.props.breakFixEditDetails.ciLocationId
		}
		GLOBAL.proposeproblemfromimpacted=1;
		// this.props.loadBreakFixSGAEEdit(this.props.breakFixEditDetails.serviceId,this.props.breakFixEditDetails.consumerCompany,"EditBreakfix")
		// this.props.loadNewBreakFixSGAE(this.props.breakFixEditDetails.serviceId, this.props.breakFixEditDetails.consumerCompany, "Investigation",amsAttribute);

		if(this.props.roleIdentification('14')||
		   this.props.roleIdentification('17')||
		   this.props.roleIdentification('17')){
		   this.setState({hideInvestigationRadioBtn: false});
		}else if(!(this.props.roleIdentification('17')||
		     this.props.roleIdentification('12')|| 
		     this.props.roleIdentification('13'))){
             this.setState({hideInvestigationRadioBtn: this.props.roleIdentification('48')});
		}
		if(!this.props.breakFixEditDetails.serviceBased){
			let queryPayload={};
			queryPayload.searchByName="ci_id";
			queryPayload.searchByValue=this.props.breakFixEditDetails.ciId+"";
			queryPayload.companyId=this.props.breakFixEditDetails.consumerCompany;
			queryPayload=JSON.stringify(queryPayload);
			axios.get('/api/config_items/V2?limit=1&page=1',{headers: {query: queryPayload}}).then((respondedCIDetails)=>{
				this.setState({newbusinessCriticalityId: respondedCIDetails.data.data[0].METALLIC===""||respondedCIDetails.data.data[0].METALLIC===null?0:respondedCIDetails.data.data[0].METALLIC,
				               newbusinessCriticality: respondedCIDetails.data.data[0].METALLIC_NAME});
				});
		}

		axios.get('/api/company_menulist?companyId='+this.props.breakFixEditDetails.consumerCompany+'&module=Incident&field=Fixed&language='+languageSelected+'&status=1').then((responseDropdownValues)=>{
			this.setState({subStatuses:responseDropdownValues.data});
		});
		const resolutionDropdown = [];
		if(this.props.breakFixEditDetails.ciId != 0 && this.props.breakFixEditDetails.ciId != '' && this.props.breakFixEditDetails.ciId != null){
           resolutionDropdown.push({id: 1, name: 'CI Based'});
		}
		if(this.props.breakFixEditDetails.serviceId != 0 && this.props.breakFixEditDetails.serviceId != '' && this.props.breakFixEditDetails.serviceId != null){
			resolutionDropdown.push({id: 2, name: 'Service Based'});
		}
		this.setState({resolutionList: resolutionDropdown});
	}
	componentWillUnmount()
	{
		GLOBAL.proposeproblemfromimpacted=0;
		GLOBAL.businessCriticalityId=this.state.oldbusinessCriticalityId;
	    GLOBAL.businessCriticality=this.state.oldbusinessCriticality;
	}
	clearForm(){
		this.setState({
			category: '',
			categoryText: '',
			subCategory: '',
			subCategoryText: '',
			typeOfFix: '',
			typeOfFixText: '',
			createProblemTicket: '',
			subCategoryOptions: [],
		});
		// this.props.dispatch(reset('provideFixTab'));
		this.props.dispatch(change('provideFixTab', 'category', ""));
		this.props.dispatch(change('provideFixTab', 'subCategory', ""));
		this.props.dispatch(change('provideFixTab', 'typeOfFix', ""));
		// this.props.dispatch(change('provideFixTab', 'resolutionCodeNameText', ""));
		this.props.dispatch(change('provideFixTab', 'create_problem_ticket', ""));
		this.props.dispatch(change("provideFixTab", "resolutionType", ''));

		var ele = document.getElementsByName("create_problem_ticket");
		for(var i=0;i<ele.length;i++){
			ele[i].checked = false;
		}
	}

	// onChangeHandle(e) {
	// 	this.setState({ createProblemTicket: e.target.value });
	// }

	//rendering predefined causeName
	// renderFixCauseName(breakFixData) {
	// 	// console.log('breakFixDatabreakFixData',breakFixData)
	// 	if (!breakFixData) {
	// 		return (
	// 			<option></option>
	// 		);
	// 	}
	// 	else {
	// 		return breakFixData.map((breakFixObj) => {
	// 			//alert(breakFixObj.categoryId);
	// 			return (
	// 				<option value={breakFixObj.field1Value}>{breakFixObj.field1Key}</option>
	// 			);
	// 		});
	// 	}
	// }
// 	showErrorMsg(msg){
// 		let msg1= this.props.translator["has been successfully submitted. For more information, please navigate to the record through Investigation List view"];
// 		console.log("this.props.translator",this.props.translator);
// 		msg=msg+msg1
// 		console.log("msgggg",msg);
//   swal({
//     text: msg,
//     button:this.props.translator['OK']
//   });
// }
	onImpactedSelection(impactedID,companyNameVal)
	{
	// 	console.log("GLOBALGLOBAL",GLOBAL.ciId, GLOBAL.ciConsumerCompany,impactedID)
	// 	if(impactedID!="")
	// 	{
	// 		if(this.state.onCreateUsingSelect=='impactedCI')
	// 		{
    //     this.props.loadBreakFixSGAE(GLOBAL.ciId, this.props.breakFixEditDetails.ciConsumerCompany,"Breakfix","impactedCI");
    //   this.props.dispatch(change('xsmBreakFixFormTab','supportPIndividual',''));
	// 		this.props.dispatch(change("xsmBreakFixFormTab", "supportPGroup",''));
			
	// 		}
	// 	}
	// console.log("impactedid",impactedID);
    this.setState({companyId:this.props.breakFixEditDetails.ciConsumerCompany});
    this.setState({
		
		valuesImpacted:GLOBAL.ciName,
		valuesImpactedId:impactedID,
		ciLocation:GLOBAL.ciLocationId,
		ciLocationName:GLOBAL.ciLocationName,
		clClassID:GLOBAL.ciClassId,
		ciClassName:GLOBAL.ciClassName,
		errorColor:""
	
	});
	this.setState({newbusinessCriticalityId: GLOBAL.businessCriticalityId, newbusinessCriticality: GLOBAL.businessCriticality});
	GLOBAL.businessCriticalityId=this.state.oldbusinessCriticalityId;
	GLOBAL.businessCriticality=this.state.oldbusinessCriticality;
	this.showImpactedCIInfoIcon(true);
	}
	onhandleSumit(values) {
		// console.log("CINAME+++",GLOBAL.ciName,GLOBAL.ciId);
		const { category, categoryText, subCategory, subCategoryText, typeOfFix, typeOfFixText, createProblemTicket } = this.state;

		if((this.props.user_id!=this.props.breakFixEditDetails.spUserid) && (this.props.roleIdentification('12')  || this.props.roleIdentification('13')))
				 swal({
					text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
					button: this.props.translator['OK'],
				 });
		else
		{

		// console.log('values - ',values);
		let ref = this;
		const { translator } = this.props;
		// console.log("ref.props.breakFixEditDetails",ref.props.breakFixEditDetails)
        // console.log("statessss",GLOBAL.ciName);
				let breakFixConsumer = GLOBAL.breakFixConsumerID
				let individualUserId = "";
				if(this.props.breakFixEditDetails && this.props.breakFixEditDetails.spUserid){
					individualUserId = this.props.breakFixEditDetails.spUserid;
				}
				// console.log("individualUserId:: ",individualUserId);
				// console.log("this.props.supportPIndividualValue:: ",this.props.supportPIndividualValue);

				if(this.props.supportPIndividualValue != undefined && individualUserId != this.props.supportPIndividualValue){
					throw new SubmissionError({ _error: translator['First save the selected Individual']+' !' });
				}					
				else if(individualUserId == 0 || individualUserId == "" || individualUserId == null 
				|| this.props.supportPIndividualValue == 0){
					throw new SubmissionError({ _error: translator['Please select an Individual']+' !' });
				}else if (`${JSON.stringify(values, null, 2)}` == "{}") {
					throw new SubmissionError({ _error: translator['Please select a value']+' !' })
				}else if(this.state.urgencyValue==='' && this.state.createProblemTicket==='Yes'){
                    this.setState({urgencyError: 'error'});
					throw new SubmissionError({ _error: translator['Please select a Urgency']+' !' });
				}else if(this.state.impactValue==='' && this.state.createProblemTicket==='Yes'){
                    this.setState({impactError: 'error'});
                    throw new SubmissionError({ _error: translator['Please select a Impact']+' !' });
				} 
				// else if (values.cserviceGroupIndserviceGroupIndividualNameIdauseName == "" || values.causeName == undefined) {
				// 	throw new SubmissionError({ _error: 'Please select a cause !' })
				// }
				// else if (values.causeName == "" || values.causeName == undefined) {
				// 	throw new SubmissionError({ _error: 'Please select a cause of fix !' })
				// }
				else if(!values.resolutionType){
					throw new SubmissionError({ _error: translator['Please select a Resolution Type']+' !' });
				}
				else if (!category) {
					throw new SubmissionError({ _error: translator['Please select a Category']+' !' })
				}
				else if (!subCategory) {
					throw new SubmissionError({ _error: translator['Please select a Sub-Category']+' !' })
				}
				else if (!typeOfFix) {
					throw new SubmissionError({ _error: translator['Please select a Type of Fix']+' !' })
				}
				else if (values.fixDetails == undefined || values.fixDetails == "") {
					throw new SubmissionError({ _error: translator['Please Describe the fix provided']+' !' })
				}
				else if((GLOBAL.ciName===''||GLOBAL.ciName==null ||GLOBAL.ciName==undefined)&&this.props.breakFixEditDetails.serviceBased==true&&values.create_problem_ticket=="Yes")
				{
					throw new SubmissionError({ _error: translator['Please select Impacted CI value']+' !' })
				}
				else if ( (!values.create_problem_ticket || values.create_problem_ticket == "" || values.create_problem_ticket == undefined ) && (this.props.breakFixEditDetails && this.props.breakFixEditDetails.priorityValue && this.props.breakFixEditDetails.priorityValue === 'P1' || this.props.breakFixEditDetails.priorityValue === 'P2' || this.props.breakFixEditDetails.priorityValue === 'Critical')&&!this.state.hideInvestigationRadioBtn) {
					throw new SubmissionError({ _error: translator['Please select Yes or No for creation of Problem ticket']+' !' })
				} 
				
				else {
					let descriptionValue = "";
					// if (values.causeName == "5") {
					// 	descriptionValue = "Cause: Bad Activity ,Type of Fix: " + values.resolutionCodeNameText+ ",Fix Provided:" + values.fixDetails
					// } else if (values.causeName == "10") {
					// 	descriptionValue = "Cause: RFC ,Type of Fix: " + values.resolutionCodeNameText+ ",Fix Provided:" + values.fixDetails
					// } else if (values.causeName == "15") {
					// 	descriptionValue = "Cause: Vendor Activity ,Type of Fix: " + values.resolutionCodeNameText+ ",Fix Provided:" + values.fixDetails
					// } else if (values.causeName == "20") {
					// 	descriptionValue = "Cause: Maintenance ,Type of Fix: " + values.resolutionCodeNameText+ ",Fix Provided:" + values.fixDetails
					// } else if (values.causeName == "25") {
					// 	descriptionValue = "Cause: Others ,Type of Fix: " + values.resolutionCodeNameText+ ",Fix Provided:" + values.fixDetails
					// } 
					// else {
					// 	descriptionValue = "Caused By: ,Fix Provided:" + values.fixDetails
					// }
					descriptionValue = `Category: ${categoryText}, Sub-Category: ${subCategoryText}, Type of Fix: ${typeOfFixText}, Resolution notes: ${values.fixDetails}`;

					let postjsonactivity = {
						"itemId": GLOBAL.breakFixId,
						"description": descriptionValue,
						"isInternal": "N",
						"createdOn": "",
						"createdBy": "",
						"module": "Breakfix",
						"createdByName": "",
						"isResolvingStep":"true",
					};


					return axios.patch(GLOBAL.breakFixEditUrl + GLOBAL.breakFixId, {
						"fixDetails": descriptionValue,
						// "cause": values.causeName,
						"status": 25,
						"reasonCode": typeOfFix,
						"reasonValue": typeOfFixText,
						"modifiedBy": "",
						"modifiedByName":"",
                        "category":categoryText,
						"subCategory":subCategoryText
					})
						//.then(function (response) {
						//		 axios.patch(GLOBAL.breakFixPatchUrl+GLOBAL.breakFixId, {
						//	"modifiedBy" : user_id
						//	})
						.then(function (response) {
						//   console.log('here in resposne')
						//   console.log(response)
							//ref.props.changeProvideFixStatus('update');
							ref.props.loadBreakFixEditDetails(response.data.breakfixId);
							ref.props.loadStatusBasedHamburgerOptions('Breakfix',response.data.status,response.data.breakfixId);
							axios.post(GLOBAL.breakFixActivityLogPostUrl, postjsonactivity, {
							}).then(function (response) {
								// console.log("response.statusText:::" + response.status)
								//	ref.props.changeProvideFixStatus('update');
								// ref.props.loadBreakFixStatusDetails(25, 25,response.data.breakfixId)
								//ref.props.loadBreakFixEditDetailsUpdate(GLOBAL.breakFixId);
								// ref.props.loadBreakFixAuditLogDetails(response.data.breakFixId.toString(), "dsc", "Breakfix"); 		//commented because .toString() was giving error toString of undefined and it was not woring further.
								// ref.props.showProcessHierarchy()
							})

							//creating problem from incident -start
								.then(function () {
									if (ref.state.createProblemTicket === 'Yes') {
										return sleep(1000)
										.then(() => {
											GLOBAL.messageForProposeInvst = "";
											// let messageForProposeInvst = "";
											// let breakFixConsumer = GLOBAL.breakFixConsumerID;
											// let breakFixConsumerNameValue = GLOBAL.breakFixConsumerName;
											// let breakFixConsumerCompany=GLOBAL.consumerCompanybreakfix;
											let breakFixConsumerCompany = ref.props.breakFixEditDetails.consumerCompany;
											let breakFixService = GLOBAL.breakFixServiceID;
											// let supportPgroupValue = GLOBAL.supportPGroupValue;
											// let supportPgroupNameValue = GLOBAL.supportPGroupName;
											// let supportPIGroupNameValue = GLOBAL.supportPIGroupName;
											let breakFixServiceName = GLOBAL.breakFixServiceName;
											// let asgPmId = GLOBAL.breakfixgroupid;
											let breakFixConsumerCompanyName = ref.props.breakFixEditDetails.consumerCompanyName;

											let serviceGroup = [];
											let serviceGroupNameId = 0;
											let serviceGroupIndividualNameId = 0;
											let serviceGroupName = "";
											let serviceGroupIndividualName = "";
											serviceGroup = ref.props.breakFixSGAEName;
											// serviceGroupNameId = serviceGroup[0].assignmentGroupId;
											// serviceGroupIndividualNameId = serviceGroup[0].assignmentMemberId ? serviceGroup[0].assignmentMemberId : 0;
											// serviceGroupName = serviceGroup[0].assignmentGroupName;
											// serviceGroupIndividualName = serviceGroup[0].assignmentMemberName ? serviceGroup[0].assignmentMemberName : '';
											let priorityValue='';
                                            if(ref.state.calculatedPriority==='P1'){
												priorityValue='45';
											}else if(ref.state.calculatedPriority==='P2'){
												priorityValue='50';
											}else if(ref.state.calculatedPriority==='P3'){
												priorityValue='55';
											}else{
												priorityValue='60';
											}

											const {value1, value2} = getCookiesValuesByPositions([6, 9]);
											// let ref = this
											axios.post(GLOBAL.investigationCreateUrl, {
												"cancellationComments": "",
												"cause": 0,
												"closureComments": "",
												"consumerCompany": breakFixConsumerCompany,
												"correctiveActionRequired": "",
												"correctiveActions": "",
												"createdOn": 0,
												"description": ref.props.breakFixEditDetails.description,
												"expectedDate": 0,
												"impactedUserid": "",
												"impactedUsername": "",
												"modifiedBy": "",
												"modifiedOn": 0,
												"priority": priorityValue,
												"priorityValue": ref.state.calculatedPriority,
												"qualificationComments": "",
												"qualified": "",
												"reportedThrough": ref.props.breakFixEditDetails.reportedThrough,
												"requestId": ref.props.breakFixEditDetails.requestId,
												"rootCause": "",
												"serviceCriticality": ref.state.impactValue,
												"serviceId": 0,
												"serviceName": breakFixServiceName,
												"ciId":ref.props.breakFixEditDetails.serviceBased?ref.state.valuesImpactedId:ref.props.breakFixEditDetails.ciId,
												"ciName":ref.props.breakFixEditDetails.serviceBased?ref.state.valuesImpacted:ref.props.breakFixEditDetails.ciName,
												"ciCode":ref.props.breakFixEditDetails.ciCode,
												"environment":ref.props.breakFixEditDetails.environment,
                                                "ciConsumerCompany":ref.props.breakFixEditDetails.ciConsumerCompany,
                                                "ciSupportCompany":ref.props.breakFixEditDetails.ciSupportCompany,
                                                "ciClassId":ref.props.breakFixEditDetails.serviceBased?ref.state.clClassID:ref.props.breakFixEditDetails.ciClassId,
                                                "ciLocationId":ref.props.breakFixEditDetails.serviceBased?ref.state.ciLocation:ref.props.breakFixEditDetails.ciLocationId,
                                                "ciLocationName":ref.props.breakFixEditDetails.serviceBased?ref.state.ciLocationName:ref.props.breakFixEditDetails.ciLocationName,
												"requesterLocationId": value1,
				                                "requesterLocationName": value2,
												"ciClassName":  ref.props.breakFixEditDetails.ciClassName,
												"serviceBased":false,
												"slaStatus": 0,
												// "status": 35,
												"status": 30,
												"symptoms": '',
												"urgency": ref.state.urgencyValue,
												"createdBy": "",
												"createdByName": "",
												"asgPmId": serviceGroupIndividualNameId,
												"asgPmName": serviceGroupIndividualName,
												"asgPmGroupid": serviceGroupNameId,
												"asgPmGroupname": serviceGroupName,
												"consumerCompanyName": breakFixConsumerCompanyName,
												"businessCriticalityId": ref.state.createProblemTicket==="Yes"?ref.state.newbusinessCriticalityId:"",
			                                    "businessCriticality": ref.state.createProblemTicket==="Yes"?ref.state.newbusinessCriticality:"",
												"supportCompanyId": ref.props.breakFixEditDetails.supportCompanyId,
			                                    "supportCompanyName": ref.props.breakFixEditDetails.supportCompanyName,
												source: "10"
											})
												.then(function (response) {
													// console.log("creating investigation");
													//console.log("creating investigation"+ref.state.msgSubmit);
													// console.log(response);
													if (response.status === 201) {
														let activityDetails ={
															"itemId": ref.props.breakFixEditDetails.breakfixId,
														    "description":`Added a Related Work Item - ${ref.props.breakFixEditDetails.breakfixNumber} Is a Parent of ${response.data.investigationNumber}`,
														    "isInternal":"Y",
														    "createdOn":"",
														    "createdBy":"",
														    "module":"Breakfix",
														    "createdByName":"",
														    "sendNotification":"true",
														    "isExternal":""
														};
														axios.post("/serviceManagement/rest/activity/", activityDetails);
														axios.post(GLOBAL.breakFixRelatedLogPostUrl, {
															"itemId": ref.props.breakFixEditDetails.breakfixId,
															"status": "A",
															"createdOn": "",
															"createdBy": "",
															"modifiedOn": "",
															"modifiedBy": "",
															"module": "Breakfix",
															"workItemId": response.data.investigationId,
															"workModule": "Investigation",
															"workItemNumber": response.data.investigationNumber,
															"createdByName": "",
															"relationType": "p2c",
															"itemNumber": ref.props.breakFixEditDetails.breakfixNumber
														});

														// axios.post(GLOBAL.breakFixRelatedLogPostUrl, {
														// 	"itemId": response.data.investigationId,
														// 	"status": "A",
														// 	"createdOn": "",
														// 	"createdBy": "",
														// 	"modifiedOn": "",
														// 	"modifiedBy": "",
														// 	"module": "Investigation",
														// 	"workItemId": ref.props.breakFixEditDetails.breakfixId,
														// 	"workModule": "Breakfix",
														// 	"workItemNumber": ref.props.breakFixEditDetails.breakfixNumber,
														// 	"createdByName": "",
														// 	"relationType": "p2p"
														// })
																											

														ref.setState({
															successMessage: response.data.investigationNumber + " has been successfully submitted. For more information, please navigate to the record through Investigation List view"
														})

													} else {
														ref.setState({
															successMessage: "Investigation not created"
														});
													}
													swal({
														text: response.data.investigationNumber+ref.props.translator[`has been successfully submitted. For more information, please navigate to the record through Investigation List view`],
														button: ref.props.translator['OK'],
													  });
												})
												.catch(function (error) {
													console.log(error);
												});
												
												
											ref.props.showProcessHierarchy();
											
										});
									}
									else {
												ref.props.showProcessHierarchy();
									}
								})
								//creating problem from incident -end

								.catch(function (error) {
									console.log(error);
								});
						})
						// 		.catch(function (error) {
						// 			console.log(error);
						// 				});
						//   console.log(response);
						// })
						.catch(function (error) {
							console.log(error);
						});
				}
	}
	}

	renderDropdown(options) {
		try{
			if (!(options && Array.isArray(options) && options.length)) {
				return null;
			}
			return options.map((item) => {
				return (
					<option value={item.id || item.field1Value}>{item.name || item.field1Key}</option>
				);
			});
		}catch(e){
			return null;
		}
	}

	normalizeData(arr){
		try{
			if (!(arr && Array.isArray(arr) && arr.length)) {
				return [];
			}
			return arr.map((item, index) => {
				let idForCategory = null;
				if(item['category_id'] && item['res_category_name']){
					idForCategory = `${item['category_id']}||${item['res_category_name']}`;
				}
				let obj = {
					id: idForCategory || item['res_sub_category_name'] || item['field1Value'] || '',
					name: item['res_category_name'] || item['res_sub_category_name'] || item['field1Key'] || ''
				};
				return obj;
			});
		}catch(e){
			return [];
		}
	}

	getCategories(resolutionTypeId){
		if(resolutionTypeId === ''){
			return;
		}
		try{
			const { breakFixEditDetails: { ciId, serviceId } } = this.props;
			let str = {};
			str.ciId = null;
			str.offeringId = null;
			if( resolutionTypeId === '2' ){
				str.offeringId = serviceId;
			}else if( resolutionTypeId === '1' ){
				str.ciId = ciId;
			}
			this.setState({subCategoryOptions: []});
			str = JSON.stringify(str);
			api.get("/getCategoriesForProvideFix", { headers: { 'query': str } }).then((response) => {
				if(response.data && Array.isArray(response.data) && response.data.length){
					this.setState({categoryOptions: this.normalizeData(response.data)});
				}
			}).catch(err => {
				console.error('Error in calling API /getCategoriesForProvideFix : ',err);
			});
		}catch(e){
			console.error(e);
		}
	}

	getsubCategoriesForCategory(value, text){
		try{
			let str = {};
			// str.category_id = value;
			str.category_id = value.split('||')[0];
			str.res_category_name = text;
			str = JSON.stringify(str);
			api.get("/getSubCategoriesForCategoryForProvideFix", { headers: { 'query': str } }).then((response) => {
				// console.log('getsubCategoriesForCategory response - ', response);
				if(response.data && Array.isArray(response.data) && response.data.length){
					this.setState({subCategoryOptions: this.normalizeData(response.data)});
				}
			}).catch(err => {
				console.error(err);
			});
		}catch(e){
			console.error(e);
		}
	}
	
	updateCategory(value, text){
		try{
			let val = value.split('||')[0];
			this.setState({
				category: val, 
				categoryText: text, 
				subCategory: '',
				subCategoryText: '',
				subCategoryOptions: []
			});
			this.props.dispatch(change("provideFixTab", "subCategory", ""));
		}catch(e){
			console.error(e);
		}
	}
	updateSubCategory(value, text){
		this.setState({subCategory: value, subCategoryText: text});
	}
	updateTypeOfFix(value, text){
		this.setState({typeOfFix: value, typeOfFixText: text});
	}
	updateCreateProblemTicket(e){
		if(e.target.value==='Yes' && this.state.urgencyList.length===0){
			this.loadUrgencyList('Investigation','Urgency');
		    this.loadImpactList('Investigation','Criticality');
		}
		this.setState({createProblemTicket: e.target.value, urgencyError: '', impactError: '', urgencyValue:'', impactValue:'', calculatedPriority: ''});
	}
	calculatePriorityforInvestigation(urgency,impact){
		if(typeof this.state.calculatedPriorityList[urgency+','+impact]!=='undefined'){
			this.setState({calculatedPriority: this.state.calculatedPriorityList[urgency+','+impact]});
		}else{
		  axios.get(`/serviceManagement/rest/priority?module=Investigation&criticality=${impact}&urgency=${urgency}`).then((calculatedInvestigationPriority) => {
			let intermidatePriorityList = this.state.calculatedPriorityList;
			intermidatePriorityList[urgency+','+impact]=calculatedInvestigationPriority.data.priorityValue;
			this.setState({calculatedPriority: calculatedInvestigationPriority.data.priorityValue, calculatedPriorityList: intermidatePriorityList});
	 	  });
	    }
	}
	loadUrgencyList(moduleName,fieldName){
		axios.get(GLOBAL.priorityCriticalityUrgencyUrl + "/module/" + moduleName + "/field/" + fieldName).then((responseUrgency)=>{
            this.setState({urgencyList: responseUrgency.data});
		});
	}
	loadImpactList(moduleName,fieldName){
		axios.get(GLOBAL.priorityCriticalityUrgencyUrl + "/module/" + moduleName + "/field/" + fieldName).then((responseImpact)=>{
            this.setState({impactList: responseImpact.data});
		});
	}
    onChangeUrgency(urgencyValue){
       if(urgencyValue!=='' && this.state.impactValue!==''){
         this.calculatePriorityforInvestigation(urgencyValue,this.state.impactValue);
	   }
	   if(urgencyValue===''){
         this.setState({calculatedPriority: '', urgencyError: 'error'});
	   }else{
		this.setState({urgencyError: ''});
	   }
	   this.setState({urgencyValue});
	}
	onChangeCriticality(impactValue){
		if(this.state.urgencyValue!=='' && impactValue!==''){
			this.calculatePriorityforInvestigation(this.state.urgencyValue,impactValue);
		}
		if(impactValue===''){
			this.setState({calculatedPriority: '', impactError: 'error'});
		}else{
            this.setState({impactError: ''});
		}
		this.setState({impactValue});
	}
	renderCriticalityName(impactList){
		if(impactList.length===0){
            return <option></option>;
		 } 
		 impactList = _.sortBy(impactList, o=> parseInt(o.field1Value, 10));
		 return impactList.map((item)=>{
            return <option value={item.field1Value}>{item.field1Key}</option>
		 });
	}
	renderUrgencyName(urgencyList){
         if(urgencyList.length===0){
            return <option></option>;
		 } 
		 urgencyList = _.sortBy(urgencyList, o=> parseInt(o.field1Value, 10));
		 return urgencyList.map((item)=>{
            return <option value={item.field1Value}>{item.field1Key}</option>
		 });
	}
	showImpactedCIInfoIcon(value){
		this.setState({impIconshow: value});
	}
	onResolutionTypeChange(event){
	   const resolutionId = event.target.value;
	   this.getCategories(resolutionId);
	   this.props.dispatch(change("provideFixTab", "resolutionType", resolutionId));
	   this.updateCategory('','');
	   this.props.dispatch(change("provideFixTab", "category", ""));
	   this.setState({categoryOptions: []});
	}
	render() {
		const imptCI = (
            <Popover className="userInfoPopup">
            <CIDEtails ciId={GLOBAL.ciId} translator={this.props.translator}/>
            </Popover>
        );
		console.log('*****', {state: this.state, props: this.props});

		// console.log("props:::))))))))))",this.props,GLOBAL.ciName);
		const { categoryOptions, subCategoryOptions, category, subCategory, typeOfFix } = this.state;
		let { error, handleSubmit, pristine, reset, submitting } = this.props;
		// console.log('**********', {state: this.state, props: this.props});
		const typeOfFixOptions = this.normalizeData(this.props.provideFixDropdownList);

		// console.log('this.props.provideFixDropdown this.props.provideFixDropdown',this.props)
		//alert("this.props.causeName:"+this.props.causeName); //selected value is coming
		//alert(this.props.breakFixEditDetails.breakfixId);
		if(this.state.successMessage==""){
		return (
			<div className="hambTab">
				 <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
                    <ul>
                        <li>
                        <button
						    type='button'
                            title="Minimize the right panel"
                            bsClass=""
                            bsStyle=""
							className="closerightPanelBtn"
                            onClick={() => {
                            this.props.rightEditPanel(false);
                            }}
                        >
                            <IoClose />
                        </button>
                        </li>
                    </ul>
                </div>
				<font color="red">{error && <strong>{error}</strong>}</font>
				<div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Provide Fix']}</div></div>
				<div className='rBoxGap'>
				<Form.Group className="form-group" >
				    <Form.Label><span className="rStar"></span>{this.props.translator['Resolution Type']}</Form.Label>
					<Field name="resolutionType" component="select" type="select" className="form-control" onChange={this.onResolutionTypeChange}>
						<option value="">Select..</option>
						{this.renderDropdown(this.state.resolutionList)}
					</Field>
				</Form.Group>	

				<Form.Group className="form-group" >
					<Form.Label><span className="rStar"></span>{this.props.translator['Category']}</Form.Label>
					<Field name="category" component="select" type="select" className="form-control" 
					onChange={(event) => {
						this.updateCategory(event.target.value, event.target[event.target.selectedIndex].text);
						this.getsubCategoriesForCategory(event.target.value, event.target[event.target.selectedIndex].text);
					}}
					value={category}
					>
						<option value="">Select..</option>
						{this.renderDropdown(categoryOptions)}
					</Field>
					{/* <FormControl className="myControl myInput" componentClass="select"
					onChange={(event) => {
						this.updateCategory(event.target.value);
						this.getsubCategoriesForCategory(event.target.value, event.target[event.target.selectedIndex].text);
					}}
					value={category}
					>
						<option value="">Select..</option>
						{this.renderDropdown(categoryOptions)}
					</FormControl> */}
				</Form.Group>

				<Form.Group className="form-group" >
					<Form.Label><span className="rStar"></span>{this.props.translator['Sub-Category']}</Form.Label>
					<Field name="subCategory" component="select" type="select" className="form-control" 
					onChange={(event) => { 
						this.updateSubCategory(event.target.value, event.target[event.target.selectedIndex].text);
					}}
					value={subCategory}
					>
						<option value="">Select..</option>
						{this.renderDropdown(subCategoryOptions)}
					</Field>
				</Form.Group>
				
				{/* Type of Fix */}
				<Form.Group className="form-group" >
					<Form.Label><span className="rStar"></span>{this.props.translator['Resolution Code']}</Form.Label>
					<Field name="typeOfFix" component="select" type="select" className="form-control" 
					onChange={(event) => { 
						// console.log('***********', event, event.target[event.target.selectedIndex].text, event.target.value);
						this.updateTypeOfFix(event.target.value, event.target[event.target.selectedIndex].text);
					}}
					value={typeOfFix} 
					>
						<option value="">Select..</option>
						{this.renderDropdown(this.state.subStatuses)}
					</Field>
				</Form.Group>
				
				
				{/* <Form.Group className="form-group" >
					<Form.Label><span className="rStar"></span>{this.props.translator['Cause']} </Form.Label>
					<Field name="causeName" component="select" type="select" className="form-control">
						<option value="">Select..</option>
						{this.renderFixCauseName(this.props.breakFixCause)}
					</Field>
				</Form.Group> */}

				{/* <Form.Group className="form-group" >
					<Form.Label><span className="rStar"></span>{this.props.translator['Resolution Code']}</Form.Label>
					<Field name="resolutionCodeName" component="select" type="select" className="form-control" onChange={(event) => { this.props.dispatch(change("provideFixTab", "resolutionCodeNameText", event.target[event.target.selectedIndex].text)); }}>
						<option value="">Select..</option>
						{this.renderFixCauseName(this.props.provideFixDropdownList)}
					</Field>
				</Form.Group> */}

				<Form.Group className="form-group" >
					<Form.Label><span className="rStar"></span>{this.props.translator['Resolution Notes']} </Form.Label>
					<Field name="fixDetails" component="textarea" maxLength={4000} className="form-control" rows={2} />
				</Form.Group>

				{ (this.props.breakFixEditDetails && this.props.breakFixEditDetails.priorityValue && ( this.props.breakFixEditDetails.priorityValue === 'P1' || this.props.breakFixEditDetails.priorityValue === 'P2' || this.props.breakFixEditDetails.priorityValue === 'Critical' ) && !this.state.hideInvestigationRadioBtn ) ?
					<Form.Group className="form-group">
						<Form.Label><span className="rStar"></span>{this.props.translator['Do you want to open a problem ticket?']} </Form.Label>
					    <br/>
						<div className="radioInlineBox float-l">
							<Field component={_radio} className='form-control' type="radio" name='create_problem_ticket' value='Yes' onChange={this.updateCreateProblemTicket} />
							<Field component={_radio} className='form-control' type="radio" name='create_problem_ticket' value='No' onChange={this.updateCreateProblemTicket} />
						</div><br />
					</Form.Group>
					: ''}
                   {this.props.breakFixEditDetails.serviceBased==true&&this.state.createProblemTicket=='Yes'?
				   <Form.Group className="form-group" 
				//    validationState={this.props.ImpactedErrorColor}
				   >
					<Form.Label><span className="rStar"></span>{this.props.translator['Impacted CI']}</Form.Label>
					<div className='position-re infoBtnDiv'>
					<Field name="ciName" className="form-control"
					onImpactedSelection={this.onImpactedSelection}
					setErrorImpactedColor = {this.props.setErrorImpactedColor}
					companyId={this.props.breakFixEditDetails.consumerCompany}
					component={CIDetailsService}
					errorColor={this.props.ImpactedErrorColor}
					showImpactedCIInfoIcon={this.showImpactedCIInfoIcon}
					onType={this.showImpactedCIInfoIcon}
					>
					</Field>
					{this.state.impIconshow?<OverlayTrigger trigger="click" rootClose placement="left" overlay={imptCI}><span className="infoBtnDiv infoicn"><ImInfo/></span></OverlayTrigger>:null}
					</div>
				</Form.Group>
	              :null}
				  {this.state.createProblemTicket==='Yes'?
				  <Form.Group className="form-group">
				        <Form.Label><span className="rStar"></span>{this.props.translator['Urgency']}</Form.Label>
						<Form.Select aria-label="select" name="urgency" value={this.state.urgencyValue} className={this.state.urgencyError==='error' ?'form-control error':"form-control"} onChange={(e) => { this.onChangeUrgency(e.target.value); }}>
						    <option value="">Select</option>
							{this.renderUrgencyName(this.state.urgencyList)}
						</Form.Select>
				  </Form.Group>
				  :
				  null}
				  {this.state.createProblemTicket==='Yes'?
				    <Form.Group className="form-group">
						<Form.Label><span className="rStar"></span>{this.props.translator['Impact']}</Form.Label>
						<Form.Select aria-label="select" name="impact" value={this.state.impactValue} className={this.state.impactError==='error' ?'form-control error':"form-control"} onChange={(e) => { this.onChangeCriticality(e.target.value); }}>
						    <option value="">Select</option>
							{this.renderCriticalityName(this.state.impactList)}
						</Form.Select>
					</Form.Group>
				  :
				  null}
				  {this.state.createProblemTicket==='Yes'?
                      <Form.Group className="form-group">
						    <Form.Label><span className="rStar"></span>{this.props.translator['Priority']}</Form.Label>
							<Field name="priority" component="input" type="text" className="form-control" disabled="true" placeholder={this.state.calculatedPriority}/>
					  </Form.Group>
				  :
				  null}
				<ButtonToolbar className="black margin-t-15">
					<Button className="rgSidrkBtn smallBtn" bsSize="small" bsStyle="primary"  disabled={submitting} onClick={handleSubmit(this.onhandleSumit)}>{submitting ? <ImSpinner6 className="icn-spinner margin-r-2"/> : null} {this.props.translator['Save']}</Button>
					<Button className="rgSiLigBtn smallBtn" bsSize="small" bsStyle="primary"  disabled={pristine || submitting} onClick={() => {this.clearForm() } }>{this.props.translator['Reset']}</Button>
				</ButtonToolbar>
				</div>
				</div>
		);
		}
		else
		{
			return(
				<div>
					<div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
						<ul>
						<li>
						<button
						    type='button'
							title="Minimize the right panel"
							bsClass=""
							bsStyle=""
							className="closerightPanelBtn"
							onClick={() => {
							this.props.rightEditPanel(false);
							}}
						>
							<IoClose />
						</button>
						</li>
						</ul>
					</div>
					<div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Provide Fix']}</div></div>
					<div className='rBoxGap'>
						{this.state.successMessage !== '' ? (this.state.successMessage) : ''}
					</div>
				</div>
			)
		}
			
		
	}
}

ProvideFixTab = reduxForm({
	form: 'provideFixTab',
	fields: [ 'category', 'subCategory', 'typeOfFix', 'create_problem_ticket'],
	initialValues: {},
	destroyOnUnmount: true

})(ProvideFixTab);

let selector = formValueSelector('provideFixTab') // <-- same as form name
ProvideFixTab = connect(
	state => {
		// can select values individually
		// let resolutionCodeName = selector(state, 'resolutionCodeName')
		let category = selector(state, 'category')
		let subCategory = selector(state, 'subCategory')
		let typeOfFix = selector(state, 'typeOfFix')

		return {
			category,
			subCategory,
			typeOfFix
		}
	}
)(ProvideFixTab)

export function mapStateToProps({ breakFixCause, provideFixDropdownList, breakFixSGAEName,breakFixEditDetails }) {
	//alert(breakFixServiceWithoutDomain);
	//console.log("breakFixEditDetails::::::>",breakFixEditDetails)
	return { breakFixCause, provideFixDropdownList, breakFixSGAEName, breakFixEditDetails };
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({loadBreakFixEditDetails, loadBreakFixStatusDetails, loadBreakFixCause, loadBreakFixEditDetailsUpdate, loadBreakFixCauseDropdown, loadBreakFixSGAE,loadNewBreakFixSGAE ,loadStatusBasedHamburgerOptions, loadTimelineData }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProvideFixTab);
