
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import {
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Image,
  Alert,
} from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { Field, change, reduxForm } from "redux-form";
import {
  TypeaheadExampleSingleSelect,
  _dropDown,
  _inputField,
  _textArea,
} from "../../../../common/formFields";
import { useState } from "react";
import { useEffect } from "react";
import { getAiosemDetails, getCategoryListAction, getEditGroupPageDetails, getForumListAction, getInitialListGroupAction, getcompanyMenuListAction } from "../../../../../actions/foundation/groupAction";
import { useParams } from "react-router";
import ListLoader from "../../../../common/loaders/ListLoader";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

let cookiesData = cookies.get('gph');
if(cookiesData) cookiesData = cookiesData.replace('s:','');
if(cookiesData) cookiesData = cookiesData.substring(0,cookiesData.lastIndexOf('.'));
cookiesData = cookiesData.split("~");
const groupNameEditable = cookiesData[63]?.toString() || '';

let GroupEditForm = (props) => {
  const [groupTypeValue, setGroupTypeValue] = useState('');
  const [isExternal, setIsExternal] = useState("false");
  const [groupTypeList, setGroupTypeList] = useState([]);
  const [selectedForum, setSelectedForum] = useState([]);
  const [forumList, setForumList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [categoryList, setCategoryList] =  useState([]);
  const [individualAssignValue, setIndividualAssignValue] = useState("");
  const initialPageValues = useSelector((state) => state.groupEditPageValues);
  const aiosemInitialDetails = useSelector((state) => state.aiosemDropdownDataForGroup);
  const loader = useSelector((state) => state.commonLoader);
  const tr = useSelector((state) => state.spcmReducer.tr);
  // const groupInitialListValues = useSelector((state) => state.groupInitialValues);
  const forumValues = useSelector((state) => state.groupForumValues);
  const categoryValues = useSelector((state) => state.groupCategoryValues);
  const companyTypeOptions = useSelector((state) => state.companyMenuListValues);

  const params = useParams();
  const dispatch = useDispatch();
  let id = params.id;

  
  useEffect(() => {
    dispatch(getEditGroupPageDetails(id));
    dispatch(getInitialListGroupAction());
    dispatch(getAiosemDetails(id));
  }, []);

    
  useEffect(() =>
  {
    if(forumValues && forumValues.length > 0)
    {
      setForumList(forumValues)
    }
  }, [forumValues])

  useEffect(() =>
  {
    if(categoryValues && categoryValues.length > 0)
    {
      setCategoryList(categoryValues)
    }

  }, [categoryValues])

  useEffect(() => {
    if ((initialPageValues && Object.keys(initialPageValues).length > 0)) {
      
      props.memberTypeFun(initialPageValues.company)
      setIsExternal(initialPageValues.isexternal)

      dispatch(getcompanyMenuListAction(initialPageValues.companyId, "Foundation", "Group Type", '1', '0'));
      dispatch(getForumListAction(initialPageValues.companyId));
      dispatch(getCategoryListAction());
      // if (initialValues == false) {
        setGroupTypeValue(initialPageValues.groupTypeId)
        let categoryObj = [{
          id: initialPageValues.categoryId,
          label: initialPageValues.category
        }]
        setSelectedCategory(categoryObj)
        dispatch(change('EditGroupForm', 'category_name', categoryObj[0].label))
        dispatch(change('EditGroupForm', 'category_id', categoryObj[0].id))
        props.setCategoryValue(categoryObj);
      

        let forumObj = [{
          id: initialPageValues.forumId,
          label: initialPageValues.forum
        }]
        setSelectedForum(forumObj)
        dispatch(change('EditGroupForm', 'forum_name', forumObj[0].label))
        dispatch(change('EditGroupForm', 'forum_id', forumObj[0].id))
        props.setForumValue(forumObj);
      //   setInitialValues(true);
      // }
    }
  }, [initialPageValues])

  useEffect(() => {
    if(aiosemInitialDetails && Object.keys(aiosemInitialDetails).length > 0){

    if (aiosemInitialDetails?.data == null) {
      dispatch(change('EditGroupForm', 'aiosemCall', 'post'))
    } else {
      dispatch(change('EditGroupForm', 'aiosemCall', 'patch'))
    }
    setIndividualAssignValue(aiosemInitialDetails?.data?.indivAsgmtEnbled);
  }
  }, [aiosemInitialDetails])
    
  useEffect(() => {
    
      if(companyTypeOptions && companyTypeOptions.length > 0)
    {
        setGroupTypeList(companyTypeOptions);
        let groupType = companyTypeOptions.filter((group) => group.field1Value == initialPageValues.groupTypeId)
        dispatch(change('EditGroupForm', 'groupTypeLabel', groupType.field1Key));
    }
  },[companyTypeOptions])
  
  
  const onSelectIsExternal = (event) => {
   
        setIsExternal(event.target.value);
        dispatch(change('EditGroupForm', 'isexternal', event.target.value));
  }
  const onGroupChange = (event) => {
    setGroupTypeValue(event.target.value);
    dispatch(change('EditGroupForm', 'groupTypeLabel', event.target[event.target.selectedIndex].text))
    setSelectedForum([]);
    props.setForumValue([]);
    dispatch(change('EditGroupForm', 'forum_name', ''));
    dispatch(change('EditGroupForm', 'forum_id', ''));
    
    setSelectedCategory([]);
    props.setCategoryValue([]);
    dispatch(change('EditGroupForm', 'category_name', ''));
    dispatch(change('EditGroupForm', 'category_id', ''));
  }
  const onIndiAssignmentChange = (event) => {
    setIndividualAssignValue(event.target.value);
    dispatch(change('EditGroupForm', 'IndivAssign', event.target[event.target.selectedIndex].text))
    if (individualAssignValue == "No" || individualAssignValue == "") {
        props.setAutoAlgoErr("");
        dispatch(change('EditGroupForm', 'autoAssignAlgo',''))
    }
  }
  const onForumSelection = (selectedForum) => {
    if (selectedForum.length > 0) { 
      setSelectedForum(selectedForum)
      dispatch(change('EditGroupForm', 'forum_name', selectedForum[0].label))
      dispatch(change('EditGroupForm', 'forum_id', selectedForum[0].id))
      props.setForumValue(selectedForum);
    } else {
      setSelectedForum([]);
      props.setForumValue([]);
      dispatch(change('EditGroupForm', 'forum_name', ''));
      dispatch(change('EditGroupForm', 'forum_id', ''));
    }
  }
  const onCrossClickForum = () => {
    setSelectedForum([]);
    props.setForumValue([]);
    dispatch(change('EditGroupForm', 'forum_name', ''));
    dispatch(change('EditGroupForm', 'forum_id', ''));
  }
  const forumInputChange = () => {
    onCrossClickForum();
  }

  const onCategorySelection = (selectedCategory) => {
    if (selectedCategory.length > 0) { 
      setSelectedCategory(selectedCategory)
      dispatch(change('EditGroupForm', 'category_name', selectedCategory[0].label))
      dispatch(change('EditGroupForm', 'category_id', selectedCategory[0].id))
      props.setCategoryValue(selectedCategory);
    } else {
      setSelectedCategory([]);
      props.setCategoryValue([]);
      dispatch(change('EditGroupForm', 'category_name', ''));
      dispatch(change('EditGroupForm', 'category_id', ''));
    }
  }
  
  const onCrossClickCategory = () => {
    setSelectedCategory([]);
    props.setCategoryValue([]);
    dispatch(change('EditGroupForm', 'category_name', ''));
    dispatch(change('EditGroupForm', 'category_id', ''));
  }
  const categoryInputChange = () => {
    onCrossClickCategory();
  }
  
  const validateEditGroupFields = (fieldType, event) => {
    switch (fieldType) {
      case "name":
        if (event.target.value == "") {
          props.setNameErr("error");
        } else {
          props.setNameErr("");
        }
        break;
      case "status":
        if (event.target.value == "") {
          props.setStatusErr("error");
        } else {
          props.setStatusErr("");
        }
        case "autoAssignAlgo":
        if (event.target.value == "") {
          props.setAutoAlgoErr("error");
        } else {
          props.setAutoAlgoErr("");
        }
        break;
      default:
    }
  };

  return (
    <>
      {loader.isLoading ? (
        <ListLoader />
      ) : (
        <Form>
          <Row>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <span className="rStar"></span>
                <Form.Label>{tr["Name"]}</Form.Label>
                <Field
                  component={_inputField}
                  name="name"
                  className={"form-control " + props.nameErr}
                  maxLength={50}
                  onBlur={(event) => {
                    validateEditGroupFields("name", event);
                  }}
                  disabled={groupNameEditable == "true" ? false : true}
                />
              </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>{tr["Group Code"]}</Form.Label>
                <Field
                  name="code"
                  className={"form-control"}
                  component={_inputField}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                <span className="rStar" />
                <Form.Label bsClass="">
                  {tr["Company"]}
                </Form.Label>
                <Field
                  name="company_name"
                  className="form-control"
                  component={_inputField}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <span className="rStar"></span>
                <Form.Label>{tr["Status"]}</Form.Label>
                <Field
                  name="status"
                  component="select"
                    className={"form-control " + (props.statusErr)}
                    onBlur={(event) => {
                      validateEditGroupFields("status", event);
                    }}
                  >
                  <option value="">{tr["Select"]}</option>
                  <option value="1">{tr["Active"]}</option>
                  <option value="0">{tr["Inactive"]}</option>
                </Field>
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>{tr["Group email address"]}</Form.Label>
                <Field
                  component={_inputField}
                  name="email_address"
                  className={"form-control"}
                  maxLength={50}
                />
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group"
               >
                <span className="rStar"></span>
                <Form.Label>{tr["Is External"]}</Form.Label>
                <Row>
                  <Col lg={4} md={6} sm={6} xs={6}>
                    <Form.Check
                      inline
                      type="radio"
                      value="true"
                      name="isexternal"
                      label="True"
                      onClick={(e)=>onSelectIsExternal(e)}
                      checked={isExternal=== "true"}
                    ></Form.Check>
                  </Col>
                  <Col lg={4} md={6} sm={6} xs={6}>
                    <Form.Check
                      inline
                      type="radio"
                      value="false"
                      name="isexternal"
                        label="False"
                        onClick={(e)=>onSelectIsExternal(e)}
                      checked={isExternal ===  "false"}
                    ></Form.Check>
                  </Col>
                </Row>
              </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>{tr["Group Type"]}</Form.Label>
                <Field
                  name="groupTypeLabel"
                  className={"form-control"}
                  component={_dropDown}
                  options={groupTypeList}
                  onChange={(e)=>onGroupChange(e)}
                ></Field>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="form-group">
                <Form.Label>{tr["Description"]} </Form.Label>
                <Field
                  name="description"
                  className={"form-control " + props.descriptionErr}
                  component={_textArea}
                  rows={1}
                />
              </Form.Group>
            </Col>
            </Row>

            <Row>
              {groupTypeValue == '50' ?
                <>
                <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                  <span className="rStar"></span>
                <Form.Label>{tr["Forum"]}</Form.Label>
                <Field
                  name="forum_name"
                  className="form-control"
                  component={TypeaheadExampleSingleSelect}
                  onSelection={onForumSelection}
                  options={forumList}
                  selectedOptions={selectedForum}
                  onCrossClick={onCrossClickForum}
                  errorClass={props.forumErr}
                  setErrorColor={props.setForumErrorColor}
                  onInputChange={forumInputChange}
                />
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <span className="rStar"></span>
                <Form.Label>{tr["Category"]}</Form.Label>
                <Field
                  name="category_name"
                  className="form-control"
                  component={TypeaheadExampleSingleSelect}
                  onSelection={onCategorySelection}
                  options={categoryList}
                  selectedOptions={selectedCategory}
                  onCrossClick={onCrossClickCategory}
                  errorClass={props.categoryErr}
                  setErrorColor={props.setCategoryErrorColor}
                  onInputChange={categoryInputChange}
                />
              </Form.Group>
                  </Col>
                </> : ''}
          </Row>
            <Row>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
            <Form.Label>{["Individual Assignment Enabled"]}</Form.Label>
            <Field
              name="IndivAssign"
              className={"form-control"}
              component="select"
              defaultSelect={individualAssignValue}
              onChange={(e)=>onIndiAssignmentChange(e)}
              >
                <option value="">{tr["Select"]}</option>
                <option value="No">{tr["No"]}</option>
                <option value="Yes">{tr["Yes"]}</option>
              </Field>
              </Form.Group> 
            </Col>
            {/* <Col md={3} sm={3} xs={6} >
              <Form.Group className="form-group">
            <Form.Label>{["Escalation Plan Enabled"]}</Form.Label>
            <Field
              name="EscPlan"
              className={"form-control"}
              component="select"
              // onChange={(e)=>onEsclationChange(e)}
            >
                <option value="">{tr["Select"]}</option>
                <option value="Yes">{tr["Yes"]}</option>
                <option value="No">{tr["No"]}</option>
              </Field>
              </Form.Group>
            </Col> */}
              { individualAssignValue == 'Yes' ?
                <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
            <span className="rStar"></span>
            <Form.Label>{["Auto Assignment Algorithm"]}</Form.Label>
            <Field
              name="autoAssignAlgo"
              className={"form-control " + (props.autoAlgoErr)}
              component="select"
              onBlur={(event) => {
                validateEditGroupFields("autoAssignAlgo", event);
              }}
            >
              <option value="">{tr["Select"]}</option>
              <option value="Round Robin">{["Round Robin"]}</option>
              <option value="Last Assignment First">{["Last Assignment First"]}</option>
              </Field>
              </Form.Group>
              </Col> : ''}
          </Row> 
        </Form>
      )}
    </>
  );
};
GroupEditForm = reduxForm({
  form: "EditGroupForm",
  enableReinitialize: true,
})(GroupEditForm);
GroupEditForm = connect(({ groupEditPageValues, aiosemDropdownDataForGroup }) => ({
  initialValues: {
    code: groupEditPageValues?.groupcode,
    name: groupEditPageValues?.name,
    status: groupEditPageValues?.active,
    company_name: groupEditPageValues?.companyName,
    description: groupEditPageValues?.description,
    email_address: groupEditPageValues?.emailAddress,
    isexternal: groupEditPageValues?.isexternal,
    groupTypeLabel: groupEditPageValues?.groupTypeId,
    company_id: groupEditPageValues?.companyId,
    forum_name: groupEditPageValues?.forum,
    forum_id: groupEditPageValues?.forumId,
    category_name: groupEditPageValues?.category,
    category_id: groupEditPageValues?.categoryId,
    nameToEdit: groupEditPageValues?.name,
    statusToEdit: groupEditPageValues?.active,
    autoAssignAlgo: aiosemDropdownDataForGroup?.data?.asgmtAlgoType,
    IndivAssign: aiosemDropdownDataForGroup?.data?.indivAsgmtEnbled,
  },
}))(GroupEditForm);

export default GroupEditForm;
