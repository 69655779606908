
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Nav, Button, NavDropdown, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Field, reduxForm, formValueSelector, change, SubmissionError } from 'redux-form';
import { loadScheduleDetailsByScheduleId } from '_Actions/onCallSchedule/schedule/onCallScheduleAction';
import { TbSubtask, TbAlignBoxLeftMiddle, TbPencil } from "react-icons/tb";
import { LuFileText, LuHistory } from "react-icons/lu";
import { IoSaveOutline, IoClose } from "react-icons/io5";
import { ImAttachment, ImSpinner6 } from "react-icons/im";
import { loadRosterDetailsByRosterId } from '_Actions/onCallSchedule/roster/onCallRosterAction';
import swal from 'sweetalert';
import CommonHeaderMenu from "../../common/CommonHeaderMenu";
import moment from "moment";
import { MyContext } from '_MyContext';
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
const cookies = new Cookies();

let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
	dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

const Header = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const translator = useSelector((state) => state.spcmReducer.tr);
	const [saveLoader, setSaveLoader] = useState(false);
	const context = useContext(MyContext);
    const closeButton = useRef(null);
    const saveObjRef = useRef(null);

    useEffect(() => {
		context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
		context.newTabPrevent();
	}, []);

	function submit(values) {
		let submitFlag = true;
		if (!values.rosterName) {
			submitFlag = false;
		}
		if (!values.shiftName) {
			submitFlag = false;
		}
		if (!values.startDate) {
			props.setStartDateErrorState('error');
			submitFlag = false;
		} else {
			props.setStartDateErrorState('');
		}
		if (!values.endDate) {
			props.setEndDateErrorState('error');
			submitFlag = false;
		} else {
			props.setEndDateErrorState('');
		}
		if (submitFlag == false)
			return false;
		if (values.startDate.format("X") > values.endDate.format("X")) {
			Swal.fire("To should be greater than from!");
			return;
		}
		if (moment(values.startDate.format("YYYY-MM-DD")).format("X") < moment(moment().format("YYYY-MM-DD")).format("X")) {
			Swal.fire("Past dates are not allowed!");
			return;
		}
		let patchObj = {};
		patchObj["rosterName"] = values.rosterName;
		patchObj["shiftId"] = values.shiftId;
		patchObj["startDate"] = moment(values.startDate, jwtdf).valueOf();
		patchObj["endDate"] = moment(values.endDate, jwtdf).valueOf();
		patchObj["sequence"] = 0;
		patchObj["status"] = "Active";
		setSaveLoader(true);
		axios.patch('/rest/aiosem/updateRosterAction/' + values.rosterId, patchObj).then(function (response) {
			if (response.status == 200) {
				setSaveLoader(false);
				dispatch(loadRosterDetailsByRosterId(props.rosterId));
			}
		}).catch(function (error) {
			setSaveLoader(false);
			swal({
				text: error.response.data.businessMessage,
				button: translator['OK'],
			});
		});
	}

	return (
		<Row className="margin-b-15">
			<Col lg={6} md={6} sm={12} xs={12}>
				{/* <h1 bsClass="" className="sPageHeading1 ">{translator["Schedule"]}</h1> */}
				<CommonHeaderMenu active="Roster" />
			</Col>
			<Col lg={6} md={6} sm={12} xs={12}>
				<div className="float-r toprigLnk">
					<Nav className="icnlnk" as="ul">
						<Nav.Item bsPrefix=" " as="li" eventKey={1}>
							<Link
								to="javascript:void(0)"
								className="anchbtn ctrlKeyPrevent"
								bsPrefix=" "
								onClick={props.handleSubmit(submit)}
								disabled={saveLoader}
								title={translator["Save"]}
								ref={saveObjRef}
							>
								{saveLoader ? <ImSpinner6 className="icn-spinner" /> : <IoSaveOutline />}
							</Link>
						</Nav.Item>
						<Nav.Item as="li">
							<Link to="/onCallSchedule/roster" ref={closeButton} className="ctrlKeyPrevent">
								<span title={translator["Cancel"]}><IoClose /></span>
							</Link>
						</Nav.Item>
					</Nav>
				</div>
			</Col>
		</Row>
	)
}

export default reduxForm({
	form: 'onCallScheduleEditRosterForm',
	destroyOnUnmount: true,
	enableReinitialize: false
})(Header);