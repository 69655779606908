
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useMemo, useState, useEffect, useContext, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { Button } from "react-bootstrap";
import { useTable, useBlockLayout } from "react-table";
import { useSticky } from "react-table-sticky";
import { TbEdit } from "react-icons/tb";
import { FaRegSave } from "react-icons/fa";
import { IoSaveOutline, IoClose } from "react-icons/io5";
import {
  useGetAllActiveQuadrantsQuery,
  useSaveSuppliersScorecardMutation,
} from "../../reducers/scorecard";
import { generateLastMonths } from "./helpers";
import windowSize from "../../reducers/windowSize";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';

const uuid = uuidv4();

const EditableCell = ({
  value: initialValue,
  row: { index },
  column: {
    id,
    name,
    isEditable,
    supplierId,
    supplierName,
    month,
    quadrantId,
    measureId,
    year,
    uuid,
  },
  updateMyData,
  editableRowIndex,
}) => {
  const [value, setValue] = useState(initialValue);

  const onChange = (e) => {
    const inputValue = e.target.value;
  //  const regex = /^[0-9]*(\.[0-9]{0,2})?$/;
    const regex = /^\d{0,4}(\.\d{0,2})?$/;
    if (regex.test(inputValue)) {
      setValue(inputValue);
    }
  };

  const onBlur = (e) => {
    if (value !== initialValue) {
      updateMyData(
        index,
        id,
        value,
        supplierName,
        supplierId,
        measureId,
        month,
        quadrantId,
        year,
        uuid
      );
    }
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return index === editableRowIndex && isEditable !== false ? (
    <input
      value={value}
      onChange={onChange}
      className="form-control"
      onBlur={onBlur}
    />
  ) : (
    <p>{value}</p>
  );
};

function convertKeysWithHash(obj) {
  const newObj = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const keyParts = key.split("#-#");
      const newKey = keyParts[keyParts.length - 1];
      if(keyParts.length===1){
        newObj[newKey] = obj[key];
      }else if(keyParts.length>0){
        if(obj[keyParts[0]] == keyParts[1] &&
          obj[keyParts[2]] == keyParts[3] &&
          obj[keyParts[4]] == keyParts[5] &&
          obj[keyParts[6]] == keyParts[7]
        ){
          newObj[newKey] = obj[key];
        }
      }
    }
  }
  return newObj;
}

const ScoreCardView = ({
  userCompanyId,
  quadrantId,
  allSuppliers = [],
  monthsData = [],
  setIsBusy,
  refetchQuardantsId,
}) => {
  const tr = useSelector((state) => state.spcmReducer.tr);
  const downloadData = useSelector(state => state.downloadData);
  const dispatch = useDispatch();

  const [editableRowIndex, setEditableRowIndex] = useState(null);
  const [data, setData] = useState([]);
  const [allColumns, setAllColumns] = useState([]);
  const [saveScoreCard, { isLoading: saveScoreCardLoading }] =
    useSaveSuppliersScorecardMutation();
    const screenSize = useSelector((state) => state.windowSize.width);

  const {
    data: QuardantsData,
    isFetching: isQFetching,
    refetch: refetchAllQuardants,
  } = useGetAllActiveQuadrantsQuery(
    {
      queryString: `?tenant_id=${userCompanyId}&quadrantId=${quadrantId}`,
    },
    {
      skip: !(quadrantId && userCompanyId),
    }
  );

  const saveScoreCardDraft = async (row, values) => {
    setIsBusy(true);
    const modifiedJsonArray = values.supplierScorecardDetails?.map((item) =>
      convertKeysWithHash(item)
    );
    const newObj = { ...values, supplierScorecardDetails: modifiedJsonArray };

    try {
      const res = await saveScoreCard({
        data: modifiedJsonArray,
        queryString: `?tenant_id=${userCompanyId}`,
      });
    } catch (error) {
      console.error("Error saving scorecard:", error);
    } finally {
      setIsBusy(false);
    }
  };

  const orderItem = isQFetching
    ? []
    : QuardantsData?.find((item) => item?.quadrantId === quadrantId);

  const {
    supplierMeasuresDetails: {
      supplierMeasure = [],
      headings = {},
      monthRange = 3,
      supplierScorecardDetails = [],
    } = {},
  } = orderItem || {};

  //  const updateMyData = (
  //   rowIndex,
  //   columnId,
  //   value,
  //   supplierName,
  //   supplierId,
  //   measureId,
  //   month,
  //   quadrantId,
  //   year,
  //   uuid
  // ) => {
  //   setData((oldData) => {
  //     return oldData.map((measure, index) => {
  //       // if (measure.measureId === measureId) {
  //       return {
  //         ...measure,
  //         supplierScorecardDetails: measure.supplierScorecardDetails?.map(
  //           (detail) => {
  //             if (
  //               index === rowIndex &&
  //               detail.supplierId === supplierId &&
  //               detail.month === month &&
  //               detail.year === year
  //             ) {
  //               return {
  //                 ...detail,
  //                 companyId: Number(userCompanyId),
  //                 [columnId]: value,
  //               };
  //             }
  //             return detail;
  //           }
  //         ),
  //       };
  //       // }
  //       return measure;
  //     });
  //   });
  // };

  const updateMyData = (
    rowIndex,
    columnId,
    value,
    supplierName,
    supplierId,
    month,
    quadrantId,
    year,
    uuid 
) => {
    setData((oldData) => {
      return oldData.map((measure, index) => {
          return {
            ...measure,
            supplierScorecardDetails: measure.supplierScorecardDetails?.map((detail) => {
                if (
                    index === rowIndex &&
                    detail.supplierId === supplierId &&
                    detail.month === quadrantId &&
                    detail.year === uuid
                ) {
                    return {
                        ...detail,
                        companyId: Number(userCompanyId),
                        [columnId]: value,
                    };
                }
                return detail;
            }),
          };
      });
    });
};

  const ActionsCell = ({ row, setEditableRowIndex, editableRowIndex }) => {
    const context = useContext(MyContext);
    const closeButton = useRef(null);
    const saveObjRef = useRef(null);

    useEffect(() => {
      if (editableRowIndex === row.index) {
        context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
      }
    }, [editableRowIndex]);

    return (
      <div
        className={
          editableRowIndex === row.index
            ? "text-center edit-mode"
            : "text-center"
        }
      >
        <p className="d-flex justify-content-center align-items-center paHedFilter">
          {editableRowIndex === row.index && (
            <Link
              className="myBt fillBtn margin-r-5"
              bsPrefix=" "
              to="javascript:void(0)"
              title={tr["Cancel"]}
              onClick={() => {
                refetchAllQuardants();
                setEditableRowIndex(null);
              }}
              ref={closeButton}
            >
              <IoClose />
            </Link>
          )}
          <Link
            onClick={async () => {
              const currentIndex = row.index;
              if (editableRowIndex !== currentIndex) {
                setEditableRowIndex(currentIndex);
              } else {
                setEditableRowIndex(null);
                const updatedRow = row.original;
                saveScoreCardDraft(row, row.original);
              }
            }}
          >
            {editableRowIndex !== row.index ? (
              <Button
                className="myBt fillBtn"
                bsPrefix=" "
                role="button"
                bsStyle="primary"
                title={tr["Edit"]}
                type="button"
              >
                <TbEdit />
              </Button>
            ) : (
              <Link
                className="myBt fillBtn"
                bsPrefix=" "
                to="javascript:void(0)"
                title={tr["Save"]}
                ref={saveObjRef}
              >
                <IoSaveOutline />
              </Link>
            )}
          </Link>
        </p>
      </div>
    );
  };

  useMemo(() => {
    const newData =
      QuardantsData?.[0]?.supplierMeasuresDetails?.supplierMeasure?.flatMap(
        (measure) => {
          const supplierScorecardDetails = allSuppliers?.flatMap((supplier) => {
            return generateLastMonths(monthRange)?.map((month) => {
              const scorecardsForMonth =
                measure?.supplierScorecardDetails?.find(
                  (scorecard) =>
                    scorecard?.month === month?.numericValue &&
                    scorecard?.supplierName === supplier.supplierName
                );
                const isScrorcardDefined = typeof scorecardsForMonth === 'undefined';
                const isDraftPercentNotDefined = isScrorcardDefined || typeof scorecardsForMonth.draftAchievedPercentage === 'undefined';
                const isDraftScoreNotDefined = isScrorcardDefined ||  typeof scorecardsForMonth.draftScore === 'undefined'

              const fieldName = `supplierName#-#${supplier.supplierName}#-#supplierId#-#${supplier.supplierId}#-#month#-#${month?.numericValue}#-#quadrantId#-#${quadrantId}#-#scorecardId`;

              return {
                measureName: measure.measureName,
                quadrantId: quadrantId,
                measureId: measure?.measureId,
                supplierName: supplier?.supplierName,
                supplierId: supplier?.supplierId,
                month: month?.numericValue,
                year: month?.year,
                draftAchievedPercentage: isDraftPercentNotDefined ? 0 : scorecardsForMonth.draftAchievedPercentage,
                draftScore: isDraftScoreNotDefined ? 0.0 : scorecardsForMonth.draftScore,
                scorecardId: scorecardsForMonth?.scorecardId,
                companyId: Number(userCompanyId),
              };
            });
          });

          return {
            measureId: measure?.measureId,
            measureName: measure.measureName,
            weightage: measure.weightage,
            score: measure.score,
            target: measure.target,
            supplierScorecardDetails,
          };
        }
      );

  const previousQuadantData = { ...downloadData };
  if(typeof QuardantsData != 'undefined' && typeof previousQuadantData[userCompanyId] == 'undefined'){
    previousQuadantData[userCompanyId] = {};
    previousQuadantData[userCompanyId][quadrantId] = [];
  }
  if(typeof QuardantsData != 'undefined' && QuardantsData[0].quadrantId == quadrantId){
    previousQuadantData[userCompanyId][quadrantId] = QuardantsData;
    dispatch({type: 'SCORECARD_PAYLOAD', payloadData: previousQuadantData});
  }
    setData(newData);
  }, [QuardantsData, monthRange, allSuppliers]);

  const defaultColumn = {
    Cell: EditableCell,
    minHeight: 50,
    width: 120,
  };
  // const defaultColumn = useMemo(
  //   () => ({
  //     Cell: EditableCell,
  //     minWidth: 100,
  //     width: 100,
  //   }),
  //   []
  // );

  const columns1 = useMemo(() => {
    const generateColumn = (heading, accessor, width, measureId) => {
      return {
        Header: " ",
        sticky: "left",
        columns: [
          {
            Header: " ",
            columns: [
              {
                Header: heading,
                accessor: accessor,
                measureId: measureId,
                width: width,
                sticky: "left",
                isEditable: false,
              },
            ],
          },
        ],
      };
    };

    const columns = Object.values(headings)?.map((heading) => {
      const supplierMeasures =
        orderItem?.supplierMeasuresDetails?.supplierMeasure;

      let column;
      switch (heading) {
        case "Measures":
          column = generateColumn("Measures", "measureName", 200);
          break;
        case "Weightage %":
          column = generateColumn(
            `Weightage % (${orderItem?.supplierMeasuresDetails?.totalWeightage}%)`,
            "weightage",
            100
          );
          break;
        case "Weightage Score":
          column = generateColumn("Weightage Score", "score", 100);
          break;
        case "Target":
          column = generateColumn("Target", "target", 100);
          break;
        default:
          // Handle other headings or defaults here
          break;
      }

      return column;
    });

    return columns;
  }, [headings, orderItem]);

  const columnsSupplier = useMemo(() => {
    return allSuppliers?.map((supplier) => {
      const supplierSubHeader = generateLastMonths(monthRange)?.map(
        (month, index) => {
          return {
            Header: `${month?.monthName} ${month?.year}`,
            align: "center",
            columns: [
              {
                Header: "Achieved %",
                supplierName: supplier.supplierName,
                supplierId: supplier.supplierId,
                month: month?.numericValue,
                quadrantId: quadrantId,
                year: month?.year,
                uuid: uuid,
                id: `supplierName#-#${supplier.supplierName}#-#supplierId#-#${supplier.supplierId}#-#month#-#${month?.numericValue}#-#quadrantId#-#${quadrantId}#-#draftAchievedPercentage`,
                accessor: (originalRow) => {
                  const scorecardsForMonth =
                    originalRow?.supplierScorecardDetails?.find(
                      (scorecard) =>
                        scorecard?.month === month?.numericValue &&
                        scorecard?.supplierName === supplier.supplierName
                    );

                  return scorecardsForMonth?.draftAchievedPercentage || "";
                },
              },
              {
                Header: "Achieved Score",
                supplierName: supplier.supplierName,
                supplierId: supplier.supplierId,
                month: month?.numericValue,
                quadrantId: quadrantId,
                year: month?.year,
                // name: "draftScore",
                uuid: uuid,
                id: `supplierName#-#${supplier.supplierName}#-#supplierId#-#${supplier.supplierId}#-#month#-#${month?.numericValue}#-#quadrantId#-#${quadrantId}#-#draftScore`,
                accessor: (originalRow) => {
                  const scorecardsForMonth =
                    originalRow?.supplierScorecardDetails?.find(
                      (scorecard) =>
                        scorecard?.month === month?.numericValue &&
                        scorecard?.supplierName === supplier.supplierName
                    );

                  return scorecardsForMonth?.draftScore || "";
                },
              },
            ],
          };
        }
      );
      return {
        Header: supplier.supplierName,
        align: "center",
        columns: supplierSubHeader,
      };
    });
  }, [allSuppliers, monthRange]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: allColumns,
        data: data || [],
        defaultColumn,
        editableRowIndex,
        setEditableRowIndex,
        updateMyData,
      },
      useBlockLayout,
      screenSize >= 992 ? useSticky : null, //Remove sticky in smaller screen
    );

  useEffect(() => {
    const mergedColumns =
      columnsSupplier.length > 0
        ? columns1?.concat(columnsSupplier.flat())
        : [];
    const actions = {
      Header: " ",
      sticky: "right",
      columns: [
        {
          Header: " ",
          columns: [
            {
              Header: "Actions",
              accessor: "actions",
              align: "center",
              Cell: ({ row, setEditableRowIndex, editableRowIndex }) => {
                return (
                  <ActionsCell
                    row={row}
                    setEditableRowIndex={setEditableRowIndex}
                    editableRowIndex={editableRowIndex}
                  />
                );
              },
            },
          ],
        },
      ],
    };
    mergedColumns.push(actions);
    setAllColumns(mergedColumns);
  }, [columnsSupplier, columns1]);

  return (
    orderItem?.quadrantName && allColumns.length > 0 && <div>
      <h3 className="text-black fs-5 fw-bold my-1 py-2">
        {orderItem?.quadrantName}
      </h3>
      <div className="styled-table">
        <div
          {...getTableProps()}
          className="score-card-table table sticky"
          style={{
            width: "100%",
            height: `${Math.min(234, 95 * rows?.length)}px`,
          }}
          key={orderItem?.quadrantId}
        >
          <div className="header">
            {headerGroups.map((headerGroup) => {
              return (
                <div {...headerGroup.getHeaderGroupProps()} className="tr">
                  {headerGroup.headers.map((column) => {
                    return (
                      <div
                        {...column.getHeaderProps()}
                        className={column?.align ? "text-center th" : "th"}
                      >
                        {column.render("Header")}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div {...getTableBodyProps()} className="body">
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className="tr">
                  {row.cells.map((cell) => {
                    return (
                      <div
                        {...cell.getCellProps()}
                        className={
                          editableRowIndex === row.index ? "edit-mode td" : "td"
                        }
                      >
                        {cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScoreCardView;
