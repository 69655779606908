
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { useEditor } from "@grapesjs/react";
import { Button, DropDownButton,DropDownButtonItem,SplitButton} from "@progress/kendo-react-buttons";
import {undoIcon,bordersAllIcon,fullscreenIcon,codeIcon,redoIcon,wrenchIcon,jsIcon,copyIcon,bringForwardIcon,saveIcon, uploadIcon,gearIcon,moreVerticalIcon} from "@progress/kendo-svg-icons";
import { IoSaveOutline, IoClose, IoCompassOutline } from "react-icons/io5";
import { FaEye, FaPencil,FaTrash} from "react-icons/fa6";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useEffect, useState } from "react";
import { cx, getFormNamesFields, hasDuplicates } from "../utils";
import { useNavigate, useParams } from "react-router";
import { gql, useMutation } from "@apollo/client";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import { Col, Modal, Row } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { Fragment } from "react";
import { TypeaheadExampleSingleSelect } from "../../../common/formFields";
import axios from "axios";
import { Link } from "react-router-dom";
import { ImSpinner6 } from "react-icons/im";
import { title } from "process";
import HeaderStatus from "./headerStatus";
import { CreateAndUpdatePublishedPage } from "../../graphQl/GraphqlQueries";


const cookies = new Cookies();
const gph = cookies.get("gph").split("~");
const username = typeof gph[32] !== "undefined" ? gph[32] : "";
const userId = typeof gph[43] !== "undefined" ? gph[43] : "";
const userFullName = typeof gph[42] !== "undefined" ? gph[42] : "";
const roles = typeof gph[33] !== "undefined" ? gph[33].split(",") : [];
const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

const UpdateForm = gql`
  mutation UpdateForm($id: String!, $CreateFormInput: CreateFormInput!) {
    updateForm(id: $id, CreateFormInput: $CreateFormInput) {
      _id
      formObjects
    }
  }
`;

const CreateForm = gql`
  mutation CreateForm($CreateFormInput: CreateFormInput!) {
    createForm(CreateFormInput: $CreateFormInput) {
      _id
      formObjects
    }
  }
`;

const CopyForm = gql`
  mutation CopyForm($id: String!, $formData: CreateFormInput!) {
    copyForm(id: $id, formData: $formData) {
      _id
      formObjects
    }
  }
`;

const saveAsPopupItems = [
  {
    text: "Save as",
  },
];

export const SaveFormComponent = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const [formName, setFormName] = useState("");
  const [pageType, setPageType] = useState("");
  const [viewForm, setViewForm] = useState(false);
  const [typedCompany, setTypedCompany] = useState("");
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState({});
  const [companyError, setCompanyError] = useState("");
  const [formNameError, setFormNameError] = useState(""); 
  const root = document.documentElement;
  const { pageDetail, statusObject } = props;
  const [disableButton,setDisableBtn] = useState(false);
  const [savingType,setSavingType] = useState("");

  viewForm
    ? root?.style.setProperty("--modalBackDrop", 0.5)
    : root?.style.setProperty("--modalBackDrop", 0);
  const valueChange = (e) => {
    if (e.target.name == "formName") {
      setFormNameError("")
      setFormName(e.target.value);
    }
  };

  const [createForm, { data: createResp, loading, reset }] = useMutation(CreateForm);
  const [copyForm, { data: copyResp, loading: copyApiLoader, reset: copyApiReset }] = useMutation(CopyForm);

  const [createAndUpdatePublishedPage, { data: publishedPageData }] = useMutation(CreateAndUpdatePublishedPage);

  const onCompanySelection = (company) => {
    if(props.mode === "copy") {
      setSelectedCompanies({
        ids: company.map(obj => obj.id).join(";"),
        labels: company.map(obj => obj.label).join(";")
      })
    }
    setCompanyError("");
    setSelectedCompany(company);
  };

  const onCrossClickCompany = () => {
    setSelectedCompany([]);
    setCompanyError("error");
  };

  const companyCleared = (e) => {
    setTypedCompany(e);
  };

  const [
    updateForm,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useMutation(UpdateForm);

  useEffect(() => {
    if(props?.isEditMode) {
      setViewForm(true);
    } else {
      setViewForm(false);
    }
  }, [props.isEditMode])
  useEffect(() => {
    if (copyResp?.copyForm?.length > 0) {
      setViewForm(false);
      props.setIsEditMode(false);
      Swal.fire("Form Copied Successfully");
      copyApiReset();
      props.refetchFormList();
      setSelectedCompanies({});
      setSelectedCompany([]);
      setTypedCompany("");
      setFormName("");
      setPageType("");
    }
  }, [copyResp]);

  const saveForm = () => {
    const formObjects = localStorage.getItem("gjsProject");
    const formsName = getFormNamesFields();
    if (hasDuplicates(formsName)) {
      Swal.fire({
        title:
          "Please check name attributes in every component it should be unique!",
      });
      return;
    } else {
      setDisableBtn(true);
      if (params.formId && !viewForm) {
        const formData = JSON.parse(localStorage.getItem("formDetail"));

        let postObjects = {
          name: pageDetail?.name,
          formObjects: formObjects,
          createdBy: pageDetail?.createdBy,
          createdByFullname: pageDetail?.createdByFullname || "",
          createdById: pageDetail?.createdById || 0,
          updatedBy: username,
          updatedByFullname: userFullName,
          updatedById: parseInt(userId, 10),
          companyName: formData?.companyName,
          ruleData: "",
          status: true,
        };
        if (formData?.customScript) {
          postObjects.customScript = formData.customScript;
        }
        if (localStorage.getItem("ruleData")) {
          postObjects.ruleData = localStorage.getItem("ruleData");
        }
        if (props?.customPageName) {
          postObjects.name = props.customPageName;
        }
        updateForm({
          variables: { id: params.formId, CreateFormInput: postObjects },
          onError(err) {
            setDisableBtn(false);
            Swal.fire(err.message);
          },
          onCompleted(data) {
            let dataObject = {
              companyId: parseInt(pageDetail?.companyId, 10),
              companyName: pageDetail?.companyName,
              forms: pageDetail?._id,
              status: statusObject?.status,
            };
            if (statusObject.status) {
              createAndUpdatePublishedPage({
                variables: {
                  createParams: dataObject,
                },
                onError(error) {
                  setDisableBtn(false);
                },
                onCompleted(data) {
                  setDisableBtn(false);
                  props?.setIsEdit(false);
                  Swal.fire("Data has been updated successfully!");
                  props.formDetailRefetch();
                },
              });
            } else {
              setDisableBtn(false);
              props?.setIsEdit(false);
              Swal.fire("Data has been updated successfully!");
            }
          },
        });
      } else if (props.mode === "copy") {
        const {
          pageType,
          formObjects: formJSON,
          _id,
        } = props?.formValues || {};
        let postObject = {
          name: formName,
          pageType: pageType,
          formObjects: formJSON,
          createdBy: username,
          createdByFullname: userFullName,
          createdById: parseInt(userId, 10),
          updatedBy: username,
          updatedByFullname: userFullName,
          updatedById: parseInt(userId, 10),
          companyIds: "",
          companyNames: "",
          status: true,
          isPublished: false,
          ruleData: "",
        };
        if (formName !== "" && selectedCompany.length > 0) {
          postObject.companyIds = selectedCompanies.ids;
          postObject.companyNames = selectedCompanies.labels;
          copyForm({
            variables: { id: _id, formData: postObject },
            onCompleted(data) {
              setDisableBtn(false);
            },
          });
        } else {
          setDisableBtn(false);
          if (selectedCompany.length == 0) {
            setCompanyError("error");
          }
          if (formName == "") {
            setFormNameError("error");
          }
        }
      } else {
        let postObjects = {
          name: formName,
          pageType: "form",
          formObjects: formObjects,
          createdBy: username,
          createdByFullname: userFullName,
          createdById: parseInt(userId, 10),
          updatedBy: username,
          updatedByFullname: userFullName,
          updatedById: parseInt(userId, 10),
          companyId: 0,
          companyName: "",
          status: true,
          isPublished: false,
          ruleData: "",
        };
        if (formName !== "" && selectedCompany.length > 0) {
          if (!formObjects) {
            setViewForm(false);
            setDisableBtn(false);
            Swal.fire({ title: "Please add some components in canvas!" });
            return;
          }
          postObjects.companyId = parseInt(selectedCompany[0].id, 10);
          postObjects.companyName = selectedCompany[0].label;
          createForm({
            variables: { CreateFormInput: postObjects },
            onError(error) {
              setDisableBtn(false);
              Swal.fire(error.message);
            },
            onCompleted(data) {
              Swal.fire({
                title: "Your form has been saved successfully!",
                icon: "success",
              }).then(() => {
                if (savingType == "Save as") {
                  setSavingType("");
                  setFormName("");
                  setSelectedCompany([]);
                  setViewForm(false);
                  setDisableBtn(false);
                } else {
                  navigate("/uiBuilder/" + data?.createForm?._id);
                }
              });
            },
          });
        } else {
          setDisableBtn(false);
          if (selectedCompany.length == 0) {
            setCompanyError("error");
          }
          if (formName == "") {
            setFormNameError("error");
          }
        }
      }
    }
  };

  return (
    <Fragment>
      <Modal
        size="lg"
        show={viewForm}
        onHide={() => {
          setViewForm(false);
          props.setIsEditMode(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="escalation-level-form"
      >
        <Modal.Header>
          <Row>
            <Col lg={6} md={6} sm={12} xs={12}>
              <h2 className="sPageHeading1">{pageDetail?.companyId==0?"Page Detail":"Replica Page Detail"}</h2>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <div className="paHedFilter">
                <Button
                  bsPrefix=" "
                  id="creTag"
                  role="button"
                  className="myBt plus popbtn"
                  disabled={updateLoading || loading}
                  onClick={() => {
                    saveForm();
                  }}
                  title={"Save"}
                >
                  {loading ? (
                    <ImSpinner6 className="icn-spinner" />
                  ) : (
                    <IoSaveOutline />
                  )}
                </Button>
                <Button bsPrefix = ' ' title="Close" role="button"  className="myBt popbtn" onClick={() => {setViewForm(false);props.setIsEditMode(false);}}><IoClose /></Button>
              </div>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12} sm={12} xs={12}>
              <Form.Group className="dvTypehdropdown-group form-group">
                <Form.Label>
                  <span className="rStar"></span>
                  {"Company"}
                </Form.Label>
                <div className="minHghtdvTypehd">
                  <TypeaheadExampleSingleSelect
                    id="companyDropdown"
                    name="company"
                    className="form-control"
                    onSelection={onCompanySelection}
                    setErrorColor={() => {}}
                    options={props?.companyList.length>0?props?.companyList.filter(res=>res.id!==0):[]}
                    selectedOptions={selectedCompany}
                    onInputChange={companyCleared}
                    onCrossClick={onCrossClickCompany}
                    typedValue={typedCompany}
                    errorClass={companyError}
                    multiple={props.mode === "copy"}
                  />
                </div>
              </Form.Group>
            </Col>
            <Col md={12} sm={12} xs={12}>
              <Form.Group className="dvTypehdropdown-group form-group">
                <Form.Label>
                  <span className="rStar"></span>
                  {"Page Name"}
                </Form.Label>
                <div className="minHghtdvTypehd">
                  <Form.Control
                    type="text"
                    name="formName"
                    className={`form-control ${formNameError}`}
                    onChange={valueChange}
                    value={formName}
                    maxLength={40}
                  ></Form.Control>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      {props.mode !== "copy" && (
        // <Button
        //   fillMode="outline"
        //   onClick={() => {
        //     if (params?.formId && (pageDetail.companyId!==0 || roles.includes("70"))) {
        //       saveForm();
        //     } else {
        //       setViewForm(true);
        //     }
        //   }}
        //   title={pageDetail?.companyId==0 && !roles.includes("70")?"Create Replica":"Save"}
        //   className="k-active"
        //   disabled={disableButton}
        // >
        //   {disableButton ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/> }
        // </Button>
        <SplitButton
          disabled={disableButton}
          onButtonClick={() => {
            if (
              params?.formId &&
              (pageDetail.companyId !== 0 || roles.includes("70"))
            ) {
              saveForm();
            } else {
              setViewForm(true);
            }
          }}
          onItemClick={(e) => {
            if (e.item?.text == "Save as") {
              setViewForm(true);
              setSavingType("Save as");
            }
          }}
          className="k-active"
          fillMode="outline"
          items={saveAsPopupItems}
          iconClass={disableButton ? "fa fa-spinner" : "fa fa-floppy-o"}
          title="Save"
          popupSettings={{ animate: false, popupClass: "tLnkPopup" }}
        />
        
      )}
    </Fragment>
  );
};

export default function TopbarButtons({ className, companyList, pageDetail, formDetailRefetch,...props }) {
  const editor = useEditor();
  const [, setUpdateCounter] = useState(0);
  const { UndoManager, Commands } = editor;
  const navigate = useNavigate();
  const [statusObject,setStatusObject] = useState({status:"",selectedMeny:""});

  useEffect(()=>{
    if(pageDetail && pageDetail?.publishedPage){
      setStatusObject({...statusObject,status:pageDetail?.publishedPage?.status})
    }
  },[pageDetail])

  const cmdButtons = [
    {
      id: "core:component-outline",
      iconPath: bordersAllIcon,
      title:"Selection",
    },
    // {
    //   id: "core:fullscreen",
    //   iconPath: fullscreenIcon,
    //   options: { target: "#root" },
    //   title:"Full Screen"
    // },
    // {
    //   id: "core:open-code",
    //   iconPath: codeIcon,
    //   title:"View Code"
    // },
    {
      id: "core:undo",
      iconPath: undoIcon,
      disabled: () => !UndoManager.hasUndo(),
      title:"Undo"
    },
    {
      id: "core:redo",
      iconPath: redoIcon,
      disabled: () => !UndoManager.hasRedo(),
      title:"Redo"
    },
    // {
    //   id: "rule-modal",
    //   iconPath: wrenchIcon,
    //   title:"Rules"
    // },
    // {
    //   id: "customScript-modal",
    //   iconPath: jsIcon,
    //   title:"Custom Script"
    // },
    {
      id: "copy-component",
      iconPath: copyIcon,
      title:"Copy Component"
    },
    {
      id: "paste-component",
      iconPath: bringForwardIcon,
      title:"Paste Component"
    },
  ];

  useEffect(() => {
    const cmdEvent = "run stop";
    const updateEvent = "update";
    const updateCounter = () => setUpdateCounter((value) => value + 1);
    const onCommand = (id) => {
      cmdButtons.find((btn) => btn.id === id) && updateCounter();
    };
    editor.on(cmdEvent, onCommand);
    editor.on(updateEvent, updateCounter);

    return () => {
      editor.off(cmdEvent, onCommand);
      editor.off(updateEvent, updateCounter);
    };
  }, []);

  return (
    <div className={cx("d-flex gap-2", className)}>
      <HeaderStatus companyList={companyList} pageDetail={pageDetail} formDetailRefetch={formDetailRefetch} setStatusObject={setStatusObject} statusObject={statusObject}/>
      {cmdButtons.map(({ id, iconPath,title, disabled, options = {} }) => (
        <Button
          fillMode="outline"
          key={id}
          title={title}
          svgIcon={iconPath}
          disabled={disabled?.()}
          onClick={() =>
            Commands.isActive(id)
              ? Commands.stop(id)
              : Commands.run(id, options)
          }
        ></Button>
      ))}

      <DropDownButton className="theDotbtn" title="More" svgIcon={moreVerticalIcon} onItemClick={(event,options = {}) => Commands.isActive(event.item.id) ? Commands.stop(event.item.id) : Commands.run(event.item.id, options)} popupSettings={{animate: false,popupClass: "tLnkPopup",}}>
        <DropDownButtonItem id="core:open-code" text="View Code" />
        <DropDownButtonItem id="rule-modal" text="Rules" />
        <DropDownButtonItem id="customScript-modal" text="Custom Script" />
      </DropDownButton>

      <Button className="margin-l-10" fillMode="outline" title="Preview" onClick={() => {navigate("/preview/" + pageDetail?._id)}}>
        <FaEye />
      </Button>
      
      <SaveFormComponent companyList={companyList} pageDetail={pageDetail} customPageName={props?.customPageName} statusObject={statusObject} formDetailRefetch={formDetailRefetch} setIsEdit={props?.setIsEdit}/>

      
      <Button fillMode="outline" title="Back to List" onClick={() => {
        navigate("/formLists", { state: {...props?.navigationProps} });
      }}>
        <IoClose />
      </Button>
    </div>
  );
}
