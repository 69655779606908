
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export default function(state = initialState.hamburgerCategoryList, action){
    switch (action.type){
        case 'FETCH_HAMBURGERCATEGORYLIST':
            return action.hamburgerCategoryList.data;
        default:
          return state;
    }
}
