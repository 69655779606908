
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';

import { GLOBAL } from '_Globals';

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

export function componentPackageSuccess(components) {
	return {
		type: 'COMPONENT_PACKAGE',
		components
	};
}

export function loadComponentPackage(offeringId) {
	//each time an offering is click in categoryList an new api call with offeringId as parameter wiil be hit to get new componentPackage corresponding to offering hit.
	return (dispatch) => {
		var str={};
		str.userId="";
		str.offeringId=offeringId;
		str = JSON.stringify(str);
		dispatch({type:'FETCHING_DETAILS_IN_PROGRESS'});
		api.get(GLOBAL.getComponentDetails,{headers:{'query':str}})
			.then((componentPackage) => {
				if (!componentPackage) {
					throw Error(componentPackage.statusText);
				}
				return componentPackage;
			})
			.then((componentPackage) => {
				dispatch({type:'DETAILS_FETCHED_SUCCESSFULLY'});
				dispatch(componentPackageSuccess(componentPackage));
			})
			.catch((err) => {
				console.log(err);
			});
	};
}
