
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import ReactDOM from "react-dom";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';

import { connect } from "react-redux";
import Spinner from "react-spinkit";
import { getMyApprovalList } from "_Actions/MyApprovals/MyApprovalActions";
import { getApprovalDisclaimiers } from '_Actions/spcmActions';
import _ from 'lodash';
import {IoSearch} from 'react-icons/io5';


import {
  loadChangeTotalApprovalDetails,
  loadChangeTotalApprovalHistoryDetails
} from "_Actions/changeManagement/changeTotalApprovalAction";
import { loadImplPlanList } from '_Actions/common/attachmentAction';
let status = "pending";
let ModuleValue = [10, 14];
const userId = "1";
import Pagination from "react-js-pagination";
import ListLoader from "_Commons/loaders/ListLoader"
import { getQuestionsCategoryWise } from '_Actions/cart/myOrders/myOrdersAction';
import { loadProfile } from '_Actions/myProfile/profileAction';
import { loadBreakFixActivityLogDetails } from '_Actions/breakFix/breakFixActivityLogAction'
import { loadFulfillmentDetails } from '_Actions/fulfillment/fulfillmentDataAction';
import { loadChangeEditData } from '_Actions/changeManagement/changeManagementAction.js';
import { getRFCApprovalDisclaimiers } from '_Actions/spcmActions';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

const Joi = require('joi');

class ApprovalList extends React.Component {
  constructor(props) {
    super();
    this.state = {
      page: "1",
      faCustomDesc1: "customArrow dropdown",
      faCustomAsc1: "customArrow dropup",
      faCustomDesc2: "customArrow dropdown",
      faCustomAsc2: "customArrow dropup",
      faCustomDesc3: "customArrow dropdown",
      faCustomAsc3: "customArrow dropup",
      faCustomDesc4: "customArrow dropdown isActive ",
      faCustomAsc4: "customArrow dropup",
      faCustomDesc5: "customArrow dropdown",
      faCustomAsc5: "customArrow dropup",
      searchInput: "",
      ciServiceSearchFilter: "",
      submittedDateSearchFilter: "",
      requestedBySearchFilter: "",
      statusSearchFilter: "",
      displaySearchFilter: "",
      sortBy: true,
      searchBy: "status",
      searchByVal: "Pending",
      lastSelectedSearchFilter: "submittedOn",
      isMultiSelectOpen: true,
    };
   
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSizePerPageChange = this.handleSizePerPageChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleCallback = this.handleCallback.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onRowSelect = this.onRowSelect.bind(this);
    this.clickOutside = this.clickOutside.bind(this);
  }

  componentWillMount() {
    this.getDataFromService({
      userId, sorttype: "desc", sortval: "submittedOn",
      status: status,
      page: this.state.page,
      limit: this.props.limit,
      ModuleValue
    });
  }

  componentDidUpdate(prevProps) {
    if (this.multiSelectDropdownRef != undefined || this.multiSelectDropdownRef != null) {
      ReactDOM.findDOMNode( this.multiSelectDropdownRef ).childNodes[0].className = "multiSelectRef";
      let myRef = this.multiSelectDropdownRef;
      if (this.props.isStatusOpen != prevProps.isStatusOpen) {
        let obj = ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1];
        if (obj != undefined || obj != null) {
          ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1].className =
            "multiSelectPopup";
        }
      }
    }
  }

  clickOutside(event) {
    const statusDv = document.getElementById("statusDv");

    if (this.multiSelectDropdownRef != undefined || this.multiSelectDropdownRef != null) {
      document.addEventListener("mousedown", (event) => {
        if (statusDv.contains(event.target)) {
          this.setState({ isMultiSelectOpen: true });
          this.props.showRightSide(false);
        }
        else {
          this.setState({ isMultiSelectOpen: false });
        }
        this.props.multiSelectOpenFun("myAprvlStatus", false);
      });
    }
  }

  handlePageChange(e) {

    this.props.showRightSide(false);
    this.getDataFromService({
      userId, sorttype: this.props.sortOrder, sortval: this.props.sortOnColumn,
      status: this.props.status,
      page: e,
      limit: this.props.limit,
      ModuleValue: this.props.modules,
      searchby: this.props.searchKey,
      searchbyVal: this.props.searchValue
    });
    this.props.itemDisclaimerChange(false);
    this.props.approveRejSecHide();
    this.props.showActiveRow(0);
  }

  handleSizePerPageChange(e) {
    this.getDataFromService({
      userId, sorttype: this.props.sortOrder, sortval: this.props.sortOnColumn,
      status: this.props.status,
      page: this.state.page,
      limit: e.target.value,
      ModuleValue: this.props.modules,
      searchby: this.props.searchKey,
      searchbyVal: this.props.searchValue
    });

    this.props.itemDisclaimerChange(false);
    this.props.approveRejSecHide();
    this.props.updatePagination(e.target.value);
    this.props.showActiveRow(0);
  }

  onRowSelect(e, item, index, cls) {
    this.props.showRightSide(true);
    this.props.getLoadRerenderApis(false);
    this.props.approveRejSecHide();
    this.props.setApprovalDetailsObj(item);
    this.props.resetAccordiansState(); 

    this.props.itemDisclaimerChange(false);
    if (item.signaturelist[0][0].item_number.indexOf("ITM") >= 0) {
      this.props.getApprovalDisclaimiers(item.signaturelist[0][0].offeringid);
      this.props.getQuestionsCategoryWise(item.signaturelist[0][0].itemid);
      this.props.loadBreakFixActivityLogDetails(item.signaturelist[0][0].itemid, "", "Fullfilment", () => { }, "Y", "LEFT");
      this.props.loadProfile(item.signaturelist[0][0].requesterid);
      this.props.loadFulfillmentDetails(item.signaturelist[0][0].itemid);
      this.props.loadChangeTotalApprovalDetails(item.signaturelist[0][0].itemid, "10", "N");
    }

    if (item.signaturelist[0][0].item_number.indexOf("RFC") >= 0) {
      this.props.loadImplPlanList("ChangeManagement", item.signaturelist[0][0].itemid);
      this.props.loadBreakFixActivityLogDetails(item.signaturelist[0][0].itemid, "", "ChangeManagement", () => { }, "Y", "LEFTCHNG");
      this.props.loadProfile(item.signaturelist[0][0].requesterid);
      this.props.loadChangeEditData(item.signaturelist[0][0].itemid);
      this.props.getRFCApprovalDisclaimiers(item.signaturelist[0][0].ciid);
      this.props.loadChangeTotalApprovalDetails(item.signaturelist[0][0].itemid, "14", "N");
    }
    else {
      this.props.loadImplPlanList("Item", item.signaturelist[0][0].itemid);
    }
    this.props.showActiveRow(index);
  }

  renderApprovalData(approvalData) {
    return approvalData.map((item, index) => {
      let cls = "";
      if (index == this.props.rowIndex) {
        cls = "myActive";

      } else {
        cls = "";
      }
      let itemStatus = "";
      let ciServiceName = "";
      ciServiceName = item.signaturelist[0][0].offering_name != undefined ?
        (item.signaturelist[0][0].ciname != null && item.signaturelist[0][0].ciname != "0" && item.signaturelist[0][0].ciname != "" && item.signaturelist[0][0].ciname != "null") ?
          item.signaturelist[0][0].ciname :
          item.signaturelist[0][0].offering_name : "";
      if (item.approvalstatuslist[0][0].approvalstatus == "null") {
        itemStatus = "Pending for approval";
      } else {
        itemStatus = item.approvalstatuslist[0][0].approvalstatus;
      }
      return (
        <tr className={this.props.isRightSideVisible && index == this.props.rowIndex ? "myActive" : ""} onClick={(e) => this.onRowSelect(e, item, index)}>
          <td><Button bsClass='' className='rowFocus'></Button><div className='showdv414'><div className='valdv414'>{item.displayNumber}<div className='keydv414'>{ciServiceName.slice(0, 70)}{ciServiceName.length > 70 ? "..." : ""}</div></div></div></td>
          <td className="hiddenBe414" title={ciServiceName}><div className='showdv414'><div className='keydv414'>{this.props.tr['Service/CI Name']}</div><div className='valdv414'>{ciServiceName.slice(0, 70)}{ciServiceName.length > 70 ? "..." : ""}</div></div></td>
          <td><div className='showdv414'><div className='keydv414'>{this.props.tr['Requested For']}</div><div className='valdv414'>{item.signaturelist[0][0].requester != undefined ? item.signaturelist[0][0].requester : ""}</div></div></td>
          <td><div className='showdv414'><div className='keydv414'>{this.props.tr['Submitted Date']}</div><div className='valdv414'>{item.signaturelist[0][0].requestedDate != undefined ? item.signaturelist[0][0].requestedDate.replace(".0", "") : ""}</div></div></td>
          <td><div className='showdv414'><div className='keydv414'>{this.props.tr['Status']}</div><div className='valdv414'>{this.props.tr[itemStatus]}</div></div></td>
        </tr>
      );
    }
    );
  }

  getDataFromService({
    userId, sorttype,
    sortval,
    status,
    page,
    limit,
    ModuleValue,
    searchby, searchbyVal
  }) {

    this.props.getMyApprovalList(
      userId, sorttype,
      sortval,
      status,
      page,
      limit,
      ModuleValue,
      searchby, searchbyVal
    );
  }

  handleSearch(e, picker,val) {
    let searchByVar = [];
    let searchByValue = [];
    let dateArr = [];
    this.props.isClearAllFilterFunc(true);
    if (this.props.displayNumberSearchInput != "") {
      searchByValue.push(this.props.displayNumberSearchInput.trim());
      searchByVar.push(this.state.displaySearchFilter);
    }
    if (this.props.ciServiceNameSearchInput != "") {
      searchByValue.push(this.props.ciServiceNameSearchInput.trim());
      searchByVar.push(this.state.ciServiceSearchFilter);
    }
    if (this.props.requestedBySearchInput != "") {
      searchByValue.push(this.props.requestedBySearchInput.trim());
      searchByVar.push(this.state.requestedBySearchFilter);
    }
    if (picker) {
      this.props.setRef(picker);
      dateArr.push(moment(picker.startDate).format("MM-DD-YYYY"), moment(picker.endDate).format("MM-DD-YYYY"));
      this.props.setStartAndEndDate(picker.startDate.format('MM/DD/YYYY'), picker.endDate.format('MM/DD/YYYY'));
      searchByValue.push(dateArr.join(","));
      searchByVar.push(val);
    }
    if (picker == undefined && !_.isEmpty(this.props.startDate) && !_.isEmpty(this.props.endDate)) {
      dateArr.push(moment(this.props.startDate).format("MM-DD-YYYY"), moment(this.props.endDate).format("MM-DD-YYYY"));
      searchByValue.push(dateArr.join(","));
      searchByVar.push("submittedOn");
    }

    this.getDataFromService({
      userId, sorttype: this.props.sortOrder, sortval: this.props.sortOnColumn,
      status: this.props.status,
      page: this.state.page,
      limit: this.props.limit,
      ModuleValue: this.props.modules,
      searchby: searchByVar.join(","),
      searchbyVal: searchByValue.join("|")
    });
    this.props.showRightSide(false);
    this.props.setSearchKeyValue(searchByVar.join(","), searchByValue.join("|"));
  }

  handleChange(e, searchValue) {
    switch (searchValue) {
      case "displayNumber":
        this.props.setdisplayNumberInputValues(e.target.value);
        this.setState({ displaySearchFilter: searchValue });
        break;
      case "serviceName":
        this.props.setCiServiceNameInputValues(e.target.value);
        this.setState({ ciServiceSearchFilter: searchValue });
        break;
      case "requestedBy":
        this.props.setRequestedByInputValues(e.target.value);
        this.setState({ requestedBySearchFilter: searchValue });
        break;
    }
  }


  onSort(sortBy, sortValue, sorttype, lastFilter) {
    let orderBy = "";
    if (lastFilter == sortValue) {
      this.setState({ sortBy: !sortBy });
      orderBy = sorttype;

    }
    else if (lastFilter != sortValue) {
      this.setState({ sortBy: "desc" });
      orderBy = "desc";
    }

    this.getDataFromService({
      userId, sorttype: orderBy, sortval: sortValue,
      status: this.props.status,
      page: this.state.page,
      limit: this.props.limit,
      ModuleValue: this.props.modules,
      searchby: this.props.searchKey,
      searchbyVal: this.props.searchValue
    });
    this.props.setSortOrder(orderBy, sortValue);
    switch (sortValue) {
      case 'displayNumber':
        this.setState({ lastSelectedSearchFilter: 'displayNumber' });
        this.setState({
          faCustomDesc1: orderBy == "asc" ? "customArrow dropdown" : orderBy == "desc" ? "customArrow dropdown isActive" : "",
          faCustomAsc1: orderBy == "asc" ? "customArrow dropup isActive" : orderBy == "desc" ? "customArrow dropup" : "",
          faCustomDesc2: "customArrow dropdown",
          faCustomAsc2: "customArrow dropup",
          faCustomDesc3: "customArrow dropdown",
          faCustomAsc3: "customArrow dropup",
          faCustomDesc4: "customArrow dropdown",
          faCustomAsc4: "customArrow dropup",
          faCustomDesc5: "customArrow dropdown",
          faCustomAsc5: "customArrow dropup",
          faCustomDesc6: "customArrow dropdown",
          faCustomAsc6: "customArrow dropup"

        });
        break;
      case 'serviceName':
        this.setState({ lastSelectedSearchFilter: 'serviceName' });
        this.setState({
          faCustomDesc2: orderBy == "asc" ? "customArrow dropdown" : orderBy == "desc" ? "customArrow dropdown isActive" : "",
          faCustomAsc2: orderBy == "asc" ? "customArrow dropup isActive" : orderBy == "desc" ? "customArrow dropup" : "",
          faCustomDesc1: "customArrow dropdown",
          faCustomAsc1: "customArrow dropup",
          faCustomDesc3: "customArrow dropdown",
          faCustomAsc3: "customArrow dropup",
          faCustomDesc4: "customArrow dropdown",
          faCustomAsc4: "customArrow dropup",
          faCustomDesc5: "customArrow dropdown",
          faCustomAsc5: "customArrow dropup",
        });
        break;
      case 'requestedBy':
        this.setState({ lastSelectedSearchFilter: 'requestedBy' });
        this.setState({
          faCustomDesc3: orderBy == "asc" ? "customArrow dropdown" : orderBy == "desc" ? "customArrow dropdown isActive" : "",
          faCustomAsc3: orderBy == "asc" ? "customArrow dropup isActive" : orderBy == "desc" ? "customArrow dropup" : "",
          faCustomDesc1: "customArrow dropdown",
          faCustomAsc1: "customArrow dropup",
          faCustomDesc2: "customArrow dropdown",
          faCustomAsc2: "customArrow dropup",
          faCustomDesc4: "customArrow dropdown",
          faCustomAsc4: "customArrow dropup",
          faCustomDesc5: "customArrow dropdown",
          faCustomAsc5: "customArrow dropup"
        });
        break;
      case 'submittedOn':
        this.setState({ lastSelectedSearchFilter: 'submittedOn' });
        this.setState({
          faCustomDesc4: orderBy == "asc" ? "customArrow dropdown" : orderBy == "desc" ? "customArrow dropdown isActive" : "",
          faCustomAsc4: orderBy == "asc" ? "customArrow dropup isActive" : orderBy == "desc" ? "customArrow dropup" : "",
          faCustomDesc1: "customArrow dropdown",
          faCustomAsc1: "customArrow dropup",
          faCustomDesc2: "customArrow dropdown",
          faCustomAsc2: "customArrow dropup",
          faCustomDesc3: "customArrow dropdown",
          faCustomAsc3: "customArrow dropup",
          faCustomDesc5: "customArrow dropdown",
          faCustomAsc5: "customArrow dropup",
        });
        break;
      case 'status':
        this.setState({ lastSelectedSearchFilter: 'status' });
        this.setState({
          faCustomDesc5: orderBy == "asc" ? "customArrow dropdown" : orderBy == "desc" ? "customArrow dropdown isActive" : "",
          faCustomAsc5: orderBy == "asc" ? "customArrow dropup isActive" : orderBy == "desc" ? "customArrow dropup" : "",
          faCustomDesc1: "customArrow dropdown",
          faCustomAsc1: "customArrow dropup",
          faCustomDesc2: "customArrow dropdown",
          faCustomAsc2: "customArrow dropup",
          faCustomDesc3: "customArrow dropdown",
          faCustomAsc3: "customArrow dropup",
          faCustomDesc4: "customArrow dropdown",
          faCustomAsc4: "customArrow dropup",
        });
        break;
    }
  }

  handleCallback(start, end) {
    this.setState({ submittedDateSearchFilter: "submittedOn" });
  }

  handleCheckBoxChange(value, event) {

    let status = "Pending";
    let searchOnStatusArr = [];
    this.props.searchKey.length == 0 && this.props.searchValue.length == 0 ?
      value.length == 0 ?
        this.props.isClearAllFilterFunc(false) :
        this.props.isClearAllFilterFunc(true) :
        this.props.isClearAllFilterFunc(true);

    this.props.setSelectedValue(value);
    value.map((checkBoxValue) => {
      return (
        searchOnStatusArr.push(checkBoxValue.value)
      );
    }
    );

    this.props.updateParameter(searchOnStatusArr.length == 0 ? status : searchOnStatusArr.join(","));
    this.props.showRightSide(false);
    this.getDataFromService({
      userId, sorttype: this.props.sortOrder, sortval: this.props.sortOnColumn,
      status: searchOnStatusArr.length == 0 ? "Pending" : searchOnStatusArr.join(","),
      page: this.state.page,
      limit: this.props.limit,
      ModuleValue: this.props.modules,
      searchby: this.props.searchKey,
      searchbyVal: this.props.searchValue
    });
  }


  handleKeyPress(e) {
    e.preventDefault();
  }

  renderApprovalTableList(approvalData) {
    let myStartDate = !_.isEmpty(this.props.startDate) ? moment(this.props.startDate).format("MM/DD/YYYY") : "";
    let myEndDate = !_.isEmpty(this.props.endDate) ? moment(this.props.endDate).format("MM/DD/YYYY") : "";
    let myNewDate = myStartDate == "" && myEndDate == "" ? "" : myStartDate + "-" + myEndDate;
    let sorttype = "";
    sorttype = this.state.sortBy ? "asc" : "desc";
    let caseResponsive = this.props.showLoader || (this.props.approvalList.approverList?.length <= 6 && this.state.isMultiSelectOpen) || this.props.approvalList.approverList == undefined ? "overFlowClp" : "table-responsive";
    const multiSelectOptions = [{ label: this.props.tr['Pending'], value: 'Pending' }, { label: this.props.tr['Approved'], value: 'Approved' }, { label: this.props.tr['Rejected'], value: 'Rejected' }, { label: this.props.tr['Referred Back'], value: 'Refer_Back' }]

    console.log('IsRightSideVisible', this.props.isRightSideVisible);
    if (this.props.isRightSideVisible == true) {
      this.multiSelectDropdownRef.childNodes[0].childNodes[0].addEventListener('click', () => {
        this.props.showRightSide(false);
      });
    }

    return (
      <div>
          <div className={"tableRgtBor " + caseResponsive}>
            <Table striped bordered condensed hover className="tableView nowrapWhright" style={{ "marginTop": "0px" }}>
            <thead>
              <tr>
                <th>
                  <div className="sortParArr" onClick={() => this.onSort(this.state.sortBy, 'displayNumber', sorttype, this.state.lastSelectedSearchFilter)} >{this.props.tr['Request ID']}<div className="sortArr"> {this.props.sortOrder == "asc" ? <span className={this.state.faCustomAsc1} title={this.props.tr['Ascending']}><span className="caret"></span></span> : <span className={this.state.faCustomDesc1} title={this.props.tr['Descending']}><span className="caret"></span></span>}</div></div>
                  <div className="colSearDv">
                    <Form.Control className="colSearInp" type="text" value={this.props.displayNumberSearchInput != "" ? this.props.displayNumberSearchInput : ""} placeholder={this.props.tr["Search here"]}
                      onKeyPress={(e) => { 
                        if (e.charCode == "13") {
                          if (this.props.displayNumberSearchInput.trim().length > 3)
                            this.handleSearch();
                        }
                      }}
                      onChange={(event) => { this.handleChange(event, 'displayNumber'); }}
                      onClick={() => this.props.multiSelectOpenFun("myAprvlStatus", false)} />
                    {this.props.displayNumberSearchInput.trim().length > 3 ?
                      <a title="search" className="faicn" href={void(0)} onClick={(event) => this.handleSearch()}>
                        <IoSearch/>
                      </a> :
                      ""
                    }

                  </div>
                </th>

                <th>
                  <div className="sortParArr" onClick={() => this.onSort(this.state.sortBy, 'serviceName', sorttype, this.state.lastSelectedSearchFilter)}>{this.props.tr['Service/CI Name']}<div className="sortArr"> {this.props.sortOrder == "asc" ? <span className={this.state.faCustomAsc2} title={this.props.tr['Ascending']}><span className="caret"></span></span> : <span className={this.state.faCustomDesc2} title={this.props.tr['Descending']}><span className="caret"></span></span>}</div></div>
                  <div className="colSearDv">
                    <Form.Control className="colSearInp" type="text" value={this.props.ciServiceNameSearchInput != "" ? this.props.ciServiceNameSearchInput : ""} placeholder={this.props.tr["Search here"]} 
                    onKeyPress={(e) => { 
                      if( e.charCode == "13"){
                        if(this.props.ciServiceNameSearchInput.trim().length > 3){
                          this.handleSearch();
                        }
                      }
                    }} 
                      onChange={(event) => { this.handleChange(event, 'serviceName') }}
                      onClick={() => this.props.multiSelectOpenFun("myAprvlStatus", false)} />
                    {this.props.ciServiceNameSearchInput.trim().length > 3 ?
                      <a title="search" className="faicn" href={void(0)} onClick={(event) => this.handleSearch()}>
                        <IoSearch/>
                      </a> :
                      ""
                    }
                  </div>
                </th>

                <th className="hiddenBe414">
                  <div className="sortParArr" onClick={() => this.onSort(this.state.sortBy, 'requestedBy', sorttype, this.state.lastSelectedSearchFilter)}>{this.props.tr['Requested For']}<div className="sortArr"> {this.props.sortOrder == "asc" ? <span className={this.state.faCustomAsc3} title={this.props.tr['Ascending']}><span className="caret"></span></span> : <span className={this.state.faCustomDesc3} title={this.props.tr['Descending']}><span className="caret"></span></span>}</div></div>
                  <div className="colSearDv">
                    <Form.Control className="colSearInp" type="text" value={this.props.requestedBySearchInput != "" ? this.props.requestedBySearchInput : ""} placeholder={this.props.tr["Search here"]} 
                     onKeyPress={(e) => { 
                      if( e.charCode == "13"){
                        if(this.props.ciServiceNameSearchInput.trim().length > 3){
                          this.handleSearch();
                        }
                      }
                    }} 
                    
                    onChange={(event) => { this.handleChange(event, "requestedBy"); }}
                    onClick={() => this.props.multiSelectOpenFun("myAprvlStatus", false)} />
                    {this.props.requestedBySearchInput.trim().length > 3 ?
                      <a title="search" className="faicn" href={void(0)} onClick={(event) => this.handleSearch()}>
                        <IoSearch/>
                      </a> :
                      ""
                    }
                  </div>
                </th>

                <th className="hiddenBe414">
                  <div className="sortParArr" onClick={() => this.onSort(this.state.sortBy, 'submittedOn', sorttype, this.state.lastSelectedSearchFilter)}>{this.props.tr['Submitted Date']}<div className="sortArr"> {this.props.sortOrder == "asc" ? <span className={this.state.faCustomAsc4} title={this.props.tr['Ascending']}><span className="caret"></span></span> : <span className={this.state.faCustomDesc4} title={this.props.tr['Descending']}><span className="caret"></span></span>}</div></div>
                  <div className="colSearDv">
                    <DateRangePicker initialSettings={{
                      autoUpdateInput: false,
                      maxDate: moment(),
                      autoApply: true
                    }}
                      onApply={(e, picker) => this.handleSearch(e, picker,"submittedOn")} onCallback={this.handleCallback}>
                      <input type="text" placeholder={this.props.tr["Search here"]} readOnly className="form-control" onPaste={this.handleKeyPress} onKeyDown={this.handleKeyPress} value={myNewDate} />
                    </DateRangePicker>
                  </div>
                </th>

                <th>
                  <div className="sortParArr" onClick={() => this.onSort(this.state.sortBy, 'status', sorttype, this.state.lastSelectedSearchFilter)}>{this.props.tr['Status']}<div className="sortArr"> {this.props.sortOrder == "asc" ? <span className={this.state.faCustomAsc5} title={this.props.tr['Ascending']}><span className="caret"></span></span> : <span className={this.state.faCustomDesc5} title={this.props.tr['Descending']}><span className="caret"></span></span>}</div></div>
                  <div id="statusDv" className="colSearDv multiSelDv" ref={e => this.multiSelectDropdownRef = e}
                  onClick={() => this.props.multiSelectOpenFun("myAprvlStatus", true)}>
                    <ReactMultiSelectCheckboxes placeholderButtonLabel={this.props.tr["Select"]} value={this.props.selectedValue} rightAligned={true} options={multiSelectOptions} onChange={this.handleCheckBoxChange} />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {this.props.approvalList.approverList == undefined || this.props.showLoader ? <tr><td colSpan="5" style={{ "textAlign": "center" }}><ListLoader /></td></tr> : this.props.approvalList.approverList.length == 0 ? <tr><td colSpan="5" style={{ "textAlign": "center" }}>{this.props.tr['There is no matching data available']}</td></tr> : this.renderApprovalData(approvalData)}
            </tbody>
          </Table></div>
      </div>
    );
  }

  render() {
    let meta = this.props.approvalList.meta;
    let panelColSize = this.props.panelResize;
    if (this.props.approvalList.approverList == undefined) {
      return (<div>
        <h2>{this.props.tr['Loading approval details']}...</h2>
        <Spinner spinnerName="three-bounce" />
      </div>);
    }
    return (
      <div>
        <Row className="xtFilterBar margin-b-5 hidden">
          {(this.state && this.state.isErrorFilterSearch) ?
            <Col md={12} sm={6} xs={6}><span style={{ 'color': 'red' }}>{this.props.tr["Search here"]}</span></Col>
            : null}
        </Row>        
        <div aria-label='Table' role="contentinfo" className={this.props.showLoader || this.props.approvalList.approverList?.length <= 6 || this.props.approvalList.approverList == undefined ? "respondv responDvMinH" : "respondv"}>
          {this.renderApprovalTableList(this.props.approvalList.approverList)}

          {this.props.approvalList.approverList.length !== 0 ?
            <div className="nBotPagina">
              <div className="nShow">
                <div className="margin-r-10" componentClass={Form.Label}>{this.props.tr['Show']}:</div>
                <div aria-label='Show Count' role="contentinfo">
                <Form.Select
                  aria-label={this.props.tr['Show']}
                  className=""
                  onChange={this.handleSizePerPageChange}
                  value={this.props.limit}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                </Form.Select></div>
              </div>
              <nav aria-label='Pagination' className='display-inline-block'>
              <Pagination
                prevPageText={this.props.tr['Prev']}
                nextPageText={this.props.tr['Next']}
                firstPageText={<i className='glyphicon glyphicon-menu-left' />}
                lastPageText={<i className='glyphicon glyphicon-menu-right' />}
                activePage={meta ? Number(meta.currentPage) : 1}
                itemsCountPerPage={this.props.limit}
                totalItemsCount={meta ? meta.rowCount : 1}
                pageRangeDisplayed={panelColSize <= 42 ? 2 : 5}
                onChange={(e) => this.handlePageChange(e)}
              /></nav>
            </div> : <span />
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ approvalList, showLoader, spcmReducer }) => {
  return {
    approvalList, showLoader: showLoader.loading, tr: spcmReducer.tr
  };
};

export default connect(
  mapStateToProps,
  {
    getMyApprovalList,
    loadChangeTotalApprovalDetails,
    loadChangeTotalApprovalHistoryDetails, loadImplPlanList, getQuestionsCategoryWise, getApprovalDisclaimiers,
    loadProfile, loadBreakFixActivityLogDetails, loadFulfillmentDetails, loadChangeEditData, getRFCApprovalDisclaimiers,
  }
)(ApprovalList);
