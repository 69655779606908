
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Card, ListGroup } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import Spinner from "react-spinkit";

import ReactReadMoreLess from "../../../helpers/ReactReadMoreLess";
import dummyImage from "../../../images/profileimg.png";

const UserDetailsComponent = ({
  userDetails,
  isUserFetching,
  timezoneText,
}) => {
  return (
    <div className="userInfoPopupParent">
      {/* <div className="rPageHeading">
        <div className="offNam margin-t-5 margin-b-5 margin-l-5">
          {userDetails?.username}
        </div>
      </div> */}
      <div className="rBoxGap">
        {isUserFetching ? (
          <Spinner spinnerName="three-bounce" />
        ) : (
          <div>
            <Card bsPrefix=" " className="cardLeftdv">
              <Card.Text bsPrefix=" " className="card-left">
                <img
                  width="70px"
                  height="70px"
                  src={userDetails?.profilePicTempURL || dummyImage}
                  alt={userDetails?.username}
                />
              </Card.Text>
              <Card.Body className="padding-l-10">
                <Card.Header className="f-size-18 margin-0">
                  <div className="margin-b-10">{userDetails?.fullname}</div>
                </Card.Header>
                {userDetails?.email && (
                  <div
                    className="f-size-13 margin-b-5"
                    style={{ display: "table", width: "100%" }}
                  >
                    <span
                      className="themeColor1 f-size-13"
                      style={{
                        width: "25px",
                        display: "table-cell",
                      }}
                    >
                      <i
                        className="f-size-12 fa fa-envelope"
                        aria-hidden="true"
                      ></i>
                    </span>
                    <span
                      className="lgrayColor"
                      style={{
                        display: "table-cell",
                        wordBreak: "break-all",
                      }}
                    >
                      {userDetails?.email || "--"}
                    </span>
                  </div>
                )}

                {userDetails?.mobilePhone && (
                  <div
                    className="f-size-13 margin-b-5"
                    style={{ display: "table", width: "100%" }}
                  >
                    <span
                      className="themeColor1 f-size-13"
                      style={{
                        width: "25px",
                        display: "table-cell",
                      }}
                    >
                      <i
                        className="f-size-13 fa fa-phone"
                        aria-hidden="true"
                      ></i>
                    </span>
                    <span
                      className="lgrayColor"
                      style={{
                        display: "table-cell",
                        wordBreak: "break-all",
                      }}
                    >
                      {userDetails?.mobilePhone}
                    </span>
                  </div>
                )}
              </Card.Body>
            </Card>
          </div>
        )}
        <Scrollbars
          style={{ height: 300 }}
          autoHide={false}
          hideTracksWhenNotNeeded={false}
          className="areaPopup"
        >
          <div className="margin-t-10">
            <ListGroup bsPrefix=" ">
              <ListGroup.Item bsPrefix=" " className="rwTable">
                <span className="rwKey">Employee ID</span>
                <span className="rwVal">
                  {userDetails?.employeeNumber || "--"}
                </span>
              </ListGroup.Item>
              <ListGroup.Item bsPrefix=" " className="rwTable">
                <span className="rwKey">Location</span>
                <span className="rwVal">
                  {userDetails?.locationName || "-"}
                </span>
              </ListGroup.Item>
              <ListGroup.Item bsPrefix=" " className="rwTable">
                <span className="rwKey">Desk Location</span>
                <span className="rwVal">{userDetails?.cubicle || "-"}</span>
              </ListGroup.Item>
              <ListGroup.Item bsPrefix=" " className="rwTable">
                <span className="rwKey">Time Zone</span>
                <span className="rwVal">{timezoneText || "--"}</span>
              </ListGroup.Item>
              <ListGroup.Item bsPrefix=" " className="rwTable">
                <span className="rwKey">Manager</span>
                <span className="rwVal">
                  {userDetails?.managerName ||
                    "--" +
                      (userDetails?.managerEmployeeNumber
                        ? " / " + userDetails?.managerEmployeeNumber
                        : "") +
                      (userDetails?.managerEmail
                        ? " / " + userDetails?.managerEmail
                        : "")}
                </span>
              </ListGroup.Item>
              <ListGroup.Item bsPrefix=" " className="rwTable">
                <span className="rwKey">Company</span>
                <span className="rwVal">
                  {userDetails?.companyName || "--"}
                </span>
              </ListGroup.Item>
              <ListGroup.Item bsPrefix=" " className="rwTable">
                <span className="rwKey">Organization</span>
                <span className="rwVal">
                  {userDetails?.organizationName || "--"}
                </span>
              </ListGroup.Item>
              <ListGroup.Item bsPrefix=" " className="rwTable">
                <span className="rwKey">Department</span>
                <span className="rwVal">
                  {userDetails?.departmentName || "--"}
                </span>
              </ListGroup.Item>
              <ListGroup.Item bsPrefix=" " className="rwTable">
                <span className="rwKey">Cost Center</span>
                <span className="rwVal">
                  {userDetails?.costcenterName || "--"}
                </span>
              </ListGroup.Item>
              <ListGroup.Item bsPrefix=" " className="rwTable">
                <span className="rwKey">Groups</span>
                <span className="rwVal">
                  <ReactReadMoreLess
                    charLimit={40}
                    readMoreText="more"
                    readLessText="...less"
                    content={userDetails?.groupName || "--"}
                  />
                </span>
              </ListGroup.Item>
              <ListGroup.Item
                bsPrefix=" "
                className="rwTable"
                style={{ borderBottom: "none" }}
              >
                <span className="rwKey">Roles</span>
                <span className="rwVal">
                  <ReactReadMoreLess
                    charLimit={40}
                    readMoreText="more"
                    readLessText="...less"
                    content={userDetails?.roleName || "--"}
                  />
                </span>
              </ListGroup.Item>
            </ListGroup>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

export default UserDetailsComponent;
