
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, {useEffect, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import EditChangeIndex from './EditChangeIndex';

export default function EditChangeMain() {
    const [changeParams, setChangeParams] = useState({changeId: ""});
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const params = useParams();
    useEffect(() => {
        setChangeParams({changeId: params.changeId});
      }, []);
      useEffect(() => {
        if(params.changeId !== changeParams.changeId){
          setLoading(true);
          setTimeout(() => {
            setChangeParams({changeId: params.changeId});
            setLoading(false);
          }, 200);
        }
      }, [params.changeId]);
      if(isLoading){
        return null;
      }
    // console.log('params - ', params);
    return <EditChangeIndex navigate={navigate} params={params}/>;
}