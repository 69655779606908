
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const cmdbTimeLineData = (state = {
    isFetching: true,
    timeLineData: []
}, action) => {
    switch (action.type) {
        case 'TIMELINE_DATA_LOAD_SUCCESS':
            return Object.assign({}, state, {
                isFetching: action.isFetching,
                timeLineData: action.auditData
            });
        case 'LOADING_CMDB_TIMELINE':
            return Object.assign({}, state, {
                isFetching: action.isFetching
            });
        default:
            return state;
    }
};

export const cmdbRelationshipData = (state = {
    relationshipData: []
}, action) => {
    switch (action.type) {
        case 'RELATIONSHIP_DATA_LOAD_SUCCESS':
            return Object.assign({}, state, {
                relationshipData: action.relationshipData
            });
        default:
            return state;
    }
};