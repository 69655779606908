import React from 'react';
import { useSelector } from 'react-redux';

const D2CGenericAIDesctiption = () => {
   const translator = useSelector(state => state.spcmReducer.tr);
   const generalDescription = `${translator['AI insights help you to boost your productivity by highlighting the areas that need most attention and help in quick decision-making']}. ${translator['Browse through the insights that we have curated for you on this ticket']}`;
   return (<>
      <div className="aiTxt margin-b-20">
          {generalDescription}
      </div>
   </>);
}
export default D2CGenericAIDesctiption;