
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import axios from "axios";
import { connect } from "react-redux";
import { GLOBAL } from "_Globals";
import { formValueSelector, change } from "redux-form";

const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

let AdministratorAsyncTypeahead = class AdministratorAsyncTypeahead extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      isLoading: false,
      minLength: 3,
      delay: 200,
      useCache: false,
      inputValue: "",
      labelKey: "label",
      defaultInputValue: "",
    };
    this._renderMenuItemChildren = this._renderMenuItemChildren.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.validateField = this.validateField.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.companyId != this.props.companyId) {
      this.props.dispatch(change("CreateContract", "administrator_id", ""));
      this.props.dispatch(change("CreateContract", "administrator_name", ""));
      this._typeahead.clear();
      this.setState({ inputValue: "", options: [] });
    }
  }

  onFocus() {
    if (this.state.inputValue && this.state.inputValue.length >= 3) {
      // this.onSearch(this.state.inputValue);
    }
  }

  onSearch(searchKeywords) {
    let finalSearchKeywords = searchKeywords.split("-").at(0);
    this.setState({ options: [] });
    let responseData = [];
    let finalData = [];
    let myObj = {};
    if (this.props.companyId) {
      this.setState({ isLoading: true });
      let strObj = {};
      strObj.companyId = this.props.companyId;
      strObj.like = finalSearchKeywords;
      strObj = JSON.stringify(strObj);
      api
        .get(GLOBAL.consumerUsersNewUrl, { headers: { query: strObj } })
        .then((response) => {
          responseData = response.data;
          // console.log("responseData===", responseData);
          finalData = [];
          for (let i = 0; i < responseData.length; i++) {
            myObj = { id: "", value: "", label: "", companyName: "" };
            myObj.id = responseData[i].userId;
            myObj.value = responseData[i].fullname;
            myObj.label =
              responseData[i].email + " - " + responseData[i].companyName;
            finalData.push(myObj);
          }
          finalData = Array.from(new Set(finalData));
          this.setState({
            options: finalData,
            isLoading: false,
          });
        })
        .catch((err) => {
          this.setState({ isLoading: false });
        });
    }
  }

  _renderMenuItemChildren(option, props) {
    return [
      <div>
        <span
          className="typHdmainHead"
          title={option.value}
          style={{ display: "block" }}
        >
          <Highlighter key="value" search={props.text}>
            {option.value}
          </Highlighter>
        </span>
        <span
          className="typHdmainSubHead"
          style={{ display: "block", "margin-top": "6px", color: "#777" }}
        >
          <Highlighter key="label" search={props.text}>
            {option.label}
          </Highlighter>
        </span>
      </div>,
    ];
  }

  onInputChange(value) {
    this.setState({ inputValue: value.trim() });
    this.props.dispatch(change("CreateContract", "administrator_id", ""));
    this.props.dispatch(change("CreateContract", "administrator_name", ""));
  }

  onValueSelection(selectedValue) {
    if (selectedValue && selectedValue.length > 0) {
      let updatedValue = selectedValue[0].value;
      for (let i = 0; i < this.state.options.length; i++) {
        if (this.state.options[i].value === selectedValue[0].value) {
          this.state.options[i].label = updatedValue;
          break;
        }
      }

      this.props.dispatch(
        change("CreateContract", "administrator_id", selectedValue[0].id)
      );
      this.props.dispatch(
        change("CreateContract", "administrator_name", selectedValue[0].value)
      );
      this.props.setErrorColor("", "administrator_id");
      this.setState({ inputValue: selectedValue[0].value });
    }
  }

  validateField() {
    if (this.props.adminstaratorParam == "") {
      this.props.setErrorColor("error", "administrator_id");
    } else {
      this.props.setErrorColor("", "administrator_id");
    }
  }

  onTypeaheadCrossClick() {
    this._typeahead.clear();
    this.setState({ inputValue: "", options: [] });
    this.props.dispatch(change("CreateContract", "administrator_id", ""));
    this.props.dispatch(change("CreateContract", "administrator_name", ""));
    this.props.setErrorColor("error", "administrator_id");
  }

  render() {
    const props = {};
    props.renderMenuItemChildren = this._renderMenuItemChildren;

    return (
      <div className={"dvTypehd " + this.props.errorColor}>
        {!this.state.isLoading ? (
          this.state.inputValue.length > 0 ? (
            <span
              title="clear"
              role="img"
              aria-label="Clear"
              className="css-hakgx8 icn"
              onClick={() => this.onTypeaheadCrossClick()}
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                role="presentation"
              >
                <path
                  d="M12 10.586L6.707 5.293a1 1 0 00-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 001.414 1.414L12 13.414l5.293 5.293a1 1 0 001.414-1.414L13.414 12l5.293-5.293a1 1 0 10-1.414-1.414L12 10.586z"
                  fill="gray"
                ></path>
              </svg>
            </span>
          ) : (
            <span
              title="Search"
              role="presentation"
              aria-hidden="true"
              className="css-hakgx8 icn"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                role="presentation"
              >
                <path
                  d="M16.436 15.085l3.94 4.01a1 1 0 01-1.425 1.402l-3.938-4.006a7.5 7.5 0 111.423-1.406zM10.5 16a5.5 5.5 0 100-11 5.5 5.5 0 000 11z"
                  fill="gray"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </span>
          )
        ) : (
          ""
        )}

        <AsyncTypeahead
          id={this.props.id}
          {...this.state}
          {...props}
          ref={(ref) => (this._typeahead = ref)}
          onSearch={this.onSearch}
          filterBy={() => true}
          onChange={(e) => this.onValueSelection(e)}
          onInputChange={this.onInputChange}
          onBlur={this.validateField}
          placeholder={"Search by Name"}
          className=""
          onFocus={this.onFocus}
        />
      </div>
    );
  }
};

let selector = formValueSelector("CreateContract");
AdministratorAsyncTypeahead = connect((state) => {
  let companyId = selector(state, "company");
  let adminList = state.AdminList;
  return {
    companyId,
    adminList,
  };
})(AdministratorAsyncTypeahead);

export default AdministratorAsyncTypeahead;
