
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ListGroup from 'react-bootstrap/ListGroup';
import Table from 'react-bootstrap/Table'
import Accordion from 'react-bootstrap/Accordion';
import {PiPencilSimpleBold} from "react-icons/pi";
import {IoClose} from "react-icons/io5";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { MyContext } from '_MyContext';
import {
  getRuleConfiguration,
  getCompanyData, getRuleCondition,
  getAMSAttributesOnly, resetAttributeList
} from '../../../actions/amsActions/amsActions.js';

class AmsHomeShortDesc extends React.Component {
  static contextType = MyContext;
  constructor(props) {
    super(props);
    this.state = {
    };
    this.onDemandAttributes=this.onDemandAttributes.bind(this);
  }

  componentDidMount() {
		this.context.keyDownHandler(this.closeButton,this.editButton,"","");
	}
	componentDidUpdate() {
		this.context.keyDownHandler(this.closeButton,this.editButton,"","");
	}
  onDemandAttributes(event){
    if(event.target.getAttribute('aria-expanded')){
      this.props.getAMSAttributesOnly(this.props.ruleId);
    }
   }

  editRuleConfiguration(ruleId) {
    this.props.getRuleConfiguration(ruleId);
  }

  componentWillUnmount(){
    this.props.resetAttributeList();
  }
  render() {
    return (
      <div className="">
        <Row>
          <Col xs={12}>
            <div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
              <ul>
                <li>
                  <Link to="javascript:void(0)" onClick={() => { this.editRuleConfiguration(this.props.ruleId) }} title={this.props.tr['Edit']} ref={e => this.editButton = e} ><PiPencilSimpleBold/></Link>
                </li>
                <li>
                  <Link to="javascript:void(0)" title={this.props.tr["Minimize the details panel"]} bsClass=""  onClick={() => { this.props.showRightSide(false); }} ref={e => this.closeButton = e} >
                  <IoClose/>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="rPageHeading">
              <div className="offNam margin-t-5 margin-b-5">{this.props.ruleName}</div>
            </div>
          </Col>
        </Row>

        <div className="rBoxGap">
          <div className="rBoxTabs hideTabNav">
          <Tabs activeKey={this.state.tabKey} onSelect={this.handleSelect} id="tabId">
            <Tab eventKey={1} tabIndex='1' title="Assignment Details">
              <ListGroup bsClass="" bsPrefix=' '>
                <ListGroup.Item bsPrefix=' ' bsClass="" className="rwTable" style={{ "border-top": "none" }}><span className="rwKey">{this.props.tr['Rule Name']} </span> <span className="rwVal">{this.props.ruleName}</span></ListGroup.Item>
                <ListGroup.Item bsPrefix=' ' bsClass="" className="rwTable"><span className="rwKey">{this.props.tr['Company']} </span> <span className="rwVal">{this.props.companyName}</span></ListGroup.Item>
                <ListGroup.Item bsPrefix=' ' bsClass="" className="rwTable"><span className="rwKey">{this.props.tr['Module']} </span> <span className="rwVal">{this.props.moduleName}</span></ListGroup.Item>
                <ListGroup.Item bsPrefix=' ' bsClass="" className="rwTable"><span className="rwKey">{this.props.tr['Assignment For']} </span> <span className="rwVal">{this.props.eventName}</span></ListGroup.Item>
                <ListGroup.Item bsPrefix=' ' bsClass="" className="rwTable"><span className="rwKey">{this.props.tr['Support Company']} </span> <span className="rwVal">{this.props.assignmentCompanyName}</span></ListGroup.Item>
                <ListGroup.Item bsPrefix=' ' bsClass="" className="rwTable"><span className="rwKey">{this.props.tr['Support Group']} </span> <span className="rwVal">{this.props.assignmentGroupName}</span></ListGroup.Item>
                <ListGroup.Item bsPrefix=' ' bsClass="" className="rwTable"><span className="rwKey">{this.props.tr['Weightage']} </span> <span className="rwVal">{this.props.weight}</span></ListGroup.Item>
                <ListGroup.Item bsPrefix=' ' bsClass="" className="rwTable"><span className="rwKey">{this.props.tr['Status']} </span> <span className="rwVal">{this.props.status}</span></ListGroup.Item>
              </ListGroup>
              {!this.props.isContextual?
              <div className="margin-t-20 clearboth brkFix brkFixOrder">
                <Accordion id="rightSidePanel6" activeKey={this.props.activePanelKey} onSelect={this.props.handlePanelClick} className="brkFix brkFixOrder">
                  <Accordion.Item>
                      <Accordion.Header  onClick={(e) => { this.onDemandAttributes(e) }}>
                        {this.props.tr['Attributes']}</Accordion.Header>
                    <Accordion.Body>
                      <Table responsive striped bordered condensed hover className="f-size-13">
                        <thead>
                          <tr>
                            <th>{this.props.tr["Name"]}</th>
                            <th>{this.props.tr["Value"]}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.ruleCondition.map((day, index) => { 
                              let attributes = day.paramValue.split(",");
                              let len = attributes.length;
                              return (
                                <tr>
                                  <td>{day.paramName}</td>
                                  <td>
                                    {day.paramName == "Impacted CI" ? (
                                      <td>
                                      {attributes.map((attr, index) => {
                                        let attribute = attr.split("|");
                                        let attLen=attribute.length
                                        return attribute.map((value, i) => {
                                          if (i == 1) {
                                            return index == len-1 ? <div className="tbSerialTxt">Serial Number - {(value=="" || value==null) ? 'NA': value}</div> :(<div className="tbSerialTxt">Serial Number - {(value=="" || value==null) ? 'NA': value},</div>);
                                          } else {
                                            return  index == len-1 || attLen > 1 ? <div>{value}</div> : <div>{value},</div>;
                                          }
                                        })
                                      })}
                                    </td>
                                    ) : (
                                      day.paramValue
                                    )}
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              : ''}
            </Tab>
            </Tabs>
            </div>
        </div>
      </div>
    );
  }
}
export function mapStateToProps({ allRuleConfigurationsData, allRuleConditions, ruleCondition }) {
  return { allRuleConfigurationsData, allRuleConditions, ruleCondition };
}

export default connect(mapStateToProps, { getRuleConfiguration, getCompanyData, getRuleCondition, getAMSAttributesOnly, resetAttributeList })(AmsHomeShortDesc);





