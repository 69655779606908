
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React ,{ useState ,useEffect} from 'react'
import { Button } from 'react-bootstrap';
import { sanitizeHTMLForDocExport } from '../../../common/helper';
const mystyle = {height: "62px",overflow: "hidden"};

const NotificationAuditTemplate=({oldVal,newVal})=>{

    // let tr=useSelector(state=>state.tr)
    const [isMoreLess, setIsMoreLess]=useState(true)
    const handleClick=()=>{
        setIsMoreLess(!isMoreLess)
    }

    oldVal=oldVal.replace(/<br><br>/g,"<br>")
    newVal=newVal.replace(/<br><br>/g,"<br>")

    return(
        <>
        <div title="New Value" className="nwnme commn">
        <div style={isMoreLess ? mystyle : null} dangerouslySetInnerHTML={{
                 __html:_.unescape(sanitizeHTMLForDocExport(newVal))
            }}></div>
             <Button bsPrefix=" " title={isMoreLess ? "more" : "less"} className="lessMoreBtnInline" onClick={handleClick} bsClass="" >{isMoreLess ? <span><i className='fa fa-angle-down margin-r-5'></i>{"more"}</span> : <span><i className='fa fa-angle-up margin-r-5'></i>{"less"}</span>}</Button>

        </div>
        <div title="Old Value" className="oldnme commn">
        <div style={isMoreLess ? mystyle : null} dangerouslySetInnerHTML={{
                 __html:_.unescape(sanitizeHTMLForDocExport(oldVal))
            }}></div>
             <Button bsPrefix=" " title={isMoreLess ? "more" : "less"} className="lessMoreBtnInline" onClick={handleClick} bsClass="" >{isMoreLess ? <span><i className='fa fa-angle-down margin-r-5'></i>{"more"}</span> : <span><i className='fa fa-angle-up margin-r-5'></i>{"less"}</span>}</Button>

        </div>
         
        
        </>
    )
}

export default NotificationAuditTemplate