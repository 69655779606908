
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import {Row} from 'react-bootstrap';
import CloseBtnRightPanel from '../../common/closeBtnRightPanel';

class CmdbProcessHierarchy extends React.Component{
	render(){
		return(
		<div>
			<CloseBtnRightPanel showRightSide={this.props.rightEditPanel} />
			<div className="rPageHeading">{this.props.translator['Process Workflow']}</div>

			<div className='text-c rBoxGap'><img className="img-fluid marAuto" src={require('../../../images/Asset-Diagram.png')} title={this.props.translator['Process Hierarchy']} /></div>
		</div>
		);
	}
}

export default CmdbProcessHierarchy;
