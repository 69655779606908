
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useSelector, useDispatch  } from "react-redux";
import Breadcrumbs from "../../../common/header/breadcrumbs";
import EditClassDefinitionHeader from "./editClassDefinitionHeader";
import EditClassDefinitionForm from "./editClassDefinitionForm";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import TaskPlanList from "./taskPlan/TaskPlanList";
import { loadCISupCompany } from "../../../../actions/cmdb/lovAction";
import "_Css/form/_form.scss";

const EditClassDefinitionIndex = () => {
  const tr = useSelector((state) => state.spcmReducer.tr);
  const [statusErr, setStatusErr] = useState("");
  const [parentError, setParentError] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [view, setView] = useState("taskPlan");
  const [isRightEditPanel, setisRightEditPanel] = useState(true);
  const [fulfilmentBtnState, setFulfilmentBtnState] = useState(false);
  const initialValue = useSelector((state) => state.classEditList);
  const dispatch = useDispatch();

  const rightEditPanel = (value) => {
    setisRightEditPanel(value);
  };

  useEffect(() => {
    if (initialValue.COMPANY_ID) {
      dispatch(loadCISupCompany(initialValue.COMPANY_ID));
    }
  }, [initialValue.COMPANY_ID, dispatch]);

  const toggleAddButtonStatus = (value, selectedValue) => {
    if (selectedValue === 'Select') {
      setFulfilmentBtnState(true);
    } else {
      setFulfilmentBtnState(value);
    }
  };

  return (
    <main>
      <Container fluid className="margin-b-15">
        <Breadcrumbs
          activePageName={"Edit"}
          parentPageurl="/classDefinition"
          parentPageName={"Class"}
        />
      </Container>

      <Container fluid>
        <EditClassDefinitionHeader
          parentError={parentError}
          setParentError={setParentError}
          companyId={companyId}
          setCompanyId={setCompanyId}
          statusErr={statusErr}
          setStatusErr={setStatusErr}
          view={view}
          setView={setView}
          rightEditPanel={rightEditPanel}
          tr={tr}
        />
        <PanelGroup direction="horizontal">
          <Panel
            id="sidebar"
            minSize={33}
            order={1}
            defaultSize={isRightEditPanel ? 67 : 100}
            className={isRightEditPanel ? "isShowLeftPanel" : ""}
          >
            <Row>
              <Col md={8} xs={12}>
                <EditClassDefinitionForm
                  parentError={parentError}
                  companyId={companyId}
                  setCompanyId={setCompanyId}
                  setParentError={setParentError}
                  statusErr={statusErr}
                  setStatusErr={setStatusErr}
                  rightEditPanel={rightEditPanel}
                  tr={tr}
                />
              </Col>
            </Row>
          </Panel>
          {isRightEditPanel ? (
            <>
              <PanelResizeHandle className="isShowLeftPanel resizeHandle">
                <div className="outlne">
                  <div className="handIcn">
                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                  </div>
                </div>
              </PanelResizeHandle>
              <Panel minSize={33} order={2} defaultSize={33}>
                {view === "taskPlan" && (
                  <div className="stickyArea rBoxStyle">
                    <TaskPlanList
                      rightEditPanel={rightEditPanel}
                      setView={setView}
                      companyId={companyId}
                      setCompanyId={setCompanyId}
                      toggleAddButtonStatus={toggleAddButtonStatus}
                      fulfilmentBtnState={fulfilmentBtnState}
                    />
                  </div>
                )}
              </Panel>
            </>
          ) : (
            ""
          )}
        </PanelGroup>
      </Container>
    </main>
  );
};

export default EditClassDefinitionIndex;
