
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React,{createContext,useEffect, useState,useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";

export const MyContext = createContext("");

const MyContextProvider = (props) => {
    const dispatch = useDispatch();
    const tr = useSelector((state) => state.spcmReducer.tr);
    const [closeBtnRef] = useState("closeButton");

    const keyDownHandler = (closeRef, editRef, globalSearchRef, saveObjRef) => {
      const keyRefMap = {
        m: closeRef,
        i: editRef,
        b: globalSearchRef,
        q: saveObjRef,
      };
    
      window.addEventListener("keydown", function (event) {
        if (event.ctrlKey) {
          const ref = keyRefMap[event.key];
          if (ref != null) {
            ref.focus();
          }
        }
      });
    }

    const newTabPrevent = (event) => {
      if (event?.target?.classList.contains("ctrlKeyPrevent") && event?.ctrlKey && event?.key === "Enter"){
        event.preventDefault();
      }
      else {
        return false;
      }
    };

    useEffect(() => {
      window.addEventListener("keydown", newTabPrevent);
      return () => {
        window.removeEventListener("keydown", newTabPrevent);
      }
    }, []);
  
  
    return (
      <MyContext.Provider value={{keyDownHandler,newTabPrevent}}>
        {props.children}
      </MyContext.Provider>
    );
  };

  export default MyContextProvider;