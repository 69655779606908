
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState, useEffect, createRef, useRef } from "react";
import { isEmpty } from "lodash";
import moment from "moment";
import LoadingIcons from "react-loading-icons";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Field, Formik, Form } from "formik";
import { Row, Col, Button, Form as BootstrapForm } from "react-bootstrap";
import { ImInfo } from "react-icons/im";

import {
  InputTextField,
  SelectField,
  DatePickerF,
  InputTextFieldNumber,
  InputTextFieldForChips,
} from "../common/FormFields";
import MeetingDropdownPlusTypeahead from "../common/MeetingDropdownPlusTypeahead";
import ReccuranceComponent from "../common/ReccuranceBottomComponent";
import Editor from "../../common/Editor/Editor";
import CustomTypeHead from "../common/CustomTypeHead";
import Header from "./header";

import {
  utis,
  returnServerPayload,
  ending,
  getWeekDayAccordingDate,
  findDuration,
  convertDate,
  week,
  weekCount,
  createUIPayload,
  yearMonthName,
  checkDateWithIn24Hr,
  createPayloadForChips,
  filterGroupUser,
  extractGroupList,
  getCookiesValuesByPositions,
  setSelectValueByText,
} from "../Helper";
import { useGetAllCompanyQuery } from "../../../reducers/foundation/forum/forumQuery";
import {
  getAllTrack,
  useGetSuppliersQuery,
  useGetUserInfoQuery,
  useGetAllForumQuery,
  useGetRegionListQuery,
  useGetAllCategoryQuery,
  useGetAllTrackQuery,
  useCreateMeetingMutation,
  useGetAllSubTrackQuery,
  useNotifyActionMutation,
  useGetActionQuery,
  useGetGovAudiLogsQuery,
  useGetAllOccurancesQuery,
  useGetMeetingDefByIdQuery,
  useGetLocationDetailsQuery,
  useGetCiSupportAllGroupQuery,
  useGetStatusTimelineQuery,
  useUpdateOccurrenceMutation,
  useCommitteDetailsByFrmNameQuery,
  useUpdateMeetingDefinationDetailsMutation,
  useGetCancelMeetingDefinationMutation,
} from "../../../reducers/governance/meeting/meeting";

import {
  findTimeFormate,
  prefDateFormatAccordingUser,
  getDateWord,
  disabledTime,
} from "../common/CommonMethod";

import WeekButton from "../common/WeekButtons";
import MonthComponent from "../common/MonthComponent";
import YearComponent from "../common/YearComponent";
import SystemInfo from "../common/SystemInfo";
import StatusTimeLine from "../common/StatusTimeline";
import RightSide from "../rightSide";

const AddEditMeeting = () => {
  const navigate = useNavigate();

  const formikRef = useRef();
  let descRef = createRef();
  let agendaRef = createRef();
  let meetingRecurrenceSummary = "";
  const params = useParams();
  const meetingId = params?.meetingId;
  const isAddMode = !meetingId;
  const characterLimit = 500;

  const { data: allCompany, isFetching: companyFetching } =
    useGetAllCompanyQuery();

  const [isAutorize, setIsAutorize] = useState(false);
  const [trackName, setTrackName] = useState("");
  const [isHemStatus, setisHemStatus] = useState("");
  const [companyOptions, setCompanyOptions] = useState([]);
  const [committeOptions, setCommitteOptions] = useState([]);
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [descLength, setDescLength] = useState(0);
  const [agendaLength, setAgendaLength] = useState(0);
  const [selectedCustId, setSelectedCustId] = useState(null);
  const [selectedForum, setSelectedForum] = useState(null);
  const [category, setCategory] = useState(null);
  const [allRegions, setAllRegions] = useState([]);
  const [activeTab, setActiveTab] = useState(true);
  const [activeTabText, setActiveTabText] = useState("");
  const [showTimeLine, setShowTimeLine] = useState(false);

  const cookieValues = getCookiesValuesByPositions([2, 3]);
  const dateformat = cookieValues?.value1;
  const timezone = cookieValues?.value2;

  const [activeNav, setActiveNav] = useState(true);
  const [activeTabs, setActiveTabs] = useState("1");

  const toggle = (tab) => {
    setActiveNav(!tab);
    if (activeTabs !== tab) setActiveTabs(tab);
  };

  const [
    addMeetingDef,
    { isLoading: addMeetingLoading, error: createMettingError },
  ] = useCreateMeetingMutation();

  const [
    updateMeetingDef,
    { isLoading: updateMeetingLoading, error: updateMettingError },
  ] = useUpdateMeetingDefinationDetailsMutation();

  const [
    statusAction,
    {
      data: statusActionData,
      isLoading: statusActionLoading,
      refetch: statusActionRefetch,
    },
  ] = useNotifyActionMutation();

  const [cancelMeeting] = useGetCancelMeetingDefinationMutation();
  //

  // const _dateFormat = userInfo?.dateformat;
  // const ueserId=userInfo?.user_id;

  const { data: userInfo } = useGetUserInfoQuery();
  const {
    data: forumData,
    error,
    isLoading,
  } = useGetAllForumQuery(selectedCustId, {
    skip: !selectedCustId,
  });

  const {
    data: allCats,
    error: catError,
    isLoading: catIsLoading,
  } = useGetAllCategoryQuery(selectedCustId, {
    skip: !selectedCustId,
  });

  const {
    data: allCommittee,
    error: committeError,
    isLoading: committeLoading,
  } = useCommitteDetailsByFrmNameQuery(
    {
      name: selectedForum,
      custId: selectedCustId,
      category: category,
      group_type: 50,
    },
    {
      skip: !(selectedForum && selectedCustId && category),
    }
  );

  const {
    data: allRegionsData,
    error: regionsError,
    isLoading: regionsLoading,
  } = useGetRegionListQuery();

  const {
    data: allTrack,
    error: trackError,
    isLoading: trackLoading,
  } = useGetAllTrackQuery(
    { custId: selectedCustId },
    {
      skip: !selectedCustId,
    }
  );

  const {
    data: allSubTrack,
    error: subTrackError,
    isLoading: subTrackLoading,
  } = useGetAllSubTrackQuery(
    { name: trackName },
    {
      skip: !trackName,
    }
  );
  const {
    data: allLocationData,
    error: locError,
    isLoading: locLoading,
  } = useGetLocationDetailsQuery(
    { custId: selectedCustId },
    {
      skip: !selectedCustId,
    }
  );

  const {
    data: allGroupData,
    error: groupError,
    isLoading: groupLoading,
  } = useGetCiSupportAllGroupQuery(
    { custId: selectedCustId },
    {
      skip: !selectedCustId,
      selectFromResult: ({ data }) => {
        if (!isEmpty(data)) {
          const transformedData = data?.map((group) => ({
            label: group.groupName,
            value: group.groupId,
          }));

          return { data: transformedData || [] };
        }

        return { data: [] };
      },
    }
  );

  const {
    data: editMeetingData,
    isFetching: editMeetingLoading,
    error: editMeetingError,
    refetch: refetchEditMeeting,
  } = useGetMeetingDefByIdQuery(
    { meetingId: meetingId },
    {
      skip: !meetingId,
    }
  );

  const {
    data: timelines,
    error: timelineError,
    isLoading: timelineLoading,
    refetch: refetchTimelines,
  } = useGetStatusTimelineQuery(
    {
      queryString: `?module=meeting&definationId=${meetingId}&tenantId=${selectedCustId}`,
    },
    {
      skip: !(meetingId && selectedCustId),
    }
  );

  const { data: allSuppliers, isFetching: isSupliersLoading } =
    useGetSuppliersQuery(
      {
        queryString: `?companyId=${selectedCustId}&role=Service Provider,Service Supporter`,
      },
      {
        skip: !selectedCustId,
      }
    );

  const {
    data: occurrencesdata,
    isLoading: occurrencesLoading,
    refetch: occurenceRefetch,
  } = useGetAllOccurancesQuery(
    {
      definationId: meetingId,
      pageNumber: -1,
      pageSize: -1,
      queryString: `tenantId=${editMeetingData?.companyId}&sortedBy=asc`,
    },
    {
      skip: !(meetingId && selectedCustId && editMeetingData?.companyId),
    }
  );

  const {
    data: auditData,
    isFetching: auditIsFetching,
    refetch: auditDataRefetch,
  } = useGetGovAudiLogsQuery(
    {
      queryString: `?definationId=${meetingId}&tenantId=${editMeetingData?.companyId}&module=meeting`,
    },
    {
      skip: !(meetingId && selectedCustId && editMeetingData?.companyId),
    }
  );


  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
    customer: Yup.string().required("Required"),

    forumId: Yup.string().required("Required"),
    categoryId: Yup.string().required("Required"),
    committeeId: Yup.string().required("Required"),

    // region: Yup.string().nullable(),
    // track: Yup.string().nullable(),
    // subTrack: Yup.string().nullable(),
    //  : Yup.array(),
    chairPerson: Yup.string().required("Required"),

    objectives: Yup.string().required("Required"),
    agenda: Yup.string().required("Required"),

    //coordinator: Yup.array(),

    meetingTypeId: Yup.string().required("Required"),
    location: Yup.string().required("Required"),
    persons: Yup.array().min(1, "Required").required("Required"),
    startOn: Yup.date()
      .required()
      .test(
        "validate start date",
        "start date should be at-least today's date",
        (value) => {
          let dateObj = new Date(value);
          let momentObj = moment(dateObj).startOf("minute");
          if (momentObj.isBefore(moment())) {
            return false;
          }
          return true;
        }
      ),
    startEndOn: Yup.date()
      .required()
      .test(
        "validate end date",
        "end date should be at-least today's date",
        (value) => {
          let dateObj = new Date(value);
          let momentObj = moment(dateObj).startOf("minute");
          if (momentObj.isBefore(moment())) {
            return false;
          }
          return true;
        }
      ),
    externalEmails: Yup.array().of(
      Yup.object().shape({
        label: Yup.string().test(
          "is-valid-email",
          "Invalid email address",
          (value) => {
            if (!value) return true;
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return regex.test(value);
          }
        ),
      })
    ),
    units: Yup.string().required("Required"),
    redioAccuranceSelection: Yup.string().required("Required"),
    recuring: Yup.boolean(),
    recuringEndOn: Yup.date().when(['units','redioAccuranceSelection','recuring'], {
      is: (units, redioAccuranceSelection, recuring) => units!=='' && redioAccuranceSelection === 'Ends By' && recuring,
      then: () => Yup.date().transform((value, originalValue) => {
          const date = moment(originalValue);
          return date.isValid() ? date.toDate() : new Date('');
        }).required('Ending On is required')
        .min(moment().startOf('day').toDate(), 'Ending On should be at least today\'s date')
        .required('Ending On is required and should be a valid date'),
      otherwise: () => Yup.date().nullable()
    })

    // groups: Yup.array(),
    // startOn: Yup.date().required("Required"),
    // startEndOn: Yup.date()
    //   .required("Required")
    //   .when("startOn", (startEndOn, schema) => {
    //     if (startEndOn) {
    //       const dayAfter = new Date(startEndOn.getTime());

    //       return schema.min(
    //         dayAfter,
    //         "End date has to be after than start date"
    //       );
    //     }
    //     return schema;
    //   })
    //   .nullable(),

    // duration: Yup.string(),
    // recuring: Yup.bool(),
    // recuringStartOn: Yup.string().when("recuring", {
    //   is: true,
    //   then: Yup.string().required("Required"),
    //   otherwise: Yup.string(),
    // }),
    // recuringEndOn: Yup.date()
    //   .nullable()
    //   .transform((curr, orig) => (orig == "" ? null : curr))
    //   .when(["recuring", "redioAccuranceSelection"], {
    //     is: (recuring, redioAccuranceSelection) =>
    //       recuring && redioAccuranceSelection === "Ends By",
    //     then: Yup.date()
    //       .nullable()
    //       .transform((curr, orig) => (orig === "" ? null : curr))
    //       .required("Required"),
    //     otherwise: Yup.date()
    //       .nullable()
    //       .transform((curr, orig) => (orig === "" ? null : curr)),
    //   }),
    // every: Yup.number().when("recuring", {
    //   is: true,
    //   then: Yup.number().min(1, "Required").required("Required"),
    //   otherwise: Yup.number(),
    // }),
    // units: Yup.string(),
    // weeks: Yup.array(),
    // redioAccuranceSelection: Yup.string(),
    // externalEmails: Yup.array().of(Yup.string().email("Enter a valid email")),
  });

  const [defaultValue, setDefaultValues] = useState({
    title: "",
    status: "",
    statusId: "",
    timeZone: timezone,
    categoryId: "",
    committeeId: "",
    customerName: "",
    companyName: "",
    companyId: "",
    customer: "",
    forum: "",
    forumName: "",
    forumId: "",
    meetingTypeId: "",
    meetingLocation: "",
    committee: "",
    trackId: "",
    track: "",
    subTrack: "",
    regionId: "",
    regionName: "",
    supplier: [],
    chairPerson: "",
    chairPersonName: "",
    objectives: "",
    agenda: "",
    coordinator: [],
    location: "",
    persons: [],
    groups: [],
    startOn: moment(),
    startEndOn: moment(),
    duration: findDuration(moment(), moment()),
    recuring: false,
    every: 1,
    units: "daily",
    recuringEndOn: '',
    recuringStartOn: moment(),
    weeks: [moment().isoWeekday().toString()],
    redioButtonSelection: "On Day",
    monthDaysNo: 1,
    weekNo: moment().weekday().toString(),
    weekDay: moment().weekday().toString(),
    yearMonthName: "1",
    yearMonthNameForOnDay: "1",
    redioAccuranceSelection: "Ends By",
    redioAccuranceSelectionDate: "",
    redioAccuranceSelectionEndAfterDaysNo: 1,
    externalEmails: [],
    meetingRecurrenceSummary: "",
    mop: "E",
    inputSearchValue: "",
    category: "",
    userAuthorized: true,
    primaryChairPersonNameCompany: "",
    primaryChairPersonNameEmail: "",
  });

  const now = moment();

  const timeConstraints = {
    hours: { min: moment().hours(), max: 23 },
    minutes: { min: 0, max: 59 },
  };

  const isValidStartTime = (current) => {
    const yesterday = moment().subtract(1, "day");
    return current.isAfter(yesterday);
  };

  const isValidEndTime = (current, startDate) => {
    const start = moment.isMoment(startDate) ? startDate : moment(startDate);
    if (current.isSame(start, "day")) {
      return true;
    }
    return current.isAfter(start);
  };

  const endDateTimeConstraints = (startDate) => {
    const startMoment = moment(startDate);

    if (!startMoment.isValid())
      return { hours: { min: 0, max: 23 }, minutes: { min: 0, max: 59 } };

    return {
      hours: { min: startMoment.hour(), max: 23 },
      minutes: { min: 0, max: 59 },
    };
  };

  const handleOnChangeDesc = (html) => {
    var quill = descRef?.current.getEditor();
    if (!quill) {
      return;
    }
    quill.on("text-change", function (delta, old, source) {
      if (quill.getLength() > characterLimit) {
        quill.deleteText(characterLimit, quill.getLength());
      }
    });
    setDescLength(quill.getLength() - 1);
  };
  const handleOnChangeAgenda = (html) => {
    var quill = agendaRef?.current.getEditor();
    if (!quill) {
      return;
    }
    quill.on("text-change", function (delta, old, source) {
      if (quill.getLength() > characterLimit) {
        quill.deleteText(characterLimit, quill.getLength());
      }
    });
    setAgendaLength(quill.getLength() - 1);
  };

  const onSubmit = async (values, { setSubmitting, setFieldError }) => {
    if (values?.duration === "0m") {
      return setFieldError(
        "startEndOn",
        "End date has to be after than start date"
      );
    }
    if (!checkDateWithIn24Hr(values.startOn, values.startEndOn)) {
      return setFieldError(
        "startEndOn",
        "Meeting duration shouldn’t be more than 24 hrs"
      );
    }

    let time = new Date().getTime();
    const data = returnServerPayload(values, userInfo?.dateformat);

    try {
      if (isAddMode) {
        try {
          const res = await addMeetingDef({ data }).unwrap();
          setSubmitting(false);
          setTimeout(
            () => navigate(`/add-edit/meeting/${res?.meetingDefinationId}`),
            1000
          );
        } catch (err) {
          console.error("Failed to add meeting:", err);
          if (err.status === 401) {
          }
        }
      } else {
        const postUpdateData = {
          ...data,
          meetingDefinationId: Number(meetingId),
        };

        const res = await updateMeetingDef({
          data: postUpdateData,
          companyId: selectedCustId,
        });
        setDefaultValues((prev)=>{
          return {...prev,
          'regionName': res.data.regionName,
          'regionId': res.data.regionId,}
        });
        setSubmitting(false);
        refetchEditMeeting();
        statusActionRefetch();
        auditDataRefetch();
        occurenceRefetch();
      }
    } catch (err) {
      setSubmitting(false);
    } finally {
      setSubmitting(false);
    }
  };

  const onCompanySelection = async (selectedCompany, setFieldValue) => {
    if (selectedCompany[0]?.id) {
      setSelectedCustId(selectedCompany?.[0]?.id);
      setFieldValue("customerId", selectedCompany?.[0]?.id);
      setFieldValue("customer", selectedCompany?.[0]?.id);
      setFieldValue("customerName", selectedCompany?.[0]?.label);
    } else {
      setSelectedCustId(null);
      setFieldValue("customerId", "");
      setFieldValue("customer", "");
      setFieldValue("customerName", "");
      setFieldValue("forumId", "");
      setFieldValue("forumName", "", true);
      setFieldValue("categoryId", "");
      setFieldValue("category", "");
      setFieldValue("groups",[]);
    }
  };

  const resetValues = (setFieldValue, values) => {
    setFieldValue("weeks", [getWeekDayAccordingDate(values.startOn)]);
    setFieldValue("redioButtonSelection", "On Day");
    setFieldValue("monthDaysNo", 1);
    setFieldValue("weekNo", getWeekDayAccordingDate(values.startOn));
    setFieldValue("weekDay", getWeekDayAccordingDate(values.startOn));
    setFieldValue("yearMonthName", "1");
    setFieldValue("yearMonthNameForOnDay", "1");
  };

  const weekHander = (weeksArray, val, setFieldValue) => {
    var index = weeksArray.indexOf(val);
    if (index !== -1) {
      weeksArray.splice(index, 1);
    } else {
      weeksArray.push(val);
    }

    setFieldValue("weeks", [...weeksArray]);
  };

  const msg = (dataValues, setFieldValue) => {
    if (dataValues.units === "daily") {
      let msgData = `Occurs every ${
        dataValues.every > 1 ? dataValues.every + " days" : "day"
      } `;
      meetingRecurrenceSummary = msgData;
      return msgData;
    }
    if (dataValues.units === "weekly") {
      let weekData = week.filter((obj) => dataValues.weeks.includes(obj.value));
      let weekDays = weekData.map((obj) => obj.name);
      let msgData = `Occurs every ${
        dataValues.every > 1 ? dataValues.every + " weeks on" : ""
      } ${weekDays.join().replace(/,/g, ", ")}`;
      meetingRecurrenceSummary = msgData;
      return msgData;
    }

    if (dataValues.units === "monthly") {
      if (dataValues.redioButtonSelection === "On Day") {
        if (dataValues.every > 1) {
          let msgData = `Occurs every ${dataValues.every} months on day ${dataValues.monthDaysNo}`;
          meetingRecurrenceSummary = msgData;
          return msgData;
        }

        let msgData = `Occurs every  month on day ${dataValues.monthDaysNo}`;
        meetingRecurrenceSummary = msgData;
        return msgData;
      }

      if (dataValues.redioButtonSelection === "On The") {
        let weekData = week.filter((obj) => obj.value === dataValues.weekDay);

        let weekDataCount = weekCount.filter(
          (obj) => obj.value === dataValues.weekNo
        );

        if (dataValues.every > 1) {
          let msgData = `Occurs every ${
            dataValues.every > 1 ? dataValues.every : ""
          } months on ${weekDataCount?.[0]?.key} ${weekData?.[0]?.name}`;
          meetingRecurrenceSummary = msgData;
          return msgData;
        }
        let msgData = `Occurs every ${
          dataValues.every > 1 ? dataValues.every : ""
        } month on ${weekDataCount?.[0]?.key} ${weekData?.[0]?.name}`;
        meetingRecurrenceSummary = msgData;
        return msgData;
      }
    }
    if (dataValues.units === "yearly") {
      if (dataValues.redioButtonSelection === "On Day") {
        let weekData = yearMonthName.filter(
          (obj) => obj.value === dataValues.yearMonthNameForOnDay
        );

        let msgData = `Occurs every  year on day ${dataValues.monthDaysNo} of ${weekData?.[0]?.key}`;
        //setFieldValue("meetingRecurrenceSummary",msgData)
        meetingRecurrenceSummary = msgData;
        return msgData;
      }
      if (dataValues.redioButtonSelection === "On The") {
        const weekDataCount = weekCount.find(
          (obj) => obj.value === dataValues.weekNo
        );

        const weekData = week.find((obj) => obj.value === dataValues.weekDay);

        const weekData1 = yearMonthName.find(
          (obj) => obj.value === dataValues.yearMonthName
        );

        let msgData = "Occurs every year on ";

        if (weekDataCount && weekDataCount.key) {
          msgData += `the ${weekDataCount.key} `;
        }

        if (weekData && weekData.name) {
          msgData += `${weekData.name} of `;
        }

        if (weekData1 && weekData1.key) {
          msgData += weekData1.key;
        }

        meetingRecurrenceSummary = msgData;
        return msgData;
      }
    }
    meetingRecurrenceSummary = "";
    return "";
  };

  const onSubmitReason = async (fields, setSubmitting) => {
    const postAction = {
      meetingDefinationId: meetingId,
      actionType: "Cancel Meeting",
      cancelReason: "Cancel meeting reason-" + fields?.description,
    };

    const resp = await cancelMeeting({
      data: postAction,
      queryString: `?tenantId=${selectedCustId}`,
    });

    setSubmitting(false);
    setisHemStatus("Cancel Meeting");
    refetchTimelines();
    refetchEditMeeting();
    occurenceRefetch();
    setActiveTabs("1");
    auditDataRefetch();
  };

  const handleOnSelectAction = async (actionStatus) => {
    if (actionStatus === "Download iCAL") {
      if (defaultValue?.signedAttachedFileUrl !== "") {
        const link = document.createElement("a");
        link.href = defaultValue.signedAttachedFileUrl;
        link.target = "_blank";
        link.click();
      }
      return;
    }
    if (actionStatus && actionStatus !== "Cancel Meeting") {
      const postAction = {
        meetingDefinationId: meetingId,
        actionType: actionStatus,
      };
      statusAction({
        data: postAction,
        queryString: `?tenantId=${selectedCustId}`,
      });
    } else {
      toggle("6");
    }
  };

  const resetDependentValues = (setFieldValue) => {
    setFieldValue("committee", "");
    setFieldValue("forum", "");
    setFieldValue("track", "");
    setFieldValue("subTrack", "");
    setFieldValue("subTrackName", "");
    setFieldValue("coordinator", []);
    setFieldValue("supplier", []);
    setFieldValue("groups", []);
    setFieldValue("chairPerson", "");
    setFieldValue("persons", []);
  };

  useEffect(() => {
    const companyData = allCompany?.message
      ? []
      : allCompany?.length !== 0 &&
        allCompany?.map((item) => ({
          label: item?.field1Key,
          id: item?.field1Value,
        }));

    setCompanyOptions(companyData || []);
  }, [allCompany]);

  useEffect(() => {
    const allCommitteeData = allCommittee?.message
      ? []
      : allCommittee?.length !== 0 &&
        allCommittee?.map((item) => ({
          label: item?.name,
          id: item?.id,
        }));

    setCommitteOptions(allCommitteeData || []);
  }, [allCommittee]);

  useEffect(() => {
    const supplierData = allSuppliers?.message
      ? []
      : allSuppliers?.length !== 0 &&
        allSuppliers?.map((item) => ({
          label: item?.field1Key,
          id: item?.field1Value,
        }));

    setSupplierOptions(supplierData || []);
  }, [isSupliersLoading]);

  useEffect(() => {
    if (editMeetingData && !isAddMode) {
      setSelectedCustId(editMeetingData?.companyId);

      // if (editMeetingData?.status !== "Draft") {
      //   setRegionList([
      //     {
      //       label: editMeetingData?.regionName,
      //       value: editMeetingData?.regionId,
      //     },
      //   ]);
      // }
      // setCategory(encodeURIComponent(editMeetingData.category));
      // setForumName(encodeURIComponent(editMeetingData.forumName));
      // setTrackName(encodeURIComponent(editMeetingData.trackName));

      setDefaultValues((prev) => {
        return {
          ...prev,
          regionName: editMeetingData.regionName,
          regionId: editMeetingData.regionId,
          ...createUIPayload(editMeetingData, dateformat, userInfo),
          timeZone: timezone,
          coordinator: createPayloadForChips(
            editMeetingData?.secondaryChairPersons,
            editMeetingData?.secondaryChairPersonsName,
            editMeetingData?.secondaryChairPersonsEmail,
            editMeetingData?.secondaryChairPersonsCompany
          ),

          persons: createPayloadForChips(
            editMeetingData?.usersId,
            editMeetingData?.usersName,
            editMeetingData?.usersEmail,
            editMeetingData?.usersCompany
          ),

          groups: editMeetingData?.statusId==3 || editMeetingData?.statusId==4 ? extractGroupList(editMeetingData.groupsId,editMeetingData.groupsName) : filterGroupUser(allGroupData || [], editMeetingData?.groupsId.toString()),

          signedAttachedFileUrl: editMeetingData?.signedAttachedFileUrl,
          redioButtonSelection:
            editMeetingData?.recurrenceMonthday ||
            editMeetingData?.recurrenceYearDay
              ? "On Day"
              : "On The",
          yearMonthNameForOnDay: editMeetingData?.recurrenceYearMonth,
          monthDaysNo:
            editMeetingData?.recurrenceYearDay ||
            editMeetingData?.recurrenceMonthday ||
            "1",
          weekNo:
            editMeetingData?.recurrenceYearWeekdayInterval ||
            editMeetingData?.recurrenceMonthWeekdayInterval,
          weekDay:
            editMeetingData?.recurrenceYearWeekday ||
            editMeetingData?.recurrenceMonthWeekday,
          yearMonthName: editMeetingData?.recurrenceYearMonthDay,
          primaryChairPerson: editMeetingData?.primaryChairPerson,
          externalEmails: editMeetingData?.externalEmailsId
            ?.split(",")
            ?.map((email) => ({
              label: email,
              value: email,
            })),
        };
      });
      setTrackName(editMeetingData?.trackName);
      let agendaText = "";
      agendaText =
        editMeetingData?.agenda != ""
          ? editMeetingData?.agenda
              .replace(
                /(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/gi,
                ""
              )
              .trim()
          : "";
      agendaText = agendaText != "" ? agendaText.replace(/\s+/g, " ") : "";
      setAgendaLength(agendaText.length);
      let descText = "";
      descText =
        editMeetingData?.objectives != ""
          ? editMeetingData?.objectives
              .replace(
                /(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/gi,
                ""
              )
              .trim()
          : "";
      descText = descText != "" ? descText.replace(/\s+/g, " ") : "";
      setDescLength(descText.length);
      const isDisabled =
        defaultValue?.mop === "V" ||
        (defaultValue?.mop === "E" && !defaultValue?.userAuthorized) ||
        editMeetingData?.status === "Cancelled";

      setIsAutorize(isDisabled);
      // if (allTrack) {
      //   setSelectValueByText("trackId", editMeetingData?.trackName);
      // }
    }
  }, [editMeetingData, allTrack, allGroupData.length > 0]);

  return (
    <div id="GovernanceMeeting">
      {updateMeetingLoading ||
      addMeetingLoading ||
      statusActionLoading ||
      editMeetingLoading ? (
        <Row>
          <Col md={12}>
            <div className="rctLoader">
              <LoadingIcons.Bars
                height="5em"
                stroke="transparent"
                fill="#06bcee"
              />
            </div>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col md={12}>
            <Row>
              <Col md={12} sm={12} xs={12}>
                <Header
                  isAddMode={isAddMode}
                  isSubmitting={formikRef?.current?.isSubmitting}
                  dirty={formikRef?.current?.dirty}
                  submitForm={formikRef?.current?.handleSubmit}
                  formikref={formikRef}
                  handleOnSelectAction={handleOnSelectAction}
                  meetingDefData={editMeetingData}
                  setShowTimeLine={setShowTimeLine}
                  showTimeLine={showTimeLine}
                  toggle={toggle}
                  activeTabs={activeTabs}
                  errorMessage={
                    statusActionData?.errorMessage ||
                    createMettingError?.data ||
                    updateMettingError?.data ||
                    ""
                  }
                />
              </Col>
            </Row>
            {(statusActionData?.errorMessage ||
              createMettingError?.data ||
              updateMettingError?.data) && (
              <Row>
                <Col md={12}>
                  <p className="red py-2">
                    {
                      <strong>
                        {statusActionData?.errorMessage ||
                          createMettingError?.data ||
                          updateMettingError?.data}
                      </strong>
                    }
                  </p>
                </Col>
              </Row>
            )}
            <Row>
              <Col
                md={8}
                // className={
                //   defaultValue?.mop === "V" ||
                //   defaultValue?.status === "Cancelled" ||
                //   !defaultValue?.userAuthorized
                //     ? "disabledallField"
                //     : ""
                // }
              >
                <Formik
                  innerRef={formikRef}
                  initialValues={defaultValue}
                  validationSchema={validationSchema}
                  enableReinitialize={true}
                  onSubmit={onSubmit}
                >
                  {({
                    errors,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    handleBlur,
                    values,
                    dirty,
                    isValid,
                    submitCount,
                    handleChange,
                  }) => {
                    return (
                      <Form noValidate>
                        <Row>
                          <Col md={12}>
                            {!isAddMode && showTimeLine && (
                              <Row>
                                <Col md={12}>
                                  <StatusTimeLine
                                    showTimeLine={showTimeLine}
                                    setShowTimeLine={setShowTimeLine}
                                    data={timelines?.data}
                                  />
                                </Col>
                              </Row>
                            )}
                            <Row>
                              <Col md={12} sm={12} xs={12}>
                                {!isValid && submitCount > 0 && (
                                  <div className="alert alert-danger">
                                    {errors?.startEndOn
                                      ? errors?.startEndOn
                                      : `The form has missing required fields, please
                                    revalidate the highlighted fields and update
                                    the form !`}
                                  </div>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6} sm={6} xs={12}>
                                <InputTextField
                                  disabled={isAutorize}
                                  name="title"
                                  label="Title"
                                  required
                                />
                              </Col>
                              <Col md={6} sm={6} xs={12}>
                                <BootstrapForm.Group className="form-group">
                                  <BootstrapForm.Label>
                                    <span className="rStar"></span> Company
                                  </BootstrapForm.Label>

                                  <Field
                                    name="customer"
                                    id="company"
                                    disabled={!isAddMode}
                                    className={
                                      errors.customer && touched.customer
                                        ? "is-invalid"
                                        : ""
                                    }
                                    component={MeetingDropdownPlusTypeahead}
                                    options={companyOptions || []}
                                    onSelection={(val) => {
                                      onCompanySelection(val, setFieldValue);
                                      setFieldValue(
                                        "customerName",
                                        val?.[0]?.label || "",
                                        true
                                      );
                                      setSelectedCustId(val?.[0]?.id);
                                      resetDependentValues(setFieldValue);
                                      setFieldValue(
                                        "customer",
                                        val?.[0]?.id || "",
                                        true
                                      );
                                      //paramHander(value || { value: "" }, "customer");
                                    }}
                                    // {...(!isAddMode &&
                                    //   companyValue && {
                                    //     defaultInputValue: companyValue,
                                    //   })}
                                    {...(!isAddMode && {
                                      selected: values?.customerName
                                        ? [
                                            {
                                              label: values.customerName,
                                              value: values?.customer,
                                            },
                                          ]
                                        : [],
                                    })}
                                  />
                                </BootstrapForm.Group>
                              </Col>
                              <Col md={6} sm={6} xs={12}>
                                <SelectField
                                  required
                                  name="forumId"
                                  label="Forum"
                                  disabled={isAutorize}
                                  onChange={(e) => {
                                    const selectedOption =
                                      e.target.options[e.target.selectedIndex];
                                    const forumName =
                                      selectedOption.getAttribute("data-name");

                                    setFieldValue(
                                      "forumId",
                                      e.target.value,
                                      true
                                    );
                                    setFieldValue("forumName", forumName, true);
                                    setSelectedForum(e.target.value);
                                  }}
                                >
                                  {selectedCustId
                                    ? forumData?.map((frm, index) => {
                                        return (
                                          <option
                                            value={frm?.forumId}
                                            key={`frm-${index}`}
                                            data-name={frm?.forumName}
                                          >
                                            {frm?.forumName}
                                          </option>
                                        );
                                      })
                                    : null}
                                </SelectField>
                              </Col>
                              <Col md={6} sm={6} xs={12}>
                                <SelectField
                                  required
                                  name="categoryId"
                                  label="Category"
                                  disabled={isAutorize}
                                  onChange={(e) => {
                                    const selectedOption =
                                      e.target.options[e.target.selectedIndex];
                                    const catName =
                                      selectedOption.getAttribute("data-name");
                                    setCategory(e.target.value);
                                    setFieldValue("categoryId", e.target.value);
                                    setFieldValue("category", catName);
                                  }}
                                >
                                  {selectedCustId
                                    ? allCats?.map((cat, index) => {
                                        return (
                                          <option
                                            value={cat?.fieldNameValue1}
                                            key={`cat-${index}`}
                                            data-name={cat?.fieldNameKey1}
                                          >
                                            {cat?.fieldNameKey1}
                                          </option>
                                        );
                                      })
                                    : null}
                                </SelectField>
                              </Col>
                              <Col md={6} sm={6} xs={12}>
                                <BootstrapForm.Group className="form-group">
                                  <BootstrapForm.Label>
                                    <span className="rStar"></span> Committee
                                  </BootstrapForm.Label>

                                  <Field
                                    name="committeeId"
                                    id="committeeId"
                                    disabled={isAutorize}
                                    className={
                                      errors.committeeId && touched.committeeId
                                        ? "is-invalid"
                                        : ""
                                    }
                                    component={MeetingDropdownPlusTypeahead}
                                    options={
                                      values?.forumId ? committeOptions : []
                                    }
                                    onSelection={(value) => {
                                      setFieldValue(
                                        "committee",
                                        value[0]?.label || "",
                                        true
                                      );
                                      setFieldValue(
                                        "committeeId",
                                        value[0]?.id || ""
                                      );
                                      // paramHander(val, "committee");
                                      //paramHander(value || { value: "" }, "customer");
                                    }}
                                    {...(!isAddMode && {
                                      selected: values?.committee
                                        ? [
                                            {
                                              label: values?.committee,
                                              value: values?.committeeId,
                                            },
                                          ]
                                        : [],
                                    })}
                                    selected={
                                      values?.forumId
                                        ? committeOptions.filter(
                                            (option) =>
                                              Number(option.id) ===
                                              Number(values.committeeId)
                                          )
                                        : []
                                    }
                                  />
                                </BootstrapForm.Group>
                              </Col>
                              <Col md={6} sm={6} xs={12}>
                                <SelectField
                                  name="regionName"
                                  label="Region"
                                  disabled={isAutorize}
                                  value={values?.regionId}                         
                                  onChange={(e) => {
                                    const selectedOption =
                                      e.target.options[e.target.selectedIndex];
                                    const regionName =
                                      selectedOption.getAttribute("data-name");

                                    setFieldValue("regionName", regionName);
                                    setFieldValue("regionId", e.target.value);
                                  }}
                                >
                                  {allRegionsData?.result?.length > 0 &&
                                    allRegionsData?.result?.map(
                                      (region, index) => {
                                        return (
                                          <option
                                            value={region?.regionId}
                                            key={`region-${index}`}
                                            data-name={region?.regionName}
                                          >
                                            {region?.regionName}
                                          </option>
                                        );
                                      }
                                    )}
                                </SelectField>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6} sm={6} xs={12}>
                                <SelectField
                                  name="track"
                                  id="trackId"
                                  label="Track"
                                  disabled={isAutorize}
                                  onChange={(e) => {
                                    const selectedOption =
                                      event.target.options[
                                        event.target.selectedIndex
                                      ];
                                    const trackId =
                                      selectedOption.getAttribute("data-id");
                                    setFieldValue("trackId", trackId);
                                    setFieldValue("track", e.target.value);
                                    setFieldValue("subTrack", "");
                                    setFieldValue("subTrackName", "");
                                    setTrackName(e.target.value);
                                  }}
                                >
                                  {selectedCustId
                                    ? allTrack?.length > 0 &&
                                      allTrack?.map((track, index) => {
                                        return (
                                          <option
                                            value={track?.setTrackName}
                                            key={`track-${index}`}
                                            data-id={track?.trackId}
                                          >
                                            {track?.trackName}
                                          </option>
                                        );
                                      })
                                    : null}
                                </SelectField>
                              </Col>
                              <Col md={6} sm={6} xs={12}>
                                <SelectField
                                  name="subTrackName"
                                  label="Sub-track"
                                  disabled={isAutorize}
                                  onChange={(e) => {
                                    const selectedOption =
                                      e.target.options[e.target.selectedIndex];
                                    const subtrackId =
                                      selectedOption.getAttribute("data-id");

                                    setFieldValue(
                                      "subTrackName",
                                      e.target.value
                                    );
                                    setFieldValue("subTrack", subtrackId);
                                  }}
                                >
                                  {values?.track
                                    ? allSubTrack?.length > 0 &&
                                      allSubTrack?.map((track, index) => {
                                        return (
                                          <option
                                            value={track?.subTrackName}
                                            key={`subtrk-${index}`}
                                            data-id={track?.subTrackId}
                                          >
                                            {track?.subTrackName}
                                          </option>
                                        );
                                      })
                                    : null}
                                </SelectField>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12} sm={12} xs={12}>
                                <div
                                  className={!isAddMode ? "infoBtnDiv" : null}
                                >
                                  {/* {console.log(values)} */}
                                  <CustomTypeHead
                                    required
                                    clearButton
                                    // disabled={isAutorize}
                                    id="chairPerson"
                                    name="chairPerson"
                                    label="Chairperson"
                                    thfor="personApi"
                                    placeholder="Please type to search"
                                    companyId={
                                      values?.customerId || values?.customer
                                    }
                                    autoComplete="off"
                                    labelkey={
                                      isAddMode
                                        ? "fullname"
                                        : (option) =>
                                            option?.fullname
                                              ? `${option?.fullname} | ${option?.email}`
                                              : option
                                    }
                                    onChange={(selected) => {
                                      setFieldValue(
                                        "chairPerson",
                                        selected?.[0]?.fullname || ""
                                      );
                                      setFieldValue(
                                        "chairPersonName",
                                        selected?.[0]?.fullname || ""
                                      );
                                      setFieldValue(
                                        "primaryChairPersonName",
                                        selected?.[0]?.fullname || "",
                                        true
                                      );
                                      setFieldValue(
                                        "primaryChairPerson",
                                        selected?.[0]?.userId || "",
                                        true
                                      );
                                      setFieldValue(
                                        "primaryChairPersonNameCompany",
                                        selected?.[0]?.companyName || "",
                                        true
                                      );
                                      setFieldValue(
                                        "primaryChairPersonNameEmail",
                                        selected?.[0]?.email || "",
                                        true
                                      );
                                    }}
                                    {...(isAddMode
                                      ? {
                                          selected:
                                            values?.primaryChairPersonName
                                              ? [
                                                  `${values?.primaryChairPersonName} | ${values?.primaryChairPersonNameEmail} | ${values?.primaryChairPersonNameCompany}`,
                                                ]
                                              : [],
                                        }
                                      : {
                                          selected: values?.chairPersonName
                                            ? [
                                                `${values?.chairPersonName} | ${values?.chairPersonEmail} | ${values?.chariPersonCompany}`,
                                              ]
                                            : [],
                                        })}
                                    disabled={
                                      !isAddMode && values.status !== "Draft"
                                    }
                                  />

                                  {/* {!isAddMode ? (
                                    <Button
                                      title="Info"
                                      bsPrefix=" "
                                      className="infoicn top-50"
                                    >
                                      <ImInfo />
                                    </Button>
                                  ) : null} */}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12} sm={12} xs={12}>
                                <CustomTypeHead
                                  multiple
                                  clearButton
                                  disabled={isAutorize}
                                  id="coordinator"
                                  name="coordinator"
                                  label="Coordinator(s)"
                                  thfor="personApi"
                                  placeholder="Please type to search"
                                  // onBlur={() => setOpentray(false)}
                                  // setOpentray={setOpentray}
                                  companyId={selectedCustId || values?.customer}
                                  autoComplete="off"
                                  labelkey={
                                    isAddMode
                                      ? "fullname"
                                      : (option) =>
                                          option?.fullname
                                            ? `${option?.fullname} | ${option?.email}`
                                            : option
                                  }
                                  onChange={(selected) => {
                                    setFieldValue(
                                      "coordinator",
                                      selected,
                                      true
                                    );
                                  }}
                                  selected={values?.coordinator || []}
                                  // open={opentray}
                                  // {...(isAddMode
                                  //   ? {
                                  //       selected: values?.chairPersonName
                                  //         ? [
                                  //             `${values?.chairPersonName} | ${values?.primaryChairPersonNameEmail} | ${values?.primaryChairPersonNameCompany}`,
                                  //           ]
                                  //         : [],
                                  //     }
                                  //   : {
                                  //       selected: values?.chairPersonName
                                  //         ? [
                                  //             `${values?.chairPersonName} | ${values?.primaryChairPersonNameEmail} | ${values?.primaryChairPersonNameCompany}`,
                                  //           ]
                                  //         : [],
                                  //     })}
                                  // disabled={isAddMode && defaultValue.status !== "Draft"}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12} sm={12} xs={12}>
                                <Editor
                                  required
                                  ref={descRef}
                                  disabled={isAutorize}
                                  textlength={descLength}
                                  characterLimit={500}
                                  name="objectives"
                                  label={"Description"}
                                  value={values.objectives}
                                  placeholder="Description here..."
                                  // onKeyDown={checkCharacterCount}
                                  // onBlur={checkCharacterCount}
                                  onChange={(value) => {
                                    setFieldValue("objectives", value, true);
                                    handleOnChangeDesc(value);
                                  }}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12} sm={12} xs={12}>
                                <Editor
                                  required
                                  ref={agendaRef}
                                  disabled={isAutorize}
                                  textlength={agendaLength}
                                  characterLimit={500}
                                  label="Agenda"
                                  name="agenda"
                                  value={values.agenda}
                                  placeholder="Agenda here..."
                                  // onKeyDown={checkCharacterCount1}
                                  onChange={(value) => {
                                    setFieldValue("agenda", value, true);
                                    handleOnChangeAgenda(value);
                                  }}
                                />
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col md={6} sm={6} xs={12}>
                                <SelectField
                                  required
                                  name="meetingTypeId"
                                  label="Meeting Type"
                                  // disabled={isAutorize}
                                  onChange={(e) => {
                                    const selectedOption =
                                      event.target.options[
                                        event.target.selectedIndex
                                      ];
                                    const locName =
                                      selectedOption.getAttribute("data-name");
                                    setFieldValue(
                                      "meetingLocation",
                                      locName,
                                      true
                                    );
                                    setFieldValue(
                                      "meetingTypeId",
                                      e.target.value,
                                      true
                                    );
                                  }}
                                  disabled={
                                    !isAddMode && values?.status !== "Draft"
                                  }
                                >
                                  {allLocationData?.length > 0 &&
                                    allLocationData?.map((loc, index) => {
                                      return (
                                        <option
                                          value={loc?.locationId}
                                          key={`loc-${index}`}
                                          data-name={loc?.locationName}
                                        >
                                          {loc?.locationName}
                                        </option>
                                      );
                                    })}
                                </SelectField>
                              </Col>
                              <Col md={6} sm={6} xs={12}>
                                <div
                                  className={!isAddMode ? "infoBtnDiv" : null}
                                >
                                  <InputTextField
                                    disabled={isAutorize}
                                    name="location"
                                    label="Meeting Details"
                                    required
                                  />
                                  {/* {!isAddMode ? (
                                    <Button
                                      title="Info"
                                      bsPrefix=" "
                                      className="infoicn top-50"
                                    >
                                      <ImInfo />
                                    </Button>
                                  ) : null} */}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12} sm={12} xs={12}>
                                <div className="margin-t-5 padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
                                  <div className="bgGray padding-5">
                                    Suppliers
                                  </div>

                                  <Row>
                                    <Col md={12}>
                                      <InputTextFieldForChips
                                        multiple
                                        disabled={isAutorize}
                                        name="supplier"
                                        label="Suppliers"
                                        options={supplierOptions || []}
                                        placeholder="Type to search a supplier..."
                                        onChange={(selected) => {
                                          setFieldValue(
                                            "supplier",
                                            selected,
                                            true
                                          );
                                        }}
                                        selected={values?.supplier || []}
                                        renderMenuItemChildren={(
                                          option,
                                          props
                                        ) => {
                                          let label =
                                            option.label.length > 30
                                              ? option.label.slice(0, 30) +
                                                "..."
                                              : option.label;
                                          let title =
                                            option.label.length > 30
                                              ? option.label
                                              : "";
                                          return (
                                            <div>
                                              <span title={title}>{label}</span>
                                            </div>
                                          );
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12} sm={12} xs={12}>
                                <div className="margin-t-5 padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
                                  <div className="bgGray padding-5">
                                    Participants
                                  </div>
                                  <Row>
                                    <Col md={6}>
                                      <CustomTypeHead
                                        required
                                        multiple
                                        clearButton
                                        disabled={isAutorize}
                                        id="persons"
                                        name="persons"
                                        label="Person(s)"
                                        thfor="personApi"
                                        placeholder="Please type to search"
                                        companyId={
                                          selectedCustId || values?.customer
                                        }
                                        autoComplete="off"
                                        labelkey={
                                          isAddMode
                                            ? "fullname"
                                            : (option) =>
                                                option?.fullname
                                                  ? `${option?.fullname} | ${option?.email}`
                                                  : option
                                        }
                                        onChange={(selected) => {
                                          setFieldValue(
                                            "persons",
                                            selected,
                                            true
                                          );
                                        }}
                                        selected={values?.persons || []}
                                      />
                                    </Col>
                                    <Col md={6}>
                                      <InputTextFieldForChips
                                        multiple
                                        disabled={isAutorize}
                                        name="groups"
                                        label="Groups"
                                        labelkey="groupName"
                                        options={
                                          selectedCustId ? allGroupData : []
                                        }
                                        placeholder="Type to search a group..."
                                        onChange={(selected) =>
                                          setFieldValue(
                                            "groups",
                                            selected,
                                            true
                                          )
                                        }
                                        selected={values?.groups || []}
                                      />
                                    </Col>
                                    <Col md={12}>
                                      <InputTextFieldForChips
                                        allowNew
                                        multiple
                                        disabled={isAutorize}
                                        className={
                                          errors.externalEmails &&
                                          touched.externalEmails
                                            ? "is-invalid"
                                            : ""
                                        }
                                        name="externalEmails"
                                        label="External Email(s)"
                                        newSelectionPrefix="Use comma sepration: "
                                        options={[]}
                                        placeholder="Enter email..."
                                        onChange={(selected) => {
                                          setFieldValue(
                                            "externalEmails",
                                            selected,
                                            true
                                          );
                                        }}
                                        selected={
                                          values?.externalEmails?.[0]?.label
                                            ? values?.externalEmails
                                            : []
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12} sm={12} xs={12}>
                                <div className="margin-t-5 padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
                                  <div className="bgGray padding-5">
                                    Schedule
                                  </div>
                                  <Row className="mt-3 align-items-center">
                                    <Col md={6}>
                                      <DatePickerF
                                        required
                                        disabled={isAutorize}
                                        label="Start Time"
                                        name="startOn"
                                        className="form-control dateTimePickerField"
                                        locale="en"
                                        initialValue={moment()}
                                        timeConstraints={timeConstraints}
                                        isValidDate={isValidStartTime}
                                        value={
                                          values?.startOn
                                            ? moment(
                                                values.startOn,
                                                userInfo?.dateformat
                                              )
                                            : ""
                                        }
                                        onChange={(value) => {
                                          setFieldValue("startOn", value, true);

                                          if (moment(values?.startEndOn).isBefore(value)) {
                                            const end=moment(value).add(30, 'minutes');
                                            setFieldValue("startEndOn", end, true);
                                            setFieldValue(
                                              "duration",
                                              findDuration(
                                                convertDate(
                                                  value,
                                                  prefDateFormatAccordingUser(
                                                    userInfo?.dateformat,
                                                    true
                                                  )
                                                ),
                                                convertDate(
                                                  end,
                                                  prefDateFormatAccordingUser(
                                                    userInfo?.dateformat,
                                                    true
                                                  )
                                                )
                                              ),
                                              true
                                            );
                                          }else {                                            
                                            setFieldValue(
                                              "duration",
                                              findDuration(
                                                convertDate(
                                                  value,
                                                  prefDateFormatAccordingUser(
                                                    userInfo?.dateformat,
                                                    true
                                                  )
                                                ),
                                                convertDate(
                                                  values.startEndOn,
                                                  prefDateFormatAccordingUser(
                                                    userInfo?.dateformat,
                                                    true
                                                  )
                                                )
                                              ),
                                              true
                                            );                                          
                                          }
                                        

                                          setFieldValue("weeks", [
                                            getWeekDayAccordingDate(value),
                                          ]);
                                          setFieldValue(
                                            "weekNo",
                                            getWeekDayAccordingDate(value)
                                          );
                                          setFieldValue(
                                            "weekDay",
                                            getWeekDayAccordingDate(value)
                                          );
                                          setFieldValue("recuring", false);
                                          setFieldValue(
                                            "recuringStartOn",
                                            value,
                                            true
                                          );

                                          

                                        }}
                                      />
                                    </Col>
                                    <Col md={6}>
                                      <DatePickerF
                                        required
                                        input
                                        disabled={isAutorize}
                                        label="End Time"
                                        name="startEndOn"
                                        className="form-control dateTimePickerField"
                                        locale="en"
                                        initialValue={moment()}
                                        timeConstraints={endDateTimeConstraints(
                                          values.startOn
                                        )}
                                        isValidDate={(currentDate) =>
                                          isValidEndTime(
                                            currentDate,
                                            values.startOn
                                          )
                                        }
                                        value={
                                          values?.startEndOn
                                            ? moment(
                                                values.startEndOn,
                                                userInfo?.dateformat
                                              )
                                            : ''
                                        }
                                        onChange={(value, dateString) => {
                                          setFieldValue(
                                            "duration",
                                            findDuration(
                                              convertDate(
                                                values.startOn,
                                                prefDateFormatAccordingUser(
                                                  userInfo?.dateformat,
                                                  true
                                                )
                                              ),
                                              convertDate(
                                                value,
                                                prefDateFormatAccordingUser(
                                                  userInfo?.dateformat,
                                                  true
                                                )
                                              )
                                            ),
                                            true
                                          );

                                          setFieldValue(
                                            "startEndOn",
                                            value,
                                            true
                                          );
                                        }}
                                      />
                                    </Col>
                                    <Col md={6} className="mt-3">
                                      <InputTextField
                                        disabled
                                        value={timezone}
                                        name="timezone"
                                        label="TimeZone"
                                      />
                                    </Col>
                                    <Col md={4} sm={10} xs={9} className="mt-3">
                                      <InputTextField
                                        disabled
                                        name="duration"
                                        label="Duration"
                                      />
                                    </Col>
                                    <Col sm={2} xs={3} className="mt-3">
                                      <div className="form-group appntRecurDv">
                                        <label
                                          className="d-block text-center mb-2"
                                        >
                                          Recurring-
                                        </label>
                                        <Field
                                          type="checkbox"
                                          className="form-control"
                                          disabled={
                                            values?.startEndOn &&
                                            values?.startOn &&
                                            isAutorize
                                          }
                                          name="recuring"
                                        />
                                      </div>
                                    </Col>
                                    {values.recuring && (
                                      <Col
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        className="mt-3"
                                      >
                                        <div className="border p-2 mb-2">
                                          <div className="bgGray padding-5 mb-2">
                                            Recurrence
                                          </div>
                                          <Row>
                                            <Col md={3} sm={12} xs={12}>
                                              <SelectField
                                                name="units"
                                                label="Repeats On"
                                                disabled={isAutorize}
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    "units",
                                                    e.target.value
                                                  );
                                                  resetValues(
                                                    setFieldValue,
                                                    e.target.value
                                                  );
                                                }}
                                              >
                                                {utis?.map(({ key, value }) => (
                                                  <option
                                                    value={value}
                                                    key={key}
                                                  >
                                                    {key}
                                                  </option>
                                                ))}
                                              </SelectField>
                                            </Col>
                                            <Col md={3} sm={12} xs={12}>
                                              <InputTextFieldNumber
                                                disabled={isAutorize}
                                                name="every"
                                                label="Repeats Every"
                                                min={1}
                                                max={99}
                                                onChange={(e) => {
                                                  const val = e.target.value;
                                                  if (val) {
                                                    if (val <= 99 && val > 0) {
                                                      setFieldValue(
                                                        "every",
                                                        val,
                                                        true
                                                      );
                                                    }
                                                  } else {
                                                    setFieldValue(
                                                      "every",
                                                      "",
                                                      true
                                                    );
                                                  }
                                                }}
                                              />
                                            </Col>
                                            <Col md={3} sm={12} xs={12}>
                                              <SelectField
                                                disabled={isAutorize}
                                                name="redioAccuranceSelection"
                                                label="Ending"
                                                onChange={(e) => {
                                                  const value = e.target.value;
                                                  // setFieldValue(
                                                  //   "recuringEndOn",
                                                  //   "",
                                                  //   true
                                                  // );
                                                  setFieldValue(
                                                    "redioAccuranceSelection",
                                                    value
                                                  );
                                                  setFieldValue(
                                                    "redioAccuranceSelectionEndAfterDaysNo",
                                                    "1",
                                                    true
                                                  );
                                                }}
                                              >
                                                {ending &&
                                                  ending.map((ln, i) => {
                                                    return (
                                                      <option
                                                        key={`key-1${i}`}
                                                        value={ln.value}
                                                      >
                                                        {ln.key}
                                                      </option>
                                                    );
                                                  })}
                                              </SelectField>
                                            </Col>
                                            <Col md={3} sm={12} xs={12}>
                                              <ReccuranceComponent
                                                isAutorize={isAutorize}
                                                values={values}
                                                _dateFormat={
                                                  userInfo?.dateformat
                                                }
                                                activeTab={activeTab}
                                                setFieldValue={setFieldValue}
                                              ></ReccuranceComponent>
                                            </Col>
                                          </Row>
                                          <Row className="align-items-center">
                                            {values.units !== "daily" && (
                                              <Col sm={12}>
                                                {
                                                  {
                                                    weekly: (
                                                      <WeekButton
                                                        weeks={values.weeks}
                                                        weekHander={weekHander}
                                                        setFieldValue={
                                                          setFieldValue
                                                        }
                                                      ></WeekButton>
                                                    ),
                                                    monthly: (
                                                      <MonthComponent
                                                        values={values}
                                                        activeTab={activeTab}
                                                        setFieldValue={
                                                          setFieldValue
                                                        }
                                                      ></MonthComponent>
                                                    ),
                                                    yearly: (
                                                      <YearComponent
                                                        values={values}
                                                        setFieldValue={
                                                          setFieldValue
                                                        }
                                                      ></YearComponent>
                                                    ),
                                                  }[values.units]
                                                }
                                                <span>
                                                  {msg(values, setFieldValue)}
                                                </span>
                                              </Col>
                                            )}
                                          </Row>
                                        </div>
                                      </Col>
                                    )}
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                            {!isAddMode && (
                              <Row>
                                <Col md={12}>
                                  <SystemInfo
                                    header="System Information"
                                    cusClass="system-info-panel"
                                    headers={[
                                      "Created On",
                                      "Created By",
                                      "Modified On",
                                      "Modified By",
                                    ]}
                                    values={[
                                      editMeetingData?.createdOn,
                                      editMeetingData?.createdByFullname,
                                      editMeetingData?.modifiedOn,
                                      editMeetingData?.modifiedByFullname,
                                    ]}
                                  />
                                </Col>
                              </Row>
                            )}
                          </Col>
                        </Row>
                      </Form>
                    );
                  }}
                </Formik>
              </Col>

              {!isAddMode && (
                <Col md={4}>
                  <RightSide
                    meetingDefData={editMeetingData}
                    activeTabs={activeTabs}
                    isAutorize={isAutorize}
                    onSubmitReason={onSubmitReason}
                    occurrencesdata={occurrencesdata}
                    auditData={auditData}
                    isFetching={auditIsFetching}
                    auditDataRefetch={auditDataRefetch}
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default AddEditMeeting;
