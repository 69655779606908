
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { TabContent, TabPane } from "react-bootstrap";
import ActivityDetails from "./activityDeatils";
import Attachment from "./attachments";

const ActionItemRightSide = ({
  actData,
  meetingDefData,
  activeTabs,
  occurenceData,
  companyId,
  meetingOccurrananceId,
}) => {
  return (
    <TabContent activeTab={activeTabs}>
      <TabPane tabId="1" active={activeTabs === "1"}>
        <ActivityDetails actData={actData || []} companyId={companyId} />
      </TabPane>
      <TabPane tabId="2" active={activeTabs === "2"}>
        <Attachment />
      </TabPane>
    </TabContent>
  );
};

export default ActionItemRightSide;
