
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/


export const reportList = (state = {}, action) => {
    switch (action.type) {
        case 'LOAD_REPORT_DATA_SUCCESS':
            return action.reportList;
        case 'EMPTY_REPORT_DATA_REDUCER':
            return {};
        default:
            return state;
    }
};

export const versionList = (state = {}, action) => {
    switch (action.type) {
        case 'LOAD_VERSION_DATA_SUCCESS':
            return action.versionList;
        case 'EMPTY_REPORT_DATA_REDUCER':
            return {};
        default:
            return state;
    }
};