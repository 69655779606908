
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import { createCICategory } from "../../../../actions/categoryBoard/quickViewAction";
import Cookies from "universal-cookie";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { navigationHooks } from "../../../../helpers/NavigationHook";
import swal from 'sweetalert';
import {IoClose, IoSaveOutline} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";
import { MyContext } from '_MyContext';
const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];

let CreateCiCategoryHeader = class CreateCiCategoryHeader extends React.Component {
  static contextType = MyContext;
  constructor(props) {
    super(props);
    this.state = {
      duplicateCatExists: false,
      duplicateSubCatExists: false,
    };
    this.showHome = this.showHome.bind(this);
    this.saveChange = this.saveChange.bind(this);
    this.userExists = this.userExists.bind(this);
    this.checkDuplicate = this.checkDuplicate.bind(this);
  }

  componentDidMount() {
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
    this.context.newTabPrevent();
	}
	componentDidUpdate() {
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
    this.context.newTabPrevent();
	}
  checkDuplicate(event) {
    let value = event.target.value.trim();
    let duplicates = this.state.valuesCategory.filter(
      (category) => category.trim() === value
    );
    if (duplicates.length > 1) {
      swal({
        text: "Category already exists!",
        button: "OK",
      });
      this.setState({ duplicateCatExists: true });
    } else {
      this.setState({ duplicateCatExists: false });
    }
  }

  showHome() {
    navigationHooks.navigate(homepagelocation);
  }
  userExists(arr) {
    return arr.some((element) => element.category_name);
  }
  saveChange(values) {
    let company = JSON.parse(values.ciCompany);
    let dataArray = [];
    this.props.extraCounts.map((count, index) => {
      let obj = {};
      obj.category_name = values["category_name" + count];
      obj.sub_category_name = values["sub_category_name" + count];
      console.log("arrayvar",index)
      if (Array.isArray(values.status)) {
        obj.status = values.status[index] === true ? "active" : "inactive";
      } else {
        obj.status = values.status === true ? "active" : "inactive";
      }
      obj.cat_company_name = company.field1Key;
      dataArray.push(obj);
    });
    let query = {
      cat_company_id: Number(company.field1Value),
      class_id: Number(values.classId),
    };
    this.props.createCICategory(query, dataArray);
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <Row className="margin-b-15">
        <Col lg={8} md={6} sm={4} xs={8}>
          <h2
            aria-label="Page Heading"
            role="contentinfo"
            bsClass=""
            className="sPageHeading1"
          >
            {this.props.tr["CI Category"]}
          </h2>
        </Col>
        <Col lg={4} md={6} sm={8} xs={4}>
          <div
            aria-label="Page Filters"
            role="contentinfo"
            className="paHedFilter"
          >
            <Link
              aria-label={this.props.tr["Save"]}
              to="javascript:void(0)"
              bsPrefix=" "
              bsStyle=""
              bsClass=""
              className="myBt plus"
              title={this.props.tr["Save"]}
              onClick={handleSubmit(this.saveChange)}
              disabled={this.props.showLoader}
              ref={(e) => (this.saveObjRef = e)}
            >
                {
                  this.props.showLoader
                    ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/>
                }
            </Link>
            <Link
              aria-label={this.props.tr["Close"]}
              to={'/categoryBoard'}
              state={{activeTab: 'category'}}
              bsPrefix=" "
              className="myBt cancel fillBtn"
              title={this.props.tr["Close"]}
              //onClick={() => navigationHooks.navigate({ state: {activeTab: 'category'}})}
              ref={e => this.closeButton = e}
            >
              <IoClose />
            </Link>
          </div>
        </Col>
      </Row>
    );
  }
};
CreateCiCategoryHeader = reduxForm({
  form: "CreateCiCategoryForm",
  destroyOnUnmount: false,
  enableReinitialize: true,
})(CreateCiCategoryHeader);

const mapStateToProps = ({ showLoader, ciCompany }) => {
  return {
    showLoader: showLoader.loading,
    ciCompany,
  };
};
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ createCICategory }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateCiCategoryHeader);
