
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Button,
  ListGroup,
  OverlayTrigger,
  Popover,
  Overlay,
} from "react-bootstrap";
import { getRelationshipData } from "../../../actions/cmdb/cmdbTimelineAction";

import { SvgIcon } from "./icons";
import icons from "./icons/icons.json";

const textLayout = {
  vertical: {
    root: {
      title: {
        textAnchor: "start",
        x: -50,
        y: -100,
      },
    },
    title: {
      textAnchor: "start",
      x: 30,
      y: -60,
    },
    attributes: {},
    attribute: {
      x: 40,
      dy: "1.2em",
    },
  },
  horizontal: {
    root: {
      title: {
        textAnchor: "start",
        x: -150,
        y: -25,
      },
    },
    title: {
      textAnchor: "start",
      y: 30,
      x: -40,
    },
    attributes: {
      x: 0,
      y: 40,
    },
    attribute: {
      x: 0,
      dy: "1.2em",
    },
  },
};

const PureSvgNodeElement = ({
  nodeDatum,
  isRootNode,
  orientation,
  toggleNode,
  onNodeClick,
  addChildren,
  setPopoverData,
  setViewFormId,
  viewFormId,
  handleNodeClick,
  handlePopoverClick,
  //  popover,
  overlayRef,
  setShowrel,
  setModule,
  setViewFormRelId,
}) => {
  const dispatch = useDispatch(useDispatch);

  const popoverRef = useRef(null);
  const [showPopover, setShowPopover] = useState(false);
  const targetRef = useRef(null);

  const popover = (
    <Popover.Body className="p-1">
      <ListGroup variant="flush">
        <ListGroup.Item>
          <Link
            to={`/editcmdb/${viewFormId}`}
            target="_blank"
            className="related"
          >
            Show CI Form
          </Link>
        </ListGroup.Item>
        <ListGroup.Item>
          <a
            // to={`/visualCI/${viewFormId}`}
            onClick={() => {
              setShowrel(true);
              setViewFormRelId(viewFormId);
              dispatch(getRelationshipData(viewFormId, "Breakfix", "No"));
              setShowPopover(false);
              setModule("Breakfix");
            }}
            className="related"
          >
            Show Related Work Items
          </a>
        </ListGroup.Item>
      </ListGroup>
    </Popover.Body>
  );

  const titleStyle = isRootNode
    ? textLayout[orientation].root?.title
    : textLayout[orientation].title;
  return (
    <>
      <a className="tree-node">
        <circle
          r={24}
          onClick={() => {
            toggleNode();
            handleNodeClick(nodeDatum);
          }}
        />
        <foreignObject
          x="-16"
          y="-16"
          width="32"
          height="32"
          onClick={() => {
            toggleNode();
            handleNodeClick(nodeDatum);
          }}
        >
          <div className="text-center node-icon">
            <SvgIcon iconData={icons[nodeDatum?.icon]} />
          </div>
        </foreignObject>
      </a>
      <g className="rd3t-label">
        <foreignObject {...titleStyle} width="150" height="120">
          <div
            onMouseEnter={() => {
              setViewFormId(nodeDatum?.id);
              setShowPopover(true);
              setPopoverData(nodeDatum);
            }}
            onMouseLeave={() => setShowPopover(false)}
            className="rd3t-label__title text-truncate"
          >
            <OverlayTrigger
              trigger={["focus"]}
              placement="right"
              overlay={
                <Popover
                  ref={overlayRef}
                  onMouseEnter={() => {
                    setShowPopover(true);
                  }}
                  onMouseLeave={() => setShowPopover(false)}
                >
                  <Popover.Header as="h3"> {nodeDatum.name}</Popover.Header>
                  {popover}
                </Popover>
              }
              show={showPopover}
              onToggle={(nextShow) => setShowPopover(nextShow)}
              rootClose={true}
            >
              <div style={{}}>
                <div ref={popoverRef} className="wrap-node-text text-truncate">
                  {nodeDatum.name}
                </div>
                {nodeDatum.attributes &&
                  Object.entries(nodeDatum.attributes).map(
                    ([labelKey, labelValue], i) => (
                      <div
                        className="rd3t-label__attributes text-truncate"
                        {...textLayout[orientation].attribute}
                        title={`${labelKey}: ${labelValue}`}
                      >
                        <strong>{labelKey}:</strong> {labelValue}
                      </div>
                    )
                  )}
              </div>
            </OverlayTrigger>

            {/* <div ref={popoverRef} className="wrap-node-text text-truncate">
              {nodeDatum.name}
            </div>

            {nodeDatum.attributes &&
              Object.entries(nodeDatum.attributes).map(
                ([labelKey, labelValue], i) => (
                  <div
                    className="rd3t-label__attributes text-truncate"
                    {...textLayout[orientation].attribute}
                    title={`${labelKey}: ${labelValue}`}
                  >
                    {labelKey}: {labelValue}
                  </div>
                )
              )}*/}
          </div>
        </foreignObject>
      </g>

      {/* TODO: ICONS */}
      {/* <foreignObject x="50" y="20" width="180" height="100">
        <div xmlns="http://www.w3.org/1999/xhtml" className="d-flex frobj">
          <span className="px-2">
            <i className="fa fa-calendar-o" aria-hidden="true" />
          </span>
          <span className="px-2">
            <i className="fa fa-history" aria-hidden="true" />
          </span>
          <span className="px-2">
            <i className="fa fa-exclamation-triangle" aria-hidden="true" />
          </span>
        </div>
      </foreignObject> */}
    </>
  );
};

export default PureSvgNodeElement;
