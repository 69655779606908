
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import { Link } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { Field, reduxForm, formValueSelector, change, SubmissionError } from 'redux-form';
import { emptyAllFields } from '_Actions/foundation/tags/tagsAction';
import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";

const Header = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const tr = useSelector((state) => state.spcmReducer.tr);
	const foundation_tags_reducer = useSelector((state) => state.foundation_tags_reducer);
	const [saveLoader, setSaveLoader] = useState(false);

	function buildTreeWithRelationships(data) {
		for (const item of data) {
			item.children = data.filter((child) => child.parentTagKey == item.tagId);
		}
		return data.filter((res) => res.parentTagKey == 0);
	}

	const getChildren = (parent, allData = []) => {
		if (parent.children.length > 0) {
			for (const child of parent.children) {
				allData.push(child);
				getChildren(child, allData);
			}
		}
		return allData;
	};

	const sortDataWithParamKey = (data) => {
		let sortedData = [];
		const dataWithRelationships = buildTreeWithRelationships(data);
		const returnedData = getChildren(dataWithRelationships[0]);
		sortedData = [dataWithRelationships[0], ...returnedData];
		return sortedData;
	};

	function submit(values) {
		if (!values.company) {
			props.setCompanyErrorState('error');
			return false;
		}
		else {
			props.setCompanyErrorState('');
		}
		let flag = false;
		setSaveLoader(true);
		axios.post('/api/postCreateTagData', {
			"businessFunction": values.businessFunctionName,
			"companyId": values.company,
			// "compositeTagKey": 0,
			// "language": "1",
			"parentTagKey": props.checklistTagData.tagId,
			"sequence": values.sequence,
			"tagCharLimit": values.character_limit,
			"tagEnabled": values.tagEnabled,
			"tagLabel": values.label.trim(),
			"tagModule": values.module,
			"tagType": values.tagType,
			"tagMandatory": values.tagMandatory,
			// "tagValueDataSource": values.tagDataSource,
			"tagValueDataType": values.tagDataValidation,
			"companyName": values.companyName,
			"businessFunctionId": values.businessFunctionId
		}).then(function (response) {
			if (response.status == 201) {
				setSaveLoader(false);
				dispatch(emptyAllFields());
				navigate("/editCustomAttribute/" + response.data.tagId.toString());
			}
		}).catch(function (error) {
			setSaveLoader(false);
			if (error.response.status == 409 && error.response.data == 'duplicate tag') {
				swal({
					text: tr["An attribute with the same label already exists. Please provide a different label."],
					button: tr['OK'],
				});
			}
		});
	}

	return (
		<Row className="margin-b-15">
			<Col sm={8} xs={7}>
				<h1 bsClass="" className="sPageHeading1">{tr["Member Attribute"]}</h1>
			</Col>
			<Col sm={4} xs={5}>
				<div className="paHedFilter">
					<Button bsPrefix=' ' id="creTag" role="button" className="myBt plus" disabled={saveLoader} onClick={props.handleSubmit(submit)} title={tr['Save']}>{saveLoader ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/>}</Button>
					<Button bsPrefix=' ' role="button" className="myBt cancel fillBtn" title={tr['Cancel']} onClick={() => { dispatch(emptyAllFields()); navigate(-1); }} ><IoClose /></Button>
				</div>
			</Col>
		</Row>
	)
}

export default reduxForm({
	form: 'foundationTagsCreateChecklistMemberForm',
	destroyOnUnmount: true,
	enableReinitialize: true
})(Header);