
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import PropTypes from "prop-types";
import {
  _inputField,
  _textArea,
  _dropDown,
  _dateField,
} from "../../../serviceDesk/common/formFields";
import { Row, Col, Form, Alert } from "react-bootstrap";
import { loadSlaWrkDayScheduleStatus } from "../../../../actions/sla/lovAction";
import { loadSlaHolidayEntries } from "../../../../actions/sla/quickViewAction";
import { connect } from "react-redux";
import { loadCICompany } from "../../../../actions/cmdb/lovAction";
import DropdownPlusTypeahead from "../../common/dropdownPlusTypeahead";
import { Field, reduxForm, change } from "redux-form";
import { GLOBAL } from "_Globals";
import _ from "lodash";
import LoadingIcons from "react-loading-icons";
import { loadHolidayScheduleData } from "../../../../actions/sla/lovAction";
import configureStore from "../../../../store/configureStore";
const store = configureStore();
let SelelectedCompany = [];
const validate = (values) => {
  console.log(values);
  let errors = {};
  if (!values.scheduleName || values.scheduleName.trim() == "") {
    errors.scheduleName = "enter schedule name";
  }
  if (values.scheduleStatus == "0") {
    errors.scheduleStatus = "enter schedule status";
  }
  if (!values.scheduleCompany) {
    errors.scheduleCompany = "enter company";
  }

  {
    /*if(!values.holidayName) {
		errors.holidayName = 'enter holiday schedule name';
	}

	if(!values.holidayDate) {
		errors.holidayDate = 'enter holiday schedule date.';
	}*/
  }
  return errors;
};

class holidayScheduleEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      CompanyName: [],
      CompanyError: "",
      companyCleared: false,
      inputValueCompany: "",
      typedValue: "",
    };
    this.companySelection = this.companySelection.bind(this);
    this.CompanyError = this.CompanyError.bind(this);
    this.statusChange = this.statusChange.bind(this);
    this.onCompanyInputChange = this.onCompanyInputChange.bind(this);
    this.onCrossClickCompany = this.onCrossClickCompany.bind(this);
  }
  componentWillMount() {
    console.log("propss++", this.props);
    store.dispatch({
      type: "HOLIDAY_SCHEDULE_DATA_LOAD_SUCCESS",
      holidayScheduleDetails: {},
    });
    //this.props.loadHolidayScheduleData(this.props.scheduleId);
    this.props.loadSlaHolidayEntries(this.props.scheduleId);
    this.props.loadCICompany();
    this.props.loadSlaWrkDayScheduleStatus("status");

    this.setState({ inputValueCompany: "2" });
    GLOBAL.holidayCompanyEditError = "";
  }

  companySelection(companySelelected) {
    console.log("SelelectedCompany.length", companySelelected.length);

    if (companySelelected.length > 0) {
      SelelectedCompany = companySelelected;
      this.props.dispatch(
        change(
          "holidayScheduleEditForm",
          "scheduleCompanyName",
          companySelelected[0].label
        )
      );
      this.props.dispatch(
        change(
          "holidayScheduleEditForm",
          "scheduleCompany",
          companySelelected[0].id
        )
      );
      GLOBAL.holidayCompanyEditError = "";
      this.setState({ inputValueCompany: companySelelected[0].label });
      this.setState({ typedValue: companySelelected[0].label });
    } else {
      SelelectedCompany = [];
      this.setState({
        companyCleared: true,
      });
      this.props.dispatch(
        change("holidayScheduleEditForm", "scheduleCompanyName", "")
      );
      this.props.dispatch(
        change("holidayScheduleEditForm", "scheduleCompany", 0)
      );
      GLOBAL.holidayCompanyEditError = "error";
    }
  }
  onCompanyInputChange(val) {
    this.setState({ inputValueCompany: val });
    this.setState({ typedValue: val });
  }
  onCrossClickCompany() {
    SelelectedCompany = [];
    this.setState({
      companyCleared: true,
    });
    this.props.dispatch(
      change("holidayScheduleEditForm", "scheduleCompanyName", "")
    );
    this.props.dispatch(
      change("holidayScheduleEditForm", "scheduleCompany", 0)
    );
    GLOBAL.holidayCompanyEditError = "error";
    this.setState({ inputValueCompany: "" });
  }
  CompanyError() {
    console.log("SelelectedCompany.length", SelelectedCompany.length);
    if (SelelectedCompany.length == 0) {
      this.setState({ CompanyError: "error" });
    } else {
      this.setState({ CompanyError: "" });
    }
    GLOBAL.holidayCompanyEditError = "";
  }
  statusChange(e) {
    this.props.dispatch(
      change(
        "holidayScheduleEditForm",
        "scheduleStatusName",
        e.target[e.target.selectedIndex].text
      )
    );
  }
  render() {
    let { error, handleSubmit, pristine, reset, submitting } = this.props;

    if (
      _.isEmpty(this.props.slaHolidayScheduleDetails) ||
      this.props.isFetching
    ) {
      return (
        <div className="rctLoader">
          <LoadingIcons.Bars height="5em" stroke="transparent" fill="#06bcee" />
        </div>
      );
    }

    let CompanySelect = [];
    if (
      this.props.slaHolidayScheduleDetails.slaHolidayScheduleDetails
        .scheduleCompany != undefined &&
      this.props.slaHolidayScheduleDetails.slaHolidayScheduleDetails
        .scheduleCompanyName != undefined &&
      this.state.companyCleared == false
    ) {
      let obj = {};
      obj.id =
        this.props.slaHolidayScheduleDetails.slaHolidayScheduleDetails.scheduleCompany;
      obj.label =
        this.props.slaHolidayScheduleDetails.slaHolidayScheduleDetails.scheduleCompanyName;
      CompanySelect.push(obj);
      SelelectedCompany = CompanySelect;
    }
    let companyData = [];
    this.props.companies.forEach((item, i) => {
      let obj = {};
      obj.id = item.field1Value;
      obj.label = item.field1Key;
      companyData.push(obj);
    });
    let errormsg = this.props.translator[error];
    console.log("errormsg", SelelectedCompany);
    return (
      <form>
        <div>
          {error == undefined || error == null ? null : (
            <Alert variant="danger" className="errorMessageSec">
              <p>{errormsg}</p>
            </Alert>
          )}

          <div className="borderForm bFormDiv">
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label bsClass="">
                    <span className="rStar" />
                    {this.props.translator["Name"]}
                  </Form.Label>
                  <Field
                    name="scheduleName"
                    type="input"
                    component={_inputField}
                    label=""
                    className="form-control"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label bsClass="">
                    <span className="rStar" />
                    {this.props.translator["Status"]}
                  </Form.Label>
                  <Field
                    name="scheduleStatus"
                    component={_dropDown}
                    options={this.props.status}
                    className="form-control"
                    onChange={this.statusChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group
                  validationState={
                    this.state.CompanyError == ""
                      ? GLOBAL.holidayCompanyEditError
                      : this.state.CompanyError
                  }
                >
                  <Form.Label bsClass="">
                    <span className="rStar" />
                    {this.props.translator["Company"]}
                  </Form.Label>
                  <Field
                    component={DropdownPlusTypeahead}
                    onSelection={this.companySelection}
                    name="scheduleCompany"
                    id="company"
                    className="form-control"
                    options={companyData}
                    setErrorColor={this.CompanyError}
                    selectedOptions={SelelectedCompany}
                    onInputChange={this.onCompanyInputChange}
                    inputValue={this.state.inputValueCompany}
                    onCrossClick={this.onCrossClickCompany}
                    typedValue={this.state.typedValue}
                  />
                </Form.Group>
              </Col>
            </Row>
            {/*<Row>
							<Col md={12}>
								<div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
									<Row>
										<Col sm={6} md={6} xs={12}>
											<Form.Group>
												<Form.Label bsClass="">{this.props.translator['Holiday Name']}</Form.Label>
												<div className="dateTimeSdiv">
													<Field name="holidayName" type="input" component={_inputField} label="" className="form-control" />
												</div>
											</Form.Group>
										</Col>
										<Col sm={6} md={6} xs={12}>
											<Form.Group>
												<Form.Label bsClass="">{this.props.translator['Date (dd/mm/yyyy)']}<span className="rStar" />
												</Form.Label>
												<div className="dateTimeSdiv">
													<Field name="holidayDate" component={_dateField} className="form-control" formType="datePicker-edit" defaultValue ={this.props.initialValues.holidayDate}/>
												</div>
											</Form.Group>
										</Col>
									</Row>
								</div>
							</Col>
						</Row>*/}
          </div>
        </div>
      </form>
    );
  }
};

holidayScheduleEditForm.propTypes = {
  loadCICompany: PropTypes.func,
  loadSlaWrkDayScheduleStatus: PropTypes.func,
};

const mapStateToProps = ({
  slaCompany,
  slaWorkdaySchedulesStatus,
  slaHolidayScheduleDetails,
  ciCompany,
  slaHolidayEntriesList,
}) => {
  console.log("state from reducer...");
  console.log(slaHolidayScheduleDetails);
  //let companies = slaCompany == null || slaCompany == undefined || slaCompany.length == 0 || _.isEmpty(slaCompany) ? [] : slaCompany;
  let companies =
    ciCompany == null ||
    ciCompany == undefined ||
    ciCompany.length == 0 ||
    _.isEmpty(ciCompany)
      ? []
      : ciCompany;
  let status =
    slaWorkdaySchedulesStatus == null ||
    slaWorkdaySchedulesStatus == undefined ||
    slaWorkdaySchedulesStatus.length == 0 ||
    _.isEmpty(slaWorkdaySchedulesStatus)
      ? []
      : slaWorkdaySchedulesStatus;
  return {
    companies,
    status,
    initialValues: slaHolidayScheduleDetails.slaHolidayScheduleDetails,
    slaHolidayScheduleDetails,
    isFetching: slaHolidayScheduleDetails.isFetching,
    slaHolidayEntriesList,
  };
};

holidayScheduleEditForm = reduxForm({
  form: "holidayScheduleEditForm",
  validate,
  enableReinitialize: true,
})(holidayScheduleEditForm);

export default connect(mapStateToProps, {
  loadCICompany,
 //loadHolidayScheduleData,
  loadSlaWrkDayScheduleStatus,
  loadSlaHolidayEntries,
})(holidayScheduleEditForm);
