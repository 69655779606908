
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Row, Col, Form, Button, Table, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import {
  getRelatedOfferings,
  setForm,
  getServiceDetailCounts,
  getRelatedOfferingsNameList,
} from "../../../actions/spcmActions";
import ComponentForm from "./editForm.js";
import axios from "axios";
import Pagination from "react-js-pagination";
import ListLoader from "_Commons/loaders/ListLoader";
import Swal from "sweetalert2";
import {IoSearch} from 'react-icons/io5';

class ListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      queryBy: "Name",
      searchOffering: "",
      sortBy: "Name",
      pageSize: 10,
      deleteError: "",
      sortIcons: false,
      class1: "sort up",
      class2: "sort",
      sortByTitle: "",
      relatedOfferings: [],
    };
    this.sortFun = this.sortFun.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onPageSizeChange = this.onPageSizeChange.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.queryBySelect = this.queryBySelect.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onSort = this.onSort.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
    this.sortHover = this.sortHover.bind(this);
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      this.onSearchClick();
      if (e.preventDefault) e.preventDefault();
    }
  }

  sortFun() {
    this.setState({ sortIcons: !this.state.sortIcons });

    this.setState({ class1: this.state.sortIcons ? "sort up" : "sort down" });
    this.setState({
      class2: "sort",
      sortBy: "Name",
    });
    this.props.getRelatedOfferings(
      this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
      "Name",
      this.state.sortIcons ? "asc" : "desc"
    );
  }

  onTextChange(e) {
    this.setState({ searchOffering: e.target.value });
  }

  onPageSizeChange(e) {
    this.setState({ pageSize: e.target.value });
    this.props.getRelatedOfferings(
      this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID
    );
  }

  onPageChange(e) {
    this.props.getRelatedOfferings(
      this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID
    );
  }
  onSearchClick() {
    this.props.getRelatedOfferingsNameList(
      this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
      this.state.searchOffering
    );
  }

  handleDismiss() {
    this.setState({ deleteError: "" });
  }

  queryBySelect(e) {
    this.setState({ queryBy: e.target.value });
  }

  sortHover(e) {
    let index = e.nativeEvent.target.selectedIndex;
    this.setState({ sortByTitle: e.nativeEvent.target[index].text });
  }

  onDeleteClick(id) {
    var str = {};
    if (id != "") {
      str.id = id;
    }

    str = JSON.stringify(str);
    console.log("iddddss", id);
    Swal.fire({
      title: 'Are you sure you wish to delete this item?',
      
      width: 550,
      padding: '15px',
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        return axios
          .delete("/service/api/relatedOfferings/", { headers: { query: str } })
          .then((resp) => {
            this.props.getRelatedOfferings(
              this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID
            );
            console.log(
              "delete this.props.spcmReducer.currentlySelectedServiceDetails",
              this.props.spcmReducer.currentlySelectedServiceDetails
            );
            this.props.getServiceDetailCounts(
              this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID
            );
          })
          .catch((error) => {
            this.setState({ deleteError: error.response.data });
            // this.setState({deleteError:error.response.data[0].message})
          });
      } else if (result.isDenied) {

      }
    })
  }

  onSort(e) {
    this.setState({ sortBy: e.target.value });
    this.props.getRelatedOfferings(
      this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID
    );
  }

  componentWillMount() {
    this.props.setForm("relatedOferingsForm");
    this.props.getRelatedOfferings(
      this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
      "",
      "",
      "Name"
    );
  }
  render() {
    const tr = this.props.tr;
    var meta = this.props.spcmReducer.meta;
    console.log("meta");
    var relatedOfferings = this.props.spcmReducer.relatedOfferings;
    console.log("relatedOfferingsreduce", relatedOfferings);

    return (
      <div className="">
        {this.props.componentFormToggle3 ? (
          <ComponentForm
            tr={tr}
            relatedOfferings={relatedOfferings}
            componentFormFun4={this.props.componentFormFun3}
          />
        ) : (
          <div className="rBoxGap">
            {this.state.deleteError == "" ? null : (
              <Alert
                style={{
                  marginBottom: "15px",
                  fontSize: "13px",
                  padding: "8px 30px 8px 8px",
                }}
                variant="danger"
                onDismiss={this.handleDismiss}
              >
                <h4>{this.state.deleteError}</h4>
              </Alert>
            )}
            <Row className="">
              <Col lg={8} md={12} sm={8} xs={12}>
                <div className="form-inline rightSideFields d-lg-flex d-md-block d-sm-flex d-block">
                  <Form.Select
                    onMouseOver={this.sortHover}
                    title={this.state.sortByTitle}
                    className="myControl mySel mySelectWidth"
                    onChange={this.queryBySelect}
                  >
                    <option value="Name">{tr["Name"]}</option>
                    {/* <option value="Company">Company Name</option> */}
                  </Form.Select>
                  <div className='d-flex'>
                  <Form.Control
                    onKeyPress={this.handleKeyPress.bind(this)}
                    className="myControl myInput margin-l-5 myInputWidth"
                    type="text"
                    onChange={this.onTextChange}
                  />
                  <Button
                    type="submit"
                    title="Search"
                    onClick={this.onSearchClick}
                    className="myBtn"
                  >
                    <IoSearch/>
                  </Button>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={12} sm={4} xs={12} className="text-r rightSideFields">
                <Form.Label className="margin-r-5 display-inline-block widthAuto">
                  {tr["Show"]}
                </Form.Label>
                <Form.Select
                  className="display-inline-block widthAuto"
                  onChange={this.onPageSizeChange}
                >
                  <option
                    value="10"
                    selected={this.state.pageSize == 10 ? true : false}
                  >
                    10
                  </option>
                  <option
                    value="25"
                    selected={this.state.pageSize == 25 ? true : false}
                  >
                    25
                  </option>
                  <option
                    value="50"
                    selected={this.state.pageSize == 50 ? true : false}
                  >
                    50
                  </option>
                  <option
                    value="100"
                    selected={this.state.pageSize == 100 ? true : false}
                  >
                    100
                  </option>
                </Form.Select>
              </Col>
            </Row>

            {this.props.spcmReducer.spcmFetchingError == true &&
            this.props.spcmReducer.spcmFetchingModule == "RELATED_OFFERINGS" &&
            this.props.spcmReducer.spcmErrorMsg ? (
              <Alert variant="danger" className="errorMessageSec margin-t-20">
                <p>{this.props.spcmReducer.spcmErrorMsg}</p>
              </Alert>
            ) : this.props.spcmReducer.spcmFetchingError == true &&
              this.props.spcmReducer.spcmFetchingModule ==
                "RELATED_OFFERINGS" &&
              !this.props.spcmReducer.spcmErrorMsg ? (
              <p>Oops! Something went wrong.</p>
            ) : null}

            <div className="f-size-12 margin-xs-t-15">
              <Table
                bordered
                condensed
                hover
                responsive
                className="tableView sortImg tableWithOutPointer"
              >
                <thead>
                  <tr>
                    <th
                      width="30%"
                      className={this.state.class1}
                      onClick={() => {
                        this.sortFun();
                      }}
                    >
                      {tr["Name"]}
                    </th>
                    {/* <th width="60%" className={this.state.class2} onClick={()=>{this.sortFun('class2')}}>{tr['Company Name']}</th> */}
                    <th width="10%" className="text-c">
                      {tr["Action"]}
                    </th>
                  </tr>
                </thead>
                {this.props.spcmReducer.spcmFetchingAttribute ? (
                  <tbody>
                    <td colSpan="3">
                      <ListLoader />
                    </td>
                  </tbody>
                ) : relatedOfferings.length ==
                  0 ? // <tbody><td colSpan="3" className="text-c">
                // 	{tr['No Data']}
                // </td></tbody>
                null : (
                  <tbody>
                    {relatedOfferings.relatedOfferings.map(function (
                      value,
                      index
                    ) {
                      return (
                        <tr>
                          <td>{value.offeringName}</td>
                          <td>
                            <div className="text-c">
                              <i
                                title={tr["Delete"]}
                                className="margin-l-10 fa fa-trash-o cursorPoint"
                                onClick={() => {
                                  this.onDeleteClick(value.id);
                                }}
                              ></i>
                            </div>
                          </td>
                          {console.log("value.id", value.id)}
                        </tr>
                      );
                    },
                    this)}
                  </tbody>
                )}
              </Table>
            </div>
            <div className="text-c "></div>
            <div className="nBotPagina">
              <nav aria-label="Pagination" className="display-inline-block">
                {meta ? (
                  <Pagination
                    activePage={meta ? meta.currentPage : 1}
                    itemsCountPerPage={this.state.pageSize}
                    totalItemsCount={meta ? meta.rowCount : 1}
                    onChange={this.onPageChange}
                  />
                ) : null}
              </nav>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = ({ spcmReducer }) => {
  return {
    spcmReducer,
  };
};

export default connect(mapStateToProps, {
  getRelatedOfferings,
  setForm,
  getServiceDetailCounts,
  getRelatedOfferingsNameList,
})(ListView);
