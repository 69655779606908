
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import axios from "axios";
import { connect } from "react-redux";
import { formValueSelector, change } from 'redux-form';
const api = axios.create({
  headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});
 
 
let BudgetAsyncTypeahead = class BudgetAsyncTypeahead extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      isLoading: false,
      minLength: 3,
      delay: 200,
      useCache: false,
      inputValue: '',
      labelKey: 'label',
      defaultInputValue: ''
    };
    this._renderMenuItemChildren = this._renderMenuItemChildren.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }
 
  componentWillReceiveProps(nextProps) {
    if(nextProps.companyIdParam != this.props.companyIdParam) {
      this.props.dispatch(change('CreateContract', 'budget_Id', ''));
      this.props.dispatch(change('CreateContract', 'budget_sponsor', ''));
     
     
      this._typeahead.clear()
      this.setState({ inputValue: '', options: [] });
    }
 
  }
 
  onFocus() {
    if (this.state.inputValue.length >= 3) {
      // this.onSearch(this.state.inputValue);
    }
  }
 
  onSearch(searchKeywords) {
    console.log(searchKeywords,"searchKeywords")
    let finalSearchKeywords = searchKeywords.split('-').at(0);
    this.setState({ options: [] });
    let responseData = [];
    let finalData = [];
    let myObj = {};
    searchKeywords = searchKeywords.trim();
    if (this.props.companyId  && searchKeywords !='')  {
      this.setState({ isLoading: true });
      let strObj = {};
      strObj = JSON.stringify(strObj);
      let query=`${searchKeywords}&companyId=${parseInt(this.props.companyId, 10)}`
      api.get(`/api/user/userInfo/${query}`)
        .then((response) => {
          responseData = response.data;
          console.log("responseData",responseData);
          finalData = [];
          for (let i = 0; i < responseData.length; i++) {
            myObj = { id: '', value: '', label: ''};
            myObj.id = responseData[i].user_id;
            myObj.value = responseData[i].Full_Name + "|" + responseData[i].Email_Address + "|" + responseData[i].Company_Name;
            myObj.label = responseData[i].Full_Name;
            finalData.push(myObj);
          }
          finalData = Array.from(new Set(finalData));
          this.setState({
            options: finalData,
            isLoading: false
          });
        })
        .catch((err) => {
          this.setState({ isLoading: false });
        });
    }
  }
 
  _renderMenuItemChildren(option, props) {
    return [
      // <Highlighter key="label" search={props.text}>
      //   {option.label}
      // </Highlighter>,
      <div>
        <span className="typHdmainHead" title={option.label} style={{'display': 'block'}}>
          <Highlighter key="label" search={props.text}>
            {option.label}
          </Highlighter>
        </span>
        <span className="typHdmainSubHead" style={{'display': 'block', 'margin-top': '6px', 'color': '#777'}}>  
          <Highlighter key="value" search={props.text}>
            {option.value}
          </Highlighter>
        </span>
      </div>
    ];
  }
 
  onInputChange(value) {
    this.setState({ inputValue: value.trim() });
    this.props.dispatch(change('CreateContract', 'budget_Id', ''));
    this.props.dispatch(change('CreateContract', 'budget_sponsor', ''));
  }
 
  onValueSelection(selectedValue) {
    console.log("onValueSelection=======",selectedValue)
    if (selectedValue && selectedValue.length > 0) {
      this.props.onBudgetSelected(selectedValue)
    }
  }
 
  onTypeaheadCrossClick() {
    this._typeahead.clear()
    this.setState({ inputValue: '', options: [] });
    this.props.dispatch(change('CreateContract', 'budget_Id', ''));
    this.props.dispatch(change('CreateContract', 'budget_sponsor', ''));
  }
  render() {
    // {console.log(this.props, "props===")}
    const props = {};
    props.renderMenuItemChildren = this._renderMenuItemChildren;
    return (
      <div className={"dvTypehd " + this.props.errorColor}>
        {!this.state.isLoading ? this.state.inputValue.length > 0 ?
          <span
            title="clear"
            role="img"
            aria-label="Clear"
            className="css-hakgx8 icn"
            onClick={() => this.onTypeaheadCrossClick()}
          >
            <svg width="18" height="18" viewBox="0 0 24 24" role="presentation">
              <path
                d="M12 10.586L6.707 5.293a1 1 0 00-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 001.414 1.414L12 13.414l5.293 5.293a1 1 0 001.414-1.414L13.414 12l5.293-5.293a1 1 0 10-1.414-1.414L12 10.586z"
                fill="gray"
              ></path>
            </svg>
          </span>
          :
          <span
            title="Search"
            role="presentation"
            aria-hidden="true"
            className="css-hakgx8 icn"
          >
            <svg width="18" height="18" viewBox="0 0 24 24" role="presentation">
              <path
                d="M16.436 15.085l3.94 4.01a1 1 0 01-1.425 1.402l-3.938-4.006a7.5 7.5 0 111.423-1.406zM10.5 16a5.5 5.5 0 100-11 5.5 5.5 0 000 11z"
                fill="gray"
                fill-rule="evenodd"
              ></path>
            </svg>
          </span>
          : ''
        }
 
        <AsyncTypeahead
          id={this.props.id}
          {...this.state}
          {...props}
          ref={(ref) => (this._typeahead = ref)}
          onSearch={this.onSearch}
          filterBy={() => true}
          onChange={(e) => this.onValueSelection(e)}
          onInputChange={this.onInputChange}
          onBlur={this.validateField}
          placeholder={"Search by Name, Email, Employee Number"}
          className=""
          onFocus={this.onFocus}
        />
      </div>
    );
  }
};
 
let selector = formValueSelector('CreateContract');
BudgetAsyncTypeahead = connect((state) => {
  // console.log("state",state)
  let companyIdParam = selector(state, 'company');
  // let businessownerParam = selector(state, 'budget_Id');
  let bugetList=state.budgetList
  return {
    companyIdParam ,bugetList
  };
})(BudgetAsyncTypeahead);
 
export default BudgetAsyncTypeahead ;