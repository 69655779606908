
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState, useRef } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cookies from "universal-cookie";
import { Outlet,useLocation} from "react-router-dom";
import { GLOBAL } from "./Globals";
import Header from "./common/header/Header";
import Footer from "./common/footer/FooterCopyright";
import AddWindowSize from "./common/windowSize.js";
import Chatbot from './chatBot/ChatBotIndex';
import { Row, Col } from "react-bootstrap";
import MyContextProvider from "./myContext.js";
import ModulesHamburger_Web from "./common/header/ModulesHamburger_Web.js";
import { loadFunctionByPageAndRole } from "_Actions/common/functionActions.js";
import { loadProfile } from '_Actions/myProfile/profileAction';
import { loadLanguagePreferences } from '_Actions/myProfile/languagePreferencesAction';

const cookies = new Cookies();
let alfy_required = cookies.get("gph");
let unauth = cookies.get("gph");

if (alfy_required) alfy_required = alfy_required.replace("s:", "");
if (alfy_required)
  alfy_required = alfy_required.substring(0, alfy_required.lastIndexOf("."));
alfy_required = alfy_required.split("~");
alfy_required = alfy_required[1];
if (alfy_required == "yes") alfy_required = "YES";

let lucy_url = cookies.get("gph");

if (lucy_url) lucy_url = lucy_url.replace("s:", "");
if (lucy_url) lucy_url = lucy_url.substring(0, lucy_url.lastIndexOf("."));
lucy_url = lucy_url.split("~");
lucy_url = lucy_url[31];
if (
  lucy_url == null ||
  lucy_url == "null" ||
  lucy_url == undefined ||
  lucy_url == "undefined"
)
  lucy_url = "";

if (unauth) unauth = unauth.replace("s:", "");
if (unauth) unauth = unauth.substring(0, unauth.lastIndexOf("."));
unauth = unauth.split("~");
unauth = unauth[0];

let user_roleId = cookies.get("gph");
if (user_roleId) user_roleId = user_roleId.replace("s:", "");
if (user_roleId)
  user_roleId = user_roleId.substring(0, user_roleId.lastIndexOf("."));
user_roleId = user_roleId.split("~");
user_roleId = user_roleId[33];
let userRoleArr = new Set(user_roleId?.split(",") || []);

const allowedRoles = new Set([
  "1", "2", "5", "12", "13", "14", "16", "17", "19", "20", "32", "33", "34", "37", "38", "39", "40", "41", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "57", "60", "61", "62", "63", "64", "65", "66", "67", "68", "71", "89"
]);

let cookiesArray = cookies.get('gph');
if (cookiesArray) cookiesArray = cookiesArray.replace('s:', '');
if (cookiesArray) cookiesArray = cookiesArray.substring(0, cookiesArray.lastIndexOf('.'));
cookiesArray = cookiesArray.split("~");
let langDir = cookiesArray[66];

const hasAllowedRole = [...userRoleArr].some(role => allowedRoles.has(role));

const LocationModule = (props) => {
  const myLocation = useLocation().pathname.slice(1);
  const location = myLocation.replace("/", "-");

  useEffect(() => {
    props.setLocation(location);
  }, [location]);

  return (
    <></>
  )
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showChat: false,
      alfyfloatingIcon: true,
      minAlfyIcon: false,
      callApi: true,
      showHeader: true,
      showSidebar: false,
      myLocation:""
    };
    this.alfyFloatingIconChangeFun = this.alfyFloatingIconChangeFun.bind(this);
    this.toggleChat = this.toggleChat.bind(this);
    this.resetApiCall = this.resetApiCall.bind(this);
    this.setShowHeader = this.setShowHeader.bind(this);
    this.setShowSidebar = this.setShowSidebar.bind(this);
    this.setLocation = this.setLocation.bind(this);
  }
  componentWillMount() {
    // let pageId = this.props.pageId;
    // console.log("function::::");
    // console.log(userDetails.functionlist);

    this.props.loadFunctionByPageAndRole("24");
  }

  componentDidUpdate() {
    const { languageDetails, profileDetails } = this.props;
    if (languageDetails && Array.isArray(languageDetails) && profileDetails?.languagePreference) {
      const languageTitle = languageDetails.find(
        (lang) => lang.field1Value === profileDetails.languagePreference
      )?.field1Key;
  
      if (languageTitle) {
        document.documentElement.setAttribute("lang", languageTitle);
        document.documentElement.setAttribute("dir", langDir);
      }
    }
  }
  

  componentDidMount() {
    this.props.loadProfile();
    this.props.loadLanguagePreferences("language");
  }

  setLocation(val) {
    this.setState({myLocation:val});
  }
  setShowSidebar(value) {
    this.setState({ showSidebar: value });
  }

  setShowHeader(value) {
    this.setState({ showHeader: value });
  }

  alfyfloatingIcon() {
    this.setState({ showChat: true });
    this.setState({ alfyfloatingIcon: false });
  }
  alfyFloatingIconChangeFun(value, minAlfyIcon) {
    this.setState({ showChat: value });
    this.setState({ alfyfloatingIcon: true });
    this.setState({ minAlfyIcon });
  }
  resetApiCall() {
    this.setState({ callApi: false });
  }

  toggleChat(flag) {
    this.setState({ showChat: flag });
    this.setState({ alfyfloatingIcon: true });
    this.setState({ minAlfyIcon: false });
  }

  render() {
    const tr = this.props.tr;

    let hiddenAppModuleArray = [];
    if (!(this.props.rightSidebarMenulist?.length == 0 || this.props.rightSidebarMenulist == [])) {
      hiddenAppModuleArray = this.props.rightSidebarMenulist?.filter(function (value) {
        return (
          value.functionId != "25" &&
          value.functionId != "26" &&
          value.functionId != "33" &&
          value.functionId != "53"
        );
      });
    }

    const shouldShowModulesHamburger = this.props.windowSize.width > 1023 && hasAllowedRole && hiddenAppModuleArray != [];

    return (
      <>
      <LocationModule setLocation={this.setLocation} />
        {unauth === "/unauthorized" ||
          unauth === "/nodomain" ||
          unauth === "/authenticationError" ||
          unauth === "/login" ||
          unauth === "/userActivationPending" ||
          window.location.pathname === "/sessionTimeout" ? null : (
          <Header setShowSidebar={this.setShowSidebar} />
        )}
        {/* <ChatPopup toggleChat={this.toggleChat} showChat={this.state.showChat}
        alfyFloatingIconChange = {this.alfyFloatingIconChangeFun}
        callApi={this.state.callApi}
        resetApiCall={this.resetApiCall}
        tr={tr}
        /> */}

        {/* {alfy_required=="yes" || alfy_required=="YES" ? this.state.alfyfloatingIcon ?
       (this.state.minAlfyIcon ?
       <div className="minAlfyfloatingIcon">
       <Button bsPrefix=' '  onClick={() => {this.alfyFloatingIconChangeFun(false,false)}}  className="float-r cursorPoint"><i className="fa fa-close"></i></Button>
       <Button bsPrefix=' '  onClick={this.alfyfloatingIcon.bind(this)} className="display-inline-block cursorPoint">
       {tr['Smart Assistant']}<i className="fa fa-comments margin-l-10"></i>
       </Button></div> :
       <div className="alfyfloatingIcon"><Button bsPrefix=' '  title="Your Virtual Assistant" onClick={this.alfyfloatingIcon.bind(this)} className="alfyBox" ></Button><Button bsPrefix=' '  onClick={() => {this.alfyFloatingIconChangeFun(false,true)}} title="Minimize" className="alfyMin"><i className="fa fa-minus-circle"></i></Button></div>)
       : null:null
     } */}

        <AddWindowSize />

        {alfy_required == "YES"
          && lucy_url != ""
          && window.location.pathname !== "/sessionTimeout"
          &&
          (GLOBAL.isLogout == "false" || GLOBAL.isLogout == false) ? (
          <Chatbot />
        ) : null}

        {unauth === "/unauthorized" ||
          unauth === "/nodomain" ||
          unauth === "/authenticationError" ||
          unauth === "/login" ||
          unauth === "/userActivationPending" ||
          window.location.pathname === "/sessionTimeout" ? (
          <MyContextProvider>
            <div className="skipdv">
              <Outlet context={this.setShowHeader} />
            </div>
          </MyContextProvider>
        ) : (
          <div className="menConFlx">
            {shouldShowModulesHamburger && (
              <ModulesHamburger_Web
                rightSidebarMenulist={this.props.rightSidebarMenulist}
              />
            )}
            <MyContextProvider>
              <div id="skipdv" className={this.state.myLocation}>
                <Outlet context={this.setShowHeader} />
              </div>
            </MyContextProvider>
          </div>
        )}

        {unauth === "/unauthorized" ||
          unauth === "/nodomain" ||
          unauth === "/authenticationError" ||
          unauth === "/login" ||
          unauth === "/userActivationPending" ||
          window.location.pathname === "/sessionTimeout" ? null : (
          <Footer />
        )}


      </>
    );
  }
}

App.propTypes = {
  // children: React.PropTypes.object.isRequired
};
const mapStateToProps = ({ spcmReducer, windowSize, profileDetails, languageDetails }) => {
  return {
    rightSidebarMenulist: spcmReducer.rightSidebarMenulist,
    spcmReducer,
    tr: spcmReducer.tr,
    windowSize,
    profileDetails,
    languageDetails
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loadFunctionByPageAndRole, loadProfile, loadLanguagePreferences }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
