
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import { ImSpinner6 } from "react-icons/im";
import { IoSaveOutline, IoClose } from "react-icons/io5";
import { LuActivitySquare } from "react-icons/lu";
import { MyContext } from '_MyContext';

const Header = (props) => {
  const dispatch = useDispatch();
  const params = useParams();

  const { formikref, isSubmitting, dirty, errors, submitForm, submitError } =
    props;

  const navigate = useNavigate();
  const tr = useSelector((state) => state.spcmReducer.tr);

  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const saveObjRef = useRef(null);

	useEffect(() => {
		context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
	}, []);

  const forumId = params?.forumId;
  const isAddMode = !forumId;

  const handleSubmit = () => {
    formikref.current?.submitForm();
  };

  return (
    <Row className="margin-b-15">
      <Col sm={8} xs={7}>
        <h1 bsClass="" className="sPageHeading1">
          {tr["Forum"]}
        </h1>
      </Col>
      <Col sm={4} xs={5}>
        <div className="paHedFilter">
        {(!isAddMode && !props.onClose) && (
            <Button
              bsPrefix=" "
              id="creTag"
              role="button"
              className="plus myBt fillBtn d-md-none"
              title={"Activity Details"}
              onClick={() => props?.setOnClose(true)}
            >
              <LuActivitySquare />
            </Button>
          )}
          <Link
            // disabled={isAddMode ? isSubmitting || !dirty : false}
            className="myBt fillBtn"
            bsPrefix=" "
            to="javascript:void(0)"
            bsStyle="primary"
            title={tr["Save"]}
            onClick={() => handleSubmit()}
            ref={saveObjRef}
          >
            {isSubmitting ? (
              <ImSpinner6 className="icn-spinner" />
            ) : (
              <IoSaveOutline />
            )}
          </Link>
          <Link
            bsPrefix=" "
            to="/forum"
            className="myBt cancel fillBtn"
            title={tr["Cancel"]}
            ref={closeButton}
          >
            <IoClose />
          </Link>
        </div>
      </Col>
      <Col xs={12}>
        <p className="red py-2">
          {submitError && <strong>{submitError}</strong>}
        </p>
      </Col>
    </Row>
  );
};

export default Header;
