
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Form from 'react-bootstrap/Form';

import { connect } from 'react-redux';
import axios from 'axios';
import ListLoader from '_Commons/loaders/ListLoader';
import {ImSpinner6} from "react-icons/im";

class Whys5 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            noofWhys: this.props.noofWhys,
            whysdata: [],
            whysLabel: this.props.whysLabel,
            sendPost: true,
            maxLength:500,
            disableButton: false,
            disableDeleteButton: false,
            enableLoader: false,
            createdID: '0',
            showDeleteButton: false,
            initialActivityDescription: ''
        };
        if(this.props.makeDisable===false){
            this.state.disableSubmit = false;
        }
        this.onwhyChange = this.onwhyChange.bind(this);
        this.validateField = this.validateField.bind(this);
        this.saveData = this.saveData.bind(this);
        this.deleteData = this.deleteData.bind(this);
        this.submitData = this.submitData.bind(this);
        this.setInitialForm = this.setInitialForm.bind(this);
        this.submitActivityDetails = this.submitActivityDetails.bind(this);
    }
    submitActivityDetails(descriptionValue,moduleName){
        let activityDetails = {
            "itemId":this.props.investigationItemDetails.investigationId,
            "description":'RCA~5Whys~'+descriptionValue,
            "isInternal":"",
            "createdOn":"",
            "createdBy":"",
            "module":moduleName,
            "createdByName":""
           };
        axios.post("/serviceManagement/rest/activity/", activityDetails);
    }
    deleteData(){
        this.setState({disableDeleteButton: true});
        axios.delete("/serviceManagement/rest/rcaDelete/"+this.props.investigationItemDetails.investigationId+"/"+this.props.techniqueID).then((deleteResponse)=>{
            this.props.hideForm();
            let descriptionforDelete='5Whys is deleted';
            this.submitActivityDetails(descriptionforDelete,'Investigation');
        }).catch((error)=>{
            this.setState({disableDeleteButton: false});
            console.log("delete api : ",error);
        });
    }
    setInitialForm(){
        let initialForm =[];
        for(let i=0;i<this.state.noofWhys; i++){
            initialForm.push({value: '', length: 0, error: ''});
        }
        return initialForm;
    }
    submitData(toSendpayload){
        let descritpionTosend='First : '+toSendpayload.keyValueData.First+' ';
        if(toSendpayload.keyValueData.Second.trim().length!==0){
            descritpionTosend+=', Second : '+toSendpayload.keyValueData.Second;
        }
        if(toSendpayload.keyValueData.Third.trim().length!==0){
            descritpionTosend+=', Third : '+toSendpayload.keyValueData.Third;
        }
        if(toSendpayload.keyValueData.Fourth.trim().length!==0){
            descritpionTosend+=', Fourth : '+toSendpayload.keyValueData.Fourth;
        }
        if(toSendpayload.keyValueData.Fifth.trim().length!==0){
            descritpionTosend+=', Fifth : '+toSendpayload.keyValueData.Fifth;
        }
        if(this.state.sendPost){
            axios.post('/serviceManagement/rest/rcaTechnique',toSendpayload).then((postResponse) => {
               this.props.hideForm();
               if(this.state.initialActivityDescription!==descritpionTosend){
               this.submitActivityDetails(descritpionTosend,'Investigation');
               }
            }).catch((error) => {
               this.setState({disableButton: false});
               console.log("post api : ",error);
            });
          }else{
            axios.put('/serviceManagement/rest/rcaTechnique/'+this.state.createdID,toSendpayload).then((patchResponse) => {
               this.props.hideForm();
               if(this.state.initialActivityDescription!==descritpionTosend){
                this.submitActivityDetails(descritpionTosend,'Investigation');
               }
            }).catch((error) => {
               this.setState({disableButton: false});
               console.log("put api : ",error);
            });
          }
    }
    componentWillMount(){
        this.setState({enableLoader: true});
        axios.get("/serviceManagment/rest/rcaTechnique/"+this.props.investigationItemDetails.investigationId+"/"+this.props.techniqueID).then((responseFindings)=>{
             let whyDatafilled =[];
             if(responseFindings.data.data===null){
                whyDatafilled=this.setInitialForm();
             }else{
                let initialActivityDescriptionRecived='First : '+responseFindings.data.data.keyValueData.First+' ';
                if(responseFindings.data.data.keyValueData.Second.trim().length!==0){
                    initialActivityDescriptionRecived+=', Second : '+responseFindings.data.data.keyValueData.Second;
                }
                if(responseFindings.data.data.keyValueData.Third.trim().length!==0){
                    initialActivityDescriptionRecived+=', Third : '+responseFindings.data.data.keyValueData.Third;
                }
                if(responseFindings.data.data.keyValueData.Fourth.trim().length!==0){
                   initialActivityDescriptionRecived+=', Fourth : '+responseFindings.data.data.keyValueData.Fourth;
                }
                if(responseFindings.data.data.keyValueData.Fifth.trim().length!==0){
                   initialActivityDescriptionRecived+=', Fifth : '+responseFindings.data.data.keyValueData.Fifth;
                }
                this.props.whysLabel.map((extractedData)=>{
                    whyDatafilled.push({value: responseFindings.data.data.keyValueData[extractedData],
                                        length: responseFindings.data.data.keyValueData[extractedData].length,
                                        error: ''});
                });
                this.setState({createdID: responseFindings.data.data.id, sendPost: false, showDeleteButton: true,initialActivityDescription: initialActivityDescriptionRecived});
             }
             this.setState({whysdata:whyDatafilled, enableLoader: false});
         }).catch((errorFindings)=>{
            console.log("response finding error : ",errorFindings);
            let whyDatafilled =[];
            whyDatafilled=this.setInitialForm();
            this.setState({enableLoader: false,whysdata:whyDatafilled});
         });
    }
    onwhyChange(event,whySequenceSelected){
        if(event.target.value.includes('~')){
            return;
        }
        let newDataArray=this.state.whysdata;
        newDataArray[whySequenceSelected].value = event.target.value;
        newDataArray[whySequenceSelected].length = event.target.value.length;
        newDataArray[whySequenceSelected].error = '';
        this.setState({whysdata: newDataArray});
    }
    validateField(whySequenceSelected){
       let newValidatedDataArray = this.state.whysdata;
       if(whySequenceSelected>0){
        for(let i=0;i<whySequenceSelected;i++){
            newValidatedDataArray[i].error = newValidatedDataArray[i].value.trim().length===0 ? 'error' : '';
        }
       }else {
            newValidatedDataArray[whySequenceSelected].error = newValidatedDataArray[whySequenceSelected].value.trim().length===0 ? 'error' : '';
       }
       this.setState({whysdata: newValidatedDataArray});
    }
    saveData(){
        let count=0;
        let stateData=this.state.whysdata;
        for(let i=1;i<stateData.length;i++){
            for(let j=0;j<=i;j++){
                if((stateData[j].error!==''||stateData[j].value.trim().length===0) && stateData[i].value.trim().length!==0){
                    count++;
                    stateData[j].length=0;
                    stateData[j].error='error';
                }
            }
        }
        let emptyCount=0;
        if(count===0){
         for(let i=0;i<this.state.whysdata.length;i++){
             if(this.state.whysdata[i].value.trim().length===0){
                  emptyCount++;
             }
         }
        }
        if(emptyCount===this.state.whysdata.length){
            count++;
            stateData[0].error='error';
        }
        this.setState({whysdata: stateData});

        if(count===0){
        let generatedKeyValuepair={};
        this.state.whysdata.forEach((savedItem,itr)=>{
          generatedKeyValuepair[""+this.state.whysLabel[itr]+""]=savedItem.value
        });
        let payload = {
          'investigationId': this.props.investigationItemDetails.investigationId,
          'displayId': this.props.techniqueID,
          'displayKey': '5Whys',
          'keyValueData':generatedKeyValuepair,
          'createdOn': '',
          'createdBy': ''
        };
        this.setState({disableButton: true});
        this.submitData(payload);
        }
    }
    render(){
        if(this.state.enableLoader){
           return <ListLoader />;
        }
        return (<div id="Whys5">
            {
                this.state.whysdata.map((item,itr) => {
                     return (<Form.Group validationState={item.error}>
                                 <Form.Label bsClass="">{this.props.translator[''+this.state.whysLabel[itr]+'']+" "+this.props.translator["Why"]}</Form.Label>
                                   <Form.Control as="textarea" className={`form-control ${item.error}`} maxLength={this.state.maxLength} rows="2" disabled={this.props.makeDisable||this.props.disableFieldsForGuestRole||this.props.isServiceDeskRolesEnabled}  onChange={(e)=> this.onwhyChange(e,itr)} onBlur={(e) => this.validateField(itr)} value={item.value}/>
                                   <p className="charLeft" style={{textAlign:"right","fontSize":"11px"}}>({this.props.translator['Characters Remaining']}: {this.state.maxLength-item.length}/500)</p>
                             </Form.Group>);
                })
            }
            {
                this.props.makeDisable ? '' : <ButtonToolbar><Button className="rgSidrkBtn smallBtn" bsSize="small" bsStyle="primary" title={this.props.translator['Save']} onClick={this.saveData} disabled={this.state.disableButton||this.state.disableDeleteButton||this.props.disableFieldsForGuestRole||this.props.isServiceDeskRolesEnabled}>{this.state.disableButton?<ImSpinner6 className="icn-spinner"/> : null}{this.props.translator["Save"]}</Button> <Button className="rgSidrkBtn smallBtn" bsSize="small" bsStyle="primary" title={this.props.translator['Close']} onClick={this.props.hideForm} disabled={this.state.disableButton||this.state.disableDeleteButton}>{this.props.translator['Close']}</Button>
                {this.state.showDeleteButton?<Button className="rgSidrkBtn smallBtn" bsSize="small" bsStyle="primary" title={this.props.translator["Delete"]} onClick={this.deleteData} disabled={this.state.disableDeleteButton||this.state.disableButton||this.props.disableFieldsForGuestRole||this.props.isServiceDeskRolesEnabled}>{this.state.disableDeleteButton?<ImSpinner6 className="icn-spinner"/> : null}{this.props.translator["Delete"]}</Button>:null}</ButtonToolbar>
            }
        </div>);
    }
}

const mapStateToProps = ({ investigationItemDetails }) => {
	return {
        investigationItemDetails,
	};
};
export default connect(mapStateToProps, { })(Whys5);
