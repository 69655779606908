
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import Breadcrumbs from "../../common/header/breadcrumbs.js";
import React, { useState, useEffect,useSelector} from "react";
import HamHeader from './HamHeader.js';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import HamList from './HamList.js';
import HamRightPart from "./HamRightPart.js";
import { Container } from "react-bootstrap";
import "_Css/common/_dataTable.scss";

const HamIndex = () => {

  const [classDefinition, setClassDefinition] = useState(null);
  const [rightDisplay, setRightDisplay] = useState(false);
  const [isEditing, setisEditing] = useState(false);
  
  const showRightSide = (val) => {
    console.log("Enterrr4444",val);
    setRightDisplay(val);
  };

  const setIsEditing = (value) => {
    setisEditing(value);
    
  }
  const resetRightSide = () => {
    // this.setState({ categoryData: null });
    setClassDefinition(null);
  }

  const getClassListDetails = (
    CLASS_NAME,
    CLASS_TYPE,
    COMPANY_NAME,
    STATUS,
    CLASS_ID,
    CREATED_AT,
    CREATED_BY,
    UPDATED_AT,
    UPDATED_BY,
    COMPANY_ID,
    PARENT_CLASS_ID
  ) => {
    const classDefinitionHam = {
      CLASS_NAME:CLASS_NAME,
      CLASS_TYPE:CLASS_TYPE,
      COMPANY_NAME:COMPANY_NAME,
      STATUS:STATUS,
      CLASS_ID:CLASS_ID,
      CREATED_AT,
      CREATED_BY,
      UPDATED_AT,
      UPDATED_BY,
      COMPANY_ID:COMPANY_ID,
      PARENT_CLASS_ID
    };
    console.log("classDefinitionHam----",classDefinitionHam);
    setClassDefinition(classDefinitionHam);
  };
console.log("classDefinition#####------",classDefinition);

  return (
    <main>
     <div className="padding-t-10 margin-b-15">
      <Container fluid>
        <Breadcrumbs activePageName={"Class Definition"} />
      </Container>

          {/* <Breadcrumbs activePageName={"Class Definition"} /> */}
        </div>
        <div className="container-fluid" bsClass="">
            < HamHeader />
            <PanelGroup direction="horizontal" className="panelOverflow">
            <Panel id="sidebar" minSize={33} order={1} defaultSize={rightDisplay ? 67 : 100} className={rightDisplay ? "catBordDv isShowLeftPanel rwLeftPart" : "catBordDv rwLeftPart"}>
            <HamList
              rightDisplay={rightDisplay}
              getClassListDetails={getClassListDetails}
              showRightSide={showRightSide}
              resetRightSide={resetRightSide}
              />
          </Panel>
          {rightDisplay ?
                    <>
                      <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
                      <Panel minSize={33} order={2} defaultSize={33}>
                        <div className="stickyArea rBoxStyle">
                          <HamRightPart
                            classDefinition={classDefinition}
                            setIsEditing={setIsEditing}
                            isEditing={isEditing}
                            resetRightSide={resetRightSide}
                            showRightSide={showRightSide}
                            rightDisplay={rightDisplay}
                           

                          />
                        </div>
                      </Panel>
                    </> : ""
                }
            </PanelGroup>
        </div>
    </main>
  )
}

export default HamIndex
