
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export default function (state = initialState.breakfixQuickViewTable, action){

	switch (action.type){
    case 'SET_BF_QV_TABLE_CAROUSEL_INDEX':
      return Object.assign({}, state, {carouselIndex: action.payload});
		default:
				return state;
		}
}
