
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GLOBAL } from "../../../components/Globals";

export const forumApi = createApi({
  reducerPath: "forumApi",
  baseQuery: fetchBaseQuery({
    baseUrl: window.location.origin,
  }),
  endpoints: (builder) => ({
    getAllActiveInActiveForums: builder.query({
      query: ({ queryString }) => {
        return GLOBAL.getAllActiveInActiveForums + queryString;
      },
    }),
    getAllCompany: builder.query({
      query: () => {
        return GLOBAL.loadTagCompanyUrl;
      },
    }),
    getAllU2CCompany: builder.query({
      query: () => {
        return GLOBAL.getAllCompany;
      },
    }),
    occurrenceStatus: builder.query({
      query: ({ queryString }) => {
        return GLOBAL.occurrenceSts + queryString;
      },
    }),
    getAllCategory: builder.query({
      query: ({ queryString }) => {
        return GLOBAL.categoryDetails + queryString;
      },
    }),
    committeDetails: builder.query({
      query: ({ queryString = "" }) => {
        return GLOBAL.allcommitteDetailsByForumName + queryString;
      },
    }),
    getAllFroums: builder.query({
      query: ({ pageNum, pgSize, filtersString = "", queryString = "" }) => {
        return (
          `${GLOBAL.getAllForums}/${pageNum}/${pgSize}` +
          queryString +
          filtersString
        );
      },
    }),
    getAllTrack: builder.query({
      query: ({ companyId }) => {
        return GLOBAL.trackDetails(companyId);
      },
    }),
    getAllSubTrack: builder.query({
      query: ({ name }) => {
        return GLOBAL.subTrackDetailsByTrackName(name);
      },
    }),
    getAllTracks: builder.query({
      query: () => {
        return GLOBAL.getAllTracks;
      },
    }),
    getAllSubTracks: builder.query({
      query: () => {
        return GLOBAL.getAllSubTracks;
      },
    }),
    getForumType: builder.query({
      query: () => {
        return GLOBAL.getForumType;
      },
    }),
    getForumStatus: builder.query({
      query: () => {
        return GLOBAL.getForumStatus;
      },
    }),
    getRegionList: builder.query({
      query: () => {
        return GLOBAL.getRegionListFnd;
      },
    }),
    getForumById: builder.query({
      query: ({ id, queryString = "" }) => {
        return GLOBAL.getForumById + queryString;
      },
      keepUnusedDataFor: 0,
    }),
    createForum: builder.mutation({
      query: ({ data, queryString = "" }) => ({
        url: GLOBAL.createForum + queryString,
        method: "POST",
        body: data,
      }),
    }),
    updateForum: builder.mutation({
      query: ({ data, queryString = "" }) => ({
        url: GLOBAL.updateForum + queryString,
        method: "PUT",
        body: data,
      }),
    }),
  }),
});
export const {
  useGetAllActiveInActiveForumsQuery,
  useGetAllCompanyQuery,
  useGetAllU2CCompanyQuery,
  useOccurrenceStatusQuery,
  useGetAllCategoryQuery,
  useCommitteDetailsQuery,
  useGetAllFroumsQuery,
  useGetAllTrackQuery,
  useGetAllSubTrackQuery,
  useGetAllSubTracksQuery,
  useGetAllTracksQuery,
  useGetForumByIdQuery,
  useCreateForumMutation,
  useUpdateForumMutation,
  useGetRegionListQuery,
  useGetForumStatusQuery,
  useGetForumTypeQuery,
} = forumApi;
const { reducer } = forumApi;
export default reducer;
