
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Container from 'react-bootstrap/Container';


import LeftSidebarMenulist from "./configBoard.js";
import SlaEditHeader from "./header.js";
import XsmSlaFixFormTab from "./form.js";
import { loadSlaDefEditDetails } from "_Actions/sla/lovAction.js";
import { loadSlaDefActiveMandatory } from "_Actions/sla/quickViewAction.js";
import { bindActionCreators } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import XsmProcessHierarchy from "../create/processHierarchy.js";
import AuditLog from "./AuditLog.js";
import LoadingIcons from "react-loading-icons";
import { GLOBAL } from "_Globals";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../common/Breadcrumbs.js";

import PopUpAttribute from "./attribute";
import PopUpMilestone from "./milestoneNew";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import "_Css/form/_form.scss";
const SlaEditIndex = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [subMenu, setSubMenu] = useState("4");
  const [showHideAlfy, setShowHideAlfy] = useState(false);
  const [showHideRightNav, setShowHideRightNav] = useState(false);
  const [mobileLeftMenuFilter, setMobileLeftMenuFilter] = useState(false);
  const [actionView, setActionView] = useState("specifyAttribute");
  const [targetTimeError,setTargetTimeError] = useState("");
  const [isRightEditPanel, setrightEditPanel] = useState(true);
  const [isFormValid, setIsFormValid] = useState(false);

  const slaDefEditDetails = useSelector((state) => state.slaDefEditDetails);
  const translator = useSelector((state) => state.spcmReducer.tr);
  const slaDefActiveMandatory = useSelector(
    (state) => state.slaDefActiveMandatory
  );
  const comingSoonShowLoader = useSelector(
    (state) => state.comingSoonShowLoader.loading
  );

  const rightEditPanel = (value) => {
    setrightEditPanel(value);
  };
  const showFormValid = (value) => {
    setIsFormValid(value)
  }

  const showHideAlfySet = (value) => {
    setShowHideAlfy(value);
  };
  const showHideRightNavSet = (value) => {
    setShowHideRightNav(!showHideRightNav);
  };

  const mobileLeftMenuFilterSet = () => {
    setMobileLeftMenuFilter(!mobileLeftMenuFilter);
  };

  const changeActionView = (actionSelected) => {
    setActionView(actionSelected);
  };

  const showRightPaneAction = (actionView) => {
    switch (actionView) {
      case "activityDetails":
        return <AuditLog ciId={params.ciId} translator={translator} slaDefId={params.slaDefId}/>;
      case "servicediagram":
        return <XsmProcessHierarchy translator={translator} rightEditPanel={rightEditPanel} />;
      case "specifyAttribute":
        return <PopUpAttribute
        translator={translator}
        moduleId={slaDefEditDetails.moduleId}
        slaDefId={params.slaDefId}
        slaDefEditDetails={slaDefEditDetails}
        rightEditPanel={rightEditPanel}
      />;
      case "specifyMilestone":
        return <PopUpMilestone
        translator={translator}
        slaDefId={params.slaDefId}
        slaDefEditDetails={slaDefEditDetails}
        rightEditPanel={rightEditPanel}
      />;

      default:
        return <XsmProcessHierarchy translator={translator} />;
    }
  };

  useEffect(() => {
    GLOBAL.slaResponseRecived = "";
    dispatch(loadSlaDefEditDetails(params.slaDefId));
    dispatch(loadSlaDefActiveMandatory(params.slaDefId));
  }, []);

  if (
    slaDefEditDetails.companyId == "" ||
    slaDefEditDetails.companyId == undefined ||
    slaDefEditDetails.moduleId == "" ||
    slaDefEditDetails.moduleId == undefined ||
    slaDefEditDetails.slaStatus == "" ||
    slaDefEditDetails.slaStatus == undefined ||
    comingSoonShowLoader
  ) {
    return (
      <LoadingIcons.Bars height="5em" stroke="transparent" fill="#06bcee" />
    );
  }
  //alert("this.props.slaDefEditDetails.moduleId"+this.props.slaDefEditDetails.moduleId)
  return (
    <main>
        {/* App Header Section Start*/}
        {/* <Header rightNavValue={showHideRightNavSet} ciId={params.ciId} /> */}
        {/* App Header Section End*/}
        {/* <div
          onClick={mobileLeftMenuFilterSet}
          id="mobileLeftMenuFilter"
          className={
            mobileLeftMenuFilter
              ? "mobileLeftMenuFilterMove d-md-none d-lg-none"
              : "d-md-none d-lg-none"
          }
        >
          <i
            className={
              mobileLeftMenuFilter
                ? "fa fa-angle-double-left"
                : "fa fa-angle-double-right"
            }
          ></i>
        </div>
        <LeftSidebarMenulist
          slaDefId={params.slaDefId}
          slaDefEditDetails={slaDefEditDetails}
          newMobileLeftMenuFilter={mobileLeftMenuFilter}
          translator={translator}
          moduleId={slaDefEditDetails.moduleId}
        /> */}
        {/* Support App Left Side MenuBar Section End*/}


        <Container bsPrefix=" " className="container-fluid margin-t-10 margin-b-15">
          <Breadcrumbs translator={translator}/>
          <Form>
            {/* Home Page blue bar Section Start*/}
            <SlaEditHeader
              translator={translator}
              slaStatusId={slaDefEditDetails.slaStatusId}
              changeActionView={changeActionView}
              slaDefId={params.slaDefId}
              navigate={navigate}
              actionView={actionView}
              targetTimeError={targetTimeError}
              setTargetTimeError={setTargetTimeError}
              rightEditPanel={rightEditPanel}
              setIsFormValid={setIsFormValid}
              showFormValid={showFormValid}
            />
            {/* Home Page blue bar Section end*/}

            <PanelGroup direction="horizontal">
            <Panel id="sidebar" minSize={33} order={1} defaultSize={isRightEditPanel ? 67 : 100} className={isRightEditPanel ? "isShowLeftPanel rwLeftPart" : "rwLeftPart"}>
            <XsmSlaFixFormTab
                  translator={translator}
                  moduleId={slaDefEditDetails.moduleId}
                  companyId={slaDefEditDetails.companyId}
                  slaDefId={params.slaDefId}
                  targetTimeError={targetTimeError}
                  setTargetTimeError={setTargetTimeError}
                  showFormValid={showFormValid}
                />
            </Panel>
            {isRightEditPanel ?
                <>
                  <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
                  <Panel minSize={33} order={2} defaultSize={33}>
                    <div className="stickyArea rBoxStyle">
                    {showRightPaneAction(actionView)}
                    </div>
                  </Panel>
                </> : null
            }      
          </PanelGroup>
          </Form>
          </Container>

    </main>
  );
};

// export function mapStateToProps({
//   slaDefEditDetails,
//   spcmReducer,
//   slaDefActiveMandatory,
//   comingSoonShowLoader,
// }) {
//   return {
//     slaDefEditDetails,
//     translator: spcmReducer.tr,
//     slaDefActiveMandatory,
//     comingSoonShowLoader: comingSoonShowLoader.loading
//   };
// }

// function mapDispatchToProps(dispatch) {
//   return bindActionCreators(
//     { loadSlaDefEditDetails, loadSlaDefActiveMandatory },
//     dispatch
//   );
// }

// connect(mapStateToProps, mapDispatchToProps)(SlaEditIndex);
export default SlaEditIndex;
