
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import axios from "axios";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
// import LeftSidebar from "./LeftSidebar";
import WIBSearchInner from "../../../common/WorkItemBoard/WIBSearch/WIBSearchInner";
import Header from "./SupportHeader";
import Empty from "../../../common/WorkItemBoard/Empty";
import ProcessHierarchy from "./ProcessHierarchy";
import {UserDetails as UserDetailss} from "./UserDetails";
import {ServiceDetails as ServiceDetailss} from "./ServiceDetails";
import CreateForm from "./CreateForm";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { getTranslation } from "../../../../actions/spcmActions";
import Breadcrumbs from '../../../common/header/breadcrumbs';
import _ from 'lodash';
import { GLOBAL } from "_Globals";
import AvailableIndividuals from "../../../common/WorkItemBoard/availableIndividuals/AvailableIndividuals";
const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});
import UserDetails from "../../../common/WorkItemBoard/xsmEditRequestedBy";
import ServiceDetails from "../../../common/WorkItemBoard/xsm-service-editquickView";
import CIDEtails from "../../../common/WorkItemBoard/xsm-ci-editquickView";
import "_Css/form/_form.scss";
class CreateIncidentIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenu: "4",
      comp: 0,
      userId: "",
      service: 0,
      categoryID: 0,
      role_id: "",
      groupID: "",
      roleID: "",
      changeImplentationRole: "",
      processhierarchy: 0,
      serviceErrorColor: "",
      ImpactedErrorColor: "",
      companyError: "",
      isPriorityCallInProgress: false,
      serviceList: [],
      sortedUrgencyList: {},
      sortedImpactList: {},
      priorityValue: {},
      selectedAssignmentGroup:[],
			selectedAssignmentUser:[],
			showAvailableIndividual:0,
      isConsumerSelected: false,
      isServiceSelected: false,
      isImpactedCISelected: false,
      isIndividualSelected: false,
      individualID: 0,
    };
    this.changeUser = this.changeUser.bind(this);
    this.changeServiceComponent = this.changeServiceComponent.bind(this);
    this.processHierarchy = this.processHierarchy.bind(this);
    this.setSaveStatus = this.setSaveStatus.bind(this);
    this.setErrorServiceColor = this.setErrorServiceColor.bind(this);
    this.setErrorImpactedColor = this.setErrorImpactedColor.bind(this);
    this.roleIdentification = this.roleIdentification.bind(this);
    this.setCompanyError = this.setCompanyError.bind(this);
    this.setPriorityCallFlag = this.setPriorityCallFlag.bind(this);
    this.setServiceList = this.setServiceList.bind(this);
    this.setGlobalList = this.setGlobalList.bind(this);
    this.setAssignmentGrpUsr = this.setAssignmentGrpUsr.bind(this);
    this.showAvailableIndividual = this.showAvailableIndividual.bind(this);
    this.rightEditPanel = this.rightEditPanel.bind(this);
    this.showConsumerDetails = this.showConsumerDetails.bind(this);
    this.showServiceDetails = this.showServiceDetails.bind(this);
    this.showImpactedCIDetails = this.showImpactedCIDetails.bind(this);
    this.showIndividualDetails = this.showIndividualDetails.bind(this);
    this.setIndividualDetails = this.setIndividualDetails.bind(this);
  }
  
  setAssignmentGrpUsr(selectd,type){
		switch(type){
		  case "user":
			this.setState({selectedAssignmentUser:selectd});
			break;
		  case  "group":
			this.setState({selectedAssignmentGroup:selectd});
			break;
		}
	}

  setPriorityCallFlag(value) {
    this.setState({
      isPriorityCallInProgress: value,
    });
  }

  setCompanyError(value) {
    this.setState({ companyError: value });
  }
  setSaveStatus(value) {
    this.setState({ saveStatus: value });
  }

  setServiceList(list) {
    this.setState({ serviceList: list });
  }
  changeUser(consumerId) {
    //alert("M1"+consumerId)
    if (consumerId != 0) {
      this.setState({
        comp: 1,
        userId: consumerId,
        service: 0,
        processhierarchy: 0,
        showAvailableIndividual:0,
        isConsumerSelected: false,
        isServiceSelected: false,
        isImpactedCISelected: false,
        isIndividualSelected: false,
      });
    }
    //alert(this.state.comp);
  }
  componentDidMount() {
    let str = {};
    str.user_id = "";
    str = JSON.stringify(str);
    api.get("/api/userInfo", { headers: { query: str } }).then((response) => {
      let changeUserid = "";
      if (
        response.data.role_id.split(",").includes("20") &&
        !response.data.role_id.split(",").includes("52")
      ) {
        changeUserid = "changeUserid";
      } else if (
        response.data.role_id.split(",").includes("52") &&
        !response.data.role_id.split(",").includes("20")
      ) {
        changeUserid = "impUserid";
      } else if (
        (response.data.role_id.split(",").includes("20") &&
          response.data.role_id.split(",").includes("52")) ||
        (response.data.role_id.split(",").includes("20") &&
          response.data.role_id.split(",").includes("51")) ||
        (response.data.role_id.split(",").includes("52") &&
          response.data.role_id.split(",").includes("19"))
      ) {
        changeUserid = "impUserid,changeUserid";
      } else if (
        response.data.role_id.split(",").includes("19") &&
        !response.data.role_id.split(",").includes("51")
      ) {
        changeUserid = "changeUserid";
      } else if (
        response.data.role_id.split(",").includes("51") &&
        !response.data.role_id.split(",").includes("19")
      ) {
        changeUserid = "impUserid";
      } else if (
        response.data.role_id.split(",").includes("19") &&
        response.data.role_id.split(",").includes("51")
      ) {
        changeUserid = "impUserid,changeUserid";
      }
      this.setState({
        role_id: response.data.role_id,
        groupID: response.data.groupId,
        roleID: response.data.role_id,
        changeImplentationRole: changeUserid,
      });
    });
  }
  // componentDidUpdate() {
  //   let str = {};
  //   str.user_id = "";
  //   str = JSON.stringify(str);
  //   api.get("/api/userInfo", { headers: { query: str } }).then((response) => {
  //     let changeUserid = "";
  //     if (
  //       response.data.role_id.split(",").includes("20") &&
  //       !response.data.role_id.split(",").includes("52")
  //     ) {
  //       changeUserid = "changeUserid";
  //     } else if (
  //       response.data.role_id.split(",").includes("52") &&
  //       !response.data.role_id.split(",").includes("20")
  //     ) {
  //       changeUserid = "impUserid";
  //     } else if (
  //       (response.data.role_id.split(",").includes("20") &&
  //         response.data.role_id.split(",").includes("52")) ||
  //       (response.data.role_id.split(",").includes("20") &&
  //         response.data.role_id.split(",").includes("51")) ||
  //       (response.data.role_id.split(",").includes("52") &&
  //         response.data.role_id.split(",").includes("19"))
  //     ) {
  //       changeUserid = "impUserid,changeUserid";
  //     } else if (
  //       response.data.role_id.split(",").includes("19") &&
  //       !response.data.role_id.split(",").includes("51")
  //     ) {
  //       changeUserid = "changeUserid";
  //     } else if (
  //       response.data.role_id.split(",").includes("51") &&
  //       !response.data.role_id.split(",").includes("19")
  //     ) {
  //       changeUserid = "impUserid";
  //     } else if (
  //       response.data.role_id.split(",").includes("19") &&
  //       response.data.role_id.split(",").includes("51")
  //     ) {
  //       changeUserid = "impUserid,changeUserid";
  //     }
  //     this.setState({
  //       role_id: response.data.role_id,
  //       groupID: response.data.groupId,
  //       roleID: response.data.role_id,
  //       changeImplentationRole: changeUserid,
  //     });
  //   });
  // }
  roleIdentification(Rolevalue) {
    let flag = false;
    let roles = this.state.role_id.split(",");
    for (let i = 0; i < roles.length; i++) {
      if (roles[i] == Rolevalue) {
        flag = true;
        break;
      }
    }
    if (flag) return true;
    else return false;
  }
  processHierarchy() {
    //alert("changeStatusTimeLine");
    this.setState({
      processhierarchy: 1,
      comp: 0,
      service: 0,
      showAvailableIndividual:0,
      isConsumerSelected: false,
      isServiceSelected: false,
      isImpactedCISelected: false,
      isIndividualSelected: false,
    });
    //alert(this.state.processhierarchy);
  }

  showAvailableIndividual() {
    //alert("changeStatusTimeLine");
    this.setState({
      processhierarchy: 0,
      comp: 0,
      service: 0,
      showAvailableIndividual:1,
      isConsumerSelected: false,
      isServiceSelected: false,
      isImpactedCISelected: false,
      isIndividualSelected: false,
    });
    //alert(this.state.processhierarchy);
  }

  setErrorServiceColor(value) {
    this.setState({
      serviceErrorColor: value,
    });
   
  }
  setErrorImpactedColor(value) {
    this.setState({
      ImpactedErrorColor: value,
    });
  
  }
  changeServiceComponent(serviceID) {
    //alert("serviceID"+serviceID);
    if (serviceID != 0) {
      this.setState({
        comp: 0,
        service: 1,
        categoryID: serviceID,
        processhierarchy: 0,
        isConsumerSelected: false,
        isServiceSelected: false,
        isImpactedCISelected: false,
        isIndividualSelected: false,
      });
    }
    //alert(this.state.service);
    //alert("service(categoryID)"+this.state.categoryID);
  }
  setGlobalList(companyID,urgencyList, impactList){
    let sortedUrgency=this.state.sortedUrgencyList;
    sortedUrgency[companyID]=_.sortBy(urgencyList[companyID], o=> parseInt(o.field1Value, 10));
    let sortedImpact=this.state.sortedImpactList;
    sortedImpact[companyID]=_.sortBy(impactList[companyID], o=> parseInt(o.field1Value, 10));
    if(typeof this.state.priorityValue[companyID]==='undefined'){
    axios.get('/api/getCompanyPriorityMatrix?companyId='+companyID+'&module=Breakfix'+'&urgency='+sortedUrgency[companyID].at(0).field1Value+'&impact='+sortedImpact[companyID].at(-2).field1Value).then((calculatedPriorityForVIP)=>{
      let priorityValue=this.state.priorityValue;
      if(calculatedPriorityForVIP.data!==''){
        priorityValue[companyID]={priority :calculatedPriorityForVIP.data[0].priority, priorityLabel: calculatedPriorityForVIP.data[0].priorityValue};
        this.setState({priorityValue: priorityValue});
      }else{
        priorityValue[companyID]='';
        this.setState({priorityValue: priorityValue});
      }
    });
    }
    this.setState({sortedUrgencyList: sortedUrgency, sortedImpactList: sortedImpact});
  }
  rightEditPanel(){

  }
  showConsumerDetails(value){
    if(value){
      this.setState({isConsumerSelected: value, comp: 0, service: 0, categoryID: 0, processhierarchy: 0, isServiceSelected: false, isImpactedCISelected: false, isIndividualSelected: false, showAvailableIndividual:0});
    }else{
      this.setState({isConsumerSelected: value});
    }
  }
  showServiceDetails(value){
    if(value){
      this.setState({isConsumerSelected: false, comp: 0, service: 0, categoryID: 0, processhierarchy: 0, isServiceSelected: value, isImpactedCISelected: false, isIndividualSelected: false, showAvailableIndividual:0});
    }else{
      this.setState({isServiceSelected: value});
    }
  }
  showImpactedCIDetails(value){
    if(value){
      this.setState({isConsumerSelected: false, comp: 0, service: 0, categoryID: 0, processhierarchy: 0, isServiceSelected: false, isImpactedCISelected: value, isIndividualSelected: false, showAvailableIndividual:0});
    }else{
      this.setState({isImpactedCISelected: value}); 
    }
  }
  showIndividualDetails(value){
    if(value){
      this.setState({isConsumerSelected: false, comp: 0, service: 0, categoryID: 0, processhierarchy: 0, isServiceSelected: false, isImpactedCISelected: false, isIndividualSelected: value, showAvailableIndividual:0});
    }else{
      this.setState({isIndividualSelected: value}); 
    }
  }
  setIndividualDetails(id){
    this.setState({individualID: id});
  }
  render() {
    return (
      <div>
        {this.state.role_id !== "" ? (
          <WIBSearchInner
            tr={this.props.tr}
            roleIdentification={this.roleIdentification}
            group_id={this.state.groupID}
            changeImplentationRole={this.state.changeImplentationRole}
          />
        ) : null}
        
        <div className="container-fluid margin-t-10 margin-b-15">
          <Breadcrumbs activePageName="Create" parentPageurl="/quickViewBreakFix" parentPageName="Work Item Board" />
        </div>
        <div className="container-fluid minHeightWIB">
          <Form>
            <Header
              processHierarchy={this.processHierarchy}
              setSaveStatus={this.setSaveStatus}
              translator={this.props.tr}
              setErrorServiceColor={this.setErrorServiceColor}
              setErrorImpactedColor={this.setErrorImpactedColor}
              setCompanyError={this.setCompanyError}
              isPriorityCallInProgress={this.state.isPriorityCallInProgress}
              serviceList={this.state.serviceList}
              sortedUrgencyList={this.state.sortedUrgencyList}
              sortedImpactList={this.state.sortedImpactList}
              priorityValue={this.state.priorityValue}
            />
            <Row className="row-eq-height">
              <Col md={8} sm={12} xs={12}>
                <CreateForm
                  roleIdentification={this.roleIdentification}
                  setErrorImpactedColor={this.setErrorImpactedColor}
                  ImpactedErrorColor={this.state.ImpactedErrorColor}
                  setErrorServiceColor={this.setErrorServiceColor}
                  serviceErrorColor={this.state.serviceErrorColor}
                  changeUser={this.changeUser}
                  changeServiceComponent={this.changeServiceComponent}
                  saveStatus={this.state.saveStatus}
                  setSaveStatus={this.setSaveStatus}
                  translator={this.props.tr}
                  setCompanyError={this.setCompanyError}
                  companyError={this.state.companyError}
                  setPriorityCallFlag={this.setPriorityCallFlag}
                  setServiceList={this.setServiceList}
                  isPriorityCallInProgress={this.state.isPriorityCallInProgress}
                  setGlobalList={this.setGlobalList}
                  selectedAssignmentGroup={this.state.selectedAssignmentGroup}
                  selectedAssignmentUser={this.state.selectedAssignmentUser}
                  setAssignmentGrpUsr={this.setAssignmentGrpUsr}
                  showAvailableIndividual={this.showAvailableIndividual}
                  showConsumerDetails={this.showConsumerDetails}
                  showServiceDetails={this.showServiceDetails}
                  showImpactedCIDetails={this.showImpactedCIDetails}
                  showIndividualDetails={this.showIndividualDetails}
                  setIndividualDetails={this.setIndividualDetails}
                />
              </Col>
              <Col md={4} sm={12} xs={12} className="colRightHeight">
                <div className="stickyArea rBoxStyle">
                  {this.state.processhierarchy == 0 &&
                  this.state.comp == 0 &&
                  this.state.service == 0 && this.state.showAvailableIndividual== 0 && !(this.state.isConsumerSelected || this.state.isServiceSelected || this.state.isImpactedCISelected ||this.state.isIndividualSelected) ? (
                    <ProcessHierarchy translator={this.props.tr} />
                  ) : (
                    <Empty />
                  )}
                  {this.state.comp == 0 ? (
                    <Empty />
                  ) : (
                    <UserDetailss
                      userId={this.state.userId}
                      translator={this.props.tr}
                    />
                  )}
                  
                  {this.state.isConsumerSelected?<UserDetails  userId={GLOBAL.breakFixConsumerID} userDetailsLabel={"Consumer Details"} translator={this.props.tr} rightEditPanel={this.rightEditPanel}/>: <Empty/>}
                  {this.state.isServiceSelected?<ServiceDetails offeringId={GLOBAL.breakFixServiceID} translator={this.props.tr}/>: <Empty />}
                  {this.state.isImpactedCISelected?<CIDEtails ciId={GLOBAL.ciId} translator={this.props.tr} rightEditPanel={this.rightEditPanel}/>: <Empty/>}
                  {this.state.isIndividualSelected?<UserDetails userId={this.state.individualID} userDetailsLabel={"Individual Details"} translator={this.props.tr} rightEditPanel={this.rightEditPanel}/>: <Empty/>}
                  { this.state.showAvailableIndividual == 1 ? (
                    <AvailableIndividuals setAssignmentGrpUsr={this.setAssignmentGrpUsr}  selectedAssignmentGroup={this.state.selectedAssignmentGroup} selectedAssignmentUser={this.state.selectedAssignmentUser} module="Incident"/>
                  ) : (
                    <Empty/>
                  )}
                  {this.state.service == 0 ? (
                    <Empty />
                  ) : (
                    <ServiceDetailss
                      categoryID={this.state.categoryID}
                      translator={this.props.tr}
                    />
                  )}
                  {this.state.processhierarchy == 1 &&
                  this.state.comp == 0 &&
                  this.state.service == 0 &&
                  this.state.showAvailableIndividual == 0 ? (
                    <ProcessHierarchy translator={this.props.tr} />
                  ) : (
                    <Empty />
                  )}
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

CreateIncidentIndex = reduxForm({
  form: "breakFixCreate",
})(CreateIncidentIndex);

const mapStateToProps = ({ spcmReducer }) => {
  return {
    tr: spcmReducer.tr,
  };
};

export default connect(mapStateToProps, { getTranslation })(
  CreateIncidentIndex
);
