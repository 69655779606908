
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

import PindCategories from "../../common/header/pind-categories";
import Breadcrumbs from "../../common/header/breadcrumbs";
import Header from "./header";
import View from "../../uiBuilder/renderer/View.js";
import "_Css/common/_table.scss";
import "_Css/form/_form.scss";

const Index = () => {
  const [refreshed,setRefreshed] = useState(false);
  useEffect(()=>{
     if(setRefreshed){
      setRefreshed(false);
     }
  },[refreshed]);
  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Row>
          <Col md={8} xs={9}>          
            <Breadcrumbs activePageName="My View" />
          </Col>
          <Col md={4} xs={3}>
          </Col>
        </Row>       
      </Container>

      <Container fluid>
        <Header setRefreshed={setRefreshed} />
      </Container>
      <div className="tabSerhed"><View pageRefreshed={refreshed} pageId={"668f94bf8ddc0f2781a31b96"}/></div>
    </main>
  );
};

export default Index;
