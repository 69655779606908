
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col} from 'react-bootstrap';
import Cookies from 'universal-cookie';
import { navigationHooks } from '../../../helpers/NavigationHook';
import {HiPlus} from "react-icons/hi";
// import CommonHeader from '../CommonHeader';
const cookies = new Cookies();
let homepagelocation = cookies.get('gph');
if (homepagelocation) homepagelocation = homepagelocation.replace('s:', '');
if (homepagelocation) homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];


class CiCategoryHeader extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			category_name:'',
			sub_category_name:'',
			status:''
        };
		this.showHome = this.showHome.bind(this);
		this.getCIcategoryDetails = this.getCIcategoryDetails.bind(this)
	}
	showHome(){
		navigationHooks.navigate(homepagelocation);
	}
	getCIcategoryDetails(category_name, sub_category_name, status) {
		this.setState({
			category_name: category_name,
			sub_category_name: sub_category_name,
			status: status
		});
	}
	render() {
		return (
			<Row className="margin-b-15">
				{/* <CommonHeader translator={this.props.tr}/>  */}
				<Col lg={4} md={6} sm={8} xs={8}>
                    <div aria-label='Page Filters' role="contentinfo" className="paHedFilter">
						<Link bsPrefix=' ' role="button" className="myBt plus fillBtn" title={this.props.tr['Create new Category']} to="/newCiCategory" ><HiPlus/></Link>
						{/* <Button aria-label={this.props.tr["Save"]} role="button" bsStyle="" bsClass="" className="myBt plus" disabled={this.props.showLoader} title={this.props.tr['Save']}><i aria-hidden="true" className={this.props.showLoader ? "fa fa-spinner fa-spin" : "fa fa-floppy-o"}></i></Button> */}
						{/* <Link aria-label={this.props.tr["Close"]} role="button" className="myBt cancel fillBtn" title={this.props.tr['Close']} to="/categoryBoard"><i className="fa fa-times" aria-hidden="true"></i></Link> */}
					</div>
					
				</Col>
			</Row>
		);
	}
}

export default CiCategoryHeader;
