
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

let sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

import { reduxForm, SubmissionError } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import { createIntegrationHub } from "../../../actions/integrationHub/integrationHubAction.js";
import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import { MyContext } from '_MyContext';

const CreateOutBoundConHeader = (props) => {
  const companyList = useSelector((state) => state.spcmReducer.companyList);
  const translator = useSelector((state) => state.spcmReducer.tr);
  const scenarioDropDownList = useSelector(
    (state) => state.getIntegrationHubData.scenarioDropDownList
  );
  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const saveObjRef = useRef(null);

	useEffect(() => {
		context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
    context.newTabPrevent();
	}, []);

  const dispatch = useDispatch();
  let { handleSubmit, submitting } = props;

  const submit = async (values) => {
    let companyName;
    let scenarioName;
    companyList.map(function (item, index) {
      if (values.COMPANY_NAME == item.field1Value) {
        companyName = item.field1Key;
      }
    });
    scenarioDropDownList &&
      scenarioDropDownList.map(function (item) {
        if (values.SENARIO == item.scenarioId) {
          scenarioName = item.scenarioName;
        }
      });

    return sleep(1000).then(() => {
      if (`${JSON.stringify(values, null, 2)}` == "{}") {
        throw new SubmissionError({
          _error:
            "The form has missing required fields, please revalidate the highlighted fields and update the form",
        });
      } else if (values.scenarioId == "") {
        throw new SubmissionError({
          _error:
            "The form has missing required fields, please revalidate the highlighted fields and update the form",
        });
      } else {
        let postjson;
        postjson = {
          id: 0,
          scenarioName: scenarioName,
          scenarioId: values.SENARIO,
          endpoint: values.END_POINT,
          requestMethod: "POST",
          headers: "{Content-Type=application/json, Charset=UTF-8}",
          username: values.USER_NAME,
          password: values.IDENS,
          authMechanism: "Basic",
          queryParameters: "",
          requestPayload: "",
          criteria: values.CRITERIA,
          tenantId: values.COMPANY_NAME,
          tenantName: companyName,
        };
        dispatch(createIntegrationHub(postjson));
      }
    });
  };
  return (
    <Row className="margin-b-15">
      <Col lg={4} md={6} sm={6} xs={12}>
        <h1 bsClass="" className="sPageHeading1">
          {translator["Outbound Connections"]}
        </h1>
      </Col>
      <Col lg={8} md={6} sm={6} xs={12}>
        <div className="paHedFilter">
          <Link
            bsPrefix=' '
            to="javascript:void(0)"
            className="myBt plus ctrlKeyPrevent"
            onClick={handleSubmit(submit)}
            disabled={submitting}
            title={translator["Save"]}
            ref={saveObjRef}
          >
            {submitting ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/>}
          </Link>
          <Link
            bsPrefix=' '
            className="myBt cancel fillBtn ctrlKeyPrevent"
            title={translator["Close"]}
            to="/integHub"
            ref={closeButton}
          >
              <IoClose/>
          </Link>
        </div>
      </Col>
    </Row>
  );
};

export default reduxForm({
  form: "xsmIntegrationHubCreateForm",
})(CreateOutBoundConHeader);
