
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Form, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import { GetFilterField } from "./filterFields";
import ListLoader from "../../common/loaders/ListLoader";

export default function GeneratedCustomTableView(props) {
  const translator = useSelector((state) => state.spcmReducer.tr);

  const onRowSelect = (index, isIconClick = false) => {
    props.setRowIndex(index);
    props.setFormValues(props.data[index]);
    props.setButtonReplacableValue(props.data[index]);
    props.setSelectedRow(props.data[index]);
    if (props?.tableProperties?.isDetailViewAvailable && !isIconClick) {
      props.setRightDisplay(true);
    }
  };

  const prepareFilters = () => {
    let filter = {};
    if (props.statusSearchInput) {
      filter["status"] = props.statusSearchInput;
    }
    return filter;
  };

  const onPageSizeChange = (e) => {
    let filter = prepareFilters();
    filter[props?.tableProperties?.currPageKey ?? "page"] = 0;
    filter[props?.tableProperties?.sizeKey ?? "size"] = e.target.value;
    props.setActivePage(0);
    props.setPerPageData(e.target.value);
    props.setFilterValues({
      ...props.filterValues,
      ...filter,
    });
    props.applyFilter({
      ...props.filterValues,
      ...filter,
    });
  };

  const onPageChange = (e) => {
    let filter = prepareFilters();
    filter[props?.tableProperties?.currPageKey ?? "page"] = e - 1;
    filter[props?.tableProperties?.sizeKey ?? "size"] = props.perPageData;
    props.setActivePage(parseInt(filter[props?.tableProperties?.currPageKey ?? "page"], 10));
    props.setFilterValues({
      ...props.filterValues,
      ...filter,
    });
    props.applyFilter({
      ...props.filterValues,
      ...filter,
    });
  };

  const onClickSortOption = (column) => {
    let sorting = "";
    let [sortKey, sortType] = props.sort.split(",");
    if (column === sortKey) {
      sorting = `${column},${sortType === "asc" ? "desc" : "asc"}`;
    } else {
      sorting = `${column},asc`;
    }
    props.setSort(sorting);
    props.setFilterValues({
      ...props.filterValues,
      [props?.tableProperties?.sortKey ?? "sort"]: sorting,
    });
    props.applyFilter({
      ...props.filterValues,
      [props?.tableProperties?.sortKey ?? "sort"]: sorting,
    });
  };

  function renderTable(data) {
    if (data && data.length > 0) {
      return data.map((data, index) => {
        return (
          <tr
            key={index}
            className={`${
              props.rightDisplay && props.rowIndex === index ? "myActive" : ""
            }`}
            onClick={() => {
              onRowSelect(index);
            }}
          >
            {props?.headerKeys.length > 0 &&
              props?.headerKeys.map((key, index) => {
                let cellText = data[key];
                if (cellText?.length > 30) {
                  cellText = cellText?.substr(0, 30) + "...";
                }
                if (props.tRow?.[index]?.isCustomCol) {
                  return (
                    <td
                      key={`custom-table-td-${index}`}
                      onClick={() => onRowSelect(index, true)}
                    >
                      {props.renderComponent(props.tRow?.[index]?.components, false, data)}
                    </td>
                  );
                }
                return (
                  <td title={data[key]} key={`custom-table-td-${index}`}>
                    {cellText}
                  </td>
                );
              })}
          </tr>
        );
      });
    }
  }

  const renderTableHeader = () => {
    if (props.theaders) {
      return (
        <tr>
          {props.theaders.map((res, index) => {
            return (
              <th key={`custom-table-th-${index}`}>
                <div
                  className="sortParArr"
                  onClick={() => {
                    res?.isSortable &&
                      onClickSortOption(props?.headerKeys?.[index]);
                  }}
                >
                  {res?.innerText}
                  {res?.isSortable &&
                    props.sort.split(",")[0] === props?.headerKeys?.[index] && (
                      <div className="sortArr">
                        <span
                          className={`customArrow ${
                            props.sort.split(",")[1] === "desc"
                              ? "dropdown"
                              : "dropup"
                          } isActive`}
                          title={
                            props.sort.split(",")[1] === "desc"
                              ? "Descending"
                              : "Ascending"
                          }
                        >
                          <span className="caret"></span>
                        </span>
                      </div>
                    )}
                </div>
                {res?.fitlerFieldType?.id && res?.isFilterable ? (
                  <div className="colSearDv">
                    {
                      <GetFilterField
                        field={res}
                        applyFilter={props?.applyFilter}
                        setFilterValues={props?.setFilterValues}
                        filterValues={props?.filterValues}
                        tableProperties={props.tableProperties}
                      />
                    }
                  </div>
                ) : null}
              </th>
            );
          })}
        </tr>
      );
    }
  };

  return (
    <div>
      <div>
        <div className="respondv">
          <div className={"tableRgtBor table-responsive"}>
            <Table
              striped
              bordered
              condensed
              hover
              className="tableView nowrapWhright"
              style={{ marginTop: "0px" }}
            >
              <thead>{renderTableHeader()}</thead>
              <tbody>
                {props?.tableDataLoading ? (
                  <tr>
                    <td
                      colSpan={props?.theaders?.length}
                      style={{ textAlign: "center" }}
                    >
                      <ListLoader />
                    </td>
                  </tr>
                ) : props.data && props.data.length === 0 ? (
                  <tr>
                    <td
                      colSpan={props?.theaders?.length}
                      style={{ textAlign: "center" }}
                    >
                      {translator["There is no matching data available"]}
                    </td>
                  </tr>
                ) : (
                  renderTable(props?.data)
                )}
              </tbody>
            </Table>
          </div>
        </div>
        {props.data && props.data.length > 0 ? (
          <div className="nBotPagina">
            <div className="nShow">
              <div className="margin-r-10">{props.itemsPerPageText}:</div>
              <Form.Select
                value={props.perPageData}
                onChange={(e) => {
                  onPageSizeChange(e);
                }}
              >
                {props.itemsPerPageOptions.split(",").map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </Form.Select>
            </div>
            <nav aria-label="Pagination" className="display-inline-block">
              <Pagination
                prevPageText="Prev"
                nextPageText="Next"
                firstPageText={<i className="glyphicon glyphicon-menu-left" />}
                lastPageText={<i className="glyphicon glyphicon-menu-right" />}
                activePage={props.activePage + 1}
                itemsCountPerPage={props.perPageData}
                totalItemsCount={
                  props?.metaData?.numberOfItems
                    ? props?.metaData?.numberOfItems
                    : 1
                }
                pageRangeDisplayed={4}
                onChange={(e) => {
                  onPageChange(e);
                }}
              />
            </nav>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
