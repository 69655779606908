
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const myCheckboxComponent = (e) => {
  e.DomComponents.addType("custom-checkbox", {
    model: {
      defaults: {
        tagName: "input",
        attributes: { type: "checkbox" },
        traits: [
          {
            type: "text",
            label: "Name",
            name: "name",
            changeProp: 1,
          },
          {
            type: "checkbox",
            label: "Checked",
            name: "checked",
            changeProp: 1,
          },
          {
            type: "text",
            label: "Label",
            name: "label",
            changeProp: 1,
          },
          {
            type: "text",
            label: "Value",
            name: "value",
            changeProp: 1,
          },
          {
            type: "checkbox",
            label: "Required",
            name: "required",
            changeProp: 1,
          },
          {
            type: "checkbox",
            label: "Disabled",
            name: "disabled",
            changeProp: 1,
          },
        ],
      },
      init() {
        this.on("change:label", this.handleInnerTextChange);
        this.on("change:checked", this.handleCheckedChange);
      },
      handleCheckedChange() {
        const checked = this.get("checked");
        const tag = this.find("input")[0];
        tag.set("attributes", {
          ...this.get("attributes"),
          checked: checked,
        });
      },
      handleInnerTextChange() {
        const tag = this.find("span")[0];
        const label = this.get("label");
        if (tag) {
          tag.components(label);
        }
      },
    },
  });
};

export const myCheckboxBlockJson = {
  label: "Custom Checkbox",
  // attributes: { class: "fa fa-check-square-o" },
  media:`<svg fill="#454545" height="40px" width="40px" version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" xml:space="preserve" stroke="#454545"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M11,30H5c-1.7,0-3-1.3-3-3v-6c0-1.7,1.3-3,3-3h6c1.7,0,3,1.3,3,3v6C14,28.7,12.7,30,11,30z"></path> </g> <g> <path d="M29,6H17c-0.6,0-1-0.4-1-1s0.4-1,1-1h12c0.6,0,1,0.4,1,1S29.6,6,29,6z"></path> </g> <g> <path d="M24,10h-7c-0.6,0-1-0.4-1-1s0.4-1,1-1h7c0.6,0,1,0.4,1,1S24.6,10,24,10z"></path> </g> <g> <path d="M29,22H17c-0.6,0-1-0.4-1-1s0.4-1,1-1h12c0.6,0,1,0.4,1,1S29.6,22,29,22z"></path> </g> <g> <path d="M24,26h-7c-0.6,0-1-0.4-1-1s0.4-1,1-1h7c0.6,0,1,0.4,1,1S24.6,26,24,26z"></path> </g> <path d="M11,2H5C3.3,2,2,3.3,2,5v6c0,1.7,1.3,3,3,3h6c1.7,0,3-1.3,3-3V5C14,3.3,12.7,2,11,2z M11.7,6.7l-3,3C8.5,9.9,8.3,10,8,10 S7.5,9.9,7.3,9.7l-2-2c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0L8,7.6l2.3-2.3c0.4-0.4,1-0.4,1.4,0S12.1,6.3,11.7,6.7z"></path> </g></svg>`,
  category: "Basic",
  content: `
    <div data-gjs-type="custom-checkbox"><input type="checkbox"  /><span> Custom Checkbox</span><div>
  `,
};
