
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { updateMultiSSOLogo } from "../../../../actions/foundation/general/MultiSSOAction";
import { reduxForm } from "redux-form";
import FormData from "form-data";

import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline} from "react-icons/io5";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';

let MultiSSOLogoHeader = (props) => {
  const tr = useSelector((state) => state.spcmReducer.tr);
  const showLoader = useSelector((state) => state.commonLoader.isLoading);
  const dispatch = useDispatch();
  const context = useContext(MyContext);
  const saveObjRef = useRef(null);

	useEffect(() => {
		context.keyDownHandler("","","",saveObjRef.current);
    context.newTabPrevent();
	}, []);

  const onSubmit = (values) => {
    let formData;
    if (values.multiTenantLogo && values.multiTenantLogo.length > 0) {
      formData = new FormData();
      formData.append("file", values.multiTenantLogo[0]);
      dispatch(updateMultiSSOLogo(formData));
    } else {
      Swal.fire("Please select file first.");
    }
  };

  let { handleSubmit, submitting } = props;

  return (
    <Row className="margin-b-15">
      <Col lg={4} md={6} sm={6} xs={12}>
        <h1 bsClass="" className="sPageHeading1">
          {tr["Multi SSO Logo"]}
        </h1>
      </Col>
      <Col lg={8} md={6} sm={6} xs={12}>
        <div className="paHedFilter">
          <Link
            bsPrefix=" "
            id="hdID"
            to="javascript:void(0)"
            className="myBt plus ctrlKeyPrevent"
            disabled={showLoader || submitting}
            onClick={handleSubmit(onSubmit)}
            title={tr["Save"]}
            ref={saveObjRef}
          >
          <span>{ showLoader || submitting ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/> }</span>
          </Link>
        </div>
      </Col>
    </Row>
  );
};

MultiSSOLogoHeader = reduxForm({
  form: "multiTenantLogo",
  enableReinitialize: true,
  destroyOnUnmount: true,
})(MultiSSOLogoHeader);

export default MultiSSOLogoHeader;
