
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ListLoader from "_Commons/loaders/ListLoader";
import { Form, Table } from "react-bootstrap";
import { IoSearch } from "react-icons/io5";
import Pagination from "react-js-pagination";
import { getUserListAction } from "../../../../actions/foundation/userAction";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import ReactDOM from "react-dom";
import { usePreviousValue } from "../../../../helpers/usePreviousValue";

const UserList = (props) => {
  const userListRed = useSelector((state) => state.userListRed);
  const showLoader = useSelector((state) => state.commonLoader.isLoading);

  const dispatch = useDispatch();

  const [page, setPage] = useState("1");
  const [faCustomDesc1, setFaCustomDesc1] = useState("customArrow dropdown");
  const [faCustomAsc1, setFaCustomAsc1] = useState("customArrow dropup");
  const [faCustomDesc2, setFaCustomDesc2] = useState("customArrow dropdown");
  const [faCustomAsc2, setFaCustomAsc2] = useState("customArrow dropup");
  const [faCustomDesc3, setFaCustomDesc3] = useState("customArrow dropdown");
  const [faCustomAsc3, setFaCustomAsc3] = useState("customArrow dropup");
  const [faCustomDesc4, setFaCustomDesc4] = useState("customArrow dropdown");
  const [faCustomAsc4, setFaCustomAsc4] = useState("customArrow dropup");
  const [faCustomDesc5, setFaCustomDesc5] = useState("customArrow dropdown");
  const [faCustomAsc5, setFaCustomAsc5] = useState("customArrow dropup");
  const [searchInput, setSearchInput] = useState("");
  const [fullNameSearchFilter, setFullNameSearchFilter] = useState("");
  const [companySearchFilter, setCompanySearchFilter] = useState("");
  const [emailSearchFilter, setEmailSearchFilter] = useState("");
  const [employeeIdSearchFilter, setEmployeeIdSearchFilter] = useState("");
  const [userNameSearchFilter, setUserNameSearchFilter] = useState("");
  const [sortBy, setSortBy] = useState(true);
  const [isMultiSelectOpen, setIsMultiSelectOpen] = useState(false);
  const previousValue = usePreviousValue(props);

  let statusOptions = [
    {label: 'Active', value: 'Y'},
    {label: 'Draft', value: 'D'},
    {label: 'Inactive', value: 'N'}
  ];

  let userTypeOptions = [
    {label: 'Contractor', value: 'Contractor'},
    {label: 'Extranet', value: 'Extranet'},
    {label: 'Office Employee', value: 'Office Employee'},
    {label: 'Pending Hire', value: 'Pending Hire'},
    {label: 'Student', value: 'Student'},
    {label: 'Supplier', value: 'Supplier'}
  ];

  const multiSelectUserTypeDropdownRef = useRef(null);
  const multiSelectStatusDropdownRef = useRef(null);


  useEffect(() => {    
    document.addEventListener('mousedown', clickOutside);
    if (multiSelectStatusDropdownRef != undefined || multiSelectStatusDropdownRef != null) {
      multiSelectStatusDropdownRef.current.childNodes[0].className = "multiSelectRef";
      let myRef = multiSelectStatusDropdownRef;
      if (props.isStatusOpen != previousValue?.isStatusOpen) {
        let obj = ReactDOM.findDOMNode(myRef.current).childNodes[0].childNodes[1];        
        if (obj != undefined || obj != null) {
          ReactDOM.findDOMNode(myRef.current).childNodes[0].childNodes[1].className = "multiSelectPopup";
          //ReactDOM.findDOMNode(myRef.current).childNodes[0].childNodes[1].style = "right:" + objRightPos + "px";
        }
      }
    }
    if (multiSelectUserTypeDropdownRef != undefined || multiSelectUserTypeDropdownRef != null) {
      multiSelectUserTypeDropdownRef.current.childNodes[0].className = "multiSelectRef";
      let myRef = multiSelectUserTypeDropdownRef;
      if (props.isUserTypeOpen != previousValue?.isUserTypeOpen) {
        let obj = ReactDOM.findDOMNode(myRef.current).childNodes[0].childNodes[1];
        if (obj != undefined || obj != null) {
          ReactDOM.findDOMNode(myRef.current).childNodes[0].childNodes[1].className = "multiSelectPopup";
          //ReactDOM.findDOMNode(myRef.current).childNodes[0].childNodes[1].style = "left:" + objLeftPos + "px";
        }
      }
    }
  }, [props.isUserTypeOpen,props.isStatusOpen])

  useEffect(() => {
    return () => {
      document.removeEventListener('mousedown', clickOutside);
    }
  }, [])

  const clickOutside = (event) =>  {
    const statusDiv = document.getElementById("statusDiv");
    const userTypeDiv = document.getElementById("userTypeDiv");

    if (multiSelectStatusDropdownRef.current != undefined || multiSelectStatusDropdownRef.current != null ||
      multiSelectUserTypeDropdownRef.current != undefined || multiSelectUserTypeDropdownRef.current != null) {
        document.addEventListener("mousedown", (event) => {
          if (userTypeDiv.contains(event.target)) {
          setIsMultiSelectOpen(true);
          props.setRightSideVisible(false);
        }
        else if (statusDiv.contains(event.target)) {
          setIsMultiSelectOpen(true);
          props.setRightSideVisible(false);
        }
        else {
          setIsMultiSelectOpen(false);
        }
      });
    }
  }

  const handleSizePerPageChange = (event) => {
    props.setrowIndex(0);
    props.setLimit(event.target.value);
    props.setRightSideVisible(false);

    dispatch(
      getUserListAction(
        props.page,
        event.target.value,
        "companyName",
        "asc",
        props.searchKey,
        props.searchValue,
        props.userType,
        props.status
      )
    );
  };

  const handlePageChange = (event) => {
    props.setrowIndex(0);
    props.setRightSideVisible(false);
    dispatch(
      getUserListAction(
        event,
        props.limit,
        "companyName",
        "asc",
        props.searchKey,
        props.searchValue,
        props.userType,
        props.status
      )
    );
  };

  const onRowSelect = (item, index) => {
    props.setrowIndex(index);
    props.setRightSideVisible(true);
    props.setUserDetails(item);
  };

  const handleChange = (e, searchValue) => {
    switch (searchValue) {
      case "companyName":
        props.setCompanySearchInput(e.target.value);
        setCompanySearchFilter(searchValue);
        break;
      case "fullName":
        props.setFullNameSearchInput(e.target.value);
        setFullNameSearchFilter(searchValue);
        break;
      case "emailAddress":
        props.setEmailSearchInput(e.target.value);
        setEmailSearchFilter(searchValue);
        break;
      case "userName":
        props.setUserNameSearchInput(e.target.value);
        setUserNameSearchFilter(searchValue);
        break;
      case "employeeNumber":
        props.setEmployeeIdSearchInput(e.target.value);
        setEmployeeIdSearchFilter(searchValue);
        break;
    }
  };

  const handleSearch = () => {
    let searchByVar = [];
    let searchByValue = [];
    props.setClearAllFilterToggle(true);
    if (props.companySearchInput != "") {
      searchByValue.push(props.companySearchInput.trim());
      searchByVar.push(companySearchFilter);
    }
    if (props.emailSearchInput != "") {
      searchByValue.push(props.emailSearchInput.trim());
      searchByVar.push(emailSearchFilter);
    }
    if (props.userNameSearchInput != "") {
      searchByValue.push(props.userNameSearchInput.trim());
      searchByVar.push(userNameSearchFilter);
    }
    if (props.fullNameSearchInput != "") {
      searchByValue.push(props.fullNameSearchInput.trim());
      searchByVar.push(fullNameSearchFilter);
    }
    if (props.employeeIdSearchInput != "") {
      searchByValue.push(props.employeeIdSearchInput.trim());
      searchByVar.push(employeeIdSearchFilter);
    }

    props.setsearchValue(searchByValue.join("|"));
    props.setsearchKey(searchByVar.join(","));
    props.setRightSideVisible(false);

    dispatch(
      getUserListAction(
        props.page,
        props.limit,
        "fullname",
        "asc",
        searchByVar.join(","),
        searchByValue.join("|"),
        props.userType,
        props.status
      )
    );
  };

  const handleStatusChange = (value) =>  {
    let searchOnStatusArr = [];
    props.setSelectedStatusValue(value);
    (props.searchKey.length === 0) ?
      props.userType.length == 0 ?
        value.length == 0 ?
          props.setClearAllFilterToggle(false) :
          props.setClearAllFilterToggle(true) :
        props.setClearAllFilterToggle(true) :
      props.setClearAllFilterToggle(true);

    props.setRightSideVisible(false);
    value.map((checkBoxValue) => {
      return (
        searchOnStatusArr.push(checkBoxValue.value)
      );
    }
    );
    props.setMultiSelectParams(props.userType, searchOnStatusArr);
    dispatch(
      getUserListAction(
        props.page,
        props.limit,
        "fullname",
        "asc",
        props.searchKey,
        props.searchValue,
        props.userType, 
        searchOnStatusArr
      )
    );
  }

  const handleUserTypeChange = (value) =>  {
    let searchOnUserTypesArr = [];
    props.setSelectedUserTypeValue(value);
    (props.searchKey.length === 0) ?
      props.status.length == 0 ?
        value.length == 0 ?
          props.setClearAllFilterToggle(false) :
          props.setClearAllFilterToggle(true) :
        props.setClearAllFilterToggle(true) :
      props.setClearAllFilterToggle(true);

    props.setRightSideVisible(false);
    value.map((checkBoxValue) => {
      return (
        searchOnUserTypesArr.push(checkBoxValue.value)
      );
    }
    );
    props.setMultiSelectParams(searchOnUserTypesArr, props.status);
    dispatch(
      getUserListAction(
        props.page,
        props.limit,
        "fullname",
        "asc",
        props.searchKey,
        props.searchValue,
        searchOnUserTypesArr, 
        props.status
      )
    );
  }

  const renderUserList = (userList) => {
    return userList.map((item, index) => {
      let cls = index == props.rowIndex ? "myActive" : "";
      let status =
        item.STATUS === "D"
          ? "Draft"
          : item.STATUS === "Y"
          ? "Active"
          : "Inactive";
      return (
        <tr
          className={
            props.isRightSideVisible && index == props.rowIndex
              ? "myActive"
              : ""
          }
          onClick={(e) => {onRowSelect(item, index);props.multiSelectOpenFun("allFalse", false)}}
        >
          <td>
            <div className="showdv414">
              <div className="valdv414">
                {item.company}
              </div>              
              {/* <div className="keydv414">{props.tr["Company"]}</div> */}
            </div>
          </td>
          <td title={item.fullname}>
            <div className="showdv414">
              <div className="keydv414">{props.tr["Name"]}</div>
              <div className="valdv414">
                {item.fullname.slice(0, 70)}
                {item.fullname.length > 70 ? "..." : ""}
              </div>
            </div>
          </td>
          <td title={item.email}>
            <div className="showdv414">
              <div className="keydv414">{props.tr["Email"]}</div>
              <div className="valdv414">
                {item.email.slice(0, 70)}
                {item.email.length > 70 ? "..." : ""}
              </div>
            </div>
          </td>
          <td title={item.username}>
            <div className="showdv414">
              <div className="keydv414">{props.tr["Username"]}</div>
              <div className="valdv414">
                {item.username.slice(0, 70)}
                {item.username.length > 70 ? "..." : ""}
              </div>
            </div>
          </td>
          <td title={item.employeeNumber}>
            <div className="showdv414">
              <div className="keydv414">{props.tr["Employee ID"]}</div>
              <div className="valdv414">
                {item.employeeNumber.slice(0, 70)}
                {item.employeeNumber.length > 70 ? "..." : ""}
              </div>
            </div>
          </td>
          <td title={item.userTypeId}>
            <div className="showdv414">
              <div className="keydv414">{props.tr["User Type"]}</div>
              <div className="valdv414">
                {item.userTypeId.slice(0, 70)}
                {item.userTypeId.length > 70 ? "..." : ""}
              </div>
            </div>
          </td>
          <td>
            <div className="showdv414">
              <div className="keydv414">{props.tr["Status"]}</div>
              <div className="valdv414">
                {status.slice(0, 70)}
                {status.length > 70 ? "..." : ""}
              </div>
            </div>
          </td>
        </tr>
      );
    });
  };

  let meta = userListRed.meta;
  let caseResponsive =
  (props.isStatusOpen || props.isUserTypeOpen) && (showLoader || userListRed.userList == [] || userListRed.userList?.length < 6) ? "overFlowClp" : "table-responsive";

  return (
    <div>
      <div aria-label="Table" role="contentinfo" className={(props.isStatusOpen || props.isUserTypeOpen) && (showLoader || userListRed.userList == [] || userListRed.userList?.length < 6) ?"respondv responDvMinH" : "respondv"}>
        <div className={"tableRgtBor " + caseResponsive}>
          <Table
            striped
            bordered
            condensed
            hover
            className="tableView nowrapWhright"
            style={{ marginTop: "0px" }}
          >
            <thead>
              <tr>
                <th>
                  <div className="sortParArr">{props.tr["Company"]}</div>
                  <div className="colSearDv ">
                    <Form.Control
                      className="colSearInp"
                      type="text"
                      value={
                        props.companySearchInput != ""
                          ? props.companySearchInput
                          : ""
                      }
                      placeholder={props.tr["Search here"]}
                      onKeyPress={(e) => {
                        if (e.charCode == "13") {
                          if (
                            props.companySearchInput != "" &&
                            props.companySearchInput.trim().length > 2
                          )
                            handleSearch();
                        }
                      }}
                      onChange={(event) => {
                        handleChange(event, "companyName");
                      }}
                      onClick={() => props.multiSelectOpenFun("allFalse", false)}
                    />
                    {props.companySearchInput.trim().length > 2 ? (
                      <a
                        title="search"
                        className="faicn"
                        href={void 0}
                        onClick={(event) => handleSearch()}
                      >
                        <IoSearch />
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </th>
                <th>
                  <div className="sortParArr">{props.tr["Name"]}</div>
                  <div className="colSearDv ">
                    <Form.Control
                      className="colSearInp"
                      type="text"
                      value={
                        props.fullNameSearchInput != ""
                          ? props.fullNameSearchInput
                          : ""
                      }
                      placeholder={props.tr["Search here"]}
                      onKeyPress={(e) => {
                        if (e.charCode == "13") {
                          if (
                            props.fullNameSearchInput != "" &&
                            props.fullNameSearchInput.trim().length > 3
                          )
                            handleSearch();
                        }
                      }}
                      onChange={(event) => {
                        handleChange(event, "fullName");
                      }}
                      onClick={() => props.multiSelectOpenFun("allFalse", false)}
                    />
                    {props.fullNameSearchInput.trim().length > 3 ? (
                      <a
                        title="search"
                        className="faicn"
                        href={void 0}
                        onClick={(event) => handleSearch()}
                      >
                        <IoSearch />
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </th>
                <th>
                  <div className="sortParArr">{props.tr["Email"]}</div>
                  <div className="colSearDv ">
                    <Form.Control
                      className="colSearInp"
                      type="text"
                      value={
                        props.emailSearchInput != ""
                          ? props.emailSearchInput
                          : ""
                      }
                      placeholder={props.tr["Search here"]}
                      onKeyPress={(e) => {
                        if (e.charCode == "13") {
                          if (
                            props.emailSearchInput != "" &&
                            props.emailSearchInput.trim().length > 3
                          )
                            handleSearch();
                        }
                      }}
                      onChange={(event) => {
                        handleChange(event, "emailAddress");
                      }}
                      onClick={() => props.multiSelectOpenFun("allFalse", false)}
                    />
                    {props.emailSearchInput.trim().length > 3 ? (
                      <a
                        title="search"
                        className="faicn"
                        href={void 0}
                        onClick={(event) => handleSearch()}
                      >
                        <IoSearch />
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </th>
                <th>
                  <div className="sortParArr">{props.tr["Username"]}</div>
                  <div className="colSearDv ">
                    <Form.Control
                      className="colSearInp"
                      type="text"
                      value={
                        props.userNameSearchInput != ""
                          ? props.userNameSearchInput
                          : ""
                      }
                      placeholder={props.tr["Search here"]}
                      onKeyPress={(e) => {
                        if (e.charCode == "13") {
                          if (
                            props.userNameSearchInput != "" &&
                            props.userNameSearchInput.trim().length > 3
                          )
                            handleSearch();
                        }
                      }}
                      onChange={(event) => {
                        handleChange(event, "userName");
                      }}
                      onClick={() => props.multiSelectOpenFun("allFalse", false)}
                    />
                    {props.userNameSearchInput.trim().length > 3 ? (
                      <a
                        title="search"
                        className="faicn"
                        href={void 0}
                        onClick={(event) => handleSearch()}
                      >
                        <IoSearch />
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </th>
                <th>
                  <div className="sortParArr">{props.tr["Employee ID"]}</div>
                  <div className="colSearDv ">
                    <Form.Control
                      className="colSearInp"
                      type="text"
                      value={
                        props.employeeIdSearchInput != ""
                          ? props.employeeIdSearchInput
                          : ""
                      }
                      placeholder={props.tr["Search here"]}
                      onKeyPress={(e) => {
                        if (e.charCode == "13") {
                          if (
                            props.employeeIdSearchInput != "" &&
                            props.employeeIdSearchInput.trim().length > 3
                          )
                            handleSearch();
                        }
                      }}
                      onChange={(event) => {
                        handleChange(event, "employeeNumber");
                      }}
                      onClick={() => props.multiSelectOpenFun("allFalse", false)}
                    />
                    {props.employeeIdSearchInput.trim().length > 3 ? (
                      <a
                        title="search"
                        className="faicn"
                        href={void 0}
                        onClick={(event) => handleSearch()}
                      >
                        <IoSearch />
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </th>
                <th>
                  <div className="sortParArr">{props.tr["User Type"]}</div>
                  <div
                    id="userTypeDiv"
                    className="colSearDv"
                    ref={multiSelectUserTypeDropdownRef}
                    onClick={() => props.multiSelectOpenFun("fdnUserType", true)}
                  >
                    <ReactMultiSelectCheckboxes
                      placeholderButtonLabel={props.tr["Select"]}
                      rightAligned={true}
                      options={userTypeOptions}
                      onChange={handleUserTypeChange}
                      value={props.selectedUserTypeValue}
                    />
                  </div>
                </th>
                <th>
                  <div className="sortParArr">{props.tr["Status"]}</div>
                  <div
                    id="statusDiv"
                    className="colSearDv"
                    ref={multiSelectStatusDropdownRef}
                    onClick={() => props.multiSelectOpenFun("fdnUserStatus", true)}
                  >
                    <ReactMultiSelectCheckboxes
                      placeholderButtonLabel={props.tr["Select"]}
                      rightAligned={true}
                      options={statusOptions}
                      onChange={handleStatusChange}
                      value={props.selectedStatusValue}
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {userListRed.length === 0 || showLoader ? (
                <tr>
                  <td colSpan="7" style={{ textAlign: "center" }}>
                    <ListLoader />
                  </td>
                </tr>
              ) : userListRed.userList.length == 0 ? (
                <tr>
                  <td colSpan="7" style={{ textAlign: "center" }}>
                    {props.tr["There is no matching data available"]}
                  </td>
                </tr>
              ) : (
                renderUserList(userListRed.userList)
              )}
            </tbody>
          </Table>
        </div>

        {userListRed.userList && userListRed.userList.length !== 0 ? (
          <div className="nBotPagina">
            <div className="nShow">
              <div className="margin-r-10" componentClass={Form.Label}>
                {props.tr["Show"]}:
              </div>
              <div aria-label="Show Count" role="contentinfo">
                <Form.Select
                  aria-label={props.tr["Show"]}
                  className=""
                  onChange={handleSizePerPageChange}
                  value={props.limit}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </Form.Select>
              </div>
            </div>
            <nav aria-label="Pagination" className="display-inline-block">
              <Pagination
                prevPageText={props.tr["Prev"]}
                nextPageText={props.tr["Next"]}
                firstPageText={<i className="glyphicon glyphicon-menu-left" />}
                lastPageText={<i className="glyphicon glyphicon-menu-right" />}
                activePage={meta ? Number(meta.currentPage) : 1}
                itemsCountPerPage={props.limit}
                totalItemsCount={meta ? meta.rowCount : 1}
                pageRangeDisplayed={props.panelResize <= 42 ? 2 : 5}
                onChange={(e) => {handlePageChange(e);props.multiSelectOpenFun("allFalse", false)}}
              />
            </nav>
          </div>
        ) : (
          <span />
        )}
      </div>
    </div>
  );
};

export default UserList;
