
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button} from 'react-bootstrap';
import Cookies from 'universal-cookie';
import { navigationHooks } from '../../../helpers/NavigationHook';
import {HiOutlineRefresh, HiPlus} from "react-icons/hi";
import { getCompanyMenuListViewData } from '../../../actions/companyMenuList/companyMenuListAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import CommonHeader from '../CommonHeader';
const cookies = new Cookies();
let homepagelocation = cookies.get('gph');
if (homepagelocation) homepagelocation = homepagelocation.replace('s:', '');
if (homepagelocation) homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];


class CompanyMenuListHeader extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			category_name:'',
			sub_category_name:'',
			status:''
        };
		this.showHome = this.showHome.bind(this);
		this.getCIcategoryDetails = this.getCIcategoryDetails.bind(this);

	}
	showHome(){
		navigationHooks.navigate(homepagelocation);
	}
	getCIcategoryDetails(category_name, sub_category_name, status) {
		this.setState({
			category_name: category_name,
			sub_category_name: sub_category_name,
			status: status
		});
	}
	// refreshCategoryBoardTable =() => {
	// 	let filter = { page: 1, limit: 10 };
	// 	this.props.getCompanyMenuListViewData(filter);
	// }
	render() {
		return (
			<Row className="margin-b-15" style={{zIndex:1,position:"relative"}}>
        <Col lg={8} md={12} sm={12} xs={12}>
        <Col lg={4} md={6} sm={6} xs={5}>
            <h1 bsClass="" className="sPageHeading1">{["Company MenuList"]}</h1>
        </Col>
        </Col>
        <Col lg={4} md={12} sm={12} xs={12}>
            <div className="paHedFilter margin-xs-t-15">
                 <Link bsPrefix=' ' role="button" className="myBt plus fillBtn" title={['Create']} to="/createCompanyMenulist" ><HiPlus/></Link>
				 <Button bsPrefix=' ' title={["Refresh"]} role="button" bsStyle="" bsClass="" className="myBt" onClick={() => {this.props.showRightSideFun(false); this.props.resetFilters(); this.props.setItemsPerPage(10);}}><HiOutlineRefresh className="refreshFlip" /></Button>
			</div>
        </Col>
    </Row>);
	}
}
export function mapStateToProps(state) {
    return {
      translator: state.spcmReducer.tr
    };
  }
  
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getCompanyMenuListViewData }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(CompanyMenuListHeader);
