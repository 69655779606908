
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Cookies from 'universal-cookie';
import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import { MyContext } from '_MyContext';
import { Link } from "react-router-dom";

const cookies = new Cookies();
let homepagelocation = cookies.get('gph');
if (homepagelocation) homepagelocation = homepagelocation.replace('s:', '');
if (homepagelocation) homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];

if (homepagelocation !== "/home")
	homepagelocation = "/" + homepagelocation;

class MyProfileHeader extends React.Component {
	static contextType = MyContext;
	constructor(props) {
		super(props);
		this.showHome = this.showHome.bind(this);
	}
	componentDidMount() {
		this.context.keyDownHandler(this.closeButton,"",this.globalSearchRef,this.saveObjRef);
	}
	componentDidUpdate() {
		this.context.keyDownHandler(this.closeButton,"",this.globalSearchRef,this.saveObjRef);
	}

	showHome() {
		this.props.navigate(homepagelocation);
	}

	render() {
		return (
			<Row className="margin-b-15">
				<Col lg={4} md={6} sm={6} xs={6}>
					<h1 bsClass="" className="sPageHeading1">{this.props.tr['My Profile']}</h1>
				</Col>
				<Col lg={8} md={6} sm={6} xs={6}>
					<div className="paHedFilter">
						<Link to="javascript:void(0)" className="myBt plus" id="saveBtnObj"
							title={this.props.tr["Save"]}
							onClick={this.props.doPost}
							role="button"
							disabled={this.props.showLoader}
							ref={e => this.saveObjRef = e}
						>
							{this.props.showLoader ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/>}
						</Link>
						<Link to="javascript:void(0)" className="myBt cancel" id="closeButton"
							title={this.props.tr["Close"]}
							onClick={this.showHome}
							role="button"
							ref={e => this.closeButton = e}
						>
							<IoClose />
						</Link>
					</div>
				</Col>
			</Row>
		);
	}
}

export default MyProfileHeader;
