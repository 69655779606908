
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import{ GLOBAL } from '../../../src/components/Globals';

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

const hamburgerCategoryUrl = GLOBAL.getcategoryhomepage;

export function loadHamburgerCategorySuccess(hamburgerCategory) {
	return {
		type: 'FETCH_HAMBCATEGORYLIST',
		hamburgerCategory
	};
}

export function loadHamburgerCategory() {
	let str = {};
	str.companyId = "";
	str = JSON.stringify(str);
	return (dispatch) => {
		dispatch({ type: "ALLCATEGORIES_LOADER_IN_PROGRESS" });
		api.get(hamburgerCategoryUrl, { headers: { 'query': str } })
			.then((hamburgerCategory) => {
				if (!hamburgerCategory) {
					dispatch({ type: 'ALLCATEGORIES_LOADER_FETCHED_SUCCESSFULLY' });
					throw Error(hamburgerCategory.statusText);
				}
				return hamburgerCategory;
			})
			.then((hamburgerCategory) => {
				dispatch(loadHamburgerCategorySuccess(hamburgerCategory));
				dispatch({ type: 'ALLCATEGORIES_LOADER_FETCHED_SUCCESSFULLY' });
			})
			.catch((err) => {

			});
	};
}

export function storeCategoryName(categoryName) {
	return (dispatch) => {
		dispatch({ type: "CATEGORY_NAME", categoryName });
	};
}