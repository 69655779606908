
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useDispatch, useSelector } from "react-redux";

import PindCategories from "../../common/header/pind-categories";
import Breadcrumbs from "../../common/header/breadcrumbs";
import MyAssetHeader from "./MyAssetHeader";
import MyAssetShortDesc from "./MyAssetShortDesc";
import MyAssetList from "./MyAssetList";
import {
  loadCmdbItemList,
  loadStatusAndSubStatusOptions,
} from "../../../actions/cmdb/quickViewAction";
import { loadCIClass } from "../../../actions/cmdb/lovAction";
import "_Css/common/_table.scss";
import "_Css/form/_form.scss";
import Cookies from "universal-cookie";
const cookies = new Cookies();

let cookiesData = cookies.get("gph");
if (cookiesData) cookiesData = cookiesData.replace("s:", "");
if (cookiesData)
  cookiesData = cookiesData.substring(0, cookiesData.lastIndexOf("."));
cookiesData = cookiesData.split("~");
let loggedInUserId = Number(cookiesData[43]);

const MyAssetIndex = () => {
  const [filterTabActive, setFilterTabActive] = useState(1);
  const [isRightSideVisible, setRightSideVisible] = useState(false);
  const [CI_ID, setCI_ID] = useState("");
  const [CI_NAME, setCI_NAME] = useState("");
  const [GROUP_NAME, setGROUP_NAME] = useState("");
  const [OWNER_NAME, setOWNER_NAME] = useState("");
  const [LOCATION_NAME, setLOCATION_NAME] = useState("");
  const [ENVIRONMENT, setENVIRONMENT] = useState("");
  const [DATASET, setDATASET] = useState("");
  const [MANUFACTURER, setMANUFACTURER] = useState("");
  const [CI_CODE, setCI_CODE] = useState("");
  const [BUSINESS_OWNER_NAME, setBUSINESS_OWNER_NAME] = useState("");
  const [TECHNICAL_OWNER_NAME, setTECHNICAL_OWNER_NAME] = useState("");
  const [COMPANY_NAME, setCOMPANY_NAME] = useState("");
  const [CLASS_NAME, setCLASS_NAME] = useState("");
  const [STATUS_NAME, setSTATUS_NAME] = useState("");
  const [SUB_STATUS_NAME, setSUB_STATUS_NAME] = useState("");
  const [TYPE, setTYPE] = useState("");
  const [CATEGORY_NAME, setCATEGORY_NAME] = useState("");
  const [SUB_CATEGORY_NAME, setSUB_CATEGORY_NAME] = useState("");
  const [ASSET_TAG, setASSET_TAG] = useState("");
  const [METALLIC_VALUE, setMETALLIC_VALUE] = useState("");
  const [UPDATED_AT, setUPDATED_AT] = useState("");
  const [selectedTypeValue, setSelectedTypeValue] = useState([]);
  const [selectedStatusValue, setSelectedStatusValue] = useState([]);
  const [selectedClassValue, setSelectedClassValue] = useState([]);
  const [isClearAllFilter, setIsClearAllFilter] = useState(false);
  const [assetIdSearchInput, setAssetIdSearchInput] = useState("");
  const [nameSearchInput, setNameSearchInput] = useState("");
  const [companySearchInput, setCompanySearchInput] = useState("");
  const [searchParams, setSearchParams] = useState({ owner_id: loggedInUserId });
  const [status, setStatus] = useState([]);
  const [type, setType] = useState([]);
  const [searchClass, setSearchClass] = useState([]);
  const [startDate, setStartDate] = useState({});
  const [endDate, setEndDate] = useState({});
  const [rowIndex, setRowIndex] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [inputRef, setInputRef] = useState();
  const [isTypeOpen, setTypeOpen] = useState(false);
  const [isStatusOpen, setStatusOpen] = useState(false);
  const [isClassOpen, setClassOpen] = useState(false);

  const dispatch = useDispatch();
  const myAssetCIList = useSelector((state) => state.cmdbQuickItemList);
  const loader = useSelector((state) => state.showLoader.loading);

  useEffect(() => {
    console.log("CALLED")
    dispatch(loadCIClass());
    dispatch(loadStatusAndSubStatusOptions("Status List"));    
    dispatch(loadCmdbItemList(1, 10, { technical_owner_id: loggedInUserId }, [], [], [],[],"technical_owner_id"));
    dispatch(loadCmdbItemList(1, 10, { business_owner_id: loggedInUserId }, [], [], [],[],"business_owner_id"));
    dispatch(loadCmdbItemList(1, 10, { owner_id: loggedInUserId }, [], [], []));
  }, []);

  const showRightSideFun = (val) => {
    setRightSideVisible(val);
  };

  const getCMDBDetails = (
    CI_ID,
    CI_NAME,
    GROUP_NAME,
    OWNER_NAME,
    LOCATION_NAME,
    ENVIRONMENT,
    DATASET,
    MANUFACTURER,
    CI_CODE,
    BUSINESS_OWNER_NAME,
    TECHNICAL_OWNER_NAME,
    COMPANY_ID,
    CLASS_NAME,
    CLASS_ID,
    COMPANY_NAME,
    TYPE,
    STATUS_NAME,
    SUB_STATUS_NAME,
    UPDATED_AT,
    ASSET_TAG,
    CATEGORY_NAME,
    METALLIC_VALUE,
    SUB_CATEGORY_NAME
  ) => {
    setCI_ID(CI_ID);
    setCI_NAME(CI_NAME);
    setGROUP_NAME(GROUP_NAME);
    setOWNER_NAME(OWNER_NAME);
    setLOCATION_NAME(LOCATION_NAME);
    setLOCATION_NAME(LOCATION_NAME);
    setENVIRONMENT(ENVIRONMENT);
    setDATASET(DATASET);
    setMANUFACTURER(MANUFACTURER);
    setCI_CODE(CI_CODE);
    setBUSINESS_OWNER_NAME(BUSINESS_OWNER_NAME);
    setTECHNICAL_OWNER_NAME(TECHNICAL_OWNER_NAME);
    setCOMPANY_NAME(COMPANY_NAME);
    setTYPE(TYPE);
    setCLASS_NAME(CLASS_NAME);
    setSTATUS_NAME(STATUS_NAME);
    setSUB_STATUS_NAME(SUB_STATUS_NAME);
    setCATEGORY_NAME(CATEGORY_NAME);
    setSUB_CATEGORY_NAME(SUB_CATEGORY_NAME);
    setASSET_TAG(ASSET_TAG);
    setMETALLIC_VALUE(METALLIC_VALUE);
    setUPDATED_AT(UPDATED_AT);
  };

  let meta = !_.isEmpty(myAssetCIList) ? myAssetCIList.meta : {};
  let size = meta && meta.limit ? meta.limit : 0;
  let currentPage = meta && meta.currentPage ? meta.currentPage : 1;
  let startLimit = size * (currentPage - 1) + 1;
  let endLimit = size * currentPage;
  let rowCount = meta && meta.rowCount ? meta.rowCount : 0;

  const multiSelectOpenFun = (keyName, val) => {
		switch (keyName) {
		  case "myAssetClass": 
        {          
          setClassOpen(val);
          setTypeOpen(false);
          setStatusOpen(false);
        };
			break;
			case "myAssetType":
        {
          setClassOpen(false);
          setTypeOpen(val);
          setStatusOpen(false);
        };
			break;
			case "myAssetStatus":
        {
          setClassOpen(false);
          setTypeOpen(false);
          setStatusOpen(val);
        };
			break;
			case "allFalse":
        {
          setClassOpen(false);
          setTypeOpen(false);
          setStatusOpen(false);
        };
			break;
		}
  }

  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Row>
          <Col md={8} xs={9}>          
            <Breadcrumbs activePageName="My View" />
          </Col>
          <Col md={4} xs={3}>
            {myAssetCIList.meta && !loader && (
              <div className="tabDatacount">
                {startLimit}-{endLimit < rowCount ? endLimit : rowCount} of{" "}
                {rowCount}
              </div>
            )}
          </Col>
        </Row>       
      </Container>

      <Container fluid>
        <MyAssetHeader
          showRightSideFun={showRightSideFun}
          filterTabActive={filterTabActive}
          setFilterTabActive={setFilterTabActive}
          setAssetIdSearchInput={setAssetIdSearchInput}
          setNameSearchInput={setNameSearchInput}
          setCompanySearchInput={setCompanySearchInput}
          setSelectedClassValue={setSelectedClassValue}
          setSelectedStatusValue={setSelectedStatusValue}
          setSelectedTypeValue={setSelectedTypeValue}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          limit={limit}
          setLimit={setLimit}
          page={page}
          setPage={setPage}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          isClearAllFilter={isClearAllFilter}
          setIsClearAllFilter={setIsClearAllFilter}
          status={status}
          setStatus={setStatus}
          type={type}
          setType={setType}
          searchClass={searchClass}
          setSearchClass={setSearchClass}
          dateRangePickerRef={inputRef}
          setRef={setInputRef}
    />
        <PanelGroup direction="horizontal" className={(isTypeOpen || isStatusOpen || isClassOpen) && (myAssetCIList.result?.length <=6 ||
      loader) ? "overflow-clip" : ""}>
          <Panel
            id="sidebar"
            minSize={33}
            defaultSize={isRightSideVisible ? 67 : 100}
            className={ (isTypeOpen || isStatusOpen || isClassOpen) && (myAssetCIList.result?.length <=6 ||
              loader) ? "overflow-clip" :
              (isRightSideVisible ? "isShowLeftPanel catBordDv" : "catBordDv")
            }
          >
            <MyAssetList
              filterTabActive={filterTabActive}
              isRightSideVisible={isRightSideVisible}
              showRightSideFun={showRightSideFun}
              assetIdSearchInput={assetIdSearchInput}
              setAssetIdSearchInput={setAssetIdSearchInput}
              nameSearchInput={nameSearchInput}
              setNameSearchInput={setNameSearchInput}
              companySearchInput={companySearchInput}
              setCompanySearchInput={setCompanySearchInput}
              selectedClassValue={selectedClassValue}
              setSelectedClassValue={setSelectedClassValue}
              selectedStatusValue={selectedStatusValue}
              setSelectedStatusValue={setSelectedStatusValue}
              selectedTypeValue={selectedTypeValue}
              setSelectedTypeValue={setSelectedTypeValue}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              limit={limit}
              setLimit={setLimit}
              page={page}
              setPage={setPage}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              isClearAllFilter={isClearAllFilter}
              setIsClearAllFilter={setIsClearAllFilter}
              rowIndex={rowIndex}
              setRowIndex={setRowIndex}
              getCMDBDetails={getCMDBDetails}
              status={status}
              setStatus={setStatus}
              type={type}
              setType={setType}
              searchClass={searchClass}
              setSearchClass={setSearchClass}
              dateRangePickerRef={inputRef}
              setRef={setInputRef}
              multiSelectOpenFun={multiSelectOpenFun}
              isTypeOpen={isTypeOpen}
              isStatusOpen={isStatusOpen}
              isClassOpen={isClassOpen}
            />
          </Panel>
          {isRightSideVisible ? (
            <>
              <PanelResizeHandle className="isShowLeftPanel resizeHandle">
                <div className="outlne">
                  <div className="handIcn">
                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                  </div>
                </div>
              </PanelResizeHandle>
              <Panel minSize={33} order={2} defaultSize={33}>
                <div className="stickyArea rBoxStyle">
                  <MyAssetShortDesc
                    showRightSideFun={showRightSideFun}
                    CI_ID={CI_ID}
                    CI_NAME={CI_NAME}
                    GROUP_NAME={GROUP_NAME}
                    OWNER_NAME={OWNER_NAME}
                    LOCATION_NAME={LOCATION_NAME}
                    ENVIRONMENT={ENVIRONMENT}
                    DATASET={DATASET}
                    MANUFACTURER={MANUFACTURER}
                    CI_CODE={CI_CODE}
                    BUSINESS_OWNER_NAME={BUSINESS_OWNER_NAME}
                    TECHNICAL_OWNER_NAME={TECHNICAL_OWNER_NAME}
                    COMPANY_NAME={COMPANY_NAME}
                    TYPE={TYPE}
                    STATUS_NAME={STATUS_NAME}
                    SUB_STATUS_NAME={SUB_STATUS_NAME}
                    UPDATED_AT={UPDATED_AT}
                    ASSET_TAG={ASSET_TAG}
                    CLASS_NAME={CLASS_NAME}
                    CATEGORY_NAME={CATEGORY_NAME}
                    SUB_CATEGORY_NAME={SUB_CATEGORY_NAME}
                    METALLIC_VALUE={METALLIC_VALUE}
                  />
                </div>
              </Panel>
            </>
          ) : (
            ""
          )}
        </PanelGroup>
      </Container>
    </main>
  );
};

export default MyAssetIndex;
