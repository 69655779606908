
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState} from "react";
import {Accordion, Tab,Tabs} from 'react-bootstrap';
import {useSelector } from "react-redux";

import AssociateCompanies from "./associate-companies";
import AssociateGroups from "./associate-groups";
import CloseBtnRightPanel from "../../../../common/closeBtnRightPanel";

const TabsComponent = (props) => {
    const tr = useSelector((state) => state.spcmReducer.tr);
    const [associatedGroupValue, setAssociatedGroupValue] = useState([]);
    return (
        <div className="hambTab userInfoPopupParent">
            <CloseBtnRightPanel showRightSide={props.showRightSideFun} />
            <div className="rPageHeading">
                <div className="offNam margin-t-5 margin-b-5">
                {tr["Company Details"]}
                </div>
            </div>
            <div className="rBoxGap">
                <div className="rBoxTabs hideTabNav">
                    <Accordion
                  id="rightSideCompnies"
                  accordion
                  className="brkFix brkFixOrder"
                >
                    <Accordion.Item eventKey="1" className="collapsed" defaultActiveKey="0">
                        <Accordion.Header>
                          <h4 toggle>
                            {tr["Associate Companies"]}
                          </h4>
                        </Accordion.Header>
                        <Accordion.Body collapsible>
                          <AssociateCompanies
                                isSuperAdmin={props.isSuperAdmin}
                                companyValue={props.companyValue} 
                                tr={tr}
                                setAssociatedGroupValue={setAssociatedGroupValue}
                            />
                        
                        </Accordion.Body>
                      </Accordion.Item>
                        <Accordion.Item eventKey="2" className="collapsed">
                        <Accordion.Header>
                          <h4 toggle>
                            {tr["Associate Groups"]}
                          </h4>
                        </Accordion.Header>
                        <Accordion.Body collapsible>
                          <AssociateGroups
                                tr={tr}
                                companyValue={props.companyValue}
                                setAssociatedGroupValue={setAssociatedGroupValue}
                                associatedGroupValue={associatedGroupValue} />
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                </div>
            </div>
        </div>
    )
}

export default TabsComponent;