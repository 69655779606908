
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { useNavigate } from "react-router-dom";
import MyDelegationIndex from "./MyDelegationIndex";

const MyDelegationMain = (props)=>{
    const navigate = useNavigate();
    return (<MyDelegationIndex navigate={navigate}/>);  
}

export default MyDelegationMain;