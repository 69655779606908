
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import App from "./components/App";
import Home from "./components/serviceCatalog/Home";
import Cookies from "universal-cookie";
import MyApprovalIndex from "./components/myApprovals/MyApprovalIndex";
import MyProfileMain from "./components/myProfile/MyProfileMain";
import MyDelegationMain from "./components/myDelegation/MyDelegationMain";
import CreateDelegateIndex from "./components/myDelegation/CreateDelegateIndex";
import IntegrationHubIndex from "./components/integrationHub/IntegrationHubIndex";
import CreateOutBoundConIndex from "./components/integrationHub/createOutboundConnection/CreateOutBoundConIndex";
import EditOutBoundConIndex from "./components/integrationHub/edit/EditOutBoundConIndex";

import PrivacyPolicy from "./components/privacyPolicy";
import SlaIndex from "./components/sla/list/SlaIndex";
import ServiceDeskMain from "./components/serviceDesk/serviceDeskMain";
import MySurveyMain from "./components/mySurvey/MySurveyMain";
import SlaWorkDayIndex from "./components/sla/slaWorkdaySchedules/list/SlaWorkDayIndex";
import SlaHolidayIndex from "./components/sla/slaHolidaySchedules/list/SlaHolidayIndex";
import SlaEditIndex from "./components/sla/edit/SlaEditIndex";
import Wiblist from "./components/wiblist";
import SlaDefCreateIndex from "./components/sla/create/SlaDefCreateIndex";
import SlaWorkdayScheduleEditIndex from "./components/sla/slaWorkdaySchedules/edit/SlaWorkdayScheduleEditIndex";
import SlaWorkdayScheduleCreateIndex from "./components/sla/slaWorkdaySchedules/create/SlaWorkdayScheduleCreateIndex";
import SlaHolidaySchEditIndex from "./components/sla/slaHolidaySchedules/edit/SlaHolidaySchEditIndex";
import SlaHolidaySchCreateIndex from "./components/sla/slaHolidaySchedules/create/SlaHolidaySchCreateIndex";
import NotificationBoardIndex from "./components/NotificationBoard/NotificationBoardIndex";
import NotificationTemplateEditIndex from "./components/NotificationBoard/Template/EditTemplate/NotificationTemplateEditIndex";
import NotificationTemplateOverride from "./components/NotificationBoard/Template/CustomNotificationTemplate/customNotificationTemplateIndex.js";

import AmsHome from "./components/ams/AmsHomeIndex";
import AmsCreateRules from "./components/ams/amsCreateRules";
import AmsEditRules from "./components/ams/amsEditRules";
import MyRequestsIndex from "./components/myRequests/MyRequestsIndex";
import Unauthorized from "./components/authorization/Unauthorized";
import SessionTimeout from "./components/authorization/SessionTimeout";
import CreateIncident from "./components/WorkItemBoard/incident/create/CreateIncidentMain";
import EditIncident from "./components/WorkItemBoard/incident/edit/EditIncidentMain";
// import EditIncident from './components/WorkItemBoard/incident/edit/EditIncidentIndex';
import SystemConfigIndex from "./components/systemConfiguration/SystemConfigIndex";
import CreateMailboxConfigIndex from "./components/systemConfiguration/createMailboxConfigration/CreateMailboxConfigIndex";
import EditMailboxConfigIndex from "./components/systemConfiguration/edit/EditMailboxConfigIndex";
import CategoryBoardIndex from "./components/categoryBoard/CategoryBoardIndex";
// import CategoryBoardHeader from './components/categoryBoard/CategoryBoardHeader';
import CreateCategoryIndex from "./components/categoryBoard/categoryBoard/createCategory/CreateCategoryIndex";
import ReportConsoleIndex from "./components/ReportConsole/ReportConsoleIndex";
import SupersetDashboard from "./components/SupersetReport/reportDashboard";
import CreateDashboardIndex from "./components/supersetDashboardConfig/createSupersetDashboard/CreateDashboardIndex.js";
import EditDashboardIndex from "./components/supersetDashboardConfig/editSupersetDashboard/EditDashboardIndex";
import DashboardConfigIndex from "./components/supersetDashboardConfig/DashboardConfigIndex";
import ServiceDetailIndex from "./components/serviceBoard/ServiceDetailIndex";
import CmdbIndex from "./components/cmdb/list/CmdbIndex";
import CmdbCreateIndex from "./components/cmdb/create/CmdbCreateIndex";
import AddEditCmdb from "./components/cmdb/add-edit";
import CmdbEditIndex from "./components/cmdb/edit/CmdbEditIndex";
import OfferingDetailsIndex from "./components/serviceCatalog/offeringDetails/OfferingDetailsIndex";
import CheckoutIndex from "./components/serviceCatalog/checkout/leftSide/CheckoutIndex";
import Fulfillment from "./components/WorkItemBoard/fulfillment/FulfillmentIndex";
import EditFulfillmentMain from "./components/WorkItemBoard/fulfillment/EditFulfillmentMain";
import GlobalSearchIndex from "./components/common/header/GlobalSearch/GlobalSearchIndex";
import ShoppingCartIndex from "./components/serviceCatalog/shoppingCart/ShoppingCartIndex";
//import CreateChangeIndex from './components/WorkItemBoard/changeM/create/CreateChangeIndex';
import EditTaskIndex from "./components/WorkItemBoard/task/edit/EditTaskIndex";
import EditTaskMain from "./components/WorkItemBoard/task/edit/EditTaskMain";
import WibListIndex from "./components/WorkItemBoard/listView/WibListIndex";
import CreateChangeMain from "./components/WorkItemBoard/Change/create/xsm-changeCreate";
import ChangeEditMain from "./components/WorkItemBoard/Change/edit/xsm-changeEdit";
import InvestigationCreateMain from "./components/WorkItemBoard/Investigation/createInvestigation/InvestigationCreateMain";
import InvestigationEditMain from "./components/WorkItemBoard/Investigation/editInvestigation/investigationEditMain";
import EditCategoryIndex from "./components/categoryBoard/categoryBoard/editCategory/EditCategoryIndex";
import CreateCiCategoryIndex from "./components/categoryBoard/ciCategory/CreateCiCategory/CreateCiCategoryIndex";
import CiCategoryIndex from "./components/categoryBoard/ciCategory/CiCategoryIndex";
import CreateBusinessFunctionIndex from "./components/businessFunction/createBusinessFunction/CreateBusinessFunctionIndex";
import Tags from "./components/foundation/tags/index";
import CreateTag from "./components/foundation/tags/create/index";
import EditTag from "./components/foundation/tags/edit/index";
import CreateMemberTag from "./components/foundation/tags/createMember/index";
import CreateChecklistMemberTag from "./components/foundation/tags/createChecklistMember/index";
import BusinessFunctionListIndex from "./components/businessFunction/listViewBusinesFunction/BusinessFunctionListIndex";
import EditBusinessFunctionIndex from "./components/businessFunction/editBusinessFunction/EditBusinessFunctionIndex";
import XsmLogin from "./components/authorization/XsmLogin";
import NoDomain from "./components/authorization/NoDomain";
import UserActivationPending from "./components/authorization/UserActivationPending";
import AuthenticationError from "./components/authorization/AuthenticationError";
import Offline from "./components/authorization/Offline";
import CreateCiResolutionCategoryIndex from "./components/categoryBoard/ciResolutionCategory/CreateCiResolutionCategory/CreateCiResolutionCategoryIndex";
import OnBehalfCheckoutIndex from "./components/serviceCatalog/checkoutOnBehalf/leftSide/OnBehalfCheckoutIndex";
import OnBehalfOfferingDetailsIndex from "./components/serviceCatalog/offeringDetails/OnBehalfOfferingDetailsIndex";
import XSMConditionsOfUse from "./components/XSMConditionsOfUse";
import CmdbVisualCI from "./components/cmdb/edit/CmdbVisualCI";

import Company from "./components/admin/foundation/company/index";
import CreateCompany from "./components/admin/foundation/company/create/index";
import EditCompany from "./components/admin/foundation/company/edit/index";

import Organization from "./components/admin/foundation/organization/index";
import CreateOrganization from "./components/admin/foundation/organization/create/index";
import EditOrganization from "./components/admin/foundation/organization/edit/index";

import Department from "./components/admin/foundation/department/index";
import CreateDepartment from "./components/admin/foundation/department/create/index";
import EditDepartment from "./components/admin/foundation/department/edit/index";

import CostCenter from "./components/admin/foundation/costCenter/index";
import CreateCostCenter from "./components/admin/foundation/costCenter/create/index";
import EditCostCenter from "./components/admin/foundation/costCenter/edit/index";

import Group from "./components/admin/foundation/group/index";
import CreateGroup from "./components/admin/foundation/group/create/index";
import EditGroup from "./components/admin/foundation/group/edit/index";

import Location from "./components/admin/foundation/location/index";
import CreateLocation from "./components/admin/foundation/location/create/index";
import EditLocation from "./components/admin/foundation/location/edit/index";

import UserIndex from "./components/admin/foundation/user/UserIndex.js";
import CreateUser from "./components/admin/foundation/user/create/index";
import EditUser from "./components/admin/foundation/user/edit/index";
import Advertisement from "./components/admin/general/advertisement/AdvertisementIndex";
import CreateAdvertisement from "./components/admin/general/advertisement/create-edit/AdvertisementFormIndex";

import ExternalLinkIndex from "./components/admin/general/externalLink/ExternalLinkIndex.js";
import ExternalLinkFormIndex from "./components/admin/general/externalLink/create-edit/ExternalLinkFormIndex.js";

import Bulletin from "./components/admin/general/bulletin/BulletinIndex.js";
import CreateBulletin from "./components/admin/general/bulletin/create-edit/BulletinFormIndex.js";
import EditBulletin from "./components/admin/general/bulletin/create-edit/BulletinFormIndex.js";

import MultiSSOLogo from "./components/admin/general/multiSSOLogo/index";

import Forum from "./components/foundation/forum/index";
import CreateForum from "./components/foundation/forum/create/index";
import EditForum from "./components/foundation/forum/edit/index";

import CreateForumN from "./components/foundation/forum/add-edit/index";

import RulesList from "./components/rules/rulesIndex.js";
import RulesCreate from "./components/rules/rulesCreateIndex.js";
import RulesEdit from "./components/rules/rulesEditIndex.js";

import Roster from "./components/onCallSchedule/roster/list/rosterListIndex";
import Shift from "./components/onCallSchedule/shift/list/ShiftListIndex";
import Schedule from "./components/onCallSchedule/schedule/list/scheduleListIndex";
import Calendar from "./components/onCallSchedule/calendar/list/index";
// import Conflicts from "./components/onCallSchedule/conflicts/list/index";

import RosterCreate from "./components/onCallSchedule/roster/create/rosterCreateIndex";
import ShiftCreate from "./components/onCallSchedule/shift/create/CreateShiftIndex";
import ScheduleCreate from "./components/onCallSchedule/schedule/create/scheduleCreateIndex";

//escalation management urls--
import EscalationListIndex from "./components/escalation/list/EscalationListIndex.js";
import CreateEscalationIndex from "./components/escalation/create/CreateEscalationIndex.js";

//Meeting

import AddEditMeeting from "./components/governance-meeting/add-edit/index";
import OccurrenceDetails from "./components/governance-meeting/rightSide/occurence/list/details";
import GovernanceConsole from "./components/governance-meeting/console";

import ActionItemDetails from "./components/governance-meeting/rightSide/occurence/actionItems/addEdit/details";

import RosterEdit from "./components/onCallSchedule/roster/edit/rosterEditIndex";
import ShiftEditIndex from "./components/onCallSchedule/shift/edit/ShiftEditIndex";
import ScheduleEdit from "./components/onCallSchedule/schedule/edit/scheduleEditIndex";

////ActionITES

import AddEditActionItem from "./components/governance-meeting/actionItem/add-edit";
import ScoreCard from "./components/scoreCard";

import AdminPreferences from "./components/adminPreferences/adminPreferencesIndex";

import MyAssetIndex from "./components/myView/myAssets/MyAssetIndex.js";
import MyNotificationsIndex from "./components/myView/myNotifications/index.js";

import Themes from "./components/foundation/theme/index";
import EscalationLevelIndex from "./components/escalation/list/levels/EscalationLevelIndex.js";

import HamIndex from "./components/ham/ClassDefinition/HamIndex.js";
import CreateClassDefinitionIndex from "./components/ham/ClassDefinition/CreateClassDefinition/CreateClassDefinitionIndex.js";
import EditClassDefinitionIndex from "./components/ham/ClassDefinition/editClassDefinition/editClassDefinitionIndex.js"


import RulesListComponent from "./components/Rules-query/list";
import AddEditRule from "./components/Rules-query/add-edit";
import CompanyMenulistIndex from "./components/companyMenuList/ciCategory/CompanyMenuListIndex.js";
import CreateCompanyMenulistIndex from "./components/companyMenuList/ciCategory/Create/CreateMenuListIndex.js";

 //CONTRACT

 import CreateIndex from "./components/ham/contractmgmt/create/index.js";
 import EditIndex from "./components/ham/contractmgmt/edit/index.js";
 import Index from "./components/ham/contractmgmt/list/index.js";
import SchedulerListIndex from "./components/scheduler/list/SchedulerListIndex.js";
import SchedulerEditIndex from "./components/scheduler/edit/SchedulerEditIndex.js";

import ToaIndex from "./components/dataDictionary/tableOfAtrributes/index.js";
import TOAIndex from "./components/dataDictionary/tableOfAtrributes/edit/index.js";
import TotIndex from "./components/dataDictionary/tableOfTables/index.js";
import TOTEditIndex from "./components/dataDictionary/tableOfTables/edit/index.js";
import GetGDS from "./components/goldenDataset/pullGDS/index.js";
import PendingActions from "./components/goldenDataset/pendingActions/index.js";

import Studio from "./components/uiBuilder/studio/studio.js";
import FormsList from "./components/uiBuilder/list";
import ConnectorIndex from "./components/uiBuilder/studio/connectors/index.js";
import CreateUpdateConnector from "./components/uiBuilder/studio/connectors/createUpdate.js";
import View from "./components/uiBuilder/renderer/View.js";

import FormView from "./components/formBuilderTemplate/FormView.js";
import LanguageListIndex from "./components/formBuilderTemplate/form-builder-language/list/LanguageListIndex.js";
import AddLanguageIndex from "./components/formBuilderTemplate/form-builder-language/create/AddLanguageIndex.js";
import SystemConstantIndex from "./components/uiBuilder/studio/systemConstant/index.js";


import MyWalkUpReq from "./components/myView/myWalkUp/index.js";
import SmartCat from "./samrtCat.js";

const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];

const queryClient = new QueryClient();

const saveTemplate = (res)=>{
  console.log("==",res);
}

export default function NRoutes() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          <Route path="/" element={<App />}>
            {/* <Route index element={homepagelocation === "/quickViewBreakFix" ? <BreakFixQuickView /> : <Home />} /> */}
            <Route path="quickViewBreakFix" element={<WibListIndex />} />
            <Route path="classDefinition" element={<HamIndex />} />
            <Route path="createClassDefinition" element={<CreateClassDefinitionIndex />} />
            <Route path="editClassDefinition/:class_id" element={<EditClassDefinitionIndex />} />

            {/* <Route path="quickViewBreakFix" element={<CreateBusinessFunctionIndex />} /> */}
            <Route
              path="investigationCreate"
              element={<InvestigationCreateMain />}
            />
            <Route
              path="investigationEdit/:investigationId"
              element={<InvestigationEditMain />}
            />
            <Route path="myApprovals" element={<MyApprovalIndex />} />
            <Route path="home" element={<Home />} />
            <Route path="serviceDesk" element={<ServiceDeskMain />} />
            <Route path="reviews" element={<MySurveyMain />} />
            <Route path="myProfile" element={<MyProfileMain />} />
            <Route path="delegate" element={<MyDelegationMain />} />
            <Route path="createDelegate" element={<CreateDelegateIndex />} />
            <Route path="integHub" element={<IntegrationHubIndex />} />
            <Route
              path="createOutboundConnection"
              element={<CreateOutBoundConIndex />}
            />
            <Route
              path="editIntegrationHub/:id"
              element={<EditOutBoundConIndex />}
            />
            <Route path="conditions-of-use" element={<XSMConditionsOfUse />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="slaDeflist" element={<SlaIndex />} />
            <Route path="slaWorkdaylist" element={<SlaWorkDayIndex />} />
            <Route path="slaHolidaylist" element={<SlaHolidayIndex />} />
            <Route path="editSlaDef/:slaDefId" element={<SlaEditIndex />} />
            <Route path="wiblist" element={<Wiblist />} />
            <Route path="createSlaDef" element={<SlaDefCreateIndex />} />
            <Route
              path="editWrkSch/:scheduleId"
              element={<SlaWorkdayScheduleEditIndex />}
            />
            <Route
              path="createWrkSch"
              element={<SlaWorkdayScheduleCreateIndex />}
            />
            <Route
              path="editHolidaySchedule/:scheduleId"
              element={<SlaHolidaySchEditIndex />}
            />
            <Route path="createHolSch" element={<SlaHolidaySchCreateIndex />} />
            <Route
              path="notificationBoard"
              element={<NotificationBoardIndex />}
            />
            <Route
              path="notificationEditTemplate/:id/:localeId"
              element={<NotificationTemplateEditIndex />}
            />
            <Route
              path="notificationOverrideTemplate/:id/:localeId"
              element={<NotificationTemplateOverride />}
            />
            <Route path="createHolSch" element={<SlaHolidaySchCreateIndex />} />
            <Route path="amsHome" element={<AmsHome />} />
            <Route path="amsCreateRules" element={<AmsCreateRules />} />
            <Route path="amsEditRules/:ruleId" element={<AmsEditRules />} />
            <Route path="myOrders" element={<MyRequestsIndex />} />
            <Route path="createBreakFix" element={<CreateIncident />} />
            <Route
              path="editBreakFix/:breakFixItemId"
              element={<EditIncident />}
            />
            <Route path="report" element={<ReportConsoleIndex />} />
            <Route path="reports" element={<SupersetDashboard />} />
            <Route
              path="reportsConfiguration"
              element={<DashboardConfigIndex />}
            />
            <Route
              path="createDashboardConfiguration"
              element={<CreateDashboardIndex />}
            />
            <Route
              path="editDashboardConfiguration/:id"
              element={<EditDashboardIndex />}
            />

            {/* <Route path="reports" element={<ReportConsoleIndex/>} />*/}
            <Route path="servicedetails" element={<ServiceDetailIndex />} />
            <Route path="categoryBoard" element={<CategoryBoardIndex />} />
            <Route path="CiCategory" element={<CiCategoryIndex />} />
            <Route path="createCategory" element={<CreateCategoryIndex />} />
            <Route path="cmdblist" element={<CmdbIndex />} />
            {/* <Route path="/createcmdb" element={<CmdbCreateIndex />} /> */}
            <Route path="/createcmdb" element={<AddEditCmdb/>}/>
            <Route path="editcmdb/:ciId" element={<CmdbEditIndex />} />
            <Route
              path="offeringDetails/:offeringId/:catname/:subcatname"
              element={<OfferingDetailsIndex />}
            />
            <Route
              path="orderNowOnBehalf/offeringDetails/:offeringId/:catname/:subcatname"
              element={<OnBehalfOfferingDetailsIndex />}
            />
            <Route path="cart/orderNow" element={<CheckoutIndex />} />
            <Route
              path="cart/orderNowOnBehalf"
              element={<OnBehalfCheckoutIndex />}
            />
            <Route
              path="editCategory/:categoryId/:catPinned"
              element={<EditCategoryIndex />}
            />
            <Route path="globalSearchList" element={<GlobalSearchIndex />} />
            <Route path="cart" element={<ShoppingCartIndex />} />
            {/* <Route path='fulFillment/:itemId' element={<Fulfillment/>}/> */}
            <Route
              path="fulFillment/:itemId"
              element={<EditFulfillmentMain />}
            />
            {/* <Route path="editTask/:taskId/:cId/:gId" element={<EditTaskIndex/>} /> */}
            <Route
              path="editTask/:taskId/:cId/:gId"
              element={<EditTaskMain />}
            />
            <Route path="createChange" element={<CreateChangeMain />} />
            <Route path="editChange/:changeId" element={<ChangeEditMain />} />
            <Route path="visualCI/:ciId" element={<CmdbVisualCI />} />
            <Route path="newCiCategory" element={<CreateCiCategoryIndex />} />
            <Route
              path="newCiResolutionCategory"
              element={<CreateCiResolutionCategoryIndex />}
            />

            <Route path="customAttribute" element={<Tags />} />
            <Route path="createCustomAttribute" element={<CreateTag />} />
            <Route path="editCustomAttribute/:tagId" element={<EditTag />} />
            <Route path="createCompositeMemberAttribute" element={<CreateMemberTag />} />
            <Route path="createChecklistMemberAttribute" element={<CreateChecklistMemberTag />} />
            <Route
              path="createBusinessFunction"
              element={<CreateBusinessFunctionIndex />}
            />

            <Route path="login" element={<XsmLogin />} />
            <Route path="unauthorized" element={<Unauthorized />} />
            <Route path="sessionTimeout" element={<SessionTimeout />} />
            <Route path="nodomain" element={<NoDomain />} />
            <Route
              path="userActivationPending"
              element={<UserActivationPending />}
            />
            <Route
              path="authenticationError"
              element={<AuthenticationError />}
            />
            <Route path="offline" element={<Offline />} />
            <Route
              path="businessFunction"
              element={<BusinessFunctionListIndex />}
            />
            <Route
              path="editBusinessFunction"
              element={<EditBusinessFunctionIndex />}
            />
            <Route path="systemConfig" element={<SystemConfigIndex />} />
            <Route
              path="createSystemConfig"
              element={<CreateMailboxConfigIndex />}
            />
            <Route
              path="editSystemConfig/:id"
              element={<EditMailboxConfigIndex />}
            />

            <Route path="rulesList" element={<RulesList />} />
            <Route path="rulesCreate" element={<RulesCreate />} />
            <Route path="rulesEdit/:ruleid" element={<RulesEdit />} />

            <Route path="onCallSchedule/roster" element={<Roster />} />
            <Route path="onCallSchedule/shift" element={<Shift />} />
            <Route path="onCallSchedule/schedule" element={<Schedule />} />
            <Route path="onCallSchedule/calendar" element={<Calendar />} />
            <Route path="escalation" element={<EscalationListIndex />} />
            <Route path="escalation/createEscalation" element={<CreateEscalationIndex />} />
            <Route path="escalation/levels/:planId" element={<EscalationLevelIndex/>}/>
            {/* <Route path="onCallSchedule/conflicts" element={<Conflicts />} /> */}

            <Route
              path="onCallSchedule/createRoster"
              element={<RosterCreate />}
            />
            <Route
              path="onCallSchedule/createShift"
              element={<ShiftCreate />}
            />
            <Route
              path="onCallSchedule/createSchedule"
              element={<ScheduleCreate />}
            />

            <Route
              path="onCallSchedule/editRoster/:rosterId"
              element={<RosterEdit />}
            />
            <Route
              path="onCallSchedule/editShift/:shiftId"
              element={<ShiftEditIndex />}
            />
            <Route
              path="onCallSchedule/editSchedule/:scheduleId"
              element={<ScheduleEdit />}
            />

            {/* Admin Pages*/}
            <Route path="Company" element={<Company />} />
            <Route path="createCompany" element={<CreateCompany />} />
            <Route path="editCompany/:id" element={<EditCompany />} />

            <Route path="organization" element={<Organization />} />
            <Route path="createOrganization" element={<CreateOrganization />} />
            <Route path="editOrganization/:id" element={<EditOrganization />} />

            <Route path="department" element={<Department />} />
            <Route path="createDepartment" element={<CreateDepartment />} />
            <Route path="editDepartment/:id" element={<EditDepartment />} />

            <Route path="costcenter" element={<CostCenter />} />
            <Route path="createCostCenter" element={<CreateCostCenter />} />
            <Route path="editCostCenter/:id" element={<EditCostCenter />} />

            <Route path="Group" element={<Group />} />
            <Route path="createGroup" element={<CreateGroup />} />
            <Route path="editGroup/:id" element={<EditGroup />} />

            <Route path="location" element={<Location />} />
            <Route path="createLocation" element={<CreateLocation />} />
            <Route path="editLocation/:id" element={<EditLocation />} />

            <Route path="forum" element={<Forum />} />
            <Route path="createForum" element={<CreateForumN />} />
            <Route path="editforum/:forumId" element={<CreateForumN />} />
            {/* EditForum */}
            <Route path="actionItems" element={<AddEditActionItem />} />
            <Route path="add-edit/action" element={<AddEditActionItem />} />
            <Route
              path="add-edit/action/:actionId"
              element={<AddEditActionItem />}
            />

            <Route path="add-edit/meeting" element={<AddEditMeeting />} />
            <Route
              path="add-edit/meeting/:meetingId"
              element={<AddEditMeeting />}
            />
            <Route
              path="add-edit/meeting/occurence-details/:occurenceId"
              element={<OccurrenceDetails />}
            />
            <Route path="consolidatedView" element={<ScoreCard />} />

            <Route path="User" element={<UserIndex />} />
            <Route path="createUser" element={<CreateUser />} />
            <Route path="editUser/:id" element={<EditUser />} />
            <Route path="advertisement" element={<Advertisement />} />
            <Route
              path="createAdvertisement"
              element={<CreateAdvertisement />}
            />
            <Route path="editAdvertisement" element={<CreateAdvertisement />} />

            <Route path="externalLink" element={<ExternalLinkIndex />} />
            <Route
              path="createExternalLink"
              element={<ExternalLinkFormIndex />}
            />
            <Route
              path="editExternalLink"
              element={<ExternalLinkFormIndex />}
            />

            <Route path="bulletin" element={<Bulletin />} />
            <Route path="createBulletin" element={<CreateBulletin />} />
            <Route path="editBulletin" element={<EditBulletin />} />
            <Route
              path="add-edit/meeting/action-item-details/:actionId"
              element={<ActionItemDetails />}
            />

            <Route
              path="meeting/governance-console"
              element={<GovernanceConsole />}
            />
            <Route path="multiSSOLogo" element={<MultiSSOLogo />} />

            <Route path="adminPreferences" element={<AdminPreferences />} />
            <Route path="themes" element={<Themes />} />
            <Route path="myView" element={<MyRequestsIndex />} />
            <Route path="myNotifications" element={<MyNotificationsIndex />} />
            <Route path="myAssets" element={<MyAssetIndex />} />
            <Route path="walkUpReq" element={<MyWalkUpReq />} />
            <Route path="rules" element={<RulesListComponent />} />
            <Route path="createRule" element={<AddEditRule />} />
            <Route path="editRule/:ruleId" element={<AddEditRule />} />
            <Route path="CompanyMenulist" element={<CompanyMenulistIndex />} />
            <Route path="createCompanyMenulist" element={<CreateCompanyMenulistIndex />} />

            <Route path="createContract" element={<CreateIndex />} />
            <Route path="editContract/:CONTRACT_NUM" element={<EditIndex />} />
            <Route path="contracts" element={<Index />} />
            <Route path="scheduler" element={<SchedulerListIndex />} />
            <Route path="schedulerEdit/:id" element={<SchedulerEditIndex />} />

            

            {/* TOT and TOA*/}
            <Route path="toa" element={<ToaIndex />} />
            <Route path="toa/:id" element={<TOAIndex />} /> 

            <Route path="tot" element={<TotIndex />} />
            <Route path="tot/:id" element={<TOTEditIndex />} />
            <Route path="getGDS" element={<GetGDS />} />
            <Route path="pendingActions" element={<PendingActions />} /> 

            {/* Form-Builder*/}
            <Route path="uiBuilder" element={<Studio onSave={saveTemplate} />} />
            <Route path="uiBuilder/:formId" element={<Studio onSave={saveTemplate} />} />
            <Route path="formLists" element={<FormsList />} />
            <Route path="renderedView/:id" element={<View />}/>
            <Route path="renderedView/:id/:dataId" element={<View />}/>

            <Route path="preview/:id" element={<FormView />} />
            <Route path="languages" element={<LanguageListIndex />} />
            <Route path="addLanguage" element={<AddLanguageIndex />} />
            <Route path="addLanguage/:languageId" element={<AddLanguageIndex />} />
            <Route path="connectors" element={<ConnectorIndex />} />
            <Route path="connectors/create" element={<CreateUpdateConnector />} />
            <Route path="connectors/:id" element={<CreateUpdateConnector />} />
            
            <Route path="systemConstants" element={<SystemConstantIndex />} />
            <Route path="smart-cat" element={<SmartCat />} />
          </Route>
        </Routes>

      </Router>
    </QueryClientProvider>
  );
}
