
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import { GLOBAL }  from '../../components/Globals';

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

export function loadBreakFixUrgencySuccess(breakFixUrgencyName){
	return {
		type: 'LOAD_BREAKFIXURGENCY_SUCCESS',
		breakFixUrgencyName
	};
}

export function loadBreakFixUrgencyFailure(errorResponse){
	return {
		type: 'LOAD_BREAKFIXURGENCY_FAILURE',
		errorResponse
	};
}

export function loadBreakFixUrgency(){
	return(dispatch) =>{
			api.get(GLOBAL.breakFixUrgencyUrl)
				.then((breakFixUrgencyName)=>{
					if(!breakFixUrgencyName){
						throw Error(breakFixUrgencyName.statusText);
					}
					return breakFixUrgencyName;
				})
				.then((breakFixUrgencyName) =>{
					dispatch(loadBreakFixUrgencySuccess(breakFixUrgencyName))
				})
				.catch((err) => {
					console.log(err);
				});

	};
}
