
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import { Dialog } from "@progress/kendo-react-dialogs";
import React, { useState, useEffect } from "react";

const CustomModal = ({ open, children, title, close, ...props }) => {
  const [visible, setVisible] = useState(false);
  const toggleDialog = () => {
    close(true);
    setVisible(!visible);
  }

  useEffect(() => {
    setVisible(open);
  }, [open])

  return (
    <>
      {visible && (
        <Dialog title={title} onClose={toggleDialog} minWidth={"50%"} height={"100vh"}>
          <div className="flex-grow-1 overflow-y-auto">
            {children}
          </div>
        </Dialog>
      )}
    </>
  )
}

export default CustomModal;
