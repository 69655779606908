
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import {useState,useEffect } from 'react';
import {useIdleTimer} from 'react-idle-timer'
import Cookies from 'universal-cookie';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { GLOBAL } from "../.././Globals";
const cookies = new Cookies();

let multitenantLogin = cookies.get('gph');
if (multitenantLogin) multitenantLogin = multitenantLogin.replace('s:', '');
if (multitenantLogin) {
  multitenantLogin = multitenantLogin.substring(0, multitenantLogin.lastIndexOf('.'));
  multitenantLogin = multitenantLogin.split('~');
  multitenantLogin = multitenantLogin[41];
}

let userLogoutUrl = cookies.get('gph');
if (userLogoutUrl) userLogoutUrl = userLogoutUrl.replace('s:', '');
if (userLogoutUrl) userLogoutUrl = userLogoutUrl.substring(0, userLogoutUrl.lastIndexOf('.'));
userLogoutUrl = userLogoutUrl.split("~");
userLogoutUrl = userLogoutUrl[44];

const api = axios.create({
    headers: { Pragma: "no-cache", "Cache-control": "no-store" },
  });


export default function useIdle({
  onIdle,
  onLogout,
  idleTime = 60000,

}){

    const [isIdle, setIsIdle] = useState();
    const navigate = useNavigate();

    const handleOnMessage = (message) => {
        if (message === 'logout') {
          if (multitenantLogin) {
            api.get(`/authentication/logout/${userLogoutUrl}`).then((response) => {
              GLOBAL.isLogout = 'true';
            });
            navigate('/sessionTimeout');
          } else {
            window.location.href = `/authentication/logout/${userLogoutUrl}`;
          }
        }
      };

      const handleOnIdle = (event) => {
        setIsIdle(true);
        console.log('Session_Timeout');
        console.log('Last Active', getLastActiveTime());
        onIdle();
      }

      
    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: idleTime,
        onIdle: handleOnIdle,
        onMessage: handleOnMessage,
        crossTab: true,
        syncTimers: 200,
        debounce: 500,
      });
    

      useEffect(() => {
        const handleStorageEvent = (event) => {
          if (event.key === 'logout') {
            handleOnMessage(event.key);
          }
        };
        window.addEventListener('storage', handleStorageEvent);
        return () => {
          window.removeEventListener('storage', handleStorageEvent);
        };
      }, []);

      return {
        getRemainingTime,
        getLastActiveTime,
        broadcastLogout: () => {
        window.localStorage.setItem('logout', Date.now());
        },
        isIdle,
      };
    }