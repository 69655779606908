
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from "axios";
import { navigationHooks } from "../../helpers/NavigationHook";
import Swal from "sweetalert2";
import { GLOBAL } from "_Globals";
const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

export const getContractList = (param) => {
  return (dispatch) => {
    dispatch({type:'LOADER_IN_PROGRESS'});
    api.get("/api/contracts")
      .then((response) => {
        // console.log("ContractAction1", response.data);
        dispatch({
          type: "CONTRACT_LIST_FETCHED_SUCCESSFULLY",
          payload: response.data,
        });
        dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
      })
      .catch((err) => {
        console.log(err);
        dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
      });
  };
};

export function saveContractData(data) {
  return (dispatch) => {
    api.post("/api/contracts", data)
      .then((response) => {
        console.log("respomseDept+++", response);
        if (response.status == 200) {
          Swal.fire({
            title: 'Contract Created Successfully',
          })
          navigationHooks.navigate("/contracts");
          } 
          dispatch(resetVendor());
          dispatch(resetCostCenter());
          dispatch(resetAdminList());
      })
      .catch((err) => {
        //console.log("ClassPostError", err)
        if (err.response.data.message == "Contract Already Exists") {
          Swal.fire({
            title: 'Contract Already Exists',
          })
          }

    });
  };
}

export const resetVendor = () => {
  return (dispatch) => {
    dispatch({ type: "EMPTY_CISUPCOMPANY_SUCCESS", payload: [] });
  };
};

export const resetCostCenter = () => {
  return (dispatch) => {
    dispatch({ type: "EMPTY_COST_CENTER", payload: [] });
  };
};

export const resetAdminList = () => {
  return (dispatch) => {
    dispatch({ type: "EMPTY_ADMINISTRATOR_GROUP_LIST", payload: [] });
  };
};


export function updateContractAction(contractNum) {
  return (dispatch) => {
    console.log(contractNum, "contractNum")
    api.patch("/api/contracts/"+contractNum.CONTRACT_NUM, contractNum)
      .then((response) => {
        console.log("respomse+++", response);
        if (response.status == 201) {
          Swal.fire({
              title: 'Contract Updated Successfully',
            })
            navigationHooks.navigate("/contracts");
        }
          else {
            navigationHooks.navigate("/contracts");
          }
      })
      .catch((err) => {
        console.log("UpdateContarct", err);
      });
  };
}

export const getContractPageDetails = (uuid) => {
  return (dispatch) => {
      dispatch({ type: 'CONTRACT_DATA_FETCHING'})
      api.get("/api/contractsByUuid/"+uuid)
        .then((response) => {
          dispatch({ type: 'CONTRACT_EDIT_DETAILS_FETCHED', payload: response.data });
          dispatch({ type: 'CONTRACT_DATA_FETCHED_SUCCESS'})

        })
        .catch((err) => {
          dispatch({ type: 'CONTRACT_DATA_FETCHED_FAILED'})
          console.log(err);
        });
    };
};

export const getBudgetList = (companyId, query) => {
  return (dispatch) => {
    let value = query.split("&")[0]
    dispatch({ type: "BUDGET_DATA_FETCHING" });
    if(value.length >=3){
    api.get(`/api/user/userInfo/${query}`)
      .then((response) => {
        // console.log("ContractAction1", response.data);
        dispatch({
          type: "BUDGET_LIST_FETCHED_SUCCESSFULLY",
          payload: response.data,
        });
        dispatch({ type: "BUDGET_DATA_FETCHED_SUCCESS" });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: "BUDGET_DATA_FETCHED_SUCCESS" });
      });
  };
}
};

export const administrator = (companyId, value) => {
  return (dispatch) => {
    dispatch({ type: "ADMINISTRATOR_DATA_FETCHING" });
    let str1 = {};
      str1.companyId = companyId;
      str1.like = value;
      str1 = JSON.stringify(str1);
      if (value.length >= 3){
    api.get("/api/companyBasedConsumerNew", { headers: { query: str1 } })
      .then((response) => {
        // console.log("ContractAction1", response.data);
        dispatch({
          type: "ADMINISTRATOR_LIST_FETCHED_SUCCESSFULLY",
          payload: response.data,
        });
        dispatch({ type: "ADMINISTRATOR_DATA_FETCHED_SUCCESS" });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: "ADMINISTRATOR_DATA_FETCHED_SUCCESS" });
      });
    }
  };
};

export const loadContractListSuccess = (contractList) => {
	return {
		type: 'LOAD_CONTRACT_SUCCESS',
		contractList:contractList.data
	};
}

export const loadContarctAttachmentList = (contractId) => {
	// console.log("contractId", contractId)
	return(dispatch) =>{
  
			// console.log("params found for action loadContractListSuccess")

			api.get("/api/attachments/contract/"+ parseInt(contractId, 10))
				.then((contractList) => {
					if (!contractList) {
						throw Error(contractList.statusText);
					}
					return contractList;
				})
				.then((contractList) => {
					dispatch(loadContractListSuccess(contractList))
				})
				.catch((err) => {
					console.log(err);
				});
	};
}

export const getContractAuditData = (contractId, sortOrder) => {
  console.log("contractId", contractId);
  return (dispatch) => {
    dispatch({ type: "CONTRACT_AUDIT_DATA_FETCHING" });
    api
      .get("/getContractAuditData/" + parseInt(contractId, 10) + "/" + sortOrder)
      .then((response) => {
        console.log('response audit', response.data);
        dispatch({ type: "CONTRACT_AUDIT_DATA_FETCHED", payload: response.data });
      })
      .catch((err) => {
        dispatch({ type: "CONTRACT_AUDIT_DATA_FETCHED_FAILED", payload: [] });
        console.log(err);
      });
  };
};
export const getContractRelationshipData = (ci_id, module, source) => {
  return (dispatch) => {
      axios.get(GLOBAL.contractRelatedUrl + '/'+ci_id+'/'+module+'/'+source)
          .then((response) => {
              if (!response) {
                  console.log('error loading contract relationship data...');
              } else {
                  if (response.status == "304" || response.status == 200) {
                      dispatch({
                          type: 'RELATIONSHIP_DATA_LOAD_SUCCESS',
                          contractData: response.data
                      });
                  }
              }
          })
  }
};

export function loadContractRelatedWorkDetailsSuccess(contractRelatedWorkDetails){
	//alert('in success')
	return {
		type: 'LOAD_CONTRACT_RELATED_WORK_DETAILS_SUCCESS',
		contractRelatedWorkDetails
	};
}

export function loadContractRelatedWorkDetailsFailure(errorResponse){
	return {
		type: 'LOAD_CONTRACT_RELATED_WORK_DETAILS_FAILURE',
		errorResponse
	};
}

export function loadContractRelatedWorkDetails(ci_id,moduleTab,source){
	return(dispatch) =>{
			dispatch({
				type: 'MENU_LOADER_IN_PROGRESS',
				payload: ''
			});
				let relatedurl="";
        if(moduleTab == 'ci')
          relatedurl="/api/config_items/relationships/"+ci_id+"/Contract/"+source;
        else
          relatedurl="/api/config_items/relationships/"+ci_id+"/"+moduleTab+"/"+source;

				api.get(relatedurl)
					.then((contractRelatedWorkDetails)=>{
						if(!contractRelatedWorkDetails){
							dispatch({type:'MENU_LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
							throw Error(contractRelatedWorkDetails.statusText);
						}
						return contractRelatedWorkDetails;
					})
					.then((contractRelatedWorkDetails) =>{//alert('yes it is')
						dispatch(loadContractRelatedWorkDetailsSuccess(contractRelatedWorkDetails));
						dispatch({type:'MENU_LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
					})
					.catch((err) => {
						dispatch({type:'MENU_LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
						console.log(err);
					});
	};
}

export const getadmingroup = (companyId,groupType) => {
  //console.log('getadmingroup details action');
  //console.log(companyId, "companyId");
  return (dispatch) => {
    axios.get('/api/getGroupFilterList/' + companyId + '/' + 80)
      .then((response) => {
        console.log('admin grp api response from action');
        console.log(response.data);
        dispatch({
          type: 'ADMINISTRATOR_GROUP_LIST',
          getadmingroup: response.data
        })
      })
  }
};