
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../../initialState';

export const foundation_tags_reducer = (state = initialState, action) => {
	switch (action.type) {
		case 'LOAD_TAG_COMPANY_SUCCESS':
			return Object.assign({}, state, { tagCompany: action.payload });
		case 'LOAD_BUSINESS_FUNCTION_SUCCESS':
			return Object.assign({}, state, { businessFunction: action.payload });
		case 'EMPTY_BUSINESS_FUNCTION_SUCCESS':
			return Object.assign({}, state, { businessFunction: [] });
		case 'LOAD_TAG_MODULE_SUCCESS':
			return Object.assign({}, state, { tagModule: action.payload });
		case 'LOAD_TAG_TYPE_SUCCESS':
			return Object.assign({}, state, { tagType: action.payload });
		case 'LOAD_TAG_DATA_VALIDATION_SUCCESS':
			return Object.assign({}, state, { tagDataValidation: action.payload });
		case 'LOAD_TAG_DATA_SOURCE_SUCCESS':
			return Object.assign({}, state, { tagDataSource: action.payload });
		case 'LOAD_ALL_COMPOSITE_TAGS_SUCCESS':
			return Object.assign({}, state, { allCompositeTags: action.payload });
		case 'LOAD_MEMBER_TAGS_FOR_COMPOSITE_SUCCESS':
			return Object.assign({}, state, { memberTagsForComposite: action.payload });
		case 'LOAD_MEMBER_TAGS_FOR_CHECKLIST_SUCCESS':
			return Object.assign({}, state, { memberTagsForChecklist: action.payload });
		case 'LOAD_ALL_CHECKLIST_TAGS_SUCCESS':
				return Object.assign({}, state, { allChecklistTags: action.payload });
		case 'EMPTY_ALL_TAG_FORM_FIELDS_SUCCESS':
			return Object.assign({}, state, {
				tagCompany: [],
				businessFunction: [],
				tagModule: [],
				tagType: [],
				tagDataValidation: [],
				tagDataSource: [],
				allCompositeTags: [],
				memberTagsForComposite: [],
				memberTagsForChecklist: [],
				allChecklistTags: []
			});
		case 'LOAD_ALL_TAGS_LIST_SUCCESS':
			return Object.assign({}, state, { allTagsList: action.payload });
		case 'LOAD_TAG_DETAILS_BY_TAG_ID_SUCCESS':
			return Object.assign({}, state, { tagDetailsByTagId: action.payload });
		case 'EMPTY_TAG_DETAILS_BY_TAG_ID_SUCCESS':
			return Object.assign({}, state, { tagDetailsByTagId: null });
		case 'LOAD_MEMBER_DEPENDENCY_DATA_SUCCESS':
			return Object.assign({}, state, { memberDependencyData: action.payload.data, memberDependencyMeta: action.payload.meta ?? {} });
		case 'LOAD_MEMBER_DEPENDENCY_PARENT_DATA_SUCCESS':
			return Object.assign({}, state, { memberDependencyParentData: action.payload.data });
		case 'CREATE_MEMBER_TAG_INITIAL_DETAILS_SUCCESS':
			return Object.assign({}, state, { createMemberTagInitialDetails: action.createMemberTagInitialDetails });
		case 'CREATE_CHECKLIST_MEMBER_TAG_INITIAL_DETAILS_SUCCESS':
			return Object.assign({}, state, { createChecklistMemberTagInitialDetails: action.createChecklistMemberTagInitialDetails });
		default:
			return state;
	}
}

export const foundation_tags_loader_reducer = (state = initialState, action) => {
	switch (action.type) {
		case 'LOAD_TAGS_LOADER_IN_PROGRESS':
			return Object.assign({}, state, { showTagsLoader: true });
		case 'LOAD_TAGS_LOADER_FINISHED':
			return Object.assign({}, state, { showTagsLoader: false });
		case 'LOAD_MEMBER_DEPENDENCY_LOADER_IN_PROGRESS':
			return Object.assign({}, state, { showMemberDependencyLoader: true });
		case 'LOAD_MEMBER_DEPENDENCY_LOADER_FINISHED':
			return Object.assign({}, state, { showMemberDependencyLoader: false });
		default:
			return state;
	}
}
