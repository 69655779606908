
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';


export const getDashboardReducerData = (state = initialState.dashboardConfigData.dashboardConfigList, action) => {
    switch (action.type) {
        case 'LOAD_DASHBOARD_CONFIG_LIST_SUCCESS':
            return Object.assign({}, state, { dashboardConfigList: action.payload });
        case 'LOAD_DASHBOARD_CONFIG_EDIT_DETAILS_SUCCESS':
            return Object.assign({}, state, { initialEditValues: action.payload });
        case '@@redux-form/SET_SUBMIT_FAILED':
            return Object.assign({}, state, { dashboardConfigSubmitError: action.error });
        case 'DASHBOARD_RESET_FORM':
            return Object.assign({}, state, { dashboardConfigSubmitError: false, initialEditValues: {}});
        default:
            return state;
    }
}
