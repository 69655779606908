
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Breadcrumb } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setWipSearchApply } from "../../../../actions/globalSearch/globalSearchAction";

import Cookies from "universal-cookie";
const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home") homepagelocation = "/" + homepagelocation;

const Breadcrumbs = ({
  breadCrumbs,
  udpateActiveMenuSubMenuItems,
  setActiveTab,
  setActiveTabId,
  setActiveTabEventKey,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const setSearchParam = () => {
    dispatch(setWipSearchApply(false));
  };
  const tr = useSelector((state) => state.spcmReducer.tr);
  return (
    <Breadcrumb className={(breadCrumbs.hierarchyType === "category" || breadCrumbs.hierarchyType === "subcategory") ?"vCatalogBreadcrumb" : ""}>
      <Breadcrumb.Item
        href={void 0}
        onClick={() => {
          navigate(homepagelocation);
          setSearchParam();
        }}
        title={tr["Home"]}
      >
        {tr["Home"]}
      </Breadcrumb.Item>
      {breadCrumbs.hierarchyType === "general" && (
        <Breadcrumb.Item active href={void 0} title={breadCrumbs.static} >
          {breadCrumbs.static}
        </Breadcrumb.Item>
      )}
      {breadCrumbs.hierarchyType === "businessFn" && (
        <Breadcrumb.Item active href={void 0} title={breadCrumbs.businessFn} >
          {breadCrumbs.businessFn}
        </Breadcrumb.Item>
      )}
      {breadCrumbs.hierarchyType === "category" && (
        <>
          <Breadcrumb.Item
            href={void 0}
            onClick={() => {
              udpateActiveMenuSubMenuItems(
                "businessFn",
                breadCrumbs.businessFnId,
                breadCrumbs.businessFn,
                breadCrumbs.businessFnEventKey,
                true
              );
              setActiveTab(breadCrumbs.businessFn);
              setActiveTabId(breadCrumbs.businessFnId);
              setActiveTabEventKey(breadCrumbs.businessFnEventKey);
            }}
            title={breadCrumbs.businessFn}
          >
            {breadCrumbs.businessFn}
          </Breadcrumb.Item>
          <Breadcrumb.Item active href={void 0} title={breadCrumbs.category} >
            {breadCrumbs.category}
          </Breadcrumb.Item>
        </>
      )}
      {breadCrumbs.hierarchyType === "subcategory" && (
        <>
          <Breadcrumb.Item
            href={void 0}
            onClick={() => {
              udpateActiveMenuSubMenuItems(
                "businessFn",
                breadCrumbs.businessFnId,
                breadCrumbs.businessFn,
                breadCrumbs.businessFnEventKey,
                true
              );
              setActiveTab(breadCrumbs.businessFn);
              setActiveTabId(breadCrumbs.businessFnId);
              setActiveTabEventKey(breadCrumbs.businessFnEventKey);
            }}
            title={breadCrumbs.businessFn}
          >
            {breadCrumbs.businessFn}
          </Breadcrumb.Item>
          <Breadcrumb.Item
            href={void 0}
            onClick={() => {
              udpateActiveMenuSubMenuItems(
                "categories",
                breadCrumbs.categoryId,
                breadCrumbs.category,
                breadCrumbs.categoryEventKey,
                true
              );
              setActiveTab(breadCrumbs.category);
              setActiveTabId(breadCrumbs.categoryId);
              setActiveTabEventKey(breadCrumbs.categoryEventKey);
            }}
            title={breadCrumbs.category}
          >
            {breadCrumbs.category}
          </Breadcrumb.Item>
          <Breadcrumb.Item active href={void 0} title={breadCrumbs.subCategory} >
            {breadCrumbs.subCategory}
          </Breadcrumb.Item>
        </>
      )}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
