
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, {useState, useEffect} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { renderResponseOptions } from './helper';

const QuestionAssessment = (props) => {
    // console.log('QuestionAssessment props - ', props);
    const { 
        translator = {}, 
        mode = 'add', 
        isEditable = true,
        questionaireList = [],
        updateQuestionaireResponse = () => {}
    } = props;
   
    return (
        <div>
            {(questionaireList && questionaireList.length > 0) && (
                <Row>
                    <Col md={12}>
                        <Row>
                            <Col xs={6} sm={6} md={6}><span className="rStar" />{translator["Question"]}</Col>
                            <Col xs={6} sm={6} md={6}><span className="rStar" />{translator["Response"]}</Col>
                        </Row>
                        {
                            questionaireList.map((item, index) => {
                                return(
                                    <Row key={`questionaireSavedAssessment_${index}`} >
                                        <Col  xs={6} sm={6} md={6}>
                                            <span>{item['questionName']}</span>
                                        </Col>
                                        <Col  xs={6} sm={6} md={6}>
                                            <Form.Select
                                            className='myControl myInput'
                                            componentClass="select"
                                            onChange={(e) => {
                                                if(!isEditable){
                                                    return;
                                                }
                                                updateQuestionaireResponse(item['question'], e.target.value, e.target[e.target.selectedIndex].text)
                                            }}
                                            value={item['response'] + '|' + item['responseWeightage']}
                                            disabled={!isEditable}
                                            >
                                                <option value="">Select</option>
                                                {renderResponseOptions((item && item['riskAnswer']) ? item['riskAnswer'] : [])}
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                );
                            })
                        }
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default QuestionAssessment;
