
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { reduxForm } from 'redux-form';
import { GLOBAL } from "_Globals";
import BreakFixHamburger from './BreakFixHamburger.js';
import BreakFixStatusHamburger from './BreakFixStatusHamburger.js';
import axios from 'axios';
// import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
// import { LinkContainer } from 'react-router-bootstrap';
import { Link, Link as LinkContainer } from 'react-router-dom';
import {LuActivitySquare, LuClipboardCheck, LuCopy,LuCalendarClock} from "react-icons/lu";
import {ImAttachment, ImSpinner6, ImCopy } from "react-icons/im";
import {PiBookOpenText, PiTagBold} from "react-icons/pi";
import {FaRegEye} from "react-icons/fa6";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import {LiaHourglass} from "react-icons/lia";
import {HiOutlineSpeakerphone,HiOutlineRefresh} from "react-icons/hi";
import { BiDotsVertical } from "react-icons/bi";
import { TbSubtask, TbCirclesRelation,TbTextWrap } from "react-icons/tb";
import { RiStackshareLine } from "react-icons/ri";
import ExportTo from '_Commons/WorkItemBoard/exportTo.js';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
import { resetBFQVFilters, fetchGroupList, setBreakfixQuickViewTableCarouselIndex } from '../../../../actions/breakFix/quickViewAction.js';
import { SubmissionError } from 'redux-form';
import { dispatchUpdatedBFDetails } from '../../../../actions/breakFix/breakFixEditDetailsAction.js';
import { bindActionCreators } from 'redux';
import { loadBreakFixEditDetails } from '../../../../actions/breakFix/breakFixEditDetailsAction.js';
import { loadStatusBasedHamburgerOptions } from '../../../../actions/common/loadStatusBasedHamburgerOptions.js';
import { loadTimelineData } from '../../../../actions/common/commonApisActions.js';
import { loadBreakFixQuickView } from '../../../../actions/breakFix/quickViewAction.js';
import swal from 'sweetalert';
import { loadBreakFixTaskLogDetails,loadTaskGroup2} from '../../../../actions/task/TaskDataAction.js';
import {setInitialwibGlobalSearchFilterData} from '../../../../actions/globalSearch/globalSearchAction.js';
import { navigationHooks } from '../../../../helpers/NavigationHook.js';
import { MyContext } from '_MyContext';
let isKnowledgeEnabled = cookies.get('gph');
if (isKnowledgeEnabled) isKnowledgeEnabled = isKnowledgeEnabled.replace('s:', '');
if (isKnowledgeEnabled) isKnowledgeEnabled = isKnowledgeEnabled.substring(0, isKnowledgeEnabled.lastIndexOf('.'));
isKnowledgeEnabled = isKnowledgeEnabled.split("~");
isKnowledgeEnabled = isKnowledgeEnabled[30];

let XsmSupportEditHeader = class XsmSupportEditHeader extends React.Component {
	static contextType = MyContext;
	constructor(props) {
		super(props);
		this.state = {
			hamburgerShowHide: false,
			statusHamburgerShowHide: false,
			d2cnumber: '',
			priorityColorType: '',
			disableCopyService: 0
		};
		this.callbackFun = this.callbackFun.bind(this);
		this.showCriticalIcon = this.showCriticalIcon.bind(this);
		this.submit = this.submit.bind(this);
		this.postActivityData = this.postActivityData.bind(this);
		this.callBreakFixApi = this.callBreakFixApi.bind(this);
		this.onChangeCopy = this.onChangeCopy.bind(this);
	}

	componentDidMount() {
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
		this.context.newTabPrevent();
	}
	componentDidUpdate() {
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
		this.context.newTabPrevent();
	}

	componentWillMount() {

		//getting color value from quickView
		GLOBAL.supportPGroupErrorColor="";
		//alert(this.props.breakFixEditDetails.urgency);
		//alert("criticalityValue"+criticalityValue)
		//console.log(criticalityValue)
		let priorityData = '';
		let priorityActualValue = '';
		let priorityActualColor = '';
		this.props.setPriorityCallFlag(true);
		//console.log("GLOBAL.priorityColorValue"+this.state.priorityColorType)

		if (GLOBAL.slaHeadEnable) {
			GLOBAL.processhierarchy = 0;
		}
	}

	submit(values) {
		try{
			console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",values,GLOBAL.AMSEXIST);
			console.log("MMM Submit GLOBAL.showGroupReassignReasonErrorColor: ", GLOBAL.showGroupReassignReasonErrorColor, " : ", values.groupReassignReason)
			GLOBAL.reopen = false;
			const categorizationErrors = {};
			for (const [key, value] of Object.entries(values).filter(([k]) => k.startsWith("opsCategoryId"))) {
			  if (value === null || value === undefined || value === '' || value === '0') {
				  categorizationErrors[key] = "error";
			  }
		  	}

		  GLOBAL.categorizationErrors = Object.keys(categorizationErrors).length > 0 ? categorizationErrors : {};

			const categorizationFields = Object.keys(values)
			.filter(key => key.startsWith("opsCategoryId") || key.startsWith("opsCategory"))
			.reduce((obj, key) => {
				obj[key] = values[key];
				return obj;
			}, {}) || {};

			//alert("this.props.reportedOn"+values.reportedOn);
			//alert("this.props.impactedServiceName"+values.impactedServiceName);
			//alert("values.issueDescription.length:"+values.issueDescription.length);
			//alert("values.issueDescription:"+values.issueDescription);
			//alert("edit");
			//alert(values.supportPGroup)
			// if(values.company!=this.props.breakFixEditDetails.consumerCompany){
			// 	let count=0;
			// 	if((GLOBAL.ciIdEdit==='' || GLOBAL.ciNameEdit===this.props.breakFixEditDetails.ciName) && this.props.breakFixEditDetails.serviceBased===false){
			//       count++;
			// 	  this.props.setErrorImpactedColor('error');
			// 	}
			// 	if(GLOBAL.breakFixConsumerID==='' || GLOBAL.breakFixConsumerName===this.props.breakFixEditDetails.impactedUsername){
			//       count++;
			// 	  this.props.setConsumerColor('error');
			// 	}
			// 	if((GLOBAL.breakFixServiceID==='' || GLOBAL.breakFixServiceName===this.props.breakFixEditDetails.serviceName) && this.props.breakFixEditDetails.serviceBased){
			// 	  count++;
			// 	  this.props.setErrorServiceColor('error');
			// 	}
			// 	if(values.supportPGroup==='' || !values.supportPGroup){
			// 	  count++;
			// 	  GLOBAL.supportPGroupErrorColor='error';
			// 	}
			// 	if(count>0){
			// 		throw new SubmissionError({ _error: 'The form has missing required fields, please revalidate the highlighted fields and update the form !' });
			// 	}
			// }else{
			// 	  this.props.setErrorImpactedColor('');
			// 	  this.props.setConsumerColor('');
			// 	  this.props.setErrorServiceColor('');
			// 	  GLOBAL.supportPGroupErrorColor='';
			// }
			if(values.impactedServiceName==='' && GLOBAL.changeValue===false && this.props.breakFixEditDetails.serviceBased){
				this.props.setErrorServiceColor('error');
			}
			let reff = this;
			let priorityActualValue = ''; let ciId = ""; let ciName = ""; let ciCode = ""; let flag = "N"; let deletedCiRelationId = ""; let relationRequired = "N";
			//alert(GLOBAL.priorityValueType);
			//alert("C:"+values.criticality)
			// if (GLOBAL.priorityValueType == "P1") {
			// 	priorityActualValue = 45;
			// } else if (GLOBAL.priorityValueType == "P2") {
			// 	priorityActualValue = 50;
			// }
			// else if (GLOBAL.priorityValueType == "P3") {
			// 	priorityActualValue = 55;
			// }
			// else if (GLOBAL.priorityValueType == "P4") {
			// 	priorityActualValue = 60;
			// } else {
			// 	priorityActualValue = 40;
			// }
			// console.log('values - ', {urgency: values.urgencyMode, criticality: values.criticality});
			priorityActualValue = values.priorityActualVal;

			let isCritical='';
			if(priorityActualValue==55||priorityActualValue==50||priorityActualValue==60){
				isCritical='No';
			}else if(GLOBAL.AcceptAsCritical=='Yes'){
				isCritical='Yes';  
			}else{
				isCritical='No';
			}
			let criticalityActualValue = '';
			let breakFixReportedOn = GLOBAL.breakFixReportedOn;
			let breakFixConsumer = GLOBAL.breakFixConsumerID;
			let breakFixService = GLOBAL.breakFixServiceID;
			let supportPgroupNameValue = "", supportPIGroupNameValue = "";
			// if (GLOBAL.supportPGroupName == "")
			// 	supportPgroupNameValue = GLOBAL.supportPGroupNameEdit;
			// else
			// 	supportPgroupNameValue = GLOBAL.supportPGroupName;
			// //let supportPgroupNameValue = GLOBAL.supportPGroupName;
			// if (GLOBAL.supportPIGroupName == "")
			// 	supportPIGroupNameValue = GLOBAL.supportPIGroupNameEdit;
			// else
			// 	supportPIGroupNameValue = GLOBAL.supportPIGroupName;
			// //  let supportPIGroupNameValue = GLOBAL.supportPIGroupName;
			// if(GLOBAL.individualBlank=='blank')
			// {
			// 	supportPIGroupNameValue="";
			// }

			if(GLOBAL.AMSEXIST == "yes"){
				supportPgroupNameValue = values.supportP1GroupName || '';
			}
			else{
				supportPgroupNameValue = values.supportPGroupName || '';
			}

			supportPIGroupNameValue = values.supportPIndividualName || '';


			//alert("GLOBAL.ciId===>"+GLOBAL.ciId);
			if (GLOBAL.ciId === "") {
				ciId = this.props.breakFixEditDetails.ciId;
				ciCode = this.props.breakFixEditDetails.ciCode;
				ciName = this.props.breakFixEditDetails.ciName;
			}
			else if (GLOBAL.ciId == "0" || GLOBAL.ciId == null) {
				//alert("T1==>"+GLOBAL.ciIdEdit)
				if (GLOBAL.ciIdEdit == "") {
					//alert("T3");
					GLOBAL.ciId = 0; GLOBAL.ciName = ""; GLOBAL.ciCode = "";
					flag = "N"; relationRequired = "N"
				} else {
					//alert("T4");
					ciId = GLOBAL.ciIdEdit; ciName = GLOBAL.ciNameEdit; ciCode = GLOBAL.ciIdNumberEdit;
					flag = "N"; relationRequired = "Y";
				}
			} else {
				//alert("T2==>"+GLOBAL.ciIdEdit);
				if (GLOBAL.ciIdEdit == "") {
					//alert("T5");
					ciId = GLOBAL.ciId; ciName = GLOBAL.ciName; ciCode = GLOBAL.ciIdNumber;
					flag = "N"; relationRequired = "N";
				} else {
					if (GLOBAL.ciId != GLOBAL.ciIdEdit) {
						//alert("T6");
						ciId = GLOBAL.ciIdEdit; ciName = GLOBAL.ciNameEdit; ciCode = GLOBAL.ciIdNumberEdit;
						flag = "Y"; deletedCiRelationId = GLOBAL.ciId; relationRequired = "N";
					} else if (GLOBAL.ciId == GLOBAL.ciIdEdit) {
						//alert("T7");
						ciId = GLOBAL.ciIdEdit; ciName = GLOBAL.ciNameEdit; ciCode = GLOBAL.ciIdNumberEdit;
						flag = "N"; relationRequired = "N";
					}
				}
			}

			if (`${JSON.stringify(values, null, 2)}` == "{}") {
				throw new SubmissionError({ _error: 'Please select a values!' });
			} else if(Object.keys(GLOBAL.categorizationErrors).length > 0){
				throw new SubmissionError({ _error: 'The form has missing required fields, please revalidate the highlighted fields and update the form !' })
			} else if (values.issueDescription == undefined || values.issueDescription == "") {
				//alert("HU2");

				throw new SubmissionError({ _error: 'The form has missing required fields, please revalidate the highlighted fields and update the form !' })
			} else if (values.issueDescription.length >2000) {
				//alert("HU2");
				throw new SubmissionError({ _error: 'Characters for issue description is more than 2000 characters!' })
			}/*else if (values.reportedOn=="" || values.reportedOn=='select' || values.reportedOn==undefined) {
			//alert("HU1");
			throw new SubmissionError({ _error: 'Please select reported on date !' })
			}*/
			else if (values.urgencyMode == "" || values.urgencyMode == 'Select..' || values.urgencyMode == undefined || values.criticality==='') {
				//alert("HU1");
				if(values.urgencyMode == "" || values.urgencyMode == 'Select..' || values.urgencyMode == undefined){
					this.props.setUrgencyError('error');
				}
				if(values.criticality===''){
					this.props.setImpactError('error');
				}
				throw new SubmissionError({ _error: 'The form has missing required fields, please revalidate the highlighted fields and update the form !' })
			}else  if(values.supportP1Group==""&&GLOBAL.AMSEXIST == "yes"||values.supportP1Group==undefined&&GLOBAL.AMSEXIST == "yes"||values.supportP1Group=="Select"&&GLOBAL.AMSEXIST == "yes")
			{
				GLOBAL.sgAllErrorColor="error";
				throw new SubmissionError({ _error: 'The form has missing required fields, please revalidate the highlighted fields and update the form !' });
			}
			else if (values.supportPGroup== "0"&&GLOBAL.AMSEXIST == "No"||values.supportPGroup==""&&GLOBAL.AMSEXIST == "No") {
				GLOBAL.sgErrorColor='error';
			throw new SubmissionError({ _error: 'The form has missing required fields, please revalidate the highlighted fields and update the form !' });
			
			}
			else if (GLOBAL.showGroupReassignReasonErrorColor && (!values.groupReassignReason || values.groupReassignReason=="")) {
				GLOBAL.GroupReassignReasonErrorColor='error';
				this.props.setGroupReasonErrorColor('error');
				throw new SubmissionError({ _error: 'The form has missing required fields, please revalidate the highlighted fields and update the form !' });
			} else {
				//alert("editok");
				//alert("values.impactedServiceName:"+values.impactedServiceName);
				//alert(`You submitted:\n\n${JSON.stringify(values, null, 2)}`);

				//alert("this.props.reportedOn"+reportedOn);
				//alert("this.props.breakFixConsumer"+breakFixConsumer);
				//let breakFixReportedOn = GLOBAL.breakFixReportedOn;
				//alert("values.criticality:"+values.criticality);
				//alert("edit:"+GLOBAL.breakFixEditUrl+GLOBAL.breakFixId)
				//axios.post(GLOBAL.breakFixCreateUrl, {


				if (values.supportPGroup == "0")
					values.supportPGroup = "0";
				// if (values.supportPIndividual == "0")
				// 	values.supportPIndividual = "0";
				if (supportPIGroupNameValue == "Select")
					supportPIGroupNameValue = "";

				if(values.supportPIndividual === "" || supportPIGroupNameValue === ""){
					values.supportPIndividual = "0";
					supportPIGroupNameValue = "";
				}

				if (ciId == "")
					ciId = "0";
				if (ciCode == "")
					ciCode = "0";
				//alert(values.supportPIndividual)
				//console.log("values.urgencyMode"+values.urgencyMode);
				//console.log("priorityActualValue"+priorityActualValue);
				//console.log("criticalityActualValue"+criticalityActualValue);
				let itemId = ""; let itemNumber = ""; let reportedOn = ""; let createdBy = "";
				let blank;
				let postjson = {};
				console.log("consumersss",breakFixConsumer,GLOBAL.breakFixConsumerName);
				//alert("-----"+ciName)
				let tempIsExternal="";
				if(this.props.breakFixEditDetails.isExternal==true || this.props.breakFixEditDetails.isExternal=="true"){
				tempIsExternal=this.props.breakFixEditDetails.isExternal;
				} else if(this.props.breakFixEditDetails.isExternal=="" || this.props.breakFixEditDetails.isExternal==false || this.props.breakFixEditDetails.isExternal==undefined || this.props.breakFixEditDetails.isExternal==null){
				tempIsExternal = (GLOBAL.isExternalIncidentEdit=="" || GLOBAL.isExternalIncidentEdit==null || GLOBAL.isExternalIncidentEdit==undefined) ? false: GLOBAL.isExternalIncidentEdit;
				}
				let impactedCIcompanyID = GLOBAL.ciConsumerCompany===''? this.props.breakFixEditDetails.ciConsumerCompany : GLOBAL.ciConsumerCompany;
				if (ciName == "" || ciName == null) {
					//alert(ciName)
					postjson = {
						//======================

						//"serviceCriticality" : values.criticality,
						...categorizationFields,
						"serviceCriticality": values.criticality,
						"criticality":priorityActualValue==55||priorityActualValue==50||priorityActualValue==60?"No":GLOBAL.AcceptAsCritical=="Yes"?"Yes":"No",
						"impactTo": "S",
						"description": values.issueDescription,
						"additionalInfo": values.additionalInfo ? values.additionalInfo : '',
						"spGroupid":GLOBAL.AMSEXIST == "yes"?values.supportP1Group:values.supportPGroup,
						"spUserid": values.supportPIndividual,
						"soGroupid": "0",
						"soUserid": "0",
						//"status" : "10", //remove this check if it is not there then form will not be submitted
						"priorityId" : priorityActualValue,
						"priorityValue": values.priorityLabel,
						//"criticalJustification" : "",
						//"holdJustification" : "",
						//"urgencyJustification" : "",
						"cause": 1,
						"criticalityStatus":priorityActualValue==55||priorityActualValue==50||priorityActualValue==60?null:blank,
						//"slaStatus" :"",
						//"expectedDate" : "",
						//"createdOn" : "",
						//"createdBy" : "3142", //user id
						"modifiedBy": "", //user id
						"modifiedByName": "",
						"reportedThrough": values.reportedThrough,
						"urgency" : values.urgencyMode,
						"spGroupname": supportPgroupNameValue,
						"spUsername": supportPIGroupNameValue,
						"serviceId" :GLOBAL.changeValue==true?GLOBAL.breakFixServiceID:values.impactedServiceId,
						"serviceName":GLOBAL.changeValue==true?GLOBAL.breakFixServiceName:values.impactedServiceName,
						"impactedUserid" :  GLOBAL.breakFixConsumerID===''?this.props.breakFixEditDetails.impactedUserid:GLOBAL.breakFixConsumerID,
						"impactedUsername" : GLOBAL.breakFixConsumerName===''?this.props.breakFixEditDetails.impactedUsername:GLOBAL.breakFixConsumerName,
						"consumerCompany":values.company,
						"consumerCompanyName":values.companyName,
						"isExternal" : tempIsExternal,
						//======================
						//description:values.description,
						//serviceArea:values.subServiceArea,
						//component:values.component,
						//service:values.service,
						//status:"10",
						//createdBy:"SX_Admin",
						//"ciId":ciId,
						//"ciName":ciName,
						//"ciCode":ciCode
						"cimGroup": this.props.breakFixEditDetails.cimGroup,
						"cimGroupname":this.props.breakFixEditDetails.cimGroupname,
						"cimUser":this.props.breakFixEditDetails.cimUser,
						"cimUsername":this.props.breakFixEditDetails.cimUsername
					};

				} else {
					//alert("===="+ciName)
					postjson = {
						//======================

						//"serviceCriticality" : values.criticality,
						...categorizationFields,
						"serviceCriticality" : values.criticality,
						"impactTo": "S",
						"description": values.issueDescription,
						"additionalInfo": values.additionalInfo ? values.additionalInfo : '',
						"spGroupid":GLOBAL.AMSEXIST == "yes"?values.supportP1Group:values.supportPGroup,
						"spUserid": values.supportPIndividual,
						"soGroupid": "0",
						"soUserid": "0",
						//"status" : "10", //remove this check if it is not there then form will not be submitted
						"priorityId" : priorityActualValue,
						"priorityValue": values.priorityLabel,
						//"criticalJustification" : "",
						//"holdJustification" : "",
						//"urgencyJustification" : "",
						"criticality":priorityActualValue==55||priorityActualValue==50||priorityActualValue==60?"No":GLOBAL.AcceptAsCritical=="Yes"?"Yes":"No",
						"cause": 1,
						"criticalityStatus":priorityActualValue==55||priorityActualValue==50||priorityActualValue==60?null:blank,
						//"slaStatus" :"",
						//"expectedDate" : "",
						//"createdOn" : "",
						//"createdBy" : "3142", //user id
						"modifiedBy": "", //user id
						"modifiedByName": "",
						"reportedThrough": values.reportedThrough,
						"urgency" : values.urgencyMode,
						"spGroupname": supportPgroupNameValue,
						"spUsername": supportPIGroupNameValue,
						//======================
						//description:values.description,
						//serviceArea:values.subServiceArea,
						//component:values.component,
						//service:values.service,
						//status:"10",
						//createdBy:"SX_Admin",
						"ciId": ciId,
						"ciName": ciName,
						"ciCode": ciCode,
						"serviceId" :GLOBAL.changeValue==true?GLOBAL.breakFixServiceID:values.impactedServiceId,
						"serviceName":GLOBAL.changeValue==true?GLOBAL.breakFixServiceName:values.impactedServiceName,
						"impactedUserid" :  GLOBAL.breakFixConsumerID===''?this.props.breakFixEditDetails.impactedUserid:GLOBAL.breakFixConsumerID,
						"impactedUsername" : GLOBAL.breakFixConsumerName===''?this.props.breakFixEditDetails.impactedUsername:GLOBAL.breakFixConsumerName,
						"consumerCompany":values.company,
						"consumerCompanyName":values.companyName,
						"isExternal" : tempIsExternal,
						"cimGroup": this.props.breakFixEditDetails.cimGroup,
						"cimGroupname":this.props.breakFixEditDetails.cimGroupname,
						"cimUser":this.props.breakFixEditDetails.cimUser,
						"cimUsername":this.props.breakFixEditDetails.cimUsername
					};
				}
				postjson.serviceCompanyId=GLOBAL.consumerCompanyId===''? this.props.breakFixEditDetails.serviceCompanyId : GLOBAL.consumerCompanyId;
				postjson.serviceCompanyName=GLOBAL.consumerCompanyName===''? this.props.breakFixEditDetails.serviceCompanyName : GLOBAL.consumerCompanyName;
				postjson.ciConsumerCompany=impactedCIcompanyID;
				postjson.requesterLocationId=GLOBAL.servicelocationid===''? this.props.breakFixEditDetails.requesterLocationId : GLOBAL.servicelocationid;
				postjson.requesterLocationName=GLOBAL.servicelocationname===''? this.props.breakFixEditDetails.requesterLocationName : GLOBAL.servicelocationname;
				postjson.ciLocationId=GLOBAL.ciLocationIdEdit===''? this.props.breakFixEditDetails.ciLocationId : GLOBAL.ciLocationIdEdit;
				postjson.ciLocationName=GLOBAL.ciLocationNameEdit===''? this.props.breakFixEditDetails.ciLocationName : GLOBAL.ciLocationNameEdit;
				postjson.ciClassId=GLOBAL.ciClassIdEdit===''? this.props.breakFixEditDetails.ciClassId : GLOBAL.ciClassIdEdit;
				postjson.ciClassName=GLOBAL.ciClassNameSelected===''? this.props.breakFixEditDetails.ciClassName :GLOBAL.ciClassNameSelected;
				postjson.businessCriticalityId=GLOBAL.businessCriticalityId===''?this.props.breakFixEditDetails.businessCriticalityId:GLOBAL.businessCriticalityId;
				postjson.businessCriticality=GLOBAL.businessCriticality===''?this.props.breakFixEditDetails.businessCriticality:GLOBAL.businessCriticality;
				postjson.supportCompanyId=values.supportCompanyID===''?this.props.breakFixEditDetails.supportCompanyId:values.supportCompanyID;
				postjson.supportCompanyName=values.supportCompanyName===''?this.props.breakFixEditDetails.supportCompanyName:values.supportCompanyName;
				// if(this.props.breakFixEditDetails.serviceBased){
                //    if(GLOBAL.contextualRuleId!==null ||GLOBAL.contextualRuleId!==''){
				// 	 postjson.contextualRuleId=GLOBAL.contextualRuleId;
				//    }else{
				// 	postjson.contextualRuleId=this.props.breakFixEditDetails.contextualRuleId;
				//    }
				// }
				if(this.props.breakFixEditDetails.spGroupid !== postjson.spGroupid){
					postjson['asgGroupHopCount'] = parseInt(this.props.breakFixEditDetails.asgGroupHopCount,10)+1;
				}
				else{
				  postjson['asgGroupHopCount'] = this.props.breakFixEditDetails.asgGroupHopCount;
				}
				if(this.props.breakFixEditDetails.spGroupid === postjson.spGroupid && this.props.breakFixEditDetails.spUserid>0 && this.props.breakFixEditDetails.spUserid !== postjson.spUserid){
					postjson['assigneeHopCount'] = parseInt(this.props.breakFixEditDetails.assigneeHopCount,10)+1;
				}
				else if(this.props.breakFixEditDetails.assigneeHopCount>0){
					postjson['assigneeHopCount'] = this.props.breakFixEditDetails.assigneeHopCount;
				}
				else{
					postjson['assigneeHopCount'] = 0;
				}
								let obj = JSON.parse(JSON.stringify(postjson));
				obj['criticality'] = values.criticality;
				if(!this.props.checkDataChanged(obj)){
					// alert('There is no change in the data!');
					swal({
						text: this.props.translator['There is no change on the form'],
						button: this.props.translator['OK'],
					});
					return false;
				}

				if(this.props.breakFixEditDetails.status === 'Pending' && ((values?.supportPGroup && this.props.breakFixEditDetails?.spGroupid != values?.supportPGroup) || (values?.supportP1Group && this.props.breakFixEditDetails?.spGroupid != values?.supportP1Group)) ){
					postjson['status'] = 15;
				}

				return axios.patch(GLOBAL.breakFixPatchUrl + GLOBAL.breakFixId, postjson)
					.then(function (response) {
						console.log("response : ", response);
						reff.props.setErrorServiceColor('');
						reff.props.loadBreakFixEditDetails(GLOBAL.breakFixId);
						reff.props.clearUserDetails();
						//alert("ABC"+response.data.breakfixId);
						if (response.status == 200) {
							reff.props.setState({ isVisible: true });
							//=========
							itemId = response.data.breakfixId;
							itemNumber = response.data.breakfixNumber;
							reportedOn = response.data.breakFixReportedOn;
							createdBy = "";
							//alert("itemId:"+itemId)
							console.log("URL====>" + GLOBAL.cmdbRelatedLogDeleteUrl + '?ci_id=' + itemId + '&ci_id_related=' + deletedCiRelationId);

							if(GLOBAL.showGroupReassignReasonErrorColor && values.groupReassignReason) {
								GLOBAL.showGroupReassignReasonErrorColor = false;
								GLOBAL.incidentgroupReason = "";
								reff.props.setGroupReasonErrorColor('');
								reff.postActivityData(values.groupReassignReason, itemId)
							} 
							
							//alert(relationRequired)
							if (relationRequired == "Y") {
								//alert("relating")

								let payloadObj ={
									"ci_id": ciId,  //==> ID of CI related
									"wi_id_related": itemId, //==> ID of Work Item related
									"wi_id_number":itemNumber,//  ==> Number of work item related
									"ci_id_reported": reportedOn,
									"created_by": createdBy,
									"relationship_type": "Y",
									"module": "Breakfix"
								}
								axios.post(GLOBAL.cmdbRelatedLogDeleteUrl, /*{
									"ci_id": itemId,
									"ci_id_related": ciId,
									"ci_id_number": itemNumber,
									"ci_id_reported": reportedOn,
									"created_by": createdBy,
									"relationship_type": "Y",
									"module": "Breakfix"
								}*/
								payloadObj
								).
									then((response) => {
										console.log("Data related successfully submitted");
										//GLOBAL.ciName="";GLOBAL.ciId="";GLOBAL.ciIdNumber="";
									});
							}
							if (flag == "Y") {
								//alert("deleting==>"+deletedCiRelationId)
								axios.delete(GLOBAL.cmdbRelatedLogDeleteUrl + '?ci_id=' + deletedCiRelationId + '&ci_id_related=' + itemId + '&module=Breakfix')
									.then((response) => {
										if (!response || response.status != 200) {
											console.log('Deletion failed.');
										} else {
											console.log('deleted relation');
											//console.log(response);
											//alert("creating new relation"+ciId)

											let payloadObj ={
												"ci_id": ciId,  //==> ID of CI related
												"wi_id_related": itemId, //==> ID of Work Item related
												"wi_id_number":itemNumber,//  ==> Number of work item related
												"ci_id_reported": reportedOn,
												"created_by": createdBy,
												"relationship_type": "Y",
												"module": "Breakfix"
											}
											axios.post(GLOBAL.cmdbRelatedLogDeleteUrl, /*{
												"ci_id": itemId,
												"ci_id_related": ciId,
												"ci_id_number": itemNumber,
												"ci_id_reported": reportedOn,
												"created_by": createdBy,
												"module": "Breakfix",
												"relationship_type": "Y"
											}*/
											payloadObj
											).then((response) => {
												console.log("This incident have been related to CMDB");
												//GLOBAL.ciName="";GLOBAL.ciId="";GLOBAL.ciIdNumber="";
											});
										}
									});
								//=========
							} else {
								console.log("no action required")
							}

						} else {
							console.log("error in CMDB deletion/relation")
						}

						//axios.delete(GLOBAL.cmdbRelatedLogDeleteUrl+'?ci_id='+response.data.breakfixId+'&ci_id_related='+GLOBAL.ciId)
						{/*if(response.status==201){
				if(response.data.ciId !=0){
					alert("response.data.ciId"+response.data.ciId)
					axios.post(GLOBAL.cmdbRelatedLogDeleteUrl, {
					"ci_id": ciId,
					"ci_id_related":response.data.breakfixId,
					"ci_id_number":response.data.breakfixNumber,
					"ci_id_reported":response.data.breakFixReportedOn,
					"created_by": userDetails.full_name,
					"module":"Breakfix"
					}).
					then((response) =>{
					console.log("this incident have been related to CMDB")
					});
				}else{
					console.log("no qualification for CI relation")
				}*/}

						//alert(response.statusText);

						//this.props.dispatch({type: 'LOAD_BREAKFIXEDITDETAILS_SUCCESS', responseData});
						reff.props.dispatchUpdatedBFDetails(response);
						// reff.props.loadBreakFixEditDetails(response.data.breakfixId);
						reff.props.loadStatusBasedHamburgerOptions('Breakfix', response.data.status, response.data.breakfixId);
						reff.props.loadBreakFixTaskLogDetails(response.data.breakfixId, "Breakfix", "asc", this.props.breakFixEditDetailsUpdate.consumerCompany);
						this.props.loadTaskGroup2(this.props.breakFixEditDetailsUpdate.consumerCompany);
						// browserHistory.push("/editBreakFix/" + response.data.breakfixId);
						navigationHooks.navigate("/editBreakFix/" + response.data.breakfixId);
						reff.props.showProcessHierarchy();
					})
					.catch(function (error) {
						console.log("error");

					});
			}
			//===================
		}catch(e){
			console.error(e);
		}
	}
	postActivityData(groupReassignReason, itemId) {
		let activityblank = false;

		if (groupReassignReason.match(/^\s*$/)) {
			activityblank = true;

		}
		let module = 'Breakfix';
			
		//const offering = this.props.offering;
		if (groupReassignReason != "" && activityblank == false) {
			this.setState({ showLoader: true });
			//this.props.fun(false,true,true);
			axios.post(GLOBAL.breakFixActivityLogPostUrl, {
				"itemId": itemId,
				"description": `${this.props.tr["Group Reassignment Reason"]} - ${groupReassignReason}`,
				"isInternal": this.state.isinternal,
				"createdOn": "",
				"createdBy": "",
				"module": module,
				"createdByName": "",
				"sendNotification": "true",
				"isExternal": ""
			}).then((response) => {
					console.log("Data successfully submitted: ", response)
				});
			}

		
	}
	statusHamburgerShowHide(event){
		this.setState({ statusHamburgerShowHide: !this.state.statusHamburgerShowHide });
	}

	hamburgerShowHide(event) {
		this.setState({ hamburgerShowHide: !this.state.hamburgerShowHide });
	}
	callbackFun(id) {
		this.setState({ hamburgerShowHide: id });
		this.setState({ statusHamburgerShowHide: id });
	}
	showCriticalIcon() {
		this.setState({ showCriticalIcon: 1 });
		//alert("this.state.showCriticalIcon"+this.state.showCriticalIcon);
	}

	callBreakFixApi() {
		this.props.resetBFQVFilters();
		this.props.fetchGroupList();
		this.props.setBreakfixQuickViewTableCarouselIndex("0");

		let searchParams = Object.assign({});
		searchParams.searchByList = 'status';
		searchParams.multipleValueList = '10,20,15';
		searchParams.sortBy = 'reportedOn';
		searchParams.orderBy = 'desc';
		searchParams.size = 10;
		searchParams.currentPage = 0;

		this.props.loadBreakFixQuickView(searchParams);
	}


	/*	componentWillReceiveProps(nextProps){
	
			let urgencyValue=nextProps.breakFixEditDetails.urgency;
				let criticalityValue= "";
				if(nextProps.breakFixEditDetails.serviceCriticality=="High"){
					criticalityValue=20
				}else if(nextProps.breakFixEditDetails.serviceCriticality=="Medium"){
					criticalityValue=25
				}else if(nextProps.breakFixEditDetails.serviceCriticality=="Low"){
					criticalityValue=30
				}
	
				   console.log(criticalityValue)
						let priorityData='';
						let priorityActualValue='';
						let priorityActualColor='';
						let priorityUrl=`/serviceManagement/rest/priority?module=Breakfix&criticality=${criticalityValue}&urgency=${urgencyValue}`;
						axios.get(priorityUrl).then((response)=>{
							priorityData = response.data;
							priorityActualValue=priorityData.priority;
							priorityActualColor=priorityData.priorityColor;
							//GLOBAL.priorityColorType = priorityActualColor;
					this.setState({
						priorityColorType:priorityData.priorityColor
					})
	
							});
	
	
		}*/

	onChangeCopy() {
		// swal({
		//     text: this.props.translator['Are you sure you want to copy this incident ?'],
		//     buttons: ["Cancel", "OK"]
		//   })
		//   .then((willDelete) => {
		//  if(willDelete)
		this.props.setShowCopyServiceForm(1)
		//   });
	}
	componentWillReceiveProps(nextProps) {
		console.log("componentWillReceiveProps999",nextProps);
		if (nextProps.showCopyServiceForm != this.state.disableCopyService)
			this.setState({ disableCopyService: nextProps.showCopyServiceForm })
	}
	goBack() {
		
	  let searchParams = Object.assign({});
      searchParams.searchByList = '';
      searchParams.multipleValueList = '';
      searchParams.patternList = '';
      searchParams.sortBy = 'createdOn';
      searchParams.orderBy = 'desc';
      searchParams.size = 10;
      searchParams.currentPage = 0;
	  //this.props.loadBreakFixQuickView(searchParams, [10, 20, 15], [], []);
	  this.props.setBreakfixQuickViewTableCarouselIndex("0");
      this.props.setInitialwibGlobalSearchFilterData();
		GLOBAL.slaHeadEnable = false;

	}
	handleSaveClick = () => {
        if (!this.props.disableFieldsForGuestRole) {
            this.props.handleSubmit(this.submit)();
        }
    };
	render() {
		//alert("priority::"+this.props.priority+"critical:::"+this.props.criticality);
		console.log("proojsj", this.props);
		let handleSaveSubmit = "";
        GLOBAL.AcceptAsCritical=this.props.breakFixEditDetails.criticality;
		let responseslastatus = this.props.breakFixSLAResponse;
		let resolutionslastatus = this.props.breakFixSLAResolution;
		let { handleSubmit, pristine, reset, submitting } = this.props
		let criticalicon = "";
		let criticalmsg = "";
		//let slacolor="";
		//alert(this.props.breakFixEditDetails.priorityId+this.props.priority)
		
		if (this.props.priority == "Critical") {

			criticalmsg = this.props.translator['Critical']

		}
		else if (this.props.criticality == "Proposed" || this.props.criticality == "Accepted" || this.props.criticalitystatusval == "1")
		//alert("M123"+this.props.priority)
		{

			criticalmsg = this.props.translator['Proposed as critical']

		}
		//alert("outval"+this.props.criticality)
		if(this.props.breakFixEditDetails.criticality=="Yes")
		{
			criticalmsg = this.props.translator['Critical'];
		}
		if (this.props.priority == "Critical" && (this.props.criticality != "Proposed" || this.props.criticalitystatusval != "1"))
			criticalicon = <span className="slahbtn criti"><i title={criticalmsg} className="cursorPoint fa fa-lightbulb-o" onClick={() => { this.props.showCriticality(); }} /></span>
		else if (this.props.priority == "Critical" && (this.props.criticality == "Proposed" || this.props.criticalitystatusval == "1"))
			criticalicon = <span className="slahbtn criti"><i title={criticalmsg} className="cursorPoint fa fa-lightbulb-o" onClick={() => { this.props.showCriticality(); }} /></span>
		else if (this.props.priority != "Critical" && (this.props.criticality == "Proposed" || this.props.criticalitystatusval == "1"))
			criticalicon = <span className="slahbtn criti"><i title={criticalmsg} className="cursorPoint fa fa-lightbulb-o" onClick={() => { this.props.showCriticality(); }} /></span>
		else if(this.props.breakFixEditDetails.criticality=="Yes")
		{
			criticalicon = <span className="slahbtn criti"><i title={criticalmsg} className="cursorPoint fa fa-lightbulb-o" onClick={() => { this.props.showCriticality(); }} /></span>
		}
			else
			criticalicon = "";



		if (this.props.breakFixEditDetails.status == "Fixed" || this.props.breakFixEditDetails.status == "Closed" || this.props.breakFixEditDetails.status == "Cancelled") {
			handleSaveSubmit = true;
		} else {
			handleSaveSubmit = false;

		}
		// console.log('*************', this.props);
		const requestNumber = (this.props.breakfixD2cNumber)? this.props.breakfixD2cNumber : (this.props.breakFixEditDetails && this.props.breakFixEditDetails.requestNumber ? this.props.breakFixEditDetails.requestNumber : "");
		return (
			<Row className="margin-b-15">
				<Col md={3} xs={12}>
					<h1 bsPrefix=" " className={"sPageHeading "}>
						{this.props.translator['Break-Fix']}
						<div className='nowap'>
						<Button title="Open Timeline" bsPrefix=" " onClick={() => { this.props.showTimeline(true); }} className={this.props.priority == "Critical" ? "tinelineicon" : "tinelineicon btn btn-link"} bsStyle="link"><LuCalendarClock /></Button>
						<ExportTo exportDocument={this.props.exportDocument} title='Export Incident Report' moduleName={'breakfix'}/>
						</div>
						<div className="order position-re">
							<div className="lnk">{requestNumber} &gt;&gt; {this.props.breakFixEditDetails.breakfixNumber} {criticalicon}</div>
						</div>
					</h1>
				</Col>
				<Col md={9} xs={12} >
					<div className='topRgtNavIcons'>
					<div className="toprigLnk">
						<div className="thrlnebtn">
							<Button title="Statuses" onClick={this.statusHamburgerShowHide.bind(this)} className="btnDropMenu btn btn-default" bsPrefix=" " disabled={this.props.disableFieldsForGuestRole}>{this.props.status} <i className="fa fa-angle-down" aria-hidden="true" /></Button>
							{this.state.statusHamburgerShowHide && !this.props.disableFieldsForGuestRole ? <BreakFixStatusHamburger criticalitystatusvalue={this.props.criticalitystatusval} resetRightSide={this.props.resetRightSide}
							status={this.props.status} priority={this.props.priority} criticality={this.props.criticality}
							fieldStatus={this.props.fieldStatus} fun={this.callbackFun} showCIRform={this.props.showCIRform} showProposeCritical={this.props.showProposeCritical}
							showProvideAttachment={this.props.showProvideAttachment} acceptCritical={this.props.acceptCritical}
							rejectCritical={this.props.rejectCritical} showNotify={this.props.showNotify}
							showProposeInvestigation={this.props.showProposeInvestigation} showProposeChange={this.props.showProposeChange} translator={this.props.translator} user_id={this.props.user_id} role_id={this.props.role_id} showCancelIssue={this.props.showCancelIssue} showOnHold={this.props.showOnHold} showProvideFix={this.props.showProvideFix} breakFixEditDetails={this.props.breakFixEditDetails} roleIdentification={this.props.roleIdentification} showReasonToReopen={this.props.showReasonToReopen} rightEditPanel={this.props.rightEditPanel} /> : null}
						</div>
						<div className="thrlnebtn margin-l-10">
							<Button title="Actions" onClick={() => {this.hamburgerShowHide(); }} className="btnDropMenu bgtran btn btn-default" disabled={this.props.disableFieldsForGuestRole} bsPrefix=" "><BiDotsVertical/></Button>
							{this.state.hamburgerShowHide && !this.props.disableFieldsForGuestRole ? <BreakFixHamburger criticalitystatusvalue={this.props.criticalitystatusval}
								status={this.props.status} priority={this.props.priority} criticality={this.props.criticality}
								fieldStatus={this.props.fieldStatus} fun={this.callbackFun} showProposeCritical={this.props.showProposeCritical}
								showProvideAttachment={this.props.showProvideAttachment} acceptCritical={this.props.acceptCritical}
								rejectCritical={this.props.rejectCritical} showNotify={this.props.showNotify}
								showProposeInvestigation={this.props.showProposeInvestigation} showProposeChange={this.props.showProposeChange} translator={this.props.translator} user_id={this.props.user_id} role_id={this.props.role_id} showCancelIssue={this.props.showCancelIssue} showOnHold={this.props.showOnHold} showProvideFix={this.props.showProvideFix} breakFixEditDetails={this.props.breakFixEditDetails} roleIdentification={this.props.roleIdentification} showExternalSystemAttributes={this.props.showExternalSystemAttributes} showReasonToReopen={this.props.showReasonToReopen} rightEditPanel={this.props.rightEditPanel}/> : null}
						</div>									
					</div>
					<div className={`float-r toprigLnk fulfillHeader`}>
						<Nav className="icnlnk riggep float-l" as="ul">
							<Nav.Item as="li" title={this.props.translator['Activity Details']} eventKey={2}><a className={this.props.processhierarchy == 1 ? "active": ""} href="javascript:void(0)" onClick={() => { this.props.showProcessHierarchy(); this.props.rightEditPanel(true); this.props.setState({isVisible: true});}}>
							<LuActivitySquare/>
							</a></Nav.Item>
							<Nav.Item as="li" title={this.props.translator['Custom Attribute']} eventKey={1}><a className={this.props.isShowTags == 1 ? "active": ""} href="javascript:void(0)" onClick={() => {this.props.showTags(); this.props.rightEditPanel(true); this.props.setState({isVisible: true});}}><span><PiTagBold className="svgRotate90"/></span></a></Nav.Item>
							<Nav.Item as="li" title={this.props.translator['Attachment']} eventKey={0}><a href="javascript:void(0)" className={this.props.provideAttachment == 1 ? "active": ""} onClick={() => { this.props.showProvideAttachment(); this.props.rightEditPanel(true); this.props.setState({isVisible: true});}}><ImAttachment/></a></Nav.Item>
							<Nav.Item as="li" title={this.props.translator['Tasks']} eventKey={1} ><a href="javascript:void(0)" className={this.props.taskeditFlag == 1 ? "active": ""} onClick={() => { this.props.showTasks(); this.props.rightEditPanel(true); this.props.setState({isVisible: true});}}><LuClipboardCheck/></a></Nav.Item>
							{isKnowledgeEnabled == "true" || isKnowledgeEnabled == true ? 
							 <Nav.Item as="li" title={this.props.translator['Knowledge Articles']} eventKey={1}>
								<a href="javascript:void(0)" className={this.props.showKnowledgeArticleFlag == 1 ? "active": ""} onClick={() => { this.props.showKnowledgeArticles(); this.props.rightEditPanel(true); this.props.setState({isVisible: true});}}><PiBookOpenText/></a>
							</Nav.Item> : null }
							{/* <Nav.Item as="li" title="Notify" eventKey={1}><a href={void 0} onClick={() => {this.props.showNotify(); this.props.rightEditPanel(true);}}><HiOutlineSpeakerphone/></a></Nav.Item> */}
							<Nav.Item title={this.props.translator['Watcher Details']} eventKey={1} as="li">
								<a href="javascript:void(0)" className={this.props.isShowWatcher == 1 ? "active": ""} onClick={() => { this.props.showWatcher(); this.props.rightEditPanel(true); this.props.setState({isVisible: true});}}>
									<span><FaRegEye/><b className="watcher-count">{this.props.watcherCount}</b></span>
									</a>
							</Nav.Item>
							{/* <Nav.Item as="li" title={this.props.translator['External Attributes']} eventKey={1} onClick={() => { this.props.showExternalSystemAttributes(); }}><span className="sAction bgBtn9 icnexSysAttr"></span></Nav.Item> */}
							<Nav.Item as="li" title={this.props.translator['SLA Progress']} eventKey={1}><a href="javascript:void(0)" className={this.props.SLA == 1 ? "active": ""} onClick={() => { this.props.showSLA(); this.props.rightEditPanel(true); this.props.setState({isVisible: true});}}><LiaHourglass className="hourGlsWidth" /></a></Nav.Item>
							{/*<Nav.Item as="li" title="Notifications" eventKey={3}><span className="sAction bgBtn9 notify"></span></Nav.Item>*/}
							{/*<Nav.Item as="li" title={this.props.translator['Causal Relationship']} eventKey={5} onClick={()=>{this.props.showCausalRelation();}}><span className="sAction bgBtn17 chart"></span></Nav.Item>*/}
							{/* <Nav.Item as="li" title={this.props.translator['Causal Relationship']} eventKey={3} onClick={() => { this.props.showCausalRelation(); }}><span className="sAction bgBtn12 icnrelationship"></span></Nav.Item> */}
							{/* <Nav.Item as="li" title={this.props.translator['Refresh']} eventKey={4} disabled={pristine || submitting} onClick={reset}><span className="sAction bgBtn16 icnrefresh"></span></Nav.Item> */}
							{/* {this.props.fieldStatus.copyIncident==true?<Nav.Item as="li" title={this.props.translator['Copy Incident']} onClick={(event) => this.onChangeCopy()} disabled={!this.state.disableCopyService ? false : true}><span className="sAction bgBtn9 icncopyOff"></span></Nav.Item>:null} */}
							<NavDropdown
							className="threeDotul"
							eventKey={3}
							as={"ul"}
							title={
							<span title="More">
								<BiDotsVertical/>
							</span>
							}
							id="threeDotdrpdown"
							>
									<NavDropdown.Item as="li" bsPrefix=" " title="External Attributes" eventKey={3.1}>
										<Nav.Link href="javascript:void(0)" onClick={() => { this.props.showExternalSystemAttributes(); this.props.rightEditPanel(true); this.props.setState({isVisible: true});}}>
										<TbTextWrap />External Attributes
										</Nav.Link>
									</NavDropdown.Item>
									<NavDropdown.Item as="li" bsPrefix=" " title="Related Work Items" eventKey={3.2}>
										<Nav.Link href="javascript:void(0)" onClick={() => { this.props.showRelatedWorkItems(); this.props.rightEditPanel(true); this.props.setState({isVisible: true});}}>
										<TbSubtask/>Related Work Items
										</Nav.Link>
									</NavDropdown.Item>
									<NavDropdown.Item as="li" bsPrefix=" " title="Related CI" eventKey={3.3}>
										<Nav.Link href="javascript:void(0)" onClick={() => { this.props.showRelateCIs(); this.props.rightEditPanel(true); this.props.setState({isVisible: true});}}>
										<TbCirclesRelation />Related CI
										</Nav.Link>
									</NavDropdown.Item>
									<NavDropdown.Item as="li" bsPrefix=" " title={this.props.translator['Causal Relationship']} eventKey={3.4} >
										<Nav.Link href="javascript:void(0)" onClick={() => { this.props.showCausalRelation(); this.props.rightEditPanel(true); this.props.setState({isVisible: true});}}>
										<RiStackshareLine/>{this.props.translator['Causal Relationship']}
										</Nav.Link>
									</NavDropdown.Item>
									{this.props.fieldStatus.copyIncident==true?<NavDropdown.Item as="li" bsPrefix=" " title={this.props.translator['Copy Incident']} disabled={this.state.disableCopyService==0 ? false : true}>
										<Nav.Link href="javascript:void(0)" onClick={(event) => {this.onChangeCopy(); this.props.rightEditPanel(true); this.props.setState({isVisible: true});}}>
										<LuCopy />{this.props.translator['Copy Incident']}
										</Nav.Link>
									</NavDropdown.Item>:null}
									<NavDropdown.Item as="li" bsPrefix=" " title={this.props.translator['Notify']} eventKey={3.4} >
										<Nav.Link href="javascript:void(0)" onClick={() => { this.props.showNotify(); this.props.setState({isVisible: true});}}>
										<HiOutlineSpeakerphone/>{this.props.translator['Notify']}
										</Nav.Link>
									</NavDropdown.Item>
							</NavDropdown>
						</Nav>
						<Nav as="ul" className="icnlnk">
							<Nav.Item bsPrefix=' ' eventKey={1} as="li" disabled={pristine || submitting || this.props.disableFieldsForGuestRole} >
								<a title={this.props.translator["Refresh"]} href="javascript:void(0)" onClick={() => {this.props.RefreshScreen();}}>
									<HiOutlineRefresh className="refreshFlip" />
								</a>
							</Nav.Item>
							<Nav.Item as="li" eventKey={2} disabled={(this.props.status === 'Closed' || this.props.isPriorityCallInProgress) ? true : submitting} title={this.props.translator['Save']}>
									<Link to="javascript:void(0)" onClick={this.handleSaveClick} ref={(e) => (this.saveObjRef = e)} className="ctrlKeyPrevent">
									<span>{submitting ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/>}</span>
									</Link>
							</Nav.Item>
							
							<Nav.Item as="li" title={this.props.translator['Close']} eventKey={3} >
							<Link to="/quickViewBreakFix" className="ctrlKeyPrevent" onClick={this.goBack.bind(this)} ref={e => this.closeButton = e}><IoClose/></Link></Nav.Item>
						</Nav>
					</div>
					</div>
				</Col>
			</Row>
		);
	}
};
const mapStateToProps = ({ breakfixD2cNumber, fieldStatus, spcmReducer, watcherDetailsFetched }) => {
	//console.log('check');
	//console.log(breakfixD2cNumber)
	let watcherCount = watcherDetailsFetched.length;
	return { breakfixD2cNumber, fieldStatus, tr:spcmReducer.tr, watcherCount };
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		resetBFQVFilters, fetchGroupList, setBreakfixQuickViewTableCarouselIndex,loadBreakFixTaskLogDetails,loadTaskGroup2,
		dispatchUpdatedBFDetails, loadStatusBasedHamburgerOptions, loadTimelineData, loadBreakFixQuickView, loadBreakFixEditDetails, setInitialwibGlobalSearchFilterData 
	}, dispatch);
}

XsmSupportEditHeader = reduxForm({
	form: 'xsmBreakFixEditFormTab'    // a unique identifier for this form
})(XsmSupportEditHeader);

export default connect(mapStateToProps, mapDispatchToProps)(XsmSupportEditHeader);
