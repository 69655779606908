
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import axios from "axios";
import Spinner from "react-spinkit";
import { GLOBAL } from "_Globals";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadProfileTimeZone } from '_Actions/myProfile/profileTimeZoneAction';
import { Scrollbars } from "react-custom-scrollbars";
import {IoClose} from "react-icons/io5";

const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});
import dummyImage from "_Images/profileimg.png";
import ReactReadMoreLess from "../../../helpers/ReactReadMoreLess";

class XsmRequestedBy extends React.Component {
  constructor(props) {
    console.log(props,'prr')
    super(props);
    this.state = {
      breakFixUser: {},
    };
    this.renderManagerDetails = this.renderManagerDetails.bind(this);
  }
  setData() {
    let strObj = {};
    if(this.props.userId){
      strObj.userid = this.props.userId;
    }else{
      strObj.userName = this.props.userName;
    }
    strObj = JSON.stringify(strObj);
    // api.get(GLOBAL.breakFixUserDetailsDataUrl,{headers:{'query':strObj}})
    api
      .get(GLOBAL.profileDetailsUrl, { headers: { query: strObj } })
      .then((response) => {
        this.setUserInfo(response);
      });
  }
  componentWillMount() {
    console.log(this.props,'rew')
    //alert("M12"+GLOBAL.breakFixConsumerID);
    this.setData();
    this.props.loadProfileTimeZone();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.userId !== prevProps.userId) {
      this.setData();
    }
  }

  setUserInfo(user) {
    this.setState({
      breakFixUser: user.data,
    });
  }
  renderManagerDetails() {
    try {
      const {
        breakFixUser: {
          managerName = "",
          managerEmployeeNumber = "",
          managerEmail = "",
        } = {},
      } = this.state;
      return (
        managerName +
        (managerEmployeeNumber ? " / " + managerEmployeeNumber : "") +
        (managerEmail ? " / " + managerEmail : "")
      );
    } catch (e) {
      console.error(e);
    }
  }
  renderTimeZone(breakFixData,id) {
		if (!breakFixData) {
			return null;
		}
		return breakFixData.map((breakFixObj) => {
      if(breakFixObj.KEY==id){
        return (
          <div key={breakFixObj.KEY}>{breakFixObj.VALUE}</div>
        );
      }
		});
	}
  renderUserDta(){
    return(
      <div className="margin-t-10"><ListGroup className="infoPopupSD" bsPrefix=" ">
              {this.state.breakFixUser.employeeNumber !==null && this.state.breakFixUser.employeeNumber !=="" ? (
                  <ListGroup.Item bsPrefix=" " className="rwTable" >
                    <span className="rwKey">{this.props.tr["Employee ID"]}</span>
                    <span className="rwVal">
                      {this.state.breakFixUser.employeeNumber}
                    </span>
                  </ListGroup.Item>
                ) : null}
                {this.state.breakFixUser.locationName !==null && this.state.breakFixUser.locationName !=="" ? (
                  <ListGroup.Item bsPrefix=" " className="rwTable" >
                    <span className="rwKey">{this.props.tr["Location"]}</span>
                    <span className="rwVal">
                      {this.state.breakFixUser.locationName}
                    </span>
                  </ListGroup.Item>
                ) : null}
                {this.state.breakFixUser.cubicle!==null && this.state.breakFixUser.cubicle!==""?<ListGroup.Item bsPrefix=" " className="rwTable" ><span className="rwKey">{this.props.tr["Desk Location"]}</span><span className="rwVal">{this.state.breakFixUser.cubicle}</span></ListGroup.Item>:null}

                {this.state.breakFixUser.timezoneid!==null && this.state.breakFixUser.timezoneid!==""?<ListGroup.Item bsPrefix=" " className="rwTable" ><span className="rwKey">{this.props.tr["Time Zone"]}</span><span className="rwVal">{this.renderTimeZone(this.props.timeZoneDetails,this.state.breakFixUser.timezoneid)}</span></ListGroup.Item>:null}
                
                {this.state.breakFixUser &&
                this.state.breakFixUser.managerName ? (
                  <ListGroup.Item bsPrefix=" " className="rwTable" >
                    <span className="rwKey">{this.props.tr["Manager"]}</span>
                    <span className="rwVal">{this.renderManagerDetails()}</span>
                  </ListGroup.Item>
                ) : null}

                {this.state.breakFixUser.companyName !==null && this.state.breakFixUser.companyName !=="" ? (
                  <ListGroup.Item bsPrefix=" " className="rwTable" >
                    <span className="rwKey">{this.props.tr["Company"]}</span>
                    <span className="rwVal">
                      {this.state.breakFixUser.companyName}
                    </span>
                  </ListGroup.Item>
                ) : null}

                {this.state.breakFixUser.organizationName!==null && this.state.breakFixUser.organizationName!=="" ?<ListGroup.Item bsPrefix=" " className="rwTable" ><span className="rwKey">{this.props.tr["Organization"]}</span><span className="rwVal">{this.state.breakFixUser.organizationName}</span></ListGroup.Item>:null}

                {this.state.breakFixUser.departmentName!==null && this.state.breakFixUser.departmentName!==""?<ListGroup.Item bsPrefix=" " className="rwTable" ><span className="rwKey">{this.props.tr["Department"]}</span><span className="rwVal">{this.state.breakFixUser.departmentName}</span></ListGroup.Item>:null}

                {this.state.breakFixUser.costcenterName!==null && this.state.breakFixUser.costcenterName!==""?<ListGroup.Item bsPrefix=" " className="rwTable" ><span className="rwKey">{this.props.tr["Cost Center"]}</span><span className="rwVal">{this.state.breakFixUser.costcenterName}</span></ListGroup.Item>:null}
               

                {this.state.breakFixUser.groupName!==null?<ListGroup.Item bsPrefix=" "  className="rwTable" >
                  <span className="rwKey">{this.props.tr["Groups"]}</span>
                  <span className="rwVal">
                    <ReactReadMoreLess charLimit={40} 
                    readMoreText="more" 
                    readLessText="...less"
                    content={this.state.breakFixUser.groupName}
                    />
                  </span>
                  </ListGroup.Item>:null} 

                {this.state.breakFixUser.roleName !==null ? (
                  <ListGroup.Item bsPrefix=" " className="rwTable" style={{borderBottom:"none"}}>
                    <span className="rwKey">{this.props.tr["Roles"]}</span>
                    <span className="rwVal">
                    <ReactReadMoreLess charLimit={40} 
                    readMoreText="more" 
                    readLessText="...less"
                    content={this.state.breakFixUser.roleName}
                    />
                    </span>
                  </ListGroup.Item>
                ) : null}
              </ListGroup></div>
    );
  }
  render() {
    const { userDetailsLabel = "Requested for Details",isHeadingVisible=true,isPopup=false} = this.props;
    /**
     * TESTCODE
     * this.state.breakFixUser.length == 0
     */
    return (
      <div className="userInfoPopupParent">
        <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
            <ul>
                <li>
                <button
                    type='button'
                    title="Minimize the right panel"
                    bsClass=""
                    bsStyle=""
                    className="closerightPanelBtn"
                    onClick={() => {
                    this.props.rightEditPanel(false);
                    }}
                >
                    <IoClose />
                </button>
                </li>
            </ul>
        </div>
        {(isHeadingVisible == true) && <div className="rPageHeading">
          <div className="offNam margin-t-5 margin-b-5">{userDetailsLabel}</div>
        </div>}
        <div className="rBoxGap">
          {Object.keys(this.state.breakFixUser).length == 0 ? (
            <Spinner spinnerName="three-bounce" />
          ) : (
            <div>
              <Card bsPrefix=" " className="cardLeftdv">
                {/* <Media.Left align="top"><Image style={{ height: '70px' }} src={this.state.breakFixUser.profilePicTempURL} alt={this.state.breakFixUser.username} /></Media.Left> */}
                <Card.Text bsPrefix=" " className="card-left">
                  <Image
                    width="90px"
                    height="90px"
                    src={
                      this.state.breakFixUser.container != null &&
                      this.state.breakFixUser.container != ""
                        ? this.state.breakFixUser.profilePicTempURL
                        : dummyImage
                    }
                    alt={this.state.breakFixUser.username}
                  />
                </Card.Text>
                <Card.Body className="padding-l-10">
                  <Card.Header className="f-size-18 margin-0">
                    <div className='margin-b-10'>{this.state.breakFixUser.fullname}</div>
                  </Card.Header>
                  {this.state.breakFixUser.email !==null && this.state.breakFixUser.email !=="" ? <div className="f-size-13 margin-b-5" style={{display:"table",width:"100%"}}>
                    <span className="themeColor1 f-size-13" style={{width:"25px",display:"table-cell"}}><i className="f-size-12 fa fa-envelope" aria-hidden="true"></i></span> <span className="lgrayColor" style={{display:"table-cell",wordBreak:"break-all"}}>{this.state.breakFixUser.email}</span>
                  </div>:null}
                  {/* {this.state.breakFixUser.employeeNumber !== "" ? <div className="f-size-13">
                    <span className="themeColor1 f-size-13" style={{width:"20px",display:"inline-block"}}><i className="fa fa-map-marker" aria-hidden="true"></i></span> <span className="lgrayColor">{this.state.breakFixUser.employeeNumber}</span>
                  </div>:null} */}
                  {this.state.breakFixUser.mobilePhone !==null && this.state.breakFixUser.mobilePhone !=="" ? <div className="f-size-13 margin-b-5" style={{display:"table",width:"100%"}}>
                    <span className="themeColor1 f-size-13" style={{width:"25px",display:"table-cell"}}><i className="f-size-13 fa fa-phone" aria-hidden="true"></i></span> <span className="lgrayColor" style={{display:"table-cell",wordBreak:"break-all"}}>{this.state.breakFixUser.mobilePhone}</span>
                  </div>:null}
                </Card.Body>
              </Card>
              {isPopup==true ?
              <Scrollbars
                  style={{ height:300 }}
                  autoHide={false}
                  hideTracksWhenNotNeeded={false}
                  className="areaPopup"
                  ref="scrollbars"
                >
              {this.renderUserDta()}
              </Scrollbars>:this.renderUserDta()}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  const { timeZoneDetails, CWLDetails } = state;  
	return {
    timeZoneDetails,
    CWLDetails,
    tr: state.spcmReducer.tr,
  };
}
function mapDispatchToProps(dispatch) {
	return bindActionCreators({loadProfileTimeZone}, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps )(XsmRequestedBy);
// export default XsmRequestedBy;
