
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const addUploadComponent = (e) => {
  e.DomComponents.addType(uploadComponent.name, uploadFieldAdd);
};

export const uploadComponent = {
  name: "uploadComponent",
  content: `<div class="row">
      <div class="col-md-12">
      <input type="file" placeholder="Please choose" class="form-control"/>
      </div>
    </div>`,
  traits: [
    {
      type: "text",
      label: "Name",
      name: "name",
      placeholder: "Enter Name",
      changeProp: 1,
    },
    {
      type: "select",
      label: "Data Source",
      name: "data_source",
      changeProp: 1,
      options: []
    },
    {
      type: "select",
      name: "fieldType",
      label: "Field Type",
      options: [
        { id: "dropzone", label: "Dropzone" },
        { id: "normal", label: "normal" },
      ],
      changeProp: 1,
    },
    {
      type: "checkbox",
      label: "Required",
      name: "required",
      changeProp: 1,
    },
    {
      type: "checkbox",
      label: "Preview",
      name: "preview",
      changeProp: 1,
    },
    {
      type: "checkbox",
      label: "Multiple",
      name: "multiSelect",
      changeProp: 1,
    },
    {
      type: "checkbox",
      label: "Disabled",
      name: "disabled",
      changeProp: 1,
    },
    {
      type: "select",
      label: "Data Source",
      name: "data_source",
      options:[],
      changeProp: 1,
    },
  ],
};

export const uploadFieldAdd = {
  model: {
    defaults: {
      tagName: "div",
      draggable: true,
      attributes: { class: "uploadField" },
      content: uploadComponent.content,
      traits: uploadComponent.traits,
      editable: true,
    },
  },
};

export const uploadBlockJson = {
  id: "uploadBlock",
  label: "Dropzone & File",
  // attributes: { class: "fa fa-file" },
  media:`<svg fill="#000000" width="40px" height="40px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" stroke="#000000" stroke-width="0.00024000000000000003"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M19,13a1,1,0,0,0-1,1v.38L16.52,12.9a2.79,2.79,0,0,0-3.93,0l-.7.7L9.41,11.12a2.85,2.85,0,0,0-3.93,0L4,12.6V7A1,1,0,0,1,5,6h7a1,1,0,0,0,0-2H5A3,3,0,0,0,2,7V19a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V14A1,1,0,0,0,19,13ZM5,20a1,1,0,0,1-1-1V15.43l2.9-2.9a.79.79,0,0,1,1.09,0l3.17,3.17,0,0L15.46,20Zm13-1a.89.89,0,0,1-.18.53L13.31,15l.7-.7a.77.77,0,0,1,1.1,0L18,17.21ZM22.71,4.29l-3-3a1,1,0,0,0-.33-.21,1,1,0,0,0-.76,0,1,1,0,0,0-.33.21l-3,3a1,1,0,0,0,1.42,1.42L18,4.41V10a1,1,0,0,0,2,0V4.41l1.29,1.3a1,1,0,0,0,1.42,0A1,1,0,0,0,22.71,4.29Z"></path></g></svg>`,
  category: "Basic",
  content: {
    type: uploadComponent.name,
  },
};
