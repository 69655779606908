
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
/**
@Description: routes for
**/

export const SaveFiltersState= (WTBState)=>{
    let savedSstate={}
    if(WTBState.moduleName=='fullfilment'){

        if(WTBState.searchFilterFulfilment=='RequestedOn'){

            savedSstate={
           fromDate:WTBState.fromDate,
           toDate:WTBState.toDate,
           checkDateField:WTBState.checkDateField,
           moduleName:WTBState.moduleName,
           searchFilter:WTBState.searchFilterFulfilment,
           tableCurrentPage:WTBState.tableCurrentPageFullfilment,
           tableSize:WTBState.tableSizeFullfilment,
           enableSearch:WTBState.enableSearchFulfilment,
           checkSearchtextField:'',
           valueStatusSearchInput:'',
           valueSearchInput:'',
           SearchParams:WTBState.fullfilmentSearchParams
            }

        }else if(WTBState.searchFilterFulfilment=='itemStatus'){
            savedSstate={
           fromDate:'',
           toDate:'',
           checkDateField:'',
           moduleName:WTBState.moduleName,
           searchFilter:WTBState.searchFilterFulfilment,
           tableCurrentPage:WTBState.tableCurrentPageFullfilment,
           tableSize:WTBState.tableSizeFullfilment,
           enableSearch:WTBState.enableSearchFulfilment,
           checkSearchtextField:WTBState.checkSearchtextFieldFulfilment,   
           valueStatusSearchInput:WTBState.valueStatusSearchInputFulfilment,
           valueSearchInput:'',
           SearchParams:WTBState.fullfilmentSearchParams
            }

        }else{

            savedSstate={
           fromDate:'',
           toDate:'',
           checkDateField:'',
           moduleName:WTBState.moduleName,
           searchFilter:WTBState.searchFilterFulfilment,
           tableCurrentPage:WTBState.tableCurrentPageFullfilment,
           tableSize:WTBState.tableSizeFullfilment,
           enableSearch:WTBState.enableSearchFulfilment,
           checkSearchtextField:'',   
           valueStatusSearchInput:'',
           valueSearchInput:WTBState.valueSearchInputFulfilment,
           SearchParams:WTBState.fullfilmentSearchParams
            }

        }

   }
    return (dispatch)=>{
        dispatch({
            type: 'SAVE_FILTERS_STATUS',
            payload:savedSstate
        })
    }
}

export const ClearFiltersState= ()=>{
    let savedSstate={
        fromDate:'',
        toDate:'',
        checkDateField:'',
        moduleName:'',
        searchFilter:'',
        tableCurrentPage:'',
        tableSize:'',
        enableSearch:'',
        checkSearchtextField:'',   
        valueStatusSearchInput:'',
        valueSearchInput:'',
        SearchParams:''
    }
    return (dispatch)=>{
        dispatch({
            type: 'RESET_FILTERS_STATUS',
            payload:savedSstate
        })
    }
}