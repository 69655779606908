
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from "axios";
import { GLOBAL } from '_Globals';

const api = axios.create({
    headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

export function loadAllRosterList(params = {}, showAllRecords = false, status = '', shiftId = 0) {
    return (dispatch) => {
        if (showAllRecords) {
            params["page"] = 0;
            params["size"] = 5000;
        }
        if (shiftId != 0) {
            params["shiftId"] = shiftId;
        }
        if (status != '') {
            params["status"] = status;
        }
        let queryParams = "";
        params["sort"] = "createdOn,desc";
        queryParams += "?";
        Object.keys(params).forEach((key, index) => {
            queryParams += key + "=" + params[key];
            if (index !== Object.keys(params).length - 1) {
                queryParams += "&";
            }
        })
        if (!showAllRecords || shiftId != 0) { dispatch({ type: 'LOAD_ROSTER_LIST_LOADER_IN_PROGRESS' }); }
        api.get(GLOBAL.loadRosterListUrl + queryParams)
            .then((response) => {
                if (!response) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => {
                if (response.status == 204) {
                    dispatch(loadAllRosterListSuccess([]));
                } else {
                    dispatch(loadAllRosterListSuccess(response.data.data));
                    dispatch({
                        type: 'LOAD_ALL_ROSTER_LIST_META',
                        payload: {
                            currentPage: response.data.currentPage,
                            numberOfItems: response.data.numberOfItems
                        }
                    });
                }
                if (!showAllRecords || shiftId != 0) {
                    dispatch({ type: "LOAD_ROSTER_LIST_LOADER_FINISHED" });
                }
            })
            .catch((err) => {
                console.log(err);
                if (!showAllRecords || shiftId != 0) {
                    dispatch({ type: "LOAD_ROSTER_LIST_LOADER_FINISHED" });
                }
            });
    };
}

export function loadAllRosterListSuccess(payload) {
    return {
        type: 'LOAD_ALL_ROSTER_LIST_SUCCESS',
        payload
    };
}

export function emptyAllRosterList() {
    return (dispatch) => {
        dispatch({
            type: 'LOAD_ALL_ROSTER_LIST_SUCCESS',
            payload: []
        });
    };
}

export function loadRosterDetailsByRosterId(rosterId) {
    return (dispatch) => {
        let str = '?rosterId=' + rosterId;
        dispatch({ type: 'LOAD_ROSTER_LIST_LOADER_IN_PROGRESS' });
        api.get(GLOBAL.loadRosterListUrl + str)
            .then((response) => {
                if (!response) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => {
                if (response.status == 204) {
                    dispatch(loadRosterDetailsByRosterIdSuccess([]));
                } else {
                    let initialValuesObj = {};
                    initialValuesObj.rosterId = response.data.data[0].rosterId;
                    initialValuesObj.rosterName = response.data.data[0].rosterName;
                    initialValuesObj.shiftId = response.data.data[0].shiftId;
                    initialValuesObj.shiftName = response.data.data[0].shiftName;
                    initialValuesObj.startDate = response.data.data[0].startDate;
                    initialValuesObj.endDate = response.data.data[0].endDate;
                    initialValuesObj.groupId = response.data.data[0].groupId;
                    initialValuesObj.groupName = response.data.data[0].groupName;
                    initialValuesObj.companyId = response.data.data[0].companyId;
                    initialValuesObj.companyName = response.data.data[0].companyName;
                    dispatch(loadRosterDetailsByRosterIdSuccess(initialValuesObj));
                }
                dispatch({ type: 'LOAD_ROSTER_LIST_LOADER_FINISHED' });
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: 'LOAD_ROSTER_LIST_LOADER_FINISHED' });
            });
    };
}

export function loadRosterDetailsByRosterIdSuccess(payload) {
    return {
        type: 'LOAD_ROSTER_DETAILS_BY_ROSTER_ID_SUCCESS',
        payload
    };
}

export function emptyRosterDetailsByRosterId() {
    return (dispatch) => {
        dispatch({ type: 'EMPTY_ROSTER_DETAILS_BY_ROSTER_ID_SUCCESS' });
    };
}

export function loadRosterMembersList(rosterId) {
    return (dispatch) => {
        dispatch({ type: 'LOAD_ROSTER_MEMBERS_LOADER_IN_PROGRESS' });
        api.get(GLOBAL.loadRosterMembersDataUrl + '/' + rosterId)
            .then((response) => {
                if (!response) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => {
                dispatch(loadRosterMembersListSuccess(response.data.data ?? []));
                dispatch({ type: 'LOAD_ROSTER_MEMBERS_LOADER_FINISHED' });
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: 'LOAD_ROSTER_MEMBERS_LOADER_FINISHED' });
            });
    };
}

export function loadRosterMembersListSuccess(payload) {
    return {
        type: 'LOAD_ROSTER_MEMBERS_LIST_SUCCESS',
        payload
    };
}

export function deleteRosterMembersData(rmIdObj, callback) {
    return (dispatch) => {
        api.delete(GLOBAL.deleteRosterMembersDataUrl, { data: rmIdObj })
            .then((response) => {
                if (!response) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => {
                callback();
            })
            .catch((err) => {
                console.log(err);
            });
    };
}

