
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from "axios";

const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

export const getMultiSSOLogo = () => {
  return (dispatch) => {
    dispatch({ type: "FDN_DATA_FETCHING" });
    api
      .get("/api/getMultiTenancyLogo")
      .then((response) => {
        console.log("ExternalLinkData", response.data);
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
        dispatch({
          type: "MULTISSO_LOGO_FETCHED_SUCCESSFULLY",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
        console.log(err);
      });
  };
};

export const updateMultiSSOLogo = (payload) => {
  return (dispatch) => {
    dispatch({ type: "FDN_DATA_FETCHING" });
    api
      .post("/fdn-img/api/updateMultiTenancyLogo", payload)
      .then((response) => {
        console.log("ExternalLinkData", response.data);
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
        dispatch({
          type: "MULTISSO_LOGO_FETCHED_SUCCESSFULLY",
          payload: { Logo_URL: response.data }
        });
      })
      .catch((err) => {
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
        console.log(err);
      });
  };
};
