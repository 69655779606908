
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
//below URL used for testing purpose
//const breakFixUrl = 'https://xsm-f.mybluemix.net/api/SX_FAVOURITEOFFERINGs/';
import { GLOBAL }  from '../../components/Globals';
//let breakFixUrl =`https://xsm-f.mybluemix.net/api/breakfixitem?breakfixitemId=`;
//let breakFixUrl=`https://xsm-f.mybluemix.net/groupListSelfService?companyId=2932&group=ResolvedER`
export function loadChangeApprovalDetailsSuccess(changeApprovalDetails){
	//alert('in success')
	return {
		type: 'LOAD_CHANGEAPPROVALDETAILS_SUCCESS',
		changeApprovalDetails
	};
}

export function loadChangeApprovalDetailsFailure(errorResponse){
	return {
		type: 'LOAD_CHANGEAPPROVALDETAILS_FAILURE',
		errorResponse
	};
}
export function loadChangeApprovalDetails(module,changeId,userId){
	//alert('action called and parameter is '+breakFixId)
	//if(breakFixId=="0")
	//breakFixId="";
	return(dispatch) =>{
		let relatedurl="";

				relatedurl=GLOBAL.changeApprovalPendingUrl+"?itemId="+changeId+"&moduleId="+module+"&userId="

				axios.get(relatedurl)
					.then((changeApprovalDetails)=>{
						if(!changeApprovalDetails){//alert('not breakFix')
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							throw Error(changeApprovalDetails.statusText);
						}
						return changeApprovalDetails;
					})
					.then((changeApprovalDetails) =>{//alert('yes it is')
						dispatch(loadChangeApprovalDetailsSuccess(changeApprovalDetails))
					})
					.catch((err) => {
						console.log(err);
					});
					//console.log("not calling loadBreakFixEditDetails");

	};
}
