
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import ListGroup from "react-bootstrap/ListGroup";
import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";
import OnClickOutside from "react-onclickoutside";
import { ImInfo } from "react-icons/im";
import {
  loadFulfillmentDetails,
  resetfulfillmentIndividual,
} from "../../../actions/fulfillment/fulfillmentDataAction";
import { loadBreakFixSGI } from "../../../actions/breakFix/serviceGroupIAction";
import { getFulfillmentTimeLineStatus } from "../../../actions/fulfillment/fulfillmentTimeLineActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loadBreakFixSourceName } from "../../../actions/breakFix/sourceAction";
// import {
//   loadBreakFixSGAEEdit,
//   loadNewBreakFixSGAEWithQueryParams,
// } from "../../../actions/breakFix/serviceGroupAEAction";
import { loadSpecificUserDetails } from "../../../actions/common/userDetails";
import OrderFullFilmentStatusTimeLine from "./FulfilmentStatusTimeLine";
import { loadBreakFixAuditLogDetails } from "../../../actions/breakFix/breakFixAuditLogAction";
import { GLOBAL } from "../../Globals";
import ListLoader from "../../common/loaders/ListLoader";
import moment from "moment";
import Datetime from "react-datetime";
import GenericTimeline from "../../common/genericTimeline";
import { getQuestionsCategoryWise } from "../../../actions/cart/myOrders/myOrdersAction";
import {
  getGroupData,
  getBreakfixGroupData,
  getAllgroupFulfillment,
} from "../../../actions/amsActions/amsActions";
import { Typeahead } from "react-bootstrap-typeahead";
import axios from "axios";
import { loadFulfillmentReasonCode } from "../../../actions/breakFix/reasonCodeAction.js";
import { RiUserSearchLine } from "react-icons/ri";
import { RuleAmsDdropDownNoReduxForm } from "../../common/forms/ruleDropDownNoReduxForm";
import { getAdminPreferences } from "../../../actions/adminPreferences/adminPreferencesAction.js";
class FulfillmentFormTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      individualDropdownValue: "",
      holdStatusDropdownValue: null,
      expDelDate: "",
      complexityValue: null,
      AllAMSGrps: false,
      grpvalidation: false,
      isIndividualChanged: false,
      selectedGroups: [],
      selectedIndividuals: [],
      typedValueGroup: "",
      typedValueIndividual: "",
      isVIPuser: false,
      showGrpReason: false,
      initialGroupId: "",
      showIndividualInfo: true,
      groupReasonText: "",
      rulesAttribute: {},
    };
    this.setReasonCode = this.setReasonCode.bind(this);
    this.setComplexityCode = this.setComplexityCode.bind(this);
    //this.setVal = this.setVal.bind(this);
    this.setValGroup = this.setValGroup.bind(this);
    this.setValIndividual = this.setValIndividual.bind(this);
    this.seterrorGroupcolor = this.seterrorGroupcolor.bind(this);
    this.AllGrps = this.AllGrps.bind(this);
    this.AllAMSGrps = this.AllAMSGrps.bind(this);
    this.groupInputChange = this.groupInputChange.bind(this);
    this.groupCrossClick = this.groupCrossClick.bind(this);
    this.individualInputChange = this.individualInputChange.bind(this);
    this.individualCrossClick = this.individualCrossClick.bind(this);
    this.validateField = this.validateField.bind(this);
    this.setGroupReason = this.setGroupReason.bind(this);
    this.showGroupReason = this.showGroupReason.bind(this);
    this.renderSourceName = this.renderSourceName.bind(this);
    //this.ShowAMSGroup=this.ShowAMSGroup.bind(this);
    //this.renderSupportAMSGroup=this.renderSupportAMSGroup.bind(this);
    GLOBAL.fulfillmentrenderval = 1;
    GLOBAL.fulfillmentHoldCode =
      this.props.fulfillmentDetails.holdingReasonCode;
    GLOBAL.complexityCode = this.props.fulfillmentDetails.complexity;
    GLOBAL.fulfillmentHoldVal =
      this.props.fulfillmentDetails.holdingReasonValue;
  }
  // setVal(e,changeFor){
  // let index = e.target.selectedIndex;
  //   if (changeFor=='group') {
  //   GLOBAL.fulfillmentgroupval = e.target.value
  //   GLOBAL.fulfillmentgrouptext = e.target[index].text
  //   this.props.loadBreakFixSGI(e.target.value);

  //   GLOBAL.fulfillmentindividualval = '';
  //   GLOBAL.fulfillmentindividualtext = '';
  //   if(GLOBAL.fulfillmentgroupval=="")
  //   {
  //     this.setState({
  // 		grpvalidation:true
  // 	})
  // 	GLOBAL.grpErrorColor=true;
  //   }
  //   else{
  // 	  this.setState({
  // 		  grpvalidation:false
  // 	  })
  // 	  GLOBAL.grpErrorColor=false;

  //   }

  // }else if(changeFor=='individual') {
  //     console.log('inside individual');
  //   GLOBAL.fulfillmentindividualval=e.target.value;
  //   this.setState({individualDropdownValue: e.target.value, isIndividualChanged: true});
  // 		GLOBAL.fulfillmentindividualtext=e.target[index].text
  // 		this.props.loadBreakFixSGI(GLOBAL.fulfillmentgroupval);
  //   console.log(GLOBAL.fulfillmentindividualval + " "+e.target.value)
  // 	}
  // }
  setGroupReason(evt) {
    GLOBAL.fulfillmentgroupReason = evt.target.value;
    this.setState({ groupReasonText: evt.target.value });
    console.log("MMM : setGroupReason evt.target.value: ", evt.target.value);
  }
  setValGroup(selectedGroup) {
    this.setState({
      individualDropdownValue: "",
      showIndividualInfo: false,
    });
    if (selectedGroup.length > 0) {
      const groupId = this.state.AllAMSGrps==false?selectedGroup[0].id:selectedGroup[0].value;
      GLOBAL.fulfillmentgroupval = groupId;
      GLOBAL.fulfillmentgrouptext = selectedGroup[0].label;
      GLOBAL.isExternalFulfillmentEdit =
        selectedGroup[0].isExternal == "" ||
        selectedGroup[0].isExternal == null ||
        selectedGroup[0].isExternal == undefined
          ? "false"
          : selectedGroup[0].isExternal;
      this.props.loadBreakFixSGI(groupId);

      this.state.selectedGroups = selectedGroup;
      this.state.selectedIndividuals = [];

      GLOBAL.fulfillmentindividualval = "";
      GLOBAL.fulfillmentindividualtext = "";
      if (GLOBAL.fulfillmentgroupval == "") {
        this.setState({
          grpvalidation: true,
        });
        GLOBAL.grpErrorColor = true;
      } else {
        this.setState({
          grpvalidation: false,
        });
        GLOBAL.grpErrorColor = false;
      }
      this.props.resetfulfillmentIndividual();
      this.props.setSupportCompany(
        selectedGroup[0].supportCompanyID,
        selectedGroup[0].supportCompanyName
      );
      this.showGroupReason(selectedGroup[0].id);
      this.props.setAssignmentGrpUsr(selectedGroup, "group");
    } else {
      this.setState({ selectedGroups: selectedGroup });
      this.props.setAssignmentGrpUsr(selectedGroup, "group");
      GLOBAL.fulfillmentgroupval = "";
      GLOBAL.fulfillmentgrouptext = "";
      GLOBAL.isExternalFulfillmentEdit = "";
    }
  }
  setValIndividual(selectedIndividual) {
    this.setState({ showIndividualInfo: false });
    if (selectedIndividual.length > 0) {
      GLOBAL.fulfillmentindividualval = selectedIndividual[0].value;

      this.setState({
        individualDropdownValue: selectedIndividual[0].value,
        isIndividualChanged: true,
        selectedIndividuals: selectedIndividual,
      });
      GLOBAL.fulfillmentindividualtext = selectedIndividual[0].label;
      if (GLOBAL.fulfillmentgroupval !== "") {
        this.props.loadBreakFixSGI(GLOBAL.fulfillmentgroupval);
      }
    } else {
      this.setState({
        selectedIndividuals: selectedIndividual,
        individualDropdownValue: "",
      });
      (GLOBAL.fulfillmentindividualval = ""),
        (GLOBAL.fulfillmentindividualtext = "");
    }
  }
  seterrorGroupcolor() {
    if (this.state.selectedGroups.length === 0) {
      GLOBAL.grpErrorColor = true;
    } else {
      GLOBAL.grpErrorColor = false;
    }
    this.setState({ selectedGroups: this.state.selectedGroups });
    this.props.setAssignmentGrpUsr(this.state.selectedGroups, "group");
  }

  changeDate(value) {
    // console.log('inside date');
    // console.log(value);
    GLOBAL.fulfillmentDate = value;
    this.setState({ expDelDate: value });
    this.props.resetDateError();
    // console.log('GLOBAL.fulfillmentDate=',GLOBAL.fulfillmentDate +value);
  }

  validateField(fieldType, event) {
    console.log(
      "MMM validateField ",
      fieldType,
      " currentTarget.defaultValue: ",
      event.target.value
    );
    switch (fieldType) {
      case "groupReassignReason":
        GLOBAL.GroupReassignReasonErrorColor = "";
        if (this.state.showGrpReason && event.target.value.trim() == "") {
          this.props.setGroupReasonErrorColor("error");
        } else {
          this.props.setGroupReasonErrorColor("");
        }
        break;
    }
  }

  componentWillMount() {
    if (
      this.props.fulfillmentDetails.asgGroupid !== 0 &&
      this.props.fulfillmentDetails.asgGroupid !== "0"
    ) {
      if (
        typeof this.props.fulfillmentDetails.asgGroupid !== "undefined" &&
        this.props.fulfillmentDetails.asgGroupid !== null &&
        typeof this.props.fulfillmentDetails.asgGroupname !== "undefined" &&
        this.props.fulfillmentDetails.asgGroupname !== null
      ) {
        this.state.selectedGroups = [
          {
            value: this.props.fulfillmentDetails.asgGroupid,
            label: this.props.fulfillmentDetails.asgGroupname,
          },
        ];
        (GLOBAL.fulfillmentgroupval = this.props.fulfillmentDetails.asgGroupid),
          (GLOBAL.fulfillmentgrouptext =
            this.props.fulfillmentDetails.asgGroupname);
        this.props.setAssignmentGrpUsr(this.state.selectedGroups, "group");
      }
    }
    if (
      this.props.fulfillmentDetails.asgUserid !== 0 &&
      this.props.fulfillmentDetails.asgUserid !== "0"
    ) {
      if (
        typeof this.props.fulfillmentDetails.asgUserid !== "undefined" &&
        this.props.fulfillmentDetails.asgUserid !== null &&
        typeof this.props.fulfillmentDetails.asgUsername !== "undefined" &&
        this.props.fulfillmentDetails.asgUsername !== null
      ) {
        this.state.selectedIndividuals = [
          {
            value: "" + this.props.fulfillmentDetails.asgUserid + "",
            label: this.props.fulfillmentDetails.asgUsername,
          },
        ];
        (GLOBAL.fulfillmentindividualval =
          this.props.fulfillmentDetails.asgUserid),
          (GLOBAL.fulfillmentindividualtext =
            this.props.fulfillmentDetails.asgUsername);
      }
    }

    this.props.loadSpecificUserDetails();
    //this.props.loadFulfillmentDetails(this.props.itemId);
    //this.props.loadBreakFixSGI(this.props.fulfillmentDetails.asgGroupid);
    this.props.getQuestionsCategoryWise(this.props.itemId);
    this.props.getFulfillmentTimeLineStatus(this.props.itemId);
    this.props.loadBreakFixAuditLogDetails(
      this.props.itemId,
      "dsc",
      "Fullfilment"
    );

    this.setState({
      AllAMSGrps: false,
    });
    GLOBAL.fulfillmentgroupval = this.props.fulfillmentDetails.asgGroupid;
    GLOBAL.fulfillmentgrouptext = this.props.fulfillmentDetails.asgGroupname;
    // this.setState({expDelDate: moment()});
    if (
      typeof this.props.fulfillmentDetails.requesterId !== "undefined" &&
      this.props.fulfillmentDetails.requesterId !== ""
    ) {
      let str = {};
      str.user_id = this.props.fulfillmentDetails.requesterId;
      str = JSON.stringify(str);
      axios
        .get(GLOBAL.userSpecificDetailsURL, { headers: { query: str } })
        .then((response) => {
          this.setState({
            isVIPuser: response.data.vip === "yes" ? "yes" : "no",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // console.log('*****', this.props);
    if (
      this.props &&
      this.props.fulfillmentDetails &&
      this.props.fulfillmentDetails.asgUserid &&
      !isNaN(this.props.fulfillmentDetails.asgUserid) &&
      !!parseInt(this.props.fulfillmentDetails.asgUserid, 10)
    ) {
      this.setState({
        individualDropdownValue: this.props.fulfillmentDetails.asgUserid,
        showIndividualInfo: true,
      });
    } else {
      this.setState({ individualDropdownValue: "", showIndividualInfo: "" });
    }
    this.props.loadBreakFixSourceName();
  }
  componentDidMount() {
    if (
      this.props.fulfillmentDetails &&
      this.props.fulfillmentDetails.asgGroupid
    ) {
      this.setState({
        initialGroupId: this.props.fulfillmentDetails.asgGroupid,
      });
    }
    this.props.setSupportCompany(
      this.props.fulfillmentDetails.supportCompanyId,
      this.props.fulfillmentDetails.supportCompanyName
    );
    if (
      typeof this.props.fulfillmentDetails !== "undefined" &&
      typeof this.props.fulfillmentDetails.companyId !== "undefined"
    ) {
      let rulesAttribute = {
        status: this.props.fulfillmentDetails.itemStatus,
        serviceName: this.props.fulfillmentDetails?.offeringNameSx,
        userLocation: this.props?.fulfillmentDetails?.requesterLocationName,
        impactedUsername: this.props?.fulfillmentDetails?.requesterName,
        serviceId: this.props.fulfillmentDetails.offeringIdSx,
        impactedCiId: "0",
        requestorId: this.props.fulfillmentDetails.requesterId,
        classId: "0",
        userLocationId: this.props.fulfillmentDetails.requesterLocationId,
        ciLocationId: "0",
      };
      this.setState({ rulesAttribute: rulesAttribute });

      if (
        this.props.fulfillmentDetails.itemStatus === 60 ||
        this.props.fulfillmentDetails.itemStatus === "60"
      ) {
        this.props.loadFulfillmentReasonCode(
          this.props.fulfillmentDetails.companyId,
          "Fulfillment",
          "On Hold",
          "1"
        );
      }
    }
    this.props.getAdminPreferences(this.props.fulfillmentDetails.companyId, 'fulfillment', '32', 'aiInsightDSAT')
  }
  componentWillReceiveProps(newProps) {
    console.log('this.props ==', this.props.fulfillmentDetails);
    let dateformat =
      this.props &&
      this.props.specificUserDetails &&
      this.props.specificUserDetails.dateformat
        ? this.props.specificUserDetails.dateformat
        : "";
    // console.log('dateformat ================',dateformat);
    let dateFormatFirstItem = null;
    if (dateformat) {
      let dateFormatCopy = Object.assign(dateformat);
      if (dateFormatCopy) {
        let dateFormatArr = dateFormatCopy.split(" ");
        if (dateFormatArr && dateFormatArr.length) {
          dateFormatFirstItem = dateFormatArr[0].toUpperCase();
        }
      }
    }

    if (
      this.props.fulfillmentDetails &&
      this.props.fulfillmentDetails.expdeliveryDate &&
      dateFormatFirstItem
    ) {
      GLOBAL.fulfillmentDate = this.props.fulfillmentDetails.expdeliveryDate;
      this.setState({
        expDelDate: moment(this.props.fulfillmentDetails.expdeliveryDate, [
          dateFormatFirstItem + " HH:mm:ss",
        ]),
      });
      // expectedDeliveryDate = moment(this.props.fulfillmentDetails.expdeliveryDate, [(dateFormatFirstItem+ " HH:mm:ss")]).format( (dateFormatFirstItem + ' HH:mm:ss') );
    }

    if (this.props.selectedAssignmentUser !== newProps.selectedAssignmentUser) {
      this.setValIndividual(newProps.selectedAssignmentUser);
    }
  }
  componentWillUnmount() {
    console.log("component will unmount");
    GLOBAL.AMSEXIST = "yes";
    GLOBAL.grpErrorColor = false;
  }

  myInputCallBackFun(myInput) {
    if (myInput.className == "nodisplay") {
      myInput.className = "";
    } else {
      myInput.className = "nodisplay";
    }
  }
  // AllAMSGrps(e)
  // {
  //   e.preventDefault();
  //   console.log("dbdjhc");
  //   this.setState({
  // 	AllAMSGrps:true
  //   });
  // }
  // renderSupportAMSGroup(groupData,selectedGroupId)
  // {
  // 	let grouplist = [];
  // 	if((!groupData || groupData.length==0)&&this.props.fulfillmentDetails.asgGroupid==0){
  // 	  return(
  // 		null
  // 	  );
  // 	}
  // 	if((groupData!=""||groupData.length!= 0))
  // 	groupData.map((breakFixObj)=>{
  // 		grouplist.push(breakFixObj.assignmentGroupId+"~"+breakFixObj.assignmentGroupName)

  // 	  });
  // 	  grouplist.push(this.props.fulfillmentDetails.asgGroupid+"~"+this.props.fulfillmentDetails.asgGroupname);
  // 	  grouplist = grouplist.filter( function( item, index, inputArray ) {
  // 		console.log("inputArray.indexOf(item)",inputArray.indexOf(item),index);
  // 		return inputArray.indexOf(item) == index;
  //  });
  // 	return grouplist.map((groupObj)=>{
  // 		let breakfixobj1=groupObj.split("~");
  // 	  if(selectedGroupId==breakfixobj1[0])
  // 	  {
  // 		return(
  // 		  <option value={breakfixobj1[0]} selected>{breakfixobj1[1]}</option>
  // 		);
  // 	  }
  // 	  else {
  // 		return(
  // 		  <option value={breakfixobj1[0]}>{breakfixobj1[1]}</option>
  // 		);
  // 	  }
  // 	});
  // }
  AllGrps(e) {
    e.preventDefault();
    console.log("dbdjhc", this.props.fulfillmentDetails.companyId);
    this.props.getAllgroupFulfillment(this.props.fulfillmentDetails.companyId);
    this.setState({
      AllAMSGrps: true,
      ShowAllAMSGRP: false,
      individualDropdownValue: "",
      showIndividualInfo: false,
    });
    //   GLOBAL.AMSGRP="yes";
    GLOBAL.AMSEXIST = "yes";
    this.setState({ selectedGroups: [] });
    GLOBAL.fulfillmentgroupval = "";
    GLOBAL.fulfillmentgrouptext = "";
    //GLOBAL.fulfillmentgroupval="";
    //GLOBAL.grpErrorColor=true;
    this.showGroupReason("");
  }
  AllAMSGrps(e) {
    let amsAttribute = {
      Status: this.props.fulfillmentDetails.itemStatus,
      Priority: "0",
      Service: this.props.fulfillmentDetails.offeringIdSx,
      "Impacted CI": "0",
      Requestor: this.props.fulfillmentDetails.requesterId,
      Class: "0",
      "User.Location": this.props.fulfillmentDetails.requesterLocationId,
      "CI.Location": "0",
    };
    let rulesAttribute = {
      status: this.props.fulfillmentDetails.itemStatus,
      serviceName: this.props.fulfillmentDetails?.offeringNameSx,
      userLocation: this.props?.fulfillmentDetails?.requesterLocationName,
      impactedUsername: this.props?.fulfillmentDetails?.requesterName,
      serviceId: this.props.fulfillmentDetails.offeringIdSx,
      impactedCiId: "0",
      requestorId: this.props.fulfillmentDetails.requesterId,
      classId: "0",
      userLocationId: this.props.fulfillmentDetails.requesterLocationId,
      ciLocationId: "0",
    };
    this.setState({ rulesAttribute: rulesAttribute });
    e.preventDefault();
    console.log("ALLAMSGRP", amsAttribute);
    // this.props.loadNewBreakFixSGAEWithQueryParams(
    //   this.props.fulfillmentDetails.offeringIdSx,
    //   this.props.fulfillmentDetails.companyId,
    //   "Fulfillment",
    //   "service",
    //   amsAttribute,
    //   this.props.fulfillmentDetails.serviceCompanyId
    // );

    // this.props.loadBreakFixSGAEEdit(this.props.fulfillmentDetails.offeringIdSx,this.props.fulfillmentDetails.companyId,"Fulfillment",true);
    this.setState({
      ShowAllAMSGRP: true,
      AllAMSGrps: false,
      individualDropdownValue: "",
      showIndividualInfo: false,
    });
    GLOBAL.AMSEXIST = "";
    //this.state.selectedGroups.length>0?GLOBAL.grpErrorColor=false:GLOBAL.grpErrorColor=true;
    this.setState({ selectedGroups: [] });
    GLOBAL.fulfillmentgroupval = "";
    GLOBAL.fulfillmentgrouptext = "";
    //GLOBAL.fulfillmentgroupval=this.props.fulfillmentDetails.asgGroupid;
    this.showGroupReason("");
  }
  showGroupReason(currentVal) {
    if (this.state.initialGroupId == currentVal) {
      this.setState({ showGrpReason: false, groupReasonText: "" });
      GLOBAL.showGroupReassignReasonErrorColor = false;
    } else {
      this.setState({ showGrpReason: true, groupReasonText: "" });
      GLOBAL.showGroupReassignReasonErrorColor = true;
    }
    GLOBAL.fulfillmentgroupReason = "";
  }
  // renderSupportUser(groupUserData){
  // 	//alert(this.props.supportPGroup);
  // 	//alert(breakFixData);
  // 	let optionsList = [],presentFlag=false;
  //  if (this.props.isFetchingDetails.isFetching) {
  //    return (
  //      <div className='rctLoader'>
  //        <ListLoader />
  //      </div>
  //    );
  //  }
  // 	if(!groupUserData || groupUserData.length==0){
  // 		return(
  // 				null
  // 		);
  // 	}
  // 	console.log("groupUserObj",groupUserData);
  // 			// return groupUserData.map((groupUserObj)=>{

  // 			// 	let setval="";
  // 			// 	if(this.props.fulfillmentDetails.asgUserid==groupUserObj.field1Value)
  // 			// 	{
  // 			// 	//	alert("yes")
  // 			// 	console.log("groupUserObj.field1Value",groupUserObj.field1Value);
  // 			// 	return(
  // 			// 				<option value={groupUserObj.field1Value} selected>{groupUserObj.field1Key}</option>
  // 			// 			);
  // 			// 		}
  // 			// 			else {
  // 			// 				return(
  // 			// 							<option value={groupUserObj.field1Value}>{groupUserObj.field1Key}</option>
  // 			// 						);
  // 			// 			}
  // 			// });
  // 			for(let i=0;i<groupUserData.length;i++){
  // 				if(this.props.fulfillmentDetails.asgUserid==groupUserData[i].field1Value)
  // 				{
  // 			        optionsList.push(<option value={groupUserData[i].field1Value} selected>{groupUserData[i].field1Key}</option>);
  // 					presentFlag=true;
  // 				}else{
  // 					optionsList.push(<option value={groupUserData[i].field1Value}>{groupUserData[i].field1Key}</option>);
  // 				}
  // 			}
  // 			if(presentFlag===false && GLOBAL.fulfillmentgroupval===this.props.fulfillmentDetails.asgGroupid){
  // 				if((this.props.fulfillmentDetails.asgUserid!=='0'||this.props.fulfillmentDetails.asgUserid!==0) && this.props.fulfillmentDetails.asgUsername!=='0'){
  // 					if(!this.state.isIndividualChanged){
  // 						GLOBAL.fulfillmentindividualtext=this.props.fulfillmentDetails.asgUsername,GLOBAL.fulfillmentindividualval=this.props.fulfillmentDetails.asgUserid;
  // 					}
  // 					optionsList.push(<option value={this.props.fulfillmentDetails.asgUserid} selected hidden>{this.props.fulfillmentDetails.asgUsername}</option>);
  // 				}
  // 			}
  // 			return optionsList;
  // }

  //   renderSupportGroup(groupData,selectedGroupId){
  // 	  console.log("renderSupportGroup() has been called : ",groupData)
  //     //alert(this.props.supportPGroup);
  //     //alert(breakFixData);
  // 	console.log("dropdown");
  // 	// this.props.setBreakFixSGAEName(breakFixData);
  // 	let grouplist = [];
  //     if((!groupData || groupData.length==0)&&this.props.fulfillmentDetails.asgGroupid==0){
  //       return(
  //         null
  //       );
  //     }
  // 	if((groupData!=""||groupData.length!= 0))
  // 	groupData.map((breakFixObj)=>{
  // 		grouplist.push(breakFixObj.assignmentGroupId+"~"+breakFixObj.assignmentGroupName)

  // 	  });
  // 	  if(this.props.fulfillmentDetails.asgGroupid!=null&&this.props.fulfillmentDetails.asgGroupname!=null&&this.props.fulfillmentDetails.asgGroupid!='0')
  // 	  grouplist.push(this.props.fulfillmentDetails.asgGroupid+"~"+this.props.fulfillmentDetails.asgGroupname);
  // 	  grouplist = grouplist.filter( function( item, index, inputArray ) {
  // 		console.log("inputArray.indexOf(item)",grouplist);
  // 		return inputArray.indexOf(item) == index;
  //  });
  //     return grouplist.map((groupObj)=>{
  // 		let breakfixobj1=groupObj.split("~");
  //       if(selectedGroupId==breakfixobj1[0])
  //       {
  //         return(
  //           <option value={breakfixobj1[0]} selected>{breakfixobj1[1]}</option>
  //         );
  //       }
  //       else {
  //         return(
  //           <option value={breakfixobj1[0]}>{breakfixobj1[1]}</option>
  //         );
  //       }
  //     });
  //   }

  renderComponent(compData) {
    //alert(this.props.supportPGroup);

    if (!compData.components) {
      return null;
    }

    return compData.components.map((compObj, index) => {
      return (
        <div className="details">
          <i className="fa fa-check bullets dCheckbox"></i>
          {/*	<Checkbox checked disabled className="dCheckbox><span></span></Checkbox>*/}

          {compObj.componentNameSx}
        </div>
      );
    });
  }

  renderQuestion(questionData, componentId) {
    //alert(this.props.supportPGroup);
    //console.log("componentId:::"+componentId)

    if (!questionData.questions) {
      //alert("hi")
      return null;
    }

    return questionData.questions.map((questionObj, index) => {
      //console.log("questionObj.question::::"+questionObj.question)

      if (questionObj.componentIdSx == componentId)
        return (
          <tr>
            <td>{questionObj.question}</td>
            <td>{questionObj.answer}</td>
          </tr>
        );
    });
  }

  renderHoldReasonName(holdData) {
    if (holdData.length == 0) {
      return null;
    }
    return holdData.map((obj, i) => (
      <option key={i} value={obj.field1Value}>
        {obj.field1Key}
      </option>
    ));
  }

  renderComplexity(complexityData) {
    if (complexityData.length == 0) {
      return null;
    }
    return complexityData.map((obj, i) => {
      return <option value={obj.field1Value}>{obj.field1Key}</option>;
    });
  }

  setReasonCode(event) {
    this.setState({ holdStatusDropdownValue: event.target.value });
    GLOBAL.fulfillmentHoldCode = event.target.value;
    GLOBAL.fulfillmentHoldVal = event.target[event.target.selectedIndex].text;
    this.props.hideHoldError();
  }
  setComplexityCode(event) {
    this.setState({ complexityValue: event.target.value });
    GLOBAL.complexityCode = event.target.value;
    //	GLOBAL.fulfillmentHoldVal=event.target[event.target.selectedIndex].text;
    //	this.props.hideHoldError();
  }

  userChoices(myOrderQuestionDetails) {
    console.log("myOrderQuestionDetails:::");

    //	console.log(myOrderQuestionDetails.length)
    console.log(myOrderQuestionDetails);
    if (
      myOrderQuestionDetails == undefined ||
      myOrderQuestionDetails.length <= 0
    ) {
      return <ListLoader />;
    }

    return []
      .concat(myOrderQuestionDetails)
      .sort((a, b) => (a.categorySequence > b.categorySequence ? 1 : -1))
      .map((category, i) => {
        //    console.log("category.questions")
        //  console.log(category.questions)
        return (
          <ListGroup.Item bsClass="">
            {/* <Accordion
            id={"questions" + i}
            accordion
            className="brkFix brkFixOrder brkFullAccordion margin-b-20"
            defaultActiveKey={i}
          >
            <Accordion eventKey={i} className="">
              <Accordion.Header>
                <h4 toggle className="font1">
                  {category.categoryName}
                </h4>
              </Accordion.Header>
              <Accordion.Body collapsible>
                <div className="table-responsive" key={i}>
                  <Table
                    bordered
                    condensed
                    hover
                    responsive
                    className="tableView sortImg"
                  >
                    <tbody>
                      {this.renderIndividualCategoryQuestions(
                        category.questions
                      )}
                    </tbody>
                  </Table>
                </div>
              </Accordion.Body>
            </Accordion>
          </Accordion> */}
            <Accordion
              id={"questions" + i}
              className="brkFix brkFixOrder brkFullAccordion"
              defaultActiveKey={[i]}
              alwaysOpen
            >
              <Accordion.Item eventKey={i}>
                <Accordion.Header>
                  <h4 toggle className="font1">
                    {category.categoryName}
                  </h4>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="table-responsive" key={i}>
                    <Table
                      bordered
                      condensed
                      hover
                      responsive
                      className="tableView sortImg"
                    >
                      <tbody>
                        {this.renderIndividualCategoryQuestions(
                          category.questions
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </ListGroup.Item>
        );
      });
  }
  // ShowAMSGroup(breakFixData)
  // {

  //   console.log("breakFixDatagropup",breakFixData=="");
  // 	let grouplist = [];
  // 	let type=typeof(breakFixData);
  // 	console.log("sabc",type);
  // 	if(breakFixData=="" || breakFixData.length==0||this.state.onCreateUsingSelect==""||type=="string"){
  // 	  return(
  // 		  null
  // 	  );
  // 	}
  // else{
  // breakFixData.map((breakFixObj)=>{
  //   grouplist.push(breakFixObj.groupName+"~"+breakFixObj.groupId);
  // });
  //   //  console.log(grouplist.toString());

  // 	// grouplist = grouplist.filter( function( item, index, inputArray ) {
  // 	//        return inputArray.indexOf(item) == index;
  // 	// });
  // 	//console.log("b:::"+grouplist.toString());

  // 	return grouplist.map((breakFixObj)=>{
  // 	  let breakfixobj1=breakFixObj.split("~");
  // 	 return(
  // 			 <option value={breakfixobj1[1]}>{breakfixobj1[0]}</option>
  // 		   );
  // 	 });
  // 	}
  // }
  renderIndividualCategoryQuestions(categoryQuestions) {
    let answers = "";
    return []
      .concat(categoryQuestions)
      .sort((a, b) => (a.questionSequence > b.questionSequence ? 1 : -1))
      .map((categoryQuestion, i) => {
        if (categoryQuestion.question == "Assets") {
          //	console.log("assets answer");
          //	console.log(categoryQuestion.answer);
          let newstringreplaced = "";
          if (
            typeof categoryQuestion.answer === "undefined" ||
            categoryQuestion.answer === null ||
            categoryQuestion.answer === ""
          ) {
            answers = "NA";
          } else if (
            categoryQuestion.answer != null &&
            categoryQuestion.answer != ""
          ) {
            newstringreplaced = categoryQuestion.answer.replace(
              /,Name/gi,
              "~Name"
            );
            answers = newstringreplaced.split("~");
          } else {
            answers = categoryQuestion.answer;
          }
        }
        return (
          <tr key={i}>
            <td className="bgBtn20 f-size-13" style={{ width: "50%" }}>
              {categoryQuestion.question}
            </td>
            <td className="f-size-13">
              {/*	{categoryQuestion.question !== "Assets" ?categoryQuestion.answer :<div className="assetsAnswers">{answers.map((a,i)=>{return <p>{a}</p>})};</div>}*/}
              {categoryQuestion.question !== "Assets" ? (
                typeof categoryQuestion.answer === "undefined" ||
                categoryQuestion.answer === null ||
                categoryQuestion.answer === "" ? (
                  "NA"
                ) : (
                  categoryQuestion.answer
                    .replace(/ ~ /g, "\n")
                    .split("\n")
                    .map((data) => {
                      console.log(data);
                      return <p>{data}</p>;
                    })
                )
              ) : answers != null ? (
                <div className="assetsAnswers">
                  {answers.map((a, i) => {
                    return <p>{a}</p>;
                  })}
                  ;
                </div>
              ) : null}
            </td>
          </tr>
        );
      });
  }

  groupInputChange(e) {
    this.setState({
      typedValueGroup: e,
      selectedIndividuals: [],
      selectedGroups: [],
      individualDropdownValue: "",
      showIndividualInfo: false,
    });

    GLOBAL.fulfillmentgroupval = "";
    GLOBAL.fulfillmentgrouptext = "";
    GLOBAL.fulfillmentindividualval = "";
    GLOBAL.fulfillmentindividualtext = "";
    this.setState({
      grpvalidation: true,
    });
    GLOBAL.grpErrorColor = true;
    this.props.resetfulfillmentIndividual();
  }
  groupCrossClick() {
    this.groupInputChange("");
    this.setState({
      typedValueIndividual: "",
      individualDropdownValue: "",
      showIndividualInfo: false,
    });

    this.showGroupReason("");
  }

  individualInputChange(e) {
    this.setState({
      typedValueIndividual: e,
      selectedIndividuals: [],
      individualDropdownValue: "",
      showIndividualInfo: false,
    });
    (GLOBAL.fulfillmentindividualval = ""),
      (GLOBAL.fulfillmentindividualtext = "");
  }
  individualCrossClick() {
    this.individualInputChange("");
  }
  renderSourceName(){
    const optionList = [];
    const { breakFixSourceName = [] } = this.props;
    if(!breakFixSourceName.length){
      return;
    }
    optionList.push(<option value="">Select</option>);
    breakFixSourceName.map((sourceName) => { 
      optionList.push(<option value={sourceName.field1Value}>{sourceName.field1Key}</option>);
    });
    return optionList;
  }

  render() {
    const {summary=''} = this.props.fulfillmentDetails;
    if (
      this.props.isFetchingDetails.isFetching ||
      this.props.myOrderQuestionDetails == undefined
    ) {
      return (
        <div className="rctLoader">
          <ListLoader />
        </div>
      );
    }

    if (GLOBAL.fulfillmentrenderval <= 3) {
      this.props.loadBreakFixSGI(this.props.fulfillmentDetails.asgGroupid);
      GLOBAL.fulfillmentrenderval = GLOBAL.fulfillmentrenderval + 1;
    }
    let delTime = this.props.fulfillmentDetails.deliveryVal;
    let h = Math.floor(delTime / 60);
    if (h < 10 || h == 0) h = "0" + h;
    let m = parseInt(delTime % 60, 10);

    if (m < 10 || m == 0) m = "0" + m;
    let time = h + ":" + m + " hrs";

    let fulfillerIndividualValue = "";
    if (this.props && this.props.individualFieldVal) {
      fulfillerIndividualValue = this.props.individualFieldVal;
    } else if (
      this.props.fulfillmentDetails &&
      this.props.fulfillmentDetails.asgUserid
    ) {
      fulfillerIndividualValue = this.props.fulfillmentDetails.asgUserid;
    } else {
      // do nothing
    }

    // let dateformat = this.props.specificUserDetails.dateformat;
    let dateformat =
      this.props &&
      this.props.specificUserDetails &&
      this.props.specificUserDetails.dateformat
        ? this.props.specificUserDetails.dateformat
        : "";
    // console.log('dateformat ================',dateformat);

    let jwtdf = "MM-DD-YYYY";
    // let jwtdf = (dateformat) ? (dateformat.slice(0, 10).toUpperCase()) : '';

    // console.log('jwtdf - ',jwtdf);

    // console.log('this.props.fulfillmentDetails - ',this.props.fulfillmentDetails);
    let expectedDeliveryDate = moment();
    // console.log('expectedDeliveryDate initial=',expectedDeliveryDate);
    let dateFormatFirstItem = null;
    if (dateformat) {
      let dateFormatCopy = Object.assign(dateformat);
      if (dateFormatCopy) {
        let dateFormatArr = dateFormatCopy.split(" ");
        if (dateFormatArr && dateFormatArr.length) {
          dateFormatFirstItem = dateFormatArr[0].toUpperCase();
          jwtdf = dateFormatFirstItem;
        }
      }
    }
    // console.log('***********************',this.props, dateFormatFirstItem);
    // console.log(' ------------------------', (dateFormatFirstItem + ' HH:mm:ss'));
    // if(this.props.fulfillmentDetails && this.props.fulfillmentDetails.expdeliveryDate && dateFormatFirstItem){
    // 	GLOBAL.fulfillmentDate=this.props.fulfillmentDetails.expdeliveryDate;
    // 	expectedDeliveryDate = moment(this.props.fulfillmentDetails.expdeliveryDate, [(dateFormatFirstItem+ " HH:mm:ss")]).format( (dateFormatFirstItem + ' HH:mm:ss') );
    // }
    // console.log('expectedDeliveryDate - ',expectedDeliveryDate);
    // console.log(this.props.fulfillmentDetails.expdeliveryDate)
    // expectedDeliveryDate=this.props.fulfillmentDetails.expdeliveryDate;
    // let errorExpDelDate = true;

    // console.log('this.props.fulfillmentDetails - ',this.props.fulfillmentDetails);

    // let itemId = (this.props && this.props.fulfillmentDetails && this.props.fulfillmentDetails.itemId) ? this.props.fulfillmentDetails.itemId : null;
    // console.log(' &&&&&&&&&&&&&&&&&&&&&&&&&&&&& ',expectedDeliveryDate, ' --- ',jwtdf);

    // console.log('this.state - ',this.state);

    let _typeaheadGroup;
    let _typeaheadIndividual;

    return (
      <div>
        {/*<Row className="margin-b-20">
				<Col xs={12}>
					<OrderFullFilmentStatusTimeLine fulfillmentDetails = {this.props.fulfillmentDetails} itemId={this.props.itemId} showPutOnHold={this.props.showPutOnHold} translator={this.props.translator} individualDropdownValue = {fulfillerIndividualValue}/>
				</Col>
			</Row>*/}

        {this.props && this.props.showTimelineFlag ? (
          <GenericTimeline
            fulfillmentDetails={this.props.fulfillmentDetails}
            showPutOnHold={this.props.showPutOnHold}
            translator={this.props.translator}
            individualDropdownValue={fulfillerIndividualValue}
            itemId={this.props.itemId}
            module={"Fullfilment"}
            showTimeline={this.props.showTimeline}
          />
        ) : null}

        {/*Error message section, please use classes like success, danger, warning, info
			<Alert variant="danger" className="errorMessageSec">
				<p>The form has missing required fields, please revalidate the highlighted fields and update the form.</p>
			</Alert>
*/}
        <div className="borderForm bFormDiv">
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label bsClass="">
                  <span className="rStar"></span>
                  {this.props.translator["Company"]}{" "}
                </Form.Label>
                <div className="position-re">
                  <Form.Control
                    disabled
                    type="text"
                    value={this.props.fulfillmentDetails.consumerCompanyName}
                  />
                </div>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group>
                <Form.Label bsClass="">
                  <span className="rStar"></span>
                  {this.props.translator["Requestor"]}
                  {this.state.isVIPuser == "yes" ? (
                    <i
                      className="vipIcn fa fa-diamond"
                      aria-hidden="true"
                      title={this.props.translator["VIP User"]}
                    ></i>
                  ) : (
                    ""
                  )}
                </Form.Label>
                <div className="infoBtnDiv typhdRig">
                  <Form.Control
                    disabled={
                      this.props.fieldStatus.requestor == false ||
                      this.props.disableFieldsForGuestRole
                        ? true
                        : false
                    }
                    type="text"
                    value={this.props.fulfillmentDetails.requesterName}
                  />
                  <Button
                    title="Info"
                    bsPrefix=" "
                    bsClass=""
                    className="infoicn"
                    onClick={() => {
                      this.props.showConsumerDetails(
                        this.props.fulfillmentDetails.requesterId,
                        "requestor",
                        "Requestor Details"
                      );
                      this.props.rightEditPanel(true);
                      this.props.setState({isVisible: true});
                    }}
                  >
                    <ImInfo />
                  </Button>
                </div>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group>
                <Form.Label bsClass="">
                  <span className="rStar"></span>
                  {this.props.translator["Service Ordered"]}{" "}
                </Form.Label>
                <div className="infoBtnDiv">
                  <Form.Control
                    disabled={
                      this.props.fieldStatus.serviceRequested == false ||
                      this.props.disableFieldsForGuestRole
                        ? true
                        : false
                    }
                    type="text"
                    value={this.props.fulfillmentDetails.offeringNameSx}
                  />
                  <Button
                    title="Info"
                    bsPrefix=" "
                    bsClass=""
                    className="infoicn"
                    onClick={() => {
                      this.props.showServiceDetails(
                        this.props.fulfillmentDetails.offeringIdSx
                      );
                      this.props.rightEditPanel(true);
                      this.props.setState({isVisible: true});
                    }}
                  >
                    <ImInfo />
                  </Button>
                </div>
              </Form.Group>
            </Col>
            {/* <Col md={6}>
					<Form.Group>
					      <Form.Label bsClass="">{this.props.translator['Hop Count']}</Form.Label>
						  <Form.Control disabled={this.props.fieldStatus.hopCount==false?true:false}  type="text" value={this.props.fulfillmentDetails.asgGroupHopCount} />
					</Form.Group>
					</Col> */}
            <Col md={6}>
              <Form.Group>
                <Form.Label bsClass="">
                  {this.props.translator["Through"]}
                </Form.Label>
                <Form.Control
                  disabled={
                    this.props.fieldStatus.through == false ||
                    this.props.disableFieldsForGuestRole
                      ? true
                      : false
                  }
                  as="select"
                  value={this.props.fulfillmentDetails.reportedThrough}
                >
                  {this.renderSourceName()}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            {/* <Col md={6}>
						<Form.Group >
						  <Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Target Time']}</Form.Label>
						  <Form.Control readOnly type="text" value={time} />
						 </Form.Group>
					</Col> */}
            <Col md={6}>
              {/* <Form.Group>
							<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Expected Completion By']} </Form.Label>
							<Form.Control readOnly type="text" value={this.props.fulfillmentDetails.expdeliveryDate} />
						</Form.Group> */}
              {/* <Form.Group>
							<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Expected Completion By']} </Form.Label>
							{this.props.fulfillmentDetails.itemStatus == "75" || this.props.fulfillmentDetails.itemStatus == "80" || this.props.fulfillmentDetails.itemStatus == "85" || this.props.fulfillmentDetails.itemStatus == "95" ?  
							<Form.Control readOnly type="text" value={this.props.fulfillmentDetails.expdeliveryDate} />:
							<div className="dateTimeSdiv">
								<Datetime  inputProps = {this.props.fulfillmentDetails.isExpComplDateEditable == null || this.props.fulfillmentDetails.isExpComplDateEditable == 'N'?{disabled:true}:{disabled:false}} onChange={(event)=>{this.changeDate(event)}}  locale="en" dateFormat={jwtdf} closeOnSelect timeFormat={true} value={this.state.expDelDate} />
								{this.props.showDateErrorText == '' ? '' : <span>{this.props.showDateErrorText}</span>}
							</div>}
						</Form.Group> */}
            </Col>
          </Row>
          <Row>
            {/* <Col md={6}>
						<Form.Group >
						  <Form.Label bsClass="">{this.props.translator['Service Value']}</Form.Label>
						  <Form.Control readOnly type="text" value={this.props.currency+" "+this.props.fulfillmentDetails.itemPrice} />
						 </Form.Group>
					</Col> */}

            {this.props.fulfillmentDetails.isCompxEditable == null ||
            this.props.fulfillmentDetails.isCompxEditable == "N" ? (
              ""
            ) : (
              <Col md={6}>
                <Form.Group>
                  <Form.Label bsClass="">
                    {this.props.translator["Complexity"]}
                  </Form.Label>
                  <Form.Select
                    componentClass="select"
                    disabled={
                      this.props.fulfillmentDetails.itemStatus == "75" ||
                      this.props.fulfillmentDetails.itemStatus == "80" ||
                      this.props.fulfillmentDetails.itemStatus == "85" ||
                      this.props.fulfillmentDetails.itemStatus == "95"
                        ? true
                        : false
                    }
                    value={
                      this.state.complexityValue == null
                        ? this.props.fulfillmentDetails.complexity
                        : this.state.complexityValue
                    }
                    onChange={(event) => {
                      this.setComplexityCode(event);
                    }}
                  >
                    <option value="">Select</option>
                    {this.renderComplexity(this.props.complexity)}
                  </Form.Select>
                </Form.Group>
              </Col>
            )}
          </Row>
          <Row>
            {this.props.fulfillmentDetails.status == "On Hold" ? (
              <Col md={6}>
                <Form.Group>
                  <Form.Label bsClass="">
                    {this.props.translator["Hold Status"]}
                  </Form.Label>
                  <Form.Select
                    required="true"
                    disabled
                    componentClass="select"
                    value={
                      this.state.holdStatusDropdownValue == null
                        ? this.props.fulfillmentDetails.holdingReasonCode
                        : this.state.holdStatusDropdownValue
                    }
                    onChange={(event) => {
                      this.setReasonCode(event);
                    }}
                  >
                    <option value="">Select</option>
                    {this.renderHoldReasonName(this.props.reasonCode)}
                  </Form.Select>
                </Form.Group>
                {this.props.showHoldError ? (
                  <div>
                    <font color="red">
                      <strong>Please select hold reason.</strong>
                    </font>
                  </div>
                ) : (
                  ""
                )}
              </Col>
            ) : (
              ""
            )}
          </Row>
          {summary==='' || summary===null ? null : <Row>
            <Col md={12}>
            <Form.Group>
                  <Form.Label bsClass="">
                    {this.props.translator["Summary"]}
                  </Form.Label>
                  <Form.Control as="textarea" value={summary} rows="3" maxLength={1000} disabled={true}/>
            </Form.Group>
            </Col>
          </Row>}

          <div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
            <div className="bgGray padding-5 inciSupProviderDv">
              {this.props.translator["Fulfiller"]}
              {this.props.fieldStatus.showAllGroups == true &&
              !this.props.disableFieldsForGuestRole &&
              this.props.fieldStatus.fulfillerGroupInd == true ? (
                <span><span>
                  {this.state.AllAMSGrps == false ? (
                    <a
                      className="showwAllGrps"
                      href="javascript:void(0)"
                      onClick={this.AllGrps}
                    >
                      Show ALL Groups
                    </a>
                  ) : (
                    <a
                      className="showwAllGrps"
                      href="javascript:void(0)"
                      onClick={this.AllAMSGrps}
                    >
                      Show AMS Groups
                    </a>
                  )}
                </span></span>
              ) : null}
            </div>
            <Row>
              <Col md={6}>
                <Form.Group
                  validationState={
                    GLOBAL.grpErrorColor === false ? "" : "error"
                  }
                >{console.log('Selected Grp --- ', this.state.selectedGroups)}
                  <Form.Label bsClass="">
                    {this.props.translator["Group"]}
                  </Form.Label>

                  <div className="dvTypehdropdown">
                    <div className="dvTypehd">
                      {!(this.props.fieldStatus.fulfillerGroupInd == false) &&
                      !this.props.disableFieldsForGuestRole &&
                      ((this.state.selectedGroups &&
                        this.state.selectedGroups.length > 0) ||
                        (this.state.typedValueGroup &&
                          this.state.typedValueGroup.length > 0)) ? (
                        <span
                          title="clear"
                          role="img"
                          aria-label="Clear"
                          className="css-hakgx8 icn"
                          onClick={() => {
                            // _typeaheadGroup.getInstance().clear();
                            this.groupCrossClick();
                          }}
                        >
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            role="presentation"
                          >
                            <path
                              d="M12 10.586L6.707 5.293a1 1 0 00-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 001.414 1.414L12 13.414l5.293 5.293a1 1 0 001.414-1.414L13.414 12l5.293-5.293a1 1 0 10-1.414-1.414L12 10.586z"
                              fill="gray"
                            ></path>
                          </svg>
                        </span>
                      ) : null}
                      <div
                        className={
                          this.state.selectedGroups.length === 0
                            ? "dvTypehd error"
                            : "dvTypehd"
                        }
                      >
                        <RuleAmsDdropDownNoReduxForm
                          name="supportGroup"
                          rulesAttribute={this.state.rulesAttribute}
                          moduleName="fulfillment"
                          formData={{ ItemList: this.props.fulfillmentDetails }}
                          disabled={
                            this.props.fieldStatus.fulfillerGroupInd == false ||
                            this.props.disableFieldsForGuestRole
                              ? true
                              : false
                          }
                          onChange={this.setValGroup}
                          onBlur={this.seterrorGroupcolor}
                          groupData={this.props.allGroups}
                          selected={this.state.selectedGroups}
                          onInputChange={this.groupInputChange}
                          showAms={this.state.AllAMSGrps}
                          ruleFor="fulfiller"
                          serviceCompanyId={this.props.fulfillmentDetails.serviceCompanyId}
                        />
                        {/* <Typeahead
                          ref={(ref) => {
                            _typeaheadGroup = ref;
                          }}
                          disabled={
                            this.props.fieldStatus.fulfillerGroupInd == false ||
                            this.props.disableFieldsForGuestRole
                              ? true
                              : false
                          }
                          onChange={this.setValGroup}
                          onBlur={this.seterrorGroupcolor}
                          options={
                            GLOBAL.AMSEXIST === "yes"
                              ? this.state.AllAMSGrps === false
                                ? this.props.breakFixSGAENameType
                                : this.props.allGroups
                              : this.props.breakFixSGAENameType
                          }
                          selected={this.state.selectedGroups}
                          placeholder={
                            this.props.translator["Please choose"] + "..."
                          }
                          onInputChange={this.groupInputChange}
                          renderMenuItemChildren={(option, props) => {
                            let label;
                            let title;
                            label =
                              option.label.length > 30
                                ? option.label.slice(0, 30) + "..."
                                : option.label;
                            title =
                              option.label.length > 30 ? option.label : "";
                            if (option?.supportCompanyName) {
                              return (
                                <div>
                                  <span
                                    className="typHdmainHead"
                                    title={title}
                                    style={{ display: "block" }}
                                  >
                                    {label}
                                  </span>
                                  <span
                                    className="typHdmainSubHead"
                                    style={{
                                      display: "block",
                                      "margin-top": "6px",
                                      color: "#777",
                                    }}
                                  >
                                    {option?.supportCompanyName || ""}
                                  </span>
                                </div>
                              );
                            }
                            return (
                              <div>
                                <span className="typHdmainHead" title={title}>
                                  {label}
                                </span>
                              </div>
                            );
                          }}
                        /> */}
                      </div>
                    </div>
                  </div>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group
                  className={`${
                    this.state.selectedGroups.length > 0
                      ? "infoBtnDiv typeHeadicn downinfobtn"
                      : ""
                  } ${
                    this.state.showIndividualInfo
                      ? "prblmBtnDv infoBtnDiv typeHeadicn downinfobtn"
                      : "chngBtnDv infoBtnDiv typeHeadicn downinfobtn"
                  }`}
                >
                  <Form.Label bsClass="">
                    {this.props.translator["Individual"]}
                  </Form.Label>
                  {/* <Form.Control componentClass="select"  disabled = {this.props.fieldStatus.fulfillerGroupInd==false?true:false} onChange={(event)=>{this.setVal(event,'individual')}} >
										<option value="">Select</option>
										{this.renderSupportUser(this.props.breakFixSGIName)}
									</Form.Control> */}
                  <div className="dvTypehdropdown">
                    <div className="dvTypehd">
                      {!(this.props.fieldStatus.fulfillerGroupInd == false) &&
                      !this.props.disableFieldsForGuestRole &&
                      ((this.state.selectedIndividuals &&
                        this.state.selectedIndividuals.length > 0) ||
                        (this.state.typedValueIndividual &&
                          this.state.typedValueIndividual.length > 0)) ? (
                        <span
                          title="clear"
                          role="img"
                          aria-label="Clear"
                          className="css-hakgx8 icn"
                          onClick={() => {
                            this.individualInputChange("");
                          }}
                        >
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            role="presentation"
                          >
                            <path
                              d="M12 10.586L6.707 5.293a1 1 0 00-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 001.414 1.414L12 13.414l5.293 5.293a1 1 0 001.414-1.414L13.414 12l5.293-5.293a1 1 0 10-1.414-1.414L12 10.586z"
                              fill="gray"
                            ></path>
                          </svg>
                        </span>
                      ) : null}
                      <Typeahead
                        ref={(ref) => {
                          _typeaheadIndividual = ref;
                        }}
                        disabled={
                          this.props.fieldStatus.fulfillerGroupInd == false ||
                          this.props.disableFieldsForGuestRole
                            ? true
                            : false
                        }
                        onChange={this.setValIndividual}
                        options={this.props.breakFixSGIENameTypeahead}
                        selected={this.state.selectedIndividuals}
                        placeholder={
                          this.props.translator["Please choose"] + "..."
                        }
                        onInputChange={this.individualInputChange}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      this.state.showIndividualInfo ||
                      this.state.selectedGroups.length > 0
                        ? `indiIcnDv`
                        : ""
                    }
                  >
                    {this.state.showIndividualInfo ? (
                      <Button
                        title="Info"
                        bsPrefix=" "
                        bsClass=""
                        className="asignicn"
                        onClick={() => {
                          this.props.showConsumerDetails(
                            this.state.individualDropdownValue,
                            "individual",
                            "Individual Details"
                          );
                          this.props.rightEditPanel(true);
                          this.props.setState({isVisible: true});
                        }}
                      >
                        <ImInfo />
                      </Button>
                    ) : null}
                    {this.state.selectedGroups.length > 0 ? (
                      <Button
                        title={
                          this.props.translator["Show available individuals"]
                        }
                        bsPrefix=" "
                        className="asignicn"
                        disabled={
                          this.props.fieldStatus.fulfillerGroupInd == false ||
                          this.props.disableFieldsForGuestRole
                            ? true
                            : false
                        }
                        onClick={() => {
                          this.props.showAvailableIndividual();
                          this.props.rightEditPanel(true);
                          this.props.setState({isVisible: true});
                        }}
                      >
                        <RiUserSearchLine />
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            {this.state.showGrpReason && (
              <Row>
                <Col md={12}>
                  <Form.Group validationState={this.props.grpReasonErrorColor}>
                    <span className="rStar"></span>
                    <Form.Label bsClass="">
                      {this.props.translator["Group Reassignment Reason"]}
                    </Form.Label>
                    <Form.Control
                      name="groupReassignReason"
                      as="textarea"
                      maxLength="3500"
                      rows={2}
                      className={
                        this.props.grpReasonErrorColor === "error"
                          ? "error"
                          : ""
                      }
                      onBlur={(event) => {
                        this.validateField("groupReassignReason", event);
                      }}
                      onChange={this.setGroupReason}
                      value={this.state.groupReasonText}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            )}
          </div>

          {/* <div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
						<div className="bgGray padding-5">{this.props.translator['Item Components & Requestor Choices']}</div>
						<Table className="maintInfo" responsive striped>
							<thead>
							  <tr>
								<th width="70%">{this.props.translator['Name']}</th>
								
							  </tr>
							</thead>
							<tbody>
							 {this.renderComponent(this.props.fulfillmentDetails)}
							</tbody>
						</Table>
					</div> */}

          {/*show component tab */}
          <ListGroup.Item bsClass="">
            <Accordion
              id="rightSidePanel3"
              accordion
              className="brkFix brkFixOrder brkFullAccordion margin-b-20"
            >
              <Accordion eventKey="1" className="collapsed">
                <Accordion.Header>
                  <h4 toggle>Item Components</h4>
                </Accordion.Header>
                <Accordion.Body collapsible>
                  <div>
                    {this.renderComponent(this.props.fulfillmentDetails)}
                    <span className="margin-l-10"></span>
                  </div>
                </Accordion.Body>
              </Accordion>
            </Accordion>
          </ListGroup.Item>
          {/**show information provided tab */}
          {this.props.myOrderQuestionDetails.length == 0 ? null : (
            <ListGroup.Item bsClass="">
              {/* <Accordion
                id="rightSidePanel4"
                accordion
                className="brkFix brkFixOrder brkFullAccordion margin-b-20"
                defaultActiveKey="1"
              >
                <Accordion
                  eventKey="1"
                  className="collapsed rad-check-align clLeftAlign"
                >
                  <Accordion.Header>
                    <h4 toggle>Information Provided</h4>
                  </Accordion.Header>
                  <Accordion.Body collapsible>
                    {this.userChoices(this.props.myOrderQuestionDetails)}
                  </Accordion.Body>
                </Accordion>
              </Accordion> */}
              <Accordion
                id="rightSidePanel4"
                className="brkFix brkFixOrder brkFullAccordion margin-b-20 wibAccordion"
                defaultActiveKey={["0"]}
                alwaysOpen
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h4>Information Provided</h4>
                  </Accordion.Header>
                  <Accordion.Body>
                    {this.userChoices(this.props.myOrderQuestionDetails)}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </ListGroup.Item>
          )}
        </div>
      </div>
    );
  }
}
export function mapStateToProps({
  breakFixSGAENameType,
  allGroups,
  breakFixSGIENameTypeahead,
  breakFixSGIName,
  fullFillmentStatusTimeLine,
  allgroupdata,
  breakFixSGAEName,
  reasonCode,
  complexity,
  specificUserDetails,
  isFetchingDetails,
  myOrderQuestionDetails,
  fieldStatus,
  breakFixSourceName,
}) {
  //  alert("breakFixEditDetails:"+breakFixAuditLogDetails);
  //console.log("breakFixEditDetails:"+breakFixAuditLogDetails.from);
  return {
    breakFixSGAENameType,
    allGroups,
    breakFixSGIENameTypeahead,
    breakFixSGIName,
    fullFillmentStatusTimeLine,
    breakFixSGAEName,
    allgroupdata,
    reasonCode,
    complexity,
    specificUserDetails,
    isFetchingDetails,
    myOrderQuestionDetails,
    fieldStatus,
    breakFixSourceName,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetfulfillmentIndividual,
      // loadBreakFixSGAEEdit,
      // loadNewBreakFixSGAEWithQueryParams,
      loadFulfillmentDetails,
      getGroupData,
      getBreakfixGroupData,
      getAllgroupFulfillment,
      loadSpecificUserDetails,
      loadBreakFixSGI,
      getFulfillmentTimeLineStatus,
      loadBreakFixAuditLogDetails,
      getQuestionsCategoryWise,
      loadFulfillmentReasonCode,
      loadBreakFixSourceName,
      getAdminPreferences
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(FulfillmentFormTab);
