
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ListGroup from 'react-bootstrap/ListGroup';
import Table from 'react-bootstrap/Table'
import Accordion from 'react-bootstrap/Accordion';
import {PiPencilSimpleBold} from "react-icons/pi";
import {IoClose} from "react-icons/io5";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {getRuleConfiguration,getCompanyData, getRuleCondition,getAMSAttributesOnly, resetAttributeList} from '../../../actions/amsActions/amsActions.js';
import {getAmsEditRuleConfig} from '../../../actions/common/queryBuilderAction.js';

class AmsHomeShortDesc extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
    this.onDemandAttributes=this.onDemandAttributes.bind(this);
  }
  onDemandAttributes(event){
    if(event.target.getAttribute('aria-expanded')){
      this.props.getAMSAttributesOnly(this.props.ruleId);
    }
   }

  editRuleConfiguration(ruleId) {
    this.props.getAmsEditRuleConfig(ruleId);
  }

  componentWillUnmount(){
    this.props.resetAttributeList();
  }
  render() {
    return (
      <div className="">
        <Row>
          <Col xs={12}>
            <div className="rPageHeadActBtn">
              <ul>
                <li>
                  <Link to="javascript:void(0)" onClick={() => { this.editRuleConfiguration(this.props.ruleId) }} title={this.props.tr['Edit']} ><PiPencilSimpleBold/></Link>
                </li>
                <li>
                  <button title={this.props.tr["Minimize the details panel"]} bsClass=""  onClick={() => { this.props.showRightSide(false); }} >
                  <IoClose/>
                  </button>
                </li>
              </ul>
            </div>
            <div className="rPageHeading">
              <div className="offNam margin-t-5 margin-b-5">{this.props.ruleName}</div>
            </div>
          </Col>
        </Row>

        <div className="rBoxGap">
          <div className="rBoxTabs hideTabNav">
          <Tabs activeKey={this.state.tabKey} onSelect={this.handleSelect} id="tabId">
            <Tab eventKey={1} tabIndex='1' title="Assignment Details">
              <ListGroup bsClass="" bsPrefix=' '>
                <ListGroup.Item bsPrefix=' ' bsClass="" className="rwTable" style={{ "border-top": "none" }}><span className="rwKey">{this.props.tr['Rule Name']} </span> <span className="rwVal">{this.props.ruleName}</span></ListGroup.Item>
                <ListGroup.Item bsPrefix=' ' bsClass="" className="rwTable"><span className="rwKey">{this.props.tr['Company']} </span> <span className="rwVal">{this.props.companyName}</span></ListGroup.Item>
                <ListGroup.Item bsPrefix=' ' bsClass="" className="rwTable"><span className="rwKey">{this.props.tr['Module']} </span> <span className="rwVal">{this.props.moduleName}</span></ListGroup.Item>
                <ListGroup.Item bsPrefix=' ' bsClass="" className="rwTable"><span className="rwKey">{this.props.tr['Assignment For']} </span> <span className="rwVal">{this.props.eventName}</span></ListGroup.Item>
                <ListGroup.Item bsPrefix=' ' bsClass="" className="rwTable"><span className="rwKey">{this.props.tr['Support Company']} </span> <span className="rwVal">{this.props.assignmentCompanyName}</span></ListGroup.Item>
                <ListGroup.Item bsPrefix=' ' bsClass="" className="rwTable"><span className="rwKey">{this.props.tr['Support Group']} </span> <span className="rwVal">{this.props.assignmentGroupName}</span></ListGroup.Item>
                <ListGroup.Item bsPrefix=' ' bsClass="" className="rwTable"><span className="rwKey">{this.props.tr['Weightage']} </span> <span className="rwVal">{this.props.weight}</span></ListGroup.Item>
                <ListGroup.Item bsPrefix=' ' bsClass="" className="rwTable"><span className="rwKey">{this.props.tr['Status']} </span> <span className="rwVal">{this.props.status}</span></ListGroup.Item>
              </ListGroup>
              {!this.props.isContextual?
              <div className="margin-t-20 clearboth brkFix brkFixOrder">
                <Accordion id="rightSidePanel6" activeKey={this.props.activePanelKey} onSelect={this.props.handlePanelClick} className="brkFix brkFixOrder">
                  <Accordion.Item>
                      <Accordion.Header  onClick={(e) => { this.onDemandAttributes(e) }}>
                        {this.props.tr['Attributes']}</Accordion.Header>
                    <Accordion.Body>
                      <Table responsive striped bordered condensed hover className="f-size-13">
                        <thead>
                          <tr>
                            <th>{this.props.tr["Name"]}</th>
                            <th>{this.props.tr["Value"]}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.props.ruleCondition.map((day, index) => {
                              return (
                                <tr>
                                  <td>{day.paramName}</td>
                                  <td>{day.paramValue}</td>

                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              : ''}
            </Tab>
            </Tabs>
            </div>
        </div>
      </div>
    );
  }
}
export function mapStateToProps({ allRuleConfigurationsData, allRuleConditions, ruleCondition }) {
  return { allRuleConfigurationsData, allRuleConditions, ruleCondition };
}

export default connect(mapStateToProps, {getAmsEditRuleConfig,getRuleConfiguration, getCompanyData, getRuleCondition, getAMSAttributesOnly, resetAttributeList })(AmsHomeShortDesc);





