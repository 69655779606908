
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import {Row, Col, Button,} from "react-bootstrap";
import ProcessDiagram from "./services/processHierarchy";
import ShortDescription from "./services/shortDescription";
import Attributes from "./Attributes";
import CopyOfferingForm from "./services/copyOfferingForm";
import ServiceSequencing from "./services/serviceSequencingList";
import { connect } from "react-redux";
import {IoClose} from "react-icons/io5";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';

class DetailsView extends React.Component {
  static contextType = MyContext;
  constructor(props) {
    super(props);
    this.state = {
      time: "",
      isRightSideVisible: "",
    };
    this.setExpirationDate = this.setExpirationDate.bind(this);
  }

  setExpirationDate(time) {
    this.setState({ time: time });
  }

  componentDidMount() {
		this.context.keyDownHandler(this.closeButton,"","","");
    this.context.newTabPrevent();
	}
	componentDidUpdate() {
		this.context.keyDownHandler(this.closeButton,"","","");
    this.context.newTabPrevent();
	}

  // completeBarFunction(myObj, countData) {
  //   delete countData.SERVICEFPLANS;

  //   let percentage = 0;
  //   for (var property in countData) {
  //     if (countData.hasOwnProperty(property)) {
  //     percentage += countData[property] > 0 ? 9 : 0;
  //     }
  //   }

  //   let total = 0,
  //     count = 0;
  //   let arr = [
  //     "DEMAND_REQUEST_NUMBER",
  //     "SUPPLIER_REFERENCE_NUMBER",
  //     "CONSUMPTION_METHOD",
  //     "NON_ENTITLED_VIEW",
  //     "UPDATED_AT",
  //     "UPDATEDBY_NAME",
  //     "UPDATED_BY",
  //     "APPROVAL_REQUIRED",
  //   ];
  //   arr.forEach((val, i) => {
  //     delete myObj[val];
  //   });
  //   for (var property in myObj) {
  //     total++;
  //     if (myObj[property] == null || myObj[property] == "undefined") {
  //       count++;
  //     }
  //   }
  //   return parseInt(((total - count) / total) * 10) + percentage;
  // }

  rightSidePageLoad(value) {
    const tr = this.props.tr;

    switch (value) {
      case "ShortDescription":
        // var completePercentage = this.completeBarFunction(
        //   this.props.spcmReducer.currentlySelectedServiceDetails,
        //   Object.assign({}, this.props.spcmReducer.serviceDetailCounts)
        // );
        return (
          <div>
            <ShortDescription
              tr={tr}
              serDetailsIcon={this.props.serDetailsIcon}
              serDetailsIconVar={this.props.serDetailsIconVar}
              showRightSide={this.props.showRightSide}
              rightSidePageNoFun={this.props.rightSidePageNoFun}
              copyOfferingToggle={this.props.copyOfferingToggle}
              copyOfferingToggleValue={this.props.copyOfferingToggleValue}
              // percent={completePercentage}
              service={this.props.currentService}
              pageCountNo1={this.props.pageCountNo}
              creServiceState1={this.props.creServiceState}
              creServiceFun1={this.props.creServiceFun}
              creServiceToggle={this.props.creServiceFun}
              rightComponentCountNo1={this.props.rightComponentCountNo}
              clickTabName1={this.props.clickTabName}
              listViewShow1={this.props.listViewShow}
              clickTabHeading1={this.props.clickTabHeading}
              componentFormToggle1={this.props.componentFormToggle}
              componentFormFun1={this.props.componentFormFun}
              editServiceFun={this.props.editServiceToggle}
              creServiceFun={this.props.creServiceFun}
              rightSidePageHead={this.props.rightSidePageHead}
              boardPageActive={this.props.boardPageActive}
              boardPageInActive2={this.props.boardPageInActive1}
              reviewShowFun2={this.props.reviewShowFun1}
              isReviewOpen2={this.props.isReviewOpen1}
              whichInsideFormOpen2={this.props.whichInsideFormOpen1}
              insideTableShowFun2={this.props.insideTableShowFun1}
              isInsideTableShow2={this.props.isInsideTableShow1}
              isPageEdit2={this.props.isPageEdit1}
              isLanEditPageOpen2={this.props.isLanEditPageOpen1}
              lanEditPageFun2={this.props.lanEditPageFun1}
              langActSecFun2={this.props.langActSecFun1}
              isLangActSecOpen2={this.props.isLangActSecOpen1}
              OFFERING_NAME={this.props.OFFERING_NAME}
              BUSINESS_FUNCTION_NAME={this.props.BUSINESS_FUNCTION_NAME}
              OFFERING_ID={this.props.OFFERING_ID}
              COMPANY_NAME={this.props.COMPANY_NAME}
              WORK_ITEM_TYPE={this.props.WORK_ITEM_TYPE}
              STATUS={this.props.STATUS}
              CATEGORY_NAME={this.props.CATEGORY_NAME}
              FEATURED={this.props.FEATURED}
              CREATED_AT={this.props.CREATED_AT}
              SUMMARY={this.props.SUMMARY}
              DELIVERY_MODE={this.props.DELIVERY_MODE}
              BASE_LANGUAGE={this.props.BASE_LANGUAGE}
              CREATED_BY={this.props.CREATED_BY}
              isRightSideVisible={this.state.isRightSideVisible}
              setActiveKeyVal={this.props.setActiveKeyVal}
              setServiceStatus={this.props.setServiceStatus}
              serviceStatusState={this.props.serviceStatusState}
              serviceCiId={this.props.serviceCiId}
              serviceOwnerid={this.props.serviceOwnerid}
              label={this.props.label}
            />
          </div>
        );
      case "CreateService":
        return (
          <div>
            <div className="rPageHeading">
              <div className="offNam margin-t-5 margin-b-5">
                {this.props.editServiceState
                  ? tr["Edit"]
                  : tr["Service Create View - Draft"]}
              </div>
            </div>
            <Attributes
              tr={tr}
              setExpirationDate={this.setExpirationDate}
              time={this.state.time}
              onChangeHandle={this.props.onChangeHandle}
              service={this.props.currentService}
              editServiceState={this.props.editServiceState}
              rightSidePageHead={this.props.rightSidePageHead}
              editServiceToggle={this.props.editServiceFun}
              creServiceState={this.props.creServiceState}
              creServiceToggle={this.props.creServiceFun}
              boardPageActive={this.props.boardPageActive}
            />
          </div>
        );
      case "CopyOffering":
        return (
          <div>
            <Row>
              <Col xs={12}>
                <div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
                  <ul>
                    <li>
                      <Link to="javascript:void(0)" className="closeRsBtn ctrlKeyPrevent" id="closeButton"
                          bsPrefix=' '
                          title="Minimize the copy offering section"
                          onClick={() => {
                            this.props.showRightSide(false);
                            this.props.setActiveKeyVal(1);
                            this.props.rightSidePageHead("default");
                            this.props.copyOfferingToggle(false);
                          }}
                          ref={e => this.closeButton = e}
                        >
                          <IoClose/>
                        </Link>
                    </li>
                  </ul>
                </div>
                <div className="rPageHeading">
                  <div className="offNam margin-t-5 margin-b-5">
                    {tr["Copy a Service"]}
                  </div>
                </div>
              </Col>
            </Row>
            <CopyOfferingForm
              tr={tr}
              insideEditOffering={false}
              copyOfferingToggle={this.props.copyOfferingToggle}
              copyOfferingToggleValue={this.props.copyOfferingToggleValue}
              rightSidePageHead={this.props.rightSidePageHead}
              boardPageActive={this.props.boardPageActive}
              componentFormFun1={this.props.componentFormFun}
              showRightSide={this.props.showRightSide}
              getServiceDetailsDataList={this.props.getServiceDetailsDataList}
              page={this.state.page}
              size={this.state.size}
              status={this.state.status}
              category={this.state.category}
              featured={this.state.featured}
              workItemType={this.state.workItemType}
              modifiedDateFilterValues={this.state.modifiedDateFilterValues}
              searchParams={this.state.searchParams}
              setSortOrder={this.setSortOrder}
              sortOnColumn={this.state.sortOnColumn}
            />
          </div>
        );
      case "ServiceSequencing":
        return (
          <div>
            <Row>
              <Col xs={12}>
                <div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
                  <ul>
                    <li>
                      <Link to="javascript:void(0)" className="closeRsBtn ctrlKeyPrevent" id="closeButton"
                        bsPrefix=' '
                        title="Minimize the service sequencing section"
                        onClick={() => {
                          this.props.showRightSide(false);
                          this.props.setActiveKeyVal(1);
                          this.props.rightSidePageHead("default");
                          this.props.copyOfferingToggle(false);
                        }}
                        ref={e => this.closeButton = e}
                      >
                        <IoClose/>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="rPageHeading">
                  <div className="offNam margin-t-5 margin-b-5">
                    {tr["Service Sequencing"]}
                  </div>
                </div>
              </Col>
            </Row>

            <ServiceSequencing
              tr={tr}
              componentFormFun={this.props.componentFormFun}
              componentFormToggle={this.props.componentFormToggle}
              setExpirationDate={this.setExpirationDate}
              time={this.state.time}
              onChangeHandle={this.props.onChangeHandle}
              service={this.props.currentService}
              editServiceState={this.props.editServiceState}
              rightSidePageHead={this.props.rightSidePageHead}
              editServiceToggle={this.props.editServiceFun}
              creServiceState={this.props.creServiceState}
              creServiceToggle={this.props.creServiceFun}
              boardPageActive={this.props.boardPageActive}
            />
          </div>
        );
      case "default":
        return (
          <div>
            <div className="rPageHeading">
              <div className="offNam margin-t-5 margin-b-5">
                {tr["Service Process Hierarchy"]}
              </div>
            </div>
            <div className="rBoxGap">
              <ProcessDiagram tr={tr} />
            </div>
          </div>
        );
    }
  }
  render() {
    return <div>{this.rightSidePageLoad(this.props.rightSidePageHead1)}</div>;
  }
}

const mapStateToProps = ({ spcmReducer }) => {
  return {
    spcmReducer,
  };
};

export default connect(mapStateToProps)(DetailsView);
