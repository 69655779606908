/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const NlpSearchApis = createApi({
    reducerPath: "NlpSearchApi",
    baseQuery: fetchBaseQuery({ baseUrl: window.location.origin,}),
    tagTypes: ["NLPSearch"],
    endpoints: (builder) => ({ 
        getAllServices: builder.query({
            query: ({ query }) => { 
                return {
                url: '/getServiceListForAMS',
                method: "GET",
                headers: { query },
            }},
        }),
        getAllCIs: builder.query({
            query: (searchParams) => {
                const { searchList, multipleValueList } = searchParams; 
                return {
                url: `/api/config_items/ci_details?searchByList=${searchList}&multipleValueList=${multipleValueList}`,
                method: "GET",
            }},
        }),
        getAllClasses: builder.query({ 
            query: () => {
                return {
                url: '/api/classes',
                method: "GET",
            }},
         }),
        getAllUsers: builder.query({
            query: ({ query }) => { 
               return {
                 url: '/api/companyBasedConsumerNew',
                 method: "GET",
                 headers: { query },
               }
            },
        }), 
        getAllCimGroups: builder.query({ 
            query: ({ query }) => {
                return {
                url: '/rules/rest/getCIMGroupListForIncident',
                method: "GET",
                headers: { query },
            }},
        }),
        getAllSupportGroups: builder.query({ 
            query: (companyId) => {
                return {
                url: `/asset-config/api/getCiSupportAllGroup/id/${companyId}`,
                method: "GET",
            }},
        }),
        getAllCompanies: builder.query({
            query: () => {
                return {
                    url: '/api/serviceportfolio/usercompanylist',
                    method: "GET",
                }},
        }),
        getAllReportedThrough: builder.query({
           query: () => {
            return {
                url: '/serviceManagement/rest/reportedThrough',
                mathod: "GET",
            }},
        }),
        getAllUrgencyAndImpant: builder.query({ 
            query: ({ companyId, moduleName, fieldName, languageSelected, status }) => {
                return {
                url: `/api/company_menulist?companyId=${companyId}&module=${moduleName}&field=${fieldName}&language=${languageSelected}&status=${status}`,
                method: "GET"
            }},
        }),
    })
});

export const { 
    useGetAllServicesQuery, 
    useGetAllCIsQuery,  
    useGetAllClassesQuery, 
    useGetAllUsersQuery, 
    useGetAllCimGroupsQuery, 
    useGetAllSupportGroupsQuery,
    useGetAllCompaniesQuery,
    useGetAllReportedThroughQuery,
    useGetAllUrgencyAndImpantQuery } = NlpSearchApis;
const { reducer } = NlpSearchApis;

export default reducer;
