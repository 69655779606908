
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, FormGroup, ListGroup, ListGroupItem } from 'react-bootstrap';
import GLOBAL from '_Globals';
import { Link } from 'react-router-dom';
import { editCICategory } from '../../../actions/categoryBoard/quickViewAction';
import { getCICategoryListData } from '../../../actions/categoryBoard/quickViewAction';
import swal from 'sweetalert';
import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";
import { PiPencilSimpleBold } from 'react-icons/pi';
import { MyContext } from '_MyContext';

class CiCategoryRightPart extends React.Component {
  static contextType = MyContext;
  constructor(props) {
    super(props);
    this.state = {
      category_name: '',
      sub_category_name: '',
      status: '',
      ciClassName: '',
      class_id: '',
      cat_company_id: '',
      category_id: '',
      category_nameErrorColor: '',
      sub_category_nameErrorColor: '',
      statusErrorColor: ''
    };
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleCloseClick = this.handleCloseClick.bind(this);
  }

  componentDidMount() {
		this.context.keyDownHandler(this.closeButton,this.editButton,"",this.saveObjRef);
	}
	componentDidUpdate() {
		this.context.keyDownHandler(this.closeButton,this.editButton,"",this.saveObjRef);
	}

  validateField(fieldType, event) {
    console.log("EVENT=========>", event.target.value)
    switch (fieldType) {
      case 'category_name':
        if (event.target.value.trim() === "") {
          this.setState({ category_nameErrorColor: 'error' });
        } else {
          this.setState({ category_nameErrorColor: '' });
        }
        break;
      case 'sub_category_name':
        if (event.target.value.trim() === "") {
          this.setState({ sub_category_nameErrorColor: 'error' });
        } else {
          this.setState({ sub_category_nameErrorColor: '' });
        }
        break;
      case 'status':
        if (event.target.value === "") {
          this.setState({ statusErrorColor: 'error' });
        } else {
          this.setState({ statusErrorColor: '' });
        }
        break;
      default:
    }
  }

  handleEditClick() {
    const { categoryData } = this.props;
    const { category_name, sub_category_name, status, class_id, category_id } = categoryData || {};

    this.setState({
      category_name: category_name || '',
      sub_category_name: sub_category_name || '',
      status: status || '',
      class_id: class_id || '',
      category_id: category_id || ''
    });
    this.props.setIsEditing(true)
  }

  handleSaveClick(event) {
    const { category_name, sub_category_name, status, class_id, category_id } = this.state;
    const { categoryData } = this.props;
    event.preventDefault();

    if (category_name.trim() === "" || sub_category_name.trim() === "" || status === "") {
      this.setState({
        category_nameErrorColor: category_name.trim() === "" ? 'error' : '',
        sub_category_nameErrorColor: sub_category_name.trim() === "" ? 'error' : '',
        statusErrorColor: status === "" ? 'error' : '',
      });
      swal({
        text: "Please fill all the mandatory fields.",
        button: 'OK',
      });
      return;
    }
    const updatedCategoryData = Object.assign({}, categoryData, {
      category_name: category_name,
      sub_category_name: sub_category_name,
      status: status,
      class_id: class_id,
      category_id: category_id,
      cat_company_id: categoryData.cat_company_id
    });
    console.log("updatedCategoryData", updatedCategoryData)
    this.props.editCICategory(updatedCategoryData, this.props.status);
    this.setState({
      category_name: updatedCategoryData.category_name,
      sub_category_name: updatedCategoryData.sub_category_name,
      status: updatedCategoryData.status,
      class_id: updatedCategoryData.class_id,
      category_id: updatedCategoryData.category_id,
      cat_company_id: updatedCategoryData.cat_company_id
    });
    this.props.setIsEditing(false);
      this.props.resetRightSide();
      this.props.showRightSide(false);
      // setTimeout(() => {
      //   this.props.getCICategoryListData(categoryData.cat_company_id, categoryData.class_id, this.props.status);
      // }, 600);
  }
  handleCloseClick() {
    this.props.setIsEditing(false);
    this.setState({
      category_nameErrorColor: '',
      sub_category_nameErrorColor: '',
      statusErrorColor: ''
    });
  }
  render() {
    const { categoryData } = this.props;
    const { category_name, sub_category_name, cat_company_name, status, cat_company_id } = categoryData || {};
    console.log("ciClassNameRight Part", this.props.ciClassName)
    console.log("Category data",categoryData)
    console.log("cat_company_id===>",categoryData.cat_company_id)
    return (
      <div className="margin-b-20">
        <div className="rPageHeadActBtn" style={{ marginTop: "-1px" }}>
          <ul>
            {(!this.props.isEditing && categoryData.cat_company_id != "0") && (
              <li>
                <Link 
                  to="javascript:void(0)"
                  title={this.props.tr['Edit']}
                  onClick={this.handleEditClick}
                  style={{ display: categoryData ? 'block' : 'none' }}
                  ref={e => this.editButton = e} >
                    <PiPencilSimpleBold />
                </Link>
              </li>
            )}
            {!this.props.isEditing && <li>
              <Link to="javascript:void(0)" title={this.props.tr["Minimize the details panel"]} bsClass="" bsStyle="" onClick={() => { this.props.showRightSide(false); }} ref={e => this.closeButton = e} >
                <IoClose />
              </Link>
            </li>}
          </ul>
        </div>
        <div className="rPageHeading">
          <div className="offNam margin-t-5 margin-b-5">CI Category Info</div>
        </div>

        <div className="rBoxGap">
          <ListGroup bsClass="" className="" bsPrefix=' '>
            {categoryData ? (
              <div>
                <ListGroupItem bsClass="" className="rwTable" style={{ borderTop: "none" }}>
                  <span className="rwKey">{this.props.tr['Company']} </span>
                  <span className="rwVal1">{cat_company_name}</span>
                </ListGroupItem>
                <ListGroupItem bsClass="" className="rwTable" bsPrefix=' '>
                  <span className="rwKey">{this.props.tr['Class']} </span>
                  <span className="rwVal1">{this.props.ciClassName}</span>
                </ListGroupItem>
                <ListGroupItem bsClass="" className="rwTable" bsPrefix=' '>
                  <span className="rwKey">{this.props.tr['Category']} </span>
                  <span className="rwVal1">
                    {this.props.isEditing ? (
                      <FormGroup validationState={this.state.category_nameErrorColor}>
                        <input
                          className={`form-control ${this.state.category_nameErrorColor}`}
                          type="text"
                          value={this.state.category_name}
                          onChange={(e) => this.setState({ category_name: e.target.value })}
                          onBlur={(event) => { this.validateField('category_name', event) }}
                          maxLength={50}
                        />
                      </FormGroup>
                    ) : (
                      category_name
                    )}
                  </span>
                </ListGroupItem>

                <ListGroupItem bsClass="" className="rwTable" bsPrefix=' '>
                  <span className="rwKey">{this.props.tr['Sub Category']} </span>
                  <span className="rwVal1">
                    {this.props.isEditing ? (
                      <FormGroup validationState={this.state.sub_category_nameErrorColor}>
                        <input
                          className={`form-control ${this.state.sub_category_nameErrorColor}`}
                          type="text"
                          value={this.state.sub_category_name}
                          onChange={(e) => this.setState({ sub_category_name: e.target.value })}
                          onBlur={(event) => { this.validateField('sub_category_name', event) }}
                          maxLength={50}
                        />
                      </FormGroup>
                    ) : (
                      sub_category_name
                    )}
                  </span>
                </ListGroupItem>
                <ListGroupItem bsClass="" className="rwTable" bsPrefix=' '>
                  <span className="rwKey">{this.props.tr['Status']} </span>
                  <span className="rwVal">
                    {this.props.isEditing ? (
                      <FormGroup validationState={this.state.statusErrorColor}>
                      <select
                        className={`form-control ${this.state.statusErrorColor}`}
                        value={this.state.status}
                        onChange={(e) => this.setState({ status: e.target.value })}
                        
                      > 
                          <>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </>
                        
                        )
                      </select>
                      </FormGroup>
                    ) : (
                      status.charAt(0).toUpperCase() + status.slice(1)
                    )}
                  </span>
                </ListGroupItem>
                {this.props.isEditing && (
                  <ListGroupItem style={{ borderBottom: "none" }} className="rwTable" bsPrefix=' '>
                    {/* <span className="rwKey"></span> */}
                    <span className="offNam margin-t-5 margin-b-5">
                      <ButtonToolbar className='black'>
                        <Link
                          size='sm'
                          to="javascript:void(0)"
                          className="rgSidrkBtn smallBtn"
                          onClick={this.handleSaveClick}
                          title={this.props.tr['Save']}
                          ref={(e) => (this.saveObjRef = e)}
                        >{this.props.showLoader ? (<ImSpinner6 className="icn-spinner"/>) : ( <span>{this.props.tr['Save']}</span>)}
                        </Link>
                        <Link
                          size='sm'
                          to="javascript:void(0)"
                          className="rgSiLigBtn smallBtn"
                          onClick={this.handleCloseClick}
                          title={this.props.tr['Close']}
                          ref={e => this.closeButton = e}
                        >
                          Close
                        </Link>
                      </ButtonToolbar>
                    </span>
                  </ListGroupItem>
                )}
              </div>
            ) : (
              <div className="rWelcomeText">Your Data Will Display Here!</div>
            )}
          </ListGroup>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ showLoader }) => {
  return {
    showLoader: showLoader.loading,
  }
}
export default connect(mapStateToProps, { editCICategory, getCICategoryListData })(CiCategoryRightPart);
