
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { loadAllRosterList } from '_Actions/onCallSchedule/roster/onCallRosterAction';
import { HiPlus, HiOutlineRefresh } from "react-icons/hi";
import { getOnCallGroups } from '_Actions/onCallSchedule/common/onCallCommonAction.js';
import { _dropDown } from "../../../common/ReduxFormFields/CategoryBoard/formFields.js";
import { change, Field, reduxForm } from 'redux-form';
import { LuFilterX } from "react-icons/lu";
import CommonHeaderMenu from "../../common/CommonHeaderMenu.js";
import onCallDropdownPlusTypeahead from "../../common/onCallDropdownPlusTypeahead";

const Header = (props) => {
  const dispatch = useDispatch();
  const tr = useSelector((state) => state.spcmReducer.tr);
  let groupList = useSelector((state) => state.onCallGroupList);
  let group = useSelector((state) => state.selectedOnCallGroup);
  const [groupErrorState, setGroupErrorState] = useState(false);
  const [groupInputValue, setGroupInputValue] = useState("");
  const [groupListOptions, setGroupListOptions] = useState([]);

  useEffect(() => {
    dispatch(getOnCallGroups());
  }, []);

  useEffect(() => {
    if (groupList && groupList.length > 0) {
      groupList = groupList.map((list) => {
        return {...list, id: list.groupId, label: list.groupName };
      });
      setGroupListOptions(groupList);
    }
  }, [groupList]);

  useEffect(() => {
    if(group && Object.keys(group).length>0){
      props.setSelectedGroupList(group);
      setGroupInputValue(group[0].label);
    }
  }, [group]);

  const onGroupSelection = (event) => {
    if (event && event[0]) {
      setGroupInputValue(event[0].label);
      dispatch(change("addUserToShift", "groupName", event[0].label));
      dispatch({ type: "SET_ONCALL_GROUP", group: event });
    } else {
      dispatch(change("addUserToShift", "groupName", ""));
      dispatch({ type: "SET_ONCALL_GROUP", group: "" });
    }
    props.setSelectedGroupList(event);
  };

  const setGroupError = () => {
    if (props.selectedGroupList.length === 0) {
      setGroupErrorState(true);
    } else {
      setGroupErrorState(false);
    }
  };

  const onGroupInputChange = (value) => {
    props.setSelectedGroupList([]);
    if (value) {
      setGroupInputValue(value);
    } else {
      setGroupInputValue("");
      onUGroupCrossClick();
    }
  };

  const onUGroupCrossClick = () => {
    props.groupCrossClicked();
    dispatch({ type: "SET_ONCALL_GROUP", group: "" });
    setGroupInputValue("");
  };

  return (
    <Row className="margin-b-15">
      <Col lg={8} md={6} sm={12} xs={12}>
        <CommonHeaderMenu active="Roster" />
      </Col>
      <Col lg={4} md={6} sm={12} xs={12}>
        <div className="paHedFilter">
          <Field
            component={onCallDropdownPlusTypeahead}
            errorColor={groupErrorState ? "error" : ""}
            onSelection={onGroupSelection}
            setErrorColor={setGroupError}
            name="header_group"
            id="header_group"
            className="form-control "
            options={groupListOptions}
            selectedOptions={props.selectedGroupList}
            onInputChange={onGroupInputChange}
            inputValue={groupInputValue}
            onCrossClick={onUGroupCrossClick}
            placeholder={tr["Select Group"]}
          />
          {props.isFilterApplied && (
            <Button
              bsPrefix=" "
              onClick={() => props.resetFilters()}
              role="button"
              className="myBt"
              title="Reset Filters"
            >
              <LuFilterX />
            </Button>
          )}
          <Link
            to="/onCallSchedule/createRoster"
            role="button"
            className="myBt plus fillBtn"
            title="Create"
          >
            <HiPlus />
          </Link>
          <Button
            bsPrefix=" "
            title="Refresh"
            role="button"
            className="myBt"
            onClick={() => {
              props.showRightSideFun(false);
              props.setActivePage(0);
              if (props.selectedGroupList && props.selectedGroupList.length > 0) {
                let filter = { page: 0, size: 10, groupId: props.selectedGroupList[0].id, ...props.filters };
                dispatch(loadAllRosterList(filter));
              }
            }}
          >
            <HiOutlineRefresh className="refreshFlip" />
          </Button>
        </div>
      </Col>
    </Row>
  );
}

export default reduxForm({
  form: "RosterListHeader",
})(Header);