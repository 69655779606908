
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export const implememtationPlanList = (state = [], action)=> {
	switch (action.type){
		case 'LOAD_IMPLPLAN_SUCCESS':
			return action.implPlanList;
			default:
			return state;
	}
}

export const cmdbPlanList = (state = {}, action)=> {
	switch (action.type){
		case 'LOAD_CMDB_SUCCESS':
			return action.cmdbList;
			default:
			return state;
	}
}