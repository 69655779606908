
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Row, Col, Button, Table, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getTaskPlans, setEditServiceDetails, setFullfilmentEditServiceDetails, setForm, getServiceDetailCounts, selectedTaskTemplate, getComponentName, setLeftEditId } from '../../../../actions/spcmActions';
import TaskPlanEditForm from './TaskPlanEditForm.js';
import constants from '../../../../utils/constants';
import { reduxForm } from 'redux-form';
import ListLoader from "../../../common/loaders/ListLoader";
import axios from 'axios';
import configureStore from '../../../../store/configureStore';
import Swal from 'sweetalert2';
import {HiPlus} from 'react-icons/hi';
import {IoClose} from "react-icons/io5";
import Cookies from "universal-cookie";
const cookies = new Cookies();

let userCompanyID = cookies.get("gph");
if (userCompanyID) userCompanyID = userCompanyID.replace("s:", "");
if (userCompanyID)
	userCompanyID = userCompanyID.substring(
		0,
		userCompanyID.lastIndexOf(".")
	);
userCompanyID = userCompanyID.split("~");
const userCompanyId = userCompanyID[48];
const store = configureStore();


class TaskPlanListView extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			deleteError: '',
			listFormToggle: 'list'
		};
		this.handleDismiss = this.handleDismiss.bind(this);
		this.listFormToggleFunc = this.listFormToggleFunc.bind(this);
	}

	handleDismiss() {
		this.setState({ deleteError: '' });
	}

	onDeleteClick(id) {
		let payload = {}
		payload.userCompanyId = parseInt(userCompanyId, 10);

		if (this.props.STATUS == "7" && this.props.SUB_STATUS == "12") {
      this.setState({
        deleteError:
          this.props.tr[
            "Changes are not allowed in Status = Deployed and Sub-Status = Functional."
          ],
      });
    } else {
      Swal.fire({
        title: "Are you sure you wish to delete this item?",		
        width: 550,
        padding: "15px",
        showDenyButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
        //   axios
        //     .delete(
        //       "/service/api/deleteFulfilmentPlan/" + id + "/" + this.props.CI_ID
        //     )
			axios.post("/service/api/deleteFulfilmentPlan/" + id, payload).then((resp) => {           
              // console.log(resp.data, 'resp');
              this.props.getTaskPlans(
                this.props.CI_ID,
                "ChangeManagement_Template"
              );
            })
            .catch((error) => {
              this.setState({ deleteError: error.response.data[0].message });
            });
        } else if (result.isDenied) {
        }
      });
    }
	}

	listFormToggleFunc(val) {
		this.setState({listFormToggle: val});
	}
	
	// componentWillUnmount() {
	// 	this.props.getTaskPlans(this.props.CI_ID);
	// }

	render() {

		const tr = this.props.tr;
		const { change, dispatch } = this.props;

		const ciTaskPlans = this.props.spcmReducer.ciTaskPlans;
		// console.log('ciTaskPlans====',ciTaskPlans);

		return (
			<div className="">
				<Row>
					<Col xs={12}>
						<div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
						<ul>
							<li>
							{this.state.listFormToggle == "form" ? (
								""
							) : (
								this.props.isRoleDisabled 
							? (
								<Button
								bsPrefix=" "
								onClick={() => {
									this.listFormToggleFunc("form");
									//  this.props.setFullfilmentEditServiceDetails({});
								}}
								title={this.props.tr["Add"]}
								bsClass=""
								bsStyle=""
								>
								<HiPlus />
								</Button>
							) : null
							)}
							</li>
							<li className="d-md-none">
							<button
							    type='button'
								title="Minimize the right panel"
								bsClass=""
								bsStyle=""
								className="closerightPanelBtn"
								onClick={() => {
								this.props.rightEditPanel(false);
								}}
							>
								<IoClose/>
							</button>
							</li>
						</ul>
						</div>
						<div className="rPageHeading">
						<div className="offNam margin-t-5 margin-b-5">
							{this.props.tr["Task Plan"]}
						</div>
						</div>
					</Col>
					</Row>

				<div className='rBoxGap'>
				{this.state.listFormToggle == 'form' ? <TaskPlanEditForm tr={tr} CI_ID={this.props.CI_ID} CI_NAME={this.props.CI_NAME} COMPANY_ID={this.props.COMPANY_ID} listFormToggleFunc={this.listFormToggleFunc} STATUS={this.props.STATUS} SUB_STATUS={this.props.SUB_STATUS} /> : <div>
					{this.state.deleteError == '' ? null : <Alert style={{ "marginBottom": "15px", "fontSize": "13px", "padding": "8px 30px 8px 8px" }} variant="danger" onDismiss={this.handleDismiss}><h4>{this.state.deleteError}</h4></Alert>}

					<Row className="show-grid selectFulPlan">
						<Col xs={5}>
							{tr['Task Plan For']}
						</Col>
						<Col xs={7}>
							<select className="form-control getFullfilmentHandler">
								<option value="ChangeManagement_Template">Change Requests</option>
							</select>
						</Col>
					</Row>

					{this.props.spcmReducer.spcmFetchingAttribute ?
						<ListLoader /> :
						<div>
							{ciTaskPlans.length == 0 || ciTaskPlans[0].tasks.length == 0 ? <div className="text-c">No Data Found</div> : <div className="f-size-12 fulPlans-listing">
								<Table bordered condensed hover responsive className="tableView tableViewMin sortImg tableWithOutPointer">
									<thead>
										{/* <tr>
											<th width="5%"  >{/*{tr['Status']}*/}
											{/* </th> */}
											{/* <th width=""  >TaskID</th>
											<th width="75%"  >
												<span>{tr['Task Name']}</span>
											</th>
											<th width="10%"  >{tr['Seq']}</th>
											<th width="10%" className="text-c">{tr['Action']}</th> */}
										{/* </tr> */} 
										<tr>
											<th width="10%"  >{/*{tr['Status']}*/}{tr['Seq']}</th>
											<th width="46%"  >{tr['Template Task Code']}</th>
											<th width="34%"  >
												<span>{tr['Task Name']}</span>
											</th>
											{/* <th width="5%"  >{tr['Seq']}</th> */}
											{this.props.isRoleDisabled ? ( <th className="text-c">{tr["Action"]}</th>) : null}
										</tr>
									</thead>
									<tbody>
										{

											ciTaskPlans[0].tasks.map(function (value, index) {
												let obj = value;
												obj.name = ciTaskPlans[0].name;

												return (<tr key={index}>
													<td>{value.status == 'Active' ? <span title="Active" className="btnStatus bgBtn2"></span> : <span title="In-Active" className="btnStatus bgBtn17"></span>}
													{value.sequenceNumber}
													</td>
													<td>{value.templateTaskCode}</td>	
													<td>
														<div>{value.description}</div>
													</td>
													{/* <td>{value.sequenceNumber}</td> */}

													
													{this.props.isRoleDisabled 
														? (
															<td>
															<div className="text-c">
																<i
																title={tr["Edit"]}
																onClick={() => {
																	this.props.setFullfilmentEditServiceDetails(obj);
																	this.props.setLeftEditId(value.templateTaskId);
																	this.listFormToggleFunc("form");
																}}
																className="fa fa-pencil-square-o cursorPoint"
																></i>
																<i
																title={tr["Delete"]}
																className="margin-l-10 fa fa-trash-o cursorPoint"
																onClick={() => {
																	this.onDeleteClick(value.templateTaskId);
																}}
																></i>
															</div>
															</td>
															) : null}
														

												</tr>);
											}, this)
										}
									</tbody>
								</Table>
							</div>
							}
						</div>
					}
				</div>
				}
				</div>
			</div>

		);
	}
}
const mapStateToProps = ({ spcmReducer }) => {
	return {
		spcmReducer
	};
};

export default connect(mapStateToProps, { getTaskPlans, setEditServiceDetails, setFullfilmentEditServiceDetails, setForm, getServiceDetailCounts, selectedTaskTemplate, getComponentName, setLeftEditId })(TaskPlanListView);
