
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState, useRef,useContext} from "react";
import { MyContext } from '_MyContext';
import { ListGroup, Row, Col, Tabs, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";


const MyShortDesc = (props) => {

  const closeButton = useRef(null);
  const context = useContext(MyContext);
  const tr = useSelector((state) => state.spcmReducer.tr);
  
  useEffect(() => {
    context.keyDownHandler(closeButton.current);
  }, []);

  let notificationData = props.notificationListData;
  if(props.dateFormat == "dd-MM-yyyy HH:mm:ss"){
    notificationData = moment(notificationData.CREATED_ON).format("DD-MM-YYYY HH:mm:ss")
  }else if(props.dateFormat == "yyyy-MM-dd HH:mm:ss"){
    notificationData = moment(notificationData.CREATED_ON).format("YYYY-MM-DD HH:mm:ss")
  }else{
    notificationData = moment(notificationData.CREATED_ON).format("MM-DD-YYYY HH:mm:ss")
  }
  return (
    <div className="">
      <Row>
        <Col xs={12}>
          <div className="rPageHeadActBtn" style={{ "marginTop": "-3px" }}>
            <ul>
              <li>
                <Link to="javascript:void(0)" className="closeRsBtn" id="closeButton"
                    title={tr["Minimize the details panel"]}
                    onClick={() => {props.showRightSideFun(false);}}
                    ref={closeButton}
                  >
                    <IoClose/>
                  </Link>
              </li>
            </ul>
          </div>
          <div className="rPageHeading">
            <div className="offNam margin-t-5 margin-b-5">{tr["Notification Details"]}</div>
          </div>
        </Col>
      </Row>
      <div className="rBoxGap">
        <div className="rBoxTabs hideTabNav">
          <Tabs id="tabId">
            <Tab eventKey={1} title={tr["Email"]}>
              <ListGroup bsPrefix=" " bsClass="">
                <ListGroup.Item
                  bsPrefix=" "
                  bsClass=""
                  className="rwTable"
                  style={{ "border-top": "none" }}
                >
                  <span className="rwKey">{tr["Date"]}</span>
                  <span className="rwVal">{notificationData}</span>
                </ListGroup.Item>
                {props.tab == "direct"?
                <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                <span className="rwKey">{tr["To"]}</span>
                <span className="rwVal">{props.notificationListData.TO}  </span>                                
                </ListGroup.Item>
                : ""
                }
                {props.tab == "direct"?                
                <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                  <span className="rwKey">{tr["CC"]}</span>
                  <span className="rwVal">{props.notificationListData.CC}</span>
                </ListGroup.Item>
                :""}
                <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                  <span className="rwKey">{tr["Subject"]}</span>
                  <span className="rwVal">{props.notificationListData.SUBJECT}</span>
                </ListGroup.Item>
                <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                            {/* <span className="rwKey">
                                {"MESSAGE"}
                            </span> */}
                              </ListGroup.Item>
                <Accordion id="rightSidePanel6" accordion className="brkFix brkFixOrder margin-t-25" >

                  <Accordion.Item eventKey={1} >
                    <Accordion.Header>
                      <div className='d-block' title="Message">{tr["Message"]}</div>
                    </Accordion.Header>
                    <Accordion.Body collapsible>
                      <span>
                        <div className='margin-t-10' dangerouslySetInnerHTML={{
                          __html: props.notificationListData.CONTENT
                        }}>
                        </div>
                      </span>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                      
              </ListGroup>
             
            </Tab>
            {/* <Tab eventKey={2} title={"SMS"}>
            <ListGroup bsPrefix=" " bsClass="">
                <ListGroup.Item
                  bsPrefix=" "
                  bsClass=""
                  className="rwTable"
                  style={{ "border-top": "none" }}
                >
                  <span className="rwKey">Date</span>
                  <span className="rwVal">{notificationData}</span>
                </ListGroup.Item>
                <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                  <span className="rwKey">Sent To</span>
                  <span className="rwVal">{props.notificationListData?.TO}</span>
                </ListGroup.Item>
                <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                  <Accordion
                    id="rightSidePanel6"
                    accordion
                    className="brkFix brkFixOrder margin-t-25"
                  >
                    <Accordion.Item eventKey={1} >
                      <Accordion.Header>
                        <div className='d-block' title="MESSAGE">MESSAGE
                        </div>
                      </Accordion.Header>
                      <Accordion.Body collapsible>

                        <div className='margin-t-10' dangerouslySetInnerHTML={{
                          __html: props.notificationListData.CONTENT
                        }}>
                        </div>
                      </Accordion.Body>
                      
                    </Accordion.Item>
                  </Accordion>
                </ListGroup.Item>
              </ListGroup>
            </Tab> */}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default MyShortDesc;
