
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export default function categoryListReducer(state = { list: [], isFetching: true, isError: false }, action) {
	switch (action.type) {

		case 'LOAD_CATEGORY_LIST_SUCCESS':
			return Object.assign({}, state,
				{
					list: action.categoryList.data,
					isFetching: action.isFetching,
					error: action.isError
				});
		case 'LOAD_CATEGORY_LIST_FAILURE':
			return Object.assign({}, state,
				{
					list: action.categoryList,
					isFetching: action.isFetching,
					error: action.isError
				});
		default:
			return state;
	}
}

