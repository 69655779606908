
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { GLOBAL } from "_Globals";

export const useMenuListDropdownOptions = (fieldType) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const getMenuListData = async () => {
      try {
        const url = "/api/menulistData";
        const queryParams = {
          field1_name: "",
          module: "CI",
        };

        switch (fieldType) {
          case "currency":
            queryParams.field1_name = "currency";
            break;
          case "chargeCategory":
            queryParams.field1_name = "chargeCategory";
            break;
          case "chargeFrequency":
            queryParams.field1_name = "chargeFrequency";
            break;
          case "chargeType":
            queryParams.field1_name = "chargeType";
            break;
          case "drTier":
            queryParams.field1_name = "disasterRecoveryTier";
            break;
          case "ownershipType":
            queryParams.field1_name = "ownershipType";
            break;
          case "depreciationScheduled":
            queryParams.field1_name = "depreciationSchedule";
            break;
            case "Dataset":
              queryParams.field1_name = "Dataset";
              break;
          default:
            break;
        }

        const objectToQueryString = (obj) => {
          return Object.keys(obj)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
            .join("&");
        };

        const queryStr = objectToQueryString(queryParams);
        const response = await axios.get(url, { headers: { query: queryStr } });

        if (response && response.data && response.data.length > 0) {
          const result = response.data.map(item => ({
            id: item["FIELD1_VALUE"],
            label: item["FIELD1_KEY"]
          }));
          setOptions(result);
        }
      } catch (err) {
        console.error("Error fetching menu list data: ", err);
      }
    };

    getMenuListData();
  }, [fieldType]);

  return options;
};


export const useDepartmentOptions = (companyId) => {
  const [departmentOptions, setDepartmentOptions] = useState([]);

  useEffect(() => {
    const getDepartment = async () => {
      try {
        setDepartmentOptions([]);
        const url = `/api/getDepartment?companyId=${companyId}`;
        const response = await axios.get(url);
        if (response && response.data && response.data.length > 0) {
          const result = response.data.map(item => ({
            id: item["field1Value"],
            label: item["field1Key"],
          }));
          setDepartmentOptions(result);
        }
      } catch (err) {
        console.error("Error fetching department data: ", err);
      }
    };

    if (companyId) {
      getDepartment();
    }
  }, [companyId]);

  return departmentOptions;
};
export const useLocationData = (companyId, locationToggle) => {
  const [locationOptions, setLocationOptions] = useState([]);

  useEffect(() => {
    const getLocationData = async () => {
      try {
        setLocationOptions([]);
        const url = `/api/getLocationData?${
          locationToggle === 'stockroom' ? 'locationType=Stockroom&' : ''
        }companyId=${companyId}`;
        const response = await axios.get(url, {
          headers: {
            query: JSON.stringify({
                locationType: locationToggle === 'stockroom' ? 'Stockroom' : undefined,
                companyID: companyId,
            }),
          },
        });
        if (response && response.data && response.data.length > 0) {
          const result = response.data.map(item => ({
            id: item.id,
            label: item?.location_name || "",
          }));
          setLocationOptions(result);
        }
      } catch (e) {
        console.error('Error in getLocationData:', e);
      }
    };

    if (companyId) {
      getLocationData();
    }
  }, [companyId, locationToggle]);

  return { locationOptions };
};

export const useDrLocationData = (companyId) => {
  const [drLocationOptions, setDrLocationOptions] = useState([]);

  useEffect(() => {
    const getDrLocationData = async () => {
      try {
        setDrLocationOptions([]);
        const url = `/api/getLocationData?companyId=${companyId}`;
          const response = await axios.get(url, {
          headers: {
            query: JSON.stringify({
                locationType: "Data Room",
                companyID: companyId,
            }),
          },
        });
        if (response && response.data && response.data.length > 0) {
          const result = response.data.map(item => ({
            id: item.id,
            label: item?.location_name || "",
          }));
          setDrLocationOptions(result);
        }
      } catch (e) {
        console.error('Error in getDrLocationData:', e);
      }
    };

    if (companyId) {
      getDrLocationData();
    }
  }, [companyId]);

  return { drLocationOptions };
};

export const useSupportCompany = (companyId) => {
  const [supportCompanyOptions, setSupportCompanyOptions] = useState([]);

  useEffect(() => {

    if (!companyId) {
      setSupportCompanyOptions([]);
      return;
    }


    const getSupportCompany = async () => {
      try {
        setSupportCompanyOptions([]);
        const url = `/asset-config/api/getCiSupportCompany/id/${companyId}`;
        const response = await axios.get(url);
        if (response && response.data && response.data.length > 0) {
          const result = response.data.map(item => ({
            id: item.field1Value,
            label: item.field1Key,
          }));
          setSupportCompanyOptions(result);
        }
      } catch (e) {
        console.error('Error fetching support company data:', e);
      }
    };

    getSupportCompany();
    
  }, [companyId]);

  return supportCompanyOptions;
};

export const useSupportGroup = (supportCompanyId) => {
  const [supportGroupOptions, setSupportGroupOptions] = useState([]);

  useEffect(() => {
    const getSupportGroup = async () => {
      try {
        setSupportGroupOptions([]);
        const url = `/asset-config/api/getCiSupportGroup/id/${supportCompanyId}`;
        const response = await axios.get(url);
        if (response && response.data && response.data.length > 0) {
          const result = response.data.map(item => ({
            id: item.field1Value,
            label: item.field1Key,
          }));
          setSupportGroupOptions(result);
        }
      } catch (e) {
        console.error('Error fetching support group data:', e);
      }
    };

    if (supportCompanyId) {
      getSupportGroup();
    }
  }, [supportCompanyId]);

  return supportGroupOptions;
};

export const useCostCenterOptions = (companyId,location_id) => {
  const [costCenterOptions, setCostCenterOptions] = useState([]);

  useEffect(() => {
    if (companyId === null || companyId === undefined || companyId === "" || companyId == 0 ){    
      setCostCenterOptions([]);
      return;
    }

    const getCostCenterOptions = async () => {
      try {
        setCostCenterOptions([]);
        const url = encodeURI(
          `/api/costCenterDropdownList/${companyId}`
        );
        const response = await axios.get(url,{
          headers: {
            'locationId': location_id 
          }
        });

        if (response && response.data && response.data.length > 0) { 
          const result = response.data.map(item => ({
            id: item.field1Value,
            label: item.field1Key,
          }));
          setCostCenterOptions(result);
        }
      } catch (e) {
        console.error('Error fetching cost center options:', e);
      }
    };

    getCostCenterOptions();

  }, [companyId, location_id]);

  return costCenterOptions;
};

export const useVendor = (companyId) => {
  const [vendorOptions, setVendorOptions] = useState([]);

  useEffect(() => {
    const getVendor = async () => {
      try {
        setVendorOptions([]);
        const url = `/asset-config/api/getCiSupportCompany/id/${companyId}`;
        const response = await axios.get(url);
        if (response && response.data && response.data.length > 0) {
          const result = response.data.map(item => ({
            id: item.field1Value,
            label: item.field1Key,
          }));
          setVendorOptions(result);
        }
      } catch (e) {
        console.error('Error fetching vendor data:', e);
      }
    };

    if (companyId) {
      getVendor();
    }
  }, [companyId]);

  return vendorOptions;
};


export const useVendorLessor = (companyId) => {
  const [vendorLessorOptions, setVendorLessorOptions] = useState([]);

  useEffect(() => {
    const getVendorLessor = async () => {
      try {
        setVendorLessorOptions([]);
        const url = `/asset-config/api/getCiSupportCompany/id/${companyId}`;
        const response = await axios.get(url);
        if (response && response.data && response.data.length > 0) {
          const result = response.data.map(item => ({
            id: item.field1Value,
            label: item.field1Key,
          }));
          setVendorLessorOptions(result);
        }
      } catch (e) {
        console.error('Error fetching vendor lessor data:', e);
      }
    };

    if (companyId) {
      getVendorLessor();
    }
  }, [companyId]);

  return vendorLessorOptions;
};

export const useComplianceRequirement = (companyId) => {
  const [complianceData, setComplianceData] = useState([]);

  useEffect(() => {
    if (!companyId) {
      setComplianceData([]);
      return;
    }

    const fetchCompliance = async () => {
      try {
        const response = await axios.get(`${GLOBAL.getCiAttributeValues}/${companyId}`, {
          headers: {
            QUERY: JSON.stringify({
              route: "/api/company_menulist?module=CMDB&field=Compliance",
              microserviceName: "foundation",
            }),
          },
        });

      
        let options = [];

        if (response?.data && typeof response?.data === 'string' && response?.data?.trim() === "") {
          setComplianceData([]);
          return;
        }

        if (response && response?.data!=="" && Array.isArray(response?.data)) {
          response?.data?.forEach((element) => {
            let obj = {
              value: element?.field1Value,
              label: element?.field1Key,
            };
            options.push(obj);
          });
        } 

        setComplianceData(options);
      } catch (error) {
        setComplianceData([]);
      }
    };

    fetchCompliance();
  }, [companyId]);

  return complianceData;
};




export const useDiscoverySource = (companyId) => {
  const [discoverySourceData, setDiscoverySourceData] = useState([]);
  useEffect(() => {

    if (!companyId) {
      setDiscoverySourceData([]);
      return;
    }

    const fetchDiscoverySource = async () => {
      try {
        const response = await axios.get(`${GLOBAL.getCiAttributeValues}/${companyId}`, {
          headers: {
            QUERY: JSON.stringify({
              route: "/api/company_menulist?module=CMDB&field=Discovery Source",
              microserviceName: "foundation",
            }),
          },
        });
        let options = [];

        if (response?.data && typeof response?.data === 'string' && response?.data?.trim() === "") {
          setDiscoverySourceData([]);
          return;
        }

          response.data.forEach((element) => {
            let obj = {};
            obj.value = element.field1Value;
            obj.label = element.field1Key;
            options.push(obj);
          });

          setDiscoverySourceData(options);
      } catch (error) {
        setDiscoverySourceData([]);
       
      } 
    };

    fetchDiscoverySource();
    
  }, [companyId]);

  return discoverySourceData;
};


export const useUserInfo = () => {
  const [userInfo, setUserInfo] = useState({
    user_id: '',
    user_roles_id: '',
    user_name: ''
  });

  useEffect(() => {
    const fetchUserInfo = async () => {
      let str = {};
      str.user_id = "";
      str = JSON.stringify(str);
      
      try {
        const response = await axios.get("/api/userInfo", { headers: { 'query': str } });

         setUserInfo({
          user_id: response.data.user_id,
          user_roles_id: response.data.role_id,
          user_name: response.data.user_name
        });
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    fetchUserInfo();
  }, []);

  return userInfo;
};


export const useMonitored = (companyId) => {
  const [monitoredData, setMonitoredData] = useState([]);
  useEffect(() => {

    if (!companyId) {
      setMonitoredData([]);
      return;
    }

    const fetchMonitored = async () => {
      try {
        const response = await axios.get(`${GLOBAL.getCiAttributeValues}/${companyId}`, {
          headers: {
            QUERY: JSON.stringify({
              route: "/api/company_menulist?module=CMDB&field=Monitored",
              microserviceName: "foundation",
            }),
          },
        });
        let options = [];

        if (response?.data && typeof response?.data === 'string' && response?.data?.trim() === "") {
          setMonitoredData([]);
          return;
        }


          response.data.forEach((element) => {
            let obj = {};
            obj.value = element.field1Value;
            obj.label = element.field1Key;
            options.push(obj);
          });

          setMonitoredData(options);
      } catch (error) {
        setMonitoredData([]);
      } 
    };

    fetchMonitored();
    
  }, [companyId]);

  return monitoredData;
};
