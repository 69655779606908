
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import Nav from 'react-bootstrap/Nav';
import {NavLink} from 'react-router-dom';
// import ScrollArea from 'react-scrollbar';

import {loadFunctionByPageAndRole} from '../../actions/common/functionActions.js';
import Cookies from 'universal-cookie';
import { openLinkInNewTab } from './helper.js';
const cookies = new Cookies();
let cookieString = cookies.get('gph');

if (cookieString) cookieString = cookieString.replace('s:', '');
if (cookieString) cookieString = cookieString.substring(0, cookieString.lastIndexOf('.'));
let cookieArray = cookieString.split("~");
let foundationUrl = cookieArray[35];
let sla_work_holiday = cookieArray[36];

class RightSidebarMenulistDynamic extends React.Component{
  componentWillMount(){
    let pageId = this.props.pageId;
    //console.log("function::::");
    //console.log(userDetails.functionlist);

    // this.props.loadFunctionByPageAndRole(pageId);
  }
  showSubMenu(){
   /*  console.log(!this.props.functions[0]);
    console.log(this.props.functions.constructor); */
  /*  if(userDetails.functionlist.length == 0){
      return(
        <div>{this.props.translator['No Data']}</div>
      );
    }else{
      return(
        <Nav bsPrefix=' '>
          {
            userDetails.functionlist.map(
              function(object, i){
                return <NavLink key = {i} to = {object.FUNCTIONACTION}><Nav.Item eventKey={{i}} ><span className={object.FUNCTIONICON}/>{object.FUNCTIONNAME}</Nav.Item></NavLink>;
              }
            )
          }

        </Nav>
      );
    }*/
    if(this.props.functions.constructor !== Array){
      return(
        <div>{this.props.tr["No Data"]}</div>
      );
    }else{
      return(
        <Nav bsPrefix=' '>
          {
            this.props.functions.map(
              function(object, i){
                if(object.functionAction!="addCart" && object.functionAction!="chatit" && object.functionAction!="myxalytic" && object.functionAction!="foundation")
                {
                  if((object.functionAction=="slaWorkdaylist" || object.functionAction=="slaHolidaylist" ) && (sla_work_holiday.toLowerCase()== false ||  sla_work_holiday.toLowerCase()== 'false')){
                    //Skip Holiday & Work
                  } else {
                    return <NavLink to = {object.functionAction}><Nav.Item eventKey={{i}} ><span className={object.functionIcon}/>{object.functionName}</Nav.Item></NavLink>;
                  }
                }
                if(object.functionAction=="myxalytic"){
                  return <NavLink to = "">
                    <Nav.Item onClick={()=> openLinkInNewTab("https://myxalytics.demo.hcldryice.com/loginformtbdel.aspx?queryuserid=xsmuser", "New Tab")}><span className={object.functionIcon}/>{object.functionName}
                    </Nav.Item>
                    </NavLink>;
                }
                if(object.functionAction=="foundation"){
                  return <NavLink to = "">
                    <Nav.Item onClick={()=> openLinkInNewTab(foundationUrl, "New Tab")}><span className={object.functionIcon}/>{object.functionName}
                    </Nav.Item>
                    </NavLink>;
                }

              }

            )
          }

        </Nav>
      );
    }
  }

  showSubmenu(){
    return(
      <Nav bsPrefix=' '>
        <Nav.Item eventKey={1}><span className="bgBtn1 rSidebar order"/>{this.props.tr["Track Order"]}</Nav.Item>
        <Nav.Item eventKey={2}><span className="bgBtn2 rSidebar history"/>{this.props.tr["Order History"]}</Nav.Item>
        <Nav.Item eventKey={3}><span className="bgBtn10 rSidebar myServices"/>{this.props.tr["My Services"]}</Nav.Item>
      </Nav>
    );
  }

	// showSubMenu(subMenuNumber){
	// 	switch(subMenuNumber){
	// 		case '1':return(
	// 			<Nav bsPrefix=' '>
	// 				<Nav.Item eventKey={1}><span className="bgBtn1 rSidebar order"/>Track Order</Nav.Item>
	// 				<Nav.Item eventKey={2}><span className="bgBtn2 rSidebar history"/>Order History</Nav.Item>
	// 				<Nav.Item eventKey={3}><span className="bgBtn10 rSidebar myServices"/>My Services</Nav.Item>
	// 			</Nav>
	// 		);
	// 		case '2':return(
	// 			<Nav bsPrefix=' '>
	// 				<Nav.Item eventKey={1}><span className="bgBtn1 rSidebar portfolio"/>Portfolio</Nav.Item>
	// 				<Nav.Item eventKey={2}><span className="bgBtn2 rSidebar supplier"/>Suppliers</Nav.Item>
	// 			</Nav>
	// 		);
	// 		case '4':return(
	// 			<Nav bsPrefix=' '>
	// 				<Nav.Item eventKey={1}><span className="bgBtn3 rSidebar dashboard"/>Quick View</Nav.Item>
	// 				<Nav.Item eventKey={2}><span className="bgBtn4 rSidebar quickview"/>List View</Nav.Item>
	// 				<Nav.Item eventKey={3}><span className="bgBtn5 rSidebar tasklist"/>Create Break-Fix</Nav.Item>
	// 				<Nav.Item eventKey={4}><span className="bgBtn6 rSidebar newtask"/>Create Investigation</Nav.Item>
	// 				<Nav.Item eventKey={5}><span className="bgBtn7 rSidebar ctchange"/>Create Change</Nav.Item>
	// 			</Nav>
	// 		);
	// 		case '5':return(
	// 			<Nav bsPrefix=' '>
	// 				<Nav.Item eventKey={1}><span className="bgBtn4 rSidebar publishd"/>Publish</Nav.Item>
	// 				<Nav.Item eventKey={2}><span className="bgBtn3 rSidebar reviews"/>Pending Reviews</Nav.Item>
	// 				<Nav.Item eventKey={3}><span className="bgBtn8 rSidebar retired"/>Retired</Nav.Item>
	// 			</Nav>
	// 		);
	// 		default:return(
	// 			<Nav bsPrefix=' '>
	// 				<Nav.Item eventKey={1}><span className="bgBtn1 rSidebar rCart"/>Cart</Nav.Item>
	// 				<Nav.Item eventKey={2}><span className="bgBtn2 rSidebar approvals"/>Approvals</Nav.Item>
	// 				<Nav.Item eventKey={3}><span className="bgBtn4 rSidebar rReview"/>Review</Nav.Item>
	// 				<NavLink to = "/createBreakFix"><Nav.Item eventKey={4}><span className="bgBtn11 rSidebar rWorksp"/>createBreakFix</Nav.Item></NavLink>
	// 				<NavLink to = "/editBreakFix"><Nav.Item eventKey={5}><span className="bgBtn11 rSidebar rWorksp"/>editBreakFix</Nav.Item></NavLink>
	// 				<NavLink to = "/quickViewBreakFix"><Nav.Item eventKey={6}><span className="bgBtn11 rSidebar rWorksp"/>Quick View</Nav.Item></NavLink>
	// 				<Nav.Item eventKey={7}><span className="bgBtn3 rSidebar rManSer"/>Manage Services</Nav.Item>
	// 			</Nav>
	// 		);
	// 	}
	// }
	render() {
		return (
    <div className={this.props.showhideClass ? 'rightNav showNav' : 'rightNav'}>
      {/* <ScrollArea className="myRightBarScroll" speed={0.8} contentClassName="content" minScrollSize={2} horizontal={false}> */}
        <div className="r-nav">
          {/* {this.showSubMenu(this.props.subMenuNumber)} */}
          {this.showSubMenu()}
        </div>
      {/* </ScrollArea> */}
    </div>
		);
	}
}

// function mapStateToProps(state,spcmReducer) {
//   //console.log(state.rightSidebarMenulist);
//   return { functions: state.rightSidebarMenulist,
//            tr:spcmReducer.tr }
// }

function mapStateToProps(state) {
  //console.log(state.rightSidebarMenulist);
  return { functions: state.rightSidebarMenulist,
           tr:state.spcmReducer.tr }
}

function mapDispatchToProps(dispatch){
	return bindActionCreators({ loadFunctionByPageAndRole }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RightSidebarMenulistDynamic)
