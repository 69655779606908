
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
let homepagelocation = cookies.get('gph');
if (homepagelocation) homepagelocation = homepagelocation.replace('s:', '');
if (homepagelocation) homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home") homepagelocation = "/" + homepagelocation;

var stylingObject = {
    body:{fontFamily:"verdana,sans-serif",margin:"0px",color:"#000",fontSize:"13px",lineHeight:"normal"},
    h1:{fontSize:"22px",color:"#929292",display:"inline-block",marginBottom:"10px"},
    p:{margin:"0px 0px 10px"},
    button:{border:"none",cursor:"pointer",display:"inline-block",color:"#fff",backgroundColor:"#10b5c5",padding:"10px 16px"},
    header:{textAlign:"center",width:"100%",background:"#EEF2F5",padding:"25px 0px",borderBottom:"1px #d2d7f2 solid",margin:"0px 0px 20px"},
    centerDiv:{textAlign:"center",marginTop:"40px"}
}

class Offline extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    } 
    render() {
        return (
            <div>
                <div className="minHeightQA" style={stylingObject.centerDiv}>
                    <h1 style={stylingObject.h1}>You are currently Offline</h1>
                    <p style={stylingObject.p}>You lost your network connection. Click the button below to try reloading.</p>
                    <button onClick={()=>{window.location.reload(true);}} style={stylingObject.button} title="Reload" type="button">Reload</button>
                </div>
            </div>
        );
    }
}

export default Offline;
