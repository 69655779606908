
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CloseBtnRightPanel from "../../common/closeBtnRightPanel";

const FormComponent = (props) => {
  const dispatch = useDispatch();
  const tr = useSelector((state) => state.spcmReducer.tr);
  return (
    <div className="stickyArea rBoxStyle">
      <CloseBtnRightPanel showRightSide={props.showRightSideFun} />
      <div className="rPageHeading">
        <div className="offNam margin-t-5 margin-b-5">{tr["Theme Preview"]}</div>
      </div>
      <div className="rBoxGap">Theme preview will be here!</div>
    </div>
  );
};

export default FormComponent;
