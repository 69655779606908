
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from "axios";

export function establishWebSocketConnection() {
	return (dispatch) => {
		axios.get('/connectToSocket').then().catch();
	};
}