
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import XsmSlaSupportHeader from "./header";
import XsmSlaDefForm from "./form";
import XsmProcessHierarchy from "./processHierarchy";
import { getTranslation } from "../../../actions/spcmActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../common/Breadcrumbs";
import "_Css/form/_form.scss";

const SlaDefCreateIndex = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [subMenu, setSubMenu] = useState("4");
  const [showHideAlfy, setShowHideAlfy] = useState(false);
  const [showHideRightNav, setShowHideRightNav] = useState(false);
  const [actionView, setActionView] = useState("assetgraph");
  const translator = useSelector((state) => state.spcmReducer.tr);

  useEffect(() => {
    dispatch(getTranslation());
  }, []);

  const showHideAlfySet = (value) => {
    setShowHideAlfy(value);
  };

  const showHideRightNavSet = (value) => {
    setShowHideRightNav(!showHideRightNav);
  };

  const changeActionView = (actionSelected) => {
    setActionView(actionSelected);
  };

  const showRightPaneAction = (actionView) => {
    switch (actionView) {
      case "assetgraph":
        return (
          <XsmProcessHierarchy ciId={params.ciId} translator={translator} />
        );
    }
  };

  return (
    <main>
      <div >
        {/* App Header Section Start*/}
        {/* <Header rightNavValue={showHideRightNavSet} /> */}
        {/* App Header Section End*/}

        {/* App Right Side MenuBar Section Start*/}
        {/* <RightSidebarMenulist
          showhideClass={showHideRightNav}
          subMenuNumber={subMenu}
        /> */}
        {/* App Right Side MenuBar Section End*/}

        {/* Support App Left Side MenuBar Section Start*/}
        {/* <LeftSidebarMenulist translator={translator} /> */}
        {/* Support App Left Side MenuBar Section End*/}

        <div className="container-fluid padding-t-10">
          <Breadcrumbs translator = {translator}/>
          <Form>
            {/* Home Page blue bar Section Start*/}
            <XsmSlaSupportHeader
              changeActionView={changeActionView}
              translator={translator}
              navigate={navigate}
            />
            {/* Home Page blue bar Section end*/}

            <Row className="row-eq-height">
              <Col md={8}>
                {/* Xsm Break Fix Form tabs Section Start*/}
                <XsmSlaDefForm translator={translator} />
              </Col>
              <Col md={4} sm={12} xs={12} className="colRightHeight">
                <div className="stickyArea">
                  {/* Xsm BreakFix Action Section Start*/}
                  {/*<XsmBreakFixAction />*/}
                  {showRightPaneAction(actionView)}
                  {/* Xsm BreakFix Action Section End*/}
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </main>
  );
};

export default SlaDefCreateIndex;
// class BreakFix extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       subMenu: "4",
//       showHideAlfy: false,
//       showHideRightNav: false,
//       actionView: "assetgraph",
//     };
//     this.showHideAlfy = this.showHideAlfy.bind(this);
//     this.showHideRightNav = this.showHideRightNav.bind(this);
//     this.changeActionView = this.changeActionView.bind(this);
//     this.showRightPaneAction = this.showRightPaneAction.bind(this);
//   }
//   showHideAlfy(value) {
//     this.setState({ showHideAlfy: value });
//   }
//   showHideRightNav(value) {
//     this.setState({ showHideRightNav: !showHideRightNav });
//   }
//   changeActionView(actionSelected) {
//     this.setState({
//       actionView: actionSelected,
//     });
//     //alert("actionSelected"+actionSelected);
//   }

//   showRightPaneAction(actionView) {
//     let ref = this;
//     switch (actionView) {
//       case "assetgraph":
//         return (
//           <XsmProcessHierarchy
//             ciId={this.props.params.ciId}
//             translator={this.props.translator}
//           />
//         );
//     }
//   }
//   render() {
//     return (
//       <div>
//         {/* App Header Section Start*/}
//         <Header rightNavValue={this.showHideRightNav} />
//         {/* App Header Section End*/}

//         {/* App Right Side MenuBar Section Start*/}
//         <RightSidebarMenulist
//           showhideClass={showHideRightNav}
//           subMenuNumber={subMenu}
//         />
//         {/* App Right Side MenuBar Section End*/}

//         {/* Support App Left Side MenuBar Section Start*/}
//         <LeftSidebarMenulist translator={this.props.translator} />
//         {/* Support App Left Side MenuBar Section End*/}

//         <div className="midMargin" bsClass="container-fluid">
//           <Form>
//             {/* Home Page blue bar Section Start*/}
//             <XsmSlaSupportHeader
//               changeActionView={this.changeActionView}
//               translator={this.props.translator}
//             />
//             {/* Home Page blue bar Section end*/}

//             <Row className="row-eq-height">
//               <Col md={8}>
//                 {/* Xsm Break Fix Form tabs Section Start*/}
//                 <XsmSlaDefForm translator={this.props.translator} />
//               </Col>
//               <Col md={4} sm={12} xs={12} className="colRightHeight">
//                 <div className="stickyArea">
//                   {/* Xsm BreakFix Action Section Start*/}
//                   {/*<XsmBreakFixAction />*/}
//                   {this.showRightPaneAction(actionView)}
//                   {/* Xsm BreakFix Action Section End*/}
//                 </div>
//               </Col>
//             </Row>
//             <Row>
//               <Col xs={12}>
//                 {/* Footer Section Start*/}
//                 {/*<FooterMain />*/}
//                 {/* Footer Section End*/}
//               </Col>
//             </Row>
//           </Form>
//         </div>
//       </div>
//     );
//   }
// }

// const mapStateToProps = ({ spcmReducer }) => {
//   return {
//     translator: spcmReducer.tr,
//   };
// };
// export default connect(mapStateToProps, { getTranslation })(BreakFix);
