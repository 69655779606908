
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Form from 'react-bootstrap/Form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import axios from 'axios';
import { GLOBAL } from '_Globals';
import { loadBreakFixAuditLogDetails } from '_Actions/breakFix/breakFixAuditLogAction';
import { getInvestigationItemDetails,getInvestigationItemDetailsById } from '_Actions/investigation/investigationAction.js';
import { loadStatusBasedHamburgerOptions } from '_Actions/common/loadStatusBasedHamburgerOptions';
import { loadTimelineData } from '_Actions/common/commonApisActions.js';
import swal from 'sweetalert';
import XsmProcessHierarchy from '../createInvestigation/ProcessHierarchy';
import {ImSpinner6} from "react-icons/im";
import {IoClose} from "react-icons/io5";

class ProposeCriticalTab extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			issueValidationError: '',
			savedMessage: '',
			// qualify: '',
			comments: '',
			// qualifyValidationError: '',
			saveLoader:false
		};
		// this.getQualify = this.getQualify.bind(this);
		this.getComments = this.getComments.bind(this);
	}

	// getQualify(event) {
	// 	//issue="";
	// 	this.setState({
	// 		qualify: event.target.value
	// 	})
	// 	console.log(event.target.value);
	// }

	getComments(event) {
		//issue="";
		if(event.target.value.trim() == ""){
			this.setState({
				comments:""
			})
		}else{
			this.setState({
				comments:"Cancellation Reason:"+" "+event.target.value
			})
		}
		console.log(event.target.value);
	}

	postRequest() {
		// if(this.props.invRelatedDataDetailsCount===0 && (this.props.investigationItemDetails.source==='Unresolved Incident'||this.props.investigationItemDetails.source==='Recurring Incident'||this.props.investigationItemDetails.source==='High Priority Incident'))
		// 		swal({
		// 			text: this.props.translator['Please link an appropriate Incident(s) with this Problem to proceed'],
		// 			button: this.props.translator['OK'],
		// 		  });
        // else
		if(this.props.user_id!=this.props.investigationItemDetails.asgPmId && (this.props.roleIdentification('16') || this.props.roleIdentification('17') ))
				   swal({
					  text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
					  button: this.props.translator['OK'],
				    });
		else
		{
		//this.onChangeUrgency();
		//alert(this.props.investigationId)
		//alert(this.state.issue)

		let ref = this;
		// let qualifyval = "";

		//alert(this.state.issue);

		// if (this.state.qualify == "" || this.state.qualify == "select") {
		// 	this.setState({
		// 		qualifyValidationError: "error"
		// 	})
		// } else {
		// 	this.setState({
		// 		qualifyValidationError: ''
		// 	})
		// }

		if (this.state.comments.trim() == "") {
			this.setState({
				issueValidationError: "error"
			})
		} else {
			this.setState({
				issueValidationError: ''
			})
		}

		//insert request start

		// if (this.state.qualify == "Y") {
		// 	qualifyval = '40'
		// }

		// if(this.state.qualify=="N")
		// {
		// 	qualifyval='70'
		// }

		let postjson = {

			"qualified": "N",
			"qualificationComments": this.state.comments,
			"cancellationComments": this.state.comments,
			"status": '70',
			"modifiedBy":"",
			"modifiedByName":""

		};
		let postjsonactivity = {
			"itemId":this.props.investigationId,
			"description": this.state.comments,
			"isInternal":"",
			"createdOn":"",
			"createdBy":"",
			"module":"Investigation",
			"createdByName":""
		};

	/*	let postjsonteam = {
			"investigationId": this.props.investigationId,
			"memberId": this.props.investigationItemDetails.asgPmId,
			"memberName":this.props.investigationItemDetails.asgPmName,
			"spGroupid": this.props.investigationItemDetails.asgPmGroupid,
			"spGroupname": this.props.investigationItemDetails.asgPmGroupname,
		};*/

		if (this.state.comments != "") {
			this.setState({saveLoader:true});
			axios.patch(GLOBAL.investigationEditUrl + this.props.investigationId, postjson)
				.then(function (response) {
					if (response.status === 200) {

						ref.setState({
							//savedMessage:"Requested cteated for order "+response.data.breakfixNumber
							savedMessage: "Problem Disqualification successfully done"
						})

						//boc post in activity log
						axios.post(GLOBAL.breakFixActivityLogPostUrl, postjsonactivity)
							.then(function (response) {
								this.setState({saveLoader:false});
								console.log("response.statusText:::" + response.status)
							})
							.catch(function (error) {
								this.setState({saveLoader:false});
								console.log(error);
								//alert("H1"+error);
							});

						ref.props.getInvestigationItemDetailsById(response.data.investigationId.toString());

						// ref.props.loadBreakFixAuditLogDetails(response.data.investigationId.toString(), "dsc", "Investigation",'STATUS');
						ref.props.loadStatusBasedHamburgerOptions('Investigation',response.data.status,response.data.investigationId);


					} else {
						console.log("response status is not ok:" + response.statusText)
						this.setState({saveLoader:false});
					}
					ref.props.changeActionView('');

				})
				.catch(function (error) {
					console.log(error);
					//alert("H1"+error);
				});

			}

		/*@Rajan
		commenting below post as problem can only be moved to root cause identified state
		when a team member is added
    Bug : Feb18Spring:INVESTIGATION
    "When the user moves a problem to "Root Cause" Status, it should only be allowed to move to
     this status when the user has formed the Problem Team.
     Please form problem team before the problem can be moved to next stage.

		*/
		/*if (this.state.qualify == "Y") {

			axios.post(GLOBAL.investigationTeamPostUrlUrl, postjsonteam)
				.then(function (response) {

					console.log("response.statusText:::" + response.status)
				})
				.catch(function (error) {
					console.log(error);
					//alert("H1"+error);
				});
		}*/
	 }
	}

	render() {
		//console.log("sm::"+this.state.savedMessage)
		if (this.state.savedMessage == "") {
			return (
				<div className="hambTab">
					{this.state.issueValidationError == "error" ? <div><font color="red"><strong>{this.props.translator['Please provide comments !']}</strong></font></div> : <div></div>}
					<div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Disqualify As Problem']}</div></div>
						<div className='rBoxGap'>

					<Form.Group>
						<Form.Label><span className="rStar"></span>{this.props.translator[' Comments']}</Form.Label>
						<Form.Control className={this.state.issueValidationError} as="textarea" rows="4" onChange={this.getComments.bind(this)} />
					</Form.Group>

					{this.state.savedMessage == "" || this.state.issueValidationError == "error" ?

					 <ButtonToolbar className="black margin-t-10">
					 <Button className='rgSidrkBtn smallBtn' disabled={this.state.saveLoader}
						 onClick={() => {this.postRequest();}} >
						   {this.state.saveLoader ?
						   <ImSpinner6 className="icn-spinner"/> : null} {this.props.translator['Save']}
					 </Button>
					 </ButtonToolbar>

					// <div>
					// 	<ButtonToolbar className="margin-t-15">
					// 		<Button bsStyle="primary" onClick={() => { this.postRequest(); }}>
					// 			{this.props.translator['Save']}
					// 		</Button>
					// 	</ButtonToolbar>
					//  </div>
					 :
					<div></div>
					}
					</div>
				</div>
			);
		}
		else {
			return (
				<div>
					<div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
						<ul>
							<li>
							<button
							    type='button'
								title="Minimize the right panel"
								bsClass=""
								bsStyle=""
								className="closerightPanelBtn"
								onClick={() => {
								this.props.rightEditPanel(false);
								}}
							>
								<IoClose />
							</button>
							</li>
						</ul>
					</div>
					<div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Disqualify As Problem']}</div></div>
					<div className='rBoxGap'>
						<div><font color="green" ><b>{this.state.savedMessage}</b></font></div>
					</div>
				</div>
			);
		}


	}
}

const mapStateToProps = ({ investigationItemDetails,invRelatedDataDetailsCount}) => {
	return {
		investigationItemDetails:(investigationItemDetails && investigationItemDetails.data ? investigationItemDetails.data[0]:investigationItemDetails),
		invRelatedDataDetailsCount
	};
};
export default connect(mapStateToProps, { loadBreakFixAuditLogDetails,getInvestigationItemDetailsById, getInvestigationItemDetails, loadStatusBasedHamburgerOptions, loadTimelineData })(ProposeCriticalTab);
