
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector, connect } from 'react-redux';
import { Row, Col, Button, Nav, NavDropdown, MenuItem, Tab, ListGroup } from 'react-bootstrap';

import { loadHamburgerCategory } from '_Actions/homepage/hamburgerAction';
import { LuFilterX, LuFileSearch, LuFileText, LuHistory } from "react-icons/lu";
import { IoMdOptions } from "react-icons/io";
import { Scrollbars } from "react-custom-scrollbars";

let CategoryFilter = (props) => {
  const dispatch = useDispatch();
  const translator = useSelector((state) => state.spcmReducer.tr);
  const [showCategoryFilterDropdown, setShowCategoryFilterDropdown] = useState(false);
  const newRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  const handleOutsideClick = (e) => {
    if (newRef.current && !newRef.current.contains(e.target)) {
      setShowCategoryFilterDropdown(false);
    }
  };

  useEffect(() => {
    dispatch(loadHamburgerCategory());
  }, []);

  const renderServiceCategories = () => {
    let selectedCategory = [];
    selectedCategory.push(<NavDropdown.Item as="li" data-categoryid={props.categoryId}><div className="selectedItem">{props.categoryName}</div></NavDropdown.Item>);
    let otherCategories = [];
    if (props.categoryId == 0) {
      otherCategories = props.hamburgerCategory.map((item) => {
        return <NavDropdown.Item as="li" style={{ cursor: "pointer" }} data-categoryid={item.categoryId} onClick={(e) => { props.setCategoryId(parseInt(e.target.getAttribute("data-categoryid"), 10)); props.setCategoryName(item.categoryName); }}>{item.categoryName}</NavDropdown.Item>
      });
    } else {
      let temp1 = [];
      temp1.push(<NavDropdown.Item as="li" style={{ cursor: "pointer" }} data-categoryid='0' onClick={(e) => { props.setCategoryId(0); props.setCategoryName('All'); }}>All</NavDropdown.Item>);
      let temp2 = props.hamburgerCategory.filter((item) => (item.categoryId != props.categoryId)).map((item) => {
        return <NavDropdown.Item as="li" style={{ cursor: "pointer" }} data-categoryid={item.categoryId} onClick={(e) => { props.setCategoryId(parseInt(e.target.getAttribute("data-categoryid"), 10)); props.setCategoryName(item.categoryName); }}>{item.categoryName}</NavDropdown.Item>
      });
      otherCategories = [...temp1, ...temp2];
    }
    return [...selectedCategory, ...otherCategories]
  }

  const renderKnowledgeCategories = () => {
    let selectedCategory = [];
    selectedCategory.push(<NavDropdown.Item as="li" data-categoryid={props.categoryId} data-categoryslug={props.categorySlug}><div className="selectedItem">{props.categoryName}</div></NavDropdown.Item>);
    let otherCategories = [];
    if (props.categoryId == 0) {
      otherCategories = props.knowledgeCategoriesList.map((item) => {
        return <NavDropdown.Item as="li" style={{ cursor: "pointer" }} data-categoryid={item.id} data-categoryslug={item.slug} onClick={(e) => { props.setCategoryId(parseInt(e.target.getAttribute("data-categoryid"), 10)); props.setCategorySlug(e.target.getAttribute("data-categoryslug")); props.setCategoryName(item.name); }}>{item.name}</NavDropdown.Item>
      });
    } else {
      let temp1 = [];
      temp1.push(<NavDropdown.Item as="li" style={{ cursor: "pointer" }} data-categoryid='0' data-categoryslug='' onClick={(e) => { props.setCategoryId(0); props.setCategorySlug(''); props.setCategoryName('All'); }}>All</NavDropdown.Item>);
      let temp2 = props.knowledgeCategoriesList.filter((item) => (item.id != props.categoryId)).map((item) => {
        return <NavDropdown.Item as="li" style={{ cursor: "pointer" }} data-categoryid={item.id} data-categoryslug={item.slug} onClick={(e) => { props.setCategoryId(parseInt(e.target.getAttribute("data-categoryid"), 10)); props.setCategorySlug(e.target.getAttribute("data-categoryslug")); props.setCategoryName(item.name); }}>{item.name}</NavDropdown.Item>
      });
      otherCategories = [...temp1, ...temp2];
    }
    return [...selectedCategory, ...otherCategories]
  }

  return (
    <ul className="sericon">
      <NavDropdown as="li" ref={newRef} show={showCategoryFilterDropdown} onClick={() => { setShowCategoryFilterDropdown(!showCategoryFilterDropdown) }} href={void (0)} className="topNavIcn d-lg-inline-block menuViewIcn padding-r-10" title={<span title={translator["Show search options"]}><IoMdOptions /></span>}>
      {props.searchOn == 'Service' ?
          (props.hamburgerCategory.length > 9 ? 
            <Scrollbars
              style={{ height: 300 }}
              autoHide={false}
              hideTracksWhenNotNeeded={true}
              className="timelineScroll"
            >
              {renderServiceCategories()}
            </Scrollbars>
            : renderServiceCategories()) : null}
        {props.searchOn == 'Knowledge' ?
          (props.knowledgeCategoriesList.length > 9 ? <Scrollbars
            style={{ height: 300 }}
            autoHide={false}
            hideTracksWhenNotNeeded={true}
            className="timelineScroll"
          >
            {renderKnowledgeCategories()}
          </Scrollbars> : renderKnowledgeCategories() ) : null}
      </NavDropdown>
    </ul>
  );
}

CategoryFilter = connect(({ hamburgerCategory, knowledgeCategoriesList }) => ({
  hamburgerCategory,
  knowledgeCategoriesList
}))(CategoryFilter);

export default CategoryFilter;
