
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const formTraitExtends = (e) => {
    e.DomComponents.addType("select", {
      model: e.DomComponents.getType("select").model.extend({
        init() {
          const traits = this.get("traits");
          traits.add([
            {
              type: "checkbox",
              label: "Disabled",
              name: "disabled",
            },
            {
              type: "select", // Type of the trait
              name: "data_source", // (required) The name of the attribute/property to use on component
              label: "Data Source", // The label you will see in Settings
              options: [],
              changeProp: 1,
            },
            {
              type: "select",
              label: "Label Key",
              name: "labelKey",
              options: [],
              changeProp: 1,
            },
            {
              type: "select",
              label: "Value Key",
              name: "valueKey",
              options: [],
              changeProp: 1,
            },
            {
              type: "text",
              label: "Default Value",
              name: "defaultValue",
              changeProp: 1,
            },
            {
              type: "checkbox",
              label: "Is Refreshable (Refresh On Data Save)",
              name: "isDataRefreshable",
              changeProp: 1,
            },
            {
              type: "text",
              label: "Label Key",
              name: "labelKeyMapping",
              changeProp: 1,
            },
            {
              type: "checkbox",
              label: "Hide Default (Select)",
              name: "hideSelect",
              changeProp: 1,
            },
            {
              type: "text",
              label: "Disabled Key",
              name: "disabledKey",
              changeProp: 1,
            }
          ]);
        },
      }),
    });
  };
  