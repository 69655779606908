
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import {connect} from 'react-redux';
import Spinner from 'react-spinkit';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Pagination from "react-js-pagination";
import swal from 'sweetalert';
const Joi = require('joi');

class AttachedArticles extends React.Component {
	constructor(props) {
		super(props);
		this.state ={
			pageSize: 5,
            currentPage: 1,
		};
		this.renderArticleHTML = this.renderArticleHTML.bind(this);
		this.removeAttachedArticle = this.removeAttachedArticle.bind(this);
		this.onPageSizeChange = this.onPageSizeChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
		this.getItemsBasedOnPagination = this.getItemsBasedOnPagination.bind(this);
		this.onDeleteIconClicked = this.onDeleteIconClicked.bind(this);
	};

	removeAttachedArticle(knowledgeId){
		this.props.removeAttachedArticle(knowledgeId);
	}

	renderArticleHTML(){
		const {attachedArticlesList} = this.props;
		const that = this;
		const allArticlesList = this.getItemsBasedOnPagination(attachedArticlesList);
		if(allArticlesList.length == 0){
			return(
				<tr>
					<td colSpan={2} className="article-table-td-align-center">{that.props.translator['No Attached Knowledge References Available']}</td>
				</tr>
			);
		}

		return allArticlesList.map((item, index) => {
			let iconClass = (item.type === 'SPCM') ? 'KA-icon interLnk' : (
				(item.type === 'Knowledge' || item.type === 'conversation') ? 'KA-icon exterLnk' : 'KA-icon community'
			);
			return(
				<tr key={`attachedArticles_${index}`} >
					<td className="text-c">
						{item.knowledgeId}
					</td>
					<td className="kn-title">
						<span>
							<span style={{verticalAlign:"middle",backgroundPosition:"0px 1px"}} className={iconClass} title={item.type == 'conversation' ? 'Knowledge' : that.props.translator[item.type]}></span>
							{that.props.renderLargeTextEllipse(item.knowledgeArticle)}
						</span>
					</td>
					<td className="text-c">
						{(that.props.checkEditAllowed()) && (
                            this.props.disableFieldsForGuestRole?'':<a title="Remove" className="margin-r-10" href="javascript:void(0)" onClick={() => {
                                that.onDeleteIconClicked(item.knowledgeId);
                            }}><i className="f-size-16 fa fa-trash-o colorStBlue" ></i></a>
                        )}
                        <a onClick={() => {
                            that.props.downloadFile(item.type, item['articleUrl'], item['id'])
                        }} href="javascript:void(0)"><i style={{"position":"relative","top":"2px"}} className={(item.type === 'SPCM') ? "fa fa-download f-size-16 colorStBlue" : "fa fa-external-link f-size-15 colorStBlue"}
                        title={(item.type === 'SPCM') ? that.props.translator['Download'] : that.props.translator['Redirect']}
                        ></i></a>
                    </td>
				</tr>
			);
		});
	}

	onPageSizeChange(e){
        this.setState({pageSize: e.target.value, currentPage: 1});
    }

    onPageChange(value){
		this.setState({currentPage: value});
    }

	getItemsBasedOnPagination(totalItems){
		try{
			const {currentPage, pageSize} = this.state;
			const startIndex = (Number(currentPage) * Number(pageSize) - Number(pageSize));
			return totalItems.slice( startIndex, (startIndex + Number(pageSize)) );
		} catch(e){
			return totalItems || [];
		}
	}
	onDeleteIconClicked(articleID){
		swal({
			text: this.props.translator['Are you sure? This will remove the association of Knowledge article with this Incident'],
			buttons: [this.props.translator['Cancel'],this.props.translator['OK']],
		}).then((willDelete) => {
            if (willDelete) {
				this.removeAttachedArticle(articleID);
            }
        });
	}

   render() {

   	// console.log('attached articles *********',{state:  this.state, props: this.props});

   	const { pageSize, currentPage } = this.state;
	   const {
		translator = {},
		attachedArticlesList
	   } = this.props;

      return (
      	<div className="margin-t-20 knowledge-search-section attached-articles-section">
            <Row>
                <Col md={12}>
					<h2 style={{marginBottom:"0px"}}>{translator["Attached Articles"]}</h2>
                    {/* <div className="padding-b-10 margin-b-10 black f-size-12 fw-300"><b>{translator["Attached Articles"]}</b></div> */}
                </Col>
                <Col md={12}>
					<div>
						<Table className="table table-striped table-bordered table-condensed table-hover" style={{'margin-top': '10px', 'margin-bottom': '0px'}}>
							<thead>
								<tr>
									<th width="21%" className="">
										<span>{translator["Topic ID"]}</span>
									</th>
									<th width="58%" className="">{translator["Knowledge Articles"]}</th>
									{/* <th className="text-c">Type</th> */}
									<th width="21%" className="text-c">{translator["Actions"]}</th>
								</tr>
							</thead>
							<tbody>
								{this.renderArticleHTML()}
							</tbody>
						</Table>
						{(attachedArticlesList && attachedArticlesList.length > 0) && (
							<Row className="margin-t-20">
								<Col lg={5} md={7} sm={7} xs={7} className="">
									<div className="">
									<Form.Label className="margin-r-5 display-inline-block widthAuto">{translator['Show']}</Form.Label>
									<Form.Select 
									    componentClass="select"
										aria-label="select"
										className="display-inline-block widthAuto" 
										onChange={(e) => this.onPageSizeChange(e)}
										value={pageSize}
									>
										{/* <option value={1}>1</option> */}
										<option value={5}>5</option>
										<option value={10}>10</option>
										<option value={20}>20</option>
										<option value={9999}>All</option>
									</Form.Select>
									</div>
								</Col>
								<Col lg={7} md={5} sm={5} xs={5}>
									<div className="text-r float-r">
										<Pagination
											activePage={currentPage}
											itemsCountPerPage={pageSize}
											totalItemsCount={attachedArticlesList.length}
											onChange={this.onPageChange}
											pageRangeDisplayed={this.props.windowSize < 1366 ? 
												(this.props.windowSize < 992 ? 
													(this.props.windowSize < 768 ? 
														(this.props.windowSize < 414 ? 2 : 5) 
														: 2) 
													: 3)
												: 5}
										/>
									</div>
								</Col>
							</Row>
						)}

					</div>
                </Col>
            </Row>
		</div>
      );
   }
}

const mapStateToProps = ({attachedArticlesList, windowSize}) => {
	return {
		attachedArticlesList,
		windowSize : windowSize.width
	};
};


export default connect(mapStateToProps,{})(AttachedArticles);
