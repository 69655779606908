
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
import _ from 'lodash';
import { getNotificationDataListApi } from '../../../../actions/spcmActions';
import { useDispatch, useSelector } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { LuFilterX } from "react-icons/lu";
import { HiOutlineRefresh } from 'react-icons/hi';
import moment from 'moment';

let homepagelocation = cookies.get('gph');
if (homepagelocation) homepagelocation = homepagelocation.replace('s:', '');
if (homepagelocation) homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];

const NotificationBoardHeader = (props) => {
  const tr = useSelector((state) => state.spcmReducer.tr);
  const dispatch = useDispatch();

  const clearAllFilters = () => {
    props.setNameSearchInput("");
    props.setCompanySearchInput("");
    props.setSelectedStatusValue([]);
    props.setSelectedModuleValue([]);
    props.setSelectedLanguageValue([]);
    props.setSelectedDate([]);
    props.setModifiedStartAndEndDate({}, {}, []);

    props.setPage(1);
    props.setLimit(10);
    props.setSearchParams({});
    props.setMultiSelectParams([], [], []);

    props.showRightSide(false);
    props.isClearAllFilterFunc(false);
    dispatch(getNotificationDataListApi([], [props.type], [], [], []));
    props.setTypeFun(props.type);    
    props.modifiedDateRef.startDate = moment();
    props.modifiedDateRef.endDate = moment();    
    // props.setModifiedStartDate()
    // props.setModifiedEndDate()
  }

  useEffect(() => {
    props.setActiveKeyVal(1);
    // props.setActiveKeyVal(process.env.NE_TEMPLATE == 'true' ? 1 : 2);
  }, [])
  const refreshTable = ()=> {
    props.showRightSide(false);
    dispatch(getNotificationDataListApi(props.modifiedDateFilterValues, [props.type], props.locale, props.module, props.status, props.searchParams, props.page, props.size));
    // props.refreshClickFun(true);
  }
  const defaultHandler = (e) => {
    dispatch(getNotificationDataListApi([], ['default'], [], [], []));
    props.setTypeFun(e.target.value);
    props.setLimit(10);
    props.setSearchParams({});
    props.setMultiSelectParams([], [], []);
    props.setNameSearchInput("");
    props.setCompanySearchInput("");
    props.setSelectedStatusValue([]);
    props.setSelectedModuleValue([]);
    props.setSelectedLanguageValue([]);
    props.isClearAllFilterFunc(false);
    props.showRightSide(false);
  }

  const customHandler = (e) => {
    dispatch(getNotificationDataListApi([], ['custom'], [], [], []));
    props.setTypeFun(e.target.value);
    props.setLimit(10);
    props.setSearchParams({});
    props.setMultiSelectParams([], [], []);
    props.setNameSearchInput("");
    props.setCompanySearchInput("");
    props.setSelectedStatusValue([]);
    props.setSelectedModuleValue([]);
    props.setSelectedLanguageValue([]);
    props.isClearAllFilterFunc(false);
    props.setSelectedDate([]);
    props.setModifiedStartAndEndDate({}, {}, []);
    props.showRightSide(false);
  }
  return (
    <>
      <Row className="margin-b-10">
        <Col md={7} sm={7} xs={12}>
          <ul className="wibTabs">
            {/* {process.env.NE_TEMPLATE == 'true' && <li eventKey={1} className={props.activeKey == 1 ? "active" : null}>
            <a onClick={()=>{props.setActiveKeyVal(1)}} role="button" href={void(0)} title={props.translator['Templates']}>{props.translator['Templates']}</a>
          </li>} */}
            <li className={props.activeKey == 1 ? "active" : null}>
              <a onClick={() => { props.setActiveKeyVal(1) }} role="button" href={void (0)} title={props.translator['Template']}>{props.translator['Template']}</a>
            </li>
            <li className={props.activeKey == 2 ? "active" : null}>
              <a onClick={() => { props.setActiveKeyVal(2) }} role="button" href={void (0)} title={props.translator['Configuration']}>{props.translator['Configuration']}</a>
            </li>
          </ul>

        </Col>
        <Col md={5} sm={5} xs={12} className="hidden-xs">
          <div className="status myStatus margin-t-10">
            <div className="paHedFilter">
              {props.isClearAllFilter ? <div className="overlayDv"><Button title="Clear All Filters" role="button" onClick={() => { clearAllFilters() }} bsPrefix=' ' className="myBt clrbtn"><LuFilterX /></Button></div> : ""}
              <Button
                aria-label={props.translator["Refresh"]}
                title={props.translator["Refresh"]}
                role="button"

                bsClass=""
                bsPrefix=' '
                className="myBt"
                onClick={() => { refreshTable(); }}>
                <HiOutlineRefresh className='refreshFlip' /></Button>
            </div>
            
          </div>
        </Col>
      </Row>
      {props.activeKey == 2 ?
        null
        :
        <Row className="margin-b-5">
          <Col lg={8} md={12} sm={12} xs={12}>
            <div aria-label="Table Filter" role="contentinfo" id="assFiltr">
              <div className="filtr">
                <Button
                  className={props.filterTabActive == 1 ? "active" : null}
                  title={"Default"}
                  bsPrefix=" "
                  value='default'
                  onClick={(e) => { defaultHandler(e) }}
                >
                  {tr["Default"]}
                </Button>
                <Button
                  className={props.filterTabActive == 2 ? "active" : null}
                  title={"Custom"}
                  bsPrefix=" "
                  value='custom'
                  onClick={(e) => { customHandler(e) }}
                >        
                  {tr["Custom"]}
                </Button>

              </div>
            </div>
          </Col>
        </Row>
      }

    </>
  );
}

export default NotificationBoardHeader