
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Popover from 'react-bootstrap/Popover'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import { connect } from 'react-redux';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import {
	loadFilterOrderList, loadOrdersList, addToSelectedOrderStatusList,
	removeFromSelectedOrderStatusList, addToSelectedOrderTypeList,
	removeFromSelectedOrderTypeList, resetSortingArrows,resetOrdersList
} from '_Actions/serviceDeskActions/myOrdersAction';
import { GLOBAL } from "_Globals";
import { Link } from 'react-router-dom';
import {HiOutlineClipboardCheck} from 'react-icons/hi';
import {PiBatteryChargingBold} from 'react-icons/pi';
import  OverlayTrigger  from 'react-bootstrap/OverlayTrigger';

//let user_id=userDetails.user_id;
//let company_id=userDetails.company_id;

class LeftSidebarMenulist extends React.Component {
	constructor(props) {
		super(props);

		let searchParams = {};




	}


	componentDidMount(){
		//this.applyAllFilters(null);
	}
	applyAllFilters(e) {
	//	alert("hi")
		// this.props.ordersListFilter('self');
		this.props.ordersListFilter();
		let searchParams = {};
		searchParams = this.props.buildSearchParamsForOrderFilter();
		//alert(searchParams)

		console.log(searchParams);
		if (_.isEmpty(searchParams)) {
			//alert("loadOrdersList");
		// this.props.resetOrdersList();
		
		
		this.props.loadOrdersList("requesterId", "type", "", "createdOn", "desc", 0, 10);
		this.props.resetSortingArrows();
		} else {
			//alert("loadOrdersListno");
			this.props.loadFilterOrderList(searchParams, 0, 10);
			this.props.resetSortingArrows();
		}
		this.props.showActiveRow(null,0);
        this.props.setShowCopyServiceForm(false)
	}

	applyAllOrderTypeFilters() {
	//	alert("hi111")
		// this.props.ordersListFilter('self');
		this.props.ordersListFilter();
		let searchParams = {};
		searchParams = this.props.buildSearchParamsForOrderFilter();

		console.log(searchParams);
		if (_.isEmpty(searchParams)) {
			// this.props.resetOrdersList();
			
			this.props.loadOrdersList("requesterId", "type", "", "createdOn", "desc", 0, 10);
			this.props.resetSortingArrows();
		} else {
			this.props.loadFilterOrderList(searchParams, 0, 10);
			this.props.resetSortingArrows();
		}
		this.props.showActiveRow(null,0);
        this.props.setShowCopyServiceForm(false) 
	}

	handleOrderTypeClick(e) {
		//alert(e.target.id);
		if (e.target.checked) {
			//alert(e.target.checked);
			this.props.addToSelectedOrderTypeList(e.target.id);
		} else {
			this.props.removeFromSelectedOrderTypeList(e.target.id);
		}


	}


	handleClick(e) {
		//alert(e.target.id);
		if (e.target.checked) {
			//alert(e.target.checked);
			this.props.addToSelectedOrderStatusList(e.target.id);
		} else {
			this.props.removeFromSelectedOrderStatusList(e.target.id);
		}
	}

	renderOverlayOrders() {
		//alert("renderOrdersOverlay"+this.state.selectedOrder)
		let applyAllFilters = (e) => console.log('applyAllFilters function not set');
		let handleClick = (e) => console.log('handleClick function not set');
		let selectedOrderType = [];
		applyAllFilters = (e) => this.applyAllOrderTypeFilters(e);
		selectedOrderType = this.props.selectedOrderType;
		handleClick = (e) => this.handleOrderTypeClick(e);
		applyAllFilters = (e) => this.applyAllOrderTypeFilters(e);
		let statusOrderMarkup = ['Service Orders', 'Correction Orders'].map((item) => {
			let ischecked = (() => { if (_.includes(selectedOrderType, item)) { return true } else { return false } })();

			//alert(ischecked+selectedStatus)
			return (
				<ListGroup.Item bsPrefix=' '>
					<Form.Check type="checkbox" label={item} id={item} checked={ischecked} onClick={handleClick}></Form.Check>
				</ListGroup.Item>
			);
		});
		return (
      <Popover id="popoverStatus">
        <Popover.Header as="h3">{this.props.tr["Order Type"]}</Popover.Header>
        <Popover.Body>
          <ListGroup bsPrefix=' '>{statusOrderMarkup}</ListGroup>
          <Button size="sm" type="button" className="myPopBtn" onClick={applyAllFilters}>
            {this.props.tr["Apply"]}
          </Button>
        </Popover.Body>
      </Popover>
    );
	}

	renderOverlayStatus() {
		//console.log("renderStatusOverlay"+this.state.selectedStatus);
		let applyAllFilters = (e) => console.log('applyAllFilters function not set');
		let handleClick = (e) => console.log('handleClick function not set');
		let selectedStatus = [];
		selectedStatus = this.props.selectedStatus;
		handleClick = (e) => this.handleClick(e);
		applyAllFilters = (e) => this.applyAllFilters(e);
		let statusPopoverMarkup = ['Open', 'Closed', 'Cancelled'].map((statusItem) => {
			//alert("this.state.selectedStatus"+selectedStatus)
			let ischecked = (() => { if (_.includes(selectedStatus, statusItem)) { return true } else { return false } })();
			//let handleStatusClick = (e) => console.log('handleClick function not set');
			//alert(ischecked+selectedStatus)
			return (
				<ListGroup.Item as="li" bsPrefix=' '>
					<Form.Check type='checkbox' label={statusItem} id={statusItem} checked={ischecked} onClick={handleClick}></Form.Check>
				</ListGroup.Item>
			);
		});
		return (
      <Popover id="popoverStatus" style={{width:"120px"}}>
        <Popover.Header as="h3">{this.props.tr["Status"]}</Popover.Header>
        <Popover.Body>
          <ListGroup as="ul">{statusPopoverMarkup}</ListGroup>
          <Button size="sm" type="button" className="myPopBtn" onClick={applyAllFilters}>
            {this.props.tr["Apply"]}
          </Button>
        </Popover.Body>
      </Popover>
    );
	}

	render() {

		return (
			<div className="{this.props.newMobileLeftMenuFilter ? 'leftNav showNav' : 'leftNav'}">
				<Nav as="ul" className="leftMenusss">
					<OverlayTrigger trigger="click" rootClose placement="bottom" overlay={this.renderOverlayOrders()}>
						<Nav.Item key={1} role='presentation' as='li' eventKey={1} title={this.props.tr['Order Type']}><Link to="#"><HiOutlineClipboardCheck/></Link>
						</Nav.Item>
					</OverlayTrigger>
					<OverlayTrigger trigger="click" rootClose placement="bottom" overlay={this.renderOverlayStatus()}>
						<Nav.Item key={2} as='li' role='presentation' eventKey={3} title={this.props.tr['Status']}><Link to="#"><PiBatteryChargingBold/></Link>
						</Nav.Item>
					</OverlayTrigger>
					
				</Nav>
			</div>
		);
	}
}

export function mapStateToProps(state) {
	return {
		
		addToSelectedOrderStatusList: state.myOrderViewFilters.addToSelectedOrderStatusList,
		removeFromSelectedOrderStatusList: state.myOrderViewFilters.removeFromSelectedOrderStatusList,
		addToSelectedOrderTypeList: state.myOrderViewFilters.addToSelectedOrderTypeList,
		removeFromSelectedOrderTypeList: state.myOrderViewFilters.removeFromSelectedOrderTypeList,
		selectedStatus: state.myOrderViewFilters.selectedStatus,
		selectedOrderType: state.myOrderViewFilters.selectedOrderType
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		loadFilterOrderList, loadOrdersList,resetOrdersList,
		addToSelectedOrderStatusList, removeFromSelectedOrderStatusList,
		addToSelectedOrderTypeList, removeFromSelectedOrderTypeList, resetSortingArrows
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftSidebarMenulist);

//export default LeftSidebarMenulist;
