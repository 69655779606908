
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import XsmSupportHeader from "./FulfillmentHeader.js";
import XsmBreakFixAction0 from "./Fulfillment-save.js";
import XsmBreakFixFormTab from "./FulfillmentFormTab.js";
import FulfillmentTabPage from "./FulfillmentTabPage.js";
import XsmServiceDetails from "../../common/WorkItemBoard/xsm-service-editquickView.js";
import XsmUserDetails from "../../common/WorkItemBoard/xsmEditRequestedBy.js";
import PopUpTask from "../../common/WorkItemBoard/breakfixTask.js";
import FulfillmentApprovalStatus from "./fulfillmentApprovalStatus.js";
import FulfillmentRelatedWork from "./fullfilmentRelatedWork.js";
import FulfillmentCMDBRelatedWork from "./FulfillmentCMDBRelatedWork.js";
import { navigationHooks } from "../../../helpers/NavigationHook.js";
import { GLOBAL } from "../../Globals.js";
import axios from "axios";
import {
  loadFulfillmentDetails,
  LoadAMSgrp,
  updateLoadFulfillmentDetails,
} from "../../../actions/fulfillment/fulfillmentDataAction.js";
import {
  loadStatusBasedHamburgerOptions,
  resetLoadHamburgerOptionsReducer,
} from "../../../actions/common/loadStatusBasedHamburgerOptions.js";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import FulfillmentNotifyTab from "./FulfillmentNotifyTab.js";

import FulfillmentPutOnHold from "./FulfillmentPutOnHoldForm.js";
import XsmBreakFixAttachment from "../../common/WorkItemBoard/attachment.js";
import FulfillmentSLAProgress from "./FulfillmentSLAProgress.js";
import FulfillmentCancel from "./FulfillmentCancel.js";
import FulfillmentReason from "./FulfillmentReason.js";
import XsmBreakFixExternalSystemAttributes from "../../common/WorkItemBoard/xsm-breakFix-externalSystemAttribute.js";

import { loadBreakFixSLAResolutionLogDetails } from "../../../actions/breakFix/breakFixSLAResolutionLogAction.js";
import { getTranslation } from "../../../actions/spcmActions.js";
import { loadExternalAttributesDetails } from "../../../actions/breakFix/breakFixEditDetailsAction.js";
import { loadBreakFixComplexity } from "../../../actions/breakFix/reasonCodeAction.js";
import { RuleAmsDdropDown } from "../../common/forms/ruleDropDown";
import "_Css/form/_form.scss";
import Cookies from "universal-cookie";
const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home") homepagelocation = "/" + homepagelocation;

import { loadBreakFixAuditLogDetails } from "../../../actions/breakFix/breakFixAuditLogAction.js";
import { getFulfillmentTimeLineStatus } from "../../../actions/fulfillment/fulfillmentTimeLineActions.js";
import LoadingIcons from "react-loading-icons";
const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});
import WibSearchFulfillment from "./wibSearch-fulfillment.js";
import Breadcrumbs from "../../common/header/breadcrumbs.js";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { loadProfile } from "_Actions/myProfile/profileAction";
import Approvals from "../../common/WorkItemBoard/approvals.js";
import ReopenItemForm from "./ReopenItemForm.js";
import WatcherDetails from "../../common/WorkItemBoard/watcher/WatcherDetails.js";
import { fetchWatcherDetails } from "../../../actions/fulfillment/watcherActions.js";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import TagDetails from "../../common/tags/TagDetails.js";
import AvailableIndividuals from "../../common/WorkItemBoard/availableIndividuals/AvailableIndividuals.js";
import { exportDoc } from "./helper.js";
import { getQuestionsCategoryWise } from "../../../actions/cart/myOrders/myOrdersAction.js";
import AiInsights from "../../common/aiInsights.js";
import AiInsightsBar from "../../common/aiInsightsBar.js";
import Empty from "../../common/WorkItemBoard/Empty.js";

class FulfillmentIndex extends React.Component {
  constructor(props) {
    super(props);
    GLOBAL.fulfillsavemsg = "";
    this.state = {
      subMenu: "4",
      showHideAlfy: false,
      showHideRightNav: false,
      service: 0,
      offeringId: 0,
      provideUserDetails: 0,
      processhierarchy: 0,
      shownotify: 0,
      aiInsights:0,
      showNotifyForm: true,
      showPutOnHold: 0,
      provideattachment: 0,
      SLA: 0,
      mobileLeftMenuFilter: false,
      //ordernumber:'',
      showSystemExternalAttributesFlag: 0,
      disabledSave: false,
      individualFieldVal: "",
      showCancel: 0,
      user_id: "",
      role_id: "",
      groupID: "",
      roleID: "",
      changeImplentationRole: "",
      date_format: "",
      currency: "",
      showHoldError: false,
      showGroupError: false,
      showDateError: false,
      showDateErrorText: "",
      full_name: "",
      showfulfill: 0,
      resolutionComment: "",
      taskeditFlag: 0,
      taskcode: "",
      approvalFlag: 0,
      relatedWorkItemsFlag: 0,
      relateCIFlag: 0,
      unchangedData: null,
      userDetailsID: "",
      userDetailsLabel: "",
      grpReasonErrorColor: "",
      showApprovals: 0,
      showReopen: 0,
      showWatcher: 0,
      supportCompanyID: "",
      supportCompanyName: "",
      isRightEditPanel: true,
      showTags: 0,
      disableFieldsForGuestRole: false,
      isChangedRolesEnabled: false,
      isProblemRolesEnabled: false,
      isIncidentRolesEnabled: false,
      isFulfillmentRolesEnabled: false,
      isTaskRoleEnabled: false,
      isCMDBRoleEnabled: false,
      selectedAssignmentGroup: [],
      selectedAssignmentUser: [],
      showAvailableIndividual: 0,
			isVisible: true,
			aismValue: null,
			aiInsightDSATEnabled: false
    };

    this.showHideAlfy = this.showHideAlfy.bind(this);
    this.showHideRightNav = this.showHideRightNav.bind(this);
    this.showServiceDetails = this.showServiceDetails.bind(this);
    this.showConsumerDetails = this.showConsumerDetails.bind(this);
    this.processHierarchy = this.processHierarchy.bind(this);
    this.setProcessHierarchyState = this.setProcessHierarchyState.bind(this);
    this.saveFulfill = this.saveFulfill.bind(this);
    this.showNotify = this.showNotify.bind(this);
    this.showCancel = this.showCancel.bind(this);
    this.showProvideAttachment = this.showProvideAttachment.bind(this);
    this.showSLA = this.showSLA.bind(this);
    this.showRefresh = this.showRefresh.bind(this);
    this.showSystemExternalAttributes =
      this.showSystemExternalAttributes.bind(this);
    this.showPutOnHold = this.showPutOnHold.bind(this);
    this.hideHoldError = this.hideHoldError.bind(this);
    this.resetDateError = this.resetDateError.bind(this);
    this.showTimeline = this.showTimeline.bind(this);
    this.showfulfill = this.showfulfill.bind(this);
    this.setResComment = this.setResComment.bind(this);
    this.roleIdentification = this.roleIdentification.bind(this);
    this.loadHome = this.loadHome.bind(this);
    this.clicktaskEdit = this.clicktaskEdit.bind(this);
    this.showTasks = this.showTasks.bind(this);
    this.showApprovals = this.showApprovals.bind(this);
    this.showRelatedWorkItems = this.showRelatedWorkItems.bind(this);
    this.showRelateCIs = this.showRelateCIs.bind(this);
    this.setUnchangedData = this.setUnchangedData.bind(this);
    this.checkDataChanged = this.checkDataChanged.bind(this);
    this.postActivityData = this.postActivityData.bind(this);
    this.setGroupReasonErrorColor = this.setGroupReasonErrorColor.bind(this);
    this.showApprovalsStatus = this.showApprovalsStatus.bind(this);
    this.showReopen = this.showReopen.bind(this);
    this.showWatcher = this.showWatcher.bind(this);
    this.setSupportCompany = this.setSupportCompany.bind(this);
    this.itemId = navigationHooks.params.itemId;
    this.rightEditPanel = this.rightEditPanel.bind(this);
    this.showTags = this.showTags.bind(this);
    this.roleCalculatorForGuestUser =
      this.roleCalculatorForGuestUser.bind(this);
    this.RefreshScreen = this.RefreshScreen.bind(this);
    this.showAvailableIndividual = this.showAvailableIndividual.bind(this);
    this.setAssignmentGrpUsr = this.setAssignmentGrpUsr.bind(this);
    this.exportDocument = this.exportDocument.bind(this);
    this.resetRightSide = this.resetRightSide.bind(this);
    this.aiInsightsShowFun = this.aiInsightsShowFun.bind(this);
  }

  createDSATPayload = () => {
		const { fulfillmentDetails } = this.props;
		console.log("breakFixEditDetailsDSAT===>",fulfillmentDetails);
		const payload = {
      "createdOn": fulfillmentDetails.createdOn || '',
      "requesterId": fulfillmentDetails.requesterId || '',
      "offeringIdSx": fulfillmentDetails.offeringIdSx || '',
      "asgGroupid": fulfillmentDetails.asgGroupid || '',
      "createdBy": fulfillmentDetails.createdBy || '',
      "modifiedBy": fulfillmentDetails.modifiedBy || '',
      "complexity": fulfillmentDetails.complexity || '',
      "locationId": fulfillmentDetails.locationId || '',
      "employeeBand": fulfillmentDetails.employeeBand || '',
      "timeZone": fulfillmentDetails.timeZone || '',
      "companyId": fulfillmentDetails.companyId || '',
  };
	
		return payload;
	  };

  setProcessHierarchyState(val) {
    this.setState({
      processhierarchy: val,
    });
  }
  setAssignmentGrpUsr(selectd, type) {
    switch (type) {
      case "user":
        this.setState({ selectedAssignmentUser: selectd });
        break;
      case "group":
        this.setState({ selectedAssignmentGroup: selectd });
        break;
    }
  }

  clicktaskEdit(i, j, k) {
    this.setState({
      taskeditFlag: i,
      taskcode: j,
      taskregid: k,
    });
  }
  setGroupReasonErrorColor(value) {
    this.setState({ grpReasonErrorColor: value });
  }
  rightEditPanel(value) {
    this.setState({ isRightEditPanel: value });
  }
  loadHome() {
    navigationHooks.navigate(homepagelocation);
  }

  RefreshScreen(obj) {
    Swal.fire({
      title: this.props.tr["Are you sure your want to refresh ?"],
      showDenyButton: true,
      confirmButtonText: this.props.tr["Yes"],
      denyButtonText: this.props.tr["No"],
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          //GLOBAL.fulfillmentPageLoad = true;
          this.props.resetLoadHamburgerOptionsReducer();
          this.props.loadFulfillmentDetails(this.itemId);
          this.props.LoadAMSgrp(this.itemId);
          this.props.loadBreakFixComplexity("Item");

          this.setState({
            processhierarchy: 1,
            provideUserDetails: 0,
            service: 0,
            offeringId: 0,
            shownotify: 0,
            aiInsights:0,
            provideattachment: 0,
            SLA: 0,
            showSystemExternalAttributesFlag: 0,
            showPutOnHold: 0,
            showCancel: 0,
            showfulfill: 0,
            taskeditFlag: 0,
            approvalFlag: 0,
            relatedWorkItemsFlag: 0,
            relateCIFlag: 0,
            showApprovals: 0,
            showReopen: 0,
            showWatcher: 0,
            showTags: 0,
            showAvailableIndividual: 0,
          });

          this.props.loadProfile();
          this.props.fetchWatcherDetails("Fullfilment", this.itemId);
        } catch (e) {
          console.error(e);
        }
      } else if (result.isDenied) {
      }
    });
  }

  componentWillMount() {
    GLOBAL.fulfillmentPageLoad = true;
    this.props.resetLoadHamburgerOptionsReducer();
    this.props.loadFulfillmentDetails(this.itemId);
    this.props.LoadAMSgrp(this.itemId);
    this.props.getQuestionsCategoryWise(this.itemId)
    console.log("xsm-fullfillment rule configuration api");
    //this.props.loadBreakFixSLAResolutionLogDetails("Fullfilment",this.props.params.itemId);
    this.props.loadBreakFixComplexity("Item");
    let str = {};
    str.user_id = "";
    str = JSON.stringify(str);
    api.get("/api/userInfo", { headers: { query: str } }).then((response) => {
      console.log(response.data);
      let changeUserid = "";
      if (
        response.data.role_id.split(",").includes("20") &&
        !response.data.role_id.split(",").includes("52")
      ) {
        changeUserid = "changeUserid";
      } else if (
        response.data.role_id.split(",").includes("52") &&
        !response.data.role_id.split(",").includes("20")
      ) {
        changeUserid = "impUserid";
      } else if (
        (response.data.role_id.split(",").includes("20") &&
          response.data.role_id.split(",").includes("52")) ||
        (response.data.role_id.split(",").includes("20") &&
          response.data.role_id.split(",").includes("51")) ||
        (response.data.role_id.split(",").includes("52") &&
          response.data.role_id.split(",").includes("19"))
      ) {
        changeUserid = "impUserid,changeUserid";
      } else if (
        response.data.role_id.split(",").includes("19") &&
        !response.data.role_id.split(",").includes("51")
      ) {
        changeUserid = "changeUserid";
      } else if (
        response.data.role_id.split(",").includes("51") &&
        !response.data.role_id.split(",").includes("19")
      ) {
        changeUserid = "impUserid";
      } else if (
        response.data.role_id.split(",").includes("19") &&
        response.data.role_id.split(",").includes("51")
      ) {
        changeUserid = "impUserid,changeUserid";
      }
      this.setState({
        user_id: response.data.user_id,
        role_id: response.data.role_id.split(","),
        groupID: response.data.groupId,
        roleID: response.data.role_id,
        changeImplentationRole: changeUserid,
        date_format: response.data.dateformat,
        currency: response.data.currency,
        full_name: response.data.full_name,
        processhierarchy: 1,
      });
      this.roleCalculatorForGuestUser(response.data.role_id);
    });
    this.props.loadProfile();
    this.props.fetchWatcherDetails("Fullfilment", this.itemId);
    if (GLOBAL.slaHeadEnable) {
      this.setState({
        SLA: 1,
        processhierarchy: 0,
      });
      this.showSLA();
      this.rightEditPanel(true);
    }
  }

  componentWillUnmount() {
    GLOBAL.fulfillmentPageLoad = false;
  }

  componentWillReceiveProps(nextProps) {
    console.log("Check56", this.props.fulfillmentdetails);
    console.log("nextProps?.aismProperty?.aiInsightDSATProp", nextProps?.aismProperty?.aiInsightDSATProp);
    if (
			nextProps?.aismProperty?.aiInsightDSATProp &&
			!Array.isArray(nextProps?.aismProperty?.aiInsightDSATProp) &&
			Object.keys(nextProps?.aismProperty?.aiInsightDSATProp).length > 0
		  ) {
			if (nextProps?.aismProperty?.aiInsightDSATProp?.value !== "") {
			  try {
          console.log("Inside try block");
				const aismDSATValue = JSON.parse(nextProps?.aismProperty?.aiInsightDSATProp?.value);
        console.log("object098765", aismDSATValue);
				if(aismDSATValue?.aism === "true" || aismDSATValue?.aism === true) {
          console.log("Inside if block");
				  this.setState({
            aiInsightDSATEnabled: true,
				  });
				  this.setState({isVisible: true});
				} else {
          console.log("Inside elseeee");
					this.setState({ aiInsightDSATEnabled: false })
				}
			  } catch (err) {
				console.log('Error: ', err);
			  }
			} else {
        console.log("Inside else 2");
			  this.setState({
				aiInsightDSATEnabled: false,
			  });
			}
		  }
    let BreakFixID = this.itemId;
    let BreakFixID2 = this.itemId;
    if (BreakFixID != BreakFixID2) {
      this.props.resetLoadHamburgerOptionsReducer();
      this.props.LoadAMSgrp(this.itemId);
      // this.props.loadFulfillmentDetails(nextProps.routeParams.itemId);
      this.props.loadBreakFixComplexity("Item");
      let str = {};
      str.user_id = "";
      str = JSON.stringify(str);
      api.get("/api/userInfo", { headers: { query: str } }).then((response) => {
        console.log(response.data);
        let changeUserid = "";
        if (
          response.data.role_id.split(",").includes("20") &&
          !response.data.role_id.split(",").includes("52")
        ) {
          changeUserid = "changeUserid";
        } else if (
          response.data.role_id.split(",").includes("52") &&
          !response.data.role_id.split(",").includes("20")
        ) {
          changeUserid = "impUserid";
        } else if (
          (response.data.role_id.split(",").includes("20") &&
            response.data.role_id.split(",").includes("52")) ||
          (response.data.role_id.split(",").includes("20") &&
            response.data.role_id.split(",").includes("51")) ||
          (response.data.role_id.split(",").includes("52") &&
            response.data.role_id.split(",").includes("19"))
        ) {
          changeUserid = "impUserid,changeUserid";
        } else if (
          response.data.role_id.split(",").includes("19") &&
          !response.data.role_id.split(",").includes("51")
        ) {
          changeUserid = "changeUserid";
        } else if (
          response.data.role_id.split(",").includes("51") &&
          !response.data.role_id.split(",").includes("19")
        ) {
          changeUserid = "impUserid";
        } else if (
          response.data.role_id.split(",").includes("19") &&
          response.data.role_id.split(",").includes("51")
        ) {
          changeUserid = "impUserid,changeUserid";
        }
        this.setState({
          subMenu: "4",
          showHideAlfy: false,
          showHideRightNav: false,
          service: 0,
          offeringId: 0,
          provideUserDetails: 0,
          processhierarchy: 0,
          shownotify: 0,
          aiInsights:0,
          showPutOnHold: 0,
          provideattachment: 0,
          SLA: 0,
          mobileLeftMenuFilter: false,
          showSystemExternalAttributesFlag: 0,
          disabledSave: false,
          individualFieldVal: "",
          showCancel: 0,
          showHoldError: false,
          showDateError: false,
          showDateErrorText: "",
          showfulfill: 0,
          resolutionComment: "",
          user_id: response.data.user_id,
          role_id: response.data.role_id.split(","),
          groupID: response.data.groupId,
          roleID: response.data.role_id,
          changeImplentationRole: changeUserid,
          date_format: response.data.dateformat,
          currency: response.data.currency,
          full_name: response.data.full_name,
          taskeditFlag: 0,
          approvalFlag: 0,
          relatedWorkItemsFlag: 0,
          relateCIFlag: 0,
          showApprovals: 0,
        });
        this.roleCalculatorForGuestUser(response.data.role_id);
      });
    } else if (
      nextProps.loadHamburgerOptions === true &&
      Object.keys(this.props.fulfillmentDetails).length != 0
    ) {
      this.props.loadStatusBasedHamburgerOptions(
        "Item",
        nextProps.fulfillmentDetails.status,
        nextProps.fulfillmentDetails.itemId
      );
      this.props.resetLoadHamburgerOptionsReducer();
    }
    this.setUnchangedData(nextProps.fulfillmentDetails);
  }

  showTimeline(flagValue) {
    this.setState({ showTimelineFlag: flagValue });
  }

  showHideAlfy(value) {
    this.setState({ showHideAlfy: value });
  }
  showHideRightNav(value) {
    this.setState({ showHideRightNav: !this.state.showHideRightNav });
  }
  mobileLeftMenuFilter() {
    this.setState({ mobileLeftMenuFilter: !this.state.mobileLeftMenuFilter });
  }

  hideHoldError() {
    this.setState({ showHoldError: false });
  }

  resetDateError() {
    this.setState({ showDateError: false, showDateErrorText: "" });
  }

  saveFulfill() {
    console.log("MMM this.propsss:::", GLOBAL.fulfillmentgroupval);
    if (this.state.showfulfill == 1) {
      let count = 0;
      for (let i = 0; i < this.state.resolutionComment.length; i++)
        if (this.state.resolutionComment[i] == " ") count++;
      if (
        this.state.resolutionComment == "" ||
        this.state.resolutionComment.length == count
      ) {
        Swal.fire("Please Provide Some Resolution Notes");
        return false;
      }
    }
    let ref = this;
    if (GLOBAL.complexityCode == null) GLOBAL.complexityCode = "0";
    console.log(
      "this.propsss:::",
      GLOBAL.fulfillmentgroupval != 0,
      GLOBAL.fulfillmentgroupval == 0
    );

    let dateformat =
      this.props &&
      this.props.specificUserDetails &&
      this.props.specificUserDetails.dateformat
        ? this.props.specificUserDetails.dateformat
        : "";
    let fulfillmentDate;

    if (this.props.fulfillmentDetails.status == "On Hold") {
      if (GLOBAL.fulfillmentHoldCode == "" || GLOBAL.fulfillmentHoldVal == "") {
        this.setState({ showHoldError: true });
        return false;
      } else {
        this.setState({ showHoldError: false });
      }
    }
    console.log(
      "MMM ",
      GLOBAL.showGroupReassignReasonErrorColor,
      " GLOBAL.fulfillmentgroupReason: ",
      GLOBAL.fulfillmentgroupReason
    );
    if (
      GLOBAL.showGroupReassignReasonErrorColor &&
      (!GLOBAL.fulfillmentgroupReason || GLOBAL.fulfillmentgroupReason == "")
    ) {
      GLOBAL.GroupReassignReasonErrorColor = "error";
      this.setState({ grpReasonErrorColor: "error" });
      //throw new SubmissionError({ _error: 'The form has missing required fields, please revalidate the highlighted fields and update the form !' });
      return;
    }
    let itemId = this.itemId;
    let obj = {};
    if (GLOBAL.fulfillmentHoldCode && GLOBAL.fulfillmentHoldCode != "") {
      obj.modifiedBy = "";
      obj.modifiedByName = "";
      obj.asgUserid =
        GLOBAL.fulfillmentindividualval == ""
          ? 0
          : GLOBAL.fulfillmentindividualval;
      obj.asgUsername =
        GLOBAL.fulfillmentindividualtext == ""
          ? ""
          : GLOBAL.fulfillmentindividualtext;
      obj.holdingReasonCode = GLOBAL.fulfillmentHoldCode;
      obj.holdingReasonValue = GLOBAL.fulfillmentHoldVal;
      obj.expdeliveryDate = fulfillmentDate;
      obj.complexity = GLOBAL.complexityCode;
    } else {
      obj.modifiedBy = "";
      obj.modifiedByName = "";
      obj.asgUserid =
        GLOBAL.fulfillmentindividualval == ""
          ? 0
          : GLOBAL.fulfillmentindividualval;
      obj.asgUsername =
        GLOBAL.fulfillmentindividualtext == ""
          ? ""
          : GLOBAL.fulfillmentindividualtext;
      obj.expdeliveryDate = fulfillmentDate;
      obj.complexity = GLOBAL.complexityCode;
    }

    console.log("access");
    if (GLOBAL.fulfillmentgroupval !== "" && GLOBAL.fulfillmentgroupval != 0) {
      console.log("access22");

      obj.asgGroupid = GLOBAL.fulfillmentgroupval;
      obj.asgGroupname = GLOBAL.fulfillmentgrouptext;
      obj.modifiedBy = "";
      obj.modifiedByName = "";
    } else {
      this.setState({
        showGroupError: true,
      });
      GLOBAL.grpErrorColor = true;
      return false;
    }
    // obj.isExternal = GLOBAL.isExternalFulfillmentEdit;
    console.log("objjj", obj);

    if (!this.checkDataChanged(obj)) {
      // alert('There is no change in the data!');
      swal({
        text: this.props.tr["There is no change on the form"],
        button: this.props.tr["OK"],
      });
      return false;
    }
    obj.supportCompanyId = this.state.supportCompanyID;
    obj.supportCompanyName = this.state.supportCompanyName;
    if (
      this.props.fulfillmentDetails.itemStatus == 60 &&
      GLOBAL.fulfillmentgroupReason != "" &&
      GLOBAL.fulfillmentgroupval != "" &&
      GLOBAL.fulfillmentgroupval != 0
    ) {
      obj.itemStatus = 70;
    }
    ref.setState({ disabledSave: true });
    axios
      .patch(GLOBAL.fulfillmentpost + itemId, obj)
      .then(function (response) {
        // ref.props.LoadAMSgrp(itemId);
        ref.setState({
          processhierarchy: 1,
          provideUserDetails: 0,
          service: 0,
          offeringId: 0,
          shownotify: 0,
          aiInsights:0,
          provideattachment: 0,
          SLA: 0,
          showSystemExternalAttributesFlag: 0,
          showPutOnHold: 0,
          showCancel: 0,
          showfulfill: 0,
          taskeditFlag: 0,
          approvalFlag: 0,
          relatedWorkItemsFlag: 0,
          relateCIFlag: 0,
          showApprovals: 0,
          showReopen:0,
          showWatcher:0,
          showTags:0,
          showAvailableIndividual:0
        });
        ref.props.loadFulfillmentDetails(itemId);
        ref.props.loadBreakFixAuditLogDetails(itemId, "dsc", "Fullfilment");

        if (
          GLOBAL.showGroupReassignReasonErrorColor &&
          GLOBAL.fulfillmentgroupReason
        ) {
          ref.postActivityData(GLOBAL.fulfillmentgroupReason, itemId);
        }
        GLOBAL.showGroupReassignReasonErrorColor = false;
        GLOBAL.fulfillmentgroupReason = "";
        ref.setState({ grpReasonErrorColor: "" });
        GLOBAL.fulfillmentindividualtext = "";
        GLOBAL.fulfillsavemsg = "Success";
        //window.location.reload();
        ref.setState({
          disabledSave: false,
          individualFieldVal: GLOBAL.fulfillmentindividualval,
        });
        ref.props.updateLoadFulfillmentDetails(response);
        console.log("resp:::" + response.data.itemId);
        navigationHooks.navigate("/fulFillment/" + response.data.itemId);
      })
      .catch(function (error) {
        ref.setState({ disabledSave: false });
        console.log(error);
      });
  }
  postActivityData(groupReassignReason, itemId) {
    let activityblank = false;

    if (groupReassignReason.match(/^\s*$/)) {
      activityblank = true;
    }
    let module = "Fullfilment";

    if (groupReassignReason != "" && activityblank == false) {
      this.setState({ showLoader: true });
      axios
        .post(GLOBAL.breakFixActivityLogPostUrl, {
          itemId: itemId,
          description: `${this.props.tr["Group Reassignment Reason"]} - ${groupReassignReason}`,
          isInternal: this.state.isinternal,
          createdOn: "",
          createdBy: "",
          module: module,
          createdByName: "",
          sendNotification: "true",
          isExternal: "",
        })
        .then((response) => {
          console.log("Data successfully submitted: ", response);
        });
    }
  }
  showServiceDetails(serviceId) {
    GLOBAL.fulfillsavemsg = "";
    this.setState({
      service: 1,
      offeringId: serviceId,
      provideUserDetails: 0,
      processhierarchy: 0,
      shownotify: 0,
      aiInsights:0,
      provideattachment: 0,
      SLA: 0,
      showSystemExternalAttributesFlag: 0,
      showPutOnHold: 0,
      showCancel: 0,
      showfulfill: 0,
      taskeditFlag: 0,
      approvalFlag: 0,
      relatedWorkItemsFlag: 0,
      relateCIFlag: 0,
      showApprovals: 0,
      showReopen: 0,
      showWatcher: 0,
      showTags: 0,
      showAvailableIndividual: 0,
    });
  }
  showConsumerDetails(userId, type = "requestor", label = "") {
    // console.log('userId - ', userId);
    if (!(userId && !isNaN(userId) && !!parseInt(userId, 10))) {
      return;
    }
    this.setState({ provideUserDetails: 0 }, () => {
      if (type == "requestor") {
        GLOBAL.breakFixConsumerID = userId;
      }
      GLOBAL.fulfillsavemsg = "";
      this.setState({
        provideUserDetails: 1,
        service: 0,
        offeringId: 0,
        processhierarchy: 0,
        shownotify: 0,
        aiInsights:0,
        provideattachment: 0,
        SLA: 0,
        showSystemExternalAttributesFlag: 0,
        showPutOnHold: 0,
        showCancel: 0,
        showfulfill: 0,
        taskeditFlag: 0,
        approvalFlag: 0,
        relatedWorkItemsFlag: 0,
        relateCIFlag: 0,
        userDetailsID: userId,
        userDetailsLabel: label,
        showApprovals: 0,
        showReopen: 0,
        showWatcher: 0,
        showTags: 0,
        showAvailableIndividual: 0,
      });
    });
  }
  processHierarchy() {
    GLOBAL.fulfillsavemsg = "";
    this.setState({
      processhierarchy: 1,
      provideUserDetails: 0,
      service: 0,
      offeringId: 0,
      shownotify: 0,
      aiInsights:0,
      provideattachment: 0,
      SLA: 0,
      showSystemExternalAttributesFlag: 0,
      showPutOnHold: 0,
      showCancel: 0,
      showfulfill: 0,
      taskeditFlag: 0,
      approvalFlag: 0,
      relatedWorkItemsFlag: 0,
      relateCIFlag: 0,
      showApprovals: 0,
      showReopen: 0,
      showWatcher: 0,
      showTags: 0,
      showAvailableIndividual: 0,
      isVisible: true,
    });
    //alert(this.state.processhierarchy);
  }

  showSystemExternalAttributes() {
    if (this.itemId) {
      this.props.loadExternalAttributesDetails(this.itemId, "item");
    }

    GLOBAL.fulfillsavemsg = "";
    this.setState({
      processhierarchy: 0,
      provideUserDetails: 0,
      service: 0,
      offeringId: 0,
      shownotify: 0,
      aiInsights:0,
      provideattachment: 0,
      SLA: 0,
      showSystemExternalAttributesFlag: 1,
      showPutOnHold: 0,
      showCancel: 0,
      showfulfill: 0,
      taskeditFlag: 0,
      approvalFlag: 0,
      relatedWorkItemsFlag: 0,
      relateCIFlag: 0,
      showApprovals: 0,
      showReopen: 0,
      showWatcher: 0,
      showTags: 0,
      showAvailableIndividual: 0,
      isVisible: true,
    });
  }

  resetRightSide() {
    GLOBAL.fulfillsavemsg = "";
    this.setState({
      processhierarchy: 0,
      provideUserDetails: 0,
      service: 0,
      offeringId: 0,
      shownotify: 0,
      aiInsights: 0,
      showNotifyForm: true,
      provideattachment: 0,
      SLA: 0,
      showSystemExternalAttributesFlag: 0,
      showPutOnHold: 0,
      showCancel: 0,
      showfulfill: 0,
      taskeditFlag: 0,
      approvalFlag: 0,
      relatedWorkItemsFlag: 0,
      relateCIFlag: 0,
      showApprovals: 0,
      showReopen: 0,
      showWatcher: 0,
      showTags: 0,
      showAvailableIndividual: 0,
      isVisible: true,
    });
  }
  aiInsightsShowFun() {
    GLOBAL.fulfillsavemsg = ""  ;
    this.setState({
      processhierarchy: 0,
      provideUserDetails: 0,
      service: 0,
      offeringId: 0,
      shownotify: 0,
      aiInsights: 1,
      showNotifyForm: true,
      provideattachment: 0,
      SLA: 0,
      showSystemExternalAttributesFlag: 0,
      showPutOnHold: 0,
      showCancel: 0,
      showfulfill: 0,
      taskeditFlag: 0,
      approvalFlag: 0,
      relatedWorkItemsFlag: 0,
      relateCIFlag: 0,
      showApprovals: 0,
      showReopen: 0,
      showWatcher: 0,
      showTags: 0,
      showAvailableIndividual: 0,
      isVisible: false,
    });
  }

  showNotify() {
    GLOBAL.fulfillsavemsg = "";
    this.setState({
      processhierarchy: 0,
      provideUserDetails: 0,
      service: 0,
      offeringId: 0,
      shownotify: 1,
      aiInsights:0,
      showNotifyForm: true,
      provideattachment: 0,
      SLA: 0,
      showSystemExternalAttributesFlag: 0,
      showPutOnHold: 0,
      showCancel: 0,
      showfulfill: 0,
      taskeditFlag: 0,
      approvalFlag: 0,
      relatedWorkItemsFlag: 0,
      relateCIFlag: 0,
      showApprovals: 0,
      showReopen: 0,
      showWatcher: 0,
      showTags: 0,
      showAvailableIndividual: 0,
      isVisible: true,
    });
  }
  showProvideAttachment() {
    GLOBAL.fulfillsavemsg = "";
    this.setState({
      processhierarchy: 0,
      provideUserDetails: 0,
      service: 0,
      offeringId: 0,
      shownotify: 0,
      aiInsights:0,
      provideattachment: 1,
      SLA: 0,
      showSystemExternalAttributesFlag: 0,
      showPutOnHold: 0,
      showCancel: 0,
      showfulfill: 0,
      taskeditFlag: 0,
      approvalFlag: 0,
      relatedWorkItemsFlag: 0,
      relateCIFlag: 0,
      showApprovals: 0,
      showReopen: 0,
      showWatcher: 0,
      showTags: 0,
      showAvailableIndividual: 0,
      isVisible: true,
    });
  }

  showPutOnHold() {
    GLOBAL.fulfillsavemsg = "";
    this.setState({
      processhierarchy: 0,
      provideUserDetails: 0,
      service: 0,
      offeringId: 0,
      shownotify: 0,
      aiInsights:0,
      provideattachment: 0,
      SLA: 0,
      showSystemExternalAttributesFlag: 0,
      showPutOnHold: 1,
      showCancel: 0,
      showfulfill: 0,
      taskeditFlag: 0,
      approvalFlag: 0,
      relatedWorkItemsFlag: 0,
      relateCIFlag: 0,
      showApprovals: 0,
      showReopen: 0,
      showWatcher: 0,
      showTags: 0,
      showAvailableIndividual: 0,
      isVisible: true,
    });
  }

  showRefresh() {
    this.props.loadFulfillmentDetails(this.itemId);
  }
  showSLA() {
    GLOBAL.fulfillsavemsg = "";
    this.setState({
      processhierarchy: 0,
      provideUserDetails: 0,
      service: 0,
      offeringId: 0,
      shownotify: 0,
      aiInsights:0,
      provideattachment: 0,
      SLA: 1,
      showSystemExternalAttributesFlag: 0,
      showPutOnHold: 0,
      showCancel: 0,
      showfulfill: 0,
      taskeditFlag: 0,
      approvalFlag: 0,
      relatedWorkItemsFlag: 0,
      relateCIFlag: 0,
      showApprovals: 0,
      showReopen: 0,
      showWatcher: 0,
      showTags: 0,
      showAvailableIndividual: 0,
      isVisible: true,
    });
  }
  showApprovalsStatus() {
    GLOBAL.fulfillsavemsg = "";
    this.setState({
      processhierarchy: 0,
      provideUserDetails: 0,
      service: 0,
      offeringId: 0,
      shownotify: 0,
      aiInsights:0,
      provideattachment: 0,
      SLA: 0,
      showSystemExternalAttributesFlag: 0,
      showPutOnHold: 0,
      showCancel: 0,
      showfulfill: 0,
      taskeditFlag: 0,
      approvalFlag: 1,
      relatedWorkItemsFlag: 0,
      relateCIFlag: 0,
      showApprovals: 0,
      showReopen: 0,
      showWatcher: 0,
      showTags: 0,
      showAvailableIndividual: 0,
      isVisible: true,
    });
  }

  showRelatedWorkItems() {
    GLOBAL.fulfillsavemsg = "";
    this.setState({
      processhierarchy: 0,
      provideUserDetails: 0,
      service: 0,
      offeringId: 0,
      shownotify: 0,
      aiInsights:0,
      provideattachment: 0,
      SLA: 0,
      showSystemExternalAttributesFlag: 0,
      showPutOnHold: 0,
      showCancel: 0,
      showfulfill: 0,
      taskeditFlag: 0,
      approvalFlag: 0,
      relatedWorkItemsFlag: 1,
      relateCIFlag: 0,
      showApprovals: 0,
      showReopen: 0,
      showWatcher: 0,
      showTags: 0,
      showAvailableIndividual: 0,
      isVisible: true,
    });
  }

  showRelateCIs() {
    GLOBAL.fulfillsavemsg = "";
    this.setState({
      processhierarchy: 0,
      provideUserDetails: 0,
      service: 0,
      offeringId: 0,
      shownotify: 0,
      aiInsights:0,
      provideattachment: 0,
      SLA: 0,
      showSystemExternalAttributesFlag: 0,
      showPutOnHold: 0,
      showCancel: 0,
      showfulfill: 0,
      taskeditFlag: 0,
      approvalFlag: 0,
      relatedWorkItemsFlag: 0,
      relateCIFlag: 1,
      showApprovals: 0,
      showReopen: 0,
      showWatcher: 0,
      showTags: 0,
      showAvailableIndividual: 0,
      isVisible: true,
    });
  }

  showTasks() {
    GLOBAL.fulfillsavemsg = "";
    this.setState({
      processhierarchy: 0,
      provideUserDetails: 0,
      service: 0,
      offeringId: 0,
      shownotify: 0,
      aiInsights:0,
      provideattachment: 0,
      SLA: 0,
      showSystemExternalAttributesFlag: 0,
      showPutOnHold: 0,
      showCancel: 0,
      showfulfill: 0,
      taskeditFlag: 1,
      approvalFlag: 0,
      relatedWorkItemsFlag: 0,
      relateCIFlag: 0,
      showApprovals: 0,
      showReopen: 0,
      showWatcher: 0,
      showTags: 0,
      showAvailableIndividual: 0,
      isVisible: true,
    });
  }

  showCancel() {
    GLOBAL.fulfillsavemsg = "";
    this.setState({
      processhierarchy: 0,
      provideUserDetails: 0,
      service: 0,
      offeringId: 0,
      shownotify: 0,
      aiInsights:0,
      provideattachment: 0,
      SLA: 0,
      showSystemExternalAttributesFlag: 0,
      showPutOnHold: 0,
      showCancel: 1,
      showfulfill: 0,
      taskeditFlag: 0,
      approvalFlag: 0,
      relatedWorkItemsFlag: 0,
      relateCIFlag: 0,
      showApprovals: 0,
      showReopen: 0,
      showWatcher: 0,
      showTags: 0,
      showAvailableIndividual: 0,
      isVisible: true,
    });
  }
  showfulfill() {
    GLOBAL.fulfillsavemsg = "";
    this.setState({
      processhierarchy: 0,
      provideUserDetails: 0,
      service: 0,
      offeringId: 0,
      shownotify: 0,
      aiInsights:0,
      provideattachment: 0,
      SLA: 0,
      showSystemExternalAttributesFlag: 0,
      showPutOnHold: 0,
      showCancel: 0,
      showfulfill: 1,
      taskeditFlag: 0,
      approvalFlag: 0,
      relatedWorkItemsFlag: 0,
      relateCIFlag: 0,
      showApprovals: 0,
      showReopen: 0,
      showWatcher: 0,
      showTags: 0,
      showAvailableIndividual: 0,
      isVisible: true,
    });
  }

  setResComment(comment) {
    this.setState({
      resolutionComment: comment,
    });
  }

  roleIdentification(Rolevalue) {
    let flag = false;
    for (let i = 0; i < this.state.role_id.length; i++) {
      if (this.state.role_id[i] == Rolevalue) {
        flag = true;
        break;
      }
    }
    if (flag) return true;
    else return false;
  }

  setUnchangedData(obj) {
    try {
      const checkEmptyValue = function (val) {
        if (!val || val == "" || val == "0" || val == "null") {
          return "";
        }
        return val;
      };
      let tempObj = {};
      tempObj["asgGroupid"] = checkEmptyValue(obj["asgGroupid"]);
      tempObj["asgUserid"] = checkEmptyValue(obj["asgUserid"]);
      tempObj["complexity"] = checkEmptyValue(obj["complexity"]);
      tempObj["holdingReasonCode"] = checkEmptyValue(obj["holdingReasonCode"]);
      this.setState({ unchangedData: tempObj });
    } catch (e) {
      console.error(e);
    }
  }

  checkDataChanged(obj) {
    try {
      const { unchangedData } = this.state;

      const isObject = function (obj) {
        return Object.prototype.toString.call(obj) === "[object Object]";
      };

      const compareObject = function (obj1, obj2) {
        if (!isObject(obj1) || !isObject(obj2)) {
          return false;
        }

        let len = null;
        //check if they're of thesame length
        if (Object.keys(obj1).length != Object.keys(obj2).length) {
          return false;
        } else {
          len = Object.keys(obj1).length; //use any length
        }

        let match = 0; //store number of matched properties
        Object.keys(obj1).forEach((i) => {
          //check if values with the same keys are equal
          if (obj1[i] == obj2[i]) {
            match++; //increment the variable
          }
        });
        //check if object length equals the number of matched properties
        if (match === len) {
          return true;
        }

        return false;
      };

      const checkEmptyValue = function (val) {
        if (!val || val == "" || val == "0" || val == "null") {
          return "";
        }
        return val;
      };

      let tempObj = {};
      tempObj["asgGroupid"] = checkEmptyValue(obj["asgGroupid"]);
      tempObj["asgUserid"] = checkEmptyValue(obj["asgUserid"]);
      tempObj["complexity"] = checkEmptyValue(obj["complexity"]);
      tempObj["holdingReasonCode"] = checkEmptyValue(obj["holdingReasonCode"]);
      // tempObj['expdeliveryDate'] = obj['expdeliveryDate'];
      // console.log('Data - ', {unchangedData, tempObj});
      const isEqual = compareObject(unchangedData, tempObj);
      // console.log('isEqual - ', isEqual);
      return !isEqual;
    } catch (e) {
      console.error(e);
    }
  }

  showApprovals() {
    GLOBAL.fulfillsavemsg = "";
    this.setState({
      processhierarchy: 0,
      provideUserDetails: 0,
      service: 0,
      offeringId: 0,
      shownotify: 0,
      aiInsights:0,
      showNotifyForm: true,
      provideattachment: 0,
      SLA: 0,
      showSystemExternalAttributesFlag: 0,
      showPutOnHold: 0,
      showCancel: 0,
      showfulfill: 0,
      taskeditFlag: 0,
      approvalFlag: 0,
      relatedWorkItemsFlag: 0,
      relateCIFlag: 0,
      showApprovals: 1,
      showReopen: 0,
      showWatcher: 0,
      showTags: 0,
      showAvailableIndividual: 0,
      isVisible: true,
    });
  }

  showReopen() {
    GLOBAL.fulfillsavemsg = "";
    this.setState({
      processhierarchy: 0,
      provideUserDetails: 0,
      service: 0,
      offeringId: 0,
      shownotify: 0,
      aiInsights:0,
      provideattachment: 0,
      SLA: 0,
      showSystemExternalAttributesFlag: 0,
      showPutOnHold: 0,
      showCancel: 0,
      showfulfill: 0,
      taskeditFlag: 0,
      approvalFlag: 0,
      relatedWorkItemsFlag: 0,
      relateCIFlag: 0,
      showApprovals: 0,
      showReopen: 1,
      showWatcher: 0,
      showTags: 0,
      showAvailableIndividual: 0,
      isVisible: true,
    });
  }

  showWatcher() {
    GLOBAL.fulfillsavemsg = "";
    this.setState({
      processhierarchy: 0,
      provideUserDetails: 0,
      service: 0,
      offeringId: 0,
      shownotify: 0,
      aiInsights:0,
      provideattachment: 0,
      SLA: 0,
      showSystemExternalAttributesFlag: 0,
      showPutOnHold: 0,
      showCancel: 0,
      showfulfill: 0,
      taskeditFlag: 0,
      approvalFlag: 0,
      relatedWorkItemsFlag: 0,
      relateCIFlag: 0,
      showApprovals: 0,
      showReopen: 0,
      showWatcher: 1,
      showTags: 0,
      showAvailableIndividual: 0,
      isVisible: true,
    });
  }

  showTags() {
    GLOBAL.fulfillsavemsg = "";
    this.setState({
      processhierarchy: 0,
      provideUserDetails: 0,
      service: 0,
      offeringId: 0,
      shownotify: 0,
      aiInsights:0,
      provideattachment: 0,
      SLA: 0,
      showSystemExternalAttributesFlag: 0,
      showPutOnHold: 0,
      showCancel: 0,
      showfulfill: 0,
      taskeditFlag: 0,
      approvalFlag: 0,
      relatedWorkItemsFlag: 0,
      relateCIFlag: 0,
      showApprovals: 0,
      showReopen: 0,
      showWatcher: 0,
      showTags: 1,
      isVisible: true,
    });
  }

  showAvailableIndividual() {
    GLOBAL.fulfillsavemsg = "";
    this.setState({
      processhierarchy: 0,
      provideUserDetails: 0,
      service: 0,
      offeringId: 0,
      shownotify: 0,
      aiInsights:0,
      provideattachment: 0,
      SLA: 0,
      showSystemExternalAttributesFlag: 0,
      showPutOnHold: 0,
      showCancel: 0,
      showfulfill: 0,
      taskeditFlag: 0,
      approvalFlag: 0,
      relatedWorkItemsFlag: 0,
      relateCIFlag: 0,
      showApprovals: 0,
      showReopen: 0,
      showWatcher: 0,
      showTags: 0,
      showAvailableIndividual: 1,
    });
  }
  setSupportCompany(companyID, companyName) {
    this.setState({
      supportCompanyID: companyID,
      supportCompanyName: companyName,
    });
  }
  roleCalculatorForGuestUser(rolesList) {
    let roles = rolesList.split(",");
    let isChangedRolesEnabled =
      roles.includes("19") ||
      roles.includes("20") ||
      roles.includes("51") ||
      roles.includes("52");
    let isProblemRolesEnabled =
      roles.includes("16") || roles.includes("17") || roles.includes("53");
    let isIncidentRolesEnabled =
      roles.includes("12") || roles.includes("13") || roles.includes("14");
    let isFulfillmentRolesEnabled =
      roles.includes("43") || roles.includes("44");
    let isServiceDeskRolesEnabled = roles.includes("48");
    let isD2CRolesEnabled =
      isChangedRolesEnabled ||
      isProblemRolesEnabled ||
      isIncidentRolesEnabled ||
      isFulfillmentRolesEnabled;
    let isGuestUserRoleEnabled =
      !(isFulfillmentRolesEnabled || isServiceDeskRolesEnabled) &&
      roles.includes("62");
    this.setState({ disableFieldsForGuestRole: isGuestUserRoleEnabled });
    isChangedRolesEnabled =
      isChangedRolesEnabled ||
      isServiceDeskRolesEnabled ||
      roles.includes("65");
    isProblemRolesEnabled =
      isProblemRolesEnabled ||
      isServiceDeskRolesEnabled ||
      roles.includes("64");
    isIncidentRolesEnabled =
      isIncidentRolesEnabled ||
      isServiceDeskRolesEnabled ||
      roles.includes("63");
    isFulfillmentRolesEnabled =
      isFulfillmentRolesEnabled ||
      isServiceDeskRolesEnabled ||
      roles.includes("62");
    let isTaskRoleEnabled =
      isD2CRolesEnabled || isServiceDeskRolesEnabled || roles.includes("61");
    let isCMDBRoleEnabled =
      roles.includes("37") || roles.includes("38") || roles.includes("39");
    this.setState({
      isChangedRolesEnabled,
      isProblemRolesEnabled,
      isIncidentRolesEnabled,
      isFulfillmentRolesEnabled,
      isTaskRoleEnabled,
      isCMDBRoleEnabled,
      isD2CRolesEnabled
    });
  }

  exportDocument(type = ''){
		try{
			if(!type){
				throw new Error('Error in exporting document');
			}
      // console.log("this.props.....",this.props);
			let offeringNameSx = '';
			let asgUsername = '';
			let asgGroupname = '';
			let reportedThroughValue = '';
      let questionsAndAnswers = [];
      let piSectionGroupName = [];
      let data = this.props?.myOrderQuestionDetails;
      const sortedCategories = data.sort((a, b) => a.categorySequence - b.categorySequence);
      let questionAndAnswerData = this.props?.fulfillmentDetails?.questions;
      const sortedQuestions = questionAndAnswerData.sort((a, b) => a.questionSequence - b.questionSequence);
      const { breakFixSourceName = [] } = this.props;

			if(breakFixSourceName && Array.isArray(breakFixSourceName) && breakFixSourceName.length > 0){
				let find = (breakFixSourceName).find(item => item['field1Value'] == this.props?.fulfillmentDetails?.reportedThrough);
				if(find){
					reportedThroughValue = find['field1Key'] || '';
				}
			}
				if(this.props?.fulfillmentDetails?.offeringNameSx){
					offeringNameSx = this.props.fulfillmentDetails.offeringNameSx;
				}
				if(this.props?.fulfillmentDetails?.asgUsername){
					asgUsername = this.props.fulfillmentDetails.asgUsername == '0' || this.props.fulfillmentDetails.asgUsername == 0 ? '' : this.props.fulfillmentDetails.asgUsername;
				}
				if(this.props?.fulfillmentDetails?.asgGroupname){
					asgGroupname = this.props.fulfillmentDetails.asgGroupname;
				}
        if (sortedQuestions) {
          sortedQuestions.forEach(item => {
              let str = `(Question: ${item.question}, Answer: ${item.answer})`;
              questionsAndAnswers.push(str);
              console.log("questionsAndAnswers", questionsAndAnswers);
          });
      }
      if (sortedCategories) {
        sortedCategories.forEach(item => {
            if (item.categoryName) {
                piSectionGroupName.push(item.categoryName);
                console.log("piSectionGroupName", piSectionGroupName);
            }
        });
    }

			let obj = {...this.props.fulfillmentDetails, ...{offeringNameSx, asgUsername, asgGroupname, reportedThroughValue, questionsAndAnswers, piSectionGroupName}};
			exportDoc(type, obj);
		}catch(e){
			console.error(e);
		}
	}


  render() {
    const payloadDSAT = this.createDSATPayload();
    let rightpanel = "";
    if (
      this.state.service == 0 &&
      this.state.provideUserDetails == 0 &&
      this.state.shownotify == 0 &&
      this.state.provideattachment == 0 &&
      this.state.SLA == 0 &&
      this.state.showPutOnHold == 0 &&
      this.state.showCancel == 0 &&
      this.state.showfulfill == 0 &&
      this.state.taskeditFlag == 0 &&
      this.state.approvalFlag == 0 &&
      this.state.relatedWorkItemsFlag == 0 &&
      this.state.relateCIFlag == 0 &&
      this.state.showApprovals == 0
    )
      rightpanel = (
        <FulfillmentTabPage
          translator={this.props.tr}
          fulfillmentDetails={this.props.fulfillmentDetails}
          itemId={this.itemId}
          fieldStatus={this.props.fieldStatus}
          ordernumber={this.props.fulfillmentDetails.ordernumber}
          date_format={this.state.date_format}
          rightEditPanel={this.rightEditPanel}
          disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}
        />
      );
    if (this.state.service != 0)
      rightpanel = (
        <XsmServiceDetails
          offeringId={this.state.offeringId}
          translator={this.props.tr}
          rightEditPanel={this.rightEditPanel}
        />
      );
    if (this.state.aiInsights != 0)
      rightpanel = (
        <AiInsights 
         payloadDSAT={payloadDSAT}
         aismDSAT={this.state.aiInsightDSATEnabled} 
         fulfillmentDetails={this.props.fulfillmentDetails} 
         resetRightSide={this.resetRightSide}/>
      );
    if (this.state.provideUserDetails != 0) {
      rightpanel = (
        <XsmUserDetails
          translator={this.props.tr}
          userId={this.state.userDetailsID}
          userDetailsLabel={this.state.userDetailsLabel}
          rightEditPanel={this.rightEditPanel}
        />
      );
    }
    if (this.state.processhierarchy != 0)
      rightpanel = (
        <FulfillmentTabPage
          disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}
          translator={this.props.tr}
          fulfillmentDetails={this.props.fulfillmentDetails}
          itemId={this.itemId}
          fieldStatus={this.props.fieldStatus}
          ordernumber={this.props.fulfillmentDetails.ordernumber}
          date_format={this.state.date_format}
          rightEditPanel={this.rightEditPanel}
        />
      );
    if (this.state.shownotify != 0)
      rightpanel = (
        <FulfillmentNotifyTab
          ordernumber={this.props.fulfillmentDetails.ordernumber}
          fulfillmentdetails={this.props.fulfillmentDetails}
          translator={this.props.tr}
          full_name={this.state.full_name}
          user_id={this.state.user_id}
          roleIdentification={this.roleIdentification}
          showNotifyForm={this.state.showNotifyForm}
          setShowNotifyForm={(value) => {
            this.setState({ showNotifyForm: value });
          }}
          rightEditPanel={this.rightEditPanel}
          disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}
        />
      );

    if (this.state.provideattachment != 0)
      rightpanel = (
        <XsmBreakFixAttachment
          itemDetails={this.props.fulfillmentDetails}
          translator={this.props.tr}
          date_format={this.state.date_format}
          user_id={this.state.user_id}
          roleIdentification={this.roleIdentification}
          rightEditPanel={this.rightEditPanel}
          disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}
        />
      );
    if (GLOBAL.fulfillsavemsg != "")
      rightpanel = <XsmBreakFixAction0 translator={this.props.tr} />;
    if (this.state.SLA != 0)
      rightpanel = (
        <FulfillmentSLAProgress
          translator={this.props.tr}
          fulfillmentdetails={this.props.fulfillmentDetails}
          breakFixSLAResolution={
            this.props.breakFixSLAResolutionLogDetails.slaStatus
          }
          rightEditPanel={this.rightEditPanel}
          module="13"
          disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}
          role_id={this.state.role_id}
        />
      );
    if (this.state.showSystemExternalAttributesFlag != 0)
      rightpanel = (
        <XsmBreakFixExternalSystemAttributes
          translator={this.props.tr}
          rightEditPanel={this.rightEditPanel}
          disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}
        />
      );
    if (this.state.showPutOnHold != 0)
      rightpanel = (
        <FulfillmentPutOnHold
          translator={this.props.tr}
          params={navigationHooks.params}
          processHierarchy={this.processHierarchy}
          user_id={this.state.user_id}
          fulfillmentdetails={this.props.fulfillmentDetails}
          roleIdentification={this.roleIdentification}
          rightEditPanel={this.rightEditPanel}
        />
      );
    if (this.state.showCancel != 0)
      rightpanel = (
        <FulfillmentCancel
          processHierarchy={this.processHierarchy}
          fulfillmentdetails={this.props.fulfillmentDetails}
          translator={this.props.tr}
          user_id={this.state.user_id}
          roleIdentification={this.roleIdentification}
          rightEditPanel={this.rightEditPanel}
        />
      );

    if (this.state.taskeditFlag != 0)
      rightpanel = (
        <PopUpTask
          fieldStatus={this.props.fieldStatus}
          orderNumber={this.props.fulfillmentDetails.ordernumber}
          fulfillmentDetails={this.props.fulfillmentDetails}
          clicktaskEdit={this.clicktaskEdit}
          breakFixItemId={this.props.breakFixItemId}
          itemId={this.props.fulfillmentDetails.itemId}
          module="Fullfilment"
          translator={this.props.tr}
          rightEditPanel={this.rightEditPanel}
          disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}
          isTaskRoleEnabled={this.state.isTaskRoleEnabled}
        />
      );

    if (this.state.approvalFlag != 0)
      rightpanel = (
        <FulfillmentApprovalStatus
          fieldStatus={this.props.fieldStatus}
          fulfillmentDetails={this.props.fulfillmentDetails}
          translator={this.props.tr}
          itemId={this.props.fulfillmentDetails.itemId}
          rightEditPanel={this.rightEditPanel}
        />
      );

    if (this.state.relatedWorkItemsFlag != 0)
      rightpanel = (
        <FulfillmentRelatedWork
          fieldStatus={this.props.fieldStatus}
          breakFixItemId={this.props.breakFixItemId}
          itemId={this.itemId}
          fulfillmentDetails={this.props.fulfillmentDetails}
          translator={this.props.tr}
          rightEditPanel={this.rightEditPanel}
          disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}
          isChangedRolesEnabled={this.state.isChangedRolesEnabled}
          isProblemRolesEnabled={this.state.isProblemRolesEnabled}
          isIncidentRolesEnabled={this.state.isIncidentRolesEnabled}
          isFulfillmentRolesEnabled={this.state.isFulfillmentRolesEnabled}
        />
      );

    if (this.state.relateCIFlag != 0)
      rightpanel = (
        <FulfillmentCMDBRelatedWork
          fieldStatus={this.props.fieldStatus}
          itemId={this.itemId}
          editChangeInitialData={this.props.editChangeInitialData}
          fulfillmentDetails={this.props.fulfillmentDetails}
          itemNumber={this.props.fulfillmentDetails.itemNumber}
          createdOn={this.props.fulfillmentDetails.createdOn}
          translator={this.props.tr}
          rightEditPanel={this.rightEditPanel}
          disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}
          isCMDBRoleEnabled={this.state.isCMDBRoleEnabled}
        />
      );

    if (this.state.showfulfill != 0)
      rightpanel = (
        <FulfillmentReason
          translator={this.props.tr}
          fulfillmentdetails={this.props.fulfillmentDetails}
          processHierarchy={this.processHierarchy}
          setResComment={this.setResComment}
          user_id={this.state.user_id}
          roleIdentification={this.roleIdentification}
          rightEditPanel={this.rightEditPanel}
        />
      );

    if (this.state.showApprovals != 0) {
      rightpanel = (
        <Approvals
          translator={this.props.tr}
          changeId={this.props.params.changeId}
          itemDetails={this.props.fulfillmentDetails}
          changeActionView={this.processHierarchy}
          roleIdentification={this.roleIdentification}
          editChangeInitialData={{}}
          module="Fulfillment"
          rightEditPanel={this.rightEditPanel}
        />
      );
    }

    if (this.state.showReopen != 0) {
      rightpanel = (
        <ReopenItemForm
          translator={this.props.tr}
          itemId={this.itemId}
          itemDetails={this.props.fulfillmentDetails}
          changeActionView={this.processHierarchy}
          roleIdentification={this.roleIdentification}
          module="Fullfilment"
          user_id={this.state.user_id}
          rightEditPanel={this.rightEditPanel}
        />
      );
    }
    if (this.state.showWatcher != 0) {
      rightpanel = (
        <WatcherDetails
          translator={this.props.tr}
          itemId={this.itemId}
          itemDetails={this.props.fulfillmentDetails}
          changeActionView={this.processHierarchy}
          roleIdentification={this.roleIdentification}
          module="Fullfilment"
          status={this.props.fulfillmentDetails.status}
          user_id={this.state.user_id}
          rightEditPanel={this.rightEditPanel}
          disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}
        />
      );
    }

    if (this.state.showTags != 0) {
      const isCancelledStatus = this.props.fulfillmentDetails.itemStatus == '85';
			const isClosedStatus = this.props.fulfillmentDetails.itemStatus == '80';
			const isTerminalStatus = isCancelledStatus || isClosedStatus;
      rightpanel = (
        <TagDetails
          itemId={this.itemId}
          itemDetails={this.props.fulfillmentDetails}
          module="Fulfillment"
          mainModule="Fulfillment"
          companyId={this.props.fulfillmentDetails.companyId}
          userId={this.state.user_id}
          userName={this.state.full_name}
          disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}
          isD2CRolesEnabled={this.state.isD2CRolesEnabled}
          isTerminalStatus={isTerminalStatus}
        />
      );
    }

    if (this.state.showAvailableIndividual != 0) {
      rightpanel = (
        <AvailableIndividuals
          setAssignmentGrpUsr={this.setAssignmentGrpUsr}
          selectedAssignmentGroup={this.state.selectedAssignmentGroup}
          selectedAssignmentUser={this.state.selectedAssignmentUser}
          module="Fulfillment"
          disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}
        />
      );
    }

    if (this.props.isFetchingDetails.isFetching)
      return (
        <div className="rctLoader">
          <LoadingIcons.Bars height="5em" stroke="transparent" fill="#06bcee" />
        </div>
      );
    else
      return (
        <div>
          {this.state.role_id !== "" ? (
            <WibSearchFulfillment
              tr={this.props.tr}
              roleIdentification={this.roleIdentification}
              group_id={this.state.groupID}
              changeImplentationRole={this.state.changeImplentationRole}
            />
          ) : null}
          <div className="container-fluid margin-t-10 margin-b-15">
            <Breadcrumbs
              activePageName="Edit"
              parentPageurl="/quickViewBreakFix"
              parentPageName="Work Item Board"
            />
          </div>
          <AiInsightsBar
          aiInsightsShowFun={this.aiInsightsShowFun}
          isVisible={this.state.isVisible}
          aismDSAT={this.state.aiInsightDSATEnabled}/>

          <div className="container-fluid">
            <div bsClass="minHeightWIB">
              <Form>
                {/* Home Page blue bar Section Start*/}

                <XsmSupportHeader
                  setProcessHierarchyState={this.setProcessHierarchyState}
                  fulfillmentDetails={this.props.fulfillmentDetails}
                  ordernumber={this.props.fulfillmentDetails.orderNumber}
                  itemId={this.itemId}
                  processHierarchy={this.processHierarchy}
                  showSLA={this.showSLA}
                  showSystemExternalAttributes={
                    this.showSystemExternalAttributes
                  }
                  saveFulfill={this.saveFulfill}
                  fulfillmentnumber={this.props.fulfillmentDetails.itemNumber}
                  showNotify={this.showNotify}
                  showPutOnHold={this.showPutOnHold}
                  showRefresh={this.showRefresh}
                  showProvideAttachment={this.showProvideAttachment}
                  translator={this.props.tr}
                  disabledSave={this.state.disabledSave}
                  showCancel={this.showCancel}
                  showTimeline={this.showTimeline}
                  showfulfill={this.showfulfill}
                  user_id={this.state.user_id}
                  roleIdentification={this.roleIdentification}
                  showTasks={this.showTasks}
                  showApprovals={this.showApprovals}
                  showApprovalsStatus={this.showApprovalsStatus}
                  showRelatedWorkItems={this.showRelatedWorkItems}
                  showRelateCIs={this.showRelateCIs}
                  showReopen={this.showReopen}
                  showWatcher={this.showWatcher}
                  showTags={this.showTags}
                  rightEditPanel={this.rightEditPanel}
                  disableFieldsForGuestRole={
                    this.state.disableFieldsForGuestRole
                  }
                  RefreshScreen={this.RefreshScreen}
                  isProcesshierarchy={this.state.processhierarchy}
                  isShowTags={this.state.showTags}
                  provideattachment={this.state.provideattachment}
                  taskeditFlag={this.state.taskeditFlag}
                  approvalFlag={this.state.approvalFlag}
                  isShowWatcher={this.state.showWatcher}
                  SLA={this.state.SLA}
                  exportDocument={this.exportDocument}
                  setState={this.setState.bind(this)}
                  isVisible={this.state.isVisible} 
                />

                {/* Home Page blue bar Section end*/}
                <PanelGroup direction="horizontal">
                  <Panel
                    id="sidebar"
                    minSize={33}
                    order={1}
                    defaultSize={this.state.isRightEditPanel ? 67 : 100}
                    className={
                      this.state.isRightEditPanel ? "isShowLeftPanel" : ""
                    }
                  >
                    <XsmBreakFixFormTab
                      showGroupError={this.state.showGroupError}
                      showDateError={this.state.showDateError}
                      roleIdentification={this.roleIdentification}
                      fulfillmentDetails={this.props.fulfillmentDetails}
                      currency={this.state.currency}
                      showServiceDetails={this.showServiceDetails}
                      showConsumerDetails={this.showConsumerDetails}
                      itemId={this.itemId}
                      showPutOnHold={this.showPutOnHold}
                      translator={this.props.tr}
                      individualFieldVal={this.state.individualFieldVal}
                      showHoldError={this.state.showHoldError}
                      hideHoldError={this.hideHoldError}
                      resetDateError={this.resetDateError}
                      showTimeline={this.showTimeline}
                      showTimelineFlag={this.state.showTimelineFlag}
                      grpReasonErrorColor={this.state.grpReasonErrorColor}
                      setGroupReasonErrorColor={this.setGroupReasonErrorColor}
                      setSupportCompany={this.setSupportCompany}
                      rightEditPanel={this.rightEditPanel}
                      disableFieldsForGuestRole={
                        this.state.disableFieldsForGuestRole
                      }
                      setAssignmentGrpUsr={this.setAssignmentGrpUsr}
                      selectedAssignmentGroup={
                        this.state.selectedAssignmentGroup
                      }
                      selectedAssignmentUser={this.state.selectedAssignmentUser}
                      showAvailableIndividual={this.showAvailableIndividual}
                      setState={this.setState.bind(this)}
                      isVisible={this.state.isVisible} 
                    />
                  </Panel>
                  {this.state.isRightEditPanel ? (
                    <>
                      <PanelResizeHandle className="isShowLeftPanel resizeHandle">
                        <div className="outlne">
                          <div className="handIcn">
                            <i
                              className="fa fa-chevron-left"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                      </PanelResizeHandle>
                      <Panel minSize={33} order={2} defaultSize={33}>
                        <div className="stickyArea rBoxStyle">
                          {/* Xsm BreakFix Action Section Start*/}

                          {rightpanel}
                          {/* Xsm BreakFix Action Section End*/}
                        </div>
                      </Panel>
                    </>
                  ) : (
                    ""
                  )}
                </PanelGroup>
              </Form>
            </div>
          </div>
          {/* {homepagelocation == "/unauthorized" || homepagelocation === "/userActivationPending" ? null : <FooterMain isLogout={GLOBAL.isLogout} />} */}
        </div>
      );
  }
}

export function mapStateToProps({
  fulfillmentDetails,
  breakFixSLAResolutionLogDetails,
  spcmReducer,
  specificUserDetails,
  loadHamburgerOptions,
  isFetchingDetails,
  fieldStatus,
  myOrderQuestionDetails,
  aismProperty,
  breakFixSourceName,
}) {
  //  alert("breakFixEditDetails:"+breakFixAuditLogDetails);
  //console.log("breakFixEditDetails:"+breakFixAuditLogDetails.from);
  return {
    fulfillmentDetails,
    breakFixSLAResolutionLogDetails,
    tr: spcmReducer.tr,
    specificUserDetails,
    loadHamburgerOptions,
    isFetchingDetails,
    fieldStatus,
    myOrderQuestionDetails,
    aismProperty,
    breakFixSourceName,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateLoadFulfillmentDetails,
      loadFulfillmentDetails,
      LoadAMSgrp,
      loadBreakFixSLAResolutionLogDetails,
      getTranslation,
      loadExternalAttributesDetails,
      getFulfillmentTimeLineStatus,
      loadBreakFixAuditLogDetails,
      loadBreakFixComplexity,
      loadStatusBasedHamburgerOptions,
      resetLoadHamburgerOptionsReducer,
      loadProfile,
      fetchWatcherDetails,
      getQuestionsCategoryWise,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(FulfillmentIndex);
