
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

/*export default function (state = initialState.changeTotalApprovalDetails, action){

	switch (action.type){
		case 'LOAD_CHANGETOTALAPPROVALDETAILS_SUCCESS':
					return action.changeTotalApprovalDetails.data;
		case 'LOAD_CHANGETOTALAPPROVALDETAILS_FAILURE':
				return action.failureMessage;
		default:
				return state;
		}

}*/

export const changeTotalApprovalDetails = (state = initialState.changeTotalApprovalDetails, action) =>{
	switch (action.type){
		case 'LOAD_CHANGETOTALAPPROVALDETAILS_SUCCESS':
			return action.changeTotalApprovalDetails.data;
			case 'LOAD_CHANGETOTALAPPROVALDETAILS_FAILURE':
					return action.failureMessage;
			default:
			return state;
	}
};

export const requesterDelegateeDetails = (state = initialState.requesterDelegateeDetails, action) =>{
	switch (action.type){
		case 'LOAD_REQUESTERDELEGATEEDETAILS_SUCCESS':
			return action.requesterDelegateeDetails.data;
			default:
			return state;
	}
};

export const changeTotalApprovalHistoryDetails = (state = initialState.changeTotalApprovalHistoryDetails, action) =>{
	switch (action.type){
		case 'LOAD_CHANGETOTALAPPROVALHISTORYDETAILS_SUCCESS':
			return action.changeTotalApprovalHistoryDetails.data;
			case 'LOAD_CHANGETOTALAPPROVALHISTORYDETAILS_FAILURE':
					return action.failureMessage;
			default:
			return state;
	}
};
