
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export default function(state={},action){
     switch(action.type){
         case 'SAVE_FILTERS_STATUS':
                    return action.payload
         case 'RESET_FILTERS_STATUS':
                    return action.payload
         default:
                    return state                  
     }
}