
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popover, PopoverActionsBar } from "@progress/kendo-react-tooltip";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {TextBox,TextArea,NumericTextBox,Checkbox,RadioButton,RadioGroup} from "@progress/kendo-react-inputs";
import { MdArrowRightAlt,MdGTranslate} from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { IoCopyOutline } from "react-icons/io5";

const langList = ["English", "French", "German", "Italian"];

const TranslatePopup = (props) => {
  const anchor = React.useRef(null);
  const [show, setShow] = React.useState(false);
  const tr = useSelector((state) => state.spcmReducer.tr);

  // useEffect(() => {
	// 	setShow(true);
	// }, []);

  const onClick = () => {
    setShow(!show);
  };

  return (
    <>
      <Button 
        title="Translate"
        className='asignicnTrans'
        onClick={onClick}
        disabled={show}
        ref={anchor}
      >
        <MdGTranslate style={{fontSize:"20px",color:"#04838f"}} />
      </Button>
      <Popover
          show={show}
          anchor={anchor.current && anchor.current.element}
          position={props.popPosition}
          title={"Translate"}
          className={"transPopdv"}
        >
          <Button className="clsbtn" onClick={onClick} fillMode={"flat"}><IoClose size={16} /></Button>
          <div className="transmdv">
              <DropDownList data={langList} defaultValue="Select" />
              <MdArrowRightAlt />
              <DropDownList data={langList} defaultValue="Select" />
              <Button fillMode={"flat"}>{tr["Ok"]}</Button>
            </div>
            <div>
              <TextArea defaultValue={"Issue Description"} rows={4} />
            </div>
            <div className="popbot bgGray">
              <span>1812 characters</span>
            <Button className="margin-l-15" fillMode={"flat"}><IoCopyOutline className="margin-r-5" />Copy</Button></div>
        </Popover>
    </>
  );
};

export default TranslatePopup;
