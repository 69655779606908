
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
//const getCategoryList = 'https://xsm-f.mybluemix.net/getOfferingsForListRestifySelfService/?categoryId=';
 import { GLOBAL } from '_Globals';

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

export function loadOrdersSuccess(ordersList) {
	return {
		type: 'LOAD_ORDERS_SUCCESS',
		ordersList
	};
}

import Cookies from 'universal-cookie';
const cookies = new Cookies();
let languageSelected = cookies.get("gph");
languageSelected = languageSelected.split("~");
languageSelected = languageSelected[20];

export function fetchCancellationType(companyId,modules,fields,status){
	return dispatch=>axios.get(`/api/company_menulist?companyId=${companyId}&module=${modules}&field=${fields}&language=${languageSelected}&status=${status}`).then(reterivedReasons =>dispatch({type: "LOAD_CANCELLATION_REASON",reasons: reterivedReasons.data}));
}

export function loadOrdersList(searchBy, type, userId, sorting, ordering, currentPage, size) {
	console.log(searchBy);
	var str = {};
	str.searchByList = type;
	str.multipleValueList = "Breakfix,Item";
	// if(listFilter == 'onBehalf') {
	// 	str.searchByList=searchBy+","+type;
	// 	str.multipleValueList=userId+"|"+"Breakfix,Item";
	// }
	// else if(listFilter == 'self') {
	// 	str.searchByList="createdBy"+","+type;
	// 	str.multipleValueList=userId+"|"+"Breakfix,Item";
	// }
	// str.operatorList="or,and";
	str.sortBy = sorting;
	str.orderBy = ordering;
	if (currentPage && currentPage != undefined)
		str.currentPage = currentPage;
	if (size && size != undefined)
		str.size = size;
	return (dispatch) => {
		dispatch({ type: "LOADER_IN_PROGRESS_2" });
		api.post(GLOBAL.ordersGetUrl, str)
			.then((ordersList) => {
				if (!ordersList) {
					throw Error(ordersList.statusText);
				}
				return ordersList;
			})
			.then((ordersList) => {
				dispatch(loadOrdersSuccess(ordersList))
				dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_2' });
			})
			.catch((err) => {
				console.log(err);
				dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_2' });
			});
	};
}

export function resetOrdersList(){
	let obj={}
	obj.data=[]
	return(dispatch) =>{

		dispatch(loadOrdersSuccess(obj));
	}
}

export function loadFilterOrderList(searchParams, currentPage, size, sortBy, asc_desc, searchFilterDropdown, searchText) {
	return (dispatch) => {
		dispatch({ type: "LOADER_IN_PROGRESS_3" });

		if (sortBy && sortBy != undefined)
			searchParams.sortBy = sortBy;
		else
			searchParams.sortBy = "createdOn";

		if (asc_desc && asc_desc != undefined)
			searchParams.orderBy = asc_desc;
		else
			searchParams.orderBy = "desc";

		if (currentPage && currentPage != undefined)
			searchParams.currentPage = currentPage;
		if (size && size != undefined)
			searchParams.size = size;
		if (searchFilterDropdown && searchText && searchFilterDropdown != undefined && searchText != undefined) {
			searchFilterDropdown=="employeeNumber"?searchParams.patternList="equal":searchParams.patternList = "like";
			searchParams.searchByList = searchFilterDropdown + ',' + searchParams.searchByList;
			searchParams.multipleValueList = searchText + '|' + searchParams.multipleValueList;
		}
		//if(!_.isEmpty(searchParams)) {params = {params:searchParams};} //lodash isEmpty checks for nulls too

		api.post(GLOBAL.ordersGetUrl, searchParams)
			.then((ordersList) => {
				if (!ordersList) {
					//dispatch(loadBreakFixFailure(breakFix.statusText))
					throw Error(ordersList.statusText);
				}
				return ordersList;
			})
			.then((ordersList) => {
				dispatch(loadOrdersSuccess(ordersList));
				dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_3' });
			})
			.catch((err) => {
				console.log(err);
				dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_3' });
			});
		//console.log("not calling loadBreakFixQuickView");

	};
}

export function loadOrdersFilterResultsForSearchString(searchBy, type, searchFilterDropdown, userId, sorting, ordering, currentPage, size, searchText) {
	// console.log(searchBy);
	var str = {};
	// str.searchByList=searchFilterDropdown+","+searchBy;
	// str.multipleValueList=searchText+"|"+userId;\
	str.searchByList = searchFilterDropdown + "," + type;
	str.multipleValueList = searchText + "|" + "Breakfix,Item";

	// str.operatorList="or,and";
	str.sortBy = sorting;
	str.orderBy = ordering;
	if(searchFilterDropdown==="itemStatus")
	  str.patternList="equal";
	  else if(searchFilterDropdown=="employeeNumber")
	{
		str.patternList="equal";
	}
	else
	  str.patternList="like";
	//str.patternList = "like";
	if (currentPage && currentPage != undefined)
		str.currentPage = currentPage;
	if (size && size != undefined)
		str.size = size;
	//str = JSON.stringify(str);
	return (dispatch) => {
		dispatch({ type: "LOADER_IN_PROGRESS_1" });
		api.post(GLOBAL.ordersGetUrl, str)
			.then((ordersList) => {
				if (!ordersList) {
					throw Error(ordersList.statusText);
				}
				return ordersList;
			})
			.then((ordersList) => {
				dispatch(loadOrdersSuccess(ordersList))
				dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_1' });
			})
			.catch((err) => {
				console.log(err);
				dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_1' });
			});
	};
}

export function addToSelectedOrderStatusList(status) {
	//alert("addStatusToSelectedInvestigationStatusList"+status)
	return {
		type: 'ADD_TO_SELECTEDORDER_STATUS_LIST',
		payload: status
	};
}

export function removeFromSelectedOrderStatusList(status) {
	//alert("removeFromSelectedOrderStatusList"+status)
	return {
		type: 'REMOVE_FROM_SELECTEDORDER_STATUS_LIST',
		payload: status
	};
}

export function addToSelectedOrderTypeList(orderType) {
	//alert("addStatusToSelectedInvestigationStatusList"+status)
	return {
		type: 'ADD_TO_SELECTEDORDER_TYPE_LIST',
		payload: orderType
	};
}

export function removeFromSelectedOrderTypeList(orderType) {
	return {
		type: 'REMOVE_FROM_SELECTEDORDER_TYPE_LIST',
		payload: orderType
	};
}

export function loadOrderDetails(orderId) {
	//alert("loadOrderDetails"+orderId);
	let str = {};
	str.orderId = orderId;
	//str.searchByList="itemid";
	str = JSON.stringify(str);

	return (dispatch) => {
		//	api.get(GLOBAL.quickViewOrdersUrlMyRequest+"searchByList=itemid&valueList="+orderId)
		api.get(GLOBAL.quickViewOrdersUrlMyRequest, { headers: { 'query': str } })
			.then((orderDetails) => {
				if (!orderDetails) {
					throw Error(orderDetails.statusText);
				}
				return orderDetails;
			})
			.then((orderDetails) => {
				dispatch(loadOrdersDetailsSuccess(orderDetails.data))
			})
			.catch((err) => {
				console.log(err);
			});
	};
}

export function loadOrdersDetailsSuccess(orderDetails) {
	return {
		type: 'LOAD_ORDER_DETAILS_SUCCESS',
		orderDetails
	};
}

//getting breakfix order details
export function loadBreakFixOrderDetails(orderId) {
	//alert("M12");
	console.log("showloaderAction2");
	return (dispatch) => {
		dispatch({type:'LOADER_IN_PROGRESS_5'});
		axios.get(GLOBAL.quickViewBrkOrdersUrl + orderId + "?requireUrgencyValue=Y")
			.then((breakFixOrderDetails) => {
				if (!breakFixOrderDetails) {
					dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY_5'});
					throw Error(breakFixOrderDetails.statusText);
				}
				return breakFixOrderDetails;
			})
			.then((breakFixOrderDetails) => {
				dispatch(loadBreakFixOrdersDetailsSuccess(breakFixOrderDetails));
				dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY_5'});
			})
			.catch((err) => {
				console.log(err);
				dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY_5'});
			});
	};
}
export function loadBreakFixOrderDetailsInteger(orderId){
	return(dispatch) =>{
		axios.get(GLOBAL.quickViewBrkOrdersUrl+orderId).then((breakFixOrderDetailsInt)=>{
            dispatch({type: 'LOAD_BREAKFIXORDER_DETAILS_SUCCESS_INTEGER',breakFixOrderDetailsInt});
		});
	};
}
export function setUrgencyList(companyId,moduleName,fieldName,languageSelected,status){
	return (dispatch) => {
		axios.get('/api/company_menulist?companyId='+companyId+'&module='+moduleName+'&field='+fieldName+'&language='+languageSelected+'&status='+status).then((respondedUrgency)=>{
			let oldUrgencyList={};
			respondedUrgency.data.map((item)=>{
			    oldUrgencyList[item.field1Value]=item.field1Key;
		    });
		    dispatch({type: 'LOAD_URGENCY_LIST', oldUrgencyList});
		});
	}
}

export function loadBreakFixOrdersDetailsSuccess(breakFixOrderDetails) {
	return {
		type: 'LOAD_BREAKFIXORDER_DETAILS_SUCCESS',
		breakFixOrderDetails
	};
}
//==============
//getting approver list
export function loadApproverList(itemId, offeringId) {
	var str = {};
	str.itemId = itemId;
	str.offeringId = offeringId;
	str = JSON.stringify(str);
	console.log("?itemId=" + itemId + "&offeringId=" + offeringId);
	return (dispatch) => {
		api.get(GLOBAL.approverListURL, { headers: { 'query': str } })
			.then((approverList) => {
				if (!approverList) {
					throw Error(approverList.statusText);
				}
				return approverList;
			})
			.then((approverList) => {
				dispatch(loadApproverListSuccess(approverList))
			})
			.catch((err) => {
				console.log(err);
			});
	};
}
export function loadApproverListSuccess(approverList) {
	return {
		type: 'LOAD_APPROVERLIST_SUCCESS',
		approverList
	};
}

export function getQuestionsCategoryWise(itemId){
	return(dispatch) =>{
		dispatch({type:'SERVICE_REVIEW_LOADER_IN_PROGRESS'});
		api.get(GLOBAL.questionsCategoryWiseURL + itemId)
			.then((questionsCategoryWise)=>{
				if(!questionsCategoryWise){
					throw Error(questionsCategoryWise.statusText);
				}
				return questionsCategoryWise;
			})
			.then((questionsCategoryWise) =>{
				dispatch(getQuestionsCategoryWiseSuccess(questionsCategoryWise))
				dispatch({type:'SERVICE_REVIEW_LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
			})
			.catch((err) => {
				console.log(err);
			});
	};
}

export function getQuestionsCategoryWiseSuccess(questionsCategoryWise) {
	return {
		type: 'LOAD_QUESTIONS_CATEORY_WISE_SUCCESS',
		questionsCategoryWise
	};
}

export function resetMRQVFilters() {
	return {
		type: 'RESET_MR_QV_FILTERS',
		payload: null
	};
}

export function resetSortingArrows() {
	return (dispatch) => {
		dispatch({
			type: 'RESET_SORTING_ARROWS',
			reset: true
		});
	};
}

export function sortingArrowsChanged() {
	return (dispatch) => {
		dispatch({
			type: 'SORTING_ARROWS_CHANGED',
			reset: false
		});
	};
}