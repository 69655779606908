
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, change, SubmissionError } from 'redux-form';
import { Link } from "react-router-dom";

import { loadTagCompany, loadBusinessFunction, emptyBusinessFunction, loadSXMenulistData, loadAllCompositeTags, loadMemberTagsForCompositeTag, emptyTagDetailsByTagId, loadMemberTagsForChecklistTag, loadAllChecklistTags } from '_Actions/foundation/tags/tagsAction';
import TagsDropdownPlusTypeahead from '../common/TagsDropdownPlusTypeahead.js';
import TagsDropdownPlusTypeaheadWithInfo from '../common/TagsDropdownPlusTypeaheadWithInfo.js';
import { _passwordField, _inputField, _dropDown, _checkbox, _number } from '../../../common/formFields';
import ListLoader from "../../../common/loaders/ListLoader.js"

import Cookies from "universal-cookie";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { BiLinkExternal } from "react-icons/bi";
import { AiOutlineQuestionCircle } from "react-icons/ai";

const cookies = new Cookies();
let app_url = cookies.get("gph");
if (app_url) app_url = app_url.replace("s:", "");
if (app_url) app_url = app_url.substring(0, app_url.lastIndexOf("."));
app_url = app_url.split("~");
app_url = app_url[18];

const validate = (values) => {
    const errors = {};
    if (!values.company)
        errors.company = "Select company";
    if (!values.module)
        errors.module = "Select module";
    if (!values.businessFunctionId)
        errors.businessFunctionId = "Select business function";
    if (!values.label || values.label.trim() == '')
        errors.label = "Select label";
    if (!values.tagType)
        errors.tagType = "Select type";
    if (!values.tagDataValidation)
        errors.tagDataValidation = "Select data validation";
    if (!values.compositeTag)
        errors.compositeTag = "Select composite attribute";
    // if (!values.parentTag)
    //     errors.parentTag = "Select parent attribute";
    if (!values.tagEnabled)
        errors.tagEnabled = "Select enabled";
    // if (!values.tagDataSource)
    //     errors.tagDataSource = "Select data source";
    if (!values.character_limit || (values.character_limit && (values.character_limit < 1 || values.character_limit > 500)))
        errors.character_limit = "Please enter a number between 1 to 500";
    return errors;
}

const FormComponent = (props) => {

    const dispatch = useDispatch();
    const translator = useSelector((state) => state.spcmReducer.tr);
    const foundation_tags_reducer = useSelector((state) => state.foundation_tags_reducer);
    const foundation_tags_loader_reducer = useSelector((state) => state.foundation_tags_loader_reducer);
    const [companySelectedList, setCompanySelectedList] = useState([]);
    const [inputValueCompany, setInputValueCompany] = useState('');
    const [parentSelectedList, setParentSelectedList] = useState([]);
    const [inputValueParent, setInputValueParent] = useState('');
    let tagDataValidationValues = useSelector((state) => state.foundation_tags_reducer.tagDataValidation);

    const selector = formValueSelector('foundationTagsEditForm');
    const tagTypeParam = useSelector(state => selector(state, 'tagType'));
    const tagDataValidationParam = useSelector(state => selector(state, 'tagDataValidation'));
    const companyIdParam = useSelector(state => selector(state, 'company'));
    const businessFunctionNameParam = useSelector(state => selector(state, 'businessFunctionName'));
    const moduleParam = useSelector(state => selector(state, 'module'));

    useEffect(() => {
        dispatch(loadTagCompany());
        dispatch(loadSXMenulistData('Tag', 'Module'));
        dispatch(loadSXMenulistData('Tag', 'Type'));
        dispatch(loadSXMenulistData('Tag', 'DataType'));
        // dispatch(loadSXMenulistData('Tag', 'DataSource'));
    }, []);

    useEffect(() => {
        if (props.initialValues && props.initialValues.company) {
            setCompanySelectedList([{ id: props.initialValues.company, label: props.initialValues.companyName }]);
            if (props.initialValues.module != 'CMDB')
                dispatch(loadBusinessFunction(props.initialValues.company));
            if (props.initialValues.tagType == 'Member' && props.initialValues.compositeTag != '0')
                dispatch(loadAllCompositeTags(props.initialValues.company, props.initialValues.businessFunctionName, props.initialValues.module, 'Composite'));
            if (props.initialValues.parentTag && props.initialValues.parentTag != '0')
                setParentSelectedList([{ id: props.initialValues.parentTag, label: props.initialValues.parentTagName }]);
            if (props.initialValues.compositeTag && props.initialValues.compositeTag != '0')
                dispatch(loadMemberTagsForCompositeTag(props.initialValues.compositeTag));
            if (props.initialValues.tagType == 'Composite')
                dispatch(loadMemberTagsForCompositeTag(props.initialValues.tagId));
            if (props.initialValues.tagType == 'Checklist')
                dispatch(loadMemberTagsForChecklistTag(props.initialValues.tagId));
            if ((props.initialValues.tagType == 'Simple' || props.initialValues.tagType == 'Composite') && (props.initialValues.parentTag && props.initialValues.parentTag != '0'))
                dispatch(loadAllChecklistTags(props.initialValues.company, props.initialValues.businessFunctionName, props.initialValues.module, 'Checklist'));
            // if (props.initialValues.tagType == 'Member' && (!props.initialValues.compositeTag || props.initialValues.compositeTag == '0'))
                // dispatch(loadAllChecklistTags(props.initialValues.company, props.initialValues.businessFunctionName, props.initialValues.module, 'Checklist'));
        }
    }, [props.initialValues]);

    useEffect(() => {
        return () => {
            dispatch(emptyTagDetailsByTagId());
        }
    }, []);

    if (tagTypeParam == 'Member') {
        tagDataValidationValues = tagDataValidationValues.filter((item) => item.field1Value == 'Dropdown' || item.field1Value == 'Typeahead')
    }

    function onCompanySelection(selectedCompany) {
        props.setCompanyErrorState('');
        setCompanySelectedList(selectedCompany);
        dispatch(change('foundationTagsEditForm', 'module', ''));
        dispatch(change('foundationTagsEditForm', 'businessFunctionId', ''));
        dispatch(change('foundationTagsEditForm', 'businessFunctionName', ''));
        dispatch(change('foundationTagsEditForm', 'tagType', ''));
        dispatch(change('foundationTagsEditForm', 'compositeTag', ''));
        dispatch(emptyBusinessFunction());
        if (selectedCompany.length > 0) {
            dispatch(loadBusinessFunction(selectedCompany[0].id));
            dispatch(change('foundationTagsEditForm', 'company', selectedCompany[0].id));
            dispatch(change("foundationTagsEditForm", "companyName", selectedCompany[0].label));
            setInputValueCompany(selectedCompany[0].label);
        }
        else {
            dispatch(change('foundationTagsEditForm', 'company', ''));
            dispatch(change("foundationTagsEditForm", "companyName", ''));
        }
    }

    function setCompanyErrorColor() {
        if (companySelectedList.length === 0)
            props.setCompanyErrorState('error');
        else
            props.setCompanyErrorState('');
    }

    function onCompanyInputChange(val) {
        setInputValueCompany(val);
    }

    function onCrossClickCompany() {
        dispatch(change('foundationTagsEditForm', 'company', ''));
        dispatch(change("foundationTagsEditForm", "companyName", ''));
        setInputValueCompany('');
        setCompanySelectedList([]);
        dispatch(change('foundationTagsEditForm', 'module', ''));
        dispatch(change('foundationTagsEditForm', 'businessFunctionId', ''));
        dispatch(change('foundationTagsEditForm', 'businessFunctionName', ''));
        dispatch(change('foundationTagsEditForm', 'tagType', ''));
        dispatch(change('foundationTagsEditForm', 'compositeTag', ''));
        dispatch(emptyBusinessFunction());
        props.setCompanyErrorState('error');
    }

    let companyTypeaheadOptions = [];
    if (foundation_tags_reducer.tagCompany && foundation_tags_reducer.tagCompany.length > 0) {
        foundation_tags_reducer.tagCompany.forEach((item, i) => {
            let obj = {};
            obj.id = item.field1Value;
            obj.label = item.field1Key;
            companyTypeaheadOptions.push(obj);
        });
    }

    function onModuleChange(e) {
        dispatch(change('foundationTagsEditForm', 'tagType', ''));
        dispatch(change('foundationTagsEditForm', 'compositeTag', ''));
        // if (e.target.value == 'CMDB') {
        dispatch(change('foundationTagsEditForm', 'businessFunctionId', 0));
        dispatch(change('foundationTagsEditForm', 'businessFunctionName', 'Default'));
        // }
        // else {
        //     dispatch(change('foundationTagsEditForm', 'businessFunctionId', ''));
        //     dispatch(change('foundationTagsEditForm', 'businessFunctionName', ''));
        // }
    }

    function onParentSelection(selectedParent) {
        setParentSelectedList(selectedParent);
        if (selectedParent.length > 0) {
            dispatch(change('foundationTagsEditForm', 'parentTag', selectedParent[0].id));
            setInputValueParent(selectedParent[0].label);
        }
        else {
            dispatch(change('foundationTagsEditForm', 'parentTag', ''));
        }
    }

    function onParentInputChange(val) {
        setInputValueParent(val);
    }

    function onCrossClickParent() {
        dispatch(change('foundationTagsEditForm', 'parentTag', ''));
        setInputValueParent('');
        setParentSelectedList([]);
    }

    let parentTagTypeaheadOptions = [];
    if (foundation_tags_reducer.memberTagsForComposite && foundation_tags_reducer.memberTagsForComposite.length > 0) {
        foundation_tags_reducer.memberTagsForComposite.forEach((item, i) => {
            let obj = {};
            obj.id = item.tagId;
            obj.label = item.tagLabel;
            obj.info = item.tagEnabled == true ? 'Status: Active' : 'Status: Inactive';
            parentTagTypeaheadOptions.push(obj);
        });
    }
    parentTagTypeaheadOptions = parentTagTypeaheadOptions.filter((item) => item.id != props.tagId)

    const tooltip = (
        <Popover id="popover-positioned-right" className="padding-10" title="Popover right">
        <strong>Simple:</strong> {translator['Simple Tooltip']} <br />
        <strong>Composite:</strong> {translator['Simple Tooltip2']} <br />
        <strong>Checklist:</strong> {translator['Checklist Tooltip2']}
        </Popover>
    );
    const tooltip2 = (
        <Popover id="popover-positioned-right" className="padding-10" title="Popover right">
        {translator['Composite Tooltip']}
        </Popover>
    );
    const tooltip3 = (
        <Popover id="popover-positioned-right" className="padding-10" title="Popover right">
        {translator['Checklist Tooltip']}
        </Popover>
    );

    if (foundation_tags_loader_reducer.showTagsLoader) {
        return <ListLoader />
    }
    else {
        return (
            <>
                <Row>
                    <Col md={6} sm={6} xs={12}>
                        <Form.Group className="form-group">
                            <Form.Label bsClass=""><span className="rStar"></span>{translator['Company']}</Form.Label>
                            <div className="minHghtdvTypehd">
                            <Field component={TagsDropdownPlusTypeahead}
                                errorColor={props.companyErrorState}
                                onSelection={onCompanySelection}
                                setErrorColor={setCompanyErrorColor}
                                name="company"
                                id="company"
                                className="form-control"
                                options={companyTypeaheadOptions}
                                selectedOptions={companySelectedList}
                                onInputChange={onCompanyInputChange}
                                inputValue={inputValueCompany}
                                onCrossClick={onCrossClickCompany}
                                disabled
                            />
                            </div>
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={6} xs={12}>
                        <Form.Group className="form-group">
                            <Form.Label><span className="rStar"></span>{translator['Module']}</Form.Label>
                            <Field component={_dropDown} name="module" disabled options={foundation_tags_reducer.tagModule} onChange={onModuleChange} className="form-control" />
                        </Form.Group>
                    </Col>
                    {/* {moduleParam != 'CMDB' ?
                        <Col md={6} sm={6} xs={12}>
                            <Form.Group className="form-group">
                                <Form.Label><span className="rStar"></span>{translator['Business Function']}</Form.Label>
                                <Field component={_dropDown} name="businessFunctionId" disabled onChange={(e) => { dispatch(change('foundationTagsEditForm', 'businessFunctionName', e.target[e.target.selectedIndex].text)); dispatch(change('foundationTagsEditForm', 'tagType', '')); dispatch(change('foundationTagsEditForm', 'compositeTag', '')); }} options={foundation_tags_reducer.businessFunction} className="form-control" />
                            </Form.Group>
                        </Col>
                        : ''} */}
                    <Col md={6} sm={6} xs={12}>
                        <Form.Group className="form-group">
                            <Form.Label><span className="rStar">
                            </span>{translator['Type']}
                                <OverlayTrigger trigger={['hover', 'focus']} rootClose placement="right" overlay={tooltip}>
                                    <span><AiOutlineQuestionCircle className="questionSvg"/></span>
                                </OverlayTrigger>
                            </Form.Label>
                            <Field component={_dropDown} disabled="true" name="tagType" options={foundation_tags_reducer.tagType} onChange={(e) => { if (e.target.value == 'Member') { dispatch(loadAllCompositeTags(companyIdParam, businessFunctionNameParam, moduleParam, 'Composite')); } }} className="form-control" />
                        </Form.Group>
                    </Col>
                    {tagTypeParam && tagTypeParam == 'Member' && props.initialValues?.compositeTag != '0' ?
                        <Col md={6} sm={6} xs={12}>
                            <Form.Group className="form-group">
                                <Form.Label><span className="rStar"></span>{translator['Composite Attribute']}</Form.Label>
                                <Link
                                    style={{ "margin-left": "5px" }}
                                    role="button"
                                    to=""
                                    title={translator['Open record']}
                                    onClick={() => {
                                        let newWindow = window.open();
                                        newWindow.opener = null;
                                        newWindow.location =
                                            app_url + "/home?editCustomAttribute=" + props.initialValues.compositeTag;
                                    }}
                                >
                                    <BiLinkExternal style={{ fontSize: "16px" }}/>
                                </Link>
                                <OverlayTrigger trigger={['hover', 'focus']} rootClose placement="right" overlay={tooltip2}>
                                    <span><AiOutlineQuestionCircle className="questionSvg"/></span>
                                </OverlayTrigger>
                                <Field component={_dropDown} name="compositeTag" disabled options={foundation_tags_reducer.allCompositeTags} onChange={(e) => { dispatch(loadMemberTagsForCompositeTag(e.target.value)); }} className="form-control" />
                            </Form.Group>
                        </Col>
                        : ''}
                        {/* tagTypeParam && tagTypeParam == 'Member' && props.initialValues.compositeTag == '0' */}
                    {(props.initialValues?.tagType == 'Simple' || props.initialValues?.tagType == 'Composite') && (props.initialValues?.parentTag && props.initialValues?.parentTag != '0') ?
                        <Col md={6} sm={6} xs={12}>
                            <Form.Group className="form-group">
                                <Form.Label><span className="rStar"></span>{translator['Checklist Attribute']}</Form.Label>
                                <Link
                                    style={{ "margin-left": "5px" }}
                                    role="button"
                                    to=""
                                    title={translator['Open record']}
                                    onClick={() => {
                                        let newWindow = window.open();
                                        newWindow.opener = null;
                                        newWindow.location =
                                            app_url + "/home?editCustomAttribute=" + props.initialValues.parentTag;
                                    }}
                                >
                                    <BiLinkExternal style={{ fontSize: "16px" }}/>
                                </Link>
                                <OverlayTrigger trigger={['hover', 'focus']} rootClose placement="right" overlay={tooltip3}>
                                    <span><AiOutlineQuestionCircle className="questionSvg"/></span>
                                </OverlayTrigger>
                                <Field component={_dropDown} name="parentTag" disabled options={foundation_tags_reducer.allChecklistTags} className="form-control" />
                            </Form.Group>
                        </Col>
                        : ''}
                    <Col md={6} sm={6} xs={12}>
                        <Form.Group className="form-group">
                            <Form.Label><span className="rStar"></span>{translator['Enabled']}</Form.Label>
                            <Field component={_dropDown} name="tagEnabled" options={[{ field1Key: 'True', field1Value: true }, { field1Key: 'False', field1Value: false }]} className="form-control" />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={6} xs={12}>
                        <Form.Group className="form-group">
                            <Form.Label><span className="rStar"></span>{translator['Label']}</Form.Label>
                            <Field name="label" component={_inputField} maxLength="50" className="form-control" />
                        </Form.Group>
                    </Col>
                    {tagTypeParam && tagTypeParam == 'Member' ?
                        <Col md={6} sm={6} xs={12}>
                            <Form.Group className="form-group">
                                <Form.Label>{translator['Dependent on']}</Form.Label>
                                <Field component={TagsDropdownPlusTypeaheadWithInfo}
                                    errorColor=''
                                    onSelection={onParentSelection}
                                    name="parentTag"
                                    id="parentTag"
                                    className="form-control"
                                    options={parentTagTypeaheadOptions}
                                    selectedOptions={parentSelectedList}
                                    onInputChange={onParentInputChange}
                                    inputValue={inputValueParent}
                                    onCrossClick={onCrossClickParent}
                                />
                            </Form.Group>
                        </Col>
                        : ''}
                    {/* <Col md={6} sm={6} xs={12}>
                        <Form.Group className="form-group">
                            <Form.Label><span className="rStar"></span>{translator['Data Source']}</Form.Label>
                            <Field component={_dropDown} name="tagDataSource" options={foundation_tags_reducer.tagDataSource} className="form-control" />
                        </Form.Group>
                    </Col> */}
                    {(props.initialValues?.tagType == 'Simple' || props.initialValues?.tagType == 'Composite') && (props.initialValues?.parentTag && props.initialValues?.parentTag != '0') ?
                        <>
                            <Col md={6} sm={6} xs={12}>
                                <Form.Group className="form-group">
                                    <Form.Label><span className="rStar"></span>{translator['Sequence']}</Form.Label>
                                    <Field component={_number} name="sequence" className="form-control" min={1} max={500} />
                                </Form.Group>
                            </Col>
                            <Col md={6} sm={6} xs={12}>
                                <Form.Group className="form-group">
                                    <Form.Label><span className="rStar"></span>{translator['Mandatory']}</Form.Label>
                                    <Field component={_dropDown} name="tagMandatory" options={[{ field1Key: 'Yes', field1Value: true }, { field1Key: 'No', field1Value: false }]} className="form-control" />
                                </Form.Group>
                            </Col>
                        </>
                        : ''}
                    {tagTypeParam != 'Composite' && tagTypeParam != 'Checklist' ?
                        <Col md={6} sm={6} xs={12}>
                            <Form.Group className="form-group">
                                <Form.Label><span className="rStar"></span>{translator['Data validation']}</Form.Label>
                                <Field component={_dropDown} name="tagDataValidation" onChange={(e) => { dispatch(change('foundationTagsEditForm', 'character_limit', '')); }} options={tagDataValidationValues} className="form-control" />
                            </Form.Group>
                        </Col>
                        : ''}
                    {tagDataValidationParam && tagDataValidationParam == 'Text' ?
                        <>
                            <Col md={6} sm={6} xs={12}>
                                <Form.Group className="form-group">
                                    <Form.Label><span className="rStar"></span>{translator['Character limit']}</Form.Label>
                                    <Field component={_number} name="character_limit" className="form-control" min={1} max={500} />
                                </Form.Group>
                            </Col>
                        </>
                        : ''}
                </Row>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    initialValues: state.foundation_tags_reducer.tagDetailsByTagId
});

export default connect(mapStateToProps, null)(
    reduxForm({
        form: 'foundationTagsEditForm',
        destroyOnUnmount: true,
        validate,
        enableReinitialize: false
    })(FormComponent)
);

// export default reduxForm({
//     form: 'foundationTagsEditForm',
//     destroyOnUnmount: true,
//     validate,
//     enableReinitialize: true
// })(FormComponent);
