
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/

import _ from "lodash";
import Cookies from "universal-cookie";

const cookies = new Cookies();
let userInfo = cookies.get('gph');

if (userInfo) userInfo = userInfo.replace('s:', '');
if (userInfo) userInfo = userInfo.substring(0, userInfo.lastIndexOf('.'));
userInfo = userInfo.split("~");


export const urgencyImpactReducer = (state = [], action) => {
    if (action.type === 'URGENCY_IMPACT_FETCHED_SUCCESSFULLY') {
      return action.payload;
    } else {
      return state;
    }
};

export const systemCofigList = (state = [], action) => {
  if (action.type === 'SYSTEM_CONFIG_FETCHED_SUCCESSFULLY') {
    return action.payload;
  } else {
    return state;
  }
};

export const savedSystemCofigList = (state = [], action) => {
  if (action.type === 'SYSTEM_CONFIG_SAVED_SUCCESSFULLY') {
    return action.payload;
  } else {
    return state;
  }
};

export const editSystemConfigList = (state = [], action) => {
  if (action.type === 'SYSTEM_CONFIG_EDITED_SUCCESSFULLY') {
    return action.payload;
  } else {
    return state;
  }
};

export const deleteSystemConfigList = (state = [], action) => {
  if (action.type === 'SYSTEM_CONFIG_DELETED_SUCCESSFULLY') {
    return action.payload;
  } else {
    return state;
  }
};
