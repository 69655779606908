
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
// import Header from "../../../common/header/Header";
import LeftSidebarMenulist from "../../common/left-sidebar-breakFixCreate";
import XsmSlaSupportHeader from "./header";
import XsmSLAWorkdayCreateForm from "./form";
import XsmProcessHierarchy from "./processHierarchy";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../common/Breadcrumbs";
import WorkScheduleRightSide from "./workScheduleCreateRightSide";
import "_Css/form/_form.scss";

const SlaWorkDayScheduleCreateIndex = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [subMenu, setSubMenu] = useState("4");
  const [showHideAlfy, setShowHideAlfy] = useState(false);
  const [showHideRightNav, setShowHideRightNav] = useState(false);
  const [actionView, setActionView] = useState("workScheduleTime");
  const translator = useSelector((state) => state.spcmReducer.tr);

  const showHideAlfySet = (value) => {
    this.setState({ showHideAlfy: value });
  };
  const showHideRightNavSet = (value) => {
    this.setState({ showHideRightNav: !this.state.showHideRightNav });
  };
  const changeActionView = (actionSelected) => {
    setActionView(actionSelected);
  };

  const showRightPaneAction = (actionView) => {
    switch (actionView) {
      case "assetgraph":
        return (
          <XsmProcessHierarchy translator={translator} ciId={params.ciId} />
        );
      case "workScheduleTime":
        return (
          <WorkScheduleRightSide translator={translator} changeActionView={changeActionView} />
         )
    }
  };

  return (
    <main>
      <div >
        <div className="container-fluid padding-t-10">
          <Breadcrumbs translator={translator} />
          <Form>
            <XsmSlaSupportHeader
              changeActionView={changeActionView}
              translator={translator}
            />

            <Row className="row-eq-height">
              <Col md={8} sm={12} xs={12}>
                <XsmSLAWorkdayCreateForm translator={translator} />
              </Col>
              <Col md={4} sm={12} xs={12} className="colRightHeight">
                <div className="stickyArea">
                  {showRightPaneAction(actionView)}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </main>
  );
};

export default SlaWorkDayScheduleCreateIndex;
