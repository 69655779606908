
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const BulletinList = (state = [], action) => {
    if (action.type === 'BULLETIN_LIST_FETCHED_SUCCESSFULLY') {
      return action.payload;
    } else {
      return state;
    }
};

export const saveBulletin = (state = [], action) => {
    if (action.type === 'BULLETIN_SAVED_SUCCESSFULLY') {
      return action.payload;
    } else {  
      return state;
    }
};

export const BulletinEditData = (state = [], action) => {
    if (action.type === 'BULLETIN_EDIT_DATA_FETCHED_SUCCESSFULLY') {
      return action.payload;
    } else {
      return state;
    }
};