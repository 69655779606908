
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import { Workbox } from 'workbox-window';

export default function registerServiceWorker() {

  console.log("enter within registerServiceWorker file");

  if ( 'production' !== process.env.NODE_ENV ) {
    return;
  }

  if ('serviceWorker' in navigator) {

    const wb = new Workbox('sw.js');
    // wb.addEventListener('installed', event => {
    //   if (event.isUpdate) {
    //     if (confirm(`An update is available!. Click OK to refresh`)) {
    //       window.location.reload();
    //     }
    //   }
    // })

    wb.addEventListener('message', (event) => {
      if (event.data && event.data.type === 'SKIP_WAITING') {
        skipWaiting();
      }
    })

    wb.addEventListener('updatefound', (event) => {
      if (event.data && event.data.type === 'SKIP_WAITING') {
        skipWaiting();
      }
    })

    wb.addEventListener("controllerchange", (event) => {
      window.location.reload();
    });

    // wb.addEventListener('message', async (event) => {
    //     if (event.data.meta === 'workbox-broadcast-update') {
    //       const {cacheName, updatedUrl} = event.data.payload;
    //       const cache = await caches.open(cacheName);
    //       const updatedResponse = await cache.match(updatedUrl);
    //       // const updatedText = await updatedResponse.text();
          
    //       console.log('Updated: ', cacheName, updatedUrl);
    //       console.log('Updated Text: ', updatedResponse);
  
    //       if (confirm(`App update is available! Click OK to refresh!`)) {
    //         window.location.reload();
    //       }
    //     }
    //   })

    // const waitForUpdate = new Promise((resolve) => {
    //   wb.addEventListener('message', async (event) => {
    //     if (event.data.meta === 'workbox-broadcast-update') {
    //       const {cacheName, updatedURL} = event.data.payload;
    //       const cache = await caches.open(cacheName);
    //       const updatedResponse = await cache.match(updatedURL);
    //       const updatedText = await updatedResponse.text();
    //       console.log(`Updated ${updatedURL} in ${cacheName}: ${updatedText}`);
  
    //       // Calling resolve() will trigger the promise's then() once.
    //       resolve();
    //     }
    //   });
    // });

    // waitForUpdate.then(() => {
    //   if (confirm('Content updated. Please refresh the page.')) {
    //     window.location.reload();
    //   }
    // });


    wb.register();
  } else {
    console.log('Service Worker is not supported by browser.');
  }


  // window.addEventListener('load', () => {
  //   let deferredPrompt;
  //   const addBtn = document.querySelector('.pwaInstallApp');
  //   //addBtn.style.display = 'none';
  //   window.addEventListener('beforeinstallprompt', (e) => {
  //     e.preventDefault();
  //     deferredPrompt = e;
  //     addBtn.style.display = 'block';
  //     addBtn.addEventListener('click', () => {
  //       addBtn.style.display = 'none';
  //       deferredPrompt.prompt();
  //       deferredPrompt.userChoice.then((choiceResult) => {
  //         if (choiceResult.outcome === 'accepted') {
  //           console.log('User accepted the A2HS prompt');
  //         } else {
  //           console.log('User dismissed the A2HS prompt');
  //         }
  //         deferredPrompt = null;
  //       });
  //     });
  //   });
  // })

}
