
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import { useSelector } from "react-redux";

import PindCategories from "../../../common/header/pind-categories";
import Breadcrumbs from "../../../common/header/breadcrumbs";
import Header from "./scheduleCreateHeader";
import FormComponent from "./scheduleCreateForm";
import "_Css/form/_form.scss";

const Index = () => {
  const translator = useSelector((state) => state.spcmReducer.tr);
  const [scheduleNameErrorState, setScheduleNameErrorState] = useState('');
  const [scheduleTypeErrorState, setScheduleTypeErrorState] = useState('');
  const [timeZoneErrorState, setTimeZoneErrorState] = useState('');

  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Breadcrumbs activePageName="Create" parentPageurl='/onCallSchedule/schedule' parentPageName="On Call Schedule" />
      </Container>

      <Container fluid>
        <Header setScheduleNameErrorState={setScheduleNameErrorState} setScheduleTypeErrorState={setScheduleTypeErrorState} setTimeZoneErrorState={setTimeZoneErrorState} />
        <Row>
          <Col md={8} xs={12}>
            <FormComponent scheduleNameErrorState={scheduleNameErrorState} scheduleTypeErrorState={scheduleTypeErrorState} timeZoneErrorState={timeZoneErrorState} setScheduleNameErrorState={setScheduleNameErrorState} setScheduleTypeErrorState={setScheduleTypeErrorState} setTimeZoneErrorState={setTimeZoneErrorState} />
          </Col>
        </Row>
      </Container>
    </main>
  )
}

export default Index;
