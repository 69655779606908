
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import PindCategories from "../../common/header/pind-categories";
import Breadcrumbs from "../../common/header/breadcrumbs";
import Header from "./header";
import FormComponent from "./form";
import HelpComponent from "./helpTxt";
import { useEffect, useState } from "react";
import axios from "axios";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import "_Css/form/_form.scss";

const defaultColors = {
  pageBG: "#fcfdff",
  pageTxtColor: "#000000",
  primaryColor: "#00838f",
  secondaryColor: "#f78623",
  anchorColor: "#0a89a7",
  aiButtonBG: "#f78623",
  headerBG: "#717272",
  headerTxtColor: "#d0d0d0",
  tableHeader: "#eaeaea",
  tableColumn: "#ffffff",
  FontFamily:"Open Sans",
};

const Index = () => {
  const tr = useSelector((state) => state.spcmReducer.tr);
  const [themeName, setThemeName] = useState("");
  const [preDefinedThemes, setPredefinedThemes] = useState([]);
  const [defaultColors,setDefaultColor] = useState(defaultColors);
  const [themeClicked,setThemeClicked] = useState(false);
  const [panelResize,setPanelResize] = useState();  
  const windowSize = useSelector((state) => state.windowSize.width);
  const [isRightSideVisible, setRightSideVisible] = useState(true);
  useEffect(() => {
    let paramData = JSON.stringify({
      moduleName: "themes",
      menuName: "themes",
    });
    axios
      .get("/api/getMenuListDataCommon", { headers: { query: paramData } })
      .then((res) => {
        setPredefinedThemes(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const submitValues = (values, field) => {
    const root = document.documentElement;
    root?.style.setProperty("--" + field, values);
  };

  const onColorFieldChange = (color, name) => {
    if(themeName !=="custom"){
      setThemeName("custom");
    }
    submitValues(color, name);
  };
  const showRightSideFun = (val) => {
    setRightSideVisible(val);
  };

  useEffect(() => {
    if( windowSize < 767 ) {
        setRightSideVisible(false);
      }
    else {
      setRightSideVisible(true);
    }
  }, [windowSize]);

  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Breadcrumbs activePageName="Themes" />
      </Container>

      <Container fluid>
        <Header
          themeName={themeName}
          setThemeName={setThemeName}
          preDefinedThemes={preDefinedThemes}
          defaultColors={defaultColors}
          setDefaultColor={setDefaultColor}
          onColorFieldChange={onColorFieldChange}
          themeClicked={themeClicked}
          setThemeClicked={setThemeClicked}
          submitValues={submitValues}
          isRightSideVisible={isRightSideVisible}
          showRightSideFun={showRightSideFun}
        />
        <PanelGroup
          direction="horizontal"
          className="formGroupB10 myProfileLabel myOrders"
        >
          <Panel
            id="sidebar"
            minSize={33}
            order={1}
            onResize={(size) => setPanelResize(size)}
            defaultSize={isRightSideVisible ? 67 : 100} 
            className={isRightSideVisible ? "isShowLeftPanel rwLeftPart" : "rwLeftPart"}
          >
            <FormComponent
              onColorFieldChange={onColorFieldChange}
              setPredefinedThemes={setPredefinedThemes}
              preDefinedThemes={preDefinedThemes}
              themeName={themeName}
              setThemeName={setThemeName}
              setThemeClicked={setThemeClicked}
              defaultColors={defaultColors}
              setDefaultColor={setDefaultColor}
              submitValues={submitValues}
              panelResize={panelResize}
            />
          </Panel>
          {isRightSideVisible ?
          <>
          <PanelResizeHandle className="isShowLeftPanel resizeHandle">
            <div className="outlne">
              <div className="handIcn">
                <i className="fa fa-chevron-left" aria-hidden="true"></i>
              </div>
            </div>
          </PanelResizeHandle>
          <Panel minSize={33} order={2} defaultSize={33}>
            <HelpComponent showRightSideFun={showRightSideFun} />
          </Panel>
          </> : null
          }
        </PanelGroup>
      </Container>
    </main>
  );
};

export default Index;
