
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Row, Col } from "react-bootstrap";
import { navigationHooks } from "../../../helpers/NavigationHook";
import Cookies from "universal-cookie";
const cookies = new Cookies();
let cookieString = cookies.get("gph");
if (cookieString) cookieString = cookieString.replace("s:", "");
if (cookieString)
  cookieString = cookieString.substring(0, cookieString.lastIndexOf("."));
let cookieArray = cookieString.split("~");
let sla_work_holiday = cookieArray[36];
let homepagelocation = cookieArray[0];
if (homepagelocation != "/home") homepagelocation = "/" + homepagelocation;
const editUrls = ["editSlaDef", "editWrkSch", "editHolidaySchedule"];

const Breadcrumbs = (props) => {
  const navigate = (location) => {
    navigationHooks.navigate(location);
  };
  const paths = window.location.pathname.split("/");
  let selectedPathname;
  if (paths.length > 0 && typeof paths[1] !== "undefined") {
    selectedPathname = paths[1];
  }
  return (
    <>
      <Row className="margin-b-15">
        <Col lg={8} md={8} sm={9} xs={10}>
          <ul className="breadcrumb margin-b-0">
            <li className="breadcrumb-item">
              <a role="button" onClick={() => navigate(homepagelocation)}>
                {props.translator["Home"]}
              </a>
            </li>
            {selectedPathname == "createSlaDef" ||
            selectedPathname == "editSlaDef" ? (
              <li className="breadcrumb-item">
                <a role="button" onClick={() => navigate("/slaDeflist")}>
                  {props.translator["SLA Board"]}
                </a>
              </li>
            ) : selectedPathname == "createWrkSch" ||
              selectedPathname == "editWrkSch" ? (
              <li className="breadcrumb-item">
                <a role="button" onClick={() => navigate("/slaWorkdaylist")}>
                  {props.translator["SLA Board"]}
                </a>
              </li>
            ) : selectedPathname == "createHolSch" ||
              selectedPathname == "editHolidaySchedule" ? (
              <li className="breadcrumb-item">
                <a role="button" onClick={() => navigate("/slaHolidaylist")}>
                  {props.translator["SLA Board"]}
                </a>
              </li>
            ) : null}
            {editUrls.includes(selectedPathname) ? (
              <li className="breadcrumb-item active">
                {props.translator["Edit"]}
              </li>
            ) : (
              <li className="breadcrumb-item active">
                {props.translator["Create"]}
              </li>
            )}
          </ul>
        </Col>
      </Row>
    </>
  );
};

export default Breadcrumbs;
