
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { reduxForm } from "redux-form";
import {
  createAdvertisement,
  deleteAdvertisementData,
  updateAdvertisement,
  uploadAdvertisementImage,
} from "../../../../../actions/foundation/general/AdvertisementAction";
import moment from "moment";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import axios from "axios";
import {ImSpinner6} from "react-icons/im";
import { IoSaveOutline, IoClose } from "react-icons/io5";
import {HiOutlineTrash} from "react-icons/hi";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';

const cookies = new Cookies();

let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

const AdvertisementFormHeader = (props) => {
  const navigate = useNavigate();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const showLoader = useSelector((state) => state.commonLoader.loading);
  const params = useParams();
  const dispatch = useDispatch();
  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const saveObjRef = useRef(null);

	useEffect(() => {
		context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
    context.newTabPrevent();
	}, []);

  const submitForm = (values) => {
    let formData;
    if (values.advertisementImage && values.advertisementImage.length > 0) {
      formData = new FormData();
      if (values.advertisementImage) {
        formData.append("file", values.advertisementImage[0]);
      } else {
        formData.append("file", "");
      }
      dispatch(
        uploadAdvertisementImage(
          values.advertisementId,
          values.company_id,
          formData
        )
      );
    }

    if (props.formType === "Create") {
      axios
        .get(
          `/api/getAdvertisementCount?companyID=${values.company_id}&advertSequence=${parseInt(values.sequence, 10)}`
        )
        .then((response) => {
          let advertSequence = response.data.advertSequence;
          let advertisementCount = response.data.advertisementCount;

          if (advertSequence > 0) {
            Swal.fire("Advertisement with same sequence already exists.");
            return;
          }
          if (advertisementCount === 10) {
            Swal.fire("More than 10 Advertisements cannot be created.");
            return;
          }

          let payload = {
            companyId: values.company_id.toString(),
            companyIdforedit: values.company_id.toString(),
            // fromDate: values.fromDate,
            fromDate: moment(values.fromDate, jwtdf + " hh:mm A").format(
              jwtdf + " HH:mm:ss"
            ),
            toDate: moment(values.toDate, jwtdf + " hh:mm A").format(
              jwtdf + " HH:mm:ss"
            ),
            status: values.status,
            adverturl: "",
            advertisementIconTempURL: null,
            advertisementURL: values.advertisementURL,
            advertSequence: parseInt(values.sequence, 10),
            filterby: null,
            filterbytext: null,
            pagesize: 0,
            eventNameDefault: null,
          };
          dispatch(createAdvertisement(payload));
          props.setformTypeState('Edit');
          props.setInitialValuesSet(false);
        });
    } else {
      axios
        .get(
          `/api/getAdvertisementCount?companyID=${values.company_id.toString()}&advertSequence=${parseInt(
            values.sequence,
            10
          )}&id=${values.advertisementId}`
        )
        .then((response) => {
          let advertSequence = response.data.advertSequence;
          let advertisementCount = response.data.advertisementCount;

          if (advertSequence > 0) {
            Swal.fire("Advertisement with same sequence already exists.");
            return;
          }

          console.log("values --- ", values, props.formType);

          let payload = {
            advertisementId: values.advertisementId,
            companyId: values.company_id.toString(),
            companyIdforedit: values.company_id.toString(),
            fromDate: moment(values.fromDate, jwtdf + "hh:mm A").format(
              jwtdf + " HH:mm:ss"
            ),
            toDate: moment(values.toDate, jwtdf + "hh:mm A").format(
              jwtdf + " HH:mm:ss"
            ),
            status: values.status,
            adverturl: "",
            advertisementIconTempURL: null,
            advertisementURL: values.advertisementURL,
            advertSequence: parseInt(values.sequence, 10),
            filterby: null,
            filterbytext: null,
            pagesize: 0,
            eventNameDefault: null,
          };

          console.log("Payload --- ", payload);
          dispatch(updateAdvertisement(payload));
        });
    }
  };

  let id = props.advertisementId;

  const onDeleteClick = () => {
    console.log("CheckId", id)
      Swal.fire({
        title: tr["Are you sure you want to delete?"],
        width: 550,
        padding: '15px',
        showDenyButton: true,
        confirmButtonText: tr['Yes'],
        denyButtonText: tr['No'],
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteAdvertisementData(id))
        } else if (result.isDenied) {
          
        }
      })
    };
  let { handleSubmit, submitting } = props;
  console.log("DeleteAdvt", props.formType)
  return (
    <Row className="margin-b-15">
      <Col lg={4} md={6} sm={6} xs={12}>
        <h1 bsClass="" className="sPageHeading1">
          {props.name}
        </h1>
      </Col>
      <Col lg={8} md={6} sm={6} xs={12}>
        <div className="paHedFilter">
          {props.formType === 'Edit' ?(<Button role="button" bsPrefix=' ' className="myBt rclose" title={tr["Delete"]} onClick={onDeleteClick}>
             <HiOutlineTrash/>
          </Button>):''}
          <Link
            bsPrefix=" "
            id="hdID"
            to="javascript:void(0)"
            className="myBt plus ctrlKeyPrevent"
            disabled={showLoader}
            onClick={handleSubmit(submitForm)}
            title={tr["Save"]}
            ref={saveObjRef}
          >
            <span>{showLoader ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/> }</span>
          </Link>
          <Link
            bsPrefix=" "
            to="/advertisement"
            className="myBt cancel fillBtn ctrlKeyPrevent"
            title={tr["Cancel"]}
            ref={closeButton}
          >
            <IoClose/>
          </Link>
        </div>
      </Col>
    </Row>
  );
};

export default reduxForm({
  form: "advertisementForm",
  enableReinitialize: false,
  destroyOnUnmount: true,
})(AdvertisementFormHeader);
