
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
// let GLOBAL = require('../../components/Globals');
import { GLOBAL } from "_Globals";

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

export function loadStatusBasedHamburgerOptions(module,status,itemId){
    console.log("module,status,itemId",module,status,itemId)
    let filter;
    if(module!="Breakfix")
       if(itemId)
         filter='?module='+module+'&currentStatus='+status+'&itemId='+itemId;
       else
         filter='?module='+module+'&currentStatus='+status;
    else
       if(itemId)
         if(status=="Fixed")
           filter='?module='+module+'&currentStatus='+status+'&itemId='+itemId;
         else
           filter='?module='+module+'&currentStatus='+status+'&itemId='+itemId;
       else
        if(status=="Fixed")
           filter='?module='+module+'&currentStatus='+status;
        else
           filter='?module='+module+'&currentStatus='+status;
    return function(dispatch){
        dispatch({ type: "LOADER_IN_PROGRESS_3" });
        return api.get(GLOBAL.statusBasedHamburgerOptionsURL+filter)
        .then(response => {
            console.log('loadStatusBasedHamburgerOptions rsponse+++++++++');
            console.log(response);
            if(response.status == 200){
                dispatch(
                    {type: 'STATUS_BASED_HAMBURGER_OPTIONS', payload: response.data}
                );
            }
            dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_3' });
        })
        .catch((err) => {
            dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_3' });
            console.log(err);
        });
    }
}

export function loadHamburger(){
    return function(dispatch){
        dispatch({ type: "LOADER_IN_PROGRESS_3" });
    }
}

export function stopLoadHamburger(){
    return function(dispatch){
        dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY_3" });
    }
}

export function resetLoadHamburgerOptionsReducer(){
    return function(dispatch){
        dispatch({type:'LOAD_HAMBURGER_OPTIONS',payload:false});
    }
}