
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Row, Col, Button, Form, Table,ListGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import { loadFulfillmentDetails } from '../../../actions/fulfillment/fulfillmentDataAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { GLOBAL } from "_Globals";
import { loadBreakFixEditDetails, loadBreakFixEditDetailsUpdate } from '../../../actions/breakFix/breakFixEditDetailsAction';
import { loadBreakFixSG } from '../../../actions/breakFix/serviceGroupAction';
import { loadBreakFixSGI } from '../../../actions/breakFix/serviceGroupIAction';
import { loadBreakFixTaskLogDetails, loadTaskGroup, loadTaskGroup2, loadAsignedTo, checkRelatedTaskForItem, getTasktype } from '../../../actions/task/TaskDataAction';
import { loadBreakFixSGAETask } from '../../../actions/breakFix/serviceGroupAEAction';
import { getInvestigationItemDetails, getInvestigationItemDetailsUpdate } from '../../../actions/investigation/investigationAction.js';
import { loadChangeEditData } from '../../../actions/changeManagement/changeManagementAction.js';
import ListLoader from '../loaders/ListLoader';
import moment from 'moment';
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';
import {ImInfo, ImSpinner6} from "react-icons/im";
import {IoClose} from "react-icons/io5";
import TaskDetailsPopup from '_Commons/WorkItemBoard/taskDetailsPopup.js';
import { TypeaheadExampleSingleSelect } from '../../../components/common/formFields';
const cookies = new Cookies();
let languageSelected = cookies.get("gph");
languageSelected = languageSelected.split("~");
languageSelected = languageSelected[20];

let dateformat = cookies.get('gph');
if (dateformat) dateformat = dateformat.replace('s:', '');
if (dateformat) dateformat = dateformat.substring(0, dateformat.lastIndexOf('.'));
dateformat = dateformat.split("~");
dateformat = dateformat[2];

let offset = cookies.get('gph');
if (offset) offset = offset.replace('s:', '');
if (offset) offset = offset.substring(0, offset.lastIndexOf('.'));
offset = offset.split("~");
offset = offset[34];

let cookiesArray = cookies.get('gph');
if (cookiesArray) cookiesArray = cookiesArray.replace('s:', '');
if (cookiesArray) cookiesArray = cookiesArray.substring(0, cookiesArray.lastIndexOf('.'));
cookiesArray = cookiesArray.split("~");
let langDir = cookiesArray[66];

const MoreArrow = <i title="More" className="fa fa-angle-double-down f-size-15" ></i>;
class PopoverTasksData extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showUserData: false,
			taskName: "",
			showLoader: false,
			assignedToUser: '',
			isExternal: '',
			additionalcharleft: 500,
			additionalSummaaryCharLeft: 4000,
			hideTaskForm: false,
			oldUrgencyList: [],
			taskType: '',
			selectedGroup: [],
			groupList: [],
			typedGroupName: '',
			maxSequenceNumber: 100,
			minSequenceNumber: 1,
		};

		GLOBAL.taskdescriptionval = "",
			GLOBAL.tasksequenceval = "0",
			GLOBAL.tasksequencetypeval = "",
			GLOBAL.taskstatusval = "0",
			GLOBAL.taskassignementuserval = "0",
			GLOBAL.taskinfoval = "",
			GLOBAL.taskTypeId = "",

		this.getData = this.getData.bind(this);
		this.onGroupNameChange = this.onGroupNameChange.bind(this);
		this.onGroupSelected = this.onGroupSelected.bind(this);
		this.setErrorColor = this.setErrorColor.bind(this);  
		this.onCrossClick = this.onCrossClick.bind(this);
		this.resetAssignedToDetails = this.resetAssignedToDetails.bind(this);
	}

	componentWillMount() {
		console.log("modulq211", this.props.breakFixItemId);
		console.log("itemId", this.props.itemId);
		let getBreanFixkItemId = (this.props.breakFixItemId) ? (this.props.breakFixItemId).split(":") : ""
		let breakFixActualItemId = this.props.breakFixItemId || ""; //getBreanFixkItemId[1];
		if (this.props.module == "Breakfix") {
			//	console.log("serviceid:::"+this.props.breakFixEditDetailsUpdate.serviceId)
			//this.props.loadBreakFixEditDetailsUpdate(breakFixActualItemId);
			// this.props.loadBreakFixSG(this.props.breakFixEditDetailsUpdate.serviceId);
			this.props.loadBreakFixTaskLogDetails(breakFixActualItemId, "Breakfix", "asc", this.props.breakFixEditDetailsUpdate.consumerCompany);
			//this.props.loadBreakFixSGAETask(this.props.breakFixEditDetails.serviceId,this.props.breakFixEditDetails.consumerCompany,"Task");
			this.props.loadTaskGroup2(this.props.breakFixEditDetailsUpdate.consumerCompany);
			// this.props.getTasktype('ADHOC_TASK', "Incident");
			if (this.props.breakFixEditDetailsUpdate.serviceBased == true) {
				axios.get('/api/company_menulist?companyId='+(this.props.breakFixEditDetailsUpdate.serviceCompanyId)+'&module=Incident&field=Task Type&language='+languageSelected+'&status=1').then((responseDropdownValues)=>{
					const oldUrgencyList1 = []
					responseDropdownValues.data.map((item)=>{
							oldUrgencyList1.push(item);
							this.setState({oldUrgencyList: oldUrgencyList1})
					});
					}); 
			} else {
				axios.get('/api/company_menulist?companyId='+(this.props.breakFixEditDetailsUpdate.consumerCompany)+'&module=Incident&field=Task Type&language='+languageSelected+'&status=1').then((responseDropdownValues)=>{
					const oldUrgencyList1 = []
					responseDropdownValues.data.map((item)=>{
							oldUrgencyList1.push(item);
							this.setState({oldUrgencyList: oldUrgencyList1})
					});
					}); 
			}
			

		}
		if (this.props.module == "Fullfilment") {
			this.props.loadTaskGroup2(this.props.fulfillmentDetails.companyId);
			// this.props.getTasktype('ADHOC_TASK', "Fulfillment")

			// this.props.loadFulfillmentDetails(this.props.itemId);
			//alert(this.props.fulfillmentDetails.offeringIdSx)
			// this.props.loadBreakFixSG(this.props.fulfillmentDetails.offeringIdSx);
			this.props.loadBreakFixTaskLogDetails(this.props.itemId, "Fullfilment", "asc", this.props.fulfillmentDetails.companyId);
			//this.props.loadBreakFixSGAETask(this.props.fulfillmentDetails.offeringIdSx,this.props.fulfillmentDetails.companyId,"TaskFullfilment");
			axios.get('/api/company_menulist?companyId='+(this.props.fulfillmentDetails.serviceCompanyId)+'&module=Fulfillment&field=Task Type&language='+languageSelected+'&status=1').then((responseDropdownValues)=>{
				const oldUrgencyList1 = []
				responseDropdownValues.data.map((item)=>{
						oldUrgencyList1.push(item);
						this.setState({oldUrgencyList: oldUrgencyList1})
				});
				}); 
		}
		if (this.props.module == "ChangeManagement") {
			//this.props.getInvestigationItemDetails(this.props.itemId);
			this.props.loadTaskGroup2(this.props.editChangeInitialData.consumerCompany);
			// this.props.getTasktype('ADHOC_TASK', "Change")
			//this.props.loadChangeEditData(this.props.itemId);
			//this.props.loadBreakFixTaskLogDetails(this.props.itemId,"ChangeManagement","desc",this.props.investigationItemDetails.consumerCompany);
			//this.props.loadBreakFixSGAE(this.props.investigationItemDetails.serviceId,"Grenzen Inc","Task");
			//console.log("task:::"+this.props.editChangeInitialData.changeNumber)
			this.props.loadBreakFixTaskLogDetails(this.props.itemId, "ChangeManagement", "asc", this.props.editChangeInitialData.consumerCompany);
			//this.props.loadBreakFixSGAETask(this.props.editChangeInitialData.serviceId,this.props.editChangeInitialData.consumerCompany,"TaskChangeManagement");
			axios.get('/api/company_menulist?companyId='+(this.props.editChangeInitialData.consumerCompany)+'&module=Change&field=Task Type&language='+languageSelected+'&status=1').then((responseDropdownValues)=>{
				const oldUrgencyList1 = []
				console.log("Inside change")
				responseDropdownValues.data.map((item)=>{
						oldUrgencyList1.push(item);
						this.setState({oldUrgencyList: oldUrgencyList1})
				});
				}); 
		}

		if (this.props.module == "Investigation") {
			//this.props.getInvestigationItemDetails(this.props.itemId);
			//console.log("servid:::::"+this.props.investigationdetail.serviceId);
			this.props.loadTaskGroup2(this.props.investigationdetail.consumerCompany);
			let serviceId = this.props.investigationdetail.serviceId;
			let consumerCompany = this.props.investigationdetail.consumerCompany;

			// this.props.getTasktype('ADHOC_TASK', "Problem");

			//this.props.getInvestigationItemDetailsUpdate(this.props.itemId);
			//this.props.loadBreakFixSG(this.props.getInvestigationItemDetails.serviceId);
			//this.props.loadBreakFixTaskLogDetails(this.props.itemId,"Investigation","desc",this.props.getInvestigationItemDetails.consumerCompany);
			//this.props.loadBreakFixSGAETask(this.props.getInvestigationItemDetails.serviceId,this.props.getInvestigationItemDetails.consumerCompany,"TaskInvestigation");

			// this.props.loadBreakFixSG(serviceId);
			this.props.loadBreakFixTaskLogDetails(this.props.itemId, "Investigation", "asc", this.props.investigationdetail.consumerCompany);
			//this.props.loadBreakFixSGAETask(serviceId,consumerCompany,"TaskInvestigation");
			axios.get('/api/company_menulist?companyId='+(this.props.investigationdetail.consumerCompany)+'&module=Problem&field=Task Type&language='+languageSelected+'&status=1').then((responseDropdownValues)=>{
				const oldUrgencyList1 = []
				responseDropdownValues.data.map((item)=>{
						oldUrgencyList1.push(item);
						this.setState({oldUrgencyList: oldUrgencyList1})
				});
				}); 

		}
		// console.log('*******************', {props: this.props});
		if(typeof this.props.roleIdentification == 'function'){
			if( this.props.investigationdetail && Object.keys(this.props.investigationdetail).length > 0 && this.props.investigationdetail.status_id && (this.props.investigationdetail.status_id==='40' || this.props.investigationdetail.status_id==='45') && this.props.roleIdentification('53')){
			 this.setState({hideTaskForm: false});
			}else if(!(this.props.roleIdentification('17')||
		     this.props.roleIdentification('16')|| 
			 this.props.roleIdentification('19')||
		     this.props.roleIdentification('20')||
		     this.props.roleIdentification('51')||
		     this.props.roleIdentification('52'))){
             this.setState({hideTaskForm: this.props.roleIdentification('48')});
		    }
	    }
		// if(typeof this.props.roleIdentification!=='undefined'){
		// 	if(typeof this.props.investigationdetail.status_id!=='undefined' && (this.props.investigationdetail.status_id==='40' || this.props.investigationdetail.status_id==='45') && this.props.roleIdentification('53')){
		// 	 this.setState({hideTaskForm: false});
		// 	}else if(!(this.props.roleIdentification('17')||
		//      this.props.roleIdentification('16')|| 
		// 	 this.props.roleIdentification('19')||
		//      this.props.roleIdentification('20')||
		//      this.props.roleIdentification('51')||
		//      this.props.roleIdentification('52'))){
        //      this.setState({hideTaskForm: this.props.roleIdentification('48')});
		//     }
	    // }
	}
	componentWillReceiveProps(nextProps) {
		if(this.state.groupList.length == 0){
		   const extractedGroups = nextProps.taskGroupData2.map(groupData => {
			   return { id: groupData.groupId, label: groupData.groupName, supportCompanyName: groupData.companyName, isExternal: groupData.isExternal };
		   });
		   this.setState({groupList: extractedGroups});
		}
	}

	onEditTask(taskcode, regid) {
		//alert("clickchk"+taskcode)
		//console.log("taskcode::"+taskcode+"regid::"+regid)

		GLOBAL.taskcode = taskcode;
		GLOBAL.taskregid = regid
		this.props.clicktaskEdit("1", taskcode, regid);
	}

	renderSupportPIGroup(breakFixData) {
		//if(GLOBAL.breakfixongrpchangeid!="")
		//GLOBAL.breakfixgroupid=GLOBAL.breakfixongrpchangeid
		//	else
		//	GLOBAL.breakfixgroupid=this.props.breakFixEditDetails.spGroupid

		/* console.log("GLOBAL.breakfixgroupid::"+GLOBAL.breakfixgroupid); */
		if (!breakFixData || breakFixData.length == 0) {
			return (
				null
			);
		}
		return breakFixData.map((breakFixObj, index) => {
			/* if(breakFixObj.assignmentMemberId!=0) */
			return (
				<option value={breakFixObj.field1Value}>{breakFixObj.field1Key.length > 50 ? (breakFixObj.field1Key.slice(0,50) + "...") : breakFixObj.field1Key}</option>
			);
		});
	}
	renderTasktype(dropdownOptions) {
		if (!dropdownOptions) {
			return <option></option>;
		  }
		  return dropdownOptions.map((option) => {
			return <option value={option.field1Value}>{option.field1Key}</option>;
		  });
	}

	renderAttList(attributeData) {
		if (!attributeData) {
			return (
				null
			);
		}
		return attributeData.map((taskObj, index) => {
			const tooltip = (<Popover className=""><TaskDetailsPopup taskObj={taskObj} disableFieldsForGuestRole={this.props.disableFieldsForGuestRole}  /></Popover>);

			//let finalDate = moment.utc(taskObj.createdDate).utcOffset(offset / 60).format(dateformat.replace("dd", "DD"));
			let finalDate = taskObj.createdDate;
			// let finalDate = '';
			// let splitDate1 = taskObj.createdDate.split('T')[0];
			// let splitDate2 = taskObj.createdDate.split('T')[1];
			// let splitDate3 = splitDate2.split('.')[0];
			// finalDate = finalDate.concat(splitDate1).concat(' ').concat(splitDate3);

			return (
				<tr>
					<td><span className='nowrap'>{this.props.isTaskRoleEnabled?<Link to={"/editTask/" + taskObj.taskCode + "/" + taskObj.clientId + "/" + taskObj.assignedToGroupId}>{taskObj.taskCode}</Link>:taskObj.taskCode}<OverlayTrigger trigger="click" rootClose placement={this.props.windowSize < 768 ? "auto-start" : (langDir === "rtl" ? "right-start" : "left-start")
} overlay={tooltip}><span title='Info' className='cursorPoint'><ImInfo className='taskInfoicn'/></span></OverlayTrigger></span></td>
					{/* <td>{taskObj.sequenceNumber}</td> */}
					<td>{taskObj.status.status}</td>
					<td>{taskObj.assignedTo}</td>
					<td>{/*{finalDate}*/}{taskObj.expectedDueDate}</td>
				</tr>
			);
		});
	}

	renderTaskData(taskData) {
		console.log('taskData');
		console.log(taskData);
		console.log("taskData00", this.props.showLoaderRelatedTask);
		if (this.props.showLoaderRelatedTask) {
			return (<div>
				<ListLoader />
			</div>);
		}
		else if (taskData.length == 0) {
			return (
				<div>{this.props.translator['Data not available.']}</div>
			);
		}

		else {
			return (
				<Table responsive striped bordered condensed hover className='f-size-13'>
					<thead>
						<tr className='f-size-12'>
							<th>{this.props.translator['Task Code']}</th>
							{/* <th>{this.props.translator['Task Sequence']}</th> */}
							<th>{this.props.translator['Status']}</th>
							<th>{this.props.translator['Fulfiller']}</th>
							<th>{this.props.translator['Expected Completion']}</th>
						</tr>
					</thead>
					<tbody>
						{this.renderAttList(taskData)}
					</tbody>
				</Table>
			);
		}
	}

	// 			renderTaskData(taskData){
	// 				//alert(""+activityLogData.length)
	// // 				if(taskData.length==0){
	// // 		 		return (
	// // 		 				<tr>
	// // 		 					<td width="100%">
	// // 		 						<div>Data not available.</div>
	// // 		 					</td>
	// // 		 				</tr>
	// // 		 			);
	// // 		 		}
	// //
	// // else{

	//   return taskData.map((taskObj,index)=>{

	// 		  return(
	// 				<Media>
	// 				 <Media.Body>
	// 					<Media.Heading className="blue margin-0 cursorPoint" onClick={this.onEditTask.bind(this,taskObj.taskCode,taskObj.registrationId)}>{taskObj.createdDate} - {taskObj.taskCode} - <span className="cursorPoint">Sequence {taskObj.sequenceNumber}</span></Media.Heading>
	// 					<div className="black fw-300 cTxt"><ReadMore lines={1} onShowMore={this.props.onChange} text={MoreArrow}>{taskObj.description}</ReadMore></div>
	// 				  </Media.Body>
	// 				</Media>
	// 		        );
	// 		      });

	// 			 {/* }*/}
	// 			{/* }*/}
	// 		}

	getData(e) {
		//alert("id::"+e.target.id+"val::"+e.target.value)
		//alert("this.props.supportPGroup"+e.target[e.target.selectedIndex].text);
		//console.log("groupname::"+)
		if(e.target.id == "taskTypeId"){
		GLOBAL.taskTypeId = e.target.value
		this.setState({ taskType: e.target[e.target.selectedIndex].text });
	}
		if (e.target.id == "description"){
			GLOBAL.taskdescriptionval = e.target.value
		    this.setState({additionalSummaaryCharLeft: 4000-e.target.value.length});
		}
		if (e.target.id == "sequence")
			GLOBAL.tasksequenceval = e.target.value
		{/*if(e.target.id=="sequencetype")
				GLOBAL.tasksequencetypeval=e.target.value*/}
		if (e.target.id == "status")
			GLOBAL.taskstatusval = e.target.value
		if (e.target.id == "supportPIndividual") {
			GLOBAL.taskassignementuserval = e.target.value;
			this.setState({ assignedToUser: e.target[e.target.selectedIndex].text });
		}
		if (e.target.id == "information")
			GLOBAL.taskinfoval = e.target.value

	}
	descriptionCharacter(e) {
		let input = e.target.value.length;
		let additionalchar = 500 - input;
		this.setState({
			additionalcharleft: additionalchar
		})
		console.log("e.target.value", input);
	}

	postTaskData() {

		let ref = this;
		if (GLOBAL.tasksequenceval <= 0 || GLOBAL.tasksequenceval > this.state.maxSequenceNumber) {
			const messageToShow = `${this.props.translator['The sequence value must be positive integer and less than']} ${this.state.maxSequenceNumber}`;
			Swal.fire(messageToShow);
		}
		else if (this.state.selectedGroup.length === 0) {
			Swal.fire(this.props.translator['Please select Assignment Group']);
			
		}
		else if (GLOBAL.taskTypeId == 0) {
			Swal.fire(this.props.translator['Please select Task Type']);
		}

		else {

			this.setState({ showLoader: true });
			let module = this.props.module;
			//let itemId=this.props.itemId;
			let actualidtosave = "";
			let clientid = "";
			let companyName = "";
			let ref = this;
			let serviceid = "";
			let servicename = "";
			let parentWorkItemId = "";
			let ciId = "";
			let ciName = "";
			let serviceBased = "";
			let priorityId = "";
			let priorityValue = "";
			let classId = "";
			let className = "";

			//	console.log("grnamepost"+ref.state.groupname)

			if (module == "Fullfilment") {
				console.log("Fullfilment=====================");
				console.log(this.props.fulfillmentDetails);
				actualidtosave = this.props.fulfillmentDetails.itemId;
				clientid = this.props.fulfillmentDetails.companyId;
				companyName = this.props.fulfillmentDetails.consumerCompanyName;
				serviceid = this.props.fulfillmentDetails.offeringIdSx;
				servicename = this.props.fulfillmentDetails.offeringNameSx
				parentWorkItemId = this.props.fulfillmentDetails.itemNumber;
				serviceBased = true;

			}
			if (module == "ChangeManagement") {
				console.log("ChangeManagement=====================");
				console.log(this.props.editChangeInitialData);
				actualidtosave = this.props.editChangeInitialData.changeId;
				//clientid=this.props.investigationItemDetails.consumerCompany;
				clientid = this.props.editChangeInitialData.consumerCompany;
				companyName = this.props.editChangeInitialData.consumerCompanyName;
				serviceid = this.props.editChangeInitialData.serviceId;
				servicename = this.props.editChangeInitialData.serviceName;
				parentWorkItemId = this.props.editChangeInitialData.changeNumber;
				ciId = this.props.editChangeInitialData.ciId;
				ciName = this.props.editChangeInitialData.ciName;
				serviceBased = this.props.editChangeInitialData.serviceBased;
				classId = this.props.editChangeInitialData.ciClassId;
				className = this.props.editChangeInitialData.ciClassName;
			}

			if (module == "Investigation") {
				console.log("Investigation=====================");
				console.log(this.props.investigationdetail);
				let serviceId = this.props.investigationdetail.serviceId;
				let consumerCompany = this.props.investigationdetail.consumerCompany;
				actualidtosave = this.props.investigationdetail.investigationId;

				/*clientid=this.props.getInvestigationItemDetails.consumerCompany;
				serviceid=this.props.getInvestigationItemDetails.serviceId;
			 servicename=this.props.getInvestigationItemDetails.serviceName*/
				clientid = consumerCompany;
				companyName = this.props.investigationdetail.consumerCompanyName;
				serviceid = serviceId;
				servicename = this.props.investigationdetail.serviceName;
				parentWorkItemId = this.props.investigationdetail.investigationNumber;
				ciId = this.props.investigationdetail.ciId;
				ciName = this.props.investigationdetail.ciName;
				serviceBased = this.props.investigationdetail.serviceBased;
				priorityId= this.props.investigationdetail.priority;
				priorityValue= this.props.investigationdetail.priorityValue;
				classId = this.props.investigationdetail.ciClassId;
				className = this.props.investigationdetail.ciClassName;
			}
			if (module == "Breakfix") {
				console.log("Incident=====================",this.props.breakFixEditDetails);
				//let getBreakFixItemId = (this.props.breakFixItemId) ? (this.props.breakFixItemId).split(":") : "";
				actualidtosave = this.props.breakFixEditDetails.breakfixId;
				clientid = this.props.breakFixEditDetails.consumerCompany;
				companyName = this.props.breakFixEditDetails.consumerCompanyName;
				serviceid = this.props.breakFixEditDetails.serviceId;
				servicename = this.props.breakFixEditDetails.serviceName;
				parentWorkItemId = this.props.breakFixEditDetails.breakfixNumber;
				ciId = this.props.breakFixEditDetails.ciId;
				ciName = this.props.breakFixEditDetails.ciName;
				serviceBased = this.props.breakFixEditDetails.serviceBased;
				priorityId= this.props.breakFixEditDetails.priorityId;
				priorityValue= this.props.breakFixEditDetails.priorityValue;
				classId = this.props.breakFixEditDetails.ciClassId;
				className = this.props.breakFixEditDetails.ciClassName;
			}

			/* if (GLOBAL.taskinfoval.trim().length == 0) {
				alert('Enter additional information.');
				this.setState({ showLoader: false });
				return false;
			} */
			if (GLOBAL.taskdescriptionval.trim().length == 0) {
				Swal.fire(this.props.translator['Please enter Summary']);
				this.setState({ showLoader: false });
				return false;
			}

			let postData = {
				"assignedToId": GLOBAL.taskassignementuserval,
				"assignedToGroupId": ref.state.selectedGroup?.[0].id,
				"description": GLOBAL.taskdescriptionval,
				"sequenceNumber": GLOBAL.tasksequenceval,
				"workItemId": actualidtosave,
				"parentWorkItemId": parentWorkItemId,
				"additionalParams": GLOBAL.taskinfoval,
				//"sequenceType":GLOBAL.tasksequencetypeval,

				/* "statusId":GLOBAL.taskstatusval, */
				"statusId": '1',//by default task will in assinged status .ie. status 1,
				"statusName": "Assigned",
				"workItemType": module,
				"applicationName": "XSM",
				"moduleName": module,
				"clientId": clientid,
				"assignedTo": ref.state.assignedToUser,
				"taskTypeId": GLOBAL.taskTypeId,
				"taskType": ref.state.taskType,
				"assignedToGroup": ref.state.selectedGroup?.[0].label,
				"requesterId": "",
				"requesterName": "",
				"serviceId": serviceid,
				"serviceName": servicename,
				"createdBy": "",
				"companyName": companyName,
				"ciId": ciId,
				"ciName": ciName,
				"serviceBased": serviceBased,
				"isExternalFulfilled": this.state.isExternal,
				"supportCompanyId": this.state.supportCompanyID,
				"supportCompanyName": this.state.supportCompanyName,
				"priorityValue": (module== "Breakfix" || module == "Investigation") ? priorityValue : null,
				"priority": (module== "Breakfix" || module == "Investigation") ? priorityId : null,
				"className": (module== "Breakfix" || module == "Investigation" || module == "ChangeManagement") ? className : null,
				"classId": (module== "Breakfix" || module == "Investigation" || module == "ChangeManagement") ? classId : null

			};
			console.log("workItemType==>",postData);
			console.log("breakFixEditDetails79",this.props.breakFixEditDetails);
			console.log("investigationdetail79",this.props.investigationdetail);
			console.log("fulfillmentDetails79",this.props.fulfillmentDetails);
			console.log("editChangeInitialData79",this.props.editChangeInitialData);

			if (module == "Fullfilment") {
				let jwtdf = dateformat.slice(0, 10).toUpperCase();
				let jwtdf1 = jwtdf + ' HH:mm:ss';
				if (this.props.fulfillmentDetails.expectedStartDate && this.props.fulfillmentDetails.expectedStartDate != '') {
					let expectedStartDate = moment(this.props.fulfillmentDetails.expectedStartDate, jwtdf1);
					console.log("expectedStartDate",expectedStartDate);
					postData.expectedStartDate = expectedStartDate;
				}
				if (this.props.fulfillmentDetails.expectedEndDate && this.props.fulfillmentDetails.expectedEndDate != '') {
					let expectedEndDate = moment(this.props.fulfillmentDetails.expectedEndDate, jwtdf1);
					console.log("expectedEndDate",expectedEndDate);
					postData.expectedDueDate = expectedEndDate;
				}
			}

			if (module == "Breakfix") {
				let jwtdf = dateformat.slice(0, 10).toUpperCase();
				let jwtdf1 = jwtdf + ' HH:mm:ss';
				if (this.props.breakFixEditDetails.expectedStartDate && this.props.breakFixEditDetails.expectedStartDate != '') {
					let expectedStartDate = moment(this.props.breakFixEditDetails.expectedStartDate, jwtdf1);
					console.log("expectedStartDate",expectedStartDate);
					postData.expectedStartDate = expectedStartDate;
				}
				if (this.props.breakFixEditDetails.expectedEndDate && this.props.breakFixEditDetails.expectedEndDate != '') {
					let expectedEndDate = moment(this.props.breakFixEditDetails.expectedEndDate, jwtdf1);
					console.log("expectedEndDate",expectedEndDate);
					postData.expectedDueDate = expectedEndDate;
				}
			}

			if (module == "Investigation") {
				let jwtdf = dateformat.slice(0, 10).toUpperCase();
				let jwtdf1 = jwtdf + ' HH:mm:ss';
				if (this.props.investigationdetail.expectedStartDate && this.props.investigationdetail.expectedStartDate != '') {
					let expectedStartDate = moment(this.props.investigationdetail.expectedStartDate, jwtdf1);
					console.log("expectedStartDate",expectedStartDate);
					postData.expectedStartDate = expectedStartDate;
				}
				if (this.props.investigationdetail.expectedEndDate && this.props.investigationdetail.expectedEndDate != '') {
					let expectedEndDate = moment(this.props.investigationdetail.expectedEndDate, jwtdf1);
					console.log("expectedEndDate",expectedEndDate);
					postData.expectedDueDate = expectedEndDate;
				}
			}
			
			if (module == "ChangeManagement") {
				let jwtdf = dateformat.slice(0, 10).toUpperCase();
				let jwtdf1 = jwtdf + ' HH:mm:ss';
				if (this.props.editChangeInitialData.expectedStartDate && this.props.editChangeInitialData.expectedStartDate != '') {
					let expectedStartDate = moment(this.props.editChangeInitialData.expectedStartDate, jwtdf1);
					console.log("expectedStartDate",expectedStartDate);
					postData.expectedStartDate = expectedStartDate;
				}
				if (this.props.editChangeInitialData.expectedEndDate && this.props.editChangeInitialData.expectedEndDate != '') {
					let expectedEndDate = moment(this.props.editChangeInitialData.expectedEndDate, jwtdf1);
					console.log("expectedEndDate",expectedEndDate);
					postData.expectedDueDate = expectedEndDate;
				}
			}
			console.log("postData",postData);
			axios.post(GLOBAL.taskurl, postData, {
				headers: {
					"X-IBM-Client-Id": "5a78384c-5316-42ad-a23e-56bc2eb76d7d"
				}
			}).
				then((response) => {

					let taskId = response.data.taskCode.substring(3);
					let taskDescription = response.data.description;
					ref.props.checkRelatedTaskForItem(response.data.workItemId, response.data.workItemType)
					if (module == "Breakfix") {
						// axios.post(GLOBAL.breakFixRelatedLogPostUrl, {
						// 	"itemId": taskId,
						// 	"status": "A",
						// 	"createdOn": "",
						// 	"createdBy": "",
						// 	"modifiedOn": "",
						// 	"modifiedBy": "",
						// 	"module": "Task",
						// 	"workItemId": this.props.breakFixEditDetails.breakfixId,
						// 	"description": "",
						// 	"taskDescription": taskDescription,
						// 	"workModule": "Breakfix"
						// }).
						// 	then((response) => {

						// 		this.setState({ showLoader: false });
						// 		console.log('task submitted in realation.')
						// 		this.inputDescription.value = "";
						// 		this.inputSequence.value = "";
						// 		this.inputSequenceType.value = "0";
						// 		this.inputStatus.value = "1";
						// 		this.inputGroup.value = "0";
						// 		this.inputGroupInd.value = "0";
						// 		this.inputAddInfo.value = "";
						// 	}).catch(() => {

						// 		this.setState({ showLoader: false });
						// 	});
						this.setState({ showLoader: false });
								console.log('task submitted in realation.')
								
						this.props.loadBreakFixTaskLogDetails(actualidtosave, "Breakfix", "asc", this.props.breakFixEditDetails.consumerCompany);
						this.inputDescription.value = "";
								this.inputSequence.value = "";
								this.inputSequenceType.value = "0";
								this.inputStatus.value = "1";
								this.inputGroup.value = "0";
								this.inputGroupInd.value = "0";
								this.inputAddInfo.value = "";
						
					}
					if (module == "Fullfilment") {
						this.setState({ showLoader: false });
						console.log('task submitted in realation.1')

						this.props.loadBreakFixTaskLogDetails(actualidtosave, "Fullfilment", "asc", this.props.fulfillmentDetails.companyId);
						this.inputDescription.value = "";
						this.inputAddInfo.value = "";
						this.inputSequence.value = "";
						this.inputSequenceType.value = "0";
						this.inputStatus.value = "1";
						this.inputGroup.value = "0";
						this.inputGroupInd.value = "0";
					}
					if (module == "ChangeManagement") {
						this.setState({ showLoader: false });
						console.log('task submitted in realation.2')

						//this.props.loadBreakFixTaskLogDetails(actualidtosave,"Investigation","desc",this.props.investigationItemDetails.consumerCompany);
						this.props.loadBreakFixTaskLogDetails(actualidtosave, "ChangeManagement", "asc", this.props.editChangeInitialData.consumerCompany);
						this.inputDescription.value = "";
						this.inputAddInfo.value = "";
						this.inputSequence.value = "";
						this.inputSequenceType.value = "0";
						this.inputStatus.value = "1";
						this.inputGroup.value = "0";
						this.inputGroupInd.value = "0";
					}
					if (module == "Investigation") {
						this.setState({ showLoader: false });
						console.log('task submitted in realation.3')
						//this.props.loadBreakFixTaskLogDetails(actualidtosave,"Investigation","desc",this.props.investigationItemDetails.consumerCompany);
						this.props.loadBreakFixTaskLogDetails(actualidtosave, "Investigation", "asc", this.props.investigationdetail.consumerCompany);
						this.inputDescription.value = "";
						this.inputAddInfo.value = "";
						this.inputSequence.value = "";
						this.inputSequenceType.value = "0";
						this.inputStatus.value = "1";
						this.inputGroup.value = "0";
						this.inputGroupInd.value = "0";
					}
				}).catch(() => {

					this.setState({ showLoader: false });
				});
		}
	}

	asctasksort() {
		let getBreanFixkItemId = (this.props.breakFixItemId) ? (this.props.breakFixItemId).split(":") : ""
		let breakFixActualItemId = this.props.breakFixItemId || ""; //getBreanFixkItemId[1];
		if (this.props.module == "Breakfix") {
			this.props.loadBreakFixTaskLogDetails(breakFixActualItemId, "Breakfix", "asc", this.props.breakFixEditDetails.consumerCompany);
		}
		if (this.props.module == "Fullfilment") {
			this.props.loadBreakFixTaskLogDetails(this.props.itemId, "Fullfilment", "asc", this.props.fulfillmentDetails.companyId);
		}
		if (this.props.module == "ChangeManagement") {
			//this.props.loadBreakFixTaskLogDetails(this.props.itemId,"ChangeManagement","asc",this.props.investigationItemDetails.consumerCompany);
			this.props.loadBreakFixTaskLogDetails(this.props.itemId, "ChangeManagement", "asc", this.props.editChangeInitialData.consumerCompany);
		}
		if (this.props.module == "Investigation") {

			this.props.loadBreakFixTaskLogDetails(this.props.itemId, "Investigation", "asc", this.props.investigationdetail.consumerCompany);
			//this.props.loadBreakFixTaskLogDetails(this.props.itemId,"Investigation","asc",3215);
		}

	}
	desctasksort() {
		let getBreanFixkItemId = (this.props.breakFixItemId) ? (this.props.breakFixItemId).split(":") : ""
		let breakFixActualItemId = this.props.breakFixItemId || ""; //getBreanFixkItemId[1];
		if (this.props.module == "Breakfix") {
			this.props.loadBreakFixTaskLogDetails(breakFixActualItemId, "Breakfix", "desc", this.props.breakFixEditDetails.consumerCompany);
		}
		if (this.props.module == "Fullfilment") {
			this.props.loadBreakFixTaskLogDetails(this.props.itemId, "Fullfilment", "desc", this.props.fulfillmentDetails.companyId);
		}
		if (this.props.module == "ChangeManagement") {
			//this.props.loadBreakFixTaskLogDetails(this.props.itemId,"Investigation","desc",this.props.investigationItemDetails.consumerCompany);
			this.props.loadBreakFixTaskLogDetails(this.props.itemId, "ChangeManagement", "desc", this.props.editChangeInitialData.consumerCompany);
		}
		if (this.props.module == "Investigation") {

			this.props.loadBreakFixTaskLogDetails(this.props.itemId, "Investigation", "desc", this.props.investigationdetail.consumerCompany);
			//this.props.loadBreakFixTaskLogDetails(this.props.itemId,"Investigation","asc",3215);
		}
	}
	onGroupNameChange(groupName){
      this.setState({typedGroupName: groupName, selectedGroup: []});
	  this.resetAssignedToDetails();
	}
	onGroupSelected(selectedGroup){
		if(selectedGroup.length > 0){
			this.props.loadAsignedTo(selectedGroup[0].id);
			this.resetAssignedToDetails(); 
			this.setState({selectedGroup: selectedGroup, typedGroupName: '', isExternal: selectedGroup[0].isExternal});
			return;
		}
		this.setState({selectedGroup: []});
	}
	setErrorColor(){
       if(this.state.selectedGroup.length === 0){
		   return;
	   }
	}
	onCrossClick(){
	   this.resetAssignedToDetails();
	   this.props.loadAsignedTo(''); 	
       this.setState({selectedGroup: [], typedGroupName: ''});
	} 
	resetAssignedToDetails(){
		GLOBAL.taskassignementuserval = 0; 
		this.setState({ assignedToUser: '' });
	}

	render() {
		console.log("fieldStatus",this.props.fieldStatus);
		console.log("orderNumber",this.props.orderNumber);
		console.log("fulfillmentDetails000000000000",this.props.fulfillmentDetails);
		console.log("breakFixItemId",this.props.breakFixItemId);
		console.log("itemId",this.props.itemId);


		return (
			<div>
				<div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
                    <ul>
                        <li>
                        <button
						    type='button'
                            title="Minimize the right panel"
                            bsClass=""
                            bsStyle=""
                            className="closerightPanelBtn"
                            onClick={() => {
                            this.props.rightEditPanel(false);
                            }}
                        >
                            <IoClose />
                        </button>
                        </li>
                    </ul>
                </div>
				<div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Tasks']}</div></div>
				<div className='rBoxGap'>
					{(
						(this.props && this.props.fieldStatus && this.props.fieldStatus.tasks == false)
						||
						(this.state.hideTaskForm)
						||
						(this.props.disableFieldsForGuestRole)
					) ?
						null
						:
						<div className="">
							<Row>
								<Col md={12}><Form.Group className="form-group margin-b-5"><span className="rStar" />{" "}<Form.Label>{this.props.translator['Summary']}</Form.Label>
								<Form.Control as="textarea" id="description" maxLength="4000" rows="2" inputRef={(input) => this.inputDescription = input} onChange={(event) => { this.getData(event) }} /></Form.Group></Col>
								<p className="charLeft" style={{ textAlign: "right", "fontSize": "11px" }}>({this.props.translator['Characters Remaining']}: {this.state.additionalSummaaryCharLeft}/4000)</p>
								<Col md={6}>
									<Form.Group className="form-group margin-b-5">
										<span className="rStar" />{" "}
										<Form.Label>{this.props.translator['Task Type']}</Form.Label>
										<Form.Select aria-label="select" name="tasktype" id="taskTypeId" inputRef={(input) => this.inputGroup = input} onChange={(event) => { this.getData(event) }}>
											<option value="0">Select</option>
											{this.renderTasktype(this.state.oldUrgencyList)}	
										</Form.Select>
									</Form.Group>
								</Col>
								{/* <Col md={6} className="padding-r-0"><Form.Group className="margin-b-5"><Form.Label>{this.props.translator['Summary']}</Form.Label><Form.Control type="text" id="description" inputRef={(input) => this.inputDescription = input} onChange={(event) => { this.getData(event) }} /></Form.Group></Col> */}
								<Col md={6}><Form.Group className="form-group margin-b-5"><span className="rStar" />{" "}
									<Form.Label>{this.props.translator['Sequence']}</Form.Label>
									<Form.Control type="number" name="sequence" id="sequence" min={this.state.minSequenceNumber} max={this.state.maxSequenceNumber} inputRef={(input) => this.inputSequence = input} onChange={(event) => { this.getData(event) }} onKeyPress={(e) => {e.key === "Enter" && e.preventDefault();}}>
										{/*<option value="0">Select</option>
						<option value="1">1</option>
						<option value="2">2</option>
						<option value="3">3</option>
						<option value="4">4</option>*/}
									</Form.Control>
								</Form.Group></Col>
							</Row>
							<Row>
								<Col md={6}>
									<Form.Group className="form-group margin-b-5">
										<span className="rStar" />{" "}
										<Form.Label>{this.props.translator['Assignment Group']}</Form.Label>
											<TypeaheadExampleSingleSelect
                                                    id="supportPGroup"
                                                    name="supportPGroup"
                                                    onSelection={this.onGroupSelected}
                                                    setErrorColor={this.setErrorColor}
                                                    options={this.state.groupList}
                                                    selectedOptions={this.state.selectedGroup}
                                                    onInputChange={this.onGroupNameChange}
                                                    onCrossClick={this.onCrossClick}
                                                    typedValue={this.state.typedGroupName}
                                                    errorClass={''} />
									</Form.Group>
								</Col>

								<Col md={6}><Form.Group className="form-group margin-b-5">
									<Form.Label>{this.props.translator['Assigned To']}</Form.Label>
									<Form.Select aria-label="select" id="supportPIndividual" className="tskSupportDropdown" name="supportPIndividual" inputRef={(input) => this.inputGroupInd = input} onChange={(event) => { this.getData(event) }}>
										<option value="0">Select</option>
										{this.renderSupportPIGroup(this.props.taskAssignedToData)}
									</Form.Select>
								</Form.Group>
								</Col>


								<Col md={12}><Form.Group className="form-group margin-b-5">
									<Form.Label>{this.props.translator['Additional Information']}</Form.Label>
									<Form.Control as="textarea" maxLength="500" id="information" inputRef={(input) => this.inputAddInfo = input} rows="2" onChange={(event) => { this.getData(event); this.descriptionCharacter(event) }} />
									<p className="charLeft" style={{ textAlign: "right", "fontSize": "11px" }}>({this.props.translator['Characters Remaining']}: {this.state.additionalcharleft}/500)</p>
								</Form.Group></Col>


								{/*<Col md={6}><Form.Group className="margin-b-5">
					<Form.Label>{this.props.translator['Sequence Type']}</Form.Label>
					<Form.Control componentClass="select" id="sequencetype" name="sequencetype" inputRef = {(input) => this.inputSequenceType = input } onChange={(event)=>{this.getData(event)}}>
					<option value="0">Select</option>
					<option value="Sequential">Sequential</option>
					<option value="Parallel">Parallel</option>
					</Form.Control>
					</Form.Group>
					</Col>*/}
								{/* <Col md={6}><Form.Group className="margin-b-5">
								<Form.Label>{this.props.translator['Status']}</Form.Label>
								<Form.Control componentClass="select" name="status" inputRef={(input) => this.inputStatus = input} id="status" onChange={(event) => { this.getData(event) }}>

									<option value="1">Assigned</option>
								</Form.Control>
							</Form.Group>
							</Col> */}

							</Row>
							<Row>


							</Row>
							{/* {this.state.showLoader ?
						<div className="workitemTaskFloatRight">
							<Loader type="Watch" color="green" height={20} width={20} />
						</div> : <span></span>
					}
				{this.state.showLoader ?
				<a  title={this.props.translator['Save']} ><i className="fa fa-chevron-circle-right "></i></a>
				:<a  title={this.props.translator['Save']} onClick={this.postTaskData.bind(this)}><i className="fa fa-chevron-circle-right "></i></a>
				} */}
							<div className="black margin-t-5">
								<Button className="rgSidrkBtn smallBtn" bsSize="small" bsStyle="primary" title={this.props.translator['Save']} disabled={this.state.showLoader} onClick={this.postTaskData.bind(this)}>
									{this.state.showLoader ? <ImSpinner6 className="icn-spinner"/> : null} {this.props.translator['Save']}</Button>
							</div>
						</div>
					}
					<div className="margin-t-5 padding-b-5 border-b"><span className="float-r f-size-17"><a onClick={this.asctasksort.bind(this)} className="black" title={this.props.translator['Ascending']}><i className="fa fa-angle-up"></i></a>&nbsp;&nbsp;<a onClick={this.desctasksort.bind(this)} className="black" title={this.props.translator['Descending']}><i className="fa fa-angle-down" ></i></a></span></div>
					<div className="clearboth">{this.renderTaskData(this.props.breakFixTaskLogDetails)}</div>

				</div>
			</div>
		);
	}
}

export function mapStateToProps({ taskAssignedToData, breakFixEditDetails, breakFixSGName, breakFixSGIName,
	breakFixTaskLogDetails, fulfillmentDetails, breakFixSGAETaskName, investigationItemDetails,
	editChangeInitialData, taskGroupData2, showLoader, showLoaderRelatedTask, taskTypeList, windowSize }) {
	return {
		taskAssignedToData, taskGroupData2, breakFixEditDetails, breakFixSGName, breakFixSGIName,
		breakFixTaskLogDetails, fulfillmentDetails, breakFixSGAETaskName, investigationItemDetails,
		editChangeInitialData, showLoader: showLoader.loading, showLoaderRelatedTask: showLoaderRelatedTask.loading, taskTypeList, windowSize: windowSize.width
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ checkRelatedTaskForItem, getInvestigationItemDetailsUpdate, loadAsignedTo, loadBreakFixEditDetails, loadBreakFixEditDetailsUpdate, loadBreakFixSG, loadBreakFixSGI, loadBreakFixTaskLogDetails, loadFulfillmentDetails, loadBreakFixSGAETask, getInvestigationItemDetails, loadChangeEditData, loadTaskGroup, loadTaskGroup2, getTasktype }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(PopoverTasksData)
