
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const acquaintedArticles = (state = [], action) => {
    switch(action.type) {
        case 'ACQUAINTED_ARTICLE_LOADING_SUCCESS':
            return action.articles.data;
        case 'ACQUAINTED_ARITCLE_LOADING_FAILURE':
            return action.errorResponse;
        default:
            return state;
    }
}