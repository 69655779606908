
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import EditSystemConfigurationHeader from "./EditMailboxConfigHeader";
import EditSystemConfigurationForm from "./EditMailboxConfigForm";
import { useSelector } from "react-redux";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import Cookies from "universal-cookie";
import Breadcrumbs from "../../common/header/breadcrumbs";
import CredentialForm from "../createMailboxConfigration/Common/SystemConfigCredentials";
import { useParams } from "react-router";
import "_Css/form/_form.scss";
const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home") homepagelocation = "/" + homepagelocation;

const EditMailboxConfigIndex = (props) => {
  const [changeActionView, setChangeActionView] = useState("assetgraph");
  const [showCredentialForm, setShowCredentialForm] = useState(false);
  const [mailBoxType, setMailBoxType] = useState('');
  const [companyError, setCompanyError] = useState("");
  const [offeringError, setOfferingError] = useState('');
  const [requestorError, setRequestorError] = useState('');
  const [selectedCompanyList, setSelectedCompanyList] = useState([]);
  const [selectedRequestor, setSelectedRequestor] = useState({});
  const [selectedOffering, setSelectedOffering] = useState({});

  const [mailboxIdErrorColor, setMailboxIdErrorColor] = useState("");
  const [mailboxProviderErrorColor, setMailboxProviderErrorColor] = useState("");
  const [whitelistedDomainErrorColor, setWhitelistedDomainErrorColor] = useState("");
  const [urgencyErrorColor, setUrgencyErrorColor] = useState("");
  const [impactErrorColor, setImpactErrorColor] = useState("");
  const [actionsErrorColor, setActionsErrorColor] = useState("");
  const [isRightEditPanel, setrightEditPanel] = useState(true);
  const [statusErrorColor, setStatusErrorColor] = useState("");
  const translator = useSelector((state) => state.spcmReducer.tr);
  
  const setCompanyErrorColor = () => {
    if (selectedCompanyList.length === 1) {
      setCompanyError("");
    } else {
      setCompanyError("error");
    }
  };

  const rightEditPanel = (value) => {
    setrightEditPanel(value);
  };

  const setOfferingErrorColor = () => {
    if (Object.keys(selectedOffering).length > 0) {
      setOfferingError("");
    } else {
      setOfferingError("error");
    }
  };
  const setRequestorErrorColor = () => {
    if (Object.keys(selectedRequestor).length > 0) {
      setRequestorError('');
    } else {
      setRequestorError('error');
    }
  };
  const { id } = useParams();
  return (
    <div>
      <div>
        <div className="container-fluid padding-t-10 margin-b-15">
          <Breadcrumbs
            activePageName={"Edit"}
            parentPageurl="/systemConfig"
            parentPageName={translator["Mailbox Configuration"]}
          />
        </div>
        <div className="container-fluid" bsClass="">
          <Form>
            <EditSystemConfigurationHeader
              changeActionView={changeActionView}
              translator={translator}
              id={id}
              setShowCredentialForm={setShowCredentialForm}
              setRequestorErrorColor={setRequestorErrorColor}
              setOfferingErrorColor={setOfferingErrorColor}
              setCompanyErrorColor={setCompanyErrorColor}
              setActionsErrorColor={setActionsErrorColor}
              setImpactErrorColor={setImpactErrorColor}
              setUrgencyErrorColor={setUrgencyErrorColor}
              setWhitelistedDomainErrorColor={setWhitelistedDomainErrorColor}
              setMailboxProviderErrorColor={setMailboxProviderErrorColor}
              setMailboxIdErrorColor={setMailboxIdErrorColor}
              rightEditPanel={rightEditPanel}
              setStatusErrorColor={setStatusErrorColor}
              isRightEditPanel={isRightEditPanel}
            /> 
            <PanelGroup direction="horizontal">
              <Panel id="sidebar" minSize={30} order={1} defaultSize={isRightEditPanel ? 67 : 100} className={isRightEditPanel ? "isShowLeftPanel" : ""}>
                {/* Xsm Break Fix Form tabs Section Start*/}			
                <EditSystemConfigurationForm translator={translator}
                    setMailBoxType={setMailBoxType}
                    offeringError={offeringError}
                    requestorError={requestorError}
                    companyError={companyError}
                    setRequestorErrorColor={setRequestorErrorColor}
                    setOfferingErrorColor={setOfferingErrorColor}
                    setCompanyErrorColor={setCompanyErrorColor}
                    setSelectedOffering={setSelectedOffering}
                    setSelectedRequestor={setSelectedRequestor}
                    setSelectedCompanyList={setSelectedCompanyList}
                    selectedRequestor={selectedRequestor}
                    selectedCompanyList={selectedCompanyList}
                    actionsErrorColor={actionsErrorColor}
                    impactErrorColor={impactErrorColor}
                    urgencyErrorColor={urgencyErrorColor}
                    whitelistedDomainErrorColor={whitelistedDomainErrorColor}
                    mailboxProviderErrorColor={mailboxProviderErrorColor}
                    mailboxIdErrorColor={mailboxIdErrorColor}
                    setActionsErrorColor={setActionsErrorColor}
                    setImpactErrorColor={setImpactErrorColor}
                    setUrgencyErrorColor={setUrgencyErrorColor}
                    setWhitelistedDomainErrorColor={setWhitelistedDomainErrorColor}
                    setMailboxProviderErrorColor={setMailboxProviderErrorColor}
                    setMailboxIdErrorColor={setMailboxIdErrorColor}
                    rightEditPanel={rightEditPanel}
                    setStatusErrorColor={setStatusErrorColor}
                    statusErrorColor={statusErrorColor}
                  />
              </Panel>
              {isRightEditPanel ?
              <>
              <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
              <Panel minSize={33} order={2} defaultSize={33} className="shopCartloader2">
                <div className="stickyArea rBoxStyle">
                    <CredentialForm translator={translator}
                      mailBoxType={mailBoxType}
                      id={id}
                      rightEditPanel={rightEditPanel}
                    />
                  </div>
              </Panel>
              </> : ""
              }
            </PanelGroup>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default EditMailboxConfigIndex;
