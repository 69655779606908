
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import constants from '../../utils/constants';
import { GLOBAL }  from '../../components/Globals';
export const getTimelineData = (itemId) => {
    return (dispatch) => {
        dispatch({type:'LOADING_CMDB_TIMELINE',isFetching:true});
        //const response = axios.get(constants.cmdburl+'/api/audit/'+itemId+'?queryBy=status');
        const response = axios.get(GLOBAL.ciTimeLineDataUrl+'/id/'+itemId);
            response.then((response) => {
                if(!response) {
                    console.log('error loading cmdb timeline data...');
                }else {
                    if(response.status == "304" || response.status == 200){
                        dispatch({type:'TIMELINE_DATA_LOAD_SUCCESS',
                        isFetching:false,
                        auditData:response.data});
                    }
                }
            });
    }
};

export const getRelationshipData = (ci_id, module,isWibCall) => {
    return (dispatch) => {
        axios.get(GLOBAL.cmdbRelatedLogDeleteUrl + '/'+ci_id+'/'+module+'/'+isWibCall)
            .then((response) => {
                if (!response) {
                    console.log('error loading cmdb relationship data...');
                } else {
                    if (response.status == "304" || response.status == 200) {
                        dispatch({
                            type: 'RELATIONSHIP_DATA_LOAD_SUCCESS',
                            relationshipData: response.data
                        });
                    }
                }
            })
    }
};