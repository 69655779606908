
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { Fragment, useEffect, useState } from "react";
import { Field, change } from "redux-form";
import { Alert, Button, ButtonToolbar, Col, Form } from "react-bootstrap";
import {
  _inputField,
  _textArea,
  _number,
  _dropDown,
} from "../../../../common/formFields";
import FormBuilderTypeahead from "../FormBuilderTypeahead";
import MultiSelectField from "../kendoFormComponents/MultiSelectField";
import DatePickerField from "../kendoFormComponents/DatePickerField";
import DateInputField from "../kendoFormComponents/DateInputField";
import ColorPickerField from "../kendoFormComponents/ColorPickerField";
import DateTimePickerField from "../kendoFormComponents/DateTimePickerField";
import DateRangePickerField from "../kendoFormComponents/DateRangePickerField";
import {
  CheckboxField,
  RadioButtonField,
  TextField,
} from "../kendoFormComponents/TextField";
import DropzoneField from "../kendoFormComponents/DropzoneField";
import { getLanguageString } from "../../../../common/helper";
import DropDownConnector from "../DropDownConnector";
import useDataQueryWithApi from "../../../hooks/useDataQueryWithApi";
import { TextArea } from "@progress/kendo-react-inputs";
import TextAreaField from "../kendoFormComponents/TextAreaField";
import { Editor, EditorTools } from "@progress/kendo-react-editor";

export const GetFields = ({
  attributes,
  language,
  onChange,
  defaultValues,
  hiddenFIelds,
  readOnlyFields,
  errors,
  showPassword,
  setShowPassword,
  atr
}) => {
  
  if (attributes && Object.keys(attributes).length > 0) {
    switch (attributes?.type?.id) {
      case "datePicker":
        if (!hiddenFIelds.includes(attributes?.name)) {
          return (
            <DatePickerField
              //component={DatePickerField}
              onValueChange={(e) => onChange(e.value, attributes)}
              name={attributes.name}
              id={atr?.id}
              className={`${atr?.className} ${errors[attributes?.name] && "error"}`}
              attributes={attributes}
              defaultValue={
                defaultValues.hasOwnProperty(attributes?.name)
                  ? defaultValues[attributes?.name]
                  : new Date()
              }
              language={language}
              readOnly={readOnlyFields.includes(attributes?.name)}
              nonEditable={defaultValues && defaultValues.hasOwnProperty('edittable')?!defaultValues?.edittable:false}
            />
          );
        }
        return null;
      case "dateInput":
        if (!hiddenFIelds.includes(attributes?.name)) {
          return (
            <DateInputField
              //component={DateInputField}
              onValueChange={(e) => onChange(e.value, attributes)}
              name={attributes.name}
              id={atr?.id}
              className={`${atr?.className} ${errors[attributes?.name] && "error"}`}
              attributes={attributes}
              defaultValue={
                defaultValues.hasOwnProperty(attributes?.name)
                  ? defaultValues[attributes?.name]
                  : null
              }
              language={language}
              readOnly={readOnlyFields.includes(attributes?.name)}
              nonEditable={defaultValues && defaultValues.hasOwnProperty('edittable')?!defaultValues?.edittable:false}
            />
          );
        }
        return null;
      case "dateTimePicker":
        if (!hiddenFIelds.includes(attributes?.name)) {
          return (
            <DateTimePickerField
              //component={DateTimePickerField}
              onValueChange={(e) => onChange(e.value, attributes)}
              name={attributes.name}
              id={atr?.id}
              className={`${atr?.className} ${errors[attributes?.name] && "error"}`}
              attributes={attributes}
              defaultValue={
                defaultValues.hasOwnProperty(attributes?.name)
                  ? defaultValues[attributes?.name]
                  : null
              }
              language={language}
              readOnly={readOnlyFields.includes(attributes?.name)}
              nonEditable={defaultValues && defaultValues.hasOwnProperty('edittable')?!defaultValues?.edittable:false}
            />
          );
        }
        return null;
      case "dateRangePicker":
        if (!hiddenFIelds.includes(attributes?.name)) {
          return (
            <DateRangePickerField
              //component={DateRangePickerField}
              onValueChange={(e) => onChange(e.value, attributes)}
              name={attributes.name}
              id={atr?.id}
              className={`${atr?.className} ${errors[attributes?.name] && "error"}`}
              attributes={attributes}
              defaultValue={
                defaultValues.hasOwnProperty(attributes?.name)
                  ? defaultValues[attributes?.name]
                  : null
              }
              language={language}
              readOnly={readOnlyFields.includes(attributes?.name)}
              nonEditable={defaultValues && defaultValues.hasOwnProperty('edittable')?!defaultValues?.edittable:false}
            />
          );
        }
        return null;
      case "textArea":
        if (!hiddenFIelds.includes(attributes.name)) {
          return (
            <TextAreaField
              onValueChange={(e) => onChange(e.value, attributes)}
              name={attributes.name}
              id={atr?.id}
              className={`${atr?.className} ${errors[attributes?.name] && "error"}`}
              attributes={attributes}
              defaultValue={
                defaultValues.hasOwnProperty(attributes?.name)
                  ? defaultValues[attributes?.name]
                  : null
              }
              language={language}
              readOnly={readOnlyFields.includes(attributes?.name)}
              nonEditable={defaultValues && defaultValues.hasOwnProperty('edittable')?!defaultValues?.edittable:false}
            />
          );
        }
        return null;
      case "colorPicker":
        if (!hiddenFIelds.includes(attributes?.name)) {
          return (
            <Fragment>
              <br />
              <ColorPickerField
                //component={ColorPickerField}
                onValueChange={(e) => onChange(e.value, attributes)}
                name={attributes.name}
                id={atr?.id}
                className={`${atr?.className} ${errors[attributes?.name] && "error"}`}
                attributes={attributes}
                defaultValue={
                  defaultValues.hasOwnProperty(attributes?.name)
                    ? defaultValues[attributes?.name]
                    : "#ccc"
                }
                language={language}
                readOnly={readOnlyFields.includes(attributes?.name)}
                nonEditable={defaultValues && defaultValues.hasOwnProperty('edittable')?!defaultValues?.edittable:false}
              />
            </Fragment>
          );
        }
        return null;
        case "textEditor":
          if (!hiddenFIelds.includes(attributes.name)) {
            return (
              <EditorBox
                onValueChange={(value) => onChange(value, attributes)}
                name={attributes.name}
                id={atr?.id}
                className={`${atr?.className} ${errors[attributes?.name] && "error"}`}
                attributes={attributes}
                defaultValues={defaultValues}
                language={language}
                errors={errors}
                readOnly={readOnlyFields.includes(attributes?.name)}
                nonEditable={defaultValues && defaultValues.hasOwnProperty('edittable')?!defaultValues?.edittable:false}
              />
            );
          }
          return null;
      default:
        if (!hiddenFIelds.includes(attributes?.name)) {
          return (
            <Fragment>
              {attributes?.showEyeIcon && (
                <a
                  role="button"
                  title={!showPassword ? "Show Password" : "Hide Password"}
                  href="javascript:void(0)"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                  style={{
                    position: "relative",
                    top: "25px",
                    left: "85%",
                    padding: "0px 5px 2px",
                    zIndex: "1",
                  }}
                >
                  <i
                    className={!showPassword ? "fa fa-eye" : "fa fa-eye-slash"}
                    aria-hidden="true"
                  ></i>
                </a>
              )}
              <TextField
                //component={TextField}
                name={attributes.name}
                id={atr?.id}
                className={`${atr?.className} ${
                  errors[attributes?.name] && "error"
                }`}
                placeholder={attributes.placeholder}
                attributes={attributes}
                onValueChange={(e) => onChange(e.value, attributes)}
                defaultValue={
                  defaultValues.hasOwnProperty(attributes?.name)
                    ? defaultValues[attributes?.name]
                    : attributes?.defaultValue
                    ? attributes?.defaultValue
                    : null
                }
                showPassword={showPassword}
                language={language}
              readOnly={readOnlyFields.includes(attributes.name)}
                nonEditable={defaultValues && defaultValues.hasOwnProperty('edittable')?!defaultValues?.edittable:false}
              />
            </Fragment>
          );
        }
        return null;
    }
  }
};

export const GetInputField = ({
  attributes,
  language,
  onChange,
  defaultValues,
  hiddenFIelds,
  readOnlyFields,
  errors,
  atr
}) => {
  const [showPassword,setShowPassword] = useState(false);
  useEffect(() => {
    if (
      attributes?.defaultValue &&
      !defaultValues.hasOwnProperty(attributes.name)
    ) {
      onChange(attributes.defaultValue, attributes);
    }
  }, []);
  if (!hiddenFIelds.includes(attributes?.name)) {
    return (
      <Fragment>
        {/* <Form.Label>
            {attributes.required && <span className="rStar"></span>}
            {getLanguageString(language, attributes?.label)}
          </Form.Label> */}
        {GetFields({
          attributes,
          language,
          onChange,
          defaultValues,
          hiddenFIelds,
          readOnlyFields,
          errors,
          showPassword,
          setShowPassword,
          atr
        })}
        {attributes?.maxLength && attributes?.maxLength > 0 && (
          <span class="f-size-10">
            Max Length : {attributes?.maxLength} characters
          </span>
        )}
      </Fragment>
    );
  }
};

export const GetTextareaField = ({
  attributes,
  language,
  onChange,
  defaultValues,
  hiddenFIelds,
  readOnlyFields,
  errors,
  atr
}) => {
  if (!hiddenFIelds.includes(attributes?.name)) {
    return (
      <TextAreaField
        onValueChange={(e) => onChange(e.value, attributes)}
        name={attributes?.name}
        id={atr?.id}
        className={`${atr?.className} ${errors[attributes?.name] && "error"}`}
        attributes={attributes}
        defaultValue={
          defaultValues.hasOwnProperty(attributes?.name)
            ? defaultValues[attributes?.name]
            : null
        }
        language={language}
        readOnly={readOnlyFields.includes(attributes?.name)}
        nonEditable={defaultValues && defaultValues.hasOwnProperty('edittable')?!defaultValues?.edittable:false}
      />
    );
  }
};

export const GetTypeaheadField = ({
  attributes,
  language,
  onChange,
  defaultValues,
  hiddenFIelds,
  readOnlyFields,
  errors,
  setDefaultValues,
  resetForm,
  isEditForm,
  atr
}) => {
  if (!hiddenFIelds.includes(attributes?.name)) {
    return (
      <Fragment>
        {attributes?.fieldType?.id && attributes?.fieldType?.id == "typeahead" ? (
          <FormBuilderTypeahead
            //component={FormBuilderTypeahead}
            name={attributes.name}
            id={atr?.id}
            className={`${atr?.className} ${errors[attributes?.name] && "error"}`}
            attributes={attributes}
            language={language}
            defaultValues={defaultValues}
            onValueChange={onChange}
            setDefaultValues={setDefaultValues}
            resetForm={resetForm}
            isEditForm={isEditForm}
            errors={errors}
            readOnly={readOnlyFields.includes(attributes.name)}
            nonEditable={defaultValues && defaultValues.hasOwnProperty('edittable')?!defaultValues?.edittable:false}
          />
        ) : attributes?.fieldType?.id &&
          attributes?.fieldType?.id == "kendoMultiSelect" ? (
          <MultiSelectField
            //component={MultiSelectField}
            name={attributes.name}
            id={atr?.id}
            className={`${atr?.className} ${errors[attributes?.name] && "error"}`}
            attributes={attributes}
            language={language}
            defaultValues={defaultValues}
            resetForm={resetForm}
            isEditForm={isEditForm}
            nonEditable={defaultValues && defaultValues.hasOwnProperty('edittable')?!defaultValues?.edittable:false}
          />
        ) : null}
      </Fragment>
    );
  }
};

export const GetDropdownConnectorField = ({
  attributes,
  language,
  onChange,
  defaultValues,
  hiddenFIelds,
  readOnlyFields,
  updateValues,
  errors,
  atr
}) => {
const disabledKey = attributes?.disabledKey && defaultValues[attributes?.disabledKey]?true:false;
if (!hiddenFIelds.includes(attributes?.name)) {
  return (
    <Fragment>
      {/* <Form.Label>
          {attributes.required && <span className="rStar"></span>}
          {attributes.label}
        </Form.Label> */}
      <DropDownConnector
        //component={DropDownConnector}
        name={attributes.name}
        className={`${atr?.className} ${errors[attributes?.name] && "error"}`}
        attributes={attributes}
        updateValues={updateValues}
        formValues={defaultValues}
        readOnly={readOnlyFields.includes(attributes.name)}
        nonEditable={defaultValues && defaultValues.hasOwnProperty('edittable')?!defaultValues?.edittable:false}
        atr={atr}
        disabledKey={disabledKey}
      />
    </Fragment>
  );
}
};

export const CustomDropdown = ({
  props,
  language,
  onChange,
  defaultValues,
  hiddenFIelds,
  readOnlyFields,
  errors,
  atr,
  setDefaultValues
}) => {
  const [options, setOptions] = useState([]);
  const [responseData, optionsLoading, setDataSourceId] = useDataQueryWithApi(
    defaultValues,
    props
  );

  useEffect(() => {
    if (Array.isArray(responseData)) {
      let modifiedResp = responseData.map((res) => {
        return {
          value: res[props?.valueKey?.id],
          text: res[props?.labelKey?.id],
        };
      });
      setOptions(modifiedResp);
    } else {
      setOptions([]);
    }
  }, [responseData]);

  useEffect(() => {
    if (props?.attributes && props?.attributes.options) {
      const fieldOptions = props?.attributes.options.split(",");
      let generatedOptions = [];
      fieldOptions.forEach((option) => {
        const splittedOption = option.split("::");
        if (splittedOption[0] && splittedOption[1]) {
          generatedOptions.push({
            value: splittedOption[0],
            text: splittedOption[1],
          });
        }
      });
      setOptions(generatedOptions);
    } else if (props?.data_source) {
      setDataSourceId(props?.data_source.id);
    }
  }, []);
  const attributes = props.attributes;
  const disabledKey = props?.disabledKey && defaultValues[props?.disabledKey]?true:false;
  if (!hiddenFIelds.includes(attributes?.name)) {
    return (
      <Fragment>
        <select
          name={props.attributes?.name}
          className={`form-control ${errors[attributes?.name] && "error"}`}
          onChange={(event) => {
            let setDefaultValue = structuredClone(defaultValues);
            if (props.attributes?.name) {
              setDefaultValue[props.attributes?.name] = event.target.value;
            }
            if (props?.labelKeyMapping) {
              let filteredValue = options.filter(
                (opt) => opt.value == event.target.value
              );
              if (filteredValue.length > 0) {
                setDefaultValue[props?.labelKeyMapping] = filteredValue[0].text;
              } else {
                delete setDefaultValue[props?.labelKeyMapping];
              }
            }
            setDefaultValues(setDefaultValue);
          }}
          multiple={props?.isMultiple}
          disabled={props.attributes?.disabled || disabledKey || (defaultValues && defaultValues.hasOwnProperty('edittable')?!defaultValues?.edittable:false)}
          {...atr}
          readOnly={readOnlyFields.includes(attributes?.name)}
        >
          {!props?.hideSelect && <option value="">Select</option>}
          {options.map((res, index) => {
            return (
              <option
                key={`${attributes.name}-select-${index}`}
                value={res.value}
                selected={
                  defaultValues[attributes?.name]
                    ? res.value == defaultValues[attributes?.name]
                    : res.value == props.defaultValue
                }
              >
                {res.text}
              </option>
            );
          })}
        </select>
      </Fragment>
    );
  }
};

export const GetUploadDropzoneField = ({
  attributes,
  language,
  onChange,
  defaultValues,
  hiddenFIelds,
  errors,
  setFileData
}) => {
  if (!hiddenFIelds.includes(attributes?.name)) {
    return (
      <Fragment>
        <DropzoneField
          attributes={attributes}
          onFileChange={(files) => {
            let filesObject = {dataType:"file"};
            if (files && files.length > 0) {
              filesObject.data =  !attributes?.multiple?files[0]:files;
              onChange(filesObject, attributes);
              setFileData({[attributes?.name]:!attributes?.multiple?files[0]:files,attributes:attributes});
            }else{
              onChange("", attributes);
            }
          }}
          defaultValues={defaultValues}
          nonEditable={
            defaultValues && defaultValues.hasOwnProperty("edittable")
              ? !defaultValues?.edittable
              : false
          }
        />
      </Fragment>
    );
  }
};

export const GetCheckBoxField = ({
  attributes,
  language,
  onChange,
  defaultValues,
  hiddenFIelds,
  readOnlyFields,
  errors,
  atr
}) => {
  if (!hiddenFIelds.includes(attributes?.name)) {
    return (
        <CheckboxField
          attributes={attributes}
          onValueChange={(value) => {
            onChange(value, attributes);
          }}
          readOnly={readOnlyFields.includes(attributes?.name)}
          nonEditable={defaultValues && defaultValues.hasOwnProperty('edittable')?!defaultValues?.edittable:false}
          atr={atr}
        />
    );
  }
};

export const GetRadioButtonField = ({
  attributes,
  language,
  onChange,
  defaultValues,
  hiddenFIelds,
  readOnlyFields,
  errors,
}) => {
  if (!hiddenFIelds.includes(attributes?.name)) {
    return (
      <RadioButtonField
        attributes={attributes}
        onValueChange={(value) => {
          onChange(value, attributes);
        }}
        readOnly={readOnlyFields.includes(attributes?.name)}
        nonEditable={defaultValues && defaultValues.hasOwnProperty('edittable')?!defaultValues?.edittable:false}
      />
    );
  }
};

export const ITagComponent = (props) => {
  return <i {...props?.attributes}></i>;
};


export const InputCheckboxMainField = (props) => {
  const { attributes, onValueChange, defaultValue, language, className, readOnlyFields } =
    props;
  const [checked, setChecked] = useState(attributes.checked);

  const handleChange = (event) => {
    setChecked(event.value);
    event.value ? onValueChange(attributes?.value) : onValueChange("");
  };

  return (
    <input
      type="checkbox"
      checked={checked}
      onChange={handleChange}
      className={className || ""}
      value={attributes?.value}
      readOnly={readOnlyFields.includes(attributes?.name)}
    />
  );
};


export const EditorBox = ({
  attributes,
  language,
  onValueChange,
  defaultValues,
  hiddenFIelds,
  readOnly,
  errors,
  nonEditable
}) => {
  const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    FontSize,
    FontName,
    FormatBlock,
    Link,
    Unlink,
  } = EditorTools;
  return (
    <Editor
      tools={[
        [Bold, Italic, Underline, Strikethrough],
        [Subscript, Superscript],
        [AlignLeft, AlignCenter, AlignRight, AlignJustify],
        [Indent, Outdent],
        [OrderedList, UnorderedList],
        FontSize,
        FontName,
        FormatBlock,
        [Undo, Redo],
        [Link, Unlink],
      ]}
      contentStyle={{
        height: 300,
      }}
      defaultContent={
        defaultValues.hasOwnProperty(attributes?.name)
          ? defaultValues[attributes?.name]
          : ""
      }
      onChange={(e) => {
        onValueChange(e.html);
      }}
      name={attributes.name}
      className={`form-control ${errors[attributes?.name] && "error"}`}
      attributes={attributes}
      disabled={attributes?.disabled || nonEditable || readOnly}
    />
  );
};
