
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, change, SubmissionError } from 'redux-form';
import { _passwordField, _inputField, _dropDown, _checkbox, _number } from '../../../common/formFields';
import onCallDropdownPlusTypeahead from '../../common/onCallDropdownPlusTypeahead';
import { _dateTimeFieldGeneral } from "../../../common/ReduxFormFields/formFields";

import { getOnCallGroups } from '_Actions/onCallSchedule/common/onCallCommonAction';
import { getShiftListData } from '_Actions/onCallSchedule/shift/onCallShiftAction';

const FormComponent = (props) => {
    const dispatch = useDispatch();
    const translator = useSelector((state) => state.spcmReducer.tr);
    let group = useSelector((state) => state.selectedOnCallGroup);
    const onCallCommonReducer = useSelector((state) => state.on_call_common_reducer);
    const onCallGroupList = useSelector((state) => state.onCallGroupList);
    const shiftListData = useSelector((state) => state.shiftListData);

    const [groupSelectedList, setGroupSelectedList] = useState([]);
    const [inputValueGroup, setInputValueGroup] = useState('');

    let groupTypeaheadOptions = [];
    if (onCallGroupList && onCallGroupList.length > 0) {
        onCallGroupList.forEach((item, i) => {
            let obj = {};
            obj.id = item.groupId;
            obj.label = item.groupName;
            obj.companyId = item.companyId;
            obj.companyName = item.companyName;
            groupTypeaheadOptions.push(obj);
        });
    }

    const [shiftSelectedList, setShiftSelectedList] = useState([]);
    const [inputValueShift, setInputValueShift] = useState('');

    let shiftTypeaheadOptions = [];
    if (shiftListData && shiftListData.length > 0) {
        shiftListData.forEach((item, i) => {
            let obj = {};
            obj.id = item.shiftId;
            obj.label = item.shiftName;
            shiftTypeaheadOptions.push(obj);
        });
    }

    useEffect(() => {
        dispatch(getOnCallGroups());
        if(props.createRosterFromShiftInitialValues) {
            dispatch(change('onCallScheduleCreateRosterForm', 'group', props.createRosterFromShiftInitialValues.group));
            dispatch(change('onCallScheduleCreateRosterForm', 'companyId', props.createRosterFromShiftInitialValues.companyId));
            dispatch(change('onCallScheduleCreateRosterForm', 'companyName', props.createRosterFromShiftInitialValues.companyName));
            dispatch(getShiftListData({groupId:props.createRosterFromShiftInitialValues.group}, 0, 5000));
            dispatch(change('onCallScheduleCreateRosterForm', 'shift', props.createRosterFromShiftInitialValues.shift));
            dispatch(change('onCallScheduleCreateRosterForm', 'shiftName', props.createRosterFromShiftInitialValues.shiftName));
        }
    }, []);
    
    useEffect(() => {
        if(props.createRosterFromShiftInitialValues) {
            setGroupSelectedList([{id: props.createRosterFromShiftInitialValues.group, label: props.createRosterFromShiftInitialValues.groupName}]);
            setInputValueGroup(props.createRosterFromShiftInitialValues.groupName);
        }
    }, [onCallGroupList]);

    useEffect(() => {
        if(group && Object.keys(group).length>0){
            setGroupSelectedList(group);
            // dispatch(change('onCallScheduleCreateRosterForm', 'shift', ''));
            // dispatch(change('onCallScheduleCreateRosterForm', 'shiftName', ''));
            dispatch(change('onCallScheduleCreateRosterForm', 'group', group[0].id));
            dispatch(change('onCallScheduleCreateRosterForm', 'companyId', group[0].companyId));
            dispatch(change('onCallScheduleCreateRosterForm', 'companyName', group[0].companyName));
            dispatch(getShiftListData({groupId:group[0].id}, 0, 5000));
            setInputValueGroup(group[0].label);
        }
      }, [group]);

    useEffect(() => {
        if(props.createRosterFromShiftInitialValues) {
            setShiftSelectedList([{id: props.createRosterFromShiftInitialValues.shift, label: props.createRosterFromShiftInitialValues.shiftName}]);
            setInputValueShift(props.createRosterFromShiftInitialValues.shiftName);
        }
    }, [shiftListData]);

    function validateField(fieldType, event, picker = false) {
        switch (fieldType) {
            case 'startDate':
                if (!picker) {
                    props.setStartDateErrorState('error');
                } else {
                    props.setStartDateErrorState('');
                }
                break;
            case 'endDate':
                if (!picker) {
                    props.setEndDateErrorState('error');
                } else {
                    props.setEndDateErrorState('');
                }
                break;
            default:
        }
    }

    function onGroupSelection(selectedGroup) {
        props.setGroupErrorState('');
        setGroupSelectedList(selectedGroup);
        dispatch(change('onCallScheduleCreateRosterForm', 'shift', ''));
        dispatch(change('onCallScheduleCreateRosterForm', 'shiftName', ''));
        setInputValueShift('');
        setShiftSelectedList([]);
        if (selectedGroup.length > 0) {
            dispatch(change('onCallScheduleCreateRosterForm', 'group', selectedGroup[0].id));
            dispatch(change('onCallScheduleCreateRosterForm', 'companyId', selectedGroup[0].companyId));
            dispatch(change('onCallScheduleCreateRosterForm', 'companyName', selectedGroup[0].companyName));
            dispatch(getShiftListData({groupId:selectedGroup[0].id}, 0, 5000));
            setInputValueGroup(selectedGroup[0].label);
        }
        else {
            dispatch(change('onCallScheduleCreateRosterForm', 'group', ''));
            dispatch(change('onCallScheduleCreateRosterForm', 'companyId', ''));
            dispatch(change('onCallScheduleCreateRosterForm', 'companyName', ''));
        }
    }

    function setGroupErrorColor() {
        if (groupSelectedList.length === 0)
            props.setGroupErrorState('error');
        else
            props.setGroupErrorState('');
    }

    function onGroupInputChange(val) {
        setInputValueGroup(val);
    }

    function onCrossClickGroup() {
        dispatch(change('onCallScheduleCreateRosterForm', 'group', ''));
        dispatch(change('onCallScheduleCreateRosterForm', 'companyId', ''));
        dispatch(change('onCallScheduleCreateRosterForm', 'companyName', ''));
        dispatch(change('onCallScheduleCreateRosterForm', 'shift', ''));
        dispatch(change('onCallScheduleCreateRosterForm', 'shiftName', ''));
        setInputValueGroup('');
        setGroupSelectedList([]);
        setInputValueShift('');
        setShiftSelectedList([]);
        props.setGroupErrorState('error');
    }

    function onShiftSelection(selectedShift) {
        props.setShiftErrorState('');
        setShiftSelectedList(selectedShift);
        if (selectedShift.length > 0) {
            dispatch(change('onCallScheduleCreateRosterForm', 'shift', selectedShift[0].id));
            dispatch(change('onCallScheduleCreateRosterForm', 'shiftName', selectedShift[0].label));
            setInputValueShift(selectedShift[0].label);
        }
        else {
            dispatch(change('onCallScheduleCreateRosterForm', 'shift', ''));
            dispatch(change('onCallScheduleCreateRosterForm', 'shiftName', ''));
        }
    }

    function setShiftErrorColor() {
        if (shiftSelectedList.length === 0)
            props.setShiftErrorState('error');
        else
            props.setShiftErrorState('');
    }

    function onShiftInputChange(val) {
        setGroupSelectedList([]);
        setInputValueShift(val);
    }

    function onCrossClickShift() {
        dispatch(change('onCallScheduleCreateRosterForm', 'shift', ''));
        dispatch(change('onCallScheduleCreateRosterForm', 'shiftName', ''));
        setInputValueShift('');
        setShiftSelectedList([]);
        props.setShiftErrorState('error');
    }

    return (
        <Row>
            <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                    <Form.Label><span className="rStar"></span>{translator['Group']}</Form.Label>
                    <div className="minHghtdvTypehd">
                    <Field component={onCallDropdownPlusTypeahead}
                        errorColor={props.groupErrorState}
                        onSelection={onGroupSelection}
                        setErrorColor={setGroupErrorColor}
                        name="group"
                        id="group"
                        className="form-control"
                        options={groupTypeaheadOptions}
                        selectedOptions={groupSelectedList}
                        onInputChange={onGroupInputChange}
                        inputValue={inputValueGroup}
                        onCrossClick={onCrossClickGroup}
                    />
                    </div>
                </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                    <Form.Label><span className="rStar"></span>{translator['Shift']}</Form.Label>
                    <div className="minHghtdvTypehd">
                    <Field component={onCallDropdownPlusTypeahead}
                        errorColor={props.shiftErrorState}
                        onSelection={onShiftSelection}
                        setErrorColor={setShiftErrorColor}
                        name="shift"
                        id="shift"
                        className="form-control"
                        options={shiftTypeaheadOptions}
                        selectedOptions={shiftSelectedList}
                        onInputChange={onShiftInputChange}
                        inputValue={inputValueShift}
                        onCrossClick={onCrossClickShift}
                    />
                    </div>
                </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                    <Form.Label><span className="rStar"></span>{translator['From']}</Form.Label>
                    <div className="dateTimeSdiv addRosterCalender">
                        <Field
                            name="startDate"
                            component={_dateTimeFieldGeneral}
                            onBlur={(event, picker) => { validateField('startDate', event, picker) }}
                            className={`form-control dateTimePickerField ${props.startDateErrorState}`}
                            formType="onCallSchedule"
                            timeFormat="false"
                        />
                    </div>
                </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                    <Form.Label><span className="rStar"></span>{translator['To']}</Form.Label>
                    <div className="dateTimeSdiv addRosterCalender">
                        <Field
                            name="endDate"
                            component={_dateTimeFieldGeneral}
                            onBlur={(event, picker) => { validateField('endDate', event, picker) }}
                            className={`form-control dateTimePickerField ${props.endDateErrorState}`}
                            formType="onCallSchedule"
                            timeFormat="false"
                        />
                    </div>
                </Form.Group>
            </Col>
        </Row>
    )
}

export default reduxForm({
    form: 'onCallScheduleCreateRosterForm',
    destroyOnUnmount: true,
    enableReinitialize: false
})(FormComponent);
