
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import ListLoader from "../../common/loaders/ListLoader.js";
import Pagination from "react-js-pagination";
import { IoSearch } from "react-icons/io5";
import { reduxForm } from "redux-form";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { loadEscalationPlans } from "../../../actions/onCallSchedule/escalationAction.js";
import { useNavigate } from "react-router";
import "_Css/common/_table.scss";

const EscalationtListView = (props) => {
  const dispatch = useDispatch();
  const navigate= useNavigate();
  const translator = useSelector((state) => state.spcmReducer.tr);
  const escalationPlanList = useSelector((state) => state.escalationPlanList);
  const showLoader = useSelector((state) => state.showLoader.loading);
  const [rowIndex, setRowIndex] = useState(0);

  useEffect(() => {
    dispatch(loadEscalationPlans({ page: 0, size: 10 }));
  }, []);

  function onRowSelect(e, row, index) {
    // props.setSelectedItemFun({
    //   createdByFullname: row.createdByFullname,
    //   createdOn: row.createdOn,
    //   modifiedOn: row.modifiedOn,
    //   shiftName: row.shiftName,
    //   shiftId: row.shiftId,
    //   modifiedByFullname: row.modifiedByFullname,
    // });
    //props.showRightSideFun(true);
    setRowIndex(index);
  }

  const prepareFilters = () => {
    let filter = {};
    if (props.planSearchInput) {
      filter["escalationPlanName"] = props.planSearchInput;
    }
    if (props.escalationIdSearchInput) {
      filter["escalationPlanCode"] = props.escalationIdSearchInput;
    }
    if (props.createdonSearchInput) {
      filter["createdOn"] = props.createdonSearchInput.replaceAll("/", "-");
    }
    if (props.createdBySearchInput) {
      filter["createdByFullname"] = props.createdBySearchInput;
    }

    if (props.companySearchInput) {
      filter["companyName"] = props.companySearchInput;
    }

    if (props.statusSearchInput) {
      filter["status"] = props.statusSearchInput;
    }
    return filter;
  };

  const handleCallback = () => {};

  const handleKeyPress = (event) => {};

  const handleSearch = (e, picker = false) => {
    let filter = prepareFilters();
    filter["page"] = 0;
    filter["size"] = 10;
    props.setActivePage(0);
    props.setItemsPerPage(10);
    if (e && e.target && picker) {
      let date = [];
      date.push(
        moment(picker.startDate).format("MM-DD-YYYY"),
        moment(picker.endDate).format("MM-DD-YYYY")
      );
      if (e.target.name === "modifiedOn") {
        props.setRef("modifiedOn", picker);
        props.setCreatedonSearchInput(
          picker.startDate.format("MM/DD/YYYY") +
            "," +
            picker.endDate.format("MM/DD/YYYY")
        );
        filter["modifiedOn"] = date.join(",");
      }
    }
    if (Object.keys(filter).length > 0) {
      props.setIsFilterApplied(true);
      props.setFilters(filter);
    }
    dispatch(loadEscalationPlans(filter));
  };

  const handleChange = (e, searchValue) => {
    switch (searchValue) {
      case "plan":
        props.setPlanSearchInput(e.target.value);
        break;
      case "escalationId":
        props.setEscalationIdSearchInput(e.target.value);
        break;
      case "createdBy":
        props.setCreatedBySearchInput(e.target.value);
        break;
      case "company":
        props.setCompanySearchInput(e.target.value);
        break;
    }
  };

  const onPageSizeChange = (e) => {
    let filter = prepareFilters();
    filter["page"] = 0;
    filter["size"] = e.target.value;
    dispatch(loadEscalationPlans(filter));
    props.setActivePage(0);
    props.setItemsPerPage(e.target.value);
  };

  const onPageChange = (e) => {
    let filter = prepareFilters();
    filter["page"] = e - 1;
    filter["size"] = props.itemsPerPage;
    dispatch(loadEscalationPlans(filter));
    props.setActivePage(parseInt(filter["page"], 10));
  };

  const onDropDownChange = (e) => {
    let filter = prepareFilters();
    filter["page"] = 0;
    filter["size"] = 10;
    props.setActivePage(0);
    props.setItemsPerPage(10);
    if (e.target.name === "status") {
      props.setStatusSearchInput(e.target.value);
      if (e.target.value) {
        filter["status"] = e.target.value;
      } else {
        delete filter["status"];
      }
    }

    if (Object.keys(filter).length > 0) {
      props.setIsFilterApplied(true);
    }
    dispatch(loadEscalationPlans(filter));
  };

  function renderEscalationPlan(escalationPlanList) {
    if (escalationPlanList && escalationPlanList.length > 0) {
      return escalationPlanList.map((data, index) => {
        return (
          <tr
            key={index}
            className={
              `${props.rightDisplay && index == rowIndex ? "myActive" : ""}`
            }
            onClick={(e) => {onRowSelect(e, data, index); navigate('/escalation/levels/'+data?.id);}}
          >
            <td>{data.companyName}</td>
            <td className="hyperLinkWIB">{data.escalationPlanName}</td>
            <td>{data.escalationPlanCode ? data.escalationPlanCode : ""}</td>
            <td>{data.createdByFullname}</td>
            <td>{data.status}</td>
            <td>{data.createdOn}</td>
          </tr>
        );
      });
    }
  }

  return (
    <div>
      <div className="respondv">
        <div className={"tableRgtBor table-responsive"}>
          <Table
            striped
            bordered
            condensed
            hover
            className="tableView nowrapWhright"
            style={{ marginTop: "0px" }}
          >
            <thead>
              <tr>
                <th>
                  <div className="sortParArr">{translator["Company"]}</div>
                  <div className="colSearDv">
                    <Form.Control
                      className="colSearInp"
                      type="text"
                      value={
                        props.companySearchInput != ""
                          ? props.companySearchInput
                          : ""
                      }
                      placeholder={translator["Search here"]}
                      onKeyPress={(e) => {
                        if (e.charCode == "13") {
                          if (
                            props.companySearchInput != "" &&
                            props.companySearchInput?.trim().length > 3
                          )
                            handleSearch();
                        }
                      }}
                      onChange={(event) => {
                        handleChange(event, "company");
                      }}
                    />
                    {props.companySearchInput?.trim().length > 3 ? (
                      <a
                        title="search"
                        className="faicn"
                        href={void 0}
                        onClick={(event) => handleSearch()}
                      >
                        <IoSearch />
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </th>
                <th>
                  <div className="sortParArr">
                    {translator["Escalation Plan"]}
                  </div>
                  <div className="colSearDv">
                    <Form.Control
                      className="colSearInp"
                      type="text"
                      value={
                        props.planSearchInput != "" ? props.planSearchInput : ""
                      }
                      placeholder={translator["Search here"]}
                      onKeyPress={(e) => {
                        if (e.charCode == "13") {
                          if (
                            props.planSearchInput != "" &&
                            props.planSearchInput?.trim().length > 3
                          )
                            handleSearch();
                        }
                      }}
                      onChange={(event) => {
                        handleChange(event, "plan");
                      }}
                    />
                    {props.planSearchInput?.trim().length > 3 ? (
                      <a
                        title="search"
                        className="faicn"
                        href={void 0}
                        onClick={(event) => handleSearch()}
                      >
                        <IoSearch />
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </th>
                <th>
                  <div className="sortParArr">
                    {translator["Escalation Id"]}
                  </div>
                  <div className="colSearDv">
                    <Form.Control
                      className="colSearInp"
                      type="text"
                      value={
                        props.escalationIdSearchInput != ""
                          ? props.escalationIdSearchInput
                          : ""
                      }
                      placeholder={translator["Search here"]}
                      onKeyPress={(e) => {
                        if (e.charCode == "13") {
                          if (
                            props.escalationIdSearchInput != "" &&
                            props.escalationIdSearchInput?.trim().length > 3
                          )
                            handleSearch();
                        }
                      }}
                      onChange={(event) => {
                        handleChange(event, "escalationId");
                      }}
                    />
                    {props.escalationIdSearchInput?.trim().length > 3 ? (
                      <a
                        title="search"
                        className="faicn"
                        href={void 0}
                        onClick={(event) => handleSearch()}
                      >
                        <IoSearch />
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </th>

                <th>
                  <div className="sortParArr">{translator["Created By"]}</div>
                  <div className="colSearDv">
                    <Form.Control
                      className="colSearInp"
                      type="text"
                      value={
                        props.createdBySearchInput != ""
                          ? props.createdBySearchInput
                          : ""
                      }
                      placeholder={translator["Search here"]}
                      onKeyPress={(e) => {
                        if (e.charCode == "13") {
                          if (
                            props.createdBySearchInput != "" &&
                            props.createdBySearchInput?.trim().length > 3
                          )
                            handleSearch();
                        }
                      }}
                      onChange={(event) => {
                        handleChange(event, "createdBy");
                      }}
                    />
                    {props.createdBySearchInput?.trim().length > 3 ? (
                      <a
                        title="search"
                        className="faicn"
                        href={void 0}
                        onClick={(event) => handleSearch()}
                      >
                        <IoSearch />
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </th>
                <th>
                  <div className="sortParArr">{translator["Status"]}</div>
                  <div className="colSearDv">
                    <Form.Select
                      className="colSearInp"
                      type="text"
                      name="status"
                      onChange={(event) => {
                        onDropDownChange(event);
                      }}
                    >
                      <option value="">Select</option>

                      <option
                        value={"Active"}
                        selected={props.statusSearchInput === "Active"}
                      >
                        {"Active"}
                      </option>
                      <option
                        value={"Inactive"}
                        selected={props.statusSearchInput === "Inactive"}
                      >
                        {"Inactive"}
                      </option>
                    </Form.Select>
                  </div>
                </th>
                <th>
                  <div className="sortParArr">{translator["Created Date"]}</div>
                  <div className="colSearDv">
                    <DateRangePicker
                      initialSettings={{
                        autoUpdateInput: false,
                        maxDate: moment(),
                        autoApply: true,
                      }}
                      onApply={(e, picker) => handleSearch(e, picker)}
                      onCallback={handleCallback}
                    >
                      <input
                        type="text"
                        placeholder={translator["Search here"]}
                        readOnly
                        className="form-control"
                        name="modifiedOn"
                        onPaste={handleKeyPress}
                        onKeyDown={handleKeyPress}
                        value={props.createdonSearchInput}
                      />
                    </DateRangePicker>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {showLoader ? (
                <tr>
                  <td colSpan="12" style={{ textAlign: "center" }}>
                    <ListLoader />
                  </td>
                </tr>
              ) : escalationPlanList.data &&
                escalationPlanList.data.length === 0 ? (
                <tr>
                  <td colSpan="12" style={{ textAlign: "center" }}>
                    {translator["There is no matching data available"]}
                  </td>
                </tr>
              ) : (
                renderEscalationPlan(escalationPlanList.data)
              )}
            </tbody>
          </Table>
        </div>
      </div>
      {escalationPlanList.data && escalationPlanList.data.length > 0 ? (
        <div className="nBotPagina">
          <div className="nShow">
            <div className="margin-r-10" componentClass={Form.Label}>
              {translator["Show"]}:
            </div>
            <Form.Select
              value={props.itemsPerPage}
              onChange={(e) => {
                onPageSizeChange(e);
                props.showRightSideFun(false);
              }}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              {/* <option value="100">100</option> */}
            </Form.Select>
          </div>
          <nav aria-label="Pagination" className="display-inline-block">
            <Pagination
              prevPageText="Prev"
              nextPageText="Next"
              firstPageText={<i className="glyphicon glyphicon-menu-left" />}
              lastPageText={<i className="glyphicon glyphicon-menu-right" />}
              activePage={props.activePage + 1}
              itemsCountPerPage={props.itemsPerPage}
              totalItemsCount={
                escalationPlanList.itemsCount
                  ? escalationPlanList.itemsCount
                  : 1
              }
              pageRangeDisplayed={4}
              onChange={(e) => {
                onPageChange(e);
              }}
            />
          </nav>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  // shiftListData: state.shiftListData
});

export default reduxForm({
  form: "EscalationtListView",
})(connect(mapStateToProps)(EscalationtListView));
