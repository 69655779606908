
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { useNavigate } from "react-router-dom";
import InvestigationCreateIndex from "./InvestigationCreateIndex.js";

export default function InvestigationCreateMain()
{ 
	const navigate = useNavigate();
	return (<InvestigationCreateIndex navigate={navigate}/>)
}