
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { editDashboardData } from "../../actions/dashboardConfigration/dashboardConfigAction";

import { loadDashboardList } from "../../actions/dashboardConfigration/dashboardConfigAction.js";
import axios from "axios";
import {PiPencilSimpleBold} from "react-icons/pi";
import {IoClose} from "react-icons/io5";
import Cookies from "universal-cookie";
import { MyContext } from '_MyContext';
const cookies = new Cookies();
let cookiesData = cookies.get("gph");
if (cookiesData) cookiesData = cookiesData.replace("s:", "");
if (cookiesData)
  cookiesData = cookiesData.substring(0, cookiesData.lastIndexOf("."));
cookiesData = cookiesData.split("~");
let homepageLocation = cookiesData[0];
if (homepageLocation != "/home") homepageLocation = "/" + homepageLocation;
let userRolesStr = cookiesData[33] ? cookiesData[33] : [];

const DashboardListDetals = (props) => {
  const dispatch = useDispatch();
  const translator = useSelector((state) => state.spcmReducer.tr);
  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const editButton = useRef(null);

  useEffect(() => {
      context.keyDownHandler(closeButton.current,editButton.current,"","");
      context.newTabPrevent();
    }, []);

  let editAccess = userRolesStr.split(',').includes('1') || userRolesStr.split(',').includes('55') || userRolesStr.split(',').includes('67')

  // useEffect(() => {
    
  // }, [loadDashboardList]);

  return (
    <div className="margin-b-20">
      <div className="rPageHeadActBtn margin-r-5" style={{ marginTop: "-3px" }}>
        <ul>
          {editAccess ? (
            <li>
              <Link to={"/editDashboardConfiguration/" + props.id} title={translator["Edit"]} className="ctrlKeyPrevent" ref={editButton}><PiPencilSimpleBold/>
              </Link>
            </li>
          ) : (
            ""
          )}

          <li> 
            <Link to="javascript:void(0)" 
              title={translator["Minimize the details panel"]}
              bsClass=""
              onClick={() => {
                props.showRightSide(false);
              }}
              ref={closeButton}
              className="ctrlKeyPrevent"
            >
              <IoClose/>
            </Link>
          </li>
        </ul>
      </div>
      <div className="rPageHeading">
        <div className="offNam margin-t-5 margin-b-5">{props.name}</div>
      </div>
      <div className="rBoxGap">
        <ListGroup variant="flush" bsClass="" className="">
          <ListGroup.Item
            bsClass=""
            className="rwTable"
            style={{ "border-top": "none" }}
          >
            <span className="rwKey">{translator["Name"]} </span>{" "}
            <span className="rwVal1">{props.name}</span>
          </ListGroup.Item>
          <ListGroup.Item
            bsClass=""
            className="rwTable"
            style={{ "border-top": "none" }}
          >
            <span className="rwKey">{translator["Classification"]} </span>{" "}
            <span className="rwVal1">{props.classification}</span>
          </ListGroup.Item>
          <ListGroup.Item bsClass="" className="rwTable">
            <span className="rwKey">{translator["Dashboard ID"]} </span>{" "}
            <span className="rwVal1">{props.dashboardID}</span>
          </ListGroup.Item>
          <ListGroup.Item bsClass="" className="rwTable">
            <span className="rwKey">{translator["Report Type"]} </span>{" "}
            <span className="rwVal1">{props.dashboardType}</span>
          </ListGroup.Item>
          <ListGroup.Item bsClass="" className="rwTable">
            <span className="rwKey">{translator["Is Active"]} </span>{" "}
            <span className="rwVal">{props.active}</span>
          </ListGroup.Item>
          <ListGroup.Item bsClass="" className="rwTable">
            <span className="rwKey">{translator["Roles"]} </span>{" "}
            <span className="rwVal">{props.roles}</span>
          </ListGroup.Item>
          <ListGroup.Item bsClass="" className="rwTable">
            <span className="rwKey">{translator["Report Filters"]} </span>{" "}
            <span className="rwVal1">{props.reportFilters}</span>
          </ListGroup.Item>
          <ListGroup.Item bsClass="" className="rwTable">
            <span className="rwKey">{translator["Master Roles"]} </span>{" "}
            <span className="rwVal">{props.masterRoles}</span>
          </ListGroup.Item>
          <ListGroup.Item bsClass="" className="rwTable">
            <span className="rwKey">{translator["Modified By"]} </span>{" "}
            <span className="rwVal1">{props.modifiedBy}</span>
          </ListGroup.Item>
          <ListGroup.Item bsClass="" className="rwTable">
            <span className="rwKey">{translator["Modified Date"]} </span>{" "}
            <span className="rwVal1">{props.modifiedDate}</span>
          </ListGroup.Item>
        </ListGroup>
      </div>
    </div>
  );
};

export default DashboardListDetals;
