
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const approvalList = (state = [], action) => {
    switch (action.type) {
        case 'APPROVAL_LIST_SUCCESS':
            return action.approvalList.data;
           // return tempApprovalList;
        case 'APPROVAL_LIST_FAILURE':
            return action.errorResponse;
        default:
            return state;
    }
}

export const adhocApproversList = (state = [], action) => {
    switch (action.type) {
        case 'ADHOC_APPROVERS_LIST':
            return action.approversList.data;
        default:
            return state;
    }
}