/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const NotificationReducer = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: window.location.origin,
  }),
  reducerPath: "notificationReducer",
  endpoints: (builder) => ({
    getNotifPopupList: builder.query({
      query: ({ email = "", type = "direct" }) => {
        let query = {
          emailId: email,
          type,
          page: 1,
          limit: 10,
          sortBy: "",
          order: "DESC",
        };
        return {
          url: "/api/notification/NotifList",
          method: "GET",
          headers: {
            query: JSON.stringify(query),
          },
        };
      },
      transformResponse: (response) => {
        let data = response?.data?.data || [];
        return data;
      },
      keepUnusedDataFor: 0,
    }),
  }),
});

export const { useGetNotifPopupListQuery } = NotificationReducer;
export default NotificationReducer.reducer;
