
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import moment from 'moment';
import { Row, Col, FormGroup, FormControl, ControlLabel, ButtonToolbar, Button, ListGroup, ListGroupItem, Alert, Radio, InputGroup, Image } from 'react-bootstrap';
import OnClickOutside from 'react-onclickoutside';
import { DateField, DatePicker, TransitionView, Calendar } from 'react-date-picker';
import { loadSlaCompany, loadSlaModule, loadSlaService, loadSlaType, loadSlaTarget, loadSlaWrkSchedule, loadSlaHolSchedule, loadSlaDefEditDetails, loadSlaStatus } from '../../../../actions/sla/lovAction';
import { loadCICompany } from '../../../../actions/cmdb/lovAction';
//import { loadSlaCompany,loadSlaModule,loadSlaStatus,loadSlaService,loadSlaType,loadSlaTarget,loadSlaWrkSchedule,loadSlaHolSchedule,loadSlaDefEditDetails } from '../../../actions/sla/lovAction';
import { connect } from 'react-redux';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { loadCategoryDropdown, loadStatusDropdown, editCategoryImages, loadEditCategoryDetails, getAssociatedCompanies,CategoriesByCompanyId } from '../../../../actions/categoryBoard/quickViewAction.js';
let sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
import swal from 'sweetalert';
// import dummyImage from "../../../../";
import dummyImage from "../../../../images/service-icons/service-dummy.png"
import { Field, reduxForm, formValueSelector, change, SubmissionError, reset } from 'redux-form';
// import Select from 'react-select';
import {GLOBAL} from '_Globals';
import _ from 'lodash';
import { _inputField, field_file,_checkbox,itemQuantityField } from '../../../../components/common/ReduxFormFields/CategoryBoard/formFields';
// import { disabled } from 'react-widgets/lib/util/propTypes';
import Form from 'react-bootstrap/Form';
import { TypeaheadExampleSingleSelect } from '../../../common/formFields.js';
import { businessFunctionByCompanyId, resetBusinessFunctionList } from '../../../../actions/spcmActions.js';

const validate = (values, props) => {
	console.log(values);
	const errors = {};
	if (values.CATEGORY_NAME) {
		if (values.CATEGORY_NAME.trim() == "") {
			errors.CATEGORY_NAME = 'Enter CategoryName';
		}
	}
	else if (!values.CATEGORY_NAME)
		errors.CATEGORY_NAME = "Required";

	if (values.SUB_CATEGORY_NAME) {
		if (values.SUB_CATEGORY_NAME.trim() == "") {
			errors.SUB_CATEGORY_NAME = 'Enter SubCategoryName';
		}
	}
	else if (!values.SUB_CATEGORY_NAME)
		errors.SUB_CATEGORY_NAME = "Required";
	
	if (isNaN(values.CATEGORY_SEQUENCE) || values.CATEGORY_SEQUENCE=="" || !(parseInt((values.CATEGORY_SEQUENCE),10) >= 1 && parseInt((values.CATEGORY_SEQUENCE),10) <= 500)) {
		errors.CATEGORY_SEQUENCE = "Please enter values between 1 and 500";
	}

		// if (!values.CATEGORY_ID)
	// 	errors.CATEGORY_ID = "Required";

	console.log(errors);
	return errors;
}

class EditCategoryForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			createUsing: 'existing',
			searchResultsDiv: false,
			disableImageUpload1: false,
			disableImageUpload2: false,
			compAssociationCheck: 'N',
			category_pinned:"false",
			typedBusinessFn: ""
		};
		this.onCreateUsingSelect = this.onCreateUsingSelect.bind(this);
		this.renderCategory = this.renderCategory.bind(this);
		this.fileUpload1 = this.fileUpload1.bind(this);
		this.fileUpload2 = this.fileUpload2.bind(this);
		this.countCharacters = this.countCharacters.bind(this);
		this.onCrossClickBusinessFn = this.onCrossClickBusinessFn.bind(this);
		this.businessFnInputChange = this.businessFnInputChange.bind(this);
		this.setBusinessFn = this.setBusinessFn.bind(this);	
	}

	componentWillMount() {
		this.setState({category_pinned:this.props.catPinned});
		this.props.loadStatusDropdown();
	}

	componentWillUnmount() {
		this.props.resetBusinessFunctionList();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.categoryEditDetails !== this.props.categoryEditDetails) {
			this.props.CategoriesByCompanyId(nextProps.categoryEditDetails.COMAPNY_ID);
			this.props.businessFunctionByCompanyId(nextProps.categoryEditDetails.COMAPNY_ID);
			this.setState({ compAssociationCheck: nextProps.categoryEditDetails.COMPANY_ASSOCIATION });
			this.props.setSelectedBusinessFn([{id: nextProps.categoryEditDetails.BUSINESS_FUNCTION_ID, businessFunctionName: nextProps.categoryEditDetails.BUSINESS_FUNCTION_NAME }]);
			this.props.dispatch(change("EditCategoryForm", "BUSINESS_FUNCTION_ID", nextProps.categoryEditDetails.BUSINESS_FUNCTION_ID));
			this.props.dispatch(change("EditCategoryForm", "BUSINESS_FUNCTION_NAME", nextProps.categoryEditDetails.BUSINESS_FUNCTION_NAME));
		}
	}

	businessFnInputChange(value) {
		this.setState({ typedBusinessFn: value });
		this.props.setSelectedBusinessFn([]);
		this.setBusinessFn([]);
	}
	
	setBusinessFn(selectedBusinessFn) {
		if(selectedBusinessFn.length > 0) {
		  this.props.setSelectedBusinessFn(selectedBusinessFn);
		  this.props.dispatch(change("EditCategoryForm", "BUSINESS_FUNCTION_ID", selectedBusinessFn[0].id));
		  this.props.dispatch(change("EditCategoryForm", "BUSINESS_FUNCTION_NAME", selectedBusinessFn[0].businessFunctionName));
		} else {
		  this.props.setSelectedBusinessFn([]);
		  this.props.dispatch(change("EditCategoryForm", "BUSINESS_FUNCTION_ID", ''));
		  this.props.dispatch(change("EditCategoryForm", "BUSINESS_FUNCTION_NAME", ''));
		}
	}
	
	onCrossClickBusinessFn() {
		this.setState({ typedBusinessFn: '' });
		this.setBusinessFn([]);
		this.props.dispatch(change("EditCategoryForm", "BUSINESS_FUNCTION_ID", ''));
		this.props.dispatch(change("EditCategoryForm", "BUSINESS_FUNCTION_NAME", ''));
	}

	// componentWillReceiveProps(nextProps) {
	// 	if (nextProps.categoryEditDetails !== this.props.categoryEditDetails) {
	// 		if(nextProps.categoryEditDetails && nextProps.categoryEditDetails.COMPANY_ASSOCIATION)
	// 			this.setState({ compAssociationCheck: nextProps.categoryEditDetails.COMPANY_ASSOCIATION });
	// 		else
	// 			this.setState({ compAssociationCheck: 'N' });
	// 	}
	// }

	searchResults(event) {
		this.setState({ searchResultsDiv: true });
	}

	callbackFun(id) {
		this.setState({ searchResultsDiv: id });
	}

	

	


	fileUpload1(values) {

		console.log('values==', values);
		let obj={};
		obj.category_name = values.CATEGORY_NAME;
		obj.sub_category_name = values.SUB_CATEGORY_NAME;
		obj.status = values.STATUS;
		obj.status_name = values.STATUS_NAME;
		obj.isvisible = 'Y';
		obj.company_name = values.COMAPNY_NAME;
		obj.company_id =values.COMAPNY_ID;
		obj.category_pinned = values.CATEGORY_PINNED;
		obj.category_sequence = values.CATEGORY_SEQUENCE;
		console.log('obj',obj);
		// this.setState({ disableImageUpload1: true });
		let categoryIcon;
		if (values.CATEGORY_ICON && values.CATEGORY_ICON[0] && values.CATEGORY_ICON[0].size > 20000){
			swal({
				text: "Please attach the file in the given format",
				button: this.props.translator['OK'],
			});
			return false;
		}
		else if (values.CATEGORY_ICON && values.CATEGORY_ICON[0]){
			categoryIcon = values.CATEGORY_ICON[0].name
		}
		else{
			categoryIcon = values.CATEGORY_ICON
		}
		console.log('categoryIcon==', values.CATEGORY_ICON[0].size);
		if (categoryIcon && categoryIcon.length != 0){
			if (categoryIcon &&
				(categoryIcon.indexOf(".jpg") <= "-1" &&
					categoryIcon.indexOf(".JPG") <= "-1" &&
					categoryIcon.indexOf(".jpeg") <= "-1" &&
					categoryIcon.indexOf(".JPEG") <= "-1" &&
					categoryIcon.indexOf(".png") <= "-1" &&
					categoryIcon.indexOf(".PNG") <= "-1" &&
					categoryIcon.indexOf(".gif") <= "-1" &&
					categoryIcon.indexOf(".GIF") <= "-1" &&
					categoryIcon.indexOf(".tif") <= "-1" &&
					categoryIcon.indexOf(".TIF") <= "-1")			
			) {
				swal({
					text: "Please attach the file in the given format",
					button: this.props.translator['OK'],
				});
				return false;
			}
		}
			else {
			swal({
				text: this.props.translator['file not attached'],
				button: this.props.translator['OK'],
			});
			return false;
		}
		
		return sleep(1000) // simulate server latency

			.then(() => {
				let formData = new FormData();
				formData.append('data', JSON.stringify(obj));
				let flag = 0;
				if (values.CATEGORY_ICON && values.CATEGORY_ICON !== this.props.categoryEditDetails.CATEGORY_ICON) {
					formData.append('category_icon', values.CATEGORY_ICON[0]);
					flag = 1;
				}
				console.log('formData====', formData);
				if (flag == 1) {
					this.props.editCategoryImages(this.props.categoryId, formData, (message) => {
						if (message === 'success')
						swal({
							text: this.props.translator['File uploaded successfully'],
							button: this.props.translator['OK'],
						});
							this.props.loadEditCategoryDetails(this.props.categoryId);
						this.setState({ disableImageUpload1: false });
					});
				}
				else {
					swal({
						text: this.props.translator['file not attached'],
						button: this.props.translator['OK'],
					});
					this.setState({ disableImageUpload1: false });
				}

			});
	}

	fileUpload2(values) {

		// console.log('values==', values);
		let obj={};
		obj.category_name = values.CATEGORY_NAME;
		obj.sub_category_name = values.SUB_CATEGORY_NAME;
		obj.status = values.STATUS;
		obj.status_name = values.STATUS_NAME;
		obj.isvisible = 'Y';
		obj.company_name = values.COMAPNY_NAME;
		obj.company_id =values.COMAPNY_ID;
		obj.category_pinned = values.CATEGORY_PINNED;
		obj.category_sequence = values.CATEGORY_SEQUENCE;
		console.log('obj',obj);
		// this.setState({ disableImageUpload2: true });
		let subCategoryIcon ;
		if (values.SUB_CATEGORY_ICON && values.SUB_CATEGORY_ICON[0] && values.SUB_CATEGORY_ICON[0].size > 20000) {
			swal({
				text: "Please attach the file in the given format",
				button: this.props.translator['OK'],
			});
			return false;
		}
		else if (values.SUB_CATEGORY_ICON && values.SUB_CATEGORY_ICON[0]) {
			subCategoryIcon = values.SUB_CATEGORY_ICON[0].name
		} else {
			subCategoryIcon = values.SUB_CATEGORY_ICON
		}
		if (subCategoryIcon && subCategoryIcon.length != 0) {
			if ( subCategoryIcon && 
				(subCategoryIcon.indexOf(".jpg") <= "-1" &&
				subCategoryIcon.indexOf(".JPG") <= "-1" &&
				subCategoryIcon.indexOf(".jpeg") <= "-1" &&
				subCategoryIcon.indexOf(".JPEG") <= "-1" &&
				subCategoryIcon.indexOf(".png") <= "-1" &&
				subCategoryIcon.indexOf(".PNG") <= "-1" &&
				subCategoryIcon.indexOf(".gif") <= "-1" &&
				subCategoryIcon.indexOf(".GIF") <= "-1" &&
				subCategoryIcon.indexOf(".tif") <= "-1" &&
				subCategoryIcon.indexOf(".TIF") <= "-1")
			){
			
			swal({
				text: "please attach the file of the given format",
				button: this.props.translator['OK'],
			});
			return false;
		}
	}else {
			swal({
				text: this.props.translator['file not attached'],
				button: this.props.translator['OK'],
			});
			return false;
		}
		return sleep(1000) // simulate server latency

			.then(() => {
				let formData = new FormData();
				formData.append('data', JSON.stringify(obj));
				let flag = 0;
				if (values.SUB_CATEGORY_ICON && values.SUB_CATEGORY_ICON !== this.props.categoryEditDetails.SUB_CATEGORY_ICON) {
					formData.append('sub_category_icon', values.SUB_CATEGORY_ICON[0]);
					flag = 1;
				}
				console.log('formData====', formData);
				if (flag == 1) {
					this.props.editCategoryImages(this.props.categoryId, formData, (message) => {
						if (message === 'success')
						swal({
							text: this.props.translator['File uploaded successfully'],
							button: this.props.translator['OK'],
						});
							this.props.loadEditCategoryDetails(this.props.categoryId);
						this.setState({ disableImageUpload2: false });
					});
				}
				else {
					swal({
						text: this.props.translator['file not attached'],
						button: this.props.translator['OK'],
					});
					this.setState({ disableImageUpload2: false });
				}

			});
	}

	onCreateUsingSelect(event) {
		
		if (event.target.value === 'new') {
			this.props.dispatch(change("EditCategoryForm", "CATEGORY_ID", ''));
			this.props.dispatch(change("EditCategoryForm", "CATEGORY_NAME", ''));
			this.props.dispatch(change("EditCategoryForm", "SUB_CATEGORY_NAME", ''));

		}
		else {
			this.props.dispatch(change("EditCategoryForm", "CATEGORY_NAME", ''));
			this.props.dispatch(change("EditCategoryForm", "SUB_CATEGORY_NAME", ''));

		}
		this.setState({ createUsing: event.target.value });
	}

	renderCategory({ input, className, disabledValue, meta }) {
		console.log(meta);
		let categoryDataOptions = this.props.CategoriesByCompanyIdDropdown;
	//	console.log("catdata")
		//console.log(categoryDataOptions)
		if (categoryDataOptions.length == 0 || categoryDataOptions.length == undefined) {
			return (
				<div>
					<select {...input} className={(meta.touched && meta.error) ? className + " error" : className} disabled={disabledValue}>
						<option value="">Select</option>
					</select>
				</div>
			);
		}
		return (
			<div>
				<select {...input} className={(meta.touched && meta.error) ? className + " error" : className} disabled={disabledValue}>
					<option value="">Select</option>
					{this.renderDropDownOptions(categoryDataOptions)}
				</select>
			</div>
		);
	}

	// renderDropDownOptions(options) {
	// 	return options.map((option, index) => <option value={option.CATEGORY_ID} key={index} data-status={option.STATUS} data-status_name={option.STATUS_NAME} data-association={option.COMPANY_ASSOCIATION}>{option.CATEGORY_NAME}</option>);
	// }

	renderDropDownOptions(options) {
		return options.map((option, index) => <option value={option.CATEGORY_NAME} key={index} >{option.CATEGORY_NAME}</option>);
	}

	renderStatus(statusData) {
		//console.log(classData)
		if (statusData.length == 0 || statusData.length == undefined) {
			return (
				null
			);
		}
		return statusData.map((objectData) => {
			if (objectData.field1Key != "Active") {
				return (
					<option value={objectData.STATUS_ID}>{objectData.STATUS_NAME}</option>
				);
			}
		});
	}

	changeCategory(event) {
		// this.props.getAssociatedCompanies(event.target.value);
		// this.props.dispatch(change("EditCategoryForm", "STATUS", event.target[event.target.selectedIndex].getAttribute('data-status')));
		// this.props.dispatch(change("EditCategoryForm", "STATUS_NAME", event.target[event.target.selectedIndex].getAttribute('data-status_name')));
		// this.props.dispatch(change("EditCategoryForm", "CATEGORY_NAME", event.target[event.target.selectedIndex].text));
		// this.setState({ compAssociationCheck: event.target[event.target.selectedIndex].getAttribute('data-association') });
	}

	countCharacters(e) {
		if (e.target.value.length > 100) {
			e.preventDefault();
		}
	}

	render() {
    //  console.log("this.props.initialValues++++++++", this.props.initialValues);
    //  console.log("this.props.categoryEditDetails++++++++", this.props.categoryEditDetails);
		console.log('Options --- ', this.props.parentFunctionByCompanyIdDropdown);
    let { error, handleSubmit, pristine, reset, submitting } = this.props;

    return (

    	<div>
    		{error == undefined || error == null || error == "" ? null : <Alert variant="danger" className="errorMessageSec"><p>{error}</p></Alert>}
    		<div className="borderForm bFormDiv">
    			<form>
    				<Row>
						<Col md={6}>
							<Form.Group className='form-group' >
								<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Company']}</Form.Label>
								<Field name="COMAPNY_NAME" className="form-control" component={_inputField} onpaste={(e) => { this.countCharacters(e) }} onChange={(e) => { this.countCharacters(e) } } disabled >
								</Field>
							</Form.Group>
						</Col>
						<Col md={6}>
							<Form.Group className='form-group'>
								<Form.Label bsClass="">
									<span className="rStar"></span>
									{this.props.translator["Business Function"]}
								</Form.Label>
								<div className='dvTypehd'>
									<Field
										id="BusinessFnField"
										name="BUSINESS_FUNCTION"
										component={TypeaheadExampleSingleSelect}
										className="form-control"
										options={this.props.parentFunctionByCompanyIdDropdown}
										selectedOptions={this.props.selectedBusinessFn}
										onSelection={this.setBusinessFn}
										errorClass={this.props.businessFnError}
										diableStatus={false}
										onInputChange={this.businessFnInputChange}
										onCrossClick={this.onCrossClickBusinessFn}
										typedValue={this.state.typedBusinessFn}
										setErrorColor={this.props.setBusinessFnErrorColor}
										labelKey={"businessFunctionName"}
									/>
								</div>
							</Form.Group>
						</Col>
    				</Row>
					<Row>
						<Col md={6}>
    						<Form.Group className='form-group radioDiv' onChange={this.onCreateUsingSelect.bind(this)}>
    							<Form.Label bsClass="">{this.props.translator['Update']}</Form.Label>
    							<div>
    								<Form.Check type="radio" inline value="new" name="createUsing" label= {this.props.translator['New Category']} checked={this.state.createUsing === 'new' ? true : false}></Form.Check>
    								<Form.Check type="radio" inline value="existing" name="createUsing" label= {this.props.translator['Existing Category']} checked={this.state.createUsing === 'existing' ? true : false}></Form.Check>
    							</div>
    						</Form.Group>
    					</Col>
					</Row>
    				<Row>
    					{this.state.createUsing === 'new' ?
    						<Col md={6}>
    							<Form.Group className='form-group' >
    								<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Category']}</Form.Label>
    								<Field name="CATEGORY_NAME" className="form-control" component={_inputField} onpaste={(e) => { this.countCharacters(e) }} onChange={(e) => { this.countCharacters(e) }} >
    								</Field>
    								<span className="f-size-10">{this.props.translator['Max Length in CB']}</span>
    							</Form.Group>
    						</Col>
    						:
    						<Col md={6}>
    							<Form.Group className='form-group' >
    								<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Category']}</Form.Label>
    								<Field name="CATEGORY_NAME" className="form-control" component={this.renderCategory} onChange={(event) => { this.changeCategory(event); }}>
    								</Field>
    							</Form.Group>
    						</Col>
    					}
    					<Col md={6}>
    						<Form.Group className='form-group' >
    							<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Sub Category']}</Form.Label>
    							<Field name="SUB_CATEGORY_NAME" className="form-control" component={_inputField} onpaste={(e) => { this.countCharacters(e) }} onChange={(e) => { this.countCharacters(e) }} >
    							</Field>
    							<span className="f-size-10">{this.props.translator['Max Length in CB']}</span>
    						</Form.Group>
    					</Col>
    				</Row>
    				<Row>
            			<Col md={6}>
						<Form.Label className='margin-r-10'>{this.props.translator["Want to pin this category?"]}</Form.Label>
    					<Field component={_checkbox} className='form-control' name='CATEGORY_PINNED'
    										onChange={(e) => {
    											if (e.target.checked) {
    												this.setState({ category_pinned: "true" })
    											} else
    												this.setState({ category_pinned: "false" })
    										}}
										
    										checked={this.state.category_pinned=="true"} />
    									</Col>
            			<Col md={6}>
    											<Form.Label style={{"display":"inline-block","margin-right":"15px"}} bsClass="">{this.props.translator["Category Sequence"]}</Form.Label>
            			    <div className="catSeqDv"><Field component={itemQuantityField}  min={1} max={500}
            			      name="CATEGORY_SEQUENCE"
            			      className='form-control' onChange={(e) => {
            			        this.setState({ minItemQuantity: e.target.value }); }} /></div>
            			</Col>
          			</Row>
					<Row>
						<Col md={6} xs={12}>
						<Row className="margin-t-10">
    							<Col md={12} xs={8} style={{"marginBottom":"5px"}}>
    								<Form.Group className='form-group priceForm-group withImgbox'>
    									<Form.Label bsClass="">{this.props.translator['Category Image']}</Form.Label>
    									<InputGroup>
    										<Field className="form-control" component={field_file} type="file" name="CATEGORY_ICON" />
    										<InputGroup.Text><Image src={this.props.categoryEditDetails && this.props.categoryEditDetails.CATEGORY_ICON ? this.props.categoryEditDetails.categoryImageTempURL : dummyImage} /></InputGroup.Text>
    									</InputGroup>
    									<div style={{"fontSize":"12px"}}>{this.props.translator['(Accepted file types: .jpg, .jpeg, .png, .tif and .gif  Preferred size is 200px X 200px and limit is 20 KB)']}</div>
    								</Form.Group>
    							</Col>
								<Col md={12} xs={4}>
    								<ButtonToolbar className="toolbarBtnStyle margin-b-10 margin-xs-t-15">
    									<Button className='rgSidrkBtn ctBrdUploadBtn' bsSize="xsmall" bsStyle="primary" onClick={handleSubmit(this.fileUpload1)} disabled={this.state.disableImageUpload1}>{this.props.translator['Upload']}</Button>
    								</ButtonToolbar>
    							</Col>    					
    						</Row>
						</Col>
						<Col md={6} xs={12}>
							<Row className="margin-t-10">
								<Col md={12} xs={8} style={{"marginBottom":"5px"}}>
    								<Form.Group className='form-group priceForm-group withImgbox'>
    									<Form.Label bsClass="">{this.props.translator['Sub Category Image']}</Form.Label>
    									<InputGroup>
    										<Field className="form-control" component={field_file} type="file" name="SUB_CATEGORY_ICON" />
    										<InputGroup.Text><Image src={this.props.categoryEditDetails && this.props.categoryEditDetails.SUB_CATEGORY_ICON ? this.props.categoryEditDetails.subCategoryImageTempURL : dummyImage} /></InputGroup.Text>
    									</InputGroup>
    									<div style={{"fontSize":"12px"}}>{this.props.translator['(Accepted file types: .jpg, .jpeg, .png, .tif and .gif  Preferred size is 200px X 200px and limit is 20 KB)']}</div>
    								</Form.Group>
    							</Col>    					
    							<Col md={12} xs={4}>
    								<ButtonToolbar className="toolbarBtnStyle margin-b-10 margin-xs-t-15">
    									<Button className='rgSidrkBtn ctBrdUploadBtn' bsSize="xsmall" bsStyle="primary" onClick={handleSubmit(this.fileUpload2)} disabled={this.state.disableImageUpload2}>{this.props.translator['Upload']}</Button>
    								</ButtonToolbar>
    							</Col>
    						</Row>
						</Col>
					</Row>
    				<Row>
    					<Col md={6}>
    						<Form.Group className='form-group' >
    							<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Status']}</Form.Label>
    							<Field name="STATUS" className="form-control" component="select" disabled={this.state.compAssociationCheck === 'Y' ? false : true} onChange={(event) => { this.props.dispatch(change("EditCategoryForm", "STATUS_NAME", event.target[event.target.selectedIndex].text)); }}>
    								{this.renderStatus(this.props.statusDropdown)}
    							</Field>
    						</Form.Group> 
    					</Col>
    				</Row>
    			</form>
    		</div>
    	</div>
    );
  }
}

EditCategoryForm = reduxForm({
	form: 'EditCategoryForm',
	destroyOnUnmount: false,
	enableReinitialize: true,
	validate
})(EditCategoryForm);

export function mapStateToProps({ categoryEditDetails, categoryDropdown, statusDropdown,CategoriesByCompanyIdDropdown, parentFunctionByCompanyIdDropdown }) {
	return {
		initialValues: categoryEditDetails[0],
		categoryEditDetails: categoryEditDetails[0],
		categoryDropdown,
		statusDropdown,
		CategoriesByCompanyIdDropdown,
		parentFunctionByCompanyIdDropdown
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ loadCategoryDropdown, loadStatusDropdown, editCategoryImages, loadEditCategoryDetails, getAssociatedCompanies,CategoriesByCompanyId, businessFunctionByCompanyId, resetBusinessFunctionList }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCategoryForm);