
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from 'react';
import { Table, Form, ButtonToolbar, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { TypeaheadExampleSingleSelect, TypeaheadandDropdown, _inputField } from '../../../../common/formFields';
import axios from "axios";
import { Field, change, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { disassociateGroupRow, getAssociatedCompanyGroupList, getAssocitedCompanyDropDownValue } from '../../../../../actions/foundation/companyAction';
import Swal from 'sweetalert2';
import { usePreviousValue } from '../../../../../helpers/usePreviousValue';
import TableComponent from '../../../../common/ReactTable/TableComponent';
import Cookies from "universal-cookie";
const cookies = new Cookies();
let app_url = cookies.get("gph");
if (app_url) app_url = app_url.replace("s:", "");
if (app_url) app_url = app_url.substring(0, app_url.lastIndexOf("."));
app_url = app_url.split("~");
app_url = app_url[18];

const GroupList = (props) => {
    const [groupData, setGroupData] = useState([])
    const [assoCompanyList, setAssoCompanyList] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [typeError, setTypeError] = useState("");
    const [groupError, setGroupError] = useState("");

    const companyList = useSelector((state) => state.associatedCompaniesDropdownValue);
    const groupDataList = useSelector((state) => state.associateGroupDropdown);
    const disassociatGroup = useSelector((state) => state.disassociatedGroup)
    const dispatch = useDispatch();

    const previousValue = usePreviousValue(props)

    useEffect(() => {
        if (props.companyValue.id !== undefined) {
            dispatch(getAssocitedCompanyDropDownValue(props.companyValue.id))
        }    
    }, [props.companyValue.id])
    
    useEffect(() => {
        if (selectedCompany.length > 0) {
            dispatch(getAssociatedCompanyGroupList(props.companyValue.id,selectedCompany[0].id))
        }    
    }, [selectedCompany])
    useEffect(() =>
    {
      if(companyList)
      {
        setAssoCompanyList(companyList);
        setSelectedCompany([]);
      }else{
        setAssoCompanyList([]);
        setSelectedCompany([]);
      }
      
    }, [companyList])

    useEffect(() =>
    {

      if(groupDataList && groupDataList.length > 0)
      {
        setGroupData(groupDataList)
      }
      
    }, [groupDataList])

    useEffect(() => {
        if (Object.keys(props.companyValue).length > 0) {
          if ((props?.companyValue?.id && props?.companyValue?.id !== previousValue?.companyValue?.id) || disassociatGroup === "1 rows deleted") {
            axios.get("/api/viewAssociatedGroups/" +props.companyValue.id +"/" +"All")
              .then((resp) => {
                props.setAssociatedGroupValue(resp.data);
                dispatch({ type: "ASSOCIATED_GROUP_DELETED", payload: "" });
              });
          }
        }
    }, [props.companyValue, disassociatGroup])

    useEffect(() => {
        if (selectedCompany.length > 0) {
            dispatch(getAssociatedCompanyGroupList(props.companyValue.id, selectedCompany[0].id));
        }
    },[selectedCompany])
    const saveAssociateGroups = () => {

        if (selectedCompany.length === 0) {
            setTypeError("error")
        }
        
        if (selectedGroup.length === 0) {
             setGroupError("error")
         }

         let groups = []
         let payload = {}
         selectedGroup.forEach((values) => {
           let val = {
              id: props.companyValue.id,
              group_Id: values.id,
             }
             groups.push(val);
           })
        
        // const payload = {
        //     companyGroupList :[{
        //         id: props.companyValue.id,
        //         group_Id: selectedGroup[0].id,
        //     }]
        // }
        payload = {
            companyGroupList: groups
        }

        axios
          .post("/api/saveAssociatedGroups", payload)
          .then((response) => {
            axios.get("/api/viewAssociatedGroups/"+props.companyValue.id+"/"+'All')
              .then((resp) => {
                    if (resp.status == 200) {
                        setSelectedCompany([]);
                        setSelectedGroup([]);
                        setGroupData([]);
                    }
                props.setAssociatedGroupValue(resp.data);
            })
          })
    };
    // const CellGroupInfo = ({row}) => {
    //         return (
    //           <Link className="hyperLinkWIB" title={props.tr['Open in new tab']} onClick={() => {
    //             let newWindow = window.open();
    //             newWindow.opener = null;
    //             newWindow.location =  app_url + "/home?editGroup=" + row.group_Id;
    //           }}>
    //             {row.group_Name}
    //           </Link>
    //         )
    // };

    const CellName = (props) => {
        let item = props.row;
        return (
        <div className="text-c">
            <i title="Disassociate" onClick={()=>{
            disassociateGroup(item)
            }} className="fa fa-chain-broken"></i>
        </div>
        )
    };
    
    const disassociateGroup = (item) => {
        let payload =
        {
            companyGroupList: [{
                id: props.companyValue.id,
                group_Id: item.group_Id
            }]
        }
        Swal.fire({
            title: props.tr["Are you sure you want to disassociate group?"],
            width: 550,
            padding: '15px',
            showDenyButton: true,
            confirmButtonText: props.tr['Yes'],
            denyButtonText: props.tr['No'],
          }).then((result) => {
            if (result.isConfirmed) {
              dispatch(disassociateGroupRow(payload))
            } 
          })
    }

    let { handleSubmit } = props;
    const onCompanySelection = (selectedCompany) => {
        if (selectedCompany.length > 0) { 
            setSelectedCompany(selectedCompany)
            dispatch(change('GroupList', 'name', selectedCompany))
            setTypeError("")
          } else {
            setSelectedCompany([]);
          }
    }

    const onGroupSelection = (selectedGroupData) => {
        if (selectedGroupData.length > 0) { 
            setSelectedGroup(selectedGroupData)
            dispatch(change('GroupList', 'group', selectedGroupData))
            setGroupError("")
          } else {
            setSelectedGroup([]);
          }
    }
    const onCrossClickCompany = () => {
      setSelectedCompany([]);
      setSelectedGroup([]);
      setGroupData([]);
    }
    const onCrossClickGroup = () => {
        setSelectedGroup([]);
        setGroupError("");
    }
    const onRowSelect = () => {
        
    }

    const tableColumns = [
        {
            name: props.tr["ID"],
            selector: (row) => row.group_Id,
            sortable: true,
        },
       {
            name: props.tr["Group"],
            selector: (row) => row.group_Name,
            sortable: true,
            cell: (row) => (<div title={row.group_Name}>{row.group_Name}</div>)
     },
        {
            name: props.tr["Company"],
            selector: (row) => row.name,
            sortable: true,
            cell: (row) => (<div title={row.name}>{row.name}</div>)
        },
        {
			name: props.tr["Disassociate"],
			sortable: false,
			cell: (row) => (
			  <CellName row={row} 
			  />
			),
			ignoreRowClick: true,
		  },
    ];

    const filterColumns = ["group_Id","name","group_Name"];
    return (
        <>
            <Form.Group className="form-group">
                <Form.Label>{props.tr["Company"]}</Form.Label>
                <Field
                  name="company"
                  className="form-control"
                  component={TypeaheadExampleSingleSelect}
                  onSelection={onCompanySelection}
                  options={assoCompanyList}
                  selectedOptions={selectedCompany}
                  errorClass={typeError === "error" ? "error" : ""}
                  onCrossClick={onCrossClickCompany}
              
                />
            </Form.Group>

            <Form.Group className="form-group">
                <Form.Label>{props.tr["Group Name"]}</Form.Label>
                <Field
                  name="group"
                  className="form-control"
                  component={TypeaheadandDropdown}
                  onSelection={onGroupSelection}
                  options={groupData}
                  selectedOptions={selectedGroup}
                  errorClass={groupError === "error" ? "error" : ""}
                  onCrossClick={onCrossClickGroup}
                  multiple={true}
                />
            </Form.Group>

            <ButtonToolbar className="black margin-t-5">
                <Button className="rgSidrkBtn smallBtn"
                onClick={handleSubmit(saveAssociateGroups)}>
                {props.tr["Add"]}
                </Button>
            </ButtonToolbar>
            <div className="catBordDv leftSeardv integrateHubTable">
            <div className='f-size-16 margin-t-20 margin-b-10 border-b padding-b-5'>{props.tr["Associated Groups"]}</div>
            <TableComponent
                  data={Array.isArray(props.associatedGroupValue)? props.associatedGroupValue:[]}
                  columns={tableColumns}
                  headerColumns={filterColumns}
                  onRowSelect={onRowSelect}
                  uniqueKey={'group_Id'}
                  paginationText={"Show"}
                />
                </div>
        </>
    )
}

export default reduxForm({
    form: 'GroupList',
    destroyOnUnmount: false,
    enableReinitialize: true
})(GroupList);