
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, {useState, useEffect} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Button from 'react-bootstrap/Button';
import swal from 'sweetalert';
import { renderOptions, getTypeForCategory, getOverallRiskGBP } from './helper';

const GBPRiskAssessment = (props) => {
    // console.log('GBPRiskAssessment props - ', props);
    const { 
        translator = {}, 
        mode = 'add', 
        isEditable = true,
        companyId = '',
        showForm = false,
        setShowForm = () => {},
        riskCategoryTypeList = [],
        fetchGBPDropdownOptions = () => {},
        gbpDropdownList = {},
        savedAssessment = [],
        setSavedAssessment = () => {},
        editAssessmentIndex = null,
        setEditAssessmentIndex = () => {},
        editAssessmentObj = null,
        setEditAssessmentObj = () => {},
        setResidualRisk = () => {},
        setInitialSavedDataOnLoad = () => {}
    } = props;
    const [riskCategory, setRiskCategory] = useState('');
    const [riskCategoryName, setRiskCategoryName] = useState('');
    const [riskType, setRiskType] = useState('');
    const [riskTypeName, setRiskTypeName] = useState('');
    const [riskProbability, setRiskProbability] = useState('');
    const [riskImpact, setRiskImpact] = useState('');
    const [riskConfidence, setRiskConfidence] = useState('');
    const [riskDesc, setRiskDesc] = useState('');
    const [riskCtrlMeasure, setRiskCtrlMeasure] = useState('');
    const [errors, setErrors] = useState({});
    const [formSubmit, setFormSubmit] = useState(false);
    const [typeList, setTypeList] = useState(null);

    useEffect(() => {
        fetchGBPDropdownOptions({category:riskCategory, type: riskType});
    }, [riskCategory, riskType]);

    const validateField = (type = '', value = '') => {
        switch(type){
            case 'riskCategory':
            case 'riskType':
            case 'riskProbability':
            case 'riskImpact':
            case 'riskConfidence':
                return !!value;

            // case 'riskDescription':
            // case 'riskControlMeasure':
            //     return !!(value && value.trim().length);

            default:
                return 'invalid type';
        }
    };

    const validate = () => {
        // let error = structuredClone(errors);
        let error = {};
        error['riskCategory'] = (validateField('riskCategory', riskCategory)) ? false : true;
        error['riskType'] = (validateField('riskType', riskType)) ? false : true;
        error['riskProbability'] = (validateField('riskProbability', riskProbability)) ? false : true;
        error['riskImpact'] = (validateField('riskImpact', riskImpact)) ? false : true;
        error['riskConfidence'] = (validateField('riskConfidence', riskConfidence)) ? false : true;
        // error['riskDescription'] = (validateField('riskDescription', riskDesc)) ? false : true;
        // error['riskControlMeasure'] = (validateField('riskControlMeasure', riskCtrlMeasure)) ? false : true;
        setErrors(error);
        const isFormError = Object.values(error).filter(item => item == true);
        return (isFormError.length ? true : false);
    }

    const updateFieldError = (type = '', value = '') => {
        if(!type){
            return;
        }
         let error = structuredClone(errors);
         error[type] = (validateField(type, value)) ? false : true;
         setErrors(error);
    }

    const clearAddFormStates = () => {
        setFormSubmit(false);
        setShowForm(false);
        setErrors({});
        setRiskCategory('');
        setRiskCategoryName('');
        setRiskType('');
        setRiskTypeName('');
        setRiskProbability('');
        setRiskImpact('');
        setRiskConfidence('');
        setRiskDesc('');
        setRiskCtrlMeasure('');
        setEditAssessmentIndex(null);
        setEditAssessmentObj(null);
    }

    const checkDuplicate = (obj) => {
        try{
            if(editAssessmentObj){
                // let editedObj = savedAssessment.slice(editAssessmentIndex, 1);
                // editedObj = editedObj[0];
                let editedObj = savedAssessment.find((item, index) => index == editAssessmentIndex);
                if(obj['riskCategory'] == editedObj['riskCategory'] && obj['riskType'] == editedObj['riskType']){
                    return false;
                }
                else{
                    const tempArr = savedAssessment.filter((item, index) => index != editAssessmentIndex);
                    const filter = tempArr.filter(item => (item['riskCategory'] == obj['riskCategory'] && item['riskType'] == obj['riskType']) );
                return( filter.length ? true : false );
                }
            }
            else{
                const filter = savedAssessment.filter(item => (item['riskCategory'] == obj['riskCategory'] && item['riskType'] == obj['riskType']) );
                return( filter.length ? true : false );
            }
        }catch(e){
            return false;
        }
    }

    const submitAssessment = async () => {
        try{
            let savedArray = structuredClone(savedAssessment);
            let obj = {
                'methodologyId': '1',
                'methodologyName': 'GBP',
                'riskCategory': riskCategory,
                'riskCategoryName': riskCategoryName,
                'riskType': riskType,
                'riskTypeName': riskTypeName,
                'probability': riskProbability,
                'impact': riskImpact,
                'confidence': riskConfidence,
                'riskDescription': riskDesc,
                'controlMeasures': riskCtrlMeasure,
                'overallRisk': ''
            };
            
            const isDuplicateExists = checkDuplicate(obj);
            if(isDuplicateExists){
                // alert('This assessment already exists!');
                swal({
                    text: 'This assessment already exists!',
                    button: 'OK',
                });
                return;
            }
            const overallRisk = await getOverallRiskGBP(companyId, riskCategory, riskType, riskProbability, riskImpact, riskConfidence);
            obj['overallRisk'] = overallRisk['overallRisk'] || 'NA';
            if(editAssessmentObj){
                let objTemp = {...editAssessmentObj, ...obj};
                savedArray.splice(editAssessmentIndex, 1, objTemp);
            }
            else{
                savedArray.push(obj);
            }
            setSavedAssessment(savedArray);
            setResidualRisk('1', savedArray);
            setInitialSavedDataOnLoad({type: 'data', data: null});
            clearAddFormStates();
        }catch(e){

        }
    }

    const setEditAssessment = (item, index) => {
        try{
            setInitialSavedDataOnLoad({type: 'data', data: null});
            setTypeList(null);
            const types = getTypeForCategory(riskCategoryTypeList, item['riskCategory']);
            setTypeList(types);
            // fetchGBPDropdownOptions({category:item['riskCategory'], type: item['riskType']});
            setTimeout(() => {
                setShowForm(true);
                setErrors({});
                setRiskCategory(item['riskCategory']);
                setRiskCategoryName(item['riskCategoryName']);
                setRiskType(item['riskType']);
                setRiskTypeName(item['riskTypeName']);
                setRiskProbability(item['probability']);
                setRiskImpact(item['impact']);
                setRiskConfidence(item['confidence']);
                setRiskDesc(item['riskDescription']);
                setRiskCtrlMeasure(item['controlMeasures']);
                setEditAssessmentIndex(index);
                setEditAssessmentObj(item);
            }, 0);
        }catch(e){}
    }

    const deleteAssessment = (index) => {
        let savedArray = structuredClone(savedAssessment);
        savedArray.splice(index, 1);
        setSavedAssessment(savedArray);
        setResidualRisk('1', savedArray);
        setInitialSavedDataOnLoad({type: 'data', data: null});
    }

    let panelColSize = props.panelResize;
    //console.log("panelSize", panelColSize, props.panelResize);

    return (
        <div>
            {(showForm) && <div style={{'margin-bottom': '18px'}}>
                <Row>
                    <Col md={panelColSize <= 50 ? 12 : 6} xs={12}>
                        <Row>
                        <Col md={5}>
                        <Form.Group>
                            <span className="rStar" />
                            <Form.Label bsClass="">{translator['Risk Category']}</Form.Label>
                            <Form.Select
                            className={formSubmit && errors['riskCategory'] ? 'myControl myInput error' : 'myControl myInput'}
                            componentClass="select"
                            onChange={(e) => {
                                setRiskCategory(e.target.value);
                                setRiskCategoryName(e.target[e.target.selectedIndex].text);
                                updateFieldError('riskCategory', e.target.value);
                                const types = getTypeForCategory(riskCategoryTypeList, e.target.value);
                                setTypeList(types);
                                setRiskType('');
                                setRiskTypeName('');
                                setRiskProbability('');
                                setRiskImpact('');
                                setRiskConfidence('');
                            }}
                            value={riskCategory}
                            disabled={!isEditable}
                            >
                                <option value="">Select</option>
                                {renderOptions('riskCategory', riskCategoryTypeList)}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={7}>
                        <Form.Group>
                            <span className="rStar" />
                            <Form.Label bsClass="">{translator['Risk Type']}</Form.Label>
                            <Form.Select
                            className={formSubmit && errors['riskType'] ? 'myControl myInput error' : 'myControl myInput'}
                            componentClass="select"
                            onChange={(e) => {
                                setRiskType(e.target.value);
                                setRiskTypeName(e.target[e.target.selectedIndex].text);
                                updateFieldError('riskType', e.target.value);
                                setRiskProbability('');
                                setRiskImpact('');
                                setRiskConfidence('');
                            }}
                            value={riskType}
                            disabled={!isEditable}
                            >
                                <option value="">Select</option>
                                {renderOptions('riskType', typeList)}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                        </Row>
                    </Col>
                    <Col md={panelColSize <= 50 ? 12 : 6} xs={12}>
                        <Row>
                        <Col md={4}>
                        <Form.Group>
                            <span className="rStar" />
                            <Form.Label bsClass="">{translator['Probability']}</Form.Label>
                            <Form.Select
                            className={formSubmit && errors['riskProbability'] ? 'myControl myInput error' : 'myControl myInput'}
                            componentClass="select"
                            onChange={(e) => {
                                setRiskProbability(e.target.value);
                                updateFieldError('riskProbability', e.target.value);
                            }}
                            value={riskProbability}
                            disabled={!isEditable}
                            >
                                <option value="">Select</option>
                                {renderOptions('riskProbability', (gbpDropdownList && gbpDropdownList['probability']) ? gbpDropdownList['probability'] : [])}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group>
                            <span className="rStar" />
                            <Form.Label bsClass="">{translator['Impact']}</Form.Label>
                            <Form.Select
                            className={formSubmit && errors['riskImpact'] ? 'myControl myInput error' : 'myControl myInput'}
                            componentClass="select"
                            onChange={(e) => {
                                setRiskImpact(e.target.value);
                                updateFieldError('riskImpact', e.target.value);
                            }}
                            value={riskImpact}
                            disabled={!isEditable}
                            >
                                <option value="">Select</option>
                                {renderOptions('riskImpact', (gbpDropdownList && gbpDropdownList['impact']) ? gbpDropdownList['impact'] : [])}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group>
                            <span className="rStar" />
                            <Form.Label bsClass="">{translator['Confidence']}</Form.Label>
                            <Form.Select
                            className={formSubmit && errors['riskConfidence'] ? 'myControl myInput error' : 'myControl myInput'}
                            componentClass="select"
                            onChange={(e) => {
                                setRiskConfidence(e.target.value);
                                updateFieldError('riskConfidence', e.target.value);
                            }}
                            value={riskConfidence}
                            disabled={!isEditable}
                            >
                                <option value="">Select</option>
                                {renderOptions('riskConfidence', (gbpDropdownList && gbpDropdownList['confidence']) ? gbpDropdownList['confidence'] : [])}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label bsClass="">{translator['Risk Description']}</Form.Label>
                            <Form.Control as="textarea" value={riskDesc} rows="3" maxLength={1000} 
                            onChange={(e) => {
                                setRiskDesc(e.target.value);
                                updateFieldError('riskDescription', e.target.value);
                            }}
                            onBlur={(e) => {
                                let str = (e && e.target && e.target.value) ? e.target.value : "";
                                str = str.trim();
                                str = str.substring(0, 1000);
                                setRiskDesc(str);
                                updateFieldError('riskDescription', str);
                            }}
                            disabled={!isEditable}
                            // className={formSubmit && errors['riskDescription'] ? 'error' : ''}
                            />
                            <p className="charLeft" style={{textAlign:"right","fontSize":"11px"}}>({translator['Characters Remaining']}: {(1000 - riskDesc.length)}/1000)</p>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label bsClass="">{translator['Control Measures']}</Form.Label>
                            <Form.Control as="textarea" value={riskCtrlMeasure} rows="3" maxLength={1000} 
                            onChange={(e) => {
                                setRiskCtrlMeasure(e.target.value);
                                updateFieldError('riskControlMeasure', e.target.value);
                            }}
                            onBlur={(e) => {
                                let str = (e && e.target && e.target.value) ? e.target.value : "";
                                str = str.trim();
                                str = str.substring(0, 1000);
                                setRiskCtrlMeasure(str);
                                updateFieldError('riskControlMeasure', str);
                            }}
                            disabled={!isEditable}
                            // className={formSubmit && errors['riskControlMeasure'] ? 'error' : ''}
                            />
                            <p className="charLeft" style={{textAlign:"right","fontSize":"11px"}}>({translator['Characters Remaining']}: {(1000 - riskCtrlMeasure.length)}/1000)</p>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <div style={{"clear":"both"}}>
                        <ButtonToolbar className="notificationcss">
                            {(isEditable == true) && <Button
                            bsSize="small"  className={(true) ? "rgSidrkBtn" : "rgSiLigBtn"} variant="primary"  onClick={(event) => {
                                setFormSubmit(true);
                                const isValid = validate();
                                if(!isValid){
                                    submitAssessment();
                                }
                            }} >Save</Button>}
                            <Button
                            bsSize="small" variant="primary" className={(false) ? "rgSidrkBtn" : "rgSiLigBtn"} onClick={(event) => {
                                setShowForm(false);
                                clearAddFormStates();
                                // setShowAddIcon(false);
                            }}>Close</Button>
                        </ButtonToolbar>
                    </div>
                </Row>
            </div>}

            <div>
                {(savedAssessment && savedAssessment.length > 0) && (
                    <div style={{'margin-top': '2rem'}}>
                        <Row>
                            <Col xs={3} sm={3} md={3}>{translator["Risk Category"]}</Col>
                            <Col xs={5} sm={5} md={5}>{translator["Risk Type"]}</Col>
                            <Col xs={2} sm={2} md={2}>{translator["Risk"]}</Col>
                            {(isEditable == true) && <Col xs={2} sm={2} md={2}>{translator["Actions"]}</Col>}
                        </Row>
                        {
                            savedAssessment.map((item, index) => {
                                return(
                                    <Row key={`gbpSavedAssessment_${index}`} >
                                        <Col xs={3} sm={3} md={3}>
                                            <Form.Control type='text' className='myControl myInput' disabled value={item['riskCategoryName']} />
                                        </Col>
                                        <Col xs={5} sm={5} md={5}>
                                            <Form.Control type='text' className='myControl myInput' disabled value={item['riskTypeName']} />
                                        </Col>
                                        <Col xs={2} sm={2} md={2}>
                                            <Form.Control type='text' className='myControl myInput' disabled value={item['overallRisk']} />
                                        </Col>
                                        {(isEditable == true) && <Col xs={2} sm={2} md={2}>
                                            <a title="Edit" className="margin-r-10" href="javascript:void(0)" 
                                            onClick={() => {
                                                setEditAssessment(item, index);
                                            }}><i className="f-size-16 fa fa-pencil" ></i></a>
                                            <a title="Delete" className="margin-r-10" href="javascript:void(0)" 
                                            onClick={() => {
                                                deleteAssessment(index);
                                            }}><i className="f-size-16 fa fa-trash-o" ></i></a>
                                            
                                        </Col>}
                                    </Row>
                                );
                            })
                        }
                    </div>
                )}
            </div>
        </div>
    );
};

export default GBPRiskAssessment;
