
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/



export const locationData = (state = [], action) => {
    if (action.type === 'LOCATION_LIST_FETCHED_SUCCESSFULLY') {
      return action.payload;
    } else {
      return state;
    }
};

export const  locationFieldData= (state = [], action) => {
    if (action.type === 'LOCATION_TYPE_INITIAL_LIST') {
      return action.payload;
    } else {
      return state;
    }
};


export const  countryFieldData= (state = [], action) => {
    if (action.type === 'COUNTRY_INITIAL_LIST_VALUES') {
      return action.payload;
    } else {
      return state;
    }
};

export const  statesFieldData= (state = [], action) => {
    if (action.type === 'STATES_INITIAL_LIST') {
      return action.payload;
    } else {
      return state;
    }
};

export const  citiesFieldData= (state = [], action) => {
    if (action.type === 'CITY_INITIAL_LIST') {
      return action.payload;
    } else {
      return state;
    }
};


export const  locationEditPageDetails= (state = [], action) => {
    if (action.type === 'LOCATION_PAGE_EDIT_DETAILS_FETCHED') {
      return action.payload;
    } else if(action.type === 'RESET_LOCATION_PAGE_DETAILS'){
      return [];
    } 
    else {
      return state;
    }
};


export const  locationCountriesMap= (state = [], action) => {
    if (action.type === 'COUNTRY_DETAILS_FETCHED') {
      return action.payload;
    } else {
      return state;
    }
};

export const  locationStatesMap= (state = [], action) => {
  if (action.type === 'STATES_DETAILS_FETCHED') {
    return action.payload;
  } else {
    return state;
  }
};

export const  locationCitiesMap= (state = [], action) => {
  if (action.type === 'CITIES_DETAILS_FETCHED') {
    return action.payload;
  } else {
    return state;
  }
};