
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});
//const getCategoryList = 'https://xsm-f.mybluemix.net/getOfferingsForListRestifySelfService/?categoryId=';
// import { GLOBAL } from '../../components/Globals';
import {GLOBAL} from '_Globals';
import { updateWIBSearchData as updatePayload } from '../../utils/common';

import Cookies from 'universal-cookie';
import finalPropsSelectorFactory from 'react-redux/es/connect/selectorFactory';
const cookies = new Cookies();
let defaultDateRangeValue = cookies.get('gph');
if (defaultDateRangeValue) defaultDateRangeValue = defaultDateRangeValue.replace('s:', '');
if (defaultDateRangeValue) defaultDateRangeValue = defaultDateRangeValue.substring(0, defaultDateRangeValue.lastIndexOf('.'));
defaultDateRangeValue = defaultDateRangeValue.split("~");
defaultDateRangeValue = defaultDateRangeValue[53];

export function loadInvestigationSuccess(investigationList) {
	return {
		type: 'LOAD_INVESTIGATION_SUCCESS',
		investigationList
	};
}

function setWibListFilterCriteria(obj = {}){
	// console.log('setWibListFilterCriteria obj - ', obj);
	return {
		type: 'LOAD_WIB_LIST_PAYLOAD',
		payload: obj['payload']
	}
}

export function loadInvestigationList(searchParams, statusInput, priorityInput, spGroupInput, spUserInput = [],isWIBGlobalSearchEnabled=false) {
	let newSearchPara = {searchParams,statusInput,priorityInput,spGroupInput,isWIBGlobalSearchEnabled,spUserInput};
	let status = statusInput.toString();
	let priority = priorityInput.toString();
	let spGroup = spGroupInput.toString();
	let spUser=spUserInput.toString();
	let searchValue = [];
	let searchKey = [];

	if (status != "") {
		searchValue.push(status);
		searchKey.push("status");
	}
	if (priority != "") {
		searchValue.push(priority);
		searchKey.push("priority");
	}
	if (spGroup != "") {
		searchValue.push(spGroup);
		searchKey.push("asgPmGroupid");
	}
	if (spUser != "") {
		searchValue.push(spUser);
		searchKey.push("asgPmId");
	}
	if (searchKey.length != 0) {
		searchParams.searchByList = searchParams.searchByList != "" ? searchParams.searchByList + "," + searchKey.join(",") : searchKey.join(",");
		searchParams.multipleValueList = searchParams.multipleValueList != "" ? searchParams.multipleValueList + "|" + searchValue.join("|") : searchValue.join("|");
		searchParams.patternList = searchParams.patternList != "" ? searchParams.patternList : 'in';
	}

	if(!isWIBGlobalSearchEnabled && searchParams){
		let searchByList = searchParams.searchByList.split(',');
		if(!searchByList.includes('createdOn')){
			let days = (defaultDateRangeValue && !isNaN(defaultDateRangeValue)) ? (parseInt(defaultDateRangeValue,10) - 1) : 29;
			searchByList.unshift('createdOn');
			searchParams.searchByList = searchByList.join(",");
			let value = [];
			value.push(moment().subtract(days, 'days').format("MM-DD-YYYY"), moment().format("MM-DD-YYYY"));
			searchParams.multipleValueList = (searchParams.multipleValueList) ? (value.join("&") + "|" + searchParams.multipleValueList) : value.join("&");
			searchParams.patternList = (searchParams.patternList) ? ("between" + "," + searchParams.patternList) : "between";
		}
	}
	
	return (dispatch) => {

		let listPayloadArr = [ (searchParams), (statusInput || ''), (priorityInput || ''), (spGroupInput || ''), (spUserInput || []), (isWIBGlobalSearchEnabled || false) ];
		let listPayloadObj = { module: 'investigation', payload: listPayloadArr };
		dispatch({ type: 'RESET_WIB_LIST_PAYLOAD' });
		setTimeout(() => {
			dispatch(setWibListFilterCriteria(listPayloadObj));
		}, 500);

		dispatch({ type: 'FETCHING_DETAILS_IN_PROGRESS' });
		const payload = updatePayload(searchParams, 'problem', 'wib');
		dispatch({type:"WIB_SEARCH_PARAMS", searchParams:{module:"problem",searchParams:newSearchPara}});
		api.post(GLOBAL.innvestigationGetUrl, payload)
			//axios.get(GLOBAL.innvestigationGetUrl)
			.then((investigationList) => {
				if (!investigationList) {
					throw Error(investigationList.statusText);
				}
				return investigationList;
			})
			.then((investigationList) => {
				dispatch({ type: 'DETAILS_FETCHED_SUCCESSFULLY' });
				dispatch(loadInvestigationSuccess(investigationList));
			})
			.catch((err) => {
				console.log("error in loading investigation" + err);
			});
	};
}

export function loadInvestigationListSort(searchParams) {
	//alert("action called")
	return (dispatch) => {
		dispatch({ type: 'FETCHING_DETAILS_IN_PROGRESS' });
		let params = {};
		if (!_.isEmpty(searchParams)) { params = searchParams } //lodash isEmpty checks for nulls too
		// api.get(GLOBAL.innvestigationGetUrl, params)
		api.post(GLOBAL.innvestigationGetUrl, params)
			.then((investigationList) => {
				if (!investigationList) {
					throw Error(investigationList.statusText);
				}
				return investigationList;
			})
			.then((investigationList) => {
				dispatch({ type: 'DETAILS_FETCHED_SUCCESSFULLY' });
				dispatch(loadInvestigationSuccess(investigationList))
			})
			.catch((err) => {
				console.log("error in loading investigation" + err);
			});
	};
}
export const loadInvestigationDataBackground = investigationId => {
   const searchParams = {
		searchByList: "investigationId",
		valueList: investigationId,
		size: 10,
		currentPage: 0,
   }		
   return dispatch => {
	api.post(GLOBAL.investigationDetailsUrl, searchParams).then(investigationData => {
		dispatch(investigationDetailsLoadSuccess(investigationData));
	});
   }
}

export const getInvestigationItemDetails = (investigationId) => {
	let searchParams = Object.assign({});
	if (investigationId) {
		searchParams.searchByList = "investigationId";
		searchParams.valueList = investigationId;
		searchParams.size = 10;
		searchParams.currentPage = 0;
	}
	let params = {};
	if (!_.isEmpty(searchParams)) { params = searchParams }
	return (dispatch) => {
		dispatch({ type: 'FETCHING_DETAILS_IN_PROGRESS' });
		api.post(GLOBAL.investigationDetailsUrl, params)
			.then((investigationDetails) => {
				if (!investigationDetails) {
					throw Error(investigationDetails.statusText);
				}
				return investigationDetails;
			})
			.then((investigationDetails) => {
				dispatch(investigationDetailsLoadSuccess(investigationDetails));
				dispatch({ type: 'DETAILS_FETCHED_SUCCESSFULLY' });
				dispatch({ type: 'LOAD_HAMBURGER_OPTIONS', payload: true });
			})
			.catch((err) => {
				console.log(err);
			});
	};
}

export const getInvestigationItemDetailsById = (investigationId) => {
	return (dispatch) => {
		dispatch({ type: 'FETCHING_DETAILS_IN_PROGRESS' });
		api.get(`${GLOBAL.investigationDetailsUrl}/${investigationId}`)
			.then((investigationDetails) => {
				if (!investigationDetails) {
					throw Error(investigationDetails.statusText);
				}
				return investigationDetails;
			})
			.then((investigationDetails) => {
				dispatch(investigationDetailsLoadSuccess(investigationDetails));
				dispatch(changeFieldStatusData(investigationDetails.data.status_id));

				// dispatch({type:'DETAILS_FETCHED_SUCCESSFULLY'});
				dispatch({type:'LOAD_HAMBURGER_OPTIONS',payload:true});
				//dispatch({type:'LOAD_HAMBURGER_OPTIONS',payload:true});
			})
			.catch((err) => {
				console.log(err);
			});
	};
}
export const changeFieldStatusData = (status_id) => {
	console.log("action in field status");
	console.log("URL", GLOBAL.ProbelemFieldStatusData + status_id);
	return (dispatch) => {
		dispatch({ type: 'FETCHING_DETAILS_IN_PROGRESS' });
		api.get(GLOBAL.ProbelemFieldStatusData + status_id)
			.then((statusData) => {
				if (!statusData) {
					throw Error(statusData.statusText);
				}
				return statusData;
			})
			.then((statusData) => {
				console.log("changeData.... ", statusData)
				dispatch(FieldstatusData(statusData));
				dispatch({ type: 'DETAILS_FETCHED_SUCCESSFULLY' });

			});
	}
}
export const FieldstatusData = (statusData) => {
	console.log("statusData", statusData);
	// let fieldStatus={
	//   type:statusData.type,
	//   summary:statusData.summary,
	// }
	return {
		type: 'CHANGE_FIELD_STATUS_DATA_LOAD_SUCCESS',
		statusData
	};
}
export const seteditInvestigationDetails=(investigationDetails)=>{
    return (dispatch) =>{
		dispatch(investigationDetailsLoadSuccess(investigationDetails));
	}
}
export const getInvestigationItemDetailsUpdate = (investigationId) => {
	return (dispatch) => {
		// dispatch({type:'FETCHING_DETAILS_IN_PROGRESS'});
		let searchParams = Object.assign({});
		if (investigationId) {
			searchParams.searchByList = "investigationId";
			searchParams.valueList = investigationId.toString();
			searchParams.size = 10;
			searchParams.currentPage = 0;
		}
		let params = {};
		if (!_.isEmpty(searchParams)) { params = searchParams }
		api.post(GLOBAL.investigationDetailsUrl, params)
			.then((investigationDetails) => {
				if (!investigationDetails) {
					throw Error(investigationDetails.statusText);
				}
				return investigationDetails;
			})
			.then((investigationDetails) => {
				dispatch(investigationDetailsLoadSuccess(investigationDetails));
				// dispatch({type:'DETAILS_FETCHED_SUCCESSFULLY'});
			})
			.catch((err) => {
				console.log(err);
			});
	};
}

const investigationDetailsLoadSuccess = (investigationDetails) => {
	return {
		type: 'INVESTIGATION_DETAILS_LOAD_SUCCESS',
		investigationDetails
	};
}

export const updateInvestigationDetails = (investigationDetails) => {
	return (dispatch) => {
		dispatch(investigationDetailsLoadSuccess(investigationDetails));
	};
}

export const getInvestigationTeamList = (investigationId) => {
	return (dispatch) => {
		api.get(GLOBAL.investigationTeamListUrl + "/investigationId/" + investigationId)
			.then((investigationlist) => {
				if (!investigationlist) {
					throw Error(investigationlist.statusText);
				}
				return investigationlist;
			})
			.then((investigationlist) => {
				dispatch(investigationTemListSuccess(investigationlist));
			})
			.catch((err) => {
				console.log(err);
			});
	};
};
export const getspecificVendorTicket=(investigationId)=>{
	console.log("**********investigationId*******",investigationId);
	console.log("URL",GLOBAL.specificVendorTicketList + investigationId);
	return(dispatch)=>{
		
	  api.get(`${GLOBAL.specificVendorTicketList}/${investigationId}`)
		.then((vendorTicketList) => {
		  if (!vendorTicketList) {
			throw Error(vendorTicketList.statusText);
		  }
		  return vendorTicketList;
		})
		.then((vendorTicketList) => {
			
			dispatch(specificVendorTicketSuccess(vendorTicketList));

		})
		.catch((err) => {
			console.log(err);
		});
	}
  }
  const specificVendorTicketSuccess = (vendorTicketList) => {
	return {
		type: 'SPECIFIC_VENDORTICKET_LIST_SUCCESS',
		vendorTicketList
	};
}
export const deleteSpecificVendorTicket = (specificVendorTicketId,investigationId) => {
// let obj=[{specificVendorTicketId}];
// obj=specificVendorTicketId;
console.log("VendorTicketId",specificVendorTicketId);
	return (dispatch) => {
		// api.delete(GLOBAL.delSpecificVendorTicket,VendorTicketId)
		api.delete(GLOBAL.delSpecificVendorTicket, { data: specificVendorTicketId } )
		  
			.then((response) => {
				console.log("response",response);
				dispatch(getspecificVendorTicket(investigationId));
			})
			.catch((err) => {
				console.log(err);
			});
	};
};
const investigationTemListSuccess = (investigationlist) => {
	return {
		type: 'INVESTIGATION_TEAM_LIST_SUCCESS',
		investigationlist
	};
}

export const deleteInvestigationTeamMember = (investigationId, memberId) => {
	return (dispatch) => {
		return api.delete(GLOBAL.deleteInvestigationTeamMemberUrl + "/investigationId/" + investigationId + "/memberId/" + memberId)
			.then((response) => {
				if (response.data.deleted == "0") {
					const investigationList = api.get(GLOBAL.investigationTeamListUrl + "/investigationId/" + investigationId);
					investigationList.then((investigationlist) => {
						if (!investigationlist) {
							throw Error(investigationlist.statusText);
						}
						return investigationlist;
					})
						.then((investigationlist) => {
							dispatch(investigationTemListSuccess(investigationlist));
						})
						.catch((err) => {
							console.log(err);
						});

					return Promise.resolve('Success');
				} else {
					alert("memeber deletion " + response.status);
					return Promise.reject('Error');
				}
			})
			.catch((err) => {
				console.log(err);
				return Promise.reject('Error');
			});
	};
};

export function loadPrbDetails(payload, flag="noreset") {
	// console.log("payload", payload);
	return (dispatch) => {
		if(flag == "reset") return dispatch({type:"RESET_STATE"})
	  dispatch({ type: "LOADER_IN_PROGRESS_2" });
	  api
		.post(GLOBAL.innvestigationGetUrl, payload)
		.then((reponse) => {
		  console.log("reponse :: ", reponse);
		  if (!reponse) {
			dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY_2" });
  
			throw Error(reponse.statusText);
		  }
		  dispatch({ type: "LOAD_PRB_DETAILS", payload: reponse.data });
		  dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY_2" });
		  
		})
		.catch((err) => {
		  console.log(err);
		});
	};
  }
export const getslaViewListAction = (size, currentPage, multipleValueList, sortBy = 'timeLeft', orderBy = 'ASC', searchByList = "moduleName", patternList = 'equal') => {
	return (dispatch) => {
		if(multipleValueList == 'task'){
			multipleValueList = "Task"
		}
		else if (multipleValueList == 'fullfilment'){
			multipleValueList = "Fulfillment"
		}
		else if (multipleValueList == 'breakfix'){
			multipleValueList = "Incident"
		}
		else if (multipleValueList == 'investigation'){
			multipleValueList = "Problem"
		}
		else if (multipleValueList == 'changeManagement'){
			multipleValueList = "Change"
		}
		// if (userType.length > 0) {
		// 	searchByValue = searchByValue.concat(searchByValue !== "" ? "|" : "", userType.join(","));
		// 	searchBy = searchBy.concat(searchBy !== "" ? "," : "", "userType");
		// }
		// if (status.length > 0) {
		// 	searchByValue = searchByValue.concat(searchByValue !== "" ? "|" : "", status.join(","));
		// 	searchBy = searchBy.concat(searchBy !== "" ? "," : "", "status");
		// }
		let payload = {
			searchByList: searchByList,
			multipleValueList: multipleValueList,
			patternList: patternList,
			orderBy: orderBy,
			sortBy: sortBy,
			currentPage: parseInt(currentPage,10),
			size: parseInt(size,10)
		};
		// let payload = {
		// 	"searchByList": "moduleName",
		// 	"multipleValueList": "Incident",
		// 	"patternList": "equal",
		// 	"orderBy": "ASC",
		// 	"sortBy": "timeLeft",
		// 	"currentPage": 0,
		// 	"size": 10
		// }
		console.log("PAYLOADDDDDd",payload);
		dispatch({ type: "FDN_DATA_FETCHING" });
		api
			.post("api/colSearchSlaView", payload)
			.then((response) => {
				dispatch({ type: "SLA_VIEW_LIST", payload: response.data });
				dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
			})
			.catch((err) => {
				console.log(err);
				dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
			});
	};
};