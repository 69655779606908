
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useSelector } from "react-redux";

import PindCategories from "../../common/header/pind-categories";
import Breadcrumbs from "../../common/header/breadcrumbs";
import CreateEscalationHeader from "./CreateEscalationHeader";
import CreateEscalationForm from "./CreateEscalationForm";
import "_Css/form/_form.scss";

const CreateEscalationIndex = () => {
  const tr = useSelector((state) => state.spcmReducer.tr);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [emailIds, setEmailIds] = useState("");
  const [valueError, setValueError] = useState("");
  const [groupError, setGroupError] = useState("");
  const [channelError, setChannelError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [emailError, setEmailError] = useState(false);


  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Breadcrumbs
          activePageName="Create"
          parentPageurl="/escalation"
          parentPageName="Escalation"
        />
      </Container>

      <Container fluid>
        <CreateEscalationHeader
          selectedChannels={selectedChannels}
          selectedGroups={selectedGroups}
          selectedUsers={selectedUsers}
          selectedCompany={selectedCompany}
          setValueError={setValueError}
          setCompanyError={setCompanyError}
          setGroupError={setGroupError}
          setChannelError={setChannelError}
          emailIds={emailIds}
          setEmailError={setEmailError}
        />
        <Row>
          <Col md={12} xs={12}>
            <CreateEscalationForm
              translator={tr}
              setSelectedChannels={setSelectedChannels}
              selectedChannels={selectedChannels}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              selectedGroups={selectedGroups}
              setSelectedGroups={setSelectedGroups}
              selectedCompany={selectedCompany}
              setSelectedCompany={setSelectedCompany}
              setValueError={setValueError}
              setCompanyError={setCompanyError}
              setGroupError={setGroupError}
              setChannelError={setChannelError}
              valueError={valueError}
              companyError={companyError}
              channelError={channelError}
              groupError={groupError}
              emailIds={emailIds}
              setEmailIds={setEmailIds}
              emailError={emailError}
              setEmailError={setEmailError}
            />
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default CreateEscalationIndex;
