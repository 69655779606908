
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from "axios";
import Swal from "sweetalert2";
import { navigationHooks } from "../../../helpers/NavigationHook";
import { parseHTML } from "jquery";

const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

export const getBulletinList = () => {
  return (dispatch) => {
    api.get("/api/Bulletin")
      .then((response) => {
      console.log("BulletinData",response.data)
      dispatch({
        type: "BULLETIN_LIST_FETCHED_SUCCESSFULLY",
        payload: response.data,
      });
  })
  .catch((err) => {
    console.log(err);
  });
  };
};

export const getBulletinEditData = (bulletinId) => {
  return (dispatch) => {
    dispatch({ type: "FDN_DATA_FETCHING" });
    api
      .get(`/api/getBulletin?id=${bulletinId}`)
      .then((response) => {
        let advData = response.data.bulletin;
        let advCompany = response.data["CompanyList"].filter((company) => company.field1Value == advData.companyId);
        console.log('Company Details ---- ', advCompany);
        advData.companyName = advCompany[0].field1Key;
        advData.details = parseHTML(advData.details)[0].wholeText;
        console.log("Advertisement List --- ", advData);
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
        dispatch({
          type: "BULLETIN_EDIT_DATA_FETCHED_SUCCESSFULLY",
          payload: advData,
        });
      })
      .catch((err) => {
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
        console.log(err);
      });
  };
};

export const resetBulletinData = () => {
  return (dispatch) => {
    dispatch({ type: "BULLETIN_EDIT_DATA_FETCHED_SUCCESSFULLY", payload: [] });
  };
};

export const createBulletin = (payload) => {
  return (dispatch) => {
    dispatch({ type: "FDN_DATA_FETCHING" });
    api
      .post(`/api/createBulletin`, payload)
      .then((response) => {
        console.log("Bulletin Created --- ", response.data);
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
        dispatch({
          type: "BULLETIN_SAVED_SUCCESSFULLY",
          payload: response.data,
        });
        navigationHooks.navigate('/bulletin')
      })
      .catch((err) => {
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
        console.log(err);
      });
  };
};

export const updateBulletin = (payload) => {
  return (dispatch) => {
    dispatch({ type: "FDN_DATA_FETCHING" });
    api
      .post(`/api/updateBulletin`, payload)
      .then((response) => {
        console.log("Bulletin Updated --- ", response.data);
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
        dispatch({
          type: "BULLETIN_UPDATED_SUCCESSFULLY",
          payload: response.data,
        });
        Swal.fire('Bulletin Updated Successfully.')
      })
      .catch((err) => {
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
        console.log(err);
      });
  };
};

export const deleteBulletinData = (id) => {
  return (dispatch) => {
    api.delete("/api/deleteBulletin/"+id)
      .then((response) => {
        console.log("BulletinDelete",response)
        Swal.fire({
          title: 'Record deleted successfully',
        }).then(result => {
          if (result.isDismissed || result.isConfirmed) {
            navigationHooks.navigate('/bulletin')
          }
        })
      })
      .catch((err) => {
        console.log(err);
      });
  };
};