
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import { connect } from 'react-redux';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { Field, reduxForm,formValueSelector, SubmissionError } from 'redux-form';

import { loadBreakFixSupportCIMGroup } from '../../../../actions/breakFix/cimGroupAction';
import { loadBreakFixCIMDetails } from '../../../../actions/breakFix/cimDetailsAction';
import { loadBreakFixEditDetails,loadBreakFixEditDetailsUpdate } from '../../../../actions/breakFix/breakFixEditDetailsAction';
import { loadBreakFixSGAE,loadNewBreakFixSGAEWithQueryParams } from '../../../../actions/breakFix/serviceGroupAEAction';
import { GLOBAL } from "_Globals";
import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";
// import { browserHistory } from 'react-router';
let sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

import { loadStatusBasedHamburgerOptions } from '../../../../actions/common/loadStatusBasedHamburgerOptions';
import { loadTimelineData } from '../../../../actions/common/commonApisActions.js';
import swal from 'sweetalert'
import { navigationHooks } from '../../../../helpers/NavigationHook';
import { RulesDropdownList } from '../../../common/forms/RulesDropdownList.js';
var criticalityAction;
class RejectCriticalTab extends React.Component{

	constructor(props){
	super(props);
	this.state={
		checkButtonStatus:false,
		rulesAttribute: {}
	}
	this.onCIMGroupChange = this.onCIMGroupChange.bind(this);
	this.submit = this.submit.bind(this);
	};

	componentWillMount(){
		let amsAttribute={
			"Status":this.props.breakFixEditDetails.statusId,
			"Priority":this.props.breakFixEditDetails.priorityValue,
			"Service":this.props.breakFixEditDetails.serviceId,
			"Impacted CI":this.props.breakFixEditDetails.ciId,
			"Requestor":this.props.breakFixEditDetails.impactedUserid,
			"Class":this.props.breakFixEditDetails.ciClassId,
			"User.Location":this.props.breakFixEditDetails.requesterLocationId,
			"CI.Location":this.props.breakFixEditDetails.ciLocationId
		}
		let rulesAttribute = {
			status: this.props.breakFixEditDetails.statusId,
			priorityId: this.props.breakFixEditDetails.priorityId,
			serviceName: this.props.breakFixEditDetails.serviceName,
			impactedCi: this.props.breakFixEditDetails.ciName,
			impactedUsername: this.props.breakFixEditDetails.impactedUsername,
			ciClassName: this.props.breakFixEditDetails.ciClassName,
			userLocation: this.props.breakFixEditDetails.requesterLocationName,
			ciLocation: this.props.breakFixEditDetails.ciLocationName,
			serviceId:this.props.breakFixEditDetails.serviceId,
			impactedCiId:this.props.breakFixEditDetails.ciId,
			requestorId:this.props.breakFixEditDetails.impactedUserid,
			classId:this.props.breakFixEditDetails.ciClassId,
			userLocationId:this.props.breakFixEditDetails.requesterLocationId,
			ciLocationId:this.props.breakFixEditDetails.ciLocationId
		  };
		  this.setState({ rulesAttribute });
		//alert("GLOBAL.breakFixServiceID"+GLOBAL.breakFixServiceID);
		//alert("reject"+this.props.breakFixEditDetails.criticalJustification);
		// if(GLOBAL.breakFixServiceID=="")
		// this.props.loadBreakFixSupportCIMGroup(this.props.offeringId,"offering");
		// else
		// this.props.loadBreakFixSupportCIMGroup(GLOBAL.breakFixServiceID,"service");
		let getBreanFixkItemId = (this.props.breakFixItemId) ? (this.props.breakFixItemId).split(":") :""
		//let BRKItemId = getBreanFixkItemId[0];
		let breakFixActualItemId = this.props.breakFixItemId || ""; //getBreanFixkItemId[1];
		GLOBAL.breakFixId=breakFixActualItemId;
	this.props.loadBreakFixEditDetailsUpdate(breakFixActualItemId);
	// this.props.loadNewBreakFixSGAEWithQueryParams(this.props.breakFixEditDetails.serviceBased?this.props.breakFixEditDetails.serviceId:this.props.breakFixEditDetails.ciId,this.props.breakFixEditDetails.consumerCompany,"CIM",this.props.breakFixEditDetails.serviceBased?"service":"impactedCI",amsAttribute,this.props.breakFixEditDetails.serviceCompanyId);
	// this.props.loadBreakFixCIMDetails(this.props.breakFixEditDetails.cimGroup);
		GLOBAL.buttonStatus="false";

	}

	onCIMGroupChange(e){
      //alert("this.props.supportPGroup"+this.props.supportPGroup);
			let grpid=e.target.value.split(":");
			GLOBAL.breakfixgroupid=grpid[0];
			// this.props.loadBreakFixCIMDetails(grpid[0]);
    /*  if(this.props.cimGroup!=undefined){
				let actualValueCimGroup = (this.props.cimGroup) ? (this.props.cimGroup).split(":"):""
        this.props.loadBreakFixCIMDetails(actualValueCimGroup[0]);
      }else{
        console.log("Error in loading loadBreakFixSGI");
      }*/
    }

		renderSupportPGroup(breakFixData){
			let grouplist = [];
			if(!breakFixData || breakFixData.length==0){
				return(
						null
				);
			}

	breakFixData.map((breakFixObj)=>{
		grouplist.push(breakFixObj.assignmentGroupId+"~"+breakFixObj.assignmentGroupName)
	});
		//  console.log(grouplist.toString());

			grouplist = grouplist.filter( function( item, index, inputArray ) {
						 return inputArray.indexOf(item) == index;
			});
			//console.log("b:::"+grouplist.toString());

					 return grouplist.map((breakFixObj)=>{
						let breakfixobj1=breakFixObj.split("~");
					 return(
									 <option value={breakfixobj1[0]+":"+breakfixobj1[1]}>{breakfixobj1[1]}</option>
								 );
					 });
		}

		renderSupportPIAEGroup(breakFixData){
			//console.log("bgid::"+GLOBAL.breakfixgroupid)
			if(!breakFixData || breakFixData.length==0){
				return(
						null
				);
			}
					return breakFixData.map((breakFixObj)=>{
						if(breakFixObj.assignmentGroupId==GLOBAL.breakfixgroupid && breakFixObj.assignmentMemberId!=0)
						return(
									<option value={breakFixObj.assignmentMemberId+":"+breakFixObj.assignmentMemberName}>{breakFixObj.assignmentMemberName}</option>
								);
					});
		}


		renderCIMGroup(breakFixData){
			//alert(this.props.breakFixEditDetails.serviceId);
	      //alert("breakFixData:"+breakFixData);
	      if(breakFixData==undefined){
					//alert("Hi");
	        return(
	            null
	        );
	      }
	      if(GLOBAL.breakFixId!=""){
	        return breakFixData.map((breakFixObj)=>{
	          return(
	              <option value={breakFixObj.field1Value}>{breakFixObj.field1Key}</option>
	            );
	        });
	      }
	    }

			renderCIMDetails(breakFixData){
				//alert(this.props.cimGroup)
				//alert("breakFixData1"+breakFixData);
				//alert("this.props.cimGroup"+this.props.cimGroup);
				//alert(breakFixData);
				if(!breakFixData){
					return(
							null
					);
				}
				if(this.props.cimGroup!=""){
						return breakFixData.map((breakFixObj)=>{
							return(
										<option value={breakFixObj.field1Value+":"+breakFixObj.field1Key}>{breakFixObj.field1Key}</option>
									);
						});
				}
			}


	
	submit(values) {
		if((values.cimGroup===''||values.cimGroup==='0'||values.cimGroup===0||values.cimGroup===null||values.cimGroup===undefined || values.cimGroup==='null'))
				 swal({
					text: this.props.translator["Please select CIM group"],
					button: this.props.translator['OK'],
				 });
			else if((values.cimUserDeails=="null:null"||values.cimUserDeails=="0:null"||values.cimUserDeails===''||values.cimUserDeails==='0'||values.cimUserDeails===0||values.cimUserDeails===null||values.cimUserDeails===undefined ||values.cimUserDeails==='null'))
				swal({
					text: this.props.translator["Please select CIM user"],
					button: this.props.translator['OK'],
				 });
				 else if(values.rejectionJustification.trim().length===0 || values.rejectionJustification==undefined )
				 {
					swal({
						text: this.props.translator["Please provide rejection justification!"],
						button: this.props.translator['OK'],
					 });
				 }
	    else
		{
		let ref = this;
		this.setState({checkButtonStatus : true});
		return sleep(1000) // simulate server latency
		.then(() => {
			let breakFixConsumer=GLOBAL.breakFixConsumerID
			let cimGroup="";
			let cimGroupname="";
			let cimUser="";
			let cimUserName="";
			let actualValueCimGroup = (values.cimGroup) ? (values.cimGroup).split(":"):""
			let actualValueCimUser = (values.cimUserDeails) ? (values.cimUserDeails).split(":"):""
			cimGroup=actualValueCimGroup[0];
			cimGroupname=actualValueCimGroup[1];
			cimUser=actualValueCimUser[0];
			cimUserName=actualValueCimUser[1];
			if(`${JSON.stringify(values, null, 2)}`=="{}"){
				ref.setState({checkButtonStatus : false})
			throw new SubmissionError({ _error: 'Please select a values!' })
			}else if (values.cimGroup==undefined || values.cimGroup=="" || cimGroup==null || cimGroup=="null" || cimGroupname=="" || cimGroupname=="null" || cimGroupname == null) {
			//alert("HU2");
			ref.setState({checkButtonStatus : false})
			throw new SubmissionError({ _error: 'Please select CIM Group!' })
			}else if (GLOBAL.user_name=="") {
			//alert("HU2");
			ref.setState({checkButtonStatus : false})
			throw new SubmissionError({ _error: 'Please select CIM !' })
			}
			else if (values.rejectionJustification==undefined || values.rejectionJustification=="") {
			//alert("HU2");
			ref.setState({checkButtonStatus : false})
			throw new SubmissionError({ _error: 'Please provide rejection justification!' })
			}else{
			GLOBAL.rejectButtonStatus="true";
			axios.patch(GLOBAL.breakFixEditUrl+GLOBAL.breakFixId, {
						//======================
						//cimGroup : values.cimGroup,
						//cimUserDeails : values.cimUserDeails
						"holdJustification" : values.rejectionJustification,
						"criticalityStatus":"15",
						"modifiedBy" : "",
						"modifiedByName":"",
						// "cimGroup" : '',
						// "cimUser" : '',
						// "cimGroupname": '',
						// "cimUsername": ''
						"cimGroup" : cimGroup,
						"cimUser" : this.props.user_id,
						"cimGroupname":cimGroupname,
						"cimUsername":GLOBAL.user_name
					})
					.then(function (response) {
						console.log(response);
						//alert("ABC"+response.data.breakfixId);
						//alert(response.statusText);
					
						GLOBAL.removeCritical = 1;
						GLOBAL.rejectButtonStatus="true";
						GLOBAL.proposeButtonStatus="false"
						GLOBAL.criticalitystatusval=3;
						GLOBAL.commonpriorityval=3;
						GLOBAL.rejectCritical=1;
						GLOBAL.rejectmessage="RejectSaved";
						ref.props.loadBreakFixEditDetails(response.data.breakfixId);
						ref.props.loadStatusBasedHamburgerOptions('Breakfix',response.data.status,response.data.breakfixId);
						ref.setState({checkButtonStatus : false})
						//alert(response.statusText);
						// browserHistory.push("/editBreakFix/breakFixItemId:"+GLOBAL.breakFixId);
						navigationHooks.navigate("/editBreakFix/"+GLOBAL.breakFixId);
						ref.props.showProcessHierarchy();
					})
					.catch(function (error) {
						ref.setState({checkButtonStatus : false});
						console.log(error);
						//alert("H1"+error);
						//window.location="/BreakFixEditError";
						//GLOBAL.removeCritical = 1
					});
					ref.props.showProcessHierarchy();
					

			}
			//===================
			ref.props.showProcessHierarchy();
		})
	}
	}

	renderCIMGroupOptions(options){
		try{
			if(!(options && Array.isArray(options))){
				return null;
			}
			return options.map(item => {
				return(
					<option value={item['assignmentGroupId']+":"+item['assignmentGroupName']}>{item['assignmentGroupName']}</option>
					);
			});
		}catch(e){
			return null;
		}
	}
	
	render(){
		GLOBAL.user_name=this.props.full_name;
		let rejectmsgs=this.props.rejectmsg
		if(this.props.cimGroupDetails instanceof Error){
       //alert("H");
       return (
         <h2>
           Network Error Occured...Please try again....
         </h2>
       );
     }

		let { error,handleSubmit, pristine, reset, submitting,justification,cimGroup,cimUserDeails} = this.props

		let savemsg="";
		if(rejectmsgs==""){
			criticalityAction=<ButtonToolbar ><Button className="rgSidrkBtn smallBtn" bsSize="small" bsStyle="primary"  disabled={this.state.checkButtonStatus} type="button" onClick={handleSubmit(this.submit)} >{this.state.checkButtonStatus ? <ImSpinner6 className="icn-spinner"/>:null}{this.props.translator['Reject']}</Button></ButtonToolbar>
			savemsg=""
		}else{
			criticalityAction="";
			savemsg="The Work Item priority is rejected as critical";
		}
		return(
		<div className="hambTab">
			<div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
				<ul>
				<li>
				<button
				    type='button'
					title="Minimize the right panel"
					bsClass=""
					bsStyle=""
					className="closerightPanelBtn"
					onClick={() => {
					this.props.rightEditPanel(false);
					}}
				>
					<IoClose />
				</button>
				</li>
				</ul>
			</div>
			<div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Reject Critical']}</div></div>
			
			<div className='rBoxGap'>
			<font color="red">{error && <strong>{error}</strong>}</font>
			<Form.Group className="form-group" >
			  <Form.Label><span className="rStar"></span>{this.props.translator['Justification']}</Form.Label>
				<Field name="justification" component="input" type="text" className="form-control" placeholder={this.props.breakFixEditDetails.criticalJustification} disabled="true">
				</Field>
			</Form.Group>
			<Form.Group className="form-group" >
			  <Form.Label><span className="rStar"></span>{this.props.translator['CIM Group']}</Form.Label>
				<RulesDropdownList
					name="cimGroup"
					component="select"
					className="form-control"
					onChange={(event) => {
					this.onCIMGroupChange(event);
					}}
					rulesAttribute={this.state.rulesAttribute}
					disabled={true}
					companyId={this.props.breakFixEditDetails.consumerCompany}
					serviceCompanyId={this.props.breakFixEditDetails.serviceCompanyId}
					moduleName="incident"
					ruleType="assignment"
					ruleFor="criticalIncidentManager"
              	/>
			</Form.Group>
			<Form.Group className="form-group" >
			  <Form.Label><span className="rStar"></span>{this.props.translator['CIM']}</Form.Label>
				<Field name="cimUserDeails" component="input" type="text"className="form-control" readOnly>
				</Field>
			</Form.Group>
			<Form.Group className="form-group" >
			  <Form.Label><span className="rStar"></span>{this.props.translator['Rejection Justification']}</Form.Label>
				<Field name="rejectionJustification" component="input" type="text" className="form-control">
				</Field>
			</Form.Group>
			{criticalityAction}
			<font color="red">{savemsg}</font>
			</div>
		</div>
		);
	}
}

RejectCriticalTab = reduxForm({
  form: 'rejectCriticalTab',
	fields: ['justification','cimGroup','cimUserDeails','rejectionJustification'],
	initialValues: {},
  //destroyOnUnmount: false
	destroyOnUnmount: false,
	enableReinitialize:true
})(RejectCriticalTab);

let selector = formValueSelector('rejectCriticalTab') // <-- same as form name
RejectCriticalTab = connect(
  state => {
		// can select values individually
    let justification = selector(state, 'justification')
    let cimGroup = selector(state, 'cimGroup')
		let cimUserDeails = selector(state, 'cimUserDeails')

    return {
      justification,
      cimGroup,
			cimUserDeails
    }
  }
)(RejectCriticalTab)

export function mapStateToProps({ cimGroupDetails,cimUserDetails,breakFixEditDetails,breakFixSGAEName, breakFixSGAENameCIM}){
  //alert(breakFixServiceWithoutDomain);
	return {
		initialValues:{
		"justification": breakFixEditDetails.criticalJustification,
		"cimGroup":breakFixEditDetails.cimGroup+":"+breakFixEditDetails.cimGroupname,
		"cimUserDeails":GLOBAL.user_name
	},
		
		cimGroupDetails,cimUserDetails,breakFixEditDetails,breakFixSGAEName, breakFixSGAENameCIM };
}

function mapDispatchToProps(dispatch){
	return bindActionCreators({ loadBreakFixSupportCIMGroup,loadBreakFixCIMDetails,loadBreakFixEditDetails,loadBreakFixEditDetailsUpdate,loadBreakFixSGAE,loadNewBreakFixSGAEWithQueryParams,loadStatusBasedHamburgerOptions,loadTimelineData}, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(RejectCriticalTab);
