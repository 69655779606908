
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect } from "react";
import { Row, Col, Form, InputGroup, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import dummyimage from "_Images/service-icons/service-dummy.png";
import { getMultiSSOLogo } from "../../../../actions/foundation/general/MultiSSOAction";
import { Field, reduxForm } from "redux-form";
import { field_file } from "../../../common/formFields";
import ListLoader from "../../../common/loaders/ListLoader";

let MultiTenantLogo = () => {
  const dispatch = useDispatch();
  const multiSSOLogo = useSelector((state) => state.MultiSSOLogo.Logo_URL);
  const showLoader = useSelector((state) => state.commonLoader.isLoading);
  const tr = useSelector((state) => state.spcmReducer.tr);

  useEffect(() => {
    dispatch(getMultiSSOLogo());
  }, []);

  return (
    <>
    { showLoader ?
    <ListLoader /> : 
    <Form>
      <Row>
        <Col md={6} sm={6} xs={12}>
          <Form.Group className="form-group priceForm-group withImgbox">
            <Form.Label>
            <span className="rStar"></span>{tr["Logo"]}{" "}
            </Form.Label>
            <InputGroup>
              <Field
                className="form-control"
                component={field_file}
                type="file"
                name="multiTenantLogo"
              />
              <InputGroup.Text>
                <Image src={multiSSOLogo !== "" ? multiSSOLogo : dummyimage} />
              </InputGroup.Text>
            </InputGroup>
            <span>
              <b className="margin-l-10 f-size-11">
                (Accepted file types: .jpg, .jpeg, .png and .gif | Preferred
                size is 200px X 200px and limit is 5 MB)
              </b>
            </span>
          </Form.Group>
        </Col>
      </Row>
    </Form> }
    </>
  );
};

MultiTenantLogo = reduxForm({
  form: "multiTenantLogo",
  enableReinitialize: true,
  destroyOnUnmount: true,
})(MultiTenantLogo);

export default MultiTenantLogo;
