
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
//let user_id = userDetails.user_id;
//let company_id = userDetails.company_id;
import constants from "../utils/constants";
//let associated_company_id = userDetails.associated_company_id;
export const GLOBAL = {
  /* API starts-------------------- */

  recipientContactNumber: "",
  // breakFixConsumerUrl: '/api/users',
  breakFixConsumerUrlNew: "/api/userCompany/users",
  onbehalfuser: "/api/parentCompany/users",
  investigationTeamUrl: "/api/teammember/users?userId=",
  breakFixSourceUrl: "/serviceManagement/rest/reportedThrough",
  breakFixCreateUrl: "/serviceManagement/rest/breakfixAction",
  breakFixPatchUrl: "/serviceManagement/rest/breakfix/",
  rqsupportpriority: "",
  fulfillmentHoldingPatchUrl:
    "/serviceManagement/rest/orderFullfilmentItemAction",
  serviceName: "",
  breakFixEditUrl: "/serviceManagement/rest/breakfixAction/",
  breakFixKnowledgeArticleUrl: "/serviceManagement/rest/knowledgeArticleAction",
  linkKnowledgeArticlesUrl: "/serviceManagement/rest/linkKnowledgeArticles",
  getAttachedArticlesUrl: "/serviceManagement/rest/getAttachedArticles",
  removeAttachedArticlesUrl: "/serviceManagement/rest/removeAttachedArticles",
  breakfixChangeGrpname: "",
  breakFixServiceWithoutDomainUrl: "/serviceListSelfService",
  breakFixCriticalityUrl: "/api/criticalityListSelfService",
  valueAssign: false,
  valueAssignImpgrp: false,
  changeValue: false,
  Foundationgrp: false,
  FoundationgrpImp: false,
  Vip: "",
  breakFixUrgencyUrl: "/serviceManagement/rest/urgency",
  loadReasonCodeUrl: "/serviceManagement/rest/menuitemAction",
  breakFixProvideFixDropdownUrl: "/serviceManagement/rest/causeDropdown",
  breakFixUrlCIDetailCopy: "/api/config_items/copyRelationship",
  breakFixSGroupUrl: "/api/groupListSelfService?offeringId=",
  breakFixSGroupIUrl: "/api/userListSelfService?groupId=",
  breakFixSGroupIUrlForUser: "/api/cimUserListSelfService?groupId=",
  breakFixServiceDetailsDataUrl: "/api/offerings",
  // breakFixUserDetailsDataUrl: '/api/users/',
  breakFixUserDetailsDataUrl: "/api/getCompanyUserInfo",
  breakFixPriorityUrl: "/menuitemAction?module=Breakfix&field1Name=Priority",
  breakFixGroupListURL: "/api/breakfixGroupsAction",
  itemGroupListURL: "/api/itemGroupsAction",
  taskGroupListURL: "/api/taskGroupsAction",
  investigationGroupListURL: "/api/investigationGroupsAction",
  changeGroupListURL: "/api/changeGroupsAction",
  breakFixCauseUrl: "/api/breakfixcauseListSelfService",
  overallRisk: "",
  individualBlank: "",
  breakFixQuickViewURL: "/api/breakfixQuickViewAction/search",
  breakFixRelationQuickViewURL: "/api/breakfixRelationQuickViewAction",
  permittedModulesGetAction: "/api/getPermittedModules",
  changeFieldStatusData:
    "/serviceManagement/rest/workflowAction/module/ChangeManagement/status_id/",
  breakfixFieldStatusData:
    "/serviceManagement/rest/workflowAction/module/Breakfix/status_id/",
  ProbelemFieldStatusData:
    "/serviceManagement/rest/workflowAction/module/Investigation/status_id/",
  fulfillmentFieldStatusData:
    "/serviceManagement/rest/workflowAction/module/Fullfilment/status_id/",
  breakFixPriorityListURL: "/api/rest/priority",
  //breakfixQuickViewActionSortByOrderByURL: constants.d2curl+'/breakfixQuickViewActionSortByOrderBy',
  //breakfixQuickViewActionForGroupURL: constants.d2curl+'/breakfixQuickViewActionForGroup',
  breakFixAuditLogUrl: "/serviceManagement/rest/auditAction",
  breakFixActivityLogUrl: "/serviceManagement/rest/activityAction",
  breakFixActivityLogPostUrl: "/serviceManagement/rest/activity/",
  TMSActivityLogUrl: "/api/activity",
  breakFixRelatedLogUrl: "/serviceManagement/rest/breakfixParamAction",
  fulfillmentRelatedLogUrl:
    "/serviceManagement/rest/orderFullfilmentItemAction/",
  breakFixRelatedLogPostUrl: "/serviceManagement/rest/breakfixRelationsAction",
  relatedLogDeleteUrl: "/serviceManagement/rest/relationsAction/",
  cmdbRelatedLogDeleteUrl: "/api/config_items/relationship",
  onlyAllgrpchangemngmt: false,
  onlyAllgrpimplmt: false,
  beforesave: false,
  reopen: false,
  onlyAllgrpCreatechangemngmt: false,
  onlyAllgrpCreateimplmt: false,
  AcceptAsCritical: "No",
  cmdbRelatedWorkUrl: "/api/config_items/cmdb",
  slaRelatedWorkUrl: "/api/sla-list-by-company",
  breakFixStatusActionUrl: "/serviceManagement/rest/statusAction",
  genericTimelineDataUrl: "/serviceManagement/rest/genericTimelineData",

  breakFixRelatedDataUrl: "/serviceManagement/rest/breakfixRelationsAction",
  nestedRelationsDataUrl: "/serviceManagement/rest/nestedRelationsAction",
  fullfilmentRelatedDataUrl:
    "/serviceManagement/rest/fullfilmentRelationsAction",
  invRelatedDataUrl: "/serviceManagement/rest/investigationRelationsAction",
  invRelatedLogUrl: "/serviceManagement/rest/investigationActions",
  investigationRelatedLogPostUrl:
    "/serviceManagement/rest/investigationRelationsAction",
  changeRelatedDataUrl:
    "/serviceManagement/rest/changeManagementRelationsAction/",
  changeRelatedLogPostUrl:
    "/serviceManagement/rest/changeManagementRelationsAction",
  changeRelatedLogUrl: "/serviceManagement/rest/changeManagementAction/",
  changeConflictSubmit: "/changeConflictAction/",
  getPirDetailsUrl: "/changeReviewActionByChangeId/",

  d2cNumberUrl: "/serviceManagement/rest/d2crequest/",
  profileDetailsUrl: "/api/users/myprofile",
  profileDateFormatUrl: "/api/list/dateformat",
  profileTimeZoneUrl: "/api/list/timezone",
  profileCurrencyUrl: "/api/list/currency",
  profileCWLUrl: "/api/list/cwl",
  profilePostUrl: "/api/users/",
  languageList: "/api/languageList",
  fulfillmentfetchrecord: "/serviceManagement/rest/orderFullfilmentItemAction/",
  fulfillmentpost: "/serviceManagement/rest/orderFullfilmentItemAction/",
  fulfillmentListURL: "/api/orderFullfilmentQuickViewAction/search",
  fulfillmentRelationListURL:
    "/api/orderFullfilmentRelationQuickViewAction/search",
  fullFillmentStatusTimeLine:
    "/serviceManagement/rest/itemStatusForApprovalAction/",
  emailNotification: "/api/breakfix/notify",
  addOfferingToCartUrl: "/insertCartRecordSelfService",
  addComponentsToCart: "/insertCartComponentRecordSelfService",
  cartOfferingsUrl: "/getCartSelfService",
  //  cartOfferingsDetailUrl: constants.xsmspcmurl+'/addCartOfferingSelfService?',
  delteOfferingFromCart: "/api/cart/deletecart",
  offeringSlaDetailsUrl: "/api/slagoaltime",
  optionalComponentInCartUrl: "/getCartComponentSelfService",
  orderDeliveryTypeUrl: "/api/sladefinitions/service/",
  orderQuestionnareUrl: "/orderNowQuestionnaireSelfService/?offeringId=",
  approvalListURL: "/api/approval/myapprovallist/",
  adhocApproversListURL: "/api/approval/getAdhocApprovers/",
  postDelegation: "/api/delegate/createdelegate",
  delegationList: "/api/delegate/delegatelist",
  editDelegation: "/api/delegate/editDelegate/",
  postOrderUrl: "/serviceManagement/rest/orderFullfilmentAction",
  ordersGetUrl: "/serviceManagement/rest/itemBreakfixCombineViewAction/search",
  quickViewOrdersUrl: "/api/orderFullfilmentQuickViewAction/search",
  quickViewOrdersUrlMyRequest: "/api/orderFullfilmentQuickViewActionMyRequest",
  quickViewBrkOrdersUrl: "/serviceManagement/rest/breakfixAction/",
  feedbackGetOrdersUrl:
    "/serviceManagement/rest/itemBreakfixCombineViewAction/search",
  feedbackGetOrdersCountUrl:
    "/serviceManagement/rest/itemBreakfixCombineViewCountAction/search",
  feedbackOrdersUrl: "/serviceManagement/rest/orderFullfilmentAction/",
  feedbackBrkOrdersUrl: "/serviceManagement/rest/breakfixAction/",
  feedbackPostUrl: "/api/postReview",
  feedbackGetOrdersFrmEngineUrl: "/api/getReviewRating",
  taskurl: "/api/tasks/",
  editTaskUrl: "/api/tasks/",
  getTaskAuditDataURL: "/api/auditLogs/",
  getTaskGroup: "/api/taskgroup/grouplist?companyId=",
  getTaskIndividual: "/api/userListSelfService?groupId=",
  getRegionList: "/api/region-details",
  getEditTaskStatus: "/api/tasks/getEditTaskStatus/",

  fulfillmentemailNotification: "/api/fulfillment/notify",
  fulfillmentReason: "/rest/activity",
  updateItemApprovalStatus: "/serviceManagement/rest/item/",
  upoadFiles: "/serviceManagement/rest/uploadBreakfixImage",
  postOfferingFavUrl: "/insertaddfavouriteSelfService",
  postOfferingFavRemoveUrl: "/removefavouriteSelfService",
  investigationCreateUrl: "/serviceManagement/rest/investigationAction",
  investigationEditUrl: "/serviceManagement/rest/investigationAction/",
  investigationDetailsUrl: "/serviceManagement/rest/investigationActions",
  investigationTeamPostUrlUrl:
    "/serviceManagement/rest/investigationTeamAction",
  investigationTeamListUrl: "/serviceManagement/rest/investigationTeamAction",
  deleteInvestigationTeamMemberUrl:
    "/serviceManagement/rest/investigationTeamAction",
  acquaintedUrl: "/acquainted/getArticles",

  articleResultsUrl: "/article/getArticles",

  viewWorkdayRelatedSlasUrl: "/api/slaByWorkScheduleId/",
  viewHolidayRelatedSlasUrl: "/api/slaByHolidayScheduleId/",
  breakFixExternalSystemAttributesUrl: "/breakfix/getExternalSystemAttributes",
  articleSearchStringUrl: "/article/getArticleSearchStringResult",
  articleResultsLoadUrl: "/article/loadArticleResults",
  articleServicesLoadUrl: "/article/loadArticleServices",
  articleCategoryLoadUrl: "/article/loadArticleCategory",
  haveMandatoryQuestionFlagForService:
    "/getHaveMandatoryQuestionFlagForService",

  discourseSearchArticles: "/api/searchDiscourseTopics",
  /* GLOBAL variables starts--------- */

  statusBasedHamburgerOptionsURL: "/serviceManagement/rest/StatusActions",
  submitReviewGeneratorRFCUrl: `/serviceManagement/rest/submitReviewGeneratorRFCUrl`,
  approvalsGeneratorRFCUrl: `/serviceManagement/rest/approvalsGeneratorRFCUrl`,
  questionsCategoryWiseURL:
    "/serviceManagement/rest/orderFullfilmentQuestionsActionCategoryWise/",
  breakfixQuestionsCategoryWiseURL: "/getBreakfixQuestionsCategoryWise/",
  changeQuestionsCategoryWiseURL: "/changeQuestionsActionCategoryWise/",
  approverListURL: "/api/approval/orderapproverlist",

  investigationCauseUrl: "/serviceManagement/rest/investigationCause",
  breakFixSLAResponseURL: `/serviceManagement/rest/responseSlaTimeElapsedAction`,
  breakFixSLAResolutionURL: `/serviceManagement/rest/resolutionSlaTimeElapsedAction`,
  reviewCalcFrmREUrl: "/api/getReviewRatingCalculation",
  getLovValues: "/serviceManagement/rest/getLovValues",
  getRiskAssessment: "/serviceManagement/rest/riskAssessment",
  postChangeUrl: `/serviceManagement/rest/changeManagementAction`,
  changeEditUrl: `/serviceManagement/rest/changeManagementAction/`,
  postPIRUrl: `/serviceManagement/rest/changeReviewAction`,
  priorityCriticalityUrgencyUrl: "/serviceManagement/rest/menuitemAction",
  changeApprovalPendingUrl: "/api/approval/rfcapproverlist",
  changeTotalApprovalUrl: "/api/approval/rfcapproverleftlist",
  getRequesterDelegateeUrl: "/api/getRequesterDelegatee",
  companyImageURL: "/api/company/image",
  investigationQuickViewURL: "/api/investigationQuickViewAction",
  changeQuickViewURL: "/api/changeQuickViewAction",
  timeZoneURL: "/api/slalist/timezonesla",
  consumerUsersUrl: "/api/companyBasedConsumer",
  consumerUsersNewUrl: "/api/companyBasedConsumerNew",
  // consumerUsersNewUrl: '/api/getCompanyUserInfo',

  changeemailNotification: "/api/change/notify",
  //cmdb
  CMDBErrorObj: {},
  ci_name_error: '',
  ci_company_error: '',
  ci_class_error: '',
  ci_type_error: '',
  ci_category_error: '',
  ci_sub_category_error: '',
  ci_status_error: '',
  ci_sub_status_error: '',
  ci_allocation_date_error: '',
  ci_business_owner_error: '',
  ci_technical_owner_error: '',


  activeCategory: "service",
  ciNameUrl: "/asset-config/api/getCiName/",
  ciNameDetailsUrl: "/api/config_items/ci_details",
  ciClassUrl: "/asset-config/api/getCiClass",
  ciManufacturerUrl: "/asset-config/api/getCiManufacturer",
  ciStatusUrl: "/asset-config/api/getCiStatus",
  ciCurrentStatusUrl: "/asset-config/api/getCiStatusCurrent",
  ciMenulistStatus: "/api/menulist/status",
  cmdbQuickViewListURL: "/asset-config/api/getCmdbItemList",
  //cmdbRelatedLogDeleteUrl: '/api/config_items/relationship',
  cmdbMyAssetCIListViewUrl: "/api/config_items/V2",
  cmdbQuickViewListFilterURL: "/asset-config/api/getCmdbFilteredItemList",
  userSpecificDetailsURL: "/api/getSpecificUserDetails",
  AMSGRP: "",
  AMSALLgrp: "",
  AMSEXISTIMP: "yes",
  AMSCREATEEXIST: "yes",
  AMSCREATEIMPEXIST: "yes",
  user_name: "",
  cmdbQuickViewListFilterSearchStringURL:
    "/asset-config/api/getCmdbFilteredSearchString",
  grpid: "",
  grpname: "",
  impactedId: "",
  impactedName: "",
  ciCompanyUrl: "/asset-config/api/getCiCompany",
  ciCompanyUrl_1: "/asset-config/api/getCiCompany1",
  ciBusinessOwnerUrl: "/asset-config/api/getCiBusinessOwner",
  ciSupportCompanyUrl: "/asset-config/api/getCiSupportCompany",
  ciSupportGroupUrl: "/asset-config/api/getCiSupportGroup",
  ciSupportAllGroupUrl: "/asset-config/api/getCiSupportAllGroup",
  ciSupportQueryAllGroupUrl: "/asset-config/api/getCiSupportQueryAllGroup",
  ciLocationUrl: "/asset-config/api/getCiLocation",
  ciEnvUrl: "/asset-config/api/getCiEnv",
  ciResidualRiskUrl: "/serviceManagement/rest/getResidualRiskByCompany",
  ciBillingUrl: "/asset-config/api/getCiBilling",
  ciCategoryUrl: "/asset-config/api/getCiCategories",
  ciCatUrl: "/api/categories/displayActiveCategory",
  ciSubCatUrl: "/api/categories/displayActiveSubcategory",
  ciCatSubcatUrl: "/api/categories/getResolutionCatSubcatByCategoryId",
  postCiDataURL: "/asset-config/api/postCiData",
  postCiScannedDataURL: "/asset-config/api/postCiScannedData",
  ciEditDetailsUrl: "/asset-config/api/getCiEditDetails",
  ciModelName: "/asset-config/api/config_items/modelName/ci",
  ciTimeLineDataUrl: "/asset-config/api/getTimeLineData",
  postCiArrtibuteUrl: "/asset-config/api/postCiAttribute",
  patchCiArrtibuteUrl: "/asset-config/api/patchCiAttribute",
  deleteCiArrtibuteUrl: "/asset-config/api/deleteCiAttribute",
  ciArrtibuteUrl: "/asset-config/api/getCiAttribute",
  getCiAttributeValues: "/asset-config/api/getCiAttributeValues",
  ciAuditLogUrl: "/asset-config/api/getCiAuditLog",
  patchCiDataURL: "/asset-config/api/patchCiData",
  getCIUserBasedLocationUrl: "/asset-config/api/getCiLocationUserBased",
  loadCMDBAttachmentList: "/api/attachments/",
  // Business Function
  postBusinessFunctionUrl: "/api/postBusinessFunction",
  getBusinessFunctionUrl: "/api/getBusinessFunction",
  businessFunctionListData: "/api/listViewBusinessFunction",
  patchBusinessFunction: "/api/updateBusinessFunction",

  //ham
  hamListClasses: "/api/classes",

  //implPlanList
  implPlanList: "/api/getImplementationPlanList",
  implPlanURL: "/api/getImplementationPlan/",
  getAttachedFile: "/api/getAttachedFile/",

  //sla start
  slaLovUrl: "/serviceManagement/rest/menuitemAction",
  slareportLovUrl: "/authentication/module",
  slaCompanyUrl: "/api/sla/companylist?userId=",
  slaServiceUrl: "/api/serviceDetails/companies/",
  slaAttributeUrl: "/api/sla/menulist?type=attribute&module=",
  slaHolidayScheduleUrl: "/api/slaDef/getHolidayScheduleData/",
  slaWorkdayScheduleUrl: "/api/getWorkdayScheduleData",
  slaHolidayScheduleDefUrl: "/api/slaDef/getHolidayScheduleData/",
  slaWorkdayScheduleDefUrl: "/api/slaDef/getWorkdayScheduleData/",
  // slaWorkdayListUrl: '/api/getWorkdayScheduleData'
  slaWorkdayListUrl: "/api/getWorkdayScheduleWithPagination",
  // slaHolidayListUrl: '/api/getHolidayScheduleData',
  slaHolidayListUrl: "/api/getHolidayScheduleWithPagination",
  slaWorkdayDayAndStatusUrl: "/api/slaworkday/menulist",
  slaDefPostUrl: "/api/sladefinition",
  slaDefViewUrl: "/api/sladefinitions/",
  slaDefPatchUrl: "/api/sladefinition/",
  slaCriteriaPostUrl: "/api/slacriteria/",
  slaCriteriaDeletetUrl: "/api/deleteSlacriteria/",
  slaCriteriaListUrl: "/api/slacriterias/",
  slaMilestonePostUrl: "/api/slamilestone/",
  slaMilestoneDeleteUrl: "/api/deleteSlamilestone/",
  slaMilestoneListUrl: "/api/slamilestones/",
  workdaySchedulePost: "/api/createWorkdaySchedule",
  holidaySchedulePost: "/api/createHolidaySchedule",
  workdayScheduleItemDetails: "/api/getParticularWorkdayScheduleData",
  workdayScheduleUpdate: "/api/workDayScheduleUpdation/",
  holidayScheduleItemDetails: "/api/getParticularHolidayScheduleData/",
  holidayScheuleUpdate: "/api/holidayScheduleUpdation/",

  loadHolidayDetails: "/api/loadHolidayDetails",

  slaHolidatEntryPostUrl: "/sla-def/api/createHolidayEntry",
  slaHolidatEntryUpdateUrl: "/sla-def/api/updateHolidayEntry/",
  slaHolidatEntriesGetUrl: "/sla-def/api/getHolidayEntries/",
  slaHolidatEntryDeleteUrl: "/sla-def/api/deleteHolidayEntry/",

  slaHolidatSearchStringUrl: "/sla-def/api/slaHolidaySearchStringData",
  slaDefMandatoryActiveUrl: "/api/sLaDefActiveMandatory/",
  slaModuleStatusUrl: "/sla-def/api/slaModuleStatus/",
  amsModuleStatusUrl: "/rest/menuItems",
  attributeBasedSlaUrl: "/api/atttibuteBasedSLA",
  taskTemplatesDetails: "/api/templatesDetails",
  //sla end
  reportingServiesApi: "/reportingServices",
  //reportingconsole
  getReportFieldsUrl: "/api/report/reportFields/",
  getReportCMDBFieldsUrl: "/api/report/reportCMDBFields",
  getReportTaskFieldsUrl: "/api/report/reportTaskFields",
  /* cart api's */
  getServiceDetails: "/getOfferingDetailsForRestifySelfService",
  getComponentDetails: "/getOfferingsForComponentRestifySelfService",
  getServicesDetailsAddedInCart: "/addCartOfferingSelfService",
  getTaskStatusForEdit: "/api/taskstatus",
  getLeftApprovalUrl: "/api/approvalruleexist/ruleexist?",
  getamslist: "/rest/ruleConfigurations",
  getamseditdata: "/rest/ruleConfiguration",
  getAMSendEditdataNew: "/rest/updateRuleConfiguration",
  sendAMSdata: "/rest/addRuleConfiguration",
  getRelatedOfferings: "/api/relatedOfferings",
  getAmsFilteredList: "/rest/ruleConfigurationFiltered",
  getallCIMgroups: "/rest/ruleConfigurationCompany",
  // getamsruleconditioneditdata: '/rest/ruleConditions',
  // getamsruleconditiondata: '/rest/ruleCondition',
  getcategoryhomepage: "/getCategoryListForRestifySelfService",
  getsubcategoryhomepage: "/getSubCategoryListForRestifySelfService",
  getTaskInputVariables: "/api/tasks/fetchOutputVariables",
  getTaskOutputVariables: "/api/TaskOutputs/",
  postOutputVariables: "/api/postTaskOutput",
  updateOutputVariables: "/api/updateTaskOutputs/",
  getServiceQuestionnaireCategories: "/getServiceQuestionnaireCategories",
  getParentQuestionsForCategory: "/getParentQuestionsForCategory/",
  getChildQuestion: "/getChildQuestion/",
  getTypeAheadChildQuestion: "/getTypeAheadChildQuestion/",
  getServiceCategoriesCount: "/getServiceCategoriesCount/",
  postIncidentOrder: "/serviceManagement/postIncidentOrder",
  postStandardChangeOrder: "/serviceManagement/rest/changeManagementAction",
  postReportVersionData: "/ReportData",
  getApplicationDetails: "api/getApplicationDetails",
  breakFixRelateAllWorkItem: "/serviceManagement/rest/relationsAllAction",

  offeringsForOrder: [],
  offeringidtotal: [],
  selectedOptionalComponents: [],
  selectedOptionalComponentsForSingleOffering: [],
  componentIdsForOrderNow: [],
  buildingValueType: "",
  cubicleValyeType: "",
  orderTotal: 0.0,
  deliveryType: "",
  SelectedServiceDetailcompanyID: "",
  breakFixConsumerID: "",
  breakFixServiceID: "",
  // amsCIID: '',
  // amsCINAME: '',
  createAMSRuleUsing: "",
  breakFixReportedOn: "",
  serviveName: "",
  serviceNameDisplay: "",
  breakFixId: "",
  removeCritical: 1,
  proposeButtonStatus: "false",
  acceptButtonStatus: "false",
  rejectButtonStatus: "false",
  priorityColorType: "",
  priorityValueType: "",
  provideFix: 0,
  breakFixServiceName: "",
  breakFixConsumerName: "",
  supportPGroupName: "",
  supportPIGroupName: "",
  //cmdb
  ciId: "",
  ciName: "",
  ciNameRfc: "",
  ciIdRfc: "",
  CIError: "",
  ciIdNumber: "",
  ciIdNumberRfc: "",
  ciIdEdit: "",
  ciNameCopy: "",
  ciNameEdit: "",
  ciIdNumberEdit: "",
  ciClass: "",
  ciClassRfc: "",
  ciClassNameSelected: "",
  ciClassName: "",
  ciClassNameRfc: "",
  categoryName: "",
  categoryNameRfc: "",
  environmentName: "",
  environmentNameRfc: "",
  categoryNameEdit: "",
  environmentNameEdit: "",
  ciConsumerCompany: "",
  ciConsumerCompanyRfc: "",
  ciSupportCompany: "",
  ciSupportCompanyRfc: "",
  ciClassId: "",
  ciClassIdRfc: "",
  ciConsumerCompanyEdit: "",
  ciSupportCompanyEdit: "",
  ciClassIdEdit: "",
  ciLocationId: "",
  ciLocationIdRfc: "",
  ciLocationName: "",
  ciLocationNameRfc: "",
  ciLocationIdEdit: "",
  ciLocationNameEdit: "",
  //validation start
  validationStateService: "",
  validationStateConsumer: "",
  validationStateIssueDescription: "",
  validationStateReportedOn: "",
  validationStateReportedThrough: "",
  validationStateCriticality: "",
  validationStateUrgencyMode: "",
  proposeCritical: 0,
  supportPGroupValue: "",
  relatedfilterval: "",
  relatedfiltertext: "",
  serviceErrorColor: "",
  grpErrorColor: false,
  consumerErrorColor: "",
  issueErrorColor: "",
  GroupReassignReasonErrorColor: "",
  showGroupReassignReasonErrorColor: false,
  probableCauseErrorColor: "",
  businessImpactErrorColor: "",
  reportedErrorColor: "",
  reportedOnErrorColor: "",
  throughErrorColor: "",
  sgErrorColor: "",
  companyError: "",
  sgIErrorColor: "",
  supportPGroupErrorColor: "",
  criticalityErrorColor: "",
  urgencyErrorColor: "",
  categorizationErrors: {},
  categorizationObj: {},
  sourceErrorColor: "",
  relatedtextdisplay: "",
  criticalitystatusval: "",
  commonpriorityval: "",
  processhierarchy: "",
  proposemessage: "",
  acceptmessage: "",
  acceptCritical: "",
  rejectCritical: "",
  rejectmessage: "",
  consumerCompanyId: "",
  consumerCompanyIdRfc: "",
  businessCriticalityId: "",
  businessCriticality: "",
  //orderNow
  totaloptionalcomponents: [],
  serviceodfilter: "",
  provideridfilter: "",
  selectedlang: "",
  componentaddvalue: "",
  componenttotalvalue: "",
  componentonetimebilling: "",
  componentdailybilling: "",
  componentweeklybilling: "",
  componentbiweeklybilling: "",
  componentmonthlybilling: "",
  componentquarterlybilling: "",
  componenthalfyearlybilling: "",
  componentyearlybilling: "",
  billingmethodtotal: "",
  questionnairevalues: [],
  msg: "",
  fulfillmentindividualval: "",
  fulfillmentindividualtext: "",
  fulfillmentgroupval: "",
  fulfillmentgrouptext: "",
  fulfillsavemsg: "",
  fulfillmentHoldCode: "",
  fulfillmentHoldVal: "",
  fulfillmentDate: "",
  fulfillmentPageLoad: false,
  fulfillmentgroupReason: "",
  incidentgroupReason: "",
  serialNumber: "",
  serialNumberEdit: "",
  serialNumberErrorColor: "",
  //orderNow end
  taskdescriptionval: "",
  tasksequenceval: "",
  tasksequencetypeval: "",
  taskstatusval: "",
  taskassignmentgroupval: "",
  taskassignementuserval: "",
  taskinfoval: "",
  taskeditflag: "",
  taskaddflag: "",
  taskcode: "",
  taskregid: "",

  //reviewFilter
  checkedServiceValue: "",
  checkedCorrectionValue: "",
  checkedPendingValue: "",
  checkedCompletedValue: "",

  //myOrdersFilter
  checkedAllOrderType: "",
  checkedServiceOrder: "",
  checkedCorrectionOrder: "",
  checkedAllStatus: "",
  checkedOpenStatus: "",
  checkedClosedStatus: "",
  checkedCancelStatus: "",
  fulfillmentrenderval: 0,
  breakFixCompanyName: "",
  breakfixgroupid: "",
  breakfixongrpchangeid: "",
  supportPGroupNameEdit: "",
  supportPIGroupNameEdit: "",
  teamGroupId: "",
  teamGroupName: "",
  //Investigation
  innvestigationGetUrl: "/serviceManagement/rest/investigationList",
  consumerCompanybreakfix: "",
  consumerCompanybreakfixRfc: "",
  taskclientid: "",
  investigationId: "",
  sgAllErrorColor: "",
  AMSEXIST: "No",
  //changeManagement
  changeManagementListURL: "/serviceManagement/rest/getChangeManagementList",
  approvalHistoryApi: "/api/fetchApprovalHistory",
  checkmoduleval: "",
  cinameErrorColor: "",
  ciclassErrorColor: "",
  cicompanyname: "",
  cigroupname: "",
  ciindividualname: "",
  cilocationname: "",
  cmdbrenderval: "",
  cmdbrenderindval: "",
  cmdbrendercatval: "",
  cmdbrenderscatval: "",
  attid: "",
  attval: "",
  attkey: "",
  pirsubmitflag: "",
  consumerName: "",
  consumerId: "",
  serviceId: "",
  deliveryRecipientId: "",
  deliveryRecipientName: "",
  onBehalfUserLocationId: "",
  onBehalfcubicle: "",
  onBehalfLocationName: "",
  editattval: "",
  editattkey: "",
  cmdbdiagram: "",
  cisupportcompanyname: "",
  citechindividualname: "",
  cistatusErrorColor: "",
  categoryErrorColor: "",
  subCategoryErrorColor: "",
  ciTypeErrorColor: "",
  cicompanyErrorColor: "",
  cimanufacturerErrorColor: "",
  cibusinessownerErrorColor: "",
  citechownerErrorColor: "",
  // cilocationErrorColor: "",
  ciSupportCompanyError: "",
  ciSupportGroupError: "",
  ciSubStatusErrorColor: "",
  implementationIndividual: "",
  selectedCompany: "",
  //Edit Task
  assignmentGrpListErrorColor: "",
  assignToListErrorColor: "",
  //SLA
  slaNameErrorColor: "",
  slaCompanyNameErrorColor: "",
  slaModuleNameErrorColor: "",
  slaStatusErrorColor: "",
  slaServiceNameErrorColor: "",
  slaImpactedNameErrorColor: "",
  slaSrviceNameErrorColor: "",
  serviceBased: true,
  slaTypeErrorColor: "",
  slaTargetForErrorColor: "",
  slaTargetTimeErrorColor: "",
  slaRetroactiveRequiredErrorColor: "",
  slaWorkdayScheduleErrorColor: "",
  slaHolidayScheduleErrorColor: "",
  specificVendorTicket: "/serviceManagement/rest/sepecificVendorTicketAction",
  delSpecificVendorTicket:
    "/serviceManagement/rest/sepecificVendorTicketAction",
  specificVendorTicketList:
    "/serviceManagement/rest/sepecificVendorTicketAction",
  scheduleNameErrorColor: "",
  scheduleCompanyErrorColor: "",
  scheduleDayErrorColor: "",
  scheduleStatusErrorColor: "",
  scheduleStartTimeErrorColor: "",
  scheduleEndTimeErrorColor: "",

  scheduleHNameErrorColor: "",
  scheduleHCompanyErrorColor: "",
  scheduleHDateErrorColor: "",
  scheduleHStatusErrorColor: "",

  slaCriteriaTypeIdErrorColor: "",
  slaCriteriaAttributeErrorColor: "",
  slaCriteriaOperatorErrorColor: "",

  slaMilestoneTypeIdErrorColor: "",
  slaMilestoneActionErrorColor: "",
  slaMilestoneTargetErrorColor: "",
  slaMilestoneTargetValueErrorColor: "",
  slaRectroactiveToggle: "",
  slaResponseRecived: "",
  typeId: "",
  typeName: "",
  servicecatid: "",
  servicecatname: "",
  servicelocationid: "",
  servicelocationname: "",
  servicedepartmentid: "",
  servicedepartmentname: "",

  reportattname: "",
  reportattval: "",
  reportattoperator: "",
  editreportattval: "",

  pIssueErrorColor: "",
  pThroughErrorColor: "",
  pCriticalityErrorColor: "",
  pUrgencyErrorColor: "",
  startindex: "",
  endindex: "",
  consumerCompanyName: "",
  consumerCompanyNameRfc: "",
  showChange: "",
  changeTimingCode: "",
  changetime: "",
  checkchange: "",
  changepageLoad: false,
  resetChangeEdit: false,

  //homepageIndexing
  startindexFeatured: "",
  endindexFeatured: "",
  startindexBestSelling: "",
  endindexBestSelling: "",
  startindexNewLaunches: "",
  endindexNewLaunches: "",
  startindexComingSoon: "",
  endindexComingSoon: "",
  startindexFree: "",
  endindexFree: "",
  startindexFavorite: "",
  endindexFavorite: "",
  startindexCategory: "",
  endindexCategory: "",

  //global search
  startindexSearch: "",
  endindexSearch: "",

  //breakFix
  breakfixShowLoader: false,
  complexityCode: "",
  //TMS
  tmsAttachList: "/api/getTMSAttachList/",
  searchbarcategory: "All",
  reqSptDesIssCharMax: "",
  reqSptDesIssCharLeft: "",
  reqSptAddInfoCharMax: "",
  reqSptAddInfoCharLeft: "",
  problemqualifyCharLeft: "",
  problemqualifyCharMax: "",
  activetab: "",
  loadRecipientLocations: "/loadRecipientLocations/",
  loadRecipientLocationsIsShared: "/api/user/locationType",
  loadRecipientCountries: "/api/getCountryNames",

  //CategoryBoard
  categoryBoardViewUrl: "/api/categories/getAllCategoriesData",
  categoryDropdownUrl: "/api/categories/getCategoriesDetailsOnly",
  categoryByCompanyIdDropdownUrl: "/api/categories/getCategoriesByCompanyId",
  statusDropdownUrl: "/api/categories/categoryStatus",
  categorySubmitUrl: "/api/categories/",
  companyAssociationUrl: "/api/categoryCompany/",
  bulkPurchaseQuestions:
    "/api/requiredInformation/getQuestionsForTableHeadersType/",
  ciCategorySubmitUrl: "/api/categories/bulkPost",
  ciCategoryListUrl: "/asset-config/api/getCiCategoryList",
  ciCategoryUpdateUrl: "/asset-config/api/patchCiCategoryData/",
  ciResCategoryPostUrl: "/api/categories/bulkPostForResolutionCatSubCat",
  ciResCategoryPatchUrl: "cmdb/api/categories/resCatSubcat/",

  //IntegrationHub
  integrationHubViewUrl: "/api/list-api-metadata",
  integrationScenarioUrl: "/api/list-scenarioids",
  integrationHubSubmitUrl: "/api/upsert-api-metadata",
  integrationDataDeleteUrl: "/api/delete-api-metadata",

  //InstanceAvailability
  // getAllCINamesURL:'/api/config_items',
  sreReportDataURL: "/ReportData",
  proposeproblemfromimpacted: 0,
  holidayCompanyError: "",
  holidayCompanyEditError: "",
  holidayscheduleNameError: "",
  statusError: "",
  WorkScheduleCompanyEditError: "",
  isLogout: "false",
  isExternalChangeCreate: "",
  isExternalChangeEdit: "",
  isExternalInvestigationCreate: "",
  isExternalInvestigationEdit: "",
  isExternalFulfillmentCreate: "",
  isExternalFulfillmentEdit: "",
  isExternalIncidentCreate: "",
  isExternalIncidentEdit: "",
  isExternalAmsCreate: "",
  isExternalAmsEdit: "",
  impleGroup: 0,
  changeMangemtGroup: 0,

  // AMS
  getAttributesList_ams: "/getAttributesList",
  getOperatorsList_ams: "/getOperatorsList",
  getAMSAttributeList_ams: "/getAMSAttributeList",
  deleteAMSAttribute_ams: "/deleteAMSAttribute",
  amsCriteriaPostUrl: "/rest/createAMSAttribute",
  filesToBeSent: [],

  //SuperSet Dashboard id
  reportsConfiguration: "/reportsConfiguration",
  changeDashboardId: 34,
  problemDashboardId: 32,
  incidentDashboardId: 26,
  taskDashboardId: 35,
  fulfillmentDashboardId: 33,
  CmdbId: 50,

  //// watchers api urls -
  addWatcherDetails: "/serviceManagement/rest/addWatcherDetails",
  getWatcherDetails: "/serviceManagement/rest/getWatcherDetails",
  updateWatcher: "/serviceManagement/rest/updateWatcherDetails/",
  countWatcher: "/serviceManagement/rest/countWatcherDetails",
  cmdbCreateSelectedText: {},

  //foundation tags
  loadTagCompanyUrl: "/service/api/serviceportfolio/companylist",
  loadBusinessFunctionUrl: "/api/getBusinessFunction",
  sxMenulistUrl: "/api/getMenuListDataCommon",
  loadAllTagsListUrl: "/api/getAllTagsList",
  loadAllCompositeTagsUrl: "/api/getCompositeTagData",
  loadMemberTagsForCompositeTagUrl: "/api/getMemberTagsForComposite",
  loadTagDetailsByTagIdUrl: "/api/getTagDetailsByTagId",
  loadTagMemberDependencyDataUrl: "/api/getTagMemberDependData",
  deleteTagMemberDependencyDataUrl: "/api/deleteTagMemberData",
  loadMemberTagsForChecklistTagUrl: "/api/getMemberTagsForChecklist",
  loadAllChecklistTagsUrl: "/api/getChecklistTagData",

  // On-Behalf Variables -
  onBehalfUserCompanyId: "",
  onBehalfUserCompanyName: "",
  onBehalfUserDetails: {},

  //Cmdb tags
  patchCiTagDataUrl: "/asset-config/api/tags/",
  getCiTagdataUrl: "/asset-config/api/tags/",
  getFdnTagDataUrl: "/api/getTagData",

  //FORUM API
  getAllForums: "/governance/rest/getAllForumsDetails",
  createForum: "/governance/rest/saveforumDetails",
  getAllCompany: "/api/serviceportfolio/usercompanylist",
  getForumType: "/governance/rest/getForumType",
  subTrackDetailsByTrackName: (name) =>
    `/governance/rest/subTrackDetailsByTrackName/${name}`,
  getForumStatus: "/governance/rest/getforumStatus",
  trackDetails: (id) => `/governance/rest/trackDetails/${id}`,
  getAllTracks: "/governance/rest/getAllTracks",
  getAllSubTracks: "/governance/rest/getAllSubTracks",
  getRegionListFnd: "/api/regionList",
  getForumById: "/governance/rest/getFourmDetailById",
  updateForum: "/governance/rest/updateforumDetails",
  committeDetailsByForumName: (name) =>
    `/governance/rest/committeDetailsByForumName/${name}`,
  allcommitteDetailsByForumName: "/api/getGroupListCommitee",
  locationDetails: "/governance/rest/locationDetails",
  getCiSupportAllGroup: (custId) =>
    `/asset-config/api/getCiSupportAllGroup/id/${custId}`,
  saveMeettingDef: "/governance/rest/saveMeetingDefinationDetails",

  getMeetingDefinationDetails: (meetingId) =>
    `/governance/rest/meetingDefinationDetails/${meetingId}`,
  getOccurrencesList: (definationId, pageNumber, pageSize, queryString) =>
    `/governance/rest/occurananceMeetingDetailsByDefinationId/${definationId}/${pageNumber}/${pageSize}?${queryString}`,

  notifyAction: "/governance/rest/notifyAction",
  getAction: "/governance/rest/action",
  getStatusTimeline: "/governance/rest/getStatusTimeline",
  occurenceById: (id) =>
    `/governance/rest/occurananceMeetingDetailsByOccurananceId/${id}`,
  getSuppliers: "/service/api/chainEntities/getChainCompanyList",

  //ACTION ITEM API
  getActionItems: "api/actionitems",
  getActionMenuDd: "api/action/menu",
  deleteActionAttachment: "/api/attachments/attachment",
  getAllForum: "/governance/rest/forumDetails/",
  getAllCommittee: "api/getGroupListCommitee",
  getAllTrack: "/governance/rest/trackDetails/",
  getAllSubtrack: "/governance/rest/subTrackDetailsByTrackName/",
  getAuditLogs: "/api/auditLogs",
  getActionItemById: "/api/actionitems",
  getActionStatusTimeline: "/api/actionitems/action/status-rules/",
  saveStatus: "/api/actionitems/action/status",
  saveAttachment: "/actionitem/api/attachment/",
  saveWorkNotes: "/actionitem/api/worknote/",
  getAttachment: "/api/attachments",
  getActionItemWorkNotes: `/actionitem/api/attachmentWorknotes`,
  postActionItemWorkNotes: (actionitemCode) =>
    `/actionitem/api/worknote/${actionitemCode}`,
  filteredActionItemList: "/api/actionitems/applySearch",

  updateOccurrence: "/governance/rest/updateMeetingOccurrananceDetails",
  cancelMeetingOccurrence: "/governance/rest/cancelMeetingOccurrence",
  govAuditLogs: "/governance/rest/auditLogs",
  saveWorkNotesDetails: "/governance/rest/saveWorkNotesDetails",
  getWorkNotesDetails: "/governance/rest/getWorkNotesDetails",
  getMinutesDetails: "/governance/rest/getMinutesDetails",
  saveMinutesDetails: "/governance/rest/saveMinutesDetails",
  updateMinutesDetails: "/governance/rest/updateMinutesDetails",
  trackingDetails: (id) => `/governance/rest/trackingDetails/${id}`,
  saveOccurranceTrackingDetails:
    "/governance/rest/saveOccurranceTrackingDetails",

  getAllActiveInActiveForums: "/governance/rest/getAllActiveInActiveForums",
  occurrenceSts: "/governance/rest/occurrenceSts",
  categoryDetails: "/governance/rest/categoryDetails",
  getMinutesAttachmentTypes: "/governance/rest/getMinutesAttachmentTypes",
  deleteMinutesDetails: "/governance/rest/deleteMinutesDetails",
  updateMeetingDefinationDetails:
    "/governance/rest/updateMeetingDefinationDetails",
  getMeetingOccurrencesByMonth: "/governance/rest/getMeetingOccurrencesByMonth",
  getAllMeetingDefinationSts: "/governance/rest/getAllMeetingDefinationSts",

  //aiosem
  aiosemSXMenulistUrl: "/rest/aiosem/menuModuleAndField1Name",
  loadScheduleListUrl: "/rest/aiosem/searchScheduleAction",
  loadScheduleDetailsUrl: "/rest/aiosem/getScheduleAction",
  loadScheduleAssociationDataUrl:
    "/rest/aiosem/searchScheduleAssociationAction",
  deleteScheduleAssociationDataUrl:
    "/rest/aiosem/deleteScheduleAssociationAction",
  loadRelatedScheduleDataUrl:
    "/rest/aiosem/getRelateScheduleByScheduleIdAction",
  deleteRelatedScheduleDataUrl: "/rest/aiosem/deleteRelateScheduleAction",
  loadRosterListUrl: "/rest/aiosem/searchRosterAction",
  loadRosterDetailsUrl: "/rest/aiosem/getRosterAction",
  loadRosterMembersDataUrl: "/rest/aiosem/getRosterMemberAction",
  deleteRosterMembersDataUrl:
    "/rest/aiosem/deleteRosterMemberListWithGroupIdAction",
  createShift: "/rest/aiosem/saveShiftAction",
  getEvents: "/rest/aiosem/searchEventAction",
  getShifts: "/rest/aiosem/searchShiftAction",
  getShiftListData: "/rest/aiosem/searchShiftAction",
  patchShiftData: "/rest/aiosem/updateShiftAction",
  getShiftByShiftId: "/rest/aiosem/getShiftAction",


  //aism
  loadAismTrainingMetrics:"/api/loadAismTrainingMetrics",

  //Contract
  contractVendorUrl: "/asset-config/api/getCiSupportCompany/id",
  contractRelatedWorkUrl: "/api/relationships/ciContractRelationship",
  contractRelatedUrl: "/api/relationships/ciContractRelationship",
  adminownerErrorColor: "",

  
  //admin preferences
  loadAdminPreferencesListUrl: "/api/getAdminPreferencesListView",

  getActiveQuadrantsId: "/scorecard/rest/getActiveQuadrantsId",
  getAllActiveQuadrants: "/scorecard/rest/getAllActiveQuadrants",
  getCancelMeetingDefination: "/governance/rest/getCancelMeetingDefination",
  saveSuppliersScorecard: "/scorecard/rest/saveSuppliersScorecard",
  submitSuppliersScoreCard: "/scorecard/rest/submitSuppliersScoreCard",

  //VerticalCatalogApi
  getVerticalCatalogLabels: "/service/getVerticalPanelLabels",
  getCommonOfferingsApi: "/service/getCommonOfferingsApi",
  getFeaturedOfferings: "/api/vertical/getFeaturedOfferingForVertical",
  getBestSellingOfferings: "/api/vertical/getBestSellingOfferingForVertical",
  getFreeOfferings: "/api/vertical/getFreeOfferingForVertical",
  getUpcomingOfferings: "/api/vertical/getUpcomingOfferingForVertical",
  getMyFavouritesOfferings: "/api/vertical/getFavouriteOfferingForVertical",
  getNewLaunchesOfferings: "/api/vertical/getNewLaunchesOfferingForVertical",
  getOfferingsByBusinessFunction: "/service/getOfferingsByBusinessFunction",
  getOfferingsByCategory: "/service/getOfferingsByCategory",
  getOfferingsBySubCategory: "/service/getOfferingsBySubCategory",
  slaHeadEnable: false,

  //Rules

  getAllRules: "/rules/rest/getAllRules",
  getAllRulesN: "/rules/rest/colSearchRuleConfiguration",
  getMenusList: (module_name, field_name) =>
    `/rules/menu/getData/${module_name}/${field_name}`,
  getQueryRawData: (module_name, status='active') =>
    `/rules/rest/getQueryRawData/${module_name}?status=${status}`,
  getAssociatedGrp: (ascompId) => `/api/1/companies/${ascompId}/groups`,
  getGroupFunction: (alterGrpCompId) =>
    `/api/getGroupFunction/${alterGrpCompId}`,

  saveRuleDefinition: "/rules/rest/saveRuleDefinition",
  serviceListSelfServiceCompanyId: "/api/foundations/serviceListSelfServiceCompanyId/",

  getAssociatedRule: "/rules/rest/getAssociatedRule",
  getAssociatedContextualRule: "/rules/rest/getAssociatedContextualRule",
  getApprovalSet: "/api/getApprovalSet",

  //Company MenuList
  postCompanyMenuList: "/api/postCompanyMenuList",
  getCompanyMenulistData: "/api/getDetailsFromCompanyMenulist",
  getCompanyMenuListView: "/api/searchCompanyMenulistView",
  patchCompanyMenuList: "/api/updateCompanyMenulist",
  getRule: (reuleId) => `/rules/rest/getRule/${reuleId}`,
  updateRuleDefinition: "/rules/rest/updateRuleDefinition",
  getEscalationPlanNameList:(companyId) => `/rest/aiosem/getEscalationPlanNameListAction/${companyId}`,
  //// schedulers
  getAllSchedulers:"/rest/getAllSchedulers",
  getSingleScheduler: (schedulerId)=>`/rest/getScheduler/${schedulerId}`,
  getCIMGroupListForIncident: '/rules/rest/getCIMGroupListForIncident',

  ///cmdb gds
  jobnotification: "/api/integration/jobnotification",
  pullci:(gdsId)=>`/api/integration/pullgds/${gdsId}`,
  getGdsList: "/api/getGdsList",

  //SPCM Knowledge
  postKnowledgeArticlesUrl: "/api/knowledge/postKnowledgeArticle",
  getKnowledgeArticlesUrl: "/api/knowledge/getKnowledgeArticle",
  deleteKnowledgeArticlesUrl: "/api/knowledge/deleteKnowledgeArticle",

  // workFlow
  getWorkflowIdUrl: (companyId, module) =>
    `/api/workflow?companyId=${companyId}&module=${module}`,
};
