
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Form, Row, Col,ButtonToolbar,Button} from 'react-bootstrap';
import { change, Field, reduxForm } from 'redux-form';
import { _inputField, _dropDown, _textArea, _radio, TypeaheadandDropdown, TypeaheadExampleSingleSelect } from '../../../../common/ReduxFormFields/CategoryBoard/formFields';
import { useSelector,useDispatch } from "react-redux";
import {getComapanyBasedDepartment} from "../../../../../actions/foundation/userAction";
import CWLTypeahead from './common/cwlAsyncTypeahead.js';
import CostCenterTypeahead from './common/costCenterAsyncTypeahead.js';
import LocationTypeahead from './common/locationAsyncTypeahead.js';
import ManagerTypeahead from './common/managerAsyncTypeahead.js';

const OrganizationalInfoForm = (props) => {
    
    const { isMandatory = true } = props;

    const dispatch = useDispatch();
    const [selectedLocation,setSelectedLocation] = useState([]);
    const [selectedCostCenter,setSelectedCostCenter] = useState([]);
    const [selectedCWL,setSelectedCWL] = useState([]);
    const [selectedManager,setSelectedManager] = useState([]);
    const [OrgzData,setOrgzData] = useState([]);
    const [ccData,setCCData] = useState([]);
    const [locationData,setLocationData] = useState([]);
    const [managerData,setManagerData] = useState([]);
    const [cwlData,setCWLData] = useState([]);


    useEffect(()=>{
        dispatch({type: "COMP_BASED_DEP",payload:[]});
        dispatch({type: "USER_COSTCENTER",payload:[]});
        dispatch({type: "USER_ORGZ",payload:[]});
        dispatch({type: "USER_MANAGER",payload:[]});
        dispatch({type: "USER_LOCATION",payload:[]});
        dispatch({type: "CWL_DATA",payload:[]});
    },[]);
    useEffect(() => {
        if(props.selectedCompany.length == 0){
            setSelectedLocation([]);
            setSelectedCostCenter([]);
            setSelectedCWL([]);
            setSelectedManager([]);
        }     
    },[props.selectedCompany])
    useEffect(() => {
        if(props.userOrgListRedData && props.userOrgListRedData.length >0){
            setOrgzData(props.userOrgListRedData);
        }else{
            setOrgzData([]);
        }
    },[props.userOrgListRedData])
    useEffect(() => {
        if(props.userCostCenterRedData && props.userCostCenterRedData.length >0){
            setCCData(props.userCostCenterRedData);
        }
        else{
            setCCData([]);
        }
    },[props.userCostCenterRedData])
    useEffect(() => {
        if(props.cwlDataRedData && props.cwlDataRedData.length >0){
            setCWLData(props.cwlDataRedData);
        }else{
            setCWLData([]);
        }
    },[props.cwlDataRedData])
    useEffect(() => {
        if(props.userLocationRedData && props.userLocationRedData.length >0){
            setLocationData(props.userLocationRedData);
        }
        else{
            setLocationData([]);
        }
    },[props.userLocationRedData])
    useEffect(() => {
        if(props.userManagerRedData && props.userManagerRedData.length >0){
            setManagerData(props.userManagerRedData);
        }
        else{
            setManagerData([]);
        }
    },[props.userManagerRedData])


    const onValueSelection = (selected) => {
    let error = '';
    if (selected.length === 0 && isMandatory == true) {
        error = 'error';
      props.setLocationErrColor(error);

    }
        if(selected.length>0){
        dispatch(change('userForm','locationName', selected[0].label));
        dispatch(change('userForm', 'locationId', selected[0].id));
        props.setLocationErrColor('');
      
        }else{
        dispatch(change("userForm","locationName", ''));
        dispatch(change("userForm", "locationId", ''));
        }
        setSelectedLocation(selected);
    };

    const onCrossClick = () => {
        setSelectedLocation([]);
        dispatch(change("userForm","locationName", ''));
        dispatch(change("userForm", "locationId", ''));
        if(isMandatory == true){
            props.setLocationErrColor('error');
        }

    };

    const onCostCenterSelection = (selected) => {
        let error = '';
    if (selected.length === 0 && isMandatory == true) {
        error = 'error';
      props.setCCErrColor(error);
    }
    if(selected.length>0){
        dispatch(change("userForm","costcenterName", selected[0].label));
        dispatch(change("userForm", "costcenterId", selected[0].id));
        props.setCCErrColor('');
      
        }else{
        dispatch(change("userForm","costcenterName", ''));
        dispatch(change("userForm", "costcenterId", ''));
        }
        setSelectedCostCenter(selected);
    };

    const onCostCenterCrossClick = () => {
        setSelectedCostCenter([]);
        dispatch(change("userForm","costcenterName", ''));
        dispatch(change("userForm", "costcenterId", ''));
        if(isMandatory == true){
            props.setCCErrColor('error');
        }

    };

    const onCWLSelection = (selected) => {
        let error = '';
    if (selected.length === 0 && isMandatory == true) {
        error = 'error';
      props.setCWLColorErr(error);
    }
    if(selected.length>0){
        dispatch(change("userForm","cwlName", selected[0].label));
        dispatch(change("userForm", "cwlId", selected[0].id));
        props.setCWLColorErr('');
      
        }else{
        dispatch(change("userForm","cwlName", ''));
        dispatch(change("userForm", "cwlId", ''));
        }
        setSelectedCWL(selected);
    };

    const onCWLCrossClick = () => {
        setSelectedCWL([]);
        dispatch(change("userForm","cwlName", ''));
        dispatch(change("userForm", "cwlId", ''));
        if(isMandatory == true){
            props.setCWLColorErr('error');
        }

    };

    const onManagerSelection = (selected) => {
    if(selected.length>0){
        dispatch(change("userForm","managerName", selected[0].label));
        dispatch(change("userForm", "managerId", selected[0].id));
      
        }else{
        dispatch(change("userForm","managerName", ''));
        dispatch(change("userForm", "managerId", ''));
        }
        setSelectedManager(selected);
    };

    const onManagerCrossClick = () => {
        setSelectedManager([]);
    };

    const  validateGroupsField = (fieldType, event)=> {
        switch (fieldType) {
          case "organizationId":
            if (event.target.value == "") {
              props.setOrgzErr("error");
            } else {
              props.setOrgzErr("");
            }
            break;
          case "departmentId":
            if (event.target.value == "") {
              props.setDepErr("error");
            } else {
              props.setDepErr("");
            }
            break;
            default:
            }
        }
  
        const onOrganizationChange = (event) => {
            dispatch(change("userForm", "organizationId", event.target[event.target.selectedIndex].text))
                dispatch(getComapanyBasedDepartment(event.target.value));  }    

    return (
        <Row className="padding-10">
            <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
                <Form.Label>{(isMandatory == true) && <span className="rStar"></span>}Organization</Form.Label>
                {/* <Form.Select aria-label="select" name="" className="form-control">
                    <option selected disabled>Select</option>
                </Form.Select> */}
                <Field component={_dropDown} options={OrgzData} name="organizationId" className={"form-control " + ((isMandatory == true) ? props.orgzErr : '')}
              onBlur={(event) => {
                validateGroupsField("organizationId", event);
              }}
              onChange={(event) => (onOrganizationChange(event))}>
				</Field>
            </Form.Group>
            </Col>
            <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
                <Form.Label>{(isMandatory == true) && <span className="rStar"></span>}Department</Form.Label>
                {/* <Form.Select aria-label="select" name="" className="form-control">
                    <option selected disabled>Select</option>
                </Form.Select> */}
                <Field component={_dropDown} options={Array.isArray(props.userDepListRedData) ? props.userDepListRedData : []} name="departmentId" className={"form-control " + ((isMandatory == true) ? props.depErr : '')}
              onBlur={(event) => {
                validateGroupsField("departmentId", event);
              }} >
				</Field>
            </Form.Group>
            </Col>
            <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
                <Form.Label>{(isMandatory == true) && <span className="rStar"></span>}CWL</Form.Label>
                {/* <Form.Control type="email" name="cwlId" placeholder="Enter CWL Name" /> */}
                {/* <Field
                        component={TypeaheadandDropdown}
                        onSelection={onCWLSelection}
                        name="cwlId"
                        className="form-control"
                        options={cwlData}
                        selectedOptions={selectedCWL}
                        onCrossClick={onCWLCrossClick}
                        errorClass={(isMandatory == true) ? props.cwlErr : ''}
                        // setErrorColor={(err) => {
                        //     if(isMandatory == true){
                        //         props.setCWLColorErr(err);
                        //     }
                        // }}
                        // setErrorColor={props.setCWLColorErr}
                    /> */}
                <CWLTypeahead
                    id="cwlTypeahead"
                    errorColor={(isMandatory == true) ? props.cwlErr : ''}
                    setErrorColor={props.setCWLColorErr}
                    // translator={translator}
                />
            </Form.Group>
            </Col>
           
            <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
                <Form.Label>{(isMandatory == true) && <span className="rStar"></span>}Cost Center</Form.Label>
                {/* <Form.Control type="text" name="" placeholder="Enter Cost Center Name" /> */}
                {/* <Field
                        component={TypeaheadandDropdown}
                        onSelection={onCostCenterSelection}
                        name="costcenterId"
                        className="form-control"
                        options={ccData}
                        selectedOptions={selectedCostCenter}
                        onCrossClick={onCostCenterCrossClick}
                        errorClass={(isMandatory == true) ? props.ccErr : ''}
                        // setErrorColor={(err) => {
                        //     if(isMandatory == true){
                        //         props.setCCErrColor(err);
                        //     }
                        // }}
                        // setErrorColor={props.setCCErrColor}
                    /> */}
                <CostCenterTypeahead
                    id="costCenterTypeahead"
                    errorColor={(isMandatory == true) ? props.ccErr : ''}
                    setErrorColor={props.setCCErrColor}
                    // translator={translator}
                />
            </Form.Group>
            </Col>
            <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
                <Form.Label>{(isMandatory == true) && <span className="rStar"></span>}Location</Form.Label>
                {/* <Form.Control type="text" name="" placeholder="Enter Location" /> */}
                {/* <Field
                        component={TypeaheadandDropdown}
                        onSelection={onValueSelection}
                        name="locationId"
                        className="form-control"
                        options={locationData}
                        selectedOptions={selectedLocation}
                        onCrossClick={onCrossClick}
                        errorClass={(isMandatory == true) ? props.locationErr : ''}
                        // setErrorColor={(err) => {
                        //     if(isMandatory == true){
                        //         props.setLocationErrColor(err);
                        //     }
                        // }}
                        // setErrorColor={props.setLocationErrColor}
                    /> */}
                <LocationTypeahead
                    id="locationTypeahead"
                    errorColor={(isMandatory == true) ? props.locationErr : ''}
                    setErrorColor={props.setLocationErrColor}
                    // translator={translator}
                />
            </Form.Group>
            </Col>
            <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
                <Form.Label>Manager</Form.Label>
                {/* <Field
                        component={TypeaheadandDropdown}
                        onSelection={onManagerSelection}
                        name="managerId"
                        className="form-control"
                        options={managerData}
                        selectedOptions={selectedManager}
                        onCrossClick={onManagerCrossClick}
                    /> */}
                <ManagerTypeahead
                    id="managerTypeahead"
                    // translator={translator}
                />
            </Form.Group>
            </Col>
            <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
                <Form.Label>Cubicle</Form.Label>
                <Field
                        name="cubicle"
                        className={"form-control " }
                        component={_inputField}
                    />
            </Form.Group>
            </Col>
            <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
                <Form.Label>Building</Form.Label>
                <Field
                        name="building"
                        className={"form-control " }
                        component={_inputField}
                    />
            </Form.Group>
            </Col>
            <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
                <Form.Label>VIP</Form.Label>
                <Row>
                    {/* <Col lg={4} md={6} sm={6} xs={6}>
                        <Form.Check inline label="Yes" name="" type="radio" id="" />
                    </Col>
                    <Col lg={4} md={6} sm={6} xs={6}>
                        <Form.Check inline label="No" name="" type="radio" id="" />
                    </Col> */}
                    <div className="radioInlineBox float-l">
                    <Field component={_radio} className='form-control' label="Yes" type="radio" name='vip' value='Yes' />
					<Field component={_radio} className='form-control' label="No" type="radio" name='vip' value='No' checked={null} />
                    </div>
                </Row>
            </Form.Group>
            </Col>
            <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
                <Form.Label>Auto Approve</Form.Label>
                <Row>
                    {/* <Col lg={4} md={6} sm={6} xs={6}>
                        <Form.Check inline label="Yes" name="" type="radio" id="" />
                    </Col>
                    <Col lg={4} md={6} sm={6} xs={6}>
                        <Form.Check inline label="No" name="" type="radio" id="" />
                    </Col> */}
					<div className="radioInlineBox float-l">
                    <Field component={_radio} className='form-control' label="Yes" type="radio" name='autoApprove' value='Yes' />
					<Field component={_radio} className='form-control' label="No" type="radio" name='autoApprove' value='No' checked={null} />
                    </div>
                </Row>
            </Form.Group>
            </Col>
        </Row>
    )
}

export default reduxForm({
    form: 'userForm',
    destroyOnUnmount: false,
    enableReinitialize: true
})(OrganizationalInfoForm);