
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import { GLOBAL }  from '../../components/Globals';
import Cookies from "universal-cookie";
const cookie = new Cookies();
let dateformat = cookie.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
dateformat = dateformat.split("~");
dateformat = dateformat[2];

export const loadTaskAuditLogDetails = (taskCode,sortType="desc", isLoaderRequired="Yes",requestType="") => {
    return dispatch => {
        // dispatch({type:'LOADER_IN_PROGRESS'});
        if(isLoaderRequired=="Yes"){
          dispatch({type:'LOADER_IN_PROGRESS'});
        }
  
        // alert(GLOBAL.getTaskAuditDataURL + taskCode + "/" + sortType);
        axios.get(GLOBAL.getTaskAuditDataURL + taskCode + "/" + sortType+ "/" + requestType)
            .then(response => {
                if(response.status==200){
                    console.log('response in action');
                    console.log(response);
                    let fromList=[], toList=[], proxyRecord={}, duplicateTags=0, i=0, j=0,jwtdf = dateformat.slice(0, 10).toUpperCase(),captureFormat=false,k=0,l=0;
                    response.data.map(item=>{
                        if(item.fieldName==="TAG"){
                            return null;
                            item.oldValue=JSON.parse(item.oldValue);
                            item.newValue=JSON.parse(item.newValue);
                            if(item.oldValue===null||item.oldValue===''){
                              item.oldValue=[];
                            }else if(typeof item.oldValue.tag_data!=='undefined'){
                              item.oldValue=item.oldValue.tag_data;
                            }
                            if(item.newValue===null||item.newValue===''){
                              item.newValue=[];
                            }else if(typeof item.newValue.tag_data!=='undefined'){
                              item.newValue=item.newValue.tag_data;
                            }
                            fromList=[];
                            toList=[]; 
                            proxyRecord={}; 
                            duplicateTags=0; 
                            i=0; 
                            j=0;
                            k=0;
                            l=0;
                            captureFormat=false;
                                if(item.oldValue.length===item.newValue.length){
                                  for(i=0;i<item.oldValue.length;i++){
                                    for(j=0;j<item.newValue.length;j++){
                                      if(item.oldValue[i].tag_key!==item.newValue[j].tag_key){
                                        continue;
                                      }
                                       if(item.oldValue[i].tag_type==='Simple' && item.newValue[j].tag_type==='Simple' && item.oldValue[i].tag_value!==item.newValue[j].tag_value){
                                        fromList.push(item.oldValue[i]);
                                        toList.push(item.newValue[j]);
                                        break;
                                       }
                                       if((item.oldValue[i].tag_data_type===null||item.oldValue[i].tag_data_type==="null") && (item.newValue[j].tag_data_type===null||item.newValue[j].tag_data_type==="null") && item.oldValue[i].tag_data_type===item.newValue[j].tag_data_type){
                                        for(k=0;k<item.oldValue[i].tag_value.length;k++){
                                          if(item.oldValue[i].tag_value[k].tag_key===item.newValue[j].tag_value[k].tag_key && item.oldValue[i].tag_value[k].tag_value!==item.newValue[j].tag_value[k].tag_value){
                                            fromList.push(item.oldValue[i].tag_value[k]);
                                            toList.push(item.newValue[j].tag_value[k]);
                                          }
                                        }
                                        break;
                                       }
                                       if(item.oldValue[i].tag_key===item.newValue[j].tag_key && item.oldValue[i].tag_value!==item.newValue[j].tag_value){
                                        if(item.oldValue[i].tag_data_type==="DateTime"||item.oldValue[i].tag_data_type==="Date"){
                                          captureFormat=item.oldValue[i].tag_data_type==="DateTime"?true:false;
                                          item.oldValue[i].tag_value=moment(item.oldValue[i].tag_value).format(captureFormat?jwtdf+" hh:mm A":jwtdf);
                                          item.newValue[j].tag_value=moment(item.newValue[j].tag_value).format(captureFormat?jwtdf+" hh:mm A":jwtdf);
                                        }
                                        fromList.push(item.oldValue[i]);
                                        toList.push(item.newValue[j]);						
                                        break;
                                       }
                                    }
                                  }
                                }else if(item.oldValue.length<item.newValue.length){
                                  for(j=0;j<item.newValue.length;j++){
                                    duplicateTags=0;
                                    for(i=0;i<item.oldValue.length;i++){
                                        if(item.oldValue[i].tag_key===item.newValue[j].tag_key){
                                            duplicateTags++;
                                        }
                                    }
                                    if(duplicateTags===0){
                                     if(item.newValue[j].tag_data_type!==null && item.newValue[j].tag_data_type!=="null"){
                                     proxyRecord=JSON.parse(JSON.stringify(item.newValue[j]));
                                     proxyRecord.tag_value='NA';
                                     fromList.push(proxyRecord);
                                     if(item.newValue[j].tag_data_type==="DateTime"||item.newValue[j].tag_data_type==="Date"){
                                        captureFormat=item.newValue[j].tag_data_type==="DateTime"?true:false;
                                        item.newValue[j].tag_value=moment(item.newValue[j].tag_value).format(captureFormat?jwtdf+" hh:mm A":jwtdf);
                                     }
                                     toList.push(item.newValue[j]);
                                     continue;
                                    }
                                    if(item.newValue[j].tag_type==='Simple'){
                                      proxyRecord=JSON.parse(JSON.stringify(item.newValue[j]));
                                      proxyRecord.tag_value='NA';
                                      fromList.push(proxyRecord);
                                      toList.push(item.newValue[j]);
                                      continue;
                                    }else if(item.newValue[j].tag_data_type===null||item.newValue[j].tag_data_type==="null"){
                                      for(k=0;k<item.newValue[j].tag_value.length;k++){
                                        proxyRecord=JSON.parse(JSON.stringify(item.newValue[j].tag_value[k]));
                                        proxyRecord.tag_value='NA';
                                        fromList.push(proxyRecord);
                                        toList.push(item.newValue[j].tag_value[k]);
                                        continue;
                                      }  
                                    }
                                    }
                                  }	
                                }else if(item.oldValue.length>item.newValue.length){
                                  duplicateTags=0;
                                  for(i=0;i<item.oldValue.length;i++){
                                    duplicateTags=0;
                                    for(j=0;j<item.newValue.length;j++){
                                      if(item.oldValue[i].tag_key===item.newValue[j].tag_key){
                                        duplicateTags++;
                                      }
                                    }
                                    if(duplicateTags===0){
                                        if(item.oldValue[i].tag_data_type!==null && item.oldValue[i].tag_data_type!=="null"){
                                        proxyRecord=JSON.parse(JSON.stringify(item.oldValue[i]));
                                        proxyRecord.tag_value='NA';
                                        if(item.oldValue[i].tag_data_type==="DateTime"||item.oldValue[i].tag_data_type==="Date"){
                                            captureFormat=item.oldValue[i].tag_data_type==="DateTime"?true:false;
                                            item.oldValue[i].tag_value=moment(item.oldValue[i].tag_value).format(captureFormat?jwtdf+" hh:mm A":jwtdf);
                                        }
                                        fromList.push(item.oldValue[i]);
                                        toList.push(proxyRecord);
                                        continue;
                                       } 
                                       if(item.oldValue[i].tag_type==='Simple'){
                                        proxyRecord=JSON.parse(JSON.stringify(item.oldValue[i]));
                                        proxyRecord.tag_value='NA';
                                        toList.push(proxyRecord);
                                        fromList.push(item.oldValue[i]);
                                        continue;
                                     }else if(item.oldValue[i].tag_data_type===null || item.oldValue[i].tag_data_type==="null"){
                                        for(k=0;k<item.oldValue[i].tag_value.length;k++){
                                            proxyRecord=JSON.parse(JSON.stringify(item.oldValue[i].tag_value[k]));
                                            proxyRecord.tag_value='NA';
                                            toList.push(proxyRecord);
                                            fromList.push(item.oldValue[i].tag_value[k]);
                                            continue;
                                        }
                                       }
                                    }
                                  }
                                }
                                item.oldValue=fromList;
                                item.newValue=toList;
                            }
                    });
                    dispatch({type:'TASK_AUDIT_DATA_FETCHED',payload:response.data});
                }
                dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
            })
            .catch((err) => {
                console.log('err in action');
                console.log(err);
                dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
            })
    };
};