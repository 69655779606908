
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import PindCategories from "../../../common/header/pind-categories";
import Breadcrumbs from "../../../common/header/breadcrumbs";
import { getUserListAction } from "../../../../actions/foundation/userAction";
import { useDispatch, useSelector } from "react-redux";
import UserHeader from "./UserHeader";
import UserList from "./UserList";
import UserShortDesc from "./UserShortDesc";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import "_Css/common/_table.scss";

const UserIndex = () => {
  let dispatch = useDispatch();
  const [isRightSideVisible, setRightSideVisible] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [rowIndex, setrowIndex] = useState(0);
  const [panelResize,setPanelResize] = useState();
  const [limit, setLimit] = useState("10");
  const [page, setPage] = useState("1");
  const [companySearchInput, setCompanySearchInput] = useState("");
  const [fullNameSearchInput, setFullNameSearchInput] = useState("");
  const [emailSearchInput, setEmailSearchInput] = useState("");
  const [userNameSearchInput, setUserNameSearchInput] = useState("");
  const [employeeIdSearchInput, setEmployeeIdSearchInput] = useState("");
  const [clearAllFilterToggle, setClearAllFilterToggle] = useState(false);
  const [selectedStatusValue, setSelectedStatusValue] = useState([]);
  const [selectedUserTypeValue, setSelectedUserTypeValue] = useState([]);
  const [userType, setUserType] = useState([]);
  const [status, setStatus] = useState([]);
  const [searchKey, setsearchKey] = useState("");
  const [searchValue, setsearchValue] = useState("");
  const [isUserTypeOpen,setUserTypeOpen] = useState(false);
  const [isStatusOpen,setStatusOpen] = useState(false);

  const translator = useSelector((state) => state.spcmReducer.tr);
  const userListRed = useSelector((state) => state.userListRed);
  const showLoader = useSelector((state) => state.commonLoader.isLoading);

  let colWidth = "";
  isRightSideVisible ? (colWidth = 8) : (colWidth = 12);

  useEffect(() => {
    dispatch(getUserListAction(page, limit, "fullName", "asc", "", "", userType, status));
  }, []);

  const setMultiSelectParams = (userType, status) => {
    setUserType(userType);
    setStatus(status);
  }

  const multiSelectOpenFun = (keyName, val) => {
		switch (keyName) {
		  	case "fdnUserType":
				{setUserTypeOpen(val);
				setStatusOpen(false);
				};
			break;
			case "fdnUserStatus":
				{setUserTypeOpen(false);
          setStatusOpen(val);
				};
			break;
			case "allFalse":
				{setUserTypeOpen(false);
				setStatusOpen(false);
				};
			break;
		}
  }

  let meta = userListRed.meta;

  let size = meta && meta.limit ? meta.limit : 0;
  let currentPage = meta && meta.currentPage ? meta.currentPage : 0;

  let startLimit = size * (currentPage - 1) + 1;
  let endLimit = size * currentPage;
  let recordCount = meta && meta.rowCount ? meta.rowCount : 0;

  return (
    <main>
      <div>
        <PindCategories />
        <Container fluid className="margin-b-15">
          <Row>
            <Col md={8} xs={8}>          
              <Breadcrumbs activePageName={"User"} />
            </Col>
            <Col md={4} xs={4}>
              {recordCount != 0 ? (
                <div className="tabDatacount">
                  {startLimit}-
                  {endLimit > recordCount ? (endLimit = recordCount) : endLimit} of{" "}
                  {recordCount}
                </div>
              ) : null}
            </Col>
          </Row>
        </Container>

        <Container fluid className="position-re">
          <UserHeader
            page={page}
            limit={limit}
            userType={userType}
            status={status}
            clearAllFilterToggle={clearAllFilterToggle}
            setClearAllFilterToggle={setClearAllFilterToggle}
            setRightSideVisible={setRightSideVisible}
            setPage={setPage}
            setLimit={setLimit}
            companySearchInput={companySearchInput}
            setCompanySearchInput={setCompanySearchInput}
            fullNameSearchInput={fullNameSearchInput}
            setFullNameSearchInput={setFullNameSearchInput}
            emailSearchInput={emailSearchInput}
            setEmailSearchInput={setEmailSearchInput}
            userNameSearchInput={userNameSearchInput}
            setUserNameSearchInput={setUserNameSearchInput}
            employeeIdSearchInput={employeeIdSearchInput}
            setEmployeeIdSearchInput={setEmployeeIdSearchInput}
            selectedStatusValue={selectedStatusValue}
            setSelectedStatusValue={setSelectedStatusValue}
            selectedUserTypeValue={selectedUserTypeValue}
            setSelectedUserTypeValue={setSelectedUserTypeValue}
            setMultiSelectParams={setMultiSelectParams}
            searchKey={searchKey}
            searchValue={searchValue}
            setsearchValue={setsearchValue}
            setsearchKey={setsearchKey}
          />
          <PanelGroup
            direction="horizontal"
            className={(isStatusOpen || isUserTypeOpen) && (userListRed.userList == [] || userListRed.userList?.length < 6 || showLoader) ? "formGroupB10 myProfileLabel myOrders overflow-clip" : "formGroupB10 myProfileLabel myOrders"}
          >
            <Panel
              id="sidebar"
              minSize={33}
              order={1}
              defaultSize={isRightSideVisible ? 67 : 100}
              onResize={(size) => setPanelResize(size)}
              className={`${(isStatusOpen || isUserTypeOpen) && (userListRed.userList == [] || userListRed.userList?.length < 6 || showLoader) ? "overflow-clip" : ""} ${(isRightSideVisible ? "isShowLeftPanel rwLeftPart" : "rwLeftPart")}`}>
              <UserList 
                tr={translator} 
                setRightSideVisible={setRightSideVisible} 
                setUserDetails={setUserDetails} 
                setrowIndex={setrowIndex}
                rowIndex={rowIndex}
                isRightSideVisible={isRightSideVisible}
                panelResize={panelResize}
                page={page}
                limit={limit}
                setPage={setPage}
                setLimit={setLimit}
                clearAllFilterToggle={clearAllFilterToggle}
                setClearAllFilterToggle={setClearAllFilterToggle}
                companySearchInput={companySearchInput}
                setCompanySearchInput={setCompanySearchInput}
                fullNameSearchInput={fullNameSearchInput}
                setFullNameSearchInput={setFullNameSearchInput}
                emailSearchInput={emailSearchInput}
                setEmailSearchInput={setEmailSearchInput}
                userNameSearchInput={userNameSearchInput}
                setUserNameSearchInput={setUserNameSearchInput}
                employeeIdSearchInput={employeeIdSearchInput}
                setEmployeeIdSearchInput={setEmployeeIdSearchInput}
                selectedStatusValue={selectedStatusValue}
                setSelectedStatusValue={setSelectedStatusValue}
                selectedUserTypeValue={selectedUserTypeValue}
                setSelectedUserTypeValue={setSelectedUserTypeValue}
                setMultiSelectParams={setMultiSelectParams}
                userType={userType}
                status={status}
                setsearchKey={setsearchKey}
                setsearchValue={setsearchValue}
                searchKey={searchKey}
                searchValue={searchValue}
                isUserTypeOpen={isUserTypeOpen}
                isStatusOpen={isStatusOpen}
                multiSelectOpenFun={multiSelectOpenFun}
              />
            </Panel>
            {isRightSideVisible ? (
              <>
                <PanelResizeHandle className="isShowLeftPanel resizeHandle">
                  <div className="outlne">
                    <div className="handIcn">
                      <i className="fa fa-chevron-left" aria-hidden="true"></i>
                    </div>
                  </div>
                </PanelResizeHandle>
                <Panel minSize={33} order={2} defaultSize={33}>
                  <div className="stickyArea rBoxStyle">
                    <UserShortDesc
                      userDetails={userDetails}
                      setRightSideVisible={setRightSideVisible}
                    />
                  </div>
                </Panel>
              </>
            ) : (
              ""
            )}
          </PanelGroup>
        </Container>
      </div>
    </main>
  );
};

export default UserIndex;
