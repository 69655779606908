
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/



export const groupList = (state = [], action) => {
    if (action.type === 'GROUP_LIST_FETCHED_SUCCESSFULLY') {
      return action.payload;
    } else {
      return state;
    }
};

export const groupInitialValues = (state = [], action) => {
    if (action.type === 'GROUP_INITIAL_LIST') {
      return action.payload;
    } else {
      return state;
    }
};

export const companyMenuListValues = (state = [], action) => {
    if (action.type === 'COMPANY_MENU_LIST_VALUES') {
      return action.payload;
    } else {
      return state;
    }
};

export const groupEditPageValues = (state = [], action) => {
    if (action.type === 'GROUP_PAGE_EDIT_DETAILS_FETCHED') {
      return action.payload;
    } else {
      return state;
    }
};


export const groupForumValues = (state = [], action) => {
    if (action.type === 'FORUM_MENU_LIST_VALUES') {
      return action.payload;
    } else {
      return state;
    }
};

export const groupCategoryValues = (state = [], action) => {
    if (action.type === 'CATEGORY_MENU_LIST_VALUES') {
      return action.payload;
    } else {
      return state;
    }
};


export const assoMembersValues = (state = [], action) => {
    if (action.type === 'GROUP_MEMBERS_FETCHED_SUCCESSFULLY') {
      return action.payload;
    } else {
      return state;
    }
};

export const associateRoleValues = (state = [], action) => {
    if (action.type === 'ASSOCIATE_ROLES_FETCHED_SUCCESSFULLY') {
      return action.payload;
    } else {
      return state;
    }
};

export const disassociatedMember = (state =[],action) =>{
  if(action.type == "ASSOCIATED_MEMBER_DELETED"){
    return action.payload;
  }
  else{
    return state;
  }
}

export const disassociatedRoles = (state =[],action) =>{
  if(action.type == "ASSOCIATED_ROLES_DELETED"){
    return action.payload;
  }
  else{
    return state;
  }
}


export const aiosemDropdownDataForGroup = (state =[],action) =>{
  if(action.type == "AIOSEM_DATA_FOR_GROUP_FETCHED_SUCCESSFULLY"){
    return action.payload;
  }
  else{
    return state;
  }
}

export const getGroupsActivityDetails = (state =[],action) =>{
  if(action.type == "GROUPS_ACTIVITY_DETAILS_FETCHED_SUCCESSFULLY"){
    return action.payload;
  }
  else{
    return state;
  }
}

export const fetchAiosemActivityForGroup = (state =[],action) =>{
  if(action.type == "AIOSEM_ACTIVITY_DETAILS_FETCHED_SUCCESSFULLY"){
    return action.payload;
  }
  else{
    return state;
  }
}