
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SlaListHeader from "../../list/SlaListHeader.js";
import XsmSlaQuickViewList from "./list.js";
import XsmQuickViewShortDescription from "./short-description.js";
import { loadSlaWorkdayListOnPagination } from "_Actions/sla/quickViewAction.js";
import { getTranslation } from "_Actions/spcmActions.js";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import _ from "lodash";
import "_Css/common/_table.scss";
class SlaWorkDayIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenu: "4",
      showHideAlfy: false,
      showHideRightNav: false,
      scheduleId: "",
      scheduleCode: "",
      scheduleName: "",
      timeZone: "",
      companyName: "",
      status: "",
      workdayScheduleList: "",
      mobileLeftMenuFilter: false,
      isRightSideVisible: false,
      scheduleSearchInput: "",
      companySearchInput: "",
      rowIndex: 0,
      selectedStatusValue: [],
      selectedTimeZoneValue: [],
      searchParamsValue: {},
      searchKey: "",
      searchValue: "",
      pattern: "",
      statusValue: [],
      timeZoneValue: [],
      isClearAllfilter: false,
      pageSize: 10,
      pageNumber: 0,
      panelResize: "",
      isStatusOpen: false,
      isTimeZoneOpen: false,
    };
    this.showHideAlfy = this.showHideAlfy.bind(this);
    this.showHideRightNav = this.showHideRightNav.bind(this);
    this.getSlaWorkdayDetails = this.getSlaWorkdayDetails.bind(this);
    this.buildSearchParamsForCMDBQV =
      this.buildSearchParamsForCMDBQV.bind(this);
    this.showRightSide = this.showRightSide.bind(this);
    this.setScheduleSearchInput = this.setScheduleSearchInput.bind(this);
    this.setCompanySearchInput = this.setCompanySearchInput.bind(this);
    this.showActiveRow = this.showActiveRow.bind(this);
    this.setSelectedTimeZoneValue = this.setSelectedTimeZoneValue.bind(this);
    this.setSelectedStatusValue = this.setSelectedStatusValue.bind(this);
    this.setSearchParams = this.setSearchParams.bind(this);
    this.isClearAllFilterFunc = this.isClearAllFilterFunc.bind(this);
    this.setPageDetails = this.setPageDetails.bind(this);
    this.multiSelectOpenFun = this.multiSelectOpenFun.bind(this);
  }

  componentDidMount() {
    let str = {};
    str.searchByList = "";
    str.multipleValueList = "";
    str.patternList = "";
    str.orderBy = "desc";
    str.sortBy = "scheduleId";
    str.currentPage = 0;
    str.size = 10;
    this.props.loadSlaWorkdayListOnPagination(str, [], []);
  }

  setPageDetails(pageNumber, size) {
    this.setState({ pageSize: size, pageNumber: pageNumber });
  }

  setSelectedTimeZoneValue(value) {
    this.setState({ selectedTimeZoneValue: value });
  }

  setSelectedStatusValue(value) {
    this.setState({ selectedStatusValue: value });
  }

  setSearchParams(searchParams, key, value, pattern, status, timeZone) {
    this.setState({
      searchParamsValue: searchParams,
      searchKey: key,
      searchValue: value,
      pattern: pattern,
      statusValue: status,
      timeZoneValue: timeZone,
    });
  }

  isClearAllFilterFunc(value) {
    this.setState({ isClearAllfilter: value });
  }

  setScheduleSearchInput(value) {
    this.setState({ scheduleSearchInput: value });
  }

  setCompanySearchInput(value) {
    this.setState({ companySearchInput: value });
  }

  showRightSide(value) {
    this.setState({ isRightSideVisible: value });
  }

  mobileLeftMenuFilter() {
    this.setState({ mobileLeftMenuFilter: !this.state.mobileLeftMenuFilter });
  }

  showHideAlfy(value) {
    this.setState({ showHideAlfy: value });
  }

  showHideRightNav(value) {
    this.setState({ showHideRightNav: !this.state.showHideRightNav });
  }

  getSlaWorkdayDetails(
    scheduleId,
    scheduleCode,
    scheduleName,
    companyName,
    status,
    workdayScheduleList,
    timeZone
  ) {
    this.setState({
      scheduleId: scheduleId,
      scheduleCode: scheduleCode,
      scheduleName: scheduleName,
      companyName: companyName,
      status: status,
      workdayScheduleList: workdayScheduleList,
      timeZone: timeZone,
    });
  }

  buildSearchParamsForCMDBQV(params) {
    let searchParams = Object.assign({}, params);

    //======Status Filter Start====
    let selectedStatus = this.props.cmdbQuickViewFilters.selectedStatus;
    //console.log(selectedpriorities);
    if (!_.isEmpty(selectedStatus)) {
      if (!_.isEmpty(searchParams.searchByList)) {
        //alert("called")
        searchParams.searchByList = searchParams.searchByList + "," + "STATUS";
        searchParams.multipleValueList =
          searchParams.multipleValueList + "|" + selectedStatus.join();
      } else {
        //alert("called")
        searchParams.searchByList = "STATUS";
        searchParams.multipleValueList = selectedStatus.join();
      }
    }
    //======Status Filter End===========

    //=======Company Filter Start========
    let selectedCompany = this.props.cmdbQuickViewFilters.selectedCompany;
    //console.log(selectedpriorities);
    if (!_.isEmpty(selectedCompany)) {
      if (!_.isEmpty(searchParams.searchByList)) {
        //alert("called")
        searchParams.searchByList =
          searchParams.searchByList + "," + "COMPANY_ID";
        searchParams.multipleValueList =
          searchParams.multipleValueList + "|" + selectedCompany.join();
      } else {
        searchParams.searchByList = "COMPANY_ID";
        searchParams.multipleValueList = selectedCompany.join();
      }
    }
    //=======Company Filter End========
    //=======Class Filter Start========
    let selectedClass = this.props.cmdbQuickViewFilters.selectedClass;
    //console.log(selectedpriorities);
    if (!_.isEmpty(selectedClass)) {
      if (!_.isEmpty(searchParams.searchByList)) {
        //alert("called")
        searchParams.searchByList =
          searchParams.searchByList + "," + "CLASS_ID";
        searchParams.multipleValueList =
          searchParams.multipleValueList + "|" + selectedClass.join();
      } else {
        searchParams.searchByList = "CLASS_ID";
        searchParams.multipleValueList = selectedClass.join();
      }
    }
    //=======Class Filter End========
    return searchParams;
  }

  showActiveRow(value) {
    this.setState({ rowIndex: value });
  }

  multiSelectOpenFun(keyName, val) {
		switch (keyName) {
		  case "slaWrkDayStatus":
			this.setState({
				isStatusOpen: val,
				isTimeZoneOpen: false,
			});
			break;
			case "slaWrkDayTimeZone":
			this.setState({
				isStatusOpen: false,
				isTimeZoneOpen: val,
			});
			break;
			case "allFalse":
			this.setState({
				isStatusOpen: false,
				isTimeZoneOpen: false,
			});
			break;
		}
	  }

  render() {
    let colWidth = "";
    this.state.isRightSideVisible ? (colWidth = 8) : (colWidth = 12);
    return (
      <div>
        <div className="container-fluid padding-t-10" bsClass="">
          {/* Xsm Quick View Detailt Header Section Start*/}
          {/*<XsmQuickViewDetailtHeader />*/}
          {/* Xsm Quick View Detailt Header Section end*/}
          <SlaListHeader
            translator={this.props.translator}
            scheduleSearchInput={this.state.scheduleSearchInput}
            companySearchInput={this.state.companySearchInput}
            setScheduleSearchInput={this.setScheduleSearchInput}
            setCompanySearchInput={this.setCompanySearchInput}
            rowIndex={this.state.rowIndex}
            showActiveRow={this.showActiveRow}
            setSearchParams={this.setSearchParams}
            searchParamsValue={this.state.searchParamsValue}
            searchKey={this.state.searchKey}
            searchValue={this.state.searchValue}
            pattern={this.state.pattern}
            isClearAllfilter={this.state.isClearAllfilter}
            isClearAllFilterFunc={this.isClearAllFilterFunc}
            statusValue={this.state.statusValue}
            timeZoneValue={this.state.timeZoneValue}
            selectedStatusValue={this.state.selectedStatusValue}
            selectedTimeZoneValue={this.state.selectedTimeZoneValue}
            setSelectedStatusValue={this.setSelectedStatusValue}
            setSelectedTimeZoneValue={this.setSelectedTimeZoneValue}
            showRightSide={this.showRightSide}
            pageSize={this.state.pageSize}
            pageNumber={this.state.pageNumber}
            setPageDetails={this.setPageDetails}
          />

          <PanelGroup direction="horizontal" className={(this.state.isStatusOpen || this.state.isTimeZoneOpen) && (this.props.show_loader == true || this.props.slaWorkdayList.data?.length <= 6) ? "formGroupB10 myProfileLabel myOrders overflow-clip" : "formGroupB10 myProfileLabel myOrders"}>
            <Panel id="sidebar" minSize={33} order={1} defaultSize={this.state.isRightSideVisible ? 67 : 100} onResize={(size) => this.setState({ panelResize : size})} className={(this.state.isStatusOpen || this.state.isTimeZoneOpen) && (this.props.show_loader == true || this.props.slaWorkdayList.data?.length <= 6) ? "overflow-clip" : (this.state.isRightSideVisible ? "isShowLeftPanel rwLeftPart" : "rwLeftPart")}>
              {/* Xsm Quick View List Section Start*/}
              <XsmSlaQuickViewList
                getSlaWorkdayDetails={this.getSlaWorkdayDetails}
                translator={this.props.translator}
                isRightSideVisible={this.state.isRightSideVisible}
                showRightSide={this.showRightSide}
                scheduleSearchInput={this.state.scheduleSearchInput}
                companySearchInput={this.state.companySearchInput}
                setScheduleSearchInput={this.setScheduleSearchInput}
                setCompanySearchInput={this.setCompanySearchInput}
                rowIndex={this.state.rowIndex}
                showActiveRow={this.showActiveRow}
                setSearchParams={this.setSearchParams}
                searchParamsValue={this.state.searchParamsValue}
                searchKey={this.state.searchKey}
                searchValue={this.state.searchValue}
                pattern={this.state.pattern}
                isClearAllfilter={this.state.isClearAllfilter}
                isClearAllFilterFunc={this.isClearAllFilterFunc}
                statusValue={this.state.statusValue}
                timeZoneValue={this.state.timeZoneValue}
                selectedStatusValue={this.state.selectedStatusValue}
                selectedTimeZoneValue={this.state.selectedTimeZoneValue}
                setSelectedStatusValue={this.setSelectedStatusValue}
                setSelectedTimeZoneValue={this.setSelectedTimeZoneValue}
                pageSize={this.state.pageSize}
                pageNumber={this.state.pageNumber}
                setPageDetails={this.setPageDetails}
                panelResize={this.state.panelResize}
                isStatusOpen={this.state.isStatusOpen}
				        isTimeZoneOpen={this.state.isTimeZoneOpen}
                multiSelectOpenFun={this.multiSelectOpenFun}
              />
              {/* Xsm Quick View List Section End*/}
            </Panel>
            {this.state.isRightSideVisible ?
                <>
                  <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
                  <Panel minSize={33} order={2} defaultSize={33}>
                    <div className="stickyArea rBoxStyle">
                      <XsmQuickViewShortDescription
                        translator={this.props.translator}
                        scheduleId={this.state.scheduleId}
                        scheduleCode={this.state.scheduleCode}
                        scheduleName={this.state.scheduleName}
                        timeZone={this.state.timeZone}
                        companyName={this.state.companyName}
                        status={this.state.status}
                        workdayScheduleList={this.state.workdayScheduleList}
                        isRightSideVisible={this.state.isRightSideVisible}
                        showRightSide={this.showRightSide}
                        rowIndex={this.state.rowIndex}
                        showActiveRow={this.showActiveRow}
                        pageSize={this.state.pageSize}
                        pageNumber={this.state.pageNumber}
                        setPageDetails={this.setPageDetails}
                      />
                    </div>
                  </Panel>
                </> : ""
            }
          </PanelGroup>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  //console.log(cmdbQuickItemList)
  return {
    slaWorkdayList: state.slaWorkdayList,
    translator: state.spcmReducer.tr,
    show_loader: state.showLoader_SLA_Work_Schedule_Pagination.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { loadSlaWorkdayListOnPagination, getTranslation },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SlaWorkDayIndex);

//export default CMDB;
