
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import * as types from '../../actions/actionTypes';
import initialState from '../initialState';

export default function chatReducer(state = initialState.chat, action) {
  switch (action.type) {
    case types.POST_CHAT:
      return Object.assign({}, state, {chatState: action.chatState, showAppHelp: false});

    case types.TOGGLE_MIC:
      let newState;
      //If the action is to switch off the mic
      if (state.micOn && state.micStream){
        state.micStream.stop();
        newState = Object.assign({}, state, {micOn: !state.micOn, micStream: ""});
      } else {
        //if the action is to switch on the mic
        newState = Object.assign({}, state, {micOn: !state.micOn, micStream: action.micStream });
      }
      return  newState;

    case types.APP_ACTION:
      return Object.assign({}, state, {appResponse: action.appResponse, appResponseReceived:true});

    case types.RESET_QUICK_ACTION_VIEW:
      return Object.assign({}, state, { appResponseReceived:false});

    case types.SIMULATE_USER_MSG:
      return Object.assign({},state, {usrMsg:action.usrMsg});

    case types.LOAD_TOKEN:
      return Object.assign({},state, {tokens:action.tokens});

    case types.SHOW_APP_HELP:
      return Object.assign({},state, {showAppHelp:action.showAppHelp});

    default :
      return state;
  }
}
