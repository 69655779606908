
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import { MdClose, MdAdd } from 'react-icons/md';
import { Form } from "react-bootstrap";
import { cx } from '../utils';
import React, { useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { TextBox } from '@progress/kendo-react-inputs';
import {IoClose} from "react-icons/io5";

export default function CustomSelectorManager({
    selectors,
    selectedState,
    states,
    targets,
    setState,
    addSelector,
    removeSelector,
    setRightPanelShow
}) {
    const [addClass, setAddClass] = useState(false);
    const [classValue, setClassValue] = useState('');

    const addNewClass = () => {
        setClassValue('');
        setAddClass(true);
    }

    const handleChange = (e) => {
        setClassValue(e.target.value);
    }

    const addNewSelector = (e) => {
        if(e.key === "Enter"){
            if(classValue) addSelector({ name: classValue, label: classValue });
            setAddClass(false);
            setClassValue('');
        }
    }

    const targetStr = targets.join(', ');

    const getStateOptions = (states) => {
        return states.map(state => {
            return {
                id: state.id,
                text: state.getName()
            }
        })
    }

    return (
        <div className="gjs-custom-selector-manager rightPanelComp flex flex-col text-left">
            <div className="rPageHeading d-flex">
                <div>Selectors</div>
                <Form.Select className='ms-auto w-25' size='sm' value={selectedState} onChange={(ev) => setState(ev.target.value)}>
                    <option value="">- State -</option>
                    {getStateOptions(states).map((state, index) => (
                        <option key={index} value={state.id}>{state.text}</option>
                    ))}
                </Form.Select>
                <Button className='closeIcon' onClick={()=>{setRightPanelShow(false)}} title='Minimize the details panel' fillMode="outline"><IoClose/></Button>
            </div>
            <div className='rightPanelGap'>
                <div className={cx('d-flex items-center gap-2 flex-wrap p-2 bg-black/30 border rounded min-h-[45px]')}>
                    {
                        targetStr ?
                            <Button
                                size="small"
                                fillMode="outline"
                                onClick={addNewClass}
                                className={cx('rounded px-2 py-1')}
                            >
                                <MdAdd></MdAdd>
                            </Button>
                            :
                            <div className="opacity-70">Select a component</div>
                    }
                    {
                        addClass && (
                            <TextBox
                                size="small"
                                fillMode="solid"
                                value={classValue}
                                onChange={handleChange}
                                onKeyDown={addNewSelector}
                            ></TextBox>
                        )
                    }
                    {selectors.map(selector => (
                        <div key={selector.toString()} className="border px-2 py-1 d-flex items-center gap-1 whitespace-nowrap bg-sky-500 rounded">
                            <div className='pt-1'>{selector.getLabel()}</div>
                            <Button size="small" fillMode="flat" onClick={() => removeSelector(selector)}>
                                <MdClose></MdClose>
                            </Button>
                        </div>
                    ))}
                </div>
                <div className='p-2'>
                    Selected: <span className="opacity-70">{targetStr || 'None'}</span>
                </div>
            </div>
        </div>
    );
}