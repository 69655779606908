
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import moment from 'moment';
import { Field, reduxForm, change, formValueSelector, getFormValues } from 'redux-form';
import { _inputField, _textArea, _dropDown, _dateField,_latentField, _dateTimeField, _latentDateTimeField, _dropDown2 } from '../../../common/ReduxFormFields/formFields';
import { TypeaheadExampleSingleSelect } from '../../../common/formFields';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';
import { connect } from 'react-redux';
import axios from 'axios';
import { GLOBAL } from "_Globals";
import RFReactConsumer from "../../../common/WorkItemBoard/AutoSearchConsumer";
import { getLovValues, getRiskCalculated, resetSomeFieldsEditChange } from '../../../../actions/changeManagement/changeManagementAction';
import { loadNewBreakFixSGAE } from '../../../../actions/breakFix/serviceGroupAEAction';
import { loadNewBreakFixSGAEChange } from '../../../../actions/breakFix/serviceGroupAEChangeAction';
import { loadBreakFixSGI,loadChangeManagementIndividual,loadChangeManagementImplGroupIndividual } from '../../../../actions/breakFix/serviceGroupIAction';
import _ from 'lodash';
import CIDetailsService from "../../../common/WorkItemBoard/ciNameSelect";
import {getAllgroup} from '../../../../actions/amsActions/amsActions';

import RiskAssessmentForm from '../../../common/WorkItemBoard/RiskAssessment';
import { RuleAmsDdropDown } from "../../../common/forms/ruleDropDown";

import { Button } from 'react-bootstrap';
import { RiUserSearchLine } from 'react-icons/ri';
import {ImInfo} from 'react-icons/im';

let downTimeRequired = false;

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});
import Cookies from 'universal-cookie';
const cookies = new Cookies();
let timezone = cookies.get('gph');
if (timezone) timezone = timezone.replace('s:', '');
if (timezone) timezone = timezone.substring(0, timezone.lastIndexOf('.'));
timezone = timezone.split("~");
timezone = timezone[3];
let dateformat = cookies.get('gph');
if (dateformat) dateformat = dateformat.replace('s:', '');
if (dateformat) dateformat = dateformat.substring(0, dateformat.lastIndexOf('.'));
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase()+' HH:mm:ss';
let excludeSecondFormat = dateformat.slice(0, 10).toUpperCase()+' HH:mm';

const validate = (valuesObj,props) => {
	var values = JSON.stringify(valuesObj);
	values = JSON.parse(values);
	if(values.actualStartDate){
		values.actualStartDate = moment(values.actualStartDate).format('MM-DD-YYYY HH:mm');
	}
	if(values.actualEndDate){
		values.actualEndDate = moment(values.actualEndDate).format('MM-DD-YYYY HH:mm');
	}
	let errors = {};
	if (!values.type) {
		errors.type = 'select type.';
	}
	if (!values.summary || values.summary.trim().length===0) {
		errors.summary = 'enter summary.';
	}
	if (!values.description || values.description.trim().length===0) {
		errors.description = 'enter description.';
	}
	// if (!values.changeTiming) {
	// 	errors.changeTiming = 'select changeTiming.';
	// }
	// if (!values.overallTechnicalRisk) {
	// 	errors.overallTechnicalRisk = 'select overall technical risk.';
	// }
	// if (!values.overallBusinessRisk) {
	// 	errors.overallBusinessRisk = 'select overall business risk.';
	// }
	if (!values.urgency) {
		errors.urgency = 'select urgency.';
	}
	if (!values.reasonForChange) {
		errors.reasonForChange = 'select reason for change.';
	}
	if (!values.cmdbUpdateNeeded) {
		errors.cmdbUpdateNeeded = 'select cmdb update needed.';
	}

	// if(values.type && values.type == '35'){
	// 	if((values.riskOccurrenceProbability && values.riskOccurrenceProbability != '0') || (values.riskImpact && values.riskImpact != '0') || (values.controlEffectiveness && values.controlEffectiveness != '0')){
	// 		if(!values.riskOccurrenceProbability||values.riskOccurrenceProbability=='0')
	// 		{
	// 			errors.riskOccurrenceProbability = 'select risk occurrence probability.';
	// 		}
	// 		if(!values.riskImpact||values.riskImpact=='0')
	// 		{
	// 			errors.riskImpact = 'select risk impact.';
	// 		}
	// 		if(!values.controlEffectiveness||values.controlEffectiveness=='0')
	// 		{
	// 			errors.controlEffectiveness = 'select control effectiveness.';
	// 		}
	// 	}
	// 	else{
	// 		errors.riskOccurrenceProbability = '';
	// 		errors.riskImpact = '';
	// 		errors.controlEffectiveness = '';
	// 	}
	// }
	// else{
	// 	if(!values.riskOccurrenceProbability||values.riskOccurrenceProbability=='0')
	// 	{
	// 		errors.riskOccurrenceProbability = 'select risk occurrence probability.';
	// 	}
	// 	else{
	// 		errors.riskOccurrenceProbability = '';
	// 	}
	// 	if(!values.riskImpact||values.riskImpact=='0')
	// 	{
	// 		errors.riskImpact = 'select risk impact.';
	// 	}
	// 	else{
	// 		errors.riskImpact = '';
	// 	}
	// 	if(!values.controlEffectiveness||values.controlEffectiveness=='0')
	// 	{
	// 		errors.controlEffectiveness = 'select control effectiveness.';
	// 	}
	// 	else{
	// 		errors.controlEffectiveness = '';
	// 	}
	// }

	// if (!values.riskOccurrenceProbability || values.riskOccurrenceProbability==='') {
	// 	errors.riskOccurrenceProbability = 'select risk occurrence probability.';
	// }
	// if (!values.riskImpact || values.riskImpact==='') {
	// 	errors.riskImpact = 'select risk impact.';
	// }
	// if (!values.controlEffectiveness || values.controlEffectiveness==='') {
	// 	errors.controlEffectiveness = 'select control effectiveness.';
	// }

	if (!values.expectedStartDate) {
		errors.expectedStartDate = ' ';
	}
	if (!values.expectedEndDate) {
		errors.expectedEndDate = ' ';
	}
	if (!values.actualStartDate) {
		errors.actualStartDate = ' ';
	}
	if (!values.actualEndDate) {
		errors.actualEndDate = ' ';
	}
	
	if (values.expectedStartDate || values.expectedEndDate) {
		let expectedStartDate = moment.utc(values.expectedStartDate).tz(timezone).format(jwtdf);
		let expectedEndDate = moment.utc(values.expectedEndDate).tz(timezone).format(jwtdf);
		if(values.type && values.type == '35'){
			if (moment.tz(expectedStartDate,jwtdf,timezone).isAfter(moment().tz(timezone))) {
				if (props.selectedType !== '30') {
					errors.expectedStartDate = 'Scheduled Start Date should not be greater than current date.';
				}
			}
			if (moment.tz(expectedEndDate,jwtdf,timezone).isAfter(moment().tz(timezone))) {
				if (props.selectedType !== '30') {
					errors.expectedEndDate = 'Scheduled End Date should not be greater than current date.';
				}
			}
		}
		else{
			if (moment.tz(expectedStartDate,jwtdf,timezone).isBefore(moment.tz(timezone))) {
				if (props.selectedType !== '30') {
					errors.expectedStartDate = 'Scheduled Start Date should be atleast today\'s date.';
				}
			}
			if (moment.tz(expectedEndDate,jwtdf,timezone).isBefore(moment.tz(timezone))) {
				if (props.selectedType !== '30') {
					errors.expectedEndDate = 'Scheduled End Date should be atleast today\'s date.';
				}
			}
		}
		if (moment.tz(expectedStartDate,jwtdf,timezone).isAfter(moment.tz(expectedEndDate,jwtdf,timezone))) {
			errors.expectedStartDate = 'Scheduled Start Date should be same or fall before end date.';
		}
		if(values.expectedStartDate=="Invalid date")
		{
			errors.expectedStartDate = 'Invalid date.';
		}
		if(values.expectedEndDate=="Invalid date")
		{
			errors.expectedEndDate = 'Invalid date.';
		}
	}

	if(!values.expectedStartDate){
		errors.expectedStartDate = 'Please select Scheduled Start Date';
	}
	if(!values.expectedEndDate){
		errors.expectedEndDate = 'Please select Scheduled End Date';
	}

	if (values.actualStartDate || values.actualEndDate) {
		if (moment(values.actualStartDate).tz(timezone) > moment().tz(timezone).add(-1, 'days')) {
			errors.actualStartDate = 'Actual Start Date should not be greater than Yersterday\'s date.';
		}
		if (moment(values.actualEndDate).tz(timezone) > moment().tz(timezone).add(-1, 'days')) {
			errors.actualEndDate = 'Actual End Date should not be greater than Yersterday\'s date.';
		}
		if (moment(values.actualStartDate).tz(timezone).isAfter(values.actualEndDate)) {
			errors.actualStartDate = 'actual start date should be same or fall before end date.';
		}
		if(values.actualStartDate=="Invalid date")
		{
			errors.actualStartDate = 'Invalid date.';
		}
		if(values.actualEndDate=="Invalid date")
		{
			errors.actualEndDate = 'Invalid date.';
		}
	}
	if (values.downtimeRad == "Y") {

		if (!values.startDate) {
			errors.startDate = ' ';
		}
		if (!values.endDate) {
			errors.endDate = ' ';
		}

		if (values.startDate || values.endDate) {
			let downtimeStartDate, downtimeEndTime, expectedStartDate, expectedEndDate, currentDate;
			if(moment(values.startDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid()){
				downtimeStartDate = moment.tz(values.startDate, timezone).format(jwtdf);
			  }else{
				downtimeStartDate = values.startDate;
			  }
			  if(moment(values.endDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid()){
				downtimeEndTime = moment.tz(values.endDate, timezone).format(jwtdf);
			  }else{
				downtimeEndTime = values.endDate;
			  }
			  if(moment(values.expectedStartDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid()){
				expectedStartDate = moment.tz(values.expectedStartDate, timezone).format(jwtdf);
			  }else{
				expectedStartDate = values.expectedStartDate;
			  }
			  if(moment(values.expectedEndDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid()){
				expectedEndDate = moment.tz(values.expectedEndDate, timezone).format(jwtdf);
			  }else{
				expectedEndDate = values.expectedEndDate;
			  }
			  downtimeStartDate = moment.tz(downtimeStartDate, jwtdf, timezone).format(excludeSecondFormat);
			  downtimeEndTime = moment.tz(downtimeEndTime, jwtdf, timezone).format(excludeSecondFormat);
			  expectedStartDate = moment.tz(expectedStartDate, jwtdf, timezone).format(excludeSecondFormat);
			  expectedEndDate = moment.tz(expectedEndDate , jwtdf, timezone).format(excludeSecondFormat);
			  currentDate = moment.tz(timezone).format(excludeSecondFormat);
			if (values.changeTiming !== '20') {
				if(props.selectedType == '25' || props.selectedType == '20'){
					if(moment.tz(downtimeStartDate,jwtdf,timezone).isBefore(moment.tz(currentDate,jwtdf,timezone))){
						errors.startDate = 'Downtime Start Date should be atleast today\'s date.';
					}
					if(moment.tz(downtimeEndTime,jwtdf,timezone).isBefore(moment.tz(currentDate,jwtdf,timezone))){
						errors.endDate = 'Downtime End Date should be atleast today\'s date.';
					}
				}
				if(moment.tz(downtimeStartDate,jwtdf,timezone).isBefore(moment.tz(expectedStartDate,jwtdf,timezone)) || moment.tz(downtimeStartDate,jwtdf,timezone).isAfter(moment.tz(expectedEndDate,jwtdf,timezone))){
					errors.startDate = 'Downtime Start Date should be same or between Scheduled start and end date.';
				}
				if(moment.tz(downtimeEndTime,jwtdf,timezone).isBefore(moment.tz(expectedStartDate,jwtdf,timezone)) || moment.tz(downtimeEndTime,jwtdf,timezone).isAfter(moment.tz(expectedEndDate,jwtdf,timezone))){
					errors.endDate = 'Downtime End Date should be same or between Scheduled start and end date.';
				}
				if(moment.tz(downtimeStartDate,jwtdf,timezone).isAfter(moment.tz(downtimeEndTime,jwtdf,timezone))){
					errors.startDate = 'Downtime start date should be same or fall before end date.';
				}
		}else{
			if (moment.tz(startDate,jwtdf,timezone).isAfter(moment().tz(timezone).subtract(1, 'days'))) {
				errors.startDate = 'Downtime Start Date should not be greater than Yersterday\'s date.';
			}
			if (moment.tz(endDate,jwtdf,timezone).isAfter(moment().tz(timezone).subtract(1, 'days'))) {
				errors.endDate = 'Downtime End Date should not be greater than Yersterday\'s date.';
			}
			if (moment.tz(startDate,jwtdf,timezone).isBefore(moment.tz(values.actualStartDate,jwtdf,timezone))) {
				errors.startDate = 'Downtime should be same or between actual start and end date.';
			}	
			if (moment.tz(endDate,jwtdf,timezone).isAfter(moment.tz(values.actualEndDate,jwtdf,timezone))) {
				errors.endDate = 'Downtime should be same or between actual start and end date.';
			}
		}
		if(values.startDate=="Invalid date")
		{
			errors.startDate = 'Invalid date.';
		}
		if(values.endDate=="Invalid date")
		{
			errors.endDate = 'Invalid date.';
		}
		}
	}
    if(!values.company || values.company===null){
		errors.company='select company';
	}
	return errors;
};
const summaryMaxChar = 500;
let ChangeForm = class ChangeForm extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
			selectedOption: 'N',
			downTimeDateDisabled: true,
			showChangeTiming: false,
			impactedServiceName: '',
			consumerDataDetails:[],
			impactedServiceDataDetails:[],
			ciNameDataDetails:[],
			companyId:'',
			blankservice:0,
			AllAMSGrps:false,
			AllAMSGrpsChangeManagement:false,
			ChangeManagementAMSgrp:false,
            AllImplmtAMSGrps:false,
			AllAMSImplmtGrps:false,
			ImplemtAMSgrp:false,
            companyList:[],
			selectedCompanyList:[],
			companyError:'',
			selectedImpgroups:[],
			selectedImpinds:[],
			selectedChgroups:[],
			selectedChginds:[],
			selectedRequestor: '',
			selectedImpactedCi: '',
			requestorOptions: [],
			typedCompany: '',
			typedGroupI: '',
			typedIndividualI: '',
			typedGroupC: '',
			typedIndividualC: '',
			disableTypeAhead: true,
      		summaryCharCountLeft: summaryMaxChar,
	  		changeType: '25',
			showRequestorInfo: true,
			showImpIndividualInfo: true,
			showChangeIndividualInfo: true,
			isCiNameInfoIcn:true,
			showImplIndividualInfoButton: false,
			showChangeIndividualInfoButton: false,
			rulesAttribute: {},
		};
		GLOBAL.ciNameEdit="";GLOBAL.ciIdEdit="";GLOBAL.ciIdNumberEdit="";
		this.disableEnableDate = this.disableEnableDate.bind(this);
		this.setSelectedOption = this.setSelectedOption.bind(this);
		this.onChangeTiming = this.onChangeTiming.bind(this);
		this.setErrorColor = this.setErrorColor.bind(this);
		this.handCall = this.handCall.bind(this);
		this.onImpactedSelection=this.onImpactedSelection.bind(this);
		this.AllAMSGrps=this.AllAMSGrps.bind(this);
        this.AllGrps=this.AllGrps.bind(this);
		this.AllimplmtGrps=this.AllimplmtGrps.bind(this);
		this.ImplmtAMSGrp=this.ImplmtAMSGrp.bind(this);
        this.setCompany=this.setCompany.bind(this);
		this.validateField=this.validateField.bind(this);
		this.setImpgroupval=this.setImpgroupval.bind(this);
		this.setImpindval=this.setImpindval.bind(this);
		this.seterrorimpindColor=this.seterrorimpindColor.bind(this);
		this.setChgroupval=this.setChgroupval.bind(this);
		this.setChgindval=this.setChgindval.bind(this);
		this.seterrorchgindColor=this.seterrorchgindColor.bind(this);
		this.companyInputChange = this.companyInputChange.bind(this);
		this.onCrossClickCompany = this.onCrossClickCompany.bind(this);
		this.onGroupInputChangeI = this.onGroupInputChangeI.bind(this);
		this.onCrossClickGroupI = this.onCrossClickGroupI.bind(this);
		this.onIndividualInputChangeI = this.onIndividualInputChangeI.bind(this);
		this.onCrossClickIndividualI = this.onCrossClickIndividualI.bind(this);
		this.onGroupInputChangeC = this.onGroupInputChangeC.bind(this);
		this.onCrossClickGroupC = this.onCrossClickGroupC.bind(this);
		this.onIndividualInputChangeC = this.onIndividualInputChangeC.bind(this);
		this.onCrossClickIndividualC = this.onCrossClickIndividualC.bind(this);
		this.onConsumerSelected = this.onConsumerSelected.bind(this);
		this.getGroupsForImptandChange = this.getGroupsForImptandChange.bind(this);
		this.checkforAMSattributes=this.checkforAMSattributes.bind(this);
		this.afterConsumerSelected=this.afterConsumerSelected.bind(this);
		this.clearGroupandIndividual=this.clearGroupandIndividual.bind(this);
		this.resetCIRequesterFields=this.resetCIRequesterFields.bind(this);
		this.onSummaryInputChange=this.onSummaryInputChange.bind(this);
		this.showConsumerInfoIcon = this.showConsumerInfoIcon.bind(this);
		this.showImpactedCIInfoIcon = this.showImpactedCIInfoIcon.bind(this);
		this.showImplIndividualInfoIcon = this.showImplIndividualInfoIcon.bind(this);
		this.showChangeIndividualInfoIcon = this.showChangeIndividualInfoIcon.bind(this);
	}
    setChgindval(selectedChginds){
		if(selectedChginds.length>0){
		  this.props.dispatch(change("changeForm", "changeUsername", selectedChginds[0].label));
		  this.props.dispatch(change("changeForm", "changeManagementIndividual", selectedChginds[0].value));
		  this.props.setIndividualDetails(selectedChginds[0].value);
		  this.showChangeIndividualInfoIcon(true);
		}else{
		  this.props.dispatch(change("changeForm", "changeUsername", ''));
		  this.props.dispatch(change("changeForm", "changeManagementIndividual", ''));
		}
		this.setState({selectedChginds: selectedChginds});
	}
    seterrorchgindColor(){
	}
    setChgroupval(selectedChgroups){
		if(selectedChgroups.length>0){
			let typeaheadData=[];
			const changeGroupID = this.state.AllAMSGrps==false?selectedChgroups[0].id:selectedChgroups[0].value;
			this.props.dispatch(change("changeForm", "changeGroupname", selectedChgroups[0].label));
			this.props.dispatch(change("changeForm","changeManagementGroup",changeGroupID));
			this.props.loadChangeManagementIndividual(changeGroupID);
			this.props.dispatch({type:'CHANGEMANAGEMENT_INDIVIDUAL_LOAD_SUCCESS_TYPE',typeaheadData});
		}else{
            this.props.dispatch(change("changeForm", "changeGroupname", ''));
			this.props.dispatch(change("changeForm","changeManagementGroup",''));
		}
		this.props.dispatch(change("changeForm", "changeManagementIndividual", ''));
		this.props.dispatch(change("changeForm", "changeUsername", ''));
		this.setState({selectedChgroups: selectedChgroups,selectedChginds:[]});
		this.props.setAssignmentGrpUsr(selectedChgroups,"group");
	}
    setImpindval(selectedImpinds){
		if(selectedImpinds.length>0){
			this.props.dispatch(change("changeForm", "impUsername", selectedImpinds[0].label));
			this.props.dispatch(change("changeForm", "implementationIndividual", selectedImpinds[0].value));
			this.props.setImplementIndividualDetails(selectedImpinds[0].value);
			this.showImplIndividualInfoIcon(true);
		}else{
			this.props.dispatch(change("changeForm", "impUsername", ''));
			this.props.dispatch(change("changeForm", "implementationIndividual", ''));
		}
		this.setState({selectedImpinds: selectedImpinds});
	}
    seterrorimpindColor(){
	}
    setImpgroupval(selectedImpgroups){
		if(selectedImpgroups.length>0){
		  const implementationGroupID = this.state.AllImplmtAMSGrps==false?selectedImpgroups[0].id:selectedImpgroups[0].value;
		  this.props.dispatch(change("changeForm", "impGroupname", selectedImpgroups[0].label));
		  this.props.dispatch(change('changeForm','implementationGroup',implementationGroupID));
          this.props.loadChangeManagementImplGroupIndividual(implementationGroupID);
          this.props.dispatch(change("changeForm", "implementationIndividual", ''));
		  this.props.dispatch(change("changeForm", "impUsername", ''));
		  let typeaheadData=[];
		  this.props.dispatch({type:'CHANGEMANAGEMENT_IMPL_INDIVIDUAL_LOAD_SUCCESS_TYPE',typeaheadData});
		  GLOBAL.isExternalChangeCreate=(selectedImpgroups[0].isExternal==null || selectedImpgroups[0].isExternal==undefined) ? false : selectedImpgroups[0].isExternal ;
		  this.props.dispatch(change("changeForm", "supportCompanyID", selectedImpgroups[0].supportCompanyID));
		  this.props.dispatch(change("changeForm", "supportCompanyName", selectedImpgroups[0].supportCompanyName));
		}else{
		  this.props.dispatch(change("changeForm", "impGroupname", ''));
		  this.props.dispatch(change('changeForm','implementationGroup',''));
		  this.props.dispatch(change("changeForm", "implementationIndividual", ''));
		  this.props.dispatch(change("changeForm", "impUsername", ''));
		  GLOBAL.isExternalChangeCreate="";
		}
		this.props.setAssignmentGrpUsrImp(selectedImpgroups,"group");
		this.setState({selectedImpgroups: selectedImpgroups, selectedImpinds:[]});
	}
    handCall(e)
	{
		if(GLOBAL.ciClass!='')
		   this.props.dispatch(change('changeForm', 'ciClassName', GLOBAL.ciClass));
		this.setState({class_status: GLOBAL.ciClass})

	}
    disableEnableDate(value) {
		switch (value) {
			case 'Y':
				downTimeRequired = true;
				this.setState({ downTimeDateDisabled: false });
				break;
			case 'N':
				downTimeRequired = false;
				this.setState({ downTimeDateDisabled: true });
				break;
		}
	}
    componentWillMount() {
		GLOBAL.breakFixServiceID=GLOBAL.ciId=GLOBAL.breakFixConsumerID=GLOBAL.servicelocationid=GLOBAL.ciLocationId=GLOBAL.businessCriticalityId=GLOBAL.businessCriticality='';
		this.props.resetSomeFieldsEditChange();
		GLOBAL.changepageLoad=true,
		GLOBAL.onlyAllgrpchangemngmt=false;
		GLOBAL.onlyAllgrpimplmt=false;
		GLOBAL.onlyAllgrpCreatechangemngmt=false;
		GLOBAL.onlyAllgrpCreateimplmt=false;
		localStorage.setItem('grpid',null);
		localStorage.setItem('grpname',null);
		localStorage.setItem('impgrpname',null);
		localStorage.setItem('implgrpid',null);
		this.setState({
			AllAMSGrps:false,
			AllAMSGrpsChangeManagement:false
		  });
		    this.props.getLovValues("ChangeManagement", "Urgency");
			this.props.getLovValues("ChangeManagement", "ReasonForChange");
			this.props.getLovValues("ChangeManagement", "RiskOccurrenceProbability");
			this.props.getLovValues("ChangeManagement", "RiskImpact");
			this.props.getLovValues("ChangeManagement", "ControlEffectiveness");
		GLOBAL.breakFixServiceName ="";
		GLOBAL.ciName="";
		GLOBAL.blankservice=0;
		let strObj={};
		strObj.userId="";
		strObj = JSON.stringify(strObj);
		let impactedServiceData = [];
		let impactedServiceDataDetails = [];
		let myObj0 = [];
		GLOBAL.Foundationgrp=false;
		GLOBAL.FoundationgrpImp=false;
		api.get('/api/serviceportfolio/usercompanylist').then((responseCompany)=>{
			responseCompany.data.map((item=>{
                  this.state.companyList.push({'id':''+item.field1Value, 'label':item.field1Key});
			}));
	    });
		var str={};
		str.userId="";
		str.companyId="";
		str.associatedCompanyId="";
		str = JSON.stringify(str);

		api.get(GLOBAL.breakFixServiceWithoutDomainUrl,{headers:{'query':str}}).then((response) => {
			impactedServiceData = response.data;
			for (let i = 0; i < impactedServiceData.length; i++) {
				myObj0 = { value: '', label: '', companyname: '', companyid: '' };
				myObj0.value = impactedServiceData[i].offeringId;
				myObj0.label = impactedServiceData[i].offeringName + "|" + impactedServiceData[i].categoryName + "|" + impactedServiceData[i].subCategoryName+ "|" + impactedServiceData[i].companyName;
				myObj0.companyname = impactedServiceData[i].company;
				myObj0.consumercompanyname=impactedServiceData[i].companyName;
				impactedServiceDataDetails.push(myObj0);
			}
			impactedServiceDataDetails = Array.from(new Set(impactedServiceDataDetails));
			this.setState({
				impactedServiceDataDetails:impactedServiceDataDetails
			});
		});
		this.props.getLovValues("ChangeManagement", "Type");
		this.props.getLovValues("ChangeManagement", "ChangeTiming");
		this.props.getLovValues("ChangeManagement", "TechnicalRisk");
		this.props.getLovValues("ChangeManagement", "BusinessRisk");
		this.props.getLovValues("ChangeManagement", "PlanType");
	}
    componentWillUnmount(){
        let typeaheadData=[],allGroup=[];
		GLOBAL.changepageLoad=false,
		GLOBAL.ciClass='';
		let breakFixSGIName={'data':[]};
        this.props.dispatch({type:'GROUP_LIST',groupList:[]});
		this.props.dispatch({type:'CHANGEMANAGEMENT_INDIVIDUAL_LOAD_SUCCESS',breakFixSGIName});
		this.props.dispatch({type:'CHANGEMANAGEMENT_IMPL_INDIVIDUAL_LOAD_SUCCESS',breakFixSGIName});
		this.props.dispatch({type:'LOAD_BREAKFIXSGAECHANGE_SUCCESS_TYPEAHED',typeaheadData});
		this.props.dispatch({type: 'GROUP_LIST_TYPE_AHEAD_INITIAL',typeaheadData});
		this.props.dispatch({type:'GROUP_LIST_TYPEAHEAD',allGroup});
		this.props.dispatch({type:'CHANGEMANAGEMENT_IMPL_INDIVIDUAL_LOAD_SUCCESS_TYPE',typeaheadData});
		this.props.dispatch({type:'CHANGEMANAGEMENT_INDIVIDUAL_LOAD_SUCCESS_TYPE',typeaheadData});
		GLOBAL.businessCriticalityId=GLOBAL.businessCriticality='';
    }
    setSelectedOption(option) {
		if (option == 'Y') {
			this.setState({ selectedOption: 'Y' });
		}
		if (option == 'N') {
			this.setState({ selectedOption: 'N' });
		}
	}
    onGroupNameChange(e) {
		if (this.props.supportPGroup != '') {
			this.props.loadBreakFixSGI(e.target.value);
		}
	}
    setErrorColor(errorColor, fieldType) {
		switch (fieldType) {
			case 'impactedCIName':
				this.props.setErrorImpactedColor("");
				break;
			case 'consumer':
				this.props.setErrorConsumerColor(errorColor);
				break;
			default:
		}
	}
    componentDidMount(){
		this.props.setEnableErrors(true);
		this.props.dispatch(change("changeForm", "expectedStartDate", moment.tz(timezone)));
        this.props.dispatch(change("changeForm", "expectedEndDate",moment.tz(timezone)));
	}

	componentDidUpdate(previousProp){
		if(this.props.selectedAssignmentUser  !==  previousProp.selectedAssignmentUser && this.props.selectedAssignmentUser.length>0){
		  this.setChgindval(this.props.selectedAssignmentUser);
		}

		if(this.props.selectedImpUser  !==  previousProp.selectedImpUser && this.props.selectedImpUser.length>0){
			this.setImpindval(this.props.selectedImpUser);
		  }
	}

    onImpactedSelection(impactedID, ciConsumerCompany, suggestion)
	{
		this.setState({AllAMSGrps:false, AllImplmtAMSGrps:false, selectedImpactedCi: suggestion.ciName});
		if(impactedID!="")
		{
			this.checkforAMSattributes('','');
			GLOBAL.ciId = impactedID;
			this.props.dispatch(change("changeForm", "implementationGroup", ''));
			this.props.dispatch(change("changeForm", "implementationIndividual",''));
			this.props.dispatch(change("changeForm", "changeManagementGroup", ''));
			this.props.dispatch(change("changeForm", "changeManagementIndividual",''));
			this.showImpactedCIInfoIcon(true);
		}else{
			this.showImpactedCIInfoIcon(false);
		}
	}
    onChangeTiming(event) {
		let changeTiminingCode = event ? event.target.value : event;
		if (changeTiminingCode === "20") {
			this.props.dispatch(change("changeForm", "actualStartDate", ''));
			this.props.dispatch(change("changeForm", "actualEndDate",''));
			this.setState({ showChangeTiming: true })
		}
		else {
			this.props.dispatch(change("changeForm", "actualStartDate", ''));
			this.props.dispatch(change("changeForm", "actualEndDate",''));
			this.setState({ showChangeTiming: false })
		}
	}
    onTypeSelect(e){
		// console.log('*************', e, e.target.value)
		this.setState({changeType: e.target.value});
		this.onChangeTiming('')
		this.props.dispatch(change("changeForm", "changeTiming", ''));
		this.props.dispatch(change("changeForm", "riskOccurrenceProbability", ''));
		this.props.dispatch(change("changeForm", "riskImpact", ''));
		this.props.dispatch(change("changeForm", "controlEffectiveness", ''));
		this.props.dispatch(change("changeForm", "overallRisk", ''));
		let response={
			data:{
				'controlEffectiveness':'',
				'id':'',
				'overallRisk':'',
				'riskImpact':'',
				'riskOccurrenceProbability':''
			}
		};
		this.props.dispatch({type: 'RISK_ASSESSMENT',response});
	}
    AllAMSGrps(e)
	{
		if(this.state.disableTypeAhead){
			return;
		}
		GLOBAL.Foundationgrp=false
		let amsAttribute={
			"Status": '0',
			"Priority": '0',
			"Service": '0',
			"Impacted CI": !GLOBAL.ciId?'0': GLOBAL.ciId,
			"Requestor": !GLOBAL.breakFixConsumerID?'0':GLOBAL.breakFixConsumerID,
			"Class": !GLOBAL.ciClassId?'0':GLOBAL.ciClassId,
			"User.Location": !GLOBAL.servicelocationid?'0':GLOBAL.servicelocationid,
			"CI.Location": !GLOBAL.ciLocationId?'0':GLOBAL.ciLocationId
		};

		let rulesAttribute={
			"status":  undefined,
			"priorityId": undefined,
			"serviceName": undefined,
			"impactedCi":  GLOBAL.ciName,
			"impactedUsername": GLOBAL.breakFixConsumerName,
			"ciClassName": GLOBAL.ciClassName,
			"userLocation": GLOBAL.servicelocationname,
			"ciLocation": GLOBAL.ciLocationName,
			"impactedCiId": !GLOBAL.ciId?'0': GLOBAL.ciId,
			"requestorId": !GLOBAL.breakFixConsumerID?'0':GLOBAL.breakFixConsumerID,
			"classId": !GLOBAL.ciClassId?'0':GLOBAL.ciClassId,
			"userLocationId": !GLOBAL.servicelocationid?'0':GLOBAL.servicelocationid,
			"ciLocationId": !GLOBAL.ciLocationId?'0':GLOBAL.ciLocationId
		};

		// this.props.loadNewBreakFixSGAEChange(GLOBAL.ciId==='' && this.state.companyId!==''?'0':GLOBAL.ciId,GLOBAL.ciConsumerCompany===''?this.state.companyId:GLOBAL.ciConsumerCompany, "ChangeManagement","impactedCI",amsAttribute);
		this.setState({AllAMSGrpsChangeManagement:false, ChangeManagementAMSgrp:false, AllAMSGrps:false, selectedChgroups:[], selectedChginds:[]});
		this.setState({ rulesAttribute });
		this.props.setAssignmentGrpUsr([],"group");
		GLOBAL.AMSCREATEEXIST="";
		let typeaheadData=[];
        this.props.dispatch({type:'CHANGEMANAGEMENT_INDIVIDUAL_LOAD_SUCCESS_TYPE',typeaheadData});
		this.props.dispatch(change("changeForm", "changeGroupname", ''));
		this.props.dispatch(change("changeForm", "changeUsername", ''));
	}
    AllimplmtGrps(e)
	{
        if(this.state.disableTypeAhead){
			return;
		}
		GLOBAL.FoundationgrpImp=true;
		e.preventDefault();
		this.props.getAllgroup(GLOBAL.ciConsumerCompany===''?this.state.companyId:GLOBAL.ciConsumerCompany);
		this.setState({AllAMSImplmtGrps:true, ImplemtAMSgrp:true, AllImplmtAMSGrps:true, selectedImpgroups:[], selectedImpinds:[]});
		this.props.setAssignmentGrpUsrImp([],"group");
		GLOBAL.AMSCREATEIMPEXIST="yes";
		let typeaheadData=[];
		this.props.dispatch({type:'CHANGEMANAGEMENT_IMPL_INDIVIDUAL_LOAD_SUCCESS_TYPE',typeaheadData});
		this.props.dispatch(change("changeForm", "impUsername", ''));
		this.props.dispatch(change("changeForm", "impGroupname", ''));
	}
    ImplmtAMSGrp()
	{
		if(this.state.disableTypeAhead){
			return;
		}
		GLOBAL.FoundationgrpImp=false;
		let amsAttribute={
			"Status": '0',
			"Priority": '0',
			"Service": '0',
			"Impacted CI": !GLOBAL.ciId?'0': GLOBAL.ciId,
			"Requestor": !GLOBAL.breakFixConsumerID?'0':GLOBAL.breakFixConsumerID,
			"Class": !GLOBAL.ciClassId?'0':GLOBAL.ciClassId,
			"User.Location": !GLOBAL.servicelocationid?'0':GLOBAL.servicelocationid,
			"CI.Location": !GLOBAL.ciLocationId?'0':GLOBAL.ciLocationId
		};

		let rulesAttribute={
			"status":  undefined,
			"priorityId": undefined,
			"serviceName": undefined,
			"impactedCi":  GLOBAL.ciName,
			"impactedUsername": GLOBAL.breakFixConsumerName,
			"ciClassName": GLOBAL.ciClassName,
			"userLocation": GLOBAL.servicelocationname,
			"ciLocation": GLOBAL.ciLocationName,
			"impactedCiId": !GLOBAL.ciId?'0': GLOBAL.ciId,
			"requestorId": !GLOBAL.breakFixConsumerID?'0':GLOBAL.breakFixConsumerID,
			"classId": !GLOBAL.ciClassId?'0':GLOBAL.ciClassId,
			"userLocationId": !GLOBAL.servicelocationid?'0':GLOBAL.servicelocationid,
			"ciLocationId": !GLOBAL.ciLocationId?'0':GLOBAL.ciLocationId
		};
		// this.props.loadNewBreakFixSGAE(GLOBAL.ciId===''?'0':GLOBAL.ciId,GLOBAL.ciConsumerCompany===''?this.state.companyId:GLOBAL.ciConsumerCompany, "ChangeManagement","impactedCI",amsAttribute);
		this.setState({AllAMSImplmtGrps:false, ImplemtAMSgrp:false, AllImplmtAMSGrps:false, selectedImpgroups:[], selectedImpinds:[]});
		this.setState({ rulesAttribute });
		GLOBAL.AMSCREATEIMPEXIST="";
		this.props.setAssignmentGrpUsrImp([],"group");
		let typeaheadData=[];
	    this.props.dispatch({type:'CHANGEMANAGEMENT_IMPL_INDIVIDUAL_LOAD_SUCCESS_TYPE',typeaheadData});
		this.props.dispatch(change("changeForm", "impUsername", ''));
		this.props.dispatch(change("changeForm", "impGroupname", ''));
	}
    AllGrps(e)
	{
		if(this.state.disableTypeAhead){
			return;
		}
		GLOBAL.Foundationgrp=true
		e.preventDefault();
		this.props.getAllgroup(GLOBAL.ciConsumerCompany===''?this.state.companyId:GLOBAL.ciConsumerCompany);
		this.setState({
		  AllAMSGrpsChangeManagement:true,
		  ChangeManagementAMSgrp:true,
		  AllAMSGrps:true,
		  selectedChgroups:[],
          selectedChginds:[]
		});
		this.props.setAssignmentGrpUsr([],"group");
		GLOBAL.AMSCREATEEXIST="yes";
		let typeaheadData=[];
		this.props.dispatch(change("changeForm", "changeGroupname", ''));
		this.props.dispatch(change("changeForm", "changeUsername", ''));
        this.props.dispatch({type:'CHANGEMANAGEMENT_INDIVIDUAL_LOAD_SUCCESS_TYPE',typeaheadData});
	}
    getRiskCalculated(field,e){
		let fieldVal = e.target.value;
		let riskOccurrenceVal = this.props.riskOccurrenceVal;
		let riskImpactVal = this.props.riskImpactVal;
		let controlEffectivenesVal = this.props.controlEffectivenesVal;
		switch (field) {
			case "riskOccurrenceProbability":
				riskOccurrenceVal = fieldVal;
				break;
			case "riskImpact":
				riskImpactVal = fieldVal;
				break;
			case "controlEffectiveness":
				controlEffectivenesVal = fieldVal;
				break;
		}
		if(riskOccurrenceVal != "" && riskOccurrenceVal != undefined && riskImpactVal != "" && riskImpactVal != undefined &&
			controlEffectivenesVal != "" && controlEffectivenesVal != undefined)
		this.props.getRiskCalculated(riskOccurrenceVal,riskImpactVal,controlEffectivenesVal);
	}
    setCompany(selectedCompany){
		let typeaheadData=[];
		GLOBAL.breakFixConsumerID='',GLOBAL.ciId='',GLOBAL.breakFixServiceID='',GLOBAL.ciConsumerCompany='';
		if(selectedCompany.length>0){
			GLOBAL.selectedCompany = selectedCompany[0].id;
		 let allGroup=[];
		 this.setState({companyId: selectedCompany[0].id, selectedCompanyList:selectedCompany});
		 this.props.dispatch(change('changeForm','companyName',selectedCompany[0].label));
		 this.props.dispatch(change('changeForm','company',selectedCompany[0].id));
		 this.props.dispatch({type:'GROUP_LIST_TYPE_AHEAD_INITIAL',typeaheadData});
		 this.props.dispatch({type:'GROUP_LIST_TYPEAHEAD',allGroup});
		 this.props.dispatch({type:'CHANGEMANAGEMENT_IMPL_INDIVIDUAL_LOAD_SUCCESS_TYPE',typeaheadData});
		 this.props.dispatch({type:'CHANGEMANAGEMENT_INDIVIDUAL_LOAD_SUCCESS_TYPE',typeaheadData});
         this.checkforAMSattributes('company',selectedCompany);
		 this.showConsumerInfoIcon(false);
		 this.showImpactedCIInfoIcon(false);
		 this.props.showImpactCI(false);
         this.props.showConsumerDetails(false);
		 this.props.showIndividualDetails(false);
		 this.props.showImplementIndividualDetails(false);
		this.showImplIndividualInfoIcon(false);
		this.showChangeIndividualInfoIcon(false);
		}else{
		 this.setState({companyId: '',selectedCompanyList:[]});
		 this.props.dispatch(change('changeForm','companyName',''));
		 this.props.dispatch(change('changeForm','company',''));
		}
		let breakFixSGIName={'data':[]};
		this.props.dispatch(change('changeForm','implementationGroup',''));
		this.props.dispatch(change('changeForm','implementationGroup',''));
		this.props.dispatch(change('changeForm','implementationIndividual',''));
		this.props.dispatch(change('changeForm','changeManagementGroup',''));
		this.props.dispatch(change('changeForm','changeManagementIndividual',''));
		this.props.dispatch({type:'GROUP_LIST',groupList:[]});
		this.props.dispatch({type:'CHANGEMANAGEMENT_INDIVIDUAL_LOAD_SUCCESS',breakFixSGIName});
		this.props.dispatch({type:'CHANGEMANAGEMENT_IMPL_INDIVIDUAL_LOAD_SUCCESS',breakFixSGIName});
		this.props.dispatch({type:'GROUP_LIST_TYPE_AHEAD_INITIAL',typeaheadData});
		this.props.dispatch({type:'LOAD_BREAKFIXSGAECHANGE_SUCCESS_TYPEAHED',typeaheadData});
		this.state.selectedChginds=this.state.selectedChgroups=this.state.selectedImpgroups=this.state.selectedImpinds=[];
		this.props.setAssignmentGrpUsr([],"group");
		this.props.setAssignmentGrpUsrImp([],"group");
		this.props.dispatch(change('changeForm', 'ciClassName', ''));
		this.resetCIRequesterFields();
		this.showConsumerInfoIcon(false);
		this.showImpactedCIInfoIcon(false);
		this.props.showImpactCI(false);
        this.props.showConsumerDetails(false);
		this.props.showIndividualDetails(false);
		this.props.showImplementIndividualDetails(false);
		this.showImplIndividualInfoIcon(false);
		this.showChangeIndividualInfoIcon(false);
	}
    validateField(){
        if(this.state.selectedCompanyList.length===0){
         this.props.seTypeaheadValidation(true,'company');
        }else{
         this.props.seTypeaheadValidation(false,'company');
        }
    }
    companyInputChange(e){
        GLOBAL.breakFixConsumerID='',GLOBAL.ciId='',GLOBAL.breakFixServiceID='',GLOBAL.ciConsumerCompany='';
        this.setState({typedCompany: e,selectedCompanyList: []});
        this.setCompany([]);
    }
    onSummaryInputChange(e){
      let input = e.target.value.length;
			const leftCount = summaryMaxChar-input;
			this.setState({
				summaryCharCountLeft:leftCount
			})
    }
    onCrossClickCompany(){
		GLOBAL.breakFixConsumerID='',GLOBAL.ciId='',GLOBAL.breakFixServiceID='',GLOBAL.ciConsumerCompany='';
		this.companyInputChange('');
		this.checkforAMSattributes('company',-1);
		this.props.dispatch(change('changeForm', 'ciClassName', ''));
		this.resetCIRequesterFields();
		this.props.showConsumerDetails(false)
		this.props.showImpactCI(false)
		this.props.showImplementIndividualDetails(false)
		this.props.showIndividualDetails(false)
		this.showConsumerInfoIcon(false);
		this.showImpactedCIInfoIcon(false);
		this.props.showImpactCI(false);
        this.props.showConsumerDetails(false);
		this.showImplIndividualInfoIcon(false);
		this.showChangeIndividualInfoIcon(false);
	}
    resetCIRequesterFields(){
		const { change } = this.props;
		change('requestor', '');
		change('ciName', '');
		GLOBAL.ciId = '';
		GLOBAL.breakFixConsumerID = '';
		this.setState({requestorOptions: [], impactedServiceDataDetails: [], selectedRequestor: '', selectedImpactedCi: '', disableTypeAhead: true})
	}
    onGroupInputChangeI(e){
		this.showImplIndividualInfoIcon(false);
		this.props.showImplementIndividualDetails(false);
		this.setState({typedGroupI: e});
		this.setImpgroupval([]);
	}
    onCrossClickGroupI(){
		this.showImplIndividualInfoIcon(false);
		this.props.showImplementIndividualDetails(false);
		this.onGroupInputChangeI('');
		this.setState({typedIndividualI: ''});
		this.props.dispatch({type:"RESET_BREAKFIXSGI"})
	}
    onIndividualInputChangeI(e){
		this.setState({typedIndividualI: e});
		this.setImpindval([]);
	}
    onCrossClickIndividualI(){
		this.showImplIndividualInfoIcon(false);
		this.props.showImplementIndividualDetails(false);
		this.onIndividualInputChangeI('');
		this.setState({showRequestorInfo:false})
	}
    onGroupInputChangeC(e){
		this.showChangeIndividualInfoIcon(false);
		this.props.showIndividualDetails(false);
		this.setState({typedGroupC: e});
		this.setChgroupval([]);
	}
    onCrossClickGroupC(){
		this.showChangeIndividualInfoIcon(false);
		this.props.showIndividualDetails(false);
		this.onGroupInputChangeC('');
		this.setState({typedIndividualC: ''});
		this.props.dispatch({type:"RESET_BREAKFIXSGI"})
	}
    onIndividualInputChangeC(e){
		this.setState({typedIndividualC: e});
		this.setChgindval([]);
	}
    onCrossClickIndividualC(){
		this.showChangeIndividualInfoIcon(false);
		this.props.showIndividualDetails(false);
		this.onIndividualInputChangeC('');
	}
    onConsumerSelected(suggestion){
		try{
			GLOBAL.breakFixConsumerID = suggestion.userId;
			GLOBAL.servicelocationid = suggestion.locationId;
			GLOBAL.servicelocationname = suggestion.locationName;
			this.checkforAMSattributes('','');
			const suggestionlabel= suggestion.fullname;
			this.setState({selectedRequestor: suggestionlabel})
		}catch(e){
			console.error(e);
		}
	}
    getGroupsForImptandChange(){
		try{
			this.ImplmtAMSGrp();
			this.AllAMSGrps();
		}catch(e){
			console.error(e);
		}
	}
    checkforAMSattributes(fieldName,value){
		let count=0;
		if(fieldName==='company' && value.length>0){
			count++;
		 }else if(value===-1){
			count--;
		 }else if(this.state.selectedCompanyList.length>0){
			count++;
		 }

		 if(GLOBAL.ciId!==''){
			count++;
		 }

		 if(GLOBAL.breakFixConsumerID!==''){
			count++;
		 }

		 if(count===3){
			let amsAttribute={
				"Status": '0',
				"Priority": '0',
				"Service": '0',
				"Impacted CI": !GLOBAL.ciId?'0': GLOBAL.ciId,
				"Requestor": !GLOBAL.breakFixConsumerID?'0':GLOBAL.breakFixConsumerID,
				"Class": !GLOBAL.ciClassId?'0':GLOBAL.ciClassId,
				"User.Location": !GLOBAL.servicelocationid?'0':GLOBAL.servicelocationid,
				"CI.Location": !GLOBAL.ciLocationId?'0':GLOBAL.ciLocationId
			};

			let rulesAttribute={
				"status":  undefined,
				"priorityId": undefined,
				"serviceName": undefined,
				"impactedCi":  GLOBAL.ciName,
				"impactedUsername": GLOBAL.breakFixConsumerName,
				"ciClassName": GLOBAL.ciClassName,
				"userLocation": GLOBAL.servicelocationname,
				"ciLocation": GLOBAL.ciLocationName,
				"impactedCiId": !GLOBAL.ciId?'0': GLOBAL.ciId,
				"requestorId": !GLOBAL.breakFixConsumerID?'0':GLOBAL.breakFixConsumerID,
				"classId": !GLOBAL.ciClassId?'0':GLOBAL.ciClassId,
				"userLocationId": !GLOBAL.servicelocationid?'0':GLOBAL.servicelocationid,
				"ciLocationId": !GLOBAL.ciLocationId?'0':GLOBAL.ciLocationId
			};
			this.setState({ rulesAttribute });
			// this.props.loadNewBreakFixSGAEChange(GLOBAL.ciId===''?'0':GLOBAL.ciId,GLOBAL.ciConsumerCompany===''?this.state.companyId:GLOBAL.ciConsumerCompany, "ChangeManagement","impactedCI",amsAttribute);
			// this.props.loadNewBreakFixSGAE(GLOBAL.ciId===''?'0':GLOBAL.ciId,GLOBAL.ciConsumerCompany===''?this.state.companyId:GLOBAL.ciConsumerCompany, "ChangeManagement","impactedCI",amsAttribute);
			this.setState({disableTypeAhead: false});
		 }else{
			this.setState({disableTypeAhead: true});
		 }
		let breakFixSGIName={'data':[]};
		let typeaheadData=[];
        this.props.dispatch(change('changeForm','implementationGroup',''));
		this.props.dispatch(change('changeForm','implementationIndividual',''));
		this.props.dispatch(change('changeForm','changeManagementGroup',''));
		this.props.dispatch(change('changeForm','changeManagementIndividual',''));
		this.props.dispatch({type:'CHANGEMANAGEMENT_INDIVIDUAL_LOAD_SUCCESS',breakFixSGIName});
		this.props.dispatch({type:'CHANGEMANAGEMENT_IMPL_INDIVIDUAL_LOAD_SUCCESS',breakFixSGIName});
		this.props.dispatch({type:'GROUP_LIST_TYPE_AHEAD_INITIAL',typeaheadData});
		this.props.dispatch({type:'LOAD_BREAKFIXSGAECHANGE_SUCCESS_TYPEAHED',typeaheadData});
		this.props.dispatch({type:'CHANGEMANAGEMENT_IMPL_INDIVIDUAL_LOAD_SUCCESS_TYPE',typeaheadData});
        this.props.dispatch({type:'CHANGEMANAGEMENT_INDIVIDUAL_LOAD_SUCCESS_TYPE',typeaheadData});
		GLOBAL.FoundationgrpImp=false;
        GLOBAL.Foundationgrp=false;
		this.setState({typedIndividualI: '', typedGroupI: '', selectedImpgroups: [], selectedImpinds: [],
		               typedGroupC: '', selectedChgroups: [], selectedChginds: [], typedIndividualC: '', AllImplmtAMSGrps: false, AllAMSGrps: false});
		this.props.setAssignmentGrpUsr([],"group");
		this.props.setAssignmentGrpUsrImp([],"group");
	  }
      afterConsumerSelected(){
		if(GLOBAL.breakFixConsumerID!==''){
			this.showConsumerInfoIcon(true);
		  }else{
			this.showConsumerInfoIcon(false);
		  }
        this.checkforAMSattributes('','');
	  }
      clearGroupandIndividual(){
		let breakFixSGIName={'data':[]};
		let typeaheadData=[];
        this.props.dispatch(change('changeForm','implementationGroup',''));
		this.props.dispatch(change('changeForm','implementationIndividual',''));
		this.props.dispatch(change('changeForm','changeManagementGroup',''));
		this.props.dispatch(change('changeForm','changeManagementIndividual',''));
		this.props.dispatch({type:'CHANGEMANAGEMENT_INDIVIDUAL_LOAD_SUCCESS',breakFixSGIName});
		this.props.dispatch({type:'CHANGEMANAGEMENT_IMPL_INDIVIDUAL_LOAD_SUCCESS',breakFixSGIName});
		this.props.dispatch({type:'GROUP_LIST_TYPE_AHEAD_INITIAL',typeaheadData});
		this.props.dispatch({type:'LOAD_BREAKFIXSGAECHANGE_SUCCESS_TYPEAHED',typeaheadData});
		this.props.dispatch({type:'CHANGEMANAGEMENT_IMPL_INDIVIDUAL_LOAD_SUCCESS_TYPE',typeaheadData});
        this.props.dispatch({type:'CHANGEMANAGEMENT_INDIVIDUAL_LOAD_SUCCESS_TYPE',typeaheadData});
		GLOBAL.FoundationgrpImp=false;
        GLOBAL.Foundationgrp=false;
		this.setState({typedIndividualI: '', typedGroupI: '', selectedImpgroups: [], selectedImpinds: [],
		               typedGroupC: '', selectedChgroups: [], selectedChginds: [], typedIndividualC: '', disableTypeAhead: true, AllImplmtAMSGrps: false, AllAMSGrps: false});
	    this.props.setAssignmentGrpUsr([],"group");
		this.props.setAssignmentGrpUsrImp([],"group");
	  }
	  showConsumerInfoIcon(value){
		if(!value){
			this.props.showImplementIndividualDetails(false);
			this.props.showIndividualDetails(false);
			this.showImplIndividualInfoIcon(false);
			this.showChangeIndividualInfoIcon(false);
		}
		this.setState({showConsumerInfoButton: value});
	  }
	  showImpactedCIInfoIcon(value){
		if(!value){
			this.props.showImplementIndividualDetails(false);
			this.props.showIndividualDetails(false);
			this.showImplIndividualInfoIcon(false);
			this.showChangeIndividualInfoIcon(false);
		}
		this.setState({showImpactedCIInfoButton: value});
	  }
	  showImplIndividualInfoIcon(value){
        this.setState({showImplIndividualInfoButton: value});
	  }
	  showChangeIndividualInfoIcon(value){
        this.setState({showChangeIndividualInfoButton: value});
	  }
      render(){
		
		// console.log('***********', this.state, this.props);

        const {
			type,
			changeTiming,
			businessRisk,
			technicalRisk,
			planType,
			implementationAndChangeManagementGroup,
			handleSubmit,
			pristine,
			reset,
			submitting,
			ChangeManagementGroup,
			GroupInd,
			GroupIndividual,
			changeMngmntIndividual,
			urgency,
			reasonForChange,
			riskOccurrenceProbability,
			riskImpact,
			controlEffectiveness,
			cmdbUpdateNeeded
		} = this.props;
riskOccurrenceProbability.map((a)=>{
	a.field1Value=parseInt(a.field1Value, 10);

})
riskImpact.map((a)=>{
	a.field1Value=parseInt(a.field1Value, 10);
})
controlEffectiveness.map((a)=>{
	a.field1Value=parseInt(a.field1Value, 10);
})
riskOccurrenceProbability.sort((a, b) => (a.field1Value > b.field1Value) ? 1 : -1);
riskImpact.sort((a, b) => (a.field1Value > b.field1Value) ? 1 : -1);
controlEffectiveness.sort((a, b) => (a.field1Value < b.field1Value) ? 1 : -1);
reasonForChange.sort((a, b) => a.field1Key.localeCompare(b.field1Key));
		return (
            <div>
					<div className="borderForm bFormDiv">
						<Row>
						<Col md={4}>
						     <Form.Group>
							      <Form.Label bsClass=""><span className="rStar" />{this.props.translator['Company']}</Form.Label>
                                  <Field name="company" component={TypeaheadExampleSingleSelect} className="form-control" options={this.state.companyList} selectedOptions={this.state.selectedCompanyList} onSelection={this.setCompany} setErrorColor={this.validateField} diableStatus={false}
								  onInputChange={this.companyInputChange}
								  onCrossClick={this.onCrossClickCompany}
								  typedValue={this.state.typedCompany}
								  errorClass={this.props.companyError?'error':''}
								  />
						     </Form.Group>
						</Col>
							<Col md={4}>
								<Form.Group>
									<Form.Label bsClass=""><span className="rStar" />{this.props.translator['Type']}</Form.Label>
									<Field name="type" component={_dropDown2} options={type} className="form-control"
									onChange={(e) => this.onTypeSelect(e)}/>
								</Form.Group>
							</Col>
							{/* <Col md={4}>
								<Form.Group>
									<Form.Label bsClass=""><span className="rStar" />{this.props.translator['Summary']}</Form.Label>
									<Field name="summary" type="textarea" component={_textArea} label="" className="form-control" rows="1" maxlength="500" onChange={this.onSummaryInputChange}/>
                  <p className="charLeft" style={{textAlign:"right","fontSize":"11px"}}>({this.props.translator['Characters Remaining']}: {this.state.summaryCharCountLeft}/500)</p>
								</Form.Group>
							</Col> */}
							<Col md={4}>
								<Form.Group>
									<Form.Label bsClass=""><span className="rStar" />{this.props.translator['Requestor']}</Form.Label>
									<div className="position-re infoBtnDiv">
										<Field name="requestor"
											options={this.state.requestorOptions}
											component={RFReactConsumer}
											setErrorColor = {this.setErrorColor}
											companyId={this.state.companyId || ''}
											afterConsumerSelected={this.afterConsumerSelected}
											checkforAMSattributes={this.checkforAMSattributes}
											clearGroupandIndividual={this.clearGroupandIndividual}
											onConsumerSelected={this.onConsumerSelected}
											selectedOption={this.state.selectedRequestor}
											showConsumerInfoIcon = {this.showConsumerInfoIcon}
										    showConsumerDetails={this.props.showConsumerDetails}
											errorColor={this.props.consumerErrorColor}
											/>
	                               {this.state.showConsumerInfoButton?
										<Button bsPrefix=' ' title="Info" bsClass="" className="infoicn" onClick={() => this.props.showConsumerDetails(true) }>
											<ImInfo/>
										</Button>
										:null}
									</div>
								</Form.Group>
							</Col>
							<Col md={4}>
							 <Form.Group>
								<Form.Label bsClass=""><span className="rStar" />{this.props.translator['Impacted CI']}</Form.Label>
								<div className="position-re infoBtnDiv" onBlur={this.handCall} onChange={this.handCall}>
									<Field name="ciName"
										options={this.state.impactedServiceDataDetails}
										companyId={this.state.companyId}
										component={CIDetailsService}
										checkforAMSattributes={this.checkforAMSattributes}
										onImpactedSelection={this.onImpactedSelection}
										setErrorImpactedColor = {this.props.setErrorImpactedColor}
										clearGroupandIndividual={this.clearGroupandIndividual}
										createChange={'Change'}
										selectedOption={this.state.selectedImpactedCi}
										showImpactedCIInfoIcon={this.showImpactedCIInfoIcon}
										showImpactedCIDetails={this.props.showImpactCI}
										errorColor={this.props.ImpactedErrorColor}
										>
		</Field> {this.state.showImpactedCIInfoButton?
										<Button bsPrefix=' ' title="Info" bsClass="" className="infoicn" onClick={() => { this.props.showImpactCI(true);}}>
											<ImInfo/>
										</Button>
										:null}
								</div>
							 </Form.Group>
					      </Col>


						  <Col md={4}>
						        <Form.Group>
					            <Form.Label bsClass="">{this.props.translator['Class']}</Form.Label>
						        <Field name="ciClassName" type="text" component={_inputField} label="" className="form-control" readOnly/>
			                    </Form.Group>
					      </Col>
						{/* </Row> */}

						{this.props.selectedType == "30"  ?
							<>
								<Col md={4}>
									<Form.Group>
										<Form.Label bsClass=""><span className="rStar" />{this.props.translator['Reason for Change']}</Form.Label>
										<Field name="reasonForChange" component={_dropDown} options={reasonForChange} className="form-control" />
									</Form.Group>
								</Col>
								<Col md={4}>
									<Form.Group>
										<Form.Label bsClass=""><span className="rStar" />{this.props.translator['CMDB Update Needed']}</Form.Label>
										<Field name="cmdbUpdateNeeded" component={_dropDown} options={cmdbUpdateNeeded} className="form-control" >
										</Field>
									</Form.Group>
								</Col>
							</> : ""
						}
						{this.props.selectedType == "25" || this.props.selectedType == "35" ?
						<>
							<Col md={4}>
								<Form.Group>
									<Form.Label bsClass=""><span className="rStar" />{this.props.translator['Reason for Change']}</Form.Label>
									<Field name="reasonForChange" component={_dropDown} options={reasonForChange} className="form-control" />
								</Form.Group>
							</Col>
								<Col md={4}>
									<Form.Group>
										<Form.Label bsClass=""><span className="rStar" />{this.props.translator['CMDB Update Needed']}</Form.Label>
										<Field name="cmdbUpdateNeeded" component={_dropDown} options={cmdbUpdateNeeded} className="form-control" >
										</Field>
									</Form.Group>
								</Col>
								<Col md={4}>
								<Form.Group>
									<Form.Label bsClass=""><span className="rStar" />{this.props.translator['Urgency']}</Form.Label>
									<Field name="urgency" component={_dropDown} options={urgency} className="form-control" />
								</Form.Group>
							</Col>
						</>:""}
						</Row>
						<Row>
						<Col md={12}>
								<Form.Group>
									<Form.Label bsClass=""><span className="rStar" />{this.props.translator['Summary']}</Form.Label>
									<Field name="summary" type="textarea" component={_textArea} label="" className="form-control" rows="2" maxlength="500" onChange={this.onSummaryInputChange}/>
                  						<p className="charLeft" style={{textAlign:"right","fontSize":"11px"}}>({this.props.translator['Characters Remaining']}: {this.state.summaryCharCountLeft}/500)</p>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col md={12}>
								<Form.Group>
									<Form.Label bsClass=""><span className="rStar" /> {this.props.translator['Description']}</Form.Label>
									<Field name="description" type="textarea" component={_textArea} label="" className="form-control" rows="2" maxlength="16000"/>
								</Form.Group>
							</Col>
						</Row>
						{/* {this.props.selectedType == "25" || this.props.selectedType == "30" || this.props.selectedType == "35" ?
						<div className="padding-t-5 padding-r-10 padding-l-15 margin-b-15 border">
								{ <div className="bgGray padding-5">
									{(this.state.changeType != '35') && <span className="rStar" />}
									{this.props.translator['Risk Assessment Form']}
								</div> }
							<Row>
								<Col md={4}>
									<Form.Group>
										<Form.Label bsClass="">{this.props.translator['Risk Occurrence Probability']}</Form.Label>
										<Field name="riskOccurrenceProbability" component={_dropDown} options={riskOccurrenceProbability}
										 onChange={(e) => this.getRiskCalculated("riskOccurrenceProbability",e)} className="form-control"/>
									</Form.Group>
								</Col>
								<Col md={4}>
									<Form.Group>
										<Form.Label bsClass="">{this.props.translator['Impact if Risk is realized']}</Form.Label>
										<Field name="riskImpact" component={_dropDown} options={riskImpact}
										 onChange={(e) => this.getRiskCalculated("riskImpact",e)} className="form-control" />
									</Form.Group>
								</Col>
								<Col md={4}>
									<Form.Group>
										<Form.Label bsClass="">{this.props.translator['Control Effectiveness']}</Form.Label>
										<Field name="controlEffectiveness" component={_dropDown} options={controlEffectiveness}
										  onChange={(e) => this.getRiskCalculated("controlEffectiveness",e)} className="form-control" />
									</Form.Group>
								</Col>
								</Row>
								<Row>
								<Col md={4}>
						<Form.Group>
							<Form.Label bsClass="">{this.props.translator['Overall Risk']}</Form.Label>
							<Field name="overallRisk" component="input" type="text" className="form-control" disabled="true" placeholder={this.props.riskOccurrenceVal != "" && this.props.riskOccurrenceVal != undefined && this.props.riskImpactVal != ""
								 && this.props.riskImpactVal != undefined &&	this.props.controlEffectivenesVal != ""
								 && this.props.controlEffectivenesVal != undefined ?this.props.riskAssessmentValue.overallRisk:""}>
              </Field>
						</Form.Group>
					</Col>
							</Row>
						</div>
						:
						 <div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
							<div className="bgGray padding-5"><span className="rStar" /> {this.props.translator['Change Classification']}</div>
							<Row>

								<Col md={4}>
									<Form.Group>
										<Form.Label bsClass="">{this.props.translator['Change Timing']}</Form.Label>
										<Field name="changeTiming" component={_dropDown} options={changeTiming} className="form-control" onChange={this.onChangeTiming}/>
									</Form.Group>
								</Col>

								<Col md={4}>
									<Form.Group>
										<Form.Label bsClass="">{this.props.translator['Overall Technical Risk']}</Form.Label>
										<Field name="overallTechnicalRisk" component={_dropDown} options={technicalRisk} className="form-control" />
									</Form.Group>
								</Col>
								<Col md={4}>
									<Form.Group>
										<Form.Label bsClass="">{this.props.translator['Overall Business Risk']}</Form.Label>
										<Field name="overallBusinessRisk" component={_dropDown} options={businessRisk} className="form-control" />
									</Form.Group>
								</Col>
							</Row>
						</div>
						} */}

						{(this.state.changeType == '25' || this.state.changeType == '30' || this.state.changeType == '35') && <RiskAssessmentForm 
							translator={this.props.translator}
							updateRiskAssessmentDetails = {this.props.updateRiskAssessmentDetails}
							companyId = {this.state.companyId || ''}
							mode='add'
							changeType={this.state.changeType || ''}
							// isEditable={( !this.props.roleIdentification('48') ) ? true : false}
							isEditable={( this.props.roleIdentification('19') || this.props.roleIdentification('20') || this.props.roleIdentification('51') || this.props.roleIdentification('52') ) ? true : false}
						/>}

						{!this.state.showChangeTiming
							?
							<Row>
								<Col md={6}>
									<div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
										<div className="bgGray padding-5"><span className="rStar" /> {this.props.translator['Schedule']}</div>
										<Row>
											<Col md={6}>
												<Form.Group>
													<Form.Label bsClass="">{this.props.translator['Scheduled Start Date']}</Form.Label>
													<div className="dateTimeSdiv dateTimePosTop">
														<Field name="expectedStartDate" component={_dateTimeField} className="form-control dateTimePickerField" formType="create" disabledValue={this.state.showChangeTiming} />
													</div>
												</Form.Group>
											</Col>
											<Col md={6}>
												<Form.Group>
													<Form.Label bsClass="">{this.props.translator['Scheduled End Date']}</Form.Label>
													<div className="dateTimeSdiv dateTimePosTop">
														<Field name="expectedEndDate" component={_dateTimeField} className="form-control dateTimePickerField" formType="create" disabledValue={this.state.showChangeTiming} />
													</div>
												</Form.Group>
											</Col>
										</Row>
									</div>
								</Col>

								<Col md={6}>
									<div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
										<div className="bgGray padding-5">
											<div className="float-r">
												<Form.Group className="margin-0">
													<Field name="downtimeRad" component="input" type="radio" inline value="Y" checked={this.state.selectedOption === 'Y'} onClick={() => { this.disableEnableDate('Y'); this.props.dispatch(change("changeForm", "downtimeRad", 'Y')); this.props.dispatch(change("changeForm", "startDate", moment().tz(timezone))); this.props.dispatch(change("changeForm", "endDate", moment().tz(timezone))); this.setSelectedOption('Y'); }} />{this.props.translator['Yes']}
													<Field defaultChecked component="input" type="radio" name="downtimeRad" inline value="N" checked={this.state.selectedOption === 'N'} onClick={() => { this.disableEnableDate('N'); this.props.dispatch(change("changeForm", "downtimeRad", 'N')); this.props.dispatch(change("changeForm", "startDate", '')); this.props.dispatch(change("changeForm", "endDate", '')); this.setSelectedOption('N'); }} />{this.props.translator['No']}
												</Form.Group>
											</div>
											<span className="rStar"></span>{this.props.translator['Downtime Required']}
										</div>
                                         {
									    this.state.selectedOption=='Y'?
										<Row>
											<Col md={6}>
											<Form.Group>
													<Form.Label bsClass="">{this.props.translator['Downtime Start Date']}</Form.Label>
													<div className="dateTimeSdiv dateTimePosTop">
														<Field name="startDate" component={_dateTimeField} className="form-control dateTimePickerField" formType="create" disabledValue={this.state.downTimeDateDisabled} />
													</div>
												</Form.Group>
											</Col>
											<Col md={6}>
											<Form.Group>
													<Form.Label bsClass="">{this.props.translator['Downtime End Date']}</Form.Label>
													<div className="dateTimeSdiv dateTimePosTop">
														<Field name="endDate" component={_dateTimeField} className="form-control dateTimePickerField" formType="create" disabledValue={this.state.downTimeDateDisabled} />
													</div>
												</Form.Group>
											</Col>
										</Row>
										:
										<Row>
											<Col md={6}>
												<Form.Group>
													<Form.Label bsClass="">{this.props.translator['Downtime Start Date']}</Form.Label>
													<div className="dateTimeSdiv dateTimePosTop">
														<Field name="startDate" component={_latentDateTimeField} className="form-control dateTimePickerField" formType="createdowntime" disabledValue={this.state.downTimeDateDisabled}

														/>
													</div>
												</Form.Group>
											</Col>
											<Col md={6}>
												<Form.Group>
													<Form.Label bsClass="">{this.props.translator['Downtime End Date']}</Form.Label>
													<div className="dateTimeSdiv dateTimePosTop">
														<Field name="endDate" component={_latentDateTimeField} className="form-control dateTimePickerField" formType="createdowntime" disabledValue={this.state.downTimeDateDisabled} />
													</div>
												</Form.Group>
											</Col>
										</Row>

	                                   }
									</div>
								</Col>
							</Row>

							:

							<Row>
								<Col md={6}>
									<div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
										<div className="bgGray padding-5"><span className="rStar" /> {this.props.translator['Actual Schedule']}</div>
										<Row>
											<Col md={6}>
												<Form.Group>
													<Form.Label bsClass="">{this.props.translator['Actual Start Date']}</Form.Label>
													<div className="dateTimeSdiv dateTimePosTop">
														<Field name="actualStartDate" component={_latentDateTimeField} formType="create" className="form-control dateTimePickerField"  />
													</div>
												</Form.Group>
											</Col>
											<Col md={6}>
												<Form.Group>
													<Form.Label bsClass="">{this.props.translator['Actual End Date']}</Form.Label>
													<div className="dateTimeSdiv dateTimePosTop">
														<Field name="actualEndDate" component={_latentDateTimeField} formType="create" className="form-control dateTimePickerField"  />
													</div>
												</Form.Group>
											</Col>
										</Row>
									</div>
								</Col>


								<Col md={6}>
									<div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
										<div className="bgGray padding-5">
											<div className="float-r">
												<Form.Group className="margin-0">
													<Field name="downtimeRad" component="input" type="radio" inline value="Y" checked={this.state.selectedOption === 'Y'} onClick={() => { this.disableEnableDate('Y'); this.props.dispatch(change("changeForm", "downtimeRad", 'Y'));this.props.dispatch(change("changeForm", "startDate", moment().tz(timezone))); this.props.dispatch(change("changeForm", "endDate", moment().tz(timezone))); this.setSelectedOption('Y'); }} />{this.props.translator['Yes']}
													<Field defaultChecked component="input" type="radio" name="downtimeRad" inline value="N" checked={this.state.selectedOption === 'N'} onClick={() => { this.disableEnableDate('N'); this.props.dispatch(change("changeForm", "downtimeRad", 'N')); this.props.dispatch(change("changeForm", "startDate", '')); this.props.dispatch(change("changeForm", "endDate", '')); this.setSelectedOption('N'); }} />{this.props.translator['No']}
												</Form.Group>
											</div>
											<span className="rStar"></span>{this.props.translator['Downtime Required']}
										</div>
										{
									    this.state.selectedOption=='Y'?
										<Row>
											<Col md={6}>
												<Form.Group>
													<Form.Label bsClass="">{this.props.translator['Downtime Start Date']}</Form.Label>
													<div className="dateTimeSdiv dateTimePosTop">
														<Field name="startDate" component={_dateTimeField} className="form-control dateTimePickerField" formType="create" disabledValue={this.state.downTimeDateDisabled} />
													</div>
												</Form.Group>
											</Col>
											<Col md={6}>
												<Form.Group>
													<Form.Label bsClass="">{this.props.translator['Downtime End Date']}</Form.Label>
													<div className="dateTimeSdiv dateTimePosTop">
														<Field name="endDate" component={_dateTimeField} className="form-control dateTimePickerField" formType="create" disabledValue={this.state.downTimeDateDisabled} />
													</div>
												</Form.Group>
											</Col>
										</Row>
										:
										<Row>
											<Col md={6}>
												<Form.Group>
													<Form.Label bsClass="">{this.props.translator['Start Date']}</Form.Label>
													<div className="dateTimeSdiv dateTimePosTop">
														<Field name="startDate" component={_latentDateTimeField} className="form-control dateTimePickerField" formType="createdowntime" disabledValue={this.state.downTimeDateDisabled} />
													</div>
												</Form.Group>
											</Col>
											<Col md={6}>
												<Form.Group>
													<Form.Label bsClass="">{this.props.translator['End Date']}</Form.Label>
													<div className="dateTimeSdiv dateTimePosTop">
														<Field name="endDate" component={_latentDateTimeField} className="form-control dateTimePickerField" formType="createdowntime" disabledValue={this.state.downTimeDateDisabled} />
													</div>
												</Form.Group>
											</Col>
										</Row>
	}
									</div>
								</Col>
							</Row>

						}
						<Row>
							<Col md={6}>
								<div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
									<div className="bgGray padding-5 inciSupProviderDv">{this.props.translator['Implementation']}{this.props.roleIdentification('20')||this.props.roleIdentification('52')?<span><span> {this.state.AllImplmtAMSGrps==false?<a className="showwAllGrps"  href='javascript:void(0)' onClick={this.AllimplmtGrps}>Show ALL Groups</a>:<a className="showwAllGrps"  href='javascript:void(0)' onClick={this.ImplmtAMSGrp}>Show AMS Groups</a>}</span></span>:null}</div>
									<Row>
										<Col md={6}>
											<Form.Group>
												<Form.Label bsClass="">{this.props.translator['Group']}</Form.Label>
												<div className="dvTypehdropdown">
													<RuleAmsDdropDown
													name="implementationGroup" 
													className="form-control"
													component={TypeaheadExampleSingleSelect} 
													groupData={implementationAndChangeManagementGroup} 
													onSelection={this.setImpgroupval}
													selectedOptions={this.state.selectedImpgroups}
													onInputChange={this.onGroupInputChangeI}
													onCrossClick={this.onCrossClickGroupI}
													disabled={this.state.disableTypeAhead}
													typedValue={this.state.typedGroupI}
													errorClass=''
													moduleName="changemanagement"
													showAms={this.state.AllImplmtAMSGrps}
													ruleType="assignment"
													formData={this.props}
													rulesAttribute={this.state?.rulesAttribute}
													ruleFor="implementation"
													/>
												{/* <Field name="implementationGroup" className="form-control" component={TypeaheadExampleSingleSelect} options={implementationAndChangeManagementGroup} onSelection={this.setImpgroupval} selectedOptions={this.state.selectedImpgroups}
												onInputChange={this.onGroupInputChangeI}
												onCrossClick={this.onCrossClickGroupI}
												disabled={this.state.disableTypeAhead}
												typedValue={this.state.typedGroupI}
												errorClass=''
												/> */}
												</div>
											</Form.Group>
										</Col>
										<Col md={6}>
											<Form.Group className='prblmBtnDv infoBtnDiv typeHeadicn downinfobtn'>
												<Form.Label bsClass="">{this.props.translator['Individual']}</Form.Label>
												<div className="dvTypehdropdown">
												<Field name="implementationIndividual" className="form-control" component={TypeaheadExampleSingleSelect} options={GroupInd} onSelection={this.setImpindval} selectedOptions={this.state.selectedImpinds} setErrorColor={this.seterrorimpindColor}
												onInputChange={this.onIndividualInputChangeI}
												onCrossClick={this.onCrossClickIndividualI}
												disabled={this.state.disableTypeAhead}
												typedValue={this.state.typedIndividualI}
												/>
												 
												</div>

												{!this.state.disableTypeAhead  && <div className={this.state.selectedImpgroups.length>0 || this.state.showImplIndividualInfoButton?'indiIcnDv':''}>
												{this.state.selectedImpgroups.length>0?<Button title={this.props.translator["Show available individuals"]} bsPrefix=" " className="asignicn" onClick={()=>{this.props.changeActionView("showIndividualImp",false);}}><RiUserSearchLine /></Button>:null}
												{this.state.showImplIndividualInfoButton?<Button bsPrefix=' ' title="Info" bsClass="" className="asignicn" onClick={() => { this.props.showImplementIndividualDetails(true)}}><ImInfo/></Button>:null}
												</div>}
											</Form.Group>
										</Col>
									</Row>
								</div>
							</Col>
							<Col md={6}>
								<div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
									<div className="bgGray padding-5 inciSupProviderDv">{this.props.translator['Change Management']}{this.props.roleIdentification('20')||this.props.roleIdentification('52')?<span><span> {this.state.AllAMSGrps==false?<a className="showwAllGrps"  href='javascript:void(0)' onClick={this.AllGrps}>Show ALL Groups</a>:<a className="showwAllGrps"  href='javascript:void(0)' onClick={this.AllAMSGrps}>Show AMS Groups</a>}</span></span>:null}</div>
									<Row>
										<Col md={6}>
											<Form.Group>
												<Form.Label bsClass="">{this.props.translator['Group']}</Form.Label>
												<div className="dvTypehdropdown">
												<RuleAmsDdropDown
													name="changeManagementGroup"
													className="form-control" 
													component={TypeaheadExampleSingleSelect}
													groupData={ChangeManagementGroup}
													onSelection={this.setChgroupval}
													selectedOptions={this.state.selectedChgroups}
													onInputChange={this.onGroupInputChangeC}
													onCrossClick={this.onCrossClickGroupC}
													disabled={this.state.disableTypeAhead}
													typedValue={this.state.typedGroupC}
													errorClass=''
													moduleName="changemanagement"
													showAms={this.state.AllAMSGrps}
													ruleType="assignment"
													formData={this.props}
													rulesAttribute={this.state?.rulesAttribute}
													ruleFor="changemanager"
													/>
												{/* <Field name="changeManagementGroup" className="form-control" component={TypeaheadExampleSingleSelect} options={ChangeManagementGroup} onSelection={this.setChgroupval} selectedOptions={this.state.selectedChgroups}
												onInputChange={this.onGroupInputChangeC}
												onCrossClick={this.onCrossClickGroupC}
												disabled={this.state.disableTypeAhead}
												typedValue={this.state.typedGroupC}
												errorClass=''
												/> */}
												</div>
											</Form.Group>
										</Col>
										<Col md={6}>
											<Form.Group className='prblmBtnDv infoBtnDiv typeHeadicn downinfobtn form-group'>
												<Form.Label bsClass="">{this.props.translator['Individual']}</Form.Label>
												<div className="dvTypehdropdown">
												<Field name="changeManagementIndividual" className="form-control" component={TypeaheadExampleSingleSelect} options={changeMngmntIndividual} onSelection={this.setChgindval} selectedOptions={this.state.selectedChginds} setErrorColor={this.seterrorchgindColor}
												onInputChange={this.onIndividualInputChangeC}
												onCrossClick={this.onCrossClickIndividualC}
												disabled={this.state.disableTypeAhead}
												typedValue={this.state.typedIndividualC}
												/>
												
											    </div>
												{!this.state.disableTypeAhead && <div className={this.state.selectedChgroups.length>0 || this.state.showChangeIndividualInfoButton?"indiIcnDv":''}>
												{this.state.selectedChgroups.length>0?<Button title={this.props.translator["Show available individuals"]} bsPrefix=" " className="asignicn" onClick={()=>{this.props.changeActionView("showIndividualGroup",false);}}><RiUserSearchLine /></Button>:null}
												{this.state.showChangeIndividualInfoButton?<Button bsPrefix=' ' title="Info" bsClass="" className="asignicn" onClick={() => {this.props.showIndividualDetails(true)}}><ImInfo/></Button>:null}
												</div>}

											</Form.Group>
										</Col>
									</Row>
								</div>
							</Col>
						</Row>
					</div>
				</div>
		);
      }

}

const mapStateToProps = ({
	lovForType,
	lovForChangeTiming,
	lovForBusinessRisk,
	lovForTechnicalRisk,
	lovForPlanType,
	breakFixSGAENameType,
	breakFixSGAEChangeNameType,
	changeManagementGroupImplIndividualTypeahead,
	changeManagementGroupIndividualTypeahead,
	lovForUrgency,
	lovForReasonForChange,
	allGroups,
	lovForRiskOccurrence, lovForRiskImpact, lovForControlEffectiveness, riskAssessmentValue,showLoader3,ItemList
}) => {

	// let lovForTypeUpd = [];
	// if(lovForType && lovForType.length){
	// 	lovForTypeUpd = [...lovForType];
	// 	lovForTypeUpd.push({
	// 		"menulistId": "",
	// 		"module": "ChangeManagement",
	// 		"field1Name": "Type",
	// 		"field1Key": "Latent Change",
	// 		"field1Value": "35"
	// 	});
	// }

	let type = lovForType == "undefined" || lovForType.length == 0 ? [] : lovForType;
	// let type = lovForTypeUpd;
	let changeTiming = lovForChangeTiming == "undefined" || lovForChangeTiming.length == 0 ? [] : lovForChangeTiming;
	let businessRisk = lovForBusinessRisk == "undefined" || lovForBusinessRisk.length == 0 ? [] : lovForBusinessRisk;
	let technicalRisk = lovForTechnicalRisk == "undefined" || lovForTechnicalRisk.length == 0 ? [] : _.sortBy(lovForTechnicalRisk, o => parseInt(o.field1Value, 10));
	let planType = lovForPlanType == "undefined" || lovForPlanType.length == 0 ? [] : lovForPlanType;
	let implementationAndChangeManagementGroup = breakFixSGAENameType == "undefined" || breakFixSGAENameType.length == 0 ? [] : GLOBAL.FoundationgrpImp==false?breakFixSGAENameType:allGroups;
	let ChangeManagementGroup = breakFixSGAEChangeNameType == "undefined" || breakFixSGAEChangeNameType.length == 0 ? [] : GLOBAL.Foundationgrp==false?breakFixSGAEChangeNameType:allGroups;
	let GroupInd = changeManagementGroupImplIndividualTypeahead == "undefined"|| (implementationAndChangeManagementGroup.length== 0&&allGroups.length==0) || changeManagementGroupImplIndividualTypeahead.length == 0 ? [] : changeManagementGroupImplIndividualTypeahead;
	let GroupIndividual = implementationAndChangeManagementGroup.length==0 ? [] : GroupInd.concat(implementationAndChangeManagementGroup);
	let changeMngmntIndividual = changeManagementGroupIndividualTypeahead == "undefined" ||(ChangeManagementGroup.length==0&&allGroups.length==0) || changeManagementGroupIndividualTypeahead.length == 0 ? [] : changeManagementGroupIndividualTypeahead;
	let urgency =  lovForUrgency == "undefined" || lovForUrgency.length == 0 ? [] : lovForUrgency;
	let reasonForChange = lovForReasonForChange == "undefined" || lovForReasonForChange.length == 0 ? [] : lovForReasonForChange;
	let riskOccurrenceProbability = lovForRiskOccurrence == "undefined" || lovForRiskOccurrence.length == 0 ? [] : lovForRiskOccurrence;
	let riskImpact = lovForRiskImpact == "undefined" || lovForRiskImpact.length == 0 ? [] : lovForRiskImpact;
	let controlEffectiveness = lovForControlEffectiveness == "undefined" || lovForControlEffectiveness.length == 0 ? [] : lovForControlEffectiveness;
	let cmdbUpdateNeeded = [{objkey:"Yes", objval:"Yes"},{objkey:"No", objval:"No"}]

	if(type.length != 0){
		type = type.filter((itm) => {
			return itm.field1Key != "Standard"
		})
	}

	return {
		type: type,
		changeTiming: changeTiming,
		businessRisk: businessRisk,
		technicalRisk: _.sortBy(technicalRisk, o => parseInt(o.field1Value, 10)),
		planType: planType,
		implementationAndChangeManagementGroup: GLOBAL.FoundationgrpImp==false?implementationAndChangeManagementGroup:allGroups,
		ChangeManagementGroup: GLOBAL.Foundationgrp==false ? ChangeManagementGroup: allGroups,
		GroupInd: GroupInd,
		GroupIndividual: GroupIndividual,
		changeMngmntIndividual:changeMngmntIndividual,
		urgency,
		reasonForChange,
		riskOccurrenceProbability,
		riskImpact,
		allGroups,
		controlEffectiveness,
		riskAssessmentValue,
		loading:showLoader3.loading,
		cmdbUpdateNeeded,
		ItemList
	};
};

ChangeForm = reduxForm({
	form: 'changeForm',
	fields: ['requestor','type','summary','ciClassName','reasonForChange','cmdbUpdateNeeded', 'urgency','description','riskOccurrenceProbability','riskImpact', 'overallRisk','changeTiming','overallTechnicalRisk', 'overallBusinessRisk','expectedStartDate','expectedEndDate','downtimeRad', 'controlEffectiveness','ciName','company'],
	validate,
	enableReinitialize: true
})(ChangeForm);

const selector = formValueSelector('changeForm');
ChangeForm = connect((state)=> {
	let selectedType = selector(state,'type');
	selectedType=(selectedType==undefined?25:selectedType)
	let riskOccurrenceVal = selector(state,'riskOccurrenceProbability');
	let riskImpactVal = selector(state,'riskImpact');
	let controlEffectivenesVal = selector(state,'controlEffectiveness');
	let ItemList = getFormValues("changeForm")(state);
	return{
		initialValues: {'type':25},
		selectedType,
		riskOccurrenceVal, riskImpactVal, controlEffectivenesVal,ItemList
	}
})(ChangeForm);

export default connect(mapStateToProps, {
	getLovValues,
	loadNewBreakFixSGAE,
	loadNewBreakFixSGAEChange, loadBreakFixSGI,loadChangeManagementIndividual,
	loadChangeManagementImplGroupIndividual,getRiskCalculated,getAllgroup,
	resetSomeFieldsEditChange
})(ChangeForm);





