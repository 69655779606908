
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const _myBreadCrumbComponent = (e) => {
  e.DomComponents.addType("myBreadCrumb", {
    model: {
      defaults: {
        tagName: "div",
        traits: [
          {
            type: "textarea",
            label: "Bread crumbs",
            name: "label",
            placeholder: "Home",
            changeProp: 1,
          },
          {
            type: "textarea",
            label: "Links",
            name: "breadCrumbLinks",
            placeholder: "Home",
            changeProp: 1,
          },
        ],
      },
      init() {
        this.on("change:label", this.handleInnerTextChange);
      },
      handleInnerTextChange() {
        const tag = this.find("span")[0];
        const label = this.get("label").split(",");
        let tags = ``;

        if (label.length > 0) {
          let i = 1;
          label.forEach((element) => {
            tags += `<li style="padding:0 5px">${element}</li>`;
            if (i !== label.length) {
              tags += `>`;
            }
            i++;
          });
        }

        if (tags) {
          tag.components(tags);
        }
      },
    },
  });
};

export const myBreadCrumbBlockJson = {
  label: "My Breadcrumb",
  // attributes: { class: "fa fa-circle" },
  media:`<svg data-name="Layer 1" viewBox="0 0 1000 1250" x="0px" y="0px"><title>5638-Breadcrumbs</title><path d="M236.93,739.17H100a16,16,0,0,1-13.15-25.12L235.37,500,86.85,286A16,16,0,0,1,100,260.83H236.93a16,16,0,0,1,13.15,6.88L404.92,490.88a16,16,0,0,1,0,18.24L250.08,732.29A16,16,0,0,1,236.93,739.17Zm-106.35-32h98L372.3,500,228.56,292.83h-98L268,490.88a16,16,0,0,1,0,18.24Z"/><path d="M491,739.17H354.11A16,16,0,0,1,341,714.05L489.48,500,341,286a16,16,0,0,1,13.14-25.12H491a16,16,0,0,1,13.15,6.88L659,490.88a16,16,0,0,1,0,18.24L504.19,732.29A16,16,0,0,1,491,739.17Zm-106.35-32h98L626.41,500,482.67,292.83h-98L522.1,490.88a16,16,0,0,1,0,18.24Z"/><path d="M745.16,739.17H608.22a16,16,0,0,1-13.14-25.12L743.59,500,595.08,286a16,16,0,0,1,13.14-25.12H745.16a16,16,0,0,1,13.14,6.88L913.15,490.88a16,16,0,0,1,0,18.24L758.3,732.29A16,16,0,0,1,745.16,739.17Zm-106.36-32h98L880.53,500,736.78,292.83h-98L776.21,490.88a16,16,0,0,1,0,18.24Z"/></svg>`,
  category: "Basic",
  content: `
      <div><ul data-gjs-type="myBreadCrumb" style="padding:0;list-style:none;display:flex;">
      <span style="display:flex;"><li>Home</li><span></ul>
      <div>
    `,
};
