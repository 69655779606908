
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Row, Col, Table, Button } from "react-bootstrap";

import TranslationForm from "./translationForm.js";
import { connect } from "react-redux";
import {
  spcmLoader,
  getTranslationForOfferingBasedOnType,
  getAttributes,
  setTranslationObject,
  getAttributesTranslationStatus,
} from "../../../../../actions/spcmActions";
import ListLoader from "../../../../common/loaders/ListLoader";
import _ from "lodash";
import Pagination from "react-js-pagination";

class AttributesListing extends React.Component {
  constructor(props) {
    super(props);
    this.whichFormOpen = this.whichFormOpen.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
  }

  whichFormOpen(value) {
    switch (value) {
      // case "Form1":
      //   return 
        // (
        //   <TextFieldForm1
        //     insideTableShowFun6={this.props.insideTableShowFun5}
        //     fieldName1={this.props.fieldName}
        //     fieldNameVal3={this.props.fieldNameVal2}
        //     lanNameVal3={this.props.lanNameVal2}
        //   />
        // );
      case "Form2":
        return (
          <TranslationForm
            insideTableShowFun6={this.props.insideTableShowFun5}
            fieldName1={this.props.fieldName}
            fieldNameVal3={this.props.fieldNameVal2}
            lanNameVal3={this.props.lanNameVal2}
            lanIdVal2={this.props.lanIdVal2}
            languageCode={this.props.languageCode}
            tr={this.props.tr}
          />
        );
    }
  }

  componentDidMount() {
    this.props.getTranslationForOfferingBasedOnType(
      this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
      this.props.lanIdVal2,
      "attributes"
    );
    this.props.getAttributes(
      this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
      "",
      "",
      ""
    );
  }

  onPageChange(e) {
    this.props.getAttributes(
      this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
      "",
      "",
      "",
      "",
      e,
      ""
    );
  }

  render() {
    console.log("getListForTranslation");
    var meta = this.props.spcmReducer.attributesMeta;
    let translatedAttributesList = this.props.spcmReducer.getListForTranslation;
    let baseLanguageAttributeList = this.props.spcmReducer.attributes;

    console.log(translatedAttributesList);
    console.log(baseLanguageAttributeList);

    translatedAttributesList = _.map(
      translatedAttributesList,
      _.partialRight(_.pick, ["ATTRIBUTE_NAME", "ATTRIBUTE_VALUE", "ID"])
    );

    const newTranslatedAttributesList = [];

    translatedAttributesList.map((item) => {
      newTranslatedAttributesList.push(
        _.mapKeys(item, (value, key) => {
          let newKey = key;
          if (key === "ATTRIBUTE_NAME") {
            newKey = "TRANSLATED_ATTR_NAME";
          }

          if (key === "ATTRIBUTE_VALUE") {
            newKey = "TRANSLATED_ATTR_VALUE";
          }

          return newKey;
        })
      );
    });

    var AttributesList = _.map(baseLanguageAttributeList, function (item) {
      return _.merge(
        item,
        _.find(newTranslatedAttributesList, { ID: Number(item.ATTRIBUTE_ID) })
      );
    });

    console.log("AttributesList");
    console.log(AttributesList);

    return (
      <div>
        {this.props.isInsideTableShow5 ? (
          <div>{this.whichFormOpen(this.props.whichInsideFormOpen5)}</div>
        ) : (
          <div>
            <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">
              Translate - {this.props.fieldNameVal2} - {this.props.lanNameVal2}{" "}
            </div></div>

            <div className="rBoxGap">
            <Row className="margin-b-5">
              <Col md={6}>
                <div className="f-size-18 colorStOrange">
                  {this.props.fieldNameVal2} List
                </div>
              </Col>
              <Col md={6} className="text-r">
                <Button
                  size="sm" className="backtolst"
                  onClick={() => {
                    this.props.lanEditPageFun5(false, "Form2");
                    this.props.getAttributesTranslationStatus(
                      this.props.spcmReducer.currentlySelectedServiceDetails
                        .OFFERING_ID,
                      this.props.lanIdVal2
                    );
                  }}
                >
                  <i className="fa fa-arrow-left margin-r-5"></i> Back to List
                </Button>
              </Col>
            </Row>
            <Table className="langTable tableView" striped bordered responsive>
              <thead>
                <tr>
                  <th width="20%">Attribute</th>
                  <th width="30%">Trans. Attr</th>
                  <th width="15%">Value</th>
                  <th width="30%">Trans. Val</th>
                  <th width="5%"></th>
                </tr>
              </thead>
              {this.props.spcmReducer.spcmFetchingAttribute ? (
                <tbody>
                  <td colSpan="5">
                    <ListLoader />
                  </td>
                </tbody>
              ) : (
                <tbody>
                  {AttributesList.map(function (value, index) {
                    return (
                      <tr>
                        <td>{value.ATTRIBUTE_NAME}</td>
                        <td>{value.TRANSLATED_ATTR_NAME}</td>
                        <td>{value.ATTRIBUTE_VALUE}</td>
                        <td>{value.TRANSLATED_ATTR_VALUE}</td>
                        <td>
                          <a
                            onClick={() => {
                              this.props.setTranslationObject(value);
                              this.props.insideTableShowFun5(true, "Form2");
                            }}
                            title="Edit"
                            href="javascript:void(0)"
                          >
                            <i className="fa fa-pencil-square-o"></i>
                          </a>
                        </td>
                      </tr>
                    );
                  }, this)}
                </tbody>
              )}
            </Table>
            <div className="text-c margin-t-10">
            <nav aria-label="Pagination" className="display-inline-block">
              {meta ? (
                <Pagination
                  activePage={meta ? meta.currentPage : 1}
                  itemsCountPerPage={10}
                  totalItemsCount={meta ? meta.rowCount : 1}
                  onChange={this.onPageChange}
                />
              ) : null}
              </nav>
            </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ spcmReducer }) => {
  return {
    spcmReducer,
  };
};

export default connect(mapStateToProps, {
  getTranslationForOfferingBasedOnType,
  getAttributes,
  setTranslationObject,
  getAttributesTranslationStatus,
})(AttributesListing);
