
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import ReactDOM from "react-dom";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import {
  loadReviewOrderList, loadPendingReviewOrderList, loadReviewOrderDetails, loadReviewBreakFixOrderDetails,
  loadReviewOrderEngineDetails, addToSelectedReviewStatusList,
  removeFromSelectedReviewStatusList, addToSelectedReviewOrderTypeList,
  removeFromSelectedReviewOrderTypeList, resetReviewFilterList
} from '../../actions/reviews/reviewAction';
import { getQuestionsCategoryWise } from '../../actions/cart/myOrders/myOrdersAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Spinner from 'react-spinkit';
import ListLoader from "../common/loaders/ListLoader";
import ListPagination from "react-js-pagination";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import _ from 'lodash';
import {IoSearch} from 'react-icons/io5';

class MySurveyList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      faCustomDesc1: "customArrow dropdown isActive",
      faCustomAsc1: "customArrow dropup",
      faCustomDesc2: "customArrow dropdown",
      faCustomAsc2: "customArrow dropup",
      faCustomDesc3: "customArrow dropdown",
      faCustomAsc3: "customArrow dropup",
      faCustomDesc4: "customArrow dropdown ",
      faCustomAsc4: "customArrow dropup",
      searchInput: "",
      displayNumberSearchInput: "",
      ciServiceNameSearchInput: "",
      requestedBySearchInput: "",
      ciServiceSearchFilter: "",
      submittedDateSearchFilter: "",
      requestedBySearchFilter: "",
      statusSearchFilter: "",
      sortBy: true,
      searchBy: "status",
      searchByVal: "Pending",
      displaySearchFilter: "",
      startDate: '',
      endDate: '',
      defaultvalue: "",
      date_format: '',
      lastSelectedSearchFilter: "displayNumber",
      isStatusCheckedValues: [],
      myclass: '',
      check_text_field: false,
      page: 0,
      limit: 10,
      isMultiSelectOpen: true,
    };

    this.onPageChange = this.onPageChange.bind(this);
    this.onPageSizeChange = this.onPageSizeChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.clickOutside = this.clickOutside.bind(this);
  }


  componentWillMount() {
    let listFilter = this.props.listFilter;
    console.log("ddddddd786",listFilter);
    let listSortValue = this.props.listSortValue;
    this.props.loadReviewOrderList("", "Breakfix,Item", "N", "displayNumber", "desc", listFilter, "", "", "", 0, 10, this.props.isSelf);
    document.addEventListener('mousedown', this.clickOutside);
  }

  componentDidUpdate(prevProps) {
    if (this.multiSelectDropdownRef != undefined || this.multiSelectDropdownRef != null) {
      ReactDOM.findDOMNode(this.multiSelectDropdownRef).childNodes[0].className = "multiSelectRef";
      let myRef = this.multiSelectDropdownRef;
      if (this.state.isStatusOpen != prevProps.isStatusOpen) {
        let obj = ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1];
        if (obj != undefined || obj != null) {
          ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1].className =
            "multiSelectPopup";
        }
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.clickOutside);
  }

  clickOutside(event) {
    const survyStatusDv = document.getElementById("survyStatusDv");

    if (this.multiSelectDropdownRef != undefined || this.multiSelectDropdownRef != null) {
      document.addEventListener("mousedown", (event) => {
        if (survyStatusDv.contains(event.target)) {
          // console.log("TYPE CLICKED");
          this.setState({ isMultiSelectOpen: true });
          this.props.showRightSide(false);
        }
        else {
          this.setState({ isMultiSelectOpen: false });
        }
        this.props.multiSelectOpenFun("mySurvyStatus", false);
      });
    }
  }

  onPageChange(e) {
    this.props.showRightSide(false);
    this.props.isFilterRerender(true);
    let listFilter = this.props.listFilter;
    let reviewReqTypeVar = 'Breakfix,Item';
    let reviewStatusVar = this.props.status;
    let reviewItemStatusVar = '75,25';
    let listSortValue = this.props.listSortValue;
    if (this.props.reviewReqType != "" && this.props.reviewReqType != undefined) {
      reviewReqTypeVar = this.props.reviewReqType;
    }
    if (this.props.reviewReqType != "" && this.props.reviewReqType != undefined) {
      reviewStatusVar = this.props.reviewStatus;
    }
    if (this.props.reviewItemStatus != "" && this.props.reviewItemStatus != undefined) {
      reviewItemStatusVar = this.props.reviewItemStatus;
    }

    let page = e - 1;
    this.props.loadReviewOrderList("", reviewReqTypeVar, reviewStatusVar, this.props.sortOnColumn, this.props.sortOrder, listFilter, this.props.searchKey, this.props.searchValue, this.props.pattern, page, this.props.listSize, this.props.isSelf);
    this.props.showActiveRow(0);
  }

  onPageSizeChange(e) {
    this.props.showRightSide(false);
    this.props.isFilterRerender(true);
    let listFilter = this.props.listFilter;
    let reviewReqTypeVar = 'Breakfix,Item';
    let reviewStatusVar = this.props.status;
    let reviewItemStatusVar = '75,25';
    let listSortValue = this.props.listSortValue;
    if (this.props.reviewReqType != "" && this.props.reviewReqType != undefined) {
      reviewReqTypeVar = this.props.reviewReqType;
    }
    if (this.props.reviewReqType != "" && this.props.reviewReqType != undefined) {
      reviewStatusVar = this.props.reviewStatus;
    }
    if (this.props.reviewItemStatus != "" && this.props.reviewItemStatus != undefined) {
      reviewItemStatusVar = this.props.reviewItemStatus;
    }


    let pageSize = parseInt(e.target.value, 10);
    this.props.saveListSize(pageSize);
    this.props.loadReviewOrderList("", reviewReqTypeVar, reviewStatusVar, this.props.sortOnColumn, this.props.sortOrder, listFilter, this.props.searchKey, this.props.searchValue, this.props.pattern, 0, pageSize, this.props.isSelf);
    this.props.showActiveRow(0);
  }

  onRowSelect(breakFixObj, index) {
    this.props.showRightSide(true);
    if (breakFixObj.type == "Breakfix") {
      this.props.getOfferingId(breakFixObj.offeringIdSx);
      this.props.loadReviewBreakFixOrderDetails(breakFixObj.itemId);
      this.props.getItemNumber(breakFixObj.itemNumber);
      this.props.getOrderNumber(breakFixObj.orderNumber);
      this.props.getFlagForReopen(false);
      this.props.loadBreakFixActivityLogDetailsAction(breakFixObj.itemId);
      this.props.getServiceName(breakFixObj.ciServiceName, breakFixObj.serviceBased);
      this.props.getIsReopenEnabled(breakFixObj.isReopenEnabled);
      this.props.getIsCloseEnabled(breakFixObj.isCloseEnabled);
      this.props.loadReviewOrderEngineDetails(breakFixObj.itemId, "", "Breakfix");
      this.props.getReviewDisplayNumber(breakFixObj.displayNumber);
      this.props.getOrderType(breakFixObj.type);
      this.props.getOrderDetails(breakFixObj.itemId);
      this.props.getIsAutoClosed(breakFixObj.isAutoClosed, breakFixObj.itemStatus, breakFixObj.feedbackSubmitted);
      this.props.showActiveRow(index);
      this.props.getQuestionsCategoryWise(breakFixObj.itemId);
    }
    else {
      this.props.getOfferingId(breakFixObj.offeringIdSx);
      this.props.getItemNumber(breakFixObj.itemNumber);
      this.props.getOrderNumber(breakFixObj.orderNumber);
      this.props.loadReviewOrderEngineDetails(breakFixObj.itemId, "", "Item");
      this.props.getOrderDetails(breakFixObj.itemId);
      this.props.getOrderType(breakFixObj.type);
      this.props.getServiceName(breakFixObj.ciServiceName, breakFixObj.serviceBased);
      this.props.getReviewOrderNumber(breakFixObj.orderNumber, breakFixObj.createdOn);
      this.props.getReviewDisplayNumber(breakFixObj.displayNumber);
      this.props.getIsReopenEnabled(breakFixObj.isReopenEnabled);
      this.props.getIsCloseEnabled(breakFixObj.isCloseEnabled);
      this.props.getIsAutoClosed(breakFixObj.isAutoClosed, breakFixObj.itemStatus, breakFixObj.feedbackSubmitted);
      this.props.showActiveRow(index);
      this.props.getQuestionsCategoryWise(breakFixObj.itemId);
    }
  }

  renderReviewOrderList(breakFixData) {
    if (breakFixData == {} || breakFixData.length == undefined) {
      return (
        <div>Loading...
          <Spinner spinnerName="three-bounce" />
        </div>
      );
    }
    let ciServiceName;
    return breakFixData.map((breakFixObj, index) => {

		let displayName="";
		let displayNameStr="";
		displayNameStr = breakFixObj.ciServiceName != null && breakFixObj.ciServiceName != "" ? breakFixObj.ciServiceName : "";
		displayName = displayNameStr != "" ? displayNameStr.replace(/(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/ig, '').trim() : "";
		displayName = displayName != "" ? displayName.replace(/\s+/g, ' ') : "";



      return (
        <tr className={this.props.isRightSideVisible && index == this.props.rowIndex ? "myActive" : ""} onClick={() => {
          this.onRowSelect(breakFixObj, index);
        }}>
          <td><Button bsClass='' className='rowFocus'></Button><div className='showdv414'><div className='valdv414'>{breakFixObj.displayNumber}<div className='keydv414'>{displayName.slice(0, 70)}{displayName.length > 70 ? "..." : ""}</div></div></div></td>
          <td className='hiddenBe414' title={breakFixObj.ciServiceName}><div className='showdv414'><div className='keydv414'>{this.props.tr['Service/CI Name']}</div><div className='valdv414'>{displayName.slice(0, 70)}{displayName.length > 70 ? "..." : ""}</div></div></td>
          <td><div className='showdv414'><div className='keydv414'>{this.props.tr['Requested For']}</div><div className="valdv414">{breakFixObj.requesterName}</div></div></td>
          {breakFixObj.feedbackSubmitted == "Y" ? <td><div className='showdv414'><div className='keydv414'>{this.props.tr['Status']}</div><div className='valdv414'>{this.props.tr['Completed']}</div></div></td> : <td><div className='showdv414'><div className='keydv414'>{this.props.tr['Status']}</div><div className='valdv414'>{this.props.tr['Pending']}</div></div></td>}
        </tr>
      );
    });
  }

  handleSearch() {
    let searchByVar = [];
    let searchByValue = [];
    let pattern = [];
    let searchInputVar = this.props.ciServiceNameSearchInput.replace(/[&]/g, '');
    searchInputVar = searchInputVar.replace(/[|]/g, '');
    searchInputVar = searchInputVar.replace(/[,]/g, '');

    let displayNumberSearchInputVar = this.props.displayNumberSearchInput.replace(/[&]/g, '');
    displayNumberSearchInputVar = displayNumberSearchInputVar.replace(/[|]/g, '');
    displayNumberSearchInputVar = displayNumberSearchInputVar.replace(/[,]/g, '');

    let requestedBySearchInputVar = this.props.requestedBySearchInput.replace(/[&]/g, '');
    requestedBySearchInputVar = requestedBySearchInputVar.replace(/[|]/g, '');
    requestedBySearchInputVar = requestedBySearchInputVar.replace(/[,]/g, '');

    this.props.isClearAllFilterFunc(true);

    if (displayNumberSearchInputVar != "") {
      searchByValue.push(displayNumberSearchInputVar.trim());
      searchByVar.push(this.state.displaySearchFilter);
      pattern.push("like");
    }
    if (searchInputVar != "") {
      searchByValue.push(searchInputVar.trim());
      searchByVar.push(this.state.ciServiceSearchFilter);
      pattern.push("like");
    }
    if (requestedBySearchInputVar != "") {
      searchByValue.push(requestedBySearchInputVar.trim());
      searchByVar.push(this.state.requestedBySearchFilter);
      pattern.push("like");
    }

    this.props.loadReviewOrderList("", 'Breakfix,Item', this.props.status, this.props.sortOnColumn, this.props.sortOrder, this.props.listFilter, searchByVar.join(","), searchByValue.join("|"), pattern.join(","), this.state.page, this.props.listSize, this.props.isSelf);
    this.props.showRightSide(false);
    this.props.setSearchKeyValue(searchByVar.join(","), searchByValue.join("|"), pattern.join(","));
  }

  handleChange(e, searchValue) {
    switch (searchValue) {
      case "displayNumber":
        this.props.setdisplayNumberInputValues(e.target.value);
        this.setState({ displaySearchFilter: searchValue });
        break;
      case "ciServiceName":
        this.props.setCiServiceNameInputValues(e.target.value);
        this.setState({ ciServiceSearchFilter: searchValue });
        break;
      case "requesterName":
        this.props.setRequestedByInputValues(e.target.value);
        this.setState({ requestedBySearchFilter: searchValue });
        break;
    }
  }

  handleCheckBoxChange(value, event) {
    let selectedArray=[];
    selectedArray.push(value);
    value=selectedArray;
    let status = "N";
    let searchOnStatusArr = [];
    this.props.searchKey.length == 0 && this.props.searchValue.length == 0 ?
      value.length == 0 ?
        this.props.isClearAllFilterFunc(false) :
        this.props.isClearAllFilterFunc(true) :
      this.props.isClearAllFilterFunc(true);

    this.props.setSelectedValue(value);
    value.map((checkBoxValue) => {
      return (
        searchOnStatusArr.push(checkBoxValue.value)
      );
    }
    );

    this.props.updateParameter(searchOnStatusArr.length == 0 ? status : searchOnStatusArr.join(","));
    this.props.showRightSide(false);
    this.props.loadReviewOrderList("", 'Breakfix,Item',
      searchOnStatusArr.length == 0 ? "N" : searchOnStatusArr.join(","), this.props.sortOnColumn, this.props.sortOrder, this.props.listFilter,
      this.props.searchKey, this.props.searchValue, this.props.pattern, this.state.page, this.props.listSize, this.props.isSelf);
  }

  onSort(sortBy, sortValue, sorttype, lastFilter) {
    let orderBy = "";
    if (lastFilter == sortValue) {
      this.setState({ sortBy: !sortBy });
      orderBy = sorttype;
    }
    else if (lastFilter != sortValue) {
      this.setState({ sortBy: "desc" });
      orderBy = "desc";
    }
    let modifiedSortValue = sortValue == "itemStatus" ? "feedbackSubmitted" : sortValue;
    let modifiedOrderBy = sortValue == "itemStatus" ? (orderBy == "desc" ? "asc" : orderBy == "asc" ? "desc" : "") : orderBy;
    this.props.loadReviewOrderList("", 'Breakfix,Item', this.props.status, modifiedSortValue, modifiedOrderBy, this.props.listFilter, this.props.searchKey, this.props.searchValue, this.props.pattern, this.state.page, this.props.listSize, this.props.isSelf);
    this.props.setSortOrder(modifiedOrderBy, modifiedSortValue);
    switch (sortValue) {
      case 'displayNumber':
        this.setState({ lastSelectedSearchFilter: 'displayNumber' });
        this.setState({
          faCustomDesc1: modifiedOrderBy == "asc" ? "customArrow dropdown" : modifiedOrderBy == "desc" ? "customArrow dropdown isActive" : "",
          faCustomAsc1: modifiedOrderBy == "asc" ? "customArrow dropup isActive" : modifiedOrderBy == "desc" ? "customArrow dropup" : "",
          faCustomDesc2: "customArrow dropdown",
          faCustomAsc2: "customArrow dropup",
          faCustomDesc3: "customArrow dropdown",
          faCustomAsc3: "customArrow dropup",
          faCustomDesc4: "customArrow dropdown",
          faCustomAsc4: "customArrow dropup",

        });
        break;
      case 'ciServiceName':
        this.setState({ lastSelectedSearchFilter: 'ciServiceName' });
        this.setState({
          faCustomDesc2: modifiedOrderBy == "asc" ? "customArrow dropdown" : modifiedOrderBy == "desc" ? "customArrow dropdown isActive" : "",
          faCustomAsc2: modifiedOrderBy == "asc" ? "customArrow dropup isActive" : modifiedOrderBy == "desc" ? "customArrow dropup" : "",
          faCustomDesc1: "customArrow dropdown",
          faCustomAsc1: "customArrow dropup",
          faCustomDesc3: "customArrow dropdown",
          faCustomAsc3: "customArrow dropup",
          faCustomDesc4: "customArrow dropdown",
          faCustomAsc4: "customArrow dropup",
        });
        break;
      case 'requesterName':
        this.setState({ lastSelectedSearchFilter: 'requesterName' });
        this.setState({
          faCustomDesc3: modifiedOrderBy == "asc" ? "customArrow dropdown" : modifiedOrderBy == "desc" ? "customArrow dropdown isActive" : "",
          faCustomAsc3: modifiedOrderBy == "asc" ? "customArrow dropup isActive" : modifiedOrderBy == "desc" ? "customArrow dropup" : "",
          faCustomDesc1: "customArrow dropdown",
          faCustomAsc1: "customArrow dropup",
          faCustomDesc2: "customArrow dropdown",
          faCustomAsc2: "customArrow dropup",
          faCustomDesc4: "customArrow dropdown",
          faCustomAsc4: "customArrow dropup",
        });
        break;
      case 'itemStatus':
        this.setState({ lastSelectedSearchFilter: 'itemStatus' });
        this.setState({
          faCustomDesc4: modifiedOrderBy == "asc" ? "customArrow dropdown" : modifiedOrderBy == "desc" ? "customArrow dropup isActive" : "",
          faCustomAsc4: modifiedOrderBy == "asc" ? "customArrow dropdown isActive" : modifiedOrderBy == "desc" ? "customArrow dropup" : "",
          faCustomDesc1: "customArrow dropdown",
          faCustomAsc1: "customArrow dropup",
          faCustomDesc2: "customArrow dropdown",
          faCustomAsc2: "customArrow dropup",
          faCustomDesc3: "customArrow dropdown",
          faCustomAsc3: "customArrow dropup",
        });
        break;
    }
  }
  
  render() {
    console.log("Black2---", this.props.isRightSideVisible);
    console.log("getData", this.props);
    let meta = this.props.myReviewOrdersListMeta;
    let sorttype = "";
    sorttype = this.state.sortBy ? "asc" : "desc";
    let caseResponsive = this.props.showLoader || this.props.myReviewOrdersList == undefined || this.props.myReviewOrdersList.length == 0 || this.props.myReviewOrdersList.length == undefined ? "" : this.props.isRightSideVisible == false ? "overFlowClp" : "table-responsive";
    let panelColSize = this.props.panelResize;

    if (this.props.isRightSideVisible == true) {
      this.multiSelectDropdownRef.childNodes[0].childNodes[0].addEventListener('click', () => {
        this.props.showRightSide(false);
      });
    }
    const multiSelectOptions = [{ label: this.props.tr['Pending'], value: "N" }, { label: this.props.tr['Completed'], value: "Y" }];

    if (this.props.checkedStatus != "") {
      return (
        <div>
          <font color="green"><strong>{this.props.checkedStatus}</strong></font>
        </div>
      );
    }
    return (
      <div>
        <Row className="xtFilterBar margin-b-5 hidden">
          {(this.state && this.state.isErrorFilterSearch) ?
            <Col md={12} sm={6} xs={6}><span style={{ 'color': 'red' }}>{this.props.tr['Search here...']}</span></Col>
            : null}

        </Row>
        <div>
          <div aria-label='Table' role="contentinfo" className="respondv">
            <div className={"tableRgtBor " + caseResponsive}><Table striped bordered condensed hover className="tableView nowrapWhright" style={{ "marginTop": "0px" }}>
              <thead>
                <tr>
                  <th>
                    <div className="sortParArr" onClick={() => this.onSort(this.state.sortBy, 'displayNumber', sorttype, this.state.lastSelectedSearchFilter)} >{this.props.tr['Request ID']}
                      <div className="sortArr">
                        {this.props.sortOrder == "asc" ?
                          <span className={this.state.faCustomAsc1} title={this.props.tr['Ascending']}>
                            <span className="caret"></span></span> :
                          <span className={this.state.faCustomDesc1} title={this.props.tr['Descending']}>
                            <span className="caret"></span>
                          </span>}
                      </div>
                    </div>
                    <div className="colSearDv">
                      <Form.Control
                        className="colSearInp"
                        type="text"
                        value={
                            this.props.displayNumberSearchInput != "" ?
                            this.props.displayNumberSearchInput : ""}
                        placeholder={this.props.tr["Search here"]}
                        onKeyPress={(e) => {
                          if (e.charCode == "13") {
                            if (this.props.displayNumberSearchInput != "" && this.props.displayNumberSearchInput.trim().length > 3)
                              this.handleSearch();
                          }
                        }}
                        onChange={(event) => { this.handleChange(event, 'displayNumber'); }}
                        onClick={() => this.props.multiSelectOpenFun("mySurvyStatus", false)} />
                      {this.props.displayNumberSearchInput.trim().length > 3 ?
                        <a title="search" className="faicn" href={void(0)} onClick={(event) => this.handleSearch()}>
                          <IoSearch/>
                        </a> :
                        ""
                      }
                    </div>
                  </th>

                  <th>
                    <div className="sortParArr" onClick={() => this.onSort(this.state.sortBy, 'ciServiceName', sorttype, this.state.lastSelectedSearchFilter)} >{this.props.tr['Service/CI Name']}
                      <div className="sortArr">
                        {this.props.sortOrder == "asc" ?
                          <span className={this.state.faCustomAsc2} title={this.props.tr['Ascending']}>
                            <span className="caret"></span></span> :
                          <span className={this.state.faCustomDesc2} title={this.props.tr['Descending']}>
                            <span className="caret"></span>
                          </span>}
                      </div>
                    </div>
                    <div className="colSearDv">
                      <Form.Control
                        className="colSearInp"
                        type="text"
                        value={
                          this.props.ciServiceNameSearchInput != "" ?
                          this.props.ciServiceNameSearchInput : ""}
                        placeholder={this.props.tr["Search here"]}
                        onKeyPress={(e) => {

                          if (e.charCode == "13") {
                            if (this.props.ciServiceNameSearchInput != "" && this.props.ciServiceNameSearchInput.trim().length > 3)
                              this.handleSearch();
                          }
                        }}
                        onChange={(event) => { this.handleChange(event, 'ciServiceName'); }} 
                        onClick={() => this.props.multiSelectOpenFun("mySurvyStatus", false)}/>
                      {this.props.ciServiceNameSearchInput.trim().length > 3 ?
                        <a title="search" className="faicn" href={void(0)} onClick={(event) => this.handleSearch()}>
                          <IoSearch/>
                        </a> :
                        ""
                      }
                    </div>
                  </th>

                  <th className="position-re hiddenBe414">
                    <div
                      className="sortParArr margin-r-20"
                      onClick={() => this.onSort(this.state.sortBy, 'requesterName', sorttype, this.state.lastSelectedSearchFilter)} >
                      {`${this.props.tr['Requested For']} ${this.props.isSelf ? this.props.tr["Self"] : this.props.tr["Others"]}`}
                      <div className="sortArr">
                        {this.props.sortOrder == "asc" ?
                          <span className={this.state.faCustomAsc3} title={this.props.tr['Ascending']}>
                            <span className="caret"></span></span> :
                          <span className={this.state.faCustomDesc3} title={this.props.tr['Descending']}>
                            <span className="caret"></span>
                          </span>}
                      </div>
                    </div>
                    <div className="colSearDv">
                      <Form.Control
                        className="colSearInp"
                        type="text"
                        value={
                          this.props.requestedBySearchInput != "" ?
                            this.props.requestedBySearchInput : ""}
                        placeholder={this.props.tr["Search here"]}
                        onKeyPress={(e) => {
                          if (e.charCode == "13") {
                            if (this.props.requestedBySearchInput != "" && this.props.requestedBySearchInput.trim().length > 3)
                              this.handleSearch();
                          }
                        }}
                        onChange={(event) => { this.handleChange(event, 'requesterName'); }} 
                        onClick={() => this.props.multiSelectOpenFun("mySurvyStatus", false)} />
                      {this.props.requestedBySearchInput.trim().length > 3 ?
                        <a title="search" className="faicn" href={void(0)} onClick={(event) => this.handleSearch()}>
                          <IoSearch/>
                        </a> :
                        ""
                      }
                    </div>
                  </th>

                  <th>
                    <div className="sortParArr" onClick={() => this.onSort(this.state.sortBy, 'itemStatus', sorttype, this.state.lastSelectedSearchFilter)}>{this.props.tr['Status']}
                      <div className="sortArr">
                        {this.props.sortOrder == "asc" ?
                          <span className={this.state.faCustomAsc4} title={this.props.tr['Descending']}>
                            <span className="caret"></span></span> :
                          <span className={this.state.faCustomDesc4} title={this.props.tr['Ascending']}>
                            <span className="caret"></span></span>}
                      </div>
                    </div>
                    <div id="survyStatusDv" className="colSearDv multiSelDv hideCheckbox" ref={e => this.multiSelectDropdownRef = e}
                    onClick={() => this.props.multiSelectOpenFun("mySurvyStatus", true)}>
                      <ReactMultiSelectCheckboxes placeholderButtonLabel={this.props.tr["Select"]} value={this.props.selectedValue} rightAligned={true} options={multiSelectOptions} onChange={this.handleCheckBoxChange} isMulti={false}/>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.showLoader ? <tr><td colSpan="5" style={{ "textAlign": "center" }}><ListLoader /></td></tr> :
                  this.props.myReviewOrdersList == undefined || this.props.myReviewOrdersList.length == 0 || this.props.myReviewOrdersList.length == undefined ?
                    <tr><td colSpan="5" style={{ "textAlign": "center" }}>{this.props.tr['Data not available']}</td></tr> :
                    this.renderReviewOrderList(this.props.myReviewOrdersList)
                }
              </tbody>
            </Table></div>
          </div>

          {/* {meta != undefined ?
            meta.recordCount != 0 ? */}
          <div className="nBotPagina">
            <div className="nShow">
              <div className="margin-r-10">{this.props.tr['Show']}:</div>
              <div aria-label='Show Count' role="contentinfo"><Form.Select aria-label={this.props.tr['Show']} value={meta ? meta.size : 10} onChange={this.onPageSizeChange}>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </Form.Select></div>
            </div>
            <nav aria-label='Pagination' className='display-inline-block'>
            <ListPagination
              prevPageText={this.props.tr['Prev']}
              nextPageText={this.props.tr['Next']}
              firstPageText={<i className="glyphicon glyphicon-menu-left" />}
              lastPageText={<i className="glyphicon glyphicon-menu-right" />}
              activePage={meta ? meta.currentPage + 1 : 1}
              itemsCountPerPage={this.props.listSize}
              totalItemsCount={meta ? meta.recordCount : 1}
              pageRangeDisplayed={panelColSize <= 42 ? 2 : 5}
              onChange={this.onPageChange}
            /></nav>
          </div>
          {/* : "" : ""} */}
        </div>

      </div>
    );
  }
}

export function mapStateToProps({ myReviewOrdersList, showLoader, reviewFilters }) {
  return {
    myReviewOrdersList: myReviewOrdersList == {} ? {} : myReviewOrdersList.data,
    showLoader: showLoader.loading,
    selectedStatus: reviewFilters.selectedStatus,
    selectedOrderType: reviewFilters.selectedOrderType
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    loadReviewOrderList, loadPendingReviewOrderList, loadReviewOrderDetails,
    loadReviewBreakFixOrderDetails, loadReviewOrderEngineDetails,
    addToSelectedReviewStatusList, getQuestionsCategoryWise,
    removeFromSelectedReviewStatusList, addToSelectedReviewOrderTypeList,
    removeFromSelectedReviewOrderTypeList, resetReviewFilterList
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MySurveyList);

 //export default XsmReviewsList;
