
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { connect } from 'react-redux';
import { Button, ButtonToolbar, FormGroup, ListGroup, ListGroupItem } from 'react-bootstrap';
import GLOBAL from '_Globals';
import { Link } from 'react-router-dom';
import { editCIResolutionCategory } from '../../../actions/categoryBoard/quickViewAction';
import { getCICategoryListData } from '../../../actions/categoryBoard/quickViewAction';
import swal from 'sweetalert';
import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";
import { MyContext } from '_MyContext';
import { PiPencilSimpleBold } from 'react-icons/pi';

  const ResolutionCategoryRightPart= (props) => {
		const dispatch = useDispatch();
    
	  const [category_name, setCategory_name] = useState("");
		const [sub_category_name, setSub_category_name] = useState("");
		const [resolution_category_name, setResolution_category_name] = useState("");
		const [resolution_category_id, setResolution_category_id] = useState("");
		const [resolution_sub_category_name, setResolution_sub_category_name] = useState("");
		const [status, setStatus] = useState("");
		const [ciClassName, setCiClassName] = useState("");
		const [class_id, setClass_id] = useState("");
		const [cat_company_id, setCat_company_id] = useState("");
		const [category_id, setCategory_id] = useState("");
		const [category_nameErrorColor, setCategory_nameErrorColor] = useState("");
		const [sub_category_nameErrorColor, setSub_category_nameErrorColor] = useState("");
		const [statusErrorColor, setStatusErrorColor] = useState("");
    const context = useContext(MyContext);
    const closeButton = useRef(null);
    const editButton = useRef(null);
    const saveObjRef = useRef(null);

    useEffect(() => {
      if(!props.isEditing) {
        context.keyDownHandler(closeButton.current,editButton.current,"","");
      }
      else {
        context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
      }
      
    }, [props.isEditing]);
  

  const validateField = (fieldType, event) => {
    console.log("EVENT=========>", event.target.value)
    switch (fieldType) {
      case 'resolution_category_name':
        if (event.target.value.trim() === "") {
         // this.setState({ category_nameErrorColor: 'error' });
          setCategory_nameErrorColor('error');
        } else {
          setCategory_nameErrorColor('')
          //this.setState({ category_nameErrorColor: '' });
        }
        break;
      case 'resolution_sub_category_name':
        if (event.target.value.trim() === "") {
          //this.setState({ sub_category_nameErrorColor: 'error' });
          setSub_category_nameErrorColor('error');
        } else {
          //this.setState({ sub_category_nameErrorColor: '' });
          setSub_category_nameErrorColor('');
        }
        break;
      case 'status':
        if (event.target.value === "") {
        //  this.setState({ statusErrorColor: 'error' });
          setStatusErrorColor('error');
        } else {
        //  this.setState({ statusErrorColor: '' });
          setStatusErrorColor('');
        }
        break;
      default:
    }
  }

  const handleEditClick = () => {
    const { categoryData } = props;
    const { category_name, sub_category_name, res_sub_category_name, res_category_name, cat_company_name, status, cat_company_id, res_category_id,category_id } = categoryData || {};


    setCategory_name(category_name || '');
    setSub_category_name(sub_category_name || '');
    setStatus(status || '');
    setClass_id(class_id || '');
    setCategory_id(category_id || '');
    setResolution_category_name(res_category_name);
    setResolution_sub_category_name(res_sub_category_name);
    setResolution_category_id(res_category_id);

  
    props.setIsEditing(true);
  }

  const handleSaveClick = (event) => {
    
    const { categoryData } = props;
    event.preventDefault();

    if (resolution_category_name.trim() === "" || resolution_sub_category_name.trim() === "" || status === "") {
    
      setCategory_nameErrorColor(resolution_category_name.trim() === "" ? 'error' : '');
      setSub_category_nameErrorColor(resolution_sub_category_name.trim() === "" ? 'error' : '');
      setStatusErrorColor(status === "" ? 'error' : '');

      swal({
        text: "Please fill all the mandatory fields.",
        button: 'OK',
      });
      return;
    }

    let payload = {
      res_category_name: resolution_category_name,
      res_sub_category_name: resolution_sub_category_name,
      status: status,
      searchedStatus: props.status
    }
    console.log("updatedCategoryData", payload)
    //props.editCIResolutionCategory(category_id, resolution_category_id, payload);
    dispatch(editCIResolutionCategory(category_id, resolution_category_id, payload));
    props.setIsEditing(false);
      props.resetRightSide();
      props.showRightSide(false);
  }
 const handleCloseClick = () => {
    props.setIsEditing(false);
    // this.setState({
    //   category_nameErrorColor: '',
    //   sub_category_nameErrorColor: '',
    //   statusErrorColor: ''
    // });
    setCategory_nameErrorColor('');
    setSub_category_nameErrorColor('');
    setStatusErrorColor('');
  }

    const { categoryData } = props;
   
    console.log("ciClassNameRight Part", props.ciClassName)
    console.log("Category data",categoryData)
    console.log("cat_company_id===>",categoryData.cat_company_id)
    return (
      <div className="margin-b-20">
        <div className="rPageHeadActBtn" style={{ marginTop: "-1px" }}>
          <ul>
            {(!props.isEditing && categoryData.cat_company_id != "0") && (
              <li>
                <Link 
                  to="javascript:void(0)"
                  title={props.tr['Edit']}
                  onClick={handleEditClick}
                  style={{ display: categoryData ? 'block' : 'none' }}
                  ref={editButton} >
                    <PiPencilSimpleBold />
                </Link>
              </li>
            )}
            {!props.isEditing && <li>
              <Link to="javascript:void(0)" title={props.tr["Minimize the details panel"]} bsClass="" bsStyle="" onClick={() => { props.showRightSide(false); }} ref={closeButton} >
                <IoClose />
              </Link>
            </li>}
          </ul>
        </div>
        <div className="rPageHeading">
          <div className="offNam margin-t-5 margin-b-5">CI Resolution Category Info</div>
        </div>

        <div className="rBoxGap">
          <ListGroup bsClass="" className="" bsPrefix=' '>
            {categoryData ? (
              <div>
                <ListGroupItem bsClass="" className="rwTable" style={{ borderTop: "none" }}>
                  <span className="rwKey">{props.tr['Company']} </span>
                  <span className="rwVal1">{categoryData.cat_company_name}</span>
                </ListGroupItem>
                <ListGroupItem bsClass="" className="rwTable" bsPrefix=' '>
                  <span className="rwKey">{props.tr['Class']} </span>
                  <span className="rwVal1">{props.ciClassName}</span>
                </ListGroupItem>

                <ListGroupItem bsClass="" className="rwTable" bsPrefix=' '>
                  <span className="rwKey">{'CI Category Name'} </span>
                  <span className="rwVal1">{categoryData.category_name}</span>
                </ListGroupItem>

                <ListGroupItem bsClass="" className="rwTable" bsPrefix=' '>
                  <span className="rwKey">{'CI Sub Category Name'} </span>
                  <span className="rwVal1">{categoryData.sub_category_name}</span>
                </ListGroupItem>

                <ListGroupItem bsClass="" className="rwTable" bsPrefix=' '>
                  <span className="rwKey">{props.tr['Resolution Category']} </span>
                  <span className="rwVal1">
                    {props.isEditing ? (
                      <FormGroup validationState={category_nameErrorColor}>
                        <input
                          className={`form-control ${category_nameErrorColor}`}
                          type="text"
                          value={resolution_category_name}
                         // onChange={(e) => .setState({ resolution_category_name: e.target.value })}
                          onChange={(e) => setResolution_category_name(e.target.value)}
                          onBlur={(event) => {validateField('resolution_category_name', event) }}
                          maxLength={50}
                        />
                      </FormGroup>
                    ) : (
                      categoryData.res_category_name
                    )}
                  </span>
                </ListGroupItem>

                <ListGroupItem bsClass="" className="rwTable" bsPrefix=' '>
                  <span className="rwKey">{'Resolution Sub Category'} </span>
                  <span className="rwVal1">
                    {props.isEditing ? (
                      <FormGroup validationState={sub_category_nameErrorColor}>
                        <input
                          className={`form-control ${sub_category_nameErrorColor}`}
                          type="text"
                          value={resolution_sub_category_name}
                         // onChange={(e) => this.setState({ resolution_sub_category_name: e.target.value })}
                          onChange={(e) => setResolution_sub_category_name(e.target.value)}
                          onBlur={(event) => { validateField('resolution_sub_category_name', event) }}
                          maxLength={50}
                        />
                      </FormGroup>
                    ) : (
                      categoryData.res_sub_category_name
                    )}
                  </span>
                </ListGroupItem>
                <ListGroupItem bsClass="" className="rwTable" bsPrefix=' '>
                  <span className="rwKey">{props.tr['Status']} </span>
                  <span className="rwVal">
                    {props.isEditing ? (
                      <FormGroup validationState={statusErrorColor}>
                      <select
                        className={`form-control ${statusErrorColor}`}
                        value={status}
                        //onChange={(e) => this.setState({ status: e.target.value })}
                        onChange={(e) => setStatus(e.target.value)}
                        
                      > 
                          <>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </>
                        
                        
                      </select>
                      </FormGroup>
                    ) : (
                      categoryData.status.charAt(0).toUpperCase() + categoryData.status.slice(1)
                    )}
                  </span>
                </ListGroupItem>
                {props.isEditing && (
                  <ListGroupItem style={{ borderBottom: "none" }} className="rwTable" bsPrefix=' '>
                    {/* <span className="rwKey"></span> */}
                    <span className="offNam margin-t-5 margin-b-5">
                      <ButtonToolbar className='black'>
                        <Link
                          size='sm'
                          to="javascript:void(0)"
                          className="rgSidrkBtn smallBtn"
                          onClick={handleSaveClick}
                          title={props.tr['Save']}
                          disabled={props.showLoader}
                          ref={saveObjRef} >
                            {props.showLoader ? (<ImSpinner6 className="icn-spinner"/>) : ( <span>{props.tr['Save']}</span>)}
                        </Link>
                        <Link
                          size='sm'
                          to="javascript:void(0)"
                          className="rgSiLigBtn smallBtn"
                          onClick={handleCloseClick}
                          title={props.tr['Close']}
                          ref={closeButton}
                        >
                          Close
                        </Link>
                      </ButtonToolbar>
                    </span>
                  </ListGroupItem>
                )}
              </div>
            ) : (
              <div className="rWelcomeText">Your Data Will Display Here!</div>
            )}
          </ListGroup>
        </div>
      </div>
    );
  
}

export default ResolutionCategoryRightPart;
