
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import Cookies from 'universal-cookie';
import moment from 'moment';
const cookies = new Cookies();
let timezone = cookies.get('gph');
if (timezone) timezone = timezone.replace('s:', '');
if (timezone) timezone = timezone.substring(0, timezone.lastIndexOf('.'));
timezone = timezone.split("~");
timezone = timezone[3];

const cookies1 = new Cookies();
let defaultDateRangeValue = cookies1.get('gph');
if (defaultDateRangeValue) defaultDateRangeValue = defaultDateRangeValue.replace('s:', '');
if (defaultDateRangeValue) defaultDateRangeValue = defaultDateRangeValue.substring(0, defaultDateRangeValue.lastIndexOf('.'));
defaultDateRangeValue = defaultDateRangeValue.split("~");
defaultDateRangeValue = defaultDateRangeValue[53];

let dateformat = cookies.get('gph');
if (dateformat) dateformat = dateformat.replace('s:', '');
if (dateformat) dateformat = dateformat.substring(0, dateformat.lastIndexOf('.'));
//console.log("homepage::::"+homepagelocation);
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();
//below URL used for testing purpose
//const breakFixUrl = 'https://xsm-f.mybluemix.net/api/SX_FAVOURITEOFFERINGs/';
import { GLOBAL } from "_Globals";

//let breakFixUrl =`https://xsm-f.mybluemix.net/api/breakfixitem?breakfixitemId=`;
//let breakFixUrl=`https://xsm-f.mybluemix.net/groupListSelfService?companyId=2932&group=ResolvedER`
import _ from 'lodash';
const api = axios.create({
  headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

export function loadBreakFixTaskLogDetailsSuccess(breakFixTaskLogDetails) {
  //alert('in success')
  return {
    type: 'LOAD_BREAKFIXTASKLOGDETAILS_SUCCESS',
    breakFixTaskLogDetails
  };
}

export function loadBreakFixTaskLogDetailsFailure(errorResponse) {
  return {
    type: 'LOAD_BREAKFIXTASKLOGDETAILS_FAILURE',
    errorResponse
  };
}
function setWibListFilterCriteria(obj = {}){
	return {type: 'LOAD_WIB_LIST_PAYLOAD', payload: obj['payload']}
}

export function loadBreakFixTaskLogDetails(breakFixId, type, sorttype, clientid, requestType = 'viewTask') {
  //alert('action called and parameter is '+breakFixId)
  let clause = "\"where\"";
  let workitem = "\"workItemId\"";
  let workitemid = "\"" + breakFixId + "\"";
  let workitemtype = "\"workItemType\"";
  let workitemtypeval = "\"" + type + "\"";
  let searchParams = {};

  // let config = {
  //   headers: { 'X-IBM-Client-Id': '5a78384c-5316-42ad-a23e-56bc2eb76d7d ' }
  // };
  return (dispatch) => {
    dispatch({ type: "LOADER_IN_PROGRESS_RELATEDTASK" });
    //alert("EditGroupIdAction123"+breakFixId);
    if (breakFixId == undefined || breakFixId == "") {
      //alert("H2 breakFixId not found");
    } else {
      let insideParams;
       insideParams = JSON.stringify({"workItemId":[breakFixId],"applicationName":"XSM","workItemType":[type],"moduleName":type,"clientId":clientid,"orderBY":"sequenceNumber "+sorttype+ " , taskId "+sorttype,"requestType" : requestType });
      let config = {
        headers: {
          'X-IBM-Client-Id': '5a78384c-5316-42ad-a23e-56bc2eb76d7d ',
          'query': insideParams
        }
      };
      api.get(GLOBAL.taskurl, config)
        .then((breakFixTaskLogDetails) => {
          if (!breakFixTaskLogDetails) {//alert('not breakFix')
            //dispatch(loadBreakFixFailure(breakFix.statusText))
            throw Error(breakFixTaskLogDetails.statusText);
          }
          return breakFixTaskLogDetails;
        })
        .then((breakFixTaskLogDetails) => {//alert('yes it is')
          let cmdbCount=0;
          for (let i = 0; i < breakFixTaskLogDetails.data.length; i++) {
            if(breakFixTaskLogDetails.data[i].taskTypeId == '50'){
                 cmdbCount++;
            }
          }
          dispatch({type: 'RELATED_CMDB_TASKS', count: cmdbCount});
          dispatch(loadBreakFixTaskLogDetailsSuccess(breakFixTaskLogDetails))
          dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY_RELATEDTASK" });
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY_RELATEDTASK" });
        });
      //console.log("not calling loadBreakFixEditDetails");
    }
  };
}

export function loadTaskListSuccess(taskDetailsList) {
  return {
    type: 'LOAD_TASKDETAILSLIST_SUCCESS',
    taskDetailsList
  };
}

export function loadTaskList(groupid, searchparams, limit = 10, skip = 0, assignmentType = '', assignedToIndividual = '') {
  //alert("called"+groupid)
  //alert("selectedStatus"+statusId)
  //console.log(statusId.length)
  console.log('searchparams==========================', searchparams);
  const newSearchParam = { groupid, searchparams, limit, skip, assignmentType, assignedToIndividual};
  let config = "";
  let groupidValue = groupid;
  // let statusIdValue = statusId

  let groupIdArr = new Array();
  // this will return an array with strings "1", "2", etc.
  if (groupidValue == "" || groupidValue == undefined) {
    groupIdArr = [];
  } else {
    groupIdArr = groupidValue.split(",");
  }
  let insideParams = {};
  if (_.isEmpty(searchparams)) {
    let days = (defaultDateRangeValue && !isNaN(defaultDateRangeValue)) ? (parseInt(defaultDateRangeValue,10) - 1) : 29;
    if(assignmentType == 'individual') {
      insideParams = JSON.stringify({"statusId":[1,2,7],"assignedToGroupId":[],"assignedToId": assignedToIndividual,"reviewerId": assignedToIndividual,"orderBY":"createdDate desc","limit": limit ,"offset":skip, "createdDateFrom": moment().subtract(days, 'days').format("YYYY-MM-DD"), "createdDateTo": moment().format("YYYY-MM-DD")});
    }else if(assignmentType === 'all'){
      insideParams = JSON.stringify({"statusId":[1,2,7],"orderBY":"createdDate desc","limit": limit ,"offset":skip, "createdDateFrom": moment().subtract(days, 'days').format("YYYY-MM-DD"), "createdDateTo": moment().format("YYYY-MM-DD"),"companyId":[]});
    } else {
      insideParams = JSON.stringify({"statusId":[1,2,7],"assignedToGroupId":[],"orderBY":"createdDate desc","limit": limit ,"offset":skip, "createdDateFrom": moment().subtract(days, 'days').format("YYYY-MM-DD"), "createdDateTo": moment().format("YYYY-MM-DD")});
    }

    // console.log('insideParams - ', {insideParams});
    
    config = { /* params: {
      'filter': {
        "where": insideParams,
        "include": ["status", "subStatus"],
        "order": "createdDate desc", "limit": limit, "skip": skip
      }
    },*/
    headers: {
      'X-IBM-Client-Id': '5a78384c-5316-42ad-a23e-56bc2eb76d7d ',
      'query': insideParams
    }
  };
    return (dispatch) => {
      let listPayloadArr = [ (groupid), (searchparams), (limit || 10), (skip || 0), (assignmentType || ''), (assignedToIndividual || '') ];
		  let listPayloadObj = { module: 'task', payload: listPayloadArr };
		  dispatch({ type: 'RESET_WIB_LIST_PAYLOAD' });
		  setTimeout(() => {dispatch(setWibListFilterCriteria(listPayloadObj));}, 500);
      dispatch({ type: 'LOADER_IN_PROGRESS' });

      dispatch({type:"WIB_SEARCH_PARAMS", searchParams:{module:"task",searchParams:newSearchParam}});
      //alert("EditGroupIdAction123"+breakFixId);
      if (groupid == undefined) {
        //alert("H2 breakFixId not found");
        console.log("loadTaskList==>groupid not found");
      } else {
        //alert("calling task list") isFetching: true,
        axios.get(GLOBAL.taskurl, config)
          .then((taskDetailsList) => {
            //alert("called")
            if (!taskDetailsList) {//alert('not breakFix')
              //dispatch(loadBreakFixFailure(breakFix.statusText))
              throw Error(taskDetailsList.statusText);
            }
            return taskDetailsList;
          })
          .then((taskDetailsList) => {//alert('yes it is')
            dispatch(loadTaskListSuccess(taskDetailsList))
            dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
          })
          .catch((err) => {
            //	console.log(taskDetailsList)
            dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
            console.log(err);
          });
        //console.log("not calling loadBreakFixEditDetails");
      }
    };
  }
  else {
     let taskStatusesStr = searchparams.taskStatuses;

    //  console.log('searchparams - ', {searchparams});

     let insideParams = {};
      console.log("searchparams.statusId===");
      console.log(searchparams.taskCode);
    if (searchparams.statusId != undefined) {
      insideParams.statusId =  searchparams.statusId.inq ;
    }

    let searchArray = [];
    if(searchparams.taskCode!=undefined){
      let taskCode = searchparams.taskCode;
       insideParams.taskCode =  taskCode;
    }
    if(searchparams.parentWorkItemId!=undefined){
       insideParams.parentWorkItemId = searchparams.parentWorkItemId; ;
    }
    if(searchparams.serviceName!=undefined){
       insideParams.serviceName =  searchparams.serviceName;
    }
    if(searchparams.ciName!=undefined){
      insideParams.ciName =  searchparams.ciName;
   }
   if(searchparams.companyName!=undefined){
    insideParams.companyNameString = searchparams.companyName;
   }
    if(searchparams.description!=undefined){
       insideParams.description =  searchparams.description;
    }
    if(searchparams.taskType && searchparams.taskType.length>0){
       insideParams.workItemType =  searchparams.taskType;
    }
    // if(searchparams.assignedTo !=undefined){
    //    insideParams.assignedTo =  searchparams.assignedTo;
    // }
    // if(searchparams.createdDateFrom !=undefined){
    //    insideParams.createdDateFrom =  searchparams.createdDateFrom;
    // }
    // if(searchparams.createdDateTo !=undefined){
    //    insideParams.createdDateTo =  searchparams.createdDateTo;
    // }
    if(searchparams.createdDateFrom){
        insideParams.createdDateFrom =  searchparams.createdDateFrom;
    }
    else{
      let days = (defaultDateRangeValue && !isNaN(defaultDateRangeValue)) ? (parseInt(defaultDateRangeValue,10) - 1) : 29;
      insideParams.createdDateFrom = moment().subtract(days, 'days').format("YYYY-MM-DD");
    }
    if(searchparams.createdDateTo){
        insideParams.createdDateTo =  searchparams.createdDateTo;
    }
    else{
      insideParams.createdDateTo = moment().format("YYYY-MM-DD");
    }
    
    let taskCode = { "taskCode": searchparams.taskCode };
    let parentWorkItemId = { "parentWorkItemId": searchparams.parentWorkItemId };
    let serviceName = { "or": searchparams.or };
    let description = { "description": searchparams.description };
    let statusId = { "statusId": searchparams.statusId };
    let taskType = { "or": searchparams.taskType };
    let and = { "and": searchparams.and };
    let group = { "or": searchparams.group };
    let assignedTo = { "assignedTo": searchparams.assignedTo };
   
    // let assignedToGroupId = { "inq": groupIdArr }; 
    if (searchparams.taskCode != undefined) {
      searchArray.push(taskCode);
    }
    console.log("assignedTo",group);
    // if(groupIdArr!=undefined)
    // {
    //   searchArray.push(assignedToGroupId);
    // }
    if (searchparams.parentWorkItemId != undefined) {
      searchArray.push(parentWorkItemId);
    }
    if (searchparams.or != undefined) {
      searchArray.push(serviceName);
    }
    if (searchparams.description != undefined) {
      searchArray.push(description);
    }
    if (searchparams.statusId != undefined) {
      searchArray.push(statusId);
    }
    if (searchparams.taskType != undefined) {
      searchArray.push(taskType);
    }
    if (searchparams.and != undefined) {
      searchArray.push(and);
    }
    if (searchparams.group != undefined) {
      searchArray.push(group);
    }

    insideParams.limit = limit ;
    insideParams.offset = skip ;
    insideParams.orderBY = "createdDate desc" ;
    // if (searchparams.assignedTo != undefined) {
    //   searchArray.push(assignedTo);
    // }
    if(assignmentType == 'individual') {
      if (searchparams.assignedTo != undefined) {
        searchArray.push(assignedTo);
        searchArray.push({ "assignedTo": assignedToIndividual });
        insideParams.assignedTo = searchparams.assignedTo ;
      }
      else {
        // searchArray.push({ "assignedTo": assignedToIndividual });
        insideParams.assignedToId = assignedToIndividual;
      }
    }
    else {
      if (searchparams.assignedTo != undefined) {
        searchArray.push(assignedTo);
        insideParams.assignedTo = searchparams.assignedTo ;
      }
    }
    if(assignedToIndividual!="" && assignedToIndividual!='undefined'){
      insideParams.assignedToId = assignedToIndividual;
    }
    if(assignmentType==='all'){
      insideParams.companyId=[];
    }
    if(assignmentType==='individual'){
      insideParams.reviewerId = assignedToIndividual;
    }
    console.log("insideParams=========")
    console.log(insideParams)
    let groupArr = [];
    if(searchparams.group != undefined){
      groupArr.push(searchparams.group);
      insideParams.assignedToGroupId = groupArr;
    config = {
      // params: {
      //   'filter': {
      //     "where": {
      //       "and":
      //         searchArray,
            
      //     },
      //     "include": ["status", "subStatus"],
      //     "order": "createdDate desc", "limit": limit, "skip": skip
      //   }
      // },
      headers: {
        'X-IBM-Client-Id': '5a78384c-5316-42ad-a23e-56bc2eb76d7d ',
        'query': JSON.stringify(insideParams)
      }
    };
  }
  else 
  {
    if(assignmentType!=='all'){
      insideParams.assignedToGroupId =[];
    }
    config = {
      // params: {
      //   'filter': {
      //     "where": {
      //       "and":
      //         searchArray,
      //         "assignedToGroupId": { "inq": [] }
            
      //     },
      //     "include": ["status", "subStatus"],
      //     "order": "createdDate desc", "limit": limit, "skip": skip
      //   }
      // },
      headers: {
        'X-IBM-Client-Id': '5a78384c-5316-42ad-a23e-56bc2eb76d7d ',
        'query': JSON.stringify(insideParams)
      }
    };
  }
    return (dispatch) => {
      let listPayloadArr = [ (groupid), (searchparams), (limit || 10), (skip || 0), (assignmentType || ''), (assignedToIndividual || '') ];
		  let listPayloadObj = { module: 'task', payload: listPayloadArr };
		  dispatch({ type: 'RESET_WIB_LIST_PAYLOAD' });
		  setTimeout(() => {dispatch(setWibListFilterCriteria(listPayloadObj));}, 500);
      //alert("EditGroupIdAction123"+breakFixId);
      dispatch({ type: 'LOADER_IN_PROGRESS' });
      dispatch({type:"WIB_SEARCH_PARAMS", searchParams:{module:"task",searchParams:newSearchParam}});
      // if (groupid == undefined) {

      // } 
      // else {
      //alert("called1");
      axios.get(GLOBAL.taskurl, config)
        .then((taskDetailsList) => {
          //alert("called2");
          //alert("======"+taskDetailsList.status);
          /* console.log("taskDetailsList")
                      console.log(taskDetailsList) */
          if (!taskDetailsList) {//alert('not breakFix')
            //dispatch(loadBreakFixFailure(breakFix.statusText))
            throw Error(taskDetailsList.statusText);
          }
          return taskDetailsList;
        })
        .then((taskDetailsList) => {//alert('yes it is')
          dispatch(loadTaskListSuccess(taskDetailsList))
          dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: 'TASK_LISTDATA_LOADING_ERROR',
            //errorMessage: 'error loading list data, check network connectivity or refresh the page.'
          });
          dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
        });
      //console.log("not calling loadBreakFixEditDetails");
      // }
    };
  }
}

export function loadTaskListAsc(groupid, searchparams, limit = 10, skip = 0) {
  //alert("called"+groupid)
  //alert("selectedStatus"+statusId)
  // console.log("limit...........",limit)
  //console.log(groupid+","+statusId)
  console.log('searchparams==========================', searchparams);
  var config = "";
  let groupidValue = groupid;
  // let statusIdValue = statusId
  let groupIdArr = new Array();
  if (groupidValue == "") {
    groupIdArr = [];
  } else {
    groupIdArr = groupidValue.split(",");
    // this will return an array with strings "1", "2", etc.
  }

  if (searchparams.taskStatuses == '' && searchparams.taskTypes == '' && searchparams.displayNumber == '' && searchparams.serviceName == '') {
    config = {
      params: {
        'filter': { "where": { "assignedToGroupId": { "inq": groupIdArr } }, "include": ["status", "subStatus"] },
        "order": "createdDate asc", "limit": limit, "skip": skip
      },
    };
    return (dispatch) => {
      //alert("EditGroupIdAction123"+breakFixId);
      if (groupid == undefined) {
        //alert("H2 breakFixId not found");
        console.log("loadTaskList==>groupid not found");
      } else {

        axios.get(GLOBAL.taskurl, config)
          .then((taskDetailsList) => {
            //alert("called1 asc")
            if (!taskDetailsList) {//alert('not breakFix')
              //dispatch(loadBreakFixFailure(breakFix.statusText))
              throw Error(taskDetailsList.statusText);
            }
            return taskDetailsList;
          })
          .then((taskDetailsList) => {//alert('yes it is')
            dispatch(loadTaskListSuccess(taskDetailsList))
          })
          .catch((err) => {
            //	console.log(taskDetailsList)
            console.log(err);
          });
        //console.log("not calling loadBreakFixEditDetails");
      }
    };
  }
  else {
    let taskStatusesStr = searchparams.taskStatuses;
    let taskTypesStr = searchparams.taskTypes;
    let displayNumberStr = searchparams.displayNumber ? searchparams.displayNumber : '';
    let serviceNameStr = searchparams.serviceName ? searchparams.serviceName : '';
    let taskStatusesArray = new Array();
    let taskTypesArray = new Array();

    if (taskStatusesStr != '')
      taskStatusesArray = taskStatusesStr.split(",");

    if (taskTypesStr != '')
      taskTypesArray = taskTypesStr.split(",");


    let insideParams = {};

    if (taskStatusesStr != '') {
      insideParams.statusId = { "inq": taskStatusesArray };
    }
    if (taskTypesStr != '') {
      insideParams.workItemType = { "inq": taskTypesArray };
    }
    if (displayNumberStr != '') {
      displayNumberStr = '/' + displayNumberStr + '/';
      insideParams.displayNumber = { "regexp": displayNumberStr };
    }
    if (serviceNameStr != '') {
      serviceNameStr = serviceNameStr + '/i';
      insideParams.serviceName = { "regexp": serviceNameStr };
    }

    insideParams.assignedToGroupId = { "inq": groupIdArr };

    config = {
      params: {
        'filter': {
          "where": insideParams,
          "include": ["status", "subStatus"],
          "order": "createdDate asc", "limit": limit, "skip": skip
        }
      },
    };

    return (dispatch) => {
      axios.get(GLOBAL.taskurl, config)
        .then((taskDetailsList) => {
          if (!taskDetailsList) {//alert('not breakFix')
            throw Error(taskDetailsList.statusText);
          }
          return taskDetailsList;
        })
        .then((taskDetailsList) => {//alert('yes it is')
          dispatch(loadTaskListSuccess(taskDetailsList))
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: 'TASK_LISTDATA_LOADING_ERROR',
            //errorMessage: 'error loading list data, check network connectivity or refresh the page.'
          });
        });
      //console.log("not calling loadBreakFixEditDetails");
      // }
    };
  }
}


export const loadRequestorAndServiceData = (callFrom) => {
  let consumerData = [];
  let consumerDataDetails = [];
  let impactedServiceData = [];
  let requestorObj = [];
  let serviceObj = [];
  let serviceData = [];


  return (dispatch) => {
    let str1 = {};
    str1.userId = "";
    str1 = JSON.stringify(str1);
    //console.log("stronbhealf");
    //console.log(str1)
    //load requestor data for task
    api.get(GLOBAL.breakFixConsumerUrlNew, { headers: { 'query': str1 } }).then((response) => {
      if (!response || response.status != '200') {
        dispatch({
          type: 'TASK_DATA_LOADING_ERROR',
          errorMessage: 'error loading requestor data, check network connectivity or refresh the page.'
        });
      }
      consumerData = response.data;
      for (let i = 0; i < consumerData.length; i++) {
        requestorObj = { value: '', label: '' };
        requestorObj.value = consumerData[i].companyId;
        requestorObj.label = consumerData[i].fullname + "|" + consumerData[i].email + "|" + consumerData[i].userId
          + "|" + consumerData[i].companyId + "|" + consumerData[i].locationId + "|" + consumerData[i].employeeNumber + "|"
          + consumerData[i].businessPhone + "|" + consumerData[i].cubicle + "|" + consumerData[i].locationName;
        consumerDataDetails.push(requestorObj);
      }
      consumerDataDetails = Array.from(new Set(consumerDataDetails));
      dispatch({
        type: 'REQUESTOR_DATA_LOADED_FOR_TASK',
        requestorData: consumerDataDetails
      });
    });
    //load services data for task
    if (callFrom != 'undefined') {
      let str = {};
      str.userId = "";
      str.companyId = "";
      str.associatedCompanyId = "";
      str = JSON.stringify(str);
      api.get(GLOBAL.breakFixServiceWithoutDomainUrl, { headers: { 'query': str } }).then((response) => {
        if (!response || response.status != '200') {
          dispatch({
            type: 'TASK_DATA_LOADING_ERROR',
            errorMessage: 'error loading service data, check network connectivity or refresh the page.'
          });
        }
        impactedServiceData = response.data;
        for (let i = 0; i < impactedServiceData.length; i++) {
          serviceObj = { value: '', label: '', companyname: '', companyid: '' };
          serviceObj.value = impactedServiceData[i].offeringId;
          serviceObj.label = impactedServiceData[i].offeringName + "|" + impactedServiceData[i].categoryName + "|" + impactedServiceData[i].subCategoryName;
          serviceObj.companyname = impactedServiceData[i].company;
          serviceData.push(serviceObj);
        }
        serviceData = Array.from(new Set(serviceData));
        dispatch({
          type: 'SERVICE_DATA_LOADED_FOR_TASK',
          serviceData: serviceData
        });
      });
    }

  };
};


export const getTaskNextEditableStatus = (currentStatusId) => {
  return (dispatch) => {
    dispatch({ type: "TASK_ITEM_NEXT_STATUS_LOADER_IN_PROGRESS" })
    const response = axios.get('/api/taskStatuses', {
      headers: {
        'X-IBM-Client-Id': '5a78384c-5316-42ad-a23e-56bc2eb76d7d ',
        'query':JSON.stringify({"id":currentStatusId})
      }
    });
    response.then(res => {
      if (!res || res.status != 200) {
        /*SKIP*/
      } else {
        let taskStatusDropDown = [];
        let firstItem = {
          value: res.data[0].id,
          name: res.data[0].status
        }
        taskStatusDropDown.push(firstItem);
        res.data[0].statusDetails.forEach(item => {
          let obj = {
            value: '',
            name: ''
          };
          obj.value = item.status.id;
          obj.name = item.status.status;

          taskStatusDropDown.push(obj);
        });
        console.log('taskStatusDropDown', taskStatusDropDown);
        dispatch({
          type: 'TASK_ITEM_NEXT_STATUS',
          payload: taskStatusDropDown
        });
        dispatch({ type: "TASK_ITEM_NEXT_STATUS_LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
      }
    });
  }
}
export const getTaskStatuses = (moduleName, statusName, d2cModuleName,requestType="") => {
  return dispatch => {
    dispatch({ type: "TASK_ITEM_NEXT_STATUS_LOADER_IN_PROGRESS" });
    const query = JSON.stringify({ module: moduleName, currentStatus: statusName, workItemType: d2cModuleName, requestType:requestType });
    axios.get('/api/retriveTaskStatuses', { headers: { 'query': query } })
      .then(res => {
        if (res.status === 200) {
          const statusList = res.data.map(item => ({ value: item.targetStatus, name: item.enableButton }));
          dispatch({ type: 'TASK_ITEM_NEXT_STATUS', payload: statusList });
          dispatch({ type: "TASK_ITEM_NEXT_STATUS_LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
        }
      })
      .catch(error => {
        console.error("Error retrieving task statuses:", error);
      });
  };
};

export const loadTaskItemToEdit = (taskid,isGuestRoleEnabled,requestType="") => {
  return (dispatch) => {
    dispatch({
      type: 'TASK_EDIT_DATA_LOADED',
      isFetching: true,
      taskDetails: {}
    });
    return axios.get(GLOBAL.taskurl + taskid, {
      headers: { "X-IBM-Client-Id": "5a78384c-5316-42ad-a23e-56bc2eb76d7d" }
    }).then((response) => {
      if (!response || response.status != '200') {
        const errorMessage = 'error loading task details, check network connectivity or refresh the page.';
        dispatch({
          type: 'TASK_DATA_LOADING_ERROR',
          errorMessage: errorMessage
        });
        return Promise.reject(errorMessage);
      } else {
        let task = response.data;
        const serviceModulesName = task.workItemType === "Breakfix" ? 'Incident' : 'Fullfilment';
        const thorowModulesName = task.workItemType === "ChangeManagement" ? 'change' : serviceModulesName;
        const d2cModuleName = task.workItemType === 'Investigation' ? 'Problem' : thorowModulesName;
        dispatch(getTaskStatuses('task', task.statusName, d2cModuleName,requestType));
        let taskDetails = {
          "registrationId": task.registrationId,
          "clientId": task.clientId,
          "assignedTo": task.assignedTo,
          "assignedToGroup": task.assignedToGroup,
          "assignedToId": task.assignedToId,
          "assignedToGroupId": task.assignedToGroupId,
          "createdDate": task.createdDate,
          "crearedBy": task.crearedBy,
          "description": task.description,
          "sequenceNumber": task.sequenceNumber,
          "sequenceType": task.sequenceType,

          "expectedStartDate": task.expectedStartDate,
          "expectedDueDate": task.expectedDueDate,
          "actualStartDate": task.actualStartDate,
          "actualEndDate": task.actualEndDate,


          // "expectedStartDate": task.expectedStartDate ? moment(task.expectedStartDate.substring(0, 10) + " " + task.expectedStartDate.substring(11, 19)).format(userDetails.date_format.toUpperCase()) : '',
          // "expectedDueDate": task.expectedDueDate ? moment(task.expectedDueDate.substring(0, 10) + " " + task.expectedDueDate.substring(11, 19)).format(userDetails.date_format.toUpperCase()) : '',
          // "actualStartDate": task.acutalStartDate ? moment(task.acutalStartDate.substring(0, 10) + " " + task.acutalStartDate.substring(11, 19)).format(userDetails.date_format.toUpperCase()) : '',
          // "actualEndDate": task.acutalEndDate ? moment(task.acutalEndDate.substring(0, 10) + " " + task.acutalEndDate.substring(11, 19)).format(userDetails.date_format.toUpperCase()) : '',
          "statusId": task.statusId,
          "serviceName": task.serviceName,
          "taskCode": task.taskCode,
          "workItemId": task.workItemId,
          "workItemType": task.workItemType,
          "requesterId": task.requesterId,
          "requesterName": task.requesterName,
          "additionalParams": task.additionalParams,
          "taskidforactivity": task.taskId,
          "serviceId": task.serviceId,
          "statusName": task.statusName,
          "parentWorkItemId": task.parentWorkItemId,
          "hopCount": task.hopCount == null ? '' : task.hopCount,
          "closeNotes": task.closeNotes,
          "companyName": task.companyName,
          "companyId": task.companyId,
          "ciId": task.ciId,
          "ciName": task.ciName,
          "serviceBased": task.serviceBased,
          "isExternalFulfilled": task.isExternalFulfilled,
          "isExternal": task.isExternalFulfilled,
          "taskType": task.taskType,
          "taskTypeId": task.taskTypeId,
          "clientTaskData": task.clientTaskData,
          "additionalParams": task.additionalParams,
          "priorityId": task.priorityId,
          "priorityValue": task.priorityValue,
          'reviewerId': task.reviewerId==null?0:task.reviewerId,
          'reviewerName': task.reviewer==null?'':task.reviewer
        };
        dispatch({
          type: 'TASK_EDIT_DATA_LOADED',
          isFetching: false,
          taskDetails: taskDetails
        });
        return Promise.resolve(taskDetails);
      }
    });
  };
};


export const checkRelatedTaskForItem = (workItemId, workItemType) => {

  let config;
  let insideParams = JSON.stringify({"statusId":[0,1,2,6,7,8,9] ,"workItemId": [workItemId],"workItemType":[workItemType],"requestType":"viewTask"});
  config = {
    // params: {
    //   'filter': { "where": { "and": [{ "statusId": { "inq": ["0", "1", "2", "6", "7"] }, "workItemId": workItemId, "workItemType": workItemType }] } }
    // },
    headers: {
      'X-IBM-Client-Id': '5a78384c-5316-42ad-a23e-56bc2eb76d7d ',
      'query': insideParams
      //	'DateHappenFormat': userDetails.date_format.toUpperCase(),
      //	'CurrencyHappenFormat': userDetails.currency,
      //	'TimeHappenFormat': userDetails.time_zone
    }
  };
  return (dispatch => {
    axios.get(GLOBAL.taskurl, config)
      .then(res => {
        console.log('resonse in action', res);
        if (!res) {
          console.log('error occured in checkRelatedTaskForItem');
        }
        let count = 0;
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].statusName === 'In Progress' || res.data[i].statusName === 'Assigned' || res.data[i].statusName === "On Hold" || res.data[i].statusName === "Completed" || res.data[i].statusName === "In Review" || res.data[i].statusName === "Reviewed") {
            count = 1;
            break;
          }
        }
        dispatch({ type: 'RELATED_TASK_COUNT_CHECK_FOR_WORKITEM', count: count });
      });
  });
};
export const resetRelatedTaskCount = ()=>{
  return (dispatch) => {dispatch({type: 'RELATED_TASK_COUNT_CHECK_FOR_WORKITEM', count: 0});}
};

export const resetrelatedCMDBtasks = ()=>{
  return (dispatch) => {
    dispatch({type: 'RELATED_CMDB_TASKS', count: -1});
  }
};
export const loadTaskGroup = (companyId) => {
  const response = axios.get(GLOBAL.getTaskGroup + companyId);
  return dispatch => {
    response.then(data => {
      if (!data || data.status != 200) {
        console.log('error occured loading task group data.');
      } else {
        dispatch({ type: 'TASK_GROUP_DATA_LOADED', taskGroupData: data.data });
      }
    });
  };
};

export const loadTaskGroup2 = (associatedCompanyId) => {
  let url = GLOBAL.ciSupportAllGroupUrl + "/id/" + associatedCompanyId;
  return (dispatch) => {
    axios.get(url)
      .then((response) => {
        // console.log('group api response');
        // console.log(response.data);
        let changedArray = [];
        if (response.data == "") {
          dispatch({
            type: 'TASK_GROUP_DATA_LOADED2',
            groupList: changedArray
          });
        }
        else {
          response.data.forEach((element) => {
            let obj = {};
            obj.groupId = element.groupId;
            obj.groupName = element.groupName;
            obj.isExternal = element.isExternal;
            obj.companyId = element.companyId;
            obj.companyName = element.companyName;
            changedArray.push(obj);
          });
          dispatch({
            type: 'TASK_GROUP_DATA_LOADED2',
            groupList: changedArray
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getTasktype = (type,module_name) => {
  let url =  "/api/getmenulist" ;
  let config = {
    headers: { 
      'query' : JSON.stringify({module:type, field1_name: module_name})
    }
  }
  return (dispatch) => {
    axios.get(url,config)
      .then((response) => {
          dispatch({
            type: 'TASK_TYPE_LIST_FETCHED_SUCCESSFULLY',
            taskType: response.data
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getNotificationByWorkItemId=(requestNumber,orderNumber)=>{
 

  let query={}
  query.emailId=""
  query.requestNumber=requestNumber
  query.moduleNames=[""]
  query.orderNumber=orderNumber
 

  const response=axios.get('/api/notifByIdAndEmail',{
    headers: { query: JSON.stringify(query) },
  })

  return dispatch=>{
    dispatch({ type: "LOADER_NE_IN_PROGRESS" });
      response.then(data=>{

        console.log("API DATA", data)

        dispatch({ type: 'NOTIFCATION_FETCHED_SUCCESS', payload: data.data.data });
        dispatch({ type: "LOADER_NE_DETAILS_FETCHED_SUCCESSFULLY" });
      })
  }
}

export const loadAsignedTo = (groupId) => {
  if (groupId == '' || groupId == null || groupId == undefined) {
    return dispatch => {
      dispatch({ type: 'TASK_ASSIGNED_TO_DATA_LOADED', taskAssignedToData: [] });
    };
  } else {
    const response = axios.get(GLOBAL.getTaskIndividual + groupId);
    return dispatch => {
      response.then(data => {
        if (!data || data.status != 200) {
          console.log('erro occured loading task Assigned to dropdown data.');
        } else {
          dispatch({ type: 'TASK_ASSIGNED_TO_DATA_LOADED', taskAssignedToData: data.data });
        }
      });
    };
  }
}

export const loadRegionList = () => {
  // return dispatch => {
  //   dispatch({
  //     type: "TESTING",
  //     taskAssignedToData: data.data
  //   });
  // };
  const response = axios.get(GLOBAL.getRegionList);
  return dispatch => {
    response.then(data => {
      dispatch({ type: "ONHOLD", regionListData: data.data });
    });
  };
};

export const getEditTaskStatus = (taskId) => {
  const response = axios.get(GLOBAL.getEditTaskStatus + taskId);
  return dispatch => {
    response.then(data => {
      dispatch({
        type: "EDIT_TASK_LIST_STATUS",
        payload: data.data
      });
    });
  };
};

export const flushReducer = () => {
  return (dispatch) => {
    dispatch({
      type: "FLUSH_REDUCER",
      payload: [],
    });
  };
};
export const flushEditTaskDetails = () => {
  return (dispatch) => {
    dispatch({
      type: "TASK_EDIT_DATA_RESET"
    });
  }
}
export const flushGroupList = () =>{
  return (dispatch) => {
    let breakFixSGAEEditName={data: []};
    dispatch({type: 'LOAD_BREAKFIXSGAEEDIT_SUCCESS',breakFixSGAEEditName});
    dispatch({type: 'GROUP_LIST',groupList: []});
  }
}
export const flushAssigntoList = () => {
  return (dispatch) => {
    dispatch({type: 'ASSIGNED_TO_DATA_LOADED_FOR_TASK',assignedToData: []});
  }
}

export const fetchTmsWatcherDetails = (module, itemId) => {
  return (dispatch) => {
    dispatch({ type: "WATCHER_LOADER_IN_PROGRESS" });
    let config = {
      headers: {
        query: JSON.stringify({ module: module, taskId: itemId }),
      },
    };
    axios
      .get(`/api/getWatchers`, config)
      .then((watcherData) => {
        if (!watcherData) {
          throw Error(watcherData.statusText);
        }
        return watcherData;
      })
      .then((watcherData) => {
        let data = [];
        if(Array.isArray(watcherData.data)){
          data = watcherData.data.map((res) => {
            return {
              ...res,
              watcherEmailid: res.watcherEmailId || res.watcherEmailid,
            };
          });
        }
        dispatch({
          type: "WATCHER_DETAILS_FETCHED",
          payload: data,
        });
        dispatch({ type: "WATCHER_DETAILS_FETCHED_SUCCESSFULLY" });
      });
  };
};

export const getTaskCountForPieChart = (module, itemId, companyId, requestType = 'viewTask') => {
  return (dispatch) => {
    let queryObj = {
      workItemId: [`${itemId}`],
      applicationName: "XSM",
      moduleName: module,
      clientId: companyId.toString(),
      orderBY: "sequenceNumber asc , taskId asc",
      requestType: requestType,
    };
    let config = {
      headers: {
        query: JSON.stringify(queryObj),
      },
    };
    axios
      .get(`/api/task/getTaskCount`, config)
      .then((resp) => {
        console.log("Response Task Count", resp.data);
        dispatch({
          type: "TASK_COUNT_FETCHED_SUCCESSFULLY",
          payload: resp.data,
        });
      })
      .catch((err) => {
        console.log(err);
      })
  };
};