import React, { Component } from "react";
import { Row, Col, Button, Table, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import {
  getTaskPlans,
  setEditServiceDetails,
  setFullfilmentEditServiceDetails,
  setForm,
  selectedTaskTemplate,
  getComponentName,
  setLeftEditId,
  getFulfilmentPlans,
  resetFulfillmentPlan,
} from "../../../../../actions/spcmActions";
import TaskPlanEdit from "./TaskPlanEdit";
import ListLoader from "../../../../common/loaders/ListLoader";
import axios from "axios";
import Swal from "sweetalert2";
import { HiPlus } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import Cookies from "universal-cookie";
const cookies = new Cookies();
let userCompanyID = cookies.get("gph");
if (userCompanyID) userCompanyID = userCompanyID.replace("s:", "");
if (userCompanyID)
  userCompanyID = userCompanyID.substring(0, userCompanyID.lastIndexOf("."));
userCompanyID = userCompanyID.split("~");
const userCompanyId = userCompanyID[48];

class TaskPlanList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteError: "",
      listFormToggle: "list",
      templateSelected: "ChangeManagement_Template",
    };
    this.getFullfilmentHandler = this.getFullfilmentHandler.bind(this);
  }

  componentDidMount() {
    console.log("Component Mounted");
    this.props.setForm("serviceFulfilmentPlanForm");
    this.props.toggleAddButtonStatus(false);
    this.props.resetFulfillmentPlan();

    // No need to check for conditions; default is ChangeManagement_Template
    const filterVal = "ChangeManagement_Template";
    this.setState({ templateSelected: filterVal });
    this.props.toggleAddButtonStatus(false, filterVal);
    this.props.selectedTaskTemplate(filterVal);

    if (this.props.classEditList && this.props.classEditList.CLASS_ID) {
      this.getFullfilmentHandler(filterVal);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.classEditList.CLASS_ID &&
      prevProps.classEditList.CLASS_ID !== this.props.classEditList.CLASS_ID
    ) {
      console.log(
        "CLASS_ID is now available:",
        this.props.classEditList.CLASS_ID
      );
      const filterVal = this.state.templateSelected;
      this.getFullfilmentHandler(filterVal);
    }
  }


  getFullfilmentHandler(fulfillmentPlanFor) {
    const classId = this.props.classEditList.CLASS_ID;
    if (classId) {
      const selectedPlan = fulfillmentPlanFor || this.state.templateSelected;
      this.props.getTaskPlans(classId, selectedPlan, "asc");
      this.setState({
        templateSelected: selectedPlan,
        listingTable: "onChange",
      });
    } else {
      console.warn("CLASS_ID is still undefined in getFullfilmentHandler");
    }
  }

  renderDropDown(fPlanOptions) {
    fPlanOptions = fPlanOptions.filter(
      (fPlanOptions) => fPlanOptions.value !== "Fullfilment_Template"
    );

    return fPlanOptions.map((item, i) => (
      <option key={i} value={item.value}>
        {item.label}
      </option>
    ));
  }

  handleDismiss = () => {
    this.setState({ deleteError: "" });
  };

  onDeleteClick = (id) => {
    let payload = {};
    payload.userCompanyId = parseInt(userCompanyId, 10);

    Swal.fire({
      title: "Are you sure you wish to delete this item?",
      width: 550,
      padding: "15px",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        const classId = this.props.classEditList.CLASS_ID;
        axios
          .post("/service/api/deleteFulfilmentPlan/" + id, payload)
          .then((resp) => {
            console.log(resp.data, "resp");
            this.props.getTaskPlans(classId, this.state.templateSelected);
          });
      }
    });
  };

  listFormToggleFunc = (val) => {
    this.setState({ listFormToggle: val });
  };

  render() {
    const fulfillmentOptions = [
      { value: "ChangeManagement_Template", label: "Change Requests" },
      { value: "CriticalIncident_Template", label: "Critical Incident" },
    ];

    const { tr, spcmReducer, classEditList } = this.props;
    const ciTaskPlans = spcmReducer.ciTaskPlans || [];
    const companyId = classEditList ? classEditList.COMPANY_ID : null;
    const classId = classEditList ? classEditList.CLASS_ID : null;
    const className = classEditList ? classEditList.CLASS_NAME : null;
    return (
      <div className="">
        <Row>
          <Col xs={12}>
            <div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
              <ul>
                <li>
                  {this.state.listFormToggle === "form" ? (
                    ""
                  ) : (
                    <Button
                      bsPrefix=" "
                      onClick={() => {
                        this.listFormToggleFunc("form");
                      }}
                      title={tr["Add"]}
                      bsClass=""
                      bsStyle=""
                    >
                      <HiPlus />
                    </Button>
                  )}
                </li>
                <li className="d-md-none">
                  <button
                    type="button"
                    title="Minimize the right panel"
                    bsClass=""
                    bsStyle=""
                    className="closerightPanelBtn"
                    onClick={() => {
                      this.props.rightEditPanel(false);
                    }}
                  >
                    <IoClose />
                  </button>
                </li>
              </ul>
            </div>
            <div className="rPageHeading">
              <div className="offNam margin-t-5 margin-b-5">
                {tr["Task Plan"]}
              </div>
            </div>
          </Col>
        </Row>

        <div className="rBoxGap">
          {this.state.listFormToggle === "form" ? (
            <TaskPlanEdit
              tr={tr}
              CI_ID={classId}
              CI_NAME={className}
              COMPANY_ID={companyId}
              templateSelected={this.state.templateSelected}
              listFormToggleFunc={this.listFormToggleFunc}
              STATUS={this.props.STATUS}
              SUB_STATUS={this.props.SUB_STATUS}
            />
          ) : (
            <div>
              {this.state.deleteError === "" ? null : (
                <Alert
                  style={{
                    marginBottom: "15px",
                    fontSize: "13px",
                    padding: "8px 30px 8px 8px",
                  }}
                  variant="danger"
                  onDismiss={this.handleDismiss}
                >
                  <h4>{this.state.deleteError}</h4>
                </Alert>
              )}

              <Row className="show-grid selectFulPlan">
                <Col xs={5}>{tr["Task Plan For"]}</Col>
                <Col lg={7} md={12} sm={7} xs={12}>
                  <select
                    className="form-control getFullfilmentHandler"
                    onChange={(event) => {
                      this.getFullfilmentHandler(event.target.value);
                      this.props.selectedTaskTemplate(event.target.value);
                    }}
                    value={this.state.templateSelected}
                  >
                    {this.renderDropDown(
                      fulfillmentOptions,
                      spcmReducer.serviceFulfilmentPlans[0]
                    )}
                  </select>
                </Col>
              </Row>

              {spcmReducer.spcmFetchingAttribute ? (
                <ListLoader />
              ) : (
                <div>
                  {ciTaskPlans.length === 0 ||
                  ciTaskPlans[0].tasks.length === 0 ? (
                    <div className="text-c">No Data Found</div>
                  ) : (
                    <div className="f-size-12 fulPlans-listing">
                      <Table
                        bordered
                        condensed
                        hover
                        responsive
                        className="tableView tableViewMin sortImg tableWithOutPointer"
                      >
                        <thead>
                          <tr>
                            <th width="10%">{tr["Seq"]}</th>
                            <th width="46%">{tr["Template Task Code"]}</th>
                            <th width="34%">
                              <span>{tr["Task Name"]}</span>
                            </th>
                            <th className="text-c">{tr["Action"]}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ciTaskPlans[0].tasks.map((value, index) => {
                            let obj = value;
                            obj.name = ciTaskPlans[0].name;

                            return (
                              <tr key={index}>
                                <td>
                                  {value.status === "Active" ? (
                                    <span
                                      title="Active"
                                      className="btnStatus bgBtn2"
                                    ></span>
                                  ) : (
                                    <span
                                      title="In-Active"
                                      className="btnStatus bgBtn17"
                                    ></span>
                                  )}
                                  {value.sequenceNumber}
                                </td>
                                <td>{value.templateTaskCode}</td>
                                <td>
                                  <div>{value.description}</div>
                                </td>
                                <td>
                                  <div className="text-c">
                                    <i
                                      title={tr["Edit"]}
                                      onClick={() => {
                                        this.props.setFullfilmentEditServiceDetails(
                                          obj
                                        );
                                        this.props.setLeftEditId(
                                          value.templateTaskId
                                        );
                                        this.listFormToggleFunc("form");
                                      }}
                                      className="fa fa-pencil-square-o cursorPoint"
                                    ></i>
                                    <i
                                      title={tr["Delete"]}
                                      className="margin-l-10 fa fa-trash-o cursorPoint"
                                      onClick={() => {
                                        this.onDeleteClick(
                                          value.templateTaskId
                                        );
                                      }}
                                    ></i>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tr: state.spcmReducer.tr,
    spcmReducer: state.spcmReducer,
    classEditList: state.classEditList,
  };
};

export default connect(mapStateToProps, {
  getTaskPlans,
  setEditServiceDetails,
  setFullfilmentEditServiceDetails,
  setForm,
  selectedTaskTemplate,
  getComponentName,
  setLeftEditId,
  getFulfilmentPlans,
  resetFulfillmentPlan,
})(TaskPlanList);
