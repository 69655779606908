
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { Component } from "react";
import { ButtonToolbar, Button, ListGroup, Table } from "react-bootstrap";
import Dropzone from "react-dropzone";
import axios from "axios";
import { connect } from "react-redux";
import { validateTaskField } from "../../../../utils/common";
import _ from "lodash";
import moment from "moment";
import configureStore from "../../../../store/configureStore";
const store = configureStore();
import Cookies from "universal-cookie";
const Joi = require("joi");
import { Scrollbars } from "react-custom-scrollbars";
import datetimeConvertor from "../../../../ISO8601converter";
import { loadCMDBAttachmentList } from "../../../../actions/common/attachmentAction";
const cookies = new Cookies();
import Swal from "sweetalert2";
import { fileobjectGenerator } from "../../../common/MIMEObjGenerator";
import {IoClose} from "react-icons/io5";
import { PiEyeBold } from "react-icons/pi";
import {ImSpinner6} from "react-icons/im";

let attachmentDetails = cookies.get("gph");
if (attachmentDetails) attachmentDetails = attachmentDetails.replace("s:", "");
if (attachmentDetails)
  attachmentDetails = attachmentDetails.substring(
    0,
    attachmentDetails.lastIndexOf(".")
  );
attachmentDetails = attachmentDetails.split("~");
const attachmentSize = attachmentDetails[21];
const attachmentType = fileobjectGenerator(attachmentDetails[22].split(', '));
// if (homepagelocation) homepagelocation = homepagelocation.replace('s:', '');
// if (homepagelocation) homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
// homepagelocation = homepagelocation.split("~");
// homepagelocation = homepagelocation[0];

// if (homepagelocation != "/home")
//   homepagelocation = "/" + homepagelocation;
let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
//console.log("homepage::::"+homepagelocation);
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

let timezone = cookies.get("gph");
if (timezone) timezone = timezone.replace("s:", "");
if (timezone) timezone = timezone.substring(0, timezone.lastIndexOf("."));
timezone = timezone.split("~");
timezone = timezone[3];

class CmdbAttachment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filesPreview: [],
      filesToBeSent: [],
      printcount: 1,
      clickAttachButton: 0,
    };
    this.deleteFileAction = this.deleteFileAction.bind(this);
    this.removeFile = this.removeFile.bind(this);
  }
  componentDidMount() {
    this.props.loadCMDBAttachmentList(this.props.CIEditDetails.CI_ID);
  }

  removeFile() {
    this.setState({ filesToBeSent: [], filesPreview: [] });
  }

  onDrop(acceptedFiles, rejectedFiles) {
    let filesToBeSent = this.state.filesToBeSent;
    if (acceptedFiles.length > 0) {
      if (filesToBeSent.length < this.state.printcount) {
        filesToBeSent.push(acceptedFiles);
        let filesPreview = [];
        for (let i in filesToBeSent) {
          if (filesToBeSent.hasOwnProperty(i)) {
          filesPreview.push(
            <ListGroup.Item bsPrefix=" ">
              <span className="float-r" style={{ cursor: 'pointer' }}
                  onClick={() => {
                    this.removeFile();
                  }}
                ><IoClose />
              </span>
              {filesToBeSent[i][0].name}
            </ListGroup.Item>
          );
        }
      }

        this.setState({ filesToBeSent, filesPreview });
      } else {
        Swal.fire({
          text: "File already attached.",
          button: "OK",
        });
      }
    } else {
    }
  }

  onDropRejected(rejectedFiles) {
    if (rejectedFiles && rejectedFiles[0] && rejectedFiles[0].file && rejectedFiles[0].file.size && rejectedFiles[0].file.size > 1048576 * attachmentSize) {
      Swal.fire({
        text: "Please upload File up to " + attachmentSize + " MB size.",
        button: "OK",
      });
    } else {
      Swal.fire({
        text: "File not supported.",
        button: "OK",
      });
    }
  }

  handleClick(event, CIEditDetails) {
    let self = this;
    if (this.state.filesPreview.length > 0) {
      self.setState({
        clickAttachButton: 1,
      });
    }
    if (this.state.filesToBeSent.length > 0) {
      let formData = new FormData();
      formData.append("file", this.state.filesToBeSent[0][0]);
      let jsonObject = new Object();
      jsonObject.ciId = CIEditDetails.CI_ID;
      jsonObject.ciCode = CIEditDetails.CI_CODE;
      jsonObject.createdBy = "";
      jsonObject.createdByName = "";
      jsonObject.modifiedBy = "";
      jsonObject.modifiedByName = "";
      jsonObject.status = "Y";
      jsonObject.source="UI";
      store.dispatch({ type: "LOAD_CMDB_SUCCESS", cmdbList: {} });
      formData.append("attachment", JSON.stringify(jsonObject));

      axios
        .post("/api/attachments", formData)
        .then((response) => {
          if (response.status == 200) {
            this.props.loadCMDBAttachmentList(CIEditDetails.CI_ID);
            Swal.fire({
              text: "File uploaded successfully.",
              button: "OK",
            });
            this.setState({
              filesToBeSent: [],
              filesPreview: [],
              clickAttachButton: 0,
            });
          } else if (
            response.data.auth == "false" ||
            response.data.auth == false
          ) {
            Swal.fire({
              text: "This user does not have access to upload attachments.",
              button: "OK",
            });
            this.setState({
              filesToBeSent: [],
              filesPreview: [],
              clickAttachButton: 0,
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            text:
              this.props.translator["Please upload File up to "] +
              attachmentSize +
              this.props.translator[" MB size."],
            button: "OK",
          });
          console.log("error in uploading file.");
          this.setState({ clickAttachButton: 0 });
        });
    } else {
      Swal.fire({
        text: this.props.translator["Please upload some files first"],
        button: this.props.translator["OK"],
      });
    }
  }

  deleteFileAction(attachmentId) {
    let ref = this;
    Swal.fire({
      text: "Are you sure you want to delete this?",
      dangerMode: true,
      showDenyButton: true,
      confirmButtonText: this.props.translator['Yes'],
      denyButtonText: this.props.translator['No'],
    }).then((afterhandling) => {
      if (afterhandling.isConfirmed) {
        axios
          .patch("/api/attachments/" + attachmentId, {})
          .then(function (response) {
            console.log(response);
            if (response.status === 200) {
              Swal.fire("Deleted!", "Your  file has been deleted!");
              ref.props.loadCMDBAttachmentList(response.data.ciId);
            } else if (
              response.data.auth == "false" ||
              response.data.auth == false
            ) {
              Swal.fire({
                text: "This user does not have access to delete attachment.",
                button: "OK",
              });
              ref.props.loadCMDBAttachmentList(response.data.ciId);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    });
  }

  renderAttachedItems(attachments) {
    console.log(attachments, "renderAttachedItems");
    if (_.isEmpty(attachments)) {
      return (
        <tr>
          <td colSpan={3} className="text-c">
            {this.props.translator["loading attachments..."]}
          </td>
        </tr>
      );
    }

    return attachments.map((attachment) => {
      const userDate = moment(attachment.createdOn * 1000);
      const userpreDate = moment(userDate).format(this.props.date_format);
      console.log("userpreDate_00", userpreDate);

      const aa = moment(userDate).format("D");

      const date1111 = moment(userDate);

      if (attachment.status !== "Y") {
        return null;
      } else {

        let attachfilename = attachment.fileName;
        if (attachfilename.indexOf("##") > -1)
          attachfilename = attachfilename.slice(15);

        let actDateTimeFormat = jwtdf + " HH:mm:ss";
        let newDate = new Date(attachment.createdOn).toLocaleString("en-US", {
          timeZone: timezone,
        });
        let myDateObj = moment(newDate).format(jwtdf + " HH:mm:ss");
        let isoDate = datetimeConvertor(myDateObj, jwtdf + " HH:mm:ss");
        myDateObj = moment(isoDate).format(actDateTimeFormat);

        let validated_url = undefined;
        const isCreatedByUser = this.props.user_id === attachment.createdBy;
        if (attachment != null && attachment.url != "") {
          let namedSchema = Joi.string().regex(/^[^<>}{]+$/);
          let validator_to_add_myQbj = namedSchema.validate(attachment.url);
          if (validator_to_add_myQbj.error == null) {
            validated_url = attachment.url;
          } else {
            console.log("Validation failed : ", validator_to_add_myQbj);
            validated_url = "\\";
          }
        } else validated_url = "\\";
        return (
          <tr bsClass="" key={attachment.attachmentId}>
            <td>
              <div>{attachfilename}</div>
              <div style={{ color: "rgb(0 0 0 / 50%)" }}>
                {attachment.createdByName}
              </div>
            </td>
            <td>{myDateObj}</td>
            <td className="text-c">
              <a className="margin-r-10" href={validated_url}>
                <PiEyeBold />
              </a>
              {this.props.CIEditDetails.STATUS == "5" ||
           (this.props.CIEditDetails.STATUS == "10" && this.props.CIEditDetails.SUB_STATUS == "19")
            ? null
            : (isCreatedByUser  && 
              this.props.isRoleDisabled 
							? (
							(
                <span className="float-r" style={{ cursor: 'pointer' }}
                onClick={() => {
                  this.deleteFileAction(
                    attachment.attachmentId,
                    attachment.module,
                    attachment.itemId
                  );
                  }}
                ><IoClose />
              </span>
              )
							) : null
              )}
            </td>
          </tr>
        );
      }
    });
  }
  render() {
    { 
    console.log(" this.props.CIEditDetails---",this.props.CIEditDetails.STATUS);
   
  }
    {
      console.log("UploadScreenUserDetails------", this.props.user_id);
    }
    let readOnlyResponse = "";
    if (
      this.props.role_id &&
      this.props.CIEditDetails.statusId &&
      this.props.CIEditDetails.statusId > 0
    ) {
      readOnlyResponse = validateTaskField(
        this.props.role_id,
        this.props.CIEditDetails.statusId
      );
      console.log(readOnlyResponse);
    }
    
    let rolesArray = this.props.user_roles_id.split(',')
    let isConfigVisible = rolesArray.includes("38") || rolesArray.includes("37")
    //console.log("rolesArray==========", rolesArray)
    //console.log("isconfigvisible========", isConfigVisible)
    return (
      <div className="App">
        <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
          <ul>
            <li>
            <button
                type='button'
                title="Minimize the right panel"
                bsClass=""
                bsStyle=""
                className="closerightPanelBtn"
                onClick={() => {
                this.props.rightEditPanel(false);
                }}
            >
                <IoClose/>
            </button>
            </li>
          </ul>
        </div>
        <div className="rPageHeading">
          <div className="offNam margin-t-5 margin-b-5">
            {["Provide Attachments"]}
          </div>
        </div>
        <div className="rBoxGap">
          {this.props.CIEditDetails.STATUS == "5" ||
          (this.props.CIEditDetails.STATUS == "10" && this.props.CIEditDetails.SUB_STATUS == "19")
            ? null
            : isConfigVisible && (
                <div>
                  <div style={{ "text-align": "left" }}>
                    <b>{"Upload your file."}</b> {"(max size"} {attachmentSize}{" "}
                    {"MB)"}
                  </div>

                  <Dropzone
                    onDrop={(files) => this.onDrop(files)}
                    onDropRejected={(files) => this.onDropRejected(files)}
                    maxSize={1048576 * attachmentSize}
                    accept={attachmentType}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <div
                          className="text-c cursorPoint margin-t-5 margin-b-5 grayBg"
                          style={{
                            width: "100%",

                            height: "60px",

                            "padding-top": "20px",

                            border: "1px solid black",

                            "border-top-color": "#bdc4c9",

                            "border-right-color": "#bdc4c9",

                            "border-bottom-color": "#bdc4c9",

                            "border-left-color": "#bdc4c9",
                          }}
                        >
                          <input {...getInputProps()} />

                          <div>{"Drop files or click here to upload."}</div>
                        </div>
                      </div>
                    )}
                  </Dropzone>

                  <div className="margin-t-20">
                    <div
                      style={{ "text-align": "left" }}
                      className="padding-b-5 margin-b-0 black f-size-16 fw-400"
                    >
                      {this.state.filesPreview.length > 0
                        ? "File attached"
                        : "No file attached"}
                    </div>

                    <ListGroup bsPrefix=" " className="attachment-break">
                      {this.state.filesPreview}
                    </ListGroup>
                  </div>

                    <ButtonToolbar className="black margin-t-5">
                      <Button
                        className="rgSidrkBtn smallBtn"
                        bsSize="small"
                        bsStyle="primary"
                        disabled={this.state.clickAttachButton == 1}
                        onClick={(event) =>
                          this.handleClick(event, this.props.CIEditDetails)
                        }
                      >
                        {this.state.clickAttachButton == 1 ? (
                          <ImSpinner6 className="icn-spinner"/>
                        ) : null}{" "}
                        {"Attach"}
                      </Button>
                    </ButtonToolbar>
                </div>
              )}

          <div className="margin-t-20">
            <div className="border-b padding-b-5 margin-b-10 black f-size-16 fw-400">
              {"Attached Files"}
            </div>
            <Table
              responsive
              striped
              bordered
              condensed
              hover
              className="f-size-13 wrpAttTab"
              style={{ marginBottom: "0px" }}
            >
              <thead>
                <tr>
                  <th width="59%">Name & {"Linked By"}</th>
                  <th width="25%">Date & Time</th>
                  <th width="26%" className="text-c">
                    {"Action"}
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.renderAttachedItems(this.props.attachmentDetails)}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ implememtationPlanList }) => {
  console.log("cmdbList", implememtationPlanList);
  return {
    attachmentDetails: implememtationPlanList,
  };
};
export default connect(mapStateToProps, { loadCMDBAttachmentList })(
  CmdbAttachment
);
