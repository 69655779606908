
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Card } from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {IoClose} from "react-icons/io5";
import {ImInfo} from "react-icons/im";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Spinner from "react-spinkit";
import moment from "moment";
import Cookies from "universal-cookie";
import momenttz from "moment-timezone";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import { loadCMDBAuditLogDetails } from "../../../../actions/breakFix/cmdbAuditLogAction";
import { GLOBAL } from "_Globals";
import datetimeConvertor from "../../../../ISO8601converter";
import XsmUserDetails from '_Commons/WorkItemBoard/xsmEditRequestedBy.js';
import ReactReadMoreLess from "../../../../helpers/ReactReadMoreLess";
import WIBNotifcation from './WIBNotification'

const cookies = new Cookies();
let timezone = cookies.get("gph");

if (timezone) timezone = timezone.replace("s:", "");
if (timezone) timezone = timezone.substring(0, timezone.lastIndexOf("."));
timezone = timezone.split("~");
timezone = timezone[3];

let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

let cookiesArray = cookies.get('gph');
if (cookiesArray) cookiesArray = cookiesArray.replace('s:', '');
if (cookiesArray) cookiesArray = cookiesArray.substring(0, cookiesArray.lastIndexOf('.'));
cookiesArray = cookiesArray.split("~");
let langDir = cookiesArray[66];
class CmdbAuditLog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserData: false,
      key:1,
    };
  }

  componentWillMount() {
    this.props.loadCMDBAuditLogDetails(this.props.ciId);
  }

  parseToJSON = (data) => {
    if (typeof data === 'string') {
      try {
        const parsed = JSON.parse(data);
        return Array.isArray(parsed.tag_data) ? parsed.tag_data : [];
      } catch (e) {
        return [];
      }
    }
    return Array.isArray(data.tag_data) ? data.tag_data : [];
  };


  processTagAuditLogs = (auditLogDetails) => {
    auditLogDetails.forEach(item => {
      if (item.AUDIT_FIELD === "TAG") {
        item.FROM = this.parseToJSON(item?.FROM);
        item.TO = this.parseToJSON(item.TO);

        let fromList = [];
        let toList = [];

        if (item.FROM.length === item.TO.length) {
          this.handleSameLengthTags(item.FROM, item.TO, fromList, toList, jwtdf);
        } else if (item.FROM.length < item.TO.length) {
          this.handleMoreTagsInTo(item.FROM, item.TO, fromList, toList, jwtdf);
        } else {
          this.handleMoreTagsInFrom(item.FROM, item.TO, fromList, toList, jwtdf);
        }

        item.FROM = fromList;
        item.TO = toList;
      }
    });
  };

   parseToArray = (data) => {
    if (typeof data === 'string') {
      try {
        const parsed = JSON.parse(data);
        return Array.isArray(parsed) ? parsed : [];
      } catch (e) {
        return [];
      }
    }
    return Array.isArray(data) ? data : [];
  };
  handleSameLengthTags = (fromTags, toTags, fromList, toList, jwtdf) => {
    fromTags.forEach((fromTag) => {
      const matchingToTag = toTags.find(toTag => toTag.tag_key === fromTag.tag_key);
      if (matchingToTag) {
        this.compareTags(fromTag, matchingToTag, fromList, toList, jwtdf);
      } else {
        fromList.push(fromTag);
        toList.push(this.createProxyTag(fromTag));
      }
    });
  };
  handleMoreTagsInTo = (fromTags, toTags, fromList, toList, jwtdf) => {
    toTags.forEach(toTag => {
      const matchingFromTag = fromTags.find(fromTag => fromTag.tag_key === toTag.tag_key);
      if (matchingFromTag) {
        this.compareTags(matchingFromTag, toTag, fromList, toList, jwtdf);
      } else {
        fromList.push(this.createProxyTag(toTag));
        toList.push(toTag);
      }
    });
  };

  handleMoreTagsInFrom = (fromTags, toTags, fromList, toList, jwtdf) => {
    fromTags.forEach(fromTag => {
      const matchingToTag = toTags.find(toTag => toTag.tag_key === fromTag.tag_key);
      if (matchingToTag) {
        this.compareTags(fromTag, matchingToTag, fromList, toList, jwtdf);
      } else {
        fromList.push(fromTag);
        toList.push(this.createProxyTag(fromTag));
      }
    });
  };
  compareTags = (fromTag, toTag, fromList, toList, jwtdf) => {
    if (fromTag.tag_type === 'Simple' && fromTag.tag_value !== toTag.tag_value) {
      fromList.push(fromTag);
      toList.push(toTag);
    } else if (fromTag.tag_type === 'Composite') {
      this.handleCompositeTagComparison(fromTag, toTag, fromList, toList);
    } else if (this.isDateType(fromTag)) {
      this.formatAndCompareDates(fromTag, toTag, fromList, toList, jwtdf);
    }
  };

  handleCompositeTagComparison = (fromTag, toTag, fromList, toList) => {
    fromTag.tag_value.forEach((subTag, index) => {
      const matchingSubTag = toTag.tag_value.find(t => t.tag_key === subTag.tag_key);
      if (matchingSubTag && subTag.tag_value !== matchingSubTag.tag_value) {
        fromList.push(subTag);
        toList.push(matchingSubTag);
      }
    });
  };
  isDateType = (tag) => {
    return tag.tag_data_type === 'DateTime' || tag.tag_data_type === 'Date';
  };

  formatAndCompareDates = (fromTag, toTag, fromList, toList, jwtdf) => {
    const format = fromTag.tag_data_type === 'DateTime' ? `${jwtdf} hh:mm A` : jwtdf;
    const formattedFromValue = moment(fromTag.tag_value).format(format);
    const formattedToValue = moment(toTag.tag_value).format(format);

    if (formattedFromValue !== formattedToValue) {
      fromTag.tag_value = formattedFromValue;
      toTag.tag_value = formattedToValue;
      fromList.push(fromTag);
      toList.push(toTag);
    }
  };

  createProxyTag = (tag) => {
    return {
      ...tag,
      tag_value: 'NA'
    };
  };

  renderTagData = (tagList) => {
    const taglistdata = this.parseToArray(tagList);
    return taglistdata?.map((tag, i) => (
      <div key={`tag-${i}`}>
        <span className="strong">{tag.tag_name}:</span> {tag.tag_value && !Array.isArray(tag.tag_value) && tag.tag_value}
        {Array.isArray(tag.tag_value) && (
          tag.tag_value.map((nestedTag, j) => (
            <div key={`nested-tag-${j}`} className="d-flex">
              <span className="strong">{nestedTag.tag_name}:</span> {nestedTag.tag_value && !Array.isArray(nestedTag.tag_value) && nestedTag.tag_value}
              {Array.isArray(nestedTag.tag_value) && (
                nestedTag.tag_value.map((deepNestedTag, k) => (
                  <div key={`deep-nested-tag-${k}`} className="d-flex">
                    <span className="strong">{deepNestedTag.tag_name}:</span> {deepNestedTag.tag_value}
                  </div>
                ))
              )}
            </div>
          ))
        )}
      </div>
    ));
  };
  
renderAuditLog(auditLogData) {

 if (!auditLogData || auditLogData?.length === 0) {
    return <div>{this.props.translator["Data not available"]}</div>;
  }

  this.processTagAuditLogs(auditLogData);

  return auditLogData.map((auditObj, index) => {

    let actDateTimeFormat = jwtdf + " HH:mm:ss";
    let myDateObj,
      endDate,
      endDateStr,
      timeago,
      startTime,
      duration,
      hours,
      isoDate;
    isoDate = datetimeConvertor(auditObj.CREATED_ON, jwtdf + " HH:mm:ss");
    myDateObj = moment(isoDate).format(actDateTimeFormat);
    endDate = moment(myDateObj, actDateTimeFormat);
    endDate = moment(myDateObj, actDateTimeFormat);
    endDateStr = endDate._i;
    startTime = moment(
      new Date().toLocaleString("en-US", { timeZone: timezone })
    );
    timeago = moment(endDateStr, actDateTimeFormat).from(startTime);
    duration = moment.duration(startTime.diff(endDate));
    hours = duration.asHours();

    let auditfield = auditObj.AUDIT_FIELD;
    let fromContent;
    let toContent;

    if (auditfield == "COMPANY_NAME") auditfield = "Company";

    if (auditfield == "CATEGORY_ID") auditfield = "Categorization";

    if (auditfield == "SUB_CATEGORY_ID") auditfield = "Sub category";

    if (auditfield == "GROUP_NAME") auditfield = "Support Group";

    if (auditfield == "OWNER_NAME") auditfield = "Owner";

    if (auditfield == "LOCATION_NAME") auditfield = "Location";

    if (auditfield == "METALLIC") auditfield = "Business Criticality";


    if (auditfield && auditfield.toUpperCase() === "TAG") {
        const tagToObj =  this.parseToArray(auditObj.TO);
        const tagFromObj  = this.parseToArray(auditObj.FROM);

       fromContent =  this.renderTagData(tagFromObj) || auditObj.FROMNAME;
       toContent =   this.renderTagData(tagToObj) || auditObj.TONAME;
    
    }

   
    if (auditfield && auditfield.toUpperCase() === "COMPLIANCE") {
      try {
        // Parse the TO and FROM fields for COMPLIANCE data
        const complianceToObj = auditObj.TO ? JSON.parse(auditObj.TO) : [];
        const complianceFromObj = auditObj.FROM ? JSON.parse(auditObj.FROM) : [];
    
        // Check if TO has compliance data
        if (Array.isArray(complianceToObj) && complianceToObj.length > 0) {
          auditObj.TONAME = complianceToObj.map((item, i) => (
            <div key={`to-compliance-${i}`}>
              <strong>{item.label}:</strong> {item.value}
            </div>
          ));
        } else {
          auditObj.TONAME = "NA";
        }
    
        // Check if FROM has compliance data
        if (Array.isArray(complianceFromObj) && complianceFromObj.length > 0) {
          auditObj.FROMNAME = complianceFromObj.map((item, i) => (
            <div key={`from-compliance-${i}`}>
              <strong>{item.label}:</strong> {item.value}
            </div>
          ));
        } else {
          auditObj.FROMNAME = "NA";
        }
      } catch (e) {
        console.error("Error parsing COMPLIANCE data:", e);
        auditObj.TONAME = "NA";
        auditObj.FROMNAME = "NA";
      }
    }    
    
    if(auditfield && auditfield.toUpperCase() != "CREATED") {
      if (auditObj.TONAME == "" || auditObj.TONAME == null || auditObj.TONAME == undefined) auditObj.TONAME = "NA";
      if (auditObj.FROMNAME == "" || auditObj.FROMNAME == null || auditObj.FROMNAME == undefined) auditObj.FROMNAME = "NA";
    }

    if (auditfield == "ATTRIBUTE - DECOMMISSION DATE" || auditfield == "ATTRIBUTE - DEPLOYMENT DATE" || auditfield == "ATTRIBUTE - END OF SUPPORT DATE") {  

      if(auditObj.TONAME != "NA"){
        auditObj.TONAME = momenttz(auditObj.TONAME).tz(timezone).format(jwtdf + " HH:mm:ss");
      }
      if(auditObj.FROMNAME != 'NA'){  
        auditObj.FROMNAME = momenttz(auditObj.FROMNAME).tz(timezone).format(jwtdf + " HH:mm:ss");
      }
    };
    if (auditfield == "IS EXTERNAL") {
      if (auditObj.TONAME == 'true' || auditObj.TONAME == true) {
        auditObj.TONAME = 'True'
      }
      if (auditObj.TONAME == 'false' || auditObj.TONAME == false) {
        auditObj.TONAME = 'False'
      }
      if (auditObj.FROMNAME == 'true' || auditObj.FROMNAME == true) {
        auditObj.FROMNAME = 'True'
      }
      if (auditObj.FROMNAME == 'false' || auditObj.FROMNAME == false) {
        auditObj.FROMNAME = 'False'
      }
    }
  
    const tooltip = (
      <Popover className="userInfoPopup">
      <XsmUserDetails userId={auditObj.UPSERT_USERID} translator={this.props.translator} isHeadingVisible={false} isPopup={true} />
      </Popover>
    );

    let lessKey = "...";
    let lessKeyTr = this.props.translator["less"];
    lessKey = lessKey.concat(lessKeyTr);
    
    return (
      <Card bsPrefix=" " key={index}>
        <div className="subhed">
          
          {auditObj.UPSERT_USERID == null || auditObj.UPSERT_USERID == "" ? (
            <span className="circleNa" title={auditObj.CREATED_BY}>
              {auditObj.CREATED_BY}
            </span>
          ) : (
            <OverlayTrigger
              trigger="click"
              rootClose
              placement={this.props.windowSize.width < 768 ? "auto-start" : (langDir === "rtl" ? "right-start" : "left-start")
}
              overlay={tooltip}
            >
              <span className="circleNa" title={auditObj.CREATED_BY}>
                {auditObj.CREATED_BY}
                <ImInfo className="margin-l-5"/>
              </span>
            </OverlayTrigger>
          )}
          <span
            className="margin-l-5 timeao"
            title={hours >= 24 ? "" : endDateStr}
          >
            {hours >= 24 ? endDateStr : timeago}
          </span>
        </div>
        <div className="desFld">
          <div title="Field Name" className="fenme">
            {auditfield}
          </div>
          <div
            title={auditfield == "ATTACHMENT" ? "Action" : "New Value"}
            className="text-break commn"
          >
          {auditfield.toUpperCase() === "COMPLIANCE" ? <ComplianceLabel auditObj={auditObj.TONAME} /> :
            auditfield.toUpperCase() !== "TAG" && <ReactReadMoreLess charLimit={80}
              readMoreText={this.props.translator["more"]}
              readLessText={lessKey}
              content={auditObj.TONAME} />
           }
          </div>
          <div
            title={auditfield == "ATTACHMENT" ? "File Name" : "Old Value"}
            className="oldnme commn"
          >
             {auditfield.toUpperCase() === "COMPLIANCE" ? <ComplianceLabel auditObj={auditObj.FROMNAME} /> :
               auditfield.toUpperCase() !== "TAG" &&<ReactReadMoreLess charLimit={80}
                  readMoreText={this.props.translator["more"]}
                  readLessText={lessKey}
                  content={auditObj.FROMNAME} />
            } 
          </div>
           {auditfield === "TAG" && <div title="New Value" className="text-break commn w-full">   
             { toContent || "NA"}
            </div>}
           {auditfield === "TAG" && <div title="Old Value" className="oldnme commn w-full">
                {fromContent || "NA"}
            </div>}
        </div>
      </Card>
    );
  });

}

  render() {
    setTimeout(() => {
      if (this.props.cmdbAuditLogDetails.length == 0) {
        this.props.changeCompMessage(
          this.props.translator["Data not available"]
        );
      }
    }, 5000);

    /*if (this.props.cmdbAuditLogDetails.length == 0) {
      return this.props.message == "" ? (
        <div>
          <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
          <ul>
            <li>
            <button
                type='button'
                title="Minimize the right panel"
                bsClass=""
                bsStyle=""
                className="closerightPanelBtn"
                onClick={() => {
                this.props.rightEditPanel(false);
                }}
            >
                <IoClose/>
            </button>
            </li>
          </ul>
        </div>
          <div className="rPageHeading">
            <div className="offNam margin-t-5 margin-b-5">
              {this.props.translator["Audit Log"]}
            </div>
          </div>
          <div className="rBoxGap">
            {this.props.translator["Loading audit log..."]}
            <Spinner spinnerName="three-bounce" />
          </div>
        </div>
      ) : (
        <div>
          <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
            <ul>
              <li>
              <button
                  type='button'
                  title="Minimize the right panel"
                  bsClass=""
                  bsStyle=""
                  className="closerightPanelBtn"
                  onClick={() => {
                  this.props.rightEditPanel(false);
                  }}
              >
                  <IoClose/>
              </button>
              </li>
            </ul>
          </div>
          <div className="rPageHeading">
            <div className="offNam margin-t-5 margin-b-5">
              {this.props.translator["Audit Log"]}
            </div>
          </div>
          <div className="rBoxGap">
            {this.props.translator["Data not available"]}
          </div>
        </div>
      );
    }*/

    return (
      <div>
        <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
          <ul>
            <li>
            <button
                type='button'
                title="Minimize the right panel"
                bsClass=""
                bsStyle=""
                className="closerightPanelBtn"
                onClick={() => {
                this.props.rightEditPanel(false);
                }}
            >
                <IoClose/>
            </button>
            </li>
          </ul>
        </div>
        <div className="rPageHeading">
          <div className="offNam margin-t-5 margin-b-5">
            {this.props.translator["Activity Details"]}
          </div>
        </div>
        <div className="rBoxGap">
        <div className="rBoxTabs cntrtTab">
           <Tabs activeKey={this.state.key} onSelect={(k) => this.setState({key:k})} id="tabId">
            <Tab eventKey={1} title={this.props.translator["Audit Log"]}>
            <div id="cmdbAudLog" className="actCommts py-2">
              {this.renderAuditLog(this.props.cmdbAuditLogDetails)}
            </div>
            </Tab>
            <Tab eventKey={2} title={this.props.translator["Notifications"]}>  
              <WIBNotifcation requestNumber={this.props?.ciCode} orderNumber={""} />
            </Tab>
          </Tabs>
          </div>
        </div>
      </div>
    );
  }
}


export function mapStateToProps({ cmdbAuditLogDetails, windowSize }) {
  return { cmdbAuditLogDetails, windowSize };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loadCMDBAuditLogDetails }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CmdbAuditLog);

const ComplianceLabel = ({ auditObj }) => {
  let parsedTONAME;

  // Attempt to parse the auditObj.TONAME safely
  try {
      // Check if the input is valid and parse it
      if (typeof auditObj === 'string' && auditObj !== 'NA' && auditObj !== 'null') {
          parsedTONAME = JSON.parse(auditObj);
      } else {
          parsedTONAME = [];
      }
  } catch (error) {
      console.error("Error parsing auditObj:", error);
      parsedTONAME = [];
  }

  return (
      <div style={{ display: "flex" }}>
          <div style={{ display: "flex", gap:0}}>
              { parsedTONAME.length > 0 ? (
                  parsedTONAME.map((item, index, arr) => (
                      <span key={index}>
                          {item.label}
                          {index < arr.length - 1 && <span>,</span>}
                      </span>
                  ))
              ) : (
                  <span>NA</span>
              )}
          </div>
      </div>
  );
};