
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const activityComponent = {
  name: "ActivityComponent",
  content: `<div class="row">
    <div
      class="col-md-12"
      style="text-align:center;height:40px;background-color:#00838f;color:white;line-height:40px;"
    >
      Activity Details
    </div>
    <div class="col-md-12">
      <ul style="color: rgba(255, 255, 255, .9);font-size: 16px;font-weight: 400;text-transform: capitalize;list-style:none;display:flex">
        <li style="padding: 5px 15px;color:#000;">Comments</li>
        <li style="padding: 5px 15px;color:#000;">Audit Log</li>
        <li style="padding: 5px 15px;color:#000;">Notifications</li>
      </ul>
    </div>
    <div class="col-md-12" style="padding:0 0 20px 54px">
        <textarea class="form-control" row="5"></textarea><br/>
        <button>Save</button>
      </div>
  </div>`,
  traits: [
    {
      type: "text",
      label: "Header Text",
      name: "headerText",
      placeholder: "Enter url to call on save button click",
      changeProp: 1,
    },
    {
      type: "number",
      label: "Col",
      name: "col",
      changeProp: 1,
    },
    {
      type: "text",
      label: "Post Url",
      name: "postUrl",
      placeholder: "Enter url to call on save button click",
      changeProp: 1,
    },
    {
      type: "text",
      label: "Get Audit Log Url",
      name: "activityLogUrl",
      placeholder: "Enter url to get audit log",
      changeProp: 1,
    },
    {
      type: "text",
      label: "Get Activity Log Url",
      name: "title",
      placeholder: "Enter url to get audit log",
      changeProp: 1,
    },
    {
      type: "text",
      label: "Get Audit Log Url",
      name: "title",
      placeholder: "Enter url to get audit log",
      changeProp: 1,
    },
    {
      type: "textarea",
      label: "Description",
      name: "description",
      placeholder: "Insert the description here",
      changeProp: 1,
    },
    {
      type: "checkbox",
      label: "Hide Audit Log",
      name: "hideAuditLog",
      changeProp: 1,
    },
    {
      type: "checkbox",
      label: "Hide Notification Tab",
      name: "hideNotificationTab",
      changeProp: 1,
    },
    {
      type: "checkbox",
      label: "Disabled",
      name: "disabled",
      changeProp: 1,
    },
    {
      type: "text",
      label: "Belongs To",
      name: "belongsTo",
      changeProp: 1,
    },
    {
      type: "text",
      label: "Besed On",
      name: "basedOn",
      changeProp: 1,
    },
  ],
};

const dataSources = ["category", "businessFunction", "status", "company"];

export const activityTypeAdd = {
  model: {
    defaults: {
      tagName: "div",
      draggable: true,
      attributes: { class: "my-component" },
      content: activityComponent.content,
      traits: activityComponent.traits,
      editable: true,
    },
  },
};

export const addActivityComponent = (e) => {
  e.DomComponents.addType(activityComponent.name, activityTypeAdd);
};

export const activityBlockJson = {
  id: "activityBlock",
  label: "Activity Details",
  attributes: { class: "fa fa-th" },
  category: "Basic",
  content: {
    type: activityComponent.name,
  },
};

//// end of creating activity block

export const typeaheadComponent = {
  name: "typeaheadComponent",
  content: `<div class="row">
    <div class="col-md-12">
    <input type="text" placeholder="Please choose" class="form-control"/>
    </div>
  </div>`,
  traits: [
    {
      type: "text",
      label: "Name",
      name: "name",
      placeholder: "Enter Name",
      changeProp: 1,
    },
    {
      type: "number",
      label: "Col",
      name: "col",
      changeProp: 1,
    },
    {
      type: "text",
      label: "Label",
      name: "label",
      placeholder: "Enter Label",
      changeProp: 1,
    },
    {
      type: "text",
      label: "Url",
      name: "url",
      placeholder: "Url",
      changeProp: 1,
    },
    {
      type: "select",
      name: "apiType",
      label: "Api Type",
      options: [
        { id: "get", label: "Get" },
        { id: "post", label: "Post" },
        { id: "patch", label: "patch" },
      ],
      changeProp: 1,
    },
    {
      type: "text",
      label: "Label Key",
      name: "labelKey",
      placeholder: "Enter lable Key",
      changeProp: 1,
    },
    {
      type: "text",
      label: "Value Key",
      name: "valueKey",
      placeholder: "Enter Value Key",
      changeProp: 1,
    },
    {
      type: "checkbox",
      label: "Required",
      name: "required",
      changeProp: 1,
    },
    {
      type: "checkbox",
      label: "Is Query Param",
      name: "isQueryParam",
      changeProp: 1,
    },
    {
      type: "text",
      label: "Comma Seperated",
      placeholder: "Enter Comma Seperated Keys",
      name: "queryParams",
      changeProp: 1,
    },
    {
      type: "checkbox",
      label: "Multiple",
      name: "multiSelect",
      changeProp: 1,
    },
    {
      type: "select",
      label: "Field Type",
      name: "fieldType",
      options: [
        {
          id: "typeahead",
          label: "Normal Typeahead",
        },
        {
          id: "kendoMultiSelect",
          label: "Kendo Multiselect",
        },
      ],
      changeProp: 1,
    },
    {
      type: "checkbox",
      label: "Is Preloaded",
      name: "isPreloaded",
      changeProp: 1,
    },
    {
      type: "checkbox",
      label: "Disabled",
      name: "disabled",
      changeProp: 1,
    },
    {
      type: "text",
      label: "Belongs To",
      name: "belongsTo",
      changeProp: 1,
    },
    {
      type: "text",
      label: "Besed On",
      name: "basedOn",
      changeProp: 1,
    },
  ],
};

export const typeaheadFieldAdd = {
  model: {
    defaults: {
      tagName: "div",
      draggable: true,
      attributes: { class: "typeaheadComponent" },
      content: typeaheadComponent.content,
      traits: typeaheadComponent.traits,
      editable: true,
    },
  },
};

export const addTypeaheadComponent = (e) => {
  e.DomComponents.addType(typeaheadComponent.name, typeaheadFieldAdd);
};

export const typeaheadBlockJson = {
  id: "typeaheadBlock",
  label: "Typeahead & Dropdown",
  attributes: { class: "fa fa-th" },
  category: "Basic",
  content: {
    type: typeaheadComponent.name,
  },
};

///// end of typeahead

export const uploadComponent = {
  name: "uploadComponent",
  content: `<div class="row">
    <div class="col-md-12">
    <input type="file" placeholder="Please choose" class="form-control"/>
    </div>
  </div>`,
  traits: [
    {
      type: "number",
      label: "Col",
      name: "col",
      changeProp: 1,
    },
    {
      type: "text",
      label: "Name",
      name: "name",
      placeholder: "Enter Name",
      changeProp: 1,
    },
    {
      type: "text",
      label: "Label",
      name: "label",
      placeholder: "Enter Label",
      changeProp: 1,
    },
    {
      type: "text",
      label: "Url",
      name: "url",
      placeholder: "Url",
      changeProp: 1,
    },
    {
      type: "select",
      name: "apiType",
      label: "Api Type",
      options: [
        { id: "get", label: "Get" },
        { id: "post", label: "Post" },
        { id: "patch", label: "Patch" },
        { id: "form", label: "Form" },
      ],
      changeProp: 1,
    },
    {
      type: "select",
      name: "fieldType",
      label: "Field Type",
      options: [
        { id: "dropzone", label: "Dropzone" },
        { id: "normal", label: "normal" },
      ],
      changeProp: 1,
    },
    {
      type: "checkbox",
      label: "Required",
      name: "required",
      changeProp: 1,
    },
    {
      type: "checkbox",
      label: "Preview",
      name: "preview",
      changeProp: 1,
    },
    {
      type: "checkbox",
      label: "Multiple",
      name: "multiSelect",
      changeProp: 1,
    },
    {
      type: "checkbox",
      label: "Disabled",
      name: "disabled",
      changeProp: 1,
    },
    {
      type: "text",
      label: "Belongs To",
      name: "belongsTo",
      changeProp: 1,
    },
    {
      type: "text",
      label: "Besed On",
      name: "basedOn",
      changeProp: 1,
    },
  ],
};

export const uploadFieldAdd = {
  model: {
    defaults: {
      tagName: "div",
      draggable: true,
      attributes: { class: "uploadField" },
      content: uploadComponent.content,
      traits: uploadComponent.traits,
      editable: true,
    },
  },
};

export const addUploadComponent = (e) => {
  e.DomComponents.addType(uploadComponent.name, uploadFieldAdd);
};

export const uploadBlockJson = {
  id: "uploadBlock",
  label: "Dropzone & File",
  attributes: { class: "fa fa-file" },
  category: "Basic",
  content: {
    type: uploadComponent.name,
  },
};

///// end of upload field type

export const myThComponent = (e) => {
  e.DomComponents.addType("custom-th", {
    model: {
      defaults: {
        tagName: "th",
        traits: [
          {
            type: "text",
            label: "Header Text",
            name: "innerText",
            changeProp: 1,
          },
          {
            type: "checkbox",
            label: "Is Sortable",
            name: "isSortable",
            changeProp: 1,
          },
          {
            type: "checkbox",
            label: "Is Filterable",
            name: "isFilterable",
            changeProp: 1,
          },
          {
            type: "select",
            label: "Fiter Field",
            name: "fitlerFieldType",
            options: [
              {
                id: "normalInput",
                label: "Input",
              },
            ],
            changeProp: 1,
          },
          {
            type: "text",
            label: "Binding Key",
            name: "bindingKey",
            changeProp: 1,
          },
        ],
      },
      init() {
        this.on("change:innerText", this.handleInnerTextChange);
      },
      handleInnerTextChange() {
        this.components(this.get("innerText"));
      },
    },
  });
};

/// end of th component

export const myInputComponent = (e) => {
  e.DomComponents.addType("input", {
    // Make the editor understand when to bind `my-input-type`
    isComponent: (el) => el.tagName === "INPUT",

    // Model definition
    model: {
      // Default properties
      defaults: {
        traits: [
          {
            type: "number",
            label: "Col",
            name: "col",
          },
          "name",
          "placeholder",
          {
            type: "select",

            name: "type",
            label: "Type",
            options: [
              { id: "text", label: "Text" },
              { id: "textArea", label: "Textarea" },
              { id: "email", label: "Email" },
              { id: "password", label: "Password" },
              { id: "number", label: "Number" },
              { id: "datePicker", label: "Date picker" },
              { id: "colorPicker", label: "Color picker" },
              { id: "dateRangePicker", label: "Date range picker" },
              { id: "dateTimePicker", label: "Date time picker" },
              { id: "dateInput", label: "Date input" },
            ],
          },
          {
            type: "checkbox",
            name: "required",
          },
          "minLength",
          "maxLength",
          {
            type: "checkbox",
            label: "Disabled",
            name: "disabled",
          },
          {
            type: "text",
            label: "Belongs To",
            name: "belongsTo",
          },
          {
            type: "text",
            label: "Besed On",
            name: "basedOn",
          },
        ],
        attributes: { type: "text", required: true, class: "form-control" },
      },
    },
  });
}; /// end of my input custom component

export const myCheckboxComponent = (e) => {
  e.DomComponents.addType("custom-checkbox", {
    model: {
      defaults: {
        tagName: "input",
        attributes: { type: "checkbox" },
        traits: [
          {
            type: "number",
            label: "Col",
            name: "col",
            changeProp: 1,
          },
          {
            type: "text",
            label: "Name",
            name: "name",
            changeProp: 1,
          },
          {
            type: "checkbox",
            label: "Checked",
            name: "checked",
            changeProp: 1,
          },
          {
            type: "text",
            label: "Label",
            name: "label",
            changeProp: 1,
          },
          {
            type: "text",
            label: "Value",
            name: "value",
            changeProp: 1,
          },
          {
            type: "checkbox",
            label: "Required",
            name: "required",
            changeProp: 1,
          },
          {
            type: "checkbox",
            label: "Disabled",
            name: "disabled",
            changeProp: 1,
          },
          {
            type: "text",
            label: "Belongs To",
            name: "belongsTo",
            changeProp: 1,
          },
          {
            type: "text",
            label: "Besed On",
            name: "basedOn",
            changeProp: 1,
          },
        ],
      },
      init() {
        this.on("change:label", this.handleInnerTextChange);
        this.on("change:checked", this.handleCheckedChange);
      },
      handleCheckedChange() {
        const checked = this.get("checked");
        const tag = this.find("input")[0];
        tag.set("attributes", {
          ...this.get("attributes"),
          checked: checked,
        });
      },
      handleInnerTextChange() {
        const tag = this.find("span")[0];
        const label = this.get("label");
        if (tag) {
          tag.components(label);
        }
      },
    },
  });
};

export const myCheckboxBlockJson = {
  label: "Custom Checkbox",
  attributes: { class: "fa fa-check-square-o" },
  category: "Basic",
  content: `
    <div data-gjs-type="custom-checkbox"><input type="checkbox"  /><span> Custom Checkbox</span><div>
  `,
};

/// end of custom checkbox

export const myRadioComponent = (e) => {
  e.DomComponents.addType("custom-radio", {
    model: {
      defaults: {
        tagName: "input",
        attributes: { type: "radio" },
        traits: [
          {
            type: "number",
            label: "Col",
            name: "col",
            changeProp: 1,
          },
          {
            type: "text",
            label: "Name",
            name: "name",
            changeProp: 1,
          },
          {
            type: "text",
            label: "Label",
            name: "label",
            changeProp: 1,
          },
          {
            type: "checkbox",
            label: "Checked",
            name: "checked",
            changeProp: 1,
          },
          {
            type: "text",
            label: "Labels",
            name: "labels",
            changeProp: 1,
          },
          {
            type: "text",
            label: "Values",
            name: "values",
            changeProp: 1,
          },
          {
            type: "checkbox",
            label: "Required",
            name: "required",
            changeProp: 1,
          },
          {
            type: "checkbox",
            label: "Disabled",
            name: "disabled",
            changeProp: 1,
          },
          {
            type: "text",
            label: "Belongs To",
            name: "belongsTo",
            changeProp: 1,
          },
          {
            type: "text",
            label: "Besed On",
            name: "basedOn",
            changeProp: 1,
          },
        ],
      },
      init() {
        this.on("change:label", this.handleInnerTextChange);
        this.on("change:checked", this.handleCheckedChange);
      },
      handleCheckedChange() {
        const checked = this.get("checked");
        const tag = this.find("input")[0];
        tag.set("attributes", {
          ...this.get("attributes"),
          checked: checked,
        });
      },
      handleInnerTextChange() {
        const tag = this.find("span")[0];
        const label = this.get("labels");
        if (tag) {
          tag.components(label);
        }
      },
    },
  });
};

export const myRadioBlockJson = {
  label: "Custom Radio",
  attributes: { class: "fa fa-circle" },
  category: "Basic",
  content: `
    <div data-gjs-type="custom-radio"><input type="radio" /><span> Custom Radio</span><div>
  `,
};

//// end of custom radio button

export const mySelect = (e) => {
  e.DomComponents.addType("custom-dropdown", {
    model: e.DomComponents.getType("select").model.extend({
      init() {
        this.listenTo(this, "change:attributes:datasource", this.updateTraits);
        this.updateTraits();

        const traits = this.get("traits");
        this.addClass("form-control");
        traits.add([
          {
            type: "number",
            label: "Col",
            name: "col",
          },
          "className",
          {
            type: "checkbox",
            name: "customOptions",
          },
          {
            type: "select", // Type of the trait
            name: "datasource", // (required) The name of the attribute/property to use on component
            label: "Data Source", // The label you will see in Settings
            options: [
              { id: "company", label: "Company" },
              { id: "status", label: "Status" },
              { id: "businessFunction", label: "Business Function" },
              { id: "category", label: "Category" },
            ],
          },
          { type: "text", name: "label", label: "Label" },
          {
            type: "checkbox",
            label: "Disabled",
            name: "disabled",
          },
          {
            type: "text",
            label: "Belongs To",
            name: "belongsTo",
          },
          {
            type: "text",
            label: "Besed On",
            name: "basedOn",
          },
        ]);
      },
      updateTraits() {
        const datasource = this.getAttributes().datasource;
        const isCustomDropdown = this.getAttributes().customOptions;
        const traits = this.get("traits");
        // traits.reset();

        // if (dataSources.includes(datasource)) {
        //   traits.add([
        //     {
        //       type: "text",
        //       label: "Url",
        //       name: "url",
        //     },
        //   ]);
        // } else if (isCustomDropdown === false) {
        //   const optionsTrait = traits.find({ name: "options" });
        //   if (optionsTrait) {
        //     traits.removeTrait("url");
        //   }
        // }
        // Add more conditions for other datasources
      },
    }),
    view: e.DomComponents.getType("select").view,
  });
}; //// custome dropdown component

export const buttonExtendTraits = (e) => {
  e.DomComponents.addType("button", {
    model: {
      defaults: {
        ...e.DomComponents.getType("button").model.prototype.defaults,
        traits: [
          ...e.DomComponents.getType("button").model.prototype.defaults.traits,
          {
            type: "number",
            label: "Col",
            name: "col",
          },
          {
            type: "text",
            label: "Url",
            name: "url",
          },
          {
            type: "text",
            label: "Redirect Url",
            name: "redirectUrl",
          },
          {
            type: "select",
            label: "API Type",
            name: "apiType",
            options: [
              {
                id: "post",
                label: "Post",
              },
              {
                id: "patch",
                label: "Patch",
              },
            ],
          },
        ],
      },
    },
  });
}; //// button extend traits

export const tableExtendTraits = (e) => {
  e.DomComponents.addType("table", {
    model: e.DomComponents.getType("table").model.extend({
      init() {
        const traits = this.get("traits");
        traits.add([
          {
            type: "text",
            label: "Url",
            name: "url",
          },
          {
            type: "text",
            label: "Unique Key",
            name: "uniqueKey",
          },
          {
            type: "select",
            label: "API Type",
            name: "apiType",
            options: [
              {
                id: "post",
                label: "Post",
              },
              {
                id: "patch",
                label: "Patch",
              },
            ],
          },
        ]);
      },
    }),
    view: e.DomComponents.getType("button").view,
  });
}; //// button extend traits

export const tableBlockJson = {
  label: "Table data grid",
  category: "Basic",
  attributes: { class: "fa fa-table" },
  content: {
    tagName: "table",
    type: "table",
    style: {
      width: "100%",
      height: "100%",
      borderCollapse: "collapse",
    },
    editable: true,
    droppable: false,
    components: `
      <table style="width: 100%; border: 1px solid #ddd; border-collapse: collapse;" border="1">
        <thead style="background-color: #eaeaea; color: #000;">
          <tr>
            <th data-gjs-type="custom-th" style="padding: 8px; border: 1px solid #ddd;">Name</th>
            <th data-gjs-type="custom-th" style="padding: 8px; border: 1px solid #ddd;">Company</th>
            <th data-gjs-type="custom-th" style="padding: 8px; border: 1px solid #ddd;">Track</th>
            <th data-gjs-type="custom-th" style="padding: 8px; border: 1px solid #ddd;">Sub Track</th>
            <th data-gjs-type="custom-th" style="padding: 8px; border: 1px solid #ddd;">Region</th>
            <th data-gjs-type="custom-th" style="padding: 8px; border: 1px solid #ddd;">Type</th>
            <th data-gjs-type="custom-th" style="padding: 8px; border: 1px solid #ddd;">Status</th>
            <th data-gjs-type="custom-th" style="padding: 8px; border: 1px solid #ddd;">Created On</th>
          </tr>
        </thead>
        <tbody>
          <tr style="background-color: #f8f9fa;">
            <td style="padding: 8px; border: 1px solid #ddd;">test OPA 9 feb 2023	</td>
            <td style="padding: 8px; border: 1px solid #ddd;">Hindustan Unilever Limited</td>
            <td style="padding: 8px; border: 1px solid #ddd;">Database	</td>
            <td style="padding: 8px; border: 1px solid #ddd;">MYSQL	</td>
            <td style="padding: 8px; border: 1px solid #ddd;">Asia</td>
            <td style="padding: 8px; border: 1px solid #ddd;">IT</td>
            <td style="padding: 8px; border: 1px solid #ddd;">Enabled</td>
            <td style="padding: 8px; border: 1px solid #ddd;">02-08-2024 19:10:33</td>
          </tr>
          <tr style="background-color: #ffffff;">
            <td style="padding: 8px; border: 1px solid #ddd;">test OPA 9 feb 2023	</td>
            <td style="padding: 8px; border: 1px solid #ddd;">Hindustan Unilever Limited</td>
            <td style="padding: 8px; border: 1px solid #ddd;">Database	</td>
            <td style="padding: 8px; border: 1px solid #ddd;">MYSQL	</td>
            <td style="padding: 8px; border: 1px solid #ddd;">Asia</td>
            <td style="padding: 8px; border: 1px solid #ddd;">IT</td>
            <td style="padding: 8px; border: 1px solid #ddd;">Enabled</td>
            <td style="padding: 8px; border: 1px solid #ddd;">02-08-2024 19:10:33</td>
          </tr>
        </tbody>
      </table>
    `,
  },
  traits: [
    { type: "text", label: "Label", name: "label" },
    {
      type: "checkbox",
      name: "isFilterRequired",
      label: "Is Filter",
    },
    {
      type: "text",
      name: "bindValueKey",
      label: "Value Key",
    },
  ],
}; /// end of table block json

export const getDataInTable = (data) => {
  if (isJson(data)) {
    const jsonData = JSON.parse(data);
    let returnString = [];
    if (jsonData && Array.isArray(jsonData)) {
      jsonData.forEach((res) => {
        if (res.hasOwnProperty("label")) {
          returnString.push(res.label);
        }
      });
      return returnString.join(",");
    }
  } else {
    return data;
  }
}; // end of data

export function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
