
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Loader from 'react-loader-spinner';
import {connect} from 'react-redux';

class ListLoader extends React.Component {
    render(){
        return(
            <div className="listLoader" style={this.props.myStyle}>{this.props.tr['Loading']} <Loader type="Oval" color="green" height={20} width={20} /></div>
        )
    }
}

const mapStateToProps = ({spcmReducer}) => {
	return {
        tr: spcmReducer.tr
    }
}

export default connect(mapStateToProps)(ListLoader);
