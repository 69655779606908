
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export const globalSearchData = (state = { list: [], isFetching: true, isError: false }, action) => {
    switch (action.type) {
        case 'GLOBAL_SEARCH_DATA_LOADED':
            return Object.assign({}, state,
                {
                    list: action.globalSearchData,
                    isFetching: action.isFetching,
                    error: action.isError
                });
        case 'GLOBAL_SEARCH_DATA_NOT_FOUND':
            return Object.assign({}, state,
                {
                    list: action.globalSearchData,
                    isFetching: action.isFetching,
                    error: action.isError
                });
        default:
            return state;
    }
};
export const globalSearchDataForRevamp = (state = initialState.globalSearchDataForRevamp, action) =>{
	switch (action.type){
		case 'GLOBAL_SEARCH_REVAMP_DATA_LOADED':
			return action.globalSearchDataForRevamp.data;
		default:
			return state;
	}
}

export const globalSearchListSearchText = (state = '', action) =>{
	switch (action.type){
		case 'SET_GLOBAL_SEARCH_LIST_SEARCHTEXT':
			return action.data;
		default:
			return state;
	}
}

export const globalSearchListCategoryId = (state = 0, action) =>{
	switch (action.type){
		case 'SET_GLOBAL_SEARCH_LIST_CATEGORYID':
			return action.data;
		default:
			return state;
	}
}

export const wibGlobalSearchFilterData =(state={},action)=>{
    if(action.type==='WIB_GLOBAL_SEARCH_FILTER_DATA'){
        return action.filterData;
    }else{
        return state;
    }
};


export const wibSearchParams = (state = {}, action) => {
  if (action.type === "WIB_SEARCH_PARAMS") {
    return {...state, searchParams:action.searchParams};
  } else {
    return state;
  }
};

export const isSearchParamsApply = (state = false, action) => {
  if (action.type === "SET_IS_SEARCH_APPLY") {
    return action.applied;
  } else {
    return state;
  }
};

export const wibActiveTab = (state = "", action) => {
    if (action.type === "SET_WIB_ACTIVE_TAB") {
      return action.tab;
    } else {
      return state;
    }
};

export const wibSelectedDateRanceLabel = (state = "", action) => {
  if (action.type === "WIB_SELECTED_DATE_RANGE_LABEL") {
    return action.label;
  } else {
    return state;
  }
};

export const wibSlaColors = (state = "", action) => {
  if (action.type === "WIB_SLA_COLORS_FDN") {
    return action.color;
  } else {
    return state;
  }
};
