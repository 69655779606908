
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useRef, useState } from "react";
import { Table, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { IoSearch } from "react-icons/io5";
import ReactDOM from "react-dom";
import Pagination from "react-js-pagination";
import ListLoader from "../../common/loaders/ListLoader";

import Cookies from "universal-cookie";
import { usePreviousValue } from "../../../helpers/usePreviousValue";
const cookies = new Cookies();


const List = (props) => {
    const dispatch = useDispatch();
    const tr = useSelector((state) => state.spcmReducer.tr);
    const loader = useSelector((state) => state.showLoader.loading);


    return (
        <>
            <div aria-label="Table" role="contentinfo" className={"respondv"}>
                <div className={"tableRgtBor table-responsive" }>
                    <Table striped bordered condensed hover
                        className="tableView nowrapWhright"
                        style={{ marginTop: "0px" }}
                    >
                        <thead>
                            <tr>
                                <th>Item ID</th>
                                <th>Company</th>
                                <th>Class</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>ITM00000000001</td>
                                <td>Hindustan Unilever Limited</td>
                                <td>Class Name</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>

            </div>
        </>
    );
};

export default List;
