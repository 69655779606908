
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { GLOBAL } from '_Globals';
import MyProfileHeader from './MyProfileHeader';
import ProfileImg from './ProfileImg';
import BottomSection from './BottomSection';

import { loadProfile } from '_Actions/myProfile/profileAction';
import axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTranslation } from '_Actions/spcmActions.js';
import Cookies from 'universal-cookie';
import PindCategories from '_Commons/header/pind-categories';
import EmailValidator from 'email-validator';
import LeftSideFormTab from './LeftSideFormTab';
import RightSideAction from './RightSideAction';
import "_Css/consumer/_myProfile.scss";
const cookies = new Cookies();
let homepagelocation = cookies.get('gph');
if (homepagelocation) homepagelocation = homepagelocation.replace('s:', '');
if (homepagelocation) homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home")
	homepagelocation = "/" + homepagelocation;

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

let updatedTitle = "";
let updatedCWL = "";
let updatedBuilding;
let updatedCubicle = "";
let updatedDateFormat = "";
let updatedTimeZone = "";
let updatedCurrency = "";
let updatedMostPopular = "";
let updatedNewLaunches = "";
let updatedComingSoon = "";
let updatedFree = "";
let updatedMyFavourites = "";
let updatedCategoryView = "";
let updatedFeatured = "";
let updateLanguage = "";
let updatedAlternatePhone = "";
let updatedAlternateEmail = "";
class MyProfileIndex extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			subMenu: '4',
			showHideAlfy: false,
			showHideRightNav: false,
			showSavedMessage: '',
			showLoader: false,
			alternateError: false,
			saveAlternatePhoneClicked: false,
			saveAlternateEmailClicked: false,
			saveBuildingClicked: false,
			saveCubicleClicked: false,
			saveCWLClicked: false,
			saveDateFormatClicked: false,
			saveTimeZoneClicked: false,
			saveCurrencyClicked: false,
			saveLanguagePreferencesClicked: false,
			saveTitleClicked: false
		};
		GLOBAL.searchbarcategory = 'All';

		this.doPost = this.doPost.bind(this);
		this.showHideAlfy = this.showHideAlfy.bind(this);
		this.showHideRightNav = this.showHideRightNav.bind(this);
		this.loadHome = this.loadHome.bind(this);
		this.showErrorMessage = this.showErrorMessage.bind(this);
		this.updateAlternatePhone = this.updateAlternatePhone.bind(this);
		this.updateAlternateEmail = this.updateAlternateEmail.bind(this);
		this.isSaveAlternatePhoneClicked = this.isSaveAlternatePhoneClicked.bind(this);
		this.isSaveAlternateEmailClicked = this.isSaveAlternateEmailClicked.bind(this);
		this.isSaveBuildingClicked = this.isSaveBuildingClicked.bind(this);
		this.isSaveCubicleClicked = this.isSaveCubicleClicked.bind(this);
		this.isSaveCWLClicked = this.isSaveCWLClicked.bind(this);
		this.isSaveDateFormatClicked = this.isSaveDateFormatClicked.bind(this);
		this.isSaveTimeZoneClicked = this.isSaveTimeZoneClicked.bind(this);
		this.isSaveCurrencyClicked = this.isSaveCurrencyClicked.bind(this);
		this.isSaveLanguagePreferencesClicked = this.isSaveLanguagePreferencesClicked.bind(this);
		this.isSaveTitleClicked = this.isSaveTitleClicked.bind(this);
		GLOBAL.buildingValueType = this.props.profileDetails.building;
		//alert("GLOBAL.buildingValueType"+GLOBAL.buildingValueType)
	}

	componentWillMount() {
		this.props.loadProfile();
	}
	showHideAlfy(value) {
		this.setState({ showHideAlfy: value });
	}

	showHideRightNav(value) {
		this.setState({ showHideRightNav: !this.state.showHideRightNav });
	}

	isSaveTitleClicked(value) {
		this.setState({ saveTitleClicked: value });
	}

	isSaveAlternatePhoneClicked(value) {
		this.setState({ saveAlternatePhoneClicked: value });
	}

	isSaveAlternateEmailClicked(value) {
		this.setState({ saveAlternateEmailClicked: value });
	}

	isSaveBuildingClicked(value) {
		this.setState({ saveBuildingClicked: value });
	}

	isSaveCubicleClicked(value) {
		this.setState({ saveCubicleClicked: value });
	}

	isSaveCWLClicked(value) {
		this.setState({ saveCWLClicked: value });
	}

	isSaveDateFormatClicked(value) {
		this.setState({ saveDateFormatClicked: value });
	}

	isSaveTimeZoneClicked(value) {
		this.setState({ saveTimeZoneClicked: value });
	}

	isSaveCurrencyClicked(value) {
		this.setState({ saveCurrencyClicked: value });
	}

	isSaveLanguagePreferencesClicked(value) {
		this.setState({ saveLanguagePreferencesClicked: value });
	}
	//methods to update profile starts
	updateTitle(title) {
		//alert("title"+title);
		updatedTitle = title
		//alert("this.state.updatedTitle"+updatedTitle);
	}

	updateCWL(CWL) {
		updatedCWL = CWL
	}

	updateBuilding(building) {
		//alert(building)
		updatedBuilding = building;
		//console.log("updatedBuilding"+updatedBuilding);
	}

	updateCubicle(cubicle) {
		updatedCubicle = cubicle;

	}

	updateDateFormat(dateFormat) {
		updatedDateFormat = dateFormat;

	}

	updateTimeZone(timeZone) {
		updatedTimeZone = timeZone;

	}

	updateCurrency(currency) {
		updatedCurrency = currency;

	}

	updateMostPopular(value) {
		updatedMostPopular = value;
	}

	updateNewLaunches(value) {
		updatedNewLaunches = value;
	}

	updateComingSoon(value) {
		updatedComingSoon = value;
	}

	updateFree(value) {
		updatedFree = value;
	}

	updateMyFavourites(value) {
		updatedMyFavourites = value;
	}

	updateCategoryView(value) {
		updatedCategoryView = value;
	}

	updateFeatured(value) {
		updatedFeatured = value;
	}

	updateLanguage(value) {
		updateLanguage = value;
	}

	updateAlternatePhone(phone) {
		updatedAlternatePhone = phone;
	}

	updateAlternateEmail(email) {
		updatedAlternateEmail = email;
	}
	//methods to update profile end

	//post updated data for user profile start
	doPost() {
		let titleValue = "";
		let CWLValue = "";
		let buildingValue = "";
		let cubicleValue = "";
		let dateFormatValue = "";
		let timeZoneValue = "";
		let baseCurrencyValue = "";
		let bestSellingValue = "";
		let newLaunchValue = "";
		let comingSoonValue = "";
		let freeValue = "";
		let myFavouriteValue = "";
		let catwiseValue = "";
		let featuredValue = "";
		let languageValue = "";
		let hometabPreferencesArrayList = [];

		this.isSaveTitleClicked(true);
		this.isSaveAlternatePhoneClicked(true);
		this.isSaveAlternateEmailClicked(true);
		this.isSaveBuildingClicked(true);
		this.isSaveCubicleClicked(true);
		this.isSaveCWLClicked(true);
		this.isSaveDateFormatClicked(true);
		this.isSaveTimeZoneClicked(true);
		this.isSaveCurrencyClicked(true);
		this.isSaveLanguagePreferencesClicked(true);
		//=================
		if (updatedTitle == "") {
			//alert("titleValue");
			titleValue = this.props.profileDetails.title;
		} else {
			titleValue = updatedTitle;
		}

		//=========================
		if (updatedCWL == "") {
			//alert("titleValue");
			CWLValue = this.props.profileDetails.cwlId;
		} else {
			CWLValue = updatedCWL
		}

		//==========================
		if (updatedBuilding == "") {
			//alert("building");
			buildingValue = "";
		} else {
			buildingValue = updatedBuilding;
			//alert(buildingValue);
		}

		//==========================
		if (updatedCubicle == "") {
			//alert("building");
			cubicleValue = "";
		} else {
			cubicleValue = updatedCubicle;
		}

		//==========================
		if (updatedDateFormat == "") {
			//alert("building");
			dateFormatValue = this.props.profileDetails.dateformat;
		} else {
			dateFormatValue = updatedDateFormat;
		}

		//==========================
		if (updatedTimeZone == "") {
			//alert("building");
			timeZoneValue = this.props.profileDetails.timezoneid;
		} else {
			timeZoneValue = updatedTimeZone;
		}

		//==========================
		if (updatedCurrency == "") {
			//alert("building");
			baseCurrencyValue = this.props.profileDetails.baseCurrency;
		} else {
			baseCurrencyValue = updatedCurrency;
		}

		//==========================
		if (updatedMostPopular == "") {
			//alert("building");
			bestSellingValue = this.props.profileDetails.bestSelling;
		} else {
			bestSellingValue = updatedMostPopular;
		}

		//==========================
		if (updatedNewLaunches == "") {
			//alert("building");
			newLaunchValue = this.props.profileDetails.newLaunch;
		} else {
			newLaunchValue = updatedNewLaunches;
		}

		//==========================
		if (updatedComingSoon == "") {
			//alert("building");
			comingSoonValue = this.props.profileDetails.comingSoon;
		} else {
			comingSoonValue = updatedComingSoon;
		}

		//==========================
		if (updatedFree == "") {
			//alert("building");
			freeValue = this.props.profileDetails.free;
		} else {
			freeValue = updatedFree;
		}

		//==========================
		if (updatedMyFavourites == "") {
			//alert("building");
			myFavouriteValue = this.props.profileDetails.myFavourite;
		} else {
			myFavouriteValue = updatedMyFavourites;
		}

		//==========================
		if (updatedCategoryView == "") {
			//alert("building");
			catwiseValue = this.props.profileDetails.catwise;
		} else {
			catwiseValue = updatedCategoryView;
		}

		//==========================
		if (updatedFeatured == "") {
			//alert("building");
			featuredValue = this.props.profileDetails.featured;
		} else {
			featuredValue = updatedFeatured;
		}

		//==========================
		if (updateLanguage == "") {
			//alert("building");
			languageValue = this.props.profileDetails.languagePreference;
		} else {
			languageValue = updateLanguage;
		}


		//===========================


		let userId = this.props.profileDetails.userId;
		let AlternatePhoneValue = "";
		let AlternateEmailValue = "";
		if (updatedAlternatePhone == "") {
			//alert("titleValue");
			AlternatePhoneValue = this.props.profileDetails.alternatePhone;
		}
		else {
			AlternatePhoneValue = updatedAlternatePhone;
			let phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
			if (phoneRegex.test(AlternatePhoneValue) != true) {
				this.setState({ showSavedMessage: this.props.tr['Please enter a valid phone number!'] });
				this.setState({ alternateError: true });
				return false;
			}
			else {
				this.setState({ showSavedMessage: '' });
				this.setState({ alternateError: false });
			}
		}
		//=================
		if (updatedAlternateEmail == "") {
			//alert("titleValue");	
			AlternateEmailValue = this.props.profileDetails.alternateEmail;
		}
		else {
			AlternateEmailValue = updatedAlternateEmail;
			if (EmailValidator.validate(AlternateEmailValue) == false) {
				this.setState({ showSavedMessage: this.props.tr['Please enter a valid email address!'] });
				this.setState({ alternateError: true });
				return false;
			}
			else {
				this.setState({ showSavedMessage: '' });
				this.setState({ alternateError: false });
			}
		}

		//alert(updatedTitle+":"+updatedCWL+":"+updatedBuilding+":"+updatedCubicle+":"+updatedDateFormat+""+updatedTimeZone+":"+updatedCurrency)
		//alert(buildingValue)

		// if (updatedTitle == "" && updatedCWL == "" && (updatedBuilding == "" || updatedBuilding == undefined) && updatedCubicle == "" &&
		// 	updatedDateFormat == "" && updatedTimeZone == "" && updatedCurrency == "" && updatedMostPopular == "" && updatedNewLaunches == "" && 
		// 	updatedComingSoon == "" && updatedFree == "" && updatedMyFavourites == "" && updatedCategoryView == "" && updatedFeatured == "" && 
		// 	updateLanguage == "" && updatedAlternatePhone=="" && updatedAlternateEmail=="") {
		// 		//alert("if")
		// 	this.setState({ showSavedMessage: this.props.tr['Nothing to update'] });
		// } 
		// else 
		if (featuredValue == "N" && bestSellingValue == "N") {
			this.setState({ showSavedMessage: this.props.tr['Please select either Most Popular or Featured to proceed further'] });
			this.setState({ alternateError: true });
		}
		else {
			hometabPreferencesArrayList = [
				{ "name": "Best Selling", "id": "bestSelling", "value": bestSellingValue },
				{ "name": "New Launches", "id": "newLaunch", "value": newLaunchValue },
				{ "name": "Coming Soon", "id": "comingSoon", "value": comingSoonValue },
				{ "name": "Free", "id": "free", "value": freeValue },
				{ "name": "My Favourites", "id": "myFavourite", "value": myFavouriteValue },
				{ "name": "Category View", "id": "catwise", "value": catwiseValue },
				{ "name": "Featured", "id": "featured", "value": featuredValue }];
			//alert("else")
			this.setState({ showLoader: true });
			api.put(GLOBAL.profilePostUrl + userId, {
				"userId": userId,
				"title": titleValue,
				"cwlId": CWLValue,
				"building": buildingValue,
				"cubicle": cubicleValue,
				"dateformat": dateFormatValue,
				"timeZone": timeZoneValue,
				"baseCurrency": baseCurrencyValue,
				"languagePreference": languageValue,
				"hometab_preference": hometabPreferencesArrayList,
				"alternatePhone": AlternatePhoneValue,
				"alternateEmail": AlternateEmailValue
			}).
				then((response) => {
					//alert("Data successfully submitted")
					//browserHistory.push("/myProfile");
					this.props.loadProfile();
					//updatedTitle=""; updatedCWL=""; updatedBuilding=""; updatedCubicle="";
					updatedDateFormat = ""; updatedTimeZone = ""; updatedCurrency = "";
					updatedMostPopular = ""; updatedNewLaunches = ""; updatedComingSoon = ""; updatedFree = "";
					updatedMyFavourites = ""; updatedCategoryView = ""; updatedFeatured = ""; updateLanguage = "";
					updatedAlternateEmail = ""; updatedAlternatePhone = "";

					if (response.status === 200) {
						this.setState({
							showSavedMessage: this.props.tr['Profile has been updated successfully'],
							showLoader: false,
							alternateError: false
						});
					}
				});
		}

	}

	loadHome() {
		this.props.navigate(homepagelocation);
	}

	showErrorMessage(message) {
		this.setState({ showSavedMessage: message });
	}

	render() {
		//alert("render"+this.buildingValue);
		return (
			<main>
				{/* <div className="gloSearchDiv"><SxSearch tr={this.props.tr} /></div> */}

				{/* <RightSidebarMenulist showhideClass={this.state.showHideRightNav} pageId={'24'} /> */}

				<div >
					<PindCategories />
					<div className="container-fluid margin-b-15">
						<nav aria-label="Breadcrumb"><ul className="breadcrumb"><li className="breadcrumb-item"><a href='#' role="button" onClick={this.loadHome} >{this.props.tr["Home"]}</a></li><li className="breadcrumb-item active">{this.props.tr["My Profile"]}</li></ul></nav>
					</div>
					<div className="container-fluid" bsClass="">
						<Form>
							{/* Home Page blue bar Section Start*/}
							<MyProfileHeader navigate={(location)=>{this.props.navigate(location);}} doPost={this.doPost.bind(this)} tr={this.props.tr} showLoader={this.state.showLoader} />
							{/* Home Page blue bar Section end*/}

							{this.state.showSavedMessage == "" ? null : <Alert variant={this.state.alternateError ? "danger" : "success"} className="errorMessageSec text-l"><p>{this.state.showSavedMessage}</p></Alert>}

							<Row aria-label='User Info' role="contentinfo" className="disFlex">
								<Col lg={3} md={4} sm={12} xs={12}>
									<ProfileImg loadProfile={this.props.loadProfile} profileDetails={this.props.profileDetails} tr={this.props.tr} />
								</Col>
								<Col lg={9} md={8} sm={12} xs={12}>
									<div className="userInfo">
										<Row>
											<Col lg={6} md={6} sm={6} xs={12}>
												<LeftSideFormTab showMsg={this.state.showSavedMessage} showErrorMessage={this.showErrorMessage} isSaveTitleClicked={this.isSaveTitleClicked} saveTitleClicked={this.state.saveTitleClicked} buildingValue={this.buildingValue} profileDetails={this.props.profileDetails} doValidate={this.doValidate} updateTitle={this.updateTitle.bind(this)} updateCWL={this.updateCWL.bind(this)} updateBuilding={this.updateBuilding.bind(this)} updateCubicle={this.updateCubicle.bind(this)} updateDateFormat={this.updateDateFormat.bind(this)} updateTimeZone={this.updateTimeZone.bind(this)} updateCurrency={this.updateCurrency.bind(this)} updateMostPopular={this.updateMostPopular.bind(this)} updateNewLaunches={this.updateNewLaunches.bind(this)} updateComingSoon={this.updateComingSoon.bind(this)} updateFree={this.updateFree.bind(this)} updateMyFavourites={this.updateMyFavourites.bind(this)} updateCategoryView={this.updateCategoryView.bind(this)} updateFeatured={this.updateFeatured.bind(this)} updateLanguage={this.updateLanguage.bind(this)} tr={this.props.tr} />
											</Col>
											<Col lg={6} md={6} sm={6} xs={12}>
												<RightSideAction showErrorMessage={this.showErrorMessage} loadProfile={this.props.loadProfile} isSaveAlternatePhoneClicked={this.isSaveAlternatePhoneClicked} isSaveAlternateEmailClicked={this.isSaveAlternateEmailClicked} isSaveBuildingClicked={this.isSaveBuildingClicked} isSaveCubicleClicked={this.isSaveCubicleClicked} profileDetails={this.props.profileDetails} tr={this.props.tr} saveAlternatePhoneClicked={this.state.saveAlternatePhoneClicked} saveAlternateEmailClicked={this.state.saveAlternateEmailClicked} updateAlternatePhone={this.updateAlternatePhone} updateAlternateEmail={this.updateAlternateEmail} isSaveCWLClicked={this.isSaveCWLClicked} isSaveDateFormatClicked={this.isSaveDateFormatClicked} isSaveTimeZoneClicked={this.isSaveTimeZoneClicked} isSaveCurrencyClicked={this.isSaveCurrencyClicked} isSaveLanguagePreferencesClicked={this.isSaveLanguagePreferencesClicked} saveCWLClicked={this.state.saveCWLClicked} saveDateFormatClicked={this.state.saveDateFormatClicked} saveTimeZoneClicked={this.state.saveTimeZoneClicked} saveCurrencyClicked={this.state.saveCurrencyClicked} saveLanguagePreferencesClicked={this.state.saveLanguagePreferencesClicked} />
											</Col>
										</Row>
									</div>
								</Col>
							</Row>
							<BottomSection showMsg={this.state.showSavedMessage} showErrorMessage={this.showErrorMessage} buildingValue={this.buildingValue} isSaveAlternatePhoneClicked={this.isSaveAlternatePhoneClicked} isSaveAlternateEmailClicked={this.isSaveAlternateEmailClicked} isSaveBuildingClicked={this.isSaveBuildingClicked} isSaveCubicleClicked={this.isSaveCubicleClicked} saveBuildingClicked={this.state.saveBuildingClicked} saveCubicleClicked={this.state.saveCubicleClicked} isSaveCWLClicked={this.isSaveCWLClicked} isSaveDateFormatClicked={this.isSaveDateFormatClicked} isSaveTimeZoneClicked={this.isSaveTimeZoneClicked} isSaveCurrencyClicked={this.isSaveCurrencyClicked} isSaveLanguagePreferencesClicked={this.isSaveLanguagePreferencesClicked} saveCWLClicked={this.state.saveCWLClicked} saveDateFormatClicked={this.state.saveDateFormatClicked} saveTimeZoneClicked={this.state.saveTimeZoneClicked} saveCurrencyClicked={this.state.saveCurrencyClicked} saveLanguagePreferencesClicked={this.state.saveLanguagePreferencesClicked} profileDetails={this.props.profileDetails} doValidate={this.doValidate} updateTitle={this.updateTitle.bind(this)} updateCWL={this.updateCWL.bind(this)} updateBuilding={this.updateBuilding.bind(this)} updateCubicle={this.updateCubicle.bind(this)} updateDateFormat={this.updateDateFormat.bind(this)} updateTimeZone={this.updateTimeZone.bind(this)} updateCurrency={this.updateCurrency.bind(this)} updateMostPopular={this.updateMostPopular.bind(this)} updateNewLaunches={this.updateNewLaunches.bind(this)} updateComingSoon={this.updateComingSoon.bind(this)} updateFree={this.updateFree.bind(this)} updateMyFavourites={this.updateMyFavourites.bind(this)} updateCategoryView={this.updateCategoryView.bind(this)} updateFeatured={this.updateFeatured.bind(this)} updateLanguage={this.updateLanguage.bind(this)} tr={this.props.tr} />
						</Form>
					</div>
				</div>
			</main>
		);
	}
}
export function mapStateToProps({ profileDetails, spcmReducer }) {
	//alert("profileDetails:"+profileDetails);
	// console.log(profileDetails)
	//console.log("breakFixEditDetails:"+breakFixEditDetails.serviceId);
	return { profileDetails, lang: spcmReducer.lang, tr: spcmReducer.tr };
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ loadProfile, getTranslation }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(MyProfileIndex);

//export default BreakFix;
