
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loadSlaWorkdayListOnPagination } from "_Actions/sla/quickViewAction.js";
import Pagination from "react-js-pagination";
import ListLoader from "_Commons/loaders/ListLoader";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import _ from "lodash";
import ReactDOM from "react-dom";
import { loadTimeZone } from "_Actions/common/commonApisActions";
import { loadSlaWrkDayScheduleStatus } from "_Actions/sla/lovAction";
import {IoSearch} from 'react-icons/io5';

class XsmSlaQuickViewList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      table_size: 10,
      scheduleSearchInput: "",
      companySearchInput: "",
      scheduleSearchFilter: "",
      companySearchFilter: "",
      isMultiSelectOpen: true,
    };
    this.onRowSelect = this.onRowSelect.bind(this);
    this.renderSlaWorkdayTableListOnPagination =
      this.renderSlaWorkdayTableListOnPagination.bind(this);
    this.onPageSizeChangeBreakFix = this.onPageSizeChangeBreakFix.bind(this);
    this.onPageChangeBreakFix = this.onPageChangeBreakFix.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleTimeZoneChange = this.handleTimeZoneChange.bind(this);
    this.clickOutside = this.clickOutside.bind(this);
  }

  componentDidMount() {
    this.props.loadTimeZone();
    this.props.loadSlaWrkDayScheduleStatus("status");
    document.addEventListener('mousedown', this.clickOutside);
  }

  componentDidUpdate(prevProps) {
    if (this.multiSelectTimeZoneDropdownRef != undefined || this.multiSelectTimeZoneDropdownRef != null) {
      ReactDOM.findDOMNode(this.multiSelectTimeZoneDropdownRef).childNodes[0].className = "multiSelectRef";
      let myRef = this.multiSelectTimeZoneDropdownRef;
      if (this.props.isTimeZoneOpen != prevProps.isTimeZoneOpen) {
        let obj = ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1];
        if (obj != undefined || obj != null) {
          ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1].className = "multiSelectPopup";
        }
      }
    }
    if (this.multiSelectStatusDropdownRef != undefined || this.multiSelectStatusDropdownRef != null) {
      ReactDOM.findDOMNode(this.multiSelectStatusDropdownRef).childNodes[0].className = "multiSelectRef";
      let myRef = this.multiSelectStatusDropdownRef;
      if (this.props.isStatusOpen != prevProps.isStatusOpen) {
        let obj = ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1];
        if (obj != undefined || obj != null) {
          ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1].className = "multiSelectPopup";
        }
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.clickOutside);
  }

  clickOutside(event) {
    const timeZoneDv = document.getElementById("timeZoneDv");
    const statusDv = document.getElementById("statusDv");

    if (this.multiSelectTargetDropdownRef != undefined || this.multiSelectTargetDropdownRef != null ||
      this.multiSelectStatusDropdownRef != undefined || this.multiSelectStatusDropdownRef != null  ||
      this.multiSelectModuleDropdownRef != undefined || this.multiSelectModuleDropdownRef != null) {
      document.addEventListener("mousedown", (event) => {
        if (timeZoneDv.contains(event.target)) {
          // console.log("TYPE CLICKED");
          this.setState({ isMultiSelectOpen: true });
          this.props.showRightSide(false);
        }
        else if (statusDv.contains(event.target)) {
          this.setState({ isMultiSelectOpen: true });
          this.props.showRightSide(false);
        }
        else {
          this.setState({ isMultiSelectOpen: false });
        }
        this.props.multiSelectOpenFun("allFalse", false);
      });
    }
  }

  onRowSelect(e, row, index) {
    this.props.getSlaWorkdayDetails(
      row.scheduleId,
      row.scheduleCode,
      row.scheduleName,
      row.companyName,
      row.status,
      row.workdayScheduleList,
      row.workdayScheduleTimeZoneLabel
    );
    this.props.showActiveRow(index);
    this.props.showRightSide(true);
  }

  renderSlaWorkdayTableListOnPagination(slaData) {
    let data = slaData.data;
    return data.map((data, index) => {
      return (
        <tr
          className={
            this.props.isRightSideVisible && index == this.props.rowIndex
              ? "myActive"
              : ""
          }
          onClick={(e) => this.onRowSelect(e, data, index)}
        >
          <td title={data.scheduleName}>
            {data.scheduleName.slice(0, 70)}
            {data.scheduleName.length > 70 ? "..." : ""}
          </td>
          <td title={data.companyName}>
            {data.companyName.slice(0, 70)}
            {data.companyName.length > 70 ? "..." : ""}
          </td>
          <td>{data.status}</td>
          <td>{data.workdayScheduleTimeZoneLabel}</td>
        </tr>
      );
    });
  }

  onPageSizeChangeBreakFix(e) {
    let size = parseInt(e.target.value, 10);
    this.setState({ table_size: size });
    this.props.setPageDetails(0, size);
    let searchParams = {};
    searchParams.searchByList = this.props.searchKey;
    searchParams.multipleValueList = this.props.searchValue;
    searchParams.patternList = this.props.pattern;
    searchParams.currentPage = 0;
    searchParams.size = size;
    searchParams.orderBy = "desc";
    searchParams.sortBy = "scheduleId";
    this.props.loadSlaWorkdayListOnPagination(
      searchParams,
      this.props.statusValue,
      this.props.timeZoneValue
    );
  }

  onPageChangeBreakFix(e) {
    this.props.setPageDetails(e - 1, this.props.pageSize);
    let searchParams = {};
    searchParams.searchByList = this.props.searchKey;
    searchParams.multipleValueList = this.props.searchValue;
    searchParams.patternList = this.props.pattern;
    searchParams.currentPage = e - 1;
    searchParams.size = this.props.pageSize;
    searchParams.orderBy = "desc";
    searchParams.sortBy = "scheduleId";
    this.props.loadSlaWorkdayListOnPagination(
      searchParams,
      this.props.statusValue,
      this.props.timeZoneValue
    );
  }

  handleSearch() {
    let searchByValue = [];
    let searchByVar = [];
    let pattern = [];
    this.props.isClearAllFilterFunc(true);

    if (this.props.scheduleSearchInput != "") {
      searchByValue.push(this.props.scheduleSearchInput.trim());
      searchByVar.push(this.state.scheduleSearchFilter);
      pattern.push("like");
    }
    if (this.props.companySearchInput != "") {
      searchByValue.push(this.props.companySearchInput.trim());
      searchByVar.push(this.state.companySearchFilter);
      pattern.push("like");
    }
    let searchParams = {};
    searchParams.searchByList = searchByVar.join(",");
    searchParams.multipleValueList = searchByValue.join("|");
    searchParams.patternList = pattern.join(",");
    searchParams.currentPage = 0;
    searchParams.size = this.props.pageSize;
    searchParams.orderBy = "desc";
    searchParams.sortBy = "scheduleId";
    this.props.setSearchParams(
      searchParams,
      searchByVar.join(","),
      searchByValue.join("|"),
      pattern.join(","),
      this.props.statusValue,
      this.props.timeZoneValue
    );
    this.props.loadSlaWorkdayListOnPagination(
      searchParams,
      this.props.statusValue,
      this.props.timeZoneValue
    );
  }

  handleStatusChange(value) {
    let searchOnStatusArr = [];
    this.props.searchKey.length == 0 && this.props.searchValue.length == 0
      ? this.props.timeZoneValue == 0
        ? value.length == 0
          ? this.props.isClearAllFilterFunc(false)
          : this.props.isClearAllFilterFunc(true)
        : this.props.isClearAllFilterFunc(true)
      : this.props.isClearAllFilterFunc(true);
    this.props.setSelectedStatusValue(value);
    value.map((checkBoxValue) => {
      return searchOnStatusArr.push(checkBoxValue.label);
    });
    let searchParams = {};
    searchParams.searchByList = this.props.searchKey;
    searchParams.multipleValueList = this.props.searchValue;
    searchParams.patternList = this.props.pattern;
    searchParams.currentPage = 0;
    searchParams.size = this.props.pageSize;
    searchParams.orderBy = "desc";
    searchParams.sortBy = "scheduleId";
    this.props.setSearchParams(
      this.props.searchParamsValue,
      this.props.searchKey,
      this.props.searchValue,
      this.props.pattern,
      searchOnStatusArr,
      this.props.timeZoneValue
    );
    this.props.loadSlaWorkdayListOnPagination(
      searchParams,
      searchOnStatusArr.length == 0 ? ["Active"] : searchOnStatusArr,
      this.props.timeZoneValue
    );
  }

  handleTimeZoneChange(value) {
    let searchOnTimeZoneArr = [];
    this.props.searchKey.length == 0 && this.props.searchValue.length == 0
      ? this.props.statusValue == 0
        ? value.length == 0
          ? this.props.isClearAllFilterFunc(false)
          : this.props.isClearAllFilterFunc(true)
        : this.props.isClearAllFilterFunc(true)
      : this.props.isClearAllFilterFunc(true);
    this.props.setSelectedTimeZoneValue(value);
    value.map((checkBoxValue) => {
      return searchOnTimeZoneArr.push(checkBoxValue.label);
    });
    let searchParams = {};
    searchParams.searchByList = this.props.searchKey;
    searchParams.multipleValueList = this.props.searchValue;
    searchParams.patternList = this.props.pattern;
    searchParams.currentPage = 0;
    searchParams.size = this.props.pageSize;
    searchParams.orderBy = "desc";
    searchParams.sortBy = "scheduleId";
    this.props.setSearchParams(
      this.props.searchParamsValue,
      this.props.searchKey,
      this.props.searchValue,
      this.props.pattern,
      this.props.statusValue,
      searchOnTimeZoneArr
    );
    this.props.loadSlaWorkdayListOnPagination(
      searchParams,
      this.props.statusValue,
      searchOnTimeZoneArr
    );
  }

  handleChange(e, searchValue) {
    switch (searchValue) {
      case "scheduleName":
        this.props.setScheduleSearchInput(e.target.value);
        this.setState({ scheduleSearchFilter: searchValue });
        break;
      case "companyName":
        this.props.setCompanySearchInput(e.target.value);
        this.setState({ companySearchFilter: searchValue });
        break;
    }
  }
  render() {
    let meta = !_.isEmpty(this.props.slaWorkdayList)
      ? this.props.slaWorkdayList.meta
      : {};
    let timeZonemap = new Map();
    let statusmap = new Map();
    let timeZoneOptions = [];
    let statusOptions = [];
    let caseResponsive =
      this.props.slaWorkdayList == {} ||
      this.props.slaWorkdayList == undefined ||
      this.props.slaWorkdayList.data == undefined ||
      this.props.slaWorkdayList.data?.length <= 6 ||
      this.props.show_loader == true
        ? "overFlowClp"
        : "table-responsive";

    timeZonemap = new Map(Object.entries(this.props.timeZoneData));
    for (let [key, value] of timeZonemap) {
      let obj = {};
      obj.label = value.field2Value;
      obj.value = value.field1Key;
      timeZoneOptions.push(obj);
    }

    statusmap = new Map(Object.entries(this.props.slaWorkdaySchedulesStatus));
    for (let [key, value] of statusmap) {
      let obj = {};
      obj.label = value.field1Key;
      obj.value = value.field1Value;
      statusOptions.push(obj);
    }
    
    let panelColSize = this.props.panelResize;

    if (this.props.isRightSideVisible == true) {
      this.multiSelectStatusDropdownRef.childNodes[0].childNodes[0].addEventListener('click', () => {
        this.props.showRightSide(false);
      });
      this.multiSelectTimeZoneDropdownRef.childNodes[0].childNodes[0].addEventListener('click', () => {
        this.props.showRightSide(false);
      });
    }

    return (
      <div>
        <div className= {this.props.slaWorkdayList == {} || this.props.slaWorkdayList == undefined || this.props.slaWorkdayList.data == undefined || this.props.slaWorkdayList.data?.length <= 6 || this.props.show_loader == true ? "respondv responDvMinH" : "respondv"}>
          <div className={"tableRgtBor " + caseResponsive}>
            <Table
              striped
              bordered
              condensed
              hover
              className="tableView nowrapWhright"
              style={{ marginTop: "0px" }}
            >
              <thead>
                <tr>
                  <th>
                    <div className="sortParArr">
                      {this.props.translator["Schedule"]}
                    </div>
                    <div className="colSearDv">
                      <Form.Control
                        className="colSearInp"
                        type="text"
                        value={
                          this.props.scheduleSearchInput != ""
                            ? this.props.scheduleSearchInput
                            : ""
                        }
                        placeholder={this.props.translator["Search here"]}
                        onKeyPress={(e) => {
                          if (e.charCode == "13") {
                            if (this.props.scheduleSearchInput.trim().length > 3)
                              this.handleSearch();
                          }
                        }}
                        onChange={(event) => {
                          this.handleChange(event, "scheduleName");
                        }}
                        onClick={() => this.props.multiSelectOpenFun("allFalse", false)}
                      />
                      {this.props.scheduleSearchInput.trim().length > 3 ? (
                        <a
                          title="search"
                          className="faicn"
                          href={void(0)}
                          onClick={(event) => this.handleSearch()}
                        >
                          <IoSearch/>
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </th>
                  <th>
                    <div className="sortParArr">
                      {this.props.translator["Company"]}
                    </div>
                    <div className="colSearDv">
                      <Form.Control
                        className="colSearInp"
                        type="text"
                        value={
                          this.props.companySearchInput != ""
                            ? this.props.companySearchInput
                            : ""
                        }
                        placeholder={this.props.translator["Search here"]}
                        onKeyPress={(e) => {
                          if (e.charCode == "13") {
                            if (
                              this.props.companySearchInput != "" &&
                              this.props.companySearchInput.trim().length >= 3
                            )
                              this.handleSearch();
                          }
                        }}
                        onChange={(event) => {
                          this.handleChange(event, "companyName");
                        }}
                        onClick={() => this.props.multiSelectOpenFun("allFalse", false)}
                      />
                      {this.props.companySearchInput.trim().length >= 3 ? (
                        <a
                          title="search"
                          className="faicn"
                          href={void(0)}
                          onClick={(event) => this.handleSearch()}
                        >
                          <IoSearch/>
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </th>
                  <th>
                    <div className="sortParArr">
                      {this.props.translator["Status"]}
                    </div>
                    <div
                      id="statusDv"
                      className="colSearDv"
                      ref={(e) => (this.multiSelectStatusDropdownRef = e)} 
                      onClick={() => this.props.multiSelectOpenFun("slaWrkDayStatus", true)}
                    >
                      <ReactMultiSelectCheckboxes
                        placeholderButtonLabel={this.props.translator["Select"]}
                        rightAligned={true}
                        options={statusOptions}
                        value={this.props.selectedStatusValue}
                        onChange={this.handleStatusChange}
                      />
                    </div>
                  </th>
                  <th>
                    <div className="sortParArr">
                      {this.props.translator["Time Zone"]}
                    </div>
                    <div
                      id="timeZoneDv"
                      className="colSearDv"
                      ref={(e) => (this.multiSelectTimeZoneDropdownRef = e)}
                      onClick={() => this.props.multiSelectOpenFun("slaWrkDayTimeZone", true)}
                    >
                      <ReactMultiSelectCheckboxes
                        placeholderButtonLabel={this.props.translator["Select"]}
                        rightAligned={true}
                        options={timeZoneOptions}
                        value={this.props.selectedTimeZoneValue}
                        onChange={this.handleTimeZoneChange}
                      />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.slaWorkdayList == {} ||
                this.props.slaWorkdayList == undefined ||
                this.props.slaWorkdayList.data == undefined ||
                this.props.slaWorkdayList.data.length == undefined ||
                this.props.show_loader == true ? (
                  <tr>
                    <td colSpan="5" style={{ textAlign: "center" }}>
                      <ListLoader />
                    </td>
                  </tr>
                ) : this.props.slaWorkdayList.data.length == 0 ? (
                  <tr>
                    <td colSpan="5" style={{ textAlign: "center" }}>
                      {
                        this.props.translator[
                          "There is no matching data available"
                        ]
                      }
                    </td>
                  </tr>
                ) : (
                  this.renderSlaWorkdayTableListOnPagination(
                    this.props.slaWorkdayList
                  )
                )}
              </tbody>
            </Table>
          </div>
          <div className="nBotPagina">
            <div className="nShow">
              <div className="margin-r-10">
                {this.props.translator["Show"]}:
              </div>
              <Form.Select
                value={this.props.pageSize}
                onChange={this.onPageSizeChangeBreakFix}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                {/* <option value="100">100</option> */}
              </Form.Select>
            </div>
            <nav aria-label="Pagination" className="display-inline-block">
              <Pagination
                prevPageText="Prev"
                nextPageText="Next"
                firstPageText={<i className="glyphicon glyphicon-menu-left" />}
                lastPageText={<i className="glyphicon glyphicon-menu-right" />}
                activePage={meta ? meta.currentPage + 1 : 1}
                itemsCountPerPage={this.props.pageSize}
                totalItemsCount={meta ? meta.recordCount : 1}
                pageRangeDisplayed={panelColSize <= 42 ? 2 : 5}
                onChange={(e) => this.onPageChangeBreakFix(e)}
              />
            </nav>
          </div>
        </div>
      </div>
    );
    // }
  }
}

export function mapStateToProps({
  slaWorkdayList,
  showLoader_SLA_Work_Schedule_Pagination,
  slaWorkdaySchedulesStatus,
  timeZoneData,
}) {
  return {
    slaWorkdayList,
    show_loader: showLoader_SLA_Work_Schedule_Pagination.loading,
    slaWorkdaySchedulesStatus,
    timeZoneData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadSlaWorkdayListOnPagination,
      loadTimeZone,
      loadSlaWrkDayScheduleStatus,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(XsmSlaQuickViewList);
