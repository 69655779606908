
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/


import initialState from '../../initialState';

export const onCallCalendarEventList = (state = {}, action) => {
  switch (action.type) {
    case "ON_CALL_CALENDER_EVENT_DATA_LIST":
      return {data:action.events.data,itemsCount:action.events.numberOfItems,pages:action.events.numberOfPages};
    default:
      return state;
  }
};

export const shiftListByGroupId = (state = {}, action) => {
    switch (action.type) {
      case "ON_CALL_CALENDER_SHIFT_LIST_DATA_BY_GROUP":
        return action.events.data;
      default:
        return state;
    }
  };
