
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import {Row, Col, Form, FormControl, Alert, ButtonToolbar, Button} from 'react-bootstrap';
import { _textArea, _dropDown, _inputField} from '../../common/formFields';
import { Field, reduxForm,SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import axios from 'axios';
import { spcmLoader,setLeftEditId,getServiceDetailCounts,getRelatedOfferings,getConsumptionItems,getAllDeployedServicesList } from '../../../actions/spcmActions';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import swal from 'sweetalert';
import {ImSpinner6} from "react-icons/im";
// import { empty } from 'joi';

class ComponentForm extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			related_offering_id: '',
			related_offering_name: ''
		};
		this.onCompanySelect = this.onCompanySelect.bind(this);
		this.onGroupSelect = this.onGroupSelect.bind(this);
		this.onRoleChange = this.onRoleChange.bind(this);
		this.submitForm = this.submitForm.bind(this);
	}
	componentWillMount(){
		this.props.getAllDeployedServicesList ();

	}

	componentWillUnmount(){
		this.props.spcmLoader('attribute','success');
	}


	onRoleChange(e){
		this.props.getChainEntitiesCompanyList("",e.target.value);
	}
	
	serviceSelection(e){
		if(e.length>0){
	    	this.setState({related_offering_id: e[0].OFFERING_ID});
	    	this.setState({related_offering_name: e[0].OFFERING_NAME});
		}
		else{
			this.setState({related_offering_id: ''});
	    	this.setState({related_offering_name: ''});
        
		}
	}

	onCompanySelect(e){
			this.props.getGroupList(e.target.value);
	}

	onGroupSelect(e){
			this.props.getManagerList(e.target.value);
	}

	submitForm(values){
		values.offering_id=this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID;
		values.offering_name= this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_NAME;
	    values.related_offering_id= this.state.related_offering_id;
		values.related_offering_name= this.state.related_offering_name;
		if(values.related_offering_id === '' || values.related_offering_name === '') {
			swal({
				text: this.props.tr['Please select an offering.'],
				button: this.props.tr["OK"],
			  });
			return false;
		}
		this.props.spcmLoader('attribute','posting');
		return axios.post("/service/api/relatedOfferings",values).then((response) => {
			this.props.getRelatedOfferings(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
			this.props.componentFormFun4(false);
			this.props.spcmLoader('attribute','success');
		})
		.catch((error) => {
			this.props.spcmLoader('attribute','success');
			throw new SubmissionError({_error: error.response.data });
			// throw new SubmissionError({_error: error.response.data[0].message });
		});	
	}
	
	render(){
		const tr=this.props.tr;
		const {error,handleSubmit} = this.props;
		let allDeployedServicesList = this.props.spcmReducer.allDeployedServicesList;
		let meta = this.props.spcmReducer.allServicesMeta;
		return(
			<div className="rBoxGap">
				<form  onSubmit={handleSubmit(this.submitForm)}>
								  {error == undefined || error == null ? null :  <Alert variant="danger" className="errorMessageSec"><p>{error}</p></Alert>}

					<Row>
						<Col md={12}>
							<Form.Group className='dvTypehd form-group'>
							<Form.Label bsClass=""><span className="rStar" ></span> {tr['Select Offering']}</Form.Label>
							<Typeahead

										onChange={(e) => { this.serviceSelection(e) }}
										className="serviceSearchType"
										options={allDeployedServicesList}
										labelKey={(option) => `${option.OFFERING_NAME}`}
									/>
							</Form.Group>
						</Col>
					</Row>

					<Row>
						<Col md={12}>
							
							<ButtonToolbar className="black">
								<Button className='rgSidrkBtn' title={tr['Save']} disabled={this.props.spcmReducer.spcmPostingAttribute} type="submit" bsSize="small" bsStyle="primary">{this.props.spcmReducer.spcmPostingAttribute ? <ImSpinner6 className="icn-spinner"/> : null } {tr['Save']}</Button>
								<Button className='rgSiLigBtn' title="Close" onClick={()=>{this.props.componentFormFun4(false)}} bsSize="small" bsStyle="text">{tr['Close']}</Button></ButtonToolbar> 
						</Col>
					</Row>
				</form>
			</div>
		);
	}
}

const mapStateToProps = ({spcmReducer}) => {
	return {
		spcmReducer,
		initialValues:spcmReducer.editLeftId>0?spcmReducer.editLeftService:''
	}
};

ComponentForm=reduxForm({
	form:'relatedOferingsForm',
	enableReinitialize:true
})(ComponentForm)
export default  connect(mapStateToProps,{ spcmLoader,setLeftEditId,getServiceDetailCounts,getRelatedOfferings,getConsumptionItems,getAllDeployedServicesList })(ComponentForm);
