
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { Link } from 'react-router-dom';


import { GLOBAL } from "_Globals";

import { loadChangeRelatedWorkDetails, clearChangeRelatedWorkDetails } from '../../../../actions/changeManagement/changeRelatedWorkAction';
import { loadChangeRelatedDataDetails } from '../../../../actions/changeManagement/changeRelatedDataAction';
import {loadrelatedWorkitems} from '../../../../actions/changeManagement/changeRelatedDataAction';
import Swal from 'sweetalert2';
import { setWipSearchApply } from '../../../../actions/globalSearch/globalSearchAction';
import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";
import { HiPlus } from "react-icons/hi";
import { PiListBulletsBold } from 'react-icons/pi';

const MoreArrow = <i title="More" className="fa fa-angle-double-down f-size-15" ></i>;

class PopoverWorkItemsData extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
			showUserData: false,
			messagedisplay: true,
			toggleFormAndList: 'list',
			showLoader: false,
			textEntered:'',
			disablePostButton: false,
		};
		GLOBAL.relatedfilterval = "";
		GLOBAL.checkmoduleval = "ChangeManagement";
		this.toggleFormAndList = this.toggleFormAndList.bind(this);
		this.setSearchApplyFalse = this.setSearchApplyFalse.bind(this);
	}
    toggleFormAndList() {
		GLOBAL.relatedfiltertext='';
		if (this.state.toggleFormAndList == 'list')
			this.setState({ toggleFormAndList: 'form',textEntered:'' });
		else
			this.setState({ toggleFormAndList: 'list',textEntered:'' });
	}
    componentWillMount() {
		let itemId = this.props.itemId;
		this.props.loadChangeRelatedDataDetails(itemId);
		GLOBAL.relatedtextdisplay = <div className="f-size-18 margin-b-5">{this.props.translator['Related Work Items']}</div>
		this.setState({ messagedisplay: true });
	}
    componentWillUnmount(){
        this.props.changeRelatedWorkDetails.length=0;
        this.renderRelatedWorkLog(this.props.changeRelatedWorkDetails); 
    }
    postRelatedData(brkid, modnumber, modulesearchparam) {
		this.setState({ showLoader: true });
		this.setState({ disablePostButton: true });
		let itemId = this.props.itemId;
		axios.post(GLOBAL.breakFixRelatedLogPostUrl, {
			"itemId": itemId,
			"status": "A",
			"createdOn": "",
			"createdBy": "",
			"modifiedOn": "",
			"modifiedBy": 0,
			"module": "ChangeManagement",
			"workItemId": brkid,
			"workModule": modulesearchparam,
			"workItemNumber": modnumber,
			"createdByName": "",
			"relationType": "p2p"
		}).then((response) => {
				this.props.loadChangeRelatedWorkDetails("changeNumber", "-1", "ChangeManagement");
				GLOBAL.relatedtextdisplay = <div className="f-size-18 margin-b-5">{this.props.translator['Related Work Items']}</div>
				this.props.loadChangeRelatedDataDetails(itemId);
				if((this.props.editChangeInitialData.type===30 || this.props.editChangeInitialData.type==='30') && GLOBAL.checkmoduleval==='Breakfix'){
					this.props.loadrelatedWorkitems(this.props.editChangeInitialData.changeId,'Breakfix');
				}
				this.setState({ messagedisplay: true });
				this.setState({ disablePostButton: false });
				this.setState({ toggleFormAndList: 'list' });
		});
	}
    deleteRelation(relationId,workModule) {
		let itemId = this.props.itemId;
		axios.delete(GLOBAL.relatedLogDeleteUrl + relationId).then((response) => {
				if (!response || response.status != '200') {
					Swal.fire('Deletion failed.');
				}
				else {
					Swal.fire('deleted relation');
					this.props.loadChangeRelatedWorkDetails("changeNumber", "-1", "ChangeManagement");
					GLOBAL.relatedtextdisplay = <div className="f-size-18 margin-b-5">{this.props.translator['Related Work Items']}</div>
					this.props.loadChangeRelatedDataDetails(itemId);
					if((this.props.editChangeInitialData.type===30 || this.props.editChangeInitialData.type==='30') && workModule==='Breakfix'){
					    this.props.loadrelatedWorkitems(this.props.editChangeInitialData.changeId,'Breakfix');
					}
					this.setState({ messagedisplay: true });
				}
		});
	}
    renderRelatedWorkLog(relatedLogData) {
		if (relatedLogData.length == 0) {
            return (
                <div></div>
            );
        }
        if (relatedLogData.data.length == 0) {
            return (
                <tr>
                    <td width="100%">
                        <div>{this.props.translator["No Data Available..."]}</div>
                    </td>
                </tr>
            );
        }
		else {
			return relatedLogData.data.map((relatedObj, index) => {
				let modnumber = "", modid = "", modRequester = "";
				let modulesearchparam = GLOBAL.checkmoduleval;
				if (modulesearchparam == "Breakfix") {
					modnumber = relatedObj.breakfixNumber
					modid = relatedObj.breakfixId
					modRequester = relatedObj.impactedUsername

				}
				if (modulesearchparam == "Fullfilment") {
					modnumber = relatedObj.itemNumber
					modid = relatedObj.itemId
					modRequester = relatedObj.requesterName
				}
				if (modulesearchparam == "Investigation") {
					modnumber = relatedObj.investigationNumber
					modid = relatedObj.investigationId
					modRequester = relatedObj.impactedUsername

				}
				if (modulesearchparam == "ChangeManagement") {
					modnumber = relatedObj.changeNumber
					modid = relatedObj.changeId
					modRequester = relatedObj.requesterName
				}

				if(!relatedObj.description){
                    relatedObj.description = "";
                }

                let desc = <span  title={relatedObj.description || "NO NAME"}>
						{ relatedObj.description && relatedObj.description.length<=25 ?relatedObj.description : relatedObj.description.substring(0, 25)+'...'}
					</span>;
					
				return (
					<div className='margin-t-5 margin-b-10 border-b padding-b-5'>
						<Row>
							<Col md={10}>{modnumber} - {relatedObj.createdOn} - <span className="fw-300 cTxt" onClick={this.showUserDetails}>{modRequester}</span>							
							{desc}						
							</Col>
							<Col md={2} className="text-c"><a onClick={this.state.disablePostButton ?((event) => event.preventDefault()) :this.postRelatedData.bind(this, modid, modnumber, modulesearchparam)} 
                                 title={this.props.translator['Relate']}><i className="fa fa-chevron-circle-right f-size-16 theme1"></i></a>
                            </Col>
						</Row>
					</div>
				);
			});
		}
	}
    renderAttList(attributeData) {	
		if (!attributeData) {
			return (
				null
			);
		}
		return attributeData.map((objjectData) => {
			let workModule = objjectData.workModule;
			let link = ''; 
			let isWIBRoleEnable = false;
			switch(workModule){
				case "Fullfilment":
					link = "/fulFillment/"+objjectData.workItemId;
					isWIBRoleEnable=this.props.isFulfillmentRolesEnabled;
					break;
				case "Breakfix":
					link = "/editBreakFix/"+objjectData.workItemId;
					isWIBRoleEnable=this.props.isIncidentRolesEnabled;
					break;
				case "Investigation":
					link = "/investigationEdit/"+objjectData.workItemId;
					isWIBRoleEnable=this.props.isProblemRolesEnabled;
					break;
				case "ChangeManagement":
					link = "/editChange/"+objjectData.workItemId;
					isWIBRoleEnable=this.props.isChangedRolesEnabled;
					break;
			}
			let relationVal = '';
			if (objjectData.relationType == "p2c") {
				relationVal = 'Is a Parent of';
			} else if (objjectData.relationType == "c2p") {
				relationVal = 'Is a Child of';
			} else if (objjectData.relationType == "p2p") {
				relationVal = 'Is a Peer of';
			} else if (objjectData.relationType == "h2c") {
				relationVal = 'Caused By';
			} else if (objjectData.relationType == "c2h") {
				relationVal = 'Has Caused';
			} else {
				relationVal = '';
			}	
			if (objjectData.itemId != null && objjectData.itemId != "") {
				if ((this.props && this.props.editChangeInitialData && this.props.editChangeInitialData.status && (this.props.editChangeInitialData.status == "Fixed" || this.props.editChangeInitialData.status == "Closed" || this.props.editChangeInitialData.status == "Completed" || this.props.editChangeInitialData.status == "Cancelled" || this.props.editChangeInitialData.status == "Rejected"))) {
					return (
						<tr>
							<td>{relationVal}</td>
							<td>{isWIBRoleEnable?<Link to={link} onClick={()=>this.setSearchApplyFalse()}>{objjectData.workItemNumber}</Link>:objjectData.workItemNumber}</td>
							<td>{objjectData.createdOn}</td>
							<td>{objjectData.createdByName}</td>
							<td></td>
						</tr>
					);
				}
				else {
					return (
						<tr>
							<td>{relationVal}</td>
							<td>{isWIBRoleEnable?<Link to={link} onClick={()=>this.setSearchApplyFalse()}>{objjectData.workItemNumber}</Link>:objjectData.workItemNumber}</td>
							<td>{objjectData.createdOn}</td>
							<td>{objjectData.createdByName}</td>
							{this.props.fieldStatus.relateWorkItems==true && !this.props.disableFieldsForGuestRole ?<td className="text-c f-size-13">{objjectData.relationType == 'p2p' ? <i onClick={this.deleteRelation.bind(this, objjectData.relationId, objjectData.workModule)} className="fa fa-trash-o cursorPoint"></i> : ''}</td>:null}
						</tr>
					);
				}
			}
			else
			  return (<tr></tr>);
		});
	}
    renderRelatedDataLog(relatedDataLog) {
		if (relatedDataLog.length == 0) {
			return (
				<div>{this.props.translator['Data not available.']}</div>
			);
		}
		else {
			return (
				<Table responsive striped bordered condensed hover className='f-size-13'>
					<thead>
						<tr  className='f-size-12'>
							<th>{this.props.translator['Relationship']}</th>
							<th>{this.props.translator['Work Item Number']}</th>
							<th>{this.props.translator['Created On']}</th>
							<th>{this.props.translator['Related By']}</th>
							{this.props.fieldStatus.relateWorkItems==true && !this.props.disableFieldsForGuestRole?<th className="text-c">{this.props.translator['Action']}</th>:null}
						</tr>
					</thead>
					<tbody>
						{this.renderAttList(relatedDataLog)}
					</tbody>
				</Table>
			);
		}
	}
    loaddata() {
		let itemId = this.props.itemId
		let modulesearch = GLOBAL.checkmoduleval;
		if (modulesearch == "Breakfix") {
			if (GLOBAL.relatedfilterval == "breakfixNumber")
				this.props.loadChangeRelatedWorkDetails(GLOBAL.relatedfilterval, GLOBAL.relatedfiltertext, "Breakfix",itemId,"Y");
			if (GLOBAL.relatedfilterval == "impactedUserid")
				this.props.loadChangeRelatedWorkDetails(GLOBAL.relatedfilterval, this.props.editChangeInitialData.requesterId, "Breakfix",itemId,"Y");
			if (GLOBAL.relatedfilterval == "serviceId")
				this.props.loadChangeRelatedWorkDetails(GLOBAL.relatedfilterval, this.props.editChangeInitialData.serviceId, "Breakfix",itemId,"Y");
			if(GLOBAL.relatedfilterval=="ciId")
			    this.props.loadChangeRelatedWorkDetails(GLOBAL.relatedfilterval, this.props.editChangeInitialData.ciId, "Breakfix",itemId,"Y");
		}
		if (modulesearch == "Fullfilment") {
			if (GLOBAL.relatedfilterval == "breakfixNumber")
				this.props.loadChangeRelatedWorkDetails("itemNumber", GLOBAL.relatedfiltertext, "Fullfilment",itemId,"Y");
			if (GLOBAL.relatedfilterval == "impactedUserid")
				this.props.loadChangeRelatedWorkDetails("requesterId", this.props.editChangeInitialData.requesterId, "Fullfilment",itemId,"Y");
			if (GLOBAL.relatedfilterval == "serviceId")
				this.props.loadChangeRelatedWorkDetails("offeringIdSx", this.props.editChangeInitialData.serviceId, "Fullfilment",itemId,"Y");
			if(GLOBAL.relatedfilterval=="ciId")	
			    this.props.loadChangeRelatedWorkDetails(GLOBAL.relatedfilterval, this.props.editChangeInitialData.ciId, "Fullfilment",itemId,"Y");
		}
		if (modulesearch == "Investigation") {
			if (GLOBAL.relatedfilterval == "breakfixNumber")
				this.props.loadChangeRelatedWorkDetails("investigationNumber", GLOBAL.relatedfiltertext, "Investigation",itemId,"Y");
			if (GLOBAL.relatedfilterval == "impactedUserid")
				this.props.loadChangeRelatedWorkDetails(GLOBAL.relatedfilterval, this.props.editChangeInitialData.requesterId, "Investigation",itemId,"Y");
			if (GLOBAL.relatedfilterval == "serviceId")
				this.props.loadChangeRelatedWorkDetails(GLOBAL.relatedfilterval, this.props.editChangeInitialData.serviceId, "Investigation",itemId,"Y");
			if(GLOBAL.relatedfilterval=="ciId")	
			    this.props.loadChangeRelatedWorkDetails(GLOBAL.relatedfilterval, this.props.editChangeInitialData.ciId, "Investigation",itemId,"Y");
		}
		if (modulesearch == "ChangeManagement") {
			if (GLOBAL.relatedfilterval == "breakfixNumber")
				this.props.loadChangeRelatedWorkDetails("changeNumber", GLOBAL.relatedfiltertext, "ChangeManagement",itemId,"Y");
			if (GLOBAL.relatedfilterval == "impactedUserid")
				this.props.loadChangeRelatedWorkDetails("requesterId", this.props.editChangeInitialData.requesterId, "ChangeManagement",itemId,"Y");
			if (GLOBAL.relatedfilterval == "serviceId")
				this.props.loadChangeRelatedWorkDetails(GLOBAL.relatedfilterval, this.props.editChangeInitialData.serviceId, "ChangeManagement",itemId,"Y");
			if(GLOBAL.relatedfilterval=="ciId")
			    this.props.loadChangeRelatedWorkDetails(GLOBAL.relatedfilterval, this.props.editChangeInitialData.ciId, "ChangeManagement",itemId,"Y");
		}
		GLOBAL.relatedtextdisplay = <div className="f-size-18 margin-b-5"></div>
		//this.props.loadChangeRelatedDataDetails("0");
		this.setState({ messagedisplay: false });
	}
    loadrelateddata() {
		let itemId = this.props.itemId
		this.props.loadChangeRelatedWorkDetails("changeNumber", "-1", "ChangeManagement");
		GLOBAL.relatedtextdisplay = <div className="f-size-18 margin-b-5">{this.props.translator['Related Work Items']}</div>
		this.props.loadChangeRelatedDataDetails(itemId);
		this.setState({ messagedisplay: true });
	}
    checkfilter(i) {
		GLOBAL.relatedfilterval = i.target.value;
		GLOBAL.relatedfiltertext=""
		this.setState({textEntered:''})
	}
    filtertext(i) {
		GLOBAL.relatedfiltertext = i.target.value;
		this.setState({textEntered:i.target.value})
	}
    checkmodule(i) {
		GLOBAL.checkmoduleval = i.target.value;
		GLOBAL.relatedfiltertext=""
		this.setState({textEntered:''});
		this.props.clearChangeRelatedWorkDetails();
	}
	setSearchApplyFalse(){
		this.props.setWipSearchApply(false);
	}
    render() {
		let formVariable = <div>
			<Row>
				<Col md={12}><Form.Group className="margin-b-5">
					<Form.Label>{this.props.translator['Type']}</Form.Label>
					<Form.Select componentClass="select" onChange={this.checkmodule.bind(this)}>
						<option value="ChangeManagement">Change</option>
						<option value="Breakfix">Incident</option>
						<option value="Fullfilment">Fulfillment</option>
						<option value="Investigation">Problem</option>
					</Form.Select>
				</Form.Group>
				</Col>
			</Row>
			<div>
				<Row>
					<Col md={6} className="padding-r-0">
						<Form.Label>{this.props.translator['Search by']}</Form.Label>
						<Form.Select componentClass="select" onChange={this.checkfilter.bind(this)} >
							<option value="">Select</option>
							{this.props.editChangeInitialData.serviceId!='0' || this.props.editChangeInitialData.serviceId!=0?<option value="serviceId">Same Service</option>:null}
							<option value="breakfixNumber">Item ID</option>
							<option value="impactedUserid">Same Requestor</option>
                            {GLOBAL.checkmoduleval=="Fullfilment"?null:this.props.editChangeInitialData.ciId!='0' || this.props.editChangeInitialData.ciId!=0?<option value="ciId">Same Impacted CI</option>:null}
						</Form.Select>
					</Col>
					<Col md={6}>
                        <Form.Label>&nbsp;</Form.Label>
                        <Form.Control type="text" onChange={this.filtertext.bind(this)} value={this.state.textEntered} placeholder="search"/>
                    </Col>
				</Row>
				<div className="btnNew margin-t-10">
					{this.state.messagedisplay == false ? <a onClick={this.loadrelateddata.bind(this)} className="backList"></a> : null}
					<Button className="rgSidrkBtn smallBtn" bsSize="small" bsStyle="primary" title={this.props.translator['Search']} disabled={this.props.showLoader} onClick={this.loaddata.bind(this)}>
                    	{this.props.translator['Search']} 
						{this.props.showLoader ? <ImSpinner6 className="icn-spinner"/> : null}
					</Button>
				</div>
			</div>
            {!this.props.showLoader.loading ? this.renderRelatedWorkLog(this.props.changeRelatedWorkDetails) :<div>{""}</div>}
		</div>;
		return (
			<div className="margin-b-10">
				<div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
                    <ul>
					{(this.props && this.props.editChangeInitialData && this.props.editChangeInitialData.status && (this.props.editChangeInitialData.status == "Fixed" || this.props.editChangeInitialData.status == "Closed" || this.props.editChangeInitialData.status == "Completed" || this.props.editChangeInitialData.status == "Cancelled" || this.props.editChangeInitialData.status == "Rejected" || this.props.disableFieldsForGuestRole)) ? null :
					    <li>
							<Button title={this.state.toggleFormAndList == 'list' ? this.props.translator['Related Work Items'] : this.props.translator['Relate Work Item']} 
					            onClick={this.toggleFormAndList} bsStyle="" bsClass="" bsPrefix=" " className="myBt">{this.props.fieldStatus.relateWorkItems==true && !this.props.disableFieldsForGuestRole ?
					            this.state.toggleFormAndList == 'list' ? <HiPlus /> : <PiListBulletsBold /> : null}
					        </Button>
					    </li>}
                        <li className="d-md-none">
                        <button
						    type='button'
                            title="Minimize the right panel"
                            bsClass=""
                            bsStyle=""
                            className="closerightPanelBtn"
                            onClick={() => {
                            this.props.rightEditPanel(false);
                            }}
                        >
                            <IoClose/>
                        </button>
                        </li>
                    </ul>
                </div>
				<div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.state.toggleFormAndList == 'list' ? this.props.translator['Related Work Items'] : this.props.translator['Relate Work Item']}</div></div>
				<div className='rBoxGap'>
					{this.state.toggleFormAndList == 'list' || this.props.disableFieldsForGuestRole ?
						this.renderRelatedDataLog(this.props.changeRelatedDataDetails)
						:
						formVariable
					}
				</div>
			</div>
		);
	}
}
export function mapStateToProps({ changeRelatedWorkDetails, changeRelatedDataDetails, rightMenuShowLoader }) {
	return { changeRelatedWorkDetails, changeRelatedDataDetails, showLoader: rightMenuShowLoader.loading };
}
function mapDispatchToProps(dispatch) {
	return bindActionCreators({ loadChangeRelatedWorkDetails, loadChangeRelatedDataDetails, loadrelatedWorkitems, clearChangeRelatedWorkDetails, setWipSearchApply }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(PopoverWorkItemsData);