
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import { FETCH_SLIDERLIST } from '../../actions/categorylist/sliderlistAction'

export default function(state=[], action){
    //console.log('Action received:',action);

    switch (action.type){
        case FETCH_SLIDERLIST:
            //return state.concat([action.payload.data]);
            return action.sliderlist.data;
            default:
            return state;
    }



}
