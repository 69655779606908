
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const downloadData = (state = {}, action) => {
    if(action.type === 'SCORECARD_PAYLOAD'){
      return action.payloadData;
    }else{
      return state;
    }
}