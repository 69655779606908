
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useMemo } from "react";
import SimpleBar from "simplebar-react";
import ListLoader from "../../common/loaders/ListLoader";
import NoDataAvailable from "../../common/NoDataAvailable";

const ActionItems = ({ actData, dateFormat, actDataFetching }) => {
  return actDataFetching ? (
    <div className="fnloader">
      <ListLoader />
    </div>
  ) : actData?.length === 0 || !actData ? (
    <div className="custom-container-fluid">
      <NoDataAvailable />
    </div>
  ) : (
    <SimpleBar className="simpl">
      <div class="table-responsive custom-task-list clearfix">
        <div id="scrollableDiv_1">
          <div className="table-container">
            <table class="table  table-sm border-1">
              <thead>
                <tr>
                  <th>Number</th>
                  <th>Priority</th>
                  <th>Expected By</th>
                  <th>Action Owner</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {actData?.map((item, index) => {
                  return (
                    <>
                      <tr>
                        <td className="action-item-code">
                          {item?.actionitemCode || "--"}
                        </td>
                        <td className="text-center">
                          {item?.priorityDetails?.priority || "--"}
                        </td>
                        <td>{item?.expectedDueDate || "--"}</td>
                        <td>{item?.assignedToFullName || "--"}</td>
                        <td>{item?.statusName || "--"}</td>
                      </tr>
                      <tr>
                        <td className="base" colSpan="5">
                          <div className="fw-semibold">Summary:</div>
                          <div
                            style={{
                              wordBreak: "break-all",
                              whiteSpace: "break-spaces",
                            }}
                          >
                            {item?.additionalParams || "--"}
                          </div>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </SimpleBar>
  );
};

export default ActionItems;
