
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
//below URL used for testing purpose
//const breakFixUrl = 'https://xsm-f.mybluemix.net/api/SX_FAVOURITEOFFERINGs/';
import { GLOBAL }  from '../../components/Globals';
//let breakFixUrl =`https://xsm-f.mybluemix.net/api/breakfixitem?breakfixitemId=`;
//let breakFixUrl=`https://xsm-f.mybluemix.net/groupListSelfService?companyId=2932&group=ResolvedER`
export function loadBreakFixSLAResolutionLogDetailsSuccess(breakFixSLAResolutionLogDetails){
	//alert('in success')
	return {
		type: 'LOAD_BREAKFIXSLARESOLUTIONLOGDETAILS_SUCCESS',
		breakFixSLAResolutionLogDetails
	};
}

export function loadBreakFixSLAResolutionLogDetailsFailure(errorResponse){
	return {
		type: 'LOAD_BREAKFIXSLARESOLUTIONLOGDETAILS_FAILURE',
		errorResponse
	};
}

export function loadBreakFixSLAResolutionLogDetails(module,itemId){
	//alert('action called and parameter is '+breakFixId)
	return(dispatch) =>{
		//alert("EditGroupIdAction123"+breakFixId);
		if(module == undefined || module == ""){
			//alert("H2 breakFixId not found");
		}else{
				//alert("my url is "+GLOBAL.breakFixEditUrl+breakFixId);
				axios.get(GLOBAL.breakFixSLAResolutionURL+"?module="+module+"&itemId="+itemId)
					.then((breakFixSLAResolutionLogDetails)=>{
						if(!breakFixSLAResolutionLogDetails){//alert('not breakFix')
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							throw Error(breakFixSLAResolutionLogDetails.statusText);
						}
						return breakFixSLAResolutionLogDetails;
					})
					.then((breakFixSLAResolutionLogDetails) =>{//alert('yes it is')
						dispatch(loadBreakFixSLAResolutionLogDetailsSuccess(breakFixSLAResolutionLogDetails))
					})
					.catch((err) => {
						console.log(err);
					});
					//console.log("not calling loadBreakFixEditDetails");
		}
	};
}
