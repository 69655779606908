
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, {Component} from 'react';
import Dropzone from 'react-dropzone';
import {Card, Button, OverlayTrigger,Tooltip,Popover,ListGroup} from "react-bootstrap";
import Cookies from 'universal-cookie';
import swal from 'sweetalert';
import { fileobjectGenerator, validateFile } from '../../../../common/MIMEObjGenerator';
import {IoClose} from "react-icons/io5";
import { ImInfo } from "react-icons/im";
import { RiUploadLine } from "react-icons/ri";

const cookies = new Cookies();

let attachmentDetails = cookies.get('gph');
if(attachmentDetails) attachmentDetails = attachmentDetails.replace('s:','');
if(attachmentDetails) attachmentDetails = attachmentDetails.substring(0,attachmentDetails.lastIndexOf('.'));
attachmentDetails=attachmentDetails.split("~");
const attachmentSize=attachmentDetails[21];
const attachmentTypeStr = attachmentDetails[22];
const attachmentType = fileobjectGenerator(attachmentDetails[22].split(', '));


class UploadScreen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      filesPreview: [],
      filesToBeSent: [],
      // printcount: 1,
    };
     this.removeFile = this.removeFile.bind(this);
  }

  removeFile(fileName) {
    const {
      setAnswer,
      question,
      dispatch
    } = this.props;
    let filesList = this.state.filesToBeSent;
    let filesPreview = this.state.filesPreview;
    const indexToBeRemoved = filesList.findIndex(file => file.name === fileName);
    if(indexToBeRemoved !== -1) {
      filesList.splice(indexToBeRemoved, 1);
      filesPreview.splice(indexToBeRemoved, 1);
    }
    this.setState({filesToBeSent: filesList, filesPreview: filesPreview});
    if(filesList.length === 0 || filesPreview.length === 0) {
      let errorObj = Object.assign({}, this.props.piSectionFormErrors, { [question.name]: true });
      this.props.setFormErrors(errorObj);
      setAnswer(question, "", dispatch);
    } else {
      setAnswer(question, filesList, dispatch);
    }
  }

  onDrop(acceptedFiles) {
    if (acceptedFiles.length > 0) {
      const { setAnswer, question, dispatch } = this.props;
      let filesToBeSent = this.state.filesToBeSent;
      let totalFileSize = [...filesToBeSent, ...acceptedFiles].reduce((totalSize, file) => {
        totalSize += file.size;
        return totalSize;
      }, 0);
      if(totalFileSize > 1048576 * attachmentSize) {
        swal({
          text: this.props.translator["Total file size should be less than "] + attachmentSize + this.props.translator[" MB."],
          button: this.props.translator["OK"],
        });
        return;
      }
      if(filesToBeSent.length > 0 && this.props.question.responseType == "Attachment") {
        swal({
          text: this.props.translator["File already attached"],
          button: this.props.translator["OK"],
        });
      } else {
        filesToBeSent = [...filesToBeSent, ...acceptedFiles];
        setAnswer(question, filesToBeSent, dispatch);
        let errorObj = Object.assign({}, this.props.piSectionFormErrors, { [this.props.question.name]: false });
        this.props.setFormErrors(errorObj);
  
        let filesPreview = [];
        filesToBeSent.forEach((file, index) => {
          filesPreview.push(
            <ListGroup.Item bsPrefix='dropZFleDv' key={`${file.name}_${index}`}>
              <span className='dropZFle' title={file.name}>{file.name}</span>
              <a title={this.props.translator["Delete"]} className="dropZRemov" onClick={() => {
                this.removeFile(file.name);
              }}><IoClose /></a>
            </ListGroup.Item>
          );
        });
        this.setState({filesToBeSent, filesPreview});
      }
    }
  }

  onDropRejected(rejectedFiles) {
    if (
      rejectedFiles &&
      rejectedFiles[0] &&
      rejectedFiles[0].file &&
      rejectedFiles[0].file.size &&
      rejectedFiles[0].file.size > 1048576 * attachmentSize
    ) {
      if(this.props.question.responseType == "Dropzone") {
        swal({
          text: this.props.translator["Total file size should be less than "] + attachmentSize + this.props.translator[" MB."],
          button: this.props.translator["OK"],
        });
      } else {
        swal({
          text: this.props.translator["Please upload File up to "] + attachmentSize + this.props.translator[" MB size."],
          button: this.props.translator["OK"],
        });
      }
    } else {
      swal({
        text:
          this.props.question.responseType == "Dropzone"
            ? this.props.translator["File(s) not supported."]
            : this.props.translator["File not supported."],
        button: this.props.translator["OK"],
      });
    }
    if(this.props.questionType == 'mandatory') {
      let errorObj = Object.assign({}, this.props.piSectionFormErrors, { [this.props.question.name]: true });
      this.props.setFormErrors(errorObj);
    }
  }

  render() {
    const renderTooltip = (props) => (
      <Tooltip bsPrefix="wboxTip" id="button-tooltip" {...props}><div className='margin-b-5 fw-600'>{this.props.translator["Supported file types:"]}</div>{attachmentTypeStr}</Tooltip>
    );
    return (
      <div key={this.props.question.qId}>
        <div className='text-l'>
          <b>
            {this.props.question.responseType == "Dropzone"
              ? this.props.translator["Upload your file(s)."]
              : this.props.translator["Upload your file."]}
          </b>{" "}
          {this.props.question.responseType == "Dropzone" && this.props.translator["(Total file size"] + " " + attachmentSize}{" "}
          {this.props.question.responseType == "Attachment" && this.props.translator["(max size"] + " " + attachmentSize} {" "}
          {this.props.translator["MB)"]}
          <OverlayTrigger placement="right" overlay={renderTooltip}><span style={{position:"relative",top:"-1px"}} role="button" className="f-size-12 margin-l-5 cursorPoint"><ImInfo /></span></OverlayTrigger>
        </div>
        <Dropzone
          multiple={
            this.props.question.responseType == "Dropzone" ? true : false
          }
          onDrop={(files) => this.onDrop(files)}
          onDropRejected={(files) => this.onDropRejected(files)}
          maxSize={1048576 * attachmentSize}
          accept={attachmentType}
          validator={(file) => {
            return validateFile(file);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p
                  className={
                    this.props.piSectionFormErrors[this.props.question.name]
                      ? "form-control dropzoneBox error"
                      : "form-control dropzoneBox"
                  }
                >
                  <span className='dropZicon'><RiUploadLine /></span>{this.props.translator["Drop files or click here to upload"]}
                  {/* <button type="button">
                    {this.props.translator["Browse"]}
                  </button> */}
                </p>
              </div>
            </section>
          )}
        </Dropzone>
        {this.state.filesPreview.length > 0 ? (
          <div className="margin-t-5">
            <div className="bor-bottom padding-b-5 black f-size-13 fw-600">
              {this.state.filesPreview.length > 0
                ? (this.props.question.responseType == "Dropzone" ? this.props.translator["File(s) attached"] : this.props.translator["File attached"])
                : (this.props.question.responseType == "Dropzone" ? this.props.translator["File(s) not attached"] : this.props.translator["File not attached"])}
            </div>
            <ListGroup className="attachment-break">
              {this.state.filesPreview}
            </ListGroup>
          </div>
        ) : null}
      </div>
    );
  }
}

export default UploadScreen;
