
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Row, Col, Form,  Alert,Button} from 'react-bootstrap';
import { Form as ReduxForm, Field, reduxForm, SubmissionError, change } from 'redux-form';
import { spcmLoader, getAllServicesList, getEditServiceDetails, getCategoryList, getCriticalityList, getCompanyList, getPortfolioGroupList, getPortfolioManagerList, getCatalogueGroupList, getCatalogueManagerList, getServiceLevels, getServiceTypes, getCIBaseData, getServiceDetailsDataList, getServiceCIs, getServiceDetailCounts, getContextualRules, businessFunctionByCompanyId, resetBusinessFunctionList, resetCategoryList,serviceOwnerGroupAction,getManagerList } from '../../../actions/spcmActions';
import { loadBreakFixComplexity } from '../../../actions/breakFix/reasonCodeAction';
import { connect } from 'react-redux';
import axios from 'axios';
import constants from '../../../utils/constants';
import { _dateField, _textArea, _dropDown, _inputField, _radio, _ckeditor, TypeaheadandDropdown } from '../../common/formFields';
import { itemQuantityField } from "../../../components/common/formFields";
import {postAttributeBasedSlaAction} from '../../../actions/sla/lovAction';
import { GLOBAL } from '_Globals';
import swal from "sweetalert";
import { Link } from 'react-router-dom';
import configureStore from '../../../store/configureStore';
import { ImInfo } from 'react-icons/im';
import { TbExternalLink } from "react-icons/tb";
import CIDetails from '_Commons/WorkItemBoard/xsm-ci-editquickView';
import UserDetails from '_Commons/WorkItemBoard/xsmEditRequestedBy';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { RuleAmsDdropDownContextual } from "../../../components/common/forms/ruleAmsDdropDownContextual";
const store = configureStore();

let savedCiId=null;

const validate = (values, props) => {
	let errors = {};
	console.log("validate+++++", values);
	console.log("validate.serviceci_id", values.serviceci_id);
	console.log("error", errors);
	console.log("(props.statusValue===", props.statusValue);
	console.log("props.spcmReducer.currentlySelectedServiceDetails.STATUS", props.spcmReducer.currentlySelectedServiceDetails.STATUS);

	savedCiId=values.serviceci_id;
	let STATUS = props.spcmReducer.currentlySelectedServiceDetails.STATUS;
	if (values.description) {
		let str = values.description;
		str = str.replace(/&nbsp;/g, "");
		str = str.replace(/(<([^>]+)>)/ig, '');
		str = str.trim();
		if (str.length == "0") {
			errors.description = 'Enter Description';
		}
	}

	else if (!values.description) {
		errors.description = 'Enter Description';
	}

	if (values.offering_name && values.offering_name.trim() == "") {
		errors.offering_name = 'Enter Offering Name';
	}
	else if (!values.offering_name) {
		errors.offering_name = 'Enter Offering Name';
	}

	if (values.offering_name != undefined || values.offering_name != null) {
		if (values.offering_name.includes("\"") == true || values.offering_name.includes("\'") == true)
			errors.offering_name = "Double Quotes (\") and Single Quotes (\') are prohibited"
	}

	if (values.offering_name != undefined || values.offering_name != null) {
		if (values.offering_name.length > 500)
			errors.offering_name = "Service Name Size Exceeded (Max. 120 Characters)"
	}

	if (!values.company_id) {
		errors.company_id = 'Select Company';
	}
	if (!values.subcategory_id) {
		errors.subcategory_id = 'Select Category';
	}

	if (parseInt(values.min_value, 10) >= parseInt(values.max_value, 10)) {
		errors.min_value = 'Min Value < Max Value';
	}

	if (parseInt(values.min_value, 10) <= 0) {
		errors.min_value = 'Please enter value > 1';
	}

	if (parseInt(values.max_value, 10) <= 1) {
		errors.max_value = 'Please enter value > 1';
	}

	if (parseInt(values.min_value, 10) > 999999) {
		errors.min_value = 'Min Value cannot be > 999999'
	}

	if (parseInt(values.max_value, 10) > 999999) {
		errors.max_value = 'Max Value cannot be > 999999'
	}

	if (STATUS == "Deployed") {
		console.log("SUCCESS");
	} else if (STATUS != "Deployed") {
		if((!values.business_function_id && values.business_function_id !== 0) || values.business_function_id === null || !values.business_function_name || values.business_function_name === null || values.business_function_name.trim().length === 0) {
			errors.businessFunction = 'Enter business function'
		}
		if (values.serviceci_name == undefined || values.serviceci_name == null || values.serviceci_name.trim() == "") {
			errors.serviceci_name = 'Enter service CI Name';
		}
	}

	if (!values.service_criticality) {
		errors.service_criticality = 'Select Criticality';
	}

	if (!values.complexity) {
		errors.complexity = 'Select Complexity';
	}

	if (!values.service_ci_base_id) {
		errors.service_ci_base_id = 'Select atleast one option';
	}
	return errors;
};
const workItemTypeOptions = [
	{ value: "serviceRequest", text: "Service Request" }, 
	{ value: "incidentRequest", text: "Incident" }, 
	{ value: "standardChange", text: "Standard Change" }
];


class CreateSerForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			nextBtn: true,
			previousBtn: false,
			date: '',
			featured: "Yes",
			complexityEditable: "true",
			slaCount: 0,
			WorkItemTypeChange: "serviceRequest",
			externalNumberVisible: "false",
			baseLanguage: "",
			minItemQuantity: "1",
			maxItemQuantity: "999999",
			selectedServiceCi: [],
			typedServiceCi: '',
			selectedCompany: '',
			externLink: false,
			externLinkAMS: false,
			role_id: '',
			selectedContextRule: [],
			ctxRuleValueError: '',
			editStatus: this.props.spcmReducer.editService.status,
			descriptionState: true,
			additionalcharleft:120,
			defaultStatus:'',
			typedBusinessFn: "",
			selectedBusinessFn: [],
			typedServiceOwnerGroup:'',
			selectedServiceOwnerGroup:[],
			typedServiceOwner:'',
			selectedServiceOwner:[],
			inputServiceCiId:'',
			serviceOwnerIcnId:'',
			panelCreResize:"",
			isShowDetails:false,
			isShowRequestorDetails:false,
			contextualRulesModule: "fulfillment"
		};
		this.submitForm = this.submitForm.bind(this);
		this.timeChange = this.timeChange.bind(this);
		this.onCompanyChange = this.onCompanyChange.bind(this);
		this.onFeaturedToChange = this.onFeaturedToChange.bind(this);
		this.oncomplexityEditableChange = this.oncomplexityEditableChange.bind(this);
		this.onWorkItemTypeChange = this.onWorkItemTypeChange.bind(this);
		this.onExternalFulfilChange = this.onExternalFulfilChange.bind(this);
		this.onServiceCiChange = this.onServiceCiChange.bind(this);
		this.onGroupSelect=this.onGroupSelect.bind(this);
		this.onCrossClickServiceCi = this.onCrossClickServiceCi.bind(this);
		this.onCrossClickContextRule = this.onCrossClickContextRule.bind(this);
		this.onContextRuleSelect = this.onContextRuleSelect.bind(this);
		this.getModuleID = this.getModuleID.bind(this);
		this.onCrossClickBusinessFn = this.onCrossClickBusinessFn.bind(this);
		this.businessFnInputChange = this.businessFnInputChange.bind(this);
		this.setBusinessFn = this.setBusinessFn.bind(this);
		this.onCrossClickServiceOwnerGroup = this.onCrossClickServiceOwnerGroup.bind(this);
		this.onServiceOwnerGroupOptionSelect = this.onServiceOwnerGroupOptionSelect.bind(this);
		this.onServiceOwnerGroupChange = this.onServiceOwnerGroupChange.bind(this);
		this.onCrossClickServiceOwner = this.onCrossClickServiceOwner.bind(this);
		this.onServiceOwnerOptionSelect = this.onServiceOwnerOptionSelect.bind(this);
		this.onServiceOwnerChange = this.onServiceOwnerChange.bind(this);
	}

	componentDidMount() {
		//  console.log('===================sdfs===================');
		const { change } = this.props;
	if(this.props.spcmReducer.editId && this.props.spcmReducer.editId > 0)	{
		change('status', this.props.initialValues.status);
		this.props.postAttributeBasedSlaAction('',this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID,'','','','','','')
		this.props.serviceOwnerGroupAction(this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID,60,'false');
		if(this.props.spcmReducer.currentlySelectedServiceDetails.SERVICE_OWNER_GROUP_ID == null || this.props.spcmReducer.currentlySelectedServiceDetails.SERVICE_OWNER_GROUP_ID == "null"){
		this.props.getManagerList(this.props.spcmReducer.currentlySelectedServiceDetails.SERVICE_OWNER_GROUP_ID, 'EMPTY_MANAGER_LIST');

		}else{
			this.props.getManagerList(this.props.spcmReducer.currentlySelectedServiceDetails.SERVICE_OWNER_GROUP_ID,'SERVICE_OWNER_BY_GROUPID');

		}


	}else{
		this.props.serviceOwnerGroupAction(0, 60, 'false');

		this.props.getManagerList(this.props.spcmReducer.currentlySelectedServiceDetails.SERVICE_OWNER_GROUP_ID, 'EMPTY_MANAGER_LIST');

		this.setState({
			defaultStatus:'Draft'
		})
	}
		this.props.getServiceTypes();

		if (this.props.editServiceState) {
			console.log("base lang");
			this.setState({
				baseLanguage: this.props.initialValues.base_language,
			});
		}
		this.props.getCIBaseData();

		let str = {};
		str.user_id = "";
		str = JSON.stringify(str);
		axios.get("/api/userInfo", { headers: { 'query': str } }).then((response) => {
			this.setState({ role_id: response.data.role_id });
			let roleArray = response.data.role_id.split(',');
			let configMgr = roleArray.includes("37");
			let assetMgr = roleArray.includes("38");
			let configVwr = roleArray.includes("39");
			let confiAMSAdmin = roleArray.includes("41");
			if (configMgr || assetMgr || configVwr) {
				this.setState({ externLink: true });
			}
			if (confiAMSAdmin) {
				this.setState({ externLinkAMS: true });
			}
		});
	}

	submitForm(values) {
		console.log("this.props.statusValue", this.props.statusValue);
		console.log("this.props.statusClicked", this.props.statusClicked);
		console.log("val,", values);
		const attributeBasedSLAData = this.props.postAttributeBasedSlaReducer;
		if (values.service_owner_group == "" && values.service_owner_group_id == ""){
			values.service_owner_group = null;
			values.service_owner_group_id = null;
			values.service_owner = null;
			values.service_owner_id = null;
		}
		// Base language
		if (this.props.editServiceState) {
			if (this.props.statusValue == "Draft") {
				values.status = "Draft";
			} else if (this.props.statusValue.trim() == "") {
				values.status = this.state.editStatus;
			} else if (this.props.statusClicked == false && this.props.statusValue != this.props.spcmReducer.currentlySelectedServiceDetails.STATUS) {
				values.status = this.props.spcmReducer.currentlySelectedServiceDetails.STATUS;
			} else {
				values.status = this.props.statusValue;
			}
		}
		values.expiration_date = "2099-12-12 11:11:11.000";
		values.base_language = this.state.baseLanguage;
		values.min_value = this.state.minItemQuantity == null || this.state.minItemQuantity == "" || this.state.minItemQuantity == "null" || this.state.minItemQuantity == undefined ? '1' : this.state.minItemQuantity;
		values.max_value = this.state.maxItemQuantity == null || this.state.maxItemQuantity == "" || this.state.maxItemQuantity == "null" || this.state.maxItemQuantity == undefined ? '999999' : this.state.maxItemQuantity;
		if (this.props.editServiceState) {
			values.updatedby_name = "";
			values.updated_by = "";
		} else {
			values.createdby_name = "";
			values.created_by = "";
		}

		if (values.externally_fulfilled == "false") {
			values.use_external_number = "false"
		}

		if (this.state.WorkItemTypeChange == "incidentRequest") {
			values.delivery_mode = "Non-Shipment Based";
			values.offering_type = "Others"
		}

		if (this.state.WorkItemTypeChange == "standardChange") {
			values.delivery_mode = "Other";
			values.offering_type = "Others"
		}

		this.props.spcmReducer.categoryList.forEach(function (val) {
			if (val.id == values.subcategory_id) {
				values.category_name = val.categoryName;
				values.sub_category_name = val.subCategoryName;
				values.category_id = val.categoryId;
			}

		}, this);

		this.props.spcmReducer.criticalityList.forEach(function (val) {
			if (val.field1Value == values.service_criticality)
				values.service_criticality_name = val.field1Key;
		}, this);
		this.props.spcmReducer.companyList.forEach(function (val) {
			if (val.field1Value == values.company_id)
				values.company_name = val.field1Key;
		}, this);

		if (this.props.editServiceState) {
			values.offering_id = this.props.spcmReducer.editService.offering_id;
			console.log('slaCounts');
			console.log(this.state.slaCount);
			values.slaCount = attributeBasedSLAData.length;


			console.log('Updated Price Status');
			console.log(this.props.spcmReducer.updatedHidePriceStatus);
			console.log('Updated SLA Status');
			console.log(this.props.spcmReducer.updatedHideSlaStatus);
			if (!this.props.spcmReducer.updatedHidePriceStatus == '') {
				values.hide_price = this.props.spcmReducer.updatedHidePriceStatus;
			}
			if (!this.props.spcmReducer.updatedHideSlaStatus == '') {
				values.hide_sla = this.props.spcmReducer.updatedHideSlaStatus;
			}



			this.props.spcmLoader('service', 'posting');
			if ((typeof (values.slaCount) == 'undefined') && (values.status == 'Deployed')) {
				swal({
					text: 'Service cannot be deployed as SLA details not found. Please trying refreshing the page.',
					button: this.props.tr["OK"],
				  });
				this.props.spcmLoader('service', 'success');
			}
			else {
				values.work_item_type = this.state.WorkItemTypeChange;
				values.ams_rule_id = (this.state.WorkItemTypeChange !== "standardChange" && this.state.selectedContextRule[0] && this.state.selectedContextRule[0].value) || null;
				values.ams_rule_name = (this.state.WorkItemTypeChange !== "standardChange" && this.state.selectedContextRule[0] && this.state.selectedContextRule[0].label) || null;
				return axios.patch(constants.SERVICEPATCH_API_URL + "/" + this.props.spcmReducer.editId, values).then((response) => {
					console.log("PATCH++++", response);
					let resp = response.data;
					this.props.statusOnClicked(false);
					this.props.setStatus("");
					this.props.spcmLoader('service', 'success');
					this.props.getServiceCIs("0", "test");
					this.props.getServiceDetailCounts(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
					console.log("this.props.spcmReducer.editService.status", this.props.spcmReducer.editService.status)
					this.props.dispatch(change("CreateSerForm", "offering_name", resp.OFFERING_NAME));
					this.props.dispatch(change("CreateSerForm", "company_id", resp.COMPANY_ID));
					this.props.dispatch(change("CreateSerForm", "featured", resp.FEATURED));
					this.props.dispatch(change("CreateSerForm", "work_item_type", resp.WORK_ITEM_TYPE));
					this.props.dispatch(change("CreateSerForm", "service_criticality", resp.SERVICE_CRITICALITY));
					this.props.dispatch(change("CreateSerForm", "complexity", resp.COMPLEXITY));
					this.props.dispatch(change("CreateSerForm", "service_ci_base_id", resp.SERVICE_CI_BASE_ID));
					this.props.dispatch(change("CreateSerForm", "serviceci_name", resp.SERVICECI_NAME));
					this.props.dispatch(change("CreateSerForm", "externally_fulfilled", resp.EXTERNALLY_FULFILLED));
					this.props.dispatch(change("CreateSerForm", "use_external_number", resp.USE_EXTERNAL_NUMBER));
					this.props.dispatch(change("CreateSerForm", "delivery_mode", resp.DELIVERY_MODE));
					this.props.dispatch(change("CreateSerForm", "offering_type", resp.OFFERING_TYPE));
					this.props.dispatch(change("CreateSerForm", "base_language", resp.BASE_LANGUAGE));
					this.props.dispatch(change("CreateSerForm", "subcategory_id", resp.SUBCATEGORY_ID));
					this.props.dispatch(change("CreateSerForm", "tags", resp.TAGS));
					this.props.dispatch(change("CreateSerForm", "summary", resp.SUMMARY));
					this.props.dispatch(change("CreateSerForm", "description", resp.DESCRIPTION));
					this.props.dispatch(change("CreateSerForm", "contextualAssignmentRule", resp.AMS_RULE_NAME));
					this.props.dispatch(change("CreateSerForm", "business_function_id", resp.BUSINESS_FUNCTION_ID));
					this.props.dispatch(change("CreateSerForm", "business_function_name", resp.BUSINESS_FUNCTION_NAME));
					this.props.dispatch(change("CreateSerForm", "service_owner_group", resp.SERVICE_OWNER_GROUP));
					this.props.dispatch(change("CreateSerForm", "service_owner_group_id", resp.SERVICE_OWNER_GROUP_ID));
					this.props.dispatch(change("CreateSerForm", "service_owner", resp.SERVICE_OWNER));
					this.props.dispatch(change("CreateSerForm", "service_owner_id", resp.SERVICE_OWNER_ID));
					this.setState({
						serviceOwnerIcnId:resp.SERVICE_OWNER_ID
					})
					// this.props.dispatch(change("CreateSerForm", "status", ''));
					this.props.statusRendering(resp.STATUS, true);
					this.props.setServiceStatus(resp.STATUS);
					this.setState({ editStatus: resp.STATUS, descriptionState: false }, () => {
						this.setState({ descriptionState: true })
					});

					swal({
						text:
							"Service has been updated successfully",
						button: this.props.tr["OK"],
					});

				})
					.catch((error) => {
						console.log("EERROORR", error.response);
						this.props.spcmLoader('service', 'success');
						throw new SubmissionError({ _error: error.response.data[0].message });

					});
			}

		} else {
			this.props.spcmLoader('service', 'posting');
			values.featured = this.state.featured;
			values.complexity_editable = this.state.complexityEditable;
			values.work_item_type = this.state.WorkItemTypeChange;
				values.ams_rule_id = this.state.WorkItemTypeChange !== "standardChange" ?
				this.state.selectedContextRule[0] && this.state.selectedContextRule[0].value : null;
				values.ams_rule_name = this.state.WorkItemTypeChange !== "standardChange"?
				this.state.selectedContextRule[0] && this.state.selectedContextRule[0].label : null;
			return axios.post(constants.SERVICEPOST_API_URL, values).then((response) => {
				console.log("POST++++");
				this.props.rightSidePageHead('default');
				this.props.updateServiceState(false, true);
				this.props.componentFormFun1(false);
				this.props.spcmLoader('service', 'success');
				this.props.showRightSide(false);
				this.props.statusOnClicked(false);
				this.props.createServiceClicked(false);
				this.props.getServiceCIs("0", "test");
				let filterData = sessionStorage.getItem("filterValues");
				filterData = JSON.parse(filterData);
				if (filterData && Object.keys(filterData).length > 0) {
					this.props.getServiceDetailsDataList(
						filterData.searchParams,
						filterData.status,
						filterData.category,
						filterData.featured,
						filterData.workItemType,
						filterData.modifiedDate,
						filterData.sortOrder,
						filterData.sortOnColumn,
						filterData.page,
						filterData.size
					);
				} else {
					this.props.getServiceDetailsDataList("", this.props.status);
				}

			})
				.catch((error) => {

					this.props.spcmLoader('service', 'success');
					throw new SubmissionError({ _error: error.response.data[0].message });
				});
		}

	}

	componentWillMount() {
		const { change } = this.props;
		change('status', 'Draft');
		change("business_function_id", 0);
		change("business_function_name", 'Default');
		if (this.props.editServiceState) {
			this.props.getCategoryList(this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID, "serviceBoard", this.props.spcmReducer.currentlySelectedServiceDetails.BUSINESS_FUNCTION_ID);
			this.props.businessFunctionByCompanyId(this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID);
			const moduleid = this.getModuleID(this.props.spcmReducer.currentlySelectedServiceDetails.WORK_ITEM_TYPE)
			// this.props.getContextualRules(this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID, moduleid);

			this.setState({
				featured: this.props.initialValues.featured, complexityEditable: this.props.initialValues.complexity_editable,
				WorkItemTypeChange: this.props.initialValues.work_item_type ? this.props.initialValues.work_item_type : "serviceRequest", externalNumberVisible: this.props.initialValues.externally_fulfilled,
				minItemQuantity: this.props.initialValues.min_value,
				maxItemQuantity: this.props.initialValues.max_value,
				additionalcharleft: 120- this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_NAME.length,
				selectedCompany: this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID,
				contextualRulesModule: structuredClone(this.props.spcmReducer.currentlySelectedServiceDetails.WORK_ITEM_TYPE === "incidentRequest" ? "incident" : this.props.spcmReducer.currentlySelectedServiceDetails.WORK_ITEM_TYPE === "serviceRequest" ? "fulfillment" : "changemanagement")

			});
			GLOBAL.SelectedServiceDetailcompanyID = this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID;
			this.setState({
				selectedServiceCi: [{
					value: this.props.initialValues.serviceci_id == null ? '' : this.props.initialValues.serviceci_id,
					label: this.props.initialValues.serviceci_name == null ? '' : this.props.initialValues.serviceci_name
				}]
			});
			this.setState({inputServiceCiId:this.props.initialValues.serviceci_id == null ? '' : this.props.initialValues.serviceci_id});
			this.setState({
				selectedServiceOwnerGroup: [{
					value: this.props.initialValues.service_owner_group_id == null ? '': this.props.initialValues.service_owner_group_id,
					label: this.props.initialValues.service_owner_group == null ?'': this.props.initialValues.service_owner_group
				}]
			});
			this.setState({serviceOwnerIcnId:this.props.initialValues.service_owner_id == null ? '' : this.props.initialValues.service_owner_id});
			this.setState({
				selectedServiceOwner: [{
					value: this.props.initialValues.service_owner_id == null ? '': this.props.initialValues.service_owner_id,
					label: this.props.initialValues.service_owner == null ? '': this.props.initialValues.service_owner
				}]
			});
			if(this.props.initialValues.ams_rule_id && this.props.initialValues.ams_rule_name && this.state.WorkItemTypeChange !== "standardChange") {
				this.setState({
					selectedContextRule: [{
						value: this.props.initialValues.ams_rule_id == null ? '' : this.props.initialValues.ams_rule_id,
						label: this.props.initialValues.ams_rule_name == null ? '' : this.props.initialValues.ams_rule_name
					}]
				});
			}
			if((this.props.initialValues.business_function_id || this.props.initialValues.business_function_id === 0) && this.props.initialValues.business_function_name) {
				let businessFunction = [
					{id: this.props.initialValues.business_function_id, businessFunctionName: this.props.initialValues.business_function_name || 'Default'}
				]
				this.setState({ selectedBusinessFn: businessFunction })
			}
		}
		this.props.getCompanyList("");
		this.props.getCriticalityList();
		const spcmReducer = this.props.spcmReducer;

		this.props.getServiceLevels(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
		this.props.loadBreakFixComplexity('Item');

		let slaCount = this.props.spcmReducer.slaStatusForFulfilment.Status;
		console.log('slaCount');
		console.log(slaCount);
		this.setState({
			slaCount: slaCount,
			baseLanguage: this.props.baseLanguage
		});
		this.props.setServiceStatus(this.props.spcmReducer.currentlySelectedServiceDetails.STATUS);

	}

	nextPreviousFun(value) {
		switch (value) {
			case 'Next':
				this.setState({ nextBtn: true });
				this.setState({ previousBtn: false });
				break;
			case 'Previous':
				this.setState({ nextBtn: false });
				this.setState({ previousBtn: true });
				break;
		}
	}

	onFeaturedToChange(e) {
		this.setState({ featured: e.target.value });
	}

	onExternalFulfilChange(e) {
		this.setState({ externalNumberVisible: e.target.value });
	}

	oncomplexityEditableChange(e) {
		this.setState({ complexityEditable: e.target.value });
	}

	businessFnInputChange(value) {
		this.setState({ typedBusinessFn: value });
		this.setBusinessFn([]);
		change('subcategory_id', '');
		this.props.resetCategoryList();
	}
	
	setBusinessFn(selectedBusinessFn) {
		const { change } = this.props;
		if(selectedBusinessFn.length > 0) {
			this.setState({ selectedBusinessFn: selectedBusinessFn })
			change("business_function_id", selectedBusinessFn[0].id);
			change("business_function_name", selectedBusinessFn[0].businessFunctionName);
			this.props.getCategoryList(this.state.selectedCompany, "serviceBoard", selectedBusinessFn[0].id);
		} else {
			this.setState({ selectedBusinessFn: [] });
			change("business_function_id", '');
			change("business_function_name", '');
			change('subcategory_id', '');
			this.props.resetCategoryList();
		}
	}
	
	onCrossClickBusinessFn() {
		const { change } = this.props;
		this.setState({ typedBusinessFn: '' });
		this.setBusinessFn([]);
		change("business_function_id", null);
		change("business_function_name", '');
		change('subcategory_id', '');
		this.props.resetCategoryList();
	}

	onWorkItemTypeChange(e) {
		const { change } = this.props
		this.setState({ WorkItemTypeChange: e.target.value });
		this.setState({ selectedContextRule: [] });
		this.setState({ contextualRulesModule: structuredClone(e.target.value === "incidentRequest" ? "incident" : e.target.value === "serviceRequest" ? "fulfillment" : "changemanagement") });
		change('complexity', '');
		change('service_ci_base_id', '');
		if (e.target.value !== "standardChange") {
			const moduleid = this.getModuleID(e.target.value);
			// this.props.getContextualRules(this.state.selectedCompany, moduleid);
		}
	}

	onCompanyChange(e) {
		const { change } = this.props
		
		if(e.target.value === "") {
			this.props.resetCategoryList();
			change('service_owner_group', null);
			change('service_owner_group_id', null);
			change('service_owner', null);
			change('service_owner_id', null);
			this.props.getManagerList(this.props.spcmReducer.currentlySelectedServiceDetails.SERVICE_OWNER_GROUP_ID,'EMPTY_MANAGER_LIST');
			this.setState({ selectedServiceOwnerGroup: [], typedServiceOwnerGroup: '' });
			this.setState({ selectedServiceOwner: [], typedServiceOwner: '' });
		}
		this.props.getCategoryList(e.target.value, "serviceBoard", 0);
		// this.props.businessFunctionByCompanyId(e.target.value);
		this.props.getServiceCIs("0", "test");
		this.setState({ selectedCompany: e.target.value });
		this.setState({ selectedServiceCi: [] });
		this.setState({ selectedContextRule: [] });
		// this.setState({ selectedBusinessFn: [] });
		// this.setState({typedServiceCi:''});
		console.log("onCompanyChange", typeof e.target.value)
		change('subcategory_id', '');
		change('serviceci_name', '');
		change('serviceci_id', '');
		change('service_owner', '');
		change('service_owner_id', '');
		change('service_owner_group', '');
		change('service_owner_group_id', '');
		this.setState({ selectedServiceOwnerGroup: [], typedServiceOwnerGroup: '' });
		this.setState({ selectedServiceOwner: [], typedServiceOwner: '',serviceOwnerIcnId: ''  });
		this.props.rightSidePageNoFun(0);
		this.setState({isShowRequestorDetails:false});
		// change('business_function_name', '');
		// change("business_function_id", '');
		this.props.serviceOwnerGroupAction(e.target.value,60,'false');

		GLOBAL.SelectedServiceDetailcompanyID = e.target.value;
		if (this.state.WorkItemTypeChange !== "standardChange") {
			const moduleid = this.getModuleID(this.state.WorkItemTypeChange)
			// this.props.getContextualRules(e.target.value, moduleid);
		}
	}

	timeChange(e) {
		let str = ''
		Object.keys(e).forEach(key => {
			if (key <= 18)
				str += e[key];
			console.log(str);
		})
		this.props.setExpirationDate(str);
	}
	componentWillUnmount() {
		this.props.resetBusinessFunctionList();
		this.props.editServiceToggle(false);
		this.props.resetCategoryList();
	}
	onCrossClickServiceCi() {
		const { change } = this.props;
		change('serviceci_name', '');
		change('serviceci_id', '');
		this.setState({ selectedServiceCi: [], typedServiceCi: '',inputServiceCiId:''});
		this.props.rightSidePageNoFun(0);
		this.setState({isShowDetails:false});
	}
	onCrossClickServiceOwnerGroup() {
		const { change } = this.props;
		change('service_owner_group', null);
		change('service_owner_group_id', null);
		change('service_owner', null);
		change('service_owner_id', null);
		this.setState({ selectedServiceOwnerGroup: [], typedServiceOwnerGroup: '' });
		this.setState({ selectedServiceOwner: [], typedServiceOwner: '',serviceOwnerIcnId: ''  });
		this.props.rightSidePageNoFun(0);
		this.setState({isShowRequestorDetails:false});
		this.props.getManagerList(this.props.spcmReducer.currentlySelectedServiceDetails.SERVICE_OWNER_GROUP_ID, 'EMPTY_MANAGER_LIST');

	}
	onCrossClickServiceOwner() {
		const { change } = this.props;
		change('service_owner', null);
		change('service_owner_id', null);
		this.setState({ selectedServiceOwner: [], typedServiceOwner: '',serviceOwnerIcnId: '' });
		this.props.rightSidePageNoFun(0);
		this.setState({isShowRequestorDetails:false});


	}
	onServiceCiChange(value) {
		console.log("typedServiceCi", value);
		this.setState({ typedServiceCi: value });
		this.setState({isShowDetails:false});
		if (this.props.editServiceState) {
			this.props.getServiceCIs(this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID, value);

		} else {
			if (this.state.selectedCompany.trim() == "") {
				this.props.getServiceCIs("0", value);
			} else {
				this.props.getServiceCIs(this.state.selectedCompany, value);
			}
		}
	}
	onServiceOwnerGroupChange(value) {
		this.setState({ typedServiceOwnerGroup: value });
		if(value == ""){
			this.props.dispatch(change('service_owner', null));
			this.props.dispatch(change('service_owner_id', null));
			this.setState({ selectedServiceOwner: [], typedServiceOwner: '', serviceOwnerIcnId: '' });
			this.props.getManagerList(this.props.spcmReducer.currentlySelectedServiceDetails.SERVICE_OWNER_GROUP_ID, 'EMPTY_MANAGER_LIST');

		}
	}
	onServiceOwnerChange(value) {
		this.setState({ typedServiceOwner: value });
		this.setState({isShowRequestorDetails:false});

	}
	onGroupSelect(selectedOption) {
		const { change } = this.props;
		let updatedValue1;
		if (selectedOption.length > 0) {
			updatedValue1 = selectedOption[0].label;

			for (let i = 0; i < this.props.spcmReducer.service_ci_data.length; i++) {
				if (this.props.spcmReducer.service_ci_data[i].CI_ID === selectedOption[0].value) {
					selectedOption[0].label = updatedValue1;
					break;
				}
			}
		}else{
			updatedValue1 = '';
		}
		console.log("updatedValue1", updatedValue1);
		console.log("this.props.spcmReducer.service_ci_data", this.props.spcmReducer.service_ci_data);
		
		if (selectedOption.length > 0) {
			change("serviceci_name", selectedOption[0].label);
			change("serviceci_id", selectedOption[0].value);
			this.state.typedServiceCi = '';
			this.setState({ inputServiceCiId: selectedOption[0].value });
			this.props.rightSidePageNoFun(0);
		} else {
			change("serviceci_name", '');
			change("serviceci_id", '');
			this.setState({ inputServiceCiId:''});
		}
		this.setState({ selectedServiceCi: selectedOption });
	}

	onServiceOwnerGroupOptionSelect(selectedOption) {
		console.log("selectedCompany", this.state.selectedCompany);
		console.log("selectedOptionCreate", selectedOption);

		const { change } = this.props;
		if (selectedOption.length > 0) {
			change("service_owner_group", selectedOption[0].label);
			change("service_owner_group_id", selectedOption[0].id);
			this.state.typedServiceOwnerGroup = '';
		} else {
			change("service_owner_group", '');
			change("service_owner_group_id", '');
		}
		if (this.props.editServiceState) {
			console.log("selectedOption", selectedOption);
			if (selectedOption.length > 0){

				this.props.getManagerList(selectedOption[0].id, 'SERVICE_OWNER_BY_GROUPID');
			}else{

				this.props.getManagerList(this.props.spcmReducer.currentlySelectedServiceDetails.SERVICE_OWNER_GROUP_ID, 'SERVICE_OWNER_BY_GROUPID');			}
		}
		else{
			

			if (this.state.selectedCompany.trim() == "") {
				this.props.getServiceCIs(selectedOption[0].id, "EMPTY_MANAGER_LIST");
			} else if (this.state.selectedCompany && selectedOption[0]?.id ) {
				this.props.getManagerList(selectedOption[0].id, 'SERVICE_OWNER_BY_GROUPID');
			}
			

			// this.props.getManagerList(selectedOption[0].id, 'SERVICE_OWNER_BY_GROUPID');
		}

		this.setState({ selectedServiceOwnerGroup: selectedOption });
	}
	onServiceOwnerOptionSelect(selectedOption) {
		const { change } = this.props;
		if (selectedOption.length > 0) {
			change("service_owner", selectedOption[0].label);
			change("service_owner_id", selectedOption[0].id);
			this.state.typedServiceOwner = '';
			this.setState({ serviceOwnerIcnId: selectedOption[0].id });

		} else {
			change("service_owner", '');
			change("service_owner_id", '');
			change("serviceOwnerIcnId", '');
		}
		this.setState({ selectedServiceOwner: selectedOption });
	}

	getModuleID(workItem) {
		let moduleId;
		switch(workItem) {
			case 'serviceRequest':
				moduleId = 25;
				break;
			case 'incidentRequest':
				moduleId = 5;
				break;
			case 'standardChange':
				moduleId = 10;
				break;
		}
		return moduleId;
	}
	
	onContextRuleSelect(selectedOption) {
		if (!(selectedOption[0] && selectedOption[0].value && selectedOption[0].label)) {
			this.setState({ ctxRuleValueError: 'error' });
		}
		else {
			this.setState({ ctxRuleValueError: '' });
		}
		this.setState({ ctxRuleValueError: '', selectedContextRule: selectedOption });
	}

	setContextRuleError() {

	}
	onCrossClickContextRule() {
		const { change } = this.props;
		change('contextualAssignmentRule', '');
		this.setState({ selectedContextRule: [] });
	}
	descriptionCharacter(e){
		let input = e.target.value.length;
		let additionalchar = 120 - input;
		this.setState({
			additionalcharleft: additionalchar
		})
		console.log("e.target.value", input);
	}
	render() {
		console.log("SERVICE_cI", this.props.spcmReducer.service_ci_data);
		console.log("this.props.spcmReducer", this.props);
		let ciBasedmenu = this.props.spcmReducer.ciBasedDataDetails;
		console.log("ciBavav", ciBasedmenu);
		console.log(this.props.spcmReducer);
		console.log('complexity');
		console.log(this.props.complexity);
		console.log(this.props.spcmReducer.serviceDetailCounts);
		console.log(this.props.spcmReducer.serviceDetailCounts.SERVICELEVEL);
		console.log("this.state.selectedServiceCi", this.state.selectedServiceCi);
		console.log("this.props.serviceOwnerid", this.props.serviceOwnerid);
		console.log("this.props.label", this.props.label);
		const tr = this.props.tr;

		const featured = this.state.featured == 'Yes' ? true : false;
		const complexityEditable = this.state.complexityEditable == 'true' ? true : false;

		const delivery_mode_options1 = [{ value: "Shipment Based", text: "Shipment Based" }, { value: "Non-Shipment Based", text: "Non-Shipment Based" }, { value: "Other", text: "Other" }]

		const delivery_mode_options2 = [{ value: "Non-Shipment Based", text: "Non-Shipment Based" }];
		const delivery_mode_options3 = [{ value: "Other", text: "Other" }];

		const serviceTypes = this.props.spcmReducer.serviceTypes;


		let edit = this.props.spcmReducer.editId;
		let status = [];
		status.push({ value: this.props.spcmReducer.editService.status, text: this.props.spcmReducer.editService.status });
		switch (this.props.spcmReducer.editService.status) {
			case "Draft":
				status.push({ value: "Proposed", text: "Proposed" });
				break
			case "Proposed":
				status.push({ value: "Deployed", text: "Deployed" });
				break
			case "Deployed":
				status.push({ value: "Suspended", text: "Suspended" });
				status.push({ value: "Obsolete", text: "Obsolete" });
				break
			case "Suspended":
				status.push({ value: "Deployed", text: "Deployed" });
				break
		}
		let serviceCiOptions = [];
		let service_ci_data = this.props.spcmReducer.service_ci_data;
		service_ci_data.map((subItem) => {
			let serialNumber = (subItem.SERIAL_NUMBER == "" || subItem.SERIAL_NUMBER == null) ? "NA" : subItem.SERIAL_NUMBER; 
			let obj = {
				'value': subItem.CI_ID,
				'CILabel' :subItem.CI_CODE + " - " + subItem.STATUS_NAME,
				'label': subItem.CI_NAME,
				'serialLabel': serialNumber

			};
			serviceCiOptions.push(obj);

		})
		let serviceOwnerGroupOptions = this.props.spcmReducer.serviceOwnerGroupReducer;
		let serviceOwnerOptions = this.props.spcmReducer.managers == ""?[]:this.props.spcmReducer.managers;
		const { error, handleSubmit, pristine, submitting } = this.props;

		let panelColSize = this.props.panelResize;
		let panelCreColSize = this.state.panelCreResize;
		return (
			<div className="borderForm bFormDiv">
				<PanelGroup direction="horizontal">
					<Panel id="sidebar" minSize={33} order={1} defaultSize={this.state.isRightSideVisible ? 67 : 100} onResize={(size) => this.setState({ panelCreResize : size})} className={this.state.isRightSideVisible ? "isShowLeftPanel quickShadow" : "quickShadow"}>
					<ReduxForm onSubmit={handleSubmit(this.submitForm)} className="spcm-service-form">{console.log("STATUS---", this.state.editStatus)}
							{error == undefined || error == null ? null : <Alert variant="danger" className="errorMessageSec"><p>{error}</p></Alert>}
							<div className="padding-0">
								<div className='incigap'>
									<Row>{console.log('StatusState22 editForm Service', this.props.serviceStatusState)}
										<Col md={6}>
											<Form.Group className='form-group errspn'>
												<Form.Label bsClass=""><span className="rStar"></span> {tr['Name Your Service']}</Form.Label>
												<Field component={_inputField} name="offering_name" className='form-control' disabled={this.state.editStatus && this.state.editStatus == 'Deployed' ? true : false} maxLength = {120} onChange={(e)=>{this.descriptionCharacter(e)}}/>
												<p className="charLeft" style={{ textAlign: "right", "fontSize": "11px" }}>({tr['Characters Remaining']}: {this.state.additionalcharleft}/120)</p>
											
											</Form.Group>
										</Col>
										<Col md={6}>
											<Form.Group className='form-group mgdv'>
												<Form.Label bsClass=""><span className="rStar"></span>  {tr['Company']}</Form.Label>
												<div className=''><Field component={_dropDown} name="company_id" onChange={this.onCompanyChange} options={this.props.spcmReducer.companyList ? this.props.spcmReducer.companyList : []} className='form-control' disabled={this.state.editStatus && this.state.editStatus == 'Deployed' || this.state.editStatus == 'Suspended' || this.state.editStatus == 'Obsolete' || this.state.editStatus == 'Proposed' || this.state.editStatus == 'Draft' ? true : false} /></div>
											</Form.Group>
										</Col>
										{/* <Col md={6}>
										<Form.Group className='form-group'>
											<Form.Label bsClass="">
												<span className="rStar"></span>
												{tr["Business Function"]}
											</Form.Label>
											<div className='dvTypehd'>
												<Field
													id="BusinessFnField"
													name="businessFunction"
													component={TypeaheadandDropdown}
													className="form-control"
													options={this.props.parentFunctionByCompanyIdDropdown}
													selectedOptions={this.state.selectedBusinessFn}
													onSelection={this.setBusinessFn}
													diableStatus={this.state.editStatus && this.state.editStatus == 'Deployed' ? true : false}
													onInputChange={this.businessFnInputChange}
													onCrossClick={this.onCrossClickBusinessFn}
													typedValue={this.state.typedBusinessFn}
													labelKey={"businessFunctionName"}
												/>
											</div>
											</Form.Group>
										</Col> */}

										<Col md={6}>
											<Form.Group className='form-group mgdv'>
												<Form.Label bsClass=""><span className="rStar"></span> {tr['Category/Sub-Category']}</Form.Label>
												<Field component={_dropDown} options={this.props.spcmReducer.categoryList ? this.props.spcmReducer.categoryList : []} className='form-control' name='subcategory_id' disabled={this.state.editStatus && this.state.editStatus == 'Deployed' ? true : false} >
												</Field>
											</Form.Group>
										</Col>

										<Col md={6}>

											<Row>
												<Col lg={this.props.editServiceState == true ? (panelColSize <= 60 ? 12 : 4) : (panelCreColSize <= 60 ? 12 : 4)} md={this.props.editServiceState == true ? (panelColSize <= 60 ? 12 : 5) : (panelCreColSize <= 60 ? 12 : 5)} sm={5} >

													<Form.Group className='form-group'>
														<div><Form.Label bsClass=""><span className="rStar"></span> {tr['Featured']}</Form.Label></div>
														<div><Field component={_radio} className='form-control' type="radio" checked={featured} name='featured' value='Yes' disabled={this.state.editStatus && this.state.editStatus == 'Deployed' ? true : false} onChange={this.onFeaturedToChange} />
															<Field component={_radio} className='form-control' type="radio" checked={!featured} name='featured' value='No' disabled={this.state.editStatus && this.state.editStatus == 'Deployed' ? true : false} onChange={this.onFeaturedToChange} /></div>
													</Form.Group>

												</Col>

												{this.state.WorkItemTypeChange == "serviceRequest" ?
													<Col lg={this.props.editServiceState == true ? (panelColSize <= 60 ? 12 : 8) : (panelCreColSize <= 60 ? 12 : 8)} md={this.props.editServiceState == true ? (panelColSize <= 60 ? 12 : 7) : (panelCreColSize <= 60 ? 12 : 7)} sm={7} >
														<Form.Group className='form-group'>
															<Form.Label bsClass="">Quantity</Form.Label>
														</Form.Group>

														<div className="">
															{/* style={{ position: "relative", top: "5px", marginLeft: "0px" }} */}
															<Row>
																<Col xs={6} className='minmaxdiv'>
																<Form.Group className='form-group'>
																	<Form.Label bsClass="" >Min</Form.Label>
																</Form.Group>
																<Form.Group className='form-group'>
																	<Field component={itemQuantityField} max={999999} min={1}
																		name="min_value"
																		className='form-control shotSpinSer' disabled={this.state.editStatus && this.state.editStatus == 'Deployed' ? true : false} onChange={(e) => {
																			this.setState({ minItemQuantity: e.target.value });

																		}} />
																</Form.Group>
																</Col>
																<Col xs={6}  className='minmaxdiv'>
																<Form.Group className='form-group'>
																	<Form.Label bsClass="">Max</Form.Label>
																</Form.Group>
																<Form.Group className='form-group'>
																	<Field component={itemQuantityField} max={999999} min={1}
																		name="max_value" disabled={this.state.editStatus && this.state.editStatus == 'Deployed' ? true : false}
																		className='form-control shotSpinSer' onChange={(e) => {
																			this.setState({ maxItemQuantity: e.target.value });
																		}} />
																</Form.Group>
																</Col>
															</Row>
														</div>
													</Col> : ''}
											</Row>

										</Col>
										<Col md={6}>
											<Form.Group>
												<Form.Label bsClass=""><span className="rStar"></span> {tr['Ordering Work Item Type']}</Form.Label>
												<Field component={_dropDown} options={workItemTypeOptions} className='form-control' name='work_item_type' disabled={this.state.editStatus && this.state.editStatus == 'Deployed' || this.state.editStatus == 'Suspended' || this.state.editStatus == 'Obsolete' || this.state.editStatus == 'Proposed' || this.state.editStatus == 'Draft' ? true : false} onChange={this.onWorkItemTypeChange}>
												</Field>
											</Form.Group>
										</Col>

										<Col md={6}>

											<Form.Group>
												<Form.Label bsClass=""><span className="rStar"></span> {tr['Criticality']}</Form.Label>
												<Field component={_dropDown} className='form-control' name='service_criticality' disabled={this.state.editStatus && this.state.editStatus == 'Deployed' ? true : false} />
											</Form.Group>
										</Col>

										{this.state.WorkItemTypeChange == "serviceRequest" ?
											<Col md={6}>
												<Form.Group className='form-group mgdv'>
													<Form.Label bsClass=""><span className="rStar"></span> {tr['Default Complexity']}</Form.Label>
													<div className=''><Field component={_dropDown} options={this.props.complexity ? this.props.complexity : []} className='form-control' name='complexity' disabled={this.state.editStatus && this.state.editStatus == 'Deployed' ? true : false}>
													</Field></div>
												</Form.Group>
											</Col>

											: null}

										{this.state.WorkItemTypeChange == "standardChange" ?

											<Col md={6}>
												<Form.Group>
													<Form.Label bsClass=""><span className="rStar"></span>{tr['Catalog Based / CI Based']}</Form.Label>
													<Field component={_dropDown} options={ciBasedmenu} className='form-control' name='service_ci_base_id' disabled={this.state.editStatus && this.state.editStatus == 'Deployed' ? true : false}>
													</Field>
												</Form.Group>
											</Col>
											: null}

										{this.state.WorkItemTypeChange == "serviceRequest" || this.state.WorkItemTypeChange == "incidentRequest" ?
											<Col md={6}>
												<Form.Group>
													<Form.Label bsClass=""><span className="rStar"></span>Service CI
														{this.state.externLink == true ?
															<div style={{ "position": "initial" }} className='exterLnkicn'><Link className="gotoRuleBtnIcn" role="button" to="/cmdblist" title="Go to Asset/Config Board" ><TbExternalLink /></Link></div>
															:
															null
														} </Form.Label>
													<div className={this.state.inputServiceCiId !='' ?"infoBtnDiv withCloseIcn":null}>
													<Field
														name="serviceci_name"
														className='form-control'
														component={TypeaheadandDropdown}
														options={serviceCiOptions}
														onCrossClick={this.onCrossClickServiceCi}
														onSelection={this.onGroupSelect}
														selectedOptions={this.state.selectedServiceCi}
														onInputChange={this.onServiceCiChange}
														typedValue={this.state.typedServiceCi}
														diableStatus={this.state.editStatus && this.state.editStatus == 'Deployed' ? true : false}
													/>
													
											{this.state.inputServiceCiId !='' ?<Button title="Info" bsPrefix=" " className="infoicn" onClick={() => { this.props.rightSidePageNoFun(7);this.props.setServiceCiId(savedCiId);this.setState({isShowDetails:true});this.props.showRightSide(true);}}><ImInfo/></Button>:null}
											</div>
										</Form.Group>
									</Col>
									: null}

										<Col md={6}>
											<Form.Group className='form-group mgdv'>
												<Form.Label bsClass=""> {tr['Externally Fulfilled']}</Form.Label>
												<div className=''><Field component={_dropDown} name="externally_fulfilled" className='form-control' options={[{ value: "false", text: "No" }, { value: "true", text: "Yes" }]} onChange={this.onExternalFulfilChange} disabled={this.state.editStatus && this.state.editStatus == 'Deployed' ? true : false} /></div>
											</Form.Group>
										</Col>
										{this.state.externalNumberVisible == "true" ?

											<Col md={6}>
												<Form.Group className='form-group mgdv'>
													<Form.Label bsClass="">{tr['Use External Number']}</Form.Label>
													<Field component={_dropDown} name="use_external_number" className='form-control' options={[{ value: "false", text: "No" }, { value: "true", text: "Yes" }]} disabled={this.state.editStatus && this.state.editStatus == 'Deployed' ? true : false} />
												</Form.Group>
											</Col> : null
										}



										<Col md={6}>
											<Form.Group className='form-group mgdv'>
												<Form.Label bsClass="">{tr['Delivery Mode']}</Form.Label>

												<Field component={_dropDown} name="delivery_mode" className='form-control' options={this.state.WorkItemTypeChange == "serviceRequest" ? delivery_mode_options1 : this.state.WorkItemTypeChange == "incidentRequest" ? delivery_mode_options2 : delivery_mode_options3} disabled={this.state.editStatus == 'Deployed' ? true : false} />
											</Form.Group>
										</Col>

										{this.state.WorkItemTypeChange == "serviceRequest" ?
											<Col md={6}>
												<Form.Group className='form-group mgdv'>
													<Form.Label bsClass="">{tr['Service Type']}</Form.Label>

													{/*	<Field component={_dropDown} name="offering_type" className='form-control' options={[{ value: "HR", text: "HR" },{ value: "Intune", text: "Intune" },{ value: "JAMF", text: "JAMF" }, { value: "Others", text: "Others" }]} disabled={this.state.editStatus &&this.state.editStatus == 'Deployed' ? true : false || this.state.WorkItemTypeChange == "incidentRequest"} />*/}
													<Field component={_dropDown} name="offering_type" className='form-control' options={serviceTypes} disabled={(this.state.editStatus && this.state.editStatus == 'Deployed' ? true : false) || this.state.WorkItemTypeChange == "incidentRequest" || this.state.WorkItemTypeChange == "standardChange"} />
												</Form.Group>
											</Col>
											: null}

										<Col md={6}>
											<Form.Group className='form-group mgdv'>
												<Form.Label bsClass="">{tr['Base Language']}</Form.Label>
												<Field component={_inputField} name="base_language" className='form-control' disabled={true} defaultValue={this.state.baseLanguage} />
											</Form.Group>
										</Col>

										<Col md={6}>
											<Form.Group>
												<Form.Label bsClass=""> {tr['Search Tags']}</Form.Label>
												<Field component={_inputField} name="tags" className='form-control' readOnly={this.state.editStatus && this.state.editStatus == 'Deployed' ? true : false} />
											</Form.Group>
										</Col>
										{ this.state.WorkItemTypeChange !== "standardChange" ? <Col md={6}>
											<Form.Group>
												<Form.Label bsClass=""> {tr['Contextual Assignment Rule']}
													{this.state.externLinkAMS == true ?
														<div style={{ "position": "initial" }} className='exterLnkicn'><Link className="gotoRuleBtnIcn" role="button" to="/rules" title="Go to Rules" ><TbExternalLink /></Link></div>
														:
														null
													}
												</Form.Label>		
												<RuleAmsDdropDownContextual 
													name="contextualAssignmentRule"
													component={TypeaheadandDropdown}									
													onSelection={this.onContextRuleSelect}
													selectedOptions={this.state.selectedContextRule}
													diableStatus={this.state.editStatus && this.state.editStatus == 'Deployed' ? true : false}
													onCrossClick={this.onCrossClickContextRule}
													formData={this.props}
													className="form-control"
													moduleName={this.state.contextualRulesModule}
													ruleType="assignment"
													companyId={this.props.editServiceState ? this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID : this.state.selectedCompany}
													/>
												{/* <Field
													name="contextualAssignmentRule"
													component={TypeaheadandDropdown}
													options={this.props.contextRuleList || []}
													onSelection={this.onContextRuleSelect}
													selectedOptions={this.state.selectedContextRule}
													diableStatus={this.state.editStatus && this.state.editStatus == 'Deployed' ? true : false}
													onCrossClick={this.onCrossClickContextRule}
													className="form-control"
												/> */}
											</Form.Group>
										</Col> : ""}
									</Row>
									
											<div className="padding-t-5 padding-r-10 padding-l-10 border margin-b-10">
											<div className="bgGray padding-5">										
												Service Owner
												</div>
												<Row>
									<Col md={6}>
												<Form.Group>
													<Form.Label bsClass="">{tr["Group"]}</Form.Label>
													<Field
														name="service_owner_group"
														className='form-control'
														component={TypeaheadandDropdown}
														options={serviceOwnerGroupOptions}
														onCrossClick={this.onCrossClickServiceOwnerGroup}
														onSelection={this.onServiceOwnerGroupOptionSelect}
														selectedOptions={this.state.selectedServiceOwnerGroup}
														onInputChange={this.onServiceOwnerGroupChange}
														typedValue={this.state.typedServiceOwnerGroup}
														diableStatus={this.state.editStatus && this.state.editStatus == 'Deployed' ? true : false}
													/>
												</Form.Group>
											</Col>
								
									<Col md={6}>
												<Form.Group>
													<Form.Label bsClass="">{tr["Individual"]}</Form.Label>
											<div className={this.state.serviceOwnerIcnId == '' || this.state.serviceOwnerIcnId == null ? null :"infoBtnDiv withCloseIcn"}>
													<Field
														name="service_owner"
														className='form-control'
														component={TypeaheadandDropdown}
														options={serviceOwnerOptions}
														onCrossClick={this.onCrossClickServiceOwner}
														onSelection={this.onServiceOwnerOptionSelect}
														selectedOptions={this.state.selectedServiceOwner}
														onInputChange={this.onServiceOwnerChange}
														typedValue={this.state.typedServiceOwner}
														diableStatus={this.state.editStatus && this.state.editStatus == 'Deployed' ? true : false}
													/>
														{this.state.serviceOwnerIcnId == '' || this.state.serviceOwnerIcnId == null ? null : <Button title="Info" bsPrefix=" " className="infoicn" onClick={() => { this.props.requestorDetailsFun(this.state.serviceOwnerIcnId, 'Individual Details'); this.props.rightSidePageNoFun(8); this.setState({ isShowRequestorDetails: true }); this.setState({ isShowDetails: false });this.props.showRightSide(true); }}><ImInfo/></Button>
										 }
											</div>
												</Form.Group>
											</Col>
											</Row>
											</div>
									

									{this.state.descriptionState && <Row>
										<Col md={12}>
											<Form.Group>
												<Form.Label bsClass=""> {tr['Short Description']}</Form.Label>
												{/* {<Field component={_textArea} name="summary" className='form-control' rows="1" disabled={this.state.editStatus &&this.state.editStatus == 'Deployed' ? true : false} /> */}
												<Field name="summary" component={_ckeditor} className={this.state.editStatus && this.state.editStatus == 'Deployed' ? "readOnlyClass reactQuillEditor-area" : "reactQuillEditor-area"} richTextConfig ={"minRichTextEditor"}/>
											</Form.Group>
										</Col>
									</Row>}

									{this.state.descriptionState && <Row>
										<Col md={12}>
											<Form.Group>
												<Form.Label bsClass=""><span className="rStar"></span> {tr['Description']}</Form.Label>
												{/* {<Field component={_textArea} name="description" className='form-control' rows="3" disabled={this.state.editStatus&&this.state.editStatus=='Deployed'?true:false}/>} */}
												{/* <HtmlEditor /> */}
												<Field name="description" component={_ckeditor} className={this.state.editStatus && this.state.editStatus == 'Deployed' ? "readOnlyClass reactQuillEditor-area" : "reactQuillEditor-area"} />
											</Form.Group>
										</Col>
									</Row>}

									<Row>
										{this.props.editServiceState ? null :
											<Col md={6}>

												<Form.Group>
													<Form.Label bsClass=""><span className="rStar"></span> {tr['Status']}</Form.Label>
													{
														this.props.spcmReducer.editId && this.props.spcmReducer.editId > 0 ? <Field component={_dropDown} options={status} className='form-control' name='status' >
														</Field> : <Field component={_inputField} className='form-control' name='status' disabled={true} defaultValue={this.state.defaultStatus}>
														</Field>
													}

												</Form.Group>

											</Col>
										}
									</Row>							
								</div>
							</div>
						</ReduxForm>
					</Panel>
					{ this.props.editServiceState == false && 
					<>
					<PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
					<Panel minSize={33} order={2} defaultSize={33} className={this.props.editServiceState == false ? "isShowLeftPanel" : ""}>					
					{this.state.isShowDetails  ?								
						<div className="stickyArea rBoxStyle">
						<CIDetails
							translator={tr}
							ciId={this.props.serviceCiId}
							rightEditPanel={this.props.showRightSide}
						/>
						</div>						
					: this.state.isShowRequestorDetails  ?								
							<div className="stickyArea rBoxStyle">
					
								<UserDetails
									userId={this.props.serviceOwnerid}
									userDetailsLabel={this.props.label}
									translator={tr}
									rightEditPanel={this.props.showRightSide}
								/>
								</div>
					: null}
					</Panel>
					</>	
					}
				</PanelGroup>
			</div>
		);
	}
}

const mapStateToProps = ({ spcmReducer, complexity, cartCount,postAttributeBasedSlaReducer, parentFunctionByCompanyIdDropdown }) => {
	return {
		spcmReducer, complexity,
		initialValues: spcmReducer.editId > 0 ? spcmReducer.editService : '',
		baseLanguage: cartCount.languageText,
		contextRuleList: spcmReducer.contextual_rules,postAttributeBasedSlaReducer,
		parentFunctionByCompanyIdDropdown
	}
};
CreateSerForm = reduxForm({
	form: 'CreateSerForm',
	validate,
	destroyOnUnmount: true
})(CreateSerForm)

export default connect(mapStateToProps, { spcmLoader, getAllServicesList, getEditServiceDetails, getCategoryList, getCriticalityList, getCompanyList, getPortfolioGroupList, getPortfolioManagerList, getCatalogueGroupList, getCatalogueManagerList, getServiceLevels, loadBreakFixComplexity, getServiceTypes, getCIBaseData, getServiceDetailsDataList, getServiceCIs, getServiceDetailCounts, getContextualRules,postAttributeBasedSlaAction,serviceOwnerGroupAction, businessFunctionByCompanyId, resetBusinessFunctionList, resetCategoryList,getManagerList }, null, { withRef: true })(CreateSerForm);