
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Row, Col, Form } from "react-bootstrap";

const SystemInfo = ({ headers, values }) => {
  const headerLabelPairs = headers?.map((header, index) => ({
    header,
    label: header.replace(/\s+/g, ""),
  }));

  return (
    <div
      className={`margin-t-5 padding-t-5 padding-r-10 padding-l-10 margin-b-15 border p-2`}
    >
      <div className="bgGray padding-5">System Information</div>
      <Row className="mt-3">
        {headerLabelPairs.map(({ header, label }, index) => (
          <Col md={6} key={index} className="pt-2">
            <Form.Label htmlFor={label}>{header}</Form.Label>
            <Form.Control type="text" disabled value={values[index]} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default SystemInfo;
