
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export const articleCategoryDropdownList = (state = [], action)=> {
	switch (action.type){
		case 'LOAD_ARTICLECATEGORYDROPDOWN_SUCCESS':
			//alert(action.breakFix.data.);
			return ((action.articleCategoryDropdown && action.articleCategoryDropdown.data) ? action.articleCategoryDropdown.data : []);
		default:
			return state;
	}
}

export const articleServicesDropdownList = (state = [], action)=> {
	switch (action.type){
		case 'LOAD_ARTICLESERVICESDROPDOWN_SUCCESS':
			//alert(action.breakFix.data.);
			return ((action.articleServicesDropdown && action.articleServicesDropdown.data) ? action.articleServicesDropdown.data : []);
		default:
			return state;
	}
}

export const articleResultsList = (state = [], action)=> {
	// if(action.type=='REFRESH_ARTICLE_RESULT') {
	// 	console.log('&&&&&&&&&&&&&&&&&&&',action.articleResult)
	// }
	switch (action.type){
		case 'LOAD_ARTICLERESULTS_SUCCESS':
			//alert(action.breakFix.data.);
			// return ((action.articleResultsResponse && action.articleResultsResponse.data) ? action.articleResultsResponse.data : []);
			// console.log('&&&&&&&&&&&&&&&&&&&',action.articleResultsResponse)
			if(action.articleResultsResponse && action.articleResultsResponse != undefined && action.articleResultsResponse.data && Array.isArray(action.articleResultsResponse.data) && action.articleResultsResponse.data.length) {
				// console.log('trueeeeeeeeeeeeeeeeee')
				return action.articleResultsResponse.data;
			}
			else{
				// console.log('falseeeeeeeeeeeeeeeeeeee')
				return( [] );
			}

		case 'REFRESH_ARTICLE_RESULT':
			//alert(action.breakFix.data.);
			return action.articleResult;
		default:
			return state;
	}
}
