
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import axios from 'axios';
import swal from 'sweetalert';
import { GLOBAL } from "_Globals";

import { loadChangeEditData}  from '../../../../actions/changeManagement/changeManagementAction.js';
import { loadStatusBasedHamburgerOptions,loadHamburger} from '../../../../actions/common/loadStatusBasedHamburgerOptions';
import { loadTimelineData } from '../../../../actions/common/commonApisActions.js';
import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";

import configureStore from '../../../../store/configureStore';
import Cookies from "universal-cookie";
const cookies = new Cookies();
let languageSelected = cookies.get("gph");
languageSelected = languageSelected.split("~");
languageSelected = languageSelected[20];

const store = configureStore();

class CancelFormTab extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
			issueValidationError: '',
			savedCancelMessage: '',
			issue: '',
			saveLoader:false,
			typeList: [],
			selectedCancellationID: '',
			selectedCancellationName: '',
			ErrorCancellationType: '',
			maxLength: 500
		};
		this.getIssue = this.getIssue.bind(this);
		this.setCancellationType = this.setCancellationType.bind(this);
		this.retriveCancellationReasonList = this.retriveCancellationReasonList.bind(this);
	}
	retriveCancellationReasonList(companyId,module,field,language,status){
		axios.get(`/api/company_menulist?companyId=${companyId}&field=${field}&language=${language}&status=${status}&module=${module}`).then((responseCalnellationData)=>{
            this.setState({typeList: responseCalnellationData.data});
		});
	}
	componentWillMount(){
      this.retriveCancellationReasonList(this.props.editChangeInitialData.consumerCompany,'Change','Cancellation Reason',languageSelected,'1');
	}
    getIssue(event) {
		if(event.target.value==='' || event.target.value.trim().length===0){
			this.setState({issueValidationError: 'error'});
		 }else{
			this.setState({issueValidationError: ''});
		 }
		this.setState({issue: event.target.value});
	}
    postRequest(editChangeInitialData) {
		let calcelReason="Cancellation Type: "+this.state.selectedCancellationName+" ,Cancellation Reason: "+this.state.issue;
		if (this.props.editChangeInitialData.statusId!=="30" && (this.props.user_id !== this.props.editChangeInitialData.implementationIndividual && this.props.user_id !== this.props.editChangeInitialData.changeManagementIndividual) && (this.props.roleIdentification('19') || this.props.roleIdentification('20') || this.props.roleIdentification('51') || this.props.roleIdentification('52'))){
			swal({
			  text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
			  button: this.props.translator['OK'],
			});
			return;
		}else if(this.props.editChangeInitialData.statusId!=="30" && (this.props.user_id===this.props.editChangeInitialData.implementationIndividual) && !(this.props.roleIdentification('51') || this.props.roleIdentification('52')) &&  (this.props.editChangeInitialData.changeManagementIndividual!==this.props.user_id)){
			swal({
			  text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
			  button: this.props.translator['OK'],
			});
			return;
		}else if(this.props.editChangeInitialData.statusId!=="30" && (this.props.editChangeInitialData.changeManagementIndividual===this.props.user_id) && !(this.props.roleIdentification('19') || this.props.roleIdentification('20')) && (this.props.user_id !== this.props.editChangeInitialData.implementationIndividual)){
			swal({
			  text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
			  button: this.props.translator['OK'],
			});
			return;
		}
		let errorCount=0;
		if (this.state.issue == ""||this.state.issue.trim()=="") {
			this.setState({issueValidationError: "error"});
			errorCount++;
		} else {
			this.setState({issueValidationError: ''});
		}
		if(this.state.selectedCancellationID===''){
           this.setState({ErrorCancellationType: 'error'});
		   errorCount++;
		}else{
			this.setState({ErrorCancellationType: ''});
		}
		if(errorCount){
			return false;
		}
		let postjsonactivity = {
			"itemId":editChangeInitialData.changeId,
			"description":calcelReason,
			"isInternal":"N",
			"createdOn":"",
			"createdBy":"",
			"module":"ChangeManagement",
			"createdByName":""
		};
		let reff=this;
		reff.setState({
			saveLoader:true,
			savedCancelMessage:this.state.issue
		});
		reff.props.loadHamburger();
		axios.patch(GLOBAL.changeEditUrl + editChangeInitialData.changeId, {
		  "cancellationType": this.state.selectedCancellationID,
		  "cancellationTypeValue": this.state.selectedCancellationName,
		  "cancellationReason": this.state.issue,
		  "status": "75",
		  "systemStartDateRequired": "N",
		  "systemEndDateRequired": "N",
		  "modifiedBy": "",
          "modifiedByName": "",
		}).then(function (response) {
		  if (response.status === 200) {
			store.dispatch({type: 'CHANGE_EDIT_DATA_LOAD_SUCCESS', editChangeInitialData: {}});
			axios.post(GLOBAL.breakFixActivityLogPostUrl, postjsonactivity).then(function (response) {
							this.setState({saveLoader:false});
			}).catch(function (error) {
				this.setState({saveLoader:false});
			});
			reff.props.loadChangeEditData(editChangeInitialData.changeId);
			reff.props.loadStatusBasedHamburgerOptions('ChangeManagement',response.data.status,response.data.changeId);
			reff.props.changeActionView('cancel');
		  }
		}).catch(function (error) {
			reff.props.loadHamburger();
			this.setState({saveLoader:false});
		});
	}
	renderCancellationType(values){
		return values.map(item => <option key={item.field1Key} value={item.field1Value}>{item.field1Key}</option>);
	}
	setCancellationType(event){
		if(event.target.value===''){
           this.setState({ErrorCancellationType: 'error'});
		}else{
		   this.setState({ErrorCancellationType: ''});
		}
       this.setState({selectedCancellationID: event.target.value, selectedCancellationName: event.target[event.target.selectedIndex].text});
	}
    render(){
        if (this.props.editChangeInitialData.status != "Cancelled") {
			return (
				<div className="hambTab">
					<div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
						<ul>
							<li>
							<button
							    type='button'
								title="Minimize the right panel"
								bsClass=""
								bsStyle=""
								className="closerightPanelBtn"
								onClick={() => {
								this.props.rightEditPanel(false);
								}}
							>
								<IoClose/>
							</button>
							</li>
						</ul>
					</div>
					<div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Cancellation Reason']}</div></div>
					<div className='rBoxGap'>
					<Form.Group className='margin-t-5'>
					    <Form.Label bsClass=""><span className="rStar"/>{this.props.translator["Cancellation Type"]}</Form.Label>
                            <Form.Select required="true" componentClass="select" onChange={this.setCancellationType} className={this.state.ErrorCancellationType?'error':''}>
                                <option value="">Select</option>
                                {this.renderCancellationType(this.state.typeList)}
                            </Form.Select>
                    </Form.Group>
					{this.state.ErrorCancellationType=='error'? <div><font color="red"><strong>{this.props.translator['Please select the Cancellation Type']+'!'}</strong></font></div> : <div></div>}
					<Form.Group className='margin-t-5'>
						<Form.Label><span className="rStar"></span>{this.props.translator['Reason']} </Form.Label>
						<Form.Control as="textarea" rows="4" onChange={this.getIssue.bind(this)} className={this.state.issueValidationError?'error':''} maxLength={this.state.maxLength}/>
						<p className="charLeft" style={{textAlign:"right","fontSize":"11px"}}>({this.props.translator['Characters Remaining']}: {this.state.maxLength-this.state.issue.length}/{this.state.maxLength})</p>
					</Form.Group>
					{this.state.issueValidationError == "error" ? <div><font color="red"><strong>{this.props.translator['Please provide the reason for cancellation']+'!'}</strong></font></div> : <div></div>}
					{
						<ButtonToolbar className="black margin-t-10">
						   <Button className="rgSidrkBtn smallBtn" bsSize="small" bsStyle="primary" disabled={this.state.saveLoader} onClick={() => {this.postRequest(this.props.editChangeInitialData);}} >
							{this.state.saveLoader ?<ImSpinner6 className="icn-spinner"/> : null} 
                            {this.props.translator['Save']}
						   </Button>
						</ButtonToolbar>
					}
					</div>
				</div>
			);
		}else if(this.props.editChangeInitialData.status == "Cancelled"){
		    return (<div>{this.props.changeActionView('processHierarchy')}</div>);
		}
    }
}
export default connect(null, {loadChangeEditData,loadHamburger, loadStatusBasedHamburgerOptions, loadTimelineData })(CancelFormTab);

