
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Row, Col, Button, Form, InputGroup, Image, Alert } from 'react-bootstrap';
import { TypeaheadExampleSingleSelect, _dropDown, _inputField } from "../../../../common/formFields";
import { Field, change, reduxForm } from "redux-form";
import { connect, useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { getCostCenterCompanyOptions, getEditCostCenterPageDetails } from "../../../../../actions/foundation/costCenterAction";
import { useParams } from "react-router";
import ListLoader from "../../../../common/loaders/ListLoader";

 let EditForm =(props)=> {
    
    const [companyList, setCompanyList] = useState([]);
    const [selectedCompanyList, setSelectedCompanyList] = useState([]);
    const [statusValue, setStatusValue] = useState([]);
     
     const dispatch = useDispatch();
     const params = useParams();
     const initialValue = useSelector((state) => state.getCostCenterEditDetails);
     const companyData = useSelector((state) => state.costCenterCompanyDropdown);
     const statusSelect = useSelector((state) => state.costCenterStatusValues);
     const loader = useSelector((state) => state.commonLoader);
     useEffect(() => {
         dispatch(getEditCostCenterPageDetails(params.id));
    },[])

     useEffect(() =>
     {
       if(companyData && companyData.length > 0)
       {
         setCompanyList(companyData)
        }
        if (statusSelect && statusSelect.length > 0) {
            let statusList = [];
            statusSelect.map((status) => {
                statusList.push({label: status.field1Key, id:status.field1Value})
            })
            setStatusValue(statusList);
        }
 
     }, [companyData, statusSelect])
     
     useEffect(() => {
         if (initialValue && initialValue.company_name) {
             setSelectedCompanyList([{
                 label: initialValue.company_name,
                 id: initialValue.company_id
             }])
             dispatch(change('EditCostCenter', 'company_name', initialValue.company_name))
             dispatch(change('EditCostCenter', 'company_id', initialValue.company_id))
         }
     }, [initialValue])
     
    const onCompanySelection=(selectedCompany)=>{
        if (selectedCompany.length > 0) { 
            setSelectedCompanyList(selectedCompany)
            dispatch(change('EditCostCenter', 'company_name', selectedCompany[0].label))
            dispatch(change('EditCostCenter', 'company_id', selectedCompany[0].id))
            props.setCompanyValue(selectedCompany)
          } else {
            setSelectedCompanyList([]);
            dispatch(change('EditCostCenter', 'company_name', ''))
            dispatch(change('EditCostCenter', 'company_id', ''))
          }
    }
    const onCrossClickCompany = () => {
        setSelectedCompanyList([])
    }

    const  validateEditCostCenterField = (fieldType, event)=> {
        switch (fieldType) {
          case "costcenter_name":
            if (event.target.value == "") {
              props.setNameErr("error");
            } else {
              props.setNameErr("");
            }
            break;
          case "ccStatus":
            if (event.target.value == "") {
              props.setStatusErr("error");
            } else {
              props.setStatusErr("");
            }
            break;
          default:
        }
      }
    

   return (
     <>
       {loader.isLoading ? (
        <ListLoader />
      ) : (
        <Form>
            <Row>
                <Col md={6} sm={6} xs={12}>
                    <Form.Group className="form-group">
                        <Form.Label>{props.tr["Cost Center Code"]}</Form.Label>
                        <Field
                            name="costcenter_code"
                            className={"form-control"}
                            component={_inputField}
                            disabled
                        />
                    </Form.Group>
                </Col>

                <Col md={6} sm={6} xs={12}>
                 <Form.Group className="form-group">
                 <span className="rStar"></span>
                        <Form.Label>{props.tr["Name"]}</Form.Label>
                        <Field
                            component={_inputField}
                            name="costcenter_name"
                            placeholder=""
                            className={"form-control "+(props.nameErr)}
                            maxLength={50}
                            onBlur={(event) => {
                                validateEditCostCenterField("costcenter_name", event);
                              }}
                        />
                    </Form.Group>
                </Col>

                <Col md={6} sm={6} xs={12}>
                 <Form.Group className="form-group">
                    <Form.Label bsClass="">
                    {props.tr["Company"]}
                    </Form.Label>
                    <Field
                        name="company_name"
                        component={TypeaheadExampleSingleSelect}
                        placeholder="Please Select"
                        className="form-control"
                        options={companyList}
                        selectedOptions={selectedCompanyList}
                        onSelection={onCompanySelection}
                        onCrossClick={onCrossClickCompany}
                        disabled
                    />
                    </Form.Group>
                </Col>
                <Col md={6} sm={6} xs={12}>
                 <Form.Group className="form-group">
                 <span className="rStar" />
                        <Form.Label>{props.tr["Status"]}</Form.Label>
                        <Field
                            name="ccStatus"
                            component={_dropDown}
                            className={"form-control " + (props.statusErr)}
                            options={statusValue}
                            onBlur={(event) => {
                                validateEditCostCenterField("ccStatus", event);
                            }}
                        >
                        </Field>
                    </Form.Group>
                </Col>
             </Row>
       </Form>
       )}
       </>
        )
    }



  EditForm = reduxForm({
    form: "EditCostCenter",
    enableReinitialize: true,
  })(EditForm);
  EditForm = connect(
      ({ getCostCenterEditDetails }) => ({
      initialValues: {
              costcenter_name: getCostCenterEditDetails?.costcenter_name,
              costcenter_code: getCostCenterEditDetails?.costcenter_code,
              ccStatus:getCostCenterEditDetails?.status,
              costCenterToEdit: getCostCenterEditDetails?.costcenter_name,
              company_name: getCostCenterEditDetails?.company_name,
              company_id: getCostCenterEditDetails?.company_id,
      },
    })
)(EditForm);
  
export default EditForm;