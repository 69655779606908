
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Row, Col, ButtonToolbar, Button, Form } from "react-bootstrap";
import {
  _inputField,
  _textArea,
  _dropDown,
  _dateField,
  _dateTimeField,
  _dateTimeFieldGeneral,
} from "../../../serviceDesk/common/formFields";
import { connect } from "react-redux";
import axios from "axios";
import { Field, reduxForm } from "redux-form";
import { GLOBAL } from "_Globals";
import _ from "lodash";
import moment from "moment";
import { loadSlaHolidayEntries } from "../../../../actions/sla/quickViewAction";
import { loadHolidayDetails } from "../../../../actions/sla/lovAction";
import ListLoader from "../../../common/loaders/ListLoader";

import Cookies from "universal-cookie";
const cookies = new Cookies();

let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
dateformat = dateformat.split("~");
dateformat = dateformat[2];
dateformat = dateformat.slice(0, 10).toUpperCase();

const validate = (values, props) => {
  let errors = {};
  if (
    props.showEditForm === "edit" &&
    (!values.holidayName ||
      (values.holidayName && values.holidayName.trim().length === 0))
  ) {
    errors.holidayName = "enter holiday schedule name";
  }
  if (
    props.showEditForm === "add" &&
    (!values.holidayNameAdd ||
      (values.holidayNameAdd && values.holidayNameAdd.trim().length === 0))
  ) {
    errors.holidayNameAdd = "enter holiday schedule name";
  }
  return errors;
};
class HolidayEntryForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrorMsg: "",
    };
    this.saveChange = this.saveChange.bind(this);
  }

  componentWillMount() {
    if (
      this.props &&
      this.props.showEditForm &&
      this.props.showEditForm == "edit"
    ) {
      this.props.loadHolidayDetails(this.props.holidayId);
    }
  }

  saveChange(valuesOriginal) {
    let ref = this;
    this.setState({ showErrorMsg: "" });

    let valuesStr = JSON.stringify(valuesOriginal);
    let values = JSON.parse(valuesStr);

    if (ref.props.showEditForm == "add") {
      if (values && values.dateAdd) {
        values.dateAdd = moment(values.dateAdd).format("DD/MM/YYYY");
      } else {
        values.dateAdd = moment().format("DD/MM/YYYY");
      }
      axios
        .post(GLOBAL.slaHolidatEntryPostUrl, {
          scheduleId: ref.props.scheduleId,
          scheduleList: [
            {
              date: values.dateAdd,
              holidayName: values.holidayNameAdd,
            },
          ],
        })
        .then((response) => {
          if (response.status == 201) {
            if (response.data.api_error == "") {
              ref.props.formToggle(false);
              ref.props.loadSlaHolidayEntries(ref.props.scheduleId);
            } else if (response.data.api_error != null) {
              this.setState({
                showErrorMsg:
                  response.data.api_error +
                  ". Addition/updation are not allowed",
              });
            }
          } else {
            console.log("error creating holiday schedule");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (values && values.date) {
        values.date = moment(values.date).format("DD/MM/YYYY");
      }

      axios
        .put(GLOBAL.slaHolidatEntryUpdateUrl, {
          scheduleId: ref.props.scheduleId,
          scheduleList: [
            {
              id: values.id,
              date: values.date,
              holidayName: values.holidayName,
            },
          ],
        })
        .then((response) => {
          if (response.data.api_error == "") {
            ref.props.formToggle(false);
            ref.props.loadSlaHolidayEntries(ref.props.scheduleId);
          } else {
            this.setState({ showErrorMsg: response.data.api_error });
          }
        })
        .catch((err) => {
          // console.log('ccccccccccccccc',err);
        });
    }
  }

  render() {
    let ref = this;
    const { handleSubmit, pristine, reset, submitting } = this.props;
    let defaultDate = moment();
    if (typeof this.props.slaHolidayDetails === "object" && typeof this.props.slaHolidayDetails.date !=="undefined" && this.props.slaHolidayDetails.date) {
        defaultDate = moment(this.props.slaHolidayDetails.date, [
          "DD/MM/YYYY",
          "MM/DD/YYYY",
          "YYYY/MM/DD",
          "DD-MM-YYYY",
          "YYYY-MM-DD",
          "MM-DD-YYYY",
          "MM-DD-YYYY HH:mm:ss",
          "DD-MM-YYYY HH:mm:ss",
          "YYYY-MM-DD HH:mm:ss",
          "MM/DD/YYYY HH:mm:ss",
          "DD/MM/YYYY HH:mm:ss",
          "YYYY/MM/DD HH:mm:ss",
          "DD-MM-YYYY" + " HH:mm:ss",
        ]);
    }
    if (ref.props.showEditForm == "add") {
      return (
        <div>
          <div className="borderForm bFormDiv">
            <Row>
              <Col md={12}>
                <Form.Group>
                  <Form.Label >
                    {this.props.translator["Holiday Name"]}
                    <span className="rStar" />
                  </Form.Label>
                  <div className="dateTimeSdiv">
                    <Field
                      name="holidayNameAdd"
                      type="input"
                      component={_inputField}
                      label=""
                      className="form-control"
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Form.Label >
                    {this.props.translator["Date (yyyy-mm-dd)"]}
                    <span className="rStar" />
                  </Form.Label>
                  <div className="dateTimeSdiv">
                    <Field
                      name="dateAdd"
                      component={_dateTimeFieldGeneral}
                      className="form-control dateTimePickerField"
                      formType="create"
                      timeFormat="false"
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {this.state.showErrorMsg ? (
                <Col md={12}>
                  <span style={{ color: "red" }}>
                    {this.state.showErrorMsg}
                  </span>
                </Col>
              ) : null}
              <Col md={12}>
                <ButtonToolbar className="black">
                  <Button
                    bsSize="small"
                    variant="primary"
                    onClick={handleSubmit(this.saveChange)}
                  >
                    {this.props.translator["Save"]}
                  </Button>
                  <Button
                    onClick={() => {
                      this.props.formToggle(false);
                    }}
                    bsSize="small"
                    bsStyle="text"
                  >
                    {this.props.translator["Close"]}
                  </Button>
                </ButtonToolbar>
              </Col>
            </Row>
          </div>
        </div>
      );
    } else if (typeof ref.props.slaHolidayDetails.date !== "undefined") {
      return (
        <div>
          <div className="borderForm bFormDiv">
            <Row>
              <Col md={12}>
                <Form.Group>
                  <Form.Label >
                    {this.props.translator["Holiday Name"]}
                    <span className="rStar" />
                  </Form.Label>
                  <div className="dateTimeSdiv">
                    <Field
                      name="holidayName"
                      type="input"
                      component={_inputField}
                      label=""
                      className="form-control"
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Form.Label >
                    {this.props.translator["Date (yyyy-mm-dd)"]}
                    <span className="rStar" />
                  </Form.Label>
                  <div className="dateTimeSdiv">
                    <Field
                      name="date"
                      component={_dateTimeFieldGeneral}
                      className="form-control dateTimePickerField"
                      formType="edit"
                      defaultValue={
                        defaultDate
                      }
                      timeFormat="false"
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {this.state.showErrorMsg ? (
                <Col md={12}>
                  <span style={{ color: "red" }}>
                    {this.state.showErrorMsg}
                  </span>
                </Col>
              ) : null}
              <Col md={12}>
                <ButtonToolbar className="black">
                  <Button
                    bsSize="small"
                    variant="primary"
                    onClick={handleSubmit(this.saveChange)}
                  >
                    {this.props.translator["Save"]}
                  </Button>
                  <Button
                    onClick={() => {
                      this.props.formToggle(false);
                    }}
                    bsSize="small"
                    bsStyle="text"
                  >
                    {this.props.translator["Close"]}
                  </Button>
                </ButtonToolbar>
              </Col>
            </Row>
          </div>
        </div>
      );
    } else {
     return (<ListLoader />);
    }
  }
}

const mapStateToProps = ({
  slaHolidayScheduleDetails,
  slaHolidayDetails,
  sLaHolidayFetchingDetails,
}) => {
  let initialVal;
  if (typeof slaHolidayDetails === "object" && slaHolidayDetails.date) {
    initialVal = {
      holidayName: slaHolidayDetails.holidayName,
      id: slaHolidayDetails.id,
      date:slaHolidayDetails.date
    };
  } else {
    initialVal = slaHolidayDetails;
  }

  return {
    initialValues:initialVal,
    slaHolidayScheduleDetails,
    slaHolidayDetails,
  };
};

HolidayEntryForm = reduxForm({
  form: "HolidayEntryForm",
  validate,
  enableReinitialize: true,
})(HolidayEntryForm);

export default connect(mapStateToProps, {
  loadSlaHolidayEntries,
  loadHolidayDetails,
})(HolidayEntryForm);
// export default HolidayEntryForm;
