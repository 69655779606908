
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export const aism_data_reducer = (state = initialState, action) => {
	switch (action.type) {
		case 'LOAD_AISM_TRAINING_METRICS_DATA_SUCCESS':
			return Object.assign({}, state, { aismTrainingMetricsData: action.payload });
		case 'EMPTY_AISM_TRAINING_METRICS_DATA_SUCCESS':
			return Object.assign({}, state, { aismTrainingMetricsData: [] });
		default:
			return state;
	}
}

export const aism_loader_reducer = (state = initialState, action) => {
	switch (action.type) {
		case 'LOAD_AISM_LOADER_IN_PROGRESS':
			return Object.assign({}, state, { aismLoader: true });
		case 'LOAD_AISM_LOADER_FINISHED':
			return Object.assign({}, state, { aismLoader: false });
		default:
			return state;
	}
}
