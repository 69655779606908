
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
/* eslint-disable import/named */
import React from "react";
import { ListGroup } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-spinkit";
import axios from "axios";
import _ from "lodash";
import { GLOBAL } from "_Globals";
import dummyImage from "_Images/service-images/offering-dummy.png";
import { storeCategoryName } from "_Actions/homepage/hamburgerAction";
import { navigationHooks } from "../../../../helpers/NavigationHook";
import Swal from "sweetalert2";
import swal from "sweetalert";

const Joi = require("joi");
const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

class SxOfferingDetailActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addCardButtonDisableStatus: false,
      checkoutButtonDisableStatus: false,
      selfCheckoutButtonStatus: false,
      favoriteval: "",
      compareval: "",
    };
    this.saveOfferingAndComponentToCart =
      this.saveOfferingAndComponentToCart.bind(this);
    this.onAddFavourite = this.onAddFavourite.bind(this);
    this.renderRelatedOfferings = this.renderRelatedOfferings.bind(this);
  }

  onSendEmail(a) {
    //alert(a)
    if (a == null || a == "null") a = "";
    let to = a;
    if (to != null) {
      let namedSchema = Joi.string().regex(
        /^[0-9a-zA-Z!#$%&'*+-/=?^_`|}{~@][^<>)(}{}]+$/
      );
      let validator_to_add_myQbj_1 = namedSchema.validate(to);
      if (validator_to_add_myQbj_1.error == null) {
        //window.location.href = "mailto:" + to;
      }
    } else {
      swal({
        text: "Not able to send mail!",
        button: this.props.translator["OK"],
      });
    }

  }
  onCompareClick(object) {
    let ref = this;
    let anchorid = document.getElementById("anchortitle");
    let anchortitle = anchorid.title;

    let offeringidlocalstorage = "";

    let item_attr_to_get_value = "offeringidlistfrcompare";
    let namedSchema = Joi.string().regex(/^[^!@#$%^&*}{_+=|\:;\'\",<.>?~-]+$/);
    let validator_to_add_myQbj = namedSchema.validate(item_attr_to_get_value);
    let validator_to_add_myQbj_2;
    if (validator_to_add_myQbj.error == null) {
      if (localStorage.getItem(item_attr_to_get_value) != null) {
        let intermediate_value = localStorage
          .getItem(item_attr_to_get_value)
          .split(",").length;
        namedSchema = Joi.string().regex(/^[^!@#$%^&*}{_+=|\:;\'\",<.>?~-]+$/);
        validator_to_add_myQbj = namedSchema.validate(intermediate_value);
        if (intermediate_value.error == null) {
          offeringidlocalstorage = intermediate_value;
        } else {
          offeringidlocalstorage = "";
        }
      }
    } else {
      offeringidlocalstorage = "";
    }

    // if (localStorage.getItem("offeringidlistfrcompare") != null) {
    // 	offeringidlocalstorage = localStorage.getItem("offeringidlistfrcompare").split(',').length;
    // }
    if (GLOBAL.offeringidtotal.length >= 30 || offeringidlocalstorage >= 30) {
      Swal.fire({
        text: this.props.tr["You cannot add more than 3 offerings in compare"],
        button: this.props.tr["OK"],
      });

      // alert(this.props.tr["You cannot add more than 3 offerings in compare"]);
      return false;
    } else {
      GLOBAL.offeringidtotal.push(object.offeringId);
    }

    if (GLOBAL.offeringidtotal != null) {
      let field_value = "offeringidlistfrcompare";
      namedSchema = Joi.string().regex(/^[^!@#$%^&*}{_+=|\:;\'\",<.>?~-]+$/);
      validator_to_add_myQbj = namedSchema.validate(GLOBAL.offeringidtotal);
      validator_to_add_myQbj_2 = namedSchema.validate(field_value);
      if (
        validator_to_add_myQbj.error == null &&
        validator_to_add_myQbj_2.error == null
      ) {
        localStorage.setItem(field_value, GLOBAL.offeringidtotal.toString());
        localStorage.setItem(
          field_value,
          JSON.stringify(GLOBAL.offeringidtotal)
        );
      } else {
        GLOBAL.offeringidtotal = 0;
      }
    } else GLOBAL.offeringidtotal = 0;
    ref.setState({ compareval: "1" });
  }

  insertaddfavouriteSelfService(object) {
    let ref = this;

    let storage_field = "offeringidtotalfrremove";
    let namedSchema = Joi.string().regex(/^[^!@#$%^&*}{_+=|\:;\'\",<.>?~-]+$/);
    let validator_to_add_myQbj = namedSchema.validate(storage_field);
    if (validator_to_add_myQbj.error == null) {
      GLOBAL.offeringidtotal = JSON.parse(
        localStorage.getItem(storage_field).replace('"', "").replace('"', "")
      );
    } else {
      GLOBAL.offeringidtotal = 0;
    }
    // GLOBAL.offeringidtotal = JSON.parse(localStorage.getItem("offeringidtotalfrremove").replace('"', '').replace('"', ''));

    let index = GLOBAL.offeringidtotal.indexOf(object.offeringId);

    if (index > -1) {
      GLOBAL.offeringidtotal.splice(index, 1);
    }

    if (GLOBAL.offeringidtotal != null) {
      let field_value = "offeringidlistfrcompare";
      namedSchema = Joi.string().regex(/^[^!@#$%^&*}{_+=|\:;\'\",<.>?~-]+$/);
      validator_to_add_myQbj = namedSchema.validate(GLOBAL.offeringidtotal);
      let validator_to_add_myQbj_2 = namedSchema.validate(field_value);
      if (
        validator_to_add_myQbj.error == null &&
        validator_to_add_myQbj_2.error == null
      ) {
        localStorage.setItem(field_value, GLOBAL.offeringidtotal.toString());
        localStorage.setItem(
          field_value,
          JSON.stringify(GLOBAL.offeringidtotal)
        );
      } else {
        GLOBAL.offeringidtotal = 0;
      }
    } else GLOBAL.offeringidtotal = 0;
    ref.setState({ compareval: "0" });
  }

  onAddFavourite(object) {
    let ref = this;

    axios
      .post(GLOBAL.postOfferingFavUrl, {
        entitledUser: "",
        //entitledUser:101,
        offeringId: object.offeringId,
      })
      .then(function (response) {
        if (response.status == 200) {
          ref.setState({ favoriteval: "1" });
        }
      })
      .catch(function (error) {});
  }

  onRemoveFavourite(object) {
    let ref = this;
    let UID = "";
    axios
      .post(GLOBAL.postOfferingFavRemoveUrl, {
        entitledUser: "",
        //entitledUser:101,
        offeringId: object.offeringId,
      })
      .then(function (response) {
        if (response.status == 200) {
          ref.setState({ favoriteval: "0" });
        }
      })
      .catch(function (error) {});
  }

  saveOfferingAndComponentToCart(offeringId, offeringComponentsForCart) {
    let ref = this;
    let offeringIds = [];
    ref.setState({ addCardButtonDisableStatus: true });
    ref.setState({ checkoutButtonDisableStatus: true });
    ref.setState({ selfCheckoutButtonStatus: true });
    let str = {};
    str.userId = "";
    str = JSON.stringify(str);
    const offeringsInCartResponse = api.get(GLOBAL.cartOfferingsUrl, {
      headers: { query: str },
    });

    offeringsInCartResponse.then((allOfferingsInCart) => {
      if (allOfferingsInCart.data.length == 0) {
        api
          .post(GLOBAL.addOfferingToCartUrl, {
            offeringId: offeringId,
            userId: "",
          })
          .then((response) => {
            if (response.status == 200) {
              if (offeringComponentsForCart.length != 0) {
                for (let i = 0; i < offeringComponentsForCart.length; i++) {
                  api
                    .post(GLOBAL.addComponentsToCart, {
                      offeringId: offeringId,
                      componentId: offeringComponentsForCart[i].id,
                    })
                    .then((response) => {
                      if (response.status == 200) {
                        ref.setState({ addCardButtonDisableStatus: true });
                        navigationHooks.navigate("/cart");
                      } else {
                        ref.setState({
                          addCardButtonDisableStatus: false,
                          checkoutButtonDisableStatus: false,
                          selfCheckoutButtonStatus: false,
                        });
                        Swal.fire({
                          text: this.props.tr[
                            "attached components could not be added, please try again"
                          ],
                          button: this.props.tr["OK"],
                        });

                        // alert(ref.props.tr['attached components could not be added, please try again']);
                      }
                    });
                }
              }
              //alert('offering added to the card successfully.');
              ref.setState({ addCardButtonDisableStatus: true });
              navigationHooks.navigate("/cart");
            } else {
              ref.setState({
                addCardButtonDisableStatus: false,
                checkoutButtonDisableStatus: false,
                selfCheckoutButtonStatus: false,
              });
              Swal.fire({
                text: this.props.tr[
                  "there is a problem adding your recent offering to the cart try again"
                ],
                button: this.props.tr["OK"],
              });

              // alert(ref.props.tr['there is a problem adding your recent offering to the cart try again']);
            }
          });
      } else {
        allOfferingsInCart.data.map((offerings, index) => {
          if (index == allOfferingsInCart.data.length - 1) {
            offeringIds.push(parseInt(offerings.offeringId,10));
          } 
        });
        if (_.includes(offeringIds, "offeringId")) {
          Swal.fire({
            text: this.props.tr["Offering already added in cart"],
            button: this.props.tr["OK"],
          });

          // alert(ref.props.tr['Offering already added in cart']);
          ref.setState({
            checkoutButtonDisableStatus: false,
            selfCheckoutButtonStatus: false,
          });
        } else {
          api
            .post(GLOBAL.addOfferingToCartUrl, {
              offeringId: offeringId,
              userId: "",
            })
            .then((response) => {
              if (response.status == 200) {
                if (offeringComponentsForCart.length != 0) {
                  for (let i = 0; i < offeringComponentsForCart.length; i++) {
                    api
                      .post(GLOBAL.addComponentsToCart, {
                        offeringId: offeringId,
                        componentId: offeringComponentsForCart[i].id,
                      })
                      .then((response) => {
                        if (response.status == 200) {
                          ref.setState({ addCardButtonDisableStatus: true });
                          navigationHooks.navigate("/cart");
                        } else {
                          ref.setState({
                            addCardButtonDisableStatus: false,
                            checkoutButtonDisableStatus: false,
                            selfCheckoutButtonStatus: false,
                          });
                          Swal.fire({
                            text: ref.props.tr[
                              "attached components could not be added, please try again"
                            ],
                            button: this.props.tr["OK"],
                          });

                          // alert(ref.props.tr['attached components could not be added, please try again']);
                        }
                      });
                  }
                }
                //alert('offering added to the card successfully.');
                ref.setState({ addCardButtonDisableStatus: true });
                navigationHooks.navigate("/cart");
              } else {
                ref.setState({
                  addCardButtonDisableStatus: false,
                  checkoutButtonDisableStatus: false,
                  selfCheckoutButtonStatus: false,
                });
                Swal.fire({
                  text: ref.props.tr[
                    "there is a problem adding your recent offering to the cart try again"
                  ],
                  button: this.props.tr["OK"],
                });

                // alert(ref.props.tr['there is a problem adding your recent offering to the cart try again']);
              }
            });
        }
      }
    });
  }

  renderSelfServiceAction(offeringActions) {
    let actions = offeringActions
      .replace(/"/g, "")
      .replace(/[{}]/g, "")
      .split(",");
    if (actions[0] == "") {
      return null;
      // return (
      // 	<ListGroupItem bsClass="" style={{ width: "auto", float: "none" }}>
      // 		<div className="f-size-15"><span className="f-size-14 margin-r-5"/>{this.props.translatoroffering['No actions available.']}</div>
      // 	</ListGroupItem>
      // );
    } else {
      let actions = offeringActions
        .replace(/"/g, "")
        .replace(/[{}]/g, "")
        .split(",");
      return actions.map((act, index) => {
        let specKey = actions[index].substring(0, actions[index].indexOf(":"));
        let specValue = actions[index].substring(
          actions[index].indexOf(":") + 1,
          actions[index].length
        );
        return (
          <ListGroup.Item as={'li'} key={index} bsPrefix=" " bsClass="">
            <div className="margin-l-5">
              <div className="f-size-15">
                <span className="f-size-14 margin-r-5">
                  <i className="fa fa-shield" />
                </span>
                {specKey}
              </div>
              <div className="lgray f-size-13">{specValue}</div>
            </div>
          </ListGroup.Item>
        );
      });
    }
  }

  routeRelatedOffering(e, offering) {
    let categoryName = offering.categoryName;
    let subCategoryName = offering.subCategoryName;
    let offeringId = offering.offeringId;
    this.props.storeCategoryName(offering.categoryName);
    categoryName = categoryName.includes("?")
      ? categoryName.replace("?", "")
      : categoryName;
    navigationHooks.navigate(
      "/offeringDetails/" +
        offeringId +
        "/" +
        categoryName +
        "/" +
        subCategoryName
    );
  }

  renderRelatedOfferings(relatedOfferings) {
    let displayStr = "";
    let displayText = "";
    return relatedOfferings.relatedOfferings.map((offering, index) => {
      displayStr =
        offering.shortDescription != null && offering.shortDescription != ""
          ? offering.shortDescription
          : "";
      displayText =
        displayStr != ""
          ? displayStr
              .replace(
                /(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/gi,
                ""
              )
              .trim()
          : "";
      displayText = displayText != "" ? displayText.replace(/\s+/g, " ") : "";
      return (
        <div
          key={index}
          bsClass=""
          className="border-b margin-b-10 padding-b-10"
        >
          <div className="relatedOfferDv">
            <div className="imbx penTd">
              <a
                title={offering.offeringName}
                onClick={(e) => {
                  this.routeRelatedOffering(e, offering);
                }}
                href="javascript:void(0)"
              >
                <img
                  alt={offering.offeringName}
                  className="img-responsive display-inline-block"
                  src={offering.icon ? offering.icon : dummyImage}
                  title={offering.offeringName}
                />
              </a>
            </div>
            <div className="txbx penTd">
              <a
                id={"relatedoff_" + index}
                title={offering.offeringName}
                onClick={(e) => {
                  this.routeRelatedOffering(e, offering);
                }}
                href="javascript:void(0)"
              >
                {offering.offeringName}
              </a>
              <div className="txtdes">
                {displayText.slice(0, 80)}
                {displayText.length > 80 ? "..." : ""}
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  render() {
    if (
      this.props.serviceReviewShowLoader ||
      this.props.offeringDetails.length == 0 ||
      this.props.offeringDetails[0] === undefined
    ) {
      return (
        <div>
          <h2>
            {this.props.translatoroffering["Loading offering details"]}...
          </h2>
          <Spinner spinnerName="three-bounce" />
        </div>
      );
    }
    let offeringidlistcompare = "";
    let favouriteexist = this.props.offeringDetails[0].isFavourite;

    let compare, favorite;

    let item_attr_to_get_value = "offeringidlistfrcompare";
    let namedSchema = Joi.string().regex(/^[^!@#$%^&*}{_+=|\:;\'\",<.>?~-]+$/);
    let validator_to_add_myQbj = namedSchema.validate(item_attr_to_get_value);
    if (validator_to_add_myQbj.error == null) {
      if (localStorage.getItem(item_attr_to_get_value) != null) {
        let intermediate_value = localStorage
          .getItem(item_attr_to_get_value)
          .split(",").length;
        namedSchema = Joi.string().regex(/^[^!@#$%^&*}{_+=|\:;\'\",<.>?~-]+$/);
        validator_to_add_myQbj = namedSchema.validate(intermediate_value);
        if (intermediate_value.error == null) {
          offeringidlistcompare = intermediate_value;
        } else {
          offeringidlistcompare = "";
        }
      }
    } else {
      offeringidlistcompare = "";
    }
    let offeringidcompareexist = offeringidlistcompare.indexOf(
      this.props.offeringDetails[0].offeringId
    );
    if (offeringidcompareexist < 0) {
      if (this.state.compareval == "0" || this.state.compareval == "")
        compare = (
          <a
            id="anchortitle"
            onClick={this.onCompareClick.bind(
              this,
              this.props.offeringDetails[0]
            )}
            className="spCommon compareIcon"
            title={this.props.translatoroffering["Add to Compare"]}
          ></a>
        );
      else
        compare = (
          <a
            id="anchortitle"
            onClick={this.onRemoveCompareClick.bind(
              this,
              this.props.offeringDetails[0]
            )}
            className="spCommon compareIcon"
            title={this.props.translatoroffering["Remove from Compare"]}
          />
        );
    } else {
      if (this.state.compareval == "0")
        compare = (
          <a
            id="anchortitle"
            onClick={this.onCompareClick.bind(
              this,
              this.props.offeringDetails[0]
            )}
            className="spCommon compareIcon"
            title={this.props.translatoroffering["Add to Compare"]}
          />
        );
      else
        compare = (
          <a
            id="anchortitle"
            onClick={this.onRemoveCompareClick.bind(
              this,
              this.props.offeringDetails[0]
            )}
            className="spCommon compareIcon"
            title={this.props.translatoroffering["Remove from Compare"]}
          />
        );
    }
    if (favouriteexist == "0") {
      if (this.state.favoriteval == "0" || this.state.favoriteval == "")
        favorite = (
          <a
            href="javascript:void(0)" 
            className="spCommon heartIcon"
            onClick={this.onAddFavourite.bind(
              this,
              this.props.offeringDetails[0]
            )}
            title={this.props.translatoroffering["Add to Favorites"]}
          />
        );
      else {
        favorite = (
          <a
            href="javascript:void(0)" 
            className="spCommon heartIcon highlight"
            onClick={this.onRemoveFavourite.bind(
              this,
              this.props.offeringDetails[0]
            )}
            title={this.props.tr["Remove from Favorites"]}
          />
        );
      }
    } else {
      if (this.state.favoriteval == "0")
        favorite = (
          <a
            href="javascript:void(0)" 
            className="spCommon heartIcon"
            onClick={this.onAddFavourite.bind(
              this,
              this.props.offeringDetails[0]
            )}
            title={this.props.translatoroffering["Add to Favorites"]}
          />
        );
      else
        favorite = (
          <a
            href="javascript:void(0)" 
            className="spCommon heartIcon highlight"
            onClick={this.onRemoveFavourite.bind(
              this,
              this.props.offeringDetails[0]
            )}
            title={this.props.tr["Remove from Favorites"]}
          />
        );
    }
    let validate_email = undefined;
    if (
      this.props.offeringDetails[0] != null ||
      this.props.offeringDetails[0].supportEmail != null ||
      this.props.offeringDetails[0].supportEmail != undefined
    ) {
      let namedSchema = Joi.string().regex(
        /^[0-9a-zA-Z!#$%&'*+-/=?^_`|}{~@][^<>)(}{}]+$/
      );
      let validator_to_add_myQbj = namedSchema.validate(
        this.props.offeringDetails[0].supportEmail
      );
      if (validator_to_add_myQbj.error == null) {
        validate_email = this.props.offeringDetails[0].supportEmail;
      } else {
        validate_email = "";
      }
    } else validate_email = "";
    let selfServiceActionsCount = this.props.offeringDetails[0].selfact
      .replace(/"/g, "")
      .replace(/[{}]/g, "")
      .split(",");

    let displayName = "";
    displayName =
      this.props.offeringDetails[0].offeringName != null &&
      this.props.offeringDetails[0].offeringName != ""
        ? this.props.offeringDetails[0].offeringName
        : "";
    return (
      <div>
        <div className="border padding-5 text-c margin-b-20">
          <img
            className="img-fluid display-inline-block"
            src={
              this.props.offeringDetails[0].offeringImageTempURL
                ? this.props.offeringDetails[0].offeringImageTempURL
                : dummyImage
            }
            alt={displayName.slice(0, 30)}
          />
        </div>

        <div className="offDetHed">
          {this.props.translatoroffering["Service Contacts"]}
        </div>

        {this.props.offeringDetails[0].hideContacts == "false" ? (
          <div className="listMX margin-b-10 border-b padding-b-10">
            <div className="greyDethad">
              {this.props.translatoroffering["Service Provider"]}
            </div>
            <div className="offHeDv">
              <div className="hd">
                {this.props.offeringDetails[0].providerCompany}
              </div>
              <div className="lnk">
                <a
                  title={this.props.offeringDetails[0].supportEmail}
                  href={"mailto:" + this.props.offeringDetails[0].supportEmail}
                >
                  <i className="fa fa-envelope-o margin-r-10" />
                  {this.props.translatoroffering["Contact"]}
                </a>
              </div>
            </div>
          </div>
        ) : null}

        {this.props.offeringDetails[0].hideContacts == "false" ? (
          <div className="listMX margin-b-20 border-b padding-b-10">
            <div className="greyDethad">
              {this.props.translatoroffering["Support Company"]}
            </div>
            <div className="offHeDv">
              <div className="hd">
                {this.props.offeringDetails[0].supportCompany}
              </div>
              <div className="lnk">
                <a
                  title={this.props.offeringDetails[0].supportEmail}
                  href={"mailto:" + this.props.offeringDetails[0].supportEmail}
                >
                  <i className="fa fa-envelope-o margin-r-10" />
                  {this.props.translatoroffering["Contact"]}
                </a>
              </div>
            </div>
          </div>
        ) : null}

        {this.props.offeringDetails[0].hideSelfServiceActions == "false" ? (
          selfServiceActionsCount[0] == "" ? null : (
            <div className="listMX margin-b-20 border-b padding-b-10">
              <div className="offDetHed">
                {this.props.translatoroffering["Self-Service Actions"]}
              </div>
              <div>
                <ListGroup as={'ul'} bsPrefix=" " bsClass="" className="">
                  {this.renderSelfServiceAction(
                    this.props.offeringDetails[0].selfact
                  )}
                </ListGroup>
                <div style={{ height: "1px" }} className="clearboth"></div>
              </div>
            </div>
          )
        ) : null}

        {this.props.relatedOfferings.length !== 0 && this.props.requestType !== 'OnBehalf' ? (
          <div className="margin-b-20">
            <div className="offDetHed">
              {this.props.translatoroffering["Related Offerings"]}
            </div>
            {this.renderRelatedOfferings(this.props.relatedOfferings)}
          </div>
        ) : null}
      </div>
    );
  }
}

// SxOfferingDetailActions.propTypes={

// };
const mapStateToProps = ({
  offeringDetails,
  spcmReducer,
  serviceReviewShowLoader,
}) => {
  return {
    offeringDetails,
    tr: spcmReducer.tr,
    serviceReviewShowLoader: serviceReviewShowLoader.loading,
  };
};
export default connect(mapStateToProps, { storeCategoryName })(
  SxOfferingDetailActions
);
