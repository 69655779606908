
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import ReactDOM from "react-dom";
// import { Row, Col, Carousel } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import sanitizeHtml from 'sanitize-html';

const MoreArrow = <i title="More" className="fa fa-angle-double-down f-size-15" ></i>;
import { connect } from 'react-redux';
// import { loadBulletin } from '../../actions/homepage/bulletinActions';
import { bindActionCreators } from 'redux';
import ListLoader from "../common/loaders/ListLoader";
import { parseHTML } from 'jquery';

const data = [
	{ title: "Application Version", summary: " text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever sin", details: "Jan 12, 2020" },
	{ title: "Approval", summary: "A handy Lorem Ipsum Generator that helps to create dummy text for all layout needs.", details: "Jan 12, 2020" },
	{ title: "Information Provided", summary: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s", details: "Jan 12, 2020" },
	{ title: "Attachments", summary: "desktop publishing packages and web page editors now use Lorem Ipsum as their default model text.", details: "Jan 12, 2020" }
];

class SxNews extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			index: 0,
			indicators: false,
			direction: 'next',
			controls: true,
			isbulletinItemHeight: false,
			isPause: true,
			activeIndex: 1
		};
		this.updateItemheight = this.updateItemheight.bind(this);
		this.isSlickPlay = this.isSlickPlay.bind(this);
		this.isSlickPause = this.isSlickPause.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
	};
	handleChange(selectedIndex, e) {
		this.setState({ activeIndex: selectedIndex + 1 });
	}
	isSlickPlay() {
		this.setState({ isPause: true });
	}
	isSlickPause() {
		this.setState({ isPause: false });
	}
	componentDidMount() {
		document.addEventListener("mousedown", this.handleClickOutside);

	}
	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClickOutside);
	}
	setWrapperRef(element) {
		this.wrapperRef = element;
	}
	handleClickOutside(event) {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.setState({ isbulletinItemHeight: false })
		}
	}
	updateItemheight() {
		this.setState({ isbulletinItemHeight: !this.state.isbulletinItemHeight });
	}
	renderList(bulletin) {
		return bulletin.map((bulletinItem, index) => {
			let displayText = "";
			let displayStr = "";
			displayStr = bulletinItem.title != null && bulletinItem.title != "" ? bulletinItem.title : "";
			displayText = displayStr != "" ? displayStr.replace(/(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/ig, '').trim() : "";
			displayText = displayText != "" ? displayText.replace(/\s+/g, ' ') : "";
			return (
				<Carousel.Item key={index}>
					<div className="hed">
						{bulletinItem.bulletinUrl != null && bulletinItem.bulletinUrl != "" ?
							<a href={bulletinItem.bulletinUrl} target="_blank" >
								{displayText.slice(0, 145)}{displayText.length > 145 ? "..." : ''}
							</a> : <span>{displayText.slice(0, 145)}{displayText.length > 145 ? "..." : ''}</span>
						}
						{/* {bulletinItem.summary != null ?
								<div className="txt">
									<ReadMore lines={1} onShowMore={this.props.onChange} text={MoreArrow}>
										<div>{bulletinItem.summary}</div>
									</ReadMore>
								</div>:""} */}
					</div>
					{this.state.isbulletinItemHeight ? <div className="info" dangerouslySetInnerHTML={{ __html: parseHTML(bulletinItem.details)[0].wholeText}}>
					</div> : null}
					<a role="button" href='#' className={bulletin.length > 1 ? "itmmore" : "itmmore oneItm"} onClick={this.updateItemheight} title={this.state.isbulletinItemHeight ? "less" : "more"}><i className={this.state.isbulletinItemHeight ? "fa fa-chevron-up" : "fa fa-chevron-down"} aria-hidden="true"></i></a>
				</Carousel.Item>
			);
		});
	}
	showBulletins(records) {
		if (records.length <= 0) {
			return null
		}
		return (
			<div className={records.length > 0 ? "newsBoxDv show" : "newsBoxDv"} ref={this.setWrapperRef}>
				<div className={this.state.isbulletinItemHeight ? "container-fluid open" : "container-fluid"}>
					<Row><Col xs={12}>
						{/* <h3>{this.props.tr["Latest News"]}</h3> */}
						<Carousel className="newsBox" onSelect={this.handleChange} interval={this.state.isPause ? 5000 : null} defaultActiveIndex={this.state.index} controls={this.props.bulletin.length > 1 ? true : false} indicators={this.state.indicators} direction={this.state.direction}>
							{this.renderList(records)}
						</Carousel>
						{records.length > 1 ? <div className="buCount">{this.state.activeIndex} of {records.length}</div> : null}
						{this.props.bulletin.length > 1 ? <a className='isPause' role="button" href='#' onClick={this.state.isPause ? this.isSlickPause : this.isSlickPlay} title={this.state.isPause ? this.props.tr["Pause"] : this.props.tr["Play"]}><i className={this.state.isPause ? "fa fa-pause pause" : "fa fa-play-circle"}></i></a> : null}
					</Col></Row>
				</div>
			</div>
		)
	}
	render() {
		return (
			this.showBulletins(this.props.bulletin)
		);
	}
}
const mapStateToProps = ({ bulletin }) => {
	return { bulletin };
}

// const mapDispatchToProps = (dispatch) => {
// 	return bindActionCreators({ loadBulletin }, dispatch)
// }

export default connect(mapStateToProps, {})(SxNews);
