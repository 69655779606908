
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
//import initialState from '../../initialState';

export const investigationList = (state = {}, action) =>{
	switch (action.type){
		case 'LOAD_INVESTIGATION_SUCCESS':
			return action.investigationList.data;
			default:
			return state;
	}
}

export const investigationItemDetails = (state = {}, action) =>{
	switch (action.type){
		case 'INVESTIGATION_DETAILS_LOAD_SUCCESS':
			//return action.investigationDetails.data.data[0];
			let obj = JSON.parse(JSON.stringify(action.investigationDetails.data));
			if(obj.symptoms == null || obj.symptoms == "null" || obj.symptoms == undefined){
				obj.symptoms = "";
			}
			// return action.investigationDetails.data;
			return obj;
			default:
			return state;
	}
}

export const investigationTeamList = (state = [], action) =>{
	switch (action.type){
		case 'INVESTIGATION_TEAM_LIST_SUCCESS':
			return action.investigationlist.data;
			default:
			return state;
	}
}
export const specificVendorTicketList = (state = [], action) =>{
	
	switch (action.type){
		case 'SPECIFIC_VENDORTICKET_LIST_SUCCESS':
			return action.vendorTicketList.data;
			default:
			return state;
	}
}
/*export const myOrderOrderDetails = (state = {}, action)=> {
	switch (action.type){
		case 'LOAD_ORDER_DETAILS_SUCCESS':
			return action.orderDetails.data;
			default:
			return state;
	}
}

export const myBreakFixOrderOrderDetails = (state = {}, action)=> {
	switch (action.type){
		case 'LOAD_BREAKFIXORDER_DETAILS_SUCCESS':
			return action.breakFixOrderDetails.data;
			default:
			return state;
	}
}

export const offeringForOrder = (state = [], action) => {
	if(action.orderDetails){
		console.log('myOrdersReducer '+action.orderDetails.data);
	}

	switch (action.type){
		case 'LOAD_OFFERING_FOR_ORDER_SUCCESS':
			return action.orderDetails.data;
			default:
			return state;
	}
};*/

export const loadPrbData = (state = {}, action) => {
	switch (action.type) {
		case 'LOAD_PRB_DETAILS':
			console.log("actionaction ",action)
			return action.payload;
		case "RESET_STATE":
			return {};
		default:
			return state;
	}
}