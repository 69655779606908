
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export const externalSystemAttributesList = (state = [], action)=> {
	switch (action.type){
		case 'LOAD_BREAKFIXEXTERNALSYSTEMATTRIBUTESRESULTS_SUCCESS':
			//alert(action.breakFix.data.);
			// return action.resultResponse;
			return ((action.resultResponse && action.resultResponse.data) ? action.resultResponse.data : []);
		default:
			return state;
	}
}
