
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PiPencilSimpleBold } from "react-icons/pi";
import { IoClose } from "react-icons/io5";
import { replaceVariables } from "../../uiBuilder/studio/utils";

const GeneratedListDescription = (props) => {
  const dispatch = useDispatch();
  const translator = useSelector((state) => state.spcmReducer.tr);
  const rightSideKeys = props?.rightSideKeys.split(",");
  const rightSideLabels = props?.rightSideLabels.split(",");

  return (
    <div className="margin-b-20">
      <div className="rPageHeadActBtn margin-r-5" style={{ marginTop: "-3px" }}>
        <ul>
          {!props?.tableProperties?.hideEditButton && (
            <li>
              <Link
                to={replaceVariables(
                  props?.tableProperties?.editUrl,
                  props?.selectedRow
                )}
                state={
                  props?.tableProperties?.passSelectedRow
                    ? { editData: props.selectedRow }
                    : null
                }
                title={translator["Edit"]}
              >
                <PiPencilSimpleBold />
              </Link>
            </li>
          )}
          <li>
            <button
              title={translator["Minimize the details panel"]}
              bsClass=""
              onClick={() => {
                props.buttonClicked({ type: "rightSideMinimize" });
              }}
            >
              <IoClose />
            </button>
          </li>
        </ul>
      </div>
      <div className="rPageHeading">
        <div className="offNam margin-t-5 margin-b-5">
          {props.title?.titleText + " Details"}
        </div>
      </div>

      <div className="rBoxGap">
        <ListGroup variant="flush" bsClass="" className="">
          {rightSideKeys.length > 0 &&
            rightSideKeys.map((res, index) => {
              return (
                <ListGroup.Item
                  bsClass=""
                  className="rwTable"
                  key={`right-side-key-${index}`}
                  style={{ "border-top": "none" }}
                >
                  <span className="rwKey">{rightSideLabels[index]} </span>{" "}
                  <span className="rwVal1">{props.selectedRow[res]}</span>
                </ListGroup.Item>
              );
            })}
        </ListGroup>
      </div>
    </div>
  );
};

export default GeneratedListDescription;
