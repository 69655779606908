
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import {
  Button,
  Accordion,
  Popover,
  Card,
  OverlayTrigger,
  Table,
  ListGroup,
} from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import Spinner from "react-spinkit";
import moment from "moment";
import { ImInfo } from "react-icons/im";

import dummyImage from "../../../../images/profileimg.png";
import datetimeConvertor from "../../../../helpers/ISO8601converter";
import NoDataAvailable from "../../../common/NoDataAvailable";
import { getCookiesValuesByPositions } from "../../Helper";
import {
  useProfileDetailsUrlQuery,
  useProfileTimeZoneUrlQuery,
} from "../../../../reducers/governance/meeting/meeting";

import UserDetailsComponent from "../../common/userDetails";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";
const cookies = new Cookies();
let cookiesArray = cookies.get('gph');
if (cookiesArray) cookiesArray = cookiesArray.replace('s:', '');
if (cookiesArray) cookiesArray = cookiesArray.substring(0, cookiesArray.lastIndexOf('.'));
cookiesArray = cookiesArray.split("~");
let langDir = cookiesArray[66];

const WorkNotesList = ({ data, isFetching }) => {
  const [selectedUser, setSelectedUser] = useState("");
  const [timezoneText, setTimezoneText] = useState("");
  const cookieValues = getCookiesValuesByPositions([2, 3]);
  const dateformat = cookieValues?.value1;
  const timezone = cookieValues?.value2;
  const windowSize = useSelector((state) => state.windowSize.width);

  const jwtdf = dateformat?.slice(0, 10)?.toUpperCase();

  const actDateTimeFormat = jwtdf + " hh:mm A";

  const { data: userDetails, isFetching: isUserFetching } =
    useProfileDetailsUrlQuery(
      {
        userId: selectedUser,
      },
      {
        skip: !selectedUser,
      }
    );

  const {
    data: timezoneData,
    isFetching: isTimezoneFetching,
    refetch: timezoneFetch,
  } = useProfileTimeZoneUrlQuery({
    skip: false,
  });

  useEffect(() => {
    const matchingBreakFixObj = timezoneData?.find((breakFixObj) => {
      return breakFixObj?.KEY === Number(userDetails?.timezoneid);
    });

    if (matchingBreakFixObj) {
      setTimezoneText(matchingBreakFixObj?.VALUE);
    }
  }, [userDetails, isTimezoneFetching]);

  return (
    <Accordion
      accordion
      id="worknotes-comment"
      // defaultActiveKey={["0"]}
      className=""
    >
      <Accordion.Item eventKey="0" className="mb-3">
        <Accordion.Header>Comments</Accordion.Header>
        <Accordion.Body>
          {data?.length === 0 ? (
            <NoDataAvailable />
          ) : (
            data?.map((notes, childKey) => {
              const isoDate = datetimeConvertor(
                notes.createdOn,
                jwtdf + " HH:mm:ss"
              );
              const myDateObj = moment(isoDate).format(actDateTimeFormat);
              const endDate = moment(myDateObj, actDateTimeFormat);
              const endDateStr = endDate._i;
              const startTime = moment(
                new Date().toLocaleString("en-US", { timeZone: timezone })
              );
              const timeago = moment(endDateStr, actDateTimeFormat).from(
                startTime
              );
              const duration = moment.duration(startTime.diff(endDate));
              const hours = duration.asHours();

              const tooltip = (
                <Popover className="userInfoPopup p-3">
                  <UserDetailsComponent
                    userDetails={userDetails}
                    isUserFetching={isUserFetching}
                    timezoneText={timezoneText}
                  />
                </Popover>
              );

              return (
                <Card className="actlogCrd actCommts" bsPrefix=" ">
                  <div className="circleTxt">
                    <div className="subhed">
                      {notes.createdByFullName !== "System" ? (
                        <OverlayTrigger
                          trigger="click"
                          rootClose
                          placement={windowSize < 768 ? "auto-start" : (langDir === "rtl" ? "right-start" : "left-start")
}
                          overlay={tooltip}
                        >
                          <span
                            className="circleNa"
                            title={notes?.createdByFullName}
                            onClick={() => {
                              setSelectedUser(notes?.createdBy);
                              timezoneFetch();
                            }}
                          >
                            {notes?.createdByFullName} <ImInfo />
                          </span>
                        </OverlayTrigger>
                      ) : (
                        <span className="margin-r-20">
                          {notes?.createdByFullName}
                        </span>
                      )}

                      <span
                        className="margin-l-5 timeao"
                        title={hours >= 24 ? "" : endDateStr}
                      >
                        {hours >= 24 ? endDateStr : timeago}
                      </span>
                    </div>

                    <div title="Field Name" className="des">
                      {notes?.worknoteDesc}
                    </div>
                  </div>
                </Card>
              );
            })
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default WorkNotesList;
