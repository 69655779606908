
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useMemo, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import matchSorter from "match-sorter";
import { Form, Table, Row, Col } from "react-bootstrap";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import Pagination from "react-js-pagination";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import ReactDOM from "react-dom";

import {
  getAllFroums,
  getAllCompanyList,
  getRegionList,
  getForumType,
  getForumStatus,
  getAllTracks,
  getAllSubTracks,
  getForumById,
} from "../../../reducers/foundation/forum/forum";

import ForumShortDescriptionList from "./forumShortDescriptionList";
import ListLoader from "../../common/loaders/ListLoader.js";

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);

  return (
    <span>
      Search:
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: "1.1rem",
          border: "0",
        }}
      />
    </span>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search here`}
      className="colSearInp form-control"
    />
  );
}

const SelectColumnFilterCheckbox = ({
  column: { filterValue, setFilter, preFilteredRows, id },
  data,
  clearAll,
  isEditDetails,
  setIsEditDetails,
  ...props
}) => {
  const [selected, setSelected] = useState();
  const [isMenuOpenDd, setIsMenuOpenDd] = useState(false);
  const ref = useRef(null);

  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  const handleChange = (selectedOptions) => {
    setSelected(selectedOptions);
    const selectedValues = selectedOptions?.map((option) => option.value) || [];
    setFilter(selectedValues.length > 0 ? selectedValues : undefined);
  };

  const clickOutside = (event) => {
    const refDv = document.getElementById(id);
    if (ref.current != null || ref.current != undefined) {
      if (refDv.contains(event.target)) {
        props.setIsMenuOpen({ [id]: true });
      }
      else {
        props.setIsMenuOpen({ });
      }
      props.showDropdownFun("allFalse", false);      
    }
  };

  useEffect(() => {
    if (!filterValue) {
      setSelected([]);
    }
  }, [filterValue]);



  useEffect(() => {
    document.addEventListener("mousedown", clickOutside);
    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, []);

  useEffect(() => {
    if (ref != undefined || ref != null) {
      ref.current.childNodes[0].className = "multiSelectRef";
      let myRef = ref;
      let obj = ReactDOM.findDOMNode(myRef.current)?.childNodes?.[0]
        ?.childNodes?.[1];
      if (obj) {
        ReactDOM.findDOMNode(
          myRef.current
        ).childNodes[0].childNodes[1].className = "multiSelectPopup";
      }
    }
  }, [isMenuOpenDd]);

  return (
    <div
      id={id}
      ref={ref}
      onClick={() => {
        setIsEditDetails(false);
        setIsMenuOpenDd(!isMenuOpenDd);
        props.showDropdownFun(id, true);
      }}
    >
    <ReactMultiSelectCheckboxes
      placeholderButtonLabel="Select"
      rightAligned={true}
      options={data || []}
      onChange={handleChange}
      value={selected}
    />
    </div>
  );
};

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      className="colSearInp form-control"
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}
function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}
fuzzyTextFilterFn.autoRemove = (val) => !val;

function RenderTable({
  columns,
  data,
  isBusy,
  paginationData,
  setPaginationData,
  setPageNum,
  setPgSize,
  pageNum,
  pgSize,
  setData,
  setrowIndex,
  rowIndex,
  isRightSideVisible,
  isEditDetails,
  setIsEditDetails,
  isMenuOpen,
  setFilterString,
  isFilterCleared,
  setIsFilterCleared,
  showDropdownFun,
}) {
  const dispatch = useDispatch();

  const [rowData, setRowData] = useState([]);
  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    setAllFilters,
    // preGlobalFilteredRows,
    // setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      manualFilters: true,
      //  filterTypes,
    },
    useFilters // useFilters!
    //useGlobalFilter // useGlobalFilter!
  );

  const firstPageRows = rows;

  const handleRowClick = (row, index) => {
    setrowIndex(index);
    setRowData(row);
    setIsEditDetails(true);
    showDropdownFun("allFalse",false);
  };

  const onPageChange = (val) => {
    // setPageNum(pgNo - 1);
    try {
      const value = val > 0 ? val - 1 : 0;
      setPageNum(value);
      setrowIndex(0);
      // const queryStr = getMeeting_QueryString({});
      // getMeetingList(value, pageSize, queryStr);
    } catch (e) {
      console.error(e);
    }
  };

  const onPageSizeChange = (pageNumber, val) => {
    setPageNum(0);
    setPgSize(val);
    setrowIndex(0);
  };
  useEffect(() => {
    if (isFilterCleared) {
      
      setAllFilters([]);
      setIsFilterCleared(false);
    }
  }, [isFilterCleared]);

  useEffect(() => {
    (async function () {
      const filters = [];
      if (state?.filters?.length > 0) {
        state?.filters?.forEach((filter) => {
          if (
            filter.value !== undefined &&
            filter.value !== null &&
            filter.value !== ""
          ) {
            filters.push(`${filter.id}=${encodeURIComponent(filter.value)}`);
          }
        });
      }
      const filtersString = filters.join("&");

      const formattedFiltersString =
        filtersString.length > 0 ? `&${filtersString}` : "";

        console.log("filtersString",filtersString);
        setFilterString(formattedFiltersString)
      const response = await dispatch(
        getAllFroums({ pageNum, pgSize, filtersString: formattedFiltersString })
      );
      const forumDetailsDTOList = response?.payload?.forumDetailsDTOList || [];

      setPaginationData({
        totalPages: response?.payload?.totalPages,
        recordCount: response?.payload?.recordCount,
      });
      setData(forumDetailsDTOList);
    })();
  }, [state?.filters, pageNum, pgSize]);

  let caseDropdownState = Object.values(isMenuOpen || {})?.some(
    (val) => val
  );
  let caseResponsive =
    caseDropdownState && (isBusy || data.length === 0 || data.length <= 6)
      ? "overFlowClp"
      : "table-responsive";
  let caseResponsiveTable =
  caseDropdownState && (isBusy || data.length === 0 || data.length <= 6)
      ? "respondv responDvMinH"
      : "respondv";
  let casePanelOverflow =
    caseDropdownState && (isBusy || data.length === 0 || data.length <= 6)
      ? "overflow-clip"
      : "";

  return (
    <PanelGroup direction="horizontal" className={casePanelOverflow}>
      <Panel id="sidebar" minSize={33} order={1} defaultSize={isEditDetails ? 67 : 100} className={`${casePanelOverflow} ${
          isEditDetails ? "isShowLeftPanel catBordDv" : "catBordDv"
        }`}>
      <div
          aria-label="Table"
          role="contentinfo"
          className={caseResponsiveTable}
        >
      <div className={"tableRgtBor " + caseResponsive}>
        <Table
          striped
          bordered
          hover
          className="tableView nowrapWhright"
          style={{ marginTop: "0px" }}
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    <div className="sortParArr">
                      {column.render("Header")}
                    </div>
                    <div className="colSearDv">
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {isBusy ? (
              <tr>
                <td colSpan="8" style={{ textAlign: "center" }}>
                  <ListLoader />
                </td>
              </tr>
            ) : firstPageRows?.length === 0 ? (
              <tr>
                <td colSpan={columns.length} className="text-center">
                  There is no matching data available
                </td>
              </tr>
            ) : (
              firstPageRows?.map((row, index) => {
                prepareRow(row);
                return (
                  <tr className={
                    isEditDetails && index == rowIndex
                      ? "myActive"
                      : ""
                  }
                    {...row.getRowProps()}
                    onClick={() => handleRowClick(row?.original, index)}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })
            )}
          </tbody>
        </Table>
        </div>
        <div aria-label="Pagination" className="nBotPagina mt-3">
          <div className="nShow">
            <div className="margin-r-10">Show:</div>
            <Form.Select
              value={pgSize}
              onChange={(e) => {
                onPageSizeChange(pageNum, e.target.value);
              }}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </Form.Select>
          </div>
          <nav aria-label="Pagination" className="display-inline-block">
            <Pagination
              prevPageText="Prev"
              nextPageText="Next"
              firstPageText={<i className="glyphicon glyphicon-menu-left" />}
              lastPageText={<i className="glyphicon glyphicon-menu-right" />}
              activePage={pageNum + 1}
              itemsCountPerPage={pgSize}
              totalItemsCount={paginationData?.recordCount}
              pageRangeDisplayed={4}
              onChange={(e) => onPageChange(e)}
            />
          </nav>
        </div>
        </div>
      </Panel>
      {isEditDetails ?
        <>
          <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
          <Panel minSize={33} order={2} defaultSize={33}>
            <ForumShortDescriptionList
              rowData={rowData}
              setIsEditDetails={setIsEditDetails}
            />
          </Panel>
        </> : ""
      }
    </PanelGroup>
  );
}

const ListSec = ({
  setPageNum,
  pageNum,
  setPgSize,
  pgSize,
  isFilterCleared,
  setIsFilterCleared,
  ...props
}) => {
  const dispatch = useDispatch();

  const froumStore = useSelector((state) => state.forums);

  const [listData, setListData] = useState([]);
  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [allRegions, setAllRegions] = useState([]);
  const [allTrackOptions, setAllTrackOptions] = useState([]);
  const [subTrackOptions, setSubTrackOptions] = useState([]);
  const [rowIndex, setrowIndex] = useState(0);  
  const [isEditDetails, setIsEditDetails] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState({});

  const multiDropdownTrackRef = useRef(null);
  const multiDropdownSubTrackRef = useRef(null);
  const multiDropdownRegionRef = useRef(null);
  const multiDropdownTypeRef = useRef(null);
  const multiDropdownStatusRef = useRef(null);
  console.log("v isFilterCleared",isFilterCleared);

  const showDropdownFun = (id, val) => {
    if (id === "allFalse") {
      if (Object.keys(isMenuOpen).length > 0) {
        setIsMenuOpen({});
      }
    } else {
      setIsMenuOpen({ [id]: val });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "forumName",
      },
      {
        Header: "Company",
        accessor: "customerName",
      },
      {
        Header: "Track",
        accessor: "trackName",
        Filter: ({ column, setFilter }) => (
          <SelectColumnFilterCheckbox
            column={column}
            setFilter={setFilter}
            data={allTrackOptions}
            clearAll={!props?.showFilterIcon}
            isEditDetails={isEditDetails}
            setIsEditDetails={setIsEditDetails}
            showDropdownFun={showDropdownFun}
            ref={multiDropdownTrackRef}
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
          />
        ),
      },
      {
        Header: "Sub-Track",
        accessor: "subTrackName",

        Filter: ({ column, setFilter }) => (
          <SelectColumnFilterCheckbox
            column={column}
            setFilter={setFilter}
            data={subTrackOptions}
            clearAll={!props?.showFilterIcon}
            isEditDetails={isEditDetails}
            setIsEditDetails={setIsEditDetails}
            showDropdownFun={showDropdownFun}
            ref={multiDropdownSubTrackRef}
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
          />
        ),
      },
      {
        Header: "Region",
        accessor: "regionName",
        Filter: ({ column, setFilter }) => (
          <SelectColumnFilterCheckbox
            column={column}
            setFilter={setFilter}
            data={allRegions}
            clearAll={!props?.showFilterIcon}
            isEditDetails={isEditDetails}
            setIsEditDetails={setIsEditDetails}
            showDropdownFun={showDropdownFun}
            ref={multiDropdownRegionRef}
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
          />
        ),
      },
      {
        Header: "Type",
        accessor: "type",
        Filter: ({ column, setFilter }) => (
          <SelectColumnFilterCheckbox
            column={column}
            setFilter={setFilter}
            data={typeOptions}
            clearAll={!props?.showFilterIcon}
            isEditDetails={isEditDetails}
            setIsEditDetails={setIsEditDetails}
            showDropdownFun={showDropdownFun}
            ref={multiDropdownTypeRef}
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
          />
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Filter: ({ column, setFilter }) => (
          <SelectColumnFilterCheckbox
            column={column}
            setFilter={setFilter}
            data={statusOptions}
            clearAll={!props?.showFilterIcon}
            isEditDetails={isEditDetails}
            setIsEditDetails={setIsEditDetails}
            showDropdownFun={showDropdownFun}
            ref={multiDropdownStatusRef}
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
          />
        ),
        // filter: "includes",
      },
      {
        Header: "Created On",
        accessor: "createdOn",
        Filter: false,
      },
    ],
    [typeOptions, allRegions, allTrackOptions, subTrackOptions]
  );

  useEffect(() => {
    (async function () {
      const response = await dispatch(getAllFroums({ pageNum, pgSize }));
      const forumDetailsDTOList = response?.payload?.forumDetailsDTOList || [];

      setPaginationData({
        totalPages: response?.payload?.totalPages,
        recordCount: response?.payload?.recordCount,
      });
      setData(forumDetailsDTOList);
    })();
  }, [pageNum, pgSize]);

  useEffect(() => {
    (async function () {
      // await dispatch(loadBusinessFunctionList());
      // await dispatch(getRegionList());
      const typeresp = await dispatch(getForumType());
      const statusresp = await dispatch(getForumStatus());

      const typeData = typeresp?.payload?.map((item) => ({
        label: item?.fieldNameKey1,
        value: item?.fieldNameKey1,
      }));

      const statusData = statusresp?.payload?.map((item) => ({
        label: item?.fieldNameKey1,
        value: item?.fieldNameKey1,
      }));
      setTypeOptions(typeData);
      setStatusOptions(statusData);
    })();
  }, []);

  useEffect(() => {
    (async function () {
      const regionresp = await dispatch(getRegionList());

      if (regionresp?.payload) {
        const data = regionresp?.payload?.result?.map((item) => ({
          label: item?.regionName,
          value: item?.regionName,
        }));
        setAllRegions(data);
      }
    })();
  }, []);

  useEffect(() => {
    (async function () {
      const alltracks = await dispatch(getAllTracks());
      const subtrackData = await dispatch(getAllSubTracks());

      const trackData = alltracks?.payload?.trackNames?.map((item) => ({
        label: item,
        value: item,
      }));

      const subData = subtrackData?.payload?.subTrackNames?.map((item) => ({
        label: item,
        value: item,
      }));
      setAllTrackOptions(trackData);

      setSubTrackOptions(subData);
    })();
  }, []);

  return (
    <RenderTable
      columns={columns}
      data={data || []}
      isBusy={props?.isBusy}
      paginationData={paginationData}
      setPaginationData={setPaginationData}
      setPageNum={setPageNum}
      setPgSize={setPgSize}
      pgSize={pgSize}
      pageNum={pageNum}
      setData={setData}
      isRightSideVisible={props?.isRightSideVisible}
      setrowIndex={setrowIndex}
      rowIndex={rowIndex}
      isEditDetails={isEditDetails}
      setIsEditDetails={setIsEditDetails}
      isMenuOpen={isMenuOpen}
      setFilterString={props.setFilterString}
      isFilterCleared={isFilterCleared}
      setIsFilterCleared={setIsFilterCleared}
      showDropdownFun={showDropdownFun}
    />
  );
};

export default ListSec;
