
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import ReactDom from 'react-dom';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { loadReportList, emptyReportList } from '../../actions/reports/reportsAction';
let GLOBAL = require('../../components/Globals');
import constanturl from '../../../src/utils/constants.js';
import { connect } from 'react-redux';
import Spinner from 'react-spinkit';
import LoadingIcons from 'react-loading-icons';
import TableComponent from "../common/ReactTable/TableComponent";

class GenerateReport extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
		this.camelize = this.camelize.bind(this);
		this.humanize = this.humanize.bind(this);
		this.onRowSelect = this.onRowSelect.bind(this);
	}

	componentWillMount() {
		this.props.loadReportList(this.props.searchbylist, this.props.operatorlist, this.props.valuelist, this.props.module, this.props.companyId);
	}

	camelize(str) {
		return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
			if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
			return index == 0 ? match.toLowerCase() : match.toUpperCase();
		});
	}

	humanize(str) {
		let frags = str.split('_');
		for (let i = 0; i < frags.length; i++) {
			frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
		}
		return frags.join(' ');
	}

	createDateFormat(cell, row) {
		if (row.createdOn != "" || row.createdOn != undefined) {
			return (
				moment.utc(row.createdDate).format("YYYY-MM-DD HH:mm:ss")
			);
		} else {
			return (
				""
			);
		}
	}

	createCustomDataFormat(cell, row) {
		if (row.status != undefined) {
			return (
				row.status
			);
		} else {
			return (
				""
			);
		}
	}

	
	
	
	renderSelectCriteria(criteriaData) {
		if (!criteriaData) {
			return (
				<span></span>
			);
		}

		let criterialength = criteriaData.length
		return criteriaData.map((criteriaObj, i) => {
			if (criteriaObj.attributename == "Breakfix Number")
				criteriaObj.attributename = "Incident Number"
			if (criterialength === i + 1) {
				return (
					<span>,{criteriaObj.attributename} - {criteriaObj.attributeval}</span>
				)
			}
			//  else {
			// 	return (
			// 		<span>,{criteriaObj.attributename} - {criteriaObj.attributeval}</span>
			// 	)
			// }
		});
	}

	reportList() {
		// this.props.loadReportList("createdOn", "like", "99-99-9999", "Breakfix", 0);
		this.props.emptyReportList();
	}
    renderReportTable(breakFixData) {		
		let filterColumns = [] ;
        let columnDetails ;
        let tableColumn =[];
        let columnName;
        let ID;

        if(this.props.module == "CMDB"){
            ID = 'CI_ID';
        }
        else if(this.props.module == "Task"){
            ID = 'taskId';
        }
        else if(this.props.module == "Fulfillment"){
            ID = 'itemId';
        }
        else if(this.props.module == "Problem"){
            ID = 'investigationId';
        }
        else if(this.props.module == "Change"){
            ID = 'changeId';
        }
        else if(this.props.module == "Incident"){
            ID = 'breakfixId';
        }
        if(this.props.module == "Task" && breakFixData.data == undefined){
			Object.keys(breakFixData).forEach(myObj => {
			if(Object.keys(myObj).length !== 0){
					breakFixData[myObj].status = breakFixData[myObj].status.status;
				}
			})
		}

	
		if (this.props.module == "Change" || this.props.module == "RFC" || this.props.module == "Investigation" || this.props.module == "Problem" || this.props.module == "Fulfillment" || this.props.module == "Incident" || this.props.module == "Breakfix")
			breakFixData = breakFixData.data;

		if (breakFixData == undefined || breakFixData.length == undefined) {
			return (
				<LoadingIcons.Bars height="5em" stroke="transparent" fill="#06bcee" />
			);
		} else if (breakFixData.length == 0) {
			return (
				<div className='text-c padding-5'>{this.props.translator['There is no matching data available']}</div>

			);
		} else if (this.props.selectedFields == "" || this.props.selectedFields == undefined) {
			return (
				<div><font color="green" ><b>{this.props.translator['Please select fields']}</b></font></div>
			);
		} else {
			let selectfieldlist = this.props.selectedFields

			let selectfieldarray = []; let myObj = [];
			for (let k = 0; k < selectfieldlist.length; k++) {
				let rowValue='';
				myObj = { headerName: '', headerValue: '' };
				
				let selectfieldseparator = "";
				if (this.props.module == "Task") {
					selectfieldseparator = selectfieldlist[k].replace(/_/g, " ");
				} else if (this.props.module == "CMDB") {
					selectfieldseparator = selectfieldlist[k];
					if(selectfieldseparator == 'STATUS')
						selectfieldseparator = 'STATUS_NAME';
				} else {
					selectfieldseparator = selectfieldlist[k].replace(/_/g, " ").toLowerCase();
				}
				if (this.props.module != "CMDB") {
					selectfieldseparator = this.camelize(selectfieldseparator);
				}
				if (selectfieldlist[k] == "sla_status")
					myObj.headerName = "SLA Status"
				else {
					if (this.props.module == "Task") {
						if (selectfieldlist[k] == 'parentWorkItemId')
							myObj.headerName = 'Parent Work Item Id';
						else if (selectfieldlist[k] == 'taskCode')
							myObj.headerName = 'Task ID';
						else if (selectfieldlist[k] == 'serviceName')
							myObj.headerName = 'Service/CI';
						else if (selectfieldlist[k] == 'description')
							myObj.headerName = 'Summary';
						else if (selectfieldlist[k] == 'companyName')
							myObj.headerName = 'Company';
						else if (selectfieldlist[k] == 'createdDate')
							myObj.headerName = 'Reported On';
						else if (selectfieldlist[k] == 'status')
							myObj.headerName = 'Status';
						else if (selectfieldlist[k] == 'createdBy')
							myObj.headerName = 'Created By';
						else
							myObj.headerName = this.humanize(selectfieldlist[k]);
					}
					else
						myObj.headerName = this.humanize(selectfieldlist[k]);
				}

                myObj.headerValue = selectfieldseparator;
                selectfieldarray.push(myObj);

                if (selectfieldarray != null) {
                    rowValue = selectfieldarray[k].headerValue;
                    filterColumns.push(rowValue);
                    if(selectfieldarray[k].headerName == "Breakfix Number" ){
                        columnName = "Incident Number";
                    }else{
                        columnName = selectfieldarray[k].headerName;
                    }
                    columnDetails =
                    {
                        name: columnName,
                        selector: (row, index, fieldName=rowValue) => row[fieldName],
                        sortable: true,
                    }
                    tableColumn.push(columnDetails);
                }
           
			}
		return (
			<div className="dtaTableDv datConTab integrateHubTable">
					<TableComponent
						onRowSelect={this.onRowSelect}
						data={breakFixData}
						columns={tableColumn}
                        headerColumns={filterColumns}   
                        uniqueKey = {ID}  
                        exportButton = {true}
					/>
			</div>
		);
       }
	}
	onRowSelect(value){
		
	}

	render() {
		return (
			<div className='reptDv'>
				<div className="bgActive padding-10">
					<Row>
						<Col md={9} sm={7} xs={12}>
							<h2 className="margin-0">{this.props.translator['Generated Report']}</h2>
						</Col>
						<Col md={3} sm={5} xs={12}>
							<div className="status myStatus">
                            <ButtonToolbar className="mybtnToolbar"><Button onClick={() => { this.props.callParFun(false, false); this.reportList(); }} variant="primary" size="xs">{this.props.translator['Edit Filters']}</Button>
							</ButtonToolbar></div>
						</Col>
					</Row>
				</div>

				<Row className="myFilterInput">
					<Col xs={12}>
						<div className="searchRslt">
							<div className="searchRsltbox">
								<strong>{this.props.translator['Applied Criteria']}-</strong>
								{this.props.module == "" ? <span></span> : <span>{this.props.translator['Module']} - {this.props.module},</span>}
								{this.props.companyId == "" ? <span></span> : <span>{this.props.translator['Company']} - {this.props.companyName}</span>}
								{this.renderSelectCriteria(this.props.criteriaObject)}</div>
						</div>
					</Col>
				</Row>
				{this.renderReportTable(this.props.reportListData)}
			</div>
		);
	}
}

export function mapStateToProps({ reportListData }) {
	return {
		reportListData: reportListData
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ loadReportList, emptyReportList }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(GenerateReport);
