
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { change, reduxForm } from "redux-form";
import { useDispatch } from "react-redux";
import { getLanguageString } from "../../../../common/helper";

const DatePickerField = (props) => {
  const dispatch = useDispatch();
  const { attributes, onValueChange, defaultValue, language, readOnly, nonEditable } = props;

  return (
    <DatePicker
      value={defaultValue || new Date()}
      placeholder={getLanguageString(language,attributes.placeholder)}
      format={attributes.format || "dd/MM/yyyy"}
      disabled={attributes.disabled || nonEditable}
      onChange={onValueChange}
      readOnly={readOnly}
    />
  );
};

// export default reduxForm({
//   form: "FormView",
// })(DatePickerField);

export default DatePickerField;
