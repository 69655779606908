
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { Component } from "react";
import { Alert } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import moment from "moment";
import AutoSuggest from "react-autosuggest";
import axios from "axios";
import Cookies from "universal-cookie";
import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import { MyContext } from '_MyContext';
import { Link } from "react-router-dom";

const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home") homepagelocation = "/" + homepagelocation;
let user_roleId = cookies.get("gph");
if (user_roleId) user_roleId = user_roleId.replace("s:", "");
if (user_roleId)
  user_roleId = user_roleId.substring(0, user_roleId.lastIndexOf("."));
user_roleId = user_roleId.split("~");
user_roleId = user_roleId[33];
import { GLOBAL } from "_Globals";
import swal from "sweetalert";
import PindCategories from "_Commons/header/pind-categories";
import { connect } from "react-redux";
import { getDelegationList } from "_Actions/delegations/delegationsActions.js";
let selectOptions = [];
let consumerData = [];
let myObj = [];
let consumerDataDetails = [];
let delegateeCreate = "";

let selectOptions2 = [];
let consumerData2 = [];
let myObj2 = [];
let consumerDataDetails2 = [];
let delegatorCreate = "";
import { _dateTimeField } from "../serviceDesk/common/formFields";
import { Field, reduxForm } from "redux-form";
import { navigationHooks } from "../../helpers/NavigationHook";

const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

const getSuggestions = (value, options) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  if (inputLength > 2) {
    selectOptions = options;
  }
  return inputLength < 2
    ? []
    : selectOptions.filter(
        (option) => option.label.toLowerCase().indexOf(inputValue) > -1
      );
};

const getSuggestionValue = (suggestion) =>
  suggestion.label.indexOf == -1
    ? ""
    : suggestion.label.substring(0, suggestion.label.indexOf("|"));
function renderSuggestion(suggestion) {
  let suggestionlabel = [];
  suggestionlabel = suggestion.label.split("|");
  // delegateeCreate = suggestion.value;
  return <span>{suggestion.label}</span>;
}

class SearchDelegatee extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onSuggestionsFetchRequested =
      this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested =
      this.onSuggestionsClearRequested.bind(this);
    this.state = {
      value: "",
      suggestions: [],
      loader: false,
    };
  }


  onChange(event, { newValue }) {
    this.setState({
      value: newValue,
    });
    // if (newValue.length == 0) {
    //   delegateeCreate = '';
    // }
    delegateeCreate = "";
  }
  onSuggestionsFetchRequested({ value }) {
    if (value.length > 4) {
      let strObj = {};
      strObj.userId = "";
      strObj.like = value;
      strObj = JSON.stringify(strObj);
      api
        .get(GLOBAL.breakFixConsumerUrlNew, { headers: { query: strObj } })
        .then((response) => {
          consumerData = response.data;
          consumerDataDetails = [];
          for (let i = 0; i < consumerData.length; i++) {
            myObj = { value: "", label: "" };
            myObj.value = consumerData[i].userId;
            myObj.label =
              consumerData[i].fullname +
              "|" +
              consumerData[i].employeeNumber +
              "|" +
              consumerData[i].email;
            consumerDataDetails.push(myObj);
          }
          consumerDataDetails = Array.from(new Set(consumerDataDetails));
          this.setState({
            suggestions: getSuggestions(value, consumerDataDetails),
          });
        });
    } else {
      this.setState({
        suggestions: [],
      });
    }
  }
  onSuggestionsClearRequested() {
    this.setState({
      suggestions: [],
    });
  }

  onSelect(event, { suggestion }) {
    // console.log("suggestions",suggestion);
    delegateeCreate = suggestion.value;
  }

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder:
        this.props.translator["Search by Name, Email and Employee ID"],
      className: `form-control ${this.props.errorColour}`,
      value,
      onChange: this.onChange,
      onBlur: this.validateField,
    };
    return (
      <AutoSuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={this.onSelect}
      />
    );
  }
}
const getSuggestions2 = (value, options) => {
  const inputValue2 = value.trim().toLowerCase();
  const inputLength2 = inputValue2.length;
  if (inputLength2 > 2) {
    selectOptions2 = options;
  }
  return inputLength2 < 2
    ? []
    : selectOptions2.filter(
        (option) => option.label.toLowerCase().indexOf(inputValue2) > -1
      );
};

const getSuggestionValue2 = (suggestion) =>
  suggestion.label.indexOf == -1
    ? ""
    : suggestion.label.substring(0, suggestion.label.indexOf("|"));
function renderSuggestion2(suggestion) {
  let suggestionlabel = [];
  suggestionlabel = suggestion.label.split("|");
  // delegatorCreate = suggestion.value;
  return <span>{suggestion.label}</span>;
}

class SearchDelegator extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onSuggestionsFetchRequested =
      this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested =
      this.onSuggestionsClearRequested.bind(this);
    this.state = {
      value: "",
      suggestions: [],
      loader: false,
    };
  }
  onChange(event, { newValue }) {
    this.setState({
      value: newValue,
    });
    // if (newValue.length == 0) {
    //   delegatorCreate = '';
    // }
    delegatorCreate = "";
  }
  onSuggestionsFetchRequested({ value }) {
    if (value.length > 4) {
      let strObj = {};
      strObj.userId = "";
      strObj.like = value;
      strObj = JSON.stringify(strObj);
      api
        .get(GLOBAL.breakFixConsumerUrlNew, { headers: { query: strObj } })
        .then((response) => {
          consumerData2 = response.data;
          consumerDataDetails2 = [];
          for (let i = 0; i < consumerData2.length; i++) {
            myObj2 = { value: "", label: "" };
            myObj2.value = consumerData2[i].userId;
            myObj2.label =
              consumerData2[i].fullname +
              "|" +
              consumerData2[i].employeeNumber +
              "|" +
              consumerData2[i].email;
            consumerDataDetails2.push(myObj2);
          }
          consumerDataDetails2 = Array.from(new Set(consumerDataDetails2));
          this.setState({
            suggestions: getSuggestions2(value, consumerDataDetails2),
          });
        });
    } else {
      this.setState({
        suggestions: [],
      });
    }
  }
  onSuggestionsClearRequested() {
    this.setState({
      suggestions: [],
    });
  }

  onSelect2(event, { suggestion }) {
    // console.log("suggestions",suggestion);
    delegatorCreate = suggestion.value;
  }

  render() {
    const value2 = this.state.value;
    const suggestions2 = this.state.suggestions;
    const inputProps2 = {
      placeholder:
        this.props.translator["Search by Name, Email and Employee ID"],
      className: `form-control ${this.props.errorColour}`,
      value: value2,
      onChange: this.onChange,
      onBlur: this.validateField,
    };
    return (
      <AutoSuggest
        suggestions={suggestions2}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue2}
        renderSuggestion={renderSuggestion2}
        inputProps={inputProps2}
        onSuggestionSelected={this.onSelect2}
      />
    );
  }
}

let CreateDelegate = class CreateDelegate extends React.Component {
  static contextType = MyContext;
  constructor(props) {
    super(props);
    this.state = {
      status: "",
      delegateFromDate: "",
      delegatee: "",
      delegator: "",
      delegatetoDate: "",
      moduleId: "",
      delegateeSearchError: "",
      delegationForError: "",
      statusError: "",
      delegateFromDateError: "",
      delegatetoDateError: "",
      showAlertMessage: false,
      adminRole: false,
      moduleError: "",
      loader: false,
      role_id: "",
    };
    this.setFormProperties = this.setFormProperties.bind(this);
    this.saveDelegation = this.saveDelegation.bind(this);
    this.loadHome = this.loadHome.bind(this);
    this.location= navigationHooks.location;
  }
  componentDidMount() {
    this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
  }
  componentDidUpdate() {
    this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
  }
  componentWillUnmount() {
    delegatorCreate = "";
    delegateeCreate = "";
  }
  componentWillMount() {
    if (user_roleId) {
      let roleArr = user_roleId.split(",");
      let adminRole = roleArr.includes("1");
      this.setState({ adminRole: adminRole });
    }
    // let adminRole = user_roleId.includes('1');
    // this.setState({ adminRole: adminRole });
  }
  // componentWillReceiveProps(nextProps) {
  //   if(nextProps.role_id && nextProps.role_id != this.props.role_id) {
  //     let roleArr = nextProps.role_id.split(',');
  //     let adminRole = roleArr.includes('1');
  //     console.log('roleArr - ', {roleArr, adminRole});
  //     this.setState({adminRole: adminRole});
  //   }
  // }
  loadHome() {
    // this.props.navigate(`/${homepagelocation}`);
    navigationHooks.navigate(homepagelocation);
  }
  setFormProperties(value, setPropertyOf) {
    switch (setPropertyOf) {
      case "status":
        this.setState({ status: value });
        break;
      case "moduleId":
        this.setState({ moduleId: value });
        break;
      case "delegateDateforFrom":
        this.setState({ delegateFromDate: value });
        break;
      case "delegateDateforTo":
        this.setState({ delegatetoDate: value });
        break;
    }
  }

  saveDelegation(event) {
    //  debugger
    event.preventDefault();
    let delegationObj = {
      status: "",
      delegateFromDate: "",
      delegatee: "",
      delegator: "",
      delegatetoDate: "",
      moduleId: "",
    };

    if (delegateeCreate == "") {
      this.setState({ delegateeSearchError: "error" });
    } else {
      this.setState({ delegateeSearchError: "" });
    }
    if (this.state.status == "") {
      this.setState({ statusError: "error" });
    } else {
      this.setState({ statusError: "" });
    }
    if (this.state.adminRole && delegatorCreate == "") {
      this.setState({ delegationForError: "error" });
    } else {
      this.setState({ delegationForError: "" });
    }
    if (
      this.state.adminRole &&
      delegatorCreate != "" &&
      delegateeCreate != "" &&
      delegatorCreate == delegateeCreate
    ) {
      swal({
        text: this.props.translator[
          "Delegation for and delegatee cannot be same."
        ],
        button: this.props.translator["OK"],
      });
      return false;
    }
    if (
      !this.state.adminRole &&
      delegateeCreate != "" &&
      delegateeCreate == this.location.state.user_id
    ) {
      swal({
        text: this.props.translator[
          "Logged in user and delegatee cannot be same."
        ],
        button: this.props.translator["OK"],
      });
      return false;
    }
    // if (this.state.delegateFromDate == '') {
    //   alert('select delegate from date');
    // } else {
    //   if (this.state.delegatetoDate == '') {
    //     alert('select delegate to date');
    //   } else {
    //     if (this.state.delegateFromDate == this.state.delegatetoDate) {
    //       alert('delegate to date and from date cannot be same');
    //     }
    //   }
    // }

    if (this.state.delegateFromDate == "") {
      swal({
        text: this.props.translator["Select delegate from date"],
        button: this.props.translator["OK"],
      });
      // alert(this.props.translator["Select delegate from date"]);
      this.setState({ delegateFromDateError: "error" });
      return false;
    } else {
      this.setState({ delegateFromDateError: "" });
    }

    if (this.state.delegatetoDate == "") {
      swal({
        text: this.props.translator["Select Delegated till date"],
        button: this.props.translator["OK"],
      });
      // alert(this.props.translator["Select delegate to date"]);
      this.setState({ delegatetoDateError: "error" });
      return false;
    } else {
      this.setState({ delegatetoDateError: "" });
    }

    if (this.state.delegateFromDate && this.state.delegatetoDate) {
      if (moment(this.state.delegateFromDate) < moment()) {
        swal({
          text: this.props.translator[
            "Delegate from date must not be less than current time"
          ],
          button: this.props.translator["OK"],
        });
        // alert(this.props.translator["Delegate from date must not be less than current time"]);
        return false;
      }

      if (moment(this.state.delegatetoDate) < moment()) {
        swal({
          text: this.props.translator[
            "Delegated till date must not be less than current time"
          ],
          button: this.props.translator["OK"],
        });
        return false;
      }
      if (
        moment(this.state.delegateFromDate) >= moment(this.state.delegatetoDate)
      ) {
        swal({
          text: this.props.translator[
            "Delegate from date must be less than delegate to date"
          ],
          button: this.props.translator["OK"],
        });
        // alert(this.props.translator["Delegate from date must be less than delegate to date"]);
        return false;
      }
    }
    if (this.state.moduleId == "") {
      this.setState({ moduleError: "error" });
    } else {
      this.setState({ moduleError: "" });
    }

    if (
      delegateeCreate != "" &&
      this.state.status != "" &&
      this.state.delegateFromDate != "" &&
      this.state.delegatetoDate != "" &&
      this.state.moduleId != ""
    ) {
      if (this.state.adminRole) {
        if (delegatorCreate == "") {
          this.setState({
            loader: false,
          });
          this.setState({ showAlertMessage: true });
          return false;
        } else {
          delegationObj.delegator = delegatorCreate;
        }
      }
      this.setState({
        loader: true,
      });
      this.setState({ showAlertMessage: false });
      delegationObj.status = this.state.status;

      if (delegationObj && this.state.delegateFromDate) {
        delegationObj.delegateFromDate = moment(
          this.state.delegateFromDate
        ).format("MM-DD-YYYY HH:mm:ss");
      } else {
        delegationObj.delegateFromDate = "";
      }

      if (delegationObj && this.state.delegatetoDate) {
        delegationObj.delegatetoDate = moment(this.state.delegatetoDate).format(
          "MM-DD-YYYY HH:mm:ss"
        );
      } else {
        delegationObj.delegateFromDate = "";
      }

      // delegationObj.delegateFromDate = this.state.delegateFromDate;
      delegationObj.delegatee = delegateeCreate;
      // delegationObj.delegatetoDate = this.state.delegatetoDate;
      delegationObj.moduleId = this.state.moduleId;

      axios.post(GLOBAL.postDelegation, delegationObj).then((response) => {
        if (response.status == 200 || response.status == 201) {
          // this.setState({ loader: true });
          this.props.navigate("/delegate");
          this.props.getDelegationList(
            "",
            1,
            10,
            "desc",
            "delegateDateforFrom",
            "Active",
            ""
          );
          // swal({
          //   text: this.props.translator["Delegation created successfully"],
          //   button: this.props.translator['OK'],
          // });
          // alert(this.props.translator["Delegation created successfully"]);
          delegatorCreate = "";
          delegateeCreate = "";
        } else {
          swal({
            text: this.props.translator["Error in creating delegation"],
            button: this.props.translator["OK"],
          });
          // alert(this.props.translator["Error in creating delegation"]);
        }
        this.setState({ loader: false });
      });
    } else {
      this.setState({ loader: false });
      this.setState({ showAlertMessage: true });
    }
  }

  render() {
    return (
      <main>
        {/* <div className="gloSearchDiv"><SxSearch tr={this.props.translator} /></div> */}

        <div >
          <PindCategories />

          <div className="container-fluid margin-b-15" >
            <nav aria-label="Breadcrumb">
              <ul className="breadcrumb creDele">
                <li className="breadcrumb-item">
                  <a
                    href={void(0)}
                    role="button"
                    onClick={this.loadHome}
                  >
                    {this.props.translator["Home"]}
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a
                    href={void(0)}
                    role="button"
                    onClick={() => {
                      this.props.navigate("/delegate");
                    }}
                  >
                    {this.props.translator["My Delegations"]}
                  </a>
                </li>
                <li className="breadcrumb-item active">
                  {this.props.translator["Create"]}
                </li>
              </ul>
            </nav>
          </div>

          <div className="container-fluid" bsClass="">
            <Row className="margin-b-15">
              <Col lg={6} md={6} sm={7} xs={8}>
                <h1 bsClass="" className="sPageHeading1">
                  {this.props.translator["My Delegations"]}
                </h1>
              </Col>
              <Col lg={6} md={6} sm={5} xs={4}>
                <div className="paHedFilter">
                  {/* <ButtonToolbar className="toolbarBtnStyle margin-t-15 text-c"><Button id="creDeleSav" title={this.props.translator['Save']} className="rgSidrkBtn" variant="primary" onClick={(event) => { this.saveDelegation(event); }}>{this.state.loader ? <i className="f-size-13 fa fa-spinner fa-spin"></i> : null} {this.props.translator['Save']}</Button><Button id="creDeleCan" title={this.props.translator['Cancel']} className="rgSiLigBtn" variant="primary" onClick={() => { browserHistory.push('/delegate') }}>{this.props.translator['Cancel']}</Button></ButtonToolbar> */}
                  
                  <Link to="javascript:void(0)" className="myBt plus" id="creDeleSav"
                    role="button"
                    title={this.props.translator["Save"]}
                    onClick={() => {
                      this.saveDelegation(event);
                    }}
                    ref={e => this.saveObjRef = e}
                    >
                    {this.state.loader ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/>}
                  </Link>
                  
                  <Link to="javascript:void(0)" className="myBt cancel" id="closeButton"
                    role="button"
                    title={this.props.translator["Cancel"]}
                    onClick={() => {
                      this.props.navigate("/delegate");
                    }}
                    ref={e => this.closeButton = e}
                    >
                    <IoClose/>
                  </Link>
                </div>
              </Col>
            </Row>

            <Row className="">
              <Col lg={8} md={12} sm={12} xs={12}>
                <form>
                  {/* <div className="rPageHeading">{this.props.translator['Create Delegation']}</div> */}

                  {this.state.showAlertMessage ? (
                    <Alert variant="danger" className="errorMessageSec">
                      <p>
                        {
                          this.props.translator[
                            "The form has missing required fields, please revalidate the highlighted fields and update the form."
                          ]
                        }
                      </p>
                    </Alert>
                  ) : null}

                  <Row>
                    {this.state.adminRole ? (
                      <Col lg={6} md={6} sm={6} xs={12}>
                        <Form.Group className="form-group"
                          validationState={this.state.delegationForError}
                        >
                          <Form.Label bsClass="">
                            <span className="rStar"></span>{" "}
                            {this.props.translator["Delegation For"]}
                          </Form.Label>
                          <div className={"searchFawicon deleDropdown" + this.state.delegationForError}>
                            <SearchDelegator
                              translator={this.props.translator}
                              errorColour={this.state.delegationForError}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                    ) : null}

                    <Col lg={6} md={6} sm={6} xs={12}>
                      <Form.Group className="form-group"
                        validationState={this.state.delegateeSearchError}
                      >
                        <Form.Label bsClass="">
                          <span className="rStar"></span>{" "}
                          {this.props.translator["Delegatee"]}
                        </Form.Label>
                        <div className="searchFawicon deleDropdown">
                          <SearchDelegatee translator={this.props.translator} errorColour={this.state.delegateeSearchError} />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={12}>
                      <Form.Group className="form-group" validationState={this.state.moduleError}>
                        <Form.Label bsClass="">
                          <span className="rStar"></span>{" "}
                          {this.props.translator["Module"]}
                        </Form.Label>
                        <Form.Select
                         className={this.state.moduleError}
                          aria-label={this.props.translator["Module"]}
                          onChange={(event) => {
                            this.setFormProperties(
                              event.target.value,
                              "moduleId"
                            );
                          }}
                        >
                          <option value="">
                            {this.props.translator["Select"]}
                          </option>
                          <option value="10">
                            {this.props.translator["Service Order"]}
                          </option>
                          <option value="14">
                            {this.props.translator["RFC"]}
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={12}>
                      <Form.Group className="form-group"
                        validationState={this.state.delegateFromDateError}
                      >
                        <Form.Label bsClass="">
                          <span className="rStar"></span>{" "}
                          {this.props.translator["Delegation Start"]}
                        </Form.Label>
                        <div className="dateTimeSdiv deledateTimePosTop">
                          {/*<DateField expandOnFocus={false} dateFormat="MM-DD-YYYY HH:mm:ss" minDate={moment()} updateOnDateClick={true} forceValidDate={true} defaultValue={moment()} ><DatePicker navigation={true} locale="en" forceValidDate={true} highlightWeekends={true} highlightToday={true} cancelButton={false} clearButton={false} weekNumbers={false} weekStartDay={1} onChange={(event) => { this.setFormProperties(event, "delegateFromDate"); }} /></DateField>*/}

                          <Field
                            name="delegateDateforFrom"
                            component={_dateTimeField}
                            className="form-control dateTimePickerField"
                            formType="createDelegate"
                            disabledValue={false}
                            defaultValue={moment()}
                            onChange={(event) => {
                              this.setFormProperties(
                                event,
                                "delegateDateforFrom"
                              );
                            }}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={12}>
                      <Form.Group className="form-group"
                        validationState={this.state.delegatetoDateError}
                      >
                        <Form.Label bsClass="">
                          <span className="rStar"></span>{" "}
                          {this.props.translator["Delegation End"]}
                        </Form.Label>
                        <div className="dateTimeSdiv deledateTimePosTop deleMBDateTimePos">
                          {/*<DateField expandOnFocus={false} dateFormat="MM-DD-YYYY HH:mm:ss" minDate={moment()} updateOnDateClick={true} forceValidDate={true} defaultValue={moment()} ><DatePicker navigation={true} locale="en" forceValidDate={true} highlightWeekends={true} highlightToday={true} cancelButton={false} clearButton={false} weekNumbers={false} weekStartDay={1} onChange={(event) => { this.setFormProperties(event, "delegatetoDate"); }} /></DateField>*/}

                          <Field
                            name="delegateDateforTo"
                            component={_dateTimeField}
                            className="form-control dateTimePickerField"
                            formType="createDelegate"
                            disabledValue={false}
                            defaultValue={moment()}
                            onChange={(event) => {
                              this.setFormProperties(
                                event,
                                "delegateDateforTo"
                              );
                            }}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={12}>
                      <Form.Group className="form-group" validationState={this.state.statusError}>
                        <Form.Label bsClass="">
                          <span className="rStar"></span>{" "}
                          {this.props.translator["Status"]}
                        </Form.Label>
                        <Form.Select
                          className={ this.state.statusError}
                          aria-label={this.props.translator["Status"]}
                          onChange={(event) => {
                            this.setFormProperties(
                              event.target.value,
                              "status"
                            );
                          }}
                        >
                          <option value="">
                            {this.props.translator["Select"]}
                          </option>
                          <option value="1">
                            {this.props.translator["Active"]}
                          </option>
                          <option value="2">
                            {this.props.translator["Inactive"]}
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                </form>
              </Col>
              <Col lg={4} md={12} sm={12} xs={12}></Col>
            </Row>
          </div>
        </div>
        {/* {homepagelocation == "/unauthorized" ||
        homepagelocation === "/userActivationPending" ? null : (
          <FooterMain isLogout={GLOBAL.isLogout} />
        )}
        <FooterMain /> */}
      </main>
    );
  }
};

// export default DelegationForm;

// DelegationForm.propTypes = {
//   getDelegationList: PropTypes.func,
// };

CreateDelegate = reduxForm({
  form: "addDelegationForm",
  // validate,
  enableReinitialize: true,
})(CreateDelegate);

const mapStateToProps = ({ delegationList, spcmReducer }) => {
  return {
    delegationList,
    translator: spcmReducer.tr,
  };
};

export default connect(mapStateToProps, { getDelegationList })(CreateDelegate);
