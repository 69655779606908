
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export default function (state = initialState.currencyDetails, action){

	switch (action.type){
		case 'LOAD_CURRENCYDETAILS_SUCCESS':
				//alert(action.breakFix.data.);
				return action.currencyDetails.data;
		case 'LOAD_CURRENCYDETAILS_FAILURE':
				return action.failureMessage;
		default:
				return state;
		}

}
