/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import NlpSearchSubStatusComponent from "./WorkItemBoard/QueryComponent/NlpSearchSubStatusComponent";
import QuerySearchSubStstusComponent from "./WorkItemBoard/QueryComponent/QuerySearchSubStstusComponent";

const SavedQueryDropdown = ({isAiTypeENV, showConditionBuilder, onSubStatusSelect, selectedFilterTab}) => {
  return (
    <>
      <div className="savQueDropDv dropdown">
        {isAiTypeENV ?
          <NlpSearchSubStatusComponent showConditionBuilder={showConditionBuilder} onSubStatusSelect={onSubStatusSelect} selectedFilterTab={selectedFilterTab} />
          :
          <QuerySearchSubStstusComponent showConditionBuilder={showConditionBuilder} onSubStatusSelect={onSubStatusSelect} selectedFilterTab={selectedFilterTab} />
        }
      </div>
    </>
  );
};

export default (SavedQueryDropdown);
