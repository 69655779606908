
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const appliedThemeData = (state = [], action) => {
  if (action.type == "APPLIED_THEME_THEME_DATA_FETCHED") {
    return action.payload;
  } else {
    return state;
  }
};

export const themeData = (state = [], action) => {
  if (action.type == "THEME_DATA_FETCHED") {
    return action.payload;
  } else {
    return state;
  }
};
