
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AmsEditRulesHeader from './amsEditRules/AmsEditRulesHeader.js';
import AmsEditRulesFormTab from './amsEditRules/AmsEditRulesFormTab.js';
import {connect} from 'react-redux';
import { bindActionCreators } from "redux";
import {
	getAMSAttributeList,getRuleConfiguration
  } from '../../actions/amsActions/amsActions.js';
import PopUpAttributes from './amsEditRules/PopUpAttributes.js';
import CreateAttributes from './amsCreateRules/CreateAttributes.js';
import LoadingIcons from 'react-loading-icons';
import Cookies from 'universal-cookie';
import { navigationHooks } from '../../helpers/NavigationHook.js';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

const cookies = new Cookies();
let homepagelocation = cookies.get('gph');
if (homepagelocation) homepagelocation = homepagelocation.replace('s:', '');
if (homepagelocation) homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home")
  homepagelocation = "/" + homepagelocation;

class AmsEditRules extends React.Component {
	constructor(props) {
		super(props);
		this.state ={
			subMenu: '4',
			showHideAlfy:false,
			showHideRightNav:false,
			attributesList: [],
			isChange: false,
			isRightEditPanel: true,
		};
		this.showHideAlfy = this.showHideAlfy.bind(this);
		this.loadHome = this.loadHome.bind(this);
		this.updateAttributesList = this.updateAttributesList.bind(this);
		this.updateIsChange = this.updateIsChange.bind(this);
		this.rightEditPanel=this.rightEditPanel.bind(this);
	};
	componentWillMount() {
		let params = navigationHooks.params;
		this.props.getRuleConfiguration(params.ruleId);
    	this.props.getAMSAttributeList(params.ruleId).then(res => {
			if(Array.isArray(res) && res.length){
				this.setState({attributesList: res});
			}
		}).catch(err => {
			console.error('Error in getting attributes list: ', err);
			this.setState({attributesList: []});
		});
	}
	loadHome() {
		navigationHooks.navigate(homepagelocation);
	}	
	rightEditPanel(value) {
		this.setState({ isRightEditPanel: value });
	}
	showHideAlfy(value){
		this.setState({showHideAlfy: value});
	};

	updateAttributesList(arr){
		try{
			if(Array.isArray(arr)){
				this.setState({attributesList: arr});
			}
		}catch(e){
			console.error('Error: ', e);
		}
	}

	updateIsChange(flag){
		this.setState({isChange: flag});
	}
   render() {
	const  tr=this.props.tr;
	const { attributesList } = this.state;
	if(this.props.ruleConfiguration.ruleId==undefined){
		return (
			<div className='rctLoader'>
				   <LoadingIcons.Bars height="5em" stroke="transparent" fill="#06bcee" />
			</div>
		);
	}

      return (
		<div>
			<div className="container-fluid padding-t-10 margin-b-15">
				<ul className="breadcrumb"><li className="breadcrumb-item"><a role="button" onClick={this.loadHome} >{this.props.tr['Home']}</a></li><li className="breadcrumb-item"><a role="button" onClick={() => { navigationHooks.navigate('/amsHome'); }} >{this.props.tr['Assignment Rules']}</a></li><li className="breadcrumb-item active">{this.props.tr['Edit']}</li></ul>
			</div>

			<div className="minHeight">
			<div className="container-fluid" bsClass="">
				{/* Home Page blue bar Section Start*/}
				<AmsEditRulesHeader tr={tr} attributesList={this.state.attributesList} isChange={this.state.isChange} updateIsChange={this.updateIsChange} rightEditPanel={this.rightEditPanel}/>
				{/* Home Page blue bar Section end*/}
				<PanelGroup direction="horizontal">
					<Panel id="sidebar" minSize={33} order={1} defaultSize={this.state.isRightEditPanel ? 67 : 100} className={this.state.isRightEditPanel ? "isShowLeftPanel" : ""}>
						{/* Xsm Break Fix Form tabs Section Start*/}			
						<AmsEditRulesFormTab tr={tr} updateIsChange={this.updateIsChange} rightEditPanel={this.rightEditPanel}/>
					</Panel>
					{this.state.isRightEditPanel ?
					<>
					<PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
					<Panel minSize={33} order={2} defaultSize={33}>
						<div className="stickyArea rBoxStyle ">
							{this.props.ruleConfiguration.isContextual?
							<CreateAttributes translator={tr} rightEditPanel={this.rightEditPanel}/>
							:
							<PopUpAttributes translator={this.props.tr} moduleId={this.props.moduleId} slaDefId={this.props.slaDefId} slaDefEditDetails={this.props.slaDefEditDetails} attributesList={attributesList} updateAttributesList={this.updateAttributesList} updateIsChange={this.updateIsChange} rightEditPanel={this.rightEditPanel}/>
	                        }
						</div>
					</Panel>
					</> : ""
					}
				</PanelGroup>
			</div>
			</div>
		</div>
      );
   }
}


const mapStateToProps = ({spcmReducer, ruleConfiguration}) => {
	return{		
		tr:spcmReducer.tr,
		ruleConfiguration:ruleConfiguration
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
	  {
		getAMSAttributeList,getRuleConfiguration
	  },
	  dispatch
	);
  }

export default connect(mapStateToProps,mapDispatchToProps)(AmsEditRules);
