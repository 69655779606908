
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/

import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import { Link, useNavigate } from "react-router-dom";
import CreateMailboxConfigHeader from "./CreateMailboxConfigHeader";
import CreatMailboxConfigForm from "./CreateMailboxConfigForm";
import { useSelector } from "react-redux";

import Cookies from "universal-cookie";
import Breadcrumbs from "../../common/header/breadcrumbs.js";
import { Container } from "react-bootstrap";
import "_Css/form/_form.scss";
const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home") homepagelocation = "/" + homepagelocation;

const CreateMailboxConfigIndex = (props) => {
  const [mailBoxType, setMailBoxType] = useState('');
  const [companyError, setCompanyError] = useState("");
  const [offeringError, setOfferingError] = useState('');
  const [requestorError, setRequestorError] = useState('');
  const [selectedCompanyList, setSelectedCompanyList] = useState([]);
  const [selectedRequestor, setSelectedRequestor] = useState({});
  const [selectedOffering, setSelectedOffering] = useState({});

  const [mailboxIdErrorColor, setMailboxIdErrorColor] = useState("");
  const [mailboxProviderErrorColor, setMailboxProviderErrorColor] = useState("");
  const [whitelistedDomainErrorColor, setWhitelistedDomainErrorColor] = useState("");
  const [urgencyErrorColor, setUrgencyErrorColor] = useState("");
  const [impactErrorColor, setImpactErrorColor] = useState("");
  const [actionsErrorColor, setActionsErrorColor] = useState("");
  const [statusErrorColor, setStatusErrorColor] = useState("");
  const [isFulfilled, setIsFulfilled] = useState("");
  const translator = useSelector((state) => state.spcmReducer.tr);

  const setCompanyErrorColor = () => {
    if (selectedCompanyList.length === 1) {
      setCompanyError("");
    } else {
      setCompanyError("error");
    }
  };

  const setOfferingErrorColor = () => {
    if (Object.keys(selectedOffering).length > 0) {
      setOfferingError("");
    } else {
      setOfferingError("error");
    }
  };
  const setRequestorErrorColor = () => {
    if (Object.keys(selectedRequestor).length > 0) {
      setRequestorError('');
    } else {
      setRequestorError('error');
    }
  };
  const isFulfilledSet = (value) => {
    setIsFulfilled(value)
  }

  return (
    <div>
      <div>
        <div className="container-fluid padding-t-10 margin-b-15">
          <Breadcrumbs
            activePageName={"Create"}
            parentPageurl="/systemConfig"
            parentPageName={translator["Mailbox Configuration"]}
          />
        </div>
        <div className="container-fluid" bsClass="">
        
          <Form>
            <CreateMailboxConfigHeader
              translator={translator}
              setRequestorErrorColor={setRequestorErrorColor}
              setOfferingErrorColor={setOfferingErrorColor}
              setCompanyErrorColor={setCompanyErrorColor}
              setActionsErrorColor={setActionsErrorColor}
              setImpactErrorColor={setImpactErrorColor}
              setUrgencyErrorColor={setUrgencyErrorColor}
              setWhitelistedDomainErrorColor={setWhitelistedDomainErrorColor}
              setMailboxProviderErrorColor={setMailboxProviderErrorColor}
              setMailboxIdErrorColor={setMailboxIdErrorColor}
              setStatusErrorColor={setStatusErrorColor}
              isFulfilled={isFulfilled}
            />
            <Row className="row-eq-height">
              <Col md={8}>
                <CreatMailboxConfigForm translator={translator}
                  setMailBoxType={setMailBoxType} 
                  offeringError={offeringError}
                  requestorError={requestorError}
                  companyError={companyError}
                  setRequestorErrorColor={setRequestorErrorColor}
                  setOfferingErrorColor={setOfferingErrorColor}
                  setCompanyErrorColor={setCompanyErrorColor}
                  setSelectedRequestor={setSelectedRequestor}
                  setSelectedCompanyList={setSelectedCompanyList}
                  setSelectedOffering={setSelectedOffering}
                  selectedRequestor={selectedRequestor}
                  selectedCompanyList={selectedCompanyList}
                  actionsErrorColor={actionsErrorColor}
                  impactErrorColor={impactErrorColor}
                  urgencyErrorColor={urgencyErrorColor}
                  whitelistedDomainErrorColor={whitelistedDomainErrorColor}
                  mailboxProviderErrorColor={mailboxProviderErrorColor}
                  mailboxIdErrorColor={mailboxIdErrorColor}
                  setActionsErrorColor={setActionsErrorColor}
                  setImpactErrorColor={setImpactErrorColor}
                  setUrgencyErrorColor={setUrgencyErrorColor}
                  setWhitelistedDomainErrorColor={setWhitelistedDomainErrorColor}
                  setMailboxProviderErrorColor={setMailboxProviderErrorColor}
                  setMailboxIdErrorColor={setMailboxIdErrorColor}
                  isFulfilledSet={isFulfilledSet}
                  setStatusErrorColor={setStatusErrorColor}
                  statusErrorColor={statusErrorColor}
                />
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default CreateMailboxConfigIndex;
