
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Col, Row, Nav } from "react-bootstrap";
import SxCartList from "./ShoppingCartList";
import OrderingInformation from "../checkout/rightSide/OrderingInformation";
import { getOfferingsInCart } from "../../../actions/cart/addToCartActions";
import { loadCartCount } from "../../../actions/common/cartCountAction";
import axios from "axios";
import _ from "lodash";
import { connect } from "react-redux";
import { setSelectedComponentForOffering } from "../../../actions/offeringDetails/offeringDetailsActions";
import { getTranslation } from "../../../actions/spcmActions";
import swal from "sweetalert";
import PindCategories from '../../common/header/pind-categories';
import "_Css/consumer/_shoppingCart.scss";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
let homepagelocation = cookies.get('gph');
if (homepagelocation) homepagelocation = homepagelocation.replace('s:', '');
if (homepagelocation) homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home")
  homepagelocation = "/" + homepagelocation;

const api = axios.create({
  headers: { Pragma: 'no-cache', 'Cache-control': 'no-store' },
});
import { GLOBAL } from '_Globals';
import { navigationHooks } from "../../../helpers/NavigationHook";
import {LuFileText} from "react-icons/lu";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

class ShoppingCartIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offeringsForOrder: [],
      cartListMessage: '',
      cartActionsMessage: '',
      subMenu: '9',
      showHideAlfy: false,
      showHideRightNav: false,
      selectedComponentForOffering: [],
      loaderStatus: true,
      isRightEditPanel: true,
      allServicePrices: {
        itemPriceAndQuantity: [],
        grandTotal: function () {
          let totalPrice = 0;
          this.itemPriceAndQuantity.forEach((service) => {
            totalPrice += service.serviceTotalPrice;
          });
          return totalPrice;
        },
      },
    };
    GLOBAL.searchbarcategory = "All";

    this.updateCart = this.updateCart.bind(this);
    this.loadCart = this.loadCart.bind(this);
    this.changeCompMessage = this.changeCompMessage.bind(this);
    this.showHideAlfy = this.showHideAlfy.bind(this);
    this.showHideRightNav = this.showHideRightNav.bind(this);
    this.deleteOffering = this.deleteOffering.bind(this);
    this.setIsOnBehalfUserValid = this.setIsOnBehalfUserValid.bind(this);
    this.rightEditPanel=this.rightEditPanel.bind(this);
  }

  setIsOnBehalfUserValid(isValid) {
  }
  showHideAlfy(value) {
    this.setState({ showHideAlfy: value });
  }
  rightEditPanel(value) {
		this.setState({ isRightEditPanel: value });
	}

  showHideRightNav(value) {
    this.setState({ showHideRightNav: !this.state.showHideRightNav });
  }

  componentWillMount() {
    this.loadCart();
  }

  componentDidMount() {
    this.setState({
      selectedComponentForOffering: this.props.selectedComponentForOffering,
    });
  }

  changeCompMessage(message, msgFor) {
    if (msgFor === "list") {
      this.setState({ cartListMessage: message });
    }
    if (msgFor === "action") {
      this.setState({ cartActionsMessage: message });
    }
  }

  deleteOffering(offeringId) {
    let {
      allServicePrices,
      selectedComponentForOffering,
      offeringsForOrder,
    } = this.state;
    api
      .post(GLOBAL.delteOfferingFromCart, { "serviceId": offeringId })
      .then((response) => {
        if (response.status === 200) {
          /*remove service and component from state*/
          offeringsForOrder = offeringsForOrder.filter((offering) => {
            return offering.offeringId !== offeringId;
          });
          selectedComponentForOffering = selectedComponentForOffering.filter(
            (component) => {
              return component.offeringId !== offeringId.toString();
            }
          );
          /*remove service price*/
          allServicePrices.itemPriceAndQuantity = allServicePrices.itemPriceAndQuantity.filter(
            (service) => {
              return service.serviceId !== offeringId;
            }
          );
          this.setState({
            allServicePrices,
            selectedComponentForOffering,
            offeringsForOrder,
          });
          this.props.loadCartCount();
        } else {
          //alert('error in deleting offering.');
          swal({
            text: this.props.tr["error in deleting offering."],
            button: this.props.tr["OK"],
          });
        }
      });
  }

  updateCart(offeringId, componentId, actualPrice, componentType, action) {
    let selectedComponentForOffering = JSON.parse(
      JSON.stringify(this.state.selectedComponentForOffering)
    );
    let componentObj = {
      componentId: componentId.toString(),
      offeringId: offeringId.toString(),
      actualPrice: actualPrice,
      componentType: componentType,
    };
    if (action === "add") {
      if (
        !_.some(selectedComponentForOffering, [
          "componentId",
          componentId.toString(),
        ])
      ) {
        selectedComponentForOffering.push(componentObj);
      }
    } else {
      if (action === "remove") {
        if (
          _.some(selectedComponentForOffering, [
            "componentId",
            componentId.toString(),
          ])
        ) {
          selectedComponentForOffering = selectedComponentForOffering.filter(
            (existingComponent) => {
              return existingComponent.componentId !== componentId.toString();
            }
          );
        }
      }
    }
    this.setState({ selectedComponentForOffering });
    this.props.setSelectedComponentForOffering(selectedComponentForOffering);
    this.calculateTotal(
      this.state.offeringsForOrder,
      selectedComponentForOffering
    );
  }

  loadCart() {
    let offeringIds = [];
    /*get all the offerings id's added in cart for the user*/
    const offeringsInCartResoponse = api.get(GLOBAL.cartOfferingsUrl, {
      headers: { query: JSON.stringify({ userId: "" }) },
    });
    offeringsInCartResoponse.then((allOfferingsInCart) => {
      if (allOfferingsInCart.status === 204 || allOfferingsInCart.data === "") {
        this.setState({ offeringsForOrder: [], loaderStatus: false });
      } else {
        let tempOfferingIds = _.uniqBy(allOfferingsInCart.data, "offeringId");
        tempOfferingIds.map((offerings) => {
          if (offerings.offeringId != null) {
            offeringIds.push(offerings.offeringId);
          }
        });
        /*get optional components added in cart corresponding
         * to the offerings got from earlier api call*/
        const optionalComponentInCart = api.get(
          GLOBAL.optionalComponentInCartUrl,
          {
            headers: {
              query: JSON.stringify({ offeringId: offeringIds.toString() }),
            },
          }
        );
        optionalComponentInCart.then((componentsInCart) => {
          if (componentsInCart.data.length == 0) {
            this.setState({ selectedComponentForOffering: [] });
          } else {
            let existingSelectedComponents = Object.assign(
              [],
              this.state.selectedComponentForOffering,
              componentsInCart.data
            );
            this.props.setSelectedComponentForOffering(
              existingSelectedComponents
            );
            this.setState({
              selectedComponentForOffering: existingSelectedComponents,
            });
          }
        });
        /*get details of all the offerings added in cart*/
        const offeringDetailsResponse = api.get(
          GLOBAL.getServicesDetailsAddedInCart,
          {
            headers: {
              query: JSON.stringify({
                userId: "",
                offeringId: offeringIds,
              }),
            },
          }
        );
        offeringDetailsResponse.then((offeringDetails) => {
          this.setState({
            offeringsForOrder: _.uniqBy(offeringDetails.data, "offeringId"),
            loaderStatus: false,
          },()=>{ this.calculateTotal(
            this.state.offeringsForOrder,
            this.state.selectedComponentForOffering
          );});
          let orderItems = this.state.offeringsForOrder;
          let selectedComponents = this.state.selectedComponentForOffering;
          orderItems.forEach((offering) => {
            offering.componentapiList.forEach((component) => {
              let comp = _.find(selectedComponents, {
                componentId: component.componentId.toString(),
              });
              if (comp) {
                comp.acutalPrice = component.actualPrice;
              }
            });
          });
        });
      }
    });
  }

  calculateTotal(offerings, selectedComponentForOffering) {
    let allServicePrices = this.state.allServicePrices;
    offerings.map((offering) => {
      let servicePrice = 0;
      let priceCalculatedForCoreOffering = false;
      offering.componentapiList.forEach((component) => {
        if (
          _.some(selectedComponentForOffering, [
            "componentId",
            component.componentId,
          ]) ||
          (_.some(selectedComponentForOffering, [
            "componentId",
            component.componentId.toString(),
          ]) &&
            offering.hidePrice === "false")
        ) {
          if (
            component.componentType === "Core" &&
            !priceCalculatedForCoreOffering
          ) {
            priceCalculatedForCoreOffering = true;
            servicePrice += parseFloat(component.actualPrice);
          } else {
            if (component.componentType === "Optional") {
              servicePrice += parseFloat(component.actualPrice);
            }
          }
        }
      });
      let service = _.find(allServicePrices.itemPriceAndQuantity, {
        serviceId: offering.offeringId,
      });
      if (service) {
        service.initialPrice = servicePrice;
        service.serviceTotalPrice = servicePrice;
      } else {
        allServicePrices.itemPriceAndQuantity.push({
          serviceId: offering.offeringId,
          initialPrice: servicePrice,
          serviceTotalPrice: servicePrice,
        });
      }
    });
    this.setState({ allServicePrices });
  }
  loadHome() {
    navigationHooks.navigate(homepagelocation);
  }

  render() {
    return (
      <main>
        {/* <div className="gloSearchDiv"><SxSearch tr={this.props.tr} /></div> */}
        {/*<RightSidebarMenulist 
        showhideClass={this.state.showHideRightNav} 
        subMenuNumber={this.state.subMenu}/>*/}
        <div className="minHeight">
          <PindCategories />
          <div className="container-fluid margin-b-15">
            <nav aria-label="Breadcrumb"><ul className="breadcrumb"><li className="breadcrumb-item"><a href="javascript:void(0)" role="button" onClick={this.loadHome} >{this.props.tr['Home']}</a></li><li className="breadcrumb-item active">{this.props.tr['Shopping Cart']}</li></ul></nav>
          </div>
          <div className="container-fluid" bsClass="">
            {/*<InnerCategorySearch/>*/}
            <Row className="margin-b-15">
              <Col md={7} sm={7} xs={12}>
                <h2 bsClass="" className="sPageHeading1">{this.props.tr['Shopping Cart']}</h2>
              </Col>
              <Col md={5} sm={5} xs={12}>
                <div className="float-r toprigLnk d-md-none">
                  <Nav className="icnlnk" as="ul">              
                    <Nav.Item
                      title={this.props.tr["Requests Details"]}
                      as="li"
                    >
                      <a href="javascript:void(0)"
                      onClick={() => {
                        this.rightEditPanel(true);
                      }}
                      className="myBt fillBtn"
                      >
                      <LuFileText/>
                      </a>
                    </Nav.Item>
                  </Nav>
                </div>
              </Col>
            </Row>
            <PanelGroup direction="horizontal">
              <Panel id="sidebar" minSize={33} order={1} defaultSize={this.state.isRightEditPanel ? 67 : 100} className={this.state.isRightEditPanel ? "isShowLeftPanel" : ""}>
                {/* Xsm Break Fix Form tabs Section Start*/}			
                <SxCartList
                  cartOfferings={this.state.offeringsForOrder}
                  updateCart={this.updateCart}
                  cartListMessage={this.state.cartListMessage}
                  changeCompMessage={this.changeCompMessage}
                  selectedComponentForOffering={
                    this.props.selectedComponentForOffering
                  }
                  translator={this.props.tr}
                  loaderStatus={this.state.loaderStatus}
                  rightEditPanel={this.rightEditPanel}
                />
              </Panel>
              {this.state.isRightEditPanel ?
              <>
              <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
              <Panel minSize={33} order={2} defaultSize={33} className="shopCartloader2">
              <OrderingInformation
                  key={this.state.allServicePrices.grandTotal()}
                  offeringsForOrder={this.state.offeringsForOrder}
                  deleteOffering={this.deleteOffering}
                  cartActionsMessage={this.state.cartActionsMessage}
                  changeCompMessage={this.changeCompMessage}
                  selectedComponentForOffering={
                    this.props.selectedComponentForOffering
                  }
                  translator={this.props.tr}
                  loaderStatus={this.state.loaderStatus}
                  allServicePrices={this.state.allServicePrices}
                  setIsOnBehalfUserValid={this.setIsOnBehalfUserValid}
                  rightEditPanel={this.rightEditPanel}
                />
              </Panel>
              </> : ""
              }
            </PanelGroup>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = ({ spcmReducer, selectedComponentForOffering }) => {
  return {
    tr: spcmReducer.tr,
    selectedComponentForOffering,
  };
};
export default connect(mapStateToProps, {
  getOfferingsInCart,
  getTranslation,
  loadCartCount,
  setSelectedComponentForOffering,
})(ShoppingCartIndex);
