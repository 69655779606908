
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Field } from "redux-form";
import { useGetAssociatedRuleMutation } from "../../../reducers/rules";

export const RulesDropdownList = ({
  name,
  formData,
  rulesAttribute,
  moduleName,
  ruleType,
  showAms,
  groupData,
  ruleFor,
  serviceCompanyId,
  ...props
}) => {
  const [ruleDataOptions, setRuleDataOptions] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const {
    status,
    priorityId,
    serviceName,
    impactedCi,
    impactedUsername,
    ciClassName,
    userLocation,
    ciLocation,
    serviceId,
    impactedCiId,
    requestorId,
    classId,
    userLocationId,
    ciLocationId
  } = rulesAttribute || {};

  const [getRulesData, { isLoading: rulesLoading }] =
    useGetAssociatedRuleMutation();

  useEffect(() => {
    if (props.companyId) {
      setLoading(true);
      getRulesData({
        data: {
          status: status?.toString(),
          priorityId: formData?.ItemList?.priorityActualVal?.toString() || priorityId?.toString(),
          serviceName,
          ciName: impactedCi,
          impactedUsername,
          ciClassName,
          requesterLocationName: userLocation,
          ciLocationName: ciLocation,
          ciId: impactedCiId?.toString(),
          serviceId: serviceId?.toString(),
          impactedUserid: requestorId?.toString(),
          ciLocationId: ciLocationId?.toString(),
          ciClassId: classId?.toString(),
          requesterLocationId: userLocationId?.toString()
        },
        moduleName,
        ruleType,
        companyId: props.companyId,
        status: "active",
        weight: "N",
        ruleFor: ruleFor,
        serviceCompanyId: serviceCompanyId,
        ciId: impactedCiId,
        serviceId: serviceId,
        impactedUserid: requestorId,
        ciLocationId: ciLocationId,
        ciClassId: classId,
        requesterLocationId: userLocationId
      })
        .then((response) => {
          let options = response.data?.body?.map((item) => ({
            id: item.responseEntityId + ":" + item.responseEntity,
            label: item.assignmentCompany
              ? `${item.responseEntity} (${item.assignmentCompany})`
              : item.responseEntity,
          }));
          options = [...new Map(
            options.map((item) => [JSON.stringify(item), item])
            ).values(),
          ]
          setRuleDataOptions(options || []);
        })
        .catch((error) => {
          console.error("API Error:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [
    formData?.ItemList?.priorityActualVal,
    formData?.ItemList?.company || formData?.ItemList?.clientId,
    status,
    serviceName,
    impactedCi,
    impactedUsername,
    ciClassName,
    userLocation,
    ciLocation,
    getRulesData,
    moduleName,
    ruleType,
    priorityId,
  ]);
  console.log("ruleDataOption --- ", ruleDataOptions);
  return (
    <Field name={name} {...props}>
      <option value="">Select</option>
      {ruleDataOptions.length > 0 &&
        ruleDataOptions.map((data, i) => {
          return (
            <option key={data.id} value={data.id}>
              {data.label}
            </option>
          );
        })}
    </Field>
  );
};
