
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
const RenderMap = (props) => {
    const {attributes, src, title} = props;
  return <iframe {...attributes} src={src} title={title}></iframe>;
};
export default RenderMap