
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const isFulfillmentGroupLoaded = (state={},action)=>{
    if(action.type==='FULFILLMENT_GROUP_FETCHED'){
        return action.flag;
    }else{
        return state;
    }
}
export const isIncidentGroupLoaded = (state={},action)=>{
    if(action.type==='INCIDENT_GROUP_FETCHED'){
        return action.flag;
    }else{
        return state;
    }
}
export const isProblemGroupLoaded = (state={},action)=>{
    if(action.type==='PROBLEM_GROUP_FETCHED'){
        return action.flag;
    }else{
        return state;
    }
}
export const isChangeMgmtGroupLoaded = (state={},action)=>{
    if(action.type==='CHANGEMGMT_GROUP_FETCHED'){
        return action.flag;
    }else{
        return state;
    } 
}