
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import Nav from "react-bootstrap/Nav";
import Table from "react-bootstrap/Table";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import OnClickOutside from "react-onclickoutside";
import PopUpAttribute from "./attribute";
import PopUpMilestone from "./milestoneNew";
import PopUpRetroActive from "./RectroActive";
import LoadingIcons from "react-loading-icons";

class LeftSidebarMenulist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.leftMenu = React.createRef(); 
  }
  handleClickOutside() {}
  render() {
    if (
      this.props.slaDefEditDetails.companyName == "" ||
      this.props.slaDefEditDetails.companyName == undefined
    ) {
      return (
        <LoadingIcons.Bars height="5em" stroke="transparent" fill="#06bcee" />
      );
    }
    const popoverAttributesData = (
      <Popover className="innerBigPopover" id="popoverAttributes">
        <div className="popover-content"><PopUpAttribute
          translator={this.props.translator}
          moduleId={this.props.moduleId}
          slaDefId={this.props.slaDefId}
          slaDefEditDetails={this.props.slaDefEditDetails}
        /></div>
      </Popover>
    );
    {
      /*const popoverAuditData = (<Popover className="innerBigPopover" id="popoverAudit"><PopUpAuditLog  ciId={this.props.ciId} module="CMDB" /></Popover>);*/
    }
    const popoverMilestoneData = (
      <Popover className="innerBigPopover" id="popoverMilestone">
        <div className="popover-content"><PopUpMilestone
          translator={this.props.translator}
          slaDefId={this.props.slaDefId}
          slaDefEditDetails={this.props.slaDefEditDetails}
        /></div>
      </Popover>
    );
    // const popoverRetroActive = (
    //   <Popover className="innerBigPopover" id="popoverRetroActive">
    //     <div className="popover-content"><PopUpRetroActive
    //       translator={this.props.translator}
    //       slaDefId={this.props.slaDefId}
    //       slaDefEditDetails={this.props.slaDefEditDetails}
    //     /></div>
    //   </Popover>
    // );
    if (this.props.moduleId == "30") {
      return (
        <div
        ref={this.leftMenu}
          className={
            this.props.newMobileLeftMenuFilter ? "leftNav showNav" : "leftNav"
          }
        >
          <Nav bsClass="" className="leftMenu"></Nav>
        </div>
      );
    } else {
      return (
        <div
        ref={this.leftMenu}
          className={
            this.props.newMobileLeftMenuFilter ? "leftNav showNav" : "leftNav"
          }
        >
          <Nav as="ul" bsClass="" className="leftMenu" >
            <OverlayTrigger
              container={this.leftMenu}
              trigger="click"
              rootClose
              placement="right"
              overlay={popoverAttributesData}
            >
              <Nav.Item as="li" eventKey={1}>
                <a
                  href={void(0)}
                  title={this.props.translator["Specify Attribute Values"]}
                >
                  <span className="bgBtn4 lSidebar attbuts"></span>
                </a>
              </Nav.Item>
            </OverlayTrigger>
            <OverlayTrigger
              container={this.leftMenu}
              trigger="click"
              rootClose
              placement="right"
              overlay={popoverMilestoneData}
            >
              <Nav.Item as="li" eventKey={2}>
                <a
                  href={void(0)}
                  title={this.props.translator["Specify Milestone Values"]}
                >
                  <span className="bgBtn8 lSidebar tagged"></span>
                </a>
              </Nav.Item>
            </OverlayTrigger>
            {/* <OverlayTrigger container={this} trigger="click" rootClose placement="right" overlay={popoverAuditData}><Nav.Item href={void(0)} eventKey={2} title="History"><span className="bgBtn2 lSidebar brkf10"></span></Nav.Item></OverlayTrigger> */}
            {/* {this.props.slaDefEditDetails.moduleName === "Incident" &&
            this.props.slaDefEditDetails.goalType === "Resolution" ? (
              <OverlayTrigger
                container={this.leftMenu}
                trigger="click"
				        rootClose
                placement="right"
                overlay={popoverRetroActive}
              >
                <Nav.Item as="li" eventKey={2}>
                  <a
                    href={void(0)}
                    title={this.props.translator["Specify Retroactive Value"]}
                  >
                    <span className="bgBtn4 lSidebar priority"></span>
                  </a>
                </Nav.Item>
              </OverlayTrigger>
            ) : null} */}
          </Nav>
        </div>
      );
    }
  }
}

class PopoverAuditData extends React.Component {
  render() {
    return (
      <div>
        <div className="f-size-18 margin-b-5">Audit</div>

        <div>
          <Table responsive striped bordered condensed hover>
            <thead>
              <tr>
                <th>Attribute</th>
                <th>Old Value</th>
                <th>New Value</th>
                <th>Changed By</th>
                <th>Changed On</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Attribute 1</td>
                <td>Value 1</td>
                <td>Value 2</td>
                <td>Vijay Sharma</td>
                <td>17 Oct, 2017</td>
              </tr>
              <tr>
                <td>Attribute 2</td>
                <td>Value 2</td>
                <td>Value 22</td>
                <td>Dheeraj Saraswat</td>
                <td>17 Oct, 2017</td>
              </tr>
              <tr>
                <td>Attribute 3</td>
                <td>Value 3</td>
                <td>Value 33</td>
                <td>Pallavi Sood</td>
                <td>17 Oct, 2017</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}
LeftSidebarMenulist = OnClickOutside(LeftSidebarMenulist);
export default LeftSidebarMenulist;
