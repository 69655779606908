
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import { reduxForm, SubmissionError } from "redux-form";
import { ImSpinner6 } from "react-icons/im";
import { IoSaveOutline, IoClose } from "react-icons/io5";
import Swal from "sweetalert2";
let sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const SchedulerEditHeader = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const [disableBtn, setDisableBtn] = useState(false);
  const submit = async (values) => {
    Swal.fire({
      // title: "Run Now",
      title:
        "Are you sure you want to run the scheduler? If yes type 'run now'",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: false,
      confirmButtonText: "Run Now",
      showLoaderOnConfirm: true,
      preConfirm: async (text) => {
        if (text == "run now") {
          return true;
        } else {
          Swal.showValidationMessage(`Please enter correct word!`);
          return false;
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        setDisableBtn(true);

        let postJson = {
          jobStatus: values.escalationLevel,
          cronExpression: values.cronExpression,
          actionStatus: props.activeChecked ? "ACTIVE" : "INACTIVE",
        };

        axios
          .patch(
            "/rest/aiosem/updateEscalationPlanAction/" + params.id,
            postJson
          )
          .then((resp) => {
            Swal.fire({
              title: "Your cron has been scheduled successfully!",
            });
            setDisableBtn(false);
          })
          .catch((error) => {
            Swal.fire({
              title: error?.response?.data?.businessMessage,
            });
            setDisableBtn(false);
          });
        // Swal.fire({
        //   title: `${result.value.login}'s avatar`,
        // });
      }
    });
  };

  return (
    <Row className="margin-b-15">
      <Col lg={6} md={6} sm={12} xs={12}>
        <h2 className="sPageHeading1">{tr["Scheduled Job"]}</h2>
      </Col>
      <Col lg={6} md={6} sm={12} xs={12}>
        <div className="paHedFilter">
          <Button
            bsPrefix=" "
            id="creTag"
            role="button"
            className="myBt plus"
            disabled={disableBtn}
            onClick={props.handleSubmit(submit)}
            title={tr["Run Now"]}
          >
            {disableBtn ? (
              <ImSpinner6 className="icn-spinner" />
            ) : (
              <IoSaveOutline />
            )}
          </Button>
          <Button
            bsPrefix=" "
            role="button"
            className="myBt cancel fillBtn"
            title={tr["Cancel"]}
            onClick={() => {
              navigate("/scheduler");
            }}
          >
            <IoClose />
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default reduxForm({
  form: "SchedulerEditForm",
  destroyOnUnmount: true,
  enableReinitialize: true,
})(SchedulerEditHeader);
