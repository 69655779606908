
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import { Link, NavLink } from "react-router-dom";
let sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
import { GLOBAL } from "_Globals";
import axios from "axios";
import { reduxForm, SubmissionError } from "redux-form";
import swal from "sweetalert";
import {navigationHooks} from '../../../helpers/NavigationHook'; 
import Swal from "sweetalert2";
import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import {LuSearch, LuBarChart} from 'react-icons/lu';
import { MyContext } from '_MyContext';

class XsmSupportHeader extends React.Component {
  static contextType = MyContext;
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
    this.state = {
      showSubmit: false,

      loader: false,
    };
  }

  componentDidMount() {
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
    this.context.newTabPrevent();
	}
	componentDidUpdate() {
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
    this.context.newTabPrevent();
	}

  submit(values) {
    this.setState({
      loader: true,
    });
    console.log("submit.......", `${JSON.stringify(values, null, 2)}`);
    let serviceBased;
    if (
      values.service == "service" ||
      values.slaModuleActualName == "Fulfillment"
    ) {
      serviceBased = true;
    } else {
      serviceBased = false;
    }

    return sleep(1000) // simulate server latency
      .then(() => {
        if (`${JSON.stringify(values, null, 2)}` == "{}") {
          throw new SubmissionError({
            _error:
              "The form has missing required fields, please revalidate the highlighted fields and update the form",
          });
        } else if (
          values.slaName == "" ||
          values.slaName.trim() == "" ||
          values.slaCompanyName == "" ||
          values.slaModuleName == "" ||
          values.slaStatusValue == "" ||
          ((values.slaModuleName == "5" || values.slaModuleName == "10") && values.slaRetroactiveRequired == "Yes" &&
          values.impactOnStart == "" )||
          values.slaType == "" ||
          values.slaTargetFor == "" ||
          values.slaTargetTime == "" ||
          (values.slaStatusValue !== "15" && (values.slaWorkdaySchedule == "" || values.slaWorkdaySchedule == "select"))
        ) {
          if (values.slaName == "" || values.slaName.trim() == "") {
            GLOBAL.slaNameErrorColor = "error";
          } else {
            GLOBAL.slaNameErrorColor = "";
          }
          if (values.slaCompanyName == "") {
            GLOBAL.slaCompanyNameErrorColor = "error";
          } else {
            GLOBAL.slaCompanyNameErrorColor = "";
          }

          if (values.slaModuleName == "") {
            GLOBAL.slaModuleNameErrorColor = "error";
          } else {
            GLOBAL.slaModuleNameErrorColor = "";
          }

          if (values.slaStatusValue == "") {
            GLOBAL.slaStatusErrorColor = "error";
          } else {
            GLOBAL.slaStatusErrorColor = "";
          }
          if (values.slaType == "") {
            GLOBAL.slaTypeErrorColor = "error";
          } else {
            GLOBAL.slaTypeErrorColor = "";
          }

          if (values.slaTargetFor == "") {
            GLOBAL.slaTargetForErrorColor = "error";
          } else {
            GLOBAL.slaTargetForErrorColor = "";
          }

          if (values.slaTargetTime == "") {
            GLOBAL.slaTargetTimeErrorColor = "error";
          } else {
            GLOBAL.slaTargetTimeErrorColor = "";
          }

          if ((values.slaModuleName == "5" || values.slaModuleName == "10") && values.slaRetroactiveRequired == "Yes" && values.impactOnStart == "") {
            GLOBAL.slaImpactErrorColor = "error";
          } else {
            GLOBAL.slaImpactErrorColor = "";
          }
          if (values.slaStatusValue !== "15" && (values.slaWorkdaySchedule == "" || values.slaWorkdaySchedule == "select")) {
            GLOBAL.slaWorkdayScheduleErrorColor = "error";
          } else {
            GLOBAL.slaWorkdayScheduleErrorColor = "";
          }
          this.setState({ loader: false });
          throw new SubmissionError({
            _error:
            "The form has missing required fields, please revalidate the highlighted fields and update the form !",
          });
        } else if (
          parseInt(values.slaTargetTime, 10) < 0 ||
          values.slaTargetTime.includes(" ") ||
          parseInt(values.slaTargetTime, 10) > 525600 ||
          Number.isNaN(parseInt(values.slaTargetTime, 10)) ||
          !Number.isInteger(parseFloat(values.slaTargetTime))
        ) {
          if (!Number.isInteger(parseFloat(values.slaTargetTime))) {
            GLOBAL.slaTargetTimeErrorColor = "error";
            throw new SubmissionError({
              _error:"Target time field should be integer value!",
            });
          }
          if (parseInt(values.slaTargetTime, 10) < 0) {
            GLOBAL.slaTargetTimeErrorColor = "error";
            this.setState({ loader: false });
            throw new SubmissionError({
              _error: "Time should not be less than 0",
            });
          } else if (parseInt(values.slaTargetTime, 10) > 525600) {
            GLOBAL.slaTargetTimeErrorColor = "error";
            this.setState({ loader: false });
            throw new SubmissionError({
              _error: "Time should not be greater than 525600",
            });
          } else if (
            values.slaTargetTime.includes(" ") ||
            Number.isNaN(parseInt(values.slaTargetTime, 10))
          ) {
            GLOBAL.slaTargetTimeErrorColor = "error";
            this.setState({ loader: false });
            throw new SubmissionError({
              _error: "Value should be a Positive Number",
            });
          }
        } else if (
          values.slaWorkdaySchedule == "" &&
          (values.slaStatusValue == 5 || values.slaStatusValue == 10)
        ) {
          //alert("values.slaWorkdaySchedule")
          if (values.slaStatusValue !== "15" && values.slaWorkdaySchedule == "") {
            GLOBAL.slaWorkdayScheduleErrorColor = "error";
          } else {
            GLOBAL.slaWorkdayScheduleErrorColor = "";
          }
          throw new SubmissionError({
            _error:
              "The form has missing required fields, please revalidate the highlighted fields and update the form !",
          });
        } else {
          this.setState({ showSubmit: true });
          let slaWorkdayScheduleName = "";
          let slaWorkdaySchedule = "";
          let slaHolidayScheduleName = "";
          let slaHolidaySchedule = "";
          if (values.slaStatusValue == 15) {
            slaWorkdayScheduleName = "";
            slaWorkdaySchedule = "0";
            slaHolidayScheduleName = "";
            slaHolidaySchedule = "0";
          } else if (
            values.slaHolidayScheduleName == "" ||
            values.slaHolidayScheduleName == undefined
          ) {
            slaWorkdayScheduleName = values.slaWorkdayScheduleName;
            slaWorkdaySchedule = values.slaWorkdaySchedule;
            slaHolidayScheduleName = "";
            slaHolidaySchedule = "0";
          } else {
            slaWorkdayScheduleName = values.slaWorkdayScheduleName;
            slaWorkdaySchedule = values.slaWorkdaySchedule;
            slaHolidayScheduleName = values.slaHolidayScheduleName;
            slaHolidaySchedule = values.slaHolidaySchedule;
          }

          let postjson = {
            slaName: values.slaName,
            companyName: values.slaCompanyActualName,
            companyId: values.slaCompanyName,
            moduleName: values.slaModuleActualName,
            moduleId: values.slaModuleName,
            slaStatus: values.slaStatusActualName,
            slaStatusId: values.slaStatusValue,
            // "serviceName": values.slaServiceActualName=="select"?"":values.slaServiceActualName,
            // "serviceId": values.slaServiceName==""?'0':values.slaServiceName,
            // "ciId":values.slaImpactedName==""?'0':values.slaImpactedName,
            ciName:
              values.slaImpactedActualName == "select"
                ? ""
                : values.slaImpactedActualName,
            serviceBased: serviceBased,
            slaType: values.slaTypeName,
            slaTypeId: values.slaType,
            goalType: values.slaTargetForName,
            goalTypeId: values.slaTargetFor,
            goalTime: values.slaTargetTime,
            slaWorkdaySchedule: slaWorkdayScheduleName,
            slaWorkdayScheduleId: slaWorkdaySchedule,
            slaHolidaySchedule: slaHolidayScheduleName,
            slaHolidayScheduleId: slaHolidaySchedule,
           "retroactiveRequired": values.slaRetroactiveRequired,
            createdBy: "",
            createdByName: "",
            impactOnStart:(values.slaModuleActualName==='Problem'||values.slaModuleActualName==='Incident')&&values.slaRetroactiveRequired==='Yes'?values.impactOnStart:"",
            description: values.description ? values.description : "",
          };
          console.log(postjson);

          axios
            .post(GLOBAL.slaDefPostUrl, postjson)
            .then(function (response) {
              console.log(response);
              if (response.status == 201) {
                console.log("creating slaDef record");
                console.log(
                  "slaDefinitionId::" + response.data.slaDefinitionId
                );
                navigationHooks.navigate(
                  "/editSlaDef/" + response.data.slaDefinitionId
                );
              } else {
                Swal.fire("error in creating slaDef record");
                console.log("error in creating slaDef record");
              }
              // this.setState({
              //   loader: false
              // });
            })
            .catch(function (error) {
              // console.log(error);

              swal(
                "You are not authorized to create/update SLA for other companies"
              );
              //window.location="/BreakFixEditError";
            });
        }
        //===================
      });
  }

  render() {
    let { handleSubmit, pristine, reset, submitting } = this.props;
    // console.log('&&&&&&&&&&&&', this.props, this.state);
    return (
      <Row className="margin-b-15">
        <Col md={7} sm={7} xs={12}>
          <h1 bsClass="" className="sPageHeading1">
            {this.props.translator["Target Definition"]}
          </h1>
        </Col>
        <Col md={5} sm={5} xs={12}>
          <div className="status myStatus ">
            <Nav as="ul">
              {this.state.showSubmit == false ? (
                <Nav.Item
                  as="li"
                  title={this.props.translator["Save"]}
                  onClick={handleSubmit(this.submit)}
                  eventKey={1}
                >
                  {this.state.loader ?
                  <a>
                    <span>
                        <ImSpinner6 className="icn-spinner"/>
                    </span>
                  </a> :
                  <Link title={this.props.translator["Save"]} to="javascript:void(0)" className="ctrlKeyPrevent" ref={e => this.saveObjRef = e}>
                    <IoSaveOutline/>
                  </Link>
                    }
                </Nav.Item>
              ) : (
                <Nav.Item as="li" eventKey={1} disabled={this.state.showSubmit}>
                  <Link title={this.props.translator["Save"]} to="javascript:void(0)" className="ctrlKeyPrevent" ref={e => this.saveObjRef = e}>
                    <IoSaveOutline/>
                  </Link>
                </Nav.Item>
              )}
              <Nav.Item as="li" eventKey={2}>
                <NavLink
                  to="/slaDeflist"
                  className="ctrlKeyPrevent"
                  title={this.props.translator["Close"]}
                  href={void(0)}
                  ref={e => this.closeButton = e}
                >
                  <IoClose/>
                </NavLink>
              </Nav.Item>
              {/*<Nav.Item title="Save" eventKey={1}><span className="sAction bgBtn15 save"></span></Nav.Item> */}
              {/*<NavLink to="/slaDeflist"><Nav.Item title="Close" eventKey={2}><span className="sAction bgBtn17 bclose"></span></Nav.Item></NavLink>*/}
            </Nav>
          </div>
          <div className="status myStatusGap border-r margin-r-10 padding-r-10">
            <Nav as="ul">
              <Nav.Item as="li" eventKey={4}>
                <a
                  title={this.props.translator["Process Workflow"]}
                  onClick={() => {
                    this.props.changeActionView("assetgraph");
                  }}
                >
                  <LuSearch/><LuBarChart className='processBarIcn'/>
                </a>
              </Nav.Item>
            </Nav>
          </div>
        </Col>
      </Row>
    );
  }
}

XsmSupportHeader = reduxForm({
  form: "XsmSlaDefForm",
  destroyOnUnmount: true,
})(XsmSupportHeader);

export default XsmSupportHeader;
