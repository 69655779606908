
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Button } from "react-bootstrap";

import {
  InputTextField,
  SelectField,
  DatePickerF,
  StandardTextArea,
} from "../../../../common/FormFields";
import CustomTypeHead from "../../../../common/CustomTypeHead";
import GridLayout from "../../../../common/GridLayout";
import {
  useGetActionMenuDdQuery,
  useCreateActionItemMutation,
} from "../../../../../../reducers/governance/meeting/actionItem";
import { useGetMeetingDefByIdQuery } from "../../../../../../reducers/governance/meeting/meeting";
import { replaceKey } from "../../../../Helper";
import swal from 'sweetalert';

const ActionAdd = ({
  meetingdefata,
  meetingDefData,
  companyID,
  userInfo,
  hideNotes,
  dateformat,
  occuranceDetails,
  activeTabs,
}) => {
  const meetingOccurrananceId = occuranceDetails?.meetingOccurrananceId;
  const meetingDefinationId = occuranceDetails?.meetingDefinationId;

  const [searchStr, setSearchStr] = useState("");
  const [foundUsers, setFoundUsers] = useState([]);
  const [opentray, setOpentray] = useState(false);

  const validationSchema = Yup.object().shape({
    additionalParams: Yup.string().required("Required"),
    expectedStartDate: Yup.string().required("Required"),
    assignedToId: Yup.string().required("Required"),
    description: Yup.string(),
  });

  const [initVals] = useState({
    description: "",
    additionalParams: "",
    impact: "",
    urgency: "",
    priority: "",
    priorityId: "",
    companyId: companyID,
    companyName: "",
    sequenceNumber: 0,
    expectedStartDate: "",
    expectedDueDate: "",
    actionOwner: [],
    performerList: [],
    assignedToGroupId: "",
    assignedToFullName: "",
    assignedTo: "",
    assignedToId: "",
    assignedToCompanyId: 0,
    assignedToCompany: 0,
  });

  const [createActionItem, { isLoading: addActionItemLoading }] =
    useCreateActionItemMutation();

  const { data: impactData, isFetching: impactDataLoading } =
    useGetActionMenuDdQuery("Impact");
  const { data: urgencyData, isFetching: urgencyDataLoading } =
    useGetActionMenuDdQuery("Urgency");
  const { data: priorityData, isFetching: priorityDataLoading } =
    useGetActionMenuDdQuery("Priority");

  const { data: editMeetingData, isLoading: editMeetingLoading } =
    useGetMeetingDefByIdQuery(
      { meetingId: meetingDefinationId },
      {
        skip: !meetingDefinationId,
      }
    );

  const onSubmit = async (payload, { setSubmitting }) => {
    payload.performerList = [
      {
        performerName: payload?.performerList?.[0]?.fullname,
        performerId: payload?.performerList?.[0]?.userId,
      },
    ];

    const postData = {
      ...payload,
      ...occuranceDetails,
      actionOwner: undefined,
      companyName: editMeetingData?.companyName,
      forumId: editMeetingData?.forumId,
      forum: editMeetingData?.forum,
      committeeId: editMeetingData?.committeeId,
      committee: editMeetingData?.committee,
      categoryId: editMeetingData?.categoryId,
      category: editMeetingData?.category,
      subTrack: editMeetingData?.subTrack,
      subtrack: editMeetingData?.subTrack,
      track: editMeetingData?.track,
      regionCode: editMeetingData?.region,
      regionValue: editMeetingData?.regionName,
      workItemId: meetingOccurrananceId,
      workItemType: "Meeting Occurrence",
      parentWorkItemId: editMeetingData?.meetingDefinationNumber,
      parentWorkItemName: editMeetingData?.title,
    };

    try {
      const resp = await createActionItem({ data: postData }).unwrap();
      if(resp){
      setSubmitting(false);
      hideNotes();
    }
    } catch (err) {
      setSubmitting(false);
      if (err.status == 400) {
        swal({
          text: err.data.message,
          button: 'OK',
      })
      }
    } finally {
      setSubmitting(false);
    }
  };

  function disabledDate(current) {
    return current && current.isBefore(moment().subtract(1, "day"));
  }

  const searchInput = (val, name) => {
    setFoundUsers([]);
    setSearchStr(val);
  };

  return (
    <Formik
      enableReinitialize={false}
      initialValues={initVals}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        handleBlur,
        values,
        handleChange,
        setValues,
        resetForm,
        dirty,
      }) => {
        return (
          <Form noValidate>
            <GridLayout cols={1}>
              <div className="mb-3">
                <StandardTextArea
                  characterLimit={200}
                  characterCount={true}
                  name="additionalParams"
                  label="Summary"
                  mandatory="true"
                  required
                  rows={2}
                />
              </div>
              <div className="mb-3">
                <StandardTextArea
                  characterCount={true}
                  name="description"
                  label="Description"
                  rows={2}
                />
              </div>
            </GridLayout>
            <GridLayout cols={2}>
              <SelectField
                name="impact"
                label="Risk"
                onChange={(e) => {
                  setFieldValue("impact", e.target.value);
                }}
              >
                {impactData &&
                  impactData.map((ln, i) => {
                    return (
                      <option key={`key-1${ln.value}`} value={ln.value}>
                        {ln.label}
                      </option>
                    );
                  })}
              </SelectField>
              <SelectField
                name="urgency"
                label="Urgency"
                onChange={(e) => {
                  setFieldValue("urgency", e.target.value);
                }}
              >
                {impactData &&
                  impactData.map((ln, i) => {
                    return (
                      <option key={`key-1${ln.value}`} value={ln.value}>
                        {ln.label}
                      </option>
                    );
                  })}
              </SelectField>
            </GridLayout>
            <GridLayout cols={2}>
              <SelectField
                name="priorityId"
                label="Priority"
                onChange={(e) => {
                  const priority = e.target.value;
                  const priorityName =
                    e.target.options[e.target.selectedIndex].getAttribute(
                      "data-name"
                    );
                    setFieldValue("priorityId", priority);
                  setFieldValue("priority", priority);
                }}
              >
                {priorityData &&
                  priorityData.map((ln, i) => {
                    return (
                      <option key={`key-1${ln.value}`} value={ln.value} data-name={ln.label}>
                        {ln.label}
                      </option>
                    );
                  })}
              </SelectField>
              <div>
                <DatePickerF
                  label="Expected By"
                  placeholder=""
                  name="expectedStartDate"
                  mandatory={"true"}
                  required
                  // value={
                  //   values.expectedStartDate
                  //     ? moment(values.expectedStartDate, _dateFormat)
                  //     : ""
                  // }
                  onChange={(value) => {
                    setFieldValue(
                      "expectedStartDate",
                      moment(value).format('MM-DD-YYYY HH:mm:ss')
                    );
                    setFieldValue("expectedDueDate", moment().format('MM-DD-YYYY HH:mm:ss'));
                  }}
                  size={"middle"}
                  disabledDate={(val) => disabledDate(val)}
                  style={{
                    width: "100%",
                  }}
                  // format={dateformat}
                  placement="bottomLeft"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  className="datepicker-action"
                />
              </div>
            </GridLayout>
            <GridLayout cols={1}>
              <CustomTypeHead
                required
                clearButton
                id="assignedToId"
                name="assignedToId"
                label="Action Owner"
                thfor="personApi"
                placeholder="Please type to search"
                companyId={companyID}
                autoComplete="off"
                labelkey={(option) =>
                  option?.fullname
                    ? `${option?.fullname} | ${option?.email}`
                    : "fullname"
                }
                onChange={(selected) => {
                  setFieldValue("actionOwner", selected, true);
                  setFieldValue("assignedTo", selected?.[0]?.fullname || "");
                  setFieldValue(
                    "assignedToId",
                    Number(selected?.[0]?.userId) || ""
                  );
                  setFieldValue(
                    "assignedToEmailAddress",
                    selected?.[0]?.email || ""
                  );
                  setFieldValue(
                    "assignedToFullName",
                    selected?.[0]?.fullname || ""
                  );
                  setFieldValue(
                    "assignedToCompanyId",
                    selected?.[0]?.companyId || ""
                  );
                  setFieldValue(
                    "assignedToCompany",
                    selected?.[0]?.companyName || ""
                  );
                }}
                selected={values?.actionOwner || []}
              />
            </GridLayout>
            <GridLayout cols={1}>
              <CustomTypeHead
                multiple
                clearButton
                id="performerlist"
                name="performerList"
                label="Action Performer"
                thfor="personApi"
                placeholder="Please type to search"
                companyId={companyID}
                autoComplete="off"
                labelkey={(option) =>
                  option?.fullname
                    ? `${option?.fullname} | ${option?.email}`
                    : "fullname"
                }
                onChange={(selected) => {
                  setFieldValue("performerList", selected, true);
                }}
                selected={values?.performerList || []}
              />
            </GridLayout>
            <div className="text-end custom-note-submit-btn">
              <Button
                type="submit"
                disabled={isSubmitting}
                title="Submit"
                className="rgSidrkBtn smallBtn mb-0"
              >
                Save
              </Button>
              &nbsp;
              <a className="btn  smallBtn" onClick={hideNotes}>
                Cancel
              </a>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ActionAdd;
