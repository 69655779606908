
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import { cx } from '../utils';
import React, {useState} from 'react';
import { Row, Col } from "react-bootstrap";
import { ExpansionPanel, ExpansionPanelContent } from '@progress/kendo-react-layout';
import { Reveal } from "@progress/kendo-react-animation";
import { Button } from '@progress/kendo-react-buttons';
import {IoClose} from "react-icons/io5";

export default function CustomBlockManager({ mapCategoryBlocks, dragStart, dragStop,setRightPanelShow}) {
    const [expanded, setExpanded] = useState(0);
    return (
      <div className="gjs-custom-block-manager text-left rightPanelComp">
        <div className="rPageHeading d-flex">
                <div>Components</div>
                <Button className='closeIcon ms-auto' onClick={()=>{setRightPanelShow(false)}} title='Minimize the details panel' fillMode="outline"><IoClose/></Button>
            </div>
            <div className='rightPanelGap'>
        {Array.from(mapCategoryBlocks).map(([category, blocks], index) => (
            <ExpansionPanel
                title={category || "Custom"} 
                key={index} 
                expanded={expanded===index} 
                onAction={(event) => {
                    setExpanded(event.expanded ? "" : index);
                }}
            >
                <Reveal>
                    {expanded === index && (
                        <ExpansionPanelContent>
                            <Row className='p-3'>
                                {blocks.map((block) => (
                                    <Col key={block.getId()}
                                        draggable
                                        className={cx('justify-content-center border rounded pe-auto py-1 transition-colors')}
                                        onDragStart={(ev) => dragStart(block, ev.nativeEvent)}
                                        onDragEnd={() => dragStop(false)}
                                        md={4}
                                    >
                                        <div
                                            className="mx-auto"
                                            style={{height: "40px", width: "40px"}}
                                            dangerouslySetInnerHTML={{ __html: block.getMedia() }}
                                        />
                                        <div className="text-center w-100" title={block.getLabel()}>
                                            {block.getLabel()}
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </ExpansionPanelContent>
                    )}
                </Reveal>
            </ExpansionPanel>
        ))}
        </div>
      </div>
    );
  }