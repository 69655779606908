
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ListLoader from '_Commons/loaders/ListLoader';
import { loadCategoryList } from '_Actions/categoryBoard/quickViewAction';
import TableComponent from '_Commons/ReactTable/TableComponent';

class CategoryBoardList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.onRowSelect = this.onRowSelect.bind(this);
        this.refreshCategoryBoardTable = this.refreshCategoryBoardTable.bind(this);
    }
     tableColumns = [
        {
          name: this.props.translator['Company'],
          selector: (row) => row.COMAPNY_NAME,
          sortable: true,
        },
        {
          name: this.props.translator["Category"],
          selector: (row) => row.CATEGORY_NAME,
          sortable: true,
        },
        {
          name: this.props.translator["Sub Category"],
          selector: (row) => row.SUB_CATEGORY_NAME,
          sortable: true,
        },
        {
          name: this.props.translator["Business Function"],
          selector: (row) => row.BUSINESS_FUNCTION_NAME,
          sortable: true,
        },
        {
          name: this.props.translator["Status"],
          selector: (row) => row.STATUS_NAME,
          sortable: true,
        },
      ];
       filterColumns = ["COMAPNY_NAME", "CATEGORY_NAME", "SUB_CATEGORY_NAME", "STATUS_NAME", "BUSINESS_FUNCTION_NAME"];

    refreshCategoryBoardTable() {
        this.props.loadCategoryLionRowSelectparamsst();
    }
     
    onRowSelect(row, isSelected, e, rowIndex) {
        let rowStr = '';
        for (const prop in row) {
            if (row.hasOwnProperty(prop)) {
            rowStr += prop + ': "' + row[prop] + '"';
        }
    }
        this.props.getCategoryBoardDetails(
            row.CATEGORY_ID,
            row.CATEGORY_NAME,
            row.SUB_CATEGORY_NAME,
            row.STATUS_NAME,
            row.CATEGORY_CODE,
            row.CATEGORY_PINNED,
            row.BUSINESS_FUNCTION_NAME
        );
        this.props.showRightSide(true);
    }
    renderCategoryTableList = (categoryData) => {
        return (
          <div className="dtaTableDv integrateHubTable">
            <div className={this.props.rightDisplay ? "leftTrue catBorSrch" : "rightTrue catBorSrch"}>
              <TableComponent
                data={categoryData}
                columns={this.tableColumns}
                headerColumns={this.filterColumns}
                onRowSelect={this.onRowSelect}
                rightDisplay={this.props.rightDisplay}
                uniqueKey={'CATEGORY_ID'}
              />
            </div>
         </div>
        );
      };
    render() {
        if (this.props.categoryBoardList.length == undefined) {
            return (
                <ListLoader />
            );
        } else if (this.props.categoryBoardList.length == 0) {
            return (
                <div>
                    <font>{this.props.translator['There is no matching data available']}</font>
                </div>
            );
        } else {
            return (
                <div>
                    {this.props.showLoader ? <ListLoader /> : this.renderCategoryTableList(this.props.categoryBoardList)}
                </div>
            );
        }
    }
}

export function mapStateToProps({ categoryBoardList, showLoader }) {
    return { categoryBoardList, showLoader: showLoader.loading };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ loadCategoryList }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryBoardList);



