
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import { gql } from "@apollo/client";

export const getApprovalSets = gql`
    query getApprovalSets($bfId: ID!, $compId: ID!, $modId: ID!, $status: ID!) {
        getApprovalSetByParam(businessFunctionId: $bfId, company_Id: $compId, module_id: $modId, status: $status) {
            setId
            approvalSetName
        }
    }
`;