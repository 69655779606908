
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ListLoader from '../common/loaders/ListLoader';
import { mailboxImpact, mailboxUrgency } from '../../utils/common';
import { getSystemConfigList, setSystemConfigData } from "../../actions/systemConfig/systemConfigAction";
import {IoClose} from "react-icons/io5";
import { PiPencilSimpleBold } from 'react-icons/pi';
import { MyContext } from '_MyContext';
const SystemConfigShortDescription = (props) => {
    const dispatch = useDispatch();

    const showLoader = useSelector(state => state.showLoader.loading);
    const translator = useSelector(state => state.spcmReducer.tr)

    const navigate = useNavigate();
    const context = useContext(MyContext);
    const closeButton = useRef(null);
    const editButton = useRef(null);

    useEffect(() => {
        context.keyDownHandler(closeButton.current,editButton.current,"","");
        context.newTabPrevent();
    }, []);
    
    return (
        <div className="margin-b-20">
            <div className="rPageHeadActBtn margin-r-5" style={{marginTop:"-3px"}}>
                <ul>
                    <li><Link to={"/editSystemConfig/" + props.systemConfigData.UUID} title={translator['Edit']} onClick={() => { dispatch(setSystemConfigData(props.systemConfigData));}} ref={editButton} className="ctrlKeyPrevent" ><PiPencilSimpleBold/></Link></li>
                    <li>
                        <Link to="javascript:void(0)" title={translator["Minimize the details panel"]} bsClass="" className="ctrlKeyPrevent" onClick={() => { props.showRightSide(false); }} ref={closeButton} >
                            <IoClose />
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="rPageHeading">
                <div className='offNam margin-t-5 margin-b-5'>{translator['Mailbox Name']}</div>
            </div>
            <div className="rBoxGap brdrTopcolor">
                <ListGroup variant='flush' bsClass="" className="">
                    <ListGroup.Item bsClass="" className="rwTable" style={{ borderTop: "none" }}><span className="rwKey">{translator['Default Impact']} </span> <span className="rwVal">{props.systemConfigData.IMPACT_NAME}</span></ListGroup.Item>
                    {/* <ListGroup.Item bsClass="" className="rwTable" style={{ borderTop: "none" }}><span className="rwKey">{translator['Default Impact']} </span> <span className="rwVal">{imapctVar}</span></ListGroup.Item> */}
                    <ListGroup.Item bsClass="" className="rwTable"><span className="rwKey">{translator['Default Urgency']} </span> <span className="rwVal">{props.systemConfigData.URGENCY_NAME}</span></ListGroup.Item>
                    <ListGroup.Item bsClass="" className="rwTable"><span className="rwKey">{translator['User Verification Type']} </span> <span className="rwVal">{props.systemConfigData.ACTIONS}</span></ListGroup.Item>
                </ListGroup>
            </div>
        </div>
    );

}

export default SystemConfigShortDescription

