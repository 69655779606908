
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Row, Col, PageHeader, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadCategoryList } from '_Actions/categoryBoard/quickViewAction';
import Axios from 'axios';
import { GLOBAL } from "_Globals";
import { object } from 'joi';
import { navigationHooks } from '../../helpers/NavigationHook';
import {HiPlus, HiOutlineRefresh} from "react-icons/hi";
const api = Axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});
class CategoryBoardHeader extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isCiCategoryBoardVisible : false,
			isCategoryBoardVisible : false,
			isCategoryActive : "",
			isServiceActive : "active",
            isResolutionCategoryBoardVisible : false,
            isResolutionActive : ""
		};
	    this.refreshCategoryBoardTable = this.refreshCategoryBoardTable.bind(this);
		this.handleActive = this.handleActive.bind(this);
        
	}
    refreshCategoryBoardTable() {
		this.props.loadCategoryList();
	}
    componentWillReceiveProps(nextProps) {
        console.log('Neww-- 1', nextProps)
    }
        componentWillMount(){
            let roleArray =  this.props.roleArray.split(',');//'34', '33', '39', '10', '32']
            console.log("roleArray===============");
            console.log(roleArray);
             if(roleArray.includes('37') || roleArray.includes('38')){
                 this.setState({ isCiCategoryBoardVisible: true });
                 this.setState({ isResolutionCategoryBoardVisible: true });
             }
             if(roleArray.includes('5')){
                 this.setState({ isCategoryBoardVisible: true});
             }
             if (GLOBAL.activeCategory == 'service') {
                 this.setState(
                     {
                         isServiceActive: "active",
                         isCategoryActive: "",
                         isResolutionActive: ""
                     });
             } else if (GLOBAL.activeCategory == 'category') {
                 this.setState(
                     {
                         isServiceActive: "",
                         isCategoryActive: "active",
                         isResolutionActive: ""
                     });
             } else if (GLOBAL.activeCategory == 'resolution') {
                 this.setState(
                     {
                         isServiceActive: "",
                         isCategoryActive: "",
                         isResolutionActive: "active"
                     });
             }
         }
	
    handleActive(clickedTab) {
				
        if (clickedTab == 'service') {
            this.setState({ isServiceActive: 'active', isCategoryActive: '', isResolutionActive: '' });
            this.props.handleTab(clickedTab);
        } else if (clickedTab == 'category') {
            this.setState({ isServiceActive: '', isCategoryActive: 'active', isResolutionActive: '' });
            this.props.handleTab(clickedTab);
        } else if (clickedTab == 'resolution') {
            this.setState({ isServiceActive: '', isCategoryActive: '', isResolutionActive: 'active' });
            this.props.handleTab(clickedTab);
        }
      }
    render() {
		console.log('isResolutionCategoryBoardVisible=======');
		console.log(this.state.isResolutionCategoryBoardVisible);
		console.log('isCiCategoryBoardVisible=======');
		console.log(this.state.isCiCategoryBoardVisible);
		return (<Row className="margin-b-15" style={{zIndex:1,position:"relative"}}>
        <Col lg={8} md={12} sm={12} xs={12}>
             <ul className="wibTabs">
                {this.state.isCategoryBoardVisible && <li onClick= {() => {this.handleActive('service'); }} className={this.props.activeTab === 'category' ? '' : this.state.isServiceActive}>
                <Link title={this.props.translator['Service Category']} to="/categoryBoard">{this.props.translator['Service Category']}</Link>
                </li>}
                
                {this.state.isCiCategoryBoardVisible && <li onClick= {() => {this.handleActive('category'); }} className={this.props.activeTab === 'category' ? 'active' : this.state.isCategoryActive}>
                    <Link title={this.props.translator['CI Category']} to="/categoryBoard">{this.props.translator['CI Category']}</Link>
                </li>}

            {this.state.isResolutionCategoryBoardVisible && <li onClick= {() => {this.handleActive('resolution'); }} className={this.props.activeTab === 'resolution' ? 'active' : this.state.isResolutionActive}>
                <Link title={this.props.translator['CI Resolution Category']} to="/categoryBoard">{this.props.translator['CI Resolution Category']}</Link>
            </li>}
            </ul> 
        </Col>
        <Col lg={4} md={12} sm={12} xs={12}>
            <div className="paHedFilter">
                {(this.state.isServiceActive=='active' && this.props.activeTab === 'service') && <Link bsPrefix=' ' role="button" className="myBt plus fillBtn" title={this.props.translator['Create new Category']} to="/createCategory" ><HiPlus/></Link>}
                
                {(this.state.isServiceActive=='active' && this.props.activeTab === 'service') && <Button bsPrefix=' ' title={this.props.translator["Refresh"]} role="button" bsStyle="" bsClass="" className="myBt" onClick={() => { this.refreshCategoryBoardTable(); }}><HiOutlineRefresh/></Button>}

                {(this.state.isCategoryActive=='active' || this.props.activeTab === 'category') && <Link bsPrefix=' ' role="button" className="myBt plus fillBtn" title={this.props.translator['Create new Category']} to="/newCiCategory" ><HiPlus/></Link>}

                {(this.state.isResolutionActive=='active' || this.props.activeTab === 'resolution') && <Link bsPrefix=' ' role="button" className="myBt plus fillBtn" title={this.props.translator['Create new Category']} to="/newCiResolutionCategory" ><HiPlus/></Link>}
            </div>
        </Col>
    </Row>);
    }
}
export function mapStateToProps(state) {
    return {
      translator: state.spcmReducer.tr
    };
  }
  
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ loadCategoryList }, dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(CategoryBoardHeader);