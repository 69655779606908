
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Button, Input } from "react-bootstrap";

import TranslationsList from "./translations/list.js";
import ReviewTranslations from "./review.js";
import { connect } from "react-redux";
import {
  spcmLoader,
  languageListTranslationStatus,
  resetReducer,
} from "../../../actions/spcmActions";
import _ from "lodash";
import ListLoader from "_Commons/loaders/ListLoader";
import CloseBtnRightPanel from "../../common/closeBtnRightPanel.js";

class LanguageActivation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lanNameVal: "",
      lanIdVal: "",
      fieldNameVal: "",
    };
    this.lanNameIdFun = this.lanNameIdFun.bind(this);
    this.fieldNameFun = this.fieldNameFun.bind(this);
  }
  lanNameIdFun(val, id, langCode) {
    this.setState({
      lanNameVal: val,
      lanIdVal: id,
      lanCode: langCode,
    });
  }
  fieldNameFun(val) {
    this.setState({ fieldNameVal: val });
  }

  componentDidMount() {
    const baseLanguage = this.props.spcmReducer.currentlySelectedServiceDetails
      .BASE_LANGUAGE;
    this.props.languageListTranslationStatus(
      this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
      baseLanguage !== null ? baseLanguage : "English"
    );
  }

  componentWillUnmount() {
    this.props.resetReducer();
  }

  render() {
    console.log("spcmReducer");
    console.log(this.props.spcmReducer);
    console.log(this.props.spcmReducer.currentlySelectedServiceDetails);
    console.log(this.props.spcmReducer.spcmFetchingAttribute);

    const languagesForTranslation = this.props.spcmReducer
      .languageListTranslationStatus;
    console.log("languagesForTranslation");
    console.log(languagesForTranslation);
    const tr = this.props.tr;

    return (
      <div>
        {this.props.isLangActSecOpen3 ? (
          <TranslationsList
            tr={tr}
            whichInsideFormOpen4={this.props.whichInsideFormOpen3}
            insideTableShowFun4={this.props.insideTableShowFun3}
            isInsideTableShow4={this.props.isInsideTableShow3}
            isPageEdit4={this.props.isPageEdit3}
            isLanEditPageOpen4={this.props.isLanEditPageOpen3}
            lanEditPageFun4={this.props.lanEditPageFun3}
            fieldNameVal1={this.state.fieldNameVal}
            fieldNameFun1={this.fieldNameFun}
            lanNameVal1={this.state.lanNameVal}
            lanIdVal1={this.state.lanIdVal}
            languageCode={this.state.lanCode}
            langActSecFun4={this.props.langActSecFun3}
          />
        ) : (
            <div>
              {this.props.isReviewOpen3 ? (
                <ReviewTranslations
                  tr={tr}
                  reviewShowFun4={this.props.reviewShowFun3}
                  lanNameVal1={this.state.lanNameVal}
                  lanIdVal1={this.state.lanIdVal}
                  languageCode={this.state.lanCode}
                />
              ) : (
                  <div>
                        <div>
                          <CloseBtnRightPanel showRightSide={this.props.showRightSide}/>
                          <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">Language Activation Status</div></div>
                          <div className="rBoxGap">
                          {this.props.spcmReducer.spcmFetchingAttribute ? <ListLoader /> : <div>
                          {languagesForTranslation.map((language) => {
                            return (
                              <div className="lanActBox">
                                <div className="lanChild lanInput">
                                  <input
                                    defaultChecked={true}
                                    type="checkbox"
                                    label="Checkbox"
                                  />
                                </div>
                                <div className="lanChild lanName">
                                  <h2>{language.languageName}</h2>
                                  <p
                                    className={
                                      "translation" +
                                      language.offeringTranslationStatus
                                    }
                                  >
                                    Translations -{" "}
                                    {language.offeringTranslationStatus}
                                  </p>
                                </div>
                                {language.offeringTranslationStatus === "All" ? (
                                  <div className="lanChild lanStat">
                                    {language.publish === "false"
                                      ? "Review Pending"
                                      : "Reviewed"}
                                  </div>
                                ) : null}

                                <div className="lanChild lanBtns">
                                  {language.offeringTranslationStatus === "All" ? (
                                    <Button
                                      onClick={() => {
                                        this.lanNameIdFun(
                                          language.languageName,
                                          language.id,
                                          language.languageCode
                                        );
                                        this.props.reviewShowFun3(true);
                                      }}
                                      bsStyle="primary"
                                      className="lanReview"
                                    >
                                      <i className="fa fa-eye" aria-hidden="true"></i>
                                    </Button>
                                  ) : null}
                                  <Button
                                    onClick={() => {
                                      this.lanNameIdFun(
                                        language.languageName,
                                        language.id,
                                        language.languageCode
                                      );
                                      this.props.langActSecFun3(true);
                                    }}
                                    className="lanEdit"
                                  >
                                    <i className="fa fa-wrench"></i>
                                  </Button>
                                </div>
                              </div>
                            );
                          })}
                          </div>
                          }
                          </div>
                          {/* <div className="lanActBox">
									<div className="lanChild lanInput"><input defaultChecked={true} type="checkbox" label="Checkbox" /></div>
									<div className="lanChild lanName"><h2>Frenchhs</h2><p style={{ backgroundColor: "#ffc000", color: "#000" }}>Translations - Partial</p></div>
									<div className="lanChild lanStat">Review Pending</div>
									<div className="lanChild lanBtns"><Button onClick={() => { this.lanNameIdFun('French'); this.props.langActSecFun3(true) }} className="lanEdit"><i className="fa fa-wrench"></i></Button></div>
								</div>
								<div className="lanActBox">
									<div className="lanChild lanInput"><input defaultChecked={true} type="checkbox" label="Checkbox" /></div>
									<div className="lanChild lanName"><h2>German</h2><p style={{ backgroundColor: "#ff0000", color: "#fff" }}>Translations - Nill</p></div>
									<div className="lanChild lanStat">Review Pending</div>
									<div className="lanChild lanBtns"><Button onClick={() => { this.lanNameIdFun('German'); this.props.langActSecFun3(true) }} className="lanEdit"><i className="fa fa-wrench"></i></Button></div>
								</div>
								<div className="lanActBox">
									<div className="lanChild lanInput"><input defaultChecked={true} type="checkbox" label="Checkbox" /></div>
									<div className="lanChild lanName"><h2>Spanish</h2><p style={{ backgroundColor: "#92d050", color: "#000" }}>Translations - All</p></div>
									<div className="lanChild lanStat">Review Pending</div>
									<div className="lanChild lanBtns"><Button onClick={() => { this.lanNameIdFun('Spanish'); this.props.reviewShowFun3(true) }} bsStyle="primary" className="lanReview"><i className="fa fa-eye" aria-hidden="true"></i></Button><Button className="lanEdit"><i className="fa fa-wrench"></i></Button></div>
								</div>
								<div className="lanActBox">
									<div className="lanChild lanInput"><input defaultChecked={false} type="checkbox" label="Checkbox" /></div>
									<div className="lanChild lanName"><h2>Portuguese</h2><p></p></div>
									<div className="lanChild lanStat"></div>
									<div className="lanChild lanBtns"></div>
								</div>
								<div className="lanActBox">
									<div className="lanChild lanInput"><input defaultChecked={true} type="checkbox" label="Checkbox" /></div>
									<div className="lanChild lanName"><h2>Simple Chinese</h2><p style={{ backgroundColor: "#92d050", color: "#000" }}>Translations - All</p></div>
									<div className="lanChild lanStat">Review Pending</div>
									<div className="lanChild lanBtns"><Button bsStyle="primary" className="lanReview"><i className="fa fa-eye" aria-hidden="true"></i></Button><Button className="lanEdit"><i className="fa fa-wrench"></i></Button></div>
								</div> */}
                        </div>
                  </div>
                )}
            </div>
          )}
      </div>
    );
  }
}

const mapStateToProps = ({ spcmReducer }) => {
  return {
    spcmReducer,
  };
};

export default connect(mapStateToProps, {
  languageListTranslationStatus,
  resetReducer,
})(LanguageActivation);
