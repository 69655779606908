
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import { GLOBAL } from '../../components/Globals';
import constanturl from '../../../src/utils/constants.js';

export function loadBreakFixSGAEChangeSuccess(breakFixSGAEChangeName){
	return {
		type: 'LOAD_BREAKFIXSGAECHANGE_SUCCESS',
		breakFixSGAEChangeName
	};
}
export function loadBreakFixSGAESuccess(breakFixSGAEName) {
	return {
		type: 'LOAD_BREAKFIXSGAE_SUCCESS',
		breakFixSGAEName
	};
}
export function loadBreakFixSGAESuccessForCIM(breakFixSGAEName) {
	return {
		type: 'LOAD_BREAKFIXSGAE_SUCCESS_CIM',
		breakFixSGAEName
	};
}
export function loadBreakFixSGAEChangegroupSuccess(breakFixSGAEChangegroup)
{
	console.log("dtatatata",breakFixSGAEChangegroup);
	return{
		type:'LOAD_BREAKFIXSGAEGROUPCHANGE_SUCCESS',
		breakFixSGAEChangegroup
	};
}
export function loadBreakFixSGAEFailure(errorResponse) {
	return {
		type: 'LOAD_BREAKFIXSGAE_FAILURE',
		errorResponse
	};
}

export function loadBreakFixSGAEEditSuccess(breakFixSGAEEditName) {
	return {
		type: 'LOAD_BREAKFIXSGAEEDIT_SUCCESS',
		breakFixSGAEEditName
	};
}

export function loadBreakFixSGAEEditFailure(errorResponse) {
	return {
		type: 'LOAD_BREAKFIXSGAEEDIT_FAILURE',
		errorResponse
	};
}

export function loadBreakFixSGAETaskSuccess(breakFixSGAETaskName) {
	return {
		type: 'LOAD_BREAKFIXSGAETASK_SUCCESS',
		breakFixSGAETaskName
	};
}

export function loadBreakFixSGAETaskFailure(errorResponse) {
	return {
		type: 'LOAD_BREAKFIXSGAETASK_FAILURE',
		errorResponse
	};
}
export function loadPropseChangeSGAE(serviceId,companyName,mod,service)
{
	let url="";
	console.log("loadPropseCHNAGESGAE");
	if(mod=="ChangeManagement"){
		//if(service=="impactedCI")
		 url="/rest/ruleConfiguration?companyId="+companyName+"&moduleId=10&eventId=25&ciId="+serviceId;
		//  else
		//  url="/rest/ruleConfiguration?companyId="+companyName+"&moduleId=10&eventId=25&serviceId="+serviceId;
	}
	return (dispatch) => {
		let modulecheck="";
		if (serviceId === "") {
			console.log("serviceId not found");
		} else {
			//alert(GLOBAL.breakFixSGroupUrl+serviceId)
			axios.get(url)
				.then((breakFixSGAEName) => {
					if (!breakFixSGAEName) {
						throw Error(breakFixSGAEName.statusText);
					}
					if(breakFixSGAEName.data!=""){
					let filteredbreakFixSGAEName =
					 breakFixSGAEName.data.filter((item,i)=>{
						return item.statusValue =="Active" ? true : false;
					});
					console.log("breakFixSGAEName.........",filteredbreakFixSGAEName);
					modulecheck=filteredbreakFixSGAEName[0].moduleName;
					return filteredbreakFixSGAEName;
				}
				else
				{
					return(breakFixSGAEName.data);
				}
				})
				.then((filteredbreakFixSGAEName) => {
						dispatch(loadBreakFixSGAEChangegroupSuccess(filteredbreakFixSGAEName));	
				})
				.catch((err) => {
					console.log(err);
				});
		}

	};
}
export function clearallCIMgroups(){
	return (dispatch) => {
		let cimGroups=[];
        dispatch({type: 'LOAD_ALL_CIM_GROUPS',cimGroups});
	};
}
export function loadallCIMgroups(companyId,moduleId,eventId){
  let url=GLOBAL.getallCIMgroups+"?companyId="+companyId+"&moduleId="+moduleId+"&eventId="+eventId;
  return (dispatch) => {
	axios.get(url).then((resultedGroups) => {  
		  let cimGroups = [];
		  resultedGroups.data.map((item) => {
			   cimGroups.push({label: item.assignmentGroupName, value: item.assignmentGroupId+'' });
		  });
		  dispatch({type: 'LOAD_ALL_CIM_GROUPS', cimGroups});
	});
  }
}

export function loadallCIMgroupsRules(companyId, moduleName, ruleFor) {
  let url = GLOBAL.getCIMGroupListForIncident;

  const headers = {
    Query: JSON.stringify({
      moduleName,
      companyId,
      ruleFor,
    }),
  };

  return (dispatch) => {
    axios
      .get(url, { headers })
      .then((response) => {
        const cimGroupsRules = response.data.body.map(item => {
        	return({
        		label: item?.responseEntity,
        		value: `${item?.responseEntityId}`
        })});
        dispatch({ type: "LOAD_ALL_CIM_GROUPS_RULES", cimGroupsRules });
      })
      .catch((error) => {
        console.error("Failed to load CIM groups rules", error);
      });
  };
}

export function loadBreakFixSGAE(serviceId,companyName,mod,service){
let url="";
if(mod=="Breakfix")
{
	if(service=="impactedCI")
     url="/rest/ruleConfiguration?companyId="+companyName+"&moduleId=5&eventId=10&ciId="+serviceId;
	 else
     url="/rest/ruleConfiguration?companyId="+companyName+"&moduleId=5&eventId=10&serviceId="+serviceId;
}
if(mod=="Task")
//url="/rest/ruleConfiguration?companyId="+companyName+"-tms&moduleName=Task&eventName=Edit Task&serviceId="+serviceId;
url="/rest/ruleConfiguration?companyId="+companyName+"&moduleId=5&eventId=40&serviceId="+serviceId;
if(mod=="Investigation")
if(service=="impactedCI")
url="/rest/ruleConfiguration?companyId="+companyName+"&moduleId=15&eventId=20&ciId="+serviceId;
else
url="/rest/ruleConfiguration?companyId="+companyName+"&moduleId=15&eventId=20&serviceId="+serviceId;
if(mod=="ChangeManagement"){
    if(service=="impactedCI")
     url="/rest/ruleConfiguration?companyId="+companyName+"&moduleId=10&eventId=25&ciId="+serviceId;
	 else
     url="/rest/ruleConfiguration?companyId="+companyName+"&moduleId=10&eventId=25&serviceId="+serviceId;
}
if(mod=="CIM")
if(service=="impactedCI")
url="/rest/ruleConfiguration?companyId="+companyName+"&moduleId=5&eventId=15&ciId="+serviceId;
else
url="/rest/ruleConfiguration?companyId="+companyName+"&moduleId=5&eventId=15&serviceId="+serviceId;
if(mod=="EditBreakfix")
url="/rest/ruleConfiguration?companyId="+companyName+"&moduleId=5&eventName=10&serviceId="+serviceId;

	return (dispatch,getState) => {
		let modulecheck="";
		if (serviceId === "") {
			console.log("serviceId not found");
		} else {
			//alert(GLOBAL.breakFixSGroupUrl+serviceId)
			axios.get(url)
				.then((breakFixSGAEName) => {
					console.log("breakFixSGAEName.........",breakFixSGAEName);
					if (!breakFixSGAEName) {
						throw Error(breakFixSGAEName.statusText);
					}
					if(breakFixSGAEName.data!=""){
					let filteredbreakFixSGAEName =
					 breakFixSGAEName.data.filter((item,i)=>{
						return item.statusValue =="Active" ? true : false;
					});
					console.log("breakFixSGAEName.........",filteredbreakFixSGAEName);
					modulecheck=filteredbreakFixSGAEName[0].moduleName;
					return filteredbreakFixSGAEName;
				}
				else
				{
					return(breakFixSGAEName.data);
				}
				})
				.then((filteredbreakFixSGAEName) => {
					console.log("filter grppp",modulecheck);
					// if(modulecheck=="ChangeManagement")
					// {
					// 	dispatch(loadBreakFixSGAEChangegroupSuccess(filteredbreakFixSGAEName));	
					// }
					if(GLOBAL.changepageLoad===true){
						let typeaheadData=[];//,currentEditdetails=getState().editChangeInitialData,flag=false;
						if(filteredbreakFixSGAEName!==''){
							filteredbreakFixSGAEName.map((item)=>{
                                   typeaheadData.push({'value':''+item.assignmentGroupId,'label':item.assignmentGroupName, 'isExternal':item.isExternal, 'supportCompanyID': item.assignmentCompanyId, 'supportCompanyName': item.assignmentCompanyName});
								   //if(typeof currentEditdetails.implementationGroup!=='undefined'){
									// if(item.assignmentGroupId+''===currentEditdetails.implementationGroup+''){
									//   flag=true;
									// }
								  //}
							});
						}
						// if(GLOBAL.changeEdit===true && flag===false && typeof currentEditdetails.implementationGroup!=='undefined'){
						// if(currentEditdetails.implementationGroup!=='0' && currentEditdetails.implementationGroup!=='' && currentEditdetails.impGroupname!=='' && currentEditdetails.implementationGroup!==0){
						// 	typeaheadData.push({'value':currentEditdetails.implementationGroup,'label':currentEditdetails.impGroupname});
						// }}
						dispatch({type:'GROUP_LIST_TYPE_AHEAD_INITIAL',typeaheadData});
					}
	
					dispatch(loadBreakFixSGAESuccess(filteredbreakFixSGAEName));
					
				})
				.catch((err) => {
					console.log(err);
				});
		}

	};
}

export function loadNewBreakFixSGAEWithQueryParams(serviceId,companyName,mod,service,amsAttribute={}, serviceCompanyId){
  amsAttribute=JSON.stringify(amsAttribute);
  let url="";
  if(mod=="Breakfix")
  {
	url="/rest/getRuleConfiguration?companyId="+companyName+"&moduleId=5&eventId=10&serviceCompanyId="+serviceCompanyId;
  }
  if(mod=="Fulfillment")
{
	url="/rest/getRuleConfiguration?companyId="+companyName+"&moduleId=25&eventId=35&serviceCompanyId="+serviceCompanyId;
}
  if(mod=="Task")
    url="/rest/getRuleConfiguration?companyId="+companyName+"&moduleId=20&eventId=40&serviceCompanyId="+serviceCompanyId;
  if(mod=="Investigation")
  	url="/rest/getRuleConfiguration?companyId="+companyName+"&moduleId=15&eventId=20&serviceCompanyId="+serviceCompanyId;
  if(mod=="ChangeManagement"){
	url="/rest/getRuleConfiguration?companyId="+companyName+"&moduleId=10&eventId=25&serviceCompanyId="+serviceCompanyId;
   }
   if(mod=="CIM")
   	url="/rest/getRuleConfiguration?companyId="+companyName+"&moduleId=5&eventId=15&serviceCompanyId="+serviceCompanyId;
   if(mod=="EditBreakfix")
       url="/rest/getRuleConfiguration?companyId="+companyName+"&moduleId=5&eventName=10&serviceCompanyId="+serviceCompanyId;
    return (dispatch) => {
		if(serviceId === ""){
			console.log("serviceId not found");
		}else{
	    axios.get(url,{ headers: { 'query': amsAttribute }}).then((responseData) => {
			   let filteredbreakFixSGAEName=[];
			   if(responseData.data!==''){
                 filteredbreakFixSGAEName = responseData.data.filter((item,i)=>{
						return item.statusValue =="Active" ? true : false;
			     });
			   }
			   return filteredbreakFixSGAEName;
	    }).then((filteredbreakFixSGAEName)=>{
			   if(GLOBAL.changepageLoad===true||GLOBAL.fulfillmentPageLoad === true){
				   let typeaheadData=[];
			       if(filteredbreakFixSGAEName!==''){
					  filteredbreakFixSGAEName.map((item)=>{
						   typeaheadData.push({'value':''+item.assignmentGroupId,'label':item.assignmentGroupName, 'isExternal':item.isExternal, 'supportCompanyID': item.assignmentCompanyId, 'supportCompanyName': item.assignmentCompanyName});
					  });
				   }
				   dispatch({type:'GROUP_LIST_TYPE_AHEAD_INITIAL',typeaheadData});
			    }     
				if(mod==="CIM"){
					dispatch(loadBreakFixSGAESuccessForCIM(filteredbreakFixSGAEName));
				}else{     
				    dispatch(loadBreakFixSGAESuccess(filteredbreakFixSGAEName));
				} 	
				})
				.catch((err) => {
					console.log(err);
				});
		}
		
    };
}
export function loadNewBreakFixSGAE(serviceId,companyName,mod,service,amsAttribute={}){
  amsAttribute=JSON.stringify(amsAttribute);
  let url="";
  if(mod=="Breakfix")
  {
	if(service=="impactedCI")
     url="/rest/getRuleConfiguration?companyId="+companyName+"&moduleId=5&eventId=10";
  }
  if(mod=="Fulfillment")
{
	url="/rest/getRuleConfiguration?companyId="+companyName+"&moduleId=25&eventId=35";
}
  if(mod=="Task")
    url="/rest/getRuleConfiguration?companyId="+companyName+"&moduleId=20&eventId=40";
  if(mod=="Investigation")
   if(service=="impactedCI")
    url="/rest/getRuleConfiguration?companyId="+companyName+"&moduleId=15&eventId=20";
   else
    url="/rest/getRuleConfiguration?companyId="+companyName+"&moduleId=15&eventId=20";
  if(mod=="ChangeManagement"){
     if(service=="impactedCI")
      url="/rest/getRuleConfiguration?companyId="+companyName+"&moduleId=10&eventId=25";
   }
   if(mod=="CIM")
    if(service=="impactedCI")
       url="/rest/getRuleConfiguration?companyId="+companyName+"&moduleId=5&eventId=15";
    else
       url="/rest/getRuleConfiguration?companyId="+companyName+"&moduleId=5&eventId=15";
   if(mod=="EditBreakfix")
       url="/rest/getRuleConfiguration?companyId="+companyName+"&moduleId=5&eventName=10";
    return (dispatch) => {
		if(serviceId === ""){
			console.log("serviceId not found");
		}else{
	    axios.get(url,{ headers: { 'query': amsAttribute }}).then((responseData) => {
			   let filteredbreakFixSGAEName=[];
			   if(responseData.data!==''){
                 filteredbreakFixSGAEName = responseData.data.filter((item,i)=>{
						return item.statusValue =="Active" ? true : false;
			     });
			   }
			   return filteredbreakFixSGAEName;
	    }).then((filteredbreakFixSGAEName)=>{
			   if(GLOBAL.changepageLoad===true||GLOBAL.fulfillmentPageLoad === true){
				   let typeaheadData=[];
			       if(filteredbreakFixSGAEName!==''){
					  filteredbreakFixSGAEName.map((item)=>{
						   typeaheadData.push({'value':''+item.assignmentGroupId,'label':item.assignmentGroupName, 'isExternal':item.isExternal, 'supportCompanyID': item.assignmentCompanyId, 'supportCompanyName': item.assignmentCompanyName});
					});
				   }
				   dispatch({type:'GROUP_LIST_TYPE_AHEAD_INITIAL',typeaheadData});
			    }     
				if(mod==="CIM"){
					dispatch(loadBreakFixSGAESuccessForCIM(filteredbreakFixSGAEName));
				}else{     
				    dispatch(loadBreakFixSGAESuccess(filteredbreakFixSGAEName));
				} 	
				})
				.catch((err) => {
					console.log(err);
				});
		}
		
    };
}
export function loadBreakFixSGAEEdit(serviceId,companyName,mod,serviceBased){
let url="";
if(mod=="Breakfix")
url="/rest/ruleConfiguration?companyId="+companyName+"&moduleId=5&eventId=10&serviceId="+serviceId;
if(mod=="Fulfillment")
{
	url="/rest/ruleConfiguration?companyId="+companyName+"&moduleId=25&eventId=35&serviceId="+serviceId;
}
if(mod=="EditTask" && serviceId!=undefined){
    if(serviceBased==true || serviceBased=="true"){
            url="/rest/ruleConfiguration?companyId="+companyName+"&moduleId=20&eventId=40&serviceId="+serviceId;
    } else {
            url="/rest/ruleConfiguration?companyId="+companyName+"&moduleId=20&eventId=40&ciId="+serviceId;
    }
}
if(mod=="Task")
//url="/rest/ruleConfiguration?companyId="+companyName+"-tms&moduleName=Task&eventName=Edit Task&serviceId="+serviceId;
url="/rest/ruleConfiguration?companyId="+companyName+"&moduleId=5&eventId=40&serviceId="+serviceId;
if(mod=="Investigation")
url="/rest/ruleConfiguration?companyId="+companyName+"&moduleId=15&eventId=20&serviceId="+serviceId;
if(mod=="ChangeManagement")
url="/rest/ruleConfiguration?companyId="+companyName+"&moduleId=10&eventId=25&serviceId="+serviceId;
if(mod=="CIM")
url="/rest/ruleConfiguration?companyId="+companyName+"&moduleId=5&eventId=15&serviceId="+serviceId;
if(mod=="EditBreakfix"&&serviceId!=undefined)
{
if(serviceBased==true)
url="/rest/ruleConfiguration?companyId="+companyName+"&moduleId=5&eventId=10&serviceId="+serviceId;
else
url="/rest/ruleConfiguration?companyId="+companyName+"&moduleId=5&eventId=10&ciId="+serviceId;
}
	return (dispatch,getState) => {
		if (serviceId === "") {
			console.log("serviceId not found");
		} else {
			//alert(GLOBAL.breakFixSGroupUrl+serviceId)
			axios.get(url)
				.then((breakFixSGAEEditName) => {
					if (!breakFixSGAEEditName) {
						throw Error(breakFixSGAEEditName.statusText);
					}
					return breakFixSGAEEditName;
				})
				.then((breakFixSGAEEditName) => {
					console.log('success in serviceGroupAction...')
					if(GLOBAL.fulfillmentPageLoad===true){
						let typeaheadData=[];
						dispatch({type:'LOAD_BREAKFIXSGI_SUCCESS_TYPEAHEAD',typeaheadData});
					}
					dispatch(loadBreakFixSGAEEditSuccess(breakFixSGAEEditName))
				})
				.catch((err) => {
					console.log(err);
				});
		}

	};
}

export function loadNewBreakFixSGAEEdit(serviceId,companyName,mod,service,amsAttribute={}){
   amsAttribute=JSON.stringify(amsAttribute);
   let url="";
   if(mod=="Breakfix")
   url="/rest/getRuleConfiguration?companyId="+companyName+"&moduleId=5&eventId=10";
   if(mod=="Fulfillment")
   {
	   url="/rest/getRuleConfiguration?companyId="+companyName+"&moduleId=25&eventId=35";
   }
   if(mod=="EditTask" && serviceId!=undefined){
	url="/rest/getRuleConfiguration?companyId="+companyName+"&moduleId=20&eventId=40";
   }
   if(mod=="Task")
   url="/rest/getRuleConfiguration?companyId="+companyName+"&moduleId=20&eventId=40";
   if(mod=="Investigation")
   url="/rest/getRuleConfiguration?companyId="+companyName+"&moduleId=15&eventId=20";
   if(mod=="ChangeManagement")
   url="/rest/getRuleConfiguration?companyId="+companyName+"&moduleId=10&eventId=25";
   if(mod=="CIM")
   url="/rest/getRuleConfiguration?companyId="+companyName+"&moduleId=5&eventId=15";
   if(mod=="EditBreakfix"&&serviceId!=undefined)
   {
	url="/rest/getRuleConfiguration?companyId="+companyName+"&moduleId=5&eventId=10";
   }
   return (dispatch,getState) => {
	if (serviceId === "") {
		console.log("serviceId not found");
	} else {
		axios.get(url,{ headers: { 'query': amsAttribute }})
			.then((breakFixSGAEEditName) => {
				if (!breakFixSGAEEditName) {
					throw Error(breakFixSGAEEditName.statusText);
				}
				return breakFixSGAEEditName;
			})
			.then((breakFixSGAEEditName) => {
				console.log('success in serviceGroupAction...')
				if(GLOBAL.fulfillmentPageLoad===true){
					let typeaheadData=[];
					dispatch({type:'LOAD_BREAKFIXSGI_SUCCESS_TYPEAHEAD',typeaheadData});
				}
				dispatch(loadBreakFixSGAEEditSuccess(breakFixSGAEEditName))
			})
			.catch((err) => {
				console.log(err);
			});
	}
  };
}
export function loadBreakFixSGAETask(serviceId,companyName,mod){
let url="";
console.log("mod::::"+mod)
if(mod=="Breakfix")
url="/rest/ruleConfiguration?companyId="+companyName+"&moduleId=5&eventId=10&serviceId="+serviceId;
if(mod=="Task")
//url="/rest/ruleConfiguration?companyId="+companyName+"-tms&moduleName=Task&eventName=Edit Task&serviceId="+serviceId;
url="/rest/ruleConfiguration?companyId="+companyName+"&moduleId=5&eventId=40&serviceId="+serviceId;
if(mod=="Investigation")
url="/rest/ruleConfiguration?companyId="+companyName+"&moduleId=15&eventId=20&serviceId="+serviceId;
if(mod=="ChangeManagement")
url="/rest/ruleConfiguration?companyId="+companyName+"&moduleId=10&eventId=25&serviceId="+serviceId;
if(mod=="CIM")
url="/rest/ruleConfiguration?companyId="+companyName+"&moduleId=5&eventId=15&serviceId="+serviceId;
if(mod=="EditBreakfix")
url="/rest/ruleConfiguration?companyId="+companyName+"&moduleId=5&eventId=10&serviceId="+serviceId;
if(mod=="TaskFullfilment")
//url="/rest/ruleConfiguration?companyId="+companyName+"-tms&moduleName=Task&eventName=Edit Task&serviceId="+serviceId;
url="/rest/ruleConfiguration?companyId="+companyName+"&moduleName=Fulfillment&eventName=EditFulfillment&serviceId="+serviceId;
if(mod=="TaskChangeManagement")
//url="/rest/ruleConfiguration?companyId="+companyName+"-tms&moduleName=Task&eventName=Edit Task&serviceId="+serviceId;
url="/rest/ruleConfiguration?companyId="+companyName+"&moduleName=ChangeManagement&eventName=EditRFC&serviceId="+serviceId;
if(mod=="TaskInvestigation")
//url="/rest/ruleConfiguration?companyId="+companyName+"-tms&moduleName=Task&eventName=Edit Task&serviceId="+serviceId;
url="/rest/ruleConfiguration?companyId="+companyName+"&moduleId=15&eventId=5&serviceId="+serviceId;
console.log("acturl:::"+url)
	return(dispatch) =>{
		if(serviceId === ""){
			console.log("serviceId not found");
		} else {
			//alert(GLOBAL.breakFixSGroupUrl+serviceId)
			axios.get(url)
				.then((breakFixSGAETaskName) => {
					if (!breakFixSGAETaskName) {
						dispatch({
							type: 'TASK_DATA_LOADING_ERROR',
							errorMessage: 'error loading Group Info, check network connectivity or refresh page again.'
						});
						throw Error(breakFixSGAETaskName.statusText);
					}
					return breakFixSGAETaskName;
				})
				.then((breakFixSGAETaskName) => {
					let assignmentGroupData = [];
					let assignedToData = [];
					for (let i = 0; i < breakFixSGAETaskName.data.length; i++) {
						let obj1 = {
							"id": breakFixSGAETaskName.data[i].assignmentGroupName,
							"name": breakFixSGAETaskName.data[i].assignmentGroupId
						};
						let obj2 = {
							"id": breakFixSGAETaskName.data[i].assignmentMemberName,
							"name":  breakFixSGAETaskName.data[i].assignmentMemberId
						};
						assignmentGroupData.push(obj1);
						assignedToData.push(obj2);
					}
					dispatch({
						type: 'ASSIGNMENT_GROUP_DATA_LOADED_FOR_TASK',
						assignmentGroupData,
						isFetching: false
					});

					dispatch({
						type: 'ASSIGNED_TO_DATA_LOADED_FOR_TASK',
						assignedToData,
						isFetching: false
					});
					dispatch(loadBreakFixSGAETaskSuccess(breakFixSGAETaskName))
				})
				.catch((err) => {
					console.log(err);
				});
		}

	};
}
export function loadNewBreakFixSGAEChange(serviceId,companyName,mod,service,amsAttribute={}){
	amsAttribute=JSON.stringify(amsAttribute);
	let url="";
	if(mod=="ChangeManagement")
	// if(service=="impactedCI")
	// {
	url="/rest/getRuleConfiguration?companyId="+companyName+"&moduleId=10&eventId=30";
	// }
	// else{
	// 	url="/rest/ruleConfiguration?companyId="+companyName+"&moduleId=10&eventId=30&serviceId="+serviceId;
	
	// }
	
		return(dispatch,getState) =>{
			if(serviceId === ""){
				console.log("serviceId not found");
			}else{
				//alert(GLOBAL.breakFixSGroupUrl+serviceId)
				dispatch({
					type: 'LOAD_FIELD_VALUE_FOR_CHANGE',
					isFieldFetching:true
				});
					axios.get(url,{ headers: { 'query': amsAttribute }})
						.then((breakFixSGAEChangeName)=>{
							if(!breakFixSGAEChangeName){
								throw Error(breakFixSGAEChangeName.statusText);
							}
							return breakFixSGAEChangeName;
						})
						.then((breakFixSGAEChangeName) =>{
							if(GLOBAL.changepageLoad===true){
								let typeaheadData=[];//,currentEditChangeDetails=getState().editChangeInitialData, flag=false;
								if(breakFixSGAEChangeName.data!==''){
									breakFixSGAEChangeName.data.map((item)=>{
										   typeaheadData.push({'value':item.assignmentGroupId,'label':item.assignmentGroupName, 'supportCompanyID': (item?.assignmentCompanyId || ''), 'supportCompanyName': (item?.assignmentCompanyName || '') });
										//    if(typeof currentEditChangeDetails.changeManagementGroup!=='undefined'){
										// 	   if(currentEditChangeDetails.changeManagementGroup+''===item.assignmentGroupId+''){
										// 		   flag=true;
										// 	   }
										//    }
									});
								}
								// if(GLOBAL.changeEdit===true && flag===false && typeof currentEditChangeDetails.changeManagementGroup!=='undefined'){
								// if(currentEditChangeDetails.changeManagementGroup!=='0' && currentEditChangeDetails.changeManagementGroup!=='' && currentEditChangeDetails.changeManagementGroup!==0 && currentEditChangeDetails.changeGroupname!==''){
								// 	typeaheadData.push({'value':currentEditChangeDetails.changeManagementGroup,'label':currentEditChangeDetails.changeGroupname});
								// }}
								dispatch({type:'LOAD_BREAKFIXSGAECHANGE_SUCCESS_TYPEAHED',typeaheadData});
							}
							dispatch(loadBreakFixSGAEChangeSuccess(breakFixSGAEChangeName))
							dispatch({
								type: 'LOAD_FIELD_VALUE_FOR_CHANGE',
								isFieldFetching:false
							});
						})
						.catch((err) => {
							console.log(err);
						});
					}
	
		};
	}
	
export function loadNewBreakFixSGAEChangeWithParams(serviceId,companyName,mod,service,amsAttribute={}, serviceCompanyId){
	amsAttribute=JSON.stringify(amsAttribute);
	let url="";
	if(mod=="ChangeManagement")
	// if(service=="impactedCI")
	// {
	url="/rest/getRuleConfiguration?companyId="+companyName+"&moduleId=10&eventId=30&serviceCompanyId="+serviceCompanyId;
	// }
	// else{
	// 	url="/rest/ruleConfiguration?companyId="+companyName+"&moduleId=10&eventId=30&serviceId="+serviceId;
	
	// }
	
		return(dispatch,getState) =>{
			if(serviceId === ""){
				console.log("serviceId not found");
			}else{
				//alert(GLOBAL.breakFixSGroupUrl+serviceId)
				dispatch({
					type: 'LOAD_FIELD_VALUE_FOR_CHANGE',
					isFieldFetching:true
				});
					axios.get(url,{ headers: { 'query': amsAttribute }})
						.then((breakFixSGAEChangeName)=>{
							if(!breakFixSGAEChangeName){
								throw Error(breakFixSGAEChangeName.statusText);
							}
							return breakFixSGAEChangeName;
						})
						.then((breakFixSGAEChangeName) =>{
							if(GLOBAL.changepageLoad===true){
								let typeaheadData=[];//,currentEditChangeDetails=getState().editChangeInitialData, flag=false;
								if(breakFixSGAEChangeName.data!==''){
									breakFixSGAEChangeName.data.map((item)=>{
										   typeaheadData.push({'value':item.assignmentGroupId,'label':item.assignmentGroupName, 'supportCompanyID': (item?.assignmentCompanyId || ''), 'supportCompanyName': (item?.assignmentCompanyName || '') });
										//    if(typeof currentEditChangeDetails.changeManagementGroup!=='undefined'){
										// 	   if(currentEditChangeDetails.changeManagementGroup+''===item.assignmentGroupId+''){
										// 		   flag=true;
										// 	   }
										//    }
									});
								}
								// if(GLOBAL.changeEdit===true && flag===false && typeof currentEditChangeDetails.changeManagementGroup!=='undefined'){
								// if(currentEditChangeDetails.changeManagementGroup!=='0' && currentEditChangeDetails.changeManagementGroup!=='' && currentEditChangeDetails.changeManagementGroup!==0 && currentEditChangeDetails.changeGroupname!==''){
								// 	typeaheadData.push({'value':currentEditChangeDetails.changeManagementGroup,'label':currentEditChangeDetails.changeGroupname});
								// }}
								dispatch({type:'LOAD_BREAKFIXSGAECHANGE_SUCCESS_TYPEAHED',typeaheadData});
							}
							dispatch(loadBreakFixSGAEChangeSuccess(breakFixSGAEChangeName))
							dispatch({
								type: 'LOAD_FIELD_VALUE_FOR_CHANGE',
								isFieldFetching:false
							});
						})
						.catch((err) => {
							console.log(err);
						});
					}
	
		};
	}
