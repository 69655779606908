
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import {Row,Col,Form,ButtonToolbar,Button,ControlLabel,FormControl} from 'react-bootstrap';

class TextFieldForm extends React.Component {	
	render(){
		return(
			<div>
				<div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">Translate - {this.props.fieldNameVal3} - {this.props.lanNameVal3}</div></div>

				<div className="rBoxGap">
					<Row>
					<Col md={12}>
						<Form.Group className="form-group" >
							<ControlLabel bsClass="">Base Language</ControlLabel>
							<FormControl disabled value="English" type="text" />
						</Form.Group>
					</Col>	
					<Col md={12}>
						<Form.Group className="form-group" >
							<ControlLabel bsClass="">{this.props.fieldName1}</ControlLabel>
							<FormControl disabled value="Amazon EC2.T2 micro service" componentClass="textarea" rows="2" />
						</Form.Group>
					</Col>
					<Col md={12}>
						<Form.Group className="form-group" >
							<ControlLabel bsClass="">Value</ControlLabel>
							<FormControl disabled value="tc2.micro" componentClass="textarea" rows="2" />
						</Form.Group>
					</Col>                    
					<Col md={12}>
						<Form.Group className="form-group">
							<ControlLabel bsClass=""><span className="rStar"></span> Translated {this.props.fieldName1} {/*<Button className="margin-l-10 margin-b-5" title="Google Translate" bsSize="xsmall"><i className="fa fa-google"></i> Translate</Button>*/}</ControlLabel>
							<FormControl componentClass="textarea" rows="3" />
							</Form.Group>
					</Col>
					<Col md={12}>
						<Form.Group className="form-group">
							<ControlLabel bsClass=""><span className="rStar"></span> Translated Value {/*<Button className="margin-l-10 margin-b-5" title="Google Translate" bsSize="xsmall"><i className="fa fa-google"></i> Translate</Button>*/}</ControlLabel>
							<FormControl componentClass="textarea" rows="3" />
							</Form.Group>
					</Col>                    
					</Row>
					<Row>
						<Col md={12}>
							<ButtonToolbar className="black">
								<Button className='rgSidrkBtn' onClick={()=>{this.props.insideTableShowFun6(false,'')}} bsSize="small" bsStyle="primary">Save</Button>
								<Button className='rgSiLigBtn' onClick={()=>{this.props.insideTableShowFun6(false,'')}} bsSize="small" bsStyle="text">Close</Button></ButtonToolbar>
						</Col>	
					</Row>
				</div>
			</div>
		);
	}
}

export default TextFieldForm;