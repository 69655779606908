/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import AiIcon from "../../aiIcon";
import { Button } from "react-bootstrap";
import { useSelector } from 'react-redux';

const NlpSearchSubStatusComponent = ({ showConditionBuilder, onSubStatusSelect, selectedFilterTab }) => {
  const translator = useSelector(state => state.spcmReducer.tr);
  return (
    <><div className="aiButtonCss">
        <Button title={translator['NLP Advanced Search']} bsPrefix=" " onClick={()=>{ showConditionBuilder(true); onSubStatusSelect(5); selectedFilterTab(5); }}>
            <AiIcon style={{"top":"-1px"}} /> {translator['Advanced Search']}
        </Button>
       </div>
    </>
  );  
};

export default NlpSearchSubStatusComponent;