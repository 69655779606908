
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Form from 'react-bootstrap/Form';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import {connect} from 'react-redux';

import { GLOBAL } from "_Globals";

import { loadStatusBasedHamburgerOptions,loadHamburger } from '../../../../actions/common/loadStatusBasedHamburgerOptions';
import {loadChangeEditData, changeEditDataLoadSuccess } from '../../../../actions/changeManagement/changeManagementAction.js';
import { loadTimelineData } from '../../../../actions/common/commonApisActions.js';

import configureStore from '../../../../store/configureStore';
import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";

const store = configureStore();

class CmdbUpdate extends React.Component{
    constructor(props){
        super();
        this.state={
			cmdbVal:'',
			loader:false,
			successMsg:'',
			cmdbValError:'',
			errorMsg:'',
			closeCode:'',
			closureNote:'',
			closeCodeError:'',
			closureNoteError:'',
			closureErrorMsg:'',
			closeErrorMsg:''
        }
		this.updateCmdbVal = this.updateCmdbVal.bind(this);
    }
    updateCmdbVal(val){
		let ref = this;
		switch (val.target.name) {
			case 'cmdb':
				let value = val.target.value.trimStart();
				ref.cmdbRef.value= val.target.value.trimStart();
				ref.setState({cmdbVal: value, cmdbValError:''});
				break;
			case 'closeCode':
				ref.setState({ closeCode: val.target.value, closeCodeError: '' })
				break;
			case 'closureNote':
				ref.closureNoteRef.value = val.target.value.trimStart();
				ref.setState({ closureNote: val.target.value.trimStart(), closureNoteError: '' })
				break;
		}
	}
    submit() {
		let cmdbVal = '';
		let ref = this;
		let data = this.props.editChangeInitialData;
		let submitForm = true;
		let date_format=this.props.date_format
		if(!(this.props.editChangeInitialData.type == "20" && this.props.editChangeInitialData.cmdbUpdateNeeded == "No")){
		let cmdbValue = ref.state.cmdbVal;
		cmdbVal = "CMDB Update: "+cmdbValue.trim();
			if(cmdbValue == "") {
				ref.setState({ cmdbValError: 'error', errorMsg: "enter CMDB Update" });
			submitForm = false;
		}else if(cmdbValue.length < 5){
			ref.setState({ cmdbValError: 'error', errorMsg: "Length is too short" });
			submitForm = false;
		}else{
			ref.setState({ cmdbValError: '' });
			}
		}
		
		if ((this.props.editChangeInitialData.type == "20" && this.props.editChangeInitialData.cmdbUpdateDone == false && this.props.editChangeInitialData.cmdbUpdateNeeded == "Yes")||
		(this.props.editChangeInitialData.type == "20" && this.props.editChangeInitialData.cmdbUpdateNeeded == "No")) {
			if (this.state.closeCode == ""){
				submitForm = false;
				this.setState({ closeCodeError: 'error',closeErrorMsg: "select Closure Code"  });
			}else{
				this.setState({ closeCodeError: '' });
			}
			if (this.state.closureNote == "" || this.state.closureNote.trim() == ""){
				submitForm = false;
				this.setState({ closureNoteError: 'error', closureErrorMsg:"enter Closure Note"  });
			}else if(this.state.closureNote.length < 5){
				this.setState({closureNoteError: 'error', closureErrorMsg:"Length is too short"});
				submitForm = false;
			}
			else
				this.setState({ closureNoteError: '' });
				cmdbVal = (this.props.editChangeInitialData.cmdbUpdateNeeded == "Yes") ? cmdbVal +"| ":cmdbVal;
			cmdbVal = cmdbVal +"Close Code:"+this.state.closeCode +"| Closure Note:"+this.state.closureNote;
		}
		if(!submitForm){
			return false;
		}
		let activityData = {
			"itemId": data.changeId,
			"description": cmdbVal,
			"createdOn": "",
			"createdBy": "",
			"isInternal": "N",
			"module": "ChangeManagement",
			"createdByName": ""
		}
		let changeObj = {
			"modifiedBy":"",
            "modifiedByName":"",
			"cmdbUpdateDone" : "true"
		}
		this.props.cmbdShow();
		this.setState({ loader: true })
		axios.post(GLOBAL.breakFixActivityLogPostUrl, activityData).then(function (response) {
				if (response.status == 201) {
					ref.setState({ loader: false, successMsg: 'Form saved successfully' });
				}
		}).catch(function (error) {
				ref.setState({ loader: false });
	    });

		if((this.props.editChangeInitialData.type == "20" && this.props.editChangeInitialData.cmdbUpdateNeeded == "No")||
		(this.props.editChangeInitialData.type == "20" && this.props.editChangeInitialData.cmdbUpdateDone == false && this.props.editChangeInitialData.cmdbUpdateNeeded == "Yes")){
			axios.patch(GLOBAL.changeEditUrl + data.changeId, {
			  "status": "60",
			  "actEndDate": "",
			  "systemEndDateRequired": "Y",
			  "modifiedBy":"",
			  "modifiedByName":"",
			  "cmdbUpdateDone" : "true"
			}).then((resp1) => {
				if (resp1.status == 200 || resp1.status == 201) {
				  axios.patch(GLOBAL.changeEditUrl + data.changeId, {"status": "70",}).then((resp2) => {
					  if (resp2.status == 200 || resp2.status == 201) {
						ref.props.loadChangeEditData(data.changeId);
						ref.props.loadStatusBasedHamburgerOptions('ChangeManagement',resp2.data.status,resp2.data.changeId);
						ref.props.changeActionView('processHierarchy');
					  }
			}).catch(function (error) {
		    });
		}
		}).catch(function (error) {
				ref.props.loadHamburger();
		});
		}else{
			axios.patch(GLOBAL.changeEditUrl + data.changeId, changeObj).then(function (response) {
				ref.props.changeEditDataLoadSuccess(response.data,date_format);
				ref.props.changeActionView('processHierarchy');
			})
		}
	}
    render(){
        return (<div className="hambTab">
			<div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
				<ul>
					<li>
					<button
					    type='button'
						title="Minimize the right panel"
						bsClass=""
						bsStyle=""
						className="closerightPanelBtn"
						onClick={() => {
						this.props.rightEditPanel(false);
						}}
					>
						<IoClose/>
					</button>
					</li>
				</ul>
			</div>
			<div className="rPageHeading">{this.props.translator['CMDB Update']}</div>
			<div className='rBoxGap'>
            {this.props.editChangeInitialData.type == "20" && this.props.editChangeInitialData.cmdbUpdateNeeded == "No"?"":
			<div>
			       <Form.Group validationState={this.state.cmdbValError}>
			            <Form.Label><span className="rStar"></span>{this.props.translator['CMDB Update']}</Form.Label>
			                <Form.Control name="cmdb" componentClass="textarea" rows="4" disabled={this.state.successMsg != ''} inputRef={(ref) => { this.cmdbRef = ref }} onChange={this.updateCmdbVal}/>
			                    {this.state.cmdbValError == 'error' ? <div><font color="red"><strong>{this.state.errorMsg}</strong></font></div>:""}
			       </Form.Group>	
			</div>
			}
            {(this.props.editChangeInitialData.type == "20" && this.props.editChangeInitialData.cmdbUpdateDone ==  false && this.props.editChangeInitialData.cmdbUpdateNeeded == "Yes")||
			(this.props.editChangeInitialData.type == "20" && this.props.editChangeInitialData.cmdbUpdateNeeded == "No")? 
			<div>
			        <Form.Group validationState={this.state.closeCodeError}  disabled={this.state.successMsg != ''}>
				        <Form.Label><span className="rStar"></span>{this.props.translator['Closure Code']}</Form.Label>
				            <Form.Select name="closeCode" componentClass="select" onChange={this.updateCmdbVal}  disabled={this.state.successMsg != ''}>
					            <option value="">Select</option>
					            <option value="Successful">Successful</option>
					            <option value="Unsuccessful">Unsuccessful</option>
					            <option value="Partially Successful">Partially Successful</option>
				            </Form.Select>
				            {this.state.closeCodeError == 'error' ? <div><font color="red"><strong>{this.state.closeErrorMsg}</strong></font></div>:""}
			        </Form.Group>
		 	        <Form.Group validationState={this.state.closureNoteError}>
				        <Form.Label><span className="rStar"></span>{this.props.translator['Closure Notes']}</Form.Label>
				            <Form.Control name="closureNote" type="text" inputRef={(ref)=>{this.closureNoteRef = ref}} onChange={this.updateCmdbVal} maxLength={1000} disabled={this.state.successMsg!=''}/>
			                {this.state.closureNoteError == 'error' ? <div><font color="red"><strong>{this.state.closureErrorMsg}</strong></font></div>:""}
			        </Form.Group>
		    </div>
		  :""}
          {this.state.successMsg == '' ?
				<ButtonToolbar className="margin-t-15">
					<Button bsSize="small" bsStyle="primary" onClick={() => { this.submit() }} disabled={this.state.loader} >{this.state.loader ? <ImSpinner6 className="icn-spinner"/> : null}{this.props.translator['Save']}</Button>
					<Button bsSize="small" bsStyle="primary" className="btn-text" disabled={this.state.loader} onClick={() => { this.props.changeActionView('processHierarchy') }}>{this.props.translator['Cancel']}</Button>
				</ButtonToolbar> :
				<font color="green" ><b>{this.props.translator[this.state.successMsg]}</b></font>
		  }
		  </div>
        </div>);
    }
}
export default connect (null,{loadStatusBasedHamburgerOptions,loadHamburger,loadChangeEditData,loadTimelineData,changeEditDataLoadSuccess})(CmdbUpdate);
