
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState} from "react";
import { Row, Col, Button } from 'react-bootstrap';
import {useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { deleteDepartmentData, updateDepartmentAction } from "../../../../../actions/foundation/departmentAction";
import { reduxForm } from "redux-form";
import Swal from "sweetalert2";

import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import {HiOutlineTrash} from "react-icons/hi";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';

const HeaderSec = (props) => {
    const navigate = useNavigate();
    const tr = useSelector((state) => state.spcmReducer.tr);
    const dispatch = useDispatch();
    const params = useParams();
    const context = useContext(MyContext);
    const closeButton = useRef(null);
    const saveObjRef = useRef(null);

    useEffect(() => {
      context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
      context.newTabPrevent();
    }, []);
  
    const submit = async (values) => {
      let id = params.id
      let isValid = true;
        console.log("EditHeaderDept",values, id)       
        if (!values.name || values.name == ''|| values.name.trim().length == 0) {
          props.setNameErr("error");
          isValid = false;
          // return;
        } else {
          props.setNameErr("");
          // isValid = true;
      }
      if (!values.status || values.status == '') {
          props.setStatusErr("error");
          isValid = false;
        } else {
          props.setStatusErr("");
          // isValid = true;
      }
        if (isValid == true) {
        let postjson;
            postjson = {
                id: id,
                dept_code: values.code,
                dept_name: values.name,
                status: values.status,
                pagesize: 0,
                eventNameDefault: null,
                orgn_name: values.organization_name,
                company_name: values.company_name,
                organizationId: values.org_id,
                companyId: values.company_id,
                deptNameToEdit: values.initialDeptName
        };
        dispatch(updateDepartmentAction(postjson)); 
        }
    };

    const onDeleteClick = () => {
      const id = window.location.href.split("/").pop();
      console.log("CheckId", id)
        Swal.fire({
          title: tr["Are you sure you want to delete?"],
          width: 550,
          padding: '15px',
          showDenyButton: true,
          confirmButtonText: tr['Yes'],
          denyButtonText: tr['No'],
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(deleteDepartmentData(id))
          } else if (result.isDenied) {
            
          }
        })
      };
    let { handleSubmit, submitting } = props;
    return (
        <>
            <Row className="margin-b-15">
            <Col lg={4} md={6} sm={6} xs={5}>
                    <h1 bsClass="" className="sPageHeading1">{tr["Department"]}</h1>
                </Col>
                <Col lg={8} md={6} sm={6} xs={7}>
                    <div className="paHedFilter">
                        <Button role="button" bsPrefix=' '  className="myBt rclose" title={tr["Delete"]} onClick={onDeleteClick} >
                          <HiOutlineTrash/>
                        </Button>
                        <Link
                            bsPrefix=' '
                            id="hdID"
                            to="javascript:void(0)"
                            className="myBt plus ctrlKeyPrevent"
                            disabled={props.loader}
                            onClick={handleSubmit(submit)} 
                            title={tr['Save']}
                            ref={saveObjRef} >
                            <span>{props.loader ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/> }</span>
                        </Link>
                        <Link
                            bsPrefix=' '
                            to="/department"
                            className="myBt cancel fillBtn ctrlKeyPrevent"
                            title={tr['Cancel']}
                            ref={closeButton} >
                            <IoClose/>
                        </Link>
                    </div>
                </Col>
            </Row>
        </>
    )
}
export default reduxForm({
    form: "EditDepartment",
  })(HeaderSec);