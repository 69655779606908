
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, ListGroup, Tab, Tabs,Card} from 'react-bootstrap';
import { useDispatch, useSelector, connect } from 'react-redux';
import PopUpAuditLog from "../../WorkItemBoard/task/edit/PopUpAuditLog";
import { Scrollbars } from "react-custom-scrollbars";
import ReactReadMoreLess from "../../../helpers/ReactReadMoreLess";
import datetimeConvertor from "../../../helpers/ISO8601converter";
import ListLoader from "../../common/loaders/ListLoader";

import { loadTaskItemToEdit } from '_Actions/task/TaskDataAction';
import {loadTMSActivityLogDetailsForRequest} from "_Actions/breakFix/breakFixActivityLogAction";

import axios from "axios";
const api = axios.create({ headers: { Pragma: "no-cache", "Cache-control": "no-store" } });

import Cookies from "universal-cookie";
const cookies = new Cookies();

let timezone = cookies.get("gph");
if (timezone) timezone = timezone.replace("s:", "");
if (timezone) timezone = timezone.substring(0, timezone.lastIndexOf("."));
timezone = timezone.split("~");
timezone = timezone[3];

let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
//console.log("homepage::::"+homepagelocation);
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();


const TaskDetailsPopup = (props) => {
    const dispatch = useDispatch();
    const tr = useSelector((state) => state.spcmReducer.tr);
    //const taskDetails = useSelector((state) => state.createTaskFormValues.editFormValues);
    const breakFixActivityLogDetails = useSelector((state) => state.breakFixActivityLogDetails);
    const [tabKey, setTabKey] = useState('Task Details');
    const [roleId, setRoleId] = useState('');
    const [dateformat, setDateformat] = useState('');

    const MoreArrow = (<i title="More" className="moreArrow fa fa-angle-double-down"></i>);
    const LessArrow = (<i title="Less" className="margin-l-5 lessArrow fa fa-angle-double-up"></i>);

    useEffect(() => {
        let requestType = props.taskObj?.requestType ? props.taskObj.requestType : "";
        //dispatch(loadTaskItemToEdit(props.taskObj.taskCode, props.disableFieldsForGuestRole, requestType));
        dispatch(loadTMSActivityLogDetailsForRequest(props.taskObj.taskCode, "createdOn desc","",requestType));
        getUserInfo();
    }, []);

    const getUserInfo = () => {
        try {
            let str = {};
            str.user_id = "";
            str = JSON.stringify(str);
            api.get("/api/userInfo", { headers: { query: str } }).then(res => {
                console.log('res - ', res);
                setRoleId(res?.data?.role_id);
                setDateformat(res?.data?.dateformat);
            }).catch(err => {
                console.error(err);
            });
        } catch (e) {
            console.error(e);
        }
    }

    const ascactivitysort = () => {
        dispatch(loadTMSActivityLogDetailsForRequest(props.taskObj.taskCode, "createdOn asc","","myRequest"));
    }

    const descactivitysort = () => {
        dispatch(loadTMSActivityLogDetailsForRequest(props.taskObj.taskCode, "createdOn desc","","myRequest"));
    }

    const renderActivityLog = (activityLogData) => {

        const groupActivityLog = activityLogData.reduce((obj, index) => {
            const date = moment(index.createdOn).format(jwtdf);
            // const date = index.createdOn.split('T')[0];
            if (!obj[date]) {
                obj[date] = [];
            }
            obj[date].push(index);
            return obj;
        }, {});


        return Object.keys(groupActivityLog).map((activityObj, index) => {
            return (
                <div id="brkActLogcomm" className="actCommts">
                    {/* <div className='hed' key={index}>{activityObj}</div> */}
                    {groupActivityLog[activityObj].map((comment, childKey) => {
                        let intval = "";
                        if (comment.isInternal == "Y") intval = "(Internal)";

                        let actDateTimeFormat = jwtdf + " hh:mm A";
                        let myDateObj,
                            endDate,
                            endDateStr,
                            timeago,
                            startTime,
                            duration,
                            hours,
                            isoDate;
                        if (comment.module == "Task") {
                            let newDate = new Date(comment.createdOn).toLocaleString(
                                "en-US",
                                { timeZone: timezone }
                            );
                            myDateObj = moment(newDate).format(jwtdf + " HH:mm:ss");
                            isoDate = datetimeConvertor(myDateObj, jwtdf + " HH:mm:ss");
                            myDateObj = moment(isoDate).format(actDateTimeFormat);
                        } else {
                            isoDate = datetimeConvertor(
                                comment.createdOn,
                                jwtdf + " HH:mm:ss"
                            );
                            myDateObj = moment(isoDate).format(actDateTimeFormat);
                        }
                        endDate = moment(myDateObj, actDateTimeFormat);
                        endDateStr = endDate._i;
                        startTime = moment(
                            new Date().toLocaleString("en-US", { timeZone: timezone })
                        );
                        timeago = moment(endDateStr, actDateTimeFormat).from(startTime);
                        duration = moment.duration(startTime.diff(endDate));
                        hours = duration.asHours();

                        let contentToShow = comment.from == "" ? comment.description == null || comment.description == "null" ? "" : comment.description : "Changed Status from " + comment.from + " to " + comment.to;

                        return (
                            <Card className="actlogCrd margin-b-15" bsPrefix=" " key={childKey}>
                                <div className="circleTxt">
                                    <div className="subhed">
                                        {
                                            comment.createdByName !== "System" ?
                                                    <span className="circleNa" title={comment.module == "Task" ? comment.createdbyName : comment.createdByName}>
                                                        {comment.module == "Task"
                                                            ? comment.createdbyName
                                                            : comment.createdByName}
                                                    </span>
                                                : <span className="margin-r-20">{comment.createdByName}</span>
                                        }
                                        <span
                                            className="margin-r-10 timeao"
                                            title={hours >= 24 ? "" : endDateStr}
                                        >
                                            {hours >= 24 ? endDateStr : timeago}
                                        </span>
                                        <span className="timeao">{intval}</span>
                                    </div>
                                    <div className="des">
                                        <ReactReadMoreLess
                                            charLimit={235}
                                            readMoreText={MoreArrow}
                                            readLessText={LessArrow}
                                            content={contentToShow}
                                        />
                                    </div>
                                </div>
                                {/* <div className='f-size-12' style={{"marginTop":"2px"}}>{timeago}</div> */}
                            </Card>
                        );
                    })}
                </div>
            );
        });
    }


    return (
        <div className="userInfoPopupParent rBoxStyle">
            <div className="rBoxGap">
                <div className="rBoxTabs">
                    <Tabs onSelect={(k) => setTabKey(k)} activeKey={tabKey}>
                        <Tab eventKey={tr["Task Details"]} title={tr["Task Details"]}>
                            <Scrollbars style={{ height: 400 }} autoHide={false} hideTracksWhenNotNeeded={false} className="areaPopup areaTaskSpace">
                                <ListGroup bsPrefix=" " as="ul" className="tskListDetail">
                                    {props.taskObj.taskCode !== null ? props.taskObj.taskCode !== "" ? <ListGroup.Item as="li" bsPrefix=" " className="rwTable" style={{ borderTop: "none" }}><span className="rwKey">{tr["Task Code"]}</span><span className="rwVal">{props.taskObj.taskCode}</span></ListGroup.Item> : null : null}
                                    {props.taskObj.taskType !== null ? props.taskObj.taskType !== "" ? <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Task Type"]}</span><span className="rwVal">{props.taskObj.taskType}</span></ListGroup.Item> : null : null}
                                    {props.taskObj.description !== null ? props.taskObj.description !== "" ? <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Summary"]}</span><span className="rwVal"><ReactReadMoreLess charLimit={60} readMoreText={MoreArrow} readLessText={LessArrow} content={props.taskObj.description} /></span></ListGroup.Item> : null : null}
                                    {props.taskObj.serviceName !== null ? props.taskObj.serviceName !== "" ? <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Service"]}</span><span className="rwVal"><ReactReadMoreLess charLimit={60} readMoreText={MoreArrow} readLessText={LessArrow} content={props.taskObj.serviceName} /></span></ListGroup.Item> : null : null}
                                    {props.taskObj.sequenceNumber !== null ? props.taskObj.sequenceNumber !== "" ? <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Sequence"]}</span><span className="rwVal">{props.taskObj.sequenceNumber}</span></ListGroup.Item> : null : null}
                                    {props.taskObj.ciName !== null ? props.taskObj.ciName !== "" ? <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Impacted CI"]}</span><span className="rwVal"><ReactReadMoreLess charLimit={60} readMoreText={MoreArrow} readLessText={LessArrow} content={props.taskObj.ciName} /></span></ListGroup.Item> : null : null}
                                    {props.taskObj.assignedToGroup !== null ? props.taskObj.assignedToGroup !== "" ? <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Assignment Group"]}</span><span className="rwVal"><ReactReadMoreLess charLimit={60} readMoreText={MoreArrow} readLessText={LessArrow} content={props.taskObj.assignedToGroup} /></span></ListGroup.Item> : null : null}
                                    {props.taskObj.assignedTo !== null ? props.taskObj.assignedTo !== "" ? <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Assigned To"]}</span><span className="rwVal">{props.taskObj.assignedTo}</span></ListGroup.Item> : null : null}
                                    {props.taskObj.expectedStartDate !== null ? props.taskObj.expectedStartDate !== "" ? <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Expected Start Date"]}</span><span className="rwVal">{props.taskObj.expectedStartDate}</span></ListGroup.Item> : null : null}
                                    {props.taskObj.expectedDueDate !== null ? props.taskObj.expectedDueDate !== "" ? <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Expected End Date"]}</span><span className="rwVal">{props.taskObj.expectedDueDate}</span></ListGroup.Item> : null : null}
                                    {props.taskObj.actualStartDate !== null ? props.taskObj.actualStartDate !== "" ? <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Actual Start Date"]}</span><span className="rwVal">{props.taskObj.actualStartDate}</span></ListGroup.Item> : null : null}
                                    {props.taskObj.actualEndDate !== null ? props.taskObj.actualEndDate !== "" ? <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Actual End Date"]}</span><span className="rwVal">{props.taskObj.actualEndDate}</span></ListGroup.Item> : null : null}
                                    {props.taskObj.hopCount !== null ? props.taskObj.hopCount !== "" ? <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Hop Count"]}</span><span className="rwVal">{props.taskObj.hopCount}</span></ListGroup.Item> : null : null}
                                    {props.taskObj.priorityValue !== null ? props.taskObj.priorityValue !== "" ? <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Priority"]}</span><span className="rwVal">{props.taskObj.priorityValue}</span></ListGroup.Item> : null : null}
                                    {props.taskObj.additionalParams !== null ? props.taskObj.additionalParams !== "" ? <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Additional Information"]}</span><span className="rwVal"><ReactReadMoreLess charLimit={60} readMoreText={MoreArrow} readLessText={LessArrow} content={props.taskObj.additionalParams} /></span></ListGroup.Item> : null : null}
                                    {props.taskObj.closeNotes !== null ? props.taskObj.closeNotes !== "" ? <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Closure Comments"]}</span><span className="rwVal"><ReactReadMoreLess charLimit={60} readMoreText={MoreArrow} readLessText={LessArrow} content={props.taskObj.closeNotes} /></span></ListGroup.Item> : null : null}
                                </ListGroup>
                            </Scrollbars>
                        </Tab>
                        <Tab eventKey={tr["Comments"]} title={tr["Comments"]}>
                            <Scrollbars style={{ height: 400 }} autoHide={false} hideTracksWhenNotNeeded={false} className="areaPopup areaTaskSpace" >
                                <div className="tskListDetail">
                                {breakFixActivityLogDetails && breakFixActivityLogDetails.length == 0 ? <div className="padding-10 text-c">{tr["Data not available."]}</div>:<>
                                    <div className="margin-t-5 padding-b-5 border-b">
                                        <span className="float-r f-size-17">
                                            <a
                                                href="javascript:void(0)"
                                                onClick={() => {ascactivitysort()}}
                                                className="black"
                                                title={tr["Ascending"]}
                                            >
                                                <i className="fa fa-angle-up"></i>
                                            </a>
                                            &nbsp;&nbsp;
                                            <a
                                                href="javascript:void(0)"
                                                onClick={() => {descactivitysort()}}
                                                className="black"
                                                title={tr["Descending"]}
                                            >
                                                <i className="fa fa-angle-down"></i>
                                            </a>
                                        </span>
                                        <span className="float-r margin-r-10"></span>
                                    </div>
                                    {renderActivityLog(breakFixActivityLogDetails)}
                                    </>}
                                </div>
                            </Scrollbars>
                        </Tab>
                        <Tab eventKey={tr["Audit Log"]} title={tr["Audit Log"]}>
                            <Scrollbars style={{ height: 400 }} autoHide={false} hideTracksWhenNotNeeded={false} className="areaPopup areaTaskSpace">
                                <PopUpAuditLog
                                    translator={tr}
                                    // taskDetails={taskDetails}
                                    taskCode={props.taskObj.taskCode}
                                    module="Task"
                                    date_format={dateformat}
                                    isOverlayTrigger={false}
                                    isLoaderRequired="No"
                                />
                            </Scrollbars>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

export default (TaskDetailsPopup);

