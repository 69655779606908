
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const customeIconComponent = {
    name: "CustomIcon",
    traits: [
      {
        type: "text",
        label: "Title",
        name: "iconTitle",
        placeholder: "Title",
        changeProp: 1,
      },
      {
        type: "text",
        label: "Icon Name",
        name: "iconName",
        placeholder: "icon name",
        changeProp: 1,
      },
      {
        type: "checkbox",
        label: "Is External",
        name: "isExternal",
        changeProp: 1,
      }
    ],
  };
  
  
  export const customIconAdd = {
    model: {
      defaults: {
        tagName: "div",
        draggable: true,
        attributes: { class: "custom-icon-btn" },
        components: [{
          type: 'link',
          tagName: 'a',
          attributes: { href: '#',class:"customIconBtn",title:"Save"},
          components: [{
            tagName: 'i',
            attributes: { class: 'fa fa-arrow-up', 'aria-hidden': 'true'}
          }]
        }],
        traits: customeIconComponent.traits,
        editable: true,
      },
      init(){
        this.on("change:iconName", this.updateIconClass);
        this.on("change:iconTitle", this.updateIconTitle);
      },
      updateIconClass(){
        const model = this.find("i")[0];
        const iconClass = this.get("iconName");
        model.setClass(iconClass);
      },
      updateIconTitle(){
        const model = this.find("a")[0];
        const iconTitleName = this.get("iconTitle");
        model.setTitle(iconTitleName);
      }
    },
  };
  
  export const addCustomIconComponent = (e) => {
    e.DomComponents.addType(customeIconComponent.name, customIconAdd);
  };
  
  export const customIconBlockJson = {
    id: "customIcon",
    label: "Custom Icon",
    // attributes: { class: "fa fa-eye" },
    media:`<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="-5.0 -10.0 110.0 135.0"><g><path d="m62.5 50c0 6.9023-5.5977 12.5-12.5 12.5s-12.5-5.5977-12.5-12.5 5.5977-12.5 12.5-12.5 12.5 5.5977 12.5 12.5"/>
     <path d="m30.438 46.094c-0.14062 0-0.28125-0.015625-0.42188-0.0625-0.82812-0.23438-1.3125-1.0938-1.0781-1.9219 2.1406-7.6719 8.2812-13.578 16.031-15.406 0.82812-0.20312 1.6875 0.32812 1.875 1.1562 0.20312 0.84375-0.32812 1.6875-1.1562 1.875-6.6406 1.5625-11.906 6.625-13.734 13.203-0.1875 0.6875-0.82812 1.1406-1.5 1.1406z"/>
     <path d="m50 71.875c-10.453 0-19.469-7.4375-21.469-17.672-0.17188-0.84375 0.39062-1.6719 1.2344-1.8281 0.84375-0.17188 1.6719 0.39062 1.8281 1.2344 1.7031 8.7812 9.4531 15.141 18.406 15.141s16.688-6.375 18.406-15.141c0.15625-0.84375 0.98438-1.4062 1.8281-1.2344s1.4062 0.98438 1.2344 1.8281c-2 10.234-11.031 17.672-21.469 17.672z"/>
     <path d="m69.562 46.094c-0.6875 0-1.3125-0.45312-1.5-1.1406-1.8281-6.5781-7.0938-11.625-13.734-13.203-0.84375-0.20312-1.3594-1.0469-1.1562-1.875s1.0469-1.3594 1.875-1.1562c7.75 1.8281 13.891 7.7344 16.031 15.406 0.23438 0.82812-0.25 1.6875-1.0781 1.9219-0.14062 0.03125-0.28125 0.0625-0.42188 0.0625z"/>
     <path d="m50 93.75c-24.125 0-43.75-19.625-43.75-43.75s19.625-43.75 43.75-43.75 43.75 19.625 43.75 43.75-19.625 43.75-43.75 43.75zm0-84.375c-22.406 0-40.625 18.219-40.625 40.625s18.219 40.625 40.625 40.625 40.625-18.219 40.625-40.625-18.219-40.625-40.625-40.625z"/></g></svg>`,
    category: "Basic",
    content: {
      type: customeIconComponent.name,
    },
  };
 