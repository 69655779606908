
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import Breadcrumbs from "../../../common/header/breadcrumbs";
import CreateForm from "../create/form";
import HeaderSec from "../create/header";
import "_Css/form/_form.scss";
const CreateIndex = (props) => {
  const [nameErr, setNameErr] = useState("");
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [lastReviewDateError, setlastReviewDateError] = useState("");
  const [nextReviewDateError, setnextReviewDateError] = useState("");
  const [businessErr, setBusinessErr] = useState("");
  const [startErr, setStartErr] = useState("");
  const [endErr, setEndErr] = useState("");
  const [companyErrorState, setCompanyErrorState] = useState('');
  const [selectedRequestor, setSelectedRequestor] = useState({});
  const [selectedBudget, setSelectedBudget] = useState({});
  const [requestorError, setRequestorError] = useState('');
  // const [budgetError, setBudgetError] = useState('');
  const [statusErr, setStatusErr] = useState('');
  const [substatusErr, setSubStatusErr] = useState('');
  const [numberErr, setNumberErr] = useState('');
  const tr = useSelector((state) => state.spcmReducer.tr);
  const setRequestorErrorColor = () => {
    if (Object.keys(selectedRequestor).length > 0) {
     setRequestorError('');
   } else {
      setRequestorError('error');
    }
  };
  const [adminTypeErr, setAdminTypeErr] = useState('');
  const [adminValue, setAdminValue] = useState([]);
  const setAdminErrorColor = (err) => {
    setAdminTypeErr(err);
  };
  // const setBudgetErrorColor = () => {
  //   if (Object.keys(selectedBudget).length > 0) {
  //     setBudgetError('');
  //   } else {
  //     setBudgetError('error');
  //   }
  // };

  return (
    <main>
      <Container fluid className="margin-t-10 margin-b-15">
        <Breadcrumbs
          activePageName={"Create"}
          parentPageurl="/contracts"
          parentPageName={tr["Contract"]}
        />
      </Container>

      <Container fluid>
        <HeaderSec
          setAdminErrorColor={setAdminErrorColor}
          adminValue={adminValue}
          setAdminValue={setAdminValue}
          setCompanyErrorState={setCompanyErrorState}
          setNameErr={setNameErr}
          startDateError={startDateError}
          setStartDateError={setStartDateError}
          endDateError={endDateError}
          setEndDateError={setEndDateError}
          lastReviewDateError={lastReviewDateError}
          setlastReviewDateError={setlastReviewDateError}
          nextReviewDateError={nextReviewDateError}
          setnextReviewDateError={setnextReviewDateError}
          setBusinessErr={setBusinessErr} 
          setStartErr={setStartErr} 
          setEndErr={setEndErr}
          setRequestorErrorColor={setRequestorErrorColor}
          // setBudgetErrorColor={setBudgetErrorColor}
          statusErr={statusErr}
          setStatusErr={setStatusErr}
          substatusErr={substatusErr}
          setSubStatusErr={setSubStatusErr}
          setNumberErr={setNumberErr}
        />
        <Row>
          <Col xs={8}>
            <CreateForm
              adminValue={adminValue}
              setAdminValue={setAdminValue}
              adminTypeErr={adminTypeErr}
              setAdminErrorColor={setAdminErrorColor}
              companyErrorState={companyErrorState} 
              setCompanyErrorState={setCompanyErrorState}
              nameErr={nameErr}
              setNameErr={setNameErr}
              startDateError={startDateError}
              setStartDateError={setStartDateError}
              lastReviewDateError={lastReviewDateError}
              setlastReviewDateError={setlastReviewDateError}
              nextReviewDateError={nextReviewDateError}
              setnextReviewDateError={setnextReviewDateError}
              endDateError={endDateError}
              setEndDateError={setEndDateError}
              businessErr={businessErr}
              setBusinessErr={setBusinessErr}
              startErr={startErr}
              setStartErr={setStartErr} 
              endErr={endErr}
              setEndErr={setEndErr}
              requestorError={requestorError}
              setRequestorErrorColor={setRequestorErrorColor}
              // setBudgetErrorColor={setBudgetErrorColor}
              setSelectedRequestor={setSelectedRequestor}
              selectedRequestor={selectedRequestor}
              // budgetError={budgetError}
              selectedBudget={selectedBudget}
              setSelectedBudget={setSelectedBudget}
              statusErr={statusErr}
              setStatusErr={setStatusErr}
              substatusErr={substatusErr}
              setSubStatusErr={setSubStatusErr}
              setNumberErr={setNumberErr}
              numberErr={numberErr}
            />
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default CreateIndex;
