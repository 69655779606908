
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Field, reduxForm, formValueSelector, change, SubmissionError } from 'redux-form';
import Cookies from "universal-cookie";
import swal from 'sweetalert';
import CommonHeaderMenu from "../../common/CommonHeaderMenu";
import Swal from "sweetalert2";
import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import { MyContext } from '_MyContext';

const cookie = new Cookies();
let dateformat = cookie.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

const Header = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const translator = useSelector((state) => state.spcmReducer.tr);
	const [saveLoader, setSaveLoader] = useState(false);
	const context = useContext(MyContext);
    const closeButton = useRef(null);
    const saveObjRef = useRef(null);

	useEffect(() => {
		context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
		context.newTabPrevent();
	}, []);

	function submit(values) {
		let submitFlag = true;
		if (!values.group) {
			props.setGroupErrorState('error');
			submitFlag = false;
		} else {
			props.setGroupErrorState('');
		}
		if (!values.shift) {
			props.setShiftErrorState('error');
			submitFlag = false;
		} else {
			props.setShiftErrorState('');
		}
		if (!values.startDate) {
			props.setStartDateErrorState('error');
			submitFlag = false;
		} else {
			props.setStartDateErrorState('');
		}
		if (!values.endDate) {
			props.setEndDateErrorState('error');
			submitFlag = false;
		} else {
			props.setEndDateErrorState('');
		}
		if(submitFlag == false)
			return false;
		if (values.startDate.format("X") > values.endDate.format("X")) {
			Swal.fire("To should be greater than from!");
			return;
		}
		if (moment(values.startDate.format("YYYY-MM-DD")).format("X") < moment(moment().format("YYYY-MM-DD")).format("X")) {
			Swal.fire("Past dates are not allowed!");
			return;
		}
		let postObj = {};
		postObj["rosterName"] = values.shiftName + ": " + moment(values.startDate).format(jwtdf) + "_" + moment(values.endDate).format(jwtdf);
		postObj["shiftId"] = values.shift;
		postObj["startDate"] = values.startDate.valueOf();
		postObj["endDate"] = values.endDate.valueOf();
		postObj["sequence"] = 0;
		postObj["status"] = "Active";
		postObj["companyId"] = values.companyId;
		postObj["companyName"] = values.companyName;
		
		setSaveLoader(true);
		axios.post('/rest/aiosem/saveRosterAction', postObj).then(function (response) {
			if (response.status == 200) {
				setSaveLoader(false);
				navigate("/onCallSchedule/editRoster/" + response.data.data.rosterId.toString());
			}
		}).catch(function (error) {
			setSaveLoader(false);
			swal({
				text: error.response.data.businessMessage,
				button: translator['OK'],
			});
		});
	}

	return (
		<Row className="margin-b-15">
			<Col lg={6} md={6} sm={12} xs={12}>
			<CommonHeaderMenu active="Roster"/>
			</Col>
			<Col lg={6} md={6} sm={12} xs={12}>
				<div className="paHedFilter">
					<Link to="javascript:void(0)" bsPrefix=' ' id="creTag" className="myBt plus ctrlKeyPrevent" disabled={saveLoader} onClick={props.handleSubmit(submit)} title={translator['Save']} ref={saveObjRef}>{saveLoader ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/>}</Link>
					<Link to="/onCallSchedule/roster" bsPrefix=' ' className="myBt cancel fillBtn ctrlKeyPrevent" title={translator['Cancel']} ref={closeButton} ><IoClose /></Link>
				</div>
			</Col>
		</Row>
	)
}

export default reduxForm({
	form: 'onCallScheduleCreateRosterForm',
	destroyOnUnmount: true,
	enableReinitialize: true
})(Header);