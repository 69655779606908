
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import { getMultiTenantLogo } from "_Actions/homepage/getMultiTenantLogo";
import Cookies from "universal-cookie";

const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[24];

const required = (value) => (value ? undefined : "Required");

const validate = (values) => {
  let error = {};
  if (!values.user_email) {
    error.user_email = "required";
  }
  if (values.user_email) return error;
};

const user_email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;

const renderField = ({
  input,
  label,
  type,
  style,
  meta: { touched, error },
}) => (
  <div>
    <input
      {...input}
      placeholder={label}
      type={type}
      style={style}
      className={touched && error ? "error form-control" : "form-control"}
    />
  </div>
);

class XsmLogin extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.props.getMultiTenantLogo();
  }

  render() {
    const { pristine, submitting } = this.props;
    const head = {
      backgroundColor: "#eef2f5",
      height: "70px",
      borderBottom: "1px #d2d7f2 solid",
      marginBottom: "20px",
    };
    const txt = {
      fontSize: "17px",
      fontWeight: "400",
      color: "#727272",
      textAlign: "center",
      marginBottom: "30px",
      marginTop: "50px",
    };
    const spanSec = {
      border: "1px #cccccc solid",
      borderRight: "0",
      color: "#555",
      backgroundColor: "#eee",
      padding: "6px 12px",
      display: "table-cell",
    };
    const inputBox = {
      fontSize: "14px",
      border: "1px #cccccc solid",
      color: "#555",
      backgroundColor: "#eee",
      padding: "6px 12px",
      height: "36px",
      display: "table-cell",
      borderRadius: "0",
      width: "210px",
    };
    const btnBox = {
      fontSize: "14px",
      fontWeight: "500",
      color: "#fff",
      backgroundColor: "#3884b4",
      border: "none",
      boxShadow: "none",
      display: "block",
      width: "100%",
      marginTop: "20px",
      padding: "10px",
      marginBottom: "140px",
    };

    let multiTenantLogoURL = this.props.multiTenantLogoReducer.Logo_URL;
    let logoEnabledFlag = this.props.multiTenantLogoReducer.Logo_Enabled_Flag;

    let bg = require("_Images/hcl-sx-logo-black.png");
    let logoUrl = logoEnabledFlag
      ? logoEnabledFlag == "TRUE"
        ? multiTenantLogoURL
        : bg
      : bg;

    let stylingObject = {
      logoAuth: {
        backgroundImage: `url(${logoUrl})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "contain",
        display: "inline-block",
        width: "130px",
        height: "42px",
        margin: "0px",
        textIndent: "-9999px",
      },
    };
    let acthomepage = "/login" + homepagelocation;

    return (
      <main style={{ marginTop: "-58px" }}>
        <form method="POST" action={acthomepage}>
          <div style={head} />

          {/* <div style={imgSec}><img src={logoEnabledFlag ? logoEnabledFlag == "TRUE" ? multiTenantLogoURL : Logo : null} height="119" width="250"/></div> */}
          <div style={{ textAlign: "center" }}>
            <div style={stylingObject.logoAuth}></div>
          </div>

          <div style={txt}>Welcome to HCL SX</div>
          <div style={{ textAlign: "center" }}>
            <div style={{ display: "inline-block" }}>
              <div style={{ position: "relative", display: "table" }}>
                <Form.Label for="email" style={spanSec}>
                  <i className="fa fa-user" />
                </Form.Label>
                <Field
                  id="email"
                  name="user_email"
                  type="email"
                  autocorrect="off"
                  autocapitalize="none"
                  component={renderField}
                  label="Email"
                  validate={[user_email, required]}
                  style={inputBox}
                />
              </div>
              <button
                type="submit"
                disabled={pristine || submitting}
                style={btnBox}
              >
                NEXT
              </button>
            </div>
          </div>
          {/* <div style={footerSec}>&copy; 2020, HCL</div> */}
        </form>
      </main>
    );
  }
}
// export default reduxForm({
//   form: 'xsmLoginForm',
//   validate
// })(XsmLogin);

const mapStateToProps = ({ multiTenantLogoReducer }) => {
  return {
    multiTenantLogoReducer,
  };
};

XsmLogin = reduxForm({
  form: "xsmLoginForm",
  validate,
})(XsmLogin);

export default connect(mapStateToProps, { getMultiTenantLogo })(XsmLogin);
