
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export const investigationCriticality = (state = [], action) => {
    switch(action.type) {
        case 'INVESTIGATION_CRITICALITY_LOAD_SUCCESS':
            return action.investigationCriticality.data;
        default:
            return state;
    }
};

export const investigationPriority = (state = [], action) => {
    switch(action.type) {
        case 'INVESTIGATION_PRIORITY_LOAD_SUCCESS':
            return action.investigationPriority.data;
        default:
            return state;
    }
};
export const investigationSource=(state=[],action)=>{
    switch(action.type) {
        case 'INVESTIGATION_SOURCE_LOAD_SUCCESS':
            return action.investigationSource.data;
        default:
            return state;
    }
}
export const investigationUrgency = (state = [], action) => {
    switch(action.type) {
        case 'INVESTIGATION_URGENCY_LOAD_SUCCESS':
            return action.investigationUrgency.data;
        default:
            return state;
    }
};

export const implementationPlanDetails = (state = [], action) => {
    switch(action.type) {
        case 'LOAD_IMPLEMENTATION_SUCCESS':
            return action.implementationPlan;
        default:
            return state;
    }
};

export const specificUserDetails = (state = [], action) => {
    switch(action.type) {
        case 'SPECIFIC_USER_DETAILS_SUCCESS':
            return action.payload;
        default:
            return state;
    }
};

export const timelineDataResult = (state = initialState.timelineDataResult, action) => {

    // console.log('action.timelineData - ',action.timelineData);

    switch(action.type) {
        case 'LOAD_TIMELINEDATA_SUCCESS':
                return ( (action.timelineData && action.timelineData.data) ? action.timelineData.data : [] );
        case 'LOAD_TIMELINEDATA_FAILURE':
                return action.errorResponse;
        default:
            return state;
    }
};


export const externalLinks = (state = initialState.externalLinks, action) =>{
	switch (action.type){
        case 'LOAD_EXTERNAL_LINK':
            return action.externalLinks.data;
        default:
            return state;
	}
}
