
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React,{useState} from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Row, Col, Button } from "react-bootstrap";
import { StandardTextArea } from "../../common/FormFields";

function ReasonComponent({ isHemStatus,onSubmitReason }) {
    const [initVals, setInitVals] = useState({
        description: "",
      });
      let initialValues = {
        ...initVals,
      };
    const validationSchema = Yup.object({
        description: Yup.string()
          .required("Required")
          .max(1000, "Must be 1000 characters or less"),
      });
      let placeholder={
        Cancel:'Reason',
        'Re-open':'Reason',
        Complete:'Close Notes',
        Close:'Close Notes',
        "Cancel Meeting":'Reason'
      }
      const onSubmitHoldingReason = (fields, { setStatus, setSubmitting,resetForm }) => {
        onSubmitReason(fields,setSubmitting,resetForm)
     
      };  
  return (
    <div className="panel-body attachment-body">
      <div className="custom-container-fluid">
        <div className="stickyArea rBoxStyle">
          <div className="rPageHeading d-flex p-3">
            <div className="flex-grow-1 offNam mw-100">{isHemStatus}</div>
          
          </div>
            <div className="rBoxGap">
              <div className="rBoxTabs">
                <Formik
                  enableReinitialize={true}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmitHoldingReason}
                >
                  {({
                    errors,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    handleBlur,
                    values,
                    handleChange,
                    setValues,
                    resetForm
                  }) => {
                    return (
                      <>
                        <Form noValidate id="worknotes">
                          <div className=" new-diamenton">
                            <Row>
                              <Col sm={12} className="text-right">
                                <div className="form-group position-relative">
                                  <StandardTextArea
                                    required
                                    as="textarea"
                                    rows="4"
                                    label={placeholder[isHemStatus] || 'Reason'}
                                    name="description"
                                    classNam="form-control w-100"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={12} className="text-right">
                                <div className="pull-right custom-note-submit-btn">
                                  <Button
                                    type="submit"
                                    disabled={isSubmitting}
                                    title="Submit"
                                    className="rgSidrkBtn smallBtn"
                                  >
                                    Submit
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Form>
                      </>
                    );
                  }}
                </Formik>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default ReasonComponent;
