
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Table, Nav } from "react-bootstrap";
import { getRelationshipData } from "../../../../actions/cmdb/cmdbTimelineAction";

import { IoClose } from "react-icons/io5";

import Cookies from "universal-cookie";
import Swal from "sweetalert2";
const cookies = new Cookies();
let app_url = cookies.get("gph");
if (app_url) app_url = app_url.replace("s:", "");
if (app_url) app_url = app_url.substring(0, app_url.lastIndexOf("."));
app_url = app_url.split("~");
app_url = app_url[18];
let cookiesData = cookies.get('gph');
if (cookiesData) cookiesData = cookiesData.replace('s:', '');
if (cookiesData) cookiesData = cookiesData.substring(0, cookiesData.lastIndexOf('.'));
cookiesData = cookiesData.split("~");
let onRoleVisible = cookiesData[33] ? cookiesData[33].split(',') : [];
const CMDBRelationship = (props) => {
  const { module, setModule } = props;
  const dispatch = useDispatch();

  const cmdbRelationshipData = useSelector(
    (state) => state?.cmdbRelationshipData?.relationshipData
  );

  const translator = useSelector((state) => state.spcmReducer.tr);

  // const [module, setModule] = useState("Breakfix");

  const changeModule = (e) => {
    setModule(e.target.value);
    // getRelationshipData(props.ciId, e.target.value);
  };


  const CellName = (objectData) => {
    let url = "";
    if (props.module == "Item") {
      let hasAccess = !(
        onRoleVisible.includes("43") ||
        onRoleVisible.includes("44") ||
        onRoleVisible.includes("62") ||
        onRoleVisible.includes("48")
      );
      let url = app_url + "/home?fulFillment=" + objectData.CI_ID_RELATED;
      return (
        <>
          {!hasAccess && (
            <a target="_blank" href={url} onClick={() => {}}>
              {" "}
              {objectData.CI_ID_NUMBER}
            </a>
          )}
          {hasAccess && (
            <a
              href="#"
              onClick={() => {
                Swal.fire(
                  "You do not have the required role to view this ticket"
                );
              }}
            >
              {" "}
              {objectData.CI_ID_NUMBER}
            </a>
          )}
        </>
      );
    } else if (props.module == "ChangeManagement") {
      let hasAccess = !(
        onRoleVisible.includes("19") ||
        onRoleVisible.includes("20") ||
        onRoleVisible.includes("65") ||
        onRoleVisible.includes("51") ||
        onRoleVisible.includes("52") ||
        onRoleVisible.includes("48")
      );
      let url = app_url + "/home?editChange=" + objectData.CI_ID_RELATED;
      return (
        <>
          {!hasAccess && (
            <a target="_blank" href={url} onClick={() => {}}>
              {" "}
              {objectData.CI_ID_NUMBER}
            </a>
          )}
          {hasAccess && (
            <a
              href="#"
              onClick={() => {
                Swal.fire(
                  "You do not have the required role to view this ticket"
                );
              }}
            >
              {" "}
              {objectData.CI_ID_NUMBER}
            </a>
          )}
        </>
      );
    } else if (props.module == "Investigation") {
      let hasAccess = !(
        onRoleVisible.includes("16") ||
        onRoleVisible.includes("17") ||
        onRoleVisible.includes("64") ||
        onRoleVisible.includes("48") ||
        onRoleVisible.includes("53")
      );
      let url = app_url + "/home?investigationEdit=" + objectData.CI_ID_RELATED;
      return (
        <>
          {!hasAccess && (
            <a target="_blank" href={url} onClick={() => {}}>
              {" "}
              {objectData.CI_ID_NUMBER}
            </a>
          )}
          {hasAccess && (
            <a
              href="#"
              onClick={() => {
                Swal.fire(
                  "You do not have the required role to view this ticket"
                );
              }}
            >
              {" "}
              {objectData.CI_ID_NUMBER}
            </a>
          )}
        </>
      );
    } else if (props.module == "Breakfix") {
      let hasAccess = !(
        onRoleVisible.includes("12") ||
        onRoleVisible.includes("13") ||
        onRoleVisible.includes("14") ||
        onRoleVisible.includes("48") ||
        onRoleVisible.includes("63")
      );
      let url = app_url + "/home?editBreakFix=" + objectData.CI_ID_RELATED;
      return (
        <>
          {!hasAccess && (
            <a target="_blank" href={url} onClick={() => {}}>
              {" "}
              {objectData.CI_ID_NUMBER}
            </a>
          )}
          {hasAccess && (
            <a
              href="#"
              onClick={() => {
                Swal.fire(
                  "You do not have the required role to view this ticket"
                );
              }}
            >
              {" "}
              {objectData.CI_ID_NUMBER}
            </a>
          )}
        </>
      );
    }

    return (
      <div className="text-c">
        <a
          href={url}
          target="_blank"
          className="hyperLinkCntrct"
          title={props.translator["Open in new tab"]}
        >
          {objectData.CI_ID_NUMBER}
        </a>
      </div>
    );
  };

  const renderRelationshipList = (cmdbRelationshipData) => {

    if (cmdbRelationshipData && cmdbRelationshipData?.length === 0) {
      return (
        <tr>
          <td className="text-c" colSpan={3}>
            No Data Available
          </td>
        </tr>
      );
    }

    return cmdbRelationshipData?.map((objectData) => {
      if (objectData?.CI_ID_NUMBER !== 0)
        //console.log(objectData, "objectData")
        return (
          <tr key={objectData.CI_ID_NUMBER}>
            <td>{CellName(objectData)}</td>
            <td>{objectData.CI_ID_REPORTED}</td>
            <td>{objectData.CREATED_BY}</td>
          </tr>
        );
    });
  };

  useEffect(() => {
    dispatch(getRelationshipData(props.ciId, module, "No"));
  }, [module]);

  return (
    <div className="clDiv Ovrflow StTimeline">
      <Row>
        <Col xs={12}>
          <div
            className="rPageHeadActBtn d-md-none"
            style={{ marginTop: "-3px" }}
          >
            <ul>
              <li>
                <button
                  type="button"
                  title="Minimize the right panel"
                  bsClass=""
                  bsStyle=""
                  className="closerightPanelBtn"
                  onClick={() => {
                    props?.rightEditPanel(false);
                  }}
                >
                  <IoClose />
                </button>
              </li>
            </ul>
          </div>
          <div
            className={props?.treeview ? "rPageHeading d-flex" : "rPageHeading"}
          >
            <div className="offNam margin-t-5 margin-b-5">
              {translator["Related Work Items"]}
            </div>
            {props?.treeview && (
              <div className="toprigLnk mt-0 ms-auto">
                <Nav className="icnlnk" as="ul">
                  <Nav.Item as="li">
                    <a className="border-none" onClick={props?.handleShowhide}>
                      <i className="fa fa-close text-white" />
                    </a>
                  </Nav.Item>
                </Nav>
              </div>
            )}
          </div>
        </Col>
      </Row>

      <div className="rBoxGap">
        <Row>
          <Col md={6}>
            <Form.Label>{translator["Search by"]}</Form.Label>
          </Col>
          <Col md={6}>
            <Form.Select
              value={module}
              componentClass="select"
              onChange={changeModule}
            >
              <option value="Breakfix">{translator["Break-Fix"]}</option>
              <option value="Investigation">{translator["Problem"]}</option>
              <option value="ChangeManagement">{translator["Change"]}</option>
              <option value="Item">{translator["Fulfillment"]}</option>
            </Form.Select>
          </Col>
        </Row>
        <Table
          bordered
          condensed
          hover
          responsive
          className="tableView sortImg margin-xs-t-15"
        >
          <thead>
            <tr>
              <th width="40%">{translator["Ticket No."]}</th>
              <th width="30%">{translator["Related On"]}</th>
              <th width="30%">{translator["Related By"]}</th>
            </tr>
          </thead>
          <tbody>{renderRelationshipList(cmdbRelationshipData)}</tbody>
        </Table>
      </div>
    </div>
  );
};

export default CMDBRelationship;
