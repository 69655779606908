
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export const categoryBoardList = (state = {}, action) => {
    switch (action.type) {
        case 'LOAD_CATEGORY_BOARD_LIST_SUCCESS':
            return action.categoryBoardList.data;
        case 'EMPTY_CATEGORY_BOARD_LIST_REDUCER':
            return {};
        default:
            return state;
    }
}

export const categoryDropdown = (state = {}, action) => {
    switch (action.type) {
        case 'LOAD_CATEGORY_DROPDOWN_SUCCESS':
            return action.categoryDropdownList.data;
        default:
            return state;
    }
}

export const CategoriesByCompanyIdDropdown = (state = {}, action) => {
    switch (action.type) {
      case "CATEGORIES_BY_COMPANY_ID":
        if (
          Array.isArray(action.categoryByCompanyIdDropdownList.data) &&
          action.categoryByCompanyIdDropdownList.data.length > 0
        ) {
          return action.categoryByCompanyIdDropdownList.data;
        } else {
          return [];
        }
      case "CATEGORIES_BY_COMPANY_ID_REFRESH":
        return "";
      default:
        return state;
    }
}

export const statusDropdown = (state = {}, action) => {
    switch (action.type) {
        case 'LOAD_STATUS_DROPDOWN_SUCCESS':
            return action.statusDropdownList.data;
        default:
            return state;
    }
}

export const categoryEditDetails = (state = {}, action) => {
    switch (action.type) {
        case 'LOAD_CATEGORY_EDIT_DETAILS_SUCCESS':
            return action.categoryEditDetails.data;
        case 'EMPTY_CATEGORY_EDIT_DETAILS_REDUCER':
            return {};
        case 'LOAD_CATEGORY_EDIT_DETAILS_SUCCESS_2':
            return action.payload;
        default:
            return state;
    }
}

export const associatedCompanies = (state = {}, action) => {
    switch (action.type) {
        case 'LOAD_COMPANY_ASSOCIATION_SUCCESS':
            return action.companyAssociationList.data;
        case 'EMPTY_COMPANY_ASSOCIATION_REDUCER':
            return {};
        default:
            return state;
    }
}

export const showLoaderCICategory = (state = {}, action) => {
    switch (action.type) {
        case 'SHOW_CREATE_CI_LOADER':
            return action.payload;
        default:
            return true;
    }
}
export const ciCategoryListData = (state = {}, action) => {
    switch (action.type) {
        case 'CICATEGORY_DATA_FETCHED_SUCCESSFULLY':
            return action.payload;
        default:
            return state;
    }
}
export const ciCategoryListDataLoader = (state = {  
    loading: false,
    message: ''
  }, action) => {
    switch (action.type) {
      case 'CICATEGORY_LOADER_IN_PROGRESS':
        return Object.assign({},
          state, {
          loading: true,
          message: 'loading...'
        });
      case 'CICATEGORY_LOADER_DETAILS_FETCHED_SUCCESSFULLY':
        return Object.assign({}, state, {
          loading: false,
          message: ''
        });
      default:
        return state;
    }
  };