
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, {useEffect, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import EditTaskIndex from "./EditTaskIndex";

export default function EditTaskMain() {
  const [taskParams, setTaskParams] = useState({breakFixItemId: ""});
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  useEffect(() => {
    // console.log('params - ', params);
    setTaskParams({cId: params.cId, gId: params.gId, taskId: params.taskId});
  }, []);
  useEffect(() => {
    // console.log('updated params - ', params);
    if(params.taskId !== taskParams.taskId){
      setLoading(true);
      setTimeout(() => {
        setTaskParams({cId: params.cId, gId: params.gId, taskId: params.taskId});
        setLoading(false);
      }, 200);
    }
  }, [params.taskId]);
  // console.log('taskParams - ', taskParams);
//   console.log('params - ', params);
  if(isLoading){
    return null;
  }
  return <EditTaskIndex navigate={navigate} params={params} />;
}
