
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const fileobjectGenerator=(formatList)=>{
    let typeObj={};
    formatList.map((item)=>{
        if(item==='.txt'){
            typeObj['text/plain']=['.txt']
        }else if(item==='.doc'){
            typeObj['application/msword']=['.doc','.docx']
        }else if(item==='.xls'){
            typeObj['application/vnd.ms-excel']=['.xls','.xlsx']
        }else if(item==='.pdf'){
            typeObj['application/pdf']=['.pdf']
        }else if(item==='.jpg'){
            typeObj['image/jpeg']=['.jpeg','.jpg']
        }else if(item==='.png'){
            typeObj['image/png']=['.png']
        }else if(item==='.ico'){
            typeObj['image/vnd.microsoft.icon']=['.ico']
        }else if(item==='.xml'){
            typeObj['application/xml']=['.xml']
        }else if(item==='.tif'){
            typeObj['image/tiff']=['.tif']
        }else if(item==='.zip'){
            typeObj['application/zip']=['.zip']
        }else if(item==='.rar'){
            typeObj['application/vnd.rar']=['.rar']
        }else if(item==='.csv'){
            typeObj['text/csv']=['.csv']
        }
        // else if(item==='.msg'){
        //     typeObj['application/vnd.ms-outlook']=['.msg']
        // }else if(item==='.ldif'){
        //     typeObj['text/ldif']=['.ldif']
        // }else if(item==='.log'){
        //     typeObj['binary/octet-stream']=['.log']
        // }else if(item==='.sql'){
        //     typeObj['text/sql']=['.sql']
        // }
        else{
            typeObj['application/octet-stream']=['.msg','.ldif','.log','.sql']
        }
    });
    return typeObj;
}

export const excludedExt = ['jfif'];
export const validateFile = (file)=>{
    const pathExt = file.path?file.path.split('.'):[];
    if(pathExt.length>0 && typeof pathExt[pathExt.length-1] !=="undefined"){
      if(excludedExt.includes(pathExt[pathExt.length-1])){
        return true;
      }
    }
    return null;
}