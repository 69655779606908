
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';

class ProcessHierarchy extends React.Component{
	render(){
		const tr=this.props.tr;
		return(
			<div className='text-c'><img src={require('../../../images/service-diagram.png')} title={tr["Process Hierarchy"]} /></div>
		);
	}
}
export default ProcessHierarchy;