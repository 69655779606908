
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Grid, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
// import Header from '../../../components/Header.js';
import EditCategoryHeader from "./EditCategoryHeader";
import EditCategoryForm from "./EditCategoryForm";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
//import CompanyAssociation from './companyAssociation.js';
import {
  loadEditCategoryDetails,
  resetEditCategoryReducer,
  resetAssociatedCompanyReducer,
} from "../../../../actions/categoryBoard/quickViewAction";
import { getServiceDetailsDataList, resetServiceDetailsDataList } from "../../../../actions/spcmActions";
import Cookies from "universal-cookie";
import { navigationHooks } from "../../../../helpers/NavigationHook";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import EditCategoryShortDesc from "./EditCategoryShortDesc";
import "_Css/form/_form.scss";

const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home") homepagelocation = "/" + homepagelocation;

class EditCategoryIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenu: "4",
      showHideAlfy: false,
      showHideRightNav: false,
      mobileLeftMenuFilter: false,
      businessFnError: "",
      selectedBusinessFn: [],
      rightSideView: 'offeringList',
      isRightEditPanel: true,
    };
    this.showHideAlfy = this.showHideAlfy.bind(this);
    this.showHideRightNav = this.showHideRightNav.bind(this);
    this.setRightSideView = this.setRightSideView.bind(this);
    this.setBusinessFnErrorColor = this.setBusinessFnErrorColor.bind(this);
    this.setSelectedBusinessFn = this.setSelectedBusinessFn.bind(this);
    this.loadHome = this.loadHome.bind(this);
    this.rightEditPanel=this.rightEditPanel.bind(this);
  }
  loadHome() {
    navigationHooks.navigate(homepagelocation);
  }

  rightEditPanel(value) {
		this.setState({ isRightEditPanel: value });
	}

  componentWillMount() {
    console.log("id=====", navigationHooks.params.categoryId);
    this.props.resetEditCategoryReducer();
    this.props.resetAssociatedCompanyReducer();
    this.props.loadEditCategoryDetails(navigationHooks.params.categoryId);
    this.props.getServiceDetailsDataList({}, [], [navigationHooks.params.categoryId], [], [], "", "asc", "Service", "1", "100000");
  }

  componentWillUnmount() {
    this.props.resetServiceDetailsDataList();
  }

  setBusinessFnErrorColor() {
    if (this.state.selectedBusinessFn.length > 0) {
      this.setState({ businessFnError: "" });
    } else {
      this.setState({ businessFnError: "error" });
    }
  }

  setSelectedBusinessFn(value) {
    this.setState({ selectedBusinessFn: value });
  }

  showHideAlfy(value) {
    this.setState({ showHideAlfy: value });
  }

  showHideRightNav(value) {
    this.setState({ showHideRightNav: !this.state.showHideRightNav });
  }

  mobileLeftMenuFilter() {
    this.setState({ mobileLeftMenuFilter: !this.state.mobileLeftMenuFilter });
  }

  setRightSideView(value) {
    this.setState({
      rightSideView: value,
    });
  }
  render() {
    return (
      <div>
        <div className="minHeightQA">
          <div className="container-fluid margin-t-10 margin-b-15">
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <a
                  role="button"
                  href="javascript:void(0)"
                  onClick={this.loadHome}
                >
                  {this.props.translator["Home"]}
                </a>
              </li>
              <li className="breadcrumb-item">
                <Link
                  title={this.props.translator["Category Board"]}
                  to="/categoryBoard"
                >
                  {this.props.translator["Category Board"]}
                </Link>
              </li>
              <li className="breadcrumb-item active">
                {this.props.translator["Edit"]}
              </li>
            </ul>
          </div>
          <div className="container-fluid" bsClass="">
            <EditCategoryHeader
              translator={this.props.translator}
              setRightSideView={this.setRightSideView}
              categoryId={navigationHooks.params.categoryId}
              setBusinessFnErrorColor={this.setBusinessFnErrorColor}
              rightSideView={this.state.rightSideView}
              isRightEditPanel={this.state.isRightEditPanel}
              rightEditPanel={this.rightEditPanel}
            />
            <PanelGroup direction="horizontal">
              <Panel
                id="sidebar"
                minSize={33}
                order={1}
                defaultSize={this.state.isRightEditPanel ? 67 : 100}
                className={this.state.isRightEditPanel ? "isShowLeftPanel catBordDv" : "catBordDv"}
              >
                <EditCategoryForm
                  translator={this.props.translator}
                  categoryId={navigationHooks.params.categoryId}
                  catPinned={navigationHooks.params.catPinned}
                  setBusinessFnErrorColor={this.setBusinessFnErrorColor}
                  businessFnError={this.state.businessFnError}
                  setSelectedBusinessFn={this.setSelectedBusinessFn}
                  selectedBusinessFn={this.state.selectedBusinessFn}
                />
              </Panel>
              {this.state.isRightEditPanel ?
							<>
							<PanelResizeHandle className="isShowLeftPanel resizeHandle">
                <div className="outlne">
                  <div className="handIcn">
                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                  </div>
                </div>
              </PanelResizeHandle>
              <Panel minSize={33} order={2} defaultSize={33}>
              <div className="stickyArea rBoxStyle">
                <EditCategoryShortDesc rightEditPanel={this.rightEditPanel}/>
              </div>
              </Panel>
							</> : ""
							}              
            </PanelGroup>
          </div>
        </div>
      </div>
    );
  }
}

export function mapStateToProps({
  slaDefEditDetails,
  spcmReducer,
  slaDefActiveMandatory,
}) {
  return {
    slaDefEditDetails,
    translator: spcmReducer.tr,
    slaDefActiveMandatory,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadEditCategoryDetails,
      resetEditCategoryReducer,
      resetAssociatedCompanyReducer,
      getServiceDetailsDataList,
      resetServiceDetailsDataList
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCategoryIndex);
