
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

import PindCategories from "../../../common/header/pind-categories";
import Breadcrumbs from "../../../common/header/breadcrumbs";
import Header from "./scheduleEditHeader";
import FormComponent from "./scheduleEditForm";
import ScheduleAssociationList from "./scheduleAssociation/scheduleAssociationList";
import ScheduleAssociationForm from "./scheduleAssociation/scheduleAssociationForm";
import RelatedSchedule from "./scheduleAssociation/relatedSchedule";
import { useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import "_Css/form/_form.scss";
const cookies = new Cookies();

const Index = () => {
  const translator = useSelector((state) => state.spcmReducer.tr);
  const scheduleDetails = useSelector((state) => state.on_call_schedule_reducer.scheduleDetailsByScheduleId);
  const [scheduleNameErrorState, setScheduleNameErrorState] = useState('');
  const [scheduleTypeErrorState, setScheduleTypeErrorState] = useState('');
  const [timeZoneErrorState, setTimeZoneErrorState] = useState('');
  const { scheduleId } = useParams();
  const [actionView, setActionView] = useState('scheduleAssociationList');
  const [addEditMode, setAddEditMode] = useState('add');
  const [editFormValues, setEditFormValues] = useState({});
  const [hideButtons,setHideButtons] = useState(false);

  useEffect(()=>{
    if(scheduleDetails){
      console.log("===scheduleDetails",scheduleDetails);
      if(scheduleDetails.scheduleType!=="On-call" && scheduleDetails.scheduleType!=="Out of Office"){
        setHideButtons(true);
      }
    }
	},[scheduleDetails]);

  function setAddEditModeFun(val) {
    setAddEditMode(val)
  }

  function setEditFormValuesFun(val) {
    let obj = {
      saId: val.saId,
      scheduleName: val.scheduleAssociationName,
      scheduleType: val.scheduleType,
      onCallStatus: val.scheduleStatus,
      scheduleStartDate: val.is24by7 ? val.scheduleStartDate.slice(0, 10) : val.scheduleStartDate,
      scheduleEndDate: val.is24by7 ? val.scheduleEndDate.slice(0, 10) : val.scheduleEndDate,
      allDaySchedule: '',
      scheduleRepeatType: val.scheduleRepeatType,
      scheduleRepeat: val.scheduleRepeat,
      byDay: val.byDay,
      scheduleStartTime: val.scheduleStartTime,
      scheduleEndTime: val.scheduleEndTime,
      is24by7: val.is24by7
    };
    setEditFormValues(obj);
  }

  function renderRightSide(actionView) {
    switch (actionView) {
      case "scheduleAssociationList":
        return <ScheduleAssociationList hideButtons={hideButtons} scheduleId={scheduleId} setActionViewFun={setActionViewFun} setAddEditModeFun={setAddEditModeFun} setEditFormValuesFun={setEditFormValuesFun} />
      case "scheduleAssociationForm":
        return <ScheduleAssociationForm scheduleId={scheduleId} setActionViewFun={setActionViewFun} addEditMode={addEditMode} initialValues={addEditMode == 'edit' ? editFormValues : {}} />
      case "relatedSchedule":
        return <RelatedSchedule scheduleId={scheduleId} setActionViewFun={setActionViewFun} />
    }
  }

  function setActionViewFun(val) {
    setActionView(val);
  }

  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Breadcrumbs activePageName="Edit" parentPageurl='/onCallSchedule/schedule' parentPageName="On Call Schedule" />
      </Container>

      <Container fluid>
        <Header hideButtons={hideButtons} scheduleId={scheduleId} setScheduleNameErrorState={setScheduleNameErrorState} setScheduleTypeErrorState={setScheduleTypeErrorState} setTimeZoneErrorState={setTimeZoneErrorState} setActionViewFun={setActionViewFun} />
        <PanelGroup direction="horizontal" className="panelOverflow">
          <Panel id="sidebar" minSize={30} order={1} defaultSize={67} className={"isShowLeftPanel catBordDv"}>
            <FormComponent hideButtons={hideButtons} scheduleId={scheduleId} scheduleNameErrorState={scheduleNameErrorState} scheduleTypeErrorState={scheduleTypeErrorState} timeZoneErrorState={timeZoneErrorState} setScheduleNameErrorState={setScheduleNameErrorState} setScheduleTypeErrorState={setScheduleTypeErrorState} setTimeZoneErrorState={setTimeZoneErrorState} setActionViewFun={setActionViewFun} />
          </Panel>
          <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
          <Panel minSize={33} order={2} defaultSize={33}>
            {renderRightSide(actionView)}
          </Panel>
        </PanelGroup>

      </Container>
    </main>
  )
}

export default Index;
