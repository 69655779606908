
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { Component } from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from "react-bootstrap/Carousel";
import { connect } from 'react-redux';
import axios from 'axios';
import _ from 'lodash';

import ListLoader from '../../../common/loaders/ListLoader';

import { loadBreakFixStatusDetails } from '../../../../actions/breakFix/breakFixStatusAction';
import { loadBreakFixEditDetailsUpdate } from '../../../../actions/breakFix/breakFixEditDetailsAction';
import {checkRelatedTaskForItem} from '../../../../actions/task/TaskDataAction';
import { loadTimelineData } from '../../../../actions/common/commonApisActions';

class GenericTimeline extends Component {
    constructor(props) {
        super(props);
    }
    componentWillMount() {
        this.props.loadTimelineData(this.props.module,this.props.itemId);
    }
    renderCurrentCarouselItems(allStatuses,carouselCount) {
        let currentCarouselItems=[];
        for(let i=1;i<=5;i++){
          let currentIndex=(carouselCount*5)+i-1;
          if(currentIndex < allStatuses.length){
            let item = <li className={currentIndex == 0 ? "mActive" : "mdisabled"}>
                            <span className="box">
                                <span className="step1">
                                    <span className="step2"></span>
                                </span>
                            </span>
                            <div className="txt">
                                <div className="head">
                                    <span>{currentIndex == 1 ? 3 : 1}</span>{allStatuses[currentIndex].itemStatus}
                                </div>
                                <div className="time">{allStatuses[currentIndex].createdOn}</div>
                                <div className="name">{allStatuses[currentIndex].createdByName}</div>
                            </div>
                        </li>;
            currentCarouselItems.push(item);
          }
        }
        return currentCarouselItems;
    }
    renderBreakFixButtons1(timelineDataResult) {
        let carouselItems=[];
        let allStatuses=timelineDataResult.pastStatusList;
        if(allStatuses) {
          let totalCarouselItems;
          let len=allStatuses.length;
          if(len%5 > 0)
            totalCarouselItems=(len/5)+1;
          else
            totalCarouselItems=(len/5);         
          let carouselCount=0;
          for(let i=1;i<=totalCarouselItems;i++) {
            let carouselItem = <Carousel.Item bsPrefix="">
                                   <ul className="progressbarDiv">
                                       {this.renderCurrentCarouselItems(allStatuses,carouselCount)}
                                   </ul> 
                                </Carousel.Item>
            carouselItems.push(carouselItem);
            carouselCount++;
          }
        }
        return carouselItems;
    }
    renderBreakFixButtons(timelineDataResult) {
        return(<div className="timelineCSS position-re">
                 <div className="progressbarDivOuter">
                   <span title="Close Timeline" onClick={()=>{this.props.showTimeline(false)}} className="progressClose cursorPoint">X</span>
                     <Carousel controls={false} interval={500000000} onSelect={this.props.handleSelect1} bsPrefix="">
                         {this.renderBreakFixButtons1(timelineDataResult)}
                     </Carousel>
                 </div>
              </div>);    
    }
    render() { 
        if(this.props.isFetching){
          return (<div className="margin-b-20"><ListLoader/></div>);
        }
        return (<Row className="margin-b-20">
                  <Col xs={12}>
                    {this.renderBreakFixButtons((this.props.timelineDataResult) ? this.props.timelineDataResult : {})}
                  </Col>
               </Row>);
    }
}
const mapStateToProps = ({ breakFixStatusDetails, breakFixEditDetails, realatedTaskCount,isFetchingTimeline, timelineDataResult }) => {
    return { breakFixStatusDetails, breakFixEditDetails, realatedTaskCount,isFetching:isFetchingTimeline.isFetching, timelineDataResult };
}
export default connect(mapStateToProps, { loadBreakFixStatusDetails, loadBreakFixEditDetailsUpdate, checkRelatedTaskForItem, loadTimelineData })(GenericTimeline);