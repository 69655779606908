
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from 'react';
import { Table,Form,ButtonToolbar,Button} from 'react-bootstrap';
import {Tab,Tabs} from 'react-bootstrap';
import axios from "axios";
import { TypeaheadExampleSingleSelect, TypeaheadandDropdown, _dropDown, _inputField } from '../../../../common/formFields';
import { Field, change, reduxForm } from 'redux-form';
import { getMenuListCompanyAction, getAssociateCompanyDropdown, disassociateCompanyRow, getAssocitedCompanyDropDownValue, disassociateConsumerCompanyRow } from '../../../../../actions/foundation/companyAction';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { usePreviousValue } from '../../../../../helpers/usePreviousValue';
import TableComponent from '../../../../common/ReactTable/TableComponent';
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom';

const cookies = new Cookies();

let app_url = cookies.get("gph");
if (app_url) app_url = app_url.replace("s:", "");
if (app_url) app_url = app_url.substring(0, app_url.lastIndexOf("."));
app_url = app_url.split("~");
app_url = app_url[18];

let cookiesArray = cookies.get('gph');
if (cookiesArray) cookiesArray = cookiesArray.replace('s:', '');
if (cookiesArray) cookiesArray = cookiesArray.substring(0, cookiesArray.lastIndexOf('.'));
cookiesArray = cookiesArray.split("~");
let associatedCompanyIdValues = cookiesArray[57];

const AssociateCompanyList = (props) => {
    const [companyData, setCompanyData] = useState([]);
    const [selectedValueList, setSelectedValueList] = useState([]);
    const [assoComapnyValue, setAssoComapnyValue] = useState([])
    const [selectedCompany, setSelectedCompany] = useState([]);
    const [typeError, setTypeError] = useState("");
    const [companyError, setCompanyError] = useState("");
    const [assoTypeError, setAssoTypeError] = useState("");
    const [associationType, setAssociationType] = useState([]);  
    const [assoTypeData, setAssoTypeData] = useState([]); 
    const [consumerCompanyList, setConsumerCompaniesList] = useState([]);
    const [key, setKey] = useState(1);  
    const [typeValue, setTypeValue] = useState([]);
    const previousValue = usePreviousValue(props)

    const dispatch = useDispatch();
    const typeReducerData = useSelector((state) => state.menuListFDNReducer);
    const serviceConsumerTypeData = useSelector((state) => state.serviceConsumerMenuList);
    const notServiceConsumerTypeData = useSelector((state) => state.notServiceConsumerMenuList);
    const companyList = useSelector((state) => state.associateCompanyDropdown);
    const disassociateCompanyData = useSelector((state) => state.disassociatedcompany);
    const dissAssoConsumerCompanyData = useSelector((state) => state.disassociateConsumerCompany)
    const associationTypeOptions = useSelector((state) => state.associationTypeMenuList);
    
    // const appAdminCompanies = associatedCompanyIdValues.split(',');

  useEffect(() => { 
    if (selectedValueList.length !== 0) {
      if (Object.keys(props.companyValue).length > 0) {
        let companyObj = {
          companyName: props.companyValue.name,
          companyId: props.companyValue.id,
          associateCompanyType: selectedValueList[0].label
        }
        dispatch(getAssociateCompanyDropdown(companyObj));
      }
    }
    },[props.companyValue, selectedValueList])



  useEffect(() =>
    {
      if(associationTypeOptions)
      {
        setAssoTypeData(associationTypeOptions)
      }
      
  }, [associationTypeOptions])
  
  useEffect(() => {
    if (props.isSuperAdmin  == 'Y') {
      dispatch(getMenuListCompanyAction(''));
    }
    }, []);

    useEffect(() =>
    {

      if(companyList && companyList.length > 0)
      {
        setCompanyData(companyList)
      }
      
    }, [companyList])
    
    const onCrossClick = () => {
      setSelectedValueList([]);
      setSelectedCompany([]);
      setAssociationType([]);
      setCompanyData([])
    }
    const onCrossClickCompany = () => {
      setSelectedCompany([]);
      setAssociationType([]);
    }
    const onValueSelection = (selected) => {
       
        let error = '';
        if (selected.length === 0) {
            error = 'error';
        }
        let arr = [];
        setSelectedValueList(selected);
        selected.map((val) => {
            arr.push(val.label)
        })
        setTypeError("")
  };
  
    useEffect(() => {
      if(Object.keys(props.companyValue).length>0){
        if ((props?.companyValue!==undefined  && props?.companyValue?.id && props?.companyValue?.id !== previousValue?.companyValue?.id) || disassociateCompanyData === 'Row Deleted' ) {
          axios.get("/api/viewAssociatedCompaniesList/" + props.companyValue.id)
              .then((resp) => {
                  setAssoComapnyValue(resp.data);
                  dispatch({ type: 'ASSOCIATED_COMPANY_DELETED', payload: ''});
              })
      }
      }
    }, [props.companyValue, disassociateCompanyData])
    
  useEffect(() => {
      if(Object.keys(props.companyValue).length>0){
        if ((props?.companyValue!==undefined  && props?.companyValue?.id && props?.companyValue?.id !== previousValue?.companyValue?.id) || dissAssoConsumerCompanyData === 'Row Deleted' ) {
          axios.get("/api/viewConsumerCompanies/" + props.companyValue.id)
              .then((resp) => {
                setConsumerCompaniesList(resp.data);
                  dispatch({ type: 'ASSOCIATED_CONSUMER_COMPANY_DELETED', payload: ''});
              })
      }
      }
    }, [props.companyValue, dissAssoConsumerCompanyData])
  
  
  const saveAssociateCompany = () => {
    let isValid = true;
        if (selectedCompany.length === 0) {
          setCompanyError("error");
          isValid = false;
        }
        if (selectedValueList.length === 0) {
          setTypeError("error");
          isValid = false;
    }
    if (isValid == true) {
      let companies = []
      let payload = {}
      selectedCompany.forEach((values) => {
        let val = {
          companyId: props.companyValue.id,
          associatedCompanyId: values.id,
          associateCompanyType: selectedValueList[0].label
        }
        companies.push(val);
      })
      payload = {
        associatedCompaniesList: companies
      }
      axios
        .post("/api/saveAssociatedCompanies", payload)
        .then((response) => {
          console.log(response.data);
          axios.get("/api/viewAssociatedCompaniesList/" + props.companyValue.id)
            .then((resp) => {
              if (resp.status == 200) {
                setSelectedCompany([]);
                setSelectedValueList([]);
                setCompanyData([]);
              }
              setAssoComapnyValue(resp.data);
              let companyListoptions = resp.data;
              let object = [];
              if (typeof companyListoptions !== "undefined" && companyListoptions.length > 0) {
                companyListoptions.forEach((values) => {
                  let val = { id: values.id, label: values.name };
                  object.push(val);
                });
              }
              dispatch({ type: 'ASSOCIATED_COMPANY_FETCHED_FOR_DROPDOWN', payload: object });
            })
        })
    }
  };
  
  const saveConsumerCompanies = () => {
    let isValid = true;
    if (selectedCompany.length === 0) {
      setCompanyError("error")
      isValid = false;
    }
    if (selectedValueList.length === 0) {
      setTypeError("error")
      isValid = false;
    }
    if (associationType.length === 0) {
      setAssoTypeError("error")
      isValid = false;
    }
    if (isValid == true) {
      let arr = [];
      associationType.map((val) => {
        arr.push(val.label)
      })
      let payload = {}
      let companies = []
      arr.forEach((data) => {
        selectedCompany.forEach((values) => {
          let val = {
            companyId: props.companyValue.id,
            associatedCompanyId: values.id,
            associateCompanyType: data
          }
          companies.push(val);
        })
      })
        
      payload = {
        associatedCompaniesList: companies
      }
      axios
        .post("/api/saveConsumerCompanies", payload)
        .then((response) => {
          console.log(response.data);
          axios.get("/api/viewConsumerCompanies/" + props.companyValue.id)
            .then((resp) => {
              if (resp.status == 200) {
                setSelectedCompany([]);
                setSelectedValueList([]);
                setCompanyData([]);
                setAssociationType([]);
              }
              setConsumerCompaniesList(resp.data);
              
            })
        })
    }
  }
//   const CellConsumerInfo = ({ row }) => {
//     if (props.isSuperAdmin == 'Y') {
//       return (
//         <>
//           <Link className="hyperLinkWIB" title={props.tr['Open in new tab']} onClick={() => {
//             let newWindow = window.open();
//             newWindow.opener = null;
//             newWindow.location = app_url + "/home?editCompany=" + row.id;
//           }}>
//             {row.name}
//           </Link>
//         </>
//       )
//     } else {
//       let showCompaniesEditPage = false;
//       appAdminCompanies.forEach((testId) => {
//         if (testId == row.id) {
//           showCompaniesEditPage = true;
//         }
//       })
//       if (showCompaniesEditPage) {
//         return (
//           <>
//             <Link className="hyperLinkWIB" title={props.tr['Open in new tab']} onClick={() => {
//               let newWindow = window.open();
//               newWindow.opener = null;
//               newWindow.location = app_url + "/home?editCompany=" + row.id;
//             }}>
//               {row.name}
//             </Link>
//           </>
//         )
//       } else {
//         return (
//           <>
//             {row.name}
//           </>
//         )
//       }
//     }
// };
  const tableColumnsConsumer = [
    {
        name: props.tr["ID"],
        selector: (row) => row.id,
        sortable: true,
    },
    // {
    //   name: props.tr["Company"],
    //   cell: (row) => (
    //   <CellConsumerInfo row={row} 
    //           />),
    //   sortable: true,
    //  },
    {
        name: props.tr["Company"],
        selector: (row) => row.name,
        sortable: true,
        cell: (row) => (<div title={row.name}>{row.name}</div>)
    },
    {
        name: props.tr["Type"],
        selector: (row) => row.companyTypes,
        sortable: true,
        cell: (row) => (<div title={row.companyTypes}>{row.companyTypes}</div>)
    },
    {
  name: props.tr["Disassociate"],
  sortable: true,
  cell: (row) => (
    <CellConsumerName row={row} 
  />
  ),
  ignoreRowClick: true,
  },
  ];
  
  const CellConsumerName = (props) => {
    let item = props.row;
    return (
    <div className="text-c">
        <i title="Disassociate" onClick={()=>{
        disassociateConsumerCompany(item)
        }} className="fa fa-chain-broken"></i>
    </div>
    )
  };
const disassociateConsumerCompany = (item) => {
    let payload =
    {
      associatedCompaniesList: [{
            companyId: props.companyValue.id,
            associatedCompanyId: item.id,
        }]
    }
    Swal.fire({
        title: props.tr["Are you sure you want to disassociate comapny?"],
        width: 550,
        padding: '15px',
        showDenyButton: true,
        confirmButtonText: props.tr['Yes'],
        denyButtonText: props.tr['No'],
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(disassociateConsumerCompanyRow(payload))
        } 
      })
}
    const onCompanySelection=(selectedCompany)=>{
        if (selectedCompany.length > 0) { 
            setSelectedCompany(selectedCompany)
            dispatch(change('AssociateCompanyList', 'name', selectedCompany))
            setCompanyError("")
          } else {
            setSelectedCompany([]);
          }
  }
  
  const onAssoTypeSelection = (selectedType) => {
    if (selectedType.length > 0) {
      setAssociationType(selectedType)
      dispatch(change('AssociateCompanyList', 'associationType', selectedType))
      setAssoTypeError("")
    } else {
      setAssociationType([]);
    }
  }
  const onCrossClickAssoType = () => {
    setAssociationType([]);
  }
  // const CellSupportInfo = ({ row }) => {
  //   if (props.isSuperAdmin == 'Y') {
  //     return (
  //       <Link className="hyperLinkWIB" title={props.tr['Open in new tab']} onClick={() => {
  //         let newWindow = window.open();
  //         newWindow.opener = null;
  //         newWindow.location = app_url + "/home?editCompany=" + row.id;
  //       }}>
  //         {row.name}
  //       </Link>
  //     )
  //   } else {
  //     let showCompaniesEditPage = false;
  //     appAdminCompanies.forEach((testId) => {
  //       if (testId == row.id) {
  //         showCompaniesEditPage = true;
  //       }
  //     })
  //     if (showCompaniesEditPage) {
  //       return (
  //         <Link className="hyperLinkWIB" title={props.tr['Open in new tab']} onClick={() => {
  //           let newWindow = window.open();
  //           newWindow.opener = null;
  //           newWindow.location = app_url + "/home?editCompany=" + row.id;
  //         }}>
  //           {row.name}
  //         </Link>
  //       )
  //     } else {
  //       return (
  //         <>
  //           {row.name}
  //         </>
  //       )
  //     }
  //   }
  //   };
    
    const CellName = (props) => {
        let item = props.row;
        return (
        <div className="text-c">
            <i title="Disassociate" onClick={()=>{
            disassociateCompany(item)
            }} className="fa fa-chain-broken"></i>
        </div>
        )
      };
    const disassociateCompany = (item) => {
        let payload =
        {
            associatedCompaniesList: [{
                companyId: props.companyValue.id,
                associatedCompanyId: item.id,
                associateCompanyType: item.companyTypes
            }]
        }
        Swal.fire({
            title: props.tr["Are you sure you want to disassociate comapny?"],
            width: 550,
            padding: '15px',
            showDenyButton: true,
            confirmButtonText: props.tr['Yes'],
            denyButtonText: props.tr['No'],
          }).then((result) => {
            if (result.isConfirmed) {
              // removeCompanyOptions = true;
              dispatch(disassociateCompanyRow(payload))
              dispatch(getAssocitedCompanyDropDownValue(props.companyValue.id));
              setTimeout(() => {
                dispatch(getAssocitedCompanyDropDownValue(props.companyValue.id)); 
                axios.get("/api/viewAssociatedGroups/"+props.companyValue.id+"/"+'All')
                .then((resp) => {
                    props.setAssociatedGroupValue(resp.data);
                    console.log("TestAssoGrp",resp.data)
              }) }, 500);
            } 
          })
    }
      let { handleSubmit } = props;
    const onRowSelect = () => {
        
    }
    const tableColumns = [
        {
            name: props.tr["ID"],
            selector: (row) => row.id,
            sortable: true,
        },
        {
          name: props.tr["Company"],
          selector: (row) => row.name,
          sortable: true,
          cell: (row) => (<div title={row.name}>{row.name}</div>)
        },
        {
            name: props.tr["Type"],
            selector: (row) => row.companyTypes,
            sortable: true,
            cell: (row) => (<div title={row.companyTypes}>{row.companyTypes}</div>)
        },
        {
			name: props.tr["Disassociate"],
			sortable: true,
			cell: (row) => (
			  <CellName row={row} 
			/>
			),
			ignoreRowClick: true,
		  },
    ];
  const filterColumns = ["id", "name", "companyTypes"];
   
  const listCompanies = () => {
    if (props?.companyValue?.companyType && props?.companyValue?.companyType[0] == "Service Consumer" && props?.companyValue?.companyType.length == 1) {
      saveAssociateCompany();
    } else {
      if (selectedValueList[0]?.id == '7') {
        saveConsumerCompanies();
      } else {
        saveAssociateCompany();
      }
    }
  }

  useEffect(() => {
    if (Object.keys(props.companyValue).length>0) {
      let typeData = props.companyValue.companyType;
      if(typeData.length === 1 && typeData[0] === 'Service Consumer') {
        setTypeValue(notServiceConsumerTypeData);
      } else if(typeData.every(type => type !== "Service Consumer")) {
        setTypeValue(serviceConsumerTypeData);
      } else {
        setTypeValue(typeReducerData);
      }
    }
  },[props.companyValue])

  //  console.log("propsCompanyValue", props.companyValue.companyType[0] == "Service Consumer", props.companyValue.companyType.length == 1)
    return (
      <>
        {props.isSuperAdmin == 'Y'? <div>
          <Form.Group className="form-group"  >
            <Form.Label>{props.tr["Type"]}</Form.Label>
            <Field
              component={TypeaheadandDropdown}
              onSelection={onValueSelection}
              name="companyType"
              className="form-control"
              options={typeValue}
              selectedOptions={selectedValueList}
              onCrossClick={onCrossClick}
              errorClass={typeError === "error" ? "error" : ""}
            />
          </Form.Group>
          <Form.Group className="form-group"  >
          <Form.Label>
            {props.tr["Company"]}
          </Form.Label>
          <Field
            name="company"
            className="form-control"
            component={TypeaheadandDropdown}
            onSelection={onCompanySelection}
            options={companyData}
            selectedOptions={selectedCompany}
            errorClass={companyError === "error" ? "error" : ""}
            onCrossClick={onCrossClickCompany}
            multiple={true}
          />
        </Form.Group>
        {(props?.companyValue?.companyType && props?.companyValue?.companyType[0] == "Service Consumer" && props?.companyValue?.companyType.length == 1)? "":(
            (selectedValueList[0]?.id == '7') ? <>
              <Form.Group className="form-group"  >
            <Form.Label>
            {props.tr["Association Type"]}
          </Form.Label>
          <Field
            name="associationType"
            className="form-control"
            component={TypeaheadandDropdown}
            onSelection={onAssoTypeSelection}
            options={assoTypeData}
            selectedOptions={associationType}
            errorClass={assoTypeError === "error" ? "error" : ""}
            onCrossClick={onCrossClickAssoType}
            multiple={true}
              />
              </Form.Group>
            </> : '')
          }
          <ButtonToolbar className="black margin-t-5">
            <Button className="rgSidrkBtn smallBtn"
              onClick={listCompanies}
            >{props.tr["Add"]}
            </Button>
          </ButtonToolbar>
        </div>:''}
          <Tabs activeKey={key} onSelect={(k) => setKey(k)} id="tabId" className='associateCompTabs'>
           <Tab eventKey={1} title={props.tr["Support Companies"]}>
            <div className="catBordDv leftSeardv hdprt integrateHubTable">
              <div className='f-size-16 margin-t-20 margin-b-10 border-b padding-b-5'>
                <TableComponent
                  data={Array.isArray(assoComapnyValue) ? assoComapnyValue : []}
                  columns={tableColumns}
                  headerColumns={filterColumns}
                  onRowSelect={onRowSelect}
                  uniqueKey={'id'}
                  paginationText={"Show"}
                />
              </div>
            </div>
          </Tab>
         { (props?.companyValue?.companyType && props?.companyValue?.companyType[0] == "Service Consumer" && props?.companyValue?.companyType.length == 1) ? "":
          <Tab eventKey={2} title={props.tr["Consumer Companies"]}>
          <div className="catBordDv leftSeardv hdprt integrateHubTable">
            <div className='f-size-16 margin-t-20 margin-b-10 border-b padding-b-5'>
            <TableComponent
                  data={Array.isArray(consumerCompanyList)? consumerCompanyList: []}
                  columns={tableColumnsConsumer}
                  headerColumns={filterColumns}
                  onRowSelect={onRowSelect}
                  uniqueKey={'id'}
                  paginationText = {"Show"}
                />
              </div>
              </div>
            </Tab>}
            </Tabs>
           
            
        </>
    )
}


export default reduxForm({
    form: 'AssociateCompanyList',
    destroyOnUnmount: false,
    enableReinitialize: true
})(AssociateCompanyList);