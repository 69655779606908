
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import {
  Row,
  Col,
  Accordion,
  Tab,
  Tabs,
  ListGroup,
  Button,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {IoClose} from "react-icons/io5";

const ShortDescription = (props) => {
  const navigate = useNavigate();
  const tr = useSelector((state) => state.spcmReducer.tr);
  return (
    <>
      <Row>
        <Col xs={12}>
          <div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
            <ListGroup bsPrefix=" " as="ul">
              <ListGroup.Item as="li" bsPrefix=" ">
                <Button
                  bsPrefix=" "
                  onClick={() => {
                    navigate("/editforum");
                  }}
                  title={tr["Edit"]}
                  className="editIcn"
                >
                  <i className="fa fa-pencil" />
                </Button>
              </ListGroup.Item>
              <ListGroup.Item as="li" bsPrefix=" ">
                <Button bsPrefix=" " title={tr["Minimize the details panel"]}
                    onClick={() => {
                      props.showRightSideFun(false);
                    }}
                  >
                  <IoClose />
                </Button>
              </ListGroup.Item>
            </ListGroup>
          </div>
          <div className="rPageHeading">
            <div className="offNam margin-t-5 margin-b-5">
              {tr["Forum Details"]}
            </div>
          </div>
        </Col>
      </Row>
      <div className="rBoxGap">
        <div className="rBoxTabs">
          <ListGroup as="ul" bsPrefix=" ">
            <ListGroup.Item
              as="li"
              bsPrefix=" "
              className="rwTable"
              style={{ borderTop: "none" }}
            >
              <span className="rwKey">Name</span>
              <span className="rwVal">Performance Analysis</span>
            </ListGroup.Item>

            <ListGroup.Item as="li" bsPrefix=" " className="rwTable">
              <span className="rwKey">Company</span>
              <span className="rwVal">ACME North America</span>
            </ListGroup.Item>

            <ListGroup.Item as="li" bsPrefix=" " className="rwTable">
              <span className="rwKey">Track</span>
              <span className="rwVal">Server</span>
            </ListGroup.Item>

            <ListGroup.Item as="li" bsPrefix=" " className="rwTable">
              <span className="rwKey">Region</span>
              <span className="rwVal">North America</span>
            </ListGroup.Item>
          </ListGroup>
        </div>
      </div>
    </>
  );
};

export default ShortDescription;
