
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { connect } from "react-redux";
import { navigationHooks } from "../../../../helpers/NavigationHook";
import BasicTable from "../../../common/ReactTable/BasicTable";
import { Link } from "react-router-dom";
import {IoClose} from "react-icons/io5";

const CellName = ({ row, selected, isClickable }) => {
  return isClickable ? (
    <Link title={row[selected]} to={`/editSlaDef/${row["slaId"]}`}>
      {row[selected]}
    </Link>
  ) : (
    <span
      title={row[selected]}
      style={{
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      }}
    >
      {row[selected]}
    </span>
  );
};
class ViewHolidayRelatedSls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
    this.renderRelatedSls = this.renderRelatedSls.bind(this);
    this.onRowSelect = this.onRowSelect.bind(this);
  }
  onRowSelect(row) {
    //navigationHooks.navigate(`/editSlaDef/${row["slaId"]}`);
  }
  // renderRelatedSls(list) {
  //     console.log("MMM this.props.holidayRelatedSla: ", this.props.holidayRelatedSla)
  // 	if(this.props.holidayRelatedSla && Array.isArray(this.props.holidayRelatedSla) && this.props.holidayRelatedSla.length>0){
  // 		const options = {
  // 			page: 1,  // which page you want to show as default
  // 			sizePerPageList: [{
  // 				text: '10', value: 10
  // 			}, {
  // 				text: '15', value: 15
  // 			}, {
  // 				text: '25', value: 25
  // 			}, {
  // 				text: 'All', value: this.props.holidayRelatedSla.length
  // 			}], // you can change the dropdown list for size per page
  // 			sizePerPage: 15,  // which size per page you want to locate as default
  // 			pageStartIndex: 1, // where to start counting the pages
  // 			paginationSize: 3,  // the pagination bar size.,
  // 			onRowClick: this.onRowSelect,
  // 		};
  // 		return(
  // 			<div>
  // 				<div className="knowledgeDiv">
  // 					<BootstrapTable
  // 						bordered condensed hover responsive className="tableView paginationSetNew"
  // 						data={this.props.holidayRelatedSla}
  // 						pagination={true} options={options}
  // 						// selectRow={selectRowProp}
  // 						// multiColumnSearch={true}
  // 						// search={true}
  // 						// exportCSV
  // 						// csvFileName="article.csv"
  // 						// searchPlaceholder={"Search by Name, Published On, Published By..."}
  // 					>
  // 						<TableHeaderColumn dataField="slaName" isKey dataSort columnClassName="" width="33%"   >{this.props.translator['Name']}</TableHeaderColumn>
  // 						<TableHeaderColumn dataField="createdOn" dataSort columnClassName="" width="33%" >{this.props.translator['Created On']}</TableHeaderColumn>
  // 						<TableHeaderColumn dataField="modifiedByName" dataSort columnClassName="" width="33%"  >{this.props.translator['Related By']}</TableHeaderColumn>
  // 					</BootstrapTable>

  // 				</div>
  // 			</div>
  // 		);
  // 	}
  // 	else{
  // 		return(
  // 			<div>{this.props.translator['No Data Available']}</div>
  // 		);
  // 	}
  // }

  renderRelatedSls(list) {
    if (
      this.props.holidayRelatedSla &&
      Array.isArray(this.props.holidayRelatedSla) &&
      this.props.holidayRelatedSla.length > 0
    ) {
      const tableColumns = [
        {
          name: this.props.translator["Name"],
          selector: (row) => row.slaName,
          sortable: true,
          cell: (row) => (
            <CellName row={row} selected="slaName" isClickable={true} />
          ),
          ignoreRowClick: true,
        },
        {
          name: this.props.translator["Created On"],
          selector: (row) => row.modifiedOn,
          cell: (row) => (
            <CellName row={row} selected="modifiedOn" isClickable={false} />
          ),
          sortable: true,
          ignoreRowClick: true,
        },
        {
          name: this.props.translator["Related By"],
          selector: (row) => row.modifiedByName,
          cell: (row) => (
            <CellName row={row} selected="modifiedByName" isClickable={false} />
          ),
          sortable: true,
          ignoreRowClick: true,
        },
      ];

      return (
        <BasicTable tableColumns={tableColumns} data={list} pagination={true} noPointer={true} />
      );
    } else {
      return <div>{this.props.translator["No Data Available"]}</div>;
    }
  }

  render() {
    // console.log('this.props',this.props);

    return (
      <div>
        <div className="stickyArea rBoxStyle">
        <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
          <ul>
            <li>
              <button
                  type='button'
                title="Minimize the right panel"
                bsClass=""
                bsStyle=""
                className="closerightPanelBtn"
                onClick={() => {
                this.props.rightEditPanel(false);
                }}
                >
                <IoClose/>
                </button>
              </li>
          </ul>
        </div>
          <div className="rPageHeading">
            <div className="offNam margin-t-5 margin-b-5">
              {this.props.translator["Related SLAs"]}
            </div>
          </div>
          <div className="rBoxGap dtaTableDv">
            {this.renderRelatedSls(this.props.holidayRelatedSla)}
          </div>
        </div>
      </div>
    );
  }
}

// export default BreakFix;

const mapStateToProps = ({
  slaRelatedDetails,
  holidayRelatedSla,
  isFetchingDetails,
}) => {
  return {
    holidayRelatedSla: slaRelatedDetails.holidayRelatedSla,
    isFetchingDetails,
  };
};

export default connect(mapStateToProps, {})(ViewHolidayRelatedSls);
