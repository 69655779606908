
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { reduxForm, Field } from "redux-form";
import { _inputField } from "../../../common/ReduxFormFields/formFields";
import { Row, Col, Form, ButtonToolbar, Button } from "react-bootstrap";
import { postTaskOutputVariables } from "../../../../actions/task/taskVariableActions";
import { validateTaskField } from "../../../../utils/common";

import { connect } from "react-redux";
import {ImSpinner6} from "react-icons/im";

const validate = (values) => {
  let errors = {};

  if (!values.output_key) {
    errors.output_key = "please enter output name";
  }

  if (!values.output_value) {
    errors.output_value = "please enter output value";
  }

  return errors;
};

let TaskOutputPostForm = class TaskOutputPostForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitValues = this.submitValues.bind(this);
  }

  submitValues(values) {
    values.taskCode = this.props.taskCode;
    switch (this.props.formType) {
      case "New":
        this.props.postTaskOutputVariables(
          {
            output_key: values.output_key,
            output_value: values.output_value,
            taskCode: values.taskCode,
            visibility: 0,
          },
          () => {
            this.props.editVarFun1(false, "");
            this.props.loadTaskOutputVariables(this.props.taskCode);
          }
        );
        break;
      case "Edit":
        this.props.updateOutPutVariable(
          {
            output_key: values.output_key,
            output_value: values.output_value,
            taskCode: values.taskCode,
            visibility: 0,
          },
          values.id,
          () => {
            this.props.editVarFun1(false, "");
            this.props.loadTaskOutputVariables(this.props.taskCode);
          }
        );
    }
  }
  componentWillUnmount() {
    this.props.initializeTaskOutputEditForm({});
  }
  render() {
    const { editVarFun1, handleSubmit, isFetchingDetails } = this.props;
    const { posting } = isFetchingDetails;
    // if(posting) {
    //     return(
    // 		<div>
    // 		 <Spinner spinnerName="three-bounce" /><h4>processing...</h4>
    // 		</div>
    // 	);
    // }
    let readOnlyResponse = "";
    if (
      this.props.role_id &&
      this.props.taskDetails.statusId &&
      this.props.taskDetails.statusId > 0
    ) {
      readOnlyResponse = validateTaskField(
        this.props.role_id,
        this.props.taskDetails.statusId
      );
      console.log(readOnlyResponse);
    }
    return (
      <form>
        <div>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label bsClass="">
                  <span className="rStar"></span>
                  {this.props.translator["Variable Name"]}{" "}
                </Form.Label>
                <div className="position-re">
                  <Field
                    name="output_key"
                    component={_inputField}
                    className="form-control"
                    readOnly={readOnlyResponse.output_key}
                  />
                </div>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.Label bsClass="">
                  <span className="rStar"></span>
                  {this.props.translator["Variable Value"]}{" "}
                </Form.Label>
                <Field
                  name="output_value"
                  component={_inputField}
                  className="form-control"
                  readOnly={readOnlyResponse.output_value}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ButtonToolbar className="black margin-t-10">
                <Button
                  onClick={handleSubmit(this.submitValues)}
                  bsSize="small"
                  bsStyle="primary"
                  type="submit"
                >
                  {posting ? (
                    <ImSpinner6 className="icn-spinner"/>
                  ) : null}
                  {this.props.translator["Submit"]}{" "}
                </Button>
                <Button
                  onClick={() => {
                    editVarFun1(false, "");
                  }}
                  bsSize="small"
                  bsStyle="text"
                >
                  {" "}
                  {this.props.translator["Cancel"]}
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>
        </div>
      </form>
    );
  }
};
TaskOutputPostForm = reduxForm({
  form: "TaskOutputPostForm",
  validate,
  enableReinitialize: true,
})(TaskOutputPostForm);

const mapStateToProps = ({ isFetchingDetails, taskInputOutputVariables }) => {
  return {
    isFetchingDetails,
    initialValues: Object.keys(taskInputOutputVariables.outputVariable).length>0?taskInputOutputVariables.outputVariable:'',
  };
};
export default connect(mapStateToProps, { postTaskOutputVariables })(
  TaskOutputPostForm
);
