
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Table, Button } from "react-bootstrap";
import {
  _textArea,
  _dropDown,
  _inputField,
} from "../../../../components/common/ReduxFormFields/CategoryBoard/formFields";
import { bindActionCreators } from "redux";
import {
  loadCIClass,
  loadCICompany,
  loadCICat
} from "../../../../actions/cmdb/lovAction";
import {
  getCICategoryListData,loadCatSubCat,
  emptyCICategoryListDataReducer,loadCISCat,resetSubCatDropdown,resetCatDropdown
} from "../../../../actions/categoryBoard/quickViewAction";
import Form from "react-bootstrap/Form";
import swal from "sweetalert";
import { Field, reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import { isArray } from "lodash";
import {PiToggleRightFill, PiToggleLeftFill} from 'react-icons/pi';
import {HiPlus, HiOutlineTrash} from "react-icons/hi";
import { loadClassByCompanyId, resetCiClass } from '../../../../actions/ham/hamAction';

const required = (value) => {
  if (!value || (value && value.trim().length === 0)) {
    return "required";
  }
};
    
  const CreateCiResolutionCategoryForm= (props) => {
		const dispatch = useDispatch();
    const tr = useSelector((state) => state.spcmReducer.tr);

		const [catStatus, setCatStatus] = useState("true");
		const [arrayCounts, setArrayCounts] = useState(1);
		const [extraCounts, setExtraCounts] = useState([0]);
		const [catStatuses, setCatStatuses] = useState([true]);
		const [createUsing, setCreateUsing] = useState("new");
    const [filteredData, setFilteredData] = useState([]);

		const [selectedCompany, setSelectedCompany] = useState("");
		const [selectedClass, setSelectedClass] = useState("");
    const [ciSubCategory, setCiSubCategory] = useState("");
		const [selectedCategory, setSelectedCategory] = useState("");
		const [selectedSubCategory, setSelectedSubCategory] = useState("");
    const ciClass = useSelector(state => state.ciClass);
    const ciCompany = useSelector(state => state.ciCompany);
    const ciCat = useSelector(state => state.ciCat);
		const ciSCat = useSelector(state => state.ciSCat);
    const ciCategoryListData = useSelector(state => state.ciCategoryListData);
    const ciResolutionCat = useSelector(state => state.ciResolutionCat);
    const compClass = useSelector((state) => state.compClass);
    
    useEffect(() =>{
      let filteredDataArr = [];
      if (isArray(ciResolutionCat.data)) {
       
        ciResolutionCat.data.map((element) => {
          if (
            filteredDataArr.find(
              (elem) => elem.res_category_name === element.res_category_name
            )
          ) {
          } else {
            filteredDataArr.push(element);
          }
        });
      }
      setFilteredData(filteredDataArr);
      console.log("filteredDataArr-----", filteredDataArr);
    },[ciResolutionCat])
  
  const renderCategory = (categoryData) => {
		if (!categoryData) {
			return (
				<option></option>
			);
		}
		return categoryData.map((categoryObj, i) => {
			return (
				<option value={categoryObj.category_name} key={i}>{categoryObj.category_name}</option>
			);
		});
	}
 
  useEffect(() => {
		setExtraCounts([0]);
			dispatch(change("CreateCiResolutionCategoryForm", "status", [catStatus]));
      dispatch(resetSubCatDropdown());
      dispatch(resetCatDropdown());
      dispatch(resetCiClass());
      
    }, [])


 const handleStatusChange = (i) => {
    let catDet = structuredClone(catStatuses);
    catDet[i] = !catDet[i];
    setCatStatuses(catDet);
    dispatch(change("CreateCiResolutionCategoryForm", "status", catDet));
  }
  const updateCatStatus = () => {
        setCatStatus(!catStatus);
  }
  const addRow = () => {
    if (extraCounts.length < 10) {
      let newStatus = catStatuses;
      newStatus.push(true);
      let arrayvar = extraCounts.slice();
      arrayvar.push(arrayCounts);
      // this.setState(
      //   {
      //     extraCounts: arrayvar,
      //     arrayCounts: arrayCounts + 1,
      //     catStatuses: newStatus,
      //   },
      
      //   () => {
      //     props.dispatch(
      //       change("", "status", newStatus)
      //     );
      //   }
      // );
        setExtraCounts(arrayvar);
        setArrayCounts(arrayCounts + 1);
        setCatStatuses(newStatus);
        dispatch(change("CreateCiResolutionCategoryForm", "status", newStatus));
    } else {
      swal({
        text: "Cannot create more than 10 records",
        button: "OK",
      });
    }
  }
  const deleteRow = (myIndex) => {
   
    let arrayvar = extraCounts;
    let index = arrayvar.indexOf(myIndex);
    arrayvar.splice(index, 1);
    let newVar = structuredClone(arrayvar);
    let statusArr = catStatuses;
    statusArr.splice(index, 1);
    setExtraCounts(newVar);
    setCatStatuses(statusArr);
  }
  const loadCount = () => {
    console.log("createUsing==",createUsing);
    const rowlength = extraCounts.length;
    props.setRowCount(extraCounts);
    return extraCounts.map((extraCount, i) => {
      if (rowlength === i + 1) {
        return (
          <tr>
            <td>
              {" "}
              <Form.Group className="form-group">
                {createUsing === "new" ? (
                  <Field
                    className="form-control"
                    component={_inputField}
                    maxLength="50"
                    name={"res_category_name" + extraCount}
                    validate={[required]}
                  />
                ) : (     
                  <Field
                    name={"res_category_name" + extraCount}
                    className={"form-control"}
                    component={_dropDown}
                    options={filteredData}
                    validate={[required]}
                  />
                )}
              </Form.Group>
            </td>
            <td>
              <Form.Group className="form-group">
                <Field
                  component={_inputField}
                  maxLength="50"
                  name={"sub_category_name" + extraCount}
                  className={"form-control"}
                  validate={[required]}
                />
              </Form.Group>
            </td>
            <td className="rBoxStyle">
              <Button
                title={catStatuses[i] ? "Active" : "Inactive"}
                onClick={() => handleStatusChange(i)}
                name={"status"}
                className={
                  catStatuses[i] ? "theme1 ciCrtCatBtn" : "ciCrtCatBtn"
                }
              >
                {catStatuses[i] ? <PiToggleRightFill/> : <PiToggleLeftFill/>}
              </Button>
              {extraCounts.length === 1 && (
                <Button
                  title="Add"
                  className='ciCrtCatBtn'
                  onClick={() => {
                    addRow();
                  }}
                >
                   <HiPlus/>
                </Button>
              )}
              {extraCounts.length > 1 && (
                <>
                  <Button
                    title="Add"
                    className='ciCrtCatBtn'
                    onClick={() => {
                      addRow();
                    }}
                  >
                     <HiPlus/>
                  </Button>
                  <Button
                    title="Delete"
                    className='ciCrtCatBtn'
                    onClick={() => {
                      deleteRow(extraCount);
                    }}
                  >
                    <HiOutlineTrash/>
                  </Button>
                </>
              )}
            </td>
          </tr>
        );
      } else {
        return (
          <tr key={i}>
            <td>
              {createUsing === "new" ? (
                <Field
                  component={_inputField}
                  maxLength="50"
                  name={"res_category_name" + extraCount}
                  className="form-control"
                  validate={[required]}
                />
              ) : (
                <Field
                  name={"res_category_name" + extraCount}
                  className="form-control"
                  component={_dropDown}
                  options={filteredData}
                  validate={[required]}
                />
              )}
            </td>
            <td>
              <Field
                component={_inputField}
                name={"sub_category_name" + extraCount}
                className="form-control"
                maxLength="50"
                validate={[required]}
              />
            </td>
            <td className="rBoxStyle">
              <Button
                title={catStatuses[i] ? "Active" : "Inactive"}
                name={"status"}
                onClick={() => handleStatusChange(i)}
                className={
                  catStatuses[i] ? "theme1 ciCrtCatBtn" : "ciCrtCatBtn"
                }
              >
                {catStatuses[i] ? <PiToggleRightFill/> : <PiToggleLeftFill/>}
              </Button>
              <Button
                title="Delete"
                className='ciCrtCatBtn'
                onClick={() => {
                  deleteRow(extraCount);
                }}
              >
                <HiOutlineTrash/>
              </Button>
            </td>
          </tr>
        );
      }
    });
  }

    const handleciCompanyChange = (event) => {
    let companyId =
      event.target.value !== ""
        ? JSON.parse(event.target.value).field1Value
        : "";
   // setState({ selectedCompany: companyId });
    setSelectedCompany(companyId);
    dispatch(
      change("CreateCiResolutionCategoryForm", "companyName", event.target.company)
    );
    // if (selectedClass !== "") {
    //   dispatch(getCICategoryListData(companyId,selectedClass,"both"));
    // }

    if (companyId !== "") {
      let status = 'ACTIVE';
      let hamCall = false;
      dispatch(loadClassByCompanyId(companyId,hamCall,status));
    } 
    dispatch(change("CreateCiResolutionCategoryForm", "classId", ""));
    // dispatch(resetCiClass());
    
  
    if(selectedClass !== "" && event.target.value !== "") {
      dispatch(loadCICat(selectedClass,event.target.value,"resolution"));
    }
  }
  const handleciClassChange = (event) => {
    //setState({ selectedClass: event.target.value });
    setSelectedClass(event.target.value);

    // if (selectedCompany !== "") {
    //   dispatch(getCICategoryListData(selectedCompany,event.target.value,"both"));
    // }
    if(selectedCompany !== "" && event.target.value !== "") {
      dispatch(loadCICat(event.target.value,selectedCompany,"resolution"));
      
    }
  }

    const handleciCategoryChange = (event) => {
    //setState({ selectedCategory: event.target.value });
    setSelectedCategory(event.target.value);
    if(selectedCompany !== "" && selectedClass !== "") {
      dispatch(loadCISCat(event.target.value,selectedClass,selectedCompany));
    }
    if (createUsing === "Existing") {
      dispatch(loadCatSubCat(""));
    }
  }

    const renderSubCategory = (subCategoryData) => {
		if (!subCategoryData) {
			return (
				<></>
			);
		}
		return subCategoryData.map((subCategoryObj, i) => {
			return (
				<option value={subCategoryObj.category_id} key={i}>{subCategoryObj.sub_category_name}</option>
			);
		});
	}


    const handleciSubCategory = (event) => {
      setSelectedSubCategory(event.target.value);
      if (createUsing === "Existing") {
        dispatch(loadCatSubCat(event.target.value));
      }
    }
useEffect(() => {
  if (createUsing === "Existing")  
     dispatch(loadCatSubCat(selectedSubCategory));
}, [createUsing])

  const handleNewExist = () => {
    
    if (!selectedCategory) {
      swal({
        text: "Please select category first.",
        button: "OK",
      });
      return; // Prevent further execution if company or class is not selected
    }

    setCreateUsing(createUsing === "new" ? "Existing" : "new");
    extraCounts.map((val) => {
      dispatch(
        change("CreateCiResolutionCategoryForm", "category_name" + val, "")
      );
      dispatch(
        change("CreateCiResolutionCategoryForm", "sub_category_name" + val, "")
      );
    });
    setArrayCounts(1);
    setExtraCounts(structuredClone(extraCounts.slice(0,1)));
    // props.setRowCount(extraCounts.splice(1,extraCounts.length));
    setCatStatuses([true]);

    
  }

 const renderClass = (classData) => {
	 console.log("This.props(((((",classData);

		if (!classData) {
			return (
				<option></option>
			);
		}
		return classData.map((classObj, i) => {
			// if (classObj.CLASS_NAME != "Service")
			return (
				<option value={classObj.CLASS_ID} key={i}>{classObj.CLASS_NAME}</option>
			);
		});
	}
 
    return (
      <div>
        <Row>
          <Col md={12}>
            <Row>
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">
                    <span className="rStar"></span> {props.tr["Company"]}
                  </Form.Label>
                  <Field
                    name="ciCompany"
                    className="form-control"
                    component={_dropDown}
                    options={ciCompany}
                    validate={[required]}
                    onChange={(event) => handleciCompanyChange(event)}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="form-group" bsPrefix=" ">
                  <Form.Label bsClass="">
                    <span className="rStar"></span> {props.tr["Class"]}
                  </Form.Label>
                  <Field
                    name="classId"
                    className="form-control"
                    component="select"
                    onChange={(event) => handleciClassChange(event)}
                    validate={[required]}
                  >
                    <option value="">Select</option>
                    {renderClass(compClass)}
                  </Field>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="form-group" bsPrefix=" ">
                  <Form.Label bsClass="">
                    <span className="rStar"></span> {props.tr["CI Category"]}
                  </Form.Label>
                  <Field
                    name="ciCategory"
                    className="form-control"
                    component={_dropDown}
                    options={ciCat}
                    validate={[required]}
                    onChange={(event) => handleciCategoryChange(event)}
                  />
                  {/* <option value="">Select</option>
               
                {renderCategory(ciCat)}
              </Field> */}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">
                    <span className="rStar"></span>{" "}
                    {props.tr["CI Sub Category"]}
                  </Form.Label>
                  <Field
                    name="ciSubCategory"
                    className={`form-control`}
                    component={_dropDown}
                    options={ciSCat}
                    validate={[required]}
                    onChange={(event) => {
                      handleciSubCategory(event);
                    }}
                  />
                  {/* <option value="">Select</option>
                    {renderSubCategory(ciSCat)}
                  </Field> */}
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="bgGray padding-5 margin-t-15 inciSupProviderDv border border-bottom-0 d-flex" bsPrefix=" ">      
        <div className="px-2">           
           <span className="rStar"></span> 
            <span className="px-1 fw-semibold"> 
              {tr["Category Details"]}
            </span>
          </div>
          <div className="ms-auto rBoxStyle">
            <Button
              className="showwAllGrps rgSidrkBtn smallBtn mb-0"            
              onClick={handleNewExist}
            >
            {createUsing === "new"
                ? "Create for Existing Resolution Category"
                : "Create New Resolution Category"}
            </Button>
          </div>        
        </div>
        <div className="commonForm">
          <Table bordered>
            <thead>
              <tr>
                <th width="42%">{props.tr["Resolution Category"]}</th>
                <th width="42%">{props.tr["Resolution Sub Category"]}</th>
                <th width="16%">{props.tr["Action"]}</th>
              </tr>
            </thead>
            <tbody>{loadCount()}</tbody>
          </Table>
        </div>
      </div>
    );
  
}

export default reduxForm({
  form: "CreateCiResolutionCategoryForm",
  enableReinitialize: true,
  })(CreateCiResolutionCategoryForm);
