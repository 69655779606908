
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
// import { Row, Col, PageHeader,Button} from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import {HiPlus, HiOutlineRefresh} from "react-icons/hi";

import { loadIntegrationHubList } from '../../actions/integrationHub/integrationHubAction.js';
import { useDispatch, useSelector } from "react-redux";
import { getSystemConfigList } from '../../actions/systemConfig/systemConfigAction.js';

const SystemConfigListHeader = ()=>{
	const translator = useSelector((state) => state.spcmReducer.tr);
	const dispatch = useDispatch()
	const refreshConfigListData = () => {
		dispatch(getSystemConfigList());
	}
		return (
			<Row className="margin-b-15">
			<Col lg={4} md={6} sm={4} xs={12}>
				<h2 bsClass="" className="sPageHeading1">{translator['Mailbox Configuration']}</h2>
			</Col>
			<Col lg={8} md={6} sm={8} xs={12} className="text-r">
				<div className="paHedFilter">
					<Link role="button" className="myBt plus fillBtn" title={translator['Mailbox Configuration']} to="/createSystemConfig" ><HiPlus/></Link>
					<Button title={translator["Refresh"]} onClick={refreshConfigListData} role="button" bsPrefix=' '  className="myBt"><HiOutlineRefresh className="refreshFlip"/></Button>
				</div>
			</Col>
		</Row>			
		);
	}



export default SystemConfigListHeader;
