
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GLOBAL } from "../../components/Globals";

export const scoreCardApi = createApi({
  reducerPath: "scoreCardApi",
  baseQuery: fetchBaseQuery({
    baseUrl: window.location.origin,
  }),
  tagTypes: ["scoreCard", "Quadrant"],

  endpoints: (builder) => ({
    getActiveQuadrantsId: builder.query({
      query: ({ queryString = "" }) => {
        return GLOBAL.getActiveQuadrantsId + queryString;
      },
      providesTags: (result, error, arg) => [
        { type: "Quadrant", id: "REFRESH" },
      ],
    }),
    getAllActiveQuadrants: builder.query({
      query: ({ queryString = "" }) => {
        return GLOBAL.getAllActiveQuadrants + queryString;
      },
      providesTags: (result, error, arg) => [{ type: "Quadrant", id: "LIST" }],
    }),
    saveSuppliersScorecard: builder.mutation({
      query: ({ data, queryString }) => ({
        url: GLOBAL.saveSuppliersScorecard + queryString,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "Quadrant", id: "LIST" }],
    }),
    submitSuppliersScoreCard: builder.mutation({
      query: ({ queryString }) => ({
        url: GLOBAL.submitSuppliersScoreCard + queryString,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
  }),
});

export const {
  useGetActiveQuadrantsIdQuery,
  useGetAllActiveQuadrantsQuery,
  useSaveSuppliersScorecardMutation,
  useSubmitSuppliersScoreCardMutation,
} = scoreCardApi;

const { reducer } = scoreCardApi;
export default reducer;
