
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/

export const ExternalLinkListValues = (state = [], action) => {
    if (action.type === 'EXTERNAL_LINK_LIST_FETCHED_SUCCESSFULLY') {
      return action.payload;
    } else {
      return state;
    }
};

export const ExternalLinkEditData = (state = [], action) => {
    if (action.type === 'EXTERNAL_LINK_DATA_FETCHED_SUCCESSFULLY') {
      return action.payload;
    } else {
      return state;
    }
};

export const ExternalLinkSaved = (state = [], action) => {
    if (action.type === 'EXTERNAL_LINK_DATA_SAVED_SUCCESSFULLY') {
      return action.payload;
    } else {
      return state;
    }
};

export const ExternalLinkImage = (state = "", action) => {
    if (action.type === 'EXTERNAL_LINK_IMAGE_UPLOADED_SUCCESSFULLY') {
      return action.payload;
    } else {
      return state;
    }
};