
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { useGetAISMIndividualsMutation } from "../../../reducers/aism/aismAPI";
import { useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useRef } from "react";
import { GLOBAL } from "../../Globals";

const AismIndividualTypeAhead = ({
  disabled,
  onSelection,
  onInputChange,
  placeholder,
  selectedOptions,
  typedValue,
  onCrossClick,
  setErrorColor,
  errorClass,
  formData,
  setIsLoading,
  isLoadingAismIndividual
}) => {
  const [getAismIndividualData, { isLoading: individualListLoader }] =
    useGetAISMIndividualsMutation();

  const [individualList, setIndividualList] = useState([]);
  const typeahead = useRef(null);

  const formValues = formData?.ItemList;

  useEffect(() => {
    if (formValues?.supportPGroup) {
      setIsLoading(true);
      getAismIndividualData({
        priorityId: formValues?.priorityActualVal,
        urgency: formValues?.urgencyMode,
        serviceCriticality: formValues?.criticality,
        serviceId: formValues?.impactedServiceId || GLOBAL.breakFixServiceID,
        description: formValues?.issueDescription,
        fixDetail: "",
        isExternal: formValues?.isExternal,
        spGroupid: formValues?.supportPGroup,
        ciId: formValues?.ciId,
        companyId: formValues?.company,
        companyName: formValues?.companyName,
      })
        .then((resp) => {
          let options = resp.data?.data?.map((item) => ({
            id: item.userId,
            label: `${item.userName}`,
            score: item.confidenceScore,
          }));
          options = [
            ...new Map(
              options.map((item) => [JSON.stringify(item), item])
            ).values(),
          ];
          setIndividualList(options || []);
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    } else {
      setIndividualList([]);
    }
  }, [formValues?.supportPGroup]);

  const tr = useSelector((state) => state.spcmReducer.tr);

  let crossClick = () => {
    typeahead.current.clear();
    onCrossClick();
  };

  return (
    <div className={"dvTypehd " + errorClass}>
      {!disabled &&
      ((selectedOptions && selectedOptions.length > 0) ||
        (typedValue && typedValue.length > 0)) ? (
        <span
          title={tr["Clear"]}
          role="img"
          aria-label="Clear"
          className="css-hakgx8 icn"
          onClick={crossClick}
        >
          <svg width="18" height="18" viewBox="0 0 24 24" role="presentation">
            <path
              d="M12 10.586L6.707 5.293a1 1 0 00-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 001.414 1.414L12 13.414l5.293 5.293a1 1 0 001.414-1.414L13.414 12l5.293-5.293a1 1 0 10-1.414-1.414L12 10.586z"
              fill="gray"
            ></path>
          </svg>
        </span>
      ) : null}
      <Typeahead
        ref={typeahead}
        id="aismIndividual"
        onChange={onSelection}
        onInputChange={onInputChange}
        options={individualList}
        selected={selectedOptions}
        placeholder={placeholder ? placeholder : tr["Please choose..."]}
        onBlur={setErrorColor}
        disabled={disabled}
        isLoading={isLoadingAismIndividual}
        renderMenuItemChildren={(option, props) => {
          const label = option?.label;
          const score = option?.score;
          return (
            <div>
              <span className="typHdmainHead" title={label}>
                <span className="f-bold">
                  {label}{" "}
                </span>
                {score}
              </span>
            </div>
          );
        }}
      />
    </div>
  );
};

export default AismIndividualTypeAhead;
