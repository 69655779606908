
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const mySelect = (e) => {
  e.DomComponents.addType("custom-dropdown", {
    model: e.DomComponents.getType("select").model.extend({
      init() {
        const traits = this.get("traits");
        this.addClass("form-control");
        traits.add([
          {
            type: "text",
            label: "Name",
            name: "name",
            changeProp: 1,
          },
          {
            type: "select", // Type of the trait
            name: "data_source", // (required) The name of the attribute/property to use on component
            label: "Data Source", // The label you will see in Settings
            options: [],
            changeProp: 1,
          },
          {
            type: "select",
            label: "Label Key",
            name: "labelKey",
            options: [],
            changeProp: 1,
          },
          {
            type: "select",
            label: "Value Key",
            name: "valueKey",
            options: [],
            changeProp: 1,
          },
          {
            type: "text",
            label: "Default Value",
            name: "defaultValue",
            changeProp: 1,
          },
          {
            type: "checkbox",
            name: "customOptions",
            changeProp: 1,
          },
          {
            type: "checkbox",
            label: "Disabled",
            name: "disabled",
            changeProp: 1,
          },
          {
            type: "text",
            label: "Disabled Key",
            name: "disabledKey",
            changeProp: 1,
          }
        ]);
      },
      updateTraits() {
        
      },
    }),
    view: e.DomComponents.getType("select").view,
  });
};
