
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import Swal from 'sweetalert2';

import { GLOBAL }  from '../../components/Globals';
import constants from '../../utils/constants';
export function loadBreakFixSLALogDetailsSuccess(breakFixSLALogDetails){
	//alert('in success')
	return {
		type: 'LOAD_BREAKFIXSLALOGDETAILS_SUCCESS',
		breakFixSLALogDetails
	};
}

export function loadBreakFixSLALogDetailsFailure(errorResponse){
	return {
		type: 'LOAD_BREAKFIXSLALOGDETAILS_FAILURE',
		errorResponse
	};
}

export function loadBreakFixSLALogDetails(module,ticketid){
	//alert('action called and parameter is '+breakFixId)
	let strObj={};
	strObj.ticketId=ticketid;
	strObj.moduleId=module;
	strObj = JSON.stringify(strObj);
	return(dispatch) =>{
		//alert("EditGroupIdAction123"+breakFixId);
		dispatch({ type: 'LOADER_IN_PROGRESS_3' })
		if(module == undefined || module == ""){
			//alert("H2 breakFixId not found");
		}else{
				//alert("my url is "+GLOBAL.breakFixEditUrl+breakFixId);
				//axios.get(constants.slaurl+"/sla-def/getSlaInformation?ticketId="+ticketid+"&moduleId="+module)
				axios.get("/sla-def/getSlaInformation",{headers:{'query':strObj}})
					.then((breakFixSLALogDetails)=>{
						if(!breakFixSLALogDetails){//alert('not breakFix')
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY_3'});
							throw Error(breakFixSLALogDetails.statusText);
						}
						return breakFixSLALogDetails;
					})
					.then((breakFixSLALogDetails) =>{//alert('yes it is')
						dispatch(loadBreakFixSLALogDetailsSuccess(breakFixSLALogDetails));
						dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_3' });
					})
					.catch((err) => {
						dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_3' });
						console.log(err);
					});
					//console.log("not calling loadBreakFixEditDetails");
		}
	};
}

export const reprocessingSlaData = (payload, module) => {
	let payloadValue = [];
	payloadValue.push(payload);
	return (dispatch) =>{
		dispatch({ type: "LOADER_IN_PROGRESS_6" });
	  axios.post("/api/ticketReprocessing", payloadValue)
		.then((response) => {
			dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY_6" });
          if(response.status == 201){
			  if (module !== undefined) {
				  dispatch(loadBreakFixSLALogDetails(module, payload));
			  }
			Swal.fire({
				title: 'All SLAs have been reprocessed',
			})
		  } else {
			Swal.fire({
				title: 'An issue occurred while reprocessing the SLAs, please try again later',
			})
		  }
		})
		.catch((err) => {
		//   dispatch({ type: "SLA_DATA_FETCHED_SUCCESS" });
		  console.log(err);
		});
	};
  };