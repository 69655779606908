
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import axios from "axios";
import Spinner from "react-spinkit";
import { GLOBAL } from "_Globals";

const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

class UserDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      breakFixUser: [],
    };
  }
  componentWillMount() {
    let userId = this.props.userId;
    //alert("M2"+userId);
    let strObj = {};
    strObj.userId = userId;
    strObj = JSON.stringify(strObj);
    api
      .get(GLOBAL.breakFixUserDetailsDataUrl, { headers: { query: strObj } })
      .then((response) => {
        this.setUserInfo(response);
      });
  }
  componentWillReceiveProps(nextProps) {
    let strObj = {};
    strObj.userId = nextProps.userId;
    strObj = JSON.stringify(strObj);
    api
      .get(GLOBAL.breakFixUserDetailsDataUrl, { headers: { query: strObj } })
      .then((response) => {
        this.setUserInfo(response);
      });
  }
  setUserInfo(user) {
    this.setState({
      breakFixUser: user.data,
    });
  }
  render() {
    if (this.state.breakFixUser.length == 0) {
      return <Spinner spinnerName="three-bounce" />;
    }
    return (
      <div className="margin-b-20">
        <div className="border-b padding-b-10 margin-b-10 black f-size-20 fw-300">
          {this.props.translator["Requested for Details"]}
        </div>

        <Card>
          <Card.Text align="top">
            <Image
              style={{ width: "90px" }}
              src={this.state.breakFixUser[0].profilePicTempURL}
              alt={this.state.breakFixUser[0].username}
            />
          </Card.Text>
          <Card.Body>
            <Card.Title className="blue f-size-18 margin-0">
              {this.state.breakFixUser[0].fullname}
            </Card.Title>
            <div className="lgray f-size-12 margin-t-5">
              {this.state.breakFixUser[0].roleName}
            </div>
          </Card.Body>
        </Card>
        <ListGroup className="">
          <ListGroup.Item className="rwTable">
            <span className="rwKey">{this.props.translator["Email"]}</span>
            <span className="rwVal">{this.state.breakFixUser[0].email}</span>
          </ListGroup.Item>
          <ListGroup.Item className="rwTable">
            <span className="rwKey">{this.props.translator["Phone"]}</span>
            <span className="rwVal">
              {this.state.breakFixUser[0].mobilePhone}
            </span>
          </ListGroup.Item>
          <ListGroup.Item className="rwTable">
            <span className="rwKey">
              {this.props.translator["Company Name"]}
            </span>
            <span className="rwVal">
              {this.state.breakFixUser[0].companyName}
            </span>
          </ListGroup.Item>
          <ListGroup.Item className="rwTable">
            <span className="rwKey">
              {this.props.translator["Location Name"]}
            </span>
            <span className="rwVal">
              {this.state.breakFixUser[0].locationName}
            </span>
          </ListGroup.Item>
        </ListGroup>
      </div>
    );
  }
}
export {UserDetails};
