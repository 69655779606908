
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import swal from 'sweetalert';
import { navigationHooks } from '../../helpers/NavigationHook';
import Swal from 'sweetalert2';
const api = axios.create({
    headers: { Pragma: "no-cache", "Cache-control": "no-store" },
  });

export const getUserListAction = (page, limit, sortBy = "companyName", sortType = "asc", searchBy = "", searchByValue = "", userType, status) => {
  return (dispatch) => {
    if(userType.length > 0) {
      searchByValue = searchByValue.concat(searchByValue !== "" ? "|" : "", userType.join(","));
      searchBy = searchBy.concat(searchBy !== "" ? "," : "", "userType");
    }
    if(status.length > 0) {
      searchByValue = searchByValue.concat(searchByValue !== "" ? "|" : "", status.join(","));
      searchBy = searchBy.concat(searchBy !== "" ? "," : "", "status");
    }
    let payload = {
      sortValue: sortBy,
      sortType: sortType,
      page: page,
      limit: limit,
      searchBy: searchBy,
      searchByValue: searchByValue,
    };
    dispatch({ type: "FDN_DATA_FETCHING" });
    api
      .post("/api/userList", payload)
      .then((response) => {
        dispatch({ type: "USER_FDN_LIST", payload: response.data });
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
      });
  };
};

export const getComapanyBasedDepartment = (id) => {
    let Id = parseInt(id, 10);
    return (dispatch) => {
   
      api.get("/api/getUserOrganizationList/"+Id).then((resp) => {
        dispatch({type:'COMP_BASED_DEP',payload : resp.data});
      })
      .catch((err) => {
        console.log(err);
      })
    }
}
export const getCompanyBasedUserInfoAction = (Id) => {
  return (dispatch) => {
   
    api.get("/api/getUserCompanyList/"+Id).then((resp) => {
      // let costCenterListArr = [];
      // const costCenter = resp.data.costCenterList;
      // if (typeof costCenter !== "undefined" && costCenter.length > 0) {
      //   costCenter.forEach((values) => {
      //     let val = { id: values.field1Value, label: values.field1Key };
      //     costCenterListArr.push(val);
      //   });
      // }
      // dispatch({type:'USER_COSTCENTER',payload : costCenterListArr});

      // let locationListArr = [];
      // const locations = resp.data.locationList;
      // if (typeof locations !== "undefined" && locations.length > 0) {
      //   locations.forEach((values) => {
      //     let val = { id: values.field1Value, label: values.field1Key };
      //     locationListArr.push(val);
      //   });
      // }
      // dispatch({type:'USER_LOCATION',payload : locationListArr});

      // let managerListArr = [];
      // const managerUser = resp.data.managerList;
      // if (typeof managerUser !== "undefined" && managerUser.length > 0) {
      //   managerUser.forEach((values) => {
      //     let val = { id: values.field1Value, label: values.field1Key };
      //     managerListArr.push(val);
      //   });
      // }
      // dispatch({type:'USER_MANAGER', payload : managerListArr});

      // let cwlListArr = [];
      // const cwl = resp.data.cwlLocationList;
      // if (typeof cwl !== "undefined" && cwl.length > 0) {
      //   cwl.forEach((values) => {
      //     let val = { id: values.field1Value, label: values.field1Key };
      //     cwlListArr.push(val);
      //   });
      // }
      // dispatch({type:'CWL_DATA', payload : cwlListArr});
      dispatch({type:'USER_ORGZ',payload : resp.data.organizationList});

    })
    .catch((err) => {
      console.log(err);
    })
  }
}

export function postCreatedUserAction(data) {
  return (dispatch) => {
      api.post("/api/saveUserAdd", data)
          .then((response) => {
              if (response.status == 201) {
                  navigationHooks.navigate("/user");    
              } 
              dispatch(resetDetails());

          })
        .catch((err) => {
            if(err.response.data === "Email ID already exists") {
              Swal.fire({
                title: "Email ID already exists",
              })
            }
           else if(err.response.data === "User ID already exists") {
              Swal.fire({
                title: "Username already exists",
              })
            }
          });
  };
}

export const getUserInformationListAction = () => {
  return(dispatch)=>{
    api.get("/api/addUser").then((resp)=>{
      let authenticationList = resp.data.authenticationSourceList;
      let datesFormats = resp.data.listDateformat;
      let langauges = resp.data.languageList;
      let userType = resp.data.userTypeIdList
      let timeZone = resp.data.timeZonelist
      let currency = resp.data.currencyList


      dispatch({type:'AUTHENTICATION_LIST',payload:authenticationList});
      dispatch({type:'DATE_FORMAT',payload : datesFormats});
      dispatch({type:'LANGUAGE_LIST',payload :langauges});
      dispatch({type:'USER_TYPE',payload :userType});
      dispatch({type:'TIME_ZONE',payload :timeZone});
      dispatch({type:'CURRENCY',payload :currency});

    })
    .catch(()=>{

    })
  }
}

export const getEditedUserDetails = (id) => {
  return (dispatch) => {
      dispatch({ type: 'FDN_DATA_FETCHING'})
    api.get("/api/editUser/"+id)
        .then((response) => {
          dispatch({ type: 'FDN_DATA_FETCHED_SUCCESS'})
            console.log("USERRESP===", response.data)
            let userValues = response.data.userInfo
            let companiesMap = response.data.companiesMap
            // if (typeof companiesMap !== "undefined" && companiesMap.length > 0) {
            //   companiesMap.forEach((values) => {
            //     let val = { id: values.field1Value, label: values.field1Key };
            //     object.push(val);
            //   });
          // }
          userValues['selfSignFlag'] = response.data.selfSignFlag;
          console.log("UserCompanyMaps", userValues)
          dispatch({ type: 'USER_PAGE_EDIT_DETAILS', payload:userValues });
          dispatch({ type: 'USER_EDIT_MAPPED_COMP', payload:companiesMap});
          if(response.data.userNotCreated){
            dispatch({ type: 'SYNC_NOT_CLICK', payload:response.data.userNotCreated});
            dispatch({ type: 'SYNC_CLICK', payload: ''});

          }else if(response.data.userCreated){
            dispatch({ type: 'SYNC_CLICK', payload:response.data.userCreated});
            dispatch({ type: 'SYNC_NOT_CLICK', payload: ''});
            
          }
          if(response.data?.CloudDirectoryMessage) {
            dispatch({ type: 'SYNC_CLICK', payload: ''});
            dispatch({ type: 'SYNC_NOT_CLICK', payload:response.data.CloudDirectoryMessage});
          }

      })
        .catch((err) => {
        dispatch({ type: 'FDN_DATA_FETCHED_FAILED'})
        console.log(err);
      });
  };
};

export const resetEditUserDetails = () => {
  return (dispatch) => {
        dispatch({ type: 'RESET_EDIT_USER_DETAILS' });
  };
};

export const resetDetails = () => {
  return (dispatch) => {
  
        dispatch({ type: 'RESET_USER_CWL_LOC_CC_MANAGER_COMPANY_DETAILS', payload: []});
      
  };
};

export const getUserGroups = (data) => {
  return (dispatch) => {
   
    api.post("/api/groupslistByCompanyIdNotAlreadyContainingUserId",data).then((resp) => {
      let object = [];
      if (resp.data.length > 0) {
        resp.data.forEach((values) => {
          let val = { id: values.id, label: values.name };
          object.push(val);
        });
      }
      
    dispatch({ type: 'USER_GROUP', payload:object });
    })
    .catch((err) => {
      console.log(err);
    })
  }
}

export const getUserRoles = (Id) => {
  return (dispatch) => {
    let str = {"userId": Id}   
   
    api.post("/api/getRolesNotAlreadyAssociatedToUserIdViaAjax",str).then((resp) => {
      let object = [];
      if (resp.data.length > 0) {
        resp.data.forEach((values) => {
          let val = { id: values.id, label: values.name , status:values.active_Role };
          object.push(val);
        });
      }
    dispatch({ type: 'USER_ROLE', payload:object });
    })
    .catch((err) => {
      console.log(err);
    })
  }
}

export const getNonRestrictAccessAction = (userID) => {
  return (dispatch) => {
    let object = [];
    api.get("/api/viewAssociatedUserFunctionsViaAjaxForUser/"+userID).then((resp) => {
      if (resp.data.length > 0) {
        resp.data.forEach((values) => {
          let val = { id: values.functionId, label: values.functionName };
          object.push(val);
        });
      }
      dispatch({type:'ACCESS_RESTRICT',payload : object});
    })
    .catch((err) => {
      console.log(err);
    })
  }
}

export const getUserCompanyAction = (userId) => {  
  return (dispatch) => {
    let str = {"userId": userId}  
    let object = [];
    api.post("/api/getDefaultCompanylistByTypeForUser",str).then((resp) => {
      if (resp.data.length > 0) {
        resp.data.forEach((values) => {
          let val = { id: values.id, label: values.name };
          object.push(val);
        });
      }
      dispatch({type:'USER_COMP_ASSOCIATED_DRPDWN_LIST',payload : object});
    })
    .catch((err) => {
      console.log(err);
    })
  }
}

export const disassociateUserCompany = (body) => {
  return (dispatch) => {
    // let str = {"userId": userId}  
    axios.post("/api/deleteViewAssociatedCompanyModalDataForUser",body)
                .then((resp) => {
        dispatch({ type: 'ASSOCIATED_COMPANY_DELETED', payload: resp.data});
            })
    .catch((err) => {
      console.log(err);
    })
  }
}

export const getOrgInfoMapList = (id) => {
  return (dispatch) => {
      dispatch({ type: 'FDN_DATA_FETCHING'})
    api.get("/api/getOrganizationalInformationValuesSelfService/"+id)
        .then((response) => {
          dispatch({ type: 'FDN_DATA_FETCHED_SUCCESS'})
            console.log("USERRESP===", response.data)
            let costcenterValues = response.data.costcenterMap
            let object = []
            if (typeof costcenterValues !== "undefined" && costcenterValues.length > 0) {
              costcenterValues.forEach((values) => {
                let val = { id: values.field1Key, label: values.field1Value };
                object.push(val);
              });
            }
          dispatch({ type: 'EDIT_ORG_MAP_CWL', payload:[{id:2853,label:"SUJI"}] });

      })
        .catch((err) => {
        dispatch({ type: 'FDN_DATA_FETCHED_FAILED'})
      });
  };
};

export const fetchUserJwt = (time, callback) => {
  return (dispatch) => {
    api.get("/api/fetchUserJwt" + `?expiresIn=${time}`)
      .then((response) => {
        dispatch({ type: 'FETCH_USER_JWT_SUCCESS', payload: response.data.jwtToken });
        callback('success');
      })
      .catch((err) => {
        callback('error');
      });
  };
};

export const emptyUserJwt = () => {
  return (dispatch) => {
    dispatch({ type: 'FETCH_USER_JWT_SUCCESS', payload: '' });
  };
};