
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useRef } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

const ForumDropdownPlusTypeahead = (props) => {
  const {
    className,
    options,
    onSelection,
    selectedOptions,
    setErrorColor,
    disabled = false,
    onInputChange,
    inputValue,
    onCrossClick,
    id,
    errorColor,
    formType,
    selected,
    defaultInputValue,
    inputs,
    metas,
    isInvalid,
    placeHolder
  } = props;

  const typeaheadRef = useRef(null);

  const onCrossClickFun = () => {
    onCrossClick();
    typeaheadRef.current.clear();
  };

  const inputProps = {
    value: inputValue ? inputValue : "",
  };

  return (
    <div className={"dvTypehd w-100 " + errorColor}>
      {inputValue ? (
        <span
          title="clear"
          role="img"
          aria-label="Clear"
          className="css-hakgx8 icn"
          onClick={onCrossClickFun}
        >
          <svg width="18" height="18" viewBox="0 0 24 24" role="presentation">
            <path
              d="M12 10.586L6.707 5.293a1 1 0 00-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 001.414 1.414L12 13.414l5.293 5.293a1 1 0 001.414-1.414L13.414 12l5.293-5.293a1 1 0 10-1.414-1.414L12 10.586z"
              fill="gray"
            ></path>
          </svg>
        </span>
      ) : null}
      <Typeahead
        clearButton
        id={id}
        ref={typeaheadRef}
        // {...(isInvalid && {
        //   isInvalid,
        // })}
        // inputProps={{
        //   className: isInvalid ? "error" : "",
        // }}
        onChange={onSelection}
        onInputChange={onInputChange}
        options={options}
        selected={selectedOptions}
        placeholder={placeHolder ? placeHolder : "Please choose..."}
        onBlur={setErrorColor}
        disabled={disabled}
        {...(selected && {
          selected: selected,
        })}
      />
    </div>
  );
};

export default ForumDropdownPlusTypeahead;
