
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, {Component } from 'react';
import Button from 'react-bootstrap/Button';
import AutoSuggest from 'react-autosuggest';
import axios from 'axios';

import { GLOBAL } from "_Globals";

let selectOptions = [];
const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});
const getSuggestions = (value,options) =>{
     const inputValue = value.trim().toLowerCase();
     const inputLength = inputValue.length;
      if(inputLength >2){
        selectOptions =options;
      }
     return inputLength < 3 ? []: selectOptions.filter(option => option.label.toLowerCase().indexOf(inputValue) > -1);
};
const getSuggestionValue = suggestion => suggestion.label.indexOf == -1 ? '' : suggestion.label.substring(0,suggestion.label.indexOf('|'));

function renderSuggestion(suggestion) {
    let suggestionlabel =[];
    suggestionlabel= suggestion.label.split("|");
    let ciNameKey="";
    let ciNameStr="";
    ciNameStr = suggestion.ciName != null && suggestion.ciName != "" ? suggestion.ciName : "";
    ciNameKey = ciNameStr != "" ? ciNameStr.replace(/(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/ig, '').trim() : "";
    ciNameKey = ciNameKey != "" ? ciNameKey.replace(/\s+/g, ' ') : "";
        return (
          <Button className='suggBtn' bsClass='' bsStyle='' onClick = {()=>{
            GLOBAL.ciIdEdit=suggestion.ciId;
            GLOBAL.ciNameEdit=suggestion.ciName;
            GLOBAL.ciNameCopy=suggestion.ciName;
            GLOBAL.ciIdNumberEdit=suggestion.ciIdNumber;
            GLOBAL.ciClass=suggestion.ciClass;
            GLOBAL.ciClassNameSelected=suggestion.ciClass;
            GLOBAL.categoryNameEdit= suggestion.categoryName;
            GLOBAL.environmentNameEdit= suggestion.environmentName;
            GLOBAL.ciConsumerCompanyEdit= suggestion.ciConsumerCompany;
            GLOBAL.ciSupportCompanyEdit= suggestion.ciSupportCompany;
            GLOBAL.ciClassIdEdit= suggestion.ciClassId;
            GLOBAL.ciLocationIdEdit= suggestion.ciLocationId;
            GLOBAL.ciLocationNameEdit= suggestion.ciLocationName;
            GLOBAL.businessCriticalityId = suggestion.businessCriticalityId===''||suggestion.businessCriticalityId===null?0:suggestion.businessCriticalityId;
            GLOBAL.businessCriticality = suggestion.businessCriticality;
            GLOBAL.serialNumberEdit= suggestion.serialNumber;
          }}>
            {ciNameKey.slice(0,70)}{ciNameKey.length > 70 ? "..." : ''}
            <div>
              Serial Number - {(suggestion.serialNumber == "" || suggestion.serialNumber == null) ? "NA" : suggestion.serialNumber}
            </div>
            <div>{suggestion.ciClass}{" - "}{suggestion.ciCompany}</div>
        </Button>
        );
}

class RFReactSelect extends Component {
    constructor(props){
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.onServiceNameSelected = this.onServiceNameSelected.bind(this);
        this.validateField = this.validateField.bind(this);
        this.state = {
          value: '',
          suggestions: []
        };
    }
    onChange(event,{newValue}){
        this.setState({
          value: newValue,
        });
        if(newValue.length===0){
          if(typeof this.props.showImpactedCIInfoIcon!=='undefined'){
            this.props.showImpactedCIInfoIcon();
          }
          this.props.isCiNameFun && this.props.isCiNameFun(true);
        }else{
          this.props.isCiNameFun && this.props.isCiNameFun(false);
        } 
    }
    onSuggestionsFetchRequested ({value}){
        if(typeof this.props.onType!=='undefined'){
          this.props.onType(false);
        }
        let ciNameDataDetails = [];
        let ciNameData=[];
        let ciNameObject={};
        if(value.length > 2) {
          api.get(GLOBAL.ciNameDetailsUrl+'?searchByList=STATUS,SUB_STATUS&multipleValueList=7|12&searchValue='+value+'&companyId='+this.props.companyId).then((response)=>{
            ciNameData = response.data;
            for (var i = 0; i < ciNameData.length; i++) {
              ciNameObject = {ciId:'',label:'',ciName:'',ciClass:'',ciCompany:'',ciIdNumber:'',ciIdRelated:'',serialNumber:''};
              ciNameObject.ciId=ciNameData[i].CI_ID;
              ciNameObject.label=ciNameData[i].CI_NAME+"|"+ciNameData[i].CLASS_NAME+"|"+ciNameData[i].COMPANY_NAME;
              ciNameObject.ciName=ciNameData[i].CI_NAME
              ciNameObject.ciClass=ciNameData[i].CLASS_NAME
              ciNameObject.ciCompany=ciNameData[i].COMPANY_NAME
              ciNameObject.categoryName=ciNameData[i].CATEGORY_NAME;
              ciNameObject.environmentName=ciNameData[i].ENVIRONMENT_NAME;
              ciNameObject.ciConsumerCompany=ciNameData[i].COMPANY_ID;
              ciNameObject.ciSupportCompany=ciNameData[i].SUPPORT_COMPANY_ID;
              ciNameObject.ciClassId=ciNameData[i].CLASS_ID;
              ciNameObject.ciLocationId=ciNameData[i].LOCATION_ID;
              ciNameObject.ciLocationName=ciNameData[i].LOCATION_NAME;
              ciNameObject.businessCriticalityId=ciNameData[i].METALLIC;
              ciNameObject.businessCriticality=ciNameData[i].METALLIC_NAME;
              ciNameObject.serialNumber=ciNameData[i].SERIAL_NUMBER;
              ciNameDataDetails.push(ciNameObject);
            }
            ciNameDataDetails = Array.from(new Set(ciNameDataDetails));
            this.setState({suggestions: getSuggestions(value,ciNameDataDetails)});
          });
        }
        else {
          this.setState({suggestions: []});
          if(this.state.value=='') {
            GLOBAL.ciIdEdit='';
            GLOBAL.ciNameEdit='';
            GLOBAL.ciIdNumberEdit='';
            GLOBAL.categoryNameEdit= '';
            GLOBAL.environmentNameEdit= '';
            GLOBAL.ciConsumerCompanyEdit= '';
            GLOBAL.ciSupportCompanyEdit= '';
            GLOBAL.ciClassIdEdit='';
            GLOBAL.ciLocationIdEdit= '';
            GLOBAL.ciLocationNameEdit= '';
            GLOBAL.serialNumberEdit= '';
          }
        }
    }
    onSuggestionsClearRequested (){
        this.setState({suggestions: []});
        if(this.state.value=='') {
          GLOBAL.ciIdEdit='';
          GLOBAL.ciNameEdit='';
          GLOBAL.ciIdNumberEdit='';
          GLOBAL.categoryNameEdit= '';
          GLOBAL.environmentNameEdit= '';
          GLOBAL.serialNumberEdit= '';
          GLOBAL.ciConsumerCompanyEdit= '';
          GLOBAL.ciSupportCompanyEdit= '';
          GLOBAL.ciClassIdEdit='';
          GLOBAL.ciLocationIdEdit= '';
          GLOBAL.ciLocationNameEdit= '';
          if(typeof this.props.checkforAMSattributes!=='undefined'){
            this.props.checkforAMSattributes('','');
          }
        }
    }
    onServiceNameSelected(){
        if(typeof this.props.onServiceSelect!=='undefined'){
           this.props.onServiceSelect();
        } 
        if(this.props.onServiceSelection!=undefined)
           this.props.onServiceSelection('imapactedCI');
        if(this.props.module=="breakfix edit"){
        this.props.onImpactedSelection(GLOBAL.ciIdEdit,GLOBAL.ciConsumerCompany);
        }   
    }
    validateField(){
      if(typeof this.props.setErrorColor!=='undefined'){
      if(GLOBAL.ciIdEdit==""){
          this.props.setErrorColor('error','consumer');
      }else{
          this.props.setErrorColor('','consumer');
      }
    }
    }
    render(){
        const { value, suggestions } = this.state;
        let ciNamevalue="";
        if(GLOBAL.ciName !="" || GLOBAL.ciName != undefined){
          ciNamevalue=GLOBAL.ciName
        }else{
          ciNamevalue="";
        }
        const inputProps = {
        placeholder: ciNamevalue != '' && ciNamevalue != undefined && ciNamevalue != 'null' ? ciNamevalue : "Enter CI Name",
        className: "form-control " + (this.props.errorColor || ""),
        value,
        onChange: this.onChange,
        onBlur: this.validateField,
      };
        return (
        <AutoSuggest
            suggestions = {suggestions}
            onSuggestionsFetchRequested = {this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue = {getSuggestionValue}
            renderSuggestion = {renderSuggestion}
            inputProps = {inputProps}
            onSuggestionSelected={this.onServiceNameSelected}
        />
      );
    }
}
export default RFReactSelect;