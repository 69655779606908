
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Field, change, reduxForm } from "redux-form";
import moment from "moment";
import momentTz from "moment-timezone";
import {
  TypeaheadExampleSingleSelect,
  _inputField,
  TypeaheadandDropdown,
} from "../../../common/formFields";
import {
  _dateField,
  _dateTimeField,
  _latentDateTimeField,
} from "../../../common/ReduxFormFields/formFields";
import { loadVendorCompany, loadCICompany, loadCIMenulistStatus, loadCIMenulistSubStatus } from "../../../../actions/cmdb/lovAction";
import { listDataWithType } from "../../../../actions/spcmActions";
import BudgetTypeAhead from "../create/BudgetTypeAhead.js";
import AdministratorTypeAhead from "../create/AdministratorTypeAhead.js";
import CmdbDropdownPlusTypeahead from "../../../cmdb/common/CmdbDropdownPlusTypeahead.js";
import { getadmingroup } from "../../../../actions/ham/contractAction";
import { GLOBAL } from "../../../Globals.js";

import Cookies from "universal-cookie";
const cookies = new Cookies();
let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();
function CreateForm(props) {
  const [ciCompanySelectedList, setCiCompanySelectedList] = useState([]);
  const [inputValueCompany, setInputValueCompany] = useState("");
  const ciCompany = useSelector((state) => state.ciCompany);
  const dispatch = useDispatch();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const [companyId, setCompanyId] = useState("");
  //VENDOR
  const vendorDetails = useSelector((state) => state.vendorSuccess);
  const [vendorList, setVendorList] = useState([]);
  const [selectedVendorList, setSelectedVendorList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState([]);

  // ADMINISTRATOR
  const adminGrpDetails = useSelector((state) => state.administratorGroupList);
  const [adminGrpList, setAdminGrpList] = useState([]);
  const [selectedAdminGrpList, setSelectedAdminGrpList] = useState([]);
  const [selectedAdminGrp, setSelectedAdminGrp] = useState([]);
  const [typedAdminGrp, setTypedAdminGrp] = useState("");
  const [administrator_id, setadministrator_id] = useState("");

  //COST CENTER
  const costCenterDetails = useSelector(
    (state) => state.spcmReducer.spcmDropDownCENTRE
  );
  const [costCenterList, setCostCenterList] = useState([]);
  const [selectedCostCenter, setSelectedCostCenter] = useState([]);

  //STATUS
  const statusDetails = useSelector((state) => state.ciStatus);
  const subStatusDetails = useSelector((state) => state.ciSubStatus);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  // const [typedVendor, setTypedVendor] = useState("");
  const [typedCostCenter, setTypedCostCenter] = useState("");
  const [lastReviewDate, setlastReviewDate] = useState("");
  const [lastReviewDateError, setlastReviewDateError] = useState("");
  const [nextReviewDate, setnextReviewDate] = useState("");
  const [nextReviewDateError, setnextReviewDateError] = useState("");
  const { isMandatory = true } = props;
  // console.log(vendorDetails, "vendorDetails");
  // console.log(costCenterDetails, "costCenterDetails");
  // console.log(subStatusDetails, "statusDetails11");

  const resetVendorData = useSelector((state) => (state.resetVendor));
  const resetCostCenterData = useSelector((state) => (state.resetCostCenter));
  const resetContractGroupData = useSelector((state) => (state.resetAdminList));

  useEffect(() => {
    let date = moment();
    dispatch(loadCICompany());
    dispatch(loadCIMenulistStatus("CONTRACT_STATUS", "", ""));
    // dispatch(loadCIMenulistSubStatus("CONTRACT_SUBSTATUS", "11"))
    dispatch(change("CreateContract", "start_date", date?._d));
    dispatch(change("CreateContract", "end_date", date?._d));
    setStartDate(date);
    setEndDate(date);
    // dispatch(change("CreateContract", "last_review_date", ''));
    // dispatch(change("CreateContract", "next_review_date",''));
    // dispatch(change("CreateContract", "date_revision", ''));
    GLOBAL.adminownerErrorColor = ''
    dispatch({ type: "RESET_VENDOR", payload: [] });
    dispatch({ type: "RESET_COST_CENTER", payload: [] });
    dispatch({ type: "RESET_CONTRACT_GROUP", payload: [] });

  }, []);
  useEffect(() => {
    if (adminGrpDetails && adminGrpDetails.length > 0) {
      let adminList = adminGrpDetails.map((element) => {
        return {
          id: element.id,
          label: element.name,
        };
      });
      setAdminGrpList(adminList);
    }
  }, [adminGrpDetails]);

  useEffect(() => {
    if (vendorDetails && vendorDetails.length > 0) {
      let venList = vendorDetails.map((element) => {
        return {
          id: element.field1Value,
          label: element.field1Key,
        };
      });
      setVendorList(venList);
    }
  }, [vendorDetails]);

  useEffect(() => {
    if (costCenterDetails && costCenterDetails.length > 0) {
      let ccList = costCenterDetails.map((element, index) => {
        return {
          id: index,
          label: element.costCenterName,
        };
      });
      setCostCenterList(ccList);
    }
  }, [costCenterDetails]);

  function onCompanySelection(selectedCompany) {
    props.setCompanyErrorState("");
    setCiCompanySelectedList(selectedCompany);
    setAdminGrpList([]);
    if (selectedCompany.length > 0) {
      dispatch(change("CreateContract", "company", selectedCompany[0].id));
      dispatch(change("CreateContract", "companyName", selectedCompany[0].label));
      dispatch(loadVendorCompany(selectedCompany[0].id));
      dispatch(getadmingroup(selectedCompany[0].id));
      dispatch(listDataWithType("Cost Center", selectedCompany[0].id));
      setCompanyId(selectedCompany[0].id);
      setInputValueCompany(selectedCompany[0].label);
    } else {
      dispatch(change("CreateContract", "company", ""));
      dispatch(change("CreateContract", "companyName", ""));
    }
  }

  function setCompanyErrorColor() {
    if (ciCompanySelectedList.length === 0) props.setCompanyErrorState("error");
    else props.setCompanyErrorState("");
  }

  function onCompanyInputChange(val) {
    setInputValueCompany(val);
    setAdminGrpList([]);
    setSelectedVendor([]);
    setSelectedAdminGrp([]);
    dispatch(change("CreateContract", "administrator_grp_name", ""));
  }

  function onCrossClickCompany() {
    dispatch(change("CreateContract", "company", ""));
    dispatch(change("CreateContract", "companyName", ""));

    setInputValueCompany("");
    setCiCompanySelectedList([]);
    dispatch(change("CreateContract", "module", ""));
    props.setCompanyErrorState("error");
    onCrossClickVendor();
    onCrossClickAdminGrp();
    onCostCenterCrossClick();
    onCrossClickBudget();
    onAdminstrationCrossClick();
    setCompanyId("");
  }

  const onVendorSelection = (selectedVendor) => {
    // console.log(selectedVendorList, "selectedVendorList")
    if (selectedVendor.length > 0) {
      setSelectedVendorList([...selectedVendorList, selectedVendor])
      setSelectedVendor(selectedVendor);
      dispatch(change("CreateContract", "vendor_name", selectedVendor));
      dispatch(change("CreateContract", "vendor_id", selectedVendor[0].id));
    } else {
      setSelectedVendor([]);
      dispatch(change("CreateContract", "vendor_name", ""));
      dispatch(change("CreateContract", "vendor_id", ""));
    }
  };

  // const vendorInputChange = (value) => {
  //   // setTypedVendor(value)
  //   setSelectedVendor([])
  //   onVendorSelection([])
  // };

  const onCrossClickVendor = () => {
    // setTypedVendor('')
    setSelectedVendor([]);
    dispatch(change("CreateContract", "vendor_name", ""));
    dispatch(change("CreateContract", "vendor_id", ""));
  };

  const onCostCenterSelection = (selectedCostCenter) => {
    if (selectedCostCenter.length > 0) {
      setSelectedCostCenter(selectedCostCenter);
      dispatch(
        change(
          "CreateContract",
          "cost_center_Name",
          selectedCostCenter[0].label
        )
      );
      dispatch(
        change("CreateContract", "cost_center_Id", selectedCostCenter[0].id)
      );
    } else {
      setSelectedCostCenter([]);
      dispatch(change("CreateContract", "cost_center_Name", ""));
      dispatch(change("CreateContract", "cost_center_Id", ""));
    }
  };

  const costCenterInputChange = (value) => {
    setTypedCostCenter(value)
    setSelectedCostCenter([]);
    onCostCenterSelection([])
  };

  const onCostCenterCrossClick = () => {
    setTypedCostCenter('')
    setSelectedCostCenter([]);
    dispatch(change("CreateContract", "cost_center_Name", ""));
    dispatch(change("CreateContract", "cost_center_Id", ""));
  };

  const onBudgetSelected = (suggestion) => {
    // console.log(suggestion, "suggestion0000");
    props.setSelectedBudget(suggestion);
    if (suggestion.length > 0) {
      dispatch(change("CreateContract", "budget_id", suggestion[0].id));
      dispatch(change("CreateContract", "budget_sponsor", suggestion[0].label));
    } else {
      dispatch(change("CreateContract", "budget_id", ""));
      dispatch(change("CreateContract", "budget_sponsor", ""));
    }
  };

  const onCrossClickBudget = () => {
    props.setSelectedBudget({});
    dispatch(change("CreateContract", "budget_id", ""));
    dispatch(change("CreateContract", "budget_sponsor", ""));
  };


  const onAdminstrationCrossClick = () => {
    props.setSelectedRequestor({});
    dispatch(change("CreateContract", "administrator_id", ""));
    dispatch(change("CreateContract", "administrator_name", ""));
  };

  const renderStatus = () => {
    if (statusDetails && statusDetails.length > 0) {
      return (
        <>
          {statusDetails.map((element, index) => {
            return (
              <option value={`${element.ID}|${element.STATUS_NAME}`}>
                {element.STATUS_NAME}
              </option>
            );
          })}
        </>
      );
    }
  };

  const renderSubStatus = () => {
    if (subStatusDetails && subStatusDetails.length > 0) {
      // console.log(subStatusDetails, "subStatusDetails");
      return (
        <>
          {subStatusDetails.map((element, index) => {
            return (
              <option value={`${element.ID}|${element.SUB_STATUS_NAME}`}>
                {element.SUB_STATUS_NAME}
              </option>
            );
          })}
        </>
      );
    }
  };

  const onAdminGrpSelection = (selectedAdminGrp) => {
    // console.log(selectedAdminGrp, "selectedAdminGrp")
    let error = '';
    if (selectedAdminGrp.length === 0) {
      error = 'error';
    props.setAdminErrorColor(error);
  }
    if (selectedAdminGrp.length > 0) {
      // setSelectedAdminGrpList([...selectedAdminGrpList, selectedAdminGrp])
      setSelectedAdminGrp(selectedAdminGrp);
      dispatch(change("CreateContract", "administrator_grp_name", selectedAdminGrp[0].label));
      dispatch(change("CreateContract", "administrator_grp_Id", selectedAdminGrp[0].id));
      props.setAdminValue(selectedAdminGrp)
      props.setAdminErrorColor('');
    } else {
      setSelectedAdminGrp([]);
      dispatch(change("CreateContract", "administrator_grp_name", ""));
      dispatch(change("CreateContract", "administrator_grp_Id", ""));
    }
  };

  const adminInputChange = (value) => {
    setTypedAdminGrp(value)
    setSelectedAdminGrp([])
    onAdminGrpSelection([])
  };
  
  const onCrossClickAdminGrp = () => {
    props.setAdminErrorColor('error')
    setTypedAdminGrp("");
    setSelectedAdminGrp([]);
    dispatch(change("CreateContract", "administrator_grp_name", ""));
    dispatch(change("CreateContract", "administrator_grp_Id", ""));
  };

  
  const setErrorColor = (errorVal,field) => {
    switch (field) {
			case 'administrator_id':
        setadministrator_id(errorVal);
        GLOBAL.adminownerErrorColor = errorVal;
        break;
    }
  }

  let companyTypeaheadOptions = [];
  if (ciCompany && ciCompany.length > 0) {
    ciCompany.forEach((item, i) => {
      let obj = {};
      obj.id = item.field1Value;
      obj.label = item.field1Key;
      companyTypeaheadOptions.push(obj);
    });
  }
  const validateEndDate=(date)=>{
    // console.log("Inside ValidateEnd")
    // console.log(date)
    setEndDate(date?._d)
    // console.table({
    //   "EValue":moment().isBefore(startDate),
    //   startDate,
    //   "endDate":date?._d
 
    // })
    if(moment(date?._d).isBefore(startDate)){
      setStartDateError("error");
      setEndDateError("error");
    }else{
      setStartDateError('');
      setEndDateError('');
    }
 
  }

  const validateStartDate=(date)=>{
    
    setStartDate(date?._d)
    // console.table({
    //   "Svalue":moment(date?._d).isAfter(endDate),
    //   "startDate":date?._d,
    //   endDate
    // })
    // if(moment(date?._d).isSameOrAfter(endDate)){ alert('if');
    //   setStartDateError("error")
    // }else{  alert('else');
    //   setStartDateError('')
    // }
    let start_date = momentTz(date, jwtdf + " hh:mm A");
    let end_date = momentTz(endDate, jwtdf + " hh:mm A");
    if(start_date.isSameOrAfter(end_date)){
      setStartDateError("error");
      setEndDateError("error");
    }else{
      setStartDateError('');
      setEndDateError('');
    }
 
  }

  const validatelastReviewDate=(date)=>{
    setlastReviewDate(date?._d)
    if(moment(date?._d).isSameOrAfter(nextReviewDate)){
      setlastReviewDateError("error")
    }else{
      setlastReviewDateError('')
    }
  }

  const validatenextReviewDate=(date)=>{
    setnextReviewDate(date?._d)
    if(moment(date?._d).isBefore(lastReviewDate)){
      setnextReviewDateError("error")
    }else{
      setnextReviewDateError('')
    }
  }
  const validateContractFields = (fieldType, event) => {
    var phoneno = /^[0-9;+]*$/;
    switch (fieldType) {

      case "vendor_contact":
        if (event.target.value.trim() != "" && !event.target.value.match(phoneno)) {
          props.setNumberErr("error");
        } else {
          props.setNumberErr("");
        }
        break;
      case "name":
        if (event.target.value == "") {
          props.setNameErr("error");
        } else {
          props.setNameErr("");
        }
        break;
      case "business_function":
        if (event.target.value == "") {
          props.setBusinessErr("error");
        } else {
          props.setBusinessErr("");
        }
        break;

      case "start_date":
        if (event.target.value == "") {
          props.setStartErr("error");
        } else {
          props.setStartErr("");
        }
        break;

      case "end_date":
        if (event.target.value == "") {
          props.setEndErr("error");
        } else {
          props.setEndErr("");
        }
        break;

      case "status":
        if (event.target.value == "") {
          props.setStatusErr("error");
          // console.log("Status111111");
        } else {
          // console.log("Enter Status111111");
          props.setStatusErr("");
        }
        break;


      case "sub_status":
      if (event.target.value == "") {
        props.setSubStatusErr("error");
        // console.log("Status111111");
      } else {
        // console.log("Enter Status111111");
        props.setSubStatusErr("");
      }
        break;
      default:
    }
  };

  // console.log(props, "props6");
  return (
    <>
      <Form>
        <Row>
        <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              <span className="rStar"></span>
              <Form.Label>{tr["Company"]}</Form.Label>
              <Field
                component={CmdbDropdownPlusTypeahead}
                onSelection={onCompanySelection}
                errorColor={props.companyErrorState}
                setErrorColor={setCompanyErrorColor}
                name="company"
                id="company"
                className="form-control"
                options={companyTypeaheadOptions}
                selectedOptions={ciCompanySelectedList}
                onInputChange={onCompanyInputChange}
                inputValue={inputValueCompany}
                onCrossClick={onCrossClickCompany}
              />
            </Form.Group>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              <span className="rStar"></span>
              <Form.Label>{tr["Contract Name"]}</Form.Label>
              <Field
                component={_inputField}
                name="name"
                className={"form-control " + props.nameErr}
                maxLength={50}
                onBlur={(event) => {
                  validateContractFields("name", event);
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
        <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              <Form.Label>{tr["Contract Type"]}</Form.Label>
              <Field
                name="contract_type"
                component="select"
                className={"form-control "}
              >
                <option value="">{tr["Select"]}</option>
                <option value="Insurance">{tr["Insurance"]}</option>
                <option value="Lease">{tr["Lease"]}</option>
                <option value="Maintenance">{tr["Maintenance"]}</option>
                <option value="Service">{tr["Service"]}</option>
                <option value="Software">{tr["Software"]}</option>
              </Field>
            </Form.Group>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              <span className="rStar"></span>
              <Form.Label>{tr["Business Function"]}</Form.Label>
              <Field
                name="business_function"
                component="select"
                className={"form-control " + props?.businessErr}
                onBlur={(event) => {
                  validateContractFields("business_function", event);
                }}
              >
                <option value="">{tr["Select"]}</option>
                <option value="Admin">{tr["Admin"]}</option>
                <option value="Finance">{tr["Finance"]}</option>
                <option value="HR">{tr["HR"]}</option>
                <option value="IT">{tr["IT"]}</option>
                <option value="Support">{tr["Support"]}</option>
              </Field>
            </Form.Group>
          </Col>
        </Row>
        <Row>
        <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              <span className="rStar"></span>
              <Form.Label>{tr["Start Date"]}</Form.Label>
              <div className="dateTimeSdiv dateTimeRightPos dateTimeBot">
                <Field
                  name="start_date"
                  component={_dateTimeField}
                  readOnlyValue={true}
                  className={
                    `form-control dateTimePickerField calIcon ${startDateError} ${props.startDateError} `
                  }
                  onClick={validateStartDate}
                  onChange={validateStartDate}
                  onBlur={validateStartDate}
                  formType="create"
                />
                {startDateError == "error" || props.startDateError == "error" ? "Start Date should be same or fall before end date." : ""}
              </div>
            </Form.Group>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              <span className="rStar"></span>
              <Form.Label>{tr["End Date"]}</Form.Label>
              <div className="dateTimeSdiv dateTimeRightPos dateTimeBot">
                <Field
                  name="end_date"
                  component={_dateTimeField}
                  readOnlyValue={true}
                  className={
                    `form-control dateTimePickerField calIcon ${endDateError} ${props.endDateError} `
                  }
                  onClick={validateEndDate}
                    onChange={validateEndDate}
                  formType="create"
                />
                {endDateError == "error" || props.endDateError == "error" ? "End Date should fall after Start Date." : ""}
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
        <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
            <span className="rStar"></span>
              <Form.Label>{tr["Status"]}</Form.Label>
              <Field
                name="status"
                className={"form-control " + props.statusErr}
                component="select"
                onBlur={(event) => {
                  validateContractFields("status", event);
                }}
                onChange={(event) => {
                  // console.log(event.target.value, "llllllllllll");
                  let statusDetails = event.target.value;
                  let statusDetailsArray = statusDetails.split("|");
                  let statusId = statusDetailsArray[0];
                  let statusName = statusDetailsArray[1];
                  dispatch(change("CreateContract", "status_id", statusId));
                  dispatch(change("CreateContract", "status_name", statusName));
                  dispatch(
                    loadCIMenulistSubStatus("CONTRACT_SUBSTATUS", statusId)
                  );
                }}
              >
                <option value="">Select</option>
                {renderStatus()}
              </Field>
            </Form.Group>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
            <span className="rStar"></span>
              <Form.Label>{tr["Sub Status"]}</Form.Label>
              <Field
                name="sub_status"
                className={"form-control " + props.substatusErr}
                component="select"
                onBlur={(event) => {
                  validateContractFields("sub_status", event);
                }}
                onChange={(event) => {
                  // console.log(event.target.value, "llllllllllll");
                  let statusDetails = event.target.value;
                  let statusDetailsArray = statusDetails.split("|");
                  let SubStatusId = statusDetailsArray[0];
                  let SubStatusName = statusDetailsArray[1];
                  dispatch(change("CreateContract", "sub_status_id", SubStatusId));
                  dispatch(change("CreateContract", "sub_status_name", SubStatusName));
                }}
              >
                <option value="">Select</option>
                {renderSubStatus()}
              </Field>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              <Form.Label>{tr["Vendor"]}</Form.Label>
              {/* {console.log(vendorList, "vendorListvendorList")} */}
              <div className="dvTypehdropdown">
              <Field
                name="vendor_name"
                className="form-control"
                component={TypeaheadandDropdown}
                onSelection={onVendorSelection}
                options={vendorList}
                selectedOptions={selectedVendor}
                resetVendorData={resetVendorData}
                // diableStatus={false}
                // onInputChange={vendorInputChange}
                onCrossClick={onCrossClickVendor}
                // typedValue={typedVendor}
                multiple={true}
              />
              </div>
            </Form.Group>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              
              <Form.Label>{tr["Vendor Contact"]}</Form.Label>
              <Field
                component={_inputField}
                name="vendor_contact"
                className={"form-control "+(props.numberErr) }
                placeholder="e.g. +919000000000"
                onBlur={(event) => {
                  validateContractFields("vendor_contact", event);
                }}
                maxLength={100} 
              />
            <span className="f-size-10">
              Please enter semicolon separated value (Ex. Value 1 ; Value 2 ;
              Value 3)
            </span>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
            {/* {console.log(costCenterList, "costCenterListcostCenterList")} */}
              <Form.Label>{tr["Cost Center"]}</Form.Label>
              <div className="dvTypehdropdown">
              <Field
                component={TypeaheadandDropdown}
                onSelection={onCostCenterSelection}
                name="cost_center_Id"
                className="form-control"
                options={costCenterList}
                diableStatus={false}
                selectedOptions={selectedCostCenter}
                onCrossClick={onCostCenterCrossClick}
                onInputChange={costCenterInputChange}
                typedValue={typedCostCenter}
                resetCostCenterData={resetCostCenterData}
              />
              </div>
            </Form.Group>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
            {/* <span className="rStar"></span> */}
              <Form.Label>{tr["Budget Sponsor"]}</Form.Label>
              <div className="dvTypehdropdown">
              <Field
                component={BudgetTypeAhead}
                onBudgetSelected={onBudgetSelected}
                name="budget_sponsor"
                className="form-control"
                companyId={companyId || ""}
                selectedOption={props.selectedBudget}
                resetSelectedOffering={onCrossClickBudget}
                placeHolder="Search by Name, Email, Employee Number"
                validateField={props.setBudgetErrorColor}
                errorColor={props.budgetError}
              />
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
        <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
            <span className="rStar"></span>
              <Form.Label>{tr["Contract Group"]}</Form.Label>
              <div className="dvTypehdropdown">
                 {/* {console.log(adminGrpList, "adminGrpListadminGrpList")} */}
              <Field
                name="administrator_group"
                id="administrator_group_id"
                className="form-control"
                component={TypeaheadandDropdown}
                onSelection={onAdminGrpSelection}
                options={adminGrpList}
                selectedOptions={selectedAdminGrp}
                diableStatus={false}
                onInputChange={adminInputChange}
                onCrossClick={onCrossClickAdminGrp}
                typedValue={typedAdminGrp}
                errorClass={props.adminTypeErr}
                resetContractGroupData={resetContractGroupData}
              />
              </div>
            </Form.Group>
          </Col>
        <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
            <span className="rStar"></span>
              <Form.Label>{tr["Contract Manager"]}</Form.Label>
              <div className="dvTypehdropdown">
                <AdministratorTypeAhead
                  id="administrator_id"
                  errorColor={
                    administrator_id === "" ? GLOBAL.adminownerErrorColor : administrator_id
                  }
                  setErrorColor={setErrorColor}
                />
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              <Form.Label>{tr["Version"]}</Form.Label>
              <Field
                component={"input"}
                name="version"
                className={"form-control "}
                maxLength={50}
              />
            </Form.Group>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              <Form.Label>{tr["Date of Revision"]}</Form.Label>
              <div className="dateTimeSdiv dateTimePosTop">
              <Field
                name="date_revision"
                component={_dateTimeField}
                className="form-control dateTimePickerField calIcon"
                readOnlyValue={true}
                formType="createNoDefault"
              />
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              <Form.Label>{tr["Last Review Date"]}</Form.Label>
              <div className="dateTimeSdiv dateTimePosTop">
              <Field
               name="last_review_date"
               component={_dateTimeField}
               readOnlyValue={true}
               className={`form-control dateTimePickerField calIcon ${lastReviewDateError} ${props.lastReviewDateError} `}
               onClick={validatelastReviewDate}
               onChange={validatelastReviewDate}
               formType="createNoDefault"
              />
              {lastReviewDateError == "error" || props.lastReviewDateError == "error" ? "Last Review Date should fall before Next Review Date." : ""}
              </div>
            </Form.Group>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              <Form.Label>{tr["Next Review Date"]}</Form.Label>
              <div className="dateTimeSdiv dateTimePosTop">
              <Field
               name="next_review_date"
               component={_dateTimeField}
               readOnlyValue={true}
               className={`form-control dateTimePickerField calIcon ${nextReviewDateError} ${props.nextReviewDateError} `}
               formType="createNoDefault"
               onClick={validatenextReviewDate}
               onChange={validatenextReviewDate}
              />
              {nextReviewDateError == "error" || props.nextReviewDateError == "error" ? "Next Review Date should fall after Last Review Date." : ""}
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={12} sm={6} xs={12}>
            <Form.Group className="form-group">
              <Form.Label>{tr["Description"]}</Form.Label>
              <Field
                component="textarea" 
                rows="3"
                name="description"
                className={"form-control "}
                maxLength={2000}
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default reduxForm({
  form: "CreateContract",
  destroyOnUnmount: false,
  enableReinitialize: true,
})(CreateForm);
