
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { loadAllTagsList } from '_Actions/foundation/tags/tagsAction';
import ListLoader from "../../common/loaders/ListLoader.js"
import TableComponent from "../../common/ReactTable/TableComponent";

const CompanyList = (props) => {
    const dispatch = useDispatch();
    const translator = useSelector((state) => state.spcmReducer.tr);
    const foundation_tags_reducer = useSelector((state) => state.foundation_tags_reducer);
    const foundation_tags_loader_reducer = useSelector((state) => state.foundation_tags_loader_reducer);

    useEffect(() => {
        dispatch(loadAllTagsList());
    }, []);

    const tableColumns = [
        {
            name: translator["Label"],
            selector: (row) => row.tagLabel,
            sortable: true,
        },
        {
            name: translator["Company"],
            selector: (row) => row.companyName,
            sortable: true,
        },
        // {
        //     name: translator["Business Function"],
        //     selector: (row) => row.businessFunction,
        //     sortable: true,
        // },
        {
            name: translator["Enabled"],
            selector: (row) => row.tagEnabled,
            sortable: true,
        },
        {
            name: translator["Module"],
            selector: (row) => row.tagModule,
            sortable: true,
        },
        {
            name: translator["Attribute type"],
            selector: (row) => row.tagType,
            sortable: true,
        },
        {
            name: translator["Created On"],
            selector: (row) => row.creatDate,
            sortable: true,
        },
        {
            name: translator["Modified On"],
            selector: (row) => row.lastUpdatedDate,
            sortable: true,
        }
    ];

    // const filterColumns = ["tagLabel", "companyName", "businessFunction", "tagModule", "tagType"];
    const filterColumns = ["tagLabel", "companyName", "tagModule", "tagType", "creatDate", "lastUpdatedDate", "tagEnabled"];

    const onRowSelect = (row) => {
        props.showRightSideFun(true);
        props.setSelectedItemFun(row);
    };

    const renderTable = (allTagsList) => {
        return (
            <div className="dtaTableDv integrateHubTable">
                <div className={props.rightDisplay ? "leftTrue srchPosTop fdnPos" : "rightTrue srchPosTop fdnPos"}>
                    <TableComponent
                        data={allTagsList}
                        columns={tableColumns}
                        headerColumns={filterColumns}
                        onRowSelect={onRowSelect}
                        rightDisplay={props.rightDisplay}
                        uniqueKey={'tagId'}
                    />
                </div>
            </div>
        );
    }

    if (foundation_tags_loader_reducer.showTagsLoader) {
        return <ListLoader />
    }
    else {
        return renderTable(foundation_tags_reducer.allTagsList);
    }
}

export default CompanyList;