
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState, useEffect, createRef, useRef } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { Field, Formik, Form } from "formik";
import { Row, Col, Button, Container } from "react-bootstrap";
import { ImInfo } from "react-icons/im";
import {
  InputTextField,
  SelectField,
  DatePickerF,
  InputTextFieldForChips,
  NonEditableField,
  StandardTextArea,
} from "../../../../common/FormFields";
import { useGetActionItemsQuery } from "../../../../../../reducers/governance/meeting/actionItem";
import { useGetOccurenceByIdQuery } from "../../../../../../reducers/governance/meeting/meeting";
import { getCookiesValuesByPositions } from "../../../../Helper";
import GridLayout from "../../../../common/GridLayout";
// import PindCategories from "../../../../../common/header/pind-categories";
import Breadcrumbs from "../../../../../common/header/breadcrumbs";
import CustomTypeHead from "../../../../common/CustomTypeHead";
import SystemInfo from "../../../../common/SystemInfo";
import Header from "./header";
import ActionItemRightSide from "./rightSide";

const ActionItemDetails = () => {
  const params = useParams();
  const formikRef = useRef(null);

  const location = useLocation();
  const companyID = location.state && location.state.companyID;
  const meetingOccurrananceId =
    location.state && location.state.meetingOccurrananceId;
  const meetingDefinationId =
    location.state && location.state.meetingDefinationId;
  const actionId = params?.actionId;

  const cookieValues = getCookiesValuesByPositions([2, 3]);
  const dateformat = cookieValues?.value1;
  const timezone = cookieValues?.value2;
  const characterLimit = 501;

  const [top, setTop] = useState("E");
  const [checkReasionCode, setCheckReasionCode] = useState(false);
  const [currentStatusId, setCurrentStatusId] = useState("");
  const [activeNav, setActiveNav] = useState(true);
  const [activeTabs, setActiveTabs] = useState("1");

  const toggle = (tab) => {
    setActiveNav(!tab);
    if (activeTabs !== tab) setActiveTabs(tab);
  };

  const validationSchema = Yup.object().shape({
    assignedTo: Yup.string().required("Required"),
    statusName: Yup.string(),
    alias: Yup.string(),
    actionOwnerChangeReason: checkReasionCode
      ? Yup.string().required("Required")
      : Yup.string(),
    reason: Yup.string().when("statusName", {
      is: (statusName, alias) =>
        statusName === "Pending" ||
        statusName === "Completed" ||
        statusName === "Cancelled" ||
        statusName === "Closed" ||
        alias === "Re-open" ||
        alias === "Re-assign",
      then: Yup.string().required("Required"),
      otherwise: Yup.string().nullable(true),
    }),
  });

  const [initValsNew, setInitValsNew] = useState({
    statusName: "",
    alias: "",
    description: "",
    statusId: "",
    reason: "",
    reason_code: "",
    assignedToFullName: "",
    performerList: [],
    actionOwnerChangeReason: "",
  });
  let initialValues = {
    ...initValsNew,
  };

  const onSubmit = async (payload, { setSubmitting }) => {};

  const {
    data: actionDetaildata,
    isFetching: actionDetailFetching,
    refetch: refetchMeetingById,
  } = useGetActionItemsQuery({
    queryString: `?filter[where][actionitemId]=${actionId}&filter[include][]=status&filter[include][]=priorityDetails&filter[include][]=urgencyDetails&filter[include][]=impactDetails&filter[include][]=performers&filter[include][]=worknotes`,
  });

  const store = useSelector((state) => state);

  // const { data: occurrenceDetaildata } = useGetOccurenceByIdQuery(
  //   { Id: occurenceId },
  //   {
  //     skip: !occurenceId,
  //   }
  // );

  useEffect(() => {
    setInitValsNew((prevState) => {
      return {
        ...prevState,
        ...(actionDetaildata?.[0] || []),
        // performerList: replaceKey(
        //   "performerName",
        //   "performerId",
        //   "label",
        //   "value",
        //   actionDetaildata?.[0]?.performers || [],
        //   "id",
        //   "updateId"
        // ),
      };
    });
    setTop(actionDetaildata?.[0]?.top);
    setCurrentStatusId(actionDetaildata?.[0]?.statusId);
  }, [actionDetaildata, actionDetailFetching]);

  return (
    <main>
      {/* <PindCategories /> */}
      <Container fluid className="margin-b-15 align-items-center">
        <Breadcrumbs
          activePageName={"Create"}
          parentPageurl={`/quickViewBreakFix`}
          parentPageName="Appointment"
        />
      </Container>
      <Container fluid>
        <div
        // className={
        //   actionDetaildata?.[0]?.top === "E" ? "border-0" : "border-0 pe-none"
        // }
        >
          <Row>
            <Col md={12} sm={12} xs={12}>
              <Header
                isSubmitting={formikRef?.current?.isSubmitting}
                dirty={formikRef?.current?.dirty}
                submitForm={formikRef?.current?.submitForm}
                formikref={formikRef}
                toggle={toggle}
                activeTabs={activeTabs}
                meetingOccurrananceId={meetingOccurrananceId}
              />
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({
                  isSubmitting,
                  setFieldValue,
                  resetForm,
                  errors,
                  values,
                  dirty,
                }) => {
                  return (
                    <Form noValidate>
                      <GridLayout cols={3}>
                        <div className="mb-3">
                          <NonEditableField
                            className="form-control disabled"
                            label="Impact"
                            value={values?.impactDetails?.impact}
                            type='dropdown'
                          />
                        </div>
                        <div className="mb-3">
                          <NonEditableField
                            className="form-control disabled"
                            label="Urgency"
                            value={values?.urgencyDetails?.urgency}
                            type='dropdown'
                          />
                        </div>
                        <div className="mb-3">
                          <NonEditableField
                            label="Priority"
                            className="form-control disabled"
                            value={values?.priorityDetails?.priority}
                            type='dropdown'
                          />
                        </div>
                        <div className="mb-3">
                          <NonEditableField
                            label="Expected By"
                            className="form-control disabled"
                            value={values?.expectedDueDate}
                            type='date'
                            // value={values?.expectedDueDate
                            //   ?.split(" ")[0]
                            //   .toString()}
                          />
                        </div>
                        <div className="mb-3">
                          <NonEditableField
                            className="form-control disabled"
                            label="Actual Start"
                            value={values?.actualStartDate}
                            type='date'
                          />
                        </div>
                        <div className="mb-3">
                          <NonEditableField
                            label="Actual End"
                            className="form-control disabled"
                            value={values?.actualEndDate}
                            type='date'
                          />
                        </div>
                      </GridLayout>
                      <GridLayout cols={1}>
                        <div className="mb-3">
                          <NonEditableField
                            className="form-control disabled"
                            label="Summary"
                            value={values?.additionalParams}
                            type='text'
                          />
                        </div>
                      </GridLayout>
                      <GridLayout cols={2}>
                        <div
                        // className={`mb-4 ${
                        //   actionDetaildata?.[0]?.top === "V" ? "disabledallField" : ""
                        // }`}
                        >
                          <StandardTextArea
                            rows={2}
                            name="description"
                            label="Description"
                            autoComplete="off"
                            value={values?.description}
                          />
                        </div>
                        <div>
                          <SelectField
                            name="statusId"
                            label="Status"
                          ></SelectField>
                        </div>
                      </GridLayout>
                      <GridLayout cols={2}>
                        <CustomTypeHead
                          required
                          clearButton
                          id="assignedToId"
                          name="assignedToId"
                          label="Action Owner"
                          thfor="personApi"
                          placeholder="Please type to search"
                          companyId={companyID}
                          autoComplete="off"
                          labelkey={(option) =>
                            option?.fullname
                              ? `${option?.fullname} | ${option?.email}`
                              : "fullname"
                          }
                          onChange={(selected) => {
                            setFieldValue("actionOwner", selected, true);
                            setFieldValue(
                              "assignedTo",
                              selected?.[0]?.fullname || ""
                            );
                            setFieldValue(
                              "assignedToId",
                              Number(selected?.[0]?.userId) || ""
                            );
                            setFieldValue(
                              "assignedToEmailAddress",
                              selected?.[0]?.email || ""
                            );
                            setFieldValue(
                              "assignedToFullName",
                              selected?.[0]?.fullname || ""
                            );
                            setFieldValue(
                              "assignedToCompanyId",
                              selected?.[0]?.companyId || ""
                            );
                            setFieldValue(
                              "assignedToCompany",
                              selected?.[0]?.companyName || ""
                            );
                          }}
                          selected={values?.actionOwner || []}
                        />
                        <CustomTypeHead
                          multiple
                          clearButton
                          id="performerlist"
                          name="performerList"
                          label="Action Performer"
                          thfor="personApi"
                          placeholder="Please type to search"
                          companyId={companyID}
                          autoComplete="off"
                          labelkey={(option) =>
                            option?.fullname
                              ? `${option?.fullname} | ${option?.email}`
                              : "fullname"
                          }
                          onChange={(selected) => {
                            setFieldValue("performerList", selected, true);
                          }}
                          selected={values?.performerList || []}
                        />
                      </GridLayout>
                      <GridLayout cols={1}>
                        <SystemInfo
                          header="System Information"
                          headers={[
                            "Created On",
                            "Created By",
                            "Modified On",
                            "Modified By",
                          ]}
                          values={[
                            values?.createdOn,
                            values?.createdByFullname,
                            values?.modifiedOn,
                            values?.modifiedByFullname,
                          ]}
                        />
                      </GridLayout>
                    </Form>
                  );
                }}
              </Formik>
            </Col>
            <Col md={4}>
              <ActionItemRightSide
                activeTabs={activeTabs}
                actData={actionDetaildata}
                meetingOccurrananceId={meetingOccurrananceId}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </main>
  );
};

export default ActionItemDetails;
