
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import {ImInfo} from 'react-icons/im';
import { Field, reduxForm, formValueSelector, change, getFormValues } from "redux-form";
import { connect } from "react-redux";
import axios from "axios";
import { bindActionCreators } from "redux";
import moment from "moment";
import RFReactConsumer from "../../../common/WorkItemBoard/AutoSearchConsumer";
import RFReactService from "../../../common/WorkItemBoard/AutoSearchService";
import CIDetailsService from "../../../common/WorkItemBoard/ciNameSelect";
import { loadBreakFixSourceName } from "../../../../actions/breakFix/sourceAction";
import { loadBreakFixCriticality } from "../../../../actions/breakFix/criticalityAction";
import { loadBreakFixUrgency } from "../../../../actions/breakFix/urgencyAction";
import { loadBreakFixSG } from "../../../../actions/breakFix/serviceGroupAction";
import { loadBreakFixSGI } from "../../../../actions/breakFix/serviceGroupIAction";
import {
  loadBreakFixSGAE,
  loadNewBreakFixSGAEWithQueryParams,
} from "../../../../actions/breakFix/serviceGroupAEAction";
import {
  getBreakfixGroupData,
  getCIGroupData,
  getAllgroup,
} from "../../../../actions/amsActions/amsActions";
import { _dateTimeField } from "../../../common/ReduxFormFields/formFields";
import { TypeaheadExampleSingleSelect } from "../../../common/formFields";
import { GLOBAL } from "_Globals";
import Cookies from "universal-cookie";
import { Button } from "react-bootstrap";
import { PiUserSwitch } from "react-icons/pi";
import { RiUserSearchLine } from "react-icons/ri";

import { RuleAmsDdropDown } from "../../../common/forms/ruleDropDown";
import { GrGroup } from "react-icons/gr";
import { getAdminPreferences } from "../../../../actions/adminPreferences/adminPreferencesAction";
import AismIndividualTypeAhead from "../../../common/WorkItemBoard/AismIndividualTypeAhead";
import AiIcon from "../../../common/aiIcon";
import { CharacterizatinForm } from "./CategorizationForm";

const cookies = new Cookies();
let languageSelected = cookies.get("gph");
languageSelected = languageSelected.split("~");
languageSelected = languageSelected[20];
const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

class CreateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment(),
      priorityValue: "",
      priorityColorValue: "",
      reportedThrough: "",
      supportPGroup: "",
      supportPIndividual: "",
      criticality: "",
      urgencyMode: "",

      impactedServiceName: "",
      consumer: "",
      issueDescription: "",
      reportedOnErrorColor: "",
      reportedThroughErrorColor: "",
      supportPGroupErrorColor: "",
      supportPAllGroupErrorColor: "",
      supportPIndividualErrorColor: "",
      criticalityErrorColor: "",
      urgencyModeErrorColor: "",
      sgErrorColor: "",
      sgIErrorColor: "",
      urgencyErrorColor: "",
      consumerDataDetails: [],
      ciNameDataDetails: [],
      companyId: "",
      onCreateUsingSelect: "",
      AllAMSGrps: false,
      companyError: "",
      isLoading: "",
      serviceListBasedOnCompany: [],
      companyList: [],
      selectedCompany: [],
      selectedImpactedCi: "",
      selectedConsumer: "",

      SpGroupSelectedList: [],
      SpIndSelectedList: [],
      SpAllGroupSelectedList: [],
      allgroup: [],
      isCompanyCleared: false,
      typedCompany: "",
      typedGroup: "",
      typedIndividual: "",
      showVIPIcon: "",
      serviceCompanyID: "",
      disableTypeAhead: true,
      urgencyList: {},
      impactList:  {},
      priorityList: {},
      selectedCompanyId: '',
      rulesAttribute:{},
      showAismGroups: false,
      aiServiceEnabled: false,
      isLoadingAismIndividual: false,
      numberOfTheCategorisationDropdowns: 0
    };
    this.onServiceNameChange = this.onServiceNameChange.bind(this);

    this.onConsumerNameChange = this.onConsumerNameChange.bind(this);
    this.onGroupNameChange = this.onGroupNameChange.bind(this);
    this.onChangeUrgency = this.onChangeUrgency.bind(this);
    this.setErrorColor = this.setErrorColor.bind(this);
    this.validateField = this.validateField.bind(this);
    this.setDropDownValue = this.setDropDownValue.bind(this);
    this.onChangeCriticality = this.onChangeCriticality.bind(this);
    this.sorting = this.sorting.bind(this);
    this.getLablel = this.getLabel.bind(this);
    this.onImpactedSelection = this.onImpactedSelection.bind(this);
    this.AllAMSGrps = this.AllAMSGrps.bind(this);
    this.ShowAMSGroup = this.ShowAMSGroup.bind(this);
    this.AllGrps = this.AllGrps.bind(this);
    this.setCompany = this.setCompany.bind(this);
    this.companyAPIcall = this.companyAPIcall.bind(this);
    this.onCompanySelection = this.onCompanySelection.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.setErrorCompanyColor = this.setErrorCompanyColor.bind(this);
    this.companyCleared = this.companyCleared.bind(this);
    this.onSupGroupSelection = this.onSupGroupSelection.bind(this);
    this.onIndivSelection = this.onIndivSelection.bind(this);
    this.onAllSupGroupSelection = this.onAllSupGroupSelection.bind(this);
    this.setSupGroupErrorColor = this.setSupGroupErrorColor.bind(this);
    this.setSupIndErrorColor = this.setSupIndErrorColor.bind(this);
    this.setAllSupGroupErrorColor = this.setAllSupGroupErrorColor.bind(this);
    this.onCrossClickCompany = this.onCrossClickCompany.bind(this);
    this.onGroupInputChange = this.onGroupInputChange.bind(this);
    this.onCrossClickGroup = this.onCrossClickGroup.bind(this);
    this.onIndividualInputChange = this.onIndividualInputChange.bind(this);
    this.onCrossClickIndividual = this.onCrossClickIndividual.bind(this);
    this.afterConsumerSelected = this.afterConsumerSelected.bind(this);
    this.setshowVIPIcon = this.setshowVIPIcon.bind(this);
    this.onConsumerSelected = this.onConsumerSelected.bind(this);
    this.checkforAMSattributes = this.checkforAMSattributes.bind(this);
    this.clearGroupandIndividual = this.clearGroupandIndividual.bind(this);
    this.resetCIRequesterFields = this.resetCIRequesterFields.bind(this);
    this.calculatePriority=this.calculatePriority.bind(this);
    this.setCriticalityPriority=this.setCriticalityPriority.bind(this);
    this.setUrgencyPriority=this.setUrgencyPriority.bind(this);
    this.fetchCompanyMatrix=this.fetchCompanyMatrix.bind(this);
    this.resetUrgencyandPriority=this.resetUrgencyandPriority.bind(this);
		this.resetGroupsandIndividual=this.resetGroupsandIndividual.bind(this);
    this.userAutoAssignment = this.userAutoAssignment.bind(this);
    this.showConsumerInfoIcon = this.showConsumerInfoIcon.bind(this);
    this.showServiceInfoIcon = this.showServiceInfoIcon.bind(this);
    this.showImpactedCIInfoIcon = this.showImpactedCIInfoIcon.bind(this);
    this.showIndividualInfoIcon =  this.showIndividualInfoIcon.bind(this);
    this.showAismGroupsFn = this.showAismGroupsFn.bind(this);
    this.setIsAismIndLoader = this.setIsAismIndLoader.bind(this);

    GLOBAL.ciName = "";
    GLOBAL.ciId = "";
    GLOBAL.ciIdNumber = "";
  }

  setIsAismIndLoader(value) {
    this.setState({ isLoadingAismIndividual: value })
  }
  calculatePriority(companyID,moduleName,urgency,criticality){
        return axios.get('/api/getCompanyPriorityMatrix?companyId='+companyID+'&module='+moduleName+'&urgency='+urgency+'&impact='+criticality);
  }
  setCriticalityPriority(priority,urgency,criticality){
    this.setState({priorityValue: priority});
    if(priority!=='' || (criticality!=='' && urgency!=='')){
       this.checkforAMSattributes('impact',priority);  
    }else if(criticality===''){
      this.props.dispatch({type:'GROUP_LIST_TYPE_AHEAD_INITIAL', typeaheadData: []});
      this.props.dispatch(change('xsmBreakFixFormTab','supportPGroup',''));
      this.props.dispatch(change('xsmBreakFixFormTab','supportPIndividual',''));
      this.props.dispatch(change('xsmBreakFixFormTab','supportPGroupName',''));
      this.props.dispatch(change('xsmBreakFixFormTab','supportPIndividualName',''));
      this.setState({SpGroupSelectedList: [], SpIndSelectedList: [],typedGroup: '', typedIndividual: '', disableTypeAhead: true});
      this.props.setAssignmentGrpUsr([],"group");
      this.showIndividualInfoIcon(false);
      this.props.showIndividualDetails(false);
    }
   }
  setUrgencyPriority(priority,urgency,criticality){
    this.setState({priorityValue: priority});
     if(priority!=='' || (criticality!=='' && urgency!=='')){
      this.checkforAMSattributes('urgency',priority);
     }else if(urgency===''){
      this.props.dispatch({type:'GROUP_LIST_TYPE_AHEAD_INITIAL', typeaheadData: []});
      this.props.dispatch(change('xsmBreakFixFormTab','supportPGroup',''));
      this.props.dispatch(change('xsmBreakFixFormTab','supportPIndividual',''));
      this.props.dispatch(change('xsmBreakFixFormTab','supportPGroupName',''));
      this.props.dispatch(change('xsmBreakFixFormTab','supportPIndividualName',''));
      this.setState({SpGroupSelectedList: [], SpIndSelectedList: [],typedGroup: '', typedIndividual: '', disableTypeAhead: true});
      this.showIndividualInfoIcon(false);
      this.props.showIndividualDetails(false);
     }
  }
  onConsumerSelected(suggestion) {
    const suggestionlabel = suggestion.fullname;
    this.setState({ selectedConsumer: suggestionlabel });
  }

  setshowVIPIcon(value) {
    this.setState({ showVIPIcon: value });
  }
  afterConsumerSelected() {
    if(GLOBAL.breakFixConsumerID!==''){
      this.showConsumerInfoIcon(true);
    }else{
      this.showConsumerInfoIcon(false);
    }
    this.checkforAMSattributes("", "");
    this.setshowVIPIcon(GLOBAL.Vip === "Yes" || GLOBAL.Vip==='yes' ? "yes" : "no");
  }

  companyAPIcall(compID) {
    GLOBAL.breakFixCompanyName = compID;
    let str = {},
      impactedServiceData = [];
    str.companyId = compID;
    str = JSON.stringify(str);
    api
      .get("/api/foundations/serviceListSelfServiceCompanyId/", {
        headers: { query: str },
      })
      .then((response) => {
        impactedServiceData = response.data;
        this.props.setServiceList(impactedServiceData);
        let serviceList = [];
        impactedServiceData.map((subItem) => {
          serviceList.push({
            value: subItem.offeringId,
            label:
              subItem.offeringName +
              "|" +
              subItem.categoryName +
              "|" +
              subItem.subCategoryName +
              "|" +
              subItem.companyName,
            companyname: subItem.company,
            categoryid: subItem.categoryId,
            categoryname: subItem.categoryName,
            offeringname: subItem.offeringName,
            subCategoryname: subItem.subCategoryName,
            consumercompanyname: subItem.companyName,
          });
        });
        this.setState({ serviceListBasedOnCompany: serviceList });
      });
  }

  setCompany(companyId) {
    GLOBAL.ciConsumerCompany = companyId[0].id;
    if (companyId[0].id != undefined) {
      this.companyAPIcall(companyId[0].id);
      this.setState({ companyId: companyId[0].id });
      this.props.setCompanyError("");
      (GLOBAL.breakFixConsumerID = ""),
        (GLOBAL.ciId = ""),
        (GLOBAL.breakFixServiceID = "");
      this.props.dispatch(change("xsmBreakFixFormTab", "supportPGroup", ""));
      this.props.dispatch(
        change("xsmBreakFixFormTab", "supportPIndividual", "")
      );
      
      this.props.dispatch(change('xsmBreakFixFormTab','supportPGroupName',''));
      this.props.dispatch(change('xsmBreakFixFormTab','supportPIndividualName',''));

      this.props.dispatch(change("xsmBreakFixFormTab", "ciName", ""));
      this.props.dispatch(
        change("xsmBreakFixFormTab", "impactedServiceName", "")
      );
      this.props.dispatch(
        change("xsmBreakFixFormTab", "consumerCompanyName", companyId[0].label)
      );
      this.props.dispatch(
        change("xsmBreakFixFormTab", "company", companyId[0].id)
      );
      this.props.getAdminPreferences(companyId[0].id, 'incident', '18');
      this.showConsumerInfoIcon(false);
      this.props.showConsumerDetails(false);
      this.showServiceInfoIcon(false);
      this.props.showServiceDetails(false);
      this.showImpactedCIInfoIcon(false);
      this.props.showImpactedCIDetails(false);
      this.showIndividualInfoIcon(false);
      this.props.showIndividualDetails(false);
    }
  }
  setErrorColor(errorColor, fieldType) {
    switch (fieldType) {
      case "impactedServiceName":
        this.setState({ impactedServiceName: errorColor });
        GLOBAL.serviceErrorColor = "";

        break;
      case "consumer":
        this.setState({ consumer: errorColor });
        GLOBAL.consumerErrorColor = "";
        break;
      default:
    }
  }
  setDropDownValue(event, fieldType) {
    switch (fieldType) {
      case "reportedThrough":
        this.setState({ reportedThrough: event.target.value });
        break;
      case "supportPGroup":
        this.setState({ supportPGroup: event.target.value });
        break;
      case "supportPIndividual":
        this.setState({ supportPIndividual: event.target.value });
        break;
      case "criticality":
        this.setState({ criticality: event.target.value });
        break;
      case "urgencyMode":
        this.setState({ urgencyMode: event.target.value });
        break;
      case "company":
        this.setState({ companyId: event.target.value });
        break;
      default:
    }
  }

  onChangeUrgency(event){ 
    if(event.target.value!=='' && this.props.criticality!==''){
     let criticalityValue= this.props.criticality;
     let urgencyValue=event.target.value;
     let priorityInputValue='';
     if(typeof this.state.priorityList[this.state.selectedCompanyId]==='undefined'){
       let returnedUrgencyPromise = this.calculatePriority(this.state.selectedCompanyId,'Breakfix',urgencyValue,criticalityValue);
       returnedUrgencyPromise.then((returnedPriority)=>{
        if(returnedPriority.data!==''){
          priorityInputValue=returnedPriority.data[0].priorityValue;
         let calculatedPriority=this.state.priorityList;
         calculatedPriority[this.state.selectedCompanyId]={};
         calculatedPriority[this.state.selectedCompanyId][urgencyValue+','+criticalityValue]={id: returnedPriority.data[0].priority, label: priorityInputValue};
         this.setState({priorityList: calculatedPriority});
         this.props.dispatch(change('xsmBreakFixFormTab','priorityActualVal',returnedPriority.data[0].priority));
         this.props.dispatch(change('xsmBreakFixFormTab','priorityLabel',returnedPriority.data[0].priorityValue));
         this.setUrgencyPriority(priorityInputValue,urgencyValue,criticalityValue);
        }else{
          this.setState({priorityValue: ''});
          this.props.dispatch(change('xsmBreakFixFormTab','priorityActualVal',''));
          this.props.dispatch(change('xsmBreakFixFormTab','priorityLabel',''));
          this.resetGroupsandIndividual();
        }
       });
     }else if(typeof this.state.priorityList[this.state.selectedCompanyId][urgencyValue+','+criticalityValue]==='undefined'){
      let returnedUrgencyPromise = this.calculatePriority(this.state.selectedCompanyId,'Breakfix',urgencyValue,criticalityValue);
      returnedUrgencyPromise.then((returnedPriority)=>{
        if(returnedPriority.data!==''){
         priorityInputValue=returnedPriority.data[0].priorityValue;
         let calculatedPriority=this.state.priorityList;
         calculatedPriority[this.state.selectedCompanyId][urgencyValue+','+criticalityValue]={id: returnedPriority.data[0].priority, label: priorityInputValue};
         this.setState({priorityList: calculatedPriority});
         this.props.dispatch(change('xsmBreakFixFormTab','priorityActualVal',returnedPriority.data[0].priority));
         this.props.dispatch(change('xsmBreakFixFormTab','priorityLabel',returnedPriority.data[0].priorityValue));
         this.setUrgencyPriority(priorityInputValue,urgencyValue,criticalityValue);
        }else{
          this.setState({priorityValue: ''});
          this.props.dispatch(change('xsmBreakFixFormTab','priorityActualVal',''));
          this.props.dispatch(change('xsmBreakFixFormTab','priorityLabel',''));
          this.resetGroupsandIndividual(); 
        }
      });
     }else{
      priorityInputValue=this.state.priorityList[this.state.selectedCompanyId][urgencyValue+','+criticalityValue];
			this.props.dispatch(change('xsmBreakFixFormTab','priorityActualVal',priorityInputValue.id));
			this.props.dispatch(change('xsmBreakFixFormTab','priorityLabel',priorityInputValue.label));
      this.setUrgencyPriority(priorityInputValue?.label,urgencyValue,criticalityValue); 
    }
    }else{
      this.setState({ priorityValue: '' });
      this.props.dispatch(change('xsmBreakFixFormTab','priorityActualVal',''));
      this.props.dispatch(change('xsmBreakFixFormTab','priorityLabel',''));
    }
  }

  onChangeCriticality(event){
    if(event.target.value!=='' && this.props.urgencyMode!==''){
     let criticalityValue= event.target.value;
     let urgencyValue=this.props.urgencyMode;
     let priorityInputValue='';
     if(typeof this.state.priorityList[this.state.selectedCompanyId]==='undefined'){
      let returnedCriticalityPromise = this.calculatePriority(this.state.selectedCompanyId,'Breakfix',urgencyValue,criticalityValue);
      returnedCriticalityPromise.then((returnedCriticality)=>{
        if(returnedCriticality.data!==''){
         priorityInputValue=returnedCriticality.data[0].priorityValue;
         let calculatedPriority=this.state.priorityList;
         calculatedPriority[this.state.selectedCompanyId]={};
         calculatedPriority[this.state.selectedCompanyId][urgencyValue+','+criticalityValue]={id: returnedCriticality.data[0].priority, label: priorityInputValue};
         this.setState({priorityList: calculatedPriority});
         this.props.dispatch(change('xsmBreakFixFormTab','priorityActualVal',returnedCriticality.data[0].priority));
         this.props.dispatch(change('xsmBreakFixFormTab','priorityLabel',returnedCriticality.data[0].priorityValue));
         this.setCriticalityPriority(priorityInputValue,urgencyValue,criticalityValue);
        }else{
          this.setState({priorityValue: ''});
          this.props.dispatch(change('xsmBreakFixFormTab','priorityActualVal',''));
          this.props.dispatch(change('xsmBreakFixFormTab','priorityLabel',''));
          this.resetGroupsandIndividual();
        }
      });
     }else if(typeof this.state.priorityList[this.state.selectedCompanyId][urgencyValue+','+criticalityValue]==='undefined'){
      let returnedCriticalityPromise = this.calculatePriority(this.state.selectedCompanyId,'Breakfix',urgencyValue,criticalityValue);
      returnedCriticalityPromise.then((returnedCriticality)=>{
        if(returnedCriticality.data!==''){
         priorityInputValue=returnedCriticality.data[0].priorityValue;
         let calculatedPriority=this.state.priorityList;
         calculatedPriority[this.state.selectedCompanyId][urgencyValue+','+criticalityValue]={id: returnedCriticality.data[0].priority, label: priorityInputValue};
         this.setState({priorityList: calculatedPriority});
         this.props.dispatch(change('xsmBreakFixFormTab','priorityActualVal',returnedCriticality.data[0].priority));
         this.props.dispatch(change('xsmBreakFixFormTab','priorityLabel',returnedCriticality.data[0].priorityValue));
         this.setCriticalityPriority(priorityInputValue,urgencyValue,criticalityValue);
        }else{
          this.setState({priorityValue: ''});
          this.props.dispatch(change('xsmBreakFixFormTab','priorityActualVal',''));
          this.props.dispatch(change('xsmBreakFixFormTab','priorityLabel',''));
          this.resetGroupsandIndividual();
        }
      });
     }else{
      priorityInputValue=this.state.priorityList[this.state.selectedCompanyId][urgencyValue+','+criticalityValue];
      this.props.dispatch(change('xsmBreakFixFormTab','priorityActualVal',priorityInputValue.id));
			this.props.dispatch(change('xsmBreakFixFormTab','priorityLabel',priorityInputValue.label));
      this.setCriticalityPriority(priorityInputValue?.label,urgencyValue,criticalityValue);
     }
    }else{
      this.setState({ priorityValue: '' });
    }
  }

  validateField(fieldType, event) {
    switch (fieldType) {
      case "issueDescription":
        if (event.currentTarget.defaultValue == "") {
          this.setState({ issueDescription: "error" });
          GLOBAL.issueErrorColor = "";
        } else {
          this.setState({ issueDescription: "" });
          GLOBAL.issueErrorColor = "";
        }
        break;
      case "reportedThrough":
        if (event.target.value == "") {
          this.setState({ reportedThroughErrorColor: "error" });
          GLOBAL.throughErrorColor = "";
        } else {
          this.setState({ reportedThroughErrorColor: "" });
          GLOBAL.throughErrorColor = "";
        }
        break;
      case "criticality":
        if (event.target.value == 0) {
          this.setState({ criticalityErrorColor: "error" });
          GLOBAL.criticalityErrorColor = "";
        } else {
          this.setState({ criticalityErrorColor: "" });
          GLOBAL.criticalityErrorColor = "";
        }
        break;
      case "urgencyMode":
        if (event.target.value == "") {
          this.setState({ urgencyModeErrorColor: "error" });
          GLOBAL.urgencyErrorColor = "";
        } else {
          this.setState({ urgencyModeErrorColor: "" });
          GLOBAL.urgencyErrorColor = "";
        }
        break;

      case "reportedOn":
        if (event._d == "") {
          this.setState({ reportedOnErrorColor: "error" });
          GLOBAL.reportedOnErrorColor = "";
        } else {
          this.setState({ reportedOnErrorColor: "" });
          GLOBAL.reportedOnErrorColor = "";
        }
        break;
      default:
    }
  }

  searchResultsValue() {
    this.setState({ searchResults: 0 });
  }

  componentDidMount() {
    GLOBAL.breakFixServiceID =
      GLOBAL.ciId =
      GLOBAL.breakFixConsumerID =
      GLOBAL.servicelocationid =
      GLOBAL.ciLocationId =
      GLOBAL.consumerCompanyId =
      GLOBAL.consumerCompanyName =
      GLOBAL.businessCriticalityId =
      GLOBAL.businessCriticality =
        "";
    GLOBAL.ciIdEdit =
      GLOBAL.ciNameEdit =
      GLOBAL.ciNameCopy =
      GLOBAL.ciIdNumberEdit =
      GLOBAL.ciClass =
      GLOBAL.ciClassNameSelected =
      GLOBAL.categoryNameEdit =
      GLOBAL.environmentNameEdit =
      GLOBAL.ciConsumerCompanyEdit =
      GLOBAL.ciSupportCompanyEdit =
      GLOBAL.ciClassIdEdit =
      GLOBAL.ciLocationIdEdit =
      GLOBAL.ciLocationNameEdit =
      GLOBAL.ciClassId =
      GLOBAL.ciClassName =
        "";
    GLOBAL.breakFixServiceID =
      GLOBAL.breakFixServiceName =
      GLOBAL.breakFixCompanyName =
      GLOBAL.consumerCompanybreakfix =
      GLOBAL.servicecatid =
      GLOBAL.servicecatname =
      GLOBAL.consumerCompanyName =
      GLOBAL.changeValue =
        "";
    GLOBAL.AMSEXIST = "No";
    this.setState({
      AllAMSGrps: false,
    });
    let myObj0 = {};
    let impactedServiceData = [];
    api.get("/api/serviceportfolio/usercompanylist").then((responseCompany) => {
      impactedServiceData = responseCompany.data;
      impactedServiceData.map((item) => {
        this.state.companyList.push({
          label: item.field1Key,
          id: item.field1Value,
        });
        console.log("this.state.companyList", this.state.companyList);
      });
    });
    this.props.loadBreakFixSourceName();
    this.props.dispatch({
      type: "LOAD_BREAKFIXSGAE_SUCCESS",
      breakFixSGAEName: "",
    });
    this.props.dispatch({
      type: "LOAD_BREAKFIXSGI_SUCCESS",
      breakFixSGIName: { data: "" },
    });
    this.props.dispatch(change('xsmBreakFixFormTab','reportedOn',moment()));
  }

  componentDidUpdate(previousProp){
    if(this.props.selectedAssignmentUser  !==  previousProp.selectedAssignmentUser && this.props.selectedAssignmentUser.length>0){
      this.onIndivSelection(this.props.selectedAssignmentUser);
    }
  }
  componentWillReceiveProps(newProps) {
    if (
      !Array.isArray(newProps.adminPreferenceForGroup) &&
      Object.keys(newProps.adminPreferenceForGroup).length > 0
    ) {
      if (newProps.adminPreferenceForGroup.value !== "") {
        try {
          const aismValue = JSON.parse(newProps.adminPreferenceForGroup.value);
          if(aismValue?.aism === "true" || aismValue?.aism === true) {
            this.setState({
              aiServiceEnabled: true,
            });
          }
        } catch (err) {
          console.log('Error: ', err);
        }
      } else {
        this.setState({
          aiServiceEnabled: false,
        });
      }
    } else {
      this.setState({
        aiServiceEnabled: false,
      });
    }

  }

  renderSourceName(breakFixData) {
    if (!breakFixData) {
      return <option></option>;
    }
    return breakFixData.map((breakFixObj, index) => {
      if (
        breakFixObj.field1Value != "55" &&
        breakFixObj.field1Value != "70" &&
        breakFixObj.field1Value != "75"
      ) {
        return (
          <option key={"sourceName_" + index} value={breakFixObj.field1Value}>
            {breakFixObj.field1Key}
          </option>
        );
      }
    });
  }

  renderPriorityName(breakFixData) {
    if (!breakFixData) {
      return <option></option>;
    }

    return breakFixData.map((breakFixObj, index) => {
      breakFixObj.field1Key = this.state.priorityValue;
      return (
        <option key={"priorityName_" + index} value={breakFixObj.field1Value}>
          {breakFixObj.field1Key}
        </option>
      );
    });
  }

  renderCriticalityName(breakFixData){
    if(!breakFixData[this.state.selectedCompanyId]){
      return;
    }
    return breakFixData[this.state.selectedCompanyId].map((breakFixObj)=>{
      return(
          <option value={breakFixObj.field1Value}>{breakFixObj.field1Key}</option>
        );
    });
  }

  sorting(json_object, key_to_sort_by) {
    function sortByKey(a, b) {
      let x = a[key_to_sort_by];
      let y = b[key_to_sort_by];
      return x < y ? -1 : x > y ? 1 : 0;
    }

    json_object.sort(sortByKey);
  }

  renderUrgencyName(breakFixData){
    if(!breakFixData[this.state.selectedCompanyId]){
      return;
    }
    return breakFixData[this.state.selectedCompanyId].map((breakFixObj)=>{

      return(
          <option value={breakFixObj.field1Value}>{breakFixObj.field1Key}</option>
        );
    });
  }
  companyCleared(e) {
    console.log("companyCleared", e);
    this.setState({
      isCompanyCleared: true,
      typedCompany: e,
      aiServiceEnabled: false,
    });
    this.setState({
      SpGroupSelectedList: [],
    });
    this.props.setAssignmentGrpUsr([],"group");
    this.resetCIRequesterFields();
  }
  componentWillUnmount() {
    console.log("component will unmount");
    GLOBAL.ciIdEdit =
      GLOBAL.ciNameEdit =
      GLOBAL.ciNameCopy =
      GLOBAL.ciIdNumberEdit =
      GLOBAL.ciClass =
      GLOBAL.ciClassNameSelected =
      GLOBAL.categoryNameEdit =
      GLOBAL.environmentNameEdit =
      GLOBAL.ciConsumerCompanyEdit =
      GLOBAL.ciSupportCompanyEdit =
      GLOBAL.ciClassIdEdit =
      GLOBAL.ciLocationIdEdit =
      GLOBAL.ciLocationNameEdit =
      GLOBAL.ciClassId =
      GLOBAL.ciClassName =
        "";
    GLOBAL.breakFixServiceID =
      GLOBAL.breakFixServiceName =
      GLOBAL.breakFixCompanyName =
      GLOBAL.consumerCompanybreakfix =
      GLOBAL.servicecatid =
      GLOBAL.servicecatname =
      GLOBAL.consumerCompanyName =
      GLOBAL.changeValue =
      GLOBAL.consumerCompanyId =
      GLOBAL.businessCriticalityId =
      GLOBAL.businessCriticality =
      GLOBAL.consumerCompanyName =
        "";
  }
  AllAMSGrps(e) {
    if (this.state.disableTypeAhead) {
      return;
    }

    this.checkforAMSattributes("", "");
    e.preventDefault();

    this.setState({
      AllAMSGrps: false,
      showAismGroups: false,
    });
    GLOBAL.AMSEXIST = "No";
    this.setState({
      SpGroupSelectedList: [],
    });
    this.props.dispatch(change("xsmBreakFixFormTab", "supportPGroup", ""));
    this.props.dispatch(change('xsmBreakFixFormTab','supportPGroupName',''));
    this.props.dispatch(change('xsmBreakFixFormTab','supportPIndividual',''));
    this.props.dispatch(change('xsmBreakFixFormTab','supportPIndividualName',''));
    this.onCrossClickGroup();
  }
  AllGrps(e) {
    if (this.state.disableTypeAhead) {
      return;
    }
    e.preventDefault();
    this.props.getAllgroup(this.state.companyId);

    this.setState({
      AllAMSGrps: true,
      ShowAllAMSGRP: false,
      showAismGroups: false,
    });
    GLOBAL.AMSEXIST = "yes";

    this.setState({
      SpGroupSelectedList: [],
    });
    this.props.dispatch(change("xsmBreakFixFormTab", "supportPGroup", ""));
    this.props.dispatch(change('xsmBreakFixFormTab','supportPGroupName',''));
    this.props.dispatch(change('xsmBreakFixFormTab','supportPIndividual',''));
    this.props.dispatch(change('xsmBreakFixFormTab','supportPIndividualName',''));
    this.onCrossClickGroup();
  }
  showAismGroupsFn() {
    this.setState({
      showAismGroups: true,
      SpGroupSelectedList: [],
    });

    GLOBAL.AMSEXIST = "No";

    this.props.dispatch(change("xsmBreakFixFormTab", "supportPGroup", ""));
    this.props.dispatch(change('xsmBreakFixFormTab','supportPGroupName',''));
    this.props.dispatch(change('xsmBreakFixFormTab','supportPIndividual',''));
    this.props.dispatch(change('xsmBreakFixFormTab','supportPIndividualName',''));
    this.onCrossClickGroup();
  }
  onServiceNameChange(serviceIdValue, companyNameVal) {
    if(this.state.onCreateUsingSelect==='service' && this.state.selectedCompanyId!==GLOBAL.consumerCompanyId){
       this.fetchCompanyMatrix(GLOBAL.consumerCompanyId,'Breakfix','Urgency,Impact',languageSelected,'1');
       this.setState({selectedCompanyId: GLOBAL.consumerCompanyId});
        this.resetUrgencyandPriority();
        this.resetGroupsandIndividual();
    }else{
      this.checkforAMSattributes("", "");
    }
    this.setState({
      isCompanyCleared: false,
    });
    this.setState({
      AllAMSGrps: false,
    });
    let ciNameDataDetails = [];
    let ciNameData = [];
    let ciNameObject = {};
    let consumerData = [];
    let consumerDataDetails = [];
    let myObj = {};
    let individualdata = [];
    if(GLOBAL.breakFixServiceID!==''){
      this.showServiceInfoIcon(true);
    }else{
      this.showServiceInfoIcon(false);
    }

    if (this.state.onCreateUsingSelect == "service") {
      this.setState({
        AllAMSGrps: false,
      });
      this.props.dispatch(
        change("xsmBreakFixFormTab", "supportPIndividual", "")
      );
      this.props.dispatch(change("xsmBreakFixFormTab", "supportPGroup", ""));

      this.props.dispatch(change('xsmBreakFixFormTab','supportPGroupName',''));
      this.props.dispatch(change('xsmBreakFixFormTab','supportPIndividualName',''));

    } else {
      console.log("Error in loading loadBreakFixSG");
    }
    if (this.state.onCreateUsingSelect == "service") {
      if (!isNaN(parseInt(companyNameVal, 10))) {
        console.log("companyNameVal", companyNameVal);
        let companyId = parseInt(companyNameVal, 10);
        this.setState({ serviceCompanyID: companyId });
      } else {
        console.log("error in loading impacted ci as no company id found.");
      }
    }
  }
  onImpactedSelection(impactedID, companyNameVal, suggestion) {
    if(this.state.onCreateUsingSelect==='impactedCI' && this.state.selectedCompanyId!==GLOBAL.ciConsumerCompany){
      this.fetchCompanyMatrix(GLOBAL.ciConsumerCompany,'Breakfix','Urgency,Impact',languageSelected,'1');
      this.setState({selectedCompanyId: GLOBAL.ciConsumerCompany});
        this.resetUrgencyandPriority();
        this.resetGroupsandIndividual();
    }else{
      this.checkforAMSattributes("", "");
    }
    this.setState({
      AllAMSGrps: false,
      selectedImpactedCi: suggestion.ciName,
    });
    console.log(
      "GLOBALGLOBAL",
      GLOBAL.ciId,
      GLOBAL.ciConsumerCompany,
      impactedID
    );
    if(GLOBAL.ciId!==''){
      this.showImpactedCIInfoIcon(true);
    }else{
      this.showImpactedCIInfoIcon(false);
    }
    if (impactedID != "") {
      if (this.state.onCreateUsingSelect == "impactedCI") {
        this.props.dispatch(
          change("xsmBreakFixFormTab", "supportPIndividual", "")
        );
        this.props.dispatch(change("xsmBreakFixFormTab", "supportPGroup", ""));

        this.props.dispatch(change('xsmBreakFixFormTab','supportPGroupName',''));
        this.props.dispatch(change('xsmBreakFixFormTab','supportPIndividualName',''));
        
      }
    }
  }
  onCreateUsingSelect(event) {
    this.setState({ onCreateUsingSelect: event.target.value });
    if (this.state.onCreateUsingSelect == "service") {
      this.props.setErrorServiceColor("");
    }
    if (this.state.onCreateUsingSelect == "impactedCI") {
      this.props.setErrorImpactedColor("");
    }
    this.props.dispatch({
      type: "GROUP_LIST_TYPE_AHEAD_INITIAL",
      typeaheadData: [],
    });
    this.props.dispatch(change("xsmBreakFixFormTab", "supportPGroup", ""));
    this.props.dispatch(change("xsmBreakFixFormTab", "supportPIndividual", ""));

    this.props.dispatch(change('xsmBreakFixFormTab','supportPGroupName',''));
    this.props.dispatch(change('xsmBreakFixFormTab','supportPIndividualName',''));
    
    this.setState({
      SpGroupSelectedList: [],
      SpIndSelectedList: [],
      typedGroup: "",
      typedIndividual: "",
    });
    this.setState({ disableTypeAhead: true });
    this.props.dispatch(change('xsmBreakFixFormTab','criticality',''));
    this.props.dispatch(change('xsmBreakFixFormTab','urgencyMode',''));
    this.props.dispatch(change('xsmBreakFixFormTab','priorityActualVal',''));
    this.props.dispatch(change('xsmBreakFixFormTab','priorityLabel',''));
    this.setState({priorityValue: '',selectedCompanyId: ''});
    this.showServiceInfoIcon(false);
    this.props.showServiceDetails(false);
    this.showImpactedCIInfoIcon(false);
    this.props.showImpactedCIDetails(false);
    this.showIndividualInfoIcon(false);
    this.props.showIndividualDetails(false);
  }
  onConsumerNameChange(companyNameVal) {
    // console.log('companyNameVal',companyNameVal)
  }

  onGroupNameChange(e) {
    this.props.loadBreakFixSGI(e.target.value);

    GLOBAL.supportPIGroupName = "";
    this.props.dispatch(
      change("xsmBreakFixFormTab", "supportPIndividual", "0")
    );
  }
  ShowAMSGroup(breakFixData) {
    console.log("breakFixDatagropup", breakFixData);
    let grouplist = [];
    let type = typeof breakFixData;
    console.log("sabc", type);
    if (
      breakFixData == "" ||
      breakFixData.length == 0 ||
      this.state.onCreateUsingSelect == "" ||
      type == "string"
    ) {
      return null;
    } else {
      breakFixData.map((breakFixObj) => {
        grouplist.push(breakFixObj.groupName + "~" + breakFixObj.groupId);
        this.state.allgroup.push({
          label: breakFixObj.groupName,
          id: breakFixObj.groupId,
        });
      });

      return grouplist.map((breakFixObj, index) => {
        let breakfixobj1 = breakFixObj.split("~");
        return (
          <option key={"amsGroup_" + index} value={breakfixobj1[1]}>
            {breakfixobj1[0]}
          </option>
        );
      });
    }
  }
  renderSupportPGroup(breakFixData) {
    let grouplist = [];
    if (!breakFixData || breakFixData.length == 0) {
      return null;
    }

    breakFixData.map((breakFixObj) => {
      grouplist.push(
        breakFixObj.assignmentGroupId + "~" + breakFixObj.assignmentGroupName
      );
    });

    grouplist = grouplist.filter(function (item, index, inputArray) {
      return inputArray.indexOf(item) == index;
    });

    return grouplist.map((breakFixObj, index) => {
      let breakfixobj1 = breakFixObj.split("~");
      return (
        <option key={"supportPGroup_" + index} value={breakfixobj1[0]}>
          {breakfixobj1[1]}
        </option>
      );
    });
  }

  renderSupportPIAEGroup(breakFixData) {
    if (!breakFixData || breakFixData.length == 0) {
      return null;
    }
    return breakFixData.map((breakFixObj, index) => {
      if (
        breakFixObj.assignmentGroupId == GLOBAL.breakfixgroupid &&
        breakFixObj.assignmentMemberId != 0 &&
        breakFixObj.assignmentMemberId != null
      )
        return (
          <option
            key={"supportPIAEGroup_" + index}
            value={breakFixObj.assignmentMemberId}
          >
            {breakFixObj.assignmentMemberName}
          </option>
        );
    });
  }

  setSupportIndividual(e) {
    GLOBAL.breakfixgroupid = e.target.value;
    GLOBAL.supportPGroupName = e.target[e.target.selectedIndex].text;
  }
  setSupportIndividualName(e) {
    GLOBAL.supportPIGroupName = e.target[e.target.selectedIndex].text;
  }
  getLabel(event) {
    GLOBAL.supportPIGroupName = "";
    this.props.breakFixSGIName.map((obj) => {
      if (obj.field1Value == event.target.value) {
        GLOBAL.supportPIGroupName = obj.field1Key;
      }
    });
  }

  renderSupportPIGroup(breakFixData) {
    console.log("kkk", breakFixData, this.props.breakFixSGAEName);
    if (!breakFixData || breakFixData.length == 0) {
      return null;
    }
    return breakFixData.map((breakFixObj, index) => {
      console.log("----");
      return (
        <option key={"supportPIGroup_" + index} value={breakFixObj.field1Value}>
          {breakFixObj.field1Key}
        </option>
      );
    });
  }

  onCompanySelection(UserCompany) {
    GLOBAL.breakFixServiceID = "";
    GLOBAL.breakFixServiceName = "";
    this.setState({
      SpGroupSelectedList: [],
    });
    this.setState({ selectedCompany: UserCompany });
    if (UserCompany.length !== 0) {
      this.checkforAMSattributes("company", UserCompany);
      this.setCompany(UserCompany);
    } else {
      this.setState({ serviceListBasedOnCompany: [] });
      this.setState({ companyId: "" });
      this.props.setCompanyError("");
      (GLOBAL.breakFixConsumerID = ""),
        (GLOBAL.ciId = ""),
        (GLOBAL.breakFixServiceID = "");
      this.props.dispatch(change("xsmBreakFixFormTab", "supportPGroup", ""));
      this.props.dispatch(
        change("xsmBreakFixFormTab", "supportPIndividual", "")
      );

      this.props.dispatch(change('xsmBreakFixFormTab','supportPGroupName',''));
      this.props.dispatch(change('xsmBreakFixFormTab','supportPIndividualName',''));

      this.props.dispatch(
        change("xsmBreakFixFormTab", "consumerCompanyName", "")
      );
      this.props.dispatch(change("xsmBreakFixFormTab", "company", ""));
      this.props.dispatch(change('xsmBreakFixFormTab','urgencyMode',''));
      this.props.dispatch(change('xsmBreakFixFormTab','criticality',''));
      this.props.dispatch(change('xsmBreakFixFormTab','priority',''));
      this.setState({priorityValue: ''});
      this.showConsumerInfoIcon(false);
      this.props.showConsumerDetails(false);
      this.showServiceInfoIcon(false);
      this.props.showServiceDetails(false);
      this.showImpactedCIInfoIcon(false);
      this.props.showImpactedCIDetails(false);
      this.showIndividualInfoIcon(false);
      this.props.showIndividualDetails(false);
    }
    this.setState({
      isCompanyCleared: true,
    });
    this.resetCIRequesterFields();
  }
  resetCIRequesterFields() {
    const { change } = this.props;
    change("consumer", "");
    change("impactedServiceName", "");
    change("ciName", "");
    this.setState({
      consumerDataDetails: [],
      ciNameDataDetails: [],
      serviceListBasedOnCompany: [],
      selectedConsumer: "",
      selectedImpactedCi: "",
      disableTypeAhead: true,
    });
  }
  setErrorCompanyColor() {
    if (this.state.selectedCompany.length === 0)
      this.setState({ companyError: "error" });
    else this.setState({ companyError: "" });
  }

  onSupGroupSelection(selectedGroup) {
    this.setState({
      supportPGroupErrorColor: "",
      SpGroupSelectedList: selectedGroup,
    });
    console.log("selectedGroup", selectedGroup);
    this.setState({
      SpIndSelectedList: [],
    });
    this.props.setAssignmentGrpUsr(selectedGroup,"group");
    if (selectedGroup.length > 0) {
           // this.setDropDownValue('supportPIndividual',)
      this.props.dispatch(
        change("xsmBreakFixFormTab", "supportPGroup", selectedGroup[0].id)
      );

      this.props.dispatch(change('xsmBreakFixFormTab','supportPGroupName',selectedGroup[0].label));
      this.props.dispatch(change('xsmBreakFixFormTab','supportPIndividual',''));
      this.props.dispatch(change('xsmBreakFixFormTab','supportPIndividualName',''));

      this.props.dispatch(change('xsmBreakFixFormTab','supportCompanyID',selectedGroup[0].supportCompanyID));
		  this.props.dispatch(change('xsmBreakFixFormTab','supportCompanyName',selectedGroup[0].supportCompanyName));
      this.props.loadBreakFixSGI(selectedGroup[0].id);
      GLOBAL.supportPGroupName = selectedGroup[0].label;
      GLOBAL.isExternalIncidentCreate =
        selectedGroup[0].isExternal == "" ||
        selectedGroup[0].isExternal == null ||
        selectedGroup[0].isExternal == undefined
          ? false
          : selectedGroup[0].isExternal;
      this.props.showIndividualDetails(false);
      this.showIndividualInfoIcon(false);    
    } else {
      this.props.dispatch(change("xsmBreakFixFormTab", "supportPGroup", ""));

      this.props.dispatch(change('xsmBreakFixFormTab','supportPGroupName',''));
      this.props.dispatch(change('xsmBreakFixFormTab','supportPIndividual',''));
      this.props.dispatch(change('xsmBreakFixFormTab','supportPIndividualName',''));
      this.props.showIndividualDetails(false);
      this.showIndividualInfoIcon(false);    
      this.setState({ supportPGroupErrorColor: "error" });
      GLOBAL.isExternalIncidentCreate = "";
    }
  }
  onIndivSelection(selectedGroup) {
    if (selectedGroup.length > 0) {
      this.setState({
        supportPIndividualErrorColor: "",
        SpIndSelectedList: selectedGroup,
      });
      GLOBAL.supportPIGroupName = selectedGroup[0].label;
      // this.setDropDownValue('supportPIndividual',)
      this.props.dispatch(
        change("xsmBreakFixFormTab", "supportPIndividual", selectedGroup[0].id)
      );
      this.props.dispatch(change('xsmBreakFixFormTab','supportPIndividualName',selectedGroup[0].label));
      this.showIndividualInfoIcon(true);
      this.props.setIndividualDetails(selectedGroup[0].id);
      // this.props.dispatch(change('xsmBreakFixFormTab','supportPIndividual',''));
    } else {
      this.props.dispatch(
        change("xsmBreakFixFormTab", "supportPIndividual", "")
      );
      this.setState({ supportPIndividualErrorColor: "error" });
      this.props.showIndividualDetails(false);
    }
  }
  onAllSupGroupSelection(selectedGroup) {
    this.setState({
      supportPAllGroupErrorColor: "",
      SpAllGroupSelectedList: selectedGroup,
    });
    console.log("selectedGroup", selectedGroup);
    this.setState({
      SpIndSelectedList: [],
    });
    if (selectedGroup.length > 0) {
      // this.setDropDownValue('supportPIndividual',)
      this.props.dispatch(
        change("xsmBreakFixFormTab", "supportP1Group", selectedGroup[0].id)
      );
      this.props.loadBreakFixSGI(selectedGroup[0].id);
      GLOBAL.supportPGroupName = selectedGroup[0].label;

      this.props.dispatch(change("xsmBreakFixFormTab", "supportPGroup", ""));
    } else {
      this.props.dispatch(change("xsmBreakFixFormTab", "supportP1Group", ""));
      this.setState({ supportPAllGroupErrorColor: "error" });
    }
  }
  setSupIndErrorColor() {
    if (this.state.SpIndSelectedList.length == 0)
      this.setState({ supportPIndividualErrorColor: "error" });
    else this.setState({ supportPIndividualErrorColor: "" });

    GLOBAL.sgIErrorColor = "";
  }
  setSupGroupErrorColor() {
    if (this.state.SpGroupSelectedList.length == 0)
      this.setState({ supportPGroupErrorColor: "error" });
    else this.setState({ supportPGroupErrorColor: "" });

    GLOBAL.sgErrorColor = "";
  }
  setAllSupGroupErrorColor() {
    if (this.state.SpAllGroupSelectedList.length == 0)
      this.setState({ supportPAllGroupErrorColor: "error" });
    else this.setState({ supportPAllGroupErrorColor: "" });

    GLOBAL.sgAllErrorColor = "";
  }
  handleSearch() {
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 2000);
  }

  onCrossClickCompany() {
    GLOBAL.ciConsumerCompany = "";
    this.companyCleared("");
    this.onCompanySelection([]);
    this.checkforAMSattributes("company", -1);
  }

  onGroupInputChange(e) {
    this.setState({
      typedGroup: e,
    });
    this.props.dispatch(change("xsmBreakFixFormTab", "supportPGroup", ""));

    this.props.dispatch(change('xsmBreakFixFormTab','supportPGroupName',''));
    this.props.dispatch(change('xsmBreakFixFormTab','supportPIndividual',''));
    this.props.dispatch(change('xsmBreakFixFormTab','supportPIndividualName',''));

    this.setState({
      supportPGroupErrorColor: "error",
      SpGroupSelectedList: [],
      SpIndSelectedList: [],
    });
    this.props.setAssignmentGrpUsr([],"group");
    GLOBAL.supportPGroupName = "";
  }
  onCrossClickGroup() {
    this.onGroupInputChange("");
    this.setState({ typedIndividual: "" });
    // this.onSupGroupSelection([]);
    this.props.setAssignmentGrpUsr([],"group");
    this.props.dispatch({type: "RESET_BREAKFIXSGI"})
    this.props.showIndividualDetails(false);
    this.showIndividualInfoIcon(false);
  }

  onIndividualInputChange(e) {
    // console.log('e - ',e);
    this.setState({
      typedIndividual: e,
    });

    if(e.length===0){
      this.showIndividualInfoIcon(false);
      this.props.showIndividualDetails(false);
    }
    this.props.dispatch(change("xsmBreakFixFormTab", "supportPIndividual", ""));
    this.setState({
      supportPIndividualErrorColor: "error",
      SpIndSelectedList: [],
    });
    GLOBAL.supportPIGroupName = "";
    this.props.dispatch(change('xsmBreakFixFormTab','supportPIndividualName',''));
  }
  onCrossClickIndividual() {
    this.onIndividualInputChange("");
    this.showIndividualInfoIcon(false);
    this.props.showIndividualDetails(false);
    // this.onIndivSelection([]);
  }
  checkforAMSattributes(fieldName, value) {
    let count = 0;
    if (fieldName === "company" && value.length > 0) {
      count++;
    } else if (value === -1) {
      count--;
    } else if (this.state.selectedCompany.length > 0) {
      count++;
    }

    if ((fieldName === "urgency" || fieldName === "impact") && value !== "") {
      count++;
    } else if (value === -1) {
      count--;
    } else if (this.state.priorityValue !== "") {
      count++;
    }

    if (this.state.onCreateUsingSelect === "impactedCI" && GLOBAL.ciId !== "") {
      count++;
    }

    if (
      this.state.onCreateUsingSelect === "service" &&
      GLOBAL.breakFixServiceID !== ""
    ) {
      count++;
    }

    if (GLOBAL.breakFixConsumerID !== "") {
      count++;
    }
    if (count === 4) {
      let amsAttribute = {
        Status: "0",
        Priority:
          (fieldName === "urgency" || fieldName === "impact") && value !== ""
            ? value
            : this.state.priorityValue !== ""
            ? this.state.priorityValue
            : "0",
        Service:
          this.state.onCreateUsingSelect === "service" &&
          GLOBAL.breakFixServiceID !== ""
            ? GLOBAL.breakFixServiceID
            : GLOBAL.breakFixServiceID !== ""
            ? GLOBAL.breakFixServiceID
            : "0",
        "Impacted CI":
          this.state.onCreateUsingSelect === "impactedCI" && GLOBAL.ciId !== ""
            ? GLOBAL.ciId
            : GLOBAL.ciId !== ""
            ? GLOBAL.ciId
            : "0",
        Requestor:
          GLOBAL.breakFixConsumerID == "" ? "0" : GLOBAL.breakFixConsumerID,
        Class: GLOBAL.ciClassId === "" ? "0" : GLOBAL.ciClassId,
        "User.Location":
          GLOBAL.servicelocationid == "" ? "0" : GLOBAL.servicelocationid,
        "CI.Location": GLOBAL.ciLocationId == "" ? "0" : GLOBAL.ciLocationId,
      };

      let rulesAttribute={
				"priorityId":(fieldName==='urgency'||fieldName==='impact') && value!=='' ? value : this.state.priorityValue==="" ? "" : this.state.priorityValue,
				"serviceName": this.state.onCreateUsingSelect === "service" && GLOBAL.breakFixServiceID !== "" ? GLOBAL.breakFixServiceName : GLOBAL.breakFixServiceID !== "" ? GLOBAL.breakFixServiceName: null,
				"impactedCi": this.state.onCreateUsingSelect === "impactedCI" && GLOBAL.ciName !== "" ? GLOBAL.ciName : null,
				"impactedUsername":this.state.selectedConsumer ==="" ? null :this.state.selectedConsumer,
				"ciClassName":GLOBAL.ciClassName==="" ? null :GLOBAL.ciClassName,
				"userLocation":GLOBAL.servicelocationname=="" ? null :GLOBAL.servicelocationname,
				"ciLocation":GLOBAL.ciLocationName=="" ? null :GLOBAL.ciLocationName,
        serviceId :
          this.state.onCreateUsingSelect === "service" &&
          GLOBAL.breakFixServiceID !== ""
            ? GLOBAL.breakFixServiceID
            : GLOBAL.breakFixServiceID !== ""
            ? GLOBAL.breakFixServiceID
            : "0",
        impactedCiId:
          this.state.onCreateUsingSelect === "impactedCI" && GLOBAL.ciId !== ""
            ? GLOBAL.ciId
            : GLOBAL.ciId !== ""
            ? GLOBAL.ciId
            : "0",
        requestorId:
          GLOBAL.breakFixConsumerID == "" ? "0" : GLOBAL.breakFixConsumerID,
        classId: GLOBAL.ciClassId === "" ? "0" : GLOBAL.ciClassId,
        userLocationId:
          GLOBAL.servicelocationid == "" ? "0" : GLOBAL.servicelocationid,
        ciLocationId: GLOBAL.ciLocationId == "" ? "0" : GLOBAL.ciLocationId,
			};

      this.setState({ rulesAttribute: rulesAttribute, disableTypeAhead: false });
    } else {
      this.setState({ disableTypeAhead: true });
    }
    this.props.dispatch({
      type: "GROUP_LIST_TYPE_AHEAD_INITIAL",
      typeaheadData: [],
    });
    this.props.dispatch(change("xsmBreakFixFormTab", "supportPGroup", ""));
    this.props.dispatch(change("xsmBreakFixFormTab", "supportPIndividual", ""));

    this.props.dispatch(change('xsmBreakFixFormTab','supportPGroupName',''));
    this.props.dispatch(change('xsmBreakFixFormTab','supportPIndividualName',''));

    this.setState({
      SpGroupSelectedList: [],
      SpIndSelectedList: [],
      typedGroup: "",
      typedIndividual: "",
      AllAMSGrps: false,
    });
    this.props.setAssignmentGrpUsr([],"group");
    this.showIndividualInfoIcon(false);
    this.props.showIndividualDetails(false);
  }
  clearGroupandIndividual() {
    this.props.dispatch({
      type: "GROUP_LIST_TYPE_AHEAD_INITIAL",
      typeaheadData: [],
    });
    this.props.dispatch(change("xsmBreakFixFormTab", "supportPGroup", ""));
    this.props.dispatch(change("xsmBreakFixFormTab", "supportPIndividual", ""));

    this.props.dispatch(change('xsmBreakFixFormTab','supportPGroupName',''));
    this.props.dispatch(change('xsmBreakFixFormTab','supportPIndividualName',''));

    this.setState({
      SpGroupSelectedList: [],
      SpIndSelectedList: [],
      typedGroup: "",
      typedIndividual: "",
      disableTypeAhead: true,
      AllAMSGrps: false,
    });
    this.props.setAssignmentGrpUsr([],"group");
  }
  fetchCompanyMatrix(companyId,moduleName,fieldName,languageSelected,status){
    if(typeof this.state.urgencyList[companyId]==='undefined'){
      axios.get('/api/company_menulist?companyId='+companyId+'&module='+moduleName+'&field='+fieldName+'&language='+languageSelected+'&status='+status).then((responseDropdownValues)=>{
        let oldUrgencyList=this.state.urgencyList;
        oldUrgencyList[companyId]=[];
        let oldImpactList=this.state.impactList;
        oldImpactList[companyId]=[];
        responseDropdownValues.data.map((item)=>{
           if(item.field2Name==='Impact'){
            oldImpactList[companyId].push(item);
           }else if(item.field2Name==='Urgency'){
            oldUrgencyList[companyId].push(item);
           }
        });
        this.setState({urgencyList: oldUrgencyList, impactList: oldImpactList});
        this.props.setGlobalList(companyId,oldUrgencyList,oldImpactList);
      });
    }
  }
  resetUrgencyandPriority(){
    this.setState({ urgencyMode: '', criticality: '', priorityValue: '' });
    this.props.dispatch(change('xsmBreakFixFormTab','urgencyMode',''));
    this.props.dispatch(change('xsmBreakFixFormTab','criticality',''));
    this.props.dispatch(change('xsmBreakFixFormTab','priorityActualVal',''));
    this.props.dispatch(change('xsmBreakFixFormTab','priorityLabel',''));
  }
  resetGroupsandIndividual(){
    this.props.dispatch({type: "LOAD_BREAKFIXSGAE_SUCCESS", breakFixSGAEName: []});
    this.props.dispatch({type: "GROUP_LIST", groupList: []});
    this.props.dispatch({type: "LOAD_BREAKFIXSGI_SUCCESS", breakFixSGIName: {data: []}});
    this.props.dispatch(change("xsmBreakFixFormTab", "supportPGroup", ""));
    this.props.dispatch(change("xsmBreakFixFormTab", "supportPIndividual", ""));

    this.props.dispatch(change('xsmBreakFixFormTab','supportPGroupName',''));
    this.props.dispatch(change('xsmBreakFixFormTab','supportPIndividualName',''));

    this.setState({SpGroupSelectedList: [], SpIndSelectedList: [], typedGroup: "", typedIndividual: "", AllAMSGrps: false, disableTypeAhead: true});
    this.props.setAssignmentGrpUsr([],"group");
    this.showIndividualInfoIcon(false);
    this.props.showIndividualDetails(false);
  }

  userAutoAssignment(){
    if (this.props.selectedAssignmentGroup) {
      let postData = {
        companyId: this.state.selectedCompanyId,
        groupId: this.props.selectedAssignmentGroup[0].id,
        itemType: "Incident",
        itemId: 0,
        itemNumber: 0,
      };
      return axios
        .post(`/rest/aiosem/userAutoTicketAssignationAction`, postData)
        .then((res) => {
     let data = res?.data?.data
     if(data && data.userId && data.userName){
       let selectedUser = [{ id:data.userId,label:data.userName}];
       this.props.setAssignmentGrpUsr(selectedUser,"user");
     }
    })
        .catch((error) => {
          console.log("==erorr", error);
        });
    }
 }
 showConsumerInfoIcon(value){
  if(!value){
     this.props.showIndividualDetails(false);
     this.showIndividualInfoIcon(false);
  }
  this.setState({showConsumerInfoButton: value});
 }
 showServiceInfoIcon(value){
  if(!value){
    this.props.showIndividualDetails(false);
    this.showIndividualInfoIcon(false);
  }
  this.setState({showServiceInfoButton: value});
 }
 showImpactedCIInfoIcon(value){
  if(!value){
    this.props.showIndividualDetails(false);
    this.showIndividualInfoIcon(false);
  }
  this.setState({showImpactedCIInfoButton: value});
 }
 showIndividualInfoIcon(value){
  this.setState({showIndividualInfoButton: value});
 }

  render() {
    let companyTypeaheadOptions = [];
    let companyTypeaheadOptions1 = [];
    let allGroupdata = [];
    let individualdata = [];
    let consumerList = [];
    let arrObjOne = [];
    if (this.props.breakFixSGAEName && this.props.breakFixSGAEName.length > 0) {
      this.props.breakFixSGAEName.forEach((item, i) => {
        let obj = {};
        obj.id = item.assignmentGroupId;
        obj.label = item.assignmentGroupName;
        obj.isExternal = item.isExternal;
        obj.supportCompanyID = item.assignmentCompanyId;
				obj.supportCompanyName = item.assignmentCompanyName;
        companyTypeaheadOptions.push(obj);
      });
      
      arrObjOne = [
        ...new Map(
          companyTypeaheadOptions.map((item) => [JSON.stringify(item), item])
        ).values(),
      ];
      console.log("arrObjOne", arrObjOne);
    }

    if (this.props.allgroupdata && this.props.allgroupdata.length > 0) {
      this.props.allgroupdata.forEach((item, i) => {
                let obj = {};
        obj.id = item.groupId;
        obj.label = item.groupName;
        obj.isExternal = item.isExternal;
        obj.supportCompanyID = item.supportCompanyID;
		    obj.supportCompanyName = item.supportCompanyName;
        allGroupdata.push(obj);
      });
          }
    if (this.props.breakFixSGIName && this.props.breakFixSGIName.length > 0) {
      this.props.breakFixSGIName.forEach((item, i) => {
        let obj = {};
        obj.id = item.field1Value;
        obj.label = item.field1Key;
        obj.isExternal = item.isExternal;
        individualdata.push(obj);
      });
    }

    let {
      error,
      handleSubmit,
      pristine,
      reset,
      submitting,
      impactedServiceName,
      consumer,
      issueDescription,
      additionalInfo,
      reportedOn,
      reportedThrough,
      supportPGroup,
      supportPIndividual,
      service,
      impactedUsers,
      priority,
      ciName,
    } = this.props;
    GLOBAL.priorityValueType = this.state.priorityValue;
    let errormsg = this.props.translator[error];
    if (this.props.breakFixSGName instanceof Error) {
      return <h2>Network Error Occured...Break Fix will be here soon....</h2>;
    }

    if (this.props.breakFixSGIName instanceof Error) {
      return <h2>Network Error Occured...Break Fix will be here soon....</h2>;
    }
    return (
      <div>
        {error == undefined || error == null ? null : (
          <Alert variant="danger" className="errorMessageSec">
            <p>{errormsg}</p>
          </Alert>
        )}

        <div className="borderForm bFormDiv">
          <Row>
            <Col md={6}>
              <Form.Group
              // validationState={
              //   this.state.companyError === ""
              //     ? this.props.companyError
              //     : this.state.companyError
              // }
              className="form-group"
              >
                <Form.Label>
                  <span className="rStar" />
                  {this.props.translator["Company"]}
                </Form.Label>
                <Field
                  id="companyDropdown"
                  name="company"
                  className="form-control"
                  component={TypeaheadExampleSingleSelect}
                  onSelection={this.onCompanySelection}
                  setErrorColor={this.setErrorCompanyColor}
                  options={this.state.companyList}
                  selectedOptions={this.state.selectedCompany}
                  onInputChange={this.companyCleared}
                  onCrossClick={this.onCrossClickCompany}
                  typedValue={this.state.typedCompany}
                  errorClass={this.state.companyError === "" ? this.props.companyError : this.state.companyError}
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group
              // validationState={
              //   this.state.consumer == ""
              //     ? GLOBAL.consumerErrorColor
              //     : this.state.consumer
              // }
              className="form-group"
              >
                <Form.Label>
                  <span className="rStar"></span>
                  {this.props.translator["Consumer"]}
                  {this.state.showVIPIcon === "yes" ? (
                    <i
                      className="vipIcn fa fa-diamond"
                      aria-hidden="true"
                      title={this.props.translator["VIP User"]}
                    ></i>
                  ) : (
                    ""
                  )}
                </Form.Label>
                <div className="position-re infoBtnDiv">
                  <Field
                    name="consumer"
                    options={this.state.consumerDataDetails}
                    companyId={this.state.companyId}
                    component={RFReactConsumer}
                    onConsumerNameChange={this.onConsumerNameChange}
                    setErrorColor={this.setErrorColor}
                    afterConsumerSelected={this.afterConsumerSelected}
                    checkforAMSattributes={this.checkforAMSattributes}
                    clearGroupandIndividual={this.clearGroupandIndividual}
                    setshowVIPIcon={this.setshowVIPIcon}
                    selectedOption={this.state.selectedConsumer}
                    onConsumerSelected={this.onConsumerSelected}
                    showConsumerInfoIcon={this.showConsumerInfoIcon}
                    showConsumerDetails={this.props.showConsumerDetails}
                    errorColor={this.state.consumer == "" ? GLOBAL.consumerErrorColor : this.state.consumer}
                  ></Field>
                  {this.state.showConsumerInfoButton?<Button title="Info" bsPrefix=" " className="infoBtnDiv infoicn" onClick={() => { this.props.showConsumerDetails(true); }}><ImInfo/></Button>:null}
                </div>

              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Form.Group
                className="radInpCs margin-0 form-group"
                onChange={this.onCreateUsingSelect.bind(this)}
              >
                <Field
                  name="service"
                  type="radio"
                  component="input"
                  value="service"
                />
                {this.props.translator["Service"]}

                <Field
                  name="service"
                  type="radio"
                  component="input"
                  value="impactedCI"
                />
                {this.props.translator["Impacted CI"]}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group
              //   validationState={this.props.serviceErrorColor}
              className="form-group"
              >
                <Form.Label>
                  {this.state.onCreateUsingSelect == "service" ||
                  this.state.onCreateUsingSelect == "" ? (
                    <span className="rStar" />
                  ) : null}
                  {this.props.translator["Which Service is impacted?"]}
                </Form.Label>
                <div className="position-re infoBtnDiv">
                  <Field
                    name="impactedServiceName"
                    options={this.state.serviceListBasedOnCompany}
                    disabled={
                      this.state.onCreateUsingSelect == "" ? true : false
                    }
                    setErrorServiceColor={this.props.setErrorServiceColor}
                    component={RFReactService}
                    onServiceNameChange={this.onServiceNameChange}
                    checkforAMSattributes={this.checkforAMSattributes}
                    onCreateUsingSelect={this.state.onCreateUsingSelect}
                    setErrorColor={this.setErrorColor}
                    clearGroupandIndividual={this.clearGroupandIndividual}
                    isInputClear={this.state.isCompanyCleared}
                    errorColor={this.props.serviceErrorColor}
                    showServiceInfoIcon={this.showServiceInfoIcon}
                    showServiceDetails={this.props.showServiceDetails}
                  ></Field>
                  {this.state.showServiceInfoButton?<Button title="Info" bsPrefix=" " className="infoicn" onClick={() => { this.props.showServiceDetails(true); }}><ImInfo/></Button>:null}
                </div>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group
              //   validationState={this.props.ImpactedErrorColor}
              className="form-group"
              >
                <Form.Label>
                  {this.state.onCreateUsingSelect == "impactedCI" ? (
                    <span className="rStar" />
                  ) : null}
                  {this.props.translator["Impacted CI"]}
                </Form.Label>
                <div className="position-re infoBtnDiv">
                  <Field
                    name="ciName"
                    options={this.state.ciNameDataDetails}
                    onImpactedSelection={this.onImpactedSelection}
                    companyId={this.state.companyId}
                    disabled={
                      this.state.onCreateUsingSelect == "" ? true : false
                    }
                    setErrorImpactedColor={this.props.setErrorImpactedColor}
                    onCreateUsingSelect={this.state.onCreateUsingSelect}
                    checkforAMSattributes={this.checkforAMSattributes}
                    clearGroupandIndividual={this.clearGroupandIndividual}
                    component={CIDetailsService}
                    selectedOption={this.state.selectedImpactedCi}
                    flagBreafix={"Breakfix_Create"}
                    errorColor={this.props.ImpactedErrorColor}
                    showImpactedCIInfoIcon={this.showImpactedCIInfoIcon}
                    showImpactedCIDetails={this.props.showImpactedCIDetails}
                  ></Field>
                  {this.state.showImpactedCIInfoButton?<Button title="Info" bsPrefix=" " className="infoicn" onClick={() => { this.props.showImpactedCIDetails(true); }}><ImInfo/></Button>:null}
                </div>
              </Form.Group>
            </Col>
            {/* <Col md={6}>
              <Form.Group
              // validationState={
              //   this.state.consumer == ""
              //     ? GLOBAL.consumerErrorColor
              //     : this.state.consumer
              // }
              className="form-group"
              >
                <Form.Label>
                  <span className="rStar"></span>
                  {this.props.translator["Consumer"]}
                  {this.state.showVIPIcon === "yes" ? (
                    <i
                      className="vipIcn fa fa-diamond"
                      aria-hidden="true"
                      title={this.props.translator["VIP User"]}
                    ></i>
                  ) : (
                    ""
                  )}
                </Form.Label>
                <div className="position-re">
                  <Field
                    name="consumer"
                    options={this.state.consumerDataDetails}
                    companyId={this.state.companyId}
                    component={RFReactConsumer}
                    onConsumerNameChange={this.onConsumerNameChange}
                    setErrorColor={this.setErrorColor}
                    afterConsumerSelected={this.afterConsumerSelected}
                    checkforAMSattributes={this.checkforAMSattributes}
                    clearGroupandIndividual={this.clearGroupandIndividual}
                    setshowVIPIcon={this.setshowVIPIcon}
                    selectedOption={this.state.selectedConsumer}
                    onConsumerSelected={this.onConsumerSelected}
                    errorColor={this.state.consumer == "" ? GLOBAL.consumerErrorColor : this.state.consumer}
                  ></Field>
                </div>
              </Form.Group>
            </Col> */}
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group
              // validationState={
              //   this.state.issueDescription == ""
              //     ? GLOBAL.issueErrorColor
              //     : this.state.issueDescription
              // }
              className="form-group"
              >
                <Form.Label>
                  <span className="rStar"></span>
                  {this.props.translator["Issue Description"]}
                </Form.Label>
                <div className="position-re">
                  <Field
                    name="issueDescription"
                    component="textarea"
                    className={"form-control " + (this.state.issueDescription == "" ? GLOBAL.issueErrorColor : this.state.issueDescription)}
                    maxLength="2000"
                    rows={2}
                    onBlur={(event) => {
                      this.validateField("issueDescription", event);
                    }}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group className="form-group">
                <Form.Label>
                  {this.props.translator["Additional Information, if any"]}
                </Form.Label>
                <Field
                  name="additionalInfo"
                  component="textarea"
                  className="form-control"
                  rows={2}
                />
              </Form.Group>
            </Col>
          </Row>

          {/* Categorization start here */}
            <CharacterizatinForm
              heading="Categorization"
              subHeading="Operational Category"
              opsLabelKey="opsCategory"
              opsValueKey="opsCategoryId"
              showDropDowns={3}
              module="Incident"
              companyId={this.state.companyId}
              formName="xsmBreakFixFormTab"
            />
          {/* Categorization end here */}

          <div className="margin-t-5 padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
            <div className="bgGray padding-5">
              <span className="rStar"></span>
              {this.props.translator["Prioritization"]}
            </div>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={6}>
                    <Form.Group
                    //   validationState={
                    //     this.state.urgencyModeErrorColor == ""
                    //       ? GLOBAL.urgencyErrorColor
                    //       : this.state.urgencyModeErrorColor
                    //   }
                    className="form-group"
                    >
                      <Form.Label>
                        <span className="rStar"></span>
                        {this.props.translator["Urgency"]}
                      </Form.Label>
                      <Field
                        name="urgencyMode"
                        component="select"
                        type="select"
                        className={"form-control " + (this.state.urgencyModeErrorColor == "" ? GLOBAL.urgencyErrorColor : this.state.urgencyModeErrorColor)}
                        value={this.state.urgencyMode}
                        onBlur={(event) => {
                          this.validateField("urgencyMode", event);
                        }}
                        onChange={(event) => {
                          this.setDropDownValue("urgencyMode", event);
                          this.onChangeUrgency(event);
                        }}
                      >
                        <option value="">Select</option>
                        {this.renderUrgencyName(this.state.urgencyList)}
                      </Field>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group
                    //   validationState={
                    //     this.state.criticalityErrorColor == ""
                    //       ? GLOBAL.criticalityErrorColor
                    //       : this.state.criticalityErrorColor
                    //   }
                    className="form-group"
                    >
                      <Form.Label>
                        <span className="rStar"></span>
                        {this.props.translator["Impact"]}
                      </Form.Label>
                      <Field
                        name="criticality"
                        component="select"
                        type="select"
                        className={"form-control " + (this.state.criticalityErrorColor == "" ? GLOBAL.criticalityErrorColor : this.state.criticalityErrorColor)}
                        value={this.state.criticality}
                        onBlur={(event) => {
                          this.validateField("criticality", event);
                        }}
                        onChange={(event) => {
                          this.setDropDownValue("criticality", event);
                          this.onChangeCriticality(event);
                        }}
                      >
                        <option value="">Select</option>
                        {this.renderCriticalityName(this.state.impactList)}
                      </Field>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label>{this.props.translator["Priority"]}</Form.Label>
                  <Field
                    name="priority"
                    component="input"
                    type="text"
                    className="form-control"
                    disabled={true}
                    placeholder={this.state.priorityValue}
                    value={this.state.criticality}
                  ></Field>
                </Form.Group>
              </Col>
            </Row>
          </div>

          <Row>
            <Col md={12}>
              <div className="padding-t-5 padding-r-10 padding-l-10 border">
                <div className="bgGray padding-5">
                  <span className="rStar"></span>
                  {this.props.translator[" Reported"]}
                </div>
                <Row>
                  <Col md={6}>
                    <Form.Group
                    //   validationState={
                    //     this.state.reportedOnErrorColor == ""
                    //       ? GLOBAL.reportedOnErrorColor
                    //       : this.state.reportedOnErrorColor
                    //   }
                    className="form-group"
                    >
                      <Form.Label>{this.props.translator["On"]}</Form.Label>
                      <div className="dateTimeSdiv dateTimePosTop">
                        <Field
                          name="reportedOn"
                          component={_dateTimeField}
                          onBlur={(event) => {
                            this.validateField("reportedOn", event);
                          }}
                          className="form-control dateTimePickerField"
                          formType="create"
                          errorColor={this.state.reportedOnErrorColor == ""
                                ? GLOBAL.reportedOnErrorColor
                                : this.state.reportedOnErrorColor}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group
                    //   validationState={
                    //     this.state.reportedThroughErrorColor == ""
                    //       ? GLOBAL.throughErrorColor
                    //       : this.state.reportedThroughErrorColor
                    //   }
                    className="form-group"
                    >
                      <Form.Label>
                        {this.props.translator["Through"]}
                      </Form.Label>
                      <Field
                        name="reportedThrough"
                        component="select"
                        type="select"
                        className={"form-control " + (this.state.reportedThroughErrorColor == ""
                              ? GLOBAL.throughErrorColor
                              : this.state.reportedThroughErrorColor)}
                        value={this.state.reportedThroughValidaton}
                        onBlur={(event) => {
                          this.validateField("reportedThrough", event);
                        }}
                        onChange={(event) => {
                          this.setDropDownValue("reportedThrough", event);
                        }}
                      >
                        <option value="">Select</option>
                        {this.renderSourceName(this.props.breakFixSourceName)}
                      </Field>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <div className="padding-t-5 padding-r-10 padding-l-10 border">
            <div className="bgGray padding-5">
              <span className="rStar"></span>
              {this.props.translator[" Support Provider"]}
              {this.props.roleIdentification("12") ||
              this.props.roleIdentification("13") ||
              this.props.roleIdentification("14") ||
              this.props.roleIdentification("48") ? (
                <span  className='float-r' style={{marginTop:"2px"}}>
                  {" "}
                  {(this.props.roleIdentification("13") || 
                  this.props.roleIdentification("14") || 
                  this.props.roleIdentification("48")) ? (this.state.AllAMSGrps == false ? (
                    <a
                      className="showwAllGrps"
                      href="javascript:void(0)"
                      onClick={this.AllGrps}
                    >
                      <GrGroup className='f-size-15' />Show ALL Groups
                    </a>
                  ) : (
                    <a
                      className="showwAllGrps"
                      href="javascript:void(0)"
                      onClick={this.AllAMSGrps}
                    >
                      <GrGroup className='f-size-15' />Show AMS Groups
                    </a>
                  )) : ''}
                </span>
              ) : null}{" "}
              {this.state.aiServiceEnabled && <span onClick={() => {if(!this.state.disableTypeAhead)this.showAismGroupsFn()}} style={{marginTop:"2px"}} className='margin-r-20 float-r'><a title='AI Recommended group' className="showwAllGrps showAlIcn f-size-13" href="javascript:void(0)"><AiIcon iconSize={18} />Show AISM Groups</a></span>}
            </div>
            <Row>
              <Col md={6}>
                {
                  <Form.Group
                  // validationState={
                  //   this.state.supportPGroupErrorColor == ""
                  //     ? GLOBAL.sgErrorColor
                  //     : this.state.supportPGroupErrorColor
                  // }
                  className="form-group"
                  >
                    <Form.Label>
                      <span className="rStar"></span>
                      {this.props.translator["Group"]}
                    </Form.Label>
                    <div className="dvTypehdropdown">
                    <RuleAmsDdropDown 
											 name="supportPGroup"
											component={TypeaheadExampleSingleSelect}
										  
											onSelection={this.onSupGroupSelection}
											setErrorColor={this.setSupGroupErrorColor}
											formData={this.props}
											rulesAttribute={this.state.rulesAttribute}
											moduleName="incident"
											ruleType="assignment"
											selectedOptions={this.state.SpGroupSelectedList}
											onInputChange={this.onGroupInputChange}
											onCrossClick={this.onCrossClickGroup}
											typedValue={this.state.typedGroup}
											id="groupDropdown"
                      disabled={this.state.disableTypeAhead}
											errorClass={this.state.supportPGroupErrorColor == '' ? GLOBAL.sgErrorColor : this.state.supportPGroupErrorColor}
                      showAms={this.state.AllAMSGrps}
                      groupData={allGroupdata}
                      ruleFor="resolver"
                      serviceCompanyId={this.state.serviceCompanyID}
                      showAismGroups={this.state.showAismGroups}
											/>
                      {/* <Field
                        component={TypeaheadExampleSingleSelect}
                        onSelection={this.onSupGroupSelection}
                        setErrorColor={this.setSupGroupErrorColor}
                        //  onSearch={this.handleSearch}
                        name="supportPGroup"
                        options={
                          this.state.AllAMSGrps == false
                            ? arrObjOne
                            : allGroupdata
                        }
                        selectedOptions={this.state.SpGroupSelectedList}
                        onInputChange={this.onGroupInputChange}
                        onCrossClick={this.onCrossClickGroup}
                        typedValue={this.state.typedGroup}
                        disabled={this.state.disableTypeAhead}
                        id="groupDropdown"
                        errorClass={this.state.supportPGroupErrorColor == ""
                            ? GLOBAL.sgErrorColor
                            : this.state.supportPGroupErrorColor}
                      /> */}
                    </div>
                  </Form.Group>
                }
              </Col>
              <Col md={6}>
                <Form.Group className={this.state.showIndividualInfoButton ? "indiBtnDv infoBtnDiv typeHeadicn downinfobtn form-group" : "prblmBtnDv infoBtnDiv typeHeadicn downinfobtn form-group"}>
                  <Form.Label>{this.props.translator["Individual"]}</Form.Label>
                  <div className="dvTypehdropdown">
                    {this.state.showAismGroups ? 
                      <AismIndividualTypeAhead
                        onSelection={this.onIndivSelection}
                        setErrorColor={this.setSupIndErrorColor}
                        className="form-control"
                        selectedOptions={this.state.SpIndSelectedList}
                        onInputChange={this.onIndividualInputChange}
                        onCrossClick={this.onCrossClickIndividual}
                        disabled={this.state.disableTypeAhead}
                        typedValue={this.state.typedIndividual}
                        formData={this.props}
                        setIsLoading={this.setIsAismIndLoader}
                        isLoadingAismIndividual={this.state.isLoadingAismIndividual}
                      /> 
                      :
                      <Field
                      component={TypeaheadExampleSingleSelect}
                      onSelection={this.onIndivSelection}
                      setErrorColor={this.setSupIndErrorColor}
                      name="supportPIndividual"
                      className="form-control"
                      options={individualdata}
                      selectedOptions={this.state.SpIndSelectedList}
                      onInputChange={this.onIndividualInputChange}
                      onCrossClick={this.onCrossClickIndividual}
                      typedValue={this.state.typedIndividual}
                      disabled={this.state.disableTypeAhead}
                      id="individualDropdown"
                    />}
                  </div>
                  {!this.state.disableTypeAhead && this.state.SpGroupSelectedList.length>0 && !this.state.isLoadingAismIndividual && <div className='indiIcnDv'>
										<Button title={this.props.translator["Auto Assign"]} bsPrefix=" " className="asignicn f-size-15" onClick={this.userAutoAssignment}><PiUserSwitch /></Button>
										<Button title={this.props.translator["Show available individuals"]} bsPrefix=" " className="asignicn" onClick={() => { this.props.showAvailableIndividual();}}><RiUserSearchLine /></Button>
                    {this.state.showIndividualInfoButton?<Button title="Info" bsPrefix=" " className="asignicn" onClick={() => { this.props.showIndividualDetails(true); }}><ImInfo/></Button>:null}
									</div>}
                </Form.Group>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

CreateForm = reduxForm({
  form: "xsmBreakFixFormTab",
  fields: [
    "impactedServiceName",
    "consumer",
    "issueDescription",
    "additionalInfo",
    "reportedOn",
    "reportedThrough",
    "supportPGroup",
    "supportP1Group",
    "supportPIndividual",
    "criticality",
    "urgencyMode",
    "ciName",
    "company",
  ],
  initialValues: {
    impactedServiceName: "",
    consumer: "",
    issueDescription: "",
    additionalInfo: "",
    reportedOn: "",
    reportedThrough: "",
    supportP1Group: "",
    supportPGroup: "",
    supportPIndividual: "",
    criticality: "",
    urgencyMode: "",
    ciName: "",
    company: "",
  },
  destroyOnUnmount: true,
})(CreateForm);

let selector = formValueSelector("xsmBreakFixFormTab");
CreateForm = connect((state) => {
  let supportPGroup = selector(state, "supportPGroup");
  let criticality = selector(state, "criticality");
  let urgencyMode = selector(state, "urgencyMode");
  let ItemList = getFormValues("xsmBreakFixFormTab")(state)   
  return {
    supportPGroup,
    criticality,
    urgencyMode,
    ItemList
  };
})(CreateForm);
export function mapStateToProps({
  breakFixSourceName,
  breakFixCriticalityName,
  breakFixUrgencyName,
  breakFixSGName,
  breakFixSGIName,
  breakFixSGAEName,
  allgroupdata,
  ItemList,
  admin_preferences_list_data_reducer
}) {
  breakFixSourceName = breakFixSourceName.filter((itm) => {
    return itm.field1Key != "Web";
  });
  return {
    breakFixSourceName,
    breakFixCriticalityName,
    breakFixUrgencyName,
    breakFixSGName,
    breakFixSGIName,
    breakFixSGAEName,
    allgroupdata,
    ItemList,
    adminPreferenceForGroup: admin_preferences_list_data_reducer?.adminPreferencesListData
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadBreakFixSourceName,
      loadBreakFixCriticality,
      loadBreakFixUrgency,
      loadBreakFixSG,
      loadBreakFixSGI,
      loadBreakFixSGAE,
      loadNewBreakFixSGAEWithQueryParams,
      getBreakfixGroupData,
      getCIGroupData,
      getAllgroup,
      getAdminPreferences
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateForm);
