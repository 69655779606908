
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from "axios";
const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

const defaultColors = {
  themeName: "default",
  pageBG: "#fcfdff",
  pageTxtColor: "#000000",
  primaryColor: "#282f30",
  secondaryColor: "#f78623",
  anchorColor: "#0a89a7",
  aiButtonBG: "#f78623",
  headerBG: "#717272",
  headerTxtColor: "#d0d0d0",
  tableHeader: "#eaeaea",
  tableColumn: "#ffffff",
  FontFamily:"Open Sans",
};

export const getAppliedThemeData = (companyId) => {
  let str = {};
  str["companyId"] = companyId;
  str["module"] = "Themes";
  str["propertyId"] = 13;
  str = JSON.stringify(str);
  return (dispatch) => {
    api
      .get("/api/getAdminPreferences", { headers: { query: str } })
      .then((response) => {
        const data = response?.data?.[0];
        const value = JSON.parse(data?.value);
        let isFirstTime = true;
        if(data.companyId !== "0"){
          isFirstTime = false;
        }
        if (data && typeof value == "object" && Object.keys(value).length > 0) {
          dispatch({
            type: "APPLIED_THEME_THEME_DATA_FETCHED",
            payload: { data: value, isFirstTime: isFirstTime },
          });
        } else {
          dispatch({
            type: "APPLIED_THEME_THEME_DATA_FETCHED",
            payload: { data: defaultColors, isFirstTime: isFirstTime },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
            type: "APPLIED_THEME_THEME_DATA_FETCHED",
            payload:  {data:defaultColors,isFirstTime:true},
          });
      });
  };
};
