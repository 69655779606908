
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
//below URL used for testing purpose
//const breakFixUrl = 'https://xsm-f.mybluemix.net/api/SX_FAVOURITEOFFERINGs/';
import { GLOBAL }  from '../../components/Globals';
//let breakFixUrl =`https://xsm-f.mybluemix.net/api/breakfixitem?breakfixitemId=`;
//let breakFixUrl=`https://xsm-f.mybluemix.net/groupListSelfService?companyId=2932&group=ResolvedER`
import constanturl from '../../../src/utils/constants.js';
export function loadCMDBAuditLogDetailsSuccess(cmdbAuditLogDetails){
	//alert('in success')
	return {
		type: 'LOAD_CMDBAUDITLOGDETAILS_SUCCESS',
		cmdbAuditLogDetails
	};
}

export function loadCMDBAuditLogDetailsFailure(errorResponse){
	return {
		type: 'LOAD_CMDBAUDITLOGDETAILS_FAILURE',
		errorResponse
	};
}

export function loadCMDBAuditLogDetails(breakFixId){
	//alert('action called and parameter is '+breakFixId)
	let url = '';
	return(dispatch) =>{
		//alert("EditGroupIdAction123"+breakFixId);
		if(breakFixId == undefined || breakFixId == ""){
			//alert("H2 breakFixId not found");
		}else{
				//alert("my url is "+GLOBAL.breakFixEditUrl+breakFixId);

				//url = constanturl.cmdburl+"/api/audit/"+breakFixId;
				url = GLOBAL.ciAuditLogUrl+"/id/"+breakFixId;

				dispatch({
					type:'FETCHING_AUDIT_FOR_TIMELINE'});
				axios.get(url)
					.then((cmdbAuditLogDetails)=>{
						if(!cmdbAuditLogDetails){//alert('not breakFix')
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							throw Error(cmdbAuditLogDetails.statusText);
						}
						return cmdbAuditLogDetails;
					})
					.then((cmdbAuditLogDetails) =>{//alert('yes it is')
					dispatch({
						type:'FETCHED_AUDIT_FOR_TIMELINE'});
						dispatch(loadCMDBAuditLogDetailsSuccess(cmdbAuditLogDetails))
					})
					.catch((err) => {
						console.log(err);
					});
					//console.log("not calling loadBreakFixEditDetails");
		}
	};
}
