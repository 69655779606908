
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Pagination from "react-js-pagination";
import Spinner from "react-spinkit";
import Alert from "react-bootstrap/Alert";
const Joi = require("joi");
import {
  clearSPCMArticles,
  clearInsidedArticles,
} from "../../../actions/breakFix/knowledgeArticleAction";
import {
  emptyInsidedReducer,
  getArticleById,
} from "../../../actions/knowledge/knowledgeAction";
import Cookies from "universal-cookie";
import { IoClose, IoSearch } from "react-icons/io5";
import { navigationHooks } from "../../../helpers/NavigationHook";
const cookies = new Cookies();
let username = cookies.get("gph");
if (username) username = username.replace("s:", "");
if (username) username = username.substring(0, username.lastIndexOf("."));
username = username.split("~");
username = username[32];
class SearchArticles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedArticles: [],
      showClearText: false,
      pageSize: 5,
      currentPage: 1,
      showError: false,
			error: 'Changes are not allowed in Deployed status',
    };
    this.getCombinedArticles = this.getCombinedArticles.bind(this);
    this.getAllArticles = this.getAllArticles.bind(this);
    this.changeSearchArticle = this.changeSearchArticle.bind(this);
    this.submitSearchArticle = this.submitSearchArticle.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.renderArticleHTML = this.renderArticleHTML.bind(this);
    this.linkArticle = this.linkArticle.bind(this);
    this.getLinkArticleObject = this.getLinkArticleObject.bind(this);
    this.checkArticleIsChecked = this.checkArticleIsChecked.bind(this);
    this.handleCheckedArticles = this.handleCheckedArticles.bind(this);
    this.checkAllArticleChecked = this.checkAllArticleChecked.bind(this);
    this.checkAllArticles = this.checkAllArticles.bind(this);
    this.linkAllArticles = this.linkAllArticles.bind(this);
    this.changeSearchFilter = this.changeSearchFilter.bind(this);
    this.clearSearchArticle = this.clearSearchArticle.bind(this);
    this.onPageSizeChange = this.onPageSizeChange.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.getItemsBasedOnPagination = this.getItemsBasedOnPagination.bind(this);
    this.dispatch = navigationHooks.dispatch;
  }

  componentWillUnmount() {
    this.dispatch({ type: "RESET_DATA" });
  }

  getCombinedArticles() {
    try {
      const { articleResultsList, insidedResultsList, searchFilter } =
        this.props;
      let articleResultsListTemp = JSON.parse(
        JSON.stringify(articleResultsList)
      );
      let insidedResultsListTemp;
      articleResultsListTemp = articleResultsListTemp.map((item) => {
        item.type = "SPCM";
        item = this.getLinkArticleObject(item);
        return item;
      });
      if (this.props.searchBy == "Topic ID") {
        insidedResultsListTemp = JSON.parse(JSON.stringify(insidedResultsList));
        let arr = [];
        if (insidedResultsList && insidedResultsList.id)
          arr.push(this.getLinkArticleObject(insidedResultsListTemp));
        insidedResultsListTemp = arr;
      } else if (this.props.searchBy == "Keyword") {
        insidedResultsListTemp = JSON.parse(
          JSON.stringify(insidedResultsList.topics)
        );
        insidedResultsListTemp = insidedResultsListTemp.map((item) => {
          if (item.contentType === "article") {
            item.type = "Knowledge";
          } else if (item.contentType === "conversation") {
            item.type = "conversation";
          } else if (item.contentType === "question") {
            item.type = "Community";
          }
          item = this.getLinkArticleObject(item);
          return item;
        });
      }
      let combinedArticlesArray = articleResultsListTemp.concat(
        insidedResultsListTemp
      );
      return combinedArticlesArray;
    } catch (e) {
      return [];
    }
  }

  getAllArticles() {
    try {
      let combinedArticlesArray = this.getCombinedArticles();
      return this.getItemsBasedOnPagination(combinedArticlesArray);
    } catch (e) {
      return [];
    }
  }

  changeSearchArticle(value) {
    const { showClearText } = this.state;
    if (showClearText) {
      this.setState({
        showClearText: false,
      });
    }
    if (!value) {
      this.clearSearchArticle();
    }
    this.props.setStateV1({
      searchArticle: value,
      messageText: "",
      messageType: "",
    });
  }

  submitSearchArticle() {
    const { searchArticle, showArticleTable } = this.props;
    if (searchArticle.trim().length) {
      if (!showArticleTable) {
        this.props.setStateV1({
          showArticleTable: true,
        });
      }
      this.setState({
        showClearText: true,
        pageSize: 5,
        currentPage: 1,
        selectedArticles: [],
      });
      let searchString =
        searchArticle && searchArticle.trim().length
          ? searchArticle.trim()
          : "";
      this.props.submitSearchArticle(searchString);
    } else {
      this.props.setStateV1({
        messageText: "Kindly provide the required input...",
        messageType: "error",
      });
    }
  }

  handleDismiss() {
		this.setState({ showError: false });
	}

  keyPress(e) {
    if (e.keyCode == 13) {
      this.submitSearchArticle();
    }
  }

  getLinkArticleObject(item) {
    function getDescription(itemObj) {
      let description = "";
      try {
        if (itemObj.type === "SPCM") {
          let arr = itemObj["EXTRACT_With_DOC_ID"]["extract_array"];
          arr.map((x) => {
            description += x;
          });
        } else {
          description = this.props.articleDetailsById;
        }
        return description;
      } catch (e) {
        return description;
      }
    }

    let obj = {};
    obj["offering_id"] = this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID;
    obj["work_item_type"] = "Breakfix";
    obj["createdOn"] = 0;
    obj["createdBy"] = "";
    obj["createdByName"] = "";
    obj["knowledgeArticle"] = item.title;
    obj["type"] = item.type;
    obj["knowledgeId"] = item.type === "SPCM" ? item["ID"] : item["id"];
    // obj['knowledgeId_insided'] = (item.type === "SPCM") ? "" : item['id'];
    obj["articleUrl"] = item["slug"] + "/" + item["id"];
    obj["topicId"] = item["id"];
    obj["knowledgeDescription"] = getDescription(item);
    return obj;
  }

linkArticle(item) {
  if (this.props.serviceStatusState === "Deployed") {
    this.setState({ showError: true }); 
  } else {
    let arr = [];
    arr.push(item);
    this.props.postKnowledgeArticles(arr);
  }
}

linkAllArticles() {
  const { selectedArticles } = this.state;
  if (this.props.serviceStatusState === "Deployed") {
    this.setState({ showError: true }); 
  } else if (!selectedArticles.length) {
    this.props.setStateV1({
      messageText: "Please select articles",
      messageType: "error",
    });
    return false;
  } else {
    this.props.postKnowledgeArticles(selectedArticles);
  }
}

  checkArticleIsChecked(type, knowledgeId) {
    const { selectedArticles } = this.state;
    let flag = false;
    selectedArticles.forEach((item) => {
      if (item.knowledgeId === knowledgeId) {
        return (flag = true);
      }
    });
    return flag;
  }

  handleCheckedArticles(value, item) {
    const { selectedArticles } = this.state;
    let selectedArticlesTemp = JSON.parse(JSON.stringify(selectedArticles));
    if (value) {
      let obj = selectedArticlesTemp.find((elem) => {
        return elem.knowledgeId === item.knowledgeId;
      });
      if (!obj) {
        selectedArticlesTemp.push(item);
      }
      this.setState({ selectedArticles: selectedArticlesTemp });
    } else {
      let tempArr = [];
      selectedArticlesTemp.map((elem) => {
        if (!(elem.knowledgeId === item.knowledgeId)) {
          tempArr.push(elem);
        }
      });
      this.setState({ selectedArticles: tempArr });
    }
  }

  renderArticleHTML() {
    const that = this;
    let allArticlesList = this.getAllArticles();
    if (allArticlesList.length == 0) {
      return (
        <tr>
          <td colSpan={4} className="article-table-td-align-center">
            {["No Knowledge References Available"]}
          </td>
        </tr>
      );
    }
    return allArticlesList.map((item, index) => {
      console.log("renderArticleHTML");
      console.log(item);

      let iconClass =
        item.type === "SPCM"
          ? "KA-icon interLnk"
          : item.type === "Knowledge" || item.type === "conversation"
          ? "KA-icon exterLnk"
          : "KA-icon community";

      return (
        <tr
          key={`searchedArticles_${index}`}
          className={`searchedArticles_${index}`}
        >
          <td className="text-c">
            <input
              type="checkbox"
              checked={this.checkArticleIsChecked(
                item.type,
                item["knowledgeId"]
              )}
              onClick={(e) => {
                this.props.setStateV1({
                  messageText: "",
                  messageType: "",
                });
                this.handleCheckedArticles(e.target.checked, item);
              }}
            />
          </td>
          <td className="text-c">{item.topicId}</td>
          <td className="kn-title">
            <span
              onClick={() => {
                that.props.showDescription(item);
                that.props.getArticleById(item.knowledgeId, username);
              }}
            >
              <a href="javascript:void(0)">
                <span
                  style={{
                    verticalAlign: "middle",
                    backgroundPosition: "0px 1px",
                  }}
                  className={iconClass}
                  title={
                    item.type == "conversation" ? "Knowledge" : [item.type]
                  }
                ></span>
              </a>
              {that.props.renderLargeTextEllipse(item.knowledgeArticle)}
            </span>
          </td>
          <td className="">
            <a
              className="margin-r-10"
              href="javascript:void(0)"
              title={["Link Article"]}
              onClick={() => {
                that.linkArticle(item);
              }}
            >
              <i
                style={{ position: "relative", top: "2px" }}
                className="fa fa-chevron-circle-right f-size-16 colorStBlue"
              ></i>
            </a>
            <a
              title={item.type === "SPCM" ? ["Download"] : ["Redirect"]}
              onClick={() => {
                that.props.downloadFile(
                  item.type,
                  item["articleUrl"],
                  item["id"]
                );
              }}
              href="javascript:void(0)"
            >
              <i
                style={{ position: "relative", top: "2px" }}
                className={
                  item.type === "SPCM"
                    ? "fa fa-download f-size-16 colorStBlue"
                    : "fa fa-external-link f-size-15 colorStBlue"
                }
              ></i>
            </a>
          </td>
        </tr>
      );
    });
  }

  checkAllArticleChecked() {
    const { selectedArticles } = this.state;
    let allArticlesList = this.getAllArticles();
    if (
      !(
        allArticlesList &&
        Array.isArray(allArticlesList) &&
        allArticlesList.length
      )
    ) {
      return false;
    }
    let flag = true;
    allArticlesList.forEach((item) => {
      let find = selectedArticles.find((elem) => {
        return elem.knowledgeId === item.knowledgeId;
      });
      if (!find) {
        flag = false;
      }
    });
    return flag;
  }

  checkAllArticles(value) {
    let allArticlesList = this.getAllArticles();
    let selectedArticlesTemp = [];
    if (value) {
      selectedArticlesTemp = [...allArticlesList];
    }
    this.setState({
      selectedArticles: selectedArticlesTemp,
    });
  }

  changeSearchFilter(e) {
    this.props.setStateV1({ searchFilter: e.target.value });
  }

  clearSearchArticle() {
    this.setState({ showClearText: false });
    this.props.setStateV1({
      searchArticle: "",
      messageText: "",
      messageType: "",
      showArticleTable: false,
    });
    this.props.clearSPCMArticles();
    this.props.clearInsidedArticles();
  }

  onPageSizeChange(e) {
    this.setState({ pageSize: e.target.value, currentPage: 1 });
  }

  onPageChange(value) {
    this.setState({ currentPage: value });
  }

  getItemsBasedOnPagination(totalItems) {
    try {
      const { currentPage, pageSize } = this.state;
      const startIndex =
        Number(currentPage) * Number(pageSize) - Number(pageSize);
      return totalItems.slice(startIndex, startIndex + Number(pageSize));
    } catch (e) {
      return totalItems || [];
    }
  }

  render() {
    // console.log('search articles *********',{state:  this.state, props: this.props});
    const { showClearText, pageSize, currentPage } = this.state;
    const {
      searchArticle,
      showArticleTable,
      searchFilter,
      checkInsidedApplicable,
      translator = {},
      isFetchingDetails_KA: { isFetching, isFetchingAttachedKA },
    } = this.props;

    const combinedArticlesArray = this.getCombinedArticles();

    return (
      <div className="knowledge-search-section">
        <Row className="">
          {checkInsidedApplicable && (
            <Col className="knTySelect" md={4}>
              <Form.Select
                aria-label="select"
                onChange={(e) => this.changeSearchFilter(e)}
                value={searchFilter}
                disabled={isFetching || isFetchingAttachedKA}
              >
                <option value="all">{["All"]}</option>
                <option value="knowledge">{["Knowledge"]}</option>
                <option value="community">{["Community"]}</option>
              </Form.Select>
            </Col>
          )}
          {this.state.showError && (<Alert style={{ "marginBottom": "15px", "fontSize": "13px", "padding": "8px 30px 8px 8px" }} variant="danger" onDismiss={this.handleDismiss}><h4>{this.state.error}</h4></Alert>)}
          <Col md={checkInsidedApplicable ? 8 : 12}>
            <div className="knSearchDiv knSearchDvdropdown">
              <Form.Select
                aria-label="select"
                value={this.props.searchBy}
                onChange={(e) => {
                  this.props.setSearchBy(e.target.value);
                  this.clearSearchArticle();
                }}
                className="form-control hide36"
              >
                <option value="Keyword">{"Keyword"}</option>
                <option value="Topic ID">{["Topic ID"]}</option>
              </Form.Select>
              <input
                className="form-control hide36"
                name="searchArticle"
                value={searchArticle}
                onKeyDown={this.keyPress}
                onChange={(e) => this.changeSearchArticle(e.target.value)}
                placeholder={["Search Knowledge Articles"]}
                disabled={isFetching || isFetchingAttachedKA}
              />
              {searchArticle.trim().length > 2 ||
              searchArticle.trim().length == 0 ||
              this.props.searchBy == "Topic ID" ? (
                <Button
                  onClick={() => {
                    if (showClearText) {
                      this.clearSearchArticle();
                    } else {
                      this.submitSearchArticle();
                    }
                  }}
                  disabled={isFetching || isFetchingAttachedKA}
                >
                  {showClearText ? <IoClose /> : <IoSearch />}
                </Button>
              ) : null}
            </div>
          </Col>
        </Row>
        {isFetching ? (
          <div className="margin-t-10">
            <Spinner spinnerName="three-bounce" />
          </div>
        ) : (
          showArticleTable === true && (
            <div className="margin-t-20">
              <div>
                <Table
                  className="table table-striped table-bordered table-condensed table-hover"
                  style={{ "margin-top": "10px", "margin-bottom": "0px" }}
                >
                  <thead>
                    <tr>
                      <th width="7%" className="text-c">
                        <input
                          type="checkbox"
                          checked={this.checkAllArticleChecked()}
                          onClick={(e) => {
                            this.props.setStateV1({
                              messageText: "",
                              messageType: "",
                            });
                            this.checkAllArticles(e.target.checked);
                          }}
                          title="Check All"
                        />
                      </th>
                      <th width="21%" className="">
                        <span>{["Topic ID"]}</span>
                      </th>
                      <th width="57%" className="">
                        <span>{["Knowledge Articles"]}</span>
                      </th>
                      <th width="15%">
                        <a
                          style={{ position: "relative", top: "2px" }}
                          href="javascript:void(0)"
                          title={["Link Articles"]}
                          onClick={() => {
                            this.linkAllArticles();
                          }}
                        >
                          <i className="fa fa-chevron-circle-right f-size-16 colorStBlue"></i>
                        </a>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.renderArticleHTML()}
                  </tbody>
                </Table>
                {combinedArticlesArray && combinedArticlesArray.length > 0 && (
                  <Row className="margin-t-20">
                    <Col lg={5} md={7} sm={7} xs={7} className="">
                      <div className="">
                        <Form.Label className="margin-r-5 display-inline-block widthAuto">
                          {["Show"]}
                        </Form.Label>
                        <Form.Select
                          className="display-inline-block widthAuto"
                          aria-label="select"
                          componentClass="select"
                          onChange={(e) => this.onPageSizeChange(e)}
                          value={pageSize}
                        >
                          {/* <option value={1}>1</option> */}
                          <option value={5}>5</option>
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={9999}>All</option>
                        </Form.Select>
                      </div>
                    </Col>
                    <Col lg={7} md={5} sm={5} xs={5}>
                      <div className="text-r">
                        <Pagination
                          activePage={currentPage}
                          itemsCountPerPage={pageSize}
                          totalItemsCount={combinedArticlesArray.length}
                          onChange={this.onPageChange}
                          pageRangeDisplayed={1}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  externalSystemAttributesList,
  isFetchingDetails,
  articleResultsList,
  insidedResultsList,
  breakFixEditDetails,
  spcmReducer,
  isFetchingDetails_KA,
  articleDetails,
}) => {
  return {
    externalSystemAttributesList,
    isFetchingDetails,
    articleResultsList,
    insidedResultsList,
    breakFixEditDetails,
    spcmReducer,
    isFetchingDetails_KA,
    articleDetailsById: articleDetails,
  };
};

export default connect(mapStateToProps, {
  clearSPCMArticles,
  emptyInsidedReducer,
  clearInsidedArticles,
  getArticleById,
})(SearchArticles);
