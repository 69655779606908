
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import {GLOBAL} from '_Globals'
import swal from 'sweetalert';
import { navigationHooks } from '../../helpers/NavigationHook';
import Swal from 'sweetalert2';

const api = axios.create({
    headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

export function loadCategoryListSuccess(categoryBoardList) {
    return {
        type: 'LOAD_CATEGORY_BOARD_LIST_SUCCESS',
        categoryBoardList
    };
}

export function loadCategoryEditSuccess(categoryEditDetails) {
    return {
        type: 'LOAD_CATEGORY_EDIT_DETAILS_SUCCESS',
        categoryEditDetails
    };
}

export function loadCICompanySuccess(ciCompany) {
    return {
        type: 'LOAD_CICOMPANY_SUCCESS',
        ciCompany
    };
}

export function loadCategoryList() {
    return (dispatch) => {
        dispatch({type:'LOADER_IN_PROGRESS'});
        api.get(GLOBAL.categoryBoardViewUrl)
            .then((categoryBoardList) => {
                if (!categoryBoardList) {
                    throw Error(categoryBoardList.statusText);
                }
                return categoryBoardList;
            })
            .then((categoryBoardList) => {
                dispatch(loadCategoryListSuccess(categoryBoardList));
                dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
            })
            .catch((err) => {
                dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
                console.log("error in loadCategoryListSuccess");
                console.log(err);
            });
    };
}


export function loadCategoryDropdown() {
    return (dispatch) => {
        api.get(GLOBAL.categoryDropdownUrl)
            .then((categoryDropdownList) => {
                if (!categoryDropdownList) {
                    throw Error(categoryDropdownList.statusText);
                }
                return categoryDropdownList;
            })
            .then((categoryDropdownList) => {
                dispatch({
                    type: 'LOAD_CATEGORY_DROPDOWN_SUCCESS',
                    categoryDropdownList
                })
            })
            .catch((err) => {
                console.log("error in loadCategoryDropdownSuccess")
                console.log(err);
            });
    };
}
//=========
export function CategoriesByCompanyId(companyId) {
    let strObj = {};
    strObj.companyId = companyId;
    strObj = JSON.stringify(strObj);
    console.log(strObj);
    return (dispatch) => {
        api.get(GLOBAL.categoryByCompanyIdDropdownUrl, { headers: { query: strObj } })
            .then((categoryByCompanyIdDropdownList) => {
                if (!categoryByCompanyIdDropdownList) {
                    throw Error(categoryByCompanyIdDropdownList.statusText);
                }
                return categoryByCompanyIdDropdownList;
            })
            .then((categoryByCompanyIdDropdownList) => {
                dispatch({
                    type: 'CATEGORIES_BY_COMPANY_ID',
                    categoryByCompanyIdDropdownList
                })
            })
            .catch((err) => {
                console.log("error in categories by company id")
                console.log(err);
            });
    };
}
//=========
export function loadStatusDropdown() {
    return (dispatch) => {
        api.get(GLOBAL.statusDropdownUrl)
            .then((statusDropdownList) => {
                if (!statusDropdownList) {
                    throw Error(statusDropdownList.statusText);
                }
                return statusDropdownList;
            })
            .then((statusDropdownList) => {
                dispatch({
                    type: 'LOAD_STATUS_DROPDOWN_SUCCESS',
                    statusDropdownList
                });
                dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
            })
            .catch((err) => {
                console.log("error in loadStatusDropdownSuccess")
                console.log(err);
            });
    };
}

export function createCategory(data) {
    return (dispatch) => {
        api.post(GLOBAL.categorySubmitUrl, data)
            .then((response) => {
                if (response.status == 200) {
                    console.log("created createCategory record");
                    console.log(response);
                    navigationHooks.navigate("/editCategory/" + response.data.CATEGORY_ID+"/"+response.data.CATEGORY_PINNED);

                } 
            })
            .catch((err) => {
                //console.log("error in createCategory")
               // console.log(err);
              //  alert(err.response.data[0].message)
                 swal({
                     text: err.response.data[0].message,
                     button: 'OK',
                 });
            });
    };
}

export function editCategory(category_id, formData) {
    return (dispatch) => {
        api.patch(GLOBAL.categorySubmitUrl + category_id, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                if (response.status == 200) {
                    navigationHooks.navigate("/categoryBoard");
                }
            })
            .catch((err) => {
              //  console.log("error in editCategory")
               // console.log(err);
                swal({
                     text: err.response.data[0].message,
                    button: 'OK',
                });
            });
    };
}

export function editCategoryStatus(category_id, formData, callback) {
    return (dispatch) => {
        api.patch(GLOBAL.categorySubmitUrl + category_id, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                if (response.status == 200) {
                    console.log("edited category record");
                    console.log(response.data[0]);
                    callback();
                    // dispatch({
                    //  type: 'LOAD_CATEGORY_EDIT_DETAILS_SUCCESS_2',
                    //  payload: response.data[0]
                    // });
                } else {
                    console.log("error in editing record");
                    console.log(response);
                }
            })
            .catch((err) => {
                console.log("error in editCategory")
                console.log(err);
                swal({
                    text: err.response.data,
                    button: 'OK',
                });
            });
    };
}

export function editCategoryImages(category_id, formData, callback) {
    return (dispatch) => {
        api.patch(GLOBAL.categorySubmitUrl + category_id, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                if (response.status == 200) {
                    console.log("edited category record");
                    console.log(response);
                    callback('success');
                } else {
                    console.log("error in editing record");
                    console.log(response);
                    callback('error');
                }
            })
            .catch((err) => {
                console.log("error in editCategory")
                console.log(err);
                callback('error');
            });
    };
}

export function loadEditCategoryDetails(id) {
    let strObj = {};
    strObj.category_id = id.toString();
    strObj = JSON.stringify(strObj);
    console.log(strObj);
    return (dispatch) => {
        api.get(GLOBAL.categoryBoardViewUrl, { headers: { 'query': strObj } })
            .then((categoryEditDetails) => {
                if (!categoryEditDetails) {
                    throw Error(categoryEditDetails.statusText);
                }
                return categoryEditDetails;
            })
            .then((categoryEditDetails) => {
                dispatch(loadCategoryEditSuccess(categoryEditDetails))
            })
            .catch((err) => {
                console.log("error in loadEditCategoryDetailsSuccess")
                console.log(err);
            });
    };
}

export function getAssociatedCompanies(category_id) {
    let strObj = {};
    strObj.category_id = category_id.toString();
    strObj = JSON.stringify(strObj);
    console.log(strObj);
    return (dispatch) => {
        dispatch({ type: "LOADER_IN_PROGRESS" });
        api.get(GLOBAL.companyAssociationUrl, { headers: { 'query': strObj } })
            .then((companyAssociationList) => {
                if (!companyAssociationList) {
                    throw Error(companyAssociationList.statusText);
                }
                return companyAssociationList;
            })
            .then((companyAssociationList) => {
                dispatch({
                    type: 'LOAD_COMPANY_ASSOCIATION_SUCCESS',
                    companyAssociationList
                });
                dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
            })
            .catch((err) => {
                console.log("error in getAssociatedCompanies Success")
                console.log(err);
                dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
            });
    };
}

export function getAssociatedCompanies2(category_id, callback) {
    let strObj = {};
    strObj.category_id = category_id.toString();
    strObj = JSON.stringify(strObj);
    console.log(strObj);
    return (dispatch) => {
        dispatch({ type: "LOADER_IN_PROGRESS" });
        api.get(GLOBAL.companyAssociationUrl, { headers: { 'query': strObj } })
            .then((companyAssociationList) => {
                if (!companyAssociationList) {
                    throw Error(companyAssociationList.statusText);
                }
                return companyAssociationList;
            })
            .then((companyAssociationList) => {
                callback(companyAssociationList.data);
                dispatch({
                    type: 'LOAD_COMPANY_ASSOCIATION_SUCCESS',
                    companyAssociationList
                });
                dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
            })
            .catch((err) => {
                console.log("error in getAssociatedCompanies Success")
                console.log(err);
                dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
            });
    };
}

export function loadCICompany(userid) {
    let url = "";
    //if(mod=="Breakfix")
    url = GLOBAL.ciCompanyUrl + "/userId/" + userid;
    //GLOBAL.ciCompanyUrl+"/userId/"+userid
    return (dispatch) => {
        axios.get(url)
            .then((ciCompany) => {
                if (!ciCompany) {
                    throw Error(ciCompany.statusText);
                }
                return ciCompany;
            })
            .then((ciCompany) => {
                console.log('success in company...');
                dispatch(loadCICompanySuccess(ciCompany));
            })
            .catch((err) => {
                console.log(err);
            });
    };
}

export function associateCategoryCompany(data) {
    return (dispatch) => {
        api.post(GLOBAL.companyAssociationUrl, data)
            .then((response) => {
                if (response.status == 200) {
                    console.log("created createCompanyAssociation record");
                    console.log(response);
                } else {
                    console.log("error in creating record");
                    console.log(response);
                }
            })
            .catch((err) => {
                console.log("error in createCompanyAssociation")
                console.log(err);
            });
    };
}

export function deleteCategoryCompany(category_id, company_id) {
    let strObj = {};
    strObj.category_id = category_id.toString();
    strObj.company_id = company_id.toString();
    strObj = JSON.stringify(strObj);
    console.log(strObj);
    return (dispatch) => {
        api.delete(GLOBAL.companyAssociationUrl, { headers: { 'query': strObj } })
            .then((response) => {
                if (response.status == 200) {
                    console.log(response);
                    console.log("deleted companyAssociation record");
                    console.log(response);
                } else {
                    console.log("error in deleting record");
                    console.log(response);
                }
            })
            .catch((err) => {
                console.log("error in deleteCategory")
                console.log(err);
            });
    };
}

export function resetEditCategoryReducer() {
    return (dispatch) => {
        dispatch({ type: 'EMPTY_CATEGORY_EDIT_DETAILS_REDUCER' });
    };
}

export function resetAssociatedCompanyReducer() {
    return (dispatch) => {
        dispatch({ type: "EMPTY_COMPANY_ASSOCIATION_REDUCER" });
    };
}

export function emptyCategoryListData() {
    return (dispatch) => {
        dispatch({ type: "EMPTY_CATEGORY_BOARD_LIST_REDUCER" });
    };
}

export function createCICategory(query, filteredArray) {
    {console.log("Insideee createCICategory")}
     return (dispatch) => {
          let config = {
              headers: { 
                  'query' : JSON.stringify(query)
              }
          }
          dispatch({type:'LOADER_IN_PROGRESS'});
          api.post(GLOBAL.ciCategorySubmitUrl, filteredArray, config)
              .then((response) => {
                  console.log("Inside Response",response.data.data)
                  const subCategoryNamesSet = new Set(response.data.data.map(item => item.sub_category_name));
                  const subCategoryNames = Array.from(subCategoryNamesSet);
                  console.log("subCategoryNames",subCategoryNames)
                  if(response.data.status == false){
                    swal({
                        text: "Specified Sub Category already exists.\n" + subCategoryNames.join("\n"),
                        button: "OK",
                      });
                      dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
                  }
                  else{
                    swal({
                        text: "Successfully Created",
                        button: "OK",
                      });
                  if (response.status == 200) {
                      dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
                      navigationHooks.navigate("/categoryBoard", {state: {activeTab: 'category'}});
                  } 
                }
              })
              .catch((err) => {
                  dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
                   swal({
                       text: err.response.data[0].message,
                       button: 'OK',
                   });
              });
      };
  }
  export function getCICategoryListData(companyId="0", classId, status, page, limit ='') {
    return (dispatch) => {
          let config = {
              headers: { 
                'query' : JSON.stringify({
                        "companyId":companyId.toString(),
                        "classId": classId.toString(),
                      "status": status,
                      "sortBy": "desc" })
              }
          }
          dispatch({ type: "CICATEGORY_LOADER_IN_PROGRESS", payload: true });
          api.get(GLOBAL.ciCategoryListUrl, config)
              .then((response) => {
                  if (response.status == 200) {
                      console.log("created ciCategorySubmitUrl record");
                      console.log(response);
                      dispatch({ type: "CICATEGORY_DATA_FETCHED_SUCCESSFULLY", payload: response });
                      dispatch({ type: "CICATEGORY_LOADER_DETAILS_FETCHED_SUCCESSFULLY", payload: false });
                  } 
              })
              .catch((err) => {
                  dispatch({ type: "CICATEGORY_LOADER_DETAILS_FETCHED_SUCCESSFULLY", payload: false });
                  //console.log("error in ciCategorySubmitUrl")
                 // console.log(err);
              //    alert(err.response.data[0].message)
              console.log("Inside catch block=====>")
                   swal({
                       text: err.response.data[0].message,
                       button: 'OK',
                   });
              });
      };
  }

  
  export function emptyCICategoryListDataReducer(){
      return(dispatch) =>{
      dispatch({ type: "CICATEGORY_DATA_FETCHED_SUCCESSFULLY", payload: [] });
  }
  }
  export function editCICategory(formData, PrvStatus) {
      return (dispatch) => {
        console.log("Inside Returnn================>");
        const { category_id, category_name, sub_category_name, status } = formData;
        const categoryData = {
          category_name: category_name,
          sub_category_name: sub_category_name,
          status: status,
        };
        console.log("categoryData insideeee", categoryData);
        console.log("======",formData.category_id)
        console.log("URl=======>",GLOBAL.ciCategoryUpdateUrl + category_id)
        dispatch({ type: "LOADER_IN_PROGRESS"});
        api.patch(GLOBAL.ciCategoryUpdateUrl + category_id, categoryData)
          .then((response) => {
              console.log("Response=========>",response);
              const subCategoryNamesSet = new Set(response.data.data.map(item => item.sub_category_name));
                  const subCategoryNames = Array.from(subCategoryNamesSet);
                  console.log("subCategoryNames",subCategoryNames)
                  if(response.data.status == false){
                    swal({
                        text: "Specified Sub Category already exists.\n" + subCategoryNames.join("\n"),
                        button: "OK",
                      });
                      dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
                  }
             else{      
            if (response.status === 200) {
              dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY"});
              dispatch(getCICategoryListData(formData.cat_company_id, formData.class_id, PrvStatus));
            }}
          })
          .catch((err) => {
              dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY"});
            // console.log("error in editCategory");
            // console.log(err);
            swal({
              text: err.response.data[0].message,
              button: "OK",
            });
          });
      };
    }


    export function createCIResolutionCategory(query, filteredArray) {
         return (dispatch) => {
              let config = {
                  headers: { 
                      'query' : JSON.stringify(query)
                  }
              }
              dispatch({type:'LOADER_IN_PROGRESS'});
              api.post(GLOBAL.ciResCategoryPostUrl, filteredArray, config)
                  .then((response) => {
                      console.log("Inside Response",response.data)
                      if(response.data.status == false || response.data.status == 'false'){
                        const categoryNamesSet = new Set(response.data.data.map(item => item.category_name));
                        const subCategoryNamesSet = new Set(response.data.data.map(item => item.sub_category_name));
                        const categoryNames = Array.from(categoryNamesSet);
                        const subCategoryNames = Array.from(subCategoryNamesSet);
                        swal({
                            text: "Specified Resolution Sub Category already exists.\n" +subCategoryNames.join("\n"),
                            button: "OK",
                          });
                          dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
                      } else {
                        swal({
                            text: "Successfully Created",
                            button: "OK",
                          });
                      if (response.status == 200) {
                          dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
                          navigationHooks.navigate("/categoryBoard", {state: {activeTab: 'resolution'}});
                      }
                     }
                  })
                  .catch((err) => {
                      dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
                       swal({
                           text: err.response.data[0].message,
                           button: 'OK',
                       });
                  });
          };
      }
      export function editCIResolutionCategory(category_id, resCatId, formData) {
                return (dispatch) => {
            api.patch(GLOBAL.ciResCategoryPatchUrl + resCatId, formData, {
                headers: {
                    query: JSON.stringify({category_id:category_id})
                }
            })
                .then((response) => {
                    if (response.status == 200) {
                        console.log("edited resolution category record");
                        console.log(response.data[0]);
                       dispatch(loadCatSubCat(category_id,formData.searchedStatus));
                       
                    } else if(response.status == 201) {
                        swal({
                            text: response.data.message,
                            button: 'OK',
                        });
                    } else {
                        console.log("error in resolution editing record");
                        console.log(response);
                    }
                })
                .catch((err) => {
                    console.log("error in resolution editCategory")
                    console.log(err);
                    swal({
                        text: err.response.data,
                        button: 'OK',
                    });
                });
        };
    }

    export function loadCatSubCat(category_id,status) {
        let url = "";
        //if(mod=="Breakfix")
        ///api/menulist?field1_name=Type&module=CI
        url = GLOBAL.ciCatSubcatUrl;
       
        let config = {
            headers: { 
              'query' : JSON.stringify({
                "category_id": category_id,
               
                    "status":  (status=='both') ? "" : status,
                    "sortBy": "desc" })
            }
        }
        console.log("URL", url);
        return (dispatch) => {
    
            axios.get(url, config)
                .then((CatSubCatRes) => {
                    if (!CatSubCatRes) {
                        throw Error(CatSubCatRes.statusText);
                    }
                    return CatSubCatRes;
                })
                .then((CatSubCatRes) => {
                    console.log('success in cat...');
                    dispatch(loadCATSUBCATSuccess(CatSubCatRes));
                })
                .catch((err) => {
                    dispatch(loadCATSUBCATSuccess([]));
                    console.log(err);
                });
    
    
        };
    }
    
    export function resetResCatSubCatList() {
        return (dispatch) => {
            dispatch(loadCATSUBCATSuccess([]));
        };
    }


    export function resetSubCatDropdown() {
      return (dispatch) => {
        dispatch({ type: "EMPTY_CISCAT_SUCCESS" });
      };
    }
    
    export function resetCatDropdown() {
        return (dispatch) => {
          dispatch({ type: "EMPTY_CICAT_SUCCESS" });
        };
      }
    
    export function loadCATSUBCATSuccess(CatSubCatRes) {
        return {
            type: 'LOAD_CATSUBCAT_SUCCESS',
            CatSubCatRes
        };
    }
    export function loadCISCatSuccess(ciSCat) {
        return {
            type: 'LOAD_CISCAT_SUCCESS',
            ciSCat
        };
    }
    

    export function loadCISCat(categoryName, classId, companyId) {
        let data = {};
        data.classId = classId;
        data.companyId = companyId;
        data.categoryName = categoryName;
    
        return (dispatch) => {
            axios.post(GLOBAL.ciSubCatUrl, data)
                .then((ciSCat) => {
                    if (!ciSCat) {
                        throw Error(ciSCat.statusText);
                    }
                    return ciSCat;
                })
                .then((ciSCat) => {
                    console.log('success in subcat...');
                    dispatch(loadCISCatSuccess(ciSCat));
                })
                .catch((err) => {
                    console.log(err);
                });
        };
    }