
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const lovForType = (state = [], action) => {
    switch(action.type) {
        case 'LOV_FOR_TYPE':
            return action.lovValueList.data;
        default:
            return state;
    }
}

export const lovForChangeTiming = (state = [], action) => {
    switch(action.type) {
        case 'LOV_FOR_CHANGE_TIMMING':
            return action.lovValueList.data;
        default:
            return state;
    }
}

export const lovForBusinessRisk = (state = [], action) => {
    switch(action.type) {
        case 'LOV_FOR_BUSINESS_RISK':
            return action.lovValueList.data;
        default:
            return state;
    }
}

export const lovForTechnicalRisk = (state = [], action) => {
    switch(action.type) {
        case 'LOV_FOR_TECHNICAL_RISK':
            return action.lovValueList.data;
        default:
            return state;
    }
}

export const lovForPlanType = (state = [], action) => {
    switch(action.type) {
        case 'LOV_FOR_TYPE_PLAN_TYPE':
            return action.lovValueList.data;
        default:
            return state;
    }
}

export const lovForUrgency = (state = [], action) => {
    switch(action.type) {
        case 'LOV_FOR_URGENCY':
            return action.lovValueList.data;
        default:
            return state;
    }
}

export const lovForReasonForChange = (state = [], action) => {
    switch(action.type) {
        case 'LOV_FOR_CHANGE_REASON':
            return action.lovValueList.data;
        default:
            return state;
    }
}

export const lovForRiskOccurrence = (state = [], action) => {
    switch(action.type) {
        case 'LOV_FOR_RISK_OCCURRENCE':
            return action.lovValueList.data;
        default:
            return state;
    }
}

export const lovForRiskImpact = (state = [], action) => {
    switch(action.type) {
        case 'LOV_FOR_RISK_IMPACT':
            return action.lovValueList.data;
        default:
            return state;
    }
}

export const lovForControlEffectiveness = (state = [], action) => {
    switch(action.type) {
        case 'LOV_FOR_CONTROL_EFFECTIVENESS':
            return action.lovValueList.data;
        default:
            return state;
    }
}

export const riskAssessmentValue = (state ='',action)=>{
	switch(action.type) {
		case 'RISK_ASSESSMENT':
			return action.response.data;
		default:
		return state;
	}
}