
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

//let user_id=userDetails.user_id;
const bulletinUrl = "/getBulletinForRestifySelfService";
export function loadBulletinSuccess(bulletin){
	return {
		type: 'LOAD_BULLETIN_SUCCESS',
		bulletin
	};
}

export function loadBulletin(){
	var str={};
	str.userId="";
	str = JSON.stringify(str);
	return(dispatch) =>{
		api.get(bulletinUrl,{headers:{'query':str}})
			.then((bulletin)=>{
				if(!bulletin){
					throw Error(bulletin.statusText);
				}
				return bulletin;
			})
			.then((bulletin) =>{
				dispatch(loadBulletinSuccess(bulletin))
			})
			.catch((err) => {
				console.log(err);
			});
	};
}
