
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import { GLOBAL }  from '../../components/Globals';
//below URL used for testing purpose
//const breakFixUrl = 'https://xsm-f.mybluemix.net/api/SX_FAVOURITEOFFERINGs/';

//let breakFixUrl=`https://xsm-f.mybluemix.net/groupListSelfService?companyId=2932&group=ResolvedER`
export function loadBreakFixCauseSuccess(breakFixCause){
	return {
		type: 'LOAD_BREAKFIXCAUSE_SUCCESS',
		breakFixCause
	};
}

export function loadBreakFixCauseFailure(errorResponse){
	return {
		type: 'LOAD_BREAKFIXCAUSE_FAILURE',
		errorResponse
	};
}

export function loadBreakFixCause(){
	return(dispatch) =>{
				//alert("edit"+breakFixUrl+breakFixId);
				axios.get(GLOBAL.breakFixCauseUrl)
					.then((breakFixCause)=>{
						if(!breakFixCause){
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							throw Error(breakFixCause.statusText);
						}
						return breakFixCause;
					})
					.then((breakFixCause) =>{
						dispatch(loadBreakFixCauseSuccess(breakFixCause))
					})
					.catch((err) => {
						console.log(err);
					});
					//console.log("not calling loadbreakFixCause");

	};
}
