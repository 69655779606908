
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState} from "react";
import {Tab,Tabs} from 'react-bootstrap';
import {useSelector } from "react-redux";

import AssociateCompanies from "./associate-companies";
import AssociateGroups from "./associate-groups";
import AssociateRoles from "./associate-roles";
import RestrictAccess from "./restrict-access";
import CloseBtnRightPanel from "../../../../common/closeBtnRightPanel";

const TabsComponent = (props) => {
    const tr = useSelector((state) => state.spcmReducer.tr);
    const [key, setKey] = useState(1);
    console.log("LALALA2",props.companyType);

    return (
        <div className="hambTab userInfoPopupParent">
            <CloseBtnRightPanel showRightSide={props.showRightSideFun} />
            <div className="rPageHeading">
                <div className="offNam margin-t-5 margin-b-5">
                {tr["User Details"]}
                </div>
            </div>
            <div className="rBoxGap">
                <div className="rBoxTabs hideTabNav">
                    <Tabs activeKey={key} onSelect={(k) => setKey(k)} id="tabId">
                        <Tab eventKey={1} title={tr["Groups"]}><AssociateGroups 
           companyType ={props.companyType}        
           /></Tab>
                        <Tab eventKey={2} title={tr["Roles"]}><AssociateRoles /></Tab>
                        <Tab eventKey={3} title="Companies"><AssociateCompanies
           companyType ={props.companyType}        
           /></Tab>
                        <Tab eventKey={4} title="Restrict Access"><RestrictAccess /></Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

export default TabsComponent;