
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { DateRangePicker } from "@progress/kendo-react-dateinputs";

const DateRangePickerField = (props) => {
  const { attributes, onValueChange, defaultValue, readOnly, nonEditable } = props;

  return (
    <DateRangePicker
      value={defaultValue || null}
      onChange={onValueChange}
      format={attributes.format || "dd/MM/yyyy"}
      disabled={attributes.disabled || nonEditable}
      readOnly={readOnly}
    />
  );
};

export default DateRangePickerField;
