
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Button, ButtonToolbar, Form, Table } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import ListLoader from "../../../common/loaders/ListLoader.js";
import Pagination from "react-js-pagination";
import { IoSearch } from "react-icons/io5";
import { reduxForm } from "redux-form";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { useNavigate } from "react-router";
import { useMutation, useQuery } from "@apollo/client";
import {
  DeleteLanguage,
  languageWithMeta,
} from "../../graphQl/GraphqlQueries.js";
import { FaPencil, FaTrash } from "react-icons/fa6";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const gph = cookies.get("gph").split("~");
const dateformat = gph[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

const LanguageListView = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const translator = useSelector((state) => state.spcmReducer.tr);
  const [rowIndex, setRowIndex] = useState(0);
  const [listData, setListData] = useState([]);
  const [count, setCount] = useState(0);

  const [deleteLanguages, { data: deleted }] = useMutation(DeleteLanguage);

  const {
    loading: showLoader,
    error,
    data,
    refetch,
  } = useQuery(languageWithMeta, {
    variables: {
      limit: parseInt(props.itemsPerPage, 10),
      offset: props.activePage * props.itemsPerPage,
    },
  });

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setListData(data?.getLanguages?.data);
      setCount(data?.getLanguages?.count);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [props.activePage, props.itemsCountPerPage]);

  useEffect(() => {
    if (props.refreshPage) {
      refetch();
      props?.setRefreshPage(false);
    }
  }, [props.refreshPage]);

  function onRowSelect(e, row, index) {
    setRowIndex(index);
  }

  const deleteLanguage = (id) => {
    deleteLanguages({
      variables: { id: id },
      onError(error) {},
      onCompleted(data) {
        refetch();
      },
    });
  };

  const onPageSizeChange = (e) => {
    let filter = {};
    filter["page"] = 0;
    filter["size"] = e.target.value;
    props.setActivePage(0);
    props.setItemsPerPage(e.target.value);
  };

  const onPageChange = (e) => {
    let filter = {};
    filter["page"] = e - 1;
    filter["size"] = props.itemsPerPage;
    props.setActivePage(parseInt(filter["page"], 10));
  };

  function renderTable(listData) {
    if (listData && listData.length > 0) {
      return listData.map((data, index) => {
        return (
          <tr
            key={index}
            className={`${
              props.rightDisplay && index == rowIndex ? "myActive" : ""
            }`}
            onClick={(e) => {
              onRowSelect(e, data, index);
              //navigate("/escalation/levels/" + data?.id);
            }}
          >
            <td>{data.language}</td>
            <td className="hyperLinkWIB">{data.key}</td>
            <td>{data.value ? data.value : ""}</td>
            <td>{data.updatedBy}</td>
            <td>{moment(data.updatedAt).format(jwtdf)}</td>
            <td>{data?.status}</td>
            <td>
              <ButtonToolbar>
                <Button
                  bsPrefix=" "
                  className="formBuilBtn margin-r-5"
                  onClick={() => {
                    navigate("/addLanguage/" + data?._id);
                  }}
                  title="Edit"
                >
                  <FaPencil />
                </Button>
                <Button
                  bsPrefix=" "
                  className="formBuilBtn"
                  onClick={() => deleteLanguage(data?._id)}
                  title="Delete"
                >
                  <FaTrash />
                </Button>
              </ButtonToolbar>
            </td>
          </tr>
        );
      });
    }
  }

  return (
    <div>
      <div className="respondv">
        <div className="tableRgtBor table-responsive">
          <Table
            striped
            bordered
            condensed
            hover
            className="tableView nowrapWhright"
            style={{ marginTop: "0px" }}
          >
            <thead>
              <tr>
                <th>
                  <div className="sortParArr">{"Language"}</div>
                </th>
                <th>
                  <div className="sortParArr">{"Key"}</div>
                </th>
                <th>
                  <div className="sortParArr">{"Value"}</div>
                </th>

                <th>
                  <div className="sortParArr">{translator["Modified By"]}</div>
                </th>
                <th>
                  <div className="sortParArr">{translator["Modified Date"]}</div>
                </th>
                <th>
                  <div className="sortParArr">{translator["Status"]}</div>
                </th>
                <th>
                  <div className="sortParArr">{"Action"}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {showLoader ? (
                <tr>
                  <td colSpan="12" style={{ textAlign: "center" }}>
                    <ListLoader />
                  </td>
                </tr>
              ) : listData && listData.length === 0 ? (
                <tr>
                  <td colSpan="12" style={{ textAlign: "center" }}>
                    {translator["There is no matching data available"]}
                  </td>
                </tr>
              ) : (
                renderTable(listData)
              )}
            </tbody>
          </Table>
        </div>
      </div>
      {listData && listData.length > 0 ? (
        <div className="nBotPagina">
          <div className="nShow">
            <div className="margin-r-10" componentClass={Form.Label}>
              {translator["Show"]}:
            </div>
            <Form.Select
              value={props.itemsPerPage}
              onChange={(e) => {
                onPageSizeChange(e);
                props.showRightSideFun(false);
              }}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              {/* <option value="100">100</option> */}
            </Form.Select>
          </div>
          <nav aria-label="Pagination" className="display-inline-block">
            <Pagination
              prevPageText="Prev"
              nextPageText="Next"
              firstPageText={<i className="glyphicon glyphicon-menu-left" />}
              lastPageText={<i className="glyphicon glyphicon-menu-right" />}
              activePage={props.activePage + 1}
              itemsCountPerPage={props.itemsPerPage}
              totalItemsCount={count ? count : 1}
              pageRangeDisplayed={4}
              onChange={(e) => {
                onPageChange(e);
              }}
            />
          </nav>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  // shiftListData: state.shiftListData
});

export default reduxForm({
  form: "LanguageListView",
})(connect(mapStateToProps)(LanguageListView));
