
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Row, Col, Form, Alert, ButtonToolbar, Button, InputGroup } from 'react-bootstrap';
import { _textArea, _dropDown, _inputField, _radio,field_file } from '../../common/formFields';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import Image from 'react-bootstrap/Image';
import { connect } from 'react-redux';
import axios from 'axios';
import Cookies from "universal-cookie";
import {ImSpinner6} from "react-icons/im";
const cookies = new Cookies();

let attachmentDetails = cookies.get("gph");
if (attachmentDetails) attachmentDetails = attachmentDetails.replace("s:", "");
if (attachmentDetails)
  attachmentDetails = attachmentDetails.substring(
    0,
    attachmentDetails.lastIndexOf(".")
  );
attachmentDetails = attachmentDetails.split("~");
const attachmentSize = attachmentDetails[21];
import { spcmLoader, setLeftEditId, getServiceDetailCounts, getConsumptionItems, getServiceBilling,getAttachmentsAction,getAttachmentsPaginationAction } from '../../../actions/spcmActions';


const validate = (values,props) => {
	let errors={};
	// if (values && values.file && values.file[0]) {
    //     console.log("Size of the file",values.file[0].size);
    //     if(values.file[0].size > 5000)
	// 		errors.file = 'select a file';
	// }

if (!values.file) {
		errors.file = 'Select a file';
	}
	return errors;
}

class ComponentForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dropdown: 0,
			AssociatedToDropdown: "",
            uploadMessage:''
		}
		this.submitForm = this.submitForm.bind(this);
	}
	componentWillMount() {
    }

	componentWillUnmount() {
		this.props.spcmLoader('attribute', 'success');
	}

	

	submitForm(values) {
        console.log("every===",values);

        let formData = new FormData();	
		if(values.file){
			formData.append('file', values.file[0]);

		}else{
			formData.append('file',"");
		}

        // if (this.props.spcmReducer.editLeftId > 0) {
			this.props.spcmLoader('attribute', 'posting');
			return axios.post("/api/attachment/" + this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID, formData).then((response) => {
                console.log("Response===",response);
				this.props.componentFormFun4(false);
				this.props.spcmLoader('attribute', 'success');
                // this.props.getAttachmentsAction(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
				this.props.getAttachmentsPaginationAction(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,'desc',1,10)
				this.props.getServiceDetailCounts(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);


		
			})
				.catch((error) => {
					console.log("activeness",error);
					this.props.spcmLoader('attribute', 'success');
					if(error.response.status === 500){
						throw new SubmissionError({ _error: "File is too large" });
					}
					if(error.response.data[0]){
						throw new SubmissionError({ _error: "Changes are not allowed in Deployed status" });
						// throw new SubmissionError({ _error: error.response.data[0].message });

					}else if(error.response.data.message == 'Something went wrong!' && values.file.length == 0){
						
						// throw new SubmissionError({ _error: error.response.data.message });
						throw new SubmissionError({ _error: "Please Upload a file" });

					}else{
						// throw new SubmissionError({ _error: error.response.data.message });
						throw new SubmissionError({ _error: "File uploaded is not of the supported format" });					

					}
				});
		// }
		
	}

	render() {
		const tr = this.props.tr;
		const { error, handleSubmit } = this.props;
		

		return (
			<div className="rBoxGap">
				<form onSubmit={handleSubmit(this.submitForm)}>
					{error == undefined || error == null ? null : <Alert variant="danger" className="errorMessageSec"><p>{error}</p></Alert>}
                    {this.state.uploadMessage == "" ? null :  <Alert variant={this.state.uploadStatus =="success" ? "success" : "danger"} className="errorMessageSec"><p>{this.state.uploadMessage}</p></Alert>}
				<Form.Group className="priceForm-group withImgbox margin-b-15">
					{/* <Form.Label bsClass="">Upload your file(max size 5 MB)</Form.Label> */}
					<p className="f-size-11"> {tr["Drop files or click here to upload"]} {tr['(max size']} {attachmentSize} {tr['MB)']}</p>
					<InputGroup>
						<Field className='form-control'  component={ field_file } type="file" name="file" />
					</InputGroup>
					<span className="f-size-10">Note: <b>Supported File Formats</b> (.doc, .docx, .xls, .xlsx, .txt. .csv )</span>
					
				</Form.Group>
				
				
					<Row className='margin-t-10'>
						<Col md={12}>
							<ButtonToolbar className="black">
								<Button className='rgSidrkBtn' title="Save" disabled={this.props.spcmReducer.spcmPostingAttribute} type="submit" bsSize="small" bsStyle="primary">{this.props.spcmReducer.spcmPostingAttribute ? <ImSpinner6 className="icn-spinner"/> : null}  {tr['Save']}</Button>
								<Button className='rgSiLigBtn' title="Close" onClick={() => { this.props.componentFormFun4(false) }} bsSize="small" bsStyle="text">{tr['Close']}</Button></ButtonToolbar>
						</Col>
					</Row>
				</form>
			</div>
		);
	}
}

const mapStateToProps = ({ spcmReducer }) => {
	return {
		spcmReducer,
	}
};
ComponentForm = reduxForm({
	form: 'attachments',
	validate,
	enableReinitialize: true
})(ComponentForm)

export default connect(mapStateToProps, { spcmLoader, setLeftEditId, getServiceDetailCounts,getAttachmentsAction,getAttachmentsPaginationAction })(ComponentForm);
