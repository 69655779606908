
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { connect } from "react-redux";
import Spinner from "react-spinkit";
import BasicTable from "../ReactTable/BasicTable";
import {IoClose} from "react-icons/io5";

class BreakFixExternalSystemAttribute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
    this.renderExternalSystemAttributeHTML =
      this.renderExternalSystemAttributeHTML.bind(this);

    this.options = {
      page: 1,
      sizePerPageList: [
        {
          text: "5",
          value: 5,
        },
        {
          text: "10",
          value: 10,
        },
        {
          text: "15",
          value: 15,
        },
        {
          text: "20",
          value: 20,
        },
        {
          text: "All",
          value: 99999, //( (this.props && this.props.articleResultsList && Array.isArray(this.props.articleResultsList) &&  this.props.articleResultsList.length) ? this.props.articleResultsList.length : 99999 )
        },
      ], // you can change the dropdown list for size per page
      sizePerPage: 5, // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 6, // the pagination bar size.

      //prePage: 'Prev', // Previous page button text
      //nextPage: 'Next', // Next page button text
      //firstPage: 'First', // First page button text
      //lastPage: 'Last', // Last page button text
      //paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
      paginationPosition: "top", // default is bottom, top and both is all available
      //paginationHAlign
      //hideSizePerPage: true > You can hide the dropdown for sizePerPage
      //alwaysShowAllBtns: true // Always show next and previous button
      // withFirstAndLast: false > Hide the going to First and Last page button,
      //sortName:'slaId',
      //sortOrder:'asc',
      //exportCSVText: 'Export search result'
    };

    // this.selectRowProp = {
    // 	mode: "radio",
    // 	clickToSelect: true,
    // 	bgColor: "#ADD8E6",
    // 	hideSelectColumn: true,
    // 	//componentClass:"select",
    // 	onSelect: this.onRowSelect,
    // 	selected: [0]
    // };
  }

  componentWillReceiveProps(nextProps, prevProps) {
    // console.log('componentWillReceiveProps item');
    // console.log('componentWillReceiveProps item',this.props,'------------',nextProps);
  }

  componentDidMount() {
    setTimeout(() => this.setState({ loading: false }), 2000); // simulates an async action, and hides the spinner
  }

  renderExternalSystemAttributeHTML(list) {
     console.log('listlist',list)
    if (list && Array.isArray(list) && list.length) {
      const tableColumns = [
        {
          name: this.props.translator['Client Name'],
          selector: (row) => row.clientName,
          sortable: true, 
        },
        {
          name: this.props.translator['Attribute'],
          selector: (row) => row.attrName,
          sortable: true,
        },
        {
          name: this.props.translator['Value'],
          selector: (row) => row.attrValue,
          sortable: true,
        },
        {
          name: this.props.translator['External Reference Number'],
          selector: (row) => row.clientReferenceNumber,
          sortable: true,
        }
      ];
      return (
        <div>
          <div className="knowledgeDiv">
            <BasicTable
              tableColumns={tableColumns}
              data={list}
              pagination={true}
              onRowSelect={(row) => {}}
            />
            {/* <BootstrapTable
              bordered
              condensed
              hover
              responsive
              className="tableView paginationSetNew"
              data={list}
            >
              <TableHeaderColumn dataField="attrId" isKey hidden dataSort>
                attribute id
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="attrName"
                dataSort
                columnClassName=""
                width="35%"
              >
                Attribute
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="attrValue"
                dataSort
                columnClassName=""
              >
                Value
              </TableHeaderColumn>
            </BootstrapTable> */}
          </div>
        </div>
      );
    } else {
      return <div>{this.props.translator["No Data Available"]}</div>;
    }
  }

  render() {
    // console.log('this.props',this.props);

    const { loading } = this.state;

    return (
      <div>
        {loading ? (
          <div>
            <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
              <ul>
                <li>
                <button
                    type='button'
                    title="Minimize the right panel"
                    bsClass=""
                    bsStyle=""
                    className="closerightPanelBtn"
                    onClick={() => {
                    this.props.rightEditPanel(false);
                    }}
                >
                    <IoClose />
                </button>
                </li>
              </ul>
            </div>
            <div className="rPageHeading">
              <div className="offNam margin-t-5 margin-b-5">
                {this.props.translator["External Attributes"]}
              </div>
            </div>
            <div className="rBoxGap">
              <Spinner spinnerName="three-bounce" />
            </div>
          </div>
        ) : (
          <div>
            <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
              <ul>
                <li>
                <button
                    type='button'
                    title="Minimize the right panel"
                    bsClass=""
                    bsStyle=""
                    className="closerightPanelBtn"
                    onClick={() => {
                    this.props.rightEditPanel(false);
                    }}
                >
                    <IoClose />
                </button>
                </li>
              </ul>
            </div>
            <div className="rPageHeading">
              <div className="offNam margin-t-5 margin-b-5">
                {this.props.translator["External Attributes"]}
              </div>
            </div>
            <div className="rBoxGap">
              {this.renderExternalSystemAttributeHTML(
                this.props.externalSystemAttributesList
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

// export default BreakFix;

const mapStateToProps = ({
  externalSystemAttributesList,
  isFetchingDetails,
}) => {
  // console.log('+++++++++++++++++++++++',externalSystemAttributesList)
  // console.log('++++++++++++++++++',isFetchingDetails)
  return {
    externalSystemAttributesList,
    isFetchingDetails,
  };
};

export default connect(mapStateToProps, {})(BreakFixExternalSystemAttribute);
