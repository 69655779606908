
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import { Link, useNavigate } from "react-router-dom";
import CreateOutBoundConHeader from "./CreateOutBoundConHeader.js";
import CreateOutBoundConForm from "./CreateOutBoundConForm.js";
import { useSelector } from "react-redux";

import Cookies from "universal-cookie";
import Breadcrumbs from "../../common/header/breadcrumbs.js";
import "_Css/form/_form.scss";
const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home") homepagelocation = "/" + homepagelocation;

const CreateOutBoundConIndex = () => {
  const [actionView, setActionView] = useState("assetgraph");
  const translator = useSelector((state) => state.spcmReducer.tr);

  return (
    <div>
      <div>
        <div className="container-fluid padding-t-10 margin-b-15">
          <Breadcrumbs
            activePageName={"Create"}
            parentPageurl="/integHub"
            parentPageName={translator["Integration Hub"]}
          />
        </div>
        <div className="container-fluid" bsClass="">
          <Form>
            <CreateOutBoundConHeader
              changeActionView={setActionView}
              translator={translator}
            />
            <Row className="row-eq-height">
              <Col md={12}>
                <CreateOutBoundConForm translator={translator} />
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default CreateOutBoundConIndex;
