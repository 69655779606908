
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import constants from '../../../src/utils/constants';
import _ from 'lodash';

export function globalSearchSuccess(globalSearchDataForRevamp){
	return {
		type: 'GLOBAL_SEARCH_REVAMP_DATA_LOADED',
		globalSearchDataForRevamp
	};
}

export const getGlobalSearchData = (searchText, category) => {

    let str = {};
    //str.categoryName=category == 'All'  || category == 'ALL' ? '' : category;
    str.categoryId = category;
    str.offeringName = searchText;
    str = JSON.stringify(str);

    const responseData = axios.get('/getOfferingsForSearchBarRestifySelfService', { headers: { 'query': str } });
    return (dispatch) => {
        dispatch({
            type: 'GLOBAL_SEARCH_DATA_LOADED',
            globalSearchData: [],
            isFetching: true,
            isError: false
        });
        responseData.then(res => {
            if (res.data.length == 0) {
                dispatch({
                    type: 'GLOBAL_SEARCH_DATA_NOT_FOUND',
                    globalSearchData: [],
                    isFetching: false,
                    isError: true
                });
            } else {
                let data = _.uniqBy(res.data, function (e) {
                    return e.offeringId;
                });
                dispatch({
                    type: 'GLOBAL_SEARCH_DATA_LOADED',
                    globalSearchData: data,
                    isFetching: false,
                    isError: false
                });
            }
        });
    };
};
export const getGlobalSearchDataForRevamp = (searchText, category,start,end) => {

    let str = {};
    //str.categoryName=category == 'All'  || category == 'ALL' ? '' : category;
    str.categoryId = category;
    str.offeringName = searchText;
    if(start!=""){
		str.start=start;
	}
	if(end!=""){
		str.end=end;
	}

    const responseData = axios.post('/getOfferingsForSearchBarRestifySelfServiceForUiRevamp', str);
    return (dispatch) => {
        dispatch({ type: "REVAMP_SEARCH_LOADER_IN_PROGRESS" });
        responseData.then(globalSearchDataForRevamp => {
				if(!globalSearchDataForRevamp){
					throw Error(globalSearchDataForRevamp.statusText);
				}
				return globalSearchDataForRevamp;
			})
			.then((globalSearchDataForRevamp) =>{
				dispatch(globalSearchSuccess(globalSearchDataForRevamp));
				dispatch({ type: 'REVAMP_SEARCH_LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
			})
			.catch((err) => {
				dispatch({ type: 'REVAMP_SEARCH_LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
        });
    };
};

export const setGlobalSearchListSearchTextAndCategory = (searchText, categoryId) => {
  return (dispatch)=>{
    dispatch({type: 'SET_GLOBAL_SEARCH_LIST_SEARCHTEXT', data: searchText});
    dispatch({type: 'SET_GLOBAL_SEARCH_LIST_CATEGORYID', data: categoryId});
  };
};

export const setwibGlobalSearchFilterData = (selectedFilterData)=>{
   return (dispatch)=>{
     dispatch({type: 'WIB_GLOBAL_SEARCH_FILTER_DATA', filterData: selectedFilterData});
   };
};
export const setInitialwibGlobalSearchFilterData = ()=>{
    return (dispatch)=>{
      dispatch({type: 'WIB_GLOBAL_SEARCH_FILTER_DATA', filterData: {}});   
    };
};


export const setWibSearchParams = (type, action, module, searchParams) => {
  let searchParam;
  if (type == "Remove") {
    searchParam = {};
  } else {
    searchParam = { action, module, searchParams };
  }
  return (dispatch) => {
    dispatch({
      type: "WIB_SEARCH_PARAMS",
      payload: { searchParams: searchParam },
    });
  };
};


export const setWipSearchApply = (action) => {
  return (dispatch) => {
    dispatch({ type: "SET_IS_SEARCH_APPLY", applied: action });
  };
};

export const setWibActiveTab = (action) => {
    return (dispatch) => {
      dispatch({ type: "SET_WIB_ACTIVE_TAB", tab: action });
    };
};

export const setWibSelectedDateRangeLabel = (range) => {
  return (dispatch) => {
    dispatch({ type: "WIB_SELECTED_DATE_RANGE_LABEL", label: range });
  };
};
 
export const setSlaColors = (companyId) => {
  let str = {};
  str["companyId"] = companyId;
  str["module"] = "SLA";
  str["propertyId"] = 15;
  str["businessFunctionId"] = "";
  str = JSON.stringify(str);
  return (dispatch) => {
    axios
      .get("/api/getAdminPreferences", { headers: { query: str } })
      .then((response) => {
        const data = response?.data?.[0];
        const value = JSON.parse(data?.value);
          dispatch({ type: "WIB_SLA_COLORS_FDN", color:value  });
          let result = [];
          for (const key in value) {
              result.push({ id: key, label: key });
          }
          dispatch({ type: "SET_ALL_SLA_COLORS", payload: result });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};