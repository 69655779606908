import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import {
  Button,
  Form,
  Container,
  Row,
  Col,
  Card,
  Overlay,
  Tooltip,
} from "react-bootstrap";
import {
  MdArrowRightAlt,
  MdContentCopy,
  MdOutlineTranslate,
} from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { IoMdCheckmark } from "react-icons/io";

const PROFILE_LANG_KEY = "profileLang";
const languages = [
  { label: "English", value: "en" },
  { label: "French", value: "fr" },
  { label: "Spanish", value: "es" },
  { label: "German", value: "de" },
  { label: "Chinese", value: "zh" },
  { label: "Japanese", value: "ja" },
  { label: "Russian", value: "ru" },
  { label: "Italian", value: "it" },
  { label: "Portuguese", value: "pt" },
  { label: "Dutch", value: "nl" },
  { label: "Korean", value: "ko" },
  { label: "Arabic", value: "ar" },
  { label: "Turkish", value: "tr" },
  { label: "Hindi", value: "hi" },
  { label: "Swedish", value: "sv" },
  { label: "Danish", value: "da" },
  { label: "Finnish", value: "fi" },
];
const SmartCat = (props) => {
  const [text, setText] = useState("");
  const [translatedData, setTranslatedData] = useState({});
  const [translatedText, setTranslatedText] = useState("");
  const [profileLang, setProfileLang] = useState("");
  const [isTranslated, setIsTranslated] = useState(false);
  const [textType, setTextType] = useState("translated");
  const [isCopied, setIsCopied] = useState(false);
  const[showTooltip, setShowTooltip] = useState(false);

  const targetTooltip = useRef(null);

  useEffect(() => {
    if (typeof window !== undefined) {
      const profl = localStorage.getItem(PROFILE_LANG_KEY);
      setText(props?.description)
      if (profl) {
        setProfileLang(profl);
      } else {
        setProfileLang("en");
      }
    }
  }, []);

  const onChangeLanguage = (e) => {
    setIsTranslated(false);
    setShowTooltip(false);
    setProfileLang(e.target.value);
    localStorage.setItem(PROFILE_LANG_KEY, e.target.value);
  };

  const translateText = async () => {
    setIsTranslated(true);
    setTextType("translated");

    const body = {
      sourceLanguage: "en",
      targetLanguages: [profileLang],
      texts: [{ text: text, context: "string" }],
    };

      axios.post("/api/translate", body).then((response) => {
        const extractRes = response.data.translations
        setTranslatedData(extractRes);
        setShowTooltip(true);
        const translations = extractRes[profileLang]?.map(item => item?.translation).join('\n');
        setTranslatedText(translations);
      }).catch((err) => {
        console.log("err", err)
      })
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(translatedText)
      .then(() => {
        setIsCopied(true);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      })
      .finally(() => {
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      });
  };

  return (
    <Container>
      <Row className="justify-content-end mb-3">
        {/* <Col xs="auto">
          <Form.Group>
            <Form.Label>Profile language</Form.Label>
            <Form.Control
              as="select"
              onChange={onChangeLanguage}
              value={profileLang}
            >
              <option value="">Select language</option>
              {languages.map((lang, idx) => (
                <option key={idx} value={lang.value}>
                  {lang.label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col> */}
      </Row>
      <Card className="max-w-md mx-auto">
        <Card.Body>
          <Row className="mb-1">
            <Col xs="12" className="f-right">
              <div
                className="d-flex justify-content-end position-relative"
                ref={targetTooltip}
              >
                {text ? (
                  <>
                    {/* <Button
                      variant={
                        textType === "source" ? "primary" : "outline-primary"
                      }
                      size="sm"
                      onClick={() => setTextType("source")}
                      className="py-0"
                    >
                      Source language
                    </Button>
                    <MdArrowRightAlt size={18} className="mx-2" />
                    <Button
                      variant={
                        textType === "translated"
                          ? "primary"
                          : "outline-primary"
                      }
                      size="sm"
                      onClick={() => setTextType("translated")}
                      className="py-0"
                    >
                      {languages.find((l) => l.value === profileLang)?.label}
                    </Button> */}
                    <Form.Group>
                      <Form.Control
                        as="select"
                        className="d-inline-block"
                        onChange={onChangeLanguage}
                        value={"en"}
                        disabled={true}
                        bsPrefix=" "
                      >
                        <option value="">Select language</option>
                        {languages.map((lang, idx) => (
                          <option key={idx} value={lang.value}>
                            {lang.label}
                          </option>
                        ))}
                      </Form.Control>{" "}
                      <MdArrowRightAlt size={18} className="mx-2" />
                      <Form.Control
                        bsPrefix=" "
                        as="select"
                        onChange={onChangeLanguage}
                        value={profileLang}
                      >
                        <option value="">Select language</option>
                        {languages.map((lang, idx) => (
                          <option key={idx} value={lang.value}>
                            {lang.label}
                          </option>
                        ))}
                      </Form.Control>
                      <Button variant="link" onClick={translateText}>
                        Translate
                      </Button>
                    </Form.Group>
                    <Overlay
                      target={targetTooltip.current}
                      show={showTooltip}
                      placement="bottom"
                    >
                      {(props) => (
                        <Tooltip id="button-tooltip" {...props}>
                          {showTooltip && (
                            <div
                              className="position-absolute"
                              style={{ right: "8px", top: "8px" }}
                            >
                              <div
                                className="d-flex gap-1"
                                style={{ justifyContent: "end" }}
                              >
                                {isCopied ? (
                                  <div className="d-flex align-items-center">
                                    <IoMdCheckmark size={16} className="" />
                                    <span className="small">Copied!</span>
                                  </div>
                                ) : (
                                  <div style={{ cursor: "pointer" }}>
                                    <MdContentCopy
                                      title="Copy"
                                      onClick={handleCopy}
                                      size={16}
                                      color="white"
                                    />
                                  </div>
                                )}
                                <div style={{ cursor: "pointer" }}>
                                  <IoClose
                                    className="text-danger ml-2 pr-2"
                                    onClick={() => {
                                      setIsTranslated(false),
                                        setShowTooltip(false);
                                    }}
                                    size={16}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="p-2">
                            {translatedData[profileLang]?.map((item, index) => (
                              <div key={index}>
                                <div className="mt-4">
                                  <p>{item.translation}</p>
                                  {item.error && (
                                    <p>
                                      <small>Error occurred!</small>
                                    </p>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </Tooltip>
                      )}
                    </Overlay>
                  </>
                ) : (
                  <>
                    {text && (
                      <div
                        onClick={translateText}
                        style={{ cursor: "pointer" }}
                      >
                        <MdOutlineTranslate
                          size={16}
                          className="text-muted cursor-pointer"
                          title="Translate"
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </Col>
          </Row>
          <div className="position-relative">
            <Form.Control
              as="textarea"
              rows={3}
              className="mb-2"
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Enter text to translate"
            />
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default SmartCat;
