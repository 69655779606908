
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import PopUpActivityLog from "../../../common/WorkItemBoard/breakfixActivityLog";
import PopUpAuditLog from "./PopUpAuditLog";
import {IoClose} from "react-icons/io5";
import WIBNotifcation from '../../WIBNotification'
class TabPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabKey: 1,
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(tabKey) {
    this.setState({ tabKey });
  }
  render() {
    console.log("Render_tabPage", this.props.taskDetails);
    return (
      <div>
        <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
            <ul>
                <li>
                <button
                    type='button'
                    title="Minimize the right panel"
                    bsClass=""
                    bsStyle=""
                    className="closerightPanelBtn"
                    onClick={() => {
                    this.props.rightEditPanel(false);
                    }}
                >
                    <IoClose />
                </button>
                </li>
            </ul>
        </div>
        <div className="rPageHeading">
          <div className="offNam margin-t-5 margin-b-5">
            {this.props.translator["Activity Details"]}
          </div>
        </div>
        <div className="rBoxGap">
          <div className="rBoxTabs">
            <Tabs
              activeKey={this.state.tabKey}
              onSelect={this.handleSelect}
              id="tabId"
            >
              <Tab eventKey={1} title={this.props.translator["Comments"]}>
                <PopUpActivityLog
                  translator={this.props.translator}
                  role_id={this.props.role_id}
                  taskDetails={this.props.taskDetails}
                  itemId={this.props.taskIdactivity}
                  module="Task"
                  taskCode={this.props.taskCode}
                  date_format={this.props.date_format}
                  disableFieldsForGuestRole={this.props.disableFieldsForGuestRole}
                />
              </Tab>
              <Tab eventKey={2} title={this.props.translator["Audit Log"]}>
                <PopUpAuditLog
                  translator={this.props.translator}
                  taskDetails={this.props.taskDetails}
                  taskCode={this.props.taskCode}
                  module="Task"
                  date_format={this.props.date_format}
                />
              </Tab>
              <Tab eventKey={3} title={"Notifications"}>
              <WIBNotifcation
                requestNumber={this.props.taskCode}
                orderNumber={""}
              />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

export default TabPage;
