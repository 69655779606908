/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const SmartFormApi = createApi({
  reducerPath: "smartFormsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: window.location.origin,
  }),
  tagTypes: ["Forms"],
  endpoints: (builder) => ({
    getAllForms: builder.mutation({
      query: (payload) => {
        return {
          url: "/api/getAllFormsByOfferingId",
          method: "POST",
          body: payload,
          headers: {
            query: JSON.stringify({
              offeringId: payload.offeringId,
              page: payload.page || 0,
              limit: payload.limit || 100,
              order: payload.order || "asc",
              formName: payload.formName || "",
            }),
          },
        };
      },
      providesTags: ["Forms"],
    }),
    saveForm: builder.mutation({
      query: (payload) => {
        return {
          url: "/api/saveFormToOffering",
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["Forms"],
    }),
    updateForm: builder.mutation({
      query: (payload) => {
        return {
          url: "/api/updateFormByOfferingId",
          method: "POST",
          body: payload,
          headers: {
            query: JSON.stringify({
              offeringId: payload.form_id,
            }),
          },
        };
      },
      invalidatesTags: ["Forms"],
    }),
    deleteForm: builder.mutation({
      query: (payload) => {
        return {
          url: "/api/deleteFormFromOffering",
          method: "POST",
          body: payload,
          headers: {
            query: JSON.stringify({
              offeringId: payload.formId,
            }),
          },
        };
      },
      invalidatesTags: ["Forms"],
    }),
  }),
});

export const { useGetAllFormsMutation, useSaveFormMutation, useUpdateFormMutation, useDeleteFormMutation } = SmartFormApi;
const { reducer } = SmartFormApi;
export default reducer;
