
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import moment from "moment";
import { DatePickerF, InputTextFieldNumber } from "./FormFields";

import { getDateWord } from "./CommonMethod";

const ReccuranceBottomComponent = ({
  isAutorize,
  values,
  activeTab,
  _dateFormat,
  setFieldValue,
}) => {
  function disabledRecurringDate(current, date) {
    return current && current.isBefore(moment(date).add(0, "day"));
  }
  return (
    <div className="month month-names" id="bottomreccuranceComponent">
      {values.redioAccuranceSelection === "Ends By" ? (
        <div className="mt-22">
          <DatePickerF
            disabled={isAutorize}
            label="Ending On"
            placeholder=""
            required={
              values.redioAccuranceSelection === "Ends By" ? true : false
            }
            value={
              values.recuringEndOn
                ? moment(
                    values.recuringEndOn,

                    getDateWord(_dateFormat)
                  )
                : ""
            }
            name="recuringEndOn"
            onChange={(value, dateString) => {
              setFieldValue("recuringEndOn", value, true);
            }}
            size={"middle"}
            disabledDate={(val) => disabledRecurringDate(val, values.startOn)}
            style={{
              width: "100%",
            }}
            showTime={false}
            allowClear={false}
            format={getDateWord(_dateFormat)}
          />
        </div>
      ) : null}
      {values.redioAccuranceSelection === "Ends After" ? (
        <div className="mt-4 d-flex align-items-baseline">
          <InputTextFieldNumber
            name="redioAccuranceSelectionEndAfterDaysNo"
            required={false}
            max={999}
            min={1}
            disabled={
              values.redioAccuranceSelection === "Ends After" ? false : true
            }
            onChange={(e) => {
              const val = e.target.value;
              if (val) {
                if (val <= 999 && val > 0) {
                  setFieldValue(
                    "redioAccuranceSelectionEndAfterDaysNo",
                    val,
                    true
                  );
                }
              } else {
                setFieldValue(
                  "redioAccuranceSelectionEndAfterDaysNo",
                  "",
                  true
                );
              }
            }}
          />
          <div id="Occurrences" className="mx-2">
            Occurrences
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default ReccuranceBottomComponent;
