
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Form from 'react-bootstrap/Form';
import { connect } from 'react-redux';
import axios from 'axios';
import ListLoader from '_Commons/loaders/ListLoader';
import swal from 'sweetalert';
import {ImSpinner6} from "react-icons/im";
class W51H extends  React.Component {
     constructor(props) {
          super(props);
          this.state = {
              noofWhys: this.props.noofWhys,
              whysdata: [],
              whysLabel: this.props.whysLabel,
              sendPost: true,
              maxLength:500,
              disableButton: false,
              enableLoader: false,
              createdID: '0',
              showDeleteButton: false,
              disableDeleteButton: false,
              initialActivityDescription:''
          };
          if(this.props.makeDisable===false){
              this.state.disableSubmit = false;
          }
          this.onwhyChange = this.onwhyChange.bind(this);
          this.saveData = this.saveData.bind(this);
          this.submitData = this.submitData.bind(this);
          this.setInitialForm = this.setInitialForm.bind(this);
          this.deleteData = this.deleteData.bind(this);
          this.submitActivityDetails = this.submitActivityDetails.bind(this);
      }
      submitActivityDetails(descriptionValue,moduleName){
        let activityDetails = {
          "itemId":this.props.investigationItemDetails.investigationId,
          "description":'RCA~5W1H~'+descriptionValue,
          "isInternal":"",
          "createdOn":"",
          "createdBy":"",
          "module":moduleName,
          "createdByName":""
         };
        axios.post("/serviceManagement/rest/activity/", activityDetails);
      }
      deleteData(){
        this.setState({disableDeleteButton: true});
        axios.delete("/serviceManagement/rest/rcaDelete/"+this.props.investigationItemDetails.investigationId+"/"+this.props.techniqueID).then((deleteResponse)=>{
            this.props.hideForm();
            let descriptionforDelete='5W1H is deleted';
            this.submitActivityDetails(descriptionforDelete,'Investigation');
        }).catch((error)=>{
            this.setState({disableDeleteButton: false});
            console.log("delete api : ",error);
        });
      }
      setInitialForm(){
        let initialForm =[];
        for(let i=0;i<this.state.noofWhys; i++){
            initialForm.push({value: '', length: 0, error: ''});
        }
        return initialForm;
      }
      submitData(toSendpayload){
        let descritpionTosend='';
        if(toSendpayload.keyValueData.What.trim().length!==0){
          descritpionTosend+='What : '+toSendpayload.keyValueData.What+', ';
        }
        if(toSendpayload.keyValueData.Where.trim().length!==0){
          descritpionTosend+='Where : '+toSendpayload.keyValueData.Where+', ';
        }
        if(toSendpayload.keyValueData.When.trim().length!==0){
          descritpionTosend+='When : '+toSendpayload.keyValueData.When+', ';
        }
        if(toSendpayload.keyValueData.Who.trim().length!==0){
          descritpionTosend+='Who : '+toSendpayload.keyValueData.Who+', ';
        }
        if(toSendpayload.keyValueData.Why.trim().length!==0){
          descritpionTosend+='Why : '+toSendpayload.keyValueData.Why+', ';
        }
        if(toSendpayload.keyValueData.How.trim().length!==0){
          descritpionTosend+='How : '+toSendpayload.keyValueData.How;
        }
        if(descritpionTosend.charAt(descritpionTosend.length-2)===','){
          descritpionTosend=descritpionTosend.substring(0,descritpionTosend.length-2);
        }
        if(this.state.sendPost){
            axios.post('/serviceManagement/rest/rcaTechnique',toSendpayload).then((postResponse) => {
               this.props.hideForm();
               if(this.state.initialActivityDescription!==descritpionTosend){
               this.submitActivityDetails(descritpionTosend,'Investigation');
               }
            }).catch((error) => {
               this.setState({disableButton: false});
               console.log("post api : ",error);
            });
          }else{
            axios.put('/serviceManagement/rest/rcaTechnique/'+this.state.createdID,toSendpayload).then((patchResponse) => {
               this.props.hideForm();
               if(this.state.initialActivityDescription!==descritpionTosend){
                this.submitActivityDetails(descritpionTosend,'Investigation');
              }
            }).catch((error) => {
               this.setState({disableButton: false});
               console.log("put api : ",error);
            });
          }
    }
      componentWillMount(){
        this.setState({enableLoader: true});
        axios.get("/serviceManagment/rest/rcaTechnique/"+this.props.investigationItemDetails.investigationId+"/125").then((responseFindings)=>{
          let whyDatafilled =[];
          if(responseFindings.data.data===null){
            whyDatafilled=this.setInitialForm();
         }else{
          let description5W1h='';
          if(responseFindings.data.data.keyValueData.What.trim().length!==0){
            description5W1h+='What : '+responseFindings.data.data.keyValueData.What+', ';
          }
          if(responseFindings.data.data.keyValueData.Where.trim().length!==0){
            description5W1h+='Where : '+responseFindings.data.data.keyValueData.Where+', ';
          }
          if(responseFindings.data.data.keyValueData.When.trim().length!==0){
            description5W1h+='When : '+responseFindings.data.data.keyValueData.When+', ';
          }
          if(responseFindings.data.data.keyValueData.Who.trim().length!==0){
            description5W1h+='Who : '+responseFindings.data.data.keyValueData.Who+', ';
          }
          if(responseFindings.data.data.keyValueData.Why.trim().length!==0){
            description5W1h+='Why : '+responseFindings.data.data.keyValueData.Why+', ';
          }
          if(responseFindings.data.data.keyValueData.How.trim().length!==0){
            description5W1h+='How : '+responseFindings.data.data.keyValueData.How;
          }
          if(description5W1h.charAt(description5W1h.length-2)===','){
            description5W1h=description5W1h.substring(0,description5W1h.length-2);
          }
          this.props.whysLabel.map((extractedData)=>{
            whyDatafilled.push({value: responseFindings.data.data.keyValueData[extractedData],
                                length: responseFindings.data.data.keyValueData[extractedData].length,
                                initialActivityDescription:description5W1h});
          });
          this.setState({createdID: responseFindings.data.data.id, sendPost: false, showDeleteButton: true});
         }
         this.setState({whysdata:whyDatafilled, enableLoader: false});
        }).catch((errorFindings)=>{
           console.log("response finding error : ",errorFindings);
           let whyDatafilled =[];
           whyDatafilled=this.setInitialForm();
           this.setState({enableLoader: false,whysdata:whyDatafilled});
        });
      }
      onwhyChange(event,whySequenceSelected){
          if(event.target.value.includes('~')){
            return;
          }
          let newDataArray=this.state.whysdata;
          newDataArray[whySequenceSelected].value = event.target.value;
          newDataArray[whySequenceSelected].length = event.target.value.length;
          this.setState({whysdata: newDataArray});
      }
      saveData(){
          let generatedKeyValuepair={},count=0;
          this.state.whysdata.forEach((savedItem,itr)=>{
            if(savedItem.value.trim().length===0){
              count++;
            }
            generatedKeyValuepair[""+this.state.whysLabel[itr]+""]=savedItem.value;
          });
          if(count===this.state.whysdata.length){
            swal({
              text: this.props.translator["Please fill any one of the field for recording 5W1H"],
              button: this.props.translator['OK']
            });
          }if(count!==this.state.whysdata.length){
          let payload = {
            'investigationId': this.props.investigationItemDetails.investigationId,
            'displayId': this.props.techniqueID,
            'displayKey': '5W1H',
            'keyValueData':generatedKeyValuepair,
            'createdOn': '',
            'createdBy': ''
          };
          this.setState({disableButton: true});
          this.submitData(payload);
          }
      }
    render(){
      if(this.state.enableLoader){
        return <ListLoader />;
      }
     return (<div id="Whys5">
         {
             this.state.whysdata.map((item,itr) => {
                  return (<Form.Group>
                              <Form.Label bsClass="">{this.props.translator[''+this.state.whysLabel[itr]+'']}</Form.Label>
                                <Form.Control as="textarea" className="form-control" maxLength={this.state.maxLength} rows="2" disabled={this.props.makeDisable||this.props.disableFieldsForGuestRole||this.props.isServiceDeskRolesEnabled}  onChange={(e)=> this.onwhyChange(e,itr)} value={item.value}/>
                                <p className="charLeft" style={{textAlign:"right","fontSize":"11px"}}>({this.props.translator['Characters Remaining']}: {this.state.maxLength-item.length}/500)</p>
                          </Form.Group>);
             })
         }
         {
             this.props.makeDisable ? '' : <ButtonToolbar>
              <Button className="rgSidrkBtn smallBtn" bsSize="small" bsStyle="primary" title={this.props.translator['Save']} onClick={this.saveData} disabled={this.state.disableDeleteButton||this.state.disableButton||this.props.disableFieldsForGuestRole||this.props.isServiceDeskRolesEnabled}>{this.state.disableButton?<ImSpinner6 className="icn-spinner"/> : null}{this.props.translator["Save"]}</Button>
              <Button className="rgSidrkBtn smallBtn" bsSize="small" bsStyle="primary" title={this.props.translator['Close']} onClick={this.props.hideForm} disabled={this.state.disableDeleteButton||this.state.disableButton}>{this.props.translator['Close']}</Button>
             {this.state.showDeleteButton?<Button className="rgSidrkBtn smallBtn" bsSize="small" bsStyle="primary" title={this.props.translator["Delete"]} onClick={this.deleteData} disabled={this.state.disableDeleteButton||this.state.disableButton||this.props.disableFieldsForGuestRole||this.props.isServiceDeskRolesEnabled}>{this.state.disableDeleteButton?<ImSpinner6 className="icn-spinner"/> : null}{this.props.translator["Delete"]}</Button>:null}
             </ButtonToolbar>
         }
     </div>);
 }
}

const mapStateToProps = ({ investigationItemDetails }) => {
	return {
        investigationItemDetails,
	};
};

export default connect(mapStateToProps, { })(W51H);
