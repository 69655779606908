
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';

//let user_id=userDetails.user_id;
//let company_id=userDetails.company_id;
import { GLOBAL } from '../../components/Globals'

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

export function loadProfileSuccess(profileDetails){
	return {
		type: 'LOAD_PROFILE_SUCCESS',
		profileDetails
	};
}

export function loadProfileFailure(errorResponse){
	return {
		type: 'LOAD_PROFILE_FAILURE',
		errorResponse
	};
}

export function loadProfile(userId=""){
	console.log("action User ",userId)
	let str={};
	if (userId == "") {
		str.userid = "";
	}else{
		str.userid = ""+userId;
	}
	str = JSON.stringify(str);
	return(dispatch) =>{
				//alert("edit"+breakFixUrl+breakFixId);
				dispatch({ type: "LOADER_IN_PROGRESS_3" });

				api.get(GLOBAL.profileDetailsUrl,{headers:{'query':str}})

					.then((profileDetails)=>{
						if(!profileDetails){
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_3' });
							throw Error(profileDetails.statusText);
						}
						return profileDetails;
					})
					.then((profileDetails) =>{
						dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_3' });
						dispatch(loadProfileSuccess(profileDetails))
					})
					.catch((err) => {
						console.log(err);
					});
					//console.log("not calling loadProfile");

	};
}

export function loadProfile2(userId=""){
	console.log("action User ",userId)
	let str={};
	if (userId == "") {
		str.userid = "";
	}else{
		str.userid = ""+userId;
	}
	str = JSON.stringify(str);
	return(dispatch) =>{
				//alert("edit"+breakFixUrl+breakFixId);
				dispatch({ type: "LOADER_IN_PROGRESS_6" });

				api.get(GLOBAL.profileDetailsUrl,{headers:{'query':str}})

					.then((profileDetails)=>{
						if(!profileDetails){
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_6' });
							throw Error(profileDetails.statusText);
						}
						return profileDetails;
					})
					.then((profileDetails) =>{
						dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_6' });
						dispatch(loadProfileSuccess(profileDetails))
					})
					.catch((err) => {
						console.log(err);
					});
					//console.log("not calling loadProfile");

	};
}