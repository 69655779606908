
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
class ProcessHierarchy extends React.Component{
	render(){
		return(
		<div>
			<div className="rPageHeading">
				<div className="offNam margin-t-5 margin-b-5">{this.props.translator['Process Hierarchy']}</div>
			</div>
			<div className='rBoxGap text-c'><img className="img-fluid marAuto"  src={require('../../../../images/change-diagram.png')} title={this.props.translator['Process Hierarchy']} /></div>
		</div>
		);
	}
}
export default ProcessHierarchy;