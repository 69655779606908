
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import * as types from '_Actions/actionTypes';
import initialState from './initialState';

export const reportReducer = (state = initialState,action) => {  
  switch(action.type) {
    case "BREAKFIX_GRAPH_VIEW":
      return Object.assign({},state,{breakFixGraphView:action.payload});
    case "LOAD_BREAKFIX_GRAPH_SLA":
      return Object.assign({},state,{breakFixGraphSlaDetails:action.payload});  
    case "TASK_GRAPH_VIEW":
      return Object.assign({},state,{taskGraphView:action.payload});  
    case "INVESTIGATION_GRAPH_VIEW":
      return Object.assign({},state,{investigationGraphView:action.payload});  
    case "CHANGE_GRAPH_VIEW":
      return Object.assign({},state,{changeGraphView:action.payload});    
    case "FULLFILMENT_GRAPH_VIEW":
      return Object.assign({},state,{fullfilmentGraphView:action.payload});    
    case "LOAD_FULLFILMENT_GRAPH_SLA":
      return Object.assign({},state,{fullfilmentGraphSlaDetails:action.payload});   
    default:
      return state;
  }
}
