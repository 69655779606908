
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Button, ButtonToolbar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { HiEye, HiOutlineRefresh } from "react-icons/hi";
import { change, reduxForm } from "redux-form";
import Cookies from "universal-cookie";
import axios from "axios";
import { getAppliedThemeData } from "../../../actions/foundation/themesAction";
import Swal from "sweetalert2";
import { LuFileText } from "react-icons/lu";
import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline} from "react-icons/io5";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';

const cookies = new Cookies();
const gph = cookies.get("gph").split("~");
const companyId = typeof gph[48] !== "undefined" ? gph[48] : "";

let Header = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const [saveLoader, setSaveLoader] = useState(false);
  const appliedThemeData = useSelector((state) => state.appliedThemeData);
  const context = useContext(MyContext);
  const saveObjRef = useRef(null);

	useEffect(() => {
		context.keyDownHandler("","","",saveObjRef.current);
	}, []);

  let { handleSubmit, submitting } = props;

  const submit = (values) => {
    let formValue = {
      companyId: companyId,
      module: "Themes",
      property: "Themes",
      configurableOn: "Company",
      value:"Specify Your Own"
    };
    formValue.specifyYourOwn = JSON.stringify({
      ...values,
      themeName: props?.themeName,
    });

    let postJson = [formValue];
    setSaveLoader(true);
    postPatchApi(postJson);
  };

  const postPatchApi = (jsonData) => {
    if (appliedThemeData.isFirstTime) {
      axios
        .post("/api/postAdminPreferences", jsonData)
        .then((res) => {
          setSaveLoader(false);
          Swal.fire({
            title: "Applied!",
            text: "Your theme has been applied.",
            icon: "success",
          });
          dispatch(getAppliedThemeData(companyId));
        })
        .catch((error) => {
          console.log(error);
          setSaveLoader(false);
        });
    } else if (!appliedThemeData.isFirstTime) {
      axios
        .patch("/api/updateAdminPreferences", jsonData)
        .then((res) => {
          dispatch(getAppliedThemeData(companyId));
          Swal.fire({
            title: "Applied!",
            text: "Your theme has been applied.",
            icon: "success",
          });
          setSaveLoader(false);
        })
        .catch((error) => {
          console.log(error);
          setSaveLoader(false);
        });
    }
    // } else if (type == "delete") {
    //   axios
    //     .delete("/api/deleteAdminPreferences", jsonData)
    //     .then((res) => {
    //       setSaveLoader(false);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       setSaveLoader(true);
    //     });
    // }
  };

  const refresh = ()=>{
    if (
      appliedThemeData.data &&
      Object.keys(appliedThemeData.data).length > 0
    ) {
      props.setThemeName(appliedThemeData?.data?.themeName);
      props.setDefaultColor(appliedThemeData?.data);
      Object.keys(appliedThemeData?.data).forEach((value) => {
        if (value !== "themeName" || value !== "FontFamily") {
          props.submitValues(appliedThemeData.data[value], value);
          dispatch(
            change("formComponent", value, appliedThemeData.data[value])
          );
        }
      });
    }
  }

  return (
    <Row className="margin-b-15">
      <Col sm={8} xs={7}>
        <h1 bsClass="" className="sPageHeading1">
          {tr["Themes"]}
        </h1>
      </Col>
      <Col sm={4} xs={5}>
        <div className="paHedFilterSecDv">
          {/* <div className="paHedFilter border-r margin-r-10 padding-r-10">
            <Button bsPrefix=" " title="Review" type="button" className="myBt">
              <HiEye />
            </Button>
          </div> */}
          <div className="paHedFilter">
          {!props.isRightSideVisible ?
          <Button
              bsPrefix=" "
              role="button"
              className="myBt d-md-none fillBtn"
              title="Theme Preview"
              onClick={() => {
              props.showRightSideFun(true);
              }}
            >
              <LuFileText/>
            </Button> : null } 
            <Button
              bsPrefix=" "
              title={tr["Refresh"]}
              type="button"
              className="myBt"
              onClick={refresh}
            >
              <HiOutlineRefresh className="refreshFlip" />
            </Button>
            <Link
              to="javascript:void(0)"
              onClick={handleSubmit(submit)}
              bsPrefix=" "
              id="addTheme"
              className="myBt plus"
              disabled={saveLoader}
              title={tr["Save"]}
              ref={saveObjRef}
            >
              <span>{ saveLoader ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/> }</span>
            </Link>
          </div>
        </div>
      </Col>
    </Row>
  );
};
Header = reduxForm({
  form: "formComponent",
  enableReinitialize: true,
})(Header);
export default Header;
