
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Form, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { loadAllScheduleList } from '_Actions/onCallSchedule/schedule/onCallScheduleAction';
import ListLoader from "../../common/loaders/ListLoader.js"
import Pagination from "react-js-pagination";
import { IoSearch } from "react-icons/io5";
import { getCompanyMenuListViewData } from "../../../actions/companyMenuList/companyMenuListAction.js";

const CompanyMenuList = (props) => {
  const dispatch = useDispatch();
  const translator = useSelector((state) => state.spcmReducer.tr);
  const allScheduleList = useSelector((state) => state.companyMenuListData.allScheduleList);
  const allScheduleListMeta = useSelector((state) => state.companyMenuListData.allScheduleListMeta);
  const showScheduleListLoader = useSelector((state) => state.companyMenuListDataLoader?.loading);
  const scheduleType = useSelector(
    (state) => state.on_call_common_reducer.scheduleType
  );
  const statusInput = useSelector((state) => state.on_call_common_reducer.onCallStatus);

  useEffect(() => {
    let filter = { page: 1, limit: 10 };
    dispatch(getCompanyMenuListViewData(filter));
    // dispatch(loadAllScheduleList(filter));
  }, []);

  function onRowSelect(e, row, index) {
    props.setSelectedItemFun({
      companyName: row.company,
      module: row.module,
      field: row.field,
      key: row.key,
      value: row.value,
      language: row.language,
      business_function: row.business_function,
      sequence: row.sequence,
      status: row.status,
      companyId:  row.companyId,
    });
    props.showRightSideFun(true);
    props.showActiveRow(index);
  }

  function renderScheduleList(allScheduleList) {
    return allScheduleList.map((data, index) => {
      return (
        <tr key={index} className={props.isRightSideVisible && index == props.rowIndex ? "myActive" : ""} onClick={(e) => onRowSelect(e, data, index)}>
          <td>{data.company}</td>
          <td>{data.module}</td>
          <td>{data.field}</td>
          <td>{data.key}</td>
          {/* <td>{data.value}</td> */}
          <td>{data.language}</td>
          <td>{data.business_function}</td>
          {/* <td>{data.sequence}</td> */}
        </tr>
      );
    });
  }


  const handleChange = (event, name) => {
    switch (name) {
      case "companyName":
        props.setNameSearchInput(event.target.value);
        break;
      case "module":
        props.setModuleSearchInput(event.target.value);
        break;
      case "key":
        props.setKeySearchInput(event.target.value);
        break;
      case "field":
        props.setFieldSearchInput(event.target.value);
        break;
      case "timezone":
        props.setTimezoneSearchInput(event.target.value);
        break;
      default:
        break;
    }
  };

  const prepareFilters = () => {
    let filter = {};
    if (props.nameSearchInput) {
      filter["searchBy"] = "companyName";
      filter["searchByValue"] = props.nameSearchInput;
    }
    if (props.moduleSearchInput) {
      filter["searchBy"] = "module";
      filter["searchByValue"] = props.moduleSearchInput;
    }
    if (props.keySearchInput) {
      filter["searchBy"] = "key";
      filter["searchByValue"] = props.keySearchInput;
    }
    if (props.fieldSearchInput) {
      filter["searchBy"] = "field";
      filter["searchByValue"] = props.fieldSearchInput;
    }
    if (props.timezoneSearchInput) {
      filter["timeZone"] = props.timezoneSearchInput;
    }
    if (props.statusSearchInput) {
      filter["status"] = props.statusSearchInput;
    }
    if (props.typeSearchInput) {
      filter["scheduleType"] = props.typeSearchInput;
    }
    return filter;
  };


  const onDropDownChange = (e) => {
    let filter = prepareFilters();
    if (e.target.name === "field") {
      props.setTypeSearchInput(e.target.value);
      if (e.target.value) {
        filter["searchBy"] = 'field';
        filter["searchByValue"] = e.target.value;
      } else {
        delete filter["searchByValue"];
      }
    }
    if (e.target.name === "status") {
      props.setStatusSearchInput(e.target.value);
      if (e.target.value) {
        filter["searchBy"] = 'language';
        filter["searchByValue"] = e.target.value;
      } else {
        delete filter["searchByValue"];
      }
    }
    if (Object.keys(filter).length > 0) {
      props.setIsFilterApplied(true);
    } else {
      props.setIsFilterApplied(false);
    }
    filter["page"] = 1;
    filter["limit"] = 10;
    props.setActivePage(1);
    props.setItemsPerPage(10);
    dispatch(getCompanyMenuListViewData(filter));
  };


  const handleSearch = () => {
    let filter = prepareFilters();
    if (Object.keys(filter).length > 0) {
      props.setIsFilterApplied(true);
    } else {
      props.setIsFilterApplied(false);
    }
    filter['page'] = 1;
    filter['limit'] = 10;
    props.setActivePage(1);
    props.setItemsPerPage(10);
    dispatch(getCompanyMenuListViewData(filter));
  }

  const onPageSizeChange = (e) => {
    let filter = prepareFilters();
    filter["page"] = 1;
    filter["limit"] = e.target.value;
    dispatch(getCompanyMenuListViewData(filter));
    props.setActivePage(1);
    props.setItemsPerPage(e.target.value);
  }

  const onPageChange = (e) => {
    let filter = prepareFilters();
    filter["page"] = e;
    filter["limit"] = props.itemsPerPage;
    dispatch(getCompanyMenuListViewData(filter));
    props.setActivePage(parseInt(filter["page"], 10));
    props.showRightSideFun(false);
  };
  let caseResponsive =  showScheduleListLoader || (allScheduleList && allScheduleList.length == 0)
    ? ""
    : props.isRightSideVisible == false
    ? "overFlowClp"
    : "table-responsive";

  return (
    <div>
      <div className="respondv">
      <div className={"tableRgtBor " + caseResponsive}>
          <Table
            striped
            bordered
            condensed
            hover
            className="tableView nowrapWhright"
            style={{ marginTop: "0px" }}
          >
            <thead>
              <tr>
                <th>
                  <div className="sortParArr">{translator["Company Name"]}</div>
                  <div className="colSearDv">
                    <Form.Control
                      className="colSearInp"
                      type="text"
                      value={
                        props.nameSearchInput != ""
                          ? props.nameSearchInput
                          : ""
                      }
                      placeholder={translator["Search here"]}
                      onKeyPress={(e) => {
                        if (e.charCode == "13") {
                          if (
                            props.nameSearchInput != "" &&
                            props.nameSearchInput?.trim().length > 3
                          )
                            handleSearch();
                        }
                      }}
                      onChange={(event) => {
                        handleChange(event, "companyName");
                      }}
                    />
                    {props.nameSearchInput?.trim().length > 3 ? (
                      <a
                        title="search"
                        className="faicn"
                        href={void 0}
                        onClick={(event) => handleSearch()}
                      >
                        <IoSearch />
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </th>
                <th>
                  <div className="sortParArr">{translator["Module"]}</div>
                  <div className="colSearDv">
                    <Form.Control
                      className="colSearInp"
                      type="text"
                      value={
                        props.moduleSearchInput != ""
                          ? props.moduleSearchInput
                          : ""
                      }
                      placeholder={translator["Search here"]}
                      onKeyPress={(e) => {
                        if (e.charCode == "13") {
                          if (
                            props.moduleSearchInput != "" &&
                            props.moduleSearchInput?.trim().length > 3
                          )
                            handleSearch();
                        }
                      }}
                      onChange={(event) => {
                        handleChange(event, "module");
                      }}
                    />
                    {props.moduleSearchInput?.trim().length > 3 ? (
                      <a
                        title="search"
                        className="faicn"
                        href={void 0}
                        onClick={(event) => handleSearch()}
                      >
                        <IoSearch />
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </th>
                <th>
                  <div className="sortParArr">{translator["Field"]}</div>
                  <div className="colSearDv">
                  <Form.Select
                      className="colSearInp"
                      type="text"
                      name="field"
                      onChange={(event) => {
                        onDropDownChange(event);
                      }}
                      
                    >
                      <option value="">Select</option>
                      <option value="Urgency">Urgency</option>
                      <option value="Impact">Impact</option>
                    </Form.Select>
                    </div>
                </th>
                <th>
                  <div className="sortParArr">{translator["Key"]}</div>
                  <div className="colSearDv">
                    <Form.Control
                      className="colSearInp"
                      type="text"
                      value={
                        props.keySearchInput != ""
                          ? props.keySearchInput
                          : ""
                      }
                      placeholder={translator["Search here"]}
                      onKeyPress={(e) => {
                        if (e.charCode == "13") {
                          if (
                            props.keySearchInput != "" &&
                            props.keySearchInput?.trim().length > 3
                          )
                            handleSearch();
                        }
                      }}
                      onChange={(event) => {
                        handleChange(event, "key");
                      }}
                    />
                    {props.keySearchInput?.trim().length > 1 ? (
                      <a
                        title="search"
                        className="faicn"
                        href={void 0}
                        onClick={(event) => handleSearch()}
                      >
                        <IoSearch />
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </th>
                <th>
                  <div className="sortParArr">{translator["Language"]}</div>
                  <div className="colSearDv">
                    <Form.Select
                      className="colSearInp"
                      type="text"
                      name="status"
                      onChange={(event) => {
                        onDropDownChange(event);
                      }}
                    >
                      <option value="">Select</option>
                      <option value="English">English</option>
                      <option value="German">German</option>
                      <option value="French">French</option>
                    </Form.Select>
                  </div>

                </th>
                <th>
                  <div className="sortParArr">{translator["Business Function"]}</div>
                  <div className="colSearDv">
                    <Form.Select
                      className="colSearInp"
                      type="text"
                      name="status"
                      onChange={(event) => {
                        onDropDownChange(event);
                      }}
                    >
                      <option value="">Select</option>
                      {/* <option value="Default">Default</option> */}
                    </Form.Select>
                  </div>

                </th>
              </tr>
            </thead>
            <tbody>
              {showScheduleListLoader ? (
                <tr>
                  <td colSpan="12" style={{ textAlign: "center" }}>
                    <ListLoader />
                  </td>
                </tr>
              ) : allScheduleList.length == 0 ? (
                <tr>
                  <td colSpan="12" style={{ textAlign: "center" }}>
                    {translator["There is no matching data available"]}
                  </td>
                </tr>
              ) : (
                renderScheduleList(allScheduleList)
              )}
            </tbody>
          </Table>
        </div>
      </div>
      {allScheduleList.length > 0 ? 
        <div className="nBotPagina">
          <div className="nShow">
            <div className="margin-r-10" componentClass={Form.Label}>
              {translator["Show"]}:
            </div>
            <Form.Select
              value={props.itemsPerPage}
              onChange={(e) => {
                onPageSizeChange(e);
              }}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              {/* <option value="100">100</option> */}
            </Form.Select>
          </div>
          <nav aria-label="Pagination" className="display-inline-block">
            <Pagination
              prevPageText="Prev"
              nextPageText="Next"
              firstPageText={<i className="glyphicon glyphicon-menu-left" />}
              lastPageText={<i className="glyphicon glyphicon-menu-right" />}
              activePage={
                allScheduleListMeta ? allScheduleListMeta.currentPage : 1
              }
              itemsCountPerPage={props.itemsPerPage}
              totalItemsCount={
                allScheduleListMeta ? allScheduleListMeta.numberOfItems : 1
              }
              pageRangeDisplayed={4}
              onChange={(e) => {
                onPageChange(e);
              }}
            />
          </nav>
        </div>
      :
        ''}
    </div>
  );
}

export default CompanyMenuList;