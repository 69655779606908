
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef } from 'react';
import { Row, Col, PageHeader, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
// import { push } from 'react-router-redux';
let sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

import {GLOBAL} from '_Globals';
import axios from 'axios';

//let user_id = userDetails.user_id;
//let user_name = userDetails.user_name
import { reduxForm, SubmissionError } from 'redux-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { postBusinessFunction } from '../../../actions/businessFunction/businessFunctionAction';
import { getCompanyList } from '_Actions/spcmActions';
import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import { MyContext } from '_MyContext';


const CreateBusinessFunctionHeader = (props) => {
  const companyList = useSelector((state) => state.spcmReducer.companyList);
  const translator = useSelector((state) => state.spcmReducer.tr);
  const scenarioDropDownList = useSelector(
    (state) => state.getIntegrationHubData.scenarioDropDownList
  );
  const showLoader = useSelector((state) => state.showLoader.loading);
  const spcmReducer = useSelector((state) => state.spcmReducer);
  const dispatch = useDispatch();
  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const saveObjRef = useRef(null);

	useEffect(() => {
		context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
    context.newTabPrevent();
	}, []);

  let { handleSubmit, submitting } = props;

  const submit = async (values) => {

    return sleep(1000) // simulate server latency

      .then(() => {
        console.log("Valuesssssssss",values)
        if (`${JSON.stringify(values, null, 2)}` == "{}") {
          throw new SubmissionError({ _error: 'The form has missing required fields, please revalidate the highlighted fields and update the form' })
        }
        else { 
              let postjson;
              postjson = [{
                "companyId": values.COMPANY_ID,
                "businessFunctionName": values.CATEGORY_NAME.trim(),
                "businessFunctionDesc": (values.SUB_CATEGORY_NAME === "" || typeof values.SUB_CATEGORY_NAME === "undefined") ? "" : values.SUB_CATEGORY_NAME.trim(),
                "parentFunction": (values.PARENT_FUNCTION === "" || typeof values.PARENT_FUNCTION === "undefined") ? "" : values.PARENT_FUNCTION,
                "status": (values.STATUS === "" || typeof values.STATUS === "undefined") ? "1" : values.STATUS,
        }];
        console.log("Post JSON",postjson)
        dispatch(postBusinessFunction(postjson));
      }
    });
  };
  return (
    <Row className="margin-b-15">
      <Col xs={8}>
        <h1 bsClass="" className="sPageHeading1">
          {translator["Business Function"]}
        </h1>
      </Col>
      <Col xs={4}>
        <div className="paHedFilter">
          <Link
            bsPrefix=' '
            to="javascript:void(0)"
            className="myBt plus ctrlKeyPrevent"
            onClick={handleSubmit(submit)}
            disabled={submitting}
            title={translator["Save"]}
            ref={saveObjRef}
          >
            <span>{submitting ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/> }</span>
          </Link>
          <Link
            bsPrefix=' '
            className="myBt cancel fillBtn ctrlKeyPrevent"
            title={translator["Close"]}
            to="/businessFunction"
            ref={closeButton}
          >
            <IoClose/>
          </Link>
        </div>
      </Col>
    </Row>
  );
};


export default reduxForm({
  form: "CreateBusinessFunctionForm",
})(CreateBusinessFunctionHeader);
