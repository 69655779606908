
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import {
  Table,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  ButtonToolbar,
  Button,
} from "react-bootstrap";

import TextFieldForm from "./formField.js";
import AttributesListing from "./attributes/attributesListing.js";
import ComponentListing from "./components/componentListing.js";
import DisclaimerListing from "./disclaimers/disclaimerListing.js";
import OrderingInfoListing from "./orderingInformation/orderingInfoListing.js";
import InsideRadioTable from "./insideRadioTable.js";
import FormWithSelectBox from "./FormWithSelectBox.js";
import CategoryTranslationForm from "./category/translationForm.js";
import {
  spcmLoader,
  getAttributesTranslationStatus,
  getTranslationForOfferingNameDescriptionSummary,
  languageListTranslationStatus,
  setTranslationObject,
  getLanguageTranslationForCategoryAndSubcategory
} from "../../../../actions/spcmActions";
import { connect } from "react-redux";
import ListLoader from "_Commons/loaders/ListLoader";
import _ from "lodash";
import sanitizeHtml from 'sanitize-html';

class TranslationsList extends React.Component {
  editPageBasedField(value, tr) {
    switch (value) {
      case "FormField1":
        return (
          <TextFieldForm
            tr={tr}
            fieldName={value}
            lanEditPageFun5={this.props.lanEditPageFun4}
            fieldNameVal2={this.props.fieldNameVal1}
            lanNameVal2={this.props.lanNameVal1}
            lanIdVal2={this.props.lanIdVal1}
            languageCode={this.props.languageCode}
          />
        );
      // case "FormField2":
      //   return (
      //     <InsideTable
      //       tr={tr}
      //       whichInsideFormOpen5={this.props.whichInsideFormOpen4}
      //       insideTableShowFun5={this.props.insideTableShowFun4}
      //       isInsideTableShow5={this.props.isInsideTableShow4}
      //       isLanEditPageOpen5={this.props.isLanEditPageOpen4}
      //       fieldName={value}
      //       lanEditPageFun5={this.props.lanEditPageFun4}
      //       fieldNameVal2={this.props.fieldNameVal1}
      //       lanNameVal2={this.props.lanNameVal1}
      //       lanIdVal2={this.props.lanIdVal1}
      //       languageCode={this.props.languageCode}
      //     />
      //   );
      case "Attribute":
        return (
          <AttributesListing
            tr={tr}
            whichInsideFormOpen5={this.props.whichInsideFormOpen4}
            insideTableShowFun5={this.props.insideTableShowFun4}
            isInsideTableShow5={this.props.isInsideTableShow4}
            isLanEditPageOpen5={this.props.isLanEditPageOpen4}
            fieldName={value}
            lanEditPageFun5={this.props.lanEditPageFun4}
            fieldNameVal2={this.props.fieldNameVal1}
            lanNameVal2={this.props.lanNameVal1}
            lanIdVal2={this.props.lanIdVal1}
            languageCode={this.props.languageCode}
          />
        );
      case "Components":
        return (
          <ComponentListing
            tr={tr}
            whichInsideFormOpen5={this.props.whichInsideFormOpen4}
            insideTableShowFun5={this.props.insideTableShowFun4}
            isInsideTableShow5={this.props.isInsideTableShow4}
            isLanEditPageOpen5={this.props.isLanEditPageOpen4}
            fieldName={value}
            lanEditPageFun5={this.props.lanEditPageFun4}
            fieldNameVal2={this.props.fieldNameVal1}
            lanNameVal2={this.props.lanNameVal1}
            lanIdVal2={this.props.lanIdVal1}
            languageCode={this.props.languageCode}
          />
        );
      case "Disclaimers":
        return (
          <DisclaimerListing
            tr={tr}
            whichInsideFormOpen5={this.props.whichInsideFormOpen4}
            insideTableShowFun5={this.props.insideTableShowFun4}
            isInsideTableShow5={this.props.isInsideTableShow4}
            isLanEditPageOpen5={this.props.isLanEditPageOpen4}
            fieldName={value}
            lanEditPageFun5={this.props.lanEditPageFun4}
            fieldNameVal2={this.props.fieldNameVal1}
            lanNameVal2={this.props.lanNameVal1}
            lanIdVal2={this.props.lanIdVal1}
            languageCode={this.props.languageCode}
          />
        );
      case "OrderingInformation":
        return (
          <OrderingInfoListing
            tr={tr}
            whichInsideFormOpen5={this.props.whichInsideFormOpen4}
            insideTableShowFun5={this.props.insideTableShowFun4}
            isInsideTableShow5={this.props.isInsideTableShow4}
            isLanEditPageOpen5={this.props.isLanEditPageOpen4}
            fieldName={value}
            lanEditPageFun5={this.props.lanEditPageFun4}
            fieldNameVal2={this.props.fieldNameVal1}
            lanNameVal2={this.props.lanNameVal1}
            lanIdVal2={this.props.lanIdVal1}
            languageCode={this.props.languageCode}
          />
        );
      case "FormWithSelectBox":
        return (
          <FormWithSelectBox
            tr={tr}
            fieldName={value}
            lanEditPageFun5={this.props.lanEditPageFun4}
            fieldNameVal2={this.props.fieldNameVal1}
            lanNameVal2={this.props.lanNameVal1}
            lanIdVal2={this.props.lanIdVal1}
            languageCode={this.props.languageCode}
          />
        );
      case "Category":
        return (
          <CategoryTranslationForm
            tr={tr}
            fieldName={value}
            lanEditPageFun5={this.props.lanEditPageFun4}
            fieldNameVal2={this.props.fieldNameVal1}
            lanNameVal2={this.props.lanNameVal1}
            lanIdVal2={this.props.lanIdVal1}
            languageCode={this.props.languageCode}
          />
        );
    }
  }

  componentDidMount() {
    this.props.getAttributesTranslationStatus(
      this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
      this.props.lanIdVal1
    );
    this.props.getTranslationForOfferingNameDescriptionSummary(
      this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
      this.props.lanIdVal1
    );
    this.props.getLanguageTranslationForCategoryAndSubcategory(
      this.props.spcmReducer.currentlySelectedServiceDetails
        .OFFERING_ID,
      this.props.lanIdVal1
    );
  }

  render() {
    console.log("attrubutesTranslationStatus");
    console.log(this.props.spcmReducer.attrubutesTranslationStatus);
    console.log("LIST+++++++++++LIST",this.props.spcmReducer.objectForTranslation);
    console.log(this.props.spcmReducer.catSubcatTranslation);
    const attrubutesTranslationStatus = this.props.spcmReducer
      .attrubutesTranslationStatus[0];
    const nameDescSummaryTranslation = this.props.spcmReducer
      .nameDescSummaryTranslation;
    const tr = this.props.tr;
    const baseLanguage = this.props.spcmReducer.currentlySelectedServiceDetails
      .BASE_LANGUAGE;
    console.log(nameDescSummaryTranslation);
let workItemType = this.props.spcmReducer.currentlySelectedServiceDetails.WORK_ITEM_TYPE;

    return (
      <div>
        {this.props.isLanEditPageOpen4 ? (
          <div>{this.editPageBasedField(this.props.isPageEdit4, tr)}</div>
        ) : (
          <div>
            <div className="rPageHeading lanName-head"><div className="offNam margin-t-5 margin-b-5">Translations - <span>{this.props.lanNameVal1}</span></div></div>
            <div className="rBoxGap">
            <Table
              className="langTable tableView"
              striped
              bordered
              responsive
              style={{ marginBottom: "10px" }}
            >
              <thead>
                <tr>
                  <th width="42%">For</th>
                  <th width="5%">Available</th>
                  <th width="50%"><div style={{"white-space":"nowrap"}}>Translated Text</div></th>
                  <th width="3%">Action</th>
                </tr>
              </thead>
              {this.props.spcmReducer.spcmFetchingAttribute ||
                _.isEmpty(attrubutesTranslationStatus) ? (
                // || _.isEmpty(nameDescSummaryTranslation)
                <tbody>
                  <td colSpan="4">
                    <ListLoader />
                  </td>
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>
                      {attrubutesTranslationStatus.offeringNameTranslationAvailable
                        ? "Yes"
                        : "No"}
                    </td>
                    <td>{nameDescSummaryTranslation.offering_name}</td>
                    <td>
                      <a
                        title="Edit"
                        onClick={() => {
                          this.props.lanEditPageFun4(true, "FormField1");
                          this.props.fieldNameFun1("Name");
                          this.props.setTranslationObject(nameDescSummaryTranslation);
                        }}
                        href="javascript:void(0)"
                      >
                        <i className="fa fa-pencil-square-o"></i>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Short Description</td>
                    <td>
                      {attrubutesTranslationStatus.offeringSummaryTranslationAvailable
                        ? "Yes"
                        : "No"}
                    </td>
                    <td>
                      {
                        <div
                          className=""
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(nameDescSummaryTranslation.summary, {allowedAttributes: {
                              '*': ['style', 'class', 'dir', 'bgcolor'],
                              'table': ['border', 'cellpadding', 'cellspacing'],
                              'th': ['scope']
                            }}),
                          }}
                        ></div>
                      }
                    </td>
                    <td>
                      <a
                        title="Edit"
                        onClick={() => {
                          this.props.lanEditPageFun4(true, "FormField1");
                          this.props.fieldNameFun1("Short Description");
                          this.props.setTranslationObject(nameDescSummaryTranslation);
                        }}
                        href="javascript:void(0)"
                      >
                        <i className="fa fa-pencil-square-o"></i>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td>
                      {attrubutesTranslationStatus.offeringDescriptionTranslationAvailable
                        ? "Yes"
                        : "No"}
                    </td>
                    <td>
                      {
                        <div
                          className=""
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(nameDescSummaryTranslation.description, {allowedAttributes: {
                              '*': ['style', 'class', 'dir', 'bgcolor'],
                              'table': ['border', 'cellpadding', 'cellspacing'],
                              'th': ['scope']
                            }}),
                          }}
                        ></div>
                      }
                    </td>
                    <td>
                      <a
                        title="Edit"
                        onClick={() => {
                          this.props.lanEditPageFun4(true, "FormField1");
                          this.props.fieldNameFun1("Description");
                          this.props.setTranslationObject(nameDescSummaryTranslation);
                        }}
                        href="javascript:void(0)"
                      >
                        <i className="fa fa-pencil-square-o"></i>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Search Tags</td>
                    <td>
                      {attrubutesTranslationStatus.tagsTranslationAvailable
                        ? "Yes"
                        : "No"}
                    </td>
                    <td>
                      {
                        <div
                          className=""
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(nameDescSummaryTranslation.tags, {allowedAttributes: {
                              '*': ['style', 'class', 'dir', 'bgcolor'],
                              'table': ['border', 'cellpadding', 'cellspacing'],
                              'th': ['scope']
                            }}),
                          }}
                        ></div>
                      }
                    </td>
                    <td>
                      <a
                        title="Edit"
                        onClick={() => {
                          this.props.lanEditPageFun4(true, "FormField1");
                          this.props.fieldNameFun1("Search Tags");
                          this.props.setTranslationObject(nameDescSummaryTranslation);
                        }}
                        href="javascript:void(0)"
                      >
                        <i className="fa fa-pencil-square-o"></i>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Attributes</td>
                    <td>
                      {attrubutesTranslationStatus.attributeNameValueTranslationAvailable
                        ? "Yes"
                        : "No"}
                    </td>
                    <td></td>
                    <td>
                      <a
                        title="Edit"
                        onClick={() => {
                          this.props.lanEditPageFun4(true, "Attribute");
                          this.props.fieldNameFun1("Attributes");
                        }}
                        href="javascript:void(0)"
                      >
                        <i className="fa fa-pencil-square-o"></i>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Components</td>
                    <td>
                      {attrubutesTranslationStatus.componentNameTranslationAvailable
                        ? "Yes"
                        : "No"}
                    </td>
                    <td></td>
                    <td>
                      <a
                        title="Edit"
                        onClick={() => {
                          this.props.lanEditPageFun4(true, "Components");
                          this.props.fieldNameFun1("Components");
                        }}
                        href="javascript:void(0)"
                      >
                        <i className="fa fa-pencil-square-o"></i>
                      </a>
                    </td>
                  </tr>
{workItemType == "incidentRequest"? ""
:<tr>
                    <td>Disclaimers</td>
                    <td>
                      {attrubutesTranslationStatus.disclaimersValueTranslationAvailable
                        ? "Yes"
                        : "No"}
                    </td>
                    <td></td>
                    <td>
                      <a
                        title="Edit"
                        onClick={() => {
                          this.props.lanEditPageFun4(true, "Disclaimers");
                          this.props.fieldNameFun1("Disclaimers");
                        }}
                        href="javascript:void(0)"
                      >
                        <i className="fa fa-pencil-square-o"></i>
                      </a>
                    </td>
</tr>
  }
{/* {workItemType == "incidentRequest"? "" */}
{/* : */}
<tr>
<td>Ordering Information</td>
<td>
  {attrubutesTranslationStatus.questionsValueTranslationAvailable &&
    attrubutesTranslationStatus.questionCategoriesTranslationAvailable
    ? "Yes"
    : "No"}
</td>
<td></td>
<td>
  <a
    title="Edit"
    onClick={() => {
      this.props.lanEditPageFun4(
        true,
        "OrderingInformation"
      );
      this.props.fieldNameFun1("Ordering Information");
    }}
    href="javascript:void(0)"
  >
    <i className="fa fa-pencil-square-o"></i>
  </a>
</td>
</tr>
{/* } */}
                  
                  {/* <tr>
                        <td>Fulfillment Plan</td>
                        <td>
                          {attrubutesTranslationStatus.attributeNameValueTranslationAvailable
                            ? "Yes"
                            : "No"}
                        </td>
                        <td></td>
                        <td>
                          <a
                            title="Edit"
                            onClick={() => {
                              this.props.lanEditPageFun4(true, "FormField1");
                              this.props.fieldNameFun1("Fulfillment Plan");
                            }}
                            href="javascript:void(0)"
                          >
                            <i className="fa fa-pencil-square-o"></i>
                          </a>
                        </td>
                          </tr>*/}
                  <tr>
                    <td>Category And Business Function</td>
                    <td>
                      {attrubutesTranslationStatus.categorySubcategoryTranslationAvailable
                        ? "Yes"
                        : "No"}
                    </td>
                    <td></td>
                    <td>
                      <a
                        title="Edit"
                        onClick={() => {
                          this.props.lanEditPageFun4(true, "Category");
                          this.props.fieldNameFun1("Category");
                        }}
                        href="javascript:void(0)"
                      >
                        <i className="fa fa-pencil-square-o"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              )}
            </Table>
            <Row className="margin-t-10">
              <Col md={12}>
                <ButtonToolbar className="black">
                  <Button
                    onClick={() => {
                      this.props.langActSecFun4(false);
                      this.props.languageListTranslationStatus(
                        this.props.spcmReducer.currentlySelectedServiceDetails
                          .OFFERING_ID,
                        baseLanguage !== null ? baseLanguage : "English"
                      );
                    }}
                    className="rgSiLigBtn"
                    bsSize="small"
                    bsStyle="text"
                  >
                    Close
                  </Button>
                </ButtonToolbar>
              </Col>
            </Row>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ spcmReducer, getAttributesTranslationStatus }) => {
  return {
    spcmReducer,
    getAttributesTranslationStatus,
  };
};

export default connect(mapStateToProps, {
  getAttributesTranslationStatus,
  getTranslationForOfferingNameDescriptionSummary,
  languageListTranslationStatus,
  setTranslationObject,
  getLanguageTranslationForCategoryAndSubcategory
})(TranslationsList);
