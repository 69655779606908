
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';

//let user_id=userDetails.user_id;
//let company_id=userDetails.company_id;

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

export function fetchNewLaunchesSuccess(newLaunches){
	return {
		type: 'FETCH_NEWLAUNCHES',
		newLaunches
	};
}

export function loadNewLaunches(start,end){
	let str={};
    str.companyId="";
	str.userId="";
	str.currency="USD";
	if(start!=""){
		str.start=start;
	}
	if(end!=""){
		str.end=end;
	}
	str = JSON.stringify(str);

const newLaunchesUrl="/getNewLaunchesOfferingForRestifySelfService"

	return(dispatch) =>{
		dispatch({ type: "NEW_LAUNCH_LOADER_IN_PROGRESS" });
		api.get(newLaunchesUrl,{headers:{'query':str}})
			.then((newLaunches)=>{
				if(!newLaunches){
					throw Error(newLaunches.statusText);
				}
				return newLaunches;
			})
			.then((newLaunches) =>{
				dispatch(fetchNewLaunchesSuccess(newLaunches))
				dispatch({ type: 'NEW_LAUNCH_LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
			})
			.catch((err) => {
				// console.log(err);
				dispatch({ type: 'NEW_LAUNCH_LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
			});
	};
}
