
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Card from 'react-bootstrap/Card';
import Cookies from 'universal-cookie';
import datetimeConvertor from '../../../../ISO8601converter';
import { useSelector } from 'react-redux';
import moment from 'moment';
const cookies = new Cookies();
let timezone = cookies.get("gph");
if (timezone) timezone = timezone.replace("s:", "");
if (timezone) timezone = timezone.substring(0, timezone.lastIndexOf("."));
timezone = timezone.split("~");
timezone = timezone[3];
let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();
const ApprovalHistory = (props) => {
  const translator = useSelector((state) => state.spcmReducer.tr);
  const renderAuditLog = (approvalAuditLogData) => {
    const approvalAuditLog = approvalAuditLogData.reduce((obj, index) => {
      const date = moment(obj.created_on).format(jwtdf);
      if (!obj[date]) {
        obj[date] = [];
      }
      obj[date].push(index);
      return obj;
    }, {});
    return Object.keys(approvalAuditLog).map((auditObj, index) => {
      return (
        <div className="actCommts">
          {approvalAuditLog[auditObj].map((comment, childKey) => {
            let actDateTimeFormat = jwtdf + " hh:mm A";
            let myDateObj,
              endDate,
              endDateStr,
              timeago,
              startTime,
              duration,
              hours,
              isoDate;
            isoDate = datetimeConvertor(comment.created_on, jwtdf + " HH:mm:ss");
            myDateObj = moment(isoDate).format(actDateTimeFormat);
            endDate = moment(myDateObj, actDateTimeFormat);
            endDate = moment(myDateObj, actDateTimeFormat);
            endDateStr = endDate._i;
            startTime = moment(
              new Date().toLocaleString("en-US", { timeZone: timezone })
            );
            timeago = moment(endDateStr, actDateTimeFormat).from(startTime);
            duration = moment.duration(startTime.diff(endDate));
            hours = duration.asHours();
            let auditfield = comment.audit_field.replace(/_/g, " ");
            if (
              comment.from == "" ||
              comment.from == null ||
              comment.from == "0" ||
              comment.from == 0 ||
              comment.from == "Select" ||
              comment.from == "select" ||
              comment.from == "<<blank>>"
            )
              comment.from = "NA";
            if (
              comment.to == "" ||
              comment.to == null ||
              comment.to == "0" ||
              comment.to == 0 ||
              comment.to == "Select" ||
              comment.to == "select" ||
              comment.to == "<<blank>>"
            )
              comment.to = "NA";

            return (
              <Card key={childKey} bsPrefix=" ">
                <div className="subhed">
                  <span className="margin-r-20">{comment.created_by}</span>
                  <span
                    className="margin-l-5 timeao"
                    title={hours >= 24 ? "" : endDateStr}
                  >
                    {hours >= 24 ? endDateStr : timeago}
                  </span>
                </div>
                <div className="desFld">
                  <div title="Field Name" className="fenme">
                    {auditfield}
                  </div>
                  <div title="New Value" className="nwnme commn">
                    {comment.to}
                  </div>
                  <div title="Old Value" className="oldnme commn">
                    {comment.from}
                  </div>
                </div>
              </Card>
            );
          })}
        </div>
      );
    });
  };

  return (
    <>
      {props.approvalAuditLogData && props.approvalAuditLogData.length > 0
        ? renderAuditLog(props.approvalAuditLogData)
        : translator["Data not available."]}
    </>
  );
};

export default ApprovalHistory;
