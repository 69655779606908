/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Button } from "react-bootstrap";
import { RiMenuSearchLine } from "react-icons/ri";
import { useSelector } from "react-redux";

const QuerySearchSubStstusComponent = ({ showConditionBuilder, onSubStatusSelect, selectedFilterTab }) => {
    const translator = useSelector(state => state.spcmReducer.tr);

   return (
    <Button title={translator['Advanced Search']} bsPrefix=" " onClick={()=>{showConditionBuilder(true); onSubStatusSelect(5); selectedFilterTab(5); }}>
        <RiMenuSearchLine style={{fontSize:"15px",verticalAlign:"sub"}} /> {translator['Advanced Search']}
    </Button>
   );
};

export default QuerySearchSubStstusComponent;
