
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import EditOutBoundConHeader from "./EditOutBoundConHeader";
import { useSelector } from "react-redux";

import Cookies from "universal-cookie";
import EditOutBoundConForm from "./EditOutBoundConForm";
import Breadcrumbs from "../../common/header/breadcrumbs";
import "_Css/form/_form.scss";
const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home") homepagelocation = "/" + homepagelocation;

const EditOutBoundConIndex = (props) => {
  const [changeActionView, setChangeActionView] = useState("assetgraph");
  const translator = useSelector((state) => state.spcmReducer.tr);

  return (
    <div>
      <div>
        <div className="container-fluid padding-t-10 margin-b-15">
          <Breadcrumbs
            activePageName={"Edit"}
            parentPageurl="/integHub"
            parentPageName={"Integration Hub"}
          />
        </div>
        <div className="container-fluid" bsClass="">
          <Form>
            <EditOutBoundConHeader
              changeActionView={changeActionView}
              translator={translator}
            />
            <Row className="row-eq-height">
              <Col md={12}>
                <EditOutBoundConForm translator={translator} />
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default EditOutBoundConIndex;
