
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Button } from 'react-bootstrap';
import {useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {postCreatedUserAction,resetDetails} from "../../../../../actions/foundation/userAction";
import { reduxForm } from "redux-form";

import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';

const HeaderSec = (props) => {
  const { isOrgInfoMandatory = true } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const saveObjRef = useRef(null);

	useEffect(() => {
		context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
    context.newTabPrevent();
	}, []);

    let { handleSubmit, submitting } = props;
    const submit = async (values) => {
        console.log("userValues", values,props);
        let isValid = true;
        var phoneno =/^\+?\d+$/;
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;    
        let specialCharRegex = /[^-_'.@&0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz]/

        console.log("sTATUS===",values.status);
        if(!values.status){
          values.status = "Y"
        }
        //user form
        if(!props.companyValue || props.companyValue.length == 0){
          props.setCompanyErrorColor('error');
          isValid = false ;
        }
        if ( !values.username || values.username == ''|| values.username.trim() == '' || values.username.trim().match(specialCharRegex)) {
          props.setUsernameErr("error");
          isValid = false;
        } else {
          props.setUsernameErr("");
      }
        if ( !values.firstname || values.firstname == ''|| values.firstname.trim() == '' || values.firstname.trim().match(specialCharRegex)) {
          props.setFirstName("error");
          isValid = false;
        } else {
          props.setFirstName("");
      }
        if ( !values.lastname || values.lastname == ''|| values.lastname.trim() == '' || values.lastname.trim().match(specialCharRegex)) {
          props.setLastnameErr("error");
          isValid = false;
        } else {
          props.setLastnameErr("");
      }
      if (values.middlename && values.middlename.trim() !== "") {
        if (values.middlename.trim().match(specialCharRegex)) {
          props.setMiddlenameErr("error");
          isValid = false;
        } else {
          props.setMiddlenameErr("");
        }
      }
      else {
        props.setMiddlenameErr("");
      }

      // user -personal form
        if ( !values.email || values.email == ''|| values.email.trim() == '') {
          console.log("email===",values.email);
          props.setEmailErr("error");
          isValid = false;
        } else {
          if(values.email.trim().match(validRegex)) {
            props.setEmailErr("");
          } else {
            props.setEmailErr("error");
            isValid = false;
          }
      }
      if(values.businessPhone && values.businessPhone.trim() !== "") {
        if (values.businessPhone.match(phoneno)) {
          props.setBusinessErr("");
        } else {
          props.setBusinessErr("error");
          isValid = false;
        }
      } else {
        props.setBusinessErr("");
      }
      if(values.mobilePhone && values.mobilePhone.trim() !== "") {
        if (values.mobilePhone.match(phoneno)) {
          props.setMobileErr("");
        } else {
          props.setMobileErr("error");
          isValid = false;
        }
      } else {
        props.setMobileErr("");
      }
      if(values.contactPhone && values.contactPhone.trim() !== "") {
        if (values.contactPhone.match(phoneno)) {
          props.setcontactErr("");
        } else {
          props.setcontactErr("error");
          isValid = false;
        }
      } else {
        props.setcontactErr("");
      }

      //user -organization Form
      if(isOrgInfoMandatory == true){
        if (!values.organizationId || values.organizationId == '' || values.organizationId == '') {
          props.setOrgzErr("error");
          isValid = false;
        } else {
          props.setOrgzErr("");
        }
  
        if (!values.departmentId || values.departmentId == '' || values.departmentId == '') {
          props.setDepErr("error");
          isValid = false;
        } else {
          props.setDepErr("");
        }
  
        if ( !values.locationId || values.locationId == ''|| values.locationId == 'null') {
          props.setLocationErrColor("error");
          isValid = false;
        } else {
          props.setLocationErrColor("");
        }
  
        if ( !values.costcenterId || values.costcenterId == ''|| values.costcenterId == 'null') {
          props.setCCErrColor("error");
          isValid = false;
        } else {
          props.setCCErrColor("");
        }
  
        if ( !values.cwlId || values.cwlId == ''|| values.cwlId == 'null') {
          props.setCWLColorErr("error");
          isValid = false;
        } else {
          props.setCWLColorErr("");
        }
      }

      //user - information form
      if ( !values.userTypeId || values.userTypeId == ''|| values.userTypeId == 'null') {
        props.setUserTypeErr("error");
        isValid = false;
      } else {
        props.setUserTypeErr("");
      }
      if ( !values.dateformat || values.dateformat == ''|| values.dateformat == 'null') {
        props.setDateFormErr("error");
        isValid = false;
      } else {
        props.setDateFormErr("");
      }
      if ( !values.status || values.status == ''|| values.status == 'null') {
        props.setStatusErr("error");
        isValid = false;
      } else {
        props.setStatusErr("");
      }
      if ( !values.timeZone || values.timeZone == ''|| values.timeZone == 'null') {
        props.setTimeZoneErr("error");
        isValid = false;
      } else {
        props.setTimeZoneErr("");
      }
      if ( !values.language || values.language == ''|| values.language == 'null') {
        props.setLanguageErr("error");
        isValid = false;
      } else {
        props.setLanguageErr("");
      }
      if ( !values.authenticationSource || values.authenticationSource == ''|| values.authenticationSource == 'null') {
        console.log("CheckAuthError")
        props.setAuthSource("error");
        isValid = false;
      } else {
        props.setAuthSource("");
      }
      if ( !values.baseCurrency || values.baseCurrency == ''|| values.baseCurrency == 'null') {
        props.setCurrencyErr("error");
        isValid = false;
      } else {
        props.setCurrencyErr("");
      }
        
        
        if (isValid == true) {
        let postjson;
        postjson = {
          "appId": null,
          "username": values.username,
          "password": null,
          "companyId": values.companyId,
          "companyName": values.companyName,
          "supportCompany": null,
          "supportCompanyName": null,
          "groupId": null,
          "groupAllId": null,
          "roleId": null,
          "roleAllId": null,
          "functionId": null,
          "title": null,
          "firstname": values.firstname,
          "middlename": values.middlename?values.middlename:'',
          "lastname": values.lastname,
          "fullname": null,
          "status": values.status?values.status:null,
          "photo": null,
          "email":values.email,
          "employeeNumber": values.employeeNumber?values.employeeNumber:'',
          "employeeBand": values.employeeBand?values.employeeBand:'',
          "currency": null,
          "l1Head": null,
          "l2Head": null,
          "l3Head": null,
          "l4Head": null,
          "gender": values.gender?values.gender:null,
          "businessPhone": values.businessPhone?values.businessPhone:null,
          "mobilePhone": values.mobilePhone?values.mobilePhone:null,
          "contactPhone": values.contactPhone?values.contactPhone:null,
          "building": values.building?values.building:null,
          "cubicle": values.cubicle?values.cubicle:null,
          "authenticationSource": values.authenticationSource,
          "organizationId": values.organizationId?values.organizationId:0,
          "organizationName": null,
          "departmentId": values.departmentId?values.departmentId:0,
          "departmentName": null,
          "costcenterId": values.costcenterId?values.costcenterId:'',
          "costcenterName": values.costcenterName?values.costcenterName:null,
          "contentType": null,
          "usergroupId": null,
          "usergroupName": null,
          "associatedCompanies": null,
          "lastUpdateDate": null,
          "bestSelling": null,
          "newLaunch": null,
          "comingSoon": null,
          "free": null,
          "myFavourite": null,
          "catwise": null,
          "prefId": null,
          "prefValue": null,
          "featured": null,
          "languagePreference": null,
          "alternateEmail": null,
          "alternatePhone": null,
          "alternateLocation": null,
          "container": null,
          "id": 0,
          "supportGroup": null,
          "technicalOwner": null,
          "businessOwner": null,
          "assignedTo": null,
          "externalUserId": null,
          "isOrgTabClicked": null,
          "hometab_preference": [],
          "profileImageName": null,
          "imageName": null,
          "imageUrl": null,
          "companyTypeCheck": null,
          "locationId": values.locationId?values.locationId:'',
          "locationName": values.locationName?values.locationName:null,
          "cwlId": values.cwlId?values.cwlId:'',
          "cwlName": values.cwlName?values.cwlName:null,
          "managerId": values.managerId?values.managerId:null,
          "userTypeId": values.userTypeId?values.userTypeId:null,
          "dateformat": values.dateformat,
          "datasource": values.datasource?values.datasource:null,
          "vip": values.vip?values.vip:null,
          "pwdNeedsReset": null,
          "pwdPolicyUser": null,
          "personCode": null,
          "notificationNotRequired": null,
          "createDate": null,
          "createdBy": null,
          "lastUpdatedDate": 0,
          "lastUpdatedBy": null,
          "loginTime": null,
          "role": null,
          "pagesize": 0,
          "eventNameDefault": null,
          "filterby": null,
          "timeZone": values.timeZone,
          "timezoneid": null,
          "actionVal": null,
          "managerName": values.managerName?values.managerName:null,
          "checkActionMode": null,
          "baseCurrency": values.baseCurrency,
          "baseCurrencytext": null,
          "timeZonetext": null,
          "dateformattext": null,
          "languagetext": null,
          "language": values.language,
          "companyList": null,
          "autoApprove": values.autoApprove?values.autoApprove:null,
          "cityId": null,
          "stateId": null,
          "countryId": null,
          "managerEmployeeNumber": null,
          "managerEmail": null,
          "uniqueKey": null,
          "resetPassword": false,
          "autoGeneratePassword": true,
          "forcePasswordChange": false,
          "userIdenS": null,
          "file": null,
          "profilePicTempURL": null,
          "roleName": null,
          "groupName": null,
          "roleNames": null,
          "groupType": null,
          "prvAccs": null,
          "prvJustification": null,
          "groupJustification": null,
          "roleJustification": null,
          "filterbytext": null
        };
    dispatch(postCreatedUserAction(postjson));
    dispatch(resetDetails());

        }
    };
    return (
      <>
        <Row className="margin-b-15">
          <Col sm={8} xs={7}>
            <h1 bsClass="" className="sPageHeading1">
              {tr["User"]}
            </h1>
          </Col>
          <Col sm={4} xs={5}>
            <div className="paHedDisplay">
              {/* <div class="paHedFilter display-inline-block border-r margin-r-10 padding-r-10">
                            <Button bsPrefix=' ' role="button" className="myBt fillBtn" title="Personal Information"><i class="fa fa-info" aria-hidden="true"></i></Button>
                            <Button bsPrefix=' ' role="button" className="myBt fillBtn" title="Organizational Information"><i class="fa fa-building-o" aria-hidden="true"></i></Button>
                            <Button bsPrefix=' ' role="button" className="myBt fillBtn" title="User Information"><i class="fa fa-user" aria-hidden="true"></i></Button>
                        </div> */}
              <div className="paHedFilter">
                <Link
                  bsPrefix=" "
                  id="hdID"
                  to="javascript:void(0)"
                  className="myBt plus ctrlKeyPrevent"
                  disabled={props.loader}
                  onClick={handleSubmit(submit)}
                  title={tr["Save"]}
                  ref={saveObjRef}
                >
                  <span>{props.loader ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/>}</span>
                </Link>
                <Link
                  bsPrefix=" "
                  to="/user"
                  className="myBt cancel fillBtn ctrlKeyPrevent"
                  title={tr["Cancel"]}
                  onClick={() => {
                    dispatch(resetDetails());
                  }}
                  ref={closeButton}
                >
                  <IoClose/>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
}

// export default HeaderSec;
export default reduxForm({
    form: "userForm",
})(HeaderSec);