
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Row, Col, Button } from "react-bootstrap";
import Editor from "../../../../../../common/Editor/Editor";
import ListLoader from "../../../../../../common/loaders/ListLoader";
import {
  useCreateMeetingMutation,
  useSaveMinutesDetailsMutation,
  useUpdateMinutesDetailsMutation,
} from "../../../../../../../reducers/governance/meeting/meeting";
import axios from "axios";

const GenralInformation = ({
  data,
  occurenceData,
  isFetching,
  companyID,
  refetchlist,
  definationId,
  isEdit,
  isAuthorized,
}) => {
  const occuranceId = occurenceData?.meetingOccurrananceId;

  const validationSchema = Yup.object().shape({
    generalInformation: Yup.string().required("Required"),
  });
  const [descLength, setDescLength] = useState(0);
  const characterLimit = 5001;
  let genInfoRef = React.createRef();

  const [initVals, setInitVals] = useState({
    generalInformation: "",
  });

  const [updateMinutes, { isLoading: isMinuteLoading }] =
    useUpdateMinutesDetailsMutation();

  const [createGenInfo, { isLoading: isGenInfoLoading }] =
    useSaveMinutesDetailsMutation();

  const [userId, setUserId] = useState("");

  const onSubmit = async (payload, { setSubmitting }) => {
    if (payload?.minutesId) {
      await updateMinutes({
        data: {
          minutesId: payload.minutesId,
          occuranceId: occuranceId,
          generalInformation: payload?.generalInformation,
          definationId: definationId,
        },
        queryString: `?tenantId=${companyID}`,
      });
      setSubmitting(false);
    } else {
      const res = await createGenInfo({
        data: {
          occuranceId: occuranceId,
          generalInformation: payload?.generalInformation,
          definationId: definationId,
        },
        queryString: `?tenantId=${companyID}`,
      });
      setSubmitting(false);
    }
  };

  useEffect(() => {
    setInitVals((prevState) => {
      return {
        ...prevState,
        ...data,
      };
    });
  }, [data]);

  useEffect(() => {
    const unprivilegedEditor = genInfoRef?.current?.unprivilegedEditor;
    if (unprivilegedEditor) {
      setDescLength(unprivilegedEditor.getLength());
    }
    return () => {
      genInfoRef = null;
    };
  }, [initVals]);

  const api = axios.create({
    headers: { Pragma: "no-cache", "Cache-control": "no-store" },
  });

  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = () => {
    try {
      let str = {};
      str.user_id = "";
      str = JSON.stringify(str);
      api
        .get("/api/userInfo", { headers: { query: str } })
        .then((res) => {
          setUserId(res?.data?.user_id);
        })
        .catch((err) => {
          console.error(err);
          setUserId("");
        });
    } catch (e) {
      console.error(e);
    }
  };

  const checkCharacterCount = (event) => {
    const unprivilegedEditor = genInfoRef.current.unprivilegedEditor;
    if (unprivilegedEditor.getText().trim().length === 0) {
      setDescLength(1);
    } else {
      setDescLength(unprivilegedEditor.getLength());
    }

    if (
      unprivilegedEditor.getLength() >= characterLimit &&
      event.key !== "Backspace"
    )
      event.preventDefault();
  };

  return isFetching ? (
    <div className="fnloader">
      <ListLoader />
    </div>
  ) : (
    <Formik
      enableReinitialize={true}
      initialValues={initVals}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        handleBlur,
        values,
        handleChange,
        setValues,
        resetForm,
        dirty,
      }) => {
        return (
          console.log("occurenceData.primaryChairPerson",occurenceData.primaryChairPerson),
          console.log("userId",userId),
          <Form noValidate>
            {occurenceData.status != "Cancelled" && occurenceData.primaryChairPerson == userId?
            <Row>
              <Col xs={12}>
                <Editor
                  ref={genInfoRef}
                  textlength={descLength}
                  characterLimit={5001}
                  name="generalInformation"
                  // label={"General Information"}
                  value={values.generalInformation}
                  placeholder="General Information..."
                  onKeyDown={checkCharacterCount}
                  onChange={(value) => {
                    setFieldValue("generalInformation", value, true);
                  }}
                  disabled={false}
                />
              </Col>
            </Row> : <Row>
              <Col xs={12}>
                <Editor
                  ref={genInfoRef}
                  textlength={descLength}
                  name="generalInformation"
                  value={values.generalInformation}
                  placeholder="General Information..."
                  // onKeyDown={checkCharacterCount}
                  // onChange={(value) => {
                  //   setFieldValue("generalInformation", value, true);
                  // }}
                  disabled={true}
                />
              </Col>
            </Row>}
           {occurenceData.status != "Cancelled" && occurenceData.primaryChairPerson == userId ?
            <Row>
              <Col xs={12}>
                <div className="pull-right custom-note-submit-btn">
                  <Button
                    disabled={isSubmitting}
                    title="Save"
                    className="rgSidrkBtn smallBtn margin-t-10 btn btn-primary"
                    type="submit"
                  >
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
            :
            <Row>
            <Col xs={12}>
              <div className="pull-right custom-note-submit-btn">
                <Button
                  title="Save"
                  className="rgSidrkBtn smallBtn margin-t-10 btn btn-primary"
                  type="submit"
                  disabled
                >
                  Save
                </Button>
              </div>
            </Col>
          </Row>
            }
          </Form>
        );
      }}
    </Formik>
  );
};

export default GenralInformation;
