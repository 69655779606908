
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  DayPicker,
  CaptionProps,
  useNavigation,
  useDayPicker,
} from "react-day-picker";
import { format } from "date-fns";
import "react-day-picker/dist/style.css";
import { prefDateFormatAccordingUser } from "../../common/CommonMethod";
import Filters from "./filter";
import MeetingEventList from "./meetingEventList";

export const EventCalendar = ({
  handleDateSelect,
  companyId,
  userCompanyName,
  filtersData,
  setFiltersData,
  allEvenstData,
  dateformat,
  allEventByDate,
}) => {
  let _dateFormat = prefDateFormatAccordingUser(dateformat, true);
  const [bookedDates, setBookedDates] = useState([]);

  const bookedStyle = {
    backgroundColor: "#11b5c5",
    color: "#fff",
    borderRadius: 0,
    height: 28,
    width: 28,
  };

  const CustomNavigation = (props) => {
    const { goToMonth, nextMonth, previousMonth } = useNavigation();

    const { today } = useDayPicker();

    return (
      <div className="d-flex justify-content-center position-relative date-picker-header datePickerNav">
        <a
          role="button"
          className="datepicker-prev"
          title="Prev"
          disabled={!previousMonth}
          onClick={() => {
            previousMonth && goToMonth(previousMonth);

            const currentMonthName = format(previousMonth, "MM");
            const currentYear = format(previousMonth, "yyyy");

            setFiltersData((prev) => {
              return {
                ...prev,
                month: currentMonthName,
                year: currentYear,
              };
            });
          }}
        >
          <span></span>
        </a>
        <a
          role="button"
          className="datepicker-next ui-corner-all"
          title="Next"
          disabled={!nextMonth}
          onClick={() => {
            nextMonth && goToMonth(nextMonth);
            const currentMonthName = format(nextMonth, "MM");
            const currentYear = format(nextMonth, "yyyy");

            setFiltersData((prev) => {
              return {
                ...prev,
                month: currentMonthName,
                year: currentYear,
              };
            });
          }}
        >
          <span></span>
        </a>
        <div className="datepicker-title">
          <span className="datepicker-month">
            {format(props.displayMonth, "MMMM yyy")}
          </span>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const newBookedDates = allEvenstData.reduce(
      (acc, { startDateTime, endDateTime }) => {
        const startDate = moment(startDateTime, _dateFormat);
        const endDate = moment(endDateTime, _dateFormat);

        for (
          var m = moment(startDate);
          m.isSameOrBefore(endDate);
          m.add(1, "days")
        ) {
          acc.push(new Date(m));
        }
        return acc;
      },
      []
    );

    setBookedDates(newBookedDates);
  }, [allEvenstData, dateformat]);

  return (
    <div className="border-1">
      <div className="filters">
        <Filters
          companyId={companyId}
          userCompanyName={userCompanyName}
          filtersData={filtersData}
          setFiltersData={setFiltersData}
        />
      </div>
      <div className="mt-2">
        <DayPicker
          showOutsideDays
          mode="single"
          onSelect={handleDateSelect}
          weekStartsOn={1}
          modifiersClassNames={{
            selected: "my-selected",
            today: "cal-today",
          }}
          modifiers={{ booked: bookedDates }}
          modifiersStyles={{ booked: bookedStyle }}
          formatters={{
            formatWeekdayName: (nameOfDay) => format(nameOfDay, "iii"),
          }}
          components={{
            Caption: CustomNavigation,
          }}
          filtersData={filtersData}
          setFiltersData={setFiltersData}
        />
        <div className="pt-2">
          <MeetingEventList
            filtersData={filtersData}
            setFiltersData={setFiltersData}
            allEventByDate={allEventByDate}
            allEvenstData={allEvenstData}
          />
        </div>
      </div>
    </div>
  );
};
