
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import {
  getAllCompanyList,
  getRegionList,
  getForumType,
  getForumStatus,
  getAllSubTrack,
  getForumById,
} from "../../../../reducers/foundation/forum/forum";
// import PindCategories from "../../../common/header/pind-categories";
import Breadcrumbs from "../../../common/header/breadcrumbs";
import Header from "./header";
import FormComponent from "./add-edit";
import "_Css/form/_form.scss";

const AddEditActionItem = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const actionId = params?.actionId;
  const isAddMode = !actionId;

  const tr = useSelector((state) => state.spcmReducer.tr);

  useEffect(() => {
    (async function () {
      const response = await dispatch(getAllCompanyList());
    })();
  }, []);

  useEffect(() => {
    (async function () {
      await dispatch(getRegionList());
      await dispatch(getForumType());
      await dispatch(getForumStatus());
    })();
  }, []);

  return (
    <main>
      {/* <PindCategories /> */}
      <Container fluid className="margin-b-15 padding-t-10">
        <Breadcrumbs
          activePageName={isAddMode ? "Create" : "Edit"}
          parentPageurl="/quickViewBreakFix"
          parentPageName="Action Items"
        />
      </Container>

      <Container fluid>
        <Row>
          <Col xs={12}>
            <FormComponent />
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default AddEditActionItem;
