
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';
export const breakFixSGAEName = (state = initialState.breakFixSGAEName, action) =>{
	switch (action.type){
		case 'LOAD_BREAKFIXSGAE_SUCCESS':
			return action.breakFixSGAEName;
			case 'LOAD_BREAKFIXSGAE_FAILURE':
					return action.failureMessage;
			default:
			return state;
	}
}
export const breakFixSGAENameCIM = (state = initialState.breakFixSGAEName, action) =>{
	switch (action.type){
		case 'LOAD_BREAKFIXSGAE_SUCCESS_CIM':
			return action.breakFixSGAEName;
			case 'LOAD_BREAKFIXSGAE_FAILURE':
					return action.failureMessage;
			default:
			return state;
	}
}
export const allCIMgroups = (state=[], action) =>{
	switch(action.type){
		case 'LOAD_ALL_CIM_GROUPS':
			  return action.cimGroups;
		default:
			  return state;
	}
}

export const allCIMgroupsRules = (state=[], action) =>{
	switch(action.type){
		case 'LOAD_ALL_CIM_GROUPS_RULES':
			  return action.cimGroupsRules;
		default:
			  return state;
	}
}

export const breakFixSGAENameType = (state=[],action)=>{
	switch(action.type){
		case 'GROUP_LIST_TYPE_AHEAD_INITIAL':
			return action.typeaheadData;
		default:
			return state;	 
	}
}
export const breakFixSGAEChangegroup = (state = initialState.breakFixSGAEChangegroup, action) =>{
	switch (action.type){
		case 'LOAD_BREAKFIXSGAEGROUPCHANGE_SUCCESS':
			return action.breakFixSGAEChangegroup;
			case 'LOAD_BREAKFIXSGAE_FAILURE':
					return action.failureMessage;
			default:
			return state;
	}
}
export const changeManagementGroupIndividual = (state=[],action) => {
	switch(action.type) {
		case 'CHANGEMANAGEMENT_INDIVIDUAL_LOAD_SUCCESS':
			return action.breakFixSGIName.data;
		default:
			return state;			
	}
};

export const changeManagementGroupIndividualTypeahead = (state=[],action) => {
	switch(action.type) {
		case 'CHANGEMANAGEMENT_INDIVIDUAL_LOAD_SUCCESS_TYPE':
			return action.typeaheadData;
		case 'RESET_BREAKFIXSGI':
			return [];
		default:
			return state;			
	}
};
export const changeManagementGroupImplIndividual = (state=[],action) => {
	switch(action.type) {
		case 'CHANGEMANAGEMENT_IMPL_INDIVIDUAL_LOAD_SUCCESS':
			return action.breakFixSGIName.data;
		default:
			return state;
	}
};
export const changeManagementGroupImplIndividualTypeahead = (state=[],action) =>{
	switch(action.type){
		case 'CHANGEMANAGEMENT_IMPL_INDIVIDUAL_LOAD_SUCCESS_TYPE':
			return action.typeaheadData;
			case 'RESET_BREAKFIXSGI':
				return [];
		default:
			return state;	
	}
}
export const breakFixSGAEEditName = (state = initialState.breakFixSGAEEditName, action) =>{
	switch (action.type){
		case 'LOAD_BREAKFIXSGAEEDIT_SUCCESS':
			return action.breakFixSGAEEditName.data;
			case 'LOAD_BREAKFIXSGAEEDIT_FAILURE':
					return action.failureMessage;
			default:
			return state;
	}
}

export const breakFixSGAETaskName = (state = initialState.breakFixSGAETaskName, action) =>{
	switch (action.type){
		case 'LOAD_BREAKFIXSGAETASK_SUCCESS':
			return action.breakFixSGAETaskName.data;
			case 'LOAD_BREAKFIXSGAETASK_FAILURE':
					return action.failureMessage;
			default:
			return state;
	}
}

export const breakFixDetailsLoadSatatus = (state ='loading',action)=>{
	switch(action.type) {
		case 'BREAKFIX_EDIT_DETAILS_LOAD_STATUS':
			return action.status;
		default:
		return state;
	}
}

export const breakfixD2cNumber = (state ='',action)=>{
	switch(action.type) {
		case 'D2C_NUMBER_LOADED':
			return action.d2cNumber;
		default:
		return state;
	}
}
