
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useRef, useState } from "react";
import { Table, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DateRangePicker from "react-bootstrap-daterangepicker";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { IoSearch } from "react-icons/io5";
import ReactDOM from "react-dom";
import Pagination from "react-js-pagination";

import { loadCmdbItemList, loadCmdbItemListForMyView } from "../../../actions/cmdb/quickViewAction";
import ListLoader from "../../common/loaders/ListLoader";

import Cookies from "universal-cookie";
import { usePreviousValue } from "../../../helpers/usePreviousValue";
const cookies = new Cookies();

let cookiesData = cookies.get("gph");
if (cookiesData) cookiesData = cookiesData.replace("s:", "");
if (cookiesData)
  cookiesData = cookiesData.substring(0, cookiesData.lastIndexOf("."));
cookiesData = cookiesData.split("~");
let loggedInUserId = Number(cookiesData[43]);
let dateformat = cookies.get('gph');
if (dateformat) dateformat = dateformat.replace('s:', '');
if (dateformat) dateformat = dateformat.substring(0, dateformat.lastIndexOf('.'));
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();
jwtdf = jwtdf.replace(/-/g, '/');

const MyAssetList = (props) => {
  const [statusOptions, setStatusOptions] = useState([]);
  const [classOptions, setClassOptions] = useState([]);
  const [isMultiSelectOpen, setIsMultiSelectOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const myAssetCIList = useSelector((state) => state.cmdbQuickItemList);
  const ciClassList = useSelector((state) => state.ciClass);
  const statusList = useSelector((state) => state.statusList);
  const loader = useSelector((state) => state.showLoader.loading);
  const previousValue = usePreviousValue(props);

  const multiSelectClassDropdownRef = useRef(null);
  const multiSelectTypeDropdownRef = useRef(null);
  const multiSelectStatusDropdownRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", clickOutside);
    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, []);

  useEffect(() => {
    let statusmap = new Map(Object.entries(statusList));
    let statusOptionsArr = [];
    for (let [key, value] of statusmap) {
      let obj = {};
      obj.label = value.STATUS_NAME;
      obj.value = value.ID;
      statusOptionsArr.push(obj);
    }
    setStatusOptions(statusOptionsArr);

    let classmap = new Map(Object.entries(ciClassList));
    let classOptionsArr = [];
    for (let [key, value] of classmap) {
      let obj = {};
      obj.label = value.CLASS_NAME;
      obj.value = value.CLASS_ID;
      classOptionsArr.push(obj);
    }
    setClassOptions(classOptionsArr);
  }, [ciClassList, statusList]);
  useEffect(() => {
    if (
      multiSelectStatusDropdownRef != undefined ||
      multiSelectStatusDropdownRef != null
    ) {
      multiSelectStatusDropdownRef.current.childNodes[0].className =
        "multiSelectRef";
      let myRef = multiSelectStatusDropdownRef;
      if (props.isStatusOpen != previousValue?.isStatusOpen) {
        let obj = ReactDOM.findDOMNode(myRef.current).childNodes[0]
          .childNodes[1];
        if (obj != undefined || obj != null) {
          ReactDOM.findDOMNode(
            myRef.current
          ).childNodes[0].childNodes[1].className = "multiSelectPopup";
        }
      }
    }

    if (
      multiSelectClassDropdownRef != undefined ||
      multiSelectClassDropdownRef != null
    ) {
      multiSelectClassDropdownRef.current.childNodes[0].className =
        "multiSelectRef";
      let myRef = multiSelectClassDropdownRef;
      if (props.isClassOpen != previousValue?.isClassOpen) {
        let obj = ReactDOM.findDOMNode(myRef.current).childNodes[0]
          .childNodes[1];
        if (obj != undefined || obj != null) {
          ReactDOM.findDOMNode(
            myRef.current
          ).childNodes[0].childNodes[1].className = "multiSelectPopup";
        }
      }
    }
    if (
      multiSelectTypeDropdownRef != undefined ||
      multiSelectTypeDropdownRef != null
    ) {
      multiSelectTypeDropdownRef.current.childNodes[0].className =
        "multiSelectRef";
      let myRef = multiSelectTypeDropdownRef;
      if (props.isTypeOpen != previousValue?.isTypeOpen) {
        let obj = ReactDOM.findDOMNode(myRef.current).childNodes[0]
          .childNodes[1];
        if (obj != undefined || obj != null) {
          ReactDOM.findDOMNode(
            myRef.current
          ).childNodes[0].childNodes[1].className = "multiSelectPopup";
        }
      }
    }
  }, [props.isStatusOpen,props.isClassOpen,props.isTypeOpen]);

  const clickOutside = (event) => {
    const statusDiv = document.getElementById("statusDiv");
    const classDiv = document.getElementById("classDiv");
    const typeDiv = document.getElementById("typeDiv");

    if (
      multiSelectStatusDropdownRef.current != undefined ||
      multiSelectStatusDropdownRef.current != null ||
      multiSelectTypeDropdownRef.current != undefined ||
      multiSelectTypeDropdownRef.current != null ||
      multiSelectClassDropdownRef.current != undefined ||
      multiSelectClassDropdownRef.current != null
    ) {
      document.addEventListener("mousedown", (event) => {
        if (classDiv.contains(event.target)) {
          setIsMultiSelectOpen(true);
          props.showRightSideFun(false);
        } else if (statusDiv.contains(event.target)) {
          props.showRightSideFun(false);
          setIsMultiSelectOpen(true);
        } else if (typeDiv.contains(event.target)) {
          setIsMultiSelectOpen(true);
          props.showRightSideFun(false);
        }        
        else {
          setIsMultiSelectOpen(false);
        }
        props.multiSelectOpenFun("allFalse", false);
      });
    }
  };

  const handleChange = (e, searchValue) => {
    props.showRightSideFun(false);
    switch (searchValue) {
      case "CI_ID":
        props.setAssetIdSearchInput(e.target.value);
        break;
      case "CI_NAME":
        props.setNameSearchInput(e.target.value);
        break;
      case "COMPANY_NAME":
        props.setCompanySearchInput(e.target.value);
        break;
      case "CLASS_NAME":
        props.setClassSearchInput(e.target.value);
        break;
    }
  };

  const handleSearch = (e, picker) => {
    let searchParamsobj = {};
    let dateArr = [];

    props.showRightSideFun(false);
    props.setIsClearAllFilter(true);
    if (props.assetIdSearchInput != "") {
      searchParamsobj.ci_code = props.assetIdSearchInput;
    }
    if (props.nameSearchInput != "") {
      searchParamsobj.ci_name = props.nameSearchInput;
    }
    if (props.companySearchInput != "") {
      searchParamsobj.company_name = props.companySearchInput;
    }
    if (props.filterTabActive === 1) {
      searchParamsobj.owner_id = loggedInUserId;
    } else if(props.filterTabActive === 2) {
      searchParamsobj.technical_owner_id = loggedInUserId;
    } else if (props.filterTabActive === 3) {
      searchParamsobj.business_owner_id = loggedInUserId;
    }

    if (picker) {
      props.setRef(picker);
      dateArr.push(
        moment(picker.startDate).format("MM-DD-YYYY"),
        moment(picker.endDate).format("MM-DD-YYYY")
      );
      props.setStartDate(picker.startDate.format("MM/DD/YYYY"));
      props.setEndDate(picker.endDate.format("MM/DD/YYYY"));
      searchParamsobj.updated_at = dateArr.join("&");
    }

    if (
      picker == undefined &&
      !_.isEmpty(props.startDate) &&
      !_.isEmpty(props.endDate)
    ) {
      dateArr.push(
        moment(props.startDate).format("MM-DD-YYYY"),
        moment(props.endDate).format("MM-DD-YYYY")
      );
      searchParamsobj.updated_at = dateArr.join("&");
    }
    dispatch(
      loadCmdbItemList(
        props.page,
        props.limit,
        searchParamsobj,
        props.type,
        props.status,
        props.searchClass
      )
    );
    props.setSearchParams(searchParamsobj);
  };

  const handleTypeChange = (value) => {
    let searchOnTypeArr = [];
    props.setSelectedTypeValue(value);

    Object.keys(props.searchParams).length === 1 &&
    (props.filterTabActive === 1
      ? props.searchParams.owner_id === loggedInUserId
      : props.filterTabActive === 2
      ? props.searchParams.technical_owner_id === loggedInUserId
      : props.filterTabActive === 3
      ? props.searchParams.business_owner_id === loggedInUserId
      : false)
      ? props.status.length == 0 && props.searchClass.length == 0
        ? value.length == 0
          ? props.setIsClearAllFilter(false)
          : props.setIsClearAllFilter(true)
        : props.setIsClearAllFilter(true)
      : props.setIsClearAllFilter(true);
    props.showRightSideFun(false);

    value.map((checkBoxValue) => {
      return (
        searchOnTypeArr.push(checkBoxValue.value)
      );
    }
    );
    props.setStatus(props.status);
    props.setSearchClass(props.searchClass);
    props.setType(searchOnTypeArr);
    dispatch(loadCmdbItemList(props.page, props.limit, props.searchParams, searchOnTypeArr, props.status, props.searchClass));
  }

  const handleStatusChange = (value) => {
    let searchOnStatusArr = [];
    props.setSelectedStatusValue(value);

    Object.keys(props.searchParams).length === 1 &&
    (props.filterTabActive === 1
      ? props.searchParams.owner_id === loggedInUserId
      : props.filterTabActive === 2
      ? props.searchParams.technical_owner_id === loggedInUserId
      : props.filterTabActive === 3
      ? props.searchParams.business_owner_id === loggedInUserId
      : false)
      ? props.type.length == 0 && props.searchClass.length == 0 ?
        value.length == 0 ?
          props.setIsClearAllFilter(false) :
          props.setIsClearAllFilter(true) :
        props.setIsClearAllFilter(true) :
      props.setIsClearAllFilter(true);

    props.showRightSideFun(false);
    value.forEach((checkBoxValue) => {
      searchOnStatusArr.push(checkBoxValue.value)
    });
    props.setStatus(searchOnStatusArr);
    props.setSearchClass(props.searchClass);
    props.setType(props.type);
    dispatch(loadCmdbItemList(props.page, props.limit, props.searchParams, props.type, searchOnStatusArr, props.searchClass));
  }

  const handleClassChange = (value) => {
    let searchOnClassArr = [];
    props.setSelectedClassValue(value);

    Object.keys(props.searchParams).length === 1 &&
    (props.filterTabActive === 1
      ? props.searchParams.owner_id === loggedInUserId
      : props.filterTabActive === 2
      ? props.searchParams.technical_owner_id === loggedInUserId
      : props.filterTabActive === 3
      ? props.searchParams.business_owner_id === loggedInUserId
      : false)
      ? props.type.length == 0 && props.status.length == 0 ?
        value.length == 0 ?
          props.setIsClearAllFilter(false) :
          props.setIsClearAllFilter(true) :
        props.setIsClearAllFilter(true) :
      props.setIsClearAllFilter(true);

    props.showRightSideFun(false);
    value.map((checkBoxValue) => {
      return (
        searchOnClassArr.push(checkBoxValue.value)
      );
    }
    );
    props.setStatus(props.status);
    props.setSearchClass(searchOnClassArr);
    props.setType(props.type);
    dispatch(loadCmdbItemList(props.page, props.limit, props.searchParams, props.type, props.status, searchOnClassArr));
  }


  const onPageChange = (e) => {
    props.showRightSideFun(false);
    props.setPage(e);
    dispatch(
      loadCmdbItemList(
        e,
        props.limit,
        props.searchParams,
        props.type,
        props.status,
        props.searchClass
      )
    );
  };

  const onPageSizeChange = (e) => {
    props.showRightSideFun(false);
    props.setLimit(e.target.value);
    dispatch(
      loadCmdbItemList(
        props.page,
        e.target.value,
        props.searchParams,
        props.type,
        props.status,
        props.searchClass
      )
    );
  };

  const onRowSelect = (e, row, index) => {
    props.getCMDBDetails(
      row.CI_ID,
      row.CI_NAME,
      row.GROUP_NAME,
      row.OWNER_NAME,
      row.LOCATION_NAME,
      row.ENVIRONMENT_VALUE,
      row.DATASET,
      row.MANUFACTURER_NAME,
      row.CI_CODE,
      row.BUSINESS_OWNER_NAME,
      row.TECHNICAL_OWNER_NAME,
      row.COMPANY_ID,
      row.CLASS_NAME,
      row.CLASS_ID,
      row.COMPANY_NAME,
      row.TYPE,
      row.STATUS_NAME,
      row.SUB_STATUS_NAME,
      row.UPDATED_AT,
      row.ASSET_TAG,
      row.CATEGORY_NAME,
      row.METALLIC_VALUE,
      row.SUB_CATEGORY_NAME
    );
    props.showRightSideFun(true);
    props.setRowIndex(index);
  };

  const tableData = () => {
    if (
      typeof myAssetCIList == "undefined" ||
      myAssetCIList.length === 0 ||
      loader
    ) {
      return (
        <tr>
          <td colSpan={7} style={{ textAlign: "center" }}>
            {<ListLoader />}
          </td>
        </tr>
      );
    } else if (myAssetCIList == 'No record found' ) {
      return (
        <tr>
          <td colSpan={7} style={{ textAlign: "center" }}>
            {tr["There is no matching data available"]}
          </td>
        </tr>
      );
    } else {
      return myAssetCIList.result.map((item, index) => (
        <tr
          className={
            props.isRightSideVisible && index == props.rowIndex
              ? "myActive"
              : ""
          }
          onClick={(e) => onRowSelect(e, item, index)}
          key={index}
        >
          <td>{item.CI_CODE}</td>
          <td title={item.CI_NAME}>
            {item.CI_NAME.slice(0, 70)}
            {item.CI_NAME.length > 70 ? "..." : ""}
          </td>
          <td title={item.COMPANY_NAME}>
            {item.COMPANY_NAME.slice(0, 70)}
            {item.COMPANY_NAME.length > 70 ? "..." : ""}
          </td>
          <td>{item.CLASS_NAME}</td>
          <td>
            {item.TYPE == "5" ? "Asset" : item.TYPE == "10" ? "CI" : "Both"}
          </td>
          <td>{item.STATUS_NAME}</td>
          <td>{item.UPDATED_AT}</td>
        </tr>
      ));
    }
  };

  const {
    assetIdSearchInput,
    companySearchInput,
    nameSearchInput,
    selectedClassValue,
    selectedStatusValue,
    selectedTypeValue,
  } = props;

  let panelColSize = props.panelResize;
  let meta = !_.isEmpty(myAssetCIList) ? myAssetCIList.meta : {};
  let typeOptions = [
    { label: "Asset", value: "5" },
    { label: "CI", value: "10" },
    { label: "Both", value: "15" },
  ];
  let myStartDate = !_.isEmpty(props.startDate)
    ? moment(props.startDate).format(jwtdf)
    : "";
  let myEndDate = !_.isEmpty(props.endDate)
    ? moment(props.endDate).format(jwtdf)
    : "";
  let myNewDate =
    myStartDate == "" && myEndDate == "" ? "" : myStartDate + "-" + myEndDate;
  let caseResponsive =
  loader || myAssetCIList == [] || myAssetCIList == 'No record found' || myAssetCIList.result?.length <= 6 ? "overFlowClp" : "table-responsive";

  return (
    <>
      <div aria-label="Table" role="contentinfo" className={loader || myAssetCIList == [] || myAssetCIList == 'No record found' || myAssetCIList.result?.length <= 6 ? "respondv responDvMinH" : "respondv"}>
        <div className={"tableRgtBor " + caseResponsive}>
          <Table
            striped
            bordered
            condensed
            hover
            className="tableView nowrapWhright"
            style={{ marginTop: "0px" }}
          >
            <thead>
              <tr>
                <th>
                  <div className="sortParArr">{tr["Asset/CI ID"]}</div>
                  <div className="colSearDv">
                    <Form.Control
                      className="colSearInp"
                      type="text"
                      value={assetIdSearchInput != "" ? assetIdSearchInput : ""}
                      placeholder={tr["Search here"]}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          if (
                            assetIdSearchInput != "" &&
                            assetIdSearchInput.trim().length > 3
                          )
                            handleSearch();
                        }
                      }}
                      onChange={(event) => {
                        handleChange(event, "CI_ID");
                      }}
                      onClick={() => props.multiSelectOpenFun("allFalse", false)}
                    />
                    {assetIdSearchInput.trim().length > 3 ? (
                      <a
                        title="search"
                        className="faicn"
                        href={void 0}
                        onClick={() => handleSearch()}
                      >
                        <IoSearch />
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </th>
                <th>
                  <div className="sortParArr">{tr["Name"]}</div>
                  <div className="colSearDv">
                    <Form.Control
                      className="colSearInp"
                      type="text"
                      value={nameSearchInput != "" ? nameSearchInput : ""}
                      placeholder={tr["Search here"]}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          if (
                            nameSearchInput != "" &&
                            nameSearchInput.trim().length > 3
                          )
                            handleSearch();
                        }
                      }}
                      onChange={(event) => {
                        handleChange(event, "CI_NAME");
                      }}
                      onClick={() => props.multiSelectOpenFun("allFalse", false)}
                    />
                    {nameSearchInput.trim().length > 3 ? (
                      <a
                        title="search"
                        className="faicn"
                        href={void 0}
                        onClick={() => handleSearch()}
                      >
                        <IoSearch />
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </th>
                <th>
                  <div className="sortParArr">{tr["Company"]}</div>
                  <div className="colSearDv">
                    <Form.Control
                      className="colSearInp"
                      type="text"
                      value={companySearchInput != "" ? companySearchInput : ""}
                      placeholder={tr["Search here"]}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          if (
                            companySearchInput != "" &&
                            companySearchInput.trim().length >= 3
                          )
                            handleSearch();
                        }
                      }}
                      onChange={(event) => {
                        handleChange(event, "COMPANY_NAME");
                      }}
                      onClick={() => props.multiSelectOpenFun("allFalse", false)}
                    />

                    {companySearchInput.trim().length >= 3 ? (
                      <a
                        title="search"
                        className="faicn"
                        href={void 0}
                        onClick={() => handleSearch()}
                      >
                        <IoSearch />
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </th>
                <th>
                  <div className="sortParArr">{tr["Class"]}</div>
                  <div
                    id="classDiv"
                    className="colSearDv"
                    ref={multiSelectClassDropdownRef}
                    onClick={() => {props.multiSelectOpenFun("myAssetClass", true)}}
                  >
                    <ReactMultiSelectCheckboxes
                      placeholderButtonLabel={tr["Select"]}
                      rightAligned={true}
                      options={classOptions}
                      value={selectedClassValue}
                      onChange={handleClassChange}
                    />
                  </div>
                </th>

                <th>
                  <div className="sortParArr">{tr["Type"]}</div>
                  <div
                    id="typeDiv"
                    className="colSearDv"
                    ref={multiSelectTypeDropdownRef}
                    onClick={() => {props.multiSelectOpenFun("myAssetType", true)}}
                  >
                    <ReactMultiSelectCheckboxes
                      placeholderButtonLabel={tr["Select"]}
                      rightAligned={true}
                      options={typeOptions}
                      value={selectedTypeValue}
                      onChange={handleTypeChange}
                    />
                  </div>
                </th>

                <th>
                  <div className="sortParArr">{tr["Status"]}</div>
                  <div
                    id="statusDiv"
                    className="colSearDv"
                    ref={multiSelectStatusDropdownRef}
                    onClick={() => {props.multiSelectOpenFun("myAssetStatus", true)}}
                  >
                    <ReactMultiSelectCheckboxes
                      placeholderButtonLabel={tr["Select"]}
                      rightAligned={true}
                      options={statusOptions}
                      value={selectedStatusValue}
                      onChange={handleStatusChange}
                    />
                  </div>
                </th>
                <th>
                  <div className="sortParArr">{tr["Modified On"]}</div>
                  <div className="colSearDv">
                    <DateRangePicker
                      initialSettings={{
                        autoUpdateInput: false,
                        maxDate: moment(),
                        autoApply: true,
                      }}
                      onApply={(e, picker) => handleSearch(e, picker)}
                      // onCallback={handleCallback}
                    >
                      <input
                        type="text"
                        placeholder={tr["Search here"]}
                        readOnly
                        className="form-control"
                        // onPaste={handleKeyPress}
                        // onKeyDown={handleKeyPress}
                        value={myNewDate}
                      />
                    </DateRangePicker>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>{tableData()}</tbody>
          </Table>
          </div>
            <div className="nBotPagina">
              <div className="nShow">
                <div className="margin-r-10" componentClass={Form.Label}>
                  {tr["Show"]}:
                </div>
                <Form.Select value={props.limit} onChange={onPageSizeChange}>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  {/* <option value="100">100</option> */}
                </Form.Select>
              </div>
              <nav aria-label="Pagination" className="display-inline-block">
                <Pagination
                  prevPageText={tr["Prev"]}
                  nextPageText={tr["Next"]}
                  firstPageText={<i className="glyphicon glyphicon-menu-left" />}
                  lastPageText={<i className="glyphicon glyphicon-menu-right" />}
                  activePage={meta ? meta.currentPage : 1}
                  itemsCountPerPage={props.limit}
                  totalItemsCount={meta ? meta.rowCount : 1}
                  pageRangeDisplayed={panelColSize <= 42 ? 2 : 5}
                  onChange={(e) => onPageChange(e)}
                />
              </nav>
            </div>
      </div>
    </>
  );
};

export default MyAssetList;
