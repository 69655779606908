
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import XsmQuickViewDetailtHeader from "./IntegrationHubListHeader.js";
import IntegrationViewList from "./IntegrationHubListView.js";
import { getCompanyList } from "../../actions/spcmActions.js";
import {
  loadIntegrationHubList,
  loadIntegrationScenarioList,
} from "../../actions/integrationHub/integrationHubAction.js";
import IntegrationHubShortDexcription from "./IntegrationHubShortDexcription.js";
import Breadcrumbs from "../common/header/breadcrumbs.js";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import "_Css/common/_table.scss";

const IntegrationHubIndex = () => {
  const dispatch = useDispatch();

  const translator = useSelector((state) => state.spcmReducer.tr);

  const [id, setId] = useState("");
  const [scenarioName, setScenarioName] = useState("");
  const [endPoint, setEndPoint] = useState("");
  const [userName, setUserName] = useState("");
  const [criteria, setCriteria] = useState("");
  const [requestMethod, setRequestMethod] = useState("");
  const [tenantName, setTenantName] = useState("");
  const [rightDisplay, setRightDisplay] = useState(false);

  const showRightSide = (val) => {
    setRightDisplay(val);
  };

  const setShortDescValues = (
    id,
    scenarioName,
    criteria,
    endPoint,
    userName,
    tenantName,
    requestMethod
  ) => {
    setId(id);
    setScenarioName(scenarioName);
    setEndPoint(endPoint);
    setUserName(userName);
    setCriteria(criteria);
    setRequestMethod(requestMethod);
    setTenantName(tenantName);
  };

  useEffect(() => {
    dispatch(loadIntegrationHubList());
    dispatch(getCompanyList(""));
    dispatch(loadIntegrationScenarioList(""));
  }, []);

  return (
    <div>
      <div>
        <div className="container-fluid padding-t-10 margin-b-15">
          <Breadcrumbs activePageName={"Integration Hub"} />
        </div>
        <div className="container-fluid" bsClass="">
          <XsmQuickViewDetailtHeader translator={translator} />
          <PanelGroup direction="horizontal" className="panelOverflow">
            <Panel id="sidebar" minSize={36} order={1} defaultSize={rightDisplay ? 67 : 100} className={rightDisplay ? "catBordDv isShowLeftPanel rwLeftPart" : "catBordDv rwLeftPart"}>
              <IntegrationViewList
                rightDisplay={rightDisplay}
                setShortDescValues={setShortDescValues}
                translator={translator}
                showRightSide={showRightSide}
              />
            </Panel>
            {rightDisplay ?
                <>
                  <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
                  <Panel minSize={33} order={2} defaultSize={33}>
                    <div className="stickyArea rBoxStyle">
                      <IntegrationHubShortDexcription
                        id={id}
                        tenantName={tenantName}
                        scenarioName={scenarioName}
                        requestMethod={requestMethod}
                        userName={userName}
                        criteria={criteria}
                        endPoint={endPoint}
                        showRightSide={showRightSide}
                      />
                    </div>
                  </Panel>
                </> : ""
            }
          </PanelGroup>
        </div>
      </div>
    </div>
  );
};

export default IntegrationHubIndex;
