
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { PieChart } from "react-minimal-pie-chart";
import { useSelector } from "react-redux";

const PieChartComponent = ({ taskCount }) => {
  const tr = useSelector((state) => state.spcmReducer.tr);

  return (
    <div
      className="display-inline-block margin-l-5"
      title={`${taskCount.meta.completed} ${tr['out of']} ${taskCount.meta.totalCount} ${tr['tasks are completed.']}`}
    >
      <PieChart
        data={taskCount.data}
        style={{ height: "20px", width: "20px" }}
      />
    </div>
  );
};

export default PieChartComponent;
