
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import CategoryBoardHeader from "./BusinessFunctionListHeader";
import {
  Row,
  Col,
} from "react-bootstrap";
import { loadBusinessFunctionList } from "../../../actions/businessFunction/businessFunctionAction";
import { bindActionCreators } from "redux";
import Cookies from "universal-cookie";
import { navigationHooks } from "../../../helpers/NavigationHook";
import CategoryBoardList from "./BusinessFunctionList";
import BusinessFunctionShortDescription from "./BusinessFunctionShortDescription";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import "_Css/common/_dataTable.scss";

const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home") homepagelocation = "/" + homepagelocation;

let user_roleId = cookies.get("gph");
if (user_roleId) user_roleId = user_roleId.replace("s:", "");
if (user_roleId)
  user_roleId = user_roleId.substring(0, user_roleId.lastIndexOf("."));
user_roleId = user_roleId.split("~");
user_roleId = user_roleId[33];

const BusinessFunctionListIndex = () => {

  const slaDefList = useSelector(state => state.slaDefList);
  const translator = useSelector(state => state.spcmReducer.tr);
  
  const dispatch = useDispatch();
  const actionCreators = bindActionCreators({ loadBusinessFunctionList }, dispatch);

  const [categoryId, setCategoryId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [businessFunctionDesc, setBusinessFunctionDesc] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");
  const [status, setStatus] = useState("");
  const [categoryCode, setCategoryCode] = useState("");
  const [catPinned, setCatPinned] = useState("");
  const [rightDisplay, setRightDisplay] = useState(false);
  const [activeTab, setActiveTab] = useState('service');
  const [loader, setLoader] = useState(false);


  const showRightSide = (val) => {
    setRightDisplay(val);
  };

  const loadHome = () => {
    navigationHooks.navigate(homepagelocation);
  };

  const handleTab = (clickedItem) => {
    setActiveTab(clickedItem);
  };

  const getCategoryBoardDetails = (
    id,
    businessFunctionName,
    parentFunction,
    companyName,
    statuss,
    businessFunctionDesc
  ) => {
    console.log("businessFunctionDesc=====>",businessFunctionName)
    setCategoryId(id);
    setCategoryName(businessFunctionName);
    setSubCategoryName(parentFunction);
    setCategoryCode(companyName);
    setStatus(statuss);
    setBusinessFunctionDesc(businessFunctionDesc);
  };

  useEffect(() => {
    dispatch(loadBusinessFunctionList());
  }, [dispatch]);

  const isServiceTabActive = activeTab === 'service';

  return (
    <div>
      <div className="minHeightQA">
        <div className="container-fluid margin-t-10 margin-b-15">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a
                role="button"
                href="javascript:void(0)"
                onClick={loadHome}
              >
                {translator["Home"]}
              </a>
            </li>
            <li className="breadcrumb-item active">
              {translator["Business Function"]}
            </li>
          </ul>
        </div>
        <div className="container-fluid">
          <CategoryBoardHeader
            translator={translator}
            activeTab={activeTab}
            handleTab={handleTab}
            roleArray={user_roleId}
          />
        </div>
        {!loader && (
          <div className="container-fluid" bsClass="">
            <PanelGroup direction="horizontal" className="panelOverflow">
            <Panel id="sidebar" minSize={33} order={1} defaultSize={rightDisplay ? 67 : 100} className={rightDisplay ? "catBordDv isShowLeftPanel rwLeftPart" : "catBordDv rwLeftPart"}>
               <CategoryBoardList
                  rightDisplay={rightDisplay}
                  getCategoryBoardDetails={getCategoryBoardDetails}
                  translator={translator}
                  showRightSide={showRightSide}
                />
            </Panel>
            {rightDisplay ?
                <>
                  <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
                  <Panel minSize={33} order={2} defaultSize={33}>
                    <div className="stickyArea rBoxStyle">
                      <BusinessFunctionShortDescription
                        categoryId={categoryId}
                        categoryName={categoryName}
                        subCategoryName={subCategoryName}
                        status={status}
                        businessFunctionDesc={businessFunctionDesc}
                        categoryCode={categoryCode}
                        translator={translator}
                        catPinned={catPinned}
                        showRightSide={showRightSide}
                      />
                    </div>
                  </Panel>
                </> : ""
            }
          </PanelGroup>
          </div>
        )}
      </div>
    </div>
  );
};



export default BusinessFunctionListIndex;
