
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Form, Table } from 'react-bootstrap';
import { connect, useDispatch, useSelector } from 'react-redux';
import ListLoader from "../../../common/loaders/ListLoader.js"
import Pagination from "react-js-pagination";
import { IoSearch } from 'react-icons/io5';
import { reduxForm } from "redux-form";
import { getShiftListData, resetShiftListData } from "../../../../actions/onCallSchedule/shift/onCallShiftAction.js";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import Swal from "sweetalert2";


const ShiftListView = (props) => {
  const dispatch = useDispatch();
  const translator = useSelector((state) => state.spcmReducer.tr);
  const shiftListData = useSelector((state) => state.shiftListData);
  const showScheduleListLoader = useSelector((state) => state.on_call_shift_loader_reducer.showShiftListLoader);
  const allShiftListMeta = useSelector((state) => state.on_call_shift_loader_reducer.allShiftListMeta);
  const [rowIndex, setRowIndex] = useState(0);
  const [pageSize, setPageSize] = useState('10');
  const [pageNumber, setPageNumber] = useState('0');

  useEffect(() => {
    dispatch(resetShiftListData())
  }, [])

  function onRowSelect(e, row, index) {
    props.setSelectedItemFun({
      createdByFullname: row.createdByFullname,
      createdOn: row.createdOn,
      modifiedOn: row.modifiedOn,
      shiftName: row.shiftName,
      shiftId: row.shiftId,
      modifiedByFullname: row.modifiedByFullname
    });
    console.log("INDEX==>", index)
    props.showRightSideFun(true);
    setRowIndex(index);
  }


  const prepareFilters = () => {
    let filter = {};
    if (props.selectedBusinessFn && props.selectedBusinessFn.length > 0) {
      filter["groupId"] = props.selectedBusinessFn[0].groupId;
    } else {
      Swal.fire({ title: "Please select group first" });
      return false;
    }
    if (props.shiftSearchInput) {
      filter["shiftName"] = props.shiftSearchInput;
    }
    if (props.scheduleSearchInput) {
      filter["scheduleName"] = props.scheduleSearchInput;
    }
    if (props.modifiedBySearchInput) {
      filter["modifiedByFullname"] = props.modifiedBySearchInput;
    }
    if (props.modifiedonSearchInput) {
      filter["modifiedOn"] = props.modifiedonSearchInput.replaceAll("/", "-");
    }
    return filter;
  };

  const handleCallback = () => { };

  const handleKeyPress = (event) => { };

  const handleSearch = (e, picker = false) => {
    let filter = prepareFilters();
    filter["page"] = 0;
    filter["size"] = 10;
    props.setActivePage(0);
    props.setItemsPerPage(10);
    if (e && e.target && picker) {
      let date = [];
      date.push(
        moment(picker.startDate).format("MM-DD-YYYY"),
        moment(picker.endDate).format("MM-DD-YYYY")
      );
      if (e.target.name === "modifiedOn") {
        props.setRef("modifiedOn",picker);
        props.setModifiedonSearchInput(
          picker.startDate.format("MM/DD/YYYY") +
          "," +
          picker.endDate.format("MM/DD/YYYY")
        );
        filter["modifiedOn"] = date.join(",");
      }
    }
    if (Object.keys(filter).length > 0) {
      props.setIsFilterApplied(true);
      props.setFilters(filter);
    }
    dispatch(getShiftListData(filter));
  };

  const handleChange = (e, searchValue) => {
    switch (searchValue) {
      case "shift":
        props.setShiftSearchInput(e.target.value);
        break;
      case "schedule":
        props.setScheduleSearchInput(e.target.value);
        break;
      case "modifiedBy":
        props.setModifiedBySearchInput(e.target.value);
        break;
    }
  }

  const onPageSizeChange = (e) => {
    let filter = prepareFilters();
    filter["page"] = 0;
    filter["size"] = e.target.value;
    dispatch(getShiftListData(filter));
    props.setActivePage(0);
    props.setItemsPerPage(e.target.value);
  };

  const onPageChange = (e) => {
    let filter = prepareFilters();
    filter["page"] = e - 1;
    filter["size"] = props.itemsPerPage;
    dispatch(getShiftListData(filter));
    props.setActivePage(parseInt(filter["page"], 10));
  };


  function renderScheduleList(shiftListData) {
    return shiftListData.map((data, index) => {
      console.log("rowIndex", rowIndex)
      console.log("props.rightDisplay", props.rightDisplay)
      console.log("index", index)
      return (
        <tr key={index} className={props.rightDisplay && index == rowIndex ? "myActive" : ""} onClick={(e) => onRowSelect(e, data, index)}>
          <td>{data.shiftName}</td>
          <td>{data.scheduleName ? data.scheduleName : ""}</td>
          <td>{data.modifiedOn}</td>
          <td>{data.modifiedByFullname}</td>
        </tr>
      );
    });
  }

  return (
    <div>
      <div className="respondv">
        <div className={"tableRgtBor "}>
          <Table
            striped
            bordered
            condensed
            hover
            className="tableView nowrapWhright"
            style={{ marginTop: "0px" }}
          >
            <thead>
              <tr>
                <th>
                  <div className="sortParArr">{translator["Shift"]}</div>
                  <div className="colSearDv">
                    <Form.Control
                      className="colSearInp"
                      type="text"
                      value={
                        props.shiftSearchInput != ""
                          ? props.shiftSearchInput
                          : ""
                      }
                      placeholder={translator["Search here"]}
                      onKeyPress={(e) => {
                        if (e.charCode == "13") {
                          if (
                            props.shiftSearchInput != "" &&
                            props.shiftSearchInput?.trim().length > 3
                          )
                            handleSearch();
                        }
                      }}
                      onChange={(event) => {
                        handleChange(event, "shift");
                      }}
                    />
                    {props.shiftSearchInput?.trim().length > 3 ? (
                      <a
                        title="search"
                        className="faicn"
                        href={void 0}
                        onClick={(event) => handleSearch()}
                      >
                        <IoSearch />
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </th>
                <th>
                  <div className="sortParArr">{translator["Schedule"]}</div>
                  <div className="colSearDv">
                    <Form.Control
                      className="colSearInp"
                      type="text"
                      value={props.scheduleSearchInput != "" ? props.scheduleSearchInput : ""}
                      placeholder={translator["Search here"]}
                      onKeyPress={(e) => {
                        if (e.charCode == "13") {
                          if (
                            props.scheduleSearchInput != "" &&
                            props.scheduleSearchInput?.trim().length > 3
                          )
                            handleSearch();
                        }
                      }}
                      onChange={(event) => {
                        handleChange(event, "schedule");
                      }}
                    />
                    {props.scheduleSearchInput?.trim().length > 3 ? (
                      <a
                        title="search"
                        className="faicn"
                        href={void 0}
                        onClick={(event) => handleSearch()}
                      >
                        <IoSearch />
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </th>
                <th>
                  <div className="sortParArr">{translator["Modified On"]}</div>
                  <div className="colSearDv">
                    <DateRangePicker
                      initialSettings={{
                        autoUpdateInput: false,
                        maxDate: moment(),
                        autoApply: true,
                      }}
                      onApply={(e, picker) => handleSearch(e, picker)}
                      onCallback={handleCallback}
                    >
                      <input
                        type="text"
                        placeholder={translator["Search here"]}
                        readOnly
                        className="form-control"
                        name="modifiedOn"
                        onPaste={handleKeyPress}
                        onKeyDown={handleKeyPress}
                        value={props.modifiedonSearchInput}
                      />
                    </DateRangePicker>
                  </div>
                </th>
                <th>
                  <div className="sortParArr">{translator["Modified By"]}</div>
                  <div className="colSearDv">
                    <Form.Control
                      className="colSearInp"
                      type="text"
                      value={
                        props.modifiedBySearchInput != ""
                          ? props.modifiedBySearchInput
                          : ""
                      }
                      placeholder={translator["Search here"]}
                      onKeyPress={(e) => {
                        if (e.charCode == "13") {
                          if (
                            props.modifiedBySearchInput != "" &&
                            props.modifiedBySearchInput?.trim().length > 3
                          )
                            handleSearch();
                        }
                      }}
                      onChange={(event) => {
                        handleChange(event, "modifiedBy");
                      }}
                    />
                    {props.modifiedBySearchInput?.trim().length > 3 ? (
                      <a
                        title="search"
                        className="faicn"
                        href={void 0}
                        onClick={(event) => handleSearch()}
                      >
                        <IoSearch />
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {showScheduleListLoader ? (
                <tr>
                  <td colSpan="12" style={{ textAlign: "center" }}>
                    <ListLoader />
                  </td>
                </tr>
              ) : shiftListData.length === 0 ? (
                <tr>
                  <td colSpan="12" style={{ textAlign: "center" }}>
                    {translator["There is no matching data available"]}
                  </td>
                </tr>
              ) : (
                renderScheduleList(shiftListData)
              )}
            </tbody>
          </Table>
        </div>
      </div>
      {shiftListData.length > 0 ?
        <div className="nBotPagina">
          <div className="nShow">
            {console.log("Page Number", pageNumber)}
            <div className="margin-r-10" componentClass={Form.Label}>
              {translator["Show"]}:
            </div>
            <Form.Select
              value={props.itemsPerPage}
              onChange={(e) => {
                onPageSizeChange(e);
                props.showRightSideFun(false);
              }}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              {/* <option value="100">100</option> */}
            </Form.Select>
          </div>
          <nav aria-label="Pagination" className="display-inline-block">
            {console.log("allShiftListMeta", allShiftListMeta)}
            <Pagination
              prevPageText="Prev"
              nextPageText="Next"
              firstPageText={<i className="glyphicon glyphicon-menu-left" />}
              lastPageText={<i className="glyphicon glyphicon-menu-right" />}
              activePage={props.activePage + 1}
              itemsCountPerPage={props.itemsPerPage}
              totalItemsCount={
                allShiftListMeta ? allShiftListMeta.numberOfItems : 1
              }
              pageRangeDisplayed={4}
              onChange={(e) => {
                onPageChange(e);
              }}
            />
          </nav>
        </div>
        :
        ''}
    </div>
  );
}

const mapStateToProps = (state) => ({
  // shiftListData: state.shiftListData
});

export default reduxForm({
  form: 'ShiftListView',
})(connect(mapStateToProps)(ShiftListView));