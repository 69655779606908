
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Spinner from 'react-spinkit';
import Form from 'react-bootstrap/Form';

import { loadProfileCurrency } from '_Actions/myProfile/profileCurrencyAction';
import { loadProfileDateFormat } from '_Actions/myProfile/profileDateFormatAction';
import { loadProfileTimeZone } from '_Actions/myProfile/profileTimeZoneAction';
import { loadProfileCWL } from '_Actions/myProfile/profileCWLAction';
import { loadLanguagePreferences } from '_Actions/myProfile/languagePreferencesAction';
import { _checkbox } from '../common/formFields';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

//let title="";
//let CWL="";
//let building="";
// cubicle="";
//let dateFormat="";
//let timeZone="";
//let currency="";

let count1 = 0;
let count2 = 0;

class LeftSideFormTab extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			//updatebtnShowHide:false,
			//inputClassName:true,
			//textInputType1:"text",
			//textInputType2:"hidden",
			//titleErrorColor:"error",
			//CWLErrorColor:"error",
			//buildingErrorColor:"error",
			//cubicleErrorColor:"error",
			//dateFormatErrorColor:"error",
			//timeZoneErrorColor:"error",
			//currencyErrorColor:"error",
			building1: this.props.profileDetails.building,
			updatedMsg: 0,
			titleClassname:"editInput",
			divTitleClassname:"formOpt editSelect"
		};
		count1 = 0;
		count2 = 0;
		//alert(this.props.buildingValue);
	}

	getOrganizationalDetails() {
		// alert('inside getOrganizationalDetails');
		if (count1 === 0) {
			this.props.loadProfileCWL();
			count1++;
		}
	}

	getApplicationPreferences() {
		// alert('inside getApplicationPreferences');
		if (count2 === 0) {
			this.props.loadProfileCurrency();
			this.props.loadProfileDateFormat();
			this.props.loadProfileTimeZone();
			this.props.loadLanguagePreferences("language");
			count2++;
		}
	}

	myInputCallBackFun(mySelect, parentDiv) {
		//title=mySelect.value
		//alert("mySelect.value"+title) //value is coming

		//this.props.showMsg="";
		if (mySelect.className == "editInput") {
			parentDiv.className = "formOpt";
			mySelect.className = "form-control showSelect";
			mySelect.disabled = false;
			mySelect.focus();
			this.setState({
				updatedMsg: 0
			})
			//alert(">>>>>>>>>")
		}
		else {
			parentDiv.className = "formOpt editSelect";
			mySelect.className = "editInput";
			mySelect.disabled = true;
			this.setState({
				updatedMsg: 1
			})
			//alert("====")
		}
		this.setState({ titleClassname: mySelect.className });
		this.setState({ divTitleClassname: parentDiv.className });
		this.props.isSaveTitleClicked(false);

	}

	changeTitle(e) {
		this.props.updateTitle(e.target.value);
	}
	changeCWL(e) {
		this.props.updateCWL(e.target.value);
	}

	changeBuilding(e) {
		//alert(e.target.value)
		this.props.updateBuilding(e.target.value);
	}

	changeCubical(e) {
		this.props.updateCubicle(e.target.value);
	}

	changeDataFormat(e) {
		this.props.updateDateFormat(e.target.value);
	}
	changeTimeZone(e) {
		this.props.updateTimeZone(e.target.value);
	}
	changeCurrency(e) {
		this.props.updateCurrency(e.target.value);
	}
	changeMostPopular(e) {
		let value = false;
		if (e.target.checked) {
			value = "Y";
		} else {
			value = "N";
		}
		this.props.updateMostPopular(value);
	}
	changeNewLaunches(e) {
		let value = false;
		if (e.target.checked) {
			value = "Y";
		} else {
			value = "N";
		}
		this.props.updateNewLaunches(value);
	}
	changeComingSoon(e) {
		let value = false;
		if (e.target.checked) {
			value = "Y";
		} else {
			value = "N";
		}
		this.props.updateComingSoon(value);
	}
	changeFree(e) {
		let value = false;
		if (e.target.checked) {
			value = "Y";
		} else {
			value = "N";
		}
		this.props.updateFree(value);
	}

	changeMyFavourites(e) {

		let value = false;
		if (e.target.checked) {
			value = "Y";
		} else {
			value = "N";
		}
		this.props.updateMyFavourites(value);
	}

	changeCategoryView(e) {
		let value = false;
		if (e.target.checked) {
			value = "Y";
		} else {
			value = "N";
		}
		this.props.updateCategoryView(value);
	}

	changeFeatured(e) {
		let value = false;
		if (e.target.checked) {
			value = "Y";
		} else {
			value = "N";
		}
		this.props.updateFeatured(value);
	}

	changeLanguage(e) {
		this.props.updateLanguage(e.target.value);
	}

	renderProfileCurrency(breakFixData) {
		//alert(breakFixData);
		if (!breakFixData) {
			return (
				<option></option>
			);
		}
		//alert(breakFixData[0].KEY)
		return breakFixData.map((breakFixObj) => {
			//alert(breakFixObj.field1Key)
			return (
				<option value={breakFixObj.KEY}>{breakFixObj.VALUE}</option>
			);
		});
	}



	renderLanguagesDropdown(languages) {
		//alert(breakFixData);
		if (languages.length == 0) {
			return (
				<option></option>
			);
		}

		return languages.map((languages) => {
			//alert(breakFixObj.field1Key)
			return (
				<option value={languages.field1Value}>{languages.field1Key}</option>
			);
		});
	}

	renderProfileDateFormat(breakFixData) {
		//alert(breakFixData);
		if (!breakFixData) {
			return (
				<option></option>
			);
		}
		return breakFixData.map((breakFixObj) => {
			//alert(breakFixObj.field1Key)
			return (
				<option value={breakFixObj.KEY}>{breakFixObj.VALUE}</option>
			);
		});
	}

	renderProfileTimeZone(breakFixData) {
		//alert(breakFixData);
		if (!breakFixData) {
			return (
				<option></option>
			);
		}

		return breakFixData.map((breakFixObj) => {
			//alert(breakFixObj.field1Key)
			return (
				<option value={breakFixObj.KEY}>{breakFixObj.VALUE}</option>
			);
		});
	}

	renderProfileCWL(breakFixData) {

		//alert(breakFixData);
		if (!breakFixData) {
			return (
				<option></option>
			);
		}

		return breakFixData.map((breakFixObj) => {
			//alert(breakFixObj.field1Key)
			return (
				<option value={breakFixObj.LOCATION_ID}>{breakFixObj.LOCATION_NAME}</option>
			);
		});
	}



	render() {

		if (this.props.profileDetails.length == 0) {
			//alert("this.props.breakFixEditDetails.length"+this.props.breakFixEditDetails.length)
			return (
				<Spinner spinnerName="three-bounce" />
			);
		}
		return (
			<div className="inLineLabel">
				{/* {this.props.showMsg == "" ? null : <Alert variant="success" className="errorMessageSec"><p>{this.props.showMsg}</p></Alert>} */}

				<h4>{this.props.tr['Personal Information']}</h4>

				{/* <Form.Group className='form-group'>
					<Form.Label bsClass="" >{this.props.tr['Title']} <i onClick={() => { this.myInputCallBackFun(this.element_1, this.element_111) }} className="fa fa-pencil-square-o cursorPoint orange"></i></Form.Label>
					<div className={this.props.saveTitleClicked ? "formOpt editSelect" : this.state.divTitleClassname} ref={(input) => { this.element_111 = input; }}><select disabled className={this.props.saveTitleClicked ? "editInput" : this.state.titleClassname} 
					ref={(input) => { this.element_1 = input; }} onChange={this.changeTitle.bind(this)} defaultValue={this.props.profileDetails.title}>
						<option value="Mr">Mr</option>
						<option value="Ms">Ms</option>
						<option value="Miss">Miss</option>
					</select></div>
				</Form.Group> */}

				<Form.Group className='form-group'>
					<Form.Label bsClass="">{this.props.tr['Username']}</Form.Label>
					<div className="formOpt">{this.props.profileDetails.username}</div>
				</Form.Group>

				<Form.Group className='form-group'>
					<Form.Label bsClass="">{this.props.tr['Employee Number']}</Form.Label>
					<div className="formOpt">{this.props.profileDetails.employeeNumber}</div>
				</Form.Group>

				<Form.Group className='form-group'>
					<Form.Label bsClass="">{this.props.tr['First Name']}</Form.Label>
					<div className="formOpt">{this.props.profileDetails.firstname}</div>
				</Form.Group>

				<Form.Group className='form-group'>
					<Form.Label bsClass="">{this.props.tr['Last Name']}</Form.Label>
					<div className="formOpt">{this.props.profileDetails.lastname}</div>
				</Form.Group>

				{/* {this.props.profileDetails.datasource != 'Automated' ?
					<Form.Group className='form-group'>
						<Form.Label bsClass="">{this.props.tr['VIP']}</Form.Label>
						<div className="formOpt">{this.props.profileDetails.vip}</div>
					</Form.Group> : ""} */}

				<Form.Group className='form-group'>
					<Form.Label bsClass="">{this.props.tr['User Type']}</Form.Label>
					<div className="formOpt">{this.props.profileDetails.userTypeId}</div>
				</Form.Group>
			</div>
		);
	}
}

export function mapStateToProps({ dateFormatDetails, timeZoneDetails, currencyDetails, languageDetails, CWLDetails }) {
	//alert(breakFixServiceWithoutDomain);
	return { dateFormatDetails, timeZoneDetails, currencyDetails, CWLDetails, languageDetails };
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ loadProfileCurrency, loadProfileDateFormat, loadProfileTimeZone, loadProfileCWL, loadLanguagePreferences }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftSideFormTab);

//export default XsmBreakFixFormTab;
