
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Field } from "redux-form";
import { useGetAssociatedContextualRuleQuery } from "../../../reducers/rules";

export const RuleAmsDdropDownContextual = ({
  name,
  formData,
  companyId,
  moduleName,
  ruleType,
  showAms,
  ...props
}) => {
  console.log("groupData=>", formData);

  const [ruleDataOptions, setRuleDataOptions] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const { data: getAllRules, isFetching } = useGetAssociatedContextualRuleQuery(
    {
      queryString: `?moduleName=${moduleName}&ruleType=${ruleType}&companyId=${companyId}&status=active&weight=N`,
    },
    { skip: !companyId }
  );

  console.log("getAllRules=>", getAllRules);

  useEffect(() => {
    let options = getAllRules?.body?.map((item) => ({
      value: item.id,
      label: item.ruleName,
    }));
    
    options = [...new Map(
      options?.map((item) => [JSON.stringify(item), item])
      ).values(),
    ]
    setRuleDataOptions(options);
  }, [getAllRules]);

  return <Field name={name} {...props} options={ruleDataOptions || []} />;
};
