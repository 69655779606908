/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Button, CloseButton } from "react-bootstrap";
import { ImSpinner6 } from "react-icons/im";
import { resetQuery } from "../../../common/helper";
import { useSelector, resetFilters } from 'react-redux';

const QueryActionComponent = ({retrieveBreakfixRecordOnQuery, showConditionBuilder}) => {
    const translator = useSelector(state => state.spcmReducer.tr);
    const showRunLoader = useSelector(state => state.showRunLoader);
    return (
        <div className="qurBtnSec">
            <Button variant="primary" title={translator['Run Query']} disabled={showRunLoader} onClick={retrieveBreakfixRecordOnQuery}>{showRunLoader ? <ImSpinner6 className="icn-spinner"/> : null} {translator['Run']}</Button>
            <CloseButton title={translator['Close']} className="closbtn" aria-label="Hide" onClick={()=>{ showConditionBuilder(false); resetQuery(); resetFilters(); }} />
      </div>
    );
};

export default QueryActionComponent;
