
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { reduxForm, change } from 'redux-form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
// import { Row, Col, PageHeader, Nav, NavItem, Button } from 'react-bootstrap';
import { submitRuleConfiguration, emptyAssignmentDetails } from '../../../actions/amsActions/amsActions.js';
import {saveQueryBuilderRecords} from '_Actions/common/queryBuilderAction';
import { connect } from 'react-redux';
import { navigationHooks } from '../../../helpers/NavigationHook.js';

import { GLOBAL } from '../../Globals.js';
import configureStore from '../../../store/configureStore.js';
import { Link } from 'react-router-dom';
import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";

const store = configureStore();

let AmsHeader = class AmsHeader extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			disabledSave: false
		};
		this.saveChange = this.saveChange.bind(this);
	}

	saveChange(values,queryResult,queryJSONResult) {

		console.log("value6789",values);
			let submitData={
				"ruleName": values.ruleName,
				"statusId": values.companyStatus,
				"statusValue": values.companyStatusName,
				"companyId": values.company,
				"companyName": values.companyName,
				"moduleId": values.module,
				"moduleName": values.moduleName,
				"weight": values.weight,
				"assignmentGroupId": values.isContextual === false ? values.group : 0,
				"assignmentGroupName": values.isContextual === false ? values.groupName : values.contextualGroup,
				"assignmentCompanyId": values.associatedCompany,
				"assignmentCompanyName": values.associatedCompanyName,
				"isExternal" : GLOBAL.isExternalAmsCreate,
				"createdById": "",
				"createdByName": "",  
				"createdOn": 0,       
				"eventId": values.event, 
				"eventName": values.eventName,
				"isContextual": values.isContextual,
				"groupType": values.isContextual? values.groupFunctionName : "",
				"groupTypeId": values.isContextual? values.groupTypeId : "0",
				"alternateGroupId": values['alternateGroup'] || "0",
				"alternateGroupName": values['alternateGroupName'] || ""
			};

			console.log("dheeraj1",queryJSONResult);
			console.log("queryResultProps79",queryResult);
			let queryResultObj=[];
			queryResultObj = JSON.stringify(queryResult);
			console.log("queryResultObj79",queryResultObj);

			let submitBuilderData={
				"ruleName": values.ruleName,
				"statusId": values.companyStatus,
				"statusValue": values.companyStatusName,
				"consumerCompanyId": values.company,
				"consumerCompany": values.companyName,
				"moduleId": values.module,
				"module": values.moduleName,
				"weight": values.weight,
				"assignmentGroupId": values.isContextual === false ? values.group : 0,
				"assignmentGroupName": values.isContextual === false ? values.groupName : values.contextualGroup,
				"assignmentCompanyId": values.associatedCompany,
				"assignmentCompany": values.associatedCompanyName,
				"isExternal" : GLOBAL.isExternalAmsCreate,
				"createdById": "",
				"createdByName": "",      
				"assignmentForId": values.event, 
				"assignmentFor": values.eventName,
				"isContextual": values.isContextual,
				"groupType": values.isContextual? values.groupFunctionName : "",
				"groupTypeId": values.isContextual? values.groupTypeId : "0",
				"alternateGroupId": values['alternateGroup'] || "0",
				"alternateGroupName": values['alternateGroupName'] || "",
				"query":queryResult,
				"conditionalJson":JSON.parse(queryJSONResult)
			};

			// this.props.submitRuleConfiguration(submitData);
			this.props.saveQueryBuilderRecords(submitBuilderData);
			
	}

	render() {
		const { handleSubmit, tr,queryResult,queryJSONResult} = this.props;
		console.log("queryResult567",queryResult,queryJSONResult);
		return (
			<Row className="margin-b-15">
				<Col md={7} sm={7}>
					<h2 bsClass="" className="sPageHeading1">{tr['Assignment Rules']}</h2>
				</Col>
				<Col md={5} sm={5} className="hidden-xs">
					<div className="paHedFilter">
						<Button bsPrefix=' ' id="creDeleSav" role="button"  className="myBt plus" disabled={this.props.loader} onClick={handleSubmit((values) => this.saveChange(values, queryResult,queryJSONResult))} title={tr['Save']}>{this.props.loader ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/>}</Button>
						<Button bsPrefix=' ' role="button" className="myBt cancel fillBtn" title={tr['Cancel']} onClick={() => { navigationHooks.navigate('/rulesList') }} ><IoClose /></Button>
					</div>
				</Col>
			</Row>
		);
	}
}

const mapStateToProps = ({ allRuleConfigurationsData, showLoader4 }) => {
	return {
		allRuleConfigurationsData, loader: showLoader4.loading
	}
}

AmsHeader = reduxForm({
	form: 'xsmAmsCreateRulesForm'
})(AmsHeader);

export default connect(mapStateToProps, { submitRuleConfiguration, emptyAssignmentDetails,saveQueryBuilderRecords})(AmsHeader);
