
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const isFetchingDetails_KA = (state = {
    isFetching: false,
    posting:false,
    postSuccess:false,
    postingError:false,
    message: '',
    isFetchingAttachedKA: false
  }, action) => {
    switch (action.type) {
      case 'FETCHING_DETAILS_IN_PROGRESS_KA':
        return Object.assign({}, state, {
          isFetching: true,
          message: 'loading...'
        });
      case 'DETAILS_FETCHED_SUCCESSFULLY_KA':
        return Object.assign({}, state, {
          isFetching: false,
          message: ''
        });
      case 'FETCHING_DETAILS_IN_PROGRESS_ATTACHED_KA':
        return Object.assign({}, state, {
          isFetchingAttachedKA: true,
          message: 'loading...'
        });
      case 'DETAILS_FETCHED_SUCCESSFULLY_ATTACHED_KA':
        return Object.assign({}, state, {
          isFetchingAttachedKA: false,
          message: ''
        });
      default:
        return state;
    }
  };

  export const attachedArticlesList = (state = [], action) => {
    switch (action.type) {
      case 'LOAD_ATTACHED_ARTICLES_SUCCESS':
        if(action.articleResultsResponse && action.articleResultsResponse != undefined && action.articleResultsResponse.data && Array.isArray(action.articleResultsResponse.data) && action.articleResultsResponse.data.length) {
            return action.articleResultsResponse.data;
        }
        else{
            return( [] );
        }
      default:
        return state;
    }
  };