
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";

import PindCategories from "../../../common/header/pind-categories";
import Breadcrumbs from "../../../common/header/breadcrumbs";
import Header from "./header";
import FormComponent from "./form";
import ValuesPart from "./values";
import { loadTagDetailsByTagId } from '_Actions/foundation/tags/tagsAction';
import ListLoader from "../../../common/loaders/ListLoader.js";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import "_Css/form/_form.scss";

const Index = () => {
  const dispatch = useDispatch();
  const { tagId } = useParams();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const [companyErrorState, setCompanyErrorState] = useState('');
  const [isRightEditPanel, setrightEditPanel] = useState(true);

  useEffect(() => {
    dispatch(loadTagDetailsByTagId(tagId));
  }, []);

  const rightEditPanel = (value) => {
    setrightEditPanel(value);
  };

  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Breadcrumbs activePageName="Edit" parentPageurl='/customAttribute' parentPageName="Custom Attribute" />
      </Container>

      <Container fluid>
        <Header tagId={tagId} setCompanyErrorState={setCompanyErrorState} rightEditPanel={rightEditPanel} />
        <PanelGroup direction="horizontal" className="panelOverflow">
          <Panel id="sidebar" minSize={33} order={1} defaultSize={isRightEditPanel ? 67 : 100} className={isRightEditPanel ? "isShowLeftPanel catBordDv" : "catBordDv"}>
          <FormComponent tagId={tagId} companyErrorState={companyErrorState} setCompanyErrorState={setCompanyErrorState} rightEditPanel={rightEditPanel} />
          </Panel>
          {isRightEditPanel ?
              <>
                <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
                <Panel minSize={33} order={2} defaultSize={33}>
                   <ValuesPart tagId={tagId} rightEditPanel={rightEditPanel} />
                </Panel>
              </> : ""
          }
        </PanelGroup>
      </Container>
    </main>
  )
}

export default Index;
