
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export default function orderquestionnaireListReducer(state = initialState.orderquestionnaireList, action){
	switch (action.type){
		case 'LOAD_ORDER_QUESTIONNAIRE_SUCCESS':
			return action.orderquestionnaireList.data;
			default:
			return state;
	}
}
