
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadBreakFixSLALogDetails } from '../../../../actions/breakFix/breakFixSLALogAction';
import Spinner from 'react-spinkit';
import {IoClose} from "react-icons/io5";

class XSMSLA extends React.Component {
  componentWillMount() {
    // this.props.loadBreakFixSLALogDetails(constants.slamodvalchnge, this.props.editChangeInitialData.changeNumber);
    // this.props.loadBreakFixSLALogDetails("1","1");
  }

  renderSLATable(slaData) {
    if (slaData.length == 0) {
      return null;
    }
    else {
      return slaData.map((sla, index) => {


        let h = Math.floor(sla.allocatedTime / 60);
        if (h < 10 || h == 0)
          h = "0" + h;
        let m = parseInt(sla.allocatedTime % 60, 10);

        if (m < 10 || m == 0)
          m = "0" + m;
        //console.log(h+":"+m);
        let time = h + ":" + m
       
        let slastatus = ""
       
        if (sla.status == "IN PROGRESS")
          slastatus = "In Progress";
        else if (sla.status == "ALREADY BREACHED" || sla.status == "BREACHED")
          slastatus = "Breached";
        else if (sla.status == "MET")
          slastatus = "Met";
        else if (sla.status != "IN PROGRESS" && sla.status != "ALREADY BREACHED" && sla.status != "BREACHED" && sla.status != "MET")
          slastatus = sla.status

        let hleft = Math.floor(sla.timeLeft / 60);

        if (hleft < 10 || hleft == 0)
          hleft = "0" + hleft;
        let mleft = sla.timeLeft % 60;
        if (mleft < 10 || mleft == 0)
          mleft = "0" + mleft;
        //console.log(h+":"+m);
        let timeleft = hleft + ":" + mleft
        return (
          <tr key={index}>
            <td className="text-c">{sla.slaType}</td>
            <td className="text-c">{sla.slaName}</td>
            <td className="text-c">{time} Hours</td>
            <td className="text-c">{slastatus}</td>
            <td className="text-c">
              {/* {sla.duedate} */}
              {(slastatus && slastatus.toLowerCase() === 'invalid') ? 'N/A' : sla.duedate}
            </td>

          </tr>
        );
      });
    }
  }

  render() {
    const loading = this.props.loader;

    if (loading) {
      return (
        <div>
          <h2>{this.props.translator['Loading SLA']}...</h2>
          <Spinner spinnerName='three-bounce' />
        </div>
      );
    }

    if (loading == false && this.props.breakFixSLALogDetails.length == 0) {
      return (
        <div>
          <h2>{this.props.translator['Currently no sla exist']}</h2>
        </div>
      );
    }

    return (
      <div className="">
        <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
            <ul>
                <li>
                <button
                    type='button'
                    title="Minimize the right panel"
                    bsClass=""
                    bsStyle=""
                    className="closerightPanelBtn"
                    onClick={() => {
                    this.props.rightEditPanel(false);
                    }}
                >
                    <IoClose />
                </button>
                </li>
            </ul>
        </div>
        <div className="rPageHeading">SLA</div>
        <Table className="table table-striped table-bordered table-condensed table-hover tablesla">
          <thead>
            <tr>
              <th className="text-c">Type</th>
              <th className="text-c">Name</th>
              <th className="text-c">SLA</th>
              <th className="text-c">Status</th>
              <th className="text-c">Due By</th>
            </tr>
          </thead>
          <tbody>
            {this.renderSLATable(this.props.breakFixSLALogDetails)}
          </tbody>
        </Table>
      </div>
    );
  }
}

export function mapStateToProps({ breakFixSLALogDetails, showLoader_3 }) {
  //  alert("breakFixEditDetails:"+breakFixAuditLogDetails);
  return { breakFixSLALogDetails, loader: showLoader_3.loading };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loadBreakFixSLALogDetails }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(XSMSLA);
