
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import {Row, Col, Form, Alert, ButtonToolbar, Button} from 'react-bootstrap';
import { _textArea, _dropDown, _inputField,_number } from '../../common/formFields';
import { Field, reduxForm,SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import axios from 'axios';
import { spcmLoader,setLeftEditId,getConsumptionItems,getServiceDetailCounts } from '../../../actions/spcmActions';
import {ImSpinner6} from "react-icons/im";


const validate = (values,props) => {
	let errors={};
	if (values.component_name) {
		if (values.component_name.trim() == "") {
			errors.component_name = "Enter Name";
		}
	} else if (!values.component_name) {
		errors.component_name = "Enter Name";
	}
	if (!values.component_type) {
		errors.component_type = 'Enter Type';
	}
	if (!values.status) {
		errors.status = 'Enter Status';
	}
	if(props.spcmReducer.currentlySelectedServiceDetails.WORK_ITEM_TYPE === 'serviceRequest'){
		if (!values.component_sequence) {
			errors.component_sequence = 'Enter Sequence';
		}
	}	

	if (values.description) {
		if (values.description.trim() == "") {
			errors.description = "Enter Description";
		}
	} else if (!values.description) {
		errors.description = "Enter Description";
	}

	

	return errors;
}




class ComponentForm extends React.Component{

	constructor(props){
		super(props);
		this.submitForm = this.submitForm.bind(this);
	}

	componentWillUnmount(){
		this.props.spcmLoader('attribute','success');
	}

		submitForm(values){
			if(this.props.spcmReducer.editLeftId>0){
							values.updatedby_name="";
							values.updatedby="";
						}else{
							values.createdby_name="";
							values.created_by="";
					}
			if(this.props.spcmReducer.editLeftId>0){
				    this.props.spcmLoader('attribute','posting');
					return axios.patch("/service/api/patchConsumptionItems/"+this.props.spcmReducer.editLeftId,values).then((response) => {
						this.props.getConsumptionItems(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
						this.props.componentFormFun4(false);
						this.props.spcmLoader('attribute','success');
					})
					.catch((error) => {
					
						this.props.spcmLoader('attribute','success');
						throw new SubmissionError({_error: error.response.data[0].message });
					});
				}
				else{
					values.offering_id=this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID;
					this.props.spcmLoader('attribute','posting');
					return axios.post("/service/api/saveConsumptionItems",values).then((response) => {
						this.props.getConsumptionItems(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
						this.props.getServiceDetailCounts(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
						this.props.componentFormFun4(false);
						this.props.spcmLoader('attribute','success');
					})
					.catch((error) => {
				
						this.props.spcmLoader('attribute','success');
						throw new SubmissionError({_error: error.response.data[0].message });
					});
				}
		}
	render(){
		const tr=this.props.tr;
		const {error,handleSubmit} = this.props;
		return(

			<div className="rBoxGap">
				<form  onSubmit={handleSubmit(this.submitForm)}>
				{error == undefined || error == null ? null :  <Alert variant="danger" className="errorMessageSec"><p>{error}</p></Alert>}
				<Row>
					<Col md={12}>
						<Form.Group className='form-group'>
						  <Form.Label bsClass=""><span className="rStar"></span> {tr['Name']}</Form.Label>
						  <Field component={_inputField} name="component_name" className='form-control' />
						 </Form.Group>
					</Col>
					<Col md={12}>
						<Form.Group className='form-group'>
						  <Form.Label bsClass=""><span className="rStar"></span> {tr['Type']}</Form.Label>
						  <Field component={_dropDown}  name="component_type" className='form-control' options={[{value:"core",text:"Core"},{value:"optional",text:"Optional"}]} disabled={this.props.spcmReducer.currentlySelectedServiceDetails.WORK_ITEM_TYPE !== 'serviceRequest'} />
						 </Form.Group>
					</Col>
					<Col md={12}>
						<Form.Group className='form-group'>
							<Form.Label bsClass=""><span className="rStar"></span> {tr['Sequence']}</Form.Label>
							<Field component={_number}  name="component_sequence" className='form-control' min={1} max={50} disabled={this.props.spcmReducer.currentlySelectedServiceDetails.WORK_ITEM_TYPE !== 'serviceRequest'} />
						 </Form.Group>
					</Col>
					<Col md={12}>
						<Form.Group className='form-group'>
							<Form.Label bsClass=""><span className="rStar"></span> {tr['Status']}</Form.Label>
							<Field component={_dropDown}  name="status" className='form-control' options={[{value:"",text:"Select"},{value:"active",text:"Active"},{value:"inactive",text:"Inactive"}]} disabled={this.props.spcmReducer.currentlySelectedServiceDetails.WORK_ITEM_TYPE !== 'serviceRequest'} />
						 </Form.Group>
					</Col>
					
				  </Row>
				  <Row>
					<Col md={12}>
						<Form.Group className='form-group'>
						  <Form.Label bsClass=""><span className="rStar"></span> {tr['Description']}</Form.Label>
						  <Field component={_textArea} name="description" className='form-control' rows="3" disabled={this.props.spcmReducer.currentlySelectedServiceDetails.WORK_ITEM_TYPE !== 'serviceRequest'} />
						 </Form.Group>
					</Col>
				  </Row>
				  <Row>
						<Col md={12}>
							<ButtonToolbar className="black">
								<Button className='rgSidrkBtn' title="Save" disabled={this.props.spcmReducer.spcmPostingAttribute} type="submit" bsSize="small" bsStyle="primary">{this.props.spcmReducer.spcmPostingAttribute ? <ImSpinner6 className="icn-spinner"/> : null } {tr['Save']}</Button>
								<Button className='rgSiLigBtn' title="Close" onClick={()=>{this.props.componentFormFun4(false)}} bsSize="small" bsStyle="text">{tr['Close']}</Button></ButtonToolbar>
						</Col>
					</Row>
			</form>
					</div>
		);
	}
}

const mapStateToProps = ({spcmReducer}) => {
	return {
		spcmReducer,
		initialValues:spcmReducer.editLeftId>0?spcmReducer.editLeftService:''
	}
};

ComponentForm=reduxForm({
	form:'componentForm',
	validate,
	enableReinitialize : true
})(ComponentForm)

export default connect(mapStateToProps,{spcmLoader,setLeftEditId,getConsumptionItems,getServiceDetailCounts})(ComponentForm);
