
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useSelector } from "react-redux";

import PindCategories from "../../common/header/pind-categories";
import Breadcrumbs from "../../common/header/breadcrumbs";
import SchedulerEditHeader from "./SchedulerEditHeader";
import SchedulerEditForm from "./SchedulerEditForm";
import { useGetSchedulerDetailByIdQuery } from "../../../reducers/schedulerUi";
import { useParams } from "react-router";

const SchedulerEditIndex = () => {
  const tr = useSelector((state) => state.spcmReducer.tr);
  const [activeChecked, setActiveChecked] = useState(false);
  const params = useParams();
  const { data, isLoading, error } = useGetSchedulerDetailByIdQuery({
    schedulerId: params?.id,
  });
  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Breadcrumbs
          activePageName={tr["Edit"]}
          parentPageurl="/scheduler"
          parentPageName="Scheduler"
        />
      </Container>

      <Container fluid>
        <SchedulerEditHeader activeChecked={activeChecked} />
        <Row>
          <Col md={12} xs={12}>
            <SchedulerEditForm
              translator={tr}
              detail={data}
              activeChecked={activeChecked}
              setActiveChecked={setActiveChecked}
            />
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default SchedulerEditIndex;
