
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import XsmTypeAhead from "../formFields/XsmTypeAhead";
import DynamicDropdown from "../formFields/DynamicDropdown";
import RadioCheckBoxGroup from "../formFields/RadioCheckBoxGroup";
import swal from "sweetalert";
import axios from "axios";
import Datetime from "react-datetime";
import Attachment from "../formFields/CartAttachments";

const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

import Joi from "joi";
import sanitizeHtml from "sanitize-html";
import moment from "moment";
import { Col, Row, Table } from "react-bootstrap";
import OnBehalfTypeAhead from "./OnBehalfTypeAhead";
import OnBehalfDynamicDropdown from "./OnBehalfDynamicDropdown";
import { getChildQuestion, getUrgencyValuesForIncident } from "../../../../../actions/cart/addToCartActions";
import { useDispatch, useSelector } from "react-redux";
import InputNumberField from "../../../../common/inputNumber";

const PISectionFields = (props) => {
  const [formErrors, setFormErrors] = useState({});
  const [tableValues, setTableValues] = useState([]);
  const [tableFormErrors, setTableFormErrors] = useState({});
  const [urgencyValuesFetched, setUrgencyValuesFetched] = useState(false);
  const [urgencyImpactValuesInitialised, setUrgencyImpactValuesInitialised] = useState(false);

  const urgencyValuesReducer = useSelector((state) => state.urgencyValuesReducer);

  const dispatch = useDispatch();

  const validateField = (question, event) => {
    let eventCheckBox = {};
    let eventMultiSelect = {};
    let numericSpinnerEvent = {};
    let errorsObj = formErrors;
    if (question.questionType === "mandatory") {
      if (question.responseType === "Dropdown List") {
        if (
          event.target.value === "" ||
          event.target.value === "Select" ||
          event.target.value === null
        ) {
          errorsObj[event.target.name] = true;
          setFormErrors(errorsObj);
        } else {
          errorsObj = formErrors;
          errorsObj[event.target.name] = false;
          setFormErrors(errorsObj);
        }
      }
      if (question.responseType === "Numeric Spinner") {
        if (
          event === "" ||
          event === null ||
          event > Number(question.maxValue) ||
          event < Number(question.minValue)
        ) {
          errorsObj[question.name] = true;
          setFormErrors(errorsObj);
        } else {
          errorsObj = formErrors;
          errorsObj[question.name] = false;
          setFormErrors(errorsObj);
        }
        numericSpinnerEvent = {
          target: {
            name: question.name,
            value: event
          }
        }
      }
      if (question.responseType === "Radio button") {
        if (event.target.value === "" || event.target.value === null) {
          errorsObj[question.name] = true;
          setFormErrors(errorsObj);
        } else {
          errorsObj = formErrors;
          errorsObj[question.name] = false;
          setFormErrors(errorsObj);
        }
      }
      if (question.responseType === "Lookup Textbox") {
        if (
          event.target.value === "" ||
          event.target.value === null ||
          event.target.value.trim() === ""
        ) {
          errorsObj[question.name] = true;
          setFormErrors(errorsObj);
        } else {
          errorsObj = formErrors;
          errorsObj[question.name] = false;
          setFormErrors(errorsObj);
        }
      }
      if (question.responseType === "Multiselect List") {
        let value = [];
        let options = [].slice.call(event.target.options);
        options.map((option) => {
          if (option.selected) {
            value.push(option.value);
          }
        });
        if (event.target.value === "" || event.target.value === null) {
          errorsObj[question.name] = true;
          setFormErrors(errorsObj);
        } else {
          errorsObj = formErrors;
          errorsObj[question.name] = false;
          setFormErrors(errorsObj);
        }
        eventMultiSelect = {
          target: {
            name: question.name,
            value: value.toString(","),
          },
        };
      }
      if (question.responseType === "Checkbox") {
        let checkBoxValues = props.piSectionFormValues[question.name] || [];
        if (event.target.checked) {
          checkBoxValues.push(event.target.value);
        } else {
          checkBoxValues.splice(checkBoxValues.indexOf(event.target.value), 1);
        }
        if (checkBoxValues === null || checkBoxValues.length === 0) {
          errorsObj[question.name] = true;
          setFormErrors(errorsObj);
        } else {
          if (question.haveChild === "true") {
            dispatch(getChildQuestion(
              question,
              checkBoxValues,
              props.componentIds,
              checkBoxValues
            ));
          }
          errorsObj = formErrors;
          errorsObj[question.name] = false;
          setFormErrors(errorsObj);
        }
        eventCheckBox = {
          target: {
            name: question.name,
            value: checkBoxValues,
          },
        };
      }
      if (
        question.responseType === "Multiline Textbox TypeHead Search" ||
        question.responseType === "Textbox" ||
        question.responseType === "Multiline Textbox"
      ) {
        if (
          event.target.value.length === 0 ||
          event.target.value.trim() === "" ||
          event.target.value === null
        ) {
          errorsObj[event.target.name] = true;
          setFormErrors(errorsObj);
        } else {
          errorsObj = formErrors;
          errorsObj[event.target.name] = false;
          setFormErrors(errorsObj);
        }
      }
      props.setFormErrors(errorsObj);

      if (question.responseType === "Checkbox") {
        props.handleOnChange(eventCheckBox);
      } else if (question.responseType === "Multiselect List") {
        props.handleOnChange(eventMultiSelect);
      } else if(question.responseType === "Numeric Spinner") {
        props.handleOnChange(numericSpinnerEvent);
      } else {
        props.handleOnChange(event);
      }
    } else {
      if(question.responseType === "Numeric Spinner") {
          errorsObj = formErrors;
          errorsObj[question.name] = false;
          setFormErrors(errorsObj);
        numericSpinnerEvent = {
          target: {
            name: question.name,
            value: event
          }
        }
        props.setFormErrors(errorsObj);
      }
      if (question.responseType === "Multiselect List") {
        let value = [];
        let options = [].slice.call(event.target.options);
        options.map((option) => {
          if (option.selected) {
            value.push(option.value);
          }
        });
        eventMultiSelect = {
          target: {
            name: question.name,
            value: value.toString(","),
          },
        };
      }
      if (question.responseType === "Checkbox") {
        let checkBoxValues = props.piSectionFormValues[question.name] || [];
        let checkboxIds = props.piSectionFormValues[`${question.name}_dataId`] || [];
        if (event.target.checked) {
          checkBoxValues.push(event.target.value);
          checkboxIds.push(event.target.getAttribute('data-id'));
        } else {
          checkBoxValues.splice(checkBoxValues.indexOf(event.target.value), 1);
          checkboxIds.splice(checkboxIds.indexOf(event.target.getAttribute('data-id')), 1);
        }
        if (question.haveChild === "true") {
          dispatch(getChildQuestion(
            question,
            checkboxIds.join(";"),
            props.componentIds,
            checkBoxValues.join(";")
          ));
        }
        eventCheckBox = {
          target: {
            name: question.name,
            value: checkBoxValues,
            dataId: checkboxIds
          },
        };
      }
      if (question.responseType === "Checkbox") {
        props.handleOnChange(eventCheckBox);
      } else if (question.responseType === "Multiselect List") {
        props.handleOnChange(eventMultiSelect);
      } else if(question.responseType === "Numeric Spinner") {
        props.handleOnChange(numericSpinnerEvent);
      } else {
        props.handleOnChange(event);
      }
    }
  };

  const validateTableField = (question, event, index) => {
    let errorsObj = tableFormErrors;
    let values = tableValues;
    let tableEvent = {};
    if(!question.question){
      if (question.questionType === "mandatory") {
        if (values[index][event.target.name].trim() === "") {
          errorsObj[`${event.target.name}${index}`] = true;
          setTableFormErrors(errorsObj);
        } else {
          errorsObj = formErrors;
          errorsObj[`${event.target.name}${index}`] = false;
          setTableFormErrors(errorsObj);
        }
      }
      tableEvent = {
        type: "table",
        target: {
          name: question.name,
          value: values,
        },
      };
    }
    else{
      if (question.question.questionType === "mandatory") {
        if (values[index][event.target.name].trim() === "") {
          errorsObj[`${event.target.name}${index}`] = true;
          setTableFormErrors(errorsObj);
        } else {
          errorsObj = formErrors;
          errorsObj[`${event.target.name}${index}`] = false;
          setTableFormErrors(errorsObj);
        }
      }
      tableEvent = {
        type: "table",
        target: {
          name: question.question.name,
          value: values,
        },
      };
    }
    props.setPISectionTableFormErrors(errorsObj);
    props.handleOnChange(tableEvent);
  };

  const renderMasterQuestionsFields = (props) => {
    let namedSchema = Joi.string().regex(/^[^}{]+$/);
    let validator_to_add_myQbj = undefined;
    let validate_summary = undefined;
    if (props.question.displayText != null || props.question.displayText != "") {
      validator_to_add_myQbj = namedSchema.validate(props.question.displayText);
      validator_to_add_myQbj.error == null
        ? (validate_summary = props.question.displayText)
        : (validate_summary = "");
      if (validator_to_add_myQbj.error != null) {
      }
    } else {
      validate_summary = "";
    }
    if (props.question.responseType === "Dropdown List") {
      let childQuestions = [];
      if (
        props.question.questionText === "Urgency" &&
        props.offering.workItemType === "incidentRequest" &&
        props.question.categoryId === 1010101010
      ) {
        let queryValues = [
          props.offering.company,
          "Breakfix",
          "Urgency,Impact",
          "1",
          props.language,
        ];
        let query = {
          api: props.question.api,
        };
        props.question.responseValueList.map((key, index) => {
          query[key.trim()] = queryValues[index];
        });
        let config = {
          headers: {
            query: JSON.stringify(query),
          },
        };
        if (!urgencyValuesFetched) {
          dispatch(getUrgencyValuesForIncident(config));
          setUrgencyValuesFetched(true);
        }
      }
      if (props.question.parent_id === 0) {
        props.showChildQuestions(
          props.question,
          childQuestions,
          props.offering,
          props.dispatch
        );
      }

      let dropDownValues = "";
      if (
        urgencyValuesReducer.length > 0 &&
        props.question.questionText === "Urgency" &&
        props.offering.workItemType === "incidentRequest" &&
        props.question.categoryId === 1010101010
      ) {
        let impactValues = [];
        let urgencyValues = [];
        dropDownValues = urgencyValuesReducer.map((option, index) => {
          if (option.field2Name === "Urgency") {
            urgencyValues.push(Number(option.field1Value));
            return (
              <option key={option.field1Key + index} value={option.field1Value}>
                {option.field1Key}
              </option>
            );
          } else if (option.field2Name === "Impact") {
            impactValues.push(Number(option.field1Value));
          }
        });
        if (!urgencyImpactValuesInitialised) {
          impactValues.sort((a, b) => a - b);
          urgencyValues.sort((a, b) => a - b);
          props.setServiceCriticalityandUrgency(impactValues, urgencyValues);
          setUrgencyImpactValuesInitialised(true);
        }
      } else if (props.question.tildArray && props.question.tildArray.length > 0) {
        let tildArray = props.question.tildArray[0].split("^");
        dropDownValues = tildArray.map((option, index) => {
          let actualOption = option.split("~");
          return (
            <option key={actualOption[0] + index} value={actualOption[1]}>
              {actualOption[0]}
            </option>
          );
        });
      } else {
        dropDownValues = props.question.responseValueList.map((option, index) => {
          return <option key={option + index}>{option}</option>;
        });
      }
      let fieldClass = props.piSectionFormErrors[props.question.name]
        ? "form-control error"
        : "form-control";

      return (
        <div>
          {props.question.questionText === "Urgency" ? (
            <div>
              <Row className="botLineWhite">
                {props.renderQuestionLabel(props.question)}
                <Col xs={12} sm={6} md={6} lg={6}>
                  <select
                    // {...props.input}
                    className={fieldClass}
                    name={props.question.name}
                    onChange={(event) => {
                      validateField(props.question, event);
                      if (props.question.haveChild === "true") {
                        dispatch(getChildQuestion(
                          props.question,
                          event.target.value,
                          props.componentIds,
                          event.target[event.target.selectedIndex].text.trim()
                        ));
                      }
                    }}
                  >
                    <option value="">{props.translator["Select"]}</option>
                    {dropDownValues}
                  </select>
                </Col>
              </Row>
            </div>
          ) : (
            <div>
              <Row className="botLineWhite">
                {props.renderQuestionLabel(props.question)}
                <Col xs={12} sm={6} md={6} lg={6}>
                  <select
                    // {...props.input}
                    className={fieldClass}
                    name={props.question.name}
                    onChange={(event) => {
                      validateField(props.question, event);
                      if (props.question.haveChild === "true") {
                        dispatch(getChildQuestion(
                          props.question,
                          event.target.value,
                          props.componentIds,
                          event.target[event.target.selectedIndex].text.trim()
                        ));
                      }
                    }}
                  >
                    <option value="">Select</option>
                    {dropDownValues}
                  </select>
                </Col>
              </Row>
            </div>
          )}
          {childQuestions.length !== 0 ? childQuestions : null}
        </div>
      );
    } else if (props.question.responseType === "Textbox") {
      let fieldClass = props.piSectionFormErrors[props.question.name]
        ? "form-control error"
        : "form-control";
      return (
        <Row className="botLineWhite">
          {props.renderQuestionLabel(props.question)}
          <Col xs={12} sm={6} md={6} lg={6}>
            <input
              type="text"
              maxLength={props.question.maxLength ? props.question.maxLength : 1000}
              className={fieldClass}
              name={props.question.name}
              onChange={(event) => validateField(props.question, event)}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
            />
          </Col>
        </Row>
      );
    } else if (
      props.question.responseType === "Typeahead" || 
      props.question.responseType === "multiSelectTypeahead"
    ) {
      let childQuestions = [];
      if (props.question.parent_id === 0) {
        props.showChildQuestions(
          props.question,
          childQuestions,
          props.offering,
          props.dispatch
        );
      }
      return (
        <div>
          <Row className="botLineWhite">
            {props.renderQuestionLabel(props.question)}
            <Col xs={12} sm={6} md={6} lg={6}>
              {props.onBehalfOrder ? (
                <OnBehalfTypeAhead
                  id={props.question.qId}
                  questionnaire={props.question}
                  setAnswer={props.setAnswer}
                  dispatch={props.dispatch}
                  parentProps={props}
                  offering={props.offering}
                  piSectionFormErrors={props.piSectionFormErrors}
                  setFormErrors={props.setFormErrors}
                  translator={props.translator}
                  multiple={
                    props.question.responseType === "multiSelectTypeahead"
                      ? true
                      : false
                  }
                  handleOnChange={validateField}
                  piSectionFormValues={props.piSectionFormValues}
                />
              ) : (
                <XsmTypeAhead
                  id={props.question.qId}
                  questionnaire={props.question}
                  setAnswer={props.setAnswer}
                  dispatch={props.dispatch}
                  parentProps={props}
                  offering={props.offering}
                  piSectionFormErrors={props.piSectionFormErrors}
                  setFormErrors={props.setFormErrors}
                  translator={props.translator}
                  multiple={
                    props.question.responseType === "multiSelectTypeahead"
                      ? true
                      : false
                  }
                  handleOnChange={validateField}
                  piSectionFormValues={props.piSectionFormValues}
                />
              )}
            </Col>
          </Row>
          {childQuestions.length !== 0 ? childQuestions : null}
        </div>
      );
    } else if (props.question.responseType === "Attachment" || props.question.responseType === "Dropzone") {
      return (
        <div>
          <Row className="botLineWhite">
            {props.renderQuestionLabel(props.question)}
            <Col xs={12} sm={6} md={6} lg={6}>
              <Attachment
                {...props}
                translator={props.translator}
                itemDetails={props.question}
                setAnswer={props.setAnswer}
                dispatch={props.dispatch}
                question={props.question}
                offeringId={props.question.serviceId}
                piSectionFormErrors={props.piSectionFormErrors}
                setFormErrors={props.setFormErrors}
              />
            </Col>
          </Row>
        </div>
      );
    } else if (props.question.responseType === "Datetime") {
      let dateFormat = props.dateFormat.slice(0, 10).toUpperCase();
      let timeFormat = "HH:mm:ss";
      let fieldClass = props.piSectionFormErrors[props.question.name]
        ? "calIcon error"
        : "calIcon";
      const dateValidation = (current) => {
        if(props.offering.workItemType == "standardChange" && props.question.categoryId === 1010101010) {
          return current.isAfter(moment().subtract(1, "d"));
        } else {   
          if (props.question.calendarTypeValue == "Past Dates") {
            return current.isAfter(moment().subtract(1, "d"));
          } else if (props.question.calendarTypeValue == "Future Dates") {
            return current.isBefore(moment());
          } else if (props.question.calendarTypeValue == "Date Range") {
            return (
              current.isAfter(moment().subtract(props.question.daysBefore + 1, "d")) &&
              current.isBefore(moment().add(props.question.daysAfter, "d"))
            );
          } else {
            return true;
          }
        }
      };
      return (
        <div className="dateTimeSdiv dateTimePosTop dateTimeRightPos dateTimeBot">
          <Row className="botLineWhite">
            {props.renderQuestionLabel(props.question)}
            <Col xs={12} sm={6} md={6} lg={6}>
              <Datetime
                className={fieldClass}
                locale="en"
                dateFormat={dateFormat}
                timeFormat={timeFormat}
                inputProps={{
                  readOnly: true,
                  onKeyDown: (e) => {
                    e.key === "Enter" && e.preventDefault();
                  },
                }}
                isValidDate={dateValidation}
                defaultValue={""}
                onChange={(event) => {
                  let errorsObj = formErrors;
                  if (event === "" || event === null) {
                    errorsObj[props.question.name] = true;
                  } else {
                    errorsObj[props.question.name] = false;
                  }
                  props.setFormErrors(errorsObj);
                  props.setAnswer(props.question, event, props.dispatch);
                }}
                closeOnSelect={true}
              />
            </Col>
          </Row>
        </div>
      );
    } else if (props.question.responseType === "Calendar") {
      let dateFormat = props.dateFormat.slice(0, 10).toUpperCase();
      let timeFormat = "HH:mm:ss";
      let fieldClass = props.piSectionFormErrors[props.question.name]
        ? "error calIcon"
        : "calIcon";
      const dateValidation = (current) => {
        if (props.question.calendarTypeValue == "Past Dates") {
          return current.isAfter(moment().subtract(1, "d"));
        } else if (props.question.calendarTypeValue == "Future Dates") {
          return current.isBefore(moment());
        } else if (props.question.calendarTypeValue == "Date Range") {
          return (
            current.isAfter(moment().subtract(props.question.daysBefore + 1, "d")) &&
            current.isBefore(moment().add(props.question.daysAfter, "d"))
          );
        } else {
          return true;
        }
      };
      return (
        <div className="dateTimeSdiv dateTimePosTop dateTimeRightPos dateTimeBot">
          <Row className="botLineWhite">
            {props.renderQuestionLabel(props.question)}
            <Col xs={12} sm={6} md={6} lg={6}>
              <Datetime
                className={fieldClass}
                locale="en"
                dateFormat={dateFormat}
                timeFormat={false}
                inputProps={{
                  readOnly: true,
                  onKeyDown: (e) => {
                    e.key === "Enter" && e.preventDefault();
                  },
                }}
                isValidDate={dateValidation}
                defaultValue={""}
                onChange={(event) => {
                  let errorsObj = formErrors;
                  if (event === "" || event === null) {
                    errorsObj[props.question.name] = true;
                  } else {
                    errorsObj[props.question.name] = false;
                  }
                  props.setFormErrors(errorsObj);
                  props.setAnswer(props.question, event, props.dispatch);
                }}
                closeOnSelect={true}
              />
            </Col>
          </Row>
        </div>
      );
    } else if (props.question.responseType === "Multiline Textbox") {
      let fieldClass = props.piSectionFormErrors[props.question.name]
        ? "form-control error"
        : "form-control";
      return (
        <div>
          <Row className="botLineWhite">
            {props.renderQuestionLabel(props.question)}
            <Col xs={12} sm={6} md={6} lg={6}>
              <textarea
                maxLength={props.question.maxLength ? props.question.maxLength : 10000}
                style={{ height: "60px" }}
                className={fieldClass}
                name={props.question.name}
                onChange={(event) => validateField(props.question, event)}
              />
            </Col>
          </Row>
        </div>
      );
    } else if (props.question.responseType === "Multiline Textbox TypeHead Search") {
      let fieldClass = props.piSectionFormErrors[props.question.name]
        ? "form-control error"
        : "form-control";
      return (
        <div>
          <Row className="botLineWhite">
            {props.renderQuestionLabel(props.question)}
            <Col xs={12} sm={6} md={6} lg={6}>
              <textarea
                maxLength={props.question.maxLength ? props.question.maxLength : 10000}
                style={{ height: "60px" }}
                className={fieldClass}
                onBlur={(e) => props.knowledgeArticles(e)}
                name={props.question.name}
                onChange={(event) => validateField(props.question, event)}
              />
            </Col>
          </Row>
        </div>
      );
    } else if (props.question.responseType === "Multiselect List") {
      let fieldClass = props.piSectionFormErrors[props.question.name]
        ? "error form-control multiselect"
        : "form-control multiselect";
      let dropDownValues = props.question.responseValueList.map((option, index) => {
        return <option key={option + index}>{option}</option>;
      });
      return (
        <div>
          <div>
            <Row className="botLineWhite">
              {props.renderQuestionLabel(props.question)}
              <Col xs={12} sm={6} md={6} lg={6}>
                <select
                  multiple={true}
                  className={fieldClass}
                  name={props.question.name}
                  onChange={(event) => {
                    validateField(props.question, event);
                  }}
                >
                  {dropDownValues}
                </select>
              </Col>
            </Row>
          </div>
        </div>
      );
    } else if (props.question.responseType === "Radio button") {
      let childQuestions = [];
      if (props.question.parent_id === 0) {
        props.showChildQuestions(
          props.question,
          childQuestions,
          props.offering,
          props.dispatch
        );
      }
      let responseValues = [];
      if (props.question.tildArray && props.question.tildArray.length > 0) {
        let tildArray = props.question.tildArray[0].split("^");
        responseValues = tildArray.map((option, index) => {
          let actualOption = option.split("~");
          return {
            label: actualOption[0],
            id: actualOption[1],
          };
        });
      } else {
        responseValues = props.question.responseValueList.map((option, index) => {
          return {
            label: option,
            id: "",
          };
        });
      }
      return (
        <div>
          <Row className="botLineWhite">
            {props.renderQuestionLabel(props.question)}
            <Col xs={12} sm={6} md={6} lg={6}>
              <RadioCheckBoxGroup
                input={props.question.name}
                responseValues={responseValues}
                validateField={validateField}
                piSectionFormErrors={props.piSectionFormErrors}
                fieldType="radio"
                question={props.question}
                componentIds={props.componentIds}
              />
            </Col>
          </Row>

          {childQuestions.length !== 0 ? childQuestions : null}
        </div>
      );
    } else if (props.question.responseType === "Checkbox") {
      let childQuestions = [];
      if (props.question.parent_id === 0) {
        props.showChildQuestions(
          props.question,
          childQuestions,
          props.offering,
          props.dispatch
        );
      }
      return (
        <div>
          <div>
            <Row className="botLineWhite">
              {props.renderQuestionLabel(props.question)}
              <Col xs={12} sm={6} md={6} lg={6}>
                <RadioCheckBoxGroup
                  input={props.question.name}
                  handleChange={props.handleOnChange}
                  responseValues={props.question.responseValueList}
                  responseValueId={props.question.responseValueId}
                  validateField={validateField}
                  piSectionFormErrors={props.piSectionFormErrors}
                  question={props.question}
                  fieldType="checkbox"
                  piSectionFormValues={props.piSectionFormValues}
                />
              </Col>
            </Row>
          </div>
          {childQuestions.length !== 0 ? childQuestions : null}
        </div>
      );
    } else if (props.question.responseType === "Lookup Textbox") {
      let childQuestions = [];
      if (props.question.parent_id === 0) {
        props.showChildQuestions(
          props.question,
          childQuestions,
          props.offering,
          props.dispatch
        );
      }
      let fieldClass = props.piSectionFormErrors[props.question.name]
        ? "form-control error"
        : "form-control";
      return (
        <div>
          <div className="input-row">
            <Row className="botLineWhite">
              {props.renderQuestionLabel(props.question)}
              <Col xs={12} sm={6} md={6} lg={6}>
                <input
                  name={props.question.name}
                  type="text"
                  className={fieldClass}
                  onChange={(event) => {
                    validateField(props.question, event);
                    if (
                      event.target.value.length >=
                        props.question.callApiAfter &&
                      props.question.haveChild === "true"
                    )
                      props.getTypeAheadChildQuestion(
                        event.target.value,
                        props.question,
                        props.componentIds,
                        null
                      );
                  }}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
              </Col>
            </Row>
          </div>
          {childQuestions.length !== 0 ? childQuestions : null}
        </div>
      );
    } else if (props.question.responseType === "Display Text") {
      return (
        <div>
          <div className="input-row">
            <Row className="botLineWhite">
              <Col xs={12} sm={6} md={6} lg={6}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(validate_summary, {
                      allowedAttributes: {
                        "*": ["style", "class", "dir", "bgcolor"],
                        table: ["border", "cellpadding", "cellspacing"],
                        th: ["scope"],
                        a: ["href", "target"]
                      },
                    }),
                  }}
                />
                {/* <div dangerouslySetInnerHTML={{ __html: props.displayText }} /> */}
              </Col>
            </Row>
          </div>
        </div>
      );
    } else if (props.question.responseType === "Numeric Spinner") {
      let fieldClass = props.piSectionFormErrors[props.question.name]
        ? "form-control error"
        : "form-control";
      return (
        <div>
          <Row className="botLineWhite">
            {props.renderQuestionLabel(props.question)}
            <Col xs={12} sm={6} md={6} lg={6}>
              <div className="input-row">
                <InputNumberField
                  question={props.question}
                  className={fieldClass}
                  onChange={validateField}
                  piSectionFormValues={props.piSectionFormValues}
                />
                {props.piSectionFormErrors[props.question.name] && (
                  <span
                    style={{ color: "red" }}
                  >{`(Please enter value between ${props.question.minValue} and ${props.question.maxValue})`}</span>
                )}
              </div>
            </Col>
          </Row>
        </div>
      );
    } else if (props.question.responseType === "Dynamic Dropdown") {
      return (
        <div>
          <Row className="botLineWhite">
            {props.renderQuestionLabel(props.question)}
            <Col xs={12} sm={6} md={6} lg={6}>
              <div className="piPosArrow">
                {props.onBehalfOrder ? 
                <OnBehalfDynamicDropdown
                  questionnaire={props.question}
                  setAnswer={props.setAnswer}
                  dispatch={props.dispatch}
                  parentProps={props}
                  piSectionFormErrors={props.piSectionFormErrors}
                  setFormErrors={props.setFormErrors}
                />
                : <DynamicDropdown
                  questionnaire={props.question}
                  setAnswer={props.setAnswer}
                  dispatch={props.dispatch}
                  parentProps={props}
                  piSectionFormErrors={props.piSectionFormErrors}
                  setFormErrors={props.setFormErrors}
                />}
              </div>
            </Col>
          </Row>
        </div>
      );
    } else if (props.question.responseType === "Table") {
      let fields = [];
      let rowObj = {};
      props.question.responseValueList.map((value) => {
        rowObj[`${value}`] = "";
      });
      fields.push(rowObj);
      if (tableValues.length === 0) {
        setTableValues(fields);
      }
      let { question } = props;
      return (
        <div>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                {question.columns.map((columnHeader) => {
                  return (
                    <th key={columnHeader}>
                      {columnHeader}
                      {question.questionType === "mandatory" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : null}
                    </th>
                  );
                })}
                <th />
              </tr>
            </thead>
            <tbody>
              {tableValues.map((field, index) => {
                let rowCount = fields.length;
                return (
                  <tr key={`${field}.${index}`}>
                    {question.columns.map((fieldName, i) => {
                      let fieldClass = props.piSectionTableFormErrors[
                        `${fieldName}${index}`
                      ]
                        ? "form-control error"
                        : "form-control";
                      return (
                        <td key={i}>
                          <input
                            type="text"
                            maxLength={props.question.maxLength ? props.question.maxLength : 1000}
                            className={fieldClass}
                            name={`${fieldName}`}
                            onChange={(event) => {
                              let tableValuesState = tableValues;
                              tableValuesState[index][`${fieldName}`] =
                                event.target.value;
                              setTableValues(tableValuesState);
                              validateTableField(props, event, index);
                            }}
                            onKeyPress={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            value={tableValues[index][fieldName]}
                          />
                        </td>
                      );
                    })}
                    <td>
                      {
                        <div style={{ width: "60px" }}>
                          {index === tableValues.length - 1 ? (
                            <div>
                              <button
                                className="btn btn-primary"
                                type="button"
                                style={{ marginRight: "5px" }}
                                disabled={rowCount === 50}
                                onMouseEnter={(e) => {
                                  rowCount === 50
                                    ? swal({
                                        text: `you cannot add more than 50 ${question.categoryName}`,
                                        button: "OK",
                                      })
                                    : e.preventDefault();
                                }}
                                title={
                                  rowCount === 50
                                    ? "maximum limit reached"
                                    : `add more ${question.categoryName}`
                                }
                                onClick={() => {
                                  let fieldsState = structuredClone(tableValues);
                                  let rowObj = {};
                                  props.question.responseValueList.map((value) => {
                                    rowObj[`${value}`] = "";
                                  });
                                  fieldsState.push(rowObj);
                                  setTableValues(fieldsState);
                                }}
                              >
                                +
                              </button>
                              {index !== 0 ? (
                                <button
                                  className="btn btn-danger"
                                  type="button"
                                  title={`delete ${question.categoryName} row`}
                                  onClick={() => {
                                    let fieldsState = tableValues;
                                    let errors = tableFormErrors;
                                    fieldsState.splice(index, 1);
                                    Object.keys(errors).forEach((element) => {
                                      question.columns.forEach((key) => {
                                        if (
                                          [`${key}${index}`] == element &&
                                          errors[element] === true
                                        ) {
                                          delete errors[element];
                                        }
                                      });
                                    });
                                    setTableFormErrors(errors);
                                    props.setPISectionTableFormErrors(errors);
                                    setTableValues(fieldsState);
                                  }}
                                >
                                  -
                                </button>
                              ) : null}
                            </div>
                          ) : (
                            <button
                              className="btn btn-danger"
                              type="button"
                              title={`delete ${question.categoryName} row`}
                              onClick={() => {
                                let fieldsState = tableValues;
                                let errors = tableFormErrors;
                                fieldsState.splice(index, 1);
                                Object.keys(errors).forEach((element) => {
                                  question.columns.forEach((key) => {
                                    if (
                                      [`${key}${index}`] == element &&
                                      errors[element] === true
                                    ) {
                                      delete errors[element];
                                    }
                                  });
                                });
                                setTableFormErrors(errors);
                                props.setPISectionTableFormErrors(errors);
                                setTableValues(fieldsState);
                              }}
                            >
                              -
                            </button>
                          )}
                        </div>
                      }
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      );
    }
  };

  return <div>{renderMasterQuestionsFields(props)}</div>;
};

export default PISectionFields;
