
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState, useEffect } from "react";
import { Row, Col, Table, Button } from "react-bootstrap";
import {
  _textArea,
  _dropDown,
  _inputField,
  _number,
} from "../../../common/ReduxFormFields/CategoryBoard/formFields";
import { bindActionCreators } from "redux";
import { loadCIClass, loadCICompany } from "../../../../actions/cmdb/lovAction";
import {
  getCICategoryListData,
  emptyCICategoryListDataReducer,
} from "../../../../actions/categoryBoard/quickViewAction";
import Form from "react-bootstrap/Form";
import swal from "sweetalert";
import { Field, reduxForm, change } from "redux-form";
import { useSelector, useDispatch } from "react-redux";
import { isArray } from "lodash";
import { PiToggleRightFill, PiToggleLeftFill } from 'react-icons/pi';
import { HiPlus, HiOutlineTrash } from "react-icons/hi";
import { getCompanyMenuListData } from "../../../../actions/companyMenuList/companyMenuListAction.js";

const required = (value) => {
  if (!value || (value && value.trim().length === 0)) {
    return "required";
  }
};
const requiredNum = (value) => {
  if ((value && value.includes(".")) || value == "" || !(parseInt((value),10) >= 1 && parseInt((value),10) <= 100))  {
    return "Please enter values between 1 and 100";
  }
};

let CreateMenuListForm = (props) => {
  const dispatch = useDispatch();

  const [catStatus, setCatStatus] = useState(true);
  const [arrayCounts, setArrayCounts] = useState(1);
  const [extraCounts, setExtraCounts] = useState([0]);
  const [catStatuses, setCatStatuses] = useState([true]);
  const [createUsing, setCreateUsing] = useState("new");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedModule, setSelectedModule] = useState("");

  const companyMenuListDropdownData = useSelector(state => state.companyMenuListData.companyMenuListDropDownData);
  const companyMenuListFieldDropDownData = useSelector(state => state.companyMenuListData.companyMenuListFieldDropDownData);
  const ciCompany = useSelector(state => state.ciCompany);
  const ciCategoryListData = useSelector(state => state.ciCategoryListData);
  // const languageDropdown = ["English", "German", "Kritish"];
  const languageDropdown = [{text: "English", value : "en"},
                            {text: "German", value : "de"},{text: "French", value : "fr"}];
  useEffect(() => {
    dispatch(loadCICompany("0"));
    setExtraCounts([0]);
    dispatch(change("CreateMenuListForm", "status", [catStatus]));
    return () => {
      dispatch(emptyCICategoryListDataReducer());
    };
  }, []);

  const handleStatusChange = (i) => {
    let updatedStatuses = [...catStatuses];
    updatedStatuses[i] = !updatedStatuses[i];
    setCatStatuses(updatedStatuses);
    dispatch(change("CreateMenuListForm", "status", updatedStatuses));
  };

  const updateCatStatus = () => {
    setCatStatus(!catStatus);
  };

  const addRow = () => {
    if (extraCounts.length < 10) {
      let newStatuses = [...catStatuses, true];
      let newArrayCounts = extraCounts.slice();
      newArrayCounts.push(arrayCounts);
      setExtraCounts(newArrayCounts);
      setArrayCounts(arrayCounts + 1);
      setCatStatuses(newStatuses);
      dispatch(change("CreateMenuListForm", "status", newStatuses));
    } else {
      swal({
        text: "Cannot create more than 10 records",
        button: "OK",
      });
    }
  };

  const deleteRow = (myIndex) => {
    let newArrayCounts = [...extraCounts];
    let index = newArrayCounts.indexOf(myIndex);
    newArrayCounts.splice(index, 1);
    let newStatusArr = [...catStatuses];
    newStatusArr.splice(index, 1);
    setExtraCounts(newArrayCounts);
    setCatStatuses(newStatusArr);
  };

  const loadCount = () => {
    const rowlength = extraCounts.length;
    props.setRowCount(extraCounts);
    return extraCounts.map((extraCount, i) => {
      if (rowlength === i + 1) {
        return (
          <tr key={i}>
            <td>
              {" "}
              <Form.Group>
                {(
                  <Field
                    className="form-control"
                    component={_inputField}
                    maxLength="50"
                    name={"key" + extraCount}
                    validate={[required]}
                  />
                )}
              </Form.Group>
            </td>
            <td>
              <Form.Group>
                <Field
                  component={_number}
                  min={1} max={100}
                  name={"value" + extraCount}
                  className={"form-control"}
                  validate={[required]}
                />
              </Form.Group>
            </td>
            <td>
              <Form.Group>
                <Field
                  component={_number}
                  min={1} max={100}
                  name={"sequence" + extraCount}
                  className={"form-control"}
                  validate={[requiredNum]}
                />
              </Form.Group>
            </td>
            <td>
              {" "}
              <Form.Group>
                {(
                  <Field
                    className="form-control"
                    component={_dropDown}
                    maxLength="50"
                    name={"languageMenuList" + extraCount}
                    options={languageDropdown}
                    validate={[required]}
                  />
                )}
              </Form.Group>
            </td>
            <td>
              {extraCounts.length === 1 && (
                <Button
                  title="Add"
                  className="ciCrtCatBtn"
                  onClick={() => {
                    addRow();
                  }}
                >
                  <HiPlus />
                </Button>
              )}
              {extraCounts.length > 1 && (
                <>
                  <Button
                    title="Add"
                    className="ciCrtCatBtn"
                    onClick={() => {
                      addRow();
                    }}
                  >
                    <HiPlus />
                  </Button>
                  <Button
                    title="Delete"
                    className="ciCrtCatBtn"
                    onClick={() => {
                      deleteRow(extraCount);
                    }}
                  >
                    <HiOutlineTrash />
                  </Button>
                </>
              )}
            </td>
          </tr>
        );
      } else {
        return (
          <tr key={i}>
            <td>
              {(
                <Field
                  component={_inputField}
                  maxLength="50"
                  name={"key" + extraCount}
                  className="form-control"
                  validate={[required]}
                />
              )}
            </td>
            <td>
              <Field
                component={_number}
                name={"value" + extraCount}
                className="form-control"
                min={1} max={100}
                validate={[required]}
              />
            </td>
            <td>
              <Field
                component={_number}
                name={"sequence" + extraCount}
                className="form-control"
                min={1} max={100}
                validate={[requiredNum]}
              />
            </td>
            <td>
              <Field
                component={_dropDown}
                name={"languageMenuList" + extraCount}
                className="form-control"
                options={languageDropdown}
                maxLength="50"
                validate={[required]}
              />
            </td>
            <td>
              <Button
                title={catStatuses[i] ? "Active" : "Inactive"}
                name={"status"}
                onClick={() => handleStatusChange(i)}
                className={
                  catStatuses[i] ? "theme1 ciCrtCatBtn" : "ciCrtCatBtn"
                }
              >
                {catStatuses[i] ? <PiToggleRightFill /> : <PiToggleLeftFill />}
              </Button>
              <Button
                title="Delete"
                className="ciCrtCatBtn"
                onClick={() => {
                  deleteRow(extraCount);
                }}
              >
                <HiOutlineTrash />
              </Button>
            </td>
          </tr>
        );
      }
    });
  };

  const handleciCompanyChange = (event) => {
    let companyId =
      event.target.value !== ""
        ? JSON.parse(event.target.value).field1Value
        : "";
    setSelectedCompany(companyId);
    dispatch(
      change("CreateMenuListForm", "companyName", event.target.company)
    );
    dispatch(
      change("CreateMenuListForm", "menuListModule", '')
    );
    dispatch(
      change("CreateMenuListForm", "menuListField", '')
    );
    dispatch(getCompanyMenuListData(companyId));
    if (selectedModule !== "") {
      dispatch(getCICategoryListData(
        companyId,
        selectedModule,
        "both"
      ));
    }
  };

  const handleModuleChange = (event) => {
    setSelectedModule(event.target.value);
    if (selectedCompany !== "") {
      dispatch(getCompanyMenuListData(selectedCompany, event.target.value));
      // dispatch(getCICategoryListData(
      //   selectedCompany,
      //   event.target.value,
      //   "both"
      // ));
    }
  };

  return (
    <div>
      <Row>
        <Col md={4}>
          <Form.Group bsPrefix=" ">
            <Form.Label bsClass="">
              <span className="rStar"></span> {"Company"}
            </Form.Label>
            <Field
              name="ciCompany"
              className="form-control"
              component={_dropDown}
              options={ciCompany}
              validate={[required]}
              onChange={(event) => handleciCompanyChange(event)}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group bsPrefix=" ">
            <Form.Label bsClass="">
              <span className="rStar"></span> {"Module"}
            </Form.Label>
            <Field
              name="menuListModule"
              className="form-control"
              component={_dropDown}
              options={companyMenuListDropdownData || []}
              validate={[required]}
              onChange={(event) => handleModuleChange(event)}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group bsPrefix=" ">
            <Form.Label bsClass="">
              <span className="rStar"></span> {"Field"}
            </Form.Label>
            <Field
              name="menuListField"
              className="form-control"
              component={_dropDown}
              options={companyMenuListFieldDropDownData ||  []}
              validate={[required]}
              // onChange={(event) => handleModuleChange(event)}
            />
          </Form.Group>
        </Col>
      </Row>
      <div className="bgGray padding-5 margin-t-15" bsPrefix=" ">
        <span className="rStar"></span> {"Details"}
      </div>
      <div className="rBoxStyle commonForm">
        <Table>
          <thead>
            <tr>
              <th width="24%">{"Key"}</th>
              <th width="24%">{"Value"}</th>
              <th width="24%">{"Sequence"}</th>
              <th width="21%">{"Language"}</th>
            </tr>
          </thead>
          <tbody>{loadCount()}</tbody>
        </Table>
      </div>
    </div>
  );
};

CreateMenuListForm = reduxForm({
  form: "CreateMenuListForm",
  enableReinitialize: true,
})(CreateMenuListForm);

export default CreateMenuListForm;
