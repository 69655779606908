
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Row, Col, Button, Nav, NavDropdown } from "react-bootstrap";
import { TbLayoutGrid } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import GeneralSection from "./LeftPanelView/GeneralSection";
import DynamicNestedMenu from "./LeftPanelView/DynamicNestedMenu";
const cookies = new Cookies();
import Cookies from 'universal-cookie';
import ListLoader from "../../common/loaders/ListLoader";
let hometab_preference = cookies.get('gph');
if (hometab_preference) hometab_preference = hometab_preference.replace('s:', '');
if (hometab_preference) hometab_preference = hometab_preference.substring(0, hometab_preference.lastIndexOf('.'));
hometab_preference = hometab_preference.split("~");
let catwise = hometab_preference[15];
const VerticalCatalogHeader = ({ activeTab, pageViewFun, offeringsCount,
  isView,
  businessFnLabels,
  isFetchingBusinessFn,
  setActiveTab,
  setActiveTabEventKey,
  setActiveTabId,
  businessFnApiError,
  url,
  labelType,
  isGeneralSection,
  start,
  end,
  parentLabel,
  parentLabelId,
  parentLabelType,
  activeMenuSubMenuItems,
  udpateActiveMenuSubMenuItems,
 }) => {
  const dispatch = useDispatch();
  const tr = useSelector((state) => state.spcmReducer.tr);

  return (
    <>
      <Row>
        <Col xs={12}>
          <div className="homeservices">
            <div className="hTabhed d-sm-block d-none">
              <h3 className="tabActive">{activeTab} ({offeringsCount})</h3>
            </div>
            <div className="d-sm-none d-xs-block categryListDv categryListDropdown">
              <Nav as="ul" variant="tab" activeKey="1">
                  <NavDropdown title={<span>{`${activeTab} (${offeringsCount})`}</span>} id="nav-dropdown">
                      {isFetchingBusinessFn ? (
                          <ListLoader />
                        ) : (
                          <>
                            <GeneralSection
                              businessFnLabels={businessFnLabels}
                              isFetchingBusinessFn={isFetchingBusinessFn}
                              setActiveTab={setActiveTab}
                              setActiveTabEventKey={setActiveTabEventKey}
                              setActiveTabId={setActiveTabId}
                              businessFnApiError={businessFnApiError}
                              udpateActiveMenuSubMenuItems={udpateActiveMenuSubMenuItems}
                            />
                          {catwise == 'Y' || catwise == 'y' ?

                          <p>{tr["All Categories"]}</p> : null}
                          {catwise == 'Y' || catwise == 'y' ?
                          (
                            <DynamicNestedMenu
                              businessFnLabels={businessFnLabels}
                              isFetchingBusinessFn={isFetchingBusinessFn}
                              setActiveTab={setActiveTab}
                              setActiveTabEventKey={setActiveTabEventKey}
                              setActiveTabId={setActiveTabId}
                              businessFnApiError={businessFnApiError}
                              url={url}
                              labelType={labelType}
                              isGeneralSection={isGeneralSection}
                              start={start}
                              end={end}
                              parentLabel={parentLabel}
                              parentLabelId={parentLabelId}
                              parentLabelType={parentLabelType}
                              activeMenuSubMenuItems={activeMenuSubMenuItems}
                              udpateActiveMenuSubMenuItems={udpateActiveMenuSubMenuItems}
                            />
                        ) : null}
                          </>
                        )}
                  </NavDropdown>
              </Nav>
            </div>
            <div className="filterBox">
              <div className="pageviews">
                <Button
                  className={isView ? "" : "active"}
                  id=""
                  variant="link"
                  title={tr["Grid View"]}
                  onClick={() => {pageViewFun(true); dispatch({ type: "GRID_VIEW_CATALOG" });}}
                >
                  <TbLayoutGrid />
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default VerticalCatalogHeader;
