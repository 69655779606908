
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';

import axios from 'axios';
import { bindActionCreators } from 'redux';
import { loadCriteriaList,loadReportFields} from '../../actions/reports/reportsAction';
import {GLOBAL} from '_Globals';
import constanturl from '../../utils/constants.js';
import { connect } from 'react-redux';
import ListLoader from "../common/loaders/ListLoader";
import Datetime from 'react-datetime';
import swal from 'sweetalert';
import Swal from 'sweetalert2';


class ReportConsoleCriteria extends React.Component{
  constructor(props){
	super(props);
		this.state = {
			formToggle:false,
			formeditToggle:false,
			formaddToggle:true,
      attname:'',
			attval:'',
			attoperator:'',
      attid:'',
      selectedattname:'',
      savebuttonshow:'',
      reportOptVal:"0",
      startDate:'',
      endDate:''

      
		};
    GLOBAL.reportattname="";
		GLOBAL.reportattval="";
    GLOBAL.reportattoperator="";
    GLOBAL.editreportattval="";
		this.formToggleFun = this.formToggleFun.bind(this);
		this.formCancelFun = this.formCancelFun.bind(this);
		this.formeditToggleFun = this.formeditToggleFun.bind(this);
    this.camelize = this.camelize.bind(this);
    this.humanize = this.humanize.bind(this);
    this.renderTaskCMDB = this.renderTaskCMDB.bind(this);
    this.changeStartDate = this.changeStartDate.bind(this);
    this.changeEndDate = this.changeEndDate.bind(this);
    
  }
  
  renderTaskCMDB(){
    if(this.props.module == "Task"){
      return (
        this.state.selectedattname == 'createdDate' ?
        <Col md={4}>
        <FormGroup className="margin-b-5">
          <Form.Label>{this.props.translator['Operator']}</Form.Label>
          <Form.Select componentClass="select" name="attributeoperator" id="attributeoperator"
           onChange={(event) => { this.setAttOperator(event) }} value={this.state.reportOptVal}>
            <option value="0">select</option>
            <option value="between">Between</option>
          </Form.Select>
        </FormGroup>
      </Col>
        :
      <Col md={4}>
        <FormGroup className="margin-b-5">
          <Form.Label>{this.props.translator['Operator']}</Form.Label>
          <Form.Select componentClass="select" name="attributeoperator" 
          id="attributeoperator" onChange={(event) => { this.setAttOperator(event) }} value={this.state.reportOptVal}>
            <option value="0">select</option>
            <option value="in">&#61;</option>
          </Form.Select>
        </FormGroup>
      </Col>
        
      )
    }
    if(this.props.module == "CMDB"){
      return (
        <Col md={4}>
          <FormGroup className="margin-b-5">
            <Form.Label>{this.props.translator['Operator']}</Form.Label>
            <Form.Select componentClass="select" name="attributeoperator" id="attributeoperator" onChange={(event) => { this.setAttOperator(event) }}>
              <option value="0">select</option>
              <option value="like">like</option>
            </Form.Select>
          </FormGroup>
        </Col>
        )
    }

  }

	formToggleFun(value){
		this.setState({formToggle:value});
		this.setState({formeditToggle:false})
		this.setState({formaddToggle:false})
	};

	formCancelFun(value1){
		this.setState({formToggle:false});
		this.setState({formeditToggle:false})
		this.setState({formaddToggle:value1})
	};

	formeditToggleFun(value,attname,attoperator,attvalue,attid,attribute=''){
		//alert(i)
		this.setState({formeditToggle:value});
		this.setState({formToggle:false})
		this.setState({formaddToggle:false})
    this.setState({attname:attname})
		this.setState({attval:attvalue})
		this.setState({attoperator:attoperator})
    this.setState({attid:attid})

    if ((attribute == "created_on" || attribute == "closed_on" || attribute == "expected_date" || attribute == "modified_on"
     || attribute == "reported_on" || attribute == "act_end_date" || attribute == "act_start_date" || attribute == "d_end_date" 
     || attribute == "d_start_date" || attribute == "exp_end_date" || attribute == "exp_start_date" || attribute == "expected_date" 
     || attribute == "expdelivery_date" || attribute=="approved_on" ||
     attribute=="cancelled_on" || attribute=="completion_date" || attribute=="rejected_on"
      || attribute == 'createdDate')
      && attoperator == 'between') {
    console.log("attoperator inside ",attoperator," : ",attvalue)
    let dateformat=this.props.date_format;
    console.log("dateformat ",dateformat)
      let sd = attvalue.split('&').slice(0, -1).join(' ');
      let ed = attvalue.split('&').slice(-1).join(' ');
        // sd = moment(sd).format(dateformat.slice(0, 10).toUpperCase());
       sd = moment(sd,"YYYY-MM-DD").format(dateformat.slice(0, 10).toUpperCase());
        // ed = moment(ed).format(dateformat.slice(0, 10).toUpperCase());
        ed =  moment(ed,"YYYY-MM-DD").format(dateformat.slice(0, 10).toUpperCase())
        console.log("dateformat ",sd)
        console.log("dateformat ",ed)

        this.setState({startDate:sd, endDate:ed})

    }

    GLOBAL.editreportattval=attvalue

	}

  componentWillMount(){
   // alert(this.props.user_id)
this.props.loadCriteriaList(this.props.module,this.props.user_id);
this.props.loadReportFields(this.props.moduleid);
}


  changeStartDate(e) {
    let dateformat=this.props.date_format;
    let dateval=moment(e, [dateformat.slice(0, 10).toUpperCase()]).format(dateformat.slice(0, 10).toUpperCase());
    this.setState({startDate:dateval});
    // startDate endDate
  }

  changeEndDate(e){
  let dateformat=this.props.date_format;
  let dateval=moment(e, [dateformat.slice(0, 10).toUpperCase()]).format(dateformat.slice(0, 10).toUpperCase());
  this.setState({endDate:dateval});
}

camelize(str) {
return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
 if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
 return index == 0 ? match.toLowerCase() : match.toUpperCase();
});
}
humanize(str) {
  let frags = str.split(' ');
  for (let i=0; i<frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
}

renderAtt(attListData){
  if(attListData.length==undefined){
  return (
      <tr><td colSpan="4" className='text-c padding-10'><ListLoader /></td></tr>
    );
  }

  let attribute=[];
  let operator=[];
  let value=[];
  let criteriaObj=[]

for (let i=0;i<attListData.length;i++)
  {
    let atributeval= "";
    if(this.props.module == "CMDB"){
      atributeval = attListData[i].attribute;
    }else{
      atributeval=attListData[i].attribute.replace(/_/g , " ");
    }
    
    //console.log("sbllower::"+atributeval+" after camel::"+this.camelize(atributeval.toLowerCase()))
    let attributepojo = "";
    if(this.props.module == "Task" || this.props.module == "CMDB"){
      attributepojo=atributeval;
    }else{
      attributepojo=this.camelize(atributeval.toLowerCase())
    }

  attribute.push(attributepojo)
  operator.push(attListData[i].operator)
  value.push(attListData[i].value.replace(/,/g , "~"))
  let criteriaObjpick={attributename:'',attributeval:''}
  criteriaObjpick.attributename=this.humanize(attListData[i].attribute.replace(/_/g , " "));
  criteriaObjpick.attributeval=attListData[i].value;
  criteriaObj.push(criteriaObjpick);

  }


  //console.log("att"+attribute.toString()+"operator"+operator.toString()+"value"+value.toString())
  this.props.getCriteria(attribute.toString(),operator.toString(),value.toString(),criteriaObj);

  return attListData.map((attObj)=>{
    let resultVal = '';

    if((attObj.attribute=="created_on" ||attObj.attribute=="closed_on" || attObj.attribute=="expected_date"
    ||attObj.attribute=="modified_on" ||attObj.attribute=="reported_on" ||attObj.attribute=="act_end_date" 
    ||attObj.attribute=="act_start_date" ||attObj.attribute=="d_end_date" ||attObj.attribute=="d_start_date" 
    ||attObj.attribute=="exp_end_date" ||attObj.attribute=="exp_start_date" ||attObj.attribute=="expected_date" 
    ||attObj.attribute=="expdelivery_date" || attObj.attribute == 'createdDate'|| attObj.attribute=="approved_on" ||
    attObj.attribute=="cancelled_on" || attObj.attribute=="completion_date" || attObj.attribute=="rejected_on" )
   && attObj.operator == 'between'){
     console.log(" attObj.attribute ",attObj.attribute," ",attObj.operator )
     console.log("attObj.value ",attObj.value)
     let dateformat=this.props.date_format;
     console.log("dateformat ",dateformat)
       let sd = attObj.value.split('&').slice(0, -1).join(' ');
       let ed = attObj.value.split('&').slice(-1).join(' ');
        //  sd = moment(sd).format(dateformat.slice(0, 10).toUpperCase());
        //  ed = moment(ed).format(dateformat.slice(0, 10).toUpperCase());
         sd = moment(sd,"YYYY-MM-DD").format(dateformat.slice(0, 10).toUpperCase());
         ed = moment(ed,"YYYY-MM-DD").format(dateformat.slice(0, 10).toUpperCase());
         resultVal = sd+" To "+ed;
    //  resultVal = attObj.value.replace(/&/g , " To ");
   }else{
     resultVal = attObj.value;
   }

   //alert(attObj.attribute)
  

    return(
      <tr>
      { attObj.attribute=="breakfix_number"?<td>Incident Number</td>: <td>{this.humanize(attObj.attribute.replace(/_/g , " "))}</td>}
        <td>{attObj.operator}</td>
        <td>{resultVal}</td>
        <td className="text-c f-size-13"><i onClick={()=>{this.formeditToggleFun(true,this.humanize(attObj.attribute.replace(/_/g , " ")),attObj.operator,attObj.value,attObj.criteriaId,attObj.attribute)}} className="fa fa-edit margin-r-10 cursorPoint"></i><i onClick={() => {
          Swal.fire({
            title: "Are you sure you wish to delete this item?",
		        
            width: 550,
            padding: '15px',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
          }).then((result) => {
            if (result.isConfirmed) {
              this.attdelete(attObj.criteriaId);
            } else if (result.isDenied) {
              
            }
          });
        }} className="fa fa-trash-o cursorPoint"></i></td>
      </tr>

      );



  });
}

renderAttList(attData){
//  console.log(attData.length)

  let listdata=[];

  if(!attData){
    return(
        <option></option>
    );
  }
let splitattData=[];
let attDataKey=[];
let attDataValue=[];


  for (let i=0;i<attData.length;i++)
  {
    splitattData=attData[i].split("|")
    attDataKey=splitattData[0];
    attDataValue=splitattData[1];
    let myObj={key:'',value:''}
      myObj.key=attDataKey;
      myObj.value=attDataValue.replace(/_/g , " ");
    
    listdata.push(myObj)
  }
  //console.log(listdata);
  return listdata.map((attObj)=>{
//console.log(attObj);
    return(
<option value={attObj.key}>{attObj.value}</option>

)


  });

}


setAttName(e)
{
  GLOBAL.reportattname=e.target.value
  console.log("e.target.value::.."+e.target.value)
  this.setState({selectedattname:e.target.value});
  if(e.target.value!="" && e.target.value!="Select" && e.target.value!="0")
  this.setState({savebuttonshow:"show"});
  else {
    this.setState({savebuttonshow:"hide"});
  }
  this.setState({reportOptVal:"0"})
  GLOBAL.reportattoperator="0";
  this.setState({startDate:'', endDate:''});

  // this.setAttOperator("0");
  // this.setState({startDate:'', endDate:''});
}
setAttVal(e)
{
  GLOBAL.reportattval=e.target.value
}
setDateAttVal(val)
{
  let dateformat=this.props.date_format;
  let dateval=moment(val, [dateformat.slice(0, 10).toUpperCase()]).format(dateformat.slice(0, 10).toUpperCase());
  console.log("dateval::::::"+dateval)
  //console.log("datevalmoment::::::"+moment(val, [dateformat.slice(0, 10).toUpperCase()]).format(dateformat.slice(0, 10).toUpperCase()))

  GLOBAL.reportattval=dateval
}
setDateEditAttVal(val)
{

  this.setState({attval: val});
  let dateformat=this.props.date_format;
  let dateval=moment(val, [dateformat.slice(0, 10).toUpperCase()]).format(dateformat.slice(0, 10).toUpperCase());


  //console.log("datevalmoment::::::"+moment(val, [dateformat.slice(0, 10).toUpperCase()]).format(dateformat.slice(0, 10).toUpperCase()))

  GLOBAL.reportattval=dateval
}
setAttOperator(e)
{console.log("e.target.value ",e.target.value)
this.setState({reportOptVal:e.target.value})
  GLOBAL.reportattoperator=e.target.value
  this.setState({startDate:'', endDate:''});
}

showErrorMsg(msg){
  swal({
    text: msg,
    button:'OK'
  })
}

  saveatt() {

    console.log("this.state.reportOptVal>>>> ",this.state.reportOptVal)
    let errorsMsg ='';
    let dateformat=this.props.date_format;
    console.log("dateformat>>>> ",dateformat)

    if(this.state.reportOptVal == "0"){
      errorsMsg = 'Please select the Operator.';
      this.showErrorMsg(errorsMsg);
      return false;
    }
    // console.log("name::" + GLOBAL.reportattname + " opt:" + GLOBAL.reportattoperator + " val:" + GLOBAL.reportattval)

    console.log(" startDate endDate ", this.state.startDate, " :: ", this.state.endDate);
    // console.log("reportOptVal ", this.state.reportOptVal);

      let startDate = this.state.startDate;
      let endDate = this.state.endDate;

      if ((this.state.selectedattname=="created_on" ||this.state.selectedattname=="closed_on" 
      || this.state.selectedattname=="expected_date" || this.state.selectedattname=="modified_on" ||
       this.state.selectedattname=="reported_on" || this.state.selectedattname=="act_end_date" 
      || this.state.selectedattname=="act_start_date" || this.state.selectedattname=="d_end_date" ||
       this.state.selectedattname=="d_start_date" || this.state.selectedattname=="exp_end_date" ||
        this.state.selectedattname=="exp_start_date" || this.state.selectedattname=="expected_date"
        || this.state.selectedattname=="expdelivery_date" || this.state.selectedattname == 'createdDate'
        || this.state.selectedattname=="approved_on" || this.state.selectedattname=="cancelled_on" ||
         this.state.selectedattname=="completion_date" || this.state.selectedattname=="rejected_on"
         )
       && this.state.reportOptVal == 'between') {

      if (this.state.startDate == '') {
        startDate = moment().format('YYYY-MM-DD');
      } else {
        startDate = moment(startDate,dateformat.slice(0, 10).toUpperCase()).format("YYYY-MM-DD");
        // startDate = moment(startDate).format('YYYY-MM-DD');
      }

      if (this.state.endDate == '') {
        endDate = moment().format('YYYY-MM-DD');
      } else {
        endDate = moment(endDate,dateformat.slice(0, 10).toUpperCase()).format("YYYY-MM-DD");
        // endDate = moment(endDate).format('YYYY-MM-DD');
      }

      // validation ----------
      if (startDate || endDate) {
        let errorsMsg = '';

        if (moment(startDate).isAfter(moment(endDate)) ) {
          errorsMsg = 'End date should fall on or after start date.';
          this.showErrorMsg(errorsMsg);
          return false;
        }
      }
  }
  console.log("result startDate endDate ", startDate, " :: ", endDate);


    // this.state.reportOptVal == 'between' ? : ;
let ref=this;
let formVal = GLOBAL.reportattval;

if ((this.state.selectedattname == "created_on" || this.state.selectedattname == "closed_on"
 || this.state.selectedattname == "expected_date" || this.state.selectedattname == "modified_on" || 
 this.state.selectedattname == "reported_on" || this.state.selectedattname == "act_end_date" 
 || this.state.selectedattname == "act_start_date" || this.state.selectedattname == "d_end_date" 
 || this.state.selectedattname == "d_start_date" || this.state.selectedattname == "exp_end_date" 
 || this.state.selectedattname == "exp_start_date" || this.state.selectedattname == "expected_date" 
 || this.state.selectedattname == "expdelivery_date" || this.state.selectedattname == 'createdDate'
 || this.state.selectedattname=="approved_on" || this.state.selectedattname=="cancelled_on" ||
   this.state.selectedattname=="completion_date" || this.state.selectedattname=="rejected_on" )
  && this.state.reportOptVal == 'between') {
  formVal = startDate + "&" + endDate
} else {
  formVal = GLOBAL.reportattval;
}

console.log("formVal ",formVal);

axios.post("/rest/reportCriteriaAction", {
  "attribute": GLOBAL.reportattname,
  "module": this.props.module,
  "operator": GLOBAL.reportattoperator,
  "value": formVal,
  "userId":this.props.user_id

}).
then((response) =>{

ref.setState({formToggle:false})
ref.setState({formeditToggle:false})
this.setState({formaddToggle:true})
GLOBAL.reportattname="";
GLOBAL.reportattval="";
GLOBAL.reportattoperator="";

//  this.props.loadCIAtt(this.props.ciId);
this.props.loadCriteriaList(this.props.module,this.props.user_id);
});

}

attdelete(attid)
{

let ref=this
axios.delete("/rest/deletereportCriteriaAction/"+attid).
then((response) =>{

ref.setState({formToggle:false})
ref.setState({formeditToggle:false})
  this.props.loadCriteriaList(this.props.module,this.props.user_id);
});

}

submitatt()
{

  // console.log("name::" + GLOBAL.reportattname + " opt:" + GLOBAL.reportattoperator + " val:" + GLOBAL.reportattval)
  let dateformat=this.props.date_format;
  console.log("dateformat>>>> ",dateformat)
  console.log(" edit startDate endDate ", this.state.startDate, " :: ", this.state.endDate);
  // console.log("reportOptVal ", this.state.reportOptVal);

    let startDate = this.state.startDate;
    let endDate = this.state.endDate;

    if (( this.state.attname=="Created On" ||this.state.attname=="Closed On" ||
     this.state.attname=="Expected Date" || this.state.attname=="Act End Date" || 
     this.state.attname=="Act Start Date" || this.state.attname=="D End Date" ||
     this.state.attname=="D Start Date" || this.state.attname=="Exp End Date" || 
     this.state.attname=="Exp Start Date" || this.state.attname=="Modified On" ||
      this.state.attname=="Expected Date" || this.state.attname=="Closed On" ||
       this.state.attname=="Completion Date" || this.state.attname=="Expdelivery Date" 
     || this.state.attname=="Rejected On" || this.state.attname=="Reported On" || this.state.attname == 'Cancelled On' 
    || this.state.attname == 'CreatedDate' ||  this.state.attname == 'Approved On')
     && this.state.attoperator == 'between') {

    if (this.state.startDate == '') {
      startDate = moment().format('YYYY-MM-DD');
    } else {
      startDate = moment(startDate,dateformat.slice(0, 10).toUpperCase()).format("YYYY-MM-DD");
      // startDate = moment(startDate).format('YYYY-MM-DD');
    }

    if (this.state.endDate == '') {
      endDate = moment().format('YYYY-MM-DD');
    } else {
      endDate = moment(endDate,dateformat.slice(0, 10).toUpperCase()).format("YYYY-MM-DD");
      // endDate = moment(endDate).format('YYYY-MM-DD');
    }
    console.log(" SD ED ", startDate, " :: ", endDate);

    // validation ----------
    if (startDate || endDate) {
      let errorsMsg = '';

      if (moment(startDate).isAfter(moment(endDate))) {
        errorsMsg = 'End date should fall on or after start date.';
        this.showErrorMsg(errorsMsg);
        return false;
      }
    }
}


  // this.state.reportOptVal == 'between' ? : ;
let ref=this;
let formVal = GLOBAL.editreportattval;

  if ((this.state.attname == "Created On" || this.state.attname == "Closed On" || this.state.attname == "Expected Date" ||
    this.state.attname == "Act End Date" || this.state.attname == "Act Start Date" || this.state.attname == "D End Date" ||
    this.state.attname == "D Start Date" || this.state.attname == "Exp End Date" ||
    this.state.attname == "Exp Start Date" || this.state.attname == "Modified On" || this.state.attname == "Expected Date"
    || this.state.attname == "Closed On" || this.state.attname == "Completion Date" || this.state.attname == "Expdelivery Date"
    || this.state.attname == "Rejected On" || this.state.attname == "Reported On" || this.state.attname == 'Cancelled On' 
    || this.state.attname == 'CreatedDate' || this.state.attname == 'Approved On')
    && this.state.attoperator == 'between') {
    formVal = startDate + "&" + endDate
  } else {
    if(GLOBAL.reportattval=="")
    GLOBAL.reportattval=GLOBAL.editreportattval;
    formVal = GLOBAL.reportattval;
  }

axios.patch("/rest/patchreportCriteriaAction/"+ref.state.attid, {

  "value":formVal

}).
then((response) =>{

GLOBAL.reportattname="";
GLOBAL.reportattval="";
GLOBAL.reportattoperator="";
ref.setState({formToggle:false})
ref.setState({formeditToggle:false})
this.setState({formaddToggle:true})
  this.props.loadCriteriaList(this.props.module,this.props.user_id);
});

}


render(){
		//alert(this.state.formToggle+":"+this.state.formaddToggle)

    let dateformat = this.props.date_format;
    // console.log('dateformat ================'+dateformat);

    let jwtdf = 'MM-DD-YYYY';

    let dateFormatFirstItem = null;
    if(dateformat) {
      let dateFormatCopy = Object.assign(dateformat);
      if(dateFormatCopy){
        let dateFormatArr = dateFormatCopy.split(' ');
        if(dateFormatArr && dateFormatArr.length){
          dateFormatFirstItem = (dateFormatArr[0]).toUpperCase();
          jwtdf = dateFormatFirstItem;
        }
      }
    }

		return(
			<div>
				<div className="f-size-18 margin-b-5">
        <Row>
						<Col md={3} sm={4} xs={12}>
              <h2 className="margin-0 margin-b-5 display-inline-block margin-r-10">{this.props.translator['Specify Criteria']}</h2>
              {this.state.formaddToggle?
              <ButtonToolbar className="toolbarBtnStyle text-r display-inline-block">
                <Button title={this.props.translator['Add']} className='rgSidrkBtn' onClick={()=>{this.formToggleFun(true)}} variant="primary" size="xs"><i className="fa fa-plus" aria-hidden="true"></i></Button>
              </ButtonToolbar>:null}
						</Col>
					</Row>

        </div>

				{this.state.formaddToggle?
				<div>
					<Table bordered condensed hover responsive className="tableView margin-0">
          <thead>
            <tr>
              <th width="40%">{this.props.translator['Attribute']}</th>
              <th width="40%">{this.props.translator['Operator']}</th>
              <th width="40%">{this.props.translator['Value']}</th>
              <th width="40%">{this.props.translator['Action']}</th>
            </tr>
          </thead>
						<tbody>
            {this.renderAtt(this.props.criteriaList)}
						</tbody>
					</Table>
				</div>
				: null}

				{this.state.formToggle ?
          <div>
    			<div className="f-size-10 margin-b-5"></div>
    			<div className="bgActive padding-10 bFormDiv">
    				<Row>
            <Col md={4}>
            <FormGroup className="margin-b-5">
            <Form.Label>{this.props.translator['Attribute']}</Form.Label>
            <Form.Select componentClass="select" name="attributename" id="attributename" onChange = {(event)=>{this.setAttName(event)}} >
            <option value="0">Select</option>
            {this.renderAttList(this.props.reportFields)}
            </Form.Select>
            </FormGroup>
            </Col>
            
          { this.props.module == "Task" || this.props.module == "CMDB" ? this.renderTaskCMDB() :
          this.state.selectedattname=="created_on" ||this.state.selectedattname=="closed_on" ||
           this.state.selectedattname=="expected_date" || this.state.selectedattname=="modified_on" || 
           this.state.selectedattname=="reported_on" || this.state.selectedattname=="act_end_date" || 
           this.state.selectedattname=="act_start_date" || this.state.selectedattname=="d_end_date" || 
           this.state.selectedattname=="d_start_date" || this.state.selectedattname=="exp_end_date" ||
           this.state.selectedattname=="exp_start_date" || this.state.selectedattname=="expected_date" ||
           this.state.selectedattname=="expdelivery_date" || this.state.selectedattname=="approved_on" ||
           this.state.selectedattname=="cancelled_on" || this.state.selectedattname=="completion_date" ||
           this.state.selectedattname=="rejected_on" ?
    					<Col md={4}>
              <FormGroup className="margin-b-5">
        <Form.Label>{this.props.translator['Operator']}</Form.Label>
              <Form.Select componentClass="select" name="attributeoperator" id="attributeoperator"
               onChange = {(event)=>{this.setAttOperator(event)}} value={this.state.reportOptVal}>
              <option value="0">select</option>
              {/* <option value="like">&#61;</option> */}
              <option value="between">Between</option>
              </Form.Select>
              </FormGroup>
              </Col>:
              <Col md={4}>
              <FormGroup className="margin-b-5">
              <Form.Label>{this.props.translator['Operator']}</Form.Label>
              <Form.Select componentClass="select" name="attributeoperator" id="attributeoperator"
               onChange = {(event)=>{this.setAttOperator(event)}} value={this.state.reportOptVal}>
              <option value="0">select</option>
              <option value="in">&#61;</option>
              <option value="like">like</option>
              <option value="in">in</option>
            {/*  <option value=">">&#62;</option>
              <option value="<">&#60;</option>
              <option value=">=">&#62; &#61;</option>
              <option value="=<">&#61; &#60;</option>*/}
              </Form.Select>
              </FormGroup>
              </Col>}
              {
                this.state.selectedattname=="created_on" ||
                this.state.selectedattname=="closed_on" || this.state.selectedattname=="expected_date"
                 || this.state.selectedattname=="modified_on" || this.state.selectedattname=="reported_on" 
                 || this.state.selectedattname=="act_end_date" || this.state.selectedattname=="act_start_date"
                  || this.state.selectedattname=="d_end_date" || this.state.selectedattname=="d_start_date"
                   || this.state.selectedattname=="exp_end_date" || this.state.selectedattname=="exp_start_date"
                    || this.state.selectedattname=="expected_date" || this.state.selectedattname=="expdelivery_date" 
                 || this.state.selectedattname == 'createdDate' || this.state.selectedattname=="approved_on" ||
                 this.state.selectedattname=="cancelled_on" || this.state.selectedattname=="completion_date" ||
                 this.state.selectedattname=="rejected_on" 
                ?
                (  GLOBAL.reportattoperator == "between" ? 
                <div>
                <Col md={2}>
                 <FormGroup className="margin-b-5">
                 <Form.Label>{this.props.translator['Start Date']}</Form.Label>
                 <Datetime id="attributeBwValue1" name="sd" inputProps={{readOnly:true}}  onChange = {this.changeStartDate}  className="form-control dateTimePickerField" locale="en" dateFormat={jwtdf}  closeOnSelect timeFormat={false} 
                 value={this.state.startDate != '' ? this.state.startDate : moment().format(jwtdf)} />
                 </FormGroup>
                  </Col>
                <Col md={2}>
                 <FormGroup className="margin-b-5">
                 <Form.Label>{this.props.translator['End Date']}</Form.Label>
                    <Datetime id="attributeBwValue2" direction="up" inputProps={{readOnly:true}}  onChange = {(event)=>{this.changeEndDate(event)}} 
                     className="form-control dateTimePickerField shiftRight" locale="en" dateFormat={jwtdf}  closeOnSelect timeFormat={false} 
                     value={this.state.endDate != '' ? this.state.endDate : moment().format(jwtdf)} />
                </FormGroup>
                 </Col>
                 </div>
                :
                <Col md={4}>
              <FormGroup className="margin-b-5">
              <Form.Label>{this.props.translator['Value']}</Form.Label>
								<Datetime id="attributevalue" inputProps={{readOnly:true}}  onChange = {(event)=>{this.setDateAttVal(event)}}  className="form-control dateTimePickerField" locale="en" dateFormat={jwtdf}  closeOnSelect timeFormat={false} />

							</FormGroup>
              </Col>
              )
              :  <Col md={4} className="">
                <FormGroup className="margin-b-5">
                <Form.Label>{this.props.translator['Value']}</Form.Label>
                <Form.Control type="attributevalue" maxLength="50" id="attributevalue" onChange = {(event)=>{this.setAttVal(event)}} />
                </FormGroup>
                </Col>}

    				</Row>




    				<div className="btnNew padding-5">
            {this.state.savebuttonshow=="show"?<ButtonToolbar>
            <Button onClick={()=>{this.saveatt()}}  size="xs">{this.props.translator['Save']}</Button>
            <Button  onClick={()=>{this.formCancelFun(true)}} size="xs">{this.props.translator['Cancel']}</Button>
            </ButtonToolbar>
            :<ButtonToolbar>
            <Button disabled="true" onClick={()=>{this.saveatt()}}  size="xs">{this.props.translator['Save']}</Button>
            <Button disabled="true"  onClick={()=>{this.formCancelFun(true)}} size="xs">{this.props.translator['Cancel']}</Button>
            </ButtonToolbar>}
            </div>
    			</div>



    			</div>
				:null}

				 {this.state.formeditToggle ?
           <div>
           <div className="f-size-18 margin-b-5"></div>
           <div className="bgActive padding-10 bFormDiv">
           <Row>
           <Col md={4}>
           <FormGroup className="margin-b-5">
           <Form.Label>{this.props.translator['Attribute']}</Form.Label>
         
           <Form.Control type="text" value={this.state.attname=="Breakfix Number"?"Incident Number":this.state.attname} disabled={true} />
           </FormGroup>
           </Col>
            <Col md={4}>
             <FormGroup className="margin-b-5">
             <Form.Label>{this.props.translator['Operator']}</Form.Label>
           
             <Form.Control type="text" placeholder={this.state.attoperator}  disabled={true}/>
             </FormGroup>
             </Col>

            {
               this.state.attname=="Created On" ||this.state.attname=="Closed On" || 
               this.state.attname=="Expected Date" || this.state.attname=="Act End Date"
                || this.state.attname=="Act Start Date" || this.state.attname=="D End Date" ||
                this.state.attname=="D Start Date" || this.state.attname=="Exp End Date" || 
                this.state.attname=="Exp Start Date" || this.state.attname=="Modified On" 
                || this.state.attname=="Expected Date" || this.state.attname=="Closed On" ||
                 this.state.attname=="Completion Date" || this.state.attname=="Expdelivery Date" 
                || this.state.attname=="Rejected On" || this.state.attname=="Reported On"
               || this.state.attname == 'CreatedDate' || this.state.attname == 'Cancelled On' || this.state.attname == 'Approved On'  ?
            (  this.state.attoperator == "between" ? 

            <div>
            <Col md={2}>
             <FormGroup className="margin-b-5">
             <Form.Label>{this.props.translator['Start Date']}</Form.Label>
             <Datetime id="attributeBwValue1" name="sd" inputProps={{readOnly:true}}  onChange = {this.changeStartDate}  className="form-control dateTimePickerField" locale="en" dateFormat={jwtdf}  closeOnSelect timeFormat={false} 
             value={this.state.startDate != '' ? this.state.startDate : moment().format(jwtdf)} />
             </FormGroup>
              </Col>
            <Col md={2}>
             <FormGroup className="margin-b-5">
             <Form.Label>{this.props.translator['End Date']}</Form.Label>
                <Datetime id="attributeBwValue2" inputProps={{readOnly:true}}  onChange = {(event)=>{this.changeEndDate(event)}} 
                 className="form-control dateTimePickerField shiftRight" locale="en" dateFormat={jwtdf}  closeOnSelect timeFormat={false} 
                 value={this.state.endDate != '' ? this.state.endDate : moment().format(jwtdf)} />
            </FormGroup>
             </Col>
             </div>

            // <Col md={4}>
            //  <FormGroup className="margin-b-5">
            //  <Form.Label>{this.props.translator['Value']}123</Form.Label>
            //   <Datetime id="attributevalue" inputProps={{readOnly:true}}  onChange = {(event)=>{this.setDateEditAttVal(event)}}  className="form-control dateTimePickerField" locale="en" dateFormat={jwtdf}  closeOnSelect timeFormat={false} value={this.state.attval} />

            // </FormGroup>
            //  </Col>
            :
            <Col md={4}>
             <FormGroup className="margin-b-5">
             <Form.Label>{this.props.translator['Value']}</Form.Label>
              <Datetime id="attributevalue" inputProps={{readOnly:true}}  onChange = {(event)=>{this.setDateEditAttVal(event)}}  className="form-control dateTimePickerField" locale="en" dateFormat={jwtdf}  closeOnSelect timeFormat={false} value={this.state.attval} />

            </FormGroup>
             </Col>
            )
             :  <Col md={4} className="">
             <FormGroup className="margin-b-5">
             <Form.Label>{this.props.translator['Value']}</Form.Label>
             <Form.Control type="attributevalue" maxLength="50" placeholder={this.state.attval} id="attributevalue" onChange = {(event)=>{this.setAttVal(event)}} />
             </FormGroup>
             </Col>}


          </Row>




             <div className="btnNew padding-5"><ButtonToolbar><Button onClick={()=>{this.submitatt()}}  size="xs">{this.props.translator['Update']}</Button><Button  onClick={()=>{this.formCancelFun(true)}} size="xs">{this.props.translator['Cancel']}</Button></ButtonToolbar></div>
           </div>



           </div>
 				 : null}


			</div>
		);
	}
}

export function mapStateToProps({ criteriaList,reportFields}){
	return {
		criteriaList,reportFields
};
}

function mapDispatchToProps(dispatch){
	return bindActionCreators({ loadCriteriaList,loadReportFields}, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(ReportConsoleCriteria);
