
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import RecordingAddView from "./addEdit";
import RecordingListView from "./list";

import { useShowHide } from "../../../../../common/useShowHide";
import TabHeader from "../../../../../common/TabHeader";
import GridLayout from "../../../../../common/GridLayout";
import ListLoader from "../../../../../../common/loaders/ListLoader";
import axios from "axios";

const Recordings = ({
  data,
  discussionsFetching,
  occurenceData,
  companyID,
  refetchlist,
  definationId,
  isEdit = true,
  sorting,
  orderby,
  activeTabs,
}) => {
  const occuranceId = occurenceData?.meetingOccurrananceId;
  const [isVisible, show, hide] = useShowHide(false);
  const [userId, setUserId] = useState("");

  const otherHeaderProps = {
    hide,
    isVisible,
  };
  const api = axios.create({
    headers: { Pragma: "no-cache", "Cache-control": "no-store" },
  });

  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = () => {
    try {
      let str = {};
      str.user_id = "";
      str = JSON.stringify(str);
      api
        .get("/api/userInfo", { headers: { query: str } })
        .then((res) => {
          setUserId(res?.data?.user_id);
        })
        .catch((err) => {
          console.error(err);
          setUserId("");
        });
    } catch (e) {
      console.error(e);
    }
  };

  const options = [
    occurenceData.status != "Cancelled" && occurenceData.primaryChairPerson == userId ?
    {
      isVisible: !isEdit ? true : isVisible,
      title: "Add",
      onClick: show,
      icon: "fa-plus",
      sorting: false,
      className: "add-message",
    } : {isVisible: true},
    {
      isVisible,
      title: "Sorting",
      onClick: sorting,
      icon: `custom-fa fa-sort-amount-asc`,
      sorting: false,
      className: "custom-sorting",
    },
    {
      isVisible,
      title: "Refresh",
      onClick: refetchlist,
      icon: "fa-refresh",
      sorting: false,
      className: "filter-icon mx-1",
    },
  ];

  return (
    <>
    {discussionsFetching == true ? <ListLoader/> : 
    <GridLayout cols={1}>
      <div className="flt-right">
        <TabHeader
          options={options}
          close={false}
          otherProps={otherHeaderProps}
        />
      </div>
      {!isVisible && (
        <RecordingListView
          hide={hide}
          data={data}
          companyID={companyID}
          occuranceId={occuranceId}
          refetchlist={refetchlist}
          definationId={definationId}
        />
      )}
      {isVisible && (
        <RecordingAddView
          hide={hide}
          data={data}
          companyID={companyID}
          occuranceId={occuranceId}
          refetchlist={refetchlist}
          definationId={definationId}
          activeTabs={activeTabs}
        />
      )}
    </GridLayout>}
    </>
  );
};

export default Recordings;
