
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export const admin_preferences_list_data_reducer = (state = initialState, action) => {
	switch (action.type) {
		case 'LOAD_ADMIN_PREFERENCES_LIST_DATA_SUCCESS':
			return Object.assign({}, state, { adminPreferencesListData: action.payload });
		case 'EMPTY_ADMIN_PREFERENCES_LIST_DATA_SUCCESS':
			return Object.assign({}, state, { adminPreferencesListData: [] });
		default:
			return state;
	}
}

export const admin_preferences_list_loader_reducer = (state = initialState, action) => {
	switch (action.type) {
		case 'LOAD_ADMIN_PREFERENCES_LIST_LOADER_IN_PROGRESS':
			return Object.assign({}, state, { adminPreferencesListLoader: true });
		case 'LOAD_ADMIN_PREFERENCES_LIST_LOADER_FINISHED':
			return Object.assign({}, state, { adminPreferencesListLoader: false });
		default:
			return state;
	}
}

export const walkupEnabled = (state = initialState, action) => {
	switch (action.type) {
		case 'WALK_UP_ENABLED':
			return Object.assign({}, state, { walkupEnabled: action.payload });
		default:
			return state;
	}
}
export const aismProperty = (state = {}, action) => {
	switch (action.type) {
		case 'AISM_AGENT_PREDICTION':
			return Object.assign({}, state, { aismAgentProp: action.payload });
		case 'AISM_GRP_PREDICTION':
			return Object.assign({}, state, { aismGrpProp: action.payload });
		case 'AIINSIGHT_FCR':
			return Object.assign({}, state, { aiInsightFCRProp: action.payload });
		case 'AIINSIGHT_DSAT':
			console.log("AIINSIGHT_DSAT", action.payload);
			return Object.assign({}, state, { aiInsightDSATProp: action.payload });
		default:
			return state;
	}
}