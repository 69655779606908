
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Breadcrumbs from "../../../common/header/breadcrumbs";
import ContractListHeader from "../list/header";
import ListSec from "../list/list";
import ShortDescription from "../list/shortDesc";
import { useDispatch, useSelector } from "react-redux";
import { getContractList } from "../../../../actions/ham/contractAction";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import "_Css/common/_dataTable.scss";
const Index = (props) => {
  const [isRightSideVisible, setRightSideVisible] = useState(false);
  const [contractData, setContractData] = useState({});
  const translator = useSelector((state) => state.spcmReducer.tr);
  let dispatch = useDispatch();

  let colWidth = "";
  isRightSideVisible ? (colWidth = 8) : (colWidth = 12);

  const showRightSideFun = (val) => {
    setRightSideVisible(val);
  };

  const contractDataHandler = (contractData) => {
    setContractData(contractData);
  };
  useEffect(() => {
    dispatch(getContractList());
  }, []);

  return (
    <main>
      {/* <PindCategories /> */}
      <Container fluid className="padding-t-10 margin-b-15">
        {/* <div className="tabDatacount">1-1 of 1</div> */}
        <Breadcrumbs activePageName={"Contract"} />
      </Container>

      <Container fluid>
        <ContractListHeader />
        <PanelGroup direction="horizontal">
								<Panel id="sidebar" minSize={33} order={1} defaultSize={isRightSideVisible ?67:100}  className={isRightSideVisible ?"isShowLeftPanel catBordDv" : "catBordDv"}>
          {/* <Col md={colWidth} sm={colWidth} xs={12} className="catBordDv"> */}
            <ListSec
              isRightSideVisible={isRightSideVisible}
              showRightSideFun={showRightSideFun}
              translator={translator}
              contractDataHandler={contractDataHandler}
            />
          </Panel>
          {isRightSideVisible ? (
            <>
           <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>								
           <Panel minSize={33} order={2} defaultSize={33}>
              <div className="stickyArea rBoxStyle">
                <ShortDescription
                  showRightSideFun={showRightSideFun}
                  translator={translator}
                  contractData={contractData}
                />
              </div>
            </Panel>
            </>
          ) : null}
        </PanelGroup>
      </Container>
    </main>
  );
};

export default Index;
