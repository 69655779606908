
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/

export const relationshipTypes = (state = [], action) => {
	switch (action.type) {
		case 'RELATIONSHIP_TYPES':
			return action.relationshipTypes;
		default:
			return state;
	}
};

export const classNames = (state = [], action) => {
	switch (action.type) {
		case 'CLASS_NAMES':
			return action.classNames;
		default:
			return state;
	}
};

export const assetCIListByClassName = (state = [], action) => {
	switch (action.type) {
		case 'ASSET_CI_LIST_BY_CLASS_NAME':
			return action.assetCIListByClassName;
		// case "RESET_STATE":
		// 	return {};
		// case "FETCHING_CI_LIST":
		// 	return {loading: true, data: []};
		default:
			return state;
	}
};
export const assetCIDisclaimer = (state = [], action) => { 
	// console.log("action.assetCIDisclaimer");
	// console.log(action.assetCIDisclaimer);
	switch (action.type) {
		case 'ASSET_CI_DISCLAIMER':
			return action.assetCIDisclaimer;
		default:
			return state;
	}
};
export const ciForTypeahead = (state = [], action) => {
	switch (action.type) {
		case 'CI_LIST_FOR_TYPEAHEAD':
			return action.assetCIListByClassName;
		default:
			return state;
	}
};