
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { getDelegationList } from "_Actions/delegations/delegationsActions.js";
import { connect } from "react-redux";
import moment from "moment";
import {LuFilterX} from "react-icons/lu";
import {HiPlus, HiOutlineRefresh} from 'react-icons/hi';

let userId = "";
let status = "Active";
let moduleId = "";
class DelegateHeader extends React.Component {
  constructor(props) {
    super(props);

    this.refreshDelegateTable = this.refreshDelegateTable.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
  }

  refreshDelegateTable() {
    this.props.getDelegationList(
      "",
      1,
      10,
      this.props.sortOrder,
      this.props.sortOnColumn,
      this.props.status,
      this.props.modules,
      this.props.searchKey,
      this.props.searchValue
    );
  }

  clearFilters() {
    this.props.showRightPanelFun(false);
    this.props.setDelegatorSearchInput("");
    this.props.setDelegateeSearchInput("");
    this.props.setSearchKeyValue("", "");
    this.props.setDelegatedFromStartAndDate({}, {});
    this.props.setDelegatedToStartAndDate({}, {});
    this.props.setStatusParameter("Active");
    this.props.setModulesParameter("");
    this.props.setSelectedValueStatus([]);
    this.props.setSelectedValueModule([]);
    if (this.props.dateRangePickerRefDelegatedFrom != undefined)
      this.props.dateRangePickerRefDelegatedFrom.endDate = moment();
    if (this.props.dateRangePickerRefDelegatedFrom != undefined)
      this.props.dateRangePickerRefDelegatedFrom.startDate = moment();
    if (this.props.dateRangePickerRefDelegatedTo != undefined)
      this.props.dateRangePickerRefDelegatedTo.endDate = moment();
    if (this.props.dateRangePickerRefDelegatedTo != undefined)
      this.props.dateRangePickerRefDelegatedTo.startDate = moment();
    this.props.getDelegationList(
      userId,
      1,
      10,
      "desc",
      "delegateDateforFrom",
      status,
      moduleId
    );
    this.props.isClearAllFilterFunc(false);
  }

  render() {
    return (
      <Row className="margin-b-15">
        <Col lg={4} md={6} sm={6} xs={7}>
          <h1 bsClass="" className="sPageHeading1">
            {this.props.tr["My Delegations"]}
          </h1>
        </Col>
        <Col lg={8} md={6} sm={6} xs={5}>
          <div className="paHedFilter">
            {/* <div className="searDv"><FormControl placeholder="search" className="searInp" type="text" /><a title="search" className="faicn" href={void(0)}><i className="fa fa-search"/></a></div> */}
            {/* <div className="overlayDv"><Button title="Clear All Filters" role="button"  className="myBt clrbtn"><i className="fa fa-times clrFilt" aria-hidden="true"/><i className="fa fa-filter" aria-hidden="true"/></Button></div> */}
            {/* <div className="overlayDv"><Button title="Export to CSV" role="button"  className="myBt react-bs-table-csv-btn  hidden-print"><i className="fa fa-download" aria-hidden="true"/></Button></div> */}
          <Link to={'/createDelegate'} state={{user_id : this.props.user_id }}>
            <a
              role="button"
              className="myBt plus fillBtn"
              title={this.props.tr["Create Delegation"]}
            >
              <HiPlus/>
            </a>
            </Link>
            {this.props.isClearAllFilter ? (
              <div className="overlayDv">
                <Button
                  title={this.props.tr["Clear All Filters"]}
                  role="button"
                  onClick={() => {
                    this.clearFilters();
                  }}
                  bsPrefix=" "
                  className="myBt clrbtn"
                >
                  <LuFilterX/>
                </Button>
              </div>
            ) : (
              ""
            )}
            <Button
              title={this.props.tr["Refresh"]}
              role="button"
              variant="link"
              className="myBt"
              onClick={() => {
                this.refreshDelegateTable();
              }}
            >
              <HiOutlineRefresh className='refreshFlip'/>
            </Button>
          </div>
        </Col>
      </Row>
    );
  }
}

// XsmSupportHeader.propTypes = {
// 	tr: PropTypes.object,
// 	getDelegationList: PropTypes.func
// };
const mapStateToProps = ({ spcmReducer }) => {
  return {
    tr: spcmReducer.tr,
  };
};
export default connect(mapStateToProps, { getDelegationList })(DelegateHeader);
