
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { Field, Formik, Form } from "formik";
import { useParams, useNavigate } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import AuditLog from "./AuditLog";
import Breadcrumbs from "../../common/header/breadcrumbs";
import Header from "./header";
import FormComponent from "./addEditRule";
import QueryBuilderComponent from "../query-builder";

import {
  useSaveRuleDefinitionMutation,
  useGetRuleQuery,
  useUpdateRuleDefinitionMutation,
} from "../../../reducers/rules";
import ListLoader from "../../common/loaders/ListLoader";
import { customQueryFormatter } from "../../common/helper";
import "_Css/form/_form.scss";

const initialQuery = {
  combinator: "and",
  rules: [],
};

const Index = () => {
  const formikRef = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tr = useSelector((state) => state.spcmReducer.tr);

  const [fields, setFields] = useState([]);
  const [ruleStatus, setRuleStatus] = useState("");
  const [query, setQuery] = useState(initialQuery);
  const [ruleError, setRuleError] = useState("");
  const [isRightEditPanel, setrightEditPanel] = useState(true);
  const [view, setView] = useState("details");
  const [saveRule, { isLoading: saveForumLoading }] =
    useSaveRuleDefinitionMutation();
  const [updateRule, { isLoading: isRuleUpdating }] =
    useUpdateRuleDefinitionMutation();

  const ruleId = params?.ruleId;
  const isAddMode = !ruleId;

  const rightEditPanel = (value) => {
    setrightEditPanel(value);
  };

  const [initVals, setInitVals] = useState({
    ruleType: "assignment",
    ruleName: "",
    consumerCompanyId: "",
    consumerCompany: "",
    alternateGroupId: "",
    alternateGroupName: "",
    assignmentCompany: "",
    assignmentCompanyId: "",
    assignmentType: "predefined",
    groupType: "",
    groupTypeId: "",
    module: "",
    ruleFor: "",
    status: "",
    weight: "",
    businessFunction: "",
    businessFunctionId: "",
    condition: "",
    responseEntityId: "",
    responseEntity: "",
  });

  let initialValues = {
    ...initVals,
  };

  const validationSchema = Yup.object().shape({
    ruleType: Yup.string().required("Required"),
    ruleName: Yup.string().required("Required"),
    consumerCompanyId: Yup.string().required("Required"),
    assignmentCompanyId: Yup.string().when("ruleType", (ruleType, schema) => {
      return ruleType?.[0] === "assignment"
        ? schema.required("Required")
        : schema;
    }),
    responseEntityId: Yup.string().required("Required"),
    module: Yup.string().required("Required"),
    weight: Yup.string().when("ruleType", (ruleType, schema) => {
      return ruleType?.[0] !== "escalation"
        ? schema.required("Required")
        : schema;
    }),
    status: Yup.string().required("Required"),
    ruleFor: Yup.string().when("ruleType", (ruleType, schema) => {
      return ruleType?.[0] === "assignment"
        ? schema.required("Required")
        : schema;
    }),
    groupTypeId: Yup.string().when(["assignmentType", "responseEntity"], ([assignmentType, responseEntity], schema) => {
      return (assignmentType === "contextual" && responseEntity === "Requestor.Location.Group")
        ? schema.required("Required")
        : schema;
    }),
  });


    const validateRule = (rule) => {
    const errors = {};
  
    if ('rules' in rule) {
      rule.rules.forEach((subRule, index) => {
        const subRuleErrors = validateRule(subRule);
        if (Object.keys(subRuleErrors).length > 0) {
          errors[`rules[${index}]`] = subRuleErrors;
        }
      });
    } else {
      if (!rule.field || rule.field === "") {
        errors.field = "Field cannot be blank.";
      }
      if (!rule.operator || rule.operator === "") {
        errors.operator = "Operator cannot be blank.";
      }
      if (rule.operator !== "null" && rule.operator !== "!null") {
        const isValueBlank =
          typeof rule.value === "object"
            ? rule.value?.id === undefined ||
              rule.value?.id === "" ||
              rule.value?.id?.toString()?.trim().length === 0
            : rule.value === undefined ||
              rule.value === "" ||
              rule.value.trim().length === 0;

        if (isValueBlank) {
          errors.value = "Value cannot be blank.";
        }
      }
    }
  
    return errors;
  };
  
  const validateAllRules = (rules) => {
    const errors = rules.map((rule, index) => {
      const ruleErrors = validateRule(rule);
      return Object.keys(ruleErrors).length > 0 ? { index, ruleErrors } : null;
    }).filter(error => error !== null);
  
    return errors.length > 0 ? { rules: errors } : {};
  };


  const onSubmit = async (fields, { resetForm, setSubmitting, errors }) => {
    const effectiveQuery = query && query.rules.length > 0 ? query : null;
    const rules = query && query.rules.length > 0 ? customQueryFormatter(effectiveQuery, 'sql') : "";
    const rulesErrors = validateAllRules(effectiveQuery?.rules || []);

    if (Object.keys(rulesErrors).length > 0) {
      setRuleError(rulesErrors);
      Swal.fire({
        title: "Conditions can not be blank",
        button: "OK",
      });
      return;
    }

    const postData = {
      ...fields,
      condition: rules || "",
      conditionalJson: effectiveQuery,
      createdOn: undefined,
      modifiedOn: undefined,
    };
    if (isAddMode) {
      try {
        const savefrm = await saveRule({
          data: postData,
        }).unwrap();
        setSubmitting(false);
        if (savefrm?.status === "OK") {
          Swal.fire({
            title: "Rule created successfully",
            button: "OK",
          }).then((action) => {
            if(action.isDismissed || action.isConfirmed) {
              navigate('/editRule/' + savefrm.body.id);
            }
          })
          // setTimeout(() => navigate("/rules"), 200);
        }
      } catch (err) {
        console.error(err?.data || "Error");
        Swal.fire({
          title:
            err?.data?.message ||
            "Oops! Something went wrong. Please try again.",
          button: "OK",
        });
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        const updateData = {
          ...postData,
          ruleId: ruleId,
        };
        const reponse = await updateRule({
          data: updateData,
        }).unwrap();
        if (reponse?.status === "OK") {
          Swal.fire({
            title: "Rule updated successfully",
            button: "OK",
          })
        }
      } catch (err) {
        console.error(err?.data || "Error");
      } finally {
        setSubmitting(false);
      }
    }
  };

  const { data: ruleDatabyId, isFetching: ruleFetching } = useGetRuleQuery(
    {
      ruleId,
    },
    {
      skip: !ruleId,
    }
  );

  useEffect(() => {
    setInitVals((prev) => {
      return {
        ...prev,
        ...ruleDatabyId,
      };
    });
    setRuleStatus(ruleDatabyId?.status);
    if (ruleDatabyId?.conditionalJson) {
      const parsedData = JSON.parse(ruleDatabyId.conditionalJson);
      setQuery(parsedData);
    } else {
      setQuery("");
    }
  }, [ruleDatabyId]);

  return (
    <main>
      <Container fluid className="margin-b-15 margin-t-10">
        <Breadcrumbs
          activePageName={isAddMode ? "Create" : "Edit"}
          parentPageurl="/rules"
          parentPageName={"Rules Listing"}
        />
      </Container>

      <Container fluid>
        <Row>
          <Col xs={12}>
            <Header
              isSubmitting={formikRef?.current?.isSubmitting}
              dirty={formikRef?.current?.dirty}
              submitForm={formikRef?.current?.handleSubmit}
              formikref={formikRef}
              errors={formikRef?.current?.errors}
              isRightEditPanel={isRightEditPanel}
              rightEditPanel={rightEditPanel}
              view={view}
              setView={setView}
            />
          </Col>
          <Col xs={12} md={12}>
            {ruleFetching ? 
              <ListLoader /> : 
              <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={onSubmit}
            >
              {({
                errors,
                touched,
                isSubmitting,
                setFieldValue,
                handleBlur,
                values,
                dirty,
                resetForm,
                handleChange,
                isValid,
                submitCount,
              }) => {
                return (
                  <Form noValidate>
                    <PanelGroup direction="horizontal">
                      <Panel
                        id="sidebar"
                        minSize={33}
                        order={1}
                        defaultSize={50}
                        className={isRightEditPanel ? "isShowLeftPanel catBordDv" : "catBordDv"}
                      >
                        <FormComponent
                          resetForm={resetForm}
                          errors={errors}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          values={values}
                          saveForumLoading={saveForumLoading || isRuleUpdating}
                          setFields={setFields}
                          isValid={isValid}
                          submitCount={submitCount}
                          handleChange={handleChange}
                          ruleStatus={ruleStatus}
                          setQuery={setQuery}
                          initialQuery={initialQuery}
                          editFormValues={ruleDatabyId}
                        />
                      </Panel>
                      {isRightEditPanel ? (
                        <>
                        <PanelResizeHandle className="isShowLeftPanel resizeHandle">
                          <div className="outlne">
                            <div className="handIcn">
                              <i className="fa fa-chevron-left" aria-hidden="true"></i>
                            </div>
                          </div>
                        </PanelResizeHandle>
                        <Panel minSize={33} order={2} defaultSize={50}>
                          {view === "details" ? (
                            <QueryBuilderComponent
                              values={values}
                              fields={fields}
                              setFields={setFields}
                              setQuery={setQuery}
                              query={query}
                              editFormValues={ruleDatabyId}
                              rightEditPanel={rightEditPanel}
                            />
                          ) : view === "activity" ? (
                            <div className="stickyArea rBoxStyle">
                              <AuditLog
                                rightEditPanel={rightEditPanel}
                                setView={setView}
                              />
                            </div>
                          ) : null}
                        </Panel>
                      </>
                      ) : (
                        ""
                      )}
                    </PanelGroup>
                  </Form>
                );
              }}
            </Formik>}
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default Index;
