
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

export function cartCountActionSuccess(cartCount){
	return {
		type: 'FETCH_CARTCOUNT',
		cartCount
	};
}

export function loadCartCount(){

	var str={};
	str.userId="";
   
    str = JSON.stringify(str);

	const cartCountUrl="/api/user/cartCount"
	return(dispatch) =>{
		api.get(cartCountUrl,{headers:{'query':str}})
			.then((cartCount) =>{
				dispatch(cartCountActionSuccess(cartCount))
			})
			.catch((err) => {
				console.log(err);
			});
	};
}
