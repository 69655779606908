
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import {
  CreateAndUpdatePublishedPage,
  GET_PAGES_URLS,
} from "../../graphQl/GraphqlQueries";
import { useMutation, useQuery } from "@apollo/client";
import Swal from "sweetalert2";
import { TypeaheadExampleSingleSelect } from "../../../common/formFields";
import { ImSpinner6 } from "react-icons/im";
import { IoSaveOutline } from "react-icons/io5";

const HeaderStatus = (props) => {
  
  const { pageDetail, statusObject, setStatusObject } = props;
  const publishedPage = pageDetail ? pageDetail?.publishedPage : {};
  const [viewForm, setViewForm] = useState(false);
  const [typedCompany, setTypedCompany] = useState("");
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [companyError, setCompanyError] = useState("");
  const [urlOptions, setUrlOptions] = useState([]);
  const root = document.documentElement;
  viewForm
    ? root?.style.setProperty("--modalBackDrop", 0.5)
    : root?.style.setProperty("--modalBackDrop", 0);

  const [
    createAndUpdatePublishedPage,
    {
      data: publishedPageData,
      loading: publishedLoading,
      error: publishedPageError,
    },
  ] = useMutation(CreateAndUpdatePublishedPage);

  const {
    data: pagesUrls,
    loading: pagesUrlsLoading,
    refetch: refetchUrls,
  } = useQuery(GET_PAGES_URLS);

  const statusChange = (status) => {
    setStatusObject({...statusObject,status})
  };

  const onCompanySelection = (company) => {
    setSelectedCompany(company);
  };

  const onCrossClickCompany = () => {
    setSelectedCompany([]);
    setTypedCompany("");
  
  };

  const companyCleared = (e) => {
    setTypedCompany(e);
  };

  useEffect(() => {
    refetchUrls();
  }, []);

  useEffect(() => {
    if (publishedPageData) {
      props?.formDetailRefetch();
      setViewForm(false);
    }
  }, [publishedPageData]);

  useEffect(() => {
    if (publishedPageError) {
      Swal.fire(`An error occurred: ${error.message}`);
    }
  }, [publishedPageError]);

  useEffect(() => {
    if (pagesUrls) {
      const urls = pagesUrls?.getPagesUrls.map((res) => {
        return { ...res, label: res.name || res.functionAction };
      });
      setUrlOptions(urls);
    }
  }, [pagesUrls]);

  const deployPageWithMenu = () => {
    let dataObject = {
      companyId: parseInt(pageDetail?.companyId, 10),
      companyName: pageDetail?.companyName,
      forms: pageDetail?._id,
      status: "Deployed",
      pageRoute: "/renderedView/" + pageDetail?._id,
    };

    // if (pageDetail?.pageType == "list") {
    //   dataObject.pageRoute = "/renderedListView/" + pageDetail?._id;
    // }
    if (selectedCompany.length > 0) {
      dataObject.functionAction = selectedCompany[0].functionAction;
      dataObject.internalUrl = selectedCompany[0].internalUrl;
    }
    createAndUpdatePublishedPage({
      variables: {
        createParams: dataObject,
      },
    });
  };

  const renderStatus = () => {
    if (pageDetail && publishedPage?.status == "Proposed") {
      return (
        <>
          <Dropdown.Item onClick={() => statusChange("Proposed")}>
            Proposed
          </Dropdown.Item>
          <Dropdown.Item onClick={() => statusChange("Deployed")}>
            Deployed
          </Dropdown.Item>
        </>
      );
    } else if (pageDetail && publishedPage?.status == "Deployed") {
      return (
        <>
          <Dropdown.Item onClick={() => statusChange("Deployed")}>
            Deployed
          </Dropdown.Item>
          <Dropdown.Item onClick={() => statusChange("Suspended")}>
            Suspended
          </Dropdown.Item>
        </>
      );
    } else if (pageDetail && publishedPage?.status == "Suspended") {
      return (
        <>
          <Dropdown.Item onClick={() => statusChange("Suspended")}>
            Suspended
          </Dropdown.Item>
          <Dropdown.Item onClick={() => statusChange("Deployed")}>
            Deployed
          </Dropdown.Item>
        </>
      );
    } else if (pageDetail && Object.keys(pageDetail).length > 0) {
      return (
        <>
          <Dropdown.Item>Draft</Dropdown.Item>
          <Dropdown.Item onClick={() => statusChange("Proposed")}>
            Proposed
          </Dropdown.Item>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <DropdownButton
        bsPrefix=" "
        id="dropdown-basic-button"
        title={statusObject?.status ? statusObject?.status : "Draft"}
        disabled={!statusObject?.status && !pageDetail}
        className="statusbtndv"
      >
        {renderStatus()}
        
      </DropdownButton>
      <Modal
        size="lg"
        show={viewForm}
        onHide={() => {
          setViewForm(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="escalation-level-form"
      >
        <Modal.Header>
          <Row>
            <Col lg={6} md={6} sm={12} xs={12}>
              <h2 className="sPageHeading1">{"Deploy"}</h2>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <div className="paHedFilter">
                <Button
                  bsPrefix=" "
                  id="creTag"
                  role="button"
                  className="myBt plus"
                  disabled={publishedLoading}
                  onClick={() => {
                    deployPageWithMenu();
                  }}
                  title={"Deploy"}
                >
                  {publishedLoading ? (
                    <ImSpinner6 className="icn-spinner" />
                  ) : (
                    <IoSaveOutline />
                  )}
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12} sm={12} xs={12}>
              <Form.Group className="dvTypehdropdown-group form-group">
                <Form.Label>
                  <span className="rStar"></span>
                  {"Select Menu"}
                </Form.Label>
                <div className="minHghtdvTypehd">
                  <TypeaheadExampleSingleSelect
                    id="page"
                    name="page3"
                    className="form-control"
                    onSelection={onCompanySelection}
                    setErrorColor={() => {}}
                    options={urlOptions}
                    selectedOptions={selectedCompany}
                    onInputChange={companyCleared}
                    onCrossClick={onCrossClickCompany}
                    typedValue={typedCompany}
                    errorClass={companyError}
                    multiple={false}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HeaderStatus;
