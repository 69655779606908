
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GLOBAL } from '../../components/Globals';

export const gdsApi = createApi({
  reducerPath: 'gdsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: window.location.origin,
  }),
  tagTypes: ['JobNotification', 'PullCI'],
  endpoints: (builder) => ({
    jobnotification: builder.query({
      query: ({ queryString = '', params = {} }) => ({
        url: GLOBAL.jobnotification + queryString,
        method: 'GET',
        headers: {
          Query: JSON.stringify(params),
        },
      }),
      keepUnusedDataFor: 0,
      providesTags: (result, error, arg) => ['JobNotification']
    }),
    pullci: builder.query({
      query: ({ gdsId, pushToMaster = false }) => ({
        url: GLOBAL.pullci(gdsId),
        method: 'GET',
        headers: {
          'Content-Type': 'application/json', 
          'Query': JSON.stringify({ pushToMaster: pushToMaster }),
        },       
      }),
      providesTags: (result, error, arg) => ['PullCI'],
      onQueryStarted(arg, { queryFulfilled, dispatch }) {
        queryFulfilled.then(() => {
          dispatch(gdsApi.util.invalidateTags(['JobNotification']));
        });
      },
    }),
  }),
});

export const { useJobnotificationQuery, usePullciQuery } = gdsApi;

const { reducer } = gdsApi;
export default reducer;
