
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Typeahead } from 'react-bootstrap-typeahead';
import "react-bootstrap-typeahead/css/Typeahead.css";
import { FaAngleDown } from "react-icons/fa";

class AdminPreferencesDropdownPlusTypeahead extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.onCrossClickFun = this.onCrossClickFun.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.inputValue != this.props.inputValue && nextProps.inputValue == '') {
            //this._typeahead.getInstance().clear();
        }
    }

    onCrossClickFun() {
        this.props.onCrossClick();
        this._typeahead.clear();
    }

    render() {
        let { options, onSelection, selectedOptions, setErrorColor, disabled = false, onInputChange, inputValue, onCrossClick, id, errorColor, formType, placeholder, labelKey = null } = this.props;

        const inputProps = {
            value: inputValue ? inputValue : ''
        };
        let props = labelKey === null ? {} : { labelKey };

        return (
            <div className={"dvTypehd " + errorColor}>
                
                {(inputValue && inputValue.length > 0) && formType !== "edit" ?
                    <span
                        title="clear"
                        role="img"
                        aria-label="Clear"
                        className="css-hakgx8 icn"
                        onClick={this.onCrossClickFun}
                    >
                        <svg width="18" height="18" viewBox="0 0 24 24" role="presentation">
                            <path
                                d="M12 10.586L6.707 5.293a1 1 0 00-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 001.414 1.414L12 13.414l5.293 5.293a1 1 0 001.414-1.414L13.414 12l5.293-5.293a1 1 0 10-1.414-1.414L12 10.586z"
                                fill="gray"
                            ></path>
                        </svg>
                    </span>
                    : id=="header_group"?<span className="custom-down-arrow"><FaAngleDown/></span>:""}
                <Typeahead
                {...props}
                    id={id}
                    ref={(ref) => (this._typeahead = ref)}
                    onChange={onSelection}
                    onInputChange={onInputChange}
                    options={options}
                    selected={selectedOptions}
                    placeholder={placeholder?placeholder:"Please choose..."}
                    onBlur={setErrorColor}
                    disabled={disabled}
                    inputProps={inputProps}
                    renderMenuItemChildren={(option, props) => {
                        let label;
                        let title;
                        if (labelKey !== null) {
                          label =
                            option[labelKey].length > 30
                              ? option[labelKey].slice(0, 30) + "..."
                              : option[labelKey];
                          title = option[labelKey].length > 30 ? option[labelKey] : "";
                        } else {
                          label =
                            option.label.length > 30
                              ? option.label.slice(0, 30) + "..."
                              : option.label;
                          title = option.label.length > 30 ? option.label : "";
                        }
                        if(option?.supportCompanyName){
                          return (
                            <div>
                              <span title={title} style={{'display': 'block'}}>{label}</span>
                              <span style={{'display': 'block', 'margin-top': '6px', 'color': '#777'}}>{option?.supportCompanyName || ''}</span>
                            </div>
                          )
                        }
                        return (
                          <div>
                            <span title={title}>{label}</span>
                          </div>
                        );
                      }}
                />
            </div>
        );
    }
}

export default AdminPreferencesDropdownPlusTypeahead;