
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import { LuFilterX } from "react-icons/lu";
import { HiOutlineRefresh } from "react-icons/hi";

import Cookies from "universal-cookie";
import moment from "moment";
const cookies = new Cookies();


const Header = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const tr = useSelector((state) => state.spcmReducer.tr);

    return (
        <>
            <Row className="margin-b-15">
                <Col lg={10} md={10} sm={10} xs={12}>
                    <ul className="wibTabs">
                        <li>
                            <Link title={tr["Pull Golden Dataset"]} to="/getGDS">
                                {tr["Pull Golden Dataset"]}
                            </Link>
                        </li>
                        <li className="active">
                            <Link title={tr["Pending Actions"]} to="javascript:void(0)">
                                {tr["Pending Actions"]}
                            </Link>
                        </li>
                    </ul>
                </Col>
                <Col lg={2} md={2} sm={2} xs={12}>
                    <div
                        aria-label="Page Filters"
                        role="contentinfo"
                        className="paHedFilter"
                    >
                        <div className="overlayDv">
                            <Button
                                title={tr["Clear All Filters"]}
                                role="button"
                                bsPrefix=" "
                                className="myBt clrbtn"
                            >
                                <LuFilterX />
                            </Button>
                        </div>
                        <Button
                            aria-label={tr["Refresh"]}
                            title={tr["Refresh"]}
                            role="button"
                            bsClass=""
                            bsPrefix=" "
                            className="myBt"
                        >
                            <HiOutlineRefresh className="refreshFlip" />
                        </Button>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default Header;
