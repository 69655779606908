
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import {Table,ProgressBar} from 'react-bootstrap';
import { connect } from 'react-redux';
import { getReviews } from '../../../actions/spcmActions';

class ServiceRatings extends React.Component {

	constructor(props){
	super(props);
		this.componentWillMount = this.componentWillMount.bind(this);
	};

	componentWillMount(){
		this.props.getReviews(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
	}


	render(){


		const tr=this.props.tr;
		let rating=this.props.spcmReducer.reviews;
		let total=0;
		let total_rat=0;
		rating.forEach(function(val,key){
			total+=val;
			total_rat+=key*val;
		});
		let inverse=[5,4,3,2,1];
		return(
			<div className="rBoxGap f-size-13">
				<Table responsive className="reviews cReviews">
					<thead>
					  <tr>
						<th width="20%">{tr['Ratings']}</th>
						<th width="65%">{total_rat?total_rat/total:0} {tr['out of 5']}</th>
						<th width="15%">{tr['Total']}</th>
					  </tr>
					</thead>
					<tbody>
						{
							inverse.map(function(val,key){
								let stars='';
								for(let i=1;i<=val;i++)
									stars+='★';
								return(
									<tr>
										<td className='serviceReviewRatingColor'>{stars}</td>
										<td><ProgressBar bsStyle="warning" now={(rating[val]*100/total)} /></td>
										<td>{rating[val]}</td>
									</tr>
								)
							})
						}
					 	  
					</tbody>
				</Table>
			</div>
		);
	}
}

const mapStateToProps = ({ spcmReducer })=>{
	return {
		spcmReducer,
		initialValues:spcmReducer.editService
	}
}


export default connect(mapStateToProps,{getReviews}) (ServiceRatings);
