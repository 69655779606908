
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import Cookies from 'universal-cookie';
import { useDispatch, useSelector } from "react-redux";
import { getMyApprovalList } from "../../actions/MyApprovals/MyApprovalActions";
import moment from 'moment';
import {LuFilterX} from "react-icons/lu";
import {HiOutlineRefresh} from 'react-icons/hi';

let status = "pending";
const userId = "1";
let ModuleValue = [10, 14];

const cookies = new Cookies();

let homepagelocation = cookies.get('gph');
if (homepagelocation) homepagelocation = homepagelocation.replace('s:', '');
if (homepagelocation) homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
let myapplocation = window.location.href;
if (myapplocation.indexOf("myapp") > -1)
	homepagelocation = "/myApprovals";

const MyApprovalsHeader = (props) => {
	const tr = useSelector((state) => state.spcmReducer.tr);
	const dispatch = useDispatch();

	const refreshApprovalTable = () => {
		props.showRightSide(false);
		getDataFromService({
			userId, sorttype: props.sortOrder, sortval: props.sortOnColumn,
			status: props.status,
			page: "1",
			limit: "5",
			ModuleValue,
			searchby: props.searchKey,
			searchbyVal: props.searchValue
		});
		props.updatePagination("5");
	}
	const getDataFromService = ({ userId, sorttype, sortval, status, page, limit, ModuleValue, searchby, searchbyVal }) => {
		dispatch(getMyApprovalList(userId, sorttype, sortval, status, page, limit, ModuleValue, searchby, searchbyVal));
	}
	const clearAllFilters = () => {
		props.setdisplayNumberInputValues("");
		props.setCiServiceNameInputValues("");
		props.setRequestedByInputValues("");
		props.setSearchKeyValue("","");
		props.setStartAndEndDate({},{});
		props.updateParameter("Pending");
		props.setSelectedValue([]);
		if(props.dateRangePickerRef!=undefined) {
			props.dateRangePickerRef.endDate=moment();
			props.dateRangePickerRef.startDate=moment();
		}
		props.showRightSide(false);
		props.isClearAllFilterFunc(false);
		getDataFromService({
			userId, sorttype: "desc", sortval: "submittedOn",
			status: status,
			page: "1",
			limit: "5",
			ModuleValue
		});
	}

	// if (homepagelocation == "/myApprovals") {
	// 	return (
	// 		null
	// 	);
	// }
	// else {
		return (
			<Row className="margin-b-15">
				<Col lg={4} md={6} sm={6} xs={8}>
					<h1 aria-label='Page Heading' role="contentinfo" bsPrefix=' ' className="sPageHeading1">{tr['My Approvals']}</h1>
				</Col>
				<Col lg={8} md={6} sm={6} xs={4}>
					<div aria-label='Page Filters' role="contentinfo" className="paHedFilter">
						{props.isClearAllFilter?<div className="overlayDv"><Button title={tr["Clear All Filters"]} role="button" onClick={() => { clearAllFilters() }}  bsPrefix=' ' className="myBt clrbtn"><LuFilterX/></Button></div>:""}
						<Button aria-label={tr["Refresh"]} title={tr["Refresh"]} role="button"  bsPrefix=' ' className="myBt" onClick={() => { refreshApprovalTable(); }}><HiOutlineRefresh className='refreshFlip'/></Button>
					</div>
				</Col>
			</Row>
		);
}

export default MyApprovalsHeader

