
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Container, Form, Nav, NavDropdown, FormControl, Button, Table, ButtonToolbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { change, Field, reduxForm, formValueSelector } from 'redux-form';
import { useDispatch, useSelector, connect } from 'react-redux';
import AdminPreferencesDropdownPlusTypeahead from "./adminPreferencesDropdownPlusTypeahead";
import { _textArea, _dropDown, _inputField, _checkbox, _number, _dynamicNamedropDown } from "../common/formFields.js";
import { loadAdminPreferencesListData, emptyAdminPreferencesListData } from '_Actions/adminPreferences/adminPreferencesAction';
import { getCompanyOptionsList } from "_Actions/foundation/commonAction";
import Cookies from 'universal-cookie';

const cookies = new Cookies();
let companyIdLoggedIn = cookies.get('gph');
if (companyIdLoggedIn) companyIdLoggedIn = companyIdLoggedIn.replace('s:', '');
if (companyIdLoggedIn) companyIdLoggedIn = companyIdLoggedIn.substring(0, companyIdLoggedIn.lastIndexOf('.'));
companyIdLoggedIn = companyIdLoggedIn.split("~");
companyIdLoggedIn = companyIdLoggedIn[48];

let cookiesArray = cookies.get('gph');
if (cookiesArray) cookiesArray = cookiesArray.replace('s:', '');
if (cookiesArray) cookiesArray = cookiesArray.substring(0, cookiesArray.lastIndexOf('.'));
cookiesArray = cookiesArray.split("~");
let isSuperAdmin = cookiesArray[60];

const styles = {
    grid: {
        paddingLeft: 0,
        paddingRight: 0
    },
    row: {
        marginLeft: 0,
        marginRight: 0
    },
    col: {
        paddingLeft: 0,
        paddingRight: 0
    }
};

const Header = (props) => {
    const dispatch = useDispatch();
    const translator = useSelector((state) => state.spcmReducer.tr);
    const companyList = useSelector((state) => state.costCenterCompanyDropdown);

    const [companySelectedList, setCompanySelectedList] = useState([]);
    const [inputValueCompany, setInputValueCompany] = useState('');
    const [companyTypeaheadOptions, setCompanyTypeaheadOptions] = useState([]);

    const selector = formValueSelector('adminPreferencesListForm');
    const companyIdParam = useSelector(state => selector(state, 'company'));

    useEffect(() => {
        dispatch(getCompanyOptionsList());
        props.setRetainSelectedModule(false);
        if (props.instanceOrCompanySelection == 'Instance') {
            dispatch(loadAdminPreferencesListData("", props.instanceOrCompanySelection, props.onlyShowOverridden));
        } else if (props.instanceOrCompanySelection == 'Company') {
            if (isSuperAdmin == 'Y') {
                dispatch(change('adminPreferencesListForm', 'company', "0"));
                setCompanySelectedList([{ id: "0", label: "Default" }]);
                setInputValueCompany("Default");
                dispatch(loadAdminPreferencesListData("0", props.instanceOrCompanySelection, props.onlyShowOverridden));
            } else {
                dispatch(loadAdminPreferencesListData(companyIdLoggedIn, props.instanceOrCompanySelection, props.onlyShowOverridden));
            }
        }
    }, []);

    useEffect(() => {
        if (!companyIdParam && companySelectedList.length > 0) {
            dispatch(change('adminPreferencesListForm', 'company', companySelectedList[0].id));
        }
    }, [companyIdParam])

    useEffect(() => {
        let companyTypeaheadOptions = [];
        if (companyList && companyList.length > 0) {
            let arr = [{ id: "0", label: "Default" }];
            companyTypeaheadOptions=[...arr, ...companyList];
        }
        setCompanyTypeaheadOptions(companyTypeaheadOptions);
    }, [companyList]);

    function onCompanySelection(selectedCompany) {
        props.setCompanyErrorState('');
        setCompanySelectedList(selectedCompany);
        props.setRetainSelectedModule(false);
        if (selectedCompany.length > 0) {
            dispatch(change('adminPreferencesListForm', 'company', selectedCompany[0].id));
            setInputValueCompany(selectedCompany[0].label);
            dispatch(loadAdminPreferencesListData(selectedCompany[0].id, props.instanceOrCompanySelection, props.onlyShowOverridden));
        }
        else {
            dispatch(change('adminPreferencesListForm', 'company', ''));
            dispatch(emptyAdminPreferencesListData());
        }
    }

    function setCompanyErrorColor() {
        if (companySelectedList.length === 0)
            props.setCompanyErrorState('error');
        else
            props.setCompanyErrorState('');
    }

    function onCompanyInputChange(val) {
        setInputValueCompany(val);
    }

    function onCrossClickCompany() {
        dispatch(change('adminPreferencesListForm', 'company', ''));
        setInputValueCompany('');
        setCompanySelectedList([]);
        props.setCompanyErrorState('error');
        dispatch(emptyAdminPreferencesListData());
    }

    function changeInstanceOrCompanySelection(val) {
        props.setInstanceOrCompanySelection(val);
        props.setOnlyShowOverridden(false);
        props.setRetainSelectedModule(false);
        if (val == 'Instance') {
            dispatch(loadAdminPreferencesListData("", val, false));
        } else if (val == 'Company') {
            if (isSuperAdmin == 'Y') {
                dispatch(change('adminPreferencesListForm', 'company', "0"));
                setCompanySelectedList([{ id: "0", label: "Default" }]);
                setInputValueCompany("Default");
                dispatch(loadAdminPreferencesListData("0", val, false));
            } else {
                dispatch(loadAdminPreferencesListData(companyIdLoggedIn, val, false));
            }
        }
    }

    function changeOnlyShowOverridden(val) {
        props.setOnlyShowOverridden(!val);
        props.setRetainSelectedModule(false);
        if (props.instanceOrCompanySelection == 'Instance') {
            dispatch(loadAdminPreferencesListData("", props.instanceOrCompanySelection, !val));
        } else if (props.instanceOrCompanySelection == 'Company') {
            if (isSuperAdmin == 'Y') {
                dispatch(loadAdminPreferencesListData(companyIdParam, props.instanceOrCompanySelection, !val));
            } else {
                dispatch(loadAdminPreferencesListData(companyIdLoggedIn, props.instanceOrCompanySelection, !val));
            }
        }
    }

    return (
        <div className="top-setting">
            <Row style={styles.row}>
                <Col lg={2} md={2} sm={12} xs={12} style={styles.col}>
                    <Form.Control className="keyword" type="text" onChange={(e) => { props.changeSearchText(e.target.value) }} placeholder={translator['Search']} />
                </Col>
                <Col lg={3} md={3} style={styles.col}></Col>
                <Col lg={2} md={2} sm={12} xs={12} style={styles.col}>
                    <ul className="wibTabs">
                        <li className={props.instanceOrCompanySelection == 'Instance' ? 'active' : ''}>
                            <Link to="javascript:void(0)" onClick={() => { changeInstanceOrCompanySelection('Instance') }} title={translator['Instance']} >
                                {translator['Instance']}
                            </Link>
                        </li>
                        <li className={props.instanceOrCompanySelection == 'Company' ? 'active' : ''}>
                            <Link to="javascript:void(0)" onClick={() => { changeInstanceOrCompanySelection('Company') }} title={translator['Company']} >
                                {translator['Company']}
                            </Link>
                        </li>
                    </ul>
                </Col>
                <Col lg={3} md={3} sm={12} xs={12} style={styles.col}>
                    {props.instanceOrCompanySelection == 'Company' && isSuperAdmin == 'Y' ? <div className="paHedFilter">
                        <Field component={AdminPreferencesDropdownPlusTypeahead}
                            errorColor={props.companyErrorState}
                            onSelection={onCompanySelection}
                            setErrorColor={setCompanyErrorColor}
                            name="company"
                            id="company"
                            className="form-control"
                            options={companyTypeaheadOptions}
                            selectedOptions={companySelectedList}
                            onInputChange={onCompanyInputChange}
                            inputValue={inputValueCompany}
                            onCrossClick={onCrossClickCompany}
                        />
                    </div> : ''}
                </Col>
                <Col lg={2} md={2} sm={12} xs={12} style={styles.col}>
                    <div style={{ marginTop: "7px", marginLeft: "18px" }}>
                        <Field name="onlyShowOverridden" component={_checkbox} className="form-control" label={translator['Only show overridden']} onChange={() => { changeOnlyShowOverridden(props.onlyShowOverridden) }} checked={props.onlyShowOverridden} />
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default reduxForm({
    form: "adminPreferencesListForm",
    destroyOnUnmount: true,
    enableReinitialize: true
})(Header);