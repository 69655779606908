
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LuFilterX } from "react-icons/lu";
import { HiPlus, HiOutlineRefresh } from "react-icons/hi";
import { getAllFroums } from "../../../reducers/foundation/forum/forum";

const Header = (props) => {
  const dispatch = useDispatch();
  const tr = useSelector((state) => state.spcmReducer.tr);

  const defaultSettings = {
    currentPage: 0,
    multipleValueList: "active",
    orderBy: "desc",
    patternList: "in",
    searchByList: "status",
    size: 10,
    sortBy: "modifiedOn",
  };

  const refresh = async () => {
    props?.rulesRefetch();
  };

  const resetFilters = () => { 
    props.setSearchParams(defaultSettings);
    props.rulesRefetch();
    props.setIsFilterCleared(true);
    props.setPgSize(10);
    props.setPageNum(0);
  };
  // console.log(props?.searchParams, "searchParams");

  useEffect(() => {
    const isDefault = Object.keys(defaultSettings).every((key) => {
      if(key !== 'size' && key !== 'currentPage' && key !== 'sortBy' && key !== 'orderBy') {
        const defaultValue = defaultSettings[key];
        const currentValue = props?.searchParams[key];
        return currentValue?.toString() === defaultValue?.toString();
      } else return true;
    });

    props?.setShowFilterIcon(!isDefault);
  }, [props?.searchParams]);

  return (
    <Row className="margin-b-15">
      <Col lg={4} md={6} sm={6} xs={7}>
        <h1 bsClass="" className="sPageHeading1">
          {tr["Rules Listing"]}
        </h1>
      </Col>
      <Col lg={8} md={6} sm={6} xs={5}>
        <div className="paHedFilter">
          <Link
            to="/createRule"
            role="button"
            className="myBt plus fillBtn"
            title="Create"
          >
            <HiPlus />
          </Link>
          {props?.showFilterIcon && (
            <div className="overlayDv">
              <Button
                title="Clear All Filters"
                role="button"
                bsPrefix=" "
                className="myBt clrbtn"
                onClick={resetFilters}
              >
                <LuFilterX />
              </Button>
            </div>
          )}
          <Button
            bsPrefix=" "
            title="Refresh"
            role="button"
            className="myBt"
            onClick={refresh}
          >
            <HiOutlineRefresh className="refreshFlip" />
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default Header;
