
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';

const Joi = require('joi');

const cookies = new Cookies();

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

let homepagelocation = cookies.get('gph');
if (homepagelocation) homepagelocation = homepagelocation.replace('s:', '');
if (homepagelocation) homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];

class Logos extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			// companyimage: '',
			// logoimage: ''
		};
	}

	componentWillMount() {
		// let str = {};
		// str.companyId = "";
		// str = JSON.stringify(str);
		// api.get(GLOBAL.companyImageURL, { headers: { 'query': str } }).then((response) => {
		// 	this.setState({
		// 		companyimage: response.data[0].companyImageTempURL,
		// 		logoimage: response.data[0].xsmLogoTempURL
		// 	}
		// 	)
		// });
	}
	render() {
		// let clientLogo = { backgroundImage: `url(${this.state.logoimage != null ? this.state.logoimage : dummyimage})` };

		let namedSchema = undefined;
		namedSchema = Joi.string().regex(/^[^}{)(]+$/);
		let validator_to_add_myQbj = undefined;
		let validate_summary = undefined;
		if (homepagelocation != null) {
			validator_to_add_myQbj = namedSchema.validate(homepagelocation);
			if (validator_to_add_myQbj.error != null) {
				validate_summary = "\\"
			}
			else {
				if (homepagelocation === "\\")
					validate_summary = homepagelocation;

				else
					validate_summary = "\\";
			}
		}
		else
			validate_summary = "\\"

		validate_summary = (homepagelocation == "\\" || homepagelocation == null || homepagelocation == undefined) ? "/home" : homepagelocation;

		return (
			<div className="logos">
				{/* <a href={validate_summary} className="xsmLogo" style={clientLogo} title="HCL SX">HCL SX</a> */}
				<Link to={validate_summary} className="xsmLogo" style={this.props.clientLogo}>
					HCL SX
				</Link>

				{/*<div className="logos"><a href={homepagelocation} className="xsmLogo" style={clientLogo} title="HCL SX">HCL SX</a> */}
				{/* <img src={this.state.companyimage} title="" className="comLogo img-responsive" alt="" />*/}
			</div>
		);
	}
}


export default Logos;
