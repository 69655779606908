
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Row, Col} from 'react-bootstrap';
import { connect } from 'react-redux';
import {LuThumbsUp, LuCalendarCheck2, LuAlertTriangle} from 'react-icons/lu';
import {IoClose} from "react-icons/io5";

class CmdbAssetCiDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        const tr = this.props.tr;
        // var approvals = this.props.spcmReducer.approval;
        let approvalsMeta = this.props.spcmReducer.approvalMeta;
        let ciTaskPlans = this.props.spcmReducer.ciTaskPlans;
        let ciTaskPlansCount = 0;
        if (ciTaskPlans && ciTaskPlans.length > 0 && ciTaskPlans[0].tasks) {
            ciTaskPlansCount = ciTaskPlans[0].tasks.length;
        }
        let ciChainEntitiesMeta = this.props.spcmReducer.ciChainEntitiesMeta;
        return (
            <div className="commonForm">
                <Row>
                    <Col xs={12}>
                        <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
                            <ul>
                            <li>
                            <button
                                type='button'
                                title="Minimize the right panel"
                                bsClass=""
                                bsStyle=""
                                className="closerightPanelBtn"
                                onClick={() => {
                                this.props.rightEditPanel(false);
                                }}
                            >
                                <IoClose/>
                            </button>
                            </li>
                            </ul>
                        </div>
                        <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{tr['Asset/CI Details']}</div></div>
                    </Col>
                </Row>
                <div className="rBoxGap">
                    <div className="serviceListBox">
                        <div className="servicePlist">
                            <div className="lbox" onClick={() => { this.props.changeActionView('CIApprovals'); this.props.toggleFormListFunc(false); }}><div className="hd"><span className="serIcon"><LuThumbsUp/></span>{tr['Approvals']}<span className="hdCount">({approvalsMeta ? approvalsMeta.rowCount : 0})</span></div><div className="txtBox"><div className="txt">{tr['Specify the Asset/CI approvals']}</div></div></div>
                            <div className="lbox" onClick={() => { this.props.changeActionView('taskPlan'); }}><div className="hd"><span className="serIcon"><LuCalendarCheck2/></span>{tr['Task Plan']}<span className="hdCount">({ciTaskPlansCount})</span></div><div className="txtBox"><div className="txt">{tr['Task Plan']}</div></div></div>
                            {/* <div className="lbox" onClick={() => { this.props.changeActionView('chainEntities'); }}><div className="hd"><span className="serIcon bgBtn1 sDetails p4" />{tr['Chain Entities']}<span className="hdCount">({ciChainEntitiesMeta && ciChainEntitiesMeta.rowCount ? ciChainEntitiesMeta.rowCount : 0})</span></div><div className="txtBox"><div className="txt">{tr['Specify the chain entities for the Asset/CI support']}</div></div></div> */}
                            <div className="lbox" onClick={() => { this.props.changeActionView('Disclaimers'); }}><div title={tr['Disclaimers']} className="hd"><span className="serIcon"><LuAlertTriangle/></span>{tr['Disclaimers']}</div><div className="txtBox"><div className="txt">{tr['Disclaimers']}</div></div></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = ({ spcmReducer }) => {
    return {
        tr: spcmReducer.tr,
        spcmReducer
    };
};

export default connect(mapStateToProps, {})(CmdbAssetCiDetails);