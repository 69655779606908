
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export default function(state = [], action){
	switch (action.type){
		case 'FETCH_FREEOFFERING':
			return action.freeOffering.data.data;
			default:
				return state;
		}
}
