
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef } from "react";
import { Row, Col, Accordion, Tab, Tabs, ListGroup, Button } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {PiPencilSimpleBold} from "react-icons/pi";
import {IoClose} from "react-icons/io5";
import { MyContext } from '_MyContext';
import { Link } from "react-router-dom";

const ShortDescription = (props) => {
    const navigate = useNavigate();
    const tr = useSelector((state) => state.spcmReducer.tr);
    const context = useContext(MyContext);
    const closeButton = useRef(null);
    const editButton = useRef(null);

    useEffect(() => {
        context.keyDownHandler(closeButton.current,editButton.current,"","");
        context.newTabPrevent();
    }, []);

    const rendervendorList = (vendorData) => {
        let vendorString = "";
        if (vendorData) {
            if (typeof vendorData === "string") {                
                try {
                    const parsedVendors = JSON.parse(vendorData);
                    if (Array.isArray(parsedVendors)) {
                        vendorString = parsedVendors.map((vendor) => vendor.label).join(", ");
                    }
                } catch (err) {
                    vendorString = "";
                }
            } else if (Array.isArray(vendorData)) {
                // If vendorData is already an array
                vendorString = vendorData.map((vendor) => vendor.label).join(", ");
            }
        }
    
        return vendorString;
    };
    

    return (
        <>
            <Row>
                <Col xs={12}>
                    <div className="rPageHeadActBtn" style={{ "marginTop": "-3px" }}>
                        <ListGroup bsPrefix=" " as="ul">
                            <ListGroup.Item as="li" bsPrefix=" "><Link bsPrefix=" " to={`/editContract/${props.contractData.CONTRACT_NUM}`} title={tr["Edit"]} className="editIcn ctrlKeyPrevent" ref={editButton}><PiPencilSimpleBold/></Link></ListGroup.Item>
                            <ListGroup.Item as="li" bsPrefix=" "><Link bsPrefix=" " to="javascript:void(0)" title={tr["Minimize the details panel"]} className="ctrlKeyPrevent" onClick={()=>{props.showRightSideFun(false)}} ref={closeButton}><IoClose/></Link></ListGroup.Item>
                        </ListGroup>
                    </div>
                    <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{tr["Contract Details"]}</div></div>
                </Col>
            </Row>
            <div className="rBoxGap">
                <div className="rBoxTabs">
                    <ListGroup as="ul" bsPrefix=" ">
                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable" style={{ "borderTop": "none" }}><span className="rwKey">{tr["Name"]}</span><span className="rwVal">{props.contractData.CONTRACT_NAME}</span></ListGroup.Item>

                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Company"]}</span><span className="rwVal">{props.contractData.COMPANY}</span></ListGroup.Item>

                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Business Function"]}</span><span className="rwVal">{props.contractData.BUSINESS_FUNCTION}</span></ListGroup.Item>

                        {(props.contractData.COST_CENTER != null && props.contractData.COST_CENTER !="") ? <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Cost Center"]}</span><span className="rwVal">{props.contractData.COST_CENTER}</span></ListGroup.Item> : ''}
                        
                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Status"]}</span><span className="rwVal">{props.contractData.STATUS_NAME}</span></ListGroup.Item>
                    
                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Sub Status"]}</span><span className="rwVal">{props.contractData.SUB_STATUS_NAME}</span></ListGroup.Item>

                        {(props.contractData.CONTRACT_TYPE != null && props.contractData.CONTRACT_TYPE != "")? <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Contract Type"]}</span><span className="rwVal">{props.contractData.CONTRACT_TYPE}</span></ListGroup.Item> : ''}

                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Start Date"]}</span><span className="rwVal">{props.contractData.START_DATE}</span></ListGroup.Item>
                    
                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["End Date"]}</span><span className="rwVal">{props.contractData.END_DATE}</span></ListGroup.Item>
                    
                        {(props.contractData.VENDOR && props.contractData.VENDOR !== "") &&
                         (<ListGroup.Item as="li" bsPrefix=" " className="rwTable">
                                <span className="rwKey">{tr["Vendor"]}</span>
                                <span className="rwVal">{rendervendorList(props?.contractData?.VENDOR)}</span>
                            </ListGroup.Item>
                            )}
                        {(props.contractData.VEDNOR_CONTACT != null  && props.contractData.VEDNOR_CONTACT != "") ? <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Vendor Contact"]}</span><span className="rwVal">{props.contractData.VEDNOR_CONTACT}</span></ListGroup.Item> : ''}
                    
                        {(props.contractData.BUDGET_SPONSOR != null && props.contractData.BUDGET_SPONSOR != "") ? <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Budget Sponsor"]}</span><span className="rwVal">{props.contractData.BUDGET_SPONSOR}</span></ListGroup.Item> : ''}

                        {(props.contractData.ADMINISTRATOR != null && props.contractData.ADMINISTRATOR != "")? <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Contract Manager"]}</span><span className="rwVal">{props.contractData.ADMINISTRATOR}</span></ListGroup.Item> : ''}

                        {(props.contractData.ADMINISTRATOR_GROUP != null && props.contractData.ADMINISTRATOR_GROUP != "")? <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Contract Group"]}</span><span className="rwVal">{props.contractData.ADMINISTRATOR_GROUP}</span></ListGroup.Item> : ''}
                        
                        {(props.contractData.DESCRIPTION != null && props.contractData.DESCRIPTION != "") ? <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Description"]}</span><span className="rwVal">{props.contractData.DESCRIPTION}</span></ListGroup.Item> : ''}
                    
                        {(props.contractData.VERSION != null && props.contractData.VERSION != "") ? <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Version"]}</span><span className="rwVal">{props.contractData.VERSION}</span></ListGroup.Item> : ''}
                    
                        {(props.contractData.DATE_REVISION != null && props.contractData.DATE_REVISION != "") ? <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Date of Revision"]}</span><span className="rwVal">{props.contractData.DATE_REVISION}</span></ListGroup.Item> : ''}

                        {(props.contractData.LAST_REVIEW_DATE != null && props.contractData.LAST_REVIEW_DATE != "") ? <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Last Review Date"]}</span><span className="rwVal">{props.contractData.LAST_REVIEW_DATE}</span></ListGroup.Item> : ''}

                        {(props.contractData.NEXT_REVIEW_DATE != null && props.contractData.NEXT_REVIEW_DATE != "") ? <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Next Review Date"]}</span><span className="rwVal">{props.contractData.NEXT_REVIEW_DATE}</span></ListGroup.Item> : ''}

                        {(props.contractData.CREATED_BY != null && props.contractData.CREATED_BY != "") ? <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Created By"]}</span><span className="rwVal">{props.contractData.CREATED_BY}</span></ListGroup.Item> : ''}

                        {(props.contractData.CREATED_AT != null && props.contractData.CREATED_AT != "") ? <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Created At"]}</span><span className="rwVal">{props.contractData.CREATED_AT}</span></ListGroup.Item> : ''}
                    
                        {(props.contractData.UPDATED_BY != null && props.contractData.UPDATED_BY != "") ? <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Updated By"]}</span><span className="rwVal">{props.contractData.UPDATED_BY}</span></ListGroup.Item> : ''}
                    
                        {(props.contractData.UPDATED_AT != null && props.contractData.UPDATED_AT != "") ? <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Updated At"]}</span><span className="rwVal">{props.contractData.UPDATED_AT}</span></ListGroup.Item> : ''}
                    </ListGroup>
                </div>
            </div>
        </>
    )
}

export default ShortDescription;