
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { Field, change, reduxForm } from "redux-form";
import {
  getInitialCityLocationAction,
  getInitialListLocationAction,
  getInitialStatesLocationAction,
  getLocationEditPageDetails,
} from "../../../../../actions/foundation/locationAction";
import { getCompanyOptionsList } from "../../../../../actions/foundation/commonAction";
import {
  TypeaheadExampleSingleSelect,
  TypeaheadandDropdown,
  _ckeditor,
  _dropDown,
  _inputField,
} from "../../../../common/formFields";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router";
import ListLoader from "../../../../common/loaders/ListLoader";
import { getcompanyMenuListAction } from "../../../../../actions/foundation/groupAction";

let EditForm = (props) => {
  const tr = useSelector((state) => state.spcmReducer.tr);
//   const [selectedCompany, setSelectedCompany] = useState([]);
  const [reasonValueList, setReasonValueList] = useState([]);
  const [locationTypeList, setLocationTypeList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [typeOfLocation, setTypeOfLocation] = useState('');
  const locationFieldValues = useSelector((state) => state.locationFieldData);
  const loader = useSelector((state) => state.commonLoader);
  const companyData = useSelector((state) => state.costCenterCompanyDropdown);
  const countryData = useSelector((state) => state.countryFieldData);
  const statesData = useSelector((state) => state.statesFieldData);
  const citiesData = useSelector((state) => state.citiesFieldData);
  const companyMenuValues = useSelector((state) => state.companyMenuListValues);
  const locationDetails = useSelector((state)=>state.locationEditPageDetails);
  const locEditPageInitialValues = useSelector(
    (state) => state.locationEditPageDetails
  );
  const params = useParams();
  const dispatch = useDispatch();
  let id = params.id;
  
  useEffect(() => {
    dispatch(getLocationEditPageDetails(id));
    return ()=>{
      dispatch({type : 'RESET_LOCATION_PAGE_DETAILS'})
    }
  }, []);

  useEffect(()=>{
   if(locationDetails && Object.keys(locationDetails).length>0){
      dispatch(getInitialStatesLocationAction(locationDetails.country_id));
      dispatch(getInitialCityLocationAction(locationDetails.state_id));
   }
  },[locationDetails]);

  useEffect(() => {
    dispatch(getInitialListLocationAction());
    if(locEditPageInitialValues.company_id){
      dispatch(getcompanyMenuListAction(locEditPageInitialValues.company_id, "Walk-up", "Reason of visit", '1', '0'));
      }
  }, [locEditPageInitialValues]);

  useEffect(() => {
    setTypeOfLocation(locEditPageInitialValues?.location_type);
    if(locEditPageInitialValues?.reasonOfVisit){
         let selected = [];
          companyMenuValues.filter((group) => {
            for(let i = 0; i<=locEditPageInitialValues?.reasonOfVisit.length; i++){
              if(group.id == locEditPageInitialValues?.reasonOfVisit[i]){
                selected.push(group);
              }
            }
        })
     onValueSelection(selected);
     dispatch(change("EditLocationForm", "reasonOfVisit", selected));
    }
    

  }, [locEditPageInitialValues,companyMenuValues]);

  const onCountrySelection = (event) => {
    dispatch(
      change(
        "EditLocationForm",
        "country",
        event.target[event.target.selectedIndex].value
      )
    );
    dispatch(change("EditLocationForm","countryText",event.target[event.target.selectedIndex].text));
    dispatch(
      change(
        "EditLocationForm",
        "state",
        ""
      )
    );
    dispatch(
      change(
        "EditLocationForm",
        "city",
        ""
      )
    );
    let countryId = event.target.value;
    setStateList([]);
    setCityList([]);
    dispatch(getInitialStatesLocationAction(countryId));
  };

  const onStateSelection = (event) => {
    setCityList([]);
    dispatch(
      change(
        "EditLocationForm",
        "state",
        event.target[event.target.selectedIndex].value
      )
    );
    dispatch(change("EditLocationForm", "stateText", event.target[event.target.selectedIndex].text));

    dispatch(
      change(
        "EditLocationForm",
        "city",
        ""
      )
    );
    let stateId = event.target.value;
    dispatch(getInitialCityLocationAction(stateId));
  };

  const onCitySelection = (event) => {
    dispatch(change("EditLocationForm", "city", event.target[event.target.selectedIndex].value));
    dispatch(change("EditLocationForm", "cityText", event.target[event.target.selectedIndex].text));
  }
  useEffect(() => {
    if (locationFieldValues && locationFieldValues.length > 0) {
      setLocationTypeList(locationFieldValues);
    }
  }, [locationFieldValues]);

  useEffect(() => {
    if (countryData && countryData.length > 0) {
      setCountryList(countryData);
    }
  }, [countryData]);
  useEffect(() => {
    if (statesData && statesData.length > 0) {
      setStateList(statesData);
    }
  }, [statesData]);
  useEffect(() => {
    if (citiesData && citiesData.length > 0) {
      setCityList(citiesData);
    }
  }, [citiesData]);

  const onLocationTypeChange = (event) => {
    setTypeOfLocation(event.target[event.target.selectedIndex].text);
    setReasonValueList([]);
    props.setValueError("");
    dispatch(change('EditLocationForm', 'reasonOfVisit', []))
    dispatch(
      change(
        "EditLocationForm",
        "locationType",
        event.target[event.target.selectedIndex].text
      )
    );
    }

    const onValueSelection = (selected) => {
      if ((Array.isArray(selected) && selected.length > 0)) {
        dispatch(change('EditLocationForm', 'reasonOfVisit', selected))
        setReasonValueList(selected);
        props.setValueError("");
      } else {
        props.setValueError("error");
        setReasonValueList([]);
        dispatch(change('EditLocationForm', 'reasonOfVisit', []))
      }
  };

  const onCrossClick = () => {
    setReasonValueList([]);
    props.setValueError("error");
    dispatch(change('EditLocationForm', 'reasonOfVisit', []))
}
    
    const  validateLocationEditFields = (fieldType, event)=> {
        switch (fieldType) {
          case "name":
            if (event.target.value == "") {
              props.setNameErr("error");
            } else {
              props.setNameErr("");
            }
            break;
          case "status":
            if (event.target.value == "") {
              props.setStatusErr("error");
            } else {
              props.setStatusErr("");
            }
            break;
          case "address_first":
            if (event.target.value == "") {
              props.setAddressErr("error");
            } else {
              props.setAddressErr("");
            }
            break;
          case "country":
            if (event.target.value == "") {
                props.setCountryErr("error");
                    } else {
                        props.setCountryErr("");
                    }
                break;
                case "state":
                    if (event.target.value == "") {
                        props.setStateErr("error");
                    } else {
                        props.setStateErr("");
                    }
                break;
                case "city":
                    if (event.target.value == "") {
                        props.setCityErr("error");
                    } else {
                        props.setCityErr("");
                    }
                break;
                case "zip":
                    if (event.target.value == "") {
                        props.setZipErr("error");
                    } else {
                        props.setZipErr("");
                    }
                    break;
          default:
        }
      }
  return (
      <>
          {loader.isLoading ? (
              <ListLoader />
          ) : (
              <Form>
                  <Row>
                      <Col md={6} sm={6} xs={12}>
                          <Form.Group className="form-group">
                              <Form.Label>{tr["Location Code"]}</Form.Label>
                              <Field
                                  name="code"
                                  className={"form-control"}
                                  component={_inputField}
                                  disabled
                              />
                          </Form.Group>
                      </Col>
                      <Col md={6} sm={6} xs={12}>
                          <Form.Group className="form-group">
                              <span className="rStar"></span>
                              <Form.Label>{tr["Address 1"]}</Form.Label>
                              <Field
                                  component={_inputField}
                                  name="address_first"
                                  className={"form-control " + props.nameErr}
                                  maxLength={50}
                                  onBlur={(event) => {
                                      validateLocationEditFields("address_first", event);
                                  }}
                              />
                          </Form.Group>
                      </Col>
                      <Col md={6} sm={6} xs={12}>
                          <Form.Group className="form-group">
                              <span className="rStar" />
                              <Form.Label bsClass="">{tr["Company"]}</Form.Label>
                              <Field
                                  name="company_name"
                                  className="form-control"
                                  component={_inputField}
                                  disabled
                              />
                          </Form.Group>
                      </Col>
                      <Col md={6} sm={6} xs={12}>
                          <Form.Group className="form-group">
                              <Form.Label>{tr["Address 2"]}</Form.Label>
                              <Field
                                  component={_inputField}
                                  name="address_second"
                                  className={"form-control"}
                                  maxLength={50}
                              />
                          </Form.Group>
                      </Col>

                      <Col md={6} sm={6} xs={12}>
                          <Form.Group className="form-group">
                              <span className="rStar"></span>
                              <Form.Label>{tr["Name"]}</Form.Label>
                              <Field
                                  component={_inputField}
                                  name="name"
                                  className={"form-control"}
                                  disabled
                              />
                          </Form.Group>
                      </Col>

                      <Col md={6} sm={6} xs={12}>
                          <Form.Group className="form-group">
                              <Form.Label>{tr["Address 3"]}</Form.Label>
                              <Field
                                  component={_inputField}
                                  name="address_third"
                                  className={"form-control"}
                                  maxLength={50}
                              />
                          </Form.Group>
                      </Col>
                      <Col md={6} sm={6} xs={12}>
                          <Form.Group className="form-group">
                              <Form.Label>{tr["Location Type"]}</Form.Label>
                              <Field
                                  name="locationType"
                                  className={"form-control"}
                                  component={_dropDown}
                                  options={locationTypeList}
                                  onChange={(event) => onLocationTypeChange(event)}
                              ></Field>
                          </Form.Group>
                      </Col>

                      <Col md={6} sm={6} xs={12}>
                          <Form.Group className="form-group">
                              <span className="rStar"></span>
                              <Form.Label>{tr["Status"]}</Form.Label>
                              <Field
                                  name="status"
                                  component="select"
                                  className={"form-control " + props.statusErr}
                                  onBlur={(event) => {
                                      validateLocationEditFields("status", event);
                                  }}
                              >
                                  <option value="">{tr["Select"]}</option>
                                  <option value="1">{tr["Active"]}</option>
                                  <option value="0">{tr["Inactive"]}</option>
                              </Field>
                          </Form.Group>
                      </Col>
                      <Col md={3} sm={3} xs={12}>
                          <Form.Group className="form-group">
                              <span className="rStar"></span>
                              <Form.Label>{tr["Country"]}</Form.Label>
                              <Field
                                  name="country"
                                  className={"form-control "+ props.countryErr}
                                  component={_dropDown}
                                  options={countryList}
                                  onBlur={(event) => { validateLocationEditFields("country", event); }}
                                  onChange={(e) => onCountrySelection(e)}
                              ></Field>
                          </Form.Group>
                      </Col>

                      <Col md={3} sm={3} xs={12}>
                          <Form.Group className="form-group">
                              <span className="rStar"></span>
                              <Form.Label>{tr["State"]}</Form.Label>
                              <Field
                                  name="state"
                                  className={"form-control "+ props.stateErr}
                                  component={_dropDown}
                                  options={stateList}
                                  onBlur={(event) => { validateLocationEditFields("state", event); }}
                                  onChange={(event) => onStateSelection(event)}
                              ></Field>
                          </Form.Group>
                      </Col>

                      <Col md={3} sm={3} xs={12}>
                          <Form.Group className="form-group">
                              <span className="rStar"></span>
                              <Form.Label>{tr["City"]}</Form.Label>
                              <Field
                                  name="city"
                                  className={"form-control "+ props.cityErr}
                                  component={_dropDown}
                                  options={cityList}
                                  onBlur={(event) => { validateLocationEditFields("city", event); }}
                                  onChange={(event) => onCitySelection(event)}
                              ></Field>
                          </Form.Group>
                      </Col>

                      <Col md={3} sm={3} xs={12}>
                          <Form.Group className="form-group">
                              <span className="rStar"></span>
                              <Form.Label>{tr["Zip"]}</Form.Label>
                              <Field
                                  component={_inputField}
                                  name="zip"
                                  className={"form-control " + props.zipErr}
                                  maxLength={50}
                                  onBlur={(event) => {
                                      validateLocationEditFields("zip", event);
                                  }}
                              />
                          </Form.Group>
                      </Col>

          {typeOfLocation == 'Walk-up'? (<Col md={6} sm={6} xs={12}>
          <Form.Group className="form-group">
            <span className="rStar" />
            <Form.Label bsClass="">
              {["Reason Of Visit"]}
            </Form.Label>
            <Field
             component={TypeaheadandDropdown}
             onSelection={onValueSelection}
             name="reasonOfVisit"
             className="form-control"
             options={companyMenuValues}
             selectedOptions={reasonValueList}
             onCrossClick={onCrossClick}
             errorClass={props.valueError === "error" ? "error" : ""}
             multiple={true}
            />
          </Form.Group>
        </Col>): ''}
                <Col md={12}>
                <Form.Group className="form-group">
			    			<Form.Label bsClass=""> {tr['Description']}</Form.Label>
			    			<Field name="description" component={_ckeditor} className={"reactQuillEditor-area"} richTextConfig ={"minRichTextEditor"}/>
			    			</Form.Group>
                </Col>
                {typeOfLocation !== 'Walk-up'? (<Col md={12}>
                 <Form.Group className="form-group">
					     	<Form.Label bsClass=""> {tr['Support Hours']}</Form.Label>
				    		<Field name="supportHours" component={_ckeditor} className={"reactQuillEditor-area"} richTextConfig ={"minRichTextEditor"}
                />
				    		</Form.Group>
                </Col>):(<Col md={12}>
                <Form.Group className="form-group">
                <span className="rStar" />
			     			<Form.Label bsClass=""> {tr['Support Hours']}</Form.Label>
			    			<Field name="supportHours" component={_ckeditor} className={"reactQuillEditor-area"} richTextConfig ={"minRichTextEditor"}
                />
                <div style={{ fontSize: '13px', color: 'red', paddingTop: '2px' }}>
                {props.supportHrsErr}
                </div>
				    		</Form.Group>
            
          </Col>)}
                <Col md={12}>
                <Form.Group className="form-group">
			    			<Form.Label bsClass=""> {tr['Accessibility Instructions']}</Form.Label>
			    			<Field name="accessInstruction" component={_ckeditor} className={"reactQuillEditor-area"} richTextConfig ={"minRichTextEditor"}/>
			     			</Form.Group>
                  </Col>
                      <Col md={6} sm={6} xs={12}>
                          <Form.Group className="form-group">
                              <Form.Label>{tr["Created Date"]}</Form.Label>
                              <Field
                                  name="createdDate"
                                  className={"form-control"}
                                  component={_inputField}
                                  disabled
                              />
                          </Form.Group>
                      </Col>
                      <Col md={6} sm={6} xs={12}>
                          <Form.Group className="form-group">
                              <Form.Label>{tr["Modified Date"]}</Form.Label>
                              <Field
                                  name="modified_date"
                                  className={"form-control"}
                                  component={_inputField}
                                  disabled
                              />
                          </Form.Group>
                      </Col>
                      <Col md={6} sm={6} xs={12}>
                          <Form.Group className="form-group">
                              <Form.Label>{tr["Created By"]}</Form.Label>
                              <Field
                                  name="created_by"
                                  className={"form-control"}
                                  component={_inputField}
                                  disabled
                              />
                          </Form.Group>
                      </Col>
                      <Col md={6} sm={6} xs={12}>
                          <Form.Group className="form-group">
                              <Form.Label>{tr["Modified By"]}</Form.Label>
                              <Field
                                  name="modified_by"
                                  className={"form-control"}
                                  component={_inputField}
                                  disabled
                              />
                          </Form.Group>
                      </Col>
                  </Row>
              </Form>)}
    </>
  );
};

EditForm = reduxForm({
  form: "EditLocationForm",
  enableReinitialize: true,
  destroyOnUnmount: true,
})(EditForm);
EditForm = connect(({ locationEditPageDetails, locationCompaniesMap, locationCountriesMap }) => ({
    initialValues: {
        createdDate: locationEditPageDetails?.create_date,
        created_by : locationEditPageDetails?.created_by,
        modified_by: locationEditPageDetails?.last_updated_by,
        modified_date: locationEditPageDetails?.last_updated_date,
        status: locationEditPageDetails?.status,
        zip: locationEditPageDetails?.location_zip,
        name: locationEditPageDetails?.location_name,
        code: locationEditPageDetails?.location_code,
        locationType: locationEditPageDetails?.location_type,
        address_first:  locationEditPageDetails?.location_address1,
        address_second: locationEditPageDetails?.location_address2,
        address_third: locationEditPageDetails?.location_address3,
        company_name: locationEditPageDetails?.company_name,
        company_id: locationEditPageDetails?.company_id,
        country: locationEditPageDetails?.country_id,
        countryText:locationEditPageDetails?.country_name,
        state: locationEditPageDetails?.state_id,
        stateText: locationEditPageDetails?.state_name,
        city:locationEditPageDetails?.city_id,
        cityText: locationEditPageDetails?.city_name,
        description: locationEditPageDetails?.description,
        supportHours: locationEditPageDetails?.support_hours,
        accessInstruction:locationEditPageDetails?.accessibility,
        reasonOfVisit: locationEditPageDetails?.reasonOfVisit
  },
}))(EditForm);

export default EditForm;
