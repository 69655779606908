
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import {connect} from 'react-redux';
import {Row, Col, Button} from 'react-bootstrap';
import { getArticleById} from '_Actions/knowledge/knowledgeAction';
import sanitizeHtml from 'sanitize-html';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

let username = cookies.get('gph');
if (username) username = username.replace('s:', '');
if (username) username = username.substring(0, username.lastIndexOf('.'));
username = username.split("~");
username = username[32];

class ArticleDetails extends React.Component {
	constructor(props) {
		super(props);
		// console.log('props - ', {props});
		this.state ={
			
		};
	};
    componentWillMount() {
        this.props.getArticleById(this.props.articleDetails.articleID, username)
    }
   render() {

   	// const { searchArticle, showSearchInput, searchStringErrorLabel } = this.state;
	   const {
        articleDetails: {
            knowledgeArticle,
            type,
            knowledgeDescription,
            articleUrl = ""
        },
        translator = {}
       } = this.props;
        let iconClass = (type === 'SPCM') ? 'KA-icon interLnk1 margin-r-5' : (
            (type === 'Knowledge' || type === 'conversation') ? 'KA-icon exterLnk1 margin-r-5' : 'KA-icon communityWh'
        );

      return (
      	<div className="knowledge-description-section">
                <Row>
                    <Col xs={12}>
                        <div className="rPageHeading"><div className="f-size-17 margin-t-0 margin-b-5 servKaArticles"><span className={iconClass} title={type== 'conversation' ? 'Knowledge' :translator[type]}></span>{knowledgeArticle}</div></div>
                    </Col>
                    {/* <Col xs={12}>
                <div className="artDetBtnChout">
                    <Button bsClass='' title="Back" bsSize="small" type="button" className="back"
                    onClick={() => {
                        this.props.closeDescription();
                    }}
                    ><i className="fa fa-angle-left margin-r-5" aria-hidden="true"></i>Back</Button>
                    <Button bsClass='' title={(type === 'SPCM') ? translator["Download"] : translator["Redirect"]} bsSize="small" type="button" className="rgtbtnchk"
                    onClick={() => {
                        this.props.downloadFile(articleUrl)
                    }}
                    >
                        <i className={(type === 'SPCM') ? "fa fa-download margin-r-5" : "fa fa-location-arrow margin-r-5"}></i>
                        {(type === 'SPCM') ? translator["Download"] : translator["Redirect"]}
                    </Button></div>
                    </Col>                     */}
                </Row>
                <div className="rBoxGap">
            <Row>
                <Col md={12}>
                    {/* {knowledgeDescription} */}
                    {(type === 'SPCM') ? (
                        <div className="KA-description" >{knowledgeDescription}</div>
                    ) : (
                        <div className="KA-description" dangerouslySetInnerHTML={{ __html: sanitizeHtml(this.props.articleDetailsById, {allowedAttributes: {
                            '*': ['style', 'class', 'dir', 'bgcolor'],
                            'table': ['border', 'cellpadding', 'cellspacing'],
                            'th': ['scope']
                          }}) }} />
                    )}
                    
                </Col>
                <Col md={12}>
                    <Button bsSize="small" type="button" className="myBtn rgSiLigBtn smallBtn"
                    onClick={() => {
                        this.props.closeDescription();
                    }}
                    >{translator["Close"]}</Button>
                    <Button bsSize="small" type="button" className="myBtn KA-download-btn rgSidrkBtn smallBtn"
                    onClick={() => {
                        this.props.downloadFile(articleUrl)
                    }}
                    >
                        <i className={(type === 'SPCM') ? "fa fa-download margin-r-5" : "fa fa-location-arrow margin-r-5"}></i>
                        {(type === 'SPCM') ? translator["Download"] : translator["Redirect"]}
                    </Button>
                </Col>
            </Row>
            </div>
		</div>
      );
   }
}


// export default BreakFix;

const mapStateToProps = ({articleDetails}) => {
	return {
		articleDetailsById: articleDetails
	};
};


export default connect(mapStateToProps,{getArticleById})(ArticleDetails);
