
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export default function favoritesReducer(state = [],action){
  switch (action.type) {
    case 'FETCH_FAVOURITE':
      return action.favorites.data.data;
    default:
      return state;
    }
}
