
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import moment from "moment";
import { Field } from "redux-form";
import { Table, Alert, ListGroupItem } from "react-bootstrap";
import { DateField, DatePicker } from "react-date-picker";
import swal from "sweetalert";
import TimePicker from "rc-time-picker";
import Datetime from "react-datetime";
import Cookies from "universal-cookie";
import { isNull } from "lodash";
import { Typeahead } from "react-bootstrap-typeahead";
import { GLOBAL } from "_Globals";
import sanitizeHtml from 'sanitize-html';

const Joi = require("joi");

const cookies = new Cookies();

let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
//console.log("homepage::::"+homepagelocation);
dateformat = dateformat.split("~");
dateformat = dateformat[2];

//console.log("+df:::::"+dateformat);

//import Datetime from 'react-datetime';

export const _textArea = ({
  input,
  label,
  type,
  className,
  rows,
  disabledValue,
  maxlength,
  meta: { touched, error },
}) => {
  return (
    <div>
      <textarea
        {...input}
        className={touched && error ? className + " error" : className}
        rows={rows}
        placeholder={label}
        disabled={disabledValue}
        maxLength={maxlength}
      />
      {touched && error && <span className="error"> {error} </span>}
      {}
    </div>
  );
};

export const _textAreaMaxlength = ({
  input,
  label,
  type,
  className,
  rows,
  disabledValue,
  meta: { touched, error },
}) => {
  return (
    <div>
      <textarea
        maxLength="4000"
        {...input}
        className={touched && error ? className + " error" : className}
        rows={rows}
        placeholder={label}
        disabled={disabledValue}
      />
      {touched && error && <span className="error"> {error} </span>}
      {}
    </div>
  );
};

export const _textAreaMaxlength2 = ({
  input,
  label,
  type,
  className,
  rows,
  disabledValue,
  meta: { touched, error },
}) => {
  return (
    <div>
      <textarea
        maxLength="10000"
        {...input}
        className={touched && error ? className + " error" : className}
        rows={rows}
        placeholder={label}
        disabled={disabledValue}
      />
      {touched && error && <span className="error"> {error} </span>}
      {}
    </div>
  );
};

export const _disclaimerCheckbox = ({
  input,
  label,
  disabled,
  onChange,
  className,
  value,
  checked,
  placeholder,
  readOnly,
  touched,
  error /*meta: { touched, error } */,
}) => {
  console.log("Disclaimer is called");

  let namedSchema = undefined;
  namedSchema = Joi.string().regex(/^[^}{)(]+$/);
  let validator_to_add_myQbj = undefined;
  let validate_summary = undefined;

  if (label != null || label != "") {
    validator_to_add_myQbj = namedSchema.validate(label);
    if (validator_to_add_myQbj.error == null) {
      validate_summary = label;
    } else {
      console.log("Validation Result : ", validator_to_add_myQbj);
      validate_summary = "";
    }
  } else validate_summary = "";

  //let disclaimerLabel = {__html: label};
  let disclaimerLabel = { __html: sanitizeHtml(validate_summary, {allowedAttributes: {
    '*': ['style', 'class', 'dir', 'bgcolor'],
    'table': ['border', 'cellpadding', 'cellspacing'],
    'th': ['scope']
    }}) };
  return (
    <div className={touched && error ? "errorBox" : ""}>
      <label>
        <input
          {...input}
          type="checkbox"
          disabled={disabled}
          checked={checked}
          onClick={onChange}
        />{" "}
        <span className="styledChk"></span>
        <div
          dangerouslySetInnerHTML={disclaimerLabel}
          className="custmChkLbl"
        ></div>
      </label>
    </div>
  );
};

export const _dropDownNotification = ({
  input,
  label,
  type,
  className,
  options,
  disabledValue,
  meta: { touched, error },
}) => {
  // console.log("option name",input.name,options);

  // options=grouplist;
  return (
    <div>
      <select
        {...input}
        className={touched && error ? className + " error" : className}
        disabled={disabledValue}
      >
        <option value="0">Select</option>

        {renderDropDownOptions(input.name, options)}
      </select>
      {touched && error && <span className="error"> {error} </span>}
    </div>
  );
};

export const _dropDown = ({
  input,
  label,
  type,
  className,
  options,
  disabledValue,
  meta: { touched, error },
  module = "",
  translator,
}) => {
  // console.log("option name", input.name, options, GLOBAL.AMSEXIST);
  // console.log(GLOBAL.valueAssign, GLOBAL.valueAssignImpgrp, GLOBAL.Foundationgrp, GLOBAL.FoundationgrpImp)
  let grouplist = [];
  // if (input.name == "showAllgroups" || input.name == "ShowImplementationAllgrp") {
  //   GLOBAL.onlyAllgrpchangemngmt = true;
  //   GLOBAL.onlyAllgrpimplmt = true;
  //   GLOBAL.onlyAllgrpCreatechangemngmt = true;
  //   GLOBAL.onlyAllgrpCreateimplmt = true;
  // }

  if (input.name == "implementationGroup") {
    GLOBAL.grpname = true;

    // if (input.name == "showAllgroups" || input.name == "ShowImplementationAllgrp") {

    //   options.map((breakFixObj) => {
    //     grouplist.push(breakFixObj.groupId + "~" + breakFixObj.groupName)
    //   });
    //   // grouplist.push(localStorage.getItem('grpid')+"~"+localStorage.getItem('grpname'));
    //   // grouplist.push('1234'+'~'+"hcl");
    //   // console.log("localStorage.getItem('grpid')",localStorage.getItem('grpid'),localStorage.getItem('grpname'));
    // }
    // else {
    if (GLOBAL.valueAssignImpgrp == true || GLOBAL.FoundationgrpImp == true) {
      options.map((breakFixObj) => {
        //    console.log("wrong data",breakFixObj.groupId==undefined);
        //    if(breakFixObj.groupId==undefined)
        //    {
        //      console.log("removed");
        //    }
        // else{
        grouplist.push(breakFixObj.groupId + "~" + breakFixObj.groupName);
        // }
      });
    } else {
      // console.log("GLOBAL VALUESS");
      options.map((breakFixObj) => {
        // if(breakFixObj.assignmentGroupId==undefined)
        // {
        //   console.log("removed");
        // }
        // else{
        grouplist.push(
          breakFixObj.assignmentGroupId + "~" + breakFixObj.assignmentGroupName
        );
        // }
      });
    }
    if (localStorage.getItem("grpid") != "undefined") {
      if (
        localStorage.getItem("grpname") != "null" &&
        (GLOBAL.AMSEXIST == "yes" || GLOBAL.AMSCREATEEXIST == "yes") &&
        input.name == "changeManagementGroup"
      ) {
        // console.log("groplist", grouplist);
        grouplist.push(
          localStorage.getItem("grpid") + "~" + localStorage.getItem("grpname")
        );
      }
    }
    if (localStorage.getItem("implgrpid") != "undefined") {
      if (
        localStorage.getItem("impgrpname") != "null" &&
        (GLOBAL.AMSEXISTIMP == "yes" || GLOBAL.AMSCREATEIMPEXIST == "yes") &&
        input.name == "implementationGroup"
      ) {
        grouplist.push(
          localStorage.getItem("implgrpid") +
            "~" +
            localStorage.getItem("impgrpname")
        );
      }
    }
    options = grouplist;
  }
  if (input.name == "changeManagementGroup") {
    GLOBAL.grpname = true;

    // if (input.name == "showAllgroups" || input.name == "ShowImplementationAllgrp") {

    //   options.map((breakFixObj) => {
    //     grouplist.push(breakFixObj.groupId + "~" + breakFixObj.groupName)
    //   });
    //   // grouplist.push(localStorage.getItem('grpid')+"~"+localStorage.getItem('grpname'));
    //   // grouplist.push('1234'+'~'+"hcl");
    //   // console.log("localStorage.getItem('grpid')",localStorage.getItem('grpid'),localStorage.getItem('grpname'));
    // }
    // else {
    if (GLOBAL.valueAssign == true || GLOBAL.Foundationgrp == true) {
      options.map((breakFixObj) => {
        //    console.log("wrong data",breakFixObj.groupId==undefined);
        //    if(breakFixObj.groupId==undefined)
        //    {
        //      console.log("removed");
        //    }
        // else{
        grouplist.push(breakFixObj.groupId + "~" + breakFixObj.groupName);
        // }
      });
    } else {
      // console.log("GLOBAL VALUESS");
      options.map((breakFixObj) => {
        // if(breakFixObj.assignmentGroupId==undefined)
        // {
        //   console.log("removed");
        // }
        // else{
        grouplist.push(
          breakFixObj.assignmentGroupId + "~" + breakFixObj.assignmentGroupName
        );
        // }
      });
    }
    if (localStorage.getItem("grpid") != "undefined") {
      if (
        localStorage.getItem("grpname") != "null" &&
        (GLOBAL.AMSEXIST == "yes" || GLOBAL.AMSCREATEEXIST == "yes") &&
        input.name == "changeManagementGroup"
      ) {
        // console.log("groplist", grouplist);
        grouplist.push(
          localStorage.getItem("grpid") + "~" + localStorage.getItem("grpname")
        );
      }
    }
    if (localStorage.getItem("implgrpid") != "undefined") {
      if (
        localStorage.getItem("impgrpname") != "null" &&
        (GLOBAL.AMSEXISTIMP == "yes" || GLOBAL.AMSCREATEIMPEXIST == "yes") &&
        input.name == "implementationGroup"
      ) {
        grouplist.push(
          localStorage.getItem("implgrpid") +
            "~" +
            localStorage.getItem("impgrpname")
        );
      }
    }
    // console.log("localStorage.getItem('grpid')",localStorage.getItem('grpid'),localStorage.getItem('grpname'));
    options = grouplist;
  }

  //  console.log("suggestions1",grouplist);
  //  console.log("condition for calling right dropdown",((GLOBAL.grpname==true)&&(input.name=="showAllgroups"||input.name=="changeManagementGroup")),GLOBAL.grpname==false,input.name=="showAllgroups",input.name=="changeManagementGroup");
  // for(let i in grouplist)
  // {
  //   console.log('ok',grouplist[i]=="undefined~undefined");
  //   if(grouplist[i]!="undefined~undefined")
  //   {

  //   grouplist=grouplist.push
  //   }
  // }

  // console.log("options??", options);
  // // }
  // options=grouplist;
  return (
    <div>
      <select
        {...input}
        className={touched && error ? className + " error" : className}
        disabled={disabledValue}
      >
        {!(module == "editChangeMgmt") && (
          <option value="0">
            {typeof translator !== "undefined"
              ? translator["Select"]
              : "Select"}
          </option>
        )}
        {GLOBAL.grpname == true &&
        (input.name == "showAllgroups" ||
          input.name == "changeManagementGroup" ||
          input.name == "implementationGroup" ||
          input.name == "ShowImplementationAllgrp")
          ? renderDropDownOptionsChangeManagementGrp(options)
          : renderDropDownOptions(input.name, options)}
      </select>
      {touched && error && <span className="error"> {error} </span>}
    </div>
  );
};
export const _dropDown3 = ({
  input,
  label,
  type,
  className,
  options,
  disabledValue,
  meta: { touched, error },
}) => {
  return (
    <div>
      <select
        {...input}
        className={touched && error ? className + " error" : className}
        disabled={disabledValue}
      >
        <option value="-1">Select</option>
        <option value="0">Requester Location Timezone</option>
        {renderDropDownOptions("workdayScheduleTimeZoned", options)}
        {renderDropDownOptions(input.name, options)}
      </select>
      {touched && error && <span className="error"> {error} </span>}
    </div>
  );
};
export const _dropDown1 = ({
  input,
  label,
  type,
  className,
  options,
  disabledValue,
  meta: { touched, error },
}) => {
  return (
    <div>
      <select
        {...input}
        className={touched && error ? className + " error" : className}
        disabled={disabledValue}
      >
        <option value="0">Select</option>
        {renderDropDownOptions(input.name, options)}
      </select>
      {touched && error && <span className="error"> {error} </span>}
    </div>
  );
};

export const _dropDown2 = ({
  input,
  label,
  type,
  className,
  options,
  disabledValue,
  meta: { touched, error },
}) => {
  return (
    <div>
      <select
        {...input}
        className={touched && error ? className + " error" : className}
        disabled={disabledValue}
      >
        {renderDropDownOptions(input.name, options)}
      </select>
      {touched && error && <span className="error"> {error} </span>}
    </div>
  );
};
const renderDropDownOptionsChangeManagementGrp = (options) => {
  // let one=1
  // console.log("one");
  // one=one+1;
  //   var filtered = options.filter(function(x) {
  //     console.log("object",x);

  //     return x !== "undefined~undefined";
  // });

  GLOBAL.grpname = false;
  // let breakfixobj1=options.split("~");
  // console.log("options????",options);
  if (options != "") {
    options = options.filter(function (item, index, inputArray) {
      // console.log("inputArray.indexOf(item)",inputArray.indexOf(item),index);
      return inputArray.indexOf(item) == index;
    });
    // console.log("last",options);
    return options.map((breakFixObj) => {
      let breakfixobj1 = breakFixObj.split("~");
      // console.log("object",breakfixobj1[1]);
      return <option value={breakfixobj1[0]}>{breakfixobj1[1]}</option>;
    });
  }
};
const renderDropDownOptions = (dropDownFor, options) => {
  switch (dropDownFor) {
    /* --- SERVICE PORTFOLIO MANAGER DROP DOWN STARTS ---*/
    case "category":
      return options.map((option, index) => (
        <option value={option.id} key={index}>
          {option.categoryName}
        </option>
      ));
    case "subCategory":
      return options.map((option, index) => (
        <option value={option.id} key={index}>
          {option.subCategoryName}
        </option>
      ));
    case "portfolioGroup":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "catalogGroup":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "portfolioManager":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "catalogManager":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "status":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "subStatus":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "name":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));

    /* --- SERVICE PORTFOLIO MANAGER DROP DOWN ENDS ---*/

    /* change management drop down starts */
    case "type":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "changeTiming":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "overallTechnicalRisk":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "overallBusinessRisk":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));

    case "changeManagementIndividual":
      // console.log("okiee", options);
      return options.map((option, index) => (
        <option
          value={
            option.assignmentMemberId
              ? option.assignmentMemberId
              : option.field1Value
          }
          key={index}
        >
          {option.assignmentMemberName
            ? option.assignmentMemberName
            : option.field1Key}
        </option>
      ));
    case "implementationGroup":
      return options.map((option, index) => (
        <option value={option.assignmentGroupId} key={index}>
          {option.assignmentGroupName}
        </option>
      ));
    case "showAllgroups":
      return options.map((option, index) => (
        <option value={option.GROUPID} key={index}>
          {option.GROUPNAME}
        </option>
      ));

    case "implementationIndividual":
      return options.map((option, index) => (
        <option
          value={
            option.assignmentMemberId
              ? option.assignmentMemberId
              : option.field1Value
          }
          key={index}
        >
          {option.assignmentMemberName
            ? option.assignmentMemberName
            : option.field1Key}
        </option>
      ));
    case "urgency":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "reasonForChange":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "riskOccurrenceProbability":
      return options.map((option, index) => (
        <option value={option.field1Key} key={index}>
          {option.field1Key}
        </option>
      ));
    case "riskImpact":
      return options.map((option, index) => (
        <option value={option.field1Key} key={index}>
          {option.field1Key}
        </option>
      ));
    case "controlEffectiveness":
      return options.map((option, index) => (
        <option value={option.field1Key} key={index}>
          {option.field1Key}
        </option>
      ));

    /* change management drop down ends */

    /* task dropdown starts */

    case "assignedToGroupId":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "assignedToId":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "sequenceNumber":
      return options.map((option, index) => (
        <option value={option.value} key={index}>
          {option.key}
        </option>
      ));
    case "sequenceType":
      return options.map((option, index) => (
        <option value={option.value} key={index}>
          {option.name}
        </option>
      ));
    case "statusId":
      return options.map((option, index) => (
        <option value={option.value} key={index}>
          {option.name}
        </option>
      ));

    /* task dropdown ends */

    /* wrkSchedule dropdown Start */
    case "scheduleCompany":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "scheduleStatus":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    case "workdayScheduleTimeZone":
      return options.map((option, index) => (
        <option value={option.field1Key} key={index}>
          {option.field2Value}
        </option>
      ));
    case "workdayScheduleTimeZoned":
      return <hr />;
    case "reportedThrough":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));

    /* wrkSchedule dropdown ends */

    /* investigationTeam dropdown starts */
    case "itGroup":
      return options.map((option, index) => (
        <option value={option.GROUPID} key={index}>
          {option.GROUPNAME}
        </option>
      ));
    case "itMember":
      return options.map((option, index) => (
        <option value={option.field1Value} key={index}>
          {option.field1Key}
        </option>
      ));
    /* investigationTeam dropdown ends */
    case "requesterLocationId":
      return options.map((option, index) => (
        <option value={option.LOCATIONS} key={index}>
          {option.Name}
        </option>
      ));
    case "country":
      return options.map((option, index) => (
        <option value={option.COUNTRY_ID} key={index}>
          {option.COUNTRY_NAME}
        </option>
      ));
    case "cmdbUpdateNeeded":
      return options.map((option, index) => (
        <option value={option.objkey} key={index}>
          {option.objval}
        </option>
      ));
    default:
      return options.map((option, index) => (
        <option value={option.value} key={index}>
          {option.label}
        </option>
      ));
  }
};

export const _dateField = ({
  input,
  placeholder,
  defaultValue,
  className,
  formType,
  disabledValue,
  handleTimeChange,
  scheduledDay,
  meta: { touched, error },
}) => {
  let jwtdf = dateformat.slice(0, 10).toUpperCase();
  switch (formType) {
    case "edit":
      return (
        <div className="dateTimeSdiv">
          {console.log("WorkDayDchedule----")}
          <DateField
            className={touched && error ? className + " error" : className}
            expandOnFocus={false}
            dateFormat={jwtdf + " HH:mm:ss"}
            updateOnDateClick
            forceValidDate
            defaultValue={defaultValue}
            minDate={moment()}
            disabled={disabledValue}
          >
            <DatePicker
              {...input}
              selected={input.value ? moment(input.value) : null}
              navigation
              locale="en"
              forceValidDate
              highlightWeekends
              highlightToday
              weekNumbers={false}
              weekStartDay={1}
            ></DatePicker>
          </DateField>
          {touched && error && <span className="error"> {error} </span>}
        </div>
      );
    case "create":
      return (
        <div className="dateTimeSdiv">
          {/* <DateField
            className={touched && error ? className + " error" : className}
            expandOnFocus={false}
            dateFormat={jwtdf + " HH:mm:ss"}
            updateOnDateClick
            forceValidDate
            defaultValue={moment()}
            minDate={moment()}
            disabled={disabledValue}
          >
            <DatePicker
              {...input}
              selected={input.value ? moment(input.value) : null}
              navigation
              locale="en"
              forceValidDate={false}
              highlightWeekends
              highlightToday
              weekNumbers={false}
              weekStartDay={1}
              todayButton={false}
            ></DatePicker>
          </DateField> */}
          {touched && error && <span className="error"> {error} </span>}
        </div>
      );
    case "timePicker":
      return (
        <div className="dateTimeSdiv">
          <TimePicker
            defaultValue={defaultValue}
            format={"HH:mm"}
            onChange={(time) => {
              handleTimeChange(time, scheduledDay);
            }}
            showSecond={false}
          />
          {error ? <span className="error">{error}</span> : null}
        </div>
      );
    case "datePicker":
      return (
        <div className="dateTimeSdiv">
          <DateField
            className={touched && error ? className + " error" : className}
            expandOnFocus={false}
            dateFormat="DD-MM-YYYY"
            updateOnDateClick
            forceValidDate
            defaultValue={defaultValue}
            minDate={moment()}
            disabled={disabledValue}
          >
            <DatePicker
              {...input}
              selected={input.value ? moment(input.value) : null}
              navigation
              locale="en"
              forceValidDate={false}
              highlightWeekends
              highlightToday
              weekNumbers={false}
              weekStartDay={1}
              todayButton={false}
            ></DatePicker>
          </DateField>
          {touched && error && <span className="error"> {error} </span>}
        </div>
      );
    case "datePicker-edit":
      return (
        <div className="dateTimeSdiv">
          <DateField
            className={touched && error ? className + " error" : className}
            expandOnFocus={false}
            dateFormat="DD-MM-YYYY"
            updateOnDateClick
            forceValidDate
            defaultValue={defaultValue}
            minDate={moment()}
            disabled={disabledValue}
          >
            <DatePicker
              {...input}
              selected={input.value ? moment(input.value) : null}
              navigation
              locale="en"
              forceValidDate={false}
              highlightWeekends
              highlightToday
              weekNumbers={false}
              weekStartDay={1}
              todayButton={false}
              cancelButton={false}
              clearButton={false}
            ></DatePicker>
          </DateField>
          {touched && error && <span className="error"> {error} </span>}
        </div>
      );
  }
};

export const _dateTimeField = ({
  input,
  placeholder,
  defaultValue,
  className,
  formType,
  disabledValue,
  handleTimeChange,
  scheduledDay,
  showInputValue = true,
  meta: { touched, error },
}) => {
  let jwtdf = dateformat.slice(0, 10).toUpperCase();
  var yesterday = Datetime.moment().subtract(1, "day");
  var valid = function (current) {
    return current.isAfter(yesterday);
  };
  switch (formType) {
    case "editDelegate":
      let delegateDate = moment(defaultValue).format(jwtdf + " HH:mm:ss");
      console.log("defaultValue : ", defaultValue);
      console.log("delegateDate : ", delegateDate);
      return (
        <div className="dateTimeSdiv">
          {/* <DateField className={(touched && error) ? className + " error" : className} expandOnFocus={false} dateFormat={"MM-DD-YYYY HH:mm:ss"} updateOnDateClick forceValidDate defaultValue={defaultValue} minDate={moment()} disabled={disabledValue}>
						<DatePicker {...input} selected={input.value ? moment(input.value) : null} navigation locale="en" forceValidDate highlightWeekends highlightToday weekNumbers={false} weekStartDay={1}></DatePicker>
					</DateField> */}
          <Datetime
            {...input}
            className={touched && error ? className + " error" : className}
            locale="en"
            timeFormat="HH:mm:ss"
            isValidDate={valid}
            inputProps={{ disabled: disabledValue, value: delegateDate }}
            value={defaultValue}
          />
          {touched && error && <span className="error"> {error} </span>}
        </div>
      );
    case "edit":
      return (
        <div className="dateTimeSdiv">
          {/* <DateField className={(touched && error) ? className + " error" : className} expandOnFocus={false} dateFormat={"MM-DD-YYYY HH:mm:ss"} updateOnDateClick forceValidDate defaultValue={defaultValue} minDate={moment()} disabled={disabledValue}>
						<DatePicker {...input} selected={input.value ? moment(input.value) : null} navigation locale="en" forceValidDate highlightWeekends highlightToday weekNumbers={false} weekStartDay={1}></DatePicker>
					</DateField> */}
          <Datetime
            {...input}
            className={touched && error ? className + " error" : className}
            locale="en"
            dateFormat={jwtdf}
            timeFormat={true}
            inputProps={{ disabled: disabledValue }}
            defaultValue={defaultValue}
          />
          {touched && error && <span className="error"> {error} </span>}
        </div>
      );
    case "editChangeDowntime":
      defaultValue =
        defaultValue == ""
          ? ""
          : defaultValue == "default"
          ? moment()
          : defaultValue;
      let defaultValues =
        typeof defaultValue == "object" && defaultValue !== null
          ? defaultValue
          : moment(defaultValue, jwtdf + " HH:mm:ss");
      console.log("defualt", defaultValues);
      return (
        <div className="dateTimeSdiv">
          <Datetime
            {...input}
            className={touched && error ? className + " error" : className}
            locale="en"
            dateFormat={jwtdf}
            timeFormat={true}
            inputProps={{ disabled: disabledValue }}
            defaultValue={defaultValues}
            value={defaultValues}
          />
          {touched && error && <span className="error"> {error} </span>}
        </div>
      );
    case "editChange":
      defaultValue = defaultValue == "" ? moment() : defaultValue;
      let defaultVal =
        typeof defaultValue == "object" && defaultValue !== null
          ? defaultValue
          : moment(defaultValue, jwtdf + " HH:mm:ss");
      if (showInputValue == false) {
        defaultVal = "";
      }
      // console.log("defaultVal",defaultVal);
      return (
        <div className="dateTimeSdiv">
          <Datetime
            {...input}
            className={touched && error ? className + " error" : className}
            locale="en"
            dateFormat={jwtdf}
            timeFormat={true}
            inputProps={{ disabled: disabledValue }}
            defaultValue={defaultVal}
            value={defaultVal}
          />
          {touched && error && <span className="error"> {error} </span>}
        </div>
      );
    case "create":
      return (
        <div className="dateTimeSdiv">
          {/* <DateField className={(touched && error) ? className + " error" : className} expandOnFocus={false} dateFormat={jwtdf + " HH:mm:ss"} updateOnDateClick forceValidDate defaultValue={moment()} minDate={moment()} disabled={disabledValue}>
						<DatePicker {...input} selected={input.value ? moment(input.value) : null} navigation locale="en" forceValidDate={true} highlightWeekends highlightToday weekNumbers={false} weekStartDay={1} todayButton={false} dateFormat="MM-DD-YYYY HH:mm:ss"></DatePicker>
					</DateField> */}
          <Datetime
            {...input}
            className={touched && error ? className + " error" : className}
            locale="en"
            dateFormat={jwtdf}
            timeFormat={true}
            inputProps={{ disabled: disabledValue }}
            defaultValue={moment()}
          />
          {touched && error && <span className="error"> {error} </span>}
        </div>
      );
    case "createdowntime":
      return (
        <div className="dateTimeSdiv">
          {/* <DateField className={(touched && error) ? className + " error" : className} expandOnFocus={false} dateFormat={jwtdf + " HH:mm:ss"} updateOnDateClick forceValidDate defaultValue={moment()} minDate={moment()} disabled={disabledValue}>
              <DatePicker {...input} selected={input.value ? moment(input.value) : null} navigation locale="en" forceValidDate={true} highlightWeekends highlightToday weekNumbers={false} weekStartDay={1} todayButton={false} dateFormat="MM-DD-YYYY HH:mm:ss"></DatePicker>
            </DateField> */}
          <Datetime
            {...input}
            className={touched && error ? className + " error" : className}
            locale="en"
            dateFormat={jwtdf}
            timeFormat={true}
            inputProps={{ disabled: disabledValue }}
            // defaultValue={moment()}
          />
          {touched && error && <span className="error"> {error} </span>}
        </div>
      );
    case "createDelegate":
      return (
        <div className="dateTimeSdiv">
          {/* <DateField className={(touched && error) ? className + " error" : className} expandOnFocus={false} dateFormat={jwtdf + " HH:mm:ss"} updateOnDateClick forceValidDate defaultValue={moment()} minDate={moment()} disabled={disabledValue}>
              <DatePicker {...input} selected={input.value ? moment(input.value) : null} navigation locale="en" forceValidDate={true} highlightWeekends highlightToday weekNumbers={false} weekStartDay={1} todayButton={false} dateFormat="MM-DD-YYYY HH:mm:ss"></DatePicker>
            </DateField> */}
          <Datetime
            {...input}
            className={touched && error ? className + " error" : className}
            locale="en"
            dateFormat={jwtdf}
            timeFormat="HH:mm:ss"
            isValidDate={valid}
            inputProps={{ disabled: disabledValue }}
            defaultValue={moment()}
          />
          {touched && error && <span className="error"> {error} </span>}
        </div>
      );
  }
};

export const _dateTimeFieldGeneral = ({
  input,
  placeholder,
  defaultValue,
  className,
  formType,
  disabledValue,
  timeFormat,
  meta: { touched, error },
}) => {
  let jwtdf = dateformat.slice(0, 10).toUpperCase();
  // console.log('formTypeformTypeformType',formType, input.value, jwtdf)
  // console.log('Datetime----------',Datetime)
  // console.log('input----------------',input);
  // console.log('timeFormattimeFormat',timeFormat)
  switch (formType) {
    case "edit":
      return (
        <div className="dateTimeSdiv">
          <Datetime
            {...input}
            className={touched && error ? className + " error" : className}
            locale="en"
            dateFormat={jwtdf}
            timeFormat={false}
            inputProps={{ disabled: disabledValue }}
            defaultValue={defaultValue}
          />
          {touched && error && <span className="error"> {error} </span>}
        </div>
      );
    case "create":
      return (
        <div className="dateTimeSdiv">
          <Datetime
            {...input}
            className={touched && error ? className + " error" : className}
            locale="en"
            dateFormat={jwtdf}
            timeFormat={false}
            inputProps={{ disabled: disabledValue }}
            defaultValue={moment()}
          />
          {touched && error && <span class="error"> {error} </span>}
        </div>
      );
  }
};

export const _inputField = ({
  input,
  label,
  type,
  className,
  placeholder,
  readOnly,
  disabledValue,
  maxlength,
  meta: { touched, error },
}) => {
  return (
    <div>
      <input
        {...input}
        placeholder={placeholder}
        type={type}
        className={touched && error ? className + " error" : className}
        readOnly={readOnly}
        disabled={disabledValue}
        maxLength={maxlength}
      />
      {touched && error && <span class="error"> {error} </span>}
    </div>
  );
};

export const _inputFieldContact = ({
  input,
  label,
  type,
  className,
  placeholder,
  readOnly,
  disabledValue,
  meta: { touched, error },
}) => {
  return (
    <div>
      <input
        {...input}
        placeholder={placeholder}
        type={type}
        maxLength="10"
        className={touched && error ? className + " error" : className}
        readOnly={readOnly}
        disabled={disabledValue}
      />
      {touched && error && <span class="error"> {error} </span>}
    </div>
  );
};

export const _latentField = ({
  input,
  formType,
  placeholder,
  defaultValue,
  className,
  disabledValue,
  handleTimeChange,
  scheduledDay,
  meta: { touched, error },
}) => {
  let jwtdf = dateformat.slice(0, 10).toUpperCase();
  switch (formType) {
    case "edit":
      return (
        <div className="dateTimeSdiv">
          <DateField
            className={touched && error ? className + " error" : className}
            expandOnFocus={false}
            dateFormat={jwtdf + " HH:mm:ss"}
            updateOnDateClick
            forceValidDate
            defaultValue={defaultValue}
            maxDate={moment().add(-1, "days")}
            disabled={disabledValue}
          >
            <DatePicker
              {...input}
              selected={input.value ? moment(input.value) : null}
              navigation
              locale="en"
              forceValidDate={false}
              highlightWeekends
              highlightToday
              weekNumbers={false}
              weekStartDay={1}
              todayButton={false}
            ></DatePicker>
          </DateField>
          {touched && error && <span class="error"> {error} </span>}
        </div>
      );
    case "create":
      return (
        <div className="dateTimeSdiv">
          <DateField
            className={touched && error ? className + " error" : className}
            expandOnFocus={false}
            dateFormat={jwtdf + " HH:mm:ss"}
            updateOnDateClick
            forceValidDate
            defaultValue={moment().add(-1, "days")}
            maxDate={moment().add(-1, "days")}
            disabled={disabledValue}
          >
            <DatePicker
              {...input}
              selected={input.value ? moment(input.value) : null}
              navigation
              locale="en"
              forceValidDate={false}
              highlightWeekends
              highlightToday
              weekNumbers={false}
              weekStartDay={1}
              todayButton={false}
            ></DatePicker>
          </DateField>
          {touched && error && <span class="error"> {error} </span>}
        </div>
      );
  }
};

export const _latentDateTimeField = ({
  input,
  formType,
  placeholder,
  defaultValue,
  className,
  disabledValue,
  handleTimeChange,
  scheduledDay,
  meta: { touched, error },
}) => {
  let jwtdf = dateformat.slice(0, 10).toUpperCase();
  switch (formType) {
    case "edit":
      defaultValue = defaultValue == "" ? moment() : defaultValue;
      return (
        <div className="dateTimeSdiv">
          {/* <DateField className={(touched && error) ? className + " error" : className} expandOnFocus={false} dateFormat={jwtdf + " HH:mm:ss"} updateOnDateClick forceValidDate defaultValue={defaultValue} maxDate={moment().add(-1, 'days')} disabled={disabledValue} >
						<DatePicker {...input} selected={input.value ? moment(input.value) : null} navigation locale="en" forceValidDate={false} highlightWeekends highlightToday weekNumbers={false} weekStartDay={1} todayButton={false}></DatePicker>
					</DateField> */}
          <Datetime
            {...input}
            className={touched && error ? className + " error" : className}
            locale="en"
            dateFormat={jwtdf}
            timeFormat={true}
            inputProps={{ disabled: disabledValue }}
            defaultValue={defaultValue}
          />
          {touched && error && <span class="error"> {error} </span>}
        </div>
      );
    case "editChangeDowntime":
      defaultValue =
        defaultValue == ""
          ? ""
          : defaultValue == "default"
          ? moment()
          : defaultValue;
      let defaultValues =
        typeof defaultValue == "object" && defaultValue !== null
          ? defaultValue
          : moment(defaultValue, jwtdf + " HH:mm:ss");
      console.log("defualt", defaultValues);
      return (
        <div className="dateTimeSdiv">
          <Datetime
            {...input}
            className={touched && error ? className + " error" : className}
            locale="en"
            dateFormat={jwtdf}
            timeFormat={true}
            inputProps={{ disabled: disabledValue }}
            defaultValue={defaultValues}
            value={defaultValues}
          />
          {touched && error && <span className="error"> {error} </span>}
        </div>
      );
    case "create":
      return (
        <div className="dateTimeSdiv">
          {/* <DateField className={(touched && error) ? className + " error" : className} expandOnFocus={false} dateFormat={jwtdf + " HH:mm:ss"} updateOnDateClick forceValidDate defaultValue={moment().add(-1, 'days')} maxDate={moment().add(-1, 'days')} disabled={disabledValue} >
						<DatePicker {...input} selected={input.value ? moment(input.value) : null} navigation locale="en" forceValidDate={false} highlightWeekends highlightToday weekNumbers={false} weekStartDay={1} todayButton={false}></DatePicker>
					</DateField> */}
          <Datetime
            {...input}
            className={touched && error ? className + " error" : className}
            locale="en"
            dateFormat={jwtdf}
            timeFormat={true}
            inputProps={{ disabled: disabledValue }}
            defaultValue={moment()}
          />
          {touched && error && <span class="error"> {error} </span>}
        </div>
      );
    case "createdowntime":
      return (
        <div className="dateTimeSdiv">
          {/* <DateField className={(touched && error) ? className + " error" : className} expandOnFocus={false} dateFormat={jwtdf + " HH:mm:ss"} updateOnDateClick forceValidDate defaultValue={moment()} minDate={moment()} disabled={disabledValue}>
              <DatePicker {...input} selected={input.value ? moment(input.value) : null} navigation locale="en" forceValidDate={true} highlightWeekends highlightToday weekNumbers={false} weekStartDay={1} todayButton={false} dateFormat="MM-DD-YYYY HH:mm:ss"></DatePicker>
            </DateField> */}
          <Datetime
            {...input}
            className={touched && error ? className + " error" : className}
            locale="en"
            dateFormat={jwtdf}
            timeFormat={true}
            inputProps={{ disabled: disabledValue }}
            // defaultValue={moment()}
          />
          {touched && error && <span className="error"> {error} </span>}
        </div>
      );
  }
};

export const _dynamicTable = ({ fields, question }) => {
  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          {question.columns.map((columnHeader) => {
            return (
              <th key={columnHeader}>
                {columnHeader}
                {question.questionType === "mandatory" ? (
                  <span style={{ color: "red" }}> *</span>
                ) : null}
              </th>
            );
          })}
          <th />
        </tr>
      </thead>
      <tbody>
        {fields.map((field, index) => {
          let rowCount = fields.length;
          return (
            <tr key={`${field}.${index}`}>
              {question.columns.map((fieldName, i) => {
                return (
                  <td key={i}>
                    <Field
                      name={`${field}.${fieldName}`}
                      type="text"
                      placeholder={fieldName}
                      component={_inputField}
                      className="form-control"
                    />
                  </td>
                );
              })}
              <td>
                {
                  <div style={{ width: "60px" }}>
                    {index === fields.length - 1 ? (
                      <div>
                        <button
                          className="btn btn-primary"
                          type="button"
                          style={{ marginRight: "5px" }}
                          disabled={rowCount === 50}
                          onMouseEnter={(e) => {
                            rowCount === 50
                              ? swal({
                                  text: `you cannot add more than 50 ${question.categoryName}`,
                                  button: "OK",
                                })
                              : e.preventDefault();
                          }}
                          title={
                            rowCount === 50
                              ? "maximum limit reached"
                              : `add more ${question.categoryName}`
                          }
                          onClick={() => {
                            fields.push({});
                          }}
                        >
                          +
                        </button>
                        {index !== 0 ? (
                          <button
                            className="btn btn-danger"
                            type="button"
                            title={`delete ${question.categoryName} row`}
                            onClick={() => {
                              fields.remove(index);
                            }}
                          >
                            -
                          </button>
                        ) : null}
                      </div>
                    ) : (
                      <button
                        className="btn btn-danger"
                        type="button"
                        title={`delete ${question.categoryName} row`}
                        onClick={() => {
                          fields.remove(index);
                        }}
                      >
                        -
                      </button>
                    )}
                  </div>
                }
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export const TypeaheadandDropdown = ({
  options,
  onSelection,
  selectedOptions,
  setErrorColor,
  diableStatus,
  onCrossClick,
  onInputChange,
  typedValue = 0,
}) => {
  let _typeahead;
  let crossClick = () => {
    _typeahead.getInstance().clear();
    onCrossClick();
  };
  return (
    <div className="dvTypehd">
      {!diableStatus &&
      ((selectedOptions && selectedOptions.length > 0) ||
        (typedValue && typedValue.length > 0)) ? (
        <span
          title="clear"
          role="img"
          aria-label="Clear"
          className="css-hakgx8 icn"
          onClick={crossClick}
        >
          <svg width="18" height="18" viewBox="0 0 24 24" role="presentation">
            <path
              d="M12 10.586L6.707 5.293a1 1 0 00-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 001.414 1.414L12 13.414l5.293 5.293a1 1 0 001.414-1.414L13.414 12l5.293-5.293a1 1 0 10-1.414-1.414L12 10.586z"
              fill="gray"
            ></path>
          </svg>
        </span>
      ) : null}
      <Typeahead
        ref={(ref) => {
          _typeahead = ref;
          if (
            _typeahead !== null &&
            selectedOptions.length === 0 &&
            typedValue.length === 0
          )
            _typeahead.getInstance().clear();
        }}
        onChange={onSelection}
        onInputChange={onInputChange}
        options={options}
        selected={selectedOptions}
        placeholder="Please choose..."
        onBlur={setErrorColor}
        disabled={diableStatus}
      />
    </div>
  );
};

export const TypeaheadandDropdownNew = ({
  options,
  onSelection,
  selectedOptions,
  setErrorColor,
  diableStatus,
  className,
  meta: { touched, error },
  onCrossClick,
  onInputChange,
  typedValue = 0,
}) => {
  let _typeahead;
  let crossClick = () => {
    _typeahead.getInstance().clear();
    onCrossClick();
  };
  return (
    <div className={touched && error ? "error dvTypehd" : "dvTypehd"}>
      {(selectedOptions && selectedOptions.length > 0) ||
      (typedValue && typedValue.length > 0) ? (
        <span
          title="clear"
          role="img"
          aria-label="Clear"
          className="css-hakgx8 icn"
          onClick={crossClick}
        >
          <svg width="18" height="18" viewBox="0 0 24 24" role="presentation">
            <path
              d="M12 10.586L6.707 5.293a1 1 0 00-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 001.414 1.414L12 13.414l5.293 5.293a1 1 0 001.414-1.414L13.414 12l5.293-5.293a1 1 0 10-1.414-1.414L12 10.586z"
              fill="gray"
            ></path>
          </svg>
        </span>
      ) : null}
      <Typeahead
        ref={(ref) => {
          _typeahead = ref;
          if (
            _typeahead !== null &&
            selectedOptions.length === 0 &&
            typedValue.length === 0
          )
            _typeahead.getInstance().clear();
        }}
        onChange={onSelection}
        onInputChange={onInputChange}
        options={options}
        selected={selectedOptions}
        placeholder="Please choose..."
        onBlur={setErrorColor}
        disabled={diableStatus}
      />
    </div>
  );
};
