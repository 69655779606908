
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import { useSelector,useDispatch } from "react-redux";
import axios from 'axios';
const api = axios.create({
    headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});
import PindCategories from "../../../../common/header/pind-categories";
import Breadcrumbs from "../../../../common/header/breadcrumbs";
import Header from "./header";
import FormComponent from "./form";
import TabsComponent from "./tabs";
import {getUserInformationListAction} from "../../../../../actions/foundation/userAction";
import "_Css/form/_form.scss";

const Index = () => {
  const tr = useSelector((state) => state.spcmReducer.tr);
  const [companyValue, setCompanyValue] = useState([]);
  const [companyTypeErr, setCompanyTypeErr] = useState('');
  const [firstNameErr,setFirstName] = useState('');
  const [userNameErr, setUsernameErr] = useState('');
  const [middlenameErr, setMiddlenameErr] = useState('');
  const [lastnameErr, setLastnameErr] = useState('');
  const [emailErr, setEmailErr] = useState('');
  const [businessErr, setBusinessErr] = useState('');
  const [mobileErr, setMobileErr] = useState('');
  const [contactErr, setcontactErr] = useState('');  
  const [userTypeErr, setUserTypeErr] = useState('');
  const [languageErr, setLanguageErr] = useState('');
  const [timeZoneErr, setTimeZoneErr] = useState('');
  const [statusErr, setStatusErr] = useState('');
  const [authSource, setAuthSource] = useState('');
  const [dateFormErr, setDateFormErr] = useState('');
  const [locationErr, setLocationErr] = useState('');
  const [ccErr, setCCErr] = useState('');
  const [cwlErr, setCWLErr] = useState('');
  const [orgzErr, setOrgzErr] = useState('');
  const [depErr, setDepErr] = useState('');
  const [currencyErr, setCurrencyErr] = useState('');
  const [isOrgInfoMandatory, setOrgInfoMandatory] = useState(true);
  const dispatch = useDispatch();


  const setCompanyErrorColor = (err) => {
    setCompanyTypeErr(err);
  };
  const setLocationErrColor = (err) => {
    setLocationErr(err);
  };
  const setCCErrColor = (err) => {
    setCCErr(err);
  };
  const setCWLColorErr = (err) => {
    setCWLErr(err);
  };
  useEffect(() => {
    dispatch(getUserInformationListAction());              
  }, []);

  const updateCompanyOrgInfoReq = (id, type = '') => {
    try{
      if(type == 'clear'){
        setOrgInfoMandatory(true);
        return;
      }
      api.get("/api/editCompany/" + id)
      .then((response) => {
        // console.log('response - ', response);
        const companyType = response?.data?.company?.companyType;
        if( companyType && Array.isArray(companyType) ){
          if(companyType.length > 0){
            const found = companyType.find(item => item.toLowerCase() == 'service consumer');
            setOrgInfoMandatory(!!found);
          }
          else{
            setOrgInfoMandatory(false);
          }
        }
        else{
          setOrgInfoMandatory(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    }catch(e){
      console.error(e);
    }
  };
  
  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Breadcrumbs activePageName={"Create"} parentPageurl='/user' parentPageName={tr["User"]} />
      </Container>

      <Container fluid>
        <Header
           setCompanyErrorColor={setCompanyErrorColor}
           setFirstName={setFirstName}
           setUsernameErr={setUsernameErr} 
           setLastnameErr={setLastnameErr}
           setMiddlenameErr={setMiddlenameErr}
           setCompanyValue={setCompanyValue}
           setEmailErr={setEmailErr}
           setUserTypeErr={setUserTypeErr}
           setLanguageErr={setLanguageErr}
           setAuthSource={setAuthSource}
           setTimeZoneErr={setTimeZoneErr}
           setStatusErr={setStatusErr}
           setDateFormErr={setDateFormErr}
           companyValue={companyValue}
           setLocationErr={setLocationErr}
           setLocationErrColor={setLocationErrColor}
           etCCErr={setCCErr}
           setCCErrColor={setCCErrColor}
           setCWLErr={setCWLErr}
           setCWLColorErr={setCWLColorErr}
           setOrgzErr={setOrgzErr}
           setDepErr={setDepErr}
           setCurrencyErr={setCurrencyErr}
           setBusinessErr={setBusinessErr}
           setMobileErr={setMobileErr}
           setcontactErr={setcontactErr}
           isOrgInfoMandatory={isOrgInfoMandatory}
           />
        <Row>
          <Col md={8} xs={12}>
            <FormComponent 
            setCompanyValue={setCompanyValue}
            setCompanyErrorColor={setCompanyErrorColor}
            setFirstName={setFirstName}
            setUsernameErr={setUsernameErr}
            setMiddlenameErr={setMiddlenameErr}  
            firstNameErr={firstNameErr}
            userNameErr={userNameErr}
            setLastnameErr={setLastnameErr}
            lastnameErr={lastnameErr}
            middlenameErr={middlenameErr}
            companyTypeErr={companyTypeErr}
            setUserTypeErr={setUserTypeErr}
            userTypeErr={userTypeErr}
            setEmailErr={setEmailErr}
            emailErr={emailErr}
            setLanguageErr={setLanguageErr}
            setAuthSource={setAuthSource}
            languageErr={languageErr}
            authSource={authSource}
            setTimeZoneErr={setTimeZoneErr}
            timeZoneErr={timeZoneErr}
            setStatusErr={setStatusErr}
            statusErr={statusErr}
            setDateFormErr={setDateFormErr}
            dateFormErr={dateFormErr}
            setLocationErr={setLocationErr}
            locationErr={locationErr}
            setLocationErrColor={setLocationErrColor}
            setCCErr={setCCErr}
            setCCErrColor={setCCErrColor}
            ccErr={ccErr}
            setCWLErr={setCWLErr}
            setCWLColorErr={setCWLColorErr}
            cwlErr={cwlErr}
            setOrgzErr={setOrgzErr}
            orgzErr={orgzErr}
            setDepErr={setDepErr}
            depErr={depErr}
            setCurrencyErr={setCurrencyErr}
            currencyErr={currencyErr}
            setBusinessErr={setBusinessErr}
            businessErr={businessErr}
            setMobileErr={setMobileErr}
            mobileErr={mobileErr}
            setcontactErr={setcontactErr}
            contactErr={contactErr}
            updateCompanyOrgInfoReq={updateCompanyOrgInfoReq}
            isOrgInfoMandatory={isOrgInfoMandatory}
            />
          </Col>
          {/* <Col xs={4}>
            <div className="stickyArea rBoxStyle">
              <TabsComponent />
            </div>
          </Col> */}
        </Row>
      </Container>
    </main>
  )
}

export default Index;
