
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import axios from "axios";
import {
  Col,
  Form,
  Row,
} from "react-bootstrap";
import { change } from "redux-form";

const generateHtmlContent = (props) => {
  return (
    <Row className="botLineWhite" key={props.key}>
      <Col xs={12} sm={12} md={12} lg={12}>
        <Form.Group>
          <Form.Label>{props.label}</Form.Label>
        </Form.Group>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12}>
        <Form.Group>
          <Form.Control
            componentClass={props.elementType}
            value={props.value}
            style={props.style}
            readOnly
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

class DynamicDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      locations: 0,
      companyId: 0,
      readOnlyInputHtml: [],
    };
    this.onValueSelection = this.onValueSelection.bind(this);
    this.setReadOnlyValues = this.setReadOnlyValues.bind(this);
  }

  componentDidMount() {
    const { questionnaire, dispatch } = this.props;
    let query = {};
    query.api = this.props.questionnaire.api;
    if (typeof questionnaire.model === "string") {
      query.Asset_Type = "Desktop";
      query.seatCode = questionnaire.model;
    } else {
      query[questionnaire.lookupKey] = questionnaire.model
        ? questionnaire.lookupKey == "Employee_Number" ? questionnaire.model.Employee_Number:
        questionnaire.lookupKey == "userid" && !questionnaire.api.includes("xsmf") ? questionnaire.model.user_id : questionnaire.model[questionnaire.lookupKey]
        : "";
      query.companyid = questionnaire.model
        ? questionnaire.model.companyId
        : "";
    }
    this._typeahead.clear();
    axios
      .get("/search/dynamic/dropdown/data/", {
        headers: { query: JSON.stringify(query) },
      })
      .then((response) => {
        this.setState({
          options: response.data,
        });
      })
      .catch((err) => {
        console.log("error occured...");
        console.log(err);
      });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.questionnaire !== nextProps.questionnaire) {
      const { questionnaire, dispatch } = nextProps;
      let query = {};
      query.api = nextProps.questionnaire.api;
      if (typeof questionnaire.model === "string") {
        query.Asset_Type = "Desktop";
        query.seatCode = questionnaire.model;
      } else {
        query[questionnaire.lookupKey] = questionnaire.model
          ? questionnaire.lookupKey == "Employee_Number" ? questionnaire.model.Employee_Number :
            questionnaire.lookupKey == "userid" && !questionnaire.api.includes("xsmf") ? questionnaire.model.user_id : questionnaire.model[questionnaire.lookupKey]
          : "";
        query.companyid = questionnaire.model
          ? questionnaire.model.companyId
          : "";
      }
      this._typeahead.clear();
      axios
        .get("/search/dynamic/dropdown/data/", {
          headers: { query: JSON.stringify(query) },
        })
        .then((response) => {
          this.setState({
            options: response.data,
          });
        })
        .catch((err) => {
          console.log("error occured...");
          console.log(err);
        });
    }
  }

  setReadOnlyValues(roValues, selectedValue) {
    let readOnlyHtml = [];
    if (selectedValue && roValues != null && roValues[0] != "") {
      readOnlyHtml = roValues.map((value, index) => {
        let readOnlyStringValue = roValues[index];
        let objectKey;
        let label;
        if (readOnlyStringValue.includes("~")) {
          let keyAndLabelHolder = readOnlyStringValue.split("~");
          objectKey = keyAndLabelHolder[0];
          label = keyAndLabelHolder[1];
        } else {
          if (readOnlyStringValue.includes("_")) {
            let objCopy = JSON.parse(JSON.stringify(readOnlyStringValue));
            label = objCopy.replace("_", " ").toString();
            objectKey = readOnlyStringValue;
          } else {
            label = readOnlyStringValue;
            objectKey = readOnlyStringValue;
          }
        }
        let isElementTypeAsset = objectKey === "Assets" ? true : false;
        return generateHtmlContent({
          elementType: isElementTypeAsset ? "textarea" : "input",
          value: isElementTypeAsset
            ? selectedValue.Assets.join("\r\n\n")
            : selectedValue[objectKey],
          style: isElementTypeAsset ? { height: 60 } : {},
          label: label,
        });
      });
    }
    return readOnlyHtml;
  }

  onValueSelection(value) {
    const { questionnaire, setAnswer, dispatch } = this.props;
    let selectedValue = value[0];
    setAnswer(questionnaire, selectedValue, dispatch);
    if(selectedValue && Object.keys(selectedValue).length === 0) {
      let errorObj = Object.assign({}, this.props.piSectionFormErrors, { [questionnaire.name]: true });
      this.props.setFormErrors(errorObj);
    }
    else {
      let errorObj = Object.assign({}, this.props.piSectionFormErrors, { [questionnaire.name]: false });
      this.props.setFormErrors(errorObj);
    }
    this.setState({ readOnlyInputHtml: [] });
    this.setState({
      readOnlyInputHtml: this.setReadOnlyValues(
        questionnaire.roResponses,
        selectedValue
      ),
    });
  }

  onInputChange(value) {
    if (value === "") {
      const { questionnaire, setAnswer, dispatch } = this.props;
      setAnswer(questionnaire, "", dispatch);
    }
  }

  render() {
    const { readOnlyInputHtml } = this.state;
    const { questionnaire, parentProps, piSectionFormErrors } = this.props;
    //labelKey={(option) => `${option.login} ${option.id}`}
    let labelKey = "Name";
    if (this.state.options.length) {
      if (this.state.options[0].hasOwnProperty("NAME")) {
        labelKey = "NAME";
      }
    }
    let fieldClass = piSectionFormErrors[questionnaire.name] ? 'error typeahead dynamicDropClass' : 'typeahead dynamicDropClass'
    return (
      <div>
        <Typeahead
          inputProps={{
            readOnly: true,
          }}
          {...parentProps.input}
          id={questionnaire.qId}
          labelKey={labelKey}
          multiple={this.state.multiple}
          options={this.state.options}
          placeholder={
            questionnaire.placeholder !== null
              ? questionnaire.placeholder
              : "Choose Assets"
          }
          onChange={this.onValueSelection}
          ref={(ref) => (this._typeahead = ref)}
          onInputChange={this.onInputChange}
          className={fieldClass}
          filterBy={(options, props) => {
            if (props.selected.length) {
              // Display all the options if there's a selection.
              return true;
            }
            // Otherwise filter on some criteria.
            return true;
          }}
          // clearButton
        />
        {readOnlyInputHtml.length === 0 ? null : readOnlyInputHtml}
      </div>
    );
  }
}

export default DynamicDropdown;
