
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Row, Col } from "react-bootstrap";
import _ from "lodash";
import axios from "axios";
import ListLoader from "../../common/loaders/ListLoader.js";
import LoadingIcons from 'react-loading-icons';
import BulkUpdate from "./bulkUpdate.js";
import XsmQuickViewDetailtHeader from "./Header.js";
import { setWipSearchApply } from "../../../actions/globalSearch/globalSearchAction";

const WibSearch = React.lazy(() => import("./Search.js"));
const XsmQuickViewTable = React.lazy(() => import("./Tables.js"));
const XsmQuickViewShortDescription = React.lazy(() =>
  import("./ShortDescription.js")
);
import SlaList from "./slaView/slaViewList.js";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import {
  loadTaskList,
  loadTaskListAsc,
} from "../../../actions/task/TaskDataAction";
import {
  setBreakFixGraphView,
  setTaskGraphView,
  setInvestigationGraphView,
  setChangeGraphView,
  setFullfilmentGraphView,
} from "../../../actions/reportActions";
import { getTranslation } from "../../../actions/spcmActions";
//let groupId=userDetails.group_id;

import { connect } from "react-redux";
//import axios from 'axios';
import { bindActionCreators } from "redux";
import moment from "moment";
import {
  fetchGroupList,
  fetchFulfillmentGroupList,
  fetchTaskGroupList,
  fetchPriorityList,
  fetchInvestigationGroupList,
  fetchChangeGroupList,
  setBreakfixQuickViewTableCarouselIndex,
  loadBreakFixQuickView,
  loadFulfillmentList,
  resetBFQVFilters,
  loadBreakFixQuickViewSort,
  loadFulfillmentListSort,
} from "../../../actions/breakFix/quickViewAction";
import {
  loadInvestigationList,
  loadInvestigationListSort, getslaViewListAction
} from "../../../actions/investigation/investigationAction";
import {
  loadChangeManagementmList,
  loadChangeManagementmListSort,
} from "../../../actions/changeManagement/changeManagementAction";
import {
  SaveFiltersState,
  ClearFiltersState,
} from "../../../actions/breakFix/WorkItemBoardCacheAction";
import { GLOBAL } from "_Globals";
import {
  loadallCIMgroups,
  clearallCIMgroups,
  loadallCIMgroupsRules
} from "../../../actions/breakFix/serviceGroupAEAction";

// import {getAllgroup} from '../../actions/amsActions/amsActions';
import "_Css/common/_table.scss";
import "_Css/common/_aiButton.scss";

import Cookies from "universal-cookie";
import {
  setInitialwibGlobalSearchFilterData,
  setWibActiveTab,
} from "../../../actions/globalSearch/globalSearchAction";
import Swal from "sweetalert2";
import { setWibSelectedDateRangeLabel } from "../../../actions/globalSearch/globalSearchAction";
import { getAdminPreferences, emptyAdminPreferencesListData } from '_Actions/adminPreferences/adminPreferencesAction';
const cookies = new Cookies();
let loggedinUserCompanyID = cookies.get("gph");

if (loggedinUserCompanyID)
  loggedinUserCompanyID = loggedinUserCompanyID.replace("s:", "");
if (loggedinUserCompanyID)
  loggedinUserCompanyID = loggedinUserCompanyID.substring(
    0,
    loggedinUserCompanyID.lastIndexOf(".")
  );
loggedinUserCompanyID = loggedinUserCompanyID.split("~");
loggedinUserCompanyID = loggedinUserCompanyID[48];

let defaultDateRangeValue = cookies.get("gph");
if (defaultDateRangeValue)
  defaultDateRangeValue = defaultDateRangeValue.replace("s:", "");
if (defaultDateRangeValue)
  defaultDateRangeValue = defaultDateRangeValue.substring(
    0,
    defaultDateRangeValue.lastIndexOf(".")
  );
defaultDateRangeValue = defaultDateRangeValue.split("~");
defaultDateRangeValue = defaultDateRangeValue[53];
let defaultDateRangeDays =
  defaultDateRangeValue && !isNaN(defaultDateRangeValue)
    ? parseInt(defaultDateRangeValue, 10) - 1
    : 29;

let loggedInUserCompany = cookies.get("gph");
loggedInUserCompany = loggedInUserCompany.split("~");
loggedInUserCompany = loggedInUserCompany[59];
const Joi = require("joi");
const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});
let insideParams = {statusId: { inq: ['1', '2', '7'] } };

class BreakFix extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bulkUpdateData:[],
      subMenu: "4",
      showHideRightNav: false,
      mobileLeftMenuFilter: false,
      investigationNumber: "",
      investigationId: "",
      problemStatements: "",
      problemSymptom: "",
      investigationReported: "",
      investigationServiceName: "",
      investigationServiceCriticality: "",

      asgPmGroupName: "",
      asgPmName: "",
      changeId: "",
      changeNumber: "",
      ciServiceName: "",
      serviceBased: "",
      serviceBasedFlag: "no value",
      serviceBasedFlagtagged: "",
      changeGroupname: "",
      changeUsername: "",
      changedescription: "",
      expStartDate: "",
      expEndDate: "",
      dStartDate: "",
      dEndDate: "",
      reportDate: "",
      dateCAB: "",
      impleGroupName: "",
      impleUserName: "",
      changeRisk: "",
      ChangeTypee: "",
      statusChange: "",
      companyNameChange: "",
      requesterName: "",
      requestNumberChange: "",
      ciIDChange: "",
      ciClassName: "",
      changeServiceId: "",
      changerequesterId: "",
      risk: "",

      breakfixId: "",
      breakfixNumber: "",
      breakFixDescription: "",
      breakFixadditionalInfo: "",
      breakFixCreatedOn: "",
      breakFixServiceName: "",
      breakFixServiceCriticality: "",
      breakFixSpGroupname: "",
      breakFixSpUsername: "",
      requestNumber: "",
      ciName: "",
      requesterId: "",

      taskCode: "",
      registrationId: "",
      serviceName: "",
      assignedToGroup: "",
      expectedStartDate: "",
      expectedDueDate: "",
      description: "",
      lastModificationDate: "",
      assignedTo: "",
      sequenceNumber: "",
      clientId: "",
      assignedToGroupId: "",
      parentWorkItemId: "",
      taskSubStatus: "",
      workItemType: "",
      statusName: "",
      TaskRequesterName: "",
      hopCount: "",
      actualStartDate: "",
      actualEndDate: "",
      ciId: null,
      breakFixSearchParams: {},
      onClickBreakFixData: [],
      breakFixDateRangeFilter: null,

      taskDateRangeFilter: null,
      onClickTaskData: [],
      taskSearchParams: {},

      changeDateRangeFilter: null,
      onClickChangeData: [],
      changeSearchParams: {},

      investigationeDateRangeFilter: null,
      onClickInvestigationData: [],
      investigationSearchParams: {},

      fullfilmentDateRangeFilter: null,
      onClickFullfilmentData: [],
      fullfilmentSearchParams:
        this.props.WorkItemBoardFilterCache.SearchParams != "" &&
        this.props.WorkItemBoardFilterCache.moduleName == "fullfilment"
          ? this.props.WorkItemBoardFilterCache.SearchParams
          : {},
      faCustomInvDesc: "fa fa-caret-down fa_custom",
      faCustomInvAsc: "fa fa-caret-up",
      faCustomChgDesc: "fa fa-caret-down fa_custom",
      faCustomChgAsc: "fa fa-caret-up",
      faCustomDesc: "fa fa-caret-down fa_custom",
      faCustomAsc: "fa fa-caret-up",
      faCustomFulDesc: "fa fa-caret-down fa_custom",
      faCustomFulAsc: "fa fa-caret-up",
      faCustomTaskDesc: "fa fa-caret-down fa_custom",
      faCustomTaskAsc: "fa fa-caret-up",
      user_id: "",
      full_name: "",
      role_id: "",
      date_format: "",
      group_id: "",
      changeImplentationRole: "",
      taskTableSize: 10,
      tableSizeBreakfix: 10,
      tableCurrentPageBreakfix: 0,
      tableSizeFullfilment:
        this.props.WorkItemBoardFilterCache.moduleName == "fullfilment" &&
        this.props.WorkItemBoardFilterCache.tableSize != ""
          ? this.props.WorkItemBoardFilterCache.tableSize
          : 10,
      tableCurrentPageFullfilment:
        this.props.WorkItemBoardFilterCache.moduleName == "fullfilment" &&
        this.props.WorkItemBoardFilterCache.tableCurrentPage != ""
          ? this.props.WorkItemBoardFilterCache.tableCurrentPage
          : 0,
      tableSizeInvest: 10,
      tableCurrentPageInvest: 0,
      tableSizeChangeMgmt: 10,
      tableCurrentPageChangeMgmt: 0,
      taskSkiprows: 0,
      valueSearchInputFulfilment:
        this.props.WorkItemBoardFilterCache.valueSearchInput != "" &&
        this.props.WorkItemBoardFilterCache.moduleName == "fullfilment"
          ? this.props.WorkItemBoardFilterCache.valueSearchInput
          : "",
      searchFilterFulfilment:
        this.props.WorkItemBoardFilterCache.searchFilter != "" &&
        this.props.WorkItemBoardFilterCache.moduleName == "fullfilment"
          ? this.props.WorkItemBoardFilterCache.searchFilter
          : "displayNumber",
      checkSearchtextFieldFulfilment:
        this.props.WorkItemBoardFilterCache.checkSearchtextField != "" &&
        this.props.WorkItemBoardFilterCache.moduleName == "fullfilment"
          ? this.props.WorkItemBoardFilterCache.checkSearchtextField
          : false,
      valueStatusSearchInputFulfilment:
        this.props.WorkItemBoardFilterCache.valueStatusSearchInput != "" &&
        this.props.WorkItemBoardFilterCache.moduleName == "fullfilment"
          ? this.props.WorkItemBoardFilterCache.valueStatusSearchInput
          : "",
      valueSearchInputBreakfix: "",
      searchFilterBreakfix: "displayNumber",
      checkSearchtextFieldBreakfix: false,
      valueStatusSearchInputBreakFix: "",
      enableSearchFulfilment:
        this.props.WorkItemBoardFilterCache.enableSearch != "" &&
        this.props.WorkItemBoardFilterCache.moduleName == "fullfilment"
          ? this.props.WorkItemBoardFilterCache.enableSearch
          : false,
      enableSearchBreakFix: false,

      searchFilterChangeMgmt: "displayNumber",
      checkSearchtextFieldChangeMgmt: false,
      valueStatusSearchInputChangeMgmt: "",
      valueSearchInputChangeMgmt: "",
      enableSearchChangeMgmt: false,

      searchFilterInvestigation: "displayNumber",
      checkSearchtextFieldInvestigation: false,
      valueStatusSearchInputInvestigation: "",
      valueSearchInputInvestigation: "",
      enableSearchInvestigation: false,

      valueSearchInputTasks: "",
      valueStatusSearchInputTasks: "",
      searchFilterTasks: "displayNumber",
      checkSearchtextFieldTasks: false,
      enableSearchTasks: false,
      checkDateField:
        this.props.WorkItemBoardFilterCache.checkDateField != ""
          ? this.props.WorkItemBoardFilterCache.checkDateField
          : false,
      fromDate:
        this.props.WorkItemBoardFilterCache.fromDate != ""
          ? this.props.WorkItemBoardFilterCache.fromDate
          : "",
      toDate:
        this.props.WorkItemBoardFilterCache.toDate != ""
          ? this.props.WorkItemBoardFilterCache.toDate
          : "",
      isDateField: false,
      defaultvalue: "",
      moduleName:
        this.props.WorkItemBoardFilterCache.moduleName != ""
          ? this.props.WorkItemBoardFilterCache.moduleName
          : "",
      isRightSideVisible: false,

      rowIndexBrk: 0,
      consumerCompanyNameBrk: "",
      status: "",
      priorityId: "",
      impactedUsername: "",
      createdByName: "",
      reportedThrough: "",
      requesterLocationName: "",
      sortOrderBrk: "desc",
      sortOnColumnBrk: "createdOn",
      searchKeyBrk: "",
      searchValueBrk: "",
      isClearAllFilterBrk: false,
      displayNumberSearchInputBrk: "",
      workItemIdSearchInputBrk: "",
      // ciServiceNameSearchInputBrk: "",
      serviceNameSearchInputBrk: "",
      ciNameSearchInputBrk: "",
      companySearchInputBrk: "",
      summarySearchInputBrk: "",
      requestedForSearchInputBrk: "",
      supportIndividualSearchInputBrk: "",
      cimindividualSearchInputBrk: "",
      startDateBrk: {},
      endDateBrk: {},
      selectedStatusValueBrk: [],
      selectedPriorityValueBrk: [],
      selectedSupportGroupValueBrk: [],
      selectedCriticality: [],
      selectedCIMGroups: [],
      statusValueBrk: [10, 20, 15],
      priorityValueBrk: [],
      spGroupValueBrk: [],
      criticalityValueBrk: [],
      cimgroupsValueBrk: [],
      statusSearchInputBrk: [],
      prioritySearchInputBrk: [],
      spGroupnameSearchInputBrk: [],
      patternListBrk: "",
      createdByBrk: "",
      createdByInv: "",
      createdByChng: "",
      ///////////////////////FULFILLMENT///////////////////
      fulfillmenRowIndex: 0,
      fulfillmentSearchKey: "",
      fulfillmentSearchValue: "",
      fulfillmentPattern: "",
      fulfillmentSortOrder: "desc",
      fulfillmentSortOnColumn: "itemId",
      fulfillmentStatus: [60, 70, 100],
      fulfillmentGroup: [],
      fulGrp: [],
      fulfillmentUser: [],
      fulfillmentIsClearAllFilter: false,
      fulfillmentDisplayNumberSearchInput: "",
      fulfillmentWorkItemIdSearchInput: "",
      fulfillmentCiServiceNameSearchInput: "",
      fulfillmentRequesterNameSearchInput: "",
      fulfillmentCompanyNameSearchInput: "",
      fulfillmentSummarySearchInput: '',
      fulfillerNameSearchInput: "",
      fulfillmentSelectedStatusValue: [],
      fulfillmentGroupValue: [],
      changeManagementGroupValue: [],
      impleGroupValue: [],

      fulfillmentReportedStartDate: {},
      fulfillmentReportedEndDate: {},
      fulfillmentReportedDateRef: {},
      activePanelKey: "0",
      ////////////////////////////CHANGE//////////////////////////
      changeRowIndex: 0,
      changeSearchKey: "",
      changeSearchValue: "",
      changePattern: "",
      changeSortOrder: "desc",
      changeSortOnColumn: "createdOn",
      changeStatus: ["30,35,40,45,55,65,95"],
      changeType: [],
      changemngmtGroup: [],
      implemntGroup: [],
      changeIsClearAllFilter: false,
      changeDisplayNumberSearchInput: "",
      changeWorkItemIdSearchInput: "",
      changeCiServiceNameSearchInput: "",
      changeCompanyNameSearchInput: "",
      changeSummaryNameSearchInput: "",
      changeRequesterNameSearchInput: "",
      changeSelectedStatusValue: [],
      changeTypeValue: [],
      changeReportedStartDate: {},
      changeReportedEndDate: {},
      changeReportedDateRef: {},
      changeExpectedStartDate: {},
      changeExpectedEndDate: {},
      changeExpectedDateRef: {},
      changeCABStartDate: {},
      changeCABEndDate: {},
      changeCABDateRef: {},
      /////////////////////////problem/////////////////
      displayNumberSearchInputInv: "",
      workItemIdSearchInputInv: "",
      ciNameSearchInputInv: "",
      companySearchInputInv: "",
      summarySearchInputInv: "",
      problemManagerSearchInputInv: "",
      requestedBySearchInputInv: "",
      priorityValueInv: "",
      startDateInv: {},
      endDateInv: {},
      problemGroupnameSearchInputInv: "",
      selectedStatusValueInv: [],
      selectedPriorityValueInv: [],
      selectedProblemGroupValueInv: [],
      statusValueInv: ["30", "35", "40", "45", "50", "55", "60"],
      spGroupValueInv: [],
      statusSearchInputInv: [],
      prioritySearchInputInv: [],
      spGroupnameSearchInputInv: [],
      patternListInv: "",
      searchKeyInv: "",
      searchValueInv: "",
      sortOnColumnInv: "createdOn",
      sortOrderInv: "desc",
      consumerCompanyInv: "",
      sourceInv: "",
      statusInv: "",
      priorityInv: "",
      createdByNameInv: "",
      requestNumberInv: "",
      invrequesterId: "",
      ////////////////////Task////////////////
      taskIsClearAllFilter: false,
      taskRowIndex: 0,
      taskNumberSearchInput: "",
      workItemNumberSearchInput: "",
      taskServiceNameSearchInput: "",
      taskCompanyNameSearchInput: '',
      taskciNameSearchInput: "",
      taskSummarySearchInput: "",
      taskAssignedToSearchInput: "",
      taskGroupValue: [],
      taskTypeValue: [],
      taskStatusValue: [],
      taskStartDate: {},
      taskEndDate: {},
      taskDateRef: {},
      taskGroup: [],
      taskType: [],
      taskStatus: [],
      taskSearchValueParams: {},
      companyName: "",
      filterTabActive: 1,
      moduleSelected: "",
      selectedModuleName: "task",
      companyId: "",
      userAllGroups: [],
      all_groups_for_task: [],
      taskDateRangeLabel: `Last ${defaultDateRangeValue || 30} days`,
      fulfillDateRangeLabel: `Last ${defaultDateRangeValue || 30} days`,
      breakDateRangeLabel: `Last ${defaultDateRangeValue || 30} days`,
      problemDateRangeLabel: `Last ${defaultDateRangeValue || 30} days`,
      changeDateRangeLabel: `Last ${defaultDateRangeValue || 30} days`,
      isWIBGlobalSearch: false,
      selectedGlobalModule: "",
      selectedGlobalDropdown: "",
      calculatedTypeToSend: "",
      enteredGlobalSearchValue: "",
      retrivedGlobalSearchEmployeeValue: "",
      isFulfillmentFilterSelected: false,
      isBreakfixFilterSelected: false,
      defaultFulfillmentStatusSelected: false,
      defaultBreakfixStatusSelected: false,
      isInvestigationFilterSelected: false,
      defaultInvestigationStatusSelected: false,
      isChangeMgmtFilterSelected: false,
      defaultChangeMgmtStatusSelected: false,
      fulfillmentModuleEnabled: false,
      incidentModuleEnabled: false,
      problemModuleEnabled: false,
      changeModuleEnabled: false,
      disableTaskModule: false,
      state_carousel_index: "",
      isAiTypeENV: false,
      isShowBuilder: false,

      //State for multiselect with Ok button
      isTaskStatusAllLabel: false,
      isTaskWorkItemAllLabel: false,
      isTaskAssignedToGroupAllLabel: false,
      isFulFillmentStatusAllLabel: false,
      isFulFillmentAsgGroupAllLabel: false,

      isIncidentStatusAllLabel: false,
      isIncidentPriorityAllLabel: false,
      isIncidentSupportGroupAllLabel: false,
      isIncidentCIMGroupsAllLabel: false,
      isIncidentCriticalityAllLabel: false,

      isProStatusAllLabel: false,
      isProPriorityAllLabel: false,
      isProManagGroupAllLabel: false,

      isChangeStatusAllLabel: false,
      isChangeManagGroupAllLabel: false,
      isChangeImpleGrpAllLabel: false,
      isChangeTypeAllLabel: false,

      isStatusOpen: false,
      isWorkItemOpen: false,
      isAssignedToGroupOpen: false,
      isFulFillmentStatusOpen: false,
      isFulFillmentAsgGroupOpen: false,

      isIncidentStatusOpen: false,
      isIncidentPriorityOpen: false,
      isIncidentSupportGroupOpen: false,
      isIncidentCIMGroupsOpen: false,
      isIncidentCriticalityOpen: false,

      isProStatusOpen: false,
      isProPriorityOpen: false,
      isProManagGroupOpen: false,

      isChangeStatusOpen: false,
      isChangeManagGroupOpen: false,
      isChangeImpleGrpOpen: false,
      isChangeTypeOpen: false,

      isMultiOpen: false,
      isBrkResetSelected: false,
      isRefreshClick: false,
      isChangeMgmtgroupSelected: false,
      isImplementationMgmtgroupSelected: false,
      groupFilterApplied: [],
      appointmentAction: "",
      aiAction: "",
      aiDateRangeLabel: `Last ${defaultDateRangeValue || 30} days`,
      showAiClearFilter: false,
      panelResize: "",
      // appointmentDateRangeLabel: `Last ${defaultDateRangeValue || 30} days`
      appointmentDateRangeLabel: `Custom Range`,
      showAppointmentClearFilter: false,
      slaDisableDateRangeState : false,
      Limit: 10,
      page : 0,
      module : '',
      slaRole: false,
      bulkUpdateAction: '',
      rightPanelData : null,
      selectedRowIndex : null,
      consoleRefreshButton : false,
      expectedResolutionTime: '',
      walkupRoleEnabled: false
    };
    this.setLimit = this.setLimit.bind(this);
    this.setPage = this.setPage.bind(this);
    this.setModule = this.setModule.bind(this);
    GLOBAL.searchbarcategory = "All";
    this.selectAllLabelFun = this.selectAllLabelFun.bind(this);
    this.getInvestigationDetails = this.getInvestigationDetails.bind(this);
    this.showHideRightNav = this.showHideRightNav.bind(this);
    this.buildSearchParamsForBreakfixQV =
      this.buildSearchParamsForBreakfixQV.bind(this);
    this.buildSearchParamsForFulfillmentQV =
      this.buildSearchParamsForFulfillmentQV.bind(this);
    this.getChangeDetails = this.getChangeDetails.bind(this);
    this.getBreakFixDetails = this.getBreakFixDetails.bind(this);
    this.buildSearchParamsForInvestigationQV =
      this.buildSearchParamsForInvestigationQV.bind(this);
    this.buildSearchParamsForChangeQV =
      this.buildSearchParamsForChangeQV.bind(this);
    this.buildSearchParamsForTaskQV =
      this.buildSearchParamsForTaskQV.bind(this);
    this.getTaskDetails = this.getTaskDetails.bind(this);
    this.changeToGraphView = this.changeToGraphView.bind(this);
    this.setGraphSearchParams = this.setGraphSearchParams.bind(this);
    this.setOnClickGraphSideBarData =
      this.setOnClickGraphSideBarData.bind(this);
    this.setGraphDateRangeFilter = this.setGraphDateRangeFilter.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.onFromDateSelect = this.onFromDateSelect.bind(this);
    this.onToDateSelect = this.onToDateSelect.bind(this);
    this.showRightSide = this.showRightSide.bind(this);
    this.isWIBGlobalSearchEnabled = this.isWIBGlobalSearchEnabled.bind(this);
    this.selectedGlobalSearchModule =
      this.selectedGlobalSearchModule.bind(this);
    this.setGlobalSearchInformation =
      this.setGlobalSearchInformation.bind(this);
    this.setOnFulfillmentFilterSelection =
      this.setOnFulfillmentFilterSelection.bind(this);
    this.setOnBreakfixFilterSelection =
      this.setOnBreakfixFilterSelection.bind(this);
    this.setOnInvestigationFilterSelection =
      this.setOnInvestigationFilterSelection.bind(this);
    this.setOnChangeMgmtFilterSelection =
      this.setOnChangeMgmtFilterSelection.bind(this);
    //      this.props.getTranslation(this.props.lang);
    // this.props.fetchTaskGroupList();
    // this.props.loadTaskList("",{});
    // this.setGraphSearchParams(insideParams,"Task");
    // this.props.setBreakfixQuickViewTableCarouselIndex("4");
    this.onSortInvAsc = this.onSortInvAsc.bind(this);
    this.onSortInvDesc = this.onSortInvDesc.bind(this);
    this.onSortChgAsc = this.onSortChgAsc.bind(this);
    this.onSortChgDesc = this.onSortChgDesc.bind(this);
    this.onSortAsc = this.onSortAsc.bind(this);
    this.onSortDesc = this.onSortDesc.bind(this);
    this.onSortFulAsc = this.onSortFulAsc.bind(this);
    this.onSortFulDesc = this.onSortFulDesc.bind(this);
    this.onSortTaskAsc = this.onSortTaskAsc.bind(this);
    this.onSortTaskDesc = this.onSortTaskDesc.bind(this);
    this.gettingRoleId = this.gettingRoleId.bind(this);
    this.roleIdentification = this.roleIdentification.bind(this);
    this.onchangeTaskTableSize = this.onchangeTaskTableSize.bind(this);
    this.changeBFCurrentPage = this.changeBFCurrentPage.bind(this);
    this.changeBFPageSize = this.changeBFPageSize.bind(this);
    this.changeFulfilmentCurrentPage =
      this.changeFulfilmentCurrentPage.bind(this);
    this.changeFulfilmentPageSize = this.changeFulfilmentPageSize.bind(this);
    this.changeInvestigationCurrentPage =
      this.changeInvestigationCurrentPage.bind(this);
    this.changeInvestigationPageSize =
      this.changeInvestigationPageSize.bind(this);
    this.changeChangeMgmtCurrentPage =
      this.changeChangeMgmtCurrentPage.bind(this);
    this.changeChangeMgmtPageSize = this.changeChangeMgmtPageSize.bind(this);
    this.changeSkiprows = this.changeSkiprows.bind(this);
    this.changeSearchInputFulfilment =
      this.changeSearchInputFulfilment.bind(this);
    this.setSelectedSearchFilterFulfilment =
      this.setSelectedSearchFilterFulfilment.bind(this);
    this.setSelectedSearchFilterBreakFix =
      this.setSelectedSearchFilterBreakFix.bind(this);
    this.changeSearchInputBreakFix = this.changeSearchInputBreakFix.bind(this);
    this.isSearchFulfilment = this.isSearchFulfilment.bind(this);
    this.isSearchBreakFix = this.isSearchBreakFix.bind(this);

    this.setSelectedSearchFilterChangeMgmt =
      this.setSelectedSearchFilterChangeMgmt.bind(this);
    this.changeSearchInputChangeMgmt =
      this.changeSearchInputChangeMgmt.bind(this);
    this.isSearchChangeMgmt = this.isSearchChangeMgmt.bind(this);

    this.setSelectedSearchFilterInvestigation =
      this.setSelectedSearchFilterInvestigation.bind(this);
    this.changeSearchInputInvestigation =
      this.changeSearchInputInvestigation.bind(this);
    this.isSearchInvestigation = this.isSearchInvestigation.bind(this);

    this.setSelectedSearchFilterTasks =
      this.setSelectedSearchFilterTasks.bind(this);
    this.changeSearchInputTasks = this.changeSearchInputTasks.bind(this);
    this.isSearchTasks = this.isSearchTasks.bind(this);
    this.showBrkActiveRow = this.showBrkActiveRow.bind(this);
    this.setBrkSortOrder = this.setBrkSortOrder.bind(this);
    this.setBrkSearchKeyValue = this.setBrkSearchKeyValue.bind(this);
    this.isBrkClearAllFilterFunc = this.isBrkClearAllFilterFunc.bind(this);
    this.setBrkdisplayNumberInputValues =
      this.setBrkdisplayNumberInputValues.bind(this);
    this.setBrkCiServiceNameInputValues =
      this.setBrkCiServiceNameInputValues.bind(this);
    this.setBrkCompanyInputValues = this.setBrkCompanyInputValues.bind(this);
    this.setBrkSummaryInputValues = this.setBrkSummaryInputValues.bind(this);
    this.setBrkRequestedForInputValues =
      this.setBrkRequestedForInputValues.bind(this);
    this.setBrkSupportIndividualInputValues =
      this.setBrkSupportIndividualInputValues.bind(this);
    this.setBrkCIMindividualInputValues =
      this.setBrkCIMindividualInputValues.bind(this);
    this.setBrkStartAndEndDate = this.setBrkStartAndEndDate.bind(this);
    this.setBrkSelectedStatusValue = this.setBrkSelectedStatusValue.bind(this);
    this.setBrkSelectedPriorityValue =
      this.setBrkSelectedPriorityValue.bind(this);
    this.setBrkSelectedSupportGroupValue =
      this.setBrkSelectedSupportGroupValue.bind(this);
    this.setBrkSelectedCriticalityValue =
      this.setBrkSelectedCriticalityValue.bind(this);
    this.setBrkSElectedCIMGroupsValue =
      this.setBrkSElectedCIMGroupsValue.bind(this);
    this.setBrkReportedOnRef = this.setBrkReportedOnRef.bind(this);
    this.updateParameterBrk = this.updateParameterBrk.bind(this);
    this.setStatusSearchInputBrk = this.setStatusSearchInputBrk.bind(this);
    this.setPrioritySearchInputBrk = this.setPrioritySearchInputBrk.bind(this);
    this.setSpGroupnameSearchInputBrk =
      this.setSpGroupnameSearchInputBrk.bind(this);
    ///fulfillment////
    this.showFulfillmentActiveRow = this.showFulfillmentActiveRow.bind(this);
    this.setFulSearchKeyValue = this.setFulSearchKeyValue.bind(this);
    this.setFulSortOrder = this.setFulSortOrder.bind(this);
    this.updateFulStatus = this.updateFulStatus.bind(this);
    this.updateFulGroup = this.updateFulGroup.bind(this);
    this.updateChangemangmtGroup = this.updateChangemangmtGroup.bind(this);
    this.updateImplmtGroup = this.updateImplmtGroup.bind(this);
    this.updateGroup = this.updateGroup.bind(this);
    this.updateUserGroup = this.updateUserGroup.bind(this);
    this.isClearAllFilterFuncFulfillment =
      this.isClearAllFilterFuncFulfillment.bind(this);
    this.setFuldisplayNumberInputValues =
      this.setFuldisplayNumberInputValues.bind(this);
    this.setFulCiServiceNameInputValues =
      this.setFulCiServiceNameInputValues.bind(this);
    this.setFulRequesterInputValues =
      this.setFulRequesterInputValues.bind(this);
    this.setFulReportedStartAndEndDate =
      this.setFulReportedStartAndEndDate.bind(this);
    this.setFulSelectedStatusValue = this.setFulSelectedStatusValue.bind(this);
    this.setFulSelectedGroupValue = this.setFulSelectedGroupValue.bind(this);
    this.setChangMangmtGroupValue = this.setChangMangmtGroupValue.bind(this);
    this.setimpleGroupValue = this.setimpleGroupValue.bind(this);
    this.setFulReportedRef = this.setFulReportedRef.bind(this);
    this.setFulfillerInputValues = this.setFulfillerInputValues.bind(this);
    this.setFulCompanyNameInputValues =
      this.setFulCompanyNameInputValues.bind(this);
    this.setFulSummaryInputValues = this.setFulSummaryInputValues.bind(this);  
    this.handlePanelClick = this.handlePanelClick.bind(this);
    ///change////
    this.setChangeSearchKeyValue = this.setChangeSearchKeyValue.bind(this);
    this.setChangeSortOrder = this.setChangeSortOrder.bind(this);
    this.setChangeReportedRef = this.setChangeReportedRef.bind(this);
    this.setChangeExpectedDateRef = this.setChangeExpectedDateRef.bind(this);
    this.setChangeCABDateRef = this.setChangeCABDateRef.bind(this);
    this.setChangeSelectedStatusValue =
      this.setChangeSelectedStatusValue.bind(this);
    this.setSelectedChangeTypeValue =
      this.setSelectedChangeTypeValue.bind(this);
    this.isClearAllFilterFuncChange =
      this.isClearAllFilterFuncChange.bind(this);
    this.setChangedisplayNumberInputValues =
      this.setChangedisplayNumberInputValues.bind(this);
    this.setChangeCiServiceNameInputValues =
      this.setChangeCiServiceNameInputValues.bind(this);
    this.setChangeCompanyNameInputValues =
      this.setChangeCompanyNameInputValues.bind(this);
    this.setChangeSummaryNameInputValues =
      this.setChangeSummaryNameInputValues.bind(this);
    this.setChangeRequesterInputValues =
      this.setChangeRequesterInputValues.bind(this);
    this.setChangeReportedStartAndEndDate =
      this.setChangeReportedStartAndEndDate.bind(this);
    this.setChangeExpectedStartAndEndDate =
      this.setChangeExpectedStartAndEndDate.bind(this);
    this.setChangeCABStartAndEndDate =
      this.setChangeCABStartAndEndDate.bind(this);
    this.updateChangeStatus = this.updateChangeStatus.bind(this);
    this.updateChangeType = this.updateChangeType.bind(this);
    this.showChangeActiveRow = this.showChangeActiveRow.bind(this);
    //////problem////////
    this.showInvActiveRow = this.showInvActiveRow.bind(this);
    this.setInvSortOrder = this.setInvSortOrder.bind(this);
    this.setInvSearchKeyValue = this.setInvSearchKeyValue.bind(this);
    this.isInvClearAllFilterFunc = this.isInvClearAllFilterFunc.bind(this);
    this.setInvdisplayNumberInputValues =
      this.setInvdisplayNumberInputValues.bind(this);
    this.setInvCiServiceNameInputValues =
      this.setInvCiServiceNameInputValues.bind(this);
    this.setInvCompanyInputValues = this.setInvCompanyInputValues.bind(this);
    this.setInvSummaryInputValues = this.setInvSummaryInputValues.bind(this);
    this.setInvRequestedByInputValues =
      this.setInvRequestedByInputValues.bind(this);
    this.setInvProblemManagerInputValues =
      this.setInvProblemManagerInputValues.bind(this);
    this.setInvStartAndEndDate = this.setInvStartAndEndDate.bind(this);
    this.setInvSelectedStatusValue = this.setInvSelectedStatusValue.bind(this);
    this.setInvSelectedPriorityValue =
      this.setInvSelectedPriorityValue.bind(this);
    this.setInvSelectedSupportGroupValue =
      this.setInvSelectedSupportGroupValue.bind(this);
    this.setInvReportedOnRef = this.setInvReportedOnRef.bind(this);
    this.updateParameterInv = this.updateParameterInv.bind(this);
    this.setStatusSearchInputInv = this.setStatusSearchInputInv.bind(this);
    this.setPrioritySearchInputInv = this.setPrioritySearchInputInv.bind(this);
    this.setSpGroupnameSearchInputInv =
      this.setSpGroupnameSearchInputBrk.bind(this);

    this.showTaskActiveRow = this.showTaskActiveRow.bind(this);
    this.setChangeTaskNumberInputValues =
      this.setChangeTaskNumberInputValues.bind(this);
    this.setChangeworkItemNumberInputValues =
      this.setChangeworkItemNumberInputValues.bind(this);
    this.setChangeTaskServiceNameInputValues =
      this.setChangeTaskServiceNameInputValues.bind(this);
    this.setChangeTaskCompanyNameInputValues = this.setChangeTaskCompanyNameInputValues.bind(this);
    this.setChangeTaskSummaryInputValues =
      this.setChangeTaskSummaryInputValues.bind(this);
    this.setChangeTaskAssignedToInputValues =
      this.setChangeTaskAssignedToInputValues.bind(this);
    this.setTaskSelectedGroupValue = this.setTaskSelectedGroupValue.bind(this);
    this.setTaskSelectedTaskValue = this.setTaskSelectedTaskValue.bind(this);
    this.setTaskSelectedStatusValue =
      this.setTaskSelectedStatusValue.bind(this);
    this.setTaskStartAndEndDate = this.setTaskStartAndEndDate.bind(this);
    this.setTaskDateRef = this.setTaskDateRef.bind(this);
    this.updateTaskGroup = this.updateTaskGroup.bind(this);
    this.updateTaskType = this.updateTaskType.bind(this);
    this.updateTaskStatus = this.updateTaskStatus.bind(this);
    this.setTaskSearchKeyValue = this.setTaskSearchKeyValue.bind(this);
    this.isClearAllFilterFuncTask = this.isClearAllFilterFuncTask.bind(this);
    this.filterTabClick = this.filterTabClick.bind(this);
    this.selectedFilterTab = this.selectedFilterTab.bind(this);
    this.handleSelectAllGroup = this.handleSelectAllGroup.bind(this);
    this.handleSelectMyGroup = this.handleSelectMyGroup.bind(this);
    this.getGroupValues = this.getGroupValues.bind(this);
    this.isProblemInvestigator = this.isProblemInvestigator.bind(this);
    this.dateRangeLabel = this.dateRangeLabel.bind(this);
    this.setDefaultFulfillmentStatusSelected =
      this.setDefaultFulfillmentStatusSelected.bind(this);
    this.setDefaultBreakfixStatusSelected =
      this.setDefaultBreakfixStatusSelected.bind(this);
    this.setDefaultInvestigationStatusSelected =
      this.setDefaultInvestigationStatusSelected.bind(this);
    this.setDefaultChangeMgmtStatusSelected =
      this.setDefaultChangeMgmtStatusSelected.bind(this);
    this.multiSelectOpenFun = this.multiSelectOpenFun.bind(this);
    this.setBrkResetSelected = this.setBrkResetSelected.bind(this);
    this.refreshClickFun = this.refreshClickFun.bind(this);
    this.setChangeMgmtgroupSelected =
      this.setChangeMgmtgroupSelected.bind(this);
    this.setImplementationMgmtgroupSelected =
      this.setImplementationMgmtgroupSelected.bind(this);
    this.setGroupFilterApplied = this.setGroupFilterApplied.bind(this);
    this.setAppointmentAction = this.setAppointmentAction.bind(this);
    this.setAiAction = this.setAiAction.bind(this);
    this.slaDisableDateRange = this.slaDisableDateRange.bind(this);
    this.bulkUpdateHandler = this.bulkUpdateHandler.bind(this);
    this.multiOpen = this.multiOpen.bind(this);
    this.showRightPanel = this.showRightPanel.bind(this);
    this.showBulkUpdateInModule = this.showBulkUpdateInModule.bind(this);
    this.setConsoleRefreshButton = this.setConsoleRefreshButton.bind(this);
    this.getAdminPrefrences = this.getAdminPrefrences.bind(this);
    this.showConditionBuilder = this.showConditionBuilder.bind(this);
  }
  setConsoleRefreshButton(data){
      this.setState({consoleRefreshButton: data});
  };

  showRightPanel(data, index){
    try {
      this.setState({ rightPanelData: data, selectedRowIndex: index});
    } catch (e) {
      console.error(e);
    }
  };
  slaDisableDateRange(val){
    this.setState({
      slaDisableDateRangeState:val
    })
  }
  setLimit(val){
    this.setState({
      Limit: val
    })
  }
  setPage(val){
    this.setState({
      page: val
    })
  }
  setModule(val){
    this.setState({
      module: val
    })
  }
  setGroupFilterApplied(value) {
    this.setState({ groupFilterApplied: value });
  }
  setChangeMgmtgroupSelected(value) {
    this.setState({ isChangeMgmtgroupSelected: value });
  }
  setImplementationMgmtgroupSelected(value) {
    this.setState({ isImplementationMgmtgroupSelected: value });
  }

  isWIBGlobalSearchEnabled(value) {
    GLOBAL.isWIBGlobalSearchEnabled = value;
    this.setState({ isWIBGlobalSearch: value });
  }
  refreshClickFun(value) {
    this.setState({ isRefreshClick: value });
  }


  componentDidMount(props) {
    //this.setTaskSelectedStatusValue([1,2,7]);
    //this.props.setWipSearchApply(false);
    GLOBAL.slaHeadEnable = false;
  }

  multiSelectOpenFun(keyName, val) {
    // this.showRightSide(false);
    // if(val==true){
    //   this.setState({ isMultiOpen:true});
    // }else{
    //   this.setState({ isMultiOpen:false});
    // }
    this.setState({isMultiOpen: val})
    switch (keyName) {
      case "statusId":
        this.setState({
          isStatusOpen: val,
          isWorkItemOpen: false,
          isAssignedToGroupOpen: false,
        });
        break;
      case "workItemType":
        this.setState({
          isStatusOpen: false,
          isWorkItemOpen: val,
          isAssignedToGroupOpen: false,
        });
        break;
      case "assignedToGroup":
        this.setState({
          isStatusOpen: false,
          isWorkItemOpen: false,
          isAssignedToGroupOpen: val,
        });
        break;
      case "itemStatusFld":
        this.setState({
          isFulFillmentStatusOpen: val,
          isFulFillmentAsgGroupOpen: false,
        });
        break;
      case "asgGroup":
        this.setState({
          isFulFillmentStatusOpen: false,
          isFulFillmentAsgGroupOpen: val,
        });
        break;
      case "inciStatus":
        this.setState({
          isIncidentStatusOpen: val,
          isIncidentPriorityOpen: false,
          isIncidentSupportGroupOpen: false,
          isIncidentCIMGroupsOpen: false,
          isIncidentCriticalityOpen: false,
        });
        break;
      case "incipriort":
        this.setState({
          isIncidentStatusOpen: false,
          isIncidentPriorityOpen: val,
          isIncidentSupportGroupOpen: false,
          isIncidentCIMGroupsOpen: false,
          isIncidentCriticalityOpen: false,
        });
        break;
      case "inciSuppGrp":
        this.setState({
          isIncidentStatusOpen: false,
          isIncidentPriorityOpen: false,
          isIncidentSupportGroupOpen: val,
          isIncidentCIMGroupsOpen: false,
          isIncidentCriticalityOpen: false,
        });
        break;
      case "inciCIMGrp":
        this.setState({
          isIncidentStatusOpen: false,
          isIncidentPriorityOpen: false,
          isIncidentSupportGroupOpen: false,
          isIncidentCIMGroupsOpen: val,
          isIncidentCriticalityOpen: false,
        });
        break;
      case "inciCritlity":
        this.setState({
          isIncidentStatusOpen: false,
          isIncidentPriorityOpen: false,
          isIncidentSupportGroupOpen: false,
          isIncidentCIMGroupsOpen: false,
          isIncidentCriticalityOpen: val,
        });
        break;
      case "proStatus":
        this.setState({
          isProStatusOpen: val,
          isProPriorityOpen: false,
          isProManagGroupOpen: false,
        });
        break;
      case "proPriort":
        this.setState({
          isProStatusOpen: false,
          isProPriorityOpen: val,
          isProManagGroupOpen: false,
        });
        break;
      case "proManagGrp":
        this.setState({
          isProStatusOpen: false,
          isProPriorityOpen: false,
          isProManagGroupOpen: val,
        });
        break;
      case "chgStatusFld":
        this.setState({
          isChangeStatusOpen: val,
          isChangeManagGroupOpen: false,
          isChangeImpleGrpOpen: false,
          isChangeTypeOpen: false,
        });
        break;
      case "chgManagGroup":
        this.setState({
          isChangeStatusOpen: false,
          isChangeManagGroupOpen: val,
          isChangeImpleGrpOpen: false,
          isChangeTypeOpen: false,
        });
        break;
      case "chgImpleGrp":
        this.setState({
          isChangeStatusOpen: false,
          isChangeManagGroupOpen: false,
          isChangeImpleGrpOpen: val,
          isChangeTypeOpen: false,
        });
        break;
      case "chgType":
        this.setState({
          isChangeStatusOpen: false,
          isChangeManagGroupOpen: false,
          isChangeImpleGrpOpen: false,
          isChangeTypeOpen: val,
        });
        break;
      case "allFalse":
        this.setState({
          isStatusOpen: false,
          isWorkItemOpen: false,
          isAssignedToGroupOpen: false,
          isFulFillmentStatusOpen: false,
          isFulFillmentAsgGroupOpen: false,
          isIncidentStatusOpen: false,
          isIncidentPriorityOpen: false,
          isIncidentSupportGroupOpen: false,
          isIncidentCIMGroupsOpen: false,
          isIncidentCriticalityOpen: false,
          isProStatusOpen: false,
          isProPriorityOpen: false,
          isProManagGroupOpen: false,
          isChangeStatusOpen: false,
          isChangeManagGroupOpen: false,
          isChangeImpleGrpOpen: false,
          isChangeTypeOpen: false,
        });
        break;
    }
  }
  selectAllLabelFun(keyName, val) {
    switch (keyName) {
      case "statusId":
        this.setState({ isTaskStatusAllLabel: val });
        break;
      case "workItemType":
        this.setState({ isTaskWorkItemAllLabel: val });
        break;
      case "assignedToGroup":
        this.setState({ isTaskAssignedToGroupAllLabel: val });
        break;
      case "itemStatusFld":
        this.setState({ isFulFillmentStatusAllLabel: val });
        break;
      case "asgGroup":
        this.setState({ isFulFillmentAsgGroupAllLabel: val });
        break;
      case "inciStatus":
        this.setState({ isIncidentStatusAllLabel: val });
        break;
      case "incipriort":
        this.setState({ isIncidentPriorityAllLabel: val });
        break;
      case "inciSuppGrp":
        this.setState({ isIncidentSupportGroupAllLabel: val });
        break;
      case "inciCIMGrp":
        this.setState({ isIncidentCIMGroupsAllLabel: val });
        break;
      case "inciCritlity":
        this.setState({ isIncidentCriticalityAllLabel: val });
        break;
      case "proStatus":
        this.setState({ isProStatusAllLabel: val });
        break;
      case "proPriort":
        this.setState({ isProPriorityAllLabel: val });
        break;
      case "proManagGrp":
        this.setState({ isProManagGroupAllLabel: val });
        break;
      case "chgStatusFld":
        this.setState({ isChangeStatusAllLabel: val });
        break;
      case "chgManagGroup":
        this.setState({ isChangeManagGroupAllLabel: val });
        break;
      case "chgImpleGrp":
        this.setState({ isChangeImpleGrpAllLabel: val });
        break;
      case "chgType":
        this.setState({ isChangeTypeAllLabel: val });
        break;
      case "allFalse":
        this.setState({
          isTaskStatusAllLabel: false,
          isTaskWorkItemAllLabel: false,
          isTaskAssignedToGroupAllLabel: false,
          isFulFillmentStatusAllLabel: false,
          isFulFillmentAsgGroupAllLabel: false,
          isIncidentStatusAllLabel: false,
          isIncidentPriorityAllLabel: false,
          isIncidentSupportGroupAllLabel: false,
          isIncidentCIMGroupsAllLabel: false,
          isIncidentCriticalityAllLabel: false,
          isProStatusAllLabel: false,
          isProPriorityAllLabel: false,
          isProManagGroupAllLabel: false,
          isChangeStatusAllLabel: false,
          isChangeManagGroupAllLabel: false,
          isChangeImpleGrpAllLabel: false,
          isChangeTypeAllLabel: false,
        });
        break;
    }
  }

  selectedGlobalSearchModule(selectedModule) {
    this.setState({ selectedGlobalModule: selectedModule });
  }
  setGlobalSearchInformation(
    selectedGlobalDropdown,
    calculatedTypeToSend,
    enteredGlobalSearchValue,
    retrivedGlobalSearchEmployeeValue = ""
  ) {
    this.setState({
      selectedGlobalDropdown: selectedGlobalDropdown,
      calculatedTypeToSend: calculatedTypeToSend,
      enteredGlobalSearchValue: enteredGlobalSearchValue,
      retrivedGlobalSearchEmployeeValue: retrivedGlobalSearchEmployeeValue,
    });
  }
  setOnFulfillmentFilterSelection(value) {
    this.setState({ isFulfillmentFilterSelected: value });
  }
  setOnBreakfixFilterSelection(value) {
    this.setState({ isBreakfixFilterSelected: value });
  }
  setOnInvestigationFilterSelection(value) {
    this.setState({ isInvestigationFilterSelected: value });
  }
  setOnChangeMgmtFilterSelection(value) {
    this.setState({ isChangeMgmtFilterSelected: value });
  }
  dateRangeLabel(val, param) {
    switch (param) {
      case "fullfilment":
        return this.setState({ fulfillDateRangeLabel: val });
      case "breakfix":
        return this.setState({ breakDateRangeLabel: val });
      case "investigation":
        return this.setState({ problemDateRangeLabel: val });
      case "changeManagement":
        return this.setState({ changeDateRangeLabel: val });
      default:
        return this.setState({ taskDateRangeLabel: val });
    }
  }

  selectedFilterTab(tabVal) {
    this.setState({ filterTabActive: tabVal });
  }

  filterTabClick(selectedFilterTab, selectedModuleName) {
    let changeUserid = "";
    this.setState({ filterTabActive: selectedFilterTab });
    this.setState({ isRightSideVisible: false });
    this.props.setWibActiveTab(selectedFilterTab);
    if (
      this.state.role_id.split(",").includes("20") &&
      !this.state.role_id.split(",").includes("52")
    ) {
      changeUserid = "changeUserid";
    } else if (
      this.state.role_id.split(",").includes("52") &&
      !this.state.role_id.split(",").includes("20")
    ) {
      changeUserid = "impUserid";
    } else if (
      (this.state.role_id.split(",").includes("20") &&
        this.state.role_id.split(",").includes("52")) ||
      (this.state.role_id.split(",").includes("20") &&
        this.state.role_id.split(",").includes("51")) ||
      (this.state.role_id.split(",").includes("52") &&
        this.state.role_id.split(",").includes("19"))
    ) {
      changeUserid = "impUserid,changeUserid";
    } else if (
      this.state.role_id.split(",").includes("19") &&
      !this.state.role_id.split(",").includes("51")
    ) {
      changeUserid = "changeUserid";
    } else if (
      this.state.role_id.split(",").includes("51") &&
      !this.state.role_id.split(",").includes("19")
    ) {
      changeUserid = "impUserid";
    } else if (
      this.state.role_id.split(",").includes("19") &&
      this.state.role_id.split(",").includes("51")
    ) {
      changeUserid = "impUserid,changeUserid";
    }
    let filterValue = {
      1: "individual",
      2: "group",
      3: "all",
      4: "sla view",
    };
    if (selectedModuleName == "breakfix") {
      this.handleSelect(selectedModuleName, filterValue[selectedFilterTab]);
    } else if (selectedModuleName == "fullfilment") {
      if (selectedFilterTab == 1) {
        this.handleSelectMyGroup();
      } else if (selectedFilterTab == 2) {
        this.handleSelectAllGroup();
      } else if (selectedFilterTab == 3) {
        this.handleSelect(selectedModuleName);
      }
    } else if (selectedModuleName == "investigation") {
      this.handleSelect(selectedModuleName, filterValue[selectedFilterTab]);
    } else if (selectedModuleName == "changeManagement") {
      (GLOBAL.changeMangemtGroup = 0), (GLOBAL.impleGroup = 0);
      if (selectedFilterTab == 1) {
        this.handleSelect(selectedModuleName, "individual", changeUserid);
      } else if (selectedFilterTab == 2) {
        this.handleSelect(selectedModuleName, "group", changeUserid);
      } else if (selectedFilterTab == 3) {
        this.handleSelect(selectedModuleName, "all");
      }
    } else if (selectedModuleName == "task") {
      this.handleSelect(selectedModuleName, filterValue[selectedFilterTab]);
    } else if (selectedModuleName == "appointment") {
      this.setAppointmentAction("dateRangeLabel", "Custom Range");
    }else if (selectedModuleName == "action") {
      this.setAppointmentAction("dateRangeLabel", "Custom Range");
    }
  }
  handleSelectMyGroup() {
    this.setState({ selectedModuleName: "fullfilment" });
    if (
      this.props.wibSearchParams.searchParams &&
      Object.keys(this.props.wibSearchParams.searchParams).length > 0 &&
      this.props.isSearchParamsApply &&
      typeof this.props.wibGlobalSearchFilterData.selectedGlobalModule ===
        "undefined"
    ) {
      let params = this.props.wibSearchParams.searchParams.searchParams;
      if (!GLOBAL.isWIBGlobalSearchEnabled) {
        this.props.loadFulfillmentList(params.searchParams);
      }
    } else {
      this.setState({
        tableSizeFullfilment: 10,
        tableCurrentPageFullfilment: 0,
        moduleName: "fullfilment",
      });
      this.props.ClearFiltersState(); //this clear all the filters
      this.props.resetBFQVFilters();
      this.props.fetchFulfillmentGroupList(); // THIS API GIVES GRPS AS RESPONSE

      this.setSelectedSearchFilterFulfilment("displayNumber");
      this.changeSearchInputFulfilment("");
      this.isSearchFulfilment(false);
      this.setFuldisplayNumberInputValues("", "");
      this.setFulCiServiceNameInputValues("");
      this.setFulRequesterInputValues("");
      this.setFulfillerInputValues("");
      this.setFulCompanyNameInputValues("");
      this.setFulSummaryInputValues('');

      this.setFulSearchKeyValue("", "", "");
      this.setFulReportedStartAndEndDate({}, {});

      this.updateGroup([]);
      this.updateFulStatus([60, 70, 100]);
      this.updateUserGroup([this.state.user_id]);
      this.updateFulGroup([]);
      this.setFulSelectedStatusValue([]);
      this.setFulSelectedGroupValue([]);
      if(this.state.fulfillmentReportedDateRef !== undefined)
        { this.state.fulfillmentReportedDateRef.endDate = moment()}
        
      if(this.state.fulfillmentReportedDateRef !== undefined)
        { this.state.fulfillmentReportedDateRef.startDate = moment().subtract(
            defaultDateRangeDays,
            "days"
          )}
      
      let searchParams = Object.assign({});
      searchParams.searchByList = "";
      // searchParams.multipleValueList = 'Under Fulfillment,In Progress,Referred Back';
      searchParams.multipleValueList = "";
      searchParams.size = 10;
      searchParams.patternList = "in";
      searchParams.currentPage = 0;
      searchParams.sortBy = "itemId";
      searchParams.orderBy = "desc";
      if (!GLOBAL.isWIBGlobalSearchEnabled) {
        this.props.loadFulfillmentList(
          searchParams,
          [60, 70, 100],
          [],
          [this.state.user_id]
        );
      }
      this.setGraphSearchParams(searchParams, "Fullfilment");
      this.props.setBreakfixQuickViewTableCarouselIndex("1");
      this.showRightSide(false);
      this.setState({
        fulfillDateRangeLabel: `Last ${defaultDateRangeValue || 30} days`,
      });
      this.isClearAllFilterFuncFulfillment(false);
      this.onFromDateSelect("");
      this.onToDateSelect("");
      this.state.checkDateField = false;
    }
  }
  handleSelectAllGroup() {
    this.setState({ selectedModuleName: "fullfilment" });
    if (
      this.props.wibSearchParams.searchParams &&
      Object.keys(this.props.wibSearchParams.searchParams).length > 0 &&
      this.props.isSearchParamsApply &&
      typeof this.props.wibGlobalSearchFilterData.selectedGlobalModule ===
        "undefined"
    ) {
      let params = this.props.wibSearchParams.searchParams.searchParams;
      this.props.loadFulfillmentList(params.searchParams);
    } else {
      this.setState({
        tableSizeFullfilment: 10,
        tableCurrentPageFullfilment: 0,
        moduleName: "fullfilment",
      });
      this.props.ClearFiltersState(); //this clear all the filters
      this.props.resetBFQVFilters();
      this.props.fetchFulfillmentGroupList(); // THIS API GIVES GRPS AS RESPONSE

      this.setSelectedSearchFilterFulfilment("displayNumber");
      this.changeSearchInputFulfilment("");
      this.isSearchFulfilment(false);
      this.setFuldisplayNumberInputValues("", "");
      this.setFulCiServiceNameInputValues("");
      this.setFulRequesterInputValues("");
      this.setFulfillerInputValues("");
      this.setFulCompanyNameInputValues("");
      this.setFulSummaryInputValues('');

      this.setFulSearchKeyValue("", "", "");
      this.setFulReportedStartAndEndDate({}, {});

      this.updateFulStatus([60, 70, 100]);
      this.updateGroup([this.state.group_id]);
      this.updateFulGroup([]);
      this.updateUserGroup([]);
      this.setFulSelectedStatusValue([]);
      this.setFulSelectedGroupValue([]);
      if(this.state.fulfillmentReportedDateRef !== undefined)
        { this.state.fulfillmentReportedDateRef.endDate = moment()}
       
      if(this.state.fulfillmentReportedDateRef !== undefined)
         {this.state.fulfillmentReportedDateRef.startDate = moment().subtract(
            defaultDateRangeDays,
            "days"
          )}
        
      let searchParams = Object.assign({});
      searchParams.searchByList = "";
      // searchParams.multipleValueList = 'Under Fulfillment,In Progress,Referred Back';
      searchParams.multipleValueList = "";
      searchParams.size = 10;
      searchParams.currentPage = 0;
      searchParams.sortBy = "itemId";
      searchParams.orderBy = "desc";
      if (!GLOBAL.isWIBGlobalSearchEnabled) {
        this.props.loadFulfillmentList(
          searchParams,
          [60, 70, 100],
          [this.state.group_id],
          []
        ); // based on condition
      }
      this.setGraphSearchParams(searchParams, "Fullfilment");
      this.props.setBreakfixQuickViewTableCarouselIndex("1");
      this.showRightSide(false);
      this.setState({
        fulfillDateRangeLabel: `Last ${defaultDateRangeValue || 30} days`,
      });
      this.isClearAllFilterFuncFulfillment(false);
      this.onFromDateSelect("");
      this.onToDateSelect("");
      this.state.checkDateField = false;
    }
  }
  componentWillMount() {
    GLOBAL.isWIBGlobalSearchEnabled = false;
    let str = {};
    str.user_id = "";
    str = JSON.stringify(str);
    api.get("/api/userInfo", { headers: { query: str } }).then((response) => {
      let changeUserid = "";
      if (
        response.data.role_id.split(",").includes("20") &&
        !response.data.role_id.split(",").includes("52")
      ) {
        changeUserid = "changeUserid";
      } else if (
        response.data.role_id.split(",").includes("52") &&
        !response.data.role_id.split(",").includes("20")
      ) {
        changeUserid = "impUserid";
      } else if (
        (response.data.role_id.split(",").includes("20") &&
          response.data.role_id.split(",").includes("52")) ||
        (response.data.role_id.split(",").includes("20") &&
          response.data.role_id.split(",").includes("51")) ||
        (response.data.role_id.split(",").includes("52") &&
          response.data.role_id.split(",").includes("19"))
      ) {
        changeUserid = "impUserid,changeUserid";
      } else if (
        response.data.role_id.split(",").includes("19") &&
        !response.data.role_id.split(",").includes("51")
      ) {
        changeUserid = "changeUserid";
      } else if (
        response.data.role_id.split(",").includes("51") &&
        !response.data.role_id.split(",").includes("19")
      ) {
        changeUserid = "impUserid";
      } else if (
        response.data.role_id.split(",").includes("19") &&
        response.data.role_id.split(",").includes("51")
      ) {
        changeUserid = "impUserid,changeUserid";
      }
      if (response.data.role_id.split(",").includes("40") ||
        response.data.role_id.split(",").includes("45")){
          this.setState({
            slaRole: true
          })
        }

      let associatedCompaniesList =
        loggedInUserCompany + "," + response.data.associatedCompanies;
      this.setState(
        {
          user_id: response.data.user_id,
          full_name: response.data.full_name,
          role_id: response.data.role_id,
          date_format: response.data.dateformat,
          group_id: response.data.groupId == "0" ? -1 : response.data.groupId,
          groupID: response.data.groupId == "0" ? [-1] : response.data.groupId.split(','),
          changeImplentationRole: changeUserid,
        },
        () => {
          let objMapping = {
            0: "breakfix",
            1: "fullfilment",
            2: "investigation",
            3: "changeManagement",
            4: "task",
          };
          // GLOBAL.assignToListErrorColor = "";
          // GLOBAL.assignmentGrpListErrorColor = "";
          let service_desk_role_enabled = response.data.role_id
            .split(",")
            .includes("48");
          let task_viewer_role_enabled = response.data.role_id
            .split(",")
            .includes("61");

          let fulfillment_manager_role_enabled = response.data.role_id
            .split(",")
            .includes("44");
          let fulfillment_user_role_enabled = response.data.role_id
            .split(",")
            .includes("43");
          let fulfillment_viewer_role_enabled = response.data.role_id
            .split(",")
            .includes("62");
          let core_fulfillment_roles_enabled =
            fulfillment_manager_role_enabled ||
            fulfillment_user_role_enabled ||
            service_desk_role_enabled;
          let fulfillment_roles_enabled =
            core_fulfillment_roles_enabled || fulfillment_viewer_role_enabled;
          let only_fulfillment_viewer_role_enabled =
            !core_fulfillment_roles_enabled && fulfillment_viewer_role_enabled;
          let fulfillment_roles_all =
            fulfillment_manager_role_enabled ||
            service_desk_role_enabled ||
            only_fulfillment_viewer_role_enabled;
          let only_fulfillment_user_role_enabled = fulfillment_user_role_enabled && !fulfillment_manager_role_enabled;
          this.setState({
            only_fulfillment_viewer_role_enabled,
            fulfillment_roles_all,
            service_desk_role_enabled
          });

          let incident_manager_role_enabled = response.data.role_id
            .split(",")
            .includes("13");
          let incident_user_role_enabled = response.data.role_id
            .split(",")
            .includes("12");
          let critical_incident_manager_enabled = response.data.role_id
            .split(",")
            .includes("14");
          let incident_viewer_role_enabled = response.data.role_id
            .split(",")
            .includes("63");
          let core_incident_roles_enabled =
            incident_manager_role_enabled ||
            incident_user_role_enabled ||
            critical_incident_manager_enabled ||
            service_desk_role_enabled;
          let incident_roles_enabled =
            core_incident_roles_enabled || incident_viewer_role_enabled;
          let only_incident_viewer_role_enabled =
            !core_incident_roles_enabled && incident_viewer_role_enabled;
          let incident_search_all =
            incident_manager_role_enabled ||
            critical_incident_manager_enabled ||
            service_desk_role_enabled ||
            only_incident_viewer_role_enabled;
          let only_incident_user_role_enabled = incident_user_role_enabled && !(incident_manager_role_enabled || critical_incident_manager_enabled);
          this.setState({
            only_incident_viewer_role_enabled,
            incident_search_all,
            critical_incident_manager_enabled
          });

          let problem_manager_role_enabled = response.data.role_id
            .split(",")
            .includes("17");
          let problem_user_role_enabled = response.data.role_id
            .split(",")
            .includes("16");
          let problem_investigator_role_enabled = response.data.role_id
            .split(",")
            .includes("53");
          let problem_viewer_role_enabled = response.data.role_id
            .split(",")
            .includes("64");
          let core_problem_roles_enabled =
            problem_manager_role_enabled ||
            problem_user_role_enabled ||
            problem_investigator_role_enabled ||
            service_desk_role_enabled;
          let problem_roles_enabled =
            core_problem_roles_enabled || problem_viewer_role_enabled;
          let only_problem_viewer_role_enabled =
            !core_problem_roles_enabled && problem_viewer_role_enabled;
          let problem_search_all =
            problem_manager_role_enabled ||
            service_desk_role_enabled ||
            only_problem_viewer_role_enabled;
          let only_problem_investigator_role_enabled = problem_investigator_role_enabled && !problem_manager_role_enabled;
          let only_problem_user_role_enabled = problem_user_role_enabled && !problem_manager_role_enabled;
          this.setState({
            only_problem_viewer_role_enabled,
            problem_search_all,
            only_problem_investigator_role_enabled,
          });

          let change_manager_role_enabled = response.data.role_id
            .split(",")
            .includes("20");
          let change_user_role_enabled = response.data.role_id
            .split(",")
            .includes("19");
          let implementation_manager_role_enabled = response.data.role_id
            .split(",")
            .includes("52");
          let implementation_user_role_enabled = response.data.role_id
            .split(",")
            .includes("51");
          let change_viewer_role_enabled = response.data.role_id
            .split(",")
            .includes("65");
          let core_change_roles_enabled =
            change_manager_role_enabled ||
            change_user_role_enabled ||
            implementation_manager_role_enabled ||
            implementation_user_role_enabled ||
            service_desk_role_enabled;
          let change_roles_enabled =
            core_change_roles_enabled || change_viewer_role_enabled;
          let only_change_viewer_role_enabled =
            !core_change_roles_enabled && change_viewer_role_enabled;
          let change_all_payload =
            change_manager_role_enabled ||
            implementation_manager_role_enabled ||
            service_desk_role_enabled ||
            only_change_viewer_role_enabled;
          let only_change_user_role_enabled = change_user_role_enabled && !change_manager_role_enabled;
          let only_implementation_user_role_enabled = implementation_user_role_enabled && !implementation_manager_role_enabled;
          let only_d2c_user_roles_enabled = only_fulfillment_user_role_enabled || only_incident_user_role_enabled || only_problem_user_role_enabled || only_change_user_role_enabled || only_implementation_user_role_enabled;
          let only_d2c_manager_roles_enabled = fulfillment_manager_role_enabled || incident_manager_role_enabled || critical_incident_manager_enabled || problem_manager_role_enabled || change_manager_role_enabled || implementation_manager_role_enabled;
          this.setState({
            only_change_viewer_role_enabled,
            change_all_payload,
            only_d2c_user_roles_enabled,
            only_d2c_manager_roles_enabled
          });

          let core_d2c_roles_enabled =
            core_fulfillment_roles_enabled ||
            core_incident_roles_enabled ||
            core_problem_roles_enabled ||
            core_change_roles_enabled;
          let only_task_viewer_role_enabled =
            !core_d2c_roles_enabled && task_viewer_role_enabled;
          this.setState({
            fulfillmentModuleEnabled: fulfillment_roles_enabled,
          });
          this.setState({ incidentModuleEnabled: incident_roles_enabled });
          this.setState({ problemModuleEnabled: problem_roles_enabled });
          this.setState({ changeModuleEnabled: change_roles_enabled });
          this.setState({ core_d2c_roles_enabled: core_d2c_roles_enabled });
          this.setState({
            task_viewer_role_enabled: task_viewer_role_enabled,
            only_task_viewer_role_enabled,
          });
          let manager_role_enabled = fulfillment_manager_role_enabled || incident_manager_role_enabled || problem_manager_role_enabled || change_manager_role_enabled || implementation_manager_role_enabled;
          this.setState({manager_role_enabled});
          let only_viewer_role_enabled = only_task_viewer_role_enabled || only_fulfillment_viewer_role_enabled || only_incident_viewer_role_enabled || only_problem_viewer_role_enabled || only_change_viewer_role_enabled;
          this.setState({only_viewer_role_enabled});
          let core_user_role_enabled = only_fulfillment_user_role_enabled || only_incident_user_role_enabled || only_problem_user_role_enabled || only_change_user_role_enabled || implementation_user_role_enabled;
          this.setState({core_user_role_enabled});
          this.checkIsWalkupEnabled(core_incident_roles_enabled, loggedinUserCompanyID, 'Walk-up', '23')
          let modulesEnabled = {};
          if(core_fulfillment_roles_enabled){
            modulesEnabled['Fullfilment']=true;
          }
          if(core_incident_roles_enabled){
            modulesEnabled['Breakfix']=true; 
          }
          if(core_problem_roles_enabled){
            modulesEnabled['Investigation']=true;
          }
          if(core_change_roles_enabled){
            modulesEnabled['ChangeManagement']=true;
          }
          this.setState({modulesEnabled});
          let carouselIndex = "";
          if (
            (core_d2c_roles_enabled ||
            task_viewer_role_enabled ||
            (this.props.wibSearchParams.searchParams &&
              Object.keys(this.props.wibSearchParams.searchParams).length > 0))
              && !(!this.props.walkupEnabled.walkupEnabled && this.props.breakfixQuickViewTable.carouselIndex === '7')
          ) {
            carouselIndex = this.props.breakfixQuickViewTable.carouselIndex;
          } else {
            if (fulfillment_roles_enabled) {
              carouselIndex = "1";
            } else if (incident_roles_enabled) {
              carouselIndex = "0";
            } else if (problem_roles_enabled) {
              carouselIndex = "2";
            } else {
              carouselIndex = "3";
            }
          }
          if (!(core_d2c_roles_enabled || task_viewer_role_enabled)) {
            this.setState({ disableTaskModule: true });
          }
          this.setState({ state_carousel_index: carouselIndex });
          this.props.setBreakfixQuickViewTableCarouselIndex(carouselIndex);
          if (
            typeof this.props.wibGlobalSearchFilterData.selectedGlobalModule !==
            "undefined"
          ) {
            this.isWIBGlobalSearchEnabled(
              this.props.wibGlobalSearchFilterData.isWIBGlobalSearch
            );
            this.setGlobalSearchInformation(
              this.props.wibGlobalSearchFilterData.selectedGlobalDropdown,
              this.props.wibGlobalSearchFilterData.calculatedTypeToSend,
              this.props.wibGlobalSearchFilterData.enteredGlobalSearchValue,
              this.props.wibGlobalSearchFilterData
                .retrivedGlobalSearchEmployeeValue
            );
            this.selectedFilterTab(
              this.props.wibGlobalSearchFilterData.filterTabActive
            );
            this.selectedGlobalSearchModule(
              this.props.wibGlobalSearchFilterData.selectedGlobalModule
            );
          }
          if (objMapping[carouselIndex] == "breakfix") {
            if (
              this.props.isSearchParamsApply &&
              typeof this.props.wibGlobalSearchFilterData
                .selectedGlobalModule === "undefined"
            ) {
              if (only_incident_viewer_role_enabled) {
                this.props.setWibActiveTab(3);
                this.selectedFilterTab(3);
                this.handleSelect("breakfix", "all");
              } else {
                this.setState({
                  filterTabActive:
                    this.props.wibActiveTab && this.props.wibActiveTab !== ""
                      ? this.props.wibActiveTab
                      : 1,
                });
                this.props.wibActiveTab === 2
                  ? this.handleSelect("breakfix", "group")
                  : this.props.wibActiveTab === 3
                  ? this.handleSelect("breakfix", "all")
                  : this.handleSelect("breakfix", "individual");
              }
            } else {
              if (only_incident_viewer_role_enabled) {
                this.props.setWibActiveTab(3);
                this.selectedFilterTab(3);
                this.handleSelect("breakfix", "all");
              } else {
                typeof this.props.wibGlobalSearchFilterData
                  .selectedGlobalModule === "undefined"
                  ? this.handleSelect("breakfix", "individual")
                  : this.props.wibGlobalSearchFilterData.filterTabActive === 3
                  ? this.handleSelect("breakfix", "all")
                  : this.handleSelect("breakfix", "group");
              }
            }
          } else if (objMapping[carouselIndex] == "investigation") {
            if (
              this.props.isSearchParamsApply &&
              typeof this.props.wibGlobalSearchFilterData
                .selectedGlobalModule === "undefined"
            ) {
              if (only_problem_viewer_role_enabled) {
                this.props.setWibActiveTab(3);
                this.selectedFilterTab(3);
                this.handleSelect("investigation", "all");
              } else {
                this.setState({
                  filterTabActive:
                    this.props.wibActiveTab && this.props.wibActiveTab !== ""
                      ? this.props.wibActiveTab
                      : 1,
                });
                this.props.wibActiveTab === 2
                  ? this.handleSelect("investigation", "group")
                  : this.props.wibActiveTab === 3
                  ? this.handleSelect("investigation", "all")
                  : this.handleSelect("investigation", "individual");
              }
            } else {
              if (only_problem_viewer_role_enabled) {
                this.props.setWibActiveTab(3);
                this.selectedFilterTab(3);
                this.handleSelect("investigation", "all");
              } else {
                typeof this.props.wibGlobalSearchFilterData
                  .selectedGlobalModule === "undefined"
                  ? this.handleSelect("investigation", "individual")
                  : this.props.wibGlobalSearchFilterData.filterTabActive === 3
                  ? this.handleSelect("investigation", "all")
                  : this.props.wibGlobalSearchFilterData.filterTabActive === 2
                  ? this.handleSelect("investigation", "group")
                  : this.handleSelect("investigation", "individual");
              }
            }
          } else if (objMapping[carouselIndex] == "fullfilment") {
            if (this.props.isSearchParamsApply) {
              if (only_fulfillment_viewer_role_enabled) {
                this.props.setWibActiveTab(3);
                this.selectedFilterTab(3);
                this.handleSelect("fullfilment");
              } else {
                this.setState({
                  filterTabActive: this.props.wibActiveTab
                    ? this.props.wibActiveTab
                    : 1,
                });
                this.props.wibActiveTab === 3
                  ? this.handleSelect("fullfilment")
                  : this.props.wibActiveTab === 2
                  ? this.handleSelectAllGroup()
                  : this.handleSelectMyGroup();
              }
            } else {
              if (only_fulfillment_viewer_role_enabled) {
                this.setState({ filterTabActive: 3 });
                this.props.setWibActiveTab(3);
                this.selectedFilterTab(3);
                this.handleSelect("fullfilment");
              } else {
                this.handleSelectMyGroup();
              }
            }
            this.props.setWipSearchApply(false);
          } else if (objMapping[carouselIndex] == "changeManagement") {
            if (
              this.props.isSearchParamsApply &&
              typeof this.props.wibGlobalSearchFilterData
                .selectedGlobalModule === "undefined"
            ) {
              if (only_change_viewer_role_enabled) {
                this.props.setWibActiveTab(3);
                this.selectedFilterTab(3);
                this.handleSelect("changeManagement", "all");
              } else {
                this.setState({
                  filterTabActive:
                    this.props.wibActiveTab && this.props.wibActiveTab !== ""
                      ? this.props.wibActiveTab
                      : 1,
                });
                this.props.wibActiveTab === 2
                  ? this.handleSelect("changeManagement", "group", changeUserid)
                  : this.props.wibActiveTab === 3
                  ? this.handleSelect("changeManagement", "all")
                  : this.filterTabClick(1, "changeManagement");
              }
            } else {
              if (only_change_viewer_role_enabled) {
                this.props.setWibActiveTab(3);
                this.selectedFilterTab(3);
                this.handleSelect("changeManagement", "all");
              } else {
                typeof this.props.wibGlobalSearchFilterData
                  .selectedGlobalModule === "undefined"
                  ? this.filterTabClick(1, "changeManagement")
                  : this.props.wibGlobalSearchFilterData.filterTabActive === 3
                  ? this.handleSelect("changeManagement", "all")
                  : this.handleSelect(
                      "changeManagement",
                      "group",
                      changeUserid
                    );
              }
            }
          } else if (objMapping[carouselIndex] == "task") {
            if (!only_task_viewer_role_enabled) {
              this.props.fetchTaskGroupList();
            }
            if (
              this.props.isSearchParamsApply &&
              typeof this.props.wibGlobalSearchFilterData
                .selectedGlobalModule === "undefined" &&
              this.props.wibSearchParams.searchParams &&
              Object.keys(this.props.wibSearchParams.searchParams).length > 0
            ) {
              this.setState({
                filterTabActive:
                  this.props.wibActiveTab && this.props.wibActiveTab !== ""
                    ? this.props.wibActiveTab
                    : 1,
              });
              const searchParams =
                this.props.wibSearchParams.searchParams.searchParams;

              this.props.loadTaskList(
                searchParams.groupid,
                searchParams.searchparams,
                searchParams.limit,
                searchParams.skip,
                searchParams.assignmentType,
                searchParams.assignedToIndividual,
                searchParams.associatedCompaniesList
              );
              this.setGraphSearchParams(searchParams?.searchparams, "Task");
            } else {
              insideParams = {statusId: { inq: ['1', '2', '7'] } };
              if (only_task_viewer_role_enabled) {
                this.props.setWibActiveTab(3);
                this.selectedFilterTab(3);
                this.props.loadTaskList(
                  "",
                  {},
                  10,
                  0,
                  "all",
                  "",
                  associatedCompaniesList
                );
              } else {
                this.props.loadTaskList(
                  "",
                  {},
                  10,
                  0,
                  "individual",
                  response.data.user_id
                );
              }
              this.setGraphSearchParams(insideParams, "Task");
            }
          }
          this.props.setWipSearchApply(false);
        }
      );
      GLOBAL.Vip = response.data.vip;
    });

    api
      .get(GLOBAL.profileDetailsUrl, { headers: { query: str } })
      .then((response) => {
        if (response && response["data"] && response["data"]["companyId"]) {
          this.setState({ companyId: response["data"]["companyId"] });
          this.getAdminPrefrences(response["data"]["companyId"],'Work Item Board','16','0','slaStatus');
          this.getAdminPrefrences(response["data"]["companyId"],'Work Item Board','17','0','requestID');
          this.getAdminPrefrences(response["data"]["companyId"],'Work Item Board','31','0','NlpSearch');
          this.props.emptyAdminPreferencesListData();
          this.props.getAdminPreferences(response["data"]["companyId"],'change','8');
          let url = "";
          url =
            GLOBAL.ciSupportAllGroupUrl +
            "/id/" +
            response["data"]["companyId"];
          axios
            .get(url)
            .then((response1) => {
              let allGroup = [],
                all_groups_for_task = [];
              if (response1.data !== "") {
                response1.data.map((item) => {
                  all_groups_for_task.push({
                    field1Value: item.groupName,
                    field1Key: item.groupId,
                  });
                  allGroup.push({
                    value: "" + item.groupId + "",
                    label: item.groupName,
                    isExternal: item.isExternal,
                  });
                });
              }
              this.setState({ userAllGroups: allGroup, all_groups_for_task });
            })

            .catch((err) => {
              console.log(err);
            });
        }
      });
  }
  componentWillUnmount() {
    GLOBAL.isWIBGlobalSearchEnabled = false;
    this.props.clearallCIMgroups();
    this.props.setInitialwibGlobalSearchFilterData();
    if (this.state.filterTabActive === 1) {
      this.props.setWibActiveTab(this.state.filterTabActive);
    }
    if (this.state.selectedModuleName === "breakfix") {
      this.props.setWibSelectedDateRangeLabel(this.state.breakDateRangeLabel);
    } else if (
      this.state.selectedModuleName === "task" &&
      this.state.taskDateRangeLabel
    ) {
      this.props.setWibSelectedDateRangeLabel(this.state.taskDateRangeLabel);
    } else if (
      this.state.selectedModuleName === "fullfilment" &&
      this.state.fulfillDateRangeLabel
    ) {
      this.props.setWibSelectedDateRangeLabel(this.state.fulfillDateRangeLabel);
    } else if (this.state.selectedModuleName === "investigation") {
      this.props.setWibSelectedDateRangeLabel(this.state.problemDateRangeLabel);
    } else if (this.state.selectedModuleName === "changeManagement") {
      this.props.setWibSelectedDateRangeLabel(this.state.changeDateRangeLabel);
    }
    // this.props.SaveFiltersState(this.state)
  }

  multiOpen(value) {
    this.setState({isMultiOpen: value});
  }

  showRightSide(value) {
    if (value == true) {
      this.setState({
        isMultiOpen: false,
        isStatusOpen: false,
        isWorkItemOpen: false,
        isAssignedToGroupOpen: false,
        isFulFillmentStatusOpen: false,
        isFulFillmentAsgGroupOpen: false,
        isIncidentStatusOpen: false,
        isIncidentPriorityOpen: false,
        isIncidentSupportGroupOpen: false,
        isIncidentCIMGroupsOpen: false,
        isIncidentCriticalityOpen: false,
        isProStatusOpen: false,
        isProPriorityOpen: false,
        isProManagGroupOpen: false,
        isChangeStatusOpen: false,
        isChangeManagGroupOpen: false,
        isChangeImpleGrpOpen: false,
        isChangeTypeOpen: false,
      });
    }
    this.setState({ isRightSideVisible: value });
  }
  ////// fulfillment /////
  showFulfillmentActiveRow(i) {
    if ((" i ", i));
    this.setState({ fulfillmenRowIndex: i });
  }
  setFulSearchKeyValue(key, value, pattern) {
    this.setState({ fulfillmentSearchKey: key });
    this.setState({ fulfillmentSearchValue: value });
    this.setState({ fulfillmentPattern: pattern });
  }
  setFulSortOrder(sortBy, sortOn) {
    this.setState({ fulfillmentSortOrder: sortBy });
    this.setState({ fulfillmentSortOnColumn: sortOn });
  }
  setFulReportedRef(ref) {
    this.inputRef = ref;
    this.setState({ fulfillmentReportedDateRef: this.inputRef });
  }
  setFulSelectedStatusValue(value) {
    this.setState({ fulfillmentSelectedStatusValue: value });
  }
  setFulSelectedGroupValue(value) {
    this.setState({ fulfillmentGroupValue: value });
  }
  setChangMangmtGroupValue(value) {
    this.setState({ changeManagementGroupValue: value });
  }
  setimpleGroupValue(value) {
    this.setState({ impleGroupValue: value });
  }
  isClearAllFilterFuncFulfillment(value) {
    this.setState({ fulfillmentIsClearAllFilter: value });
  }
  setFuldisplayNumberInputValues(value, column) {
    // this.setState({
    //   fulfillmentDisplayNumberSearchInput: displayNumber,
    // });
    switch (column) {
      case "orderNumber":
        this.setState({
          fulfillmentDisplayNumberSearchInput: value || "",
        });
        break;
      case "itemNumber":
        this.setState({
          fulfillmentWorkItemIdSearchInput: value || "",
        });
        break;
      default:
        this.setState({
          fulfillmentDisplayNumberSearchInput: value || "",
          fulfillmentWorkItemIdSearchInput: value || "",
        });
        break;
    }
  }

  setFulCiServiceNameInputValues(ciServiceName) {
    this.setState({
      fulfillmentCiServiceNameSearchInput: ciServiceName,
    });
  }

  setFulRequesterInputValues(requestedBy) {
    this.setState({
      fulfillmentRequesterNameSearchInput: requestedBy,
    });
  }
  setFulCompanyNameInputValues(companyName) {
    this.setState({
      fulfillmentCompanyNameSearchInput: companyName,
    });
  }
  setFulSummaryInputValues(summary){
    this.setState({
      fulfillmentSummarySearchInput: summary,
    });
  }

  setFulfillerInputValues(fulfiller) {
    this.setState({
      fulfillerNameSearchInput: fulfiller,
    });
  }

  setFulReportedStartAndEndDate(startDate, endDate) {
    this.setState({
      fulfillmentReportedStartDate: startDate,
      fulfillmentReportedEndDate: endDate,
    });
  }
  updateFulStatus(statusValues) {
    this.setState({ fulfillmentStatus: statusValues });
  }
  setDefaultFulfillmentStatusSelected(value) {
    this.setState({ defaultFulfillmentStatusSelected: value });
  }
  setDefaultBreakfixStatusSelected(value) {
    this.setState({ defaultBreakfixStatusSelected: value });
  }
  setDefaultInvestigationStatusSelected(value) {
    this.setState({ defaultInvestigationStatusSelected: value });
  }
  setDefaultChangeMgmtStatusSelected(value) {
    this.setState({ defaultChangeMgmtStatusSelected: value });
  }
  updateFulGroup(groupValues) {
    this.setState({ fulfillmentGroup: groupValues });
  }
  updateChangemangmtGroup(groupValues) {
    this.setState({ changemngmtGroup: groupValues });
  }
  updateImplmtGroup(groupValues) {
    this.setState({ implemntGroup: groupValues });
  }
  updateGroup(groupValues) {
    this.setState({ fulGrp: groupValues });
  }
  updateUserGroup(userGroup) {
    this.setState({ fulfillmentUser: userGroup });
  }
  /////// Change ///////
  showChangeActiveRow(i) {
    if ((" i ", i));
    this.setState({ changeRowIndex: i });
  }
  setChangeSearchKeyValue(key, value, pattern) {
    this.setState({ changeSearchKey: key });
    this.setState({ changeSearchValue: value });
    this.setState({ changePattern: pattern });
  }
  setChangeSortOrder(sortBy, sortOn) {
    this.setState({ changeSortOrder: sortBy });
    this.setState({ changeSortOnColumn: sortOn });
  }
  setChangeReportedRef(ref) {
    this.inputRef = ref;
    this.setState({ changeReportedDateRef: this.inputRef });
  }
  setChangeExpectedDateRef(ref) {
    this.inputRef = ref;
    this.setState({ changeExpectedDateRef: this.inputRef });
  }
  setChangeCABDateRef(ref) {
    this.inputRef = ref;
    this.setState({ changeCABDateRef: this.inputRef });
  }

  setChangeSelectedStatusValue(value) {
    this.setState({ changeSelectedStatusValue: value });
  }
  setSelectedChangeTypeValue(value) {
    this.setState({ changeTypeValue: value });
  }
  isClearAllFilterFuncChange(value) {
    this.setState({ changeIsClearAllFilter: value });
  }
  setChangedisplayNumberInputValues(value, column) {
    // this.setState({
    //   changeDisplayNumberSearchInput: displayNumber,
    // });
    switch (column) {
      case "requestNumber":
        this.setState({
          changeDisplayNumberSearchInput: value || "",
        });
        break;
      case "changeNumber":
        this.setState({
          changeWorkItemIdSearchInput: value || "",
        });
        break;
      default:
        this.setState({
          changeDisplayNumberSearchInput: value || "",
          changeWorkItemIdSearchInput: value || "",
        });
        break;
    }
  }
  setChangeCiServiceNameInputValues(ciServiceName) {
    this.setState({
      changeCiServiceNameSearchInput: ciServiceName,
    });
  }
  setChangeCompanyNameInputValues(ciServiceName) {
    this.setState({
      changeCompanyNameSearchInput: ciServiceName,
    });
  }
  setChangeSummaryNameInputValues(ciServiceName) {
    this.setState({
      changeSummaryNameSearchInput: ciServiceName,
    });
  }
  setChangeRequesterInputValues(requestedBy) {
    this.setState({
      changeRequesterNameSearchInput: requestedBy,
    });
  }
  setChangeReportedStartAndEndDate(startDate, endDate) {
    this.setState({
      changeReportedStartDate: startDate,
      changeReportedEndDate: endDate,
    });
  }
  setChangeExpectedStartAndEndDate(startDate, endDate) {
    this.setState({
      changeExpectedStartDate: startDate,
      changeExpectedEndDate: endDate,
    });
  }
  setChangeCABStartAndEndDate(startDate, endDate) {
    this.setState({ changeCABStartDate: startDate, changeCABEndDate: endDate });
  }

  updateChangeStatus(statusValues) {
    this.setState({ changeStatus: statusValues });
  }
  updateChangeType(groupValues) {
    this.setState({ changeType: groupValues });
  }
  // Search Filters -----------------------------------

  isSearchFulfilment(value) {
    this.setState({ enableSearchFulfilment: value });
  }
  isSearchBreakFix(value) {
    this.setState({ enableSearchBreakFix: value });
  }

  changeSearchInputFulfilment(value) {
    if (this.state.checkSearchtextFieldFulfilment) {
      this.setState({ valueStatusSearchInputFulfilment: value });
    } else this.setState({ valueSearchInputFulfilment: value });
  }

  setSelectedSearchFilterFulfilment(value) {
    this.setState({ searchFilterFulfilment: value });
    if (value == "RequestedOn") {
      this.setState({
        checkSearchtextFieldFulfilment: false,
        checkDateField: true,
      });
    } else if (value == "itemStatus") {
      this.setState({
        checkSearchtextFieldFulfilment: true,
        checkDateField: false,
      });
    } else {
      this.setState({
        checkSearchtextFieldFulfilment: false,
        checkDateField: false,
      });
    }
  }

  changeSearchInputBreakFix(value) {
    if (this.state.checkSearchtextFieldBreakFix) {
      this.setState({ valueStatusSearchInputBreakFix: value });
    } else this.setState({ valueSearchInputBreakFix: value });
  }

  setSelectedSearchFilterBreakFix(value) {
    this.setState({ searchFilterBreakfix: value });
    if (value == "ReportedOn") {
      this.setState({
        checkSearchtextFieldBreakFix: false,
        checkDateField: true,
        serviceBasedFlag: "no value",
      });
    } else if (value == "status") {
      this.setState({
        checkSearchtextFieldBreakFix: true,
        checkDateField: false,
        serviceBasedFlag: "no value",
      });
    } else if (value == "ciName") {
      this.setState({
        checkSearchtextFieldBreakFix: false,
        checkDateField: false,
        serviceBasedFlag: false,
      });
    } else if (value == "serviceName") {
      this.setState({
        checkSearchtextFieldBreakFix: false,
        checkDateField: false,
        serviceBasedFlag: true,
      });
    } else if (value == "displayNumbertagged") {
      this.setState({
        checkSearchtextFieldChangeMgmt: false,
        checkDateField: false,
        serviceBasedFlag: "no value",
        serviceBasedFlagtagged: "no value",
      });
    } else {
      this.setState({
        checkSearchtextFieldBreakFix: false,
        checkDateField: false,
        serviceBasedFlag: "no value",
      });
    }
  }

  isSearchTasks(value) {
    this.setState({ enableSearchTasks: value });
  }

  changeSearchInputTasks(value) {
    if (this.state.checkSearchtextFieldTasks) {
      this.setState({ valueStatusSearchInputTasks: value });
    } else this.setState({ valueSearchInputTasks: value });
  }

  setSelectedSearchFilterTasks(value) {
    this.setState({ searchFilterTasks: value });
    if (value == "status") {
      this.setState({ checkSearchtextFieldTasks: true });
      this.setState({ isDateField: false });
    } else if (value == "createdDate") {
      this.setState({ checkSearchtextFieldTasks: false });
      this.setState({ isDateField: true });
    } else {
      this.setState({ checkSearchtextFieldTasks: false });
      this.setState({ isDateField: false });
    }
  }

  isSearchChangeMgmt(value) {
    this.setState({ enableSearchChangeMgmt: value });
  }

  changeSearchInputChangeMgmt(value) {
    if (this.state.checkSearchtextFieldChangeMgmt) {
      this.setState({ valueStatusSearchInputChangeMgmt: value });
    } else this.setState({ valueSearchInputChangeMgmt: value });
  }

  setSelectedSearchFilterChangeMgmt(value) {
    this.setState({ searchFilterChangeMgmt: value });
    if (value == "ReportedOn") {
      this.setState({
        checkSearchtextFieldChangeMgmt: false,
        checkDateField: true,
        serviceBasedFlag: "no value",
      });
    } else if (value == "status") {
      this.setState({
        checkSearchtextFieldChangeMgmt: true,
        checkDateField: false,
        serviceBasedFlag: "no value",
      });
    } else if (value == "ciName") {
      this.setState({
        checkSearchtextFieldChangeMgmt: false,
        checkDateField: false,
        serviceBasedFlag: false,
      });
    } else if (value == "serviceName") {
      this.setState({
        checkSearchtextFieldChangeMgmt: false,
        checkDateField: false,
        serviceBasedFlag: true,
      });
    } else if (value == "displayNumbertagged") {
      this.setState({
        checkSearchtextFieldChangeMgmt: false,
        checkDateField: false,
        serviceBasedFlag: "no value",
        serviceBasedFlagtagged: "no value",
      });
    } else {
      this.setState({
        checkSearchtextFieldChangeMgmt: false,
        checkDateField: false,
        serviceBasedFlag: "no value",
      });
    }
  }

  isSearchInvestigation(value) {
    this.setState({ enableSearchInvestigation: value });
  }

  changeSearchInputInvestigation(value) {
    if (this.state.checkSearchtextFieldInvestigation) {
      this.setState({ valueStatusSearchInputInvestigation: value });
    } else this.setState({ valueSearchInputInvestigation: value });
  }

  setSelectedSearchFilterInvestigation(value) {
    this.setState({ searchFilterInvestigation: value });
    if (value == "RequestedOnProblem") {
      this.setState({
        checkSearchtextFieldInvestigation: false,
        checkDateField: true,
        serviceBasedFlag: "no value",
      });
    } else if (value == "status") {
      this.setState({
        checkSearchtextFieldInvestigation: true,
        checkDateField: false,
        serviceBasedFlag: "no value",
      });
    } else if (value == "ciName") {
      this.setState({
        checkSearchtextFieldInvestigation: false,
        checkDateField: false,
        serviceBasedFlag: false,
      });
    } else if (value == "serviceName") {
      this.setState({
        checkSearchtextFieldInvestigation: false,
        checkDateField: false,
        serviceBasedFlag: true,
      });
    } else if (value == "displayNumbertagged") {
      this.setState({
        checkSearchtextFieldChangeMgmt: false,
        checkDateField: false,
        serviceBasedFlag: "no value",
        serviceBasedFlagtagged: "no value",
      });
    } else {
      this.setState({
        checkSearchtextFieldInvestigation: false,
        checkDateField: false,
        serviceBasedFlag: "no value",
      });
    }
  }

  //---------------------------------------------

  changeBFCurrentPage(currentPage) {
    this.setState({ tableCurrentPageBreakfix: currentPage });
  }

  changeBFPageSize(tableSize) {
    this.setState({ tableSizeBreakfix: tableSize });
  }

  changeFulfilmentCurrentPage(currentPage) {
    this.setState({ tableCurrentPageFullfilment: currentPage });
  }

  changeFulfilmentPageSize(tableSize) {
    this.setState({ tableSizeFullfilment: tableSize });
  }

  changeInvestigationCurrentPage(currentPage) {
    this.setState({ tableCurrentPageInvest: currentPage });
  }

  changeInvestigationPageSize(tableSize) {
    this.setState({ tableSizeInvest: tableSize });
  }

  changeChangeMgmtCurrentPage(currentPage) {
    this.setState({ tableCurrentPageChangeMgmt: currentPage });
  }

  changeChangeMgmtPageSize(tableSize) {
    this.setState({ tableSizeChangeMgmt: tableSize });
  }

  changeSkiprows(skiprows) {
    this.setState({ taskSkiprows: skiprows });
  }

  onSortInvAsc() {
    let params = this.buildSearchParamsForInvestigationQV();
    let searchParams = Object.assign({});

    this.setState({
      faCustomInvDesc: "fa fa-caret-down",
      faCustomInvAsc: "fa fa-caret-up fa_custom",
    });

    if (this.state.faCustomInvAsc != "fa fa-caret-up fa_custom") {
      searchParams.sortBy = "createdOn";
      searchParams.orderBy = "asc";
      searchParams.searchByList =
        Object.keys(params).length && params.searchByList != ""
          ? params.searchByList
          : "status";
      if (this.state.checkDateField)
        searchParams.searchByList = "createdOn," + searchParams.searchByList;
      searchParams.multipleValueList =
        Object.keys(params).length && params.multipleValueList != ""
          ? params.multipleValueList
          : "Under Review,Under Investigation,Root Cause Identified,Under Corrective Action,Corrective Action Not required,Corrected";
      if (this.state.checkDateField) {
        if (this.state.fromDate != "" && this.state.toDate == "") {
          searchParams.multipleValueList =
            moment(this.state.fromDate).format("YYYY-MM-DD") +
            "|" +
            searchParams.multipleValueList;
        } else if (this.state.fromDate == "" && this.state.toDate != "") {
          searchParams.multipleValueList =
            moment(this.state.toDate).format("YYYY-MM-DD") +
            "|" +
            searchParams.multipleValueList;
        } else if (this.state.fromDate != "" && this.state.toDate != "") {
          searchParams.multipleValueList =
            moment(this.state.fromDate).format("YYYY-MM-DD") +
            "&" +
            moment(this.state.toDate).format("YYYY-MM-DD") +
            "|" +
            searchParams.multipleValueList;
        }
      }
      if (this.state.checkDateField) {
        if (this.state.fromDate != "" && this.state.toDate == "")
          searchParams.patternList = "greaterthanequal";
        else if (this.state.fromDate == "" && this.state.toDate != "")
          searchParams.patternList = "lessthanequal";
        else if (this.state.fromDate != "" && this.state.toDate != "")
          searchParams.patternList = "between,in";
      }
      searchParams.currentPage = this.state.tableCurrentPageInvest;
      searchParams.size = this.state.tableSizeInvest;

      this.props.loadInvestigationListSort(searchParams);
      this.props.resetBFQVFilters();
    }

    // let searchParams = Object.assign({});
    // this.setState({
    //   faCustomInvDesc:"fa fa-angle-down",
    //   faCustomInvAsc:"fa fa-angle-up fa_custom"
    // })
    // if(this.state.faCustomInvAsc!="fa fa-angle-up fa_custom"){
    //   searchParams.searchByList = 'status';
    //   searchParams.multipleValueList = 'Under Review,Under Investigation,Root Cause Identified,Under Corrective Action,Corrective Action Not required,Corrected';
    //   searchParams.sortBy = 'createdOn';
    //   searchParams.orderBy = 'asc';
    //   this.props.loadInvestigationListSort(searchParams);
    //   this.props.resetBFQVFilters();
    // }
  }

  onSortInvDesc() {
    let params = this.buildSearchParamsForInvestigationQV();
    let searchParams = Object.assign({});

    this.setState({
      faCustomInvDesc: "fa fa-caret-down fa_custom",
      faCustomInvAsc: "fa fa-caret-up",
    });

    if (this.state.faCustomInvDesc != "fa fa-caret-down fa_custom") {
      searchParams.sortBy = "createdOn";
      searchParams.orderBy = "desc";
      searchParams.searchByList =
        Object.keys(params).length && params.searchByList != ""
          ? params.searchByList
          : "status";
      if (this.state.checkDateField)
        searchParams.searchByList = "createdOn," + searchParams.searchByList;
      searchParams.multipleValueList =
        Object.keys(params).length && params.multipleValueList != ""
          ? params.multipleValueList
          : "Under Review,Under Investigation,Root Cause Identified,Under Corrective Action,Corrective Action Not required,Corrected";
      if (this.state.checkDateField) {
        if (this.state.fromDate != "" && this.state.toDate == "") {
          searchParams.multipleValueList =
            moment(this.state.fromDate).format("YYYY-MM-DD") +
            "|" +
            searchParams.multipleValueList;
        } else if (this.state.fromDate == "" && this.state.toDate != "") {
          searchParams.multipleValueList =
            moment(this.state.toDate).format("YYYY-MM-DD") +
            "|" +
            searchParams.multipleValueList;
        } else if (this.state.fromDate != "" && this.state.toDate != "") {
          searchParams.multipleValueList =
            moment(this.state.fromDate).format("YYYY-MM-DD") +
            "&" +
            moment(this.state.toDate).format("YYYY-MM-DD") +
            "|" +
            searchParams.multipleValueList;
        }
      }
      if (this.state.checkDateField) {
        if (this.state.fromDate != "" && this.state.toDate == "")
          searchParams.patternList = "greaterthanequal";
        else if (this.state.fromDate == "" && this.state.toDate != "")
          searchParams.patternList = "lessthanequal";
        else if (this.state.fromDate != "" && this.state.toDate != "")
          searchParams.patternList = "between,in";
      }
      searchParams.currentPage = this.state.tableCurrentPageInvest;
      searchParams.size = this.state.tableSizeInvest;

      this.props.loadInvestigationListSort(searchParams);
      this.props.resetBFQVFilters();
    }

    // this.setState({
    //   faCustomInvDesc:"fa fa-angle-down fa_custom",
    //   faCustomInvAsc:"fa fa-angle-up"
    // })
    // let searchParams = Object.assign({});
    // if(this.state.faCustomInvDesc!="fa fa-angle-down fa_custom"){
    //   searchParams.searchByList = 'status';
    //   searchParams.multipleValueList = 'Under Review,Under Investigation,Root Cause Identified,Under Corrective Action,Corrective Action Not required,Corrected';
    //   searchParams.sortBy = 'createdOn';
    //   searchParams.orderBy = 'desc';
    //   this.props.loadInvestigationListSort(searchParams);
    //   this.props.resetBFQVFilters();
    // }
  }
  //==========
  //=========
  onSortChgAsc() {
    let params = this.buildSearchParamsForChangeQV();
    let searchParams = Object.assign({});

    this.setState({
      faCustomChgDesc: "fa fa-caret-down",
      faCustomChgAsc: "fa fa-caret-up fa_custom",
    });
    if (this.state.faCustomChgAsc != "fa fa-caret-up fa_custom") {
      searchParams.sortBy = "createdOn";
      searchParams.orderBy = "asc";
      searchParams.searchByList =
        Object.keys(params).length && params.searchByList != ""
          ? params.searchByList
          : "status";
      searchParams.multipleValueList =
        Object.keys(params).length && params.multipleValueList != ""
          ? params.multipleValueList
          : "30,35,40,45,55,65,95";
      searchParams.currentPage = this.state.tableCurrentPageChangeMgmt;
      searchParams.size = this.state.tableSizeChangeMgmt;

      this.props.loadChangeManagementmListSort(searchParams);
      this.props.resetBFQVFilters();

      // let searchParams = Object.assign({});
      // this.setState({
      //   faCustomChgDesc:"fa fa-angle-down",
      //   faCustomChgAsc:"fa fa-angle-up fa_custom"
      // })
      // if(this.state.faCustomChgAsc!="fa fa-angle-up fa_custom"){
      //   searchParams.searchByList = 'status';
      //   searchParams.multipleValueList = 'Draft,Under Review,Under Approval,Scheduled,Under Implementation,Under PIR';
      //   searchParams.sortBy = 'createdOn';
      //   searchParams.orderBy = 'asc';
      //   this.props.loadChangeManagementmListSort(searchParams);
      //   this.props.resetBFQVFilters();
    }
  }

  onSortChgDesc() {
    let params = this.buildSearchParamsForChangeQV();
    let searchParams = Object.assign({});

    this.setState({
      faCustomChgDesc: "fa fa-caret-down fa_custom",
      faCustomChgAsc: "fa fa-caret-up",
    });

    if (this.state.faCustomChgDesc != "fa fa-caret-down fa_custom") {
      searchParams.sortBy = "createdOn";
      searchParams.orderBy = "desc";
      searchParams.searchByList =
        Object.keys(params).length && params.searchByList != ""
          ? params.searchByList
          : "status";
      searchParams.multipleValueList =
        Object.keys(params).length && params.multipleValueList != ""
          ? params.multipleValueList
          : "30,35,40,45,55,65,95";
      searchParams.currentPage = this.state.tableCurrentPageChangeMgmt;
      searchParams.size = this.state.tableSizeChangeMgmt;

      this.props.loadChangeManagementmListSort(searchParams);
      this.props.resetBFQVFilters();

      // this.setState({
      //   faCustomChgDesc:"fa fa-angle-down fa_custom",
      //   faCustomChgAsc:"fa fa-angle-up"
      // })
      // let searchParams = Object.assign({});
      // if(this.state.faCustomChgDesc!="fa fa-angle-down fa_custom"){
      //   searchParams.searchByList = 'status';
      //   searchParams.multipleValueList = 'Draft,Under Review,Under Approval,Scheduled,Under Implementation,Under PIR';
      //   searchParams.sortBy = 'createdOn';
      //   searchParams.orderBy = 'desc';
      //   this.props.loadChangeManagementmListSort(searchParams);
      //   this.props.resetBFQVFilters();
    }
  }
  //=========
  onSortAsc() {
    let searchParams = Object.assign({});
    let params = this.buildSearchParamsForBreakfixQV();

    this.setState({
      faCustomDesc: "fa fa-caret-down",
      faCustomAsc: "fa fa-caret-up fa_custom",
    });
    if (this.state.faCustomAsc != "fa fa-caret-up fa_custom") {
      // searchParams.searchByList = 'status';
      // searchParams.multipleValueList = 'Submitted,Pending,In Progress';
      searchParams.sortBy = "reportedOn";
      searchParams.orderBy = "asc";
      searchParams.searchByList =
        Object.keys(params).length && params.searchByList != ""
          ? params.searchByList
          : "status";
      if (this.state.checkDateField)
        searchParams.searchByList = "createdOn," + searchParams.searchByList;
      searchParams.multipleValueList =
        Object.keys(params).length && params.multipleValueList != ""
          ? params.multipleValueList
          : "10,20,15";
      if (params.searchByList === "spUserid")
        searchParams.multipleValueList = "";
      if (this.state.checkDateField) {
        if (this.state.fromDate != "" && this.state.toDate == "") {
          searchParams.multipleValueList =
            moment(this.state.fromDate).format("YYYY-MM-DD") +
            "|" +
            searchParams.multipleValueList;
        } else if (this.state.fromDate == "" && this.state.toDate != "") {
          searchParams.multipleValueList =
            moment(this.state.toDate).format("YYYY-MM-DD") +
            "|" +
            searchParams.multipleValueList;
        } else if (this.state.fromDate != "" && this.state.toDate != "") {
          searchParams.multipleValueList =
            moment(this.state.fromDate).format("YYYY-MM-DD") +
            "&" +
            moment(this.state.toDate).format("YYYY-MM-DD") +
            "|" +
            searchParams.multipleValueList;
        }
      }
      if (this.state.checkDateField) {
        if (this.state.fromDate != "" && this.state.toDate == "")
          searchParams.patternList = "greaterthanequal";
        else if (this.state.fromDate == "" && this.state.toDate != "")
          searchParams.patternList = "lessthanequal";
        else if (this.state.fromDate != "" && this.state.toDate != "")
          searchParams.patternList = "between,in";
      }
      searchParams.currentPage = this.state.tableCurrentPageBreakfix;
      searchParams.size = this.state.tableSizeBreakfix;
      this.props.loadBreakFixQuickViewSort(searchParams);
      //this.props.resetBFQVFilters();
    }
  }

  onSortDesc() {
    let searchParams = Object.assign({});
    let params = this.buildSearchParamsForBreakfixQV();

    this.setState({
      faCustomDesc: "fa fa-caret-down fa_custom",
      faCustomAsc: "fa fa-caret-up",
    });
    if (this.state.faCustomDesc != "fa fa-caret-down fa_custom") {
      // searchParams.searchByList = 'status';
      // searchParams.multipleValueList = 'Submitted,Pending,In Progress';
      searchParams.sortBy = "reportedOn";
      searchParams.orderBy = "desc";
      searchParams.searchByList =
        Object.keys(params).length && params.searchByList != ""
          ? params.searchByList
          : "status";
      if (this.state.checkDateField)
        searchParams.searchByList = "createdOn," + searchParams.searchByList;
      searchParams.multipleValueList =
        Object.keys(params).length && params.multipleValueList != ""
          ? params.multipleValueList
          : "10,20,15";
      if (params.searchByList === "spUserid")
        searchParams.multipleValueList = "";
      if (this.state.checkDateField) {
        if (this.state.fromDate != "" && this.state.toDate == "") {
          searchParams.multipleValueList =
            moment(this.state.fromDate).format("YYYY-MM-DD") +
            "|" +
            searchParams.multipleValueList;
        } else if (this.state.fromDate == "" && this.state.toDate != "") {
          searchParams.multipleValueList =
            moment(this.state.toDate).format("YYYY-MM-DD") +
            "|" +
            searchParams.multipleValueList;
        } else if (this.state.fromDate != "" && this.state.toDate != "") {
          searchParams.multipleValueList =
            moment(this.state.fromDate).format("YYYY-MM-DD") +
            "&" +
            moment(this.state.toDate).format("YYYY-MM-DD") +
            "|" +
            searchParams.multipleValueList;
        }
      }
      if (this.state.checkDateField) {
        if (this.state.fromDate != "" && this.state.toDate == "")
          searchParams.patternList = "greaterthanequal";
        else if (this.state.fromDate == "" && this.state.toDate != "")
          searchParams.patternList = "lessthanequal";
        else if (this.state.fromDate != "" && this.state.toDate != "")
          searchParams.patternList = "between,in";
      }
      searchParams.currentPage = this.state.tableCurrentPageBreakfix;
      searchParams.size = this.state.tableSizeBreakfix;
      this.props.loadBreakFixQuickViewSort(searchParams);
      //this.props.resetBFQVFilters();
      //this.props.loadOrdersList("requesterId","type",user_id,"createdOn","desc");
    }
  }
  //=============
  onSortFulAsc() {
    let params = this.buildSearchParamsForFulfillmentQV();
    let searchParams = Object.assign({});

    let searchInputVar = "";
    if (this.state.checkSearchtextFieldFulfilment) {
      searchInputVar = this.state.valueStatusSearchInputFulfilment;
    } else if (this.state.checkDateField) {
      if (this.state.fromDate != "" && this.state.toDate == "") {
        searchInputVar = moment(this.state.fromDate).format("YYYY-MM-DD");
      } else if (this.state.fromDate == "" && this.state.toDate != "") {
        searchInputVar = moment(this.state.toDate).format("YYYY-MM-DD");
      } else if (this.state.fromDate != "" && this.state.toDate != "") {
        searchInputVar =
          moment(this.state.fromDate).format("YYYY-MM-DD") +
          "&" +
          moment(this.state.toDate).format("YYYY-MM-DD");
      }
    } else {
      searchInputVar = this.state.valueSearchInputFulfilment;
    }
    this.setState({
      faCustomFulDesc: "fa fa-caret-down",
      faCustomFulAsc: "fa fa-caret-up fa_custom",
    });

    if (this.state.faCustomFulAsc != "fa fa-caret-up fa_custom") {
      // searchParams.searchByList = 'itemStatus';
      // searchParams.multipleValueList = 'Pending Approval,Under Fulfillment,On Hold';
      searchParams.searchByList =
        Object.keys(params).length && params.searchByList != ""
          ? params.searchByList
          : "itemStatus";
      if (this.state.checkDateField)
        searchParams.searchByList = "createdOn," + searchParams.searchByList;
      else if (this.state.searchFilterFulfilment != "displayNumber")
        searchParams.searchByList =
          this.state.searchFilterFulfilment + "," + searchParams.searchByList;
      else if (
        this.state.searchFilterFulfilment == "displayNumber" &&
        this.state.valueSearchInputFulfilment != ""
      ){
        searchParams.searchByList =
          this.state.searchFilterFulfilment + "," + searchParams.searchByList;
      }
      searchParams.multipleValueList =
        Object.keys(params).length && params.multipleValueList != ""
          ? params.multipleValueList
          : "60,70,100";
      if (
        params.multipleValueList == "" &&
        searchParams.multipleValueList == "60,70,100"
      )
        searchParams.multipleValueList = "";
      if (searchParams.searchByList == "asgUserid")
        searchParams.multipleValueList = searchInputVar + "|";
      else if (
        params.multipleValueList == "" &&
        searchParams.multipleValueList == "60,70,100" &&
        !this.state.checkDateField
      ){
        searchParams.multipleValueList = searchInputVar;
      }
      else if (!this.state.checkDateField){
        searchParams.multipleValueList =
          searchInputVar + "|" + searchParams.multipleValueList;
      }
      if (this.state.checkDateField) {
        if (this.state.fromDate != "" && this.state.toDate == "") {
          searchParams.multipleValueList =
            moment(this.state.fromDate).format("YYYY-MM-DD") +
            "|" +
            searchParams.multipleValueList;
        } else if (this.state.fromDate == "" && this.state.toDate != "") {
          searchParams.multipleValueList =
            moment(this.state.toDate).format("YYYY-MM-DD") +
            "|" +
            searchParams.multipleValueList;
        } else if (this.state.fromDate != "" && this.state.toDate != "") {
          searchParams.multipleValueList =
            moment(this.state.fromDate).format("YYYY-MM-DD") +
            "&" +
            moment(this.state.toDate).format("YYYY-MM-DD") +
            "|" +
            searchParams.multipleValueList;
        }
      }
      if (this.state.checkDateField) {
        if (this.state.fromDate != "" && this.state.toDate == "")
          searchParams.patternList = "greaterthanequal";
        else if (this.state.fromDate == "" && this.state.toDate != "")
          searchParams.patternList = "lessthanequal";
        else if (this.state.fromDate != "" && this.state.toDate != "")
          searchParams.patternList = "between,in";
      } else searchParams.patternList = "like";
      searchParams.currentPage = this.state.tableCurrentPageFullfilment;
      searchParams.size = this.state.tableSizeFullfilment;
      searchParams.sortBy = "itemId";
      searchParams.orderBy = "asc";
      this.props.loadFulfillmentListSort(searchParams);
      //this.props.resetBFQVFilters();
    }
  }

  onSortFulDesc() {
    let params = this.buildSearchParamsForFulfillmentQV();
    let searchParams = Object.assign({});

    let searchInputVar = "";
    if (this.state.checkSearchtextFieldFulfilment) {
      searchInputVar = this.state.valueStatusSearchInputFulfilment;
    } else if (this.state.checkDateField) {
      if (this.state.fromDate != "" && this.state.toDate == "") {
        searchInputVar = moment(this.state.fromDate).format("YYYY-MM-DD");
      } else if (this.state.fromDate == "" && this.state.toDate != "") {
        searchInputVar = moment(this.state.toDate).format("YYYY-MM-DD");
      } else if (this.state.fromDate != "" && this.state.toDate != "") {
        searchInputVar =
          moment(this.state.fromDate).format("YYYY-MM-DD") +
          "&" +
          moment(this.state.toDate).format("YYYY-MM-DD");
      }
    } else {
      searchInputVar = this.state.valueSearchInputFulfilment;
    }
    this.setState({
      faCustomFulDesc: "fa fa-caret-down fa_custom",
      faCustomFulAsc: "fa fa-caret-up",
    });
    if (this.state.faCustomFulDesc != "fa fa-caret-down fa_custom") {
      // searchParams.searchByList = 'itemStatus';
      // searchParams.multipleValueList = 'Pending Approval,Under Fulfillment,On Hold';
      searchParams.sortBy = "itemId";
      searchParams.orderBy = "desc";
      searchParams.searchByList =
        Object.keys(params).length && params.searchByList != ""
          ? params.searchByList
          : "itemStatus";
      if (this.state.checkDateField)
        searchParams.searchByList = "createdOn," + searchParams.searchByList;
      else if (this.state.searchFilterFulfilment != "displayNumber")
        searchParams.searchByList =
          this.state.searchFilterFulfilment + "," + searchParams.searchByList;
      else if (
        this.state.searchFilterFulfilment == "displayNumber" &&
        this.state.valueSearchInputFulfilment != ""
      ){
        searchParams.searchByList =
          this.state.searchFilterFulfilment + "," + searchParams.searchByList;
      }

      searchParams.multipleValueList =
        Object.keys(params).length && params.multipleValueList != ""
          ? params.multipleValueList
          : "60,70,100";
      if (
        params.multipleValueList == "" &&
        searchParams.multipleValueList == "60,70,100"
      )
        searchParams.multipleValueList = "";
      if (searchParams.searchByList == "asgUserid")
        searchParams.multipleValueList = searchInputVar + "|";
      else if (
        params.multipleValueList == "" &&
        searchParams.multipleValueList == "60,70,100" &&
        !this.state.checkDateField
      ){
        searchParams.multipleValueList = searchInputVar;
      }
      else if (!this.state.checkDateField){
        searchParams.multipleValueList =
          searchInputVar + "|" + searchParams.multipleValueList;}
      if (this.state.checkDateField) {
        if (this.state.fromDate != "" && this.state.toDate == "") {
          searchParams.multipleValueList =
            moment(this.state.fromDate).format("YYYY-MM-DD") +
            "|" +
            searchParams.multipleValueList;
        } else if (this.state.fromDate == "" && this.state.toDate != "") {
          searchParams.multipleValueList =
            moment(this.state.toDate).format("YYYY-MM-DD") +
            "|" +
            searchParams.multipleValueList;
        } else if (this.state.fromDate != "" && this.state.toDate != "") {
          searchParams.multipleValueList =
            moment(this.state.fromDate).format("YYYY-MM-DD") +
            "&" +
            moment(this.state.toDate).format("YYYY-MM-DD") +
            "|" +
            searchParams.multipleValueList;
        }
      }
      if (this.state.checkDateField) {
        if (this.state.fromDate != "" && this.state.toDate == "")
          searchParams.patternList = "greaterthanequal";
        else if (this.state.fromDate == "" && this.state.toDate != "")
          searchParams.patternList = "lessthanequal";
        else if (this.state.fromDate != "" && this.state.toDate != "")
          searchParams.patternList = "between,in";
      } else searchParams.patternList = "like";
      searchParams.currentPage = this.state.tableCurrentPageFullfilment;
      searchParams.size = this.state.tableSizeFullfilment;
      this.props.loadFulfillmentListSort(searchParams);
      //this.props.resetBFQVFilters();
    }
  }
  //==============
  onSortTaskAsc() {
    let taskTableSize = this.state.taskTableSize;
    let taskSkiprows = this.state.taskSkiprows;
    this.setState({
      faCustomTaskDesc: "fa fa-caret-down",
      faCustomTaskAsc: "fa fa-caret-up fa_custom",
    });
    if (this.state.faCustomTaskAsc != "fa fa-caret-up fa_custom") {
      let searchParams = Object.assign({}, {});
      searchParams.taskStatuses = "1,2,6,7";
      searchParams.taskTypes = "";
      this.props.loadTaskListAsc("", searchParams, taskTableSize, taskSkiprows);
      this.props.resetBFQVFilters();
    }
  }

  onSortTaskDesc() {
    let taskTableSize = this.state.taskTableSize;
    let taskSkiprows = this.state.taskSkiprows;

    this.setState({
      faCustomTaskDesc: "fa fa-caret-down fa_custom",
      faCustomTaskAsc: "fa fa-caret-up",
    });
    if (this.state.faCustomTaskDesc != "fa fa-caret-down fa_custom") {
      let searchParams = {};
      searchParams.taskStatuses = "1,2,6,7";
      searchParams.taskTypes = "";
      this.props.loadTaskList("", searchParams, taskTableSize, taskSkiprows);
      this.props.resetBFQVFilters();
    }
  }
  roleIdentification(Rolevalue) {
    let flag = false;
    let roles = this.state.role_id.split(",");
    for (let i = 0; i < roles.length; i++) {
      if (roles[i] == Rolevalue) {
        flag = true;
        break;
      }
    }
    if (flag) return true;
    else return false;
  }
  gettingRoleId(Rolevalue) {
    let flag = false;
    let roles = this.state.role_id.split(",");
    if (roles.length == 1) {
      if (roles[0] == Rolevalue) {
        return false;
      } else {
        return true;
      }
    } else {
      for (let i = 0; i < roles.length; i++) {
        if (roles[i] == "16" || roles[i] == "17" || roles[i] == "33") {
          flag = true;
        }
      }
      if (flag == true) {
        return true;
      } else {
        return false;
      }
    }
  }
  //==============
  handleSelect(selectedIndex, type = "individual", changeManagementUser) {
    // console.log('selectedIndex, type - ', selectedIndex, type);
    this.showConditionBuilder(false);
    this.setState({
      isMultiOpen: false,
      isStatusOpen: false,
      isWorkItemOpen: false,
      isAssignedToGroupOpen: false,
      isTaskStatusAllLabel: false,
      isTaskWorkItemAllLabel: false,
      isTaskAssignedToGroupAllLabel: false,
    });

    this.setState({
      isRightSideVisible: false,
      selectedModuleName: selectedIndex,
    });
    if (selectedIndex == "breakfix") {
      this.setState({ tableCurrentPageBreakfix: 0, tableSizeBreakfix: 10 });
      this.props.fetchGroupList();
      this.props.fetchPriorityList("breakfix");
      this.setSelectedSearchFilterBreakFix("displayNumber");
      this.changeSearchInputBreakFix("");
      this.isSearchBreakFix(false);
      this.props.setBreakfixQuickViewTableCarouselIndex("0");
      let searchParams = Object.assign({});
      searchParams.searchByList = "";
      searchParams.multipleValueList = "";
      searchParams.patternList = "";
      searchParams.sortBy = "createdOn";
      searchParams.orderBy = "desc";
      searchParams.size = 10;
      searchParams.currentPage = 0;

      if (!GLOBAL.isWIBGlobalSearchEnabled) {
        this.setGlobalSearchInformation("", "", "", "");
        this.setOnBreakfixFilterSelection(false);
        this.setDefaultBreakfixStatusSelected(false);
      }
      if (this.props.allCIMgroups.length === 0) {
        this.props.loadallCIMgroups(loggedinUserCompanyID, "5", "15");
        this.props.loadallCIMgroupsRules(loggedinUserCompanyID, "incident", "criticalIncidentManager");
      }
      if(this.inputRef !== undefined) { this.inputRef.endDate = moment()}
      if(this.inputRef !== undefined)
        {this.inputRef.startDate = moment().subtract(
            defaultDateRangeDays,
            "days"
          )}
        

      // this.props.loadBreakFixQuickView(searchParams, [10, 20, 15], [], []);
      if (
        this.props.wibSearchParams.searchParams &&
        Object.keys(this.props.wibSearchParams.searchParams).length > 0 &&
        this.props.isSearchParamsApply &&
        typeof this.props.wibGlobalSearchFilterData.selectedGlobalModule ===
          "undefined"
      ) {
        let params = this.props.wibSearchParams.searchParams.searchParams;
        this.setBrkResetSelected(false);
        this.props.loadBreakFixQuickView(
          params.searchParams,
          [],
          [],
          [],
          [],
          [],
          [],
          params.isWIBGlobalSearchEnabled,
          true
        );
      } else {
        this.props.resetBFQVFilters();
        this.props.ClearFiltersState();
        this.setBrkdisplayNumberInputValues("", "");
        this.setBrkCiServiceNameInputValues("", "");
        this.setBrkCompanyInputValues("");
        this.setBrkSummaryInputValues("");
        this.setBrkRequestedForInputValues("");
        this.setBrkSupportIndividualInputValues("");
        this.setBrkCIMindividualInputValues("");
        this.setBrkSearchKeyValue("", "", "");
        this.setBrkStartAndEndDate({}, {});
        this.updateParameterBrk([10, 20, 15], [], [], [], []);
        this.setBrkSelectedStatusValue([]);
        this.setBrkSelectedPriorityValue([]);
        this.setBrkSelectedSupportGroupValue([]);
        this.setBrkSelectedCriticalityValue([]);
        this.setBrkSElectedCIMGroupsValue([]);
        if (type === "individual") {
          // searchParams.patternList = 'equals';
          this.props.loadBreakFixQuickView(
            searchParams,
            [10, 20, 15],
            "",
            "",
            [this.state.user_id],
            "",
            ""
          );
        } else if (type === "group" && !GLOBAL.isWIBGlobalSearchEnabled) {
          // searchParams.patternList = 'in,in';
          const { group_id } = this.state;
          let groups =
            !group_id || group_id == -1 || group_id == "0"
              ? ["-1"]
              : group_id && group_id.length > 0
              ? group_id.split(",")
              : [];
          this.props.loadBreakFixQuickView(
            searchParams,
            [10, 20, 15],
            "",
            groups,
            "",
            "",
            ""
          );
        } else if (type === "all" && !GLOBAL.isWIBGlobalSearchEnabled) {
          // searchParams.patternList = 'in,in';
          this.props.loadBreakFixQuickView(
            searchParams,
            [10, 20, 15],
            [],
            [],
            [],
            [],
            []
          );
        }

        this.setGraphSearchParams(searchParams, "BreakFix");
        this.showRightSide(false);
        this.setState({
          breakDateRangeLabel: `Last ${defaultDateRangeValue || 30} days`,
        });
        this.isBrkClearAllFilterFunc(false);
        this.onFromDateSelect("");
        this.onToDateSelect("");
      }
    } else if (selectedIndex == "fullfilment") {
      if (
        this.props.wibSearchParams.searchParams &&
        Object.keys(this.props.wibSearchParams.searchParams).length > 0 &&
        this.props.isSearchParamsApply &&
        typeof this.props.wibGlobalSearchFilterData.selectedGlobalModule ===
          "undefined"
      ) {
        let params = this.props.wibSearchParams.searchParams.searchParams;
        this.props.loadFulfillmentList(params.searchParams);
      } else {
        this.setState({
          tableSizeFullfilment: 10,
          tableCurrentPageFullfilment: 0,
          moduleName: "fullfilment",
        });
        this.props.ClearFiltersState();
        this.props.resetBFQVFilters();
        this.props.fetchFulfillmentGroupList();
        this.setSelectedSearchFilterFulfilment("displayNumber");
        this.changeSearchInputFulfilment("");
        this.isSearchFulfilment(false);
        this.setFuldisplayNumberInputValues("", "");
        this.setFulCiServiceNameInputValues("");
        this.setFulRequesterInputValues("");
        this.setFulfillerInputValues("");
        this.setFulCompanyNameInputValues("");
        this.setFulSummaryInputValues('');

        this.setFulSearchKeyValue("", "", "");
        this.setFulReportedStartAndEndDate({}, {});

        this.updateFulStatus([60, 70, 100]);
        this.updateGroup([]);
        this.updateUserGroup([]);
        this.updateFulGroup([]);
        this.setFulSelectedStatusValue([]);
        this.setFulSelectedGroupValue([]);
        if (!GLOBAL.isWIBGlobalSearchEnabled) {
          this.setGlobalSearchInformation("", "", "", "");
          this.setOnFulfillmentFilterSelection(false);
          this.setDefaultFulfillmentStatusSelected(false);
        }
        if(this.state.fulfillmentReportedDateRef !== undefined)
          {this.state.fulfillmentReportedDateRef.endDate = moment()}
        
        if(this.state.fulfillmentReportedDateRef !== undefined)
          { this.state.fulfillmentReportedDateRef.startDate =
              moment().subtract(defaultDateRangeDays, "days")}
         
        let searchParams = Object.assign({});
        searchParams.searchByList = "";
        // searchParams.multipleValueList = 'Under Fulfillment,In Progress,Referred Back';
        searchParams.multipleValueList = "";
        searchParams.patternList = "";
        searchParams.size = 10;
        searchParams.currentPage = 0;
        searchParams.sortBy = "itemId";
        searchParams.orderBy = "desc";
        if (!GLOBAL.isWIBGlobalSearchEnabled) {
          this.props.loadFulfillmentList(searchParams, [60, 70, 100], [], []);
        }
        this.setGraphSearchParams(searchParams, "Fullfilment");
        this.props.setBreakfixQuickViewTableCarouselIndex("1");
        this.showRightSide(false);
        this.setState({
          fulfillDateRangeLabel: `Last ${defaultDateRangeValue || 30} days`,
        });
        this.isClearAllFilterFuncFulfillment(false);
        this.onFromDateSelect("");
        this.onToDateSelect("");
        this.state.checkDateField = false;
      }
    } else if (selectedIndex == "investigation") {
      if (
        this.props.wibSearchParams.searchParams &&
        Object.keys(this.props.wibSearchParams.searchParams).length > 0 &&
        this.props.isSearchParamsApply &&
        typeof this.props.wibGlobalSearchFilterData.selectedGlobalModule ===
          "undefined"
      ) {
        let params = this.props.wibSearchParams.searchParams.searchParams;
        this.props.loadInvestigationList(params.searchParams, [], "", "", "");
      } else {
        this.setState({
          tableSizeInvest: 10,
          tableCurrentPageInvest: 0,
        });
        this.props.ClearFiltersState();
        this.props.resetBFQVFilters();
        this.props.fetchInvestigationGroupList();
        this.props.fetchPriorityList("investigation");
        this.setSelectedSearchFilterInvestigation("displayNumber");
        this.changeSearchInputInvestigation("");
        this.isSearchInvestigation(false);
        this.setInvdisplayNumberInputValues("", "");
        this.setInvCiServiceNameInputValues("");
        this.setInvCompanyInputValues("");
        this.setInvSummaryInputValues("");
        this.setInvRequestedByInputValues("");
        this.setInvProblemManagerInputValues("");

        this.setInvSearchKeyValue("", "", "");
        this.setInvStartAndEndDate({}, {});
        this.updateParameterInv(
          ["30", "35", "40", "45", "50", "55", "60"],
          [],
          []
        );
        this.setInvSelectedStatusValue([]);
        this.setInvSelectedPriorityValue([]);
        this.setInvSelectedSupportGroupValue([]);
        if (!GLOBAL.isWIBGlobalSearchEnabled) {
          this.setGlobalSearchInformation("", "", "", "");
          this.setOnInvestigationFilterSelection(false);
          this.setDefaultInvestigationStatusSelected(false);
        }
        if(this.inputRef !== undefined) {this.inputRef.endDate = moment()}
        if(this.inputRef !== undefined)
          {this.inputRef.startDate = moment().subtract(
              defaultDateRangeDays,
              "days"
            )}

        let searchParams = Object.assign({});
        searchParams.searchByList = "";
        searchParams.multipleValueList = "";
        searchParams.size = 10;
        searchParams.currentPage = 0;
        searchParams.sortBy = "createdOn";
        searchParams.orderBy = "desc";
        // this.props.loadInvestigationList(searchParams, ["Under Review", "Under Investigation", "Root Cause Identified", "Under Corrective Action", "Corrective Action Not required", "Corrected"], [], []);

        let statusArr = ["30", "35", "40", "45", "50", "55", "60"];
        if (type === "individual" && !GLOBAL.isWIBGlobalSearchEnabled) {
          let userId = "";
          if (!this.isProblemInvestigator()) {
            userId = [this.state.user_id];
          }
          this.props.loadInvestigationList(
            searchParams,
            statusArr,
            "",
            "",
            userId
          );
        } else if (type === "group" && !GLOBAL.isWIBGlobalSearchEnabled) {
          const { group_id } = this.state;
          let groups =
            !group_id || group_id == -1 || group_id == "0"
              ? ["-1"]
              : group_id && group_id.length > 0
              ? group_id.split(",")
              : [];
          this.props.loadInvestigationList(
            searchParams,
            statusArr,
            "",
            groups,
            ""
          );
        } else if (type === "all" && !GLOBAL.isWIBGlobalSearchEnabled) {
          this.props.loadInvestigationList(searchParams, statusArr, [], [], []);
        }

        this.setGraphSearchParams(searchParams, "Investigation");
        this.showRightSide(false);
        this.setState({
          problemDateRangeLabel: `Last ${defaultDateRangeValue || 30} days`,
        });
        this.isInvClearAllFilterFunc(false);
        this.props.setBreakfixQuickViewTableCarouselIndex("2");
        this.onFromDateSelect("");
        this.onToDateSelect("");
        this.state.checkDateField = false;
      }
    } else if (selectedIndex == "changeManagement") {
      if (
        this.props.wibSearchParams.searchParams &&
        Object.keys(this.props.wibSearchParams.searchParams).length > 0 &&
        this.props.isSearchParamsApply &&
        typeof this.props.wibGlobalSearchFilterData.selectedGlobalModule ===
          "undefined"
      ) {
        let chParams = this.props.wibSearchParams.searchParams.searchParams;
        this.props.loadChangeManagementmList(
          chParams.searchParams,
          [],
          [],
          [],
          [],
          [],
          "",
          "",
          chParams.filterType,
          chParams.tab,
          chParams.groupFilterApplied,
          true
        );
      } else {
        this.setState({
          tableSizeChangeMgmt: 10,
          tableCurrentPageChangeMgmt: 0,
        });
        this.props.ClearFiltersState();
        this.props.resetBFQVFilters();
        this.props.fetchChangeGroupList();
        this.setSelectedSearchFilterChangeMgmt("displayNumber");
        this.changeSearchInputChangeMgmt("");
        this.isSearchChangeMgmt(false);
        this.setChangedisplayNumberInputValues("", "");
        this.setChangeCiServiceNameInputValues("");
        this.setChangeCompanyNameInputValues("");
        this.setChangeSummaryNameInputValues("");
        this.setChangeRequesterInputValues("");
        this.updateChangemangmtGroup([]);
        this.updateImplmtGroup([]);
        this.setChangMangmtGroupValue([]);
        this.setimpleGroupValue([]);
        this.setChangeSearchKeyValue("", "", "");
        this.setChangeReportedStartAndEndDate({}, {});
        this.setChangeExpectedStartAndEndDate({}, {});
        this.setChangeCABStartAndEndDate({}, {});
        this.updateParameterChange(["30,35,40,45,55,65,95"], [], []);
        this.updateChangeStatus(["30,35,40,45,55,65,95"]);
        this.updateChangeType([]);

        this.setChangeSelectedStatusValue([]);
        this.setSelectedChangeTypeValue([]);
        if (!GLOBAL.isWIBGlobalSearchEnabled) {
          this.setGlobalSearchInformation("", "", "", "");
          this.setOnChangeMgmtFilterSelection(false);
          this.setDefaultChangeMgmtStatusSelected(false);
        }

        if(this.state.changeReportedDateRef !== undefined)
           {this.state.changeReportedDateRef.endDate = moment()}
        
        if(this.state.changeReportedDateRef !== undefined)
           {this.state.changeReportedDateRef.startDate = moment().subtract(
              defaultDateRangeDays,
              "days"
            )}
        else if (this.state.changeExpectedDateRef !== undefined)
           {this.state.changeExpectedDateRef.endDate = moment()}

        if(this.state.changeExpectedDateRef !== undefined)
          { this.state.changeExpectedDateRef.startDate = moment()}

        if(this.state.changeCABDateRef !== undefined)
           {this.state.changeCABDateRef.endDate = moment()}
        
        if(this.state.changeCABDateRef !== undefined)
          {this.state.changeCABDateRef.startDate = moment()}
          

        let searchParams = Object.assign({});
        searchParams.searchByList = "";
        searchParams.multipleValueList = "";
        searchParams.patternList = "";
        searchParams.size = 10;
        searchParams.currentPage = 0;
        searchParams.sortBy = "createdOn";
        searchParams.orderBy = "desc";
        // this.props.loadBreakFixQuickView(searchParams, [10, 20, 15], [], []);
        const roles =
          this.state.changeImplentationRole === "impUserid,changeUserid"
            ? "both"
            : "individual";
        if (type === "individual") {
          // console.log('searchParams - ', searchParams);
          if (
            (this.state.role_id.split(",").includes("19") &&
              this.state.role_id.split(",").includes("51")) ||
            (this.state.role_id.split(",").includes("52") &&
              this.state.role_id.split(",").includes("20")) ||
            (this.state.role_id.split(",").includes("20") &&
              this.state.role_id.split(",").includes("51")) ||
            (this.state.role_id.split(",").includes("52") &&
              this.state.role_id.split(",").includes("19")) ||
            this.state.role_id.split(",").includes("48")
          ) {
            searchParams.operatorList = "or";
            this.updateParameterChange(["30,35,40,45,55,65,95"], [], []);
            this.props.loadChangeManagementmList(
              searchParams,
              ["30,35,40,45,55,65,95"],
              [this.state.user_id],
              [this.state.user_id],
              [],
              [],
              [],
              false,
              roles,
              "assigned_to_me"
            );
          } else if (changeManagementUser == "changeUserid") {
            // this.setChangeSearchKeyValue(changeManagementUser, "", "");
            this.updateParameterChange(["30,35,40,45,55,65,95"], [], []);
            this.props.loadChangeManagementmList(
              searchParams,
              ["30,35,40,45,55,65,95"],
              [this.state.user_id],
              [],
              [],
              [],
              [],
              false,
              roles,
              "assigned_to_me"
            );
          } else if (changeManagementUser == "impUserid") {
            this.updateParameterChange(["30,35,40,45,55,65,95"], [], []);
            this.props.loadChangeManagementmList(
              searchParams,
              ["30,35,40,45,55,65,95"],
              [],
              [this.state.user_id],
              [],
              [],
              [],
              false,
              roles,
              "assigned_to_me"
            );
          }
        }
        if (type === "all" && !GLOBAL.isWIBGlobalSearchEnabled) {
          this.updateParameterChange(["30,35,40,45,55,65,95"], [], []);
          this.props.loadChangeManagementmList(
            searchParams,
            ["30,35,40,45,55,65,95"],
            [],
            [],
            [],
            [],
            [],
            false,
            roles,
            "All"
          );
        }
        if (type === "group" && !GLOBAL.isWIBGlobalSearchEnabled) {
          if (
            (this.state.role_id.split(",").includes("19") &&
              this.state.role_id.split(",").includes("51")) ||
            (this.state.role_id.split(",").includes("52") &&
              this.state.role_id.split(",").includes("20")) ||
            (this.state.role_id.split(",").includes("20") &&
              this.state.role_id.split(",").includes("51")) ||
            (this.state.role_id.split(",").includes("52") &&
              this.state.role_id.split(",").includes("19")) ||
            this.state.role_id.split(",").includes("48")
          ) {
            if(GLOBAL.changeMangemtGroup == 0 || GLOBAL.impleGroup == 0)
              { 
                (searchParams.operatorList = "or");
              }
            this.updateParameterChange(["30,35,40,45,55,65,95"], [], []);
            this.props.loadChangeManagementmList(
              searchParams,
              ["30,35,40,45,55,65,95"],
              [],
              [],
              [this.state.group_id],
              [this.state.group_id],
              [],
              false,
              roles,
              "assigned_to_group"
            );
          } else if (changeManagementUser == "changeUserid") {
            this.updateParameterChange(
              ["30,35,40,45,55,65,95"],
              [this.state.group_id],
              []
            );
            this.props.loadChangeManagementmList(
              searchParams,
              ["30,35,40,45,55,65,95"],
              [],
              [],
              [this.state.group_id],
              [],
              [],
              false,
              roles,
              "assigned_to_group"
            );
          } else if (changeManagementUser == "impUserid") {
            this.updateParameterChange(
              ["30,35,40,45,55,65,95"],
              [],
              [this.state.group_id]
            );
            this.props.loadChangeManagementmList(
              searchParams,
              ["30,35,40,45,55,65,95"],
              [],
              [],
              [],
              [this.state.group_id],
              [],
              false,
              roles,
              "assigned_to_group"
            );
          }
        }
        this.setGraphSearchParams(searchParams, "Change");
        this.showRightSide(false);
        this.setState({
          changeDateRangeLabel: `Last ${defaultDateRangeValue || 30} days`,
        });
        this.isClearAllFilterFuncChange(false);
        this.props.setBreakfixQuickViewTableCarouselIndex("3");
        this.onFromDateSelect("");
        this.onToDateSelect("");
      }
    } else if (selectedIndex == "task") {
      this.props.ClearFiltersState();
      this.setState({ taskTableSize: 10, taskSkiprows: 0 });
      this.props.resetBFQVFilters();
      if (type !== "all") {
        this.props.fetchTaskGroupList();
      }
      this.setChangeTaskNumberInputValues("");
      this.setChangeworkItemNumberInputValues("");
      this.setChangeTaskServiceNameInputValues("", "");
      this.setChangeTaskCompanyNameInputValues('');
      this.setChangeTaskSummaryInputValues("");
      this.setChangeTaskAssignedToInputValues("");

      this.setTaskStartAndEndDate({}, {});
      this.updateTaskGroup([]);
      this.updateTaskType([]);
      this.updateTaskStatus([]);

      this.setTaskSearchKeyValue({});
      this.setTaskSelectedGroupValue([]);
      this.setTaskSelectedTaskValue([]);
      this.setTaskSelectedStatusValue([]);
      if(this.state.taskDateRef !== undefined)
        {this.state.taskDateRef.endDate = moment()}
    
      if(this.state.taskDateRef !== undefined)
        {this.state.taskDateRef.startDate = moment().subtract(
            defaultDateRangeDays,
            "days"
          )}

        insideParams = {statusId: { inq: ['1', '2', '7'] } };
        if (type === "individual") {
        this.props.loadTaskList(
          "",
          insideParams,
          10,
          0,
          "individual",
          this.state.user_id
        );
      } else if (type === "group") {
        this.props.loadTaskList("", insideParams);
      } else if (type === "all") {
        this.props.loadTaskList(
          "",
          insideParams,
          10,
          0,
          "all",
          ""
        );
      }
      this.showRightSide(false);
      this.setState({
        taskDateRangeLabel: `Last ${defaultDateRangeValue || 30} days`,
      });
      this.isClearAllFilterFuncTask(false);
      Object.keys(insideParams).length = 0;
      this.props.setBreakfixQuickViewTableCarouselIndex("4");
      this.setSelectedSearchFilterTasks("displayNumber");
      this.setGraphSearchParams(insideParams, "Task");
      this.changeSearchInputTasks("");
      this.isSearchTasks(false);
      this.onFromDateSelect("");
      this.onToDateSelect("");
    } else if (selectedIndex == "appointment") {
      this.setState({ appointmentDateRangeLabel: `Custom Range` });
      this.props.setBreakfixQuickViewTableCarouselIndex("5");
      this.setAppointmentAction("appointmentTabClick");
      // this.setState({
      //   appointmentDateRangeLabel: `Last ${defaultDateRangeValue || 30} days`,
      // });
    } else if (selectedIndex == "action") {
      this.props.setBreakfixQuickViewTableCarouselIndex("6");
    }
    else if (selectedIndex == "walkup") {
      this.props.setBreakfixQuickViewTableCarouselIndex("7");
    }
    //this.props.setBreakfixQuickViewTableCarouselIndex(selectedIndex);
  }

  changeToGraphView(bool, moduleName) {
    switch (moduleName) {
      case "BreakFix":
        this.props.setBreakFixGraphView(bool);
        break;
      case "Task":
        this.props.setTaskGraphView(bool);
        break;
      case "Change":
        this.props.setChangeGraphView(bool);
        break;
      case "Investigation":
        this.props.setInvestigationGraphView(bool);
        break;
      case "Fullfilment":
        this.props.setFullfilmentGraphView(bool);
        break;
      default:
        break;
    }
  }

  setGraphSearchParams(params, moduleName) {
    switch (moduleName) {
      case "BreakFix":
        this.setState({ breakFixSearchParams: params });
        break;
      case "Task":
        this.setState({ taskSearchParams: params });
        break;
      case "Change":
        this.setState({ changeSearchParams: params });
        break;
      case "Investigation":
        this.setState({ investigationSearchParams: params });
        break;
      case "Fullfilment":
        this.setState({ fullfilmentSearchParams: params });
        break;
      default:
        break;
    }
  }

  setGraphDateRangeFilter(filter, moduleName) {
    switch (moduleName) {
      case "0":
        this.setState({ breakFixDateRangeFilter: filter });
        break;
      case "4":
        this.setState({ taskDateRangeFilter: filter });
        break;
      case "3":
        this.setState({ changeDateRangeFilter: filter });
        break;
      case "2":
        this.setState({ investigationeDateRangeFilter: filter });
        break;
      case "1":
        this.setState({ fullfilmentDateRangeFilter: filter });
        break;
    }
  }

  setOnClickGraphSideBarData(onClickData, moduleName) {
    switch (moduleName) {
      case "BreakFix":
        this.setState({ onClickBreakFixData: onClickData });
        break;
      case "Task":
        this.setState({ onClickTaskData: onClickData });
        break;
      case "Change":
        this.setState({ onClickChangeData: onClickData });
        break;
      case "Investigation":
        this.setState({ onClickInvestigationData: onClickData });
        break;
      case "Fullfilment":
        this.setState({ onClickFullfilmentData: onClickData });
        break;
    }
  }

  showHideRightNav(value) {
    this.setState({ showHideRightNav: !this.state.showHideRightNav });
  }
  mobileLeftMenuFilter() {
    this.setState({ mobileLeftMenuFilter: !this.state.mobileLeftMenuFilter });
  }

  getBreakFixDetails(
    breakfixId,
    breakfixNumber,
    description,
    additionalInfo,
    createdOn,
    ciServiceName,
    serviceBased,
    serviceCriticality,
    spGroupname,
    spUsername,
    requestNumber,
    consumerCompanyName,
    status,
    priorityId,
    impactedUsername,
    createdByName,
    reportedThrough,
    requesterLocationName,
    createdBy,
    requesterId,
    expectedResolutionTime
  ) {
    this.setState({
      breakfixId: breakfixId,
      breakfixNumber: breakfixNumber,
      breakFixDescription: description,
      breakFixadditionalInfo: additionalInfo,
      breakFixCreatedOn: createdOn,
      ciServiceName: ciServiceName,
      serviceBased: serviceBased,
      breakFixServiceCriticality: serviceCriticality,
      breakFixSpGroupname: spGroupname,
      breakFixSpUsername: spUsername,
      requestNumber: requestNumber,
      consumerCompanyName: consumerCompanyName,
      status: status,
      priorityId: priorityId,
      impactedUsername: impactedUsername,
      createdByName: createdByName,
      reportedThrough: reportedThrough,
      requesterLocationName: requesterLocationName,
      createdByBrk: createdBy,
      requesterId: requesterId,
      expectedResolutionTime: expectedResolutionTime
    });
  }

  getInvestigationDetails(
    investigationNumber,
    investigationId,
    problemStatements,
    problemSymptom,
    investigationReported,
    ciServiceName,
    serviceBased,
    investigationServiceCriticality,
    asgPmGroupName,
    asgPmName,
    consumerCompany,
    reportedThrough,
    status,
    priority,
    createdByName,
    requestNumber,
    createdBy,
    invrequesterId
  ) {
    this.setState({
      investigationNumber: investigationNumber,
      investigationId: investigationId,
      problemStatements: problemStatements,
      problemSymptom: problemSymptom,
      investigationReported: investigationReported,
      ciServiceName: ciServiceName,
      serviceBased: serviceBased,
      investigationServiceCriticality: investigationServiceCriticality,
      asgPmGroupName: asgPmGroupName,
      asgPmName: asgPmName,
      consumerCompanyInv: consumerCompany,
      sourceInv: reportedThrough,
      statusInv: status,
      priorityInv: priority,
      createdByNameInv: createdByName,
      requestNumberInv: requestNumber,
      createdByInv: createdBy,
      invrequesterId: invrequesterId,
    });
  }

  getChangeDetails(
    changeNumber,
    changeId,
    ciServiceName,
    serviceBased,
    changeGroupname,
    changeUsername,
    description,
    expStartDate,
    expEndDate,
    dStartDate,
    dEndDate,
    createdOn,
    cabDate,
    impGroupname,
    impUsername,
    businessRisk,
    changeType,
    status,
    consumerCompanyName,
    requesterName,
    requestNumber,
    ciId,
    ciClassId,
    createdBy,
    changeServiceId,
    changerequesterId,
    risk
  ) {
    this.setState({
      changeNumber: changeNumber,
      changeId: changeId,
      ciServiceName: ciServiceName,
      serviceBased: serviceBased,
      changeGroupname: changeGroupname,
      changeUsername: changeUsername,
      changedescription: description,
      expStartDate: expStartDate,
      expEndDate: expEndDate,
      dStartDate: dStartDate,
      dEndDate: dEndDate,
      reportDate: createdOn,
      dateCAB: cabDate,
      impleGroupName: impGroupname,
      impleUserName: impUsername,
      changeRisk: businessRisk,
      ChangeTypee: changeType,
      statusChange: status,
      companyNameChange: consumerCompanyName,
      requesterName: requesterName,
      requestNumberChange: requestNumber,
      ciIDChange: ciId,
      ciClassName: ciClassId,
      createdByChng: createdBy,
      changeServiceId: changeServiceId,
      changerequesterId: changerequesterId,
      risk: risk,
    });
  }

  getTaskDetails(
    taskCode,
    registrationId,
    serviceName,
    assignedToGroup,
    expectedStartDate,
    expectedDueDate,
    description,
    lastModificationDate,
    assignedTo,
    sequenceNumber,
    clientId,
    assignedToGroupId,
    parentWorkItemId,
    taskSubStatus,
    workItemType,
    statusName,
    requesterName,
    hopCount,
    actualStartDate,
    actualEndDate,
    companyName,
    ciId,
    serviceBased,
    ciName
  ) {
    this.setState({
      taskCode: taskCode,
      registrationId: registrationId,
      serviceName: serviceName,
      assignedToGroup: assignedToGroup,
      expectedStartDate: expectedStartDate,
      expectedDueDate: expectedDueDate,
      description: description,
      lastModificationDate: lastModificationDate,
      assignedTo: assignedTo,
      sequenceNumber: sequenceNumber,
      clientId: clientId,
      assignedToGroupId: assignedToGroupId,
      parentWorkItemId: parentWorkItemId,
      taskSubStatus: taskSubStatus,
      workItemType: workItemType,
      statusName: statusName,
      TaskRequesterName: requesterName,
      hopCount: hopCount,
      actualStartDate: actualStartDate,
      actualEndDate: actualEndDate,
      companyName: companyName,
      ciId: ciId,
      serviceBased: serviceBased,
      ciName: ciName,
    });
  }

  buildSearchParamsForBreakfixQV(params) {
    this.setState({
      faCustomDesc: "fa fa-caret-down fa_custom",
      faCustomAsc: "fa fa-caret-up",
    });
    let searchParams = Object.assign({}, params);
    //serviceManagement/rest/breakfixQuickViewAction?
    //searchByList=breakfixId,status
    //&multipleValueList=12|1,2,3
    //&patternList=like,in
    //&sortBy=breakfixId&orderBy=asc

    let selectedgroups = this.props.breakfixQuickViewFilters.selectedgroups;
    //console.log(selectedgroups);
    if (!_.isEmpty(selectedgroups)) {
      if (!_.isEmpty(searchParams.searchByList)) {
        searchParams.searchByList =
          searchParams.searchByList + "," + "spGroupid";
        searchParams.multipleValueList =
          searchParams.multipleValueList + "|" + selectedgroups.join();
      } else {
        searchParams.searchByList = "spGroupid";
        searchParams.multipleValueList = selectedgroups.join();
      }
    }

    //serviceManagement/rest/breakfixQuickViewAction?
    //searchByList=breakfixId,status
    //&multipleValueList=12|1,2,3
    //&patternList=like,in
    //&sortBy=breakfixId&orderBy=asc

    let selectedpriorities =
      this.props.breakfixQuickViewFilters.selectedpriorities;
    if (!_.isEmpty(selectedpriorities)) {
      if (!_.isEmpty(searchParams.searchByList)) {
        searchParams.searchByList =
          searchParams.searchByList + "," + "priorityId";
        searchParams.multipleValueList =
          searchParams.multipleValueList + "|" + selectedpriorities.join();
      } else {
        searchParams.searchByList = "priorityId";
        searchParams.multipleValueList = selectedpriorities.join();
      }
    }

    let selectedTags = this.props.breakfixQuickViewFilters.selectedTags;
    if (!_.isEmpty(selectedTags)) {
      if (!_.isEmpty(searchParams.searchByList)) {
        searchParams.searchByList =
          searchParams.searchByList + "," + "spUserid";
        searchParams.multipleValueList =
          searchParams.multipleValueList + "|" + selectedTags.join();
      } else {
        searchParams.searchByList = "spUserid";
        searchParams.multipleValueList = selectedTags.join();
      }
    }

    let selectedStatus = this.props.breakfixQuickViewFilters.selectedStatus;
    // Break-Fix:
    //   Open - Submitted, In Progress, Pending
    //   Closed – Fixed, Closed, Cancelled

    let statusMap = new Map();
    // statusMap.set('Open',['Submitted','Pending','In Progress']);
    // statusMap.set('Closed',['Cancelled','Fixed','Closed']);
    statusMap.set("Open", ["10", "20", "15"]);
    statusMap.set("Closed", ["35", "25", "30"]);

    //console.log(selectedStatus);
    if (!_.isEmpty(selectedStatus)) {
      let selectedMappedStatus = selectedStatus
        .map((item) => {
          return statusMap.get(item);
        })
        .join();
      if (!_.isEmpty(searchParams.searchByList)) {
        searchParams.searchByList = searchParams.searchByList + "," + "status";
        // searchParams.multipleValueList = searchParams.multipleValueList+'|'+selectedStatus.join();
        searchParams.multipleValueList =
          searchParams.multipleValueList + "|" + selectedMappedStatus;
      } else {
        searchParams.searchByList = "status";
        // searchParams.multipleValueList = selectedStatus.join();
        searchParams.multipleValueList = selectedMappedStatus;
      }
    }

    if (_.isEmpty(searchParams.searchByList)) {
      searchParams.searchByList = "status";
      // searchParams.multipleValueList = 'Submitted,Pending,In Progress';
      searchParams.multipleValueList = "10,20,15";
    }

    return searchParams;
  }

  buildSearchParamsForFulfillmentQV(params) {
    // console.log(params);
    // console.log(this.props);
    this.setState({
      faCustomFulDesc: "fa fa-caret-down fa_custom",
      faCustomFulAsc: "fa fa-caret-up",
    });
    let searchParams = Object.assign({}, params);

    //serviceManagement/rest/breakfixQuickViewAction?
    //searchByList=breakfixId,status
    //&multipleValueList=12|1,2,3
    //&patternList=like,in
    //&sortBy=breakfixId&orderBy=asc

    let selectedgroups =
      this.props.breakfixQuickViewFilters.selectedFulfillmentGroups;
    //console.log(selectedgroups);
    if (!_.isEmpty(selectedgroups)) {
      if (!_.isEmpty(searchParams.searchByList)) {
        searchParams.searchByList =
          searchParams.searchByList + "," + "asgGroupid";
        searchParams.multipleValueList =
          searchParams.multipleValueList + "|" + selectedgroups.join();
      } else {
        searchParams.searchByList = "asgGroupid";
        searchParams.multipleValueList = selectedgroups.join();
      }
    }

    //serviceManagement/rest/breakfixQuickViewAction?
    //searchByList=breakfixId,status
    //&multipleValueList=12|1,2,3
    //&patternList=like,in
    //&sortBy=breakfixId&orderBy=asc

    // let selectedpriorities = this.props.breakfixQuickViewFilters.selectedFulfillmentPriorities;
    // console.log(selectedpriorities);
    // if(!_.isEmpty(selectedpriorities)) {
    //   if(!_.isEmpty(searchParams.searchByList)){
    //     searchParams.searchByList = searchParams.searchByList+','+'priorityId';
    //     searchParams.multipleValueList = searchParams.multipleValueList+'|'+selectedpriorities.join();
    //   }
    //   else{
    //     searchParams.searchByList = 'priorityId';
    //     searchParams.multipleValueList = selectedpriorities.join();
    //   }
    // }

    let selectedTags =
      this.props.breakfixQuickViewFilters.selectedFulfillmentTags;
    //console.log(selectedTags);
    if (!_.isEmpty(selectedTags)) {
      if (!_.isEmpty(searchParams.searchByList)) {
        searchParams.searchByList =
          searchParams.searchByList + "," + "asgUserid";
        searchParams.multipleValueList =
          searchParams.multipleValueList + "|" + selectedTags.join();
      } else {
        searchParams.searchByList = "asgUserid";
        searchParams.multipleValueList = selectedTags.join();
      }
    }

    let selectedStatus =
      this.props.breakfixQuickViewFilters.selectedFulfillmentStatus;
    // Fulfillment:
    //   Open - Pending Approval, Under Fulfillment
    //   Closed – Fulfilled, Closed, Cancelled

    let statusMap = new Map();
    // statusMap.set('Assigned',['Under Fulfillment','In Progress']);
    // statusMap.set('Pending',['Pending Approval','On Hold','Referred Back']);
    // statusMap.set('Fulfilled',['Fullfilled','Fulfilled','Fullfiled']);
    // statusMap.set('Closed',['Closed','Rejected','Cancelled']);

    statusMap.set("Assigned", ["70", "15"]);
    statusMap.set("Pending", ["65", "60", "100"]);
    statusMap.set("Fulfilled", ["75"]);
    statusMap.set("Closed", ["80", "95", "85"]);

    //console.log(selectedStatus);
    if (!_.isEmpty(selectedStatus)) {
      let selectedMappedStatus = selectedStatus
        .map((item) => {
          return statusMap.get(item);
        })
        .join();
      if (!_.isEmpty(searchParams.searchByList)) {
        searchParams.searchByList =
          searchParams.searchByList + "," + "itemStatus";
        // searchParams.multipleValueList = searchParams.multipleValueList+'|'+selectedStatus.join();
        searchParams.multipleValueList =
          searchParams.multipleValueList + "|" + selectedMappedStatus;
      } else {
        searchParams.searchByList = "itemStatus";
        // searchParams.multipleValueList = selectedStatus.join();
        searchParams.multipleValueList = selectedMappedStatus;
      }
    }

    if (_.isEmpty(searchParams.searchByList)) {
      searchParams.searchByList = "itemStatus";
      searchParams.multipleValueList = "60,70,100";
    }

    return searchParams;
  }

  //========InvestigationFilterFunction start==========
  buildSearchParamsForInvestigationQV(params) {
    this.setState({
      faCustomInvDesc: "fa fa-caret-down fa_custom",
      faCustomInvAsc: "fa fa-caret-up",
    });
    let searchParams = Object.assign({}, params);
    //Swal.fire("buildSearchParamsForInvestigationQV")
    let selectedgroups =
      this.props.breakfixQuickViewFilters.selectedInvestigationGroups;
    //console.log("selectedgroups");
    //console.log(selectedgroups);
    if (!_.isEmpty(selectedgroups)) {
      if (!_.isEmpty(searchParams.searchByList)) {
        searchParams.searchByList =
          searchParams.searchByList + "," + "asgPmGroupid";
        searchParams.multipleValueList =
          searchParams.multipleValueList + "|" + selectedgroups.join();
      } else {
        searchParams.searchByList = "asgPmGroupid";
        searchParams.multipleValueList = selectedgroups.join();
      }
    }

    let selectedTags =
      this.props.breakfixQuickViewFilters.selectedInvestigationTags;
    //console.log(selectedTags);
    if (!_.isEmpty(selectedTags)) {
      if (!_.isEmpty(searchParams.searchByList)) {
        searchParams.searchByList = searchParams.searchByList + "," + "asgPmId";
        searchParams.multipleValueList =
          searchParams.multipleValueList + "|" + selectedTags.join();
      } else {
        searchParams.searchByList = "asgPmId";
        searchParams.multipleValueList = selectedTags.join();
      }
    }

    let selectedStatus =
      this.props.breakfixQuickViewFilters.selectedInvestigationStatus;
    // Investigation:
    //   Open - Under Review, Under Investigation, Root Cause Identified,Under Corrective Action,Corrective Action Not required,Corrected
    //   Closed – Closed, Cancelled

    let statusMap = new Map();
    statusMap.set("Open", [
      "Under Review",
      "Under Investigation",
      "Root Cause Identified",
      "Under Corrective Action",
      "Corrective Action Not required",
      "Corrected",
    ]);
    statusMap.set("Closed", ["Closed", "Cancelled"]);

    //console.log(selectedStatus);
    if (!_.isEmpty(selectedStatus)) {
      let selectedMappedStatus = selectedStatus
        .map((item) => {
          return statusMap.get(item);
        })
        .join();
      if (!_.isEmpty(searchParams.searchByList)) {
        searchParams.searchByList = searchParams.searchByList + "," + "status";
        // searchParams.multipleValueList = searchParams.multipleValueList+'|'+selectedStatus.join();
        searchParams.multipleValueList =
          searchParams.multipleValueList + "|" + selectedMappedStatus;
      } else {
        searchParams.searchByList = "status";
        // searchParams.multipleValueList = selectedStatus.join();
        searchParams.multipleValueList = selectedMappedStatus;
      }
    }
    if (_.isEmpty(searchParams.searchByList)) {
      searchParams.searchByList = "status";
      searchParams.multipleValueList =
        "Under Review,Under Investigation,Root Cause Identified,Under Corrective Action,Corrective Action Not required,Corrected";
    }

    return searchParams;
  }

  //==============InvestigationFilterFunction end======

  //==========changeStart==============================
  buildSearchParamsForChangeQV(params) {
    this.setState({
      faCustomChgDesc: "fa fa-caret-down fa_custom",
      faCustomChgAsc: "fa fa-caret-up",
    });
    let searchParams = Object.assign({}, params);
    //Swal.fire("buildSearchParamsForInvestigationQV")
    let selectedgroups =
      this.props.breakfixQuickViewFilters.selectedChangeGroups;
    //console.log(selectedgroups);
    if (!_.isEmpty(selectedgroups)) {
      if (!_.isEmpty(searchParams.searchByList)) {
        searchParams.searchByList =
          searchParams.searchByList + "," + "impGroupid";
        searchParams.multipleValueList =
          searchParams.multipleValueList + "|" + selectedgroups.join();
      } else {
        searchParams.searchByList = "impGroupid";
        searchParams.multipleValueList = selectedgroups.join();
      }
    }

    let selectedTags = this.props.breakfixQuickViewFilters.selectedChangeTags;
    //console.log(selectedTags);
    if (!_.isEmpty(selectedTags)) {
      if (!_.isEmpty(searchParams.searchByList)) {
        searchParams.searchByList =
          searchParams.searchByList + "," + "changeUserid";
        searchParams.multipleValueList =
          searchParams.multipleValueList + "|" + selectedTags.join();
      } else {
        searchParams.searchByList = "changeUserid";
        searchParams.multipleValueList = selectedTags.join();
      }
    }

    let selectedStatus =
      this.props.breakfixQuickViewFilters.selectedChangeStatus;
    // Investigation:
    //   Open - 'Draft','Under Review','Under Approval','Scheduled','Under Implementation','Under PIR'
    //   Closed – 'Rejected','Implemented','Completed','Cancelled'

    let statusMap = new Map();
    statusMap.set("Open", [
      "Draft",
      "Under Review",
      "Under Approval",
      "Scheduled",
      "Under Implementation",
      "Under PIR",
      "Referred Back",
    ]);
    statusMap.set("Closed", [
      "Rejected",
      "Implemented",
      "Completed",
      "Cancelled",
    ]);

    if (!_.isEmpty(selectedStatus)) {
      let selectedMappedStatus = selectedStatus
        .map((item) => {
          return statusMap.get(item);
        })
        .join();
      if (!_.isEmpty(searchParams.searchByList)) {
        searchParams.searchByList = searchParams.searchByList + "," + "status";
        // searchParams.multipleValueList = searchParams.multipleValueList+'|'+selectedStatus.join();
        searchParams.multipleValueList =
          searchParams.multipleValueList + "|" + selectedMappedStatus;
      } else {
        searchParams.searchByList = "status";
        // searchParams.multipleValueList = selectedStatus.join();
        searchParams.multipleValueList = selectedMappedStatus;
      }
    }
    if (_.isEmpty(searchParams.searchByList)) {
      searchParams.searchByList = "status";
      searchParams.multipleValueList = "30,35,40,45,55,65,95";
    }

    return searchParams;
  }
  //=========changeEnd=================================

  //==========taskStart==============================
  buildSearchParamsForTaskQV(params) {
    this.setState({
      faCustomTaskDesc: "fa fa-caret-down fa_custom",
      faCustomTaskAsc: "fa fa-caret-up",
      taskTableSize: 10,
    });
    let searchParams = Object.assign({}, params);
    searchParams.taskStatuses = "";
    searchParams.taskTypes = "";

    //Swal.fire("buildSearchParamsForInvestigationQV")
    let selectedStatus = this.props.breakfixQuickViewFilters.selectedTaskStatus;

    let statusMap = new Map();
    //statusMap.set('Open',['Draft','Under Review','Under Approval','Scheduled','Under Implementation','Under PIR']);
    //statusMap.set('Assigned',['1']);
    //statusMap.set('In Progress',['2']);
    //statusMap.set('Completed',['3']);
    //statusMap.set('Cancelled',['4']);

    statusMap.set("Open", ["1", "2", "6"]);
    //statusMap.set('Closed',['3','4','5']);
    statusMap.set("Closed", ["3", "4"]);
    // console.log(selectedStatus);
    if (!_.isEmpty(selectedStatus)) {
      let selectedMappedStatus = selectedStatus
        .map((item) => {
          return statusMap.get(item);
        })
        .join();
      if (!_.isEmpty(searchParams.taskStatuses)) {
        searchParams.taskStatuses = selectedMappedStatus;
      } 
    } else {
      searchParams.taskStatuses = "1,2,6,7";
      // searchParams = "3,4,5";
      // this.setState({taskTableSize:10});
      // this.props.resetBFQVFilters();
      // this.props.loadTaskList("","1,2,6,7");
      // this.props.setBreakfixQuickViewTableCarouselIndex("4");
    }

    let selectedTaskTypes =
      this.props.breakfixQuickViewFilters.selectedTaskTypeStatus;
    // console.log(selectedTaskTypes);

    let TaskTypesMap = new Map();

    TaskTypesMap.set("Fulfillment", [
      "Fulfilment",
      "Fullfilment",
      "Fulfillment",
    ]);
    TaskTypesMap.set("Incident", ["Incident", "Breakfix"]);
    TaskTypesMap.set("Problem", ["Problem", "Investigation"]);
    TaskTypesMap.set("Change", ["Change", "ChangeManagement"]);

    if (!_.isEmpty(selectedTaskTypes)) {
      let selectedTaskTypesTemp = selectedTaskTypes
        .map((item) => {
          return TaskTypesMap.get(item);
        })
        .join();
      // let selectedTaskTypesTemp = selectedTaskTypes.join();
      searchParams.taskTypes = selectedTaskTypesTemp;
    }
    return searchParams;
  }

  onchangeTaskTableSize(value) {
    this.setState({ taskTableSize: value });
  }

  onFromDateSelect(e) {
    let today = moment();
    this.setState({ defaultvalue: moment(today) });
    let FromDate = moment(e, this.state.date_format, true);
    let fromCorrectFormat = FromDate.isValid();
    if (typeof e == "string") {
      if (e.length == 0) this.setState({ fromDate: e });
      else {
        let empty_count = 0;
        let code_count = 0;
        for (let i = 0; i < e.length; i++) {
          if (e[i] == " ") empty_count++;
        }
        let namedSchema = Joi.string().regex(
          /^[^a-zA-Z.!@#\-$%\]^&\[/*_+=|\:;\'\"?~<,}>{)`(]+$/
        );
        let validator_to_add_myQbj = namedSchema.validate(e);
        if (validator_to_add_myQbj.error != null) {
          code_count++;
        }
        if (e.length == empty_count) {
          Swal.fire(this.props.tr["Empty Value Of Date Cannot Be Accepted"]);
          if (this.state.fromDate != "") this.setState({ fromDate: "" });
        } else if (code_count != 0 || empty_count != 0) {
          Swal.fire(this.props.tr["Please Provide Valid Date Format."]);
          if (this.state.fromDate != "") this.setState({ fromDate: "" });
        } else if (fromCorrectFormat == false) {
          Swal.fire(this.props.tr["Please Provide Valid Date Format."]);
          if (this.state.fromDate != "") this.setState({ fromDate: "" });
        } else this.setState({ fromDate: e });
      }
    } else {
      if (moment(e).valueOf() > today.valueOf()) {
        Swal.fire(this.props.tr["Future date is not allowed"]);
        if (this.state.fromDate != "") this.setState({ fromDate: "" });
      } else if (
        moment(this.state.toDate).valueOf() < moment(e).valueOf() &&
        this.state.toDate != ""
      ) {
        Swal.fire(this.props.tr["From date cannot be greater than To date"]);

        if (this.state.fromDate != "") this.setState({ fromDate: "" });
      } else {
        this.setState({ fromDate: e });
      }
    }
  }
  onToDateSelect(e) {
    let today = moment();
    this.setState({ defaultvalue: moment(today) });
    let ToDate = moment(e, this.state.date_format, true);
    let ToCorrectFormat = ToDate.isValid();
    if (typeof e == "string") {
      if (e.length == 0) this.setState({ toDate: e });
      else {
        let empty_count = 0;
        let code_count = 0;
        for (let i = 0; i < e.length; i++) {
          if (e[i] == " ") empty_count++;
        }
        let namedSchema = Joi.string().regex(
          /^[^a-zA-Z.!@#\-$%\]^&\[/*_+=|\:;\'\"?~<,}>{)`(]+$/
        );
        let validator_to_add_myQbj = namedSchema.validate(e);
        if (validator_to_add_myQbj.error != null) {
          code_count++;
        }
        if (e.length == empty_count) {
          Swal.fire(this.props.tr["Empty Value Of Date Cannot Be Accepted"]);
          if (this.state.toDate != "") this.setState({ toDate: "" });
        } else if (code_count != 0 || empty_count != 0) {
          Swal.fire(this.props.tr["Please Provide Valid Date Format."]);
          if (this.state.toDate != "") this.setState({ toDate: "" });
        } else if (ToCorrectFormat == false) {
          Swal.fire(this.props.tr["Please Provide Valid Date Format."]);
          if (this.state.toDate != "") this.setState({ toDate: "" });
        } else this.setState({ toDate: e });
      }
    } else {
      if (moment(e).valueOf() > today.valueOf()) {
        Swal.fire(this.props.tr["Future date is not allowed"]);
        if (this.state.toDate != "") this.setState({ toDate: "" });
      } else if (
        moment(e).valueOf() < moment(this.state.fromDate).valueOf() &&
        this.state.fromDate != ""
      ) {
        Swal.fire(this.props.tr["To date cannot be smaller than from date"]);
        if (this.state.toDate != "") this.setState({ toDate: "" });
      } else {
        this.setState({ toDate: e });
      }
    }
  }
  //=========TaskEnd=================================
  showBrkActiveRow(i) {
    this.setState({ rowIndexBrk: i });
  }

  setBrkSortOrder(sortBy, sortOn) {
    this.setState({ sortOrderBrk: sortBy });
    this.setState({ sortOnColumnBrk: sortOn });
  }

  setBrkSearchKeyValue(key, value, pattern) {
    this.setState({
      searchKeyBrk: key,
      searchValueBrk: value,
      patternListBrk: pattern,
    });
  }

  setBrkReportedOnRef(ref) {
    this.inputRef = ref;
  }
  isBrkClearAllFilterFunc(value) {
    this.setState({ isClearAllFilterBrk: value });
  }

  setBrkdisplayNumberInputValues(value, column) {
    // this.setState({
    //   displayNumberSearchInputBrk: displayNumber,
    // });

    switch (column) {
      case "requestNumber":
        this.setState({
          displayNumberSearchInputBrk: value || "",
        });
        break;
      case "breakfixNumber":
        this.setState({
          workItemIdSearchInputBrk: value || "",
        });
        break;
      default:
        this.setState({
          displayNumberSearchInputBrk: value || "",
          workItemIdSearchInputBrk: value || "",
        });
        break;
    }
  }

  setBrkCiServiceNameInputValues(value, column) {
    switch (column) {
      case "serviceName":
        this.setState({
          serviceNameSearchInputBrk: value || "",
        });
        break;
      case "ciName":
        this.setState({
          ciNameSearchInputBrk: value || "",
        });
        break;
      default:
        this.setState({
          serviceNameSearchInputBrk: value || "",
          ciNameSearchInputBrk: value || "",
        });
        break;
    }
  }

  setBrkCompanyInputValues(company) {
    this.setState({
      companySearchInputBrk: company,
    });
  }

  setBrkSummaryInputValues(summary) {
    this.setState({
      summarySearchInputBrk: summary,
    });
  }

  setBrkRequestedForInputValues(requestedFor) {
    this.setState({
      requestedForSearchInputBrk: requestedFor,
    });
  }

  setBrkSupportIndividualInputValues(supportIndividual) {
    this.setState({
      supportIndividualSearchInputBrk: supportIndividual,
    });
  }

  setBrkCIMindividualInputValues(cimIndividual) {
    this.setState({ cimindividualSearchInputBrk: cimIndividual });
  }
  setBrkStartAndEndDate(startDate, endDate) {
    this.setState({ startDateBrk: startDate, endDateBrk: endDate });
  }

  setBrkSelectedStatusValue(value) {
    this.setState({ selectedStatusValueBrk: value });
  }

  setBrkSelectedPriorityValue(value) {
    this.setState({ selectedPriorityValueBrk: value });
  }

  setBrkSelectedSupportGroupValue(value) {
    this.setState({ selectedSupportGroupValueBrk: value });
  }

  setBrkSelectedCriticalityValue(value) {
    this.setState({ selectedCriticality: value });
  }
  setBrkSElectedCIMGroupsValue(value) {
    this.setState({ selectedCIMGroups: value });
  }
  setStatusSearchInputBrk(status) {
    this.setState({ statusSearchInputBrk: status });
  }
  setPrioritySearchInputBrk(priority) {
    this.setState({ prioritySearchInputBrk: priority });
  }
  setSpGroupnameSearchInputBrk(spGroupname) {
    this.setState({ spGroupnameSearchInputBrk: spGroupname });
  }

  updateParameterBrk(status, priority, spGroup, criticality, cimgroups) {
    this.setState({
      statusValueBrk: status,
      priorityValueBrk: priority,
      spGroupValueBrk: spGroup,
      criticalityValueBrk: criticality,
      cimgroupsValueBrk: cimgroups,
    });
  }

  handlePanelClick(activePanelKey) {
    this.setState({ activePanelKey: activePanelKey });
  }
  /////////problem//////////////
  showInvActiveRow(i) {
    this.setState({ rowIndexInv: i });
  }

  setInvSortOrder(sortBy, sortOn) {
    this.setState({ sortOrderInv: sortBy });
    this.setState({ sortOnColumnInv: sortOn });
  }

  setInvSearchKeyValue(key, value, pattern) {
    this.setState({
      searchKeyInv: key,
      searchValueInv: value,
      patternListInv: pattern,
    });
  }

  setInvReportedOnRef(ref) {
    this.inputRef = ref;
  }
  isInvClearAllFilterFunc(value) {
    this.setState({ isClearAllFilterInv: value });
  }
  setInvdisplayNumberInputValues(value, column) {
    // this.setState({
    //   displayNumberSearchInputInv: displayNumber,
    // });

    switch (column) {
      case "requestNumber":
        this.setState({
          displayNumberSearchInputInv: value || "",
        });
        break;
      case "investigationNumber":
        this.setState({
          workItemIdSearchInputInv: value || "",
        });
        break;
      default:
        this.setState({
          displayNumberSearchInputInv: value || "",
          workItemIdSearchInputInv: value || "",
        });
        break;
    }
  }

  setInvCiServiceNameInputValues(ciServiceName) {
    this.setState({
      ciNameSearchInputInv: ciServiceName,
    });
  }

  setInvCompanyInputValues(company) {
    this.setState({
      companySearchInputInv: company,
    });
  }

  setInvSummaryInputValues(summary) {
    this.setState({
      summarySearchInputInv: summary,
    });
  }

  setInvRequestedByInputValues(requestedFor) {
    this.setState({
      requestedBySearchInputInv: requestedFor,
    });
  }

  setInvProblemManagerInputValues(supportIndividual) {
    this.setState({
      problemManagerSearchInputInv: supportIndividual,
    });
  }

  setInvStartAndEndDate(startDate, endDate) {
    this.setState({ startDateInv: startDate, endDateInv: endDate });
  }

  setInvSelectedStatusValue(value) {
    this.setState({ selectedStatusValueInv: value });
  }

  setInvSelectedPriorityValue(value) {
    this.setState({ selectedPriorityValueInv: value });
  }

  setInvSelectedSupportGroupValue(value) {
    this.setState({ selectedProblemGroupValueInv: value });
  }

  setStatusSearchInputInv(status) {
    this.setState({ statusSearchInputInv: status });
  }
  setPrioritySearchInputInv(priority) {
    this.setState({ prioritySearchInputInv: priority });
  }
  setSpGroupnameSearchInputInv(spGroupname) {
    this.setState({ spGroupnameSearchInputInv: spGroupname });
  }

  updateParameterInv(status, priority, spGroup) {
    this.setState({
      statusValueInv: status,
      priorityValueInv: priority,
      spGroupValueInv: spGroup,
    });
  }
  ///////////////task/////////////////
  showTaskActiveRow(i) {
    if ((" i ", i));
    this.setState({ taskRowIndex: i });
  }
  setChangeTaskNumberInputValues(taskNumber) {
    this.setState({
      taskNumberSearchInput: taskNumber,
    });
  }
  setChangeworkItemNumberInputValues(itemNumber) {
    this.setState({
      workItemNumberSearchInput: itemNumber,
    });
  }
  setChangeTaskSummaryInputValues(summary) {
    this.setState({
      taskSummarySearchInput: summary,
    });
  }
  setChangeTaskAssignedToInputValues(assignedValue) {
    this.setState({
      taskAssignedToSearchInput: assignedValue,
    });
  }
  setChangeTaskServiceNameInputValues(value, column) {
    // this.setState({
    //   taskServiceNameSearchInput: serviceName,
    // });

    switch (column) {
      case "serviceName":
        this.setState({
          taskServiceNameSearchInput: value || "",
        });
        break;
      case "ciName":
        this.setState({
          taskciNameSearchInput: value || "",
        });
        break;
      default:
        this.setState({
          taskServiceNameSearchInput: value || "",
          taskciNameSearchInput: value || "",
        });
        break;
    }
  }
  setChangeTaskCompanyNameInputValues(value){
    this.setState({taskCompanyNameSearchInput: value});
  }
  setTaskStartAndEndDate(startDate, endDate) {
    this.setState({ taskStartDate: startDate, taskEndDate: endDate });
  }
  setTaskDateRef(ref) {
    this.inputRef = ref;
    this.setState({ taskDateRef: this.inputRef });
  }
  setTaskSelectedGroupValue(value) {
    this.setState({ taskGroupValue: value });
  }
  updateTaskGroup(groupValues) {
    this.setState({ taskGroup: groupValues });
  }
  setTaskSelectedTaskValue(value) {
    this.setState({ taskTypeValue: value });
  }
  updateTaskType(typeValues) {
    this.setState({ taskType: typeValues });
  }
  setTaskSelectedStatusValue(value) {
    this.setState({ taskStatusValue: value });
  }
  updateTaskStatus(statusValues) {
    this.setState({ taskStatus: statusValues });
  }
  setTaskSearchKeyValue(key) {
    this.setState({ taskSearchValueParams: key });
  }

  isClearAllFilterFuncTask(value) {
    this.setState({ taskIsClearAllFilter: value });
  }
  updateParameterChange(status, ChangeGroup, impleGroup) {
    this.setState({
      statusValueChange: status,
      ChangeGroup: ChangeGroup,
      impleGroup: impleGroup,
    });
  }
  getGroupValues(groups) {
    const { group_id } = this.state;
    if (groups && Array.isArray(groups) && groups.length) {
      return groups;
    } else if (!group_id || group_id == -1 || group_id == "0") {
      return ["-1"];
    } else if (
      group_id &&
      typeof group_id === "string" &&
      group_id.length > 0
    ) {
      return group_id.split(",");
    }
    return [];
  }

  isProblemInvestigator() {
    // console.log('this.state.role_id - ', this.state.role_id);
    //
    /**
     * 53 - Problem Investigator
     * 16 - Problem User
     * 17 - Problem Manager
     * 48 - Service Desk User
     */
    if (
      this.state.role_id.split(",").includes("53") &&
      !(
        this.state.role_id.split(",").includes("16") ||
        this.state.role_id.split(",").includes("17") ||
        this.state.role_id.split(",").includes("48")
      )
    ) {
      return true;
    } else {
      return false;
    }
  }
  setBrkResetSelected(value) {
    this.setState({ isBrkResetSelected: value });
  }

  setAppointmentAction(action = "", value = "") {
    if (action == "dateRangeLabel") {
      this.setState({ appointmentDateRangeLabel: value });
    } else if (action == "hideClearFilter") {
      this.setState({ showAppointmentClearFilter: false });
    } else if (action == "showClearFilter") {
      this.setState({ showAppointmentClearFilter: true });
    } else {
      this.setState({ appointmentAction: action });
      if (action == "clear") {
        this.setState({ showAppointmentClearFilter: false });
      }
    }
  }
  setAiAction(action = "", value = "") {
    if (action == "dateRangeLabel") {
      this.setState({ aiDateRangeLabel: value });
    } else if (action == "hideClearFilter") {
      this.setState({ showAiClearFilter: false });
    } else if (action == "showClearFilter") {
      this.setState({ showAiClearFilter: true });
    } else {
      this.setState({ aiAction: action });
      if (action == "clear") {
        this.setState({ showAiClearFilter: false });
      }
    }
  }

  bulkUpdateHandler(type = '', data = []){
    try{
      if(type == 'hideBulkUpdateDiffCompany' || type==='' || type==='hideBulkUpdateSection' || type == 'clearSection'){
        this.setState({ isRightSideVisible: false });
      }
      else{
        this.setState({ isRightSideVisible: true });
      }
      if(type == 'hideBulkUpdateSection' || type == 'clearSection' || type == 'hideBulkUpdateDiffCompany'){
        this.setState({ bulkUpdateData: [] });
      }
      if(type == '' || type == 'selectAll'){
        this.setState({ isRightSideVisible: ((data && Array.isArray(data) && data.length > 0) ? true : false), bulkUpdateData: data });
      }
      if(type == 'bulkUpdateComplete'){
        this.setState({ bulkUpdateData: [], hideBulkUpdateDiffCompany: false });
      }
      this.setState({ bulkUpdateAction: type });
    }catch(e){
      console.error(e);
    }
  }

  showBulkUpdateInModule(module = ''){
    try{
      switch(module){
        case 'incident':
          // 12 - Incident User, 13 - Incident Manager, 14 - Critical Incident Manager, 63 - Incident Viewer
          // 48 - Service Desk User
          if( this.state.role_id.split(",").includes("12") || this.state.role_id.split(",").includes("13") || this.state.role_id.split(",").includes("14") || (this.state.role_id.split(",").includes("63") && this.state.role_id.split(",").includes("48")) || this.state.role_id.split(",").includes("48") ){
            return true;
          }
          return false;
        case 'problem':
          // 16 - Problem User, 17 - Problem Manager, 53 - Problem Investigator, 64 - Problem Viewer
          if( this.state.role_id.split(",").includes("16") || this.state.role_id.split(",").includes("17") || this.state.role_id.split(",").includes("53") ){
            return true;
          }
          return false;
        case 'fulfillment':
          // 43 - Fulfillment User, 44 - Fulfillment Manager, 62 - Fulfillment Viewer
          if( this.state.role_id.split(",").includes("43") || this.state.role_id.split(",").includes("44") || (this.state.role_id.split(",").includes("62") && this.state.role_id.split(",").includes("48")) || this.state.role_id.split(",").includes("48") ){
            return true;
          }
          return false;
        case 'change':
          // 19 - Change User, 20 - Change Manager, 65 - Change Viewer, 51 - Implementation User, 52 - Implementation Manager
          if( this.state.role_id.split(",").includes("19") || this.state.role_id.split(",").includes("20") || this.state.role_id.split(",").includes("51") || this.state.role_id.split(",").includes("52") ){
            return true;
          }
          return false;
        default:
          return false;
      }
    }catch(e){
      console.error(e);
      return false;
    }
  }
  getAdminPrefrences(companyId,moduleName,propertyID,functionID,fieldName=''){
    let adminQuery = {
      "companyId": companyId,
      "module": moduleName,
      "propertyId": propertyID,
      "businessFunctionId": functionID
    };
    adminQuery = JSON.stringify(adminQuery);
    axios.get("/api/getAdminPreferences", { headers: { query: adminQuery } }).then((responseData) => {
      if(responseData.data!==''||responseData.data[0].length>0){
        if(fieldName==='slaStatus'){
          this.setState({showSLAstatus: responseData.data[0].value==='True'?true:false});
        }else if(fieldName==='requestID'){
          this.setState({showRequestID: responseData.data[0].value==='True'?true:false});
        }else if(fieldName==='NlpSearch'){
          this.setState({isAiTypeENV: responseData.data[0].value==='True'?true:false});
        }
      }
    }).catch((err) => {
      console.log(err);
    });
  } 
  checkIsWalkupEnabled(incRolesEnabled, companyId, moduleName, propertyID) {
    let adminQuery = {
      "companyId": companyId,
      "module": moduleName,
      "propertyId": propertyID
    };
    adminQuery = JSON.stringify(adminQuery);
    axios.get("/api/getAdminPreferences", { headers: { query: adminQuery } }).then((responseData) => {
      this.setState({ walkupRoleEnabled: incRolesEnabled && responseData.data[0].value==='True' })
    })
  }
  showConditionBuilder(val) {
    this.setState({ isShowBuilder: val });
  }
  render() {

    const breakFixDateRangeFilter = this.state.breakFixDateRangeFilter;
    const breakFixGraphView = this.props.breakFixGraphView; //this.state.breakFixGraphView;
    const carouselIndex = this.props.breakfixQuickViewTable.carouselIndex;

    const breakFixSearchParams = this.state.breakFixSearchParams;

    const taskGraphView = this.props.taskGraphView;
    const taskDateRangeFilter = this.state.taskDateRangeFilter;
    const taskSearchParams = this.state.taskSearchParams;

    const investigationGraphView = this.props.investigationGraphView;
    const investigationDateRangeFilter =
      this.state.investigationDateRangeFilter;
    const investigationSearchParams = this.state.investigationSearchParams;

    const changeGraphView = this.props.changeGraphView;
    const changeDateRangeFilter = this.state.changeDateRangeFilter;
    const changeSearchParams = this.state.changeSearchParams;

    const fullfilmentGraphView = this.props.fullfilmentGraphView;
    const fullfilmentDateRangeFilter = this.state.fullfilmentDateRangeFilter;
    const fullfilmentSearchParams = this.state.fullfilmentSearchParams;

    const dateRangeFlag =
      (carouselIndex == 0 && breakFixGraphView) ||
      (carouselIndex == 4 && taskGraphView) ||
      (carouselIndex == 1 && fullfilmentGraphView) ||
      (carouselIndex == 2 && investigationGraphView) ||
      (carouselIndex == 3 && changeGraphView);

    let colWidth = "";
    this.state.isRightSideVisible
      ? (colWidth = 8)
      : (colWidth = 12);


    const allDrpdownState = (this.state.isStatusOpen || this.state.isWorkItemOpen || this.state.isAssignedToGroupOpen || this.state.isFulFillmentStatusOpen || this.state.isFulFillmentAsgGroupOpen || this.state.isIncidentStatusOpen || this.state.isIncidentPriorityOpen || this.state.isIncidentSupportGroupOpen || this.state.isIncidentCIMGroupsOpen || this.state.isIncidentCriticalityOpen || this.state.isProStatusOpen || this.state.isProPriorityOpen || this.state.isProManagGroupOpen || this.state.isChangeStatusOpen || this.state.isChangeManagGroupOpen || this.state.isChangeImpleGrpOpen || this.state.isChangeTypeOpen) && this.props.isFetchingDetails ;
    
    const dataLengthState = (this.props.myChangeManagementList?.data?.length <= 6 || this.props.investigationList?.data?.length <= 6 || this.props.myTaskDetailsList?.data?.length <= 6 || this.props.quickViewDetails?.data?.length <= 6 || this.props.fulFillmentList?.data?.length <= 6);

    const loaderState = (this.props.isFetchingDetails || this.props.showLoader);

    return (
      <>
      {(this.state?.bulkUpdateAction == 'bulkUpdateInProgress') && <div className="fullViewLoadDv">
        <div className='rctLoader'>
					<LoadingIcons.Bars height="5em" stroke="transparent" fill="#06bcee" />
				</div>
      </div>}
      <main className="">
        {this.state.role_id !== "" ? (
          <React.Suspense fallback={<ListLoader />}>
            <WibSearch
              tr={this.props.tr}
              handleSelect={this.handleSelect}
              selectedFilterTab={this.selectedFilterTab}
              roleIdentification={this.roleIdentification}
              isWIBGlobalSearchEnabled={this.isWIBGlobalSearchEnabled}
              selectedGlobalSearchModule={this.selectedGlobalSearchModule}
              group_id={this.state.group_id}
              changeImplentationRole={this.state.changeImplentationRole}
              handleSelectAllGroup={this.handleSelectAllGroup}
              setGlobalSearchInformation={this.setGlobalSearchInformation}
            />
          </React.Suspense>
        ) : null}

        <div className="container-fluid margin-t-10 minHeightWIB" bsClass="">
          <XsmQuickViewDetailtHeader
            isShowBuilder={this.state.isShowBuilder}
            showConditionBuilder={this.showConditionBuilder}
            full_name={this.state.full_name}
            companyId={this.state.companyId}
            isAiTypeENV={this.state.isAiTypeENV}
            service_desk_role_enabled={this.state.service_desk_role_enabled}
            critical_incident_manager_enabled={this.state.critical_incident_manager_enabled}
            core_user_role_enabled={this.state.core_user_role_enabled}
            bulkUpdateHandler={this.bulkUpdateHandler}
            setModule = {this.setModule}
            module={this.state.module}
            Limit={this.state.Limit}
            setLimit={this.setLimit}
            page={this.state.page}
            setPage={this.setPage}
            slaRole={this.state.slaRole}
            slaDisableDateRange={this.slaDisableDateRange}
            slaDisableDateRangeState={this.state.slaDisableDateRangeState}
            appointmentAction={this.state.appointmentAction}
            appointmentDateRangeLabel={this.state.appointmentDateRangeLabel}
            showAppointmentClearFilter={this.state.showAppointmentClearFilter}
            setAppointmentAction={this.setAppointmentAction}
            aiAction={this.state.aiAction}
            aiDateRangeLabel={this.state.aiDateRangeLabel}
            showAiClearFilter={this.state.showAiClearFilter}
            setAiAction={this.setAiAction}
            groupFilterApplied={this.state.groupFilterApplied}
            changeImplentationRole={this.state.changeImplentationRole}
            setGroupFilterApplied={this.setGroupFilterApplied}
            refreshClickFun={this.refreshClickFun}
            multiSelectOpenFun={this.multiSelectOpenFun}
            selectAllLabelFun={this.selectAllLabelFun}
            taskDateRangeLabel={this.state.taskDateRangeLabel}
            fulfillDateRangeLabel={this.state.fulfillDateRangeLabel}
            breakDateRangeLabel={this.state.breakDateRangeLabel}
            problemDateRangeLabel={this.state.problemDateRangeLabel}
            changeDateRangeLabel={this.state.changeDateRangeLabel}
            isProblemInvestigator={this.isProblemInvestigator}
            getGroupValues={this.getGroupValues}
            role_id={this.state.role_id}
            user_id={this.state.user_id}
            group_id={this.state.group_id}
            filterTabActive={this.state.filterTabActive}
            filterTabClick={this.filterTabClick}
            handleSelectMyGroup={this.handleSelectMyGroup}
            handleSelectAllGroup={this.handleSelectAllGroup}
            selectedFilterTab={this.selectedFilterTab}
            setDateRangeFilter={this.setGraphDateRangeFilter}
            dateRangeFilter={dateRangeFlag}
            moduleName={carouselIndex}
            handleSelect={this.handleSelect}
            translator={this.props.tr}
            myTaskDetailsList={this.props.myTaskDetailsList}
            showRightSide={this.showRightSide}
            isRightSideVisible={this.state.isRightSideVisible}
            breakFixGraphView={breakFixGraphView}
            taskGraphView={taskGraphView}
            fullfilmentGraphView={fullfilmentGraphView}
            investigationGraphView={investigationGraphView}
            changeGraphView={changeGraphView}
            fulfillmenRowIndex={this.state.fulfillmenRowIndex}
            fulfillmentSearchKey={this.state.fulfillmentSearchKey}
            fulfillmentSearchValue={this.state.fulfillmentSearchValue}
            fulfillmentPattern={this.state.fulfillmentPattern}
            fulfillmentSortOrder={this.state.fulfillmentSortOrder}
            fulfillmentSortOnColumn={this.state.fulfillmentSortOnColumn}
            fulfillmentGroup={this.state.fulfillmentGroup}
            changemngmtGroup={this.state.changemngmtGroup}
            implemntGroup={this.state.implemntGroup}
            fulGrp={this.state.fulGrp}
            fulfillmentUser={this.state.fulfillmentUser}
            fulfillmentStatus={this.state.fulfillmentStatus}
            fulfillmentIsClearAllFilter={this.state.fulfillmentIsClearAllFilter}
            // fulfillmentDisplayNumberSearchInput={this.state.fulfillmentDisplayNumberSearchInput}
            fulfillmentCiServiceNameSearchInput={
              this.state.fulfillmentCiServiceNameSearchInput
            }
            fulfillmentRequesterNameSearchInput={
              this.state.fulfillmentRequesterNameSearchInput
            }
            fulfillmentCompanyNameSearchInput={
              this.state.fulfillmentCompanyNameSearchInput
            }
            fulfillmentSummarySearchInput={this.state.fulfillmentSummarySearchInput}
            fulfillerNameSearchInput={this.state.fulfillerNameSearchInput}
            fulfillmentSelectedStatusValue={
              this.state.fulfillmentSelectedStatusValue
            }
            fulfillmentGroupValue={this.state.fulfillmentGroupValue}
            changeManagementGroupValue={this.state.changeManagementGroupValue}
            impleGroupValue={this.state.impleGroupValue}
            fulfillmentReportedStartDate={
              this.state.fulfillmentReportedStartDate
            }
            fulfillmentReportedEndDate={this.state.fulfillmentReportedEndDate}
            fulfillmentReportedDateRef={this.state.fulfillmentReportedDateRef}
            showFulfillmentActiveRow={this.showFulfillmentActiveRow}
            setFulSearchKeyValue={this.setFulSearchKeyValue}
            setFulSortOrder={this.setFulSortOrder}
            updateFulStatus={this.updateFulStatus}
            defaultFulfillmentStatusSelected={
              this.state.defaultFulfillmentStatusSelected
            }
            defaultBreakfixStatusSelected={
              this.state.defaultBreakfixStatusSelected
            }
            defaultInvestigationStatusSelected={
              this.state.defaultInvestigationStatusSelected
            }
            defaultChangeMgmtStatusSelected={
              this.state.defaultChangeMgmtStatusSelected
            }
            setDefaultFulfillmentStatusSelected={
              this.setDefaultFulfillmentStatusSelected
            }
            setDefaultBreakfixStatusSelected={
              this.setDefaultBreakfixStatusSelected
            }
            setDefaultInvestigationStatusSelected={
              this.setDefaultInvestigationStatusSelected
            }
            setDefaultChangeMgmtStatusSelected={
              this.setDefaultChangeMgmtStatusSelected
            }
            updateFulGroup={this.updateFulGroup}
            updateChangemangmtGroup={this.updateChangemangmtGroup}
            updateImplmtGroup={this.updateImplmtGroup}
            updateUserGroup={this.updateUserGroup}
            updateGroup={this.updateGroup}
            isClearAllFilterFuncFulfillment={
              this.isClearAllFilterFuncFulfillment
            }
            // setFuldisplayNumberInputValues={this.setFuldisplayNumberInputValues}
            setFulCiServiceNameInputValues={this.setFulCiServiceNameInputValues}
            setFulRequesterInputValues={this.setFulRequesterInputValues}
            setFulReportedStartAndEndDate={this.setFulReportedStartAndEndDate}
            setFulSelectedStatusValue={this.setFulSelectedStatusValue}
            setFulSelectedGroupValue={this.setFulSelectedGroupValue}
            setChangMangmtGroupValue={this.setChangMangmtGroupValue}
            setimpleGroupValue={this.setimpleGroupValue}
            setFulReportedRef={this.setFulReportedRef}
            setFulfillerInputValues={this.setFulfillerInputValues}
            setFulCompanyNameInputValues={this.setFulCompanyNameInputValues}
            setFulSummaryInputValues={this.setFulSummaryInputValues}
            rowIndexBrk={this.state.rowIndexBrk}
            consumerCompanyName={this.state.consumerCompanyName}
            status={this.state.status}
            priorityId={this.state.priorityId}
            impactedUsername={this.state.impactedUsername}
            createdByName={this.state.createdByName}
            reportedThrough={this.state.reportedThrough}
            requesterLocationName={this.state.requesterLocationName}
            sortOrderBrk={this.state.sortOrderBrk}
            sortOnColumnBrk={this.state.sortOnColumnBrk}
            searchKeyBrk={this.state.searchKeyBrk}
            searchValueBrk={this.state.searchValueBrk}
            isClearAllFilterBrk={this.state.isClearAllFilterBrk}
            // displayNumberSearchInputBrk={this.state.displayNumberSearchInputBrk}
            // ciServiceNameSearchInputBrk={this.state.ciServiceNameSearchInputBrk}
            companySearchInputBrk={this.state.companySearchInputBrk}
            summarySearchInputBrk={this.state.summarySearchInputBrk}
            requestedForSearchInputBrk={this.state.requestedForSearchInputBrk}
            supportIndividualSearchInputBrk={
              this.state.supportIndividualSearchInputBrk
            }
            cimindividualSearchInputBrk={this.state.cimindividualSearchInputBrk}
            startDateBrk={this.state.startDateBrk}
            endDateBrk={this.state.endDateBrk}
            selectedStatusValueBrk={this.state.selectedStatusValueBrk}
            selectedPriorityValueBrk={this.state.selectedPriorityValueBrk}
            selectedSupportGroupValueBrk={
              this.state.selectedSupportGroupValueBrk
            }
            selectedCriticality={this.selectedCriticality}
            patternListBrk={this.state.patternListBrk}
            showBrkActiveRow={this.showBrkActiveRow}
            setBrkSortOrder={this.setBrkSortOrder}
            setBrkSearchKeyValue={this.setBrkSearchKeyValue}
            isBrkClearAllFilterFunc={this.isBrkClearAllFilterFunc}
            // setBrkdisplayNumberInputValues={this.setBrkdisplayNumberInputValues}
            setBrkCiServiceNameInputValues={this.setBrkCiServiceNameInputValues}
            setBrkCompanyInputValues={this.setBrkCompanyInputValues}
            setBrkSummaryInputValues={this.setBrkSummaryInputValues}
            setBrkRequestedForInputValues={this.setBrkRequestedForInputValues}
            setBrkSupportIndividualInputValues={
              this.setBrkSupportIndividualInputValues
            }
            setBrkCIMindividualInputValues={this.setBrkCIMindividualInputValues}
            setBrkStartAndEndDate={this.setBrkStartAndEndDate}
            setBrkSelectedStatusValue={this.setBrkSelectedStatusValue}
            setBrkSelectedPriorityValue={this.setBrkSelectedPriorityValue}
            setBrkSelectedSupportGroupValue={
              this.setBrkSelectedSupportGroupValue
            }
            setBrkStatusRef={this.setBrkStatusRef}
            setBrkPriorityRef={this.setBrkPriorityRef}
            setBrkSupportGroupRef={this.setBrkSupportGroupRef}
            setStatusSearchInputBrk={this.setStatusSearchInputBrk}
            setPrioritySearchInputBrk={this.setStatusSearchInputBrk}
            setSpGroupnameSearchInputBrk={this.setSpGroupnameSearchInputBrk}
            statusSearchInputBrk={this.state.statusSearchInputBrk}
            prioritySearchInputBrk={this.state.prioritySearchInputBrk}
            spGroupnameSearchInputBrk={this.state.spGroupnameSearchInputBrk}
            setBrkReportedOnRef={this.setBrkReportedOnRef}
            reprotedOnDateRangePickerRef={this.inputRef}
            updateParameterBrk={this.updateParameterBrk}
            statusValueBrk={this.state.statusValueBrk}
            priorityValueBrk={this.state.priorityValueBrk}
            spGroupValueBrk={this.state.spGroupValueBrk}
            criticalityValueBrk={this.state.criticalityValueBrk}
            cimgroupsValueBrk={this.state.cimgroupsValueBrk}
            changeToGraphView={this.changeToGraphView}
            activePanelKey={this.state.activePanelKey}
            handlePanelClick={this.handlePanelClick}
            changeRowIndex={this.state.changeRowIndex}
            showChangeActiveRow={this.showChangeActiveRow}
            changeSearchKey={this.state.changeSearchKey}
            changeSearchValue={this.state.changeSearchValue}
            changePattern={this.state.changePattern}
            changeSortOrder={this.state.changeSortOrder}
            changeSortOnColumn={this.state.changeSortOnColumn}
            changeStatus={this.state.changeStatus}
            changeType={this.state.changeType}
            // changeDisplayNumberSearchInput={this.state.changeDisplayNumberSearchInput}
            changeCiServiceNameSearchInput={
              this.state.changeCiServiceNameSearchInput
            }
            changeCompanyNameSearchInput={
              this.state.changeCompanyNameSearchInput
            }
            changeSummaryNameSearchInput={
              this.state.changeSummaryNameSearchInput
            }
            changeRequesterNameSearchInput={
              this.state.changeRequesterNameSearchInput
            }
            changeSelectedStatusValue={this.state.changeSelectedStatusValue}
            changeTypeValue={this.state.changeTypeValue}
            changeReportedStartDate={this.state.changeReportedStartDate}
            changeReportedEndDate={this.state.changeReportedEndDate}
            changeExpectedStartDate={this.state.changeExpectedStartDate}
            changeExpectedEndDate={this.state.changeExpectedEndDate}
            changeCABStartDate={this.state.changeCABStartDate}
            changeCABEndDate={this.state.changeCABEndDate}
            changeReportedDateRef={this.state.changeReportedDateRef}
            changeExpectedDateRef={this.state.changeExpectedDateRef}
            changeCABDateRef={this.state.changeCABDateRef}
            changeIsClearAllFilter={this.state.changeIsClearAllFilter}
            setChangeSearchKeyValue={this.setChangeSearchKeyValue}
            setChangeSortOrder={this.setChangeSortOrder}
            setChangeReportedRef={this.setChangeReportedRef}
            setChangeExpectedDateRef={this.setChangeExpectedDateRef}
            setChangeCABDateRef={this.setChangeCABDateRef}
            setChangeSelectedStatusValue={this.setChangeSelectedStatusValue}
            setSelectedChangeTypeValue={this.setSelectedChangeTypeValue}
            isClearAllFilterFuncChange={this.isClearAllFilterFuncChange}
            // setChangedisplayNumberInputValues={this.setChangedisplayNumberInputValues}
            setChangeCiServiceNameInputValues={
              this.setChangeCiServiceNameInputValues
            }
            setChangeCompanyNameInputValues={
              this.setChangeCompanyNameInputValues
            }
            setChangeSummaryNameInputValues={
              this.setChangeSummaryNameInputValues
            }
            setChangeRequesterInputValues={this.setChangeRequesterInputValues}
            setChangeReportedStartAndEndDate={
              this.setChangeReportedStartAndEndDate
            }
            setChangeExpectedStartAndEndDate={
              this.setChangeExpectedStartAndEndDate
            }
            setChangeCABStartAndEndDate={this.setChangeCABStartAndEndDate}
            updateChangeStatus={this.updateChangeStatus}
            updateChangeType={this.updateChangeType}
            tableSizeChangeMgmt={this.state.tableSizeChangeMgmt}
            tableSizeFullfilment={this.state.tableSizeFullfilment}
            rowIndexInv={this.state.rowIndexInv}
            sortOrderInv={this.state.sortOrderInv}
            sortOnColumnInv={this.state.sortOnColumnInv}
            searchKeyInv={this.state.searchKeyInv}
            searchValueInv={this.state.searchValueInv}
            isClearAllFilterInv={this.state.isClearAllFilterInv}
            // displayNumberSearchInputInv={this.state.displayNumberSearchInputInv}
            ciNameSearchInputInv={this.state.ciNameSearchInputInv}
            companySearchInputInv={this.state.companySearchInputInv}
            summarySearchInputInv={this.state.summarySearchInputInv}
            requestedBySearchInputInv={this.state.requestedBySearchInputInv}
            problemManagerSearchInputInv={
              this.state.problemManagerSearchInputInv
            }
            startDateInv={this.state.startDateInv}
            endDateInv={this.state.endDateInv}
            selectedStatusValueInv={this.state.selectedStatusValueInv}
            selectedPriorityValueInv={this.state.selectedPriorityValueInv}
            selectedProblemGroupValueInv={
              this.state.selectedProblemGroupValueInv
            }
            patternListInv={this.state.patternListInv}
            showInvActiveRow={this.showInvActiveRow}
            setInvSortOrder={this.setInvSortOrder}
            setInvSearchKeyValue={this.setInvSearchKeyValue}
            isInvClearAllFilterFunc={this.isInvClearAllFilterFunc}
            // setInvdisplayNumberInputValues={this.setInvdisplayNumberInputValues}
            setInvCiServiceNameInputValues={this.setInvCiServiceNameInputValues}
            setInvCompanyInputValues={this.setInvCompanyInputValues}
            setInvSummaryInputValues={this.setInvSummaryInputValues}
            setInvRequestedByInputValues={this.setInvRequestedByInputValues}
            setInvProblemManagerInputValues={
              this.setInvProblemManagerInputValues
            }
            setInvStartAndEndDate={this.setInvStartAndEndDate}
            setInvSelectedStatusValue={this.setInvSelectedStatusValue}
            setInvSelectedPriorityValue={this.setInvSelectedPriorityValue}
            setInvSelectedSupportGroupValue={
              this.setInvSelectedSupportGroupValue
            }
            setInvStatusRef={this.setInvStatusRef}
            setInvPriorityRef={this.setInvPriorityRef}
            setInvSupportGroupRef={this.setInvSupportGroupRef}
            setStatusSearchInputInv={this.setStatusSearchInputInv}
            setPrioritySearchInputInv={this.setStatusSearchInputInv}
            setSpGroupnameSearchInputInv={this.setSpGroupnameSearchInputInv}
            statusSearchInputInv={this.state.statusSearchInputInv}
            prioritySearchInputInv={this.state.prioritySearchInputInv}
            spGroupnameSearchInputInv={this.state.spGroupnameSearchInputInv}
            setInvReportedOnRef={this.setInvReportedOnRef}
            reprotedOnDateRangePickerRefInv={this.inputRef}
            updateParameterInv={this.updateParameterInv}
            statusValueInv={this.state.statusValueInv}
            priorityValueInv={this.state.priorityValueInv}
            spGroupValueInv={this.state.spGroupValueInv}
            showTaskActiveRow={this.showTaskActiveRow}
            taskRowIndex={this.state.taskRowIndex}
            taskNumberSearchInput={this.state.taskNumberSearchInput}
            workItemNumberSearchInput={this.state.workItemNumberSearchInput}
            taskSummarySearchInput={this.state.taskSummarySearchInput}
            taskAssignedToSearchInput={this.state.taskAssignedToSearchInput}
            setChangeTaskNumberInputValues={this.setChangeTaskNumberInputValues}
            setChangeworkItemNumberInputValues={
              this.setChangeworkItemNumberInputValues
            }
            setChangeTaskSummaryInputValues={
              this.setChangeTaskSummaryInputValues
            }
            setChangeTaskAssignedToInputValues={
              this.setChangeTaskAssignedToInputValues
            }
            taskStartDate={this.state.taskStartDate}
            taskEndDate={this.state.taskEndDate}
            taskDateRef={this.state.taskDateRef}
            setTaskStartAndEndDate={this.setTaskStartAndEndDate}
            setTaskSelectedGroupValue={this.setTaskSelectedGroupValue}
            taskGroupValue={this.state.taskGroupValue}
            setTaskDateRef={this.setTaskDateRef}
            updateTaskGroup={this.updateTaskGroup}
            taskGroup={this.state.taskGroup}
            taskTypeValue={this.state.taskTypeValue}
            taskType={this.state.taskType}
            setTaskSelectedTaskValue={this.setTaskSelectedTaskValue}
            updateTaskType={this.updateTaskType}
            taskStatusValue={this.state.taskStatusValue}
            taskStatus={this.state.taskStatus}
            setTaskSelectedStatusValue={this.setTaskSelectedStatusValue}
            updateTaskStatus={this.updateTaskStatus}
            setTaskSearchKeyValue={this.setTaskSearchKeyValue}
            taskSearchValueParams={this.state.taskSearchValueParams}
            taskIsClearAllFilter={this.state.taskIsClearAllFilter}
            isClearAllFilterFuncTask={this.isClearAllFilterFuncTask}
            insideParams={insideParams}
            taskTableSize={this.state.taskTableSize}
            gettingRoleId={this.gettingRoleId}
            isWIBGlobalSearchEnabled={this.isWIBGlobalSearchEnabled}
            selectedGlobalSearchModule={this.selectedGlobalSearchModule}
            selectedGlobalModule={this.state.selectedGlobalModule}
            setOnFulfillmentFilterSelection={
              this.setOnFulfillmentFilterSelection
            }
            setOnBreakfixFilterSelection={this.setOnBreakfixFilterSelection}
            setOnInvestigationFilterSelection={
              this.setOnInvestigationFilterSelection
            }
            setOnChangeMgmtFilterSelection={this.setOnChangeMgmtFilterSelection}
            setGlobalSearchInformation={this.setGlobalSearchInformation}
            setBrkResetSelected={this.setBrkResetSelected}
            setImplementationMgmtgroupSelected={
              this.setImplementationMgmtgroupSelected
            }
            setChangeMgmtgroupSelected={this.setChangeMgmtgroupSelected}
            roleIdentification={this.roleIdentification}
            fulfillmentModuleEnabled={this.state.fulfillmentModuleEnabled}
            incidentModuleEnabled={this.state.incidentModuleEnabled}
            walkupRoleEnabled={this.state.walkupRoleEnabled}
            problemModuleEnabled={this.state.problemModuleEnabled}
            changeModuleEnabled={this.state.changeModuleEnabled}
            disableTaskModule={this.state.disableTaskModule}
            core_d2c_roles_enabled={this.state.core_d2c_roles_enabled}
            task_viewer_role_enabled={this.state.task_viewer_role_enabled}
            only_fulfillment_viewer_role_enabled={
              this.state.only_fulfillment_viewer_role_enabled
            }
            only_incident_viewer_role_enabled={
              this.state.only_incident_viewer_role_enabled
            }
            only_problem_viewer_role_enabled={
              this.state.only_problem_viewer_role_enabled
            }
            only_change_viewer_role_enabled={
              this.state.only_change_viewer_role_enabled
            }
            problem_search_all={this.state.problem_search_all}
            incident_search_all={this.state.incident_search_all}
            fulfillment_roles_all={this.state.fulfillment_roles_all}
            state_carousel_index={this.state.state_carousel_index}
            only_viewer_role_enabled={this.state.only_viewer_role_enabled}
            manager_role_enabled={this.state.manager_role_enabled}
            showRightPanel={this.showRightPanel}
            selectedRowIndex={this.state.selectedRowIndex}
            rightPanelData={this.state.rightPanelData}
            setConsoleRefreshButton={this.setConsoleRefreshButton}
            isWalkUpEnabled={this.props.walkupEnabled.walkupEnabled}
          />

          {/* Xsm Quick View Detailt Header Section end*/}

          <PanelGroup direction="horizontal" className={allDrpdownState && (dataLengthState || loaderState) ? "overflow-clip" : ""}>
            <Panel
              id="sidebar"
              minSize={33}
              order={1}
              defaultSize={this.state.isRightSideVisible ? 67 : 100}
              onResize={(size) => this.setState({ panelResize: size })}
              className={`${allDrpdownState && (dataLengthState || loaderState) ? "overflow-clip" : ""} ${this.state.isRightSideVisible ? "quickShadow isShowLeftPanel rwLeftPart" : "quickShadow rwLeftPart"}`}
            >
              <div className="">
                {/* Xsm Quick View List Section Start*/}

                <React.Suspense fallback={<ListLoader />}>
                  {this.state.filterTabActive === 4?
                    <SlaList
                      slaListViewVal={this.state.slaListViewVal}
                      slaRowIndex={this.props.slaRowIndex}
                      setSlaRowIndex={this.props.setSlaRowIndex}
                      Limit = {this.state.Limit}
                      setLimit = {this.setLimit}
                      page={this.state.page}
                      setPage = {this.setPage}
                      module={this.state.module}
                    />
                    
                    :
                    <XsmQuickViewTable
                      groupID={this.state.groupID}
                      service_desk_role_enabled={this.state.service_desk_role_enabled}
                      critical_incident_manager_enabled={this.state.critical_incident_manager_enabled}
                      task_viewer_role_enabled={this.state.task_viewer_role_enabled}
                      // filterTabActive={this.state.filterTabActive}
                      modulesEnabled={this.state.modulesEnabled}
                      core_user_role_enabled={this.state.core_user_role_enabled}
                      setAiAction={this.setAiAction}
                      aiAction={this.state.aiAction}
                      showSLAstatus={this.state.showSLAstatus}
                      showRequestID={this.state.showRequestID}
                      core_d2c_roles_enabled={this.state.core_d2c_roles_enabled}
                      bulkUpdateData={this.state.bulkUpdateData}
                      showBulkUpdateInModule={this.showBulkUpdateInModule}
                      bulkUpdateHandler={this.bulkUpdateHandler}
                      bulkUpdateAction={this.state.bulkUpdateAction}
                      selectedModuleName={this.state.selectedModuleName}
                      setAppointmentAction={this.setAppointmentAction}
                      appointmentAction={this.state.appointmentAction}
                      groupFilterApplied={this.state.groupFilterApplied}
                      changeImplentationRole={this.state.changeImplentationRole}
                      setGroupFilterApplied={this.setGroupFilterApplied}
                      refreshClickFun={this.refreshClickFun}
                      isRefreshClick={this.state.isRefreshClick}
                      selectAllLabelFun={this.selectAllLabelFun}
                      isTaskStatusAllLabel={this.state.isTaskStatusAllLabel}
                      isTaskWorkItemAllLabel={this.state.isTaskWorkItemAllLabel}
                      isTaskAssignedToGroupAllLabel={
                        this.state.isTaskAssignedToGroupAllLabel
                      }
                      isFulFillmentStatusAllLabel={
                        this.state.isFulFillmentStatusAllLabel
                      }
                      isFulFillmentAsgGroupAllLabel={
                        this.state.isFulFillmentAsgGroupAllLabel
                      }
                      isIncidentStatusAllLabel={
                        this.state.isIncidentStatusAllLabel
                      }
                      isIncidentPriorityAllLabel={
                        this.state.isIncidentPriorityAllLabel
                      }
                      isIncidentSupportGroupAllLabel={
                        this.state.isIncidentSupportGroupAllLabel
                      }
                      isIncidentCIMGroupsAllLabel={
                        this.state.isIncidentCIMGroupsAllLabel
                      }
                      isIncidentCriticalityAllLabel={
                        this.state.isIncidentCriticalityAllLabel
                      }
                      isProStatusAllLabel={this.state.isProStatusAllLabel}
                      isProPriorityAllLabel={this.state.isProPriorityAllLabel}
                      isProManagGroupAllLabel={this.state.isProManagGroupAllLabel}
                      isChangeStatusAllLabel={this.state.isChangeStatusAllLabel}
                      isChangeManagGroupAllLabel={
                        this.state.isChangeManagGroupAllLabel
                      }
                      isChangeImpleGrpAllLabel={
                        this.state.isChangeImpleGrpAllLabel
                      }
                      isChangeTypeAllLabel={this.state.isChangeTypeAllLabel}
                      multiSelectOpenFun={this.multiSelectOpenFun}
                      isMultiOpen={this.state.isMultiOpen}
                      isStatusOpen={this.state.isStatusOpen}
                      isWorkItemOpen={this.state.isWorkItemOpen}
                      isAssignedToGroupOpen={this.state.isAssignedToGroupOpen}
                      isFulFillmentStatusOpen={this.state.isFulFillmentStatusOpen}
                      only_viewer_role_enabled={this.state.only_viewer_role_enabled}
                      isFulFillmentAsgGroupOpen={
                        this.state.isFulFillmentAsgGroupOpen
                      }
                      isIncidentStatusOpen={this.state.isIncidentStatusOpen}
                      isIncidentPriorityOpen={this.state.isIncidentPriorityOpen}
                      isIncidentSupportGroupOpen={
                        this.state.isIncidentSupportGroupOpen
                      }
                      isIncidentCIMGroupsOpen={this.state.isIncidentCIMGroupsOpen}
                      isIncidentCriticalityOpen={
                        this.state.isIncidentCriticalityOpen
                      }
                      isChangeStatusOpen={this.state.isChangeStatusOpen}
                      isChangeManagGroupOpen={this.state.isChangeManagGroupOpen}
                      isChangeImpleGrpOpen={this.state.isChangeImpleGrpOpen}
                      isChangeTypeOpen={this.state.isChangeTypeOpen}
                      isProStatusOpen={this.state.isProStatusOpen}
                      isProPriorityOpen={this.state.isProPriorityOpen}
                      isProManagGroupOpen={this.state.isProManagGroupOpen}
                      dateRangeLabel={this.dateRangeLabel}
                      userAllGroups={this.state.userAllGroups}
                      all_groups_for_task={this.state.all_groups_for_task}
                      only_task_viewer_role_enabled={
                        this.state.only_task_viewer_role_enabled
                      }
                      manager_role_enabled={this.state.manager_role_enabled}
                      isProblemInvestigator={this.isProblemInvestigator}
                      getGroupValues={this.getGroupValues}
                      role_id={this.state.role_id}
                      isFetchingDetails={this.props.isFetchingDetails}
                      breakFixGraphView={breakFixGraphView}
                      breakFixDateRangeFilter={breakFixDateRangeFilter}
                      breakFixSearchParams={breakFixSearchParams}
                      gettingRoleId={this.gettingRoleId}
                      changeToGraphView={this.changeToGraphView}
                      setGraphSearchParams={this.setGraphSearchParams}
                      onClickGraphSideBarData={this.setOnClickGraphSideBarData}
                      taskGraphView={taskGraphView}
                      taskDateRangeFilter={taskDateRangeFilter}
                      taskSearchParams={taskSearchParams}
                      investigationGraphView={investigationGraphView}
                      investigationDateRangeFilter={investigationDateRangeFilter}
                      investigationSearchParams={investigationSearchParams}
                      changeGraphView={changeGraphView}
                      changeDateRangeFilter={changeDateRangeFilter}
                      changeSearchParams={changeSearchParams}
                      fullfilmentGraphView={fullfilmentGraphView}
                      fullfilmentDateRangeFilter={fullfilmentDateRangeFilter}
                      fullfilmentSearchParams={fullfilmentSearchParams}
                      getInvestigationDetails={this.getInvestigationDetails}
                      getChangeDetails={this.getChangeDetails}
                      getBreakFixDetails={this.getBreakFixDetails}
                      getTaskDetails={this.getTaskDetails}
                      buildSearchParamsForBreakfixQV={
                        this.buildSearchParamsForBreakfixQV
                      }
                      buildSearchParamsForFulfillmentQV={
                        this.buildSearchParamsForFulfillmentQV
                      }
                      messageForDataAvailability={
                        this.state.messageForDataAvailability
                      }
                      translator={this.props.tr}
                      myTaskDetailsList={this.props.myTaskDetailsList}
                      onSortInvAsc={this.onSortInvAsc}
                      onSortInvDesc={this.onSortInvDesc}
                      faCustomInvAsc={this.state.faCustomInvAsc}
                      faCustomInvDesc={this.state.faCustomInvDesc}
                      onSortChgAsc={this.onSortChgAsc}
                      onSortChgDesc={this.onSortChgDesc}
                      onSortTaskAsc={this.onSortTaskAsc}
                      onSortTaskDesc={this.onSortTaskDesc}
                      faCustomChgAsc={this.state.faCustomChgAsc}
                      faCustomChgDesc={this.state.faCustomChgDesc}
                      onSortAsc={this.onSortAsc}
                      onSortDesc={this.onSortDesc}
                      faCustomAsc={this.state.faCustomAsc}
                      faCustomDesc={this.state.faCustomDesc}
                      onSortFulAsc={this.onSortFulAsc}
                      onSortFulDesc={this.onSortFulDesc}
                      faCustomFulDesc={this.faCustomFulDesc}
                      faCustomFulAsc={this.state.faCustomFulAsc}
                      faCustomTaskAsc={this.state.faCustomTaskAsc}
                      faCustomTaskDesc={this.state.faCustomTaskDesc}
                      group_id={this.state.group_id}
                      onchangeTaskTableSize={this.onchangeTaskTableSize}
                      taskTableSize={this.state.taskTableSize}
                      tableSizeBreakfix={this.state.tableSizeBreakfix}
                      tableCurrentPageBreakfix={
                        this.state.tableCurrentPageBreakfix
                      }
                      changeBFCurrentPage={this.changeBFCurrentPage}
                      changeBFPageSize={this.changeBFPageSize}
                      tableSizeFullfilment={this.state.tableSizeFullfilment}
                      tableCurrentPageFullfilment={
                        this.state.tableCurrentPageFullfilment
                      }
                      changeFulfilmentCurrentPage={
                        this.changeFulfilmentCurrentPage
                      }
                      changeFulfilmentPageSize={this.changeFulfilmentPageSize}
                      tableSizeInvest={this.state.tableSizeInvest}
                      tableCurrentPageInvest={this.state.tableCurrentPageInvest}
                      changeInvestigationCurrentPage={
                        this.changeInvestigationCurrentPage
                      }
                      changeInvestigationPageSize={
                        this.changeInvestigationPageSize
                      }
                      buildSearchParamsForInvestigationQV={
                        this.buildSearchParamsForInvestigationQV
                      }
                      tableSizeChangeMgmt={this.state.tableSizeChangeMgmt}
                      tableCurrentPageChangeMgmt={
                        this.state.tableCurrentPageChangeMgmt
                      }
                      changeChangeMgmtCurrentPage={
                        this.changeChangeMgmtCurrentPage
                      }
                      changeChangeMgmtPageSize={this.changeChangeMgmtPageSize}
                      buildSearchParamsForChangeQV={
                        this.buildSearchParamsForChangeQV
                      }
                      changeSkiprows={this.changeSkiprows}
                      buildSearchParamsForTaskQV={this.buildSearchParamsForTaskQV}
                      valueSearchInputFulfilment={
                        this.state.valueSearchInputFulfilment
                      }
                      changeSearchInputFulfilment={
                        this.changeSearchInputFulfilment
                      }
                      setSelectedSearchFilterFulfilment={
                        this.setSelectedSearchFilterFulfilment
                      }
                      searchFilterFulfilment={this.state.searchFilterFulfilment}
                      valueSearchInputBreakFix={
                        this.state.valueSearchInputBreakFix
                      }
                      changeSearchInputBreakFix={this.changeSearchInputBreakFix}
                      setSelectedSearchFilterBreakFix={
                        this.setSelectedSearchFilterBreakFix
                      }
                      searchFilterBreakfix={this.state.searchFilterBreakFix}
                      checkSearchtextFieldBreakFix={
                        this.state.checkSearchtextFieldBreakFix
                      }
                      valueStatusSearchInputBreakFix={
                        this.state.valueStatusSearchInputBreakFix
                      }
                      checkSearchtextFieldFulfilment={
                        this.state.checkSearchtextFieldFulfilment
                      }
                      valueStatusSearchInputFulfilment={
                        this.state.valueStatusSearchInputFulfilment
                      }
                      defaultvalue={this.state.defaultvalue}
                      isSearchFulfilment={this.isSearchFulfilment}
                      isSearchBreakFix={this.isSearchBreakFix}
                      enableSearchFulfilment={this.state.enableSearchFulfilment}
                      enableSearchBreakFix={this.state.enableSearchBreakFix}
                      changeSearchInputChangeMgmt={
                        this.changeSearchInputChangeMgmt
                      }
                      setSelectedSearchFilterChangeMgmt={
                        this.setSelectedSearchFilterChangeMgmt
                      }
                      valueSearchInputChangeMgmt={
                        this.state.valueSearchInputChangeMgmt
                      }
                      searchFilterChangeMgmt={this.state.searchFilterChangeMgmt}
                      checkSearchtextFieldChangeMgmt={
                        this.state.checkSearchtextFieldChangeMgmt
                      }
                      valueStatusSearchInputChangeMgmt={
                        this.state.valueStatusSearchInputChangeMgmt
                      }
                      isSearchChangeMgmt={this.isSearchChangeMgmt}
                      enableSearchChangeMgmt={this.state.enableSearchChangeMgmt}
                      changeSearchInputInvestigation={
                        this.changeSearchInputInvestigation
                      }
                      setSelectedSearchFilterInvestigation={
                        this.setSelectedSearchFilterInvestigation
                      }
                      valueSearchInputInvestigation={
                        this.state.valueSearchInputInvestigation
                      }
                      searchFilterInvestigation={
                        this.state.searchFilterInvestigation
                      }
                      checkSearchtextFieldInvestigation={
                        this.state.checkSearchtextFieldInvestigation
                      }
                      valueStatusSearchInputInvestigation={
                        this.state.valueStatusSearchInputInvestigation
                      }
                      isSearchInvestigation={this.isSearchInvestigation}
                      enableSearchInvestigation={
                        this.state.enableSearchInvestigation
                      }
                      valueSearchInputTasks={this.state.valueSearchInputTasks}
                      changeSearchInputTasks={this.changeSearchInputTasks}
                      valueStatusSearchInputTasks={
                        this.state.valueStatusSearchInputTasks
                      }
                      searchFilterTasks={this.state.searchFilterTasks}
                      setSelectedSearchFilterTasks={
                        this.setSelectedSearchFilterTasks
                      }
                      checkSearchtextFieldTasks={
                        this.state.checkSearchtextFieldTasks
                      }
                      enableSearchTasks={this.state.enableSearchTasks}
                      isSearchTasks={this.isSearchTasks}
                      checkDateField={this.state.checkDateField}
                      serviceBasedFlag={this.state.serviceBasedFlag}
                      serviceBasedFlagtagged={this.state.serviceBasedFlagtagged}
                      fromDate={this.state.fromDate}
                      toDate={this.state.toDate}
                      onToDateSelect={this.onToDateSelect}
                      onFromDateSelect={this.onFromDateSelect}
                      date_format={this.state.date_format}
                      isDateField={this.state.isDateField}
                      showRightSide={this.showRightSide}
                      isRightSideVisible={this.state.isRightSideVisible}
                      rowIndexBrk={this.state.rowIndexBrk}
                      consumerCompanyName={this.state.consumerCompanyName}
                      status={this.state.status}
                      priorityId={this.state.priorityId}
                      impactedUsername={this.state.impactedUsername}
                      createdByName={this.state.createdByName}
                      reportedThrough={this.state.reportedThrough}
                      requesterLocationName={this.state.requesterLocationName}
                      sortOrderBrk={this.state.sortOrderBrk}
                      sortOnColumnBrk={this.state.sortOnColumnBrk}
                      searchKeyBrk={this.state.searchKeyBrk}
                      searchValueBrk={this.state.searchValueBrk}
                      isClearAllFilterBrk={this.state.isClearAllFilterBrk}
                      displayNumberSearchInputBrk={
                        this.state.displayNumberSearchInputBrk
                      }
                      workItemIdSearchInputBrk={
                        this.state.workItemIdSearchInputBrk
                      }
                      // ciServiceNameSearchInputBrk={this.state.ciServiceNameSearchInputBrk}
                      serviceNameSearchInputBrk={
                        this.state.serviceNameSearchInputBrk
                      }
                      ciNameSearchInputBrk={this.state.ciNameSearchInputBrk}
                      companySearchInputBrk={this.state.companySearchInputBrk}
                      summarySearchInputBrk={this.state.summarySearchInputBrk}
                      requestedForSearchInputBrk={
                        this.state.requestedForSearchInputBrk
                      }
                      supportIndividualSearchInputBrk={
                        this.state.supportIndividualSearchInputBrk
                      }
                      cimindividualSearchInputBrk={
                        this.state.cimindividualSearchInputBrk
                      }
                      startDateBrk={this.state.startDateBrk}
                      endDateBrk={this.state.endDateBrk}
                      selectedStatusValueBrk={this.state.selectedStatusValueBrk}
                      selectedPriorityValueBrk={
                        this.state.selectedPriorityValueBrk
                      }
                      selectedSupportGroupValueBrk={
                        this.state.selectedSupportGroupValueBrk
                      }
                      selectedCriticality={this.state.selectedCriticality}
                      selectedCIMGroups={this.state.selectedCIMGroups}
                      // statusRefBrk={this.state.statusRefBrk}
                      // priorityRefBrk={this.state.priorityRefBrk}
                      // supportGroupRefBrk={this.state.supportGroupRefBrk}
                      patternListBrk={this.state.patternListBrk}
                      showBrkActiveRow={this.showBrkActiveRow}
                      setBrkSortOrder={this.setBrkSortOrder}
                      setBrkSearchKeyValue={this.setBrkSearchKeyValue}
                      isBrkClearAllFilterFunc={this.isBrkClearAllFilterFunc}
                      setBrkdisplayNumberInputValues={
                        this.setBrkdisplayNumberInputValues
                      }
                      setBrkCiServiceNameInputValues={
                        this.setBrkCiServiceNameInputValues
                      }
                      setBrkCompanyInputValues={this.setBrkCompanyInputValues}
                      setBrkSummaryInputValues={this.setBrkSummaryInputValues}
                      setBrkRequestedForInputValues={
                        this.setBrkRequestedForInputValues
                      }
                      setBrkSupportIndividualInputValues={
                        this.setBrkSupportIndividualInputValues
                      }
                      setBrkCIMindividualInputValues={
                        this.setBrkCIMindividualInputValues
                      }
                      setBrkStartAndEndDate={this.setBrkStartAndEndDate}
                      setBrkSelectedStatusValue={this.setBrkSelectedStatusValue}
                      setBrkSelectedPriorityValue={
                        this.setBrkSelectedPriorityValue
                      }
                      setBrkSelectedSupportGroupValue={
                        this.setBrkSelectedSupportGroupValue
                      }
                      setBrkSelectedCriticalityValue={
                        this.setBrkSelectedCriticalityValue
                      }
                      setBrkSElectedCIMGroupsValue={
                        this.setBrkSElectedCIMGroupsValue
                      }
                      setBrkStatusRef={this.setBrkStatusRef}
                      setBrkPriorityRef={this.setBrkPriorityRef}
                      setBrkSupportGroupRef={this.setBrkSupportGroupRef}
                      setStatusSearchInputBrk={this.setStatusSearchInputBrk}
                      setPrioritySearchInputBrk={this.setStatusSearchInputBrk}
                      setSpGroupnameSearchInputBrk={
                        this.setSpGroupnameSearchInputBrk
                      }
                      statusSearchInputBrk={this.state.statusSearchInputBrk}
                      prioritySearchInputBrk={this.state.prioritySearchInputBrk}
                      spGroupnameSearchInputBrk={
                        this.state.spGroupnameSearchInputBrk
                      }
                      setBrkReportedOnRef={this.setBrkReportedOnRef}
                      changeGroups={this.props.changeGroups}
                      fulfillmentGroupsData={this.props.fulfillmentGroupsData}
                      fulfillmenRowIndex={this.state.fulfillmenRowIndex}
                      fulfillmentSearchKey={this.state.fulfillmentSearchKey}
                      fulfillmentSearchValue={this.state.fulfillmentSearchValue}
                      fulfillmentPattern={this.state.fulfillmentPattern}
                      fulfillmentSortOrder={this.state.fulfillmentSortOrder}
                      fulfillmentSortOnColumn={this.state.fulfillmentSortOnColumn}
                      fulfillmentGroup={this.state.fulfillmentGroup}
                      changemngmtGroup={this.state.changemngmtGroup}
                      implemntGroup={this.state.implemntGroup}
                      fulGrp={this.state.fulGrp}
                      fulfillmentUser={this.state.fulfillmentUser}
                      fulfillmentStatus={this.state.fulfillmentStatus}
                      fulfillmentIsClearAllFilter={
                        this.state.fulfillmentIsClearAllFilter
                      }
                      fulfillmentDisplayNumberSearchInput={
                        this.state.fulfillmentDisplayNumberSearchInput
                      }
                      fulfillmentWorkItemIdSearchInput={
                        this.state.fulfillmentWorkItemIdSearchInput
                      }
                      fulfillmentCiServiceNameSearchInput={
                        this.state.fulfillmentCiServiceNameSearchInput
                      }
                      fulfillmentRequesterNameSearchInput={
                        this.state.fulfillmentRequesterNameSearchInput
                      }
                      fulfillmentCompanyNameSearchInput={
                        this.state.fulfillmentCompanyNameSearchInput
                      }
                      fulfillmentSummarySearchInput={this.state.fulfillmentSummarySearchInput}
                      fulfillerNameSearchInput={
                        this.state.fulfillerNameSearchInput
                      }
                      fulfillmentSelectedStatusValue={
                        this.state.fulfillmentSelectedStatusValue
                      }
                      fulfillmentGroupValue={this.state.fulfillmentGroupValue}
                      changeManagementGroupValue={
                        this.state.changeManagementGroupValue
                      }
                      impleGroupValue={this.state.impleGroupValue}
                      fulfillmentReportedStartDate={
                        this.state.fulfillmentReportedStartDate
                      }
                      fulfillmentReportedEndDate={
                        this.state.fulfillmentReportedEndDate
                      }
                      fulfillmentReportedDateRef={
                        this.state.fulfillmentReportedDateRef
                      }
                      showFulfillmentActiveRow={this.showFulfillmentActiveRow}
                      setFulSearchKeyValue={this.setFulSearchKeyValue}
                      setFulSortOrder={this.setFulSortOrder}
                      updateFulStatus={this.updateFulStatus}
                      defaultFulfillmentStatusSelected={
                        this.state.defaultFulfillmentStatusSelected
                      }
                      defaultBreakfixStatusSelected={
                        this.state.defaultBreakfixStatusSelected
                      }
                      defaultInvestigationStatusSelected={
                        this.state.defaultInvestigationStatusSelected
                      }
                      defaultChangeMgmtStatusSelected={
                        this.state.defaultChangeMgmtStatusSelected
                      }
                      setDefaultFulfillmentStatusSelected={
                        this.setDefaultFulfillmentStatusSelected
                      }
                      setDefaultBreakfixStatusSelected={
                        this.setDefaultBreakfixStatusSelected
                      }
                      setDefaultInvestigationStatusSelected={
                        this.setDefaultInvestigationStatusSelected
                      }
                      setDefaultChangeMgmtStatusSelected={
                        this.setDefaultChangeMgmtStatusSelected
                      }
                      updateFulGroup={this.updateFulGroup}
                      updateChangemangmtGroup={this.updateChangemangmtGroup}
                      updateImplmtGroup={this.updateImplmtGroup}
                      updateUserGroup={this.updateUserGroup}
                      isClearAllFilterFuncFulfillment={
                        this.isClearAllFilterFuncFulfillment
                      }
                      setFuldisplayNumberInputValues={
                        this.setFuldisplayNumberInputValues
                      }
                      setFulCiServiceNameInputValues={
                        this.setFulCiServiceNameInputValues
                      }
                      setFulRequesterInputValues={this.setFulRequesterInputValues}
                      setFulReportedStartAndEndDate={
                        this.setFulReportedStartAndEndDate
                      }
                      setFulSelectedStatusValue={this.setFulSelectedStatusValue}
                      setFulSelectedGroupValue={this.setFulSelectedGroupValue}
                      setChangMangmtGroupValue={this.setChangMangmtGroupValue}
                      setimpleGroupValue={this.setimpleGroupValue}
                      setFulReportedRef={this.setFulReportedRef}
                      setFulfillerInputValues={this.setFulfillerInputValues}
                      setFulCompanyNameInputValues={
                        this.setFulCompanyNameInputValues
                      }
                      setFulSummaryInputValues={this.setFulSummaryInputValues}
                      updateParameterBrk={this.updateParameterBrk}
                      statusValueBrk={this.state.statusValueBrk}
                      priorityValueBrk={this.state.priorityValueBrk}
                      spGroupValueBrk={this.state.spGroupValueBrk}
                      criticalityValueBrk={this.state.criticalityValueBrk}
                      cimgroupsValueBrk={this.state.cimgroupsValueBrk}
                      activePanelKey={this.state.activePanelKey}
                      handlePanelClick={this.handlePanelClick}
                      changeRowIndex={this.state.changeRowIndex}
                      showChangeActiveRow={this.showChangeActiveRow}
                      changeSearchKey={this.state.changeSearchKey}
                      changeSearchValue={this.state.changeSearchValue}
                      changePattern={this.state.changePattern}
                      changeSortOrder={this.state.changeSortOrder}
                      changeSortOnColumn={this.state.changeSortOnColumn}
                      changeStatus={this.state.changeStatus}
                      changeType={this.state.changeType}
                      changeDisplayNumberSearchInput={
                        this.state.changeDisplayNumberSearchInput
                      }
                      changeWorkItemIdSearchInput={
                        this.state.changeWorkItemIdSearchInput
                      }
                      changeCiServiceNameSearchInput={
                        this.state.changeCiServiceNameSearchInput
                      }
                      changeCompanyNameSearchInput={
                        this.state.changeCompanyNameSearchInput
                      }
                      changeSummaryNameSearchInput={
                        this.state.changeSummaryNameSearchInput
                      }
                      changeRequesterNameSearchInput={
                        this.state.changeRequesterNameSearchInput
                      }
                      changeSelectedStatusValue={
                        this.state.changeSelectedStatusValue
                      }
                      changeTypeValue={this.state.changeTypeValue}
                      changeReportedStartDate={this.state.changeReportedStartDate}
                      changeReportedEndDate={this.state.changeReportedEndDate}
                      changeExpectedStartDate={this.state.changeExpectedStartDate}
                      changeExpectedEndDate={this.state.changeExpectedEndDate}
                      changeCABStartDate={this.state.changeCABStartDate}
                      changeCABEndDate={this.state.changeCABEndDate}
                      changeReportedDateRef={this.state.changeReportedDateRef}
                      changeExpectedDateRef={this.state.changeExpectedDateRef}
                      changeCABDateRef={this.state.changeCABDateRef}
                      changeIsClearAllFilter={this.state.changeIsClearAllFilter}
                      setChangeSearchKeyValue={this.setChangeSearchKeyValue}
                      setChangeSortOrder={this.setChangeSortOrder}
                      setChangeReportedRef={this.setChangeReportedRef}
                      setChangeExpectedDateRef={this.setChangeExpectedDateRef}
                      setChangeCABDateRef={this.setChangeCABDateRef}
                      setChangeSelectedStatusValue={
                        this.setChangeSelectedStatusValue
                      }
                      setSelectedChangeTypeValue={this.setSelectedChangeTypeValue}
                      isClearAllFilterFuncChange={this.isClearAllFilterFuncChange}
                      setChangedisplayNumberInputValues={
                        this.setChangedisplayNumberInputValues
                      }
                      setChangeCiServiceNameInputValues={
                        this.setChangeCiServiceNameInputValues
                      }
                      setChangeCompanyNameInputValues={
                        this.setChangeCompanyNameInputValues
                      }
                      setChangeSummaryNameInputValues={
                        this.setChangeSummaryNameInputValues
                      }
                      setChangeRequesterInputValues={
                        this.setChangeRequesterInputValues
                      }
                      setChangeReportedStartAndEndDate={
                        this.setChangeReportedStartAndEndDate
                      }
                      setChangeExpectedStartAndEndDate={
                        this.setChangeExpectedStartAndEndDate
                      }
                      setChangeCABStartAndEndDate={
                        this.setChangeCABStartAndEndDate
                      }
                      updateChangeStatus={this.updateChangeStatus}
                      updateChangeType={this.updateChangeType}
                      investigationGroups={this.props.investigationGroups}
                      rowIndexInv={this.state.rowIndexInv}
                      sortOrderInv={this.state.sortOrderInv}
                      sortOnColumnInv={this.state.sortOnColumnInv}
                      searchKeyInv={this.state.searchKeyInv}
                      searchValueInv={this.state.searchValueInv}
                      isClearAllFilterInv={this.state.isClearAllFilterInv}
                      displayNumberSearchInputInv={
                        this.state.displayNumberSearchInputInv
                      }
                      workItemIdSearchInputInv={
                        this.state.workItemIdSearchInputInv
                      }
                      ciNameSearchInputInv={this.state.ciNameSearchInputInv}
                      companySearchInputInv={this.state.companySearchInputInv}
                      summarySearchInputInv={this.state.summarySearchInputInv}
                      requestedBySearchInputInv={
                        this.state.requestedBySearchInputInv
                      }
                      problemManagerSearchInputInv={
                        this.state.problemManagerSearchInputInv
                      }
                      startDateInv={this.state.startDateInv}
                      endDateInv={this.state.endDateInv}
                      selectedStatusValueInv={this.state.selectedStatusValueInv}
                      selectedPriorityValueInv={
                        this.state.selectedPriorityValueInv
                      }
                      selectedProblemGroupValueInv={
                        this.state.selectedProblemGroupValueInv
                      }
                      patternListInv={this.state.patternListInv}
                      showInvActiveRow={this.showInvActiveRow}
                      setInvSortOrder={this.setInvSortOrder}
                      setInvSearchKeyValue={this.setInvSearchKeyValue}
                      isInvClearAllFilterFunc={this.isInvClearAllFilterFunc}
                      setInvdisplayNumberInputValues={
                        this.setInvdisplayNumberInputValues
                      }
                      setInvCiServiceNameInputValues={
                        this.setInvCiServiceNameInputValues
                      }
                      setInvCompanyInputValues={this.setInvCompanyInputValues}
                      setInvSummaryInputValues={this.setInvSummaryInputValues}
                      setInvRequestedByInputValues={
                        this.setInvRequestedByInputValues
                      }
                      setInvProblemManagerInputValues={
                        this.setInvProblemManagerInputValues
                      }
                      setInvStartAndEndDate={this.setInvStartAndEndDate}
                      setInvSelectedStatusValue={this.setInvSelectedStatusValue}
                      setInvSelectedPriorityValue={
                        this.setInvSelectedPriorityValue
                      }
                      setInvSelectedSupportGroupValue={
                        this.setInvSelectedSupportGroupValue
                      }
                      setStatusSearchInputInv={this.setStatusSearchInputInv}
                      setPrioritySearchInputInv={this.setStatusSearchInputInv}
                      setSpGroupnameSearchInputInv={
                        this.setSpGroupnameSearchInputInv
                      }
                      statusSearchInputInv={this.state.statusSearchInputInv}
                      prioritySearchInputInv={this.state.prioritySearchInputInv}
                      spGroupnameSearchInputInv={
                        this.state.spGroupnameSearchInputInv
                      }
                      setInvReportedOnRef={this.setInvReportedOnRef}
                      reprotedOnDateRangePickerRefInv={this.inputRef}
                      updateParameterInv={this.updateParameterInv}
                      statusValueInv={this.state.statusValueInv}
                      priorityValueInv={this.state.priorityValueInv}
                      spGroupValueInv={this.state.spGroupValueInv}
                      showTaskActiveRow={this.showTaskActiveRow}
                      taskRowIndex={this.state.taskRowIndex}
                      taskGroupData={this.props.taskGroupData}
                      taskNumberSearchInput={this.state.taskNumberSearchInput}
                      workItemNumberSearchInput={
                        this.state.workItemNumberSearchInput
                      }
                      taskSummarySearchInput={this.state.taskSummarySearchInput}
                      taskAssignedToSearchInput={
                        this.state.taskAssignedToSearchInput
                      }
                      setChangeTaskNumberInputValues={
                        this.setChangeTaskNumberInputValues
                      }
                      setChangeworkItemNumberInputValues={
                        this.setChangeworkItemNumberInputValues
                      }
                      setChangeTaskSummaryInputValues={
                        this.setChangeTaskSummaryInputValues
                      }
                      setChangeTaskAssignedToInputValues={
                        this.setChangeTaskAssignedToInputValues
                      }
                      taskServiceNameSearchInput={
                        this.state.taskServiceNameSearchInput
                      }
                      taskciNameSearchInput={this.state.taskciNameSearchInput}
                      setChangeTaskServiceNameInputValues={
                        this.setChangeTaskServiceNameInputValues
                      }
                      setChangeTaskCompanyNameInputValues={this.setChangeTaskCompanyNameInputValues}
                      taskCompanyNameSearchInput={this.state.taskCompanyNameSearchInput}
                      taskStartDate={this.state.taskStartDate}
                      taskEndDate={this.state.taskEndDate}
                      taskDateRef={this.state.taskDateRef}
                      setTaskStartAndEndDate={this.setTaskStartAndEndDate}
                      setTaskDateRef={this.setTaskDateRef}
                      setTaskSelectedGroupValue={this.setTaskSelectedGroupValue}
                      taskGroupValue={this.state.taskGroupValue}
                      updateTaskGroup={this.updateTaskGroup}
                      taskGroup={this.state.taskGroup}
                      taskTypeValue={this.state.taskTypeValue}
                      taskType={this.state.taskType}
                      setTaskSelectedTaskValue={this.setTaskSelectedTaskValue}
                      updateTaskType={this.updateTaskType}
                      taskStatusValue={this.state.taskStatusValue}
                      taskStatus={this.state.taskStatus}
                      setTaskSelectedStatusValue={this.setTaskSelectedStatusValue}
                      updateTaskStatus={this.updateTaskStatus}
                      setTaskSearchKeyValue={this.setTaskSearchKeyValue}
                      taskSearchValueParams={this.state.taskSearchValueParams}
                      statusName={this.state.statusName}
                      TaskRequesterName={this.state.TaskRequesterName}
                      hopCount={this.state.hopCount}
                      actualStartDate={this.state.actualStartDate}
                      actualEndDate={this.state.actualEndDate}
                      taskIsClearAllFilter={this.state.taskIsClearAllFilter}
                      isClearAllFilterFuncTask={this.isClearAllFilterFuncTask}
                      insideParams={insideParams}
                      ciName={this.state.ciName}
                      serviceName={this.state.serviceName}
                      updateGroup={this.updateGroup}
                      filterTabActive={this.state.filterTabActive}
                      user_id={this.state.user_id}
                      full_name={this.state.full_name}
                      selectedGlobalDropdown={this.state.selectedGlobalDropdown}
                      calculatedTypeToSend={this.state.calculatedTypeToSend}
                      enteredGlobalSearchValue={
                        this.state.enteredGlobalSearchValue
                      }
                      retrivedGlobalSearchEmployeeValue={
                        this.state.retrivedGlobalSearchEmployeeValue
                      }
                      setOnFulfillmentFilterSelection={
                        this.setOnFulfillmentFilterSelection
                      }
                      setOnBreakfixFilterSelection={
                        this.setOnBreakfixFilterSelection
                      }
                      setOnInvestigationFilterSelection={
                        this.setOnInvestigationFilterSelection
                      }
                      setOnChangeMgmtFilterSelection={
                        this.setOnChangeMgmtFilterSelection
                      }
                      isFulfillmentFilterSelected={
                        this.state.isFulfillmentFilterSelected
                      }
                      isBreakfixFilterSelected={
                        this.state.isBreakfixFilterSelected
                      }
                      isInvestigationFilterSelected={
                        this.state.isInvestigationFilterSelected
                      }
                      isChangeMgmtFilterSelected={
                        this.state.isChangeMgmtFilterSelected
                      }
                      isWIBGlobalSearch={this.state.isWIBGlobalSearch}
                      isBrkResetSelected={this.state.isBrkResetSelected}
                      setBrkResetSelected={this.setBrkResetSelected}
                      setImplementationMgmtgroupSelected={
                        this.setImplementationMgmtgroupSelected
                      }
                      setChangeMgmtgroupSelected={this.setChangeMgmtgroupSelected}
                      isImplementationMgmtgroupSelected={
                        this.state.isImplementationMgmtgroupSelected
                      }
                      isChangeMgmtgroupSelected={
                        this.state.isChangeMgmtgroupSelected
                      }
                      panelResize={this.state.panelResize}
                      multiOpen={this.multiOpen}
                      showRightPanel={this.showRightPanel}
                      selectedRowIndex={this.state.selectedRowIndex}
                      rightPanelData={this.state.rightPanelData}
                      consoleRefreshButton={this.state.consoleRefreshButton}
                      setConsoleRefreshButton={this.setConsoleRefreshButton}
                    />
                }
                 
                </React.Suspense>

                {/* Xsm Quick View List Section End*/}
              </div>
            </Panel>
            {this.state.isRightSideVisible ? (
              <>
                <PanelResizeHandle className="isShowLeftPanel resizeHandle">
                  <div className="outlne">
                    <div className="handIcn">
                      <i className="fa fa-chevron-left" aria-hidden="true"></i>
                    </div>
                  </div>
                </PanelResizeHandle>
                <Panel minSize={33} order={2} defaultSize={33}>
                  <div className="stickyArea rBoxStyle">
                    {/* Xsm Quick View Short Description Section Start*/}

                    {(this.state?.bulkUpdateData && Array.isArray(this.state.bulkUpdateData) && this.state.bulkUpdateData.length > 0) ? 
                    <BulkUpdate 
                      bulkUpdateData={this.state.bulkUpdateData} 
                      // module='incident' 
                      module={this.state.selectedModuleName}
                      handler={(type = '', data = []) => {
                        if(type == 'clearSection'){
                          this.bulkUpdateHandler(type, []);
                        }
                        if(type == 'bulkUpdateInProgress' || type == 'bulkUpdateComplete'){
                          this.bulkUpdateHandler(type);
                        }
                        if(type == 'fetchListData'){
                          if(this.state.selectedModuleName == 'breakfix'){
                            this.props.loadBreakFixQuickView(...data);
                          }
                          else if(this.state.selectedModuleName == 'fullfilment'){
                            this.props.loadFulfillmentList(...data);
                          }
                          else if(this.state.selectedModuleName == 'investigation'){
                            this.props.loadInvestigationList(...data);
                          }
                          else if(this.state.selectedModuleName == 'changeManagement'){
                            this.props.loadChangeManagementmList(...data);
                          }else if(this.state.selectedModuleName == 'task'){
                            this.props.loadTaskList(...data);
                          }
                        }
                      }}
                      roleId={this.state.role_id} 
                      only_d2c_user_roles_enabled={this.state.only_d2c_user_roles_enabled}
                      only_problem_investigator_role_enabled={this.state.only_problem_investigator_role_enabled}
                      only_d2c_manager_roles_enable={this.state.only_d2c_manager_roles_enabled}
                    /> :

                    <React.Suspense fallback={<ListLoader />}>
                      <XsmQuickViewShortDescription
                        isFetchingDetails={this.props.isFetchingDetails}
                        breakFixGraphView={breakFixGraphView}
                        breakFixDateRangeFilter={breakFixDateRangeFilter}
                        onClickBreakFixData={this.state.onClickBreakFixData}
                        taskGraphView={taskGraphView}
                        taskDateRangeFilter={taskDateRangeFilter}
                        onClickTaskData={this.state.onClickTaskData}
                        investigationGraphView={investigationGraphView}
                        investigationDateRangeFilter={
                          investigationDateRangeFilter
                        }
                        onClickInvestigationData={
                          this.state.onClickInvestigationData
                        }
                        changeGraphView={changeGraphView}
                        changeDateRangeFilter={changeDateRangeFilter}
                        onClickChangeData={this.state.onClickChangeData}
                        fullfilmentGraphView={fullfilmentGraphView}
                        fullfilmentDateRangeFilter={fullfilmentDateRangeFilter}
                        onClickFullfilmentData={
                          this.state.onClickFullfilmentData
                        }
                        breakfixId={this.state.breakfixId}
                        breakfixNumber={this.state.breakfixNumber}
                        breakFixDescription={this.state.breakFixDescription}
                        breakFixadditionalInfo={
                          this.state.breakFixadditionalInfo
                        }
                        breakFixCreatedOn={this.state.breakFixCreatedOn}
                        breakFixServiceName={this.state.breakFixServiceName}
                        breakFixServiceCriticality={
                          this.state.breakFixServiceCriticality
                        }
                        breakFixSpGroupname={this.state.breakFixSpGroupname}
                        breakFixSpUsername={this.state.breakFixSpUsername}
                        requestNumber={this.state.requestNumber}
                        requesterId={this.state.requesterId}
                        expectedResolutionTime={this.state.expectedResolutionTime}
                        investigationId={this.state.investigationId}
                        investigationNumber={this.state.investigationNumber}
                        problemStatements={this.state.problemStatements}
                        problemSymptom={this.state.problemSymptom}
                        investigationReported={this.state.investigationReported}
                        investigationServiceName={
                          this.state.investigationServiceName
                        }
                        investigationServiceCriticality={
                          this.state.investigationServiceCriticality
                        }
                        asgPmGroupName={this.state.asgPmGroupName}
                        asgPmName={this.state.asgPmName}
                        changeId={this.state.changeId}
                        changeNumber={this.state.changeNumber}
                        ciServiceName={this.state.ciServiceName}
                        serviceBased={this.state.serviceBased}
                        changeGroupname={this.state.changeGroupname}
                        changeUsername={this.state.changeUsername}
                        changedescription={this.state.changedescription}
                        expStartDate={this.state.expStartDate}
                        expEndDate={this.state.expEndDate}
                        dStartDate={this.state.dStartDate}
                        dEndDate={this.state.dEndDate}
                        reportDate={this.state.reportDate}
                        dateCAB={this.state.dateCAB}
                        impleGroupName={this.state.impleGroupName}
                        impleUserName={this.state.impleUserName}
                        changeRisk={this.state.changeRisk}
                        ChangeTypee={this.state.ChangeTypee}
                        statusChange={this.state.statusChange}
                        companyNameChange={this.state.companyNameChange}
                        requesterName={this.state.requesterName}
                        requestNumberChange={this.state.requestNumberChange}
                        ciIDChange={this.state.ciIDChange}
                        changeServiceId={this.state.changeServiceId}
                        changerequesterId={this.state.changerequesterId}
                        risk={this.state.risk}
                        ciClassName={this.state.ciClassName}
                        taskCode={this.state.taskCode}
                        registrationId={this.state.registrationId}
                        serviceName={this.state.serviceName}
                        ciName={this.state.ciName}
                        assignedToGroup={this.state.assignedToGroup}
                        expectedStartDate={this.state.expectedStartDate}
                        expectedDueDate={this.state.expectedDueDate}
                        description={this.state.description}
                        lastModificationDate={this.state.lastModificationDate}
                        assignedTo={this.state.assignedTo}
                        sequenceNumber={this.state.sequenceNumber}
                        clientId={this.state.clientId}
                        assignedToGroupId={this.state.assignedToGroupId}
                        parentWorkItemId={this.state.parentWorkItemId}
                        taskSubStatus={this.state.taskSubStatus}
                        workItemType={this.state.workItemType}
                        ciId={this.state.ciId}
                        translator={this.props.tr}
                        user_id={this.state.user_id}
                        full_name={this.state.full_name}
                        role_id={this.state.role_id}
                        date_format={this.state.date_format}
                        group_id={this.state.group_id}
                        showRightSide={this.showRightSide}
                        isRightSideVisible={this.state.isRightSideVisible}
                        rowIndexBrk={this.state.rowIndexBrk}
                        consumerCompanyName={this.state.consumerCompanyName}
                        status={this.state.status}
                        priorityId={this.state.priorityId}
                        impactedUsername={this.state.impactedUsername}
                        createdByName={this.state.createdByName}
                        reportedThrough={this.state.reportedThrough}
                        requesterLocationName={this.state.requesterLocationName}
                        updateParameterBrk={this.updateParameterBrk}
                        statusValueBrk={this.state.statusValueBrk}
                        priorityValueBrk={this.state.priorityValueBrk}
                        spGroupValueBrk={this.state.spGroupValueBrk}
                        criticalityValueBrk={this.state.criticalityValueBrk}
                        cimgroupsValueBrk={this.state.cimgroupsValueBrk}
                        fulfillmenRowIndex={this.state.fulfillmenRowIndex}
                        fulfillmentSearchKey={this.state.fulfillmentSearchKey}
                        fulfillmentSearchValue={
                          this.state.fulfillmentSearchValue
                        }
                        fulfillmentPattern={this.state.fulfillmentPattern}
                        fulfillmentSortOrder={this.state.fulfillmentSortOrder}
                        fulfillmentSortOnColumn={
                          this.state.fulfillmentSortOnColumn
                        }
                        fulfillmentGroup={this.state.fulfillmentGroup}
                        changemngmtGroup={this.state.changemngmtGroup}
                        implemntGroup={this.state.implemntGroup}
                        fulGrp={this.state.fulGrp}
                        fulfillmentUser={this.state.fulfillmentUser}
                        fulfillmentStatus={this.state.fulfillmentStatus}
                        fulfillmentIsClearAllFilter={
                          this.state.fulfillmentIsClearAllFilter
                        }
                        // fulfillmentDisplayNumberSearchInput={this.state.fulfillmentDisplayNumberSearchInput}
                        fulfillmentCiServiceNameSearchInput={
                          this.state.fulfillmentCiServiceNameSearchInput
                        }
                        fulfillmentRequesterNameSearchInput={
                          this.state.fulfillmentRequesterNameSearchInput
                        }
                        fulfillmentCompanyNameSearchInput={
                          this.state.fulfillmentCompanyNameSearchInput
                        }
                        fulfillmentSummarySearchInput={this.state.fulfillmentSummarySearchInput}
                        fulfillerNameSearchInput={
                          this.state.fulfillerNameSearchInput
                        }
                        fulfillmentSelectedStatusValue={
                          this.state.fulfillmentSelectedStatusValue
                        }
                        fulfillmentGroupValue={this.state.fulfillmentGroupValue}
                        changeManagementGroupValue={
                          this.state.changeManagementGroupValue
                        }
                        impleGroupValue={this.state.impleGroupValue}
                        fulfillmentReportedStartDate={
                          this.state.fulfillmentReportedStartDate
                        }
                        fulfillmentReportedEndDate={
                          this.state.fulfillmentReportedEndDate
                        }
                        fulfillmentReportedDateRef={
                          this.state.fulfillmentReportedDateRef
                        }
                        showFulfillmentActiveRow={this.showFulfillmentActiveRow}
                        setFulSearchKeyValue={this.setFulSearchKeyValue}
                        setFulSortOrder={this.setFulSortOrder}
                        updateFulStatus={this.updateFulStatus}
                        updateFulGroup={this.updateFulGroup}
                        updateChangemangmtGroup={this.updateChangemangmtGroup}
                        updateImplmtGroup={this.updateImplmtGroup}
                        updateUserGroup={this.updateUserGroup}
                        isClearAllFilterFuncFulfillment={
                          this.isClearAllFilterFuncFulfillment
                        }
                        // setFuldisplayNumberInputValues={this.setFuldisplayNumberInputValues}
                        setFulCiServiceNameInputValues={
                          this.setFulCiServiceNameInputValues
                        }
                        setFulRequesterInputValues={
                          this.setFulRequesterInputValues
                        }
                        setFulReportedStartAndEndDate={
                          this.setFulReportedStartAndEndDate
                        }
                        setFulSelectedStatusValue={
                          this.setFulSelectedStatusValue
                        }
                        setFulSelectedGroupValue={this.setFulSelectedGroupValue}
                        setChangMangmtGroupValue={this.setChangMangmtGroupValue}
                        setimpleGroupValue={this.setimpleGroupValue}
                        setFulReportedRef={this.setFulReportedRef}
                        setFulfillerInputValues={this.setFulfillerInputValues}
                        setFulCompanyNameInputValues={
                          this.setFulCompanyNameInputValues
                        }
                        setFulSummaryInputValues={this.setFulSummaryInputValues}
                        activePanelKey={this.state.activePanelKey}
                        handlePanelClick={this.handlePanelClick}
                        changeRowIndex={this.state.changeRowIndex}
                        showChangeActiveRow={this.showChangeActiveRow}
                        changeSearchKey={this.state.changeSearchKey}
                        changeSearchValue={this.state.changeSearchValue}
                        changePattern={this.state.changePattern}
                        changeSortOrder={this.state.changeSortOrder}
                        changeSortOnColumn={this.state.changeSortOnColumn}
                        changeStatus={this.state.changeStatus}
                        changeType={this.state.changeType}
                        // changeDisplayNumberSearchInput={this.state.changeDisplayNumberSearchInput}
                        changeCiServiceNameSearchInput={
                          this.state.changeCiServiceNameSearchInput
                        }
                        changeCompanyNameSearchInput={
                          this.state.changeCompanyNameSearchInput
                        }
                        changeSummaryNameSearchInput={
                          this.state.changeSummaryNameSearchInput
                        }
                        changeRequesterNameSearchInput={
                          this.state.changeRequesterNameSearchInput
                        }
                        changeSelectedStatusValue={
                          this.state.changeSelectedStatusValue
                        }
                        changeTypeValue={this.state.changeTypeValue}
                        changeReportedStartDate={
                          this.state.changeReportedStartDate
                        }
                        changeReportedEndDate={this.state.changeReportedEndDate}
                        changeExpectedStartDate={
                          this.state.changeExpectedStartDate
                        }
                        changeExpectedEndDate={this.state.changeExpectedEndDate}
                        changeCABStartDate={this.state.changeCABStartDate}
                        changeCABEndDate={this.state.changeCABEndDate}
                        changeReportedDateRef={this.state.changeReportedDateRef}
                        changeExpectedDateRef={this.state.changeExpectedDateRef}
                        changeCABDateRef={this.state.changeCABDateRef}
                        changeIsClearAllFilter={
                          this.state.changeIsClearAllFilter
                        }
                        setChangeSearchKeyValue={this.setChangeSearchKeyValue}
                        setChangeSortOrder={this.setChangeSortOrder}
                        setChangeReportedRef={this.setChangeReportedRef}
                        setChangeExpectedDateRef={this.setChangeExpectedDateRef}
                        setChangeCABDateRef={this.setChangeCABDateRef}
                        setChangeSelectedStatusValue={
                          this.setChangeSelectedStatusValue
                        }
                        setSelectedChangeTypeValue={
                          this.setSelectedChangeTypeValue
                        }
                        isClearAllFilterFuncChange={
                          this.isClearAllFilterFuncChange
                        }
                        // setChangedisplayNumberInputValues={this.setChangedisplayNumberInputValues}
                        setChangeCiServiceNameInputValues={
                          this.setChangeCiServiceNameInputValues
                        }
                        setChangeCompanyNameInputValues={
                          this.setChangeCompanyNameInputValues
                        }
                        setChangeSummaryNameInputValues={
                          this.setChangeSummaryNameInputValues
                        }
                        setChangeRequesterInputValues={
                          this.setChangeRequesterInputValues
                        }
                        setChangeReportedStartAndEndDate={
                          this.setChangeReportedStartAndEndDate
                        }
                        setChangeExpectedStartAndEndDate={
                          this.setChangeExpectedStartAndEndDate
                        }
                        setChangeCABStartAndEndDate={
                          this.setChangeCABStartAndEndDate
                        }
                        updateChangeStatus={this.updateChangeStatus}
                        updateChangeType={this.updateChangeType}
                        consumerCompanyInv={this.state.consumerCompanyInv}
                        sourceInv={this.state.sourceInv}
                        statusInv={this.state.statusInv}
                        priorityInv={this.state.priorityInv}
                        createdByNameInv={this.state.createdByNameInv}
                        requestNumberInv={this.state.requestNumberInv}
                        invrequesterId={this.state.invrequesterId}
                        showTaskActiveRow={this.showTaskActiveRow}
                        taskRowIndex={this.state.taskRowIndex}
                        taskGroupData={this.props.taskGroupData}
                        taskNumberSearchInput={this.state.taskNumberSearchInput}
                        workItemNumberSearchInput={
                          this.state.workItemNumberSearchInput
                        }
                        taskSummarySearchInput={
                          this.state.taskSummarySearchInput
                        }
                        taskAssignedToSearchInput={
                          this.state.taskAssignedToSearchInput
                        }
                        setChangeTaskNumberInputValues={
                          this.setChangeTaskNumberInputValues
                        }
                        setChangeworkItemNumberInputValues={
                          this.setChangeworkItemNumberInputValues
                        }
                        setChangeTaskSummaryInputValues={
                          this.setChangeTaskSummaryInputValues
                        }
                        setChangeTaskAssignedToInputValues={
                          this.setChangeTaskAssignedToInputValues
                        }
                        // taskServiceNameSearchInput={this.state.taskServiceNameSearchInput}
                        // setChangeTaskServiceNameInputValues={this.setChangeTaskServiceNameInputValues}
                        setTaskSelectedGroupValue={
                          this.setTaskSelectedGroupValue
                        }
                        taskGroupValue={this.state.taskGroupValue}
                        updateTaskGroup={this.updateTaskGroup}
                        taskGroup={this.state.taskGroup}
                        taskTypeValue={this.state.taskTypeValue}
                        taskType={this.state.taskType}
                        setTaskSelectedTaskValue={this.setTaskSelectedTaskValue}
                        updateTaskType={this.updateTaskType}
                        taskStatusValue={this.state.taskStatusValue}
                        taskStatus={this.state.taskStatus}
                        setTaskSelectedStatusValue={
                          this.setTaskSelectedStatusValue
                        }
                        updateTaskStatus={this.updateTaskStatus}
                        setTaskSearchKeyValue={this.setTaskSearchKeyValue}
                        taskSearchValueParams={this.state.taskSearchValueParams}
                        createdByBrk={this.state.createdByBrk}
                        createdByInv={this.state.createdByInv}
                        createdByChng={this.state.createdByChng}
                        statusName={this.state.statusName}
                        TaskRequesterName={this.state.TaskRequesterName}
                        hopCount={this.state.hopCount}
                        actualStartDate={this.state.actualStartDate}
                        actualEndDate={this.state.actualEndDate}
                        taskIsClearAllFilter={this.state.taskIsClearAllFilter}
                        isClearAllFilterFuncTask={this.isClearAllFilterFuncTask}
                        insideParams={insideParams}
                        taskStartDate={this.state.taskStartDate}
                        taskEndDate={this.state.taskEndDate}
                        taskDateRef={this.state.taskDateRef}
                      />
                    </React.Suspense>
                    }

                    {/* Xsm Quick View Short Description Section End*/}
                  </div>
                </Panel>
              </>
            ) : (
              ""
            )}
          </PanelGroup>
        </div>
      </main>
      </>
    );
  }
}

// export default BreakFix;

export function mapStateToProps(state) {
  return {
    // quickViewDetails: state.quickViewDetails ,
    // fulFillmentList: state.fulFillmentList,
    groups: state.breakfixQuickViewFilters.groups,
    fulfillmentGroupsData: state.breakfixQuickViewFilters.fulfillmentGroups,
    taskGroupData: state.breakfixQuickViewFilters.taskGroups,
    investigationGroups: state.breakfixQuickViewFilters.investigationGroups,
    changeGroups: state.breakfixQuickViewFilters.changeGroups,
    priority: state.breakfixQuickViewFilters.priority,

    breakfixQuickViewFilters: state.breakfixQuickViewFilters,
    breakfixQuickViewTable: state.breakfixQuickViewTable,
    breakFixGraphView: state.reportReducer.breakFixGraphView,
    taskGraphView: state.reportReducer.taskGraphView,
    changeGraphView: state.reportReducer.changeGraphView,
    investigationGraphView: state.reportReducer.investigationGraphView,
    fullfilmentGraphView: state.reportReducer.fullfilmentGraphView,
    carouselIndex: state.breakfixQuickViewTable.carouselIndex,
    //spcmReducer:state.spcmReducer,
    myTaskDetailsList: state.myTaskDetailsList,
    tr: state.spcmReducer.tr,
    lang: state.spcmReducer.lang,
    isFetchingDetails: state.isFetchingDetails.isFetching,
    showLoader: state.showLoader.loading,
    WorkItemBoardFilterCache: state.WorkItemBoardFilterCache,
    allCIMgroups: state.allCIMgroups,
    allCIMgroupsRules: state.allCIMgroupsRules,
    wibGlobalSearchFilterData: state.wibGlobalSearchFilterData,
    wibSearchParams: state.wibSearchParams,
    isSearchParamsApply: state.isSearchParamsApply,
    wibActiveTab: state.wibActiveTab,
    wibSelectedDateRanceLabel: state.wibSelectedDateRanceLabel,
    myChangeManagementList: state.myChangeManagementList,
    investigationList: state.investigationList,
    quickViewDetails: state.quickViewDetails,
    fulFillmentList: state.fulFillmentList,
    walkupEnabled: state.walkupEnabled
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchGroupList,
      fetchFulfillmentGroupList,
      fetchTaskGroupList,
      fetchPriorityList,
      fetchInvestigationGroupList,
      fetchChangeGroupList,
      loadBreakFixQuickView,
      loadBreakFixQuickViewSort,
      // loadBreakFixQuickViewByOptions,
      loadFulfillmentList,
      loadFulfillmentListSort,
      // setBreakfixQuickViewSearchText,
      // setBreakfixQuickViewSearchOption,
      getTranslation,
      setBreakfixQuickViewTableCarouselIndex,
      setBreakFixGraphView,
      loadTaskList,
      loadTaskListAsc,
      setTaskGraphView,
      setInvestigationGraphView,
      setChangeGraphView,
      setFullfilmentGraphView,
      loadInvestigationList,
      loadInvestigationListSort,
      loadChangeManagementmList,
      loadChangeManagementmListSort,
      resetBFQVFilters,
      SaveFiltersState,
      ClearFiltersState,
      loadallCIMgroups,
      loadallCIMgroupsRules,
      clearallCIMgroups,
      setInitialwibGlobalSearchFilterData,
      setWibActiveTab,
      setWipSearchApply,
      setWibSelectedDateRangeLabel, getslaViewListAction,
      getAdminPreferences, 
      emptyAdminPreferencesListData
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(BreakFix);
