
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import TableComponent from "../Common/ReactTable/TableComponent";
import SystemConfigListHeader from "./SystemConfigListHeader";
import SystemConfigListView from "./SystemConfigListView";
import { getCompanyList } from "../../actions/spcmActions.js";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import SystemConfigShortDescription from "./SystemConfigShortDescription";
import Breadcrumbs from "../common/header/breadcrumbs.js";
import { getSystemConfigList } from "../../actions/systemConfig/systemConfigAction";
import "_Css/common/_dataTable.scss";
const SystemConfigIndex = () => {
 
    const dispatch = useDispatch();

    const translator = useSelector((state) => state.spcmReducer.tr);
    const [requestMethod, setRequestMethod] = useState("");
    const [rightDisplay, setRightDisplay] = useState(false);
    const [systemConfigData, setSystemConfigData] = useState({});
    const showRightSide = (val) => {
      setRightDisplay(val);
    };
  
    const setShortDescValues = (
      systemConfigData
    ) => {
      setSystemConfigData(systemConfigData);
    };
  
    useEffect(() => {
      dispatch(getSystemConfigList());
      // dispatch(getCompanyList(""));
      // dispatch(loadIntegrationScenarioList(""));
    }, []);
  
  return (
    <div>
      <div>
        <div className="container-fluid padding-t-10 margin-b-15">
          <Breadcrumbs activePageName={"Mailbox Configuration"} />
        
        </div>
      <div className="container-fluid" bsClass="">
          <SystemConfigListHeader translator={translator} />
          <PanelGroup direction="horizontal" className="panelOverflow">
            <Panel id="sidebar" minSize={33} order={1} defaultSize={rightDisplay ? 67 : 100} className={rightDisplay ? "catBordDv isShowLeftPanel rwLeftPart" : "catBordDv rwLeftPart"}>
              <SystemConfigListView
                rightDisplay={rightDisplay}
                setShortDescValues={setShortDescValues}
                translator={translator}
                showRightSide={showRightSide}
              />
            </Panel>
            {rightDisplay ?
                <>
                  <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
                  <Panel minSize={33} order={2} defaultSize={33}>
                    <div className="stickyArea rBoxStyle">
                      <SystemConfigShortDescription
                        showRightSide={showRightSide}
                        systemConfigData={systemConfigData}
                      />
                    </div>
                  </Panel>
                </> : ""
            }
          </PanelGroup>
        </div> 
       
      </div>
    </div>
  );
};

export default SystemConfigIndex;
