
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export const parentFunctionByCompanyIdDropdown = (state = [], action) => {
    // console.log("Reducersssss1234567890",action.categoryByCompanyIdDropdownList)
    // console.log("Reducersssss",action.type)
    switch (action.type) {
        case 'PARENTFUNCTION_BY_COMPANY_ID':
            return action.categoryByCompanyIdDropdownList;
        case 'PARENTFUNCTION_BY_COMPANY_ID_REFRESH':
            return "";
        default:
            return state;
    }
}
export const businessFunctionList = (state = {}, action) => {
    // console.log("Action====>",action)
    switch (action.type) {
        case 'LOAD_BUSINESS_FUNCTION_LIST_SUCCESS':
            return action.categoryBoardList.data;
        case 'EMPTY_BUSINESS_FUNCTION_LIST_REDUCER':
            return {};
        default:
            return state;
    }
}
export const businessFunctionListIntialValues = (state = {}, action) => {
    // console.log("businessFunctionListIntialValues====>Reducer",action)
    switch (action.type) {
        case 'LOAD_BUSINESS_EDIT_DETAILS_SUCCESS':
            return action.row;
        default:
            return state;
    }
}