
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
//below URL used for testing purpose
//const breakFixUrl = 'https://xsm-f.mybluemix.net/api/SX_FAVOURITEOFFERINGs/';
import { GLOBAL }  from '../../components/Globals';
//let breakFixUrl =`https://xsm-f.mybluemix.net/api/breakfixitem?breakfixitemId=`;
//let breakFixUrl=`https://xsm-f.mybluemix.net/groupListSelfService?companyId=2932&group=ResolvedER`
export function loadFullfilmentRelatedWorkDetailsSuccess(fullfilmentRelatedWorkDetails){
    //alert('in success')
    return {
        type: 'LOAD_FULLFILMENTRELATEDWORKDETAILS_SUCCESS',
        fullfilmentRelatedWorkDetails
    };
}

export function loadFullfilmentRelatedWorkDetailsFailure(errorResponse){
    return {
        type: 'LOAD_FULLFILMENTRELATEDWORKDETAILS_FAILURE',
        errorResponse
    };
}
export function loadFullfilmentRelatedWorkDetails(filterval,breakFixId,module,itmId='',isRelationReq=''){
    //alert('action called and parameter is '+breakFixId)
    //if(breakFixId=="0")
    //breakFixId="";
    // console.log("module ful ", module)
    // console.log("Check Values--->",filterval,breakFixId,module)

    return(dispatch) =>{
        if(!(breakFixId == -1)){
        dispatch({
            type: 'MENU_LOADER_IN_PROGRESS',
            payload: ''
        });
    }
        //alert("EditGroupIdAction123"+breakFixId);

                //alert("hiaaa::::");
                let relatedurl="";
                let axioscall="";
              
                if(module=="Breakfix")
                relatedurl=GLOBAL.breakFixRelationQuickViewURL;
                if(module=="Fullfilment")
                relatedurl=GLOBAL.fulfillmentRelationListURL;
                if(module=="Investigation")
                relatedurl=GLOBAL.investigationQuickViewURL;
                if(module=="ChangeManagement")
                relatedurl=GLOBAL.changeQuickViewURL;

                if(module=="Breakfix"){
					let str = {};
					str.searchByList = filterval;
					str.patternList = "like";
                    str.multipleValueList=""+breakFixId;
                    str.sortBy = "breakfixNumber";
                    str.orderBy = "desc";
                    str.size = 99;
					str.currentPage = 0;
				//	str = JSON.stringify(str);
					axioscall = axios.post(relatedurl, str);
                console.log("?searchByList="+filterval+
                "&sortBy=breakfixNumber&orderBy=desc&patternList=like&multipleValueList="+breakFixId);
				}else if(module=="Fullfilment"){
					let str={};
					str.searchByList=filterval;
					str.isNestedRequired="N";
					str.patternList="like";
                    str.multipleValueList=""+breakFixId;
                    str.sortBy = "itemNumber";
                    str.orderBy = "desc";
                    str.size = 99;
                    str.currentPage = 0;
                    
                    if(isRelationReq != '')	str.isRelationsNotRequired = isRelationReq;
                   if(itmId != '') str.itemId = itmId.toString();
                //	str = JSON.stringify(str);
                 //  console.log("stringobjectaddeditemID",str);
					axioscall=axios.post(relatedurl,str);
                    console.log("?searchByList="+filterval+
                    "&sortBy=itemNumber&isNestedRequired=N&orderBy=desc&patternList=like&multipleValueList="+breakFixId);
				}else if(module=="Investigation"){
                    // "?searchByList="+filterval+"&sortBy=investigationNumber&orderBy="+
                    // "desc&patternList=like&multipleValueList="+breakFixId
					let str={};
                    str.searchByList=filterval;
                    str.sortBy="investigationNumber";
                    str.orderBy="desc";
                    str.patternList="like";
                    str.size = 99;
					str.currentPage = 0;
                    str.multipleValueList=""+breakFixId;
                    axioscall=axios.post(relatedurl,str);
				}else if(module=="ChangeManagement"){
                   // "?searchByList="+filterval+"&sortBy=changeNumber&orderBy=desc&patternList=like&multipleValueList="+breakFixId
                    
					let str={};
					str.searchByList=filterval;
                    str.patternList="like";
                    str.sortBy="changeNumber";
                    str.orderBy="desc";
					str.multipleValueList=""+breakFixId;
					str.size = 99;
					str.currentPage = 0;
					axioscall=axios.post(relatedurl,str);
				}else{
					axioscall=axios.get(relatedurl)
				}
				axioscall.then((fullfilmentRelatedWorkDetails)=>{
                        if(!fullfilmentRelatedWorkDetails){//alert('not breakFix')
                            //dispatch(loadBreakFixFailure(breakFix.statusText))
                            dispatch({
                                type: 'MENU_LOADER_DETAILS_FETCHED_SUCCESSFULLY',
                                payload: ''
                            });
                            throw Error(fullfilmentRelatedWorkDetails.statusText);
                        }
                        return fullfilmentRelatedWorkDetails;
                    })
                    .then((fullfilmentRelatedWorkDetails) =>{//alert('yes it is')
                        dispatch({
                            type: 'MENU_LOADER_DETAILS_FETCHED_SUCCESSFULLY',
                            payload: ''
                        });
                        dispatch(loadFullfilmentRelatedWorkDetailsSuccess(fullfilmentRelatedWorkDetails));
                    })
                    .catch((err) => {
                        dispatch({
                            type: 'MENU_LOADER_DETAILS_FETCHED_SUCCESSFULLY',
                            payload: ''
                        });
                        console.log(err);
                    });
                    //console.log("not calling loadBreakFixEditDetails");

    };
}

export function clearFullfilmentRelatedWorkDetails(){
	return(dispatch) =>{
		let obj = {
			data: {
				data: [],
				meta: null
			}
		};
		dispatch(loadFullfilmentRelatedWorkDetailsSuccess(obj));
	};
}