
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from './initialState';

export default function authorReducer(state = initialState.authors, action){
  switch (action.type) {
    case 'LOAD_AUTHORS_SUCCESS':
      return action.authors;

    default:
    return state;

  }
}
