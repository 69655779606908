
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { GLOBAL } from '_Globals';
import LeftSidebarMenulist from './LeftSidebarMenulist.js';
import CreateInvestigationHeader from './InvestigationCreateHeader.js';
import InvestigationCreateForm from './CreateInvestigationForm';
import ProcessHierarchy from './ProcessHierarchy.js';
import XsmUserDetails from '_Commons/WorkItemBoard/xsmRequestedBy.js';
import XsmServiceDetails from '_Commons/WorkItemBoard/xsm-service-quickView.js';
import XsmBreakFixAction0 from '_Commons/WorkItemBoard/xsm-breakFix-action0.js';
import { connect } from 'react-redux';
import { getTranslation} from '_Actions/spcmActions';
const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});
import WibSearchFulfillment from '_Commons/WorkItemBoard/wibSearch-fulfillment';
import {setInitialwibGlobalSearchFilterData} from '_Actions/globalSearch/globalSearchAction';
import Empty from '../../../common/WorkItemBoard/Empty.js';
import AvailableIndividuals from '../../../common/WorkItemBoard/availableIndividuals/AvailableIndividuals.js';
import UserDetails from "../../../common/WorkItemBoard/xsmEditRequestedBy";
import ServiceDetails from "../../../common/WorkItemBoard/xsm-service-editquickView";
import CIDEtails from "../../../common/WorkItemBoard/xsm-ci-editquickView";
import "_Css/form/_form.scss";
class InvestigationCreateIndex extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			subMenu: '4',
			showHideAlfy: false,
			showHideRightNav: false,
			comp:0,
			userId:'',
			service:0,
			categoryID:0,
			processhierarchy:0,
			role_id:'',
			groupID:'',
		    roleID:'',
			changeImplentationRole:'',
			selectedAssignmentGroup:[],
			selectedAssignmentUser:[],
			showAvailableIndividual:0,
			isConsumerSelected: false,
			isServiceSelected: false,
			isImpactedCISelected: false,
			isIndividualSelected: false,
			individualID: 0,
			
		};
		this.showHideAlfy = this.showHideAlfy.bind(this);
		this.showHideRightNav = this.showHideRightNav.bind(this);
		this.changeUser = this.changeUser.bind(this);
		this.changeServiceComponent = this.changeServiceComponent.bind(this);
		this.processHierarchy = this.processHierarchy.bind(this);
		this.roleIdentification=this.roleIdentification.bind(this)
		this.setAssignmentGrpUsr = this.setAssignmentGrpUsr.bind(this);
        this.showAvailableIndividual = this.showAvailableIndividual.bind(this);
		this.showConsumerDetails = this.showConsumerDetails.bind(this);
		this.showImpactedCIDetails = this.showImpactedCIDetails.bind(this);
        this.showIndividualDetails = this.showIndividualDetails.bind(this);
        this.setIndividualDetails = this.setIndividualDetails.bind(this);

	}

	setAssignmentGrpUsr(selectd,type){
		switch(type){
		  case "user":
			this.setState({selectedAssignmentUser:selectd});
			break;
		  case  "group":
			this.setState({selectedAssignmentGroup:selectd});
			break;
		}
	}

	showAvailableIndividual() {
		//alert("changeStatusTimeLine");
		this.setState({
			comp:0,
			service:0,
			processhierarchy:0,
			showAvailableIndividual:1,
			isIndividualSelected: false,
		});
		//alert(this.state.processhierarchy);
	  }

	componentWillMount()
	{
		let str={};
			str.user_id="";
			str = JSON.stringify(str);
		api.get("/api/userInfo",{headers:{'query':str}}).then((response)=>{
			let changeUserid='';
    if(response.data.role_id.split(",").includes("20")&&(!response.data.role_id.split(",").includes("52")))
    {
      changeUserid="changeUserid";
    }
    else if(response.data.role_id.split(",").includes("52")&&(!response.data.role_id.split(",").includes("20")))
    {
      changeUserid="impUserid";
    }
    else if(response.data.role_id.split(",").includes("20")&&response.data.role_id.split(",").includes("52")||response.data.role_id.split(",").includes("20")&&response.data.role_id.split(",").includes("51")||response.data.role_id.split(",").includes("52")&&response.data.role_id.split(",").includes("19"))
    {
      changeUserid="impUserid,changeUserid";
    }
    else if(response.data.role_id.split(",").includes("19")&&(!response.data.role_id.split(",").includes("51")))
    {
      changeUserid="changeUserid";
    }
    else if(response.data.role_id.split(",").includes("51")&&(!response.data.role_id.split(",").includes("19")))
    {
      changeUserid="impUserid";
    }
    else if(response.data.role_id.split(",").includes("19")&&response.data.role_id.split(",").includes("51"))
    {
      changeUserid="impUserid,changeUserid";
    }
			this.setState({

			 role_id:response.data.role_id,
			 groupID:response.data.groupId,
			 roleID:response.data.role_id,
			 changeImplentationRole:changeUserid

			});
			

		})
	}
	componentWillReceiveProps()
	{
		let str={};
			str.user_id="";
			str = JSON.stringify(str);
			api.get("/api/userInfo",{headers:{'query':str}}).then((response)=>{
				let changeUserid='';
    if(response.data.role_id.split(",").includes("20")&&(!response.data.role_id.split(",").includes("52")))
    {
      changeUserid="changeUserid";
    }
    else if(response.data.role_id.split(",").includes("52")&&(!response.data.role_id.split(",").includes("20")))
    {
      changeUserid="impUserid";
    }
    else if(response.data.role_id.split(",").includes("20")&&response.data.role_id.split(",").includes("52")||response.data.role_id.split(",").includes("20")&&response.data.role_id.split(",").includes("51")||response.data.role_id.split(",").includes("52")&&response.data.role_id.split(",").includes("19"))
    {
      changeUserid="impUserid,changeUserid";
    }
    else if(response.data.role_id.split(",").includes("19")&&(!response.data.role_id.split(",").includes("51")))
    {
      changeUserid="changeUserid";
    }
    else if(response.data.role_id.split(",").includes("51")&&(!response.data.role_id.split(",").includes("19")))
    {
      changeUserid="impUserid";
    }
    else if(response.data.role_id.split(",").includes("19")&&response.data.role_id.split(",").includes("51"))
    {
      changeUserid="impUserid,changeUserid";
    }
				this.setState({

				 role_id:response.data.role_id,
				 groupID:response.data.groupId,
			     roleID:response.data.role_id,
				 changeImplentationRole:changeUserid
				});
			})
	}
	showHideAlfy(value) {
		this.setState({ showHideAlfy: value });
	}
	showHideRightNav(value) {
		this.setState({ showHideRightNav: !this.state.showHideRightNav });
	}
	roleIdentification(Rolevalue){
		let flag=false
		let roles=this.state.role_id.split(',')
		for(let i=0;i<roles.length;i++)
		{
			if(roles[i]==Rolevalue)
			   {
				   flag=true
				   break
			   }
		}
		if(flag)
		   return true
		else
		   return false
	   }
	changeServiceComponent(serviceID){
//console.log("serviceID : "+serviceID);
if(serviceID != 0){
		this.setState({
			comp:0,
			service:1,
			categoryID:serviceID,
			processhierarchy:0,
			showAvailableIndividual:0
		});
}
	}
	changeUser(consumerId) {
		//console.log("consumerId :: "+consumerId)
		if(consumerId != 0){
		this.setState({
			comp:1,
			userId:consumerId,
			service:0,
			processhierarchy:0,
			showAvailableIndividual:0,
			isIndividualSelected:false
		});
	}
		//alert(this.state.comp);

	}

	processHierarchy() {
		//alert("changeStatusTimeLine");
		this.setState({
			processhierarchy:1,
			comp:0,
			service:0,
			showAvailableIndividual:0,
			isIndividualSelected: false,
			isImpactedCISelected: false,
			isConsumerSelected: false
		});
		//alert(this.state.processhierarchy);
	}

	showConsumerDetails(value){
		if(value){
		  this.setState({isConsumerSelected: value, comp: 0, service: 0, categoryID: 0, processhierarchy: 0, isServiceSelected: false, isImpactedCISelected: false, isIndividualSelected: false, showAvailableIndividual:0});
		}else{
		  this.setState({isConsumerSelected: value});
		}
	  }

	  showImpactedCIDetails(value){
		if(value){
		  this.setState({isConsumerSelected: false, comp: 0, service: 0, categoryID: 0, processhierarchy: 0, isServiceSelected: false, isImpactedCISelected: value, isIndividualSelected: false, showAvailableIndividual:0});
		}else{
		  this.setState({isImpactedCISelected: value}); 
		}
	  }
	  showIndividualDetails(value){
		
		if(value){
		  this.setState({isConsumerSelected: false, comp: 0, service: 0, categoryID: 0, processhierarchy: 0, isServiceSelected: false, isImpactedCISelected: false, isIndividualSelected: value, showAvailableIndividual:0});
		}else{
		  this.setState({isIndividualSelected: value}); 
		}
	  }
	  setIndividualDetails(id){
		this.setState({individualID: id});
	  }
	render() {
		console.log(this.state.isIndividualSelected,'test indi')
		console.log(this.state.individualID)
		return (
			<div className="container-fluid">
				{this.state.role_id!==''?<WibSearchFulfillment tr={this.props.tr} roleIdentification={this.roleIdentification} group_id={this.state.groupID} changeImplentationRole={this.state.changeImplentationRole}/>:null}
        <div className="margin-t-10 margin-b-15">
				<ul className="breadcrumb"><li className="breadcrumb-item"><a role="button" onClick={this.loadHome}>{this.props.tr["Home"]}</a></li><li className="breadcrumb-item"><Link title={this.props.tr["Work Item Board"]} to="/quickViewBreakFix">{this.props.tr["Work Item Board"]}</Link></li><li className="breadcrumb-item active">{this.props.tr["Create"]}</li></ul>
			</div>
				<div >
						<CreateInvestigationHeader processHierarchy = {this.processHierarchy} translator={this.props.tr} setInitialwibGlobalSearchFilterData={this.props.setInitialwibGlobalSearchFilterData}/>
						<Row className="row-eq-height">
							<Col lg={8} md={12} sm={12} xs={12}>
								<InvestigationCreateForm roleIdentification={this.roleIdentification} changeUser = {this.changeUser} changeServiceComponent = {this.changeServiceComponent} translator={this.props.tr}
								selectedAssignmentGroup={this.state.selectedAssignmentGroup}
								selectedAssignmentUser={this.state.selectedAssignmentUser}
								setAssignmentGrpUsr={this.setAssignmentGrpUsr}
								showAvailableIndividual={this.showAvailableIndividual} 
								showConsumerDetails={this.showConsumerDetails}
								showImpactedCIDetails={this.showImpactedCIDetails}
								showIndividualDetails={this.showIndividualDetails}
                                setIndividualDetails={this.setIndividualDetails}
								/>
								
								
							</Col>
							<Col lg={4} md={12} sm={12} xs={12} className="colRightHeight">
								<div className="stickyArea rBoxStyle">
								{this.state.isIndividualSelected?<UserDetails userId={this.state.individualID} userDetailsLabel={"Individual Details"} translator={this.props.tr} rightEditPanel={this.rightEditPanel}/>: <Empty/>}
								{this.state.isConsumerSelected?<UserDetails  userId={GLOBAL.breakFixConsumerID} userDetailsLabel={"Requestor Details"} translator={this.props.tr} rightEditPanel={this.rightEditPanel}/>: <Empty/>}
								{this.state.processhierarchy==0 && this.state.comp==0 && this.state.service==0 && this.state.showAvailableIndividual == 0 && !(this.state.isConsumerSelected || this.state.isServiceSelected || this.state.isImpactedCISelected ||this.state.isIndividualSelected)? <ProcessHierarchy translator={this.props.tr}/>:<XsmBreakFixAction0/>}
								{this.state.comp==0  ? <XsmBreakFixAction0/>:<XsmUserDetails userId = {this.state.userId}  translator={this.props.tr}/>}
								{this.state.isImpactedCISelected?<CIDEtails ciId={GLOBAL.ciId} translator={this.props.tr} rightEditPanel={this.rightEditPanel}/>: <Empty/>}
								{this.state.service==0 ? <XsmBreakFixAction0/>:<XsmServiceDetails categoryID={this.state.categoryID}  translator={this.props.tr}/>}
								{this.state.processhierarchy==1 && this.state.comp==0 && this.state.service==0 && this.state.showAvailableIndividual == 0? <ProcessHierarchy translator={this.props.tr}/>:<XsmBreakFixAction0/>}
								{ this.state.showAvailableIndividual == 1 ? (<AvailableIndividuals setAssignmentGrpUsr={this.setAssignmentGrpUsr}  selectedAssignmentGroup={this.state.selectedAssignmentGroup} selectedAssignmentUser={this.state.selectedAssignmentUser} module="Problem"/>
                  ) : (
                    <Empty/>
                  )}
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12}>
							</Col>
						</Row>
				</div>
			</div>
		);
	}
}


const mapStateToProps = ({spcmReducer}) => {
	return {

		tr:spcmReducer.tr
	}
};

export default connect(mapStateToProps,{ getTranslation, setInitialwibGlobalSearchFilterData } )(InvestigationCreateIndex);
