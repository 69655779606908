
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Container, Form, Nav, NavDropdown, FormControl, Button, Table, ButtonToolbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { change, Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector, connect } from 'react-redux';
import { _textArea, _dropDown, _inputField, _checkbox, _number, _dynamicNamedropDown } from "../common/formFields.js";
import ListLoader from "../common/loaders/ListLoader.js"
import IndividualPropertyByModule from "./individualPropertyByModule";
import IndividualPropertyByModuleAISM from "./individualPropertyByModuleAISM";

const styles = {
    grid: {
        paddingLeft: 0,
        paddingRight: 0
    },
    row: {
        marginLeft: 0,
        marginRight: 0
    },
    col: {
        paddingLeft: 0,
        paddingRight: 0
    }
};

const List = (props) => {
    const dispatch = useDispatch();
    const [selectedModule, setSelectedModule] = useState([]);
    const [selectedModuleIndex, setSelectedModuleIndex] = useState(0);
    const [adminPreferencesListDataFiltered, setAdminPreferencesListDataFiltered] = useState([]);

    const translator = useSelector((state) => state.spcmReducer.tr);
    const adminPreferencesListLoader = useSelector((state) => state.admin_preferences_list_loader_reducer.adminPreferencesListLoader);
    const adminPreferencesListData = useSelector((state) => state.admin_preferences_list_data_reducer.adminPreferencesListData);

    useEffect(() => {
        if(Array.isArray(adminPreferencesListData)) {
            setAdminPreferencesListDataFiltered(adminPreferencesListData);
        }
        if(props.searchText && props.searchText != '') {
            filterAdminPreferencesListData(adminPreferencesListData, props.searchText);
        }
    }, [adminPreferencesListData]);

    useEffect(() => {
        if (adminPreferencesListDataFiltered.length > 0) {
            if(props.retainSelectedModule) {
                setSelectedModule(adminPreferencesListDataFiltered[selectedModuleIndex])
            } else {
                setSelectedModule(adminPreferencesListDataFiltered[0])
                setSelectedModuleIndex(0);
            }
        }
    }, [adminPreferencesListDataFiltered]);

    useEffect(() => {
        filterAdminPreferencesListData(adminPreferencesListData, props.searchText);
    }, [props.searchText]);

    function filterAdminPreferencesListData(data, searchText) {
        let adminPreferencesListFilteredData = [];
        if (searchText == '') {
            adminPreferencesListFilteredData = data;
        } else {
            data.forEach((module) => {
                let filteredPropertiesForModule = module.properties.filter((property) => property.propertyName.toLowerCase().includes(searchText.toLowerCase()));
                if (filteredPropertiesForModule.length > 0) {
                    let moduleClone = structuredClone(module);
                    for (let key in moduleClone) {
                        if (key == 'properties') {
                            moduleClone[key] = filteredPropertiesForModule;
                        }
                    }
                    adminPreferencesListFilteredData.push(moduleClone);
                }
            })
        }
        if(Array.isArray(adminPreferencesListFilteredData)) {
            setAdminPreferencesListDataFiltered(adminPreferencesListFilteredData);
        }
    }

    if (adminPreferencesListLoader) {
        return (
            <div className="custom-grey-bg p-10">
                <ListLoader />
            </div>
        )
    }
    if (adminPreferencesListDataFiltered.length == 0) {
        return (
            <div className="custom-grey-bg p-10 text-c">
                {translator['No Data Found']}
            </div>
        )
    }
    return (
        <div>
            <Row style={styles.row}>
                <Col lg={2} md={2} sm={12} xs={12} style={styles.col}>
                    <div className="custom-grey-bg" style={{ overflowY: "auto", height: "545px" }}>
                        <Nav className="flex-column" as={"ul"}>
                            {adminPreferencesListDataFiltered?.map((item, index) => {
                                return (
                                    <Nav.Item key={index} as="li" title={item.moduleName}>
                                        <Nav.Link className={index == selectedModuleIndex ? "active" : ""} to="javascript:void(0)" onClick={() => { setSelectedModuleIndex(index); setSelectedModule(item); }}>{item.moduleName}</Nav.Link>
                                    </Nav.Item>
                                )
                            })}
                        </Nav>
                    </div>
                </Col>
                <Col lg={10} md={10} sm={12} xs={12} style={styles.col}>
                    <div className="pos-r">
                        <Row style={styles.row}>
                            <Col lg={3} md={3} sm={12} xs={12} style={styles.col} className="min-h-545"></Col>
                            <Col lg={9} md={9} sm={12} xs={12} style={styles.col} className="bg-lightBlue min-h-545"></Col>
                        </Row>
                        <div className="pos-a">
                            {selectedModule.properties && selectedModule.properties.map((item, index) => {
                                // return selectedModule.moduleName == "AISM" ?
                                //     <IndividualPropertyByModuleAISM selectedModuleIndex={selectedModuleIndex} moduleName={selectedModule.moduleName} propertyData={item} key={"props.propertyData.propertyName_" + index} instanceOrCompanySelection={props.instanceOrCompanySelection} showBottomBorder={index < selectedModule.properties.length - 1} isSuperAdmin={props.isSuperAdmin} onlyShowOverridden={props.onlyShowOverridden} setRetainSelectedModule={props.setRetainSelectedModule} />
                                // : <IndividualPropertyByModule selectedModuleIndex={selectedModuleIndex} moduleName={selectedModule.moduleName} propertyData={item} key={"props.propertyData.propertyName_" + index} instanceOrCompanySelection={props.instanceOrCompanySelection} showBottomBorder={index < selectedModule.properties.length - 1} isSuperAdmin={props.isSuperAdmin} onlyShowOverridden={props.onlyShowOverridden} setRetainSelectedModule={props.setRetainSelectedModule} />
                                return <IndividualPropertyByModule selectedModuleIndex={selectedModuleIndex} moduleName={selectedModule.moduleName} propertyData={item} key={"props.propertyData.propertyName_" + index} instanceOrCompanySelection={props.instanceOrCompanySelection} showBottomBorder={index < selectedModule.properties.length - 1} isSuperAdmin={props.isSuperAdmin} onlyShowOverridden={props.onlyShowOverridden} setRetainSelectedModule={props.setRetainSelectedModule} />
                            })}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default reduxForm({
    form: "adminPreferencesListForm",
    destroyOnUnmount: true,
    enableReinitialize: true
})(List);