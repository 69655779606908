
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState,useRef,useEffect} from "react";
import { Row, Col, Container} from 'react-bootstrap';
import {useSelector } from "react-redux";

import PindCategories from "../../../../common/header/pind-categories";
import Breadcrumbs from "../../../../common/header/breadcrumbs";
import Header from "./header";
import FormComponent from "./form";
import TabsComponent from "./tabs";
import SyncAll from "./syncAll";
import ListLoader from "../../../../common/loaders/ListLoader";

import Cookies from 'universal-cookie';
import { useLocation } from "react-router";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import "_Css/form/_form.scss";

const cookies = new Cookies();
let cookiesArray = cookies.get('gph');
if (cookiesArray) cookiesArray = cookiesArray.replace('s:', '');
if (cookiesArray) cookiesArray = cookiesArray.substring(0, cookiesArray.lastIndexOf('.'));
cookiesArray = cookiesArray.split("~");
let isSuperAdmin = cookiesArray[60];

const Index = () => {
  const [panelResize,setPanelResize] = useState();
  const [companyType,setCompanyType] = useState([]);
  const [companyTypeErr, setCompanyTypeErr] = useState('');
  const [nameErr,setNameErr] = useState('');
  const [descriptionErr, setDescriptionErr] = useState('');
  const [companyValue, setCompanyValue] = useState([]);
  const [isRightSideVisible, setRightSideVisible] = useState(true);
  const [syncAllDisabel, setSyncAllDisable] = useState(false);
  const tr = useSelector((state) => state.spcmReducer.tr);
  const menuListFDNReducerData = useSelector((state) => state.menuListFDNReducer);
  const associationFlagOnLoad = useSelector((state) => state.editCompanyFdnList?.company?.associationActiveFlag);
  const associationFlagOnUpdate = useSelector((state) => state.associationFlag);
  const loader = useSelector((state) => state.commonLoader);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const refObj = useRef(null);

  useEffect(() => {
    const updateDimensions = () => {
      if (refObj.current) {
        const { width, height } = refObj.current.getBoundingClientRect();
        setDimensions({ width, height });
      }
    };

    const resizeObserver = new ResizeObserver(updateDimensions);
    if (refObj.current) {
      resizeObserver.observe(refObj.current);
    }

    window.addEventListener('resize', updateDimensions);

    return () => {
      if (refObj.current) {
        resizeObserver.unobserve(refObj.current);
      }
      window.removeEventListener('resize', updateDimensions);
    };
  }, [refObj.current]);


  const companyTypeFun = (list) =>{
    setCompanyType(list);
  };

  const showRightSideFun = (val) => {
    setRightSideVisible(val);
  };

  const [currentView,setCurrentView] = useState("tabView");

  const companyTypeErrFun = (err) => {
    if(err && typeof err == 'string'){
      setCompanyTypeErr(err);
    }else if(Array.isArray(err) && err.length==0){
      setCompanyTypeErr("error");
    }else{
      setCompanyTypeErr("");
    }
  };
  const companyData = (value) => {
    setCompanyValue(value)
  }

const showRightPanel = (view) => {
    switch (view) {
      case 'syncAll':
        return (<SyncAll  setCurrentView={setCurrentView} companyValue={companyValue} showRightSideFun={showRightSideFun} syncAllDisabel={syncAllDisabel} setSyncAllDisable={setSyncAllDisable}/>);
      default:
        return (
          <TabsComponent tr={tr} companyValue={companyValue} isSuperAdmin={isSuperAdmin} showRightSideFun={showRightSideFun} />
        );
    }
  }


  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Breadcrumbs activePageName={tr["Edit"]} parentPageurl='/company' parentPageName={tr["Company"]} />
      </Container>

      <Container fluid>
        <Header
          tr={tr}
          companyType = {companyType}
          companyTypeErrFun = {companyTypeErrFun}
          setNameErr={setNameErr}
          setDescriptionErr={setDescriptionErr}
          setCurrentView={setCurrentView}
          showRightSideFun={showRightSideFun}
          isRightSideVisible={isRightSideVisible}
        />
        <PanelGroup direction="horizontal">
            <Panel id="sidebar" minSize={33} onResize={(size) => setPanelResize(size)} order={1} defaultSize={isRightSideVisible ? 67 : 100} className={isRightSideVisible ? "isShowLeftPanel rwLeftPart" : "rwLeftPart"}>
              <FormComponent
                tr={tr}
                companyTypeFun = {companyTypeFun}          
                companyTypeErr = {companyTypeErr}
                companyTypeErrFun = {companyTypeErrFun}
                nameErr={nameErr}
                setNameErr={setNameErr}
                descriptionErr = {descriptionErr}
                setDescriptionErr={setDescriptionErr}
                menuListFDNReducerData={menuListFDNReducerData}
                companyData={companyData}
                loader={loader}
                companyType={companyType}
                showRightSideFun={showRightSideFun}
              />
            </Panel>
            {isRightSideVisible ?
                <>
                  <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
                  <Panel minSize={33} order={2} defaultSize={33} className={dimensions.width < 380 ? "toSmall":null}>
                    <div ref={refObj} className="stickyArea rBoxStyle">
                      {loader.isLoading ? (
                        <ListLoader />
                      ) : (
                        showRightPanel(currentView)
                      )} 
                    </div>
                  </Panel>
                </> : ""
            }
          </PanelGroup>
      </Container>
    </main>
  )
}

export default Index;
