
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Form,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import dummyimage from "_Images/service-icons/service-dummy.png";
import { Field, change, reduxForm } from "redux-form";
import {
    TypeaheadExampleSingleSelect,
  _dropDown,
  _inputField,
  _textArea,
} from "../../../../common/formFields";
import {
    getCategoryListAction,
    getForumListAction,
    getInitialListGroupAction,
  getcompanyMenuListAction,
} from "../../../../../actions/foundation/groupAction";
// import { getCostCenterCompanyOptions } from "../../../../../actions/foundation/costCenterAction";
import { getCompanyOptionsList } from "../../../../../actions/foundation/commonAction";

import Cookies from 'universal-cookie';
const cookies = new Cookies();
let cookiesArray = cookies.get('gph');
if (cookiesArray) cookiesArray = cookiesArray.replace('s:', '');
if (cookiesArray) cookiesArray = cookiesArray.substring(0, cookiesArray.lastIndexOf('.'));
cookiesArray = cookiesArray.split("~");
let loggedInUser = cookiesArray[42];

const CreateForm = (props) => {
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [selectedForum, setSelectedForum] = useState([]);
  const [forumList, setForumList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [categoryList, setCategoryList] =  useState([]);
  const [groupTypeValue, setGroupTypeValue] = useState('');
  const [groupTypeList, setGroupTypeList] = useState([]);
  const [isExternal, setIsExternal] = useState("false");
  const [individualAssignValue, setIndividualAssignValue] = useState("");
  const dispatch = useDispatch();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const companyData = useSelector((state) => state.costCenterCompanyDropdown);
  const companyTypeOptions = useSelector((state) => state.companyMenuListValues);
  // const groupInitialListValues = useSelector((state) => state.groupInitialValues);
  const forumValues = useSelector((state) => state.groupForumValues);
  const categoryValues = useSelector((state) => state.groupCategoryValues);


    useEffect(() => {
      dispatch(getCompanyOptionsList());
      dispatch(getInitialListGroupAction());
      dispatch(change('CreateGroup', 'isexternal', "false"));
    }, [])
  
       

    useEffect(() =>
    {
      if(companyData && companyData.length > 0)
      {
        setCompanyList(companyData)
      }

    }, [companyData])

    useEffect(() =>
    {
      if(forumValues && forumValues.length > 0)
      {
        setForumList(forumValues)
      }
    }, [forumValues])
  
    useEffect(() =>
    {
      if(categoryValues && categoryValues.length > 0)
      {
        setCategoryList(categoryValues)
      }

    }, [categoryValues])

  useEffect(() => {
      console.log("CreateGroupType", companyTypeOptions)
        if(companyTypeOptions && companyTypeOptions.length > 0)
      {
        setGroupTypeList(companyTypeOptions)
      }
    },[companyTypeOptions])

    const onCompanySelection=(selectedCompany)=>{
        if (selectedCompany.length > 0) { 
            setSelectedCompany(selectedCompany)
            dispatch(change('CreateGroup', 'company_name', selectedCompany[0].label))
            dispatch(change('CreateGroup', 'company_id', selectedCompany[0].id))
            props.setCompanyValue(selectedCompany);
            dispatch(getcompanyMenuListAction(selectedCompany[0].id, "Foundation", "Group Type", '1', '0'));
            dispatch(getForumListAction(selectedCompany[0].id));
            dispatch(getCategoryListAction());
          } else {
            setSelectedCompany([]);
            dispatch(change('CreateGroup', 'company_name', ''));
            dispatch(change('CreateGroup', 'company_id', ''));
          }
    }

    const onCrossClickCompany = () => {
      setSelectedCompany([]);
      setSelectedForum([]);
      setSelectedCategory([]);
      
      props.setCompanyValue([]);
      dispatch(change('CreateGroup', 'company_name', ''));
      dispatch(change('CreateGroup', 'company_id', ''));
      
      props.setForumValue([]);
      dispatch(change('CreateGroup', 'forum_name', ''));
      dispatch(change('CreateGroup', 'forum_id', ''));
      
      props.setCategoryValue([]);
      dispatch(change('CreateGroup', 'category_name', ''));
      dispatch(change('CreateGroup', 'category_id', ''));
    }
    
  const onGroupChange = (event) => {
        setGroupTypeValue(event.target.value);
        dispatch(change('CreateGroup', 'groupTypeLabel', event.target[event.target.selectedIndex].text));
        setSelectedForum([]);
        props.setForumValue([]);
        dispatch(change('CreateGroup', 'forum_name', ''));
        dispatch(change('CreateGroup', 'forum_id', ''));
    
        setSelectedCategory([]);
        props.setCategoryValue([]);
        dispatch(change('CreateGroup', 'category_name', ''));
        dispatch(change('CreateGroup', 'category_id', ''));
  }
    const companyInputChange = () => {
      onCrossClickCompany();
    }
    
  
    const onForumSelection = (selectedForum) => {
      if (selectedForum.length > 0) { 
        setSelectedForum(selectedForum)
        dispatch(change('CreateGroup', 'forum_name', selectedForum[0].label))
        dispatch(change('CreateGroup', 'forum_id', selectedForum[0].id))
        props.setForumValue(selectedForum);
      } else {
        setSelectedForum([]);
        props.setForumValue([]);
        dispatch(change('CreateGroup', 'forum_name', ''));
        dispatch(change('CreateGroup', 'forum_id', ''));
      }
    }
    const onCrossClickForum = () => {
      setSelectedForum([]);
      props.setForumValue([]);
      dispatch(change('CreateGroup', 'forum_name', ''));
      dispatch(change('CreateGroup', 'forum_id', ''));
    }
    const forumInputChange = () => {
      onCrossClickForum();
    }

    const onCategorySelection = (selectedCategory) => {
      if (selectedCategory.length > 0) { 
        setSelectedCategory(selectedCategory)
        dispatch(change('CreateGroup', 'category_name', selectedCategory[0].label))
        dispatch(change('CreateGroup', 'category_id', selectedCategory[0].id))
        props.setCategoryValue(selectedCategory);
      } else {
        setSelectedCategory([]);
        props.setCategoryValue([]);
        dispatch(change('CreateGroup', 'category_name', ''));
        dispatch(change('CreateGroup', 'category_id', ''));
      }
    }
    
    const onCrossClickCategory = () => {
      setSelectedCategory([]);
      props.setCategoryValue([]);
      dispatch(change('CreateGroup', 'category_name', ''));
      dispatch(change('CreateGroup', 'category_id', ''));
    }
    const categoryInputChange = () => {
      onCrossClickCategory();
    }
  
    const onSelectIsExternal = (event) => {
            setIsExternal(event.target.value);
            dispatch(change('CreateGroup', 'isexternal', event.target.value));
    }
    
    const onIndividualAssignmentSelect = (event) => {
      setIndividualAssignValue(event.target.value);
      dispatch(change('CreateGroup', 'IndivAssign', event.target.value));
      if (individualAssignValue == "No" || individualAssignValue == "") {
        props.setAutoAlgoErr("");
        dispatch(change('CreateGroup', 'autoAssignAlgo',''))
    }
  }
  
  // const onEscalationEnbledSelected = (event) => {
  //   // setIsExternal(event.target.value);
  //   dispatch(change('CreateGroup', '', event.target.value));
  // }
//   const onAutoAssignAlgoSelection = (event) => {
//     // setIsExternal(event.target.value);
//     dispatch(change('CreateGroup', '', event.target.value));
// }

    const  validateGroupsField = (fieldType, event)=> {
        switch (fieldType) {
          case "name":
            if (event.target.value == "") {
              props.setNameErr("error");
            } else {
              props.setNameErr("");
            }
            break;
          case "status":
            if (event.target.value == "") {
              props.setStatusErr("error");
            } else {
              props.setStatusErr("");
            }
            break;
          case "autoAssignAlgo":
            if (event.target.value == "") {
              props.setAutoAlgoErr("error");
            } else {
              props.setAutoAlgoErr("");
            }
            break;
          default:
        }
    }
    
  console.log("TypeOfCompany", groupTypeValue);
    return (
        <>
    <Form>
      <Row>
        <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
            <span className="rStar"></span>
            <Form.Label>
              {tr["Name"]}
            </Form.Label>
            <Field
              component={_inputField}
              name="name"
              className={"form-control " +( props.nameErr)}
              maxLength={50}
              onBlur={(event) => {
                validateGroupsField("name", event);
              }}
            />
          </Form.Group>
        </Col>

        <Col md={6} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>{tr["Group Code"]}</Form.Label>
            <Field
              name="code"
              className={"form-control"}
              component={_inputField}
              disabled
            />
          </Form.Group>
        </Col>
        <Col md={6} sm={6} xs={12}>
          <Form.Group className="form-group">
            <span className="rStar" />
            <Form.Label bsClass="">
              {tr["Company"]}
            </Form.Label>
            <Field
              name="company_name"
              className="form-control"
              component={TypeaheadExampleSingleSelect}
              onSelection={onCompanySelection}
              options={companyList}
              selectedOptions={selectedCompany}
              onCrossClick={onCrossClickCompany}
              errorClass={props.companyTypeErr}
              setErrorColor={props.setCompanyErrorColor}
              onInputChange={companyInputChange}
            />
          </Form.Group>
        </Col>

        <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
            <span className="rStar"></span>
            <Form.Label>{tr["Status"]}</Form.Label>
            <Field
              name="status"
              component="select"
              className={"form-control "+(props.statusErr)}
              onBlur={(event) => {
                validateGroupsField("status", event);
              }}
            >
              <option value="">{tr["Select"]}</option>
              <option value="1">{tr["Active"]}</option>
              <option value="0">{tr["Inactive"]}</option>
            </Field>
          </Form.Group>
        </Col>
        <Col md={6} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>{tr["Group email address"]}</Form.Label>
            <Field
              component={_inputField}
              name="email_address"
              className={"form-control"}
              maxLength={50}
            />
          </Form.Group>
        </Col>
        <Col md={6} sm={6} xs={12}>
              <Form.Group
                className="form-group"
                // onChange={onSelectIsExternal}
              >
            <span className="rStar"></span>
        <Form.Label>{"Is External"}</Form.Label>
            <Row>
              <Col lg={4} md={6} sm={6} xs={6}>
              <Form.Check
                      inline
                      type="radio"
                      value="true"
                      name="isexternal"
                      label="True"
                      checked={isExternal === "true"}
                      onClick={(e)=>onSelectIsExternal(e)}
                    ></Form.Check>
                    </Col>
                    <Col lg={4} md={6} sm={6} xs={6}>
                    <Form.Check
                      inline
                      type="radio"
                      value="false"
                      name="isexternal"
                      label="False"
                      checked={isExternal === "false"}
                      onClick={(e)=>onSelectIsExternal(e)}
                    ></Form.Check>
              </Col>
            </Row>
          </Form.Group>
        </Col>

        <Col md={6} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>{tr["Group Type"]}</Form.Label>
            <Field
              name="groupTypeLabel"
              className={"form-control"}
              component={_dropDown}
              options={groupTypeList}
              onChange={(e)=>onGroupChange(e)}
            ></Field>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="form-group">
            <Form.Label>{tr["Description"]} </Form.Label>
            <Field
              name="description"
              className={"form-control " + props.descriptionErr}
              component={_textArea}
              rows={1}
            />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            {  groupTypeValue == '50' ?
            <>
                <Col md={6} sm={6} xs={12}>
                <div className="dvTypehdropdown">
              <Form.Group className="form-group">
              <span className="rStar"></span>
            <Form.Label bsClass="">
            {tr["Forum"]}
            </Form.Label>
            <Field
              name="forum_name"
              className="form-control"
              component={TypeaheadExampleSingleSelect}
              onSelection={onForumSelection}
              options={forumList}
              selectedOptions={selectedForum}
              onCrossClick={onCrossClickForum}
              errorClass={props.forumErr}
              setErrorColor={props.setForumErrorColor}
              onInputChange={forumInputChange}
            />
          </Form.Group>
          </div>
        </Col>
        <Col md={6} sm={6} xs={12}>
        <div className="dvTypehdropdown">
          <Form.Group className="form-group">
            <span className="rStar"></span>
            <Form.Label bsClass="">
              {tr["Category"]}
            </Form.Label>
            <Field
              name="category_name"
              className="form-control"
              component={TypeaheadExampleSingleSelect}
              onSelection={onCategorySelection}
              options={categoryList}
              selectedOptions={selectedCategory}
              onCrossClick={onCrossClickCategory}
              errorClass={props.categoryErr}
              setErrorColor={props.setCategoryErrorColor}
              onInputChange={categoryInputChange}
              />
              </Form.Group>
              </div>
            </Col>
            </> : ''}
            </Row>
          <Row>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
            <Form.Label>{["Individual Assignment Enabled"]}</Form.Label>
            <Field
              name="IndivAssign"
              className={"form-control"}
              component="select"
              defaultSelect={individualAssignValue}
              onChange={(e)=>onIndividualAssignmentSelect(e)}
            >
                <option value="">{tr["Select"]}</option>
                <option value="No">{tr["No"]}</option>
                <option value="Yes">{tr["Yes"]}</option>
              </Field>
              </Form.Group> 
            </Col>
            {/* <Col md={3} sm={3} xs={6} >
              <Form.Group className="form-group">
            <Form.Label>{["Escalation Plan Enabled"]}</Form.Label>
            <Field
              name="EscPlan"
              className={"form-control"}
              component="select"
              // onChange={(e)=>onEscalationEnbledSelected(e)}
            >
                <option value="">{tr["Select"]}</option>
                <option value="No">{tr["No"]}</option>
                <option value="Yes">{tr["Yes"]}</option>
              </Field>
              </Form.Group>
            </Col> */}
            {individualAssignValue == 'Yes' ? <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
            <span className="rStar"></span>
            <Form.Label>{["Auto Assignment Algorithm"]}</Form.Label>
            <Field
              name="autoAssignAlgo"
              component="select"
              className={"form-control "+(props.autoAlgoErr)}
              onBlur={(event) => {
                validateGroupsField("autoAssignAlgo", event);
              }}
              // onChange={(e)=>onAutoAssignAlgoChange(e)}
            >
              <option value="">{tr["Select"]}</option>
              <option value="Round Robin">{["Round Robin"]}</option>
              <option value="Last Assignment First">{["Last Assignment First"]}</option>
              </Field>
              </Form.Group>
              </Col> : ''}
          </Row>    
      </Form>
      </>
  );
};

export default reduxForm({
  form: "CreateGroup",
  destroyOnUnmount: false,
  enableReinitialize: true,
})(CreateForm);
