
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../../initialState';

export const ordersList = (state = initialState.ordersList, action) =>{
	switch (action.type){
		case 'LOAD_ORDERS_SUCCESS':
			return JSON.parse(JSON.stringify(action.ordersList.data));
		case 'EMPTY_ORDERS_REDUCER':
			return [];
		default:
			return state;
	}
}

export const ordersListGblSearch = (state = initialState.ordersListGblSearch, action) =>{
	switch (action.type){
		case 'LOAD_ORDERS_SUCCESS_GBL_SEARCH':
			return action.ordersListGblSearch.data;
		default:
			return state;
	}
}


export const myOrderOrderDetails = (state = {}, action)=> {
	switch (action.type){
		case 'LOAD_ORDER_DETAILS_SUCCESS':
			return action.orderDetails.data;
			default:
			return state;
	}
}
export const loadReasons = (state=[], action)=>{
	if(action.type==='LOAD_CANCELLATION_REASON'){
		return action.reasons;
	}else{
        return state;
	}
}

export const myBreakFixOrderOrderDetails = (state = {}, action)=> {
	switch (action.type){
		case 'LOAD_BREAKFIXORDER_DETAILS_SUCCESS':
			return action.breakFixOrderDetails.data;
			default:
			return state;
	}
}
export const myBreakFixOrderOrderDetailsInteger = (state = {}, action)=> {
	switch (action.type){
		case 'LOAD_BREAKFIXORDER_DETAILS_SUCCESS_INTEGER':
			return action.breakFixOrderDetailsInt.data;
			default:
			return state;
	}
}
export const urgencyList = (state={}, action) => {
	switch (action.type){
		case 'LOAD_URGENCY_LIST':
			  return action.oldUrgencyList;
		default: return state;
	}
}

export const offeringForOrder = (state = [], action) => {
	if(action.orderDetails){
		console.log('myOrdersReducer '+action.orderDetails.data);
	}

	switch (action.type){
		case 'LOAD_OFFERING_FOR_ORDER_SUCCESS':
			return action.orderDetails.data;
			default:
			return state;
	}
};

//for approver list--params(itemid,offeringid)
export const approverListDetails = (state = {}, action)=> {
	if(action.approverList){
		console.log(action.approverList.data);
	}
	switch (action.type){
		case 'LOAD_APPROVERLIST_SUCCESS':
			return action.approverList.data;
			default:
			return state;
	}
}

export const myOrderQuestionDetails = (state = [], action)=> {
	switch (action.type){
		case 'LOAD_QUESTIONS_CATEORY_WISE_SUCCESS':
			return action.questionsCategoryWise.data;
			default:
			return state;
	}
}

export const categoryName= (state = {}, action) => {
	// console.log("action OBJ :",action.categoryName)
	switch (action.type){
		case 'CATEGORY_NAME':
			return action.categoryName;
		default:
			return state;
	}
}

export const resetSortArrows = (state = true, action) =>{
	switch (action.type){
		case 'RESET_SORTING_ARROWS':
			return action.reset;
		case 'SORTING_ARROWS_CHANGED':
			return action.reset;
		default:
			return state;
	}
}