
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState, useEffect } from "react";
import { Row, Col, Form, Table, Nav, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";
import swal from 'sweetalert';
import { loadContractRelatedWorkDetails } from "../../../../actions/ham/contractAction";
import { GLOBAL } from "_Globals";
import {ImSpinner6} from "react-icons/im";
import Cookies from "universal-cookie";
import { loadChangeRelatedWorkDetails } from "../../../../actions/changeManagement/cmdbchangeRelatedWorkAction";
import { Link } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import ListLoader from "_Commons/loaders/ListLoader";

function ContractRelationship(props) {
  const tr = useSelector((state) => state.spcmReducer.tr);
  const dispatch = useDispatch();
  const [toggleFormAndList, setToggleFormAndList] = useState("list");
  const [key, setKey] = useState(1);
  const [moduleTab, setModuleTab] = useState('ci');
  const [module, setModule] = useState(null);
  const [searchName, setSearchName] = useState("");
  const [isErrorVisible , setIsErrorVisible] = useState(false);
  // const [hasLink, setHasLink] = useState(false);
  const searchDetails = useSelector((state) => state.cmdbChangeRelatedWorkDetails?.data || []);
  const initialValue = useSelector((state) => state.contractEditList);
  const contractWorkDetails = useSelector((state) => state.contractWorkDetails);

  const showLoader = useSelector((state) => state.rightMenuShowLoader.loading);
  const cookies = new Cookies();
  let app_url = cookies.get("gph");
  if (app_url) app_url = app_url.replace("s:", "");
  if (app_url) app_url = app_url.substring(0, app_url.lastIndexOf("."));
  app_url = app_url.split("~");
  app_url = app_url[18];
  // let onRoleVisible = cookiesData[33] ? cookiesData[33].split(',') : [];

  useEffect(() => {
    if (initialValue.CONTRACT_ID) {
      dispatch(loadContractRelatedWorkDetails(initialValue.CONTRACT_ID, moduleTab, "Contract"));
    }
  }, [initialValue.CONTRACT_ID]);

  // useEffect(() => {
  //   if (onRoleVisible.includes("37") || onRoleVisible.includes("38") || onRoleVisible.includes("39")){
  //     setHasLink(true);
  //   }
  // }, []);

  const changeTab = (k) => {
    setKey(k);
    k == 1 ? setModuleTab('ci') : setModuleTab('sla')
    dispatch(loadContractRelatedWorkDetails(initialValue.CONTRACT_ID, k == 1 ? 'ci' : 'sla', "Contract"));
  }
  
  const checkfilter = (e) => {
    // console.log("e=====",e.target.value )
    dispatch({type: "RESET_ASSET_DETAILS"})
    setModule(e.target.value);
  };

  const formAndList = () => {
    // dispatch(loadChangeRelatedWorkDetails("ci_name", "","CMDB", '3215'));
    dispatch({type: "RESET_ASSET_DETAILS"})
    setIsErrorVisible(false);
    setModule(null);

    if (toggleFormAndList == "list") {
      setToggleFormAndList("form");
      setSearchName("")
    } else {
      setToggleFormAndList("list");
      setSearchName("")
    }
  };

  const onSearch = (e) => {
    // console.log("e=====", e.target.value);
    setSearchName(e.target.value);
  };

  const onSearchClick = (arr = [], value) => {
    // console.log("Search Clicked");
    // console.log(module, "module======")
    // console.log(searchName, "searchName======")
    if(module == null || module == "Select" || searchName == "" || searchName == null ){
       setIsErrorVisible(true)
    }
    else{
      if(module == 'ci_name')
        dispatch(loadChangeRelatedWorkDetails("ci_name", searchName, "CMDB", initialValue.COMPANY_ID));
      else if(module == 'sla')
        dispatch(loadChangeRelatedWorkDetails("sla", searchName, "SLA", initialValue.COMPANY_ID));
    }
  };

  const renderRelatedWorkLog = (relatedLogData) => {
    if (!Array.isArray(relatedLogData)) relatedLogData = [];

    if (relatedLogData.length == 0) {
      // console.log(showLoader.loading, "showLoader2")
      return (
        <tr>
          {/* {showLoader.loading ? <ImSpinner6 className="icn-spinner"/> : <td className="text-c" colSpan={3}>No Data Available</td>} */}
        </tr>
      );
    } else {
      if(module == 'ci_name') {
        return relatedLogData.map((relatedObj, index) => {
          let createddate = relatedObj.CREATED_AT.substring(
            0,
            relatedObj.CREATED_AT.indexOf(".")
          );
          return (
            <div className="margin-t-5 margin-b-10 border-b padding-b-5">
              <Row>
                <Col md={10} className="padding-r-0">
                  {relatedObj.CI_NAME} - {createddate} - {relatedObj.CREATED_BY}
                  <div className="tbSerialTxt">
                    Serial Number -{" "}
                    {relatedObj.SERIAL_NUMBER == "" ||
                    relatedObj.SERIAL_NUMBER == null
                      ? "NA"
                      : relatedObj.SERIAL_NUMBER}
                  </div>
                </Col>
                <Col md={2} className="text-c">
                  <a
                    onClick={() => {postRelatedData(relatedObj.CI_ID);}}
                    title={tr["Relate"]}
                  >
                    <i className="fa fa-chevron-circle-right f-size-16 theme1"></i>
                  </a>
                </Col>
              </Row>
            </div>
          );
        });
      } else if(module == 'sla') {
        return relatedLogData.map((relatedObj, index) => {
          return (
            <div className="margin-t-5 margin-b-10 border-b padding-b-5">
              <Row>
                <Col md={10} className="padding-r-0">
                  {relatedObj.slaName} - {relatedObj.modifiedOn} - {relatedObj.createdByName}
                  <div className="tbSerialTxt">
                    Module -{" "}{relatedObj.moduleName} {"; "}
                    Status -{" "}{relatedObj.slaStatus}
                  </div>
                </Col>
                <Col md={2} className="text-c">
                  <a
                    onClick={() => {postRelatedData(relatedObj.slaId, relatedObj.slaName);}}
                    title={tr["Relate"]}
                  >
                    <i className="fa fa-chevron-circle-right f-size-16 theme1"></i>
                  </a>
                </Col>
              </Row>
            </div>
          );
        });
      }
    }
  };

  const renderRelatedDataLog = (relatedDataLog) => {
    return (
      <div>
        <div>
          <Row>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label>{tr["Search By"]}</Form.Label>
                <Form.Select
                  defaultValue={null}
                  componentClass="select"
                  onChange={checkfilter}
                >
                  <option default value="Select">{tr["Select"]}</option>
                  <option value="ci_name">{tr["CI Name"]}</option>
                  <option value="sla">{tr["SLA"]}</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Label>{tr[""]}</Form.Label>
              <Form.Control
                type="text"
                placeholder={tr["search"]}
                onChange={onSearch}
              />
            </Col>
          </Row>
          <div className="btnNew margin-t-10">
            <Button
              className="rgSidrkBtn smallBtn"
              size="sm"
              bsStyle="primary"
              title={tr["Search"]}
              onClick={onSearchClick}
              disabled={showLoader}
            >
              {tr["Search"]}{showLoader ? <ImSpinner6 className="icn-spinner"/> : null}
            </Button>
          </div>
        </div>
        {searchDetails.length > 0 ? renderRelatedWorkLog(searchDetails) : "Data not available"}
      </div>
    );

    // }
  };

  const postRelatedData = (id, slaName = '') => {
    // console.log("initail===", initialValue);
    let payloadObj = {
      ci_id: id,
      contract_id_related: initialValue.CONTRACT_ID,
      contract_id_number: initialValue.CONTRACT_NUM,
      ci_id_reported: initialValue.CREATED_AT,
      created_by: initialValue.CREATED_BY,
      module: "Contract",
    };
    if(module == 'sla') {
      payloadObj.related_name = slaName;
      payloadObj.module = module;
    }
    axios.post(GLOBAL.contractRelatedUrl, payloadObj).then((response) => {
      // console.log("response-------1234", response.data.message);
      if (response.data.status === false) {
        Swal.fire({
          title: response.data.message,
          button: "OK",
        });
      } else {
        setToggleFormAndList("list");
        setModule(null);
      }
      // setToggleFormAndList("list"),
      // setModule(null);
      if(module == 'ci_name') {
        changeTab(1);
      } else {
        changeTab(2);
      }
      // console.log(response, "response====");
    });
  };

  const deleteRelation = (CI_ID, CONTRACT_ID_RELATED, mod) => {
    let relatedurl = "";
    relatedurl ="/api/config_items/relationship/" + CI_ID + "/" + CONTRACT_ID_RELATED + "/" + mod;
    axios.delete(relatedurl).then((response) => {
      if (!response || response.status != "200") {
        swal({
          text: "Deletion failed.",
          button: tr["OK"],
        });
      } else {
        swal({
          text: "Deleted Relation",
          button: tr["OK"],
        });
        dispatch(loadContractRelatedWorkDetails(initialValue.CONTRACT_ID, moduleTab, "Contract"));
      }
      // console.log(response, "====");
    });
  };

  return (
    <div>
      {/* {console.log(listTableToggle, "listTableToggle")} */}
      {toggleFormAndList == "list" ? (
        <div>
          <Row>
            <Col xs={12}>
              <div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
                <ul>
                  <li>
                    <Button
                      bsPrefix=" "
                      onClick={formAndList}
                      bsClass=""
                      bsStyle=""
                      title={
                        toggleFormAndList == "list"
                          ? tr["Add"]
                          : tr["Relationships"]
                      }
                    >
                      <i
                        className={
                          toggleFormAndList == "list"
                            ? "fa fa-plus-square f-size-16"
                            : "fa fa-list-ul f-size-16"
                        }
                      ></i>
                    </Button>
                  </li>
                  <li className="d-md-none">
                      <Link to="javascript:void(0)" id="closeButton"
                          className="closerightPanelBtn"
                          title={tr["Minimize the details panel"]}
                          onClick={() => { props.rightEditPanel(false); props.setView(""); }}
                          >
                          <IoClose/>
                      </Link>
                  </li>
                </ul>
              </div>
              <div className="rPageHeading">
                <div className="offNam margin-t-5 margin-b-5">
                  {tr["Relationships"]}
                </div>
              </div>
            </Col>
          </Row>

          <div className="rBoxGap">
            <div className="rBoxTabs cntrtTab">
              <Tabs activeKey={key} onSelect={(k) => changeTab(k)} id="tabId">
                <Tab eventKey={1} title={tr["CI"]}>
                  {showLoader ? <div><ListLoader myStyle={{ marginTop: "20px" }} /></div>
                  :
                  contractWorkDetails.length != 0 ?
                    <Table responsive striped bordered condensed hover>
                      <thead>
                        <tr>
                          <th>{tr["CI Name"]}</th>
                          <th>{tr["Created On"]}</th>
                          <th>{tr["Related By"]}</th>
                          <th className="text-c">{tr["Action"]}</th>
                        </tr>
                      </thead>
                      <tbody>                        
                        {contractWorkDetails?.map((data) => (
                          <tr>
                            <td>
                            <a title="CI Name" href={app_url + "/home?editcmdb=" + data.CI_ID} target="_blank">
                              {" "}
                                {data.CI_NAME_RELATED}
                            </a>
                            <div className="tbSerialTxt">
                              Serial Number - {(data.SERIAL_NUMBER_CI_NAME_RELATED == "" || data.SERIAL_NUMBER_CI_NAME_RELATED == null) ? "NA" : data.SERIAL_NUMBER_CI_NAME_RELATED}
                            </div>
                            </td>
                            {/* <td>
                                {hasLink ? (
                                  <a
                                    title="CI Name"
                                    href="javascript:void(0)" 
                                    onClick={() => {
                                      let newWindow = window.open();
                                      newWindow.opener = null;
                                      newWindow.location = app_url + "/home?editcmdb=" + data.CI_ID
                                    }}
                                  >
                                    {" "}
                                    {data.CI_NAME_RELATED}
                                  </a>
                                ) : (
                                  <a
                                    href="#"
                                    onClick={() => {
                                      Swal.fire(
                                        "You do not have the required role to view this CI"
                                      );
                                    }}
                                  >
                                    {" "}
                                    {data.CI_NAME_RELATED}
                                  </a>
                                )}
                                <div className="tbSerialTxt">
                                  Serial Number -{" "}
                                  {data.SERIAL_NUMBER_CI_NAME_RELATED == "" ||
                                  data.SERIAL_NUMBER_CI_NAME_RELATED == null
                                    ? "NA"
                                    : data.SERIAL_NUMBER_CI_NAME_RELATED}
                                </div>
                              </td> */}
                            <td>{data.CREATED_ON}</td>
                            <td>{data.CREATED_BY}</td>
                            <td className="text-c f-size-13">
                              <i
                                onClick={() => {deleteRelation(data.CI_ID, data.CONTRACT_ID_RELATED, "Contract");}}
                                className="fa fa-trash-o cursorPoint"
                              ></i>
                            </td>
                          </tr>
                        ))
                        }
                      </tbody>
                    </Table>
                    :
                    "No Data Available"
                  }
                </Tab>
                <Tab eventKey={2} title={tr["SLA"]}>
                  {showLoader ? <div><ListLoader myStyle={{ marginTop: "20px" }} /></div>
                  :
                  contractWorkDetails.length != 0 ?
                    <Table responsive striped bordered condensed hover>
                      <thead>
                        <tr>
                          <th>{tr["SLA Name"]}</th>
                          <th>{tr["Created On"]}</th>
                          <th>{tr["Related By"]}</th>
                          <th className="text-c">{tr["Action"]}</th>
                        </tr>
                      </thead>
                      <tbody>                        
                        {contractWorkDetails?.map((data) => (
                          <tr>
                            <td>
                            {/* <a title="CI Name" href={app_url + "/home?editSlaDef=" + data.SLA_ID} target="_blank">
                              {" "}
                                {data.CI_NAME_RELATED}
                            </a> */}
                            <div>
                              {(data.RELATED_NAME == "" || data.RELATED_NAME == null) ? "NA" : data.RELATED_NAME}
                            </div>
                            </td>
                            <td>{data.CREATED_ON}</td>
                            <td>{data.CREATED_BY}</td>
                            <td className="text-c f-size-13">
                              <i
                                onClick={() => {deleteRelation(data.SLA_ID, data.CONTRACT_ID_RELATED, "sla");}}
                                className="fa fa-trash-o cursorPoint"
                              ></i>
                            </td>
                          </tr>
                        ))
                        }
                      </tbody>
                    </Table>
                    :
                    "No Data Available"
                  }
                </Tab>
              </Tabs>
            </div>
          </div> 
        </div>
      ) : (
        <div>
          <Row>
            <Col xs={12}>
              <div className="rPageHeadActBtn">
                <ul>
                  <li>
                    <Button
                      bsPrefix=" "
                      onClick={formAndList}
                      bsClass=""
                      bsStyle=""
                      title={
                        toggleFormAndList == "list"
                          ? tr["Add"]
                          : tr["Relationships"]
                      }
                    >
                      <i
                        className={
                          toggleFormAndList == "list"
                            ? "fa fa-plus-square f-size-16"
                            : "fa fa-list-ul f-size-16"
                        }
                      ></i>
                    </Button>
                  </li>
                </ul>
              </div>
              <div className="rPageHeading">
                <div className="offNam margin-t-5 margin-b-5">
                  {tr["Relationships"]}
                </div>
              </div>
            </Col>
          </Row>
          <div className="rBoxGap">{renderRelatedDataLog()}</div>
        </div>
      )}
    </div>
  );
}

export default ContractRelationship;
