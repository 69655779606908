
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import AuditLogs from "../../../governance-meeting/common/AuditLogs";
import ListLoader from "../../../common/loaders/ListLoader";
import WorkNotes from "../WorkNotes/add-edit";

const GovAuditLogs = ({
  meetingDefData,
  isAutorize,
  auditData,
  isFetching,
  auditDataRefetch
}) => {
  const [tabKey, setTabKey] = useState(1);
  const meetingId = meetingDefData?.meetingDefinationId;
  const companyId = meetingDefData?.companyId;
  const status = meetingDefData?.status;

  // console.log(meetingId, status);
  // const { data: auditData, isFetching } = useGetGovAudiLogsQuery(
  //   {
  //     queryString: `?definationId=${meetingId}&tenantId=${companyId}&module=meeting`,
  //   },
  //   {
  //     skip: !meetingId,
  //   }
  // );

  const handleSelect = (tabKey) => {
    if(tabKey==='6'){
      auditDataRefetch();
    }
    setTabKey(tabKey);
  };

  return (
    <div className="panel-body attachment-body">
      <div className="custom-container-fluid">
        <div className="stickyArea rBoxStyle">
          <div className="rPageHeading d-flex p-3">
            <div className="flex-grow-1 offNam mw-100">Activity Details</div>
          </div>
            <div className="rBoxGap">
              <div className="rBoxTabs">
                <Tabs activeKey={tabKey} onSelect={handleSelect} id="tabId">
                  <Tab eventKey={1} title="Comments">
                    <WorkNotes
                      meetingId={meetingId}
                      companyId={companyId}
                      isAutorize={isAutorize}
                    />
                  </Tab>
                  <Tab eventKey={6} title="Audit Log">
                    {isFetching ? (
                      <ListLoader />
                    ) : (
                      <AuditLogs data={auditData} />
                    )}
                  </Tab>
                </Tabs>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default GovAuditLogs;
