
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';
import moment from 'moment';
export const slaCompany = (state = {}, action) => {
	switch (action.type) {
		case 'LOAD_SLACOMPANY_SUCCESS':
			return action.slaCompany.data;
		case 'LOAD_SLACOMPANY_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}

export const slaModule = (state = {}, action) => {
	switch (action.type) {
		case 'LOAD_SLAMODULE_SUCCESS':
			return action.slaModule.data;
		case 'LOAD_SLAMODULE_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}

export const slaStatus = (state = {}, action) => {
	switch (action.type) {
		case 'LOAD_SLASTATUS_SUCCESS':
			return action.slaStatus.data;
		case 'LOAD_SLASTATUS_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}

export const slaService = (state = {}, action) => {
	// console.log("slaservice",action);
	switch (action.type) {
		case 'LOAD_SLASERVICE_SUCCESS':

			return action.slaService == null ? action.slaService : action.slaService.data;
		case 'LOAD_SLASERVICE_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}
export const slaImpacted = (state = {}, action) => {
	// console.log("action in impacted",action);
	switch (action.type) {
		case 'LOAD_IMPACTED_SUCCESS':
		

			return action.slaService == null ? action.slaService : action.slaService.data;
		case 'LOAD_SLAIMPACTED_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}
export const slaType = (state = {}, action) => {
	switch (action.type) {
		case 'LOAD_SLATYPE_SUCCESS':
			return action.slaType.data;
		case 'LOAD_SLATYPE_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}

export const slaTarget = (state = {}, action) => {
	switch (action.type) {
		case 'LOAD_SLATARGET_SUCCESS':
			return action.slaTarget == null ? action.slaTarget : action.slaTarget.data;
		case 'LOAD_SLATARGET_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}


export const slaWorkdaySchedules = (state = {}, action) => {
	switch (action.type) {
		case 'LOAD_SLAWRKSCH_SUCCESS':
			return action.slaWorkdaySchedules == null ? action.slaWorkdaySchedules : action.slaWorkdaySchedules.data;
		case 'LOAD_SLAWRKSCH_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}

export const slaHolidaySchedules = (state = {}, action) => {
	switch (action.type) {
		case 'LOAD_SLAHOLSCH_SUCCESS':
			return action.slaHolidaySchedules == null ? action.slaHolidaySchedules : action.slaHolidaySchedules.data;
		case 'LOAD_SLAHOLSCH_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}

export const slaWorkdaySchedulesDays = (state = {}, action) => {
	switch (action.type) {
		case 'LOAD_SLAWORKDAYSCHDAY_SUCCESS':
			return action.slaWorkdaySchedulesDays.data;
		case 'LOAD_SLAWORKDAYSCHDAY_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}

export const slaWorkdaySchedulesStatus = (state = {}, action) => {
	switch (action.type) {
		case 'LOAD_SLAWORKDAYSCHSTATUS_SUCCESS':
			return action.slaWorkdaySchedulesStatus.data;
		case 'LOAD_SLAWORKDAYSCHSTATUS_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}

export const slaDefEditDetails = (state = {}, action) => {
	switch (action.type) {
		case 'LOAD_SLADEFEDITDETAILS_SUCCESS':
			return action.slaDefEditDetails.data;
		case 'LOAD_SLADEFEDITDETAILS_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}

export const slaCriteriaType = (state = {}, action) => {
	switch (action.type) {
		case 'LOAD_CRITERIATYPE_SUCCESS':
			return action.slaCriteriaType;
		case 'LOAD_CRITERIATYPE_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}

export const slaOperatorType = (state = {}, action) => {
	switch (action.type) {
		case 'LOAD_OPERATOR_SUCCESS':
			return action.slaOperatorType.data;
		case 'LOAD_OPERATOR_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}

export const slaMilestoneActionList = (state = {}, action) => {
	switch (action.type) {
		case 'LOAD_MILESTONEACTIONLIST_SUCCESS':
			return action.slaMilestoneActionList.data;
		case 'LOAD_MILESTONEACTIONLIST_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}

export const slaMilestoneTargetList = (state = {}, action) => {
	switch (action.type) {
		case 'LOAD_MILESTONETARGETLIST_SUCCESS':
			return action.slaMilestoneTargetList.data;
		case 'LOAD_MILESTONETARGETLIST_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}

export const slaAttributes = (state = {}, action) => {
	switch (action.type) {
		case 'LOAD_ATTRIBUTE_SUCCESS':
			return action.slaAttributes;
		case 'LOAD_ATTRIBUTE_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}

export const slaAttributesList = (state = {}, action) => {
	switch (action.type) {
		case 'LOAD_ATTRIBUTELIST_SUCCESS':
			return action.slaAttributesList.data;
		case 'LOAD_ATTRIBUTELIST_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}

export const slaSelectedAttributesList = (state ="", action) => {
	switch(action.type){
		case 'LOAD_SELECTEDATTRIBUTES_SUCESS': return action.selectedAttributes;
		default: return state;
	}
}
export const slaMilestoneList = (state = {}, action) => {
	switch (action.type) {
		case 'LOAD_MILESTONELIST_SUCCESS':
			return action.slaMilestoneList.data;
		case 'LOAD_MILESTONELIST_FAILURE':
			return action.failureMessage;
		case 'RESET_MILESTONE_LIST':
			return '';	
		default:
			return state;
	}
}

export const slaUniqueMilestoneFromRecord = (state={}, action) => {
	switch (action.type) {
		case 'SAVE_EXTRACTED_MILESTONES':
			return action.collections;	
		default:
			return state;
	}	  
}

export const slaUniqueEventTypeFromRecord = (state={}, action) => {
	switch(action.type) {
	    case 'SAVE_EXTRACTED_TYPES':
		    return action.collections;
		default: return state;	
	}	
}

export const slaWorkScheduleDetails = (state = {
	slaWorkScheduleDetails: {},
	isFetching: false
}, action) => {
	switch (action.type) {
		case 'WORKDAY_SCHEDULE_DATA_LOAD_SUCCESS':
			return {
				workdayScheduleDetails: action.workdayScheduleDetails,
				isFetching: action.isFetching
			}
		default:
			return state;
	}
}

export const newSlaWorkScheduleAssociation = (state = {
	workdayScheduleData: {},
	isFetching: false
}, action) => {
	switch (action.type) {
		case 'NEW_WORK_SCHEDULE_LOAD_SUCCESS':
			return {
				workdayScheduleData: action.workdayScheduleData,
				isFetching: action.isFetching
			}
		default:
			return state;
	}
}
export const slaHolidayScheduleDetails = (state = {}, action) => {
	switch (action.type) {
		case 'HOLIDAY_SCHEDULE_DATA_LOAD_SUCCESS':
			return {slaHolidayScheduleDetails:action.holidayScheduleDetails,
				isFetching:action.isFetching}
		default:
			return state;
	}
}

export const timeZoneData = (state = [], action) => {
	switch (action.type) {
		case 'TIMEZONE_LOAD_SUCCESS':
			return action.timeZone;
		default:
			return state;
	}
}

export const slaHolidayDetails = (state = {}, action) => {
	// console.log('action.holidayDetailsaction.holidayDetails',action.holidayDetails);
	let finalData = {};
	let finalArr = [];
	if( action && action.holidayDetails && action.holidayDetails.data && action.holidayDetails.data.scheduleList &&  Array.isArray(action.holidayDetails.data.scheduleList) && action.holidayDetails.data.scheduleList.length ){
		finalData = Object.assign(action.holidayDetails.data.scheduleList[0]);
	}
	switch (action.type) {
		case 'HOLIDAY_DETAILS_LOAD_SUCCESS':
			return finalData;
			// return ( (action.holidayDetails && action.holidayDetails.data && action.holidayDetails.data.scheduleList && Array.isArray(action.holidayDetails.data.scheduleList) && action.holidayDetails.data.scheduleList.length) ? action.holidayDetails.data.scheduleList[0] : {} )
		default:
			return state;
	}
};
export const slaRelatedDetails = (state = {}, action) => {
	switch (action.type) {
		case 'HOLIDAY_RELATEDSLAS_DATA_LOAD_SUCCESS':
			return {holidayRelatedSla: action.holidayRelatedSla};
		case 'WORK_RELATEDSLAS_DATA_LOAD_SUCCESS':
			return {workRelatedSla: action.workRelatedSla};
		default:
			return state;
	}
};
export const slaEditLov = (state = [], action) => {
  // console.log('action.holidayDetailsaction.holidayDetails',action.holidayDetails);

  // if (
  //   action &&
  //   action.holidayDetails &&
  //   action.holidayDetails.data &&
  //   action.holidayDetails.data.scheduleList &&
  //   Array.isArray(action.holidayDetails.data.scheduleList) &&
  //   action.holidayDetails.data.scheduleList.length
  // ) {
  //   finalData = Object.assign(action.holidayDetails.data.scheduleList[0]);
  // }

  switch (action.type) {
    case "SLA_LOV_FETCHED_SUCCESSFULLY":
      return action.payload;
    // return ( (action.holidayDetails && action.holidayDetails.data && action.holidayDetails.data.scheduleList && Array.isArray(action.holidayDetails.data.scheduleList) && action.holidayDetails.data.scheduleList.length) ? action.holidayDetails.data.scheduleList[0] : {} )
    default:
      return state;
  }
};

export const postAttributeBasedSlaReducer =(state =[],action) =>{
	switch (action.type) {
		case 'ATTRIBUTE_BASED_SLA':
			return action.attributeBasedSlaData;
		default:
			return state;
	}
}