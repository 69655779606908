
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { connect } from "react-redux";
import { setwindowSize } from "../../actions/common/userDetails";

class Windowsize extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            innerWidth: window.innerWidth,
            innerHeight: window.innerHeight
     };
    }
    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }
    resize() {
        // this.setState({ innerWidth: window.innerWidth, innerHeight:window.innerHeight});
        // this.props.setwindowSize({width:window.innerWidth+"px",height:window.innerHeight+"px"});
        this.props.setwindowSize({width:window.innerWidth,height:window.innerHeight});
    }
    render(){
        // console.log("00props",this.props.windowSize);
        // return(<div>InnerWidth: {this.state.innerWidth} and innerHeight: {this.state.innerHeight}</div> )
        return null;
    }
}

const mapStateToProps = ({windowSize}) => {
    return {windowSize}
}

export default connect(mapStateToProps, {setwindowSize})(Windowsize);