
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import { gql } from "@apollo/client";
import Cookies from "universal-cookie";
const cookie = new Cookies();
let selectedLanguage = cookie.get("gph");
if (selectedLanguage) selectedLanguage = selectedLanguage.replace("s:", "");
if (selectedLanguage)
  selectedLanguage = selectedLanguage.substring(
    0,
    selectedLanguage.lastIndexOf(".")
  );
selectedLanguage = selectedLanguage.split("~");
selectedLanguage = selectedLanguage[20];

export const getForm = gql`
  query GetFormDetail($id: String!) {
    getForm(id: $id) {
      _id
      name
      formObjects
      ruleData
      pageType
      customScript
      publishedPage{
        status
      }
      createdBy
      updatedBy
    }
  }
`;

export const getLanguage = gql`
  {
    getAllLanguages(queryParams: { language: "${selectedLanguage}" }) {
      value
    }
  }
`;

export const languageWithMeta = gql`
  query GetLanguages($offset: Int!, $limit: Int!) {
    getLanguages(queryParams: { limit: $limit, offset: $offset }) {
      count
      data {
        _id
        language
        key
        value
        status
        createdBy
        createdAt
      }
    }
  }
`;

export const SaveLanguage = gql`
  mutation SaveLanguage($CreateLanguageInput: CreateLanguageInput!) {
    saveLanguage(CreateLanguageInput: $CreateLanguageInput) {
      _id
      language
      key
      value
      status
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

export const UpdateLanguage = gql`
  mutation UpdateLanguage(
    $id: String!
    $CreateLanguageInput: CreateLanguageInput!
  ) {
    updateLanguage(id: $id, CreateLanguageInput: $CreateLanguageInput) {
      _id
      language
      key
      value
      status
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

export const getSingleLanguage = gql`
  query GetLanguage($id: String!) {
    getLanguage(id: $id) {
      _id
      language
      key
      value
      status
    }
  }
`;

export const DeleteLanguage = gql`
  mutation DeleteLanguage($id: String!) {
    deleteLanguage(id: $id) {
      _id
      language
      key
      value
      status
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

export const GET_FORMS = gql`
  query GetLanguages($offset: Int!, $limit: Int!, $companyId: Int!) {
    getAllForms(
      queryParams: { companyId: $companyId, limit: $limit, offset: $offset }
    ) {
      _id
      name
      formObjects
      companyName
      pageType
      updatedBy
      updatedByFullname
      updatedAt
      createdBy
      createdAt
      ruleData
      companyId
      publishedPage {
        status
      }
    }
  }
`;

export const UpdateForm = gql`
  mutation UpdateForm($id: String!, $CreateFormInput: CreateFormInput!) {
    updateForm(id: $id, CreateFormInput: $CreateFormInput) {
      _id
      formObjects
    }
  }
`;

export const DeleteForm = gql`
  mutation DeleteForm($id: String!) {
    deleteForm(id: $id) {
      _id
      status
    }
  }
`;

export const CreateAndUpdatePublishedPage = gql`
  mutation CreateAndUpdatePublishedPage($createParams: PublishedPageInput!) {
    createAndUpdatePublishedPage(createParams: $createParams) {
      _id
      status
    }
  }
`;

export const GET_PAGES_URLS = gql`
  query GetPagesUrls {
    getPagesUrls {
      _id
      functionAction
      form_internal_route
      sx_internal_route
      name
    }
  }
`;

export const GET_PUBLISHED_PAGES = gql`
  query GetAllPublishedPages($status: String!) {
    getAllPublishedPages(queryParams: { status: $status }) {
      _id
      functionAction
      pageRoute
      internalRoute
    }
  }
`;

export const GET_ASSET_URLS = gql`
  query GetAssetUrls($companyId: String!){
    assetUrls(companyId: $companyId)
  }
`