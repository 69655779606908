
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useMemo, useCallback } from "react";
import Swal from "sweetalert2";
import Loader from "react-loader-spinner";
import Table from "../../../../../../../common/governance/tableView";
import NoDataAvailable from "../../../../../../../common/NoDataAvailable";
import { useDeleteMinutesDetailsMutation } from "../../../../../../../../reducers/governance/meeting/meeting";

const RecordingListView = ({
  hide,
  data,
  isFetching,
  companyID,
  occuranceId,
  refetchlist,
}) => {
  const [deletefn, { isLoading: isDeleting }] =
    useDeleteMinutesDetailsMutation();

  const deleteRecording = useCallback(
    (minutesId, occuranceId, title, companyID) => {
      Swal.fire({
        title: `Do you want to delete - ${title}?`,
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deletefn({
            queryString: `?minutesId=${minutesId}&occurrenceId=${occuranceId}&tenantId=${companyID}`,
          });
          refetchlist();
          Swal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            icon: "success",
          });
        }
      });
    },
    [refetchlist]
  );

  const columns = useMemo(
    () => [
      {
        accessor: "recordingName",
        Header: "Name",
        // disableSortBy: true,
        // Cell: ({ row: { original } }) => {
        //   return <a className="taska">{original?.recordingName}</a>;
        // },
      },
      {
        accessor: "createdByFullName",
        Header: "Uploaded By",
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
          return <span>{original?.modifiedByFullName}</span>;
        },
      },

      {
        accessor: "createdOn",
        Header: "Uploaded On",
        disableSortBy: true,
      },

      {
        accessor: "externalUrl",
        Header: "",
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
          return (
            <div className="text-right">
              <a
                target="_blank"
                href={original?.externalUrl || original?.signedAttachedFileUrl}
                rel="noreferrer"
                className="orange me-2"
              >
                <i className="fa f14 fa-link" aria-hidden="true"></i>
              </a>
              <a
                className="red"
                role="button"
                onClick={() =>
                  deleteRecording(
                    original?.minutesId,
                    original?.occuranceId,
                    original?.recordingName,
                    companyID
                  )
                }
              >
                <i className="fa f14 fa-trash" aria-hidden="true"></i>
              </a>
            </div>
          );
        },
      },
    ],
    [companyID]
  );

  return isFetching ? (
    <div className="fnloader">
      <Loader type="ThreeDots" color="#2e75b6" height={10} />
    </div>
  ) : data?.length === 0 ? (
    <div className="custom-container-fluid mb-2">
      <NoDataAvailable />
    </div>
  ) : (
    <div class="table-responsive custom-task-list clearfix">
      <div id="scrollableDiv_">
        <Table
          disablesort
          columns={columns}
          data={data || []}
          tableClass="table table-sm custom-table-border"
          id="action-table"
        />
      </div>
    </div>
  );
};

export default RecordingListView;
