
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {patchBusinessFunction} from "../../../actions/businessFunction/businessFunctionAction"

let sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

import { reduxForm, SubmissionError } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import { createIntegrationHub, deleteIntegrationData } from "../../../actions/integrationHub/integrationHubAction.js";
import Swal from "sweetalert2";
import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import { MyContext } from '_MyContext';

const EditBusinessFunctionHeader = (props) => {
  const showLoader = useSelector((state) => state.showLoader.loading);
  const spcmReducer = useSelector((state) => state.spcmReducer);
  const businessFunctionListIntialValues = useSelector(
    (state) => state.businessFunctionListIntialValues
  );
  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const saveObjRef = useRef(null);

	useEffect(() => {
		context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
    context.newTabPrevent();
	}, []);

  useEffect(() => {
    let id = businessFunctionListIntialValues.id
    console.log("ID===>",id)
    console.log("123456businessFunctionListIntialValues=============",businessFunctionListIntialValues);
  }, []);
  const dispatch = useDispatch();

  const submit = async (values) => {
    let companyName;
    let scenarioName;
    spcmReducer.companyList.map(function (item) {
      if (values.COMPANY_NAME == item.field1Value) {
        companyName = item.field1Key;
      }
    });

      return sleep(1000) // simulate server latency

      .then(() => {
        console.log("Valuesssssssss",values)
        if (`${JSON.stringify(values, null, 2)}` == "{}") {
          throw new SubmissionError({ _error: 'The form has missing required fields, please revalidate the highlighted fields and update the form' })
        }
        else {
           
          let postjson;
              postjson = [{
                "companyId": values.companyId,
                "id":values.id,
                "businessFunctionName": values.businessFunctionName.trim(),
                "businessFunctionDesc": (values.businessFunctionDesc === "" || typeof values.businessFunctionDesc === "undefined") ? "" : values.businessFunctionDesc.trim(),
                "parentFunction": (values.parentFunction === "" || typeof values.parentFunction === "undefined") ? "" : values.parentFunction,
                "status": (values.status === "" || typeof values.status === "undefined") ? "0" : values.status,
            }]
          console.log("Post Json")
          console.log(postjson);
          dispatch(patchBusinessFunction(postjson));
        }
      });
  }

  let { handleSubmit, submitting } = props;

  return (
    <Row className="margin-b-15">
      <Col xs={8}>
        <h1 bsClass="" className="sPageHeading1">
          {props.translator["Business Function"]}
        </h1>
      </Col>
      <Col xs={4}>
        <div className="paHedFilter">
          <Link to="javascript:void(0)" bsPrefix=' '  className="myBt plus ctrlKeyPrevent" onClick={handleSubmit(submit)} disabled={submitting} title={props.translator["Save"]} ref={saveObjRef}>
            <span>{submitting ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/> }</span>
          </Link>
          <Link bsPrefix=' ' className="myBt cancel fillBtn ctrlKeyPrevent" title={props.translator["Close"]} to="/businessFunction" ref={closeButton} >
           <IoClose/>
          </Link>
        </div>
      </Col>
    </Row>
  );
};

export default reduxForm({
  form: "EditBusinessFunctionForm",
  destroyOnUnmount: false,
})(EditBusinessFunctionHeader);
