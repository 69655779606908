
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import AuditHistory from "./auditLog";
import WorkNotes from "../WorkNotes/add-edit";
import ListLoader from "../../../../common/loaders/ListLoader";
import { useGetAuditLogsQuery } from "../../../../../reducers/governance/meeting/actionItem";
import {IoClose} from "react-icons/io5";
import WIBNotification from "../../../../WorkItemBoard/WIBNotification"

const GovAuditLogs = ({ editActionData }) => {
  const [tabKey, setTabKey] = useState(1);
  const [orderValue, setOrderValue] = useState("desc");

  const actionItemCode = editActionData?.[0]?.actionitemCode;
  const companyId = editActionData[0]?.companyId;

  const {
    data: auditData,
    isFetching,
    refetch: auditLogRefetch,
  } = useGetAuditLogsQuery({code:actionItemCode, orderValue:orderValue}, {
    skip: !(actionItemCode && orderValue) ,
  });

  const handleSelect = (tabKey) => {
    setTabKey(tabKey);
  };
  return (
    <div className="panel-body attachment-body">
      <div className="custom-container-fluid">
        <div className="stickyArea rBoxStyle">
          <div className="rPageHeading d-flex p-3">
            <div className="flex-grow-1 offNam mw-100">Activity Details</div>
            {/* <div>
              <IoClose />
            </div> */}
          </div>
            <div className="rBoxGap">
              <div className="rBoxTabs">
                <Tabs activeKey={tabKey} onSelect={handleSelect} id="tabId">
                  <Tab eventKey={1} title="Comments">
                    <WorkNotes editActionData={editActionData} />
                  </Tab>
                  <Tab eventKey={6} title="Audit Log">
                    {isFetching ? (
                      <ListLoader />
                    ) : (
                      <AuditHistory
                        data={auditData}
                        auditLogRefetch={auditLogRefetch}
                        setOrderValue={setOrderValue}
                      />
                    )}
                  </Tab>
                  <Tab eventKey={2} title="Notification">
                    
                    <WIBNotification
                      requestNumber={actionItemCode}
                      orderNumber={""}
                    />
                  </Tab>
                </Tabs>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default GovAuditLogs;
