
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
//below URL used for testing purpose
//const breakFixUrl = 'https://xsm-f.mybluemix.net/api/SX_FAVOURITEOFFERINGs/';
import { GLOBAL }  from '../../components/Globals';
//let breakFixUrl =`https://xsm-f.mybluemix.net/api/breakfixitem?breakfixitemId=`;
//let breakFixUrl=`https://xsm-f.mybluemix.net/groupListSelfService?companyId=2932&group=ResolvedER`
import { loadBreakFixSGI } from '../../actions/breakFix/serviceGroupIAction';
import {loadBreakFixSGAESuccess} from "../breakFix/serviceGroupAEAction";

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

export function loadFulfillmentDetailsSuccess(fulfillmentDetails){
	return {
		type: 'LOAD_FULFILLMENTDETAILS_SUCCESS',
		fulfillmentDetails
	};
}

export function loadFulfillmentDetailsFailure(errorResponse){
	return {
		type: 'LOAD_FULFILLMENTDETAILS_FAILURE',
		errorResponse
	};
}

export function resetfulfillmentIndividual(){
	let typeaheadData=[];
	return {type: 'LOAD_BREAKFIXSGI_SUCCESS_TYPEAHEAD',typeaheadData};
}
export function LoadAMSgrp(itemId,amsAttribute)
{
	console.log('URL----', GLOBAL.fulfillmentfetchrecord+itemId);
	return(dispatch,getState) =>{
		//alert("EditGroupIdAction123"+breakFixId);
		dispatch({type:'FETCHING_FULDETAILS_IN_PROGRESS'});
		if(itemId == undefined || itemId == "" || isNaN(itemId)){
			//alert("H2 breakFixId not found");
		}else{		  	
				//alert("my url is "+GLOBAL.breakFixEditUrl+breakFixId);
				//GLOBAL.fulfillmentindividualval = "";
				
				api.get(GLOBAL.fulfillmentfetchrecord+itemId)
					.then((fulfillmentDetails)=>{
						if(!fulfillmentDetails){//alert('not breakFix')
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							throw Error(fulfillmentDetails.statusText);
						}
            GLOBAL.fulfillmentindividualval = fulfillmentDetails.data.asgUserid==0 ? "": fulfillmentDetails.data.asgUserid			
			return fulfillmentDetails;
					})
					.then((fulfillmentDetails) =>{
						
						let amsAttribute={
							"Status":fulfillmentDetails.data.itemStatus,
							"Priority":"0",
							"Service":fulfillmentDetails.data.offeringIdSx,
							"Impacted CI":"0",
							"Requestor":fulfillmentDetails.data.requesterId,
							"Class":"0",
							"User.Location":fulfillmentDetails.data.requesterLocationId,
							"CI.Location":'0'
						}
						//alert('yes it is')
					
			api.get("/rest/getRuleConfiguration?companyId="+fulfillmentDetails.data.companyId+"&moduleId=25&eventId=35",
			{ headers: { 'query': JSON.stringify(amsAttribute)}})
              .then((breakFixSGAEName) => {
                if (!breakFixSGAEName) {
                  throw Error(breakFixSGAEName.statusText);
                }
                let filteredbreakFixSGAEName = breakFixSGAEName.data ? 
                  breakFixSGAEName.data.filter((item,i)=>{
                    return item.statusValue =="Active" ? true : false;
                  }) : [];
                return filteredbreakFixSGAEName;
              })
              .then((filteredbreakFixSGAEName) => {
				  if(GLOBAL.fulfillmentPageLoad===true){
					let typeaheadData=[];
					if(filteredbreakFixSGAEName!==''){
                      let currentstateFulfillment=getState().fulfillmentDetails;//,flag=false;
				      filteredbreakFixSGAEName.map((item)=>{
					  typeaheadData.push({'value':''+item.assignmentGroupId+'','label':item.assignmentGroupName, 'supportCompanyID': item.assignmentCompanyId, 'supportCompanyName': item.assignmentCompanyName}) 
					//   if(''+item.assignmentGroupId+''==''+currentstateFulfillment.asgGroupid+''){
					//     	flag=true;
					//   } 
				     });
				    //  if((currentstateFulfillment.asgGroupid!==null ||  currentstateFulfillment.asgGroupid!==0 || currentstateFulfillment.asgGroupid!=="0")&& flag===false){
					//       typeaheadData.push({'value':''+currentstateFulfillment.asgGroupid+'', 'label':currentstateFulfillment.asgGroupname});
				    //  }
				     //flag=null,
					 currentstateFulfillment=null;
					}
				  dispatch({type: 'GROUP_LIST_TYPE_AHEAD_INITIAL',typeaheadData});
				  } 
                dispatch( {
                  type: 'LOAD_BREAKFIXSGAE_SUCCESS',
                  breakFixSGAEName:filteredbreakFixSGAEName
                });
              })
              .catch((err) => {
              	//GLOBAL.fulfillmentindividualval = "";
                console.log(err);
              });
						dispatch(loadFulfillmentDetailsSuccess(fulfillmentDetails));
						console.log("api called");
						dispatch(changeFieldStatusData(fulfillmentDetails.data.itemStatus));
						dispatch({type:'LOAD_HAMBURGER_OPTIONS',payload:true});
						// dispatch({type:'DETAILS_FULFETCHED_SUCCESSFULLY'})
					})
					.catch((err) => {
						console.log("error in loadFulfillmentDetailsSuccess(fulfillmentDetails)");
					});
					//console.log("not calling loadBreakFixEditDetails");
		}
	};
}
export const loadFulfillmentDataBackground = fulfillmentId => {
  return dispatch => {
	api.get(GLOBAL.fulfillmentfetchrecord + fulfillmentId).then(itemData => {
        dispatch({type: 'LOAD_BREAKFIXSGAE_SUCCESS', breakFixSGAEName: itemData});
	});
  }
}
export function loadFulfillmentDetails(itemId){
	//alert('action called and parameter is '+breakFixId)
	return(dispatch,getState) =>{
		//alert("EditGroupIdAction123"+breakFixId);
		dispatch({type:'FETCHING_FULDETAILS_IN_PROGRESS'});
		if(itemId == undefined || itemId == ""){
			//alert("H2 breakFixId not found");
			return Promise.reject('Error');
		}else{		  	
				//alert("my url is "+GLOBAL.breakFixEditUrl+breakFixId);
				//GLOBAL.fulfillmentindividualval = "";
				return api.get(GLOBAL.fulfillmentfetchrecord+itemId)
					.then((fulfillmentDetails)=>{
						if(!fulfillmentDetails){//alert('not breakFix')
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							throw Error(fulfillmentDetails.statusText);
						}
            GLOBAL.fulfillmentindividualval = fulfillmentDetails.data.asgUserid==0 ? "": fulfillmentDetails.data.asgUserid			
			return fulfillmentDetails;
					})
					.then((fulfillmentDetails) =>{//alert('yes it is')
						let amsAttribute={
							"Status":fulfillmentDetails.data.itemStatus,
							"Priority":"0",
							"Service":fulfillmentDetails.data.offeringIdSx,
							"Impacted CI":"0",
							"Requestor":fulfillmentDetails.data.requesterId,
							"Class":"0",
							"User.Location":fulfillmentDetails.data.requesterLocationId,
							"CI.Location":'0'
						}
			api.get("/rest/getRuleConfiguration?companyId="+fulfillmentDetails.data.companyId+"&moduleId=25&eventId=35",
			{ headers: { 'query': JSON.stringify(amsAttribute)}})
              .then((breakFixSGAEName) => {
                if (!breakFixSGAEName) {
                  throw Error(breakFixSGAEName.statusText)
                }
                let filteredbreakFixSGAEName =
                  breakFixSGAEName.data.filter((item,i)=>{
                    return item.statusValue =="Active" ? true : false;
                  });
                return filteredbreakFixSGAEName;
              })
              .then((filteredbreakFixSGAEName) => {
				  if(GLOBAL.fulfillmentPageLoad===true){
					let typeaheadData=[];
					if(filteredbreakFixSGAEName!==''){
                      let currentstateFulfillment=getState().fulfillmentDetails;//,flag=false;
				      filteredbreakFixSGAEName.map((item)=>{
					  typeaheadData.push({'value':''+item.assignmentGroupId+'','label':item.assignmentGroupName, 'supportCompanyID': item.assignmentCompanyId, 'supportCompanyName': item.assignmentCompanyName}) 
					//   if(''+item.assignmentGroupId+''==''+currentstateFulfillment.asgGroupid+''){
					//     	flag=true;
					//   } 
				     });
				    //  if((currentstateFulfillment.asgGroupid!==null ||  currentstateFulfillment.asgGroupid!==0 || currentstateFulfillment.asgGroupid!=="0")&& flag===false){
					//       typeaheadData.push({'value':''+currentstateFulfillment.asgGroupid+'', 'label':currentstateFulfillment.asgGroupname});
				    //  }
				     //flag=null,
					 currentstateFulfillment=null;
					}
				  dispatch({type: 'GROUP_LIST_TYPE_AHEAD_INITIAL',typeaheadData});
				  } 
                dispatch( {
                  type: 'LOAD_BREAKFIXSGAE_SUCCESS',
                  breakFixSGAEName:filteredbreakFixSGAEName
                });
              })
              .catch((err) => {
              	//GLOBAL.fulfillmentindividualval = "";
                console.log(err);
              });
						dispatch(loadFulfillmentDetailsSuccess(fulfillmentDetails));
						console.log("api called");
						dispatch(changeFieldStatusData(fulfillmentDetails.data.itemStatus));
						dispatch({type:'LOAD_HAMBURGER_OPTIONS',payload:true});
						// dispatch({type:'DETAILS_FULFETCHED_SUCCESSFULLY'})
						return Promise.resolve(fulfillmentDetails);
					})
					.catch((err) => {
						console.log("error in loadFulfillmentDetailsSuccess(fulfillmentDetails)");
						return Promise.reject('Error');
					});
					//console.log("not calling loadBreakFixEditDetails");
		}
	};
}
export const changeFieldStatusData=(status_id)=>{
	console.log("action in field status");
	console.log("URL",GLOBAL.changeFieldStatusData + status_id);
	return(dispatch)=>{
	  dispatch({type:'FETCHING_DETAILS_IN_PROGRESS'});
	  api.get(GLOBAL.fulfillmentFieldStatusData + status_id)
		.then((statusData) => {
		  if (!statusData) {
			throw Error("No Status Data");
		  }
		  return statusData;
		})
		.then((statusData) => {
		  console.log("changeData.... ",statusData)
		  dispatch(FieldstatusData(statusData));
		  dispatch({type:'DETAILS_FETCHED_SUCCESSFULLY'});
  
		});
	}
  }
  export const FieldstatusData=(statusData)=>{
	console.log("statusData",statusData);
	// let fieldStatus={
	//   type:statusData.type,
	//   summary:statusData.summary,
	// }
	return {
	  type: 'CHANGE_FIELD_STATUS_DATA_LOAD_SUCCESS',
	  statusData
	};
  }
export function updateLoadFulfillmentDetails(fulfillmentDetails){
	return(dispatch) =>{
		dispatch(loadFulfillmentDetailsSuccess(fulfillmentDetails))
	};
}