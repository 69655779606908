
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from "axios";
import { GLOBAL } from "../../components/Globals";
import { navigationHooks } from "../../helpers/NavigationHook";
import Swal from "sweetalert2";
const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

export function loadIntegrationHubList() {
  return (dispatch) => {
    dispatch({ type: "LOADER_IN_PROGRESS" });
    api
      .get(GLOBAL.integrationHubViewUrl)
      .then((integrationHubList) => {
        dispatch(loadCategoryListSuccess(integrationHubList.data));
        dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
      })
      .catch((err) => {
        dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
        console.log("error in loadIntegrationHubList");
        console.log(err);
      });
  };
}
export function loadCategoryListSuccess(integrationHubList) {
  return {
    type: "LOAD_INTEGRATION_HUB_LIST_SUCCESS",
    payload: integrationHubList && integrationHubList.apimetadataboundlist,
  };
}

export function loadIntegrationScenarioList() {
  return (dispatch) => {
    dispatch({ type: "RESET_FORM" });
    dispatch({ type: "LOADER_IN_PROGRESS" });
    api
      .get(GLOBAL.integrationScenarioUrl)
      .then((integrationScenarioList) => {
        dispatch(loadScenarioListSuccess(integrationScenarioList.data));
        dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
      })
      .catch((err) => {
        dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
        console.log("error in loadScenario List");
        console.log(err);
      });
  };
}
export function loadScenarioListSuccess(integrationHubList) {
  return {
    type: "LOAD_SECNARIO_LIST_SUCCESS",
    payload: integrationHubList,
  };
}

export function createIntegrationHub(data) {
  return (dispatch) => {
    dispatch({ type: "RESET_FORM" });
    api
      .post(GLOBAL.integrationHubSubmitUrl, data)
      .then((response) => {
        if (response.status == 200) {
          console.log("created IntegrationHub record");
          console.log(response);
          if (
            response.data.status === "This Outbound connection already exists."
          ) {
            Swal.fire({
              title: response.data.status,
              button: "OK",
            });
          } else {
            navigationHooks.navigate("/integHub");
          }
        }
      })
      .catch((err) => {
        console.log("error in createCategory", err.message);
        console.log(err);
        Swal.fire({
          title: err.message,
          button: "OK",
        });
      });
  };
}
export function loadEditIntegrationHubDetails(id) {
  let strObj = {};
  strObj.id = id.toString();
  strObj = JSON.stringify(strObj);
  console.log(strObj);
  return (dispatch) => {
    api
      .get(GLOBAL.integrationHubViewUrl, { headers: { query: strObj } })
      .then((integrationEditDetails) => {
        if (!integrationEditDetails) {
          throw Error(integrationEditDetails.statusText);
        }
        return integrationEditDetails;
      })
      .then((integrationEditDetails) => {
        dispatch(loadIntegrationEditSuccess(integrationEditDetails));
      })
      .catch((err) => {
        console.log("error in loadIntegrationEditSuccess");
        console.log(err);
      });
  };
}
export function loadIntegrationEditSuccess(integrationEditDetails) {
  return {
    type: "LOAD_INTEGRATION_EDIT_DETAILS_SUCCESS",
    payload: integrationEditDetails,
  };
}

export function deleteIntegrationData(id) {
  return (dispatch) => {
    dispatch({ type: "DELETE_IN_PROGRESS" });
    api
      .delete(GLOBAL.integrationDataDeleteUrl + "?id=" + id)
      .then((integrationScenarioList) => {
        if (!integrationScenarioList) {
          throw Error(integrationScenarioList.statusText);
        }

        dispatch({ type: "OUTBOUND_CONNECTION_DELETED_SUCCESSFULLY" });
        Swal.fire({
          title: "Outbound Connection deleted successfully",
        }).then((result) => {
          if (result.isDismissed || result.isConfirmed) navigationHooks.navigate("/integHub");
        });
        return integrationScenarioList.data;
      })
      .catch((err) => {
        dispatch({ type: "OUTBOUND_CONNECTION_DELETED_SUCCESSFULLY" });
        console.log("error in deleteIntegrationData Api");
        console.log(err);
      });
  };
}
