
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import { GLOBAL }  from '../../components/Globals';
//below URL used for testing purpose
//const breakFixUrl = 'https://xsm-f.mybluemix.net/api/SX_FAVOURITEOFFERINGs/';

//let breakFixUrl=`https://xsm-f.mybluemix.net/groupListSelfService?companyId=2932&group=ResolvedER`

export function loadBreakFixCauseDropdownSuccess(provideFixDropdown){
	return {
		type: 'LOAD_BREAKFIXCAUSEDROPDOWN_SUCCESS',
		provideFixDropdown
	};
}

export function loadBreakFixCauseDropdown(){
	const filterTypeOfFixOptions = (arr) => {
		try{
			return arr.filter(item => {
				return(
					!(['First Call Resolution', 'Remote Control', 'Service Desk Assisted', 'On Site Support'].includes(item['field1Key']))
				);
			});
		}catch(e){
			console.error(e);
			return arr;
		}
	};
	return(dispatch) =>{
				//alert("edit"+breakFixUrl+breakFixId);
				axios.get(GLOBAL.breakFixProvideFixDropdownUrl)
					.then((provideFixDropdown)=>{
						console.log('BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB', provideFixDropdown)
						if(!provideFixDropdown){
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							throw Error(provideFixDropdown.statusText);
						}
						return provideFixDropdown;
					})
					.then((provideFixDropdown) =>{
						provideFixDropdown['data'] = (provideFixDropdown && provideFixDropdown.data && Array.isArray(provideFixDropdown.data)) ? filterTypeOfFixOptions(provideFixDropdown.data) : [];
						dispatch(loadBreakFixCauseDropdownSuccess(provideFixDropdown))
					})
					.catch((err) => {
						console.log(err);
					});
					//console.log("not calling loadprovideFixDropdown");

	};
}
