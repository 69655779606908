
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import { GLOBAL } from '_Globals';
import axios from "axios";

export const fetchOfferingDetails = (offeringId, type = "", onBehalfUser = {}) => {
  let str = {};
  if(type === "entitlement_entity") {
    str.type = type;
    str.company_id = onBehalfUser.companyId;
    str.role_id = onBehalfUser.role_id;
    str.departments = onBehalfUser.department_id;
    str.employee_band_name = onBehalfUser.employee_band;
    str.organizations = onBehalfUser.organization_id;
    str.locations = onBehalfUser.locations;
    str.country_id = onBehalfUser.country_id;
    str.group_id = onBehalfUser.group_id;
    str.offeringId = offeringId;
  } else {
    str.user = "";
    str.offeringId = offeringId;
  }
  str = JSON.stringify(str);

  return (dispatch) => {
    axios.get(GLOBAL.getServiceDetails, {
      headers: { query: str },
    })
    .then((offeringDetails) => {
        if (offeringDetails.erroCode) {
          dispatch(offeringDetailsFailure(offeringDetails));
        } else {
          dispatch(offeringDetailsSuccess(offeringDetails));
        }
      })
      .catch((err) => {
        dispatch(offeringDetailsFailure(err));
      });
  };
};

export const loadOfferingSlaDetails = (serviceId) => {
  return (dispatch) => {
    dispatch({ type: "SLA_DETAILS_LOADER_IN_PROGRESS" });
    axios.get(
      GLOBAL.offeringSlaDetailsUrl + "/5/" + serviceId + "/10"
    )
    .then((slaDetails) => {
      if (slaDetails.status == 200) {
        dispatch({
          type: "OFFERING_SUPPORT_SLA",
          supportSla: slaDetails.data == "" ? ["empty"] : slaDetails.data,
        });
        dispatch({ type: "SLA_DETAILS_LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
      } else {
        if (slaDetails.status == "204") {
          dispatch({
            type: "OFFERING_SUPPORT_SLA",
            supportSla: ["empty"],
          });
          dispatch({ type: "SLA_DETAILS_LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
        } 
      }
    });
  };
};

export const loadOfferingAavailability = (serviceId) => {
  return (dispatch) => {
    dispatch({ type: "SLA_DETAILS_LOADER_IN_PROGRESS" });
    axios.get(
      GLOBAL.offeringSlaDetailsUrl + "/30/" + serviceId + "/50"
    )
    .then((availability) => {
      if (availability.status == 200) {
        dispatch({
          type: "OFFERING_AVAILABILITY",
          availability: availability.data == "" ? ["empty"] : availability.data,
        });
        dispatch({ type: "SLA_DETAILS_LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
      } else {
        if (availability.status == "204") {
          dispatch({
            type: "OFFERING_AVAILABILITY",
            availability: ["empty"],
          });
          dispatch({ type: "SLA_DETAILS_LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
        }
      }
    });
  };
};

export const loadOfferingDeliveryType = (serviceId) => {
  return (dispatch) => {
    dispatch({ type: "SLA_DETAILS_LOADER_IN_PROGRESS" });
    axios.get(
      GLOBAL.offeringSlaDetailsUrl + "/15/" + serviceId + "/30"
    ).then((delOptions) => {
      if (delOptions.status == 200) {
        dispatch({
          type: "OFFERING_DELIVERY_OPTIONS",
          deliveryOptions: delOptions.data == "" ? ["empty"] : delOptions.data,
        });
        dispatch({ type: "SLA_DETAILS_LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
      } else {
        if (delOptions.status == "204") {
          dispatch({
            type: "OFFERING_DELIVERY_OPTIONS",
            deliveryOptions: ["empty"],
          });
          dispatch({ type: "SLA_DETAILS_LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
        }
      }
    });
  };
};

const offeringDetailsSuccess = (offeringDetails) => {
  return {
    type: "OFFERING_DETAILS_SUCCESS",
    offeringDetails,
  };
};

export const resetStateInStore = (resetStateFor) => {
  return (dispatch) => {
    switch (resetStateFor) {
      case "service_components":
        dispatch({
          type: "RESET_COMPONENT_PACKAGE_STATE",
          componentPackage: [],
        });
        break;
      case "offering_details":
        dispatch({
          type: "RESET_OFFERING_DETAILS",
          offeringDetails: [],
        });
        break;
      case "selected_components":
        dispatch({
          type: "RESET_SELECTED_COMPONENTS",
          selectedComponentForOffering: [],
        });
        break;
    }
  };
};

export const setSelectedComponentForOffering = (
  selectedComponentForOffering
) => {
  return (dispatch) => {
    dispatch({
      type: "SET_SELECTED_COMPONENTS",
      selectedComponentForOffering,
    });
  };
};

const offeringDetailsFailure = (errorResponse) => {
  return {
    type: "OFFERING_DETAILS_FAILURE",
    errorResponse,
  };
};

export const getRelatedOfferings = (offeringId) => {
  let str = {};
  str.offeringId = offeringId;
  str = JSON.stringify(str);

  return (dispatch) => {
    axios
      .get(GLOBAL.getRelatedOfferings, { headers: { query: str } })
      .then((resp) => {
        dispatch({
          type: "RELATED_OFFERINGS",
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "RELATED_OFFERINGS",
          payload: [],
        });
      });
  };
};
