
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Nav, Button, ListGroup, NavDropdown } from "react-bootstrap";
import { Link as LinkContainer } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import axios from "axios";
import OnClickOutside from "react-onclickoutside";
import configureStore from "../../../../store/configureStore";
import { GLOBAL } from "../../../Globals";
import { connect } from "react-redux";
import {
  fetchFulfillmentGroupList,
  loadFulfillmentList,
} from "../../../../actions/breakFix/quickViewAction";
import {
  loadTaskItemToEdit,
  loadTaskGroup,
  loadAsignedTo,
  getEditTaskStatus,
  flushReducer,
} from "../../../../actions/task/TaskDataAction";
import { setWipSearchApply } from "../../../../actions/globalSearch/globalSearchAction";
let currentStatus = "";
const store = configureStore();
import moment from "moment-timezone";
import Cookies from "universal-cookie";
import swal from "sweetalert";
import Swal from "sweetalert2";
import {LuActivitySquare } from "react-icons/lu";
import {ImAttachment, ImSpinner6} from "react-icons/im";
import {FaTimeline, FaRegEye, FaTag} from "react-icons/fa6";
import {AiOutlineApartment} from "react-icons/ai";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import {MdKeyboardDoubleArrowRight} from "react-icons/md";
import { BiDotsVertical } from "react-icons/bi";
import { PiTagBold } from "react-icons/pi";
import {HiOutlineRefresh} from "react-icons/hi";
import {LiaHourglass} from "react-icons/lia";
import {TbTextWrap} from "react-icons/tb";
import { getFormValues } from 'redux-form';
import { MyContext } from '_MyContext';

const cookies = new Cookies();

const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
//// console.log("homepage::::"+homepagelocation);
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

let timezone = cookies.get("gph");
if (timezone) timezone = timezone.replace("s:", "");
if (timezone) timezone = timezone.substring(0, timezone.lastIndexOf("."));
//// console.log("homepage::::"+homepagelocation);
timezone = timezone.split("~");
timezone = timezone[3];

let full_name = cookies.get("gph");
if (full_name) full_name = full_name.replace("s:", "");
if (full_name) full_name = full_name.substring(0, full_name.lastIndexOf("."));
full_name = full_name.split("~");
full_name = full_name[42];

let user_id = cookies.get("gph");
if (user_id) user_id = user_id.replace("s:", "");
if (user_id) user_id = user_id.substring(0, user_id.lastIndexOf("."));
user_id = user_id.split("~");
user_id = user_id[43];
const isWatcherEnabled = false;

let TaskEditHeader = class TaskEditHeader extends React.Component {
  static contextType = MyContext;
  constructor(props) {
    super(props);
    this.state = {
      hamburgerShowHide: false,
      statusHamburgerShowHide: false,
      statusName: "",
      previousStatus: this.props.editFormValues.statusName,
      previousStatusId: this.props.editFormValues.statusId,
    };
    this.callbackFun = this.callbackFun.bind(this);
    this.statusHamburgerShowHide = this.statusHamburgerShowHide.bind(this);
    this.setStatusName = this.setStatusName.bind(this);
  }

  componentDidMount(){
    if (GLOBAL.slaHeadEnable) {
      this.props.changeActionView('slaProgress')
    }
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
    this.context.newTabPrevent();
	}
	componentDidUpdate() {
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
    this.context.newTabPrevent();
	}
  hamburgerShowHide() {
    this.setState({ hamburgerShowHide: !this.state.hamburgerShowHide });
  }

  callbackFun(id) {
    this.setState({ statusHamburgerShowHide: id });
  }

  componentWillReceiveProps(nextProps) {
    dateformat = this.props.date_format;
    if (this.state.previousStatus === undefined) {
      this.setState({ previousStatus: this.props.editFormValues.statusName });
      this.setState({ previousStatusId: this.props.editFormValues.statusId });
      // this.props.setPreviousStatus(this.state.previousStatus);
    }
    this.previousGroupId = this.props.editFormValues.assignedToGroupId;
    this.props.setPreviousStatusId(this.state.previousStatusId);
  }

  saveChange(values) {
    // let esd = moment(values.expectedStartDate, jwtdf + " hh:mm A").format("YYYY-MM-DD hh:mm A");
    let esd='';
    if((values.workItemType == "Change" || values.workItemType == "changeManagement")){
      esd = moment(values.expectedStartDate,jwtdf + " HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");
    }else{
      esd = moment(values.expectedStartDate, jwtdf + " hh:mm A").format("YYYY-MM-DD HH:mm:ss");
    }
    // console.log("jwtdf ===", jwtdf);
    //let edd = moment(values.expectedDueDate, jwtdf + " hh:mm A").format("YYYY-MM-DD hh:mm A");
    let edd='';
    if((values.workItemType == "Change" || values.workItemType == "changeManagement")){
      edd = moment(values.expectedDueDate,jwtdf + " HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");
    }else{
      edd = moment(values.expectedDueDate, jwtdf + " hh:mm A").format("YYYY-MM-DD HH:mm:ss");
    }
    let asd = "",
      aed = "";
    if (
      this.state.previousStatus !== this.props.editFormValues.status &&
      this.state.previousStatusId === 1 &&
      this.props.editFormValues.statusId === 2 &&
      values.actualStartDate == null
    ) {
      asd = moment().tz(timezone).format("YYYY-MM-DD HH:mm:ss");
    }
    // console.log("values.actualStartDate", values.actualStartDate);
    if (
      this.state.previousStatus !== this.props.editFormValues.status &&
      this.state.previousStatusId === 2 &&
      (this.props.editFormValues.statusId === 3 ||
        this.props.editFormValues.statusId === 4)
    ) {
      aed = moment().tz(timezone).format("YYYY-MM-DD HH:mm:ss");
    }
    if (
      this.state.previousStatus !== this.props.editFormValues.status &&
      this.state.previousStatusId === 1 &&
      this.props.editFormValues.statusId === 4 &&
      values.actualStartDate != null
    ) {
      aed = moment().tz(timezone).format("YYYY-MM-DD HH:mm:ss");
    }
    // let asd = moment(values.actualStartDate, jwtdf + " hh:mm A").format("YYYY-MM-DD hh:mm A");
    // let aed = moment(values.actualEndDate, jwtdf + " hh:mm A").format("YYYY-MM-DD hh:mm A");
    // console.log("AED", aed);
    let expectedStartDate = "";
    let expectedDueDate = "";
    let actualStartDate = "";
    let actualEndDate = "";

    //expectedStartDate = typeof esd == "object" ? moment.utc(esd).format() : moment.utc(moment(esd)).format();
    expectedStartDate = esd;
    // expectedDueDate = typeof edd == "object" ? moment.utc(edd).format() : moment.utc(moment(edd)).format();
    expectedDueDate = edd;
    // actualStartDate = asd.length != 0  ? moment.utc(moment(asd)).format() : null;
    // actualEndDate = aed.length != 0 ? moment.utc(moment(aed)).format() : null;
    actualStartDate = asd.length != 0 ? asd : null;
    actualEndDate = aed.length != 0 ? aed : null;
    // console.log("ASD 02", actualStartDate);
    let moduleName = "";
    this.props.editFormValues.workItemType == "Change"
      ? (moduleName = "ChangeManagement")
      : this.props.editFormValues.workItemType == "Problem"
      ? (moduleName = "Investigation")
      : this.props.editFormValues.workItemType == "Incident"
      ? (moduleName = "Breakfix")
      : (moduleName = this.props.editFormValues.workItemType);

    let ref = this;
    // console.log(values.assignedToId);
    // if(values.assignedToGroup=="" || values.assignedToGroup==null || values.assignedTo=="" || values.assignedTo==null){
    if (values.assignedToGroup == "" || values.assignedToGroup == null) {
      this.props.setAssignmentGrpListErrorColor("error");
      return false;
    }
    // if(values.assignedToGroup=="" ||  values.assignedToGroup==null){
    // 	GLOBAL.assignmentGrpListErrorColor = "error";
    // } else {
    // 	GLOBAL.assignmentGrpListErrorColor = "";
    // }
    // if((values.statusName === "Completed" || values.statusName === "Cancelled" || values.statusName === "In Progress")
    // 	&& (values.assignedToId=="" || values.assignedToId==null)
    // ){
    // 	GLOBAL.assignToListErrorColor = "error";
    // } else {
    // 	GLOBAL.assignToListErrorColor = "";
    // }
    const isChangeImplementation = (this.props.editFormValues.workItemType==='Change' || this.props.editFormValues.workItemType==='ChangeManagement') &&  this.props.editFormValues.taskTypeId != '50';
    const reviewEnabled = this.props.adminPreferencesListData && isChangeImplementation;
    const isLoggedIn_as_Reviewer = this.props.userId==values.reviewerId;
    const isLoggedIn_as_Assignee = this.props.userId==values.assignedToId;
    if(reviewEnabled && this.state.previousStatusId=='2' && this.state.statusName=='In Review' && (values.reviewerId=='' || typeof values.reviewerId=='undefined' || values.reviewerId==null)){
      swal({
        text: this.props.translator['Please assign the reviewer before submitting for Review'],
        button: this.props.translator["OK"]
      });
      this.props.setReviewerErrorParent('error');
      return;
    }

    if(reviewEnabled && this.state.previousStatusId=='2' && this.state.statusName=='In Review' && values.reviewerId==this.props.user_id){
      swal({
         text: this.props.translator['Assignee and Reviewer cannot be the same user'],
         button: this.props.translator["OK"]
      });
      return;
    }
    
    if(reviewEnabled && ((this.state.previousStatusId=='2' && this.state.statusName=='In Review') || (this.state.previousStatusId=='9' && this.state.statusName=='Completed')) && !isLoggedIn_as_Assignee){
      swal({
        text: this.props.translator['Please assign the ticket to yourself before changing the status of the ticket'],
        button: this.props.translator["OK"]
      });
      return;
    }
    
    if(reviewEnabled && this.state.previousStatusId=='8' && (this.state.statusName=='Reviewed' || this.state.statusName=='In Progress') && !isLoggedIn_as_Reviewer){
      swal({
        text: this.props.translator['You are not allowed to Change the status of ticket, as You are not Reviewer'],
        button: this.props.translator["OK"]
      });
      return;
    }
    if (
      this.state.previousStatus !== this.state.statusName &&
      (this.state.statusName === "Completed" ||
        this.state.statusName === "Cancelled" ||
        this.state.statusName === "In Progress" ||
        this.state.statusName === "On Hold")
    ) {
      if(reviewEnabled && values.reviewerId==this.props.editTaskFormValues.reviewerId){

      }else if (this.props.user_id != values.assignedToId) {
        swal({
          text: this.props.translator[
            "Please assign the ticket to yourself before changing the status of the ticket"
          ],
          button: this.props.translator["OK"],
        });
        return false;
      }
    }

    if (
      this.state.previousStatus == this.state.statusName &&
      this.previousGroupId == values.assignedToGroupId &&
      (values.assignedToId === "" ||
        values.assignedToId === null ||
        values.assignedToId === 0)
    ) {
      this.props.setAssignToListErrorColor("error");
      return false;
    }
    if (
      (this.state.statusName === "Completed" ||
        this.state.statusName === "Cancelled" ||
        this.state.statusName === "In Progress" ||
        this.state.statusName === "On Hold") &&
      (values.assignedTo == "" || values.assignedTo == null)
    ) {
      this.props.setAssignToListErrorColor("error");
      return false;
    }
    if (
      this.state.statusName === "" &&
      (this.state.previousStatus === "Completed" ||
        this.state.previousStatus === "Cancelled" ||
        this.state.previousStatus === "In Progress" ||
        this.state.previousStatus === "On Hold") &&
      (values.assignedTo === "" || values.assignedTo === null)
    ) {
      this.props.setAssignToListErrorColor("error");
      return false;
    }
    if (
      this.props.editFormValues.statusName == "Completed" ||
      this.props.editFormValues.statusName == "Cancelled" || (reviewEnabled && ((this.state.previousStatusId=='2' && this.state.statusName=='In Review') || (this.state.previousStatusId=='8' && this.state.statusName=='Reviewed') || (this.state.previousStatusId=='8' && this.state.statusName=='In Progress') || (this.state.previousStatusId=='9' && this.state.statusName=='Completed') || (this.state.previousStatusId=='7' && this.state.statusName=='In Progress') || ((this.state.previousStatusId=='2' && this.state.statusName=='On Hold'))))
    ) {
      if (!this.props.taskDetails.closeNotes) {
        // console.log("submit close notes");
        swal({
          text: this.props.translator["Please provide Notes before saving"],
          button: this.props.translator["OK"],
        });
        return false;
      }
    }
    if (values.sequenceNumber <= 0 || values.sequenceNumber > 100) {
      Swal.fire("The sequence value must be positive interger and less than 100");
    } else {
      this.setState({ previousStatus: this.props.editFormValues.statusName });
      let tempIsExternal = "";
      if (
        this.props.editFormValues.isExternalFulfilled == true ||
        this.props.editFormValues.isExternalFulfilled == "true"
      ) {
        tempIsExternal = this.props.editFormValues.isExternalFulfilled;
      } else if (
        this.props.editFormValues.isExternalFulfilled == "" ||
        this.props.editFormValues.isExternalFulfilled == "false" ||
        this.props.editFormValues.isExternalFulfilled == false ||
        this.props.editFormValues.isExternalFulfilled == undefined ||
        this.props.editFormValues.isExternalFulfilled == null
      ) {
        tempIsExternal =
          GLOBAL.isExternalTaskEdit == true ||
          GLOBAL.isExternalTaskEdit == "true"
            ? "true"
            : "false";
      }
      // console.log("Patch values");
      // console.log(values);
      return axios
        .patch(GLOBAL.editTaskUrl + values.taskCode, {
          taskCode: values.taskCode,
          clientId: values.clientId,
          applicationName: "XSM",
          moduleName: moduleName,
          actualStartDate: actualStartDate,
          actualEndDate: actualEndDate,
          assignedToId: values.assignedToId == null ? "0" : values.assignedToId,
          assignedToGroupId: values.assignedToGroupId,
          assignedTo: values.assignedTo == "" ? null : values.assignedTo,
          assignedToGroup: values.assignedToGroup,
          description: values.description,
          additionalParams: values.additionalParams,
          sequenceNumber: values.sequenceNumber,
          statusId: this.props.editFormValues.statusId,
          statusName: this.props.editFormValues.statusName,
          expectedStartDate: expectedStartDate,
          expectedDueDate: expectedDueDate,
          workItemType: moduleName,
          modifiedBy: "",
          modifiedByName: "",
          companyName: this.props.editFormValues.companyName,
          isExternalFulfilled: tempIsExternal,
          taskType: values.taskType,
          serviceCompanyId: this.props.editFormValues.serviceCompanyId,
          serviceCompanyName: this.props.editFormValues.serviceCompanyName,
          supportCompanyId: values.supportCompanyID,
          supportCompanyName: values.supportCompanyName,
          reviewerId: values.reviewerId==''?0:values.reviewerId,
          reviewer: values.reviewerName
        })
        .then(function (response) {
          ref.props.flushReducer();
          ref.props.loadTaskItemToEdit(ref.props.taskId);
          ref.props.loadAsignedTo(values.assignedToGroupId);
          ref.props.getEditTaskStatus(ref.props.taskId);
          ref.props.changeActionView("processHierarchy");
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  }

  handleClickOutside() {
    this.statusHamburgerShowHide();
  }

  statusHamburgerShowHide() {
    this.setState({
      statusHamburgerShowHide: !this.state.statusHamburgerShowHide,
    });
  }

  setStatusName(value) {
    this.setState({ statusName: value });
  }

  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;
    let moduleLink = "";
    let moduleLinkClickable = false;
    if (this.props.moduleList.data && this.props.moduleList.data.length > 0) {
      if (
        this.props.taskDetails.workItemType == "Breakfix" ||
        this.props.taskDetails.workItemType == "Incident"
      ) {
        moduleLinkClickable = this.props.moduleList.data.includes("breakfix");
      } else if (
        this.props.taskDetails.workItemType == "Fullfilment" ||
        this.props.taskDetails.workItemType == "Fulfillment"
      ) {
        moduleLinkClickable =
          this.props.moduleList.data.includes("fullfilment");
      } else if (
        this.props.taskDetails.workItemType == "Investigation" ||
        this.props.taskDetails.workItemType == "Problem"
      ) {
        moduleLinkClickable =
          this.props.moduleList.data.includes("investigation");
      } else if (
        this.props.taskDetails.workItemType == "ChangeManagement" ||
        this.props.taskDetails.workItemType == "Change"
      ) {
        moduleLinkClickable =
          this.props.moduleList.data.includes("changeManagement");
      }
    }
    if (
      this.props.taskDetails.workItemType == "Breakfix" ||
      this.props.taskDetails.workItemType == "Incident"
    )
      moduleLink =
        "/editBreakFix/" + this.props.taskDetails.workItemId;
    else if (
      this.props.taskDetails.workItemType == "Fullfilment" ||
      this.props.taskDetails.workItemType == "Fulfillment"
    ) {
      moduleLink = "/fulFillment/" + this.props.taskDetails.workItemId;
      let searchParams = Object.assign({});
      searchParams.searchByList = "itemStatus";
      searchParams.multipleValueList = "70,15,100";
      searchParams.size = 10;
      searchParams.currentPage = 0;
      //this.props.loadFulfillmentList(searchParams);
      //this.props.fetchFulfillmentGroupList();
    } else if (
      this.props.taskDetails.workItemType == "Investigation" ||
      this.props.taskDetails.workItemType == "Problem"
    )
      moduleLink = "/investigationEdit/" + this.props.taskDetails.workItemId;
    else if (
      this.props.taskDetails.workItemType == "ChangeManagement" ||
      this.props.taskDetails.workItemType == "Change"
    )
      moduleLink = "/editChange/" + this.props.taskDetails.workItemId;

    const isGuestUserRoleEnabled =  this.props.disableFieldsForGuestRole;
    const reviewStatus = this.state.previousStatusId=='8';
    const reviewerId = typeof this.props.editTaskFormValues==='undefined' || typeof this.props.editTaskFormValues.reviewerId==='undefined'?'':this.props.editTaskFormValues.reviewerId;
    const isLoggedInReviewer = this.props.userId==reviewerId;
    const isReviewerFunctionalityEnabled = reviewStatus && this.props.adminPreferencesListData;
    const isEnabledForViewerRole =  isGuestUserRoleEnabled && isReviewerFunctionalityEnabled && isLoggedInReviewer;
    const disableForGuestRole =  isEnabledForViewerRole? false : isGuestUserRoleEnabled;
    const loggedInAssignedUserID = typeof this.props.editTaskFormValues==='undefined' || typeof this.props.editTaskFormValues.assignedToId==='undefined'?'':this.props.editTaskFormValues.assignedToId;
    const isLoggedInAssignedUser = this.props.userId==loggedInAssignedUserID;
    const disableForLoggedInUser =  isReviewerFunctionalityEnabled && reviewStatus && isLoggedInAssignedUser;
    return (
      <Row className="margin-b-15">
        <Col md={3} xs={12}>
              <h1 bsClass="" className="sPageHeading">
                {this.props.translator["Task"]}
                <div className="order position-re">
                  {moduleLinkClickable ? (
                    <Link to={moduleLink} onClick={()=>this.props.setWipSearchApply(false)}>
                      {this.props.taskDetails.parentWorkItemId} &gt;&gt;{" "}
                      {this.props.taskDetails.taskCode}
                    </Link>
                  ) : (
                    <span>
                      {this.props.taskDetails.parentWorkItemId} &gt;&gt;{" "}
                      {this.props.taskDetails.taskCode}
                    </span>
                  )}
                </div>
              </h1>
        </Col>
        <Col md={9} xs={12}>
            <div className="topRgtNavIcons stusGap">
            <div className="toprigLnk">
                <div className="thrlnebtn">
                  <Button
                    title="Statuses" 
                    onClick={this.statusHamburgerShowHide.bind(this)}
                    className="btnDropMenu btn btn-default"
                    disabled={
                      this.props.editTaskStatus.editTaskStatus === false || disableForGuestRole || disableForLoggedInUser ||
                      this.props.editTaskStatus.statusId == 3 ||
                      this.props.editTaskStatus.statusId == 4
                        ? true
                        : false
                    }
                  >
                    {this.props.editFormValues.statusName}{" "}
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </Button>
                  {this.state.statusHamburgerShowHide ? (
                    this.props.taskStatusLoader ? null : (
                      <TaskStatusHamburger
                      isLoggedIn_as_Reviewer={this.props.userId==this.props.editTaskFormValues.reviewerId}
                      isLoggedIn_as_Assignee={this.props.userId==this.props.editTaskFormValues.assignedToId}
                        assignToListErrorColorParentFunc={
                          this.props.assignToListErrorColorParentFunc
                        }
                        setAssignToListErrorColor={
                          this.props.setAssignToListErrorColor
                        }
                        setStatusName={this.setStatusName}
                        statusName={this.state.statusName}
                        taskStatusToEdit={this.props.taskStatusToEdit}
                        fun={this.callbackFun}
                        changeActionView={this.props.changeActionView}
                        editFormValues={this.props.editFormValues}
                        rightEditPanel={this.props.rightEditPanel}
                        adminPreferencesListData={this.props.adminPreferencesListData}
                        userId={this.props.userId}
                        previousStatusId={this.state.previousStatusId}
                        editTaskFormValues={this.props.editTaskFormValues}
                      />
                    )
                  ) : null}
                </div>
              </div>
              <div className="toprigLnk status fulfillHeader">
                <Nav className="icnlnk riggep float-l" as="ul">
                  <Nav.Item as="li" eventKey={3}>
                    <a className={this.props.actionView == "processHierarchy" ? "active" : ""}
                      href="javascript:void(0)"
                      title={this.props.translator["Activity Details"]}
                      onClick={() => {
                        this.props.changeActionView("processHierarchy");
                        this.props.rightEditPanel(true);
                      }}
                    >
                      <LuActivitySquare/>
                    </a>
                  </Nav.Item>
                  <Nav.Item as="li"  eventKey={1}>
                    <a href="javascript:void(0)" className={this.props.actionView == "tags" ? "active" : ""}
                     title={this.props.translator["Custom Attribute"]}
                     onClick={() => {
                      this.props.changeActionView('tags');
                      this.props.rightEditPanel(true);
                      }}><PiTagBold className="svgRotate90"/>
                      </a>
                  </Nav.Item>
                  <Nav.Item as="li" eventKey={0}>
                    <a
                      href="javascript:void(0)" className={this.props.actionView == "provideAttachments" ? "active" : ""}
                      title={this.props.translator["Attachment"]}
                      onClick={() => {
                        this.props.changeActionView("provideAttachments");
                        this.props.rightEditPanel(true);
                      }}
                    >
                      <ImAttachment/>
                    </a>
                  </Nav.Item>
                  <Nav.Item as="li" eventKey={1}>
                    <a
                      href="javascript:void(0)" className={this.props.actionView == "showTaskVariables" ? "active" : ""}
                      title={this.props.translator["Task Variables"]}
                      onClick={() => {
                        this.props.changeActionView("showTaskVariables");
                        this.props.rightEditPanel(true);
                      }}
                    >
                      <FaTimeline style ={{ rotate: "90deg" }}/>
                    </a>
                  </Nav.Item>
                  <Nav.Item as="li" eventKey={2}>
                    <a className={this.props.actionView == "viewParentItemDetails" ? "active" : ""}
                      title={this.props.translator["Task Item Details"]}
                      onClick={() => {
                        this.props.changeActionView("viewParentItemDetails");
                        this.props.rightEditPanel(true);
                      }}
                      href="javascript:void(0)"
                    >
                      <AiOutlineApartment/>
                    </a>
                   </Nav.Item>
                  <Nav.Item title={this.props.translator['Watcher Details']} eventKey={1} as="li">
                    <a href="javascript:void(0)" className={this.props.actionView == "watcher" ? "active" : ""} onClick={() => { this.props.changeActionView("watcher"); this.props.rightEditPanel(true);}}>
                      <span><FaRegEye/><b className="watcher-count prblmCount">{this.props.watcherCount}</b></span>
                    </a>
                    </Nav.Item>
                  <Nav.Item title={this.props.translator['SLA Progress']} eventKey={1} as="li">
                    <a href="javascript:void(0)" className={this.props.actionView == "slaProgress" ? "active" : ""} onClick={() => { this.props.changeActionView("slaProgress"); this.props.rightEditPanel(true);}}>
                    <LiaHourglass className="hourGlsWidth" />
                    </a>
                  </Nav.Item>
                  {/* <Nav.Item title={this.props.translator['SLA Progress']} eventKey={5} onClick={()=> {this.props.changeActionView('sla');}}><span className="sAction bgBtn14 slapro"></span></Nav.Item> */}
                  {/* <Nav.Item title={this.props.translator['Edit Task']} eventKey={4} disabled={pristine || submitting} onClick={reset}><span className="sAction bgBtn16 refresh"></span></Nav.Item> */}
                  <NavDropdown
                    as={"ul"}
                    className="threeDotul"
                    eventKey={4}
                    title={
                      <span title="More">
                        <BiDotsVertical/>
                      </span>
                    }
                    id="extLnks"
                  >
                    <NavDropdown.Item
                  bsPrefix=" "
                  as="li"
                  title="External Attributes"
                  eventKey={1}
                >
                  <Nav.Link onClick={() => {
                      this.props.changeActionView("viewExternalSystemAttributes"); this.props.rightEditPanel(true);
                    }} href="#"><TbTextWrap />External Attributes
                  </Nav.Link>
                </NavDropdown.Item>
                    {/* <NavDropdown.Item
                      title="External Attributes"
                      bsPrefix=" "
                      as="li"
                      eventKey={4.1}
                      onClick={() => {
                        this.props.changeActionView(
                          "viewExternalSystemAttributes"
                        );
                      }}
                    >
                      <i className="fa fa-angle-double-right"></i>External
                      System Attributes
                    </NavDropdown.Item> */}
                  </NavDropdown>
                </Nav>

                <Nav className="icnlnk" as="ul">
                  {/* <Nav.Item title={this.props.translator['Save']} eventKey={1}><span className="sAction bgBtn15 save" onClick={handleSubmit(this.saveChange.bind(this))}></span></Nav.Item> */}
                  
                  <Nav.Item bsPrefix=' ' eventKey={1} as="li">
                    <a title={this.props.translator["Refresh"]} href="javascript:void(0)" onClick={() => {this.props.RefreshScreen();}}>
                      <HiOutlineRefresh className="refreshFlip" />
                    </a>
                  </Nav.Item>
                  <Nav.Item as="li" eventKey={2}>
                    <Link
                      to="javascript:void(0)"
                      disabled={submitting}
                      title={this.props.translator["Save"]}
                      onClick={disableForGuestRole ? '' : handleSubmit(this.saveChange.bind(this))}
                      ref={(e) => (this.saveObjRef = e)}
                      className="ctrlKeyPrevent"
                    >
                      <span>
                          {submitting ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/>}
                      </span>
                    </Link>
                  </Nav.Item>

                  <Nav.Item
                    as="li"
                    title={this.props.translator["Close"]}
                    eventKey={3}
                  >
                    <Link to="/quickViewBreakFix" ref={e => this.closeButton = e} className="ctrlKeyPrevent">
                      <IoClose/>
                    </Link>
                  </Nav.Item>
                </Nav>
              </div>
            </div>
        </Col>
      </Row>
    );
  }
};

let TaskStatusHamburger = class TaskStatusHamburger extends React.Component {
  constructor(props) {
    super(props);
    this.state={reviewEnabled: false};
    this.renderStatusOptions = this.renderStatusOptions.bind(this);
    this.currentAction = this.currentAction.bind(this);
  }
  componentDidMount(){
    const isChangeImplementation = (this.props.editFormValues.workItemType==='Change' || this.props.editFormValues.workItemType==='ChangeManagement') &&  this.props.editFormValues.taskTypeId != '50';
    const reviewEnabled = this.props.adminPreferencesListData && isChangeImplementation;
    this.setState({reviewEnabled});
  }
  handleClickOutside() {
    this.props.fun(false);
  }

  currentAction(id, name) {
    this.props.editFormValues.statusId = id;
    this.props.editFormValues.statusName = name;
    this.props.setStatusName(name);
    this.props.setAssignToListErrorColor("");
    this.props.assignToListErrorColorParentFunc("");
  }

  renderStatusOptions(status) {
    return status.map((item, index) => {
      if(this.state.reviewEnabled && item.value=='3' && this.props.previousStatusId=='2'){
         return null;
      }     
      if(this.state.reviewEnabled && item.value=='9' && this.props.isLoggedIn_as_Assignee){
         return null;   
      }
      if(this.state.reviewEnabled && item.value=='3' && this.props.isLoggedIn_as_Reviewer){
        return null;
      }
      if(this.state.reviewEnabled && item.value=='2' && this.props.isLoggedIn_as_Assignee && this.props.previousStatusId=='8'){
        return null;
      }
      if(!this.state.reviewEnabled && (item.value=='8'||item.value=='9')){
        return null;
      }
      if(this.state.reviewEnabled && this.props.isLoggedIn_as_Assignee && this.props.previousStatusId=='8'){
        return null; 
      }
      if(this.state.reviewEnabled && (item.value!='9' && item.value!='8' && item.value!='2') && this.props.isLoggedIn_as_Reviewer && this.props.previousStatusId=='8'){
       return null;
      }
      if(this.state.reviewEnabled && this.props.previousStatusId=='8' && !(this.props.isLoggedIn_as_Assignee || this.props.isLoggedIn_as_Reviewer)){
        return null;
      }
      return (
        <ListGroup.Item key={index}>
          <Nav.Item
            title={item.name}
            onClick={() => {
              this.currentAction(item.value, item.name);
                this.props.changeActionView(item.name);
                this.props.rightEditPanel(true);
                this.handleClickOutside();
            }}
          >
            <MdKeyboardDoubleArrowRight/>
            {item.name}
          </Nav.Item>
        </ListGroup.Item>
      );
    });
  }

  render() {
    return (
      <div className="breakFixHamburger">
        <ListGroup className="myLinks" style={{ top: "12px" }}>
          {this.renderStatusOptions(this.props.taskStatusToEdit)}
        </ListGroup>
      </div>
    );
  }
};

TaskStatusHamburger = OnClickOutside(TaskStatusHamburger);

const mapStateToProps = (state) => {
  let { createTaskFormValues, moduleList, taskStatusToEdit, taskStatusLoader,watcherDetailsFetched } =
    state;
  let formData = createTaskFormValues.editFormValues;
  // console.log("taskstatus==",taskStatusLoader);
  let watcherCount = watcherDetailsFetched.length;
  const adminPreferencesListData = state.admin_preferences_list_data_reducer.adminPreferencesListData.value?state.admin_preferences_list_data_reducer.adminPreferencesListData.value==='True':false;
  const editTaskFormValues = getFormValues('editTaskForm')(state);
  return {
    editFormValues: formData,
    moduleList,
    taskStatusToEdit,
    taskStatusLoader: taskStatusLoader.loading,
    watcherCount,
    adminPreferencesListData,
    editTaskFormValues
  };
};

TaskEditHeader = reduxForm({
  form: "editTaskForm",
})(TaskEditHeader);

export default connect(mapStateToProps, {
  loadTaskItemToEdit,
  fetchFulfillmentGroupList,
  loadFulfillmentList,
  loadTaskGroup,
  loadAsignedTo,
  getEditTaskStatus,
  flushReducer,
  setWipSearchApply
})(TaskEditHeader);
