
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Nav from "react-bootstrap/Nav";
import { Link, NavLink } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import axios from 'axios';
import { connect } from 'react-redux';
import swal from "sweetalert";
import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import {LuSearch, LuBarChart} from 'react-icons/lu';

import {setInitialwibGlobalSearchFilterData} from '../../../../actions/globalSearch/globalSearchAction';
import { GLOBAL } from "_Globals";
import { navigationHooks } from '../../../../helpers/NavigationHook';
import { MyContext } from '_MyContext';

let dateformat="";

class ChangeHeader extends React.Component {
	static contextType = MyContext;
    constructor(props) {
		super(props);
		this.state = {
			showSubmit: false
		};
		this.saveChange = this.saveChange.bind(this);
		this.goBack=this.goBack.bind(this);
	}

	componentDidMount() {
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
		this.context.newTabPrevent();
	}
	componentDidUpdate() {
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
		this.context.newTabPrevent();
	}

    componentWillReceiveProps(nextProps)
	{
		dateformat=this.props.date_format;
	}
    saveChange(values) {
		if(typeof values.changeGroupname==='undefined' || values.changeGroupname===''){
			values.changeGroupname='0';
		}
		if(typeof values.changeManagementGroup==='undefined' || values.changeManagementGroup===''){
            values.changeManagementGroup='0';
		}
		if(typeof values.implementationGroup==='undefined' || values.implementationGroup===''){
            values.implementationGroup='0';
		}
		if(typeof values.impGroupname==='undefined' || values.impGroupname===''){
            values.impGroupname='0';
		}
		let ref = this;
		let ciId="";let ciName="";let ciCode="";
		localStorage.setItem('grpname',values.changeGroupname);
        localStorage.setItem('grpid',values.changeManagementGroup);
        localStorage.setItem('impgrpname',values.impGroupname);
        localStorage.setItem('implgrpid',values.implementationGroup);
        GLOBAL.beforesave=true;
		
		if ((GLOBAL.ciId == ""||GLOBAL.ciName=="")) {
				this.props.setErrorImpactedColor('error');
				return;
		}else {
				GLOBAL.CIImpactedErrorColor = ""
				this.props.setErrorImpactedColor('');
		}
		if (GLOBAL.ciId == "" || GLOBAL.ciId == undefined){
				ciId = 0; ciName = ""; ciCode = "";GLOBAL.environmentName="";GLOBAL.categoryName="";GLOBAL.ciConsumerCompany="";
				GLOBAL.ciSupportCompany="";GLOBAL.ciClassId =""; GLOBAL.ciLocationId="";GLOBAL.ciLocationName="";
		}else{
				ciId = GLOBAL.ciId; ciName = GLOBAL.ciName; ciCode = GLOBAL.ciIdNumber;
		}
		if(GLOBAL.breakFixConsumerID == "") {
				GLOBAL.consumerErrorColor="error";
				this.props.setErrorConsumerColor('error');
				return;
		}else {
				GLOBAL.consumerErrorColor="";
				this.props.setErrorConsumerColor('');
		}
		
		ref.setState({ showSubmit: true });
		let jwtdf = dateformat.slice(0, 10).toUpperCase();

		let riskAssessmentValue = this.props.riskAssessmentValue.overallRisk;
		if( GLOBAL.breakFixServiceID == ""||GLOBAL.breakFixServiceName==""){
			
			GLOBAL.breakFixServiceID=0;
			GLOBAL.breakFixServiceName=null;
	    }
		 
		if(values && values.expectedStartDate){
			values.expectedStartDate =  moment(values.expectedStartDate).format((jwtdf + " HH:mm:ss"));
		}
		if(values && values.expectedEndDate){
			values.expectedEndDate =  moment(values.expectedEndDate).format((jwtdf + " HH:mm:ss"));
		}
		if(values && values.actualStartDate){
			values.actualStartDate =  moment(values.actualStartDate).format((jwtdf + " HH:mm:ss"));
		}
		if(values && values.actualEndDate){
			values.actualEndDate =  moment(values.actualEndDate).format((jwtdf + " HH:mm:ss"));
		}
		if(values && values.startDate){
			values.startDate =  moment(values.startDate).format((jwtdf + " HH:mm:ss"));
		}
		if(values && values.endDate){
			values.endDate =  moment(values.endDate).format((jwtdf + " HH:mm:ss"));
		}
		if(values && values.lStartDate){
			values.lStartDate =  moment(values.lStartDate).format((jwtdf + " HH:mm:ss"));
		}
		if(values && values.lEndDate){
			values.lEndDate =  moment(values.lEndDate).format((jwtdf + " HH:mm:ss"));
		}
		
	   if(values.changeTiming === '20'){
		  values.expectedStartDate = 0;
		  values.expectedEndDate = 0;
		  if(values.downtimeRad === 'N' || values.downtimeRad === undefined){
			values.startDate = 0;
			values.endDate = 0;
		  }
	    }else{
		  values.actualStartDate = 0;
		  values.actualEndDate = 0;
		  if(values.downtimeRad === 'N' || values.downtimeRad === undefined ){
			 values.startDate = 0;
			 values.endDate = 0;
		  }
	    }

		// console.log('*************', {isRiskAssessmentMandatory: this.props.isRiskAssessmentMandatory, isRiskAssessmentFilled: this.props.isRiskAssessmentFilled});
		if(this.props.isRiskAssessmentMandatory == true && this.props.isRiskAssessmentFilled == false){
			swal({
				text: this.props.translator["Please fill Risk Assessment"] || "Please fill Risk Assessment",
				button: this.props.translator["OK"],
			  });
			  ref.setState({ showSubmit: false });
			  return false;
		}

		axios.post(GLOBAL.postChangeUrl,{
			"changeType": values.type,
			"summary": values.summary,
			"requesterId": GLOBAL.breakFixConsumerID.toString(),
			"requesterName": GLOBAL.breakFixConsumerName,
			"serviceId": 0,
			"serviceName": "",
			"description": values.description,
			"changeTiming": (values.type =="25" || values.type =="30" || values.type =="35" ?"0": values.changeTiming),
			"technicalRisk": (values.type =="25" || values.type =="30" || values.type =="35"?"0":values.overallTechnicalRisk),
			"businessRisk": (values.type =="25" || values.type =="30" || values.type =="35"?"0":values.overallBusinessRisk),
			"expEndDate": values.expectedEndDate,
			"expStartDate": values.expectedStartDate,
			"dStartDate": values.startDate,
			"dEndDate": values.endDate,
			"actStartDate": values.actualStartDate,
			"actEndDate": values.actualEndDate,
			"changeGroupid": values.changeManagementGroup.toString(),
			"changeGroupname": values.changeGroupname,
			"changeUserid": values.changeManagementIndividual===undefined ||values.changeManagementIndividual==='' ? 0 : values.changeManagementIndividual.toString(),
			"changeUsername": values.changeUsername,
			"impGroupid":values.implementationGroup.toString(),
			"impGroupname": values.impGroupname,
			"impUserid": values.implementationIndividual===undefined  || values.implementationIndividual==='' ? 0 : values.implementationIndividual.toString(),
			"impUsername": values.impUsername===undefined || values.impUsername===''?0:values.impUsername,
			"createdBy": "",
			"createdOn": 0,
			"modifiedBy": "0",
			"modifiedOn": 0,
			"plan": 0,
			"planType": "20",
			"requestId": "",
			"status": "30",
			"type": "0",
			"consumerCompany": values.company,
			"createdByName": "",
			"downtimeRequired": values.downtimeRad ? values.downtimeRad : "N",
			"consumerCompanyName":values.companyName,
			"ciId":ciId,
            "ciName":ciName,
			"ciCode":ciCode,
			"ciClassName": values.ciClassName,
			"urgency":(values.type =="25" || values.type =="35" ?values.urgency:"0"),
			"reasonForChange":(values.type =="25" || values.type =="30" || values.type =="35" ? values.reasonForChange:"0"),
			"risk":(values.type =="25" || values.type =="30" || values.type =="35" ?riskAssessmentValue:"null"),
			"riskImpact":(values.type =="25" || values.type =="30" || values.type =="35" ?values.riskImpact:"null"),
			"riskOccurrenceProbability":(values.type =="25" || values.type =="30" || values.type =="35" ?values.riskOccurrenceProbability:"null"),
			"controlEffectiveness":(values.type =="25" || values.type =="30" || values.type =="35" ?values.controlEffectiveness:"null"),
			"cmdbUpdateNeeded":(values.type =="25" || values.type =="30" || values.type =="35" ?values.cmdbUpdateNeeded:"No"),
			"category":GLOBAL.categoryName, 
			"environment":GLOBAL.environmentName,
			"ciConsumerCompany": GLOBAL.ciConsumerCompany == "" ? "0" : GLOBAL.ciConsumerCompany,
			"ciSupportCompany": GLOBAL.ciSupportCompany == "" ? "0" : GLOBAL.ciSupportCompany ,
			"ciClassId" : GLOBAL.ciClassId == "" ? "0" : GLOBAL.ciClassId,
			"ciLocationId" : GLOBAL.ciLocationId == "" ? "0" : GLOBAL.ciLocationId,
			"ciLocationName" : GLOBAL.ciLocationName == "" ? "0" : GLOBAL.ciLocationName,
			"serviceBased":false,
			"cabDate":0,
			"requesterLocationId": GLOBAL.servicelocationid || "",
			"requesterLocationName": GLOBAL.servicelocationname || "",
			"isExternal": (GLOBAL.isExternalChangeCreate=="" || GLOBAL.isExternalChangeCreate==null || GLOBAL.isExternalChangeCreate==undefined) ? false: GLOBAL.isExternalChangeCreate,
			"businessCriticalityId": GLOBAL.businessCriticalityId,
            "businessCriticality": GLOBAL.businessCriticality,
			"supportCompanyId": values.supportCompanyID,
			"supportCompanyName": values.supportCompanyName,
			"residualRisk": this.props.riskAssessmentDetails.residualRisk,
			"riskAssessment": this.props.riskAssessmentDetails.riskAssessment
		}).then(function (response) {
				if(response.status==201){
					ref.setState({ showSubmit: false });
			        if(ciId !=0){
						let payloadObj ={
							"ci_id": ciId,  //==> ID of CI related
							"wi_id_related": response.data.changeId, //==> ID of Work Item related
							"wi_id_number": response.data.changeNumber,//  ==> Number of work item related
							"ci_id_reported": response.data.createdOn,
							"created_by": "",
							"relationship_type": "Y",
							"module": "Change",
							"consumerCompany": values.company,
					        "consumerCompanyName":values.companyName
						  }
			           axios.post(GLOBAL.cmdbRelatedLogDeleteUrl, /*{
					        "ci_id": response.data.changeId,
					        "ci_id_related":ciId,
					        "ci_id_number":response.data.changeNumber,
					        "ci_id_reported":response.data.createdOn,
					        "created_by": "",
				            "relationship_type":"Y",
					        "module":"Change",
					        "consumerCompany": values.company,
					        "consumerCompanyName":values.companyName
				        }*/
						payloadObj
						).then((response) =>{
				       });
			        }
		}
			navigationHooks.navigate("/editChange/" + response.data.changeId.toString());
		}).catch(function (error) {
			ref.setState({ showSubmit: false });
		});	
	}
    goBack(){
		this.props.setInitialwibGlobalSearchFilterData();
	}
    render() {
		const { handleSubmit, pristine, reset, submitting } = this.props;
		return (
			<Row className="margin-b-15">
				<Col lg={8} md={7} sm={6} xs={12}>
					<h2 className="sPageHeading1">{this.props.translator['Change']}</h2>
				</Col>
				<Col lg={4} md={5} sm={6} xs={12}>
				<div className="status myStatus ">
                   <Nav as="ul">
						<Nav.Item bsPrefix=' ' as="li" eventKey={1}>
								<Link className="saveBtnStyle ctrlKeyPrevent" to="javascript:void(0)" bsStyle="primary" disabled={this.state.showSubmit} title={this.props.translator['Save']}
									onClick={handleSubmit(this.saveChange)} ref={(e) => (this.saveObjRef = e)}>
									<span> {this.state.showSubmit ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/>}
									</span>
								</Link>
						</Nav.Item>
						<Nav.Item as="li" eventKey={2}>
                            <NavLink to="/quickViewBreakFix" title={this.props.translator["Close"]} className="ctrlKeyPrevent" ref={e => this.closeButton = e}>
								<IoClose/>
                            </NavLink>
                        </Nav.Item>
			        </Nav>
				</div>
				<div className="status myStatusGap border-r margin-r-10 padding-r-10">
						<Nav as={'ul'} bsPrefix=' '>
							<Nav.Item as='li' eventKey={4}><Nav.Link title={this.props.translator['Process Hierarchy']} onClick={() => { this.props.processHierarchy(); }}><LuSearch/><LuBarChart className='processBarIcn'/></Nav.Link></Nav.Item>
						</Nav>
					</div>
				</Col>
			</Row>
		);
	}
}

const mapStateToProps = ({ riskAssessmentValue }) => { 
	return { riskAssessmentValue } };

ChangeHeader = reduxForm({
	form: 'changeForm'
})(ChangeHeader);

export default connect(mapStateToProps, {setInitialwibGlobalSearchFilterData})(ChangeHeader);