
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import DashboardListHeader from "./DashboardListHeader.js";
import DashboardListDetals from "./DashboardListDetails.js";
import DashboardListView from "./DashboardListView.js";
import {
  loadDashboardList
} from "../../actions/dashboardConfigration/dashboardConfigAction.js";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import Breadcrumbs from "../common/header/breadcrumbs.js";
import axios from "axios";
import "_Css/common/_dataTable.scss";

const DashboardConfigIndex = () => {
  const dispatch = useDispatch();
  const translator = useSelector((state) => state.spcmReducer.tr);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [classification, setClassification] = useState("");
  const [dashboardID, setDashboardID] = useState("");
  const [dashboardType, setDashboardType] = useState("");
  const [roles, setRoles] = useState("");
  const [masterRoles, setMasterRoles] = useState("");
  const [reportFilters, setReportFilters] = useState("");
  const[active,setActive]= useState("")
  const [modifiedBy, setModifiedBy] = useState("");
  const [modifiedDate, setModifiedDate] = useState("");
  const [rightDisplay, setRightDisplay] = useState(false);
  const [requestMethod, setRequestMethod] = useState("");

  const showRightSide = (val) => {
    setRightDisplay(val);
  };

  const setShortDescValues = (
    id,
    name,
    classification,
    dashboardID,
    dashboardType,
    active,
    roles,
    reportFilters,
    masterRoles,
    modifiedBy,
    modifiedDate,
    requestMethod
  ) => {
  
    setId(id);
    setName(name);
    setClassification(classification);
    setDashboardID(dashboardID);
    setDashboardType(dashboardType);
    setReportFilters(reportFilters);
    setMasterRoles(masterRoles)
    setRoles(roles);
    setActive(active)
    setModifiedBy(modifiedBy);
    setModifiedDate(modifiedDate);
    setRequestMethod(requestMethod)
  };

  useEffect(() => {
    dispatch(loadDashboardList());
  }, []);
 

  return (
    <div>
      <div>
        <div className="container-fluid padding-t-10 margin-b-15">
          <Breadcrumbs activePageName={"Superset Dashboard"} />
        </div>
        <div className="container-fluid" bsClass="">
          <DashboardListHeader translator={translator} />
          <div className="dtaTableDv integrateHubTable">
            <PanelGroup direction="horizontal">
              <Panel id="sidebar" minSize={33} order={1} defaultSize={rightDisplay ? 67 : 100} className={rightDisplay ? "isShowLeftPanel catBordDv rwLeftPart" : "catBordDv rwLeftPart"}>
                <DashboardListView
                    rightDisplay={rightDisplay}
                    setShortDescValues={setShortDescValues}
                    translator={translator}
                    showRightSide={showRightSide}
                  />
              </Panel>
              {rightDisplay ?
                  <>
                    <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
                    <Panel minSize={33} order={2} defaultSize={33}>
                      <div className="stickyArea rBoxStyle">
                        <DashboardListDetals
                          id={id}
                          name={name}
                          classification={classification}
                          requestMethod={requestMethod}
                          dashboardID={dashboardID}
                          dashboardType={dashboardType}
                          reportFilters={reportFilters}
                          masterRoles={masterRoles}
                          roles={roles}
                          active={active}
                          modifiedBy={modifiedBy}
                          modifiedDate={modifiedDate}
                          showRightSide={showRightSide}
                        
                        />
                      </div>
                    </Panel>
                  </> : ""
              }
            </PanelGroup>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardConfigIndex