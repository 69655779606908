
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Container } from 'react-bootstrap';

import PindCategories from "../common/header/pind-categories";
import Breadcrumbs from "../common/header/breadcrumbs";
import Header from "./adminPreferencesHeader";
import List from "./adminPreferencesList";

const Index = () => {
  const [companyErrorState, setCompanyErrorState] = useState('');
  const [searchText, setSearchText] = useState('');
  const [onlyShowOverridden, setOnlyShowOverridden] = useState(false);
  const [instanceOrCompanySelection, setInstanceOrCompanySelection] = useState('Company');
  const [retainSelectedModule, setRetainSelectedModule] = useState(false);

  function changeSearchText(val) {
    setSearchText(val);
  }

  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Breadcrumbs activePageName="Admin Preferences" />
      </Container>

      <Container fluid>
        <Header companyErrorState={companyErrorState} setCompanyErrorState={setCompanyErrorState} changeSearchText={changeSearchText} instanceOrCompanySelection={instanceOrCompanySelection} setInstanceOrCompanySelection={setInstanceOrCompanySelection} onlyShowOverridden={onlyShowOverridden} setOnlyShowOverridden={setOnlyShowOverridden} setRetainSelectedModule={setRetainSelectedModule} />
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <List setCompanyErrorState={setCompanyErrorState} searchText={searchText} instanceOrCompanySelection={instanceOrCompanySelection} onlyShowOverridden={onlyShowOverridden} retainSelectedModule={retainSelectedModule} setRetainSelectedModule={setRetainSelectedModule} />
          </Col>
        </Row>
      </Container>
    </main>
  );
}

export default Index;