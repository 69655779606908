
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export default function (state = initialState.reviewFilters, action){
  let newSelectedReviewOrderTypeArray = [];
  let newselectedReviewStatusArray = [];


	switch (action.type){

    //Change start
    case 'ADD_TO_SELECTEDREVIEWORDER_STATUS_LIST':
      newselectedReviewStatusArray = state.selectedStatus.concat(action.payload);
			return Object.assign({}, state, {selectedStatus: newselectedReviewStatusArray});
		case 'REMOVE_FROM_SELECTEDREVIEWORDER_STATUS_LIST':
			newselectedReviewStatusArray = state.selectedStatus.filter((item) => {return item!=action.payload;});
			return Object.assign({}, state, {selectedStatus: newselectedReviewStatusArray});

    case 'ADD_TO_SELECTEDREVIEWORDER_TYPE_LIST':
    // console.log(state.selectedTags);
    // console.log(action.payload);
  	   newSelectedReviewOrderTypeArray = state.selectedOrderType.concat(action.payload);
  	   return Object.assign({}, state, {selectedOrderType: newSelectedReviewOrderTypeArray});
  	case 'REMOVE_FROM_SELECTEDREVIEWORDER_TYPE_LIST':
      // console.log(state.selectedTags);
      // console.log(action.payload);
  		newSelectedReviewOrderTypeArray = state.selectedOrderType.filter((item) => {return item!=action.payload;});
  		return Object.assign({}, state, {selectedOrderType: newSelectedReviewOrderTypeArray});

    //my request reset filter
    case 'RESET_REVIEW_FILTERS':
      return Object.assign(
        {},
        state,
        {
          selectedOrderType:['Service Orders', 'Correction Orders'],
          selectedStatus:['Pending']
        }
      );
		default:
				return state;
		}
}
