
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Row, Col, Form,Accordion} from 'react-bootstrap';
import OnClickOutside from 'react-onclickoutside';
import { DateField, DatePicker, TransitionView, Calendar } from 'react-date-picker';
import { loadCIStatusCurrent, loadCIMetallic, loadCIEnv, loadCIManr, loadCIDataset, loadCICompany, loadCIUserBasedLocation, loadCIGroup, emptyCIGroup, loadCIInd, loadCICat, loadCIType, loadCISCat, loadCISupCompany, emptyCISupCompany, emptyCICatReducer, emptyCISCatReducer } from '../../../actions/cmdb/lovAction';
import { connect } from 'react-redux';
import { _radio } from '../../common/formFields';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { loadClassByCompanyId, resetCiClass } from '../../../actions/ham/hamAction';

//let user_id=userDetails.user_id;
import { Field, reduxForm, formValueSelector, change, SubmissionError } from 'redux-form';
// import Select from 'react-select';
import {GLOBAL} from '_Globals';
import {
	resetCMDBQVFilters
} from '../../../actions/cmdb/quickViewAction';
// import { TypeaheadExampleSingleSelect } from "../../../components/common/formFields";
import CmdbDropdownPlusTypeahead from '../common/CmdbDropdownPlusTypeahead.js';
import BusinessOwner from './formfield/businessOwnerAsyncTypeahead.js';
import TechnicalOwner from './formfield/technicalOwnerAsyncTypeahead.js';
import Location from './formfield/locationAsyncTypeahead.js';
import AssignedTo from './formfield/assignedToAsyncTypeahead.js';

import { TypeaheadSelect, DateTimeField } from '../common/helper.js';

import Cookies from "universal-cookie";
const cookies = new Cookies();

let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
dateformat = dateformat.split("~");
dateformat = dateformat[2];

class CmdbCreateForm extends React.Component { 
	constructor(props) {
		super(props);
		this.state = {
			searchResultsDiv: false,
			ciname: "",
			ciclass: "",
			cistatus: "",
			cicompanyErrorColor: "",
			cimanufacturer: "",
			cibusinessowner: "",
			citechowner: "",
			ciType: "",
			defaultTypeValue:"",
			ciCompanySelectedList: [],
			ciSupCompanySelectedList: [],
			ciSupGroupSelectedList: [],
			inputValueCompany: '',
			inputValueSupportCompany: '',
			inputValueSupportGroup: '',
			category:'',
			subCategory:'',
			is_external:'false',
			serial_number: "",
			createDataObj: {},
			errorObj: {},
			typeaheadTypedObj: {},
			typeaheadDropdownOptions: {},
			locationToggle: 'all',
			locationDropdownOptions: [],
			supportCompanyDropdownOptions: [],
			supportGroupDropdownOptions: [],
			costCenterDropdownOptions: [],
			vendorDropdownOptions: [],
			vendorLessorDropdownOptions: [],
			drLocationDropdownOptions: [],
			currencyDropdownOptions: [],
			chargeCategoryDropdownOptions: [],
			chargeFrequencyDropdownOptions: [],
			chargeTypeDropdownOptions: [],
			drTierDropdownOptions: [],
			ownershipTypeDropdownOptions: [],
			depScheduledDropdownOptions: [],
			departmentDropdownOptions: []
		};

		this.validateField = this.validateField.bind(this);
		this.onCompanySelection = this.onCompanySelection.bind(this);
		this.setCompanyErrorColor = this.setCompanyErrorColor.bind(this);
		this.onSupCompanySelection = this.onSupCompanySelection.bind(this);
		this.onSupGroupSelection = this.onSupGroupSelection.bind(this);
		this.setErrorColor = this.setErrorColor.bind(this);
		this.onCompanyInputChange = this.onCompanyInputChange.bind(this);
		this.onCrossClickCompany = this.onCrossClickCompany.bind(this);
		this.onSupportCompanyInputChange = this.onSupportCompanyInputChange.bind(this);
		this.onCrossClickSupportCompany = this.onCrossClickSupportCompany.bind(this);
		this.onSupportGroupInputChange = this.onSupportGroupInputChange.bind(this);
		this.onCrossClickSupportGroup = this.onCrossClickSupportGroup.bind(this);
		this.setMetallic = this.setMetallic.bind(this);
		this.setCreateDataObj = this.setCreateDataObj.bind(this);
		this.getLocationData = this.getLocationData.bind(this);
		this.getSupportCompany = this.getSupportCompany.bind(this);
		this.getSupportGroup = this.getSupportGroup.bind(this);
		this.getCostCenterOptions = this.getCostCenterOptions.bind(this);
		this.getVendor = this.getVendor.bind(this);
		this.getVendorLessor = this.getVendorLessor.bind(this);
		this.getMenuListData = this.getMenuListData.bind(this);
		this.getDepartment = this.getDepartment.bind(this);
	}

	searchResults(event) {
		this.setState({ searchResultsDiv: true });
	}
	
	callbackFun(id) {
		this.setState({ searchResultsDiv: id });
	}

	componentWillMount() {
		//this.props.loadCIClass();
		this.props.resetCiClass();
		this.props.loadCIStatusCurrent('create', 0);
		this.props.loadCIMetallic();
		this.props.loadCIEnv();
		this.props.loadCIManr();
		//this.props.loadCIDataset();
		this.props.loadCICompany("0");
		// this.props.loadCIUserBasedLocation();
		this.props.resetCMDBQVFilters();
		this.props.loadCIType();
		GLOBAL.cinameErrorColor = ''
		GLOBAL.ciclassErrorColor = ''
		GLOBAL.cistatusErrorColor = ''
		GLOBAL.cicompanyErrorColor = ''
		GLOBAL.cibusinessownerErrorColor = ''
		GLOBAL.citechownerErrorColor = ''
		GLOBAL.categoryErrorColor = ''
		GLOBAL.subCategoryErrorColor = ''
		GLOBAL.ciSubStatusErrorColor = ''
		GLOBAL.serialNumberErrorColor = ''

	}

	validateField(fieldType, event) { 
		switch (fieldType) {
			case 'environment':
				GLOBAL.cmdbCreateSelectedText['environment']= event.target[event.target.selectedIndex].text;
				break;

			case 'subStatus':
				GLOBAL.cmdbCreateSelectedText['sub_status_name']= event.target[event.target.selectedIndex].text;
				break;

			case 'ciname':
				if (event.currentTarget.defaultValue == "") {
					this.setState({ ciname: 'error' });
					GLOBAL.cinameErrorColor = "";
				} else {
					this.setState({ ciname: '' });
					GLOBAL.cinameErrorColor = "";
				}
				break;
			case 'ciclass':
				if (event.target.value == "") {
					this.setState({ ciclass: 'error' });
					GLOBAL.ciclassErrorColor = "";
				} else {
					this.setState({ ciclass: '' });
					GLOBAL.ciclassErrorColor = "";
				}
				break;
			case 'serial_number': 
				if (event.target.value.toString().length > 250) {
					this.setState({ serial_number: 'error' });
					GLOBAL.serialNumberErrorColor = "";
				} else {
					this.setState({ serial_number: '' });
					GLOBAL.serialNumberErrorColor = "";
				}
				break; 
			case 'citype':
				if (event.target.value == "") {
					this.setState({ ciclass: 'error' });
					GLOBAL.ciTypeErrorColor = "";
				} else {
					this.setState({ ciclass: '' });
					GLOBAL.ciTypeErrorColor = "";
				}
				break;
			case 'status':
				GLOBAL.cmdbCreateSelectedText['status_name']= event.target[event.target.selectedIndex].text;
				if (event.target.value == 0 || event.target.value == "") {
					this.setState({ cistatus: 'error' });
					GLOBAL.cistatusErrorColor = "";
				} else {
					this.setState({ cistatus: '' });
					GLOBAL.cistatusErrorColor = "";
				}
				break;
			case 'category':
				if (event.target.value == 0 || event.target.value == "") {
					this.setState({ category: 'error' });
					GLOBAL.categoryErrorColor = "";
				} else {
					this.setState({ category: '' });
					GLOBAL.categoryErrorColor = "";
				}
				break;
			case 'subCategory':
				GLOBAL.cmdbCreateSelectedText['sub_category_name']= event.target[event.target.selectedIndex].text;
				if (event.target.value == 0 || event.target.value == "") {
					this.setState({ subCategory: 'error' });
					GLOBAL.subCategoryErrorColor = "";
				} else {
					this.setState({ subCategory: '' });
					GLOBAL.subCategoryErrorColor = "";
				}
				break;
			// case 'is_external':
			// 	if (event.target.value == 0 || event.target.value == "") {
			// 		this.setState({ is_external: 'error' });
			// 		GLOBAL.is_externalErrorColor = "";
			// 	} else {
			// 		this.setState({ is_external: '' });
			// 		GLOBAL.is_externalErrorColor = "";
			// 	}
			// 	break;
			// case 'supcompany':
			// 	if (event.target.value == 0 || event.target.value == "") {
			// 		this.setState({ cisupcompany: 'error' });
			// 		GLOBAL.cisupcompanyErrorColor = "";
			// 	} else {
			// 		this.setState({ cisupcompany: '' });
			// 		GLOBAL.cisupcompanyErrorColor = "";
			// 	}
			// 	break;
			// case 'manufacturer':
			// 	if (event.target.value == 0 || event.target.value == "") {
			// 		this.setState({ cimanufacturer: 'error' });
			// 		GLOBAL.cimanufacturerErrorColor = "";
			// 	} else {
			// 		this.setState({ cimanufacturer: '' });
			// 		GLOBAL.cimanufacturerErrorColor = "";
			// 	}
			// 	break;
			// case 'businessowner':
			// 	if (event.target.value == 0 || event.target.value == "") {
			// 		this.setState({ cibusinessowner: 'error' });
			// 		GLOBAL.cibusinessownerErrorColor = "";
			// 	} else {
			// 		this.setState({ cibusinessowner: '' });
			// 		GLOBAL.cibusinessownerErrorColor = "";
			// 	}
			// 	break;
			// case 'techowner':
			// 	if (event.target.value == 0 || event.target.value == "") {
			// 		this.setState({ citechowner: 'error' });
			// 		GLOBAL.citechownerErrorColor = "";
			// 	} else {
			// 		this.setState({ citechowner: '' });
			// 		GLOBAL.citechownerErrorColor = "";
			// 	}
			// 	break;
			// case 'group':
			// 	if (event.target.value == 0 || event.target.value == "") {
			// 		this.setState({ cigroup: 'error' });
			// 		GLOBAL.cigroupErrorColor = "";
			// 	} else {
			// 		this.setState({ cigroup: '' });
			// 		GLOBAL.cigroupErrorColor = "";
			// 	}
			// 	break;
			default:

		}
	}

	setErrorColor(errorVal,field) {
		switch (field) {
			case 'businessowner':
				this.setState({ cibusinessowner: errorVal });
				GLOBAL.cibusinessownerErrorColor = "";
				break;
			case 'techowner':
				this.setState({ citechowner: errorVal });
				GLOBAL.citechownerErrorColor = "";
				break;
		}
	}

	renderClass(classData) {
		//console.log(classData)
		if (!classData) {
			return (
				<option></option>
			);
		}
		return classData.map((classObj, i) => {
			// if (classObj.CLASS_NAME != "Service")
			return (
				<option value={classObj.CLASS_ID} key={i}>{classObj.CLASS_NAME}</option>
			);
		});
	}
	renderType(typeData) {

		if (!typeData) {console.log("HIIIIIIIII");
			return (
				<option></option>
			);
		}
		return typeData.map((typeObj) => {
			return (
				<option value={typeObj.FIELD1_VALUE}>{typeObj.FIELD1_KEY}</option>
				);
		});
	}

	renderStatus(statusData) {
		//console.log(classData)
		if (!statusData) {
			return (
				<option></option>
			);
		}
		return statusData.map((statusObj) => {
			if (this.props.initialValues.status == statusObj.FIELD1_VALUE){
        		GLOBAL.cmdbCreateSelectedText['status_name']= statusObj.FIELD1_KEY;
			}
			return (
				<option value={statusObj.FIELD1_VALUE}>{statusObj.FIELD1_KEY}</option>
			);
		});
	}
	renderMetallic(metallicData) {
		//console.log(classData)
		if (!metallicData) {
			return (
				<option></option>
			);
		}
		return metallicData.map((metallicObj) => {
			return (
				<option value={metallicObj.FIELD1_VALUE}>{metallicObj.FIELD1_KEY}</option>
			);
		});
	}

	renderEnv(envData) {
		// console.log(envData)
		if (!envData) {
			return (
				<option></option>
			);
		}
		return envData.map((envObj) => {

			return (
				<option value={envObj.FIELD1_VALUE}>{envObj.FIELD1_KEY}</option>
			);
		});
	}

	// renderManr(manrData) {
	// 	console.log(manrData);
	// 	if (!manrData) {
	// 		return (
	// 			<option></option>
	// 		);
	// 	}
	// 	return manrData.map((manrObj) => {

	// 		return (
	// 			<option value={manrObj.FIELD1_VALUE}>{manrObj.FIELD1_KEY}</option>
	// 		);
	// 	});
	// }

	renderDataset(datasetData) {
		console.log(datasetData)
		if (!datasetData) {
			return (
				<option></option>
			);
		}
		return datasetData.map((datasetObj) => {

			return (
				<option value={datasetObj.FIELD1_VALUE}>{datasetObj.FIELD1_KEY}</option>
			);
		});
	}
	renderCompany(companyData) {
		//console.log(companyData)
		if (!companyData) {
			return (
				<option></option>
			);
		}
		return companyData.map((companyObj) => {

			return (
				<option value={companyObj.field1Value}>{companyObj.field1Key}</option>
			);
		});
	}

	renderSupCompany(companyData) {
		//console.log(companyData)
		if (!companyData) {
			return (
				<option></option>
			);
		}
		return companyData.map((companyObj) => {
			return (
				<option value={companyObj.field1Value}>{companyObj.field1Key}</option>
			);
		});
	}

	// renderLocation(locationData) {
	// 	//console.log(companyData)
	// 	if (!locationData) {
	// 		return (
	// 			<option></option>
	// 		);
	// 	}
	// 	return locationData.map((locationObj) => {
	// 		return (
	// 			locationObj.LOCATION_NAME != null || locationObj.LOCATION_NAME != undefined ?
	// 				<option value={locationObj.LOCATION_ID}>
	// 					{locationObj.LOCATION_NAME}
	// 				</option> :
	// 				""
	// 		);
	// 	});
	// }

	renderGroup(groupData) {
		//console.log(companyData)
		if (!groupData) {
			return (
				<option></option>
			);
		}
		return groupData.map((groupObj) => {

			return (
				<option value={groupObj.field1Value}>{groupObj.field1Key}</option>
			);
		});
	}

	// renderInd(indData) {
	// 	//console.log(companyData)
	// 	if (!indData) {
	// 		return (
	// 			<option></option>
	// 		);
	// 	}
	// 	return indData.map((indObj) => {

	// 		return (
	// 			<option value={indObj.field1Value}>{indObj.field1Key}</option>
	// 		);
	// 	});
	// }

	renderCat(catData) {
		//console.log(companyData)
		if (!catData) {
			return (
				<option></option>
			);
		}
		return catData.map((catObj) => {
			return (
				<option data-companyid={catObj.cat_company_id} value={catObj.category_name}>{catObj.category_name}</option>
			);
		});
	}

	renderSCat(scatData) {
		//console.log(companyData)
		if (!scatData) {
			return (
				<option></option>
			);
		}
		return scatData.map((scatObj) => {
			// return (
			// 	<option value={scatObj.category_id}>{scatObj.sub_category_name}</option>
			// );
			return (
				<option value={scatObj.sub_category_name}>{scatObj.sub_category_name}</option>
			);
		});
	}

	// setGroup(i) {
	// 	//alert(i.target[i.target.selectedIndex].text)
	// 	this.props.loadCIGroup(i.target.value)
	// 	GLOBAL.cisupportcompanyname = i.target[i.target.selectedIndex].text;
	// }

	// setInd(i) {
	// 	this.props.loadCIInd(i.target.value)
	// 	this.props.loadCISupCompany(i.target.value)
	// 	//GLOBAL.cigroupname=i.target[i.target.selectedIndex].text;
	// 	GLOBAL.cicompanyname = i.target[i.target.selectedIndex].text;
	// }

	// setGroupName(i) {
	// 	GLOBAL.cigroupname = i.target[i.target.selectedIndex].text;
	// }

	// setIndName(i) {
	// 	//this.props.loadCIInd(i.target.value)
	// 	GLOBAL.ciindividualname = i.target[i.target.selectedIndex].text;
	// }

	// setTechIndName(i) {
	// 	//this.props.loadCIInd(i.target.value)
	// 	GLOBAL.citechindividualname = i.target[i.target.selectedIndex].text;
	// }

	// setLocationName(i) {
	// 	//this.props.loadCIInd(i.target.value)
	// 	GLOBAL.cilocationname = i.target[i.target.selectedIndex].text;
	// }

	setCat(i) {
		console.log("selectedIndex",i.target[i.target.selectedIndex].text)
		this.props.emptyCICatReducer();
		this.props.emptyCISCatReducer();
		let filter = 
		this.props.compClass.filter(d => 
  
			d.CLASS_ID == i.target.value
		)
		let type = filter[0].CLASS_TYPE; 
		let classId= filter[0].CLASS_ID; 
		if (type.toLowerCase() == 'asset') {
			this.props.dispatch(change("CmdbCreateForm", "citype", "5"));
			GLOBAL.cmdbCreateSelectedText['type_name'] = 'Asset';
		} else if (type.toLowerCase() == 'ci') {
			this.props.dispatch(change("CmdbCreateForm", "citype", "10"));
			GLOBAL.cmdbCreateSelectedText['type_name'] = 'CI';
		} else if (type.toLowerCase() == 'both') {
			this.props.dispatch(change("CmdbCreateForm", "citype", "15"));
			GLOBAL.cmdbCreateSelectedText['type_name'] = 'Both';
		}
		if (i.target[i.target.selectedIndex].text == "Mobile Devices" || i.target[i.target.selectedIndex].text == "EUC" || i.target[i.target.selectedIndex].text == "Network") {
			//this.props.dispatch(change("CmdbCreateForm", "citype", "5"));
			this.props.dispatch(change("CmdbCreateForm", "status", "1"));
			this.props.dispatch(change("CmdbCreateForm", "subStatus", "1"));
			//GLOBAL.cmdbCreateSelectedText['type_name'] = 'Asset';
			GLOBAL.cmdbCreateSelectedText['status_name'] = 'Requested';
			GLOBAL.cmdbCreateSelectedText['sub_status_name'] = 'Planned';
		} else if (i.target[i.target.selectedIndex].text == "Base Class" || i.target[i.target.selectedIndex].text == "Server" || i.target[i.target.selectedIndex].text == "Service") {
			//this.props.dispatch(change("CmdbCreateForm", "citype", "15"));
			this.props.dispatch(change("CmdbCreateForm", "status", "1"));
			this.props.dispatch(change("CmdbCreateForm", "subStatus", "1"));
			// GLOBAL.cmdbCreateSelectedText['type_name'] = 'Both';
			GLOBAL.cmdbCreateSelectedText['status_name'] = 'Requested';
			GLOBAL.cmdbCreateSelectedText['sub_status_name'] = 'Planned';
		} else {
			//this.props.dispatch(change("CmdbCreateForm", "citype", "10"));
			this.props.dispatch(change("CmdbCreateForm", "status", "6"));
			this.props.dispatch(change("CmdbCreateForm", "subStatus", "11"));
			// GLOBAL.cmdbCreateSelectedText['type_name'] = 'CI';
			GLOBAL.cmdbCreateSelectedText['status_name'] = 'Requested';
			GLOBAL.cmdbCreateSelectedText['sub_status_name'] = 'Planned';
		}
		if (classId && classId != '') {
			let companyId = this.state.createDataObj?.company[0].id || '';
			// if (this.props.companyIdParam) {
			// 	this.props.loadCICat(classId, this.props.companyIdParam)
			// }
			this.props.loadCICat(classId, companyId)
		} 
		this.props.dispatch(change('CmdbCreateForm','category',''));
		this.props.dispatch(change('CmdbCreateForm','subCategory',''));
        GLOBAL.cmdbCreateSelectedText['class_name']= i.target[i.target.selectedIndex].text;

		// if(this.props.citypeParam == "10") {
		// 	GLOBAL.cmdbCreateSelectedText['status_name']= "build";
		// }
	}

	setType(i) {
		//alert(i.target[i.target.selectedIndex].text)
		if (i.target[i.target.selectedIndex].text == "Asset" || i.target[i.target.selectedIndex].text=="Both" ) { this.props.dispatch(change("CmdbCreateForm", "status", "1")); this.props.dispatch(change("CmdbCreateForm", "subStatus", "1")) }
		else if (i.target[i.target.selectedIndex].text == "CI"  ) { this.props.dispatch(change("CmdbCreateForm", "status", "6")); this.props.dispatch(change("CmdbCreateForm", "subStatus", "11")) }
		this.props.loadCIType();

		let tempTypeNameObj = {'type_name': i.target[i.target.selectedIndex].text}
		GLOBAL.cmdbCreateSelectedText['type_name']= i.target[i.target.selectedIndex].text;
	}
	setMetallic(i) {
		GLOBAL.cmdbCreateSelectedText['metallic_name']= i.target[i.target.selectedIndex].text;
	}

	setSCat(i) {
		// console.log(i.target);
		// console.log(i.target[i.target.selectedIndex].dataset.companyid);
		this.props.emptyCISCatReducer();
	if(	i.target.value && i.target.value !=''){
		let ciClassId = this.state.createDataObj?.ciclass || '';
		// this.props.loadCISCat(i.target.value,this.props.classIdParam,i.target[i.target.selectedIndex].dataset.companyid)
		this.props.loadCISCat(i.target.value,ciClassId,i.target[i.target.selectedIndex].dataset.companyid)
	};
		this.props.dispatch(change('CmdbCreateForm','subCategory',''));
		
		let tempCatNameObj = {'category_name': i.target[i.target.selectedIndex].text}
		GLOBAL.cmdbCreateSelectedText['category_name']= i.target[i.target.selectedIndex].text;
	}
	setIsExternal(i) {
		// console.log(i.target.value);
		// console.log(i.target[i.target.selectedIndex].dataset.companyid);
		//this.props.emptyCISCatReducer();
		this.props.dispatch(change('CmdbCreateForm','is_external',i.target.value));
		this.setState({'is_external': i.target.value.toLowerCase()});
		
		
	}

	onCompanySelection(selectedCompany) {
		try{
			this.setState({ cicompanyErrorColor: '', ciCompanySelectedList: selectedCompany });
			this.props.dispatch(change('CmdbCreateForm','supcompany',''));
			this.props.dispatch(change("CmdbCreateForm","supcompanyName",''));
			this.props.dispatch(change('CmdbCreateForm','group',''));
			this.props.dispatch(change("CmdbCreateForm","groupName",''));
			this.props.dispatch(change('CmdbCreateForm','category',''));
			this.props.dispatch(change('CmdbCreateForm','subCategory',''));
			this.setState({ inputValueSupportCompany: '', ciSupCompanySelectedList: [] });
			this.setState({ inputValueSupportGroup: '', ciSupGroupSelectedList: [] });
			this.props.emptyCISupCompany();
			this.props.emptyCIGroup();
			this.props.emptyCICatReducer();
			this.props.emptyCISCatReducer();

			if(selectedCompany.length > 0) {
				// this.props.loadCIInd(selectedCompany[0].id);
				this.props.loadCISupCompany(selectedCompany[0].id);
				let status = 'ACTIVE';
		let hamCall = false;
				this.props.loadClassByCompanyId(selectedCompany[0].id,hamCall,status);
				if(this.props.classIdParam) 
					this.props.loadCICat(this.props.classIdParam,selectedCompany[0].id,'CMDB');
				// this.props.dispatch(change('CmdbCreateForm','company',selectedCompany[0].id));
				// this.props.dispatch(change("CmdbCreateForm","companyName",selectedCompany[0].label));
				this.setState({ inputValueCompany: selectedCompany[0].label });
				// this.setCreateDataObj({data: selectedCompany, fieldType: 'company', isMandatory: true});
			}
			else {
				this.props.dispatch(change('CmdbCreateForm','company',''));
				this.props.dispatch(change("CmdbCreateForm","companyName",''));
				// this.setState({ inputValueCompany: '' });
				// this.setCreateDataObj({data: null, fieldType: 'company', isMandatory: true}); 
			}
		}catch(e){
			console.error('error: ', e);
		}
		
	}
	
	onCompanyInputChange(val) {
		this.setState({ inputValueCompany: val });
		// this.setCreateDataObj({data: null, fieldType: 'company', isMandatory: true});
		// this.setCreateDataObj({data: val, fieldSubType: 'company-typed', isMandatory: true}); 
	}

	onCrossClickCompany() {
		this.props.dispatch(change('CmdbCreateForm','company',''));
		this.props.dispatch(change("CmdbCreateForm","companyName",''));
		this.setState({ inputValueCompany: '', ciCompanySelectedList: [] });
		this.props.dispatch(change('CmdbCreateForm','supcompany',''));
		this.props.dispatch(change("CmdbCreateForm","supcompanyName",''));
		this.props.dispatch(change('CmdbCreateForm','group',''));
		this.props.dispatch(change("CmdbCreateForm","groupName",''));
		this.setState({ inputValueSupportCompany: '', ciSupCompanySelectedList: [] });
		this.setState({ inputValueSupportGroup: '', ciSupGroupSelectedList: [] });
		this.props.emptyCISupCompany();
		this.props.emptyCIGroup();
		this.props.emptyCICatReducer();
		this.props.emptyCISCatReducer();
		this.props.dispatch(change('CmdbCreateForm','ciclass',''));
		this.props.resetCiClass();
		this.setState({ cicompanyErrorColor: 'error' });
		// this.setCreateDataObj({data: null, fieldType: 'company', isMandatory: true});
		// this.setCreateDataObj({data: null, fieldSubType: 'company-typed', isMandatory: true});
	}
	
	setCompanyErrorColor() {
		if (this.state.ciCompanySelectedList.length === 0)
			this.setState({ cicompanyErrorColor: 'error' });
		else
			this.setState({ cicompanyErrorColor: '' });

		GLOBAL.cicompanyErrorColor = "";
	}
	
	onSupCompanySelection(selectedCompany) {
		this.setState({ ciSupCompanySelectedList: selectedCompany });
		this.props.dispatch(change('CmdbCreateForm','group',''));
		this.props.dispatch(change("CmdbCreateForm","groupName",''));
		this.setState({ inputValueSupportGroup: '', ciSupGroupSelectedList: [] });
		this.props.emptyCIGroup();
		
		if(selectedCompany.length > 0) {
			this.props.loadCIGroup(selectedCompany[0].id);
			this.props.dispatch(change('CmdbCreateForm','supcompany',selectedCompany[0].id));
			this.props.dispatch(change("CmdbCreateForm","supcompanyName",selectedCompany[0].label));
			this.setState({ inputValueSupportCompany: selectedCompany[0].label });
			// this.setCreateDataObj({data: selectedCompany, fieldType: 'supportCompany'});
		}
		else {
			this.props.dispatch(change('CmdbCreateForm','supcompany',''));
			this.props.dispatch(change("CmdbCreateForm","supcompanyName",''));
			// this.setCreateDataObj({data: null, fieldType: 'supportCompany'});
			// this.setState({ inputValueSupportCompany: '' });
		}
	}

	onSupportCompanyInputChange(val) {
		this.setState({ inputValueSupportCompany: val });
		// this.setCreateDataObj({data: null, fieldType: 'supportCompany'});
	}

	onCrossClickSupportCompany() {
		this.props.dispatch(change('CmdbCreateForm','supcompany',''));
		this.props.dispatch(change("CmdbCreateForm","supcompanyName",''));
		this.setState({ inputValueSupportCompany: '', ciSupCompanySelectedList: [] });
		this.props.dispatch(change('CmdbCreateForm','group',''));
		this.props.dispatch(change("CmdbCreateForm","groupName",''));
		this.setState({ inputValueSupportGroup: '', ciSupGroupSelectedList: [] });
		this.props.emptyCIGroup();
		this.setState({ cisupcompanyErrorColor: 'error' });
		this.setState({ cigroupErrorColor: 'error' });
		// this.setCreateDataObj({data: null, fieldType: 'supportCompany'});
	}

	setSupCompanyErrorColor() {
		if (this.state.ciSupCompanySelectedList && this.state.ciSupCompanySelectedList.length === 0)
			this.setState({ cisupcompanyErrorColor: 'error' });
		else
			this.setState({ cisupcompanyErrorColor: '' });

		GLOBAL.cisupcompanyErrorColor = "";
	}
	
	onSupGroupSelection(selectedGroup) {
		this.setState({ ciSupGroupSelectedList: selectedGroup });
		
		if(selectedGroup.length > 0) {
			this.props.dispatch(change('CmdbCreateForm','group',selectedGroup[0].id));
			this.props.dispatch(change("CmdbCreateForm","groupName",selectedGroup[0].label));
			this.setState({ inputValueSupportGroup: selectedGroup[0].label });
			// this.setCreateDataObj({data: selectedGroup, fieldType: 'supportGroup'});
		}
		else {
			this.props.dispatch(change('CmdbCreateForm','group',''));
			this.props.dispatch(change("CmdbCreateForm","groupName",''));
			// this.setCreateDataObj({data: null, fieldType: 'supportGroup'});
			// this.setState({ inputValueSupportGroup: '' });
		}
	}

	onSupportGroupInputChange(val) {
		this.setState({ inputValueSupportGroup: val });
		// this.setCreateDataObj({data: null, fieldType: 'supportGroup'});
	}

	onCrossClickSupportGroup() {
		this.props.dispatch(change('CmdbCreateForm','group',''));
		this.props.dispatch(change("CmdbCreateForm","groupName",''));
		this.setState({ inputValueSupportGroup: '', ciSupGroupSelectedList: [] });
		this.setState({ cigroupErrorColor: 'error' });
		// this.setCreateDataObj({data: null, fieldType: 'supportGroup'});
	}

	componentDidMount(){
		this.props.dispatch(change('CmdbCreateForm','is_external',this.state.is_external));

		this.getMenuListData({fieldType: 'currency'});
		this.getMenuListData({fieldType: 'chargeCategory'});
		this.getMenuListData({fieldType: 'chargeFrequency'});
		this.getMenuListData({fieldType: 'chargeType'});
		this.getMenuListData({fieldType: 'drTier'});
		this.getMenuListData({fieldType: 'ownershipType'});
		this.getMenuListData({fieldType: 'depreciationScheduled'});

		this.props.dispatch(change('CmdbCreateForm', 'createDataObj', {}));
	}

	setCreateDataObj({event, data = null, fieldType = '', fieldSubType = '', isMandatory = false}){
		try{
			if(fieldType){
				let obj = {};
				let error = {};
				switch(fieldType){
					case 'ciclass':
					case 'citype':
					case 'category':
					case 'subCategory':
					case 'status':
					case 'subStatus':
					case 'businessCriticality':
					case 'environment':
					case 'ownershipType':
					case 'depreciationScheduled':
					case 'chargeCategory':
					case 'chargeFrequency':
					case 'currency':
					case 'chargeType':
					case 'drEnabled':
					case 'drTier':
					case 'patchHold':
						let val = event?.currentTarget?.defaultValue || event?.target?.value || '';
						let name = event?.target[event?.target?.selectedIndex]?.text || '';
						// console.log('&&&&&&&&&&&&&', event, value, fieldType, data, isMandatory);
						if(val){
							obj = {...this.state.createDataObj, ...{[fieldType]: val, [`${fieldType}_Name`]: name}};
							if(isMandatory){
								error = {...this.state.errorObj, ...{[fieldType]: ''}};
							}
						}
						else{
							obj = {...this.state.createDataObj, ...{[fieldType]: ''}};
							if(isMandatory){
								error = {...this.state.errorObj, ...{[fieldType]: 'error'}};
							}
						}
					break;
					case 'manufacturer':
					case 'modelName':
					case 'modelNumber':
					case 'ciname':
					case 'assettag':
					case 'serial_number':
					case 'description':
					case 'complianceRequirement':
					case 'keywords':
					case 'createdBy':
					case 'updatedBy':
					case 'dataset':
					case 'availabilityPercentage':
					case 'acquisitionCost':
					case 'poNumber':
					case 'invoiceNumber':
					case 'costCurrency':
					case 'glAccount':
					case 'netBookValue':
					case 'depreciationCharged':
					case 'chargeCurrencyAmount':
					case 'hostName':
					case 'ipAddress':
					case 'macAddress':
					case 'backupRetention':
					case 'backupScheduled':
					case 'monitor':
					case 'fqdn':
					case 'allocatedCapacity':
					case 'maximumCapacity':
					case 'capacityUnit':
					case 'capacityUtilization':
					case 'capacityThresholds':
					case 'recoveryPointObjective':
					case 'recoveryTimeObjective':
					case 'discoverySource':
					case 'patchHoldReason':
					case 'patchingSchedule':
					case 'releasePatchingLevel':
						// let value = event?.currentTarget?.defaultValue || event?.target?.value || '';
						let value = event?.target?.value || '';
						// console.log('&&&&&&&&&&&&&', event, value, fieldType, data, isMandatory);
						if(value){
							obj = {...this.state.createDataObj, ...{[fieldType]: value}};
							if(isMandatory){
								error = {...this.state.errorObj, ...{[fieldType]: ''}};
							}
						}
						else{
							obj = {...this.state.createDataObj, ...{[fieldType]: ''}};
							if(isMandatory){
								error = {...this.state.errorObj, ...{[fieldType]: 'error'}};
							}
						}
					break;
					case 'company':
					case 'location':
					case 'warrantyExpiration':
					case 'createdDate':
					case 'updatedDate':
					case 'isExternal':
					case 'deploymentDate':
					case 'decommissionDate':
					case 'endOfSupport':
					case 'firstDeployedDate':
					case 'installedDate':
					case 'supportGroup':
					case 'supportCompany':
					case 'assignedDate':
					case 'department':
					case 'changeGroup':
					case 'ownedBy':
					case 'costCenter':
					case 'vendor':
					case 'chargeToCC':
					case 'chargeToCompany':
					case 'chargeToProject':
					case 'vendorLessor':
					case 'drLocation':
					case 'orderedDate':
					case 'orderReceived':
					case 'poDate':
					case 'chargeStartDate':
					case 'chargeEndDate':
					case 'actualReturnDate':
					case 'scheduledReturnDate':
					case 'firstDiscovered':
					case 'mostRecentDiscovery':
					case 'allocationDate':
					case 'businessOwner':
					case 'technicalOwner':
						// console.log('2222222222 &&&&&&&&&&&&&', fieldType, data, isMandatory);
						if(data){
							obj = {...this.state.createDataObj, ...{[fieldType]: data}};
							if(isMandatory){
								error = {...this.state.errorObj, ...{[fieldType]: ''}};
							}
						}
						else{
							if(fieldType == 'isExternal'){
								obj = {...this.state.createDataObj, ...{ [fieldType]: false }};
							}
							else{
								obj = {...this.state.createDataObj, ...{ [fieldType]: null }};
							}
							// obj = {...this.state.createDataObj, ...{ [fieldType]: null }};
							if(isMandatory){
								error = {...this.state.errorObj, ...{[fieldType]: 'error'}};
							}
						}
					break;
					default:
						obj = {...this.state.createDataObj};
						break;
				}
				this.setState({ createDataObj: obj });
				if(isMandatory){
					this.setState({ errorObj: error });
				}

				this.props.dispatch(change('CmdbCreateForm', 'createDataObj', obj));
			}
			if(fieldSubType){
				switch(fieldSubType){
					case 'company-typed':
					case 'department-typed':
					case 'location-typed':
					case 'changeGroup-typed':
					case 'ownedBy-typed':
					case 'costCenter-typed':
					case 'vendor-typed':
					case 'chargeToCC-typed':
					case 'chargeToCompany-typed':
					case 'chargeToProject-typed':
					case 'vendorLessor-typed':
					case 'drLocation-typed':
						let fldType = fieldSubType.split('-')[0];
						let typedObj = {...this.state.typeaheadTypedObj, ...{[fldType]: (data || '')}};
						this.setState({ typeaheadTypedObj: typedObj });
						break;
					default:
						break;
				}
			}
			
			
		}catch(e){
			console.error(e);
		}
	}

	getLocationData({companyId = '', locationToggle = '', type = 'location'}){
		try{
			console.log('location type - ', type);
			let url = '';
			if(type == 'location'){
				this.setState({ locationDropdownOptions: [] });
				url = `/api/getLocationData?${locationToggle == 'stockroom' ? 'locationType=Stockroom&' : ''}companyId=${companyId}`;
			}
			else{
				this.setState({ drLocationDropdownOptions: [] });
				url = `/api/getLocationData?companyId=${companyId}`;
			}
			axios.get(url).then(res => {
				console.log('getLocationData res - ', res);
				if(res && res.data && res.data.length > 0){
					let result = [];
					res.data.forEach(item => {
						let tempObj = {id: item['id'], label: item['location_name']};
						result.push(tempObj);
					});
					console.log('result - ', result);
					if(type == 'location'){
						this.setState({ locationDropdownOptions: result });
					}
					else{
						this.setState({ drLocationDropdownOptions: result });
					}
				}
			}).catch(err => {
				// console.log('getLocationData err - ', err);
			});
		}catch(e){
			console.error(`Error in getLocation Data(${type}):`, e);
		}
	}

	getSupportCompany(companyId = ''){
		try{
			this.setState({ supportCompanyDropdownOptions: [] });
			let url = `/asset-config/api/getCiSupportCompany/id/${companyId}`;
			axios.get(url).then(res => {
				console.log('getSupportCompany res - ', res);
				if(res && res.data && res.data.length > 0){
					let result = [];
					res.data.forEach(item => {
						let tempObj = {id: item['field1Value'], label: item['field1Key']};
						result.push(tempObj);
					});
					this.setState({ supportCompanyDropdownOptions: result });
				}
			}).catch(err => {
				console.log('getSupportCompany err - ', err);
			});
		}catch(e){

		}
	}

	getSupportGroup(supportCompanyId = ''){
		try{
			this.setState({ supportGroupDropdownOptions: [] });
			let url = `/asset-config/api/getCiSupportGroup/id/${supportCompanyId}`;
			axios.get(url).then(res => {
				console.log('getSupportGroup res - ', res);
				if(res && res.data && res.data.length > 0){
					let result = [];
					res.data.forEach(item => {
						let tempObj = {id: item['field1Value'], label: item['field1Key']};
						result.push(tempObj);
					});
					this.setState({ supportGroupDropdownOptions: result });
				}
			}).catch(err => {
				console.log('getSupportGroup err - ', err);
			});
		}catch(e){

		}
	}

	getCostCenterOptions(companyId = ''){
		try{
			this.setState({ costCenterDropdownOptions: [] });
			let url = `/service/api/approvalRule/listDataWithType?type=Cost Center&companyId=${companyId}`;
			url = encodeURI(url);
			axios.get(url).then(res => {
				// console.log('getCostCenter res - ', res);
				if(res && res.data && res.data.length > 0){
					let result = [];
					res.data.forEach(item => {
						let tempObj = {id: item['costCenterName'], label: item['costCenterName']};
						result.push(tempObj);
					});
					this.setState({ costCenterDropdownOptions: result });
				}
			}).catch(err => {
				// console.log('getCostCenter err - ', err);
			});
		}catch(e){

		}
	}

	getVendor(companyId = ''){
		try{
			this.setState({ vendorDropdownOptions: [] });
			let url = `/asset-config/api/getCiSupportCompany/id/${companyId}`;
			axios.get(url).then(res => {
				console.log('getVendor res - ', res);
				if(res && res.data && res.data.length > 0){
					let result = [];
					res.data.forEach(item => {
						let tempObj = {id: item['field1Value'], label: item['field1Key']};
						result.push(tempObj);
					});
					this.setState({ vendorDropdownOptions: result });
				}
			}).catch(err => {
				console.log('getVendor err - ', err);
			});
		}catch(e){

		}
	}

	getVendorLessor(companyId = ''){
		try{
			this.setState({ vendorLessorDropdownOptions: [] });
			let url = `/asset-config/api/getCiSupportCompany/id/${companyId}`;
			axios.get(url).then(res => {
				console.log('getVendorLessor res - ', res);
				if(res && res.data && res.data.length > 0){
					let result = [];
					res.data.forEach(item => {
						let tempObj = {id: item['field1Value'], label: item['field1Key']};
						result.push(tempObj);
					});
					this.setState({ vendorLessorDropdownOptions: result });
				}
			}).catch(err => {
				console.log('getVendorLessor err - ', err);
			});
		}catch(e){

		}
	}

	getMenuListData({fieldType = ''}){
		try{
			let url = '/api/menulistData';
			let queryParams = {
				'field1_name': '',
				'module': 'CI'
			};
			if(fieldType == 'currency'){
				queryParams['field1_name'] = 'currency';
				this.setState({currencyDropdownOptions: []});
			}
			else if(fieldType == 'chargeCategory'){
				queryParams['field1_name'] = 'chargeCategory';
				this.setState({chargeCategoryDropdownOptions: []});
			}
			else if(fieldType == 'chargeFrequency'){
				queryParams['field1_name'] = 'chargeFrequency';
				this.setState({chargeFrequencyDropdownOptions: []});
			}
			else if(fieldType == 'chargeType'){
				queryParams['field1_name'] = 'chargeType';
				this.setState({chargeTypeDropdownOptions: []});
			}
			else if(fieldType == 'drTier'){
				queryParams['field1_name'] = 'disasterRecoveryTier';
				this.setState({drTierDropdownOptions: []});
			}
			else if(fieldType == 'ownershipType'){
				queryParams['field1_name'] = 'ownershipType';
				this.setState({ownershipTypeDropdownOptions: []});
			}
			else if(fieldType == 'depreciationScheduled'){
				queryParams['field1_name'] = 'depreciationSchedule';
				this.setState({depScheduledDropdownOptions: []});
			}

			function objectToQueryString(obj) {
				const keys = Object.keys(obj);
				const keyValuePairs = keys.map(key => {
				  return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
				});
				return keyValuePairs.join('&');
			  }

			let queryStr = objectToQueryString(queryParams);
			axios.get(url, { headers: { 'query': queryStr } })
			.then(res => {
				console.log('res - ', res);
				if(res && res.data && res.data.length > 0){
					let result = [];
					res.data.forEach(item => {
						let tempObj = {id: item['FIELD1_VALUE'], label: item['FIELD1_KEY']};
						result.push(tempObj);
					});
					if(fieldType == 'currency'){
						this.setState({currencyDropdownOptions: result});
					}
					else if(fieldType == 'chargeCategory'){
						this.setState({chargeCategoryDropdownOptions: result});
					}
					else if(fieldType == 'chargeFrequency'){
						this.setState({chargeFrequencyDropdownOptions: result});
					}
					else if(fieldType == 'chargeType'){
						this.setState({chargeTypeDropdownOptions: result});
					}
					else if(fieldType == 'drTier'){
						this.setState({drTierDropdownOptions: result});
					}
					else if(fieldType == 'ownershipType'){
						this.setState({ownershipTypeDropdownOptions: result});
					}
					else if(fieldType == 'depreciationScheduled'){
						this.setState({depScheduledDropdownOptions: result});
					}
				}
			})
			.catch(err => {
				console.log('err - ', err);
			});
		}catch(e){
			console.error(e);
		}
	}

	getDepartment(companyId){
		try{
			this.setState({ departmentDropdownOptions: [] });
			let url = '/api/getDepartment?companyId=' + companyId;
			axios.get(url)
			.then(res => {
				console.log('res - ', res);
				if(res && res.data && res.data.length > 0){
					let result = [];
					res.data.forEach(item => {
						let tempObj = {id: item['field1Value'], label: item['field1Key']};
						result.push(tempObj);
					});
					this.setState({ departmentDropdownOptions: result });
				}
			})
			.catch(err => {
				console.log('err - ', err);
			});
		}catch(e){
			console.error(e);
		}
	}

	render() {
		let { error, handleSubmit, pristine, reset, submitting, ciname, ciclass, description, company, status, category, subCategory, metallic, group, environment, owner, location, dataset, supcompany, businessowner, techowner } = this.props;

		console.log('**********************', {createDataObj: this.state.createDataObj, errorObj: this.state.errorObj, typeaheadTypedObj: this.state.typeaheadTypedObj, state: this.state, props: this.props});

		let companyTypeaheadOptions = [];
		if(this.props.ciCompany && this.props.ciCompany.length > 0) {
			this.props.ciCompany.forEach((item,i) => {
				let obj = {};
				obj.id = item.field1Value;
				obj.label = item.field1Key;
				companyTypeaheadOptions.push(obj);
			});
		}
		
		let supCompanyTypeaheadOptions = [];
		if(this.props.ciSupCompany && this.props.ciSupCompany.length > 0) {
			this.props.ciSupCompany.forEach((item,i) => {
				let obj = {};
				obj.id = item.field1Value;
				obj.label = item.field1Key;
				supCompanyTypeaheadOptions.push(obj);
			});
		}
		
		let supGroupTypeaheadOptions = [];
		if(this.props.ciGroup && this.props.ciGroup.length > 0) {
			this.props.ciGroup.forEach((item,i) => {
				let obj = {};
				obj.id = item.field1Value;
				obj.label = item.field1Key;
				supGroupTypeaheadOptions.push(obj);
			});
		}

		return (

			<div>
				<font color="red">{error && <strong>{error}</strong>}</font>
				{/*Error message section, please use classes like success, danger, warning, info*/}
				{/*<Alert variant="danger" className="errorMessageSec">
				<p>The form has missing required fields, please revalidate the highlighted fields and update the form.</p>
			</Alert>*/}
				<div className="borderForm bFormDiv">
					<Row>
						<Col lg={4} md={4}  sm={6} xs={12}>
							<Form.Group className="form-group">
								<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Name']}</Form.Label>
								<Field name="ciname" 
								// className={`form-control ${this.state.ciname == '' ? GLOBAL.cinameErrorColor : this.state.ciname}`}
								className={`form-control ${(GLOBAL.ci_name_error) ? 'error' : ''}`}
								component="input" onBlur={(event) => { 
									// this.validateField('ciname', event);
									this.setCreateDataObj({event, fieldType: 'ciname', isMandatory: true}); 
								}} />
							</Form.Group>
						</Col>
						
						<Col lg={4} md={4}  sm={6} xs={12}>
							<Form.Group className="form-group">
								<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Company']}</Form.Label>
								<Field component={CmdbDropdownPlusTypeahead}
									// errorColor={this.state.cicompanyErrorColor === '' ? GLOBAL.cicompanyErrorColor : this.state.cicompanyErrorColor}
									errorColor={(GLOBAL.ci_company_error) ? 'error' : ''}
									// onSelection={this.onCompanySelection}
									onSelection={(val) => {
										this.setCreateDataObj({data: val, fieldType: 'company', isMandatory: true});
										if(val && val[0] && val[0].id){
											this.onCompanySelection(val);
											this.getLocationData({companyId: val[0].id, locationToggle: this.state.locationToggle});
											this.getLocationData({companyId: val[0].id, type: 'drLocation'});
											this.getSupportCompany(val[0].id);
											this.getCostCenterOptions(val[0].id);
											this.getDepartment(val[0].id);
											// this.getVendor(val[0].id);
											// this.getVendorLessor(val[0].id);
											this.setCreateDataObj({data: val[0].label, fieldSubType: 'company-typed', isMandatory: true}); 
											this.setState({ inputValueCompany: val[0].label });
										}
										
										setTimeout(() => {
											this.setCreateDataObj({data: null, fieldType: 'location'});
											this.setCreateDataObj({data: null, fieldType: 'supportCompany'});
											this.setCreateDataObj({data: null, fieldType: 'supportGroup'});
											this.setCreateDataObj({data: null, fieldType: 'department'});
											this.setState({ supportGroupDropdownOptions: [] });
										}, 500);
									}}
									setErrorColor={this.setCompanyErrorColor}
									name="company"
									id="company"
									className="form-control"
									options={companyTypeaheadOptions}
									// selectedOptions={this.state.ciCompanySelectedList}
									selectedOptions={(this.state.createDataObj?.company) || []}
									// onInputChange={this.onCompanyInputChange}
									onInputChange={(val) => {
										this.onCompanyInputChange(val);
										this.setCreateDataObj({data: null, fieldType: 'company', isMandatory: true});
										this.setCreateDataObj({data: val, fieldSubType: 'company-typed', isMandatory: true}); 
										this.setState({ locationDropdownOptions: [], supportCompanyDropdownOptions: [], supportGroupDropdownOptions: [], costCenterDropdownOptions: [], vendorDropdownOptions: [], vendorLessorDropdownOptions: [], drLocationDropdownOptions: [], departmentDropdownOptions: [] });
									}}
									inputValue={this.state.typeaheadTypedObj?.company || ''}
									// inputValue={this.state.inputValueCompany}
									// onCrossClick={this.onCrossClickCompany}
									onCrossClick={() => {
										this.onCrossClickCompany();
										this.setCreateDataObj({data: null, fieldType: 'company', isMandatory: true});
										this.setCreateDataObj({data: null, fieldSubType: 'company-typed', isMandatory: true});
										this.setState({ locationDropdownOptions: [], supportCompanyDropdownOptions: [], supportGroupDropdownOptions: [], costCenterDropdownOptions: [], vendorDropdownOptions: [], vendorLessorDropdownOptions: [], drLocationDropdownOptions: [], departmentDropdownOptions: [] });
									}}
								/>
							</Form.Group>
						</Col>
						<Col lg={4} md={4}  sm={6} xs={12}>
							<Form.Group className="form-group">
								<Form.Label bsClass=""><span className="rStar"></span> {this.props.translator['Class']}</Form.Label>
								{/* <Field name="ciclass" 
								// className={`form-control ${this.state.ciclass == '' ? GLOBAL.ciclassErrorColor : this.state.ciclass}`}
								className={`form-control ${(this.state.errorObj['ciclass'] || GLOBAL.CMDBErrorObj['ciclass']) ? 'error' : ''}`}
								component="select" onBlur={(event) => { this.validateField('ciclass', event) }} onChange={(event) => { 
									this.setCat(event); 
									this.setCreateDataObj({event, fieldType: 'ciclass', isMandatory: true}); 
								}}>
									<option value="">Select</option>
									{this.renderClass(this.props.compClass)}

								</Field> */}
								<Form.Select componentClass="select"
									name="ciclass" 
									className={`form-control ${(GLOBAL.ci_class_error) ? 'error' : ''}`}
									value={(this.state.createDataObj?.ciclass) || ''}
									onBlur={(event) => { this.validateField('ciclass', event) }}
									onChange={(event) => { 
										this.setCat(event); 
										this.setCreateDataObj({event, fieldType: 'ciclass', isMandatory: true}); 
									}}
								>
									<option value="">Select</option>
									{this.renderClass(this.props.compClass)}
								</Form.Select>
							</Form.Group>
						</Col>
						<Col lg={4} md={4}  sm={6} xs={12}>
							<Form.Group className="form-group">
								<Form.Label bsClass=""><span className="rStar"></span> Type</Form.Label>
								<Form.Select componentClass="select" name="citype" 
								// className={`form-control ${this.state.ciType == '' ? GLOBAL.ciTypeErrorColor : this.state.ciType}`} 
								className={`form-control ${(GLOBAL.ci_type_error) ? 'error' : ''}`} 
								onBlur={(event) => { this.validateField('citype', event) }} onChange={(event) => { 
									this.setType(event); 
									this.setCreateDataObj({event, fieldType: 'citype', isMandatory: true});  
								}} 
								value={(this.state.createDataObj?.citype) || ''}
								>
									<option value="">Select</option>
									{this.renderType(this.props.ciType)}
								</Form.Select>
							</Form.Group>
						</Col>
						<Col lg={4} md={4}  sm={6} xs={12}>
							<Form.Group className="form-group">
								<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Category']}</Form.Label>
								<Form.Select componentClass="select" name="category" 
								// className={` form-control ${this.state.category == '' ? GLOBAL.categoryErrorColor : this.state.category}`} 
								className={` form-control ${(GLOBAL.ci_category_error) ? 'error' : ''}`} 
								onBlur={(event) => { this.validateField('category', event) }}  onChange={(event) => { 
									this.setSCat(event); 
									this.setCreateDataObj({event, fieldType: 'category', isMandatory: true});  
								}}
								value={(this.state.createDataObj?.category) || ''}
								>
									<option value="">Select</option>
									{this.renderCat(this.props.ciCat)}
								</Form.Select>
							</Form.Group>
						</Col>
						<Col lg={4} md={4}  sm={6} xs={12}>
							<Form.Group className="form-group">
								<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Sub Category']}</Form.Label>
								<Form.Select componentClass="select" name="subCategory" 
								// className={`form-control ${this.state.subCategory == '' ? GLOBAL.subCategoryErrorColor : this.state.subCategory}`} 
								className={`form-control ${(GLOBAL.ci_sub_category_error) ? 'error' : ''}`} 
								onBlur={(event) => { this.validateField('subCategory', event) }} 
								onChange={(event) => {
									this.setCreateDataObj({event, fieldType: 'subCategory', isMandatory: true}); 
								}}
								value={(this.state.createDataObj?.subCategory) || ''}
								>
									<option value="">Select</option>
									{this.renderSCat(this.props.ciSCat)}
								</Form.Select>
							</Form.Group>
						</Col>

						<Col lg={4} md={4}  sm={6} xs={12}>
							<Form.Group className="form-group">
								<Form.Label bsClass="">Asset/CI ID</Form.Label>
								<Form.Control type="text"  disabled={true} />
							</Form.Group>
						</Col>
						<Col lg={4} md={4}  sm={6} xs={12}>
							<Form.Group className="form-group">
								<Form.Label bsClass=""> {this.props.translator['Asset Tag']}</Form.Label>
								<Field name="assettag" className="form-control" component="textarea" rows="1" onBlur={(event) => { 
									this.validateField('assettag', event); 
									this.setCreateDataObj({event, fieldType: 'assettag'}); 
								}} 
								value={(this.state.createDataObj?.assettag) || ''}
								/>
							</Form.Group>
						</Col>

						<Col lg={4} md={4}  sm={6} xs={12}>
							<Form.Group className="form-group">
								<Form.Label bsClass=""> {this.props.translator['Serial Number']}</Form.Label>
								<Field name="serial_number"  maxLength="250" 
								// className={`form-control ${this.state.serial_number == '' ? GLOBAL.serialNumberErrorColor : this.state.serial_number}`} 
								className={"form-control"} 
								component="textarea" rows="1" onBlur={(event) => { 
									this.validateField('serial_number', event);
									this.setCreateDataObj({event, fieldType: 'serial_number'});
								}} 
								value={(this.state.createDataObj?.serial_number) || ''}
								></Field>
							</Form.Group>
						</Col>

						<Col lg={4} md={4}  sm={6} xs={12}>
							<Form.Group className="form-group">
								<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Status']}</Form.Label>
								{this.props.citypeParam == '10' ?
									<Form.Select componentClass="select" name="status" 
									// className={`form-control ${this.state.cistatus == '' ? GLOBAL.cistatusErrorColor : this.state.cistatus}`}  
									className={`form-control ${GLOBAL.ci_status_error}`}  
									onBlur={(event) => { this.validateField('status', event) }} onChange={(event) => {
										this.setCreateDataObj({event, fieldType: 'status', isMandatory: true}); 
									}}
									value={(this.state.createDataObj?.status) || ''}
									>
										<option value="">Select</option>
										{/* {this.renderStatus(this.props.ciStatus)} */}
										<option value="6">Build</option>
									</Form.Select>
								:
									this.props.citypeParam == '5' || this.props.citypeParam == '15' ? 
									<Form.Select componentClass="select" name="status" 
									className={`form-control ${GLOBAL.ci_status_error}`}  
									onBlur={(event) => { this.validateField('status', event) }} onChange={(event) => {
										this.setCreateDataObj({event, fieldType: 'status', isMandatory: true}); 
									}}
									value={(this.state.createDataObj?.status) || ''}
									>
										<option value="">Select</option>
										{/* {this.renderStatus(this.props.ciStatus)} */}
										<option value="1">Requested</option>
									</Form.Select>
									:
									<Form.Select componentClass="select" name="status" 
									className={`form-control ${GLOBAL.ci_status_error}`}
									onBlur={(event) => { this.validateField('status', event) }}>
										<option value="">Select</option>
									</Form.Select> 
								}
							</Form.Group>
						</Col>
						
						<Col lg={4} md={4}  sm={6} xs={12}>
							<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Sub Status']}</Form.Label>
							{this.props.citypeParam == '10' ?
								<Form.Select componentClass="select" name="subStatus" 
								// className="form-control" 
								className={`form-control ${GLOBAL.ci_sub_status_error}`}
								onBlur={(event) => { this.validateField('subStatus', event) }}  onChange={(event) => {
									this.setCreateDataObj({event, fieldType: 'subStatus', isMandatory: true}); 
								}}
								value={(this.state.createDataObj?.subStatus) || ''}
								>
									<option value="">Select</option>
									<option value="11">Build</option>
								</Form.Select>
								:
								this.props.citypeParam == '5' || this.props.citypeParam == '15' ?
									<Form.Select componentClass="select" name="subStatus" 
									className={`form-control ${GLOBAL.ci_sub_status_error}`}
									onBlur={(event) => { this.validateField('subStatus', event) }}  onChange={(event) => {
										this.setCreateDataObj({event, fieldType: 'subStatus', isMandatory: true}); 
									}}
									value={(this.state.createDataObj?.subStatus) || ''}
									>
										<option value="">Select</option>
										<option value="1">Planned</option>
									</Form.Select>
									:
									<Form.Select componentClass="select" name="subStatus" 
									className={`form-control ${GLOBAL.ci_sub_status_error}`}
									onBlur={(event) => { this.validateField('subStatus', event) }}>
										<option value="">Select</option>
									</Form.Select>
							}
						</Col>

						<Col lg={4} md={4}  sm={6} xs={12}>
							<Form.Label bsClass=""><span className="rStar"></span>Allocation Date</Form.Label>
							<div style={(GLOBAL.ci_allocation_date_error == 'error') ? {'border': '1px solid red', 'border-radius': '5px'} : {}}>
								<DateTimeField format={dateformat} onChange={(value = '') => {
									console.log('value - ', value);
									this.setCreateDataObj({data: value, fieldType: 'allocationDate'});
								}} 
								value={(this.state.createDataObj?.allocationDate) || ''}
								/>
							</div>
						</Col>
					
						<Col md={12}>
							<Form.Group className="form-group">
								<Form.Label bsClass=""> {this.props.translator['Description']}</Form.Label>
								<Field name="description" className="form-control" component="textarea" rows="1" onBlur={(event) => { 
									this.validateField('description', event);
									this.setCreateDataObj({event, fieldType: 'description'}); 
								}} 
								value={(this.state.createDataObj?.description) || ''}
								/>
							</Form.Group>
						</Col>
						
						<Col lg={4} md={4}  sm={6} xs={12}>
							<Form.Group className="form-group">
								<Form.Label bsClass="">Manufacturer</Form.Label>
								<Form.Control type="text"  onChange={(event) => { this.setCreateDataObj({event, fieldType: 'manufacturer'}); }} 
								value={(this.state.createDataObj?.manufacturer) || ''}
								/>
							</Form.Group>
						</Col>
						<Col lg={4} md={4}  sm={6} xs={12}>
							<Form.Group className="form-group">
								<Form.Label bsClass="">Model Name</Form.Label>
								<Form.Control type="text"  onChange={(event) => { this.setCreateDataObj({event, fieldType: 'modelName'}); }} 
								value={(this.state.createDataObj?.modelName) || ''}
								/>
							</Form.Group>
						</Col>
						<Col lg={4} md={4}  sm={6} xs={12}>
							<Form.Group className="form-group">
								<Form.Label bsClass="">Model Number</Form.Label>
								<Form.Control type="text"  onChange={(event) => { this.setCreateDataObj({event, fieldType: 'modelNumber'}); }} 
								value={(this.state.createDataObj?.modelNumber) || ''}
								/>
								
							</Form.Group>
						</Col>
						
						<Col lg={4} md={4}  sm={6} xs={12}>
							<Form.Group className="form-group">
								<Form.Label bsClass=""> {this.props.translator['Business Criticality']}</Form.Label>
								<Form.Select componentClass="select" name="metallic" className="form-control" onBlur={(event) => { this.validateField('metallic', event) }}  onChange={(event) => { 
									this.setMetallic(event);
									this.setCreateDataObj({event, fieldType: 'businessCriticality'});
								}} 
								value={(this.state.createDataObj?.businessCriticality) || ''}
								>
									<option value="">Select</option>
									{this.renderMetallic(this.props.ciMetallic)}
								</Form.Select>
							</Form.Group>
						</Col>
						<Col lg={4} md={4}  sm={6} xs={12}>
							<Form.Group className="form-group">
								<Form.Label bsClass="">Warranty Expiration</Form.Label>
								<DateTimeField format={dateformat} onChange={(value = '') => {
									this.setCreateDataObj({data: value, fieldType: 'warrantyExpiration'});
								}} 
								value={(this.state.createDataObj?.warrantyExpiration) || ''}
								/>
							</Form.Group>
						</Col>
						<Col lg={4} md={4}  sm={6} xs={12}>
							<Form.Group className="form-group">
								<Form.Label bsClass="">Compliance Requirement</Form.Label>
								<Form.Control type="text"  onChange={(event) => { this.setCreateDataObj({event, fieldType: 'complianceRequirement'}); }} 
								value={(this.state.createDataObj?.complianceRequirement) || ''}
								/>
							</Form.Group>
						</Col>
						
						<Col lg={4} md={4}  sm={6} xs={12}>
							<Form.Group className="form-group">
								<a className="showwAllGrps margin-t-5 float-r" href="javascript:void(0)" onClick={() => {
									let newFlag = (this.state.locationToggle == 'all') ? 'stockroom' : 'all';
									this.setState({locationToggle: newFlag});
									// console.log('createDataObjcreateDataObjcreateDataObj', this.state.createDataObj);
									this.getLocationData({companyId: (this.state?.createDataObj?.company && Array.isArray(this.state?.createDataObj?.company) && this.state?.createDataObj?.company.length > 0 ? this.state.createDataObj.company[0].id : '') ,locationToggle: newFlag});
								}}>{(this.state.locationToggle == 'all') ? 'Show Stockroom Locations' : 'Show All Locations'}</a>
								<Form.Label bsClass=""> {this.props.translator['Location']}</Form.Label>

								<div className="dvTypehd">
									<TypeaheadSelect 
										onSelection={(val) => {
											console.log('val ********', val);
											if(val && val[0] && val[0].id){
												this.setCreateDataObj({data: val, fieldType: 'location'});
											}
											else{
												this.setCreateDataObj({data: null, fieldType: 'location'}); 
											}
										}}
										id='location-field'
										name="location"
										className="form-control"
										// errorClass={groupError}
										options={this.state.supportCompanyDropdownOptions}
										selectedOptions={(this.state.createDataObj?.location) || [] }
										// disabled={false}
										onInputChange={(e) => {
											this.setCreateDataObj({data: null, fieldType: 'location'}); 
											this.setCreateDataObj({data: e,fieldSubType: 'location-typed'});
											}}
											onCrossClick={() => {
											this.setCreateDataObj({data: null, fieldType: 'location'}); 
											this.setCreateDataObj({data: null,fieldSubType: 'location-typed'});
											}}
										tr={this.props.translator}
										typedValue={(this.state.typeaheadTypedObj && 'location' in this.state.typeaheadTypedObj && this.state.typeaheadTypedObj?.location) ? this.state.typeaheadTypedObj?.location : '' }
									/>
									{/* <TypeaheadSelect 
										onSelection={(val) => {
											console.log('val ********', val);
											if(val && val[0] && val[0].id){
												this.setCreateDataObj({data: val, fieldType: 'location'});
											}
											else{
												this.setCreateDataObj({data: null, fieldType: 'location'}); 
											}
										}}
										id='location-field'
										name="location"
										className="form-control"
										// errorClass={(this.state.errorObj?.location || GLOBAL.CMDBErrorObj['location']) ? 'error' : ''}
										options={this.state.locationDropdownOptions || []}
										selectedOptions={(this.state.createDataObj?.location) || [] }
										// disabled={false}
										onInputChange={(e) => {
											this.setCreateDataObj({data: null, fieldType: 'location'}); 
											this.setCreateDataObj({data: e,fieldSubType: 'location-typed'});
											}}
											onCrossClick={() => {
											this.setCreateDataObj({data: null, fieldType: 'location'}); 
											this.setCreateDataObj({data: null,fieldSubType: 'location-typed'});
											}}
										tr={this.props.translator}
										typedValue={(this.state.typeaheadTypedObj?.location) || '' }
									/> */}
								</div>

							</Form.Group>
						</Col>
						<Col lg={4} md={4}  sm={6} xs={12}>
							<Form.Group className="form-group">
								<Form.Label bsClass=""> {this.props.translator['Environment']}</Form.Label>
								<Form.Select componentClass="select" name="environment" className="form-control" onBlur={(event) => { 
									this.validateField('environment', event);
								}} onChange={(event) => { this.setCreateDataObj({event, fieldType: 'environment'}); }} 
								value={(this.state.createDataObj?.environment) || ''}
								>
									<option value="">Select</option>
									{this.renderEnv(this.props.ciEnv)}
								</Form.Select>
							</Form.Group>
						</Col>
						<Col lg={4} md={4}  sm={6} xs={12}>
							<Form.Group className="form-group">
								<Form.Label bsClass="">Keywords</Form.Label>
								<Form.Control type="text"  onChange={(event) => { this.setCreateDataObj({event, fieldType: 'keywords'}); }} 
								value={(this.state.createDataObj?.keywords) || ''}
								/>
							</Form.Group>
						</Col>
					</Row>

					<Accordion className='margin-t-15 brkFix brkFixOrder brkFullAccordion'>
						<Accordion.Item eventKey="0">
							<Accordion.Header>General Info</Accordion.Header>
							<Accordion.Body>
								<Row>
									{/* <Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Created By</Form.Label>
											<Form.Control type="text"  onBlur={(event) => { this.setCreateDataObj({event, fieldType: 'createdBy'}); }} disabled={true} />
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Created Date</Form.Label>
											<DateTimeField format={dateformat} onChange={(value = '') => {
												this.setCreateDataObj({data: value, fieldType: 'createdDate'});
											}} disabled={true} />
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Updated By</Form.Label>
											<Form.Control type="text"  onBlur={(event) => { this.setCreateDataObj({event, fieldType: 'updatedBy'}); }} disabled={true} />
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Updated Date</Form.Label>
											<DateTimeField format={dateformat} onChange={(value = '') => {
												this.setCreateDataObj({data: value, fieldType: 'updatedDate'});
											}} disabled={true} />
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">SX ID</Form.Label>
											<Form.Control type="text"  disabled={true} />
										</Form.Group>
									</Col> */}
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Is External</Form.Label>
											<div className='radioInlineBox margin-t-5'>
												<Field component={_radio} className='form-control' type="radio" name='is_external' value='True' 
												// checked={(this.state.is_external==true || this.state.is_external=='true') ? true : false} 
												checked={(this.state.createDataObj?.isExternal == true) ? true : false}
												onChange={(event) => { 
													// console.log('******', event.target.value, event.target.checked);
													// this.setIsExternal(event);
													this.setCreateDataObj({data: true, fieldType: 'isExternal'});
												}} />
												<Field component={_radio} className='form-control' type="radio" name='is_external' value='False' 
												checked={(!this.state.createDataObj?.isExternal || this.state.createDataObj?.isExternal == false) ? true : false} 
												onChange={(event) => { 
													// console.log('******', event.target.value, event.target.checked);
													// this.setIsExternal(event);
													this.setCreateDataObj({data: false, fieldType: 'isExternal'});
												}} />
											</div>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Deployment Date</Form.Label>
											<DateTimeField format={dateformat} onChange={(value = '') => {
												// console.log('value - ', value);
												this.setCreateDataObj({data: value, fieldType: 'deploymentDate'});
											}} 
											value={(this.state.createDataObj?.deploymentDate) || ''}
											/>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Decommission Date</Form.Label>
											<DateTimeField format={dateformat} onChange={(value = '') => {
												// console.log('value - ', value);
												this.setCreateDataObj({data: value, fieldType: 'decommissionDate'});
											}} 
											value={(this.state.createDataObj?.decommissionDate) || ''}
											/>
										</Form.Group>
									</Col>
									
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">End of Support</Form.Label>
											<DateTimeField format={dateformat} onChange={(value = '') => {
												// console.log('value - ', value);
												this.setCreateDataObj({data: value, fieldType: 'endOfSupport'});
											}} 
											value={(this.state.createDataObj?.endOfSupport) || ''}
											/>
										</Form.Group>
									</Col>
									
									
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">First Deployed Date</Form.Label>
											<DateTimeField format={dateformat} onChange={(value = '') => {
												// console.log('value - ', value);
												this.setCreateDataObj({data: value, fieldType: 'firstDeployedDate'});
											}} 
											value={(this.state.createDataObj?.firstDeployedDate) || ''}
											/>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Installed Date</Form.Label>
											<DateTimeField format={dateformat} onChange={(value = '') => {
												// console.log('value - ', value);
												this.setCreateDataObj({data: value, fieldType: 'installedDate'});
											}} 
											value={(this.state.createDataObj?.installedDate) || ''}
											/>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Dataset</Form.Label>
											<Form.Control type="text"  onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'dataset'});
											}} 
											value={(this.state.createDataObj?.dataset) || ''}
											/>
										</Form.Group>
									</Col>
									
								</Row>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="1">
							<Accordion.Header>Owner/Support Contacts</Accordion.Header>
							<Accordion.Body>
									<div className="margin-b-15">
											<div className="bgGray padding-5 margin-b-5"> {this.props.translator['Owner Contacts']}</div>
											<Row>
											<Col lg={4} md={4}  sm={6} xs={12}>
											<Form.Group className="form-group" >
												<Form.Label bsClass=""><span className="rStar"></span> {this.props.translator['Business Owner']}</Form.Label>
												<BusinessOwner
													id="businessowner"
													// errorColor={this.state.cibusinessowner === '' ? GLOBAL.cibusinessownerErrorColor : this.state.cibusinessowner}
													errorColor={GLOBAL.ci_business_owner_error}
													setErrorColor={this.setErrorColor}
													translator={this.props.translator}
													onUpdateValue={(value) => {
														this.setCreateDataObj({data: value, fieldType: 'businessOwner'});
													}}
													value={(this.state.createDataObj?.businessOwner) || []}
													createDataObj={this.state.createDataObj}
												/>
											</Form.Group >
											</Col>
											<Col lg={4} md={4}  sm={6} xs={12}>
											<Form.Group className="form-group">
												<Form.Label bsClass=""><span className="rStar"></span> {this.props.translator['Technical Owner']}</Form.Label>
												<TechnicalOwner
													id="techowner"
													// errorColor={this.state.citechowner === '' ? GLOBAL.citechownerErrorColor : this.state.citechowner}
													errorColor={GLOBAL.ci_technical_owner_error}
													setErrorColor={this.setErrorColor}
													translator={this.props.translator}
													onUpdateValue={(value) => {
														this.setCreateDataObj({data: value, fieldType: 'technicalOwner'});
													}}
													value={(this.state.createDataObj?.technicalOwner) || []}
													createDataObj={this.state.createDataObj}
												/>
											</Form.Group>
											</Col>
											<Col lg={4} md={4}  sm={6} xs={12}>
											<Form.Group className="drpdwntop form-group">
												<Form.Label bsClass=""> {this.props.translator['Assigned To']}</Form.Label>
												<AssignedTo
													id="assignedto"
													translator={this.props.translator}
													onUpdateValue={(value) => {
														this.setCreateDataObj({data: value, fieldType: 'assignedTo'});
													}}
													value={(this.state.createDataObj?.assignedTo) || []}
													createDataObj={this.state.createDataObj}
												/>
											</Form.Group>
											</Col>
											<Col lg={4} md={4}  sm={6} xs={12}>
											<Form.Group className="form-group">
												<Form.Label bsClass="">Assigned Date</Form.Label>
												<DateTimeField format={dateformat} onChange={(value = '') => {
													// console.log('value - ', value);
													this.setCreateDataObj({data: value, fieldType: 'assignedDate'});
												}} 
												value={(this.state.createDataObj?.assignedDate) || ''}
												/>
											</Form.Group>
											</Col>
											
											<Col lg={4} md={4}  sm={6} xs={12}>
											<Form.Group className="form-group">
												<Form.Label bsClass="">Department</Form.Label>
												<div className="dvTypehd">
													<TypeaheadSelect 
														onSelection={(val) => {
															console.log('val ********', val);
															if(val && val[0] && val[0].id){
																this.setCreateDataObj({data: val, fieldType: 'department'});
															}
															else{
																this.setCreateDataObj({data: null, fieldType: 'department'}); 
															}
														}}
														id='department-field'
														name="department"
														className="form-control"
														// errorClass={(this.state.errorObj?.department || GLOBAL.CMDBErrorObj['department']) ? 'error' : ''}
														options={this.state.departmentDropdownOptions || []}
														selectedOptions={(this.state.createDataObj?.department) || [] }
														// disabled={false}
														onInputChange={(e) => {
															this.setCreateDataObj({data: null, fieldType: 'department'}); 
															this.setCreateDataObj({data: e,fieldSubType: 'department-typed'});
															}}
															onCrossClick={() => {
															this.setCreateDataObj({data: null, fieldType: 'department'}); 
															this.setCreateDataObj({data: null,fieldSubType: 'department-typed'});
															}}
														tr={this.props.translator}
														// typedValue={(this.state.typeaheadTypedObj?.department) || '' }
														typedValue={(this.state.typeaheadTypedObj && 'department' in this.state.typeaheadTypedObj && this.state.typeaheadTypedObj?.department) ? this.state.typeaheadTypedObj?.department : '' }
													/>
												</div>
											</Form.Group>
											</Col>
											<Col lg={4} md={4}  sm={6} xs={12}>
											<Form.Group className="form-group">
												<Form.Label bsClass="">Change Group</Form.Label>
												<div className="dvTypehd">
													<TypeaheadSelect 
														onSelection={(val) => {
															console.log('val ********', val);
															if(val && val[0] && val[0].id){
																this.setCreateDataObj({data: val, fieldType: 'changeGroup'});
															}
															else{
																this.setCreateDataObj({data: null, fieldType: 'changeGroup'}); 
															}
														}}
														id='changeGroup-field'
														name="changeGroup"
														className="form-control"
														// errorClass={groupError}
														options={[]}
														selectedOptions={(this.state.createDataObj?.changeGroup) || [] }
														// disabled={false}
														onInputChange={(e) => {
															this.setCreateDataObj({data: null, fieldType: 'changeGroup'}); 
															this.setCreateDataObj({data: e,fieldSubType: 'changeGroup-typed'});
															}}
															onCrossClick={() => {
															this.setCreateDataObj({data: null, fieldType: 'changeGroup'}); 
															this.setCreateDataObj({data: null,fieldSubType: 'changeGroup-typed'});
															}}
														tr={this.props.translator}
														typedValue={(this.state.typeaheadTypedObj?.changeGroup) || '' }
													/>
												</div>
											</Form.Group>
											</Col>
											<Col lg={4} md={4}  sm={6} xs={12}>
											<Form.Group className="form-group">
												<Form.Label bsClass="">Availability Percentage (Committed)</Form.Label>
												<Form.Control type="text"  onChange={(event) => {
													this.setCreateDataObj({event, fieldType: 'availabilityPercentage'});
												}} 
												value={(this.state.createDataObj?.availabilityPercentage) || ''}
												/>
											</Form.Group>
											</Col>
											</Row>
										</div>

										<div className="">
											<div className="bgGray padding-5 margin-b-5">{this.props.translator['Support Contacts']}</div>

											<Row>
											<Col lg={4} md={4}  sm={6} xs={12}>
											<Form.Group className="drpdwntop form-group">
												<Form.Label bsClass="">{this.props.translator['Support Company']}</Form.Label>
												<Field component={CmdbDropdownPlusTypeahead}
													// errorColor={this.state.cisupcompanyErrorColor === '' ? GLOBAL.cisupcompanyErrorColor : this.state.cisupcompanyErrorColor}
													// onSelection={this.onSupCompanySelection}
													onSelection={(val) => {
														this.onSupCompanySelection(val);
														this.setCreateDataObj({data: val, fieldType: 'supportCompany'});
														if(val && val[0] && val[0].id){
															this.getSupportGroup(val[0].id);
														}
														this.setCreateDataObj({data: null, fieldType: 'supportGroup'});
														this.setState({ supportGroupDropdownOptions: [] });
													}}
													setErrorColor={this.setSupCompanyErrorColor}
													name="supcompany"
													id="supcompany"
													className="form-control"
													// options={supCompanyTypeaheadOptions}
													options={this.state.supportCompanyDropdownOptions || []}
													// selectedOptions={this.state.ciSupCompanySelectedList}
													value={(this.state.createDataObj?.supportCompany) || []}
													// onInputChange={this.onSupportCompanyInputChange}
													onInputChange={(val) => {
														this.onSupportCompanyInputChange(val);
														this.setCreateDataObj({data: null, fieldType: 'supportCompany'});
														this.setState({ supportGroupDropdownOptions: [] });
													}}
													inputValue={this.state.inputValueSupportCompany}
													// onCrossClick={this.onCrossClickSupportCompany}
													onCrossClick={() => {
														this.onCrossClickSupportCompany();
														this.setCreateDataObj({data: null, fieldType: 'supportCompany'});
														this.setState({ supportGroupDropdownOptions: [] });
													}}
												/>
											</Form.Group>
											</Col>
											<Col lg={4} md={4}  sm={6} xs={12}>
											<Form.Group className="drpdwntop form-group">
												<Form.Label bsClass="">{this.props.translator['Support Group']}</Form.Label>
												<Field component={CmdbDropdownPlusTypeahead}
													// errorColor={this.state.cigroupErrorColor === '' ? GLOBAL.cigroupErrorColor : this.state.cigroupErrorColor}
													// onSelection={this.onSupGroupSelection}
													onSelection={(val) => {
														this.onSupGroupSelection(val);
														this.setCreateDataObj({data: val, fieldType: 'supportGroup'});
													}}
													setErrorColor={this.setSupGroupErrorColor}
													name="group"
													id="group"
													// className="form-control"
													// options={supGroupTypeaheadOptions}
													options={this.state.supportGroupDropdownOptions || []}
													// selectedOptions={this.state.ciSupGroupSelectedList}
													value={(this.state.createDataObj?.supportGroup) || []}
													// onInputChange={this.onSupportGroupInputChange}
													onInputChange={(val) => {
														this.onSupportGroupInputChange(val);
														this.setCreateDataObj({data: null, fieldType: 'supportGroup'});
													}}
													inputValue={this.state.inputValueSupportGroup}
													// onCrossClick={this.onCrossClickSupportGroup}
													onCrossClick={() => {
														this.onCrossClickSupportGroup();
														this.setCreateDataObj({data: null, fieldType: 'supportGroup'});
													}}
												/>
											</Form.Group>
											</Col>
											
											</Row>

									</div>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="2">
							<Accordion.Header>Financials</Accordion.Header>
							<Accordion.Body>
								<Row>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Owned By</Form.Label>
											<div className="dvTypehd">
													<TypeaheadSelect 
														onSelection={(val) => {
															console.log('val ********', val);
															if(val && val[0] && val[0].id){
																this.setCreateDataObj({data: val, fieldType: 'ownedBy'});
															}
															else{
																this.setCreateDataObj({data: null, fieldType: 'ownedBy'}); 
															}
														}}
														id='ownerBy-field'
														name="ownerBy"
														className="form-control"
														// errorClass={groupError}
														options={companyTypeaheadOptions}
														selectedOptions={(this.state.createDataObj?.ownedBy) || [] }
														// disabled={false}
														onInputChange={(e) => {
															this.setCreateDataObj({data: null, fieldType: 'ownedBy'}); 
															this.setCreateDataObj({data: e,fieldSubType: 'ownedBy-typed'});
															}}
															onCrossClick={() => {
															this.setCreateDataObj({data: null, fieldType: 'ownedBy'}); 
															this.setCreateDataObj({data: null,fieldSubType: 'ownedBy-typed'});
															}}
														tr={this.props.translator}
														// typedValue={(this.state.typeaheadTypedObj?.ownedBy) || '' }
														typedValue={(this.state.typeaheadTypedObj && 'ownedBy' in this.state.typeaheadTypedObj && this.state.typeaheadTypedObj?.ownedBy) ? this.state.typeaheadTypedObj?.ownedBy : '' }
													/>
												</div>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Ownership Type</Form.Label>
											<Form.Select componentClass="select" name="ownershipType" className={`form-control`} onBlur={(event) => { }} onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'ownershipType'});
											}}
											value={(this.state.createDataObj?.ownershipType) || ''}
											>
												<option value="">Select</option>
												{this.state.ownershipTypeDropdownOptions.map(item => {
													return(<option value={item['id']}>{item['label']}</option>);
												})}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Ordered</Form.Label>
											<DateTimeField format={dateformat} onChange={(value = '') => {
												// console.log('value - ', value);
												this.setCreateDataObj({data: value, fieldType: 'orderedDate'});
											}} 
											value={(this.state.createDataObj?.orderedDate) || ''}
											/>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Ordered Received</Form.Label>
											<DateTimeField format={dateformat} onChange={(value = '') => {
												// console.log('value - ', value);
												this.setCreateDataObj({data: value, fieldType: 'orderReceived'});
											}} 
											value={(this.state.createDataObj?.orderReceived) || ''}
											/>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Acquisition Cost</Form.Label>
											<Form.Control type="text"  onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'acquisitionCost'});
											}} 
											value={(this.state.createDataObj?.acquisitionCost) || ''}
											/>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Cost Center</Form.Label>
											<div className="dvTypehd">
													<TypeaheadSelect 
														onSelection={(val) => {
															console.log('val ********', val);
															if(val && val[0] && val[0].id){
																this.setCreateDataObj({data: val, fieldType: 'costCenter'});
															}
															else{
																this.setCreateDataObj({data: null, fieldType: 'costCenter'}); 
															}
														}}
														id='costCenter-field'
														name="costCenter"
														className="form-control"
														// errorClass={groupError}
														options={this.state.costCenterDropdownOptions}
														selectedOptions={(this.state.createDataObj?.costCenter) || [] }
														// disabled={false}
														onInputChange={(e) => {
															this.setCreateDataObj({data: null, fieldType: 'costCenter'}); 
															this.setCreateDataObj({data: e,fieldSubType: 'costCenter-typed'});
															}}
															onCrossClick={() => {
															this.setCreateDataObj({data: null, fieldType: 'costCenter'}); 
															this.setCreateDataObj({data: null,fieldSubType: 'costCenter-typed'});
															}}
														tr={this.props.translator}
														// typedValue={(this.state.typeaheadTypedObj?.costCenter) || '' }
														typedValue={(this.state.typeaheadTypedObj && 'costCenter' in this.state.typeaheadTypedObj && this.state.typeaheadTypedObj?.costCenter) ? this.state.typeaheadTypedObj?.costCenter : '' }
													/>
												</div>
										</Form.Group>
									</Col>
									
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">PO Date</Form.Label>
											<DateTimeField format={dateformat} onChange={(value = '') => {
												console.log('value - ', value);
												this.setCreateDataObj({data: value, fieldType: 'poDate'});
											}} 
											value={(this.state.createDataObj?.poDate) || ''}
											/>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">PO Number</Form.Label>
											<Form.Control type="text"  onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'poNumber'});
											}} 
											value={(this.state.createDataObj?.poNumber) || ''}
											/>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Invoice Number</Form.Label>
											<Form.Control type="text"  onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'invoiceNumber'});
											}} 
											value={(this.state.createDataObj?.invoiceNumber) || ''}
											/>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Cost Currency</Form.Label>
											
											<Form.Control type="text"  onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'costCurrency'});
											}} 
											value={(this.state.createDataObj?.costCurrency) || ''}
											/>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">GL Account</Form.Label>
											<Form.Control type="text"  onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'glAccount'});
											}} 
											value={(this.state.createDataObj?.glAccount) || ''}
											/>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Net Book Value</Form.Label>
											<Form.Control type="text"  onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'netBookValue'});
											}} 
											value={(this.state.createDataObj?.netBookValue) || ''}
											/>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Depreciation Charged</Form.Label>
											
											<Form.Control type="text"  onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'depreciationCharged'});
											}} 
											value={(this.state.createDataObj?.depreciationCharged) || ''}
											/>
										</Form.Group>
									</Col>

									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Depreciation Scheduled</Form.Label>
											<Form.Select componentClass="select" name="depreciationScheduled" className={`form-control`} onBlur={(event) => { }} onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'depreciationScheduled'});
											}}
											value={(this.state.createDataObj?.depreciationScheduled) || ''}
											>
												<option value="">Select</option>
												{this.state.depScheduledDropdownOptions.map(item => {
													return(<option value={item['id']}>{item['label']}</option>);
												})}
											</Form.Select>
										</Form.Group>
									</Col>
									
									
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Vendor</Form.Label>
											<div className="dvTypehd">
													<TypeaheadSelect 
														onSelection={(val) => {
															console.log('val ********', val);
															if(val && val[0] && val[0].id){
																this.setCreateDataObj({data: val, fieldType: 'vendor'});
															}
															else{
																this.setCreateDataObj({data: null, fieldType: 'vendor'}); 
															}
														}}
														id='vendor-field'
														name="vendor"
														className="form-control"
														// errorClass={groupError}
														options={this.state.supportCompanyDropdownOptions}
														selectedOptions={(this.state.createDataObj?.vendor) || [] }
														// disabled={false}
														onInputChange={(e) => {
															this.setCreateDataObj({data: null, fieldType: 'vendor'}); 
															this.setCreateDataObj({data: e,fieldSubType: 'vendor-typed'});
															}}
															onCrossClick={() => {
															this.setCreateDataObj({data: null, fieldType: 'vendor'}); 
															this.setCreateDataObj({data: null,fieldSubType: 'vendor-typed'});
															}}
														tr={this.props.translator}
														// typedValue={(this.state.typeaheadTypedObj?.vendor) || '' }
														typedValue={(this.state.typeaheadTypedObj && 'vendor' in this.state.typeaheadTypedObj && this.state.typeaheadTypedObj?.vendor) ? this.state.typeaheadTypedObj?.vendor : '' }
													/>
												</div>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Currency</Form.Label>
											<Form.Select componentClass="select" name="currency" className={`form-control`} onBlur={(event) => { }} onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'currency'});
											}}
											value={(this.state.createDataObj?.currency) || ''}
											>
												<option value="">Select</option>
												{this.state.currencyDropdownOptions.map(item => {
													return(<option value={item['id']}>{item['label']}</option>);
												})}
											</Form.Select>
										</Form.Group>
									</Col>
								</Row>
								<div className="bgGray padding-5 margin-b-5 margin-t-10">Chargeback</div>
								<Row>
								<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Charge Category</Form.Label>
											<Form.Select componentClass="select" name="chargeCategory" className={`form-control`} onBlur={(event) => { }} onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'chargeCategory'});
											}}
											value={(this.state.createDataObj?.chargeCategory) || ''}
											>
												<option value="">Select</option>
												{this.state.chargeCategoryDropdownOptions.map(item => {
													return(<option value={item['id']}>{item['label']}</option>);
												})}
											</Form.Select>
										</Form.Group>
									</Col>
									
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Charge Start Date</Form.Label>
											<DateTimeField format={dateformat} onChange={(value = '') => {
												console.log('value - ', value);
												this.setCreateDataObj({data: value, fieldType: 'chargeStartDate'});
											}} 
											value={(this.state.createDataObj?.chargeStartDate) || ''}
											/>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Charge End Date</Form.Label>
											<DateTimeField format={dateformat} onChange={(value = '') => {
												console.log('value - ', value);
												this.setCreateDataObj({data: value, fieldType: 'chargeEndDate'});
											}} 
											value={(this.state.createDataObj?.chargeEndDate) || ''}
											/>
										</Form.Group>
									</Col>

									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Charge Type</Form.Label>
											<Form.Select componentClass="select" name="chargeType" className={`form-control`} onBlur={(event) => { }} onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'chargeType'});
											}}
											value={(this.state.createDataObj?.chargeType) || ''}
											>
												<option value="">Select</option>
												{this.state.chargeTypeDropdownOptions.map(item => {
													return(<option value={item['id']}>{item['label']}</option>);
												})}
											</Form.Select>
										</Form.Group>
									</Col>
									{/* <Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Charge Type</Form.Label>
											<Field name="chargeCategory" className={`form-control`} component="select" onBlur={(event) => { }} onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'chargeCategory'});
											}}>
												<option value="">Select</option>
											</Field>
										</Form.Group>
									</Col> */}
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Charge Frequency</Form.Label>
											<Form.Select componentClass="select" className={`form-control`} onBlur={(event) => { }} onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'chargeFrequency'});
											}}
											value={(this.state.createDataObj?.chargeFrequency) || ''}
											>
												<option value="">Select</option>
												{this.state.chargeFrequencyDropdownOptions.map(item => {
													return(<option value={item['id']}>{item['label']}</option>);
												})}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Charge Currency Amount</Form.Label>
											
											<Form.Control type="text"  onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'chargeCurrencyAmount'});
											}} 
											value={(this.state.createDataObj?.chargeCurrencyAmount) || ''}
											/>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Charge to CC</Form.Label>
											<div className="dvTypehd">
													<TypeaheadSelect 
														onSelection={(val) => {
															console.log('val ********', val);
															if(val && val[0] && val[0].id){
																this.setCreateDataObj({data: val, fieldType: 'chargeToCC'});
															}
															else{
																this.setCreateDataObj({data: null, fieldType: 'chargeToCC'}); 
															}
														}}
														id='chargeCC-field'
														name="chargeCC"
														className="form-control"
														// errorClass={groupError}
														options={this.state.supportCompanyDropdownOptions || []}
														selectedOptions={(this.state.createDataObj?.chargeToCC) || [] }
														// disabled={false}
														onInputChange={(e) => {
															this.setCreateDataObj({data: null, fieldType: 'chargeToCC'}); 
															this.setCreateDataObj({data: e,fieldSubType: 'chargeToCC-typed'});
															}}
															onCrossClick={() => {
															this.setCreateDataObj({data: null, fieldType: 'chargeToCC'}); 
															this.setCreateDataObj({data: null,fieldSubType: 'chargeToCC-typed'});
															}}
														tr={this.props.translator}
														// typedValue={(this.state.typeaheadTypedObj?.chargeToCC) || '' }
														typedValue={(this.state.typeaheadTypedObj && 'chargeToCC' in this.state.typeaheadTypedObj && this.state.typeaheadTypedObj?.chargeToCC) ? this.state.typeaheadTypedObj?.chargeToCC : '' }
													/>
												</div>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Charge to Company</Form.Label>
											<div className="dvTypehd">
													<TypeaheadSelect 
														onSelection={(val) => {
															console.log('val ********', val);
															if(val && val[0] && val[0].id){
																this.setCreateDataObj({data: val, fieldType: 'chargeToCompany'});
															}
															else{
																this.setCreateDataObj({data: null, fieldType: 'chargeToCompany'}); 
															}
														}}
														id='chargeToCompany-field'
														name="chargeToCompany"
														className="form-control"
														// errorClass={groupError}
														options={companyTypeaheadOptions}
														selectedOptions={(this.state.createDataObj?.chargeToCompany) || [] }
														// disabled={false}
														onInputChange={(e) => {
															this.setCreateDataObj({data: null, fieldType: 'chargeToCompany'}); 
															this.setCreateDataObj({data: e,fieldSubType: 'chargeToCompany-typed'});
															}}
															onCrossClick={() => {
															this.setCreateDataObj({data: null, fieldType: 'chargeToCompany'}); 
															this.setCreateDataObj({data: null,fieldSubType: 'chargeToCompany-typed'});
															}}
														tr={this.props.translator}
														// typedValue={(this.state.typeaheadTypedObj?.chargeToCompany) || '' }
														typedValue={(this.state.typeaheadTypedObj && 'chargeToCompany' in this.state.typeaheadTypedObj && this.state.typeaheadTypedObj?.chargeToCompany) ? this.state.typeaheadTypedObj?.chargeToCompany : '' }
													/>
												</div>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Charge to Project</Form.Label>
											<div className="dvTypehd">
													<TypeaheadSelect 
														onSelection={(val) => {
															console.log('val ********', val);
															if(val && val[0] && val[0].id){
																this.setCreateDataObj({data: val, fieldType: 'chargeToProject'});
															}
															else{
																this.setCreateDataObj({data: null, fieldType: 'chargeToProject'}); 
															}
														}}
														id='chargeToProject-field'
														name="chargeToProject"
														className="form-control"
														// errorClass={groupError}
														options={[]}
														selectedOptions={(this.state.createDataObj?.chargeToProject) || [] }
														// disabled={false}
														onInputChange={(e) => {
															this.setCreateDataObj({data: null, fieldType: 'chargeToProject'}); 
															this.setCreateDataObj({data: e,fieldSubType: 'chargeToProject-typed'});
															}}
															onCrossClick={() => {
															this.setCreateDataObj({data: null, fieldType: 'chargeToProject'}); 
															this.setCreateDataObj({data: null,fieldSubType: 'chargeToProject-typed'});
															}}
														tr={this.props.translator}
														// typedValue={(this.state.typeaheadTypedObj?.chargeToProject) || '' }
														typedValue={(this.state.typeaheadTypedObj && 'chargeToProject' in this.state.typeaheadTypedObj && this.state.typeaheadTypedObj?.chargeToProject) ? this.state.typeaheadTypedObj?.chargeToProject : '' }
													/>
												</div>
										</Form.Group>
									</Col>
								</Row>
								<div className="bgGray padding-5 margin-b-5 margin-t-10">Lease</div>
								<Row>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Actual Return Date</Form.Label>
											<DateTimeField format={dateformat} onChange={(value = '') => {
												console.log('value - ', value);
												this.setCreateDataObj({data: value, fieldType: 'actualReturnDate'});
											}} 
											value={(this.state.createDataObj?.actualReturnDate) || ''}
											/>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Scheduled Return Date</Form.Label>
											<DateTimeField format={dateformat} onChange={(value = '') => {
												console.log('value - ', value);
												this.setCreateDataObj({data: value, fieldType: 'scheduledReturnDate'});
											}} 
											value={(this.state.createDataObj?.scheduledReturnDate) || ''}
											/>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Vendor (Lessor)</Form.Label>
											<div className="dvTypehd">
													<TypeaheadSelect 
														onSelection={(val) => {
															console.log('val ********', val);
															if(val && val[0] && val[0].id){
																this.setCreateDataObj({data: val, fieldType: 'vendorLessor'});
															}
															else{
																this.setCreateDataObj({data: null, fieldType: 'vendorLessor'}); 
															}
														}}
														id='vendorLessor-field'
														name="vendorLessor"
														className="form-control"
														// errorClass={groupError}
														options={this.state.supportCompanyDropdownOptions}
														selectedOptions={(this.state.createDataObj?.vendorLessor) || [] }
														// disabled={false}
														onInputChange={(e) => {
															this.setCreateDataObj({data: null, fieldType: 'vendorLessor'}); 
															this.setCreateDataObj({data: e,fieldSubType: 'vendorLessor-typed'});
															}}
															onCrossClick={() => {
															this.setCreateDataObj({data: null, fieldType: 'vendorLessor'}); 
															this.setCreateDataObj({data: null,fieldSubType: 'vendorLessor-typed'});
															}}
														tr={this.props.translator}
														// typedValue={(this.state.typeaheadTypedObj?.vendorLessor) || '' }
														typedValue={(this.state.typeaheadTypedObj && 'vendorLessor' in this.state.typeaheadTypedObj && this.state.typeaheadTypedObj?.vendorLessor) ? this.state.typeaheadTypedObj?.vendorLessor : '' }
													/>
												</div>
										</Form.Group>
									</Col>
								</Row>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="3">
							<Accordion.Header>Properties</Accordion.Header>
							<Accordion.Body>
								<Row>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Host Name</Form.Label>
											<Form.Control type="text"  onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'hostName'});
											}} 
											value={(this.state.createDataObj?.hostName) || ''}
											/>
										</Form.Group>
									</Col>
									
									
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">IP Address</Form.Label>
											<Form.Control type="text"  onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'ipAddress'});
											}} 
											value={(this.state.createDataObj?.ipAddress) || ''}
											/>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">MAC Address</Form.Label>
											<Form.Control type="text"  onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'macAddress'});
											}} 
											value={(this.state.createDataObj?.macAddress) || ''}
											/>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Backup Retention</Form.Label>
											<Form.Control type="text"  onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'backupRetention'});
											}} 
											value={(this.state.createDataObj?.backupRetention) || ''}
											/>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Backup Scheduled</Form.Label>
											<Form.Control type="text"  onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'backupScheduled'});
											}} 
											value={(this.state.createDataObj?.backupScheduled) || ''}
											/>
										</Form.Group>
									</Col>
									
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Monitor</Form.Label>
											<Form.Control type="text"  onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'monitor'});
											}} 
											value={(this.state.createDataObj?.monitor) || ''}
											/>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">FQDN</Form.Label>
											<Form.Control type="text"  onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'fqdn'});
											}} 
											value={(this.state.createDataObj?.fqdn) || ''}
											/>
										</Form.Group>
									</Col>
								</Row>
								<div className="bgGray padding-5 margin-b-5 margin-t-10">Capacity</div>
								<Row>
								<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Allocated Capacity</Form.Label>
											<Form.Control type="text"  onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'allocatedCapacity'});
											}} 
											value={(this.state.createDataObj?.allocatedCapacity) || ''}
											/>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Maximum Capacity</Form.Label>
											<Form.Control type="text"  onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'maximumCapacity'});
											}} 
											value={(this.state.createDataObj?.maximumCapacity) || ''}
											/>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Capacity Unit</Form.Label>
											<Form.Control type="text"  onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'capacityUnit'});
											}} 
											value={(this.state.createDataObj?.capacityUnit) || ''}
											/>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Capacity Utilization</Form.Label>
											<Form.Control type="text"  onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'capacityUtilization'});
											}} 
											value={(this.state.createDataObj?.capacityUtilization) || ''}
											/>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Capacity Thresholds</Form.Label>
											<Form.Control type="text"  onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'capacityThresholds'});
											}} 
											value={(this.state.createDataObj?.capacityThresholds) || ''}
											/>
										</Form.Group>
									</Col>
									
									
									
								</Row>
								<div className="bgGray padding-5 margin-b-5 margin-t-10">BCP/DR</div>
								<Row>
								<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">DR Enabled</Form.Label>
											<Form.Select componentClass="select" name="drEnabled" className={`form-control`} onBlur={(event) => { }} onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'drEnabled'});
											}}
											value={(this.state.createDataObj?.drEnabled) || ''}
											>
												<option value="">Select</option>
												<option value="true">True</option>
												<option value="false">False</option>
											</Form.Select>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">DR Location</Form.Label>
											<div className="dvTypehd">
													<TypeaheadSelect 
														onSelection={(val) => {
															console.log('val ********', val);
															if(val && val[0] && val[0].id){
																this.setCreateDataObj({data: val, fieldType: 'drLocation'});
															}
															else{
																this.setCreateDataObj({data: null, fieldType: 'drLocation'}); 
															}
														}}
														id='drLocation-field'
														name="drLocation"
														className="form-control"
														// errorClass={groupError}
														options={this.state.drLocationDropdownOptions}
														selectedOptions={(this.state.createDataObj?.drLocation) || [] }
														// disabled={false}
														onInputChange={(e) => {
															this.setCreateDataObj({data: null, fieldType: 'drLocation'}); 
															this.setCreateDataObj({data: e,fieldSubType: 'drLocation-typed'});
															}}
															onCrossClick={() => {
															this.setCreateDataObj({data: null, fieldType: 'drLocation'}); 
															this.setCreateDataObj({data: null,fieldSubType: 'drLocation-typed'});
															}}
														tr={this.props.translator}
														// typedValue={(this.state.typeaheadTypedObj?.drLocation) || '' }
														typedValue={(this.state.typeaheadTypedObj && 'drLocation' in this.state.typeaheadTypedObj && this.state.typeaheadTypedObj?.drLocation) ? this.state.typeaheadTypedObj?.drLocation : '' }
													/>
												</div>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">DR Tier</Form.Label>
											<Form.Select componentClass="select" name="drTier" className={`form-control`} onBlur={(event) => { }} onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'drTier'});
											}}
											value={(this.state.createDataObj?.drTier) || ''}
											>
												<option value="">Select</option>
												{this.state.drTierDropdownOptions.map(item => {
													return(<option value={item['id']}>{item['label']}</option>);
												})}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Recovery Point Objective</Form.Label>
											<Form.Control type="text"  onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'recoveryPointObjective'});
											}} 
											value={(this.state.createDataObj?.recoveryPointObjective) || ''}
											/>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Recovery Time Objective</Form.Label>
											<Form.Control type="text"  onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'recoveryTimeObjective'});
											}} 
											value={(this.state.createDataObj?.recoveryTimeObjective) || ''}
											/>
										</Form.Group>
									</Col>
								</Row>
								<div className="bgGray padding-5 margin-b-5 margin-t-10">Discovery</div>
								<Row>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Discovery Source</Form.Label>
											<Form.Control type="text"  onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'discoverySource'});
											}} 
											value={(this.state.createDataObj?.discoverySource) || ''}
											/>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">First Discovered</Form.Label>
											<DateTimeField format={dateformat} onChange={(value = '') => {
												console.log('value - ', value);
												this.setCreateDataObj({data: value, fieldType: 'firstDiscovered'});
											}} 
											value={(this.state.createDataObj?.firstDiscovered) || ''}
											/>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Most Recent Discovery</Form.Label>
											<DateTimeField format={dateformat} onChange={(value = '') => {
												console.log('value - ', value);
												this.setCreateDataObj({data: value, fieldType: 'mostRecentDiscovery'});
											}} 
											value={(this.state.createDataObj?.mostRecentDiscovery) || ''}
											/>
										</Form.Group>
									</Col>
								</Row>
								<div className="bgGray padding-5 margin-b-5 margin-t-10">Patching</div>
								<Row>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Patch Hold</Form.Label>
											<Form.Select componentClass="select" name="patchHold" className={`form-control`} onBlur={(event) => { }} onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'patchHold'});
											}}
											value={(this.state.createDataObj?.patchHold) || ''}
											>
												<option value="">Select</option>
												<option value={'true'}>True</option>
												<option value={'false'}>False</option>
											</Form.Select>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Patch Hold Reason</Form.Label>
											<Form.Control type="text"  onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'patchHoldReason'});
											}} 
											value={(this.state.createDataObj?.patchHoldReason) || ''}
											/>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Patching Schedule</Form.Label>
											<Form.Control type="text"  onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'patchingSchedule'});
											}} 
											value={(this.state.createDataObj?.patchingSchedule) || ''}
											/>
										</Form.Group>
									</Col>
									<Col lg={4} md={4}  sm={6} xs={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">Release Patch Level</Form.Label>
											<Form.Control type="text"  onChange={(event) => {
												this.setCreateDataObj({event, fieldType: 'releasePatchLevel'});
											}} 
											value={(this.state.createDataObj?.releasePatchLevel) || ''}
											/>
										</Form.Group>
									</Col>
								</Row>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>

				</div>
			</div>
		);
	}
}

CmdbCreateForm = reduxForm({
	form: 'CmdbCreateForm',
	fields: ['ciname', 'ciclass', 'citype', 'description', 'company', 'status', 'category', 'subCategory', 'metallic', 'group', 'environment', 'owner', 'location', 'dataset', 'supcompany', 'businessowner', 'techowner', 'assettag', 'subStatus', 'is_external', 'serial_number'],
	initialValues: { ciname: "", ciclass: "", citype: "", description: "", company: "", status: "", category: "", subCategory: "", metallic: "", group: "", environment: "", owner: "", location: "", dataset: "", supcompany: "", businessowner: "", techowner: "", assettag: "", subStatus: "", is_external: "", serial_number: null },
	destroyOnUnmount: false

})(CmdbCreateForm);

let selector = formValueSelector('CmdbCreateForm')
CmdbCreateForm = connect((state) => {
	let citypeParam = selector(state,'citype');
	let companyIdParam = selector(state, 'company');
	let classIdParam = selector(state, 'ciclass');
	return{
		citypeParam,companyIdParam,classIdParam
	}
})(CmdbCreateForm)


export function mapStateToProps({ ciClass, ciType, ciStatus, ciMetallic, ciEnv, ciManr, ciDataset, ciCompany, ciUserBasedLocation, ciGroup, ciInd, ciCat, ciSCat, ciSupCompany, compClass }) {
	//console.log(ciInd);
	//console.log(ciSupCompany);
	return { compClass, ciClass, ciStatus, ciType, ciMetallic, ciEnv, ciManr, ciDataset, ciCompany, ciUserBasedLocation, ciGroup, ciInd, ciCat, ciSCat, ciSupCompany };
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ resetCiClass, loadClassByCompanyId, loadCIStatusCurrent, loadCIMetallic, loadCIEnv, loadCIManr, loadCIDataset, loadCICompany, loadCIUserBasedLocation, loadCIGroup, emptyCIGroup, loadCIInd, loadCICat, loadCIType, loadCISCat, loadCISupCompany, emptyCISupCompany, resetCMDBQVFilters, emptyCICatReducer, emptyCISCatReducer }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CmdbCreateForm);
