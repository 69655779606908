
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { NavLink } from "react-router-dom";
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  loadSlaDefListOnPagination,
  loadSlaWorkdayListOnPagination,
  loadSlaHolidayListOnPagination,
} from "_Actions/sla/quickViewAction.js";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {LuFilterX} from "react-icons/lu";
import {HiPlus, HiOutlineRefresh} from "react-icons/hi";

const cookies = new Cookies();
let cookieString = cookies.get("gph");

if (cookieString) cookieString = cookieString.replace("s:", "");
if (cookieString)
  cookieString = cookieString.substring(0, cookieString.lastIndexOf("."));
let cookieArray = cookieString.split("~");
let sla_work_holiday = cookieArray[36];
let homepagelocation = cookieArray[0];

if (homepagelocation != "/home") homepagelocation = "/" + homepagelocation;

const SlaListHeader = (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const slaDefList = useSelector((state)=>state.slaDefList);
  const slaWorkdayList = useSelector((state)=>state.slaWorkdayList);
  const slaHolidayList = useSelector((state)=>state.slaHolidayList);
  const handleSelect = (sla) => {
    if (sla == "slaDeflist") {
      navigate("/slaDeflist");
    }
    if (sla == "slaWorkdaylist") {
      navigate("/slaWorkdaylist");
    }
    if (sla == "slaHolidaylist") {
      navigate("/slaHolidaylist");
    }
  };

  const loadHome = () => {
    navigate(homepagelocation);
  };

  const clearAllFiltersSLA = () => {
    props.setNameSearchInput("");
    props.setCompanySearchInput("");
    props.setSelectedTargetValue([]);
    props.setSelectedStatusValue([]);
    props.setSelectedModuleValue([]);
    props.setStartAndEndDate({}, {});
    if (props.dateRangePickerRef != undefined)
      props.dateRangePickerRef.endDate = moment();
    if (props.dateRangePickerRef != undefined)
      props.dateRangePickerRef.startDate = moment();
    props.setPageDetails(0, 10);
    let str = {};
    str.searchByList = "";
    str.multipleValueList = "";
    str.patternList = "";
    str.orderBy = "desc";
    str.sortBy = "modifiedOn";
    str.currentPage = 0;
    str.size = 10;
    props.setSearchParams(str, "", "", "", [], [], []);
    dispatch(loadSlaDefListOnPagination(str, [], [], []));
    props.showRightSide(false);
    props.isClearAllFilterFunc(false);
  };

  const refreshTableSLA = () => {
    props.showRightSide(false);
    props.setPageDetails(0, 10);
    let searchParams = {};
    searchParams.searchByList = props.searchKey;
    searchParams.multipleValueList = props.searchValue;
    searchParams.patternList = props.pattern;
    searchParams.currentPage = 0;
    searchParams.size = 10;
    searchParams.orderBy = "desc";
    searchParams.sortBy = "modifiedOn";
    dispatch(loadSlaDefListOnPagination(
      searchParams,
      props.statusValue,
      props.targetValue,
      props.moduleValue
    ));
  };

  const clearAllFiltersWork = () => {
    props.setScheduleSearchInput("");
    props.setCompanySearchInput("");
    props.setSelectedStatusValue([]);
    props.setSelectedTimeZoneValue([]);
    props.setPageDetails(0, 10);
    let str = {};
    str.searchByList = "";
    str.multipleValueList = "";
    str.patternList = "";
    str.currentPage = 0;
    str.size = 10;
    str.orderBy = "desc";
    str.sortBy = "scheduleId";
    props.setSearchParams(str, "", "", "", [], [], []);
    dispatch(loadSlaWorkdayListOnPagination(str, [], []));
    props.showRightSide(false);
    props.isClearAllFilterFunc(false);
  };

  const refreshTableWork = () => {
    props.showRightSide(false);
    props.setPageDetails(0, 10);
    let searchParams = {};
    searchParams.searchByList = props.searchKey;
    searchParams.multipleValueList = props.searchValue;
    searchParams.patternList = props.pattern;
    searchParams.currentPage = 0;
    searchParams.size = 10;
    searchParams.orderBy = "desc";
    searchParams.sortBy = "scheduleId";
    dispatch(loadSlaWorkdayListOnPagination(
      searchParams,
      props.statusValue,
      props.timeZoneValue
    ));
  };

  const clearAllFiltersHoliday = () => {
    props.setScheduleSearchInput("");
    props.setCompanySearchInput("");
    props.setSelectedStatusValue([]);
    props.setPageDetails(0, 10);
    let str = {};
    str.searchByList = "";
    str.multipleValueList = "";
    str.patternList = "";
    str.orderBy = "desc";
    str.sortBy = "scheduleId";
    str.currentPage = 0;
    str.size = 10;
    props.setSearchParams(str, "", "", "", [], [], []);
    dispatch(loadSlaHolidayListOnPagination(str, []));
    props.showRightSide(false);
    props.isClearAllFilterFunc(false);
  };

  const refreshTableHoliday = () => {
    props.showRightSide(false);
    props.setPageDetails(0, 10);
    let searchParams = {};
    searchParams.searchByList = props.searchKey;
    searchParams.multipleValueList = props.searchValue;
    searchParams.patternList = props.pattern;
    searchParams.currentPage = 0;
    searchParams.size = 10;
    searchParams.orderBy = "desc";
    searchParams.sortBy = "scheduleId";
    dispatch(loadSlaHolidayListOnPagination(
      searchParams,
      props.statusValue
    ));
  };

  const showModuleIcons = (selectedPathname) => {
    if (selectedPathname == "/slaDeflist") {
      return (
        <div className="paHedFilter">
          <NavLink
            role="button"
            className="myBt plus fillBtn"
            title={props.translator["Create new SLA"]}
            to="/createSlaDef"
          >
            <HiPlus/>
          </NavLink>
          <div className="overlayDv">
            {props.isClearAllfilter ? (
              <Button
                title="Clear All Filters"
                role="button"
                bsPrefix=" "
                className="myBt clrbtn"
                onClick={() => {
                  clearAllFiltersSLA();
                }}
              >
                <LuFilterX/>
              </Button>
            ) : (
              ""
            )}
          </div>
          <Button
            title={props.translator["Refresh"]}
            bsPrefix=" "
            role="button"
            
            bsClass=""
            className="myBt"
            onClick={() => {
              refreshTableSLA();
            }}
          >
            <HiOutlineRefresh className="refreshFlip"/>
          </Button>
        </div>
      );
    } else if (selectedPathname == "/slaWorkdaylist") {
      return (
        <div className="paHedFilter">
          <NavLink
            role="button"
            className="myBt plus fillBtn"
            title={props.translator["Create Work Schedule"]}
            to="/createWrkSch"
          >
            <HiPlus/>
          </NavLink>
          <div className="overlayDv">
            {props.isClearAllfilter ? (
              <Button
                title="Clear All Filters"
                role="button"
                bsPrefix=" "
                className="myBt clrbtn"
                onClick={() => {
                  clearAllFiltersWork();
                }}
              >
                <LuFilterX/>
              </Button>
            ) : (
              ""
            )}
          </div>
          <Button
            title={props.translator["Refresh"]}
            bsPrefix=" "
            role="button"
            
            bsClass=""
            className="myBt"
            onClick={() => {
              refreshTableWork();
            }}
          >
            <HiOutlineRefresh className="refreshFlip"/>
          </Button>
        </div>
      );
    } else {
      return (
        <div className="paHedFilter">
          <NavLink
            role="button"
            className="myBt plus fillBtn"
            title={props.translator["Create Holiday Schedule"]}
            to="/createHolSch"
          >
            <HiPlus/>
          </NavLink>
          <div className="overlayDv">
            {props.isClearAllfilter ? (
              <Button
                title="Clear All Filters"
                role="button"
                bsPrefix=" "
                className="myBt clrbtn"
                onClick={() => {
                  clearAllFiltersHoliday();
                }}
              >
                <LuFilterX/>
              </Button>
            ) : (
              ""
            )}
          </div>
          <Button
            title={props.translator["Refresh"]}
            bsPrefix=" "
            role="button"
            
            bsClass=""
            className="myBt"
            onClick={() => {
              refreshTableHoliday();
            }}
          >
            <HiOutlineRefresh className="refreshFlip"/>
          </Button>
        </div>
      );
    }
  };


  let selectedPathname = window.location.pathname;
    let metaSLA = !_.isEmpty(slaDefList)
      ? slaDefList.data.meta
      : {};
    let sizeSLA = metaSLA && metaSLA.size ? metaSLA.size : 0;
    let currentPageSLA =
      metaSLA && metaSLA.currentPage ? metaSLA.currentPage : 0;
    let startLimitSLA = sizeSLA * currentPageSLA + 1;
    let endLimitSLA = sizeSLA * (currentPageSLA + 1);
    let recordCountSLA =
      metaSLA && metaSLA.recordCount ? metaSLA.recordCount : 0;

    let metaWork = !_.isEmpty(slaWorkdayList)
      ? slaWorkdayList.meta
      : {};
    let sizeWork = metaWork && metaWork.size ? metaWork.size : 0;
    let currentPageWork =
      metaWork && metaWork.currentPage ? metaWork.currentPage : 0;
    let startLimitWork = sizeWork * currentPageWork + 1;
    let endLimitWork = sizeWork * (currentPageWork + 1);
    let recordCountWork =
      metaWork && metaWork.recordCount ? metaWork.recordCount : 0;

    let metaHoliday = !_.isEmpty(slaHolidayList)
      ? slaHolidayList.meta
      : {};
    let sizeHoliday = metaHoliday && metaHoliday.size ? metaHoliday.size : 0;
    let currentPageHoliday =
      metaHoliday && metaHoliday.currentPage ? metaHoliday.currentPage : 0;
    let startLimitHoliday = sizeHoliday * currentPageHoliday + 1;
    let endLimitHoliday = sizeHoliday * (currentPageHoliday + 1);
    let recordCountHoliday =
      metaHoliday && metaHoliday.recordCount ? metaHoliday.recordCount : 0;
  return (
    <div>
      <Row className="margin-b-15">
        <Col lg={8} md={8} sm={9} xs={8}>
          <ul className="breadcrumb margin-b-0">
            <li className="breadcrumb-item">
              <a role="button" onClick={loadHome.bind(this)}>
                {props.translator["Home"]}
              </a>
            </li>
            <li className="breadcrumb-item active">
              {props.translator["SLA Board"]}
            </li>
          </ul>
        </Col>
        {selectedPathname == "/slaDeflist" ? (
          <Col lg={4} md={4} sm={3} xs={4}>
            {recordCountSLA != 0 ? (
              <div className="tabDatacount">
                {startLimitSLA}-
                {endLimitSLA > recordCountSLA
                  ? (endLimitSLA = recordCountSLA)
                  : endLimitSLA}{" "}
                of {recordCountSLA}
              </div>
            ) : null}
          </Col>
        ) : selectedPathname == "/slaWorkdaylist" ? (
          <Col lg={4} md={4} sm={3} xs={4}>
            {recordCountWork != 0 ? (
              <div className="tabDatacount">
                {startLimitWork}-
                {endLimitWork > recordCountWork
                  ? (endLimitWork = recordCountWork)
                  : endLimitWork}{" "}
                of {recordCountWork}
              </div>
            ) : null}
          </Col>
        ) : (
          <Col lg={4} md={4} sm={3} xs={4}>
            {recordCountHoliday != 0 ? (
              <div className="tabDatacount">
                {startLimitHoliday}-
                {endLimitHoliday > recordCountHoliday
                  ? (endLimitHoliday = recordCountHoliday)
                  : endLimitHoliday}{" "}
                of {recordCountHoliday}
              </div>
            ) : null}
          </Col>
        )}
      </Row>
      <Row className="margin-b-15">
        <Col lg={7} md={7} sm={10} xs={12}>
          <ul className="wibTabs">
            <li className={selectedPathname == "/slaDeflist" ? "active" : ""}>
              <a
                role="button"
                href="javascript:;"
                title={props.translator["Target Definition"]}
                onClick={() => handleSelect("slaDeflist")}
              >
                {props.translator["Target Definition"]}{" "}
                {selectedPathname == "/slaDeflist"
                  ? recordCountSLA != 0
                    ? "(" + recordCountSLA + ")"
                    : null
                  : null}
              </a>
            </li>

            <li
              className={selectedPathname == "/slaWorkdaylist" ? "active" : ""}
            >
              <a
                role="button"
                href="javascript:;"
                title={props.translator["Work Schedule"]}
                onClick={() => handleSelect("slaWorkdaylist")}
              >
                {props.translator["Work Schedule"]}{" "}
                {selectedPathname == "/slaWorkdaylist"
                  ? recordCountWork != 0
                    ? "(" + recordCountWork + ")"
                    : null
                  : null}
              </a>
            </li>

            <li
              className={selectedPathname == "/slaHolidaylist" ? "active" : ""}
            >
              <a
                role="button"
                href="javascript:;"
                title={props.translator["Holiday Schedule"]}
                onClick={() => handleSelect("slaHolidaylist")}
              >
                {props.translator["Holiday Schedule"]}
                {selectedPathname == "/slaHolidaylist"
                  ? recordCountHoliday != 0
                    ? "(" + recordCountHoliday + ")"
                    : null
                  : null}
              </a>
            </li>
          </ul>
        </Col>
        <Col lg={5} md={5} sm={2} xs={12}>
          {showModuleIcons(selectedPathname)}
        </Col>
      </Row>
    </div>
  );
};

export default SlaListHeader;
// export function mapStateToProps({
//   slaDefList,
//   slaWorkdayList,
//   slaHolidayList,
// }) {
//   return { slaDefList, slaWorkdayList, slaHolidayList };
// }

// export default connect(mapStateToProps, {
//   loadSlaDefListOnPagination,
//   loadSlaWorkdayListOnPagination,
//   loadSlaHolidayListOnPagination,
// })(SlaListHeader);
