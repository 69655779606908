
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";

const Tooltip = ({ x, y, text }) => {
  return (
    <div
      style={{
        position: "absolute",
        left: x,
        top: y,
        padding: "8px",
        background: "rgba(0, 0, 0, 0.7)",
        color: "#fff",
        borderRadius: "4px",
      }}
    >
      {text}
    </div>
  );
};

export default Tooltip;
