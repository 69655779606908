
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch, connect } from "react-redux";
import { Row, Col, Button, Form } from "react-bootstrap";
import {
  Field,
  reduxForm,
  formValueSelector,
  change,
  SubmissionError,
} from "redux-form";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import ForumDropdownPlusTypeahead from "../../common/ForumDropdownPlusTypeahead";
import {
  getForumById,
  getAllTrack,
  getAllSubTrack,
} from "../../../../reducers/foundation/forum/forum";
import { loadBusinessFunctionList } from "../../../../actions/businessFunction/businessFunctionAction";

const FormComponent = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const froumStore = useSelector((state) => state.forums);
  const allCompany = useSelector((state) => state.forums?.allCompanyList);
  const allBusinessFunctions = useSelector(
    (state) => state?.businessFunctionList
  );
  const allRegionList = useSelector((state) => state?.forums?.regionList);
  const { error, handleSubmit } = props;

  const forumId = params?.forumId;
  const isAddMode = !forumId;

  const [companyOptions, setCompanyOptions] = useState([]);
  const [companyValue, setCompanyValue] = useState();
  const [allRegions, setAllRegions] = useState([]);
  const onCompanyInputChange = (val) => {
    console.log(val);
  };
  const required = (value) => (value ? undefined : "Required");

  const renderField = ({ label, input, meta: { touched, error } }) => {
    return (
      <div>
        <input
          {...input}
          placeholder={label}
          className={
            touched && error ? "error-form form-control" : `form-control`
          }
        />
        {touched && error && (
          <span className="error-form text-end d-block">{error}</span>
        )}
      </div>
    );
  };

  const renderTextAreaField = ({ label, input, meta: { touched, error } }) => {
    return (
      <div>
        <textarea
          rows={2}
          {...input}
          placeholder={label}
          className={
            touched && error ? "error-form form-control" : `form-control`
          }
        />
        {touched && error && (
          <span className="error-form text-end d-block">{error}</span>
        )}
      </div>
    );
  };
  const renderSelectField = ({ inputs, metas, options, onChange }) => {
    const { meta, input, className } = inputs;

    return (
      <div>
        <select
          {...input}
          onChange={onChange}
          className={
            meta?.touched && meta?.error
              ? "error-form form-control"
              : `form-control`
          }
        >
          <option value="">Select</option>
          {options?.length
            ? options.map((val, index) => (
                <option
                  value={val?.fieldNameValue1}
                  key={`forumStatus-${index}`}
                >
                  {val?.fieldNameKey1}
                </option>
              ))
            : null}
        </select>
        {meta?.touched && meta?.error && (
          <span className="error-form text-end d-block">{meta?.error}</span>
        )}
      </div>
    );
  };

  const renderFunctionSelectField = ({ inputs, metas, options, onChange }) => {
    const { meta, input, className } = inputs;

    return (
      <div>
        <select
          {...input}
          onChange={onChange}
          className={
            meta?.touched && meta?.error
              ? "error-form form-control"
              : `form-control`
          }
        >
          <option value="">Select</option>
          {options?.length
            ? options.map((val, index) => (
                <option value={val?.id} key={`biz-${index}`}>
                  {val.businessFunctionName}
                </option>
              ))
            : null}
        </select>
        {meta?.touched && meta?.error && (
          <span className="error-form text-end d-block">{meta?.error}</span>
        )}
      </div>
    );
  };

  const renderDefaultSelectField = ({
    inputs,
    metas,
    options,
    onChange,
    getOptionValue,
    getOptionLabel,
  }) => {
    const { meta, input } = inputs;

    return (
      <div>
        <select
          {...input}
          onChange={onChange}
          className={
            meta?.touched && meta?.error
              ? "error-form form-control"
              : `form-control`
          }
        >
          <option value="">Select</option>
          {options?.length
            ? options?.map((val, index) => {
                return (
                  <option
                    value={val[getOptionValue]}
                    key={`${getOptionValue}-option-${index}`}
                  >
                    {val[getOptionValue]}
                  </option>
                );
              })
            : null}
        </select>
        {meta?.touched && meta?.error && (
          <span className="error-form text-end d-block">{meta?.error}</span>
        )}
      </div>
    );
  };

  const onCompanySelection = async (selectedCompany) => {
    if (selectedCompany[0]?.id) {
      await dispatch(
        change("createForum", "customerName", selectedCompany[0]?.label)
      );
      await dispatch(
        change("createForum", "customerId", selectedCompany[0]?.id)
      );
      const resp = await dispatch(getAllTrack(selectedCompany[0]?.id));
    }
  };

  const onChangeTrack = async (e) => {
    const value = e.target.value;
    if (value) {
      dispatch(change("createForum", "trackName", value));
      const resp = await dispatch(getAllSubTrack(value));
    }
  };

  useEffect(() => {
    const companyData = allCompany?.map((item) => ({
      label: item?.field1Key,
      id: item?.field1Value,
    }));

    setCompanyOptions(companyData);
  }, [allCompany]);

  useEffect(() => {
    (async function () {
      await dispatch(loadBusinessFunctionList());
    })();
  }, []);

  useEffect(() => {
    if (allRegionList.length > 0) {
      const optionsArray = allRegionList?.slice(1, -1)?.split(", ");
      setAllRegions(optionsArray);
    }
  }, [allRegionList]);

  useEffect(() => {
    if (!isAddMode) {
      (async function () {
        await dispatch(getForumById(forumId));
      })();
    }
  }, [!isAddMode]);

  useEffect(() => {
    (async function () {
      if (!isAddMode && froumStore?.forumById) {
        const forumValue = froumStore?.forumById;

        const setformValues = {
          ...forumValue,
          company: forumValue?.customerName,
        };
        const companyValues = [
          {
            label: forumValue?.customerName,
            id: forumValue?.customerId,
          },
        ];

        onCompanySelection(companyValues);
        if (forumValue?.trackName) {
          const resp = await dispatch(getAllSubTrack(forumValue?.trackName));
        }
        setCompanyValue(forumValue?.customerName);

        props.initialize(setformValues);
      }
    })();
  }, [!isAddMode, froumStore?.forumById]);

  return (
    <form>
      <Row>
        <Col md={6} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>
              <span className="rStar"></span> Name
            </Form.Label>
            <Field
              name="forumName"
              className={`form-control`}
              component={renderField}
            />
          </Form.Group>
        </Col>
        <Col md={6} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>
              <span className="rStar"></span> Status
            </Form.Label>
            <Field
              name="statusId"
              className={`form-control`}
              component={(inputs, metas) =>
                renderSelectField({
                  inputs,
                  metas,
                  options: froumStore?.forumStatus,
                  onChange: (e) => {
                    const textval = e.target[e.target.selectedIndex].text;
                    dispatch(change("createForum", "status", textval));
                    dispatch(change("createForum", "statusId", e.target.value));
                  },
                })
              }
            />
          </Form.Group>
        </Col>
        <Col md={6} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>
              <span className="rStar"></span> Company
            </Form.Label>

            <Field
              name="company"
              id="company"
              disabled={!isAddMode}
              className="form-control"
              component={ForumDropdownPlusTypeahead}
              options={companyOptions || []}
              onInputChange={onCompanyInputChange}
              onSelection={onCompanySelection}
              {...(!isAddMode &&
                companyValue && {
                  defaultInputValue: companyValue,
                })}
              {...(!isAddMode && {
                selected: companyValue ? [companyValue] : [],
              })}
            />
          </Form.Group>
        </Col>
        <Col md={6} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>
              <span className="rStar"></span> Function
            </Form.Label>

            <Field
              name="functionId"
              className={`form-control`}
              component={(inputs, metas) =>
                renderFunctionSelectField({
                  inputs,
                  metas,
                  options: allBusinessFunctions || [],
                  onChange: (e) => {
                    const funval = e.target[e.target.selectedIndex].text;
                    dispatch(change("createForum", "functionName", funval));
                    dispatch(
                      change("createForum", "functionId", e.target.value)
                    );
                  },
                })
              }
            />
          </Form.Group>
        </Col>
        <Col md={6} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>Track</Form.Label>
            <Field
              name="trackName"
              className={`form-control`}
              component={(inputs, metas) =>
                renderDefaultSelectField({
                  inputs,
                  metas,
                  options: froumStore?.allTrack || [],
                  getOptionValue: "trackName",
                  getOptionLabel: "trackName",
                  onChange: onChangeTrack,
                })
              }
            />
          </Form.Group>
        </Col>
        <Col md={6} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>Sub-Track</Form.Label>
            <Field
              name="subTrackName"
              className={`form-control`}
              component="select"
            >
              <option value="">Select</option>
              {froumStore?.allSubTrack?.length > 0 &&
                froumStore?.allSubTrack?.map((item, index) => {
                  return (
                    <option
                      value={item?.subTrackName}
                      key={`subTrackId-${index}`}
                      data-id={item?.subTrackId}
                    >
                      {item?.subTrackName}
                    </option>
                  );
                })}
            </Field>
          </Form.Group>
        </Col>
        <Col md={6} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>Region</Form.Label>
            <Field
              name="regionName"
              className={`form-control`}
              component="select"
            >
              <option value="">Select</option>
              {allRegions?.length > 0 &&
                allRegions?.map((region, index) => {
                  return (
                    <option value={region} key={`track-${index}`}>
                      {region}
                    </option>
                  );
                })}
            </Field>
          </Form.Group>
        </Col>
        <Col md={6} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>
              <span className="rStar"></span> Type
            </Form.Label>
            <Field
              name="type"
              className={`form-control`}
              component={(inputs, metas) =>
                renderDefaultSelectField({
                  inputs,
                  metas,
                  options: froumStore?.forumType || [],
                  getOptionValue: "fieldNameKey1",
                  getOptionLabel: "fieldNameKey1",
                  onChange: (e) => {
                    const typeval = e.target[e.target.selectedIndex].text;
                    dispatch(change("createForum", "type", typeval));
                  },
                })
              }
            />
          </Form.Group>
        </Col>
        <Col xs={12}>
          <Form.Group className="form-group">
            <Form.Label>
              <span className="rStar"></span> Objectives
            </Form.Label>
            <Field
              name="objective"
              className="form-control"
              component={renderTextAreaField}
            />
          </Form.Group>
        </Col>
      </Row>
    </form>
  );
};

export const validate = (values) => {
  const errors = {};
  if (!values.forumName) {
    errors.forumName = "Required";
  }

  if (values.status === "Select" || !values.statusId) {
    errors.statusId = "Required";
  }
  // if (!values.company) {
  //   errors.company = "Required";
  // }
  if (!values.functionId) {
    errors.functionId = "Required";
  }
  if (!values.type) {
    errors.type = "Required";
  }

  if (!values.objective) {
    errors.objective = "Required";
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {};
};

//const ConnectedFormComponent = connect(mapStateToProps)(FormComponent);

export default reduxForm({
  form: "createForum",
  validate,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(FormComponent);
