
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import {
  Image,
  Row,
  Col,
  FormGroup,
  FormControl,
  ControlLabel,
  InputGroup,
  Button,
  ButtonToolbar,
  Accordion,
  Panel,
  Media,
  ListGroup,
  ListGroupItem,
} from "react-bootstrap";
import { getQuestions } from "_Actions/communityActions";
import { connect } from "react-redux";
import axios from "axios";
import { Scrollbars } from "react-custom-scrollbars";
import {IoSearch} from 'react-icons/io5';

import constants from "_Utils/constants";
import OnClickOutside from "react-onclickoutside";

import avaterImage from "_Images/com-user.png";

class TextAreaDetails extends React.Component {
  handleClickOutside(eventKey) {
    this.props.handleClick();
  }

  render() {
    return <div {...this.props}>{this.props.children}</div>;
  }
}
TextAreaDetails = OnClickOutside(TextAreaDetails);

class ListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addQuesPart: false,
      questionId: -1,
      commentId: -1,
      community: [],
      pageSize: 3,
      start: 15,
      comment: "",
      question: "",
      search: "",
      offeringName: this.props.offeringName,
      offeringId: this.props.offeringId,
      panelClicked: false,
      openPanel4Id: -1,
      showParentClicked: false,
      showParentId: -1,
      showBackButton: false,
    };
    this.addQuesPartFun = this.addQuesPartFun.bind(this);
    this.quesChange = this.quesChange.bind(this);
    this.addComment = this.addComment.bind(this);
    this.commentChange = this.commentChange.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.handleScrollFrame = this.handleScrollFrame.bind(this);
    this.searchChange = this.searchChange.bind(this);
    this.searchQuestion = this.searchQuestion.bind(this);
    this.toggleLike = this.toggleLike.bind(this);
    this.panelClickedFun = this.panelClickedFun.bind(this);
    this.showParentClickedFun = this.showParentClickedFun.bind(this);
    this.showBackButtonFun = this.showBackButtonFun.bind(this);
    this.removeQuesPartFun = this.removeQuesPartFun.bind(this);
  }

  panelClickedFun(idValue) {
    if (idValue == this.state.openPanel4Id && this.state.panelClicked) {
      this.setState({
        panelClicked: false,
        openPanel4Id: idValue,
        showParentClicked: false,
        questionId: -1,
        commentId: -1,
      });
    } else {
      this.setState({
        panelClicked: true,
        openPanel4Id: idValue,
        showParentClicked: false,
        questionId: -1,
        commentId: -1,
      });
    }
  }
  showParentClickedFun(idValue) {
    if (idValue == this.state.showParentId && this.state.showParentClicked) {
      this.setState({ showParentClicked: false, showParentId: idValue });
    } else {
      this.setState({ showParentClicked: true, showParentId: idValue });
    }
  }
  toggleLike(id, prev, curr) {
    let values = {};
    values.user_id = "";
    values.community_id = id;
    if (!prev) {
      if (curr == "Y") values.like_value = "like";
      else values.like_value = "dislike";

      axios
        .post(constants.communityurl + "/api/community/likes", values)
        .then((res) => {
          this.props.getQuestions(
            1,
            this.state.start,
            this.state.search,
            "",
            this.state.offeringId,
            (err, val) => {
              this.setState({
                community: val.data.result,
                comment: "",
                commentId: -1,
                questionId: -1,
              });
            }
          );
        });
    } else if (prev == curr) {
      values.like_value = "delete";
      axios
        .patch(constants.communityurl + "/api/community/likes", values)
        .then((res) => {
          this.props.getQuestions(
            1,
            this.state.start,
            this.state.search,
            "",
            this.state.offeringId,
            (err, val) => {
              this.setState({
                community: val.data.result,
                comment: "",
                commentId: -1,
                questionId: -1,
              });
            }
          );
        });
    } else {
      if (curr == "Y") values.like_value = "like";
      else values.like_value = "dislike";
      axios
        .patch(constants.communityurl + "/api/community/likes", values)
        .then((res) => {
          this.props.getQuestions(
            1,
            this.state.start,
            this.state.search,
            "",
            this.state.offeringId,
            (err, val) => {
              this.setState({
                community: val.data.result,
                comment: "",
                commentId: -1,
                questionId: -1,
              });
            }
          );
        });
    }
  }

  searchChange(e) {
    this.setState({ search: e.target.value });
    if (this.state.search == "") {
      this.props.getQuestions(
        1,
        15,
        this.state.search,
        "",
        this.state.offeringId,
        (err, val) => {
          this.setState({ community: val.data.result });
        }
      );
    }
  }

  handleScrollFrame(e) {
    if (e.top == 1) {
      this.props.getQuestions(
        this.state.start + 1,
        this.state.start + this.state.pageSize,
        this.state.search,
        "",
        this.state.offeringId,
        (err, val) => {
          let temp = this.state.community.concat(val.data.result);
          this.setState({
            community: temp,
            start: this.state.start + this.state.pageSize,
          });
        }
      );
    }
  }

  removeQuesPartFun() {
    this.setState({ questionId: "", commentId: [], comment: "" });
  }

  keyPress() {
    if (this.state.comment.length > 0) {
      if (this.state.questionId > -1)
        axios
          .post(constants.communityurl + "/api/community", {
            createdby_name: "",
            created_by: "",
            offeringId: this.state.offeringId,
            community_text: this.state.comment,
            parent: this.state.questionId,
          })
          .then((res) => {
            this.props.getQuestions(
              1,
              this.state.start,
              this.state.search,
              "",
              this.state.offeringId,
              (err, val) => {
                this.setState({
                  community: val.data.result,
                  comment: "",
                  commentId: -1,
                  questionId: -1,
                });
              }
            );
            this.setState({ addQuesPart: false });
          });
      else {
        axios
          .post(constants.communityurl + "/api/community", {
            createdby_name: "",
            offeringId: this.state.offeringId,
            created_by: "",
            community_text: this.state.comment,
            parent: this.state.commentId[1],
          })
          .then((res) => {
            this.props.getQuestions(
              1,
              this.state.start,
              this.state.search,
              "",
              this.state.offeringId,
              (err, val) => {
                this.setState({
                  community: val.data.result,
                  comment: "",
                  commentId: -1,
                  questionId: -1,
                });
              }
            );
            this.setState({ addQuesPart: false });
          });
      }
    } else {
      this.setState({ addQuesPart: false });
    }
  }

  addQuesPartFun() {
    this.setState({ addQuesPart: true });
  }

  commentChange(e) {
    this.setState({ comment: e.target.value });
  }

  addComment(e, i) {
    if (typeof e == "number")
      this.setState({ questionId: e, commentId: [], comment: "" });
    else {
      if (!this.state.community[i[0]].children[i[1]].children) {
        let temp = this.state.community;
        temp[i[0]].children[i[1]].children = [-1];
        this.setState({ community: temp });
      }
      this.setState({ questionId: -1, commentId: e, comment: "" });
    }
  }

  quesChange(e) {
    this.setState({ question: e.target.value });
  }

  SaveQuesFun() {
    if (this.state.question.length > 0) {
      axios
        .post(constants.communityurl + "/api/community", {
          createdby_name: "",
          created_by: "",
          offeringId: this.state.offeringId,
          offeringName: this.state.offeringName,
          community_text: this.state.question,
          parent: 0,
        })
        .then((res) => {
          this.props.getQuestions(
            1,
            15,
            this.state.search,
            "",
            this.state.offeringId,
            (err, val) => {
              this.setState({
                community: val.data.result,
                addQuesPart: false,
                question: "",
              });
            }
          );
        });
    } else {
      this.props.getQuestions(
        1,
        15,
        this.state.search,
        "",
        this.state.offeringId,
        (err, val) => {
          this.setState({ community: val.data.result, addQuesPart: false });
        }
      );
    }
  }

  searchQuestion() {
    if (this.state.search.length > 0) {
      this.props.getQuestions(
        1,
        15,
        this.state.search,
        "",
        this.state.offeringId,
        (err, val) => {
          this.setState({
            community: val.data.result,
            search: "",
            showBackButton: true,
          });
        }
      );
    }
  }
  showBackButtonFun() {
    this.props.getQuestions(
      1,
      15,
      "",
      "",
      this.state.offeringId,
      (err, val) => {
        this.setState({
          community: val.data.result,
          search: "",
          showBackButton: false,
        });
      }
    );
  }

  componentDidMount() {
    this.props.getQuestions(
      1,
      15,
      this.state.search,
      "",
      this.state.offeringId,
      (err, val) => {
        this.setState({ community: val.data.result });
      }
    );
  }

  render() {
    const community = this.state.community;

    if (community.length <= 0 || community == null) {
      return (
        <div>
          <div className="rPageHeading">
            {this.state.showBackButton && (
              <span
                onClick={this.showBackButtonFun}
                className="float-r cursorPoint f-size-13 margin-t-10"
              >
                <i className="fa fa-undo f-size-12 margin-r-5"></i>Back to List
              </span>
            )}
            {this.props.translatoroffering["Community Answers"]}
          </div>
          <div className="bgActive padding-10">
            <Row>
              <Col xs={12}>
                <ButtonToolbar className="mySearchInput">
                  <Button
                    title={this.props.translatoroffering["Post"]}
                    onClick={() => {
                      this.SaveQuesFun();
                    }}
                    bsStyle="primary"
                  >
                    <i className="fa fa-paper-plane"></i>
                  </Button>
                </ButtonToolbar>
                <FormControl
                  className="qusSearchInput"
                  placeholder={
                    this.props.translatoroffering[
                      "please post your question here"
                    ]
                  }
                  onChange={this.quesChange}
                  type="text"
                />
              </Col>
            </Row>
          </div>
          <div className="withOutBor margin-t-20">
            {this.props.translatoroffering["No match Found"]}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="rPageHeading">
            {this.state.addQuesPart ? (
              <div>
                <span
                  onClick={() => {
                    this.SaveQuesFun();
                  }}
                  className="float-r cursorPoint f-size-13 margin-t-10"
                >
                  <i className="fa fa-search f-size-12 margin-r-5"></i>
                  {this.props.translatoroffering["Search Question"]}
                </span>
              </div>
            ) : (
              <span
                onClick={() => {
                  this.addQuesPartFun();
                }}
                className="float-r cursorPoint f-size-13 margin-t-10"
              >
                <i className="fa fa-plus f-size-12 margin-r-5"></i>
                {this.props.translatoroffering["Add Question"]}
              </span>
            )}
            {this.props.translatoroffering["Community Answers"]}
          </div>

          {this.state.addQuesPart ? (
            <div className="bgActive padding-10">
              <Row>
                <Col xs={12}>
                  <ButtonToolbar className="mySearchInput">
                    <Button
                      title={this.props.translatoroffering["Post"]}
                      onClick={() => {
                        this.SaveQuesFun();
                      }}
                      bsStyle="primary"
                    >
                      <i className="fa fa-paper-plane"></i>
                    </Button>
                  </ButtonToolbar>
                  <FormControl
                    className="qusSearchInput"
                    placeholder={
                      this.props.translatoroffering[
                        "please post your question here"
                      ]
                    }
                    onChange={this.quesChange}
                    type="text"
                  />
                </Col>
              </Row>
            </div>
          ) : (
            <div>
              <div className="bgActive padding-10">
                <Row>
                  <Col xs={12}>
                    <ButtonToolbar className="mySearchInput">
                      <Button
                        title={this.props.translatoroffering["Search"]}
                        onClick={() => {
                          this.searchQuestion();
                        }}
                        Style="primary"
                      >
                        <IoSearch/>
                      </Button>
                    </ButtonToolbar>
                    <FormControl
                      className="qusSearchInput"
                      placeholder={
                        this.props.translatoroffering["please search for help"]
                      }
                      onChange={this.searchChange}
                      type="text"
                    />
                  </Col>
                </Row>
              </div>

              <div className="withOutBor margin-t-20">
                {/* defaultActiveKey="1" */}
                <Scrollbars
                  onScrollFrame={this.handleScrollFrame}
                  style={{ height: 500 }}
                  autoHide={false}
                  hideTracksWhenNotNeeded={true}
                  className="askCommScrollbar"
                >
                  <Accordion
                    className="accordPart"
                    style={{ marginRight: 10 + "px" }}
                  >
                    {this.state.community.map((val, i) => {
                      return (
                        <Panel
                          key={i}
                          className={
                            this.state.panelClicked &&
                            this.state.openPanel4Id == val.COMMUNITY_ID
                              ? "collapsed panelParent"
                              : "collapsed"
                          }
                          onSelect={() => {
                            this.panelClickedFun(val.COMMUNITY_ID);
                          }}
                          eventKey={i}
                          header={
                            this.state.panelClicked &&
                            this.state.openPanel4Id == val.COMMUNITY_ID ? (
                              <div className="panelPart">
                                <div className="leftPart">
                                  <Image
                                    className="border-radius4"
                                    src={val.USER_IMAGE || avaterImage}
                                  />
                                </div>
                                <div className="rightPart">
                                  <div className="txt">
                                    {val.COMMUNITY_TEXT}
                                  </div>
                                  <span className="dateTim">
                                    {val.CREATED_AT.substr(0, 19)}
                                  </span>
                                  by
                                  {(
                                    val.CREATEDBY_NAME || "CREATEDBY_NAME"
                                  ).substr(0, 19)}
                                </div>
                              </div>
                            ) : (
                              <div className="panelPart">
                                <div className="rightPart">
                                  <div className="txt">
                                    {val.COMMUNITY_TEXT.length <= 70
                                      ? val.COMMUNITY_TEXT + "  "
                                      : val.COMMUNITY_TEXT.substring(0, 68) +
                                        "..."}
                                  </div>
                                </div>
                              </div>
                            )
                          }
                        >
                          <div className="panelPart ansPart">
                            <Button
                              bsStyle="link"
                              className="plusComm"
                              onClick={() => {
                                this.addComment(val.COMMUNITY_ID, i);
                              }}
                            >
                              Reply
                            </Button>
                            {this.state.questionId == val.COMMUNITY_ID ? (
                              <div className="clearboth margin-b-10">
                                <div className="leftPart">
                                  <Image
                                    className="border-radius4"
                                    src={avaterImage}
                                  />
                                </div>
                                <div className="rightPart">
                                  <TextAreaDetails
                                    handleClick={this.removeQuesPartFun}
                                    className="panelPart textareaPart"
                                  >
                                    <div className="leftPart">
                                      <textarea
                                        onChange={this.commentChange}
                                        placeholder="Add a comment and post"
                                        className="form-control commAddTextArea"
                                        rows="1"
                                        id="addComment"
                                      ></textarea>
                                    </div>
                                    <div className="rightPart">
                                      <Button
                                        onClick={() => {
                                          this.keyPress(val.OFFERINGID);
                                        }}
                                        title="Reply"
                                        bsStyle="link"
                                        className="addNComm"
                                      >
                                        <i className="fa fa-paper-plane"></i>
                                      </Button>
                                    </div>
                                  </TextAreaDetails>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {val.children ? (
                              val.children.map((value, j) => (
                                <div className="margin-b-5" key={j}>
                                  <div className="leftPart">
                                    <Image
                                      className="border-radius4"
                                      src={val.USER_IMAGE || avaterImage}
                                    />
                                  </div>
                                  <div className="rightPart">
                                    <div
                                      onClick={() =>
                                        this.showParentClickedFun(
                                          value.COMMUNITY_ID
                                        )
                                      }
                                      className= "txt blueAnchor"
                                    >
                                      {value.COMMUNITY_TEXT}
                                    </div>
                                    <div>
                                      <span className="dateTim">
                                        {val.CREATED_AT.substr(0, 19)}
                                      </span>
                                      by
                                      {(
                                        val.CREATEDBY_NAME || "CREATEDBY_NAME"
                                      ).substr(0, 10)}
                                      <div className="margin-b-15">
                                        <Button
                                          bsStyle="link"
                                          className="plusComm"
                                          onClick={() =>
                                            this.addComment(
                                              [
                                                val.COMMUNITY_ID,
                                                value.COMMUNITY_ID,
                                              ],
                                              [i, j]
                                            )
                                          }
                                        >
                                          Reply
                                        </Button>
                                        <Button
                                          bsStyle="link"
                                          className="addComm green"
                                          title="Like"
                                          onClick={() =>
                                            this.toggleLike(
                                              value.COMMUNITY_ID,
                                              value.LIKED,
                                              "Y"
                                            )
                                          }
                                        >
                                          <i
                                            className={
                                              value.LIKED == "Y"
                                                ? "fa fa-thumbs-up"
                                                : "fa fa-thumbs-o-up"
                                            }
                                          ></i>
                                          ({value.LIKES})
                                        </Button>
                                        <Button
                                          bsStyle="link"
                                          className="addComm red"
                                          title="Dislike"
                                          onClick={() =>
                                            this.toggleLike(
                                              value.COMMUNITY_ID,
                                              value.LIKED,
                                              "N"
                                            )
                                          }
                                        >
                                          <i
                                            className={
                                              value.LIKED == "N"
                                                ? "fa fa-thumbs-down"
                                                : "fa fa-thumbs-o-down"
                                            }
                                          ></i>
                                          ({value.DISLIKES})
                                        </Button>
                                      </div>
                                    </div>

                                    {value.children
                                      ? value.children.map((e, k) => (
                                          <div className="clearboth" key={k}>
                                            {this.state.commentId.length > 0 &&
                                            this.state.commentId[1] ==
                                              value.COMMUNITY_ID &&
                                            k == 0 ? (
                                              <div className="panelPart ansPart margin-t-5">
                                                <div className="leftPart">
                                                  <Image
                                                    className="border-radius4"
                                                    src={avaterImage}
                                                  />
                                                </div>
                                                <div className="rightPart">
                                                  <TextAreaDetails
                                                    handleClick={
                                                      this.removeQuesPartFun
                                                    }
                                                    className="panelPart textareaPart"
                                                  >
                                                    <div className="leftPart">
                                                      <textarea
                                                        onChange={
                                                          this.commentChange
                                                        }
                                                        placeholder="Add a comment and post"
                                                        className="form-control commAddTextArea"
                                                        rows="1"
                                                        id="addComment"
                                                      >
                                                        {this.state.comment}
                                                      </textarea>
                                                    </div>
                                                    <div className="rightPart">
                                                      <Button
                                                        onClick={() => {
                                                          this.keyPress(
                                                            val.OFFERINGID
                                                          );
                                                        }}
                                                        title="Reply"
                                                        bsStyle="link"
                                                        className="addNComm"
                                                      >
                                                        <i className="fa fa-paper-plane"></i>
                                                      </Button>
                                                    </div>
                                                  </TextAreaDetails>
                                                </div>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {typeof e == "number" ? (
                                              ""
                                            ) : this.state.showParentClicked &&
                                              this.state.showParentId ==
                                                value.COMMUNITY_ID ? (
                                              <div className="panelPart ansPart">
                                                <div className="leftPart">
                                                  <Image
                                                    className="border-radius4"
                                                    src={
                                                      e.USER_IMAGE ||
                                                      avaterImage
                                                    }
                                                  />
                                                </div>
                                                <div className="rightPart">
                                                  <div className="txt">
                                                    {e.COMMUNITY_TEXT}
                                                  </div>
                                                  <div>
                                                    <span className="dateTim">
                                                      {e.CREATED_AT.substr(
                                                        0,
                                                        19
                                                      )}
                                                    </span>
                                                    by
                                                    {(
                                                      e.CREATEDBY_NAME ||
                                                      "CREATEDBY_NAME"
                                                    ).substr(0, 10)}
                                                    <Button
                                                      bsStyle="link"
                                                      className="addComm green"
                                                      title="Like"
                                                      onClick={() =>
                                                        this.toggleLike(
                                                          e.COMMUNITY_ID,
                                                          e.LIKED,
                                                          "Y"
                                                        )
                                                      }
                                                    >
                                                      <i
                                                        className={
                                                          e.LIKED == "Y"
                                                            ? "fa fa-thumbs-up"
                                                            : "fa fa-thumbs-o-up"
                                                        }
                                                      ></i>
                                                      ({e.LIKES})
                                                    </Button>
                                                    <Button
                                                      bsStyle="link"
                                                      className="addComm red"
                                                      title="Dislike"
                                                      onClick={() =>
                                                        this.toggleLike(
                                                          e.COMMUNITY_ID,
                                                          e.LIKED,
                                                          "N"
                                                        )
                                                      }
                                                    >
                                                      <i
                                                        className={
                                                          e.LIKED == "N"
                                                            ? "fa fa-thumbs-down"
                                                            : "fa fa-thumbs-o-down"
                                                        }
                                                      ></i>
                                                      ({e.DISLIKES})
                                                    </Button>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        ))
                                      : ""}
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className="panelPart ansPart">
                                No answers yet
                              </div>
                            )}
                          </div>
                        </Panel>
                      );
                    })}
                  </Accordion>
                </Scrollbars>
              </div>
            </div>
          )}
        </div>
      );
    }
  }
}

export default connect(null, { getQuestions })(ListView);
