
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { Button } from "react-bootstrap";
import { Formik, Form, ErrorMessage } from "formik";
import { useDropzone } from "react-dropzone";
import GridLayout from "../../../../../../common/GridLayout";
import {
  InputTextField,
  SelectField,
} from "../../../../../../common/FormFields";
import {
  useGetMinutesAttachmentTypesQuery,
  useSaveMinutesDetailsMutation,
} from "../../../../../../../../reducers/governance/meeting/meeting";
import { useShowHide } from "../../../../../../common/useShowHide";

const RecordingAddView = ({
  hide,
  data,
  isFetching,
  prefData,
  _dateFormat,
  companyID,
  occuranceId,
  refetchlist,
  definationId,
  activeTabs,
}) => {
  const maxSize = 1e7;
  const [rejectedF, setRejectedF] = useState([]);

  const [fileVal, setFileVal] = useState([]);

  const [isLink, showLink, hideLink] = useShowHide(false);

  const [initVals, setInitVals] = useState({
    type: "",
    attachedfileName: "",
    mimeType: "",
    name: "",
    externalUrl: "",
    file: "",
  });

  const urlValidRegex =
    /^(https?:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    type: Yup.string().required("Required").oneOf(["Attachment", "url", "URL"]),
    file: Yup.mixed().when("type", {
      is: "Attachment",
      then: Yup.mixed().required("Required"),
    }),
    externalUrl: Yup.string().when(["type"], {
      is: (type) => ["url", "URL"].includes(type),
      then: () =>
        Yup.string()
          .required("Required")
          .matches(urlValidRegex, { message: "Enter valid URL" }),
    }),
  });

  const [saveRecording, { isLoading: isLoadingSaveDisc }] =
    useSaveMinutesDetailsMutation();

  const { data: docTypeata, isFetching: docTypeFetching } =
    useGetMinutesAttachmentTypesQuery(
      {
        queryString: `?tenantId=${companyID}`,
      },
      {
        skip: !companyID,
      }
    );

    useEffect(() => {
      return () => {
       hide();
      }
     }, [activeTabs]);

  const onSubmit = async (payload, { setSubmitting }) => {
    const postData = {
      occuranceId: occuranceId,
      filename: fileVal[0]?.name || "",
      mimeType: fileVal[0]?.type || "",
      recordingName: payload?.name,
      externalUrl: payload?.externalUrl,
      definationId: definationId,
      // isAttachment: "Y",
    };

    const Objdata = JSON.stringify(postData);
    let formData = new FormData();
    formData.append("file", fileVal[0]);
    formData.append("data", Objdata);

    //let postPayload = payload?.type === "url" ? postData : formData;
    if (payload?.type === "url" || payload?.type === "URL") {
      await saveRecording({
        data: postData,
        queryString: `?tenantId=${companyID}`,
      });

      setSubmitting(false);
      hide();
    } else if (payload?.type === "Attachment") {
    }
  };

  const formatBytes = (bytes, decimals = 2) => {
    let fileSizeMB = bytes / 1024 ** 2;
    return fileSizeMB.toFixed(2) + " MB";
  };

  function sizeValidator(file) {
    if (file.size > maxSize) {
      return {
        code: "file-too-large",
        message: `File Size is Large - ${formatBytes(file.size)}`,
      };
    }

    return null;
  }
  const UploadComponent = (props) => {
    const { setFieldValue } = props;
    const { getRootProps, getInputProps } = useDropzone({
      accept:
        ".zip,.xls,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,text/plain,image/*,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/calendar,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.rar,application/rtf,application/vnd.ms-excel,pplication/zip,application/pdf,application/zip,application/x-7z-compressed",
      multiple: false,
      maxFiles: 1,
      minSize: 0,
      // maxSize: 1023,
      validator: sizeValidator,
      onDrop: (acceptedFiles, fileRejections) => {
        setRejectedF(fileRejections);
        setFieldValue(
          "fileV",
          acceptedFiles.length !== 0 ? acceptedFiles[0].name : ""
        );
        setFieldValue(
          "file",
          acceptedFiles.length !== 0 ? acceptedFiles[0].name : ""
        );
        setFileVal(acceptedFiles);
      },
    });
    return (
      <div {...getRootProps({ className: "message" })}>
        <input {...getInputProps()} name="file" />
        <div>
          <i className="fa fa-cloud-upload"></i>{" "}
          <p className="mb-0">Drag 'n' drop a file here, or click to select</p>
          <em style={{ fontSize: "12px" }}>
            (Only csv, doc, docx, gif, ics, jpeg, log, msg, pdf, png, ppt, pptx,
            txt, xls, xlsx, zip will be accepted)
          </em>
        </div>
      </div>
    );
  };

  const filesdispaly = fileVal.map((file) => (
    <span key={file.path}>
      {file.path} - {formatBytes(file.size)}
    </span>
  ));

  const fileRejectionItems = rejectedF.length !== 0 && (
    <span className="red px-1">
      {rejectedF[0].errors[0].message === "Too many files"
        ? "Only single file is allowed"
        : rejectedF[0].errors[0].message}
    </span>
  );

  return (
    <div className="uploadContainer" id="meeting-document">
      <Formik
        enableReinitialize={true}
        initialValues={initVals}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleBlur,
          values,
          handleChange,
          setValues,
          resetForm,
          dirty,
        }) => {
          return (
            <Form noValidate>
              <GridLayout cols={1}>
                <div>
                  <InputTextField
                    name="name"
                    label="Name"
                    mandatory={"true"}
                    required
                  />
                </div>
                <div>
                  <SelectField
                    name="type"
                    label="Type"
                    required
                    onChange={(e) => {
                      if (
                        e.target.value === "url" ||
                        e.target.value === "URL"
                      ) {
                        showLink();
                      } else {
                        hideLink();
                      }
                      setFieldValue("type", e.target.value);
                    }}
                  >
                    {docTypeata &&
                      docTypeata?.map((item, i) => {
                        if(item.fieldNameKey1==='Attachment'){
                          return null;
                        }
                        const uniquekey = uuidv4();
                        return (
                          <option key={uniquekey} value={item?.fieldNameKey1}>
                            {item?.fieldNameKey1}
                          </option>
                        );
                      })}
                  </SelectField>
                </div>
                {isLink && (
                  <div>
                    <InputTextField
                      required
                      name="externalUrl"
                      label="External URL"
                    />
                  </div>
                )}
              </GridLayout>
              <GridLayout cols={1}>
                <div className="pull-right custom-note-submit-btn">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    title="Save"
                    className="rgSidrkBtn smallBtn mb-0"
                  >
                    Save
                  </Button>
                  &nbsp;
                  <a className="btn  smallBtn" onClick={hide}>
                    Cancel
                  </a>
                </div>
              </GridLayout>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default RecordingAddView;
