
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/

import axios from "axios";
import { GLOBAL } from "../../../components/Globals";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
let companyId = cookies.get('gph');
if (companyId) companyId = companyId.replace('s:', '');
if (companyId) companyId = companyId.substring(0, companyId.lastIndexOf('.'));
companyId = companyId.split("~");
companyId = companyId[48];

const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

export function loadEventList(filters) {
    let queryParams = "";
    let i=1;
    filters["sort"] = "createdOn,desc";
    if(filters && Object.keys(filters).length>0){
        queryParams+= "?";
        Object.keys(filters).forEach(key=>{
            queryParams+=key+"="+filters[key];
            if(i!==Object.keys(filters).length){
                queryParams+="&";
            }
            i++;
        })
    }
  return (dispatch) => {
    dispatch({ type: "LOADER_IN_PROGRESS" });
    axios
      .get(GLOBAL.getEvents+queryParams)
      .then((events) => {
        if (!events) {
          throw Error(events.statusText);
        }
        return events;
      })
      .then((events) => {
        dispatch({
          type: "ON_CALL_CALENDER_EVENT_DATA_LIST",
          events: events.data,
        });
        dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
      })
      .catch((err) => {
        dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
        console.log(err);
      });
  };
}

export function loadShiftListByGroup(groupId) {
  return (dispatch) => {
    axios
      .get(`${GLOBAL.getShifts}?scheduleId=isNotNull&groupId=${groupId}&page=0&size=500`)
      .then((events) => {
        if (!events) {
          throw Error(events.statusText);
        }
        return events;
      })
      .then((events) => {
        dispatch({
          type: "ON_CALL_CALENDER_SHIFT_LIST_DATA_BY_GROUP",
          events: events.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
}