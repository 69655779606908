
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Button } from 'react-bootstrap';
import {useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {getEditedUserDetails} from "../../../../../actions/foundation/userAction";
import { reduxForm } from "redux-form";
import { useParams } from "react-router";
import axios from "axios";
import swal from 'sweetalert';

import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import ListLoader from "../../../../common/loaders/ListLoader";
import {LuFileText} from "react-icons/lu";
import { PiUserSwitch } from "react-icons/pi";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';

const HeaderSec = (props) => {
  const { isOrgInfoMandatory = true } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const tr = useSelector((state) => state.spcmReducer.tr);
    const clickSyncButton = useSelector((state) => state.notSyncRed);
    const syncButton = useSelector((state) => state.SyncRed);
    const loader = useSelector((state) => state.commonLoader); 
    const [syncEnable, setSyncEnable] = useState();
    const [message, setMessage] = useState();
    const context = useContext(MyContext);
    const closeButton = useRef(null);
    const saveObjRef = useRef(null);

    useEffect(() => {
      context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
      context.newTabPrevent();
    }, []);

    let { handleSubmit, submitting } = props;
    const params = useParams();
    let userId = params.id;
    let isValid = true;
    let formData = new FormData();
    let companyID;
  useEffect(() => {
    if (clickSyncButton && clickSyncButton == "User's account is not synced with IAM. Please click on the Sync button to sync the user's record with IAM.") {
      setSyncEnable(true);
      setMessage(clickSyncButton);
    }else if (clickSyncButton && clickSyncButton == "Cloud Directory is not configured to sync user details.") {
      setSyncEnable(false);
      setMessage(clickSyncButton);
    }

    if (syncButton && syncButton == "User's account is successfully synced with IAM.") {
      setSyncEnable(false);

      setMessage(syncButton);

    }
  }, [clickSyncButton,syncButton]);

      const syncFunc = async (values) => {
          if (values.companyId) {
            companyID = values.companyId;
          } else {
            companyID = props.companyType[0].id;
            values.companyName = props.companyType[0].label;
          
          }
          let postjson;
          postjson =
          {
            "userId": userId,
            "appId": null,
            "username": values.username,
            "password": values.password ? values.password : null,
            "companyId": companyID,
            "companyName": values.companyName ? values.companyName : '',
            "supportCompany": null,
            "supportCompanyName": null,
            "groupId": null,
            "groupAllId": null,
            "roleId": null,
            "roleAllId": null,
            "functionId": null,
            "title": values.title ? values.title : null,
            "firstname": values.firstname,
            "middlename": values.middlename ? values.middlename : "",
            "lastname": values.lastname,
            "fullname": values.fullname,
            "status": values.status,
            "photo": null,
            "email": values.email,
            "employeeNumber": values.employeeNumber ? values.employeeNumber : "",
            "employeeBand": values.employeeBand ? values.employeeBand : '',
            "currency": null,
            "l1Head": null,
            "l2Head": null,
            "l3Head": null,
            "l4Head": null,
            "gender": values.gender ? values.gender : null,
            "businessPhone": values.businessPhone ? values.businessPhone : null,
            "mobilePhone": values.mobilePhone ? values.mobilePhone : null,
            "contactPhone": values.contactPhone ? values.contactPhone : null,
            "building": values.building ? values.building : null,
            "cubicle": values.cubicle ? values.cubicle : null,
            "authenticationSource": values.authenticationSource ? values.authenticationSource : null,
            "organizationId": values.organizationId ? values.organizationId : 0,
            "organizationName": null,
            "departmentId": values.departmentId ? values.departmentId : 0,
            "departmentName": null,
            "costcenterId": values.costcenterId ? values.costcenterId : '',
            "costcenterName": values.costcenterName ? values.costcenterName : null,
            "contentType": null,
            "usergroupId": null,
            "usergroupName": null,
            "associatedCompanies": null,
            "lastUpdateDate": null,
            "bestSelling": null,
            "newLaunch": null,
            "comingSoon": null,
            "free": null,
            "myFavourite": null,
            "catwise": null,
            "prefId": null,
            "prefValue": null,
            "featured": null,
            "languagePreference": null,
            "alternateEmail": null,
            "alternatePhone": null,
            "alternateLocation": null,
            "container": null,
            "id": 0,
            "supportGroup": null,
            "technicalOwner": null,
            "businessOwner": null,
            "assignedTo": null,
            "externalUserId": null,
            "isOrgTabClicked": null,
            "hometab_preference": [],
            "profileImageName": null,
            "imageName": null,
            "imageUrl": null,
            "companyTypeCheck": null,
            "locationId": values.locationId ? values.locationId : '',
            "locationName": values.locationName ? values.locationName : null,
            "cwlId": values.cwlId ? values.cwlId : '',
            "cwlName": values.cwlName ? values.cwlName : null,
            "managerId": values.managerId ? values.managerId : null,
            "userTypeId": values.userTypeId ? values.userTypeId : null,
            "dateformat": values.dateformat,
            "datasource": values.datasource ? values.datasource : null,
            "vip": values.vip ? values.vip : null,
            "pwdNeedsReset": null,
            "pwdPolicyUser": null,
            "personCode": null,
            "notificationNotRequired": null,
            "createDate": values.createDate ? values.createDate : null,
            "createdBy": values.createdBy ? values.createdBy : null,
            "lastUpdatedDate": values.lastUpdatedDate ? values.lastUpdatedDate : 0,
            "lastUpdatedBy": values.lastUpdatedBy ? values.lastUpdatedBy : null,
            "loginTime": null,
            "role": null,
            "pagesize": 0,
            "eventNameDefault": null,
            "filterby": null,
            "timeZone": values.timeZone,
            "timezoneid": null,
            "actionVal": null,
            "managerName": values.managerName ? values.managerName : null,
            "checkActionMode": null,
            "baseCurrency": values.baseCurrency,
            "baseCurrencytext": null,
            "timeZonetext": null,
            "dateformattext": null,
            "languagetext": null,
            "language": values.language,
            "companyList": null,
            "autoApprove": values.autoApprove ? values.autoApprove : null,
            "cityId": null,
            "stateId": null,
            "countryId": null,
            "managerEmployeeNumber": null,
            "managerEmail": null,
            "uniqueKey": null,
            "resetPassword": props.resetPasswordVal,
            "autoGeneratePassword": props.autoGeneratePasswordVal,
            "forcePasswordChange": props.forcePasswordChangeVal,
            "userIdenS": values.password ? values.password : null,
            "file": null,
            "profilePicTempURL": null,
            "roleName": null,
            "groupName": null,
            "roleNames": null,
            "groupType": null,
            "prvAccs": null,
            "prvJustification": null,
            "groupJustification": null,
            "roleJustification": null,
            "filterbytext": null
          }
          let Id = userId
          return axios.get("/api/updateUserAppId/" + Id).then((resp) => {
            console.log("SYNC RESPONSE", resp)
            return axios.post("/api/editUserDetails/" + 'UPDATE', postjson).then((response) => {
              if (response.status == 201) {
                swal({
                  text: "User's account is successfully synced with IAM",
                  button: "OK",
                });
              }
              setMessage("User's account is successfully synced with IAM");
              props.updateAllData();
           
            })
          }).catch((error) => { });
      }


const submit = async (values) => {
        console.log("userValues", values);
        console.log("PROPS_COMPANY", props.companyType);
        var phoneno =/^\+?\d+$/;
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;    
        let specialCharRegex = /[^-_'.@&0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz]/
        if(values.companyId){          
          companyID = values.companyId;
        }else{          
          companyID = props.companyType[0].id;
          values.companyName = props.companyType[0].label;
          
        }
//error conditions 
        if (values.file) {
          formData.append('file', values.file[0]);    
        } else {
          formData.append('file', "");
        }
     // user -personal form
      if ( !values.email || values.email == ''|| values.email.trim() == '') {
        props.setEmailErr("error");
        isValid = false;
      } else {
        if(values.email.trim().match(validRegex)) {
          props.setEmailErr("");
        } else {
          props.setEmailErr("error");
          isValid = false;
        }
      }
      if(values.businessPhone && values.businessPhone.trim() !== "") {
        if (values.businessPhone.match(phoneno)) {
          props.setBusinessErr("");
        } else {
          props.setBusinessErr("error");
          isValid = false;
        }
      } else {
        props.setBusinessErr("");
      }
      if(values.mobilePhone && values.mobilePhone.trim() !== "") {
        if (values.mobilePhone.match(phoneno)) {
          props.setMobileErr("");
        } else {
          props.setMobileErr("error");
          isValid = false;
        }
      } else {
        props.setMobileErr("");
      }
      if(values.contactPhone && values.contactPhone.trim() !== "") {
        if (values.contactPhone.match(phoneno)) {
          props.setcontactErr("");
        } else {
          isValid = false;
          props.setcontactErr("error");
        }
      } else {
        props.setcontactErr("");
      }
      // user form
      // if(!props.companyValue || props.companyValue.length == 0){
      if(!companyID){
        props.setCompanyErrorColor('error');
        isValid = false ;
      }
      if ( !values.username || values.username == ''|| values.username.trim() == '' || values.username.trim().match(specialCharRegex)) {
        props.setUsernameErr("error");
        isValid = false;
      } else {
        props.setUsernameErr("");
      }
      if ( !values.firstname || values.firstname == ''|| values.firstname.trim() == '' || values.firstname.trim().match(specialCharRegex)) {
        props.setFirstName("error");
        isValid = false;
      } else {
        props.setFirstName("");
      }
      if ( !values.lastname || values.lastname == ''|| values.lastname.trim() == '' || values.lastname.trim().match(specialCharRegex)) {
        props.setLastnameErr("error");
        isValid = false;
      } else {
        props.setLastnameErr("");
      }
      if (values.middlename && values.middlename.trim() !== "") {
        if (values.middlename.trim().match(specialCharRegex)) {
          props.setMiddlenameErr("error");
          isValid = false;
        } else {
          props.setMiddlenameErr("");
        }
      }
      else {
        props.setMiddlenameErr("");
      }
      //user - information form

      if ( !values.userTypeId || values.userTypeId == ''|| values.userTypeId == 'null') {
        props.setUserTypeErr("error");
        isValid = false;
      } else {
        props.setUserTypeErr("");
      }
      if ( !values.dateformat || values.dateformat == ''|| values.dateformat == 'null') {
        props.setDateFormErr("error");
        isValid = false;
      } else {
        props.setDateFormErr("");
      }
      if ( !values.status || values.status == ''|| values.status == 'null') {
        props.setStatusErr("error");
        isValid = false;
      } else {
        props.setStatusErr("");
      }
      if ( !values.timeZone || values.timeZone == ''|| values.timeZone == 'null') {
        props.setTimeZoneErr("error");
        isValid = false;
      } else {
        props.setTimeZoneErr("");
      }
      if ( !values.language || values.language == ''|| values.language == 'null') {
        props.setLanguageErr("error");
        isValid = false;
      } else {
        props.setLanguageErr("");
      }
      if ( !values.baseCurrency || values.baseCurrency == ''|| values.baseCurrency == 'null') {
        props.setCurrencyErr("error");
        isValid = false;
      } else {
        props.setCurrencyErr("");
      }
      if (!values.authenticationSource || values.authenticationSource == ""|| values.authenticationSource == 'null') {
        props.setAuthSource("error");
        isValid = false;
      } else {
        props.setAuthSource("");
      }
       //user -organization Form
       if(isOrgInfoMandatory == true){
          if (!values.organizationId || values.organizationId == '' || values.organizationId == '') {
            props.setOrgzErr("error");
            isValid = false;
          } else {
            props.setOrgzErr("");
          }
    
          if (!values.departmentId || values.departmentId == '' || values.departmentId == '') {
            props.setDepErr("error");
            isValid = false;
          } else {
            props.setDepErr("");
          }
    
          if ( !values.locationId || values.locationId == ''|| values.locationId == 'null') {
            props.setLocationErrColor("error");
            isValid = false;
          } else {
            props.setLocationErrColor("");
          }
    
          if ( !values.costcenterId || values.costcenterId == ''|| values.costcenterId == 'null') {
            props.setCCErrColor("error");
            isValid = false;
          } else {
            props.setCCErrColor("");
          }
    
          if ( !values.cwlId || values.cwlId == ''|| values.cwlId == 'null') {
            props.setCWLColorErr("error");
            isValid = false;
          } else {
            props.setCWLColorErr("");
          }
       }

      //  if (values?.userPassword && typeof values?.userPassword == 'string' && values?.userPassword.trim().length > 0) {
      //     if(props.validatePassword(values.userPassword) == false){
      //       props.setProfileErr('error');
      //       isValid = false;
      //     }
      //     else{
      //       props.setProfileErr('');
      //     }
      //   }
      if (props.resetPasswordVal && !props.autoGeneratePasswordVal) {
          if(props.validatePassword(values.userPassword) == false){
            props.setProfileErr('error');
            isValid = false;
          }
          else{
            props.setProfileErr('');
          }
        }
      
      if (isValid == true) {
        let postjson;
        postjson =
        {
          "userId": userId,
          "appId": null,
          "username": values.username,
          "password": values.password ? values.password : null,
          "companyId": companyID,
          "companyName": values.companyName ? values.companyName : '',
          "supportCompany": null,
          "supportCompanyName": null,
          "groupId": null,
          "groupAllId": null,
          "roleId": null,
          "roleAllId": null,
          "functionId": null,
          "title": values.title ? values.title : null,
          "firstname": values.firstname,
          "middlename": values.middlename ? values.middlename : "",
          "lastname": values.lastname,
          "fullname": values.fullname,
          "status": values.status,
          "photo": null,
          "email": values.email,
          "employeeNumber": values.employeeNumber ? values.employeeNumber : "",
          "employeeBand": values.employeeBand ? values.employeeBand : '',
          "currency": null,
          "l1Head": null,
          "l2Head": null,
          "l3Head": null,
          "l4Head": null,
          "gender": values.gender ? values.gender : null,
          "businessPhone": values.businessPhone ? values.businessPhone : null,
          "mobilePhone": values.mobilePhone ? values.mobilePhone : null,
          "contactPhone": values.contactPhone ? values.contactPhone : null,
          "building": values.building ? values.building : null,
          "cubicle": values.cubicle ? values.cubicle : null,
          "authenticationSource": values.authenticationSource ? values.authenticationSource : null,
          "organizationId": values.organizationId ? values.organizationId : 0,
          "organizationName": null,
          "departmentId": values.departmentId ? values.departmentId : 0,
          "departmentName": null,
          "costcenterId": values.costcenterId ? values.costcenterId : '',
          "costcenterName": values.costcenterName ? values.costcenterName : null,
          "contentType": null,
          "usergroupId": null,
          "usergroupName": null,
          "associatedCompanies": null,
          "lastUpdateDate": null,
          "bestSelling": null,
          "newLaunch": null,
          "comingSoon": null,
          "free": null,
          "myFavourite": null,
          "catwise": null,
          "prefId": null,
          "prefValue": null,
          "featured": null,
          "languagePreference": null,
          "alternateEmail": null,
          "alternatePhone": null,
          "alternateLocation": null,
          "container": null,
          "id": 0,
          "supportGroup": null,
          "technicalOwner": null,
          "businessOwner": null,
          "assignedTo": null,
          "externalUserId": null,
          "isOrgTabClicked": null,
          "hometab_preference": [],
          "profileImageName": null,
          "imageName": null,
          "imageUrl": null,
          "companyTypeCheck": null,
          "locationId": values.locationId ? values.locationId : '',
          "locationName": values.locationName ? values.locationName : null,
          "cwlId": values.cwlId ? values.cwlId : '',
          "cwlName": values.cwlName ? values.cwlName : null,
          "managerId": values.managerId ? values.managerId : null,
          "userTypeId": values.userTypeId ? values.userTypeId : null,
          "dateformat": values.dateformat,
          "datasource": values.datasource ? values.datasource : null,
          "vip": values.vip ? values.vip : null,
          "pwdNeedsReset": null,
          "pwdPolicyUser": null,
          "personCode": null,
          "notificationNotRequired": null,
          "createDate": values.createDate ? values.createDate : null,
          "createdBy": values.createdBy ? values.createdBy : null,
          "lastUpdatedDate": values.lastUpdatedDate ? values.lastUpdatedDate : 0,
          "lastUpdatedBy": values.lastUpdatedBy ? values.lastUpdatedBy : null,
          "loginTime": null,
          "role": null,
          "pagesize": 0,
          "eventNameDefault": null,
          "filterby": null,
          "timeZone": values.timeZone,
          "timezoneid": null,
          "actionVal": null,
          "managerName": values.managerName ? values.managerName : null,
          "checkActionMode": null,
          "baseCurrency": values.baseCurrency,
          "baseCurrencytext": null,
          "timeZonetext": null,
          "dateformattext": null,
          "languagetext": null,
          "language": values.language,
          "companyList": null,
          "autoApprove": values.autoApprove ? values.autoApprove : null,
          "cityId": null,
          "stateId": null,
          "countryId": null,
          "managerEmployeeNumber": null,
          "managerEmail": null,
          "uniqueKey": null,
          // "resetPassword": props.resetPasswordVal ? props.resetPasswordVal : null,
          // "autoGeneratePassword": props.autoGeneratePasswordVal ? props.autoGeneratePasswordVal : null,
          // "forcePasswordChange": props.forcePasswordChangeVal ? props.forcePasswordChangeVal : null,
          "resetPassword": props.resetPasswordVal ,
          "autoGeneratePassword": props.autoGeneratePasswordVal,
          "forcePasswordChange": props.forcePasswordChangeVal,
          "userIdenS": values.userPassword ? values.userPassword : null,
          "file": null,
          "profilePicTempURL": null,
          "roleName": null,
          "groupName": null,
          "roleNames": null,
          "groupType": null,
          "prvAccs": null,
          "prvJustification": null,
          "groupJustification": null,
          "roleJustification": null,
          "filterbytext": null
        };
        
        props.setProfileErr('');


      if (!values.file || typeof values.file == "string") {
        console.log("IF ====");
        return axios
          .post("/api/editUserDetails/" + "=", postjson)
          .then((response) => {
            console.log("response++++", response);
            if(response.data.userAlreadyExist && response.data.userAlreadyExist == "User ID already exists" ){
              swal({
                text: "Username already exists",
                button: "OK",
              });
              return false;
            }
            else if(response.data.emailAlreadyExist && response.data.emailAlreadyExist == "Email ID already exists" ){
              swal({
                text: "Email ID already exists",
                button: "OK",
              });
              return false;
            }
            else if (response.status == 201) {
              swal({
                text: (response?.data?.userAlreadyExist) ? 'Username already exists' : 'User has been Updated',
                button: "OK",
              });
              if(!response?.data?.userAlreadyExist){
                props.updateAllData();
              }
            }

            // else if (response.data == "Name already exists") {
            //   swal({
            //     text: response.data,
            //     button: "OK",
            //   });
            //   return false;
            // }
          })
          .catch((error) => {});
      } else if (typeof values.file == "object" && values.file == null) {
        console.log("IF ELSE 0");
        return axios
        .post("/api/editUserDetails/"+"=", postjson)
          .then((response) => {
            if (response.status == 201) {
              swal({
                text: (response?.data?.userAlreadyExist) ? 'Username already exists' : 'User has been Updated',
                button: "OK",
              });
              if(!response?.data?.userAlreadyExist){
                props.updateAllData();
              }
            }
            // } else if (response.data == "Name already exists") {
            //   swal({
            //     text: response.data,
            //     button: "OK",
            //   });
            //   return false;
            // }
          })
          .catch((error) => {});
      } else if (typeof values.file == "object" && values.file.length > 0) {
        console.log("IF ELSE 1");

        return axios
          .post("/api/savemyProfileuploadUserImage" + "/" + userId + "/" + companyID, formData)
          .then((resp) => {
            console.log("Upload Image", resp);
            if (resp.data == "File type not supported") {
              swal({
                text: "Please upload an image of supported format",
                button: "OK",
              });
            } 
            else {
              props.setFileURL(resp.data)
              return axios
              .post("/api/editUserDetails/"+"=", postjson)
                .then((response) => {
                  if (response.status == 201) {
                    swal({
                      text: (response?.data?.userAlreadyExist) ? 'Username already exists' : 'User has been Updated',
                      button: "OK",
                    });
                    if(!response?.data?.userAlreadyExist){
                      props.updateAllData();
                    }
                  dispatch(getEditedUserDetails(userId));

                  }

                })
                .catch((error) => {});
            }
          });
      } else if (typeof values.file == "object" && values.file.length == 0) {
            console.log("LAST IF++++");

        return axios
        .post("/api/editUserDetails/"+"=", postjson)
          .then((response) => {
            if (response.status == 201) {
              swal({
                text: (response?.data?.userAlreadyExist) ? 'Username already exists' : 'User has been Updated',
                button: "OK",
              });
              if(!response?.data?.userAlreadyExist){
                props.updateAllData();
              }
            }
          })
          .catch((error) => {});
      }
    }
}

    return (
      <>
        <Row className="margin-b-15">
          <Col sm={8} xs={7}>
            <h1 bsClass="" className="sPageHeading1">
              {tr["User"]}
            </h1>
          </Col>
          <Col sm={4} xs={5}>
            <div className="paHedDisplay text-r">
              { message != ''? <span className="f-size-10 f-bold">{props.EDITUSERData.status == 'Y'?  props.EDITUSERData.authenticationSource !== "ActiveDirectory" ? message : '': ''}</span> : ''} 
              <div className="paHedFilter">
                {!props.isRightSideVisible ?
                <Button
                    bsPrefix=" "
                    role="button"
                    className="myBt d-md-none fillBtn"
                    title="Details"
                    onClick={() => {
                    props.showRightSideFun(true);
                    props.setCurrentView('tabView');
                    }}
                  >
                    <LuFileText/>
                  </Button> : null }               
                 {props.EDITUSERData.status == 'Y' ? props.EDITUSERData.authenticationSource !== "ActiveDirectory" ?(syncEnable == true  &&
                  <Button
                    bsPrefix=" "
                    role="button"
                    className="myBt fillBtn"
                    title="Sync"
                    onClick={handleSubmit(syncFunc)}
                  >
                    <PiUserSwitch />
                  </Button> ): '':''}
                <Link
                  bsPrefix=" "
                  id="hdID"
                  to="javascript:void(0)"
                  className="myBt plus ctrlKeyPrevent"
                  disabled={props.loader}
                  onClick={handleSubmit(submit)}
                  title={tr["Save"]}
                  ref={saveObjRef}
                >
                  <span>
                    {props.loader ? (
                      <ImSpinner6 className="icn-spinner" />
                    ) : (
                      <IoSaveOutline />
                    )}
                  </span>
                </Link>
                <Link
                  bsPrefix=" "
                  to="/user"
                  className="myBt cancel fillBtn ctrlKeyPrevent"
                  title={tr["Cancel"]}
                  ref={closeButton}
                >
                  <IoClose />
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
}

export default reduxForm({
    form: "EditUserform",
})(HeaderSec);