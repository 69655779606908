
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from 'react-redux';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import AmsHomeHeader from './amsHome/AmsHomeHeader.js';
import AmsHomeListView from './amsHome/AmsHomeListView.js';
import AmsHomeShortDesc from './amsHome/AmsHomeShortDesc.js';
import { getAllRuleConfigurationsData } from '../../actions/amsActions/amsActions.js';


const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home") homepagelocation = "/" + homepagelocation;

const AmsHomeIndex = (props) => {
	const dispatch = useDispatch();
	const tr = useSelector(state => state.spcmReducer.tr);
	const [subMenu, setSubMenu] = useState('4');
	const [showHideAlfyData, setShowHideAlfyData] = useState(false);
	const [showHideRightNavBar, setShowHideRightNavBar] = useState(false);
	const [ruleId, setRuleId] = useState('');
	const [companyName, setCompanyName] = useState('');
	const [moduleName, setModuleName] = useState('');
	const [ruleName, setRuleName] = useState('');
	const [weight, setWeight] = useState('');
	const [assignmentCompanyName, setAssignmentCompanyName] = useState('');
	const [assignmentGroupName, setAssignmentGroupName] = useState('');
	const [serviceNameState, setServiceNameState] = useState('');
	const [ciServiceName, setCiServiceName] = useState('');
	const [serviceBased, setServiceBased] = useState('');
	const [status, setStatus] = useState('');
	const [searchFilter, setSearchFilter] = useState('serviceName');
	const [checkSearchtextField, setCheckSearchtextField] = useState(true);
	const [valueTextSearchInput, setValueTextSearchInput] = useState('');
	const [valueSearchInput, setValueSearchInput] = useState('');
	const [isRightSideVisible, setIsRightSideVisible] = useState(false);
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const [rowIndex, setRowIndex] = useState(0);
	const [searchParamsValue, setSearchParamsValue] = useState({});
	const [searchKey, setSearchKey] = useState('');
	const [searchValue, setSearchValue] = useState('');
	const [pattern, setPattern] = useState('');
	const [nameSearchInputState, setNameSearchInputState] = useState('');
	const [consumerCompanySearchInputState, setConsumerCompanySearchInputState] = useState('');
	const [serviceCiSearchInputState, setServiceCiSearchInputState] = useState('');
	const [supCompanySearchInputState, setSupCompanySearchInputState] = useState('');
	const [selectedModuleValueState, setSelectedModuleValueState] = useState([]);
	const [selectedAssignmentForValueState, setSelectedAssignmentForValueState] = useState([]);
	const [selectedSupGroupValueState, setSelectedSupGroupValueState] = useState([]);
	const [selectedStatusValueState, setSelectedStatusValueState] = useState([]);
	const [statusValue, setStatusValue] = useState([]);
	const [assignmentForValue, setAssignmentForValue] = useState([]);
	const [moduleValue, setModuleValue] = useState([]);
	const [supGroupValue, setSupGroupValue] = useState([]);
	const [startDate, setStartDate] = useState({});
	const [endDate, setEndDate] = useState({});
	const [isClearAllfilter, setIsClearAllfilter] = useState(false);
	const [eventName, setEventName] = useState('');
	const [ciClassName, setCiClassName] = useState('');
	const [ruleCondition, setRuleCondition] = useState({});
	const [isContextual, setIsContextual] = useState(true);
	const [activePanelKey, setActivePanelKey] = useState('0');
	const [inputRef, setinputRef] = useState('');
	const [panelResize,setPanelResize] = useState();
	const [isModuleOpen,setModuleOpen] = useState(false);
	const [isAssgnOpen,setAssgnOpen] = useState(false);
	const [isSupportGrpOpen,setSupportGrpOpen] = useState(false);
	const [isStatusOpen,setStatusOpen] = useState(false);
	const allRuleConfigurationsData = useSelector(state => state.allRuleConfigurationsData);
	const amsBoardShowLoader = useSelector(state => state.amsBoardShowLoader);

	useEffect(()=> {
		let str = {};
		str.searchByList = "";
		str.multipleValueList = "";
		str.patternList = "";
		str.orderBy = "desc";
		str.sortBy = "modifiedOn";
		str.currentPage = 0;
		str.size = 10;
		dispatch(getAllRuleConfigurationsData(str, [], [], [], [], true));
	},[])

	const showRightSide = (value)=> {
		setIsRightSideVisible(value);
	}

	const showActiveRow = (index) => {
		setRowIndex(index);
	}

	const updatePagination = (page, limit) =>{
		setPage(page)
		setPageSize(limit)
	}

	const setNameSearchInput = (value) => {
		setNameSearchInputState(value );
	}

	const setConsumerCompanySearchInput = (value) => {
		setConsumerCompanySearchInputState(value);
	}

	const setSupCompanySearchInput = (value) => {
		setSupCompanySearchInputState(value);
	}

	const setServiceCiSearchInput = (value) => {
		setServiceCiSearchInputState(value);
	}

	const setSelectedAssignmentForValue = (value) => {
		setSelectedAssignmentForValueState(value);
	}

	const setSelectedModuleValue = (value) => {
		setSelectedModuleValueState(value);
	}

	const setSelectedStatusValue = (value) => {
		setSelectedStatusValueState(value);
	}

	const setSelectedSupGroupValue = (value) => {
		setSelectedSupGroupValueState(value);
	}

	const setStartAndEndDate = (startDate, endDate) => {
		setStartDate(startDate);
		setEndDate(endDate)
	}

	const setRef = (ref) => {
	    setinputRef(ref);
	}

	const isClearAllFilterFunc =(value)=>{
		setIsClearAllfilter(value);
	}

	const handlePanelClick=(activePanelKey)=>{
		 setActivePanelKey(activePanelKey);
	}

	const setSearchParams=(searchParams, key, value, pattern, module, assignmentFor, supportGroup, status)=> {
		setSearchParamsValue(searchParams);
		setSearchKey(key);
		setSearchValue(value);
		setPattern(pattern);
		setModuleValue(module);
		setAssignmentForValue(assignmentFor);
		setSupGroupValue(supportGroup);
		setStatusValue(status);
		
	}

	const setSelectedSearchFilter = (value)=>{
		setSearchFilter (value);
		if (value == 'serviceName') {
			setCheckSearchtextField (true);
		}
		else if (value == "ciName") {
			setCheckSearchtextField (true);
		}
		else {
			setCheckSearchtextField (false);
		}
	}

	const changeSearchInput=(value)=>{
		if (checkSearchtextField) {
			 setValueTextSearchInput(value);
		} else
			 setValueSearchInput(value);
	}

	const showHideAlfy=(value)=> {
		setShowHideAlfyData(value);
	}

	const showHideRightNav=(value) =>{
		setShowHideRightNavBar(!showHideRightNavBar);
	}

	const multiSelectOpenFun = (keyName, val) => {
		switch (keyName) {
		  	case "amsModule":
				{setModuleOpen(val);
				setAssgnOpen(false);
				setSupportGrpOpen(false);
				setStatusOpen(false);
				};
			break;
			case "amsAssgn":
				{setModuleOpen(false);
				setAssgnOpen(val);
				setSupportGrpOpen(false);
				setStatusOpen(false);
				};
			break;
			case "amsSupportGrp":
				{setModuleOpen(false);
				setAssgnOpen(false);
				setSupportGrpOpen(val);
				setStatusOpen(false);
				};
			break;
			case "amsStatus":
				{setModuleOpen(false);
				setAssgnOpen(false);
				setSupportGrpOpen(false);
				setStatusOpen(val);
				};
			break;
			case "allFalse":
				{setModuleOpen(false);
				setAssgnOpen(false);
				setSupportGrpOpen(false);
				setStatusOpen(false);
				};
			break;
		}
	  }

	const getTaskDetails=(ruleId, companyName, moduleName, ruleName, weight, assignmentCompanyName, assignmentGroupName, serviceName, ciServiceName, serviceBased, status,eventName,ciClassName,ruleCondition,isContextual) =>{
		
		setRuleId(ruleId);
		setCompanyName(companyName);
		setModuleName(moduleName);
		setRuleName(ruleName);
		setWeight(weight);
		setAssignmentCompanyName(assignmentCompanyName);
		setAssignmentGroupName(assignmentGroupName);
		setServiceNameState(serviceName);
		setCiServiceName(ciServiceName);
		setServiceBased(serviceBased);
		setStatus(status);
		setEventName(eventName);
		setCiClassName(ciClassName);
		setRuleCondition(ruleCondition);
		setIsContextual(isContextual);
		
	}
		let colWidth = "";
		isRightSideVisible ? colWidth = 8 : colWidth = 12;
		return (
			<div>

				<div className="container-fluid margin-t-10 minHeightWIB" bsClass="">
					{/* Home Page blue bar Section Start*/}
					<AmsHomeHeader
						tr={tr}
						isRightSideVisible={isRightSideVisible}
						showRightSide={showRightSide}

						nameSearchInput={nameSearchInputState}
						consumerCompanySearchInput={consumerCompanySearchInputState}
						serviceCiSearchInput={serviceCiSearchInputState}
						supCompanySearchInput={supCompanySearchInputState}
						selectedModuleValue={selectedModuleValueState}
						selectedAssignmentForValue={selectedAssignmentForValueState}
						selectedSupGroupValue={selectedSupGroupValueState}
						selectedStatusValue={selectedStatusValueState}
						setNameSearchInput={setNameSearchInput}
						setConsumerCompanySearchInput={setConsumerCompanySearchInput}
						setSupCompanySearchInput={setSupCompanySearchInput}
						setServiceCiSearchInput={setServiceCiSearchInput}
						setSelectedAssignmentForValue={setSelectedAssignmentForValue}
						setSelectedModuleValue={setSelectedModuleValue}
						setSelectedStatusValue={setSelectedStatusValue}
						setSelectedSupGroupValue={setSelectedSupGroupValue}
						startDate={startDate}
						endDate={endDate}
						setStartAndEndDate={setStartAndEndDate}
						updatePagination={updatePagination}
						pageSize={pageSize}
						page={page}
						showActiveRow={showActiveRow}
						rowIndex={rowIndex}
						setSearchParams={setSearchParams}
						searchKey={searchKey}
						pattern={pattern}
						searchValue={searchValue}
						searchParamsValue={searchParamsValue}
						statusValue={statusValue}
						assignmentForValue={assignmentForValue}
						moduleValue={moduleValue}
						supGroupValue={supGroupValue}
						setRef={setRef}
						isClearAllfilter={isClearAllfilter}
						isClearAllFilterFunc={isClearAllFilterFunc}
						dateRangePickerRef={inputRef}
						
					/>
					{/* Home Page blue bar Section end*/}

					
					<PanelGroup direction="horizontal" className={(isModuleOpen || isAssgnOpen || isSupportGrpOpen || isStatusOpen) && ((allRuleConfigurationsData?.data?.length < 6) || (amsBoardShowLoader.loading)) ? "formGroupB10 myProfileLabel myOrders overflow-clip" : "formGroupB10 myProfileLabel myOrders"}>
					<Panel id="sidebar" minSize={33} order={1} defaultSize={isRightSideVisible ? 67 : 100} onResize={(size) => setPanelResize(size)} className={(isModuleOpen || isAssgnOpen || isSupportGrpOpen || isStatusOpen) && ((allRuleConfigurationsData?.data?.length < 6) || (amsBoardShowLoader.loading)) ? "overflow-clip" : (isRightSideVisible ? "isShowLeftPanel rwLeftPart" : "rwLeftPart") } >
						<AmsHomeListView
							tr={tr}
							getTaskDetails={getTaskDetails}
							searchFilter={searchFilter}
							setSelectedSearchFilter={setSelectedSearchFilter}
							checkSearchtextField={checkSearchtextField}
							valueTextSearchInput={valueTextSearchInput}
							valueSearchInput={valueSearchInput}
							changeSearchInput={changeSearchInput}
							isRightSideVisible={isRightSideVisible}
							showRightSide={showRightSide}
							nameSearchInput={nameSearchInputState}
							consumerCompanySearchInput={consumerCompanySearchInputState}
							serviceCiSearchInput={serviceCiSearchInputState}
							supCompanySearchInput={supCompanySearchInputState}
							selectedModuleValue={selectedModuleValueState}
							selectedAssignmentForValue={selectedAssignmentForValueState}
							selectedSupGroupValue={selectedSupGroupValueState}
							selectedStatusValue={selectedStatusValueState}
							setNameSearchInput={setNameSearchInput}
							setConsumerCompanySearchInput={setConsumerCompanySearchInput}
							setSupCompanySearchInput={setSupCompanySearchInput}
							setServiceCiSearchInput={setServiceCiSearchInput}
							setSelectedAssignmentForValue={setSelectedAssignmentForValue}
							setSelectedModuleValue={setSelectedModuleValue}
							setSelectedStatusValue={setSelectedStatusValue}
							setSelectedSupGroupValue={setSelectedSupGroupValue}
							startDate={startDate}
							endDate={endDate}
							setStartAndEndDate={setStartAndEndDate}
							updatePagination={updatePagination}
							page={page}
							pageSize={pageSize}
							showActiveRow={showActiveRow}
							rowIndex={rowIndex}
							setSearchParams={setSearchParams}
							searchKey={searchKey}
							pattern={pattern}
							searchValue={searchValue}
							searchParamsValue={searchParamsValue}
							statusValue={statusValue}
							assignmentForValue={assignmentForValue}
							moduleValue={moduleValue}
							supGroupValue={supGroupValue}
							setRef={setRef}
							isClearAllfilter={isClearAllfilter}
							isClearAllFilterFunc={isClearAllFilterFunc}
							dateRangePickerRef={inputRef}
							activePanelKey={activePanelKey}
							handlePanelClick={handlePanelClick}
							panelResize={panelResize}
							isModuleOpen={isModuleOpen}
							isAssgnOpen={isAssgnOpen}
							isSupportGrpOpen={isSupportGrpOpen}
							isStatusOpen={isStatusOpen}
							multiSelectOpenFun={multiSelectOpenFun}
						/>
					</Panel>
					{isRightSideVisible ?
						<>
							<PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
							<Panel minSize={33} order={2} defaultSize={33}>
								<div className="stickyArea rBoxStyle">
									{/* Xsm BreakFix Action Section Start*/}
									<AmsHomeShortDesc
										tr={tr}
										ruleId={ruleId}
										companyName={companyName}
										moduleName={moduleName}
										ruleName={ruleName}
										weight={weight}
										assignmentCompanyName={assignmentCompanyName}
										assignmentGroupName={assignmentGroupName}
										serviceName={serviceNameState}
										ciServiceName={ciServiceName}
										serviceBased={serviceBased}
										status={status}
										isRightSideVisible={isRightSideVisible}
										showRightSide={showRightSide}
										nameSearchInput={nameSearchInputState}
										consumerCompanySearchInput={consumerCompanySearchInputState}
										serviceCiSearchInput={serviceCiSearchInputState}
										supCompanySearchInput={supCompanySearchInputState}
										selectedModuleValue={selectedModuleValueState}
										selectedAssignmentForValue={selectedAssignmentForValueState}
										selectedSupGroupValue={selectedSupGroupValueState}
										selectedStatusValue={selectedStatusValueState}
										setNameSearchInput={setNameSearchInput}
										setConsumerCompanySearchInput={setConsumerCompanySearchInput}
										setSupCompanySearchInput={setSupCompanySearchInput}
										setServiceCiSearchInput={setServiceCiSearchInput}
										setSelectedAssignmentForValue={setSelectedAssignmentForValue}
										setSelectedModuleValue={setSelectedModuleValue}
										setSelectedStatusValue={setSelectedStatusValue}
										setSelectedSupGroupValue={setSelectedSupGroupValue}
										startDate={startDate}
										endDate={endDate}
										setStartAndEndDate={setStartAndEndDate}
										updatePagination={updatePagination}
										page={page}
										pageSize={pageSize}
										showActiveRow={showActiveRow}
										rowIndex={rowIndex}
										setSearchParams={setSearchParams}
										searchKey={searchKey}
										pattern={pattern}
										searchValue={searchValue}
										searchParamsValue={searchParamsValue}
										statusValue={statusValue}
										assignmentForValue={assignmentForValue}
										moduleValue={moduleValue}
										supGroupValue={supGroupValue}
										setRef={setRef}
										isClearAllfilter={isClearAllfilter}
										isClearAllFilterFunc={isClearAllFilterFunc}
										dateRangePickerRef={inputRef}
										eventName={eventName}
										// ciClassName={ciClassName}
										ruleCondition={ruleCondition}
										isContextual={isContextual}
										activePanelKey={activePanelKey}
										handlePanelClick={handlePanelClick}
									/>
									{/* Xsm BreakFix Action Section End*/}
								</div>
							</Panel>
						</> : ""
					}
					</PanelGroup>
				</div>
			</div>
		);
	
}

const mapStateToProps = ({ allRuleConfigurationsData, amsBoardShowLoader }) => {
	return { allRuleConfigurationsData, amsBoardShowLoader: amsBoardShowLoader.loading }
  }

export default AmsHomeIndex;
