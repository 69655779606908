
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, PageHeader, Nav, NavItem, Button } from 'react-bootstrap';
// import { push } from 'react-router-redux';
let sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

import {GLOBAL} from '_Globals';
import axios from 'axios';
import { editCategory } from '../../../../actions/categoryBoard/quickViewAction.js';
import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
//let user_id = userDetails.user_id;
//let user_name = userDetails.user_name
import { reduxForm, formValueSelector, change, SubmissionError } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getCompanyList } from './../../../../actions/spcmActions';
import Swal from 'sweetalert2';
import { navigationHooks } from '../../../../helpers/NavigationHook';
import swal from 'sweetalert';
import { HiOutlineTrash } from 'react-icons/hi';
import { LuFileText } from 'react-icons/lu';
import { MyContext } from '_MyContext';

class EditCategoryHeader extends React.Component {
  static contextType = MyContext;
  constructor(props) {
    super(props);
    this.state = {
      loader: false
    };
    this.submit = this.submit.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
  }

  componentDidMount() {
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
    this.context.newTabPrevent();
	}
	componentDidUpdate() {
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
    this.context.newTabPrevent();
	}

  submit(values) {
    console.log('Edit Values --- ', values);
    if((!values.BUSINESS_FUNCTION_ID && (values.BUSINESS_FUNCTION_ID && values.BUSINESS_FUNCTION_ID !== 0)) || !values.BUSINESS_FUNCTION_NAME || values.BUSINESS_FUNCTION_NAME.trim() === "") {
      this.props.setBusinessFnErrorColor();
      return false;
    }
    let companyName;
    this.props.spcmReducer.companyList.map(function (item, index) {
    if (values.COMAPNY_ID == item.field1Value) {
    companyName = item.field1Key;
  }
 

});

    return sleep(1000) // simulate server latency

      .then(() => {

        if (`${JSON.stringify(values, null, 2)}` == "{}") {
          throw new SubmissionError({ _error: 'The form has missing required fields, please revalidate the highlighted fields and update the form' })
        }
        else {
          let formData = new FormData();
          let postjson;
          if(values.CATEGORY_SEQUENCE == undefined && values.CATEGORY_PINNED == undefined){

           postjson = {
            "category_name": values.CATEGORY_NAME,
            "sub_category_name": values.SUB_CATEGORY_NAME,
            "status": values.STATUS,
            "status_name": values.STATUS_NAME,
            "isvisible": 'Y',
            "company_name": companyName,
            "company_id": values.COMAPNY_ID,
            "category_pinned":"false",
            "category_sequence":"1",
            "business_function_id": values.BUSINESS_FUNCTION_ID,
            "business_function_name": values.BUSINESS_FUNCTION_NAME
          };
        }else if(values.CATEGORY_PINNED == undefined || values.CATEGORY_PINNED == ""){
          postjson = {
            "category_name": values.CATEGORY_NAME,
            "sub_category_name": values.SUB_CATEGORY_NAME,
            "status": values.STATUS,
            "status_name": values.STATUS_NAME,
            "isvisible": 'Y',
            "company_name": companyName,
            "company_id": values.COMAPNY_ID,
            "category_pinned":"false",
            "category_sequence":(values.CATEGORY_SEQUENCE) ? values.CATEGORY_SEQUENCE.toString() : '',
            "business_function_id": values.BUSINESS_FUNCTION_ID,
            "business_function_name": values.BUSINESS_FUNCTION_NAME
          };

        }
        else if(values.CATEGORY_PINNED == true  && values.CATEGORY_SEQUENCE == undefined){
          postjson = {
            "category_name": values.CATEGORY_NAME,
            "sub_category_name": values.SUB_CATEGORY_NAME,
            "status": values.STATUS,
            "status_name": values.STATUS_NAME,
            "isvisible": 'Y',
            "company_name": companyName,
            "company_id": values.COMAPNY_ID,
            "category_pinned":"true",
            "category_sequence":"1",
            "business_function_id": values.BUSINESS_FUNCTION_ID,
            "business_function_name": values.BUSINESS_FUNCTION_NAME
          };


        }
        else{
           postjson = {
            "category_name": values.CATEGORY_NAME,
            "sub_category_name": values.SUB_CATEGORY_NAME,
            "status": values.STATUS,
            "status_name": values.STATUS_NAME,
            "isvisible": 'Y',
            "company_name": companyName,
            "company_id": values.COMAPNY_ID,
            "category_pinned":"true",
            "category_sequence":(values.CATEGORY_SEQUENCE) ? values.CATEGORY_SEQUENCE.toString() : '',
            "business_function_id": values.BUSINESS_FUNCTION_ID,
            "business_function_name": values.BUSINESS_FUNCTION_NAME
          };
        }
          formData.append('data', JSON.stringify(postjson));
          if (values.CATEGORY_ICON)
            formData.append('category_icon', values.CATEGORY_ICON[0]);
          if (values.SUB_CATEGORY_ICON)
            formData.append('sub_category_icon', values.SUB_CATEGORY_ICON[0]);

          console.log('formData====', formData);
          this.props.editCategory(this.props.categoryId, formData);
        }
      });
  }
  onDeleteClick(){
    console.log("this.props.categoryId",this.props.categoryId);
    let id = this.props.categoryId;
    Swal.fire({
      title: 'Are you sure you wish to delete this item?',
      width: 550,

      padding: '15px',
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
      return axios.delete("/api/category/deleteCategoryWithNoOffering/"+id).then((resp)=>{
        navigationHooks.navigate("/categoryBoard");

    }).catch((error)=>{
      console.log("error",error.response)
      if(!error.response.data[0]){
        swal({
          text: error.response.data.message,
          button: 'OK',
      });
      }else{
       
        swal({
          text: error.response.data[0].message,
          button: 'OK',
      });
      }
      this.setState({deleteError:error.response.data[0].message})
    })
          } else if (result.isDenied) {
        
      }
    })
  }
  render() {
    let { handleSubmit, submitting } = this.props;
    return (
      <Row className="margin-b-15">
        <Col md={7} sm={7} xs={12}>
          <h2 bsClass="" className="sPageHeading1">
            {this.props.translator["Service Category"]}
          </h2>
        </Col>
        <Col md={5} sm={5} xs={12}>
          <div className="paHedFilter">
            <div className="float-r toprigLnk">
              {!this.props.isRightEditPanel ?
              <Nav className="icnlnk riggep float-l" as="ul">
                <Nav.Item
                  title={this.props.translator["Services"]}
                  eventKey={4}
                  as="li"
                >
                  <a className={this.props.isRightEditPanel === true ? "active" : ""}
                    href={void 0}
                    onClick={() => {
                      this.props.rightEditPanel(true);
                    }}
                  >
                    <LuFileText/>
                  </a>
                </Nav.Item>
              </Nav>
              : ""}
              

              <Nav className="icnlnk" as="ul">
                <Nav.Item bsPrefix=" " as="li">
                  <Link
                    bsPrefix=" "
                    className="myBt rclose"
                    title={this.props.translator["Delete"]}
                    onClick={this.onDeleteClick}
                  >
                    <HiOutlineTrash/>
                  </Link>
                </Nav.Item>
                <Nav.Item bsPrefix=" " as="li" eventKey={1}>
                  <Link
                    className="anchbtn"
                    to="javascript:void(0)"
                    bsPrefix=" "
                    onClick={handleSubmit(this.submit)}
                    disabled={submitting}
                    title={this.props.translator["Save"]}
                    ref={(e) => (this.saveObjRef = e)}
                  >
                    {submitting ? (
                      <ImSpinner6 className="icn-spinner" />
                    ) : (
                      <IoSaveOutline />
                    )}
                  </Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Link to="/categoryBoard" ref={e => this.closeButton = e}>
                    <IoClose />
                  </Link>
                </Nav.Item>
              </Nav>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

EditCategoryHeader = reduxForm({
  form: 'EditCategoryForm',
  destroyOnUnmount: true
})(EditCategoryHeader);

export function mapStateToProps({ showLoader,spcmReducer }) {
  return {
    showLoader: showLoader.loading,
    spcmReducer
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ editCategory,getCompanyList }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCategoryHeader);
// export default XsmSupportHeader;