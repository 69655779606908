
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export const quickViewDetails = (state = initialState.quickViewDetails, action)=>{
  let newbreakfixquickviewarray = [];
	switch (action.type){
		case 'LOAD_BREAKFIXQUICKVIEW_SUCCESS':
				//alert(action.breakFix.data.);
				return action.quickViewDetails.data;
		case 'LOAD_BREAKFIXQUICKVIEW_FAILURE':
				return action.failureMessage;
		default:
				return state;
		}

}

export const moduleList = (state = {}, action)=> {
	switch (action.type){
		case 'LOAD_MODULES_SUCCESS':
			return action.moduleList.data;
			default:
			return state;
	}
}

export const multiSelectDropDownWithOk = (state = {}, action)=> {
	switch (action.type){
		case 'MULTI_SELECT_DROPDOWN_WITH_OK':
			return action.payload;
		default:
			return state;
	}
}

export const loadIncData = (state = {}, action) => {
	switch (action.type) {
		case 'LOAD_Inc_DETAILS':
			console.log("actionaction ",action)
			return action.payload;
		case "RESET_STATE":
			return {};
		default:
			return state;
	}
}

export const wibListPayload = (state = {}, action) => {
	switch (action.type) {
		case 'LOAD_WIB_LIST_PAYLOAD':
			return action.payload;
		case "RESET_WIB_LIST_PAYLOAD":
			return {};
		default:
			return state;
	}
}