
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import PindCategories from "../common/header/pind-categories";
import HomeGridView from "./gridView/homeGridView";
import HomeListView from "./listView/homeListView";
import SxAdvertisement from "./SXAdvertisement";
import SxNews from "./SXNews";
import { connect } from "react-redux";

import { loadAdvertisement } from "../../actions/homepage/advertisementAction";
import { loadBulletin } from "../../actions/homepage/bulletinActions";

import Cookies from "universal-cookie";
import { GLOBAL } from "_Globals";
import VerticalCatalogIndex from "./home/VerticalCatalogIndex";
import "_Css/consumer/_serviceCatalog.scss";
import "_Css/common/_globalSearch.scss";

const cookies = new Cookies();
let cookieData = cookies.get("gph");
if (cookieData) cookieData = cookieData.replace("s:", "");
if (cookieData)
  cookieData = cookieData.substring(
    0,
    cookieData.lastIndexOf(".")
  );
cookieData = cookieData.split("~");
let homepagelocation = cookieData[0];
let userRoleId = cookieData[33];
let verticalPanelActive = cookieData[61];

let Home = class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenu: "0",
      showHideRightNav: false,
      isView: true,
    };
    this.showHideRightNav = this.showHideRightNav.bind(this);
    this.checkPageView = this.checkPageView.bind(this);
  }
  checkPageView(pageview) {
    this.setState({ isView: pageview });
  }
  showHideRightNav(value) {
    this.setState({ showHideRightNav: !this.state.showHideRightNav });
  }

  componentWillMount() {
    if (
      userRoleId.indexOf("11") > -1 ||
      userRoleId.indexOf("10") > -1 ||
      userRoleId.indexOf("47") > -1 ||
      userRoleId.indexOf("2") > -1
    ) {
      this.props.loadAdvertisement();
      this.props.loadBulletin();
    }
    if(this.props.offeringView === "list"){
      this.setState({ isView: false });
    } else if(this.props.offeringView === "grid") {
      this.setState({ isView: true });
    }
  }

  render() {
    return (
      <main>
        <div>
          <PindCategories />
          <SxNews tr={this.props.tr} />
          <SxAdvertisement tr={this.props.tr} />
          {this.state.isView ? (
            <HomeGridView
              isView={this.state.isView}
              pageViewFun={this.checkPageView}
            />
          ) : verticalPanelActive == "true" ? (
            <VerticalCatalogIndex
              pageViewFun={this.checkPageView}
              isView={this.state.isView}
            />
          ) : (
            <HomeListView
              tr={this.props.tr}
              isView={this.state.isView}
              pageViewFun={this.checkPageView}
            />
          )}
        </div>
      </main>
    );
  }
};

const mapStateToProps = ({ spcmReducer, offeringView }) => {
  return {
    spcmReducer,
    tr: spcmReducer.tr,
    offeringView
  };
};

export default connect(mapStateToProps, { loadBulletin, loadAdvertisement })(
  Home
);
// export default HomeMain;
