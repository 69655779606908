
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import { GLOBAL }  from '../../components/Globals';

export function loadBreakFixCriticalitySuccess(breakFixCriticalityName){
	return {
		type: 'LOAD_BREAKFIXCRITICALITY_SUCCESS',
		breakFixCriticalityName
	};
}

export function loadBreakFixCriticalityFailure(errorResponse){
	return {
		type: 'LOAD_BREAKFIXCRITICALITY_FAILURE',
		errorResponse
	};
}

export function loadBreakFixCriticality(){
	return(dispatch) =>{
			axios.get(GLOBAL.breakFixCriticalityUrl)
				.then((breakFixCriticalityName)=>{
					if(!breakFixCriticalityName){						
						throw Error(breakFixCriticalityName.statusText);
					}
					return breakFixCriticalityName;
				})
				.then((breakFixCriticalityName) =>{
					dispatch(loadBreakFixCriticalitySuccess(breakFixCriticalityName))
				})
				.catch((err) => {
					console.log(err);
				});

	};
}
