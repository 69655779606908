
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';// use to make ajax request from browser

//const ROOT_URL=`https://xsm-f.mybluemix.net/getProviderCompanyForRestifySelfService/`

export const FETCH_SLIDERLIST = 'FETCH_SLIDERLIST';

export function loadSliderListSuccess(sliderlist){
	return{
		type: FETCH_SLIDERLIST,
		sliderlist

	};
}

export function loadSliderList(url){
	return(dispatch) =>{
		axios.get(url)
			.then((sliderlist)=>{
				if(!sliderlist){
					throw Error(sliderlist.statusText);
				}
				return sliderlist;
			})
			.then((sliderlist) =>{
				dispatch(loadSliderListSuccess(sliderlist))
			})
			.catch((err) => {
				console.log(err);
			});
	};
}
