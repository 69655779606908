
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export default function rightSidebarMenulistReducer(state = initialState.functions, action){
  switch (action.type) {
    case 'LOAD_FUNCTION_BY_PAGE_AND_ROLE_SUCCESS':
      return action.functions;

    default:
    return state;

  }
}
