
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import { gql } from "@apollo/client";

export const CreateConnector = gql`
    mutation CreateConnector($input: CreateConnectorInput!){
        createConnector(connector: $input){
            authentication
            body
            createdBy
            createdById
            headers
            module
            modifiedBy
            modifiedById
            query_string
            title
            url
            verb
        }
    }
`

export const GetConnector = gql `
    query connector($id: String!) {
        connector(id: $id){
            authentication
            body
            headers
            module
            query_string
            title
            url
            verb
        }
    }
`

export const MenuList = gql`
    query menuListByName($search: String!) {
        menuListByName(search: $search){
            values
        }
    }
`

export const UpdateConnector = gql`
    mutation UpdateConnector($id: String!, $input: UpdateConnectorInput!){
        updateConnector(id: $id, connector: $input){
            authentication
            body
            headers
            module
            modifiedBy
            modifiedById
            query_string
            title
            url
            verb
        }
    }
`

export const GetConnectors = gql`
    query connectors {
        connectors {
            _id
            title
            module
            modifiedBy
            updatedAt
        }
    }
`

export const FilterConnectorByName = gql `
    query connectorsByName($search: String!) {
        connectorsByName(search: $search){
            _id
            title
        }
    }
`

export const DeleteConnector = gql`
    mutation DeleteConnector($id: String!){
        deleteConnector(id: $id){
            _id
        }
    }
`


export const CreateDataQuery = gql `
    mutation CreateDataQuery($input: CreateDataQueryInput!){
        createDataQuery(dataQuery: $input){
            title
            createdBy
            createdById
            type
            data
            form
            modifiedBy
            modifiedById
        }
    }
`

export const GetDataQueries = gql `
    query DataQueries($input: FilterDataQueryInput!) {
        dataQueries(filters: $input) {
            _id
            title
            connector {
                module
            }
            data
        }
    }
`

export const GetDataQuery = gql `
    query DataQuery($id: String!) {
        dataQuery(id: $id){
            title
            type
            data
            form
            connector {
                title
                _id
                verb
                url
                module
            }
        }
    }
`

export const UpdateDataQuery = gql `
    mutation UpdateDataQuery($id: String!, $input: UpdateDataQueryInput!){
        updateDataQuery(id: $id, dataQuery: $input){
            title
            type
            data
            form
            modifiedBy
            modifiedById
        }
    }
`

export const DeleteDataQuery = gql`
    mutation DeleteDataQuery($id: String!){
        deleteDataQuery(id: $id){
            _id
        }
    }
`