
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Pagination from "react-js-pagination";
import ListLoader from "_Commons/loaders/ListLoader";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import _ from "lodash";
import ReactDOM from "react-dom";
import { loadSlaHolidayListOnPagination } from "_Actions/sla/quickViewAction.js";
import { loadSlaWrkDayScheduleStatus } from "_Actions/sla/lovAction";
import {IoSearch} from 'react-icons/io5';

class XsmSlaQuickViewList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      table_size: 10,
      scheduleSearchFilter: "",
      companySearchFilter: "",
      selectObjRightPos: "0",
      isMultiSelectOpen: true,
    };
    this.onRowSelect = this.onRowSelect.bind(this);
    // this.customDataField = this.customDataField.bind(this);
    // this.refreshHolidayTable = this.refreshHolidayTable.bind(this);
    this.renderSlaHolidayTableListOnPagination =
      this.renderSlaHolidayTableListOnPagination.bind(this);
    this.onPageSizeChangeBreakFix = this.onPageSizeChangeBreakFix.bind(this);
    this.onPageChangeBreakFix = this.onPageChangeBreakFix.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.clickOutside = this.clickOutside.bind(this);
  }

  componentDidMount() {
    this.props.loadSlaWrkDayScheduleStatus("status");
    document.addEventListener('mousedown', this.clickOutside);
  }

  componentDidUpdate(prevProps) {
    if (this.multiSelectStatusDropdownRef != undefined || this.multiSelectStatusDropdownRef != null) {
      ReactDOM.findDOMNode( this.multiSelectStatusDropdownRef ).childNodes[0].className = "multiSelectRef";
      let myRef = this.multiSelectStatusDropdownRef;
      if (this.props.isStatusOpen != prevProps.isStatusOpen) {
        let obj = ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1];
        if (obj != undefined || obj != null) {
          ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1].className =
            "multiSelectPopup";
        }
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.clickOutside);
  }

  clickOutside(event) {
    const statusDv = document.getElementById("statusDv");

    if (this.multiSelectStatusDropdownRef != undefined || this.multiSelectStatusDropdownRef != null) {
      document.addEventListener("mousedown", (event) => {
        if (statusDv.contains(event.target)) {
          // console.log("TYPE CLICKED");
          this.setState({ isMultiSelectOpen: true });
          this.props.showRightSide(false);
        }
        else {
          this.setState({ isMultiSelectOpen: false });
        }
        this.props.multiSelectOpenFun("slaHolDayStatus", false);
      });
    }
  }

  onRowSelect(e, row, index) {
    let holidays = [];
    this.props.getSlaHolidayDetails(
      row.scheduleId,
      row.scheduleCode,
      row.scheduleName,
      row.companyName,
      row.status,
      row.holidayScheduleList
    );
    this.props.showRightSide(true);
    this.props.showActiveRow(index);
  }

  renderSlaHolidayTableListOnPagination(holidayData) {
    let data = holidayData.data;
    return data.map((data, index) => {
      return (
        <tr
          className={
            this.props.isRightSideVisible && index == this.props.rowIndex
              ? "myActive"
              : ""
          }
          onClick={(e) => this.onRowSelect(e, data, index)}
        >
          <td title={data.scheduleName}>
            {data.scheduleName.slice(0, 70)}
            {data.scheduleName.length > 70 ? "..." : ""}
          </td>
          <td title={data.companyName}>
            {data.companyName.slice(0, 70)}
            {data.companyName.length > 70 ? "..." : ""}
          </td>
          {/* <td>{data.scheduleName}</td>
					<td>{data.companyName}</td> */}
          <td>{data.status}</td>
        </tr>
      );
    });
  }
  onPageSizeChangeBreakFix(e) {
    let size = parseInt(e.target.value, 10);
    this.setState({ table_size: size });
    this.props.setPageDetails(0, size);
    let searchParams = {};
    searchParams.searchByList = "";
    searchParams.multipleValueList = "";
    searchParams.patternList = "";
    searchParams.currentPage = 0;
    searchParams.size = size;
    searchParams.orderBy = "desc";
    searchParams.sortBy = "scheduleId";
    this.props.loadSlaHolidayListOnPagination(
      searchParams,
      this.props.statusValue
    );
  }

  onPageChangeBreakFix(e) {
    let searchParams = {};
    searchParams.searchByList = "";
    searchParams.multipleValueList = "";
    searchParams.patternList = "";
    searchParams.currentPage = e - 1;
    searchParams.size = this.props.pageSize;
    searchParams.orderBy = "desc";
    searchParams.sortBy = "scheduleId";
    this.props.loadSlaHolidayListOnPagination(
      searchParams,
      this.props.statusValue
    );
  }

  handleSearch() {
    let searchByValue = [];
    let searchByVar = [];
    let pattern = [];
    this.props.isClearAllFilterFunc(true);
    this.props.showRightSide(false);
    if (this.props.scheduleSearchInput != "") {
      searchByValue.push(this.props.scheduleSearchInput.trim());
      searchByVar.push(this.state.scheduleSearchFilter);
      pattern.push("like");
    }
    if (this.props.companySearchInput != "") {
      searchByValue.push(this.props.companySearchInput.trim());
      searchByVar.push(this.state.companySearchFilter);
      pattern.push("like");
    }
    let searchParams = {};
    searchParams.searchByList = searchByVar.join(",");
    searchParams.multipleValueList = searchByValue.join("|");
    searchParams.patternList = pattern.join(",");
    searchParams.currentPage = 0;
    searchParams.size = this.props.pageSize;
    searchParams.orderBy = "desc";
    searchParams.sortBy = "scheduleId";
    this.props.setSearchParams(
      searchParams,
      searchByVar.join(","),
      searchByValue.join("|"),
      pattern.join(","),
      this.props.statusValue
    );
    this.props.loadSlaHolidayListOnPagination(
      searchParams,
      this.props.statusValue
    );
  }

  handleStatusChange(value) {
    let searchOnStatusArr = [];
    this.props.searchKey.length == 0 && this.props.searchValue.length == 0
      ? value.length == 0
        ? this.props.isClearAllFilterFunc(false)
        : this.props.isClearAllFilterFunc(true)
      : this.props.isClearAllFilterFunc(true);
    this.props.showRightSide(false);
    this.props.setSelectedStatusValue(value);
    value.map((checkBoxValue) => {
      return searchOnStatusArr.push(checkBoxValue.label);
    });
    let searchParams = {};
    searchParams.searchByList = this.props.searchKey;
    searchParams.multipleValueList = this.props.searchValue;
    searchParams.patternList = this.props.pattern;
    searchParams.currentPage = 0;
    searchParams.size = this.props.pageSize;
    searchParams.orderBy = "desc";
    searchParams.sortBy = "scheduleId";
    this.props.setSearchParams(
      this.props.searchParamsValue,
      this.props.searchKey,
      this.props.searchValue,
      this.props.pattern,
      searchOnStatusArr
    );
    this.props.loadSlaHolidayListOnPagination(searchParams, searchOnStatusArr);
  }

  handleChange(e, searchValue) {
    switch (searchValue) {
      case "scheduleName":
        this.props.setScheduleSearchInput(e.target.value);
        this.setState({ scheduleSearchFilter: searchValue });
        break;
      case "companyName":
        this.props.setCompanySearchInput(e.target.value);
        this.setState({ companySearchFilter: searchValue });
        break;
    }
  }

  render() {
    console.log("this.props.slaHolidayList", this.props.slaHolidayList);
    let meta = !_.isEmpty(this.props.slaHolidayList)
      ? this.props.slaHolidayList.meta
      : {};
    let caseResponsive =
      this.props.slaHolidayList == undefined || this.props.slaHolidayList.data == undefined || this.props.show_loader || this.props.slaHolidayList.data?.length <= 6
        ? "overFlowClp"
        : "table-responsive";

    let statusmap = new Map();
    let statusOptions = [];

    statusmap = new Map(Object.entries(this.props.slaWorkdaySchedulesStatus));
    for (let [key, value] of statusmap) {
      let obj = {};
      obj.label = value.field1Key;
      obj.value = value.field1Value;
      statusOptions.push(obj);
    }

    let panelColSize = this.props.panelResize;

    if (this.props.isRightSideVisible == true) {
      this.multiSelectStatusDropdownRef.childNodes[0].childNodes[0].addEventListener('click', () => {
        this.props.showRightSide(false);
      });
    }

    return (
      <div>
        <div className={this.props.slaHolidayList == undefined || this.props.slaHolidayList.data == undefined || this.props.show_loader || this.props.slaHolidayList.data?.length <= 6 ? "respondv responDvMinH" : "respondv"}>
          <div className={"tableRgtBor " + caseResponsive}>
            <Table
              striped
              bordered
              condensed
              hover
              className="tableView nowrapWhright"
              style={{ marginTop: "0px" }}
            >
              <thead>
                <tr>
                  <th>
                    <div className="sortParArr">
                      {this.props.translator["Schedule"]}
                    </div>
                    <div className="colSearDv">
                      <Form.Control
                        className="colSearInp"
                        type="text"
                        value={
                          this.props.scheduleSearchInput != ""
                            ? this.props.scheduleSearchInput
                            : ""
                        }
                        placeholder={this.props.translator["Search here"]}
                        onKeyPress={(e) => {
                          if (e.charCode == "13") {
                            if (this.props.scheduleSearchInput.trim().length >3)
                              this.handleSearch();
                          }
                        }}
                        onChange={(event) => {
                          this.handleChange(event, "scheduleName");
                        }}
                        onClick={() => this.props.multiSelectOpenFun("slaHolDayStatus", false)}
                      />
                      {this.props.scheduleSearchInput.trim().length > 3 ? (
                        <a
                          title="search"
                          className="faicn"
                          href={void(0)}
                          onClick={(event) => this.handleSearch()}
                        >
                          <IoSearch/>
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </th>
                  <th>
                    <div className="sortParArr">
                      {this.props.translator["Company"]}
                    </div>
                    <div className="colSearDv">
                      <Form.Control
                        className="colSearInp"
                        type="text"
                        value={
                          this.props.companySearchInput != ""
                            ? this.props.companySearchInput
                            : ""
                        }
                        placeholder={this.props.translator["Search here"]}
                        onKeyPress={(e) => {
                          if (e.charCode == "13") {
                            if (this.props.companySearchInput != "" && this.props.companySearchInput.trim().length >= 3)
                              this.handleSearch();
                          }
                        }}
                        onChange={(event) => {
                          this.handleChange(event, "companyName");
                        }}
                        onClick={() => this.props.multiSelectOpenFun("slaHolDayStatus", false)}
                      />
                      {this.props.companySearchInput.trim().length >= 3 ? (
                        <a
                          title="search"
                          className="faicn"
                          href={void(0)}
                          onClick={(event) => this.handleSearch()}
                        >
                          <IoSearch/>
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </th>

                  <th>
                    <div className="sortParArr">
                      {this.props.translator["Status"]}
                    </div>
                    <div
                      id="statusDv"
                      className="colSearDv"
                      ref={(e) => (this.multiSelectStatusDropdownRef = e)}
                      onClick={() => this.props.multiSelectOpenFun("slaHolDayStatus", true)}
                    > 
                      <ReactMultiSelectCheckboxes
                        placeholderButtonLabel={this.props.translator["Select"]}
                        rightAligned={true}
                        options={statusOptions}
                        value={this.props.selectedStatusValue}
                        onChange={this.handleStatusChange}
                      />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.slaHolidayList == undefined ||
                this.props.slaHolidayList.data == undefined ||
                this.props.slaHolidayList.data.length == undefined ||
                this.props.show_loader == true ? (
                  <tr>
                    <td colSpan="5" style={{ textAlign: "center" }}>
                      <ListLoader />
                    </td>
                  </tr>
                ) : this.props.slaHolidayList.data.length === 0 ? (
                  <tr>
                    <td colSpan="5" style={{ textAlign: "center" }}>
                      {
                        this.props.translator[
                          "There is no matching data available"
                        ]
                      }
                    </td>
                  </tr>
                ) : (
                  this.renderSlaHolidayTableListOnPagination(
                    this.props.slaHolidayList
                  )
                )}
              </tbody>
            </Table>
          </div>
          <div className="nBotPagina">
            <div className="nShow">
              <div className="margin-r-10">
                {this.props.translator["Show"]}:
              </div>
              <Form.Select
                value={this.props.pageSize}
                onChange={this.onPageSizeChangeBreakFix}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                {/* <option value="100">100</option> */}
              </Form.Select>
            </div>
            <nav aria-label="Pagination" className="display-inline-block">
              <Pagination
                prevPageText="Prev"
                nextPageText="Next"
                firstPageText={<i className="glyphicon glyphicon-menu-left" />}
                lastPageText={<i className="glyphicon glyphicon-menu-right" />}
                activePage={meta ? meta.currentPage + 1 : 1}
                itemsCountPerPage={this.props.pageSize}
                totalItemsCount={meta ? meta.recordCount : 1}
                pageRangeDisplayed={panelColSize <= 42 ? 2 : 5}
                onChange={(e) => this.onPageChangeBreakFix(e)}
              />
            </nav>
          </div>
        </div>

        {/* <Row className="border-b  padding-b-5  margin-b-5">
						<Col md={7} sm={6} xs={6}><h3 className="margin-0">{this.props.translator['Service Level Targets']} ({this.props.slaDefList.data.meta.recordCount})</h3></Col> */}
        {/* <Col md={7} sm={6} xs={6}><h3 className="margin-0">{this.props.translator['Service Level Targets']} ({this.props.slaDefList.length})</h3></Col> */}
        {/* <Col md={5} sm={6} xs={6}>
							<div className="innerStatus margin-r-0 padding-r-0">
								<div className="status float-l">
									<Nav bsClass="">
										<LinkContainer to="/createSlaDef" ><NavItem title={this.props.translator['Create new SLA']} eventKey={2}><span className="sAction bgBtn15 artAdd"></span></NavItem></LinkContainer>
										<NavItem title={this.props.translator['Refresh']} eventKey={1} onClick={() => { this.refreshSlaDefTable(); }}><span className="sAction bgBtn16 refresh"></span></NavItem>
									</Nav>
								</div>
							</div>
						</Col>
					</Row> */}
        {/* {this.renderSlaDefTableListPagination(this.props.slaDefList)} */}
      </div>
      // <div>

      // 	<Row className="border-b  padding-b-5  margin-b-5">
      // 		<Col lg={7} md={7} sm={7} xs={7}>
      // 			<h3 className="margin-0">{this.props.translator['Holiday Schedule']} ({this.props.slaHolidayList.meta.recordCount})</h3>
      // 		</Col>
      // 		<Col lg={5} md={5} sm={5} xs={5}>
      // 			<div className="innerStatus margin-r-0 padding-r-0">
      // 				<div className="status float-l">
      // 					<Nav bsClass="">
      // 						<LinkContainer to="/createHolSch" ><NavItem title={this.props.translator['Create Holiday Schedule']} eventKey={2}><span className="sAction bgBtn15 artAdd"></span></NavItem></LinkContainer>
      // 						<NavItem title={this.props.translator['Refresh']} eventKey={1} onClick={() => { this.refreshHolidayTable(); }}><span className="sAction bgBtn16 refresh"></span></NavItem>

      // 					</Nav>
      // 				</div>
      // 			</div>
      // 		</Col>
      // 	</Row>
      // 	{this.renderSlaHolidayTableListOnPagination(this.props.slaHolidayList)}
      // </div>
    );
    // }
  }
}

export function mapStateToProps({
  slaHolidayList,
  showLoader_SLA_Holiday_Schedule_Pagination,
  slaWorkdaySchedulesStatus,
}) {
  //console.log(slaDefList)
  return {
    slaHolidayList,
    show_loader: showLoader_SLA_Holiday_Schedule_Pagination.loading,
    slaWorkdaySchedulesStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { loadSlaHolidayListOnPagination, loadSlaWrkDayScheduleStatus },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(XsmSlaQuickViewList);
