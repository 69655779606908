
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const tableExtendTraits = (e) => {
  e.DomComponents.addType("table", {
    model: e.DomComponents.getType("table").model.extend({
      init() {
        const traits = this.get("traits");
        traits.add([
          {
            type: "select",
            label: "Data Source",
            name: "data_source",
            options: []
          },
          {
            type: "select",
            label: "Table Type",
            name: "tableType",
            options: [
              { id: "customTable", label: "Custom Table" },
              { id: "bootstrapTable", label: "Bootstrap Table" },
            ],
          },
          {
            type: "text",
            label: "Table Title",
            name: "tableTitle",
          },
          {
            type: "text",
            label: "Unique Key",
            name: "uniqueKey",
          },
          {
            type: "checkbox",
            label: "Is Detail View ",
            name: "isDetailViewAvailable",
          },
          {
            type: "textarea",
            label: "Right Side Keys",
            placeholder: "Enter comma seperated Right Side Keys",
            name: "rightSideKeys",
          },
          {
            type: "textarea",
            label: "Label Against Keys",
            placeholder: "Enter comma seperated",
            name: "rightSideLabels",
          },
          {
            type: "text",
            label: "Edit Url",
            placeholder: "Edit Url With Keys",
            name: "editUrl",
          },
          {
            type: "checkbox",
            label: "Pass Seleced Row On Edit Page",
            name: "passSelectedRow",
          },
          {
            type: "text",
            label: "Listing Key",
            name: "listingKey",
          },
          {
            type: "text",
            label: "Meta Key",
            name: "metaKey",
          },
          {
            type: "select",
            label: "Filter Values Processing Method",
            name: "dataTransmissionMethod",
            options: [
              { id: "headers", label: "Headers" },
              { id: "queryParams", label: "Query Params" },
              { id: "body", label: "Body" },
            ],
          },
          {
            type: "text",
            label: "Filter Values Key",
            name: "filterValuesKey",
          },
          {
            type: "text",
            label: "Current Page Key",
            name: "currPageKey",
          },
          {
            type: "text",
            label: "Sorting Key",
            name: "sortingKey",
          },
          {
            type: "text",
            label: "Items Per Page Key",
            name: "sizeKey",
          },
          {
            type: "text",
            label: "Items Per Page Text",
            name: "itemsPerPageText",
          },
          {
            type: "text",
            label: "Items Per Page Options",
            name: "itemsPerPageOptions",
          },
          {
            type: "checkbox",
            label: "Display Records Count After Title(Custom Table)",
            name: "displayTotalRecordsCount",
          },
          {
            type: "checkbox",
            label: "Send data in body as JSON (Custom Table)",
            name: "sendDataAsJSON",
          },
          {
            type: "text",
            label: "Total Number of Records Key",
            name: "totalRecordsKey",
          },
          {
            type: "checkbox",
            label: "Search Box (Bootstrap Table)",
            name: "showSearchBox",
          },
          {
            type: "checkbox",
            label: "No Data Found Text",
            name: "notDataFound",
          },
          {
            type: "checkbox",
            label: "Hide Edit Button (Right Side)",
            name: "hideEditButton",
          },
        ]);
      },
    }),
  });
};
