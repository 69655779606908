
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Row, Col, Form, ButtonToolbar, Button, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import axios from 'axios';
import { spcmLoader, getAttributesTranslationStatus, languageListTranslationStatus, translate, getTranslationForOfferingNameDescriptionSummary, resetReducer, translateCategory, translateSubcategory, getLanguageTranslationForCategoryAndSubcategory } from '../../../../../actions/spcmActions';
import { Field, reduxForm, SubmissionError, change } from 'redux-form';
import { _textArea, _dropDown, _inputField, _number } from '../../../../common/formFields';
import {ImSpinner6} from "react-icons/im";

const validate = values => {
	console.log("values");
	console.log(values);
	let errors = {};


	if (values.translated_category) {
		if (values.translated_category.trim() == "") {
			errors.translated_category = "Enter Translated Category";
		}
	}
	else if (!values.translated_category) {
		errors.translated_category = "Enter Translated Category";
	}

	//translated_business_function
	if (values.translated_business_function) {
		if (values.translated_business_function.trim() == "") {
			errors.translated_business_function = "Enter Translated Business Function";
		}
	}
	else if (!values.translated_business_function) {
		errors.translated_business_function = "Enter Translated Business Function";
	}

	if (values.translated_subcategory) {
		if (values.translated_subcategory.trim() == "") {
			errors.translated_subcategory = "Enter Translated Sub Category";
		}
	}
	else if (!values.translated_subcategory) {
		errors.translated_subcategory = "Enter Translated Sub Category";
	}


	console.log("errors");
	return errors;
}

class TextFieldForm extends React.Component {

	constructor(props) {
		super(props);

		this.submitForm = this.submitForm.bind(this);
	};

	componentWillReceiveProps(newProps) {

		if (this.props.spcmReducer.translatedCategory !== newProps.spcmReducer.translatedCategory) {
			this.props.dispatch(change('CategoryForm', 'translated_category', newProps.spcmReducer.translatedCategory));
		}

		if (this.props.spcmReducer.translatedSubcategory !== newProps.spcmReducer.translatedSubcategory) {
			this.props.dispatch(change('CategoryForm', 'translated_subcategory', newProps.spcmReducer.translatedSubcategory));
		}
	}

	componentWillUnmount() {
		this.props.resetReducer();
	}

	componentDidMount() {
		console.log(this.props.spcmReducer.catSubcatTranslation);
		let outerThis = this;
		this.props.spcmReducer.catSubcatTranslation.map((item) => {
			console.log(item);
			if (item.ATTRIBUTE_TYPE == "category_name") {
				this.props.dispatch(change(
					"CategoryForm",
					"translated_category",
					item.ATTRIBUTE_TRANS_VALUE
				));
			}
			else if (item.ATTRIBUTE_TYPE == "sub_category_name") {
				this.props.dispatch(change(
					"CategoryForm",
					"translated_subcategory",
					item.ATTRIBUTE_TRANS_VALUE
				));
			}
			else if (item.ATTRIBUTE_TYPE == "business_function_name") {
				this.props.dispatch(change(
					"CategoryForm",
					"translated_business_function",
					item.ATTRIBUTE_TRANS_VALUE
				));
			}
		})
		// this.props.getLanguageTranslationForCategoryAndSubcategory(
		// 	this.props.spcmReducer.currentlySelectedServiceDetails
		// 		.OFFERING_ID,
		// 	this.props.lanIdVal2
		// );
	}

	submitForm(values) {
		console.log("valuess");
		console.log(values);
		let data = [
			{
				offering_id: -1,
				language_id: this.props.lanIdVal2,
				attribute_id: this.props.spcmReducer.currentlySelectedServiceDetails.CATEGORY_ID,
				attribute_type: "category_name",
				attribute_value: this.props.spcmReducer.currentlySelectedServiceDetails.CATEGORY_NAME,
				attribute_trans_value: values.translated_category
			},
			{
				offering_id: -1,
				language_id: this.props.lanIdVal2,
				attribute_id: this.props.spcmReducer.currentlySelectedServiceDetails.SUBCATEGORY_ID,
				attribute_type: "sub_category_name",
				attribute_value: this.props.spcmReducer.currentlySelectedServiceDetails.SUB_CATEGORY_NAME,
				attribute_trans_value: values.translated_subcategory
			},
			{
				offering_id: -1,
				language_id: this.props.lanIdVal2,
				attribute_id: this.props.spcmReducer.currentlySelectedServiceDetails.BUSINESS_FUNCTION_ID,
				attribute_type: "business_function_name",
				attribute_value: this.props.spcmReducer.currentlySelectedServiceDetails.BUSINESS_FUNCTION_NAME,
				attribute_trans_value: values.translated_business_function
			}
		]


		this.props.spcmLoader('attribute', 'posting');
		return axios.post("/api/languageTranslation/postRegardingOfferingTypeTranslation", data).then((response) => {
			this.props.spcmLoader('attribute', 'success');
			this.props.lanEditPageFun5(false);
			this.props.getLanguageTranslationForCategoryAndSubcategory(
				this.props.spcmReducer.currentlySelectedServiceDetails
					.OFFERING_ID,
				this.props.lanIdVal2
			);
			this.props.getAttributesTranslationStatus(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID, this.props.lanIdVal2);
		})
			.catch((error) => {
				this.props.spcmLoader('attribute', 'success');
				throw new SubmissionError({ _error: error.response.data.message });
			});
	}


	render() {
		const tr = this.props.tr;
		const { error, handleSubmit } = this.props;
		const category = this.props.spcmReducer.currentlySelectedServiceDetails.CATEGORY_NAME;
		const subcategory = this.props.spcmReducer.currentlySelectedServiceDetails.SUB_CATEGORY_NAME;
		const businessFunctionName = this.props.spcmReducer.currentlySelectedServiceDetails.BUSINESS_FUNCTION_NAME;

		return (
			<div>
				<div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">Translate - {this.props.fieldNameVal2} - {this.props.lanNameVal2}</div></div>

				<div className="rBoxGap">
					<form onSubmit={handleSubmit(this.submitForm)}>
						{error == undefined || error == null ? null : <Alert variant="danger" className="errorMessageSec"><p>{error}</p></Alert>}
						<Row>
							<Col md={12}>
								<Form.Group className="form-group" >
									<Form.Label bsClass="">Base Language</Form.Label>
									<Form.Control disabled value="English" type="text" />
								</Form.Group>
							</Col>

							<Col md={12}>
								<Form.Group className="form-group" >
									<Form.Label bsClass="">Business Function</Form.Label>
									<Form.Control disabled value={businessFunctionName} type="text" />
								</Form.Group>
							</Col>

							<Col md={12}>
								<Form.Group className="form-group" >
									<Form.Label bsClass="">Category</Form.Label>
									<Form.Control disabled value={category} type="text" />
								</Form.Group>
							</Col>

							<Col md={12}>
								<Form.Group className="form-group" >
									<Form.Label bsClass="">Sub-Category</Form.Label>
									<Form.Control disabled value={subcategory} type="text" />
								</Form.Group>
							</Col>

							<Col md={12}>
								<Form.Group className="form-group">
									<Form.Label bsClass=""><span className="rStar"></span> Translated Business Function {/*<Button onClick={() => this.props.translateCategory(category, this.props.languageCode)} className="margin-l-10 margin-b-5" title="Google Translate" bsSize="xsmall"><i className="fa fa-google"></i> Translate</Button>*/}</Form.Label>
									<Field component={_textArea} name="translated_business_function" className='form-control' rows="3" />
								</Form.Group>
							</Col>

							<Col md={12}>
								<Form.Group className="form-group">
									<Form.Label bsClass=""><span className="rStar"></span> Translated Category {/*<Button onClick={() => this.props.translateCategory(category, this.props.languageCode)} className="margin-l-10 margin-b-5" title="Google Translate" bsSize="xsmall"><i className="fa fa-google"></i> Translate</Button>*/}</Form.Label>
									<Field component={_textArea} name="translated_category" className='form-control' rows="3" />
								</Form.Group>
							</Col>

							<Col md={12}>
								<Form.Group className="form-group">
									<Form.Label bsClass=""><span className="rStar"></span> Translated Subcategory{/* <Button onClick={() => this.props.translateSubcategory(subcategory, this.props.languageCode)} className="margin-l-10 margin-b-5" title="Google Translate" bsSize="xsmall"><i className="fa fa-google"></i> Translate</Button>*/}</Form.Label>
									<Field component={_textArea} name="translated_subcategory" className='form-control' rows="3" />
								</Form.Group>
							</Col>


						</Row>
						<Row>
							<Col md={12}>
								<ButtonToolbar className="black">
									<Button className='rgSidrkBtn' title={tr['Save']} type="submit" bsSize="small" bsStyle="primary">{this.props.spcmReducer.spcmPostingAttribute ? <ImSpinner6 className="icn-spinner"/> : null} {tr['Save']}</Button>
									<Button className='rgSiLigBtn' onClick={() => { this.props.lanEditPageFun5(false) }} bsSize="small" bsStyle="text">Close</Button></ButtonToolbar>
							</Col>
						</Row>
					</form>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ spcmReducer, cartCount }) => {
	return {
		spcmReducer,
		baseLanguage: cartCount.languageText
	}
};


TextFieldForm = reduxForm({
	form: 'CategoryForm',
	validate,
	enableReinitialize: true
})(TextFieldForm)

export default connect(mapStateToProps, { spcmLoader, getAttributesTranslationStatus, languageListTranslationStatus, translate, getTranslationForOfferingNameDescriptionSummary, translateCategory, translateSubcategory, getLanguageTranslationForCategoryAndSubcategory, resetReducer })(TextFieldForm);