
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import axios from 'axios';

import { GLOBAL } from "_Globals";

const api = axios.create({
    headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

class XsmServiceQuickView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          breakFixServiceDetails: []
        };
    }
    componentWillMount() {
        if(this.props.offeringId == "" || this.props.offeringId !== undefined){      
          var str={};
                str.offeringId=this.props.offeringId;
                str = JSON.stringify(str);
          const request = api.get(GLOBAL.breakFixServiceDetailsDataUrl,{headers:{'query':str}})
            request.then((response)=>{
                  this.setServiceInfo(response);
            });
          }else{
          }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.categoryID != "") {
          var str = {};
          str.offeringId = nextProps.categoryID;
          str = JSON.stringify(str);
          const request = api.get(GLOBAL.breakFixServiceDetailsDataUrl, { headers: { 'query': str } });
          request.then((response) => {
            this.setServiceInfo(response);
          });
        } else {
        } 
    }
    setServiceInfo(service) {
        this.setState({
          breakFixServiceDetails: service.data
        });
    }
    render() {
        if (this.state.breakFixServiceDetails.length == 0) {
            return (<div></div>);
        }
        return (
            <div className="margin-b-20">
              <div className="border-b padding-b-10 margin-b-10 black f-size-20 fw-300">{this.props.translator['Service Quick View']}</div>
              <ListGroup bsClass="" className="ListDetails" as="ul">
                <ListGroup.Item as="li" bsClass=""><span className="labSpan">{this.props.translator['Service Name']}</span>{this.state.breakFixServiceDetails[0].offeringName}</ListGroup.Item>
                <ListGroup.Item as="li" bsClass=""><span className="labSpan">{this.props.translator['Summary']}</span>{this.state.breakFixServiceDetails[0].offeringSummary}</ListGroup.Item>
                <ListGroup.Item as="li" bsClass=""><span className="labSpan">{this.props.translator['Category']}</span>{this.state.breakFixServiceDetails[0].category}</ListGroup.Item>
                <ListGroup.Item as="li" bsClass=""><span className="labSpan">{this.props.translator['SubCategory']}</span>{this.state.breakFixServiceDetails[0].subCategory}</ListGroup.Item>
                <ListGroup.Item as="li" bsClass=""><span className="labSpan">{this.props.translator['Company Name']}</span>{this.state.breakFixServiceDetails[0].companyName}</ListGroup.Item>     
              </ListGroup>
            </div>
          );
    }
}
export default XsmServiceQuickView;