
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';// use to make ajax request from browser

//const ROOT_URL=`https://xsm-f.mybluemix.net/getServiceListForRestifySelfService/`

export const FETCH_SERVICELIST = 'FETCH_SERVICELIST';

export function loadServiceListSuccess(servicelist){
return{
		type: FETCH_SERVICELIST,
		servicelist

	};
}

export function loadServiceList(url){
	return(dispatch) =>{
		axios.get(url)
			.then((servicelist)=>{
				if(!servicelist){
					throw Error(servicelist.statusText);
				}
				return servicelist;
			})
			.then((servicelist) =>{
				dispatch(loadServiceListSuccess(servicelist))
			})
			.catch((err) => {
				console.log(err);
			});
	};
}
