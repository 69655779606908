
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector, connect } from 'react-redux';
import { currentOfferingDescription } from "../../../../actions/categorylist/categorylistAction";
import { getGlobalSearchDataForRevamp } from "../../../../actions/globalSearch/globalSearchAction";
import PindCategories from "../../../common/header/pind-categories";
import Cookies from "universal-cookie";
import { GLOBAL } from "_Globals";
import GlobalSearchListing from "./GlobalSearchListing";
import { navigationHooks } from "../../../../helpers/NavigationHook";
import { useLocation, useParams } from "react-router";

const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home") homepagelocation = "/" + homepagelocation;

let GlobalSearchIndex = () => {
  const location = useLocation();
  const globalSearchListSearchText = useSelector((state) => state.globalSearchListSearchText);
  const globalSearchListCategoryId = useSelector((state) => state.globalSearchListCategoryId);
  const [searchText, setSearchText] = useState("");
  const [categoryId, setCategoryId] = useState(0);

  useEffect(() => {
    setSearchText(location.state.searchText);
    setCategoryId(location.state.categoryId);
  }, [location]);
  
  useEffect(() => {
    setSearchText(globalSearchListSearchText);
  }, [globalSearchListSearchText]);
  
  useEffect(() => {
    setCategoryId(globalSearchListCategoryId);
  }, [globalSearchListCategoryId]);

  return (
    <>
      <GlobalSearchMain searchText={searchText} categoryId={categoryId} />
    </>
  );
};

export default GlobalSearchIndex;

class GlobalSearchMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenu: "0",
      showHideAlfy: false,
      showHideRightNav: false,
      listingMessage: "",
      detailsMessage: "",
    };
    GLOBAL.startindexSearch = 1;
    GLOBAL.endindexSearch = 10;
    this.showHideAlfy = this.showHideAlfy.bind(this);
    this.showHideRightNav = this.showHideRightNav.bind(this);
    this.changeCompMessage = this.changeCompMessage.bind(this);
    this.loadHome = this.loadHome.bind(this);
    this.locationState = navigationHooks.location;
  }
  componentWillMount() {
    this.props.getGlobalSearchDataForRevamp(
      this.locationState.state.searchText.trim(),
      this.locationState.state.categoryId,
      GLOBAL.startindexSearch,
      GLOBAL.endindexSearch
    );
    this.props.currentOfferingDescription(null);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.searchText !== nextProps.searchText) {
      this.props.getGlobalSearchDataForRevamp(
        nextProps.searchText.trim(),
        nextProps.categoryId,
        GLOBAL.startindexSearch,
        GLOBAL.endindexSearch
      );
      this.props.currentOfferingDescription(null);
    }
  }
  changeCompMessage(message, msgFor) {
    if (msgFor === "listView") {
      this.setState({ listingMessage: message });
    }
    if (msgFor === "detailsView") {
      this.setState({ detailsMessage: message });
    }
  }

  loadHome() {
    navigationHooks.navigate(homepagelocation);
  }
  showHideAlfy(value) {
    this.setState({ showHideAlfy: value });
  }
  showHideRightNav(value) {
    this.setState({ showHideRightNav: !this.state.showHideRightNav });
  }
  render() {
    let meta = this.props.metaData != undefined ? this.props.metaData.meta : "";
    let size = meta && meta.limit ? meta.limit : 0;
    let currentPage = meta && meta.currentPage ? meta.currentPage : 0;
    let startindex = size * (currentPage - 1) + 1;
    let endindex = size * currentPage;
    let recordCount = meta && meta.rowCount ? meta.rowCount : 0;
    return (
      <main>
        <div className="minHeight">
          <PindCategories />
          <div className="container-fluid margin-b-15">
            <nav aria-label="Breadcrumb">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a
                    href="javascript:void(0)"
                    role="button"
                    onClick={this.loadHome}
                  >
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item active">
                  {this.props.tr["Search Result"]}
                </li>
              </ul>
            </nav>
          </div>
          <div className="container-fluid">
            <div className="srResulcount">
              {recordCount != 0 ? (
                <span>
                  {startindex}-
                  {endindex > recordCount ? (endindex = recordCount) : endindex}{" "}
                  of {recordCount}
                </span>
              ) : null}{" "}
              results for{" "}
              <span className="keywrd">
                "{this.props.searchText?.substr(0, 1).toUpperCase() + this.props.searchText?.substr(1)}"
              </span>
            </div>
          </div>
          <div className="container-fluid" bsClass="">
            <Row className="row-eq-height innerMain">
              <Col lg={12} md={12} sm={12} xs={12} className="rwLeftPart">
                <GlobalSearchListing
                  changeCompMessage={this.changeCompMessage}
                  listingMessage={this.state.listingMessage}
                  refereshPage="true"
                  type="list"
                  globalSearchDataForRevampData={
                    this.props.globalSearchDataForRevamp
                  }
                  searchText={this.props.searchText}
                  categoryId={this.props.categoryId}
                />
              </Col>
            </Row>
          </div>
        </div>
      </main>
    );
  }
}
const mapStateToProps = ({
  globalSearchDataForRevamp,
  spcmReducer,
}) => {
  let data = globalSearchDataForRevamp.data;
  return {
    globalSearchDataForRevamp,
    metaData: data,
    tr: spcmReducer.tr,
  };
};

GlobalSearchMain = connect(mapStateToProps, {
  getGlobalSearchDataForRevamp,
  currentOfferingDescription,
})(GlobalSearchMain);
