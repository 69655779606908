
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";

import GeneralSection from "./GeneralSection";
import DynamicNestedMenu from "./DynamicNestedMenu";
import ListLoader from "../../../common/loaders/ListLoader";
const cookies = new Cookies();
import Cookies from 'universal-cookie';
import { useSelector } from "react-redux";
import { Col, Form, Nav, NavDropdown, Row } from "react-bootstrap";
let hometab_preference = cookies.get('gph');
if (hometab_preference) hometab_preference = hometab_preference.replace('s:', '');
if (hometab_preference) hometab_preference = hometab_preference.substring(0, hometab_preference.lastIndexOf('.'));
hometab_preference = hometab_preference.split("~");
let catwise = hometab_preference[15];

const LeftPanelIndex = ({
  businessFnLabels,
  isFetchingBusinessFn,
  setActiveTab,
  setActiveTabEventKey,
  businessFnApiError,
  setActiveTabId,
  activeMenuSubMenuItems,
  udpateActiveMenuSubMenuItems,
  url,
  labelType,
  start,
  end,
  isGeneralSection,
  parentLabelType,
  parentLabelId,
  parentLabel,
  activeTabEventKey
}) => {
  const tr = useSelector((state) => state.spcmReducer.tr);

  return (
    <>
      <div className="categryListDv d-sm-block d-none">
        {isFetchingBusinessFn ? (
          <ListLoader />
        ) : (
          <>
            <GeneralSection
              businessFnLabels={businessFnLabels}
              isFetchingBusinessFn={isFetchingBusinessFn}
              setActiveTab={setActiveTab}
              setActiveTabEventKey={setActiveTabEventKey}
              setActiveTabId={setActiveTabId}
              businessFnApiError={businessFnApiError}
              udpateActiveMenuSubMenuItems={udpateActiveMenuSubMenuItems}
              activeTabEventKey={activeTabEventKey}
            />
            {catwise == "Y" || catwise == "y" ? (
              <p>{tr["All Categories"]}</p>
            ) : null}
            {catwise == "Y" || catwise == "y" ? (
              <DynamicNestedMenu
                businessFnLabels={businessFnLabels}
                isFetchingBusinessFn={isFetchingBusinessFn}
                setActiveTab={setActiveTab}
                setActiveTabEventKey={setActiveTabEventKey}
                setActiveTabId={setActiveTabId}
                businessFnApiError={businessFnApiError}
                url={url}
                labelType={labelType}
                isGeneralSection={isGeneralSection}
                start={start}
                end={end}
                parentLabel={parentLabel}
                parentLabelId={parentLabelId}
                parentLabelType={parentLabelType}
                activeMenuSubMenuItems={activeMenuSubMenuItems}
                udpateActiveMenuSubMenuItems={udpateActiveMenuSubMenuItems}
              />
            ) : null}
          </>
        )}
      </div>
    </>
  );
};

export default LeftPanelIndex;
