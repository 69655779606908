
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { loadCIClass, loadCICompany} from '../../../actions/cmdb/lovAction';
import { Row, Col,FormGroup,ControlLabel,FormControl,Table,Button, PageHeader} from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GLOBAL from '_Globals';
import { Field, change, reduxForm } from 'redux-form';
import Form from 'react-bootstrap/Form';
import { getCICategoryListData, emptyCICategoryListDataReducer } from '../../../actions/categoryBoard/quickViewAction';
import ListLoader from '../../../components/common/loaders/ListLoader';
import getCategoryBoardDetails from './CiCategoryIndex';
import TableComponent from '_Commons/ReactTable/TableComponent';
import {IoSaveOutline, IoClose} from "react-icons/io5";
import { loadClassByCompanyId, resetCiClass } from '../../../actions/ham/hamAction';

const validate = values => {
	let errors = {};
	if(!values.ciCompany || values.ciCompany === "" || values.ciCompany === null) {
		errors.ciCompany = "Select Company"
	}
	if(!values.ciClass || values.ciClass === "" || values.ciClass === null) {
		errors.ciClass = "Select Class"
	}
	return errors;
}
class CiCategoryList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			// catStatus:true,
			ciCompany:'',
			ciStatus:'active',
			ciClass:'',
			ciClassErrorColor: '',
			ciCompanyErrorColor: '',
			ciStatusErrorColor:'',
			editRowIndex: -1,
			ciClassName:''
		};
		this.actionFormatter = this.actionFormatter.bind(this);
		this.onRowSelect = this.onRowSelect.bind(this);
	}
		tableColumns = [
			{
			  name: this.props.tr["Category"],
			  selector: (row) => row.category_name,
			  sortable: true,
			},
			{
			  name: this.props.tr["Sub Category"],
			  selector: (row) => row.sub_category_name,
			  sortable: true,
			},
			{
			  name: this.props.tr["Status"],
			  selector: (row) => row.status1,
			  sortable:  true,
			},
		  ];
		  filterColumns = ["category_name", "sub_category_name", "status"];	
	componentWillMount() {
		// this.props.loadCIClass();
		this.props.resetCiClass();
		this.props.loadCICompany("0");
	}
	componentWillUnmount() {
		this.props.emptyCICategoryListDataReducer();
	}
	renderCompany(companyData) {
		if (!companyData) {
			return (
				<option></option>
			);
		}
		return companyData.map((companyObj, i) => {
			return (
				<option company = {companyObj.field1Key} value={companyObj.field1Value} key={i}>{companyObj.field1Key}</option>
			);
		});
	}
	renderClass(classData) {
		if (!classData) {
			return (
				<option></option>
			);
		}
		return classData.map((classObj, i) => {
			return (
				<option value={classObj.CLASS_ID} key={i}>{classObj.CLASS_NAME}</option>
			);
		});
	}
	validateField(fieldType, event) {
		console.log("EVENT=========>",event.target.value)
		switch (fieldType) {
			case 'ciClass':
				if (event.target.value == "") {
					this.setState({ ciClassErrorColor: 'error' });
				} else {
					this.setState({ ciClassErrorColor: '' });
				}
				break;
				case 'ciCompany':
				if (event.target.value == "") {
					this.setState({ ciCompanyErrorColor: 'error' });
				} else {
					this.setState({ ciCompanyErrorColor: '' });
				}
				break;
				case 'ciStatus':
				if (event.target.value == "") {
					this.setState({ ciStatusErrorColor: 'error' });
				} else {
					this.setState({ ciStatusErrorColor: '' });
				}
				break;
			default:
		}
	}
	handleciCompanyChange(event)  {
		this.setState({ciCompany: event.target.value});
		console.log("this.state.ciClass",this.state.ciClass)
		this.props.setIsEditing(false);
		let status = 'ALL';
		let hamCall = false;
		this.props.loadClassByCompanyId(event.target.value,hamCall,status);
		this.setState({ciClass: ""});
		this.props.dispatch(change("CiCategoryList", "ciClass", ""));
	// 	if (this.state.ciClass !== "" && this.state.ciStatus !== "" && event.target.value !== '') {
	// 	this.props.getCICategoryListData(event.target.value, this.state.ciClass, this.state.ciStatus)
	// 	this.props.setListMetaData(this.state.ciStatus, this.state.ciClassName)
	// }
		console.log("event.target.value",event.target.value)
		this.props.resetRightSide();
		this.props.showRightSide(false);
	};
	handleciClassChange(event) {
		this.setState({ciClass: event.target.value});
		console.log("Insde class chnge",event.target.value)
		const ciClassName = event.target.options[event.target.selectedIndex].text;
		this.setState({ciClassName: ciClassName});
		console.log("className===>",ciClassName)
		this.props.setIsEditing(false)
		if (this.state.ciCompany !== "" && this.state.ciStatus !== "" && event.target.value !== "") {
		console.log("Inside if of class")
		this.props.getCICategoryListData(this.state.ciCompany, event.target.value, this.state.ciStatus)
		this.props.setListMetaData(this.state.ciStatus, ciClassName)}
		console.log("setciClass",event.target.value)
		console.log("For Limit",event.target)
		this.props.resetRightSide();
		this.props.showRightSide(false);
	};
	handleciStatusChange(event) {
		this.setState({ciStatus: event.target.value});
		console.log("Before if",this.state.ciStatus)
		this.props.setIsEditing(false)
		if (this.state.ciCompany !== "" && this.state.ciClass !== "" && event.target.value !== "") {
		console.log("Inside if of class")
		this.props.getCICategoryListData(this.state.ciCompany,this.state.ciClass, event.target.value)
		this.props.setListMetaData(event.target.value, this.state.ciClassName)}
		console.log("ciStatus",event.target.value)
		console.log("For ciStatus",event.target)
		this.props.resetRightSide();
		this.props.showRightSide(false);
	};
	onRowSelect(row, isSelected, e, rowIndex) {
        let rowStr = '';
        for (const prop in row) {
            if (row.hasOwnProperty(prop)) {
            rowStr += prop + ': "' + row[prop] + '"';
			console.log("Inside row select")
        }
    }	console.log("this.row", row);
		this.props.setIsEditing(false);
        this.props.getCategoryBoardDetails(
			row.category_name, 
			row.sub_category_name, 
			row.cat_company_name, 
			row.status, 
			row.class_id, 
			row.category_id, 
			row.cat_company_id
			);
			this.props.showRightSide(true);
    }
	handleEditClick(rowIndex){
		this.setState({ editRowIndex: rowIndex });
	  };
	
	  handleCancelClick(){
		this.setState({ editRowIndex: -1 });
	  };
	
	  handleSaveClick(rowIndex){
		this.setState({ editRowIndex: -1 });
	  };
	  handleDeleteClick(cell, row) {
        return (
            <div className='text-c' onClick={() => this.onDeleteClick(row.id)}><i className="fa fa-trash-o cursorPoint" ></i></div>
        );
    }
	  actionFormatter(cell, row, rowIndex){
		if (rowIndex === this.state.editRowIndex) {
		  return (
			<div>
				<Button bsStyle="" className='margin-r-5 smbtnsave' title="Save" onClick={() => this.handleSaveClick(rowIndex)}><IoSaveOutline/></Button>
			  <Button bsStyle="" title="Cancel" onClick={() => this.handleCancelClick}>
			  <IoClose/>
			  </Button>
			</div>
		  );
		} else {
		  return (
			<div>
			  <Button className='margin-r-5' bsStyle="" title="Edit" onClick={() => this.handleEditClick(rowIndex)}><i className="fa fa-pencil" aria-hidden="true"></i></Button>
			  <Button className='myBt rclose' title="Delete" bsStyle="" onClick={() => this.handleDeleteClick(rowIndex)}><i className="fa fa-trash"></i></Button>
			</div>
		  );
		}
	  };
	  renderCICategoryTableList = (ciCategoryData) => {
		console.log("ciCategoryData===>",ciCategoryData)
		const capitalizedData = ciCategoryData.map(item => {
			return {
			  ...item,
			  status1: item.status.charAt(0).toUpperCase() + item.status.slice(1)
			};
		  });	  
        return (
          <div className="dtaTableDv integrateHubTable margin-xs-t-15">
            <div className={this.props.rightDisplay ? "leftTrue" : "rightTrue"}>
              <TableComponent
                data={capitalizedData}
                columns={this.tableColumns}
                headerColumns={this.filterColumns}
                onRowSelect={this.onRowSelect}
                rightDisplay={this.props.rightDisplay}
                uniqueKey={'category_id'}
              />
            </div>
         </div>
        );
      };
	showData(){
		console.log("getCICategoryListData============>",this.props.ciCategoryListData.data)
        if (this.props.ciCategoryListData.data == undefined)  {
            return (
                <div className='myOrderDiv'>
                    {this.props.ciCategoryListDataLoader ? <div className='margin-t-40'><ListLoader /></div> :this.renderCICategoryTableList([])}
                </div>
            );
        } else {
            return (
                <div className='myOrderDiv'>
                    {this.props.ciCategoryListDataLoader ? <div className='margin-t-40'><ListLoader /></div> : this.renderCICategoryTableList(this.props.ciCategoryListData.data)}
                </div>
            );
        }
    }
	render(){
		const tr=this.props.tr;
		console.log("this.props.ciCategoryListData",this.props.ciCategoryListData)
		console.log("Inside render",this.state.ciClass)
		console.log("Insideeeee render",this.state.ciCompany)
		console.log("Insideeeee render 2",this.props.ciCategoryListData);
		return (
			<div>
				<Row>
					<Col md={4}>
					<Form.Group className="form-group" validationState={this.state.ciCompany == '' ? this.state.ciCompanyErrorColor : ''}>
							<Form.Label bsClass=""><span className="rStar"></span> {this.props.tr['Company']}</Form.Label>
							    <Field name="ciCompany" className={`form-control ${this.state.ciCompany == '' ? this.state.ciCompanyErrorColor : ''}`} component="select" onBlur={(event) => { this.validateField('ciCompany', event) }} onChange={(event) => { this.handleciCompanyChange(event) }} >
								<option value="">Select</option>
									<option value="0">Default</option>
									{this.renderCompany(this.props.ciCompany)}
								</Field>									 
						</Form.Group>
					</Col>
					<Col md={4}>
					<Form.Group className="form-group" validationState={this.state.ciClass == '' ? this.state.ciClassErrorColor : ''}>
							<Form.Label bsClass=""><span className="rStar"></span> {this.props.tr['Class']}</Form.Label>
							<Field name="ciClass" className={`form-control ${this.state.ciClass == '' ? this.state.ciClassErrorColor : ''}`} component="select" onBlur={(event) => { this.validateField('ciClass', event) }} onChange={(event) => { this.handleciClassChange(event) }}>
									<option value="">Select</option>
									{this.renderClass(this.props.compClass)}
								</Field>
						</Form.Group>
					</Col>
					<Col md={4}>
					<Form.Group className="form-group" validationState={this.state.ciStatus == '' ? this.state.ciStatusErrorColor : ''}>
							<Form.Label bsClass=""><span className="rStar"></span> {this.props.tr['Status']}</Form.Label>
							<Field name="ciStatus" className={`form-control ${this.state.ciStatus == '' ? this.state.ciStatusErrorColor : ''}`} component="select" onBlur={(event) => { this.validateField('ciStatus', event) }} onChange={(event) => { this.handleciStatusChange(event) }}>
									<option value="active">Active</option>
    								<option value="inactive">Inactive</option>
    								<option value="both">All</option>
								</Field>
						</Form.Group> 
					</Col>
				</Row><div>
					<h2 style={{position:"relative",top:"35px",zIndex:"2"}} className="sPageHeading1 display-inline-block"><span ></span>{tr['Selected Categories']}</h2>
					<div className>
						<div className="cIcatBordDv">
									{this.showData()}		
						</div>
					</div>
				</div>
			</div>
		);
	}
}
CiCategoryList = reduxForm({
	form: 'CiCategoryList',
	validate,
	destroyOnUnmount: true

})(CiCategoryList);
const mapDispatchToProps = (dispatch) =>{
	return bindActionCreators({  loadClassByCompanyId, resetCiClass, loadCIClass, loadCICompany, getCICategoryListData, emptyCICategoryListDataReducer}, dispatch)
	}

const mapStateToProps = ({compClass,ciClass, ciCompany, ciCategoryListData, ciCategoryListDataLoader}) =>{
	return {compClass, ciClass, ciCompany, ciCategoryListData, ciCategoryListDataLoader: ciCategoryListDataLoader.loading}
	}

export default connect(mapStateToProps, mapDispatchToProps)(CiCategoryList)