
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Row, Col, Form, Alert, ButtonToolbar, Button } from 'react-bootstrap';
import { _textArea, _dropDown, _inputField } from '../../common/formFields';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import axios from 'axios';
import { spcmLoader, getChainEntitiesCompanyList, setLeftEditId, getServiceDetailCounts, getChainEntities, getConsumptionItems, getCompanyList, getGroupList, getManagerList } from '../../../actions/spcmActions';
// import GLOBAL from '../../../components/Globals';
import { GLOBAL } from '_Globals';
import {ImSpinner6} from "react-icons/im";

const validate = (values, props) => {

	let errors = {};
	if (!values.chain_type) {
		errors.chain_type = 'Select Role';
	} else {
		/* props.chainEntities.forEach(role=>{
			if(role.CHAIN_TYPE==values.chain_type){
				errors.chain_type = 'Role already Exists';
			}

		}) */
	}
	if (!values.chain_company_id) {
		errors.chain_company_id = 'Select Company';
	}
	if (!values.chain_group_id) {
		errors.chain_group_id = 'Select Group';
	}
	if (!values.chain_person_id) {
		errors.chain_person_id = 'Select Manager';
	}

	if (!values.chain_person_email) {
		errors.chain_person_email = 'Enter Contact Email';
	}

	if (!values.assignment_for) {
		errors.assignment_for = 'Select Assignment For';
	}

	return errors;
}

class ComponentForm extends React.Component {
	constructor(props) {
		super(props);
		this.onCompanySelect = this.onCompanySelect.bind(this);
		this.onGroupSelect = this.onGroupSelect.bind(this);
		this.onRoleChange = this.onRoleChange.bind(this);
		this.submitForm = this.submitForm.bind(this);

		this.state = {
			assignmentFor: false,
			isRollSelected: false
		};
	}
	componentWillMount() {
		if (this.props.spcmReducer.editLeftId > 0){
			this.props.getChainEntitiesCompanyList(GLOBAL.SelectedServiceDetailcompanyID, this.props.initialValues.chain_type);
			this.props.getGroupList(this.props.initialValues.chain_company_id);
		}
		if (this.props.spcmReducer.currentlySelectedServiceDetails.WORK_ITEM_TYPE == "incidentRequest") {
			if (this.props.initialValues.assignment_for == "resolver" || this.props.initialValues.assignment_for == "criticalIncidentManager") {
				this.setState({ assignmentFor: true });
			}
		}
		if (this.props.spcmReducer.currentlySelectedServiceDetails.WORK_ITEM_TYPE == "standardChange") {
			if (this.props.initialValues.assignment_for == "implementation" || this.props.initialValues.assignment_for == "changemanager") {
				this.setState({ assignmentFor: true });
			}
		}
		console.log("this.props.initialValues.assignment_for", this.props.initialValues.assignment_for);
		console.log("this.props.spcmReducer.currentlySelectedServiceDetails.WORK_ITEM_TYPE", this.props.spcmReducer.currentlySelectedServiceDetails.WORK_ITEM_TYPE);
	}

	componentWillUnmount() {
		this.props.spcmLoader('attribute', 'success');
	}


	onRoleChange(e) {
		console.log("e>>>", e.target.value);
		// e.target.value.trim() == "" || 
		// console.log("serviceWorkItemType>>>>>>>>>>", this.props.spcmReducer.currentlySelectedServiceDetails.WORK_ITEM_TYPE);

		this.props.getChainEntitiesCompanyList(GLOBAL.SelectedServiceDetailcompanyID, e.target.value);
		if (this.props.spcmReducer.currentlySelectedServiceDetails.WORK_ITEM_TYPE == "incidentRequest" || this.props.spcmReducer.currentlySelectedServiceDetails.WORK_ITEM_TYPE == "standardChange") {

			if (e.target.value.trim() == "") {
				this.setState({ assignmentFor: false, isRollSelected: false });
			}
			else if (e.target.value == "Support") {
				this.setState({ assignmentFor: true, isRollSelected: true });
			}
			else if (e.target.value.trim() == "" || e.target.value == "Provider" || e.target.value == "Orchestrator" || e.target.value == "Broker" || e.target.value == "Aggregator") {
				this.setState({ assignmentFor: false, isRollSelected: true });
			}
		} else {
			if (e.target.value == "") {
				this.setState({ isRollSelected: false });
			} else {
				this.setState({ isRollSelected: true });
			}
		}
	}

	onCompanySelect(e){
			const { change } = this.props;
			change('chain_group_id','');
			this.props.getGroupList(e.target.value);
			let groupList = this.props.getGroupList(e.target.value);
			console.log("groupList>>>",groupList);
	}

	onGroupSelect(e) {
		console.log("e.target.value",e.target.value);

		console.log("e.target1231");
		console.log(e.target.isExternal);
		this.props.getManagerList(e.target.value);
	}

	submitForm(values) {
		delete values.isexternal;
		console.log("value>>", values);

		if (this.props.spcmReducer.currentlySelectedServiceDetails.WORK_ITEM_TYPE == "serviceRequest") {
			if (values.chain_type == "Provider") {
				values.assignment_for = "fulfiller";
				values.assignment_id = "35";
			}
			else {
				values.assignment_for = "resolver";
				values.assignment_id = "10";
			}
		}
		else if (this.props.spcmReducer.currentlySelectedServiceDetails.WORK_ITEM_TYPE == "standardChange") {
			console.log("ASDF+++")
			if (values.assignment_for == "implementation") {
				values.assignment_for = "implementation";
				values.assignment_id = "25";
			}
			else if (values.assignment_for == "changemanager") {
				values.assignment_for = "changemanager";
				values.assignment_id = "30";
			}
		}
		else {
			if (values.assignment_for == "resolver") {
				values.assignment_for = "resolver";
				values.assignment_id = "10";
			}
			else {

				values.assignment_for = "criticalIncidentManager";
				values.assignment_id = "15";
			}
		}
		if (this.props.spcmReducer.editLeftId > 0) {
			values.updatedby_name = "";
			values.updatedby = "";
		} else {
			values.createdby_name = "";
			values.created_by = "";
		}

		values.offering_id=this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID;
				this.props.spcmReducer.chain_entities_company.forEach(function(val) { //chain_entities_company
					if(val.field1Value==values.chain_company_id)
						values.chain_company=val.field1Key;
				}, this);
		console.log("this.props.spcmReducer.groups");
		console.log(this.props.spcmReducer.groups);
				this.props.spcmReducer.groups.forEach(function(val) {
			if (val.field1Value == values.chain_group_id){
				console.log("val140");
				console.log(val);
				values.chain_group_name = val.field1Key;
				values.isExternal=(val.isExternal==null || val.isExternal=="" || val.isExternal== undefined) ? "false" : val.isExternal;
			}
				}, this);
				// this.props.spcmReducer.managers.forEach(function(val) {
				// 	if(val.field1Value==values.chain_person_id)
				// 		values.chain_person_name=val.field1Key;
				// }, this);
				if(this.props.spcmReducer.editLeftId>0){
					this.props.spcmLoader('attribute','posting');
			console.log("valuesedit123==");
			console.log(values);
					return axios.patch("/service/api/patchChainEntities/"+this.props.spcmReducer.editLeftId,values).then((response) => {
						this.props.getChainEntities(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
						this.props.componentFormFun4(false);
						this.props.spcmLoader('attribute','success');
					})
					.catch((error) => {
					
						this.props.spcmLoader('attribute','success');
						if(!error.response.data[0]){
							throw new SubmissionError({_error: error.response.data.message });
						}
						else{
							throw new SubmissionError({_error: error.response.data[0].message });
						}
						
						
					});
				}
				else{
					this.props.spcmLoader('attribute','posting');
			console.log("values123==");
			console.log(values);
					return axios.post("/service/api/saveChainEntities",values).then((response) => {
						this.props.getChainEntities(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
						this.props.getServiceDetailCounts(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
						this.props.componentFormFun4(false);
						this.props.spcmLoader('attribute','success');
					})
					.catch((error) => {
				
						this.props.spcmLoader('attribute','success');
						if(!error.response.data[0]){
							throw new SubmissionError({_error: error.response.data.message });
						}
						else{
							throw new SubmissionError({_error: error.response.data[0].message });
						}					});
				}
	}
	render(){
		const tr=this.props.tr;
		const {error,handleSubmit} = this.props;
		const {isRollSelected} = this.state;
		const chain_type=[
			{text:"Aggregator",value:"Aggregator"},
			{text:"Broker",value:"Broker"},
			{text:"Orchestrator",value:"Orchestrator"},
			{text:"Provider",value:"Provider"},
			{text:"Support",value:"Support"},
			/* {text:"Consumer",value:"Consumer"} */
		];
		// const company = isRollSelected ? this.props.spcmReducer.chain_entities_company: [];
		const company =  this.props.spcmReducer.chain_entities_company;

		const groups = this.props.spcmReducer.groups;
		const managers = this.props.spcmReducer.managers;
		let assignmentForDropdownArray = [];
		assignmentForDropdownArray = [{ 'field1Key': 'Resolver', 'field1Value': 'resolver' }, { 'field1Key': 'Critical Incident Manager', 'field1Value': 'criticalIncidentManager' }];
		let assignmentForDropdownStandardChange = [];
		assignmentForDropdownStandardChange = [{ 'field1Key': 'Implementation', 'field1Value': 'implementation' }, { 'field1Key': 'Change Manager', 'field1Value': 'changemanager' }]
		const serviceWorkItemType = this.props.spcmReducer.currentlySelectedServiceDetails.WORK_ITEM_TYPE;

		return (
			<div className="rBoxGap">
				<form onSubmit={handleSubmit(this.submitForm)}>
					{error == undefined || error == null ? null : <Alert variant="danger" className="errorMessageSec"><p>{error}</p></Alert>}

					<Row>
						<Col md={12}>
							<Form.Group className='form-group'>
								<Form.Label bsClass=""><span className="rStar"></span> {tr['Role']}</Form.Label>
								<Field component={_dropDown} options={chain_type} name="chain_type" className='form-control' onChange={this.onRoleChange}>
								</Field>
							</Form.Group>
						</Col>
						<Col md={12}>
							<Form.Group className='form-group'>
								<Form.Label bsClass=""><span className="rStar"></span> {tr['Company Name']}</Form.Label>
								<Field component={_dropDown} options={company} name="chain_company_id" onChange={this.onCompanySelect} className='form-control'>
								</Field>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<Form.Group className='form-group'>
								<Form.Label bsClass=""><span className="rStar"></span> {tr['Groups']}</Form.Label>
								<Field component={_dropDown} options={groups} name="chain_group_id" onChange={this.onGroupSelect} className='form-control'>
								</Field>
							</Form.Group>
						</Col>
						{/* <Col md={6}>
						<Form.Group >
						  <Form.Label bsClass=""><span className="rStar"></span> Manager</Form.Label>
						  <Field component={_dropDown} options={managers} name="chain_person_id"  className='form-control'>
							</Field>
						 </Form.Group>
					</Col>
					<Col md={6}>
						<Form.Group >
						  <Form.Label bsClass=""><span className="rStar"></span> Contact Email</Form.Label>
						  <Field type="text" component={_inputField} name="chain_person_email" className='form-control'/>
						 </Form.Group>
					</Col> */}
					</Row>
					{this.state.assignmentFor ? (
						<Row>
							<Col md={12}>
								<Form.Group className='form-group'>
									<Form.Label bsClass=""><span className="rStar" /> {tr['Assignment For']}</Form.Label>
									{serviceWorkItemType == "standardChange" ? (
										<Field
											component={_dropDown}
											options={assignmentForDropdownStandardChange}
											name="assignment_for"
											className="form-control"

										/>
									) : (
										<Field
											component={_dropDown}
											options={assignmentForDropdownArray}
											name="assignment_for"
											className="form-control"
										/>
									)
									}

								</Form.Group>
							</Col>
						</Row>
					) :
						(
							""
						)
					}
					<Row className='margin-t-10'>
						<Col md={12}>

							<ButtonToolbar className="black">
								<Button className='rgSidrkBtn' title="Save" disabled={this.props.spcmReducer.spcmPostingAttribute} type="submit" bsSize="small" bsStyle="primary">{this.props.spcmReducer.spcmPostingAttribute ? <ImSpinner6 className="icn-spinner"/> : null} {tr['Save']}</Button>
								<Button className='rgSiLigBtn' title="Close" onClick={() => { this.props.componentFormFun4(false) }} bsSize="small" bsStyle="text">{tr['Close']}</Button></ButtonToolbar>
						</Col>
					</Row>
				</form>
			</div>
		);
	}
}

const mapStateToProps = ({ spcmReducer }) => {
	return {
		spcmReducer,
		initialValues:spcmReducer.editLeftId>0?spcmReducer.editLeftService:''
	}
};

ComponentForm = reduxForm({
	form: 'chainEntitiesForm',
	validate,
	enableReinitialize: true
})(ComponentForm)
export default connect(mapStateToProps, { spcmLoader, getChainEntitiesCompanyList, setLeftEditId, getServiceDetailCounts, getChainEntities, getConsumptionItems, getCompanyList, getManagerList, getGroupList })(ComponentForm);
