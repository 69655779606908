
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TableComponent from '../../../common/ReactTable/TableComponent';
import ListLoader from '_Commons/loaders/ListLoader';

const ListSec = (props) => {

    const [companyStatusData, setCompanyStatusData] = useState([]);

    const companyFdnList = useSelector((state) => state.companyFdnList);
    const showLoader = useSelector((state) => state.commonLoader.isLoading);

  const onRowSelect = (row) => {
    props.companyDataHandeler(row);
    props.showRightSideFun(true);
  }
    const tableColumns = [
        {
          name: props.translator["Company"],
          selector: (row) => row.name,
          sortable: true,
        },
        {
            name: props.translator["Company Code"],
            selector: (row) => row.company_code,
            sortable: true,
         },
        {
        name: props.translator["Company Type"],
        selector: (row) => row.companyTypes,
        sortable: true,
        cell: (row) => (<div title={row.companyTypes}>{row.companyTypes}</div>)
        },
        {
          name: props.translator["Status"],
          selector: (row) => row.status == 1? "Active":"Inactive",
          sortable: true,
        }
    ];

    useEffect(() => {
      if (companyFdnList.length > 0) {
        setCompanyStatusData(companyFdnList)
      }
      if (companyFdnList.length == 0) {
        setCompanyStatusData([])
        }
      },[companyFdnList])
    
      const filterColumns = ["name", "status", "companyTypes", "company_code"];
    const renderFoundationCompanyTableList = () => {
        return (
            <div className="dtaTableDv integrateHubTable">
              <div className={`${props.isSuperAdmin == 'Y' ? (props.isRightSideVisible ? "leftTrue srchPosTop fdnPos" : "rightTrue srchPosTop fdnPos"): (props.isRightSideVisible ? "leftTrue srchPosTop fdnPos cmpSrchPos" : "rightTrue srchPosTop fdnPos cmpSrchPos")}`}>
                <TableComponent
                  data={companyStatusData}
                  columns={tableColumns}
                  headerColumns={filterColumns}
                  onRowSelect={onRowSelect}
                  rightDisplay={props.isRightSideVisible}
                  uniqueKey={'id'}
                />
              </div>
            </div>
          );
    }

    if (!companyStatusData) return null;
    if (companyStatusData.length == undefined) {
      return <ListLoader />;
    } else if (companyStatusData.length === 0) {
      return (
        renderFoundationCompanyTableList()
      )
    } else {
      return (
        <div className="myOrderDiv">
          {
          showLoader ? (
            <ListLoader />
          ) : (
            renderFoundationCompanyTableList()
          )
          }
        </div>
      );
    }
  };
export default ListSec;