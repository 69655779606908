
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useRef, useState } from "react";
import RestApiConnector from "./restApi";
import Breadcrumbs from "../../../common/header/breadcrumbs";
import { Button, Container, Row, Col, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

export default function CreateUpdateConnector(){
    const connectorRef = useRef();
    const params = useParams();
    const tr = useSelector((state) => state.spcmReducer.tr);
    const [validated, setValidated] = useState(true);
    const [submittingForm, setSubmittingForm] = useState(false);
    const navigate = useNavigate();

    const requestValidated = (value) => {
        setValidated(value);
    }

    const loading = (value) => {
        setSubmittingForm(value);
    }

    return (
        <div>
            <div className="container-fluid margin-t-10 margin-b-15">
                <Breadcrumbs activePageName={params.id ? "Edit":"Create"} parentPageurl="/connectors" parentPageName="Connectors" />
            </div>
            <Container fluid className="margin-t-10 margin-b-15">
                <Row className="margin-b-15">
                    <Col lg={6} md={6} sm={6} xs={5}>
                        <h1 className="sPageHeading1">
                            {"Connector"}
                        </h1>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={7}>
                        <div className="paHedFilterSecDv">
                            <div className="paHedFilter">
                                <Button
                                    type="button"
                                    onClick={() => {connectorRef.current?.handleSubmit()}}
                                    className="myBt save"
                                    title={tr["Save"]}
                                    disabled={validated || submittingForm}
                                    
                                >
                                    { submittingForm
                                        ? (<Spinner animation="border" size="sm" />)
                                        : (<i aria-hidden="true" className="fa fa-save"></i>)
                                    }
                                </Button>
                                <Button
                                    type="button"
                                    onClick={() => navigate('/connectors')}
                                    className="myBt"
                                    title={tr["Close"]}
                                >
                                    <i
                                        aria-hidden="true"
                                        className="fa fa-close"
                                    ></i>
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
                <RestApiConnector 
                    ref={connectorRef} 
                    requestValidated={requestValidated} 
                    recordId={params.id}
                    loading={loading}
                ></RestApiConnector>
            </Container>
        </div>
    )
}