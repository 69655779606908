
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import CreateCiResolutionCategoryForm from "./CreateCiResolutionCategoryForm";
import CreateCiResolutionCategoryHeader from './CreateCiResolutionCategoryHeader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTranslation } from '../../../../actions/spcmActions';
import Cookies from "universal-cookie";
import { navigationHooks } from "../../../../helpers/NavigationHook.js";
import "_Css/form/_form.scss";
// import CiCategoryRightPart from "../CiCategoryRightPart.js";
let GLOBAL = require("_Globals");
const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
	homepagelocation = homepagelocation.substring(
		0,
		homepagelocation.lastIndexOf(".")
	);
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home") homepagelocation = "/" + homepagelocation;


	const CreateCiResolutionCategoryIndex= (props) => {
		const dispatch = useDispatch();
		const tr = useSelector((state) => state.spcmReducer.tr);
	  const [showLoader, setShowLoader] = useState("false");
	  const [extraCounts, setExtraCounts] = useState([0]);
	  
	
		GLOBAL.searchbarcategory = "All";
		
	
	const loadHome = () => {
		navigationHooks.navigate(homepagelocation);
	}
	const setRowCount = (count) => {
		setExtraCounts(count);
	}
	
		return (
			<main>
				<div className="minHeight">
					<div className="container-fluid margin-t-10 margin-b-15">
						<nav aria-label="Breadcrumb">
							<ul className="breadcrumb">
								<li className="breadcrumb-item">
									<a
										role="button"
										href="javascript:void(0)"
										onClick={loadHome}
									>
										{tr["Home"]}
									</a>
								</li>
								<li className="breadcrumb-item">
									<Link title={tr["Category Board"]} to="/categoryBoard">{tr["Category Board"]}</Link>
								</li>
								<li className="breadcrumb-item active">
									{tr["Create"]}
								</li>
							</ul>
						</nav>
					</div>
					 <div className="container-fluid" bsClass="">
						<Form>
							<CreateCiResolutionCategoryHeader showLoader={showLoader} tr={tr} extraCounts={extraCounts}/>
							<Row className="">
								<Col md={8} sm={12} xs={12} className="rwLeftPart">
									<CreateCiResolutionCategoryForm tr={tr} setRowCount={setRowCount} />
								</Col>
								{/* <Col md={4} sm={6} xs={12} className="">
									<div className="rBoxStyle commonForm">
										<CiCategoryRightPart tr={this.tr} />
									</div>
								</Col> */}
							</Row>
						</Form>
					</div> 
				</div>
			</main>
		);
	
							}


export default CreateCiResolutionCategoryIndex;
