
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const myThComponent = (e) => {
  e.DomComponents.addType("custom-th", {
    model: {
      defaults: {
        tagName: "th",
        traits: [
          {
            type: "text",
            label: "Header Text",
            name: "innerText",
            changeProp: 1,
          },
          {
            type: "checkbox",
            label: "Is Sortable",
            name: "isSortable",
            changeProp: 1,
          },
          {
            type: "checkbox",
            label: "Is Filterable",
            name: "isFilterable",
            changeProp: 1,
          },
          {
            type: "select",
            label: "Filter Field",
            name: "fitlerFieldType",
            options: [
              {
                id: "normalInput",
                label: "Input",
              },
              {
                id: "dropdownField",
                label: "Dropdown Field",
              },
              {
                id: "multiSelectCheckbox",
                label: "Multiselect Checkbox",
              },
              {
                id: "dateSelector",
                label: "Date Range",
              },
            ],
            changeProp: 1,
          },
          {
            type: "text",
            label: "Binding Key",
            name: "bindingKey",
            changeProp: 1,
          },
        ],
      },
      init() {
        this.on("change:optionsType", function () {
          this.handleTraitsChange();
        });
        this.on("change:innerText", this.handleInnerTextChange);
        this.on("change:fitlerFieldType", this.handleFilterTypeChange);

        // JavaScript
      },
      handleInnerTextChange() {
        this.components(this.get("innerText"));
      },
      handleFilterTypeChange() {
        if (
          [
            "singleSelectCheckbox",
            "dropdownField",
            "multiSelectCheckbox",
          ].includes(this.get("fitlerFieldType").id)
        ) {
          this.addTrait([
            {
              type: "select",
              label: "Filter Values",
              name: "optionsType",
              options: [
                {
                  id: "staticOption",
                  label: "Static Values",
                },
                {
                  id: "dataSourceOptions",
                  label: "Data Source",
                },
              ],
              changeProp: 1,
            },
          ]);
        } else {
          this.removeTrait(["optionsType","data_source", "labelKey", "valueKey"]);
        }
      },
      handleTraitsChange() {
        // Add new traits here
        if (this.get("optionsType").id == "dataSourceOptions") {
          this.addTrait([
            {
              type: "select",
              label: "Data Source",
              name: "data_source",
              options: [],
              changeProp: 1,
            },
            {
              type: "select",
              label: "Label Key",
              name: "labelKey",
              options: [],
              changeProp: 1,
            },
            {
              type: "select",
              label: "Value Key",
              name: "valueKey",
              options: [],
              changeProp: 1,
            },
          ]);
          this.removeTrait(["options"]);
        } else if (this.get("optionsType").id == "staticOption") {
          this.addTrait([
            {
              type: "text",
              label: "Options",
              name: "options",
              changeProp: 1,
            },
          ]);
          this.removeTrait(["data_source", "labelKey", "valueKey"]);
        } else {
          this.removeTrait([
            "data_source",
            "labelKey",
            "valueKey",
            "optionsType",
            "options",
          ]);
        }
      },
    },
  });
};
