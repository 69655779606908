
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import { Input, Checkbox, RadioButton } from "@progress/kendo-react-inputs";
import { getLanguageString } from "../../../../common/helper";

export const TextField = (props) => {
  const { attributes, onValueChange, defaultValue, language, className, readOnly, showPassword, nonEditable } = props;
  return (
    <Input
      type={showPassword?'text':attributes?.type?.id}
      disabled={attributes?.disabled || nonEditable}
      maxlength={attributes?.maxLength}
      minLength={attributes?.minLength}
      onChange={onValueChange}
      value={defaultValue || ""}
      placeholder={getLanguageString(language,attributes?.placeholder)}
      textBoxName={"text-box-"+attributes?.name}
      className={className || "" }
      readOnly={readOnly}
    />
  );
};

export const CheckboxField = (props) => {
  const { attributes, onValueChange, defaultValue, language, className, readOnly, atr } = props;
  const [checked, setChecked] = useState(attributes.checked);

  const handleChange = (event) => {
    setChecked(event.value);
    event.value ? onValueChange(attributes?.value) : onValueChange("");
  };

  return (
    <Checkbox
      checked={checked}
      onChange={handleChange}
      label={attributes?.label?getLanguageString(language,attributes?.label):""}
      className={className || "" }
      id={atr?.id}
      value={attributes?.value}
      readOnly={readOnly}
    />
  );
};

export const RadioButtonField = (props) => {
  const { attributes, onValueChange, defaultValue, language, readOnly } = props;
  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (event) => {
    setSelectedValue(event.value);
    event.value ? onValueChange(event?.value) : onValueChange("");
  };

  const radioButtons = attributes?.values?attributes?.values.split(","):"";
  const labels = attributes?.labels?attributes?.labels.split(","):"";

  const getRadioButtons = (labels) => {
    if (Array.isArray(labels) && labels.length > 0) {
      return labels.map((value, index) => {
        return (
          <span className="me-3">
            <RadioButton
              name={attributes?.name}
              value={radioButtons[index]}
              checked={selectedValue === radioButtons[index]}
              label={getLanguageString(language,value)}
              onChange={handleChange}
              readOnly={readOnly}
            />
          </span>
        );
      });
    }
  };

  return getRadioButtons(labels);
};
