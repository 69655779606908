/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

const IntegerFieldComponent = ({handleOnChange, value, minimumValue, maximumValue, fieldData}) => {
    const [integerValue, setIntegerValue] = useState(minimumValue);
    const [fieldName, setFieldName] = useState('');
    
    const handleValueChange = (value) => {
        let finalValue = 0;
        if(value > maximumValue){
            finalValue = maximumValue;
        }else if(value < minimumValue){
            finalValue = minimumValue;
        }else{
            finalValue = value;
        }
        setIntegerValue(finalValue);
        handleOnChange(finalValue);
    }

    useEffect(()=> {
        if(value != '' && value != null && value != undefined){
            handleValueChange(value);
        }
        setFieldName(fieldData?.name);
    },[]);

    useEffect(()=> {
        if(fieldName !== fieldData.name && (value === '' || value?.length === 0)){
            setFieldName(fieldData?.name);
            handleValueChange(minimumValue);
        }
    }, [fieldData]);

    const handleChange = (e) => {
        handleValueChange(e.target.value);
    };
    return <Form.Control type="number" name="sequence" id="sequence" min={minimumValue} max={maximumValue} onChange={handleChange} value={integerValue} />
}

export default IntegerFieldComponent;