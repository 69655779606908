
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const linkExtendTraits = (e) => {
    e.DomComponents.addType("link", {
      model: {
        defaults: {
          ...e.DomComponents.getType("link").model.prototype.defaults,
          traits: [
            // ...e.DomComponents.getType("link").model.prototype.defaults.traits,
            {
              type: "select",
              label: "Data Source",
              name: "data_source",
              changeProp: 1,
              options: [],
            },
            {
              type: "text",
              label: "Redirect Url",
              name: "redirectUrl",
              changeProp: 1,
            },
            {
              type: "text",
              label: "Icon Class",
              name: "iconClass",
              changeProp: 1,
            },
            {
              type: "select",
              label: "Button Type",
              name: "buttonType",
              options: [
                {
                  id: "link",
                  label: "Link",
                },
                {
                  id: "cancel",
                  label: "Cancel",
                },
                {
                  id: "save",
                  label: "Save",
                },
                {
                  id: "delete",
                  label: "Delete",
                },
                {
                  id: "refresh",
                  label: "Refresh",
                },
                {
                  id: "clearAllFilter",
                  label: "Clear All Filters",
                },
                {
                  id: "repeatativePlus",
                  label: "Repeatative Plus",
                },
                {
                  id: "repeatativeDelete",
                  label: "Repeatative Delete",
                },
                {
                  id: "newPageOpen",
                  label: "Open New Page",
                },
              ],
              changeProp: 1,
            },
            {
              type: "text",
              label: "Confirmation Message",
              name: "confirmationMessage",
              changeProp: 1,
            },
            {
              type: "text",
              label: "Success Message",
              name: "successMessage",
              changeProp: 1,
            },
            {
              type: "select",
              label: "Page (On Click)",
              name: "page",
              changeProp: 1,
              options: [],
            },
            {
              type: "select",
              label: "Open Page In",
              name: "openPageInPopup",
              options: [
                { id: "self", label: "Self" },
                { id: "bootstrapPopup", label: "Bootstrap Popup" },
                { id: "kendoPopup", label: "Kendo Popup" },
                { id: "container", label: "Container" },
              ],
              changeProp: 1,
            },
            {
              type: "select",
              label: "Select Container",
              name: "container_name",
              options: [],
              changeProp: 1,
            },
            {
              type: "text",
              label: "Popup Title",
              name: "popupTitle",
              changeProp: 1,
            },
            {
              type: "text",
              label: "Keys To Pass Value On Next",
              name: "passingKeys",
              changeProp: 1,
            },
            {
              type: "text",
              label: "Title",
              name: "title",
            },
            {
              type: "checkbox",
              label: "Refresh (On Success)",
              name: "refreshOnSuccess",
              changeProp: 1,
            },
            {
              type: "checkbox",
              label: "Skip Validation",
              name: "skipValidation",
              changeProp: 1,
            },
            {
              type: "checkbox",
              label: "Upload File",
              name: "uploadFile",
              changeProp: 1,
            },
          ],
        },
      },
    });
  };
  