
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../../initialState';

export const on_call_roster_reducer = (state = initialState, action) => {
	switch (action.type) {
		case 'LOAD_ALL_ROSTER_LIST_SUCCESS':
			return Object.assign({}, state, { allRosterList: action.payload });
		case 'LOAD_ALL_ROSTER_LIST_META':
			return Object.assign({}, state, { allRosterListMeta: action.payload });
		case 'LOAD_ROSTER_DETAILS_BY_ROSTER_ID_SUCCESS':
			return Object.assign({}, state, { rosterDetailsByRosterId: action.payload });
		case 'EMPTY_ROSTER_DETAILS_BY_ROSTER_ID_SUCCESS':
			return Object.assign({}, state, { rosterDetailsByRosterId: null });
		default:
			return state;
	}
}

export const on_call_roster_members_reducer = (state = initialState, action) => {
	switch (action.type) {
		case 'LOAD_ROSTER_MEMBERS_LIST_SUCCESS':
			return Object.assign({}, state, { rosterMembersList: action.payload });
		default:
			return state;
	}
}

export const on_call_roster_loader_reducer = (state = initialState, action) => {
	switch (action.type) {
		case 'LOAD_ROSTER_LIST_LOADER_IN_PROGRESS':
			return Object.assign({}, state, { showRosterListLoader: true });
		case 'LOAD_ROSTER_LIST_LOADER_FINISHED':
			return Object.assign({}, state, { showRosterListLoader: false });
		case 'LOAD_ROSTER_MEMBERS_LOADER_IN_PROGRESS':
			return Object.assign({}, state, { showRosterMembersLoader: true });
		case 'LOAD_ROSTER_MEMBERS_LOADER_FINISHED':
			return Object.assign({}, state, { showRosterMembersLoader: false });
		default:
			return state;
	}
}
