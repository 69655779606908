
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const taskInputOutputVariables = (state ={
    fetchingInputs:true,
    errorFetchingInputs:false,
    fetchingOutputs:true,
    errorFetchingOutputs:false,
    inputs:[],
    outputs:[],
    outputVariable:{}
},action) =>{
    switch(action.type){
        case 'FETCHING_TASK_INPUTS':
            return Object.assign({},state,{
                fetchingInputs:true,
                errorFetchingInputs:false,
                inputs:[]
            });
        case 'ERROR_LOADING_TASK_INPUTS':
            return Object.assign({},state,{
                fetchingInputs:false,
                errorFetchingInputs:true,
                inputs:[]
            });
        case 'TASK_INPUT_FETCHED':
            return Object.assign({},state,{
                fetchingInputs:false,
                errorFetchingInputs:false,
                inputs:action.payload
            });
        case 'FETCHING_TASK_OUTPUTS':
            return Object.assign({},state,{
                fetchingOutputs:true,
                errorFetchingOutputs:false,
                outputs:[]
            });
        case 'ERROR_LOADING_TASK_OUTPUTS':
            return Object.assign({},state,{
                fetchingOutputs:false,
                errorFetchingOutputs:true,
                outputs:[]
            });
        case 'TASK_OUTPUT_FETCHED':
            return Object.assign({},state,{
                fetchingOutputs:false,
                errorFetchingOutputs:false,
                outputs:action.data
            });
        case 'INITIALIZE_TASK_OUTPUT_FORM':
            return Object.assign({},state,{
                outputVariable:action.outputVariable
            });
        default:
            return state;
    }
};