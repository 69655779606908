
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import Swal from "sweetalert2";
import axios from "axios";
import Cookies from "universal-cookie";
import { Field, Formik, Form } from "formik";
import { useParams, useNavigate } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { GLOBAL } from "_Globals";
import {useUserInfo} from "../add-edit/formfield/hooks";
import Breadcrumbs from "../../common/header/breadcrumbs";
import CmdbProcessHierarchy from "../create/CmdbProcessHierarchy";
import { getTimelineData } from "../../../actions/cmdb/cmdbTimelineAction";
import { navigationHooks } from "../../../helpers/NavigationHook";
import {loadCIEditDetails,loadModelName} from "../../../actions/cmdb/lovAction";
import AddEditCmdb from "./addEditCmdb";

const cookies = new Cookies();

let cookiesData = cookies.get('gph');
if (cookiesData) cookiesData = cookiesData.replace('s:', '');
if (cookiesData) cookiesData = cookiesData.substring(0, cookiesData.lastIndexOf('.'));
cookiesData = cookiesData.split("~");
let dateformat = cookiesData[2];

const CmdbEditIndex = (props) => {
  const dispatch = useDispatch();
  // const formikRef = useRef(null);
  const params = useParams();
  const userInfo = useUserInfo();
  const translator = useSelector((state) => state.spcmReducer.tr);
  const cIEditDetails = useSelector((state) => state.CIEditDetails);

  const ciId = params?.ciId;
  const isAddMode = !ciId;

  const [isRightEditPanel, setrightEditPanel] = useState(true);
  const [initVals, setInitVals] = useState({
    ci_name: "",
    company_id: "",
    company_name: "",
    class_id: "",
    class_name: "",
    type: "",
    category_id: "",
    category_name: "",
    sub_category_id: 0,
    sub_category_name: "",
    ci_id: "",
    asset_tag: "",
    serial_number: "",
    status: "",
    status_name: "",
    sub_status: "",
    description: "",
    manufacturer: "",
    model_name: "",
    model_number: "",
    metallic: "",
    compliance: null,
    location_id: "0",
    location_name: null,
    environment: "",
    keyword: "",
    is_external: "False",
    dataset: "",
    business_owner_id: 0,
    business_owner_name: "",
    technical_owner_id: 0,
    technical_owner_name: "",
    owner_id: 0,
    owner_name: null,
    department_id: null,
    department_name: null,
    change_group_id: null,
    change_group_name: null,
    availaibility_percentage: "",
    support_company_id: "0",
    support_company_name: "",
    group_id: "0",
    group_name: "",
    owned_by_id: "0",
    owned_by: "",
    ownership_type_id: "0",
    order_date: undefined,
    acquisition_cost: null,
    cost_center_id: "0",
    cost_center: "",
    purchase_order_number: "",
    invoice_number: "",
    cost_currency_id: "",
    general_ledger_account: null,
    net_book_value: "",
    depreciation_charged_id: "",
    depreciation_charged_name:"",
    depreciation_schedule: "",
    vendor_id: "0",
    vendor: "",
    currency_id: "0",
    charge_category_id: "0",
    charge_type_id: "0",
    charge_frequency: "",
    charge_to_cc_id: null,
    charge_to_cc_name: null,
    charge_to_company_id: "0",
    charge_to_company_name: "",
    charge_to_project_id: "0",
    charge_to_project_name: null,
    vendor_seller_id: "0",
    vendor_seller: "",
    host_name: "",
    ip_address: "",
    mac_address: "",
    backup_retention: "",
    monitored: "",
    monitored_id: "0",
    domain_name: "",
    allocated_capacity: "",
    maximum_capacity: "",
    capacity_unit: "",
    capacity_utilization: "",
    capacity_thresholds: "",
    disaster_recovery_enabled: false,
    disaster_recovery_location_id: "0",
    disaster_recovery_location_name: "",
    disaster_tier_id: "0",
    recovery_point_objective: "",
    recovery_time_objective: "",
    discovery_source_name: "",
    patch_hold: "",
    patch_hold_reason: "",
    patch_schedule: "",
    patch_level: "",
    charge_category_name: null,
    charge_currency: "",
    charge_currency_id: "0",
    charge_type_name: "",
    cost_currency: null,
    created_by: "",

    dataset_name: "",
    depreciation_charged_name: "",
    disaster_tier_name: null,
    discovery_source_id: "0",
    domain_name: "",
    environment_name: "",

    manufacturer_id: "0",
    metallic_name: "",
    model_id: "0",
    owner_email: null,
    ownership_type: null,
    sub_status_name: "",
    technical_owner_email: "",
    type_name: "",
    business_owner_email: "",
    warranty_expiration_date: undefined,
    charge_start_date: undefined,
    scheduled_return_date: undefined,
    first_discovered_at: undefined,
    allocation_date: undefined,
    
  });

  let initialValues = {
    ...initVals,
  };

  const validationSchema = Yup.object().shape({
    ci_name: Yup.string().required("Required"),
    company_id: Yup.string().required("Required"),
    class_id: Yup.string().required("Required"),
    type: Yup.string().required("Required"),
    category_name: Yup.string().required("Required"),
    sub_category_name: Yup.string().required("Required"),
    status: Yup.string().required("Required"),
    status_name: Yup.string(),
    sub_status: Yup.string().required("Required"),
    // allocation_date: Yup.string().required("Required"),
    business_owner_name: Yup.string().required("Required"),
    // owner_name: Yup.string().required("Required"),
    technical_owner_name: Yup.string().required("Required"),
    //model_number: Yup.number().typeError("Please enter a valid number.").notRequired(), 
   // net_book_value: Yup.number().typeError("Please enter a valid number.").notRequired(), 
    // availaibility_percentage: Yup.number().typeError("Please enter a valid number.")
    // .notRequired()
    // .min(0, 'Percentage cannot be less than 0')
    // .max(100, 'Percentage cannot be more than 100'),
    purchase_order_number: Yup.number().typeError("Please enter a valid number.").notRequired(),
    //maximum_capacity: Yup.number().typeError("Please enter a valid number.").notRequired(),
   // Conditional validation based on status_name
   support_company_name: Yup.string().when('status_name', {
    is: (val) => val.includes("Deployed"), 
    then: () => Yup.string().required("Required"),
    otherwise: () => Yup.string(),
  }),
  group_name: Yup.string().when('status_name', {
    is: (val) => val.includes("Deployed"), 
    then: () => Yup.string().required("Required"),
    otherwise: () => Yup.string(),
  }),
  
  });

 

  const onSubmit = async (fields, { resetForm, setSubmitting, errors }) => {
		setSubmitting(true);

    const fieldsToCheck = ['ci_id', 'purchase_order_number', 'dataset_name','cost_currency_id','depreciation_charged_id','maximum_capacity'];
 
    const dateFields = ["allocation_date","warranty_expiration_date","deployement_date","decommission_date","support_end_date","first_deployed_date","installation_date","assigned_date","order_date","order_received_date","purchase_order_date","charge_start_date","charge_end_date","actual_retun_date","scheduled_return_date","backup_schedule","first_discovered_at"]; 


    const formattedFields = { ...fields };
    dateFields.forEach(field => {
        if (fields[field]) {
            formattedFields[field] = moment(fields[field] , dateformat.slice(0, 10)?.toUpperCase() +" HH:mm:ss").format('YYYY-MM-DD HH:mm:ss');
        }
    });
  
    const PostData = {
			...formattedFields,
      ...fieldsToCheck.reduce((acc, field) => {
        acc[field] = fields[field] === "" ? "0" : fields[field];
        return acc;
    }, {}),
      compliance: fields.compliance ? JSON.stringify(fields.compliance) :null,
			category_id: Number(fields.category_id || 0),
			category_companyId: undefined, 
		};
	
		try {
			const response = await axios.patch(`${GLOBAL.patchCiDataURL}/${ciId}`, PostData);
			const responseData = response.data[0];

			if (responseData?.upsert_status === false || responseData?.upsert_status === "false") {
				Swal.fire(responseData.message);
			} else {			
				dispatch(loadCIEditDetails(responseData.CI_ID));
				dispatch(loadModelName(responseData.CI_ID));
				dispatch(getTimelineData(responseData.CI_ID));				
				// ref.props.changeActionView("specifyAttributeValues");
			}
		} catch (error) {		
			console.error(error?.response?.data?.[0]?.message || error.message);
			// Optionally, handle specific error scenarios or set form errors
		} finally {			
			setSubmitting(false);
		}
	};

  const parseIfJson = input => {
    try {
        return typeof input === "string" ? JSON.parse(input) : input;
    } catch (e) {
        return input;
    }
};
  // useEffect(() => {
  //   if(!isAddMode && ciId) {
  //     dispatch(loadCIEditDetails(ciId));
  //   }
  // },[ciId, dispatch]);

  useEffect(() => {
    if (!isAddMode && ciId && cIEditDetails) {
 
      const updatedInitVals = {
        ci_name: cIEditDetails.CI_NAME,
        company_id: cIEditDetails.COMPANY_ID,
        company_name: cIEditDetails.COMPANY_NAME,
        class_id: cIEditDetails.CLASS_ID,
        class_name: cIEditDetails.CLASS_NAME,
        type: cIEditDetails.TYPE,
        category_id: cIEditDetails?.CATEGORY_ID || "0",
        category_name: cIEditDetails.CATEGORY_NAME,
        sub_category_id: cIEditDetails.SUB_CATEGORY_ID || 0,
        sub_category_name: cIEditDetails.SUB_CATEGORY_NAME,
        ci_id: cIEditDetails.CI_ID,
        asset_tag: cIEditDetails.ASSET_TAG,
        serial_number: cIEditDetails.SERIAL_NUMBER,
        status: cIEditDetails.STATUS,
        status_name: cIEditDetails.STATUS_NAME,
        sub_status: cIEditDetails.SUB_STATUS,
        sub_status_name: cIEditDetails.SUB_STATUS_NAME,
        description: cIEditDetails.DESCRIPTION,
        manufacturer: cIEditDetails.MANUFACTURER,
        model_name: cIEditDetails.MODEL_NAME,
        model_number: cIEditDetails?.MODEL_NUMBER ||  "",
        metallic: cIEditDetails.METALLIC,
        compliance: parseIfJson(cIEditDetails?.COMPLIANCE) || null,
        location_id: cIEditDetails.LOCATION_ID || "0",
        location_name: cIEditDetails.LOCATION_NAME,
        environment: cIEditDetails.ENVIRONMENT,
        keyword: cIEditDetails.KEYWORD,
        is_external: String(cIEditDetails.IS_EXTERNAL),
        dataset: cIEditDetails.DATASET,
        business_owner_id: cIEditDetails.BUSINESS_OWNER_ID,
        business_owner_name: cIEditDetails.BUSINESS_OWNER_NAME,
        technical_owner_id: cIEditDetails.TECHNICAL_OWNER_ID,
        technical_owner_name: cIEditDetails.TECHNICAL_OWNER_NAME,
        owner_id: cIEditDetails.OWNER_ID,
        owner_name: cIEditDetails.OWNER_NAME,
        department_id: cIEditDetails.DEPARTMENT_ID,
        department_name: cIEditDetails.DEPARTMENT_NAME,
        change_group_id: cIEditDetails.CHANGE_GROUP_ID,
        change_group_name: cIEditDetails.CHANGE_GROUP_NAME,
        availaibility_percentage: cIEditDetails.AVAILAIBILITY_PERCENTAGE,
        support_company_id: cIEditDetails.SUPPORT_COMPANY_ID,
        support_company_name: cIEditDetails.SUPPORT_COMPANY_NAME,
        group_id: cIEditDetails.GROUP_ID === 0 ? "0" : cIEditDetails.GROUP_ID,
        group_name: cIEditDetails.GROUP_NAME,
        owned_by_id: cIEditDetails.OWNED_BY_ID,
        owned_by: cIEditDetails.OWNED_BY,
        ownership_type_id: cIEditDetails.OWNERSHIP_TYPE_ID,
        ownership_type: cIEditDetails.OWNERSHIP_TYPE,
        // order_date: cIEditDetails.ORDER_DATE,
        acquisition_cost: cIEditDetails.ACQUISITION_COST,
        cost_center_id: cIEditDetails.COST_CENTER_ID,
        cost_center: cIEditDetails.COST_CENTER,
        purchase_order_number: cIEditDetails.PURCHASE_ORDER_NUMBER === "0" ? "" : cIEditDetails.PURCHASE_ORDER_NUMBER,
        invoice_number: cIEditDetails.INVOICE_NUMBER,
        cost_currency_id: cIEditDetails?.COST_CURRENCY_ID == "0" ? "" : cIEditDetails?.COST_CURRENCY_ID,
        general_ledger_account: cIEditDetails.GENERAL_LEDGER_ACCOUNT,
        net_book_value: cIEditDetails.NET_BOOK_VALUE,
        depreciation_charged_id: cIEditDetails.DEPRECIATION_CHARGED_ID  == "0" ? "" : cIEditDetails.DEPRECIATION_CHARGED_ID,
        depreciation_charged_name: cIEditDetails.DEPRECIATION_CHARGED_NAME,
        depreciation_schedule: cIEditDetails.DEPRECIATION_SCHEDULE,
        vendor_id: cIEditDetails.VENDOR_ID,
        vendor: cIEditDetails.VENDOR,
        currency_id: cIEditDetails?.CURRENCY_ID || "0",
        charge_category_id: cIEditDetails.CHARGE_CATEGORY_ID,
        charge_type_id: cIEditDetails.CHARGE_TYPE_ID,
        charge_frequency: cIEditDetails?.CHARGE_FREQUENCY || "",
        charge_to_cc_id: cIEditDetails.CHARGE_TO_CC_ID,
        charge_to_cc_name: cIEditDetails.CHARGE_TO_CC_NAME,
        charge_to_company_id: cIEditDetails.CHARGE_TO_COMPANY_ID || "0",
        charge_to_company_name: cIEditDetails.CHARGE_TO_COMPANY_NAME,
        charge_to_project_id: cIEditDetails.CHARGE_TO_PROJECT_ID || "0",
        charge_to_project_name: cIEditDetails.CHARGE_TO_PROJECT_NAME,
        vendor_seller_id: cIEditDetails.VENDOR_SELLER_ID || "0",
        vendor_seller: cIEditDetails.VENDOR_SELLER,
        host_name: cIEditDetails.HOST_NAME,
        ip_address: cIEditDetails.IP_ADDRESS,
        mac_address: cIEditDetails.MAC_ADDRESS,
        backup_retention: cIEditDetails.BACKUP_RETENTION,
        monitored: cIEditDetails.MONITORED,
        monitored_id: cIEditDetails?.MONITORED_ID || "0",
        domain_name: cIEditDetails.DOMAIN_NAME,
        allocated_capacity: cIEditDetails.ALLOCATED_CAPACITY,
        maximum_capacity: cIEditDetails.MAXIMUM_CAPACITY,
        capacity_unit: cIEditDetails.CAPACITY_UNIT,
        capacity_utilization: cIEditDetails.CAPACITY_UTILIZATION,
        capacity_thresholds: cIEditDetails.CAPACITY_THRESHOLDS,
        disaster_recovery_enabled: cIEditDetails.DISASTER_RECOVERY_ENABLED,
        disaster_recovery_location_id: cIEditDetails.DISASTER_RECOVERY_LOCATION_ID,
        disaster_recovery_location_name: cIEditDetails.DISASTER_RECOVERY_LOCATION_NAME,
        disaster_tier_id: cIEditDetails.DISASTER_TIER_ID || "0",
        recovery_point_objective: cIEditDetails.RECOVERY_POINT_OBJECTIVE,
        recovery_time_objective: cIEditDetails.RECOVERY_TIME_OBJECTIVE,
        discovery_source_name: cIEditDetails.DISCOVERY_SOURCE_NAME,
        patch_hold: cIEditDetails.PATCH_HOLD,
        patch_hold_reason: cIEditDetails.PATCH_HOLD_REASON,
        patch_schedule: cIEditDetails.PATCH_SCHEDULE,
        patch_level: cIEditDetails.PATCH_LEVEL,
        charge_category_name: cIEditDetails.CHARGE_CATEGORY_NAME,
        charge_currency: cIEditDetails.CHARGE_CURRENCY,
        charge_currency_id: cIEditDetails.CHARGE_CURRENCY_ID || "0",
        charge_type_name: cIEditDetails.CHARGE_TYPE_NAME,
        cost_currency: cIEditDetails.COST_CURRENCY,
        created_by: cIEditDetails.CREATED_BY,
        dataset_name: cIEditDetails?.DATASET_NAME,
        disaster_tier_name: cIEditDetails.DISASTER_TIER_NAME,
        discovery_source_id: cIEditDetails.DISCOVERY_SOURCE_ID || "0",
        domain_name: cIEditDetails.DOMAIN_NAME,
        environment_name: cIEditDetails?.ENVIRONMENT_NAME || "",
        manufacturer_id: cIEditDetails.MANUFACTURER_ID || "0",
        metallic_name: cIEditDetails?.METALLIC_NAME || "",
        model_id: cIEditDetails.MODEL_ID || "0",
        owner_email: cIEditDetails.OWNER_EMAIL,
        // sub_status_name: cIEditDetails.SUB_STATUS_NAME,
        technical_owner_email: cIEditDetails.TECHNICAL_OWNER_EMAIL,
        type_name: cIEditDetails.TYPE_NAME,
        business_owner_email: cIEditDetails.BUSINESS_OWNER_EMAIL,
        // warranty_expiration_date: cIEditDetails.WARRANTY_EXPIRATION_DATE,
        // charge_start_date: cIEditDetails.CHARGE_START_DATE,
        // scheduled_return_date: cIEditDetails.SCHEDULED_RETURN_DATE,
        first_discovered_at: cIEditDetails.FIRST_DISCOVERED_AT,
        allocation_date: cIEditDetails.ALLOCATION_DATE,
        updated_by: cIEditDetails.UPDATED_BY || "",
        updated_at: cIEditDetails.UPDATED_AT,
        upsert_userid: cIEditDetails.UPSERT_USERID,
        decommission_date: cIEditDetails?.DECOMMISSION_DATE,
        deployement_date:cIEditDetails?.DEPLOYEMENT_DATE,
				// support_end_date:cIEditDetails?.SUPPORT_END_DATE,
				first_deployed_date:cIEditDetails?.FIRST_DEPLOYED_DATE,
				installation_date:cIEditDetails?.INSTALLATION_DATE,
				order_date:cIEditDetails?.ORDER_DATE,
				order_received_date:cIEditDetails?.ORDER_RECEIVED_DATE,
				purchase_order_date:cIEditDetails?.PURCHASE_ORDER_DATE,
				scheduled_return_date:cIEditDetails?.SCHEDULED_RETURN_DATE,
				support_end_date:cIEditDetails?.SUPPORT_END_DATE,
				warranty_expiration_date:cIEditDetails?.WARRANTY_EXPIRATION_DATE,
				charge_start_date:cIEditDetails?.CHARGE_START_DATE,
				charge_end_date:cIEditDetails?.CHARGE_END_DATE,
				backup_schedule:cIEditDetails?.BACKUP_SCHEDULE,
				actual_retun_date:cIEditDetails?.ACTUAL_RETUN_DATE,
				most_recent_discovery_at:cIEditDetails?.MOST_RECENT_DISCOVERY_AT,
				assigned_date:cIEditDetails?.ASSIGNED_DATE
      };
  
      setInitVals((prev) => {
        return {
          ...prev,
          ...updatedInitVals
        };
      });
    }
  }, [ciId, cIEditDetails]);
  
  return (
        <Container fluid>
        <Row className="margin-b-15">       
          <Col xs={12} md={12}>
            <Formik
              innerRef={props?.formikRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={onSubmit}
            >
              {({
                errors,
                touched,
                isSubmitting,
                setFieldValue,
                handleBlur,
                values,
                dirty,
                resetForm,
                handleChange,
                isValid,
                submitCount,
              }) => {
                return (
                  <Form noValidate className="row-eq-height">
										<AddEditCmdb
											setFieldValue={setFieldValue}
											values={values}
											isValid={isValid}
											submitCount={submitCount}
											errors={errors}
											touched={touched}
											isSubmitting={isSubmitting}
                      cIEditDetails={cIEditDetails}
										/>
                  </Form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </Container>
  );
};

export default CmdbEditIndex;
