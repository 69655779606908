
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef } from 'react'
import { Row, Col, Accordion, Tab, Tabs, ListGroup, Button } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {PiPencilSimpleBold} from "react-icons/pi";
import {IoClose} from "react-icons/io5";
import { Link } from 'react-router-dom';
import { MyContext } from '_MyContext';

const HamRightPart = (props) => {

  const navigate = useNavigate();
  const tr = useSelector((state) => state.spcmReducer.tr);
  console.log("classDefinition", props.classDefinition);
  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const editButton = useRef(null);

  useEffect(() => {
    context.keyDownHandler(closeButton.current,"","","");
    if (props.classDefinition.COMPANY_ID != 0) {
        context.keyDownHandler("",editButton.current,"","");
    }
    context.newTabPrevent();
  }, [props.classDefinition]);


  return (
    <div>

<Row>
                <Col xs={12}>
                    <div className="rPageHeadActBtn" style={{ "marginTop": "-3px" }}>
                        <ListGroup bsPrefix=" " as="ul">
                            { props.classDefinition.COMPANY_ID != 0 ? 
                            <ListGroup.Item as="li" bsPrefix=" "><Link bsPrefix=" " to={`/editClassDefinition/${props.classDefinition.CLASS_ID}`} title={tr["Edit"]} ref={editButton} className="editIcn ctrlKeyPrevent"><PiPencilSimpleBold/></Link></ListGroup.Item>
                            : ''
                            }
                            <ListGroup.Item as="li" bsPrefix=" "><Link to="javascript:void(0)" bsPrefix=" " title={tr["Minimize the details panel"]} className="ctrlKeyPrevent" onClick={()=>{props.showRightSide(false)}} ref={closeButton} ><IoClose/></Link></ListGroup.Item>
                        </ListGroup>
                    </div>
                    <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{tr["Class Definition"]}</div></div>
                </Col>
            </Row>

            <div className="rBoxGap">
                <div className="rBoxTabs">
                    <ListGroup as="ul" bsPrefix=" ">

                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Company Name"]}</span><span className="rwVal">{props.classDefinition.COMPANY_NAME}</span></ListGroup.Item>

                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Class Name"]}</span><span className="rwVal">{props.classDefinition.CLASS_NAME}</span></ListGroup.Item>
                       
                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Class ID"]}</span><span className="rwVal">{props.classDefinition.CLASS_ID}</span></ListGroup.Item>

                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Class Type"]}</span><span className="rwVal">{props.classDefinition.CLASS_TYPE}</span></ListGroup.Item>

                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Status"]}</span><span className="rwVal">{props.classDefinition.STATUS}</span></ListGroup.Item>

                        {(props.classDefinition.PARENT_CLASS_ID != null  &&  props.classDefinition.PARENT_CLASS_ID != "") ? <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Parent Class ID"]}</span><span className="rwVal">{props.classDefinition.PARENT_CLASS_ID}</span></ListGroup.Item> : ''}
                        
                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Created At"]}</span><span className="rwVal">{props.classDefinition.CREATED_AT}</span></ListGroup.Item>

                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Created By"]}</span><span className="rwVal">{props.classDefinition.CREATED_BY}</span></ListGroup.Item>

                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Updated At"]}</span><span className="rwVal">{props.classDefinition.UPDATED_AT}</span></ListGroup.Item>

                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Updated By"]}</span><span className="rwVal">{props.classDefinition.UPDATED_BY}</span></ListGroup.Item>

                    </ListGroup>
                </div>
            </div>
      
    </div>
  )
}

export default HamRightPart
