
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Row } from "react-bootstrap";

class ProcessHierarchy extends React.Component {
  render() {
    return (
      <div>
        <div className="rPageHeading">
          {this.props.translator["Process Workflow"]}
        </div>

        <div className='text-c'>
          <img
            className="img-fluid marAuto"
            src={require("../../../../images/sla-diagram.png")}
            title={this.props.translator["Process Hierarchy"]}
          />
        </div>
      </div>
    );
  }
}

export default ProcessHierarchy;
