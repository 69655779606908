
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Form, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { loadAllScheduleList } from '_Actions/onCallSchedule/schedule/onCallScheduleAction';
import ListLoader from "../../../common/loaders/ListLoader.js"
import Pagination from "react-js-pagination";
import { loadOnCallSXMenulistData } from "../../../../actions/onCallSchedule/common/onCallCommonAction.js";
import { IoSearch } from "react-icons/io5";

const ListView = (props) => {
  const dispatch = useDispatch();
  const translator = useSelector((state) => state.spcmReducer.tr);
  const allScheduleList = useSelector((state) => state.on_call_schedule_reducer.allScheduleList);
  const allScheduleListMeta = useSelector((state) => state.on_call_schedule_reducer.allScheduleListMeta);
  const showScheduleListLoader = useSelector((state) => state.on_call_schedule_loader_reducer.showScheduleListLoader);
  const scheduleType = useSelector(
    (state) => state.on_call_common_reducer.scheduleType
  );
  const statusInput = useSelector((state) => state.on_call_common_reducer.onCallStatus);

  useEffect(() => {
    let filter = { page: 0, size: 10 };
    dispatch(loadAllScheduleList(filter));
    dispatch(loadOnCallSXMenulistData("AIOSEM", "ScheduleType"));
    dispatch(loadOnCallSXMenulistData("AIOSEM", "Status"));
  }, []);

  function onRowSelect(e, row, index) {
    props.setSelectedItemFun({
      scheduleId: row.scheduleId,
      scheduleName: row.scheduleName,
      scheduleType: row.scheduleType,
      timeZone: row.timeZone,
      status: row.status
    });
    props.showRightSideFun(true);
    props.showActiveRow(index);
  }

  function renderScheduleList(allScheduleList) {
    return allScheduleList.map((data, index) => {
      return (
        <tr key={index} className={props.isRightSideVisible && index == props.rowIndex ? "myActive" : ""} onClick={(e) => onRowSelect(e, data, index)}>
          <td>{data.scheduleName}</td>
          <td>{data.scheduleType}</td>
          <td>{data.timeZone}</td>
          <td>{data.status}</td>
        </tr>
      );
    });
  }


  const handleChange = (event, name) => {
    switch (name) {
      case "scheduleName":
        props.setNameSearchInput(event.target.value);
        break;
      case "timezone":
        props.setTimezoneSearchInput(event.target.value);
        break;
      default:
        break;
    }
  };

  const prepareFilters = () => {
    let filter = {};
    if (props.nameSearchInput) {
      filter["scheduleName"] = props.nameSearchInput;
    }
    if (props.timezoneSearchInput) {
      filter["timeZone"] = props.timezoneSearchInput;
    }
    if (props.statusSearchInput) {
      filter["status"] = props.statusSearchInput;
    }
    if (props.typeSearchInput) {
      filter["scheduleType"] = props.typeSearchInput;
    }
    return filter;
  };


  const onDropDownChange = (e) => {
    let filter = prepareFilters();
    if (e.target.name === "scheduleType") {
      props.setTypeSearchInput(e.target.value);
      if (e.target.value) {
        filter["scheduleType"] = e.target.value;
      } else {
        delete filter["scheduleType"];
      }
    }
    if (e.target.name === "status") {
      props.setStatusSearchInput(e.target.value);
      if (e.target.value) {
        filter["status"] = e.target.value;
      } else {
        delete filter["status"];
      }
    }
    if (Object.keys(filter).length > 0) {
      props.setIsFilterApplied(true);
    } else {
      props.setIsFilterApplied(false);
    }
    filter["page"] = 0;
    filter["size"] = 10;
    props.setActivePage(0);
    props.setItemsPerPage(10);
    dispatch(loadAllScheduleList(filter));
  };


  const handleSearch = () => {
    let filter = prepareFilters();
    if (Object.keys(filter).length > 0) {
      props.setIsFilterApplied(true);
    } else {
      props.setIsFilterApplied(false);
    }
    filter['page'] = 0;
    filter['size'] = 10;
    props.setActivePage(0);
    props.setItemsPerPage(10);
    dispatch(loadAllScheduleList(filter));
  }

  const onPageSizeChange = (e) => {
    let filter = prepareFilters();
    filter["page"] = 0;
    filter["size"] = e.target.value;
    dispatch(loadAllScheduleList(filter));
    props.setActivePage(0);
    props.setItemsPerPage(e.target.value);
  }

  const onPageChange = (e) => {
    let filter = prepareFilters();
    filter["page"] = e - 1;
    filter["size"] = props.itemsPerPage;
    dispatch(loadAllScheduleList(filter));
    props.setActivePage(parseInt(filter["page"], 10));
  };

  return (
    <div>
      <div className="respondv">
        <div className={"tableRgtBor "}>
          <Table
            striped
            bordered
            condensed
            hover
            className="tableView nowrapWhright"
            style={{ marginTop: "0px" }}
          >
            <thead>
              <tr>
                <th>
                  <div className="sortParArr">{translator["Name"]}</div>
                  <div className="colSearDv">
                    <Form.Control
                      className="colSearInp"
                      type="text"
                      value={
                        props.nameSearchInput != ""
                          ? props.nameSearchInput
                          : ""
                      }
                      placeholder={translator["Search here"]}
                      onKeyPress={(e) => {
                        if (e.charCode == "13") {
                          if (
                            props.nameSearchInput != "" &&
                            props.nameSearchInput?.trim().length > 3
                          )
                            handleSearch();
                        }
                      }}
                      onChange={(event) => {
                        handleChange(event, "scheduleName");
                      }}
                    />
                    {props.nameSearchInput?.trim().length > 3 ? (
                      <a
                        title="search"
                        className="faicn"
                        href={void 0}
                        onClick={(event) => handleSearch()}
                      >
                        <IoSearch />
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </th>
                <th>
                  <div className="sortParArr">{translator["Type"]}</div>
                  <div className="colSearDv">
                    <Form.Select
                      className="colSearInp"
                      type="text"
                      name="scheduleType"
                      onChange={(event) => {
                        onDropDownChange(event);
                      }}
                    >
                      <option value="">Select</option>
                      {scheduleType &&
                        scheduleType.length > 0 &&
                        scheduleType.map((type) => {
                          return (
                            <option
                              value={type.field1Key}
                              selected={
                                props.typeSearchInput === type.field1Key
                              }
                            >
                              {type.field1Key}
                            </option>
                          );
                        })}
                    </Form.Select>
                  </div>
                </th>
                <th>
                  <div className="sortParArr">{translator['Timezone']}</div>
                  <div className="colSearDv">
                    <Form.Control
                      className="colSearInp"
                      type="text"
                      value={
                        props.timezoneSearchInput != ""
                          ? props.timezoneSearchInput
                          : ""
                      }
                      placeholder={translator["Search here"]}
                      onKeyPress={(e) => {
                        if (e.charCode == "13") {
                          if (
                            props.timezoneSearchInput != "" &&
                            props.timezoneSearchInput?.trim().length >= 3
                          )
                            handleSearch();
                        }
                      }}
                      onChange={(event) => {
                        handleChange(event, "timezone");
                      }}
                    />

                    {props.timezoneSearchInput?.trim().length >= 3 ? (
                      <a
                        title="search"
                        className="faicn"
                        href={void 0}
                        onClick={(event) => handleSearch()}
                      >
                        <IoSearch />
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </th>
                <th>
                  <div className="sortParArr">{translator["Status"]}</div>
                  <div className="colSearDv">
                    <Form.Select
                      className="colSearInp"
                      type="text"
                      name="status"
                      onChange={(event) => {
                        onDropDownChange(event);
                      }}
                    >
                      <option value="">Select</option>
                      {statusInput &&
                        statusInput.length > 0 &&
                        statusInput.map((type) => {
                          return (
                            <option
                              value={type.field1Key}
                              selected={
                                props.statusSearchInput === type.field1Key
                              }
                            >
                              {type.field1Key}
                            </option>
                          );
                        })}
                    </Form.Select>
                  </div>

                </th>
              </tr>
            </thead>
            <tbody>
              {showScheduleListLoader ? (
                <tr>
                  <td colSpan="12" style={{ textAlign: "center" }}>
                    <ListLoader />
                  </td>
                </tr>
              ) : allScheduleList.length == 0 ? (
                <tr>
                  <td colSpan="12" style={{ textAlign: "center" }}>
                    {translator["There is no matching data available"]}
                  </td>
                </tr>
              ) : (
                renderScheduleList(allScheduleList)
              )}
            </tbody>
          </Table>
        </div>
      </div>
      {allScheduleList.length > 0 ? 
        <div className="nBotPagina">
          <div className="nShow">
            <div className="margin-r-10" componentClass={Form.Label}>
              {translator["Show"]}:
            </div>
            <Form.Select
              value={props.itemsPerPage}
              onChange={(e) => {
                onPageSizeChange(e);
              }}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              {/* <option value="100">100</option> */}
            </Form.Select>
          </div>
          <nav aria-label="Pagination" className="display-inline-block">
            <Pagination
              prevPageText="Prev"
              nextPageText="Next"
              firstPageText={<i className="glyphicon glyphicon-menu-left" />}
              lastPageText={<i className="glyphicon glyphicon-menu-right" />}
              activePage={
                allScheduleListMeta ? allScheduleListMeta.currentPage + 1 : 1
              }
              itemsCountPerPage={props.itemsPerPage}
              totalItemsCount={
                allScheduleListMeta ? allScheduleListMeta.numberOfItems : 1
              }
              pageRangeDisplayed={4}
              onChange={(e) => {
                onPageChange(e);
              }}
            />
          </nav>
        </div>
      :
        ''}
    </div>
  );
}

export default ListView;