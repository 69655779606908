
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import { connect } from "react-redux";
import DelegateHeader from "./DelegateHeader";
import MyDelegationList from "./MyDelegationList";
import DelegateDescription from "./DelegateDescription";
import EditDelegate from "./EditDelegate";
import axios from "axios";
import { GLOBAL } from '_Globals';
import PindCategories from '_Commons/header/pind-categories';
import Cookies from "universal-cookie";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import "_Css/common/_table.scss";

const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home") homepagelocation = "/" + homepagelocation;

const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

class MyDelegationIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenu: "7",
      showHideAlfy: false,
      showHideRightNav: false,
      delegate: "",
      user_id: "",
      role_id: "",
      date_format: "",
      rowIndex: 0,
      isListPart: false,
      isRightPanel: false,
      searchKey: [],
      searchValue: [],
      sortOrder: "desc",
      sortOnColumn: "delegateDateforFrom",
      status: "Active",
      modules: "",
      isClearAllFilter: false,
      delegatorSearchInput: "",
      delegateeSearchInput: "",
      delegatedFromStartDate: {},
      delegatedFromEndDate: {},
      delegatedToStartDate: {},
      delegatedToEndDate: {},
      selectedValueStatus: [],
      selectedValueModule: [],
      delegatedFromRef: {},
      delegatedToRef: {},
      panelResize: "",
      isStatusOpen: false,
      isModuleOpen: false,
    };
    GLOBAL.searchbarcategory = "All";
    this.showHideAlfy = this.showHideAlfy.bind(this);
    this.showHideRightNav = this.showHideRightNav.bind(this);
    this.setDelegateForEdit = this.setDelegateForEdit.bind(this);
    this.loadHome = this.loadHome.bind(this);
    this.showActiveRow = this.showActiveRow.bind(this);
    this.showListPart = this.showListPart.bind(this);
    this.showRightPanelFun = this.showRightPanelFun.bind(this);
    this.setSearchKeyValue = this.setSearchKeyValue.bind(this);
    this.setSortOrder = this.setSortOrder.bind(this);
    this.setStatusParameter = this.setStatusParameter.bind(this);
    this.setModulesParameter = this.setModulesParameter.bind(this);
    this.isClearAllFilterFunc = this.isClearAllFilterFunc.bind(this);
    this.setDelegatorSearchInput = this.setDelegatorSearchInput.bind(this);
    this.setDelegateeSearchInput = this.setDelegateeSearchInput.bind(this);
    this.setDelegatedFromStartAndDate =
      this.setDelegatedFromStartAndDate.bind(this);
    this.setDelegatedToStartAndDate =
      this.setDelegatedToStartAndDate.bind(this);
    this.setSelectedValueStatus = this.setSelectedValueStatus.bind(this);
    this.setSelectedValueModule = this.setSelectedValueModule.bind(this);
    this.setDelegatedFromRef = this.setDelegatedFromRef.bind(this);
    this.setDelegatedToRef = this.setDelegatedToRef.bind(this);
    this.multiSelectOpenFun = this.multiSelectOpenFun.bind(this);
  }
  componentWillMount() {
    let str = {};
    str.user_id = "";
    str = JSON.stringify(str);
    api.get("/api/userInfo", { headers: { query: str } }).then((response) => {
      this.setState({
        user_id: response.data.user_id,
        role_id: response.data.role_id,
        date_format: response.data.dateformat,
      });
    });
  }
  multiSelectOpenFun(keyName, val) {
		switch (keyName) {
		  case "myDeleStatus":
			this.setState({
			  isStatusOpen: val,
			  isModuleOpen: false,
			});
			break;
		  case "myDeleModule":
			this.setState({
			  isStatusOpen: false,
			  isModuleOpen: val,
			});
			break;
		  case "allFalse":
			this.setState({
			  isStatusOpen: false,
			  isModuleOpen: false,
			});
			break;
		  }
	  }
  setDelegatedFromRef(ref) {
    this.inputRef = ref;
    this.setState({ delegatedFromRef: this.inputRef });
  }
  setDelegatedToRef(ref) {
    this.inputRef = ref;
    this.setState({ delegatedToRef: this.inputRef });
  }

  setSelectedValueStatus(value) {
    this.setState({ selectedValueStatus: value });
  }

  setSelectedValueModule(value) {
    this.setState({ selectedValueModule: value });
  }

  isClearAllFilterFunc(value) {
    this.setState({ isClearAllFilter: value });
  }

  setDelegatorSearchInput(displayNumber) {
    this.setState({
      delegatorSearchInput: displayNumber,
    });
  }

  setDelegateeSearchInput(ciServiceName) {
    this.setState({
      delegateeSearchInput: ciServiceName,
    });
  }

  setDelegatedFromStartAndDate(startDate, endDate) {
    this.setState({
      delegatedFromStartDate: startDate,
      delegatedFromEndDate: endDate,
    });
  }
  setDelegatedToStartAndDate(startDate, endDate) {
    this.setState({
      delegatedToStartDate: startDate,
      delegatedToEndDate: endDate,
    });
  }

  setSearchKeyValue(key, value) {
    this.setState({ searchKey: key });
    this.setState({ searchValue: value });
  }
  setSortOrder(sortBy, sortOn) {
    this.setState({ sortOrder: sortBy });
    this.setState({ sortOnColumn: sortOn });
  }
  setStatusParameter(statusValues) {
    this.setState({ status: statusValues });
  }
  setModulesParameter(statusValues) {
    this.setState({ modules: statusValues });
  }
  showRightPanelFun(value) {
    this.setState({ isRightPanel: value });
  }
  showListPart(isListPart) {
    this.setState({ isListPart });
  }
  showHideAlfy(value) {
    this.setState({ showHideAlfy: value });
  }
  showHideRightNav(value) {
    this.setState({ showHideRightNav: !this.state.showHideRightNav });
  }
  setDelegateForEdit(delegate) {
    this.setState({ delegate: delegate });
  }
  showActiveRow(i) {
    if ((" i ", i));
    this.setState({ rowIndex: i });
  }
  loadHome() {
    //browserHistory.push(homepagelocation);
    this.props.navigate(homepagelocation);
  }
  render() {
    let metaData =
      this.props.delegationList.meta != undefined
        ? this.props.delegationList.meta
        : "";
    let size = metaData && metaData.limit ? metaData.limit : 0;
    let currentPage =
      metaData && metaData.currentPage ? metaData.currentPage : 0;
    let startindex = size * (currentPage - 1) + 1;
    let endindex = size * currentPage;
    let recordCount = metaData && metaData.rowCount ? metaData.rowCount : 0;
    return (
      <main>
        {/* <div className="gloSearchDiv"><SxSearch tr={this.props.translator} /></div> */}
        <div >
          <PindCategories />

          {/* <Header rightNavValue={this.showHideRightNav} /> */}
          {/* <RightSidebarMenulist showhideClass={this.state.showHideRightNav} subMenuNumber={this.state.subMenu} /> */}

          <div className="container-fluid margin-b-15">
            <Row>
              <Col md={8} xs={8}>          
                <nav aria-label="Breadcrumb">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a
                        href={void(0)}
                        role="button"
                        onClick={this.loadHome}
                      >
                        {this.props.translator["Home"]}
                      </a>
                    </li>
                    <li className="breadcrumb-item active">
                      {this.props.translator["My Delegations"]}
                    </li>
                  </ul>
                </nav>
              </Col>
              <Col md={4} xs={4}>
                {recordCount != 0 ? (
                  <div className="tabDatacount">
                    {startindex}-
                    {endindex > recordCount ? (endindex = recordCount) : endindex}{" "}
                    of {recordCount}
                  </div>
                ) : null}
              </Col>
            </Row>
          </div>

          {/* <DelegateLeftSideMenu translator={this.props.translator} /> */}

          <div className="container-fluid" bsClass="">
            <DelegateHeader
              navigate={(location)=>{ this.props.navigate(location);}}
              showRightPanelFun={this.showRightPanelFun}
              user_id={this.state.user_id}
              translator={this.props.translator}
              searchKey={this.state.searchKey}
              searchValue={this.state.searchValue}
              sortOrder={this.state.sortOrder}
              sortOnColumn={this.state.sortOnColumn}
              status={this.state.status}
              modules={this.state.modules}
              isClearAllFilterFunc={this.isClearAllFilterFunc}
              setSearchKeyValue={this.setSearchKeyValue}
              isClearAllFilter={this.state.isClearAllFilter}
              delegatorSearchInput={this.state.delegatorSearchInput}
              delegateeSearchInput={this.state.delegateeSearchInput}
              delegatedFromStartDate={this.state.delegatedFromStartDate}
              delegatedFromEndDate={this.state.delegatedFromEndDate}
              delegatedToStartDate={this.state.delegatedToStartDate}
              delegatedToEndDate={this.state.delegatedToEndDate}
              setStatusParameter={this.setStatusParameter}
              setModulesParameter={this.setModulesParameter}
              setDelegatedFromStartAndDate={this.setDelegatedFromStartAndDate}
              setDelegatedToStartAndDate={this.setDelegatedToStartAndDate}
              setDelegatorSearchInput={this.setDelegatorSearchInput}
              setDelegateeSearchInput={this.setDelegateeSearchInput}
              setSelectedValueStatus={this.setSelectedValueStatus}
              setSelectedValueModule={this.setSelectedValueModule}
              selectedValueStatus={this.state.selectedValueStatus}
              selectedValueModule={this.state.selectedValueModule}
              setDelegatedFromRef={this.setDelegatedFromRef}
              setDelegatedToRef={this.setDelegatedToRef}
              dateRangePickerRefDelegatedFrom={this.state.delegatedFromRef}
              dateRangePickerRefDelegatedTo={this.state.delegatedToRef}
            />
            <PanelGroup direction="horizontal" className={(this.state.isStatusOpen || this.state.isModuleOpen) && (this.props.delegationList.delegationList == undefined ||this.props.delegateShowLoader || this.props.delegationList.delegationList?.length <= 6) ? "overflow-clip" : "" }>
								<Panel id="sidebar" minSize={33} order={1} defaultSize={this.state.isRightPanel ? 67:100}  onResize={(size) => this.setState({ panelResize : size})} className={`${(this.state.isStatusOpen || this.state.isModuleOpen) && (this.props.delegationList.delegationList == undefined ||this.props.delegateShowLoader || this.props.delegationList.delegationList?.length <= 6) ? "overflow-clip" : " "} ${this.state.isRightPanel ? "isShowLeftPanel rwLeftPart" : "rwLeftPart"}`}>
									{/* Xsm Break Fix Form tabs Section Start*/}
                  <MyDelegationList
                    showRightPanelFun={this.showRightPanelFun}
                    translator={this.props.translator}
                    setDelegateForEdit={this.setDelegateForEdit}
                    rowIndex={this.state.rowIndex}
                    showActiveRow={this.showActiveRow}
                    role_id={this.state.role_id}
                    isRightPanel={this.state.isRightPanel}
                    searchKey={this.state.searchKey}
                    searchValue={this.state.searchValue}
                    sortOrder={this.state.sortOrder}
                    sortOnColumn={this.state.sortOnColumn}
                    status={this.state.status}
                    setSearchKeyValue={this.setSearchKeyValue}
                    setSortOrder={this.setSortOrder}
                    setStatusParameter={this.setStatusParameter}
                    modules={this.state.modules}
                    setModulesParameter={this.setModulesParameter}
                    showListPart1={this.showListPart}
                    isClearAllFilterFunc={this.isClearAllFilterFunc}
                    isClearAllFilter={this.state.isClearAllFilter}
                    delegatorSearchInput={this.state.delegatorSearchInput}
                    delegateeSearchInput={this.state.delegateeSearchInput}
                    delegatedFromStartDate={this.state.delegatedFromStartDate}
                    delegatedFromEndDate={this.state.delegatedFromEndDate}
                    delegatedToStartDate={this.state.delegatedToStartDate}
                    delegatedToEndDate={this.state.delegatedToEndDate}
                    setDelegatedFromStartAndDate={
                      this.setDelegatedFromStartAndDate
                    }
                    setDelegatedToStartAndDate={this.setDelegatedToStartAndDate}
                    setDelegatorSearchInput={this.setDelegatorSearchInput}
                    setDelegateeSearchInput={this.setDelegateeSearchInput}
                    setSelectedValueStatus={this.setSelectedValueStatus}
                    setSelectedValueModule={this.setSelectedValueModule}
                    selectedValueStatus={this.state.selectedValueStatus}
                    selectedValueModule={this.state.selectedValueModule}
                    setDelegatedFromRef={this.setDelegatedFromRef}
                    setDelegatedToRef={this.setDelegatedToRef}
                    dateRangePickerRefDelegatedFrom={
                      this.state.delegatedFromRef
                    }
                    dateRangePickerRefDelegatedTo={this.state.delegatedToRef}
                    panelResize={this.state.panelResize}
                    isStatusOpen={this.state.isStatusOpen}
                    isModuleOpen={this.state.isModuleOpen}
							      multiSelectOpenFun={this.multiSelectOpenFun}
                  />
								</Panel>
								{this.state.isRightPanel ?
								<>
								<PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>								
  								<Panel minSize={33} order={2} defaultSize={33}>
									<div className="stickyArea rBoxStyle ">
                  {this.state.isListPart ? (
                      <EditDelegate
                        showRightPanelFun={this.showRightPanelFun}
                        showListPart1={this.showListPart}
                        translator={this.props.translator}
                        user_id={this.state.user_id}
                        role_id={this.state.role_id}
                        date_format={this.state.date_format}
                        delegate={this.state.delegate}
                        rowIndex={this.state.rowIndex}
                        showActiveRow={this.showActiveRow}
                        searchKey={this.state.searchKey}
                        searchValue={this.state.searchValue}
                        sortOrder={this.state.sortOrder}
                        sortOnColumn={this.state.sortOnColumn}
                        status={this.state.status}
                        modules={this.state.modules}
                        isClearAllFilterFunc={this.isClearAllFilterFunc}
                        isClearAllFilter={this.state.isClearAllFilter}
                        delegatorSearchInput={this.state.delegatorSearchInput}
                        delegateeSearchInput={this.state.delegateeSearchInput}
                        delegatedFromStartDate={
                          this.state.delegatedFromStartDate
                        }
                        delegatedFromEndDate={this.state.delegatedFromEndDate}
                        delegatedToStartDate={this.state.delegatedToStartDate}
                        delegatedToEndDate={this.state.delegatedToEndDate}
                        setStatusParameter={this.setStatusParameter}
                        setModulesParameter={this.setModulesParameter}
                        setDelegatedFromStartAndDate={
                          this.setDelegatedFromStartAndDate
                        }
                        setDelegatedToStartAndDate={
                          this.setDelegatedToStartAndDate
                        }
                        setDelegatorSearchInput={this.setDelegatorSearchInput}
                        setDelegateeSearchInput={this.setDelegateeSearchInput}
                        setSelectedValueStatus={this.setSelectedValueStatus}
                        setSelectedValueModule={this.setSelectedValueModule}
                        selectedValueStatus={this.state.selectedValueStatus}
                        selectedValueModule={this.state.selectedValueModule}
                        setDelegatedFromRef={this.setDelegatedFromRef}
                        setDelegatedToRef={this.setDelegatedToRef}
                        dateRangePickerRefDelegatedFrom={
                          this.state.delegatedFromRef
                        }
                        dateRangePickerRefDelegatedTo={
                          this.state.delegatedToRef
                        }
                      />
                    ) : (
                      <DelegateDescription
                        showRightPanelFun={this.showRightPanelFun}
                        showListPart1={this.showListPart}
                        delegate={this.state.delegate}
                        rowIndex={this.state.rowIndex}
                        showActiveRow={this.showActiveRow}
                        role_id={this.state.role_id}
                        searchKey={this.state.searchKey}
                        searchValue={this.state.searchValue}
                        sortOrder={this.state.sortOrder}
                        sortOnColumn={this.state.sortOnColumn}
                        status={this.state.status}
                        modules={this.state.modules}
                        isClearAllFilterFunc={this.isClearAllFilterFunc}
                        isClearAllFilter={this.state.isClearAllFilter}
                        delegatorSearchInput={this.state.delegatorSearchInput}
                        delegateeSearchInput={this.state.delegateeSearchInput}
                        delegatedFromStartDate={
                          this.state.delegatedFromStartDate
                        }
                        delegatedFromEndDate={this.state.delegatedFromEndDate}
                        delegatedToStartDate={this.state.delegatedToStartDate}
                        delegatedToEndDate={this.state.delegatedToEndDate}
                        setStatusParameter={this.setStatusParameter}
                        setModulesParameter={this.setModulesParameter}
                        setDelegatedFromStartAndDate={
                          this.setDelegatedFromStartAndDate
                        }
                        setDelegatedToStartAndDate={
                          this.setDelegatedToStartAndDate
                        }
                        setDelegatorSearchInput={this.setDelegatorSearchInput}
                        setDelegateeSearchInput={this.setDelegateeSearchInput}
                        setSelectedValueStatus={this.setSelectedValueStatus}
                        setSelectedValueModule={this.setSelectedValueModule}
                        selectedValueStatus={this.state.selectedValueStatus}
                        selectedValueModule={this.state.selectedValueModule}
                        setDelegatedFromRef={this.setDelegatedFromRef}
                        setDelegatedToRef={this.setDelegatedToRef}
                        dateRangePickerRefDelegatedFrom={
                          this.state.delegatedFromRef
                        }
                        dateRangePickerRefDelegatedTo={
                          this.state.delegatedToRef
                        }
                      />
                    )}
									</div>
								</Panel> </> : "" }
							</PanelGroup>
          

                    {/* <Tab.Container activeKey={this.state.activeTab} onSelect={(selectedKey) => {this.showActiveTab(selectedKey)}} className="nRpTabSec" id="nRpTabs">
									<div className="clearfix">
										<Nav className="nRpTabLst" bsStyle="tabs">
										<NavItem eventKey={1} onClick={() => { this.setCreateDelegate(false)}}><span title="Edit Delegation">Edit</span></NavItem>
											<NavItem eventKey={2} onClick={() => { this.setCreateDelegate(true)}}><span title="Create Delegation">Create</span></NavItem>
										</Nav>
										<Tab.Content className="nRpContSec" animation>
										{this.state.createDelegate ? 
											<CreateDelegate translator={this.props.translator} user_id={this.state.user_id} role_id={this.state.role_id} date_format={this.state.date_format} setCreateDelegate={this.setCreateDelegate} rowIndex={this.state.rowIndex} showActiveRow={this.showActiveRow} activeTab={this.state.activeTab} showActiveTab={this.showActiveTab}/> : 
											<EditDelegate translator={this.props.translator} setCreateDelegate={this.setCreateDelegate} user_id={this.state.user_id} role_id={this.state.role_id} date_format={this.state.date_format} delegate={this.state.delegate} rowIndex={this.state.rowIndex} showActiveRow={this.showActiveRow} activeTab={this.state.activeTab} showActiveTab={this.showActiveTab}/>}
										</Tab.Content>
									</div>
								</Tab.Container> */}
         
                {/* <Col md={4} className="hidden-sm hidden-xs colRightHeight">
							{this.state.createDelegate ? <CreateDelegate translator={this.props.translator} user_id={this.state.user_id} role_id={this.state.role_id} date_format={this.state.date_format} setCreateDelegate={this.setCreateDelegate} /> : <EditDelegate translator={this.props.translator} setCreateDelegate={this.setCreateDelegate} user_id={this.state.user_id} role_id={this.state.role_id} date_format={this.state.date_format} delegate={this.state.delegate} />}
						</Col> */}

               {/* <Row className="">
                 <Col xs={12} className="rwLeftPart">
                   <MyDelegationList
                    showRightPanelFun={this.showRightPanelFun}
                    translator={this.props.translator}
                    setDelegateForEdit={this.setDelegateForEdit}
                    rowIndex={this.state.rowIndex}
                    showActiveRow={this.showActiveRow}
                    role_id={this.state.role_id}
                    isRightPanel={this.state.isRightPanel}
                    searchKey={this.state.searchKey}
                    searchValue={this.state.searchValue}
                    sortOrder={this.state.sortOrder}
                    sortOnColumn={this.state.sortOnColumn}
                    status={this.state.status}
                    setSearchKeyValue={this.setSearchKeyValue}
                    setSortOrder={this.setSortOrder}
                    setStatusParameter={this.setStatusParameter}
                    modules={this.state.modules}
                    setModulesParameter={this.setModulesParameter}
                    showListPart1={this.showListPart}
                    isClearAllFilterFunc={this.isClearAllFilterFunc}
                    isClearAllFilter={this.state.isClearAllFilter}
                    delegatorSearchInput={this.state.delegatorSearchInput}
                    delegateeSearchInput={this.state.delegateeSearchInput}
                    delegatedFromStartDate={this.state.delegatedFromStartDate}
                    delegatedFromEndDate={this.state.delegatedFromEndDate}
                    delegatedToStartDate={this.state.delegatedToStartDate}
                    delegatedToEndDate={this.state.delegatedToEndDate}
                    setDelegatedFromStartAndDate={
                      this.setDelegatedFromStartAndDate
                    }
                    setDelegatedToStartAndDate={this.setDelegatedToStartAndDate}
                    setDelegatorSearchInput={this.setDelegatorSearchInput}
                    setDelegateeSearchInput={this.setDelegateeSearchInput}
                    setSelectedValueStatus={this.setSelectedValueStatus}
                    setSelectedValueModule={this.setSelectedValueModule}
                    selectedValueStatus={this.state.selectedValueStatus}
                    selectedValueModule={this.state.selectedValueModule}
                    setDelegatedFromRef={this.setDelegatedFromRef}
                    setDelegatedToRef={this.setDelegatedToRef}
                    dateRangePickerRefDelegatedFrom={
                      this.state.delegatedFromRef
                    }
                     dateRangePickerRefDelegatedTo={this.state.delegatedToRef}
                   />
                </Col>
               </Row> */}
          </div>
        </div>
        {/* {homepagelocation == "/unauthorized" ||
        homepagelocation === "/userActivationPending" ? null : (
          <FooterMain isLogout={GLOBAL.isLogout} />
        )}
        <FooterMain /> */}
      </main>
    );
  }
}

// BreakFix.propTypes = {
//   translator: PropTypes.object,
// };

const mapStateToProps = ({ delegationList, spcmReducer, delegateShowLoader }) => {
  return {
    delegationList,
    translator: spcmReducer.tr,
    delegateShowLoader: delegateShowLoader.loading,
  };
};

export default connect(mapStateToProps, {})(MyDelegationIndex);
