
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export default function orderofferingListReducer(state = initialState.orderofferingList, action){
	switch (action.type){
		case 'LOAD_ORDER_OFFERING_SUCCESS':
			return action.orderofferingList.data;
			default:
			return state;
	}
}
