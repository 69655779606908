
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";

import PindCategories from "../../common/header/pind-categories";
import Breadcrumbs from "../../common/header/breadcrumbs";
import Header from "./header";
import List from "./list";
import ShortDesc from "./shortDesc";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import "_Css/common/_table.scss";

const Index = () => {
  const [isBusy, setIsBusy] = useState(false);
  const [isRightSideVisible, setRightSideVisible] = useState(false);
  const [showFilterIcon, setShowFilterIcon] = useState(false);
  const [filterString, setFilterString] = useState("");
  const [pageNum, setPageNum] = useState(0);
  const [pgSize, setPgSize] = useState(10);
  const [isFilterCleared, setIsFilterCleared] = useState(false);
  let colWidth = "";
  isRightSideVisible ? (colWidth = 8) : (colWidth = 12);

  const showRightSideFun = (val) => {
    setRightSideVisible(val);
  };

  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Row>
          <Col md={8} xs={8}>          
            <Breadcrumbs activePageName={"Forum"} />
          </Col>
          <Col md={4} xs={4}>
            <div className="tabDatacount">1-1 of 1</div>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Header 
        setIsBusy={setIsBusy}
        setShowFilterIcon={setShowFilterIcon}
        showFilterIcon={showFilterIcon}
        filterString={filterString}
        setFilterString={setFilterString}
        pageNum={pageNum}
        setPageNum={setPageNum}
        pgSize={pgSize}
        setPgSize={setPgSize}
        isFilterCleared={isFilterCleared}
        setIsFilterCleared={setIsFilterCleared}
        />
        <PanelGroup direction="horizontal">
          <Panel id="sidebar" minSize={33} order={1} defaultSize={isRightSideVisible ? 67 : 100} className={isRightSideVisible ? "isShowLeftPanel catBordDv" : "catBordDv"}>
            <List
              isRightSideVisible={isRightSideVisible}
              showRightSideFun={showRightSideFun}
              isBusy={isBusy}
              filterString={filterString}
              setFilterString={setFilterString}
              pageNum={pageNum}
              setPageNum={setPageNum}
              pgSize={pgSize}
              setPgSize={setPgSize}
              isFilterCleared={isFilterCleared}
              setIsFilterCleared={setIsFilterCleared}

            />
          </Panel>
          {isRightSideVisible ?
            <>
              <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
              <Panel minSize={33} order={2} defaultSize={33}>
              <div className="stickyArea rBoxStyle">
                <ShortDesc showRightSideFun={showRightSideFun} />
              </div>
              </Panel>
            </> : ""
          }
        </PanelGroup>
      </Container>
    </main>
  );
};

export default Index;
