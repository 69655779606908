
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import moment from "moment-timezone";

import ListLoader from "../../../../../common/loaders/ListLoader.js";
import NoDataAvailable from "../../../../../common/NoDataAvailable";
import Table from "../../../../../common/governance/tableView";
import { useGetActionItemsQuery } from "../../../../../../reducers/governance/meeting/actionItem";

const ActionItemsListView = ({
  data,
  actDataFetching,
  meetingDefData,
  occurenceData,
}) => {
  const [visible, setVisible] = useState(false);
  const [actionItem, setActionItem] = useState(null);

  const meetingOccurrananceId = occurenceData?.meetingOccurrananceId;
  const meetingDefinationId = occurenceData?.meetingDefinationId;
  const companyID = meetingDefData?.companyId;

  const columns = useMemo(
    () => [
      {
        accessor: "actionitemCode",
        Header: "Number",
        disableSortBy: false,
        Cell: ({ row: { original } }) => {
          return (
            <Link
              to={`/add-edit/meeting/action-item-details/${original?.actionitemId}`}
              state={{
                companyID: companyID,
                meetingOccurrananceId: meetingOccurrananceId,
                meetingIdL: meetingDefinationId,
              }}
              className="taska"
            >
              {original?.actionitemCode}
            </Link>
          );
        },
      },
      {
        accessor: "additionalParams",
        Header: "Summary",
        disableSortBy: false,
        Cell: ({ row: { original } }) => {
          return (
            <div className="white-space">{original?.additionalParams}</div>
          );
        },
      },

      {
        accessor: "assignedToFullName",
        Header: "Owner",
        Cell: ({ row: { original } }) => {
          return <span>{original?.assignedToFullName || "--"}</span>;
        },
        disableSortBy: false,
      },
      {
        accessor: "statusName",
        Header: "Status",
        disableSortBy: false,
        Cell: ({ row: { original } }) => {
          return <span>{original?.statusName || "--"}</span>;
        },
      },
      {
        accessor: "lastModificationDate",
        Header: "Modified",
        disableSortBy: false,
        Cell: ({ row: { original } }) => {
          return <span>{original?.lastModificationDate || "--"}</span>;
        },
      },
    ],
    [companyID]
  );

  return (
    <>
      {actDataFetching ? (
        <div className="fnloader">
          <ListLoader />
        </div>
      ) : data?.length === 0 ? (
        <div className="container-fluid text-center pt-2">
          <NoDataAvailable />
        </div>
      ) : (
        <div class="table-responsive custom-task-list clearfix">
          <div id="scrollableDiv_">
            <Table
              columns={columns}
              data={data || []}
              tableClass="table table-sm custom-table-border text-center"
              id="action-table"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ActionItemsListView;
