
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import {useDispatch, useSelector } from "react-redux";

import PindCategories from "../../../../common/header/pind-categories";
import Breadcrumbs from "../../../../common/header/breadcrumbs";
import Header from "./ExternalLinkFormHeader";
import FormComponent from "./ExternalLinkForm";
import { useLocation } from "react-router";
import { reset } from "redux-form";
import { getBulletinEditData, resetBulletinData } from "../../../../../actions/foundation/general/BulletinAction";
import { getExternalLinkEditData, resetExternalLinksData } from "../../../../../actions/foundation/general/ExternalLinkAction";
import "_Css/form/_form.scss";

const ExternalLinkFormIndex = () => {
  const tr = useSelector((state) => state.spcmReducer.tr);
  const formType = useLocation()?.state?.formType;
  const link_Id = useLocation()?.state?.link_Id;
  const dispatch = useDispatch();


  useEffect(() => {
    if(formType === 'Edit') {
      dispatch(getExternalLinkEditData(link_Id))
    }

    return () => {
      dispatch(reset('externalLinkForm'));
      dispatch(resetExternalLinksData());
    }
  }, []);

  console.log('formType --- ', formType)

  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Breadcrumbs activePageName={tr[`${formType} External Link`]} parentPageurl='/externalLink' parentPageName={tr["External Link"]} />
      </Container>

      <Container fluid>
        <Header name={tr[`${formType} External Link`]} formType={formType} link_Id={link_Id} />
        <Row>
          <Col md={8} xs={12}>
            <FormComponent formType={formType} />
          </Col>
        </Row>
      </Container>
    </main>
  )
}

export default ExternalLinkFormIndex;
