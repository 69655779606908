
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useSelector } from "react-redux";

import PindCategories from "../../../common/header/pind-categories";
import Breadcrumbs from "../../../common/header/breadcrumbs";
import EscalationLevelHeader from "./EscalationLevelHeader";
import EscalationLevelView from "./EscalationLevelView";

const EscalationLevelIndex = () => {
  const tr = useSelector((state) => state.spcmReducer.tr);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [companyError, setCompanyError] = useState("");

  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Breadcrumbs
          activePageName={tr["Levels"]}
          parentPageurl="/escalation"
          parentPageName="Escalation"
        />
      </Container>

      <Container fluid>
        <EscalationLevelHeader
          selectedCompany={selectedCompany}
          setCompanyError={setCompanyError}
        />
        <Row>
          <Col md={12} xs={12}>
            <EscalationLevelView
              translator={tr}
              selectedCompany={selectedCompany}
              setSelectedCompany={setSelectedCompany}
              setCompanyError={setCompanyError}
              companyError={companyError}
            />
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default EscalationLevelIndex;
