
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import {
  _textArea,
  _dropDown,
  _inputField,
  _number,
  _radio,
  TypeaheadExample
} from "../../common/formFields";
import { PiPlusSquareBold } from "react-icons/pi";

class CreateAttributes extends React.Component {

  render() {
    return (<div>
      <div className="rPageHeading">
        <Button disabled={true} style={{ cursor: 'default'}} bsPrefix=" " bsClass='' className="rPlusIcn1" title={this.props.translator["Specify Attribute Values"]} bsSize="small" variant="primary" ><PiPlusSquareBold/></Button>
        <div className="offNam margin-t-5 margin-b-5">{this.props.translator["Specify Attribute Values"]}</div>
      </div>

      <div className="rBoxGap">
        <div className="margin-b-5">
          <Form.Group className="form-group">
            <Form.Label bsClass=""><span className="rStar" />{this.props.translator["Attribute"]}</Form.Label>
            <Form.Select className="myControl myInput" componentClass="select" disabled={true}>
              <option value="">select..</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label bsClass=""><span className="rStar" />{this.props.translator["Operator"]}</Form.Label>
            <Form.Select className="myControl myInput" componentClass="select" disabled={true}>
              <option value="">select..</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label bsClass=""><span className="rStar" />{this.props.translator["Value"]}</Form.Label>
            <Form.Select className="myControl myInput" componentClass="select" disabled={true}>
              <option value="">select..</option>
            </Form.Select>
          </Form.Group>
          <ButtonToolbar className="">
            <Button className="rgSidrkBtn" variant="primary" disabled={true}>AND</Button>
            <Button className="rgSiLigBtn" variant="primary" disabled={true}>OR</Button>
          </ButtonToolbar>
        </div>
      </div>
    </div>
    );
  }

}

export default CreateAttributes;