
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import { GLOBAL } from '_Globals'

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

export function loadServiceSuccess(serviceList){
	return {
		type: 'LOAD_SERVICE_SUCCESS',
		serviceList
	};
}

export function loadServiceDetailsSuccess(serviceDetails){
	return {
		type: 'LOAD_SERVICEDETAILS_SUCCESS',
		serviceDetails
	};
}

export function loadServiceList(requesterId,sorting,ordering,page,size){
	/* console.log(requesterId); */
	let str={};
	str.searchByList="requesterId,itemStatus";
	str.multipleValueList=requesterId+"|75,80,30";
	str.sortBy=sorting;
	str.orderBy=ordering;
	str.currentPage=page;
	str.size=size;
	//str = JSON.stringify(str);
	console.log("searchByList=requesterId,itemStatus&multipleValueList="+requesterId+
	"|75,80,30"+"&sortBy="+sorting+"&orderBy="+ordering);
	// "|Fulfilled,Closed"+"&sortBy="+sorting+"&orderBy="+ordering);
	return(dispatch) =>{
		dispatch({type: "LOADER_IN_PROGRESS"});
		api.post(GLOBAL.quickViewOrdersUrl,str)
			.then((serviceList)=>{
				if(!serviceList){
					throw Error(serviceList.statusText);
				}
				return serviceList;
			})
			.then((serviceList) =>{
				dispatch(loadServiceSuccess(serviceList.data))
				dispatch({type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY"});
			})
			.catch((err) => {
				console.log(err);
				dispatch({type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY"});
			});
	};
}

export function loadServiceSearchList(requesterId,searchFilter,searchText,sorting,ordering){
	/* console.log(requesterId); */
	let str={};
	str.searchByList="requesterId,itemStatus,"+searchFilter;
	str.multipleValueList=requesterId+"|75,80,30|"+searchText;
	str.sortBy=sorting;
	str.orderBy=ordering;
	if(searchText!="")
	str.patternList="in,in,like"
	//str = JSON.stringify(str);
	console.log("searchByList=requesterId,"+searchFilter+"&multipleValueList="+
			requesterId+"|"+searchText+"&sortBy="+sorting+"&orderBy="+ordering);
	return(dispatch) =>{
		api.post(GLOBAL.quickViewOrdersUrl,str)
			.then((serviceList)=>{
				if(!serviceList){
					throw Error(serviceList.statusText);
				}
				return serviceList;
			})
			.then((serviceList) =>{
				dispatch(loadServiceSuccess(serviceList.data.data))
			})
			.catch((err) => {
				console.log(err);
			});
	};
}

export function loadServiceByBilling(requesterId,billingMethod,sorting,ordering){
	//console.log(requesterId);
	let str={};
	str.searchByList="requesterId,itemStatus,billingMethod";
	str.multipleValueList=requesterId+"|75,80,30|"+billingMethod;
	str.sortBy=sorting;
	str.orderBy=ordering;
	//str = JSON.stringify(str);
	console.log("searchByList=requesterId,itemStatus,billingMethod&multipleValueList="+requesterId+
			"|75,80,30|"+billingMethod+"&sortBy="+sorting+"&orderBy="+ordering);
	return(dispatch) =>{
		api.post(GLOBAL.quickViewOrdersUrl,str)
			.then((serviceList)=>{
				if(!serviceList){
					throw Error(serviceList.statusText);
				}
				return serviceList;
			})
			.then((serviceList) =>{
				dispatch(loadServiceSuccess(serviceList.data))
			})
			.catch((err) => {
				console.log(err);
			});
	};
}

//load orderlist for orderType
export function loadServiceDetails(serviceId){
	//alert("loadOrdersListFilter"+serviceId);
		return(dispatch) =>{
			let str={};
			str.offeringId=serviceId;
			str = JSON.stringify(str);
			

			api.get(GLOBAL.breakFixServiceDetailsDataUrl,{headers:{'query':str}})
				.then((serviceDetails)=>{
					if(!serviceDetails){
						throw Error(serviceDetails.statusText);
					}
					return serviceDetails;
				})
				.then((serviceDetails) =>{
					dispatch(loadServiceDetailsSuccess(serviceDetails))
				})
				.catch((err) => {
					console.log(err);
				});
		};
}

//load userlist  from reviewsapp.mybluemix.net(review engine) for a particular offering
export function loadUserListReviewOffferingFromRE(workItemId,workItemType)
{
//export function loadReviewOrderEngineDetails(){
	//alert(workItemId);
	//console.log("userListOfferingReview");
	let config = {
    //   params: {'filter': {"where":{"offeringId":workItemId,"workItemType":workItemType}}}
	headers: {'X-IBM-Client-Id': 'b56cce80-a3fc-4194-87d7-edddb4d3bd28',
			'query':JSON.stringify({"offeringId":workItemId,"workItemType":workItemType})
		}
  };

	return(dispatch) =>{
		//axios.get(GLOBAL.feedbackGetOrdersFrmEngineUrl+{"where":{"workItemId":workItemId,"requestorId":requestorId}})
		//alert("M12");
		axios.get(GLOBAL.feedbackGetOrdersFrmEngineUrl, config)
			.then((userListOfferingReview)=>{
				if(!userListOfferingReview){
					throw Error(userListOfferingReview.statusText);
				}
				return userListOfferingReview;
			})
			.then((userListOfferingReview) =>{
				dispatch(loadUserListReviewOffferingFromRESuccess(userListOfferingReview))
			})
			.catch((err) => {
				console.log(err);
			});
	};
}

export function loadUserListReviewOffferingFromRESuccess(userListOfferingReview){
	return {
		type: 'LOAD_REVIEWUSEROFFERINGENGINE_LIST_SUCCESS',
		userListOfferingReview
	};
}

//=========
//load rating calculation  from reviewsapp.mybluemix.net(review engine) for a particular offering
export function loadRatingCalcOffferingFromRE(offeringId){
	
//export function loadReviewOrderEngineDetails(){
	//alert(workItemId);
	//console.log("userListOfferingReview");

	/*{let config = {
      //params: {"workItemId=":workItemId,"workItemType=":workItemType},
			headers: {'X-IBM-Client-Id': 'b56cce80-a3fc-4194-87d7-edddb4d3bd28'}
			/*params: {
				'filter': 'fd',
				'Test' : 'abc'
			}
  };
	let config = {
		//params: {'filter': {"where":{"workItemId":workItemId,"requestorId":requestorId,"workItemType":workItemType}}},
      params: {'filter': {"where":{"offeringId":workItemId,"&workItemType":workItemType}}},
	};}*/let config = {
		headers: {'X-IBM-Client-Id': 'b56cce80-a3fc-4194-87d7-edddb4d3bd28',
		'query':JSON.stringify({"offeringId":offeringId})
	}
};
	
	return(dispatch) =>{
		//axios.get(GLOBAL.feedbackGetOrdersFrmEngineUrl+{"where":{"workItemId":workItemId,"requestorId":requestorId}})
		//alert("M12");
		dispatch({ type: "SERVICE_REVIEW_LOADER_IN_PROGRESS" });
		// axios.get(GLOBAL.reviewCalcFrmREUrl+"/offeringId/"+workItemId)
		axios.get(GLOBAL.reviewCalcFrmREUrl, config)
			.then((ratingCalcOfferingReview)=>{
				if(!ratingCalcOfferingReview){
					throw Error(ratingCalcOfferingReview.statusText);
				}
				if(ratingCalcOfferingReview.status === 204) {
					return {data: {data: {}}};
				 }
				return ratingCalcOfferingReview;
			})
			.then((ratingCalcOfferingReview) =>{
				dispatch(loadRatingCalcOffferingFromRESuccess(ratingCalcOfferingReview));
				dispatch({ type: 'SERVICE_REVIEW_LOADER_DETAILS_FETCHED_SUCCESSFULLY' });

			})
			
			.catch((err) => {
				dispatch({ type: 'SERVICE_REVIEW_LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
			});
	};
}

export function loadRatingCalcOffferingFromRESuccess(ratingCalcOfferingReview){
	return {
		type: 'LOAD_RATINGCALCOFFERINGENGINE_LIST_SUCCESS',
		ratingCalcOfferingReview
	};
}

//======EOL==
//=======load service review from engine
//==============
//load order details from reviewsapp.mybluemix.net(review engine)
export function loadReviewServiceEngineDetails(workItemId,requestorId,workItemType){
//export function loadReviewOrderEngineDetails(){
	//alert(workItemId);
	//console.log("reviewServiceEngineDetails");

	let config = {
    //   params: {'filter': {"where":{"workItemId":workItemId,"requesterId":requestorId,"workItemType":workItemType}}},
			headers: {'X-IBM-Client-Id': 'b56cce80-a3fc-4194-87d7-edddb4d3bd28',
			'query':JSON.stringify({"offeringId":workItemId,"workItemType":workItemType})
		}
			/*params: {
				'filter': 'fd',
				'dileep' : 'abc'
			}*/
  };

	return(dispatch) =>{
		//axios.get(GLOBAL.feedbackGetOrdersFrmEngineUrl+{"where":{"workItemId":workItemId,"requestorId":requestorId}})
		//alert("M12");
		axios.get(GLOBAL.feedbackGetOrdersFrmEngineUrl, config)
			.then((reviewServiceEngineDetails)=>{
				if(!reviewServiceEngineDetails){
					throw Error(reviewServiceEngineDetails.statusText);
				}
				return reviewServiceEngineDetails;
			})
			.then((reviewServiceEngineDetails) =>{
				dispatch(loadReviewServiceEngineDetailsSuccess(reviewServiceEngineDetails))
			})
			.catch((err) => {
				console.log(err);
			});
	};
}

export function loadReviewServiceEngineDetailsSuccess(reviewServiceEngineDetails){
	return {
		type: 'LOAD_REVIEWSERVICEENGINE_DETAILS_SUCCESS',
		reviewServiceEngineDetails
	};
}

export function displayLoader(){
	return(dispatch) =>{
		dispatch({
			type:'LOADER_IN_PROGRESS',
			payload:''
		});
	};
}

export function destroyLoader(){
	return(dispatch) =>{
		dispatch({
			type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY',
			payload:''
		});
	};
}

export function emptyReducer(){
	return(dispatch) =>{
		dispatch({
			type:'LOAD_SERVICE_SUCCESS',
			serviceList:{data:{}}
		});
	};
}

export function loadItmDetails(payload, flag="noreset") {
	// console.log("payload", payload);
	return (dispatch) => {
		if(flag == "reset") return dispatch({type:"RESET_STATE"})
	  dispatch({ type: "LOADER_IN_PROGRESS_2" });
	  api
		.post(GLOBAL.quickViewOrdersUrl, payload)
		.then((reponse) => {
		  console.log("reponse :: ", reponse);
		  if (!reponse) {
			dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY_2" });
  
			throw Error(reponse.statusText);
		  }
		  dispatch({ type: "LOAD_ITM_DETAILS", payload: reponse.data.data });
		  dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY_2" });
		})
		.catch((err) => {
		  console.log(err);
		});
	};
  }