
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector, connect } from "react-redux";
import { reduxForm, SubmissionError } from "redux-form";
import { Row, Col, Button, ListGroup, Nav } from "react-bootstrap";
import { IoSaveOutline, IoClose, IoDocumentTextOutline } from "react-icons/io5";
import { BiDotsVertical } from "react-icons/bi";
import { TbTimeline } from "react-icons/tb";
import { ImSpinner6 } from "react-icons/im";
import { ImAttachment } from "react-icons/im";

import { TbCheckbox, TbCube } from "react-icons/tb";

import {
  useGetStatusTimelineQuery,
  useGetActionQuery,
} from "../../../../../../reducers/governance/meeting/meeting";

const Header = ({
  formikref,
  toggle,
  activeTabs,
  isSubmitting,
  handleOnSelectAction,
  occurrenceDetaildata,
  meetingDefData,
  submitForm,
  dirty,
  meetingOccurrananceId,
  ...props
}) => {
  const menuRef = useRef(null);
  const dispatch = useDispatch();
  const params = useParams();

  const navigate = useNavigate();
  const tr = useSelector((state) => state.spcmReducer.tr);

  const [isOpen, setIsOpen] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(
    formikref?.current?.isSubmitting || !formikref?.current?.dirty
  );

  const companyId = meetingDefData?.companyId;
  const definationId = meetingDefData?.meetingDefinationId;

  const handleSubmit = () => {
    formikref.current?.submitForm();
  };

  useEffect(() => {
    setIsSaveDisabled(
      formikref?.current?.isSubmitting || !formikref?.current?.dirty
    );
  }, [formikref]);

  return (
    <Row className="margin-b-15">
      <Col md={4} sm={6} xs={12}>
        <h1 bsClass="" className="sPageHeading1">
          Action
        </h1>
      </Col>

      <Col md={8} sm={6} xs={12}>
        <div className="paHedFilter">
          <Nav>
            <Button
              bsPrefix=" "
              id="creTag"
              role="button"
              className={
                activeTabs === "1" ? "plus myBt fillBtn" : "myBt fillBtn"
              }
              title={"Activity Details"}
              onClick={() => {
                toggle("1");
              }}
            >
              <svg
                stroke="currentColor"
                fill="none"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke-linecap="round"
                stroke-linejoin="round"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="18" height="18" x="3" y="3" rx="2"></rect>
                <path d="M17 12h-2l-2 5-2-10-2 5H7"></path>
              </svg>
            </Button>

            <Button
              bsPrefix=" "
              id="creTag"
              role="button"
              className={
                activeTabs === "4" ? "plus myBt fillBtn" : "myBt fillBtn"
              }
              title={"Action Template"}
              onClick={() => {
                toggle("2");
              }}
            >
              <ImAttachment />
            </Button>
            <Button
              bsPrefix=" "
              id="creTag"
              role="button"
              title={"More"}
              className="myBt fillBtn"
            >
              <BiDotsVertical />
            </Button>
          </Nav>

          <Button
            // disabled={isSaveDisabled}
            className="myBt fillBtn"
            bsPrefix=" "
            role="button"
            bsStyle="primary"
            title={"Save"}
            type="button"
            onClick={() => handleSubmit()}
          >
            {formikref?.current?.isSubmitting ? (
              <ImSpinner6 className="icn-spinner" />
            ) : (
              <IoSaveOutline />
            )}
          </Button>

          <Button
            bsPrefix=" "
            role="button"
            className="myBt cancel fillBtn"
            title={tr["Cancel"]}
            onClick={() => {
              navigate(
                `/add-edit/meeting/occurence-details/${meetingOccurrananceId}`
              );
            }}
          >
            <IoClose />
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default Header;
