
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Grid, Row, Col, Form, FormControl, Table, Button, } from "react-bootstrap";
import { Link } from "react-router-dom";
import CreateCiCategoryForm from "./CreateCiCategoryForm.js";
import CreateCiCategoryHeader from './CreateCiCategoryHeader.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTranslation } from '../../../../actions/spcmActions';
import Cookies from "universal-cookie";
import { navigationHooks } from "../../../../helpers/NavigationHook.js";
import CiCategoryRightPart from "../CiCategoryRightPart.js";
import "_Css/form/_form.scss";
let GLOBAL = require("_Globals");
const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
	homepagelocation = homepagelocation.substring(
		0,
		homepagelocation.lastIndexOf(".")
	);
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home") homepagelocation = "/" + homepagelocation;

class CreateCiCategoryIndex extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			extraCounts: [0]
		};
		GLOBAL.searchbarcategory = "All";
		this.loadHome = this.loadHome.bind(this);
		this.setRowCount = this.setRowCount.bind(this);
	}
	loadHome() {
		navigationHooks.navigate(homepagelocation);
	}
	setRowCount(count) {
		this.setState({extraCounts:count});
	}
	render() {
		return (
			<main>
				<div className="minHeight">
					<div className="container-fluid margin-t-10 margin-b-15">
						<nav aria-label="Breadcrumb">
							<ul className="breadcrumb">
								<li className="breadcrumb-item">
									<a
										role="button"
										href="javascript:void(0)"
										onClick={this.loadHome}
									>
										{this.props.tr["Home"]}
									</a>
								</li>
								<li className="breadcrumb-item">
									<a title={this.props.tr["Category Board"]} onClick={() => navigationHooks.navigate('/categoryBoard', { state: {activeTab: 'category'}})}>{this.props.tr["Category Board"]}</a>
								</li>
								<li className="breadcrumb-item active">
									{this.props.tr["Create"]}
								</li>
							</ul>
						</nav>
					</div>
					 <div className="container-fluid" bsClass="">
						<Form>
							<CreateCiCategoryHeader showLoader={this.state.showLoader} tr={this.props.tr} extraCounts={this.state.extraCounts}/>
							<Row className="">
								<Col md={8} sm={12} xs={12} className="rwLeftPart">
									<CreateCiCategoryForm tr={this.props.tr} setRowCount={this.setRowCount} />
								</Col>			
							</Row>
						</Form>
					</div> 
				</div>
			</main>
		);
	}
}


export function mapStateToProps({spcmReducer }) {
	return {lang: spcmReducer.lang, tr: spcmReducer.tr };
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({getTranslation}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateCiCategoryIndex);
