
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Link } from 'react-router';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { loadReviewOrderList, resetReviewFilterList, loadReviewOrderDetails, loadReviewBreakFixOrderDetails, loadReviewOrderEngineDetails } from '../../actions/reviews/reviewAction';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {LuFilterX} from "react-icons/lu";
import {HiOutlineRefresh} from 'react-icons/hi';
import {IoClose} from "react-icons/io5";
class MySurveyHeader extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.reviewListFilterChange = this.reviewListFilterChange.bind(this);
		this.refreshReviewTable = this.refreshReviewTable.bind(this);
	}

	reviewListFilterChange(e) {
		let filterVar = e.target.value;
		this.props.reviewListFilter(filterVar);
		this.props.listSort("desc");
		this.props.isFilterRerender(true);
		this.props.resetReviewFilterList();
		this.props.loadReviewOrderList("", "Breakfix,Item", "75,25", "N", this.props.sortOnColumn, this.props.sortOrder, this.props.listFilter, this.props.searchKey, this.props.searchValue, 0, 10, this.props.isSelf);
	}

	refreshReviewTable() {
		this.props.loadReviewOrderList("", "Breakfix,Item", this.props.status, this.props.sortOnColumn, this.props.sortOrder, this.props.listFilter, this.props.searchKey, this.props.searchValue, this.props.pattern, 0, 10, this.props.isSelf);
		this.props.showRightSide(false);
		this.props.saveListSize(10);
	}

	clearAllFilters() {
		this.props.setdisplayNumberInputValues("");
		this.props.setCiServiceNameInputValues("");
		this.props.setRequestedByInputValues("");
		this.props.setSearchKeyValue("", "");
		this.props.updateParameter("N");
		this.props.setSelectedValue([]);
		this.props.loadReviewOrderList("", "Breakfix,Item", "N", "displayNumber", "desc", this.props.listFilter, "", "", "", 0, 10, this.props.isSelf);
		this.props.showRightSide(false);
		this.props.isClearAllFilterFunc(false);
		this.props.saveListSize(10);
	}
	render() {
		return (
			<Row className="margin-b-15">
				<Col lg={10} md={9} sm={8} xs={12}>
					<h1 aria-label='Page Heading' role="contentinfo" bsPrefix=" " className="sPageHeading1">{this.props.tr['My Surveys']}</h1>
				</Col>
				<Col lg={2} md={3} sm={4} xs={12}>
					<div aria-label='Page Filters' role="contentinfo" className="paHedFilter">
						<div aria-label='Filter Icons' className="reqSwitdv">{this.props.isSelf ? this.props.tr["Self"] : this.props.tr["Others"]}<label aria-label={this.props.isSelf ? this.props.tr["Self"] : this.props.tr["Others"] } title={this.props.isSelf ? this.props.tr["Self"] : this.props.tr["Others"]} className="reqSwitch"><input aria-label={this.props.isSelf ? this.props.tr["Self"] : this.props.tr["Others"] } type="checkbox" value={this.props.isSelf ? "requesterId" : "createdBy"} onClick={(e) => this.props.reviewListFilter(e.target.value)} /><span onClick={() => { this.props.isSelfFun() }} className="slider"></span></label></div>
						{/* <div className="searDv"><FormControl placeholder="search" className="searInp" type="text" /><a title="search" className="faicn" href={void(0)}><i className="fa fa-search"></i></a></div> */}
						{/* <div className="overlayDv"><OverlayTrigger trigger="click" rootClose placement="bottom" overlay={filtersGroups} ><Button title="Apply Filters" role="button"  className="myBt"><i className="fa fa-filter" aria-hidden="true"></i></Button></OverlayTrigger></div>
						<div className="overlayDv"><Button title="Clear All Filters" role="button"  className="myBt clrbtn"><IoClose /><i className="fa fa-filter" aria-hidden="true"></i></Button></div> */}
						{this.props.isClearAllFilter ? <div className="overlayDv"><Button bsPrefix=' ' title={this.props.tr["Clear All Filters"]} role="button" onClick={() => { this.clearAllFilters() }} className="myBt clrbtn"><LuFilterX/></Button></div> : ""}
						<Button bsPrefix=' ' aria-label={this.props.tr["Refresh"]} title={this.props.tr["Refresh"]} role="button"  className="myBt" onClick={() => { this.refreshReviewTable(); }}><HiOutlineRefresh className='refreshFlip'/></Button>
					</div>
				</Col>
			</Row>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ loadReviewOrderList, resetReviewFilterList }, dispatch);
}

export default connect(null, mapDispatchToProps)(MySurveyHeader);
