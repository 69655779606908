
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { reduxForm, change } from 'redux-form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
// import { Row, Col, PageHeader, Nav, NavItem, Button } from 'react-bootstrap';
import { submitRuleConfiguration, emptyAssignmentDetails } from '../../../actions/amsActions/amsActions.js';
import {saveQueryBuilderRecords} from '_Actions/common/queryBuilderAction';
import { connect } from 'react-redux';
import { navigationHooks } from '../../../helpers/NavigationHook.js';

import { GLOBAL } from '../../Globals.js';
import configureStore from '../../../store/configureStore.js';
import { Link } from 'react-router-dom';
import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import { MyContext } from '_MyContext';

const store = configureStore();

let AmsHeader = class AmsHeader extends React.Component {
	static contextType = MyContext;
	constructor(props) {
		super(props);
		this.state = {
			disabledSave: false
		};
		this.saveChange = this.saveChange.bind(this);
	}

	componentDidMount() {
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
		this.context.newTabPrevent();
	}
	componentDidUpdate() {
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
		this.context.newTabPrevent();
	}

	saveChange(values) {

		console.log("value6789",values);
			let submitData={
				"ruleName": values.ruleName,
				"statusId": values.companyStatus,
				"statusValue": values.companyStatusName,
				"companyId": values.company,
				"companyName": values.companyName,
				"moduleId": values.module,
				"moduleName": values.moduleName,
				"weight": values.weight,
				"assignmentGroupId": values.isContextual === false ? values.group : 0,
				"assignmentGroupName": values.isContextual === false ? values.groupName : values.contextualGroup,
				"assignmentCompanyId": values.associatedCompany,
				"assignmentCompanyName": values.associatedCompanyName,
				"isExternal" : GLOBAL.isExternalAmsCreate,
				"createdById": "",
				"createdByName": "",  
				"createdOn": 0,       
				"eventId": values.event, 
				"eventName": values.eventName,
				"isContextual": values.isContextual,
				"groupType": values.isContextual? values.groupFunctionName : "",
				"groupTypeId": values.isContextual? values.groupTypeId : "0",
				"alternateGroupId": values['alternateGroup'] || "0",
				"alternateGroupName": values['alternateGroupName'] || ""
			};


			this.props.submitRuleConfiguration(submitData);
			
	}

	render() {
		const { handleSubmit, tr} = this.props;
		return (
			<Row className="margin-b-15">
				<Col md={7} sm={7}>
					<h2 bsClass="" className="sPageHeading1">{tr['Assignment Rules']}</h2>
				</Col>
				<Col md={5} sm={5} className="hidden-xs">
					<div className="paHedFilter">
						<Link to="javascript:void(0)" bsPrefix=' ' id="creDeleSav" className="myBt plus ctrlKeyPrevent" disabled={this.props.loader} onClick={handleSubmit((values) => this.saveChange(values))} title={tr['Save']} ref={e => this.saveObjRef = e}><span>{this.props.loader ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/>}</span></Link>
						<Link
							to="/amsHome"
							className="myBt cancel fillBtn ctrlKeyPrevent"
							title={tr["Close"]}
							ref={e => this.closeButton = e}
							>
							<IoClose/>
						</Link>
					</div>
				</Col>
			</Row>
		);
	}
}

const mapStateToProps = ({ allRuleConfigurationsData, showLoader4 }) => {
	return {
		allRuleConfigurationsData, loader: showLoader4.loading
	}
}

AmsHeader = reduxForm({
	form: 'xsmAmsCreateRulesForm'
})(AmsHeader);

export default connect(mapStateToProps, { submitRuleConfiguration, emptyAssignmentDetails,saveQueryBuilderRecords})(AmsHeader);
