
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import axios from "axios";
import { reduxForm, SubmissionError } from "redux-form";
import { ImSpinner6 } from "react-icons/im";
import { IoSaveOutline, IoClose } from "react-icons/io5";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';
let sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const CreateEscalationHeader = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const [disableBtn, setDisableBtn] = useState(false);
  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const saveObjRef = useRef(null);

	useEffect(() => {
		context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
    context.newTabPrevent();
	}, []);

  const submit = async (values) => {
    if (
      props.selectedCompany.length == 0 ||
      props.selectedChannels.length == 0
    ) {
      props.setChannelError("error");
      props.setCompanyError("error");
      throw new SubmissionError({
        _error:
          "The form has missing required fields, please revalidate the highlighted fields and update the form",
      });
    } else {
      if (props.selectedUsers.length == 0 && !values.externalEmail) {
        props.setValueError("error");
        return;
      }
      setDisableBtn(true);
      let userJson = [];
      let groupJson = [];
      let channelJson = [];
      if (props.selectedUsers.length > 0) {
        props.selectedUsers.forEach((user) => {
          userJson.push({
            memberId: user.id,
            memberName: user.label,
            escalationPlanId: 0,
          });
        });
      }

      if (props.selectedGroups.length > 0) {
        props.selectedGroups.forEach((group) => {
          groupJson.push({
            groupId: group.groupId,
            groupName: group.groupName,
            escalationPlanId: 0,
          });
        });
      }
      if (props.selectedChannels.length > 0) {
        props.selectedChannels.forEach((channel) => {
          channelJson.push({
            channelId: channel.id,
            channelName: channel.label,
          });
        });
      }

      let postJson = {
        escalationPlan: {
          companyId: props.selectedCompany[0].id,
          companyName:props.selectedCompany[0].label,
          status: values.escalationStatus,
          escalationPlan: values.plan,
          moduleName: values.escalationModule,
          escalationPlanName: values.plan,
        },
        escalationLevel: {
          status: values.escalationStatus,
          escalationPlanId: 0,
          escalationLevel: values.escalationLevel,
          notifyUserIds: userJson,
          notifyGroupIds: groupJson,
          notifyGroupManager: values.groupManager ? "Yes" : "No",
          escalationTimeout: values.escalationTimeoutMin,
          escalationPlanChannel: channelJson,
          externalEmail: values.externalEmail,
        },
      };

      axios
        .post("/rest/aiosem/saveEscalationPlanWithLevelAction", postJson)
        .then((resp) => {
          setDisableBtn(false);
          navigate("/escalation");
        })
        .catch((error) => {
          Swal.fire({ title: error?.response?.data?.businessMessage || error?.response?.data?.messageCode });
          setDisableBtn(false);
        });
    }
  };


  return (
    <Row className="margin-b-15">
      <Col lg={6} md={6} sm={12} xs={12}>
        <h2 className="sPageHeading1">{tr["Escalation Plan"]}</h2>
      </Col>
      <Col lg={6} md={6} sm={12} xs={12}>
        <div className="paHedFilter">
          <Link
            bsPrefix=" "
            id="creTag"
            to="javascript:void(0)"
            className="myBt plus ctrlKeyPrevent"
            disabled={props.submitting}
            onClick={props.handleSubmit(submit)}
            title={tr["Save"]}
            ref={saveObjRef}
          >
            {disableBtn ? (
              <ImSpinner6 className="icn-spinner" />
            ) : (
              <IoSaveOutline />
            )}
          </Link>
          <Link
            to="/escalation"
            bsPrefix=" "
            className="myBt cancel fillBtn ctrlKeyPrevent"
            title={tr["Cancel"]}
            ref={closeButton}
          >
            <IoClose />
          </Link>
        </div>
      </Col>
    </Row>
  );
};

export default reduxForm({
  form: "CreateEscalationForm",
  destroyOnUnmount: true,
  enableReinitialize: true,
})(CreateEscalationHeader);
