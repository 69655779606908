
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/

export const companyFdnList = (state = [], action) => {
    if (action.type === 'COMPANY_FDN_LIST_FETCHED_SUCCESSFULLY') {
      return action.payload;
    } else {
      return state;
    }
  };
  
export const menuListFDNReducer = (state =[],action) =>{
  if(action.type == "MENULIST_FDN_COMPANY"){
    return action.payload;
  }
  else{
    return state;
  }
}

export const editCompanyFdnList = (state = [], action) => {
  if (action.type === 'COMPANY_EDIT_LIST_FETCHED_SUCCESSFULLY') {
    return action.payload;
  } else if(action.type == 'RESET_COMPANY_EDIT_DETAILS'){
    return [];
  }else return state;
};

export const associateCompanyList = (state =[],action) =>{
  if(action.type == "ASSOCIATE_COMPANY_FETCHED_SUCCESSFULLY"){
    return action.payload;
  }
  else{
    return state;
  }
}

export const associateCompanyDropdown = (state =[],action) =>{
  if(action.type == "ASSOCIATE_COMPANY_FETCHED"){
    return action.payload;
  }
  else{
    return state;
  }
}

export const associateGroupDropdown = (state =[],action) =>{
  if(action.type == "ASSOCIATE_GROUP_FETCHED_SUCCESSFULLY"){
    return action.payload;
  }
  else{
    return state;
  }
}

export const associatedCompaniesDropdownValue = (state =[],action) =>{
  if(action.type == "ASSOCIATED_COMPANY_FETCHED_FOR_DROPDOWN"){
    return action.payload;
  }
  else{
    return state;
  }
}

export const disassociatedcompany = (state =[],action) =>{
  if(action.type == "ASSOCIATED_COMPANY_DELETED"){
    return action.payload;
  }
  else{
    return state;
  }
}

export const disassociatedGroup = (state =[],action) =>{
  if(action.type == "ASSOCIATED_GROUP_DELETED"){
    return action.payload;
  }
  else{
    return state;
  }
}

export const associationFlag = (state =[],action) =>{
  if(action.type == "ASSOCIATION_FLAG_FETCHED_SUCCESSFULLY"){
    return action.payload;
  }
  else{
    return state;
  }
}

export const syncedData = (state = {}, action) => {
  switch (action.type){
    case 'SYNC_ALL_CLICK':
        return {show: true, msg: action.payload};
    case 'SYNC_ALL_NOT_CLICK':
        return {show: false, msg: action.payload};
    default:
      return state;
  }
};


export const associationTypeMenuList = (state =[],action) =>{
  if(action.type == "ASSOCIATION_TYPE_MENULIST_FDN_COMPANY"){
    return action.payload;
  }
  else{
    return state;
  }
}

export const disassociateConsumerCompany = (state =[],action) =>{
  if(action.type == "ASSOCIATED_CONSUMER_COMPANY_DELETED"){
    return action.payload;
  }
  else{
    return state;
  }
}

export const serviceConsumerMenuList = (state =[],action) =>{
  if(action.type == "SERVICE_CONSUMER_MENULIST_FDN_COMPANY"){
    return action.payload;
  }
  else{
    return state;
  }
}

export const notServiceConsumerMenuList = (state =[],action) =>{
  if(action.type == "NOT_SERVICE_CONSUMER_MENULIST_FDN_COMPANY"){
    return action.payload;
  }
  else{
    return state;
  }
}