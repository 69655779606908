
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Col, Row } from "react-bootstrap";
import { reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';
import {IoClose} from "react-icons/io5";

// let bg = require('../../images/hcl-sx-logo.png');
// var stylingObject = {
//   logoAuth: { "backgroundImage": `url(${bg})`, "backgroundRepeat": "no-repeat", "backgroundPosition": "center", "backgroundSize": "contain", "display": "inline-block", "width": "104px", "height": "30px", "margin": "0px", "textIndent": "-9999px" }
// }

const NotificationBoardConfRightSide = (props) => {
  const tr = useSelector(state => state.spcmReducer.tr)

  return (
    <div>
      <Row>
        <Col xs={12}>
          <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
            <ul>
              <li>
                <button
                  type="button"
                  title="Minimize the right panel"
                  bsClass=""
                  bsStyle=""
                  className="closerightPanelBtn"
                  onClick={() => {
                  props.rightEditPanel(false);
                  }}
                  >
                  <IoClose/>
                  </button>
                </li>
            </ul>
          </div>
          <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{tr["Preview Configuration"]}</div></div>
        </Col>
      </Row>
      {props.onSave == "false"?
      <div className="rBoxGap">
        <div className="notiPre">
          <div className="secdv"><span className="tag">To</span><span className="tagDes">{tr["Recipient"]}</span></div>
          <div className="secdv"><span className="tag">CC</span><span className="tagDes">{tr["Recipient"]}</span></div>
          <div className="secdv"><span className="tag">{tr["Subject"]}</span><span className="tagDes">{tr["Subject Line"]}</span></div>

          {props.logoPreviewState == "" ||props.logoPreviewState == null || props.logoPreviewState == "null"?           
           <div className="secdv" style={{borderBottom:'none'}}>
          <div className="secdv">{tr["Company Logo"]}</div>
          </div>:
          <div className="secdv" style={{borderBottom:'none'}}>
            {/* <div style={stylingObject.logoAuth}>{tr["Company Logo"]}</div> */}
            {/* <div className="secdv">{props.logoState}</div> */}
            <img src={props.logoPreviewState} width="100" height="50"></img>
            </div>
          }
          <div className="notiBody secdv">
            <div className="f-size-30"><i className="fa fa-envelope-o" aria-hidden="true"></i></div>
            <div className="">{tr["Notification Body"]}</div>
          </div>

          {/* <div id="nePreview" dangerouslySetInnerHTML={{ __html:props.dbtemplate}} /> */}

          {props.signaturePreviewState == "" ||props.signaturePreviewState == null || props.signaturePreviewState == "null"?            
          <div className="secdv">{tr["Signature"]}</div> :
          <div className="secdv"  style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>{props.signaturePreviewState}</div>
          }

          {props.footerTxtPreviewState == "" ||props.footerTxtPreviewState == null || props.footerTxtPreviewState == "null"?           
          <div className="secdv">{tr["Footer Text"]}</div> :
          <div className="secdv" style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>{props.footerTxtPreviewState}</div>
          }

          {props.footerImgPreviewState == "" ||props.footerImgPreviewState == null || props.footerImgPreviewState == "null"?            
          <div className="secdv">{tr["Footer Image"]}</div> :
          // <div className="secdv">{props.footerImgState}</div>
          <img src={props.footerImgPreviewState} width="100%" height="100%"></img>

          }  

          {props.footerNotePreviewState == "" ||props.footerNotePreviewState == null || props.footerNotePreviewState == "null"?            
          <div className="secdv">{tr["Footer Note"]}</div> :
          <div className="secdv text-c" style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>{props.footerNotePreviewState}</div>
          } 

        </div>
      </div>
      : 
      <div className="rBoxGap">
        <div className="notiPre">
          <div className="secdv"><span className="tag">To</span><span className="tagDes">{tr["Recipient"]}</span></div>
          <div className="secdv"><span className="tag">CC</span><span className="tagDes">{tr["Recipient"]}</span></div>
          <div className="secdv"><span className="tag">{tr["Subject"]}</span><span className="tagDes">{tr["Subject Line"]}</span></div>
          {props.logoState == "" ||props.logoState == null || props.logoState == "null"?            
          <div className="secdv" style={{borderBottom:'none'}}>
          <div className="secdv">{tr["Company Logo"]}</div>
          </div>:
          <div className="secdv" style={{borderBottom:'none'}}>
            {/* <div style={stylingObject.logoAuth}>{tr["Company Logo"]}</div> */}
            {/* <div className="secdv">{props.logoState}</div> */}
            <img src={props.logoState} width="100" height="50"></img>
            </div>
          }
          <div className="notiBody secdv">
            <div className="f-size-30"><i className="fa fa-envelope-o" aria-hidden="true"></i></div>
            <div className="">{tr["Notification Body"]}</div>
          </div>

          {/* <div id="nePreview" dangerouslySetInnerHTML={{ __html:props.dbtemplate}} /> */}

          {props.signatureState == "" ||props.signatureState == null || props.signatureState == "null"?            
          <div className="secdv">{tr["Signature"]}</div> :
          <div className="secdv"  style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>{props.signatureState}</div>
          }

          {props.footerTxtState == "" ||props.footerTxtState == null || props.footerTxtState == "null"?           
          <div className="secdv">{tr["Footer Text"]}</div> :
          <div className="secdv" style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>{props.footerTxtState}</div>
          }

          {props.footerImgState == "" ||props.footerImgState == null || props.footerImgState == "null"?            
          <div className="secdv">{tr["Footer Image"]}</div> :
          // <div className="secdv">{props.footerImgState}</div>
          <img src={props.footerImgState} width="100%" height="100%"></img>

          }  

          {props.footerNoteState == "" ||props.footerNoteState == null || props.footerNoteState == "null"?            
          <div className="secdv">{tr["Footer Note"]}</div> :
          <div className="secdv text-c" style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>{props.footerNoteState}</div>
          } 

        </div>
      </div>
      }
    </div>
  );
}

export default reduxForm({
  form: 'configurationRight',
  enableReinitialize: true,
})(NotificationBoardConfRightSide);