
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { reduxForm,SubmissionError } from "redux-form";
import { postFDNCompanyAction } from "../../../../../actions/foundation/companyAction";
import swal from "sweetalert";
import axios from 'axios';

import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import {HiOutlineTrash} from "react-icons/hi";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';

const HeaderSec = (props) => {
  let { handleSubmit, submitting } = props;
  const tr = useSelector((state) => state.spcmReducer.tr);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const saveObjRef = useRef(null);

	useEffect(() => {
		context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
    context.newTabPrevent();
	}, []);

  const submit = async (values) => {
    console.log("CompanyCreate", values)
    let isValid = true;
    if (!values.name || values.name.trim() == "") {
        props.setNameErr("error");
        isValid = false;
    } else {
      props.setNameErr("");
    }
    // if (typeof (values.companyType) == 'undefined' || values.companyType == '' || values.companyType.trim() == '') {
    //     props.companyTypeErrFun();
    //     isValid = false;
    // } 
    console.log("CompanyValues",values.companyType)
    values.companyType = props.companyType
    console.log("CompanyValues12",values.companyType, Array.isArray(values.companyType))
    if(values.companyType.length == 0){
        props.companyTypeErrFun('error');
        isValid = false;
    }else{
        props.companyTypeErrFun('');
    }
    if (!values.description || values.description.trim() == "") {
        props.setDescriptionErr("error");
        isValid = false;
    } else {
      props.setDescriptionErr("");
    }

    // let formData = new FormData();
    // if (values.fdnImg != null && values.fdnImg != "null") {
    //   const footerFileUpload = values.fdnImg[0];
    //   formDataFooter.append('footerFiles', footerFileUpload);
    //   formDataFooter.append('id', values.name);
    //   // formDataFooter.append('suffix', 'footer');

    // }

    if (isValid == true) {
    let postjson;
    postjson = {
      file: null,
      fileLogo: null,
      companyImageTempURL: null,
      xsmLogoTempURL: null,
      container: null,
      imagename: null,
      company_code: "COM000000000001",
      actionVal: "createCompany",
      company_prefix_req: values.company_prefix_req ? values.company_prefix_req : null,
      notification_flag: values.notification_flag ? values.notification_flag : "Y",
      knowledge_flag: values.sms_notification_flag ? values.sms_notification_flag : "N",
      field1Key: null,
      field1Value: null,
      group_Id: null,
      group_Name: null,
      company_Name: "priyanka Company",
      sequence: null,
      urgency: null,
      impact: null,
      priority: null,
      label: null,
      businessFunctionId: null,
      multilingual_flag: values.multilingual_flag ? values.multilingual_flag : "N",
      sms_notification_flag: values.sms_notification_flag ? values.sms_notification_flag : "N",
      filterby: null,
      pagesize: 0,
      vendorId: null,
      vendorIds: null,
      aliasName: values.aliasName ? values.aliasName.trim() : "",
      name: values.name,
      description: values.description,
      companyType: props.companyType,
      companyTypeName: null,
      eventNameDefault: null,
      associateCompanyType: null,
      associatedCompanies: null,
      companyTypes: null,
      associationActiveFlag: null,
      associationActiveFlagWithOtherCompanies: null,
      userId: 0,
      status: values.status ? values.status : "1",
      associatedAction: null,
      filterbytext: null,
      checkActionMode: null,
      welcomeMessage: "welcome priyanka",
      product_container: null,
      groupList: null,
      logo: null,
      footerNote: null,
      portalName: null,
      otherInfo: null,
      footerImg: null,
      footerSignature: null,
      lucyUrl: values.lucyUrl ? values.lucyUrl.trim() : null ,
      module: null,
      field: null,
      key: null,
      newKey: null,
      value: 0,
      language: null,
    };
    // return axios.post("/api/uploadCompanyLogoSelfService",formData).then((reponse) => {
    //   fdnLogo = response.data.TempFileName;
    // }

    // );
      // return axios.post("/api/saveCompany", postjson).then((response) => {
      //   console.log("response++++",response)
      //   if (response.data.CompanyData == "Company is Created" && response.status == 201) {
      //     swal({
      //       text: "Company has been Created",
      //       button: 'OK',
      //     });
      //     navigate("/editCompany/"+props.companyData.id);
      //   }
      //   else if (response.data == "Name already exists") {
      //     swal({
      //       text: response.data,
      //       button: 'OK',
      //     });
      //     return false;
      //   }
      // })
      // .catch((error) => {

      // });
    dispatch(postFDNCompanyAction(postjson));
    }
  };
  return (
    <>
      <Row className="margin-b-15">
        <Col lg={4} md={6} sm={6} xs={5}>
          <h1 bsClass="" className="sPageHeading1">
            {tr["Company"]}
          </h1>
        </Col>
        <Col lg={8} md={6} sm={6} xs={7}>
          <div className="paHedFilter">
            <Link
              bsPrefix=" "
              id="hdID"
              to="javascript:void(0)"
              className="myBt plus ctrlKeyPrevent"
              disabled={submitting}
              onClick={handleSubmit(submit)}
              title={tr["Save"]}
              ref={saveObjRef}
            >
              <span>{props.loader ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/> }</span>
            </Link>
            <Link
              bsPrefix=" "
              to="/Company"
              className="myBt cancel fillBtn ctrlKeyPrevent"
              title={tr["Cancel"]}
              ref={closeButton}
            >
              <IoClose/>
            </Link>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default reduxForm({
  form: "CreateCompanyForm",
})(HeaderSec);
