
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { LuFilterX } from "react-icons/lu";
import { HiOutlineRefresh } from "react-icons/hi";
import { myViewNotificationAction } from "../../../actions/spcmActions";

const MyHeader = (props) => {
  const dispatch = useDispatch();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const isWalkupEnabled = useSelector((state) => state.walkupEnabled.walkupEnabled);

const clearAllFilters = () => {
  props.setNameSearchInput("");
  props.setCompanySearchInput("");
  props.setOrderNumberSearchInput("");
  props.setSelectedDate([]);
  props.setModifiedStartAndEndDate({}, {}, []);
  props.setPage(1);
  props.setLimit(10);
  props.setSearchParams({});
  props.showRightSideFun(false);
  props.isClearAllFilterFunc(false);
  props.showActiveRow(0);
  dispatch(myViewNotificationAction(props.email_address,props.tab,"","",1,10,"","true"))
  props.modifiedDateRef.startDate = moment();
  props.modifiedDateRef.endDate = moment();    

}
const refreshHandler = () => {
  dispatch(myViewNotificationAction(props.email_address,props.tab, "", "", 1, 10, "", "true")); 
  props.showRightSideFun(false);
  props.setPage(1);
  props.setLimit(10);
}

  return (
    <>
      <Row className="margin-b-15">
        <Col lg={10} md={10} sm={12} xs={12}>
          <ul className="wibTabs">
            <li>
              <Link title={tr["My Requests"]} to="/myOrders">
                {tr["My Requests"]}
              </Link>
            </li>
            {isWalkupEnabled && <li><Link title={tr["My Walk Up Requests"]} to="/walkUpReq">{tr["My Walk Up Requests"]}</Link></li>}
            <li>
              <Link title={tr["My Assets/CIs"]} to="/myAssets">
                {tr["My Assets/CIs"]}
              </Link>
            </li>
            <li className="active">
              <Link title={tr["My Notifications"]} to="/myNotifications">
                {tr["My Notifications"]}
              </Link>
            </li>
          </ul>
        </Col>
        <Col lg={2} md={2} sm={12} xs={12}>
          <div aria-label="Page Filters" role="contentinfo" className="paHedFilter">
           
            {props.isClearAllFilter ? <div className="overlayDv"><Button title={tr["Clear All Filters"]} role="button" onClick={() => { clearAllFilters() }} bsPrefix=' ' className="myBt clrbtn"><LuFilterX /></Button></div> : ""}

            <Button aria-label={tr["Refresh"]} title={tr["Refresh"]} role="button" bsClass="" bsPrefix=" " className="myBt" onClick={() => { refreshHandler() }} >
              <HiOutlineRefresh className="refreshFlip" />
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="margin-b-10">
        <Col lg={8} md={12} sm={12} xs={12}>
          <div aria-label="Table Filter" role="contentinfo" id="assFiltr">
            <div className="filtr">
              <Button className={props.filterTabActive == 1 ? "active" : null} title={tr["Direct (to)"]} bsPrefix=" " value="direct" onClick={(e) => {
                console.log("DIRECT")
                dispatch(myViewNotificationAction(props.email_address,e.target.value, "", "", 1, 10, "", "true"));
                props.tabHandler(e.target.value,1);
                props.setNameSearchInput("");
                props.setCompanySearchInput("");
                props.setOrderNumberSearchInput("");
                props.setSelectedDate([]);
                props.setModifiedStartAndEndDate({}, {}, []);
                props.setPage(1);
                props.setLimit(10);
                props.setSearchParams({});
                props.showRightSideFun(false);
                props.isClearAllFilterFunc(false);
                // props.modifiedDateRef.startDate = moment();
                // props.modifiedDateRef.endDate = moment();   
                }}>
                {tr["Direct (to)"]}
              </Button>
              <Button className={props.filterTabActive == 2 ? "active" : null} title={tr["Watcher (cc)"]} bsPrefix=" " value="watcher" onClick={(e) => {
                console.log("WTACHER")
                dispatch(myViewNotificationAction(props.email_address,e.target.value, "", "", 1, 10, "", "true"));
                props.tabHandler(e.target.value,2);
                props.setNameSearchInput("");
                props.setCompanySearchInput("");
                props.setOrderNumberSearchInput("");
                props.setSelectedDate([]);
                props.setModifiedStartAndEndDate({}, {}, []);
                props.setPage(1);
                props.setLimit(10);
                props.setSearchParams({});
                props.showRightSideFun(false);
                props.isClearAllFilterFunc(false);
                // props.modifiedDateRef.startDate = moment();
                // props.modifiedDateRef.endDate = moment();   

                }}>
                {tr["Watcher (cc)"]}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default MyHeader;
