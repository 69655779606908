
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';

//let user_id=userDetails.user_id;
//let company_id=userDetails.company_id;

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

export function loadFavoritesSuccess(favorites){
	return {
		type: 'FETCH_FAVOURITE',
		favorites
	};
}

export function loadFavorites(start,end){
	let str={};
    str.companyId="";
	str.userId="";
	str.currency="USD";
	if(start!=""){
		str.start=start;
	}
	if(end!=""){
		str.end=end;
	}
	str = JSON.stringify(str);

	const favoriteUrl="/getFavouriteForRestifySelfService"
	return(dispatch) =>{
		dispatch({ type: "FAVOURITE_SERVICE_LOADER_IN_PROGRESS" });
		api.get(favoriteUrl,{headers:{'query':str}})
			.then((favorites)=>{
				if(!favorites){
					throw Error(favorites.statusText);
				}
				return favorites;
			})
			.then((favorites) =>{
				dispatch(loadFavoritesSuccess(favorites))
				dispatch({ type: 'FAVOURITE_SERVICE_LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
			})
			.catch((err) => {
				// console.log(err);
				dispatch({ type: 'FAVOURITE_SERVICE_LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
			});
	};
}

