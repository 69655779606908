
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { Alert, OverlayTrigger, Popover, Table } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import axios from "axios";
import Cookies from "universal-cookie";
import Datetime from "react-datetime";
import Swal from "sweetalert2";
import ListLoader from "../../loaders/ListLoader";
import { getAvailableIndividuals } from "../../../../actions/onCallSchedule/common/onCallCommonAction";
import XsmUserDetails from "_Commons/WorkItemBoard/xsmEditRequestedBy.js";
import { LuUserCheck2 } from "react-icons/lu";
import {ImInfo} from "react-icons/im";

/* cookies use for getting company id */

const cookie = new Cookies();
const gph = cookie.get("gph").split("~");
let dateformat = cookie.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
dateformat = dateformat.split("~");
dateformat = dateformat[2];

const AvailableIndividuals = (props) => {
  const translator = useSelector((state) => state.spcmReducer.tr);
  const dispatch = useDispatch();
  const availableIndividuals = useSelector(
    (state) => state.availableIndividuals.currentAvailableUser
  );
  const upComingShiftsUser = useSelector(
    (state) => state.availableIndividuals.upcomingShift
  );
  const loading = useSelector((state) => state.showLoader.loading);
  const [showErrorText, setShowErrorText] = useState("");
  const [showError, setShowError] = useState("");
  const [users, setUsers] = useState([]);
  const [upcomingShiftUsers, setUpcomingShiftUsers] = useState([]);

  useEffect(() => {
    if (
      props.selectedAssignmentGroup &&
      props.selectedAssignmentGroup.length > 0
    ) {
      let group = props.selectedAssignmentGroup[0];
      dispatch(getAvailableIndividuals(group.id || group.value));
    }
    if (props.selectedImpGroup && props.selectedImpGroup.length > 0) {
        let group = props.selectedImpGroup[0];
        dispatch(getAvailableIndividuals(group.id || group.value));
      } else {
      dispatch({
        type: "GET_AVAILABLE_INDIVIDUALS",
        users: [],
      });
    }
  }, [props.selectedAssignmentGroup,props.selectedImpGroup]);

  useEffect(() => {
    setUsers(availableIndividuals);
  }, [availableIndividuals]);
  
  useEffect(() => {
    setUpcomingShiftUsers(upComingShiftsUser);
  }, [upComingShiftsUser]);

  const selectUser = (userId, userName) => {
    let userSelection = [
      {
        id: userId,
        label: userName,
        value:userId
      },
    ];
    if(props.module==="Implementation"){
        props.setAssignmentGrpUsrImp(userSelection, "user");
    }else{
        props.setAssignmentGrpUsr(userSelection, "user");
    }
  };

  const renderTagData = (list) => {
    return list.map((data, index) => {
      let id = "list-" + index;
      const tooltip = (
        <Popover className="userInfoPopup">
          <XsmUserDetails
            userId={data.userId}
            translator={translator}
            isHeadingVisible={false}
            isPopup={true}
          />
        </Popover>
      );
      return (
        <tr key={id}>
          <td>
            {data.userName}{" "}
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="left"
              key={id}
              overlay={tooltip}
            >
              <span title="info" className="cursorPoint">
                {data.watcherName}{" "}
                <ImInfo />
              </span>
            </OverlayTrigger>
          </td>
          {props.disableFieldsForGuestRole?'':<td>
            <Button
              title={translator["Assign"]}
              onClick={(e) => selectUser(data.userId, data.userName)}
            >
              <LuUserCheck2 />
            </Button>
          </td>}
        </tr>
      );
    });
  };
  const renderUpcomingShiftData = (list) => {
    return list.map((data, index) => {
      console.log("Data==>",data)
      let id = "list-" + index;
      const tooltip = (
        <Popover className="userInfoPopup">
          <XsmUserDetails
            userId={data.userId}
            translator={translator}
            isHeadingVisible={false}
            isPopup={true}
          />
        </Popover>
      );
      return (
        <tr key={id}>
          <td>
            {data.userName}{" "}
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="left"
              key={id}
              overlay={tooltip}
            >
              <span title="info" className="cursorPoint">
                {data.watcherName}{" "}
                <ImInfo />
              </span>
            </OverlayTrigger>
          </td>
          <td>
            {data.shiftName}{" "}
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="left"
              key={id}
              overlay={tooltip}
            >
              <span>
              </span>
            </OverlayTrigger>
          </td>
          <td>
            {data.eventStartDateTime}{" "}
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="left"
              key={id}
              overlay={tooltip}
            >
              <span>
              </span>
            </OverlayTrigger>
          </td>
          {props.disableFieldsForGuestRole?'':<td>
            <Button
              title={translator["Assign"]}
              onClick={(e) => selectUser(data.userId, data.userName)}
            >
              <LuUserCheck2 />
            </Button>
          </td>}
        </tr>
      );
    });
  };

  ///main render part
  return (
    <div className="hambTab watcher-container">
      {/* <div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
        <ul>
          <li>
            <Button bsStyle="" bsClass="" bsPrefix=" " className="myBt">
              <HiPlus />
            </Button>
          </li>

          <li>
            <Button bsStyle="" bsClass="" bsPrefix=" " className="myBt">
              <PiPencilSimpleBold />
            </Button>
          </li>

          <li className="d-md-none">
            <button
              title="Minimize the right panel"
              bsClass=""
              bsStyle=""
              className="closerightPanelBtn"
            >
              <IoClose />
            </button>
          </li>
        </ul>
      </div> */}
      <div className="rPageHeading">
        <div className="offNam margin-t-5 margin-b-5">
          {translator['Available Individuals']}
        </div>
      </div>

      <div className="rBoxGap">
        {showError ? (
          <Alert
            style={{
              marginBottom: "15px",
              fontSize: "13px",
              padding: "8px 30px 8px 8px",
            }}
            variant="danger"
          >
            <h4>{showErrorText}</h4>
          </Alert>
        ) : (
          ""
        )}

        <div>
        <b>{['Available User']}</b>
          <Table
            bordered
            condensed
            hover
            responsive
            className="tableView tableWithOutPointer"
          >
            <thead>
              <tr>
                <th className="sort">{translator['Name']}</th>
                {props.disableFieldsForGuestRole?'':<th className="sort">{translator['Action']}</th>}
              </tr>
            </thead>
            {loading && (
              <tbody>
                <tr>
                  <td colSpan={3}>
                    <ListLoader />
                  </td>
                </tr>
              </tbody>
            )}
            {!loading && (
              <tbody>
                {users && users.length > 0 ? (
                  renderTagData(users)
                ) : (
                  <tr>
                    <td colSpan={3} className="text-c">
                      {translator["No Data Available"]}
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </Table>
        </div>
        <div>
        <b>{['Upcoming Shift']}</b>
        <Table
            bordered
            condensed
            hover
            responsive
            className="tableView tableWithOutPointer"
          >
            <thead>
              <tr>
                <th className="sort">{translator['Name']}</th>
                <th className="sort">{translator['Shift Name']}</th>
                <th className="sort">{translator['Start Time']}</th>
                {props.disableFieldsForGuestRole?'':<th className="sort">{translator['Action']}</th>}
              </tr>
            </thead>
            {loading && (
              <tbody>
                <tr>
                  <td colSpan={3}>
                    <ListLoader />
                  </td>
                </tr>
              </tbody>
            )}
            {!loading && (
              <tbody>
                {upcomingShiftUsers && upcomingShiftUsers.length > 0 ? (
                  renderUpcomingShiftData(upcomingShiftUsers)
                ) : (
                  <tr>
                    <td colSpan={4} className="text-c">
                      {translator["No Data Available"]}
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </Table>
        </div>
      </div>
    </div>
  );
};

export default reduxForm({
  form: "AvailableIndividuals",
})(AvailableIndividuals);
