import { Dialog } from "@progress/kendo-react-dialogs";
import React, { useState, useEffect } from "react";
import View from "../../View";
import { replaceVariables } from "../../../studio/utils";
import { Modal } from "react-bootstrap";

const PopupModal = ({ open, setOpenPopup, ...props }) => {
  const [visible, setVisible] = useState(false);
  const { buttonProp, data } = props?.buttonProp;
  const root = document.documentElement;
  visible && buttonProp?.openPageInPopup?.id == "bootstrapPopup"
    ? root?.style.setProperty("--modalBackDrop", 0.5)
    : root?.style.setProperty("--modalBackDrop", 0);
  const toggleDialog = () => {
    setVisible(!visible);
    setOpenPopup(!visible);
  };

  useEffect(() => {
    setVisible(open);
  }, [open]);

  return (
    <>
      {visible && buttonProp?.openPageInPopup?.id == "kendoPopup" && (
        <Dialog
          title={replaceVariables(buttonProp?.popupTitle, data)}
          onClose={toggleDialog}
          minWidth={"70%"}
          height={"95vh"}
        >
          {buttonProp?.page && (
            <View
              parantValues={data}
              pageId={buttonProp?.page?.id}
              isChildPage={true}
            />
          )}
        </Dialog>
      )}

      {visible && buttonProp?.openPageInPopup?.id == "bootstrapPopup" && (
        <Modal show={visible} onHide={toggleDialog} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>
              {replaceVariables(buttonProp?.popupTitle, data)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {buttonProp?.page && (
              <View
                parantValues={data}
                pageId={buttonProp?.page?.id}
                isChildPage={true}
              />
            )}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default PopupModal;
