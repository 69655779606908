
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Card from 'react-bootstrap/Card';
import { connect } from 'react-redux';
import { getAuditHistory } from '../../../actions/spcmActions';

class ServiceHistory extends React.Component {
	constructor(props){
	super(props);
		this.state = {
			showUserData:false
		};
		this.componentWillMount = this.componentWillMount.bind(this);
		this.showUserDetails = this.showUserDetails.bind(this);
	};

	componentWillMount(){
		this.props.getAuditHistory(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
	}

	showUserDetails(){
		this.setState({showUserData:!this.state.showUserData});
	};	
	render(){
	
		let audit=this.props.spcmReducer.audit;
		return(
			<div className="rBoxGap f-size-13">
				{audit.map((value,key)=>{
					return(
					<Card className="margin-b-5">
					<Card.Body>
						<div className="blue">{value.created_on} <span className="colorStOrange cursorPoint" >{value.createdby_name}</span></div>
						<div className="black fw-300 f-size-12"><i>{value.audit_field} modified from {value.fromname||"-"} to {value.toname||"-"}</i></div>
					</Card.Body>
										{this.state.showUserData ? <div className="bgGray padding-5"><div className="display-inline-block"><div className="f-size-12">HUL_Portfolio_Manager</div><div>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</div></div></div> : null}

				</Card>
				)
				})}
			</div>
		);
	}
}

const mapStateToProps = ({ spcmReducer })=>{
	return {
		spcmReducer,
		initialValues:spcmReducer.editService
	}
}


export default connect(mapStateToProps,{getAuditHistory}) (ServiceHistory);