
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { GLOBAL } from "_Globals";
import axios from 'axios';
import { connect } from 'react-redux';
import { loadImplPlanList } from '../../../../actions/common/attachmentAction';
import _ from 'lodash';
import configureStore from '../../../../store/configureStore';
import constanturl from '../../../../utils/constants';
const store = configureStore();
import ListGroup from 'react-bootstrap/ListGroup';

import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';
import {IoClose} from "react-icons/io5";
import { fileobjectGenerator,validateFile } from '../../../common/MIMEObjGenerator';
const cookies = new Cookies();

let attachmentDetails = cookies.get('gph');
if(attachmentDetails) attachmentDetails = attachmentDetails.replace('s:','');
if(attachmentDetails) attachmentDetails = attachmentDetails.substring(0,attachmentDetails.lastIndexOf('.'));
attachmentDetails=attachmentDetails.split("~");
const attachmentSize=attachmentDetails[21];
const attachmentTypeStr = attachmentDetails[22];
const attachmentType = fileobjectGenerator(attachmentDetails[22].split(', '));


class UploadScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filesPreview: [],
            filesToBeSent: [],
            printcount: 1,
            clickAttachButton: 0,
            fileNameToCompare: '',
            attach_IdUpdate: ''
        };
        //this.deleteFileAction = this.deleteFileAction.bind(this);
        this.removeFile = this.removeFile.bind(this);
    }
    componentDidMount() {
        //alert("from New Component")
        let itemId;
        let module;
        //alert("==="+module);
        if (_.has(this.props.itemDetails, 'breakfixNumber')) {
            module = 'Breakfix';
            itemId = this.props.itemDetails.breakfixId;
        } else if (_.has(this.props.itemDetails, 'changeNumber')) {
            module = 'ChangeManagement';
            itemId = this.props.itemDetails.changeId;
        } else if (_.has(this.props.itemDetails, 'investigationNumber')) {
            module = 'Investigation';
            itemId = this.props.itemDetails.investigationId;
        } else if (_.has(this.props.itemDetails, 'itemNumber')) {
            module = 'Item';
            itemId = this.props.itemDetails.itemId;
        }
        //this.props.loadImplPlanList(module, itemId);
    }


    removeFile() {

        this.setState({ filesToBeSent: [], filesPreview: [] });
        this.setState({ fileNameToCompare: "" });
        GLOBAL.filesToBeSent=[];
    }
    onDrop(acceptedFiles) {
        GLOBAL.filesToBeSent=[];
        let filesToBeSent = this.state.filesToBeSent;
        if (acceptedFiles.length > 0) {
            if (filesToBeSent.length < this.state.printcount) {
                filesToBeSent.push(acceptedFiles);
                let filesPreview = [];
                for (let i in filesToBeSent) {
                    if (filesToBeSent.hasOwnProperty(i)) {
                    console.log(filesToBeSent)
                    console.log(filesToBeSent[i][0])
                    filesPreview.push(
                        <ListGroup.Item bsClass=""><span className="float-r cursorPoint" title='Remove' onClick={() => { this.removeFile(); }}><IoClose /></span>
                            {filesToBeSent[i][0].name}
                        </ListGroup.Item>
                    )
                    this.setState({ fileNameToCompare: filesToBeSent[i][0].name });
                    console.log(filesToBeSent[i][0].name);
                    console.log(this.state.fileNameToCompare);
                }
            }

                this.setState({ filesToBeSent, filesPreview });
                GLOBAL.filesToBeSent=filesToBeSent;
                console.log(this.state.fileNameToCompare);
            }
            else {
                Swal.fire(this.props.translator["File already attached"]);
            }
        }
        else {
            // alert("Maximum File Size for upload is 10Mb");
            // alert("File not supported.");
        }
        console.log(this.state.fileNameToCompare)
    }

    onDropRejected(rejectedFiles) {
        if(rejectedFiles && rejectedFiles[0] && rejectedFiles[0].file && rejectedFiles[0].file.size && rejectedFiles[0].file.size > 1048576*attachmentSize) {
            Swal.fire(this.props.translator['Please upload File up to '] + attachmentSize + this.props.translator[' MB size.']);
        }
        else {
            Swal.fire("File not supported.");
        }
    }

    handleClick(event, itemId) {
        let self = this;
        if (this.state.filesPreview.length > 0) {
            self.setState({
                clickAttachButton: 1
            });
        }

        if (this.state.filesToBeSent.length > 0) {
            let formData = new FormData();
            formData.append('file', this.state.filesToBeSent[0][0]);
            let jsonObject = new Object;
            jsonObject.module = 'Breakfix';
            jsonObject.itemId = itemId;


            jsonObject.createdOn = Math.round(new Date().getTime() / 1000.0);
            jsonObject.createdBy = "";
            store.dispatch({ type: 'LOAD_IMPLPLAN_SUCCESS', implPlanList: {} });
            formData.append("attachment", JSON.stringify(jsonObject));

            axios.post("/change/api/saveImplPlan", formData).then((response) => {
                //debugger
                console.log(response)
                let res = response.data
                if (response.status == 200) {
                    this.props.loadImplPlanList(jsonObject.module, jsonObject.itemId)
                    // alert(this.props.translator['file uploaded successfully.']);
                    this.setState({ filesToBeSent: [], filesPreview: [], clickAttachButton: 0 });

                }
                res.forEach((item, index) => {
                    console.log(item.filename === this.state.fileNameToCompare && item.status == "N")
                    if (item.filename === this.state.fileNameToCompare && item.status == "N") {
                        this.setState({ attach_IdUpdate: item.attachmentId })
                    }

                });
            })
                .catch((error) => {
                    // alert(this.props.translator['Please upload File up to 10 MB size.']);
                    // alert(this.props.translator['Please upload File up to '] + attachmentSize + this.props.translator[' MB size.']);
                    console.log("error in uploading file.")
                });
        }
        else {
            Swal.fire(this.props.translator["Please upload some files first"]);
        }
    }


    render() {
        return (
            <div>
                {/* <Dropzone className="text-c cursorPoint grayBg" onDrop={(files) => this.onDrop(files)} onDropRejected={(files) => this.onDropRejected(files)}  maxSize={1048576*attachmentSize} accept={attachmentType} style={{ "width": "100%", "height": "60", "padding-top": "20px", "border": "1px solid black", "border-top-color": "#bdc4c9", "border-right-color": "#bdc4c9", "border-bottom-color": "#bdc4c9", "border-left-color": "#bdc4c9" }} ><div>{this.props.translator['Drop files or click here to upload.']}</div></Dropzone> */}

                <Dropzone
                    className="text-c cursorPoint margin-t-5 margin-b-5 grayBg"
                    onDrop={(files) => this.onDrop(files)}
                    onDropRejected={(files) => this.onDropRejected(files)}
                    style={{
                    width: "100%",
                    height: "60",
                    "padding-top": "20px",
                    border: "1px solid black",
                    "border-top-color": "#bdc4c9",
                    "border-right-color": "#bdc4c9",
                    "border-bottom-color": "#bdc4c9",
                    "border-left-color": "#bdc4c9",
                    }}
                    maxSize={1048576 * attachmentSize}
                    accept={attachmentType}
                    validator={(file)=>{ return validateFile(file,this.props.translator);}}
                >
                    {({ getRootProps, getInputProps }) => (
                    <section className="form-control dropFileBtn">
                        <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {
                            this.props.translator[
                            "Drop files or click here to upload."
                            ]
                        }
                        {this.props.translator["(max size"]} {attachmentSize}
                        {this.props.translator["MB)"]}
                        <button onClick={(e) => e.preventDefault()}>{this.props.translator["Browse"]}</button>
                        </div>
                    </section>
                    )}
                </Dropzone>

                {/* <span className="f-size-13"><b>{this.props.translator['Supported file types:']}</b></span> <span className="f-size-12"> .doc, .docx, .xls, .xlsx, .pdf, .jpeg, .jpg, .png, .ico, .xml, .tif, .msg, .zip, .rar, .txt, .csv, .ldif, .log, .sql </span> */}
                {/* <span className="f-size-12" style={{"marginTop":"4px","display":"inline-block"}}><i>(<b>{this.props.translator['Supported file types:']}</b> {attachmentType})</i> </span> */}

                {this.state.filesPreview.length > 0 ?
                <div className="margin-t-15">
                    <div className="border-b padding-b-5 margin-b-10 black f-size-13 fw-400">{this.state.filesPreview.length > 0 ? this.props.translator['file attached'] : this.props.translator['file not attached']}</div>
                    <ListGroup bsClass="" className="">
                        {this.state.filesPreview}
                    </ListGroup>
                </div> : null }
		 </div>
        );
    }
}

const style = {
    margin: 15,
};
const mapStateToProps = ({ implememtationPlanList }) => {
    return {
        attachmentDetails: implememtationPlanList
    };
};
export default connect(mapStateToProps, { loadImplPlanList })(UploadScreen);
