
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import {
  Row,
  Col,
  PageHeader,
  Nav,
  NavItem,
  Button,
  ListGroup,
  ListGroupItem,
} from "react-bootstrap";
import { submit } from "redux-form";
import { connect } from "react-redux";
import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline} from "react-icons/io5";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';

class HeaderRight extends React.Component {
  static contextType = MyContext;
  componentDidMount() {
		this.context.keyDownHandler("","","",this.saveObjRef);
		this.context.newTabPrevent();

	}
	componentDidUpdate() {
		this.context.keyDownHandler("","","",this.saveObjRef);
    this.context.newTabPrevent();
	}

  render() {
    const tr = this.props.tr;
    return (
      <Link to="javascript:void(0)"
          className="myBt plus ctrlKeyPrevent"
          disabled={this.props.spcmReducer.spcmPostingService}
          onClick={() => {
            console.log("BALA");
            this.props.dispatch(submit("CreateSerForm"));
            this.props.boardPageInActive2(false);
            this.props.langActSecFun2(false);
            this.props.rightSidePageNoFun(0);
          }}
          title={tr["Save"]}
          ref={e => this.saveObjRef = e}
          >
            {
            this.props.spcmReducer.spcmPostingService
              ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/>
            }
        </Link>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    spcmReducer: state.spcmReducer,
  };
};

/* const mapStateToProps = ({spcmReducer,spcmStatusLoader}) => {
	return {
		spcmReducer,
		spcmStatusLoader
	}
}; */

export default connect(mapStateToProps, null)(HeaderRight);
