
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { TabContent, TabPane } from "react-bootstrap";

import Occurrences from "./occurence";
import GovAuditLogs from "./AuditLogs";
import ReasonComponent from "../common/reason";

const RightSide = ({
  meetingDefData,
  activeTabs,
  isAutorize,
  onSubmitReason,
  auditData,
  occurrencesdata,
  isFetching,
  auditDataRefetch,
}) => {
  return (
    <TabContent activeTab={activeTabs}>
      <TabPane tabId="1" active={activeTabs === "1"}>
        <GovAuditLogs
          auditDataRefetch={auditDataRefetch}
          meetingDefData={meetingDefData}
          isAutorize={isAutorize}
          auditData={auditData}
          isFetching={isFetching}
        />
      </TabPane>

      <TabPane tabId="3" active={activeTabs === "3"}>
        <Occurrences
          meetingDefData={meetingDefData}
          activeTabs={activeTabs}
          occurrencesdata={occurrencesdata}
        />
      </TabPane>
      <TabPane tabId="4" active={activeTabs === "6"}>
        <ReasonComponent module="Meeting" onSubmitReason={onSubmitReason} />
      </TabPane>
    </TabContent>
  );
};

export default RightSide;
