
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import ListGroup from "react-bootstrap/ListGroup";
import { useDispatch, useSelector } from "react-redux";
import {
  loadSlaCriteriaType,
  loadSlaAttributesList,
  loadSlaMilestoneList,
} from "_Actions/sla/lovAction";
import ListLoader from "_Commons/loaders/ListLoader";
import _ from "lodash";
import {PiPencilSimpleBold} from "react-icons/pi";
import {IoClose} from "react-icons/io5";
import { MyContext } from '_MyContext';

const XsmQuickViewShortDescription = (props) => {
  const dispatch = useDispatch();
  const [tabKey, setTabKey] = useState(1);

  const slaCriteriaType = useSelector((state) => state.slaCriteriaType);
  const slaAttributesList = useSelector((state) => state.slaAttributesList);
  const slaMilestoneList = useSelector((state) => state.slaMilestoneList);
  const sLaHolidayFetchingDetails = useSelector(
    (state) => state.sLaHolidayFetchingDetails.isFetching
  );
  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const editButton = useRef(null);

  useEffect(() => {
      context.keyDownHandler(closeButton.current,editButton.current,"","");
      context.newTabPrevent();
  }, []);

  const handleSelect = (tabKey) => {
    setTabKey(tabKey);
  };

  const loadAttributesAPI = () => {
    dispatch(loadSlaAttributesList(props.slaId));
    dispatch(loadSlaCriteriaType("SLADEF", "criteriatype"));
  };

  const loadMileStonesAPI = () => {
    dispatch(loadSlaMilestoneList(props.slaId));
  };

  const renderAttList = (attributeData) => {
    return slaCriteriaType.map((item) => {
      return attributeData[item.field1Key].map((subItem, itr) => {
        let attributes = subItem.attributeDisplayValue?.split(",");
        if (attributes) {
          let len = attributes.length;
          return (
            <tr>
              <td>{itr === 0 ? subItem.conditionName : ""}</td>
              <td>{subItem.attributeName}</td>
              <td>{subItem.operator}</td>
              <td>
                  {attributes.map((attr, index) => {
                    let attribute = attr.split("|");
                    let attLen=attribute.length
                    return attribute.map((value, i) => {
                      if (i == 1) {
                        return index == len-1 ? <div className="tbSerialTxt">Serial Number - {(value=="" || value==null) ? 'NA': value}</div> :(<div className="tbSerialTxt">Serial Number - {(value=="" || value==null) ? 'NA': value},</div>);
                      } else {
                        return  index == len-1 || attLen > 1 ? <div>{value}</div> : <div>{value},</div>;
                      }
                    })
                  })}
                </td>
              <td>{subItem.joinCondition}</td>
            </tr>
          );
        }        
      });
    });
  };

  const getTargetValue = (type, value) => {
    try {
      let typeTemp = type.split("_")[1].toLowerCase();
      if (typeTemp === "milestone") {
        return value;
      } else if (typeTemp === "breach") {
        return "Breach";
      }
    } catch (e) {
      return "";
    }
  };

  const renderMilestoneList = (milestoneData) => {
    return milestoneData.map((objjectData) => {
      return (
        <tr>
          <td>{objjectData.eventType}</td>
          <td>
            {getTargetValue(objjectData.eventType, objjectData.threshold)}
          </td>
          <td>{objjectData.audienceRoleName}</td>
          {objjectData.audienceRoleName.includes("Select Group") ? (
            <td>{objjectData.groupName}</td>
          ) : (
            <td></td>
          )}
        </tr>
      );
    });
  };

  //   useEffect(() => {
  //    // dispatch(loadSlaCriteriaType());
  //    // dispatch(loadSlaAttributesList());
  //    // dispatch(loadSlaMilestoneList());
  //   }, []);

  return (
    <div className="">
      <Row>
        <Col xs={12}>
          <div className="rPageHeadActBtn">
            <ul>
              <li>
                <NavLink to={"/editSlaDef/" + props.slaId} title={props.translator["Edit"]} ref={editButton} className="ctrlKeyPrevent">
                  <PiPencilSimpleBold/>
                </NavLink>
              </li>
              <li>
                <Link
                  title={props.translator["Minimize the details panel"]}
                  bsClass=""
                  to="javascript:void(0)"
                  onClick={() => {
                    props.showRightSide(false);
                  }}
                  ref={closeButton}
                  className="ctrlKeyPrevent"
                >
                  <IoClose/>
                </Link>
              </li>
            </ul>
          </div>
          <div className="rPageHeading">
            {/* {props.translator['Service Target']} {props.slaName} 
						for */}
            <div className="offNam margin-t-5 margin-b-5">{props.slaName}</div>
          </div>
        </Col>
      </Row>
      <div className="rBoxGap">
        <div className="rBoxTabs">
          <Tabs
            activeKey={tabKey}
            onSelect={handleSelect}
            id="tabId"
          >
            <Tab eventKey={1} title="SLA TARGET DETAILS">
              <ListGroup variant="flush" bsClass="">
                <ListGroup.Item
                  bsClass=""
                  className="rwTable"
                  style={{ "border-top": "none" }}
                >
                  <span className="rwKey">{props.translator["Name"]} </span>{" "}
                  <span className="rwVal">{props.slaName}</span>
                </ListGroup.Item>
                <ListGroup.Item bsClass="" className="rwTable">
                  <span className="rwKey">{props.translator["Company"]} </span>{" "}
                  <span className="rwVal">{props.companyFor}</span>
                </ListGroup.Item>
                <ListGroup.Item bsClass="" className="rwTable">
                  <span className="rwKey">
                    {props.translator["Target For"]}{" "}
                  </span>{" "}
                  <span className="rwVal">{props.targetFor}</span>
                </ListGroup.Item>
                <ListGroup.Item bsClass="" className="rwTable">
                  <span className="rwKey">{props.translator["Module"]} </span>{" "}
                  <span className="rwVal">{props.moduleName}</span>
                </ListGroup.Item>
                <ListGroup.Item bsClass="" className="rwTable">
                  <span className="rwKey">{props.translator["Status"]} </span>{" "}
                  <span className="rwVal">{props.status}</span>
                </ListGroup.Item>
                {props.retroactiveRequired != null ? (
                  <ListGroup.Item bsClass="" className="rwTable">
                    <span className="rwKey">
                      {props.translator["Retroactive Action Required"]}{" "}
                    </span>{" "}
                    <span className="rwVal">{props.retroactiveRequired}</span>
                  </ListGroup.Item>
                ) : (
                  ""
                )}
                <ListGroup.Item bsClass="" className="rwTable">
                  <span className="rwKey">{props.translator["SLA Type"]} </span>{" "}
                  <span className="rwVal">{props.slaType}</span>
                </ListGroup.Item>
                {/* <ListGroup.Item bsClass="" className="rwTable"><span className="rwKey">{props.translator['Target Time(Mins)']} -</span> <span className="rwVal">{props.targetTime}</span></ListGroup.Item> */}
              </ListGroup>
              <div className="margin-t-20 clearboth brkFix brkFixOrder">
                <Accordion
                  id="rightSidePanel6"
                  activeKey={props.activePanelKey}
                  onSelect={props.handlePanelClick}
                  className="brkFix brkFixOrder"
                >
                  <Accordion.Item eventKey="1" className="collapsed">
                    <Accordion.Header
                      onClick={() => {
                        loadAttributesAPI();
                      }}
                    >
                      {props.translator["Attributes"]}
                    </Accordion.Header>
                    <Accordion.Body>
                      {
                        <Table
                          responsive
                          striped
                          bordered
                          condensed
                          hover
                          className="f-size-13"
                        >
                          <thead>
                            <tr>
                              <th width="15%">{props.translator["Type"]}</th>
                              <th width="15%">
                                {props.translator["Attribute"]}
                              </th>
                              <th width="15%">
                                {props.translator["Operator"]}
                              </th>
                              <th width="40%">{props.translator["Value"]}</th>
                              <th width="15%">
                                {props.translator["Join Condition"]}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {slaCriteriaType.length == 0 ||
                            slaCriteriaType.length == undefined ||
                            _.isEmpty(slaAttributesList) ||
                            slaAttributesList == undefined ? (
                              <td colSpan="5" style={{ textAlign: "center" }}>
                                <ListLoader />
                              </td>
                            ) : slaAttributesList.Pause.length == 0 &&
                              slaAttributesList.Start.length == 0 &&
                              slaAttributesList.Stop.length == 0 ? (
                              <td colSpan="5" style={{ textAlign: "center" }}>
                                {props.translator["No Data Found"]}
                              </td>
                            ) : (
                              renderAttList(slaAttributesList)
                            )}
                          </tbody>
                        </Table>
                      }
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2" className="collapsed">
                    <Accordion.Header
                      onClick={() => {
                        loadMileStonesAPI();
                      }}
                    >
                      {props.translator["Milestone"]}
                    </Accordion.Header>
                    <Accordion.Body>
                      {
                        <Table
                          responsive
                          striped
                          bordered
                          condensed
                          hover
                          className="f-size-13 tableWithOutPointer"
                        >
                          <thead>
                            <tr>
                              <th>{props.translator["Event Type"]}</th>
                              <th>{props.translator["Target"]}</th>
                              <th>{props.translator["Recipients"]}</th>
                              <th>{props.translator["Group Name"]}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {slaMilestoneList.length == undefined ||
                            sLaHolidayFetchingDetails ? (
                              <tr><td colSpan="4" style={{ textAlign: "center" }}>
                                <ListLoader />
                              </td></tr>
                            ) : slaMilestoneList == "" ? (
                              <tr><td colSpan="4" style={{ textAlign: "center" }}>
                                {props.translator["No Data Found"]}
                              </td></tr>
                            ) : (
                              renderMilestoneList(slaMilestoneList)
                            )}
                          </tbody>
                        </Table>
                      }
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default XsmQuickViewShortDescription;
