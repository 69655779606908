
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
/* eslint-disable no-case-declarations */
import React from 'react';
import { connect } from 'react-redux';
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import {
	getAllRuleConfigurationsData,
	getCompanyData, getParameterValueData,
	getEventData, getModuleData, getAmsFilteredData,
	getAssignmentGroups, resetAttributeList
} from '../../../actions/amsActions/amsActions.js';
import ListLoader from "../../common/loaders/ListLoader.js";
import { bindActionCreators } from "redux";
import Pagination from "react-js-pagination";
import ReactDOM from "react-dom";
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import {IoSearch} from 'react-icons/io5';



class AmsHomeListView extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			companyName: '',
			moduleName: '',
			ruleName: '',
			weight: '',
			assignmentCompanyName: '',
			assignmentGroupName: '',
			serviceName: '',
			statusValue: '',
			nameSearchFilter: '',
			consumerCompanySearchFilter: '',
			serviceCiSearchFilter: '',
			supCompanySearchFilter: '',
			modifiedDateSearchFilter: '',
			isMultiSelectOpen: false,
			selectObjLeftPos: "0",
			//isOpen: true,
		};
		this.onRowSelect = this.onRowSelect.bind(this);
		this.onPageSizeChangeBreakFix = this.onPageSizeChangeBreakFix.bind(this);
		this.onPageChangeBreakFix = this.onPageChangeBreakFix.bind(this);
		this.handleAssignmentForChange = this.handleAssignmentForChange.bind(this);
		this.handleModuleChange = this.handleModuleChange.bind(this);
		this.handleStatusChange = this.handleStatusChange.bind(this);
		this.handleSupportGroupChange = this.handleSupportGroupChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.clickOutside = this.clickOutside.bind(this);
		this.handleCallback = this.handleCallback.bind(this);
	}

	componentDidMount() {
		this.props.getModuleData();
			this.props.getEventData('event');
			// this.props.getAssignmentGroups();
	}

	componentDidUpdate(prevProps) {
		document.addEventListener('mousedown', this.clickOutside);
		if (this.multiSelectModuleDropdownRef != undefined || this.multiSelectModuleDropdownRef != null) {
			ReactDOM.findDOMNode(this.multiSelectModuleDropdownRef).childNodes[0].className = "multiSelectRef";
			let myRef = this.multiSelectModuleDropdownRef;
      		if (this.props.isModuleOpen !== prevProps.isModuleOpen) {
        	let obj = ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1];
       		if (obj != undefined || obj != null) {
          	ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1].className = "multiSelectPopup";
            //ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1].style = "left:" + this.state.selectObjLeftPos + "px";
        	}
     	  }
		}
		if (this.multiSelectStatusDropdownRef != undefined || this.multiSelectStatusDropdownRef != null) {
			ReactDOM.findDOMNode(this.multiSelectStatusDropdownRef).childNodes[0].className = "multiSelectRef";
			let myRef = this.multiSelectStatusDropdownRef;
      		if (this.props.isStatusOpen !== prevProps.isStatusOpen) {
        	let obj = ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1];
       		if (obj != undefined || obj != null) {
          	ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1].className = "multiSelectPopup";
            //ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1].style = "left:" + this.state.selectObjLeftPos + "px";
        	}
     	  }
		}
		if (this.multiSelectAssignmentForDropdownRef != undefined || this.multiSelectAssignmentForDropdownRef != null) {
			ReactDOM.findDOMNode(this.multiSelectAssignmentForDropdownRef).childNodes[0].className = "multiSelectRef";
			let myRef = this.multiSelectAssignmentForDropdownRef;
      		if (this.props.isAssgnOpen !== prevProps.isAssgnOpen) {
        	let obj = ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1];
       		if (obj != undefined || obj != null) {
          	ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1].className = "multiSelectPopup";
            //ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1].style = "left:" + this.state.selectObjLeftPos + "px";
        	}
     	  }
		}
		if (this.multiSelectSupportGroupDropdownRef != undefined || this.multiSelectSupportGroupDropdownRef != null) {
			ReactDOM.findDOMNode(this.multiSelectSupportGroupDropdownRef).childNodes[0].className = "multiSelectRef";
			let myRef = this.multiSelectSupportGroupDropdownRef;
      		if (this.props.isSupportGrpOpen !== prevProps.isSupportGrpOpen) {
        	let obj = ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1];
       		if (obj != undefined || obj != null) {
          	ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1].className = "multiSelectPopup";
            //ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1].style = "left:" + this.state.selectObjLeftPos + "px";
        	}
     	  }
		}
	}
	
	// componentWillReceiveProps(nextProps) {
	// 	console.log('***********', {nextProps, props: this.props});
	// 	// allRuleConfigurationsData
	// 	if(this.props.allAssignmentGroups.length == 0) {
	// 		// this.props.getModuleData();
	// 		// this.props.getEventData('event');
	// 		// this.props.getAssignmentGroups();
	// 	}
	// }

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.clickOutside);
	}

	clickOutside(event) {
		const statusDiv = document.getElementById("statusDiv");
		const moduleDiv = document.getElementById("moduleDiv");
		const supportGroupDiv = document.getElementById("supportGroupDiv");
		const assignmentForDiv = document.getElementById("assignmentForDiv");

		if (this.multiSelectStatusDropdownRef != undefined || this.multiSelectStatusDropdownRef != null) {
			document.addEventListener("mousedown", (event) => {
				if (statusDiv.contains(event.target)) {
					this.props.allRuleConfigurationsData.data.length<=2?this.setState({ isMultiSelectOpen: true }):this.setState({ isMultiSelectOpen: false });
					this.props.showRightSide(false);
				} else if (moduleDiv.contains(event.target)) {
					this.setState({ isMultiSelectOpen: true });
					this.props.showRightSide(false);
				} else if (supportGroupDiv.contains(event.target)) {
					this.setState({ isMultiSelectOpen: true });
					this.props.showRightSide(false);
				} else if (assignmentForDiv.contains(event.target)) {
					this.setState({ isMultiSelectOpen: true });
					this.props.showRightSide(false);
				} else {
					this.setState({ isMultiSelectOpen: false });
				}
				this.props.multiSelectOpenFun("allFalse", false);
			});
		}

	}


	onPageSizeChangeBreakFix(e) {
		let size = parseInt(e.target.value, 10);
		this.setState({ table_size: size });
		this.props.updatePagination(0, size);
		let searchParams = {};
		searchParams.searchByList = this.props.searchKey;
		searchParams.multipleValueList = this.props.searchValue;
		searchParams.patternList = this.props.pattern;
		searchParams.currentPage = 0;
		searchParams.size = size;
		searchParams.orderBy = "desc";
		searchParams.sortBy = "modifiedOn";
		this.props.showRightSide(false);
		this.props.getAllRuleConfigurationsData(searchParams, this.props.moduleValue, this.props.assignmentForValue, this.props.supGroupValue, this.props.statusValue);
		this.props.multiSelectOpenFun("allFalse", false);
	}

	onPageChangeBreakFix(e) {
		this.props.updatePagination(e - 1, this.props.pageSize);
		let searchParams = {};
		searchParams.searchByList = this.props.searchKey;
		searchParams.multipleValueList = this.props.searchValue;
		searchParams.patternList = this.props.pattern;
		searchParams.currentPage = e - 1;
		searchParams.size = this.props.pageSize;
		searchParams.orderBy = "desc";
		searchParams.sortBy = "modifiedOn";
		this.props.showRightSide(false);
		this.props.getAllRuleConfigurationsData(searchParams, this.props.moduleValue, this.props.assignmentForValue, this.props.supGroupValue, this.props.statusValue);
	}

	onRowSelect(e, row, index) {
		this.props.getTaskDetails(row.ruleId, row.companyName, row.moduleName, row.ruleName, row.weight, row.assignmentCompanyName, row.assignmentGroupName, row.serviceName, row.ciServiceName, row.serviceBased, row.statusValue, row.eventName, row.ciClassName, row.ruleCondition, row.isContextual);
		this.props.resetAttributeList();
		this.props.handlePanelClick("0");
		this.props.showRightSide(true);
		this.props.showActiveRow(index);
	}

	renderTable(data) {
		return data.data.map((data, index) => {
			return (
				<tr className={this.props.isRightSideVisible && index == this.props.rowIndex ? "myActive" : ""} onClick={(e) => {this.onRowSelect(e, data, index);this.props.multiSelectOpenFun("allFalse", false)}}>
					<td title={data.ruleName}>{data.ruleName.slice(0, 50)}{data.ruleName.length > 50 ? "..." : ""}</td>
					<td>{data.companyName}</td>
					{/* <td title={data.ciServiceName}>{data.ciServiceName != null ? data.ciServiceName.slice(0, 70) : ''}{data.ciServiceName != null ? data.ciServiceName.length > 70 ? "..." : "" : ''}</td> */}
					<td>{data.moduleName}</td>
					<td>{data.eventName}</td>
					<td>{data.assignmentGroupName}</td>
					<td>{data.assignmentCompanyName}</td>
					<td>{data.statusValue}</td>
					<td>{data.modifiedOn}</td>
				</tr>
			);
		});
	}

	handleSearch(e, picker) {
		let searchByValue = [];
		let searchByVar = [];
		let pattern = [];
		this.props.isClearAllFilterFunc(true);
		this.props.showRightSide(false);
		let dateArr = [];

		if (this.props.nameSearchInput != "") {
			searchByValue.push(this.props.nameSearchInput.trim());
			searchByVar.push(this.state.nameSearchFilter);
			pattern.push("like");
		}
		if (this.props.consumerCompanySearchInput != "") {
			searchByValue.push(this.props.consumerCompanySearchInput.trim());
			searchByVar.push(this.state.consumerCompanySearchFilter);
			pattern.push("like");
		}
		if (this.props.supCompanySearchInput != "") {
			searchByValue.push(this.props.supCompanySearchInput.trim());
			searchByVar.push(this.state.supCompanySearchFilter);
			pattern.push("like");
		}
		if (this.props.serviceCiSearchInput != "") {
			searchByValue.push(this.props.serviceCiSearchInput.trim());
			searchByVar.push(this.state.serviceCiSearchFilter);
			pattern.push("like");
		}

		if (picker) {
			this.props.setRef(picker);
			dateArr.push(moment(picker.startDate).format("MM-DD-YYYY"), moment(picker.endDate).format("MM-DD-YYYY"));
			this.props.setStartAndEndDate(picker.startDate.format('MM/DD/YYYY'), picker.endDate.format('MM/DD/YYYY'));
			searchByValue.push(dateArr.join("&"));
			if(this.state.modifiedDateSearchFilter===''){
				searchByVar.push('modifiedOn');
			}else{
			    searchByVar.push(this.state.modifiedDateSearchFilter);
			}
			pattern.push("between");
		}

		if (picker == undefined && !_.isEmpty(this.props.startDate) && !_.isEmpty(this.props.endDate)) {
			dateArr.push(moment(this.props.startDate).format("MM-DD-YYYY"), moment(this.props.endDate).format("MM-DD-YYYY"));
			searchByValue.push(dateArr.join("&"));
			if(this.state.modifiedDateSearchFilter===''){
				searchByVar.push('modifiedOn');
			}else{
			    searchByVar.push(this.state.modifiedDateSearchFilter);
			}
			pattern.push("between");
		}

		let searchParams = {};
		searchParams.searchByList = searchByVar.join(",");
		searchParams.multipleValueList = searchByValue.join("|");
		searchParams.patternList = pattern.join(",");
		searchParams.currentPage = 0;
		searchParams.size = this.props.pageSize;
		searchParams.orderBy = "desc";
		searchParams.sortBy = "modifiedOn";
		this.props.showRightSide(false);
		this.props.setSearchParams(searchParams, searchByVar.join(","), searchByValue.join("|"), pattern.join(','), this.props.moduleValue, this.props.assignmentForValue, this.props.supGroupValue, this.props.statusValue);
		this.props.getAllRuleConfigurationsData(searchParams, this.props.moduleValue, this.props.assignmentForValue, this.props.supGroupValue, this.props.statusValue);
	}

	handleCallback(start, end) {
		this.setState({ modifiedDateSearchFilter: "modifiedOn" });
	}

	handleStatusChange(value) {
		let searchOnStatusArr = [];
		this.props.searchKey.length == 0 && this.props.searchValue.length == 0 ?
			this.props.moduleValue.length == 0 && this.props.assignmentForValue.length == 0 && this.props.supGroupValue.length == 0 ?
				value.length == 0 ?
					this.props.isClearAllFilterFunc(false) :
					this.props.isClearAllFilterFunc(true) :
				this.props.isClearAllFilterFunc(true) :
			this.props.isClearAllFilterFunc(true);
		this.props.showRightSide(false);
		this.props.setSelectedStatusValue(value);
		value.map((checkBoxValue) => {
			return (
				searchOnStatusArr.push(checkBoxValue.label)
			);
		}
		);
		let searchParams = {};
		searchParams.searchByList = this.props.searchKey;
		searchParams.multipleValueList = this.props.searchValue;
		searchParams.patternList = this.props.pattern;
		searchParams.currentPage = 0;
		searchParams.size = this.props.pageSize;
		searchParams.orderBy = "desc";
		searchParams.sortBy = "modifiedOn";
		this.props.setSearchParams(this.props.searchParamsValue, this.props.searchKey, this.props.searchValue, this.props.pattern, this.props.moduleValue, this.props.assignmentForValue, this.props.supGroupValue, searchOnStatusArr);
		this.props.getAllRuleConfigurationsData(searchParams, this.props.moduleValue, this.props.assignmentForValue, this.props.supGroupValue, searchOnStatusArr);
	}

	handleModuleChange(value) {
		let searchOnModuleArr = [];
		this.props.searchKey.length == 0 && this.props.searchValue.length == 0 ?
			this.props.assignmentForValue.length == 0 && this.props.statusValue.length == 0 && this.props.supGroupValue.length == 0 ?
				value.length == 0 ?
					this.props.isClearAllFilterFunc(false) :
					this.props.isClearAllFilterFunc(true) :
				this.props.isClearAllFilterFunc(true) :
			this.props.isClearAllFilterFunc(true);
		this.props.showRightSide(false);
		this.props.setSelectedModuleValue(value);
		value.map((checkBoxValue) => {
			return (
				searchOnModuleArr.push(checkBoxValue.label)
			);
		}
		);
		let searchParams = {};
		searchParams.searchByList = this.props.searchKey;
		searchParams.multipleValueList = this.props.searchValue;
		searchParams.patternList = this.props.pattern;
		searchParams.currentPage = 0;
		searchParams.size = this.props.pageSize;
		searchParams.orderBy = "desc";
		searchParams.sortBy = "modifiedOn";
		this.props.setSearchParams(this.props.searchParamsValue, this.props.searchKey, this.props.searchValue, this.props.pattern, searchOnModuleArr, this.props.assignmentForValue, this.props.supGroupValue, this.props.statusValue);
		this.props.getAllRuleConfigurationsData(searchParams, searchOnModuleArr, this.props.assignmentForValue, this.props.supGroupValue, this.props.statusValue);
	}

	handleAssignmentForChange(value) {
		let assignmentForArr = [];
		this.props.searchKey.length == 0 && this.props.searchValue.length == 0 ?
			this.props.moduleValue.length == 0 && this.props.statusValue.length == 0 && this.props.supGroupValue.length == 0 ?
				value.length == 0 ?
					this.props.isClearAllFilterFunc(false) :
					this.props.isClearAllFilterFunc(true) :
				this.props.isClearAllFilterFunc(true) :
			this.props.isClearAllFilterFunc(true);
		this.props.showRightSide(false);
		this.props.setSelectedAssignmentForValue(value);
		value.map((checkBoxValue) => {
			return (
				assignmentForArr.push(checkBoxValue.label)
			);
		}
		);
		let searchParams = {};
		searchParams.searchByList = this.props.searchKey;
		searchParams.multipleValueList = this.props.searchValue;
		searchParams.patternList = this.props.pattern;
		searchParams.currentPage = 0;
		searchParams.size = this.props.pageSize;
		searchParams.orderBy = "desc";
		searchParams.sortBy = "modifiedOn";
		this.props.setSearchParams(this.props.searchParamsValue, this.props.searchKey, this.props.searchValue, this.props.pattern, this.props.moduleValue, assignmentForArr, this.props.supGroupValue, this.props.statusValue);
		this.props.getAllRuleConfigurationsData(searchParams, this.props.moduleValue, assignmentForArr, this.props.supGroupValue, this.props.statusValue);
	}

	handleSupportGroupChange(value) {
		let supportGroupArr = [];
		this.props.searchKey.length == 0 && this.props.searchValue.length == 0 ?
			this.props.moduleValue.length == 0 && this.props.assignmentForValue.length == 0 && this.props.statusValue.length == 0 ?
				value.length == 0 ?
					this.props.isClearAllFilterFunc(false) :
					this.props.isClearAllFilterFunc(true) :
				this.props.isClearAllFilterFunc(true) :
			this.props.isClearAllFilterFunc(true);
		this.props.showRightSide(false);
		this.props.setSelectedSupGroupValue(value);
		value.map((checkBoxValue) => {
			return (
				supportGroupArr.push(checkBoxValue.label)
			);
		}
		);
		let searchParams = {};
		searchParams.searchByList = this.props.searchKey;
		searchParams.multipleValueList = this.props.searchValue;
		searchParams.patternList = this.props.pattern;
		searchParams.currentPage = 0;
		searchParams.size = this.props.pageSize;
		searchParams.orderBy = "desc";
		searchParams.sortBy = "modifiedOn";
		this.props.setSearchParams(this.props.searchParamsValue, this.props.searchKey, this.props.searchValue, this.props.pattern, this.props.moduleValue, this.props.assignmentForValue, supportGroupArr, this.props.statusValue);
		this.props.getAllRuleConfigurationsData(searchParams, this.props.moduleValue, this.props.assignmentForValue, supportGroupArr, this.props.statusValue);
	}

	handleChange(e, searchValue) {
		switch (searchValue) {
			case "ruleName":
				this.props.setNameSearchInput(e.target.value);
				this.setState({ nameSearchFilter: searchValue });
				break;
			case "companyName":
				this.props.setConsumerCompanySearchInput(e.target.value);
				this.setState({ consumerCompanySearchFilter: searchValue });
				break;
			case "serviceName":
				this.props.setServiceCiSearchInput(e.target.value);
				this.setState({ serviceCiSearchFilter: searchValue });
				break;
			case "assignmentCompanyName":
				this.props.setSupCompanySearchInput(e.target.value);
				this.setState({ supCompanySearchFilter: searchValue });
				break;
		}
	}



	render() {
		let caseResponsive = this.props.amsBoardShowLoader ? "overFlowClp" : this.state.isMultiSelectOpen ? "overFlowClp" : "table-responsive";

		let meta = !_.isEmpty(this.props.allRuleConfigurationsData) ? this.props.allRuleConfigurationsData.meta : {};
		let myStartDate = !_.isEmpty(this.props.startDate) ? moment(this.props.startDate).format("MM/DD/YYYY") : "";
		let myEndDate = !_.isEmpty(this.props.endDate) ? moment(this.props.endDate).format("MM/DD/YYYY") : "";
		let myNewDate = myStartDate == "" && myEndDate == "" ? "" : myStartDate + "-" + myEndDate;

		let moduleOptions = [];
		let assignmentForOptions = [];
		let supGroupOptions = [];
		let statusOptions = [{ label: "Active", value: "Active" }, { label: "InActive", value: "InActive" },{label: "Draft", value: "Draft"}];

		let modulemap = [];
		let assignmentFormap = [];
		let supportGroupmap = [];
		let panelColSize = this.props.panelResize;

		modulemap = new Map(Object.entries(this.props.moduleData));
		for (let [key, value] of modulemap) {
			let obj = {};
			obj.label = value.field1Key;
			obj.value = value.field1Key;
			moduleOptions.push(obj);
		}

		assignmentFormap = new Map(Object.entries(this.props.eventData));
		for (let [key, value] of assignmentFormap) {
			let obj = {};
			obj.label = value.displayName;
			obj.value = value.field1Key;
			assignmentForOptions.push(obj);
		}

		supportGroupmap = new Map(Object.entries(this.props.allAssignmentGroups));
		for (let [key, value] of supportGroupmap) {
			let obj = {};
			obj.label = value;
			obj.value = value;
			supGroupOptions.push(obj);
		}

		// statusmap = new Map(Object.entries(this.props.companyStatus));
		// for (let [key, value] of statusmap) {
		// 	console.log("statusmap", key + ' = ' + value, statusmap);
		// 	let obj = {};
		// 	obj.label = value.field1Key;
		// 	obj.value = value.field1Key;
		// 	statusOptions.push(obj);
		// }

		const tr = this.props.tr;
		return (
			<div>
				<div className={this.props.amsBoardShowLoader || this.props.allRuleConfigurationsData?.data?.length < 6 ? "respondv responDvMinH" : "respondv"}>
					<div className={"tableRgtBor " + caseResponsive}>
						<Table striped bordered condensed hover className="tableView nowrapWhright" style={{ "marginTop": "0px" }}>
							<thead>
								<tr>
									<th>
										<div className="sortParArr">{this.props.tr['Rule Name']}</div>
										<div className="colSearDv">
										<Form.Control
											className="colSearInp"
											type="text"
											value={this.props.nameSearchInput != "" ? this.props.nameSearchInput : ""}
											placeholder={this.props.tr["Search here"]}
											onKeyPress={(e) => {
												if (e.charCode == "13") {
												if (
													this.props.nameSearchInput != "" &&
													this.props.nameSearchInput.trim().length > 3
												)
													this.handleSearch();
												}
											}}
											onChange={(event) => {
												this.handleChange(event, "ruleName");
											}}
											onClick={() => this.props.multiSelectOpenFun("allFalse", false)}
											/>
											{this.props.nameSearchInput.trim().length > 3 ?
												<a title="search" className="faicn" href={void(0)} onClick={(event) => this.handleSearch()}>
													<IoSearch/>
												</a> : ""}
										</div>
									</th>
									<th>
										<div className="sortParArr">{this.props.tr['Consumer Company']}</div>
										<div className="colSearDv">
											<Form.Control className="colSearInp" type="text" value={this.props.consumerCompanySearchInput != "" ? this.props.consumerCompanySearchInput : ""} placeholder={this.props.tr["Search here"]}
												onKeyPress={(e) => { if (e.charCode == "13") {
													if(this.props.consumerCompanySearchInput.trim().length >= 3)
														this.handleSearch();
												} }}
												onChange={(event) => { this.handleChange(event, 'companyName'); }}			
											onClick={() => this.props.multiSelectOpenFun("allFalse", false)}/>
											{this.props.consumerCompanySearchInput.trim().length >= 3 ?
												<a title="search" className="faicn" href={void(0)} onClick={(event) => this.handleSearch()}>
													<IoSearch/>
												</a> : ""}
										</div>
									</th>
									<th>
										<div className="sortParArr">{this.props.tr['Module']}</div>
 										<div id="moduleDiv" className="colSearDv multiSelDv" ref={e => this.multiSelectModuleDropdownRef = e} onClick={() => this.props.multiSelectOpenFun("amsModule", true)}>
											<ReactMultiSelectCheckboxes placeholderButtonLabel={this.props.tr["Select"]} rightAligned={true} options={moduleOptions} value={this.props.selectedModuleValue} onChange={this.handleModuleChange} />
										</div>
									</th>
									<th>
										<div className="sortParArr">{this.props.tr['Assignment For']}</div>
										<div id="assignmentForDiv" className="colSearDv" ref={e => this.multiSelectAssignmentForDropdownRef = e} onClick={() => this.props.multiSelectOpenFun("amsAssgn", true)}>
											<ReactMultiSelectCheckboxes placeholderButtonLabel={this.props.tr["Select"]} rightAligned={true} options={assignmentForOptions} value={this.props.selectedAssignmentForValue} onChange={this.handleAssignmentForChange} />
										</div>
									</th>
									<th>
										<div className="sortParArr">{this.props.tr['Support Group']}</div>
										<div id="supportGroupDiv" className="colSearDv" ref={e => this.multiSelectSupportGroupDropdownRef = e} onClick={() => this.props.multiSelectOpenFun("amsSupportGrp", true)}>
											<ReactMultiSelectCheckboxes placeholderButtonLabel={this.props.tr["Select"]} rightAligned={true} options={supGroupOptions} value={this.props.selectedSupGroupValue} onChange={this.handleSupportGroupChange} />
										</div>
									</th>
									<th>
										<div className="sortParArr">{this.props.tr['Support Company']}</div>
										<div className="colSearDv">
										<Form.Control
											className="colSearInp"
											type="text"
											value={
												this.props.supCompanySearchInput != ""
												? this.props.supCompanySearchInput
												: ""
											}
											placeholder={this.props.tr["Search here"]}
											onKeyPress={(e) => {
												if (e.charCode == "13") {
												if (
													this.props.supCompanySearchInput != "" &&
													this.props.supCompanySearchInput.trim().length >= 3
												)
													this.handleSearch();
												}
											}}
											onChange={(event) => {
												this.handleChange(event, "assignmentCompanyName");
											}}
											onClick={() => this.props.multiSelectOpenFun("allFalse", false)}
											/>
											{this.props.supCompanySearchInput.trim().length >= 3 ?
												<a title="search" className="faicn" href={void(0)} onClick={(event) => this.handleSearch()}>
													<IoSearch/>
												</a> : ""}
										</div>
									</th>
									<th>
										<div className="sortParArr">{this.props.tr['Status']}</div>
										<div id="statusDiv" onclick={(event) => this.showCoords(event)} className="colSearDv" ref={e => this.multiSelectStatusDropdownRef = e} 
											onClick={() => this.props.multiSelectOpenFun("amsStatus", true)}>
											<ReactMultiSelectCheckboxes placeholderButtonLabel={this.props.tr["Select"]} rightAligned={true} options={statusOptions} value={this.props.selectedStatusValue} onChange={this.handleStatusChange} />
										</div>
									</th>
									<th>
										<div className="sortParArr">{this.props.tr['Modified Date']}</div>
										<div className="colSearDv" onClick={() => this.props.multiSelectOpenFun("allFalse", false)}>
											<DateRangePicker initialSettings={{
												autoUpdateInput: false,
												maxDate: moment(),
												autoApply: true
											}}
												onApply={(e, picker) => this.handleSearch(e, picker)} onCallback={this.handleCallback}>
												<input type="text" placeholder={this.props.tr["Search here"]} readOnly className="form-control" onPaste={this.handleKeyPress} onKeyDown={this.handleKeyPress} value={myNewDate} />
											</DateRangePicker>
										</div>
									</th>
								</tr>
							</thead>
							<tbody>
								{this.props.allRuleConfigurationsData == undefined || this.props.allRuleConfigurationsData.data == undefined || this.props.amsBoardShowLoader ?
									<tr>
										<td colSpan="9" style={{ "textAlign": "center" }}><ListLoader /></td>
									</tr> :
									this.props.allRuleConfigurationsData.data.length == 0 ?
										<tr>
											<td colSpan="9" style={{ "textAlign": "center" }}>{this.props.tr['There is no matching data available']}</td>
										</tr> :
										this.renderTable(this.props.allRuleConfigurationsData)
								}
							</tbody>
						</Table>
					</div>
					<div className="nBotPagina">
						<div className="nShow">
							<div className="margin-r-10">{this.props.tr['Show']}:</div>
							<Form.Select className="" value={this.props.pageSize} onChange={this.onPageSizeChangeBreakFix}>
								<option value="10">10</option>
								<option value="25">25</option>
								<option value="50">50</option>
							</Form.Select>
						</div>
						<nav aria-label='Pagination' className='display-inline-block'>
						<Pagination
							prevPageText='Prev'
							nextPageText='Next'
							firstPageText={<i className='glyphicon glyphicon-menu-left' />}
							lastPageText={<i className='glyphicon glyphicon-menu-right' />}
							activePage={meta ? meta.currentPage + 1 : 1}
							itemsCountPerPage={this.props.pageSize}
							totalItemsCount={meta ? meta.recordCount : 1}
							pageRangeDisplayed={panelColSize <= 42 ? 2 : 5}
							onChange={(e) => {this.onPageChangeBreakFix(e);this.props.multiSelectOpenFun("allFalse", false)}}
							/>
							</nav>
					</div>

				</div>

			</div>
		);
	}
}

const mapStateToProps = ({ allRuleConfigurationsData, amsBoardShowLoader, companyData,
	moduleData, eventData, parameterValueData, allAssignmentGroups }) => { 
	return {
		allRuleConfigurationsData, amsBoardShowLoader: amsBoardShowLoader.loading,
		companyData, moduleData, eventData,
		locationData: parameterValueData,
		allAssignmentGroups
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getCompanyData, getAllRuleConfigurationsData,
		getParameterValueData, getEventData, getModuleData, getAmsFilteredData, getAssignmentGroups, resetAttributeList
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AmsHomeListView);
