
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";

import Breadcrumbs from "../../common/header/breadcrumbs";
import Header from "./header";
import List from "./list";
import ShortDesc from "./shortDesc";
import { useGetAllRulesQuery } from "../../../reducers/rules";
import "_Css/common/_table.scss";

const RulesListComponent = () => {
  const [pageNum, setPageNum] = useState(0);
  const [pgSize, setPgSize] = useState(10);
  const [isBusy, setIsBusy] = useState(false);
  const [showFilterIcon, setShowFilterIcon] = useState(false);
  const [searchParams, setSearchParams] = useState({});
  const [isFilterCleared, setIsFilterCleared] = useState(false);

  const [isRightSideVisible, setRightSideVisible] = useState(false);
  let colWidth = "";
  isRightSideVisible ? (colWidth = 8) : (colWidth = 12);

  const showRightSideFun = (val) => {
    setRightSideVisible(val);
  };


  const {
    data: allRules,
    isFetching: rulesFetching,
    refetch: rulesRefetch,
  } = useGetAllRulesQuery(
    {
      queryString: `?pageNumber=${pageNum}&pageSize=${pgSize}`,
      params: searchParams,
    },
    { skip: Object.keys(searchParams).length === 0 }
  );
  const { currentPage, size, recordCount, totalPageCount } =
    allRules?.body?.metaInfo || {
      currentPage: 0,
      size: 0,
      recordCount: 0,
      totalPageCount: 0,
    };

  const startRecord = currentPage * size + 1;
  const endRecord = Math.min(startRecord - 1 + size, totalPageCount);


  return (
    <main>
      <Container fluid className="margin-b-15 margin-t-10">
        <Row>
          <Col md={8} xs={8}>          
            <Breadcrumbs activePageName={"Rules Listing"} />
          </Col>
          <Col md={4} xs={4}>
            {recordCount !== 0 && (
              <div className="tabDatacount">
                {startRecord}-{endRecord} of {totalPageCount}
              </div>
            )}
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Header
          rulesRefetch={rulesRefetch}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          setPageNum={setPageNum}
          setPgSize={setPgSize}
          setShowFilterIcon={setShowFilterIcon}
          showFilterIcon={showFilterIcon}
          setIsFilterCleared={setIsFilterCleared}
        />
        <Row>
          <Col md={colWidth} sm={colWidth} xs={12}>
            <List
              isRightSideVisible={isRightSideVisible}
              showRightSideFun={showRightSideFun}
              setPgSize={setPgSize}
              pgSize={pgSize}
              allRules={allRules}
              pageNum={pageNum}
              setPageNum={setPageNum}
              rulesFetching={rulesFetching}
              setSearchParams={setSearchParams}
              showFilterIcon={showFilterIcon}
              setIsFilterCleared={setIsFilterCleared}
              isFilterCleared={isFilterCleared}
              searchParams={searchParams}
            />
          </Col>
          {isRightSideVisible ? (
            <Col md={4} sm={4} xs={12}>
              <div className="stickyArea rBoxStyle">
                <ShortDesc showRightSideFun={showRightSideFun} />
              </div>
            </Col>
          ) : null}
        </Row>
      </Container>
    </main>
  );
};

export default RulesListComponent;
