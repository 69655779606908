
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Table from 'react-bootstrap/Table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { Scrollbars } from "react-custom-scrollbars";
// import OnClickOutside from 'react-onclickoutside';
// import RFReactConsumer from '../../changeManagement/changeCreate/searchConsumer.js';
import { Field, reduxForm, formValueSelector, change, SubmissionError } from 'redux-form';
import axios from 'axios';
import { connect } from 'react-redux';
import {ImInfo, ImSpinner6} from "react-icons/im";
import { GLOBAL } from "_Globals";
import { getInvestigationTeamList, deleteInvestigationTeamMember } from '_Actions/investigation/investigationAction.js';
import { getGroupData, getFormProblemTeam } from '_Actions/amsActions/amsActions.js';
import { _inputField, _textArea, _dropDown, _dateField,TypeaheadandDropdown } from '_Commons/ReduxFormFields/formFields';
import swal from 'sweetalert';
import XsmUserDetails from '_Commons/WorkItemBoard/xsmEditRequestedBy.js';
import AInvReactSelect from './AutoInvestigationTeam.js';
import {IoClose} from "react-icons/io5";
const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});


const validate = values => {
	let errors = {};
	if (!values.itGroup) {
		errors.itGroup = 'Select Group.';
	}
	if (!values.itMember || values.itMember==='0') {
		errors.itMember = 'Select Member.';
	}
	return errors;
};

let ProposeCriticalTab = class ProposeCriticalTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchResultsDiv: false,
      disabled: true,
      saveButtonStatus: false,
      showErrorMsg: "",
      selectedPIT: [],
      isSaveValue:false,
      isServiceDesk: false,
      PITLead: null,
      isDisabled: false,
      currentPITLead: null,
      resetTextField: false
    };
    this.saveTeamMemberDetails = this.saveTeamMemberDetails.bind(this);
    this.clearErrorMessage = this.clearErrorMessage.bind(this);
    this.checkLoginUser = this.checkLoginUser.bind(this);
    this.onCrossClickPIT = this.onCrossClickPIT.bind(this);
    this.onPITSelect = this.onPITSelect.bind(this);
    this.renderMemberList = this.renderMemberList.bind(this);
    this.changePITLead = this.changePITLead.bind(this);
    this.getCurrentPITLead = this.getCurrentPITLead.bind(this);
    this.setTextField = this.setTextField.bind(this);
  }

  componentWillMount() {
    this.props.getInvestigationTeamList(this.props.investigationId);
    // axios.get(GLOBAL.investigationTeamUrl).then((response) => {
    // 	consumerData = response.data;
    // 	for (let i = 0; i < consumerData.length; i++) {
    // 		myObj = { value: '', label: '' };
    // 		myObj.value = consumerData[i].userId;
    // 		myObj.label = consumerData[i].fullname +  "|" + consumerData[i].email + "|" + consumerData[i].userId + "|" +consumerData[i].companyId +"|" + consumerData[i].usergroupId + "|" + consumerData[i].usergroupName;
    // 		GLOBAL.consumerCompanybreakfix = consumerData[i].companyId;
    // 		consumerDataDetails.push(myObj);
    // 	}
    // 	consumerDataDetails = Array.from(new Set(consumerDataDetails));
    // });

    this.props.getFormProblemTeam(
      this.props.investigationItemDetails.consumerCompany
    );
    if(!(this.props.roleIdentification('17') || this.props.roleIdentification('16')||this.props.roleIdentification('53'))){
		  this.setState({isServiceDesk: this.props.roleIdentification('48')});
		}
    // if(this.props.currentPITLead){
    //   let arr = [];
    //   arr.push(this.props.currentPITLead);
    //   this.setState({PITLead: arr});
    // }

    this.getCurrentPITLead(this.props.investigationId);
  }
  setTextField(value){
    this.setState({resetTextField: value});
  }

  searchResults(event) {
    this.setState({ searchResultsDiv: true });
  }

  callbackFun(id) {
    this.setState({ searchResultsDiv: id });
  }

  setErrorColor() {
    console.log("error color");
  }

  clearErrorMessage() {
    this.setState({ showErrorMsg: "" });
  }

  // saveTeamMemberDetails() {
  // 	let ref = this;
  // 	ref.setState({ saveButtonStatus: true });
  // 	if (GLOBAL.breakFixConsumerID == '') {
  // 		alert('select member first.');
  // 		ref.setState({ saveButtonStatus: false });
  // 	} else {
  // 		axios.post(GLOBAL.investigationTeamPostUrlUrl, {
  // 			"investigationId": ref.props.investigationId,
  // 			"memberId": GLOBAL.breakFixConsumerID,
  // 			"memberName": GLOBAL.breakFixConsumerName,
  // 			"spGroupid":  GLOBAL.teamGroupId,
  // 			"spGroupname": GLOBAL.teamGroupName
  // 		})
  // 			.then(function (response) {
  // 				if (response.statusText == "Created") {
  // 					GLOBAL.breakFixConsumerID = '';
  // 					ref.setState({ saveButtonStatus: false });
  // 					ref.props.getInvestigationTeamList(ref.props.investigationId);
  // 				} else {
  // 					ref.setState({ saveButtonStatus: false });
  // 					alert('error saving team member');
  // 				}
  // 			})
  // 			.catch(function (error) {
  // 				ref.setState({ saveButtonStatus: false });
  // 				console.log(error);
  // 			});
  // 	}
  // 	ref.setState({ saveButtonStatus: false });
  // }

  saveTeamMemberDetails(values) {
    // if (this.props.user_id != this.props.investigationItemDetails.asgPmId) {
    // 	swal({
    // 		text: this.props.translator["Please assign the ticket to yourself before creating problem team"],
    // 		button: this.props.translator['OK'],
    // 	});
    // }
    //else {

    let ref = this;
    console.log("submit", values);
	if (this.state.selectedPIT.length == 0) {
	this.setState({
		showErrorMsg: "Select a Member",
	});
	return false;
	}
	let memberName = this.state.selectedPIT[0].label;
	const memberNameArr = memberName.split("|");
	memberName = memberNameArr[0];
	ref.setState({ saveButtonStatus: true, showErrorMsg: "" });
    values.itMember = this.state.selectedPIT[0].value;
    values.itMemberName = memberName;

    axios
      .post(GLOBAL.investigationTeamPostUrlUrl, {
        investigationId: ref.props.investigationId,
        memberId: values.itMember,
        memberName: values.itMemberName,
        spGroupid: "0",
        spGroupname: null,
      })
      .then(function (response) {
        // console.log('responseresponseresponseresponseresponse',response)
        if (response.status === 201) {
          ref.setState({ saveButtonStatus: false });
          ref.props.getInvestigationTeamList(ref.props.investigationId);
          ref.setState({ isSaveValue:true});
        } else {
          ref.setState({ saveButtonStatus: false });
          alert("error saving team member");
          ref.setState({ isSaveValue:false});
        }
        //ref.props.changeActionView("");
        ref.setState({ showErrorMsg: "" });
        ref.setState({ selectedPIT: [] });
        ref.setTextField(true);
      })
      .catch(function (error) {
        // console.log('errorerrorerrorerrorerror',error.toString())
        if (
          error &&
          error.toString() &&
          (error.toString() == "Error: Request failed with status code 400" || error.toString().indexOf('Request failed with status code 400') >= 0 )
        ) {
          ref.setState({
            showErrorMsg: "User is already a member of Investigation Team !",
          });
          // setTimeout(function(){
          // 	ref.setState({showErrorMsg: ''});
          // }, 5000);
        } else {
          ref.setState({ showErrorMsg: "Unable to process the request." });
          // setTimeout(function(){
          // 	ref.setState({showErrorMsg: ''});
          // }, 5000);
        }
        ref.setState({ saveButtonStatus: false });
        console.log(error);
      });
    //}
  }
  checkLoginUser(member) {
    if (this.props.user_id != this.props.investigationItemDetails.asgPmId) {
      swal({
        text: this.props.translator[
          "Please assign the ticket to yourself before deleting the team member"
        ],
        button: this.props.translator["OK"],
      });
    } else {
      this.props.deleteInvestigationTeamMember(
        member.investigationId,
        member.memberId
      )
      .then(res => {
        // console.log('res - ', res);
        let PITLeadArr = [];
        if(this.state.PITLead && Array.isArray(this.state.PITLead)){
          PITLeadArr = structuredClone(this.state.PITLead);
          let arr = PITLeadArr.filter(item => item != member.memberId);
          PITLeadArr = arr;
        }
        if(this.state.currentPITLead == member.memberId){
          this.setState({ currentPITLead: null });
        }
        setTimeout(() => {
          this.setState({ PITLead: PITLeadArr });
        }, 500);
      })
      .catch(err => {
        // console.log('err - ', err);
      });

    }
  }
  renderMemberList(members, investigationItemDetails) {
    const { investigationItemDetails : { status_id = "" } = {} } = this.props;

    if (!(members && Array.isArray(members) && members.length > 0)) {
      return (
        <tr>
          <td colSpan={3} className="text-c">
            No members in team yet.
          </td>
        </tr>
      );
    }
    // console.log('members - ', members);
    // console.log('this.state.PITLead - ', this.state.PITLead);
    const ref = this;
    return members.map((member, index) => {
      const tooltip = (
        <Popover className="userInfoPopup">
          <XsmUserDetails userId={member.memberId} translator={this.props.translator} isHeadingVisible={false} isPopup={true} />
          </Popover>
      );
      let isPITLead = false;
      if(this.state.PITLead && Array.isArray(this.state.PITLead)){
        isPITLead = (this.state.PITLead).find(item => item == member['memberId']);
        isPITLead = !!isPITLead;
      }
      return (
        <tr key={index}>
          {/* <td>
            <div className="text-c">
              <Radio name="radioGroup" />
            </div>
          </td> */}
          <td>
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="left"
              overlay={tooltip}
            >
              <Button className="userBtn" bsPrefix='default'>
                {member.memberName}{" "}
                <ImInfo/>
              </Button>
            </OverlayTrigger>
          </td>
          <td>
            <div className="text-c">
              {( status_id == '40' && !this.state.isServiceDesk && !this.props.disableFieldsForGuestRole) ? 
              <input type='radio' name='PIT_Lead' onChange={() => this.changePITLead(member)} checked={isPITLead} disabled={this.state.isDisabled} />
              : 
              (
                (this.state.currentPITLead == member['memberId']) ? 
                <input type='radio' name='PIT_Lead' checked={true} />
                : null
              )
              }
            </div>
          </td>
          <td>
            <div className="text-c">
              {(status_id == '40') && !this.state.isServiceDesk && !this.props.disableFieldsForGuestRole && <Button
                className="userBtn"
                bsPrefix='default'
                onClick={() => {
                  this.checkLoginUser(member);
                }}
                disabled={this.state.isDisabled}
              >
                <i className="fa fa-trash-o blue f-size-14"></i>
              </Button>}
            </div>
          </td>
          {/* {member.memberId == investigationItemDetails.asgPmId ? null : <td><div className="text-c"><i className="fa fa-trash-o blue f-size-14 cursorPoint" onClick = {()=>{this.props.deleteInvestigationTeamMember(member.investigationId,member.memberId);}}></i></div></td>} */}
        </tr>
      );
    });
  }

  onPITSelect(selectedOption) {
    console.log("onSelction====", selectedOption);
    if (
      !(selectedOption[0] && selectedOption[0].value && selectedOption[0].label)
    ) {
      this.setState({ showErrorMsg: "error" });
    } else {
      this.setState({ showErrorMsg: "" });
    }
    this.setState({ showErrorMsg: "", selectedPIT: selectedOption });
  }

  onCrossClickPIT() {
    this.setState({ showErrorMsg: "" });
    this.setState({ selectedPIT: [] });
  }

  changePITLead(memberObj){
    try{
      // console.log('memberObj - ', memberObj);
      let PITLeadArr = [];
      let isTeamLead = true;
      // if(this.state.PITLead && Array.isArray(this.state.PITLead)){
      //   PITLeadArr = structuredClone(this.state.PITLead);
      //   let found = PITLeadArr.find(item => item == memberObj['memberId']);
      //   if(found){
      //     isTeamLead = false;
      //     let arr = PITLeadArr.filter(item => item != memberObj['memberId']);
      //     PITLeadArr = arr;
      //   }
      //   else{
      //     isTeamLead = true;
      //     PITLeadArr.push(memberObj['memberId']);
      //   }
      // }
      // else{
      //   isTeamLead = true;
      //   PITLeadArr.push(memberObj['memberId']);
      // }
      PITLeadArr.push(memberObj['memberId']);
      this.setState({PITLead: PITLeadArr});
      const { teamId = '', investigationId = '' } = memberObj;
      let str={};
      this.setState({isDisabled: true});
      api.patch(`/serviceManagement/rest/investigationTeamPITLead?teamId=${teamId}&investigationId=${investigationId}&isTeamLead=${isTeamLead}`,{headers:{'query':str}}).then((response)=>{
        // console.log('response - ', response);
        if(response && response.data){
          swal({
            text: response.data,
            button: this.props.translator["OK"],
          });
        }
        this.setState({isDisabled: false});
      }).catch(err => {
        // console.log('err - ', err);
        this.setState({isDisabled: false});
      });
    }catch(e){
      console.error(e);
    }
  }

  getCurrentPITLead(investigationId){
		try{
			let str={};
			api.get(`/serviceManagement/rest/investigationTeamAction?investigationId=${investigationId}`,{headers:{'query':str}}).then((response)=>{
			//   console.log('response getCurrentPITLead - ', response);
			  if(response && response.data && Array.isArray(response.data)){
				let find = (response.data).find(item => item['isTeamLead'] == true);
          if(find){
            let arr = [];
            arr.push(find['memberId']);
            this.setState({PITLead: arr, currentPITLead: find['memberId']});
          }
			  }
			}).catch(err => {
			  // console.log('err - ', err);
			});
		  }catch(e){
			console.error(e);
		  }
	}

  render() {
    // console.log('*****', {state: this.state, props: this.props});
    const { handleSubmit, pristine, reset, submitting, investigationItemDetails : { status_id = "" } = {} } = this.props;
    // console.log('*****', this.props);
    // console.log("this.state.selectedPIT", this.state.selectedPIT);
    // console.log("this.state.saveButtonStatus", this.state.saveButtonStatus);
    let pitOptions = [];
    let problemTeamData = this.props.problemTeam;
    problemTeamData.map((subItem) => {
      let obj = {
        value:subItem.field1Value,
        deptname:subItem.field2Key,
        email:subItem.field2Value,
        label:subItem.field1Key
		    //label:subItem.field1Key + "|" + subItem.field2Value + "|" + subItem.field2Key
      };
      pitOptions.push(obj);
    });

    return (
      <div className="hambTab userInfoPopupParent">
        <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
					<ul>
						<li>
						<button
                type='button'
							title="Minimize the right panel"
							bsClass=""
							bsStyle=""
							className="closerightPanelBtn"
							onClick={() => {
							this.props.rightEditPanel(false);
							}}
						>
							<IoClose />
						</button>
						</li>
					</ul>
				</div>
        <div className="rPageHeading">
          <div className="offNam margin-t-5 margin-b-5">
            {this.props.translator["Investigation Team"]}
          </div>
        </div>
        <div className="rBoxGap">
          <span style={{ color: "red" }}>{this.state.showErrorMsg}</span>
          {/* <Form.Group >
					<Form.Label>{this.props.translator['Groups']} </Form.Label>
					<Field name="itGroup" component={_dropDown} options={this.props.groupData} className="form-control" onChange={(event)=>{ event.target.value != '' ? this.props.getFormProblemTeam() :''; this.props.dispatch(change("proposeCriticalTabForm", "itGroupName", event.target[event.target.selectedIndex].text)); this.clearErrorMessage()} }/>
				</Form.Group> */}

          {(status_id == '40') && !this.state.isServiceDesk && !this.props.disableFieldsForGuestRole && <Form.Group>
            <Form.Label>
              {this.props.translator["Problem Investigation Team"]}{" "}
            </Form.Label>
            {/* <Field name="itMember" component={_dropDown} options={this.props.problemTeam} className="form-control" onChange={(event)=>{ this.props.dispatch(change("proposeCriticalTabForm", "itMemberName", event.target[event.target.selectedIndex].text)); this.clearErrorMessage()} }/> */}
            <Field
              name="itMember"
              component={AInvReactSelect}
              className="form-control"
              options={pitOptions}
              onSelection={this.onPITSelect}
              selectedOptions={this.state.selectedPIT}
              savedValue={this.state.isSaveValue}
              resetTextField={this.state.resetTextField}
              setTextField={this.setTextField}
              onKeyPress={(e) => {e.key === "Enter" && e.preventDefault();}}
              //onCrossClick={this.onCrossClickPIT}
            />
          </Form.Group>}

          {/* <Form.Group >
					<Form.Label>{this.props.translator['IT Members']}</Form.Label>
					<div className="position-re">
						<Field name="itMember"
							options={consumerDataDetails}
							component={RFReactConsumer}
							renderFor="investigationTeam"
							setErrorColor={this.setErrorColor}
						>
						</Field>
					</div>
				</Form.Group> */}

          {/* <ButtonToolbar className="margin-t-15 margin-b-20"><Button bsStyle="primary" disabled = {this.state.saveButtonStatus} onClick={() => { this.saveTeamMemberDetails();}}>{this.props.translator['Add']}</Button></ButtonToolbar> */}
          {/* <ButtonToolbar className="margin-t-15 margin-b-20">
				<Button bsStyle="primary" disabled = {this.state.saveButtonStatus}
				onClick={handleSubmit(this.saveTeamMemberDetails)}>{this.props.translator['Add']}
				</Button>
				</ButtonToolbar> */}

          {(status_id == '40') && !this.state.isServiceDesk && !this.props.disableFieldsForGuestRole && <ButtonToolbar className="black margin-t-10">
            <Button
              className="rgSidrkBtn smallBtn"
              disabled={this.state.saveButtonStatus}
              onClick={this.saveTeamMemberDetails}
            >
              {this.state.saveButtonStatus ? (
                <ImSpinner6 className="icn-spinner"/>
              ) : null}
              {this.props.translator["Add"]}
            </Button>
          </ButtonToolbar>}

          <Table
            responsive
            striped
            bordered
            condensed
            hover
            className="f-size-13 maintInfo"
          >
            <thead>
              <tr>
                {/* <th width="10%">Lead</th> */}
                <th width="70%">{this.props.translator["Member"]}</th>
                <th width="20%">{this.props.translator["PIT Lead"] || 'PIT Lead'}</th>
                <th width="10%">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {this.renderMemberList(
                this.props.investigationTeamList,
                this.props.investigationItemDetails
              )}
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
};

ProposeCriticalTab = reduxForm({
	form: 'proposeCriticalTabForm',
	validate,
	enableReinitialize: true
})(ProposeCriticalTab);

const mapStateToProps = ({ investigationTeamList,investigationItemDetails,groupData,individualData,problemTeam }) => {
	console.log("investigationItemDetails ::>",investigationItemDetails)
	console.log("investigationTeamList ",investigationTeamList)
	return {
		investigationTeamList,
		investigationItemDetails:(investigationItemDetails && investigationItemDetails.data ? investigationItemDetails.data[0]:investigationItemDetails) ,

		groupData,
		individualData,
		problemTeam
	};
};

export default connect(mapStateToProps, { getInvestigationTeamList, deleteInvestigationTeamMember, getGroupData, getFormProblemTeam })(ProposeCriticalTab);
