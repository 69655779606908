import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Field, change } from "redux-form";
import axios from "axios";
import { useDispatch } from "react-redux";
import { GLOBAL } from "../../../Globals";

export const CharacterizatinForm = ({ heading, subHeading, opsValueKey, opsLabelKey, module, companyId, formName, initialValues, formType = "create" }) => {
  const [categoryData, setCategoryData] = useState({});
  const [dropdownOptions, setDropdownOptions] = useState({});
  const [visibleDropdowns, setVisibleDropdowns] = useState(1);
  const [showDropDowns, setShowDropDowns] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (companyId) {
      const str = JSON.stringify({ companyId, module: 'Foundation', propertyId: '35' });
      axios
        .get('/api/getAdminPreferences/', { headers: { 'query': str } })
        .then((res) => {
          setShowDropDowns(
            typeof res.data[0]?.value === "string" ? parseInt(res.data[0]?.value, 10) : res.data[0]?.value
          );
        });
        if(formType == "create"){
          dispatch(change(formName, `${opsValueKey}1`, null));
        }
    }
  }, [companyId]);

  const fetchDropdownOptions = async (idx, parent = '') => {
    try {
      if(parent != "0"){
        const response = await axios.get(`/api/company_menulist?companyId=${companyId}&module=${module}&field=${subHeading + " " + idx}&language=en&status=1&parent=${parent}`);
        setDropdownOptions((prevOptions) => ({
          ...prevOptions,
          [`${opsValueKey}${idx}`]: response.data,
        }));
      }
    } catch (error) {
      console.error(`Error fetching options for dropdown ${idx}:`, error);
    }
  };

  useEffect(() => {
    if (!companyId) return;

    if (formType === "edit" && initialValues) {
      // In edit mode, set up the dropdowns based on initialValues
      setVisibleDropdowns(Object.keys(initialValues).length / 2);
      setCategoryData(initialValues);
      setShowDropDowns(Object.keys(initialValues).length / 2);

      // Fetch options for each dropdown based on initial values
      for (let idx = 1; idx <= showDropDowns; idx++) {
        if (!dropdownOptions[`${opsValueKey}${idx}`]) {
          fetchDropdownOptions(idx, initialValues[`${opsValueKey}${idx - 1}`] || '');
        }
      }
    } else if (formType === "create") {
      // In create mode, initialize only the first dropdown
      setVisibleDropdowns(1);
      fetchDropdownOptions(1);
    }
  }, [companyId, initialValues, formType]);

  const handleCategoryChange = (e, optionFieldName, idx) => {
    const { name, value } = e.target;
    setCategoryData((prevData) => {
      const updatedData = { 
        ...prevData, 
        [name]: value, 
        [optionFieldName]: e.target.options[e.target.selectedIndex].text 
      };

        GLOBAL.categorizationErrors = {...GLOBAL.categorizationErrors, [name]: (value && value != "0") ? "" : "error"}

        // Clear category data for subsequent dropdowns
      for (let i = idx + 1; i <= visibleDropdowns; i++) {
        delete updatedData[`${opsValueKey}${i}`];
        delete updatedData[`${opsLabelKey}${i}`];
      }
      
      return updatedData;
    });

    setDropdownOptions((prevOptions) => {
      const updatedOptions = { ...prevOptions };
  
      // Clear dropdown options for subsequent dropdowns
      for (let i = idx + 1; i <= visibleDropdowns; i++) {
        updatedOptions[`${opsValueKey}${i}`] = [];
      }
  
      return updatedOptions;
    });
  
    // Redux form state updates
    dispatch(change(formName, name, value));
    dispatch(change(formName, optionFieldName, e.target.options[e.target.selectedIndex].text));
  
    for (let i = idx + 1; i <= visibleDropdowns; i++) {
      dispatch(change(formName, `${opsValueKey}${i}`, null));
      dispatch(change(formName, `${opsLabelKey}${i}`, null));
    }
  
    // Fetch options for the next dropdown if a value is selected
    if (value && idx < showDropDowns) {
      fetchDropdownOptions(idx + 1, value);
      if (idx === visibleDropdowns) {
        setVisibleDropdowns((prev) => Math.min(prev + 1, showDropDowns));
      }
    }
  
    if (visibleDropdowns <= showDropDowns && idx < showDropDowns && formType === "create") {
      dispatch(change(formName, `${opsValueKey}${idx + 1}`, null));
    }
  };

  return (
    showDropDowns > 0 && companyId && <div>
      <div className="margin-t-5 padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
        <div className="bgGray padding-5">
          <span className="rStar"></span>
          {heading}
        </div>
        <Row md={3}>
          {Array.from({ length: visibleDropdowns }, (_, index) => index + 1).map((idx) => (
            <Col key={idx}>
              <Form.Group className="form-group">
                <Form.Label>
                  <span className="rStar"></span>
                  {subHeading + " " + idx}
                </Form.Label>
                <Field
                  name={`${opsValueKey}${idx}`}
                  component="select"
                  type="select"
                  className={`form-control ${GLOBAL.categorizationErrors[`${opsValueKey}${idx}`] ? "error" : ""}`}
                  value={categoryData[`${opsValueKey}${idx}`] || ''}
                  onChange={(e) => handleCategoryChange(e, `${opsLabelKey}${idx}`, idx)}
                >
                  <option value="0">Select</option>
                  {(dropdownOptions?.[`${opsValueKey}${idx}`] || []).map((option, optionIdx) => (
                    <option key={optionIdx} value={option?.field1Value}>
                      {option?.field1Key}
                    </option>
                  ))}
                </Field>
              </Form.Group>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};
