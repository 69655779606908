
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import AutoSuggest from "react-autosuggest";
import { GLOBAL } from "_Globals";
let selectOptions = [];
const getSuggestions = (value, options) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  if (inputLength > 2) {
    selectOptions = options;
  }
  return inputLength <= 2
    ? []
    : selectOptions.filter(
        (option) => option.label.toLowerCase().indexOf(inputValue) > -1
      );
};

const getSuggestionValue = (suggestion) =>
  suggestion.label.indexOf == -1
    ? ""
    : suggestion.label.substring(0, suggestion.label.indexOf("|"));

function renderSuggestion(suggestion) {
  // let suggestionlabel =[];
  // suggestionlabel= suggestion.label.split("|");

  let offeringNameKey = "";
  let offeringNameStr = "";
  offeringNameStr =
    suggestion.offeringname != null && suggestion.offeringname != ""
      ? suggestion.offeringname
      : "";
  offeringNameKey =
    offeringNameStr != ""
      ? offeringNameStr
          .replace(
            /(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/gi,
            ""
          )
          .trim()
      : "";
  offeringNameKey =
    offeringNameKey != "" ? offeringNameKey.replace(/\s+/g, " ") : "";

  let categoryNamekey = "";
  let categoryNameStr = "";
  categoryNameStr =
    suggestion.categoryname != null && suggestion.categoryname != ""
      ? suggestion.categoryname
      : "";
  categoryNamekey =
    categoryNameStr != ""
      ? categoryNameStr
          .replace(
            /(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/gi,
            ""
          )
          .trim()
      : "";
  categoryNamekey =
    categoryNamekey != "" ? categoryNamekey.replace(/\s+/g, " ") : "";

  let subCategoryNameKey = "";
  let subCategoryNameStr = "";
  subCategoryNameStr =
    suggestion.subCategoryname != null && suggestion.subCategoryname != ""
      ? suggestion.subCategoryname
      : "";
  subCategoryNameKey =
    subCategoryNameStr != ""
      ? subCategoryNameStr
          .replace(
            /(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/gi,
            ""
          )
          .trim()
      : "";
  subCategoryNameKey =
    subCategoryNameKey != "" ? subCategoryNameKey.replace(/\s+/g, " ") : "";

  let consumerCompanyNameKey = "";
  let consumerCompanyNameStr = "";
  consumerCompanyNameStr =
    suggestion.consumercompanyname != null &&
    suggestion.consumercompanyname != ""
      ? suggestion.consumercompanyname
      : "";
  consumerCompanyNameKey =
    consumerCompanyNameStr != ""
      ? consumerCompanyNameStr
          .replace(
            /(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/gi,
            ""
          )
          .trim()
      : "";
  consumerCompanyNameKey =
    consumerCompanyNameKey != ""
      ? consumerCompanyNameKey.replace(/\s+/g, " ")
      : "";

  return (
    <Button
      bsPrefix=" "
      className="suggBtn"
      onClick={() => {
        GLOBAL.breakFixServiceID = suggestion.value;
        // GLOBAL.breakFixServiceName=suggestionlabel[0];
        // GLOBAL.breakFixServiceName=suggestion.label;
        GLOBAL.breakFixServiceName = suggestion.offeringname;
        GLOBAL.breakFixCompanyName = suggestion.companyname;
        GLOBAL.consumerCompanybreakfix = suggestion.companyname;
        GLOBAL.servicecatid = suggestion.categoryid;
        GLOBAL.servicecatname = suggestion.categoryname;
        GLOBAL.consumerCompanyName = suggestion.consumercompanyname;
        GLOBAL.consumerCompanyId = suggestion.companyname;
        GLOBAL.changeValue = true;
      }}
    >
      {/* {suggestion.label} */}
      {offeringNameKey.slice(0, 70)}
      {offeringNameKey.length > 70 ? "..." : ""}
      <div>
        {categoryNamekey.slice(0, 30)}
        {categoryNamekey.length > 30 ? "..." : ""}
        {" - "}
        {subCategoryNameKey.slice(0, 30)}
        {subCategoryNameKey.length > 30 ? "..." : ""}
        {" - "}
        {consumerCompanyNameKey.slice(0, 30)}
        {consumerCompanyNameKey.length > 30 ? "..." : ""}
      </div>
    </Button>
  );
}

class RFReactSelect extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onSuggestionsFetchRequested =
      this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested =
      this.onSuggestionsClearRequested.bind(this);
    this.onServiceNameSelected = this.onServiceNameSelected.bind(this);

    this.state = {
      value: "",
      suggestions: [],
    };
    this.validateField = this.validateField.bind(this);
  }
  onChange(event, { newValue }) {
    this.setState({
      value: newValue,
    });
    if(typeof this.props.onType!=='undefined' && newValue!==''){
      if(newValue.localeCompare(this.state.value)){
        this.props.onType(false);
      }
    }
    if (newValue === "") {
      this.props.isServiceFun && this.props.isServiceFun(true);
      if (typeof this.props.clearGroupandIndividual !== "undefined") {
        this.props.clearGroupandIndividual();
      }
      if(typeof this.props.showServiceInfoIcon !== 'undefined'){
        this.props.showServiceInfoIcon(false);
      }
      if(typeof this.props.showServiceDetails !== 'undefined'){
        this.props.showServiceDetails(false);
      }
    } else {
      this.props.isServiceFun && this.props.isServiceFun(false);
    }
  }
  onSuggestionsFetchRequested({ value }) {
    if(this.state.value.length===0 && typeof this.props.onType!=='undefined'){
      this.props.onType(false);
    }
    this.setState({
      suggestions: getSuggestions(value, this.props.options),
    });
  }
  onSuggestionsClearRequested() {
    this.setState({
      suggestions: [],
    });
    if (this.state.value == "") {
      GLOBAL.breakFixServiceID = "";
      GLOBAL.changeValue = false;
      if (typeof this.props.checkforAMSattributes !== "undefined") {
        this.props.checkforAMSattributes("", "");
      }
    }

  }
  //   componentWillReceiveProps(nextProps)
  //   {
  //     console.log("COMPONENTWILLRECI123444444444444444",nextProps,this.props);
  //     if(nextProps.onCreateUsingSelect!=this.props.onCreateUsingSelect){
  //     this.setState({
  //       value:''
  //     })
  //     GLOBAL.breakFixServiceID="";
  //     GLOBAL.breakFixServiceName="";
  //   }
  // }
  onServiceNameSelected(event, { suggestion }) {

    this.props.onServiceNameChange(
      GLOBAL.breakFixServiceID,
      suggestion.companyname
    );
    //alert("M12");
    // if (GLOBAL.breakFixServiceID != "") {
    //   //alert("M12");
    //   GLOBAL.breakFixServiceID = ""; //it is working
    // }
  }
  validateField() {   
    if (this.props.onCreateUsingSelect == "impactedCI") {
    } else {
      if (GLOBAL.breakFixServiceID == "") {
        if (this.props.setErrorServiceColor != undefined)
          this.props.setErrorServiceColor("error");
      } else {
        if (this.props.setErrorServiceColor != undefined)
          this.props.setErrorServiceColor("");
      }
    }
  }
  componentWillReceiveProps(nextProps) {

    if (nextProps.onCreateUsingSelect != this.props.onCreateUsingSelect) {


      this.setState({
        value: "",
      });
      GLOBAL.breakFixServiceID = "";
      GLOBAL.breakFixServiceName = "";
    }
    if (this.props.isInputClear == false && nextProps.isInputClear == true) {
      this.setState({
        value: "",
      });
    }
  }

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder:
        this.props.placeHolder != "" && this.props.placeHolder != undefined
          ? this.props.placeHolder
          : "Enter Service Name",
      className: "form-control " + (this.props.errorColor || ""),
      value,
      onChange: this.onChange,
      onBlur: this.validateField,
      disabled: this.props.disabled,
    };
  
    return (
      <AutoSuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={this.onServiceNameSelected}
      />
    );
  }
}
export default RFReactSelect;
