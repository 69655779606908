/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const useInfiniteScroll = (referenceElement, isCatalogPage = false) => {
  const [isFetching, setIsFetching] = useState(false);
  let headerHeight = 0;

  const advertisements = useSelector((state) => state.advertisement);
  const bulletins = useSelector((state) => state.bulletin);
  const homeElement = document.querySelector(".home");

  useEffect(() => {
    if (
      advertisements &&
      advertisements.length > 0 &&
      bulletins &&
      bulletins.length > 0
    ) {
      headerHeight = 270;
    } else if (advertisements && advertisements.length > 0) {
      headerHeight = 240;
    } else if (bulletins && bulletins.length > 0) {
      headerHeight = 150;
    } else {
      headerHeight = 160;
    }
    
    if (homeElement)
      homeElement.addEventListener("scroll", handleScroll, false);
    return () => homeElement?.removeEventListener("scroll", handleScroll, false);
  }, [referenceElement.current, homeElement]);

  function handleScroll() {
    console.log('Scrolling')
    let totalHeight = referenceElement.current.offsetHeight + headerHeight;
    const homeElement = document.querySelector(".home");
    if (
      Math.ceil(window.innerHeight + homeElement.scrollTop) <
        Math.round(totalHeight) ||
      isFetching
    )
      return;
    setIsFetching(true);
  }

  return [isFetching, setIsFetching];
};

export default useInfiniteScroll;
