
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import HeaderSec from "./header";
import TOAForm from "./form";
import PindCategories from "../../../common/header/pind-categories";
import Breadcrumbs from "../../../common/header/breadcrumbs";
import AuditLog from "./AuditLog";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import "_Css/form/_form.scss";

const TOAIndex = () => {
  const [statusErr, setStatusErr] = useState("");
  const [methodErr, setMethodErr] = useState("");
  const [apiHostErr, setAPIHostErr] = useState("");
  const [attributeKeyErr, setAttributeKeyErr] = useState("");
  const [attributeLengthErr, setAttributeLengthErr] = useState("");
  const [attributeValueTypeErr, setAttributeValueTypeErr] = useState("");
  const [endPointErr, setEndpointErr] = useState("");
  const [headersErr, setHeadersErr] = useState("");
  const [bindAttriErr, setBindAttriErr] = useState("");
  const [foreignkeyErr, setForeignKeyErr] = useState("");
  const [referencetableErr, setReferenceTableErr] = useState("");
  const [referencecolumnErr, setReferenceColumnErr] = useState("");
  const [referencemsErr, setReferenceMSErr] = useState("");
  const tr = useSelector((state) => state.spcmReducer.tr);
  const [isActive, setIsActive] = useState(false);
  const [view, setView] = useState("activity");
  const [isRightEditPanel, setisRightEditPanel] = useState(true);

  const setActiveHandler = (val) => {
    setIsActive(val);
  };

  const rightEditPanel = (value) => {
    setisRightEditPanel(value);
  };
  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Breadcrumbs
          activePageName={tr["Edit"]}
          parentPageurl="/toa"
          parentPageName="Data Dictionary"
        />
      </Container>

      <Container fluid>
        <HeaderSec
          isActive={isActive}
          setStatusErr={setStatusErr}
          setMethodErr={setMethodErr}
          setAPIHostErr={setAPIHostErr}
          setAttributeKeyErr={setAttributeKeyErr}
          setAttributeLengthErr={setAttributeLengthErr}
          setAttributeValueTypeErr={setAttributeValueTypeErr}
          setEndpointErr={setEndpointErr}
          setHeadersErr={setHeadersErr}
          setBindAttriErr={setBindAttriErr}
          setForeignKeyErr={setForeignKeyErr}
          setReferenceTableErr={setReferenceTableErr}
          setReferenceColumnErr={setReferenceColumnErr}
          setReferenceMSErr={setReferenceMSErr}
          view={view}
        />
        <PanelGroup direction="horizontal">
          <Panel
            id="sidebar"
            minSize={33}
            order={1}
            defaultSize={isRightEditPanel ? 67 : 100}
            className={isRightEditPanel ? "isShowLeftPanel" : ""}
          >
            <TOAForm
              statusErr={statusErr}
              setStatusErr={setStatusErr}
              methodErr={methodErr}
              setMethodErr={setMethodErr}
              isActive={isActive}
              tr={tr}
              setActiveHandler={setActiveHandler}
              setAPIHostErr={setAPIHostErr}
              setAttributeKeyErr={setAttributeKeyErr}
              setAttributeLengthErr={setAttributeLengthErr}
              setAttributeValueTypeErr={setAttributeValueTypeErr}
              setEndpointErr={setEndpointErr}
              setHeadersErr={setHeadersErr}
              setBindAttriErr={setBindAttriErr}
              setForeignKeyErr={setForeignKeyErr}
              setReferenceTableErr={setReferenceTableErr}
              setReferenceColumnErr={setReferenceColumnErr}
              setReferenceMSErr={setReferenceMSErr}
              apiHostErr={apiHostErr}
              attributeKeyErr={attributeKeyErr}
              attributeLengthErr={attributeLengthErr}
              attributeValueTypeErr={attributeValueTypeErr}
              endPointErr={endPointErr}
              headersErr={headersErr}
              bindAttriErr={bindAttriErr}
              foreignkeyErr={foreignkeyErr}
              referencetableErr={referencetableErr}
              referencecolumnErr={referencecolumnErr}
              referencemsErr={referencemsErr}
            />
          </Panel>
          {isRightEditPanel ? (
            <>
              <PanelResizeHandle className="isShowLeftPanel resizeHandle">
                <div className="outlne">
                  <div className="handIcn">
                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                  </div>
                </div>
              </PanelResizeHandle>
              <Panel minSize={33} order={2} defaultSize={33}>
                {view === "activity" && (
                  <div className="stickyArea rBoxStyle">
                    <AuditLog
                      rightEditPanel={rightEditPanel}
                      setView={setView}
                    />
                  </div>
                )}
              </Panel>
            </>
          ) : (
            ""
          )}
        </PanelGroup>
      </Container>
    </main>
  );
};

export default TOAIndex;
