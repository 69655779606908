
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import OnClickOutside from 'react-onclickoutside';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import { MyContext } from '_MyContext';

let InnerPartWIBSearch=class InnerPartWIBSearch extends React.Component {
	static contextType = MyContext;
	constructor(props) {
		super(props);
		this.handleKeyPress = this.handleKeyPress.bind(this);
	}
	handleKeyPress(e){
		if (e.code ==="Enter") {
			this.props.handleSubmit();
		}
	}
	handleClickOutside() {
		this.props.fun(false);
	}
	// keyDownHandler(){
	// 	if (this.wibSearchInput !== null || this.wibSearchInput !== undefined) {
	// 	  const mySearchObj = this.wibSearchInput;
	// 	  window.addEventListener("keydown", function (event) {
	// 		if (event.ctrlKey && event.key === "b") {
	// 		  mySearchObj.focus();
	// 		}
	// 	  });
	// 		}
	//   }
	  componentDidMount() {
		this.context.keyDownHandler("","",this.wibSearchInput,"");
		this.context.newTabPrevent();
	  }
	  componentDidUpdate() {
		this.context.keyDownHandler("","",this.wibSearchInput,"");
		this.context.newTabPrevent();
	  }	
	render() {
		return (
			<div className="innerIpt">
				<Form.Control bsPrefix=' ' className="colSearInp ctrlKeyPrevent" type="text" placeholder={this.props.placeholderMessage} value={this.props.searchInput} onChange={this.props.updateSearchInputFun} onFocus={() => {if (this.props.isActiveModuleBox===false) {this.props.modulePopupFunction(true,'textfield')} }} onKeyDown={this.handleKeyPress} id="wibSearchId" ref={e => this.wibSearchInput = e} />
				{this.props.isModulePopup ? <ListGroup className="serchmodu">{this.props.renderModulesPopupNameList}</ListGroup> : null}
				{this.props.isActiveModuleBox ? <Button bsPrefix=' ' title={this.props.tr[this.props.selectedModule]+' '+this.props.tr['Module']} className="serchmoduVal" onClick={()=>{this.props.modulePopupFunction(true,'crossbutton');this.wibSearchInput.focus();}}>{this.props.activeModuleName}</Button> : null}
			</div>
		);
	}
};

InnerPartWIBSearch = OnClickOutside(InnerPartWIBSearch);

InnerPartWIBSearch.propTypes={
	activeModuleName: PropTypes.string,
	searchInput: PropTypes.string,
	updateSearchInputFun: PropTypes.func,
	isActiveModuleBox: PropTypes.bool,
	modulePopupFunction: PropTypes.func,
	isModulePopup: PropTypes.bool,
	renderModulesPopupNameList: PropTypes.array,
	selectedModule: PropTypes.string,
	tr: PropTypes.object,
	fun: PropTypes.func,
	handleSubmit: PropTypes.func
};

export default InnerPartWIBSearch;