
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from "../initialState";

export const watcherLoader = (state = {  
    loading: false,
    message: ''
  }, action) => {
    switch (action.type) {
      case 'WATCHER_LOADER_IN_PROGRESS':
        return Object.assign({},
          state, {
          loading: true,
          message: 'loading...'
        });
      case 'WATCHER_DETAILS_FETCHED_SUCCESSFULLY':
        return Object.assign({}, state, {
          loading: false,
          message: ''
        });
      default:
        return state;
    }
  };

export const watcherDetailsFetched = (state = [], action) => {
  // alert("Inside Componet")
  switch (action.type) {
    case "WATCHER_DETAILS_FETCHED":
      return action.payload;
    default:
      return state;
  }
};
