
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';// use to make ajax request from browser

//const ROOT_URL=`https://xsm-f.mybluemix.net/getProviderCompanyForRestifySelfService/`

export const FETCH_PROVIDERLIST = 'FETCH_PROVIDERLIST';

export function loadProviderListSuccess(providerlist){
	return{
		type: FETCH_PROVIDERLIST,
		providerlist

	};
}

export function loadProviderList(url){
	return(dispatch) =>{
		axios.get(url)
			.then((providerlist)=>{
				if(!providerlist){
					throw Error(providerlist.statusText);
				}
				return providerlist;
			})
			.then((providerlist) =>{
				dispatch(loadProviderListSuccess(providerlist))
			})
			.catch((err) => {
				console.log(err);
			});
	};
}
