
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import * as types from "../actions/actionTypes";
import initialState from "./initialState";


//NE_AUDIT_LOGS
export const neReducer = (state = initialState.ne_editformDetails, action) => {
  let preEdit = "";
  let edit = "";
  
  switch (action.type) {   
    case "EDITFORM_NE_DETAILS":
       return  action.payload;
    case "OVERRIECUSTOM_TEMPLATE_NE":
       return  action.payload;     
    default:
         return state;
           
  }
};
export const auditLogNEReducer = (state = [], action) => {

  if (action.type === 'NE_AUDIT_LOGS') {
    return action.payload;
  } else {
    return state;
  }
};


export const customVariablesReducer = (state = [], action) => {
   if (action.type === 'TEMPLATE_VARIABLES') {
     return action.payload;
   } else {
     return state;
   }
 };
