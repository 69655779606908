
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TableComponent from "../../../common/ReactTable/TableComponent";
import ListLoader from "_Commons/loaders/ListLoader";

const AdvertisementList = (props) => {
  const [advertisementListData, setAdvertisementListData] = useState([]);

  const advertisementList = useSelector((state) => state.advertisementList);
  const showLoader = useSelector((state) => state.commonLoader.isLoading);

  const onRowSelect = (row) => {
    props.rowHandler(row);
    props.showRightSideFun(true);
  };

  const tableColumns = [
    {
      name: props.translator["Company"],
      selector: (row) => row.companyId,
      sortable: true,
    },
    {
      name: props.translator["Sequence"],
      selector: (row) => row.advertSequence,
      sortable: true,
    },
    {
      name: props.translator["From Date"],
      selector: (row) => row.fromDate,
      sortable: true,
    },
    {
      name: props.translator["To Date"],
      selector: (row) => row.toDate,
      sortable: true,
    }
  ];

  useEffect(() => {
    if (advertisementList.length > 0) {
      setAdvertisementListData(advertisementList);
    }
    if (advertisementList.length == 0) {
      setAdvertisementListData([]);
    }
  }, [advertisementList]);

  const filterColumns = ["companyId"];

  const renderCostCenterTableData = () => {
    return (
      <div className="dtaTableDv integrateHubTable">
        <div
          className={
            props.isRightSideVisible
              ? "leftTrue srchPosTop fdnPos"
              : "rightTrue srchPosTop fdnPos"
          }
        >
          <TableComponent
            data={advertisementListData || []}
            columns={tableColumns}
            headerColumns={filterColumns}
            onRowSelect={onRowSelect}
            rightDisplay={props.isRightSideVisible}
            uniqueKey={"advertisementId"}
          />
        </div>
      </div>
    );
  };

  if (!advertisementListData) return null;
  if (advertisementListData.length == undefined) {
    return <ListLoader />;
  } else {
    return (
      <div className="myOrderDiv">
        {showLoader ? (
          <ListLoader />
        ) : (
          renderCostCenterTableData()
        )}
      </div>
    );
  }
};

export default AdvertisementList;