
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const relatedworkitemsCount=(state=-2,action) =>{
    switch(action.type){
        case 'LOAD_RELATED_WORK_ITEMS_COUNT': 
              return action.recordCount;
        default:
              return state;
    }
}