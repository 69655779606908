
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import NavLink from 'react-bootstrap/NavLink';
import { Link } from "react-router-dom";
import { reduxForm,formValueSelector,change,SubmissionError } from 'redux-form';
import { navigationHooks } from "../../../../helpers/NavigationHook";
// import { push } from 'react-router-redux';
let sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
import moment from 'moment';
import { GLOBAL } from '_Globals';
import axios from 'axios';
import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import {LuSearch, LuBarChart} from 'react-icons/lu';
import { MyContext } from '_MyContext';

class InvestigationCreateHeader extends React.Component {
	static contextType = MyContext;
	constructor(props) {
		super(props);
		this.state = {
			showSubmit:true
    };
		this.saveChange = this.saveChange.bind(this);
        this.goBack = this.goBack.bind(this);
	}

	componentDidMount() {
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
		this.context.newTabPrevent();
	}
	componentDidUpdate() {
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
		this.context.newTabPrevent();
	}

	saveChange(values) {
		let ref=this;
	  return sleep(1000)
	    .then(() => {
			    console.log("values",values);
				if(values.supportPIndividualName == 'Select..')
				values.supportPIndividualName="";
				
	      let priorityActualValue='';
		  if(GLOBAL.priorityValueType=="P2"){
	        priorityActualValue=50;
	      }else if(GLOBAL.priorityValueType=="P1"){
	        priorityActualValue=45;
	      }
	      else if(GLOBAL.priorityValueType=="P3"){
	        priorityActualValue=55;
	      }
	      else if(GLOBAL.priorityValueType=="P4"){
	        priorityActualValue=60;
	      }
	      let breakFixConsumer=GLOBAL.breakFixConsumerID;
	      let breakFixConsumerNameValue=GLOBAL.breakFixConsumerName;
	      //let breakFixConsumerCompany=GLOBAL.breakFixCompanyName;
	    //   let breakFixService=GLOBAL.breakFixServiceID;
	      //let supportPgroupValue = GLOBAL.supportPGroupValue;
	      //let supportPgroupNameValue = GLOBAL.supportPGroupName;
	      //let supportPIGroupNameValue = GLOBAL.supportPIGroupName;
	      //let breakFixServiceName=GLOBAL.breakFixServiceName;
	      //let asgPmId = GLOBAL.breakfixgroupid;
				//let breakfixconsumercompanyname=GLOBAL.consumerCompanyName;
				let ciId="";let ciName="";let ciCode="";
        if(GLOBAL.ciId=="" || GLOBAL.ciId==undefined){
          ciId=0;ciName="";ciCode="";
        }else{
          ciId=GLOBAL.ciId;ciName=GLOBAL.ciName;ciCode=GLOBAL.ciIdNumber;
        }
	      if(`${JSON.stringify(values, null, 2)}`=="{}"){
	        throw new SubmissionError({ _error: 'The form has missing required fields, please revalidate the highlighted fields and update the form' })
	      }else if (breakFixConsumer == "" || values.issueDescription == ""
	        || values.source=="" || values.criticality == "" || values.urgencyMode == "" || values.urgencyMode === undefined || GLOBAL.ciId==="") {
	          if(values.source=="")
			  {
				GLOBAL.sourceErrorColor="error";
			  }
			  else{
				GLOBAL.sourceErrorColor="";
			  }
			// 	if(breakFixService == ""&&values.service=="service"){
			// 			GLOBAL.serviceErrorColor="error";
	        // }else{
	        //   GLOBAL.serviceErrorColor="";
	        // }
	        if(breakFixConsumer == "") {
						GLOBAL.consumerErrorColor="error";
	        }else {
	          GLOBAL.consumerErrorColor="";
	        }
	        if(values.issueDescription=="") {
	          GLOBAL.issueErrorColor="error";
	        }else {
	          GLOBAL.issueErrorColor="";
	        }
	        if(values.criticality=="" || values.criticality == undefined || values.criticality == "Select..") {
	          GLOBAL.criticalityErrorColor="error";
	        } else {
	          GLOBAL.criticalityErrorColor="";
	        }
	        if(values.urgencyMode =="" || values.urgencyMode ==undefined || values.urgencyMode == "Select..") {
	          GLOBAL.urgencyErrorColor="error";
	        }else {
	          GLOBAL.urgencyErrorColor="";
	        }
			if(GLOBAL.ciId===""){
				GLOBAL.CIError="error"
			}
			else{
				GLOBAL.CIError=""
			}
	        // if(values.supportPIndividual == "" || values.supportPIndividual == undefined || values.supportPIndividual == 'Select..'){
	        //   GLOBAL.sgIErrorColor = "error";
	        // }else{
	        //     GLOBAL.sgIErrorColor = "";
	        // }
	        throw new SubmissionError({ _error: 'The form has missing required fields, please revalidate the highlighted fields and update the form !' });
	      }
	      else{
			let isProblemMgmtGroupEmpty = values.supportPGroup === "" || values.supportPGroup === undefined || values.supportPGroup === 'Select' || values.supportPGroup === "0";
					ref.setState({showSubmit:false});
	        axios.post(GLOBAL.investigationCreateUrl, {
	          "asgPmId":isProblemMgmtGroupEmpty ? "0" : values.supportPIndividual,
	          "asgPmName": isProblemMgmtGroupEmpty ? "": values.supportPIndividualName,
	          "cancellationComments": "",
	          "asgPmGroupid":isProblemMgmtGroupEmpty ? "0" : values.supportPGroup,
	          "asgPmGroupname":isProblemMgmtGroupEmpty ? "" : values.supportPGroupName,
	          "cause": 0,
	          "closureComments": "",
	          "consumerCompany": values.company,
	          "correctiveActionRequired": "",
	          "correctiveActions": "",
	          "createdBy": "",
	          "createdOn": 0,
	          "description": values.issueDescription,
	          "expectedDate": 0,
	          "impactedUserid":breakFixConsumer,
	          "impactedUsername": breakFixConsumerNameValue,
	          "modifiedBy": "",
	          "modifiedOn": 0,
	          "priority": priorityActualValue,
			  "priorityValue": GLOBAL.priorityValueType,
	          "qualificationComments": "",
	          "qualified": "",
	          "reportedThrough": "",
	          "requestId": 0,
	          "rootCause": "",
	          "serviceCriticality": values.criticality,
			  "source":values.source,
	          "serviceId": 0,
	          "serviceName": '',   
	          "slaStatus": 0,
			  "status": 30,
	          // "status": 35,
	          "symptoms": values.additionalInfo,
	          "urgency": values.urgencyMode,
	          "createdByName":"",
			  "consumerCompanyName":values.companyName,
			"ciId":ciId,
            "ciName":ciName,
            "ciCode":ciCode,
			"serviceBased":false,

			"ciClassName":GLOBAL.ciClassName,
			"environment":GLOBAL.environmentName,
			"ciConsumerCompany":GLOBAL.ciConsumerCompany,
			"ciSupportCompany":GLOBAL.ciSupportCompany,
			"ciClassId":GLOBAL.ciClassId,
			"ciLocationId":GLOBAL.ciLocationId,
			"ciLocationName":GLOBAL.ciLocationName,
			"isExternal" : GLOBAL.isExternalInvestigationCreate,
			"requesterLocationId": GLOBAL.servicelocationid,
			"requesterLocationName": GLOBAL.servicelocationname,
			"businessCriticalityId": GLOBAL.businessCriticalityId,
            "businessCriticality": GLOBAL.businessCriticality,
			"supportCompanyId": values.supportCompanyID,
			"supportCompanyName": values.supportCompanyName
	        })
	        .then(function (response) {
	          console.log("creating investigation");
						console.log(response);
						if(response.status==201){
              if(ciId !=0){
                //alert("response.data.ciId"+response.data.ciId)

				let payloadObj ={
					"ci_id": ciId,  //==> ID of CI related
					"wi_id_related": response.data.investigationId, //==> ID of Work Item related
					"wi_id_number": response.data.investigationNumber,//  ==> Number of work item related
					"ci_id_reported": response.data.createdOn,
					"created_by": "",
					"relationship_type": "Y",
					"module": "Investigation"
				}
                axios.post(GLOBAL.cmdbRelatedLogDeleteUrl, /*{
            			"ci_id": response.data.investigationId,
            			"ci_id_related":ciId,
            			"ci_id_number":response.data.investigationNumber,
            			"ci_id_reported":response.data.createdOn,
            			"created_by": "",
                  "relationship_type":"Y",
            			"module":"Investigation"
            		}*/
					payloadObj
					).
            		then((response) =>{
            			//alert("Data successfully submitted")
            			//this.props.loadChangeRelatedWorkDetails("ci_name","---","CMDB",userDetails.company_id);
            			//GLOBAL.relatedtextdisplay= <div className="f-size-18 margin-b-5">{this.props.translator['Related Config Items']}</div>
            			//this.props.loadChangeRelatedDataDetails(itemId,"Breakfix");
            			//this.setState({toggleFormAndList:'list'});
                  console.log("this incident have been related to CMDB");
                  //GLOBAL.ciName="";GLOBAL.ciId="";GLOBAL.ciIdNumber="";
            		});
              }else{
                console.log("no qualification for CI relation")
              }
            }
				navigationHooks.navigate("/investigationEdit/"+response.data.investigationId.toString());
	        })
	        .catch(function (error) {
	          console.log(error);
	        });
	      }
	    });
	}
	goBack(){
      this.props.setInitialwibGlobalSearchFilterData();
	}
	render() {
		let { handleSubmit, pristine, reset, submitting } = this.props
		return (
			<Row className="margin-b-15">
				{/* <Col lg={7} md={7} sm={6} xs={5}>
					<h2 bsClass="" className="sPageHeading1">{this.props.translator['Create Problem Ticket']}</h2>
				</Col>
				<Col lg={5} md={5} sm={6} xs={7}>
					<div className="status myStatus">
						<Nav bsClass="" as="ul">
							<Nav.Item title={this.props.translator['Process Hierarchy']} onClick={()=>{this.props.processHierarchy();}} eventKey={3} as="li">
								<span className="sAction bgBtn17 chart"></span>
							</Nav.Item>
							<Nav.Item eventKey={1} as="li">
								<Button bsStyle="primary" disabled={!this.state.showSubmit} title={this.props.translator['Save']}
									onClick={handleSubmit(this.saveChange)}><i className={!this.state.showSubmit ?
										"fa fa-spinner fa-spin" : "fa fa-floppy-o"}></i>
								</Button>
							</Nav.Item>
							<Link
								to="/quickViewBreakFix"
							>
								<Nav.Item as="li" title={this.props.translator["Close"]} eventKey={2}>
								<a href={void 0}>
									<span
										className="sAction bgBtn17 icnbclose"
										onClick={this.goBack}
									></span>
								</a>
								</Nav.Item>
							</Link>
						</Nav>
					</div>
				</Col> */}


				<Col lg={8} md={7} sm={12} xs={12}>
					<h2 className="sPageHeading1">
						{this.props.translator["Investigation"]}
					</h2>
				</Col>
				<Col lg={4} md={5} sm={12} xs={12}>
					<div className="status myStatus">
						<Nav as="ul">
						<Nav.Item as="li" eventKey={1}>
							<Link className="saveBtnStyle ctrlKeyPrevent"
							to="javascript:void(0)"
							disabled={!this.state.showSubmit}
							title={this.props.translator["Save"]}
							onClick={handleSubmit(this.saveChange)}
							ref={(e) => (this.saveObjRef = e)}
							>
							<span> {!this.state.showSubmit ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/>}
							</span>
							</Link>
						</Nav.Item>

						<Nav.Item as="li" title={this.props.translator["Close"]} eventKey={2}>
							<Link to="/quickViewBreakFix" ref={e => this.closeButton = e} className="ctrlKeyPrevent"><IoClose/></Link>
						</Nav.Item>
						
						</Nav>
					</div>
					<div className="status myStatusGap border-r margin-r-10 padding-r-10">
						<Nav as="ul">
						<Nav.Item as="li"
							title={this.props.translator["Process Hierarchy"]}
							eventKey={4}
							onClick={() => {
								this.props.processHierarchy();
							}}
						>
							<a href="javascript:void(0)">
							<LuSearch/><LuBarChart className='processBarIcn'/>
							</a>
						</Nav.Item>
						</Nav>
					</div>
				</Col>
			</Row>
		);
	}
}


InvestigationCreateHeader = reduxForm({
  form: 'createInvestigationForm'    // a unique identifier for this form
})(InvestigationCreateHeader);


export default InvestigationCreateHeader;
