
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import ServiceAttributes from './serviceAttributes/list.js';
import ConsumptionItems from './consumptionItems/list.js';
import Entitlements from './entitlements/list.js';
import ChainEntities from './chainEntities/list.js';
import ServiceLevels from './serviceLevels/list.js';
import SelfServices from './selfServices/list.js';
import ServiceOrderChoices from './serviceOrderChoices/list.js';
import ServiceKnowledge from './serviceKnowledge/list.js';
import ServiceBilling from './serviceBilling/list.js';
import ServiceApprovals from './approvals/list.js';
import FulfilmentPlan from './fulfilmentPlan/list.js';
import OrderingPreferences from './orderingPreferences/editForm.js';
import Disclaimers from './disclaimers/editForm.js';
import RelatedOfferings from './relatedOfferings/list.js';
import Reminders from './reminders/list.js';
import SharedOfferings from './sharedOfferings/list.js';
import Attachments from './attachments/list.js';
import KnowledgeArticle from './knowledgeArticle/index.js';
import {postAttributeBasedSlaAction} from './../../actions/sla/lovAction.js';
import {getAttachmentsPaginationAction,getAttachmentsAction} from '../../actions/spcmActions.js';

import axios from "axios";
import { Link } from 'react-router-dom';
import Cookies from "universal-cookie";
import {LuThumbsUp, LuSettings2, LuCalendarCheck2, LuAlertTriangle, LuShare, LuCalendarClock} from 'react-icons/lu';
import {ImAttachment} from 'react-icons/im';
import {PiListBulletsBold, PiLinkBold, PiPuzzlePieceBold, PiBookOpenText, PiHandTapBold, PiTarget} from 'react-icons/pi';
import {IoRibbonOutline} from 'react-icons/io5';
import {LiaFileInvoiceDollarSolid} from 'react-icons/lia';
import {TbFileInfo, TbCirclesRelation, TbBulb} from 'react-icons/tb';
import { RiFileList3Line } from 'react-icons/ri';
import CloseBtnRightPanel from '../common/closeBtnRightPanel.js';
import { openLinkInNewTab } from '../common/helper.js';
import SmartCatList from './smartForm/list.js';
import { FaWpforms } from "react-icons/fa";

const cookies = new Cookies();
let app_url = cookies.get("gph");
if (app_url) app_url = app_url.replace("s:", "");
if (app_url) app_url = app_url.substring(0, app_url.lastIndexOf("."));
app_url = app_url.split("~");
app_url = app_url[18];

const spcmKnowledgeCall = process.env.SPCM_KNOWLEDGE_SEARCH;

class Attributes extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			subMenu: '4',
			fulfilmentBtnState: false,
			countSLA: this.props.spcmReducer.serviceDetailCounts.SERVICELEVEL,
			slaManager : false,
			slaUser : false
		};
		this.showComFun = this.showComFun.bind(this);
		this.toggleAddButtonStatus = this.toggleAddButtonStatus.bind(this);
		this.slaCountFun = this.slaCountFun.bind(this);
	};
	toggleAddButtonStatus(value, selectedValue) {
		if (selectedValue == 'Select') {
			this.setState({ fulfilmentBtnState: true });
		}
		else {
			this.setState({ fulfilmentBtnState: value });
		}

	}
	slaCountFun(val){
		this.setState({countSLA:val});
	}
	showComFun(value) {

		const tr = this.props.tr;
		switch (value) {
			case 1:
				return <ServiceAttributes
					tr={tr} componentFormToggle3={this.props.componentFormToggle2} componentFormFun3={this.props.componentFormFun2} />;
			case 2:
				return <ConsumptionItems tr={tr}
					componentFormToggle3={this.props.componentFormToggle2} componentFormFun3={this.props.componentFormFun2} />;
			case 3:
				return <Entitlements tr={tr}
					componentFormToggle3={this.props.componentFormToggle2} componentFormFun3={this.props.componentFormFun2} />;
			case 4:
				return <ChainEntities
					tr={tr} componentFormToggle3={this.props.componentFormToggle2} componentFormFun3={this.props.componentFormFun2} />;
			case 5:
				return <ServiceLevels tr={tr}
					showHideTabCreate={this.props.showHideTabCreate} componentFormToggle3={this.props.componentFormToggle2} componentFormFun3={this.props.componentFormFun2} setServiceStatus={this.setServiceStatus} serviceStatusState = {this.props.serviceStatusState} slaCountFun ={this.slaCountFun} countSLA ={this.state.countSLA}/>;
			case 6:
				return <SelfServices tr={tr}
					componentFormToggle3={this.props.componentFormToggle2} componentFormFun3={this.props.componentFormFun2} />;
			case 7:
				return <ServiceOrderChoices tr={tr}
					componentFormToggle3={this.props.componentFormToggle2} componentFormFun3={this.props.componentFormFun2} />;
			case 8:
				return <ServiceKnowledge tr={tr}
					componentFormToggle3={this.props.componentFormToggle2} componentFormFun3={this.props.componentFormFun2} />;
			case 9:
				return <ServiceBilling tr={tr}
					componentFormToggle3={this.props.componentFormToggle2} componentFormFun3={this.props.componentFormFun2} />;
			case 10:
				return <ServiceApprovals tr={tr}
					componentFormToggle3={this.props.componentFormToggle2} componentFormFun3={this.props.componentFormFun2} setServiceStatus={this.setServiceStatus} serviceStatusState = {this.props.serviceStatusState}/>;
			case 11:
				return <FulfilmentPlan tr={tr} toggleAddButtonStatus={this.toggleAddButtonStatus}
					componentFormToggle3={this.props.componentFormToggle2} componentFormFun3={this.props.componentFormFun2} fulfilmentBtnState={this.state.fulfilmentBtnState} setServiceStatus={this.props.setServiceStatus} serviceStatusState={this.props.serviceStatusState} />;
			case 12:
				return <OrderingPreferences tr={tr}
					componentFormToggle3={this.props.componentFormToggle2} componentFormFun3={this.props.componentFormFun2} boardPageInActive4={this.props.boardPageInActive3} />;
			case 13:
				return <Disclaimers tr={tr}
					componentFormToggle3={this.props.componentFormToggle2} componentFormFun3={this.props.componentFormFun2} boardPageInActive4={this.props.boardPageInActive3} />;
			case 14:
				return <RelatedOfferings
					tr={tr} componentFormToggle3={this.props.componentFormToggle2} componentFormFun3={this.props.componentFormFun2} />;
			case 15:
				return <Reminders
					tr={tr} componentFormToggle3={this.props.componentFormToggle2} componentFormFun3={this.props.componentFormFun2} boardPageInActive4={this.props.boardPageInActive3} />;
			case 16:
				return <SharedOfferings
					tr={tr} componentFormToggle3={this.props.componentFormToggle2} componentFormFun3={this.props.componentFormFun2} />;
      case 17:
				return <Attachments
					tr={tr} componentFormToggle3={this.props.componentFormToggle2} componentFormFun3={this.props.componentFormFun2} />;
      case 18:
				return <KnowledgeArticle
					tr={tr} componentFormToggle3={this.props.componentFormToggle2} setServiceStatus={this.setServiceStatus} serviceStatusState = {this.props.serviceStatusState}/>;
      case 20:
        return <SmartCatList
          tr={tr} componentFormToggle3={this.props.componentFormToggle2} componentFormFun3={this.props.componentFormFun2} />;
		}
	};
	componentDidMount(){
    this.props.getAttachmentsPaginationAction(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,'desc',1,10);
    this.props.getAttachmentsAction(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
    this.props.postAttributeBasedSlaAction('',this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID,'','','','','','')
		let str = {};
		str.user_id = "";
		str = JSON.stringify(str);
		axios.get("/api/userInfo", { headers: { query: str } }).then((response) => {
		//   this.setState({ role_id: response.data.role_id });
		  let roleArray = response.data.role_id.split(",");
		  console.log("roleArray===",roleArray)
		//   let amsRole = roleArray.includes("41");
		//   this.setState({ showRedirectionLink: amsRole });
		let slaManagerRole = roleArray.includes("40");
		this.setState({slaManager:slaManagerRole});
		let slaUserRole = roleArray.includes("45");
		this.setState({slaUser:slaUserRole});

		});
	}

	render() {
		let SLA_ADD_URL = app_url+"/home?createSlaDef=true";
		const tr = this.props.tr;
		const countData = this.props.spcmReducer.serviceDetailCounts;
		const attributeBasedSLAData = this.props.postAttributeBasedSlaReducer;
    const attachmentsData =  this.props.spcmReducer.attachmentsReducer

		var meta = this.props.spcmReducer.approvalMeta;
		var metaCount = meta && meta.rowCount ? meta.rowCount : ''
		const serviceWorkItemType = this.props.spcmReducer.currentlySelectedServiceDetails.WORK_ITEM_TYPE;
		console.log("props");
		console.log(this.props);
		console.log("serviceWorkItemType",serviceWorkItemType);
		console.log("this.state.slaManager",this.state.slaManager,this.state.slaUser,this.props.clickTabHeading2);
		console.log("this.props.spcmReducer.currentlySelectedServiceDetails.orderingPreferences",this.props.spcmReducer.orderingPreferences);
		console.log("attachmentsData",attachmentsData);
		return (
      <div className="commonForm stickyArea rBoxStyle">
        {this.props.listViewShow2 ? (
          <div>
            <CloseBtnRightPanel showRightSide={this.props.showRightSide}/>
            <div className="rPageHeading">
              <div className="offNam margin-t-5 margin-b-5 serbdbtn">
                {this.props.componentFormToggle2 ? null : this.props.clickTabHeading2 == "Reminders" ||
                  (serviceWorkItemType !== "serviceRequest" && this.props.clickTabHeading2 == "Components") || (this.props.clickTabHeading2=="Service Level Targets" && (this.state.slaManager == false && this.state.slaUser == false))
					? ( "" ) 
					: (this.props.clickTabHeading2=="Service Level Targets")?
				
				<a
										title="Go to SLA BOARD"
										onClick={() => {
                      openLinkInNewTab(app_url+"/home?createSlaDef=true", "New Tab");
										}}
										bsSize="xsmall"
										bsStyle=""
										className="addbtntr btn btn-primary"
										
									>{tr["Add"]}</a>
				
					: (
                  <Button
                    disabled={this.state.fulfilmentBtnState}
                    onClick={() => {
                      this.props.componentFormFun2(true);
                    }}
                    title={
                      this.props.clickTabHeading2 == "Related Offerings"
                        ? tr["Relate"]
                        : this.props.clickTabHeading2 == "Shared Offerings"
                        ? "Share"
                        : tr["Add"]
                    }
                    bsSize="xsmall"
                    bsStyle=""
                    className="addbtntr"
                  >
                    {this.props.clickTabHeading2 == "Related Offerings"
                      ? tr["Relate"]
                      : this.props.clickTabHeading2 == "Shared Offerings"
                      ? "Share"
                      : tr["Add"]}
                  </Button>
               		 )
				}{" "}
                {tr[this.props.clickTabHeading2] || this.props.clickTabHeading2}
              </div>
            </div>{" "}
            {this.showComFun(this.props.rightComponentCountNo2)}
          </div>
        ) : (
          <div>
            <CloseBtnRightPanel showRightSide={this.props.showRightSide}/>
            <div className="rPageHeading">
              <div className="offNam margin-t-5 margin-b-5">
                {tr["Service Details"]}
              </div>
            </div>
            <div className="rBoxGap serviceListBox">
              <div className="servicePlist">
                {serviceWorkItemType == "incidentRequest" ? null :
                <div
                  className="lbox"
                  onClick={() => {
                    this.props.clickTabName2("Approvals", 10);
                  }}
                >
                  <div title={tr["Approvals"]} className="hd">
                  <span className='serIcon'><LuThumbsUp/></span>
                    {tr["Approvals"]}
                    <span className="hdCount">({metaCount})</span>
                  </div>
                  <div className="txtBox">
                    <div className="txt">
                      {tr["Specify the service approvals"]}
                    </div>
                  </div>
                </div>
  }
                <div
                  className="lbox"
                  onClick={() => {
                    this.props.clickTabName2("Attachments", 17);
                  }}
                >
                  <div title={tr["Attachments"]} className="hd">
                  <span className='serIcon'><ImAttachment/></span>
                    {tr["Attachments"]}
                    <span className="hdCount">({countData.OFGATTACHMENTS})</span>
                  </div>
                  <div className="txtBox">
                    <div className="txt">
                      {
                        tr[
"Attachments"                        ]
                      }
                    </div>
                  </div>
                </div>

                <div
                  className="lbox"
                  onClick={() => {
                    this.props.clickTabName2("Attributes", 1);
                  }}
                >
                  <div title={tr["Attributes"]} className="hd">
                  <span className='serIcon'><PiListBulletsBold/></span>
                    {tr["Attributes"]}
                    <span className="hdCount">({countData.OFGATTRIBUTES})</span>
                  </div>
                  <div className="txtBox">
                    <div className="txt">
                      {
                        tr[
                          "List the features of the service that you want potential consumers to  view to help them decide"
                        ]
                      }
                    </div>
                  </div>
                </div>

                <div
                  className="lbox"
                  onClick={() => {
                    this.props.clickTabName2("Chain Entities", 4);
                  }}
                >
                  <div title={tr["Chain Entities"]} className="hd">
                  <span className='serIcon'><PiLinkBold/></span>
                    {tr["Chain Entities"]}
                    <span className="hdCount">({countData.CHAINENTITIES})</span>
                  </div>
                  <div className="txtBox">
                    <div className="txt">
                      {
                        tr[
                          "Specify the entities through the complete support chain for the service"
                        ]
                      }
                    </div>
                  </div>
                </div>

                <div
                  className="lbox"
                  onClick={() => {
                    this.props.clickTabName2("Components", 2);
                  }}
                >
                  <div title={tr["Components"]} className="hd">
                  <span className='serIcon'><PiPuzzlePieceBold/></span>
                    {tr["Components"]}
                    <span className="hdCount">
                      ({countData.CONSUMPTIONITEMS})
                    </span>
                  </div>
                  <div className="txtBox">
                    <div className="txt">
                      {tr["Specify components that comprise the service"]}
                    </div>
                  </div>
                </div>

                <div
                  className="lbox"
                  onClick={() => {
                    this.props.clickTabName2("Entitlements", 3);
                  }}
                >
                  <div title={tr["Entitlements"]} className="hd">
                  <span className='serIcon'><IoRibbonOutline/></span>
                    {tr["Entitlements"]}
                    <span className="hdCount">({countData.ENTITLEMENT})</span>
                  </div>
                  <div className="txtBox">
                    <div className="txt">
                      {
                        tr[
                          "Specify who gets to order and who gets to just view the service"
                        ]
                      }
                    </div>
                  </div>
                </div>
                {spcmKnowledgeCall == "true" ? (
                  <div
                    className="lbox"
                    onClick={() => {
                      this.props.clickTabName2("Knowledge", 8);
                    }}
                  >
                    <div title={tr["Knowledge"]} className="hd">
                    <span className='serIcon'><PiBookOpenText/></span>
                      {tr["Knowledge"]}
                      <span className="hdCount">
                        ({countData.SERVICEKNOWLEDGE})
                      </span>
                    </div>
                    <div className="txtBox">
                      <div className="txt">
                        {
                          tr[
                            "Specify sources of knowledge that can be referred to by consumers"
                          ]
                        }
                      </div>
                    </div>
                  </div>
                ) : null}

                {serviceWorkItemType == "serviceRequest" ? (
                  <div
                    className="lbox"
                    onClick={() => {
                      this.props.clickTabName2("Pricing", 9);
                    }}
                  >
                    <div title={tr["Pricing"]} className="hd">
                    <span className='serIcon'><LiaFileInvoiceDollarSolid/></span>
                      {tr["Pricing"]}
                      <span className="hdCount">
                        ({countData.SERVICEBILLING})
                      </span>
                    </div>
                    <div className="txtBox">
                      <div className="txt">
                        {tr["Specify the service price"]}
                      </div>
                    </div>
                  </div>
                ) : null}

                {/* {serviceWorkItemType == "serviceRequest"? */}
                <div
                  className="lbox"
                  onClick={() => {
                    this.props.clickTabName2("Ordering Information", 7);
                  }}
                >
                  <div title={tr["Ordering Information"]} className="hd">
                  <span className='serIcon'><TbFileInfo/></span>
                    {tr["Ordering Information"]}
                    <span className="hdCount">({countData.OFGQUESTIONS})</span>
                  </div>
                  <div className="txtBox">
                    <div className="txt">
                      {
                        tr[
                          "Specify what information you need from a potential consumer while ordering  the service"
                        ]
                      }
                    </div>
                  </div>
                </div>
                {/* : null} */}

                {this.props.spcmReducer.orderingPreferences.hide_sla ==
                "false" ? (
                  // <div className="lbox" onClick={() => { this.props.clickTabName2('Service Level Targets', 5) }}><div title={tr['Service Level Targets']} className="hd"><span className="serIcon bgBtn15 sDetails p5"></span>{tr['Service Level Targets']}<span className="hdCount">({countData.SERVICELEVEL?countData.SERVICELEVEL:this.state.countSLA})</span></div><div className="txtBox"><div className="txt">{tr['Specify the support performance targets for the service']}</div></div></div>
                  <div
                    className="lbox"
                    onClick={() => {
                      this.props.clickTabName2("Service Level Targets", 5)
                        this.props.componentFormFun2(false);
                    }}
                  >
                    <div title={tr["Service Level Targets"]} className="hd">
                      <span className="serIcon"><PiTarget/></span>
                      {tr["Service Level Targets"]}
                      <span className="hdCount">
                        ({attributeBasedSLAData.length})
                      </span>
                    </div>
                    <div className="txtBox">
                      <div className="txt">
                        {
                          tr[
                            "Specify the support performance targets for the service"
                          ]
                        }
                      </div>
                    </div>
                  </div>
                ) : null}
                <div
                  className="lbox"
                  onClick={() => {
                    this.props.clickTabName2("Self-Service Actions", 6);
                  }}
                >
                  <div title={tr["Self-Service Actions"]} className="hd">
                    <span className='serIcon'><PiHandTapBold/></span>
                    {tr["Self-Service Actions"]}
                    <span className="hdCount">
                      ({countData.OFGSELFSERVICEACTIONS})
                    </span>
                  </div>
                  <div className="txtBox">
                    <div className="txt">
                      {
                        tr[
                          "Specify what actions can consumers perform on their own"
                        ]
                      }
                    </div>
                  </div>
                </div>

                <div
                  className="lbox"
                  onClick={() => {
                    this.props.clickTabName2("Preferences", 12)
                      this.props.componentFormFun2(true);
                  }}
                >
                  <div title={tr["Preferences"]} className="hd">
                  <span className='serIcon'><LuSettings2/></span>
                    {tr["Preferences"]}
                  </div>
                  <div className="txtBox">
                    <div className="txt">{tr["Preferences"]}</div>
                  </div>
                </div>

                <div
                  className="lbox"
                  onClick={() => {
                    this.props.clickTabName2("Fulfillment Plan", 11)
                      this.setState({ fulfilmentBtnState: true });
                      this.props.componentFormFun2(false);
                  }}
                >
                  <div title={tr["Fulfillment Plan"]} className="hd">
                  <span className='serIcon'><LuCalendarCheck2/></span>
                    {tr["Fulfillment Plan"]}
                  </div>
                  <div className="txtBox">
                    <div className="txt">{tr["Fulfillment Plan"]}</div>
                  </div>
                </div>

                {serviceWorkItemType == "serviceRequest" ? (
                  <div
                    className="lbox"
                    onClick={() => {
                      this.props.clickTabName2("Disclaimers", 13)
                        this.props.componentFormFun2(true);
                    }}
                  >
                    <div title={tr["Disclaimers"]} className="hd">
                    <span className='serIcon'><LuAlertTriangle/></span>
                      {tr["Disclaimers"]}
                    </div>
                    <div className="txtBox">
                      <div className="txt">{tr["Disclaimers"]}</div>
                    </div>
                  </div>
                ) : null}

                <div
                  className="lbox"
                  onClick={() => {
                    this.props.clickTabName2("Related Offerings", 14);
                  }}
                >
                  <div title={tr["Related Offerings"]} className="hd">
                  <span className='serIcon'><TbCirclesRelation/></span>
                    {tr["Related Offerings"]}
                  </div>
                  <div className="txtBox">
                    <div className="txt">{tr["Related Offerings"]}</div>
                  </div>
                </div>

                <div>
                  {this.props.spcmReducer.currentlySelectedServiceDetails
                    .allowShare == false ? (
                    ""
                  ) : (
                    <div
                      className="lbox"
                      onClick={() => {
                        this.props.clickTabName2("Shared Offerings", 16);
                      }}
                    >
                      <div title={tr["Shared Offerings"]} className="hd">
                      <span className='serIcon'><LuShare/></span>
                        {tr["Shared Offerings"]}
                      </div>
                      <div className="txtBox">
                        <div className="txt">{tr["Shared Offerings"]}</div>
                      </div>
                    </div>
                  )}
                </div>
            {serviceWorkItemType == "incidentRequest" ? null :
                <div
                  className="lbox"
                  onClick={() => {
                    this.props.clickTabName2("Reminders", 15);
                  }}
                >
                  <div title={tr["Reminders"]} className="hd">
                    <span className='serIcon'><LuCalendarClock/></span>
                    Reminders
                  </div>
                  <div className="txtBox">
                    <div className="txt">{tr["Reminders"]}</div>
                  </div>
                </div> }
                <div
                  className="lbox"
                  onClick={() => {
                    this.props.clickTabName2("Knowledge Article", 18);
                    this.props.componentFormFun2(true);
                  }}
                >
                  <div title={tr["Knowledge Article"]} className="hd">
                    <span className='serIcon'><RiFileList3Line /><TbBulb className='knwldgIcn'/></span>
                    Knowledge Article
                  </div>
                  <div className="txtBox">
                    <div className="txt">{tr["Knowledge Article"]}</div>
                  </div>
                </div>
                <div
                  className="lbox"
                  onClick={() => {
                    this.props.clickTabName2("Smart Form", 20);
                  }}
                >
                  <div title={tr["Smart Form"]} className="hd">
                  <span className='serIcon'><FaWpforms /></span>
                    {tr["Smart Form"]}
                  </div>
                  <div className="txtBox">
                    <div className="txt">{tr["Smart Form"]}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
	}
}

const mapStateToProps = ({ spcmReducer,postAttributeBasedSlaReducer }) => {
	return {
		spcmReducer,postAttributeBasedSlaReducer
	}
};

export default connect(mapStateToProps,{postAttributeBasedSlaAction,getAttachmentsPaginationAction,getAttachmentsAction})(Attributes);