
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import ListLoader from '_Commons/loaders/ListLoader';
import {loadBusinessFunctionList} from '../../../actions/businessFunction/businessFunctionAction'
import TableComponent from '_Commons/ReactTable/TableComponent';
import moment from "moment-timezone";
import Cookies from "universal-cookie";

const cookies = new Cookies();
let timezone = cookies.get("gph");
if (timezone) timezone = timezone.replace("s:", "");
if (timezone) timezone = timezone.substring(0, timezone.lastIndexOf("."));
timezone = timezone.split("~");
timezone = timezone[3];
let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

const BusinessFunctionList = ({
  translator,
  businessFunctionByCompanyId,
  rightDisplay,
  loadBusinessFunctionList,
  getCategoryBoardDetails,
  showRightSide,
  parentFunctionByCompanyIdDropdown,
}) => {
  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  const businessFunctionList = useSelector(
    (state) => state.businessFunctionList,
    console.log("state.businessFunctionList.businessFunctionList",businessFunctionList)
  );

  const showLoader = useSelector((state) => state.showLoader.loading);

  const tableColumns = [
    {
      name: translator["Company"],
      selector: (row) => row.companyName,
      sortable: true,
    },
    {
      name: translator["Name"],
      selector: (row) => row.businessFunctionName,
      sortable: true,
    },
    {
      name: translator["Parent Function"],
      selector: (row) => row.parentFunction,
      sortable: true,
    },
    {
      name: translator["Created On"],
      selector: (row) => row.createdDate,
      sortable: true,
    },
    {
      name: translator["Modified On"],
      selector: (row) => row.modifiedDate,
      sortable: true,
    },
    {
      name: translator["Status"],
      selector: (row) => row.statuss,
      sortable: true,
    },
    
  ];

  useEffect(() => {
if(businessFunctionList.length>0){
  setList(businessFunctionList.map((obj)=> {
   return {
    ...obj, statuss: obj.status == '1' ? 'Active' : 'Inactive',
   }
  }))
}
    
  }, [businessFunctionList]);
  
  const filterColumns = ["businessFunctionName", "parentFunction","companyName","statuss", "createdDate", "modifiedDate"];

  const onRowSelect = (row) => {
    console.log("Row ====>",row.businessFunctionDesc)
    getCategoryBoardDetails(
      row.id,
      row.businessFunctionName,
      row.parentFunction,
      row.companyName,
      row.statuss,
      row.businessFunctionDesc
    )
    dispatch({
      type: 'LOAD_BUSINESS_EDIT_DETAILS_SUCCESS',
      row
  })
    showRightSide(true);
  };

  const renderBusinessFunctionListTable = () => (
    console.log("businessFunctionList====>",businessFunctionList),
    <div className="dtaTableDv integrateHubTable">
      <div className={rightDisplay ? 'leftTrue srchPosTop bfncSrchPos' : 'rightTrue srchPosTop bfncSrchPos'}>
        <TableComponent
          data={list}
          columns={tableColumns}
          headerColumns={filterColumns}
          onRowSelect={onRowSelect}
          rightDisplay={rightDisplay}
          uniqueKey={'id'}
        />
      </div>
    </div>
  );
  return (
    <div className='myOrderDiv'>
        {showLoader ? <ListLoader /> : renderBusinessFunctionListTable()}
    </div>
  );
//   if (businessFunctionList== undefined)  {
//     return (
//         <div className='myOrderDiv'>
//             {showLoader ? <ListLoader /> : renderBusinessFunctionListTable()}
//         </div>
//     );
// } else {
//     return (
//         <div className='myOrderDiv'>
//             {showLoader ? <ListLoader /> : renderBusinessFunctionListTable()}
//         </div>
//     );
// }
};


const mapDispatchToProps = dispatch => bindActionCreators({ loadBusinessFunctionList }, dispatch);

export default connect(mapDispatchToProps)(BusinessFunctionList);
