
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import {Row, Col, Container, Button } from "react-bootstrap";
import {Field,formatQuery,QueryBuilder,RuleGroupType,defaultValidator,defaultOperators,OptionGroup,ValueEditor,findPath,} from "react-querybuilder";
import "react-querybuilder/dist/query-builder.css";
import axios from 'axios';
import {useNavigate} from "react-router-dom";

import { getQueryBuilderFields,getQueryBuilderChildFields} from '_Actions/common/queryBuilderAction';
import { useDispatch, useSelector} from 'react-redux';

let newVal = [
	{ name: "incident.serviceName.company", label: `\u00A0 - Company` },
	{ name: "incident.serviceName.workItemType", label: `\u00A0 - Work Item Type`,},
	{ name: "incident.serviceName.status", label: `\u00A0 - Status`,valueEditorType: 'select',values: status,operators: defaultOperators.filter((op) => op.name === '='),},
	{ name: "incident.serviceName.category", label: `\u00A0 - Category`,comparator:status,valueSources: ['field', 'value']},
	{ name: "incident.serviceName.lastModifiedOn", label: `\u00A0 - Last Modified On`,},
];
const initialQuery={combinator: "and",rules: []};

const Querybuilder = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryBuilderData = useSelector((state) => state.queryBuilderData);
  const queryBuilderChildData = useSelector((state) => state.queryBuilderChildData);
  const postQueryBuilderData = useSelector((state) => state.postQueryBuilderData);

  console.log("postQueryBuilderData790",postQueryBuilderData);

  console.log("queryBuilderData790",queryBuilderData);

  const [query, setQuery] = useState(initialQuery);
  const [fields, setFields] = useState(queryBuilderData);
  const [qwes, setQwes] = useState("");
  const [indexKey, seIndexKey] = useState("");
  const [isResult, setResult] = useState(false);

  console.log("queryBuilderChildData79",queryBuilderChildData);

  useEffect(() => {
    setFields(queryBuilderData)
 },[queryBuilderData]);

  let saveQuery = (val) => {
    setResult(true);
    console.log("79Val", val);
  };

  useEffect(() => {
    dispatch(getQueryBuilderFields());
  }, []);
  

  useEffect(() => {
    if(queryBuilderChildData.length > 0 && qwes !=undefined && qwes !=''){
      Array.prototype.splice.apply(qwes, [
        parseInt(indexKey,10) + 1,
        0,
        ...queryBuilderChildData,
      ]);
      setFields(structuredClone(qwes));
    }
  }, [qwes,queryBuilderChildData]);
  
let updateChildFun = (updateField) => {
  let indexkeyName;
  fields.map((field, i) => {
    if (updateField === field.name && field.foriegnTableExists) {
      seIndexKey(i);
      indexkeyName = field.name;
    }
  });
  let qwe = fields;

  if (updateField===indexkeyName) {
    dispatch(getQueryBuilderChildFields(updateField,indexkeyName));
    setQwes(qwe);
  }
}

  const handleOnRuleChange = (v) => {updateChildFun(v);};
  const FieldValueSelector = ({className,handleOnChange,options,value,context,...ValueSelectorProps}) => {
    return (<>
      <select className="" value={value} onChange={(v) => {handleOnChange(v.target.value);handleOnRuleChange(v.target.value);}}>
        {options.map((item, i) => {
          const key = i;
          return (
            <option key={key} value={item.name}>
              {item.label} {item.foriegnTableExists? "(+)" : null}
            </option>
          );
        })}
      </select>
	  </>);
  };
  const testFieldSelector = {
    fieldSelector: FieldValueSelector,
  };
  let setQueryResult = props.setQueryResult;

  useEffect(() => {
    setQueryResult(formatQuery(query, "sql"),formatQuery(query, "json"));
 },[query]);

  return (
    <>
      <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">QueryBuilder</div></div>
      <div className="rBoxGap">
        <QueryBuilder
          controlClassnames={{ queryBuilder: "queryBuilder-branches" }}
          fields={fields}
          query={query}
          onQueryChange={(q) => {
            setQuery(q);
            setResult(false);
          }}
          validator={defaultValidator}
          controlElements={testFieldSelector}
        />
        <div className="padding-t-20">
          {/* <div className="text-c ">
            <Button onClick={() => {saveQuery(query);}} style={{padding:"5px 10px",fontSize:"11px"}}>show query</Button>
          </div> */}
          {/* {postQueryBuilderData.status ==="OK" ?  */}
          <><h3>Query result type <code>json</code></h3>
          <pre className="margin-t-10"><code>{formatQuery(query, "json")}</code></pre>

          <h3 className="margin-t-20">Query result type <code>SQL</code></h3>
          <pre className="margin-t-10"><code>{formatQuery(query, "sql")}</code></pre></>
          
          {/* : null} */}
        </div>
      </div>
    </>
  );
};

export default Querybuilder;
