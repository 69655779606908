
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export const provideFixDropdownList = (state = [], action)=> {
	switch (action.type){
		case 'LOAD_BREAKFIXCAUSEDROPDOWN_SUCCESS':
			//alert(action.breakFix.data.);
			return action.provideFixDropdown.data;
		default:
			return state;
	}
}

