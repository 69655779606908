
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import { BlocksProvider, LayersProvider, PagesProvider, SelectorsProvider, StylesProvider, TraitsProvider } from '@grapesjs/react';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import React, { useState } from 'react';
import { cx } from '../utils';
import { MdAddBox, MdBrush, MdSettings, MdLayers, MdLibraryBooks, MdDeviceHub } from 'react-icons/md';
import { PiDatabaseBold } from 'react-icons/pi'
import CustomTraitManager from './customTraitManager';
import CustomLayerManager from './customLayerManager';
import CustomBlockManager from './customBlockManager';
import CustomPageManager from './customPageManager';
import CustomSelectorManager from './customSelectorManager';
import { Button } from '@progress/kendo-react-buttons';
import DataQuery from './dataQuery';
import CustomStyleManager from './customStyleManager';

export default function RightSidebar({ className, updateTrait, updateDataSourceKeys, editor,isPanelShow,setRightPanelShow, pageChanged}) {
    const [selectedTab, setSelectedTab] = useState(isPanelShow);
    const handleSelect = (e) => {
        setSelectedTab(e.selected);
        pageChanged()
        updateTrait();
    };
    return (
      <div className={cx('gjs-right-sidebar d-flex flex-column', className)}>
        <TabStrip selected={selectedTab} onSelect={handleSelect}>
            <TabStripTab title={<Button title='Selectors' fillMode="outline"><MdBrush/></Button>}>
                <>
                    <SelectorsProvider>
                        {props => <CustomSelectorManager setRightPanelShow={setRightPanelShow} {...props}></CustomSelectorManager>}
                    </SelectorsProvider>
                    <StylesProvider>
                        {props => <CustomStyleManager {...props}></CustomStyleManager> }
                    </StylesProvider>
                </>
            </TabStripTab>
            <TabStripTab title={<Button title='Properties' fillMode="outline"><MdSettings/></Button>}>
                <TraitsProvider>
                    {props => <CustomTraitManager setRightPanelShow={setRightPanelShow} {...props} updateDataSourceKeys={updateDataSourceKeys}></CustomTraitManager>}
                </TraitsProvider>
            </TabStripTab>
            <TabStripTab contentClassName="w-100" title={<Button title='Layers' fillMode="outline"><MdLayers/></Button>}>
                <LayersProvider>
                    {props => <CustomLayerManager setRightPanelShow={setRightPanelShow} {...props}></CustomLayerManager>}
                </LayersProvider>
            </TabStripTab>
            <TabStripTab title={<Button title='Components' fillMode="outline"><MdAddBox/></Button>}>
                <BlocksProvider>
                    {props => <CustomBlockManager setRightPanelShow={setRightPanelShow} {...props}></CustomBlockManager>}
                </BlocksProvider>
            </TabStripTab>
            {/* <TabStripTab title={<Button title='Pages' fillMode="outline"><MdLibraryBooks/></Button>}>
                <PagesProvider>
                    {props => <CustomPageManager setRightPanelShow={setRightPanelShow} {...props}></CustomPageManager>}
                </PagesProvider>
            </TabStripTab> */}
            <TabStripTab title={<Button title='Data Query' fillMode="outline"><PiDatabaseBold /></Button>}>
                <DataQuery setRightPanelShow={setRightPanelShow} editor={editor}></DataQuery>
            </TabStripTab>
        </TabStrip>
      </div>
    );
}