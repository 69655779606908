
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GLOBAL } from "../../../components/Globals";

export const actionitemApi = createApi({
  reducerPath: "actionitemApi",
  baseQuery: fetchBaseQuery({
    baseUrl: window.location.origin,
  }),
  tagTypes: ["Action"],
  endpoints: (builder) => ({
    getActionItems: builder.query({
      query: ({ queryString = "" }) => {
        return GLOBAL.getActionItems + queryString;
      },
      providesTags: ["Action"],
    }),
    getActionMenuDd: builder.query({
      query: (name) => GLOBAL.getActionMenuDd + `?name=${name}`,
    }),
    getAttachment: builder.query({
      query: (actionCode) =>
        GLOBAL.getAttachment +
        `?filter[where][actionitemCode]=${actionCode}&filter[status]=Y&filter[order]=createdOn desc`,
      providesTags: ["Action"],
    }),
    getworknoteAttachment: builder.query({
      query: (actionCode) =>
        GLOBAL.getActionItemWorkNotes +
        `?filter[where][actionitemCode]=${actionCode}&filter[where][status]=Y&filter[order]=createdOn desc`,
      providesTags: ["Action"],
    }),
    getActionItemById: builder.query({
      query: (actionId) =>
        GLOBAL.getActionItemById +
        `?filter[where][actionitemId]=${actionId}&filter[include][]=status&filter[include][]=urgencyDetails&filter[include][]=impactDetails&filter[include][]=priorityDetails&filter[include][]=performers&filter[include][]=suppliers`,
      providesTags: ["Action"],
    }),
    getAuditLogs: builder.query({
      query: ({code,orderValue}) =>
        GLOBAL.getAuditLogs +
        `?filter[where][keyValue]=${code}&filter[order]=updatedOn%20${orderValue}`,
      providesTags: ["Action"],
    }),
    getActionStatus: builder.query({
      query: ({code,orderValue}) =>
        GLOBAL.getAuditLogs +
        `?filter[where][keyValue]=${code}&filter[where][fieldName]=status&filter[order]=updatedOn%20${orderValue}`,
      providesTags: ["Action"],
    }),
    getActionStatusTimeline: builder.query({
      query: (statusId) => GLOBAL.getActionStatusTimeline + `${statusId}`,
      providesTags: ["Action"],
    }),
    createActionItem: builder.mutation({
      query: ({ data }) => ({
        url: GLOBAL.getActionItems,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Action"],
    }),
    filteredActionItemList: builder.mutation({
      query: ({ data }) => ({
        url: GLOBAL.filteredActionItemList,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Action"],
    }),
    saveStatus: builder.mutation({
      query: ({ data }) => ({
        url: GLOBAL.saveStatus,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Action"],
    }),
    updateActionItem: builder.mutation({
      query: ({ data }) => ({
        url: GLOBAL.getActionItems,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Action"],
    }),
    saveActionItemAttachment: builder.mutation({
      query: ({ actionCode, attachmentData }) => ({
        url: GLOBAL.saveAttachment + `${actionCode}`,
        method: "POST",
        body: attachmentData,
      }),
      invalidatesTags: ["Action"],
    }),
    saveWorkNotes: builder.mutation({
      query: ({ actionCode, worknoteData }) => ({
        url: GLOBAL.saveWorkNotes + `${actionCode}`,
        method: "POST",
        body: worknoteData,
      }),
      invalidatesTags: ["Action"],
    }),
    deleteActionAttachment: builder.mutation({
      query: ({ data }) => ({
        url: GLOBAL.deleteActionAttachment,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["Action"],
    }),
  }),
});

export const {
  useGetActionItemsQuery,
  useGetActionMenuDdQuery,
  useCreateActionItemMutation,
  useGetAuditLogsQuery,
  useGetActionItemByIdQuery,
  useGetActionStatusTimelineQuery,
  useSaveActionItemAttachmentMutation,
  useGetAttachmentQuery,
  useUpdateActionItemMutation,
  useSaveWorkNotesMutation,
  useGetActionStatusQuery,
  useSaveStatusMutation,
  useGetworknoteAttachmentQuery,
  useDeleteActionAttachmentMutation,
  useFilteredActionItemListMutation
} = actionitemApi;

const { reducer } = actionitemApi;
export default reducer;
