
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, {useEffect, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import EditFulfillmentIndex from "./FulfillmentIndex";

export default function EditIncidentMain() {
  const [fulfillmentParams, setFulfillmentParams] = useState({itemId: ""});
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  useEffect(() => {
    setFulfillmentParams({itemId: params.itemId});
  }, []);
  useEffect(() => {
    if(params.itemId !== fulfillmentParams.itemId){
      setLoading(true);
      setTimeout(() => {
        setFulfillmentParams({itemId: params.itemId});
        setLoading(false);
      }, 200);
    }
  }, [params.itemId]);
  if(isLoading){
    return null;
  }
  return <EditFulfillmentIndex navigate={navigate} params={fulfillmentParams} />;
}
