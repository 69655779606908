
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { ErrorMessage, useField } from "formik";
import { Typeahead } from "react-bootstrap-typeahead";
import Datetime from "react-datetime";
import moment from "moment";
import Cookies from "universal-cookie";

const cookies = new Cookies();

let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));

dateformat = dateformat.split("~");
dateformat = dateformat[2];

export const TypeaheadSelect = (props) => {
  const {
    id = "",
    tr = {},
    options = [],
    selectedOptions = [],
    disabled = false,
    typedValue = "",
    onSelection = () => {},
    onCrossClick = () => {},
    errorClass = "",
    onInputChange = () => {},
    style = {},
    name = "",
  } = props || {};
  // let _typeahead;
  let crossIconStyle = style["crossIcon"] || {};
  let errorStyle = {};
  if (errorClass) {
    errorStyle = {
      border: "1px solid red",
      "border-radius": "5px",
    };
  }
 //console.log(id, selectedOptions, "selectedOptions?.length > 0 || typedValue?.length > 0 || typedValue !== " , selectedOptions?.length > 0, " ||", typedValue?.length > 0, " || ", typedValue !== "");
  return (
    <div className="dvTypehd" style={errorStyle}>
      {!disabled && (selectedOptions?.length > 0 || typedValue?.length > 0 || typedValue !== "") && (
        <span
          title="clear"
          role="img"
          aria-label="Clear"
          className="css-hakgx8 icn"
          onClick={onCrossClick}
          style={crossIconStyle}
        >
          <svg width="18" height="18" viewBox="0 0 24 24" role="presentation">
            <path
              d="M12 10.586L6.707 5.293a1 1 0 00-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 001.414 1.414L12 13.414l5.293 5.293a1 1 0 001.414-1.414L13.414 12l5.293-5.293a1 1 0 10-1.414-1.414L12 10.586z"
              fill="gray"
            ></path>
          </svg>
        </span>
      )}

      {/* <div className={errorClass ? 'dvTypehd error': 'dvTypehd'}> */}
      <Typeahead
        id={id}
        // ref={(ref) => {
        // 	_typeahead = ref;
        // }}
        disabled={disabled ? true : false}
        onChange={onSelection}
        // onBlur={this.seterrorGroupcolor}
        options={options}
        selected={selectedOptions}
        placeholder={tr["Please choose"] + "..."}
        onInputChange={onInputChange}
        multiple={false}
        // renderMenuItemChildren={(option, props) => {
        // 	let label;
        // 	let title;
        // 	label =
        // 		option.label.length > 30
        // 		? option.label.slice(0, 30) + "..."
        // 		: option.label;
        // 	title = option.label.length > 30 ? option.label : "";
        // 	if(option?.supportCompanyName){
        // 	return (
        // 		<div>
        // 		<span className="typHdmainHead" title={title} style={{'display': 'block'}}>{label}</span>
        // 		<span className="typHdmainSubHead" style={{'display': 'block', 'margin-top': '6px', 'color': '#777'}}>{option?.supportCompanyName || ''}</span>
        // 		</div>
        // 	)
        // 	}
        // 	return (
        // 	<div>
        // 		<span className="typHdmainHead" title={title}>{label}</span>
        // 	</div>
        // 	);
        // }}
      />
      {/* </div> */}
    </div>
  );
};

export const DateTimeField = (props) => {
  const {
    format = "",
    onChange = () => {},
    name = "",
    value = null,
    error = false,
  } = props;

	const handleDateChange = (date) => {
    if (moment(date).isValid()) {
      onChange(moment(date).format(`${format?.slice(0, 10)?.toUpperCase()} HH:mm:ss`));
    } else {
			onChange(null);
    }
  };

  console.log(`DateTimeField  == ${name}==`,value, value && moment(value).isValid())
  return (
    <div className="dateTimeSdiv dateTimeRightPos">
    <Datetime
      key={'nameofpicker' + name}
		  initialValue={value ? value : null}
      onChange={handleDateChange}
      locale="en"
      dateFormat={format?.slice(0, 10)?.toUpperCase()}     
      inputProps={{
        className: error ? "form-control error" : "form-control",
        name,       
        autoComplete: "off",
      }}    

      value={
        value && moment(value).isValid()
          ? moment(value, format?.slice(0, 10)?.toUpperCase() + " HH:mm:ss")
          : null
      }
    />
    </div>
  );
};

export const DatePickerCMDB = ({
  label,
  name='',
  required,
  disabled,
	format = "",
  value = "",
  showTime = true,
  ...props
}) => {
  const [field, meta] = useField(name);
  const showError = meta.touched && !!meta.error;
  const jwtdf = format?.slice(0, 10).toUpperCase();

  return (
    <div className="dateTimeSdiv dateTimePosTop">
      <label className={`select-label ${showError ? "is-invalid" : ""}`}>
        {label} {required && <span className="rStar"></span>}
      </label>
      <Datetime
        {...props}
        dateFormat={jwtdf}
				timeFormat={true}
        inputProps={{
					name,
          className: `form-control ${showError ? "is-invalid" : ""}`,
          disabled         
        }} 
        value={moment(value, jwtdf + " HH:mm:ss") || ""}
      />
      {showError && (
        <ErrorMessage
          name={name}
          component="div"
          className="invalid-feedback text-right"
        />
      )}
    </div>
  );
};
