
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import { navigationHooks } from '../../helpers/NavigationHook';
import Swal from 'sweetalert2';
const api = axios.create({
    headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});
  

export const getDepartmentList = (param) => {
    return (dispatch) => {
      dispatch({ type: 'FDN_DATA_FETCHING'})
      api.get("/api/department")
          .then((response) => {
            console.log("DepartAction1",response.data)
          dispatch({ type: 'DEPARTMENT_LIST_FETCHED_SUCCESSFULLY', payload: response.data});
          dispatch({ type: 'FDN_DATA_FETCHED_SUCCESS'})
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: 'FDN_DATA_FETCHED_SUCCESS'})
        });
    };
};


export function saveDepartmentData(data) {
    return (dispatch) => {
        api.post("/api/saveDepartment", data)
            .then((response) => {
              console.log("respomseDept+++",response);
              if (response.status == 201) {
                if (response.data.nameAlreadyExist === 'Name already exists') {
                  Swal.fire({
                    title: 'Department already exists',
                  })
                } else {
                  navigationHooks.navigate("/department");
                }
                } 
            })
            .catch((err) => {
                console.log("DepartmntEror", err)
            });
    };
}

export const getOrganizationOptions = (companyId) => {
    return (dispatch) => {
      api.get("/api/getInitiallistDepartmentOrg/"+companyId)
          .then((response) => {
            console.log("Depatmnt",response)
            let object = [];
            const menuListoptions = response.data.listOrganization;
            if (typeof menuListoptions !== "undefined" && menuListoptions.length > 0) {
              menuListoptions.forEach((values) => {
                let val = { id: values.field1Value, label: values.field1Key };
                object.push(val);
              });
              }
            console.log("DepatResp", response.data);
            
            dispatch({ type: 'ORGANIZATION_DROPDOWN_FETCHED_SUCCESSFULLY', payload: object });
        })
        .catch((err) => {
          console.log(err);
        });
    };
};

export const resetOrganizationData = () => {
  return (dispatch) => {
        dispatch({ type: 'ORGANIZATION_DROPDOWN_FETCHED_SUCCESSFULLY', payload: [] });
  };
};
export const getDepartmentPageDetails = (id) => {
  return (dispatch) => {
      dispatch({ type: 'FDN_DATA_FETCHING'})
      api.get("/api/editDepartment/"+id)
        .then((response) => {
          dispatch({ type: 'FDN_DATA_FETCHED_SUCCESS'})
            //   let companyName = response.data.companiesMap[response.data.costcenter.company_name]
              console.log("CheckEditDepartment",response)
              let data = {
                  ...response.data.department,
                  company_name: response.data.department.company_name,
                  companyId: response.data.department.companyId
            }

            dispatch({ type: 'DEPARTMENT_EDIT_DETAILS_FETCHED', payload: data });

        })
        .catch((err) => {
          dispatch({ type: 'FDN_DATA_FETCHED_FAILED'})
          console.log(err);
        });
    };
};

export function updateDepartmentAction(data) {
    return (dispatch) => {
        api.post("/api/updateDepartment", data)
            .then((response) => {
              console.log("respomse+++",response);
              if (response.status == 201) {
                if (response.data.nameAlreadyExist === 'Name already exists') {
                  Swal.fire({
                    title: 'Department already exists',
                  })
                } else {
                  navigationHooks.navigate("/department");
                }
                } 
            })
            .catch((err) => {
              console.log("EditDept",err);
            });
    };
  }

export const deleteDepartmentData = (id) => {
    return (dispatch) => {
      api.delete("/api/deleteDepartment/"+id)
        .then((response) => {
          Swal.fire({
            title: 'Record deleted successfully',
          }).then(result => {
            if (result.isDismissed || result.isConfirmed) {
              navigationHooks.navigate('/department')
            }
          })
        })
        .catch((err) => {
          console.log(err);
        });
    };
  };
