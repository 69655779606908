
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useCallback, useEffect, useState } from "react";
import { Button, Accordion } from "react-bootstrap";
import GenralInformation from "./GenralInformation";
import DiscussionPoint from "./DiscussionPoints";
import Recordings from "./Recordings";
import ActionItems from "./ActionDecide";
import {IoClose} from "react-icons/io5";
import { useGetMinutesDetailsQuery } from "../../../../../../reducers/governance/meeting/meeting";

const Minutes = ({ occurenceData, companyId, isAuthorized, activeTabs }) => {
  const [orderby, setOrderby] = useState("asc");
  const [sortedBy, SetSortedBy] = useState("genralInfo");
  const [activeKey, SetActiveKey] = useState("");

  const meetingOccurrananceId = occurenceData?.meetingOccurrananceId;
 
  useEffect(()=>{
    SetActiveKey("");
  }, [activeTabs])

  const sorting = useCallback(() => {
    setOrderby(orderby === "desc" ? "asc" : "desc");
  }, [orderby]);

  const toggle = (id) => {
    SetSortedBy(id);
  };

  const {
    data: discussionPointsData,
    isFetching: discussionsFetching,
    refetch: refetchDicison,
  } = useGetMinutesDetailsQuery(
    {
      queryString: `?occurrenceId=${meetingOccurrananceId}&tenantId=${companyId}&sortOrder=${orderby}&sortedByParameter=${sortedBy}`,
    },
    {
      skip: !(companyId && meetingOccurrananceId),
    }
  );

  return (
    <div className="stickyArea rBoxStyle">
      <div className="rPageHeading d-flex p-3">
        <div className="flex-grow-1 offNam mw-100">MINUTES</div>
        {/* <div>
          <IoClose />
        </div> */}
      </div>

      <div className="p-3 occurnance">
        <Accordion
          accordion
          id="rightSidePane-occurrence"
          defaultActiveKey=""
          activeKey={activeKey}
          className=""
        >
          <Accordion.Item eventKey="0" className="mb-3">
            <Accordion.Header
              onClick={(e) => {
                toggle("genralInfo");
                SetActiveKey(activeKey === "0" ? '' : "0");
              }}
            >
              General Information
            </Accordion.Header>
            <Accordion.Body>
              <GenralInformation
                occurenceData={occurenceData}
                companyID={companyId}
                orderby={orderby}
                sorting={sorting}
                refetchlist={refetchDicison}
                isFetching={discussionsFetching}
                data={discussionPointsData?.generalInfo}
                definationId={meetingOccurrananceId}
                isAuthorized={isAuthorized}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1" className="mb-3">
            <Accordion.Header
              onClick={(e) => {
                toggle("discussionPoints");
                SetActiveKey(activeKey === "1" ? '' : "1");
              }}
            >
              Decisions
            </Accordion.Header>
            <Accordion.Body>
              <DiscussionPoint
                occurenceData={occurenceData}
                companyId={companyId}
                orderby={orderby}
                sorting={sorting}
                refetchDicison={refetchDicison}
                discussionsFetching={discussionsFetching}
                discussionPoints={discussionPointsData?.discussionPoints}
                activeTabs={activeTabs}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2" className="mb-3">
            <Accordion.Header
              onClick={(e) => {
                toggle("actiondecided");
                SetActiveKey(activeKey === "2" ? '' : "2");
              }}
            >
              Action
            </Accordion.Header>
            <Accordion.Body>
              <ActionItems
                companyID={companyId}
                occurenceData={occurenceData}
                definationId={meetingOccurrananceId}
                activeTabs={activeTabs}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3" className="mb-3">
            <Accordion.Header
              onClick={(e) => {
                toggle("recording");
                SetActiveKey(activeKey === "3" ? '' : "3");
              }}
            >
              View Recordings
            </Accordion.Header>
            <Accordion.Body className="p-2">
              <Recordings
                data={discussionPointsData?.recording}
                sorting={sorting}
                companyID={companyId}
                discussionsFetching={discussionsFetching}
                refetchlist={refetchDicison}
                occurenceData={occurenceData}
                definationId={meetingOccurrananceId}
                activeTabs={activeTabs}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default Minutes;
