
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export default function (state = initialState.breakfixQuickViewFilters, action){
  let newselectedgroupsarray = [];
  let newselectedprioritiesarray = [];
  let newselectedtagsarray = [];
  let newselectedstatusarray = [];

	switch (action.type){
    case 'FETCH_GROUP_LIST':
      return Object.assign({}, state, {groups: action.payload.data});
    case 'FETCH_FLFLMT_GROUP_LIST':
      return Object.assign({}, state, { fulfillmentGroups: action.payload.data });
    case 'FETCH_TASK_GROUP_LIST':
      return Object.assign({}, state, { taskGroups: action.payload.data });
    case 'FETCH_PRIORITY_LIST':
      return Object.assign({}, state, {priority: action.payload.data});
    case 'REMOVE_FROM_SELECTED_GROUP_LIST':
			newselectedgroupsarray = state.selectedgroups.filter((item) => {return item!=action.payload;});
			return Object.assign({}, state, {selectedgroups: newselectedgroupsarray});
		case 'ADD_TO_SELECTED_GROUP_LIST':
			newselectedgroupsarray = state.selectedgroups.concat(action.payload);
			return Object.assign({}, state, {selectedgroups: newselectedgroupsarray});
    case 'REMOVE_FROM_SELECTED_FLFLMT_GROUP_LIST':
			newselectedgroupsarray = state.selectedFulfillmentGroups.filter((item) => {return item!=action.payload;});
			return Object.assign({}, state, {selectedFulfillmentGroups: newselectedgroupsarray});
		case 'ADD_TO_SELECTED_FLFLMT_GROUP_LIST':
			newselectedgroupsarray = state.selectedFulfillmentGroups.concat(action.payload);
			return Object.assign({}, state, {selectedFulfillmentGroups: newselectedgroupsarray});
		case 'REMOVE_FROM_SELECTED_PRIORITY_LIST':
			newselectedprioritiesarray = state.selectedpriorities.filter((item) => {return item!=action.payload;});
			return Object.assign({}, state, {selectedpriorities: newselectedprioritiesarray});
		case 'ADD_TO_SELECTED_PRIORITY_LIST':
			newselectedprioritiesarray = state.selectedpriorities.concat(action.payload);
			return Object.assign({}, state, {selectedpriorities: newselectedprioritiesarray});
    case 'REMOVE_FROM_SELECTED_TAGGED_LIST':
      // console.log(state.selectedTags);
      // console.log(action.payload);
			newselectedtagsarray = state.selectedTags.filter((item) => {return item!=action.payload;});
			return Object.assign({}, state, {selectedTags: newselectedtagsarray});
    case 'REMOVE_FROM_SELECTED_FLFLMT_TAGGED_LIST':
      newselectedtagsarray = state.selectedFulfillmentTags.filter((item) => {return item!=action.payload;});
      return Object.assign({}, state, {selectedFulfillmentTags: newselectedtagsarray});
		case 'ADD_TO_SELECTED_STATUS_LIST':
			newselectedstatusarray = state.selectedStatus.concat(action.payload);
			return Object.assign({}, state, {selectedStatus: newselectedstatusarray});
		case 'REMOVE_FROM_SELECTED_STATUS_LIST':
			newselectedstatusarray = state.selectedStatus.filter((item) => {return item!=action.payload;});
			return Object.assign({}, state, {selectedStatus: newselectedstatusarray});
    case 'ADD_TO_SELECTED_FLFLMT_STATUS_LIST':
      newselectedstatusarray = state.selectedFulfillmentStatus.concat(action.payload);
      return Object.assign({}, state, {selectedFulfillmentStatus: newselectedstatusarray});
    case 'REMOVE_FROM_SELECTED_FLFLMT_STATUS_LIST':
			newselectedstatusarray = state.selectedFulfillmentStatus.filter((item) => {return item!=action.payload;});
			return Object.assign({}, state, {selectedFulfillmentStatus: newselectedstatusarray});
		case 'ADD_TO_SELECTED_TAGGED_LIST':
    // console.log(state.selectedTags);
    // console.log(action.payload);
			newselectedtagsarray = state.selectedTags.concat(action.payload);
			return Object.assign({}, state, {selectedTags: newselectedtagsarray});
    case 'ADD_TO_SELECTED_FLFLMT_TAGGED_LIST':
      newselectedtagsarray = state.selectedFulfillmentTags.concat(action.payload);
      return Object.assign({}, state, {selectedFulfillmentTags: newselectedtagsarray});
    case 'MARK_ALL_GROUPS_SELECTED':
			return Object.assign({}, state, {selectedgroups: action.payload});
		case 'MARK_ALL_GROUPS_UNSELECTED':
			return Object.assign({}, state, {selectedgroups: []});
    case 'MARK_ALL_FLFLMT_GROUPS_SELECTED':
			return Object.assign({}, state, {selectedFulfillmentGroups: action.payload});
		case 'MARK_ALL_FLFLMT_GROUPS_UNSELECTED':
			return Object.assign({}, state, {selectedFulfillmentGroups: []});
    case 'MARK_ALL_PRIORITIES_SELECTED':
      return Object.assign({}, state, {selectedpriorities: action.payload});
    case 'MARK_ALL_PRIORITIES_UNSELECTED':
      return Object.assign({}, state, {selectedpriorities: []});
    case 'SET_BF_QV_SEARCH_OPTION':
      return Object.assign({}, state, {searchOption: action.payload});
    case 'SET_BF_QV_SEARCH_TEXT':
      return Object.assign({}, state, {searchText: action.payload});
    case 'SET_BF_QV_ORDER_BY':
      return Object.assign({}, state, {orderBy: action.payload});
    case 'SET_BF_QV_SORT_BY':
      return Object.assign({}, state, {sortBy: action.payload});
    case 'SET_FFMT_QV_SEARCH_OPTION':
      return Object.assign({}, state, {searchOptionForFulfillment: action.payload});
    case 'SET_FFMT_QV_SEARCH_TEXT':
      return Object.assign({}, state, {searchTextForFulfillment: action.payload});
    case 'SET_FFMT_QV_ORDER_BY':
      return Object.assign({}, state, {orderByForFulfillment: action.payload});
    case 'SET_FFMT_QV_SORT_BY':
      return Object.assign({}, state, {sortByForFulfillment: action.payload});

    //Investigation
    case 'ADD_TO_SELECTEDINV_STATUS_LIST':
      newselectedstatusarray = state.selectedInvestigationStatus.concat(action.payload);
			return Object.assign({}, state, {selectedInvestigationStatus: newselectedstatusarray});
		case 'REMOVE_FROM_SELECTEDINV_STATUS_LIST':
			newselectedstatusarray = state.selectedInvestigationStatus.filter((item) => {return item!=action.payload;});
			return Object.assign({}, state, {selectedInvestigationStatus: newselectedstatusarray});

    case 'ADD_TO_SELECTEDINV_TAGGED_LIST':
    // console.log(state.selectedTags);
    // console.log(action.payload);
  	   newselectedtagsarray = state.selectedInvestigationTags.concat(action.payload);
  	   return Object.assign({}, state, {selectedInvestigationTags: newselectedtagsarray});

  	case 'REMOVE_FROM_SELECTEDINV_TAGGED_LIST':
      // console.log(state.selectedTags);
      // console.log(action.payload);
  		newselectedtagsarray = state.selectedInvestigationTags.filter((item) => {return item!=action.payload;});
  		return Object.assign({}, state, {selectedInvestigationTags: newselectedtagsarray});

      case 'FETCH_INVST_GROUP_LIST':
          return Object.assign({}, state, {investigationGroups: action.payload.data});

      case 'MARK_ALL_INV_GROUPS_SELECTED':
        return Object.assign({}, state, {selectedInvestigationGroups: action.payload});
      case 'MARK_ALL_INV_GROUPS_UNSELECTED':
      	return Object.assign({}, state, {selectedInvestigationGroups: []});

      case 'ADD_TO_SELECTED_INV_GROUP_LIST':
        //alert("ADD_TO_SELECTED_INV_GROUP_LIST")
      	newselectedgroupsarray = state.selectedInvestigationGroups.concat(action.payload);
        //alert(newselectedgroupsarray)
      	return Object.assign({}, state, {selectedInvestigationGroups: newselectedgroupsarray});
      case 'REMOVE_FROM_SELECTED_INV_GROUP_LIST':
    		newselectedgroupsarray = state.selectedInvestigationGroups.filter((item) => {return item!=action.payload;});
    		return Object.assign({}, state, {selectedInvestigationGroups: newselectedgroupsarray});

    //Investigation end

    //Change start
    case 'ADD_TO_SELECTEDCHG_STATUS_LIST':
      newselectedstatusarray = state.selectedChangeStatus.concat(action.payload);
			return Object.assign({}, state, {selectedChangeStatus: newselectedstatusarray});
		case 'REMOVE_FROM_SELECTEDCHG_STATUS_LIST':
			newselectedstatusarray = state.selectedChangeStatus.filter((item) => {return item!=action.payload;});
			return Object.assign({}, state, {selectedChangeStatus: newselectedstatusarray});

    case 'ADD_TO_SELECTEDCHG_TAGGED_LIST':
    // console.log(state.selectedTags);
    // console.log(action.payload);
  	   newselectedtagsarray = state.selectedChangeTags.concat(action.payload);
  	   return Object.assign({}, state, {selectedChangeTags: newselectedtagsarray});

  	case 'REMOVE_FROM_SELECTEDCHG_TAGGED_LIST':
      // console.log(state.selectedTags);
      // console.log(action.payload);
  		newselectedtagsarray = state.selectedChangeTags.filter((item) => {return item!=action.payload;});
  		return Object.assign({}, state, {selectedChangeTags: newselectedtagsarray});

    case 'FETCH_CHANGE_GROUP_LIST':
          return Object.assign({}, state, {changeGroups: action.payload.data});

    /*case 'MARK_ALL_INV_GROUPS_SELECTED':
        return Object.assign({}, state, {selectedInvestigationGroups: action.payload});
    case 'MARK_ALL_INV_GROUPS_UNSELECTED':
      	return Object.assign({}, state, {selectedInvestigationGroups: []});
    */
    case 'ADD_TO_SELECTED_CHG_GROUP_LIST':
        //alert("ADD_TO_SELECTED_INV_GROUP_LIST")
      	newselectedgroupsarray = state.selectedChangeGroups.concat(action.payload);
        //alert(newselectedgroupsarray)
      	return Object.assign({}, state, {selectedChangeGroups: newselectedgroupsarray});
    case 'REMOVE_FROM_SELECTED_CHG_GROUP_LIST':
    		newselectedgroupsarray = state.selectedChangeGroups.filter((item) => {return item!=action.payload;});
    		return Object.assign({}, state, {selectedChangeGroups: newselectedgroupsarray});

    //Change end

    //Task start
    case 'ADD_TO_SELECTEDTASK_STATUS_LIST':
      newselectedstatusarray = state.selectedTaskStatus.concat(action.payload);
			return Object.assign({}, state, {selectedTaskStatus: newselectedstatusarray});
		case 'REMOVE_FROM_SELECTEDTASK_STATUS_LIST':
			newselectedstatusarray = state.selectedTaskStatus.filter((item) => {return item!=action.payload;});
			return Object.assign({}, state, {selectedTaskStatus: newselectedstatusarray});

    case 'ADD_TO_SELECTEDTASK_TYPE_LIST':
      newselectedstatusarray = state.selectedTaskTypeStatus.concat(action.payload);
      return Object.assign({}, state, {selectedTaskTypeStatus: newselectedstatusarray});
    case 'REMOVE_FROM_SELECTEDTASK_TYPE_LIST':
      newselectedstatusarray = state.selectedTaskTypeStatus.filter((item) => {return item!=action.payload;});
      return Object.assign({}, state, {selectedTaskTypeStatus: newselectedstatusarray});
  
    /*case 'ADD_TO_SELECTEDCHG_TAGGED_LIST':
    // console.log(state.selectedTags);
    // console.log(action.payload);
  	   newselectedtagsarray = state.selectedChangeTags.concat(action.payload);
  	   return Object.assign({}, state, {selectedChangeTags: newselectedtagsarray});

  	case 'REMOVE_FROM_SELECTEDCHG_TAGGED_LIST':
      // console.log(state.selectedTags);
      // console.log(action.payload);
  		newselectedtagsarray = state.selectedChangeTags.filter((item) => {return item!=action.payload;});
  		return Object.assign({}, state, {selectedChangeTags: newselectedtagsarray});

    case 'FETCH_CHANGE_GROUP_LIST':
          return Object.assign({}, state, {changeGroups: action.payload.data});*/

    //Task end
    case 'RESET_BF_QV_FILTERS':
      return Object.assign(
        {},
        state,
        {
          selectedgroups:[],
          selectedpriorities:[],
          selectedTags:[],
          selectedStatus:[],
          searchText: '',
          searchOption: '10',
          sortBy:'breakfixId',
          orderBy:'desc',

          selectedFulfillmentGroups:[],
          selectedFulfillmentTags:[],
          selectedFulfillmentStatus:[],

          selectedInvestigationStatus:[],
          selectedInvestigationGroups:[],
          selectedInvestigationTags:[],

          selectedChangeStatus:[],
          selectedChangeGroups:[],
          selectedChangeTags:[],

          selectedTaskStatus:[],
          selectedTaskTypeStatus:[]


        }
      );
		default:
				return state;
		}
}
