
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import {
  Row,
  Col,
  Form,
  Radio,
  ButtonToolbar,
  Button,
} from "react-bootstrap";
import { connect } from "react-redux";
import { _dropDown, _checkbox } from "../../common/formFields";
import { Field, reduxForm, SubmissionError, change } from "redux-form";
import {
  getReminderNotificationQuestions,
  getReminderNotifications,
  postReminderNotifications,
  patchReminderNotifications,
} from "../../../actions/spcmActions";
import {ImSpinner6} from "react-icons/im";
// import { initial } from "underscore";

const reminderNotificationInitiationOptions = [
  { text: "30 Days", value: "30" },
  { text: "20 Days", value: "20" },
  { text: "15 Days", value: "15" },
  { text: "10 Days", value: "10" },
  { text: "5 Days", value: "5" },
];
const reminderNotificationIntervalOptions = [
  { text: "Fortnightly", value: "14" },
  { text: "Weekly", value: "7" },
  { text: "Alternate Days", value: "2" },
  { text: "Daily", value: "1" },
];

const validate = (values) => {
  let errors = {};

  console.log("validate method running");
  console.log(values);

  if (!values.reminderNotificationEndDate) {
    errors.reminderNotificationEndDate = "Select PI Question";
  }
  if (!values.reminderNotificationInitiation) {
    errors.reminderNotificationInitiation = "Select Days before End Date";
  }
  if (!values.reminderNotificationInterval) {
    errors.reminderNotificationInterval = "Select Frequency";
  }
  // console.log('errors');
  // console.log(errors);

  return errors;
};

let Notifications = class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationEnabled: "",
    };
    this.renderQuestions = this.renderQuestions.bind(this);
    this.saveReminder = this.saveReminder.bind(this);
    this.onChangeFlag = this.onChangeFlag.bind(this);
  }

  componentWillMount() {
    console.log("toCheck", this.props.initialValues);
    console.log('this.state.notificationEnabled+++++++',this.state.notificationEnabled);

    this.props.getReminderNotifications(
      this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID
    );
    this.props.getReminderNotificationQuestions(
      this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID
    );
  }

  renderQuestions(spcmReminderNotificationQuestions) {
    return spcmReminderNotificationQuestions.map((question, i) => {
      return (
        <option key={i} value={question.QUESTION_ID}>
          {question.QUESTION_TEXT}
        </option>
      );
    });
  }

  saveReminder(values) {
    console.log("values====", values);
    let check = "";
    if (values.notificationEnabled == true) check = false;
    else check = true;
    if (this.props.spcmReducer.spcmReminderNotifications.length > 0) {
      //patch
      let data = {
        offeringIdSx: this.props.spcmReducer.currentlySelectedServiceDetails
          .OFFERING_ID,
        offeringNameSx: this.props.spcmReducer.currentlySelectedServiceDetails
          .OFFERING_NAME,
        questionId: values.reminderNotificationEndDate,
        questionText: values.reminderNotificationEndDateText,
        duration: values.reminderNotificationInitiation,
        frequencyId: values.reminderNotificationInterval,
        frequency: values.reminderNotificationIntervalText,
        notificationEnabled: check,
        companyId: this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID
      };
      console.log("data=====", data);
      this.props.patchReminderNotifications(
        this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
        data,
        () => {
          this.props.boardPageInActive4(false);
        }
      );
    } else {
      //post
      let data = {
        offeringIdSx: this.props.spcmReducer.currentlySelectedServiceDetails
          .OFFERING_ID,
        offeringNameSx: this.props.spcmReducer.currentlySelectedServiceDetails
          .OFFERING_NAME,
        questionId: values.reminderNotificationEndDate,
        questionText: values.reminderNotificationEndDateText,
        duration: values.reminderNotificationInitiation,
        frequencyId: values.reminderNotificationInterval,
        frequency: values.reminderNotificationIntervalText,
        notificationEnabled: check,
        companyId: this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID
      };
      // console.log('data=====',data);
      this.props.postReminderNotifications(data, () => {
        this.props.boardPageInActive4(false);
      });
    }
  }

  onChangeFlag(e) {
    console.log('e.target.checked==========',e.target.checked);
    this.setState({ notificationEnabled: e.target.checked });
  }

  render() {
    const tr = this.props.tr;
    const { error, handleSubmit } = this.props;
    // console.log(
    //   "this.props.spcmReducer.spcmReminderNotifications=========",
    //   this.props.spcmReducer.spcmReminderNotifications
    // );
    console.log('this.props.spcmReducer.currentlySelectedServiceDetails=========',this.props.spcmReducer.currentlySelectedServiceDetails);
    let checkedValue;
    console.log('this.state.notificationEnabled==========',this.state.notificationEnabled);
    console.log('this.props.initialValues.notificationEnabled==========',this.props.initialValues.notificationEnabled);
   
    if(this.state.notificationEnabled === true){
      console.log("entered in true");
      checkedValue = true;
    } 
    else if(this.state.notificationEnabled === false ){
      console.log("entered in false");
      checkedValue = false;
    }  else if(this.state.notificationEnabled == "" && (this.props.initialValues.notificationEnabled == undefined || this.props.initialValues.notificationEnabled == false)){
      console.log("entered in false space");
      checkedValue = true;
     
    }else if((this.state.notificationEnabled == "" && this.props.initialValues.notificationEnabled == true)){
      checkedValue = false;
      console.log("entered in true space");
    }
    console.log('checkedValue==========',checkedValue);

    return (
      <div className="rBoxGap">
        <Row>
          <Col md={12} className="dCheckbox customCheckBoxContainer">
            <Field
              component={_checkbox}
              className="form-control"
              name="notificationEnabled"
              label={tr["Disable Reminder Notification"]}
              onChange={(e) => {
                this.onChangeFlag(e);
              }}
              checked={ checkedValue
                // this.state.notificationEnabled === ""
                //   ? this.props.initialValues.notificationEnabled == undefined
                //     ? false
                //     : this.props.initialValues.notificationEnabled == false
                //   : this.state.notificationEnabled
              }
            />
          </Col>
          <Col className="margin-t-15" md={12}>
            <Form.Group className="form-group">
              <Form.Label bsClass="">
                <span className="rStar"></span>{" "}
                {tr["Reminder Notification End Date"]}
              </Form.Label>
              {/* <FormControl componentClass="select">
								<option value="">Select PI Question</option>
								{this.renderQuestions(this.props.spcmReducer.spcmReminderNotificationQuestions)}
							</FormControl> */}
                         
                <Field
                  component={_dropDown}
                  name="reminderNotificationEndDate"
                  options={
                    this.props.spcmReducer.spcmReminderNotificationQuestions
                  }
                  className="form-control"
                  onChange={(event) => {
                    this.props.dispatch(
                      change(
                        "reminderNotificationsForm",
                        "reminderNotificationEndDateText",
                        event.target[event.target.selectedIndex].text
                      )
                    );
                  }}
                  disabled = {checkedValue
                    // this.state.notificationEnabled === ""
                    //   ? this.props.initialValues.notificationEnabled == undefined
                    //     ? false
                    //     : this.props.initialValues.notificationEnabled == false
                    //   : this.state.notificationEnabled
                  }
                />

            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group  className="form-group">
              <Form.Label bsClass="">
                <span className="rStar"></span>{" "}
                {tr["Reminder Notification Initiation"]}
              </Form.Label> 
                <Field
                  component={_dropDown}
                  name="reminderNotificationInitiation"
                  options={reminderNotificationInitiationOptions}
                  className="form-control"
                  disabled = {checkedValue
                    // this.state.notificationEnabled === ""
                    //   ? this.props.initialValues.notificationEnabled == undefined
                    //     ? false
                    //     : this.props.initialValues.notificationEnabled == false
                    //   : this.state.notificationEnabled
                  }
                />
            

              {/* <FormControl componentClass="select">
								<option value="">Select Days before End Date</option>
								<option value="30">30 Days</option>
								<option value="20">20 Days</option>
								<option value="15">15 Days</option>
								<option value="10">10 Days</option>
								<option value="5">5 Days</option>
							</FormControl> */}
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group className="form-group">
              <Form.Label bsClass="">
                <span className="rStar"></span>{" "}
                {tr["Reminder Notification Interval"]}
              </Form.Label>    
                  <Field
                  component={_dropDown}
                  name="reminderNotificationInterval"
                  options={reminderNotificationIntervalOptions}
                  className="form-control"
                  onChange={(event) => {
                    this.props.dispatch(
                      change(
                        "reminderNotificationsForm",
                        "reminderNotificationIntervalText",
                        event.target[event.target.selectedIndex].text
                      )
                    );
                  }}
                  disabled = { checkedValue
                    // this.state.notificationEnabled === ""
                    //   ? this.props.initialValues.notificationEnabled == undefined
                    //     ? false
                    //     : this.props.initialValues.notificationEnabled == false
                    //   : this.state.notificationEnabled
                  }
                />
             
              {/* <FormControl componentClass="select">
								<option value="">Select Frequency</option>
								<option value="Fortnightly">Fortnightly</option>
								<option value="Weekly">Weekly</option>
								<option value="Alternate Days">Alternate Days</option>
								<option value="Daily">Daily</option>
							</FormControl> */}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <ButtonToolbar className="black">
              <Button
                bsSize="small"
                bsStyle="primary"
                onClick={handleSubmit(this.saveReminder)}
                disabled={this.props.spcmReducer.spcmPostingAttribute}
                className="rgSidrkBtn"
              >
                {this.props.spcmReducer.spcmPostingAttribute ? (
                  <ImSpinner6 className="icn-spinner"/>
                ) : null}
                {tr["Save"]}
              </Button>
              <Button
                bsSize="small"
                bsStyle="text"
                className="rgSiLigBtn"
                onClick={() => {
                  this.props.boardPageInActive4(false);
                }}
              >
                {tr["Close"]}
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>
      </div>
    );
  }
};

Notifications = reduxForm({
  form: "reminderNotificationsForm",
  validate,
  enableReinitialize: true,
})(Notifications);

const mapStateToProps = ({ spcmReducer }) => {
  let initialValues = {};
  if (spcmReducer.spcmReminderNotifications.length > 0) {
    let obj =
      spcmReducer.spcmReminderNotifications[
        spcmReducer.spcmReminderNotifications.length - 1
      ];
    if (
      spcmReducer.spcmReminderNotificationQuestions &&
      spcmReducer.spcmReminderNotificationQuestions.length > 0
    ) {
      let flag = 0;
      spcmReducer.spcmReminderNotificationQuestions.forEach((element) => {
        if (element.QUESTION_ID == obj.questionId) flag = 1;
      });
      if (flag == 1) {
        initialValues.reminderNotificationEndDate = obj.questionId;
        initialValues.reminderNotificationEndDateText = obj.questionText;
        initialValues.reminderNotificationInitiation = obj.duration;
        initialValues.reminderNotificationInterval = obj.frequencyId;
        initialValues.notificationEnabled = obj.notificationEnabled;
      }
    }
  } else {
    initialValues = {};
  }
  return {
    spcmReducer,
    initialValues: initialValues,
  };
};

export default connect(mapStateToProps, {
  getReminderNotificationQuestions,
  getReminderNotifications,
  postReminderNotifications,
  patchReminderNotifications,
})(Notifications);
