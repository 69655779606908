
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef } from "react";
import { formatQuery } from "react-querybuilder";
import { ListGroup, Row, Col, Tabs, Tab, Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { PiPencilSimpleBold } from "react-icons/pi";
import { IoClose } from "react-icons/io5";


const GdsShortDescriptionList = ({ rowData, setIsEditDetails }) => {
  const translator = useSelector((state) => state.spcmReducer.tr);
  const closeButton = useRef(null);
  const editButton = useRef(null);

  // useEffect(() => {
  //   context.keyDownHandler(closeButton.current,editButton.current,"","");
  // }, []);
  console.log(rowData);

  return (
    <div className="stickyArea rBoxStyle">
      <Row>
        <Col xs={12}>
          <div className="rPageHeadActBtn">
            <ul>
              {/* <li>
                <Link
                  to={`/editRule/${rowData?.id}`}
                  title={translator["GDS"]}
                  ref={editButton} 
                >
                  <PiPencilSimpleBold />
                </Link>
              </li> */}
              <li>
                <Link to="javascript:void(0)"
                  title={translator["Minimize the details panel"]}
                  bsClass=""
                  onClick={() => setIsEditDetails(false)}
                  ref={closeButton}
                >
                  <IoClose />
                </Link>
              </li>
            </ul>
          </div>
          <div className="rPageHeading py-3">{rowData?.job_name}</div>
        </Col>
      </Row>
      <div className="rBoxGap">
        <div className="rBoxTabs hideTabNav">
          <Tabs id="tabId">
            <Tab eventKey={1} title="Pull Golden Dataset Details">
              <ListGroup bsPrefix=" " bsClass="">
                <ListGroup.Item
                  bsPrefix=" "
                  bsClass=""
                  className="rwTable"
                  style={{ "border-top": "none" }}
                >
                  <span className="rwKey">Job Name</span>
                  <span className="rwVal text-capitalize">
                    {rowData?.job_name || "--"}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                  <span className="rwKey">{translator["Company"]} </span>
                  <span className="rwVal text-capitalize">
                    {rowData?.company_name || "--"}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                  <span className="rwKey">
                    {translator["Job Status"] || "Job Status"}
                  </span>
                  <span className="rwVal text-capitalize">
                    {rowData?.job_status || "--"}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                  <span className="rwKey">
                    {translator["Started Date"] || "Started Date"}
                  </span>
                  <span className="rwVal text-capitalize">
                    {rowData?.started_date}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                  <span className="rwKey">
                    {translator["Completed Date"] || "Completed Date"}
                  </span>
                  <span className="rwVal text-capitalize">
                    {rowData?.completed_date}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                  <span className="rwKey">
                    {translator["Processed Count"] || "Processed Count"}
                  </span>
                  <span className="rwVal text-capitalize">
                    {rowData?.processed_count || "--"}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                  <span className="rwKey">
                   Total Count
                  </span>
                  <span className="rwVal text-capitalize">
                    {rowData?.total_count || "--"}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                  <span className="rwKey">
                    {translator["Class Name"] || "Class Name"}
                  </span>
                  <span className="rwVal text-capitalize">
                    {rowData?.class_name || "--"}
                  </span>
                </ListGroup.Item>
              </ListGroup>
            </Tab>
          </Tabs>
         
        </div>
      </div>
    </div>
  );
};

export default GdsShortDescriptionList;
