
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import PopUpAuditLog from '../../../common/WorkItemBoard/breakfixAuditLog';
import PopUpActivityLog from '../../../common/WorkItemBoard/breakfixActivityLog';
import {IoClose} from "react-icons/io5";
import WIBNotifcation from '../../WIBNotification'

const MoreArrow = <i title="More" className="fa fa-angle-double-down f-size-15" ></i>;


class TabPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabKey: 1,
			isOpen: true,
			message: '',
			messageactivity: '',
			taskeditFlag: '0',
			taskcode: ''            
        };
        this.handleSelect = this.handleSelect.bind(this);
		this.changeCompMessage = this.changeCompMessage.bind(this);
		this.changeCompMessageactivity = this.changeCompMessageactivity.bind(this);
		this.clicktaskEdit = this.clicktaskEdit.bind(this);
		this.onpopupclick = this.onpopupclick.bind(this);        
    }
    handleSelect(tabKey) {
        this.setState({ tabKey });
    }

	clicktaskEdit(flag, taskCode, regId) {
		//alert("iclick::::"+i+"j::::"+j)
		this.setState({
			taskeditFlag: flag,
			taskcode: taskCode,
			taskregid: regId
		});

	}

	changeCompMessage(message) {
		this.setState({ message });
	}
	changeCompMessageactivity(messageactivity) {
		this.setState({ messageactivity });
	}
	onpopupclick() {

		this.setState({
			taskeditFlag: "0"
		});
	}    
    render() {
        console.log("Render_tabPage", this.props.taskDetails);
        return (
            <div>
                <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
                    <ul>
                        <li>
                        <button
                            type='button'
                            title="Minimize the right panel"
                            bsClass=""
                            bsStyle=""
                            className="closerightPanelBtn"
                            onClick={() => {
                            this.props.rightEditPanel(false);
                            }}
                        >
                            <IoClose />
                        </button>
                        </li>
                    </ul>
                </div>
                <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Activity Details']}</div></div>
                <div className="rBoxGap">
                    <div className="rBoxTabs">
                        <Tabs activeKey={this.state.tabKey} onSelect={this.handleSelect} id="tabId">
                            <Tab eventKey={1} title={this.props.translator["Comments"]}>
                                {(this.props.breakFixEditDetails && Object.keys(this.props.breakFixEditDetails).length > 0) && <PopUpActivityLog date_format={this.props.date_format} messageactivity={this.state.messageactivity} breakFixEditDetails={this.props.breakFixEditDetails} breakFixItemId={this.props.breakFixItemId} fieldStatus={this.props.fieldStatus} changeCompMessageactivity={this.changeCompMessageactivity} module="Breakfix" translator={this.props.translator} disableFieldsForGuestRole={this.props.disableFieldsForGuestRole}/>}
                            </Tab>
                            {/* <Tab eventKey={2} title={this.props.translator["Related Work Items"]}>
                                <PopUpRelatedWork breakFixItemId={this.props.breakFixItemId} fieldStatus={this.props.fieldStatus} breakFixEditDetails={this.props.breakFixEditDetails} translator={this.props.translator} />
                            </Tab>
                            <Tab eventKey={3} title={this.props.translator["Relate CI"]}>
                                <PopUpCMDBRelatedWork breakfixNumber={this.props.breakFixEditDetails.breakfixNumber} fieldStatus={this.props.fieldStatus} breakFixReportedOn={this.props.breakFixEditDetails.reportedOn} breakFixEditDetails={this.props.breakFixEditDetails} itemId={this.props.breakFixItemId} editChangeInitialData={this.props.editChangeInitialData} translator={this.props.translator} />
                            </Tab>
                            <Tab eventKey={4} title={this.props.translator["Tasks"]}>
                                {this.state.taskeditFlag == "0" ? <PopUpTask clicktaskEdit={this.clicktaskEdit} fieldStatus={this.props.fieldStatus} breakFixItemId={this.props.breakFixItemId} translator={this.props.translator} breakFixEditDetailsUpdate={this.props.breakFixEditDetails} breakFixEditDetails={this.props.breakFixEditDetails} module="Breakfix" /> : <PopUpTaskEdit taskcode={this.state.taskcode} clicktaskEdit={this.clicktaskEdit} breakFixItemId={this.props.breakFixItemId} breakFixEditDetails={this.props.breakFixEditDetails} module="Breakfix" translator={this.props.translator} />}
                            </Tab> */}
                            <Tab eventKey={5} title={this.props.translator["Audit Log"]}>
                                <PopUpAuditLog date_format={this.props.date_format} message={this.state.message} changeCompMessage={this.changeCompMessage} breakFixEditDetails={this.props.breakFixEditDetails} breakFixItemId={this.props.breakFixItemId} module="Breakfix" translator={this.props.translator} urgencyListKeyValue={this.props.urgencyListKeyValue} impactListKeyValue={this.props.impactListKeyValue}/>
                            </Tab>
                            <Tab eventKey={3} title={"Notifications"}>
                            <WIBNotifcation requestNumber={this.props.breakFixEditDetails.breakfixNumber}
                            orderNumber={""}/>
                             </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }
}

export default TabPage;


