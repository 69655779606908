/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/
import React from "react";
import { Row, Col, Form, Alert, ButtonToolbar, Button } from "react-bootstrap";
import {
  _textArea,
  _dropDown,
  _inputField,
  _number,
  _radio,
  TypeaheadExampleSingleSelect,
} from "../../../common/formFields";
import {
  Field,
  reduxForm,
  SubmissionError,
  formValueSelector,
  change,
} from "redux-form";
import { connect } from "react-redux";
import axios from "axios";
import constants from "../../../../utils/constants";
import _ from "lodash";
import {
  spcmLoader,
  setLeftEditId,
  getServiceDetailCounts,
  getTaskPlans,
  getConsumptionItems,
  getServiceSupportCompanyGroups,
  getLOVFullfilmentPlan,
  getComponentName,
} from "../../../../actions/spcmActions";
import { loadCIGroupForTaskPlan } from "../../../../actions/cmdb/lovAction";
import { ImSpinner6 } from "react-icons/im";
import Cookies from "universal-cookie";
import { GLOBAL } from "../../../Globals";
import Swal from "sweetalert2";
const cookies = new Cookies();
let languageSelected = cookies.get("gph");
languageSelected = languageSelected.split("~");
languageSelected = languageSelected[20];

let userCompanyID = cookies.get("gph");
if (userCompanyID) userCompanyID = userCompanyID.replace("s:", "");
if (userCompanyID)
  userCompanyID = userCompanyID.substring(0, userCompanyID.lastIndexOf("."));
userCompanyID = userCompanyID.split("~");
const userCompanyId = userCompanyID[48];
const Joi = require("joi");

const removeDuplicates = (originalArray, prop) => {
  var newArray = [];
  var lookupObject = {};

  for (var i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i];
  }

  for (i in lookupObject) {
    newArray.push(lookupObject[i]);
  }
  return newArray;
};

const validate = (values) => {
  let errors = {};

  if (!values.sequenceNumber) {
    errors.sequenceNumber = "Enter Title";
  } else if (
    values.sequenceNumber == "" ||
    parseInt(values.sequenceNumber, 10) < 1 ||
    parseInt(values.sequenceNumber, 10) > 100
  ) {
    errors.sequenceNumber = "Please enter values between 1 and 100";
  }
  if (values.description) {
    if (values.description.trim() == "") {
      errors.description = "Enter Title";
    }
  } else if (!values.description) {
    errors.description = "Enter Title";
  }
  if (!values.fplan_desc) {
    errors.fplan_desc = "Enter Desc";
  }
  if (!values.status || values.status == "Select") {
    errors.status = "Enter Status";
  }

  if (
    !values.taskTypeId ||
    values.taskTypeId == "" ||
    values.taskTypeId == 0 ||
    values.taskTypeId == "0"
  ) {
    errors.taskTypeId = "Select Task Type";
  }

  return errors;
};

class TaskPlanEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isStatus: true,
      cisupcompanyErrorColor: "",
      ciSupCompanySelectedList: [],
      cigroupErrorColor: "",
      ciTaskNameErrorColor: "",
      ciSupGroupSelectedList: [],
      isExternal: "",
      oldUrgencyList: [],
      taskType: [],
      errorTaskType: false,
      selectedTaskType: 0,
    };
	(GLOBAL.taskTypeId = ""),
    this.submitForm = this.submitForm.bind(this);
    this.onSupCompanySelection = this.onSupCompanySelection.bind(this);
    this.setSupCompanyErrorColor = this.setSupCompanyErrorColor.bind(this);
    this.onSupGroupSelection = this.onSupGroupSelection.bind(this);
    this.setSupGroupErrorColor = this.setSupGroupErrorColor.bind(this);
    this.onCrossClickSupCompany = this.onCrossClickSupCompany.bind(this);
    this.onCrossClickSupGroup = this.onCrossClickSupGroup.bind(this);
    this.getData = this.getData.bind(this);
    this.setTaskTypeError = this.setTaskTypeError.bind(this);
  }

  componentWillMount() {
    const { change } = this.props;
    axios
      .get(
        "/api/company_menulist?companyId=" +
          this.props.COMPANY_ID +
          "&module=Change&field=Task Type&language=" +
          languageSelected +
          "&status=1"
      )
      .then((responseDropdownValues) => {
        const oldUrgencyList1 = [];
        responseDropdownValues.data.map((item) => {
          oldUrgencyList1.push(item);
          this.setState({ oldUrgencyList: oldUrgencyList1 });
        });
      });
    change("serviceName", "");
    change("serviceId", 0);
    change("ciName", this.props.CI_NAME);
    change("ciId", this.props.CI_ID);

    if (this.props.spcmReducer.editLeftId > 0) {
      // this.onCompanySelect(this.props.initialValues.companyId);
      this.props.loadCIGroupForTaskPlan(this.props.initialValues.companyId);

      // Status active or inactive
      if (
        this.props.initialValues.isExternal == null ||
        this.props.initialValues.isExternal == "" ||
        this.props.initialValues.isExternal == undefined
      ) {
        this.setState({ isExternal: false });
      } else {
        this.setState({ isExternal: this.props.initialValues.isExternal });
      }

      let isStatus = true;

      if (this.props.initialValues.status == "Inactive") {
        isStatus = false;
      }

      this.setState({ isStatus });
	  if (this.props.initialValues.taskType && this.props.initialValues.task_type_id ){
        this.setState({
          taskType: [{
            id: this.props.initialValues.task_type_id,
            label: this.props.initialValues.taskType
          }]
        });
      }
    }
  }

  componentDidMount() {
    if (this.props.spcmReducer.editLeftId > 0) {
      if (this.props.ciSupCompany && this.props.ciSupCompany.length > 0) {
        let arr = this.props.ciSupCompany.filter(
          (item) => item.field1Value == this.props.initialValues.companyId
        );
        let arr2 = arr.map((item) => {
          return { id: item.field1Value, label: item.field1Key };
        });
        this.setState({ ciSupCompanySelectedList: arr2 });
      }
      if (
        this.props.ciGroupForTaskPlan &&
        this.props.ciGroupForTaskPlan.length > 0
      ) {
        let arr = this.props.ciGroupForTaskPlan.filter(
          (item) =>
            item.field1Value == this.props.initialValues.assignedToGroupId
        );
        let arr2 = arr.map((item) => {
          return { id: item.field1Value, label: item.field1Key };
        });
        this.setState({ ciSupGroupSelectedList: arr2 });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.spcmReducer.editLeftId > 0) {
      if (
        nextProps.ciSupCompany &&
        nextProps.ciSupCompany !== this.props.ciSupCompany &&
        nextProps.ciSupCompany.length > 0
      ) {
        let arr = nextProps.ciSupCompany.filter(
          (item) => item.field1Value === this.props.initialValues.companyId
        );
        let arr2 = arr.map((item) => {
          return { id: item.field1Value, label: item.field1Key };
        });
        this.setState({ ciSupCompanySelectedList: arr2 });
      }

      if (
        nextProps.ciGroupForTaskPlan &&
        nextProps.ciGroupForTaskPlan !== this.props.ciGroupForTaskPlan &&
        nextProps.ciGroupForTaskPlan.length > 0
      ) {
        let arr = nextProps.ciGroupForTaskPlan.filter(
          (item) =>
            item.field1Value === this.props.initialValues.assignedToGroupId
        );
        let arr2 = arr.map((item) => {
          return { id: item.field1Value, label: item.field1Key };
        });
        this.setState({ ciSupGroupSelectedList: arr2 });
      }

      if (nextProps.companyIdParam === "") {
        this.setState({ ciSupGroupSelectedList: [] });
        this.props.change("assignedToGroupId", "");
        this.props.change("assignedToGroup", "");
      }
    }
  }

  componentWillUnmount() {
    this.props.spcmLoader("attribute", "success");
  }

  // onCompanySelect(companyId) {
  // 	this.props.change('assignedToGroupId', '');
  // 	this.props.change('assignedToGroup', '');
  // 	if(companyId && companyId != '') {
  // 		this.props.loadCIGroupForTaskPlan(companyId);
  // 	}
  // }

  onCrossClickSupCompany() {
    this.props.change("companyId", "");
    this.props.change("companyName", "");
    this.props.change("assignedToGroupId", "");
    this.props.change("assignedToGroup", "");
    this.setState({ ciSupCompanySelectedList: [] });
    this.setState({ ciSupGroupSelectedList: [] });
  }

  onCrossClickSupGroup() {
    this.props.change("assignedToGroupId", "");
    this.props.change("assignedToGroup", "");
    this.setState({ ciSupGroupSelectedList: [] });
  }

  onSupCompanySelection(selectedCompany) {
    this.setState({
      cisupcompanyErrorColor: "",
      ciSupCompanySelectedList: selectedCompany,
    });

    this.props.change("assignedToGroupId", "");
    this.props.change("assignedToGroup", "");

    if (selectedCompany.length > 0) {
      this.props.loadCIGroupForTaskPlan(selectedCompany[0].id);
      this.props.change("companyId", selectedCompany[0].id);
      this.props.change("companyName", selectedCompany[0].label);
    } else {
      this.props.change("companyId", "");
      this.props.change("companyName", "");
    }
    if (selectedCompany.length === 0) {
      this.setState({ ciSupGroupSelectedList: [] });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.companyIdParam !== this.props.companyIdParam &&
      this.props.companyIdParam === ""
    ) {
      this.props.change("assignedToGroupId", "");
      this.props.change("assignedToGroup", "");
      this.setState({ ciSupGroupSelectedList: [] });
    }
  }

  setSupCompanyErrorColor() {
    if (this.state.ciSupCompanySelectedList.length === 0)
      this.setState({ cisupcompanyErrorColor: "error" });
    else this.setState({ cisupcompanyErrorColor: "" });
  }

  onSupGroupSelection(selectedGroup) {
    if (selectedGroup.length > 0) {
      let isExternal;
      if (
        selectedGroup[0].isExternal == "" ||
        selectedGroup[0].isExternal == null ||
        selectedGroup[0].isExternal == undefined
      ) {
        isExternal = false;
      } else {
        isExternal = selectedGroup[0].isExternal;
      }
      this.setState({
        cigroupErrorColor: "",
        ciSupGroupSelectedList: selectedGroup,
        isExternal: isExternal,
      });

      this.props.change("assignedToGroupId", selectedGroup[0].id);
      this.props.change("assignedToGroup", selectedGroup[0].label);
    } else {
      this.setState({
        cigroupErrorColor: "",
        ciSupGroupSelectedList: selectedGroup,
        isExternal: "",
      });
      this.props.change("assignedToGroupId", "");
      this.props.change("assignedToGroup", "");
    }
  }

  setSupGroupErrorColor() {
    if (this.state.ciSupGroupSelectedList.length === 0)
      this.setState({ cigroupErrorColor: "error" });
    else this.setState({ cigroupErrorColor: "" });
  }

  handleCompanyFieldKeyDown = (event) => {
    if (event.key === "Backspace" && event.target.value === "") {
      this.props.change("assignedToGroupId", "");
      this.props.change("assignedToGroup", "");
      this.setState({ ciSupGroupSelectedList: [] });
    }
  };

  getData(e) {
    //alert("id::"+e.target.id+"val::"+e.target.value)
    //alert("this.props.supportPGroup"+e.target[e.target.selectedIndex].text);
    //console.log("groupname::"+)
    if (e.target.id == "taskTypeId") {
      GLOBAL.taskTypeId = e.target.value;
      this.setState({ taskType: e.target[e.target.selectedIndex].text });
    }
    this.props.dispatch(
      change(
        "TaskPlanEditForm",
        "taskType",
        e.target[e.target.selectedIndex].text
      )
    );
    this.props.dispatch(
      change(
        "TaskPlanEditForm",
        "taskTypeId",
        e.target[e.target.selectedIndex].text
      )
    );
  }

  setTaskTypeError() {
    if (this.state.selectedTaskType === 0) {
      this.setState({ errorTaskType: true });
    } else {
      this.setState({ errorTaskType: false });
    }
  }

  submitForm(values) {
    if (
      !values.description ||
      values.description == "" ||
      !values.companyId ||
      values.companyId == "" ||
      !values.assignedToGroupId ||
      values.assignedToGroupId == ""
    ) {
      if (!values.description || values.description == "") {
        this.setState({ ciTaskNameErrorColor: "error" });
      } else {
        this.setState({ ciTaskNameErrorColor: "" });
      }
      if (!values.companyId || values.companyId == "") {
        this.setState({ cisupcompanyErrorColor: "error" });
      } else {
        this.setState({ cisupcompanyErrorColor: "" });
      }
      if (!values.assignedToGroupId || values.assignedToGroupId == "") {
        this.setState({ cigroupErrorColor: "error" });
      } else {
        this.setState({ cigroupErrorColor: "" });
      }
      throw new SubmissionError({
        _error:
          this.props.tr[
            "The form has missing required fields, please revalidate the highlighted fields and update the form !"
          ],
      });
    }
    if (this.props.STATUS == "7" && this.props.SUB_STATUS == "12") {
      throw new SubmissionError({
        _error:
          this.props.tr[
            "Changes are not allowed in Status = Deployed and Sub-Status = Functional."
          ],
      });
    }
    if (this.props.spcmReducer.editLeftId > 0) {
      //values.modifiedBy_id=userDetails.user_id;
      values.modifiedBy = "";
    } else {
      //values.createdby_name=userDetails.user_name;
      values.createdBy = "";
      values.modifiedBy = "";
    }

    values.status = this.state.isStatus ? "Active" : "Inactive";

    let taskOutputs = [];
    if (values.taskOutputs) {
      let outputs = values.taskOutputs.split(";");

      outputs.forEach((item, i) => {
        let obj = {
          output_key: item,
        };
        taskOutputs.push(obj);
      });
    }
    if (values.taskTypeId === 0 || !values.taskTypeId || values.taskTypeId === "Select") {
        Swal.fire("Please select Task Type");
        return; 
        }
    let templtInfo = {};
    templtInfo.name = "ChangeManagement_Template";
    templtInfo.description = "ChangeManagement_Template";
    templtInfo.status = values.status;
    templtInfo.companyId = values.companyId;
    templtInfo.companyName = values.companyName;
    templtInfo.serviceId = 0;
    templtInfo.author = values.createdBy;
    templtInfo.serviceName = "";
    templtInfo.createdBy = values.createdBy;
    templtInfo.modifiedBy = values.createdBy;
    templtInfo.ciId = values.ciId;
    templtInfo.ciName = values.ciName;
    templtInfo.assignedToGroupId = values.assignedToGroupId;
    templtInfo.assignedToGroup = values.assignedToGroup;
    templtInfo.userCompanyId = userCompanyId;
    templtInfo.taskType = values.taskType;
    templtInfo.taskTypeId = GLOBAL.taskTypeId;
    if (this.props.spcmReducer.editLeftId > 0) {
      values.templateTaskId = this.props.spcmReducer.editLeftId;
      let formData = Object.assign({}, values);
      formData.taskOutputs = taskOutputs;
      formData.isExternal = this.state.isExternal;
      formData.userCompanyId = userCompanyId;
      // console.log(formData);
	  formData.taskTypeId = GLOBAL.taskTypeId;
      this.props.spcmLoader("attribute", "posting");
      return axios
        .patch(
          "/service/api/patchFulfilmentPlan/" +
            this.props.spcmReducer.editLeftId,
          formData
        )
        .then((response) => {
          this.props.getTaskPlans(
            this.props.CI_ID,
            "ChangeManagement_Template"
          );
          this.props.spcmLoader("attribute", "success");
          this.props.listFormToggleFunc("list");
          this.props.setLeftEditId(-1);
        })
        .catch((error) => {
          // alert('Oops! Something went wrong.');
          this.props.spcmLoader("attribute", "success");
          throw new SubmissionError({ _error: error.response.data[0].message });
        });
    } else {
      let formData = Object.assign({}, values);
      formData.taskOutputs = taskOutputs;
      formData.isExternal = this.state.isExternal;
      templtInfo.tasks = formData;
      templtInfo.isExternal = this.state.isExternal;
	  formData.taskTypeId = GLOBAL.taskTypeId;
      // console.log(templtInfo);
      this.props.spcmLoader("attribute", "posting");
      return axios
        .post("/service/api/saveFulfilmentPlan", templtInfo)
        .then((response) => {
          this.props.getTaskPlans(
            this.props.CI_ID,
            "ChangeManagement_Template"
          );
          this.props.spcmLoader("attribute", "success");
          this.props.listFormToggleFunc("list");
        })
        .catch((error) => {
          // alert('Oops! Something went wrong.');
          this.props.spcmLoader("attribute", "success");
          throw new SubmissionError({ _error: error.response.data[0].message });
        });
    }
  }
  renderTasktype(dropdownOptions) {
    if (!dropdownOptions) {
      return <option></option>;
    }
    return dropdownOptions.map((option) => {
      return (
        <option
          selected={this.props.initialValues.taskType === option.field1Key}
          value={option.field1Value}
        >
          {option.field1Key}
        </option>
      );
    });
  }
  render() {
    const tr = this.props.tr;
    const { change } = this.props;
    const { error, handleSubmit } = this.props;
    let supCompanyTypeaheadOptions = [];
    if (Array.isArray(this.props.ciSupCompany)) {
      supCompanyTypeaheadOptions = this.props.ciSupCompany.map((item) => {
        return { id: item.field1Value, label: item.field1Key };
      });
    }

    supCompanyTypeaheadOptions = removeDuplicates(
      supCompanyTypeaheadOptions,
      "id"
    );

    let supGroupTypeaheadOptions = [];
    let isExternal = "";
    if (
      this.props.companyIdParam &&
      this.props.companyIdParam != "" &&
      this.props.ciGroupForTaskPlan &&
      this.props.ciGroupForTaskPlan != ""
    ) {
      supGroupTypeaheadOptions = this.props.ciGroupForTaskPlan.map((item) => {
        if (
          item.isExternal == null ||
          item.isExternal == "" ||
          item.isExternal == undefined
        ) {
          isExternal = "";
        } else {
          isExternal = item.isExternal;
        }
        return {
          id: item.field1Value,
          label: item.field1Key,
          isExternal: isExternal,
        };
      });
      supGroupTypeaheadOptions = removeDuplicates(
        supGroupTypeaheadOptions,
        "id"
      );
    }

    return (
      <div className="borderForm margin-b-10">
        <form>
          {error == undefined || error == null ? null : (
            <Alert
              style={{
                marginBottom: "15px",
                fontSize: "13px",
                padding: "8px 30px 8px 8px",
              }}
              variant="danger"
            >
              <h4>{error}</h4>
            </Alert>
          )}

          <Row>
            {this.props.spcmReducer.editLeftId > 0 ? (
              <Col md={14}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="" className="float-l margin-r-20">
                    {tr["Template Task Code"]}
                  </Form.Label>
                  <div
                    className="radioInlineBox float-l"
                    style={{ position: "relative", top: "-7px" }}
                  >
                    <Field
                      name="templateTaskCode"
                      component={_inputField}
                      className="form-control"
                      disabled={true}
                    ></Field>
                  </div>
                </Form.Group>
              </Col>
            ) : null}
            <Col md={12}>
              <Form.Group className="form-group">
                <Form.Label bsClass="">
                  <span className="rStar"></span> {tr["Task Plan For"]}
                </Form.Label>
                <Field
                  name="taskfor"
                  component={_inputField}
                  className="form-control"
                  placeholder="Change Requests"
                  readOnly
                ></Field>
              </Form.Group>
            </Col>

            {/* <Col md={12}>
              <Form.Group
                validationState={this.state.errorTaskType ? "error" : ""}
                className="form-group"
              >
                <Form.Label bsClass="">
                  <span className="rStar"></span> {tr["Task Type"]}
                </Form.Label>
                <Field
                  name="taskTypeId"
                  className="form-control"
                  component={_dropDown}
                  options={this.state.oldUrgencyList}
                  onChange={(event) => {
                    this.getData(event);
                  }}
                  setErrorColor={this.setTaskTypeError}
                  value={this.props.initialValues.taskTypeId || ""}
                >
                  <option value="0">{tr["Select"]}</option>
                </Field>
              </Form.Group>
            </Col> */}

            <Col md={12}>
              <Form.Group className="margin-b-5">
                <span className="rStar" />{" "}
                <Form.Label>{tr["Task Type"]}</Form.Label>
                <Form.Select
					name="tasktype"
					id="taskTypeId"
					as="select"
					inputRef={(input) => (this.inputGroup = input)}
					onChange={(event) => {
						this.getData(event);
					}}
					>
					<option value="0">Select</option>
					{this.renderTasktype(this.state.oldUrgencyList)}
					</Form.Select>
              </Form.Group>
            </Col>

            <Col md={12}>
              <Form.Group className="form-group">
                <Form.Label bsClass="">
                  <span className="rStar"></span>
                  {tr["Task Name"]}
                </Form.Label>
                <Field
                  name="description"
                  component={_textArea}
                  placeholder=""
                  rows={2}
                  className={"form-control " + this.state.ciTaskNameErrorColor}
                />
              </Form.Group>
            </Col>

            <Col md={12}>
              <Row>
                <Col md={4}>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">
                      <span className="rStar"></span>
                      {tr["Sequence"]}{" "}
                    </Form.Label>
                    <Field
                      component={_number}
                      name="sequenceNumber"
                      className="form-control"
                      min={1}
                      max={100}
                      step="1"
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">
                      <span className="rStar"></span> {tr["Status"]}
                    </Form.Label>
                    <Form.Check
                      type="checkbox"
                      className="dCheckbox checkbox"
                      checked={this.state.isStatus}
                      onClick={() => {
                        this.setState({ isStatus: !this.state.isStatus });
                      }}
                      label={this.state.isStatus ? "Active" : "In-Active"}
                    ></Form.Check>
                  </Form.Group>
                </Col>
              </Row>
            </Col>

            <Col md={12}>
              <Form.Group className="form-group">
                <Form.Label bsClass="">
                  <span className="rStar"></span> {tr["Company"]}
                </Form.Label>
                <div className="dvTypehdropdown">
                  <Field
                    component={TypeaheadExampleSingleSelect}
                    onSelection={this.onSupCompanySelection}
                    setErrorColor={this.setSupCompanyErrorColor}
                    name="companyId"
                    onCrossClick={this.onCrossClickSupCompany}
                    errorClass={this.state.cisupcompanyErrorColor}
                    options={supCompanyTypeaheadOptions}
                    selectedOptions={this.state.ciSupCompanySelectedList}
                    onKeyDown={this.handleCompanyFieldKeyDown}
                  />
                </div>
              </Form.Group>
            </Col>

            <Col md={12}>
              <Form.Group className="form-group">
                <Form.Label bsClass="">
                  <span className="rStar"></span> {tr["Group"]}
                </Form.Label>

                <div className="dvTypehdropdown">
                  <Field
                    component={TypeaheadExampleSingleSelect}
                    onSelection={this.onSupGroupSelection}
                    setErrorColor={this.setSupGroupErrorColor}
                    errorClass={this.state.cigroupErrorColor}
                    options={supGroupTypeaheadOptions}
                    name="assignedToGroupId"
                    selectedOptions={this.state.ciSupGroupSelectedList}
                    onCrossClick={this.onCrossClickSupGroup}
                  />
                </div>
              </Form.Group>
            </Col>

            <Col md={12}>
              <Form.Group className="form-group">
                <Form.Label bsClass="">{tr["Output Variables"]} </Form.Label>
                <Field
                  component={_textArea}
                  name="taskOutputs"
                  className="form-control"
                  rows="1"
                />
                <span className="f-size-10">
                  Separated by a semicolon (Ex. Variable 1 ; Variable 2 ;
                  Variable 3)
                </span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ButtonToolbar className="black">
                <Button
                  disabled={this.props.spcmReducer.spcmPostingAttribute}
                  onClick={handleSubmit(this.submitForm)}
                  bsSize="small"
                  bsStyle="primary"
                  className="rgSidrkBtn smallBtn"
                >
                  {this.props.spcmReducer.spcmPostingAttribute ? (
                    <ImSpinner6 className="icn-spinner" />
                  ) : null}{" "}
                  {tr["Save"]}
                </Button>
                <Button
                  onClick={() => {
                    this.props.listFormToggleFunc("list");
                    this.props.setLeftEditId(-1);
                  }}
                  bsSize="small"
                  bsStyle="text"
                  className="rgSiLigBtn smallBtn"
                >
                  {tr["Close"]}
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({ spcmReducer, ciSupCompany, ciGroupForTaskPlan }) => {
  let initialValues = {};
  if (spcmReducer.editLeftId > 0) {
    initialValues = {
      ...spcmReducer.editLeftService,
      taskType: spcmReducer.editLeftService.taskType,
      taskTypeId: spcmReducer.editLeftService.task_type_id,
      sequenceNumber: spcmReducer.editLeftService.sequenceNumber || "1",
    };
  } else {
    initialValues = {
      sequenceNumber: "1",
    };
  }
  return {
    spcmReducer,
    initialValues,
    ciSupCompany,
    ciGroupForTaskPlan,
  };
};

TaskPlanEditForm = reduxForm({
  form: "TaskPlanEditForm",
  validate,
  enableReinitialize: true,
})(TaskPlanEditForm);

const selector = formValueSelector("TaskPlanEditForm");
TaskPlanEditForm = connect((state) => {
  let companyIdParam = selector(state, "companyId");
  return {
    companyIdParam,
  };
})(TaskPlanEditForm);

export default connect(mapStateToProps, {
  spcmLoader,
  setLeftEditId,
  getServiceDetailCounts,
  getTaskPlans,
  getConsumptionItems,
  getServiceSupportCompanyGroups,
  getLOVFullfilmentPlan,
  getComponentName,
  loadCIGroupForTaskPlan,
})(TaskPlanEditForm);
