
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { DevicesProvider, WithEditor } from '@grapesjs/react';
import { cx } from '../utils';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import TopbarButtons from './topbarButtons';
import { TextBox } from "@progress/kendo-react-inputs";
import { MdOutlineEdit } from "react-icons/md";
import { IoSaveOutline, IoClose } from "react-icons/io5";
import {Button,Form} from "react-bootstrap";

function deviceOptions(devices){
    return devices.map(device => {
        return {
            id: device.id,
            text: device.getName()
        }
    })
} 

export default function Topbar({ className, companyList, pageDetail, formDetailRefetch, navigationProps }) {
    const [isEdit,setIsEdit] = useState(false);
    const [customPageName,setCustomPageName] = useState("");
    useEffect(()=>{
       if(pageDetail){
         setCustomPageName(pageDetail?.name);
       }
    },[pageDetail])
    return (
        <div className={cx('gjs-top-sidebar d-flex items-center', className)}>
            {/* {!showField?<h1 bsClass="" className="sPageHeading1">{pageDetail?.name?pageDetail?.name:"UI Builder"}</h1>:<TextBox style={{maxWidth:"200px"}} onChange={(e)=>setCustomPageName(e.value)} defaultValue={customPageName}/>} */}

            {isEdit ? <div><Form.Control className="margin-r-10 display-inline-block" style={{width:"200px",padding:"4px 10px"}} type="text" onChange={(e)=>setCustomPageName(e.target.value)} placeholder={pageDetail?.name} value={customPageName} maxLength={40} /><Button bsPrefix=" " onClick={()=>{setIsEdit(!isEdit)}} className="isEdicn" title="Close"><IoClose /></Button></div> :
            <h1 bsClass="" className="sPageHeading1">{pageDetail?.name?pageDetail?.name:"UI Builder"} <Button bsPrefix=" " onClick={()=>{setIsEdit(!isEdit)}} className="isEdicn" title="Edit"><MdOutlineEdit /></Button></h1>}

            <DevicesProvider>
                {({ selected, select, devices }) => { 
                    let options = deviceOptions(devices);
                    let selectedOption = options.find(item => item.id === selected);
                    return (
                        <DropDownList
                            fillMode="outline"
                            textField="text"
                            dataItemKey="id" 
                            value={selectedOption}
                            onChange={(ev) => {select(ev.target.value.id)}} 
                            data={options}
                            className="devicesDv"
                            title="Select Device"
                        ></DropDownList>
                    )
                }}
            </DevicesProvider>
            
            <WithEditor>
                <TopbarButtons className="ms-auto px-2 topBtnMobileCls" companyList={companyList} pageDetail={pageDetail} formDetailRefetch={formDetailRefetch} customPageName={customPageName} setIsEdit={setIsEdit} navigationProps={navigationProps} />
            </WithEditor>
        </div>
    );
  }