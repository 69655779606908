
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';

// import { GLOBAL }  from '../../components/Globals';
// import constants from '../../../src/utils/constants';

export function getKnowledgeAPIKey() {
	return (dispatch) => {
		let str = {};
		str['companyId'] = "0";
		str['module'] = 'Knowledge';
		str['propertyId'] = '14';
		str = JSON.stringify(str);
		axios.get('/api/getKnowledgeKey', { headers: { 'query': str } })
			.then(response => {
				// console.log('data--',response.data);
			})
			.catch(err => {
				// console.log('err',err);
			});
	};
}

export function getInsidedTokenAction() {
	return (dispatch) => {
		axios.get('/api/insided/token')
			.then(response => {
				// console.log('/api/insided/token response==', response);
				dispatch({
					type: 'FETCH_INSIDED_TOKEN_SUCCESS',
					insidedTokenResult: response.data
				});
			})
			.catch(err => {
				// console.log('insided token err====', err);
				dispatch({ type: 'FETCH_INSIDED_TOKEN_ERROR' });
			});
	};
}

export function loadInsidedArticles(categories, token, callFromMyArticlesRoute = false) {
	return (dispatch) => {
		dispatch({ type: 'FETCHING_INSIDED_DETAILS_IN_PROGRESS' });
		axios.get('/search/' + categories, { headers: { 'authorization': `bearer ${token}` } })
			.then((res) => {
				// console.log('final res====',res);
				let allData = res.data.community;
				let displayData = allData;

				if (callFromMyArticlesRoute == false) {
					if (allData.length > 5)
						displayData = allData.slice(0, 5);
				}

				dispatch({ type: 'LOAD_INSIDED_RESULT_SUCCESS', insidedResult: displayData });
				dispatch({ type: 'INSIDED_DETAILS_FETCHED_SUCCESSFULLY' });
			})
			.catch(err => {
				// console.log('insided token err====', err);
				dispatch({ type: 'LOAD_INSIDED_RESULT_SUCCESS', insidedResult: [] });
				dispatch({ type: 'INSIDED_DETAILS_FETCHED_SUCCESSFULLY' });
			});
	};
}

export function loadInsidedArticlesForSearch(categories, searchText, token, callFromMyArticlesRoute = false) {
	return (dispatch) => {
		dispatch({ type: 'FETCHING_INSIDED_DETAILS_IN_PROGRESS' });
		axios.get('/search/' + categories + '/' + searchText, { headers: { 'authorization': `bearer ${token}` } })
			.then((res) => {
				// console.log('final res====',res);
				let allData = res.data.community;
				let displayData = allData;

				if (callFromMyArticlesRoute == false) {
					if (allData.length > 5)
						displayData = allData.slice(0, 5);
				}

				dispatch({ type: 'LOAD_INSIDED_RESULT_SUCCESS', insidedResult: displayData });
				dispatch({ type: 'INSIDED_DETAILS_FETCHED_SUCCESSFULLY' });
			})
			.catch(err => {
				// console.log('insided token err====', err);
				dispatch({ type: 'LOAD_INSIDED_RESULT_SUCCESS', insidedResult: [] });
				dispatch({ type: 'INSIDED_DETAILS_FETCHED_SUCCESSFULLY' });
			});
	};
}

export function emptyInsidedReducer() {
	return (dispatch) => {
		dispatch({ type: 'LOAD_INSIDED_RESULT_SUCCESS', insidedResult: [] });
	};
}
export function loadDiscourseArticlesForSearch(searchText, username) {
	return (dispatch) => {
		dispatch({ type: 'FETCHING_INSIDED_DETAILS_IN_PROGRESS' });
		axios.get("/api/searchKnowledgeTopicsForGlobalSearch" + '?q=' + searchText, { headers: { 'username': username } }) 
			.then((res) => {
				// console.log('final res====',res);
				 let allData = res.data
				//  let combineData = []
				//  allData.topics.map((topic , i) => {
				// 	let postsObj = allData.posts[i]
				// 	let topicObj = {...topic, ...postsObj}
				// 	combineData.push(topicObj)
				//  })
				 
				 console.log("data");
				 console.log(allData);
				 let displayData = allData
					if (allData.length > 5)
					//displayData = allData.slice(0, 5);
					console.log("data" , allData );
				dispatch({ type: 'INSIDED_DETAILS_FETCHED_SUCCESSFULLY' });
				dispatch({ type: 'LOAD_INSIDED_RESULT_SUCCESS', insidedResult: displayData });
			})
			.catch(err => {
				// console.log('insided token err====', err);
				dispatch({ type: 'LOAD_KNOWLEDGE_GLOBAL_RESULT_SUCCESS', knowledgeResult: [] });
				dispatch({ type: 'KNOWLEDGE_GLOBAL_DETAILS_FETCHED_SUCCESSFULLY' });
			});

	};
}

export function loadDiscourseArticlesForSearchByTopicId(id, username) {
	return (dispatch) => {
		dispatch({ type: 'FETCHING_INSIDED_DETAILS_IN_PROGRESS' });
		axios.get("/api/getArticleById/" + id, { headers: { 'username': username } }) 
			.then((res) => {
				let allData = res.data;
				console.log("data");
				console.log(allData);
				dispatch({ type: 'INSIDED_DETAILS_FETCHED_SUCCESSFULLY' });
				dispatch({ type: 'LOAD_INSIDED_RESULT_SUCCESS', insidedResult: allData });
			})
			.catch(err => {
				dispatch({ type: 'LOAD_KNOWLEDGE_GLOBAL_RESULT_SUCCESS', knowledgeResult: [] });
				dispatch({ type: 'KNOWLEDGE_GLOBAL_DETAILS_FETCHED_SUCCESSFULLY' });
			});

	};
}

export function loadDiscourseArticlesForGlobalSearch(searchText, username, categoryId = 0, categorySlug = '') {
	if (categoryId != 0 && categorySlug != '') {
		searchText += encodeURIComponent(' #' + categorySlug);
	}
	return (dispatch) => {
		dispatch({ type: 'FETCHING_KNOWLEDGE_GLOBAL_DETAILS_IN_PROGRESS' });
		axios.get("/api/searchKnowledgeTopicsForGlobalSearch" + '?q=' + searchText, { headers: { 'username': username } })
			.then((res) => {
				dispatch({ type: 'LOAD_KNOWLEDGE_GLOBAL_RESULT_SUCCESS', knowledgeResult: res.data });
				dispatch({ type: 'KNOWLEDGE_GLOBAL_DETAILS_FETCHED_SUCCESSFULLY' });
			})
			.catch(err => {
				dispatch({ type: 'LOAD_KNOWLEDGE_GLOBAL_RESULT_SUCCESS', knowledgeResult: [] });
				dispatch({ type: 'KNOWLEDGE_GLOBAL_DETAILS_FETCHED_SUCCESSFULLY' });
			});
	};
}

export function getArticleById(id, username) {
	return (dispatch) => {
		dispatch({ type: 'FETCHING_KNOWLEDGE_GLOBAL_DETAILS_IN_PROGRESS' });
		axios.get("/api/getArticleById/" + id, { headers: { 'username': username } }) 
			.then((res) => {
				let article = res.data.post_stream.posts[0].cooked
				console.log("article");
				console.log(article);
				dispatch({ type: 'LOAD_DISCOURSE_ARTICLE_SUCCESS', articleResult: article });
				dispatch({ type: 'KNOWLEDGE_GLOBAL_DETAILS_FETCHED_SUCCESSFULLY' });
			})
			.catch(err => {
				// console.log('insided token err====', err);
				//dispatch({ type: 'LOAD_KNOWLEDGE_GLOBAL_RESULT_SUCCESS', knowledgeResult: [] });
				dispatch({ type: 'KNOWLEDGE_GLOBAL_DETAILS_FETCHED_SUCCESSFULLY' });
			});
	};
}
export function emptyGlobalInsidedReducer() {
	return (dispatch) => {
		dispatch({ type: 'LOAD_KNOWLEDGE_GLOBAL_RESULT_SUCCESS', knowledgeResult: [] });
	};
}

export function loadKnowledgeCategories(username) {
	return (dispatch) => {
		axios.get("/api/knowledge/categories?include_subcategories=true", { headers: { 'username': username } }) 
			.then((response) => {
				console.log('response.data-------------',response.data)
				if(response.status == 200)
				dispatch({ type: 'LOAD_KNOWLEDGE_CATEGORIES_SUCCESS', knowledgeCategories: response.data.category_list.categories });
			})
			.catch(err => {
				dispatch({ type: 'LOAD_KNOWLEDGE_CATEGORIES_SUCCESS', knowledgeCategories: [] });
			});
	};
}