
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export default function offeringDescriptionReducer(state = initialState.offeringDescription,action){

  switch (action.type) {
    case 'SELECTED_OFFERING':
      return action.selectedOffering;
    default:
      return state;
  }
}
