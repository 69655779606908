
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
let initialState = {
    isLoading: false,
    message : ''
}

export const commonLoader = (state = initialState, action) => {
    if (action.type === 'FDN_DATA_FETCHING') {
        return {
            isLoading: true,
            message: 'loading'
      };
    }
    else if (action.type === 'FDN_DATA_FETCHED_SUCCESS') {
        return {
            isLoading: false,
            message: 'success'
        }
    }
   else if (action.type === 'FDN_DATA_FETCHED_FAILED') {
        return {
            isLoading: false,
            message: 'failed'
        }
    }
    else {
      return state;
    }
  };


export const hosts = (state = {}, action) => {
    if (action.type === 'SET_HOSTS') {
        return action.hosts;
    }
    return state;
};


