
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect } from "react";
import NumericInput from "react-numeric-input";

const InputNumberField = (props) => {
  useEffect(() => {
    // document.querySelector("#numField").setAttribute("pattern", "[0-9]");
  }, []);

  return (
    <>
      <NumericInput
        id="numField"
        name={props.question.name}
        className={props.className}
        step={1}
        min={props.question.minValue}
        max={props.question.maxValue}
        onChange={(e) => props.onChange(props.question, e)}
        strict={true}
        value={props.piSectionFormValues[props.question.name] || props.question.minValue}
        snap
      />
    </>
  );
};

export default InputNumberField;
