
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import { navigationHooks } from '../../helpers/NavigationHook';

const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});



export function getQueryBuilderAllRules() {
  return (dispatch) => {
      api.get('/rules/rest/getAllRules')
          .then((response) => {
              if (!response) {
                  throw Error(response.statusText);
              }
              return response;
          })
          .then((response) => {
            dispatch(
              {type: 'QUERYBUILDER_ALLRULES_SUCCESS', payload: response.data}
            );
          })
          .catch((err) => {
              console.log(err);
          });
  };
}

export function getQueryBuilderFields() {
  return (dispatch) => {
      api.get('/rules/rest/getQueryRawData/Incident')
          .then((response) => {
              if (!response) {
                  throw Error(response.statusText);
              }
              return response;
          })
          .then((response) => {
            dispatch(
              {type: 'QUERYBUILDER_SUCCESS', payload: response.data}
            );
          })
          .catch((err) => {
              console.log(err);
          });
  };
}

export function getQueryBuilderChildFields(value,name) {
  return (dispatch) => {
    let config = {
      headers: {query:JSON.stringify({value,name})}
    };
      api.get('/rules/rest/getChildData/',config)
          .then((response) => {
              if (!response) {
                  throw Error(response.statusText);
              }
              return response;
          })
          .then((response) => {
            dispatch(
              {type: 'QUERYBUILDER_CHILD_LOAD_SUCCESS', payload: response.data}
            );
          })
          .catch((err) => {
              console.log(err);
          });
  };
}

export function getQueryBuilderChildAttributes(key,path) {
  return (dispatch) => {
    let config = {
      headers: {query:JSON.stringify({key,path})}
    };
      api.get('/rules/rest/getChildNodesByKey',config)
          .then((response) => {
              if (!response) {
                  throw Error(response.statusText);
              }
              return response;
          })
          .then((response) => {
            dispatch(
              {type: 'QUERYBUILDER_CHILD_ATTRIBUTES_SUCCESS', payload: {key, path, "data":response.data}}
            );
            console.log(response,"response.data")
          })
          .catch((err) => {
              console.log(err);
          });
  };
}

export function saveQueryBuilderRecords(submitData){
	return (dispatch) => {
    console.log("submitData798",submitData);
    return axios.post('/rules/rest/generateConditionalQuery', submitData)
    .then((response) => {
      if (!response) {
          throw Error(response.statusText);
      }
      return response;
    })
    .then(response => {
      console.log("responsea798",response);
      dispatch(
        {type: 'QUERYBUILDER_POST_QUERY_LOAD_SUCCESS', payload: response.data}
      );
      // navigationHooks.navigate('/amslist');
      navigationHooks.navigate('/rulesList');
    })
    .catch((err) => {
      console.log(err);
    });
	}
}


export function getAmsEditRuleConfig(ruleId){
  console.log("ruleId79",ruleId);
  return (dispatch) => {
    api.get("/rules/rest/getRule/"+ruleId)
      .then((response) => {
        console.log("response795",response);
        let editRuleConfigurationDetails = {
          "ruleId": response.data.id,
          "ruleName": response.data.ruleName,
          "consumerCompany": response.data.consumerCompany,
          "assignmentFor": response.data.assignmentFor,
          "module": response.data.module,
          "status": response.data.status,
          "weight": response.data.weight,
          "createdBy": response.data.createdBy,
          "conditionalJson": response.data.conditionalJson,
          "condition": response.data.condition,
          "assignmentCompany": response.data.assignmentCompany,
          "assignmentGroupName": response.data.assignmentGroupName,
        }
        dispatch({
          type: 'GET_EDIT_RULE_CONFIGURATION_SUCCESS',
          editRuleConfigurationDetails: editRuleConfigurationDetails
        })
      })
      .then((response) => {
        // navigationHooks.navigate('/amsEdit/' + ruleId);
        navigationHooks.navigate('/rulesEdit/' + ruleId);
      })
  }
};
