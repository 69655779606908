
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import moment from "moment";
import Cookies from "universal-cookie";

import {
  prefDateFormatAccordingUser,
  getDateWord,
  findTimeFormate,
} from "./common/CommonMethod";

export const ending = [
  {
    key: "After",
    value: "Ends After",
  },
  { key: "on Date", value: "Ends By" },
  { key: "Never", value: "Naver Ends" },
];

export const utis = [
  {
    key: "Day(s)",
    value: "daily",
  },
  { key: "Week(s)", value: "weekly" },
  { key: "Month(s)", value: "monthly" },
  { key: "Year(s)", value: "yearly" },
];

export const yearMonthName = [
  { key: "Jan", value: "1" },
  { key: "Feb", value: "2" },
  { key: "Mar", value: "3" },
  { key: "Apr", value: "4" },
  { key: "May", value: "5" },
  { key: "Jun", value: "6" },
  { key: "Jul", value: "7" },
  { key: "Aug", value: "8" },
  { key: "Sep", value: "9" },
  { key: "Oct", value: "10" },
  { key: "Nov", value: "11" },
  { key: "Dec", value: "12" },
];

export const week = [
  { key: "Sun", value: "7", name: "Sunday" },
  { key: "Mon", value: "1", name: "Monday" },
  { key: "Tue", value: "2", name: "Tuesday" },
  { key: "Wed", value: "3", name: "Wednesday" },
  { key: "Thu", value: "4", name: "Thursday" },
  { key: "Fri", value: "5", name: "Friday" },
  { key: "Sat", value: "6", name: "Saturday" },
];
export const weekCount = [
  { key: "First", value: "1" },
  { key: "Second", value: "2" },
  { key: "Third", value: "3" },
  { key: "Fourth", value: "4" },
  { key: "Last", value: "5" },
];

const findDurationAsMinute = (start, end) => {
  const duration = moment.duration(moment(end).diff(moment(start)));
  return parseInt(duration.asMinutes(), 10);
};


export const findDuration = (start, end) => {
  if (start && end) {
    var diff = moment.duration(end.diff(start));

    var days = diff.days();
    var hours = diff.hours();
    var minutes = diff.minutes();

    let duration = "";
    if (days) {
      duration += days + (days > 1 ? " days " : " day ");
    }
    if (hours) {
      duration += hours + " hr ";
    }
    if (minutes) {
      duration += minutes + " m";
    }

    return duration.trim() || "0m";
  }
  return "";
};

export const findDuration11 = (start, end) => {

  if (start && end) {

    var diff = moment.duration(moment(end).second(58).diff(moment(start)));

    var days = parseInt(diff.asDays(), 10); //84

    var hours = parseInt(diff.asHours(), 10); //2039 hours, but it gives total hours in given miliseconds which is not expacted.

    hours = hours - days * 24; // 23 hours

    var minutes = parseInt(diff.asMinutes(), 10); //122360 minutes,but it gives total minutes in given miliseconds which is not expacted.

    minutes = minutes - (days * 24 * 60 + hours * 60);

    let duration = "";
    if (days) {
      if (days > 1) {
        duration = days + "days";
      } else {
        duration = days + "day";
      }
    }
    if (hours) {
      duration = duration + " " + hours + "hr";
    }
    if (minutes) {
      duration = duration + " " + minutes + "m";
    }

    if (days == 0 && hours == 0 && minutes == 0) {
      duration = "0m";
    }
    if (duration.includes("-")) {
      return "";
    }
    return duration;
  }
  return "";
};

export const replaceKey = (
  replaceKeyTo1,
  replaceKeyTo2,
  replaceKeyFrom1,
  replaceKeyFrom2,
  data,
  extraKey,
  extraKeyReplace,
  imageUrl
) => {
  if (data) {
    const transformed = data.map((obj) => {
      let result = {};

      if (obj.hasOwnProperty(replaceKeyTo1)) {
        result[replaceKeyFrom1] = obj[replaceKeyTo1] || "";
      }
      if (obj.hasOwnProperty(replaceKeyTo2)) {
        result[replaceKeyFrom2] = obj[replaceKeyTo2] || "";
      }

      if (extraKey && extraKeyReplace) {
        result[extraKeyReplace] = obj[extraKey] || imageUrl;
      }

      return result;
    });

    return transformed;
  }

  return [];
};

export const convertDateForServer = (date) => {
  if (date) {
    return moment(date).format("DD/MM/YYYY HH:mm:ss");
  }
  return "";
};
export const convertToformateData = (date) => {
  if (date) {
    return moment(date).format("DD/MM/YYYY");
  }
  return "";
};
export const addZiroSecond = (date, prefData) => {
  if (date) {
    return moment(date)
      .second(10)
      .format(prefDateFormatAccordingUser(prefData, false));
  }
};
export const returnServerPayload = (serverObj, prefData, userInfo, type) => {
  console.log("v", serverObj);
  let payload = {
    title: serverObj.title,
    companyId: serverObj.customer,
    companyName: serverObj.customerName,
    committeeName: serverObj.committee,
    trackName: serverObj.track,
    category: serverObj?.category,
    subTrackName: serverObj.subTrackName,
    subTrackId: serverObj.subTrack,
    forumId: serverObj.forumId,
    forumName: serverObj.forumName,
    committeeId: serverObj.committeeId,
    categoryId: serverObj.categoryId,
    regionId: serverObj.regionId,
    regionName: serverObj.regionName,
    meetingRecurrenceSummary: serverObj.meetingRecurrenceSummary,
    supplierId: serverObj.supplier?.map((val) => val?.id).join(","),
    supplierNames: serverObj.supplier?.map((val) => val?.label).join(","),
    primaryChairPerson: serverObj.primaryChairPerson,
    primaryChairPersonName:
      serverObj.primaryChairPersonName || serverObj.chairPersonName,
    externalEmailsId: serverObj.externalEmails
      ?.map((val) => val?.label)
      .join(","),
    secondaryChairPersons: serverObj?.coordinator
      ?.map((val) => val && val.usersId)
      .join(","),

    secondaryChairPersonsName: serverObj?.coordinator
      ?.map((val) => val?.fullname)
      .join(","),
    secondaryChairPersonsEmail: serverObj?.coordinator
      ?.map((val) => val?.email)
      .join(","),
    secondaryChairPersonsCompany: serverObj?.coordinator
      ?.map((val) => val?.companyName)
      .join(","),
    primaryChairPersonNameCompany: serverObj?.primaryChairPersonNameCompany,
    primaryChairPersonNameEmail: serverObj?.primaryChairPersonNameEmail,
    objectives: serverObj.objectives,
    agenda: serverObj.agenda,
    meetingLocation: serverObj.meetingLocation,
    meetingLocationDetails:
      serverObj.location || serverObj.meetingLocationDetails,
    timeZone: serverObj.timeZone,
    status: serverObj.status,
    statusId: serverObj.statusId,
    usersId: serverObj?.persons
      ?.map((val) => val?.usersId || val?.userId)
      .join(","),
    usersName: serverObj?.persons?.map((val) => val?.fullname).join(","),
    usersEmail: serverObj?.persons?.map((val) => val?.email).join(","),
    usersCompany: serverObj?.persons?.map((val) => val?.companyName).join(","),
    groupsEmail: serverObj?.groups?.map((val) => val.email).join(","),
    groupsCompany: serverObj?.groups?.map((val) => val?.companyName).join(","),
    groupsId: serverObj?.groups?.map((val) => val?.value).join(","),
    groupsName: serverObj?.groups?.map((val) => val?.label).join(","),
    startDateTime: addZiroSecond(serverObj.startOn, prefData),
    endDateTime: addZiroSecond(serverObj.startEndOn, prefData),
    durationInMiutes: findDurationAsMinute(
      serverObj.startOn,
      serverObj.startEndOn
    ),
    recurranceFlag: serverObj.recuring,
    recurrenceUnit: serverObj.recuring ? serverObj.units : "",
    recurrenceStartDateTime: "",
    recurrenceEndDateTime: "",
    recurrenceFrequency: "",
    meetingTypeId: serverObj.meetingTypeId,
  };

  if (type === "details") {
    payload.recurrenceStartDateTime = addZiroSecond(
      serverObj?.recurrenceStartDateTime,
      prefData
    );
    payload.recurrenceEndDateTime = addZiroSecond(
      serverObj?.recurrenceEndDateTime,
      prefData
    );
  }

  if (serverObj.recuring) {
    payload.recurrenceStartDateTime = addZiroSecond(
      serverObj.startOn,
      prefData
    );

    payload.recurrenceFrequency = serverObj.every;
    payload.noEndDate = false;
    payload.endAfterSpecifiedOccurances = false;
    payload.endBy = false;
    payload.noOfOccurances = "";
    if (serverObj.redioAccuranceSelection === "Ends By") {
      payload.endBy = true;
      payload.recurrenceEndDateTime = `${getDateWord(
        addZiroSecond(serverObj.recuringEndOn, prefData),
        false
      )}${findTimeFormate(addZiroSecond(serverObj.startOn, prefData), false)}`;
    }
    if (serverObj.redioAccuranceSelection === "Ends After") {
      payload.endAfterSpecifiedOccurances = true;
      payload.noOfOccurances = Number(
        serverObj.redioAccuranceSelectionEndAfterDaysNo
      );
    }
    if (serverObj.redioAccuranceSelection === "Naver Ends") {
      payload.noEndDate = true;
    }

    if (serverObj.units === "weekly") {
      payload.recurrenceUnit = serverObj.units;
      payload.recurrenceWeekday = serverObj?.weeks?.map((val) => val).join(",");
    }
    if (serverObj.units === "monthly") {
      payload.recurrenceUnit = serverObj.units;
      if (serverObj.redioButtonSelection === "On Day") {
        payload.singleDaySelection = true;
        payload.recurrenceMonthday = serverObj.monthDaysNo;
      } else {
        payload.recurrenceMonthWeekdayInterval = serverObj.weekNo;
        payload.intervalDaySelection = true;
        payload.recurrenceMonthWeekday = serverObj.weekDay;
      }
    }
    if (serverObj.units === "yearly") {
      payload.recurrenceUnit = serverObj.units;
      if (serverObj.redioButtonSelection === "On Day") {
        payload.singleDaySelection = true;
        payload.recurrenceYearMonth = serverObj.yearMonthNameForOnDay;
        payload.recurrenceYearDay = serverObj.monthDaysNo;
      } else {
        payload.intervalDaySelection = true;
        payload.recurrenceYearWeekdayInterval = serverObj.weekNo;
        payload.recurrenceYearWeekday = serverObj.weekDay;
        payload.recurrenceYearMonthDay = serverObj.yearMonthName;
      }
    }
  }
  return payload;
};

export const convertDateForUI = (date) => {
  if (date) {
    return moment(new Date(date), "DD/MM/YYYY HH:mm:ss").toDate();
  }
  return undefined;
};
export const splitValue = (value, label) => {
  const lables = label?.split(",");
  const values = value?.split(",");
  let result = [];
  lables?.map((val, index) => {
    if (val && values[index]) {
      result.push({ label: val, value: values[index] });
    }
  });

  return result;
};
export const createUIPayload = (serverObj, prefData, userInfo) => {
  let payload = {
    meetingDefinationNumber: serverObj?.meetingDefinationNumber,
    meetingId: serverObj?.meetingDefinationId,
    title: serverObj.title,
    customer: serverObj.companyId?.toString(),
    customerName: serverObj.companyName,
    mop: serverObj.mop,
    forumName: serverObj.forumName,
    forumId: serverObj.forumId,
    committee: serverObj.committeeName,
    track: serverObj.trackName,
    subTrack: serverObj.subTrackName,
    subTrackName: serverObj.subTrackName,
    committeeId: serverObj.committeeId,
    categoryId: serverObj.categoryId,
    status: serverObj.status,
    statusId: serverObj.statusId,
    region: serverObj.regionId,
    regionName: serverObj.regionName,
    category: serverObj?.category,
    userAuthorized: serverObj?.userAuthorized,
    supplier: splitValue(serverObj.supplierId, serverObj.supplierNames),

    chairPerson: serverObj.primaryChairPerson,
    chairPersonName: serverObj.primaryChairPersonName,
    chairPersonEmail: serverObj.primaryChairPersonNameEmail,
    chariPersonCompany: serverObj.primaryChairPersonNameCompany,
    primaryChairPersonNameCompany:
      serverObj?.primaryChairPersonNameCompany || "",
    primaryChairPersonNameEmail: serverObj?.primaryChairPersonNameEmail || "",
    timeZone: serverObj.timeZone,
    objectives: serverObj.objectives,
    agenda: serverObj.agenda,
    meetingTypeId: serverObj.meetingTypeId,
    meetingLocation: serverObj.meetingLocation,
    location: serverObj.meetingLocationDetails,
    externalEmails:
      (serverObj?.externalEmailsId && serverObj.externalEmailsId?.split(",")) ||
      [],
    startOn: moment(
      serverObj.startDateTime,
      prefDateFormatAccordingUser(prefData, true)
    ),
    startEndOn: moment(
      serverObj.endDateTime,
      prefDateFormatAccordingUser(prefData, true)
    ),

    duration: findDuration(
      convertDate(
        serverObj.startDateTime,
        prefDateFormatAccordingUser(prefData, true)
      ),
      convertDate(
        serverObj.endDateTime,
        prefDateFormatAccordingUser(prefData, true)
      )
    ),
    recuring: serverObj.recurranceFlag,
    units: serverObj.recurrenceUnit || "daily",
  };

  if (serverObj.recurranceFlag) {
    payload.recuringStartOn = serverObj?.recurrenceStartDateTime
      ? serverObj.recurrenceStartDateTime
      : "";
    payload.recuringEndOn = serverObj.recurrenceEndDateTime
      ? moment(
          serverObj.recurrenceEndDateTime,
          prefDateFormatAccordingUser(prefData, true)
        )
      : "";
    payload.every = serverObj.recurrenceFrequency;
  }

  if (serverObj.noEndDate) {
    payload.redioAccuranceSelection = "Naver Ends";
  }
  if (serverObj.endAfterSpecifiedOccurances) {
    payload.redioAccuranceSelection = "Ends After";
    payload.redioAccuranceSelectionEndAfterDaysNo = serverObj.noOfOccurances;
  }
  if (serverObj.endBy) {
    payload.redioAccuranceSelection = "Ends By";
  }

  if (serverObj.recurrenceUnit === "weekly") {
    payload.units = serverObj.recurrenceUnit;
    payload.weeks = serverObj.recurrenceWeekday?.split(",") || [];
  }
  if (serverObj.units === "monthly") {
    payload.units = serverObj.recurrenceUnit;
    if (serverObj.singleDaySelection) {
      payload.redioButtonSelection = "On Day";
      payload.monthDaysNo = serverObj.recurrenceMonthday;
    } else if (serverObj.intervalDaySelection) {
      payload.weekNo = serverObj.recurrenceMonthWeekdayInterval;
      payload.redioButtonSelection = "On The";
      payload.weekDay = serverObj.recurrenceMonthWeekday;
    }
  }
  if (serverObj.units === "yearly") {
    payload.units = serverObj.recurrenceUnit;
    if (serverObj.singleDaySelection) {
      payload.redioButtonSelection = "On Day";
      payload.yearMonthNameForOnDay = serverObj.recurrenceYearMonth;
      payload.monthDaysNo = serverObj.recurrenceYearDay;
    } else if (payload.intervalDaySelection) {
      payload.redioButtonSelection = "On The";
      payload.weekNo = serverObj.recurrenceYearWeekdayInterval;
      payload.weekDay = serverObj.recurrenceYearWeekday;
      payload.yearMonthName = serverObj.recurrenceYearMonthDay;
    }
  }

  return payload;
};

export function createPayloadForChips(...params) {
  let usersId = params[0]?.split(",") || [];
  let usersName = params[1]?.split(",") || [];
  let usersEmail = params[2]?.split(",") || [];
  let usersCompany = params[3]?.split(",") || [];
  const peopleObjects = usersName?.filter(Boolean).map((value, index) => {
    let obj = {
      value: "",
      label: "",
      email: "",
      fullname: "",
      companyName: "",
      usersId: "",
    };
    obj.value = value;
    obj.fullname = usersName[index] || "";
    obj.label = usersName[index] || "";
    obj.email = usersEmail[index] || "";
    obj.companyName = usersCompany[index] || "";
    obj.usersId = usersId[index] || "";
    return obj;
  });

  return peopleObjects;
}

export const filterPersons = (...params) => {
  let usersEmail = params[0]?.split(",") || [];
  let usersCompany = params[1]?.split(",") || [];
  let usersId = params[2]?.split(",") || [];
  let usersName = params[3]?.split(",") || [];

  const personObjects = usersId.filter(Boolean).map((value, index) => {
    let obj = {
      value: "",
      email: "",
      fullname: "",
      companyName: "",
      userId: "",
    };
    obj.value = value;
    obj.fullname = usersName[index] || "";
    obj.label = usersName[index] || "";
    obj.email = usersEmail[index] || "";
    obj.userId = usersId[index] || "";
    obj.companyName = usersCompany[index] || "";
    return obj;
  });

  return personObjects;
};

export const filterUser = (usersData, editUsersData) => {
  let users = editUsersData?.split(",") || [];

  if (users.length > 0) {
    let filterUser = usersData.filter(
      (user) =>
        users.includes(user.userName) || users.includes(user.userId?.toString())
    );
    return replaceKeyInObjectArray(filterUser, {
      fullName: "label",
      userName: "value",
      imgName: "img",
      emailAddress: "email",
    });
  }
  return users;
};
export const filterGroup = (usersData, editUsersData) => {
  let users = editUsersData?.split(",") || [];

  if (users.length > 0) {
    let filterUser = usersData.filter((user) =>
      users.includes(user.value?.toString())
    );
    return replaceKeyInObjectArray(filterUser, {
      actualName: "label",
      groupId: "value",
      imgName: "img",
      emailAddress: "email",
    });
  }

  return users;
};

export const filterGroupUser = (usersData, editUsersData) => {
  const userGroups = editUsersData?.split(",") || [];
  if (userGroups.length > 0) {
    const filteredUsers = usersData.filter((user) => {
      return userGroups?.includes(user?.value?.toString());
    });

    const transformedUsers = replaceKeyInObjectArray(filteredUsers, {
      groupName: "label",
      groupId: "value",
    });

    return transformedUsers;
  }

  return userGroups;
};
export const extractGroupList = (groupIds,groupNames) => {
  if(groupIds.length===0 || groupNames.length===0){
    return [];
  }
  const idList = groupIds.split(',');
  const nameList = groupNames.split(',');
  let objectList = [];
  for(let i=0;i<idList.length;i++){
    objectList.push({value: idList[i], label: nameList[i]});
  }
  return objectList;
} 

export const replaceKeyInObjectArray = (arr, keyMap) => {
  if (arr && keyMap) {
    return arr.map((obj) => {
      const newObj = {};
      for (const key in obj) {
        newObj[keyMap[key] || key] = obj[key];
      }
      return newObj;
    });
  }
  return [];
};

export const convertDate = (value, dateFormat) => {
  return moment(value, dateFormat);
};

export const arrayToCommaSperated = (values, type) => {
  if (type === "label") {
    return (
      values
        ?.map((val) => val?.label)
        .filter(Boolean)
        .join(",") || ""
    );
  }

  if (type === "value") {
    return (
      values
        ?.map((val) => val?.value)
        .filter(Boolean)
        .join(",") || ""
    );
  }

  if (type === "email") {
    return (
      values
        ?.map((val) => val?.email)
        .filter(Boolean)
        .join(",") || ""
    );
  }
  if (type === "companyName") {
    return (
      values
        ?.map((val) => val?.company?.companyName)
        .filter(Boolean)
        .join(",") || ""
    );
  }
};

export const filterURL = (params) => {
  const queryParams = Object.entries(params).reduce(
    (a, [k, v]) => (v ? ((a[k] = v), a) : a),
    {}
  );
  let qs = new URLSearchParams(queryParams);
  return `?${qs.toString()}`;
};
export const arraWithComaSepratedData = (arrData, allData) => {
  let findAll = arrData.some((obj) => obj.value == "all");
  if (findAll) {
    return "";
  }
  let allArray = arrData.filter((obj) => obj.value != "all");
  return allArray?.map((obj) => obj.label).join(",") || [];
};
export const handleAllCaseData = (arrData, type) => {
  let findAll = arrData.some((obj) => obj.value === "all");
  if (findAll) {
    return [];
  }
  if (type === "label") {
    return arrData?.map((val) => val && val.label).join(",") || "";
  }

  return arrData?.map((val) => val && val.value);
};

export const NewlineText = (props) => {
  const text = props.text;
  const newText = text.split("\n").map((str) => <div>{str}</div>);
  return newText;
};
export const checkDateWithIn24Hr = (start, end) => {
  if (start && end) {
    var diff = moment.duration(moment(end).second(50).diff(moment(start)));
    var days = parseInt(diff.asDays(), 10); //84
    var hours = parseInt(diff.asHours(), 10); //2039 hours, but it gives total hours in given miliseconds which is not expacted.
    hours = hours - days * 24; // 23 hours
    var minutes = parseInt(diff.asMinutes(), 10); //122360 minutes,but it gives total minutes in given miliseconds which is not expacted.
    minutes = minutes - (days * 24 * 60 + hours * 60);
    if (days === 1 && hours === 0 && minutes === 0) {
      return true;
    }
    if (days === 0 && hours <= 24) {
      return true;
    }
    return false;
  }
};

export const getWeekDayAccordingDate = (date) => {
  return moment(date).isoWeekday().toString();
};

export function getCookiesValuesByPositions(positions) {
  const cookies = new Cookies();
  let cookieValue = cookies.get("gph");

  if (cookieValue) {
    cookieValue = cookieValue.replace("s:", "");
    cookieValue = cookieValue.substring(0, cookieValue.lastIndexOf("."));
    const values = cookieValue.split("~");

    const result = {};
    positions.forEach((position, index) => {
      result[`value${index + 1}`] = values[position];
    });

    return result;
  } else {
    return null; // or handle the case when the cookie is not found
  }
}

export function setSelectValueByText(selectId, text) {
  const select = document.getElementById(selectId);

  for (let i = 0; i < select?.options?.length; i++) {
    if (select?.options[i]?.text?.trim() === text.trim()) {
      select.value = select?.options[i]?.value?.toString();
      break;
    }
  }
}
