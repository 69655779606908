
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../../initialState';


export const escalationPlanList = (state = [], action) => {
    switch (action.type) {
        case 'LOAD_ESCALATION_PLAN_LIST_SUCCESS':
            return action.payload;
        default:
            return state;
    }
}


export const escalationPlanLevel = (state = [], action) => {
    switch (action.type) {
        case 'LOAD_ESCALATION_PLAN_LEVEL_SUCCESS':
            return action.payload;
        default:
            return state;
    }
}

export const escalationPlanDetail = (state = [], action) => {
    switch (action.type) {
        case 'LOAD_ESCALATION_PLAN_DETAIL_SUCCESS':
            return action.payload;
        default:
            return state;
    }
}

export const escalationPlansLevelDetail = (state = [], action) => {
    switch (action.type) {
        case 'LOAD_ESCALATION_PLAN_LEVEL_DETAIL_SUCCESS':
            return action.payload;
        default:
            return state;
    }
}
