
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from "axios";
import Swal from "sweetalert2";
import { navigationHooks } from "../../../helpers/NavigationHook";


const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

export const getAdvertisementList = () => {
  return (dispatch) => {
    dispatch({ type: "FDN_DATA_FETCHING" });
    api
      .get("/api/getAdvertisementList")
      .then((response) => {
        console.log("Advertisement List --- ", response.data);
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
        dispatch({
          type: "ADVERTISEMENT_LIST_FETCHED_SUCCESSFULLY",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
        console.log(err);
      });
  };
};

export const getAdvertisementCount = (companyId, sequence, advId = "") => {
  return (dispatch) => {
    dispatch({ type: "FDN_DATA_FETCHING" });
    api
      .get(`/api/getAdvertisementCount?companyID=${companyId}&advertSequence=${sequence}${advId !== "" ? `&id=${advId}` : ``}`)
      .then((response) => {
        console.log("Advertisement List --- ", response.data);
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
        dispatch({
          type: "ADVERTISEMENT_LIST_FETCHED_SUCCESSFULLY",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
        console.log(err);
      });
  };
};

export const getAdvertisementEditData = (advId, companyId) => {
  return (dispatch) => {
    dispatch({ type: "FDN_DATA_FETCHING" });
    api
      .get(`/api/getAdvertisement?id=${advId}&companyID=${companyId}`)
      .then((response) => {
        let advData = response.data.advertisement;
        let advCompany = response.data["Company List"].filter((company) => company.field1Value == advData.companyId);
        console.log('Company Details ---- ', advCompany);
        advData.companyIdforedit = advCompany[0].field1Key;

        console.log("Advertisement List --- ", advData);
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
        dispatch({
          type: "ADVERTISEMENT_EDIT_DATA_FETCHED_SUCCESSFULLY",
          payload: response.data.advertisement,
        });
        dispatch({
          type: "ADVERTISEMENT_IMAGE_UPDATED_SUCCESSFULLY",
          payload: response.data.advertisement.advertisementIconTempURL,
        });
      })
      .catch((err) => {
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
        console.log(err);
      });
  };
};



export const resetAdvertisementData = () => {
  return (dispatch) => {
    dispatch({ type: "ADVERTISEMENT_EDIT_DATA_FETCHED_SUCCESSFULLY", payload: [] });
  };
};


export const createAdvertisement = (payload) => {
  return (dispatch) => {
    dispatch({ type: "FDN_DATA_FETCHING" });
    api
      .post(`/api/createAdvertisement`, payload)
      .then((response) => {
        console.log("Advertisement List --- ", response.data);
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
        dispatch({
          type: "ADVERTISEMENT_SAVED_SUCCESSFULLY",
          payload: response.data,
        });
        let advId = response.data.advertisement.advertisementId
        let companyId = response.data.advertisement.companyId
        dispatch(getAdvertisementEditData(advId, companyId));
      })
      .catch((err) => {
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
        console.log(err);
      });
  };
};

export const updateAdvertisement = (payload) => {
  return (dispatch) => {
    dispatch({ type: "FDN_DATA_FETCHING" });
    api
      .post(`/api/updateAdvertisement`, payload)
      .then((response) => {
        console.log("Advertisement List --- ", response.data);
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
        dispatch({
          type: "ADVERTISEMENT_UPDATED_SUCCESSFULLY",
          payload: response.data,
        });
        Swal.fire('Advertisement Updated Successfully.');
      })
      .catch((err) => {
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
        console.log(err);
      });
  };
};

export const uploadAdvertisementImage = (id, companyId, payload) => {
  return (dispatch) => {
    dispatch({ type: "FDN_DATA_FETCHING" });
    api
      .post(`/fdn-img/api/uploadAdvertisementImage?id=${id}&companyID=${companyId}&type=icon`, payload)
      .then((response) => {
        console.log("Advertisement List --- ", response.data);
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
        dispatch({
          type: "ADVERTISEMENT_IMAGE_UPDATED_SUCCESSFULLY",
          payload: response.data,
        });
        Swal.fire('Advertisement Image Updated Successfully.');
      })
      .catch((err) => {
        dispatch({ type: "FDN_DATA_FETCHED_SUCCESS" });
        console.log(err);
      });
  };
};


export const deleteAdvertisementData = (id) => {
  return (dispatch) => {
    api.delete("/api/deleteAdvertisement/"+id)
      .then((response) => {
        console.log("AdvtDelete",response)
        Swal.fire({
          title: 'Record deleted successfully',
        }).then(result => {
          if (result.isDismissed || result.isConfirmed) {
            navigationHooks.navigate('/advertisement')
          }
        })
      })
      .catch((err) => {
        console.log(err);
      });
  };
};