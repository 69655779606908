
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export default function (state = [], action){
	switch (action.type){
		case 'LOAD_BREAKFIXSGAECHANGE_SUCCESS_TYPEAHED':
				return action.typeaheadData;
		default:
				return state;
		}
}