
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export default function (state = null, action) {

	switch (action.type) {
		case 'TASK_AUDIT_DATA_FETCHED':
			return action.payload;
		default:
			return state;
	}

}