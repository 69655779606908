
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { getChildQuestion } from "../../../../../actions/cart/addToCartActions";
import { useDispatch } from "react-redux";

const RadioCheckBoxGroup = (props) => {
  const dispatch = useDispatch();

  const {
    input,
    responseValues,
    fieldType,
    piSectionFormErrors,
    question,
    validateField,
    responseValueId
  } = props;
  let fieldClass = piSectionFormErrors[input] ? "errorBox" : "";
  
  switch (fieldType) {
    case "radio":
      return (
        <div className={"d-flex align-items-center flex-wrap " + fieldClass}>
          {responseValues.map((option, index) => {
            return (
              <label key={option.id ? option.id : index}>
                <input
                  type={fieldType}
                  name={input}
                  value={option.id ? option.id : option.label}
                  className={""}
                  onClick={(event) => {
                    validateField(question, event);
                    if (question.haveChild === "true") {
                      dispatch(getChildQuestion(
                        question,
                        event.target.value,
                        props.componentIds,
                        event.target.name
                      ));
                    }
                  }}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />{" "}
                {option.label}
              </label>
            );
          })}
        </div>
      );
    case "checkbox":
      return (
        <div className={"d-flex align-items-center flex-wrap " + fieldClass}>
          {responseValues.map((option, index) => {
            return (
              <label key={option}>
                <input
                  type={fieldType}
                  name={`${input}[${index}]`}
                  value={option}
                  data-id={responseValueId?.split(";")[index]}
                  onChange={(event) => {
                    validateField(question, event);
                  }}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />{" "}
                {option}
              </label>
            );
          })}
        </div>
      );
  }
};

export default RadioCheckBoxGroup;
