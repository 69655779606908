
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import axios from 'axios';
import Spinner from 'react-spinkit';
import { GLOBAL } from "_Globals";
import {IoClose} from "react-icons/io5";

const api = axios.create({
  headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

class XsmCiQuickView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      breakFixCiDetails: []
    };
  }
  setData(){
    if (this.props.ciId == "" || this.props.ciId !== undefined) {
      var str = {};
      str.offeringId = this.props.offeringId;
      str = JSON.stringify(str);
      const request = api.get(GLOBAL.ciEditDetailsUrl + "/id/" + this.props.ciId);
      request.then((response) => {
        this.setCiInfo(response);
      });
    }
  }
  componentWillMount() {
    this.setData();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.ciId !== prevProps.ciId) {
      this.setData();
    }
  }

  setCiInfo(service) {
    this.setState({
      breakFixCiDetails: service.data
    });
    //console.log(this.state.breakFixCiDetails.data.offeringName)
  }

  //===============

  //===============

  render() {
    // if (this.state.breakFixCiDetails.length == 0) {
    //   //alert("length"+this.state.breakFixCiDetails.length)
    //   return (
    //     <Spinner spinnerName="three-bounce" />
    //   );
    // }
    return (
      <div>
        <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
            <ul>
                <li>
                <button
                    type='button'
                    title="Minimize the right panel"
                    bsClass=""
                    bsStyle=""
                    className="closerightPanelBtn"
                    onClick={() => {
                    this.props.rightEditPanel(false);
                    }}
                >
                    <IoClose />
                </button>
                </li>
            </ul>
        </div>
        <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">CI Quick View</div></div>
        <div className='rBoxGap'>
        {
          this.state.breakFixCiDetails.length == 0 ? <Spinner spinnerName="three-bounce" />
            :

            //   <ListGroup  className="ListDetails">
            //   <ListGroup.Item ><span className="labSpan">CI Name-</span>{this.state.breakFixCiDetails.CI_NAME}</ListGroup.Item>
            //   <ListGroup.Item ><span className="labSpan">Description-</span>{this.state.breakFixCiDetails.DESCRIPTION}</ListGroup.Item>
            //   <ListGroup.Item ><span className="labSpan">Company-</span>{this.state.breakFixCiDetails.COMPANY_NAME}</ListGroup.Item>
            //   <ListGroup.Item ><span className="labSpan">Class-</span>{this.state.breakFixCiDetails.CLASS_NAME}</ListGroup.Item>
            //   <ListGroup.Item ><span className="labSpan">Status-</span>{this.state.breakFixCiDetails.STATUS_NAME}</ListGroup.Item>
            // </ListGroup>

            <ListGroup bsPrefix=' ' as='ul' className="margin-t-0">
              <ListGroup.Item bsPrefix=' ' as='li' className="rwTable"  style={{ 'border-top': 'none' }}><span className="rwKey">CI Name</span><span className="rwVal">{this.state.breakFixCiDetails.CI_NAME}</span></ListGroup.Item>
              <ListGroup.Item bsPrefix=' ' as='li' className="rwTable" ><span className="rwKey">Company</span><span className="rwVal">{this.state.breakFixCiDetails.COMPANY_NAME}</span></ListGroup.Item>
              <ListGroup.Item bsPrefix=' ' as='li' className="rwTable" ><span className="rwKey">Class</span><span className="rwVal">{this.state.breakFixCiDetails.CLASS_NAME}</span></ListGroup.Item>
              <ListGroup.Item bsPrefix=' ' as='li' className="rwTable" ><span className="rwKey">Status</span><span className="rwVal">{this.state.breakFixCiDetails.STATUS_NAME}</span></ListGroup.Item>
              {/* <ListGroup.Item  className="rwTable" style={{ 'border-bottom': 'none', "paddingTop": "20px" }}>
                <div className="rwDynaCls">{this.state.breakFixCiDetails.DESCRIPTION}</div>
              </ListGroup.Item> */}
            </ListGroup>

          // <ButtonToolbar className="margin-t-15"><Button bsStyle="primary">Component</Button><Button bsStyle="primary">Chain Roles</Button><Button bsStyle="primary">Details</Button></ButtonToolbar> 
        }
        </div>
      </div>
    );
  }
}

export default XsmCiQuickView;
