
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';

import OfferingDetails from '../common/OfferingDetailsComponents';
import ProductDetails from '../common/ProductDetails';
import ServiceReview from '../common/ServiceReview';
import AskToCommunity from '../common/askToCommunity';
import { connect } from 'react-redux';
import {getRelatedOfferings} from '_Actions/offeringDetails/offeringDetailsActions';
import SlaDetails from '../common/SlaDetails';


class LeftSidePart extends React.Component{
	constructor(props) {
		super(props);
		this.state ={

		};
		this.rightSideData = this.rightSideData.bind(this);
	}

	componentWillMount() {
		this.props.getRelatedOfferings(this.props.offeringId);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.offeringId !== nextProps.offeringId) {
			this.props.getRelatedOfferings(nextProps.offeringId);
		}
	}


	rightSideData(propsValue) {
		switch (propsValue) {
			case 'Product Details': return (<ProductDetails offering={this.props.rightSectionDetails} translatoroffering={this.props.translator} />);
			case 'SLA Details': return (<SlaDetails slaDetails={this.props.rightSectionDetails} translatoroffering={this.props.translator} />);
			case 'Service Review': return (<ServiceReview offeringReview={this.props.rightSectionDetails} translatoroffering={this.props.translator} />);
			case 'Community Answers': return (<AskToCommunity translatoroffering={this.props.translator} offeringId={this.props.offeringId} offeringName={this.props.offeringName} />);
			default: return (<OfferingDetails relatedOfferings={this.props.relatedOfferings} requestType={this.props.requestType} offeringId={this.props.offeringId} offeringComponentsForCart={this.props.selectedComponentForOffering} translatoroffering={this.props.translator} />);
		}
	}
	render(){
		return(
			<div>{this.rightSideData(this.props.otherActionbtnValue)}</div>
		);
	}
}


const mapStateToProps = ({ relatedOfferings }) => {
	return {
		relatedOfferings
	};
};
export default connect(mapStateToProps, {
	getRelatedOfferings
})(LeftSidePart);

