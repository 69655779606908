
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GLOBAL } from "../../../components/Globals";

export const governanceApi = createApi({
  reducerPath: "governanceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: window.location.origin,
  }),
  tagTypes: [
    "GovMeeting",
    "updateMeeting",
    "MeetingStatus",
    "WorkNotesPost",
    "DicisionPoint",
    "Tracking",
    "OccurenceById",
  ],
  endpoints: (builder) => ({
    getAllForum: builder.query({
      query: (custId) => `${GLOBAL.getAllForum}${custId}`,
    }),
    getAllCategory: builder.query({
      query: (custId) => `/governance/rest/categoryDetails?tenantId=${custId}`,
    }),
    getUserInfo: builder.query({
      query: () => ({
        url: "/api/userInfo",
        method: "GET",
        headers: {
          query: JSON.stringify({ user_id: "" }),
        },
      }),
    }),

    committeDetailsByFrmName: builder.query({
      query: ({ name, custId, category, group_type }) => {
        return (
          GLOBAL.allcommitteDetailsByForumName +
          `?forumId=${name}&categoryId=${category}&companyId=${custId}&groupType=${group_type}`
        );
      },
    }),
    getAllTrack: builder.query({
      query: ({ custId }) => {
        return GLOBAL.trackDetails(custId);
      },
    }),
    getAllSubTrack: builder.query({
      query: ({ name }) => {
        return GLOBAL.subTrackDetailsByTrackName(name);
      },
    }),
    getRegionList: builder.query({
      query: () => {
        return GLOBAL.getRegionListFnd;
      },
    }),
    getLocationDetails: builder.query({
      query: () => {
        return GLOBAL.locationDetails;
      },
    }),
    getCiSupportAllGroup: builder.query({
      query: ({ custId }) => {
        return GLOBAL.getCiSupportAllGroup(custId);
      },
    }),
    getMeetingDefById: builder.query({
      query: ({ meetingId }) => {
        return GLOBAL.getMeetingDefinationDetails(meetingId);
      },
      providesTags: ["MeetingStatus"],
    }),

    getAllPersons: builder.query({
      query: ({ companyId = "", str }) => ({
        url: GLOBAL.consumerUsersNewUrl,
        method: "GET",
        headers: {
          query: JSON.stringify({ companyId: companyId, like: str }),
        },
      }),
    }),

    getAllOccurances: builder.query({
      query: ({ definationId, pageNumber, pageSize, queryString }) => {
        return GLOBAL.getOccurrencesList(
          definationId,
          pageNumber,
          pageSize,
          queryString
        );
      },
      providesTags: ["MeetingStatus"],
    }),
    getAction: builder.query({
      query: ({ queryString }) => {
        return GLOBAL.getAction + queryString;
      },
      providesTags: ["MeetingStatus"],
    }),
    notifyAction: builder.mutation({
      query: ({ data: data, queryString }) => ({
        url: GLOBAL.notifyAction + queryString,
        method: "POST",
        body: data,
      }),
      //providesTags: ["updateMeeting", "MeetingStatus"],
      providesTags: (result, error, body) => [
        { type: "updateMeeting", id: "LIST" },
        { type: "MeetingStatus", id: "LIST" },
      ],
      invalidatesTags: ["MeetingStatus"],
    }),
    getStatusTimeline: builder.query({
      query: ({ queryString }) => {
        return GLOBAL.getStatusTimeline + queryString;
      },
      providesTags: ["MeetingStatus"],
    }),
    getOccurenceById: builder.query({
      query: ({ Id }) => {
        return GLOBAL.occurenceById(Id);
      },
      providesTags: (result, error, body) => [
        { type: "OccurenceById", id: "LIST" },
        { type: "MeetingStatus", id: "LIST" },
      ],
      invalidatesTags: ["GovMeeting"],
    }),
    updateOccurrence: builder.mutation({
      query: ({ data, companyId }) => ({
        url: GLOBAL.updateOccurrence + `?tenantId=${companyId}`,
        method: "PUT",
        body: data,
      }),
      providesTags: ["MeetingStatus"],
    }),
    updateMeetingDefinationDetails: builder.mutation({
      query: ({ data, companyId }) => ({
        url: GLOBAL.updateMeetingDefinationDetails + `?tenantId=${companyId}`,
        method: "PUT",
        body: data,
      }),
      providesTags: ["MeetingStatus"],
      invalidatesTags: ["GovMeeting"],
    }),

    cancelMeetingOccurrence: builder.mutation({
      query: ({ data, queryString }) => ({
        url: GLOBAL.cancelMeetingOccurrence + queryString,
        method: "POST",
        body: data,
      }),
      providesTags: ["MeetingStatus", "OccurenceById"],
      invalidatesTags: ["GovMeeting","MeetingStatus"],
    }),
    createMeeting: builder.mutation({
      query: ({ data }) => ({
        url: GLOBAL.saveMeettingDef,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["GovMeeting"],
    }),
    getGovAudiLogs: builder.query({
      query: ({ queryString }) => {
        return GLOBAL.govAuditLogs + queryString;
      },
    }),
    saveWorkNotesDetails: builder.mutation({
      query: ({ data, queryString }) => ({
        url: GLOBAL.saveWorkNotesDetails + queryString,
        method: "POST",
        body: data,
      }),
      providesTags: ["WorkNotesPost"],
      invalidatesTags: ["WorkNotesPost"],
    }),
    getWorkNotesDetails: builder.query({
      query: ({ queryString }) => {
        return GLOBAL.getWorkNotesDetails + queryString;
      },
      providesTags: ["WorkNotesPost"],
    }),
    getMinutesDetails: builder.query({
      query: ({ queryString }) => {
        return GLOBAL.getMinutesDetails + queryString;
      },
      providesTags: ["DicisionPoint"],
    }),
    saveMinutesDetails: builder.mutation({
      query: ({ data, queryString }) => ({
        url: GLOBAL.saveMinutesDetails + queryString,
        method: "POST",
        body: data,
      }),
      providesTags: ["DicisionPoint"],
      invalidatesTags: ["DicisionPoint"],
    }),
    updateMinutesDetails: builder.mutation({
      query: ({ data, queryString }) => ({
        url: GLOBAL.updateMinutesDetails + queryString,
        method: "PUT",
        body: data,
      }),
      providesTags: ["DicisionPoint"],
      invalidatesTags: ["DicisionPoint"],
    }),
    getTrackingDetails: builder.query({
      query: ({ Id, queryString }) => {
        return GLOBAL.trackingDetails(Id) + queryString;
      },
      providesTags: ["Tracking"],
      invalidatesTags: ["Tracking"],
    }),
    saveOccurranceTracking: builder.mutation({
      query: ({ data, queryString }) => ({
        url: GLOBAL.saveOccurranceTrackingDetails + queryString,
        method: "POST",
        body: data,
      }),
      providesTags: ["Tracking"],
      invalidatesTags: ["Tracking"],
    }),
    getMinutesAttachmentTypes: builder.query({
      query: ({ queryString }) => {
        return GLOBAL.getMinutesAttachmentTypes + queryString;
      },
    }),
    deleteMinutesDetails: builder.mutation({
      query: ({ queryString = "" }) => ({
        url: GLOBAL.deleteMinutesDetails + queryString,
        method: "DELETE",
      }),
      providesTags: ["DicisionPoint"],
      invalidatesTags: ["DicisionPoint"],
    }),
    getActionItemWorkNotes: builder.query({
      query: ({ queryString = "" }) => {
        return GLOBAL.getActionItemWorkNotes + queryString;
      },
    }),
    postActionItemWorkNotes: builder.mutation({
      query: ({ data, queryString, actionitemCode }) => ({
        url: GLOBAL.postActionItemWorkNotes(actionitemCode) + queryString,
        method: "POST",
        body: data,
      }),
    }),
    getSuppliers: builder.query({
      query: ({ queryString = "" }) => {
        return GLOBAL.getSuppliers + queryString;
      },
    }),
    getMeetingOccurrencesByMonth: builder.query({
      query: ({ queryString = "" }) => {
        return GLOBAL.getMeetingOccurrencesByMonth + queryString;
      },
    }),
    contractVendorUrl: builder.query({
      query: ({ queryString = "" }) => {
        return GLOBAL.contractVendorUrl + queryString;
      },
    }),
    getAllMeetingDefStatus: builder.query({
      query: ({ queryString = "" }) => {
        return GLOBAL.getAllMeetingDefinationSts + queryString;
      },
    }),

    profileDetailsUrl: builder.query({
      query: ({ userid = "" }) => ({
        url: GLOBAL.profileDetailsUrl,
        method: "GET",
        headers: {
          query: JSON.stringify({ userid: userid }),
        },
      }),
      keepUnusedDataFor: 0
    }),
    profileTimeZoneUrl: builder.query({
      query: () => {
        return GLOBAL.profileTimeZoneUrl;
      },
    }),
    getCancelMeetingDefination: builder.mutation({
      query: ({ data, queryString = "" }) => ({
        url: GLOBAL.getCancelMeetingDefination + queryString,
        method: "POST",
        body: data,
      }),
      providesTags: (result, error, body) => [
        { type: "updateMeeting", id: "LIST" },
        { type: "MeetingStatus", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useGetMeetingDefByIdQuery,
  useGetUserInfoQuery,
  useGetAllForumQuery,
  useGetAllCategoryQuery,
  useCommitteDetailsByFrmNameQuery,
  useGetAllTrackQuery,
  useGetAllSubTrackQuery,
  useGetRegionListQuery,
  useGetLocationDetailsQuery,
  useGetCiSupportAllGroupQuery,
  useCreateMeetingMutation,
  useGetAllOccurancesQuery,
  useNotifyActionMutation,
  useGetActionQuery,
  useGetStatusTimelineQuery,
  useGetOccurenceByIdQuery,
  useGetAllPersonsQuery,
  useUpdateOccurrenceMutation,
  useGetGovAudiLogsQuery,
  useCancelMeetingOccurrenceMutation,
  useGetWorkNotesDetailsQuery,
  useSaveWorkNotesDetailsMutation,
  useGetMinutesDetailsQuery,
  useSaveMinutesDetailsMutation,
  useUpdateMinutesDetailsMutation,
  useGetTrackingDetailsQuery,
  useSaveOccurranceTrackingMutation,
  useGetMinutesAttachmentTypesQuery,
  useDeleteMinutesDetailsMutation,
  useUpdateMeetingDefinationDetailsMutation,
  useGetActionItemWorkNotesQuery,
  usePostActionItemWorkNotesMutation,
  useGetSuppliersQuery,
  useContractVendorUrlQuery,
  useGetMeetingOccurrencesByMonthQuery,
  useGetAllMeetingDefStatusQuery,
  useProfileDetailsUrlQuery,
  useProfileTimeZoneUrlQuery,
  useGetCancelMeetingDefinationMutation,
} = governanceApi;

const { reducer } = governanceApi;
export default reducer;
