
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Col, Form, ListGroup, Row, Table } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import ListLoader from "../../../common/loaders/ListLoader.js";
import { Field, change, reduxForm } from "redux-form";
import {
  deleteLevels,
  loadEscalationPlansDetail,
  loadPlanLevels,
} from "../../../../actions/onCallSchedule/escalationAction.js";
import { useParams } from "react-router";
import { FaPencil } from "react-icons/fa6";
import AddLevelForm from "./addLevelForm.js";
import { TypeaheadExampleSingleSelect } from "../../../common/formFields.js";
import {
  _dropDown,
  _inputField,
} from "../../../common/ReduxFormFields/CategoryBoard/formFields.js";
import axios from "axios";
import { validateEmail } from "../../../../utils/common.js";
import Swal from "sweetalert2";

const modules = [{ field1Key: "Incident", field1Value: "Incident" }];
const statusOptions = [
  { field1Key: "Active", field1Value: "Active" },
  { field1Key: "Inactive", field1Value: "Inactive" },
];
const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

const validate = (values, props) => {
  const errors = {};
  if (!values.plan) errors.plan = "Please choose escalation plan";
  if (!values.escalationModule)
    errors.escalationModule = "Please choose escalation module";
  if (!values.escalationStatus)
    errors.escalationStatus = "Please choose escalation status";
  return errors;
};

const EscalationLevelView = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const translator = useSelector((state) => state.spcmReducer.tr);
  const escalationPlanLevel = useSelector((state) => state.escalationPlanLevel);
  const escalationPlanDetail = useSelector(
    (state) => state.escalationPlanDetail
  );
  const [viewForm, setViewForm] = useState(false);
  const showLoader = useSelector((state) => state.showLoader.loading);
  const [levelId, setLevelId] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [typedCompany, setTypedCompany] = useState("");
  const root = document.documentElement;
  viewForm
    ? root?.style.setProperty("--modalBackDrop", 0.5)
    : root?.style.setProperty("--modalBackDrop", 0);


  useEffect(() => {
    dispatch(loadPlanLevels(params?.planId));
    dispatch(loadEscalationPlansDetail(params?.planId));
    getCompanyList();
  }, []);

  useEffect(() => {
    if (escalationPlanDetail && Object.keys(escalationPlanDetail).length > 0) {
      dispatch(
        change(
          "EditEscalationForm",
          "plan",
          escalationPlanDetail.escalationPlanName
        )
      );
      dispatch(
        change(
          "EditEscalationForm",
          "escalationModule",
          escalationPlanDetail.moduleName
        )
      );
      dispatch(
        change(
          "EditEscalationForm",
          "escalationStatus",
          escalationPlanDetail.status
        )
      );
      props.setSelectedCompany([
        {
          id: escalationPlanDetail.companyId,
          label: escalationPlanDetail.companyName || "NA",
        },
      ]);
    }
  }, [escalationPlanDetail]);

  /// company
  const onCompanySelection = (company) => {
    props.setSelectedCompany(company);
  };

  const setErrorCompanyColor = () => {
    if (props.selectedCompany.length === 0) props.setCompanyError("error");
    else props.setCompanyError("");
  };

  const companyCleared = (e) => {
    setTypedCompany(e);
    props.setSelectedCompany([]);
  };

  const onCrossClickCompany = () => {
    props.setSelectedCompany([]);
    props.setCompanyError("error");
  };

  const getCompanyList = () => {
    let impactedServiceData;
    api.get("/api/serviceportfolio/usercompanylist").then((responseCompany) => {
      let companyList = [];
      impactedServiceData = responseCompany.data;
      impactedServiceData.map((item) => {
        companyList.push({
          label: item.field1Key,
          id: item.field1Value,
        });
        setCompanyList(companyList);
      });
    });
  };

  const editLevel = (levelId) => {
    setLevelId(levelId);
    setViewForm(true);
  };

  const deleteLevel = (levelId) => {
    if(escalationPlanLevel.length===1){
      Swal.fire({title:"1 level should be there!"});
      return;
    }
    dispatch(deleteLevels(levelId, params?.planId));
  };

  const renderLevels = (levelList) => {
    if (levelList && levelList.length > 0) {
      return levelList.map((value, index) => {
        let users = [];
        let channels = [];
        if (value?.sxEscalationLevelMemberList) {
          value.sxEscalationLevelMemberList.forEach((member) => {
            users.push(member.memberName);
          });
        }
        if (value?.sxEscalationCommChannelList) {
          value.sxEscalationCommChannelList.forEach((channel) => {
            channels.push(channel.channelName);
          });
        }
        const usertext = users.join(", ");
        const emailText = value?.externalEmail?value?.externalEmail.replaceAll(",", ", "):"";
        return (
          <ListGroup.Item key={"level-list-" + index} as="li" prefix=" ">
            <div className="Escalation-level-list-data">
              <Table responsive className="table custom-border-1">
                <tr>
                  <th width="15%">{translator["Level"]}</th>
                  <th width="15%">{translator["Notify User"]}</th>
                  <th>{translator["Emails"]}</th>
                  <th width="15%">{translator["Escalation Timeout"]}</th>
                  <th width="15%">{translator["Communication Channel"]}</th>
                  <td  width={"5%"} rowspan="2" style={{ "border-left": "1px solid #ddd" }}>
                    <span className="edit-level">
                      <a
                        title={translator["Edit Level"]}
                        href={void 0}
                        onClick={() => editLevel(value.id)}
                      >
                        <FaPencil />
                      </a>
                    </span>
                    <span className="trash-level">
                      <a
                        title={translator["Delete Level"]}
                        href={void 0}
                        onClick={() => deleteLevel(value.id)}
                      >
                        <i className="fa fa-trash-o"></i>
                      </a>
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>Escalation Level {value?.escalationLevel}</td>
                  <td title={usertext}>
                    {usertext.length > 70
                      ? usertext.substring(0, 70) + "..."
                      : usertext.length > 0
                      ? usertext
                      : "---"}
                  </td>
                  <td title={value?.externalEmail}>
                    {emailText.length > 100
                      ? emailText.substring(0, 100) + "..."
                      : emailText.length > 0
                      ? emailText
                      : "---"}
                  </td>
                  <td>{value?.escalationTimeout} mins</td>
                  <td>{channels.length > 0 ? channels.join(",") : "---"}</td>
                </tr>
              </Table>
            </div>
          </ListGroup.Item>
        );
      });
    } else {
      return (
        <div>
          <h2>{translator["There is no matching data available"]}</h2>
        </div>
      );
    }
  };

  return (
    <div>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={3} sm={3} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>
                  <span className="rStar"></span>
                  {translator["Company"]}
                </Form.Label>
                <Field
                  id="companyDropdown"
                  name="company"
                  className="form-control"
                  component={TypeaheadExampleSingleSelect}
                  onSelection={onCompanySelection}
                  setErrorColor={setErrorCompanyColor}
                  options={companyList}
                  selectedOptions={props.selectedCompany}
                  onInputChange={companyCleared}
                  onCrossClick={onCrossClickCompany}
                  typedValue={typedCompany}
                  errorClass={props.companyError}
                  disabled={true}
                />
              </Form.Group>
            </Col>
            <Col md={3} sm={3} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>
                  <span className="rStar"></span>
                  {"Plan"}
                </Form.Label>
                <div className="heightdvTypehd">
                  <Field
                    component={_inputField}
                    name="plan"
                    className="form-control"
                  ></Field>
                </div>
              </Form.Group>
            </Col>
            <Col md={3} sm={3} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>
                  <span className="rStar"></span>
                  {translator["Module"]}
                </Form.Label>
                <div className="heightdvTypehd infoBtnDiv">
                  <Field
                    component={_dropDown}
                    name="escalationModule"
                    options={modules}
                    className="form-control"
                  />
                </div>
              </Form.Group>
            </Col>
            <Col md={3} sm={3} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>
                  <span className="rStar"></span>
                  {translator["Status"]}
                </Form.Label>
                <div className="heightdvTypehd infoBtnDiv">
                  <Field
                    component={_dropDown}
                    name="escalationStatus"
                    options={statusOptions}
                    className="form-control"
                  />
                </div>
              </Form.Group>
            </Col>
            {/* <Col md={4} sm={4} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>{"Escalation Id "}</Form.Label>
            <div className="heightdvTypehd infoBtnDiv">
              <Field
                component={_inputField}
                name="escalationId"
                className="form-control"
                disabled
              ></Field>
              <Button
                title="Info"
                bsPrefix=" "
                className="infoicn"
                onClick={() => {}}
              >
                <ImInfo />
              </Button>
            </div>
          </Form.Group>
        </Col> */}
            <div className="border margin-b-15"> </div>
          </Row>
        </Col>
        <Col md={12} className="padding-r-10 padding-l-10">
          <ListGroup as={"ol"} bsPrefix=" " className="Escalation-level-list">
            {showLoader ? <ListLoader /> : renderLevels(escalationPlanLevel)}
          </ListGroup>
        </Col>
        <Col md={12}>
          {viewForm && (
            <AddLevelForm
              formType={"edit"}
              show={viewForm}
              planId={params?.palnId}
              levelId={levelId}
              selectedCompany={props.selectedCompany}
              setViewForm={setViewForm}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  // shiftListData: state.shiftListData
});

export default reduxForm({
  form: "EditEscalationForm",
  destroyOnUnmount: true,
  validate,
  enableReinitialize: true,
})(connect(mapStateToProps)(EscalationLevelView));
