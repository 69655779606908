
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import {
	getAllRuleConfigurationsData,
	getCompanyData, getParameterValueData,
	getEventData, getModuleData, getAmsFilteredData,
	getAssignmentGroups, resetAttributeList
} from '../../../actions/amsActions/amsActions.js';
import { getQueryBuilderAllRules } from '_Actions/common/queryBuilderAction';

import ListLoader from "../../common/loaders/ListLoader.js";

import Pagination from "react-js-pagination";
import { IoSearch } from 'react-icons/io5';



const AmsHomeListView = (props) => {
	const dispatch = useDispatch();
	const tr = useSelector(state => state.spcmReducer.tr);
	const amsBoardShowLoader = useSelector(state => state.amsBoardShowLoader);
	const resetAttributeList = useSelector(state => state.resetAttributeList);
	const queryBuilderAllRulesData = useSelector((state) => state.queryBuilderAllRulesData);

	console.log("queryBuilderAllRulesData678", queryBuilderAllRulesData);

	useEffect(() => {
		dispatch(getQueryBuilderAllRules());
	}, []);

	const onRowSelect = (e, row, index)=> {
		console.log("row79",row);
		props.getTaskDetails(row.id, row.consumerCompany, row.module, row.ruleName, row.weight, row.assignmentCompany, row.assignmentGroupName, row.serviceName, row.ciServiceName, row.createdBy, row.status, row.assignmentFor, row.ciClassName, row.conditionalJson, row.isContextual);
		// props.resetAttributeList();
		props.handlePanelClick("0");
		props.showRightSide(true);
		props.showActiveRow(index);
	}

	const renderTable = (data) => { 
		return data.map((data, index) => {
			return (
				<tr className={props.isRightSideVisible && index == props.rowIndex ? "myActive" : ""}
					onClick={(e) => onRowSelect(e, data, index)}
				>
					<td title={data.ruleName}>{data.ruleName}</td>
					<td>{data.consumerCompany}</td>
					<td>{data.module}</td>
					<td>{data.assignmentFor}</td>
					<td>{data.assignmentGroupName}</td>
					<td>{data.assignmentCompany}</td>
					<td>{data.status}</td>
					<td>{data.modifiedOn}</td>
				</tr>
			);
		});
	}

	return (
		<div>
			<div className="respondv">
				<div className='table-responsive'>
					<Table striped bordered condensed hover className="tableView nowrapWhright" style={{ "marginTop": "0px" }}>
						<thead>
							<tr>
								<th>
									<div className="sortParArr">{tr['Rule Name']}</div>

								</th>
								<th>
									<div className="sortParArr">{tr['Consumer Company']}</div>

								</th>
								<th>
									<div className="sortParArr">{tr['Module']}</div>

								</th>
								<th>
									<div className="sortParArr">{tr['Assignment For']}</div>

								</th>
								<th>
									<div className="sortParArr">{tr['Support Group']}</div>

								</th>
								<th>
									<div className="sortParArr">{tr['Support Company']}</div>

								</th>
								<th>
									<div className="sortParArr">{tr['Status']}</div>

								</th>
								<th>
									<div className="sortParArr">{tr['Modified Date']}</div>

								</th>
							</tr>
						</thead>
						<tbody>
							{
									renderTable(queryBuilderAllRulesData)
							}
						</tbody>
					</Table>
				</div>

			</div>

		</div>
	)
}

export default AmsHomeListView;
