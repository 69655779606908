
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import { LuFilterX } from "react-icons/lu";
import { HiOutlineRefresh } from "react-icons/hi";

import Cookies from "universal-cookie";
import { loadCmdbItemList } from "../../../actions/cmdb/quickViewAction";
import moment from "moment";
const cookies = new Cookies();

let cookiesData = cookies.get("gph");
if (cookiesData) cookiesData = cookiesData.replace("s:", "");
if (cookiesData)
  cookiesData = cookiesData.substring(0, cookiesData.lastIndexOf("."));
cookiesData = cookiesData.split("~");
let loggedInUserId = Number(cookiesData[43]);

const MyAssetHeader = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const myAssetCIList = useSelector((state) => state.cmdbQuickItemList);
  const cmdbQuickTechnicalItemList = useSelector((state) => state.cmdbQuickTechnicalItemList);
  const cmdbQuickBusinessItemList = useSelector((state) => state.cmdbQuickBusinessItemList);
  const isWalkupEnabled = useSelector((state) => state.walkupEnabled.walkupEnabled);

  const handleClearAllFilters = (fnCall) => {
    props.setPage(1);
    props.setLimit(10);
    props.setAssetIdSearchInput("");
    props.setNameSearchInput("");
    props.setCompanySearchInput("");
    props.setSelectedTypeValue([]);
    props.setSelectedStatusValue([]);
    props.setSelectedClassValue([]);
    props.setStatus([]);
    props.setSearchClass([]);
    props.setType([]);
    props.showRightSideFun(false);
    props.setIsClearAllFilter(false);
    props.setStartDate({});
    props.setEndDate({});
    if (props.dateRangePickerRef != undefined) {
      props.dateRangePickerRef.endDate = moment();
      props.dateRangePickerRef.startDate = moment();
    }
	if (fnCall !== "filterTab") {
    if (props.filterTabActive == 1) {
      props.setSearchParams({ owner_id: loggedInUserId });
      dispatch(loadCmdbItemList(1, 10, { owner_id: loggedInUserId }, [], [], []));
    } else if (props.filterTabActive == 2) {
      props.setSearchParams({ technical_owner_id: loggedInUserId });
      dispatch(loadCmdbItemList(1, 10, { technical_owner_id: loggedInUserId }, [], [], []));
    } else if (props.filterTabActive == 3) {
      props.setSearchParams({ business_owner_id: loggedInUserId });
      dispatch(loadCmdbItemList(1, 10, { business_owner_id: loggedInUserId }, [], [], []));
    }
  }
  };

  const handleRefreshClick = () => {
    props.showRightSideFun(false);
    props.setPage(1);
    props.setLimit(10);
    dispatch(loadCmdbItemList(1, 10, props.searchParams, props.type, props.status, props.searchClass));
  };

  const handleFilterTab = (event) => {
    let selectedFilter = event.target.value;

    switch (selectedFilter) {
      case "Assigned to me":
        props.setFilterTabActive(1);
        handleClearAllFilters("filterTab");
        props.setSearchParams({ owner_id: loggedInUserId });
        dispatch(loadCmdbItemList(1, 10, { owner_id: loggedInUserId }, [], [], []));
        break;
      case "Technical owner":
        props.setFilterTabActive(2);
        handleClearAllFilters("filterTab");
        props.setSearchParams({ technical_owner_id: loggedInUserId });
        dispatch(loadCmdbItemList(1, 10, { technical_owner_id: loggedInUserId }, [], [], []));
        break;
      case "Business owner":
        props.setFilterTabActive(3);
        handleClearAllFilters("filterTab");
        props.setSearchParams({ business_owner_id: loggedInUserId });
        dispatch(loadCmdbItemList(1, 10, { business_owner_id: loggedInUserId }, [], [], []));
        break;
    }
  };

  return (
    <>
      <Row className="margin-b-15">
      <Col lg={10} md={10} sm={12} xs={12}>
          <ul className="wibTabs">
            <li>
              <Link title={tr["My Requests"]} to="/myOrders">
                {tr["My Requests"]}
              </Link>
            </li>
            {isWalkupEnabled && <li><Link title={tr["My Walk Up Requests"]} to="/walkUpReq">{tr["My Walk Up Requests"]}</Link></li>}
            <li className="active">
              <Link title={tr["My Assets/CIs"]} to="/myAssets">
                {tr["My Assets/CIs"]}
              </Link>
            </li>
            <li>
              <Link title={tr["My Notifications"]} to="/myNotifications">
                {tr["My Notifications"]}
              </Link>
            </li>
          </ul>
        </Col>
        <Col lg={2} md={2} sm={12} xs={12}>
          <div
            aria-label="Page Filters"
            role="contentinfo"
            className="paHedFilter"
          >
            <div className="overlayDv">
              {props.isClearAllFilter && (
                <Button
                  title={tr["Clear All Filters"]}
                  role="button"
                  onClick={() => {
                    handleClearAllFilters();
                  }}
                  bsPrefix=" "
                  className="myBt clrbtn"
                >
                  <LuFilterX />
                </Button>
              )}
            </div>
            <Button
              aria-label={tr["Refresh"]}
              title={tr["Refresh"]}
              role="button"
              bsClass=""
              bsPrefix=" "
              className="myBt"
              onClick={() => {
                handleRefreshClick();
              }}
            >
              <HiOutlineRefresh className="refreshFlip" />
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="margin-b-10">
        <Col lg={8} md={12} sm={12} xs={12}>
          <div aria-label="Table Filter" role="contentinfo" id="assFiltr">
            <div className="filtr">
              <Button
                className={props.filterTabActive == 1 ? "active" : null}
                title={tr["Assigned to me"]}
                bsPrefix=" "
                value="Assigned to me"
                onClick={(e) => {
                  handleFilterTab(e);
                }}
              >
                {tr["Assigned to me"]}
              </Button>
              {cmdbQuickTechnicalItemList?.length > 0 ?
              <Button
                className={props.filterTabActive == 2 ? "active" : null}
                title={tr["Technical owner"]}
                bsPrefix=" "
                value="Technical owner"
                onClick={(e) => {
                  handleFilterTab(e);
                }}
              >
                {tr["Technical owner"]}
              </Button>
              :null}
              {cmdbQuickBusinessItemList?.length > 0 ?
                <Button
                  className={props.filterTabActive == 3 ? "active" : null}
                  title={tr["Business owner"]}
                  bsPrefix=" "
                  value="Business owner"
                  onClick={(e) => {
                    handleFilterTab(e);
                  }}
                >
                  {tr["Business owner"]}
                </Button>
                : null}
             
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default MyAssetHeader;
