
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import  {GLOBAL } from "_Globals";
import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import { reduxForm, SubmissionError } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import { MyContext } from '_MyContext';

import {createDashboardData } from "../../../actions/dashboardConfigration/dashboardConfigAction.js";

let sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const CreateDashboardHeader = (props) => {
  const translator = useSelector((state) => state.spcmReducer.tr);
  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const saveObjRef = useRef(null);

  useEffect(() => {
      context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
      context.newTabPrevent();
    }, []);

  const dispatch = useDispatch();
  let { handleSubmit, submitting } = props;
  const submit = async (values) => {
    let countArr = values.dynamicFieldArr;
    let dynamicFieldValues = countArr.reduce((acc, curr) => {
      acc.push({
        companyIdAttr: values[`companyIdAttr_${curr}`] || "",
        groupIdAttr: values[`groupIdAttr_${curr}`] || "",
        dataSetId: values[`dataSetId_${curr}`]
      })
      return acc;
    }, []);
    return sleep(1000).then(() => {
      if (!values.roles) {
        props.setValueError('error');
        throw new SubmissionError({
          _error:
            "The form has missing required fields, please revalidate the highlighted fields and update the form",
        });
      } else {
       
        let role = values.roles.map((ele)=>ele.value)
        let masterRoles = "";
        if(values.masterRoles) {
          masterRoles = values.masterRoles.map((ele)=>ele.value);
        }
        let postjson;
        postjson = {
          active:values.Active=='True'?true:false,  
          classification: values.Classification,
          dashboardResId:values.DashboardResourceID,
          dashboardUUID:values.DashboardUUID,
          dashboardId:values.Dashboard_ID,
          datasetId:values.DatasetID,
          groupIdAttr:values.GroupIDField,
          companyIdAttr:values.CompanyIDField,
          firstName:values.InstanceUserFirstName,
          lastName:values.InstanceUserLastName,
          userName:values.InstanceUserUserName,
          label:values.Name,
          reportType: values.Report_Type,
          reportFilters:values.ReportFilters,
          masterRoles:masterRoles.toString(),
          roles:role.toString(),
          widgetType:values.WidgetType,
          compidGrpidDsid: dynamicFieldValues
        };
       dispatch(createDashboardData(postjson));
      }
    });
  };

  return (
    <Row className="margin-b-15">
      <Col sm={8} xs={12}>
        <h1 bsClass="" className="sPageHeading1">
          {translator["Superset Dashboard"]}
        </h1>
      </Col>
      <Col sm={4} xs={12}>
        <div className="paHedFilter">
          <Link to="javascript:void(0)"
            bsPrefix=" "
            className="myBt plus ctrlKeyPrevent"
            onClick={handleSubmit(submit)}
            disabled={submitting}
            title={translator["Save"]}
            ref={saveObjRef}
          >
           <span>{submitting ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/>}</span>
          </Link>
          <Link
            bsPrefix=" "
            className="myBt cancel fillBtn ctrlKeyPrevent"
            title={translator["Close"]}
            to={GLOBAL.reportsConfiguration}
            ref={closeButton}
          >
            <IoClose/>
          </Link>
        </div>
      </Col>
    </Row>
  );
};

export default reduxForm({
  form: "supersetDashboardForm",
})(CreateDashboardHeader);
