
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Typeahead } from 'react-bootstrap-typeahead';
import "react-bootstrap-typeahead/css/Typeahead.css";

class TagsDropdownPlusTypeahead extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.onCrossClickFun = this.onCrossClickFun.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.inputValue != this.props.inputValue && nextProps.inputValue == '') {
            //this._typeahead.getInstance().clear();
        }
    }

    onCrossClickFun() {
        this.props.onCrossClick();
        this._typeahead.clear();
    }

    render() {
        let { options, onSelection, selectedOptions, setErrorColor, disabled = false, onInputChange, inputValue, onCrossClick, id, errorColor, formType } = this.props;

        const inputProps = {
            value: inputValue ? inputValue : ''
        };

        return (
            <div className={"dvTypehd dvTypehdropdown " + errorColor}>
                {(inputValue && inputValue.length > 0) && formType !== "edit" ?
                    <span
                        title="clear"
                        role="img"
                        aria-label="Clear"
                        className="css-hakgx8 icn"
                        onClick={this.onCrossClickFun}
                    >
                        <svg width="18" height="18" viewBox="0 0 24 24" role="presentation">
                            <path
                                d="M12 10.586L6.707 5.293a1 1 0 00-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 001.414 1.414L12 13.414l5.293 5.293a1 1 0 001.414-1.414L13.414 12l5.293-5.293a1 1 0 10-1.414-1.414L12 10.586z"
                                fill="gray"
                            ></path>
                        </svg>
                    </span>
                    : null}
                <Typeahead
                    id={id}
                    ref={(ref) => (this._typeahead = ref)}
                    onChange={onSelection}
                    onInputChange={onInputChange}
                    options={options}
                    selected={selectedOptions}
                    placeholder="Please choose..."
                    onBlur={setErrorColor}
                    disabled={disabled}
                    inputProps={inputProps}
                    renderMenuItemChildren={(option) => (
                        <div>
                          {option.label}
                          <div>
                            <small>{option.info}</small>
                          </div>
                        </div>
                      )}
                />
            </div>
        );
    }
}

export default TagsDropdownPlusTypeahead;