
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
//below URL used for testing purpose
//const breakFixUrl = 'https://xsm-f.mybluemix.net/api/SX_FAVOURITEOFFERINGs/';
import { GLOBAL }  from '../../components/Globals';
//let breakFixUrl =`https://xsm-f.mybluemix.net/api/breakfixitem?breakfixitemId=`;
//let breakFixUrl=`https://xsm-f.mybluemix.net/groupListSelfService?companyId=2932&group=ResolvedER`
export function loadInvRelatedDataDetailsSuccess(invRelatedDataDetails){
	//alert('in success')
	return {
		type: 'LOAD_INVRELATEDDATADETAILS_SUCCESS',
		invRelatedDataDetails
	};
}

export function loadRelatedBreakFixDataToProblemOnSucess(invRelatedDataDetailsCount){
	return {
		type: 'LOAD_RELATED_BREAKFIX_DATA_SUCCESS',
		invRelatedDataDetailsCount
	};
}
export function loadInvRelatedDataDetailsFailure(errorResponse){
	return {
		type: 'LOAD_INVRELATEDDATADETAILS_FAILURE',
		errorResponse
	};
}
export function loadInvRelatedDataDetails(breakFixId,description){
	//alert('action called and parameter is '+breakFixId)

	return(dispatch) =>{
		//alert("EditGroupIdAction123"+breakFixId);

				//alert("hiaaa::::");
				axios.get(GLOBAL.invRelatedDataUrl+"?itemId="+breakFixId+"&module=Investigation")
					.then((invRelatedDataDetails)=>{
						if(!invRelatedDataDetails){//alert('not breakFix')
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							throw Error(invRelatedDataDetails.statusText);
						}
						return invRelatedDataDetails;
					})
					.then((invRelatedDataDetails) =>{//alert('yes it is')
						dispatch(loadInvRelatedDataDetailsSuccess(invRelatedDataDetails))
					})
					.catch((err) => {
						console.log(err);
					});
					//console.log("not calling loadBreakFixEditDetails");

	};
}

export function loadRelatedBreakFixDataToProblem(breakfixId,workItemModuleName,moduleName){
	let recordCount=0;
	let iterativeFunction=(element)=>element.workModule==='Breakfix'?recordCount++:recordCount
	return (dispatch) =>{
	axios.get(GLOBAL.invRelatedDataUrl+"?itemId="+breakfixId+"&workModule="+workItemModuleName+"&module="+moduleName)
					.then((invRelatedDataDetails) =>{
						invRelatedDataDetails.data.map(iterativeFunction);
						console.log("Count Sended : ",recordCount);
						dispatch(loadRelatedBreakFixDataToProblemOnSucess(recordCount));
					})
					.catch((err) => {
						console.log(err);
					});
				};
}
