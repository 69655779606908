
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import {Card} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Accordion from 'react-bootstrap/Accordion';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import ListGroup from 'react-bootstrap/ListGroup';
import moment from 'moment-timezone';
import sanitizeHtml from 'sanitize-html';
import { MyContext } from '_MyContext';
import { Link } from "react-router-dom";

import { Field, reduxForm } from 'redux-form';
import Spinner from "react-spinkit";
import axios from "axios";
import { GLOBAL } from "../Globals";
import swal from 'sweetalert';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
let dateformat = cookies.get('gph');
if (dateformat) dateformat = dateformat.replace('s:', '');
if (dateformat) dateformat = dateformat.substring(0, dateformat.lastIndexOf('.'));
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let timezone = cookies.get('gph');
if (timezone) timezone = timezone.replace('s:', '');
if (timezone) timezone = timezone.substring(0, timezone.lastIndexOf('.'));
timezone = timezone.split("~");
timezone = timezone[3];
let user_id = cookies.get("gph");
if (user_id) user_id = user_id.replace("s:", "");
if (user_id) user_id = user_id.substring(0, user_id.lastIndexOf("."));
user_id = user_id.split("~");
user_id = user_id[43];
import { connect } from "react-redux";
import { getMyApprovalList, getAdhocApprovers } from "../../actions/MyApprovals/MyApprovalActions";
import { loadChangeTotalApprovalDetails, getRequesterDelegateeForApprovalDetails } from "../../actions/changeManagement/changeTotalApprovalAction";
import { bindActionCreators } from "redux";
import ListLoader from "_Commons/loaders/ListLoader"
import {getChangeQuestionsCategoryWise } from '../../actions/cart/myOrders/myOrdersAction';
import { loadImplPlanList } from '../../actions/common/attachmentAction';

import { formatMinutesIntoDaysHoursMinutes } from '../../actions/common/functionActions.js';
import { getQuestionsCategoryWise } from '../../actions/cart/myOrders/myOrdersAction';
import { loadCartCount } from '../../actions/common/cartCountAction';
import { getDisclaimiers } from '../../actions/spcmActions';
import { loadBreakFixActivityLogDetails } from '../../actions/breakFix/breakFixActivityLogAction';
import RFReactUser from './AutoSearchUser.js';
import ReactReadMoreLess from "../../helpers/ReactReadMoreLess";
import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";
import Swal from "sweetalert2";

const MoreArrow = <i title="More" className="fa fa-angle-double-down f-size-15" ></i>;
const api = axios.create({
  headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});
const Joi = require('joi')
class ApprovalOfferingDetails extends React.Component {
  static contextType = MyContext;
  constructor(props) {
    super(props);
    this.state = {
      rejectSecHideShow: false,
      approveSecHideShow: false,
      rejectTextValue: "",
      approveTextValue: "",
      referBackTextValue: "",
      approvedButtonDisabled: false,
      rejectButtonDisabled: false,
      referBackButtonDisabled: false,
      loader: false,
      newQuestionsTextValue: [],
      currentSelectedApprover: {},
      approversArray: [],
      emptyAutosuggestValue: false,
      adhocButtonDisabled: false,
      displayMessage: "",
      showApprovalLoader: false,
      cabDateVal: '',
      errorMsg: '',
      date_format: 'DD-MM-YYYY',
      cancelreasonCharLeft: 500,
      approvalsTabOpen: false,
      attachmentsTabOpen: false,
      infoProvidedTabOpen: false,
      requestorInfoTabOpen: false,
      itemComponentsTabOpen: false,
      viewUpdatesTabOpen: false,
      changePlansTabOpen: false,
      activeBtn: "approve",
      showPlansState: false,
      showPlansObj:{},
      adhocTextValue: "",
    };
    this.updateRejectTextField = this.updateRejectTextField.bind(this);
    this.updateReferBackTextField = this.updateReferBackTextField.bind(this);
    this.getAttachment = this.getAttachment.bind(this);
    this.getInformation = this.getInformation.bind(this);
    this.renderIndividualCategoryQuestions = this.renderIndividualCategoryQuestions.bind(this);
    this.updateApproveTextField = this.updateApproveTextField.bind(this);
    this.groupPopoverTable = this.groupPopoverTable.bind(this);
    this.individualPopoverTable = this.individualPopoverTable.bind(this);
    this.renderAdditionalDetails = this.renderAdditionalDetails.bind(this);
    this.approvalsViewUpdate = this.approvalsViewUpdate.bind(this);
    this.onUserNameChange = this.onUserNameChange.bind(this);
    this.showCreateApproversList = this.showCreateApproversList.bind(this);
    this.changeAutosuggestState = this.changeAutosuggestState.bind(this);
    this.deleteApproverFromList = this.deleteApproverFromList.bind(this);
    this.submitAdhocApprovers = this.submitAdhocApprovers.bind(this);
    this.checkRenderApproverData = this.checkRenderApproverData.bind(this);
    this.onClickApprove = this.onClickApprove.bind(this);
    this.onClickReferBack = this.onClickReferBack.bind(this);
    this.onClickReject = this.onClickReject.bind(this);
    this.showPlansFunc = this.showPlansFunc.bind(this);
    this.hidePlansFunc = this.hidePlansFunc.bind(this);
    this.renderInformationProvided = this.renderInformationProvided.bind(this);
    this.adhocTextField = this.adhocTextField.bind(this);
    this.renderQuestionFields = this.renderQuestionFields.bind(this);
    GLOBAL.cancelreasonCharMax=500;
  }

  componentDidMount() {
    this.context.keyDownHandler(this.closeButton,"",this.globalSearchRef,"");
  }
  componentDidUpdate() {
    this.context.keyDownHandler(this.closeButton,"",this.globalSearchRef,"");
  }

  onClickApprove(){
    this.props.approveSecHideShowFunc()
    this.setState({ activeBtn: "approve"})
  }

  onClickReject(){
    this.props.rejectSecHideShowFunc()
    this.setState({ activeBtn: "reject"})
  }

  onClickReferBack(){
    this.props.referBackSecHideShowFunc()
    this.setState({ activeBtn: "referBack"})
  }

  componentWillMount() {
    this.setState({ loader: false });
    let str = {};
    str.user_id = "";
    str = JSON.stringify(str);
    this.setState({ date_format:dateformat });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.approveSecHideShow !== this.props.approveSecHideShow && nextProps.approveSecHideShow === false) {
      this.setState({ approveTextValue: '' });
    }
    if (nextProps.rejectSecHideShow !== this.props.rejectSecHideShow && nextProps.rejectSecHideShow === false) {
      this.setState({ rejectTextValue: '' });
    }
    if (nextProps.referBackSecHideShow !== this.props.referBackSecHideShow && nextProps.referBackSecHideShow === false) {
      this.setState({ referBackTextValue: '' });
    }
    if (nextProps.showCreateAdhocApproverPanel !== this.props.showCreateAdhocApproverPanel && nextProps.showCreateAdhocApproverPanel === false) {
      this.setState({ approversArray: [], currentSelectedApprover: {}, emptyAutosuggestValue: false });
    }
    if (nextProps.approvalObj !== this.props.approvalObj && nextProps.approvalObj) {
      let signatureId = nextProps.approvalObj.signaturelist[0][0].signatureid;
      this.props.getAdhocApprovers(signatureId);
    }
    if (nextProps && nextProps.changeTotalApprovalDetails && nextProps.changeTotalApprovalDetails != this.props.changeTotalApprovalDetails && nextProps.changeTotalApprovalDetails[0].isRequester == "true" && nextProps.changeTotalApprovalDetails[0].approverDetails) {
      let modulenumber;
      if (nextProps.approvalObj !== this.props.approvalObj && nextProps.approvalObj) {
        modulenumber = nextProps.approvalObj.signaturelist[0][0].item_number;
      }
      else {
        modulenumber = this.props.approvalObj.signaturelist[0][0].item_number;
      }
      if (modulenumber.indexOf("RFC") >= 0) {
        this.props.getRequesterDelegateeForApprovalDetails(nextProps.changeTotalApprovalDetails[0].requesterId, "14")
      }
      else {
        this.props.getRequesterDelegateeForApprovalDetails(nextProps.changeTotalApprovalDetails[0].requesterId, "10")
      }
    }
    if (nextProps.resetAccordiansStateCount !== this.props.resetAccordiansStateCount) {
      this.setState({
        approvalsTabOpen: false,
        attachmentsTabOpen: false,
        infoProvidedTabOpen: false,
        requestorInfoTabOpen: false,
        itemComponentsTabOpen: false,
        viewUpdatesTabOpen: false,
        changePlansTabOpen: false,
        activeBtn: "approve"
      });
    }
  }

  onUserNameChange(value) {
    this.setState({ currentSelectedApprover: value });
  }

  changeAutosuggestState(val) {
    this.setState({ emptyAutosuggestValue: val });
  }

  addApproverToList() {
    if (Object.keys(this.state.currentSelectedApprover).length === 0)
      return false;
    let arr = this.state.approversArray;
    let obj = Object.assign({}, this.state.currentSelectedApprover);
    arr.push(obj);
    this.setState({ approversArray: arr });
    this.setState({ adhocTextValue: "" });
    this.changeAutosuggestState(true);
    this.setState({ currentSelectedApprover: {} });
  }

  deleteApproverFromList(index, adhocApproverUserId) {
    let arr = this.state.approversArray;
    let result = arr.filter(approver => approver.adhocApproverUserId != adhocApproverUserId);
    this.setState({ approversArray: result });
  }

  showCreateApproversList() {
    return this.state.approversArray.map((approver, i) => {
      return <tr>
        <td>
          {approver.adhocApproverUserName}
        </td>
        <td>
          <Button className="pad05" bsSize="small" title={this.props.tr['Delete']} onClick={() => { this.deleteApproverFromList(i, approver.adhocApproverUserId) }}><i className="fa fa-trash"></i></Button>
        </td>
      </tr>;
    });
  }

  submitAdhocApprovers() {
    if(this.state.adhocTextValue.trim() == ""){
      Swal.fire({
        title: 'Please enter Approval Request Comments',
      })
      return;
    }
    let signatureId = this.props.approvalObj.signaturelist[0][0].signatureid;
    // let arr = JSON.parse(JSON.stringify(this.state.approversArray));
    let adhocUserName = [];
    let arr = this.state.approversArray;
    for (let i = 0; i < arr.length; i++) {
      let item = arr[i];
      item.approvalRequestComments = this.state.adhocTextValue;
      adhocUserName.push(" "+item.adhocApproverFullName.trim());
    }
    arr.forEach((item) => {
      delete item.label;
      delete item.adhocApproverFullName;
    });
    let obj = {};
    obj.adhocApproverFlag = 'Y';
    obj.adhocCreatedBy = '';
    obj.adhocApprovers = arr;
    this.setState({ adhocButtonDisabled: true });
    axios.post("/api/approval/adhocApprovers/" + signatureId, obj)
      .then(response => {
        if (response.status == 200 || response.status == 201) {
          this.props.getAdhocApprovers(signatureId);
          this.props.showCreateAdhocApproverPanelToggle(false);
          this.setState({ adhocButtonDisabled: false });
          this.loadApprovalHistoryAndDetails(this.props.approvalObj);
        }
      })
      .catch(error => {
        this.setState({ adhocButtonDisabled: false });
      })
      let activityDetails ={
        "itemId": this.props.approvalObj.signaturelist[0][0].item_number.indexOf("RFC") >= 0 ? this.props.editChangeInitialData.changeId :this.props.fulfillmentDetails.itemId,
        "description": `Adhoc approval requested to${adhocUserName}`,
        "isInternal":"N",
        "createdOn":"",
        "createdBy":"",
        "module":  this.props.approvalObj.signaturelist[0][0].item_number.indexOf("RFC") >= 0 ? "ChangeManagement":"Fullfilment",
        "createdByName":"",
        "sendNotification":"true",
        "isExternal":""
        };
        axios.post("/serviceManagement/rest/activity", activityDetails);
  }

  loadApprovalHistoryAndDetails(approval) {
    let modulenumber = approval.signaturelist[0][0].item_number;
    let recordStatus = approval.approvalstatuslist[0][0].approvalstatus;
    recordStatus = (recordStatus == "Refer Back" ? "Refer_Back" : recordStatus);
    if (modulenumber.indexOf("RFC") >= 0) {
      this.props.loadChangeTotalApprovalDetails(
        approval.signaturelist[0][0].itemid,
        "14",
        "N", recordStatus
      );
    } else {
      this.props.loadChangeTotalApprovalDetails(
        approval.signaturelist[0][0].itemid,
        "10",
        "N", recordStatus
      );
    }
  }

  renderAdhocApproversTable() {
    return (
      <Table bordered hover responsive className="tableView  tableWithOutPointer">
        <thead>
          <tr>
            <th width="60%" className="sort">{this.props.tr['Adhoc Approvers']}</th>
          </tr>
        </thead>
        <tbody>
          {this.props.adhocApproversList.map((approver, i) => {
            return (
              <tr>
                <td>
                  {approver.adhocApproverUserName}
                  <Form.Check
                    className="dCheckbox float-r readOnlyChk"
                    checked
                    readOnly
                  >
                    <span />
                  </Form.Check>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  updateRejectTextField(value) {

    this.setState({ rejectTextValue: value });
  }

  updateApproveTextField(value) {
    this.setState({ approveTextValue: value });
  }

  adhocTextField(value) {
    this.setState({ adhocTextValue: value });
  }

  updateReferBackTextField(value) {
    this.setState({ referBackTextValue: value, cancelreasonCharLeft: GLOBAL.cancelreasonCharMax-value.length});
  }

  groupPopoverTable(tabledata, adhocApproverFlag) {
    return tabledata.map((data, index) => {
      let statusapp = data.status;
      if (statusapp == "null" || statusapp == "" || statusapp == null)
        statusapp = "Pending"
      else
        statusapp = data.status;

      return (<tr>
        <td >
          {data.name}
        </td>
        <td >
          {statusapp}
          {data.approvedby != null ? " (" + data.approvedby + ")" : ""}
        </td>
        <td >
          {data.date}
        </td>
        <td >
          {data.reason}
        </td>
        {adhocApproverFlag == 'Y' ? <td >
          {data.approvalRequestComments}
        </td> : null}
      </tr>)
    })
  }

  individualPopoverTable(tabledata, adhocApproverFlag) {
        return (<tr>
      <td >
        {tabledata.name}
      </td>
      <td >
        {tabledata.reason}
      </td>
      {adhocApproverFlag == 'Y' ?<td>
        {tabledata.approvalRequestComments}
      </td> : null}
    </tr>)
  }

  renderAppHistoryDataLog(relatedAppHistoryDataLog) {
    if (!relatedAppHistoryDataLog) {
      return (
        <tr>
          <td width="100%">
            <div>{this.props.tr['Data not available.']}</div>
          </td>
        </tr>
      );
    }
    else {
      return relatedAppHistoryDataLog.map((relatedAppHistoryData, index) => {
        let statusapp = relatedAppHistoryData.status;
        if (statusapp == "null" || statusapp == "" || statusapp == null)
          statusapp = "Pending"
        else
          statusapp = relatedAppHistoryData.status;

        let statusappColor = '';
        if (statusapp == "Approved") {
          statusappColor = "greenDot";
        } else if (statusapp == "Rejected") {
          statusappColor = "redDot";
        } else if (statusapp == "Pending") {
          statusappColor = "blueDot";
        } else if (statusapp == "Referred Back") {
          statusappColor = "orangeDot";
        }

        let approverDetails = '';
        approverDetails = relatedAppHistoryData.approverDetails != null ? relatedAppHistoryData.approverDetails : '';

        let indData = { name: relatedAppHistoryData.signatures, reason: relatedAppHistoryData.rejectreason };

        let individualPopoverRej = (
          <Popover id={index + "ind"} className="approvalPopup myReqApprvalPopup">
            <Popover.Header as="h3">{this.props.tr["Rejection Reason"]}</Popover.Header>
            <Popover.Body>
              <Table className="table table-striped table-bordered table-condensed table-hover tablesl ">
                <thead>
                  <tr>
                    <th >{this.props.tr['Name']}</th>
                    <th >{['Approval Action Comments']}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.individualPopoverTable(indData)}
                </tbody>
              </Table>
            </Popover.Body>
          </Popover>
        );

        let indApprovalData = { name: relatedAppHistoryData.signatures };
        relatedAppHistoryData.status == "Referred Back" ? indApprovalData.reason = relatedAppHistoryData.referbackComments : indApprovalData.reason = relatedAppHistoryData.approvalComments;
        let individualPopoverApproval = (
          <Popover id={index + "ind" + index} className="approvalPopup myReqApprvalPopup">
            <Popover.Header as="h3">{relatedAppHistoryData.status == "Referred Back" ? this.props.tr["Referred Back Comment"] : this.props.tr["Approver Comment"]}</Popover.Header>
            <Popover.Body>
            <Table responsive className="f-size-12 table table-striped table-bordered table-condensed table-hover">
              <thead>
                <tr>
                  <th >{this.props.tr['Name']}</th>
                  <th >{['Approval Action Comments']}</th>
                  {relatedAppHistoryData.adhocApproverFlag == 'Y' ? <th>{['Approval Request Comments']}</th>:null}
                </tr>
              </thead>
              <tbody>
                {this.individualPopoverTable(indApprovalData, relatedAppHistoryData.adhocApproverFlag)}
              </tbody>
            </Table>
            </Popover.Body>
          </Popover>
        );

        let groupPopoverRej = (
          <Popover id={index + "groupRej"} className="approvalPopup myReqApprvalPopup">
            <Popover.Header as="h3">{this.props.tr["Rejection Reason"]}</Popover.Header>
            <Popover.Body>
              <Table className="table table-striped table-bordered table-condensed table-hover tablesl ">
                <thead>
                  <tr>
                    <th width="25%">{this.props.tr['Name']}</th>
                    <th width="25%">{this.props.tr['Status']}</th>
                    <th width="25%">{this.props.tr['Date & Time']}</th>
                    <th width="30%">{['Approval Action Comments']}</th>
                  </tr>
                </thead>
                <tbody>
                  {approverDetails == '' ?
                    <tr>
                      <td colspan="4">
                        {this.props.tr['Data not available.']}
                      </td>
                    </tr> 
                    :
                    this.groupPopoverTable(approverDetails)}
                </tbody>
              </Table>
            </Popover.Body>
          </Popover>
        );

        let groupPopover = (
          <Popover id={index + "group"} className="approvalPopup myReqApprvalPopup myAppAdhocPopup">
            <Popover.Header as="h3">{relatedAppHistoryData.adhocApproverFlag == "Y" ? this.props.tr['Adhoc Approvals'] : this.props.tr["Group Approvals"]}</Popover.Header>
            <Popover.Body>
            <Table responsive className="f-size-12 table-striped table-bordered table-condensed table-hover">
              <thead>
                <tr>
                  <th width="35%">{this.props.tr['Name']}</th>
                  <th width="35%">{this.props.tr['Status']}</th>
                  <th width="35%">{this.props.tr['Date & Time']}</th>
                  <th width="50%">{['Approval Action Comments']}</th>
                  {relatedAppHistoryData.adhocApproverFlag == "Y"? <th width="50%">{['Approval Request Comments']}</th>:''}
                </tr>
              </thead>
              <tbody>
                {approverDetails == '' ?
                  <tr>
                    <td colspan="4">
                      {this.props.tr['Data not available.']}
                    </td>
                  </tr>
                  :
                  this.groupPopoverTable(approverDetails, relatedAppHistoryData.adhocApproverFlag)}
              </tbody>
            </Table>
            </Popover.Body>
          </Popover>
        );

        return (
          <tr>
            <td >
              {statusapp == "Rejected" ?
                (relatedAppHistoryData.approverType != "Group" && relatedAppHistoryData.approverType != "GBO" && relatedAppHistoryData.adhocApproverFlag != "Y" ?
                  <OverlayTrigger
                    trigger='click' rootClose placement="bottom" overlay={individualPopoverRej}>
                    <Button className={statusappColor} title={this.props.tr[statusapp]} bsPrefix=' '  variant="success"></Button>
                  </OverlayTrigger>
                  :
                  (relatedAppHistoryData.approverType == "Group" || relatedAppHistoryData.adhocApproverFlag == "Y" ?
                    <span className={statusappColor} title={this.props.tr[statusapp]}></span>
                    :
                    <OverlayTrigger
                      trigger='click' rootClose placement="bottom" overlay={groupPopoverRej}>
                      <Button className={statusappColor} title={this.props.tr[statusapp]} bsPrefix=' '  variant="success"></Button>
                    </OverlayTrigger>))
                :
                (relatedAppHistoryData.approverType != "Group" && relatedAppHistoryData.approverType != "GBO" && relatedAppHistoryData.adhocApproverFlag != "Y" ?
                  <OverlayTrigger
                    trigger='click' rootClose placement="bottom" overlay={individualPopoverApproval}>
                    <Button className={statusappColor} title={this.props.tr[statusapp]} bsPrefix=' '  variant="success"></Button>
                  </OverlayTrigger>
                  :
                  <span className={statusappColor} title={this.props.tr[statusapp]}></span>
                )
              }
            </td>

            <td >
              {relatedAppHistoryData.levelname}
            </td>
            <td >
              {relatedAppHistoryData.approverType.trim() != "Group" || relatedAppHistoryData.approverType.trim() != "GBO" ?
                relatedAppHistoryData.signatures == "null" || relatedAppHistoryData.signatures == null ?
                  "Approver does not exist" : relatedAppHistoryData.signatures :
                relatedAppHistoryData.signatures}
              {relatedAppHistoryData.approverType.trim() == "Group" || relatedAppHistoryData.approverType.trim() == "GBO" || relatedAppHistoryData.adhocApproverFlag == "Y" ? <OverlayTrigger
                trigger='click' rootClose placement="bottom" overlay={groupPopover}>
                <Button title="Info" bsPrefix=' '  className="btninfo">i</Button>
              </OverlayTrigger> : ""}
            </td>
            <td >
              {relatedAppHistoryData.lastUpdatedDate}
            </td>
          </tr>
        );
      });
    }
  }

  renderPlans(implememtationPlanList) {
    return (
      <div>
        {this.state.showPlansState ? <div>
          <Form.Group className="form-group">
            <Form.Label> {this.props.tr['Plan Type']}</Form.Label>
            <Form.Control type="text" value={this.state.showPlansObj.planType} disabled="true" />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>{this.props.tr['Plan']}</Form.Label>
            <Form.Control componentClass="textarea" readOnly rows="2" value={this.state.showPlansObj.planDetail}>
            </Form.Control>
          </Form.Group>
          <div className="margin-t-20">
            <div className="border-b padding-b-5 margin-b-10 black f-size-16 fw-400">
            <p>{this.props.tr['File Name']}</p>
            <p>{this.state.showPlansObj.filename}</p><br />
            <Button bsStyle="danger" onClick={() => { this.hidePlansFunc(); }}>{this.props.tr['Cancel']} </Button>
            </div>
          </div>
        </div>: ''}
        <Table responsive striped bordered condensed hover className="f-size-13">
          <thead>
            <tr>
              <th>{this.props.tr['Plan Type']}</th>
              <th>{this.props.tr['File Name']}</th>
              {/* <th width="70%">{this.props.tr['Plan Details']}</th> */}
              <th>{this.props.tr['Created By']}</th>
              <th>{this.props.tr['Action']}</th>
            </tr>
          </thead>
          {this.renderPlansData(implememtationPlanList)}
        </Table>
      </div>
    );
  }

  renderPlansData(implememtationPlanList) {
    if (implememtationPlanList && implememtationPlanList.length == 0) {
      return (
        <div><font color="green" ><b>{this.props.tr['No plan found']}</b></font></div>
      );
    }
    return implememtationPlanList.map((implememtationPlanDataObj) => {
      let validated_url;
      if (implememtationPlanDataObj && implememtationPlanDataObj.fileTempURL) {
        let namedSchema = Joi.string().regex(/^[^<>}{]+$/)
        let validator_to_add_myQbj = namedSchema.validate(implememtationPlanDataObj.fileTempURL)
        if (!validator_to_add_myQbj.error) {
          validated_url = implememtationPlanDataObj.fileTempURL;
        }
        else {
          // console.log("Validation failed : ", validator_to_add_myQbj)
          validated_url = "\\";
        }
      }
      else
        validated_url = "\\";
      if(implememtationPlanDataObj['planType']){
        return (
          <tbody>
            <tr>
              <td>{implememtationPlanDataObj.planType}</td>
              <td><a title={implememtationPlanDataObj.filename}  href={validated_url}>{implememtationPlanDataObj.filename ? (implememtationPlanDataObj.filename.length > 12 ? implememtationPlanDataObj.filename.slice(0, 12) + '...' : implememtationPlanDataObj.filename) : ''}</a></td>
              {/* <td>{implememtationPlanDataObj.planDetail}</td> */}
              <td>{implememtationPlanDataObj.createdByName}<p>{moment.unix(implememtationPlanDataObj.createdOn).tz(timezone).format(this.state.date_format.slice(0, 10).toUpperCase() + this.state.date_format.slice(10))}</p></td>
              <td><center><a title="View" rel="noopener"><i className="fa fa-eye" onClick={() => { this.showPlansFunc(implememtationPlanDataObj)}}></i></a></center></td>
            </tr>
          </tbody>
        );
      }
    });
  }

  showPlansFunc(planDetails) {
    this.setState({showPlansState: true, showPlansObj: planDetails});
  }
  
  hidePlansFunc() {
    this.setState({showPlansState: false, showPlansObj: {}});
  }

  renderAttachedItems(attachments) {

    // eslint-disable-next-line no-undef
    if (_.isEmpty(attachments)) {
      return (
        <div><h4>{this.props.tr['No File Found']}</h4></div>
      );
    }

    let listCount = 0;
    attachments.map((attachment) => {
      if (attachment.status == "Y" || attachment.status == null || attachment.filename == null) {
        listCount = listCount + 1;
      }
    });

    if (listCount == attachments.length) {
      return (
        <div><h4>{this.props.tr['No File Found']}</h4></div>
      );
    }

    return attachments.map((attachment) => {

      let validated_url = undefined;
      // if (attachment != null || attachment.fileTempURL != "") {
      //   let namedSchema = Joi.string().regex(/^[^<>}{]+$/);
      //   let validator_to_add_myQbj = namedSchema.validate(attachment.fileTempURL);
      //   if (validator_to_add_myQbj.error == null) {
      //     validated_url = attachment.fileTempURL;
      //   }
      //   else {
      //     validated_url = "\\";
      //   }
      // }
      // else
      //   validated_url = "\\";

      if (attachment && 'fileTempURL' in attachment && attachment.fileTempURL) {
        let namedSchema = Joi.string().regex(/^[^<>}{]+$/);
        let validator_to_add_myQbj = namedSchema.validate(attachment.fileTempURL);
        if (validator_to_add_myQbj.error == null) {
          validated_url = attachment.fileTempURL;
        }
        else {
          validated_url = "\\";
        }
      }
      else
        validated_url = "\\";

      if(attachment.filename !== null && attachment != undefined) {
        let attachfilename = attachment.filename || attachment.fileName;
        if (attachfilename.indexOf("##") > -1){
          attachfilename = attachfilename.slice(15);
        } 
        attachment.filename=attachfilename;
      }
      if (attachment.status == "N" && attachment.filename !== null) {
        return (
          <ListGroup.Item bsPrefix=' ' className="attachmentList" style={{ "width": "100%", "float": "left", "margin-bottom": "5px", "display": "block" }} key={attachment.attachmentId}><div style={{ "width": "92%", "float": "left", wordBreak: "break-all"}}>{attachment.filename}</div> <span className="float-r"><a href={validated_url} ><i className="fa fa-eye"></i></a>&nbsp;&nbsp;</span></ListGroup.Item>
          // <ListGroup.Item bsPrefix=' ' className="attachmentList" style={{ "width": "100%", "float": "left", "margin-bottom": "5px", "display": "block" }} key={attachment.attachmentId}><div style={{ "width": "92%", "float": "left" }}>{attachment.filename}</div> <span className="float-r"><a href={attachment.fileTempURL} ><i className="fa fa-eye"></i></a>&nbsp;&nbsp;</span></ListGroup.Item>
        );
      }
    });
  }
  renderApprovalHistoryDataLog(relatedAppHistoryDataLog) {
    //debugger
    //alert(""+relatedAppHistoryDataLog)
    if (!relatedAppHistoryDataLog) {
      return (
        <tr>
          <td width="100%">
            <div>{this.props.tr['Data not available.']}</div>
          </td>
        </tr>
      );
    } else {
      return relatedAppHistoryDataLog.map((relatedAppHistoryData, index) => {
        //alert(index)
        return (
          <div>
            <ListGroup>
              <ListGroup.Item
                bsPrefix=' '
                className="rwTable"
                style={{ "border-top": "none" }}
              >
                <span className="rwKey">{this.props.tr['Level Name']}</span>
                <span className="rwVal">{relatedAppHistoryData.levelname}</span>
              </ListGroup.Item>
              <ListGroup.Item bsPrefix=' ' className="rwTable">
                <span className="rwKey">{this.props.tr['Approver Name']}</span>
                <span className="rwVal">
                  {relatedAppHistoryData.signatures}
                </span>
              </ListGroup.Item>
              <ListGroup.Item bsPrefix=' ' className="rwTable">
                <span className="rwKey">{this.props.tr['Status']}</span>
                <span className="rwVal"> {relatedAppHistoryData.status}</span>
              </ListGroup.Item>
              <ListGroup.Item bsPrefix=' ' className="rwTable">
                <span className="rwKey">{this.props.tr['App/Rej By']}</span>
                <span className="rwVal">
                  {relatedAppHistoryData.modifiedby}
                </span>
              </ListGroup.Item>
              <ListGroup.Item bsPrefix=' ' className="rwTable">
                <span className="rwKey">{this.props.tr['App/Rej Date']}</span>
                <span className="rwVal">
                  {relatedAppHistoryData.modifieddate}
                </span>
              </ListGroup.Item>
            </ListGroup>
          </div>
        );
      });
    }
  }

  // itemComponent(components) {
  //   if (components === undefined || components.length === undefined) {
  //     return <div />;
  //   }
  //   return components.map((component, index) => {
  //     return (
  //       <div key={index} className="details">


  //         <i className="fa fa-check bullets"></i>
  //         <span className="margin-l-30" style={{ "font-weight": "normal" }}> </span>
  //         {component.componentNameSx}
  //         <span className="margin-l-30" style={{ "font-weight": "normal" }}>
  //           {this.props.tr['USD']} {component.componentPrice}
  //         </span>

  //       </div>
  //     );
  //   });
  // }

  renderItemComponents(orderDetails) {
    let finalArray = [], coreArray = [], optionalArray = [];
    let optionalCompCount = 0;
    if (orderDetails.servicePrice == null || typeof (orderDetails.servicePrice) === "undefined") {
      orderDetails.servicePrice = 0;
    }
    let totalUnitPrice = parseFloat(orderDetails.servicePrice);

    finalArray.push(<ListGroup.Item bsPrefix=' ' className="rwTableGap" style={{ 'border-top': 'none' }}><span className="rwKey"><b>{this.props.tr['Service Price']}</b></span><span className="rwGap">-</span><span className="rwVal">{orderDetails.currency != null && orderDetails.itemPrice > 0 && orderDetails.isPriceEnabled ? orderDetails.currency + ' ' + orderDetails.servicePrice : this.props.tr['NA']}</span></ListGroup.Item>);

    orderDetails.components.forEach((component, i) => {
      if (component.componentType == 'Core') {
        coreArray.push(<ListGroup.Item bsPrefix=' ' className="rwTableGap"><span className="rwKey">{component.componentNameSx}</span><span className="rwGap">-</span><span className="rwVal">{this.props.tr['Included']}</span></ListGroup.Item>);
      }
      if (component.componentType == 'Optional') {
        optionalArray.push(<ListGroup.Item bsPrefix=' ' className="rwTableGap"><span className="rwKey">{component.componentNameSx}</span><span className="rwGap">-</span><span className="rwVal">{orderDetails.currency != null && orderDetails.itemPrice > 0 && orderDetails.isPriceEnabled ? orderDetails.currency + ' ' + component.componentPrice : this.props.tr['NA']}</span></ListGroup.Item>); optionalCompCount++;
        totalUnitPrice = (totalUnitPrice + parseFloat(component.componentPrice));
      }
    });

    finalArray = finalArray.concat(coreArray);

    if (optionalCompCount > 0) {
      finalArray.push(<ListGroup.Item bsPrefix=' ' className="rwTableGap"><span className="rwKey"><b>{this.props.tr['Optional Components']}</b></span><span className="rwGap"></span><span className="rwVal"></span></ListGroup.Item>);
      finalArray = finalArray.concat(optionalArray);
    }

    finalArray.push(<ListGroup.Item bsPrefix=' ' className="rwTableGap"><span className="rwKey"><b>{this.props.tr['Total Unit Price']}</b></span><span className="rwGap">-</span><span className="rwVal">{orderDetails.currency != null && orderDetails.itemPrice > 0 && orderDetails.isPriceEnabled ? orderDetails.currency + ' ' + totalUnitPrice : this.props.tr['NA']}</span></ListGroup.Item>);
    return finalArray;
  }

  disclaimersPanel(disclaimer) {
    if (disclaimer.length == 0) {
      return <div />;
    }
    return (
      <div>
        <Form.Control as="textarea" value={disclaimer} />
      </div>
    );
  }

  userChoices(myOrderQuestionDetails) {
    if (myOrderQuestionDetails.length <= 0) {
      return (
        <div><h4>{this.props.tr['Data not available.']}</h4></div>
      );
    }

    return [].concat(myOrderQuestionDetails).sort((a, b) => a.categorySequence > b.categorySequence ? 1 : -1).map((category, i) => {
      return (
          <Accordion id={'questions' + i} className="brkFix brkFixOrder">
            <Accordion.Item eventKey='1'>
              <Accordion.Header><h4 toggle className="font1">{category.categoryName}</h4></Accordion.Header>
              <Accordion.Body>
                <div key={i}>
                  <Table bordered hover responsive className="tableView addWhiteSpc">
                    <tbody>
                      {this.renderIndividualCategoryQuestions(category.questions)}
                    </tbody>
                  </Table>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

      );
    })
  }

  renderIndividualCategoryQuestions(categoryQuestions) {
    return [].concat(categoryQuestions).sort((a, b) => a.questionSequence > b.questionSequence ? 1 : -1).map((categoryQuestion, i) => {
      if (categoryQuestion.question == "Assets") {

        var newstringreplaced = ""
        var answers = "";
        if(typeof categoryQuestion.answer === 'undefined' || categoryQuestion.answer === null ||  categoryQuestion.answer === ""){
          answers = "NA";
        }else if (categoryQuestion.answer != null && categoryQuestion.answer != "") {
          newstringreplaced = categoryQuestion.answer.replace(/,Name/gi, "~Name");
          answers = newstringreplaced.split('~');
        }
        else {
          answers = categoryQuestion.answer
        }

      }
      return (

        <tr key={i}>
          <td className="bgBtn20 f-size-13" style={{ "width": "50%" }}>{categoryQuestion.question}</td>
          <td className="f-size-13">

            {/*	{categoryQuestion.question !== "Assets" ?categoryQuestion.answer :<div className="assetsAnswers">{answers.map((a,i)=>{return <p>{a}</p>})};</div>}*/}
            {categoryQuestion.question !== "Assets" ? typeof categoryQuestion.answer === 'undefined' || categoryQuestion.answer === null ||  categoryQuestion.answer === "" ? 'NA' : (
              categoryQuestion.answer.replace(/ ~ /g, "\n").split("\n").map((data) => {
                return <p>{data}</p>
              })
            ) : answers != null ? (
              <div className="assetsAnswers">
                {answers.map((a, i) => {
                  return <p>{a}</p>;
                })}
                ;
              </div>
            ) : null}
          </td>
        </tr>
      );
    })
  }

  updateApprovalStatus(type, module) {

    if (module.modulelist[0][0].module == "10")
      module.itemType = "Fulfillment";
    if (module.modulelist[0][0].module == "14")
      module.itemType = "ChangeManagement";

      // console.log('**************', {type, module, state: this.state, props: this.props});
      const { editChangeInitialData : { type: changeType = "" } = {} } = this.props;


    // return false;
    let ref = this;
    let signatureId = this.props.approvalObj.signaturelist[0][0].signatureid;

    // debugger
    ref.setState({ loader: true });
    // Reject ------------------------------------------------
    if (type == "reject") {
      if (this.state.approveTextValue != "") {
        ref.setState({ loader: false });
        let appmsg = ref.props.tr['Please remove the approval comment'];
        // alert(appmsg);
        swal({
          text: appmsg,
          button: ref.props.tr['OK'],
        });
        return false;
      }
      if (this.state.referBackTextValue != "") {
        ref.setState({ loader: false });
        let appmsg = ref.props.tr['Please remove the refer back reason'];
        // alert(appmsg);
        swal({
          text: appmsg,
          button: ref.props.tr['OK'],
        });
        return false;
      }
      if (this.state.rejectTextValue.trim() == "") {
        ref.setState({ loader: false });
        let rejmsg = ref.props.tr['provide reason for rejection'];
        // alert(rejmsg);
        swal({
          text: rejmsg,
          button: ref.props.tr['OK'],
        });
        return false;
      } else {
        //  this.setState({ loader: true });
        ref.setState({ rejectButtonDisabled: true });
        axios
          .put("/api/approval/myapproval/" + signatureId, {
            status: "Rejected",
            rejectedBy: "",
            rejectreason: this.state.rejectTextValue.replace(/"/g, '').replace(/\\/g, '\\\\').replace(/(\r\n|\n|\r)/gm, ""),
            lastUpdatedBy: ""
          })
          .then(response => {
            if (response.status == 201 || response.status == "201") {
              if (module.modulelist[0][0].module == "10") {
                swal({
                  text: ref.props.tr["Another Approver has already taken action on this ticket."],
                  button: ref.props.tr['OK'],
                });
                this.props.itemDisclaimerChange(false);
                ref.props.showRightSide(false);
                ref.props.getMyApprovalList(
                  ref.props.user_Id,
                  ref.props.sortOrder,
                  ref.props.sortOnColumn,
                  ref.props.status,
                  ref.props.page,
                  ref.props.limit,
                  ref.props.modules,
                  ref.props.searchKey,
                  ref.props.searchValue
                );
                ref.props.loadCartCount();
                ref.props.changeRejectSecHideShow(false);
                // ref.setState({ rejectSecHideShow: false });
                ref.setState({ rejectTextValue: "" });
                ref.setState({ approvedButtonDisabled: false });
                ref.setState({ rejectButtonDisabled: false });
                ref.setState({ referBackButtonDisabled: false });
                ref.setState({ loader: false });
              }
              else {
                swal({
                  text: ref.props.tr["Another Approver has already taken action on this ticket."],
                  button: ref.props.tr['OK'],
                });
                ref.props.showRightSide(false);
                ref.props.getMyApprovalList(
                  ref.props.user_Id,
                  ref.props.sortOrder,
                  ref.props.sortOnColumn,
                  ref.props.status,
                  ref.props.page,
                  ref.props.limit,
                  ref.props.modules,
                  ref.props.searchKey,
                  ref.props.searchValue
                );
                ref.props.loadCartCount();
                ref.props.changeRejectSecHideShow(false);
                // ref.setState({ rejectSecHideShow: false });
                ref.setState({ rejectTextValue: "" });
                ref.setState({ approvedButtonDisabled: false });
                ref.setState({ rejectButtonDisabled: false });
                ref.setState({ referBackButtonDisabled: false });

              }
            }
            else if (response.status == 200) {
              if (module.modulelist[0][0].module == "10") {
                let rejSuccess = ref.props.tr['Rejected Successfully']
                this.props.itemDisclaimerChange(false);

                // alert(rejSuccess);
                swal({
                  text: rejSuccess,
                  button: ref.props.tr['OK'],
                });
                /* axios.patch(GLOBAL.fulfillmentpost + module.itemId, {
                  "itemStatus": "70"
                })
                  .then((response) => {
                    if (response.status == 200 || response.status == 201) { */
                //ref.props.loadBreakFixAuditLogDetails(module.itemId,"dsc", "Fulfillment",'STATUS');
                //ref.props.getMyApprovalList(userId, "desc");
                ref.props.showRightSide(false);
                ref.props.showActiveRow(0);
                ref.props.getMyApprovalList(
                  ref.props.user_Id,
                  ref.props.sortOrder,
                  ref.props.sortOnColumn,
                  ref.props.status,
                  ref.props.page,
                  ref.props.limit,
                  ref.props.modules,
                  ref.props.searchKey,
                  ref.props.searchValue
                );
                ref.props.loadCartCount();
                ref.props.changeRejectSecHideShow(false);
                // ref.setState({ rejectSecHideShow: false });
                ref.setState({ rejectTextValue: "" });
                ref.setState({ approvedButtonDisabled: false });
                ref.setState({ rejectButtonDisabled: false });
                ref.setState({ referBackButtonDisabled: false });
                ref.setState({ loader: false });
                /* 		}
                  }); */
              } else {
                if (module.modulelist[0][0].module == "14") {
                  let changeapprovalMsg = ref.props.tr['change approval rejected'];
                  // alert(changeapprovalMsg);
                  swal({
                    text: changeapprovalMsg,
                    button: ref.props.tr['OK'],
                  });
                  let patchObj = {
                    // status: "50",
                    "systemStartDateRequired": "N",
                    "systemEndDateRequired": "N"
                  };
                  if(changeType == '35'){
                    patchObj['status'] = '50';
                  }
                  axios
                    .patch(GLOBAL.changeEditUrl + module.signaturelist[0][0].itemid, patchObj)
                    .then(response => {
                      if (response.status == 200 || response.status == 201) {
                        ref.setState({ loader: false });
                        //ref.props.loadBreakFixAuditLogDetails(module.changeId,"dsc", "ChangeManagement",'STATUS');
                        //ref.props.getMyApprovalList(userId, "desc");
                        ref.props.showRightSide(false);
                        ref.props.showActiveRow(0);
                        ref.props.getMyApprovalList(
                          ref.props.user_Id,
                          ref.props.sortOrder,
                          ref.props.sortOnColumn,
                          ref.props.status,
                          ref.props.page,
                          ref.props.limit,
                          ref.props.modules,
                          ref.props.searchKey,
                          ref.props.searchValue
                        );
                        ref.props.loadCartCount();
                        ref.props.changeRejectSecHideShow(false);
                        // ref.setState({ rejectSecHideShow: false });
                        ref.setState({ rejectTextValue: "" });
                        ref.setState({ approvedButtonDisabled: false });
                        ref.setState({ rejectButtonDisabled: false });
                        ref.setState({ referBackButtonDisabled: false });
                      }
                    });
                }
              }
            } else {
              ref.setState({ rejectButtonDisabled: false });
              let errorRejMsg = ref.props.tr['error in rejection'];
              // alert(errorRejMsg);
              swal({
                text: errorRejMsg,
                button: ref.props.tr['OK'],
              });
            }
          });
      }
    }

    else if (type == "referBack") {
      let referBackTextValueForActivityLog = this.state.referBackTextValue;
      if (this.state.approveTextValue != "") {
        ref.setState({ loader: false });
        let appmsg = ref.props.tr['Please remove the approval comment'];
        // alert(appmsg);
        swal({
          text: appmsg,
          button: ref.props.tr['OK'],
        });
        return false;
      }
      if (this.state.rejectTextValue != "") {
        ref.setState({ loader: false });
        let appmsg = ref.props.tr['Please remove the rejection reason'];
        // alert(appmsg);
        swal({
          text: appmsg,
          button: ref.props.tr['OK'],
        });
        return false;
      }
      if (this.state.referBackTextValue.trim() == "") {
        ref.setState({ loader: false });
        let rejmsg = ref.props.tr['provide reason for refer back'];
        // alert(rejmsg);
        swal({
          text: rejmsg,
          button: ref.props.tr['OK'],
        });
        return false;
      } else {
        //  this.setState({ loader: true });
        ref.setState({ referBackButtonDisabled: true });
        axios
          .put("/api/approval/myapproval/" + signatureId, {
            status: "Refer Back",
            moreinfoby: "",
            moreinforequired: this.state.referBackTextValue.replace(/"/g, '').replace(/\\/g, '\\\\').replace(/(\r\n|\n|\r)/gm, ""),
            lastUpdatedBy: ""
          })
          .then(response => {
            if (response.status == "201" || response.status == 201) {
              if (module.modulelist[0][0].module == "10") {
                swal({
                  text: ref.props.tr["Another Approver has already taken action on this ticket."],
                  button: ref.props.tr['OK'],
                });
                this.props.itemDisclaimerChange(false);
                ref.props.showRightSide(false);
                ref.props.getMyApprovalList(
                  ref.props.user_Id,
                  ref.props.sortOrder,
                  ref.props.sortOnColumn,
                  ref.props.status,
                  ref.props.page,
                  ref.props.limit,
                  ref.props.modules,
                  ref.props.searchKey,
                  ref.props.searchValue
                );
                ref.props.loadCartCount();
                ref.props.changeReferBackSecHideShow(false);
                // ref.setState({ rejectSecHideShow: false });
                ref.setState({ referBackTextValue: "" });
                ref.setState({ approvedButtonDisabled: false });
                ref.setState({ rejectButtonDisabled: false });
                ref.setState({ referBackButtonDisabled: false });
                ref.setState({ loader: false });
              }

              else {
                if (module.modulelist[0][0].module == "14") {
                  swal({
                    text: ref.props.tr["Another Approver has already taken action on this ticket."],
                    button: ref.props.tr['OK'],
                  });
                  axios
                    .patch(GLOBAL.changeEditUrl + module.signaturelist[0][0].itemid, {
                      // status: "50",
                      "systemStartDateRequired": "N",
                      "systemEndDateRequired": "N"
                    })
                    .then(response => {

                      if (response.status == 200 || response.status == 201) {
                        ref.setState({ loader: false });
                        //ref.props.loadBreakFixAuditLogDetails(module.changeId,"dsc", "ChangeManagement",'STATUS');
                        //ref.props.getMyApprovalList(userId, "desc");
                        ref.props.showRightSide(false);
                        ref.props.getMyApprovalList(
                          ref.props.user_Id,
                          ref.props.sortOrder,
                          ref.props.sortOnColumn,
                          ref.props.status,
                          ref.props.page,
                          ref.props.limit,
                          ref.props.modules,
                          ref.props.searchKey,
                          ref.props.searchValue
                        );
                        ref.props.loadCartCount();
                        ref.props.changeReferBackSecHideShow(false);
                        // ref.setState({ rejectSecHideShow: false });
                        ref.setState({ referBackTextValue: "" });
                        ref.setState({ approvedButtonDisabled: false });
                        ref.setState({ rejectButtonDisabled: false });
                        ref.setState({ referBackButtonDisabled: false });
                      }
                    });
                }
              }
            }
            else if (response.status == "200" || response.status == 200) {
              if (module.modulelist[0][0].module == "10") {
                let rejSuccess = ref.props.tr['Referred Back Successfully']
                this.props.itemDisclaimerChange(false);

                // alert(rejSuccess);
                swal({
                  text: rejSuccess,
                  button: ref.props.tr['OK'],
                });
                // api.post(GLOBAL.breakFixActivityLogPostUrl, {
                //   "itemId": module.signaturelist[0][0].itemid,
                //   "description": "Refer Back Comments" + referBackTextValueForActivityLog,
                //   "isInternal": "N",
                //   "createdOn": "",
                //   "createdBy": "",
                //   "module": "Fullfilment",
                //   "createdByName": "",
                //   "isReorder": "Y"
                // }).
                //   then((response) => {
                //     // this.props.loadBreakFixActivityLogDetails(response.data.itemId, "desc", "Fullfilment", () => { }, "Y","RESPONSEITM");
                //   });


                //ref.props.getMyApprovalList(userId, "desc");
                ref.props.showRightSide(false);
                ref.props.showActiveRow(0);
                ref.props.getMyApprovalList(
                  ref.props.user_Id,
                  ref.props.sortOrder,
                  ref.props.sortOnColumn,
                  ref.props.status,
                  ref.props.page,
                  ref.props.limit,
                  ref.props.modules,
                  ref.props.searchKey,
                  ref.props.searchValue
                );
                ref.props.loadCartCount();
                ref.props.changeReferBackSecHideShow(false);
                // ref.setState({ rejectSecHideShow: false });
                ref.setState({ referBackTextValue: "" });
                ref.setState({ approvedButtonDisabled: false });
                ref.setState({ rejectButtonDisabled: false });
                ref.setState({ referBackButtonDisabled: false });
                ref.setState({ loader: false });
                /* 		}
                  }); */
              } else {
                if (module.modulelist[0][0].module == "14") {
                  let changeapprovalMsg = ref.props.tr['change approval referred back'];
                  // alert(changeapprovalMsg);
                  swal({
                    text: changeapprovalMsg,
                    button: ref.props.tr['OK'],
                  });

                  let patchObj = {
                    // status: "50",
                    "systemStartDateRequired": "N",
                    "systemEndDateRequired": "N"
                  };
                  if(changeType == '35'){
                    patchObj['status'] = '95';
                  }

                  axios
                    .patch(GLOBAL.changeEditUrl + module.signaturelist[0][0].itemid, patchObj)
                    .then(response => {
                      if (response.status == 200 || response.status == 201) {
                        ref.setState({ loader: false });
                        //ref.props.loadBreakFixAuditLogDetails(module.changeId,"dsc", "ChangeManagement",'STATUS');
                        //ref.props.getMyApprovalList(userId, "desc");
                        ref.props.showRightSide(false);
                        ref.props.showActiveRow(0);
                        ref.props.getMyApprovalList(
                          ref.props.user_Id,
                          ref.props.sortOrder,
                          ref.props.sortOnColumn,
                          ref.props.status,
                          ref.props.page,
                          ref.props.limit,
                          ref.props.modules,
                          ref.props.searchKey,
                          ref.props.searchValue
                        );
                        ref.props.loadCartCount();
                        ref.props.changeReferBackSecHideShow(false);
                        // ref.setState({ rejectSecHideShow: false });
                        ref.setState({ referBackTextValue: "" });
                        ref.setState({ approvedButtonDisabled: false });
                        ref.setState({ rejectButtonDisabled: false });
                        ref.setState({ referBackButtonDisabled: false });
                      }
                      /*api.post(GLOBAL.breakFixActivityLogPostUrl, {
                        "itemId": module.signaturelist[0][0].itemid,
                        "description": "Refer Back Comments" + referBackTextValueForActivityLog,
                        "isInternal": "N",
                        "createdOn": "",
                        "createdBy": "",
                        "module": "ChangeManagement",
                        "createdByName": "",
                        "isReorder": "Y"
                      }).
                        then((response) => {
                          // this.props.loadBreakFixActivityLogDetails(response.data.itemId, "desc", "ChangeManagement", () => { }, "Y","RESPONSEAPP");
                        });*/
                    });
                }
              }
            } else {
              ref.setState({ referBackButtonDisabled: false });
              let errorRejMsg = ref.props.tr['error in refer back'];
              // alert(errorRejMsg);
              swal({
                text: errorRejMsg,
                button: ref.props.tr['OK'],
              });
            }
          });
      }
    }

    else {
      // Approved ---------------------------------------------------
      let dateformat = this.state.date_format.slice(0, 10).toUpperCase();
      let cabDateValue = "0";
      if (this.props.changeTotalApprovalDetails && this.props.changeTotalApprovalDetails[0].isRequester == "true") {
        if(this.props.requesterDelegateeDetails && this.props.requesterDelegateeDetails.length > 0) {
          let delegateeCheck = false;
          this.props.requesterDelegateeDetails.forEach((delegatee) => {
            if(user_id == delegatee.delegatee) {
              delegateeCheck = true;
            }
          });
          if(delegateeCheck == true || user_id == this.props.changeTotalApprovalDetails[0].requesterId) {
            this.setState({ loader: false });
            swal({
              text: this.props.tr['The requester cannot be the sole approver. Please select at least one other approver'],
              button: this.props.tr['OK'],
            });
            return false;
          }
        }
        else {
          if(user_id == this.props.changeTotalApprovalDetails[0].requesterId){
            this.setState({ loader: false });
            swal({
              text: this.props.tr['The requester cannot be the sole approver. Please select at least one other approver'],
              button: this.props.tr['OK'],
            });
            return false;
          }
        }
      }
      if (this.state.rejectTextValue != "") {
        ref.setState({ loader: false });
        let appmsg = ref.props.tr['Please remove the rejection reason'];
        // alert(appmsg);
        swal({
          text: appmsg,
          button: ref.props.tr['OK'],
        });
        return false;
      }
      if (this.state.referBackTextValue != "") {
        ref.setState({ loader: false });
        let appmsg = ref.props.tr['Please remove the refer back reason'];
        // alert(appmsg);
        swal({
          text: appmsg,
          button: ref.props.tr['OK'],
        });
        return false;
      }
      if (module.itemType === 'Fulfillment' && module.questionsText[0][0].questiontext !== "") {
        if( this.state.newQuestionsTextValue.length !== module.questionsText[0].length) {
          ref.setState({ loader: false });
          let appmsg = ref.props.tr['Please select the answer'];
          swal({
            text: appmsg,
            button: ref.props.tr['OK'],
          });
          return false;
        }
      }
      if (this.state.approveTextValue.trim() == "") {
        ref.setState({ loader: false });
        let appmsg = ref.props.tr['provide approval comment'];
        // alert(appmsg);
        swal({
          text: appmsg,
          button: ref.props.tr['OK'],
        });
        return false;
      } else {
        let approveTextValueForActivityLog = this.state.approveTextValue;
        if (type == "approve") {
          let obj = {};
          obj.status = "Approved";
          obj.approvedBy = "";
          obj.lastUpdatedBy = "";
          obj.approval_comments = this.state.approveTextValue.replace(/"/g, '').replace(/\\/g, '\\\\').replace(/(\r\n|\n|\r)/gm, "");
          if (module.questionsText[0].questiontext !== "" && this.state.newQuestionsTextValue.length > 0) {
            obj.questionTextValue =  this.state.newQuestionsTextValue;
          }
          if (module.itemType === 'ChangeManagement') {
            // if ((this.props.approvalObj.signaturelist[0][0].levelSequence == "1") && (this.props.editChangeInitialData.type == "30" || this.props.editChangeInitialData.type == "25") && (this.props.approvalObj.signaturelist[0][0].isCabDateExist == "false")) {
            //   if (this.state.cabDateVal == '' || this.state.cabError == "error") {
            //     ref.setState({ loader: false });
            //     swal({
            //       text: ref.props.tr['Please select valid CAB date.'],
            //       button: ref.props.tr['OK'],
            //     });
            //     return false;
            //   }
            //   if (moment(this.state.cabDateVal, dateformat) < moment(moment(), dateformat)) {
            //     ref.setState({ loader: false });
            //     this.setState({ errorMsg: "CAB Date should be atleast today's date.", cabError: 'error' });
            //     return false;
            //   }
            //   cabDateValue = this.state.cabDateVal.format(dateformat + " HH:mm:ss");
            //   obj.cabDate = cabDateValue;
            // }
          }
          ref.setState({ approvedButtonDisabled: true });
          axios
            // .put("/api/approval/myapproval/" + signatureId, {
            //   status: "Approved",
            //   approvedBy: "",
            //   lastUpdatedBy: "",
            //   approval_comments: this.state.approveTextValue
            // })
            .put("/api/approval/myapproval/" + signatureId, obj)
            .then(response => {
              if (response.status == "201" || response.status == 201) {
                swal({
                  text: ref.props.tr["Another Approver has already taken action on this ticket."],
                  button: ref.props.tr['OK'],
                });
                ref.props.showRightSide(false);
                ref.props.getMyApprovalList(
                  ref.props.user_Id,
                  ref.props.sortOrder,
                  ref.props.sortOnColumn,
                  ref.props.status,
                  ref.props.page,
                  ref.props.limit,
                  ref.props.modules,
                  ref.props.searchKey,
                  ref.props.searchValue
                );
                ref.props.loadCartCount();
                ref.props.changeApproveSecHideShow(false);
                // ref.setState({ approveSecHideShow: false });
                ref.setState({ approveTextValue: "" });
                ref.setState({ newQuestionsTextValue: [] });
                ref.setState({ approvedButtonDisabled: false });
                ref.setState({ rejectButtonDisabled: false });
                ref.setState({ referBackButtonDisabled: false });
                ref.setState({ loader: false });
                this.props.itemDisclaimerChange(false);

              }
              else if (response.status == "200" || response.status == 200) {
                //ref.props.getMyApprovalList(userId, "desc");
                ref.props.showRightSide(false);
                ref.props.showActiveRow(0);
                ref.props.getMyApprovalList(
                  ref.props.user_Id,
                  ref.props.sortOrder,
                  ref.props.sortOnColumn,
                  ref.props.status,
                  ref.props.page,
                  ref.props.limit,
                  ref.props.modules,
                  ref.props.searchKey,
                  ref.props.searchValue
                );
                ref.props.loadCartCount();
                ref.props.changeApproveSecHideShow(false);
                // ref.setState({ approveSecHideShow: false });
                ref.setState({ approveTextValue: "" });
                ref.setState({ newQuestionsTextValue: [] });
                ref.setState({ approvedButtonDisabled: false });
                ref.setState({ rejectButtonDisabled: false });
                ref.setState({ referBackButtonDisabled: false });
                ref.setState({ loader: false });

                let successMsg = this.props.tr['Approved Successfully'];
                this.props.itemDisclaimerChange(false);

                // alert(successMsg);
                swal({
                  text: successMsg,
                  button: ref.props.tr['OK'],
                });
                api.post(GLOBAL.breakFixActivityLogPostUrl, {
                  "itemId": module.signaturelist[0][0].itemid,
                  "description": ref.props.tr["Approved Comments: "] + approveTextValueForActivityLog,
                  "isInternal": "N",
                  "createdOn": "",
                  "createdBy": "",
                  "module": module.itemType == "Fulfillment" ? "Fullfilment" : module.itemType,
                  "createdByName": "",
                  "isReorder": "Y"
                }).
                  then((response) => {
                    // this.props.loadBreakFixActivityLogDetails(response.data.itemId, "desc", module.itemType, () => { }, "Y","RESPONSEAPPR");
                  });
                approveTextValueForActivityLog = '';
                if (module.modulelist[0][0].module == "14") {
                  let callApi = false;
                  let obj = {
                    //  status: "",
                    "systemStartDateRequired": "N",
                    "systemEndDateRequired": "N"
                  };
                  // if ((ref.props.approvalObj.signaturelist[0][0].levelSequence == "1") && (ref.props.editChangeInitialData.type == "30" || ref.props.editChangeInitialData.type == "25") && (ref.props.approvalObj.signaturelist[0][0].isCabDateExist == "false")) {
                  //   obj.cabDate = cabDateValue;
                  // }
                  // axios
                  //   .get(
                  //     GLOBAL.getLeftApprovalUrl +
                  //     "companyId=" +
                  //     module.consumerCompany +
                  //     "&module=14&serviceId=" +
                  //     module.serviceId +
                  //     "&workitemId=" +
                  //     module.signaturelist[0][0].itemid
                  //   )
                  axios
                    .get(
                      GLOBAL.getLeftApprovalUrl +
                      "companyId=" +
                      ref.props.editChangeInitialData.consumerCompany +
                      "&module=14&serviceId=" +
                      ref.props.editChangeInitialData.serviceId +
                      "&workitemId=" +
                      ref.props.editChangeInitialData.changeId
                    )
                    .then(res => {
                      if (res.status == 200) {
                        if (res.data == "1") {
                          // obj.status = "40";
                          callApi = true;
                        } else {
                          if (res.data == "0") {
                            //  obj.status = "45";
                            callApi = true;
                          }
                          callApi = true;
                        }
                      }

                      if (callApi) {
                        axios
                          .patch(GLOBAL.changeEditUrl + module.signaturelist[0][0].itemid, obj)
                          .then(response => {
                            if (
                              response.status == 200 ||
                              response.status == 201
                            ) {
                              //ref.props.getMyApprovalList(userId, "desc");
                              ref.props.showRightSide(false);
                              ref.props.showActiveRow(0);
                              ref.props.getMyApprovalList(
                                ref.props.user_Id,
                                ref.props.sortOrder,
                                ref.props.sortOnColumn,
                                ref.props.status,
                                ref.props.page,
                                ref.props.limit,
                                ref.props.modules,
                                ref.props.searchKey,
                                ref.props.searchValue
                              );
                              ref.props.loadCartCount();
                              ref.props.changeApproveSecHideShow(false);
                              // ref.setState({ approveSecHideShow: false });
                              ref.setState({ approveTextValue: "" });
                              ref.setState({ newQuestionsTextValue: [] });
                              ref.setState({ approvedButtonDisabled: false });
                              ref.setState({ rejectButtonDisabled: false });
                              ref.setState({ referBackButtonDisabled: false });
                            }
                          })
                          .catch(function (error) {
                          });
                      }
                    });
                }
              } else {
                ref.setState({ approvedButtonDisabled: false });
                let appmsg = ref.props.tr['error in approval'];
                // alert(appmsg);
                swal({
                  text: appmsg,
                  button: ref.props.tr['OK'],
                });
              }
            });
        }
      }
    }
  }

  getAttachment(itemId, Module) {
    if(this.state.attachmentsTabOpen == false) {
      this.props.loadImplPlanList(Module, itemId);
    }
    this.setState({ 
      attachmentsTabOpen: !this.state.attachmentsTabOpen,
      approvalsTabOpen: false,
      infoProvidedTabOpen: false,
      requestorInfoTabOpen: false,
      itemComponentsTabOpen: false,
      viewUpdatesTabOpen: false,
      changePlansTabOpen: false
    });
  }

  getInformation(itemId) {
    if(this.state.infoProvidedTabOpen == false) {
      this.props.getQuestionsCategoryWise(itemId);
    }
    this.setState({ 
      infoProvidedTabOpen: !this.state.infoProvidedTabOpen,
      approvalsTabOpen: false,
      attachmentsTabOpen: false,
      requestorInfoTabOpen: false,
      itemComponentsTabOpen: false,
      viewUpdatesTabOpen: false,
      changePlansTabOpen: false
    });
  }

  createDescriptionMarkup(description) {

    let namedSchema = undefined
    namedSchema = Joi.string().regex(/^[^}{)(]+$/);
    let validator_to_add_myQbj = undefined
    let validate_summary = undefined
    if (description != null || description != "") {
      validator_to_add_myQbj = namedSchema.description
      if (validator_to_add_myQbj.error == null) {
        validate_summary = description
      }
      else {
        validate_summary = ""
      }
    }
    else
      validate_summary = ""
    return { __html: sanitizeHtml(validate_summary, {allowedAttributes: {
      '*': ['style', 'class', 'dir', 'bgcolor'],
      'table': ['border', 'cellpadding', 'cellspacing'],
      'th': ['scope']
    }}) };
    //return { __html: description };
  }

  approvalsViewUpdate(approvalData) {
    if (approvalData == undefined || approvalData == "" || approvalData == []) {
      return false;
    } else {
      return approvalData.map((approvalObj, index) => {
        let activitymsg = "";
        if (approvalObj.from === approvalObj.to)
          activitymsg = this.props.tr["Status set to "] + approvalObj.to;
        else
          activitymsg = this.props.tr["Changed Status from "] + approvalObj.from + this.props.tr[" to "] + approvalObj.to
        return (
          <Card bsPrefix=" " className="mediaCrd">
            <Card.Body>
              <Card.Header className="blue margin-0">{approvalObj.createdOn} <span className="colorStOrange cursorPoint">{approvalObj.createdByName}</span></Card.Header>
              <div className="black fw-300 cTxt">
              {/* <ReadMore lines={1} text={MoreArrow}> */}
                {approvalObj.description}
              {/* </ReadMore> */}
              </div>
            </Card.Body>
          </Card>
        )
      });
    }
  }

  renderQuestionFields(questionsText) {
   if(questionsText.length > 0 && questionsText[0].questiontext !== "") {
    return questionsText.map((question) => {
      return (
      <Form.Group >
        <div className="f-size-15 margin-t-20 margin-b-5 "><span className="rStar"></span> {question.questiontext}</div>
        <select name={question.questiontext} className="form-control" value={this.state.newQuestionsTextValue.filter((ques)=>ques.question === question.questiontext)[0]?.value} onChange={(e) => this.selectQuestionsTextValue(e, question)}>
          <option value="">{this.props.tr['Select']}</option>
          {this.renderQuestionDropdownOptions(question.questionvalue)}
        </select>
      </Form.Group>
    );
    })
  }
  }
  renderDetailsForItem(Item) {
    this.props.loadImplPlanList("", "");
    let fulfillmentDetails = this.props.fulfillmentDetails;

    let disclaimers = this.props.disclaimers.length <= 0 ? this.props.disclaimers : this.props.disclaimers[0].APPROVER_DISCLAIMER;
    if (disclaimers != null && disclaimers.length != 0) {
      disclaimers = disclaimers.replace(/<[^>]+>/g, '');
    }

		let displayName="";
		let displayNameStr="";
		displayNameStr = Item.signaturelist[0][0].offering_name != null && Item.signaturelist[0][0].offering_name != "" ? Item.signaturelist[0][0].offering_name : "";
		displayName = displayNameStr != "" ? displayNameStr.replace(/(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/ig, '').trim() : "";
		displayName = displayName != "" ? displayName.replace(/\s+/g, ' ') : "";
  
		let getInnerWidth = (innerWidth)=>{
		  if (innerWidth > 1535) return 92;
		  if (innerWidth > 1367) return 80;
		  if (innerWidth > 1279) return 70;
		  if (innerWidth > 1023) return 50;
		  if (innerWidth > 767) return 38;
		  if (innerWidth > 640) return 62;
		  if (innerWidth > 539) return 48;
		  if (innerWidth > 411) return 70;
		  if (innerWidth > 279) return 40;
		  return 20;
		}

    let itemStatus = "";

    let finalValue = formatMinutesIntoDaysHoursMinutes(fulfillmentDetails.deliveryType);

    if (Item.approvalstatuslist[0][0].approvalstatus == "null") {
      itemStatus = "Pending for approval";
    } else {
      itemStatus = Item.approvalstatuslist[0][0].approvalstatus;
    }
    return (
      this.props.loadingRightSide || this.props.showLoader ?
        <div className="margin-b-20">
          <ListLoader myStyle={{ marginTop: "20px" }} /></div>
        :
        <div aria-label='Right Section' role="contentinfo">
          <div className="rPageHeadActBtn">
            <ul>
              <li>
              <Link to="javascript:void(0)" className="closeRsBtn" id="closeButton"
                    title={this.props.tr["Minimize the details panel"]}
                    onClick={() => {
                      this.props.showRightSide(false);
                    }}
                    ref={e => this.closeButton = e}
                  >
                    <IoClose/>
                  </Link>
              </li>
            </ul>
          </div>
          <div className="rPageHeading">
            {Item.signaturelist[0][0].order_number == "" || Item.signaturelist[0][0].order_number == "null" || Item.signaturelist[0][0].order_number == null ?
              Item.signaturelist[0][0].item_number
              :
              Item.signaturelist[0][0].order_number + "-" + Item.signaturelist[0][0].item_number}
            <div className="offNam" title={Item.signaturelist[0][0].offering_name}>
              {displayName.slice(0, getInnerWidth(this.props.windowSize.width))}{displayName.length > getInnerWidth(this.props.windowSize.width) ? "..." : ''}
            </div>
          </div>
          <div className="rBoxGap">
            <div className="rBoxTabs hideTabNav">
            <Tabs activeKey={this.state.tabKey} onSelect={this.handleSelect} id="tabId">
              <Tab tabindex="1" eventKey={1} title={this.props.tr["Request Details"]}>
                <ListGroup bsPrefix=' '>
                  <ListGroup.Item bsPrefix=' ' className="rwTable" style={{ "border-top": "none" }}><span className="rwKey">{this.props.tr['Work Item ID']}</span><span className="rwVal">{Item.signaturelist[0][0].item_number}</span></ListGroup.Item>
                  {/* <ListGroup.Item bsPrefix=' ' className="rwTable"><span className="rwKey">{this.props.tr['Delivery Time']}</span><span className="rwVal">{finalValue}</span></ListGroup.Item> */}
                  <ListGroup.Item bsPrefix=' ' className="rwTable"><span className="rwKey">{this.props.tr['Service Value']}</span>{fulfillmentDetails.isPriceEnabled == true && fulfillmentDetails.itemPrice > 0 ? <span className="rwVal">{fulfillmentDetails.currency} {fulfillmentDetails.itemPrice}</span> : <span className="rwVal">{this.props.tr['NA']}</span>}</ListGroup.Item>
                  {fulfillmentDetails.quantity > 0 ?
                    <ListGroup.Item bsPrefix=' ' className="rwTable"><span className="rwKey">{this.props.tr['Quantity']}</span><span className="rwVal">{fulfillmentDetails.quantity}</span></ListGroup.Item> : null}
                  <ListGroup.Item bsPrefix=' ' className="rwTable"><span className="rwKey">{this.props.tr['Submit Date']}</span><span className="rwVal">{fulfillmentDetails.createdOn}</span> </ListGroup.Item>
                  <ListGroup.Item bsPrefix=' ' className="rwTable"><span className="rwKey">{this.props.tr['Status']}</span><span className="rwVal">{this.props.tr[itemStatus]}</span></ListGroup.Item>
                </ListGroup>

                <div className="brkFix brkFixOrder margin-t-20 clearboth">
                  <Accordion id="rightSidePanel1" style={{ "border-top": "none" }}>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header onClick={() => { this.setState({infoProvidedTabOpen: false, approvalsTabOpen: false, attachmentsTabOpen: false, requestorInfoTabOpen: !this.state.requestorInfoTabOpen, itemComponentsTabOpen: false, viewUpdatesTabOpen: false, changePlansTabOpen: false}) }}>
                        <h4 toggle>{this.props.tr['Requestor Information']}</h4>
                      </Accordion.Header>
                      <Accordion.Body>
                        {this.props.showLoader3 ?
                          <ListLoader />
                          :
                          this.renderAdditionalDetails(this.props.profileDetails)
                        }
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey='2'>
                      <Accordion.Header onClick={() => { this.checkRenderApproverData(Item.signaturelist[0][0].offeringid, "10", Item.signaturelist[0][0].itemid, '0', '0') }}>
                        <h4 toggle>{this.props.tr['Approval']}</h4></Accordion.Header>
                      <Accordion.Body>
                        {this.state.showApprovalLoader ? <div><ListLoader /></div> :
                          this.state.displayMessage != "" ? this.state.displayMessage :
                            this.props.rightMenuShowLoader ? <div>	<ListLoader /></div> :
                              this.props.changeTotalApprovalDetails.length > 0 ?
                                <Table responsive className="f-size-13 table table-striped table-bordered table-condensed table-hover margin-b-0">
                                  <thead>
                                    <tr>
                                      <th width="9%"></th>
                                      <th width="17%">{this.props.tr['Level']}</th>
                                      <th width="44%">{this.props.tr['Approver']}</th>
                                      <th width="30%">{this.props.tr['Date & Time']}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.renderAppHistoryDataLog(this.props.changeTotalApprovalDetails)}
                                  </tbody>
                                </Table> :
                                <div>
                                  {this.props.tr["Approval is being triggered. Please try later!"]}
                                </div>
                        }
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                      <Accordion.Header onClick={() => { this.setState({infoProvidedTabOpen: false, approvalsTabOpen: false, attachmentsTabOpen: false, requestorInfoTabOpen: false, itemComponentsTabOpen: !this.state.itemComponentsTabOpen, viewUpdatesTabOpen: false, changePlansTabOpen: false}) }}>
                          <h4 toggle>{this.props.tr['Item Components']}</h4>
                      </Accordion.Header>
                      <Accordion.Body>
                        <ListGroup bsPrefix=' '>
                          {this.renderItemComponents(fulfillmentDetails)}
                        </ListGroup>

                      </Accordion.Body>
                    </Accordion.Item>
                    {Item.questionsText.length == 0 ? null : (
                      <Accordion.Item eventKey="4">
                        <Accordion.Header onClick={() => { this.getInformation(Item.signaturelist[0][0].itemid); }}><h4 toggle>{this.props.tr['Information Provided']}</h4></Accordion.Header>
                        <Accordion.Body>
                          {this.props.showLoader4 ?
                            <ListLoader />
                            :
                            this.userChoices(this.props.myOrderQuestionDetails)
                          }
                        </Accordion.Body>
                      </Accordion.Item>
                    )}

                    <Accordion.Item eventKey="5">
                      <Accordion.Header onClick={() => { this.getAttachment(Item.signaturelist[0][0].itemid, "Item"); }}><h4 toggle>{this.props.tr['Attachment']}</h4></Accordion.Header>
                      <Accordion.Body>
                        <ListGroup>{this.renderAttachedItems(this.props.implememtationPlanList)}</ListGroup>
                      </Accordion.Body>
                    </Accordion.Item>
                    {this.props.breakFixActivityLogDetails.length == 0 ? null : (
                      <Accordion.Item eventKey="6">
                        <Accordion.Header onClick={() => { this.setState({infoProvidedTabOpen: false, approvalsTabOpen: false, attachmentsTabOpen: false, requestorInfoTabOpen: false, itemComponentsTabOpen: false, viewUpdatesTabOpen: !this.state.viewUpdatesTabOpen, changePlansTabOpen: false}) }}>
                          <h4 toggle>{this.props.tr['View Updates']}</h4>
                        </Accordion.Header>
                        <Accordion.Body>
                          {this.approvalsViewUpdate(this.props.breakFixActivityLogDetails)}
                        </Accordion.Body>
                      </Accordion.Item>
                    )}
                  </Accordion>
                </div>

                {Item.approvalstatuslist[0][0].approvalstatus != "null" &&
                  Item.approvalstatuslist[0][0].approvalstatus != "" &&
                  Item.approvalstatuslist[0][0].approvalstatus != "Pending" &&
                  Item.approvalstatuslist[0][0].approvalstatus != "Pending for approval" ? null :
                  <div className="myAppvls margin-t-10">
                    {disclaimers == null || disclaimers.length == 0 ? null :
                      <div key={`inline-checkbox`} className={this.props.itemDisclaimer? "mb-3 d-flex align-items-md-center disabled":"mb-3 d-flex align-items-md-center"}>
                        <Form.Check inline className="apprvlCheck" type="checkbox" disabled={this.props.itemDisclaimer} checked={this.props.itemDisclaimer} onClick={() => { this.props.itemDisclaimerChange(true) }} />
                          <div className="disclaimerHtm" dangerouslySetInnerHTML={this.createDescriptionMarkup(this.props.disclaimers[0].APPROVER_DISCLAIMER)} />
                      </div>}
                    <div aria-label='Button Toolbar' role="contentinfo" className="margin-t-15">
                    <ButtonToolbar className="myAppChange">
                      <Button onClick={this.onClickApprove} variant="primary" disabled={this.state.rejectButtonDisabled || this.state.approvedButtonDisabled || (disclaimers == null || disclaimers.length == 0 ? false : !this.props.itemDisclaimer)} className={this.state.activeBtn=== "approve"? "rgSidrkBtn" : "rgSiLigBtn"}>{this.props.tr['Approve']}</Button>
                      <Button onClick={this.onClickReject} variant="primary" disabled={this.state.rejectButtonDisabled || this.state.approvedButtonDisabled} className={this.state.activeBtn=== "reject"? "rgSidrkBtn" : "rgSiLigBtn"}> {this.props.tr['Reject']}</Button>
                      <Button onClick={this.onClickReferBack} variant="primary" disabled={this.state.referBackButtonDisabled} className={this.state.activeBtn=== "referBack"? "rgSidrkBtn" : "rgSiLigBtn"}>{this.props.tr['Refer Back']}</Button>
                    </ButtonToolbar></div>
                  </div>
                }

                {this.props.rejectSecHideShow ?
                  <div className="margin-t-20">
                    <div className="f-size-15 margin-t-20 margin-b-5 ">
                      <span className="rStar"></span> {this.props.tr['Reason for Rejection']}
                    </div>
                    <Form.Group className='form-group'>
                      <Form.Control as="textarea" value={this.state.rejectTextValue} rows="3" onChange={event => { this.updateRejectTextField(event.target.value); }} maxLength="1000" />
                    </Form.Group>
                    <div className="f-size-15 margin-t-20 margin-b-5 ">{this.props.tr['Are you sure you want to reject?']}</div>
                    <div aria-label='Button Toolbar' role="contentinfo">
                    <ButtonToolbar>
                      <Button bsSize="small" variant="primary" disabled={this.state.rejectButtonDisabled} onClick={() => { this.updateApprovalStatus("reject", Item); }}>{(this.state.loader && this.state.rejectTextValue !== "") ? <ImSpinner6 className="icn-spinner"/> : null}{this.props.tr['Yes']}</Button>
                      <Button bsSize="small" variant="primary" disabled={this.state.rejectButtonDisabled} onClick={() => { this.props.changeRejectSecHideShow(false); this.setState({ loader: false }); }}>{this.props.tr['No']}</Button>
                    </ButtonToolbar></div>
                  </div>
                  : null}

                {this.props.approveSecHideShow ? (
                  <div className="margin-t-20">
                    {this.props.showCreateAdhocApproverPanel == false ?
                      <div>
                        <div className="f-size-15 margin-t-20 margin-b-5 ">
                          {this.props.tr['Additional Approvers (Optional)']}
                          <span className="float-r"><Button className="pad05" title={this.props.tr['Add']} onClick={() => { this.props.showCreateAdhocApproverPanelToggle(true); }}><i className="fa fa-plus"></i></Button></span>
                          {this.props.adhocApproversList.length > 0 ?
                            <span className="float-r margin-r-5"><Button className="pad05" title={this.props.tr['View']} onClick={() => { this.props.showApproversListToggle(!this.props.showApproversList) }}><i className="fa fa-eye"></i></Button></span>
                            : ''}
                        </div>
                        <div style={{ "clear": "both", "height": "2px" }}></div>
                        {this.props.showApproversList == true && this.props.adhocApproversList && this.props.adhocApproversList.length > 0 ?
                          this.renderAdhocApproversTable()
                          : null}
                        {Item.questionsText && Item.questionsText.length > 0 && Item.questionsText[0].length > 0  ?
                          this.renderQuestionFields(Item.questionsText[0])
                        
                          : ''}
                        <Form.Group className='form-group'>
                          <div className="f-size-15 margin-t-20 margin-b-5 "><span className="rStar"></span> {this.props.tr['Approval Comments']} </div>
                          <Form.Control as="textarea" value={this.state.approveTextValue} rows="3" onChange={event => { this.updateApproveTextField(event.target.value); }} maxLength="1000" />
                        </Form.Group>
                        <div className="f-size-15 margin-t-20 margin-b-5 ">{this.props.tr['Are you sure you want to approve?']}</div>
                        
                        <div aria-label='Button Toolbar' role="contentinfo">
                        <ButtonToolbar>
                          <Button bsSize="small" variant="primary" disabled={this.state.approvedButtonDisabled} onClick={() => { this.updateApprovalStatus("approve", Item); }}> {(this.state.loader && this.state.rejectTextValue === "") ? <ImSpinner6 className="icn-spinner"/> : null}{this.props.tr['Yes']}</Button>
                          <Button bsSize="small" variant="primary" disabled={this.state.approvedButtonDisabled} onClick={() => { this.props.changeApproveSecHideShow(false); this.props.itemDisclaimerChange(false); }} >{this.props.tr['No']}</Button>
                        </ButtonToolbar></div>
                      </div>
                      :
                      <div>
                        <div className="f-size-15 margin-t-20 margin-b-5 ">
                          {this.props.tr['Additional Approvers (Optional)']}
                          {this.props.adhocApproversList.length > 0 ? <span className="float-r"><Button className="pad05" title={this.props.tr['View']} onClick={() => { this.props.showApproversListToggle(!this.props.showApproversList) }}><i className="fa fa-eye"></i></Button></span> : ''}
                        </div>
                        <div style={{ "clear": "both", "height": "2px" }}></div>
                        {this.props.showApproversList == true && this.props.adhocApproversList && this.props.adhocApproversList.length > 0 ?
                          this.renderAdhocApproversTable()
                          : null}
                        <div className="margin-t-20 aproHiSho">
                          
                          <div className="col1v">
                              <Field
                                name="approver_name"
                                component={RFReactUser}
                                onUserNameChange={this.onUserNameChange}
                                changeAutosuggestState={this.changeAutosuggestState}
                                emptyAutosuggestValue={this.state.emptyAutosuggestValue}
                                tr={this.props.tr}
                              />
                            </div>
                            
                            <div className="col2v">
                              <Button onClick={() => { this.addApproverToList() }} title={this.props.tr['Add']} variant="primary" className="approveBtnBreak">{this.props.tr['Add']}</Button>

                            </div>
                        </div>
                        {this.state.approversArray.length == 0 ? null :
                          (<><Table className="margin-t-10">
                            <thead>
                              <tr>
                                <th >{this.props.tr['Approver Name']}</th>
                                <th >{this.props.tr['Action']}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.showCreateApproversList()}
                            </tbody>
                          </Table>
                          <Form.Group className='form-group'>
                          <div className="f-size-15 margin-t-20 margin-b-5 "><span className="rStar"></span> {['Approval Request Comments']} </div>
                          <Form.Control as="textarea" value={this.state.adhocTextValue} rows="3" onChange={event => {this.adhocTextField(event.target.value);}} maxLength="1000" />
                        </Form.Group>
                          </>)
                        }
                        <div aria-label='Button Toolbar' role="contentinfo" className="margin-t-20">
                          <ButtonToolbar>
                            <Button bsSize="small" variant="primary" onClick={() => { this.submitAdhocApprovers(); }} disabled={this.state.adhocButtonDisabled || this.state.approversArray.length == 0}>{this.state.adhocButtonDisabled == true ? <ImSpinner6 className="icn-spinner"/> : null} {this.props.tr['Submit']}</Button>
                            <Button bsSize="small" bsStyle="text" onClick={() => { this.props.showCreateAdhocApproverPanelToggle(false); }}>{this.props.tr['Cancel']}</Button>
                          </ButtonToolbar>
                        </div>
                      </div>
                    }
                  </div>
                ) : null}

                {this.props.referBackSecHideShow ? (
                  <div className="margin-t-20">
                    <div className="f-size-15 margin-t-20 margin-b-5 ">
                      <span className="rStar"></span> {this.props.tr['Reason for Refer Back']}
                    </div>
                    <Form.Group className='form-group'>
                      <div>
                      <Form.Control
                        as="textarea"
                        value={this.state.referBackTextValue}
                        rows="3"
                        maxLength="500"
                        onChange={event => {
                          this.updateReferBackTextField(event.target.value);

                        }}
                      />
                      <p className="charLeft" style={{textAlign:"right","fontSize":"11px"}}>({this.props.tr['Characters Remaining']}: {this.state.referBackTextValue==""?GLOBAL.cancelreasonCharMax:this.state.cancelreasonCharLeft}/500)</p>
                      </div>
                    </Form.Group>
                    <div aria-label='Button Toolbar' role="contentinfo">
                    <ButtonToolbar>
                      <Button bsSize="small" variant="primary" disabled={this.state.referBackButtonDisabled} onClick={() => { this.updateApprovalStatus("referBack", Item); }}>{(this.state.loader && this.state.referBackTextValue !== "") ? <ImSpinner6 className="icn-spinner"/> : null}{this.props.tr['Submit']}</Button>
                      <Button bsSize="small" variant="primary" disabled={this.state.referBackButtonDisabled} onClick={() => { this.props.changeReferBackSecHideShow(false); this.setState({ loader: false }); }}>{this.props.tr['Cancel']}</Button>
                    </ButtonToolbar></div>
                  </div>
                ) : null}

              </Tab>
            </Tabs>
            </div>
          </div >
        </div>
    );
  }

  renderQuestionDropdownOptions(dropdown) {
    let dropdownValues = dropdown.split(';');
    return dropdownValues.map((dropdownValue, i) => {
      return <option value={dropdownValue}>{dropdownValue}</option>;
    });
  }

  selectQuestionsTextValue(e) {
    let responses = [];
    if((this.state.newQuestionsTextValue.length > 0 && this.state.newQuestionsTextValue.filter((question) => question.question === e.target.name).length > 0)) {
      this.state.newQuestionsTextValue.map((question) => {
        if(question.question === e.target.name) {
        responses.push({ question:  e.target.name, value:e.target.value });
        } else {
          responses.push({ question:question.question , value:question.value   });
        }
      });
    } else {
      responses = this.state.newQuestionsTextValue;
      responses.push({ question: e.target.name, value: e.target.value });
    }
    this.setState({
      newQuestionsTextValue: responses
    });
  }

  checkRenderApproverData(offeringId, module, itemid, ciId, ciClass) {
    this.setState({ showApprovalLoader: true });
    if(this.state.approvalsTabOpen == false) {
      axios.get("/api/approval/ruleCount/offeringId/" + offeringId + "/moduleId/" + module + "/ciId/" + ciId + "/ciclass/" + ciClass + "/requestId/" + itemid)
      .then((resp) => {
        if (resp.status == 200) {
          this.setState({ showApprovalLoader: false });
          if (resp.data.count >= 1) {
            this.props.loadChangeTotalApprovalDetails(itemid, module, "N");
            this.setState({ displayMessage: "" })
          }
          else if (resp.data.count == -1) {
            this.setState({ showApprovalLoader: false });
            this.setState({
              displayMessage:
              this.props.tr["There are no active approvals as the request is cancelled"],
            });
          }
          else {
            this.setState({ showApprovalLoader: false });
            this.setState({ displayMessage: this.props.tr["This service does not require any approval"] })
          }
        }
      });
    }
    this.setState({ 
      approvalsTabOpen: !this.state.approvalsTabOpen,
      attachmentsTabOpen: false,
      infoProvidedTabOpen: false,
      requestorInfoTabOpen: false,
      itemComponentsTabOpen: false,
      viewUpdatesTabOpen: false,
      changePlansTabOpen: false
    });
  }

  // updateDateField(value) {
  //   let dateformat = this.state.date_format.slice(0, 10).toUpperCase();
  //   dateformat = dateformat + " HH:mm:ss";
  //   let data = this.props.editChangeInitialData;
  //   let date = value._d;

  //   let cabDateValue = moment(date).format(dateformat);
  //   let cabDateObj = moment(cabDateValue, dateformat);
  //   this.setState({ cabDateVal: cabDateObj })
  //   if (!date) {
  //     this.setState({ errorMsg: 'Select valid value', cabError: 'error' })
  //     return false;
  //   }
  //   if (moment(cabDateObj, dateformat) < moment(moment(), dateformat)) {
  //     this.setState({ errorMsg: "CAB Date should be atleast today's date.", cabError: 'error' });
  //     return false;
  //   }
  //   if(data.type != 30) {
  //     if (moment(cabDateObj, dateformat) > moment(data.expectedStartDate, dateformat)) {
  //       this.setState({ errorMsg: 'CAB date should fall before Expected Start date.', cabError: 'error' });
  //       return false;
  //     }
  //   }
  //   this.setState({ errorMsg: '', cabError: '' });
  // }
  renderInformationProvided(myOrderQuestionDetails) {
    if (myOrderQuestionDetails.length <= 0) {
      return (
        <div>
          <h4>{this.props.tr["Data not available."]}</h4>
        </div>
      );
    }
    return [].concat(myOrderQuestionDetails).sort((a, b) => a.categorySequence > b.categorySequence ? 1 : -1).map((category, i) => {
      return (
        <ListGroup.Item bsPrefix=" " bsClass="">
          <Accordion
            id={"questions" + i}
            accordion
            className="brkFix brkFixOrder"
          >
            <Accordion.Item eventKey="1" className="collapsed">
              <Accordion.Header>
                <h4 className="font1">{category.categoryName}</h4>
              </Accordion.Header>
              <Accordion.Body>
                <div key={i}>
                  <Table
                    bordered
                    condensed
                    hover
                    responsive
                    className="tableView addWhiteSpc"
                  >
                    <tbody>
                      {this.renderIndividualCategoryQuestions(
                        category.questions
                      )}
                    </tbody>
                  </Table>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </ListGroup.Item>
      );
    });
  }

  renderDetailsForChange(Change) {
    let ciClass = Change.signaturelist[0][0].ciClass == "" || Change.signaturelist[0][0].ciClass == "null" ? undefined : Change.signaturelist[0][0].ciClass;
    let ciId = Change.signaturelist[0][0].ciid == "" || Change.signaturelist[0][0].ciid == "null" ? undefined : Change.signaturelist[0][0].ciid;

    let disclaimers = this.props.disclaimers.length <= 0 ? this.props.disclaimers : this.props.disclaimers[0].APPROVER_DISCLAIMER;
    if (disclaimers != null && disclaimers.length != 0) {
      disclaimers = disclaimers.replace(/<[^>]+>/g, '');
    }
let disclaimersVisible = this.props.disclaimers.length <= 0 ? 'N' : this.props.disclaimers[0].ISVISIBLE;

    let editChangeInitialData = this.props.editChangeInitialData;
    let dateformat = this.state.date_format.slice(0, 10).toUpperCase();

    let changeT = "";
    switch (editChangeInitialData.type) {
      case "10":
        changeT = "Minor";
        break;
      case "15":
        changeT = "Major";
        break;
      case "20":
        changeT = "Standard";
        break;
      case "25":
        changeT = "Normal";
        break;
      case "30":
        changeT = "Emergency";
        break;
      case "35":
        changeT = "Latent";
        break;
    }
    return (
      this.props.loadingRightSide || this.props.showLoader ?
        <div className="margin-b-20"><ListLoader myStyle={{ marginTop: "20px" }} /></div>
        :
        <div aria-label='Right Section' role="contentinfo">
          <div className="rPageHeadActBtn">
            <ul>
              <li>
              <Link to="javascript:void(0)" className="closeRsBtn" id="closeButton"
                    title={this.props.tr["Minimize the details panel"]}
                    onClick={() => {
                      this.props.showRightSide(false);
                    }}
                    ref={e => this.closeButton = e}
                  >
                    <IoClose/>
                  </Link>
              </li>
            </ul>
          </div>
          <div className="rPageHeading">
            {Change.signaturelist[0][0].order_number == "" || Change.signaturelist[0][0].order_number == "null" || Change.signaturelist[0][0].order_number == null ? Change.signaturelist[0][0].item_number : Change.signaturelist[0][0].order_number + "-" + Change.signaturelist[0][0].item_number}
            <div className="offNam">{Change.signaturelist[0][0].ciname}</div>
          </div>
          <div className="rBoxGap">
            <div className="rBoxTabs">
            <Tabs activeKey={this.state.tabKey} onSelect={this.handleSelect} id="tabId">
              <Tab tabindex="1" eventKey={1} title={this.props.tr["Request Details"]}>
                <ListGroup bsPrefix=' '>
                  <ListGroup.Item bsPrefix=' ' className="rwTable" style={{ 'border-top': 'none' }}><span className="rwKey">{this.props.tr['Change Id']}</span><span className="rwVal">{Change.signaturelist[0][0].item_number}</span></ListGroup.Item>
                  <ListGroup.Item bsPrefix=' ' className="rwTable"><span className="rwKey">{this.props.tr['Change Summary']}</span><span className="rwVal">
                  <ReactReadMoreLess charLimit={75} 
                    readMoreText="more" 
                    readLessText="...less"
                    content={editChangeInitialData.summary}
                    />
                    </span></ListGroup.Item>
                  <ListGroup.Item bsPrefix=' ' className="rwTable"><span className="rwKey">{this.props.tr['Status']}</span><span className="rwVal"> {editChangeInitialData.status}</span></ListGroup.Item>
                  <ListGroup.Item bsPrefix=' ' className="rwTable"><span className="rwKey">{this.props.tr['Change Type']}</span><span className="rwVal"> {changeT}</span></ListGroup.Item>
                  {(editChangeInitialData.ciClassName != null && editChangeInitialData.ciClassName != "" && editChangeInitialData.ciClassName != "0" && editChangeInitialData.ciClassName != "null") ?
                    <ListGroup.Item bsPrefix=' ' className="rwTable"><span className="rwKey">{this.props.tr['Class']}</span><span className="rwVal"> {editChangeInitialData.ciClassName}</span></ListGroup.Item> : ""}
                  <ListGroup.Item bsPrefix=' ' className="rwTable"><span className="rwKey">{this.props.tr['Schedule Start Date & Time']}</span><span className="rwVal">{editChangeInitialData.expectedStartDate}</span></ListGroup.Item>
                  <ListGroup.Item bsPrefix=' ' className="rwTable" style={{ 'border-bottom': '1px solid #b3d4e6' }}><span className="rwKey">{this.props.tr['Schedule End Date & Time']}</span><span className="rwVal">{editChangeInitialData.expectedEndDate}</span></ListGroup.Item>
                </ListGroup>


                <Accordion id="rightSidePanel6" accordion className="brkFix brkFixOrder margin-t-25">
                  <Accordion.Item eventKey='3'>
                    <Accordion.Header onClick={() => { this.checkRenderApproverData(Change.signaturelist[0][0].offeringid, "14", Change.signaturelist[0][0].itemid, ciId, ciClass) }}>
                      <h4 toggle>{this.props.tr['Approval']}</h4></Accordion.Header>
                    <Accordion.Body>
                      {this.state.showApprovalLoader ? <div><ListLoader /></div> :
                        this.state.displayMessage != "" ? <div>{this.state.displayMessage}</div> :
                          this.props.rightMenuShowLoader ? <div><ListLoader /></div> :
                            this.props.changeTotalApprovalDetails.length > 0 ?
                              <Table responsive className="f-size-13 table table-striped table-bordered table-condensed table-hover margin-b-0">
                                <thead>
                                  <tr>
                                    <th width="9%"></th>
                                    <th width="17%">{this.props.tr['Level']}</th>
                                    <th width="44%">{this.props.tr['Approver']}</th>
                                    <th width="30%">{this.props.tr['Date & Time']}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.renderAppHistoryDataLog(this.props.changeTotalApprovalDetails)}
                                </tbody>
                              </Table> :
                              <div>
                                {this.props.tr["Approval is being triggered. Please try later!"]}
                              </div>
                      }
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header onClick={() => { this.setState({infoProvidedTabOpen: false, approvalsTabOpen: false, attachmentsTabOpen: false, requestorInfoTabOpen: false, itemComponentsTabOpen: false, viewUpdatesTabOpen: false, changePlansTabOpen: !this.state.changePlansTabOpen, showPlansState: false}) }}>
                      <h4 toggle>{this.props.tr['Change Plans']}</h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      {this.renderPlans(this.props.implememtationPlanList)}
                    </Accordion.Body>
                  </Accordion.Item>

                  {this.props.changeTotalApprovalDetails.length > 0 ?
                    <Accordion.Item eventKey="2">
                      <Accordion.Header onClick={() => { this.setState({infoProvidedTabOpen: false, approvalsTabOpen: false, attachmentsTabOpen: false, requestorInfoTabOpen: !this.state.requestorInfoTabOpen, itemComponentsTabOpen: false, viewUpdatesTabOpen: false, changePlansTabOpen: false}) }}>
                        <h4 toggle>{this.props.tr['Requestor Information']}</h4>
                      </Accordion.Header>
                      <Accordion.Body>
                        {this.props.showLoader3 ?
                          <ListLoader />
                          :
                          this.renderAdditionalDetails(this.props.profileDetails)
                        }
                      </Accordion.Body>
                    </Accordion.Item> : null}

                  <Accordion.Item eventKey="1">
                    <Accordion.Header onClick={() => { this.getAttachment(Change.signaturelist[0][0].itemid, "ChangeManagement"); }}><h4 toggle>{this.props.tr['Attachment']}</h4></Accordion.Header>
                    <Accordion.Body>
                      <ListGroup>{this.renderAttachedItems(this.props.implememtationPlanList)}</ListGroup>
                    </Accordion.Body>
                  </Accordion.Item>
                  {this.props.breakFixActivityLogDetails.length == 0 ? null : (
                    <Accordion.Item eventKey="6">
                      <Accordion.Header onClick={() => { this.setState({infoProvidedTabOpen: false, approvalsTabOpen: false, attachmentsTabOpen: false, requestorInfoTabOpen: false, itemComponentsTabOpen: false, viewUpdatesTabOpen: !this.state.viewUpdatesTabOpen, changePlansTabOpen: false}) }}>
                        <h4 toggle>{this.props.tr['View Updates']}</h4>
                      </Accordion.Header>
                      <Accordion.Body>
                        {this.approvalsViewUpdate(this.props.breakFixActivityLogDetails)}
                      </Accordion.Body>
                    </Accordion.Item>
                    )}
                    {(changeT=== "Standard") && <Accordion.Item eventKey='4' className="collapsed">
										    	<Accordion.Header onClick={() => {this.props.getChangeQuestionsCategoryWise(this.props.editChangeInitialData.changeId)}}><h4 toggle>{this.props.tr['Information Provided']}</h4></Accordion.Header>
										    	<Accordion.Body collapsible>
                            {this.props.showLoaderQuestion ?
                              <ListLoader />
                              :
										    		this.renderInformationProvided(this.props.myOrderQuestionDetails)}
										    	</Accordion.Body>
										    </Accordion.Item>}

                </Accordion>

                {Change.approvalstatuslist[0][0].approvalstatus != "null" &&
                  Change.approvalstatuslist[0][0].approvalstatus != "" &&
                  Change.approvalstatuslist[0][0].approvalstatus != "Pending for approval" ? null : (
                    <div className="myAppvls margin-t-10">

                    {disclaimersVisible == 'N' ? null :
                      disclaimers == null || disclaimers.length == 0 ? null :
                      <div>
                        {/* <span className="disclaimerCss">{this.props.tr['Approval Disclaimers']}{this.props.tr['Approval Disclaimers Message']}</span> */}
                        <Form.Check type="checkbox" className="apprvlCheck" inline disabled={this.props.rfcDisclaimer} checked={this.props.rfcDisclaimer} onClick={() => { this.props.rfcDisclaimerChange(true) }} />
                          <div style={{display:"inline-block"}} dangerouslySetInnerHTML={this.createDescriptionMarkup(this.props.disclaimers[0].APPROVER_DISCLAIMER)} />
                      </div>}

                  <div aria-label='Button Toolbar' role="contentinfo" className="margin-t-15">
                  <ButtonToolbar className="myAppChange">
                    <Button onClick={this.onClickApprove} variant="primary" disabled={this.state.rejectButtonDisabled || this.state.approvedButtonDisabled || (disclaimers == null || disclaimers.length == 0 ? false : !this.props.rfcDisclaimer)} className={this.state.activeBtn=== "approve"? "rgSidrkBtn" : "rgSiLigBtn"}>{this.props.tr['Approve']}</Button>
                    <Button onClick={this.onClickReject} variant="primary" disabled={this.state.rejectButtonDisabled || this.state.approvedButtonDisabled} className={this.state.activeBtn=== "reject"? "rgSidrkBtn" : "rgSiLigBtn"}>{this.props.tr['Reject']}</Button>
                    <Button onClick={this.onClickReferBack} variant="primary" disabled={this.state.referBackButtonDisabled} className={this.state.activeBtn=== "referBack"? "rgSidrkBtn" : "rgSiLigBtn"}>{this.props.tr['Refer Back']}</Button>
                  </ButtonToolbar></div>
                  </div>
                )}
                {/* } */}


                {
                  this.props.rejectSecHideShow ? (
                    <div className="margin-t-20">
                      <div className="f-size-15 margin-t-20 margin-b-5 ">
                        <span className="rStar"></span> {this.props.tr['Reason for Rejection']}
                      </div>
                      <Form.Group className='form-group'>
                        <Form.Control as="textarea" value={this.state.rejectTextValue} rows="3" onChange={event => { this.updateRejectTextField(event.target.value); }} maxLength="1000" />
                      </Form.Group>
                      <div className="f-size-15 margin-t-20 margin-b-5 ">
                        {this.props.tr['Are you sure you want to reject?']}
                      </div>
                      <div aria-label='Button Toolbar' role="contentinfo">
                      <ButtonToolbar>
                        <Button bsSize="small" variant="primary" disabled={this.state.rejectButtonDisabled} onClick={() => { this.updateApprovalStatus("reject", Change); }}>{(this.state.loader && this.state.rejectTextValue !== "") ? <ImSpinner6 className="icn-spinner"/> : null}{this.props.tr['Yes']}</Button>
                        <Button bsSize="small" variant="primary" disabled={this.state.rejectButtonDisabled} onClick={() => { this.props.changeRejectSecHideShow(false); }}>{this.props.tr['No']}</Button>
                      </ButtonToolbar></div>
                    </div>
                  ) : null
                }

                {
                  this.props.approveSecHideShow ? (
                    <div className="margin-t-20">
                      {this.props.showCreateAdhocApproverPanel == false ?
                        <div>
                          <div className="f-size-15 margin-t-20 margin-b-5 ">
                            {this.props.tr['Additional Approvers (Optional)']}
                            <span className="float-r"><Button className="pad05" title={this.props.tr['Add']} onClick={() => { this.props.showCreateAdhocApproverPanelToggle(true); }}><i className="fa fa-plus"></i></Button></span>
                            {this.props.adhocApproversList.length > 0 ? <span className="float-r margin-r-5"><Button className="pad05" title={this.props.tr['View']} onClick={() => { this.props.showApproversListToggle(!this.props.showApproversList) }}><i className="fa fa-eye"></i></Button></span> : ''}
                          </div>
                          <div style={{ "clear": "both", "height": "2px" }}></div>
                          {this.props.showApproversList == true && this.props.adhocApproversList && this.props.adhocApproversList.length > 0 ?
                            this.renderAdhocApproversTable()
                            : null}
                          <Form.Group className='form-group'>
                            <div className="f-size-15 margin-t-20 margin-b-5 ">
                              <span className="rStar"></span> {this.props.tr['Approval Comments']}
                            </div>
                            <Form.Control as="textarea" value={this.state.approveTextValue} rows="3" onChange={event => { this.updateApproveTextField(event.target.value); }} maxLength="1000" />
                            {/* {((Change.signaturelist[0][0].levelSequence == "1") && (editChangeInitialData.type == "30" || editChangeInitialData.type == "25") && (Change.signaturelist[0][0].isCabDateExist == "false")) ?
                              <div className="margin-t-20 margin-b-5">
                                <Form.Group validationState={this.state.cabError}>
                                  <ControlLabel bsPrefix=' '><span className="rStar"></span> {this.props.tr['CAB Date']}</ControlLabel>
                                  <div className="dateTimeSdiv dateTimePosTop">
                                    <Datetime className="form-control dateTimePickerField"
                                      dateFormat={dateformat}
                                      value={this.state.cabDateVal == '' ? moment() : this.state.cabDateVal}
                                      onChange={(e) => { this.updateDateField(e) }}
                                    />
                                  </div>
                                  <span className="error">{this.state.errorMsg == '' ? '' : this.props.tr[this.state.errorMsg]}</span>
                                </Form.Group>
                              </div>
                              : ''} */}
                          </Form.Group>
                          <div className="f-size-15 margin-t-20 margin-b-5 ">
                            {this.props.tr['Are you sure you want to approve?']}
                          </div>
                          <div aria-label='Button Toolbar' role="contentinfo">
                          <ButtonToolbar>
                            <Button bsSize="small" variant="primary" disabled={this.state.approvedButtonDisabled} onClick={() => { this.updateApprovalStatus("approve", Change); }}> {(this.state.loader && this.state.rejectTextValue === "") ? <ImSpinner6 className="icn-spinner"/> : null}{this.props.tr['Yes']} </Button>
                            <Button bsSize="small" variant="primary" disabled={this.state.approvedButtonDisabled} onClick={() => { this.props.changeApproveSecHideShow(false); this.props.itemDisclaimerChange(false); }}>{this.props.tr['No']}</Button>
                          </ButtonToolbar></div>
                        </div>
                        :
                        <div>
                          <div className="f-size-15 margin-t-20 margin-b-5 ">
                            {this.props.tr['Additional Approvers (Optional)']}
                            {this.props.adhocApproversList.length > 0 ? <span className="float-r"><Button className="pad05" title={this.props.tr['View']} onClick={() => { this.props.showApproversListToggle(!this.props.showApproversList) }}><i className="fa fa-eye"></i></Button></span> : ''}
                          </div>
                          <div style={{ "clear": "both", "height": "2px" }}></div>
                          {this.props.showApproversList == true && this.props.adhocApproversList && this.props.adhocApproversList.length > 0 ?
                            this.renderAdhocApproversTable()
                            : null}
                          <div className="margin-t-20 aproHiSho">
                          <div className="col1v">
                                <Field name="approver_name" component={RFReactUser} onUserNameChange={this.onUserNameChange} changeAutosuggestState={this.changeAutosuggestState} emptyAutosuggestValue={this.state.emptyAutosuggestValue} tr={this.props.tr} />
                              </div>
                              <div className="col2v">
                                <Button onClick={() => { this.addApproverToList() }} title={this.props.tr['Add']} variant="primary" className="approveBtnBreak">{this.props.tr['Add']}</Button>

                              </div>
                          </div>
                          {this.state.approversArray.length == 0 ? null :
                            (<><Table className="margin-t-10">
                              <thead>
                                <tr>
                                  <th >{this.props.tr['Approver Name']}</th>
                                  <th >{this.props.tr['Action']}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.showCreateApproversList()}
                              </tbody>
                            </Table>
                            <Form.Group className='form-group'>
                            <div className="f-size-15 margin-t-20 margin-b-5 "><span className="rStar"></span> {['Approval Request Comments']} </div>
                            <Form.Control as="textarea" value={this.state.adhocTextValue} rows="3" onChange={event => { this.adhocTextField(event.target.value); }} maxLength="1000" />
                            </Form.Group>
                            </>)
                          }
                          <div aria-label='Button Toolbar' role="contentinfo" className="margin-t-20">
                            <ButtonToolbar>
                              <Button bsSize="small" variant="primary" onClick={() => { this.submitAdhocApprovers(); }} disabled={this.state.adhocButtonDisabled || this.state.approversArray.length == 0}>
                                {this.state.adhocButtonDisabled == true ? <ImSpinner6 className="icn-spinner"/> : null}
                                {this.props.tr['Submit']}
                              </Button>
                              <Button bsSize="small" bsStyle="text" onClick={() => { this.props.showCreateAdhocApproverPanelToggle(false); }}>
                                {this.props.tr['Cancel']}
                              </Button>
                            </ButtonToolbar>
                          </div>
                        </div>
                      }
                    </div>
                  ) : null
                }

                {
                  this.props.referBackSecHideShow ? (
                    <div className="margin-t-20">
                      <div className="f-size-15 margin-t-20 margin-b-5 ">
                        <span className="rStar"></span> {this.props.tr['Reason for Refer Back']}
                      </div>
                      <Form.Group className='form-group'>
                        <div>
                        <Form.Control as="textarea" value={this.state.referBackTextValue} rows="3" onChange={event => { this.updateReferBackTextField(event.target.value); }}  maxLength="500"  />
                        <p className="charLeft" style={{textAlign:"right","fontSize":"11px"}}>({this.props.tr['Characters Remaining']}: {this.state.referBackTextValue==""?GLOBAL.cancelreasonCharMax:this.state.cancelreasonCharLeft}/500)</p>
                        </div>
                      </Form.Group>
                      <div aria-label='Button Toolbar' role="contentinfo">
                      <ButtonToolbar>
                        <Button bsSize="small" variant="primary" disabled={this.state.referBackButtonDisabled} onClick={() => { this.updateApprovalStatus("referBack", Change); }}>{(this.state.loader && this.state.referBackTextValue !== "") ?
                          <ImSpinner6 className="icn-spinner"/> : null}
                          {this.props.tr['Submit']}
                        </Button>
                        <Button bsSize="small" variant="primary" disabled={this.state.referBackButtonDisabled} onClick={() => { this.props.changeReferBackSecHideShow(false); }}>
                          {this.props.tr['Cancel']}
                        </Button>
                      </ButtonToolbar></div>
                    </div>
                  ) : null
                }
              </Tab>
            </Tabs>
            </div>
          </div >
        </div>
    );
  }

  renderAdditionalDetails(profileDetails) {
    //let profileDetails = this.props.profileDetails;
    let locationName = '';
    if (profileDetails.cwlName != '' && profileDetails.cwlName != null && profileDetails.cwlName != 'null') {
      locationName = profileDetails.cwlName
    } else {
      locationName = profileDetails.locationName
    }

    return (
      < div className="table-responsive" >
        <Table bordered hover responsive className="tableView sortImg" style={{ "marginTop": "0px" }}>
          <tbody>
            <tr>
              <td className="bgBtn20 f-size-13" style={{ "width": "50%" }}>{this.props.tr['Permanent Location']}</td>
              <td className="f-size-13">
                {this.props.fulfillmentDetails.requesterLocationName == "undefined" || this.props.fulfillmentDetails.requesterLocationName == undefined ||
                  this.props.fulfillmentDetails.requesterLocationName == null || this.props.fulfillmentDetails.requesterLocationName == "null" ? "" : this.props.fulfillmentDetails.requesterLocationName}
              </td>
            </tr>
            <tr>
              <td className="bgBtn20 f-size-13" style={{ "width": "50%" }}>{this.props.tr['Current Working Location']}</td>
              <td className="f-size-13">{locationName}</td>
            </tr>
            <tr>
              <td className="bgBtn20 f-size-13" style={{ "width": "50%" }}>{this.props.tr['Alternate Phone']}</td>
              <td className="f-size-13">{profileDetails.alternatePhone}</td>
            </tr>
            <tr>
              <td className="bgBtn20 f-size-13" style={{ "width": "50%" }}>{this.props.tr['Alternate Email']}</td>
              <td className="f-size-13">{profileDetails.alternateEmail}</td>
            </tr>
            <tr>
              <td className="bgBtn20 f-size-13" style={{ "width": "50%" }}>{this.props.tr['Manager Name']}</td>
              <td className="f-size-13">{profileDetails.managerName}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    )
  }

  render() {
    const approval = this.props.approvalObj;
    setTimeout(() => {
      if (approval == "" || approval == null || approval == undefined) {
        this.props.changeCompMessage("no approvals found.", "detailsView");
      }
    }, 10000);
    if (this.props.approvalList.approverList !== undefined) {
      if (this.props.approvalList.approverList.length == 0) {
        return (
          <div>
            <font color="green">
              <b>{this.props.tr['There is no matching data available']}</b>
            </font>
          </div>
        );
      }
    }
    if (approval == "" || approval == null || approval == undefined) {
      return this.props.detailsMessage == "" ? (
        <div>
          <h2>{this.props.tr['Loading approval details']}...</h2>
          <Spinner spinnerName="three-bounce" />
        </div>
      ) : (
        <div>
          <h2>{this.props.detailsMessage}</h2>
        </div>
      );
    } 
    else {

      return (
        approval.signaturelist[0][0].item_number.indexOf("RFC") >= 0
          ? this.renderDetailsForChange(approval)
          : this.renderDetailsForItem(approval)

      );
    }
  }
}

export function mapStateToProps(state) {
  let { approvalList,
    changeTotalApprovalDetails,
    changeTotalApprovalHistoryDetails,
    implememtationPlanList, showLoader,
    spcmReducer, myOrderQuestionDetails,
    rightMenuShowLoader, profileDetails,
    showLoader3, breakFixActivityLogDetails,
    isFetchingDetails, fulfillmentDetails,
    editChangeInitialData, showLoader4, adhocApproversList,
    windowSize,
    requesterDelegateeDetails
  } = state;
  return {
    tr: spcmReducer.tr, approvalList, changeTotalApprovalDetails, changeTotalApprovalHistoryDetails, implememtationPlanList, showLoader: showLoader.loading, myOrderQuestionDetails,
    disclaimers: spcmReducer.spcmDisclaimers, rightMenuShowLoader: rightMenuShowLoader.loading, profileDetails, showLoader3: showLoader3.loading, breakFixActivityLogDetails,
    loadingRightSide: isFetchingDetails.isFetching, fulfillmentDetails, editChangeInitialData,
    showLoader4: showLoader4.loading, adhocApproversList, spcmReducer, windowSize, requesterDelegateeDetails
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getMyApprovalList, loadImplPlanList, getQuestionsCategoryWise, loadCartCount, loadBreakFixActivityLogDetails, getAdhocApprovers, loadChangeTotalApprovalDetails, getRequesterDelegateeForApprovalDetails, getDisclaimiers, getChangeQuestionsCategoryWise }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'approvalsRightSideForm',
    // validate,
    enableReinitialize: true,
    // destroyOnUnmount: true
  })(
    ApprovalOfferingDetails
  )
)

