
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col, Nav} from "react-bootstrap";
import { IoSaveOutline, IoClose } from "react-icons/io5";
import {
  LuSearch,
  LuBarChart
} from "react-icons/lu";

const Header = (props) => {
  const { formikref, values, toggle } = props;

  const translator = useSelector((state) => state.spcmReducer.tr);

  //   const closeButton = useRef(null);
  //   const saveObjRef = useRef(null);

  const handleSubmit = () => {
    formikref.current?.submitForm();
  };

  return (
    <Row className="margin-b-15">
      <Col md={6} sm={6} xs={5}>
        <h1 bsClass="" className="sPageHeading1">
          {translator["Asset/Config Board"]}
        </h1>
      </Col>
      <Col md={6} sm={6} xs={7}>
        <div className="status myStatus">
          <Nav bsPrefix=" " as={"ul"} bsClass="">
            <Nav.Item bsPrefix=" " as={"li"}>
              <Link
                to="javascript:void(0)"
                bsPrefix=" "
                className="saveBtnStyle"
                title={translator["Save"]}
                onClick={handleSubmit}
                eventKey={1}
              >
                <span>
                  <IoSaveOutline />
                </span>
              </Link>
            </Nav.Item>
            <Nav.Item bsPrefix=" " as={"li"}>
              <Link
                to="/cmdblist"
                bsPrefix=" "
                title={translator["Close"]}
                eventKey={2}
              >
                <IoClose />
              </Link>
            </Nav.Item>
          </Nav>
        </div>
        {!props.isRightEditPanel &&<div className="status toprigLnk myStatusGap mt-0">
          <Nav bsPrefix=" " as={"ul"} bsClass="" className="icnlnk riggep">
            <Nav.Item bsPrefix=" " as={"li"}>
              <Nav.Link
                bsPrefix=" "
                bsStyle="primary"
                title={translator["Process Workflow"]}
                eventKey={4}
                onClick={() => {props.rightEditPanel(true)}}
              >
                <LuSearch />
                <LuBarChart className="processBarIcn" />
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>}
      </Col>
    </Row>
  );
};

export default Header;
