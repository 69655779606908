
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import {Row,Col, Form, Button, Alert, ButtonToolbar} from 'react-bootstrap';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import axios from 'axios';
import { _textArea, _dropDown, _inputField} from '../../common/formFields';
import { spcmLoader,setLeftEditId,getServiceSequenceList,getSearchServiceSequenceList } from '../../../actions/spcmActions';
import { itemQuantityField } from "../../../components/common/formFields";
import {ImSpinner6} from "react-icons/im";

const validate = (values,props) => { 
	
	let errors={};
    if (!values.offering_id) {
        errors.offering_id = 'Please Select Service Name';
    }
    return errors;
}

class ServiceSequencingForm extends React.Component{
	constructor(props){
	super(props);
		this.state = {
			itemQuantity:"1"
		};
	
		this.submitForm = this.submitForm.bind(this);
	};
	componentWillMount(){

		this.props.getSearchServiceSequenceList();
	}

	submitForm(values){	
		values.featured_sequence=this.state.itemQuantity;
		if (this.props.spcmReducer.editLeftId > 0) {
			this.props.spcmLoader('attribute', 'posting');
			let obj={};
			obj.featured_sequence=values.featured_sequence;
			return axios.patch("/api/featuredServices/" + values.id,obj ).then((response) => {
				this.props.getServiceSequenceList("","");
				this.props.componentFormFun(false);
				this.props.spcmLoader('attribute', 'success');
			})
				.catch((error) => {

					this.props.spcmLoader('attribute', 'success');
					throw new SubmissionError({ _error: error.response.data[0].message });
				});
		}
		else {
			this.props.spcmLoader('attribute', 'posting');
			return axios.post("/api/featuredServices", values).then((response) => {
				this.props.getServiceSequenceList("","");
				this.props.componentFormFun(false);
				this.props.spcmLoader('attribute', 'success');
			})
				.catch((error) => {
					this.props.spcmLoader('attribute', 'success');
					console.log("ERROR", error.response.data[0].message);
					throw new SubmissionError({ _error: error.response.data[0].message });

				});
		}	
	}	


	render(){
		const tr=this.props.tr;
		const {error,handleSubmit} = this.props;
		var serviceSequencingListOptions=this.props.spcmReducer.serviceSequencingListOnAdd;
		console.log("ITEMQUANTITY",this.state.itemQuantity);
		return (
			<div className="borderForm margin-b-10">
				<form onSubmit={handleSubmit(this.submitForm)}>
					{error == undefined || error == null ? null : <Alert variant="danger" className="errorMessageSec"><p>{error}</p></Alert>}
					<Row>
						<Col md={12} className="dCheckbox customCheckBoxContainer">
							<Form.Group>
								<Form.Label bsClass="">{tr['Service Name']}</Form.Label>
								{
								!this.props.spcmReducer.editLeftId ?
								<Field 
								component={_dropDown} 
								className="form-control" 
								name='offering_id' 
								options={serviceSequencingListOptions } />
								:
								 <Field name="offering_id" component={_inputField} readOnly className="form-control" defaultValue={this.props.spcmReducer.editLeftService.offering_name}/>}
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={12} className="dCheckbox customCheckBoxContainer">
							<Form.Group>
							<Form.Label bsClass="">{tr['Sequence No.']}</Form.Label>
								<Field component={itemQuantityField} max={100000} min={1}
									name="featured_sequence"
									className='form-control wd100' value={this.state.itemQuantity} onChange={(e) => {this.setState({ itemQuantity: e.target.value });
									}} />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={12}>

							<ButtonToolbar className="black">
								<Button className='rgSidrkBtn' title="Save" disabled={this.props.spcmReducer.spcmPostingAttribute} type="submit" size="sm">{this.props.spcmReducer.spcmPostingAttribute ? <ImSpinner6 className="icn-spinner"/> : null} {tr['Save']}</Button>
								<Button className='rgSiLigBtn' title="Close" onClick={() => { this.props.componentFormFun(false);this.props.getServiceSequenceList("",""); }} size="sm">{tr['Close']}</Button>
							</ButtonToolbar>
						</Col>


					</Row>
				</form>
			</div>
		);
	}
}
const mapStateToProps = ({spcmReducer}) => {
	return {
		spcmReducer,
		initialValues:spcmReducer.editLeftId>0?spcmReducer.editLeftService:''
	}
};
ServiceSequencingForm=reduxForm({
	form:'serviceSequencingForm',
	validate,
	enableReinitialize : true
})(ServiceSequencingForm)

export default connect(mapStateToProps,{spcmLoader,getServiceSequenceList,getSearchServiceSequenceList,setLeftEditId})(ServiceSequencingForm);
