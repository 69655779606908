
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import {Nav,NavItem} from 'react-bootstrap';

class PinnedItems extends React.Component {
	render() {
		return (
        <div className="pindCateBox hidden-xs hidden-sm">
            <Nav bsPrefix=' ' className="">
                <NavItem className="pdLi" href={void(0)} eventKey={1}>4444</NavItem>
                <NavItem className="pdLi" href={void(0)} eventKey={2}>Applications</NavItem>
                <NavItem className="pdLi" href={void(0)} eventKey={3}>Backup</NavItem>
                <NavItem className="pdLi" href={void(0)} eventKey={4}>Devices</NavItem>
                <NavItem className="pdLi" href={void(0)} eventKey={5}>Database</NavItem>
                <NavItem className="pdLi" href={void(0)} eventKey={6}>Media</NavItem>
                <NavItem className="pdLi" href={void(0)} eventKey={7}>Product Development</NavItem>
                <NavItem className="pdLi" href={void(0)} eventKey={8}>RNS_Services</NavItem>
            </Nav>
        </div>
		);
	}
}
export default PinnedItems;


