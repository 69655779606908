
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { GLOBAL } from "../../Globals";
import AutoSuggest from "react-autosuggest";
import EmailValidator from "email-validator";
import Swal from "sweetalert2";
import { Typeahead } from 'react-bootstrap-typeahead';
import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";

let consumerDataDetails = [];
let consumerData = [];
let myObj = [];
let specifiedEmail = "";
let selectOptions = [];

const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

const getSuggestions = (value, consumerDataDetails) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  if (inputLength > 2) {
    selectOptions = consumerDataDetails;
  }
  return inputLength < 1
    ? []
    : selectOptions.filter(
        (offering) =>
          offering.email.toLowerCase().slice(0, inputLength) === inputValue
      );
};

const getSuggestionValue = (suggestion) => suggestion.email;

function renderSuggestion(suggestion) {
  specifiedEmail = suggestion.email;
  return <span>{suggestion.email}</span>;
}

class SendToTypeHead extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onSuggestionsFetchRequested =
      this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested =
      this.onSuggestionsClearRequested.bind(this);
    this.state = {
      value: "",
      suggestions: [],
    };
  }
  onChange(event, { newValue }) {
    this.setState({
      value: newValue,
    });
    if (this.state.value == "") {
      specifiedEmail = "";
    }
  }
  onSuggestionsFetchRequested({ value }) {
    this.setState({
      suggestions: getSuggestions(value, consumerDataDetails),
    });
  }
  onSuggestionsClearRequested() {
    this.setState({
      suggestions: [],
    });
  }

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: "search for consumer email",
      className: "form-control",
      value,
      onChange: this.onChange,
    };
    return (
      <AutoSuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
      />
    );
  }
}

class FulfillmentNotifyTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toValue: "",
      mailSubject: "",
      mailBody: "",
      sentToDisableValue: false,
      showConsumerTypeHead: false,
      submitButtonDisabled: false,
      specifiedEmail: "",
      onSuccessMessage: "",
      sentToValue: "",
      maxSelectedgroups: 5,
      allGroups:[],
			selectedOptions:[]
    };
    this.onSendToSelection = this.onSendToSelection.bind(this);
    this.setMailSubject = this.setMailSubject.bind(this);
    this.setMailBody = this.setMailBody.bind(this);
    this.postNotification = this.postNotification.bind(this);
    this.setSpecifiedEmail = this.setSpecifiedEmail.bind(this);
    this.onGroupSelected = this.onGroupSelected.bind(this);
  }

  componentWillMount(){
    if(!this.props.disableFieldsForGuestRole){
		axios.get(GLOBAL.ciSupportAllGroupUrl+'/id/'+this.props.fulfillmentdetails.companyId).then((responseGroupdata)=>{
            let allGroup=[];
			if (responseGroupdata.data !== "") {
				responseGroupdata.data.map((item) => {
				  allGroup.push({'value': ''+item.groupId+'', 'label':item.groupName, companyName: (item['companyName'] || '') });
				});
			} 
			this.setState({allGroups: allGroup});
		});
  }
	}

  setSpecifiedEmail(value) {
    this.setState({ specifiedEmail: value });
  }

  onSendToSelection(sentTo) {
    let impactedUserid = this.props.fulfillmentdetails.requesterId;

    let ref = this;
    ref.setState({ sentToDisableValue: false });
    ref.setState({ sentToValue: sentTo });
    ref.setState({
			specifiedEmail: '',
			toValue: '',
			selectedOptions: []
		});
    let str = {};
    str.userid = impactedUserid;
    str = JSON.stringify(str);
    if (sentTo == "User") {
      ref.setState({ showConsumerTypeHead: false });
      ref.setState({ specifiedEmail: "" });
      ref.setState({ toValue: "" });
      ref.setState({ sentToDisableValue: true });
      let response = api.get(GLOBAL.profileDetailsUrl, {
        headers: { query: str },
      }); //hardcoding impacedUserId as its coming as string in response on edit page
      response.then((responseData) => {
        if (responseData.status == 200) {
          ref.setState({ toValue: responseData.data.email });
          //	ref.setState({ sentToDisableValue: true });
        } else {
          Swal.fire("error in fetching user email");
          ref.setState({ sentToDisableValue: false });
        }
      });
    }
    if (sentTo == "Group") {
      let spGroupId = this.props.fulfillmentdetails.asgGroupid;
      ref.setState({ specifiedEmail: "" });
      ref.setState({ toValue: "" });
      ref.setState({ sentToDisableValue: true });
      ref.setState({ showConsumerTypeHead: false });
      api.get("/api/groups?groupId=" + spGroupId).then((response) => {
        if (response.status == 200) {
          ref.setState({ toValue: response.data[0].GROUP_MEMBER_EMAILS });
        } else {
          if (response.status == 204) {
            Swal.fire("no group found chosse user instead.");
          } else {
            Swal.fire("error in loading group email.");
          }
        }
      });
      ref.setState({ specifiedEmail: "" });
    }
    if (sentTo == "Specify") {
      ref.setState({ showConsumerTypeHead: true });
    }
    if (sentTo == "select") {
      this.setState({ toValue: "" });
      this.setState({ showConsumerTypeHead: false });
    }
    if(sentTo==='specifyGroup'){
      this.setState({selectedOptions: []});
      this.setState({ toValue: '' });
      this.setState({ showConsumerTypeHead: false });
    }
  }

  setMailSubject(subject) {
    this.setState({ mailSubject: subject });
  }

  setMailBody(body) {
    this.setState({ mailBody: body });
  }

  postNotification(event) {
    let ref = this;
    event.preventDefault();
    let brkItemId = this.props.fulfillmentdetails.itemId;
    let sendTo;
    if (this.state.specifiedEmail.trim() == "") {
      sendTo = this.state.toValue;
    } else {
      sendTo = this.state.specifiedEmail;
    }
    let NotifyObj = {
      itemId: "",
      subject: "",
      mailBody: "",
      addEmail: "",
      orderNumber: this.props.fulfillmentdetails.orderNumber,
      itemNumber: this.props.fulfillmentdetails.itemNumber,
      requesterName: this.props.fulfillmentdetails.requesterName,
      senderName: this.props.full_name,
      "type":"email"+"~"+this.state.sentToValue
    };
    // if (
    //   this.state.sentToValue && this.state.sentToValue != 'specifyGroup' &&
    //   this.state.toValue.trim() == "" &&
    //   this.state.specifiedEmail.trim() == ""
    // ) {
    //   Swal.fire("recipient field cannot be empty");
    //   return false;
    // } 
    if ( !this.state.sentToValue || (this.state.sentToValue==='specifyGroup' && this.state.selectedOptions.length===0) || ( this.state.sentToValue!=='specifyGroup' && (!this.state.toValue || this.state.toValue.trim() == '') && (!this.state.specifiedEmail || this.state.specifiedEmail.trim() == '') ) ) {
      Swal.fire("Recipient field cannot be empty");
      return false;
    }
    else {

      // if(this.state.sentToValue==='specifyGroup' && this.state.selectedOptions.length===0){
      //   Swal.fire("Recipient field cannot be empty");
      //   return false;
      // }

      if (this.state.mailBody.trim() == "") {
        // Swal.fire("Mail body cannot be empty");
        Swal.fire("Please specify the message.");
        return false;
      } else {
        if (this.state.mailSubject.trim() == "") {
          // Swal.fire("Subject cannot be empty");
          Swal.fire("Please specify the subject.");
          return false;
        } else if (
          EmailValidator.validate(sendTo) == false &&
          this.state.sentToValue == "Specify"
        ) {
          Swal.fire("Please enter a valid email address!");
          return false;
        } else {
          this.setState({ submitButtonDisabled: true });
          NotifyObj.itemId = brkItemId;
          NotifyObj.subject = this.state.mailSubject
            .replace(/</g, "")
            .replace(/>/g, "");
          NotifyObj.mailBody = this.state.mailBody
            .replace(/</g, "")
            .replace(/>/g, "");
          // NotifyObj.addEmail = sendTo;

          if(this.state.sentToValue==='specifyGroup'){
            let groupID='',groupName='';
            this.state.selectedOptions.forEach((selectedGroup,itr)=>{
              if(itr===this.state.selectedOptions.length-1){
              groupID+=selectedGroup.value;
              groupName+=selectedGroup.label;
              }else{
                groupID+=selectedGroup.value+',';
                groupName+=selectedGroup.label+',';
              }	 
            });
            NotifyObj.addEmail = groupID;
          }else{
            NotifyObj.addEmail = sendTo;
          }

          axios
            .post(GLOBAL.fulfillmentemailNotification, NotifyObj)
            .then((response) => {
              if (response.status == 200) {
                this.setState({ submitButtonDisabled: false });
                this.setState({ mailBody: "" });
                this.setState({ mailSubject: "" });
                this.setState({ toValue: "", selectedOptions:[], sentToValue: "" });
                ref.setState({
                  onSuccessMessage: "Email sent Successfully.",
                  toValue: "",
                  mailSubject: "",
                  mailBody: "",
                  specifiedEmail: "",
                });
                ref.props.setShowNotifyForm(false);
              } else {
                Swal.fire("error in sending email");
                this.setState({ submitButtonDisabled: false });
              }
            });
          this.setState({ submitButtonDisabled: false });
        }
      }
    }
    //}
  }

  onGroupSelected(groupsSelected){
		if(groupsSelected.length<=5){
			this.setState({selectedOptions: groupsSelected});
		}else{
			this.setState({selectedOptions: this.state.selectedOptions});
		}
	}

  render() {
    return this.props.showNotifyForm === true ? (
      <div className="hambTab">
        <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
          <ul>
            <li>
            <button
                type='button'
                title="Minimize the right panel"
                bsClass=""
                bsStyle=""
                className="closerightPanelBtn"
                onClick={() => {
                this.props.rightEditPanel(false);
                }}
            >
                <IoClose/>
            </button>
            </li>
          </ul>
        </div>
        <div className="rPageHeading">
          <div className="offNam margin-t-5 margin-b-5">
            {this.props.translator["Notify"]}
          </div>
        </div>
        <div className="rBoxGap">
          <Form.Group className="form-group topMargin">
            <Form.Label>
              <span className="rStar"></span>
              {this.props.translator["Send"]}{" "}
            </Form.Label>
            <Form.Select componentClass="select" disabled={this.props.disableFieldsForGuestRole}>
              {/* <option value="select">Email</option> */}
              <option value="email">Email</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="form-group topMargin">
            <Form.Label>
              <span className="rStar"></span>
              {this.props.translator["Send To"]}{" "}
            </Form.Label>
            <Form.Select
              disabled={this.props.disableFieldsForGuestRole}
              componentClass="select"
              onChange={(event) => {
                this.onSendToSelection(event.target.value);
              }}
            >
              <option value="select">select</option>
              <option value="User">Requestor</option>
              <option value="Group">Assigned Group</option>
              <option value="Specify">Specify User</option>
              <option value="specifyGroup">Specify Group</option>
            </Form.Select>
          </Form.Group>
          {(this.state.sentToValue == 'specifyGroup') ? 
            <Form.Group className="typeaheadInput form-group notifydvTypehd">
              <Form.Label>{this.props.translator['To']+" ("+this.props.translator['Maximum']+" "+this.state.maxSelectedgroups+" "+this.props.translator['can be selected']+")"}</Form.Label>
              <Typeahead 
                multiple
                options={this.state.allGroups}
                selected={this.state.selectedOptions}
                placeholder={this.props.translator["Please choose"]+"..."}
                // onBlur={this.setErrorColor}
                // onInputChange={this.onGroupInputChange}
                onChange={this.onGroupSelected}
                disabled={this.props.disableFieldsForGuestRole}
                renderMenuItemChildren={(option, props) => {
                  let label;
                  let title;
                  label =
                    option.label.length > 30
                      ? option.label.slice(0, 30) + "..."
                      : option.label;
                    title = option.label.length > 30 ? option.label : "";
                  if(option?.companyName){
                    return (
                    <div>
                      <span className="typHdmainHead" title={title} style={{'display': 'block'}}>{label}</span>
                      <span className="typHdmainSubHead" style={{'display': 'block', 'margin-top': '6px', 'color': '#777'}}>{option?.companyName || ''}</span>
                    </div>
                    )
                  }
                  return (
                    <div>
                    <span className="typHdmainHead" title={title}>{label}</span>
                    </div>
                  );
                  }}
              />   
            </Form.Group>
          : 
            <div>
              {this.state.showConsumerTypeHead ? (
                <Form.Group className="form-group topMargin">
                  <Form.Label>{this.props.translator["To"]}</Form.Label>
                  {/* <SendToTypeHead /> Issue #96 require only input type field*/}
                  <Form.Control
                    disabled={this.props.disableFieldsForGuestRole}
                    type="text"
                    value={this.state.specifiedMail}
                    onChange={(event) => {
                      this.setSpecifiedEmail(event.target.value);
                    }}
                    onKeyPress={(e) => {e.key === "Enter" && e.preventDefault();}}
                  />
                </Form.Group>
              ) : (
                <Form.Group className="form-group topMargin">
                  <Form.Label>{this.props.translator["To"]}</Form.Label>
                  <Form.Control
                    disabled={this.props.disableFieldsForGuestRole}
                    type="text"
                    value={this.state.specifiedEmail}
                    placeholder={this.state.toValue}
                    readOnly={this.state.sentToDisableValue}
                    onKeyPress={(e) => {e.key === "Enter" && e.preventDefault();}}
                  />
                </Form.Group>
              )}
            </div>
          }
          
          <Form.Group className="form-group topMargin">
            <Form.Label>{this.props.translator["Subject"]}</Form.Label>
            <Form.Control
              disabled={this.props.disableFieldsForGuestRole}
              type="text"
              maxLength="250"
              value={this.state.mailSubject}
              onChange={(event) => {
                this.setMailSubject(event.target.value);
              }}
              onKeyPress={(e) => {e.key === "Enter" && e.preventDefault();}}
            />
          </Form.Group>
          <Form.Group className="form-group topMargin">
            <Form.Label>{this.props.translator["Body"]}</Form.Label>
            <Form.Control
              disabled={this.props.disableFieldsForGuestRole}
              as="textarea"
              maxLength="1000"
              rows="5"
              value={this.state.mailBody}
              onChange={(event) => {
                this.setMailBody(event.target.value);
              }}
            />
          </Form.Group>

          <ButtonToolbar className="black topMargin">
            <Button
              className="rgSidrkBtn smallBtn"
              bsSize="small"
              bsStyle="primary"
              type={this.props.translator["submit"]}
              disabled={this.state.submitButtonDisabled|| this.props.disableFieldsForGuestRole}
              onClick={(event) => {
                this.postNotification(event);
              }}
            >
              {this.state.submitButtonDisabled ? (
                <ImSpinner6 className="icn-spinner"/>
              ) : null}
              {this.props.translator["Send"]}
            </Button>
          </ButtonToolbar>
          {/* <Button type={this.props.translator['submit']} onClick={(event) => { this.postNotification(event); }} disabled={this.state.submitButtonDisabled}>{this.props.translator['Send']}</Button> */}
        </div>
      </div>
    ) : (
      <div className="hambTab">
        <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
          <ul>
            <li>
            <button
                type='button'
                title="Minimize the right panel"
                bsClass=""
                bsStyle=""
                className="closerightPanelBtn"
                onClick={() => {
                this.props.rightEditPanel(false);
                }}
            >
                <IoClose/>
            </button>
            </li>
          </ul>
        </div>
        <div className="rPageHeading">
          <div className="offNam margin-t-5 margin-b-5">
            {this.props.translator["Notify"]}
          </div>
        </div>
        <div className="rBoxGap">{this.state.onSuccessMessage}</div>
      </div>
    );
  }
}

export default FulfillmentNotifyTab;
