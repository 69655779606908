
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux';
import PindCategories from '../common/header/pind-categories';
import MyRequestsHeader from './MyRequestsHeader.js';
import MyRequestListView from './MyRequestsListView';
import MyRequestsShortDesc from './MyRequestsShortDesc';
import MyRequestsShortDescInc from './MyRequestsShortDescInc.js';
import { loadOrdersList, emptyOrdersReducer } from "../../actions/cart/myOrders/myOrdersAction";
import { getTranslation } from '../../actions/spcmActions';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { navigationHooks } from '../../helpers/NavigationHook';
import Cookies from 'universal-cookie';
import Breadcrumbs from '../common/header/breadcrumbs';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { Container } from 'react-bootstrap';
import "_Css/common/_table.scss";
import "_Css/form/_form.scss";

const cookies = new Cookies();
let homepagelocation = cookies.get('gph');
if (homepagelocation){
	homepagelocation = homepagelocation.replace('s:', '');
} 
if (homepagelocation){
	homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
}
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation !== "/home")
homepagelocation = "/" + homepagelocation;
let email_address =  cookies.get("gph");
email_address = email_address.split("~");
email_address = email_address[29];
class MyRequestsIndex extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			subMenu: '4',
			mobileLeftMenuFilter: false,
			showHideAlfy: false,
			showHideRightNav: false,
			quickViewOrderId: '',
			filterValue: 'self',
			moduleName: '',
			itemIdValue: '',
			orderStatus: '',
			listFilter: 'onBehalf',
			searchclick: true,
			check_status: '',
			rowIndex: 0,
			ciServiceName: '',
			serviceBased: '',
			isRightPanel: false,
			searchKey: [],
			searchValue: [],
			pattern: "",
			sortOrder: "desc",
			sortOnColumn: "createdOn",
			status: '20,10,15,65,70,-20,100,60',
			isClearAllFilter: false,
			displayNumberSearchInput: "",
			ciServiceNameSearchInput: "",
			requesterNameSearchInput: "",
			submittedStartDate: {},
			submittedEndDate: {},
			modifiedStartDate: {},
			modifiedEndDate: {},
			selectedValue: [],
			// isSelf: true,
			isSelf: 'self',
			submittedDateRef: {},
			modifiedDateRef: {},
			page: 0,
			limit: 10,
			isStatusOpen: false,
			isRefreshClick: false,
			filterTabActive:1,
			panelResize: "",
		};
		this.showHideAlfy = this.showHideAlfy.bind(this);
		this.showHideRightNav = this.showHideRightNav.bind(this);
		this.getOrderDetails = this.getOrderDetails.bind(this);
		this.getOrderType = this.getOrderType.bind(this);
		this.getItemId = this.getItemId.bind(this);
		this.getOrderStatus = this.getOrderStatus.bind(this);
		this.buildSearchParamsForOrderFilter = this.buildSearchParamsForOrderFilter.bind(this);
		this.ordersListFilter = this.ordersListFilter.bind(this);
		this.ordersListFilter2 = this.ordersListFilter2.bind(this);
		this.showActiveRow = this.showActiveRow.bind(this);
		this.loadHome = this.loadHome.bind(this);
		this.setCiServiceName = this.setCiServiceName.bind(this);
		this.showRightPanelFun = this.showRightPanelFun.bind(this);
		this.setSearchKeyValue = this.setSearchKeyValue.bind(this);
		this.setSortOrder = this.setSortOrder.bind(this);
		this.updateParameter = this.updateParameter.bind(this);
		this.isClearAllFilterFunc = this.isClearAllFilterFunc.bind(this);
		this.setdisplayNumberInputValues = this.setdisplayNumberInputValues.bind(this);
		this.setCiServiceNameInputValues = this.setCiServiceNameInputValues.bind(this);
		this.setRequestedByInputValues = this.setRequestedByInputValues.bind(this);
		this.setSubmittedStartAndEndDate = this.setSubmittedStartAndEndDate.bind(this);
		this.setModifiedStartAndEndDate = this.setModifiedStartAndEndDate.bind(this);
		this.setSelectedValue = this.setSelectedValue.bind(this);
		this.setSubmittedRef = this.setSubmittedRef.bind(this);
		this.setModifiedRef = this.setModifiedRef.bind(this);
		this.isSelfFun = this.isSelfFun.bind(this);
		this.updatePagination = this.updatePagination.bind(this);
		this.multiSelectOpenFun = this.multiSelectOpenFun.bind(this);
		this.refreshClickFun = this.refreshClickFun.bind(this);
	}

	multiSelectOpenFun(keyName, val) {
		switch (keyName) {
		  case "myReqStatus":
			this.setState({
			  isStatusOpen: val,
			});
			break;
		}
	  }

	refreshClickFun(value) {
		this.setState({ isRefreshClick: value });
	}

	componentWillMount() {
		this.props.emptyOrdersReducer();
	}

	isSelfFun(val) {
		let filterVal;
		// { this.state.isSelf == true ? filterVal = "self" : filterVal = "onBehalf"; }
		// { this.state.isSelf == 'watcher' ? filterVal = "watcher" : this.state.isSelf == 'self' ? filterVal = "onBehalf" : filterVal = "self"}
		{ this.state.isSelf == 'watcher' ? filterVal = "watcher" : this.state.isSelf == 'self' ? filterVal = "self" : filterVal = "onBehalf"}
		// {val == 'watcher' ? filterVal = "watcher" :val == 'self'?filterVal = "onBehalf" : filterVal = "self";}
		{val == 'watcher' ? filterVal = "watcher" :val == 'self'?filterVal = "self" : filterVal = "onBehalf";}

		if (val == "self" ) {
			this.setState({
				filterTabActive: 1
			})
		}
		else if (val == "onBehalf" ) {
			this.setState({
				filterTabActive: 2
			})
		} else if (val == 'watcher') {
			this.setState({
				filterTabActive: 3
			})
		}

		this.setState({ isRightPanel: false });
		this.setState({ displayNumberSearchInput: "" });
		this.setState({ ciServiceNameSearchInput: "" });
		this.setState({ requesterNameSearchInput: "" });
		this.setState({ searchKey: [] });
		this.setState({ searchValue: [] });
		this.setState({ pattern: "" });
		this.setState({ submittedStartDate: {} });
		this.setState({ submittedEndDate: {} });
		this.setState({ modifiedStartDate: {} });
		this.setState({ modifiedEndDate: {} });
		this.setState({ status: '20,10,15,65,70,-20,100,60' });
		this.setState({ selectedValue: [
			{ label: this.props.tr["Draft"], value: "-20" },
			{ label: this.props.tr["On Hold"], value: "60" },
			{ label: this.props.tr["Pending Approval"], value: "65" },
			{ label: this.props.tr["Referred Back"], value: "100" },
			{ label: this.props.tr["Submitted"], value: "10" },
			{ label: this.props.tr["Under Fulfillment"], value: "70" },
			{ label: this.props.tr["In Progress"], value: "15" },
			{ label: this.props.tr["Pending"], value: "20" }
		] });
		this.setState({ isClearAllFilter: false });
		this.props.loadOrdersList("requesterId", "type", "", "createdOn", "desc", 0, 10, filterVal, '20,10,15,65,70,-20,100,60',email_address);
		// this.setState({ isSelf: !this.state.isSelf });
		this.setState({ isSelf: val });
		this.setState({ filterValue: filterVal });
		this.setState({ limit: 10 });
		this.setState({ page: 0 });
	}
	setSubmittedRef(ref) {
		this.inputRef = ref;
		this.setState({ submittedDateRef: this.inputRef });
	}
	setModifiedRef(ref) {
		this.inputRef = ref;
		this.setState({ modifiedDateRef: this.inputRef });
	}

	setSelectedValue(value) {
		this.setState({ selectedValue: value });
	}

	isClearAllFilterFunc(value) {
		this.setState({ isClearAllFilter: value });
	}

	setdisplayNumberInputValues(displayNumber) {
		this.setState({
			displayNumberSearchInput: displayNumber,
		});
	}

	setCiServiceNameInputValues(ciServiceName) {
		this.setState({
			ciServiceNameSearchInput: ciServiceName,
		});
	}

	setRequestedByInputValues(requestedBy) {
		this.setState({
			requesterNameSearchInput: requestedBy,
		});
	}
	setSubmittedStartAndEndDate(startDate, endDate) {
		this.setState({ submittedStartDate: startDate, submittedEndDate: endDate });
	}
	setModifiedStartAndEndDate(startDate, endDate) {
		this.setState({ modifiedStartDate: startDate, modifiedEndDate: endDate });
	}
	setSearchKeyValue(key, value, pattern) {
		this.setState({ searchKey: key });
		this.setState({ searchValue: value });
		this.setState({ pattern: pattern });
	}
	setSortOrder(sortBy, sortOn) {
		this.setState({ sortOrder: sortBy });
		this.setState({ sortOnColumn: sortOn });
	}
	showRightPanelFun(value) {
		this.setState({ isRightPanel: value });
	}

	mobileLeftMenuFilter() {
		this.setState({ mobileLeftMenuFilter: !this.state.mobileLeftMenuFilter });
	}

	showHideAlfy(value) {
		this.setState({ showHideAlfy: value });
	}
	showHideRightNav(value) {
		this.setState({ showHideRightNav: !this.state.showHideRightNav });
	}

	getOrderStatus(orderStatus) {
		this.setState({
			orderStatus: orderStatus
		});
	}
	getOrderDetails(orderID) {
		this.setState({
			quickViewOrderId: orderID
		});
	}

	getItemId(itemId) {
		this.setState({
			itemIdValue: itemId
		});
	}


	getOrderType(moduleName) {
		this.setState({
			moduleValue: moduleName
		});

	}

	ordersListFilter(listFilter) {
		this.setState({ listFilter: listFilter, searchclick: false });
	}

	ordersListFilter2() {
		this.setState({ searchclick: true });
	}
	setCiServiceName(name, value) {
		this.setState({ ciServiceName: name, serviceBased: value });
	}
	updateParameter(statusValues) {
		this.setState({ status: statusValues });
	}
	buildSearchParamsForOrderFilter(params) {
		let searchParams ;
		searchParams = Object.assign({}, params);
		let selectedStatus ;
		selectedStatus = this.props.myOrderViewFilters.selectedStatus;
        let statusMap;
		statusMap = new Map();
	
    	statusMap.set('Open', ['20', '10', '15', '65', '70', '-20', '100', '60']);
		statusMap.set('Closed', ['25', '75', '80', '30', '95']);
		statusMap.set('Cancelled', ['85', '35']);

		if (!_.isEmpty(selectedStatus)) {

			let selectedMappedStatus;
			selectedMappedStatus = selectedStatus.map((item) => { return statusMap.get(item); }).join();
			if (!_.isEmpty(searchParams.searchByList)) {
				searchParams.searchByList = searchParams.searchByList + ',' + 'itemStatus';
				searchParams.multipleValueList = searchParams.multipleValueList + '|' + "" + '|' + selectedMappedStatus;
			}
			else {
				// if (this.state.listFilter === 'onBehalf') {
				if (this.state.filterValue === 'self') {
					searchParams.searchByList = 'requesterId';
					searchParams.multipleValueList = "";
				}
				// else if (this.state.listFilter == 'self') {
				else if (this.state.filterValue == 'onBehalf') {
					searchParams.searchByList = 'requesterId,createdBy';
					searchParams.multipleValueList = "|";
					searchParams.patternList = "not in";

				}
			}
		}
		let selectedOrderType = this.props.myOrderViewFilters.selectedOrderType;
		let statusOrderMap = new Map();
		statusOrderMap.set('Service Orders', ['Item']);
		statusOrderMap.set('Correction Orders', ['Breakfix']);


		if (!_.isEmpty(selectedOrderType)) {

			let selectedMappedStatus;
			selectedMappedStatus = selectedOrderType.map((item) => { return statusOrderMap.get(item); }).join();
			if (!_.isEmpty(searchParams.searchByList)) {
				searchParams.searchByList = searchParams.searchByList + ',' + 'type';
				searchParams.multipleValueList = searchParams.multipleValueList + '|' + selectedMappedStatus;
			}
			else {
				if (this.state.listFilter == 'onBehalf') {
					searchParams.searchByList = 'requesterId' + "," + 'type';
					searchParams.multipleValueList = "" + "|" + selectedMappedStatus;
				}
				else if (this.state.listFilter == 'self') {
					searchParams.searchByList = 'requesterId,createdBy,' + 'type';
					searchParams.multipleValueList = "|" + "|" + selectedMappedStatus;

				}
			}
		}
		return searchParams;
	}

	showActiveRow(i, statusVal) {
		if (" i & status", i, statusVal);
		this.setState({
			rowIndex: i,
			check_status: statusVal
		});
	}

	loadHome() {
		navigationHooks.navigate(homepagelocation);
	}

	updatePagination(page, limit) {
		this.setState({ page: page });
		this.setState({ limit: limit });
	}

	render() {
		let metaData = this.props.orderListMeta;
		let size = metaData && metaData.size ? metaData.size : 0;
		let currentPage = metaData && metaData.currentPage ? metaData.currentPage : 0;
		let startindex = (size * currentPage) + 1;
		let endindex = size * (currentPage + 1);
		let recordCount = metaData && metaData.recordCount ? metaData.recordCount : 0;
		console.log('Date Filter Callback: 2', this.state.submittedStartDate, this.state.submittedEndDate);
		return (
			<main>
				<div className="minHeight">
					<PindCategories />
					<Container fluid className="margin-b-15">
						<Row>
							<Col md={8} xs={9}>          
							<Breadcrumbs activePageName="My View" />
							</Col>
							<Col md={4} xs={3}>
								{recordCount != 0 ? <div className="tabDatacount">{startindex}-{endindex > recordCount ? endindex = recordCount : endindex} of {recordCount}</div> : null}
							</Col>
						</Row>       
					</Container>
					<div className="container-fluid" bsClass="">
						{/* Home Page blue bar Section Start */}
						<MyRequestsHeader showRightPanelFun={this.showRightPanelFun}
							isRightPanel={this.state.isRightPanel}
							showActiveRow={this.showActiveRow}
							ordersListFilter={this.ordersListFilter}
							listFilter={this.state.listFilter}
							searchclick={this.state.searchclick}
							tr={this.props.tr}
							buildSearchParamsForOrderFilter={this.buildSearchParamsForOrderFilter}
							rowIndex={this.state.rowIndex}
							searchKey={this.state.searchKey}
							searchValue={this.state.searchValue}
							pattern={this.state.pattern}
							setSearchKeyValue={this.setSearchKeyValue}
							sortOrder={this.state.sortOrder}
							setSortOrder={this.setSortOrder}
							sortOnColumn={this.state.sortOnColumn}
							status={this.state.status}
							isClearAllFilter={this.state.isClearAllFilter}
							isClearAllFilterFunc={this.isClearAllFilterFunc}
							displayNumberSearchInput={this.state.displayNumberSearchInput}
							ciServiceNameSearchInput={this.state.ciServiceNameSearchInput}
							requesterNameSearchInput={this.state.requesterNameSearchInput}
							submittedStartDate={this.state.submittedStartDate}
							submittedEndDate={this.state.submittedEndDate}
							modifiedStartDate={this.state.modifiedStartDate}
							modifiedEndDate={this.state.modifiedEndDate}
							updateParameter={this.updateParameter}
							setSubmittedStartAndEndDate={this.setSubmittedStartAndEndDate}
							setModifiedStartAndEndDate={this.setModifiedStartAndEndDate}
							setdisplayNumberInputValues={this.setdisplayNumberInputValues}
							setCiServiceNameInputValues={this.setCiServiceNameInputValues}
							setRequestedByInputValues={this.setRequestedByInputValues}
							setSelectedValue={this.setSelectedValue}
							selectedValue={this.state.selectedValue}
							setSubmittedRef={this.setSubmittedRef}
							setModifiedRef={this.setModifiedRef}
							dateRangePickerRefSubmitted={this.state.submittedDateRef}
							dateRangePickerRefModified={this.state.modifiedDateRef}
							isSelf={this.state.isSelf}
							isSelfFun={this.isSelfFun}
							updatePagination={this.updatePagination}
							page={this.state.page}
							limit={this.state.limit}
							isStatusOpen={this.state.isStatusOpen}
							multiSelectOpenFun={this.multiSelectOpenFun}
							refreshClickFun={this.refreshClickFun}
							isRefreshClick={this.state.isRefreshClick}
							filterTabActive={this.state.filterTabActive}
							filterValue={this.state.filterValue}
						/>
						{/* Home Page blue bar Section end  */}					
							<PanelGroup direction="horizontal" className={(this.state.isStatusOpen) && (this.props.showLoader_2 == true || this.props.showLoader_3 == true || this.props.showLoader_1 == true || this.props.ordersList?.length <= 6) ? "overflow-clip" : "" }>
								<Panel id="sidebar" minSize={33} order={1} defaultSize={this.state.isRightPanel ?67:100} onResize={(size) => this.setState({ panelResize : size})} className={(this.state.isStatusOpen) && (this.props.showLoader_2 == true || this.props.showLoader_3 == true || this.props.showLoader_1 == true || this.props.ordersList?.length <= 6) ? "overflow-clip" : (this.state.isRightPanel ?"isShowLeftPanel rwLeftPart" : "rwLeftPart")}>
									{/* Xsm Break Fix Form tabs Section Start*/}
									<MyRequestListView ordersListFilter={this.ordersListFilter}
										ordersListFilter2={this.ordersListFilter2}
										listFilter={this.state.listFilter}
										tr={this.props.tr}
										getOrderStatus={this.getOrderStatus}
										getItemId={this.getItemId}
										filterValue={this.state.filterValue}
										getOrderDetails={this.getOrderDetails}
										getOrderType={this.getOrderType}
										searchclick={this.state.searchclick}
										buildSearchParamsForOrderFilter={this.buildSearchParamsForOrderFilter}
										showActiveRow={this.showActiveRow}
										rowIndex={this.state.rowIndex}
										check_status={this.state.check_status}
										searcTextGloSearch={""}
										setCiServiceName={this.setCiServiceName}
										ciServiceName={this.state.ciServiceName}
										serviceBased={this.state.serviceBased}
										showRightPanelFun={this.showRightPanelFun}
										isRightPanel={this.state.isRightPanel}
										searchKey={this.state.searchKey}
										searchValue={this.state.searchValue}
										pattern={this.state.pattern}
										setSearchKeyValue={this.setSearchKeyValue}
										sortOrder={this.state.sortOrder}
										setSortOrder={this.setSortOrder}
										sortOnColumn={this.state.sortOnColumn}
										status={this.state.status}
										updateParameter={this.updateParameter}
										isClearAllFilter={this.state.isClearAllFilter}
										isClearAllFilterFunc={this.isClearAllFilterFunc}
										displayNumberSearchInput={this.state.displayNumberSearchInput}
										ciServiceNameSearchInput={this.state.ciServiceNameSearchInput}
										requesterNameSearchInput={this.state.requesterNameSearchInput}
										submittedStartDate={this.state.submittedStartDate}
										submittedEndDate={this.state.submittedEndDate}
										modifiedStartDate={this.state.modifiedStartDate}
										modifiedEndDate={this.state.modifiedEndDate}
										setSubmittedStartAndEndDate={this.setSubmittedStartAndEndDate}
										setModifiedStartAndEndDate={this.setModifiedStartAndEndDate}
										setdisplayNumberInputValues={this.setdisplayNumberInputValues}
										setCiServiceNameInputValues={this.setCiServiceNameInputValues}
										setRequestedByInputValues={this.setRequestedByInputValues}
										setSelectedValue={this.setSelectedValue}
										selectedValue={this.state.selectedValue}
										setSubmittedRef={this.setSubmittedRef}
										setModifiedRef={this.setModifiedRef}
										dateRangePickerRefSubmitted={this.state.submittedDateRef}
										dateRangePickerRefModified={this.state.modifiedDateRef}
										isSelf={this.state.isSelf}
										isSelfFun={this.isSelfFun}
										updatePagination={this.updatePagination}
										page={this.state.page}
										limit={this.state.limit}
										isStatusOpen={this.state.isStatusOpen}
										multiSelectOpenFun={this.multiSelectOpenFun}
										refreshClickFun={this.refreshClickFun}
										isRefreshClick={this.state.isRefreshClick}		
										panelResize={this.state.panelResize}	
									/>
								</Panel>
								{this.state.isRightPanel ?
								<>
								<PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>								
  								<Panel minSize={33} order={2} defaultSize={33}>
									<div className="stickyArea rBoxStyle ">
										{this.props.showLoader == false ? this.props.ordersList.length > 0 ? this.state.moduleValue == "Breakfix" ?
											<MyRequestsShortDescInc status={this.state.status}
												pattern={this.state.pattern}
												searchKey={this.state.searchKey}
												searchValue={this.state.searchValue}
												setSearchKeyValue={this.setSearchKeyValue}
												sortOrder={this.state.sortOrder}
												setSortOrder={this.setSortOrder}
												sortOnColumn={this.state.sortOnColumn}
												showRightPanelFun={this.showRightPanelFun}
												tr={this.props.tr}
												ciServiceName={this.state.ciServiceName}
												orderStatus={this.state.orderStatus}
												itemId={this.state.itemIdValue}
												quickViewOrderId={this.state.quickViewOrderId}
												module={this.state.moduleValue}
												buildSearchParamsForOrderFilter={this.buildSearchParamsForOrderFilter}
												serviceBased={this.state.serviceBased}
												isClearAllFilter={this.state.isClearAllFilter}
												isClearAllFilterFunc={this.isClearAllFilterFunc}
												displayNumberSearchInput={this.state.displayNumberSearchInput}
												ciServiceNameSearchInput={this.state.ciServiceNameSearchInput}
												requesterNameSearchInput={this.state.requesterNameSearchInput}
												submittedStartDate={this.state.submittedStartDate}
												submittedEndDate={this.state.submittedEndDate}
												modifiedStartDate={this.state.modifiedStartDate}
												modifiedEndDate={this.state.modifiedEndDate}
												updateParameter={this.updateParameter}
												setSubmittedStartAndEndDate={this.setSubmittedStartAndEndDate}
												setModifiedStartAndEndDate={this.setModifiedStartAndEndDate}
												setdisplayNumberInputValues={this.setdisplayNumberInputValues}
												setCiServiceNameInputValues={this.setCiServiceNameInputValues}
												setRequestedByInputValues={this.setRequestedByInputValues}
												setSelectedValue={this.setSelectedValue}
												selectedValue={this.state.selectedValue}
												setSubmittedRef={this.setSubmittedRef}
												setModifiedRef={this.setModifiedRef}
												dateRangePickerRefSubmitted={this.state.submittedDateRef}
												dateRangePickerRefModified={this.state.modifiedDateRef}
												updatePagination={this.updatePagination}
												page={this.state.page}
												limit={this.state.limit}
												isStatusOpen={this.state.isStatusOpen}
												multiSelectOpenFun={this.multiSelectOpenFun}
												refreshClickFun={this.refreshClickFun}
												isRefreshClick={this.state.isRefreshClick}
					
											/> :
											<MyRequestsShortDesc status={this.state.status}
												pattern={this.state.pattern} x
												searchKey={this.state.searchKey}
												searchValue={this.state.searchValue}
												setSearchKeyValue={this.setSearchKeyValue}
												sortOrder={this.state.sortOrder}
												setSortOrder={this.setSortOrder}
												sortOnColumn={this.state.sortOnColumn}
												showRightPanelFun={this.showRightPanelFun}
												ciServiceName={this.state.ciServiceName}
												listFilter={this.state.listFilter}
												tr={this.props.tr}
												orderStatus={this.state.orderStatus}
												itemId={this.state.itemIdValue}
												quickViewOrderId={this.state.quickViewOrderId}
												module={this.state.moduleValue}
												showActiveRow={this.showActiveRow}
												buildSearchParamsForOrderFilter={this.buildSearchParamsForOrderFilter}
												serviceBased={this.state.serviceBased}
												isClearAllFilter={this.state.isClearAllFilter}
												isClearAllFilterFunc={this.isClearAllFilterFunc}
												displayNumberSearchInput={this.state.displayNumberSearchInput}
												ciServiceNameSearchInput={this.state.ciServiceNameSearchInput}
												requesterNameSearchInput={this.state.requesterNameSearchInput}
												submittedStartDate={this.state.submittedStartDate}
												submittedEndDate={this.state.submittedEndDate}
												modifiedStartDate={this.state.modifiedStartDate}
												modifiedEndDate={this.state.modifiedEndDate}
												updateParameter={this.updateParameter}
												setSubmittedStartAndEndDate={this.setSubmittedStartAndEndDate}
												setModifiedStartAndEndDate={this.setModifiedStartAndEndDate}
												setdisplayNumberInputValues={this.setdisplayNumberInputValues}
												setCiServiceNameInputValues={this.setCiServiceNameInputValues}
												setRequestedByInputValues={this.setRequestedByInputValues}
												setSelectedValue={this.setSelectedValue}
												selectedValue={this.state.selectedValue}
												setSubmittedRef={this.setSubmittedRef}
												setModifiedRef={this.setModifiedRef}
												dateRangePickerRefSubmitted={this.state.submittedDateRef}
												dateRangePickerRefModified={this.state.modifiedDateRef}
												updatePagination={this.updatePagination}
												page={this.state.page}
												limit={this.state.limit}
												isStatusOpen={this.state.isStatusOpen}
												multiSelectOpenFun={this.multiSelectOpenFun}
												refreshClickFun={this.refreshClickFun}
												isRefreshClick={this.state.isRefreshClick}
												isSelf={this.state.isSelf}
											/> : '' : ''}
									</div>
								</Panel> </> : "" }
							</PanelGroup>
						
							{/* <Row className="colTransit"><Col xs={12} className="rwLeftPart">
								{/* Xsm Break Fix Form tabs Section Start
								<MyRequestListView ordersListFilter={this.ordersListFilter}
									ordersListFilter2={this.ordersListFilter2}
									listFilter={this.state.listFilter}
									tr={this.props.tr}
									getOrderStatus={this.getOrderStatus}
									getItemId={this.getItemId}
									filterValue={this.state.filterValue}
									getOrderDetails={this.getOrderDetails}
									getOrderType={this.getOrderType}
									searchclick={this.state.searchclick}
									buildSearchParamsForOrderFilter={this.buildSearchParamsForOrderFilter}
									showActiveRow={this.showActiveRow}
									rowIndex={this.state.rowIndex}
									check_status={this.state.check_status}
									searcTextGloSearch={""}
									setCiServiceName={this.setCiServiceName}
									ciServiceName={this.state.ciServiceName}
									serviceBased={this.state.serviceBased}
									showRightPanelFun={this.showRightPanelFun}
									isRightPanel={this.state.isRightPanel}
									pattern={this.state.pattern}
									searchKey={this.state.searchKey}
									searchValue={this.state.searchValue}
									setSearchKeyValue={this.setSearchKeyValue}
									sortOrder={this.state.sortOrder}
									setSortOrder={this.setSortOrder}
									sortOnColumn={this.state.sortOnColumn}
									status={this.state.status}
									updateParameter={this.updateParameter}
									isClearAllFilter={this.state.isClearAllFilter}
									isClearAllFilterFunc={this.isClearAllFilterFunc}
									displayNumberSearchInput={this.state.displayNumberSearchInput}
									ciServiceNameSearchInput={this.state.ciServiceNameSearchInput}
									requesterNameSearchInput={this.state.requesterNameSearchInput}
									submittedStartDate={this.state.submittedStartDate}
									submittedEndDate={this.state.submittedEndDate}
									modifiedStartDate={this.state.modifiedStartDate}
									modifiedEndDate={this.state.modifiedEndDate}
									setSubmittedStartAndEndDate={this.setSubmittedStartAndEndDate}
									setModifiedStartAndEndDate={this.setModifiedStartAndEndDate}
									setdisplayNumberInputValues={this.setdisplayNumberInputValues}
									setCiServiceNameInputValues={this.setCiServiceNameInputValues}
									setRequestedByInputValues={this.setRequestedByInputValues}
									setSelectedValue={this.setSelectedValue}
									selectedValue={this.state.selectedValue}
									setSubmittedRef={this.setSubmittedRef}
									setModifiedRef={this.setModifiedRef}
									dateRangePickerRefSubmitted={this.inputRef}
									dateRangePickerRefModified={this.inputRefModified}
									isSelf={this.state.isSelf}
									isSelfFun={this.isSelfFun}
									updatePagination={this.updatePagination}
									page={this.state.page}
									limit={this.state.limit}
									isStatusOpen={this.state.isStatusOpen}
									multiSelectOpenFun={this.multiSelectOpenFun}
									refreshClickFun={this.refreshClickFun}
									isRefreshClick={this.state.isRefreshClick}
		
								/>
							</Col></Row> */}
					

					</div>
				</div>
				{/* {homepagelocation == "/unauthorized" || homepagelocation === "/userActivationPending" ? null : <FooterCopyright isLogout={GLOBAL.isLogout}/>} */}
			</main>
		);
	}
}
MyRequestsIndex.propTypes = {
	emptyOrdersReducer: PropTypes.func,
	showLoader: PropTypes.bool,
	tr: PropTypes.object,
	orderListMeta: PropTypes.object

};

export function mapStateToProps(state) {
	let data = state.ordersList.data;
	let meta = state.ordersList.meta;
	if (data == undefined)
		data = [];
	return {
		myOrderViewFilters: state.myOrderViewFilters,
		ordersList: data,
		orderListMeta: meta,
		lang: state.spcmReducer.lang,
		tr: state.spcmReducer.tr,
		showLoader: state.showLoader.loading,
		showLoader_1: state.showLoader_1.loading,
		showLoader_2: state.showLoader_2.loading,
		showLoader_3: state.showLoader_3.loading
	};
}


export default connect(mapStateToProps, { getTranslation, emptyOrdersReducer, loadOrdersList })(MyRequestsIndex);

