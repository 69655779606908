
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
//const getCategoryList = 'https://xsm-f.mybluemix.net/getOfferingsForListRestifySelfService/?categoryId=';
import {GLOBAL} from '_Globals'
import Cookies from 'universal-cookie';
const cookies = new Cookies();
let userID = cookies.get('gph');
if (userID) userID = userID.replace('s:', '');
if (userID) userID = userID.substring(0, userID.lastIndexOf('.'));
userID = userID.split("~");
userID = userID[43];

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

export function loadReviewOrderListSuccess(reviewOrdersList) {
	return {
		type: 'LOAD_REVIEWORDERS_SUCCESS',
		reviewOrdersList
	};
}

export function loadPendingReviewOrderListCountSuccess(reviewOrdersListCount){
	return {
		type: 'LOAD_PENDINGREVIEWORDERS_COUNT_SUCCESS',
		reviewOrdersListCount
	};
}
export function loadPendingReviewOrderListSuccess(reviewOrdersList) {
	return {
		type: 'LOAD_PENDINGREVIEWORDERS_SUCCESS',
		reviewOrdersList
	};
}

export function loadReviewOrderList(requesterId, orderType, feedbackStatus, sortBy, orderBy, listFilter, searchColumn, searchValue, patternList, currentPage = 0, size = 10, self=true) {
	let str = {};
	let itemStatus = [];
	if (feedbackStatus == "N") { itemStatus.push("75,25"); }
	if (feedbackStatus == "Y") { itemStatus.push("80,30"); }
	if (feedbackStatus == "Y,N" || feedbackStatus == "N,Y") { itemStatus.push("75,25,80,30"); }
	let otherCondition = !self?','+'requesterId':'';
	let otherConditionValue = !self?'|'+userID:'';
	if (searchColumn != "" || searchValue != "") {
		str.searchByList = searchColumn + "," + listFilter +otherCondition+ ",type,itemStatus,feedbackSubmitted";
		str.multipleValueList = searchValue + "|" + requesterId +otherConditionValue+ "|" + orderType + "|" + itemStatus + "|" + feedbackStatus+otherConditionValue;
		str.patternList = patternList;
	}
	else {
		str.searchByList = listFilter+otherCondition + ",type,itemStatus,feedbackSubmitted";
		str.multipleValueList = requesterId +otherConditionValue+ "|" + orderType + "|" + itemStatus + "|" + feedbackStatus+otherConditionValue;
	}
	//str.operatorList="or,and";
	str.sortBy = sortBy;
	str.orderBy = orderBy;
	str.currentPage = currentPage,
	//str.searchByList+= ',isAutoClosed';
	//str.multipleValueList+='|false';
		str.size = size;
		if(!self){
			if(typeof str.patternList!=='undefined'){
			 str.patternList=str.patternList+',IN,NOT IN';
			}else{
			 str.patternList='IN,NOT IN';
			}
		 }
	//str = JSON.stringify(str);
	return (dispatch) => {
		dispatch({ type: "LOADER_IN_PROGRESS" });
		//for pending axios.get(requesterId,createdBy GLOBAL.feedbackGetOrdersUrl+"searchByList="+searchBy+","+type+","+status+"&multipleValueList="+userId+"|"+"N"+"|"+statusValue+"&orderBy="+sorting)
		api.post(GLOBAL.feedbackGetOrdersUrl, str)
			.then((reviewOrdersList) => {
				if (!reviewOrdersList) {
					dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
					throw Error(reviewOrdersList.statusText);
				}
				return reviewOrdersList;
			})
			.then((reviewOrdersList) => {
				dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
				dispatch(loadReviewOrderListSuccess(reviewOrdersList));
			})
			.catch((err) => {
				dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
			});
	};
}

export function loadPendingReviewOrderList(requesterId, orderType, feedbackStatus, sortBy, orderBy, listFilter, searchColumn, searchValue, patternList, currentPage = 0, size = 10) {
	let str = {};
	let itemStatus = [];
	if (feedbackStatus == "N") { itemStatus.push("75,25"); }
	if (feedbackStatus == "Y") { itemStatus.push("80,30"); }
	if (feedbackStatus == "Y,N" || feedbackStatus == "N,Y") { itemStatus.push("75,25,80,30"); }

	if (searchColumn != "" || searchValue != "") {
		str.searchByList = searchColumn + "," + listFilter + ",type,itemStatus,feedbackSubmitted";
		str.multipleValueList = searchValue + "|" + requesterId + "|" + orderType + "|" + itemStatus + "|" + feedbackStatus;
		str.patternList = patternList;
	}
	else {
		str.searchByList = listFilter + ",type,itemStatus,feedbackSubmitted";
		str.multipleValueList = requesterId + "|" + orderType + "|" + itemStatus + "|" + feedbackStatus;
	}
	//str.operatorList="or,and";
	str.sortBy = sortBy;
	str.orderBy = orderBy;
	str.currentPage = currentPage,
		str.size = size;
	return (dispatch) => {
		dispatch({ type: "LOADER_IN_PROGRESS" });
		//for pending axios.get(requesterId,createdBy GLOBAL.feedbackGetOrdersUrl+"searchByList="+searchBy+","+type+","+status+"&multipleValueList="+userId+"|"+"N"+"|"+statusValue+"&orderBy="+sorting)
		api.post(GLOBAL.feedbackGetOrdersUrl, str)
			.then((reviewOrdersList) => {
				if (!reviewOrdersList) {
					dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
					throw Error(reviewOrdersList.statusText);
				}
				return reviewOrdersList;
			})
			.then((reviewOrdersList) => {
				dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
				dispatch(loadPendingReviewOrderListSuccess(reviewOrdersList));
			})
			.catch((err) => {
				dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
				console.log(err);
			});
	};
}

export function loadPendingReviewOrderListCount(requesterId, orderType, feedbackStatus, sortBy, orderBy, listFilter, searchColumn, searchValue, patternList, currentPage = 0, size = 10){
	let str = {};
	let itemStatus = [];
	if (feedbackStatus == "N") { itemStatus.push("75,25"); }
	if (feedbackStatus == "Y") { itemStatus.push("80,30"); }
	if (feedbackStatus == "Y,N" || feedbackStatus == "N,Y") { itemStatus.push("75,25,80,30"); }

	if (searchColumn != "" || searchValue != "") {
		str.searchByList = searchColumn + "," + listFilter + ",type,itemStatus,feedbackSubmitted";
		str.multipleValueList = searchValue + "|" + requesterId + "|" + orderType + "|" + itemStatus + "|" + feedbackStatus;
		str.patternList = patternList;
	}
	else {
		str.searchByList = listFilter + ",type,itemStatus,feedbackSubmitted";
		str.multipleValueList = requesterId + "|" + orderType + "|" + itemStatus + "|" + feedbackStatus;
	}
	str.sortBy = sortBy;
	str.orderBy = orderBy;
	str.currentPage = currentPage,
		str.size = size;
	return (dispatch) => {
		dispatch({ type: "LOADER_IN_PROGRESS" });
		api.post(GLOBAL.feedbackGetOrdersCountUrl, str).then((reviewOrdersList) => {
			if (!reviewOrdersList) {
				dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
				throw Error(reviewOrdersList.statusText);
			}
			return reviewOrdersList;
		}).then((reviewOrdersList)=>{
			dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
			reviewOrdersList.data.data={};
			dispatch(loadPendingReviewOrderListCountSuccess(reviewOrdersList));
		}).catch((err) => {
			dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
			console.log(err);
		});
	};
}
//load/filter orderlist for orderType
export function loadReviewOrdersListType(requesterId, orderType, itemStatus, feedbackStatus, orderBy) {
	//alert("loadOrdersListFilter");
	var str = {};
	str.searchByList = "requesterId,type,itemStatus,feedbackSubmitted";
	str.multipleValueList = requesterId + "|" + orderType + "|" + itemStatus + "|" + feedbackStatus;
	str.sortBy = "createdOn";
	str.orderBy = orderBy;
	//str = JSON.stringify(str);
	return (dispatch) => {
		api.get(GLOBAL.feedbackGetOrdersUrl, str)
			.then((reviewOrdersList) => {
				if (!reviewOrdersList) {
					throw Error(reviewOrdersList.statusText);
				}
				return reviewOrdersList;
			})
			.then((reviewOrdersList) => {
				dispatch(loadReviewOrderListSuccess(reviewOrdersList))
			})
			.catch((err) => {
				console.log(err);
			});
	};
}

//
//load/filter orderlist for correction and service
export function loadReviewOrdersListService(requesterId, orderType, itemStatus, feedbackStatus, orderBy) {
	//alert("loadOrdersListFilter");
	var str = {};
	str.searchByList = "requesterId,type,itemStatus,feedbackSubmitted";
	str.multipleValueList = requesterId + "|" + orderType + "|" + itemStatus + "|" + feedbackStatus;
	str.sortBy = "createdOn";
	str.orderBy = orderBy;
	//str = JSON.stringify(str);
	return (dispatch) => {
		api.get(GLOBAL.feedbackGetOrdersUrl, str)
			.then((reviewOrdersList) => {
				if (!reviewOrdersList) {
					throw Error(reviewOrdersList.statusText);
				}
				return reviewOrdersList;
			})
			.then((reviewOrdersList) => {
				dispatch(loadReviewOrderListSuccess(reviewOrdersList))
			})
			.catch((err) => {
				console.log(err);
			});
	};
}
//load order details
export function loadReviewOrderDetails(orderId) {
	//alert("loadReviewOrderDetails");
	return (dispatch) => {
		axios.get(GLOBAL.feedbackOrdersUrl + orderId)
			.then((reviewOrdersDetails) => {
				if (!reviewOrdersDetails) {
					throw Error(reviewOrdersDetails.statusText);
				}
				return reviewOrdersDetails;
			})
			.then((reviewOrdersDetails) => {
				dispatch(loadReviewOrdersDetailsSuccess(reviewOrdersDetails))
			})
			.catch((err) => {
				console.log(err);
			});
	};
}

export function loadReviewOrdersDetailsSuccess(reviewOrdersDetails) {
	return {
		type: 'LOAD_REVIEWORDER_DETAILS_SUCCESS',
		reviewOrdersDetails
	};
}

//getting breakfix order details
export function loadReviewBreakFixOrderDetails(orderId) {
	//alert("M12");
	return (dispatch) => {
		axios.get(GLOBAL.feedbackBrkOrdersUrl + orderId)
			.then((reviewBreakFixOrderDetails) => {
				if (!reviewBreakFixOrderDetails) {
					throw Error(reviewBreakFixOrderDetails.statusText);
				}
				return reviewBreakFixOrderDetails;
			})
			.then((reviewBreakFixOrderDetails) => {
				dispatch(loadReviewBreakFixOrdersDetailsSuccess(reviewBreakFixOrderDetails))
			})
			.catch((err) => {
				console.log(err);
			});
	};
}

export function loadReviewBreakFixOrdersDetailsSuccess(reviewBreakFixOrderDetails) {
	return {
		type: 'LOAD_REVIEWBREAKFIXORDER_DETAILS_SUCCESS',
		reviewBreakFixOrderDetails
	};
}
//==============

//==============
//load order details from reviewsapp.mybluemix.net(review engine)
export function loadReviewOrderEngineDetails(workItemId, requestorId, workItemType) {
	//export function loadReviewOrderEngineDetails(){
	//alert(workItemId);
	console.log("reviewOrdersEngineDetails");

	var config = {
		//  params: {'filter': {"where":{"workItemId":workItemId,"requesterId":requestorId,"workItemType":workItemType}}},
		// params: { 'filter': { "where": { "workItemId": workItemId, "workItemType": workItemType } } },
		headers: {'X-IBM-Client-Id': 'b56cce80-a3fc-4194-87d7-edddb4d3bd28',
			'query':JSON.stringify({"workItemId": workItemId, "workItemType": workItemType})
		}
	};

	return (dispatch) => {
		//axios.get(GLOBAL.feedbackGetOrdersFrmEngineUrl+{"where":{"workItemId":workItemId,"requestorId":requestorId}})
		//alert("M12");
		axios.get(GLOBAL.feedbackGetOrdersFrmEngineUrl, config)
			.then((reviewOrdersEngineDetails) => {
				if (!reviewOrdersEngineDetails) {
					throw Error(reviewOrdersEngineDetails.statusText);
				}
				return reviewOrdersEngineDetails;
			})
			.then((reviewOrdersEngineDetails) => {
				dispatch(loadReviewOrderEngineSuccess(reviewOrdersEngineDetails))
			})
			.catch((err) => {
				console.log(err);
			});
	};
}

export function loadReviewOrderEngineSuccess(reviewOrdersEngineDetails) {
	return {
		type: 'LOAD_REVIEWORDERENGINE_DETAILS_SUCCESS',
		reviewOrdersEngineDetails
	};
}

export function addToSelectedReviewStatusList(status) {
	console.log("add Status:" + status)
	return {
		type: 'ADD_TO_SELECTEDREVIEWORDER_STATUS_LIST',
		payload: status
	};
}

export function removeFromSelectedReviewStatusList(status) {
	console.log("remove Status ..." + status)
	return {
		type: 'REMOVE_FROM_SELECTEDREVIEWORDER_STATUS_LIST',
		payload: status
	};
}

export function addToSelectedReviewOrderTypeList(orderType) {

	console.log("add OrderType ", orderType)
	return {
		type: 'ADD_TO_SELECTEDREVIEWORDER_TYPE_LIST',
		payload: orderType
	};
}

export function removeFromSelectedReviewOrderTypeList(orderType) {
	console.log(" OrderType...", orderType)
	return {
		type: 'REMOVE_FROM_SELECTEDREVIEWORDER_TYPE_LIST',
		payload: orderType
	};
}

export function resetReviewFilterList() {
	return {
		type: 'RESET_REVIEW_FILTERS'
	};
}

export function loadTopReviewOrderListSuccess(topReviewList) {
	return {
		type: 'LOAD_TOP_REVIEWS_LIST',
		topReviewList
	};
}

export function loadTopReviewList(offeringId) {
	let str = {};
	str.offeringId = offeringId;
	return (dispatch) => {
		dispatch({ type: "REVIEW_LOADER_IN_PROGRESS" });
		api.post("/api/reviews/topRating", str)
			.then((topReviewList) => {
				dispatch({ type: 'REVIEW_LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
				if (!topReviewList) {
					throw Error(topReviewList.statusText);
				}
				if(topReviewList.status === 204) {
                   return {data: {responseData: "No data present for the mentioned offering ID"}};
                }
				return topReviewList;
			})
			.then((topReviewList) => {
				dispatch({ type: 'REVIEW_LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
				dispatch(loadTopReviewOrderListSuccess(topReviewList));
			})
			.catch((err) => {
				dispatch({ type: 'REVIEW_LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
				dispatch(loadTopReviewOrderListSuccess(err.response));
				console.log(err);
			});
	};
}