
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export const insidedResultsList = (state = [], action) => {
	switch (action.type) {
		case 'LOAD_INSIDED_RESULT_SUCCESS':
			//alert(action.breakFix.data.);
			return action.insidedResult;
		case 'RESET_DATA':
			return [];
		default:
			return state;
	}
};

export const isFetchingInsidedDetails = (state = {
	isFetching: false
}, action) => {
	switch (action.type) {
		case 'FETCHING_INSIDED_DETAILS_IN_PROGRESS':
			return Object.assign({}, state, {
				isFetching: true
			});
		case 'INSIDED_DETAILS_FETCHED_SUCCESSFULLY':
			return Object.assign({}, state, {
				isFetching: false
			});
		default:
			return state;
	}
};

export const insidedTokenDetails = (state = {
	access_token: '',
	expires_in: '',
	token_type: ''
}, action) => {
	switch (action.type) {
		case 'FETCH_INSIDED_TOKEN_SUCCESS':
			return Object.assign({}, state, {
				access_token: action.insidedTokenResult.access_token,
				expires_in: action.insidedTokenResult.expires_in,
				token_type: action.insidedTokenResult.token_type
			});
		case 'FETCH_INSIDED_TOKEN_ERROR':
			return Object.assign({}, state, {
				access_token: '',
				expires_in: '',
				token_type: ''
			});
		default:
			return state;
	}
};

export const knowledgeGlobalResultsList = (state = [], action) => {
	switch (action.type) {
		case 'LOAD_KNOWLEDGE_GLOBAL_RESULT_SUCCESS':
			//alert(action.breakFix.data.);
			return action.knowledgeResult;
		default:
			return state;
	}
};

export const isFetchingGlobalKnowledgeDetails = (state = {
	isFetching: false
}, action) => {
	switch (action.type) {
		case 'FETCHING_KNOWLEDGE_GLOBAL_DETAILS_IN_PROGRESS':
			return Object.assign({}, state, {
				isFetching: true
			});
		case 'KNOWLEDGE_GLOBAL_DETAILS_FETCHED_SUCCESSFULLY':
			return Object.assign({}, state, {
				isFetching: false
			});
		default:
			return state;
	}
};
export const articleDetails = (state = [], action) => {
	switch (action.type)
	{
		case 'LOAD_DISCOURSE_ARTICLE_SUCCESS':
			//alert(action.breakFix.data.);
			return action.articleResult;
		default:
			return state;
	}
};

export const knowledgeCategoriesList = (state = [], action) => {
	switch (action.type) {
		case 'LOAD_KNOWLEDGE_CATEGORIES_SUCCESS':
			return action.knowledgeCategories;
		default:
			return state;
	}
};