
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
//import initialState from '../../initialState';

export const myServiceList = (state = {}, action) =>{
	switch (action.type){
		case 'LOAD_SERVICE_SUCCESS':
			return action.serviceList.data;
			default:
			return state;
	}
}

export const myServiceDetails = (state = {}, action)=> {
	switch (action.type){
		case 'LOAD_SERVICEDETAILS_SUCCESS':
			return action.serviceDetails.data;
			default:
			return state;
	}
}

export const userListReviewFromRE = (state = {}, action)=> {
	switch (action.type){
		case 'LOAD_REVIEWUSEROFFERINGENGINE_LIST_SUCCESS':
			return action.userListOfferingReview.data;
			default:
			return state;
	}
}

export const serviceReviewwFromRE = (state = {}, action)=> {
	switch (action.type){
		case 'LOAD_REVIEWSERVICEENGINE_DETAILS_SUCCESS':
			return action.reviewServiceEngineDetails.data;
			default:
			return state;
	}
}

export const ratingCalcReviewFromRE = (state = {}, action)=> {
	switch (action.type){
		case 'LOAD_RATINGCALCOFFERINGENGINE_LIST_SUCCESS':
			return action.ratingCalcOfferingReview.data;
			default:
			return state;
	}
}

export const loadItmData = (state = {}, action) => {
	switch (action.type) {
		case 'LOAD_ITM_DETAILS':
			console.log("actionaction ",action)
			return action.payload;
		case "RESET_STATE":
			return {};
		default:
			return state;
	}
}