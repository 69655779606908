
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import {Button} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import axios from 'axios';
import {connect} from 'react-redux';
import swal from 'sweetalert';

import { loadChangeEditData } from '../../../../actions/changeManagement/changeManagementAction.js';
import { loadBreakFixAuditLogDetails } from '../../../../actions/breakFix/breakFixAuditLogAction';
import { loadStatusBasedHamburgerOptions } from '../../../../actions/common/loadStatusBasedHamburgerOptions';
import { loadTimelineData } from '../../../../actions/common/commonApisActions.js';

import { GLOBAL } from "_Globals";
import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";
import configureStore from '../../../../store/configureStore';

const store = configureStore();


class ProvideAttachmentsTab extends React.Component{
    constructor(props){
        super(props);
            this.state = {
                statusValidationError:'',
                outrecdValidationError:'',
                exceptionValidationError:'',
                expserviceValidationError:'',
                desresultValidationError:'',
                status:'',
                outrecd:'',
                exception:'',
                expservice:'',
                desresult:'',
                savedMessage:'',
                incRaised: '',
                chgOnSchedule: '',
                impact: '',
                adqMitigated: '',
                newRisks: '',
                detailsOfRisks: '',
                newChangeReq: '',
                closeCode:'',
                closureNote:'',
                incRaisedError: '',
                chgOnScheduleError: '',
                impactError: '',
                adqMitigatedError: '',
                newRisksError: '',
                detailsOfRisksError: '',
                newChangeReqError: '',
                loader:false,
                successMsg:''
            };
            GLOBAL.pirsubmitflag='false'
            this.getStatus = this.getStatus.bind(this);
            this.getOutrecd = this.getOutrecd.bind(this);
            this.getException = this.getException.bind(this);
            this.getExpservice = this.getExpservice.bind(this);
            this.getDesresult = this.getDesresult.bind(this);
            this.updatePIRValue = this.updatePIRValue.bind(this);
            this.postForm = this.postForm.bind(this);
    }
    getStatus(event){
        this.setState({status:event.target.value});
    }
    getOutrecd(event){
        this.setState({outrecd:event.target.value});
    }
    getException(event){
        this.setState({exception:event.target.value});
    }
    getExpservice(event){
        this.setState({expservice:event.target.value});
    }
    getDesresult(event){
        this.setState({desresult:event.target.value});
    }
    updatePIRValue(event){
		let ref = this;
		switch(event.target.name){
			case 'incRaised':
				ref.setState({ incRaised: event.target.value, incRaisedError:'' });
				break;
			case 'chgOnSchedule':
				ref.setState({ chgOnSchedule: event.target.value, chgOnScheduleError:'' })
				break;
			case 'impact':
				ref.setState({ impact: event.target.value, impactError:'' })
				break;
			case 'adqMitigated':
				ref.setState({ adqMitigated: event.target.value, adqMitigatedError:'' })
				break;
			case 'newRisks':
				ref.setState({ newRisks: event.target.value, newRisksError:'', detailsOfRisks: '', detailsOfRisksError:'' });
				break;
			case 'detailsOfRisks':
				// ref.detailsOfRisksRef.value= event.target.value.trimStart();
        ref.setState({ detailsOfRisks: event.target.value.trimStart(), detailsOfRisksError:'' });
				break;
			case 'newChangeReq':
				ref.setState({ newChangeReq: event.target.value, newChangeReqError:'' })
				break;
			case 'closeCode':
				ref.setState({ closeCode: event.target.value, closeCodeError:'' })
				break;
			case 'closureNote':
				// ref.closureNoteRef.value= event.target.value.trimStart();
				ref.setState({ closureNote: event.target.value.trimStart(), closureNoteError:'' })
				break;
		}
	}
    postForm(){
		let ref = this;
		if((this.props.user_id!=this.props.editChangeInitialData.changeManagementIndividual)&& (this.props.roleIdentification('19') || this.props.roleIdentification('20')) && (this.props.editChangeInitialData.type!="20"||this.props.editChangeInitialData.type!=20)){
			swal({
			  text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
			  button: this.props.translator['OK'],
			});
			return;
		}
		if(this.state.incRaised == "")
		this.setState({incRaisedError:'error'});
		else
		this.setState({incRaisedError:''});

		if(this.state.chgOnSchedule == "")
		this.setState({chgOnScheduleError:'error'});
		else
		this.setState({chgOnScheduleError:''});

		if(this.state.impact == "")
		this.setState({impactError:'error'});
		else
		this.setState({impactError:''});

		if(this.state.adqMitigated == "")
		this.setState({adqMitigatedError:'error'});
		else
		this.setState({adqMitigatedError:''});

		if(this.state.newRisks == "")
		this.setState({newRisksError:'error'});
		else
		this.setState({newRisksError:''});

		if(this.state.detailsOfRisks == "" || this.state.detailsOfRisks.trim() == "")
		this.setState({detailsOfRisksError:'error'});
		else
		this.setState({detailsOfRisksError:''});

		if(this.state.newChangeReq == "")
		this.setState({newChangeReqError:'error'});
		else
		this.setState({newChangeReqError:''});

		if(this.state.closeCode == "")
		this.setState({closeCodeError:'error'});
		else
		this.setState({closeCodeError:''});

		if(this.state.closureNote == "" || this.state.closureNote.trim() == "")
		this.setState({closureNoteError:'error'});
		else
		this.setState({closureNoteError:''});

		if (this.state.incRaised != "" && this.state.chgOnSchedule != "" && this.state.impact != "" &&
			this.state.adqMitigated != "" && this.state.newRisks != "" && this.state.newChangeReq != "" &&
			this.state.closeCode != "" && this.state.closureNote.trim() != "") {
			if (this.state.newRisks == "Yes" && this.state.detailsOfRisks.trim() == "") {
				return false;
			}

			let formObj = {
				"changeId": this.props.itemDetails.changeId,
				"incRaised": this.state.incRaised,
				"chgOnSchedule": this.state.chgOnSchedule,
				"impact": this.state.impact,
				"adqMitigated": this.state.adqMitigated,
				"newRisks": this.state.newRisks,
				"detailsOfRisks": this.state.newRisks == "Yes" ? this.state.detailsOfRisks.trim() : "",
				"newChangeReq": this.state.newChangeReq,
				"closureCodes":this.state.closeCode
			}

			let clouserNoteObj = {
				"itemId": this.props.itemDetails.changeId,
				 "description": "Closure Code: "+this.state.closeCode+", "+ "Closure Notes: "+this.state.closureNote.trim(),
				 "createdOn": "",
				 "createdBy": "",
				 "isInternal": "N",
				 "module": "ChangeManagement",
				 "createdByName": ""
			}

			this.setState({loader:true})
			axios.post(GLOBAL.postPIRUrl, formObj).then(function (response) {
					if (response.status == 201) {
						axios.patch(GLOBAL.changeEditUrl+response.data.changeId, {
							"status": "70",
							"modifiedBy": "",
							"modifiedByName": "",
							"closureCodes": ref.state.closeCode,
                            "closureComments": ref.state.closureNote
						}).then((resp)=>{
							ref.props.changeActionView('processHierarchy');
							ref.props.loadStatusBasedHamburgerOptions('ChangeManagement',resp.data.status,resp.data.changeId);
							ref.props.loadChangeEditData(resp.data.changeId);
							store.props.dispatch({ type: 'CHANGE_EDIT_DATA_LOAD_SUCCESS', editChangeInitialData: {} });
						})
						.catch(function (error) {
						});
						axios.post(GLOBAL.breakFixActivityLogPostUrl, clouserNoteObj).then(function (response) {
								if (response.status == 201) {
									ref.setState({ loader: false, successMsg: 'PIR saved successfully' });
								}
							}).catch(function (error) {
								ref.setState({ loader: false });
							});
						}
				}).catch(function (error) {
					ref.setState({ loader: false });
				});
		}
	}
    postRequest(){
		let ref=this;
		if((this.props.user_id!=this.props.editChangeInitialData.changeManagementIndividual)&& (this.props.roleIdentification('19') || this.props.roleIdentification('20')) && (this.props.editChangeInitialData.type!="20"||this.props.editChangeInitialData.type!=20)){
        swal({
          text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
          button: this.props.translator['OK'],
        });
		return;
	    }
		if(this.state.status==""){
			this.setState({statusValidationError:"error"});
			GLOBAL.pirsubmitflag='true'
		}else{
			this.setState({statusValidationError:''});
			GLOBAL.pirsubmitflag='false'
		}

		if(this.state.status=="5" || this.state.status=="10"){
			if(this.state.outrecd=="" || this.state.outrecd=="Select")
			{
				this.setState({outrecdValidationError:"error"});
				GLOBAL.pirsubmitflag='true'
			}
			else {
				this.setState({outrecdValidationError:''});
				GLOBAL.pirsubmitflag='false'
			}

			if(this.state.exception=="" || this.state.exception=="Select")
			{
				this.setState({exceptionValidationError:"error"});
				GLOBAL.pirsubmitflag='true'
			}
			else {
				this.setState({exceptionValidationError:''});
				GLOBAL.pirsubmitflag='false'
			}

			if(this.state.expservice=="" || this.state.expservice=="Select")
			{
				this.setState({expserviceValidationError:"error"});
				GLOBAL.pirsubmitflag='true'
			}
			else {
				this.setState({expserviceValidationError:''});
				GLOBAL.pirsubmitflag='false'
			}

			if(this.state.desresult=="" || this.state.desresult=="Select")
			{
				this.setState({desresultValidationError:"error"});
				GLOBAL.pirsubmitflag='true'

			}
			else {
				this.setState({desresultValidationError:''});
				GLOBAL.pirsubmitflag='false'
			}
		}

		if(this.state.status=="15"){
			if(this.state.outrecd=="" || this.state.outrecd=="Select")
			{
				this.setState({outrecdValidationError:"error"});
				GLOBAL.pirsubmitflag='true';
			}
			else {
				this.setState({outrecdValidationError:''});
				GLOBAL.pirsubmitflag='false';
			}

			if(this.state.exception=="" || this.state.exception=="Select")
			{
				this.setState({exceptionValidationError:"error"});
				GLOBAL.pirsubmitflag='true';
			}
			else {
				this.setState({exceptionValidationError:''});
				GLOBAL.pirsubmitflag='false';
			}

			if(this.state.desresult=="" || this.state.desresult=="Select")
			{
				this.setState({desresultValidationError:"error"});
				GLOBAL.pirsubmitflag='true';

			}
			else {
				this.setState({desresultValidationError:''});
				GLOBAL.pirsubmitflag='false';
			}
		}
    let postjson = {
			"changeId": this.props.changeId,
			"desResult": this.state.desresult,
			"exceptions": this.state.exception,
			"expService": this.state.expservice,
			"outRecd": this.state.outrecd,
			"statusReason": this.state.status,
			"status":70
    };
		if(GLOBAL.pirsubmitflag=='false'){
	    axios.post(GLOBAL.postPIRUrl, postjson).then(function (response) {
				if(response.status==201){
					axios.patch(GLOBAL.changeEditUrl+response.data.changeId, {"status": "70"}).then((resp)=>{
						ref.props.changeActionView('processHierarchy')
						ref.props.loadStatusBasedHamburgerOptions('ChangeManagement',response.data.status,response.data.changeId);
						ref.props.loadChangeEditData(response.data.changeId);
						store.props.dispatch({ type: 'CHANGE_EDIT_DATA_LOAD_SUCCESS', editChangeInitialData: {} });
					})
					.catch(function (error) {
					});
					ref.setState({savedMessage:'PIR saved successfully'});
				}
	    }).catch(function (error) {
	    });
		}
	}
    render(){
        let ref = this;
        let errorMsg = ((this.state.incRaisedError || this.state.chgOnScheduleError || this.state.impactError || this.state.adqMitigatedError ||
	    this.state.newRisksError ||  this.state.newChangeReqError || this.state.closeCodeError || this.state.closureNoteError ) ? true : false);
	    errorMsg = errorMsg ? errorMsg : (this.state.newRisks == "Yes" && this.state.detailsOfRisksError !=="" ?true :false) ;
        if(this.props.itemDetails.type == "25" || this.props.itemDetails.type == "30"  || this.props.itemDetails.type == "35" ){
            if(ref.state.savedMessage==""){
                return(
                    <div className="hambTab">
                        <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
                            <ul>
                                <li>
                                <button
                                    type='button'
                                    title="Minimize the right panel"
                                    bsClass=""
                                    bsStyle=""
                                    className="closerightPanelBtn"
                                    onClick={() => {
                                    this.props.rightEditPanel(false);
                                    }}
                                >
                                    <IoClose/>
                                </button>
                                </li>
                            </ul>
                        </div>
                        <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Post Implementation Review']}</div></div>
                        <div className='rBoxGap'>
                        {errorMsg ? <div><font color="red"><strong>{this.props.translator['Please enter the required data']}</strong></font></div>:""}
                        <Form.Group className='form-group'
                        // validationState={this.state.incRaisedError}
                        >
                            <Form.Label><span className="rStar"></span>{this.props.translator['Any Incidents raised due to Change Implementation ?']}</Form.Label>
                            <Form.Select className={this.state.incRaisedError} name="incRaised" as="select" onChange={this.updatePIRValue} disabled={this.state.successMsg != ''}>
                                <option value="">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className='form-group'
                        // validationState={this.state.chgOnScheduleError}
                        >
                            <Form.Label><span className="rStar"></span>{this.props.translator['Was the Change implemented on Schedule?']}</Form.Label>
                            <Form.Select className={this.state.chgOnScheduleError} name="chgOnSchedule" as="select" onChange={this.updatePIRValue} disabled={this.state.successMsg != ''}>
                                <option value="">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className='form-group'
                        // validationState={this.state.impactError}
                        >
                            <Form.Label><span className="rStar"></span>{this.props.translator['Did the Change impact any other services or Cis which was not in the plan?']}</Form.Label>
                            <Form.Select className={this.state.impactError} name="impact" as="select" onChange={this.updatePIRValue}  disabled={this.state.successMsg != ''}>
                                <option value="">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className='form-group'
                        // validationState={this.state.adqMitigatedError}
                        >
                            <Form.Label><span className="rStar"></span>{this.props.translator['Were realized risks adequately mitigated?']}</Form.Label>
                            <Form.Select className={this.state.adqMitigatedError} name="adqMitigated" as="select" onChange={this.updatePIRValue}  disabled={this.state.successMsg != ''}>
                                <option value="">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className='form-group'
                        validationState={this.state.newRisksError}
                        >
                            <Form.Label><span className="rStar"></span>{this.props.translator['Any new risks observed?']}</Form.Label>
                            <Form.Select className={this.state.newRisksError} name="newRisks" as="select" onChange={this.updatePIRValue}  disabled={this.state.successMsg != ''}>
                                <option value="">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </Form.Select>
                        </Form.Group>
                        {this.state.newRisks == "Yes"?
                        <Form.Group className='form-group'
                        disabled={this.state.successMsg != ''}>
                            <Form.Label><span className="rStar"></span>{this.props.translator['Please provide the details of the new risks observed']}</Form.Label>
                            <Form.Control className={this.state.detailsOfRisksError} name="detailsOfRisks"  inputRef={(ref) => { this.detailsOfRisksRef = ref }} type="text" onChange={this.updatePIRValue} maxLength={1000}  disabled={this.state.successMsg != ''}/>
                        </Form.Group>
                        :""}
                        <Form.Group className='form-group'
                        // validationState={this.state.newChangeReqError}
                        disabled={this.state.successMsg != ''}>
                            <Form.Label><span className="rStar"></span>{this.props.translator['Any new change were required during implementation?']}</Form.Label>
                            <Form.Select className={this.state.newChangeReqError} name="newChangeReq" as="select" onChange={this.updatePIRValue}  disabled={this.state.successMsg != ''}>
                                <option value="">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className='form-group'
                        // validationState={this.state.closeCodeError}
                        disabled={this.state.successMsg != ''}>
                            <Form.Label><span className="rStar"></span>{this.props.translator['Closure Code']}</Form.Label>
                            <Form.Select className={this.state.closeCodeError} name="closeCode" as="select" onChange={this.updatePIRValue}  disabled={this.state.successMsg != ''}>
                                <option value="">Select</option>
                                <option value="Successful">Successful</option>
                                <option value="Unsuccessful">Unsuccessful</option>
                                <option value="Partially Successful">Partially Successful</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className='form-group'
                        // validationState={this.state.closureNoteError}
                        >
                            <Form.Label><span className="rStar"></span>{this.props.translator['Closure Notes']}</Form.Label>
                            <Form.Control className={this.state.closureNoteError} name="closureNote" type="text"
                            // inputRef={(ref) => { this.closureNoteRef = ref }}
                            value={this.state.closureNote}
                            onChange={this.updatePIRValue} maxLength={1000}  disabled={this.state.successMsg != ''} />
                        </Form.Group>
                        {this.state.successMsg == '' ?
                            <ButtonToolbar className="margin-t-10">
                                <Button bsSize="small" bsStyle="primary" className="rgSidrkBtn smallBtn" onClick={() => { this.postForm(); }} disabled={this.state.loader}>{this.state.loader ? <ImSpinner6 className="icn-spinner"/> : null}{this.props.translator['Save']}</Button>
                                <Button bsSize="small" bsStyle="primary" className="rgSiLigBtn smallBtn" disabled={this.state.loader} onClick={() => {this.props.changeActionView('processHierarchy') }}>{this.props.translator['Cancel']}</Button>
                            </ButtonToolbar> :
                            <font color="green" ><b>{this.props.translator[this.state.successMsg]}</b></font>}
                            </div>
                    </div>
                )
            }
        }else{
            if(this.state.savedMessage==""){
                return(
                <div className="hambTab">
                    <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
                        <ul>
                            <li>
                            <button
                                type='button'
                                title="Minimize the right panel"
                                bsClass=""
                                bsStyle=""
                                className="closerightPanelBtn"
                                onClick={() => {
                                this.props.rightEditPanel(false);
                                }}
                            >
                                <IoClose/>
                            </button>
                            </li>
                        </ul>
                    </div>
                    {this.state.statusValidationError=="error" ?
                       <div>
                        <font color="red"><strong>{this.props.translator['Please specify Implementation Status !']}</strong></font>
                       </div>
                    :<div>
                    </div>}
                    {this.state.outrecdValidationError=="error" ?
                        <div>
                            <font color="red"><strong>{this.props.translator['Please mention outages received !']}</strong></font>
                        </div>
                    :<div>
                    </div>}
                    {this.state.exceptionValidationError=="error" ?
                        <div><font color="red"><strong>{this.props.translator['Please mention errors or exceptions received !']}</strong></font>
                        </div>
                    :<div>
                    </div>}
                    {this.state.expserviceValidationError=="error" ?
                        <div><font color="red"><strong>{this.props.translator['Please mention is the service operating as expected !']}</strong></font>
                        </div>
                    :<div>
                    </div>}
                    {this.state.desresultValidationError=="error" ?
                        <div><font color="red"><strong>{this.props.translator['Please mention desired results !']}</strong></font></div>
                    :<div></div>}
                    <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Post Implementation Review']}</div></div>
                    <div className='rBoxGap'>
                        <Form.Group className='form-group'
                        // validationState={this.state.statusValidationError}
                        >
                            <Form.Label><span className="rStar"></span> {this.props.translator['Implementation Status']}</Form.Label>
                                <Form.Select className={this.state.statusValidationError} as="select" onChange ={this.getStatus.bind(this)}>
                                    <option value="">Select</option>
                                    <option value="5">Successful</option>
                                    <option value="10">Successful with issues</option>
                                    <option value="15">Failed</option>
                                    <option value="20">Backed Out</option>
                                </Form.Select>
                        </Form.Group>
                        <Form.Group className='form-group'
                        // validationState={this.state.outrecdValidationError}
                        >
                            <Form.Label>{this.props.translator['Outages Received During the Change?']}</Form.Label>
                                <Form.Control className={this.state.outrecdValidationError} type="text" onChange ={this.getOutrecd.bind(this)}/>
                        </Form.Group>
                        <Form.Group className='form-group'
                        // validationState={this.state.exceptionValidationError}
                        >
                            <Form.Label>{this.props.translator['Errors or exceptions received during the change ?']}</Form.Label>
                                <Form.Control className={this.state.exceptionValidationError} type="text" onChange ={this.getException.bind(this)}/>
                        </Form.Group>
                        <Form.Group className='form-group'
                        // validationState={this.state.expserviceValidationError}
                        >
                            <Form.Label>{this.props.translator['Is the service operating as expected after the change?']}</Form.Label>
                                <Form.Control className={this.state.expserviceValidationError} type="text" onChange ={this.getExpservice.bind(this)}/>
                        </Form.Group>
                        <Form.Group className='form-group'
                        // validationState={this.state.desresultValidationError}
                        >
                            <Form.Label>{this.props.translator['Desired Results']}</Form.Label>
                                <Form.Control className={this.state.desresultValidationError} type="text" onChange ={this.getDesresult.bind(this)}/>
                        </Form.Group>
                        <ButtonToolbar className="margin-t-10">
                            <Button  bsSize="small" bsStyle="primary" onClick={()=>{this.postRequest();}}>{this.props.translator['Save']}</Button>
                            <Button  bsSize="small" bsStyle="primary" className="btn-text" onClick={()=>{this.postRequest();}}>{this.props.translator['Cancel']}</Button>
                        </ButtonToolbar>
                    </div>
                </div>
                );
            }else{
                return(
                    <div className="hambTab">
                        <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
                            <ul>
                                <li>
                                <button
                                    type='button'
                                    title="Minimize the right panel"
                                    bsClass=""
                                    bsStyle=""
                                    className="closerightPanelBtn"
                                    onClick={() => {
                                    this.props.rightEditPanel(false);
                                    }}
                                >
                                    <IoClose/>
                                </button>
                                </li>
                            </ul>
                        </div>
                        {this.state.statusValidationError=="error" ? <div><font color="red"><strong>{this.props.translator['Please specify Implementation Status !']}</strong></font></div>:<div></div>}
                        <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Post Implementation Review']}</div></div>
                        <div className='rBoxGap'>
                            <Form.Group
                            // validationState={this.state.statusValidationError}
                            >
                                <Form.Label><span className="rStar"></span> {this.props.translator['Implementation Status']}</Form.Label>
                                    <Form.Select className={this.state.statusValidationError} as="select" defaultValue={this.state.status}>
                                        <option value="">Select</option>
                                        <option value="5">Successful</option>
                                        <option value="10">Successful with issues</option>
                                        <option value="15">Failed</option>
                                        <option value="20">Backed Out</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>{this.props.translator['Outages Received During the Change?']}</Form.Label>
                                    <Form.Control type="text" value={this.state.outrecd}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>{this.props.translator['Errors or exceptions received during the change ?']}</Form.Label>
                                    <Form.Control type="text" value={this.state.exception}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>{this.props.translator['Is the service operating as expected after the change?']}</Form.Label>
                                    <Form.Control type="text" value={this.state.expservice}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>{this.props.translator['Desired Results']}</Form.Label>
                                    <Form.Control type="text" value={this.state.desresult}/>
                            </Form.Group>
                            <font color="green" ><b>{this.state.savedMessage}</b></font>
                        </div>
                    </div>
                );
            }
        }
    }
}
export default connect (null,{loadChangeEditData, loadBreakFixAuditLogDetails, loadStatusBasedHamburgerOptions, loadTimelineData})(ProvideAttachmentsTab);
