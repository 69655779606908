
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from "axios";
import { GLOBAL } from "../../components/Globals";
import { navigationHooks } from "../../helpers/NavigationHook";
import Swal from "sweetalert2";
const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});
export function loadDashboardList() {
  return (dispatch) => {
    dispatch({ type: "LOADER_IN_PROGRESS" });
    api
      .get('/getDashboardConfigData')
      .then((dashboardList) => {
        dispatch(loadCategoryListSuccess(dashboardList));
        dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
      })
      .catch((err) => {
        dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
        console.log("error in LoadDashboardList");
        console.log(err);
      });
  };
}
export function loadCategoryListSuccess(dashboardList) {
  return {
    type: "LOAD_DASHBOARD_CONFIG_LIST_SUCCESS",
    payload: dashboardList,
  };
}

export function createDashboardData(data) {
  return (dispatch) => {
    api
      .post('/createDashboardConfigData', data)
      .then((response) => {
        if (response.status == 200) {
          if (
            response.data.status === "This DashboardConfigData already exists."
          ) {
            Swal.fire({
              title: response.data.status,
              button: "OK",
            });
          } else {
            dispatch({ type: "DASHBOARD_RESET_FORM" });
            navigationHooks.navigate(GLOBAL.reportsConfiguration);
          }
        }
      })
      .catch((err) => {
        console.log("error in DashboardConfigData", err.response.data);
        if(err.response.data === "Duplicate entries present for requested report") {
          Swal.fire({
            title: "Duplicate Entry Already Exists",
            button: "OK",
          });
        }
      });
  };
}





export function updateDashboardData(data) {
   return (dispatch) => {
     api
       .patch("/updateDashboardConfigData", data)
       .then((response) => {
       
         if (response.status == 200) {
           if (
             response.data.status === "This DashboardConfigData already exists."
           ) {
             Swal.fire({
               title: response.data.status,
               button: "OK",
             });
           } else {
             dispatch({ type: "DASHBOARD_RESET_FORM" });
             navigationHooks.navigate(GLOBAL.reportsConfiguration);
           }
         }
       })
       .catch((err) => {
         console.log("error in DashboardConfigData", err.response.data);
         if(err.response.data === "Duplicate entries present for requested report") {
          Swal.fire({
            title: "Duplicate Entry Already Exists",
            button: "OK",
          });
        }
       });
   };
 }
export function editDashboardData(id) {
  return (dispatch) => {
    dispatch({ type: "LOADER_IN_PROGRESS" });
    api
      .get(`/editDashboardConfigData/${id}`)
      .then((editDetals) => {
        if (!editDetals) {
          throw Error(editDetals.statusText);
        }
        return editDetals;
      })
      .then((editDetals) => {
        dispatch(loadDashboardConfigEditSuccess(editDetals));
        dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
      })
      .catch((err) => {
        dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
        console.log("error in DashboardConfigEditSuccess");
        console.log(err);
      });
  };
}
export function loadDashboardConfigEditSuccess(editDetalsData) {
  return {
    type: "LOAD_DASHBOARD_CONFIG_EDIT_DETAILS_SUCCESS",
    payload:editDetalsData,
  };
}







