
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';


export const getIntegrationHubData = (state = initialState.integrationHubData, action) => {
    switch (action.type) {
        case 'LOAD_INTEGRATION_HUB_LIST_SUCCESS':
            return Object.assign({}, state, { integrationHubList: action.payload });
        case 'LOAD_INTEGRATION_EDIT_DETAILS_SUCCESS':
            return Object.assign({}, state, { initialEditValues: action.payload });
        case 'LOAD_SECNARIO_LIST_SUCCESS':
            return Object.assign({}, state, { scenarioDropDownList: action.payload && action.payload.sxhubscenaiomasterlist });
        case '@@redux-form/SET_SUBMIT_FAILED':
            return Object.assign({}, state, { integrationHubSubmitError: action.error });
        case 'RESET_FORM':
            return Object.assign({}, state, { integrationHubSubmitError: false, initialEditValues: {}});
        case 'OUTBOUND_CONNECTION_DELETED_SUCCESSFULLY':
            return Object.assign({}, state, { message: action.payload });
        case 'DELETE_IN_PROGRESS':
            return Object.assign({}, state, { message: action.payload });
        default:
            return state;
    }
}
/* 
export const categoryDropdown = (state = {}, action) => {
    switch (action.type) {
        case 'LOAD_CATEGORY_DROPDOWN_SUCCESS':
            return action.categoryDropdownList.data;
        default:
            return state;
    }
}

export const CategoriesByCompanyIdDropdown = (state = {}, action) => {
    switch (action.type) {
        case 'CATEGORIES_BY_COMPANY_ID':
            return action.categoryByCompanyIdDropdownList.data;
        case 'CATEGORIES_BY_COMPANY_ID_REFRESH':
            return "";
        default:
            return state;
    }
}

export const statusDropdown = (state = {}, action) => {
    switch (action.type) {
        case 'LOAD_STATUS_DROPDOWN_SUCCESS':
            return action.statusDropdownList.data;
        default:
            return state;
    }
}

export const categoryEditDetails = (state = {}, action) => {
    switch (action.type) {
        case 'LOAD_CATEGORY_EDIT_DETAILS_SUCCESS':
            return action.categoryEditDetails.data;
        case 'EMPTY_CATEGORY_EDIT_DETAILS_REDUCER':
            return {};
        case 'LOAD_CATEGORY_EDIT_DETAILS_SUCCESS_2':
            return action.payload;
        default:
            return state;
    }
}

export const associatedCompanies = (state = {}, action) => {
    switch (action.type) {
        case 'LOAD_COMPANY_ASSOCIATION_SUCCESS':
            return action.companyAssociationList.data;
        case 'EMPTY_COMPANY_ASSOCIATION_REDUCER':
            return {};
        default:
            return state;
    }
} */
