
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, change, SubmissionError } from 'redux-form';
import { _inputField, _dropDown } from '../../../common/ReduxFormFields/CategoryBoard/formFields';
// import { loadTagCompany, loadBusinessFunction, emptyBusinessFunction, loadSXMenulistData, loadAllCompositeTags, loadMemberTagsForCompositeTag } from '_Actions/foundation/tags/tagsAction';
import { _passwordField, _checkbox, _number, TypeaheadExampleSingleSelect } from '../../../common/formFields';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Link } from "react-router-dom";
import { loadAllScheduleList } from '../../../../actions/onCallSchedule/schedule/onCallScheduleAction';
import { loadShiftDetailsByShiftId } from "../../../../actions/onCallSchedule/shift/onCallShiftAction";
import { getOnCallGroups } from '../../../../actions/onCallSchedule/common/onCallCommonAction.js';
import ListLoader from "../../../common/loaders/ListLoader";
import Cookies from "universal-cookie";
import { openLinkInNewTab } from "../../../common/helper.js";

const cookies = new Cookies();
let app_url = cookies.get("gph");
if (app_url) app_url = app_url.replace("s:", "");
if (app_url) app_url = app_url.substring(0, app_url.lastIndexOf("."));
app_url = app_url.split("~");
app_url = app_url[18];

const validate = (values, props) => {
  const errors = {};

  if (!values.SHIFT_NAME)
    errors.SHIFT_NAME = "Required";
  else if (values.SHIFT_NAME.trim() === "")
    errors.SHIFT_NAME = 'Enter name';

  if (values.SUB_CATEGORY_NAME && values.SUB_CATEGORY_NAME.trim() === "")
    errors.SUB_CATEGORY_NAME = 'Enter SubCategoryName';

  if (!values.CATEGORY_ID)
    errors.CATEGORY_ID = "Required";

  return errors;
};
const ShiftEditForm = (props) => {
  const dispatch = useDispatch();
  const translator = useSelector((state) => state.spcmReducer.tr);
  const allScheduleList = useSelector((state) => state.on_call_schedule_reducer.allScheduleList);
  const [typedBusinessFn, setTypedBusinessFn] = useState('');
  const [typedSchedule, setTypedSchedule] = useState('');
  const [scheduleIdParam, setScheduleIdParam] = useState('');
  const showShiftListLoader = useSelector((state) => state.on_call_shift_loader_reducer.showShiftListLoader);

  const selector = formValueSelector('ShiftEditForm');
 

  useEffect(() => {
    let filter = { scheduleType: "Workday" };
    dispatch(getOnCallGroups());
    dispatch(loadAllScheduleList(filter, true, 'Active'))
    dispatch(loadShiftDetailsByShiftId(props.shiftId));

  }, []);
  useEffect(() => {
    if (props.initialValues && props.initialValues.shiftName) {
      props.setSelectedBusinessFn([{ id: props.initialValues.groupId, groupName: props.initialValues.groupName }]);
      dispatch(change('ShiftEditForm', 'GROUP_NAME', props.initialValues.groupName));
      dispatch(change('ShiftEditForm', 'GROUP_ID', props.initialValues.groupId));
      dispatch(change("ShiftEditForm", "SCHEDULE_ID", props.initialValues.scheduleId));
      dispatch(change('ShiftEditForm', 'SHIFT_NAME', props.initialValues.shiftName));
    }
  }, [props.initialValues]);

  useEffect(() => {
    if (props.initialValues && Object.keys(props.initialValues).length > 0 && allScheduleList?.length > 0) {
      let initialSchedule = allScheduleList.filter(schedule => schedule.scheduleId === props.initialValues.scheduleId);
      dispatch(change('ShiftEditForm', 'SCHEDULE_ID', initialSchedule.scheduleId));
      dispatch(change('ShiftEditForm', 'SCHEDULE_NAME', initialSchedule.scheduleName));
      if (initialSchedule.length != 0) {
        props.setSelectedSchedule([{ id: initialSchedule[0].scheduleId, scheduleName: initialSchedule[0].scheduleName }]);
        if (props.initialValues && props.initialValues.scheduleId) {
          setScheduleIdParam(props.initialValues.scheduleId);
      }
      }
    }
  }, [allScheduleList, props.initialValues]);


  const onCrossClickBusinessFn = () => {
    setTypedBusinessFn('');
    dispatch(change("ShiftEditForm", "GROUP_ID", ''));
    dispatch(change("ShiftEditForm", "GROUP_NAME", ''));
    props.setSelectedBusinessFn([]);
  };
  const onCrossClickScheduleFn = () => {
    setTypedSchedule('');
    dispatch(change("ShiftEditForm", "SCHEDULE_ID", ''));
    dispatch(change("ShiftEditForm", "SCHEDULE_NAME", ''));
    props.setSelectedSchedule([]);
  };

  const setBusinessFn = (selectedBusinessFn) => {
    if (selectedBusinessFn.length > 0) {
      props.setSelectedBusinessFn(selectedBusinessFn);
      dispatch(change("ShiftEditForm", "GROUP_ID", selectedBusinessFn[0].groupId));
      dispatch(change("ShiftEditForm", "GROUP_NAME", selectedBusinessFn[0].groupName));
    } else {
      props.setSelectedBusinessFn([]);
      dispatch(change("ShiftEditForm", "GROUP_ID", ''));
      dispatch(change("ShiftEditForm", "GROUP_NAME", ''));
    }
  };
  const handleSchedule = () => {
    allScheduleList.map(schedule => {
      if (schedule.scheduleId === props.initialValues.scheduleId) {
        dispatch(change('ShiftEditForm', 'SCHEDULE_ID', schedule.scheduleId));
        dispatch(change('ShiftEditForm', 'SCHEDULE_NAME', schedule.scheduleName));
        dispatch(change('ShiftEditForm', 'SHIFT_NAME', schedule.shiftName));
      }
      return null;
    });
  };
  const setSchedule = (selectedSchedule) => {
    if (selectedSchedule.length > 0) {
      props.setSelectedSchedule(selectedSchedule);
      dispatch(change("ShiftEditForm", "SCHEDULE_ID", selectedSchedule[0].scheduleId));
      dispatch(change("ShiftEditForm", "SCHEDULE_NAME", selectedSchedule[0].scheduleName));
    } else {
      props.setSelectedSchedule([]);
      dispatch(change("ShiftEditForm", "SCHEDULE_ID", ''));
      dispatch(change("ShiftEditForm", "SCHEDULE_NAME", ''));
    }
  };

  const businessFnInputChange = (value) => {
    setTypedBusinessFn(value);
    props.setSelectedBusinessFn([]);
  }

  const scheduleInputChange = (value) => {
    setTypedSchedule(value);
    props.setSelectedSchedule([]);
  }

  const countCharacters = (e) => {
    if (e.target.value.length > 50) {
      e.preventDefault();
    }
  }

  if (showShiftListLoader) {
    return <ListLoader />;
  }
  else {
    return (
      <>
        <Row>
          <Col md={6}>
            <Form.Group className='form-group'>
              <Form.Label bsClass="">
                <span className="rStar"></span>
                {translator["Name"]}
              </Form.Label>
              <Field
                name="SHIFT_NAME" className="form-control" component={_inputField}
                onPaste={(e) => { countCharacters(e); }}
                onChange={(e) => { countCharacters(e); }}
                disabled
              />
              <span className="f-size-10">{translator["Max Length in 50"]}</span>
            </Form.Group></Col>
          <Col md={6}>
            <Form.Group className="dvTypehdropdown-group">
              <Form.Label><span className="rStar"></span>{translator['Group']}</Form.Label>
              <div className="minHghtdvTypehd">
              <Field
                id="groupName"
                name="groupName"
                component={TypeaheadExampleSingleSelect}
                className="form-control"
                options={props.onCallGroupList}
                selectedOptions={props.selectedBusinessFn}
                onSelection={setBusinessFn}
                errorClass={props.businessFnError}
                diableStatus={false}
                onInputChange={businessFnInputChange}
                onCrossClick={onCrossClickBusinessFn}
                typedValue={typedBusinessFn}
                setErrorColor={props.setBusinessFnErrorColor}
                labelKey={"groupName"}
                placeholder={translator["Please choose..."]}
                disabled={true}

              />
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              <Form.Label><span className="rStar"></span>{translator['Schedule']}</Form.Label>
              <Link
                style={{ "margin-left": "5px" }}
                title={translator['Open record']}
                onClick={() => {
                  openLinkInNewTab(app_url + "/home?onCallScheduleEditSchedule=" + scheduleIdParam, "New Tab")
                }}
              ><i className="fa fa-external-link" aria-hidden="true"></i></Link>
              {/* <div style={{ display: 'flex', alignItems: 'center' }}> */}
              <div className="heightdvTypehd">
              <Field
                id="Schedule"
                name="Schedule"
                component={TypeaheadExampleSingleSelect}
                className="form-control"
                options={allScheduleList}
                selectedOptions={props.selectedSchedule}
                onSelection={setSchedule}
                errorClass={props.scheduleError}
                diableStatus={false}
                onInputChange={scheduleInputChange}
                onCrossClick={onCrossClickScheduleFn}
                typedValue={typedSchedule}
                setErrorColor={props.setScheduleErrorColor}
                labelKey={"scheduleName"}
                placeholder={translator["Please choose..."]}
              />
              </div>
              <Link
                to="/onCallSchedule/createSchedule"
                style={{ marginLeft: '5px', fontSize: '11.5px' }}
              >
                {translator["Can't find? Create a new schedule"]}
              </Link>
              {/* </div> */}
            </Form.Group>
          </Col>

        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  onCallGroupList: state.onCallGroupList,
  parentFunctionByCompanyIdDropdown: state.parentFunctionByCompanyIdDropdown,
  initialValues: state.on_call_shift_reducer.shiftDetailsByShiftId
});

export default reduxForm({
  form: 'ShiftEditForm',
  destroyOnUnmount: true,
  validate,
  enableReinitialize: true
})(connect(mapStateToProps)(ShiftEditForm));
