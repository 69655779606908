
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from "react-bootstrap/Form";
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import { _inputField, _textArea, _dropDown, TypeaheadandDropdown } from '../formFields';
// import { Row, Col, FormGroup, ControlLabel, Radio,FormControl} from 'react-bootstrap';
import {
	getCompanyData,
	getAssociatedCompanyData, getGroupData,
	getBreakfixGroupData, getIndividualData,
	getModuleData
} from '../../../actions/amsActions/amsActions.js';
import {
	getEventData,
	getCompanyService,
	getCompanyStatus,
	getParameterData, getClassData,
	getAssociatedCompanyListNew,
	getCIGroupData, resetFormDropdownValues,
	getGroupFunctionData
} from '../../../actions/amsActions/amsActions.js';
import { connect } from 'react-redux';
import {GLOBAL} from '../../Globals';

var regex= /^\d*[1-9]\d*$/;

const validate = values => {
	let errors = {};

	if (!values.ruleName) {
		errors.ruleName = 'Enter Rule Name.';
	}
	if (!values.companyStatus) {
		errors.companyStatus = 'Select  Status.';
	}
	if (!values.company) {
		errors.company = 'Select Company.';
	}
	if (!values.companyService) {
		errors.companyService = 'Select Company Service.';
	}
	if (!values.module) {
		errors.module = 'Select Module.';
	}
	if (!values.event) {
		errors.event = 'Select Assignment For.';
	}
	if (!values.weight) {
		errors.weight = 'Enter Weight.';
	}
	if (values.weight && !regex.test(values.weight)) {
		errors.weight = 'Invalid Weight.';
	}
	if(values.companyStatus && values.companyStatus == '1' && values.weight && (parseInt(values.weight,10) <= 0 || parseInt(values.weight,10) > 500)){
		errors.weight='Please enter values between 1 and 500';
	}
	if (!values.associatedCompany) {
		errors.associatedCompany = 'Select Assignment Company.';
	}
	if (!values.group||values.group=="null"||values.group==null) {
		errors.group = 'Select Assignment Group.';
	}
	if(values.isContextual && (!values.groupTypeId || values.groupTypeId==="")){
		errors.groupFunction="Select Group Function";
	}else if(!values.isContextual && (!values.group || values.group==="")){
		errors.group="Select Group";
	}
	if (typeof values.contextualGroup=== 'undefined' || values.contextualGroup === ''|| values.contextualGroup === null) {
		errors.contextualGroup = 'Select Support Group';
	}

	return errors;
};

let XsmAmsEditRulesFormTab = class XsmAmsEditRulesFormTab extends React.Component {

	constructor(props){
		super(props);
		this.state ={
            selectedAssociatedCompany:[],
			selectedGroups:[],
			selectedAlternateGroups:[],
			errorAssoCompany:false,
			errorGroup:false,
			typedAssociatedCompany:'',
			typedGroups: '',
			typedAlternateGroups: '',
			isContextual: false,
			selectedGroupFunction: [],
			typedGroupFunction: '',
			errorGroupFunction:false,
			supportGroup: [{field1Value: 'predefined',field1Key: 'Predefined'},
			   {field1Value: 'contextual',field1Key: 'Contextual'} ],
			contextualGroup: ''
		};
		this.onAssoCompanySelect=this.onAssoCompanySelect.bind(this);
		this.setAssoCompanyError=this.setAssoCompanyError.bind(this);
		this.onCrossClickAssoCompany=this.onCrossClickAssoCompany.bind(this);
		this.onGroupSelect=this.onGroupSelect.bind(this);
		this.setGroupError=this.setGroupError.bind(this);		
		this.onCrossClickGroup=this.onCrossClickGroup.bind(this);
		this.onAssoCompanyChange=this.onAssoCompanyChange.bind(this);
		this.onGroupChange=this.onGroupChange.bind(this);
		this.onGroupFunctionChange=this.onGroupFunctionChange.bind(this);
		this.onGroupFunctionSelect=this.onGroupFunctionSelect.bind(this);
		this.onCrossClickGroupFunction=this.onCrossClickGroupFunction.bind(this);
		this.setGroupFunctionError = this.setGroupFunctionError.bind(this);
		this.onSupportGroupChange = this.onSupportGroupChange.bind(this);
		this.onAltGroupSelect=this.onAltGroupSelect.bind(this);
		this.onCrossClickAltGroup=this.onCrossClickAltGroup.bind(this);
		this.onAltGroupChange=this.onAltGroupChange.bind(this);
	}
    onAssoCompanySelect(selectedAssociatedCompany){
        if(selectedAssociatedCompany.length>0){
			this.props.ruleConfiguration.companyService == null ? this.props.getCIGroupData(selectedAssociatedCompany[0].value,'AMScreate') : this.props.getGroupData(selectedAssociatedCompany[0].value,'AMScreate');
			this.state.typedAssociatedCompany='';
			this.state.typedGroups='';
			this.props.dispatch(change("xsmAmsEditRulesForm", "associatedCompanyName", selectedAssociatedCompany[0].label)); 
			this.props.dispatch(change("xsmAmsEditRulesForm", "associatedCompany", selectedAssociatedCompany[0].value));
			this.props.getGroupFunctionData(selectedAssociatedCompany[0].value);	
			
		} else {
            this.props.dispatch(change("xsmAmsEditRulesForm", "associatedCompanyName", '')); 
			this.props.dispatch(change("xsmAmsEditRulesForm", "associatedCompany", ''));
		}
		this.state.typedGroups=[];
		this.setState({selectedAssociatedCompany:selectedAssociatedCompany,selectedGroups:[], selectedAlternateGroups: [], typedAlternateGroups: '' });
		this.props.dispatch(change("xsmAmsEditRulesForm", "groupName", ""));
		this.props.dispatch(change("xsmAmsEditRulesForm", "group", ""));
		this.props.dispatch(change("xsmAmsEditRulesForm", "alternateGroupName", ""));
		this.props.dispatch(change("xsmAmsEditRulesForm", "alternateGroup", ""));

		this.props.updateIsChange(true);
	}
	setAssoCompanyError(){
        if(this.state.selectedAssociatedCompany.length===0){
            this.setState({errorAssoCompany:true});
		}else{
            this.setState({errorAssoCompany:false});
		}
	}
	onCrossClickAssoCompany(){
		this.props.dispatch(change("xsmAmsEditRulesForm", "associatedCompanyName", '')); 
		this.props.dispatch(change("xsmAmsEditRulesForm", "associatedCompany", ''));
		this.props.dispatch(change("xsmAmsCreateRulesForm","group",''));
		this.props.dispatch(change("xsmAmsCreateRulesForm","groupName",''));
		this.props.dispatch(change("xsmAmsEditRulesForm", "alternateGroupName", ""));
		this.props.dispatch(change("xsmAmsEditRulesForm", "alternateGroup", ""));
		this.setState({selectedAssociatedCompany:[],typedAssociatedCompany:'',typedGroups:'',selectedGroups:[], selectedAlternateGroups: [], typedAlternateGroups: '' });
		let typeaheadData=[];
		this.props.dispatch({ type: 'GROUP_LIST_TYPE', typeaheadData });
		this.onCrossClickGroupFunction();
	}
	onAssoCompanyChange(value){
		this.setState({typedAssociatedCompany:value,selectedGroups:[],typedGroups:'', selectedAlternateGroups: [], typedAlternateGroups: '' });
		this.props.dispatch(change("xsmAmsEditRulesForm", "alternateGroupName", ""));
		this.props.dispatch(change("xsmAmsEditRulesForm", "alternateGroup", ""));
		let typeaheadData=[];
		this.props.dispatch({type:'GROUP_LIST_TYPE',typeaheadData});
	}
	onGroupSelect(selectedGroups) {
		if (selectedGroups.length > 0) {
			if (selectedGroups[0].value != '')
				this.props.getIndividualData(selectedGroups[0].value, this.props.associatedCompIdParam);
			this.props.dispatch(change("xsmAmsEditRulesForm", "groupName", selectedGroups[0].label));
			this.props.dispatch((change("xsmAmsEditRulesForm", "group", selectedGroups[0].value)));
			this.state.typedGroups = '';
			GLOBAL.isExternalAmsEdit = selectedGroups[0].isExternal;
		} else {
			this.props.dispatch(change("xsmAmsEditRulesForm", "groupName", ''));
			this.props.dispatch((change("xsmAmsEditRulesForm", "group", '')));
			GLOBAL.isExternalAmsEdit = "";
		}
	   this.setState({selectedGroups:selectedGroups});

	   this.props.updateIsChange(true);
	}
	setGroupError(){
       if(this.state.selectedGroups.length===0){
           this.setState({errorGroup:true});
	   }else{
		   this.setState({errorGroup:false}); 
	   }
	}
	onCrossClickGroup(){
        this.props.dispatch(change("xsmAmsEditRulesForm", "groupName",''));
		this.props.dispatch((change("xsmAmsEditRulesForm","group",'')));
		this.setState({selectedGroups:[],typedGroups:''});
	}
	onGroupChange(value){
		this.setState({typedGroups:value});
	}
	onGroupFunctionChange(value) {
		this.setState({ typedGroupFunction: value, selectedGroupFunction:[] });
		this.props.dispatch(change("xsmAmsEditRulesForm", "contextualGroupFunctionName", ''));
		this.props.dispatch(change("xsmAmsEditRulesForm","groupTypeId",''));
	}
	onGroupFunctionSelect(selectedGroupFunctionValue) {
		this.state.typedGroupFunction = '';
		if (selectedGroupFunctionValue.length > 0){
		this.props.dispatch(change("xsmAmsEditRulesForm", "contextualGroupFunctionName", selectedGroupFunctionValue[0].label));
		this.props.dispatch(change("xsmAmsEditRulesForm", "groupTypeId", selectedGroupFunctionValue[0].value));
		}else{
			this.props.dispatch(change("xsmAmsEditRulesForm", "contextualGroupFunctionName",''));
			this.props.dispatch(change("xsmAmsEditRulesForm", "groupTypeId",''));
		}
		this.setState({ selectedGroupFunction: selectedGroupFunctionValue });
		this.props.updateIsChange(true);
	}
	onCrossClickGroupFunction() {
		this.props.dispatch(change("xsmAmsEditRulesForm", "contextualGroupFunctionName",''));
		this.props.dispatch(change("xsmAmsEditRulesForm", "groupTypeId",''));
		this.setState({ selectedGroupFunction: [], typedGroupFunction:'' })
	}
	setGroupFunctionError() {
		if (this.state.selectedGroupFunction.length === 0) {
			this.setState({errorGroupFunction: true});
		}else{
			this.setState({errorGroupFunction: false});
		}
	}
	onSupportGroupChange(value) {
		this.props.dispatch(change("xsmAmsEditRulesForm", "alternateGroupName", ""));
		this.props.dispatch(change("xsmAmsEditRulesForm", "alternateGroup", ""));
		this.setState({
			selectedAlternateGroups: [],
			typedAlternateGroups: ''
		});
		if (value === 'predefined') {
			this.props.dispatch(change("xsmAmsEditRulesForm", "isContextual", false));
			this.setState({
				isContextual: false,
				selectedGroupFunction: [],
				selectedGroups: [],
				typedGroupFunction: '',
				typedGroups: ''
			})
			this.props.dispatch(change("xsmAmsEditRulesForm", "groupFunctionName", ''));
			this.props.dispatch(change("xsmAmsEditRulesForm", "contextualGroup", ''));
			// this.props.dispatch(change("xsmAmsEditRulesForm", "group", ''));
			// this.props.dispatch(change("xsmAmsEditRulesForm", "groupName", ''));
		} else {
			this.props.dispatch(change("xsmAmsEditRulesForm", "isContextual", true));
			// this.props.dispatch(change("xsmAmsEditRulesForm", "contextualGroup", 'Requestor.Location.Group'));
			if(this.props.ruleConfiguration.module === '25'){
				this.setState({
					isContextual: true,
					selectedGroupFunction: [],
					selectedGroups: [],
					typedGroupFunction: '',
					typedGroups: '',
					contextualGroup: 'Requestor.Location.Group',
					selectedAlternateGroups: [], typedAlternateGroups: ''
				})
			}
			else {
				this.setState({
					isContextual: true,
					selectedGroupFunction: [],
					selectedGroups: [],
					typedGroupFunction: '',
					typedGroups: '',
					contextualGroup: '',
					selectedAlternateGroups: [], typedAlternateGroups: ''
				})
			}
			this.props.dispatch(change("xsmAmsEditRulesForm", "alternateGroupName", ""));
			this.props.dispatch(change("xsmAmsEditRulesForm", "alternateGroup", ""));
			this.onCrossClickGroup();
			this.props.dispatch(change("xsmAmsEditRulesForm", "groupFunctionName", ''));
			this.props.dispatch(change("xsmAmsEditRulesForm", "groupTypeId",''));
			if(this.props.ruleConfiguration.module === '25') {
				this.props.dispatch(change("xsmAmsEditRulesForm", "contextualGroup", 'Requestor.Location.Group'));
			}
			else {
				this.props.dispatch(change("xsmAmsEditRulesForm", "contextualGroup", ''));
			}
			this.onCrossClickGroupFunction();
		}
	}
	componentWillMount() {     
		this.props.resetFormDropdownValues();
		this.props.getCompanyData();
		this.props.getModuleData();
		this.props.getCompanyStatus();
		if (this.props.ruleConfiguration.isExternal == null || this.props.ruleConfiguration.isExternal == '' || this.props.ruleConfiguration.isExternal == 'undefined') {
			GLOBAL.isExternalAmsEdit = false;
		} else {
			GLOBAL.isExternalAmsEdit = this.props.ruleConfiguration.isExternal;
		}

		this.props.getAssociatedCompanyListNew(this.props.ruleConfiguration.company,this.props.ruleConfiguration.companyName,'AMScreate');
		this.props.getCIGroupData(this.props.ruleConfiguration.associatedCompany,'AMScreate');
        this.props.getGroupFunctionData(this.props.ruleConfiguration.associatedCompany);	
		this.props.getCompanyService(this.props.ruleConfiguration.company);
		this.props.getClassData(this.props.ruleConfiguration.company);
		this.props.getEventData(this.props.ruleConfiguration.moduleName);
		this.props.getIndividualData(this.props.ruleConfiguration.group,this.props.ruleConfiguration.associatedCompany);
		this.props.getParameterData(this.props.ruleConfiguration.moduleName);
		this.state.selectedAssociatedCompany=[{
		   value: typeof this.props.ruleConfiguration.associatedCompany==='undefined'?'':this.props.ruleConfiguration.associatedCompany,
		   label: typeof this.props.ruleConfiguration.associatedCompanyName==='undefined'?'':this.props.ruleConfiguration.associatedCompanyName
	    }];
		this.setState({selectedGroups:[{
           value: typeof this.props.ruleConfiguration.group==='undefined'?'':this.props.ruleConfiguration.group,
		   label: typeof this.props.ruleConfiguration.groupName==='undefined'?'':this.props.ruleConfiguration.groupName
		}]
		});

		let altGroupId = this.props.ruleConfiguration.alternateGroup || '';
		let altGroupName = this.props.ruleConfiguration.alternateGroupName || '';
		if(altGroupId && altGroupName){
			this.setState({
				selectedAlternateGroups: [{ 
					value: altGroupId,
					label: altGroupName
				}]
			});
		}
		this.props.dispatch(change("xsmAmsEditRulesForm", "alternateGroup", altGroupId ));
		this.props.dispatch(change("xsmAmsEditRulesForm", "alternateGroupName", altGroupName ));

		this.setState({
			isContextual: this.props.ruleConfiguration.isContextual
		})
		if (this.props.ruleConfiguration.isContextual == true) {
			this.props.dispatch(change("xsmAmsEditRulesForm", "contextualGroup", this.props.ruleConfiguration.groupName));
			this.setState({
				selectedGroupFunction: [{
					label: typeof this.props.ruleConfiguration.contextualGroupFunctionName === 'undefined' || this.props.ruleConfiguration.contextualGroupFunctionName === null ? '' : this.props.ruleConfiguration.contextualGroupFunctionName,
				    value: typeof this.props.ruleConfiguration.groupTypeId==='undefined' || this.props.ruleConfiguration.groupTypeId === null?'':this.props.ruleConfiguration.groupTypeId
				}],
				isContextual: this.props.ruleConfiguration.isContextual,
				contextualGroup: this.props.ruleConfiguration.contextualGroup
			})
		 }
	}

	onCrossClickAltGroup(){
        this.props.dispatch(change("xsmAmsEditRulesForm", "alternateGroupName",''));
		this.props.dispatch((change("xsmAmsEditRulesForm","alternateGroup",'')));
		this.setState({selectedAlternateGroups:[],typedAlternateGroups:''});
	}
	onAltGroupChange(value){
		this.setState({typedAlternateGroups:value});
	}

	onAltGroupSelect(selectedGroups) {
		if (selectedGroups.length > 0) {
			this.props.dispatch(change("xsmAmsEditRulesForm", "alternateGroupName", selectedGroups[0].label));
			this.props.dispatch((change("xsmAmsEditRulesForm", "alternateGroup", selectedGroups[0].value)));
		
			this.setState({typedGroups: '' });
		} else {
			this.props.dispatch(change("xsmAmsEditRulesForm", "alternateGroupName", ''));
			this.props.dispatch((change("xsmAmsEditRulesForm", "alternateGroup", '')));
		}
	   this.setState({selectedAlternateGroups:selectedGroups});
	}

	render() {
		const tr = this.props.tr;
		let contextualGroups = [
			{label: 'Requestor.Location Group', value: 'Requestor.Location.Group'},
			{label: 'CI.Support Group', value: 'CI.Support.Group'}
		];
		return (
			<form>
				<div>
				<div className="borderForm bFormDiv">
						<Row>
							<Col md={6}>
								<Form.Group>
									<Form.Label bsClass=""> {tr['Rule Name']}</Form.Label>
									<Field
											name="ruleName"
											component={_inputField}
											label=""
											className="form-control"
											readOnly
									/>
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group>
									<Form.Label bsClass=""><span className="rStar" /> {tr['Status']}</Form.Label>
									<Field
											name="companyStatus"
											component={_dropDown}
											options={this.props.companyStatus}
											draftEnable={this.props.ruleConfiguration.companyStatus==='1' || this.props.ruleConfiguration.companyStatus==='0'?false:true}
											className="form-control"
											onChange={(event) => {
												this.props.dispatch(change("xsmAmsEditRulesForm", "companyStatusName", event.target[event.target.selectedIndex].text));
												this.props.updateIsChange(true);
											}}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col md={6}>
								<Form.Group>
									<Form.Label bsClass=""> {tr['Consumer Company']}</Form.Label>
									<Field
											name="companyName"
											component={_inputField}
											label=""
											className="form-control"
											readOnly
									/>
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group>
									<Form.Label bsClass=""> {tr['Module']}</Form.Label>
									<Field
											name="moduleName"
											component={_inputField}
											label=""
											className="form-control"
											readOnly
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col md={6}>
								<Form.Group>
									<Form.Label bsClass=""> {tr['Assignment For']}</Form.Label>
									<Field
											name="eventName"
											component={_inputField}
											label=""
											className="form-control"
											readOnly
									/>
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group>
									<Form.Label bsClass=""><span className="rStar" /> {tr['Weight']}</Form.Label>
									<Field
											name="weight"
											type="number"
											component={_inputField}
											label=""
			                                readOnly={this.props.ruleConfiguration.companyStatus==="1"?true:false}
											className="form-control"
											onChange={(event) => {
												this.props.updateIsChange(true);
											}}
									/>
								</Form.Group>
							</Col>
						</Row>



				<div className="padding-t-10 padding-r-10 padding-l-10 margin-b-15 border">
                    <Row>
                       <Col md={6}>
                        <div className="bgGray padding-5"><span className="rStar" /> {tr['Support Company']}</div>
                    <Row>
                    <Col md={12}>
                      <Form.Group validationState={this.state.errorAssoCompany?'error':''}>
					  <div className="dvTypehdropdown">
                            <Field
                                name="associatedCompany"
                                component={TypeaheadandDropdown}
                                options={this.props.associatedCompanyDataTypeahead}
                                onSelection={this.onAssoCompanySelect}
                                selectedOptions={this.state.selectedAssociatedCompany}
                                setErrorColor={this.setAssoCompanyError}
                                diableStatus={this.props.ruleConfiguration.companyStatus==="1"?true:false}
                                onCrossClick={this.onCrossClickAssoCompany}
                                onInputChange={this.onAssoCompanyChange}
                                typedValue={this.state.typedAssociatedCompany}
                                // onChange={(event)=>{if(this.props.moduleIdParam != 5 && event.target.value != ''){this.state.getCISupportGroup ? this.props.getCIGroupData(event.target.value) : this.props.getGroupData(event.target.value);} if(this.props.moduleIdParam == 5 && event.target.value != ''){this.state.getCISupportGroup ? this.props.getCIGroupData(event.target.value) : ((this.props.serviceIdParam && this.props.serviceIdParam !='') ? this.props.getBreakfixGroupData(this.props.serviceIdParam) : '');} this.props.dispatch(change("xsmAmsCreateRulesForm", "associatedCompanyName", event.target[event.target.selectedIndex].text)); this.props.dispatch(change("xsmAmsCreateRulesForm", "groupName", "")); this.props.dispatch(change("xsmAmsCreateRulesForm", "group", ""));}}
                                className="form-control"
                            />
							</div>
                        </Form.Group>
                       </Col>
                    </Row>
                   </Col>
                 <Col md={6}>
                 <div className="bgGray padding-5"><span className="rStar" /> {tr['Support Group']} </div>
      
                 <Row>
                 <Col md={12}>
                 <Form.Group className='slectInp'>
				{this.props.ruleConfiguration.module!=="15" && this.props.ruleConfiguration.module!=="10"?
				            <Field name="contextualDropdown"
							       component={_dropDown}
								   className='sleinput grayGradi'
								   options={this.state.supportGroup}
											 onChange={(event)=>{ 
												this.onSupportGroupChange(event.target.value);
											 }}
								   disabled={this.props.ruleConfiguration.companyStatus==="1"?true:false}	 
				/>:''}
                {this.state.isContextual === false ?
						<div className='inpt'>
				    <Form.Group validationState={this.state.errorGroup ? 'error' : ''}>
					<div className="dvTypehdropdown">
                        <Field
                                name="group"
                                component={TypeaheadandDropdown}
                                options={this.props.groupDataTypeahead}
                                onSelection={this.onGroupSelect}
                                selectedOptions={this.state.selectedGroups}
                                setErrorColor={this.setGroupError}
                                onCrossClick={this.onCrossClickGroup}
                                diableStatus={this.props.ruleConfiguration.companyStatus==="1"?true:false}
                                onInputChange={this.onGroupChange}
                                typedValue={this.state.typedGroups}
                                //onChange={(event)=>{ event.target.value != '' ? this.props.getIndividualData(event.target.value,this.props.associatedCompIdParam) :''; this.props.dispatch(change("xsmAmsCreateRulesForm", "groupName", event.target[event.target.selectedIndex].text))}}
                                className="form-control"/>
								</div>
							</Form.Group>
						       </div> :
							<div className='inpt'>
							<Form.Group>
							{this.props.ruleConfiguration.module !== '25' ?
							<Field
								name="contextualGroup"
								component={_dropDown}
								options={contextualGroups}
								className="form-control"
								onChange={(event) => {
									this.props.dispatch(change("xsmAmsEditRulesForm", "contextualGroup", event.target.value));
									this.props.dispatch(change("xsmAmsEditRulesForm","groupTypeId","0"));
									this.setState({ contextualGroup: event.target.value })
									this.onCrossClickGroupFunction();
								}}
								disabled={this.props.ruleConfiguration.companyStatus==="1"?true:false}
							/> : 
							<Field
							component={_inputField}
							className="form-control"
							name='contextualGroup'
							placeholder='Requestor.Location.Group'
							readOnly
							/>}
                        </Form.Group></div>}
            </Form.Group>
            </Col>
      </Row>
    </Col>
</Row>


<Row>
  {(this.state.isContextual === true) && <Col md={6}>
      <div className="bgGray padding-5">{tr['Alternate Group']}</div>
      <Row>
        <Col md={12}>
			<Form.Group>
			<div className='inpt'><Form.Group>
			<div className="dvTypehdropdown">
					<Field
						name="alternateGroup"
						component={TypeaheadandDropdown}
						options={this.props.groupDataTypeahead}
						onSelection={this.onAltGroupSelect}
						selectedOptions={this.state.selectedAlternateGroups}
						// setErrorColor={this.setGroupError}
						onCrossClick={this.onCrossClickAltGroup}
						diableStatus={this.props.ruleConfiguration.companyStatus==="1"?true:false}
						onInputChange={this.onAltGroupChange}
						typedValue={this.state.typedAlternateGroups}
						className="form-control"/>
						</div>
				</Form.Group>
			</div>
			</Form.Group>
        </Col>
      </Row>
  </Col>}
  {( (this.props.ruleConfiguration.module!=='15' && this.props.ruleConfiguration.module!=='10') && this.state.isContextual && this.state.contextualGroup === 'Requestor.Location.Group' ) && <Col md={6}>
      <div className="bgGray padding-5"><span className="rStar" /> {tr['Group Function']} </div>
      <Row>
			<Col md={12}>
				<Form.Group validationState={this.state.errorGroupFunction?'error':''}>
				<div className="dvTypehdropdown">
					<Field
						name="groupFunction"
						component={TypeaheadandDropdown}
						options={this.props.groupFunctionDataTypeahead}
						onSelection={this.onGroupFunctionSelect}
						selectedOptions={this.state.selectedGroupFunction}
						setErrorColor={this.setGroupFunctionError}
						diableStatus={this.props.ruleConfiguration.companyStatus==="1"?true:false}
						onCrossClick={this.onCrossClickGroupFunction}
						onInputChange={this.onGroupFunctionChange}
						typedValue={this.state.typedGroupFunction}
						className="form-control"
					/>
					</div>
				</Form.Group>
			</Col>
      </Row>
    </Col>}
</Row>

</div>
					</div>
				</div>
			</form>
		);
	}
};

const mapStateToProps = ({companyData,groupFunctionDataTypeahead,associatedCompanyDataTypeahead,groupDataTypeahead,individualData,moduleData,eventData,ruleConfiguration,ruleCondition,companyStatus,companyService,allRuleConditions}) => {
	return{
		companyData,
		groupFunctionDataTypeahead,
		associatedCompanyDataTypeahead,
		groupDataTypeahead,
		individualData,
		moduleData,
		eventData,
		ruleConfiguration,
		ruleCondition,
		companyStatus,
		companyService,
		allRuleConditions,
		initialValues:ruleConfiguration
	}
}

XsmAmsEditRulesFormTab = reduxForm({
	form: 'xsmAmsEditRulesForm',
	validate,
	enableReinitialize: true
})(XsmAmsEditRulesFormTab);

const selector = formValueSelector('xsmAmsEditRulesForm');
XsmAmsEditRulesFormTab = connect((state)=> {
	let associatedCompIdParam = selector(state,'associatedCompany')
	let moduleIdParam = selector(state,'module')
	let serviceIdParam = selector(state,'companyService')
	return{
		associatedCompIdParam,moduleIdParam,serviceIdParam
	}
})(XsmAmsEditRulesFormTab);

export default connect(mapStateToProps, {
	getGroupFunctionData,getCompanyData,getAssociatedCompanyData,getGroupData,getBreakfixGroupData,getIndividualData,getModuleData,getEventData,getCompanyService,getCompanyStatus,getParameterData,getClassData,getAssociatedCompanyListNew,getCIGroupData,resetFormDropdownValues
})(XsmAmsEditRulesFormTab);
