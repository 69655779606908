
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React ,{ useState ,useEffect} from 'react'
import { getAuditLogsNEData } from "../../../../actions/spcmActions";
import { Popover, Card, Button, OverlayTrigger } from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { ImInfo } from "react-icons/im";
import { useSelector ,useDispatch} from 'react-redux';
import XsmUserDetails from '_Commons/WorkItemBoard/xsmEditRequestedBy.js';
import Spinner from "react-spinkit";
import moment from 'moment';
import momenttz from "moment-timezone";
import { GLOBAL } from '_Globals';
import ListLoader from '_Commons/loaders/ListLoader';
import Cookies from "universal-cookie";
import datetimeConvertor from "../../../../helpers/ISO8601converter.js";
const cookies = new Cookies();
// import  _ from 'underscore'
let timezone = cookies.get("gph");
if (timezone) timezone = timezone.replace("s:", "");
if (timezone) timezone = timezone.substring(0, timezone.lastIndexOf("."));
timezone = timezone.split("~");
timezone = timezone[3];
import NotificationAuditTemplate from './NotificationAuditTemplate.js';
import CloseBtnRightPanel from '../../../common/closeBtnRightPanel.js';
let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
//console.log("homepage::::"+homepagelocation);
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

let cookiesArray = cookies.get('gph');
if (cookiesArray) cookiesArray = cookiesArray.replace('s:', '');
if (cookiesArray) cookiesArray = cookiesArray.substring(0, cookiesArray.lastIndexOf('.'));
cookiesArray = cookiesArray.split("~");
let langDir = cookiesArray[66];

function NotificationAuditLogs(props) {

    let auditLogsData=useSelector(state=>state.auditLogNEReducer)
    const translator = useSelector((state) => state.spcmReducer.tr);
    const windowSize = useSelector((state) => state.windowSize.width);
    const dispatch = useDispatch();

    let templateId=props.notificationData[0]?.TEMPLATE_ID
    let audit_type=props.notificationData[0]?.COMPANY_ID == '0' ? 'ne_templates' : 'ne_custom_templates'
    

  useEffect(()=>{ 
    dispatch(getAuditLogsNEData(templateId,audit_type))
  },[])

  const renderAuditLogsData=(auditData)=>{
    return (
      <div id="brkAudLogcomm" className="actCommts">
        {auditData.map((comment, childKey) => {

          let actDateTimeFormat = jwtdf + " HH:mm:ss";
          let myDateObj,
              endDate,
              endDateStr,
              timeago,
              startTime,
              duration,
              hours,
              isoDate;
              let date = momenttz(comment?.MODIFIED_DATE).tz(timezone).format(jwtdf + " HH:mm:ss");
              isoDate = datetimeConvertor(date, jwtdf + " HH:mm:ss");
              myDateObj = moment(isoDate).format(actDateTimeFormat);
              endDate = moment(myDateObj, actDateTimeFormat);
              endDate = moment(myDateObj, actDateTimeFormat);
              endDateStr = endDate._i;
              startTime = moment(
                  new Date().toLocaleString("en-US", { timeZone: timezone })
              );
              timeago = moment(endDateStr, actDateTimeFormat).from(startTime);
              duration = moment.duration(startTime.diff(endDate));
              hours = duration.asHours();  

          const tooltip = (
              <Popover className="userInfoPopup">
              <XsmUserDetails userId={parseInt(comment.MODIFIEDBY,10)} translator={translator}  isHeadingVisible={false} isPopup={true} />
              </Popover>
          );
            return (

              <Card className="actlogCrd" bsPrefix=" " key={childKey}>
                  <div className="circleTxt">
                  <div className="subhed">
                  {comment.AUDIT_FIELD !== "System" ? <OverlayTrigger trigger="click" rootClose placement={windowSize < 768 ? "auto-start" : (langDir === "rtl" ? "right-start" : "left-start")
} overlay={tooltip}><span className="circleNa" title={comment.MODIFIEDBY_NAME}>{comment.MODIFIEDBY_NAME} <ImInfo/></span></OverlayTrigger> : <span className="margin-r-20">{comment.MODIFIEDBY_NAME}</span>}
                    <span
                      className="margin-l-5 timeao"
                      title={hours >= 24 ? "" : endDateStr}
                    >
                      {hours >= 24 ? endDateStr : timeago}
                    </span>
                  </div>
                  <div className="desFld">
                      {comment.FIELD != 'TEMPLATE' && <div title="Field Name" className="fenme">
                        {comment?.FIELD}
                  </div>}
                  {comment?.FIELD == 'BODY TEXT' ? (
                    <>
                    <NotificationAuditTemplate oldVal={comment?.FROM} newVal={comment?.TO} />
                    </>
                  ):(
                    <>
                    <div title="New Value" className="nwnme commn">
                    {comment?.FIELD != 'BODY TEXT' && comment?.AUDIT_EVENT != 'CREATE' && comment?.TO }
                    {comment?.FIELD != 'BODY TEXT' && comment?.AUDIT_EVENT == 'CREATE' && "Created" }
                    </div>
                    <div title="Old Value" className="oldnme commn">
                    {comment?.FIELD != 'BODY TEXT' && comment?.FROM }
                    </div>
                    </>
                  )}
                  </div>
                  </div>
                  {/* <div className='f-size-12' style={{"marginTop":"2px"}}>{timeago}</div> */}
                </Card>
            );

        })}
      </div>
    );

  }

  return (

   <>
    <CloseBtnRightPanel showRightSide={props.rightEditPanel} />
     <div className="rPageHeading">
          <div className="offNam margin-t-5 margin-b-5">
            {translator["Activity Details"]}
          </div>
        </div>
      <div className="rBoxGap">
      <div className="rBoxTabs">
      <Tabs
              activeKey={1}
              // onSelect={this.handleSelect}
              id="tabId"
            >
            <Tab eventKey={1} title={translator["Audit Log"]}>
            <div id="cmdbAudLog" className="actCommts">
              {auditLogsData?.length >0 ? renderAuditLogsData(auditLogsData) : "No Data Found"}
          </div>
              </Tab>
      </Tabs>
      </div>
          
      </div>

    
    
   </>

  )
}

export default NotificationAuditLogs
