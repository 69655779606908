
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Table, ButtonToolbar } from 'react-bootstrap';
import { Field, reduxForm, SubmissionError, change, formValueSelector, untouch, reset } from "redux-form";
import { _textArea, _dropDown, _inputField, _number, _dynamicNamedropDown } from "../../../common/formFields.js";
import { useDispatch, useSelector, connect } from 'react-redux';
import { useNavigate } from "react-router";
import axios from 'axios';
import { PiPencilSimpleBold } from "react-icons/pi";
import { HiPlus } from "react-icons/hi"
import { IoClose } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import ListLoader from "../../../common/loaders/ListLoader.js"
import { loadRosterMembersList, deleteRosterMembersData } from '_Actions/onCallSchedule/roster/onCallRosterAction';
import { onCallGroupUsersByGroupId } from "_Actions/onCallSchedule/common/onCallCommonAction";
import swal from 'sweetalert';
import {ImSpinner6} from "react-icons/im";

const RightSide = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const translator = useSelector((state) => state.spcmReducer.tr);
    const rosterDetailsByRosterId = useSelector((state) => state.on_call_roster_reducer.rosterDetailsByRosterId);
    const on_call_roster_loader_reducer = useSelector((state) => state.on_call_roster_loader_reducer);
    const rosterMembersListReducer = useSelector((state) => state.on_call_roster_members_reducer.rosterMembersList);
    const onCallGroupUsersList = useSelector((state) => state.onCallGroupUsersList);

    const [showListOrAdd, setShowListOrAdd] = useState('list');
    const [createUsersList, setCreateUsersList] = useState([]);
    const [createUsersFilteredList, setCreateUsersFilteredList] = useState([]);
    const [rosterMembersList, setRosterMembersList] = useState([]);
    const [rosterMemberIdArray, setRosterMemberIdArray] = useState([]);
    const [selectedMemberCountForDelete, setSelectedMemberCountForDelete] = useState(0);
    const [saveLoader, setSaveLoader] = useState(false);

    useEffect(() => {
        dispatch(loadRosterMembersList(props.rosterId));
    }, []);

    useEffect(() => {
        if(rosterDetailsByRosterId && rosterDetailsByRosterId.groupId)
            dispatch(onCallGroupUsersByGroupId(rosterDetailsByRosterId.groupId));
    }, [rosterDetailsByRosterId]);

    useEffect(() => {
        let membersList = [];
        let memberIdArray = [];
        let deleteRosterMemberIdArray = [];
        rosterMembersListReducer.forEach((user) => {
            membersList.push({ memberId: user.memberId, memberName: user.memberName, rmId: user.rmId, checked: false });
            memberIdArray.push(user.memberId);
            deleteRosterMemberIdArray.push({})
        });
        setRosterMembersList(membersList);
        setRosterMemberIdArray(memberIdArray);
    }, [rosterMembersListReducer]);

    function loadGroupUsersList() {
        if (onCallGroupUsersList && onCallGroupUsersList.length > 0) {
            let onCallGroupNewUsersList = onCallGroupUsersList.filter((item) => !rosterMemberIdArray.includes(parseInt(item.field1Value, 10)));
            let usersList = [];
            let usersFilteredList = [];
            onCallGroupNewUsersList.forEach((user) => {
                usersList.push({ id: user.field1Value, name: user.field1Key, checked: false });
                usersFilteredList.push({ id: user.field1Value, name: user.field1Key, checked: false });
            });
            setCreateUsersList(usersList);
            setCreateUsersFilteredList(usersFilteredList);
        }
    }

    function onDeleteClick() {
        let userIdArr = [];
        rosterMembersList.forEach((item) => {
            if (item.checked === true)
                userIdArr.push(item.rmId);
        });
        let rmIdObj = {};
        rmIdObj.userIds = userIdArr;
        rmIdObj.groupId = rosterDetailsByRosterId.groupId;
        rmIdObj.shiftId = rosterDetailsByRosterId.shiftId;
        dispatch(deleteRosterMembersData(rmIdObj, () => {
            dispatch(loadRosterMembersList(props.rosterId));
            setSelectedMemberCountForDelete(0);
        }))
    }

    function onListCheckboxClick(rmId, newCheckedStatus) {
        let arr = structuredClone(rosterMembersList);
        arr.forEach((item) => {
            if (item.rmId == rmId) {
                item.checked = newCheckedStatus;
            }
        });
        setRosterMembersList(arr);
        let count = 0;
        arr.forEach((item) => {
            if (item.checked === true)
                count++;
        })
        setSelectedMemberCountForDelete(count);
    }

    function resetSelectedMemberCountForDelete() {
        let arr = structuredClone(rosterMembersList);
        arr.forEach((item) => item.checked = false);
        setRosterMembersList(arr);
        setSelectedMemberCountForDelete(0);
    }

    function renderRosterMembersList() {
        if (on_call_roster_loader_reducer.showRosterMembersLoader) {
            return <ListLoader />;
        }
        else if (rosterMembersList.length > 0) {
            return rosterMembersList.map((item, i) => {
                return (
                    <li>
                        <label for={"rosterMembersList_" + i} className="playlist-name">
                            <input name={"rosterMembersList_" + i} type="checkbox" checked={item.checked} onClick={() => { onListCheckboxClick(item.rmId, !item.checked) }} id={"rosterMembersList_" + i} />
                            <span>{item.memberName}</span>
                        </label>
                    </li>
                );
            })
        } else {
            return (
                <li>
                    No data
                </li>
            )
        }
    }

    function filterCreateRosterMembers(filterText) {
        let arr = structuredClone(createUsersList);
        let filteredArray = arr.filter((item) => item.name.toLowerCase().includes(filterText.toLowerCase()));
        setCreateUsersFilteredList(filteredArray);
    }

    function onCreateCheckboxClick(id, newCheckedStatus) {
        let arr = structuredClone(createUsersFilteredList);
        arr.forEach((item) => {
            if (item.id == id) {
                item.checked = newCheckedStatus;
            }
        });
        setCreateUsersFilteredList(arr);
    }

    function renderCreateRosterMembersList() {
        if (createUsersFilteredList.length > 0) {
            return createUsersFilteredList.map((item, i) => {
                return (
                    <li>
                        <label for={"createUsersList_" + i} className="playlist-name">
                            <input name={"createUsersList_" + i} type="checkbox" checked={item.checked} onClick={() => { onCreateCheckboxClick(item.id, !item.checked) }} id={"createUsersList_" + i} />
                            <span>{item.name}</span>
                        </label>
                    </li>
                );
            })
        } else {
            return (
                <li>
                    {translator["No users to add"]}
                </li>
            )
        }
    }

    function resetCreateUsersFilteredList() {
        setCreateUsersFilteredList(createUsersList);
    }

    function submitCreateRosterMembers() {
        let postArray = [];
        let arr = structuredClone(createUsersFilteredList);
        arr.forEach((item) => {
            let obj = {};
            if (item.checked) {
                obj["memberId"] = item.id;
                obj["memberName"] = item.name;
                obj["sxRosterId"] = rosterDetailsByRosterId.rosterId;
                obj["shiftId"] = rosterDetailsByRosterId.shiftId;
                obj["companyId"] = rosterDetailsByRosterId.companyId;
                obj["companyName"] = rosterDetailsByRosterId.companyName;
                // obj["sequence"] = 0;
                // obj["status"] = "Active";
                postArray.push(obj);
            }
        });
        if(postArray.length > 0) {
            setSaveLoader(true);
            axios.post('/rest/aiosem/saveRosterMemberListAction', postArray).then(function (response) {
                if (response.status == 200) {
                    setSaveLoader(false);
                    dispatch(loadRosterMembersList(props.rosterId));
                    setShowListOrAdd('list');
                }
            }).catch(function (error) {
                setSaveLoader(false);
                swal({
                    text: error.response.data.businessMessage,
                    button: translator['OK'],
                });
            });
        }
    }

    if (on_call_roster_loader_reducer.showRosterListLoader) {
        return <ListLoader />;
    }
    else {
        return (
            <div className="stickyArea rBoxStyle">
                <Row>
                    <Col xs={12}>
                        {showListOrAdd == 'list' ?
                            <div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
                                <ul>
                                    <li>
                                        <Button bsStyle="" bsClass="" bsPrefix=" " className="myBt" onClick={() => { setShowListOrAdd('add'); loadGroupUsersList() }} title={translator["Add"]} >
                                            <HiPlus />
                                        </Button>
                                    </li>
                                </ul>
                            </div>
                            : ''}
                        <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{translator["Roster Members"]}</div></div>
                    </Col>
                </Row>
                <div className="rBoxGap">
                    {showListOrAdd == 'list' ?
                        <>
                            <div className="member-add-head">{translator["Member List"]}</div>
                            {selectedMemberCountForDelete > 0 ? <div className="deleteFilter">
                                <Row>
                                    <Col sm={6}>
                                        <a href={void (0)} onClick={() => resetSelectedMemberCountForDelete()}><i className="fa fa-long-arrow-left" aria-hidden="true"></i></a>
                                        <span className="checkbox-count" style={{ marginLeft: "5px" }}>{selectedMemberCountForDelete}</span> <span className="item-selected">Selected</span>
                                    </Col>
                                    <Col sm={6}>
                                        <div className="float-r text-right">
                                            <a href={void (0)} onClick={() => onDeleteClick()}><i className="fa fa-trash-o" aria-hidden="true"></i></a>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                                : ''}
                            <div className="addto-playlists">
                                <ul>
                                    {renderRosterMembersList()}
                                </ul>
                            </div>
                        </>
                        :
                        <>
                            <div className="member-add-head">{translator["Add Member"]}</div>
                            <div className="addto-search">
                                <div className="form-input">
                                    <Form.Control className="keyword" type="text" onChange={(e) => { filterCreateRosterMembers(e.target.value) }} placeholder={translator['Search']} />
                                    <button className="search-button"><i className="fa fa-search"></i></button>
                                </div>
                            </div>
                            <div className="addto-playlists">
                                <ul>
                                    {renderCreateRosterMembersList()}
                                </ul>
                            </div>
                            <Row>
                                <Col md={12}>
                                    <ButtonToolbar className="black" style={{ marginTop: "10px" }}>
                                        <Button title="Save" bsSize="small" bsStyle="primary" disabled={saveLoader} onClick={() => submitCreateRosterMembers()} className="rgSidrkBtn smallBtn" >
                                            {saveLoader ? (
                                                <ImSpinner6 className="icn-spinner"/>
                                            ) : null}
                                            {translator["Save"]}
                                        </Button>
                                        <Button title="Close" bsSize="small" bsStyle="text" className="rgSiLigBtn smallBtn" onClick={() => { setShowListOrAdd('list'); resetCreateUsersFilteredList(); }} >
                                            {translator["Close"]}
                                        </Button>
                                    </ButtonToolbar>
                                </Col>
                            </Row>
                        </>
                    }
                </div>
            </div>
        );
    }
}

export default reduxForm({
    form: "onCallRelatedScheduleForm",
    destroyOnUnmount: true,
    enableReinitialize: true
})(RightSide);
