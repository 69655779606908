
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import {Row, Col, Form, Button, Table, Alert} from 'react-bootstrap';
import { connect } from 'react-redux';
import { getChainEntities,setEditServiceDetails,setForm,getServiceDetailCounts } from '../../../actions/spcmActions';
import ComponentForm from './editForm.js';
import axios from 'axios';
import Pagination from "react-js-pagination";
import ListLoader from "_Commons/loaders/ListLoader";
import { Link } from 'react-router-dom';
import { loadProfile } from '../../../actions/myProfile/profileAction';
import { getRuleConfiguration } from '../../../actions/amsActions/amsActions.js';
import Swal from 'sweetalert2';
import Cookies from "universal-cookie";
import {IoSearch} from 'react-icons/io5';
import { BiLinkExternal } from 'react-icons/bi';
import { openLinkInNewTab } from '../../common/helper.js';

const cookies = new Cookies();
let app_url = cookies.get("gph");
if (app_url) app_url = app_url.replace("s:", "");
if (app_url) app_url = app_url.substring(0, app_url.lastIndexOf("."));
app_url = app_url.split("~");
app_url = app_url[18];
class ListView extends React.Component {
	constructor(props){
		super(props);
		this.state={
			queryBy:'Role',
			query:'',
			sortBy:'Role',
			pageSize:10,
			deleteError:'',
			sortIcons: false ,
			class1: 'sort up',
			class2: 'sort',
			sortByTitle:'',
			externLink:false
		}
		this.sortFun = this.sortFun.bind(this);
		this.onTextChange = this.onTextChange.bind(this);
		this.onPageChange = this.onPageChange.bind(this);
		this.onPageSizeChange = this.onPageSizeChange.bind(this);
		this.onSearchClick = this.onSearchClick.bind(this);
		this.queryBySelect = this.queryBySelect.bind(this);
		this.onDeleteClick = this.onDeleteClick.bind(this);
		this.onSort = this.onSort.bind(this);
		this.handleDismiss = this.handleDismiss.bind(this);
		this.sortHover = this.sortHover.bind(this);
	}

	handleKeyPress(e){
		if (e.key === 'Enter'){
			this.onSearchClick();
			if(e.preventDefault) e.preventDefault();
		}
	}

	sortFun(myObj){
		this.setState({ sortIcons:!this.state.sortIcons});
		switch(myObj) {
			case 'class1':
				this.setState({ class1:(this.state.sortIcons ? 'sort up' : 'sort down')});
				this.setState({ class2:'sort',sortBy:'Role'});
				this.props.getChainEntities(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy,this.state.query,'Role',this.state.sortIcons?'asc':'desc');
			break;
			case 'class2':
				this.setState({ class1:'sort'});
				this.setState({ class2:(this.state.sortIcons ? 'sort up' : 'sort down'),sortBy:'Company'});
				this.props.getChainEntities(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy,this.state.query,'Company',this.state.sortIcons?'asc':'desc');
			break;
		}

	};


	onTextChange(e){
		this.setState({query:e.target.value});
	}

	onPageSizeChange(e){
		this.setState({pageSize:e.target.value});
		this.props.getChainEntities(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy,this.state.query,this.state.sortBy,this.state.sortIcons?'desc':'asc',1,e.target.value);
	}

	onPageChange(e){

		this.props.getChainEntities(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy,this.state.query,this.state.sortBy,this.state.sortIcons?'desc':'asc',e,this.state.pageSize);
	}

	onSearchClick(){
		this.props.getChainEntities(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy,this.state.query,this.state.sortBy);
	}

	handleDismiss() {
		this.setState({ deleteError: '' });
	  }

	queryBySelect(e){
		this.setState({queryBy:e.target.value});
	}

	sortHover(e){
		let index = e.nativeEvent.target.selectedIndex;
		this.setState({sortByTitle:e.nativeEvent.target[index].text})
	}

	onDeleteClick(id){
		Swal.fire({
            title: 'Are you sure you wish to delete this item?',
            width: 550,
			
            padding: '15px',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
          }).then((result) => {
            if (result.isConfirmed) {
					return axios.delete("/service/api/deleteChainEntities/"+id).then((resp)=>{
						this.props.getChainEntities(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
						this.props.getServiceDetailCounts(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
			
					}).catch((error)=>{
						this.setState({deleteError:error.response.data[0].message})
					})
                } else if (result.isDenied) {
              
            }
          })
	}

	onSort(e){
		this.setState({sortBy:e.target.value});
		this.props.getChainEntities(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy,this.state.query,e.target.value)
	}

	componentWillMount(){
		this.props.setForm('chainEntitiesForm');
		console.log("app_url",app_url);
		this.props.getChainEntities(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID, '', '', 'Role');
		this.props.loadProfile();
		let profileRoleName = this.props.profileDetails.roleName.match("AMS Administrator");
		console.log("ROLE===",profileRoleName);

		if(profileRoleName == null || profileRoleName == "null" ){
			console.log("its a null")
			this.setState(
				{externLink:false}
			)
		}else{
			console.log("its not a null")
			this.setState(
				{externLink:true}
			)
		}

	}
	render() {
		const tr = this.props.tr;
		var meta = this.props.spcmReducer.meta;
		console.log('meta');
		console.log(meta);
		console.log("profileDetails",this.props.profileDetails)
		var chainEntities = this.props.spcmReducer.chainEntities;
		console.log("this.state.externLink",this.state.externLink)
		

		return (
			<div className="">
			{this.props.componentFormToggle3 ? <ComponentForm tr={tr} chainEntities={chainEntities} componentFormFun4={this.props.componentFormFun3}/> : <div className='rBoxGap'>
				{this.state.deleteError == '' ? null : <Alert style={{ "marginBottom": "15px", "fontSize": "13px", "padding": "8px 30px 8px 8px" }} variant="danger" onDismiss={this.handleDismiss}><h4>{this.state.deleteError}</h4></Alert>}
				<Row className="">
					<Col lg={8} md={12} sm={8} xs={12}>
						<div className="form-inline rightSideFields d-lg-flex d-md-block d-sm-flex d-block">
							<Form.Select onMouseOver={this.sortHover}  title={this.state.sortByTitle} className="myControl mySel mySelectWidth" onChange={ this.queryBySelect }>
								<option value="Role">Role</option>
								<option value="Company">Company Name</option>
							</Form.Select>
							<div className='d-flex'>
							<Form.Control onKeyPress={this.handleKeyPress.bind(this)} className="myControl myInput margin-l-5 myInputWidth" type="text" onChange={ this.onTextChange }/>
							<Button type="submit" title="Search" onClick={ this.onSearchClick } className="myBtn"><IoSearch/></Button>
							</div>
						</div>
					</Col>
					<Col lg={4} md={12} sm={4} xs={12} className="text-r rightSideFields">
						<Form.Label className="margin-r-5 display-inline-block widthAuto">{tr['Show']}</Form.Label>
						<Form.Select className=" display-inline-block widthAuto" onChange={ this.onPageSizeChange }>
						<option value="10" selected={this.state.pageSize==10?true:false}>10</option>
						<option value="25" selected={this.state.pageSize==25?true:false}>25</option>
						<option value="50" selected={this.state.pageSize==50?true:false}>50</option>
						<option value="100" selected={this.state.pageSize==100?true:false}>100</option>
						</Form.Select>
						
						{this.state.externLink == true?
						<div className='exterLnkicn exterLnkicnBG'>
							{/* <Link role="button" to="/amsHome" title ="Go to Assignment Rules" ><i className="fa fa-external-link" aria-hidden="true" ></i></Link></div> */}
									<a
										// title="Go to Assignment Rules"
										title={tr["Go to Rules"]}
										onClick={() => {
											openLinkInNewTab(app_url+"/home?rules=true", "New Tab");
										}}
									>
									<BiLinkExternal />
								</a>
						</div>
						:
						null
						// <div className='exterLnkicn'><i className="fa fa-external-link" aria-hidden="true" ></i></div>
					} 
						</Col>
				</Row>

				{this.props.spcmReducer.spcmFetchingError == true  && this.props.spcmReducer.spcmFetchingModule == 'CHAIN_ENTITIES' && this.props.spcmReducer.spcmErrorMsg ?  <Alert variant="danger" className="errorMessageSec margin-t-20"><p>{this.props.spcmReducer.spcmErrorMsg}</p></Alert> :  this.props.spcmReducer.spcmFetchingError == true  && this.props.spcmReducer.spcmFetchingModule == 'CHAIN_ENTITIES' && !this.props.spcmReducer.spcmErrorMsg ? <p>Oops! Something went wrong.</p> : null}

				<div className="f-size-12 margin-xs-t-15">
					<Table bordered condensed hover responsive className="tableView sortImg tableWithOutPointer">
					<thead>
					  <tr>
						<th width="30%" className={this.state.class1} onClick={()=>{this.sortFun('class1')}} >{tr['Role']}</th>
						<th width="60%" className={this.state.class2} onClick={()=>{this.sortFun('class2')}}>{tr['Company Name']}</th>
						<th width="10%" className="text-c">{tr['Action']}</th>
					  </tr>
					</thead>
					{this.props.spcmReducer.spcmFetchingAttribute  ? 
					<tbody><td colSpan="3">
						<ListLoader />
					</td></tbody> : 
					<tbody>
						{chainEntities.map(function(value,index){
							let deepLinkUrl = value['AMS_DEEPLINK'];
							// let deepLinkUrl = "http://xsmdev.dryice-aws.com?amsEditRules=12976";
							let AMS_ID = value['AMS_CREATE_ID'] || '';
							return (<tr>
						<td>{value.CHAIN_TYPE}</td>
						<td>{value.CHAIN_COMPANY}</td>
						<td>
							<div className="text-c">
								{this.state.externLink == true ? 
									<i title={tr["Edit in Assignment Rules"]} onClick={()=>{
									// this.props.setEditServiceDetails(value),this.props.componentFormFun3(true,'Edit',value.CHAIN_ID)
									if(AMS_ID){
										// this.props.getRuleConfiguration(AMS_ID);
										var newWindow = openLinkInNewTab(deepLinkUrl, "New Tab"); 
									}
									}} className="fa fa-pencil-square-o cursorPoint"></i>
								: null}

								<i title={tr["Delete"]} className="margin-l-10 fa fa-trash-o cursorPoint"  onClick={()=>{this.onDeleteClick(value.CHAIN_ID)}}></i>
							</div>
						</td>
					  </tr>)
						},this)}
					</tbody>
					}
					</Table>
					
				</div>

				<div className='nBotPagina'>
				<nav aria-label="Pagination" className="display-inline-block">
					{/* <Pagination className="margin-0" prev next boundaryLinks items={meta?meta.totalPageCount:1} maxButtons={3} activePage={meta?meta.currentPage:1}  onSelect={this.onPageChange}/> */}
					
					{meta ? <Pagination  
						activePage={meta ? meta.currentPage : 1}
						itemsCountPerPage={this.state.pageSize}
						totalItemsCount={meta ? meta.rowCount : 1}
						onChange={this.onPageChange}
					/> : null }
					
				</nav>
				</div>
				</div>
				}
			</div>
		);
	}
}
const mapStateToProps = ({profileDetails,spcmReducer}) => {
	return {
		spcmReducer,profileDetails
	}
};

export default connect(mapStateToProps,{ getChainEntities,setEditServiceDetails,setForm,getServiceDetailCounts,loadProfile, getRuleConfiguration })(ListView);
