
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, change, SubmissionError } from 'redux-form';
import { _passwordField, _inputField, _dropDown, _checkbox, _number } from '../../../common/formFields';
import onCallDropdownPlusTypeahead from '../../common/onCallDropdownPlusTypeahead';
import ListLoader from "../../../common/loaders/ListLoader.js"

import { loadOnCallSXMenulistData } from '_Actions/onCallSchedule/common/onCallCommonAction';
import { loadProfileTimeZone } from '_Actions/myProfile/profileTimeZoneAction';
import { loadScheduleDetailsByScheduleId, emptyScheduleDetailsByScheduleId } from '_Actions/onCallSchedule/schedule/onCallScheduleAction';

const FormComponent = (props) => {
    const dispatch = useDispatch();
    const translator = useSelector((state) => state.spcmReducer.tr);
    const onCallCommonReducer = useSelector((state) => state.on_call_common_reducer);
    const on_call_schedule_loader_reducer = useSelector((state) => state.on_call_schedule_loader_reducer);
    const [timeZoneSelectedList, setTimeZoneSelectedList] = useState([]);
    const [inputValueTimeZone, setInputValueTimeZone] = useState('');
    let scheduleTypeValues = useSelector((state) => state.on_call_common_reducer.scheduleType);
    // scheduleTypeValues = scheduleTypeValues.filter((item) => item.field1Key == 'Holiday' || item.field1Key == 'Workday');
    let timeZoneDetails = useSelector((state) => state.timeZoneDetails);
    let timeZoneTypeaheadOptions = [];
    if (timeZoneDetails && timeZoneDetails.length > 0) {
        timeZoneDetails.forEach((item, i) => {
            let obj = {};
            obj.id = item.VALUE;
            obj.label = item.VALUE;
            timeZoneTypeaheadOptions.push(obj);
        });
    }

    useEffect(() => {
        dispatch(loadScheduleDetailsByScheduleId(props.scheduleId));
        dispatch(loadOnCallSXMenulistData('AIOSEM', 'ScheduleType'));
        dispatch(loadOnCallSXMenulistData('AIOSEM', 'Status'));
        dispatch(loadProfileTimeZone());
    }, []);

    useEffect(() => {
        if (props.initialValues && props.initialValues.timeZone) {
            setTimeZoneSelectedList([{ id: props.initialValues.timeZone, label: props.initialValues.timeZone }]);
            dispatch(change('onCallScheduleEditScheduleForm', 'timeZone', props.initialValues.timeZone));
        }
    }, [props.initialValues]);

    useEffect(() => {
        return () => {
            dispatch(emptyScheduleDetailsByScheduleId());
        }
    }, []);

    function validateField(fieldType, event) {
        switch (fieldType) {
            case 'scheduleName':
                if (event.target.value.trim() == "") {
                    props.setScheduleNameErrorState('error');
                } else {
                    props.setScheduleNameErrorState('');
                }
                break;
            case 'scheduleType':
                if (event.target.value == "") {
                    props.setScheduleTypeErrorState('error');
                } else {
                    props.setScheduleTypeErrorState('');
                }
                break;
            default:
        }
    }

    function onTimeZoneSelection(selectedTimeZone) {
        props.setTimeZoneErrorState('');
        setTimeZoneSelectedList(selectedTimeZone);
        if (selectedTimeZone.length > 0) {
            dispatch(change('onCallScheduleEditScheduleForm', 'timeZone', selectedTimeZone[0].id));
            setInputValueTimeZone(selectedTimeZone[0].label);
        }
        else {
            dispatch(change('onCallScheduleEditScheduleForm', 'timeZone', ''));
        }
    }

    function setTimeZoneErrorColor() {
        if (timeZoneSelectedList.length === 0)
            props.setTimeZoneErrorState('error');
        else
            props.setTimeZoneErrorState('');
    }

    function onTimeZoneInputChange(val) {
        setInputValueTimeZone(val);
    }

    function onCrossClickTimeZone() {
        dispatch(change('onCallScheduleEditScheduleForm', 'timeZone', ''));
        setInputValueTimeZone('');
        setTimeZoneSelectedList([]);
        props.setTimeZoneErrorState('error');
    }

    if (on_call_schedule_loader_reducer.showScheduleListLoader) {
        return <ListLoader />;
    }
    else {
        return (
            <Row>
                <Col md={6} sm={6} xs={12}>
                    <Form.Group className="form-group">
                        <Form.Label><span className="rStar"></span>{translator['Name']}</Form.Label>
                        <Field name="scheduleName" component={_inputField} onBlur={(event) => { validateField('scheduleName', event) }} maxLength="50" className={`form-control ${props.scheduleNameErrorState}`} />
                    </Form.Group>
                </Col>
                <Col md={6} sm={6} xs={12}>
                    <Form.Group className="form-group">
                        <Form.Label><span className="rStar"></span>{translator['Type']}</Form.Label>
                        <Field component={_dropDown} name="scheduleType" disabled onBlur={(event) => { validateField('scheduleType', event) }} options={scheduleTypeValues} className={`form-control ${props.scheduleTypeErrorState}`} />
                    </Form.Group>
                </Col>
                <Col md={6} sm={6} xs={12}>
                    <Form.Group className="form-group">
                        <Form.Label><span className="rStar"></span>{translator['Timezone']}</Form.Label>
                        <div className="heightdvTypehd">
                        <Field component={onCallDropdownPlusTypeahead}
                            errorColor={props.timeZoneErrorState}
                            onSelection={onTimeZoneSelection}
                            setErrorColor={setTimeZoneErrorColor}
                            name="timeZone"
                            id="timeZone"
                            className="form-control"
                            options={timeZoneTypeaheadOptions}
                            selectedOptions={timeZoneSelectedList}
                            onInputChange={onTimeZoneInputChange}
                            inputValue={inputValueTimeZone}
                            onCrossClick={onCrossClickTimeZone}
                        />
                        </div>
                    </Form.Group>
                </Col>
                <Col md={6} sm={6} xs={12}>
                    <Form.Group className="form-group">
                        <Form.Label><span className="rStar"></span>{translator['Status']}</Form.Label>
                        <Field component={_dropDown} name="onCallStatus" options={onCallCommonReducer.onCallStatus} className="form-control" />
                    </Form.Group>
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state) => ({
    initialValues: state.on_call_schedule_reducer.scheduleDetailsByScheduleId
});

export default connect(mapStateToProps, null)(
    reduxForm({
        form: 'onCallScheduleEditScheduleForm',
        destroyOnUnmount: true,
        enableReinitialize: false
    })(FormComponent)
);
