
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const accordionExtendTrait = (e) => {
    e.DomComponents.addType("accordion", {
      model: {
        defaults: {
          traits: [
            // ...e.DomComponents.getType("link").model.prototype.defaults.traits,
            {
              type: "checkbox",
              label: "Default Open",
              name: "defaultOpen",
              changeProp: 1,
            },
            {
              type: "select",
              label: "Data Source",
              name: "data_source",
              changeProp: 1,
              options: [],
            },
            {
              type: "select",
              label: "Page (On Click)",
              name: "page",
              changeProp: 1,
              options: [],
            },
          ],
        },
      },
    });
  };
  