
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';

import ListLoader from '../../../common/loaders/ListLoader';
import TableComponent from '../../../common/ReactTable/TableComponent';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const ListSec = (props) => {
    
    const [locationData, setLocationData] = useState([]);

    const locationListData = useSelector((state) => state.locationData);
    const showLoader = useSelector((state) => state.commonLoader.isLoading);
    const onRowSelect = (row) => {
        props.companyDataHandler(row);
        props.showRightSideFun(true);
    }
    const tableColumns = [
        {
            name: props.translator["Location Name"],
            selector: (row) => row.location_name,
            sortable: true,
        },
        {
            name: props.translator["Company"],
            selector: (row) => row.company_name,
            sortable: true,
         },
        {
            name: props.translator["City"],
            selector: (row) => row.city_name,
            sortable: true,
        },
        {
            name: props.translator["State"],
            selector: (row) => row.state_name,
            sortable: true,
        },
        {
            name: props.translator["Country"],
            selector: (row) => row.country_name,
            sortable: true,
        },
        {
          name: props.translator["Status"],
          selector: (row) => row.status,
          sortable: true,
        }
    ];
    useEffect(() => {
        if (locationListData.length > 0) {
            setLocationData(locationListData.map(obj => {
            obj.status = obj.status === "1" ? "Active" : "Inactive" 
          }))
        }
    }, [locationListData])


    const filterColumns = ["location_name","company_name","city_name","state_name","country_name"];
    // console.log("LocationListData",locationListData)
    const renderLocationTableData = () => {
        return (
            <div className="dtaTableDv integrateHubTable">
                <div className={props.isRightSideVisible ? "leftTrue srchPosTop fdnPos" : "rightTrue srchPosTop fdnPos"}>
                    <TableComponent
                        data={locationListData}
                        columns={tableColumns}
                        headerColumns={filterColumns}
                        onRowSelect={onRowSelect}
                        rightDisplay={props.isRightSideVisible}
                        uniqueKey={'id'}
                    />
                </div>
            </div>
        );
    }



    if (!locationListData) return null;
    if (locationListData.length == undefined) {
      return <ListLoader />;
    } else if (locationListData.length === 0) {
      return (
        renderLocationTableData()
      )
    } else {
      return (
        <div className="myOrderDiv">
          {
          showLoader ? (
            <ListLoader />
          ) : (
            renderLocationTableData()
          )
          }
        </div>
      );
    }
}

export default ListSec;