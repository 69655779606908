
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';

import { navigationHooks } from '../../helpers/NavigationHook';
import Swal from 'sweetalert2';
const api = axios.create({
    headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});
  

export const getLocationList = (param) => {
    return (dispatch) => {
      dispatch({ type: 'FDN_DATA_FETCHING'})
      api.get("/api/location")
          .then((response) => {
            console.log("LOCAction1",response.data)
          dispatch({ type: 'LOCATION_LIST_FETCHED_SUCCESSFULLY', payload: response.data});
          dispatch({ type: 'FDN_DATA_FETCHED_SUCCESS'})
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: 'FDN_DATA_FETCHED_SUCCESS'})
        });
    };
};

export const getInitialListLocationAction = () => {
    return (dispatch) => {
        axios.get("/api/initModelListLocation")
        .then((resp) => {
            let valuesCountry = [];
            console.log("RestValues", resp.data)
            let obj = {}
            let array = [];
            const menuListoptions = resp.data.locationTypeMap;
            for (const label in menuListoptions) {
              if (menuListoptions.hasOwnProperty(label)) {
                obj = {
                    text: label,
                    value :label
                }
                array.push(obj);  
              }
            }
            const countryMenuListoptions = resp.data.listCountry;
            console.log("countryMenuListoptions", countryMenuListoptions);
        if (typeof countryMenuListoptions !== "undefined" && countryMenuListoptions.length > 0) {
            countryMenuListoptions.forEach((value) => {
            let val = { value: value.country_id, text: value.country_name };
            valuesCountry.push(val);
          });
        }
        dispatch({
          type: "LOCATION_TYPE_INITIAL_LIST",
          payload: array,
        });
        dispatch({
            type: "COUNTRY_INITIAL_LIST_VALUES",
            payload: valuesCountry,
          });
      });
    };
};

export function saveLocationAction(data) {
    return (dispatch) => {
        api.post("/api/saveLocation", data)
            .then((response) => {
              console.log("respomse+++",response);
              if (response.status == 201) {
                if (response.data.nameAlreadyExist === 'Name already exists') {
                  Swal.fire({
                    title: 'Location already exists',
                  })
                } else {
                  navigationHooks.navigate("/location");
                }
                } 
            })
            .catch((err) => {
                if(err.response.data.nameAlreadyExist === 'Name already exists') {
                    Swal.fire({
                      title: 'Location already exists',
                    })
                  }
            });
    };
}

export const getInitialStatesLocationAction = (Id) => {
    return (dispatch) => {
        axios.get("/api/getLocationStateList/"+Id)
            .then((resp) => {
        let values = [];
                const menuListoptions = resp.data;
            console.log("StatesFieldValues", menuListoptions);
        if (typeof menuListoptions !== "undefined" && menuListoptions.length > 0) {
            menuListoptions.forEach((value) => {
            let val = { value: value.field1Value, text: value.field1Key };
            values.push(val);
          });
        }
        dispatch({
          type: "STATES_INITIAL_LIST",
          payload: values,
        });
      });
    };
};

export const getInitialCityLocationAction = (Id) => {
    return (dispatch) => {
        axios.get("/api/getLocationCityList/"+Id)
        .then((resp) => {
        let values = [];
            const menuListoptions = resp.data;
            console.log("LocationCityValues", menuListoptions);
        if (typeof menuListoptions !== "undefined" && menuListoptions.length > 0) {
            menuListoptions.forEach((value) => {
            let val = { value: value.field1Value, text: value.field1Key };
            values.push(val);
          });
        }
        dispatch({
          type: "CITY_INITIAL_LIST",
          payload: values,
        });
      });
    };
};



export function updateLocationAction(data) {
    return (dispatch) => {
        api.post("/api/updateLocation", data)
            .then((response) => {
              console.log("respomse+++",response);
              if (response.status == 201) {
                if (response.data.nameAlreadyExist === 'Name already exists') {
                  Swal.fire({
                    title: 'Location name already exists',
                  })
                } else {
                  navigationHooks.navigate("/location");
                }
                } 
            })
            .catch((err) => {
                
            });
    };
  }

export const deleteLocationData = (id) => {
    return (dispatch) => {
      api.delete("/api/deleteLocation/"+id)
        .then((response) => {
          console.log("DeletedGrop",response)
          Swal.fire({
            title: 'Record deleted successfully',
          }).then(result => {
            if (result.isDismissed || result.isConfirmed) {
              navigationHooks.navigate('/location')
            }
          })
        })
        .catch((err) => {
          console.log(err);
        });
    };
};

export const getLocationEditPageDetails = (id) => {
    return (dispatch) => {
        dispatch({ type: 'FDN_DATA_FETCHING'})
      api.get("/api/editLocation/"+id)
          .then((response) => {
              dispatch({ type: 'FDN_DATA_FETCHED_SUCCESS' })
              
              console.log("LOCResponse", response.data)
            let countriesMap = response.data.countriesMap;
            let citiesMap = response.data.cityList;
            let statesMap = response.data.stateList;
            dispatch({ type: 'LOCATION_PAGE_EDIT_DETAILS_FETCHED', payload: response.data.location});
            dispatch({ type: 'COUNTRY_DETAILS_FETCHED', payload: countriesMap });
            dispatch({ type: 'STATES_DETAILS_FETCHED', payload: statesMap });
            dispatch({ type: 'CITIES_DETAILS_FETCHED', payload: citiesMap });
        })
          .catch((err) => {
          dispatch({ type: 'FDN_DATA_FETCHED_FAILED'})
          console.log(err);
        });
    };
};