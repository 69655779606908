
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import {GLOBAL} from '../../../src/components/Globals'
import swal from 'sweetalert';
import { navigationHooks } from '../../helpers/NavigationHook';
import Swal from 'sweetalert2';
const api = axios.create({
    headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});
  

export const getOrganizationList = (param) => {
    return (dispatch) => {
      dispatch({ type: 'FDN_DATA_FETCHING'})
      api.get("/api/organization")
          .then((response) => {
            console.log("OrgAction1",response.data)
          dispatch({ type: 'ORGANIZTION_LIST_FETCHED_SUCCESSFULLY', payload: response.data});
          dispatch({ type: 'FDN_DATA_FETCHED_SUCCESS'})
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: 'FDN_DATA_FETCHED_SUCCESS'})
        });
    };
};

export function saveOrganizationData(data) {
    return (dispatch) => {
        api.post("/api/saveOrganization", data)
            .then((response) => {
              console.log("respomse+++",response);
              if (response.status == 201) {
                if (response.data.nameAlreadyExit === 'Name already exists') {
                  Swal.fire({
                    title: 'Organization already exists',
                  })
                } else {
                  navigationHooks.navigate("/organization");
                }
                } 
            })
          .catch((err) => {
            console.log("SaveOrgErr", err)
            });
    };
}


export const getOrganizationPageDetails = (id) => {
    return (dispatch) => {
    dispatch({ type: 'FDN_DATA_FETCHING'})
      api.get("/api/editOrganization/"+id)
          .then((response) => {
            dispatch({ type: 'FDN_DATA_FETCHED_SUCCESS'})
              console.log("CheckEditOrg",response)
              let data = {
                  ...response.data,
                //   company_name: response.data.department.company_name,
                //   companyId: response.data.department.companyId
              }
            dispatch({ type: 'ORGANIZATION_EDIT_DETAILS_FETCHED', payload: data });
        })
          .catch((err) => {
            dispatch({ type: 'FDN_DATA_FETCHED_FAILED'})
          console.log(err);
        });
    };
};

export function updateOrganizationAction(data) {
    return (dispatch) => {
        api.post("/api/updateOrganization", data)
            .then((response) => {
              console.log("respomseOrgUpdate+++",response);
              if (response.status == 201) {
                if (response.data.nameAlreadyExit === 'Name already exists') {
                  Swal.fire({
                    title: 'Organization already exists',
                  })
                } else {
                  navigationHooks.navigate("/organization");
                }
                } 
            })
            .catch((err) => {
              console.log("UpdateOrgErr", err)
            });
    };
  }

export const deleteOrganizationData = (id) => {
    return (dispatch) => {
      api.delete("/api/deleteOrganization"+id)
        .then((response) => {
          Swal.fire({
            title: 'Record deleted successfully',
          }).then(result => {
            if (result.isDismissed || result.isConfirmed) {
              navigationHooks.navigate('/organization')
            }
          })
        })
        .catch((err) => {
          console.log(err);
        });
    };
};
  
export const getOrganizationCompanyOptions = () => {
    return (dispatch) => {
      api.get("/api/getInitiallist")
          .then((response) => {
              console.log("LetResponse", response);
            let object = [];
            const menuListoptions = response.data.listCompany;
            if (typeof menuListoptions !== "undefined" && menuListoptions.length > 0) {
              menuListoptions.forEach((values) => {
                let val = { id: values.id, label: values.name };
                object.push(val);
              });
              }
            console.log("CCResp", response.data)
            // let mapValues = response.data.companiesMap
            dispatch({ type: 'ORG_COMPANY_DROPDOWN_LIST', payload: object });
            // dispatch({ type: 'CC_STATUS_DROPDOWN_LIST', payload: response.data.statusList });
            // dispatch({ type: 'COMPANY_DETAILS_FETCHED', payload:mapValues});
        })
        .catch((err) => {
          console.log(err);
        });
    };
};