
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
//import initialState from '../../initialState';

export const myChangeManagementList = (state = {}, action) => {
	switch (action.type) {
		case 'LOAD_CMList_SUCCESS':
			return action.changeManagementList.data;
		default:
			return state;
	}
}

export const editChangeInitialData = (state = {}, action) => {
	switch (action.type) {
		case 'CHANGE_EDIT_DATA_LOAD_SUCCESS':
		return action.editChangeInitialData;
		default:
			return state;
	}
};
export const extractedChangeDataForSucessRate = (state = {}, action) => {
    if(action.type === 'EXTRACTED_CHANGE_DATA_FOR_RATE_LOAD_SUCCESS') {
        return action.extractedChangeData;
    }else{
        return state;
    }
};
export const fieldStatus=(state={},action)=>{
	switch(action.type){
		case 'CHANGE_FIELD_STATUS_DATA_LOAD_SUCCESS':
			return action.statusData.data;
		default:
			return state;
	}
};