
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ContenttView from "./common/privacyPolicy-content.js";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Cookies from "universal-cookie";
const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home") homepagelocation = "/" + homepagelocation;

const PrivacyPolicy = () => {
  const tr = useSelector((state) => state.spcmReducer.tr);
  const navigate = useNavigate();
  const loadHome = () => {
    navigate(homepagelocation);
  };
  return (
    <main>
      <div className="container-fluid padding-t-10 margin-b-15">
        <nav aria-label="Breadcrumb">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href={void(0)} role="button" onClick={loadHome}>
                {tr["Home"]}
              </a>
            </li>
            <li className="breadcrumb-item active">{tr["Privacy Policy"]}</li>
          </ul>
        </nav>
      </div>
      <div className="container-fluid">
        <Row>
          <Col xs={12}>
            <ContenttView />
          </Col>
        </Row>
      </div>
    </main>
  );
};

export default PrivacyPolicy;
