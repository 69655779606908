
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState} from "react";
import { Row, Col, Button } from 'react-bootstrap';
import {useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import {HiPlus, HiOutlineRefresh} from "react-icons/hi";
import { LuFilterX } from "react-icons/lu";
import { getTOAList } from "../../../actions/spcmActions";

const Header = (props) => {
    const tr = useSelector((state) => state.spcmReducer.tr);
    const dispatch = useDispatch();

    const refreshHandler = () => {

        dispatch(getTOAList(props.searchKey,props.searchValue,props.dataType,props.status,props.page,props.size,props.patternList));
        props.showRightSideFun(false);
        props.setPage(0);
        props.setLimit(10);
	}
    const clearAllFilters = () => {
        props.setTableNameSearchInput('');
        props.setAttributeSearchInput('');
        props.setAttributeLabelSearchInput('');
        props.setLastModifiedBySearchInput('');
        props.setSelectedStatusValue([{label: 'Active', value: 'active'}]);
        props.setSelectedDatatypeValue([]);
        props.setDataType([]);
        props.setModifiedStartAndEndDate('');
        props.setRefreshedonStartAndEndDate('');
        props.setsearchValue('');
        props.setsearchKey('');
        props.setClearAllFilterToggle(false);
        dispatch(getTOAList());
        props.modifiedDateRef.startDate = moment();
        props.modifiedDateRef.endDate = moment();
        props.refreshedonDateRef.startDate = moment();
        props.refreshedonDateRef.endDate = moment();
	}

    console.log("prps,clearAllFilterToggle",props.clearAllFilterToggle)
    return (
    <Row className="margin-b-15">
        <Col lg={10} md={10} sm={10} xs={12}>
					<ul className="wibTabs">
						<li><Link title={"Table of Tables"} to="/tot">{"Table of Tables"}</Link></li>
						<li className="active"><Link title={"Table of Attributes"} to="/toa">{"Table of Attributes"}</Link></li>
					</ul>
				</Col>
        <Col lg={2} md={2} sm={2} xs={12}>
                <div aria-label="Page Filters" className="paHedFilter">

                    {props.clearAllFilterToggle ? <div className="overlayDv"><Button title={tr["Clear All Filters"]} role="button" onClick={() => { clearAllFilters() }} bsPrefix=' ' className="myBt clrbtn"><LuFilterX /></Button></div> : ""}

                    <Button bsPrefix=' ' title={tr["Refresh"]} onClick={refreshHandler} role="button" className="myBt"><HiOutlineRefresh className="refreshFlip" /></Button>
                </div>
        </Col>
    </Row>
    )
}

export default Header;