
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState, useRef } from "react";
import axios from "axios";
import * as Yup from "yup";
import { Row, Col, Button } from "react-bootstrap";
import { Formik, Form } from "formik";
import { StandardTextArea } from "../../../common/FormFields";
import {
  useGetWorkNotesDetailsQuery,
  useSaveWorkNotesDetailsMutation,
} from "../../../../../reducers/governance/meeting/meeting";
import WorkNotesList from "./list";
import {
  useGetworknoteAttachmentQuery,
  useSaveWorkNotesMutation,
} from "../../../../../reducers/governance/meeting/actionItem";

const WorkNotes = ({ editActionData }) => {
  const [saveWorkNote, { isLoading }] = useSaveWorkNotesMutation();

  const validationSchema = Yup.object().shape({
    comments: Yup.string()
      .required("Required")
      .max(1000, "Must be 1000 characters or less"),
  });

  const [initVals, setInitVals] = useState({
    comments: "",
  });
  let initialValues = {
    ...initVals,
  };
  const actionItemCode = editActionData[0]?.actionitemCode;
  const onSubmit = async (fields, { resetForm, setSubmitting }) => {
    const data = {
      actionitemCode: actionItemCode,
      filename: "",
      mimeType: "",
      activityType: null,
      createdOn: undefined,
      createdBy: editActionData[0]?.createdById,
      createdByName: editActionData[0]?.createdBy,
      modifiedOn: undefined,
      modifiedBy: editActionData[0]?.modifiedById,
      modifiedByName: editActionData[0]?.modifiedBy,
      description: fields.comments,
      // encodedString: "",
      status: "Y",
    };
    saveWorkNote({
      actionCode: actionItemCode,
      worknoteData: data,
    });
    resetForm();
  };

  const { data: worknotes, isFetching: notesFetching } =
    useGetworknoteAttachmentQuery(actionItemCode, {
      skip: !actionItemCode,
    });

  return (
    <div id="WorkNotes">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleBlur,
          values,
          handleChange,
          setValues,
        }) => {
          return (
            editActionData.length > 0 ?
            editActionData[0].statusName !== "Completed" && editActionData[0].statusName !== "Cancelled" && editActionData[0].statusName !== "Closed" ?
            <>
              <Form noValidate id="worknotes">
                <div className=" new-diamenton">
                  <Row>
                    <Col sm={12} className="text-right">
                      <div className="form-group position-relative">
                        <StandardTextArea
                          required
                          as="textarea"
                          rows="4"
                          label="Note"
                          name="comments"
                          classNam="form-control w-100"
                          disabled = {false}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} className="text-right">
                      <div className="pull-right custom-note-submit-btn">
                        <Button
                          type="submit"
                          disabled={isSubmitting}
                          title="Save"
                          className="rgSidrkBtn smallBtn"
                        >
                          Save
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Form>
            </> : ''
         : '' );
        }}
      </Formik>
      <div className="occurnance mt-3">
        <WorkNotesList data={worknotes} />
      </div>
    </div>
  );
};

export default WorkNotes;
