
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const allCINames = (state = [], action) => {
    switch (action.type) {
        case 'GET_ALL_CI_NAMES_SUCCESS':
            return action.allCINames;
        default:
            return state;
    }
};

export const hourlySlicingData = (state = [], action) => {
    switch (action.type) {
        case 'GET_HOURLY_SLICING_DATA_SUCCESS':
            return action.hourlySlicingData;
        default:
            return state;
    }
};

export const dailySlicingData = (state = [], action) => {
    switch (action.type) {
        case 'GET_DAILY_SLICING_DATA_SUCCESS':
            return action.dailySlicingData;
        default:
            return state;
    }
};

export const alertsData = (state = [], action) => {
    switch (action.type) {
        case 'GET_ALERTS_DATA_SUCCESS':
            return action.alertsData;
        default:
            return state;
    }
};

export const showCINamesDataLoader = (state = {
    loading: false,
    message: ''
}, action) => {
    switch (action.type) {
        case 'CI_NAMES_DATA_LOADER_IN_PROGRESS':
            return Object.assign({},
                state, {
                loading: true,
                message: 'loading...'
            });
        case 'CI_NAMES_DATA_LOADER_DETAILS_FETCHED_SUCCESSFULLY':
            return Object.assign({}, state, {
                loading: false,
                message: ''
            });
        default:
            return state;
    }
};

export const showSlicingDataLoader = (state = {
    loading: false,
    message: ''
}, action) => {
    switch (action.type) {
        case 'SLICING_DATA_LOADER_IN_PROGRESS':
            return Object.assign({},
                state, {
                loading: true,
                message: 'loading...'
            });
        case 'SLICING_DATA_LOADER_DETAILS_FETCHED_SUCCESSFULLY':
            return Object.assign({}, state, {
                loading: false,
                message: ''
            });
        default:
            return state;
    }
};

export const showAlertsDataLoader = (state = {
    loading: false,
    message: ''
}, action) => {
    switch (action.type) {
        case 'ALERTS_DATA_LOADER_IN_PROGRESS':
            return Object.assign({},
                state, {
                loading: true,
                message: 'loading...'
            });
        case 'ALERTS_DATA_LOADER_DETAILS_FETCHED_SUCCESSFULLY':
            return Object.assign({}, state, {
                loading: false,
                message: ''
            });
        default:
            return state;
    }
};