
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ListLoader from '../common/loaders/ListLoader';
import { loadIntegrationEditSuccess } from '../../actions/integrationHub/integrationHubAction.js';
import {PiPencilSimpleBold} from "react-icons/pi";
import {IoClose} from "react-icons/io5";
import { MyContext } from '_MyContext';

const IntegrationHubShortDexcription = (props) => {
    const dispatch = useDispatch();

    const showLoader = useSelector(state => state.showLoader.loading);
    const integrationHubData = useSelector(state => state.integrationHubList);
    const translator = useSelector(state => state.spcmReducer.tr)
    const integrationHubList = useSelector(state => state.getIntegrationHubData.integrationHubList);
    const context = useContext(MyContext);
    const closeButton = useRef(null);
    const editButton = useRef(null);

    useEffect(() => {
        context.keyDownHandler(closeButton.current,editButton.current,"","");
        context.newTabPrevent();
    }, []);

    useEffect(() => {
        if (integrationHubList && integrationHubList.length > 0) {
            const id = props.id;
            const currentDetails = integrationHubList.filter(item => item.id == id);
            currentDetails.length > 0 && dispatch(loadIntegrationEditSuccess(currentDetails[0]));
        }
    }, [integrationHubList])

    

    if (integrationHubData) {
        if (integrationHubData.length == undefined) {
            return (
                <ListLoader />
            );
        }
        else if (integrationHubData.length === 0) {
            return (
                <div>
                    <font color="green" ><b>{translator['There is no matching data available']}</b></font>
                </div>
            );
        }
    }
    else if (showLoader) {
        return (
            <ListLoader />
        );
    }
    return (
        <div className="margin-b-20">
            <div className="rPageHeadActBtn margin-r-5" style={{marginTop:"-3px"}}>
                <ul>
                    <li><Link to={"/editIntegrationHub/" + props.id} title={translator['Edit']} ref={editButton} className="ctrlKeyPrevent" ><PiPencilSimpleBold/></Link></li>
                    <li>
                        <Link to="javascript:void(0)" title={translator["Minimize the details panel"]} bsClass="" className="ctrlKeyPrevent" onClick={() => { props.showRightSide(false); }} ref={closeButton} >
                          <IoClose/>
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="rPageHeading">
                <div className='offNam margin-t-5 margin-b-5'>{translator['Outbound Details']}</div>
            </div>

            <div className="rBoxGap">
                <ListGroup variant='flush' bsClass="" className="">
                    <ListGroup.Item bsClass="" className="rwTable" style={{ "border-top": "none" }}><span className="rwKey">{translator['Company']} </span> <span className="rwVal1">{props.tenantName}</span></ListGroup.Item>
                    <ListGroup.Item bsClass="" className="rwTable"><span className="rwKey">{translator['Scenario']} </span> <span className="rwVal1">{props.scenarioName}</span></ListGroup.Item>
                    <ListGroup.Item bsClass="" className="rwTable"><span className="rwKey">{translator['Criteria']} </span> <span className="rwVal1">{props.criteria}</span></ListGroup.Item>
                    <ListGroup.Item bsClass="" className="rwTable"><span className="rwKey">{translator['End Point']} </span> <span className="rwVal">{props.endPoint}</span></ListGroup.Item>
                    <ListGroup.Item bsClass="" className="rwTable"><span className="rwKey">{translator['Username']} </span> <span className="rwVal">{props.userName}</span></ListGroup.Item>
                </ListGroup>
            </div>
        </div>
    );

}

export default IntegrationHubShortDexcription

