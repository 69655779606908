
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const advertisementList = (state = [], action) => {
    if (action.type === 'ADVERTISEMENT_LIST_FETCHED_SUCCESSFULLY') {
      return action.payload;
    } else {
      return state;
    }
};

export const saveAdvertisement = (state = [], action) => {
    if (action.type === 'ADVERTISEMENT_SAVED_SUCCESSFULLY') {
      return action.payload;
    } else {
      return state;
    }
};

export const advertisementEditData = (state = [], action) => {
    if (action.type === 'ADVERTISEMENT_EDIT_DATA_FETCHED_SUCCESSFULLY') {
      return action.payload;
    } else {
      return state;
    }
};

export const advertisementIcon = (state = [], action) => {
    if (action.type === 'ADVERTISEMENT_IMAGE_UPDATED_SUCCESSFULLY') {
      return action.payload;
    } else {
      return state;
    }
};