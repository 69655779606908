
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import {useSelector } from "react-redux";

import PindCategories from "../../../../common/header/pind-categories";
import Breadcrumbs from "../../../../common/header/breadcrumbs";
import Header from "./header";
import FormComponent from "./form";
import "_Css/form/_form.scss";

const Index = () => {
  const tr = useSelector((state) => state.spcmReducer.tr);
  const [companyType,setCompanyType] = useState([]);
  const [companyTypeErr,setCompanyTypeErr] = useState('');
  const [nameErr,setNameErr] = useState('');
  const [descriptionErr,setDescriptionErr] = useState('');

  const companyTypeFun = (list) =>{
    setCompanyType(list);
  };
  // const companyTypeErrFun = (err) => {
  //   if (companyType.length === 1) {
  //     setCompanyTypeErr("");
  //   } else {
  //     setCompanyTypeErr("error");
  //   }
  // };
  const companyTypeErrFun = (err) => {
    setCompanyTypeErr(err);
  };
 

  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Breadcrumbs activePageName={"Create"} parentPageurl='/company' parentPageName={tr["Company"]} />
      </Container>

      <Container fluid>
        <Header 
          companyType = {companyType}
          companyTypeErrFun = {companyTypeErrFun}
          setNameErr={setNameErr}
          setDescriptionErr={setDescriptionErr}
        />
        <Row>
          <Col md={8} xs={12}>
            <FormComponent 
              companyTypeFun = {companyTypeFun}          
              companyTypeErr = {companyTypeErr}
              companyTypeErrFun = {companyTypeErrFun}
              nameErr={nameErr}
              companyType={companyType}
              setNameErr={setNameErr}
              descriptionErr = {descriptionErr}
              setDescriptionErr={setDescriptionErr}
            />
          </Col>
        </Row>
      </Container>
    </main>
  )
}

export default Index;
