
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
//below URL used for testing purpose
//const breakFixUrl = 'https://xsm-f.mybluemix.net/api/SX_FAVOURITEOFFERINGs/';
import { GLOBAL }  from '../../components/Globals';
//let breakFixUrl =`https://xsm-f.mybluemix.net/api/breakfixitem?breakfixitemId=`;
//let breakFixUrl=`https://xsm-f.mybluemix.net/groupListSelfService?companyId=2932&group=ResolvedER`
export function loadBreakFixRelatedDataDetailsSuccess(breakFixRelatedDataDetails){
	//alert('in success')
	return {
		type: 'LOAD_BREAKFIXRELATEDDATADETAILS_SUCCESS',
		breakFixRelatedDataDetails
	};
}

export function loadBreakFixRelatedDataDetailsFailure(errorResponse){
	return {
		type: 'LOAD_BREAKFIXRELATEDDATADETAILS_FAILURE',
		errorResponse
	};
}
export function loadBreakFixRelatedDataDetails(breakFixId,description){
	//alert('action called and parameter is '+breakFixId)
	
	return(dispatch) =>{
		//alert("EditGroupIdAction123"+breakFixId);

				//alert("hiaaa::::");
				axios.get(GLOBAL.breakFixRelatedDataUrl+"?itemId="+breakFixId+"&module=Breakfix&sortBy=relationId&orderBy=desc")
					.then((breakFixRelatedDataDetails)=>{
						if(!breakFixRelatedDataDetails){//alert('not breakFix')
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							throw Error(breakFixRelatedDataDetails.statusText);
						}
						return breakFixRelatedDataDetails;
					})
					.then((breakFixRelatedDataDetails) =>{//alert('yes it is')
						dispatch(loadBreakFixRelatedDataDetailsSuccess(breakFixRelatedDataDetails))
					})
					.catch((err) => {
						console.log(err);
					});
					//console.log("not calling loadBreakFixEditDetails");

	};
}
