
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState} from "react";
import {Tab,Tabs} from 'react-bootstrap';
import {useSelector } from "react-redux";
import ContractAttachment from "./attachments";
import { Link } from "react-router-dom";
import { IoClose } from "react-icons/io5";

const TabsComponent = (props) => {
    const tr = useSelector((state) => state.spcmReducer.tr);
    return (
        <div className="hambTab userInfoPopupParent">
            <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
                <ul>
                    <li>
                        <Link to="javascript:void(0)" id="closeButton"
                            className="closerightPanelBtn"
                            title={tr["Minimize the details panel"]}
                            onClick={() => { props.rightEditPanel(false); props.setView(""); }}
                            >
                            <IoClose/>
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="rPageHeading">
                <div className="offNam margin-t-5 margin-b-5">
                {tr["Contract Details"]}
                </div>
            </div>
            <div className="rBoxGap">
                <div className="rBoxTabs hideTabNav">
                    <Tabs  id="tabId">
                        <Tab eventKey={1} title={tr["Provide Attachments"]}><ContractAttachment /></Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}
export default TabsComponent;