
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Field, Formik, Form, ErrorMessage } from "formik";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch, connect } from "react-redux";
import { Row, Col, Button, Form as BootstrapForm } from "react-bootstrap";

import {
  InputTextField,
  SelectField,
} from "../../../common/governance/FormFields";

import ForumDropdownPlusTypeahead from "../../common/ForumDropdownPlusTypeahead";

import {
  useGetAllCompanyQuery,
  useGetAllTrackQuery,
  useGetAllSubTrackQuery,
  useGetForumTypeQuery,
  useGetForumStatusQuery,
  useGetRegionListQuery,
  useGetForumByIdQuery,
} from "../../../../reducers/foundation/forum/forumQuery";

import { loadBusinessFunctionList } from "../../../../actions/businessFunction/businessFunctionAction";

const FormComponent = (props) => {
  const {
    errors,
    touched,
    setFieldValue,
    values,
    companyValue,
    setSelectedCompanyId,
    selectedCompanyId,
    error,
    handleSubmit,
  } = props;
  const formikRef = useRef(null);

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const froumStore = useSelector((state) => state.forums);
  //const allCompany = useSelector((state) => state.forums?.allCompanyList);
  const allBusinessFunctions = useSelector(
    (state) => state?.businessFunctionList
  );

  const forumId = params?.forumId;
  const isAddMode = !forumId;

  const [companyOptions, setCompanyOptions] = useState([]);

  const [allRegions, setAllRegions] = useState([]);
  // const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [trackName, setTrackName] = useState("");


  useEffect(() => {
    if (values && values.trackName && values.trackName.trim() !== "") {
      setTrackName(values.trackName);
    }
  }, [values]);
 

  const { data: allCompany, isFetching: companyFetching } =
    useGetAllCompanyQuery();

  const { data: allTrack, isFetching: trackFetching } = useGetAllTrackQuery(
    { companyId: selectedCompanyId },
    {
      skip: !selectedCompanyId,
    }
  );
  const { data: allSubTrack, isFetching: subTrackFetching } =
    useGetAllSubTrackQuery(
      { name: trackName },
      {
        skip: !trackName,
      }
    );

  const { data: allRegionList, isFetching: regionFetching } =
    useGetRegionListQuery();
  const { data: allTypes, isFetching: typeFetching } = useGetForumTypeQuery();
  const { data: allStatus, isFetching: statusFetching } =
    useGetForumStatusQuery();

  const onCompanySelection = async (selectedCompany, setFieldValue) => {
    if (selectedCompany[0]?.id) {
      setFieldValue("customerName", selectedCompany[0]?.label);
      setFieldValue("customerId", selectedCompany[0]?.id);
      setSelectedCompanyId(selectedCompany[0]?.id);
    }
  };

  // const onChangeTrack = async (e) => {
  //   const value = e.target.value;
  //   if (value) {
  //     dispatch(change("createForum", "trackName", value));
  //     const resp = await dispatch(getAllSubTrack(value));
  //   }
  // };

  useEffect(() => {
    const companyData = allCompany?.message
      ? []
      : allCompany?.length !== 0 &&
        allCompany?.map((item) => ({
          label: item?.field1Key,
          id: item?.field1Value,
        }));

    setCompanyOptions(companyData || []);
  }, [allCompany]);

  useEffect(() => {
    (async function () {
      await dispatch(loadBusinessFunctionList());
    })();
  }, []);

  return (
    <Row>
      <Col md={12}>
        <Row>
          <Col md={6} sm={6} xs={12}>
            <InputTextField name="forumName" label="Title" required />
          </Col>
          <Col md={6} sm={6} xs={12}>
            <SelectField
              required
              name="status"
              label="Status"
              onChange={(e) => {
                const selectedOption =
                  event.target.options[event.target.selectedIndex];
                const statusId = selectedOption.getAttribute("data-id");
                setFieldValue("status", e.target.value);
                setFieldValue("statusId", statusId);
              }}
            >
              {allStatus?.length
                ? allStatus?.map((val, index) => (
                    <option
                      value={val?.fieldNameKey1}
                      key={`status-option-${index}`}
                      data-id={val?.fieldNameValue1}
                    >
                      {val?.fieldNameKey1}
                    </option>
                  ))
                : null}
            </SelectField>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6} xs={12}>
            <BootstrapForm.Label>
              <span className="rStar"></span> Company
            </BootstrapForm.Label>
            <Field
              name="company"
              id="company"
              disabled={!isAddMode}
              className="form-control"
              component={ForumDropdownPlusTypeahead}
              errorColor={touched?.customerId && errors?.customerId ? "error" : ""}
              options={companyOptions || []}
              onSelection={(val) => onCompanySelection(val, setFieldValue)}
              {...(!isAddMode &&
                companyValue && {
                  defaultInputValue: companyValue,
                })}
              {...(!isAddMode && {
                selected: companyValue ? [companyValue] : [],
              })}
            />
          </Col>
          <Col md={6} sm={6} xs={12}>
            <SelectField
              required
              name="functionName"
              label="Function"
              onChange={(e) => {
                const selectedOption =
                  event.target.options[event.target.selectedIndex];
                const functionId = selectedOption.getAttribute("data-id");
                setFieldValue("functionName", e.target.value);
                setFieldValue("functionId", functionId);
              }}
            >
              {allBusinessFunctions?.length
                ? allBusinessFunctions?.map((val, index) => (
                    <option
                      value={val?.businessFunctionName}
                      key={`biz-${index}`}
                      data-id={val?.id}
                    >
                      {val.businessFunctionName}
                    </option>
                  ))
                : null}
            </SelectField>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6} xs={12}>
            <SelectField
              name="trackName"
              label="Track"
              onChange={(e) => {
                const selectedOption =
                  event.target.options[event.target.selectedIndex];
                const trackId = selectedOption.getAttribute("data-id");
                setFieldValue("trackId", trackId);
                setFieldValue("trackName", e.target.value);
                setFieldValue("subTrack", "");
                setTrackName(e.target.value);
              }}
            >
              {allTrack?.length
                ? allTrack.map((val, index) => (
                    <option
                      value={val.trackName}
                      key={`track-option-${index}`}
                      data-id={val?.trackId}
                    >
                      {val.trackName}
                    </option>
                  ))
                : null}
            </SelectField>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <SelectField
              name="subTrackName"
              label="Sub-Track"
              onChange={(e) => {
                const selectedOption =
                  event.target.options[event.target.selectedIndex];
                const subTrackId = selectedOption.getAttribute("data-id");
                setFieldValue("subTrackName", e.target.value || "", true);
                setFieldValue("subTrackId", subTrackId || "", true);
              }}
            >
              {allSubTrack?.length
                ? allSubTrack.map((val, index) => (
                    <option
                      value={val.subTrackName}
                      key={`subtrk-option-${val.subTrackId}`}
                      data-id={val?.subTrackId}
                    >
                      {val.subTrackName}
                    </option>
                  ))
                : null}
            </SelectField>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6} xs={12}>
            <SelectField
              name="regionName"
              label="Region"
              onChange={(e) => {
                setFieldValue("regionName", e.target.value);
                setFieldValue("regionId", 1);
              }}
            >
              {allRegionList?.result?.length > 0 &&
                allRegionList?.result?.map((region, index) => {
                  return (
                    <option value={region?.regionId} key={`region-${index}`}>
                      {region?.regionName}
                    </option>
                  );
                })}
            </SelectField>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <SelectField
              required
              name="type"
              label="Type"
              onChange={(e) => {
                const selectedOption =
                  event.target.options[event.target.selectedIndex];
                const typeId = selectedOption.getAttribute("data-id");
                setFieldValue("type", e.target.value);
                setFieldValue("typeId", typeId);
              }}
            >
              {allTypes?.length > 0 &&
                allTypes?.map((type, index) => {
                  return (
                    <option
                      value={type?.fieldNameKey1}
                      key={`type-${index}`}
                      data-id={type?.fieldNameValue1}
                    >
                      {type?.fieldNameKey1}
                    </option>
                  );
                })}
            </SelectField>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <BootstrapForm.Group className="form-group">
              <BootstrapForm.Label>
                <span className="rStar"></span> Objectives
              </BootstrapForm.Label>
              <Field
                required
                rows={2}
                name="objective"
                as="textarea"
                className={`form-control ${
                  touched?.objective && errors?.objective ? "is-invalid" : ""
                }`}
                onChange={(e) => {
                  setFieldValue("objective", e.target.value);
                }}
              />
              <ErrorMessage
                name="objective"
                component="span"
                className="invalid-feedback text-right"
              />
            </BootstrapForm.Group>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default FormComponent;
