
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Form from 'react-bootstrap/Form';
import W51H from './5W1H';
import Whys5 from './5Whys';
import Convergence_Divergence from './Convergence-Divergence';
import { getRCAtechniques } from '_Actions/investigation/RCATechniquesActions';
import { connect } from 'react-redux';
import {IoClose} from "react-icons/io5";

class RecordInvestigationFindings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRCAMethod: '',
        };
        this.onSelect = this.onSelect.bind(this);
        this.renderTechniques = this.renderTechniques.bind(this);
        this.hideForm = this.hideForm.bind(this);
    }

    componentWillMount(){
        this.props.getRCAtechniques("investigation","RcaTechniques");
    }
    hideForm(){
        this.setState({selectedRCAMethod: ''});
    }
    onSelect(event){
        this.setState({selectedRCAMethod: event.target.value});
    }
    renderTechniques(){
        if(this.props.rcaTechniques.length===0){
           return null;
        }
        return this.props.rcaTechniques.map((item) => {
            return (<option value={item.field1Value}>{item.field1Key}</option>);
        });
    }
    render(){
      console.log("MMM this.state.selectedRCAMethod: ", this.state.selectedRCAMethod)
        const {showTitle = true} = this.props;
        let mountedComponent = '';
        if(this.state.selectedRCAMethod==='120'){
            mountedComponent = <Whys5 makeDisable={false} noofWhys={5} whysLabel={['First', 'Second', 'Third', 'Fourth', 'Fifth']} hideForm={this.hideForm} techniqueID={this.state.selectedRCAMethod} translator={this.props.translator} disableFieldsForGuestRole={this.props.disableFieldsForGuestRole} isServiceDeskRolesEnabled={this.props.isServiceDeskRolesEnabled}/>;
        }else if(this.state.selectedRCAMethod==='125'){
            mountedComponent = <W51H makeDisable={false} noofWhys={6} whysLabel={['What', 'Where', 'When', 'Who', 'Why','How']} hideForm={this.hideForm} techniqueID={this.state.selectedRCAMethod} translator={this.props.translator} disableFieldsForGuestRole={this.props.disableFieldsForGuestRole} isServiceDeskRolesEnabled={this.props.isServiceDeskRolesEnabled}/>;
        }else if(this.state.selectedRCAMethod==='130'){
            mountedComponent = <Convergence_Divergence makeDisable={false} hideForm={this.hideForm} techniqueID={this.state.selectedRCAMethod} translator={this.props.translator} disableFieldsForGuestRole={this.props.disableFieldsForGuestRole} isServiceDeskRolesEnabled={this.props.isServiceDeskRolesEnabled}/>;
        }
        return (<div className="hambTab">
                <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
					<ul>
						<li>
						<button
                            type='button'
							title="Minimize the right panel"
							bsClass=""
							bsStyle=""
							className="closerightPanelBtn"
							onClick={() => {
							this.props.rightEditPanel(false);
							}}
						>
							<IoClose />
						</button>
						</li>
					</ul>
				</div>
                  {(showTitle == true) && <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator["Record Problem Findings"]}</div></div>}
                  <div className={showTitle==true ? 'rBoxGap':''} >
            <Form.Group className='margin-b-20'>
                   <Form.Label bsClass="">{this.props.translator["RCA Techniques"]}</Form.Label>
                   <Form.Select componentClass="select" name="selectRCA"  type="select" className="form-control"  onChange={(e)=>{this.onSelect(e)}} >
                   <option value='' selected={this.state.selectedRCAMethod===''?true:false}>Select</option>
                   {this.renderTechniques()}
                   </Form.Select>
            </Form.Group>
               <div>
                   {mountedComponent}
               </div>
            </div>
        </div>

        )
    }
}

const mapStateToProps = ({ rcaTechniques,investigationItemDetails }) => {
	return {
		rcaTechniques,investigationItemDetails
	};
};

export default connect(mapStateToProps, { getRCAtechniques })(RecordInvestigationFindings);


