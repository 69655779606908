
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export default function (state = initialState.profileDetails, action){

	switch (action.type){
		case 'LOAD_PROFILE_SUCCESS':
				//alert(action.breakFix.data.);
				return action.profileDetails.data;
		case 'LOAD_PROFILE_FAILURE':
				return action.failureMessage;
		default:
				return state;
		}

}
