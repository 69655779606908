
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect } from "react";
import ReactMultiSelectCheckboxes, {
  components,
} from "react-multiselect-checkboxes";
import { useDispatch, useSelector } from "react-redux";
import { usePreviousValue } from "../../helpers/usePreviousValue";
import {IoClose} from "react-icons/io5";

const MultiSelectCheckboxWithOK = (parentProps) => {
  const previousValue = usePreviousValue(parentProps);

  const dispatch = useDispatch();
  const isMultiSelectFilterApplied = useSelector(
    (state) => state.multiSelectDropDownWithOk
  );

  const onCancelClick = () => {
    console.log('Cancel Props --- ', parentProps)
    const { keyName } = parentProps;
    switch (keyName) {
      case "myReqStatus":
        parentProps.setSelectedValueFun(parentProps.lastSelectedStatusFilter);
        break;
      case "sericeBoardStatus":
        parentProps.setSelectedValueFun(parentProps.lastSelectedStatusFilter);
        break;
    }
    if (
      parentProps.lastSelectedStatusFilter.length === parentProps.options.length
    ) {
      parentProps.selectAllLabelFun(parentProps.keyName, true);
    } else {
      parentProps.selectAllLabelFun(parentProps.keyName, false);
    }
    parentProps.multiSelectOpenFun(parentProps.keyName, false);
  };

  const onOkFun = () => {
    dispatch({
      type: "MULTI_SELECT_DROPDOWN_WITH_OK",
      payload: true,
    });
    parentProps.OkSubmitFun();
    parentProps.multiSelectOpenFun(parentProps.keyName, false);
  };

  const loadDafultStatus = () => {
    parentProps.multiSelectOpenFun("allFalse", false);
    if (parentProps.keyName == "myReqStatus") {
      let taskDefaultStatus = [
        { label: parentProps.tr["Draft"], value: "-20" },
        { label: parentProps.tr['In Progress'], value: "15" },
        { label: parentProps.tr["On Hold"], value: "60" },
        { label: parentProps.tr['Pending'], value: "20" },
        { label: parentProps.tr["Pending Approval"], value: "65" },
        { label: parentProps.tr["Referred Back"], value: "100" },
        { label: parentProps.tr["Submitted"], value: "10" },
        { label: parentProps.tr["Under Fulfillment"], value: "70" },
      ];
      if (parentProps.selectValue.length > 0) {
        taskDefaultStatus = parentProps.selectValue;
      }
      parentProps.showCheckBoxOptions(taskDefaultStatus);
    } else if (parentProps.keyName == "sericeBoardStatus") {
      let taskDefaultStatus = [
        { label: "Draft", value: "Draft" },
        { label: "Deployed", value: "Deployed" },
        { label: "Proposed", value: "Proposed" },
        { label: "Suspended", value: "Suspended" },
      ];
      if (parentProps.selectValue.length > 0) {
        taskDefaultStatus = parentProps.selectValue;
      }
      parentProps.showCheckBoxOptions(taskDefaultStatus);
    }
  };

  const setSelectAllFun = (module) => {
    if (module === "Select All") {
      parentProps.selectAllLabelFun(parentProps.keyName, true);
      parentProps.showCheckBoxOptions(parentProps.options);
    } else {
      parentProps.selectAllLabelFun(parentProps.keyName, false);
      parentProps.showCheckBoxOptions([]);
    }
  };

  useEffect(() => {
    if (parentProps.keyName == "myReqStatus") {
      if (previousValue && (parentProps.isOpen !== previousValue.isOpen)) {
        if(!parentProps.isOpen && 
          !isMultiSelectFilterApplied && 
          (parentProps.showLoader_1 !== previousValue.showLoader_1 ||
          parentProps.showLoader_2 !== previousValue.showLoader_2 ||
          parentProps.showLoader_3 !== previousValue.showLoader_3)) {
          parentProps.showCheckBoxOptions(parentProps.selectValue);
        } else if(!parentProps.isOpen && !isMultiSelectFilterApplied) {
          onCancelClick();
        }
        if(parentProps.isRefreshClick && !parentProps.isOpen && (parentProps.showLoader_1 !== previousValue.showLoader_1 ||
          parentProps.showLoader_2 !== previousValue.showLoader_2 ||
          parentProps.showLoader_3 !== previousValue.showLoader_3)) {
            onCancelClick();
            parentProps.refreshClickFun(false);
          }
      }
      if (
        previousValue &&
        (parentProps.showLoader_1 !== previousValue.showLoader_1 ||
          parentProps.showLoader_2 !== previousValue.showLoader_2 ||
          parentProps.showLoader_3 !== previousValue.showLoader_3)
      ) {
        // if (!isMultiSelectFilterApplied && parentProps.isOpen !== previousValue.isOpen) {
        //   loadDafultStatus();
        // }
        if (
          !parentProps.showLoader_3 ||
          !parentProps.showLoader_2 ||
          !parentProps.showLoader_1
        ) {
          dispatch({
            type: "MULTI_SELECT_DROPDOWN_WITH_OK",
            payload: false,
          });
        }
      }
    }
    if (parentProps.keyName == "sericeBoardStatus") {
      if (previousValue && (parentProps.isOpen !== previousValue.isOpen)) {
        if(!parentProps.isOpen && !isMultiSelectFilterApplied && parentProps.isFetchingDetails !== previousValue.isFetchingDetails) {
          parentProps.showCheckBoxOptions(parentProps.selectValue);
        } else if(!parentProps.isOpen && !isMultiSelectFilterApplied) {
          onCancelClick();
        }
        if(parentProps.isRefreshClick && !parentProps.isOpen && parentProps.isFetchingDetails !== previousValue.isFetchingDetails) {
            onCancelClick();
            parentProps.refreshClickFun(false);
          }
      }
      if (
        previousValue &&
        parentProps.isFetchingDetails !== previousValue.isFetchingDetails
      ) {
          if (!parentProps.isFetchingDetails) {
          dispatch({
            type: "MULTI_SELECT_DROPDOWN_WITH_OK",
            payload: false,
          });
        }
      }
    }
  }, [parentProps]);

  useEffect(() => {
    loadDafultStatus();
  }, []);

  let Control = ({ children, props }) => { 
   let unSelectAllDisplay = parentProps.tr["Unselect All"];
   let selectAllDisplay = parentProps.tr["Select All"];

    return (
          <components {...props} className="customReactSelectMenu">
        <div className="btnhed">
          <div>
            <a
              title={
                parentProps.isSelectAllLabel ||
                parentProps.selectValue.length === parentProps.options.length
                  ? parentProps.tr["Unselect All"]
                  : parentProps.tr["Select All"]
              }
              role="button"
              onMouseDown={() => {
                setSelectAllFun(
                  parentProps.isSelectAllLabel ||
                    parentProps.selectValue.length ===
                      parentProps.options.length
                    ?  "Unselect All"
                    :  "Select All"
                );
              }}
              href="javascript:void(0)"
            >
                            
               {parentProps.isSelectAllLabel ||
              parentProps.selectValue.length === parentProps.options.length
                ? <>{unSelectAllDisplay.slice(0, 10)}{unSelectAllDisplay.length > 10 ? "..." : ''}</> // parentProps.tr["Unselect All"]
                : <>{selectAllDisplay.slice(0, 10)}{selectAllDisplay.length > 10 ? "..." : ''}</> //parentProps.tr["Select All"]
              } 
              
              {/* {
                  parentProps.isSelectAllLabel || parentProps.selectValue.length === parentProps.options.length
                  ? <>{unSelectAllDisplay.slice(0, 8)}{unSelectAllDisplay.length > 8 ? "..." : ''}</>
                  : <>{selectAllDisplay.slice(0, 8)}{selectAllDisplay.length > 8 ? "..." : ''}</>
              } */}
            </a>
            <div className="okbtnmulti">
              <a
                title={parentProps.tr["OK"]}
                role="button"
                href="javascript:void(0)"
                onMouseDown={() => {
                  onOkFun();
                }}
              >
               {parentProps.tr["OK"]}
              </a>
              <a
                role="button"
                title={parentProps.tr["Close"]}
                className="clos"
                href="javascript:void(0)"
                onMouseDown={() => {
                  onCancelClick();
                }}
              >
                <IoClose />
              </a>
            </div>
          </div>
        </div>
        {children}
      </components>
    );
  };
  return (
    <ReactMultiSelectCheckboxes
      placeholderButtonLabel={parentProps.tr["Select"]}
      value={parentProps.selectValue}
      rightAligned={false}
      options={parentProps.options}
      menuIsOpen={parentProps.isOpen}
      onChange={(selected, event) => {
        if (event.action === "deselect-option") {
          parentProps.selectAllLabelFun(parentProps.keyName, false);
          return parentProps.showCheckBoxOptions(
            selected.filter((o) => o.value !== "*")
          );
        } else if (event.action === "select-option") {
          if (selected.length === parentProps.options.length) {
            parentProps.selectAllLabelFun(parentProps.keyName, true);
          }
          return parentProps.showCheckBoxOptions(
            selected.filter((o) => o.value !== "*")
          );
        } else if (selected.length === parentProps.options.length - 1) {
          parentProps.selectAllLabelFun(parentProps.keyName, true);
          return parentProps.showCheckBoxOptions(parentProps.options);
        } else {
          parentProps.selectAllLabelFun(parentProps.keyName, true);
          return parentProps.showCheckBoxOptions(selected);
        }
      }}
      components={{ Control }}
    />
  );
};

export default MultiSelectCheckboxWithOK;
