
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';


class XsmBreakFixAction0 extends React.Component {

  constructor(props) {
    super(props);
    //this.props.loadBreakFix();
  };
   render() {
     return (
  		<div>

  		</div>
      );
   }
}
export default XsmBreakFixAction0;
