
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GLOBAL } from "../../components/Globals";

export const schedulerApi = createApi({
  reducerPath: "schedulerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: window.location.origin,
  }),
  tagTypes: ["schedulerUi", "Scheduler"],
  endpoints: (builder) => ({
    getAllSchedulers: builder.query({
      query: ({ queryString = "" }) => {
        return GLOBAL.getAllSchedulers + queryString;
      },
      providesTags: (result, error, arg) => [
        { type: "Scheduler", id: "REFRESH" },
      ],
    }),
    getSchedulerDetailById: builder.query({
      query: ({ schedulerId = "" }) => {
        return GLOBAL.getSingleScheduler(schedulerId);
      },
      providesTags: (result, error, arg) => [{ type: "Scheduler", id: "LIST" }],
    }),
    // saveSuppliersScorecard: builder.mutation({
    //   query: ({ data, queryString }) => ({
    //     url: GLOBAL.saveSuppliersScorecard + queryString,
    //     method: "POST",
    //     body: data,
    //   }),
    //   invalidatesTags: [{ type: "Quadrant", id: "LIST" }],
    // }),
    // submitSuppliersScoreCard: builder.mutation({
    //   query: ({ queryString }) => ({
    //     url: GLOBAL.submitSuppliersScoreCard + queryString,
    //     method: "PATCH",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   }),
    // }),
  }),
});

export const { useGetAllSchedulersQuery, useGetSchedulerDetailByIdQuery } =
  schedulerApi;

const { reducer } = schedulerApi;
export default reducer;
