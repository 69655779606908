
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";

import moment from "moment";

const dateFormat = "YYYY/MM/DD";
export const getStartDate = (days) => {
  let skipCurrent = moment().subtract(0, "days").format(dateFormat);
  const iniStartDate = moment(skipCurrent + " 00:00:00").subtract(days, "days");
  return iniStartDate.valueOf();
};
export const getEndDate = () => {
  let skipCurrent = moment().subtract(0, "days").format(dateFormat);
  const iniEndDate = moment(skipCurrent + " 23:59:59");
  return iniEndDate.valueOf();
};
export const getFutureDate = (days) => {
  let skipCurrent = moment().add(days, "days").format(dateFormat);
  const iniEndDate = moment(skipCurrent + " 23:59:59");
  return iniEndDate.valueOf();
};
export const getTodayDate = () => {
  let skipCurrent = moment().subtract(0, "days").format(dateFormat);
  const iniEndDate = moment(skipCurrent + " 23:59:59");
  return iniEndDate.valueOf();
};
export const converDateToMiliSecond = (date) => {
  const iniEnd = moment(date).format(dateFormat);
  const iniEndDate = moment(iniEnd + " 23:59:59");
  return iniEndDate.valueOf();
};
export const converStartDateToMiliSecond = (date) => {
  const iniEnd = moment(date).format(dateFormat);
  const iniEndDate = moment(iniEnd + " 00:00:00");
  return iniEndDate.valueOf();
};
export const formateDate = (date) => {
  const iniStartDate = moment(date).format("Do-MMMM-YYYY");
  return iniStartDate;
};
export const formateDateYYYYMMDD = (date) => {
  const iniStartDate = moment(date).format("YYYY/MM/DD");
  return iniStartDate;
};
export const formateDateYYYY_M_DD = (date) => {
  const iniStartDate = moment(date).format("YYYY-MM-DD");
  return iniStartDate;
};
export const userDateFormate = (date, format) => {
  return (
    "Updated as on " +
    moment().format(`${getDateWord(format)}${findTimeFormate(format)}`)
  );
};
function hasWhiteSpace(s) {
  return /\s/g.test(s);
}
export const userDateFormateforFilter = (date, format) => {
  if (date) {
    const iniStartDate = moment(new Date(date)).format(format.toUpperCase());
    if (hasWhiteSpace(iniStartDate)) {
      return iniStartDate.split(" ")[0];
    }
    return iniStartDate;
  }
  return "";
};
export const userDateFormateForList = (date, format, type) => {
  if (date) {
    const iniStartDate = moment(new Date(date)).format(format.toUpperCase());
    if (iniStartDate === "Invalid date") {
      return date;
    }

    if (!iniStartDate.includes("T") && type !== "taskListing") {
      return iniStartDate + "T";
    }
    return iniStartDate;
  }
  return "";
};
export const formateDateMMDDYYYY = (date) => {
  const iniStartDate = moment(date).format("DD-MM-YYYY");
  return iniStartDate;
};

export const downloadImage = (data, filename) => {
  var a = document.createElement("a");
  a.href = data;
  a.download = filename ? filename : "untitled.png";
  document.body.appendChild(a);
  a.click();
};
export const AddHideIntegrationManagement = (txt) => {
  let allGraphView = document.getElementById("AllGraphViewIntegration");
  if (allGraphView) {
    allGraphView.classList.remove("col-sm-8");
    allGraphView.classList.add("col-sm-12");
  }

  let listViewComponent = document.getElementById(
    "ListViewComponentIntegrationManagement"
  );
  if (listViewComponent) {
    listViewComponent.classList.add("hide");
  }
};

export const removeHideIntegrationManagement = (txt) => {
  let allGraphView = document.getElementById("AllGraphViewIntegration");
  if (allGraphView) {
    allGraphView.classList.remove("col-sm-12");
    allGraphView.classList.add("col-sm-8");
  }
  let listViewComponent = document.getElementById(
    "ListViewComponentIntegrationManagement"
  );
  if (listViewComponent) {
    listViewComponent.classList.remove("hide");
  }
};
export const minimizeMaxmizeList = (txt) => {
  let allGraphView = document.getElementById("AllGraphView");
  if (allGraphView) {
    allGraphView.classList.remove("col-sm-12");
    allGraphView.classList.add("col-sm-8");
  }
  let listViewComponent = document.getElementById("ListViewComponent");
  if (listViewComponent) {
    listViewComponent.classList.remove("hide");
  }
};
export const removeHideClass = (id) => {
  let listViewComponent = document.getElementById(
    id || "ListViewComponentDhashboard"
  );

  if (listViewComponent) {
    listViewComponent.classList.remove("hide");
  }
};
export const addHideClass = (id) => {
  let listViewComponent = document.getElementById(
    id || "ListViewComponentDhashboard"
  );
  if (listViewComponent) {
    listViewComponent.classList.add("hide");
  }
};
export const removeHideClassNewDesign = () => {
  let listViewComponent = document.getElementById("ListViewComponentNewDesign");
  if (listViewComponent) {
    listViewComponent.classList.remove("hide");
  }
};
export const addHideClassClassNewDesign = () => {
  let listViewComponent = document.getElementById("ListViewComponentNewDesign");
  if (listViewComponent) {
    listViewComponent.classList.add("hide");
  }
};
export const maxmizeList = (txt) => {
  let allGraphView = document.getElementById("AllGraphView");
  if (allGraphView) {
    allGraphView.classList.remove("col-sm-8");
    allGraphView.classList.add("col-sm-12");
  }

  let listViewComponent = document.getElementById("ListViewComponent");
  if (listViewComponent) {
    listViewComponent.classList.add("hide");
  }
};
export const prefDateFormatAccordingUser = (dateFormat, isSecondSkip) => {
  let _dateFormat = dateFormat;
  if (_dateFormat !== "") {
    _dateFormat = `${getDateWord(dateFormat, isSecondSkip)}${findTimeFormate(
      dateFormat,
      isSecondSkip
    )}`;
  }

  return _dateFormat;
};

export const getDateWord = (dateFormat, isSecondSkip) => {
  if (dateFormat) {
    if (isSecondSkip) {
      dateFormat = dateFormat?.replace(":ss", "");
    }
    let spaceIndex = dateFormat.indexOf(" ");
    return spaceIndex === -1
      ? dateFormat.toUpperCase()
      : dateFormat.substring(0, spaceIndex).toUpperCase();
  }
  return "";
};

export const findTimeFormate = (dateFormat, isSecondSkip) => {
  if (dateFormat) {
    if (isSecondSkip) {
      dateFormat = dateFormat?.replace(":ss", "");
    }
    dateFormat = dateFormat?.replace("a", "A");
    let spaceIndex = dateFormat?.indexOf(" ");
    if (dateFormat.length > spaceIndex) {
      return dateFormat?.substring(spaceIndex, dateFormat.length);
    }
    return "";
  }
  return "";
};

// Set User Preference Date Format
const setUserPrefDateFormat = (prefData, userInfo) => {
  let _dateFormat =
    Object.keys(prefData).length !== 0 ? prefData.dateFormat.toUpperCase() : "";
  if (_dateFormat !== "") {
    _dateFormat = _dateFormat.split(":");
    _dateFormat =
      _dateFormat[0] +
      ":" +
      _dateFormat[1].toUpperCase() +
      ":" +
      _dateFormat[2].toUpperCase();
  } else {
    _dateFormat = userInfo.dateFormat;
  }

  return _dateFormat;
};

export const userPrefDateFormat = (value, prefData, userInfo) => {
  return moment
    .utc(value)
    .tz(prefData.timeZoneVal !== undefined && prefData.timeZoneVal)
    .format(setUserPrefDateFormat(prefData, userInfo));
};
export const disabledTime = (current) => {
  if (current && moment(current) > moment()) {
    return {
      disabledHours: () => enabledFuture(),
      disabledMinutes: () => getFutureDisabledMinutes(),
    };
  }
  return {
    disabledHours: () => getDisabledHours(),
    disabledMinutes: () => getDisabledMinutes(moment(current).hour()),
  };
};
const enabledFuture = () => {
  return [];
};
const getFutureDisabledMinutes = () => {
  return [];
};
const getDisabledHours = () => {
  var hours = [];
  for (var i = 0; i < moment().hour(); i++) {
    hours.push(i);
  }
  return hours;
};

const getDisabledMinutes = (selectedHour) => {
  var minutes = [];
  if (selectedHour === moment().hour()) {
    for (var i = 0; i < moment().minute(); i++) {
      minutes.push(i);
    }
  }
  return minutes;
};

export const replaceKeyInObjectArray = (a, r) => {
  if (a && r) {
    return a.map((o) =>
      Object.keys(o)
        .map((key) => ({ [r[key] || key]: o[key] || "" }))
        .reduce((a, b) => Object.assign({}, a, b))
    );
  }
  return [];
};

export const replaceKey = (
  replacekeyTo1,
  replaceKeyTo2,
  replacekeyFrom1,
  replacekeyFrom2,
  data,
  extraKey,
  extraKeyReplace,
  imgeUrl
) => {
  if (data) {
    const transformed = data.map((obj) => {
      let result = {};
      result[replacekeyFrom1] = obj[replacekeyTo1] || "";
      result[replacekeyFrom2] = obj[replaceKeyTo2] || "";
      if (extraKey && extraKeyReplace) {
        result[extraKeyReplace] = obj[extraKey] || imgeUrl;
      }
      return result;
    });

    return transformed;
  }
  return [];
};

export const getFormetedText = (addInfo) => {
  const afterSplit = addInfo.split("\n");
  const newAddInfo = afterSplit.map((item) => {
    return <span className="text-indent-0">{item}</span>;
  });
  return newAddInfo;
};
