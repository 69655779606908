
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
// import {FormGroup,FormControl,ControlLabel} from 'react-bootstrap';
import { connect } from 'react-redux';
// import { getTranslation} from '_Actions/spcmActions';
import Form from 'react-bootstrap/Form';
import { navigationHooks } from '../../../helpers/NavigationHook';

class LanguageDropDown extends React.Component {
	constructor(props){
		super(props);
		this.onChange = this.onChange.bind(this);
	}

	onChange(e){
		// this.props.getTranslation(e.target.value);
	}
   render() {

	const {tr,lang}=this.props;

	//zh-Hans zh-cn  zh-tw
      return (
		<Form.Group controlId="formControlsSelect" className="languageDropDown d-none d-lg-inline-block">{this.props.selectedLanguage}
			{/* <FormControl componentClass="select" value={lang} onChange={this.onChange}>*/}
				{/* <option value="en" >{this.props.selectedLanguage}</option> */}
				{/* <option value="de"  >Deutsch</option>
				<option value="es" >Español</option>
				<option value="fr">Français</option>
				<option value="ko" >한국어</option>
				<option value="it" >Italiano</option>
				<option value="ja" >日本語</option>
				<option value="pt-BR" >Português/Brasil</option>
				<option value="zh-Hant" >繁體中文</option>
				<option value="zh-Hans">简体中文</option>
				<option value="hi" >Hindi</option> */}
			{/* </FormControl> */}
		</Form.Group>
      );
   }
}

const mapStateToProps = ({spcmReducer,cartCount}) => {
	return {
		lang:spcmReducer.lang,
		tr:spcmReducer.tr,
    selectedLanguage:cartCount.languageText
	}
};

export default connect(mapStateToProps, {} )(LanguageDropDown);


