
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import {Row, Col, Form, Button, Table, Alert} from 'react-bootstrap';
import { connect } from 'react-redux';
import { getServiceBilling,setEditServiceDetails,setForm,getServiceDetailCounts,updateHideFromConsumerPrice } from '../../../actions/spcmActions';
import ComponentForm from './editForm.js';
import constants from '../../../utils/constants';
import axios from 'axios';
import Pagination from "react-js-pagination";
import ListLoader from "_Commons/loaders/ListLoader";
import Swal from 'sweetalert2';
import {IoSearch} from 'react-icons/io5';

class ListView extends React.Component {
	constructor(props){
		super(props);
		this.state={
			queryBy:'Component',
			sortBy:'Component',
			query:'',
			deleteError:'',
			sortIcons: false ,
			class1: 'sort up',
			class2: 'sort',
			sortByTitle:'',
			hideFromConsumer:"",
			hideFromConsumerError:''

		}
		this.sortFun = this.sortFun.bind(this);
		this.onTextChange=this.onTextChange.bind(this);
		this.onPageChange=this.onPageChange.bind(this);
		this.onPageSizeChange=this.onPageSizeChange.bind(this);
		this.onSearchClick=this.onSearchClick.bind(this);
		this.queryBySelect=this.queryBySelect.bind(this);
		this.onDeleteClick=this.onDeleteClick.bind(this);
		this.onSort = this.onSort.bind(this);
		this.sortHover = this.sortHover.bind(this);
		this.hideFromConsumer = this.hideFromConsumer.bind(this);
		this.handleDismiss = this.handleDismiss.bind(this);
	}

	handleKeyPress(e){
		if (e.key === 'Enter'){
			this.onSearchClick();
			if(e.preventDefault) e.preventDefault();
		}
	}

	handleDismiss() {
		this.setState({ hideFromConsumerError: '' });
	  }

	sortFun(myObj){
		this.setState({ sortIcons:!this.state.sortIcons});
		switch(myObj) {
			case 'class1':
				this.setState({ class1:(this.state.sortIcons ? 'sort up' : 'sort down')});
				this.setState({ class2:'sort',sortBy:'Component'});
				this.props.getServiceBilling(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy,this.state.query,'Component',this.state.sortIcons?'asc':'desc');
			break;
			case 'class2':
				this.setState({ class1:'sort'});
				this.setState({ class2:(this.state.sortIcons ? 'sort up' : 'sort down'),sortBy:'Price'});
				this.props.getServiceBilling(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy,this.state.query,'Price',this.state.sortIcons?'asc':'desc');
			break;
		}

	};

	onTextChange(e){
		this.setState({query:e.target.value});
	}

	onPageSizeChange(e){
		this.setState({pageSize:e.target.value});
		this.props.getServiceBilling(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy,this.state.query,this.state.sortBy,this.state.sortIcons?'desc':'asc',1,e.target.value);
	}

	onPageChange(e){
		this.props.getServiceBilling(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy,this.state.query,this.state.sortBy,this.state.sortIcons?'desc':'asc',e,this.state.pageSize);
	}

	onSearchClick(){
		this.props.getServiceBilling(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy,this.state.query,this.state.sortBy);
	}

	queryBySelect(e){
		this.setState({queryBy:e.target.value});
	}

	sortHover(e){
		let index = e.nativeEvent.target.selectedIndex;
		this.setState({sortByTitle:e.nativeEvent.target[index].text})
	}


	onDeleteClick(id){
		Swal.fire({
            title: 'Are you sure you wish to delete this item?',
			
            width: 550,
            padding: '15px',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
          }).then((result) => {
            if (result.isConfirmed) {
				axios.delete("/service/api/deleteServiceBilling/"+id).then((resp)=>{
					this.props.getServiceBilling(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
					this.props.getServiceDetailCounts(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
				}).catch((error)=>{
					this.setState({deleteError:error.response.data[0].message})
				})
            } else if (result.isDenied) {

            }
          })
	}


	onSort(e){
		this.setState({sortBy:e.target.value});
		this.props.getServiceBilling(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy,this.state.query,e.target.value)
	}


	hideFromConsumer(e){
		let hasPrice =false;
		for(let i=0;i<this.props.spcmReducer.serviceBilling.length;i++){
			if(this.props.spcmReducer.serviceBilling[i].PRICE>0){
				hasPrice=true;
				break;
			}
		}
		if(hasPrice){
			console.log('hasPrice:' +  hasPrice);
			this.setState({hideFromConsumerError:'Pricing can be hidden only when the service price is zero'});
			e.preventDefault();
		}
		else{
			axios.patch(constants.SERVICEPATCH_API_URL+"/"+this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,{"hide_price":e.target.checked ? "true":"false" });
		    this.props.updateHideFromConsumerPrice(e.target.checked ? "true":"false")
		}

		
	}

	
	componentWillMount(){
		this.props.setForm("serviceBillingForm");
		this.props.getServiceBilling(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);


		if(this.props.spcmReducer.updatedHidePriceStatus){
			if(this.props.spcmReducer.updatedHidePriceStatus == "true"){
				this.setState({hideFromConsumer:true})
			}
			else{
				this.setState({hideFromConsumer:false})
			}
		}
		else{
			if(this.props.spcmReducer.currentlySelectedServiceDetails.HIDE_PRICE === "true"){
				this.setState({hideFromConsumer:true})
			}
			else{
				this.setState({hideFromConsumer:false})
			}
		}
	}



   render() {
	   console.log("sdfljl sljflj fls fl jflws");
	//alert(this.state.deleteError)
	const tr=this.props.tr;
	   const meta=this.props.spcmReducer.meta;
		 const serviceBilling=this.props.spcmReducer.serviceBilling;
		 console.log(serviceBilling);
		 //console.log(serviceBilling.length)
		 //alert(serviceBilling.length)
		 console.log('spcmReducer');
		 console.log(this.props.spcmReducer);

      return (
			<div className="">
			{this.props.componentFormToggle3 ? <ComponentForm tr={tr} componentFormFun4={this.props.componentFormFun3} /> : <div className='rBoxGap'>
			{this.state.deleteError == '' ? null :  <Alert variant="danger" className="errorMessageSec"><p>{this.state.deleteError}</p></Alert>}

			{this.state.hideFromConsumerError == '' ? null :  <Alert style={{"marginBottom":"15px","fontSize":"13px","padding":"8px 30px 8px 8px"}} variant="danger" onDismiss={this.handleDismiss}><h4>{this.state.hideFromConsumerError}</h4></Alert>}
			
			{/* <div>
				<label>{tr['Hide from Consumer']}<input type="checkbox" name="hide_price" onChange={this.hideFromConsumer}   //defaultChecked={this.props.spcmReducer.currentlySelectedServiceDetails.HIDE_PRICE === "true" ? true : false} 
				
				defaultChecked={this.state.hideFromConsumer}
				
				disabled={this.props.spcmReducer.currentlySelectedServiceDetails.STATUS === 'Deployed'} />  </label>
			</div> */}

				<Row className="">
					<Col lg={8} md={12} sm={8} xs={12}>
						<div className="form-inline rightSideFields d-lg-flex d-md-block d-sm-flex d-block">
							<Form.Select onMouseOver={this.sortHover}  title={this.state.sortByTitle}   className="myControl mySel mySelectWidth" onChange={ this.queryBySelect }>
								<option value="Component">Component</option>
								<option value="Price">Price</option>
							</Form.Select>
							<div className='d-flex'>
							<Form.Control onKeyPress={this.handleKeyPress.bind(this)} className="myControl myInput margin-l-5 myInputWidth" type="text" onChange={ this.onTextChange }/>
							<Button type="submit" title="Search" onClick={ this.onSearchClick } className="myBtn"><IoSearch/></Button>
							</div>
						</div>
					</Col>
					<Col lg={4} md={12} sm={4} xs={12} className="text-r rightSideFields">

						<Form.Label className="margin-r-5 display-inline-block widthAuto">{tr['Show']}</Form.Label>
						<Form.Select className="display-inline-block widthAuto" componentClass="select" onChange={ this.onPageSizeChange }>
						<option value="10" selected={this.state.pageSize==10?true:false}>10</option>
						<option value="25" selected={this.state.pageSize==25?true:false}>25</option>
						<option value="50" selected={this.state.pageSize==50?true:false}>50</option>
						<option value="100" selected={this.state.pageSize==100?true:false}>100</option>
						</Form.Select>
					</Col>

				</Row>
				
				{this.props.spcmReducer.spcmFetchingError == true  && this.props.spcmReducer.spcmFetchingModule == 'SERVICE_BILLING' && this.props.spcmReducer.spcmErrorMsg ?  <Alert variant="danger" className="errorMessageSec margin-t-20"><p>{this.props.spcmReducer.spcmErrorMsg}</p></Alert> :  this.props.spcmReducer.spcmFetchingError == true  && this.props.spcmReducer.spcmFetchingModule == 'SERVICE_BILLING' && !this.props.spcmReducer.spcmErrorMsg ? <p>Oops! Something went wrong.</p> : null}


				<div className="f-size-12">
					<Table bordered condensed hover responsive className="tableView sortImg tableWithOutPointer">
					<thead>
					  <tr>
						<th width="70%" className={this.state.class1} onClick={()=>{this.sortFun('class1')}}>{tr['Component']}</th>
						<th width="20%" className={this.state.class2} onClick={()=>{this.sortFun('class2')}}> {tr['Price']}</th>
						<th width="10%" className="text-c">{tr['Action']}</th>
					  </tr>
					</thead>
					{this.props.spcmReducer.spcmFetchingAttribute  ? 
						<tbody><td colSpan="4">
							<ListLoader />
						</td></tbody> 
					
					:
					<tbody>
						{serviceBilling.map(function(value,index){
				
							return (<tr>
						<td>{value.PARENT_NAME}</td>
						<td>{value.PRICE}</td>
						<td>
						<div className="text-c">
							<i title={tr["Edit"]} onClick={()=>{
									if (value.BILLINGDEF_TYPE=="Service")
										value.PARENT_ID=this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_NAME;
										// value.PARENT_ID;
									this.props.setEditServiceDetails(value);
									this.props.componentFormFun3(true,'Edit',value.BILLINGDEF_ID);
								}} className="fa fa-pencil-square-o cursorPoint"></i>
							<i title={tr["Delete"]} className="margin-l-10 fa fa-trash-o cursorPoint" onClick={()=>{this.onDeleteClick(value.BILLINGDEF_ID)}}></i>
						</div>
						</td>
					  </tr>)
						},this)}
					</tbody>}
					</Table>
				</div>


				<div className="nBotPagina">
                <nav aria-label="Pagination" className="display-inline-block">
					{/* <Pagination className="margin-0" prev next boundaryLinks items={meta?meta.totalPageCount:1} maxButtons={3} activePage={meta?meta.currentPage:1}  onSelect={this.onPageChange}/> */}

					{meta ? <Pagination  
						activePage={meta ? meta.currentPage : 1}
						itemsCountPerPage={this.state.pageSize}
						totalItemsCount={meta ? meta.rowCount : 1}
						onChange={this.onPageChange}
					/> : null }
					</nav>
				</div>
					
				</div>
				}
			</div>
		);
	}
}

const mapStateToProps=({spcmReducer})=>{
	return{
		spcmReducer
	}
}
export default connect(mapStateToProps,{ getServiceBilling,setEditServiceDetails,setForm,getServiceDetailCounts,updateHideFromConsumerPrice })(ListView);
