
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/

import _ from 'lodash';
import axios from 'axios';

import { GLOBAL } from '_Globals'

const api = axios.create({
  headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

export const getOfferingsInCart = (userId) => {
  var str = {};
  str.userId = "";
  str = JSON.stringify(str)
  const offeringsInCartResoponse = api.get(GLOBAL.cartOfferingsUrl, { headers: { 'query': str } });
  return ((dispatch) => {
    offeringsInCartResoponse.then((cartOfferingList) => {
      if (cartOfferingList.errorCode) {
        dispatch(cartOfferingFailure(cartOfferingList));
      } else {
        let tempOfferingIds = _.uniqBy(cartOfferingList.data, 'offeringId');
        let offeringIds = [];
        tempOfferingIds.map((offerings) => {
          if (offerings.offeringId != null) {
            offeringIds.push(offerings.offeringId);
          }
        });

        var str = {};
        str.userId = "";
        str.offeringId = offeringIds;

        str = JSON.stringify(str);


        const cartOfferingDetails = api.get(GLOBAL.getServicesDetailsAddedInCart, { headers: { 'query': str } });
        cartOfferingDetails.then((cartOfferingDetails) => {
          if (cartOfferingDetails.errorCode) {
            dispatch(cartOfferingFailure(cartOfferingDetails));
          } else {
            dispatch(cartOfferingSuccess(cartOfferingDetails));
          }
        })
          .catch((err) => {
            dispatch(cartOfferingFailure(err));
          });
      }
    })
      .catch((err) => {
        dispatch(cartOfferingFailure(err));
      });
  });
};

export const removeOfferingFromCart = (offeringId, userId) => {
  axios.post(GLOBAL.removeOfferingFromCart, {
    serviceId: offeringId
  }).then((response) => {
    if (response.status == 200) {
      alert('offering successfully deleted from cart.');
      getOfferingsInCart(userId);
    } else {
      alert('there is some problem deleting offering from cart.');
    }
  });
};
const cartOfferingSuccess = (cartOfferings) => {
  return {
    type: 'CART_OFFERING_LIST_SUCCESS',
    cartOfferings
  };
};

const cartOfferingFailure = (errorResponse) => {
  return {
    type: 'CART_OFFERING_LIST_FAILURE',
    errorResponse
  };
};

export const loadOrderDeliverOptions = (offeringIds) => {
  var str = {};
  str.userId = "";
  str = JSON.stringify(str);
  return (dispatch) => {
    dispatch({
      type: 'LOADING_SERVICE_DELIVERY_OPTIONS',
      isFetching: true,
      isEmpty: false
    });
    api.get(GLOBAL.orderDeliveryTypeUrl + offeringIds, { headers: { 'query': str } })
      .then((deliveryOptions) => {
        if (deliveryOptions.statusText == 'No Content' || deliveryOptions.data == "") {
          dispatch({
            type: 'SERVICE_DELIVERY_OPTIONS_NO_DATA',
            isFetching: false,
            isEmpty: true,
            message: 'No active SLA found'
          });
        }
        if (!deliveryOptions) {
          throw Error(deliveryOptions.statusText);
        }
        return deliveryOptions;
      })
      .then((deliveryOptions) => {
        let slaFound = false;
        if (deliveryOptions.data != "") {
          let filteredDelivery = deliveryOptions.data.data.filter((item, index) => {
            if (item.moduleId == "15" && item.goalTypeId == "30" && item.slaStatus == "Active") {
              slaFound = true;
              return true;
            } else {
              return false;
            }
          });
          if (slaFound) {
            dispatch({
              type: 'SERVICE_DELIVERY_LOADED',
              isFetching: false,
              isEmpty: false,
              deliveryOptions: filteredDelivery
            });
          } else {
            dispatch({
              type: 'SERVICE_DELIVERY_OPTIONS_NO_DATA',
              isFetching: false,
              isEmpty: true,
              message: 'No active SLA found'
            });
          }

        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};


export const loadOfferingForOrder = (offeringId) => {
  return (dispatch) => {
    let str = {};
    str.userId = "";
    if (offeringId !== "") {
      str.offeringId = offeringId;
    }

    str = JSON.stringify(str);
    api.get(GLOBAL.getServicesDetailsAddedInCart, { headers: { 'query': str } })
      .then((orderDetails) => {
        if (!orderDetails) {
          throw Error(orderDetails.statusText);
        }
        return orderDetails;
      })
      .then((orderDetails) => {
        dispatch(loadOfferingForOrderSuccess(orderDetails))
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

const loadOfferingForOrderSuccess = (cartOfferings) => {
  return {
    type: 'CART_OFFERING_LIST_SUCCESS',
    cartOfferings
  };
};

const selectedComponentsLoadSuccess = (selectedComponents) => {
  return {
    type: 'SELECTED_COMPONENTS_LOAD_SUCCESS',
    selectedComponents
  };
};

export const setServiceDeliveries = (deliveryObj) => {
  return (dispatch) => {
    dispatch({
      type: 'ADD_UPDATE_DEFAULT_SERVICE_DELIVERY',
      deliveryObj: deliveryObj
    });
  };
};


/*export const getServiceQuestionnaireCategories = (serviceId, categoryIds) => {
  return (dispatch, getState) => {
    const questionnaireCategories = api.get(GLOBAL.getServiceQuestionnaireCategories + serviceId + "/" + categoryIds);
    questionnaireCategories.then(response => {
      const {serviceQuestionnaireCategories, selectedComponentForOffering} = getState();
      let serviceQuestionnaireCategories1 = JSON.parse(JSON.stringify(serviceQuestionnaireCategories));
      let selectedComponents = selectedComponentForOffering.map((component) => {
        return component.componentId;
      });
      if (response.data.length !== 0) {
        response.data.forEach((service) => {
          service.categories.forEach((category) => {
            /!*fetching parent question for each category*!/
            category.parentQuestions = [];
            category.otherCategoryQuestions = [];
            const parentQuestions = axios.get(GLOBAL.getParentQuestionsForCategory + serviceId + "/" + category.CATEGORY_ID + "/" + selectedComponents);
            parentQuestions.then(parentQuestionResponse => {
              if (parentQuestionResponse.data.length !== 0) {
                /!*adding childQuestions array property to each parent question object
              * withing the category*!/
                parentQuestionResponse.data.forEach((question) => {
                  /!*adding name property for REDUX-FORM and childQuestions property to each question object*!/
                  question.name = question.questionText.replace(/[^\w\s]/gi, '').replace(/ /g, '_') + "_" + question.qId;
                  question.categoryId = parseInt(question.categoryId);
                  question.childQuestions = [];
                });
                category.parentQuestions = parentQuestionResponse.data;
              }
              dispatch({
                type: "SERVICE_QUESTIONNAIRE_CATEGORIES_LOAD_SUCCESS",
                payload: _.uniqBy(serviceQuestionnaireCategories1.concat(response.data), "serviceId")
              });
            }).catch(error => {
              console.log("ERROR GETTING PARENT QUESTION FOR CATEGORY : " + category.CATEGORY_NAME);
              console.log(error);
            });

          });
        });
      }
    }).catch(error => {
      console.log("in questionnaireCategories action error");
      console.log(error);
    });
  };
};*/

export const getServiceQuestionnaireCategories = (serviceId, componentIds) => {
  return (dispatch, getState) => {
    dispatch({
      type: "FETCHING_CATEGORIES",
      payload: true
    });
    let queryData ={
      serviceId:serviceId,
      componentIds:componentIds
    };
    const questionnaireCategories = api.get(GLOBAL.getServiceQuestionnaireCategories,{
      headers: { query: JSON.stringify(queryData) },
    });
    questionnaireCategories.then(response => {
      const { serviceQuestionnaireCategories } = getState();
      let serviceQuestionnaireCategories1 = JSON.parse(JSON.stringify(serviceQuestionnaireCategories));
      /*adding parentQuestions array property to each category object
      * withing the service*/
      if (response.data.length !== 0) {
        response.data.forEach((service) => {
          service.categories.forEach((category) => {
            category.parentQuestions = [];
            category.otherCategoryQuestions = [];
          });
        });
      }
      dispatch({
        type: "SERVICE_QUESTIONNAIRE_CATEGORIES_LOAD_SUCCESS",
        payload: _.uniqBy(serviceQuestionnaireCategories1.concat(response.data), "serviceId")
      });
      dispatch({
        type: "FETCHING_CATEGORIES_SUCCESS",
        payload: false
      });
    }).catch(error => {
      console.log("in questionnaireCategories action error");
      console.log(error);
    });
    dispatch({
      type: "FETCHING_CATEGORIES",
      payload: true
    });
  };
};

export const getUserDefinedServiceQuestionnaireCategories = (serviceId, componentIds) => {
  return (dispatch, getState) => {
    dispatch({
      type: "FETCHING_CATEGORIES",
      payload: true
    });
    let queryData ={
      serviceId:serviceId,
      componentIds:componentIds
    };
    const questionnaireCategories = api.get('/getUserDefinedQuestionnaireCategory',{
      headers: { query: JSON.stringify(queryData) },
    });
    questionnaireCategories.then(response => {
      const { userDefinedServiceQuestionnaireCategories } = getState();
      let serviceQuestionnaireCategories1 = JSON.parse(JSON.stringify(userDefinedServiceQuestionnaireCategories));
      /*adding parentQuestions array property to each category object
      * withing the service*/
      if (response.data.length !== 0) {
        response.data.forEach((service) => {
          service.categories.forEach((category) => {
            category.parentQuestions = [];
            category.otherCategoryQuestions = [];
          });
        });
      }
      dispatch({
        type: "USER_DEFINED_SERVICE_QUESTIONNAIRE_CATEGORIES_LOAD_SUCCESS",
        payload: _.uniqBy(serviceQuestionnaireCategories1.concat(response.data), "serviceId")
      });
      dispatch({
        type: "FETCHING_CATEGORIES_SUCCESS",
        payload: false
      });
    }).catch(error => {
      console.log("in questionnaireCategories action error");
      console.log(error);
    });
    dispatch({
      type: "FETCHING_CATEGORIES",
      payload: true
    });
  };
};

export const getCategoryParentQuestions = (categoryId, serviceId, componentIds) => {
  return (dispatch, getState) => {
    const parentQuestions = axios.get(GLOBAL.getParentQuestionsForCategory + serviceId + "/" + categoryId + "/" + componentIds);
    dispatch({
      type: "FETCHING_QUESTIONS",
      payload: true
    });
    parentQuestions.then(response => {
      const { userDefinedServiceQuestionnaireCategories } = getState();
      console.log('#######', userDefinedServiceQuestionnaireCategories);
      let serviceQuestionnaireCategories1 = JSON.parse(JSON.stringify(userDefinedServiceQuestionnaireCategories));
      console.log('******* 2', response.data);
      if (response.data.length !== 0) {

        /*adding childQuestions array property to each parent question object
      * withing the category*/
        response.data.forEach((question) => {
          /*naming question and adding childQuestions array property*/
          // Removing display text condition as name key is required for to render the question
          // if (question.responseType !== "Display Text") {
          //   question.name = question.questionText.replace(/[^\w\s]/gi, '').replace(/ /g, '_') + "_" + question.qId;
          // }
          question.name = question.questionText.replace(/[^\w\s]/gi, '').replace(/ /g, '_') + "_" + question.qId;
          question.categoryId = parseInt(question.categoryId, 10);
          question.childQuestions = [];
        });

        _.find(
          _.find(
            serviceQuestionnaireCategories1, { "serviceId": response.data[0].serviceId }
          )
            .categories, { "CATEGORY_ID": parseInt(response.data[0].categoryId, 10) }
        ).parentQuestions = response.data;
      }
      console.log('*******', serviceQuestionnaireCategories1);
      dispatch({
        type: "USER_DEFINED_SERVICE_QUESTIONNAIRE_CATEGORIES_LOAD_SUCCESS",
        payload: serviceQuestionnaireCategories1
      });
      dispatch({
        type: "FETCHING_QUESTIONS_SUCCESS",
        payload: false
      });
    }).catch(error => {
      console.log("in getCategoryParentQuestions action error");
      console.log(error);
      dispatch({
        type: "FETCHING_QUESTIONS",
        payload: true
      });
    });
  };
};

export const getMasterQuestionsPI = (ofg_id,cat_id,component_id) => {
  return (dispatch, getState) => {
    const parentQuestions = axios.get(`/api/requiredInformation/getMasterQuestionDetails/${ofg_id}/${cat_id}/${component_id}`);
    dispatch({
      type: "FETCHING_QUESTIONS",
      payload: true
    });
    parentQuestions.then(response => {
      const { serviceQuestionnaireCategories } = getState();
      let serviceQuestionnaireCategories1 = JSON.parse(JSON.stringify(serviceQuestionnaireCategories));
      if (response.data.length !== 0) {

        /*adding childQuestions array property to each parent question object
      * withing the category*/
        response.data.forEach((question) => {
          /*naming question and adding childQuestions array property*/
          // Removing display text condition as name key is required for to render the question
          // if (question.responseType !== "Display Text") {
          //   question.name = question.questionText.replace(/[^\w\s]/gi, '').replace(/ /g, '_') + "_" + question.qId;
          // }
          question.name = question.questionText.replace(/[^\w\s]/gi, '').replace(/ /g, '_') + "_" + question.qId;
          question.categoryId = parseInt(question.categoryId, 10);
          question.childQuestions = [];
        });

        _.find(
          _.find(
            serviceQuestionnaireCategories1, { "serviceId": response.data[0].serviceId }
          )
            .categories, { "CATEGORY_ID": parseInt(response.data[0].categoryId, 10) }
        ).parentQuestions = response.data;
      }
      dispatch({
        type: "SERVICE_QUESTIONNAIRE_CATEGORIES_LOAD_SUCCESS",
        payload: serviceQuestionnaireCategories1
      });
      dispatch({
        type: "FETCHING_QUESTIONS_SUCCESS",
        payload: false
      });
    }).catch(error => {
      console.log("in getCategoryParentQuestions action error");
      console.log(error);
      dispatch({
        type: "FETCHING_QUESTIONS",
        payload: true
      });
    });
  };
};

const insertChildQuestion = (parent, child, questionExists) => {
  if (parent) {
    if (Number(parent.qId) === child[0].parent_id) {
      parent.childQuestions = child;
      questionExists = true;
    } else {
      if (child[0].parent_id === 0) {
        parent.childQuestions = [];
        questionExists = true;
      }
    }
    if (!questionExists) {
      for (let i = 0; i < parent.childQuestions.length; i++) {
        insertChildQuestion(parent.childQuestions[i], child);
      }
    }
  }
};

const deleteChildQuestionFromParentQuestionsArray = (parent, child) => {
  if (parent.qId === child[0].qId) {
    parent.childQuestions = [];
  }
  for (let i = 0; i < parent.childQuestions.length; i++) {
    deleteChildQuestionFromParentQuestionsArray(parent.childQuestions[i], child);
  }
};

const deleteChildQuestionFromOtherCategoryQuestionsArray = (serviceQuestionnaireCategories1, question) => {
  _.find(
    serviceQuestionnaireCategories1, { "serviceId": question.serviceId }
  ).categories.forEach((category) => {
    category.otherCategoryQuestions =
      category.otherCategoryQuestions.filter((ques) => {
        if (ques.initParentId === question.initParentId && ques.parent_id > question.parent_id
          &&
          ques.qId !== question.qId && question.parent_id !== ques.parent_id) {
          return false;
        } else {
          return true;
        }
      });
  });
};

export const getChildQuestion = (question, selectedValue, componentIds, selectedValueText) => {
  return (dispatch, getState) => {
    const { userDefinedServiceQuestionnaireCategories, serviceQuestionnaireCategories } = getState();
    let serviceQuestionnaireCategories1
    if(parseInt(question.categoryId, 10) === 1010101010) {
      serviceQuestionnaireCategories1 = JSON.parse(JSON.stringify(serviceQuestionnaireCategories));
    }
    else {
      serviceQuestionnaireCategories1 = JSON.parse(JSON.stringify(userDefinedServiceQuestionnaireCategories));
    }
    let headerObj = {};
    headerObj.offeringId = question.serviceId;
    headerObj.categoryId = question.categoryId;
    headerObj.responseValue = selectedValue === "" ? "select" : selectedValue;
    headerObj.initId = question.initParentId;
    headerObj.parentId = question.qId;
    headerObj.componentIds = componentIds;
    headerObj.responseValueText = selectedValueText === "" ? "select" : selectedValueText;
    const childQuestion = axios.get(GLOBAL.getChildQuestion,
      {
        headers: {
          'query': JSON.stringify(headerObj)
        }
      });
    dispatch({
      type: "FETCHING_CHILD_QUESTIONS",
      payload: true
    });
    childQuestion.then(response => {

      let parentQuestion = _.find(
        _.find(
          _.find(
            serviceQuestionnaireCategories1, { "serviceId": question.serviceId }
          ).categories, { "CATEGORY_ID": parseInt(question.categoryId, 10) }
        ).parentQuestions, { "initParentId": question.initParentId });

      let questionsWithinSameCategory = [];
      let questionsBelongsToOtherCategory = [];

      if (response.data.length !== 0) {
        response.data.forEach((childQuestion) => {
          /*naming question and adding childQuestions array property*/

          // Removing display text condition as name key is required for to render the question
          // if (childQuestion.responseType !== "Display Text") {
          //   childQuestion.name = childQuestion.questionText.replace(/[^\w\s]/gi, '').replace(/ /g, '_') + "_" + childQuestion.qId;
          // }
          childQuestion.name = childQuestion.questionText.replace(/[^\w\s]/gi, '').replace(/ /g, '_') + "_" + childQuestion.qId;
          if (childQuestion.categoryId === question.categoryId && !question.crossCategoryQuestion) {
            childQuestion.childQuestions = [];
            questionsWithinSameCategory.push(childQuestion);
          } else {
            childQuestion.crossCategoryQuestion = true;
            childQuestion.childQuestions = [];
            questionsBelongsToOtherCategory.push(childQuestion);
          }
        });

        if (questionsWithinSameCategory.length !== 0) {
          let questionExists = false;
          if (parentQuestion) {
            /*if parentQuestion is found child question belongs to parentQuestions Array*/
            insertChildQuestion(parentQuestion, questionsWithinSameCategory, questionExists);

            questionsWithinSameCategory.forEach((childQuestion) => {
              _.find(
                serviceQuestionnaireCategories1, { "serviceId": childQuestion.serviceId }
              ).categories.forEach((category) => {
                category.otherCategoryQuestions =
                  category.otherCategoryQuestions.filter((question) => {
                    if (question.initParentId === childQuestion.initParentId && question.parent_id >= childQuestion.parent_id) {
                      return false;
                    } else {
                      return true;
                    }
                  });
              });
            });
          }
        } else {
          let questionExists = false;
          let ques = [question];
          insertChildQuestion(parentQuestion, ques, questionExists);
        }

        /*inserting question that belongs to other category*/
        questionsBelongsToOtherCategory.forEach((childQuestion) => {
          let category = _.find(
            _.find(
              serviceQuestionnaireCategories1, { "serviceId": childQuestion.serviceId }
            ).categories, { "CATEGORY_ID": parseInt(childQuestion.categoryId, 10) }
          );
          /*category.otherCategoryQuestions =
            category.otherCategoryQuestions.filter((question) => {
              return question.parent_id < childQuestion.parent_id;
            });
          category.otherCategoryQuestions.push(childQuestion);*/

          /*#bug fix TSK100000000694
          * if any cross category has the question with same parent Id as the childQuestion
          * remove that question from the category*/

          _.find(
            serviceQuestionnaireCategories1, { "serviceId": childQuestion.serviceId }
          ).categories.forEach((category) => {
            category.otherCategoryQuestions =
              category.otherCategoryQuestions.filter((ques) => {
                if (ques.initParentId === childQuestion.initParentId && ques.parent_id >= childQuestion.parent_id) {
                  return false;
                } else {
                  return true;
                }
              });
          });
          category.otherCategoryQuestions.push(childQuestion);
        });


      } else {
        if (response.data === "") {
          /*there is no child question and the response
         * is blank then we are passing the same object to
         * insertChildQuestion function to empty the previously stored
         * question at same level*/
          response.data = [question];
          if (parentQuestion && parentQuestion.childQuestions.length !== 0) {
            /*if parentQuestion found the question belongs to parentQuestions Array in category else
            * to otherCategoryQuestionArray*/
            deleteChildQuestionFromParentQuestionsArray(parentQuestion, response.data);
          } /*else {
            deleteChildQuestionFromOtherCategoryQuestionsArray(serviceQuestionnaireCategories1, question);
          }*/
          deleteChildQuestionFromOtherCategoryQuestionsArray(serviceQuestionnaireCategories1, question);
        }
      }
      if(parseInt(question.categoryId, 10) === 1010101010) {
        dispatch({
          type: "SERVICE_QUESTIONNAIRE_CATEGORIES_LOAD_SUCCESS",
          payload: serviceQuestionnaireCategories1
        });
      }
      else {
        dispatch({
          type: "USER_DEFINED_SERVICE_QUESTIONNAIRE_CATEGORIES_LOAD_SUCCESS",
          payload: serviceQuestionnaireCategories1
        });
      }
      dispatch({
        type: "FETCHING_CHILD_QUESTIONS_SUCCESS",
        payload: false
      });
    }).catch(error => {
      dispatch({
        type: "FETCHING_CHILD_QUESTIONS",
        payload: true
      });
      console.log("in getChildQuestion action error");
      console.log(error);
    });
  };
};

export const getTypeAheadChildQuestion = (selectedTypeaheadValue, question, componentIds) => {
  console.log("selectedTypeaheadValue");
  console.log(selectedTypeaheadValue);
  return (dispatch, getState) => {
    let serviceQuestionnaireCategories1;
    const { userDefinedServiceQuestionnaireCategories, serviceQuestionnaireCategories } = getState();
    if(parseInt(question.categoryId, 10) === 1010101010) {
      serviceQuestionnaireCategories1 = JSON.parse(JSON.stringify(serviceQuestionnaireCategories));
    }
    else {
      serviceQuestionnaireCategories1 = JSON.parse(JSON.stringify(userDefinedServiceQuestionnaireCategories));
    }
    let headerObj = {};
    headerObj.offeringId = question.serviceId;
    headerObj.categoryId = question.categoryId;
    headerObj.initId = question.initParentId;
    headerObj.parentId = question.qId;
    headerObj.questionId = question.qId;
    headerObj.responseType = question.responseType;
    headerObj.componentIds = componentIds;

    const childQuestion = axios.get(GLOBAL.getTypeAheadChildQuestion,
      {
        headers: {
          'query': JSON.stringify(headerObj)
        }
      });
    // dispatch({
    //   type: "FETCHING_CHILD_QUESTIONS",
    //   payload: true
    // });
    childQuestion.then(response => {
      let childQuestion = response.data[0];
      if (childQuestion) {
        response.data.forEach(question => {
          question.model = selectedTypeaheadValue
        });
      }
      let parentQuestion = _.find(
        _.find(
          _.find(
            serviceQuestionnaireCategories1, { "serviceId": question.serviceId }
          ).categories, { "CATEGORY_ID": parseInt(question.categoryId, 10) }
        ).parentQuestions, { "initParentId": question.initParentId });

      let questionsWithinSameCategory = [];
      let questionsBelongsToOtherCategory = [];

      if (response.data.length !== 0) {
        response.data.forEach((childQuestion) => {

          /*naming question and adding childQuestions array property*/

          // Removing display text condition as name key is required for to render the question

          /* if (childQuestion.responseType !== "Display Text") {

            // Adding selectedValue.name key in case parent is tyhpeahead and adding that in childquestion.name to re-register child question in case on tyhpeahead onChange

            //  OR

            // Adding selectedValue in case parent is lookupText and adding that in childquestion.name to re-register child question in case on Lookup Text onChange


            let selectedValueText = selectedTypeaheadValue.Name ? selectedTypeaheadValue.Name.replace(/[^\w\s]/gi, '').replace(/ /g, '_') : selectedTypeaheadValue;


            childQuestion.name = childQuestion.questionText.replace(/[^\w\s]/gi, '').replace(/ /g, '_') + "_" + childQuestion.qId + "_" + selectedValueText;



            console.log("step 3..child question details");
            console.log(childQuestion);
          } */


          let selectedValueText = selectedTypeaheadValue.Name ? selectedTypeaheadValue.Name.replace(/[^\w\s]/gi, '').replace(/ /g, '_') : selectedTypeaheadValue;


          childQuestion.name = childQuestion.questionText.replace(/[^\w\s]/gi, '').replace(/ /g, '_') + "_" + childQuestion.qId + "_" + selectedValueText;


          /*in case of dynamic drop down, same question will come when selecting different values
          * from typeahead, in that case need to give a unique key name to the dynamic drop down field
          * as React dom doen't update question if qId will be same every time*/
          if (typeof selectedTypeaheadValue === "string") {
            /*For hardware request service value will be the seat code and it's coming from Lookup Text field as string
            * other values comes as object which gets selected from typeahead field*/
            childQuestion.key = selectedTypeaheadValue;
          } else {
            childQuestion.key = selectedTypeaheadValue.Name;
          }
          if (childQuestion.categoryId === question.categoryId && !question.crossCategoryQuestion) {
            childQuestion.childQuestions = [];
            questionsWithinSameCategory.push(childQuestion);
          } else {
            childQuestion.crossCategoryQuestion = true;
            childQuestion.childQuestions = [];
            questionsBelongsToOtherCategory.push(childQuestion);
          }
        });

        if (questionsWithinSameCategory.length !== 0) {
          let questionExists = false;
          insertChildQuestion(parentQuestion, questionsWithinSameCategory, questionExists);
        } else {
          let questionExists = false;
          let ques = [question];
          insertChildQuestion(parentQuestion, ques, questionExists);
        }

        /*inserting question that belongs to other category*/
        questionsBelongsToOtherCategory.forEach((childQuestion) => {
          let category = _.find(
            _.find(
              serviceQuestionnaireCategories1, { "serviceId": childQuestion.serviceId }
            ).categories, { "CATEGORY_ID": parseInt(childQuestion.categoryId, 10) }
          );
          /*category.otherCategoryQuestions =
            category.otherCategoryQuestions.filter((question) => {
              return question.parent_id < childQuestion.parent_id;
            });
          category.otherCategoryQuestions.push(childQuestion);*/

          /*#bug fix TSK100000000694
          * if any cross category has the question with same parent Id as the childQuestion
          * remove that question from the category*/

          _.find(
            serviceQuestionnaireCategories1, { "serviceId": childQuestion.serviceId }
          ).categories.forEach((category) => {
            category.otherCategoryQuestions =
              category.otherCategoryQuestions.filter((ques) => {
                if (ques.initParentId === childQuestion.initParentId && ques.parent_id >= childQuestion.parent_id) {
                  return false;
                } else {
                  return true;
                }
              });
          });
          category.otherCategoryQuestions.push(childQuestion);
        });

      } else {
        if (response.data === "") {
          /*there is no child question and the response
         * is blank then we are passing the same object to
         * insertChildQuestion function to empty the previously stored
         * question at same level*/
          response.data = [question];
          if (parentQuestion && parentQuestion.childQuestions.length !== 0) {
            deleteChildQuestionFromParentQuestionsArray(parentQuestion, response.data);
          } /*else {
            deleteChildQuestionFromOtherCategoryQuestionsArray(serviceQuestionnaireCategories1, response.data[0]);
          }*/
          deleteChildQuestionFromOtherCategoryQuestionsArray(serviceQuestionnaireCategories1, response.data[0]);
        }
      }
      if(parseInt(question.categoryId, 10) === 1010101010) {
        dispatch({
          type: "SERVICE_QUESTIONNAIRE_CATEGORIES_LOAD_SUCCESS",
          payload: serviceQuestionnaireCategories1
        });
      }
      else {
        dispatch({
          type: "USER_DEFINED_SERVICE_QUESTIONNAIRE_CATEGORIES_LOAD_SUCCESS",
          payload: serviceQuestionnaireCategories1
        });
      }
      dispatch({
        type: "FETCHING_CHILD_QUESTIONS_SUCCESS",
        payload: false
      });
    }).catch(error => {
      console.log("in getTypeAheadChildQuestion action error");
      console.log(error);
      dispatch({
        type: "FETCHING_CHILD_QUESTIONS",
        payload: true
      });
    });
  };
};

export const resetCartQuestionsState = () => {
  return (dispatch, getState) => {
    const { serviceQuestionnaireCategories, userDefinedServiceQuestionnaireCategories } = getState();
    let serviceQuestionnaireCategories1 = JSON.parse(JSON.stringify(serviceQuestionnaireCategories));
    let serviceQuestionnaireCategories2 = JSON.parse(JSON.stringify(userDefinedServiceQuestionnaireCategories));
    serviceQuestionnaireCategories1.forEach((service) => {
      service.categories.forEach((category) => {
        category.parentQuestions = [];
        category.otherCategoryQuestions = [];
      });
    });
    serviceQuestionnaireCategories2.forEach((service) => {
      service.categories.forEach((category) => {
        category.parentQuestions = [];
        category.otherCategoryQuestions = [];
      });
    });
    dispatch({
      type: "SERVICE_QUESTIONNAIRE_CATEGORIES_LOAD_SUCCESS",
      payload: []
    });
    dispatch({
      type: "USER_DEFINED_SERVICE_QUESTIONNAIRE_CATEGORIES_LOAD_SUCCESS",
      payload: []
    });
    dispatch({
      type: "RESET_CATEGORY_COUNT_STATE",
      payload: []
    });
  };
};

export const getServiceCategoryCount = (serviceId, componentIds, callfor, callback) => {

  return (dispatch, getState) => {
    const categories = axios.get(GLOBAL.getServiceCategoriesCount + serviceId + "/" + componentIds);

    dispatch({
      type: "FETCHING_CATEGORY_COUNT",
      payload: true
    });


    categories.then(response => {
      if (callfor === "cart") {
        const { categoryCountList } = getState();
        let prevCategoryCount = JSON.parse(JSON.stringify(categoryCountList));
        prevCategoryCount.push(response.data);
        dispatch({ type: 'SERVICE_CATEGORY_COUNT_LOADED', payload: prevCategoryCount });
        callback(prevCategoryCount);
      } else {
        if (callfor === "quickCheckout") {
          callback([response.data]);
        }
      }
      dispatch({
        type: "FETCHING_CATEGORY_COUNT_SUCCESS",
        payload: false
      });
    }).catch(error => {
      console.log("in getServiceCategoryCount action error");
      console.log(error);
      dispatch({
        type: "FETCHING_CATEGORY_COUNT",
        payload: true
      });
    });
  };
};

export const loadRecipientLocations = (companyId) => {
  let str = {};
  str.companyId = companyId;
  str = JSON.stringify(str);
  return (dispatch) => {
    api.get(GLOBAL.loadRecipientLocations,{ headers: { 'query': str } })
      .then((recipientLocations) => {
        console.log("LOCATIONS+++",recipientLocations);
        if (!recipientLocations) {
          throw Error(recipientLocations.statusText);
        }
        return recipientLocations;
      })
      .then((recipientLocations) => {
        dispatch({ type: 'RECIPIENT_DELIVERY_LOCATIONS', recipientLocations: recipientLocations.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const loadRecipientLocationsIsShared = (companyId) => {
  let str = {};

  str.companyId = companyId;
  str = JSON.stringify(str);
  return (dispatch) => {
    api.get(GLOBAL.loadRecipientLocationsIsShared,{ headers: { 'query': str } })
      .then((recipientLocationsIsShared) => {
        if (!recipientLocationsIsShared) {
          throw Error(recipientLocationsIsShared.statusText);
        }
        return recipientLocationsIsShared;
      })
      .then((recipientLocationsIsShared) => {
        dispatch({ type: 'RECIPIENT_DELIVERY_LOCATIONS_ISSHARED', recipientLocationsIsShared: recipientLocationsIsShared.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const loadRecipientCountries = () => {
  return (dispatch) => {
    api.get(GLOBAL.loadRecipientCountries)
      .then((recipientCountries) => {
        if (!recipientCountries) {
          throw Error(recipientCountries.statusText);
        }
        return recipientCountries;
      })
      .then((recipientCountries) => {
        dispatch({ type: 'RECIPIENT_DELIVERY_COUNTRIES', recipientCountries: recipientCountries.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const updateOrderingInformation = (updateFor, updatedValue, formValues, id, locationName) => {
  return dispatch => {
    switch (updateFor) {
      case "location":
        dispatch({ type: 'UPDATE_RECIPIENT_LOCATION', updatedValue, formValues, id, locationName });
        break;
      case "contact":
        dispatch({ type: 'UPDATE_RECIPIENT_CONTACT_NUMBER', updatedValue, formValues, id });
        break;
      case "cubicle":
        dispatch({ type: 'UPDATE_RECIPIENT_CUBICLE_NUMBER', updatedValue, formValues, id });
        break;
    }

  };
};

export const resetOrderingInfoForm = () => {
  return dispatch => {
    dispatch({ type: 'RESET_ORDERING_INFO_FORM' });
  };
};


export const haveMandatoryQuestionFlagForService = (offeringIds, componentIds) => {
  let allOfferings = _.uniqBy(offeringIds, 'offeringId');
  let offeringIdsArr = allOfferings.map(a => a.offeringId);
  let allComponents = _.uniqBy(componentIds, 'componentId');
  // let componentIdsArr = allComponents.map(a => a.componentId);

  let str = {};

  str.offeringIds = offeringIdsArr;
  str.selectedComponents = componentIds;
  str = JSON.stringify(str);
  return (dispatch) => {
    api.get(GLOBAL.haveMandatoryQuestionFlagForService, { headers: { 'query': str } })
      .then((haveMandatoryQuestionFlagForService) => {
        dispatch(haveMandatoryQuestionFlagForServiceSuccess(haveMandatoryQuestionFlagForService));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const haveMandatoryQuestionFlagForServiceSuccess = (haveMandatoryQuestionFlagForService) => {
  return {
    type: 'HAVE_MANDATORY_QUESTION_FLAG_FOR_SERVICE',
    payload: haveMandatoryQuestionFlagForService.data
  };
};

export const getBulkPurchaseQuestions = (offeringIds, componentIds) => {
  let apiParams = {
    offeringIds: [],
    componentIds: []
  };
  if (!Array.isArray(offeringIds)) {
    apiParams.offeringIds.push(offeringIds);
  } else {
    apiParams.offeringIds = offeringIds;
  }
  apiParams.componentIds = componentIds;
  apiParams = JSON.stringify(apiParams);
  return (dispatch) => {
    api.get(GLOBAL.bulkPurchaseQuestions, { headers: { 'query': apiParams } })
      .then((bulkPurchaseQuestions) => {
        if (!bulkPurchaseQuestions || bulkPurchaseQuestions.data === "") {
          dispatch({ type: 'BULK_PURCHASE_QUESTIONS', payload: [] });
        }
        return bulkPurchaseQuestions;
      })
      .then((bulkPurchaseQuestions) => {
        dispatch({ type: 'BULK_PURCHASE_QUESTIONS', payload: bulkPurchaseQuestions.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getUrgencyValuesForIncident = (config) => {
  return (dispatch) => {
    api.get("/api/getUrgencyImpactValues", config)
      .then((urgencyValues) => {
        dispatch({ type: 'URGENCY_FETCHED_SUCCESSFULLY', payload: urgencyValues.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const resetUrgencyImpactValues = () => {
  return (dispatch) => {
    dispatch({ type: 'URGENCY_FETCHED_SUCCESSFULLY', payload: [] });
  };
};

export const getOfferingAttachmentsForCart = (offeringId) => {
  return (dispatch, getState) => {
    api.get("/api/attachment/" + offeringId)
      .then((resp) => {
        const { cartOfferingAttachmentsReducer } = getState();
        let attachmentsArr = structuredClone(cartOfferingAttachmentsReducer);
        if(resp.data.length > 0) {
          let attachmentObj = {
            serviceId: offeringId,
            attachments: resp.data
          }
          attachmentsArr.push(attachmentObj);
        }
        dispatch({
          type: "CART_OFFERING_ATTACHMENTS_FETCHED_SUCCESSFULLY",
          payload: attachmentsArr
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};