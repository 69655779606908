
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Alert } from "react-bootstrap";

class XsmBreakFixAction0 extends React.Component {
  constructor(props) {
    super(props);
    //this.props.loadBreakFix();
  }
  render() {
    return (
      <div>
        <Alert variant="success" className="errorMessageSec">
          <p>Work Item has been successfully saved . </p>
        </Alert>
      </div>
    );
  }
}
export default XsmBreakFixAction0;
