
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Row, Col, Form, Alert, ButtonToolbar, Button, Table } from 'react-bootstrap';
import { _textArea, _dropDown, _inputField, _number, _dynamicNamedropDown } from '../../common/formFields';
import { Field, reduxForm, SubmissionError, change , formValueSelector, untouch} from 'redux-form';
import { connect } from 'react-redux';
import axios from 'axios';
import { getLovValues } from '../../../actions/changeManagement/changeManagementAction.js';
import { spcmLoader, setLeftEditId, getApprovals, getApprovalQuestions, getApprovalDetails, getApproverType, getApproverCriteria, getApproverScenario, getApproverEntity, getApproverLOB, getApproverOrderType, getApproverCompanyCode, getApproverCriteriaOperator, getApproverModule, getApproverLevel, getApproverGroup, getApproverIndividual, emptyApproverGroup, emptyApproverIndividual, getApproverGroupOrUser, emptyApproverGroupOrUser, listDataWithType, getServiceDetailCounts } from '../../../actions/spcmActions';
import IndividualAsyncTypeahead from './individualAsyncTypeahead.js';
import DropdownPlusTypeahead from './dropdownPlusTypeahead.js';
import { loadCISupCompany, loadApprovalsAutoAction } from '../../../actions/cmdb/lovAction';
import {ImSpinner6} from "react-icons/im";
const validate = (values, props) => {
	let errors = {};
	if (values.ruleName && values.ruleName.trim() == "" ) {
		errors.ruleName = 'Enter Rule Name';
	}
	else if (!values.ruleName) {
		errors.ruleName = 'Enter Rule Name';
	}
	if (!values.moduleId) {
		errors.moduleId = 'Select Module';
	}

	if (!values.rfcChangeType) {
		errors.rfcChangeType = 'Select Change Type';
	}

	if (!values.rfcChangeTiming) {
		errors.rfcChangeTiming = 'Select  Change Timing';
	}
	if (!values.urgency) {
		errors.urgency = 'select urgency.';
	}
	if (!values.level) {
		errors.level = 'Select Level';
	}
	if (!values.approverType) {
		errors.approverType = 'Select Type';
	}
	// if (!values.approverIndividual) {
	// 	errors.approverIndividual = 'Select Approver Individual';
	// }
	// if (!values.approverGroup) {
	// 	errors.approverGroup = 'Select Approver Group';
	// }
	if (!values.signIn) {
		errors.signIn = 'Select Sign In Type';
	}

	if (values.questiontext && values.questiontext.trim() == "") {
		errors.questiontext = 'Select Approval Question';
	}
	else if (!values.questiontext) {
		errors.questiontext = 'Select Approval Question';
	}

	if (!values.module) {
		errors.module = 'Select Module';
	}

	if (!values.level) {
		errors.level = 'Select Level';
	}

	if (!values.sequence) {
		errors.sequence = 'Enter Visibility Sequence';
	}

	if (values.questionvalue && values.questionvalue.trim()=="") {
		errors.questionvalue = 'Enter Response Values';
	}
	else if (!values.questionvalue) {
		errors.questionvalue = 'Enter Response Values';
	}

	if (!values.status) {
		errors.status = 'Select Status';
	}
	if (!values.weightage) {
		errors.weightage = 'Enter Weightage';
	}

	props.extraCounts.forEach(val => {

		if (values["key" + val.toString()]) {
			if (!values["operator" + val.toString()])
				errors["operator" + val.toString()] = "Required";
			if (!values["value" + val.toString()])
				errors["value" + val.toString()] = "Required";
		}

		if (values["operator" + val.toString()]) {
			if (!values["key" + val.toString()])
				errors["key" + val.toString()] = "Required";
			if (!values["value" + val.toString()])
				errors["value" + val.toString()] = "Required";
		}

		if (values["value" + val.toString()]) {
			if (!values["key" + val.toString()])
				errors["key" + val.toString()] = "Required";
			if (!values["operator" + val.toString()])
				errors["operator" + val.toString()] = "Required";
		}


	});

	return errors;
};

class ApprovalForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			type: '',
			rfcModuleSelected: false,
			arrayCounts: 1,
			extraCounts: [0],
			criteriaToggle: false,
			criteriaExist: false,
			test: false,
			spcmDropDownCENTRE:[],
			spcmDropDownDEP:[],
			spcmDropDownLOCATION:[],
			spcmDropDownORG:[],
			spcmDropDownTYPEUSER:[],
			companyErrorColor: "",
			companySelectedList: [],
			inputValueCompany: '',
			groupErrorColor: "",
			groupSelectedList: [],
			inputValueGroup: '',
			individualErrorColor: "",
			companyIdParam: ''
		};
		this.onChange = this.onChange.bind(this);
		this.componentWillMount = this.componentWillMount.bind(this);
		this.onModuleChange = this.onModuleChange.bind(this);
		this.submitForm = this.submitForm.bind(this);
		this.loadCount = this.loadCount.bind(this);
		this.addRow = this.addRow.bind(this);
		this.deleteRow = this.deleteRow.bind(this);
		this.addCriteriaToggle = this.addCriteriaToggle.bind(this);
		this.changeScenarioType = this.changeScenarioType.bind(this);
		this.valueChange = this.valueChange.bind(this);
		this.onCompanySelection = this.onCompanySelection.bind(this);
		this.setCompanyErrorColor = this.setCompanyErrorColor.bind(this);
		this.onCompanyInputChange = this.onCompanyInputChange.bind(this);
		this.onCrossClickCompany = this.onCrossClickCompany.bind(this);
		this.onGroupSelection = this.onGroupSelection.bind(this);
		this.setGroupErrorColor = this.setGroupErrorColor.bind(this);
		this.onGroupInputChange = this.onGroupInputChange.bind(this);
		this.onCrossClickGroup = this.onCrossClickGroup.bind(this);
		this.setIndividualErrorColor = this.setIndividualErrorColor.bind(this);
    this.resetValue = this.resetValue.bind(this)
	}
  resetValue(extraCount) {
    const change = this.props.change;
     
      this.setState({ [`key${extraCount}`]: "" });
      change(`value${extraCount}`, "");
    
      this.setState({ [`key${extraCount}`]: "" });
      change(`operator${extraCount}`, "");

      this.setState({ [`key${extraCount}`]: "" });
      change(`key${extraCount}`, "");
  }
	componentWillMount() {
		if (this.props.spcmReducer.editLeftId > 0) {

			if (this.props.formSelection === 'approval_form') {
				this.props.getApproverLevel(this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID, this.props.initialValues.moduleId);
			}

			else {
				this.props.getApproverLevel(this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID, this.props.initialValues.module);
			}


			if (this.props.initialValues.approverType == 'Individual') {
				this.setState({ type: 'Individual' });
				// this.props.getApproverIndividual(this.props.initialValues.company);
			}
			else if (this.props.initialValues.approverType == 'Group') {
				this.setState({ type: 'Group' });
				// this.props.getApproverGroup(this.props.initialValues.company);
			}
			else {
				this.setState({ type: '' });
			}

			if (this.props.initialValues.moduleId == 14) {
				this.setState({ rfcModuleSelected: true });
				this.props.getLovValues("ChangeManagement", "Type");
				this.props.getLovValues("ChangeManagement", "ChangeTiming");
				this.props.getLovValues("ChangeManagement", "Urgency");

			}


		}

	}

	componentDidMount() {
		this.props.getApproverType();
		this.props.getApproverModule();
		this.props.getApproverCriteria();
		this.props.getApproverCriteriaOperator();
		this.props.getApproverScenario();
		this.props.getApproverCompanyCode();
		this.props.getApproverLOB();
		this.props.getApproverOrderType();
		this.props.getApproverEntity();
		this.props.listDataWithType("Organization",this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID)
		this.props.listDataWithType("Department",this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID)
		this.props.listDataWithType("User Type",this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID)
		this.props.listDataWithType("Cost Center",this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID)
		this.props.listDataWithType("Location",this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID)	             
		this.props.loadApprovalsAutoAction('Fulfillment', '3');

		if (this.props.spcmReducer.editLeftId > 0) {

			// Setting Criteria from initial values in case of editing approval

			if (this.props.formSelection === "approval_form") {
				if ( typeof this.props.initialValues.criteriaList!=="undefined" && this.props.initialValues.criteriaList !== null) {

					let arrayvar = [];
					let criteriaLength = this.props.initialValues.criteriaList.length > 0 ? this.props.initialValues.criteriaList.length : 1;
					for (var i = 0; i < criteriaLength; i++) {
						arrayvar.push(i);
					}

					this.setState({ arrayCounts: criteriaLength, extraCounts: arrayvar }, () => {
						this.props.setExtraCount(arrayvar);
					});




					if (this.props.initialValues.criteriaList.length > 0) {
            if(this.props.initialValues.criteriaList.length==1 && this.props.initialValues.criteriaList[0].OPERATOR=="" && this.props.initialValues.criteriaList[0].KEY=="" && this.props.initialValues.criteriaList[0].VALUE=="" ){
              this.setState({ criteriaExist: false, criteriaToggle: false });
          }else{
						this.setState({ criteriaExist: true, criteriaToggle: true })
						this.props.initialValues.criteriaList.map((item, index) => {
							this.setState({ [`key${index}`]: item.KEY })


						});

					}
				}
			}
    }
		}
		else {
			this.props.setExtraCount([0]);
		}
	}

	componentWillUnmount() {
		this.props.spcmLoader('attribute', 'success');
	}

	addCriteriaToggle() {
		this.setState({
			criteriaToggle: !this.state.criteriaToggle
		})
	}

	changeScenarioType(e, extraCount) {
		const change = this.props.change;
		if (e.target.value) {
			this.setState({ [`key${extraCount}`]: e.target.value })
			change(`value${extraCount}`, "")
		}
	}
	valueChange(e) {
		console.log(e.target.value);
		if (e.target.value.length > 50) {
			e.preventDefault();
		}
	}

	submitForm(values) {
		console.log('console values');
		console.log(values);
		console.log('Reducer Value', this.props.spcmReducer.currentlySelectedServiceDetails.STATUS)
		if (this.props.serviceStatusState == "Deployed") {

			throw new SubmissionError({ _error: "Changes are not allowed in Deployed status" });
		}

		else {



			if (this.props.formSelection == 'approval_form') {


				let temp2 = values;

				Object.keys(temp2).forEach(val => {
					if (val.indexOf("value" == 0)) {
						if (val.substring(5) && this.state.extraCounts.indexOf(parseInt(val.substring(5),10)) < 0) {
							delete (values['key' + val.substring(5)]);
							delete (values['value' + val.substring(5)]);
							delete (values['operator' + val.substring(5)]);
						}

					}


				})


				values.offeringId = this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID;
				values.offeringName = this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_NAME;
				values.companyId = this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID;


				values.lastUpdatedBy = "";
				values.status = 1;

				delete (values.created_by);
				delete (values.createdby_name);


				if (this.props.spcmReducer.editLeftId > 0) {

					if (values.approverType == "Individual") {
						values.approverGroup = '';
						values.signIn = '';
					}

					else if (values.approverType == "Group") {
						values.approverIndividual = '';
					}

					else if (values.approverType == "Manager") {
						values.approverGroup = '';
						values.signIn = '';
						values.approverIndividual = '';
					}

					values.updatedby_name = "";
					values.updatedby = "";


					let key = [];
					let operator = [];
					let value = [];
					let final = [];

					Object.keys(values).forEach(function (valueKey) {

						if (valueKey.indexOf("value") == 0) {
							valueKey.substring(5) ? value[valueKey.substring(5)] = values[valueKey] : value[0] = values[valueKey];

						}
						else if (valueKey.indexOf("key") == 0) {
							valueKey.substring(3) ? key[valueKey.substring(3)] = values[valueKey] : key[0] = values[valueKey];

						}
						else if (valueKey.indexOf("operator") == 0) {
							valueKey.substring(8) ? operator[valueKey.substring(8)] = values[valueKey] : operator[0] = values[valueKey];
						}
					}, this)

					Object.keys(key).forEach(function (valueKey) {
						final.push({ key: key[valueKey], operator: operator[valueKey], value: value[valueKey] })
					})



					{
						if (this.state.criteriaToggle === true) {
							values.criteria = final
						}
					}



					console.log("submitValues");
					console.log(values);
					//return false;

					this.props.spcmLoader('attribute', 'posting');
					return axios.patch("/service/api/approval/editApprovalRule/" + this.props.spcmReducer.editLeftId, values).then((response) => {
						this.props.getApprovals(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
						this.props.componentFormFun4(false);
						this.props.spcmLoader('attribute', 'success');
					})
						.catch((error) => {

							this.props.spcmLoader('attribute', 'success');
							console.log(error);
							throw new SubmissionError({ _error: error.response.data.message });

						});


				}
				else {
					if (values.approverType == 'Group' || values.approverType == 'Individual') {
						if(this.state.companyIdParam == "" || this.state.companyIdParam == "0"){
							this.setState({companyErrorColor: "error"});
							return false;
						}else{
							this.setState({companyErrorColor: ""});
						}
						if(values.approverType == 'Group' && !values.approverGroup) {
							this.setState({groupErrorColor: "error"});
							return false;
						}else{
							this.setState({groupErrorColor: ""});
						}
						if(values.approverType == 'Individual' && !values.approverIndividual) {
							this.setState({individualErrorColor: "error"});
							return false;
						}else{
							this.setState({individualErrorColor: ""});
						}
					}
					let key = [];
					let operator = [];
					let value = [];
					let final = [];
					Object.keys(values).forEach(function (valueKey) {

						if (valueKey.indexOf("value") == 0) {
							valueKey.substring(5) ? value[valueKey.substring(5)] = values[valueKey] : value[0] = values[valueKey];
							final.push({ key: key[valueKey.substring(5)], operator: operator[valueKey.substring(5)], value: value[valueKey.substring(5)] })
						}
						else if (valueKey.indexOf("key") == 0) {
							valueKey.substring(3) ? key[valueKey.substring(3)] = values[valueKey] : key[0] = values[valueKey];
						}
						else if (valueKey.indexOf("operator") == 0) {
							valueKey.substring(8) ? operator[valueKey.substring(8)] = values[valueKey] : operator[0] = values[valueKey];
						}
					}, this)

					{
						if (this.state.criteriaToggle === true) {
							values.criteria = final
						}
					}


					console.log("submitValues");
					console.log(values);




					this.props.spcmLoader('attribute', 'posting');
					return axios.post("/service/api/approval/createApprovalRule", values).then((response) => {
						this.props.getApprovals(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
						this.props.getServiceDetailCounts(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
						this.props.componentFormFun4(false);
						this.props.spcmLoader('attribute', 'success');
					})
						.catch((error) => {

							this.props.spcmLoader('attribute', 'success');
							throw new SubmissionError({ _error: error.response.data.message });
						});


				}
			}

			else if (this.props.formSelection == 'approval_question_form') {

				values.offeringid = this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID;
				values.offeringname = this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_NAME;
				values.companyid = this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID;

				if (this.props.spcmReducer.editLeftId > 0) {

					this.props.spcmLoader('attribute', 'posting');
					return axios.patch("/service/api/approval/editApprovalQuestions/" + this.props.spcmReducer.editLeftId, values).then((response) => {
						this.props.getApprovalQuestions(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
						this.props.componentFormFun4(false);
						this.props.spcmLoader('attribute', 'success');
					})
						.catch((error) => {

							this.props.spcmLoader('attribute', 'success');
							console.log(error);
							throw new SubmissionError({ _error: error.response.data.message });

						});


				}
				else {
					this.props.spcmLoader('attribute', 'posting');
					return axios.post("/service/api/approval/saveApprovalQuestions", values).then((response) => {
						this.props.getApprovalQuestions(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
						this.props.componentFormFun4(false);
						this.props.spcmLoader('attribute', 'success');
					})
						.catch((error) => {

							this.props.spcmLoader('attribute', 'success');
							throw new SubmissionError({ _error: error.response.data.message });
						});


				}
			}


		}

	}

	onModuleChange(e) {
    console.log(e.target.value);

    this.props.getApproverLevel(
      this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID,
      e.target.value
    );
	if (e.target.value == 14) { //14 for RFC
    this.setState({ rfcModuleSelected: true });
    this.props.getLovValues("ChangeManagement", "Type");
  } else {
    this.setState({ rfcModuleSelected: false });
  }
    for (var i = 0; i < this.state.extraCounts.length; i++) {
      this.props.dispatch(change("ApprovalForm", `key${i}`, ""));
    }
  }

	onChange(e) {
		this.setState({ type: e.target.value });
		if (e.target.value == 'Group') {
			// this.props.getApproverGroup(this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID);
			 this.props.loadCISupCompany(this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID);
			if(this.state.type == 'Individual' && this.state.companyIdParam != '') {
				this.onCrossClickCompany();
			}
			this.setState({ type: 'Group' });
		} else if (e.target.value == 'Individual') {
			// this.props.getApproverIndividual(this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID);
			 this.props.loadCISupCompany(this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID);
			if(this.state.type == 'Group' && this.state.companyIdParam != '') {
				this.onCrossClickCompany();
			}
			this.setState({ type: 'Individual' });
		}
		else {
			this.setState({ type: '' });
		}
	}

	addRow() {
		this.setState({ arrayCounts: this.state.arrayCounts + 1 });
		let arrayvar = this.state.extraCounts.slice();
		arrayvar.push(this.state.arrayCounts);
		this.setState({ extraCounts: arrayvar });
		this.props.setExtraCount(arrayvar);
	};
	deleteRow(myIndex) {
		let arrayvar = this.state.extraCounts;
		let index = arrayvar.indexOf(myIndex);
		arrayvar.splice(index, 1);
		this.setState({ extraCounts: arrayvar });
		this.props.setExtraCount(arrayvar);
	};



	loadCount(approverCriteria, approverCriteriaOperator, approverCriteriaScenario, approverEntity, approverLOB, approverCompanyCode, approverOrderType,approverCriteriaOperator2) {
		const rowlength = this.state.extraCounts.length;
		return this.state.extraCounts.map((extraCount, i) => {
            let valueDropDown=[]
			if(this.state[`key${extraCount}`] === "Organization")
				valueDropDown=this.state.spcmDropDownORG
			else if(this.state[`key${extraCount}`] === "Department")
				valueDropDown=this.state.spcmDropDownDEP
			else if(this.state[`key${extraCount}`] === "User Type")
				valueDropDown=this.state.spcmDropDownTYPEUSER
			else if(this.state[`key${extraCount}`] === "Cost Center")
				valueDropDown=this.state.spcmDropDownCENTRE
			else if(this.state[`key${extraCount}`] === "Location")
			    valueDropDown=this.state.spcmDropDownLOCATION

			if (this.props.spcmReducer.editLeftId && this.props.spcmReducer.editLeftId > 0) {

				if (rowlength === i + 1) {
					return <tr>
						<td>
							<Field component={_dynamicNamedropDown} nameforOptionsList="key" options={approverCriteria} name={"key" + extraCount} className='form-control' onChange={(e) => this.changeScenarioType(e, extraCount)} /></td>
						<td>
							<Field component={_dynamicNamedropDown} nameforOptionsList="operator" name={"operator" + extraCount}
								options={
									this.state[`key${extraCount}`] != "Organization" && this.state[`key${extraCount}`] != "Department" && this.state[`key${extraCount}`] != "User Type" && this.state[`key${extraCount}`] != "Cost Center" && this.state[`key${extraCount}`] != "Location" ? approverCriteriaOperator : approverCriteriaOperator2}
								className='form-control' />
						</td>
						<td>
							{
								this.state[`key${extraCount}`] && (this.state[`key${extraCount}`] != "Employee Band") && (this.state[`key${extraCount}`] != "CI Name")?
									<Field component={_dynamicNamedropDown} nameforOptionsList="value" name={"value" + extraCount} 
										options={
											this.state[`key${extraCount}`] === "Scenario Type" ? approverCriteriaScenario :
												this.state[`key${extraCount}`] === "Entity" ?
													approverEntity :
													this.state[`key${extraCount}`] === "LOB" ?
														approverLOB :
														this.state[`key${extraCount}`] === "Company Code" ?
															approverCompanyCode :
															this.state[`key${extraCount}`] === "Order Type" ?
																approverOrderType : valueDropDown
										} 
										className='form-control' /> : <Field component={_inputField} name={"value" + extraCount} onpaste={(e) => { this.valueChange(e) }} onChange={(e) => { this.valueChange(e) }} nameforOptionsList="value" className='form-control' />
							}
						</td><td>{i !== 0 ? <Button title="Delete" className="addMinusButton" onClick={() => { this.deleteRow(extraCount) }}><i className="fa fa-trash"></i></Button> : <Button title="Delete" className="addMinusButton" onClick={() => {this.resetValue(extraCount);}}><i className="fa fa-trash"></i></Button>}<Button title="Add" className="addMinusButton" onClick={() => { this.addRow() }}><i className="fa fa-plus"></i></Button></td></tr>;
				}
				else {

					return <tr key={i}>
						<td>
							<Field component={_dynamicNamedropDown} nameforOptionsList="key" options={approverCriteria} name={"key" + extraCount} className='form-control' onChange={(e) => this.changeScenarioType(e, extraCount)} />
						</td>
						<td>
							<Field component={_dynamicNamedropDown} nameforOptionsList="operator" name={"operator" + extraCount}
							options={
								this.state[`key${extraCount}`] != "Organization" && this.state[`key${extraCount}`] != "Department" && this.state[`key${extraCount}`] != "User Type" && this.state[`key${extraCount}`] != "Cost Center" && this.state[`key${extraCount}`] != "Location" ? approverCriteriaOperator : approverCriteriaOperator2}
							className='form-control' />
						</td>
						<td>
							{
								this.state[`key${extraCount}`] && (this.state[`key${extraCount}`] != "Employee Band") && (this.state[`key${extraCount}`] != "CI Name")?
									<Field component={_dynamicNamedropDown} nameforOptionsList="value" name={"value" + extraCount} 
										options={
											this.state[`key${extraCount}`] === "Scenario Type" ? approverCriteriaScenario :
												this.state[`key${extraCount}`] === "Entity" ?
													approverEntity :
													this.state[`key${extraCount}`] === "LOB" ?
														approverLOB :
														this.state[`key${extraCount}`] === "Company Code" ?
															approverCompanyCode :
															this.state[`key${extraCount}`] === "Order Type" ?
																approverOrderType : valueDropDown
										} 
										className='form-control' /> : <Field component={_inputField} name={"value" + extraCount} onpaste={(e) => { this.valueChange(e) }} onChange={(e) => { this.valueChange(e) }} nameforOptionsList="value" className='form-control' />
							}
						</td>
						<td><Button title="Delete" className="addMinusButton" onClick={() => { this.deleteRow(extraCount) }}><i className="fa fa-trash"></i></Button></td></tr>;
				}

			} else if (rowlength === i + 1) {
				
				return <tr>
					<td>
						<Field component={_dynamicNamedropDown} nameforOptionsList="key" options={approverCriteria} name={"key" + extraCount} className='form-control' onChange={(e) => this.changeScenarioType(e, extraCount)} /></td>
					<td>
						<Field component={_dynamicNamedropDown} nameforOptionsList="operator" name={"operator" + extraCount}
						options={
							this.state[`key${extraCount}`] != "Organization" && this.state[`key${extraCount}`] != "Department" && this.state[`key${extraCount}`] != "User Type" && this.state[`key${extraCount}`] != "Cost Center" && this.state[`key${extraCount}`] != "Location" ? approverCriteriaOperator : approverCriteriaOperator2}
						className='form-control' />
					</td>
					<td>
						{
								this.state[`key${extraCount}`] && (this.state[`key${extraCount}`] != "Employee Band") && (this.state[`key${extraCount}`] != "CI Name")?
									<Field component={_dynamicNamedropDown} nameforOptionsList="value" name={"value" + extraCount} 
										options={
											this.state[`key${extraCount}`] === "Scenario Type" ? approverCriteriaScenario :
												this.state[`key${extraCount}`] === "Entity" ?
													approverEntity :
													this.state[`key${extraCount}`] === "LOB" ?
														approverLOB :
														this.state[`key${extraCount}`] === "Company Code" ?
															approverCompanyCode :
															this.state[`key${extraCount}`] === "Order Type" ?
																approverOrderType : valueDropDown
										} 
										className='form-control' /> : <Field component={_inputField} name={"value" + extraCount} onpaste={(e) => { this.valueChange(e) }} onChange={(e) => { this.valueChange(e) }} nameforOptionsList="value" className='form-control' />
							}
					</td><td>{i !== 0 ? <Button title="Delete" className="addMinusButton" onClick={() => { this.deleteRow(extraCount) }}><i className="fa fa-trash"></i></Button> : null}<Button title="Add" className="addMinusButton" onClick={() => { this.addRow() }}><i className="fa fa-plus"></i></Button></td></tr>;
			}
			else {
				
				return <tr key={i}>
					<td>
						<Field component={_dynamicNamedropDown} nameforOptionsList="key" options={approverCriteria} name={"key" + extraCount} className='form-control' onChange={(e) => this.changeScenarioType(e, extraCount)} />
					</td>
					<td>
						<Field component={_dynamicNamedropDown} nameforOptionsList="operator" name={"operator" + extraCount} 
						options={
							this.state[`key${extraCount}`] != "Organization" && this.state[`key${extraCount}`] != "Department" && this.state[`key${extraCount}`] != "User Type" && this.state[`key${extraCount}`] != "Cost Center" && this.state[`key${extraCount}`] != "Location" ? approverCriteriaOperator : approverCriteriaOperator2}
						className='form-control' />
					</td>
					<td>
					{
								this.state[`key${extraCount}`] && (this.state[`key${extraCount}`] != "Employee Band") && (this.state[`key${extraCount}`] != "CI Name")?
									<Field component={_dynamicNamedropDown} nameforOptionsList="value" name={"value" + extraCount} 
										options={
											this.state[`key${extraCount}`] === "Scenario Type" ? approverCriteriaScenario :
												this.state[`key${extraCount}`] === "Entity" ?
													approverEntity :
													this.state[`key${extraCount}`] === "LOB" ?
														approverLOB :
														this.state[`key${extraCount}`] === "Company Code" ?
															approverCompanyCode :
															this.state[`key${extraCount}`] === "Order Type" ?
																approverOrderType : valueDropDown
										} 
										className='form-control' /> : <Field component={_inputField} name={"value" + extraCount} onpaste={(e) => { this.valueChange(e) }} onChange={(e) => { this.valueChange(e) }} nameforOptionsList="value" className='form-control' />
							}

					</td>
					<td><Button title="Delete" className="addMinusButton" onClick={() => { this.deleteRow(extraCount) }}><i className="fa fa-trash"></i></Button></td></tr>;
			}
		});
	}

	onTypeSelect(e){
		if (e.target.value == "25") {
			this.props.getLovValues("ChangeManagement", "Urgency");
		}
	}

	onCompanySelection(selectedCompany) {
		this.setState({ companyErrorColor: '', companySelectedList: selectedCompany });
		this.props.dispatch(change('ApprovalForm','approverGroup',''));
		this.setState({ inputValueGroup: '', groupSelectedList: [] });
		// this.props.emptyApproverGroup();
		this.props.emptyApproverGroupOrUser();
		// this.props.emptyApproverIndividual();
		
		if(selectedCompany.length > 0) {
			// this.props.dispatch(change('ApprovalForm','company',selectedCompany[0].id));
			// this.props.dispatch(change("ApprovalForm","companyName",selectedCompany[0].label));
			this.setState({companyIdParam: selectedCompany[0].id});
			this.setState({ inputValueCompany: selectedCompany[0].label });
			if (this.state.type == 'Group') {
				// this.props.getApproverGroup(selectedCompany[0].id);
				this.props.getApproverGroupOrUser(this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID, selectedCompany[0].id, 'group');
			} else if (this.state.type == 'Individual') {
				// this.props.getApproverIndividual(selectedCompany[0].id);
			}
		}
		else {
			// this.props.dispatch(change('ApprovalForm','company',''));
			// this.props.dispatch(change("ApprovalForm","companyName",''));
			this.setState({companyIdParam: ''});
		}
	}
	
	onCompanyInputChange(val) {
		this.setState({ inputValueCompany: val });
	}

	onCrossClickCompany() {
		// this.props.dispatch(change('ApprovalForm','company',''));
		// this.props.dispatch(change("ApprovalForm","companyName",''));
		this.setState({companyIdParam: ''});
		this.setState({ inputValueCompany: '', companySelectedList: [] });
		this.props.dispatch(change('ApprovalForm','approverGroup',''));
		this.setState({ inputValueGroup: '', groupSelectedList: [] });
		// this.props.emptyApproverGroup();
		this.props.emptyApproverGroupOrUser();
		// this.props.emptyApproverIndividual();
		this.setState({ companyErrorColor: 'error' });
		// this.setState({ groupErrorColor: 'error' });
	}
	
	setCompanyErrorColor() {
		if (this.state.companySelectedList.length === 0)
			this.setState({ companyErrorColor: 'error' });
		else
			this.setState({ companyErrorColor: '' });
	}
	
	onGroupSelection(selectedGroup) {
		this.setState({ groupErrorColor: '', groupSelectedList: selectedGroup });
		
		if(selectedGroup.length > 0) {
			this.props.dispatch(change('ApprovalForm','approverGroup',selectedGroup[0].id));
			this.setState({ inputValueGroup: selectedGroup[0].label });
		}
		else {
			this.props.dispatch(change('ApprovalForm','approverGroup',''));
		}
	}

	onGroupInputChange(val) {
		this.setState({ inputValueGroup: val });
	}

	onCrossClickGroup() {
		this.props.dispatch(change('ApprovalForm','approverGroup',''));
		this.setState({ inputValueGroup: '', groupSelectedList: [] });
		this.setState({ groupErrorColor: 'error' });
	}

	setGroupErrorColor() {
		if (this.state.groupSelectedList.length === 0)
			this.setState({ groupErrorColor: 'error' });
		else
			this.setState({ groupErrorColor: '' });
	}
	
	setIndividualErrorColor(errorVal) {
		this.setState({ individualErrorColor: errorVal });
	}
	
	render() {
		const tr = this.props.tr;
		let workItemType = this.props.spcmReducer.editService.work_item_type;
		let approverType = this.props.spcmReducer.approver_type;
		if(workItemType=='serviceRequest'){
			approverType = approverType.filter((currentValue, index)=>
				currentValue.field1Key!='CI Business Owner' && currentValue.field1Key!='CI Technical Owner'
			);
		}
		const signInType = [{ text: 'One must Sign In', value: 'OMS' }, { text: 'All must Sign In', value: 'AMS' }]
		let mod = this.props.spcmReducer.approver_module;
		if(workItemType == "standardChange"){
          mod=mod.filter(function(mod){
				return mod.field1Key!= "Item"
			})
		}else if(workItemType == "serviceRequest"||workItemType == "incidentRequest"){
			mod=mod.filter(function(mod){
				  return mod.field1Key!= "Change"
			  })
		  }
		if((this.props.spcmReducer.spcmDropDownCENTRE!=undefined || this.props.spcmReducer.spcmDropDownCENTRE!=null) && this.props.spcmReducer.spcmDropDownCENTRE[0] != undefined && (this.state.spcmDropDownCENTRE.length==0))
		{
			   let valueDropDown=[]
			   name=Object.getOwnPropertyNames(this.props.spcmReducer.spcmDropDownCENTRE[0])
			   let nameArr = name.split(',')
			   let attr_to_take=[]
			   for(let i=0;i<nameArr.length;i++)
			   {
				 if(this.props.spcmReducer.spcmDropDownCENTRE[0][nameArr[i]]==null)
					continue
				 else
				 {
					attr_to_take=nameArr[i]
					break 
				 }    	 
			   }
			  for(let i=0;i<this.props.spcmReducer.spcmDropDownCENTRE.length;i++)
			  {
				  let obj={field1Value: this.props.spcmReducer.spcmDropDownCENTRE[i][attr_to_take], field1Key: this.props.spcmReducer.spcmDropDownCENTRE[i][attr_to_take]}
				  valueDropDown.push(obj)
			  }
			  this.state.spcmDropDownCENTRE=valueDropDown
		}
		console.log("asdfghjkh : ",this.props.spcmReducer.spcmDropDownDEP)
		if((this.props.spcmReducer.spcmDropDownDEP!=undefined || this.props.spcmReducer.spcmDropDownDEP!=null) && this.props.spcmReducer.spcmDropDownDEP[0] != undefined && (this.state.spcmDropDownDEP.length==0))
		{
			           let valueDropDown=[]	
			           name=Object.getOwnPropertyNames(this.props.spcmReducer.spcmDropDownDEP[0])
					   let nameArr = name.split(',')
					   let attr_to_take=[]
					   for(let i=0;i<nameArr.length;i++)
					   {
						 if(this.props.spcmReducer.spcmDropDownDEP[0][nameArr[i]]==null)
							continue
						 else
						 {
							attr_to_take=nameArr[i]
							break 
						 }    	 
					   }
					  for(let i=0;i<this.props.spcmReducer.spcmDropDownDEP.length;i++)
					  {
						  let obj={field1Value: this.props.spcmReducer.spcmDropDownDEP[i][attr_to_take], field1Key: this.props.spcmReducer.spcmDropDownDEP[i][attr_to_take]}
						  valueDropDown.push(obj)
					  }
					  this.state.spcmDropDownDEP=valueDropDown
		}
		if((this.props.spcmReducer.spcmDropDownLOCATION!=undefined || this.props.spcmReducer.spcmDropDownLOCATION!=null) && this.props.spcmReducer.spcmDropDownLOCATION[0] != undefined && (this.state.spcmDropDownLOCATION.length==0))
		{
			let valueDropDown=[]	
			name=Object.getOwnPropertyNames(this.props.spcmReducer.spcmDropDownLOCATION[0])
			   let nameArr = name.split(',')
			   let attr_to_take=[]
			   for(let i=0;i<nameArr.length;i++)
			   {
				 if(this.props.spcmReducer.spcmDropDownLOCATION[0][nameArr[i]]==null)
					continue
				 else
				 {
					attr_to_take=nameArr[i]
					break 
				 }    	 
			   }
			  for(let i=0;i<this.props.spcmReducer.spcmDropDownLOCATION.length;i++)
			  {
				  let obj={field1Value: this.props.spcmReducer.spcmDropDownLOCATION[i][attr_to_take], field1Key: this.props.spcmReducer.spcmDropDownLOCATION[i][attr_to_take]}
				  valueDropDown.push(obj)
			  }
			  this.state.spcmDropDownLOCATION=valueDropDown
		}
		if((this.props.spcmReducer.spcmDropDownORG!=undefined || this.props.spcmReducer.spcmDropDownORG!=null) && this.props.spcmReducer.spcmDropDownORG[0] != undefined && (this.state.spcmDropDownORG.length==0))
		{
			let valueDropDown=[]
			        name=Object.getOwnPropertyNames(this.props.spcmReducer.spcmDropDownORG[0])
					let nameArr = name.split(',')
					let attr_to_take=[]
					for(let i=0;i<nameArr.length;i++)
					{
					  if(this.props.spcmReducer.spcmDropDownORG[0][nameArr[i]]==null)
						 continue
					  else
					  {
						 attr_to_take=nameArr[i]
						 break 
					  }    	 
					}
				   for(let i=0;i<this.props.spcmReducer.spcmDropDownORG.length;i++)
				   {
					   let obj={field1Value: this.props.spcmReducer.spcmDropDownORG[i][attr_to_take], field1Key: this.props.spcmReducer.spcmDropDownORG[i][attr_to_take]}
					   valueDropDown.push(obj)
				   }
				   this.state.spcmDropDownORG=valueDropDown
		}
		if((this.props.spcmReducer.spcmDropDownTYPEUSER!=undefined || this.props.spcmReducer.spcmDropDownTYPEUSER!=null) && this.props.spcmReducer.spcmDropDownTYPEUSER[0] != undefined && (this.state.spcmDropDownTYPEUSER.length==0))
		{
			let valueDropDown=[]
			name=Object.getOwnPropertyNames(this.props.spcmReducer.spcmDropDownTYPEUSER[0])
					   let nameArr = name.split(',')
					   let attr_to_take=[]
					   for(let i=0;i<nameArr.length;i++)
					   {
						 if(this.props.spcmReducer.spcmDropDownTYPEUSER[0][nameArr[i]]==null)
							continue
						 else
						 {
							attr_to_take=nameArr[i]
							break 
						 }    	 
					   }
					  for(let i=0;i<this.props.spcmReducer.spcmDropDownTYPEUSER.length;i++)
					  {
						  let obj={field1Value: this.props.spcmReducer.spcmDropDownTYPEUSER[i][attr_to_take], field1Key: this.props.spcmReducer.spcmDropDownTYPEUSER[i][attr_to_take]}
						  valueDropDown.push(obj)
					  }
					  this.state.spcmDropDownTYPEUSER=valueDropDown
		}
		if (this.props.formSelection == 'approval_question_form') {
			mod = mod.filter(e => {
				return e.field1Value === "10"
			})
		}

		const level = this.props.spcmReducer.approver_level;
		// const groups = this.props.spcmReducer.approver_group;
		// const individuals = this.props.spcmReducer.approver_individual;
		const approverCriteriaOperator = this.props.spcmReducer.approverCriteriaOperator;
		const approverCriteriaScenario = this.props.spcmReducer.approverCriteriaScenario;
		const approverEntity = this.props.spcmReducer.approverEntity;
		const approverLOB = this.props.spcmReducer.approverLOB;
		const approverOrderType = this.props.spcmReducer.approverOrderType;
		const approverCompanyCode = this.props.spcmReducer.approverCompanyCode;
		const approverCriteriaOperator2 = [{"field1Key": "=","field1Value": "="}];
		let approverCriteria = this.props.spcmReducer.approverCriteria;


		let rfcChangeType = this.props.lovForType;
		console.log("tocheckk",this.props.lovForType);
		// rfcChangeType = rfcChangeType.map((item) => {
		// 	return { text: item.field1Key, value: item.field1Value }
		// })
		
		if(workItemType== "standardChange"){
			rfcChangeType=rfcChangeType.filter(function(rfcChangeType){
				return rfcChangeType.field1Key=="Standard"
			})
		}
		else{
			rfcChangeType=rfcChangeType.filter(function(rfcChangeType){
				return rfcChangeType.field1Key!="Standard"
			})
		}
    	rfcChangeType = rfcChangeType.map((item) => {
        return { text: item.field1Key, value: item.field1Value };
  });

		let rfcChangeTiming = [...this.props.lovForChangeTiming, { field1Key: 'All', field1Value: 'All' }];
		rfcChangeTiming = rfcChangeTiming.map((item) => {
			return { text: item.field1Key, value: item.field1Value }
		})

		if(!this.state.rfcModuleSelected){
			approverCriteria = approverCriteria.filter(function(approverCriteria){
				return approverCriteria.field1Key != "CI Name"
			})
		}
		else {
			approverCriteria = this.props.spcmReducer.approverCriteria;
		}

		console.log("approverCriteria");
		console.log(approverCriteria);
		const { error, handleSubmit, urgency} = this.props;

		let companyTypeaheadOptions = [];
		if(this.props.ciSupCompany && this.props.ciSupCompany.length > 0) {
			let flag=0;
			this.props.ciSupCompany.forEach((item,i) => {
				let obj = {};
				obj.id = item.field1Value;
				obj.label = item.field1Key;
				companyTypeaheadOptions.push(obj);
				if(item.field1Value == this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID)
					flag=1;
			});
			if(flag == 0) {
				let obj = {};
				obj.id = this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID;
				obj.label = this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_NAME;
				companyTypeaheadOptions.push(obj);
			}
		}
		
		let groupsTypeaheadOptions = [];
		if(this.props.spcmReducer.approver_group_or_individual && this.props.spcmReducer.approver_group_or_individual.length > 0) {
			this.props.spcmReducer.approver_group_or_individual.forEach((item,i) => {
				let obj = {};
				obj.id = item.field1Value;
				obj.label = item.field1Key;
				groupsTypeaheadOptions.push(obj);
			});
		}
		
		return (
			<div className="rBoxGap">
				<form onSubmit={handleSubmit(this.submitForm)}>
					{error == undefined || error == null ? null : <Alert variant="danger" className="errorMessageSec"><p>{error}</p></Alert>}

					{this.props.formSelection == 'approval_form' ?
						<div>
							<Row>{console.log('StatusState22', this.props.serviceStatusState)}
								<Col md={12}>
									<Form.Group className="priceForm-group form-group">

										<Form.Label bsClass=""><span className="rStar"></span> {tr['Rule Name']}</Form.Label>
										<Field component={_inputField} name="ruleName" disabled={this.props.spcmReducer.editLeftId > 0} className='form-control'>
										</Field>
									</Form.Group>
								</Col>
								<Col md={12}>
									<Form.Group className='form-group'>
										<Form.Label bsClass=""><span className="rStar"></span> {tr['Module']} </Form.Label>
										<Field component={_dropDown} options={mod} name="moduleId" className='form-control' onChange={this.onModuleChange} disabled={this.props.spcmReducer.editLeftId > 0}>
										</Field>
									</Form.Group>
								</Col>
								{this.state.rfcModuleSelected && (
									<Col md={12}>
										<Form.Group className='form-group'>
											<Form.Label bsClass=""><span className="rStar"></span> {tr['Change Type']} </Form.Label>
											<Field component={_dropDown} options={rfcChangeType} name="rfcChangeType" className='form-control' onChange={(e) => this.onTypeSelect(e)} disabled={this.props.spcmReducer.editLeftId > 0}>
											</Field>
										</Form.Group>
									</Col>)}
								{this.state.rfcModuleSelected && (
								this.props.selectedType == "25" ?  (
								this.props.spcmReducer.editLeftId == undefined ?(
								<Col md={12}>
								   <Form.Group className='form-group'>
								        <Form.Label bsClass=""><span className="rStar"></span> {tr['Urgency']} </Form.Label>
										<Field name="urgency" component={_dropDown} options={urgency} className="form-control">
							         	</Field>
							    	</Form.Group>
								</Col>):(<Col md={12}>
								   <Form.Group className='form-group'>
								        <Form.Label bsClass="">{tr['Urgency']} </Form.Label>
										<Field name="urgency" component={_dropDown} options={urgency} className="form-control" disabled>
							         	</Field>
							    	</Form.Group>
								</Col>)
								): ''
								// workItemType == "standardChange" || this.props.selectedType=="30"?"":
								// (<Col md={12}>
								// 	<Form.Group >
								// 		<Form.Label bsClass=""><span className="rStar"></span> {tr['Change Timing']} </Form.Label>
								// 		<Field component={_dropDown} options={rfcChangeTiming} name="rfcChangeTiming" className='form-control' disabled={this.props.spcmReducer.editLeftId > 0}>
								// 		</Field>
								// 	</Form.Group>
								// </Col>)
								)}

							</Row>
							<Row>

								<Col md={12}>
									<Form.Group className='form-group'>
										<Form.Label bsClass=""><span className="rStar"></span> {tr['Level']} </Form.Label>
										<Field component={_dropDown} options={level} name="level" className='form-control' disabled={this.props.spcmReducer.editLeftId > 0}>
										</Field>
									</Form.Group>
								</Col>
								<Col md={12}>
									<Form.Group className='form-group'>
										<Form.Label bsClass=""><span className="rStar"></span> {tr['Type']} </Form.Label>
										<Field component={_dropDown} options={approverType} name="approverType" className='form-control' onChange={this.onChange} disabled={this.props.spcmReducer.editLeftId > 0}>
										</Field>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								{
									this.state.type == 'Individual' ?
										(
											<div>
											{this.props.spcmReducer.editLeftId > 0 ? null :
											<Col md={12}>
												<Form.Group className='form-group'>
													<Form.Label bsClass=""><span className="rStar"></span> {tr['Company']} </Form.Label>
													<Field component={DropdownPlusTypeahead}
														onSelection={this.onCompanySelection}
														setErrorColor={this.setCompanyErrorColor}
														name="company"
														id="company"
														className="form-control"
														options={companyTypeaheadOptions}
														selectedOptions={this.state.companySelectedList}
														onInputChange={this.onCompanyInputChange}
														inputValue={this.state.inputValueCompany}
														onCrossClick={this.onCrossClickCompany}
														errorColor={this.state.companyErrorColor}
													/>
												</Form.Group>
											</Col>}
											<Col md={12}>
												<Form.Group className='form-group'>
													<Form.Label bsClass=""><span className="rStar"></span> {tr['Approver Individual']} </Form.Label>
													{
														this.props.spcmReducer.editLeftId > 0 ? 
														// <Field component={_dropDown} options={individuals} name="approverIndividual" className='form-control' disabled></Field>
														<Field component={_inputField} title={this.props.initialValues.approverIndividualDetails} name="approverIndividualName" className='form-control' disabled></Field>
														: 
														<IndividualAsyncTypeahead
															id="approverIndividual"
															name="approverIndividual"
															setErrorColor = {this.setIndividualErrorColor}
															offeringCompanyIdParam = {this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID}
															supportCompanyIdParam = {this.state.companyIdParam}
															translator={this.props.tr}
															errorColor={this.state.individualErrorColor}
														/>
													}
												</Form.Group>
											</Col>
											</div>
										) : null

								}

								{
									this.state.type == 'Group' ? (
										<div>
											{this.props.spcmReducer.editLeftId > 0 ? null : 
											<Col md={12}>
												<Form.Group className='form-group'>
													<Form.Label bsClass=""><span className="rStar"></span> {tr['Company']} </Form.Label>
													<Field component={DropdownPlusTypeahead}
														onSelection={this.onCompanySelection}
														setErrorColor={this.setCompanyErrorColor}
														name="company"
														id="company"
														className="form-control"
														options={companyTypeaheadOptions}
														selectedOptions={this.state.companySelectedList}
														onInputChange={this.onCompanyInputChange}
														inputValue={this.state.inputValueCompany}
														onCrossClick={this.onCrossClickCompany}
														errorColor = {this.state.companyErrorColor}
													/>
												</Form.Group>
											</Col>}
											<Col md={12}>
												<Form.Group className='form-group'>
													<Form.Label bsClass=""><span className="rStar"></span> {tr['Approver Group']} </Form.Label>
													{
														this.props.spcmReducer.editLeftId > 0 ? 
														// <Field component={_dropDown} options={groups} name="approverGroup" className='form-control' disabled></Field>
														<Field component={_inputField} title={this.props.initialValues.approverGroupDetails} name="approverGroupName" className='form-control' disabled></Field>
														:
														<Field component={DropdownPlusTypeahead}
															onSelection={this.onGroupSelection}
															setErrorColor={this.setGroupErrorColor}
															name="approverGroup"
															id="approverGroup"
															className="form-control"
															options={groupsTypeaheadOptions}
															selectedOptions={this.state.groupSelectedList}
															onInputChange={this.onGroupInputChange}
															inputValue={this.state.inputValueGroup}
															onCrossClick={this.onCrossClickGroup}
															errorColor = {this.state.groupErrorColor}
														/>
													}
												</Form.Group>
											</Col>
										</div>
									) : null
								}



							</Row>
							<Row>
								{
									this.state.type == 'Group' ? <Col md={12}>
										<Form.Group className='form-group'>
											<Form.Label bsClass=""><span className="rStar"></span> {tr['Sign In Type']} </Form.Label>
											<Field component={_dropDown} options={signInType} name="signIn" className='form-control' disabled={this.props.spcmReducer.editLeftId > 0}>
											</Field>
										</Form.Group>
									</Col> : ''
								}
                               <Col md={12}>
									<Form.Group className='form-group'>
										<Form.Label bsClass=""><span className="rStar"></span> {tr['Weightage']} </Form.Label>
										<Field component={_number} max={50} min={1} name="weightage" className='form-control' >
										</Field>
									</Form.Group>
								</Col>

								<Col md={12}>
									<Form.Group className="form-group">
										<Form.Label bsClass=""> {tr["Automatic action on approvals in pending status"]} </Form.Label>
										<Field component={_dropDown} options={this.props.approvalsAutoAction} name="autoAction" className="form-control" ></Field>
									</Form.Group>
								</Col>

								<Col md={12}>
									<Form.Group className="form-group">
										<Form.Label bsClass=""> {tr["Automatic action on approvals in (x) days"]} </Form.Label>
										<Field component={_number} max={50} min={1} name="autoActionInDays" className="form-control" ></Field>
									</Form.Group>
								</Col>

								{
									this.state.criteriaToggle === true ?
										<Col md={12}>
											<Table className="criteria-table" cellspacing="5" cellpadding="5">
												<thead>
													<tr>
														<th width="39%">Key</th>
														<th width="22%">Operator</th>
														<th width="29%">Value</th>
														<th width="10%">{tr['Action']}</th>
													</tr>
												</thead>
												<tbody>
													{this.loadCount(approverCriteria, approverCriteriaOperator, approverCriteriaScenario, approverEntity, approverLOB, approverCompanyCode, approverOrderType,approverCriteriaOperator2)}
												</tbody>
											</Table>

										</Col> : null
								}




								<Col md={12}>
									<ButtonToolbar className="black margin-t-10">

										{/* {
											this.props.spcmReducer.editLeftId > 0 ? null : <Button title="Save" bsSize="small" bsStyle="primary" disabled={this.props.spcmReducer.spcmPostingAttribute} type="submit" >
												{this.props.spcmReducer.spcmPostingAttribute ? <i className="f-size-13 fa fa-spinner fa-spin"></i> : null} {tr['Save']}</Button>
										} */}


										{this.state.criteriaExist === false ?
											<Button className='rgSiLigBtn' title={this.state.criteriaToggle ?
												'Remove Criteria' : 'Add Criteria'} onClick={() => { this.addCriteriaToggle() }} bsSize="small" bsStyle="primary">{this.state.criteriaToggle ?
													'Remove Criteria' : 'Add Criteria'}</Button> :
											null}


										<Button className='rgSidrkBtn' title="Save" bsSize="small" bsStyle="primary" disabled={this.props.spcmReducer.spcmPostingAttribute} type="submit" >
											{this.props.spcmReducer.spcmPostingAttribute ? <ImSpinner6 className="icn-spinner"/> : null} {tr['Save']}</Button>


										<Button className='rgSiLigBtn' title="Close" onClick={() => { this.props.componentFormFun4(false) }} bsSize="small" bsStyle="text">{tr['Close']}</Button></ButtonToolbar>
								</Col>
							</Row>

						</div> :
						<div>
							<Row>
								<Col md={12}>
									<Form.Group className='form-group'>

										<Form.Label bsClass=""><span className="rStar"></span> {tr['Question']}</Form.Label>
										<Field component={_inputField} name="questiontext" className='form-control'>
										</Field>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col md={12}>
									<Form.Group className='form-group'>
										<Form.Label bsClass=""><span className="rStar"></span> {tr['Module']} </Form.Label>
										<Field component={_dropDown} options={mod} name="module" className='form-control' onChange={this.onModuleChange}>
										</Field>
									</Form.Group>
								</Col>
							</Row>
							<Row>

								<Col md={12}>
									<Form.Group className='form-group'>
										<Form.Label bsClass=""><span className="rStar"></span> {tr['Level']} </Form.Label>
										<Field component={_dropDown} options={level} name="level" className='form-control'>
										</Field>
									</Form.Group>
								</Col>
							</Row>

							<Row>

								<Col md={12}>
									<Form.Group className='form-group'>
										<Form.Label bsClass=""><span className="rStar"></span> {tr['Visibility Sequence']} </Form.Label>
										<Field component={_number} max={50} min={1} name="sequence" className='form-control'>
										</Field>
									</Form.Group>
								</Col>

							</Row>

							<Row>
								<Col md={12}>
									<Form.Group className='form-group'>
										<Form.Label bsClass=""><span className="rStar"></span> {tr['Status']}</Form.Label>
										<Field component={_dropDown} name="status" className='form-control' onChange={(e) => { this.setState({ categoryNameInitialStatus: e.target.value }) }} options={[{ value: "", text: "Select" }, { value: "Y", text: "Active" }, { value: "N", text: "Inactive" }]} />
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col md={12}>
									<Form.Group className='form-group'>
										<Form.Label bsClass=""><span className="rStar"></span> {tr['Response Value']}</Form.Label>
										<Field component={_inputField} name="questionvalue" className='form-control'>
										</Field>
									</Form.Group>
									<span className="f-size-10">{this.props.tr['Separated by a semicolon (Ex. Variable 1 ;  Variable 2 ;  Variable 3)']}</span>
								</Col>
							</Row>



							<Row>

								<Col md={12}>
									<ButtonToolbar className="black">

										<Button className='rgSidrkBtn' title="Save" bsSize="small" bsStyle="primary" disabled={this.props.spcmReducer.spcmPostingAttribute} type="submit" >
											{this.props.spcmReducer.spcmPostingAttribute ? <ImSpinner6 className="icn-spinner"/> : null} {tr['Save']}</Button>

										<Button className='rgSiLigBtn' title="Close" onClick={() => { this.props.componentFormFun4(false) }} bsSize="small" bsStyle="text">{tr['Close']}</Button></ButtonToolbar>
								</Col>
							</Row>

						</div>
					}

				</form>
			</div>
		);
	}
}

const mapStateToProps = ({ spcmReducer, lovForType, lovForChangeTiming, lovForUrgency , ciSupCompany, approvalsAutoAction}, ownProps) => {
	let urgency =  lovForUrgency == "undefined" || lovForUrgency.length == 0 ? [] : lovForUrgency;
	if (spcmReducer.editLeftId > 0) {
		if (ownProps.formSelection == "approval_form") {

			if (spcmReducer.editLeftService.criteriaList!=undefined && spcmReducer.editLeftService.criteriaList!="undefined" && spcmReducer.editLeftService.criteriaList !== null && spcmReducer.editLeftService.criteriaList.length > 0) {
				let obj = {}
				spcmReducer.editLeftService.criteriaList.map((item, index) => {
					Object.keys(item).forEach(val => {
						let key = val + index;
						obj[key.toLowerCase()] = item[val];
					})
				});
				spcmReducer.editLeftService = Object.assign(spcmReducer.editLeftService, obj);
			}
		}

	}

	console.log("===INITIAL",spcmReducer.editLeftId,spcmReducer.editLeftService);
	return {
		spcmReducer,
		lovForType,
		lovForChangeTiming,
		urgency,
		initialValues: spcmReducer.editLeftId > 0 ? spcmReducer.editLeftService : '',
		ciSupCompany,
		approvalsAutoAction
	}
};

ApprovalForm = reduxForm({
	form: 'ApprovalForm',
	validate,
	enableReinitialize: true
})(ApprovalForm)

const selector = formValueSelector('ApprovalForm');
ApprovalForm = connect((state)=> {
	let selectedType = selector(state,'rfcChangeType');
	console.log("selectedType===",selectedType);
	return{
		selectedType
	}
})(ApprovalForm);

export default connect(mapStateToProps, { spcmLoader, getLovValues, setLeftEditId, getApprovals, getApprovalQuestions, getApprovalDetails, getApproverType, getApproverModule, getApproverLevel, getApproverGroup, getApproverIndividual, emptyApproverGroup, emptyApproverIndividual, getApproverGroupOrUser, emptyApproverGroupOrUser, getApproverCriteria, getApproverLOB, getApproverOrderType, getApproverCompanyCode, getApproverEntity, getApproverCriteriaOperator, getApproverScenario, listDataWithType, loadCISupCompany, getServiceDetailCounts, loadApprovalsAutoAction })(ApprovalForm);
