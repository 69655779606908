
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
// import {
//   Row, Col,
//   Button,
//   FormControl,
//   FormGroup,
//   ControlLabel,
//   Table,
//   ButtonToolbar,
//   Alert
// } from "react-bootstrap";
import {
  _textArea,
  _dropDown,
  _inputField,
  _number,
  _radio,
  TypeaheadExample,
  TypeaheadandDropdown
} from "../../common/formFields";
import { loadCIClass } from "../../../actions/cmdb/lovAction"
import {
  loadamsModuleStatus
} from "../../../actions/sla/quickViewAction";
import { connect } from "react-redux";
import axios from "axios";
import { bindActionCreators } from "redux";
import {
  fetchPriorityList
} from '../../../actions/breakFix/quickViewAction';
import { loadCIUserBasedLocation } from '../../../actions/cmdb/lovAction';
import {GLOBAL} from '../../Globals'
import {IoClose} from "react-icons/io5";
import { PiPlusSquareBold } from "react-icons/pi";

// let GLOBAL = require("../../../components/Globals");


import {
  Field,
  reduxForm,
  formValueSelector,
  change,
} from "redux-form";

import {
  getAMSAttributeList,
  getAttributesList,
  getOperatorsList,
  deleteAMSAttribute
} from '../../../actions/amsActions/amsActions.js';
import ListLoader from "../../common/loaders/ListLoader";
const api = axios.create({
  headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});
// let isClassBasedImpactedCI=false;
class PopUpAttribute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFormVisible: false,
      error: '',
      attributeSelected: {},
      operatorSelected: {},
      valueSelected: {},
      editEnable: false,
      selectedConsumers: [],
      consumerDataDetails: [],
      typedRequestor: '',
      selectedValueList: [],
      valuesDropdownOptions: [],
      valueError: '',
      attributeError: '',
      operatorError: '',
      amsJoinCondition:'AND',
      isSaveClicked: true,
      userCILocationInitial: []
      // classBasedImpactedCI:[],
    };
    this.setAttributeChange = this.setAttributeChange.bind(this);
    this.setOperatorChange = this.setOperatorChange.bind(this);
    this.validateValueField = this.validateValueField.bind(this);
    this.onRequestorChange = this.onRequestorChange.bind(this);
    this.saveAttribute = this.saveAttribute.bind(this);
    this.getValuesDropdownOptions = this.getValuesDropdownOptions.bind(this);
    this.renderValueDropdown = this.renderValueDropdown.bind(this);
    this.onValueSelection = this.onValueSelection.bind(this);
    this.generateOptions = this.generateOptions.bind(this);
    this.setValueError = this.setValueError.bind(this);
    this.getFilteredAttributeList = this.getFilteredAttributeList.bind(this);
    this.renderAttList = this.renderAttList.bind(this);
    this.deleteAttribute = this.deleteAttribute.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.ANDORClick=this.ANDORClick.bind(this);
    this.setError = this.setError.bind(this);
    this.getLocation=this.getLocation.bind(this);
    // this.isClassBasedCI=this.isClassBasedCI.bind(this);
  }

  getLocation(value){
    let result = this.state.userCILocationInitial;
    let str = {};
    str.companyId = this.props.ruleConfiguration.company, str.like=value, str = JSON.stringify(str);
    api.get(GLOBAL.getCIUserBasedLocationUrl, { headers: { 'query': str } }).then((response) => {
      if (Array.isArray(response.data)) {
        let count=0;
        response.data.map(networkItem => {
          count=0;
          this.state.valuesDropdownOptions.forEach((item) => {
            if(networkItem.LOCATION_ID===item.id){
              ++count;
            }
          });
          if(count===0){
           result.push({ id: networkItem.LOCATION_ID, label: networkItem.LOCATION_NAME });
          }
        });
      }
      this.setState({ valuesDropdownOptions: result });
    });
  }
  componentWillMount() {
    const { ruleConfiguration: { moduleName, ruleId = "" } = {} } = this.props;
    this.props.getAttributesList(moduleName);
    this.props.getOperatorsList();
  }

  renderAttList(attributeData) {
    // this.checkClassOnLoad(attributeData)

    try {

      if (!(attributeData && Array.isArray(attributeData) && attributeData.length)) {
        return (
          <tr>
            <td colSpan="5" style={{ "textAlign": "center" }}>{this.props.translator['There is no matching data available']}</td>
          </tr>
        );
      }
      return attributeData.map((item, index) => {

        return (
          <tr>
            <td>{item.attrName}</td>
            <td>{item.operatorName}</td>
            <td>{item.attrValue}</td>
            <td>{item.attrJoinCondition}</td>
            <td className="text-c f-size-13">
              <i
                onClick={() => { this.deleteAttribute(item.id); }}
                className="fa fa-trash-o cursorPoint"
              />
            </td>
          </tr>
        );
      });
    } catch (e) {
      return null;
    }
  }

  generateOptions(options) {
    try {
      return options.map((item, index) => {
        return (
          this.props.ruleConfiguration.moduleName==="Problem" && this.state.attributeSelected.name==="Priority" && item.name==="Critical" ? '' :
          <option value={item.id}>
            {item.name}
          </option>
        );
      });
    } catch (e) {
      return null;
    }
  }

  setOperatorChange(value) {
    let obj = { id: value.value, name: value.text };
    this.setState({ operatorSelected: obj });

    if(!(obj.name && obj.id)){
      this.setState({operatorError: 'error'});
    }
    else{
      this.setState({operatorError: ''});
    }
  }
  setAttributeChange(value) {
    let obj = { id: value.value, name: value.text };
    this.setState({ attributeSelected: obj, selectedValueList: [], consumerDataDetails: [] });

    if(!(obj.name && obj.id)){
      this.setState({attributeError: 'error'});
    }
    else{
      this.setState({attributeError: ''});
    }

    // this.props.dispatch(change("PopUpAttribute", "amsCriteriaValue", ""));
    let str = {};
    str.companyId = this.props.ruleConfiguration.company, str = JSON.stringify(str);

    if (value.text == "Service") {
      let result = [];
      let str = {};
      str['companyId'] = this.props.ruleConfiguration.company;
      let moduleObj = {
        'Fulfillment': 'service',
        'Incident': 'incident',
        'ChangeManagement': 'change',
        'Task': ''
      };
      let url = '/getServiceListForAMS';
      if(this.props.ruleConfiguration.moduleName == 'Incident'){
        url = '/api/foundations/serviceListSelfServiceCompanyId/';
      }
      else{
        if( ['Fulfillment', 'Incident', 'ChangeManagement'].includes(this.props.ruleConfiguration.moduleName) ){
          str['moduleType'] = moduleObj[this.props.ruleConfiguration.moduleName];
        }
      }

      str = JSON.stringify(str);
      /**
       * Old API URL: "/api/foundations/serviceListSelfServiceCompanyId/"
       */
      axios.get(url, { headers: { 'query': str } }).then((response) => {
        let impactedServiceData = response.data;
        if (Array.isArray(response.data)) {
          for (let i = 0; i < impactedServiceData.length; i++) {
            let obj = { id: '', value: '', label: '', companyname: '', categoryid: '', categoryname: '' };
            if(this.props.ruleConfiguration.moduleName == 'Incident'){
              obj.id = impactedServiceData[i]['offeringId'];
              obj.label = impactedServiceData[i]['offeringName'];
            }
            else{
              obj.id = impactedServiceData[i]['OFFERING_ID'];
              obj.label = impactedServiceData[i]['OFFERING_NAME'];
            }
            result.push(obj);
          }
          result = Array.from(new Set(result));
        }
        this.setState({
          valuesDropdownOptions: result
        })
      });
    }
    else if (value.text == "Class") {
      this.props.loadCIClass();
    }
    else if (value.text == "Impacted CI") {
      let result = [];
      axios.get(GLOBAL.ciNameDetailsUrl + '?searchByList=STATUS&multipleValueList=7&searchValue=&companyId=' + this.props.ruleConfiguration.company).then((response) => {
        if (Array.isArray(response.data)) {
          response.data.map((item) => {
            result.push({ id: item.CI_ID, label: item.CI_NAME });
          });
        }
        this.setState({ valuesDropdownOptions: result });
      });
      // if(isClassBasedImpactedCI==false){
      // }
      // else
      // {
      //   this.setState({valuesDropdownOptions:this.state.classBasedImpactedCI.length==0?this.props.isClassExist_list:this.state.classBasedImpactedCI});
      // }
    }
    else if (value.text == "CI.Location") {

      // let result = [];
      // axios.get(GLOBAL.ciNameDetailsUrl + '?searchByList=STATUS&multipleValueList=7&searchValue=&companyId=' + this.props.ruleConfiguration.company).then((response) => {
      //   if (Array.isArray(response.data)) {
      //     response.data.map((item) => {
      //       result.push({ id: item.LOCATION_ID, label: item.LOCATION_NAME });
      //     });
      //   }
      //   this.setState({ valuesDropdownOptions: result });
      // });

      let result = [];
      let str = {};
      str.companyId = this.props.ruleConfiguration.company, str = JSON.stringify(str);
      api.get(GLOBAL.getCIUserBasedLocationUrl, { headers: { 'query': str } }).then((response) => {
        if (Array.isArray(response.data)) {
          response.data.map(item => {
            result.push({ id: item.LOCATION_ID, label: item.LOCATION_NAME });
          });
        }
        this.setState({ valuesDropdownOptions: result, userCILocationInitial: result });
      });


    }
    else if (value.text == 'Status') {
      this.props.loadamsModuleStatus(this.props.ruleConfiguration.moduleName == "Fulfillment" ? "Fullfilment" : this.props.ruleConfiguration.moduleName == "Incident" ? "Breakfix" : this.props.ruleConfiguration.moduleName == "Problem" ? "Investigation" : this.props.ruleConfiguration.moduleName,"ViewStatus");
    }

    else if (value.text == "Priority") {
      this.props.fetchPriorityList(this.props.ruleConfiguration.moduleName == "Problem" ? "Investigation" : this.props.ruleConfiguration.moduleName == "Incident" ? "Breakfix" : this.props.ruleConfiguration.moduleName);
    }
    else if (value.text == "User.Location") {
      let result = [];
      api.get(GLOBAL.getCIUserBasedLocationUrl, { headers: { 'query': str } }).then((response) => {
        if (Array.isArray(response.data)) {
          response.data.map(item => {
            result.push({ id: item.LOCATION_ID, label: item.LOCATION_NAME });
          });
        }
        this.setState({ valuesDropdownOptions: result, userCILocationInitial: result });
      });
    }
    else if (value.text == "Component") {
      let result = [];
      let offeringID = '';
      const { attributesList = [] } = this.props;
      let serviceAttrInList = attributesList.filter(item => item['attrName'] == 'Service');
      if(serviceAttrInList.length){
        let str={};
        str.userId="";
        str.offeringId=serviceAttrInList[0]['attrValueId'];
        str = JSON.stringify(str);
        api.get(GLOBAL.getComponentDetails,{headers:{'query':str}})
        .then((componentPackage) => {
          if(componentPackage && componentPackage.data && Array.isArray(componentPackage.data)){
            (componentPackage.data).forEach(item => {
              result.push({ id: item['componentId'], name: item['componentName'] });
            });
          }
          this.setState({ valuesDropdownOptions: result });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ valuesDropdownOptions: result });
        });
      }
      else{
        this.setState({ valuesDropdownOptions: result });
      }
    }
  }

  onRequestorChange(value) {
    this.setState({ consumerDataDetails: [], selectedConsumers: [], typedRequestor: value });
    if (value.length > 3) {
      let consumerDataDetails = [], consumerData = [], myObj = {}, strObj = {};
      strObj.like = value, strObj.companyId = this.props.ruleConfiguration.company, strObj = JSON.stringify(strObj);
      axios.get(GLOBAL.consumerUsersNewUrl, { headers: { 'query': strObj } }).then((response) => {
        consumerData = response.data;
        if (Array.isArray(response.data) && this.state.typedRequestor !== '') {
          for (let i = 0; i < consumerData.length; i++) {
            myObj = { value: '', label: '' };
            myObj.id = consumerData[i].userId;
            myObj.label = consumerData[i].fullname + "|" + consumerData[i].email;
            myObj.name = consumerData[i].fullname;
            consumerDataDetails.push(myObj);
          }
          consumerDataDetails = Array.from(new Set(consumerDataDetails));
        } else {
          consumerDataDetails = [];
        }
        this.setState({
          consumerDataDetails: consumerDataDetails
        })
      });
    }
  }
  // isClassBasedCI(classIDtoPass)
  // {
  //   let result=[];
  //   axios.get("/api/config_items/ciDetailsByClassId?class_id=" + classIDtoPass).then((response)=>{
  //         response.data.map((item)=>{
  //           result.push({id:item.CI_ID, label:item.CI_NAME});
  //      });
  //      this.setState({
  //       classBasedImpactedCI:result
  //     });
  //   });
  // }
  saveAttribute(event, formVisible = false) {
    event.preventDefault();
    try{
      const { attributeSelected = {}, operatorSelected = {}, selectedValueList } = this.state;
      const { ruleConfiguration = {}, attributesList, updateAttributesList } = this.props;

      let multiValueCollections={id:'',value:''};
      let ref = this;
        this.state.selectedValueList.forEach((item) => {
          multiValueCollections.id += ',' + item.id,
            multiValueCollections.value += ',' + item.label;
        });
        multiValueCollections.id = multiValueCollections.id.substr(1, multiValueCollections.id.length - 1)
          , multiValueCollections.value = multiValueCollections.value.substr(1, multiValueCollections.value.length - 1);
      // Soft add functionality
      let lastIndex = 0;
      let tempAttributesList = JSON.parse(JSON.stringify(attributesList));

      const validateDuplicateRule = (item) => {
        try{
          let valueExist = false;
          multiValueCollections['id'].split(',').forEach(x => {
            if(item['attrValueId'].split(",").includes(x)){
              valueExist = true;
            }
          });
          return (attributeSelected['id'] == item['attrId'] && valueExist) ? true : false;
          // return (attributeSelected['id'] == item['attrId'] && multiValueCollections['id'].split(',').sort().join(',') == item['attrValueId'].split(',').sort().join(',')) ? true : false;
        }catch(e){
          return false;
        }
      };

      if( !(attributeSelected.name && attributeSelected.id && operatorSelected.name && operatorSelected.id && multiValueCollections.value && multiValueCollections.id) ){
        if(!(attributeSelected.name && attributeSelected.id)){
          this.setState({attributeError: 'error'});
        }
        else{
          this.setState({attributeError: ''});
        }
        if(!(operatorSelected.name && operatorSelected.id)){
          this.setState({operatorError: 'error'});
        }
        else{
          this.setState({operatorError: ''});
        }
        if(!(multiValueCollections.value && multiValueCollections.id)){
          this.setState({valueError: 'error'});
        }
        else{
          this.setState({valueError: ''});
        }
        return false;
      }

      tempAttributesList.forEach((item, index) => {
        const id = parseInt(item.id.split('_||_')[0], 10);
        if(lastIndex < id){
          lastIndex = id;
        }
        if(validateDuplicateRule(item)){
          throw new Error(this.props.translator['Duplicate entities exists']);
        }
      });

      const obj = {
        attrName: attributeSelected.name || '',
        attrId: attributeSelected.id || '',
        operatorName: operatorSelected.name || '',
        operatorId: operatorSelected.id || '',
        attrValue: multiValueCollections.value || '',
        attrValueId: multiValueCollections.id || '',
        attrJoinCondition: this.state.isSaveClicked ? 'AND' : this.state.amsJoinCondition || '',
        id: `${lastIndex+1}_||_${attributeSelected.name || ''}_||_${multiValueCollections.value || ''}`
      };
      tempAttributesList.push(obj);
      updateAttributesList(tempAttributesList);

      this.props.updateIsChange(true);

      this.setState({
        isFormVisible: formVisible,
        attributeSelected: {},
        operatorSelected: {},
        valueSelected: {},
        editEnable: false,
        selectedConsumers: [],
        consumerDataDetails: [],
        typedRequestor: '',
        selectedValueList: [],
        valuesDropdownOptions: [],
        valueError: '',
        attributeError: '',
        operatorError: '',
        amsJoinCondition:(formVisible) ? this.state.amsJoinCondition : 'AND',
        isSaveClicked: true
      });
    }catch(e){
      console.error(e.message);
      this.setError(e.message);
      //alert(e || 'Error in saving rule!');
    }
  //  if(attributeSelected.name=="Class"&&this.state.amsJoinCondition=="AND")
  //  {
  //   this.isClassBasedCI(multiValueCollections.id)
  //  }
    // let postjson =
    // {
    //   "amsRuleId": ruleConfiguration.ruleId,
    //   "amsAttributeDisplayValue": attributeSelected.name,
    //   "amsAttributeDisplayId": attributeSelected.id,
    //   "amsOperator": operatorSelected.name,
    //   "amsOperatorId": operatorSelected.id,
    //   "createdBy": "",
    //   "createdByName": "",
    //   "amsAttributeValue": multiValueCollections.value,
    //   "amsAttributeValueId": multiValueCollections.id,
    //   "amsJoinCondition": "AND"
    // }

    // axios.post(GLOBAL.amsCriteriaPostUrl, postjson).then(function (response) {
    //   if (response.status == "200" || response.status == "201") {

    //     ref.setState({ isFormVisible: false });
    //     ref.props.getAMSAttributeList(ref.props.ruleConfiguration.ruleId);
    //   }
    // }).catch(error =>{
    //   console.error('Error in saving attribute: ', error);
    // });
  }

  getValuesDropdownOptions() {
    try {
      const { attributeSelected = {}, valuesDropdownOptions = [], consumerDataDetails = [] } = this.state;
      const { ciClass = [], slaModuleStatusValues = [], breakfixQuickViewFilters: { priority = [] } = { priority: [] } } = this.props;
      let arr = [];
      switch (attributeSelected.name) {
        case 'Service':
        case 'Impacted CI':
        case 'User.Location':
        case 'CI.Location':
        case 'Component':
          return valuesDropdownOptions;
        case 'Class':
          // return ciClass;
          ciClass.forEach(item => {
            let obj = {
              id: item.CLASS_ID,
              name: item.CLASS_NAME
            };
            arr.push(obj);
          });
          return arr;
        case 'Requestor':
          if (this.state.selectedConsumers == []) {
            return [];
          }
          else {
            return consumerDataDetails;
          }
        case 'Status':
          // return slaModuleStatusValues;
          slaModuleStatusValues.forEach(item => {
            let obj = {
              id: item.field1Value,
              name: item.field1Key
            };
            arr.push(obj);
          });
          return arr;
        case 'Priority':
          // return priority;
          (priority).forEach(item => {
            let obj = {
              id: item.field1Value,
              name: item.field1Key
            };
            arr.push(obj);
          });
          return arr;
        default:
          return [];
      }
    } catch (e) {
      return [];
    }
  }

  renderValueDropdown() {
    try {
      const { attributeSelected = {}, valueError = '', selectedValueList = [], editEnable = false } = this.state;
      // console.log('attributeSelected.name - ', attributeSelected.name);
      switch (attributeSelected.name) {
        // case 'Service':
        case 'Impacted CI':
        case 'Requestor':
        case 'User.Location':
        case 'CI.Location':
          return (
            <Form.Group className="attrvaldv form-group" validationState={valueError}>
              <Field
                component={TypeaheadExample}
                onSelection={this.onValueSelection}
                onInputChange={this.onValueChange}
                setErrorColor={this.setValueError}
                name="amsCriteriaValue"
                className="form-control"
                options={this.getValuesDropdownOptions()}
                selectedOptions={selectedValueList}
                errorClass={valueError === 'error' ? 'error' : ''}
              // editEnable={editEnable}
              >
              </Field>
            </Form.Group>
          );
        case 'Service':
          return(
            <Form.Group className="attrvaldv form-group" validationState={valueError}>
              <Field
                component={TypeaheadandDropdown}
                onSelection={this.onValueSelection}
                onInputChange={this.onValueChange}
                setErrorColor={this.setValueError}
                name="amsCriteriaValue"
                className="form-control"
                errorClass={valueError === 'error' ? 'error' : ''}
                options={this.getValuesDropdownOptions()}
                selectedOptions={selectedValueList}
                onCrossClick={() => this.setState({ valueError: '', selectedValueList: [], consumerDataDetails: [] }) }
                multiple={true}
              >
              </Field>
            </Form.Group>
          );
        case 'Class':
        case 'Status':
        case 'Priority':
        case 'Component':
          return (
            <Form.Group
              className="form-group"
              // validationState={valueError == ""
              //   ? GLOBAL.amsValueErrorColor
              //   : valueError}
              validationState={valueError}
            >
              <Form.Select
              className={valueError === 'error' ? 'myControl myInput error' : 'myControl myInput'}
              componentClass="select"
                onBlur={(event) => { this.validateValueField(event) }}
                onChange={(e) => { this.onValueSelection(e.target[e.target.selectedIndex]) }}
              >
                <option value="">Select</option>
                {this.generateOptions(this.getValuesDropdownOptions())}
              </Form.Select>
            </Form.Group>
          );
        default:
          return (
            <Form.Group
              className="form-group"
              validationState={valueError}
            >
              <Field
                name="attribute_option"
                className={valueError === 'error' ? 'form-control error' : 'form-control'}
                component={_dropDown}
                options={this.getValuesDropdownOptions()}
                onBlur={(event) => { this.validateValueField(event) }}
              >
              </Field>
            </Form.Group>
          );
      }
    } catch (e) {
      return null;
    }
  }

  setValueError() {
    const { selectedValueList = [] } = this.state;
    if (selectedValueList.length === 0)
      this.setState({ valueError: 'error' });
    else
      this.setState({ valueError: '' });
  }

  validateValueField(event) {
    if (event.target.value == 0 || event.target.value == "") {
      this.setState({ valueError: 'error' });
      GLOBAL.amsValueErrorColor = "";
    } else {
      this.setState({ valueError: '' });
      GLOBAL.amsValueErrorColor = "";
    }
  }

  onValueSelection(selected) {
    const { attributeSelected = {}, userCILocationInitial = [] } = this.state;
    switch (attributeSelected.name) {
      case 'Service':
      case 'Impacted CI':
      case 'Requestor':
      case 'User.Location':
      case 'CI.Location':
        this.setState({ valueError: '', selectedValueList: selected });
        if(!(Array.isArray(selected) && selected.length)){
          this.setState({valueError: 'error'});
        }
        else{
          this.setState({valueError: ''});
        }
        if( (attributeSelected.name === "User.Location" || attributeSelected.name === "CI.Location") && Array.isArray(selected) && selected.length == 0 ){
          this.setState({ valuesDropdownOptions: userCILocationInitial });
        }
        break;
      case 'Class':
      case 'Status':
      case 'Priority':
      case 'Component':
        let obj = {};
        obj.id = selected.value;
        obj.label = selected.text;
        if(!(obj.id && obj.label)){
          this.setState({valueError: 'error'});
        }
        else{
          this.setState({valueError: ''});
        }
        this.setState({ valueError: '', selectedValueList: [obj] });
        break;
      default:
    }
  }

  getFilteredAttributeList(){
    try{
      let arr =[];
      let lastJoinCondition = 'AND';
      let isSingleService = false;
      const { attributesList_ams = [], amsAttributeList_ams = [], attributesList = [] } = this.props;
      if(Array.isArray(attributesList) && attributesList.length){
        lastJoinCondition = attributesList[attributesList.length - 1]['attrJoinCondition'];
        let services = attributesList.filter(item => {
          return item['attrName'] === 'Service';
        });
        if(services.length == 1 && services[0]['attrValueId'].split(',').length == 1){
          isSingleService = true;
        }
      }
      if(lastJoinCondition == 'AND'){
        arr = attributesList_ams.filter(item1 => {
          let test = attributesList.filter(item2 => item2.attrName == item1.name);
          return !(test.length > 0);
        });
      }
      else{
        // attributesList.map(item => {
        //   let obj = { id: item.attrId, name: item.attrName };
        //   arr.push(obj);
        //   arr = arr.filter((value, index, self) =>
        //   index === self.findIndex((t) => (
        //   t.name === value.name
        //  )))
        // })
        let obj = attributesList[attributesList.length - 1];
        obj = { id: obj['attrId'], name: obj['attrName'] };
        arr.push(obj);
      }
      if(isSingleService == false){
        arr = arr.filter(item => {
          return item['name'] !== 'Component';
        });
      }
      return arr;
    }
    catch (e) {
      return [];
    }
  }

  deleteAttribute(id){
    try{
      // Soft delete functionality
      const { attributesList, updateAttributesList } = this.props;
      let arr = attributesList.filter(item => item.id != id);
      updateAttributesList(arr);
      this.props.updateIsChange(true);
    }catch(e){
      console.error('Error in deleting attribute!');
    }
    // this.props.deleteAMSAttribute(id).then((response) => {
    //   if (response.status == "200" || response.status == "201") {
    //     this.props.getAMSAttributeList(this.props.ruleConfiguration.ruleId);
    //   }
    // }).catch(error =>{
    //   console.error('Error in deleting attribute: ', error);
    // });
  }

  onValueChange(value){
    const { attributeSelected = {}, userCILocationInitial = [] } = this.state;
    if(attributeSelected.name === "User.Location" || attributeSelected.name === "CI.Location"){
      if(value == ''){
        this.setState({ valuesDropdownOptions: userCILocationInitial });
      }
      else if(value.length > 3){
        this.getLocation(value);
      }
    }else if(attributeSelected.name == 'Requestor'){
      this.onRequestorChange(value);
    }
  }
  ANDORClick(event, Condition)
  {
    this.setState({amsJoinCondition:Condition, isSaveClicked: false}, () => {
      this.saveAttribute(event, true);
    });
  }

  setError(msg = ''){
    try{
      this.setState({error: msg});
      setTimeout(() => {
        this.setState({error: ''});
      }, 5000);
    }catch(e){
      console.error(e);
    }
  }

  render() {
    const { error = '', valueError = '', isFormVisible, attributeError, operatorError, attributeSelected = {id: '', name: ''}, operatorSelected = {id: '', name: ''} } = this.state;
    const { loaders_ams, attributesList } = this.props;
    let filteredAttributeList = this.getFilteredAttributeList();

    if (loaders_ams) {
      return (
        <div className="borderForm bFormDiv">
          <ListLoader />
        </div>
      );
    }

    return (
      <div>
        <Row>
          <Col xs={12}>            
            <div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
              <ul>
                <li>
                {(isFormVisible === false) && (<Button
                  title={this.props.translator["Specify Attribute Values"]}
                  bsPrefix=" "
                  className="rPlusIcn"
                  size="xs"
                  variant="primary"
                  bsClass=''
                  onClick={() => {
                    this.setState({ isFormVisible: true })
                  }}
                >
                  <PiPlusSquareBold/>
                </Button>)}
                </li>
                <li className="d-md-none">
                <button
                    type='button'
                    title="Minimize the right panel"
                    bsClass=""
                    bsStyle=""
                    className="closerightPanelBtn"
                    onClick={() => {
                    this.props.rightEditPanel(false);
                    }}
                >
                    <IoClose/>
                </button>
                </li>
              </ul>
            </div>
            <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator["Specify Attribute Values"]}</div></div>
          </Col>
        </Row>


        {<div className="rBoxGap" style={{'minHeight': 'auto'}}>

          {(error) && <Alert variant="danger" className="errorMessageSec">
            <p>{error}</p>
          </Alert>}

          <Table responsive striped bordered hover className="tableWithOutPointer">
            <thead>
              <tr>
                <th width="15%">{this.props.translator["Attribute"]}</th>
                <th width="15%">{this.props.translator["Operator"]}</th>
                <th width="40%">{this.props.translator["Value"]}</th>
                <th width="15%">{this.props.translator["Join Condition"]}</th>
                {/* <th width="40%">{this.props.translator['Edit']}</th> */}
                <th width="15%" className="text-c">
                  {this.props.translator["Action"]}
                </th>
              </tr>
            </thead>
            {/* <tbody>{this.renderAttList(this.props.amsAttributeList_ams)}</tbody> */}
            <tbody>{this.renderAttList(attributesList)}</tbody>
          </Table>
        </div>}


        {(isFormVisible) && (<div className="rBoxGap">
          <div>
            <Form.Group className="form-group" validationState={attributeError}>
              <Form.Label bsClass="">
                <span className="rStar" />
                {this.props.translator["Attribute"]}
              </Form.Label>
              <Form.Select
              className={attributeError === 'error' ? 'myControl myInput error' : 'myControl myInput'}
              componentClass="select"
                onChange={(e) => {
                  this.setAttributeChange(e.target[e.target.selectedIndex]);
                }}
                value={(attributeSelected && attributeSelected['id']) ? attributeSelected['id'] : ''}

              >
                <option value="">select..</option>
                {this.generateOptions(filteredAttributeList)}
              </Form.Select>
            </Form.Group>
            <Form.Group className="form-group" validationState={operatorError}>
              <Form.Label bsClass="">
                <span className="rStar" />
                {this.props.translator["Operator"]}
              </Form.Label>
              <Form.Select
                className={operatorError === 'error' ? 'myControl myInput error' : 'myControl myInput'}
                componentClass="select"
                onChange={(e) => {
                  this.setOperatorChange(e.target[e.target.selectedIndex]);
                }}
                value={(operatorSelected && operatorSelected['id']) ? operatorSelected['id'] : ''}
              >
                <option value="">select..</option>
                {this.generateOptions(this.props.operatorsList_ams)}
              </Form.Select>
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label bsClass="">
                <span className="rStar" />
                {this.props.translator["Value"]}
              </Form.Label>
              {this.renderValueDropdown()}
            </Form.Group>

          </div>

            <ButtonToolbar className="notificationcss">
                    <Button
                      bsSize="small"  className={(this.state.amsJoinCondition == "AND") ? "rgSidrkBtn" : "rgSiLigBtn"} variant="primary"  onClick={(event) => {
                        this.ANDORClick(event, "AND")
                      }} >AND</Button>
                    <Button
                        bsSize="small" variant="primary" className={(this.state.amsJoinCondition == "OR") ? "rgSidrkBtn" : "rgSiLigBtn"} onClick={(event) => {
                          this.ANDORClick(event, "OR")
                        }}>OR</Button></ButtonToolbar>
          <div style={{"clear":"both"}}>
          <ButtonToolbar >

              <Button
                className="rgSidrkBtn"
                variant="primary"
                onClick={(event) => {
                  this.saveAttribute(event)
                }}
              >
                {this.props.translator["Save"]}
              </Button>
              <Button
                className="rgSiLigBtn"
                variant="primary"
                onClick={() => {
                  this.setState({
                    isFormVisible: false,
                    attributeError:'',
                    operatorError: '',
                    valueError: '',
                    amsJoinCondition:'AND',
                    isSaveClicked: true,
                    attributeSelected: {},
                    operatorSelected: {},
                    valueSelected: {},
                    editEnable: false,
                    selectedConsumers: [],
                    consumerDataDetails: [],
                    typedRequestor: '',
                    selectedValueList: [],
                    valuesDropdownOptions: []
                  });
                }}
              >
                {this.props.translator["Cancel"]}
              </Button>
            </ButtonToolbar>
          </div>

        </div>
        )}
      </div>
    );
  }
}
PopUpAttribute = reduxForm({
  form: "popUpAttribute",


})(PopUpAttribute);

let selector = formValueSelector("popUpAttribute");
PopUpAttribute = connect()(PopUpAttribute);

export function mapStateToProps({
  slaModuleStatusValues,
  ruleConfiguration,
  attributesList_ams,
  operatorsList_ams,
  amsAttributeList_ams,
  loaders_ams,
  breakfixQuickViewFilters,
  ciUserBasedLocation,
  ciClass,
  isClassExist_list
}) {
  return {
    slaModuleStatusValues,
    ruleConfiguration,
    attributesList_ams,
    operatorsList_ams,
    amsAttributeList_ams,
    loaders_ams,
    breakfixQuickViewFilters,
    ciUserBasedLocation,
    ciClass,
    isClassExist_list
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadamsModuleStatus,
      getAMSAttributeList,
      getAttributesList,
      getOperatorsList,
      deleteAMSAttribute,
      fetchPriorityList,
      loadCIUserBasedLocation,
      loadCIClass
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PopUpAttribute);
