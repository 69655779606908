
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Nav from 'react-bootstrap/Nav';

class LeftSidebarMenulist extends React.Component {
   render() {
      return (
		<div className="leftNav">
			<Nav as="ul" className="leftMenu">
				<Nav.Item as="li"  eventKey={1}><a href={void(0)} title={this.props.translator['Details']}><span className="bgBtn9 lSidebar brkf1"></span></a></Nav.Item>
			</Nav>
		</div>

      );
   }
}


export default LeftSidebarMenulist;
