
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export const cmdbQuickItemList = (state = initialState.cmdbItemList, action) => {
	switch (action.type) {
		case 'LOAD_CMDB_ITEM_LIST_SUCCESS':
			return action.cmdbItemList.data;
		default:
			return state;
	}
}
export const cmdbQuickTechnicalItemList = (state = initialState.cmdbItemList, action) => {
	switch (action.type) {
		case 'T_Owner':
			return action.data;
		default:
			return state;
	}
}
export const cmdbQuickBusinessItemList = (state = initialState.cmdbItemList, action) => {
	switch (action.type) {
		case 'B_Owner':
			return action.data;
		default:
			return state;
	}
}

export const statusList = (state = initialState.statusList, action) => {
	switch (action.type) {
		case 'LOAD_STATUSOPTONS_SUCCESS':
			return action.statusList.data;
		default:
			return state;
	}
}

export const subStatusList = (state = initialState.subStatusList, action) => {
	switch (action.type) {
		case 'LOAD_SUBSTATUSOPTONS_SUCCESS':
			return action.subStatusList.data;
		default:
			return state;
	}
}