
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const dropdownConnector = (e) => {
    e.DomComponents.addType(dropdownConnectorJson.id, {
        model: {
            defaults: {
                tagName: "div",
                draggable: true,
                content: `<div class="row">
                    <div class="col-md-12">
                        <input type="text" placeholder="Please choose" class="form-control"/>
                    </div>
                </div>`,
                traits: [
                    {
                        type: "text",
                        label: "Name",
                        name: "name",
                        placeholder: "Enter Name",
                        changeProp: 1,
                    },
                    {
                        type: "select",
                        name: "data_source",
                        label: "Data Source",
                        options: [],
                        changeProp: 1,
                    },
                    {
                        type: "select",
                        label: "Label Key",
                        name: "labelKey",
                        options: [],
                        changeProp: 1,
                    },
                    {
                        type: "select",
                        label: "Value Key",
                        name: "valueKey",
                        options: [],
                        changeProp: 1,
                    },
                    {
                        type: "checkbox",
                        label: "Required",
                        name: "required",
                        changeProp: 1,
                    },
                    {
                        type: "checkbox",
                        label: "Multiple",
                        name: "multiSelect",
                        changeProp: 1,
                    },
                    {
                        type: "checkbox",
                        label: "Disabled",
                        name: "disabled",
                        changeProp: 1,
                    },
                    {
                        type: "text",
                        label: "Populate Data On Change",
                        name: "populateData",
                        changeProp: 1,
                    },
                    {
                        type: "text",
                        label: "Label Key Mapping",
                        name: "labelKeyMapping",
                        changeProp: 1,
                    },
                    {
                      type: "text",
                      label: "Disabled Key",
                      name: "disabledKey",
                      changeProp: 1,
                    }
                ],
                editable: true,
            },
        },
    });
}

export const dropdownConnectorJson = {
    id: "dropdownConnector",
    label: "Dropdown with Connector",
    attributes: { class: "fa fa-th" },
    category: "Basic",
    media: `<svg
        viewBox="0 0 24 24"
        fill="currentColor"
    >
        <path d="M17 5h3l-1.5 2L17 5M3 2h18a2 2 0 012 2v4c0 1.11-.89 2-2 2h-5v10c0 1.11-.89 2-2 2H3a2 2 0 01-2-2V4c0-1.1.9-2 2-2m0 2v4h11V4H3m18 4V4h-5v4h5M3 20h11V10H3v10m2-8h7v2H5v-2m0 4h7v2H5v-2z" />
    </svg>`,
    content: {
      type: "dropdownConnector",
    }
};
