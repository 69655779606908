
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';
export const contractList = (state = [], action) => {
    if (action.type === 'CONTRACT_LIST_FETCHED_SUCCESSFULLY') {
      return action.payload;
    } else {
      return state;
    }
};

export const contractEditList = (state = initialState.contractEditList, action) => {
  if (action.type === 'CONTRACT_EDIT_DETAILS_FETCHED') {
    return action.payload;
  } else {
    return state;
  }
};
export const contractEditLoader = (state = { isLoading: false }, action) => {
  if (action.type === 'CONTRACT_DATA_FETCHING') {
    return { isLoading: true};
  } 
  if (action.type === 'CONTRACT_DATA_FETCHED_SUCCESS') {
    return { isLoading: false};
  }
  else {
    return state;
  }
};

export const budgetList = (state = [], action) => {
  if (action.type === 'BUDGET_LIST_FETCHED_SUCCESSFULLY') {
    return action.payload;
  } else {
    return state;
  }
};

export const administratorList = (state = [], action) => {
  if (action.type === 'ADMINISTRATOR_LIST_FETCHED_SUCCESSFULLY') {
    return action.payload;
  } else {
    return state;
  }
};

export const contractPlanList = (state = {}, action)=> {
	switch (action.type){
		case 'LOAD_CONTRACT_SUCCESS':
			return action.contractList;
			default:
			return state;
	}
}

export const contractAuditData = (state = {loading: false, data: []}, action) => {
  switch (action.type) {
    case "CONTRACT_AUDIT_DATA_FETCHING":
      return {loading: true, data: []};
    case "CONTRACT_AUDIT_DATA_FETCHED":
      return {loading: false, data: action.payload};
    case "CONTRACT_AUDIT_DATA_FETCHED_FAILED":
      return {loading: false, data: action.payload};
    default:
      return state;
  }
};

export const contractRelationshipData = (state = {contractData: []}, action) => {
  switch (action.type) {
      case 'RELATIONSHIP_DATA_LOAD_SUCCESS':
        return Object.assign({}, state, {
          contractData: action.contractData
          });
      default:
      return state;
  }
};

export const contractWorkDetails = (state = [], action) => {

	switch (action.type){
		case 'LOAD_CONTRACT_RELATED_WORK_DETAILS_SUCCESS':
			return action.contractRelatedWorkDetails.data;
		case 'LOAD_CONTRACT_RELATED_WORK_DETAILS_FAILURE':
			return action.failureMessage; 
      default:
			return state;
		}

}

export const administratorGroupList = (state = [],action) => {
  switch (action.type) {
    case 'ADMINISTRATOR_GROUP_LIST':
      return action.getadmingroup;
    case 'EMPTY_ADMINISTRATOR_GROUP_LIST':
      return [];
    default:
      return state;
  }
};

export const resetVendor = (state = [], action) => {
  if (action.type == 'RESET_VENDOR') {
    return action.payload;
  } else if (action.type == 'RESET_CONTRACT_DETAILS') {
    return [];
  }
  else {
    return state;
  }
}

export const resetCostCenter = (state = [], action) => {
  if (action.type == 'RESET_COST_CENTER') {
    return action.payload;
  } else if (action.type == 'RESET_CONTRACT_DETAILS') {
    return [];
  }
  else {
    return state;
  }
}

export const resetAdminList = (state = [], action) => {
  if (action.type == 'RESET_CONTRACT_GROUP') {
    return action.payload;
  } else if (action.type == 'RESET_CONTRACT_DETAILS') {
    return [];
  }
  else {
    return state;
  }
}