
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import {Row, Col, Table, Button, Alert , ButtonToolbar} from 'react-bootstrap';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import axios from 'axios';
import { _textArea, _dropDown, _inputField} from '../../common/formFields';
import { spcmLoader,setLeftEditId,getServiceDetailCounts,getAttributes } from '../../../actions/spcmActions';
import {ImSpinner6} from "react-icons/im";


const validate = (values,props) => {
	const errors={};
	if(!values.attribute_name || values.attribute_name.trim().length === 0)
		errors.attribute_name="Required";
	if(!values.attribute_value || values.attribute_value.trim().length === 0)
		errors.attribute_value="Required";
	props.extraCounts.forEach(val=>{
		if(!values["attribute_name"+val.toString()] || values["attribute_name"+val.toString()].trim().length === 0)
			errors["attribute_name"+val.toString()]="Required";
		if(!values["attribute_value"+val.toString()] || values["attribute_value"+val.toString()].trim().length === 0)
			errors["attribute_value"+val.toString()]="Required";
	})
	return errors;

}

class ComponentForm extends React.Component{
	constructor(props){
	super(props);
		this.state = {
			arrayCounts:1,
			extraCounts:[0]
		};
		this.loadCount = this.loadCount.bind(this);
		this.addRow = this.addRow.bind(this);
		this.deleteRow = this.deleteRow.bind(this);
		this.componentWillMount = this.componentWillMount.bind(this);
		this.submitForm = this.submitForm.bind(this);
	};

	addRow(){
		this.setState({ arrayCounts: this.state.arrayCounts+1 });
		let arrayvar = this.state.extraCounts.slice();
		arrayvar.push(this.state.arrayCounts);
		this.setState({ extraCounts: arrayvar });
		this.props.setExtraCount(arrayvar);
	};
	deleteRow(myIndex){
		let arrayvar = this.state.extraCounts;
		let index = arrayvar.indexOf(myIndex);
		arrayvar.splice(index,1);
		this.setState({extraCounts: arrayvar });
		this.props.setExtraCount(arrayvar);
	};

	componentWillMount(){
		this.props.setExtraCount([0]);
	}
	
	componentWillUnmount(){
		this.props.spcmLoader('attribute','success');
	}

	submitForm(values){
		let temp = values;

		Object.keys(temp).forEach(val=>{
			if(val[10]=='v'){
				if(val.substring(15)&&this.state.extraCounts.indexOf(parseInt(val.substring(15),10)) < 0){
					delete(values['attribute_name'+val.substring(15)]);
					delete(values['attribute_value'+val.substring(15)]);
				}
				
			}

			
		})
		if(this.props.spcmReducer.editLeftId<=0) {
			let count = Object.keys(values).length/2;
			for (let i = 0; i < count; i++) {
				if(values["attribute_name" + i].trim() === "" || values["attribute_value" + i].trim() === "" ) return ;
			}
		}
		 if(this.props.spcmReducer.editLeftId>0){
					//return axios.patch(constants.SERVER+constants.OFG_ATTRIBUTES_API_URL+"/"+this.props.spcmReducer.editLeftId,values).then((response) => {
					this.props.spcmLoader('attribute','posting');
					return axios.patch("/service/api/patchAttribute/"+this.props.spcmReducer.editLeftId,values).then((response) => {
						this.props.getAttributes(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
						this.props.componentFormFun4(false);
						this.props.spcmLoader('attribute','success');
					})
					.catch((error) => {
						
						this.props.spcmLoader('attribute','success');
						throw new SubmissionError({_error: error.response.data[0].message });
					});
				}
				else{
					var name=[];
					var value=[];
					var final=[];
					Object.keys(values).forEach(function(key){
						if(key[10]=='v'){
							key.substring(15)?value[key.substring(15)]=values[key]:value[0]=values[key];
							final.push({attribute_name:name[key.substring(15)],attribute_value:value[key.substring(15)],offering_id:this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID})
						}
						else if(key[10]=='n'&&key[0]=='a'){
							key.substring(14)?name[key.substring(14)]=values[key]:name[0]=values[key];
						}


					},this)
					//return axios.post(constants.SERVER+constants.OFG_ATTRIBUTES_API_URL,final).then((response) => {
					this.props.spcmLoader('attribute','posting');
								return axios.post("/service/api/saveAttribute",final).then((response) => {
						this.props.getAttributes(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
						this.props.componentFormFun4(false);
						this.props.getServiceDetailCounts(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
						this.props.spcmLoader('attribute','success');
					})
					.catch((error) => {
					this.props.spcmLoader('attribute','success');
					throw new SubmissionError({_error: error.response.data[0].message });

					});
				}
	}

	loadCount(){
		const rowlength = this.state.extraCounts.length;
		return this.state.extraCounts.map((extraCount,i)=>{

			if(rowlength === i + 1 && this.props.spcmReducer.editLeftId && this.props.spcmReducer.editLeftId>0){
				return <tr><td><Field component={_inputField} name={"attribute_name"} className='form-control' /></td><td><Field component={_inputField} name={"attribute_value"} className='form-control'/></td></tr>;
			}else if(rowlength === i + 1){
				return <tr><td><Field component={_inputField} name={"attribute_name"+extraCount} className='form-control' /></td><td><Field component={_inputField} name={"attribute_value"+extraCount} className='form-control'/></td><td><Button title="Add" onClick={()=>{this.addRow()}}><i className="fa fa-plus"></i></Button></td></tr>;
			}
			else{
				return <tr key={i}><td><Field component={_inputField} name={"attribute_name"+extraCount} className='form-control'/></td><td><Field component={_inputField} name={"attribute_value"+extraCount} className='form-control'/></td><td><Button title="Delete" onClick={()=>{this.deleteRow(extraCount)}}><i className="fa fa-trash"></i></Button></td></tr>;
			}
		});
	}
	render(){
		const tr=this.props.tr;
		const {error,handleSubmit} = this.props;
		return(
			<div className="rBoxGap">
				<form  onSubmit={handleSubmit(this.submitForm)}>
				  {error == undefined || error == null ? null :  <Alert variant="danger" className="errorMessageSec"><p>{error}</p></Alert>}
				<Table>
					<thead>
						<tr>
							<th width="43%">{tr['Attribute']}</th>
							<th width="43%">{tr['Value']}</th>
							<th width="14%">{tr['Action']}</th>
						</tr>
					</thead>
					<tbody>
					{this.loadCount()}
					</tbody>
				</Table>
				<Row>
						<Col md={12}>
							
							<ButtonToolbar className="black"><Button className='rgSidrkBtn' title="Save" disabled={this.props.spcmReducer.spcmPostingAttribute} type="submit" bsSize="small" bsStyle="primary">{this.props.spcmReducer.spcmPostingAttribute ? <ImSpinner6 className="icn-spinner"/> : null } {tr['Save']}</Button>
							<Button className='rgSiLigBtn' title="Close" onClick={()=>{this.props.componentFormFun4(false)}} bsSize="small" bsStyle="text">{tr['Close']}</Button></ButtonToolbar>
						</Col>

						
					</Row>
				</form>
			</div>
		);
	}
}
const mapStateToProps = ({spcmReducer}) => {
	return {
		spcmReducer,
		initialValues:spcmReducer.editLeftId>0?spcmReducer.editLeftService:''
	}
};
ComponentForm=reduxForm({
	form:'attributesForm',
	validate,
	enableReinitialize : true
})(ComponentForm)

export default connect(mapStateToProps,{spcmLoader,setLeftEditId,getServiceDetailCounts,getAttributes})(ComponentForm);
