
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from "react-bootstrap/Button";
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import {
  loadSlaMilestoneAction,
  loadSlaMilestoneTarget,
  loadSlaMilestoneList,
} from "_Actions/sla/lovAction";
import { connect } from "react-redux";
import axios from "axios";
import { bindActionCreators } from "redux";
import { GLOBAL } from "_Globals";
import LoadingIcons from "react-loading-icons";
import { Field, reduxForm, change } from "redux-form";
import {HiPlus} from "react-icons/hi";
import {
  TypeaheadExample,
  TypeaheadandDropdown,
} from "../../common/formFields";
import CloseBtnRightPanel from "../../common/closeBtnRightPanel";
const Joi = require("joi");
class PopUpMilestone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      MilestoneEventTypeError: "",
      slaRecipientsError: "",
      milestoneEventTypeValue: "5",
      milestoneEventTypeName: "Breach",
      formToggle: "",
      formaddToggle: true,
      ThresholdError: "",
      recipientsList: [],
      selectedRecipients: "",
      isEmail: false,
      emailError: "",
      EditTypeFromRecord: [],
      ThresholdFromRecord: [],
      disableButtons: false,
      emailUsers: [],
      typedEmail: "",
      selectedEmailRecipents: [],
      isSelectGroup: false,
      selectGroupError: "",
      groupList: [],
      typedGroup: "",
      selectedGroupList: [],
    };
    this.saveAtt = this.saveAtt.bind(this);
    this.onRecipientSelection = this.onRecipientSelection.bind(this);
    this.setErrorRecipentColor = this.setErrorRecipentColor.bind(this);
    this.onEmailSelect = this.onEmailSelect.bind(this);
    this.setEmailError = this.setEmailError.bind(this);
    this.onCrossClickEmail = this.onCrossClickEmail.bind(this);
    this.onEmailInputChange = this.onEmailInputChange.bind(this);
    this.onselectGroup = this.onselectGroup.bind(this);
    this.setselectGroupError = this.setselectGroupError.bind(this);
    this.onCrossClickGroup = this.onCrossClickGroup.bind(this);
    this.onselectGroupInputChange = this.onselectGroupInputChange.bind(this);
  }
  onEmailInputChange(value) {
    this.setState({
      typedEmail: value,
      selectedEmailRecipents: [],
      emailError: "",
      emailUsers: [],
    });
    if (value.length > 3) {
      let consumerDataDetails = [],
        consumerData = [],
        strObj = {},
        myObj = {};
      strObj.userId = "";
      strObj.companyId = this.props.slaDefEditDetails.companyId;
      strObj.like = value;
      strObj = JSON.stringify(strObj);
      axios
        .get(GLOBAL.consumerUsersNewUrl, { headers: { query: strObj } })
        .then((response) => {
          consumerData = response.data;
          if (Array.isArray(response.data) && this.state.typedEmail !== "") {
            for (let i = 0; i < consumerData.length; i++) {
              myObj = {};
              myObj.id = consumerData[i].locationId + "";
              myObj.label = consumerData[i].email;
              consumerDataDetails.push(myObj);
            }
            consumerDataDetails = Array.from(new Set(consumerDataDetails));
          } else {
            consumerDataDetails = [];
          }
          this.setState({
            emailUsers: consumerDataDetails,
          });
        });
    }
  }
  onCrossClickEmail() {
    this.setState({
      selectedEmailRecipents: [],
      emailError: "",
      typedEmail: "",
      emailUsers: [],
    });
  }
  onEmailSelect(selectedEmail) {
    if (selectedEmail.length > 0) {
      this.setState({ selectedEmailRecipents: selectedEmail });
    } else {
      this.setState({ selectedEmailRecipents: [] });
    }
  }
  setEmailError() {
    if (this.state.selectedEmailRecipents.length === 0) {
      this.setState({ emailError: "error" });
    } else {
      this.setState({ emailError: "" });
    }
  }

  formToggleFun(value) {
    this.setState({
      formToggle: value,
      formaddToggle: false,
      MilestoneEventTypeError: "",
      slaRecipientsError: "",
      milestoneEventTypeValue: "5",
      milestoneEventTypeName: "Breach",
      ThresholdError: "",
      recipientsList: [],
      selectedRecipients: "",
      isEmail: false,
      emailError: "",
      disableButtons: false,
      emailUsers: [],
      selectedEmailRecipents: [],
      typedEmail: "",
      isSelectGroup: false,
      selectGroupError: "",
      typedGroup: "",
      selectedGroupList: [],
    });
    this.props.dispatch(change("PopUpMilestone", "slaMilestoneEventType", ""));
    this.props.dispatch(change("PopUpMilestone", "slaMilestoneThreshhold", ""));
  }

  componentWillMount() {
    this.props.loadSlaMilestoneList(this.props.slaDefEditDetails.slaId);
    this.props.loadSlaMilestoneTarget("SLADEF", "MilestoneTarget");
    axios
      .get(
        "/asset-config/api/getCiSupportAllGroup/id/" +
          this.props.slaDefEditDetails.companyId
      )
      .then((recivedGroups) => {
        let retrivedGroups = [];
        recivedGroups.data.map((group) => {
          retrivedGroups.push({ id: group.groupId, label: group.groupName, companyId: (group?.companyId || ''), companyName: (group?.companyName || '') });
        });
        this.setState({ groupList: retrivedGroups });
      })
      .catch((error) => {});
  }
  componentWillUnmount() {
    this.props.dispatch({ type: "RESET_MILESTONE_LIST" });
  }

  getTargetValue(type, value) {
    try {
      let typeTemp = type.split("_")[1].toLowerCase();
      if (typeTemp === "milestone") {
        return value;
      } else if (typeTemp === "breach") {
        return "Breach";
      }
    } catch (e) {
      return "";
    }
  }

  renderMilestoneList(milestoneData) {
    if (
      !milestoneData ||
      typeof milestoneData === "string" ||
      milestoneData.length === 0 ||
      milestoneData.length === undefined
    ) {
      return null;
    }
    return milestoneData.map((objjectData) => {
      if (objjectData.slaId != null && objjectData.slaId != "")
        return (
          <tr>
            <td>{objjectData.eventType}</td>
            <td>
              {this.getTargetValue(
                objjectData.eventType,
                objjectData.threshold
              )}
            </td>
            <td>{objjectData.audienceRoleName}</td>
            {objjectData.audienceRoleName.includes("Select Group") ? (
              <td>{objjectData.groupName}</td>
            ) : (
              <td></td>
            )}
            <td className="text-c f-size-13">
              <i
                onClick={() => {
                  this.attDelete(objjectData.milestoneId, objjectData.slaId);
                }}
                className="fa fa-trash-o cursorPoint"
              ></i>
            </td>
          </tr>
        );
      else return null;
    });
  }

  validateField(fieldType, event) {
    switch (fieldType) {
      case "slaMilestoneThreshhold":
        event.target.value === ""
          ? this.setState({ ThresholdError: "error" })
          : this.setState({ ThresholdError: "" });
        break;
      default:
    }
  }

  saveAtt(values) {
    let count = 0;
    if (
      this.state.milestoneEventTypeValue === "10" &&
      (!values.slaMilestoneThreshhold || values.slaMilestoneThreshhold === "")
    ) {
      count++, (this.state.ThresholdError = "error");
    }
    if (this.state.selectedRecipients.length === 0) {
      count++, (this.state.slaRecipientsError = "error");
    }
    if (
      this.state.isEmail === true &&
      this.state.selectedEmailRecipents.length === 0
    ) {
      count++, (this.state.emailError = "error");
    } else if (
      this.state.isEmail === true &&
      this.state.selectedEmailRecipents.length !== 0
    ) {
      let namedSchema = Joi.string().regex(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/);
      let validator_to_add_myQbj = namedSchema.validate(
        this.state.selectedEmailRecipents[0].label
      );
      if (validator_to_add_myQbj.error != null) {
        count++, (this.state.emailError = "error");
      }
    }
    if (this.state.isSelectGroup && this.state.selectedGroupList.length === 0) {
      count++;
      this.setState({ selectGroupError: "error" });
    }

    if (count !== 0) {
      this.setState({ slaRecipientsError: this.state.slaRecipientsError });
    } else {
      let selectedUsers = { id: "", values: "" },
        ref = this;
      this.state.selectedRecipients.forEach((item) => {
        selectedUsers.id += "," + item.id;
        selectedUsers.values += "," + item.label;
      });
      (selectedUsers.id = selectedUsers.id.substr(
        1,
        selectedUsers.id.length - 1
      )),
        (selectedUsers.values = selectedUsers.values.substr(
          1,
          selectedUsers.values.length - 1
        ));

      let jsonPayload = {
        slaId: this.props.slaDefEditDetails.slaId,
        threshold:
          this.state.milestoneEventTypeValue === "10"
            ? values.slaMilestoneThreshhold
            : 0,

        actionId: "5",
        actionName: "Notify",

        eventType:
          this.props.slaDefEditDetails.moduleName +
          "_" +
          this.state.milestoneEventTypeName,

        audienceRoleId: selectedUsers.id,
        audienceRoleName: selectedUsers.values,

        audienceSpecifyValue: this.state.isEmail
          ? this.state.selectedEmailRecipents[0].label
          : "",
        groupName: this.state.isSelectGroup
          ? this.state.selectedGroupList[0].label
          : "",
        groupId: this.state.isSelectGroup
          ? this.state.selectedGroupList[0].id
          : "",
        createdBy: "",
        createdByName: "",
      };
      this.setState({ disableButtons: true });
      axios
        .post(GLOBAL.slaMilestonePostUrl, jsonPayload)
        .then(function (response) {
          ref.props.loadSlaMilestoneList(ref.props.slaDefEditDetails.slaId);
          ref.formToggleFun(false);
          ref.setState({formaddToggle : true});
        })
        .catch((err) => {
          this.setState({ disableButtons: false });
        });
    }
  }

  attDelete(attid, slaId) {
    let ref = this;
    axios.delete(GLOBAL.slaMilestoneDeleteUrl + attid).then((response) => {
      ref.setState({ formToggle: false });
      ref.props.loadSlaMilestoneList(slaId);
    });
  }

  onRecipientSelection(selectedUsers) {
    let ref = this,
      i = 0,
      flag = false,
      isIndividualSelected = 0,
      isGroupSelected = 0;
    for (; i < selectedUsers.length; i++) {
      if (
        selectedUsers[i].label === "Specify Individual" &&
        selectedUsers[i].id === "20"
      ) {
        flag = true;
        isIndividualSelected++;
        this.setState({ isEmail: true });
      } else if (
        selectedUsers[i].label === "Select Group" &&
        selectedUsers[i].id === "15"
      ) {
        flag = true;
        isGroupSelected++;
        this.setState({ isSelectGroup: true });
      }
    }
    if (
      (flag === false && ref.state.isEmail === true) ||
      isIndividualSelected === 0
    )
      this.setState({ isEmail: false });
    if (
      (flag === false && ref.state.isSelectGroup === true) ||
      isGroupSelected === 0
    )
      this.setState({ isSelectGroup: false });
    if (this.state.slaRecipientsError === "error")
      this.setState({
        slaRecipientsError: "",
        selectedRecipients: selectedUsers,
      });
    else this.setState({ selectedRecipients: selectedUsers });
  }

  setErrorRecipentColor() {
    if (this.state.selectedRecipients.length === 0)
      this.setState({ slaRecipientsError: "error" });
    else this.setState({ slaRecipientsError: "" });
  }

  renderRecipentsListOnResponse() {
    if (
      !this.props.slaMilestoneTargetList ||
      typeof this.props.slaMilestoneTargetList === "string" ||
      this.props.slaMilestoneTargetList.length === 0 ||
      this.props.slaMilestoneTargetList.length === undefined
    ) {
      return null;
    }
    this.props.slaMilestoneTargetList.map((item) => {
      this.state.recipientsList.push({
        label: item.field1Key,
        id: item.field1Value,
      });
    });
    this.setState({ recipientsList: this.state.recipientsList });
  }

  renderEventType() {
    if (this.props.slaMilestoneList.length === 0) {
      return <div></div>;
    } else if (this.props.slaMilestoneList.length > 0) {
      return null;
    }
  }
  onselectGroup(selectGroup) {
    this.setState({ selectedGroupList: selectGroup });
  }
  onCrossClickGroup() {
    this.setState({ typedGroup: "", selectedGroupList: [] });
  }
  onselectGroupInputChange(groupValue) {
    this.setState({ typedGroup: groupValue, selectedGroupList: [] });
  }
  setselectGroupError() {
    if (this.state.selectedGroupList.length === 0) {
      this.setState({ selectGroupError: "error" });
    } else {
      this.setState({ selectGroupError: "" });
    }
  }

  render() {
    // console.log('******************', {state: this.state, props: this.props});

    if (
      typeof this.props.slaUniqueEventTypeFromRecord["Breach"] !== "undefined"
    )
      (this.state.milestoneEventTypeValue = "10"),
        (this.state.milestoneEventTypeName = "Milestone");
    let { handleSubmit } = this.props;
    if (
      this.props.isFetchingDetails ||
      this.props.isFetchingTimeline ||
      this.props.isCausalRelationshipData ||
      this.props.slaMilestoneTargetList.length === 0 ||
      typeof this.props.slaMilestoneTargetList.length === "undefined"
    ) {
      return (
        <LoadingIcons.Bars height="5em" stroke="transparent" fill="#06bcee" />
      );
    } else
      return (
        <div>
          <CloseBtnRightPanel showRightSide={this.props.rightEditPanel} />
        <div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
          <ul>
            <li>
            <Button
              disabled={
                this.state.disableButtons ||
                (typeof this.props.slaUniqueEventTypeFromRecord["Breach"] !==
                  "undefined" &&
                  typeof this.props.slaUniqueEventTypeFromRecord[
                    "Milestone"
                  ] !== "undefined" &&
                  typeof this.props.slaUniqueMilestoneFromRecord["50"] !==
                    "undefined" &&
                  typeof this.props.slaUniqueMilestoneFromRecord["75"] !==
                    "undefined")
              }
              title={this.props.translator["Specify Milestone Values"]}
              onClick={() => {
                this.formToggleFun(true);
              }}
              bsPrefix=" "
              bsClass=""
              bsStyle=""
             >
            <HiPlus />
          </Button>
            </li>
          </ul>
        </div>
        
        <div className="rPageHeading">
          <div className="offNam margin-t-5 margin-b-5">          
          {this.props.translator["Specify Milestone Values"]}
          </div>
        </div>

        <div className="rBoxGap">
        {this.state.formaddToggle ? (
          <div>
            <Table
              responsive
              striped
              bordered
              condensed
              hover
              className="tableWithOutPointer f-size-13"
            >
              <thead>
                <tr>
                  <th>{this.props.translator["Event Type"]}</th>
                  <th>{this.props.translator["Target"]}</th>
                  <th>{this.props.translator["Recipients"]}</th>
                  <th>{this.props.translator["Group Name"]}</th>
                  <th className="text-c">{this.props.translator["Action"]}</th>
                </tr>
              </thead>
              <tbody>
                {this.renderMilestoneList(this.props.slaMilestoneList)}
              </tbody>
            </Table>
          </div>) : null}
          {this.state.formToggle ? (
            <div>
              <Table className="slaAttrTable">
                <tbody>
                  <tr>
                    <td>
                      <Form.Label bsClass="">
                        <span className="rStar"></span>
                        {this.props.translator["Event Type"]}
                      </Form.Label>
                    </td>
                    <td>
                      <Form.Group
                        className="margin-b-0"
                      >
                        <Field
                          name="slaMilestoneEventType"
                          className={this.state.MilestoneEventTypeError === 'error' ? 'form-control error' : 'form-control'}
                          component="select"
                          onBlur={(event) => {
                            this.validateField("slaMilestoneEventType", event);
                          }}
                          onChange={(event) => {
                            this.setState({
                              milestoneEventTypeValue: event.target.value,
                              milestoneEventTypeName:
                                event.target[event.target.selectedIndex].text,
                            });
                          }}
                        >
                          {this.props.slaMilestoneList.length === 0 ? (
                            <option value="5">Breach</option>
                          ) : typeof this.props.slaUniqueEventTypeFromRecord[
                              "Breach"
                            ] === "undefined" ? (
                            <option value="5">Breach</option>
                          ) : (
                            ""
                          )}
                          {this.props.slaMilestoneList.length === 0 ? (
                            <option value="10">Milestone</option>
                          ) : typeof this.props.slaUniqueMilestoneFromRecord[
                              "50"
                            ] === "undefined" ||
                            typeof this.props.slaUniqueMilestoneFromRecord[
                              "75"
                            ] === "undefined" ? (
                            <option value="10">Milestone</option>
                          ) : (
                            ""
                          )}
                        </Field>
                      </Form.Group>
                    </td>
                  </tr>
                  {this.state.milestoneEventTypeValue === "10" ? (
                    <tr>
                      <td>
                        <Form.Label bsClass="">
                          <span className="rStar"></span>
                          {this.props.translator["Threshold"]}
                        </Form.Label>
                      </td>
                      <td>
                        <Form.Group
                          className="margin-b-0"
                        >
                          <Field
                            name="slaMilestoneThreshhold"
                            className={this.state.ThresholdError === 'error' ? 'form-control error' : 'form-control'}
                            component="select"
                            onBlur={(event) => {
                              this.validateField(
                                "slaMilestoneThreshhold",
                                event
                              );
                            }}
                            onChange={(event) => {
                              this.props.dispatch(
                                change(
                                  "PopUpMilestone",
                                  "slaMilestoneThreshhold",
                                  event.target[event.target.selectedIndex].text
                                )
                              );
                            }}
                          >
                            <option value="">select..</option>
                            {this.props.slaMilestoneList.length === 0 ? (
                              <option value="50">50</option>
                            ) : typeof this.props.slaUniqueMilestoneFromRecord[
                                "50"
                              ] === "undefined" ? (
                              <option value="50">50</option>
                            ) : (
                              ""
                            )}
                            {this.props.slaMilestoneList.length === 0 ? (
                              <option value="75">75</option>
                            ) : typeof this.props.slaUniqueMilestoneFromRecord[
                                "75"
                              ] === "undefined" ? (
                              <option value="75">75</option>
                            ) : (
                              ""
                            )}
                          </Field>
                        </Form.Group>
                      </td>
                    </tr>
                  ) : null}
                  <tr>
                    <td>
                      <Form.Label bsClass="">
                        <span className="rStar"></span>
                        {this.props.translator["Recipients"]}
                      </Form.Label>
                    </td>
                    <td>
                      {this.state.recipientsList.length === 0
                        ? this.renderRecipentsListOnResponse()
                        : null}
                      <Form.Group
                        className="margin-b-0"
                      >
                        <div className="attrvaldv">
                        <Field
                          name="slaRecipients"
                          className="form-control"
                          errorClass={this.state.slaRecipientsError === 'error' ? 'error' : ''}
                          component={TypeaheadExample}
                          onSelection={this.onRecipientSelection}
                          setErrorColor={this.setErrorRecipentColor}
                          options={this.state.recipientsList}
                          selectedOptions={this.state.selectedRecipients}
                        ></Field>
                        </div>
                      </Form.Group>
                    </td>
                  </tr>
                  {this.state.isEmail ? (
                    <tr>
                      <td>
                        <Form.Label bsClass="">
                          <span className="rStar"></span>
                          {this.props.translator["E-Mail"]}
                        </Form.Label>
                      </td>
                      <td>
                        <Form.Group
                          className="margin-b-0"
                        >
                          <div className="attrvaldv">
                          <Field
                            name="slaSpecifyUserEmail"
                            className="form-control"
                            component={TypeaheadandDropdown}
                            errorClass={this.state.emailError === 'error' ? 'error' : ''}
                            onSelection={this.onEmailSelect}
                            setErrorColor={this.setEmailError}
                            options={this.state.emailUsers}
                            selectedOptions={this.state.selectedEmailRecipents}
                            diableStatus={false}
                            onCrossClick={this.onCrossClickEmail}
                            onInputChange={this.onEmailInputChange}
                            typedValue={this.state.typedEmail}
                          ></Field>
                          </div>
                        </Form.Group>
                      </td>
                    </tr>
                  ) : null}
                  {this.state.isSelectGroup ? (
                    <tr>
                      <td>
                        <Form.Label bsClass="">
                          <span className="rStar"></span>
                          {this.props.translator["Select Group"]}
                        </Form.Label>
                      </td>
                      <td>
                        <Form.Group
                          className="margin-b-0"
                        >
                          <div className="attrvaldv">
                          <Field
                            name="selectGroup"
                            className="form-control"
                            errorClass={this.state.selectGroupError === 'error' ? 'error' : ''}
                            component={TypeaheadandDropdown}
                            onSelection={this.onselectGroup}
                            setErrorColor={this.setselectGroupError}
                            options={this.state.groupList}
                            selectedOptions={this.state.selectedGroupList}
                            diableStatus={false}
                            onCrossClick={this.onCrossClickGroup}
                            onInputChange={this.onselectGroupInputChange}
                            typedValue={this.state.typedGroup}
                          />
                          </div>
                        </Form.Group>
                      </td>
                    </tr>
                  ) : null}
                  <tr>
                    <td colSpan={2}>
                      <ButtonGroup bsPrefix=" " className="btn-toolbar">
                        <Button
                          onClick={handleSubmit(this.saveAtt)}
                          disabled={this.state.disableButtons}
                          bsSize="xsmall"
                          className="rgSidrkBtn smallBtn"
                        >
                          {this.props.translator["Save"]}
                        </Button>
                        <Button
                          onClick={() => {
                            this.formToggleFun(false);
                            this.setState({formaddToggle : true});
                          }}
                          disabled={this.state.disableButtons}                      
                          bsSize="xsmall"
                          className="rgSiLigBtn smallBtn"
                           >
                          {this.props.translator["Cancel"]}
                        </Button>
                      </ButtonGroup>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          ) : null}
        </div>
      </div>
      );
  }
}

PopUpMilestone = reduxForm({
  form: "PopUpMilestone",
})(PopUpMilestone);

export function mapStateToProps({
  slaMilestoneTargetList,
  slaMilestoneList,
  slaUniqueMilestoneFromRecord,
  slaUniqueEventTypeFromRecord,
  isFetchingDetails,
  isFetchingTimeline,
  isCausalRelationshipData,
}) {
  return {
    slaMilestoneTargetList,
    slaMilestoneList,
    slaUniqueMilestoneFromRecord,
    slaUniqueEventTypeFromRecord,
    isFetchingDetails: isFetchingDetails.isFetching,
    isFetchingTimeline: isFetchingTimeline.isFetching,
    isCausalRelationshipData: isCausalRelationshipData.causalRelationData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { loadSlaMilestoneAction, loadSlaMilestoneTarget, loadSlaMilestoneList },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PopUpMilestone);
