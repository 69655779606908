
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { PageHeader, FormGroup,  } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';

import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadOrdersList, resetMRQVFilters, resetSortingArrows } from '_Actions/serviceDeskActions/myOrdersAction';

import Cookies from 'universal-cookie';

const cookies = new Cookies();
let homepagelocation = cookies.get('gph');
if (homepagelocation) homepagelocation = homepagelocation.replace('s:', '');
if (homepagelocation) homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];

class ServiceDeskHeader extends React.Component {
	constructor(props) {
		super(props);
		this.state = {}
	}



	render() {
		return (
			<Row className="margin-b-15">
				<Col lg={2} md={2} sm={12} xs={12}>

					<div bsPrefix=' '  className="sPageHeading1">{this.props.tr['Service Desk']} </div>
				</Col>
				<Col lg={6} md={6} sm={12} xs={12}>

				</Col>
				<Col lg={4} md={4} sm={12} xs={12} >
					<div className="status myStatus marginT10 margin-t-10">
						<Nav bsPrefix=' '>
							<LinkContainer to={homepagelocation}>
								<Nav.Item title={this.props.tr['Close']} eventKey={2} ><span className="sAction bgBtn17 bclose"></span></Nav.Item>
							</LinkContainer>
						</Nav>
					</div>
				</Col>
			</Row>
		);
	}
}

export function mapStateToProps({ ordersList }) {
	//  console.log(ordersList)
	let data = ordersList.data;
	if (data == undefined)
		data = [];
	return { ordersList: data, ordersListMeta: ordersList.meta };
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ loadOrdersList, resetMRQVFilters, resetSortingArrows }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceDeskHeader);

// export default XsmSupportHeader;
