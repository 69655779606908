
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export default function deliverytypeListReducer(state = initialState.deliverytypeList, action){
	switch (action.type){
		case 'LOAD_DELIVERY_TYPE_SUCCESS':
			return action.deliverytypeList.data;
			default:
			return state;
	}
}
