
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
// import { Row, Col, PageHeader,Button} from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadIntegrationHubList } from '../../actions/integrationHub/integrationHubAction.js';
import {HiPlus, HiOutlineRefresh} from "react-icons/hi";

class XsmQuickViewDetailtHeader extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
        };
		this.refreshIntegrationHubTable = this.refreshIntegrationHubTable.bind(this);
	}
	refreshIntegrationHubTable() {
        this.props.loadIntegrationHubList();
    }
	render() {
		return (
			<Row className="margin-b-15">
			<Col lg={4} md={6} sm={6} xs={12}>
				<h2 bsClass="" className="sPageHeading1">{this.props.translator['Outbound Connections']}</h2>
			</Col>
			<Col lg={8} md={6} sm={6} xs={12} className="text-r">
				<div className="paHedFilter">
					<Link role="button" className="myBt plus fillBtn" title={this.props.translator['Create New Outbound Connection']} to="/createOutboundConnection" ><HiPlus/></Link>
					<Button title={this.props.translator["Refresh"]} role="button" bsPrefix=' '  className="myBt" onClick={() => { this.refreshIntegrationHubTable(); }}><HiOutlineRefresh className="refreshFlip"/></Button>
				</div>
			</Col>
		</Row>			
		);
	}
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({ loadIntegrationHubList }, dispatch);
}


export default connect('', mapDispatchToProps)(XsmQuickViewDetailtHeader);
