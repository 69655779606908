
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import {
  Scheduler,
  DayView,
  WeekView,
  MonthView,
  TimelineView,
  DateHeaderCell,
} from "@progress/kendo-react-scheduler";
import { useCallback } from "react";
import { useState, useMemo } from "react";
import { timezoneNames } from "@progress/kendo-date-math";
import "@progress/kendo-theme-default/dist/all.css";
import { useDispatch, useSelector } from "react-redux";
import { onCallGroupUsersByGroupId } from "../../../actions/onCallSchedule/common/onCallCommonAction";
import { loadEventList } from "../../../actions/onCallSchedule/calendar/onCallCalendarAction";
import { getDateAndTime } from "../common/onCallUtils";
import { useEffect } from "react";
//
import Cookies from "universal-cookie";
const cookies = new Cookies();
let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

let ctimezone = cookies.get("gph");
if (ctimezone) ctimezone = ctimezone.replace("s:", "");
if (ctimezone) ctimezone = ctimezone.substring(0, ctimezone.lastIndexOf("."));
ctimezone = ctimezone.split("~");
ctimezone = ctimezone[3];
import "@progress/kendo-date-math/tz/all";
import moment from "moment";
import { Button, ButtonToolbar } from "react-bootstrap";

const customModelFields = {
  id: "TaskID",
  title: "Title",
  description: "Description",
  start: "Start",
  end: "End",
  recurrenceRule: "RecurrenceRule",
  recurrenceId: "RecurrenceID",
  recurrenceExceptions: "RecurrenceException",
};
const currentYear = new Date().getFullYear();
const parseAdjust = (eventDate) => {
  const date = new Date(eventDate);
  date.setFullYear(currentYear);
  return date;
};
const randomInt = (min, max) =>
  Math.floor(Math.random() * (max - min + 1)) + min;
const displayDate = new Date();

const CustomCalendar = (props) => {
  const dispatch = useDispatch();
  const timezones = useMemo(() => timezoneNames(), []);
  const [timezone, setTimezone] = useState(ctimezone);
  const userList = useSelector((state) => state.onCallGroupUsersList);
  const eventList = useSelector((state) => state.onCallCalendarEventList);
  const [events, setEvents] = useState([]);
  const [resources, setResources] = useState([]);
  const [panelColSize, setPanelColSize] = useState(100);
  const [mainView, setMainView] = useState("resourceView");
  const root = document.documentElement;
  const locales = [
    {
      language: "en-US",
      locale: "en",
    },
    {
      language: "es-ES",
      locale: "es",
    },
  ];
  const [view, setView] = useState("timeline");
  const [date, setDate] = useState(displayDate);
  const [orientation, setOrientation] = useState("vertical");
  const [data, setData] = useState([]);

  const handleViewChange = useCallback((event) => {
    let startEndDate = getStartEndDate(event?.target?.props?.date, event.value);
    callApi(startEndDate?.startDate, startEndDate?.endDate);
    setView(event.value);
  }, []);

  useEffect(() => {
    if (mainView == "calendarView") {
      setView("day");
      setDate(displayDate);
      callApi(
        moment(displayDate).format("MM-DD-YYYY"),
        moment(displayDate).format("MM-DD-YYYY"),
        "calendarView"
      );
    } else {
      setView("timeline");
      setDate(displayDate);
      callApi(
        moment(displayDate).format("MM-DD-YYYY"),
        moment(displayDate).format("MM-DD-YYYY"),
        "resourceView"
      );
    }
  }, [mainView]);

  const callApi = (startDate, endDate, mainViewParam = "") => {
    let params = {
      page: 0,
      size: 50000,
      eventStartTimestamp: `${startDate},${endDate}`,
      // eventEndTimestamp:moment().format("MM-DD-YYYY")
    };
    if (mainViewParam == "resourceView" || mainView == "resourceView") {
      params["userId"] = props.users.join(",");
    }
    dispatch(loadEventList(params));
  };

  const handleDateChange = useCallback(
    (event, view) => {
      let startEndDate = getStartEndDate(event.value, view);
      setDate(event.value);
      callApi(startEndDate?.startDate, startEndDate?.endDate);
    },
    [setDate]
  );

  useEffect(() => {
    setPanelColSize(props.panelResize);
  }, [props.panelResize]);

  useEffect(() => {
    if (props.selectedGroupList && props.selectedGroupList.length > 0) {
      dispatch(onCallGroupUsersByGroupId(props.selectedGroupList[0].id));
    } else {
      dispatch({
        type: "ON_CALL_CALENDER_EVENT_DATA_LIST",
        events: [],
      });
    }
  }, [props.selectedGroupList]);

  /// resource updation
  useEffect(() => {
    if (mainView == "resourceView") {
      if (userList && userList.length > 0) {
        let userListFields = [];
        userList.forEach((list) => {
          userListFields.push(list.field1Value);
        });
        props.setUsers(userListFields);
        dispatch(
          loadEventList({
            page: 0,
            size: 50000,
            userId: userListFields.join(","),
            eventStartTimestamp: `${moment().format(
              "MM-DD-YYYY"
            )},${moment().format("MM-DD-YYYY")}`,
            // eventEndTimestamp:moment().format("MM-DD-YYYY")
          })
        );
        const users = userList.map((user) => {
          return {
            value: user.field1Value.toString(),
            text: user.field1Key,
            color: "#dfecfa",
          };
        });
        setResources(users);
      }
    }
  }, [userList]);

  //// event updation
  useEffect(() => {
    if (
      eventList &&
      Object.keys(eventList).length > 0 &&
      eventList?.data?.length > 0
    ) {
      let newEvents = eventList?.data.map((event) => {
        return {
          TaskID: event.eventId,
          OwnerID: event.eventId,
          Title:
            event.available == "Yes" ? event.shiftName : event.scheduleType,
          Description: "",
          StartTimezone: null,
          Start: parseAdjust(getDateAndTime(event.eventStartTimestamp, jwtdf)),
          End: parseAdjust(getDateAndTime(event.eventEndTimestamp, jwtdf)),
          isAllDay: false,
          EndTimezone: null,
          RecurrenceRule: null,
          RecurrenceID: null,
          RecurrenceException: null,
          PersonIDs: event.userId.toString(),
        };
      });
      setEvents(newEvents);
    } else {
      setEvents([]);
    }
  }, [eventList]);

  const checkTimeSlot = (view) => {
    if (view == "week") {
      root?.style.setProperty("--timeSlotDisplay", "none");
    } else {
      root?.style.setProperty("--timeSlotDisplay", "block");
    }
  };

  const getStartEndDate = (dateParam, view) => {
    let startDate;
    let endDate;
    checkTimeSlot(view);
    if (view === "timeline") {
      startDate = moment(dateParam).format("MM-DD-YYYY");
      endDate = moment(dateParam).format("MM-DD-YYYY");
    } else if (view === "week") {
      startDate = moment(dateParam).startOf("week").format("MM-DD-YYYY");
      endDate = moment(dateParam).endOf("week").format("MM-DD-YYYY");
    } else if (view === "month") {
      startDate = moment(dateParam).startOf("month").format("MM-DD-YYYY");
      endDate = moment(dateParam).endOf("month").format("MM-DD-YYYY");
    }
    return { startDate, endDate };
  };

  const handleOrientationChange = useCallback((event) => {
    setOrientation(event.target.getAttribute("data-orientation"));
  }, []);
  const handleDataChange = useCallback(
    ({ created, updated, deleted }) => {
      setData((old) =>
        old
          .filter(
            (item) =>
              deleted.find((current) => current.TaskID === item.TaskID) ===
              undefined
          )
          .map(
            (item) =>
              updated.find((current) => current.TaskID === item.TaskID) || item
          )
          .concat(
            created.map((item) =>
              Object.assign({}, item, {
                TaskID: "",
              })
            )
          )
      );
    },
    [setData]
  );
  return (
    <div>
      <ButtonToolbar className="m-b-10 btn-toolbar-calendar">
        <Button onClick={() => setMainView("calendarView")}>
          Calendar View
        </Button>
        <Button onClick={() => setMainView("resourceView")}>
          Resource View
        </Button>
      </ButtonToolbar>
      {mainView == "calendarView" && (
        <Scheduler
          data={events}
          onDataChange={handleDataChange}
          view={view}
          onViewChange={handleViewChange}
          date={date}
          onDateChange={(e) => handleDateChange(e, view)}
          editable={false}
          timezone={timezone}
          modelFields={customModelFields}
          footer={(props) => {
            return <React.Fragment />;
          }}
        >
          <DayView
            defaultShowWorkHours={false}
            showWorkHours={false}
            slotDuration={60}
          />
          <WeekView
            allDaySlot={false}
            slotDuration={60}
            currentTimeMarker={false}
            dateHeaderCell={(props) => <DateHeaderCell {...props} format="d" />}
            defaultShowWorkHours={false}
            showWorkHours={false}
          />
          <MonthView defaultShowWorkHours={false} showWorkHours={false} />
        </Scheduler>
      )}

      {mainView == "resourceView" && (
        <Scheduler
          data={events}
          onDataChange={handleDataChange}
          view={view}
          onViewChange={handleViewChange}
          date={date}
          onDateChange={(e) => handleDateChange(e, view)}
          editable={false}
          timezone={timezone}
          modelFields={customModelFields}
          group={
            view != "month"
              ? {
                  resources: ["Persons"],
                  orientation,
                }
              : {}
          }
          resources={
            view != "month"
              ? [
                  {
                    name: "Persons",
                    data: resources,
                    field: "PersonIDs",
                    valueField: "value",
                    textField: "text",
                    colorField: "color",
                  },
                ]
              : ""
          }
          footer={(props) => {
            return <React.Fragment />;
          }}
        >
          <TimelineView showWorkHours={false} title={"Day"} />
          <WeekView
            slotDivisions={1}
            allDaySlot={false}
            slotDuration={240}
            currentTimeMarker={false}
            dateHeaderCell={(props) => <DateHeaderCell {...props} format="d" />}
            defaultShowWorkHours={false}
            showWorkHours={false}
          />
          {/* <MonthView defaultShowWorkHours={false} showWorkHours={false} /> */}
        </Scheduler>
      )}
    </div>
  );
};

export default CustomCalendar;
