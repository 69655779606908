
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import Spinner from 'react-spinkit';
import LeftSidebarMenulist from './serviceDeskOrdersFilter.js';
import ServiceDeskHeader from './serviceDeskHeader.js';
import XsmBreakFixFormTab from './serviceDeskLeftSide.js';
import ServiceDeskRightSide from './serviceDeskRightSide.js';
import ServiceDeskBreakfixRightSide from './serviceDeskBreakfixRightSide.js';
import BreakFixCopy from './CopyIncidentService'
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
//import XsmBreakFixAction1 from './rightSideWithNoRender.js'

import { connect } from 'react-redux';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { loadOrdersList } from '_Actions/serviceDeskActions/myOrdersAction';
import { getTranslation } from '_Actions/spcmActions';

import { GLOBAL } from '_Globals';

import { browserHistory } from 'react-router';
import Cookies from 'universal-cookie';
import "_Css/common/_table.scss";
import "_Css/form/_form.scss";
const cookies = new Cookies();
let homepagelocation = cookies.get('gph');
if (homepagelocation) homepagelocation = homepagelocation.replace('s:', '');
if (homepagelocation) homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home")
    homepagelocation = "/" + homepagelocation;

//let user_id=userDetails.user_id;

//let company_id=userDetails.company_id;
class ServiceDeskClass extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subMenu: '4',
            mobileLeftMenuFilter: false,
            showHideAlfy: false,
            showHideRightNav: false,
            quickViewOrderId: '',
            ExternalRequestNumber: '',
            filterValue: '',
            moduleName: '',
            itemIdValue: '',
            orderStatus: '',
            listFilter: '',
            asc_desc: 'desc',
            searchclick: true,
            rowIndex: 0,
            check_status: '',
            showCopyServiceForm: false,
            showRightSideBar: false,
            panelResize: "",
        };
        GLOBAL.searchbarcategory = 'All';
        this.showHideAlfy = this.showHideAlfy.bind(this);
        this.showHideRightNav = this.showHideRightNav.bind(this);
        this.UpdateExternalRequestNumber = this.UpdateExternalRequestNumber.bind(this);
        this.getOrderDetails = this.getOrderDetails.bind(this);
        //this.filterOrder = this.filterOrder.bind(this);
        this.getOrderType = this.getOrderType.bind(this);
        this.getItemId = this.getItemId.bind(this);
        this.getOrderStatus = this.getOrderStatus.bind(this);
        this.buildSearchParamsForOrderFilter = this.buildSearchParamsForOrderFilter.bind(this);
        this.ordersListFilter = this.ordersListFilter.bind(this);
        this.ordersListFilter2 = this.ordersListFilter2.bind(this);
        this.set_asc_desc = this.set_asc_desc.bind(this);
        this.showActiveRow = this.showActiveRow.bind(this);
        this.setShowCopyServiceForm = this.setShowCopyServiceForm.bind(this);
        this.loadHome = this.loadHome.bind(this);

        //this.props.loadOrdersList("requesterId","type",user_id,"createdOn","desc");
    };

    loadHome() {
        this.props.navigate(homepagelocation);
    }

    showActiveRow(i, statusVal) {
        if (" i & status", i, statusVal);
        this.setState({
            rowIndex: i,
            check_status: statusVal
        });
    }
    UpdateExternalRequestNumber(value) {
        console.log("::::::::::::::::::::", value);
        this.setState({ ExternalRequestNumber: value });
    }
    mobileLeftMenuFilter() {
        this.setState({ mobileLeftMenuFilter: !this.state.mobileLeftMenuFilter });
    };

    showHideAlfy(value) {
        this.setState({ showHideAlfy: value });
    };
    showHideRightNav(value) {
        this.setState({ showHideRightNav: !this.state.showHideRightNav });
    };

    getOrderStatus(orderStatus) {
        this.setState({
            orderStatus: orderStatus
        });
    }
    getOrderDetails(orderID) {
        this.setState({
            quickViewOrderId: orderID
        });
        //console.log("this.state.quickViewOrderId"+this.state.quickViewOrderId);
    }

    getItemId(itemId) {
        this.setState({
            itemIdValue: itemId
        });
        //console.log("this.state.itemIdValue:::"+this.state.itemIdValue)
        //alert("this.state.itemIdValue:::"+this.state.itemIdValue)
    }


    getOrderType(moduleName) {
        //alert("moduleName"+moduleName);
        this.setState({
            moduleValue: moduleName
        });

        //console.log("this.state.moduleValue:::"+this.state.moduleValue)
    }

    ordersListFilter() {
        //alert("searchclick::"+this.state.searchclick)
        this.setState({ searchclick: false });
    }

    ordersListFilter2() {
        this.setState({ searchclick: true });
    }

    set_asc_desc(asc_desc) {
        this.setState({ asc_desc: asc_desc });
    }

    //orderFilterAction
    buildSearchParamsForOrderFilter(params) {
        let searchParams = Object.assign({}, params);
        //alert("buildSearchParamsForInvestigationQV")

        let selectedStatus = this.props.myOrderViewFilters.selectedStatus;
        // Investigation:
        //   Open - 'Pending','Submitted','In Progress'
        //   Closed – 'Fixed','Fullfiled','Corrected'
        this.setRightSideBar(false);
        let statusMap = new Map();
        statusMap.set('Open', ['20', '10', '15', '65', '70', '-20', '100']);
        statusMap.set('Closed', ['25', '75', '80', '30', '95']);
        statusMap.set('Cancelled', ['85', '35']);

        //console.log(selectedStatus);
        if (!_.isEmpty(selectedStatus)) {

            let selectedMappedStatus = selectedStatus.map((item) => { return statusMap.get(item) }).join();
            if (!_.isEmpty(searchParams.searchByList)) {
                searchParams.searchByList = searchParams.searchByList + ',' + 'itemStatus';
                // searchParams.multipleValueList = searchParams.multipleValueList+'|'+selectedStatus.join();
                searchParams.multipleValueList = searchParams.multipleValueList + '|' + "" + '|' + selectedMappedStatus;
                // searchParams.operatorList="or,and";
            }
            else {
                searchParams.searchByList = 'itemStatus';
                searchParams.multipleValueList = selectedMappedStatus;


            }
        }
        //alert(this.props.myOrderViewFilters.selectedOrderType);
        let selectedOrderType = this.props.myOrderViewFilters.selectedOrderType;
        // Investigation:
        //   Service Orders - 'All','Service Orders','Correction Orders'
        //   Closed – 'Fixed','Fullfiled','Corrected'

        let statusOrderMap = new Map();
        let statusOrderMap1 = new Map();
        statusOrderMap.set('Service Orders', ['Item']);
        statusOrderMap.set('Correction Orders', ['Breakfix']);


        //console.log(selectedStatus);
        if (!_.isEmpty(selectedOrderType)) {

            let selectedMappedStatus = selectedOrderType.map((item) => { return statusOrderMap.get(item) }).join();
            if (!_.isEmpty(searchParams.searchByList)) {
                searchParams.searchByList = searchParams.searchByList + ',' + 'type';
                // searchParams.multipleValueList = searchParams.multipleValueList+'|'+selectedStatus.join();
                searchParams.multipleValueList = searchParams.multipleValueList + '|' + selectedMappedStatus;
                // searchParams.operatorList="or,and";
            }
            else {
                searchParams.searchByList = 'type';
                searchParams.multipleValueList = selectedMappedStatus;

            }
        }
        return searchParams;
    }

    setShowCopyServiceForm(flag) {
        this.setState({ showCopyServiceForm: flag })
    }

    setRightSideBar(value) {
        this.setState({ showRightSideBar: value })
    }
    render() {

        const { showRightSideBar } = this.state;
        const colToShow = showRightSideBar ? 8 : 12;

        return (
            <div>

                {/* App Right Side MenuBar Section Start*/}
                {/* <RightSidebarMenulist showhideClass={this.state.showHideRightNav} pageId={'24'} /> */}
                {/* App Right Side MenuBar Section End*/}

                {/* <div onClick={this.mobileLeftMenuFilter.bind(this)} id="mobileLeftMenuFilter" className={this.state.mobileLeftMenuFilter ? 'mobileLeftMenuFilterMove d-md-none d-lg-none' : 'd-md-none d-lg-none'}><i className={this.state.mobileLeftMenuFilter ? 'fa fa-angle-double-left' : 'fa fa-angle-double-right'}></i></div> */}

                {/* Support App Left Side MenuBar Section Start*/}
                {/* <LeftSidebarMenulist showActiveRow={this.showActiveRow} ordersListFilter={this.ordersListFilter} listFilter={this.state.listFilter} searchclick={this.state.searchclick} tr={this.props.tr} newMobileLeftMenuFilter={this.state.mobileLeftMenuFilter} filterOrder={this.filterOrder} buildSearchParamsForOrderFilter={this.buildSearchParamsForOrderFilter} setShowCopyServiceForm={this.setShowCopyServiceForm}/> */}
                {/* Support App Left Side MenuBar Section End*/}

                <Container fluid className='padding-t-10 margin-b-15'>
                    <nav aria-label="Breadcrumb">
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href={void (0)} role="button" onClick={this.loadHome}>
                                    {this.props.tr["Home"]}
                                </a>
                            </li>
                            <li className="breadcrumb-item active">
                                {this.props.tr["Service Desk"]}
                            </li>
                        </ul>
                    </nav>
                </Container>

                <div className="container-fluid midMargins" bsPrefix=''>
                    {/* Home Page blue bar Section Start */}
                    <ServiceDeskHeader ordersListFilter={this.ordersListFilter} listFilter={this.state.listFilter} searchclick={this.state.searchclick} tr={this.props.tr} />
                    {/* Home Page blue bar Section end  */}
                    <PanelGroup direction="horizontal" className='formGroupB10 myProfileLabel myOrders'>
                        <Panel id="sidebar" minSize={33} order={1} defaultSize={showRightSideBar ? 67 : 100} onResize={(size) => this.setState({ panelResize : size})} className={showRightSideBar ? "isShowLeftPanel rwLeftPart" : "rwLeftPart"}>
                            {/* Xsm Break Fix Form tabs Section Start*/}
                            <XsmBreakFixFormTab
                                ordersListFilter={this.ordersListFilter} newMobileLeftMenuFilter={this.state.mobileLeftMenuFilter} filterOrder={this.filterOrder}
                                setRightSideBar={(value) => { this.setRightSideBar(value); }} set_asc_desc={this.set_asc_desc} ExternalRequestNumber={this.state.ExternalRequestNumber} UpdateExternalRequestNumber={this.UpdateExternalRequestNumber} ordersListFilter2={this.ordersListFilter2} listFilter={this.state.listFilter} check_status={this.state.check_status} tr={this.props.tr} getOrderStatus={this.getOrderStatus} getItemId={this.getItemId} rowIndex={this.state.rowIndex} showActiveRow={this.showActiveRow} filterValue={this.state.filterValue} getOrderDetails={this.getOrderDetails} getOrderType={this.getOrderType} searchclick={this.state.searchclick} buildSearchParamsForOrderFilter={this.buildSearchParamsForOrderFilter} setShowCopyServiceForm={this.setShowCopyServiceForm} itemIdValue={this.state.itemIdValue}
                                panelResize={this.state.panelResize} />
                        </Panel>
                        {showRightSideBar ?
                            <>
                                <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
                                <Panel minSize={33} order={2} defaultSize={33}>
                                    <div className="stickyArea rBoxStyle" style={{ marginTop: "33px" }}>
                                        {/* Xsm BreakFix Action Section Start*/}
                                        {/* {this.state.moduleValue=="Breakfix" ? <XsmBreakFixAction0 tr={this.props.tr} orderStatus={this.state.orderStatus} itemId={this.state.itemIdValue} quickViewOrderId={this.state.quickViewOrderId} module={this.state.moduleValue}/>:<XsmBreakFixAction tr={this.props.tr} orderStatus={this.state.orderStatus} itemId={this.state.itemIdValue} quickViewOrderId={this.state.quickViewOrderId} module={this.state.moduleValue}/>} */}
                                        {this.state.showCopyServiceForm ? <BreakFixCopy translator={this.props.tr} setShowCopyServiceForm={this.setShowCopyServiceForm} serviceDetails={this.state.serviceDetails} quickViewOrderId={this.state.quickViewOrderId} /> : this.props.showLoader == false ? this.props.ordersList.length > 0 ? this.state.moduleValue == "Breakfix" ? <ServiceDeskBreakfixRightSide showActiveRow={this.showActiveRow} setRightSideBar={(value) => { this.setRightSideBar(value); }} tr={this.props.tr} ExternalRequestNumber={this.state.ExternalRequestNumber} orderStatus={this.state.orderStatus} itemId={this.state.itemIdValue} quickViewOrderId={this.state.quickViewOrderId} module={this.state.moduleValue} setShowCopyServiceForm={this.setShowCopyServiceForm} /> : <ServiceDeskRightSide showActiveRow={this.showActiveRow} setRightSideBar={(value) => { this.setRightSideBar(value); }} ExternalRequestNumber={this.state.ExternalRequestNumber} UpdateExternalRequestNumber={this.UpdateExternalRequestNumber} listFilter={this.state.listFilter} tr={this.props.tr} orderStatus={this.state.orderStatus} itemId={this.state.itemIdValue} quickViewOrderId={this.state.quickViewOrderId} module={this.state.moduleValue} /> : '' : ''}
                                        {/* Xsm BreakFix Action Section End*/}
                                    </div>
                                </Panel>
                            </> : ""
                        }
                    </PanelGroup>
                </div>
            </div>
        );
    }
}

export function mapStateToProps(state) {
    //alert(breakFixServiceWithoutDomain);
    let data = state.ordersList.data;
    if (data == undefined)
        data = [];
    return {
        // quickViewDetails: state.quickViewDetails ,
        // fulFillmentList: state.fulFillmentList,
        myOrderViewFilters: state.myOrderViewFilters,
        ordersList: data,
        lang: state.spcmReducer.lang,
        tr: state.spcmReducer.tr,
        showLoader: state.showLoader.loading
    };
}



export default connect(mapStateToProps, { getTranslation })(ServiceDeskClass);




