
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import { chunk } from "lodash";
import * as React from "react";
import { Col, Row } from "react-bootstrap";

const GridLayout = ({ cols, children, className }) => {
  const colWidth = 12 / cols;
  const rows = chunk(React.Children.toArray(children), cols);
  return (
    <>
      {rows.map((cols, index) => (
        <Row key={index} className={className}>
          {cols.map((col, idx) => (
            <Col sm={12} md={colWidth} key={idx}>
              {col}
            </Col>
          ))}
        </Row>
      ))}
    </>
  );
};
export default GridLayout;
