
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Cookies from 'universal-cookie';
import { navigationHooks } from '../../helpers/NavigationHook';

const cookies = new Cookies();

let homepagelocation = cookies.get('gph');
if(homepagelocation) homepagelocation = homepagelocation.replace('s:','');
if(homepagelocation) homepagelocation = homepagelocation.substring(0,homepagelocation.lastIndexOf('.'));
homepagelocation=homepagelocation.split("~");
homepagelocation=homepagelocation[0];

class ReportConsoleHeader extends React.Component {
	constructor(props) {
		super(props);

		this.showHome = this.showHome.bind(this);
	}
  showHome(){
    console.log("direction to home:nothing to save");
    navigationHooks.navigate(homepagelocation);
  }
   render() {
      return (
		<Row className="margin-b-15">
			<Col md={7} sm={7}>
				<h2 bsClass="" className="sPageHeading1">{this.props.translator['Reports']}</h2>
			</Col>
		</Row>
      );
   }
}


export default ReportConsoleHeader;

