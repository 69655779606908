
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import moment from "moment";
import Cookies from "universal-cookie";
import { formatQuery, defaultValueProcessor, formatQueryField, formatQueryValue } from "react-querybuilder";
import sanitizeHtml from 'sanitize-html';

export const allowedFields = ['input','label','textarea','button','select','custom-dropdown','typeaheadComponent','table','uploadComponent','checkbox','custom-checkbox','custom-radio', 'dropdownConnector','myBreadCrumb','titleComponent','RepeatativeFieldsComponent','form','pageContainer'];

export function getCookiesValuesByPositions(positions) {
  const cookies = new Cookies();
  let cookieValue = cookies.get("gph");

  if (cookieValue) {
    cookieValue = cookieValue.replace("s:", "");
    cookieValue = cookieValue.substring(0, cookieValue.lastIndexOf("."));
    const values = cookieValue.split("~");

    const result = {};
    positions.forEach((position, index) => {
      result[`value${index + 1}`] = values[position];
    });

    return result;
  } else {
    return null; // or handle the case when the cookie is not found
  }
}


export const commonSorting = (a, b, dataField, order = 'asc') => {
  let aValue = '';
  let bValue = '';

  if (dataField === 'company') {
      aValue = a?.original[dataField]?.companyName || '';
      bValue = b?.original[dataField]?.companyName || '';
  } else if (dataField === 'organization') {
      aValue = a?.original[dataField]?.orgName || '';
      bValue = b?.original[dataField]?.orgName || '';
  } else {
      aValue = a?.original[dataField] || '';
      bValue = b?.original[dataField] || '';
  }

  const comparison = aValue?.localeCompare(bValue, navigator.languages[0] || navigator.language, {
      numeric: true,
      ignorePunctuation: true,
  });

  return order === 'asc' ? comparison : -comparison;
};


export const getComponents = (components) => {
  let result = [];
  components.forEach((component) => {
    result.push(component);
    if (component.components && component.components.length > 0) {
      result = result.concat(getComponents(component.components));
    }
  });
  return result;
};

export const filterAllowedFields = (fields, filterIn = false) => {
  let result = [];
  if (fields && fields.length > 0) {
    fields.forEach((res) => {
      if (res.length > 0) {
        res.forEach((res1) => {
          if (filterIn.includes(res1.type)) {
            result.push(res1);
          }
        });
      } else {
        if (filterIn.includes(res?.type)) {
          result.push(res);
        }
      }
    });
    return result;
  }
};

export const getFilteredFields = (data) => {
  if (typeof data == "object" && Object.keys(data).length > 0) {
    let components = [];
    for (let i = 0; i < data.pages.length; i++) {
      for (let j = 0; j < data.pages[i].frames.length; j++) {
        components = [
          ...components,
          getComponents(data.pages[i].frames[j].component.components),
        ];
      }
    }
    const filteredFields = filterAllowedFields(components, allowedFields);
    return filteredFields;
  }
};

export const getInitialFieldsForRules = (forms) => {
  if (forms && forms.length > 0) {
    let fields = [];
    forms.forEach((res) => {
      if (res?.attributes?.name) {
        fields.push({
          name: res?.attributes.name,
          label: res?.attributes.name,
          valueSources: ["field", "value"],
        });
      } else if (res.name) {
        fields.push({
          name: res?.name,
          label: res?.name,
          valueSources: ["field", "value"],
        });
      }
    });
    return fields;
  }
};

export const generateCondition = (query, defaultValues) => {
  if (!query) return "";
  const { combinator, rules } = query;
  const conditions = rules.map((rule) => {
    if (rule.combinator) {
      return `(${generateCondition(rule, defaultValues)})`;
    } else {
      let fieldD;
      if (typeof defaultValues[rule.field] !== "undefined") {
        fieldD = JSON.stringify(defaultValues[rule.field]);
      } else {
        fieldD = JSON.stringify("");
      }

      const value =
        rule.valueSource === "value"
          ? JSON.stringify(rule.value)
          : JSON.stringify(defaultValues[rule.value]);

      return `${fieldD} ${rule.operator} ${value}`;
    }
  });
  return conditions.join(` ${combinator} `);
};


export const getLanguageString = (language={}, string) => {
  return language.hasOwnProperty(string) ? language[string] : string;
};

export const filterFromArray = (array, key, value)=>{
  return array.filter((res)=>{
    if(res[key] == value){
      return true
    }else{
      return false;
    }
  })
}


const customValueProcessor = (field, operator, value) => {
  if (typeof value === 'object' && 'value' in value) {
    return `'${value.value}'`;
  }
  return defaultValueProcessor(field, operator, value);
};

// Custom query formatter
export const customQueryFormatter = (query, format) => {
  return formatQuery(query, {
    format,
    valueProcessor: customValueProcessor,
    formatField: formatQueryField,
    formatValue: formatQueryValue,
  });
};

export const openLinkInNewTab = (url, target) => {
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.rel = 'noopener noreferrer';
  if(target === 'New Tab') {
    anchor.target = '_blank';
  }
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
}

export const sanitizeElements = (element) => {
  return sanitizeHtml(element, {
    allowedAttributes: {
      '*': ['style', 'class', 'dir', 'bgcolor', 'id'],
      'table': ['border', 'cellpadding', 'cellspacing', 'valign', 'align'],
      'th': ['scope'],
      'a': ['href', 'target'],
    }
  })
}

export const sanitizeHTMLForDocExport = (element) => {
  return sanitizeHtml(element, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['style','!DOCTYPE', 'html', 'body', 'head']),
    allowedAttributes: {
      '*': ['style', 'class', 'dir', 'bgcolor', 'id'],
      'table': ['border', 'cellpadding', 'cellspacing', 'valign', 'align',],
      'th': ['scope'],
      'td': ['colspan'],
      'a': ['href', 'target'],
    }
  })
}

// A function to find an element from a deeply nested array.
export const findChildElement = (array, key, value, subItemsField) => {
  for (const item of array) {
    if (item[key] === value) {
      return item;
    }
    if (item[subItemsField] && Array.isArray(item[subItemsField])) {
      const found = findChildElement(item[subItemsField], key, value, subItemsField);
      if (found) {
        return found;
      }
    }
  }
  return undefined;
};
// A function to generate an object having id and value properties. 
export const createValueObject = (id, value) => {
  return { id, value };
};

//Initial Query for React-Query-Builder
export const initialQuery = { combinator: "and", rules: [] };

//Initial Data for Criticality Dropdown
export const initialCriticalityData = [ { id: 'yes', label: 'Yes' }, { id: 'no', label: 'No' } ];

//Initial Data for Service Based Dropdown
export const initialServiceBasedData = [ { id: 'true', label: 'True' }, { id: 'false', label: 'False' } ];

// Priority list for Incident Module
export const allPriority = [{ value: '45', label: 'P1' },{ value: '50', label: 'P2' },{ value: '55', label: 'P3' },{ value: '60', label: 'P4' }];

// export const mergeRules = (rules) => {
//   const mergedRules = [];
//   const ruleMap = new Map();

//   rules.forEach(rule => {
//     if (rule.rules) {
//       mergedRules.push(transformPayload(rule));
//     } else {
//       const key = `${rule.field}-${rule.operator}`;
//       if (ruleMap.has(key)) {
//         ruleMap.get(key).value.push(rule.value);
//       } else {
//         ruleMap.set(key, { ...rule, value: [rule.value] });
//       }
//     }
//   });

//   ruleMap.forEach(value => mergedRules.push(value));
//   return mergedRules;
// };

// const mergeRules = (rules) => {
//   const ruleMap = new Map();

//   rules.forEach(rule => {
//     const key = `${rule.field}-${rule.operator}`;
//     if (ruleMap.has(key)) {
//       ruleMap.get(key).value.push(...[].concat(rule.value));
//     } else {
//       ruleMap.set(key, { ...rule, value: [].concat(rule.value) });
//     }
//   });

//   const mergedRules = [];
//   ruleMap.forEach(value => mergedRules.push(value));
//   return mergedRules;
// };

//
const mergeRules = (rules) => {
  const ruleMap = new Map();

  rules.forEach(rule => {
    const key = `${rule.field}-${rule.operator}`;
    if (ruleMap.has(key)) {
      ruleMap.get(key).value.push(...[].concat(rule.value).filter(v => v !== null));
    } else {
      ruleMap.set(key, { ...rule, value: [].concat(rule.value).filter(v => v !== null) });
    }
  });

  const mergedRules = [];
  ruleMap.forEach(value => {
    mergedRules.push(value);
  });
  return mergedRules;
};

//A function to rename certain keys for a non-linear React-Query-Builder's query payload for D2C's Incident Module
// export const transformPayload = (obj) => {
//   if (Array.isArray(obj)) {
//     return obj.map(transformPayload);
//   } else if (typeof obj === 'object' && obj !== null) {
//     const newObj = {};
//     for (const key in obj) {
//       if (key === 'id' || key === 'valueSource') continue;
//       else if (key === 'rules') {
//         newObj[key] = mergeRules(obj[key]);
//       } else if (key === 'combinator') {
//         newObj['logicalOperator'] = obj[key];
//       } 
//     }
//     return newObj;
//   }
//   return obj;
// };
export const transformPayload = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(transformPayload);
  } else if (typeof obj === 'object' && obj !== null) {
    const newObj = {};
    for (const key in obj) {
      if (key === 'id' || key === 'valueSource') continue;
      else if (key === 'rules') {
        newObj[key] = mergeRules(obj[key].map(transformPayload));
      } else if (key === 'combinator') {
        newObj['logicalOperator'] = obj[key];
      } else {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  }
  return obj;
};

// A function to construct dropdown field for React-Query's defined dropdown  
export const constructDropdownFields = (retrivedDropdownLists) => {
  const extractedFields = [];
  retrivedDropdownLists.map(item => {
    if(item.value == "ci_location_id" || item.value == "requester_location_id"){
      return;
    }
    const extractedOperators = item.operators.map(op => ({ name: op.name.replace(/\s+/g, ''), label: op.label }));
    extractedFields.push({ name: item.name.split('.')[1], label: item.label, inputType: item.attributeValueType, operators: extractedOperators, apiEndPoint: item.apiEndPoint });
  });
  return extractedFields;
}

// A function to reset the React-Query-Builder's Query
export const resetQuery = () => {
  return dispatch => {
    dispatch({ type: 'PRODUCED_QUERY', payload: initialQuery });
    dispatch({ type: 'FINAL_QUERY', payload: initialQuery });
  }
};

// A function to reset 
export const resetFilters = () => {
  return dispatch => {
    dispatch({type: 'ON_AI_SEARCH_LOADER' });
    dispatch({type: 'OFF_AI_SEARCH_LOADER' });
  }
};

// An Incident's item Statuses List
export const incidentStatuses = [{ value: "10", label: "Submitted" }, { value: "15", label: "In Progress" }, { value: "20", label: "Pending" }, { value: "25", label: "Fixed" }, { value: "30", label: "Closed" }, { value: "35", label: "Cancelled" }]; 

// common operator for advance search
export const textFieldOperators =  [{"name": "equals", "label": "Equal"}, {"name":"startswith", "label": "Starts With"}, {"name": "endswith", "label": "Ends With"}, {"name": "contains", "label": "Contains"}];
export const radioOperators = [{"name": "equals", "label": "Equal"}, {"name": "notequal", "label": "Not Equal"}];
export const multiselectDropdownCheckboxOperators = [{"name": "equals", "label": "Equal"}, {"name": "notequal", "label": "Not Equal"}, {"name": "in", "label": "In"}, {"name": "notin", "label": "Not In"}];
export const datesOperators = [{"name": "between", "label": "Between"}];

// Incident Module Meta Data for NLP Search
export const tempFields = [
  { name: 'requestNumber', label: 'Request ID',inputType: 'input',operators: textFieldOperators, isTextEnabled: true},
  { name: 'breakfixNumber', label: 'Work Item Number',inputType: 'input',operators: textFieldOperators, isTextEnabled: true},
  { name: 'slaStatusValue', label: 'SLA Status',inputType: 'typeahead',operators: multiselectDropdownCheckboxOperators, isTextEnabled: true},
  { name: 'serviceName', label: 'Service Name',inputType: 'typeahead',operators: multiselectDropdownCheckboxOperators, isTextEnabled: true},
  { name: 'ciName', label: 'CI Name',inputType: 'typeahead',operators: multiselectDropdownCheckboxOperators, isTextEnabled: true},
  { name: 'consumerCompanyName', label: 'Company',inputType: 'typeahead',operators: multiselectDropdownCheckboxOperators, isTextEnabled: true},
  { name: 'description', label: 'Description',inputType: 'textarea',operators: textFieldOperators, isTextEnabled: true},
  { name: 'status', label: 'Status',inputType: 'dropdown',operators: multiselectDropdownCheckboxOperators, isTextEnabled: false},
  { name: 'priorityId', label: 'Priority',inputType: 'dropdown',operators: multiselectDropdownCheckboxOperators, isTextEnabled: false},
  { name: 'spGroupname', label: 'Support Group',inputType: 'typeahead',operators: multiselectDropdownCheckboxOperators, isTextEnabled: true},
  { name: 'cimGroupname', label: 'CIM Groups',inputType: 'typeahead',operators: multiselectDropdownCheckboxOperators, isTextEnabled: true},
  { name: 'criticality', label: 'Criticality',inputType: 'radio',operators: radioOperators, isTextEnabled: false},
  { name: 'cimUsername', label: 'CIM Individual',inputType: 'typeahead',operators: multiselectDropdownCheckboxOperators, isTextEnabled: true},
  { name: 'spUsername', label: 'Support Individual',inputType: 'typeahead',operators: multiselectDropdownCheckboxOperators, isTextEnabled: true},
  { name: 'impactedUsername', label: 'Requested For',inputType: 'typeahead',operators: multiselectDropdownCheckboxOperators, isTextEnabled: false},
  { name: 'createdDate', label: 'Reported On', inputType: 'dateRange', operators: datesOperators, isTextEnabled: false},
  { name: 'modifiedOn', label: 'Last Modified On', inputType: 'dateRange', operators: datesOperators, isTextEnabled: true},
  { name: 'ciClassId', label: 'Class', inputType: 'typeahead', operators: multiselectDropdownCheckboxOperators, isTextEnabled: false},
  { name: 'serviceBased', label: 'Service Based', inputType: 'radio', operators: radioOperators, isTextEnabled: false},
  { name: 'asgGroupHopCount', label: 'Hop Count', inputType: 'integerTextArea', operators: radioOperators, isTextEnabled: true},
  { name: 'reportedThrough', label: 'Reported Through', inputType: 'typeahead', operators: multiselectDropdownCheckboxOperators, isTextEnabled: false},  
  { name: 'additionalInfo', label: 'Additional Information',inputType: 'textarea',operators: textFieldOperators, isTextEnabled: true},
  { name: 'urgency', label: 'Urgency', inputType: 'typeahead', operators: multiselectDropdownCheckboxOperators, isTextEnabled: false},
  { name: 'serviceCriticality', label: 'Impact', inputType: 'typeahead', operators: multiselectDropdownCheckboxOperators, isTextEnabled: false},
];
export const replaceVariables = (obj, replaceValues, text) => {
  let result = {};
  Object.keys(obj).map((variable) => {
    if(obj[variable] === "{{like}}"){ 
      result[variable] = text
    }
    else if(obj[variable].toString().includes('{{') && obj[variable].toString().includes('}}')) {
      let value = obj[variable].toString().split('{{').map(elem => {
        if(elem.toString().includes('}}')) {
          return replaceValues[elem.toString().substr(0, elem.toString().indexOf('}}'))]
        } else {
          return elem;
        }
      })
      result[variable] = Array.isArray(value) ? value.join('') : value;
    } else {
      result[variable] = obj[variable];
    }
  })
  return result;
}

//Reducer code body for single action type and initial state
export const codeForReducer = (action, actionType, initialState) => {
    if(action.type === actionType){
        return action.payload;
    }else{
        return initialState; 
    }
}

//A function to check whether two arrays are equal or not
export const arraysEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
      return false;
  }
  for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] != arr2[i]) {
          return false;
      }
  }
  return true;
}
// Fuzzy String Comparison
export const areStringsEqual = (str1, str2, matchingThreshold = 0.9) => {
  const minLength = Math.min(str1.length, str2.length);
  let matchCount = 0;
  for (let i = 0; i < minLength; i++) {
      if (str1[i] === str2[i]) {
          matchCount++;
      }
  }
  let calculatedMatchingThreshold = matchCount / minLength;
  return calculatedMatchingThreshold >= matchingThreshold;
}
// Returns the initialSettings object for <DateRangePicker> component. Just provide start and end date in the format "MM-DD-YYYY".
export const getInitialSettings = (startDate, endDate) => { 
  return {autoUpdateInput: false,
    maxDate: moment(),
    autoApply: true,
    ranges: {"Last 7 Days": [moment().subtract(6, "days"), moment()],
      "Last 30 Days": [moment().subtract(29, "days"), moment()],
      "Last 90 Days": [moment().subtract(89, "days"), moment()]},
    startDate: startDate,
    endDate: endDate,
  };
}

// Return days difference (in integer) between two dates
export const daysDifference = (startDate, endDate) => { 
  return endDate.diff(startDate, 'days');
}