
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/


export const departmentList = (state = [], action) => {
    if (action.type === 'DEPARTMENT_LIST_FETCHED_SUCCESSFULLY') {
      return action.payload;
    } else {
      return state;
    }
};

export const departmentEditList = (state = [], action) => {
    if (action.type === 'DEPARTMENT_EDIT_DETAILS_FETCHED') {
      return action.payload;
    } else {
      return state;
    }
};

export const organizationList = (state = [], action) => {
    if (action.type === 'ORGANIZATION_DROPDOWN_FETCHED_SUCCESSFULLY') {
      return action.payload;
    } else {
      return state;
    }
};

