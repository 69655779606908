/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/
import React, { useEffect, useState } from "react";
import useDataQueryWithApi from "../../hooks/useDataQueryWithApi";
import ListLoader from "../../../common/loaders/ListLoader";
import { Carousel, ListGroup } from "react-bootstrap";

const HorizontalSlider = (props) => {
  const [data, setData] = useState([]);
  const { item, components, defaultValues, setDefaultValues } = props;
  const [activeIndex, setActiveIndex] = useState(item?.isPageBased?1:0);
  const [limit, setLimit] = useState(item?.itemsInOneSlide || 5);
  const [page, setPage] = useState(1);
  const limitKey = item?.limitKey || "limit";
  const pageKey = item?.pageKey || "page";
  
  const [
    responseData,
    optionsLoading,
    setDataSourceId,
    dataError,
    setRefreshed,
  ] = useDataQueryWithApi({ ...defaultValues,[limitKey]:limit,[pageKey]:page}, item, { ...defaultValues });

  useEffect(() => {
    if (item?.data_source) {
      setDataSourceId(item?.data_source.id);
    } else if (item?.defaultValueKey) {
      if (defaultValues[item?.defaultValueKey]) {
        setData(defaultValues[item?.defaultValueKey]);
      }
    }
  }, []);

  useEffect(() => {
    if (responseData) {
        
      if (item?.dataKey) {
        setData(responseData[item?.dataKey]);
        if(item?.metaKey){
          setDefaultValues({ ...defaultValues, [item?.metaKey]: responseData[item?.metaKey]});
        }
      } else {
        setData(responseData);
      }
    }
  }, [responseData]);


  useEffect(() => {
    if (activeIndex) {
      if (defaultValues[item?.metaKey]) {
        let limit1 = item?.itemsInOneSlide || 5;
        let page1 = item?.isPageBased
          ? parseInt(activeIndex,10) * parseInt(item?.itemsInOneSlide,10) + 1
          : activeIndex;
        setLimit(limit1);
        setPage(page1);       }
    }
  }, [activeIndex]);

  useEffect(() => {
    if (limit && page) {
      setRefreshed(true);
    }
  }, [limit, page]);

  useEffect(() => {
    if(props.isDataRefreshed){
      setRefreshed(true);
    }
  }, [props.isDataRefreshed]);

  const renderByComponent = (json, renderData) => {
    if (renderData.length > 0) {
      return renderData.map((field, index) =>
        props.renderComponent(json, true, field)
      );
    }
  };


    const handleSelect = (selectedIndex, e) => {
      if (defaultValues[item?.metaKey]) {
        const totalCount = defaultValues[item?.metaKey][item?.totalCountKey];
        const pages = Math.ceil(totalCount / item?.itemsInOneSlide);
        let index = activeIndex;
        let direction =
          e.target.classList[0] === "carousel-control-next-icon" || e.target.classList[0] === "carousel-control-next"
            ? "next"
            : "previous";
        if (direction === "next") {
          if (activeIndex < pages) {
            index = activeIndex + 1;
          }
        } else if (direction === "previous") {
          if (activeIndex > 0) {
            index = activeIndex - 1;
          }
        }
        setActiveIndex(index);
      }else{
        setActiveIndex(selectedIndex);
      }
    };

  const renderCarouselItems = (components) => {
    let carouselItems = [];
    if (item?.isOneTimeData) {
      for (let i = 0; i < Math.ceil(data.length/item?.itemsInOneSlide); i++) {
        const passData = data.slice(i * item?.itemsInOneSlide, (i + 1) * item?.itemsInOneSlide);
        carouselItems.push(
          <Carousel.Item key={i}>{renderByComponent(components,passData)}</Carousel.Item>
        );
      }
    } else {
        carouselItems.push(
          <Carousel.Item key={0}>
            {renderByComponent(components, data)}
          </Carousel.Item>
        );  
    }

    return (
      <Carousel
        slide={item?.slide || false}
        id="bestSellingUlBestSell"
        activeIndex={item?.isPageBased?0:activeIndex}
        controls={item?.controls || false}
        indicators={item?.indicators || false}
        direction={item?.direction || false}
        onSelect={(i, e) => e && handleSelect(i, e)}
        nextIcon={item?.nextIcon?<i className={`carousel-control-next-icon ${item?.nextIcon}`}></i>:<span className="carousel-control-next-icon" />}
        prevIcon={item?.prevIcon?<i className={`carousel-control-next-icon ${item?.prevIcon}`}></i>:<span className="carousel-control-prev-icon" />}
        nextLabel={item?.nextText || ""}
        prevLabel={item?.prevText || ""}
        interval={item?.interval || null}
      >
        {carouselItems}
      </Carousel>
    );
  };

  if (optionsLoading && item?.transitionLoader) {
    return <ListLoader />;
  } else {
    return !data || data.length == 0 ? (
      <div className="text-c padding-t-10 padding-b-10 border">
        No Data Found
      </div>
    ) : (
      data && data.length > 0 && renderCarouselItems(components)
    );
  }
};

export default HorizontalSlider;
