
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import TabHeader from "../../../common/TabHeader";
import { useShowHide } from "../../../common/useShowHide";

import { getCookiesValuesByPositions } from "../../../Helper";
import ActionItemsListView from "./list";
import ActionAdd from "./addEdit";
import { useGetUserInfoQuery } from "../../../../../reducers/governance/meeting/meeting";
import { useGetActionItemsQuery } from "../../../../../reducers/governance/meeting/actionItem";

const ActionItems = ({
  occurenceData,
  meetingDefData,
  companyID,
  activeTabs,
  module = "Meeting Occurrence",
}) => {
  const cookieValues = getCookiesValuesByPositions([2, 3]);
  const dateformat = cookieValues?.value1;
  const timezone = cookieValues?.value2;
  const jwtdf = dateformat?.slice(0, 10).toUpperCase();

  const meetingOccurrananceId = occurenceData?.meetingOccurrananceId;
  const meetingDefinationId = occurenceData?.meetingDefinationId;

  const [isVisible, showNotes, hideNotes] = useShowHide(false);
  const [orderby, setOrderby] = useState("asc");

  const sorting = () => {
    setOrderby(orderby === "asc" ? "desc" : "asc");
  };

  const { data: userInfo } = useGetUserInfoQuery();

  const {
    data: actData,
    isFetching: actDataFetching,
    refetch: actDataRefetching,
  } = useGetActionItemsQuery(
    {
      queryString: `?&filter[where][workItemId]=${meetingOccurrananceId}&filter[where][companyId]=${companyID}&filter[include][]=performers&filter[order]=createdDate ${orderby}`,
    },
    {
      skip: !companyID,
    }
  );

  const otherHeaderProps = {
    hideNotes,
    isVisible,
  };

  useEffect(() => {
    return () => {
     hideNotes();
    }
   }, [activeTabs]);

  const options = [
    {
      isVisible,
      title: "Add",
      onClick: showNotes,
      icon: "fa-plus",
      sorting: false,
      className: `add-message`,
    },
    {
      isVisible,
      title: "Sorting",
      onClick: sorting,
      icon: `custom-fa fa-sort-amount-${orderby}`,
      sorting: false,
      className: "custom-sorting",
    },
    {
      isVisible,
      onClick: actDataRefetching,
      title: "Refresh",
      icon: "fa-refresh",
      sorting: false,
      className: "filter-icon mx-1",
    },
  ];

  const filteredOptions = options.filter((option, index) => {
    return (
      index !== 0 ||
      (occurenceData?.userAuthorized && occurenceData?.mop === "E")
    );
  });

  return (
    console.log("activeTabs==>",activeTabs),
    <div className="stickyArea rBoxStyle">
      <div className="rPageHeading d-flex p-3">
        <div className="flex-grow-1 offNam mw-100">ACTION</div>
      </div>

      <div className="p-3 occurnance">
        <TabHeader
          heading={!isVisible ? "List" : "New"}
          options={filteredOptions}
          close={false}
          otherProps={otherHeaderProps}
        />
        {!isVisible && (
          <ActionItemsListView
            data={actData}
            actDataFetching={actDataFetching}
            companyID={companyID}
            occurenceData={occurenceData}
            meetingDefData={meetingDefData}
          />
        )}
        {isVisible && (
          <ActionAdd
            companyID={companyID}
            hideNotes={hideNotes}
            occuranceDetails={occurenceData || []}
            meetingDefData={meetingDefData}
            dateformat={jwtdf}
          />
        )}
      </div>
    </div>
  );
};

export default ActionItems;
