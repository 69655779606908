
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
/**
@Description: routes for
**/
import axios from "axios";
import { GLOBAL } from '../../components/Globals';

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});
export const getMyApprovalList = (userId,sorttype, sortval,approvalStatus,page,limit,ModuleValue,searchby = '', searchbyVal = '') => {
  let status = approvalStatus.toString();
  let str = {};


 if (searchby != "") {
    str.searchby = 'status' + "," + searchby;
    str.searchbyVal = status + "|" + searchbyVal;
  }
  else{
    str.searchby = "status";
    str.searchbyVal = status;
  }

  str = JSON.stringify(str);
  // const response = axios.get(
  //   GLOBAL.approvalListURL +userId +"/" +ModuleValue +"/" +page +"/" +limit +"/" +sortval +"/" +sorttype, { headers: { 'query': str } }
  // );
  return dispatch => {
    dispatch({ type: "LOADER_IN_PROGRESS" });
    return axios.get(
      GLOBAL.approvalListURL +userId +"/" +ModuleValue +"/" +page +"/" +limit +"/" +sortval +"/" +sorttype, { headers: { 'query': str } }
    )
      .then(approvalList => {
        if (approvalList.errorCode) {
          dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
          dispatch(getApprovalListFailure(approvalList));
        } else {
          dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY' });
          dispatch(getApprovalListSuccess(approvalList));
        }
        return Promise.resolve(approvalList);
      })
      .catch(err => {
        dispatch(getApprovalListFailure(err));
        return Promise.reject('Error');
      });
  };
};

const getApprovalListFailure = errorResponse => {
  return {
    type: "APPROVAL_LIST_FAILURE",
    errorResponse
  };
};

const getApprovalListSuccess = approvalList => {
  return {
    type: "APPROVAL_LIST_SUCCESS",
    approvalList
  };
};

export const getAdhocApprovers = (signatureId) => {
  const response = axios.get(GLOBAL.adhocApproversListURL + signatureId);
  return dispatch => {
    response
      .then(approversList => {
        if (approversList.status == 200) {
          dispatch(
            {
              type: 'ADHOC_APPROVERS_LIST',
              approversList
            }
          );
        }
      })
      .catch(err => {

      });
  };
};

export const loadApprovalHistory = (itemNumber,module)=>{
	var str={};
	str.item_number=itemNumber;
	str.module = module;
	str = JSON.stringify(str);
	return(dispatch) =>{
		api.get(GLOBAL.approvalHistoryApi,{headers:{'query':str}})
			.then((approvalHistory)=>{
				if(!approvalHistory){
					throw Error(approvalHistory.statusText);
				}
				return approvalHistory;
			})
			.then((approvalHistory) =>{
				dispatch({type:"LOAD_APPROVALHISTORY_DETAIL_SUCCESS",payload:approvalHistory.data})
			})
			.catch((err) => {
				dispatch({type:"LOAD_APPROVALHISTORY_DETAIL_FAILURE",failureMessage:err})
				console.log(err);
			});
	};
  }