
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const changeConflictData = (state = {}, action) => {
	switch (action.type) {
		case 'CHANGE_CONFLICT_DATA':
			return  action.changeConflictData;
		default:
			return state;
	}
};

export const changeConflictJustification = (state = {}, action) => {
	switch (action.type) {
		case 'CHANGE_CONFLICT_JUSTIFICATION':
			return action.changeConflictJustification;
		default:
			return state;
	}
}

export const loadPirData = (state = {}, action) => {
	switch (action.type) {
		case 'LOAD_PIR_DETAILS':
			console.log("actionaction ",action)
			return action.pirDetails;
		default:
			return state;
	}
}

export const loadCngData = (state = {}, action) => {
	switch (action.type) {
		case 'LOAD_CNG_DETAILS':
			console.log("actionaction ",action)
			return action.payload;
		case "RESET_STATE":
			return {};
		default:
			return state;
	}
}