
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef } from "react";
import { Row, Col, ListGroup, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {PiPencilSimpleBold} from "react-icons/pi";
import {IoClose} from "react-icons/io5";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';

const BulletinShortDesc = (props) => {
  const navigate = useNavigate();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const context = useContext(MyContext);
    const closeButton = useRef(null);
    const editButton = useRef(null);

    useEffect(() => {
        context.keyDownHandler(closeButton.current,editButton.current,"","");
        context.newTabPrevent();
    }, []);

  // console.log("ShortBulletin", props.bulletinData)
  return (
    <>
      <Row>
        <Col xs={12}>
          <div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
            <ListGroup bsPrefix=" " as="ul">
              <ListGroup.Item as="li" bsPrefix=" ">
                <Link
                  bsPrefix=" "
                  to="/editBulletin"
                  state= {{
                    formType: "Edit",
                    bulletinId: props.bulletinData.bulletinId,
                  }}
                  title={tr["Edit"]}
                  className="editIcn ctrlKeyPrevent"
                  ref={editButton}
                >
                  <PiPencilSimpleBold/>
                </Link>
              </ListGroup.Item>
              <ListGroup.Item as="li" bsPrefix=" ">
                <Link to="javascript:void(0)" bsPrefix=" " title={tr["Minimize the details panel"]} className="ctrlKeyPrevent" onClick={() => { props.showRightSideFun(false); }} ref={closeButton} >
                    <IoClose/>
                </Link>
              </ListGroup.Item>
            </ListGroup>
          </div>
          <div className="rPageHeading">
            <div className="offNam margin-t-5 margin-b-5">
              {tr["Bulletin Details"]}
            </div>
          </div>
        </Col>
      </Row>
      <div className="rBoxGap">
        <div className="rBoxTabs">
          <ListGroup as="ul" bsPrefix=" ">
            <ListGroup.Item
              as="li"
              bsPrefix=" "
              className="rwTable"
              style={{ borderTop: "none" }}
            >
              <span className="rwKey">{tr["Company"]}</span>
              <span className="rwVal">{props.bulletinData.companyId}</span>
            </ListGroup.Item>

            <ListGroup.Item as="li" bsPrefix=" " className="rwTable">
              <span className="rwKey">{tr["Sequence"]}</span>
              <span className="rwVal">
                {props.bulletinData.bulletinSequence}
              </span>
            </ListGroup.Item>

            <ListGroup.Item as="li" bsPrefix=" " className="rwTable">
              <span className="rwKey">{tr["From Date"]}</span>
              <span className="rwVal">{props.bulletinData.fromDate}</span>
            </ListGroup.Item>

            <ListGroup.Item as="li" bsPrefix=" " className="rwTable">
              <span className="rwKey">{tr["To Date"]}</span>
              <span className="rwVal">{props.bulletinData.toDate}</span>
            </ListGroup.Item>
          </ListGroup>
        </div>
      </div>
    </>
  );
};

export default BulletinShortDesc;
