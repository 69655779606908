
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const myTdComponent = (e) => {
  e.DomComponents.addType("custom-td", {
    model: {
      defaults: {
        tagName: "td",
        traits: [
          {
            type: "text",
            label: "Column Text",
            name: "innerText",
            changeProp: 1,
          },
          {
            type: "checkbox",
            label: "Is Custom Column",
            name: "isCustomCol",
            changeProp: 1,
          },
        ],
      },
      init() {
        this.on("change:innerText", this.handleInnerTextChange);
      },
      handleInnerTextChange() {
        this.components(this.get("innerText"));
      },
    },
  });
};
