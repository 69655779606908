
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import List from "./list";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useDispatch, useSelector } from "react-redux";
import Header from "./header";
import PindCategories from "../../common/header/pind-categories";
import Breadcrumbs from "../../common/header/breadcrumbs";
import { getTOAList } from "../../../actions/spcmActions";
import ShortDescp from "./shortDescription";
import "_Css/common/_table.scss";

const ToaIndex = () => {
  const [isRightSideVisible, setRightSideVisible] = useState(false);
  const [rowIndex, setRowIndex] = useState(0);
  const [toaListData, setTOAListData] = useState({});
  const [panelResize,setPanelResize] = useState();
  const [searchParams, setSearchParams] = useState({});
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const translator = useSelector((state) => state.spcmReducer.tr);
  const [clearAllFilterToggle, setClearAllFilterToggle] = useState(false);
  const [selectedStatusValue, setSelectedStatusValue] = useState([{label: 'Active', value: 'active'}]);
  const [selectedDatatypeValue, setSelectedDatatypeValue] = useState([]);
  const [tableNameSearchInput, setTableNameSearchInput] = useState("");
  const [attributeSearchInput, setAttributeSearchInput] = useState("");
  const [attributeLabelSearchInput, setAttributeLabelSearchInput] = useState("");
  const [lastModifiedBySearchInput, setLastModifiedBySearchInput] = useState("");
  const [searchKey, setsearchKey] = useState("");
  const [searchValue, setsearchValue] = useState("");
  const [patternList, setPatternList] = useState("");
  const [dataType, setDataType] = useState([]);
  const [status, setStatus] = useState(["active"]);
  const [modifiedDateRef, setModifiedDateRef] = useState(null);
  const [modifiedStartDate, setModifiedStartDate] = useState({});
  const [modifiedEndDate, setModifiedEndDate] = useState({});
  const [modifiedDateFilterValues, setModifiedDateFilterValues] = useState([]);
  const [refreshedonDateRef, setRefreshedonDateRef] = useState(null);
  const [refreshedonStartDate, setRefreshedonStartDate] = useState({});
  const [refreshedonEndDate, setRefreshedonEndDate] = useState({});
  const [refreshedonDateFilterValues, setRefreshedonDateFilterValues] = useState([]);
  const [selectedDate, setSelectedDate] = useState([]);
  const [isStatusOpen, setStatusOpen] = useState(false);
  const [isDataTypeOpen, setDataTypeOpen] = useState(false);
  const toaList = useSelector((state) => state.spcmReducer.toaList);
  const commonLoader = useSelector((state) => state.commonLoader.isLoading);

  let dispatch = useDispatch();
  let colWidth = "";
  isRightSideVisible ? (colWidth = 8) : (colWidth = 12);

  const showRightSideFun = (val) => {
    setRightSideVisible(val);
  };
  const toaListHandler = (data) => {
    setTOAListData(data)
  }
 
  useEffect(() => {
    dispatch(getTOAList());
   },[])

   const setMultiSelectParams = (dataType, status) => {
    setDataType(dataType);
    setStatus(status);
  }

  const setModifiedStartAndEndDate = (startDate, endDate, filterValue)=> {
    setModifiedStartDate(startDate)
    setModifiedEndDate(endDate)
    setModifiedDateFilterValues(filterValue)
  }
  const setRefreshedonStartAndEndDate = (startDate, endDate, filterValue)=> {
    setRefreshedonStartDate(startDate)
    setRefreshedonEndDate(endDate)
    setRefreshedonDateFilterValues(filterValue)
  }
  const multiSelectOpenFun = (keyName) => {
		if (keyName === "dataType") {
      setDataTypeOpen(true);
    }
    else if (keyName === "status") {
      setStatusOpen(true);
    }
    else if (keyName === "dropdownClose") {
      setDataTypeOpen(false);
      setStatusOpen(false);
    }
  }

   console.log("searchValue",searchValue)
   console.log("searchKey",searchKey)
   console.log("patternList",patternList)

   const resetFilters = () => {
    setSelectedStatusValue([{ label: 'Active', value: 'active' }]);
    setSelectedDatatypeValue([]);
    setTableNameSearchInput("");
    setAttributeSearchInput("");
    setAttributeLabelSearchInput("");
    setLastModifiedBySearchInput("");
    setModifiedStartDate({});
    setModifiedEndDate({});
    setRefreshedonStartDate({});
    setRefreshedonEndDate({});
    setDataType([]);
    setStatus(["active"]);
    setPage(0);

   
    setsearchKey("");
    setsearchValue("");
    dispatch(getTOAList());
  };

     let toaDataList = toaList ? toaList.records : { data: [] };
     let meta = !_.isEmpty(toaList) ? toaList.metaInfo : {}; 
   let pageSize = meta && meta.size ? meta.size : 0;
   let currentPage = meta && meta.currentPage ? meta.currentPage : 0;
   let startLimit = pageSize * currentPage + 1;
   let endLimit = pageSize * (currentPage + 1);
   let recordCount = meta && meta.recordCount ? meta.recordCount : 0;
   let totalPageCount = meta && meta.totalPageCount ? meta.totalPageCount : 0;
   let panelResponsiveState = (isStatusOpen || isDataTypeOpen) && (toaDataList == undefined || toaDataList.length === 0 || toaDataList.length <=6 || commonLoader)
  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Row className="">
        <Col lg={8} md={8} sm={9} xs={8}>
        <Breadcrumbs activePageName= "Data Dictionary" /></Col>
        <Col lg={4} md={4} sm={3} xs={4}>
                {/* {recordCount != 0 ? (
                  <div className="tabDatacount">
                    {startLimit}-
                    {endLimit > recordCount
                      ? (endLimit = recordCount)
                      : endLimit}{" "}
                    of {recordCount}
                  </div>
                ) : null} */}
                {totalPageCount != 0 ? (
                  <div className="tabDatacount">
                    {startLimit}-
                    {endLimit > totalPageCount
                      ? (endLimit = totalPageCount)
                      : endLimit}{" "}
                    of {totalPageCount}
                  </div>
                ) : null}
              </Col>
        </Row>
        
      </Container>

      <Container fluid className="position-re">
        <Header 
              clearAllFilterToggle={clearAllFilterToggle}
              setClearAllFilterToggle={() => {
                setClearAllFilterToggle(true);
                  resetFilters();
              }}
              setMultiSelectParams={setMultiSelectParams}
              setModifiedStartAndEndDate={setModifiedStartAndEndDate}
              setSelectedStatusValue={setSelectedStatusValue}
              setSelectedDatatypeValue={setSelectedDatatypeValue}
              setTableNameSearchInput={setTableNameSearchInput}
              setAttributeSearchInput={setAttributeSearchInput}
              setAttributeLabelSearchInput={setAttributeLabelSearchInput}
              setLastModifiedBySearchInput={setLastModifiedBySearchInput}
              setRefreshedonStartAndEndDate={setRefreshedonStartAndEndDate}
              setPage={setPage}
              setLimit={setSize}
              searchKey={searchKey}
              searchValue={searchValue}
              dataType={dataType}
              status={status}
              showRightSideFun={showRightSideFun}
              setsearchValue={setsearchValue}
              setsearchKey={setsearchKey}
              modifiedDateRef={modifiedDateRef}
              refreshedonDateRef={refreshedonDateRef}
              patternList={patternList}
              setDataType={setDataType}


        />
        <PanelGroup direction="horizontal" className={panelResponsiveState ? "overflow-clip" : ""}>
          <Panel id="sidebar" minSize={33} order={1} defaultSize={isRightSideVisible ? 67 : 100} onResize={(psize) => setPanelResize(psize)}className={`${isRightSideVisible ? "isShowLeftPanel catBordDv" : "catBordDv"} ${panelResponsiveState ? "overflow-clip" : ""}`}>
            <List
              isRightSideVisible={isRightSideVisible}
              showRightSideFun={showRightSideFun}
              translator={translator}
              showActiveRow={setRowIndex}
              toaListHandler={toaListHandler} 
              rowIndex={rowIndex}
              setPage={setPage}
              size={size}
              setLimit={setSize}
              page={page}
              panelResize={panelResize}
              clearAllFilterToggle={clearAllFilterToggle}
              setClearAllFilterToggle={setClearAllFilterToggle}
              selectedStatusValue={selectedStatusValue}
              setSelectedStatusValue={setSelectedStatusValue}
              selectedDatatypeValue={selectedDatatypeValue}
              setSelectedDatatypeValue={setSelectedDatatypeValue}
              tableNameSearchInput={tableNameSearchInput}
              setTableNameSearchInput={setTableNameSearchInput}
              attributeSearchInput={attributeSearchInput}
              setAttributeSearchInput={setAttributeSearchInput}
              attributeLabelSearchInput={attributeLabelSearchInput}
              setAttributeLabelSearchInput={setAttributeLabelSearchInput}
              lastModifiedBySearchInput={lastModifiedBySearchInput}
              setLastModifiedBySearchInput={setLastModifiedBySearchInput}
              searchKey={searchKey}
              searchValue={searchValue}
              setsearchValue={setsearchValue}
              setsearchKey={setsearchKey}
              patternList={patternList}
              setPatternList={setPatternList}
              dataType={dataType}
              status={status}
              setMultiSelectParams={setMultiSelectParams}
              setModifiedDateRef={setModifiedDateRef}
              modifiedDateRef={modifiedDateRef}
              setModifiedStartAndEndDate={setModifiedStartAndEndDate}
              modifiedDateFilterValues={modifiedDateFilterValues}
              modifiedEndDate={modifiedEndDate}
              modifiedStartDate={modifiedStartDate}
              setRefreshedonDateRef={setRefreshedonDateRef}
              refreshedonDateRef={refreshedonDateRef}
              setRefreshedonStartAndEndDate={setRefreshedonStartAndEndDate}
              refreshedonDateFilterValues={refreshedonDateFilterValues}
              refreshedonEndDate={refreshedonEndDate}
              refreshedonStartDate={refreshedonStartDate}
              setSelectedDate={setSelectedDate}
              isStatusOpen={isStatusOpen}
              isDataTypeOpen={isDataTypeOpen}
              multiSelectOpenFun={multiSelectOpenFun}
              />
          </Panel>
          {isRightSideVisible ?
            <>
              <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
              <Panel minSize={33} order={2} defaultSize={33}>
              <div className="stickyArea rBoxStyle">
                <ShortDescp
                  showRightSideFun={showRightSideFun}
                  translator={translator}
                  toaListData={toaListData}

                  />
              </div>
              </Panel>
            </> : ""
          }
        </PanelGroup>
      </Container>
    </main>
  )
}

export default ToaIndex;
