
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import {Button} from "react-bootstrap";

const XSMScrollToTop = (props) => {
  const [is_visible, setIs_visible] = useState(false);

  useEffect(() => {
    document.addEventListener("scroll", toggleVisibility);
    return () => {
      document.removeEventListener("scroll", toggleVisibility);
    }
  }, [])
  

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIs_visible(true);
    } else {
      setIs_visible(false);
    }
  }
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  return (is_visible && (
    <Button bsPrefix=' '  id="backtotop" title={props.translator["Back to Top"]} onClick={scrollToTop}><i className="fa fa-angle-up"></i>{props.translator["Back to Top"]}</Button>
  ));
}

export default XSMScrollToTop;