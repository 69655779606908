
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import { GLOBAL }  from '../../components/Globals';
//let breakFixUrl=`https://xsm-f.mybluemix.net/groupListSelfService?companyId=2932&group=ResolvedER`

export function loadBreakFixCIMDetailsSuccess(cimUserDetails){
	return {
		type: 'LOAD_BREAKFIXCIMDETAILS_SUCCESS',
		cimUserDetails
	};
}

export function loadBreakFixCIMDetailsSuccessForUser(cimUserDetailsForUser){
	return {
		type: 'LOAD_BREAKFIXCIMDETAILS_SUCCESS_FOR_USER',
		cimUserDetailsForUser
	};
}
export function loadBreakFixCIMDetailsFailure(errorResponse){
	return {
		type: 'LOAD_BREAKFIXCIMDETAILS_FAILURE',
		errorResponse
	};
}

export function loadBreakFixCIMDetails(CIMGroupId){
	return(dispatch) =>{
		//alert(CIMGroupId);
		if(CIMGroupId=="" || CIMGroupId == undefined){
			//alert("H2");
		}else{
			//alert("H1");
				axios.get(GLOBAL.breakFixSGroupIUrl+CIMGroupId)
					.then((cimUserDetails)=>{
						if(!cimUserDetails){
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							throw Error(cimUserDetails.statusText);
						}
						return cimUserDetails;
					})
					.then((cimUserDetails) =>{
						dispatch(loadBreakFixCIMDetailsSuccess(cimUserDetails))
					})
					.catch((err) => {
						console.log(err);
					});
					//console.log("not calling loadBreakFixCIMDetails");
		}
	};
}

export function loadBreakFixCIMForUserDetails(CIMGroupId){
	return(dispatch) =>{
		//alert(CIMGroupId);
		if(CIMGroupId=="" || CIMGroupId == undefined){
			//alert("H2");
		}else{
			//alert("H1");
				axios.get(GLOBAL.breakFixSGroupIUrlForUser+CIMGroupId)
					.then((cimUserDetails)=>{
						if(!cimUserDetails){
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							throw Error(cimUserDetails.statusText);
						}
						return cimUserDetails;
					})
					.then((cimUserDetails) =>{
						console.log("Before Dispatching")
						dispatch(loadBreakFixCIMDetailsSuccessForUser(cimUserDetails))
					})
					.catch((err) => {
						console.log(err);
					});
					//console.log("not calling loadBreakFixCIMDetails");
		}
	};
}
