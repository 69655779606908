
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
//load all order
export const causalRelationshipList = (state = {}, action) =>{
	switch (action.type){
		case 'LOAD_CAUSALRELATIONSHIPDATA_SUCCESS':
			return action.causalRelationshipData.data;
			default:
			return state;
	}
}
