
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, {Component, PropTypes } from 'react';
import {Button} from 'react-bootstrap';
import AutoSuggest from 'react-autosuggest';

let selectOptions = [];

const getSuggestions = (value,options) =>{
   const inputValue = value.trim().toLowerCase();
   const inputLength = inputValue.length;
    if(inputLength >2){
      selectOptions =options;
    }
   return inputLength <= 2 ? []: selectOptions.filter(option => option.label.toLowerCase().indexOf(inputValue) > -1);
};

const getSuggestionValue = suggestion => suggestion.label.indexOf == -1 ? '' : suggestion.label.substring(0,suggestion.label.indexOf('|'));

function renderSuggestion(suggestion) {
    let suggestionlabel =[];
    return (
      <Button bsPrefix=" "
      className="suggBtn" onClick = {()=>{
        suggestionlabel= suggestion.label;
      }}>
        {suggestion.label}<div>{suggestion.email}{" - "}{suggestion.deptname}</div>
        </Button>
    );
  }


class AInvReactSelect extends Component {
  constructor(props){
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.onServiceNameSelected = this.onServiceNameSelected.bind(this);
    this.resetField = this.resetField.bind(this);
    this.state = {
      value: '',
      suggestions: []
    };
  }

  componentDidUpdate(prevProps){
    if (this.props.savedValue !== prevProps.savedValue){
      if(this.state.suggestions.length==0){
        this.resetField();
      }
    }
    if(this.props.resetTextField !== prevProps.resetTextField){
      this.resetField();
    }
  }
  resetField(){
    this.setState({value:''});
    this.props.setTextField(false);
  }

  onChange(event,{newValue}){
    this.setState({value: newValue});
  }
  onSuggestionsFetchRequested({value}) {
    this.setState({
      suggestions: getSuggestions(value,this.props.options)
    });
  }

  onSuggestionsClearRequested (){
    this.setState({
      suggestions: []
    });
  }

  onServiceNameSelected(event, {suggestion}){
    let suggestionlabel =[];
    suggestionlabel.push(suggestion);
    this.setState({value:suggestion.label});
    this.props.onSelection(suggestionlabel);
  }

  render(){
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: 'Enter Member Name',
      className:'form-control',
      value,
      onChange: this.onChange
    };
    return (
        <AutoSuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          onSuggestionSelected={this.onServiceNameSelected}
        />
    );
}
}

export default AInvReactSelect;
