
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import CreateDashboardHeader from "./CreateDashboardHeader.js";
import CreateDashboardForm from "./CreateDashboardForm.js";
import { useSelector } from "react-redux";
import  {GLOBAL } from "_Globals";

import Cookies from "universal-cookie";
import Breadcrumbs from "../../common/header/breadcrumbs.js";
import "_Css/form/_form.scss";
const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home") homepagelocation = "/" + homepagelocation;

const CreateDashboardIndex = () => {
  const [actionView, setActionView] = useState("assetgraph");
  const [valueError, setValueError] = useState("");

  const translator = useSelector((state) => state.spcmReducer.tr);

  return (
    <div>
      <div>
        <div className="container-fluid padding-t-10 margin-b-15">
          <Breadcrumbs
            activePageName={"Create"}
            parentPageurl={GLOBAL.reportsConfiguration}
            parentPageName={translator["Dashboard Configration"]}
          />
        </div>
        <div className="container-fluid" bsClass="">
          <Form>
            <CreateDashboardHeader
              changeActionView={setActionView}
              translator={translator}
              setValueError={setValueError}
            />
            <Row className="row-eq-height">
              <Col md={8}>
                <CreateDashboardForm translator={translator} setValueError={setValueError} valueError={valueError} />
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default CreateDashboardIndex;
