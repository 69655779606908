
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { loadAllTagsList } from '_Actions/foundation/tags/tagsAction';
import { HiPlus, HiOutlineRefresh } from "react-icons/hi";
import {getOnCallGroups} from '../../../../actions/onCallSchedule/common/onCallCommonAction.js';
import {getShiftListData, resetShiftListData } from '../../../../actions/onCallSchedule/shift/onCallShiftAction.js';
import { _dropDown } from "../../../common/ReduxFormFields/CategoryBoard/formFields.js";
import { change, Field, reduxForm } from 'redux-form';
import { TypeaheadExampleSingleSelect } from '../../../common/formFields';
import { LuFilterX } from "react-icons/lu";
import CommonHeaderMenu from "../../common/CommonHeaderMenu.js";
import onCallDropdownPlusTypeahead from "../../common/onCallDropdownPlusTypeahead.js";

const ShiftListHeader = (props) => {
	const dispatch = useDispatch();
	const translator = useSelector((state) => state.spcmReducer.tr);
  let group = useSelector((state) => state.selectedOnCallGroup);
	const [typedBusinessFn, setTypedBusinessFn] = useState('');
	useEffect(() => {
		dispatch(getOnCallGroups());
	}, []);

  useEffect(() => {
    if(group && Object.keys(group).length>0){
      props.setSelectedBusinessFn(group);
		  dispatch(change("CreateShiftForm", "BUSINESS_FUNCTION_ID", group[0].id));
		  dispatch(change("CreateShiftForm", "BUSINESS_FUNCTION_NAME", group[0].businessFunctionName));
		  dispatch(getShiftListData({groupId:group[0].groupId}));
      setTypedBusinessFn(group[0].groupId);
    }
  }, [group]);

	const refreshPage = () => {
    if (
      typeof props.filters == "object" &&
      Object.keys(props.filters).length > 0
    ) {
	  props.setActivePage(0);
      dispatch(getShiftListData(props.filters));
    } else {
      props.setActivePage(0);
      props.setItemsPerPage(10);
      dispatch(getShiftListData({groupId:props.selectedBusinessFn[0].groupId}));
    }
  };

	const setBusinessFn = (selectedBusinessFn) => {
				if (selectedBusinessFn.length > 0) {
          console.log("===",selectedBusinessFn);
		  props.setSelectedBusinessFn(selectedBusinessFn);
      dispatch({ type: "SET_ONCALL_GROUP", group: selectedBusinessFn });
		  dispatch(change("CreateShiftForm", "BUSINESS_FUNCTION_ID", selectedBusinessFn[0].id));
		  dispatch(change("CreateShiftForm", "BUSINESS_FUNCTION_NAME", selectedBusinessFn[0].businessFunctionName));
		  dispatch(getShiftListData({groupId:selectedBusinessFn[0].groupId}));
      setTypedBusinessFn(selectedBusinessFn[0].groupId);
    } else {
		  props.setSelectedBusinessFn([]);
      dispatch({ type: "SET_ONCALL_GROUP", group: "" });
		  dispatch(change("CreateShiftForm", "BUSINESS_FUNCTION_ID", ''));
		  dispatch(change("CreateShiftForm", "BUSINESS_FUNCTION_NAME", ''));
		}
	  };
	  const businessFnInputChange = (value) => {
    props.setSelectedBusinessFn([]);
		setTypedBusinessFn(value);
		props.setSelectedBusinessFn([]);}
		// dispatch(getShiftListData(value));
		
		const onCrossClickBusinessFn = () => {
			setTypedBusinessFn('');
      dispatch({ type: "SET_ONCALL_GROUP", group: "" });
			dispatch(change("CreateShiftForm", "GROUP_ID", ''));
			dispatch(change("CreateShiftForm", "GROUP_NAME", ''));
			props.setSelectedBusinessFn([]);
			// dispatch(getShiftListData('null'));
			dispatch(resetShiftListData());
			props.showRightSideFun(false) 
		  };
	return (
    <Row className="margin-b-15">
      <Col lg={8} md={6} sm={12} xs={12}>
      <CommonHeaderMenu active="Shift"/>
      </Col>
     
      <Col lg={4} md={6} sm={12} xs={12}>
        <div className="paHedFilter">
		<Field
            id="header_group"
            name="BUSINESS_FUNCTION"
            component={onCallDropdownPlusTypeahead}
            className="form-control"
            options={props.onCallGroupList}
            selectedOptions={props.selectedBusinessFn}
            onSelection={setBusinessFn}
            errorColor={props.businessFnError}
            diableStatus={false}
            onInputChange={businessFnInputChange}
            onCrossClick={onCrossClickBusinessFn}
            inputValue={typedBusinessFn}
            setErrorColor={props.setBusinessFnErrorColor}
            labelKey={"groupName"}
			      placeholder={translator["Select Group"]}
          />
          {props.isFilterApplied && (
            <Button
              bsPrefix=" "
              onClick={() => props.resetFilters()}
              role="button"
              className="myBt"
              title="Reset Filters"
            >
              <LuFilterX />
            </Button>
          )}
          <Link
            to="/onCallSchedule/createShift"
            role="button"
            className="myBt plus fillBtn"
            title="Create"
          >
            <HiPlus />
          </Link>
          <Button
            bsPrefix=" "
            title="Refresh"
            role="button"
            className="myBt"
            onClick={() => {
              props.showRightSideFun(false);
              refreshPage();
            }}
          >
            <HiOutlineRefresh className="refreshFlip" />
          </Button>
        </div>
      </Col>
    </Row>
  );
}
const mapStateToProps = (state) => ({
	onCallGroupList:state.onCallGroupList?state.onCallGroupList.map(list=>{ return {...list,label:list.groupName,id:list.groupId}}):[], 
  });
  
  export default reduxForm({
	form: 'ShiftListHeader', 
  })(connect(mapStateToProps)(ShiftListHeader));