
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Row, Col, Form, Container } from 'react-bootstrap';

import Breadcrumbs from '../common/header/breadcrumbs';
import MySurveyHeader from './MySurveyHeader';
import MySurveyList from './MySurveyList.js';
import MySurveyActions from './MySurveyActions.js';
import MySurveyActionsBrk from './MySurveyActionsBrk.js';
// import XsmReviewsActionsNoRender from './xsmReviewsActionsNoRender.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadBreakFixActivityLogDetails } from '../../actions/breakFix/breakFixActivityLogAction';
import { getTranslation } from '../../actions/spcmActions';
import PindCategories from '../common/header/pind-categories';
import { loadReviewOrderList, loadPendingReviewOrderList } from '../../actions/reviews/reviewAction';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import "_Css/common/_table.scss";
import "_Css/form/_form.scss";

class MySurveyIndex extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			subMenu: '0',
			mobileLeftMenuFilter: false,
			showHideAlfy: false,
			showHideRightNav: false,
			reviewQuickViewOrderId: '',
			reviewModuleValue: '',
			reviewServiceName: '',
			reviewOrderNumber: '',
			submittedDate: '',
			reviewDisplayNumber: '',
			itemId: '',
			checkedYes: '',
			checkedStatus: '',
			offeringId: '',
			isReopenEnabled: '',
			isCloseEnabled: '',
			reviewReqType: '',
			reviewStatus: '',
			reviewItemStatus: '',
			listSize: 10,
			listFilter: 'requesterId',
			leftSideSelectedOrder: ['All'],
			leftSideSelectedStatus: ['Pending'],
			listSortValue: 'desc',
			listPageNumberValue: 0,
			isAutoClosed: '',
			itemStatus: '',
			feedbackSubmitted: '',
			orderNumber: '',
			itemNumber: '',
			rowIndex: 0,
			filterRerender: true,
			serviceBased: '',
			sortOrder: "desc",
			sortOnColumn: "displayNumber",
			searchKey: "",
			searchValue: "",
			status: "N",
			pattern: "",
			isSelf: true,
			isClearAllFilter: false,
			displayNumberSearchInput: "",
			ciServiceNameSearchInput: "",
			requestedBySearchInput: "",
			startDate: {},
			endDate: {},
			selectedValue: [],
			isRightSideVisible: false,
			panelResize: "",
			isStatusOpen: false,
		};
		this.showHideAlfy = this.showHideAlfy.bind(this);
		this.showHideRightNav = this.showHideRightNav.bind(this);
		this.getOrderDetails = this.getOrderDetails.bind(this);
		this.getOrderType = this.getOrderType.bind(this);
		this.getServiceName = this.getServiceName.bind(this);
		this.getReviewOrderNumber = this.getReviewOrderNumber.bind(this);
		this.getReviewDisplayNumber = this.getReviewDisplayNumber.bind(this);
		this.getFlagForReopen = this.getFlagForReopen.bind(this);
		this.loadBreakFixActivityLogDetailsAction = this.loadBreakFixActivityLogDetailsAction.bind(this);
		this.getCheckedStatus = this.getCheckedStatus.bind(this);
		this.getOfferingId = this.getOfferingId.bind(this);
		this.getOrderNumber = this.getOrderNumber.bind(this);
		this.getItemNumber = this.getItemNumber.bind(this);
		this.getIsReopenEnabled = this.getIsReopenEnabled.bind(this);
		this.getIsCloseEnabled = this.getIsCloseEnabled.bind(this);
		this.reviewFilterDetail = this.reviewFilterDetail.bind(this);
		this.saveListSize = this.saveListSize.bind(this);
		this.reviewListFilter = this.reviewListFilter.bind(this);
		this.changeleftSideSelectedOrder = this.changeleftSideSelectedOrder.bind(this);
		this.changeleftSideSelectedStatus = this.changeleftSideSelectedStatus.bind(this);
		this.listSort = this.listSort.bind(this);
		this.listPageNumber = this.listPageNumber.bind(this);
		this.getIsAutoClosed = this.getIsAutoClosed.bind(this);
		this.loadHome = this.loadHome.bind(this);
		this.showActiveRow = this.showActiveRow.bind(this);
		this.isFilterRerender = this.isFilterRerender.bind(this);
		this.setSortOrder = this.setSortOrder.bind(this);
		this.setSearchKeyValue = this.setSearchKeyValue.bind(this);
		this.updateParameter = this.updateParameter.bind(this);
		this.isSelfFun = this.isSelfFun.bind(this);
		this.isClearAllFilterFunc = this.isClearAllFilterFunc.bind(this);
		this.setdisplayNumberInputValues = this.setdisplayNumberInputValues.bind(this);
		this.setCiServiceNameInputValues = this.setCiServiceNameInputValues.bind(this);
		this.setRequestedByInputValues = this.setRequestedByInputValues.bind(this);
		this.setSelectedValue = this.setSelectedValue.bind(this);
		this.setRef = this.setRef.bind(this);
		this.showRightSide = this.showRightSide.bind(this);
		this.multiSelectOpenFun = this.multiSelectOpenFun.bind(this);
	}

	showRightSide(value) {
		this.setState({ isRightSideVisible: value });
	}

	multiSelectOpenFun(keyName, val) {
		switch (keyName) {
		  case "mySurvyStatus":
			this.setState({
			  isStatusOpen: val,
			});
			break;
		}
	  }

	isSelfFun() {
		let filterVal;
		{ this.state.isSelf == true ? filterVal = "createdBy" : filterVal = "requesterId"; }
		this.setState({ isRightPanel: false });
		this.setState({ displayNumberSearchInput: "" });
		this.setState({ ciServiceNameSearchInput: "" });
		this.setState({ requestedBySearchInput: "" });
		this.setState({ searchKey: "" });
		this.setState({ searchValue: "" });
		this.setState({ status: "N" });
		this.setState({ pattern: "" });
		this.setState({ selectedValue: [] });
		this.setState({ isClearAllFilter: false });
		this.setState({ isRightSideVisible: "" });
		this.setState({ isSelf: !this.state.isSelf });
		this.props.loadReviewOrderList("", "Breakfix,Item", "N", "displayNumber", "desc", filterVal, "", "", "", 0, 10, !this.state.isSelf);
		// this.props.loadPendingReviewOrderList("", "Breakfix,Item", "N", "displayNumber", "desc", filterVal, "", "", "", 0, 10, !this.state.isSelf);
	}

	setRef(ref) {
		this.inputRef = ref;
	}
	setSelectedValue(value) {
		this.setState({ selectedValue: value });
	}

	isClearAllFilterFunc(value) {
		this.setState({ isClearAllFilter: value });
	}

	setdisplayNumberInputValues(displayNumber) {
		this.setState({
			displayNumberSearchInput: displayNumber,
		});
	}

	setCiServiceNameInputValues(ciServiceName) {
		this.setState({
			ciServiceNameSearchInput: ciServiceName,
		});
	}

	setRequestedByInputValues(requestedBy) {
		this.setState({
			requestedBySearchInput: requestedBy,
		});
	}

	isFilterRerender(val) {
		this.setState({ filterRerender: val });
	}

	listPageNumber(page) {
		this.setState({ listPageNumberValue: page });
	}

	listSort(value) {
		this.setState({ listSortValue: value });
	}

	changeleftSideSelectedOrder(value) {
		let newVal = this.state.leftSideSelectedOrder.includes(value) ? "" : value;
		if (newVal == "") return false;
		else this.state.leftSideSelectedOrder.push(newVal);
		this.setState({
			leftSideSelectedOrder: value,
			listSortValue: 'desc',
			listPageNumberValue: 0
		});
	}
	changeleftSideSelectedStatus(value) {
		this.setState({
			leftSideSelectedStatus: value,
			listSortValue: 'desc',
			listPageNumberValue: 0
		});
	}

	reviewFilterDetail(reviewReqTypeData, itemStatusData, reviewStatusData) {
		this.setState({
			reviewReqType: reviewReqTypeData,
			reviewItemStatus: itemStatusData,
			reviewStatus: reviewStatusData,
			listSize: 10
		});
	}

	reviewListFilter(filterData) {
		this.setState({
			listFilter: filterData,
			leftSideSelectedOrder: 'All',
			leftSideSelectedStatus: 'Pending',
			listSortValue: 'desc',
			listPageNumberValue: 0
		});
	}

	saveListSize(pageSize) {
		this.setState({
			listSize: pageSize
		});
	}

	mobileLeftMenuFilter() {
		this.setState({ mobileLeftMenuFilter: !this.state.mobileLeftMenuFilter });
	}

	getIsReopenEnabled(val) {
		this.setState({
			isReopenEnabled: val
		});
	}
	getIsAutoClosed(val, itemstatus, feedbackSubmitted) {
		this.setState({
			isAutoClosed: val,
			itemStatus: itemstatus,
			feedbackSubmitted: feedbackSubmitted
		});
	}

	getIsCloseEnabled(val) {
		this.setState({
			isCloseEnabled: val
		});
	}

	getOfferingId(offeringId) {
		this.setState({
			offeringId: offeringId
		});
	}
	getOrderNumber(orderNumber) {
		this.setState({
			orderNumber: orderNumber
		});
	}
	getItemNumber(itemNumber) {
		this.setState({
			itemNumber: itemNumber
		});
	}
	getCheckedStatus(checkedStatus) {
		if (checkedStatus == "") {
			this.setState({
				checkedStatus: ""
			});
		} else {
			this.setState({
				checkedStatus: checkedStatus
			});
		}
	}
	showHideAlfy(value) {
		this.setState({ showHideAlfy: value });
	}
	showHideRightNav(value) {
		this.setState({ showHideRightNav: !this.state.showHideRightNav });
	}

	getFlagForReopen(flag) {
		this.setState({
			checkedYes: flag
		});
		//alert(this.state.checkedYes);
	}

	loadBreakFixActivityLogDetailsAction(breakFixActualItemId) {
		this.props.loadBreakFixActivityLogDetails(breakFixActualItemId, "desc", "Breakfix");
	}

	getOrderDetails(orderID) {
		//console.log("orderID"+orderID)
		this.setState({
			reviewQuickViewItemId: orderID
		});
		//console.log("this.state.quickViewOrderId"+this.state.reviewQuickViewOrderId);
	}

	getOrderType(moduleName) {
		//alert("moduleName"+moduleName);
		this.setState({
			reviewModuleValue: moduleName
		});
		//console.log("this.state.moduleValue:::"+this.state.reviewModuleValue);
	}
	getServiceName(sName, value) {
		//alert("moduleName"+moduleName);
		this.setState({
			reviewServiceName: sName,
			serviceBased: value
		});
		//console.log("this.state.moduleValue:::"+this.state.reviewServiceName);
	}

	getReviewOrderNumber(orderNumber, createdOn) {
		this.setState({
			reviewOrderNumber: orderNumber,
			submittedDate: createdOn
		});
		//console.log("this.state.moduleValue:::"+this.state.reviewOrderNumber)
	}

	getReviewDisplayNumber(displayNumber) {
		this.setState({
			reviewDisplayNumber: displayNumber
		});
	}

	showActiveRow(i) {
		this.setState({ rowIndex: i });
	}

	loadHome() {
		// this.props.navigate("/" + homepage);
	}

	setSortOrder(sortBy, sortOn) {
		this.setState({ sortOrder: sortBy });
		this.setState({ sortOnColumn: sortOn });

	}

	setSearchKeyValue(key, value, pattern) {
		this.setState({ searchKey: key });
		this.setState({ searchValue: value });
		this.setState({ pattern: pattern });
	}

	updateParameter(statusValues) {
		this.setState({ status: statusValues });
	}

	render() {
		console.log("isRightSideVisible", this.state.isRightSideVisible);
		let meta = this.props.myReviewOrdersListMeta;
		let size = meta && meta.size ? meta.size : 0;
		let currentPage = meta && meta.currentPage ? meta.currentPage : 0;
		let startLimit = (size * currentPage) + 1;

		let recordCount = meta && meta.recordCount ? meta.recordCount : 0;
		let endLimit = size * (currentPage + 1);
		let colWidth = "";
		this.state.isRightSideVisible == "" ? colWidth = 12 : colWidth = 8;
		return (
			<main>
				<div >
					<PindCategories />
					<Container fluid className="margin-b-15">
						<Row>
							<Col md={8} xs={9}>          
								<Breadcrumbs activePageName="My Surveys" />
							</Col>
							<Col md={4} xs={3}>
								{recordCount != 0 ? <div className="tabDatacount">{startLimit}-{endLimit > recordCount ? endLimit = recordCount : endLimit} of {recordCount}</div> : null}
							</Col>
						</Row>       
					</Container>
					<div className="container-fluid" bsClass="">
						<Form>
							{/* Support Header Section Start*/}
							<MySurveyHeader
								isSelf={this.state.isSelf}
								isSelfFun={this.isSelfFun}
								searchValue={this.state.searchValue}
								searchKey={this.state.searchKey}
								sortOrder={this.state.sortOrder}
								setSortOrder={this.setSortOrder}
								sortOnColumn={this.state.sortOnColumn}
								setSearchKeyValue={this.setSearchKeyValue}
								tr={this.props.tr}
								reviewListFilter={this.reviewListFilter}
								listFilter={this.state.listFilter}
								isFilterRerender={this.isFilterRerender}
								listSort={this.listSort}
								status={this.state.status}
								updateParameter={this.updateParameter}
								pattern={this.state.pattern}
								isClearAllFilter={this.state.isClearAllFilter}
								isClearAllFilterFunc={this.isClearAllFilterFunc}
								displayNumberSearchInput={this.state.displayNumberSearchInput}
								ciServiceNameSearchInput={this.state.ciServiceNameSearchInput}
								requestedBySearchInput={this.state.requestedBySearchInput}
								setdisplayNumberInputValues={this.setdisplayNumberInputValues}
								setCiServiceNameInputValues={this.setCiServiceNameInputValues}
								setRequestedByInputValues={this.setRequestedByInputValues}
								setSelectedValue={this.setSelectedValue}
								selectedValue={this.state.selectedValue}
								getOrderType={this.getOrderType}
								showRightSide={this.showRightSide}
								isRightSideVisible={this.state.isRightSideVisible}
								saveListSize={this.saveListSize}
							/>
							{/* Support Header Section end*/}

							<PanelGroup direction="horizontal">
								<Panel id="sidebar" minSize={33} order={1} defaultSize={this.state.isRightSideVisible ? 67 : 100} onResize={(size) => this.setState({ panelResize : size})} className={this.state.isRightSideVisible ? "isShowLeftPanel rwLeftPart" : "rwLeftPart"}>
									{/* Reviews List Section Start*/}
									<MySurveyList
										isSelf={this.state.isSelf}
										getOfferingId={this.getOfferingId}
										getOrderNumber={this.getOrderNumber}
										getIsReopenEnabled={this.getIsReopenEnabled}
										getIsCloseEnabled={this.getIsCloseEnabled}
										checkedStatus={this.state.checkedStatus}
										getFlagForReopen={this.getFlagForReopen}
										loadBreakFixActivityLogDetailsAction={
											this.loadBreakFixActivityLogDetailsAction
										}
										getItemNumber={this.getItemNumber}
										getReviewOrderNumber={this.getReviewOrderNumber}
										getReviewDisplayNumber={this.getReviewDisplayNumber}
										getOrderDetails={this.getOrderDetails}
										getOrderType={this.getOrderType}
										getServiceName={this.getServiceName}
										tr={this.props.tr}
										getIsAutoClosed={this.getIsAutoClosed}
										listPageNumberValue={this.state.listPageNumberValue}
										listPageNumber={this.listPageNumber}
										listSort={this.listSort}
										listSortValue={this.state.listSortValue}
										reviewListFilter={this.reviewListFilter}
										listFilter={this.state.listFilter}
										saveListSize={this.saveListSize}
										reviewReqType={this.state.reviewReqType}
										listSize={this.state.listSize}
										reviewItemStatus={this.state.reviewItemStatus}
										reviewStatus={this.state.reviewStatus}
										rowIndex={this.state.rowIndex}
										showActiveRow={this.showActiveRow}
										leftSideSelectedOrder={this.state.leftSideSelectedOrder}
										leftSideSelectedStatus={this.state.leftSideSelectedStatus}
										changeleftSideSelectedOrder={this.changeleftSideSelectedOrder}
										changeleftSideSelectedStatus={this.changeleftSideSelectedStatus}
										reviewFilterDetail={this.reviewFilterDetail}
										isFilterRerender={this.isFilterRerender}
										filterRerender={this.state.filterRerender}
										serviceBased={this.state.serviceBased}
										showRecordCount={this.showRecordCount}
										rowCount={this.state.rowCount}
										myReviewOrdersListMeta={this.props.myReviewOrdersListMeta}

										searchValue={this.state.searchValue}
										searchKey={this.state.searchKey}
										sortOrder={this.state.sortOrder}
										setSortOrder={this.setSortOrder}
										sortOnColumn={this.state.sortOnColumn}
										setSearchKeyValue={this.setSearchKeyValue}
										status={this.state.status}
										updateParameter={this.updateParameter}
										pattern={this.state.pattern}
										displayNumberSearchInput={this.state.displayNumberSearchInput}
										ciServiceNameSearchInput={this.state.ciServiceNameSearchInput}
										requestedBySearchInput={this.state.requestedBySearchInput}
										setdisplayNumberInputValues={this.setdisplayNumberInputValues}
										setCiServiceNameInputValues={this.setCiServiceNameInputValues}
										setRequestedByInputValues={this.setRequestedByInputValues}
										setSelectedValue={this.setSelectedValue}
										selectedValue={this.state.selectedValue}
										isClearAllFilter={this.state.isClearAllFilter}
										isClearAllFilterFunc={this.isClearAllFilterFunc}
										showRightSide={this.showRightSide}
										isRightSideVisible={this.state.isRightSideVisible}
										panelResize={this.state.panelResize}
										isStatusOpen={this.state.isStatusOpen}
										multiSelectOpenFun={this.multiSelectOpenFun}
									/>
									{/* Reviews List S ection End*/}
								</Panel>
								{this.state.isRightSideVisible ?
									<>
										<PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
										<Panel minSize={33} order={2} defaultSize={33}>
											<div className="stickyArea rBoxStyle">
												{/* Reviews Actions Section Start*/}
												{this.state.reviewModuleValue == "Breakfix" ? (
													<MySurveyActionsBrk
														tr={this.props.tr}
														isReopenEnabled={this.state.isReopenEnabled}
														isCloseEnabled={this.state.isCloseEnabled}
														offeringId={this.state.offeringId}
														orderNumber={this.state.orderNumber}
														itemNumber={this.state.itemNumber}
														offeringName={this.state.reviewServiceName}
														employeeNumber={this.state.employeeNumber}
														checkedStatus={this.state.checkedStatus}
														flagForReopen={this.state.checkedYes}
														reviewOrderNumber={this.state.reviewOrderNumber}
														submittedDate={this.state.submittedDate}
														reviewDisplayNumber={this.state.reviewDisplayNumber}
														reviewModuleValue={this.state.reviewModuleValue}
														quickViewItemId={this.state.reviewQuickViewItemId}
														serviceName={this.state.reviewServiceName}
														breakFixActivityLogDetails={
															this.props.breakFixActivityLogDetails
														}
														isAutoClosed={this.state.isAutoClosed}
														itemStatus={this.state.itemStatus}
														feedbackSubmitted={this.state.feedbackSubmitted}
														reviewListFilter={this.reviewListFilter}
														listFilter={this.state.listFilter}
														showActiveRow={this.showActiveRow}
														serviceBased={this.state.serviceBased}
														getOrderType={this.getOrderType}

														searchValue={this.state.searchValue}
														searchKey={this.state.searchKey}
														sortOrder={this.state.sortOrder}
														setSortOrder={this.setSortOrder}
														sortOnColumn={this.state.sortOnColumn}
														setSearchKeyValue={this.setSearchKeyValue}
														status={this.state.status}
														updateParameter={this.updateParameter}
														pattern={this.state.pattern}
														isClearAllFilter={this.state.isClearAllFilter}
														isClearAllFilterFunc={this.isClearAllFilterFunc}
														displayNumberSearchInput={this.state.displayNumberSearchInput}
														ciServiceNameSearchInput={this.state.ciServiceNameSearchInput}
														requestedBySearchInput={this.state.requestedBySearchInput}
														setdisplayNumberInputValues={this.setdisplayNumberInputValues}
														setCiServiceNameInputValues={this.setCiServiceNameInputValues}
														setRequestedByInputValues={this.setRequestedByInputValues}
														setSelectedValue={this.setSelectedValue}
														selectedValue={this.state.selectedValue}
														showRightSide={this.showRightSide}
														isRightSideVisible={this.state.isRightSideVisible}
														isSelf={this.state.isSelf}

													/>
												) : null}
												{this.state.reviewModuleValue == "Item" ? (
													<MySurveyActions
														tr={this.props.tr}
														isReopenEnabled={this.state.isReopenEnabled}
														isCloseEnabled={this.state.isCloseEnabled}
														offeringId={this.state.offeringId}
														orderNumber={this.state.orderNumber}
														itemNumber={this.state.itemNumber}
														offeringName={this.state.reviewServiceName}
														checkedStatus={this.state.checkedStatus}
														reviewOrderNumber={this.state.reviewOrderNumber}
														submittedDate={this.state.submittedDate}
														reviewDisplayNumber={this.state.reviewDisplayNumber}
														reviewModuleValue={this.state.reviewModuleValue}
														quickViewItemId={this.state.reviewQuickViewItemId}
														serviceName={this.state.reviewServiceName}
														reviewListFilter={this.reviewListFilter}
														listFilter={this.state.listFilter}
														isAutoClosed={this.state.isAutoClosed}
														itemStatus={this.state.itemStatus}
														feedbackSubmitted={this.state.feedbackSubmitted}
														showActiveRow={this.showActiveRow}
														serviceBased={this.state.serviceBased}
														getOrderType={this.getOrderType}

														searchValue={this.state.searchValue}
														searchKey={this.state.searchKey}
														sortOrder={this.state.sortOrder}
														setSortOrder={this.setSortOrder}
														sortOnColumn={this.state.sortOnColumn}
														setSearchKeyValue={this.setSearchKeyValue}
														status={this.state.status}
														updateParameter={this.updateParameter}
														pattern={this.state.pattern}
														isClearAllFilter={this.state.isClearAllFilter}
														isClearAllFilterFunc={this.isClearAllFilterFunc}
														displayNumberSearchInput={this.state.displayNumberSearchInput}
														ciServiceNameSearchInput={this.state.ciServiceNameSearchInput}
														requestedBySearchInput={this.state.requestedBySearchInput}
														setdisplayNumberInputValues={this.setdisplayNumberInputValues}
														setCiServiceNameInputValues={this.setCiServiceNameInputValues}
														setRequestedByInputValues={this.setRequestedByInputValues}
														setSelectedValue={this.setSelectedValue}
														selectedValue={this.state.selectedValue}
														showRightSide={this.showRightSide}
														isRightSideVisible={this.state.isRightSideVisible}
														isSelf={this.state.isSelf}
													/>
												) : null}
												{/* {this.state.reviewModuleValue == "" ? (
										<XsmReviewsActionsNoRender tr={this.props.tr} />
									) : null} */}

												{/* Reviews Actions Section End*/}
											</div>
										</Panel>
									</> : ""
								}
							</PanelGroup>
						</Form>
					</div>
				</div>
			</main>
		);
	}
}


// export default BreakFix;

export function mapStateToProps({ breakFixActivityLogDetails, spcmReducer, myReviewOrdersList }) {
	return {
		breakFixActivityLogDetails, lang: spcmReducer.lang, tr: spcmReducer.tr, myReviewOrdersListMeta: myReviewOrdersList == {} ? {} : myReviewOrdersList.meta,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ loadBreakFixActivityLogDetails, getTranslation, loadReviewOrderList, loadPendingReviewOrderList }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MySurveyIndex);
