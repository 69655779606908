
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export default (state={},action)=>{
    switch(action.type){
        case 'LOAD_WINDOW_SIZE': 
        return action.screenDetail;
        default: return state;
    }
}