
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { HiPlus, HiOutlineRefresh } from "react-icons/hi";
import Cookies from "universal-cookie";
import { loadDashboardList } from "../../actions/dashboardConfigration/dashboardConfigAction";

const cookies = new Cookies();
let cookieData = cookies.get("gph");
if (cookieData) cookieData = cookieData.replace("s:", "");
if (cookieData)
  cookieData = cookieData.substring(0, cookieData.lastIndexOf("."));
cookieData = cookieData.split("~");
let rolesArr = cookieData[33];

const DashboardListHeader = () => {
  const [access, setAccess] = useState();
  const translator = useSelector((state) => state.spcmReducer.tr);
  const dispatch = useDispatch();

  const refreshIntegrationHubTable = () => {
    dispatch(loadDashboardList());
  };

  useEffect(() => {
    if (rolesArr && rolesArr.length > 0) {
      let roles = rolesArr.split(",");
      if (roles.includes("67")) {
        setAccess(true);
      }
    }
  }, []);

  return (
    <Row className="margin-b-15">
      <Col md={8} sm={7} xs={12}>
        <h2 bsClass="" className="sPageHeading1">
          {translator["Superset Dashboard"]}
        </h2>
      </Col>
      <Col md={4} sm={5} xs={12} className="text-r">
        <div className="paHedFilter">
          {access ? (
            <Link
              role="button"
              className="myBt plus fillBtn"
              title={translator["Create"]}
              to="/createDashboardConfiguration"
            >
              <HiPlus />
            </Link>
          ) : (
            ""
          )}
          <Button
            title={translator["Refresh"]}
            role="button"
            bsPrefix=" "
            className="myBt"
            onClick={() => refreshIntegrationHubTable()}
          >
            <HiOutlineRefresh className="refreshFlip" />
          </Button>
        </div>
      </Col>
    </Row>
  );
};
export default DashboardListHeader;
