
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Cookies from 'universal-cookie';

import bg from '_Images/hcl-sx-logo-black.png';
import deniedimage from '_Images/accountpending.png'
import { openLinkInNewTab } from '../common/helper';

const cookies = new Cookies();
let userLogoutUrl = cookies.get('gph');
if (userLogoutUrl) userLogoutUrl = userLogoutUrl.replace('s:', '');
if (userLogoutUrl) userLogoutUrl = userLogoutUrl.substring(0, userLogoutUrl.lastIndexOf('.'));
userLogoutUrl = userLogoutUrl.split("~");
userLogoutUrl = userLogoutUrl[44];

let stylingObject = {
  logoAuth: { "backgroundImage": `url(${bg})`, "backgroundRepeat": "no-repeat", "backgroundPosition": "center", "backgroundSize": "contain", "display": "inline-block", "width": "130px", "height": "42px", "margin": "0px", "textIndent": "-9999px" }
};

class Logos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  redirectToLogin() {
    openLinkInNewTab("/authentication/logout/" + userLogoutUrl, "");
  }
  render() {
    return (
      <div style={{ marginTop: '-58px' }}>
        <div style={{ backgroundColor: '#eef2f5', height: '70px', borderBottom: '1px #d2d7f2 solid', marginBottom: '20px' }} />
        <div style={{ textAlign: 'center' }}>
          <div style={stylingObject.logoAuth}></div>
        </div>
        <div style={{ marginTop: '50px', textAlign: 'center', padding: '0px 20px' }}>
          <div><img height={47} width={52} src={deniedimage} /></div>
          <h1 style={{ margin: '0px', color: 'red', fontSize: '30px', fontWeight: 400, display: 'block' }}>Account Not Active</h1>
          <h3 style={{ fontSize: '17px', color: '#000', fontWeight: 300, margin: '10px 0px 30px' }}>Your account activation is in progress. You'll receive an email when your account is ready to use.</h3>
          <div style={{ textAlign: 'center', marginBottom: '30px' }}><button onClick={this.redirectToLogin} style={{ cursor: 'pointer', fontSize: '14px', color: '#fff', border: 'none', backgroundColor: '#3884b4', padding: '10px 20px', textTransform: 'uppercase' }}>Go back to login</button></div>
        </div>
      </div>
    );
  }
}

export default Logos;
