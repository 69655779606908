
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
// import { Row, Col, PageHeader, Nav, NavItem, Button, ListGroup, ListGroupItem } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import Nav from 'react-bootstrap/Nav';
import OnClickOutside from 'react-onclickoutside';
import { Field, reduxForm,formValueSelector,change } from 'redux-form';
import {GLOBAL} from '../../Globals';
import { connect } from 'react-redux';
import { checkRelatedTaskForItem } from '../../../actions/task/TaskDataAction';
import { loadFulfillmentDetails } from '../../../actions/fulfillment/fulfillmentDataAction';
import { loadStatusBasedHamburgerOptions } from '../../../actions/common/loadStatusBasedHamburgerOptions';
import { loadTimelineData } from '../../../actions/common/commonApisActions.js';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import swal from 'sweetalert';



class FulfillmentHamburger extends React.Component{

	constructor(props) {
		super(props);
		this.state={};
		this.currentAction = this.currentAction.bind(this);
		this.fullFillItem = this.fullFillItem.bind(this);
		// this.checkAssign = this.checkAssign.bind(this);
		this.renderHamburgerSOptions = this.renderHamburgerSOptions.bind(this);
	}

	componentWillMount() {
		this.props.checkRelatedTaskForItem(this.props.itemId, "Fullfilment");
	}

	fullFillItem(itemId) {
		let self = this;
		axios.patch(GLOBAL.fulfillmentpost + itemId, {
		  "itemStatus": "75",
		  "approvalStatus": "75",
		  "modifiedBy": "",
		  "modifiedByName": ""
		})
		.then(function (response) {
			// self.props.loadBreakFixAuditLogDetails(itemId, "dsc", "Fullfilment");
			self.props.loadFulfillmentDetails(itemId);
			self.props.loadStatusBasedHamburgerOptions('Item',response.data.status,response.data.itemId);
		})
		.catch(function (error) {
			console.log(error);
		});
	}

	// putItemOnHold(itemId) {
	// 	let self = this;
	// 	axios.patch(GLOBAL.fulfillmentpost + itemId, {
	// 		"itemStatus": "60",
	// 	})
	// 	.then(function (response) {
	// 		// self.props.loadBreakFixAuditLogDetails(itemId, "dsc", "Fullfilment");
	// 		self.props.loadFulfillmentDetails(itemId);
	// 		self.props.loadStatusBasedHamburgerOptions('Item',response.data.status,response.data.itemId);
	// 	})
	// 	.catch(function (error) {
	// 		console.log(error);
	// 	});
	// }

	putUnderFulfillment(itemId) {
		let self = this;
		axios.patch(GLOBAL.fulfillmentpost + itemId, {
		  "itemStatus": "70",
		  "modifiedBy": "",
		  "modifiedByName": ""
		})
		.then(function (response) {
			// self.props.loadBreakFixAuditLogDetails(itemId, "dsc", "Fullfilment");
			self.props.loadFulfillmentDetails(itemId);
			self.props.loadStatusBasedHamburgerOptions('Item',response.data.status,response.data.itemId);
		})
		.catch(function (error) {
			console.log(error);
		});
	  }

	currentAction(enableButton) {
		// alert(enableButton +' called');
		switch(enableButton) {
			case 'Approvals':
				this.props.showApprovals();
				break;
			case 'Provide Attachments':
				this.props.showProvideAttachment();
				break;
			case 'Notify':
				this.props.showNotify();
				break;
			case 'Cancel Request':
				if ((this.props.user_id == this.props.fulfillmentDetails.asgUserid) && (this.props.roleIdentification('43') || this.props.roleIdentification('44') || this.props.roleIdentification('48'))) {
					this.props.showCancel();
				} else {
					swal({
						text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
						button: this.props.translator['OK']
					});
				}
				break;
			case 'Put on Hold':
				if ((this.props.user_id == this.props.fulfillmentDetails.asgUserid) && (this.props.roleIdentification('43') || this.props.roleIdentification('44') || this.props.roleIdentification('48'))) {
					this.props.showPutOnHold();
				} else {
					swal({
						text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
						button: this.props.translator['OK']
					});
				}
				break;
			case 'Put Under Fulfillment':
				if ((this.props.user_id == this.props.fulfillmentDetails.asgUserid) && (this.props.roleIdentification('43') || this.props.roleIdentification('44') || this.props.roleIdentification('48'))) {
					this.putUnderFulfillment(this.props.fulfillmentDetails.itemId);
				} else {
					swal({
						text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
						button: this.props.translator['OK']
					});
				}
				break;
			case 'Fulfill it':
				if ((this.props.user_id == this.props.fulfillmentDetails.asgUserid) && (this.props.roleIdentification('43') || this.props.roleIdentification('44') || this.props.roleIdentification('48'))) {
					this.props.showfulfill();
				} else {
					swal({
						text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
						button: this.props.translator['OK']
					});
				}
				break;
			case 'Reopen':
				// if ((this.props.user_id == this.props.fulfillmentDetails.asgUserid) && (this.props.roleIdentification('43') || this.props.roleIdentification('44') || this.props.roleIdentification('48')))
				this.props.showReopen();
				// else {
				// swal({
				// text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
				// button: this.props.translator['OK']
				// });
				// }
				break;
		}
	}


	handleClickOutside(){
		this.props.fun(false);
	}

	renderHamburgerSOptions(statusBasedHamburgerOptions, profile) {
		// console.log('---------statusBasedHamburgerOptions---------');
		// console.log(statusBasedHamburgerOptions);
		const { fulfillmentDetails: { status = "" } = {} } = this.props;
		if(statusBasedHamburgerOptions.length == 0){
			return(
					null
			);
		}
		const isApprovalObjExist = statusBasedHamburgerOptions.find(item => item.enableButton == 'Approvals');
		if(profile.roleName.includes('Approver') && !isApprovalObjExist){
			statusBasedHamburgerOptions.push({action: 'S', enableButton: 'Approvals'});
		}
		return statusBasedHamburgerOptions.map((item,index)=>{
			// console.log('hamburger item',item);
			if(item.action !== 'L'){
				if(item.action == 'S'){
					return(
						<ListGroup.Item bsClass="" key={index}><Nav.Item title={item.enableButton} onClick={()=>{this.currentAction(item.enableButton); this.handleClickOutside(); this.props.rightEditPanel(true);}}><i className="fa fa-angle-double-right" ></i>{item.enableButton}</Nav.Item></ListGroup.Item>
					);
				}
			}
		});
	}

	renderHamburgerGOptions(statusBasedHamburgerOptions) {
		// console.log('---------statusBasedHamburgerOptions---------');
		// console.log(statusBasedHamburgerOptions);
		if(statusBasedHamburgerOptions.length == 0){
			return(
					null
			);
		}

		return statusBasedHamburgerOptions.map((item,index)=>{
			// console.log('hamburger item',item);
			if(item.action !== 'L'){
				if(item.action == 'G'){
					if (this.props.fieldStatus.provideAttachments == false && item.enableButton == "Provide Attachments" || this.props.fieldStatus.notify == false && item.enableButton == "Notify" ) {
						// alert("123",item.enableButton);
						item.enableButton = "blank";
					}
					return(
						<ListGroup.Item bsClass="" key={index}><Nav.Item title={item.enableButton} onClick={()=>{this.currentAction(item.enableButton); this.handleClickOutside(); this.props.rightEditPanel(true);}}>{item.enableButton == "blank" ? null : <i className="fa fa-angle-double-right" ></i>}{item.enableButton == "blank" ? null : item.enableButton}</Nav.Item></ListGroup.Item>
					);
				}
			}
		});
	}

	// checkAssign()
	// {
	// 	if((this.props.user_id!=this.props.fulfillmentDetails.asgUserid) && (this.props.roleIdentification('43') || this.props.roleIdentification('44') || this.props.roleIdentification('48')))
	// 	   swal({
	// 		text:this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
	// 		button: this.props.translator['OK']
	// 	   })
	// }

	render(){
		// console.log('*******', this.props);
		// console.log('=============',this.props.statusBasedHamburgerOptions);
		return(
				// <div className="breakFixHamburger">
				// 	<ListGroup bsClass="" className="myLinks">
				// 		<ListGroupItem bsClass=""><NavItem title={this.props.translator['Provide Attachments']} onClick={()=>{this.props.showProvideAttachment()}}><i className="fa fa-angle-double-right" ></i>{this.props.translator['Provide Attachments']}</NavItem></ListGroupItem>
				// 		<ListGroupItem bsClass=""><NavItem title={this.props.translator['Notify']} onClick = {()=> {this.props.showNotify()}}><i className="fa fa-angle-double-right" ></i>{this.props.translator['Notify']}</NavItem></ListGroupItem>
				// 		{this.props.status == 'Under Fulfillment' || this.props.status == 'Pending Approval' || this.props.status == 'On Hold' ? <ListGroupItem bsClass=""><NavItem title={this.props.translator['Cancel']} onClick={()=>{this.props.showCancel()}}><i className="fa fa-angle-double-right" ></i>{this.props.translator['Cancel']}</NavItem></ListGroupItem> : ''}
				// 	</ListGroup>
				// </div>
				<div className="breakFixHamburger statusHamburger">
					<ListGroup bsClass="" className="myLinks" >
						{this.renderHamburgerSOptions(this.props.statusBasedHamburgerOptions, this.props.profileDetails)}
						{/* <ListGroupItem bsClass=""><span className="HamburgerGap">&nbsp;</span></ListGroupItem>
						{this.renderHamburgerGOptions(this.props.statusBasedHamburgerOptions)} */}
					</ListGroup>
				</div>
		);
	}
}
FulfillmentHamburger = OnClickOutside(FulfillmentHamburger);

export function mapStateToProps({statusBasedHamburgerOptions, realatedTaskCount, profileDetails}){
	return {
		statusBasedHamburgerOptions,
		realatedTaskCount,
    profileDetails
	};
}

function mapDispatchToProps(dispatch){
	return bindActionCreators({checkRelatedTaskForItem, loadFulfillmentDetails, loadStatusBasedHamburgerOptions, loadTimelineData}, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(FulfillmentHamburger);

// export default FulfillmentHamburger;
