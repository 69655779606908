
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/

export const queryBuilderAllRulesData = (state = [], action) => {
    switch(action.type) {
        case 'QUERYBUILDER_ALLRULES_SUCCESS':
            return action.payload;
        default:
            return state;
    }
};

export const queryBuilderData = (state = [], action) => {
    switch(action.type) {
        case 'QUERYBUILDER_SUCCESS':
            return action.payload;
        default:
            return state;
    }
};

export const queryBuilderChildData = (state = [], action) => {
    switch(action.type) {
        case 'QUERYBUILDER_CHILD_LOAD_SUCCESS':
            return action.payload;
        default:
            return state;
    }
};

export const queryBuilderChildAttributes = (state = [], action) => {
    switch(action.type) {
        case 'QUERYBUILDER_CHILD_ATTRIBUTES_SUCCESS':
            return action.payload;
        default:
            return state;
    }
};

export const postQueryBuilderData = (state = [], action) => {
    switch(action.type) {
        case 'QUERYBUILDER_POST_QUERY_LOAD_SUCCESS':
            return action.payload;
        default:
            return state;
    }
};

export const amsEditRuleConfiguration = (state = [],action) => {
    switch (action.type) {
      case 'GET_EDIT_RULE_CONFIGURATION_SUCCESS':
        return action.editRuleConfigurationDetails;
      default:
        return state;
    }
  };

