
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const extendSelectTraits = (e) => {
    e.DomComponents.addType("form", {
      model: e.DomComponents.getType("form").model.extend({
        init() {
          const traits = this.get("traits");
          traits.add([
            {
              type: "select",
              label: "Data Source",
              name: "data_source",
              changeProp: 1,
              options: []
            }
          ]);
          traits.remove(['action','method'])
        },
      }),
      //view: e.DomComponents.getType("button").view,
    });
  };
  