
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import {Row, Col, Button,Form, ListGroup,ButtonToolbar } from "react-bootstrap";
import { useDispatch, useSelector, connect } from "react-redux";
import { Field, reduxForm, formValueSelector, change, SubmissionError, reset } from "redux-form";
import {_passwordField, _inputField, _dropDown, _checkbox, _number } from "../../../common/formFields";
import moment from "moment";
import Cookies from "universal-cookie";
import { _dateTimeField, _dateTimeFieldGeneral } from "../../../common/ReduxFormFields/formFields";
import onCallDropdownPlusTypeahead from "../../common/onCallDropdownPlusTypeahead";
import { onCallGroupUsersByGroupId } from "../../../../actions/onCallSchedule/common/onCallCommonAction";
import { IoClose } from "react-icons/io5";
import { loadShiftListByGroup } from "../../../../actions/onCallSchedule/calendar/onCallCalendarAction";
import { loadProfileTimeZone } from "_Actions/myProfile/profileTimeZoneAction";
import Swal from "sweetalert2";
import axios from "axios";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';
const cookies = new Cookies();
let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:","");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
  dateformat = dateformat.split("~");
  dateformat = dateformat[2];
  dateformat = dateformat.slice(0, 10).toUpperCase();

const validate = (values) => {
  const errors = {};
  if (!values.from) {
    errors.from = true;
  }
  if (!values.to) {
    errors.to = true;
  }
  return errors;
};

const SetOutOfOffice = (props) => {
  const dispatch = useDispatch();
  const translator = useSelector((state) => state.spcmReducer.tr);
  const [userErrorState, setUserErrorState] = useState(false);
  const [selectedUserList, setSelectedUserList] = useState([]);
  const [userInputValue, setUserInputValue] = useState("");
  const [userListOptions, setUserListOptions] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [timeZoneSelectedList, setTimeZoneSelectedList] = useState([]);
  // const [inputValueTimeZone, setInputValueTimeZone] = useState("");
  const [fullDayLeave, setFullDayLeave] = useState("false");
  const { handleSubmit, error } = props;

  const userList = useSelector((state) => state.onCallGroupUsersList);
  // // let timeZoneDetails = useSelector((state) => state.timeZoneDetails);
  // let timeZoneTypeaheadOptions = [];
  // if (timeZoneDetails && timeZoneDetails.length > 0) {
  //   timeZoneDetails.forEach((item, i) => {
  //     let obj = {};
  //     obj.id = item.VALUE;
  //     obj.label = item.VALUE;
  //     timeZoneTypeaheadOptions.push(obj);
  //   });
  // }
  const context = useContext(MyContext);
  const closeButton = useRef(null);

  useEffect(() => {
      context.keyDownHandler(closeButton.current,"","","");
  }, []);

  useEffect(() => {
    if (userList && userList.length > 0) {
      let userListFields = [];
      userList.forEach((list) => {
        userListFields.push({ id: list.field1Value, label: list.field1Key });
      });
      setUserListOptions(userListFields);
    }
  }, [userList]);

  useEffect(() => {
    if (props.selectedGroupList && props.selectedGroupList.length > 0) {
      dispatch(loadShiftListByGroup(props.selectedGroupList[0].id));
    } else {
      setUserListOptions([]);
      dispatch({
        type: "ON_CALL_CALENDER_SHIFT_LIST_DATA_BY_GROUP",
        events: { data: [] },
      });
    }
  }, [props.selectedGroupList]);

  useEffect(() => {
    dispatch(loadProfileTimeZone());
    dispatch(change("SetOutOfOffice", "from", moment()));
    dispatch(change("SetOutOfOffice", "to", moment()));
    dispatch(change("SetOutOfOffice", "user", ""));
    dispatch(change("SetOutOfOffice", "timezone", ""));
  }, []);

  // function onTimeZoneSelection(selectedTimeZone) {
  //   props.setTimeZoneErrorState("");
  //   setTimeZoneSelectedList(selectedTimeZone);
  //   if (selectedTimeZone.length > 0) {
  //     dispatch(change("SetOutOfOffice", "timezone", selectedTimeZone[0].id));
  //     setInputValueTimeZone(selectedTimeZone[0].label);
  //   } else {
  //     dispatch(change("SetOutOfOffice", "timezone", ""));
  //   }
  // }

  // function setTimeZoneErrorColor() {
  //   if (timeZoneSelectedList.length === 0) props.setTimeZoneErrorState("error");
  //   else props.setTimeZoneErrorState("");
  // }

  // function onTimeZoneInputChange(val) {
  //   setInputValueTimeZone(val);
  // }

  // function onCrossClickTimeZone() {
  //   dispatch(change("SetOutOfOffice", "timezone", ""));
  //   setInputValueTimeZone("");
  //   setTimeZoneSelectedList([]);
  //   props.setTimeZoneErrorState("error");
  // }

  const onUserSelection = (event) => {
    if (event && event[0]) {
      setUserInputValue(event[0].label);
      dispatch(change("SetOutOfOffice", "user", event[0].label));
    } else {
      dispatch(change("SetOutOfOffice", "user", ""));
    }
    setSelectedUserList(event);
  };

  const setCompanyError = () => {
    if (selectedUserList.length === 0) {
      setUserErrorState(true);
    } else {
      setUserErrorState(false);
    }
  };

  const onUserInputChange = (value) => {
    if (value) {
      setUserInputValue(value);
    } else {
      setUserInputValue("");
      onUserCrossClick();
    }
  };

  const onUserCrossClick = () => {
    setSelectedUserList([]);
    setUserInputValue("");
  };

  const handleChange = (e) => {
    if (e.target.name === "fullDayLeave") {
      e.target.checked ? setFullDayLeave("true") : setFullDayLeave("false");
    }
  };

  const validateFields = (values) => {
    let error = false;

    if (!values.user) {
      setCompanyError();
      error = true;
    }
    return error;
  };

  const formSubmit = (values) => {
    if (validateFields(values)) {
      throw new SubmissionError({
        _error:
          "The form has missing required fields, please revalidate the highlighted fields and update the form",
      });
    }
    if (values.from.format("X") > values.to.format("X")) {
      Swal.fire("To should be greater than from!");
      return;
    }

    if (moment(values.from.format("YYYY-MM-DD")).format("X") < moment(moment().format("YYYY-MM-DD")).format("X")) {
      Swal.fire("Past dates are not allowed!");
      return;
    }
    let postJson = {};

    postJson["userId"] = selectedUserList[0].id;
    postJson["userFullName"] = selectedUserList[0].label;
    postJson["is24by7"] = fullDayLeave == "true" ? true : false;
    postJson["scheduleType"] = "Out of Office";
    postJson["fromDate"] = values.from.format("x");
    postJson["toDate"] = values.to.format("x");
    postJson["companyId"] = props.selectedGroupList[0].companyId;
    postJson["companyName"] = props.selectedGroupList[0].companyName;
    setIsButtonDisabled(true);
    axios
      .post("/rest/aiosem/saveUserOOOAction", postJson)
      .then((shiftAssigned) => {
        if (!shiftAssigned) {
          throw Error("Watcher details not found.");
        }
        return shiftAssigned;
      })
      .then((shiftAssigned) => {
        Swal.fire({
          title: "User has been set to out of office successfully!",
        });
        props.resetFilters();
        props.showRightSideFun("");
        setIsButtonDisabled(true);
      })
      .catch((error) => {
        setIsButtonDisabled(false);
        Swal.fire({ title: error.message });
      });
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
            <ListGroup bsPrefix=" " as="ul">
              <ListGroup.Item as="li" bsPrefix=" ">
                <Link
                  to="javascript:void(0)"
                  bsPrefix=" "
                  title={translator["Minimize the details panel"]}
                  onClick={() => {
                    props.showRightSideFun("");
                  }}
                  ref={closeButton}
                >
                  <IoClose />
                </Link>
              </ListGroup.Item>
            </ListGroup>
          </div>
          <div className="rPageHeading">
            <div className="offNam margin-t-5 margin-b-5">
              {"Set Out Of Office"}
            </div>
          </div>
        </Col>
      </Row>
      <div className="rBoxGap">
        <div className="rBoxTabs">
          <div>
            <font color="red">{error && <strong>{error}</strong>}</font>
          </div>
          <Row>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>
                  <span className="rStar"></span>
                  {"User"}
                </Form.Label>
                <Field
                  component={onCallDropdownPlusTypeahead}
                  errorColor={userErrorState ? "error" : ""}
                  onSelection={onUserSelection}
                  setErrorColor={setCompanyError}
                  name="user"
                  id="user"
                  className="form-control"
                  options={userListOptions}
                  selectedOptions={selectedUserList}
                  onInputChange={onUserInputChange}
                  inputValue={userInputValue}
                  onCrossClick={onUserCrossClick}
                />
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label style={{ width: "100%" }}>&nbsp;</Form.Label>
                <Field
                  name="fullDayLeave"
                  component={_checkbox}
                  className="form-control"
                  label="Full day leave"
                  onChange={handleChange}
                  checked={fullDayLeave == "true"}
                />
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>
                  <span className="rStar"></span>
                  {"From"}
                </Form.Label>
                <div className="dateTimeSdiv">
                  <Field
                    name="from"
                    component={
                      fullDayLeave !== "true"
                        ? _dateTimeField
                        : _dateTimeFieldGeneral
                    }
                    formType={
                      fullDayLeave !== "true" ? "createOutOfOffice" : "onCallSchedule"
                    }
                    className="form-control dateTimePickerField"
                  />
                </div>
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>
                  <span className="rStar"></span>
                  {"To"}
                </Form.Label>
                <div className="dateTimeSdiv dateTimePosLeft">
                  <Field
                    name="to"
                    component={
                      fullDayLeave !== "true"
                        ? _dateTimeField
                        : _dateTimeFieldGeneral
                    }
                    formType={
                      fullDayLeave !== "true" ? "createOutOfOffice" : "onCallSchedule"
                    }
                    className="form-control dateTimePickerField"
                  />
                </div>
              </Form.Group>
            </Col>
            {/* <Col md={12}>
              <Form.Group className="form-group">
                <Form.Label>
                  <span className="rStar"></span>
                  {translator["Timezone"]}
                </Form.Label>
                <Field
                  component={onCallDropdownPlusTypeahead}
                  errorColor={props.timeZoneErrorState}
                  onSelection={onTimeZoneSelection}
                  setErrorColor={setTimeZoneErrorColor}
                  name="timeZone"
                  id="timeZone"
                  className="form-control"
                  options={timeZoneTypeaheadOptions}
                  selectedOptions={timeZoneSelectedList}
                  onInputChange={onTimeZoneInputChange}
                  inputValue={inputValueTimeZone}
                  onCrossClick={onCrossClickTimeZone}
                />
              </Form.Group>
            </Col> */}
          </Row>
          <Row>
            <Col md={12}>
              <ButtonToolbar>
                <Button
                  onClick={handleSubmit(formSubmit)}
                  bsSize="xsmall"
                  className="rgSidrkBtn smallBtn"
                  title={translator["Add"]}
                  disabled={isButtonDisabled}
                >
                  {translator["Add"]}
                </Button>
                <Button
                  onClick={() => {
                    props.showRightSideFun("");
                  }}
                  bsSize="xsmall"
                  className="rgSiLigBtn smallBtn"
                  title={translator["Cancel"]}
                  disabled={isButtonDisabled}
                >
                  {translator["Cancel"]}
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default reduxForm({
  form: "SetOutOfOffice",
  destroyOnUnmount: true,
  validate,
  enableReinitialize: true,
})(SetOutOfOffice);
