
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef } from "react";
import { Row, Col, Accordion, Tab, Tabs, ListGroup, Button } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {PiPencilSimpleBold} from "react-icons/pi";
import {IoClose} from "react-icons/io5";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';

const ShiftListShortDesc = (props) => {
    const navigate = useNavigate();
    const tr = useSelector((state) => state.spcmReducer.tr);
    const context = useContext(MyContext);
    const closeButton = useRef(null);
    const editButton = useRef(null);

    useEffect(() => {
        context.keyDownHandler(closeButton.current,editButton.current,"","");
        context.newTabPrevent();
    }, []);

    return (
        console.log("props.....",props),
        <>
            <Row>
                <Col xs={12}>
                    <div className="rPageHeadActBtn" style={{ "marginTop": "-3px" }}>
                        <ListGroup bsPrefix=" " as="ul">
                            <ListGroup.Item as="li" bsPrefix=" "><Link bsPrefix=" " to={`/onCallSchedule/editShift/${props.selectedItem.shiftId}`} title={tr["Edit"]} ref={editButton} className="editIcn ctrlKeyPrevent"><PiPencilSimpleBold/></Link></ListGroup.Item>
                            <ListGroup.Item as="li" bsPrefix=" "><Link to="javascript:void(0)" bsPrefix=" " title={tr["Minimize the details panel"]} ref={closeButton} className="ctrlKeyPrevent" onClick={() => { props.showRightSideFun(false) }}><IoClose/></Link></ListGroup.Item>
                        </ListGroup>
                    </div>
                    <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{["Shift Details"]}</div></div>
                </Col>
            </Row>
            <div className="rBoxGap">
                <div className="rBoxTabs">
                    <ListGroup as="ul" bsPrefix=" ">
                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable" style={{ "borderTop": "none" }}><span className="rwKey">{tr["Shift"]}</span><span className="rwVal">{props.selectedItem.shiftName}</span></ListGroup.Item>
                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Created On"]}</span><span className="rwVal">{props.selectedItem.createdOn}</span></ListGroup.Item>
                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Created By"]}</span><span className="rwVal">{props.selectedItem.createdByFullname}</span></ListGroup.Item>
                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Modified On"]}</span><span className="rwVal">{props.selectedItem.modifiedOn}</span></ListGroup.Item>
                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">{tr["Modified By"]}</span><span className="rwVal">{props.selectedItem.modifiedByFullname}</span></ListGroup.Item>
                    </ListGroup>
                </div>
            </div>
        </>
    )
}

export default ShiftListShortDesc;