
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/


export const organizationData = (state = [], action) => {
    if (action.type === 'ORGANIZTION_LIST_FETCHED_SUCCESSFULLY') {
      return action.payload;
    } else {
      return state;
    }
};

export const organizationEditPageData = (state = [], action) => {
    if (action.type === 'ORGANIZATION_EDIT_DETAILS_FETCHED') {
      return action.payload;
    } else {
      return state;
    }
};

export const orgCompanyDropdown = (state =[],action) =>{
    if(action.type == "ORG_COMPANY_DROPDOWN_LIST"){
      return action.payload;
    }
    else{
      return state;
    }
}
