
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Button } from "react-bootstrap";
import { InputVariables } from "./taskInputVariables.js";
import OutputVariables from "./taskOutPutVariables.js";
import { connect } from "react-redux";
import {
  loadTaskInputVariables,
  loadTaskOutputVariables,
  postTaskOutputVariables,
  initializeTaskOutputEditForm,
  updateOutPutVariable,
} from "../../../../actions/task/taskVariableActions";
import {IoClose} from "react-icons/io5";

class TaskVariables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortIcons: false,
      class1: "sort",
      class2: "sort down",
      typeVar: true,
      editVar: false,
      editNewTagName: "",
    };
    this.sortFun = this.sortFun.bind(this);
    this.checkTypeVar = this.checkTypeVar.bind(this);
    this.editVarFun = this.editVarFun.bind(this);
  }
  componentDidMount() {
    const { workItemId, taskidforactivity, workItemType, taskCode } =
      this.props.taskDetails;
    this.props.loadTaskInputVariables(
      workItemId,
      taskidforactivity,
      workItemType,
      taskCode
    );
  }
  sortFun(myObj) {
    this.setState({ sortIcons: !this.state.sortIcons });
    switch (myObj) {
      case "class1":
        this.setState({
          class1: this.state.sortIcons ? "sort up" : "sort down",
        });
        this.setState({ class2: "sort" });
        break;
      case "class2":
        this.setState({ class1: "sort" });
        this.setState({
          class2: this.state.sortIcons ? "sort up" : "sort down",
        });
        break;
    }
  }
  editVarFun(newVal, myTagName) {
    this.setState({ editVar: newVal });
    this.setState({ editNewTagName: myTagName });
  }
  checkTypeVar() {
    this.setState({ typeVar: !this.state.typeVar });
  }
  render() {
    const {
      inputs,
      fetchingInputs,
      errorFetchingInputs,
      outputs,
      fetchingOutputs,
      errorFetchingOutputs,
    } = this.props.taskInputOutputVariables;
    const { taskCode } = this.props.taskDetails;
    return (
      <div>
        <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
            <ul>
                <li>
                <button
                    type='button'
                    title="Minimize the right panel"
                    bsClass=""
                    bsStyle=""
                    className="closerightPanelBtn"
                    onClick={() => {
                    this.props.rightEditPanel(false);
                    }}
                >
                    <IoClose />
                </button>
                </li>
            </ul>
        </div>
        <div className="rPageHeading">
          {this.state.editVar ? null : (
            <Button
              disabled={this.props.disableFieldsbasedOnRoles}
              onClick={() => {
                this.checkTypeVar();
                if (!this.state.typeVar) {
                  this.props.loadTaskInputVariables(this.props.taskDetails.workItemId, this.props.taskDetails.taskidforactivity, this.props.taskDetails.workItemType, this.props.taskDetails.taskCode)
                }
              }}
              title={
                this.state.typeVar
                  ? this.props.translator["Output Variables"]
                  : this.props.translator["Input Variables"]
              }
              bsSize="xsmall"
              bsStyle="primary"
              className="float-r outputVarBtn"
            >
              {this.state.typeVar
                ? this.props.translator["Output Variables"]
                : this.props.translator["Input Variables"]}
            </Button>
          )}
          <div className="offNam margin-t-5 margin-b-5">
            {this.state.typeVar
              ? this.props.translator["Input Variables"]
              : this.state.editVar
              ? this.state.editNewTagName +
                " " +
                this.props.translator["Variable"]
              : this.props.translator["Output Variables"]}
          </div>
        </div>
        <div className="rBoxGap">
          {this.state.typeVar ? (
            <InputVariables
              sortFunInput={this.sortFun}
              classNameVar1Input={this.state.class1}
              classNameVar2Input={this.state.class2}
              inputs={inputs}
              fetchingInputs={fetchingInputs}
              errorFetchingInputs={errorFetchingInputs}
              translator={this.props.translator}
            />
          ) : (
            <OutputVariables
              editVar1={this.state.editVar}
              editVarFun1={this.editVarFun}
              sortFun={this.sortFun}
              classNameVar1={this.state.class1}
              classNameVar2={this.state.class2}
              loadTaskOutputVariables={this.props.loadTaskOutputVariables}
              outputs={outputs}
              fetchingOutputs={fetchingOutputs}
              errorFetchingOutputs={errorFetchingOutputs}
              taskCode={taskCode}
              isFetchingDetails={this.props.isFetchingDetails}
              initializeTaskOutputEditForm={
                this.props.initializeTaskOutputEditForm
              }
              formType={this.state.editNewTagName}
              updateOutPutVariable={this.props.updateOutPutVariable}
              translator={this.props.translator}
              taskDetails={this.props.taskDetails}
              role_id={this.props.role_id}
              disableFieldsForGuestRole={this.props.disableFieldsForGuestRole}
            />
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ taskInputOutputVariables, isFetchingDetails }) => {
  return {
    taskInputOutputVariables,
    isFetchingDetails,
  };
};

export default connect(mapStateToProps, {
  loadTaskInputVariables,
  loadTaskOutputVariables,
  postTaskOutputVariables,
  initializeTaskOutputEditForm,
  updateOutPutVariable,
})(TaskVariables);
