
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const isFetchingDetails = (state = {
  isFetching: true,
  posting:false,
  postSuccess:false,
  postingError:false,
  message: ''
}, action) => {
  switch (action.type) {
    case 'FETCHING_DETAILS_IN_PROGRESS':
      return Object.assign({}, state, {
        isFetching: true,
        message: 'loading...'
      });
    case 'FETCHING_DETAILS_IN_PROGRES_BY_ID':
        return Object.assign({}, state, {
          isFetching: true,
          message: 'loading...'
        });
    case 'DETAILS_FETCHED_SUCCESSFULLY':
      return Object.assign({}, state, {
        isFetching: false,
        message: ''
      });
    case 'DETAILS_FETCHED_SUCCESSFULLY_BY_ID':
        return Object.assign({}, state, {
          isFetching: false,
          message: ''
        });
    case 'ERROR_LOADING_DETAILS':
      return Object.assign({}, state, {
        isFetching: true,
        message: action.message
      });
    case 'POSTING_DATA':
      return Object.assign({},state,{
        posting: true,
        isFetching:false,
        postingError:false
      });
    case 'DATA_POSTED_SUCCESSFULLY':
      return Object.assign({},state,{
        posting:false,
        isFetching:false,
        postSuccess:true,
        postingError:false
      });
    case 'ERROR_POSTING_DATA':
      return Object.assign({},state,{
        posting:false,
        isFetching:false,
        postingError:true
      });
      case 'FETCHING_FULDETAILS_IN_PROGRESS':
      return Object.assign({}, state, {
        isFetching: true,
        message: 'loading...'
      });
    case 'DETAILS_FULFETCHED_SUCCESSFULLY':
      return Object.assign({}, state, {
        isFetching: false,
        message: ''
      });
    default:
      return state;
  }
};
//====
export const sLaHolidayFetchingDetails = (state = {
  isFetching: true,
  message: ''
}, action) => {
  switch (action.type) {
    case 'FETCHING_DETAILS_IN_PROGRESS':
      return Object.assign({}, state, {
        isFetching: true,
        message: 'loading...'
      });
    case 'DETAILS_FETCHED_SUCCESSFULLY':
      return Object.assign({}, state, {
        isFetching: false,
        message: ''
      });
    case 'ERROR_LOADING_DETAILS':
      return Object.assign({}, state, {
        isFetching: true,
        message: action.message
      });
    default:
      return state;
  }
};
//====


export const isFetchingTimeline = (state = {
  isFetching: false,
  message: ''
}, action) => {
  switch (action.type) {
    case 'FETCHING_AUDIT_FOR_TIMELINE':
      return Object.assign({},
        state, {
          isFetching: true,
          message: 'loading...'
        });
    case 'FETCHED_AUDIT_FOR_TIMELINE':
      return Object.assign({},
        state, {
          isFetching: false,
          message: ''
        });
    case 'ERROR_LOADING_AUDIT_FOR_TIMELINE':
      return Object.assign({},
        state, {
          isFetching: true,
          message: action.message
        })
    default:
      return state;
  }
};
//=======================
export const isCausalRelationshipData = (state = {
  causalRelationData: true,
  message: ''
}, action) => {
  switch (action.type) {
    case 'CAUSALRELATIONSHIP_DETAILS_IN_PROGRESS':
      return Object.assign({},
        state, {
          causalRelationData: true,
          message: 'loading...'
        });
    case 'CAUSALRELATIONSHIP_DETAILS_FETCHED_SUCCESSFULLY':
      return Object.assign({}, state, {
        causalRelationData: false,
        message: ''
          });
    default:
      return state;
  }
};
//=================
//=======================
export const showLoader = (state = {
  loading: false,
  message: ''
}, action) => {
  switch (action.type) {
    case 'LOADER_IN_PROGRESS':
      return Object.assign({},
        state, {
          loading: true,
          message: 'loading...'
        });
    case 'LOADER_DETAILS_FETCHED_SUCCESSFULLY':
      return Object.assign({}, state, {
        loading: false,
        message: ''
          });
    default:
      return state;
  }
};
//=================
//=======================
export const showLoader2 = (state = {
  loading: false,
  message: ''
}, action) => {
  switch (action.type) {
    case 'LOADER_IN_PROGRESS_2':
      return Object.assign({},
        state, {
          loading: true,
          message: 'loading...'
        });
    case 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_2':
      return Object.assign({}, state, {
        loading: false,
        message: ''
          });
    default:
      return state;
  }
};
//=================
//=======================
export const rightMenuShowLoader = (state = {
  loading: false,
  message: ''
}, action) => {
  switch (action.type) {
    case 'MENU_LOADER_IN_PROGRESS':
      return Object.assign({},
        state, {
          loading: true,
          message: 'loading...'
        });
    case 'MENU_LOADER_DETAILS_FETCHED_SUCCESSFULLY':
      return Object.assign({}, state, {
        loading: false,
        message: ''
          });
    default:
      return state;
  }
};
//=================
//=======================
export const showLoader3 = (state = {
  loading: false,
  message: ''
}, action) => {
  switch (action.type) {
    case 'LOADER_IN_PROGRESS_3':
      return Object.assign({},
        state, {
          loading: true,
          message: 'loading...'
        });
    case 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_3':
      return Object.assign({}, state, {
        loading: false,
        message: ''
          });
    default:
      return state;
  }
};
//=================

export const showLoader4 = (state = {
  loading: false,
  message: ''
}, action) => {
  switch (action.type) {
    case 'LOADER_IN_PROGRESS_4':
      return Object.assign({},
        state, {
          loading: true,
          message: 'loading...'
        });
    case 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_4':
      return Object.assign({}, state, {
        loading: false,
        message: ''
          });
    default:
      return state;
  }
};
//=======================
//=======================
export const showLoader_1 = (state = {
  loading: false,
  message: ''
}, action) => {
  switch (action.type) {
    case 'LOADER_IN_PROGRESS_1':
      return Object.assign({},
        state, {
          loading: true,
          message: 'loading...'
        });
    case 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_1':
      return Object.assign({}, state, {
        loading: false,
        message: ''
          });
    default:
      return state;
  }
};

export const showLoader_2 = (state = {
  loading: false,
  message: ''
}, action) => {
  switch (action.type) {
    case 'LOADER_IN_PROGRESS_2':
      return Object.assign({},
        state, {
          loading: true,
          message: 'loading...'
        });
    case 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_2':
      return Object.assign({}, state, {
        loading: false,
        message: ''
          });
    default:
      return state;
  }
};

export const showLoader_3 = (state = {
  loading: false,
  message: ''
}, action) => {
  switch (action.type) {
    case 'LOADER_IN_PROGRESS_3':
      return Object.assign({},
        state, {
          loading: true,
          message: 'loading...'
        });
    case 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_3':
      return Object.assign({}, state, {
        loading: false,
        message: ''
          });
    default:
      return state;
  }
};
export const showLoader_5 = (state = {
  loading: false,
  message: ''
}, action) => {
  switch (action.type) {
    case 'LOADER_IN_PROGRESS_5':
      return Object.assign({},
        state, {
          loading: true,
          message: 'loading...'
        });
    case 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_5':
      return Object.assign({}, state, {
        loading: false,
        message: ''
          });
    default:
      return state;
  }
};
export const showLoader_6 = (state = {
  loading: false,
  message: ''
}, action) => {
  switch (action.type) {
    case 'LOADER_IN_PROGRESS_6':
      return Object.assign({},
        state, {
          loading: true,
          message: 'loading...'
        });
    case 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_6':
      return Object.assign({}, state, {
        loading: false,
        message: ''
          });
    default:
      return state;
  }
};
export const showLoader_SLA_Deflist_Pagination = (state = {
  loading: false,
  message: ''
}, action) => {
  switch (action.type) {
    case 'LOADER_IN_PROGRESS_SLA_DEFINATIONS':
      return Object.assign({},
        state, {
          loading: true,
          message: 'loading...'
        });
    case 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_SLA_DEFINATIONS':
      return Object.assign({}, state, {
        loading: false,
        message: ''
          });
    default:
      return state;
  }
};

export const showLoader_SLA_Work_Schedule_Pagination = (state = {
  loading: false,
  message: ''
}, action) => {
  switch (action.type) {
    case 'LOADER_IN_PROGRESS_SLA_WORK_SCHEDULE':
      return Object.assign({},
        state, {
          loading: true,
          message: 'loading...'
        });
    case 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_WORK_SCHEDULE':
      return Object.assign({}, state, {
        loading: false,
        message: ''
          });
    default:
      return state;
  }
};

export const showLoader_SLA_Holiday_Schedule_Pagination = (state = {
  loading: false,
  message: ''
}, action) => {
  switch (action.type) {
    case 'LOADER_IN_PROGRESS_SLA_HOLIDAY_SCHEDULE':
      return Object.assign({},
        state, {
          loading: true,
          message: 'loading...'
        });
    case 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_HOLIDAY_SCHEDULE':
      return Object.assign({}, state, {
        loading: false,
        message: ''
          });
    default:
      return state;
  }
};

export const freeServiceShowLoader= (state = {
  loading: false,
  message: ''
}, action) => {
  switch (action.type) {
    case 'FREE_SERVICE_LOADER_IN_PROGRESS':
      return Object.assign({},
        state, {
          loading: true,
          message: 'loading...'
        });
    case 'FREE_SERVICE_LOADER_DETAILS_FETCHED_SUCCESSFULLY':
      return Object.assign({}, state, {
        loading: false,
        message: ''
          });
    default:
      return state;
  }
};

export const newLaunchShowLoader= (state = {
  loading: false,
  message: ''
}, action) => {
  switch (action.type) {
    case 'NEW_LAUNCH_LOADER_IN_PROGRESS':
      return Object.assign({},
        state, {
          loading: true,
          message: 'loading...'
        });
    case 'NEW_LAUNCH_LOADER_DETAILS_FETCHED_SUCCESSFULLY':
      return Object.assign({}, state, {
        loading: false,
        message: ''
          });
    default:
      return state;
  }
};

export const amsBoardShowLoader= (state = {
  loading: false,
  message: ''
}, action) => {
  switch (action.type) {
    case 'AMS_BOARD_LOADER_IN_PROGRESS':
      return Object.assign({},
        state, {
          loading: true,
          message: 'loading...'
        });
    case 'AMS_BOARD_LOADER_DETAILS_FETCHED_SUCCESSFULLY':
      return Object.assign({}, state, {
        loading: false,
        message: ''
          });
    default:
      return state;
  }
};
export const featuredShowLoader= (state = {
  loading: false,
  message: ''
}, action) => {
  switch (action.type) {
    case 'FEATURED_LOADER_IN_PROGRESS':
      return Object.assign({},
        state, {
          loading: true,
          message: 'loading...'
        });
    case 'FEATURED_LOADER_DETAILS_FETCHED_SUCCESSFULLY':
      return Object.assign({}, state, {
        loading: false,
        message: ''
          });
    default:
      return state;
  }
};


export const bestSellingShowLoader= (state = {
  loading: false,
  message: ''
}, action) => {
  switch (action.type) {
    case 'BEST_SELLING_LOADER_IN_PROGRESS':
      return Object.assign({},
        state, {
          loading: true,
          message: 'loading...'
        });
    case 'BEST_SELLING_LOADER_DETAILS_FETCHED_SUCCESSFULLY':
      return Object.assign({}, state, {
        loading: false,
        message: ''
          });
    default:
      return state;
  }
};


export const comingSoonShowLoader= (state = {
  loading: false,
  message: ''
}, action) => {
  switch (action.type) {
    case 'COMING_SOON_LOADER_IN_PROGRESS':
      return Object.assign({},
        state, {
          loading: true,
          message: 'loading...'
        });
    case 'COMING_SOON_LOADER_DETAILS_FETCHED_SUCCESSFULLY':
      return Object.assign({}, state, {
        loading: false,
        message: ''
          });
    default:
      return state;
  }
};


export const favouriteServiceShowLoader= (state = {
  loading: false,
  message: ''
}, action) => {
  switch (action.type) {
    case 'FAVOURITE_SERVICE_LOADER_IN_PROGRESS':
      return Object.assign({},
        state, {
          loading: true,
          message: 'loading...'
        });
    case 'FAVOURITE_SERVICE_LOADER_DETAILS_FETCHED_SUCCESSFULLY':
      return Object.assign({}, state, {
        loading: false,
        message: ''
          });
    default:
      return state;
  }
};

export const delegateShowLoader= (state = {
  loading: false,
  message: ''
}, action) => {
  switch (action.type) {
    case 'DELEGATE_LOADER_IN_PROGRESS':
      return Object.assign({},
        state, {
          loading: true,
          message: 'loading...'
        });
    case 'DELEGATE_LOADER_DETAILS_FETCHED_SUCCESSFULLY':
      return Object.assign({}, state, {
        loading: false,
        message: ''
          });
    default:
      return state;
  }
};

export const serviceReviewShowLoader= (state = {
  loading: false,
  message: ''
}, action) => {
  switch (action.type) {
    case 'SERVICE_REVIEW_LOADER_IN_PROGRESS':
      return Object.assign({},
        state, {
          loading: true,
          message: 'loading...'
        });
    case 'SERVICE_REVIEW_LOADER_DETAILS_FETCHED_SUCCESSFULLY':
      return Object.assign({}, state, {
        loading: false,
        message: ''
          });
    default:
      return state;
  }
};

export const slaDetailsShowLoader= (state = {
  loading: false,
  message: ''
}, action) => {
  switch (action.type) {
    case 'SLA_DETAILS_LOADER_IN_PROGRESS':
      return Object.assign({},
        state, {
          loading: true,
          message: 'loading...'
        });
    case 'SLA_DETAILS_LOADER_DETAILS_FETCHED_SUCCESSFULLY':
      return Object.assign({}, state, {
        loading: false,
        message: ''
          });
    default:
      return state;
  }
};

export const loading_orders_gsearch_2 = (state = {
  loading: false,
  message: ''
}, action) => {
  switch (action.type) {
    case 'FETCHING_ORDERS_GBL_SRCH_2':
      return Object.assign({},
        state, {
          loading: true,
          message: 'loading...'
        });
    case 'ORDERS_GBL_SRCH_SUCCESS_2':
      return Object.assign({}, state, {
        loading: false,
        message: ''
          });
    default:
      return state;
  }
};
//=======================
export const showLoaderRelatedTask = (state = {
  loading: false,
  message: ''
}, action) => {
  switch (action.type) {
    case 'LOADER_IN_PROGRESS_RELATEDTASK':
      return Object.assign({},
        state, {
          loading: true,
          message: 'loading...'
        });
    case 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_RELATEDTASK':
      return Object.assign({}, state, {
        loading: false,
        message: ''
          });
    default:
      return state;
  }
};
//=================

export const allCategoriesLoader = (state = {
  loading: false,
  message: ''
}, action) => {
  switch (action.type) {
    case 'ALLCATEGORIES_LOADER_IN_PROGRESS':
      return Object.assign({},
        state, {
          loading: true,
          message: 'loading...'
        });
    case 'ALLCATEGORIES_LOADER_FETCHED_SUCCESSFULLY':
      return Object.assign({}, state, {
        loading: false,
        message: ''
          });
    default:
      return state;
  }
};


export const topReviewLoader = (state = {
  loading: false,
  message: ''
}, action) => {
  switch (action.type) {
    case 'REVIEW_LOADER_IN_PROGRESS':
      return Object.assign({},
        state, {
          loading: true,
          message: 'loading...'
        });
    case 'REVIEW_LOADER_DETAILS_FETCHED_SUCCESSFULLY':
      return Object.assign({}, state, {
        loading: false,
        message: ''
          });
    default:
      return state;
  }
};
export const revampGlobalSearchShowLoader= (state = {
  loading: false,
  message: ''
}, action) => {
  switch (action.type) {
    case 'REVAMP_SEARCH_LOADER_IN_PROGRESS':
      return Object.assign({},
        state, {
          loading: true,
          message: 'loading...'
        });
    case 'REVAMP_SEARCH_LOADER_DETAILS_FETCHED_SUCCESSFULLY':
      return Object.assign({}, state, {
        loading: false,
        message: ''
          });
    default:
      return state;
  }
};

export const showLoader_ne = (state = {
  loading: false,
  message: ''
}, action) => {
  switch (action.type) {
    case 'LOADER_NE_IN_PROGRESS':
      return Object.assign({},
        state, {
          loading: true,
          message: 'loading...'
        });
    case 'LOADER_NE_DETAILS_FETCHED_SUCCESSFULLY':
      return Object.assign({}, state, {
        loading: false,
        message: ''
          });
    default:
      return state;
  }
};

export const taskStatusLoader = (state = {  
  loading: false,
  message: ''
}, action) => {
  switch (action.type) {
    case 'LOADER_IN_PROGRESS':
      return Object.assign({},
        state, {
        loading: true,
        message: 'loading...'
      });
    case 'LOADER_DETAILS_FETCHED_SUCCESSFULLY':
      return Object.assign({}, state, {
        loading: false,
        message: ''
      });
    default:
      return state;
  }
};
