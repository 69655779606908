
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import { Row, Col, Container } from 'react-bootstrap';

import PindCategories from "../../../common/header/pind-categories";
import Breadcrumbs from "../../../common/header/breadcrumbs";
import Header from "./header";
import FormComponent from "./form";
import "_Css/form/_form.scss";

const Index = () => {
  const [isRightSideVisible, setRightSideVisible] = useState(false);
  let colWidth = "";
  isRightSideVisible ? (colWidth = 8) : (colWidth = 12);

  const showRightSideFun = (val) => {
    setRightSideVisible(val);
  };

  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Breadcrumbs activePageName={"Multi SSO Logo"} />
      </Container>

      <Container fluid>
        <Header />
        <Row>
          <Col md={8} xs={12}>
            <FormComponent />
          </Col>
        </Row>
      </Container>
    </main>
  )
}

export default Index;
