
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import {Row, Col, Form, Button, Table, Alert} from 'react-bootstrap';
import { connect } from 'react-redux';
import {getAttachmentsAction,getAttachmentsPaginationAction,getServiceDetailCounts } from '../../../actions/spcmActions';
import ComponentForm from './editForm';
import axios from 'axios';
import Pagination from "react-js-pagination";
import ListLoader from "_Commons/loaders/ListLoader";
import Swal from 'sweetalert2';
const Joi = require("joi");
class ListView extends React.Component {
	constructor(props){
		super(props);
		this.state={
			queryBy:'Role',
			query:'',
			sortBy:'Role',
			pageSize:10,
			deleteError:'',
			sortIcons: false ,
			class1: 'sort up',
			class2: 'sort',
			sortByTitle:'',
			externLink:false
		}
		this.onPageChange = this.onPageChange.bind(this);
		this.onPageSizeChange = this.onPageSizeChange.bind(this);
		this.onDeleteClick = this.onDeleteClick.bind(this);
	}


    onPageSizeChange(e){

		this.setState({pageSize:e.target.value});
        this.props.getAttachmentsPaginationAction(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,'desc',1,e.target.value)
	
    }

	onPageChange(e){
        this.props.getAttachmentsPaginationAction(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,'desc',e,this.state.pageSize)
	}

	
	onDeleteClick(id){
		Swal.fire({
            title: 'Are you sure you wish to delete this item?',
            width: 550,
			
            padding: '15px',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
          }).then((result) => {
            if (result.isConfirmed) {
					return axios.delete("/api/attachment/"+id).then((resp)=>{
                        // this.props.getAttachmentsAction(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
                        this.props.getAttachmentsPaginationAction(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,"desc",1,10)
				this.props.getServiceDetailCounts(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
						
						
			
					}).catch((error)=>{
						this.setState({deleteError:error.response.data[0].message})
					})
                } else if (result.isDenied) {
              
            }
          })
	}

	componentWillMount(){
        this.props.getAttachmentsPaginationAction(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,"desc",1,10)
		this.props.getAttachmentsAction(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
		

	}
  
    
	render() {
		const tr = this.props.tr;
		var meta = this.props.spcmReducer.meta;
        var attachmentReducerData;
        if(this.props.spcmReducer.paginationAttachmentsReducer.length>0){
             attachmentReducerData = this.props.spcmReducer.paginationAttachmentsReducer;
        }
		else{
            attachmentReducerData = [];
        }
       

		console.log(" this.props.spcmReducer.paginationAttachmentsReducer", this.props.spcmReducer.paginationAttachmentsReducer)
		

		return (
			<div className="">
			{this.props.componentFormToggle3 ? <ComponentForm tr={tr} componentFormFun4={this.props.componentFormFun3}/> : <div className='rBoxGap'>
				{this.state.deleteError == '' ? null : <Alert style={{ "marginBottom": "15px", "fontSize": "13px", "padding": "8px 30px 8px 8px" }} variant="danger" onDismiss={this.handleDismiss}><h4>{this.state.deleteError}</h4></Alert>}
				<Row className="">
				
					<Col md={12} sm={4} xs={12} className="text-r rightSideFields">
						<Form.Label className="margin-r-5 display-inline-block widthAuto">{tr['Show']}</Form.Label>
						<Form.Select className=" display-inline-block widthAuto" onChange={ this.onPageSizeChange }>
						<option value="10" selected={this.state.pageSize==10?true:false}>10</option>
						<option value="25" selected={this.state.pageSize==25?true:false}>25</option>
						<option value="50" selected={this.state.pageSize==50?true:false}>50</option>
						<option value="100" selected={this.state.pageSize==100?true:false}>100</option>
						</Form.Select>
						</Col>
				</Row>

				{this.props.spcmReducer.spcmFetchingError == true  && this.props.spcmReducer.spcmFetchingModule == 'ATTACHMENTS_SB' && this.props.spcmReducer.spcmErrorMsg ?  <Alert variant="danger" className="errorMessageSec margin-t-20"><p>{this.props.spcmReducer.spcmErrorMsg}</p></Alert> :  this.props.spcmReducer.spcmFetchingError == true  && this.props.spcmReducer.spcmFetchingModule == 'CHAIN_ENTITIES' && !this.props.spcmReducer.spcmErrorMsg ? <p>Oops! Something went wrong.</p> : null}

				<div className="f-size-12">
					<Table bordered condensed hover responsive className="tableView sortImg tableWithOutPointer">
					
					<thead>
					  <tr>
						<th width="60%">Attachment Name</th>
						<th width="10%" className="text-c">{tr['Action']}</th>
					  </tr>
					</thead>
					{this.props.spcmReducer.spcmFetchingAttribute  ? 
					<tbody><td colSpan="3">
						<ListLoader />
					</td></tbody> : 
					<tbody>
                    {attachmentReducerData.map(function(value,index){
                        console.log("VALUE000",value);
                        let validated_url = undefined;
                        // if (value != null || value.ATTACHMENT_URL != undefined) {
                        if (value && value.ATTACHMENT_URL != undefined) {
                          let namedSchema = Joi.string().regex(/^[^<>}{]+$/);
                          let validator_to_add_myQbj = namedSchema.validate(
                            value.ATTACHMENT_URL
                          );
                          if (validator_to_add_myQbj.error == null) {
                            validated_url = value.ATTACHMENT_URL;
                          } else {
                            validated_url = "\\";
                          }
                        } else validated_url = "\\";
                console.log("VALIDATEDURL",validated_url);
            
                        return (<tr>
                    <td>{value.ORIGINAL_ATTACHMENT_NAME}</td>
                    <td>
                    <div className="text-c">
                                    <a className="margin-r-10" href={validated_url}>
                                        <i className="fa fa-eye"></i>
                                    </a>
                        <i title={tr["Delete"]} className="margin-l-10 fa fa-trash-o cursorPoint" onClick={()=>{this.onDeleteClick(value.ATTACHMENT_ID)}}></i>
                    </div>
                    </td>
                  </tr>)
                    },this)}
                      {attachmentReducerData.length == 0 ? (
                      <tr>
                        <td colSpan={4} className="text-c">
                          {tr["No Data Found"]}
                        </td>
                      </tr>
                    ) : null}
                </tbody>}
					</Table>
					
				</div>

				<div className='nBotPagina'>
				<nav aria-label="Pagination" className="display-inline-block">
					
					{meta ? <Pagination  
						activePage={meta ? meta.currentPage : 1}
						itemsCountPerPage={this.state.pageSize}
						totalItemsCount={meta ? meta.rowCount : 1}
						onChange={this.onPageChange}
					/> : null }
					
				</nav>
				</div>
				</div>
				}
			</div>
		);
	}
}
const mapStateToProps = ({spcmReducer}) => {
	return {
		spcmReducer	}
};

export default connect(mapStateToProps,{getAttachmentsAction,getAttachmentsPaginationAction,getServiceDetailCounts })(ListView);
