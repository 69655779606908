
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from "axios";
import { GLOBAL } from '_Globals';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
let companyId = cookies.get('gph');
if (companyId) companyId = companyId.replace('s:', '');
if (companyId) companyId = companyId.substring(0, companyId.lastIndexOf('.'));
companyId = companyId.split("~");
companyId = companyId[48];

const api = axios.create({
    headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

export function loadOnCallSXMenulistData(moduleName, field1Name) {
    return (dispatch) => {
        api.get(GLOBAL.aiosemSXMenulistUrl + '/' + moduleName + '/' + field1Name)
            .then((response) => {
                if (!response) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => {
                if (field1Name === 'ScheduleType') {
                    if (response.status == 204) {
                        dispatch(loadScheduleTypeSuccess([]));
                    } else {
                        dispatch(loadScheduleTypeSuccess(response.data.data));
                    }
                }
                else if (field1Name === 'Status') {
                    if (response.status == 204) {
                        dispatch(loadStatusSuccess([]));
                    } else {
                        dispatch(loadStatusSuccess(response.data.data));
                    }
                }
                else if (field1Name === 'Frequency') {
                    if (response.status == 204) {
                        dispatch(loadFrequencySuccess([]));
                    } else {
                        dispatch(loadFrequencySuccess(response.data.data));
                    }
                }
                else if (field1Name === 'EscalationLevel') {
                    if (response.status == 204) {
                        dispatch(loadEscalationLevelSuccess([]));
                    } else {
                        dispatch(loadEscalationLevelSuccess(response.data.data));
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
}

export function loadScheduleTypeSuccess(payload) {
    return {
        type: 'LOAD_SCHEDULE_TYPE_SUCCESS',
        payload
    };
}

export function loadStatusSuccess(payload) {
    return {
        type: 'LOAD_ON_CALL_STATUS_SUCCESS',
        payload
    };
}

export function loadFrequencySuccess(payload) {
    return {
        type: 'LOAD_ON_CALL_FREQUENCY_SUCCESS',
        payload
    };
}

export function loadEscalationLevelSuccess(payload) {
    return {
        type: 'LOAD_ON_CALL_ESCALATION_LEVEL_SUCCESS',
        payload
    };
}

export function getOnCallGroups() {
    return (dispatch) => {
        dispatch({type:'LOADER_IN_PROGRESS'});
        axios.get("/asset-config/api/getCiSupportAllGroup/id/" +companyId)
            .then((groupList) => {
                if (!groupList) {
                    throw Error(groupList.statusText);
                }
                return groupList;
            })
            .then((groupList) => {
                dispatch({
                    type: 'LOAD_ON_CALL_GROUP_LIST_SUCCESS',
                    groupList
                })
                dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
            })
            .catch((err) => {
                dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
                console.log("error in groupList");
                console.log(err);
            });
    };
}

export function onCallGroupUsersByGroupId(groupId) {
    return (dispatch) => {
        axios.get("/api/userListSelfService?groupId=" +groupId)
            .then((usersList) => {
                if (!usersList) {
                    throw Error(usersList.statusText);
                }
                return usersList;
            })
            .then((usersList) => {
                dispatch({
                    type: 'LOAD_ON_CALL_GROUP_USERS_LIST',
                    usersList
                })
            })
            .catch((err) => {
                console.log("error in usersList");
                console.log(err);
            });
    };
}

export function getAvailableIndividuals(groupId) {
    return (dispatch) => {
        dispatch({type:'LOADER_IN_PROGRESS'});
        axios.get("/rest/aiosem/getAvailableUserList/" +groupId)
            .then((usersList) => {
                if (!usersList) {
                    throw Error(usersList.statusText);
                }
                return usersList;
            })
            .then((usersList) => {
                dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
                dispatch({
                    type: 'GET_AVAILABLE_INDIVIDUALS',
                    users:usersList.data?.data
                })
            })
            .catch((err) => {
                dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
                console.log("error in usersList");
                console.log(err);
            });
    };
}
