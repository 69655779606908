
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
// import { Tabs, Row, Col, Nav, Nav.Item, Tab } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';


import BestSelling from './SXBestSellingServices';
import NewLaunches from './SXNewLaunchesServices';
import ComingSoon from './SXComingSoonServices';
import Free from './SXFreeServices';
import Favourites from './sXFavouriteServices';
import { loadBestSelling } from '_Actions/homepage/bestsellingofferingAction';
import { loadNewLaunches } from '_Actions/homepage/newLaunchesAction';
import { loadComingSoon } from '_Actions/homepage/comingSoonAction';
import { loadFreeOffering } from '_Actions/homepage/freeOfferingAction';
import { loadFavorites } from '_Actions/homepage/favouritesAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';
const cookies = new Cookies();
let hometab_required = cookies.get('gph');

if (hometab_required) hometab_required = hometab_required.replace('s:', '');
if (hometab_required) hometab_required = hometab_required.substring(0, hometab_required.lastIndexOf('.'));
hometab_required = hometab_required.split("~");
hometab_required = hometab_required[4];
if (hometab_required == "" || hometab_required == undefined || hometab_required == "undefined")
  hometab_required = "yes";

class SxHomeTabOffering extends React.Component {
  constructor(props) {
    super(props);
    // this.showbestselling = this.showbestselling.bind(this);
    // this.shownewlaunch = this.shownewlaunch.bind(this);
    // this.showcomingsoon = this.showcomingsoon.bind(this);
    // this.showfree = this.showfree.bind(this);
    // this.showfav = this.showfav.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.state = {
      homeactivetab: ''
    };

  }
  // showbestselling()
  // {
  //   this.props.loadBestSelling();
  // }
  // shownewlaunch()
  // {
  //   this.props.loadNewLaunches();
  // }
  // showcomingsoon()
  // {
  // this.props.loadComingSoon();
  // }
  // showfree()
  // {
  //   this.props.loadFreeOffering();
  // }
  // showfav()
  // {
  //  this.props.loadFavorites();
  // }

  // componentWillMount()
  // {
  //   console.log('this.props++++++++++++++++++++++++');
  //   console.log(this.props);
  //   if(this.props.showBestSelling == 'Y' || this.props.showBestSelling == 'y')
  //     this.showbestselling();
  //   else if(this.props.showNewLaunch == 'Y' || this.props.showNewLaunch == 'y')
  //     this.shownewlaunch();
  //   else if(this.props.showComingSoon == 'Y' || this.props.showComingSoon == 'y')
  //     this.showcomingsoon();
  //   else if(this.props.showFree == 'Y' || this.props.showFree == 'y')
  //     this.props.showfree();
  //   else if(this.props.showMyFavourite == 'Y' || this.props.showMyFavourite == 'y')
  //     this.props.showfav();
  // }

  componentWillReceiveProps(nextProps) {

    if (this.props.showBestSelling !== nextProps.showBestSelling || this.props.showNewLaunch !== nextProps.showNewLaunch || this.props.showComingSoon !== nextProps.showComingSoon || this.props.showFree !== nextProps.showFree || this.props.showMyFavourite !== nextProps.showMyFavourite) {
      if (nextProps.showBestSelling == 'Y' || nextProps.showBestSelling == 'y') {
        this.setState({ homeactivetab: 'bestSelling' });
      }

      else if (nextProps.showNewLaunch == 'Y' || nextProps.showNewLaunch == 'y') {
        // this.props.loadNewLaunches();
        this.setState({ homeactivetab: 'newLaunches' });

      }
      else if (nextProps.showComingSoon == 'Y' || nextProps.showComingSoon == 'y') {
        // this.props.loadComingSoon();
        this.setState({ homeactivetab: 'comingSoon' });

      }
      else if (nextProps.showFree == 'Y' || nextProps.showFree == 'y') {
        // this.props.loadFreeOffering();
        this.setState({ homeactivetab: 'free' });

      }
      else if (nextProps.showMyFavourite == 'Y' || nextProps.showMyFavourite == 'y') {
        // this.props.loadFavorites();
        this.setState({ homeactivetab: 'favourites' });
      }
    }
  }

  handleSelect(key) {
    this.setState({ homeactivetab: key });
  }

  render() {
    return (
      <Tab.Container activeKey={this.state.homeactivetab} onSelect={this.handleSelect}>
        <Row id="SxHomeTab"  className='sxhometabact mobMarTB40'>
          <Col sm={12} xs={11}>
            {hometab_required == "yes" || hometab_required == "YES" ?
              <Nav className="nav-tabs nav-tabs-responsive" as="ul">
                {this.props.showBestSelling == 'Y' || this.props.showBestSelling == 'y' ? <Nav.Item  as="li" bsPrefix={this.state.homeactivetab =="bestSelling" ? "active" : ' '}><Nav.Link bsPrefix=' ' eventKey="bestSelling">{this.props.tr['Best Selling']}</Nav.Link></Nav.Item> : null}
                {this.props.showNewLaunch == 'Y' || this.props.showNewLaunch == 'y' ? <Nav.Item as="li" bsPrefix={this.state.homeactivetab =="newLaunches" ? "active" : ' '}><Nav.Link bsPrefix=' ' eventKey="newLaunches">{this.props.tr['New Launches']}</Nav.Link></Nav.Item> : null}
                {this.props.showComingSoon == 'Y' || this.props.showComingSoon == 'y' ? <Nav.Item as="li" bsPrefix={this.state.homeactivetab =="comingSoon" ? "active" : ' '}><Nav.Link bsPrefix=' ' eventKey="comingSoon">{this.props.tr['Coming Soon']}</Nav.Link></Nav.Item> : null}
                {this.props.showFree == 'Y' || this.props.showFree == 'y' ? <Nav.Item as="li" bsPrefix={this.state.homeactivetab =="free" ? "active" : ' '}><Nav.Link bsPrefix=' ' eventKey="free">{this.props.tr['Free']}</Nav.Link></Nav.Item> : null}
                {this.props.showMyFavourite == 'Y' || this.props.showMyFavourite == 'y' ? <Nav.Item as="li" bsPrefix={this.state.homeactivetab =="favourites" ? "active" : ' '}><Nav.Link bsPrefix=' ' eventKey="favourites">{this.props.tr['My Favourites']}</Nav.Link></Nav.Item> : null}
              </Nav> :
              <Nav className="nav-tabs nav-tabs-responsive" as="ul">
                {this.props.showBestSelling == 'Y' || this.props.showBestSelling == 'y' ? <Nav.Item as="li" bsPrefix={this.state.homeactivetab =="bestSelling" ? "active" : ' '}><Nav.Link bsPrefix=' ' eventKey="bestSelling">{this.props.tr['Best Selling']}</Nav.Link></Nav.Item> : null}
                {this.props.showNewLaunch == 'Y' || this.props.showNewLaunch == 'y' ? <Nav.Item as="li" bsPrefix={this.state.homeactivetab =="newLaunches" ? "active" : ' '}><Nav.Link bsPrefix=' ' eventKey="newLaunches">{this.props.tr['New Launches']}</Nav.Link></Nav.Item> : null}
                {this.props.showMyFavourite == 'Y' || this.props.showMyFavourite == 'y' ? <Nav.Item as="li" bsPrefix={this.state.homeactivetab =="favourites" ? "active" : ' '}><Nav.Link bsPrefix=' ' eventKey="favourites">{this.props.tr['My Favourites']}</Nav.Link></Nav.Item> : null}
              </Nav>
            }
          </Col>       
          <Col md={12}>
            {hometab_required == "yes" || hometab_required == "YES" ?
              <Tab.Content animation className="tabouter-border clearfix">
                {this.props.showBestSelling == 'Y' || this.props.showBestSelling == 'y' ? <Tab.Pane eventKey="bestSelling"><BestSelling homeactivetab={this.state.homeactivetab} /></Tab.Pane> : null}
                {this.props.showNewLaunch == 'Y' || this.props.showNewLaunch == 'y' ? <Tab.Pane eventKey="newLaunches"><NewLaunches homeactivetab={this.state.homeactivetab} /></Tab.Pane> : null}
                {this.props.showComingSoon == 'Y' || this.props.showComingSoon == 'y' ? <Tab.Pane eventKey="comingSoon"><ComingSoon homeactivetab={this.state.homeactivetab} /></Tab.Pane> : null}
                {this.props.showFree == 'Y' || this.props.showFree == 'y' ? <Tab.Pane eventKey="free"><Free homeactivetab={this.state.homeactivetab} /></Tab.Pane> : null}
                {this.props.showMyFavourite == 'Y' || this.props.showMyFavourite == 'y' ? <Tab.Pane eventKey="favourites"><Favourites homeactivetab={this.state.homeactivetab} /></Tab.Pane> : null}
              </Tab.Content> :
              <Tab.Content animation className="tabouter-border clearfix">
                {this.props.showBestSelling == 'Y' || this.props.showBestSelling == 'y' ? <Tab.Pane eventKey="bestSelling"><BestSelling homeactivetab={this.state.homeactivetab} /></Tab.Pane> : null}
                {this.props.showNewLaunch == 'Y' || this.props.showNewLaunch == 'y' ? <Tab.Pane eventKey="newLaunches"><NewLaunches homeactivetab={this.state.homeactivetab} /></Tab.Pane> : null}
                {this.props.showMyFavourite == 'Y' || this.props.showMyFavourite == 'y' ? <Tab.Pane eventKey="favourites"><Favourites homeactivetab={this.state.homeactivetab} /></Tab.Pane> : null}
              </Tab.Content>}
          </Col>
        </Row>
      </Tab.Container>
    );
  }

  //  render1() {

  //   return (
  // 	<Tab.Container id="SxHomeTab" defaultActiveKey="bestSelling">
  // 		<Row className="margin-t-30">
  // 			<Col md={12}>
  //         {hometab_required=="yes" || hometab_required=="YES" ?
  // 				<Nav className="nav-tabs nav-tabs-responsive">
  // 				 { this.props.bestselling==true?<Nav.Item eventKey="bestSelling">{this.props.tr['Best Selling']}</Nav.Item>:null}
  //         { this.props.newlaunch==true?  <Nav.Item eventKey="newLaunches" onClick={() => { this.shownewlaunch() }}>{this.props.tr['New Launches']}</Nav.Item>:null}
  // 				 {this.props.comingsoon==true? <Nav.Item eventKey="comingSoon" onClick={() => { this.showcomingsoon() }}>{this.props.tr['Coming Soon']}</Nav.Item>:null}
  // 				{this.props.free==true?  <Nav.Item eventKey="free" onClick={() => { this.showfree() }}>{this.props.tr['Free']}</Nav.Item>:null}
  // 				{this.props.favourite==true?  <Nav.Item eventKey="favourites" onClick={() => { this.showfav() }}>{this.props.tr['My Favourites']}</Nav.Item>:null}

  // 				</Nav>:
  //         <Nav className="nav-tabs nav-tabs-responsive">
  // 				  { this.props.bestselling==true?<Nav.Item eventKey="bestSelling">{this.props.tr['Best Selling']}</Nav.Item>:null}
  //           { this.props.newlaunch==true?<Nav.Item eventKey="newLaunches" onClick={() => { this.shownewlaunch() }}>{this.props.tr['New Launches']}</Nav.Item>:null}


  // 				  {this.props.favourite==true?<Nav.Item eventKey="favourites" onClick={() => { this.showfav() }}>{this.props.tr['My Favourites']}</Nav.Item>:null}

  // 				</Nav>

  //       }
  // 			</Col>
  // 			<Col md={12}>
  //       {hometab_required=="yes" || hometab_required=="YES" ?
  // 				<Tab.Content animation className="tabouter-border clearfix">
  // 				  { this.props.bestselling==true? <Tab.Pane eventKey="bestSelling"><BestSelling /></Tab.Pane>:null}
  // 				 { this.props.newlaunch==true? <Tab.Pane eventKey="newLaunches"><NewLaunches /></Tab.Pane>:null}
  // 				  {this.props.comingsoon==true ? <Tab.Pane eventKey="comingSoon"><ComingSoon /></Tab.Pane>:null}
  // 				  {this.props.free==true ?<Tab.Pane eventKey="free"><Free /></Tab.Pane>:null}
  // 				 {this.props.favourite==true? <Tab.Pane eventKey="favourites"><Favourites /></Tab.Pane>:null}
  // 				</Tab.Content>:
  //         <Tab.Content animation className="tabouter-border clearfix">
  //         { this.props.bestselling==true? <Tab.Pane eventKey="bestSelling"><BestSelling /></Tab.Pane>:null}
  //        { this.props.newlaunch==true? <Tab.Pane eventKey="newLaunches"><NewLaunches /></Tab.Pane>:null}
  //        {this.props.favourite==true? <Tab.Pane eventKey="favourites"><Favourites /></Tab.Pane>:null}
  //      </Tab.Content>}
  // 			</Col>
  // 		</Row>
  // 	</Tab.Container>
  //     );
  //  }
}

/*const mapStateToProps = ({spcmReducer}) => {
  return {
    spcmReducer,
    tr:spcmReducer.tr
  }
};

export default connect(mapStateToProps,{ } )(SxHomeTabOffering);*/
SxHomeTabOffering.propTypes = {
  showBestSelling: PropTypes.string,
  showNewLaunch: PropTypes.string,
  showComingSoon: PropTypes.string,
  showFree: PropTypes.string,
  showMyFavourite: PropTypes.string,
  tr: PropTypes.object,
};


function mapStateToProps({ bestSelling, spcmReducer, newLaunches, comingSoon, freeoffering, favorites }) {
  return {
    bestSelling, newLaunches, comingSoon, freeoffering, favorites, spcmReducer,
    tr: spcmReducer.tr
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loadBestSelling, loadNewLaunches, loadComingSoon, loadFreeOffering, loadFavorites }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(SxHomeTabOffering);
