
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Row, Col, Table, Button } from "react-bootstrap";
import {
  _textArea,
  _dropDown,
  _inputField,
} from "../../../../components/common/ReduxFormFields/CategoryBoard/formFields";
import { bindActionCreators } from "redux";
import { loadCIClass, loadCICompany } from "../../../../actions/cmdb/lovAction";
import {
  getCICategoryListData,
  emptyCICategoryListDataReducer,
} from "../../../../actions/categoryBoard/quickViewAction";
import Form from "react-bootstrap/Form";
import swal from "sweetalert";
import { Field, reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import { isArray } from "lodash";
import {PiToggleRightFill, PiToggleLeftFill} from 'react-icons/pi';
import {HiPlus, HiOutlineTrash} from "react-icons/hi";
import { loadClassByCompanyId, resetCiClass } from '../../../../actions/ham/hamAction';

const required = (value) => {
  if (!value || (value && value.trim().length === 0)) {
    return "required";
  }
};

class CreateCiCategoryForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      catStatus: true,
      arrayCounts: 1,
      extraCounts: [0],
      catStatuses: [true],
      createUsing: "new",
      selectedCompany: "",
      selectedClass: "",
    };
    this.loadCount = this.loadCount.bind(this);
    this.addRow = this.addRow.bind(this);
    this.deleteRow = this.deleteRow.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleNewExist = this.handleNewExist.bind(this);
    this.handleDefaultCategory = this.handleDefaultCategory.bind(this);
    this.handleciClassChange = this.handleciClassChange.bind(this);
    this.handleciCompanyChange = this.handleciCompanyChange.bind(this);
  }
  componentWillMount() {
    this.props.resetCiClass();
    this.props.loadCICompany("0");
    this.setState({ extraCounts: [0] });
    this.props.dispatch(
      change("CreateCiCategoryForm", "status", [this.state.catStatus])
    );
  }
  componentWillUnmount() {
    this.props.emptyCICategoryListDataReducer();
  }
  handleStatusChange(i) {
    let catDet = this.state.catStatuses;
    catDet[i] = !catDet[i];
    this.setState({ catStatuses: catDet });
    this.props.dispatch(change("CreateCiCategoryForm", "status", catDet));
  }
  updateCatStatus() {
    this.setState({ catStatus: !this.state.catStatus });
  }
  addRow() {
    if (this.state.extraCounts.length < 10) {
      let newStatus = this.state.catStatuses;
      newStatus.push(true);
      let arrayvar = this.state.extraCounts.slice();
      arrayvar.push(this.state.arrayCounts);
      this.setState(
        {
          extraCounts: arrayvar,
          arrayCounts: this.state.arrayCounts + 1,
          catStatuses: newStatus,
        },
        () => {
          this.props.dispatch(
            change("CreateCiCategoryForm", "status", newStatus)
          );
        }
      );
    } else {
      swal({
        text: "Cannot create more than 10 records",
        button: "OK",
      });
    }
  }
  deleteRow(myIndex) {
    let arrayvar = this.state.extraCounts;
    let index = arrayvar.indexOf(myIndex);
    arrayvar.splice(index, 1);
    let statusArr = this.state.catStatuses;
    statusArr.splice(index, 1);
    this.setState({
      extraCounts: arrayvar,
      catStatuses: statusArr,
    });
  }
  loadCount() {
    const rowlength = this.state.extraCounts.length;
    this.props.setRowCount(this.state.extraCounts);
    return this.state.extraCounts.map((extraCount, i) => {
      if (rowlength === i + 1) {
        return (
          <tr>
            <td>
              <Form.Group>
                {this.state.createUsing === "new" ? (
                  <Field
                    className="form-control"
                    component={_inputField}
                    maxLength="50"
                    name={"category_name" + extraCount}
                    validate={[required]}
                  />
                ) : (
                  <Field
                    name={"category_name" + extraCount}
                    className={"form-control"}
                    component={_dropDown}
                    options={this.props.filteredData}
                    validate={[required]}
                  />
                )}
              </Form.Group>
            </td>
            <td>
              <Form.Group>
                <Field
                  component={_inputField}
                  maxLength="50"
                  name={"sub_category_name" + extraCount}
                  className={"form-control"}
                  validate={[required]}
                />
              </Form.Group>
            </td>
            <td className="rBoxStyle">
              <Button
                title={this.state.catStatuses[i] ? "Active" : "Inactive"}
                onClick={() => this.handleStatusChange(i)}
                name={"status"}
                className={
                  this.state.catStatuses[i] ? "theme1 ciCrtCatBtn" : "ciCrtCatBtn"
                }
              >
                {this.state.catStatuses[i] ? <PiToggleRightFill/> : <PiToggleLeftFill/>}
              </Button>
              {this.state.extraCounts.length === 1 && (
                <Button
                  title="Add"
                  className="ciCrtCatBtn"
                  onClick={() => {
                    this.addRow();
                  }}
                >
                  <HiPlus/>
                </Button>
              )}
              {this.state.extraCounts.length > 1 && (
                <>
                  <Button
                    title="Add"
                    className="ciCrtCatBtn"
                    onClick={() => {
                      this.addRow();
                    }}
                  >
                    <HiPlus/>
                  </Button>
                  <Button
                    title="Delete"
                    className="ciCrtCatBtn"
                    onClick={() => {
                      this.deleteRow(extraCount);
                    }}
                  >
                    <HiOutlineTrash/>
                  </Button>
                </>
              )}
            </td>
          </tr>
        );
      } else {
        return (
          <tr key={i}>
            <td>
              {this.state.createUsing === "new" ? (
                <Field
                  component={_inputField}
                  maxLength="50"
                  name={"category_name" + extraCount}
                  className="form-control"
                  validate={[required]}
                />
              ) : (
                <Field
                  name={"category_name" + extraCount}
                  className="form-control"
                  component={_dropDown}
                  options={this.props.filteredData}
                  validate={[required]}
                />
              )}
            </td>
            <td>
              <Field
                component={_inputField}
                name={"sub_category_name" + extraCount}
                className="form-control"
                maxLength="50"
                validate={[required]}
              />
            </td>
            <td className="rBoxStyle">
              <Button
                title={this.state.catStatuses[i] ? "Active" : "Inactive"}
                name={"status"}
                onClick={() => this.handleStatusChange(i)}
                className={
                  this.state.catStatuses[i] ? "theme1 ciCrtCatBtn" : "ciCrtCatBtn"
                }
              >
                {this.state.catStatuses[i] ?  <PiToggleRightFill/> : <PiToggleLeftFill/>}
              </Button>
              <Button
                title="Delete"
                className="ciCrtCatBtn"
                onClick={() => {
                  this.deleteRow(extraCount);
                }}
              >
                <HiOutlineTrash/>
              </Button>
            </td>
          </tr>
        );
      }
    });
  }
  handleciCompanyChange(event) {
  
    let companyId =
      event.target.value !== ""
        ? JSON.parse(event.target.value).field1Value
        : "";
    this.setState({ selectedCompany: companyId });
    this.props.dispatch(
      change("CreateCiCategoryForm", "companyName", event.target.company)
    );
   // if (this.state.selectedClass !== "") {
      // this.props.getCICategoryListData(
      //   companyId,
      //   this.state.selectedClass,
      //   "both"
      // );
      
      if (companyId !== "") {
        let status = 'ACTIVE';
        let hamCall = false;
        this.props.loadClassByCompanyId(companyId,hamCall,status);
      } 
      this.props.dispatch(change("CreateCiCategoryForm", "classId", ""));
      // this.props.resetCiClass();
   // }
  }
  handleciClassChange(event) {
    this.setState({ selectedClass: event.target.value });
    if (this.state.createUsing === "Existing"){
      this.props.getCICategoryListData(this.state.selectedCompany, event.target.value, 'both');
    }else if (this.state.createUsing === "default"){
      this.props.getCICategoryListData(0, event.target.value, 'both');
    }
       
  }
  handleDefaultCategory() {
    if (!this.state.selectedCompany || !this.state.selectedClass) {
      swal({
        text: "Please select both Company and Class.",
        button: "OK",
      });
      return;
    }

    this.setState({
      createUsing: "default"
    });
    this.props.getCICategoryListData(0, this.state.selectedClass, 'both');
  }
  handleNewExist() {
    if (!this.state.selectedCompany || !this.state.selectedClass) {
      swal({
        text: "Please select both Company and Class.",
        button: "OK",
      });
      return;
    }

    this.setState({
      createUsing: this.state.createUsing === "new" ? "Existing" : "new",
    });
    this.state.extraCounts.forEach((val) => {
      this.props.dispatch(
        change("CreateCiCategoryForm", "category_name" + val, "")
      );
      this.props.dispatch(
        change("CreateCiCategoryForm", "sub_category_name" + val, "")
      );
    });
    this.setState({
      arrayCounts: 1,
      extraCount: this.state.extraCounts.splice(
        1,
        this.state.extraCounts.length
      ),
      catStatuses: [true],
    });

    if (this.state.createUsing === "new")
    
      this.props.getCICategoryListData(this.state.selectedCompany, this.state.selectedClass, 'both');
    }

  renderClass(classData) {
		//console.log(classData)
		if (!classData) {
			return (
				<option></option>
			);
		}
		return classData.map((classObj, i) => {
			// if (classObj.CLASS_NAME != "Service")
			return (
				<option value={classObj.CLASS_ID} key={i}>{classObj.CLASS_NAME}</option>
			);
		});
	}

  render() {
    return (
      <div>
        <Row>
          <Col md={6}>
            <Form.Group bsPrefix=" ">
              <Form.Label bsClass="">
                <span className="rStar"></span> {this.props.tr["Company"]}
              </Form.Label>
              <Field
                name="ciCompany"
                className="form-control"
                component={_dropDown}
                options={this.props.ciCompany}
                validate={[required]}
                onChange={(event) => this.handleciCompanyChange(event)}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group bsPrefix=" ">
              <Form.Label bsClass="">
                <span className="rStar"></span> {this.props.tr["Class"]}
              </Form.Label>

              <Field
                name="classId"
                className="form-control"
                component="select"
                onChange={(event) => this.handleciClassChange(event)}
                validate={[required]}
              >
                <option value="">Select</option>
                {this.renderClass(this.props.compClass)}
              </Field>
            </Form.Group>
          </Col>
        </Row>
        <div className="bgGray padding-5 margin-t-15 inciSupProviderDv border border-bottom-0 d-flex rBoxStyle" bsPrefix=" ">      
          <div className="px-2">           
           <span className="rStar"></span> 
            <span className="px-1 fw-semibold"> 
              {this.props.tr["Category Details"]}
            </span>
          </div>
          <div className="ms-auto">
            <Button
              className="showwAllGrps rgSidrkBtn smallBtn"            
              onClick={() => this.handleNewExist()}
            >
              {this.state.createUsing === "new"
                ? "Create for existing category"
                : "Create for new category"}
            </Button>
          </div>
          <div className="ms-2">        
            <Button className={`showwAllGrps smallBtn rgSidrkBtn ${this.state.createUsing === 'default' ? 'active' : ''}`}
           
              onClick={this.handleDefaultCategory}
            >
              Create from default
            </Button>        
          </div>
        </div>
        <div className="commonForm">
          <Table bordered>
            <thead>
              <tr>
                  <th width="42%">
                    <div className="d-flex">
                    {this.props.tr["Category"]}                   
            </div> 
            </th>
                <th width="42%">{this.props.tr["Sub-Category"]}</th>
                <th width="16%">{this.props.tr["Action"]}</th>
              </tr>
            </thead>
            <tbody>{this.loadCount()}</tbody>
          </Table>
        </div>
      </div>
    );
  }
}

CreateCiCategoryForm = reduxForm({
  form: "CreateCiCategoryForm",
  enableReinitialize: true,
})(CreateCiCategoryForm);

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loadCIClass,
      loadClassByCompanyId,
      resetCiClass,
      loadCICompany,
      getCICategoryListData,
      emptyCICategoryListDataReducer,
    },
    dispatch
  );
};
const mapStateToProps = ({ compClass,ciClass, ciCompany, ciCategoryListData }) => {
  let filteredData = [];
  if (isArray(ciCategoryListData.data)) {
    ciCategoryListData.data.map((element) => {
      if (
        filteredData.find(
          (elem) => elem.category_name === element.category_name
        )
      ) {
      } else {
        filteredData.push(element);
      }
    });
  }
  console.log("filteredData", filteredData);
  return { compClass, ciClass, ciCompany, ciCategoryListData, filteredData };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateCiCategoryForm);
