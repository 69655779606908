
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Row, Col, Form, Table, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import { getApprovals, getApprovalQuestions, setEditServiceDetails, setForm, getServiceDetailCounts } from '../../../actions/spcmActions';
import ApprovalForm from './ApprovalForm.js';
import axios from 'axios';
import Pagination from "react-js-pagination";

import ListLoader from "_Commons/loaders/ListLoader";
import Swal from 'sweetalert2';

class ListView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			queryBy: 'Role',
			query: '',
			sortBy: 'Role',
			pageSize: 10,
			showError: false,
			error: 'Changes are not allowed in Deployed status',
			sortByTitle: '',
			currentForm: 'approval_form',
			extraCounts: [0],
		}
		this.onTextChange = this.onTextChange.bind(this);
		this.onPageChange = this.onPageChange.bind(this);
		this.onPageSizeChange = this.onPageSizeChange.bind(this);
		this.onSearchClick = this.onSearchClick.bind(this);
		this.queryBySelect = this.queryBySelect.bind(this);
		this.onDeleteClick = this.onDeleteClick.bind(this);
		this.onSort = this.onSort.bind(this);
		this.handleDismiss = this.handleDismiss.bind(this);
		this.sortHover = this.sortHover.bind(this);
		this.setExtraCount = this.setExtraCount.bind(this);
	}

	handleKeyPress(e) {
		if (e.key === 'Enter') {
			this.onSearchClick();
			if (e.preventDefault) e.preventDefault();
		}
	}

	setExtraCount(e) {
		this.setState({ extraCounts: e });
	}

	onTextChange(e) {
		this.setState({ query: e.target.value });
	}

	onPageSizeChange(e) {
		this.setState({ pageSize: e.target.value });
		this.props.getApprovals(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID, this.state.queryBy, this.state.query, this.state.sortBy, 1, e.target.value);
	}

	onPageChange(e) {
		this.props.getApprovals(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID, this.state.queryBy, this.state.query, this.state.sortBy, e, this.state.pageSize);
	}

	onSearchClick() {
		this.props.getApprovals(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID, this.state.queryBy, this.state.query, this.state.sortBy);
	}

	queryBySelect(e) {
		this.setState({ queryBy: e.target.value });
	}

	sortHover(e) {
		let index = e.nativeEvent.target.selectedIndex;
		this.setState({ sortByTitle: e.nativeEvent.target[index].text })
	}


	onDeleteClick(values) {
		console.log("values delete");
		console.log(values);
		if (this.props.serviceStatusState == "Deployed") {
			this.setState({ showError: true });
		} else {
			Swal.fire({
				title: "Are you sure you wish to delete this item?",
				
				width: 550,
				padding: '15px',
				showDenyButton: true,
				confirmButtonText: 'Yes',
				denyButtonText: 'No',
			  }).then((result) => {
				if (result.isConfirmed) {
					if (this.state.currentForm === "approval_form") {
						values.status = 0;
						axios.patch("/service/api/approval/editApprovalRule/" + values.ruleId, values).then((response) => {
							this.props.getApprovals(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
	  
						})
							.catch((error) => {
								throw new SubmissionError({ _error: error.response.data[0].message });
							});
					}
	  
					else {
						axios.delete("/service/api/approval/deleteApprovalQuestions/" + values.question_id).then((response) => {
							this.props.getApprovalQuestions(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID, this.state.queryBy, this.state.query, this.state.sortBy, 1, 10);
							this.props.getServiceDetailCounts(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
						})
							.catch((error) => {
								throw new SubmissionError({ _error: error.response.data[0].message });
							});
					}
					} else if (result.isDenied) {
				  
				}
			  })
		}

	}

	handleDismiss() {
		this.setState({ showError: false });
	}

	onSort(e) {
		this.setState({ sortBy: e.target.value });
		this.props.getApprovals(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID, this.state.queryBy, this.state.query, e.target.value)
	}

	componentWillMount() {
		this.props.setForm('approvalForm');
		this.props.getApprovals(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID, this.state.queryBy, this.state.query, this.state.sortBy, 1, 10);
	}

	getListHandler(e) {
		console.log("getlist");
		console.log(e);
		this.setState({ currentForm: e });
		if (e == 'approval_form') {
			this.props.getApprovals(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID, this.state.queryBy, this.state.query, this.state.sortBy, 1, 10);
		}
		else if (e == 'approval_question_form') {
			this.props.getApprovalQuestions(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID, this.state.queryBy, this.state.query, this.state.sortBy, 1, 10);
		}

	}

	render() {
		const tr = this.props.tr;
		var meta = this.props.spcmReducer.approvalMeta;
		console.log(this.props.spcmReducer);
		console.log(meta);
		var approvals = this.props.spcmReducer.approval;
		var approval_questions = this.props.spcmReducer.approval_questions;
		console.log(approval_questions);
		console.log("=========" + approvals)
		return (
			<div className="">

				{this.props.componentFormToggle3 ? <ApprovalForm tr={tr} componentFormFun4={this.props.componentFormFun3} formSelection={this.state.currentForm} setExtraCount={this.setExtraCount} extraCounts={this.state.extraCounts} setServiceStatus={this.setServiceStatus} serviceStatusState = {this.props.serviceStatusState} /> : <div className='rBoxGap'>
				{this.state.showError && (<Alert style={{ "marginBottom": "15px", "fontSize": "13px", "padding": "8px 30px 8px 8px" }} variant="danger" onDismiss={this.handleDismiss}><h4>{this.state.error}</h4></Alert>)}
					<Row className="borderForm  row">
						<Col xs={12}>
							<Form.Group className="radioInlineBox">
								<div className="radioInlineBox float-l">
									<label className="radio-inline"><input type="radio" name='req_info_chk' value='approval_form' onChange={(event) => { this.getListHandler(event.target.value) }} checked={this.state.currentForm == 'approval_form'} />{this.props.tr['Approvals']}</label>
									<label className="radio-inline"><input type="radio" name='req_info_chk' value='approval_question_form' onChange={(event) => { this.getListHandler(event.target.value) }} checked={this.state.currentForm == 'approval_question_form'} />{this.props.tr['Approver Questions']}</label>

								</div>
							</Form.Group>
						</Col>
					</Row>
					{this.state.currentForm == 'approval_form' ?
						<div>
							<Row className="">
								{/* <Col md={8} sm={8} xs={12}>
						<div className="form-inline rightSideFields display-inline-block">

							<Form.Control onMouseOver={this.sortHover} title={this.state.sortByTitle} className="myControl mySel mySelectWidth" componentClass="select" onChange={ this.queryBySelect }>
								<option value="Role">Role</option>
								<option value="Company">Company Name</option>
							</Form.Control>
							<Form.Control onKeyPress={this.handleKeyPress.bind(this)}  style={{width:'100px'}} className="myControl myInput margin-l-5 myInputWidth" type="text" onChange={ this.onTextChange }/>
							<Button type="submit" title="Search" onClick={ this.onSearchClick } className="myBtn"><i className="fa fa-search"></i></Button>
						</div>
					</Col> */}
								<Col md={12} sm={12} xs={12} className="text-r float-end rightSideFields">

									<Form.Label className="margin-r-5 display-inline-block widthAuto">{tr['Show']}</Form.Label>
									<Form.Select className="display-inline-block widthAuto" onChange={this.onPageSizeChange}>
										<option value="10" selected={this.state.pageSize == 10 ? true : false}>10</option>
										<option value="25" selected={this.state.pageSize == 25 ? true : false}>25</option>
										<option value="50" selected={this.state.pageSize == 50 ? true : false}>50</option>
										<option value="100" selected={this.state.pageSize == 100 ? true : false}>100</option>
									</Form.Select>
								</Col>
							</Row>

							{this.props.spcmReducer.spcmFetchingError == true && this.props.spcmReducer.spcmFetchingModule == 'APPROVAL' ? <Alert variant="danger" className="errorMessageSec margin-t-20"><p>Oops! Something went wrong.</p></Alert> : null}
							<div className="f-size-12">

								<Table bordered condensed hover responsive className="tableView  tableWithOutPointer">
									<thead>
										<tr>
											<th width="60%" className="sort">{tr['Rule Name']}</th>
											<th width="20%" className="sort">{tr['Module']}</th>
											<th width="10%" className="text-c">{tr['Action']}</th>
										</tr>
									</thead>
									{this.props.spcmReducer.spcmFetchingAttribute ?

										<tbody><td colSpan="3">
											<ListLoader />
										</td></tbody> :

										<tbody>
											{approvals == "" || approvals == undefined ? null : approvals.map(function (value, index) {
												return (<tr>
													<td>{value.ruleName}</td>
													<td>{value.moduleName}</td>
													<td><div className="text-c"><i title={tr["Edit"]} onClick={() => {
														this.props.setEditServiceDetails(value);
														this.props.componentFormFun3(true, 'Edit', value.ruleId);
													}}
														className="fa fa-pencil-square-o cursorPoint"></i>
														<i title={tr["Delete"]} className="margin-l-10 fa fa-trash-o cursorPoint" onClick={() => { this.onDeleteClick(value) }}></i></div></td>
												</tr>)
											}, this)}
											{
					                     		approvals.length == 0 ? <tr><td colSpan={4} className="text-c">{tr['No Data Found']}</td></tr> : null
					                    	}	
										</tbody>
									}
								</Table>


							</div>


							<div className='nBotPagina'>
							 <nav aria-label="Pagination" className="display-inline-block">
								{/* <Pagination className="margin-0" prev next boundaryLinks items={meta?meta.totalPageCount:1} maxButtons={3} activePage={meta?meta.currentPage:1}  onSelect={this.onPageChange}/> */}


								{meta ? <Pagination
									activePage={meta ? meta.currentPage : 1}
									itemsCountPerPage={this.state.pageSize}
									totalItemsCount={meta ? meta.rowCount : 1}
									onChange={this.onPageChange}
								/> : null}
							 </nav>
							</div>

						</div> :
						<div>
							<Row className="">
								{/* <Col md={8} sm={8} xs={12}>
					<div className="form-inline rightSideFields display-inline-block">

						<Form.Control onMouseOver={this.sortHover} title={this.state.sortByTitle} className="myControl mySel mySelectWidth" componentClass="select" onChange={ this.queryBySelect }>
							<option value="Role">Role</option>
							<option value="Company">Company Name</option>
						</Form.Control>
						<Form.Control onKeyPress={this.handleKeyPress.bind(this)}  style={{width:'100px'}} className="myControl myInput margin-l-5 myInputWidth" type="text" onChange={ this.onTextChange }/>
						<Button type="submit" title="Search" onClick={ this.onSearchClick } className="myBtn"><i className="fa fa-search"></i></Button>
					</div>
				</Col> */}
								<Col md={12} sm={12} xs={12} className="text-r float-end rightSideFields">

									<Form.Label className="margin-r-5 display-inline-block widthAuto">{tr['Show']}</Form.Label>
									<Form.Select className="display-inline-block widthAuto" onChange={this.onPageSizeChange}>
										<option value="10" selected={this.state.pageSize == 10 ? true : false}>10</option>
										<option value="25" selected={this.state.pageSize == 25 ? true : false}>25</option>
										<option value="50" selected={this.state.pageSize == 50 ? true : false}>50</option>
										<option value="100" selected={this.state.pageSize == 100 ? true : false}>100</option>
									</Form.Select>
								</Col>
							</Row>

							{this.props.spcmReducer.spcmFetchingError == true && this.props.spcmReducer.spcmFetchingModule == 'APPROVAL' ? <Alert variant="danger" className="errorMessageSec margin-t-20"><p>Oops! Something went wrong.</p></Alert> : null}
							<div className="f-size-12">

								<Table bordered condensed hover responsive className="tableView  tableWithOutPointer">
									<thead>
										<tr>
											<th width="50%" className="sort">{tr['Question']}</th>
											<th width="30%" className="sort">{tr['Response Value']}</th>
											<th width="10%" className="text-c">{tr['Level']}</th>
											<th width="10%" className="text-c">{tr['Action']}</th>
										</tr>
									</thead>
									{this.props.spcmReducer.spcmFetchingAttribute ?

										<tbody><td colSpan="4">
											<ListLoader />
										</td></tbody> :

										<tbody>
											{approval_questions == "" || approval_questions == undefined ? null : approval_questions.map(function (value, index) {
												console.log("checking");
												console.log(value);
												return (<tr>
													<td>{value.questiontext}</td>
													<td>{value.questionvalue}</td>
													<td>{value.leveltext}</td>
													<td><div className="text-c"><i title={tr["Edit"]} 
														onClick={() => {
															this.props.setEditServiceDetails(value);
															this.props.componentFormFun3(true, 'Edit', value.question_id);
														}}
														className="fa fa-pencil-square-o cursorPoint"></i>
														<i title={tr["Delete"]} className="margin-l-10 fa fa-trash-o cursorPoint" onClick={() => { this.onDeleteClick(value) }}></i>

													</div></td>
												</tr>)
											}, this)}
										</tbody>
									}
								</Table>


							</div>

							<div className='nBotPagina'>
							<nav aria-label="Pagination" className="display-inline-block">
								{/* <Pagination className="margin-0" prev next boundaryLinks items={meta?meta.totalPageCount:1} maxButtons={3} activePage={meta?meta.currentPage:1}  onSelect={this.onPageChange}/> */}


								{meta ? <Pagination
									activePage={meta ? meta.currentPage : 1}
									itemsCountPerPage={this.state.pageSize}
									totalItemsCount={meta ? meta.rowCount : 1}
									onChange={this.onPageChange}
								/> : null}
							</nav>
								</div>
						</div>}
				</div>
				}
			</div>
		);
	}
}
const mapStateToProps = ({ spcmReducer }) => {
	return {
		spcmReducer

	}
};

export default connect(mapStateToProps, { getApprovals, getApprovalQuestions, setEditServiceDetails, setForm, getServiceDetailCounts })(ListView);
