
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
if (process.env.NODE_ENV === 'production'){
  module.exports = require('./configureStore.prod');
} else {
  module.exports = require('./configureStore.dev');
}
