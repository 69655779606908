
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, InputGroup, Image, Alert, Dropdown, DropdownButton, Tab, Tabs } from 'react-bootstrap';
import { useSelector,useDispatch } from "react-redux";
import dummyimage from "_Images/service-icons/service-dummy.png";

import PersonalInfoForm from "./personal-information";
import OrganizationalInfoForm from "./organizational-information";
import UserInfoForm from "./user-information";
import { change, Field, reduxForm } from 'redux-form';
import { _inputField, _dropDown, _textArea, TypeaheadandDropdown, TypeaheadExampleSingleSelect } from '../../../../common/ReduxFormFields/CategoryBoard/formFields';
import { getCompanyOptionsList } from "../../../../../actions/foundation/commonAction";
import {getComapanyBasedDepartment,getCompanyBasedUserInfoAction,getUserInformationListAction} from "../../../../../actions/foundation/userAction";


const CreateForm = (props) => {
    const { isOrgInfoMandatory = true } = props;
    const tr = useSelector((state) => state.spcmReducer.tr);
    const dispatch = useDispatch();
    const [key, setKey] = useState(1);
    const [companyList, setCompanyList] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState([]);
    const companyData = useSelector((state) => state.costCenterCompanyDropdown);
    const userDepListRedData = useSelector((state) => state.userDepListRed);
    const userOrgListRedData = useSelector((state) => (state.userOrgListRed));
    const userManagerRedData = useSelector((state) => state.userManagerRed);
    const userLocationRedData = useSelector((state) => (state.userLocationRed));
    const userCostCenterRedData = useSelector((state) => (state.userCostCenterRed));
    const cwlDataRedData = useSelector((state) => (state.cwlDataRed));

    useEffect(() => {
        dispatch(getCompanyOptionsList());              
      }, [])
    useEffect(() =>{
      if(companyData && companyData.length > 0){
        setCompanyList(companyData)
      }
    }, [companyData]);
    const onCompanySelection=(selectedCompany)=>{
        let error = '';
        if (selectedCompany.length === 0) {
            error = 'error';
          props.setCompanyErrorColor(error);

        }
        if (selectedCompany.length > 0) { 
            setSelectedCompany(selectedCompany)
            dispatch(change('userForm', 'companyName', selectedCompany[0].label))
            dispatch(change('userForm', 'companyId', selectedCompany[0].id))
            props.setCompanyValue(selectedCompany);
            props.setCompanyErrorColor('');

            dispatch(getCompanyBasedUserInfoAction(selectedCompany[0].id)); 
            
            props.updateCompanyOrgInfoReq(selectedCompany[0].id);
          } else {
          
            setSelectedCompany([]);
            dispatch(change('userForm', 'companyName', ''));
            dispatch(change('userForm', 'companyId', ''));
            dispatch(change("userForm","locationName", ''));
            dispatch(change("userForm", "locationId", ''));
            dispatch(change("userForm","costcenterName", ''));
            dispatch(change("userForm", "costcenterId", ''));
            dispatch(change("userForm","cwlName", ''));
            dispatch(change("userForm", "cwlId", ''));
            dispatch(change("userForm","managerName", ''));
            dispatch(change("userForm", "managerId", ''));
            dispatch(change("userForm", "organizationId", ''));
            dispatch(change("userForm", "departmentId", ''));
            props.updateCompanyOrgInfoReq(null, 'clear');
          }

    }

    const onCrossClickCompany = () => {
        setSelectedCompany([]);  
        props.setCompanyErrorColor('error');
        dispatch(change('userForm', 'companyName', ''));
        dispatch(change('userForm', 'companyId', ''));
        dispatch(change("userForm","locationName", ''));
        dispatch(change("userForm", "locationId", ''));
        dispatch(change("userForm","costcenterName", ''));
        dispatch(change("userForm", "costcenterId", ''));
        dispatch(change("userForm","cwlName", ''));
        dispatch(change("userForm", "cwlId", ''));
        dispatch(change("userForm","managerName", ''));
        dispatch(change("userForm", "managerId", ''));
        dispatch(change("userForm", "organizationId", ''));
        dispatch(change("userForm", "departmentId", ''));
        props.updateCompanyOrgInfoReq(null, 'clear');
    }

    const  validateGroupsField = (fieldType, event)=> {
        let specialCharRegex = /[^-_'.@&0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz]/;
        switch (fieldType) {
          case "firstname":
            if (event.target.value.trim() == "") {
                props.setFirstName("error");
            } else if (event.target.value.trim().match(specialCharRegex)) {
              props.setFirstName("error");
            } else {
              props.setFirstName("");
            }
            break;
          case "username":
            if (event.target.value == "") {
              props.setUsernameErr("error");
            } else if (event.target.value.trim().match(specialCharRegex)) {
              props.setUsernameErr("error");
            } else {
              props.setUsernameErr("");
            }
            break;
          case "lastname":
            if (event.target.value == "") {
                props.setLastnameErr("error");
            } else if (event.target.value.trim().match(specialCharRegex)) {
              props.setLastnameErr("error");
            } else {
              props.setLastnameErr("");
            }
            break;
          case "middlename":
          if (event.target.value.trim() !== "") {
            if (event.target.value.trim().match(specialCharRegex)) {
              props.setMiddlenameErr("error");
            }
          }
          else {
            props.setMiddlenameErr("");
          }
          break;
          default:
        }
    }

    return (
        <>
        <Row>
            <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                    <Form.Label><span className="rStar"></span>Username</Form.Label>
                    {/* <Form.Control type="text" name="username" placeholder="" /> */}
                    <Field
                        name="username"
                        component={_inputField}
                        className={"form-control "+ (props.userNameErr)}
                        maxLength={50}
                        onBlur={(event) => {
                            validateGroupsField("username", event);
                        }}
                    />
                </Form.Group>
            </Col>

            {/* <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group priceForm-group withImgbox">
                    <Form.Label>Profile Picture <b className='margin-l-10 f-size-11'>(upload only .jpeg,.jpg,.png,.giff,.gif)</b>
                    </Form.Label>
                    <InputGroup>
                        <div><Form.Control type="file" /></div>
                        <InputGroup.Text>
                            <Image src={dummyimage} />
                        </InputGroup.Text>
                    </InputGroup>
                </Form.Group>
            </Col> */}

            <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                    <Form.Label><span className="rStar"></span>First Name</Form.Label>
                    {/* <InputGroup className="mb-3 userInGrp">
                        <DropdownButton variant="outline-secondary" title="Mr" id="">
                            <Dropdown.Item href="#">Mr</Dropdown.Item>
                            <Dropdown.Item href="#">Miss</Dropdown.Item>
                            <Dropdown.Item href="#">Ms</Dropdown.Item>
                        </DropdownButton> */}
                        {/* <Form.Control type="text" name="firstname" placeholder="" /> */}
                        <div className="userInGrp d-flex align-items-center">
                        <Field
                            name="title"
                            component="select"
                            className="form-control"
                        >
                            <option value="Mr">Mr</option>
                            <option value="Miss">Miss</option>
                            <option value="Ms">Ms</option>

                        </Field>
                        
                        <Field
                        name="firstname"
                        component={_inputField}
                        className={"form-control "+ (props.firstNameErr)}
                        maxLength={50}
                        onBlur={(event) => {
                            validateGroupsField("firstname", event);
                        }}
                    />
                    </div>
                    {/* </InputGroup> */}
                </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                    <Form.Label>Middle Name</Form.Label>
                    {/* <Form.Control type="text" name="middlename" placeholder="" /> */}
                    <Field
                        name="middlename"
                        className={"form-control " + (props.middlenameErr)}
                        component={_inputField}
                    />
                </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                    <Form.Label><span className="rStar"></span>Last Name </Form.Label>
                    {/* <Form.Control type="text" name="lastname" placeholder="" /> */}
                    <Field
                        name="lastname"
                        className={"form-control "+ (props.lastnameErr) }
                        component={_inputField}
                        maxLength={50}
                        onBlur={(event) => {
                            validateGroupsField("lastname", event);
                        }}
                    />
                </Form.Group>
            </Col>

            {/* <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                    <Form.Label>Company <span className="rStar"></span></Form.Label>
                    <Form.Select aria-label="select" name="companyName" className="form-control">
                        <option selected disabled>Select</option>
                    </Form.Select>
                </Form.Group>
            </Col> */}
                <Col md={6} sm={6} xs={12}>
                    <Form.Group className="form-group">
                        <span className="rStar" />
                        <Form.Label bsClass="">
                            {tr["Company"]}
                        </Form.Label>
                        <Field
                            name="companyName"
                            className="form-control"
                            component={TypeaheadandDropdown}
                            onSelection={onCompanySelection}
                            options={companyList}
                            selectedOptions={selectedCompany}
                            onCrossClick={onCrossClickCompany}
                            errorClass={props.companyTypeErr}
                            // setErrorColor={props.setCompanyErrorColor}
                        />
                    </Form.Group>
                </Col>

            {/* <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                    <Form.Label>Full Name</Form.Label>
                    <Field
                            name="fullname"
                            component={_inputField}
                            className={"form-control "}                            
                    />
                </Form.Group>
            </Col> */}

            {/* <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                    <Form.Label>Person Code</Form.Label>
                    <Field
                        name="personCode"
                        className={"form-control " }
                        component={_inputField}
                    />
                </Form.Group>
            </Col> */}
        </Row>


        <div className="margin-t-20">
            <div className="tabStyle">
                <Tabs activeKey={key} onSelect={(k) => setKey(k)} id="tabId">
                    <Tab eventKey={1} title="Personal Information"><PersonalInfoForm 
                            setEmailErr={props.setEmailErr}
                            emailErr={props.emailErr}
                            setBusinessErr={props.setBusinessErr}
                            businessErr={props.businessErr}
                            setMobileErr={props.setMobileErr}
                            mobileErr={props.mobileErr}
                            setcontactErr={props.setcontactErr}
                            contactErr={props.contactErr}
                    /></Tab>
                    <Tab eventKey={2} title="Organizational Information"><OrganizationalInfoForm
                        userDepListRedData = {userDepListRedData}
                        userOrgListRedData = {userOrgListRedData}
                        userManagerRedData = {userManagerRedData}
                        userLocationRedData = {userLocationRedData}
                        userCostCenterRedData = {userCostCenterRedData}
                        cwlDataRedData = {cwlDataRedData}
                        selectedCompany = {selectedCompany}
                        setLocationErr={props.setLocationErr}
                        locationErr={props.locationErr}
                        setLocationErrColor={props.setLocationErrColor}
                        setCCErr={props.setCCErr}
                        setCCErrColor={props.setCCErrColor}
                        ccErr={props.ccErr}
                        setCWLErr={props.setCWLErr}
                        setCWLColorErr={props.setCWLColorErr}
                        cwlErr={props.cwlErr}
                        setOrgzErr={props.setOrgzErr}
                        orgzErr={props.orgzErr}
                        setDepErr={props.setDepErr}
                        depErr={props.depErr}
                        isMandatory={isOrgInfoMandatory}

                    /></Tab>
                    <Tab eventKey={3} title="User Information"><UserInfoForm 
                    setUserTypeErr={props.setUserTypeErr}
                    userTypeErr={props.userTypeErr}
                    setLanguageErr={props.setLanguageErr}
                    languageErr={props.languageErr}
                    setTimeZoneErr={props.setTimeZoneErr}
                    timeZoneErr={props.timeZoneErr}
                    setStatusErr={props.setStatusErr}
                    statusErr={props.statusErr}
                    setDateFormErr={props.setDateFormErr}
                    dateFormErr={props.dateFormErr}
                    setCurrencyErr={props.setCurrencyErr}
                    currencyErr={props.currencyErr}
                    setAuthSource={props.setAuthSource}
                    authSource={props.authSource}/></Tab>
                </Tabs>
            </div>
        </div>
        </>
    )
}

// export default CreateForm;

export default reduxForm({
    form: 'userForm',
    destroyOnUnmount: false,
    enableReinitialize: true
})(CreateForm);