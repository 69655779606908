
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { destroy } from "redux-form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ListGroup from "react-bootstrap/ListGroup";
import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";
import OnClickOutside from "react-onclickoutside";
import AiIcon from "../../../common/aiIcon";

import TranslateIconPopup from "../../../common/translatePopup.js";

import {
  Field,
  reduxForm,
  formValueSelector,
  change,
  onChange,
  getFormValues,
} from "redux-form";
import { _inputField } from "../../../common/ReduxFormFields/formFields";
import { ImInfo } from "react-icons/im";
import { PiUserSwitch } from "react-icons/pi";
import { RiUserSearchLine } from "react-icons/ri";
import { connect } from "react-redux";
import axios from "axios";
import { bindActionCreators } from "redux";
import { GLOBAL } from "_Globals";
import { loadBreakFixSourceName } from "../../../../actions/breakFix/sourceAction";
import { loadBreakFixSG } from "../../../../actions/breakFix/serviceGroupAction";
import { loadBreakFixSGI } from "../../../../actions/breakFix/serviceGroupIAction";
import { loadBreakFixEditDetails } from "../../../../actions/breakFix/breakFixEditDetailsAction";
import { loadBreakFixStatusDetails } from "../../../../actions/breakFix/breakFixStatusAction";
// import {
//   loadBreakFixSGAEEdit,
//   loadNewBreakFixSGAEWithQueryParams,
// } from "../../../../actions/breakFix/serviceGroupAEAction";
import {
  getBreakfixGroupData,
  getAllgroup,
} from "../../../../actions/amsActions/amsActions";
import RFReactService from "../../../common/WorkItemBoard/AutoSearchService";
import RFReactConsumer from "../../../common/WorkItemBoard/AutoSearchConsumer";
import CIDetailsService from "../../../common/WorkItemBoard/ciNameSelectEdit";
import GenericTimeline from "../../../common/WorkItemBoard/genericTimeline";
import {
  TypeaheadExampleSingleSelect,
  TypeaheadExampleSingle,
} from "../../../common/formFields";
import { getBreakfixQuestionsCategoryWise } from "../../../../actions/cart/myOrders/myOrdersAction";
import ListLoader from "../../../common/loaders/loader";
import { RuleAmsDdropDown } from "../../../common/forms/ruleDropDown";
import swal from "sweetalert";
import { GrGroup } from "react-icons/gr";
import { TbFolderSymlink } from "react-icons/tb";
import { RiLinksFill } from "react-icons/ri";
import { getAdminPreferences } from "../../../../actions/adminPreferences/adminPreferencesAction";
import { Modal } from 'react-bootstrap';
import SmartCat from '../../../../samrtCat.js';

// let breakFixActualItemId = '';
let serviceData = [];

let enableButtonData = [];
let myObj = [];
let ciNameData = [];
let ciNameDataDetails = [];
let ciNameObject = [];
let SpIndSelectedList = [];
let groupdata = [];
let editGrpData = [];

import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import AismIndividualTypeAhead from "../../../common/WorkItemBoard/AismIndividualTypeAhead";
import { FaLanguage } from "react-icons/fa6";
import { MdGTranslate } from "react-icons/md";
import { CharacterizatinForm } from "../create/CategorizationForm.js";
const cookies = new Cookies();
let gph = cookies.get("gph").split("~");  
let languageSelected = cookies.get("gph");
languageSelected = languageSelected.split("~");
languageSelected = languageSelected[20];
const showTranslator = gph[67];

const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});
class EditForm extends React.Component {
  constructor(props) {
    GLOBAL.breakfixongrpchangeid = "";
    super(props);

    this.state = {
      searchResultsDiv: false,
      readOnly: true,
      statusActionValue: "",
      supportPGroup: "",
      supportPIndividual: "",
      priorityValue: "",
      priorityIdValue: "",
      urgencyValue: "",
      criticalityErrorColor: "",
      issueDescription: "",
      supportPIndividualErrorColor: "",
      consumerDataDetails: [],
      supportPGroupErrorColor: "",
      companyId: "",
      AllAMSGrps: false,
      ShowAllAMSGRP: true,
      Disable: true,
      serviceListBasedOnCompany: [],
      urgencyDropdownValue: "",
      criticalityDropdownValue: "",
      supportPAllGroupErrorColor: "",
      SpAllGroupSelectedList: [],
      SpGroupSelectedList: [],
      isLoading: "",
      typedGroup: "",
      typedIndividual: "",
      isVIPuser: "",
      showVIPIcon: "",
      selectedCIorServiceID: "",
      selectedCI: "",
      selectedService: "",
      CIselectedID: "",
      selectedServiceID: "",
      selectedServiceName: "",
      serviceIDSelected: "",
      showGrpReason: false,
      initialGroupId: "",
      supportIndividualValue: "",
      consumerValue: "",
      showIndividualInfo: false,
      showConsumerInfo: true,
      isCiNameInfoIcn: true,
      impactedIDSelected: "",
      isServiceInfoIcn: false,
      disableTypeAhead: false,
      urgencyList: {},
      impactList: {},
      priorityList: {},
      selectedCompanyId: "",
      //companyList:'',
      //companyError:''
      rulesAttribute: {},
      showAismGroups: false,
      isLoadingAismIndividual: false,
      aiGrpPredictionEnabled: false,
      aiIndPredictionEnabled: false,
			modalShow: false,
    };
    this.setDropDownValue = this.setDropDownValue.bind(this);
    this.onChangeCriticality = this.onChangeCriticality.bind(this);
    this.onChangeUrgency = this.onChangeUrgency.bind(this);
    this.validateField = this.validateField.bind(this);
    this.sorting = this.sorting.bind(this);
    this.AllGrps = this.AllGrps.bind(this);
    this.AllAMSGrps = this.AllAMSGrps.bind(this);
    this.ShowAMSGroup = this.ShowAMSGroup.bind(this);
    this.renderSupportAMSGroup = this.renderSupportAMSGroup.bind(this);
    this.onServiceNameChange = this.onServiceNameChange.bind(this);
    this.onImpactedSelection = this.onImpactedSelection.bind(this);
    this.setErrorColor = this.setErrorColor.bind(this);

    this.setCompany = this.setCompany.bind(this);
    this.onIndivSelection = this.onIndivSelection.bind(this);
    this.setSupIndErrorColor = this.setSupIndErrorColor.bind(this);
    this.onSupGroupSelection = this.onSupGroupSelection.bind(this);
    this.setSupGroupErrorColor = this.setSupGroupErrorColor.bind(this);
    this.onAllSupGroupSelection = this.onAllSupGroupSelection.bind(this);
    this.setAllSupGroupErrorColor = this.setAllSupGroupErrorColor.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.onGroupInputChange = this.onGroupInputChange.bind(this);
    this.onCrossClickGroup = this.onCrossClickGroup.bind(this);
    this.onIndividualInputChange = this.onIndividualInputChange.bind(this);
    this.onCrossClickIndividual = this.onCrossClickIndividual.bind(this);
    this.afterConsumerSelected = this.afterConsumerSelected.bind(this);
    this.setshowVIPIcon = this.setshowVIPIcon.bind(this);
    this.resettingConsumerInfoState =
      this.resettingConsumerInfoState.bind(this);
    this.setGroupReason = this.setGroupReason.bind(this);
    this.showGroupReason = this.showGroupReason.bind(this);
    this.checkforAMSattributes = this.checkforAMSattributes.bind(this);
    this.calculatePriority = this.calculatePriority.bind(this);
    this.setCriticalityPriority = this.setCriticalityPriority.bind(this);
    this.setUrgencyPriority = this.setUrgencyPriority.bind(this);
    this.renderInformationProvided = this.renderInformationProvided.bind(this);
    this.renderIndividualCategoryQuestions =
      this.renderIndividualCategoryQuestions.bind(this);
    this.fetchCompanyMatrix = this.fetchCompanyMatrix.bind(this);
    this.resetUrgencyandPriority = this.resetUrgencyandPriority.bind(this);
    this.resetGroupsandIndividual = this.resetGroupsandIndividual.bind(this);
    this.resetService = this.resetService.bind(this);
    this.resetImpactCI = this.resetImpactCI.bind(this);
    this.userAutoAssignment = this.userAutoAssignment.bind(this);
    this.resetServiceValue = this.resetServiceValue.bind(this);
    this.resetImpactedCIValue = this.resetImpactedCIValue.bind(this);
    this.onConsumerType = this.onConsumerType.bind(this);
    this.onServiceType = this.onServiceType.bind(this);
    this.onImpactCIType = this.onImpactCIType.bind(this);
    this.showAismGroupsFn = this.showAismGroupsFn.bind(this);
    this.resetDetails = this.resetDetails.bind(this);
    this.setIsAismIndLoader = this.setIsAismIndLoader.bind(this);
    this.showModal = this.showModal.bind(this);
    ciNameData = [];
    ciNameDataDetails = [];
    ciNameObject = [];
    GLOBAL.ciNameEdit = "";
    GLOBAL.ciIdEdit = "";
    GLOBAL.ciIdNumberEdit = "";
  }
  showModal(show) {
		const root = document.documentElement;
		show? root?.style.setProperty("--modalBackDrop", 0.5): root?.style.setProperty("--modalBackDrop", 0);
		this.setState({ modalShow: show });
	}

  setIsAismIndLoader(value) {
    this.setState({ isLoadingAismIndividual: value })
  }
  calculatePriority(companyID, moduleName, urgency, criticality) {
    return axios.get(
      "/api/getCompanyPriorityMatrix?companyId=" +
        companyID +
        "&module=" +
        moduleName +
        "&urgency=" +
        urgency +
        "&impact=" +
        criticality
    );
  }
  setCriticalityPriority(priority, criticality, urgency = "") {
    this.setState({
      criticalityDropdownValue: criticality,
      priorityValue: priority,
    });
    GLOBAL.priorityValueType = priority;
    this.checkforAMSattributes("impact", priority);
    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "criticality", criticality)
    );
    this.props.setImpactError("");
  }
  setUrgencyPriority(priority, urgency, criticality = "") {
    this.setState({
      urgencyDropdownValue: urgency,
      priorityValue: priority,
    });
    GLOBAL.priorityValueType = priority;
    this.checkforAMSattributes("urgency", priority);
    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "urgencyMode", urgency)
    );
    this.props.setUrgencyError("");
  }

  renderInformationProvided(myOrderQuestionDetails) {
    if (myOrderQuestionDetails.length <= 0) {
      return (
        <div>
          <h4>{this.props.translator["Data not available."]}</h4>
        </div>
      );
    }
    return []
      .concat(myOrderQuestionDetails)
      .sort((a, b) => (a.categorySequence > b.categorySequence ? 1 : -1))
      .map((category, i) => {
        return (
          <Accordion
            id={"questions" + i}
            accordion
            className="brkFix brkFixOrder"
            defaultActiveKey={i}
          >
            <Accordion.Item eventKey={i} className="">
              <Accordion.Header>
                <h4 toggle className="font1">
                  {category.categoryName}
                </h4>
              </Accordion.Header>
              <Accordion.Body collapsible>
                <div key={i}>
                  <Table
                    bordered
                    condensed
                    hover
                    responsive
                    className="tableView addWhiteSpc"
                  >
                    <tbody>
                      {this.renderIndividualCategoryQuestions(
                        category.questions
                      )}
                    </tbody>
                  </Table>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        );
      });
  }

  renderIndividualCategoryQuestions(categoryQuestions) {
    let answers = "";
    return []
      .concat(categoryQuestions)
      .sort((a, b) => (a.questionSequence > b.questionSequence ? 1 : -1))
      .map((categoryQuestion, i) => {
        if (categoryQuestion.question == "Assets") {
          //	console.log("assets answer");
          //	console.log(categoryQuestion.answer);
          let newstringreplaced = "";
          if (
            typeof categoryQuestion.answer === "undefined" ||
            categoryQuestion.answer === null ||
            categoryQuestion.answer === ""
          ) {
            answers = "NA";
          } else if (
            categoryQuestion.answer != null &&
            categoryQuestion.answer != ""
          ) {
            newstringreplaced = categoryQuestion.answer.replace(
              /,Name/gi,
              "~Name"
            );
            answers = newstringreplaced.split("~");
          } else {
            answers = categoryQuestion.answer;
          }
        }
        return (
          <tr key={i}>
            <td className="bgBtn20 f-size-11" style={{ width: "50%" }}>
              {categoryQuestion.question}
            </td>
            <td className="f-size-11">
              {/*	{categoryQuestion.question !== "Assets" ?categoryQuestion.answer :<div className="assetsAnswers">{answers.map((a,i)=>{return <p>{a}</p>})};</div>}*/}
              {categoryQuestion.question !== "Assets" ? (
                typeof categoryQuestion.answer === "undefined" ||
                categoryQuestion.answer === null ||
                categoryQuestion.answer === "" ? (
                  "NA"
                ) : (
                  categoryQuestion.answer
                    .replace(/ ~ /g, "\n")
                    .split("\n")
                    .map((data) => {
                      // console.log(data)
                      return <p>{data}</p>;
                    })
                )
              ) : answers != null ? (
                <div className="assetsAnswers">
                  {answers.map((a, i) => {
                    return <p>{a}</p>;
                  })}
                  ;
                </div>
              ) : null}
            </td>
          </tr>
        );
      });
  }

  setshowVIPIcon(value) {
    if (value === "") {
      this.setState({ showVIPIcon: this.state.isVIPuser });
    } else {
      this.setState({ showVIPIcon: value });
    }
  }
  afterConsumerSelected() {
    this.checkforAMSattributes();

    this.setshowVIPIcon((GLOBAL.Vip === "Yes" || GLOBAL.Vip==='yes') ? "yes" : "no");
    this.props.saveConsumerState({
      breakFixConsumerName: GLOBAL.breakFixConsumerName,
      breakFixConsumerID: GLOBAL.breakFixConsumerID,
      servicelocationid: GLOBAL.servicelocationid,
      servicelocationname: GLOBAL.servicelocationname,
      Vip: GLOBAL.Vip,
      servicedepartmentid: GLOBAL.servicedepartmentid,
      servicedepartmentname: GLOBAL.servicedepartmentname,
    });
    let value =
      GLOBAL.breakFixConsumerID !== "" &&
      GLOBAL.breakFixConsumerID !== "0" &&
      GLOBAL.breakFixConsumerID !== 0
        ? GLOBAL.breakFixConsumerID
        : this.props.breakFixEditDetails.impactedUserid !== "" &&
          this.props.breakFixEditDetails.impactedUserid !== "0" &&
          this.props.breakFixEditDetails.impactedUserid !== 0
        ? this.props.breakFixEditDetails.impactedUserid
        : "";
    this.setState({
      consumerValue: value,
      showConsumerInfo: value !== "" ? true : false,
    });
  }
  setCompany(event) {
    this.setState({ companyId: event.target.value });
    (GLOBAL.breakFixConsumerID = ""),
      (GLOBAL.ciId = ""),
      (GLOBAL.breakFixServiceID = ""),
      (GLOBAL.breakFixConsumerName = "");
    this.props.dispatch(change("xsmBreakFixEditFormTab", "supportPGroup", ""));
    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "supportPIndividual", "")
    );
    this.props.dispatch(change("xsmBreakFixEditFormTab", "ciName", ""));
    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "impactedServiceName", "")
    );
    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "supportPGroupName", "")
    );
    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "supportPIndividualName", "")
    );
    this.props.dispatch(change("xsmBreakFixEditFormTab", "supportP1Group", ""));
    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "supportP1GroupName", "")
    );
  }

  searchResults(event) {
    this.setState({ searchResultsDiv: true });
  }
  callbackFun(id) {
    this.setState({ searchResultsDiv: id });
  }
  componentWillUnmount() {
    // console.log("component will unmount");

    (GLOBAL.breakFixConsumerName = ""), (GLOBAL.breakFixConsumerID = "");
    GLOBAL.ciIdEdit =
      GLOBAL.ciNameEdit =
      GLOBAL.ciNameCopy =
      GLOBAL.ciIdNumberEdit =
      GLOBAL.ciClass =
      GLOBAL.ciClassNameSelected =
      GLOBAL.categoryNameEdit =
      GLOBAL.environmentNameEdit =
      GLOBAL.ciConsumerCompanyEdit =
      GLOBAL.ciSupportCompanyEdit =
      GLOBAL.ciClassIdEdit =
      GLOBAL.ciLocationIdEdit =
      GLOBAL.ciLocationNameEdit =
      GLOBAL.ciClassId =
      GLOBAL.ciClassName =
        "";
    GLOBAL.breakFixServiceID =
      GLOBAL.breakFixServiceName =
      GLOBAL.breakFixCompanyName =
      GLOBAL.consumerCompanybreakfix =
      GLOBAL.servicecatid =
      GLOBAL.servicecatname =
      GLOBAL.consumerCompanyName =
      GLOBAL.changeValue =
      GLOBAL.consumerCompanyId =
      GLOBAL.consumerCompanyName =
      GLOBAL.businessCriticalityId =
      GLOBAL.businessCriticality =
        "";
  }
  // componentDidUpdate(prevProps){
  // 	console.log('prevProps - ', prevProps, this.props);
  // }
  componentWillReceiveProps(newProps) {
    if (
      newProps?.aismProperty?.aismGrpProp &&
      !Array.isArray(newProps?.aismProperty?.aismGrpProp) &&
      Object.keys(newProps?.aismProperty?.aismGrpProp).length > 0
    ) {
      if (newProps?.aismProperty?.aismGrpProp?.value !== "") {
        try {
          const aismValue = JSON.parse(newProps?.aismProperty?.aismGrpProp?.value);
          if(aismValue?.aism === "true" || aismValue?.aism === true) {
            this.setState({
              aiGrpPredictionEnabled: true,
            });
          } else {
            this.setState({
              aiGrpPredictionEnabled: false,
            });
          }
        } catch (err) {
          console.log('Error: ', err);
        }
      } else {
        this.setState({
          aiGrpPredictionEnabled: false,
        });
      }
    } if (
      newProps?.aismProperty?.aismAgentProp &&
      !Array.isArray(newProps?.aismProperty?.aismAgentProp) &&
      Object.keys(newProps?.aismProperty?.aismAgentProp).length > 0
    ) {
      if (newProps?.aismProperty?.aismAgentProp?.value !== "") {
        try {
          const aismValue = JSON.parse(newProps?.aismProperty?.aismAgentProp?.value);
          if(aismValue?.aism === "true" || aismValue?.aism === true) {
            this.setState({
              aiIndPredictionEnabled: true,
            });
          } else {
            this.setState({
              aiIndPredictionEnabled: false,
            });
          }
        } catch (err) {
          console.log('Error: ', err);
        }
      } else {
        this.setState({
          aiIndPredictionEnabled: false,
        });
      }
    } else {
      this.setState({
        aiGrpPredictionEnabled: false,
        aiIndPredictionEnabled: false,
      });
    }

    if (this.props.selectedAssignmentUser !== newProps.selectedAssignmentUser) {
      this.onIndivSelection(newProps.selectedAssignmentUser);
    }

    if (newProps.isFormAPIsTriggered == true) {
      return;
    }

    if (
      newProps.breakFixEditDetails &&
      newProps.breakFixEditDetails.breakfixId &&
      newProps.isFormAPIsTriggered == false
    ) {
      GLOBAL.ciId =
        GLOBAL.breakFixConsumerID =
        GLOBAL.servicelocationid =
        GLOBAL.ciLocationId =
        GLOBAL.consumerCompanyId =
        GLOBAL.consumerCompanyName =
        GLOBAL.businessCriticalityId =
        GLOBAL.businessCriticality =
          "";
      GLOBAL.ciIdEdit =
        GLOBAL.ciNameEdit =
        GLOBAL.ciNameCopy =
        GLOBAL.ciIdNumberEdit =
        GLOBAL.ciClass =
        GLOBAL.ciClassNameSelected =
        GLOBAL.categoryNameEdit =
        GLOBAL.environmentNameEdit =
        GLOBAL.ciConsumerCompanyEdit =
        GLOBAL.ciSupportCompanyEdit =
        GLOBAL.ciClassIdEdit =
        GLOBAL.ciLocationIdEdit =
        GLOBAL.ciLocationNameEdit =
        GLOBAL.ciClassId =
        GLOBAL.ciClassName =
          "";
      GLOBAL.breakFixServiceID =
        GLOBAL.breakFixServiceName =
        GLOBAL.breakFixCompanyName =
        GLOBAL.consumerCompanybreakfix =
        GLOBAL.servicecatid =
        GLOBAL.servicecatname =
        GLOBAL.consumerCompanyName =
        GLOBAL.changeValue =
          "";
      groupdata = [];
      SpIndSelectedList = [];
      GLOBAL.breakFixConsumerName = "";
      GLOBAL.isExternalIncidentEdit =
        newProps.breakFixEditDetails.isExternal == null ||
        newProps.breakFixEditDetails.isExternal == "" ||
        newProps.breakFixEditDetails.isExternal == undefined
          ? false
          : newProps.breakFixEditDetails.isExternal;
      GLOBAL.AMSEXIST = "No";
      let str = {},
        impactedServiceData = [];
      str.companyId = newProps.breakFixEditDetails.consumerCompany;
      str = JSON.stringify(str);
      api
        .get("/api/foundations/serviceListSelfServiceCompanyId/", {
          headers: { query: str },
        })
        .then((response) => {
          impactedServiceData = response.data;
          let dataArr = structuredClone(this.state.serviceListBasedOnCompany);
          impactedServiceData.map((subItem) => {
            dataArr.push({
              value: subItem.offeringId,
              label:
                subItem.offeringName +
                "|" +
                subItem.categoryName +
                "|" +
                subItem.subCategoryName +
                "|" +
                subItem.companyName,
              companyname: subItem.company, //name here
              categoryid: subItem.categoryId,
              categoryname: subItem.categoryName,
              offeringname: subItem.offeringName,
              subCategoryname: subItem.subCategoryName,
              consumercompanyname: subItem.companyName,
            });
          });
          this.setState({ serviceListBasedOnCompany: dataArr });
        });

      this.state.supportPIndividual = newProps.breakFixEditDetails.spGroupid;
      GLOBAL.changeValue = false;
      // console.log("componentWillMOUNT CALLING");
      if (GLOBAL.reopen == true) {
        this.props.dispatch(
          change("xsmBreakFixEditFormTab", "supportPIndividual", "0")
        );

        this.props.dispatch(
          change("xsmBreakFixEditFormTab", "supportPIndividualName", "")
        );
      }
      // this.setState({
      // 	AllAMSGrps:false
      //   });
      this.setState({
        AllAMSGrps: false,
        selectedCIorServiceID: newProps.breakFixEditDetails.serviceBased
          ? newProps.breakFixEditDetails.serviceId
          : newProps.breakFixEditDetails.ciId,
        selectedCI: newProps.breakFixEditDetails.serviceBased
          ? 0
          : newProps.breakFixEditDetails.ciId,
        selectedService: newProps.breakFixEditDetails.serviceBased
          ? newProps.breakFixEditDetails.serviceId
          : 0,
      });
      this.props.loadBreakFixSourceName();
      if (newProps.initialValues.supportPIndividual == "0") {
        this.setState({ supportPIndividualErrorColor: "error" });
        GLOBAL.sgIErrorColor = "";
      }

      // breakFixActualItemId = this.props.breakFixItemId || "";

      str = {};
      str.userId = "";
      str.companyId = "";
      str.associatedCompanyId = "";
      str = JSON.stringify(str);
      let myObj0 = {};
      impactedServiceData = [];

      GLOBAL.priorityValueType = newProps.breakFixEditDetails.priorityValue;
      GLOBAL.supportPGroupNameEdit = newProps.breakFixEditDetails.spGroupname;
      GLOBAL.supportPIGroupNameEdit =
        newProps.breakFixEditDetails.spUsername == "0" ||
        newProps.breakFixEditDetails.spUserid == ""
          ? ""
          : newProps.breakFixEditDetails.spUsername;

      this.props.dispatch(
        change(
          "xsmBreakFixEditFormTab",
          "supportPGroupName",
          newProps.breakFixEditDetails.spGroupname
        )
      );
      this.props.dispatch(
        change(
          "xsmBreakFixEditFormTab",
          "supportPIndividualName",
          newProps.breakFixEditDetails.spUsername == "0" ||
            newProps.breakFixEditDetails.spUserid == ""
            ? ""
            : newProps.breakFixEditDetails.spUsername
        )
      );

      this.checkforAMSattributes("initial", -1);
      this.props.loadBreakFixSGI(newProps.breakFixEditDetails.spGroupid);

      let consumerCompanyID =
        newProps.breakFixEditDetails &&
        newProps.breakFixEditDetails.consumerCompany &&
        !isNaN(parseInt(newProps.breakFixEditDetails.consumerCompany, 10))
          ? parseInt(newProps.breakFixEditDetails.consumerCompany, 10)
          : "";

      this.setState({
        companyId: consumerCompanyID,
      });
      let obj1 = {};
      let obj3 = {};
      if (
        newProps.breakFixEditDetails.spUserid != undefined &&
        newProps.breakFixEditDetails.spUsername != undefined &&
        newProps.breakFixEditDetails.spUsername != "0"
      ) {
        obj1.id = newProps.breakFixEditDetails.spUserid;
        obj1.label = newProps.breakFixEditDetails.spUsername;
        SpIndSelectedList.push(obj1);
      }
      if (
        newProps.breakFixEditDetails.spUsername == "0" ||
        newProps.breakFixEditDetails.spUsername == "" ||
        newProps.breakFixEditDetails.spUsername == null
      ) {
        SpIndSelectedList = [];
        GLOBAL.supportPIGroupNameEdit = "";
        this.props.dispatch(
          change("xsmBreakFixEditFormTab", "supportPIndividualName", "")
        );
      }
      if (
        newProps.breakFixEditDetails.spGroupid != undefined &&
        newProps.breakFixEditDetails.spGroupname != undefined
      ) {
        if (
          newProps.breakFixEditDetails.spGroupid != "0" &&
          newProps.breakFixEditDetails.spGroupname != ""
        ) {
          obj3.id = newProps.breakFixEditDetails.spGroupid;
          obj3.label = newProps.breakFixEditDetails.spGroupname;
          groupdata.push(obj3);
          this.props.setAssignmentGrpUsr(groupdata, "group");
        }
      }
      console.log("SpIndSelectedList", groupdata, SpIndSelectedList);
      if (
        typeof newProps.breakFixEditDetails.impactedUserid !== "undefined" &&
        newProps.breakFixEditDetails.impactedUserid !== ""
      ) {
        str = {};
        str.user_id = newProps.breakFixEditDetails.impactedUserid;
        str = JSON.stringify(str);
        axios
          .get(GLOBAL.userSpecificDetailsURL, { headers: { query: str } })
          .then((response) => {
            let vipRequestor = (response.data.vip === "Yes" || response.data.vip === "yes") ? "yes" : "no";
            this.setState({
              isVIPuser: vipRequestor,
              showVIPIcon: vipRequestor,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }

      // console.log('1111111 *****', {props: this.props});
      this.props.setUnchangedData(newProps.breakFixEditDetails);

      if (
        newProps &&
        newProps.breakFixEditDetails &&
        newProps.breakFixEditDetails.impactedUserid
      ) {
        this.setState({
          consumerValue: newProps.breakFixEditDetails.impactedUserid,
          showConsumerInfo: true,
        });
      } else {
        this.setState({ consumerValue: "", showConsumerInfo: false });
      }
      if (
        newProps &&
        newProps.breakFixEditDetails &&
        newProps.breakFixEditDetails.serviceId
      ) {
        let serviceId =
          newProps.breakFixEditDetails.serviceId !== "" &&
          newProps.breakFixEditDetails.serviceId !== "0" &&
          newProps.breakFixEditDetails.serviceId !== 0
            ? newProps.breakFixEditDetails.serviceId
            : "";
        this.setState({
          serviceIDSelected: serviceId,
          isServiceInfoIcn: serviceId !== "" ? true : false,
        });
      } else {
        this.setState({ serviceIDSelected: "", isServiceInfoIcn: false });
      }
      if (
        newProps &&
        newProps.breakFixEditDetails &&
        newProps.breakFixEditDetails.ciId
      ) {
        let ciId =
          newProps.breakFixEditDetails.ciId !== "" &&
          newProps.breakFixEditDetails.ciId !== "0" &&
          newProps.breakFixEditDetails.ciId !== 0
            ? newProps.breakFixEditDetails.ciId
            : "";
        this.setState({
          impactedIDSelected: ciId,
          isCiNameInfoIcn: ciId !== "" ? true : false,
        });
      } else {
        this.setState({ impactedIDSelected: "", isCiNameInfoIcn: false });
      }
      if (
        newProps &&
        newProps.breakFixEditDetails &&
        newProps.breakFixEditDetails.spUserid
      ) {
        this.setState({
          supportIndividualValue: newProps.breakFixEditDetails.spUserid,
          showIndividualInfo: true,
        });
      } else {
        this.setState({
          supportIndividualValue: "",
          showIndividualInfo: false,
        });
      }

      if (
        !(
          this.props.roleIdentification("14") ||
          this.props.roleIdentification("48")
        )
      ) {
        if (
          newProps &&
          newProps.breakFixEditDetails &&
          !this.props.groupIdentification(
            newProps.breakFixEditDetails.spGroupid
          )
        ) {
          this.setState({ disableTypeAhead: true });
        } else {
          this.setState({ disableTypeAhead: false });
        }
      }

      this.props.setFormAPITriggered(true);
    }
  }
  setGroupReason(evt) {
    GLOBAL.incidentgroupReason = evt.target.value;
  }
  validateField(fieldType, event) {
    console.log(fieldType, event.target);
    switch (fieldType) {
      case "supportPIndividual":
        if (event.target.defaultValue == "0" || event.target.value == "0") {
          this.setState({ supportPIndividualErrorColor: "error" });
          GLOBAL.sgIErrorColor = "";
        } else {
          this.setState({ supportPIndividualErrorColor: "" });
          GLOBAL.sgIErrorColor = "";
        }
        break;
      case "supportPGroup":
        if (event.target.defaultValue == "0" || event.target.value == "0") {
          this.setState({ supportPGroupErrorColor: "error" });
          GLOBAL.supportPGroupErrorColor = "";
        } else {
          this.setState({ supportPGroupErrorColor: "" });
          GLOBAL.supportPGroupErrorColor = "";
        }

        GLOBAL.GroupReassignReasonErrorColor = "";
        break;
      case "issueDescription":
        if (
          event.currentTarget.defaultValue == "" ||
          event.currentTarget.defaultValue.trim() == ""
        ) {
          this.setState({ issueDescription: "error" });
          GLOBAL.issueErrorColor = "";
        } else {
          this.setState({ issueDescription: "" });
          GLOBAL.issueErrorColor = "";
        }

        break;
      case "groupReassignReason":
        GLOBAL.GroupReassignReasonErrorColor = "";
        if (this.state.showGrpReason && event.target.value.trim() == "") {
          this.props.setGroupReasonErrorColor("error");
        } else {
          this.props.setGroupReasonErrorColor("");
        }
        break;
    }
  }

  componentDidMount() {
    // console.log('this.props - ', this.props);

    // let breakFixActualItemId = this.props.breakFixItemId || "";
    const categorizationObj = {};
    for (const [key, value] of Object.entries(this.props.breakFixEditDetails).filter(([k]) => k.startsWith("opsCategoryId") || k.startsWith("opsCategory"))) {
          categorizationObj[key] = this.props.breakFixEditDetails[key];
    }

    GLOBAL.categorizationObj = Object.keys(categorizationObj).length > 0 ? categorizationObj : {};

    groupdata = [];
    SpIndSelectedList = [];

    let obj1 = {};
    let obj3 = {};
    if (
      this.props.breakFixEditDetails.spUserid != undefined &&
      this.props.breakFixEditDetails.spUsername != undefined &&
      this.props.breakFixEditDetails.spUsername != "0"
    ) {
      obj1.id = this.props.breakFixEditDetails.spUserid;
      obj1.label = this.props.breakFixEditDetails.spUsername;
      SpIndSelectedList.push(obj1);
    }
    if (
      this.props.breakFixEditDetails.spUsername == "0" ||
      this.props.breakFixEditDetails.spUsername == "" ||
      this.props.breakFixEditDetails.spUsername == null
    ) {
      SpIndSelectedList = [];
      GLOBAL.supportPIGroupNameEdit = "";
      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportPIndividualName", "")
      );
    }
    if (
      this.props.breakFixEditDetails.spGroupid != undefined &&
      this.props.breakFixEditDetails.spGroupname != undefined
    ) {
      if (
        this.props.breakFixEditDetails.spGroupid != "0" &&
        this.props.breakFixEditDetails.spGroupname != ""
      ) {
        obj3.id = this.props.breakFixEditDetails.spGroupid;
        obj3.label = this.props.breakFixEditDetails.spGroupname;
        groupdata.push(obj3);
        this.props.setAssignmentGrpUsr(groupdata, "group");
      }
    }

    // if(this.props.isFormAPIsTriggered == true){
    // 	return;
    // }

    // console.log('this.props - ', this.props);

    if (this.props.breakFixEditDetails) {
      if (this.props.breakFixEditDetails.serviceCriticality) {
        this.setState({
          criticalityDropdownValue:
            this.props.breakFixEditDetails.serviceCriticality,
        });
      }
      if (this.props.breakFixEditDetails.urgency) {
        this.setState({
          urgencyDropdownValue: this.props.breakFixEditDetails.urgency,
        });
      }
      if (this.props.breakFixEditDetails.spGroupid) {
        this.setState({
          initialGroupId: this.props.breakFixEditDetails.spGroupid,
        });
      }
      if (
        typeof this.props.breakFixEditDetails.urgency !== "undefined" &&
        typeof this.props.breakFixEditDetails.serviceCriticality !== "undefined"
      ) {
        this.setState({
          priorityValue: this.props.breakFixEditDetails.priorityValue,
        });
        GLOBAL.priorityValueType = this.props.breakFixEditDetails.priorityValue;
        this.props.dispatch(
          change(
            "xsmBreakFixEditFormTab",
            "priorityActualVal",
            this.props.breakFixEditDetails.priorityValue === "P1"
              ? "45"
              : this.props.breakFixEditDetails.priorityValue === "P2"
              ? "50"
              : this.props.breakFixEditDetails.priorityValue === "P3"
              ? "55"
              : "60"
          )
        );
        this.props.dispatch(
          change(
            "xsmBreakFixEditFormTab",
            "priorityLabel",
            this.props.breakFixEditDetails.priorityValue
          )
        );
      }
    }

    if (this.props.breakFixEditDetails.spGroupid) {
      if (
        !(
          this.props.roleIdentification("14") ||
          this.props.roleIdentification("48")
        )
      ) {
        if (
          this.props &&
          this.props.breakFixEditDetails &&
          !this.props.groupIdentification(
            this.props.breakFixEditDetails.spGroupid
          )
        ) {
          this.setState({ disableTypeAhead: true });
        } else {
          this.setState({ disableTypeAhead: false });
        }
      }
    }
    if (typeof this.props.breakFixEditDetails.consumerCompany !== "undefined") {
      this.setState({
        selectedCompanyId: this.props.breakFixEditDetails.serviceBased
          ? this.props.breakFixEditDetails.serviceCompanyId
          : this.props.breakFixEditDetails.ciConsumerCompany,
      });
      this.setState({
        onCreateUsingSelect: this.props.breakFixEditDetails.serviceBased
          ? "service"
          : "impactedCI",
      });
      this.fetchCompanyMatrix(
        this.props.breakFixEditDetails.serviceBased
          ? this.props.breakFixEditDetails.serviceCompanyId
          : this.props.breakFixEditDetails.ciConsumerCompany,
        "Breakfix",
        "Urgency,Impact",
        languageSelected,
        "1"
      );
      axios
        .get(
          "/api/company_menulist?companyId=" +
            (this.props.breakFixEditDetails.serviceBased
              ? this.props.breakFixEditDetails.serviceCompanyId
              : this.props.breakFixEditDetails.ciConsumerCompany) +
            "&module=Breakfix&field=Impact,Urgency&language=" +
            languageSelected +
            "&status=1"
        )
        .then((responseDropdownValues) => {
          this.props.setUrgencyImpact(responseDropdownValues.data);
        });
    }

    if (
      this.props.breakFixEditDetails &&
      this.props.breakFixEditDetails.breakfixId
    ) {
      GLOBAL.ciId =
        GLOBAL.breakFixConsumerID =
        GLOBAL.servicelocationid =
        GLOBAL.ciLocationId =
        GLOBAL.consumerCompanyId =
        GLOBAL.consumerCompanyName =
        GLOBAL.businessCriticalityId =
        GLOBAL.businessCriticality =
          "";
      GLOBAL.ciIdEdit =
        GLOBAL.ciNameEdit =
        GLOBAL.ciNameCopy =
        GLOBAL.ciIdNumberEdit =
        GLOBAL.ciClass =
        GLOBAL.ciClassNameSelected =
        GLOBAL.categoryNameEdit =
        GLOBAL.environmentNameEdit =
        GLOBAL.ciConsumerCompanyEdit =
        GLOBAL.ciSupportCompanyEdit =
        GLOBAL.ciClassIdEdit =
        GLOBAL.ciLocationIdEdit =
        GLOBAL.ciLocationNameEdit =
        GLOBAL.ciClassId =
        GLOBAL.ciClassName =
          "";
      GLOBAL.breakFixServiceID =
        GLOBAL.breakFixServiceName =
        GLOBAL.breakFixCompanyName =
        GLOBAL.consumerCompanybreakfix =
        GLOBAL.servicecatid =
        GLOBAL.servicecatname =
        GLOBAL.consumerCompanyName =
        GLOBAL.changeValue =
          "";
      groupdata = [];
      SpIndSelectedList = [];
      GLOBAL.breakFixConsumerName = "";
      GLOBAL.isExternalIncidentEdit =
        this.props.breakFixEditDetails.isExternal == null ||
        this.props.breakFixEditDetails.isExternal == "" ||
        this.props.breakFixEditDetails.isExternal == undefined
          ? false
          : this.props.breakFixEditDetails.isExternal;
      GLOBAL.AMSEXIST = "No";
      let str = {},
        impactedServiceData = [];
      str.companyId = this.props.breakFixEditDetails.consumerCompany;
      str = JSON.stringify(str);
      api
        .get("/api/foundations/serviceListSelfServiceCompanyId/", {
          headers: { query: str },
        })
        .then((response) => {
          impactedServiceData = response.data;
          let dataArr = structuredClone(this.state.serviceListBasedOnCompany);
          impactedServiceData.map((subItem) => {
            dataArr.push({
              value: subItem.offeringId,
              label:
                subItem.offeringName +
                "|" +
                subItem.categoryName +
                "|" +
                subItem.subCategoryName +
                "|" +
                subItem.companyName,
              companyname: subItem.company, //name here
              categoryid: subItem.categoryId,
              categoryname: subItem.categoryName,
              offeringname: subItem.offeringName,
              subCategoryname: subItem.subCategoryName,
              consumercompanyname: subItem.companyName,
            });
          });
          this.setState({ serviceListBasedOnCompany: dataArr });
        });

      this.state.supportPIndividual = this.props.breakFixEditDetails.spGroupid;
      GLOBAL.changeValue = false;
      console.log("componentWillMOUNT CALLING");
      if (GLOBAL.reopen == true) {
        this.props.dispatch(
          change("xsmBreakFixEditFormTab", "supportPIndividual", "0")
        );

        this.props.dispatch(
          change("xsmBreakFixEditFormTab", "supportPIndividualName", "")
        );
      }
      // this.setState({
      // 	AllAMSGrps:false
      //   });
      this.setState({
        AllAMSGrps: false,
        selectedCIorServiceID: this.props.breakFixEditDetails.serviceBased
          ? this.props.breakFixEditDetails.serviceId
          : this.props.breakFixEditDetails.ciId,
        selectedCI: this.props.breakFixEditDetails.serviceBased
          ? 0
          : this.props.breakFixEditDetails.ciId,
        selectedService: this.props.breakFixEditDetails.serviceBased
          ? this.props.breakFixEditDetails.serviceId
          : 0,
      });
      this.props.loadBreakFixSourceName();
      if (this.props.initialValues.supportPIndividual == "0") {
        this.setState({ supportPIndividualErrorColor: "error" });
        GLOBAL.sgIErrorColor = "";
      }

      // breakFixActualItemId = this.props.breakFixItemId || "";

      str = {};
      str.userId = "";
      str.companyId = "";
      str.associatedCompanyId = "";
      str = JSON.stringify(str);
      let myObj0 = {};
      impactedServiceData = [];

      GLOBAL.priorityValueType = this.props.breakFixEditDetails.priorityValue;
      GLOBAL.supportPGroupNameEdit = this.props.breakFixEditDetails.spGroupname;
      GLOBAL.supportPIGroupNameEdit =
        this.props.breakFixEditDetails.spUsername == "0" ||
        this.props.breakFixEditDetails.spUserid == ""
          ? ""
          : this.props.breakFixEditDetails.spUsername;

      this.props.dispatch(
        change(
          "xsmBreakFixEditFormTab",
          "supportPGroupName",
          this.props.breakFixEditDetails.spGroupname
        )
      );
      this.props.dispatch(
        change(
          "xsmBreakFixEditFormTab",
          "supportPIndividualName",
          this.props.breakFixEditDetails.spUsername == "0" ||
            this.props.breakFixEditDetails.spUserid == ""
            ? ""
            : this.props.breakFixEditDetails.spUsername
        )
      );

      this.checkforAMSattributes("initial", -1);
      this.props.loadBreakFixSGI(this.props.breakFixEditDetails.spGroupid);

      let consumerCompanyID =
        this.props.breakFixEditDetails &&
        this.props.breakFixEditDetails.consumerCompany &&
        !isNaN(parseInt(this.props.breakFixEditDetails.consumerCompany, 10))
          ? parseInt(this.props.breakFixEditDetails.consumerCompany, 10)
          : "";

      this.setState({
        companyId: consumerCompanyID,
      });
      let obj1 = {};
      let obj3 = {};
      if (
        this.props.breakFixEditDetails.spUserid != undefined &&
        this.props.breakFixEditDetails.spUsername != undefined &&
        this.props.breakFixEditDetails.spUsername != "0"
      ) {
        obj1.id = this.props.breakFixEditDetails.spUserid;
        obj1.label = this.props.breakFixEditDetails.spUsername;
        SpIndSelectedList.push(obj1);
      }
      if (
        this.props.breakFixEditDetails.spUsername == "0" ||
        this.props.breakFixEditDetails.spUsername == "" ||
        this.props.breakFixEditDetails.spUsername == null
      ) {
        SpIndSelectedList = [];
        GLOBAL.supportPIGroupNameEdit = "";
        this.props.dispatch(
          change("xsmBreakFixEditFormTab", "supportPIndividualName", "")
        );
      }
      if (
        this.props.breakFixEditDetails.spGroupid != undefined &&
        this.props.breakFixEditDetails.spGroupname != undefined
      ) {
        if (
          this.props.breakFixEditDetails.spGroupid != "0" &&
          this.props.breakFixEditDetails.spGroupname != ""
        ) {
          obj3.id = this.props.breakFixEditDetails.spGroupid;
          obj3.label = this.props.breakFixEditDetails.spGroupname;
          groupdata.push(obj3);
          this.props.setAssignmentGrpUsr(groupdata, "group");
        }
      }
      console.log("SpIndSelectedList", groupdata, SpIndSelectedList);
      if (
        typeof this.props.breakFixEditDetails.impactedUserid !== "undefined" &&
        this.props.breakFixEditDetails.impactedUserid !== ""
      ) {
        str = {};
        str.user_id = this.props.breakFixEditDetails.impactedUserid;
        str = JSON.stringify(str);
        axios
          .get(GLOBAL.userSpecificDetailsURL, { headers: { query: str } })
          .then((response) => {
            let vipRequestor = (response.data.vip === "Yes" || response.data.vip === "yes") ? "yes" : "no";
            this.setState({
              isVIPuser: vipRequestor,
              showVIPIcon: vipRequestor,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }

      // console.log('1111111 *****', {props: this.props});
      this.props.setUnchangedData(this.props.breakFixEditDetails);

      if (
        this.props &&
        this.props.breakFixEditDetails &&
        this.props.breakFixEditDetails.impactedUserid
      ) {
        this.setState({
          consumerValue: this.props.breakFixEditDetails.impactedUserid,
          showConsumerInfo: true,
        });
      } else {
        this.setState({ consumerValue: "", showConsumerInfo: false });
      }
      if (
        this.props &&
        this.props.breakFixEditDetails &&
        this.props.breakFixEditDetails.serviceId
      ) {
        let serviceId =
          this.props.breakFixEditDetails.serviceId !== "" &&
          this.props.breakFixEditDetails.serviceId !== "0" &&
          this.props.breakFixEditDetails.serviceId !== 0
            ? this.props.breakFixEditDetails.serviceId
            : "";
        this.setState({
          serviceIDSelected: serviceId,
          isServiceInfoIcn: serviceId !== "" ? true : false,
        });
      } else {
        this.setState({ serviceIDSelected: "", isServiceInfoIcn: false });
      }
      if (
        this.props &&
        this.props.breakFixEditDetails &&
        this.props.breakFixEditDetails.ciId
      ) {
        let ciId =
          this.props.breakFixEditDetails.ciId !== "" &&
          this.props.breakFixEditDetails.ciId !== "0" &&
          this.props.breakFixEditDetails.ciId !== 0
            ? this.props.breakFixEditDetails.ciId
            : "";
        this.setState({
          impactedIDSelected: ciId,
          isCiNameInfoIcn: ciId !== "" ? true : false,
        });
      } else {
        this.setState({ impactedIDSelected: "", isCiNameInfoIcn: false });
      }
      if (
        this.props &&
        this.props.breakFixEditDetails &&
        this.props.breakFixEditDetails.spUserid
      ) {
        this.setState({
          supportIndividualValue: this.props.breakFixEditDetails.spUserid,
          showIndividualInfo: true,
        });
      } else {
        this.setState({
          supportIndividualValue: "",
          showIndividualInfo: false,
        });
      }

      if (
        !(
          this.props.roleIdentification("14") ||
          this.props.roleIdentification("48")
        )
      ) {
        if (
          this.props &&
          this.props.breakFixEditDetails &&
          !this.props.groupIdentification(
            this.props.breakFixEditDetails.spGroupid
          )
        ) {
          this.setState({ disableTypeAhead: true });
        } else {
          this.setState({ disableTypeAhead: false });
        }
      }
      this.props.getBreakfixQuestionsCategoryWise(
        this.props.breakFixEditDetails.breakfixId
      );
      this.props.setFormAPITriggered(true);
    }
    this.props.getAdminPreferences(this.props.breakFixEditDetails.consumerCompany, 'incident', '29', 'aiInsight')
    this.props.getAdminPreferences(this.props.breakFixEditDetails.consumerCompany, 'incident', '18', 'Group')
    this.props.getAdminPreferences(this.props.breakFixEditDetails.consumerCompany, 'incident', '19', 'Agent')
  }

  onIndivSelection(selectedGroup) {
    this.setState({ supportPIndividualErrorColor: "" });
    SpIndSelectedList = selectedGroup;

    if (selectedGroup.length > 0) {
      // this.setDropDownValue('supportPIndividual',)
      this.props.setIncidentDetails("individual", selectedGroup[0].id);
      // this.props.dispatch(change('xsmBreakFixEditFormTab','supportPIndividual',selectedGroup[0].id));
      // GLOBAL.supportPIGroupName=selectedGroup[0].label;
      // GLOBAL.individualBlank="";
      this.setState({ supportIndividualValue: "" }, () => {
        this.setState({
          supportIndividualValue: selectedGroup[0].id,
          showIndividualInfo: true,
        });
        this.props.dispatch(
          change(
            "xsmBreakFixEditFormTab",
            "supportPIndividual",
            selectedGroup[0].id
          )
        );
        this.props.supportPIndividualValueChange(selectedGroup[0].id);
        // this.props.dispatch(change('xsmBreakFixFormTab','supportPIndividual',''));
        GLOBAL.supportPIGroupName = selectedGroup[0].label;
        GLOBAL.individualBlank = "";

        this.props.dispatch(
          change(
            "xsmBreakFixEditFormTab",
            "supportPIndividualName",
            selectedGroup[0].label
          )
        );
      });
    } else {
      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportPIndividual", "")
      );
      this.setState({
        supportPIndividualErrorColor: "error",
        supportIndividualValue: "",
        showIndividualInfo: false,
      });
      this.props.setIncidentDetails("individual", "");
      GLOBAL.individualBlank = "blank";
      GLOBAL.supportPIGroupName = "";

      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportPIndividualName", "")
      );
    }
  }
  setSupIndErrorColor() {
    if (SpIndSelectedList.length == 0)
      this.setState({ supportPIndividualErrorColor: "error" });
    else this.setState({ supportPIndividualErrorColor: "" });

    GLOBAL.sgIErrorColor = "";
  }
  onSupGroupSelection(selectedGroup) {
    console.log(
      "MMM initialGroupId:",
      this.state.initialGroupId,
      " : selectedGroup[0].id: ",
      selectedGroup[0].id
    );

    this.setState({
      supportPGroupErrorColor: "",
      supportIndividualValue: "",
      showIndividualInfo: false,
    });
    this.props.setIncidentDetails("individual", "");
    groupdata = selectedGroup;
    SpIndSelectedList = [];

    GLOBAL.supportPIGroupNameEdit = "";
    if (selectedGroup.length > 0) {
      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportPGroup", selectedGroup[0].id)
      );
      if(!(this.state.showAismGroups && this.state.aiIndPredictionEnabled)) {
        this.props.loadBreakFixSGI(selectedGroup[0].id);
      }
      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportP1Group", "")
      );
      this.props.dispatch(
        change(
          "xsmBreakFixEditFormTab",
          "supportCompanyID",
          selectedGroup[0].supportCompanyID
        )
      );
      this.props.dispatch(
        change(
          "xsmBreakFixEditFormTab",
          "supportCompanyName",
          selectedGroup[0].supportCompanyName
        )
      );
      GLOBAL.supportPGroupName = selectedGroup[0].label;
      GLOBAL.supportPIGroupName = "";
      GLOBAL.isExternalIncidentEdit =
        selectedGroup[0].isExternal == "" ||
        selectedGroup[0].isExternal == null ||
        selectedGroup[0].isExternal == undefined
          ? false
          : selectedGroup[0].isExternal;
      this.showGroupReason(selectedGroup[0].id);
      this.props.setAssignmentGrpUsr(groupdata, "group");

      this.props.dispatch(
        change(
          "xsmBreakFixEditFormTab",
          "supportPGroupName",
          selectedGroup[0].label
        )
      );
      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportP1GroupName", "")
      );
      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportPIndividual", "0")
      );
      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportPIndividualName", "")
      );
    } else {
      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportPGroup", "")
      );
      this.setState({ supportPGroupErrorColor: "error" });
      GLOBAL.isExternalIncidentEdit = "";

      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportPGroupName", "")
      );
      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportP1Group", "")
      );
      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportP1GroupName", "")
      );
      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportPIndividual", "0")
      );
      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportPIndividualName", "")
      );
    }
  }
  handleSearch() {
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 2000);
  }
  setSupGroupErrorColor() {
    if (groupdata.length == 0)
      this.setState({ supportPGroupErrorColor: "error" });
    else this.setState({ supportPGroupErrorColor: "" });

    GLOBAL.sgErrorColor = "";
  }
  onAllSupGroupSelection(selectedGroup) {
    this.setState({
      supportPAllGroupErrorColor: "",
      supportIndividualValue: "",
      showIndividualInfo: false,
    });
    this.props.setIncidentDetails("individual", "");
    groupdata = selectedGroup;
    editGrpData = groupdata;
    SpIndSelectedList = [];
    if (selectedGroup.length > 0) {
      this.props.setAssignmentGrpUsr(groupdata, "group");
      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportP1Group", selectedGroup[0].id)
      );
      this.props.loadBreakFixSGI(selectedGroup[0].id);
      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportPGroup", "")
      );
      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportPIndividual", "0")
      );
      this.props.dispatch(
        change(
          "xsmBreakFixEditFormTab",
          "supportCompanyID",
          selectedGroup[0].supportCompanyID
        )
      );
      this.props.dispatch(
        change(
          "xsmBreakFixEditFormTab",
          "supportCompanyName",
          selectedGroup[0].supportCompanyName
        )
      );
      GLOBAL.supportPIGroupNameEdit = "";
      GLOBAL.supportPGroupName = selectedGroup[0].label;
      GLOBAL.supportPIGroupName = "";
      GLOBAL.isExternalIncidentEdit =
        selectedGroup[0].isExternal == "" ||
        selectedGroup[0].isExternal == null ||
        selectedGroup[0].isExternal == undefined
          ? false
          : selectedGroup[0].isExternal;
      this.showGroupReason(selectedGroup[0].id);

      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportPGroupName", "")
      );
      this.props.dispatch(
        change(
          "xsmBreakFixEditFormTab",
          "supportP1GroupName",
          selectedGroup[0].label
        )
      );
      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportPIndividualName", "")
      );
    } else {
      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportP1Group", "")
      );
      this.setState({ supportPAllGroupErrorColor: "error" });
      GLOBAL.isExternalIncidentEdit = "";

      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportPGroupName", "")
      );
      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportPGroup", "")
      );
      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportP1GroupName", "")
      );
      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportPIndividual", "0")
      );
      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportPIndividualName", "")
      );
    }
  }
  setAllSupGroupErrorColor() {
    if (groupdata.length == 0)
      this.setState({ supportPAllGroupErrorColor: "error" });
    else this.setState({ supportPAllGroupErrorColor: "" });

    GLOBAL.sgAllErrorColor = "";
  }
  renderSupportAMSGroup(breakFixData) {
    let grouplist = [];
    if (
      (!breakFixData || breakFixData.length == 0) &&
      this.props.breakFixEditDetails.spGroupid == 0
    ) {
      return null;
    }

    this.props.setBreakFixSGAEName(breakFixData);
    if (
      Array.isArray(breakFixData) ||
      breakFixData != "" ||
      breakFixData.length != 0
    )
      breakFixData.map((breakFixObj) => {
        grouplist.push(
          breakFixObj.assignmentGroupId + "~" + breakFixObj.assignmentGroupName
        );
      });

    grouplist = grouplist.filter(function (item, index, inputArray) {
      return inputArray.indexOf(item) == index;
    });

    return grouplist.map((breakFixObj) => {
      let breakfixobj1 = breakFixObj.split("~");
      return <option value={breakfixobj1[0]}>{breakfixobj1[1]}</option>;
    });
  }

  resetDetails() {
    this.props.dispatch(change("xsmBreakFixEditFormTab", "supportP1Group", ""));
    this.props.dispatch(change("xsmBreakFixEditFormTab", "supportPGroup", ""));
    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "supportPGroupName", "")
    );
    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "supportP1GroupName", "")
    );
    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "supportPIndividual", "")
    );
    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "supportPIndividualName", "")
    );
    this.showGroupReason("");
    // this.props.dispatch({type:"RESET_BREAKFIXSGI"})
    this.onCrossClickGroup();
  }

  AllGrps(e) {
    if (this.state.disableTypeAhead) {
      return;
    }
    e.preventDefault();
    this.props.getAllgroup(this.props.breakFixEditDetails.consumerCompany);
    // this.props.breakFixEditDetails.serviceBased == true ? this.props.getAllgroup(this.props.breakFixEditDetails.consumerCompany) : this.props.getAllgroup(this.props.breakFixEditDetails.consumerCompany);
    this.setState({
      AllAMSGrps: true,
      ShowAllAMSGRP: false,
      showAismGroups: false,
      supportIndividualValue: "",
      showIndividualInfo: false,
    });
    this.props.setIncidentDetails("individual", "");
    //   GLOBAL.AMSGRP="yes";
    GLOBAL.AMSEXIST = "yes";

    groupdata = [];
    editGrpData = [];
    this.resetDetails();
  }
  AllAMSGrps(e) {
    if (this.state.disableTypeAhead) {
      return;
    }

    e.preventDefault();

    this.checkforAMSattributes();
    this.setState({
      ShowAllAMSGRP: true,
      AllAMSGrps: false,
      showAismGroups: false,
      supportIndividualValue: "",
      showIndividualInfo: false,
    });
    this.props.setIncidentDetails("individual", "");
    GLOBAL.AMSEXIST = "No";
    editGrpData = [];
    groupdata = [];
    this.resetDetails();
  }
  showAismGroupsFn() {
    this.setState({
      showAismGroups: true,
      supportIndividualValue: "",
    });

    this.props.setIncidentDetails("individual", "");
    GLOBAL.AMSEXIST = "No";
    groupdata = [];
    editGrpData = [];

    this.resetDetails();
  }
  showGroupReason(currentVal) {
    if (this.state.initialGroupId == currentVal) {
      this.setState({ showGrpReason: false });
      GLOBAL.showGroupReassignReasonErrorColor = false;
    } else {
      this.setState({ showGrpReason: true });
      GLOBAL.showGroupReassignReasonErrorColor = true;
    }
    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "groupReassignReason", "")
    );
    GLOBAL.incidentgroupReason = "";
  }
  renderSourceName(breakFixData) {
    let optionsList = [];
    if (!breakFixData) {
      return <option />;
    }

    optionsList.push(<option value="">Select</option>);
    for (let i = 0; i < breakFixData.length; i++) {
      if (
        breakFixData[i].field1Value != "55" &&
        breakFixData[i].field1Value != "70"
      ) {
        optionsList.push(
          <option value={breakFixData[i].field1Value}>
            {breakFixData[i].field1Key}
          </option>
        );
      } else {
        if (
          breakFixData[i].field1Value ==
          this.props.breakFixEditDetails.reportedThrough
        ) {
          optionsList.push(
            <option value={breakFixData[i].field1Value}>
              {breakFixData[i].field1Key}
            </option>
          );
        }
      }
    }
    return optionsList;
  }
  setErrorColor(errorColor, fieldType) {
    switch (fieldType) {
      case "consumer":
        this.props.setConsumerColor(errorColor);
        break;
      default:
    }
  }
  onDescriptionChange(e) {
    this.setState({
      issueDescription: e.target.value,
      showDescriptionValidError: "",
      showDescriptionError: "",
    });
  }
  onChangeCriticality(e) {
    if (
      this.props.user_id + "" !==
      this.props.breakFixEditDetails.spUserid + ""
    ) {
      swal({
        text: this.props.translator[
          "Please assign the ticket to yourself before changing the Impact of the ticket"
        ],
        button: this.props.translator["OK"],
      });
      this.props.dispatch(
        change(
          "xsmBreakFixEditFormTab",
          "criticality",
          this.props.breakFixEditDetails.serviceCriticality
        )
      );
      return;
    }
    if (e.target.value === "") {
      this.props.setImpactError("error");
    }
    let eventTargetValue =
      e && e.target && e.target.value ? e.target.value : "";
    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "criticality", eventTargetValue)
    );
    let urgencyValue = this.state.urgencyDropdownValue;
    let priorityInputValue = "";
    if (urgencyValue !== "" && eventTargetValue !== "") {
      if (
        typeof this.state.priorityList[this.state.selectedCompanyId] ===
        "undefined"
      ) {
        let returnedCriticalityPromise = this.calculatePriority(
          this.state.selectedCompanyId,
          "Breakfix",
          urgencyValue,
          eventTargetValue
        );
        returnedCriticalityPromise.then((returnedCriticality) => {
          if (returnedCriticality.data !== "") {
            priorityInputValue = returnedCriticality.data[0].priorityValue;
            let calculatedPriority = this.state.priorityList;
            calculatedPriority[this.state.selectedCompanyId] = {};
            calculatedPriority[this.state.selectedCompanyId][
              urgencyValue + "," + eventTargetValue
            ] = priorityInputValue;
            this.setState({ priorityList: calculatedPriority });
            this.props.dispatch(
              change(
                "xsmBreakFixEditFormTab",
                "priorityActualVal",
                returnedCriticality.data[0].priority
              )
            );
            this.props.dispatch(
              change(
                "xsmBreakFixEditFormTab",
                "priorityLabel",
                returnedCriticality.data[0].priorityValue
              )
            );
            this.setCriticalityPriority(
              priorityInputValue,
              eventTargetValue,
              urgencyValue
            );
          } else {
            this.setState({ priorityValue: "" });
            this.props.dispatch(
              change("xsmBreakFixEditFormTab", "priorityActualVal", "")
            );
            this.props.dispatch(
              change("xsmBreakFixEditFormTab", "priorityLabel", "")
            );
            this.props.setImpactError("");
            GLOBAL.priorityValueType = "";
            this.resetGroupsandIndividual();
          }
        });
      } else if (
        typeof this.state.priorityList[this.state.selectedCompanyId][
          urgencyValue + "," + eventTargetValue
        ] === "undefined"
      ) {
        let returnedCriticalityPromise = this.calculatePriority(
          this.state.selectedCompanyId,
          "Breakfix",
          urgencyValue,
          eventTargetValue
        );
        returnedCriticalityPromise.then((returnedCriticality) => {
          if (returnedCriticality.data !== "") {
            priorityInputValue = returnedCriticality.data[0].priorityValue;
            let calculatedPriority = this.state.priorityList;
            calculatedPriority[this.state.selectedCompanyId][
              urgencyValue + "," + eventTargetValue
            ] = priorityInputValue;
            this.setState({ priorityList: calculatedPriority });
            this.props.dispatch(
              change(
                "xsmBreakFixEditFormTab",
                "priorityActualVal",
                returnedCriticality.data[0].priority
              )
            );
            this.props.dispatch(
              change(
                "xsmBreakFixEditFormTab",
                "priorityLabel",
                returnedCriticality.data[0].priorityValue
              )
            );
            this.setCriticalityPriority(
              priorityInputValue,
              eventTargetValue,
              urgencyValue
            );
          } else {
            this.setState({ priorityValue: "" });
            this.props.dispatch(
              change("xsmBreakFixEditFormTab", "priorityActualVal", "")
            );
            this.props.dispatch(
              change("xsmBreakFixEditFormTab", "priorityLabel", "")
            );
            this.props.setImpactError("");
            GLOBAL.priorityValueType = "";
            this.resetGroupsandIndividual();
          }
        });
      } else {
        priorityInputValue =
          this.state.priorityList[this.state.selectedCompanyId][
            urgencyValue + "," + eventTargetValue
          ];
        this.setCriticalityPriority(
          priorityInputValue,
          eventTargetValue,
          urgencyValue
        );
      }
    } else {
      this.props.setImpactError("");
      this.setState({ criticalityDropdownValue: eventTargetValue });
    }
  }

  onChangeUrgency(e) {
    if (
      this.props.user_id + "" !==
      this.props.breakFixEditDetails.spUserid + ""
    ) {
      swal({
        text: this.props.translator[
          "Please assign the ticket to yourself before changing the Urgency of the ticket"
        ],
        button: this.props.translator["OK"],
      });
      this.props.dispatch(
        change(
          "xsmBreakFixEditFormTab",
          "urgencyMode",
          this.props.breakFixEditDetails.urgency
        )
      );
      return;
    }
    if (e.target.value === "") {
      this.props.setUrgencyError("error");
    }
    let criticalityValue = this.state.criticalityDropdownValue;
    let urgencyValue = e.target.value;
    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "urgencyMode", urgencyValue)
    );
    let priorityInputValue = "";
    if (urgencyValue !== "" && criticalityValue !== "") {
      if (
        typeof this.state.priorityList[this.state.selectedCompanyId] ===
        "undefined"
      ) {
        let returnedUrgencyPromise = this.calculatePriority(
          this.state.selectedCompanyId,
          "Breakfix",
          urgencyValue,
          criticalityValue
        );
        returnedUrgencyPromise.then((returnedPriority) => {
          if (returnedPriority.data !== "") {
            priorityInputValue = returnedPriority.data[0].priorityValue;
            let calculatedPriority = this.state.priorityList;
            calculatedPriority[this.state.selectedCompanyId] = {};
            calculatedPriority[this.state.selectedCompanyId][
              urgencyValue + "," + criticalityValue
            ] = priorityInputValue;
            this.setState({ priorityList: calculatedPriority });
            this.props.dispatch(
              change(
                "xsmBreakFixEditFormTab",
                "priorityActualVal",
                returnedPriority.data[0].priority
              )
            );
            this.props.dispatch(
              change(
                "xsmBreakFixEditFormTab",
                "priorityLabel",
                returnedPriority.data[0].priorityValue
              )
            );
            this.setUrgencyPriority(
              priorityInputValue,
              urgencyValue,
              criticalityValue
            );
          } else {
            this.setState({ priorityValue: "" });
            this.props.dispatch(
              change("xsmBreakFixEditFormTab", "priorityActualVal", "")
            );
            this.props.dispatch(
              change("xsmBreakFixEditFormTab", "priorityLabel", "")
            );
            this.props.setUrgencyError("");
            GLOBAL.priorityValueType = "";
            this.resetGroupsandIndividual();
          }
        });
      } else if (
        typeof this.state.priorityList[this.state.selectedCompanyId][
          urgencyValue + "," + criticalityValue
        ] === "undefined"
      ) {
        let returnedUrgencyPromise = this.calculatePriority(
          this.state.selectedCompanyId,
          "Breakfix",
          urgencyValue,
          criticalityValue
        );
        returnedUrgencyPromise.then((returnedPriority) => {
          if (returnedPriority.data !== "") {
            priorityInputValue = returnedPriority.data[0].priorityValue;
            let calculatedPriority = this.state.priorityList;
            calculatedPriority[this.state.selectedCompanyId][
              urgencyValue + "," + criticalityValue
            ] = priorityInputValue;
            this.setState({ priorityList: calculatedPriority });
            this.props.dispatch(
              change(
                "xsmBreakFixEditFormTab",
                "priorityActualVal",
                returnedPriority.data[0].priority
              )
            );
            this.props.dispatch(
              change(
                "xsmBreakFixEditFormTab",
                "priorityLabel",
                returnedPriority.data[0].priorityValue
              )
            );
            this.setUrgencyPriority(priorityInputValue, urgencyValue);
          } else {
            this.setState({ priorityValue: "" });
            this.props.dispatch(
              change("xsmBreakFixEditFormTab", "priorityActualVal", "")
            );
            this.props.dispatch(
              change("xsmBreakFixEditFormTab", "priorityLabel", "")
            );
            this.props.setUrgencyError("");
            GLOBAL.priorityValueType = "";
            this.resetGroupsandIndividual();
          }
        });
      } else {
        priorityInputValue =
          this.state.priorityList[this.state.selectedCompanyId][
            urgencyValue + "," + criticalityValue
          ];
        this.setUrgencyPriority(priorityInputValue, urgencyValue);
      }
    } else {
      this.props.setUrgencyError("");
      this.setState({ urgencyDropdownValue: urgencyValue });
    }
  }

  renderCriticalityName(breakFixData) {
    if (!breakFixData[this.state.selectedCompanyId]) {
      return <option></option>;
    }
    return breakFixData[this.state.selectedCompanyId].map((breakFixObj) => {
      return (
        <option value={breakFixObj.field1Value}>{breakFixObj.field1Key}</option>
      );
    });
  }

  sorting(json_object, key_to_sort_by) {
    function sortByKey(a, b) {
      let x = a[key_to_sort_by];
      let y = b[key_to_sort_by];
      return x < y ? -1 : x > y ? 1 : 0;
    }

    json_object.sort(sortByKey);
  }

  renderUrgencyName(breakFixData) {
    if (!breakFixData[this.state.selectedCompanyId]) {
      return <option></option>;
    }
    return breakFixData[this.state.selectedCompanyId].map((breakFixObj) => {
      return (
        <option value={breakFixObj.field1Value}>{breakFixObj.field1Key}</option>
      );
    });
  }

  onGroupNameChange(e) {
    if (this.props.supportPGroup != "") {
      this.props.loadBreakFixSGI(e.target.value);
    } else {
      console.log("Error in loading loadBreakFixSGI");
    }
    // GLOBAL.supportPIGroupName = '';
    // GLOBAL.supportPIGroupNameEdit = '';
    GLOBAL.supportPIGroupName = "";
    GLOBAL.supportPIGroupNameEdit = "";
    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "supportPIndividual", "0")
    );
    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "supportPIndividualName", "")
    );
  }

  renderSupportPGroup(breakFixData) {
    if (this.props.breakFixEditDetails.length != 0) {
      this.props.loadBreakFixSG(this.props.breakFixEditDetails.serviceId);
    }
    let optionsList = [];
    if (!breakFixData || breakFixData.length == 0) {
      return <option />;
    }
    if (GLOBAL.breakFixServiceID != "" || breakFixData.length != 0) {
      this.props.loadBreakFixSGI(breakFixData[0].field1Value);
      for (let i = 0; i < breakFixData.length; i++) {
        if (
          breakFixData[i].field1Key == this.props.breakFixEditDetails.spGroupid
        ) {
          optionsList.push(
            <option value={breakFixData[i].field1Value}>
              {breakFixData[i].field1Key}
            </option>
          );
        }
      }
      for (let i = 0; i < breakFixData.length; i++) {
        if (
          breakFixData[i].field1Key != this.props.breakFixEditDetails.spGroupid
        ) {
          optionsList.push(
            <option value={breakFixData[i].field1Value}>
              {breakFixData[i].field1Key}
            </option>
          );
        }
      }
      return optionsList;
    } else {
      return optionsList;
      // <option>no data</option>;
    }
  }

  renderSupportPAEGroup(breakFixData) {
    let grouplist = [];
    if (
      (!breakFixData || breakFixData.length == 0) &&
      this.props.breakFixEditDetails.spGroupid == 0
    ) {
      return null;
    }

    this.props.setBreakFixSGAEName(breakFixData);
    if (
      Array.isArray(breakFixData) ||
      breakFixData != "" ||
      breakFixData.length != 0
    )
      breakFixData.map((breakFixObj) => {
        grouplist.push(
          breakFixObj.assignmentGroupId + "~" + breakFixObj.assignmentGroupName
        );
      });

    if (
      this.props.breakFixEditDetails.spGroupid != null &&
      this.props.breakFixEditDetails.spGroupname != null
    ) {
      grouplist.push(
        this.props.breakFixEditDetails.spGroupid +
          "~" +
          this.props.breakFixEditDetails.spGroupname
      );
    }
    grouplist = grouplist.filter(function (item, index, inputArray) {
      return inputArray.indexOf(item) == index;
    });

    return grouplist.map((breakFixObj) => {
      let breakfixobj1 = breakFixObj.split("~");
      return <option value={breakfixobj1[0]}>{breakfixobj1[1]}</option>;
    });
  }

  renderSupportPIGroup(breakFixData) {
    let optionsList = [],
      presentFlag = false;
    if (!breakFixData || breakFixData.length == 0) {
      return <option />;
    }
    if (this.props.supportPGroup != "") {
      for (let i = 0; i < breakFixData.length; i++) {
        if (
          breakFixData[i].field1Key == this.props.breakFixEditDetails.spGroupid
        ) {
          optionsList.push(
            <option value={breakFixData[i].field1Value}>
              {breakFixData[i].field1Key}
            </option>
          );
        }
        if (
          breakFixData[i].field1Key === this.props.breakFixEditDetails.spUserid
        )
          presentFlag = true;
      }
      if (
        presentFlag === false &&
        this.state.supportPIndividual ===
          this.props.breakFixEditDetails.spGroupid
      )
        optionsList.push(
          <option value={this.props.breakFixEditDetails.spUserid} hidden>
            {this.props.breakFixEditDetails.spUsername}
          </option>
        );
      for (let i = 0; i < breakFixData.length; i++) {
        if (
          breakFixData[i].field1Key != this.props.breakFixEditDetails.spGroupid
        ) {
          optionsList.push(
            <option value={breakFixData[i].field1Value}>
              {breakFixData[i].field1Key}
            </option>
          );
        }
      }
      return optionsList;
    } else {
      return optionsList;
      // <option>no data</option>;
    }
  }

  renderSupportPIAEGroup(breakFixData) {
    if (GLOBAL.breakfixongrpchangeid != "")
      GLOBAL.breakfixgroupid = GLOBAL.breakfixongrpchangeid;
    else GLOBAL.breakfixgroupid = this.props.breakFixEditDetails.spGroupid;

    if (!breakFixData || breakFixData.length == 0) {
      return null;
    }
    return breakFixData.map((breakFixObj) => {
      if (
        breakFixObj.assignmentGroupId == GLOBAL.breakfixgroupid &&
        breakFixObj.assignmentMemberId != 0 &&
        breakFixObj.assignmentMemberId != null
      )
        return (
          <option value={breakFixObj.assignmentMemberId}>
            {breakFixObj.assignmentMemberName}
          </option>
        );
    });
  }
  ShowAMSGroup(breakFixData) {
    let grouplist = [];
    let type = typeof breakFixData;

    if (breakFixData == "" || type == "string") {
      return null;
    } else {
      breakFixData.map((breakFixObj) => {
        grouplist.push(breakFixObj.groupName + "~" + breakFixObj.groupId);
      });

      return grouplist.map((breakFixObj) => {
        let breakfixobj1 = breakFixObj.split("~");
        return <option value={breakfixobj1[1]}>{breakfixobj1[0]}</option>;
      });
    }
  }
  setSupportIndividual(e) {
    GLOBAL.breakfixgroupid = e.target.value;
    GLOBAL.supportPGroupName = e.target[e.target.selectedIndex].text;
    GLOBAL.breakfixongrpchangeid = e.target.value;
  }
  onImpactedSelection(impactedID, companyNameVal) {
    if (
      this.state.onCreateUsingSelect === "impactedCI" &&
      this.state.selectedCompanyId !== GLOBAL.ciConsumerCompanyEdit
    ) {
      if (
        this.props.user_id + "" !==
        this.props.breakFixEditDetails.spUserid + ""
      ) {
        swal({
          text: this.props.translator[
            "Please assign the ticket to yourself before changing the Impact CI of the ticket"
          ],
          button: this.props.translator["OK"],
        });
        this.resetImpactCI();
        return;
      } else {
        this.fetchCompanyMatrix(
          GLOBAL.ciConsumerCompanyEdit,
          "Breakfix",
          "Urgency,Impact",
          languageSelected,
          "1"
        );
        this.setState({ selectedCompanyId: GLOBAL.ciConsumerCompanyEdit });
        this.resetUrgencyandPriority();
      }
    } else {
      this.checkforAMSattributes();
    }
    let impactedCIValue =
      impactedID !== "" && impactedID !== "0" && impactedID !== 0
        ? impactedID
        : this.props.breakFixEditDetails.ciId !== "" &&
          this.props.breakFixEditDetails.ciId !== "0" &&
          this.props.breakFixEditDetails.ciId !== 0
        ? this.props.breakFixEditDetails.ciId
        : "";
    this.setState({
      impactedIDSelected: impactedCIValue,
      isCiNameInfoIcn: impactedCIValue !== "" ? true : false,
    });
    this.props.setErrorImpactedColor("");
    if (this.props.breakFixEditDetails.serviceBased == false) {
      //GLOBAL.AMSEXIST = ""
      this.setState({
        AllAMSGrps: false,
      });

      if (impactedID != "") {
        this.props.dispatch(
          change("xsmBreakFixEditFormTab", "supportPIndividual", "")
        );
        this.props.dispatch(
          change("xsmBreakFixEditFormTab", "supportPGroup", "")
        );

        this.props.dispatch(
          change("xsmBreakFixEditFormTab", "supportPGroupName", "")
        );
        this.props.dispatch(
          change("xsmBreakFixEditFormTab", "supportP1Group", "")
        );
        this.props.dispatch(
          change("xsmBreakFixEditFormTab", "supportP1GroupName", "")
        );
        this.props.dispatch(
          change("xsmBreakFixEditFormTab", "supportPIndividualName", "")
        );
      }
      this.setState({
        companyId: GLOBAL.ciConsumerCompanyEdit,
        selectedCIorServiceID: GLOBAL.ciIdEdit,
        selectedCI: GLOBAL.ciIdEdit,
      });
    }
    this.setState({ CIselectedID: impactedID });
  }
  onServiceNameChange(serviceIdValue, companyNameVal, suggestion) {
    if (
      this.state.onCreateUsingSelect === "service" &&
      this.state.selectedCompanyId !== GLOBAL.consumerCompanyId
    ) {
      if (
        this.props.user_id + "" !==
        this.props.breakFixEditDetails.spUserid + ""
      ) {
        swal({
          text: this.props.translator[
            "Please assign the ticket to yourself before changing the Service of the ticket"
          ],
          button: this.props.translator["OK"],
        });
        this.resetService();
        return;
      } else {
        // this.props.dispatch(change('xsmBreakFixEditFormTab','impactedServiceName', suggestion?.offeringname));
        this.fetchCompanyMatrix(
          GLOBAL.consumerCompanyId,
          "Breakfix",
          "Urgency,Impact",
          languageSelected,
          "1"
        );
        this.setState({ selectedCompanyId: GLOBAL.consumerCompanyId });
        this.resetUrgencyandPriority();
      }
    }
    this.checkforAMSattributes();
    console.log(GLOBAL.changeValue);
    GLOBAL.changeValue = true;
    console.log("changeValue", GLOBAL.changeValue);
    this.props.setConsumerColor("");
    let serviceValue =
      GLOBAL.breakFixServiceID !== "" &&
      GLOBAL.breakFixServiceID !== "0" &&
      GLOBAL.breakFixServiceID !== 0
        ? GLOBAL.breakFixServiceID
        : this.props.breakFixEditDetails.serviceId !== "" &&
          this.props.breakFixEditDetails.serviceId !== "0" &&
          this.props.breakFixEditDetails.serviceId !== 0
        ? this.props.breakFixEditDetails.serviceId
        : "";
    this.setState({
      serviceIDSelected: serviceValue,
      isServiceInfoIcn: serviceValue !== "" ? true : false,
    });
    if (this.props.breakFixEditDetails.serviceBased == true) {
      let ciNameDataDetails = [];
      let ciNameData = [];
      let ciNameObject = {};
      let consumerData = [];
      let consumerDataDetails = [];
      let myObj = {};

      GLOBAL.breakFixConsumerID = "";
      GLOBAL.breakFixConsumerName = "";

      this.setState({
        AllAMSGrps: false,
        selectedCIorServiceID: GLOBAL.breakFixServiceID,
        selectedCI: GLOBAL.breakFixServiceID,
        selectedServiceID: GLOBAL.breakFixServiceID,
        selectedServiceName: GLOBAL.breakFixServiceName,
      });

      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportPIndividual", "")
      );
      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportPGroup", "")
      );

      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportPGroupName", "")
      );
      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportP1Group", "")
      );
      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportP1GroupName", "")
      );
      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportPIndividualName", "")
      );

      if (!isNaN(parseInt(companyNameVal, 10))) {
        console.log("companyNameVal", companyNameVal);
        let companyId = parseInt(companyNameVal, 10);

        this.setState({
          companyId: companyId,
        });
      } else {
        console.log("error in loading impacted ci as no company id found.");
      }
    }
  }
  setSupportIndividualName(e) {
    GLOBAL.supportPIGroupName = e.target[e.target.selectedIndex].text;
  }

  setDropDownValue(event, fieldType) {
    if (event == "supportPIndividual") {
      this.props.supportPIndividualValueChange(fieldType.target.value);
    }
    switch (fieldType) {
      case "supportPGroup":
        this.setState({ supportPGroup: event.target.value });
        break;
      case "supportPIndividual":
        this.setState({ supportPIndividual: event.target.value });

        break;
      case "criticality":
        this.setState({ criticality: event.target.value });
        break;
      default:
    }
  }

  onGroupInputChange(e) {
    this.setState({
      typedGroup: e,
      supportIndividualValue: "",
      showIndividualInfo: false,
    });
    this.props.setIncidentDetails("individual", "");
    this.props.dispatch(change("xsmBreakFixEditFormTab", "supportPGroup", ""));
    this.setState({ supportPGroupErrorColor: "error" });
    GLOBAL.supportPGroupName = "";
    GLOBAL.supportPIGroupName = "";
    groupdata = [];
    SpIndSelectedList = [];

    editGrpData = [];
    GLOBAL.supportPIGroupNameEdit = "";
    this.props.dispatch(change("xsmBreakFixEditFormTab", "supportP1Group", ""));
    this.setState({ supportPAllGroupErrorColor: "error" });

    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "supportPGroupName", "")
    );
    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "supportP1GroupName", "")
    );
    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "supportPIndividual", "0")
    );
    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "supportPIndividualName", "")
    );
  }
  onCrossClickGroup() {
    this.onGroupInputChange("");
    this.props.setAssignmentGrpUsr([], "user");
    this.setState({ typedIndividual: "" });
    // this.onSupGroupSelection([]);
    this.showGroupReason("");
    this.props.dispatch({ type: "RESET_BREAKFIXSGI" });
  }

  onIndividualInputChange(e) {
    this.setState({
      typedIndividual: e,
      supportIndividualValue: "",
      showIndividualInfo: false,
    });
    this.props.setIncidentDetails("individual", "");
    SpIndSelectedList = [];
    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "supportPIndividual", "")
    );

    this.setState({ supportPIndividualErrorColor: "error" });
    GLOBAL.individualBlank = "blank";
    GLOBAL.supportPIGroupName = "";

    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "supportPIndividualName", "")
    );
  }
  onCrossClickIndividual() {
    this.onIndividualInputChange("");
    this.props.setAssignmentGrpUsr([], "user");
    // this.onIndivSelection([]);
  }

  resettingConsumerInfoState(value = "") {
    value =
      value != "" && value != "0" && value != 0
        ? value
        : this.props.breakFixEditDetails.impactedUserid != "" &&
          this.props.breakFixEditDetails.impactedUserid != "0" &&
          this.props.breakFixEditDetails.impactedUserid != 0
        ? this.props.breakFixEditDetails.impactedUserid
        : "";
    this.setState({
      consumerValue: value,
      showConsumerInfo: value !== "" ? true : false,
    });
    this.props.setIncidentDetails("consumer", value);
  }
  checkforAMSattributes(fieldName, value) {
    if (fieldName !== "initial" && value !== -1) {
      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportPGroup", "")
      );
      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportPIndividual", "")
      );
      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportP1Group", "")
      );

      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportPGroupName", "")
      );
      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportP1GroupName", "")
      );
      this.props.dispatch(
        change("xsmBreakFixEditFormTab", "supportPIndividualName", "")
      );

      this.setState({ showIndividualInfo: false });
    }
    let amsAttribute = {
      Status: this.props.breakFixEditDetails.statusId,
      Priority:
        (fieldName === "urgency" || fieldName === "impact") && value !== ""
          ? value
          : this.state.priorityValue === ""
          ? this.props.breakFixEditDetails.priorityValue
          : this.state.priorityValue,
      Service:
        this.state.selectedServiceID === ""
          ? this.props.breakFixEditDetails.serviceId
          : this.state.selectedServiceID,
      "Impacted CI":
        GLOBAL.ciIdEdit === ""
          ? this.props.breakFixEditDetails.ciId
          : GLOBAL.ciIdEdit,
      Requestor:
        GLOBAL.breakFixConsumerID === ""
          ? this.props.breakFixEditDetails.impactedUserid
          : GLOBAL.breakFixConsumerID,
      Class:
        GLOBAL.ciClassIdEdit === ""
          ? this.props.breakFixEditDetails.ciClassId
          : GLOBAL.ciClassIdEdit,
      "User.Location":
        GLOBAL.servicelocationid == ""
          ? this.props.breakFixEditDetails.requesterLocationId
          : GLOBAL.servicelocationid,
      "CI.Location":
        GLOBAL.ciLocationIdEdit == ""
          ? this.props.breakFixEditDetails.ciLocationId
          : GLOBAL.ciLocationIdEdit,
    };
    console.log(GLOBAL.breakFixServiceName, "GLOBAL.breakFixServiceName");
    console.log(this.state.selectedServiceID, "GLOBAL.breakFixServiceName");
    console.log(
      this.state.selectedServiceID === ""
        ? this.props.breakFixEditDetails.serviceName
        : GLOBAL.breakFixServiceName,
      "GLOBAL.breakFixServiceName"
    );
    let rulesAttribute = {
      status: this.props.breakFixEditDetails.statusId,
      priorityId:
        (fieldName === "urgency" || fieldName === "impact") && value !== ""
          ? value
          : this.state.priorityValue === ""
          ? this.props.breakFixEditDetails.priorityId
          : this.state.priorityValue,
      serviceName: GLOBAL.breakFixServiceName !== ""
        ? GLOBAL.breakFixServiceName
        : this.props.breakFixEditDetails.serviceName,
      impactedCi:
        GLOBAL.ciIdEdit === ""
          ? this.props.breakFixEditDetails.ciName
          : GLOBAL.ciNameEdit,
      impactedUsername:
        GLOBAL.breakFixConsumerName === ""
          ? this.props.breakFixEditDetails.impactedUsername
          : GLOBAL.breakFixConsumerName,
      ciClassName:
        GLOBAL.ciClassIdEdit === ""
          ? this.props.breakFixEditDetails.ciClassName
          : GLOBAL.ciClassIdEdit,
      userLocation:
        GLOBAL.servicelocationname == ""
          ? this.props.breakFixEditDetails.requesterLocationName
          : GLOBAL.servicelocationname,
      ciLocation:
        GLOBAL.ciLocationIdEdit == ""
          ? this.props.breakFixEditDetails.ciLocationName
          : GLOBAL.ciLocationNameEdit,
      serviceId:
        GLOBAL.breakFixServiceID === ""
          ? this.props.breakFixEditDetails.serviceId
          : GLOBAL.breakFixServiceID,
      impactedCiId:
        GLOBAL.ciIdEdit === ""
          ? this.props.breakFixEditDetails.ciId
          : GLOBAL.ciIdEdit,
      requestorId:
        GLOBAL.breakFixConsumerID === ""
          ? this.props.breakFixEditDetails.impactedUserid
          : GLOBAL.breakFixConsumerID,
      classId:
        GLOBAL.ciClassIdEdit === ""
          ? this.props.breakFixEditDetails.ciClassId
          : GLOBAL.ciClassIdEdit,
      userLocationId:
        GLOBAL.servicelocationid == ""
          ? this.props.breakFixEditDetails.requesterLocationId
          : GLOBAL.servicelocationid,
      ciLocationId:
        GLOBAL.ciLocationIdEdit == ""
          ? this.props.breakFixEditDetails.ciLocationId
          : GLOBAL.ciLocationIdEdit,
    };

    var newAttribute =
      GLOBAL.consumerCompanyId == ""
        ? this.props.breakFixEditDetails.serviceCompanyId
        : GLOBAL.consumerCompanyId;
    if (this.props.breakFixEditDetails.serviceBased == true) {
      this.setState({ rulesAttribute: rulesAttribute });
      // this.props.loadNewBreakFixSGAEWithQueryParams(
      //   this.state.selectedCIorServiceID !== ""
      //     ? this.state.selectedCIorServiceID
      //     : this.props.breakFixEditDetails.serviceBased
      //     ? this.props.breakFixEditDetails.serviceId
      //     : this.props.breakFixEditDetails.ciId,
      //   this.props.breakFixEditDetails.consumerCompany,
      //   "Breakfix",
      //   "service",
      //   amsAttribute,
      //   newAttribute
      // );
    }
    if (this.props.breakFixEditDetails.serviceBased == false) {
      this.setState({ rulesAttribute: rulesAttribute });
      // this.props.loadNewBreakFixSGAEWithQueryParams(
      //   this.state.selectedCIorServiceID !== ""
      //     ? this.state.selectedCIorServiceID
      //     : this.props.breakFixEditDetails.serviceBased
      //     ? this.props.breakFixEditDetails.serviceId
      //     : this.props.breakFixEditDetails.ciId,
      //   this.props.breakFixEditDetails.consumerCompany,
      //   "Breakfix",
      //   "impactedCI",
      //   amsAttribute,
      //   newAttribute
      // );
    }
    groupdata = [];
    SpIndSelectedList = [];
    editGrpData = [];
    this.setState({
      typedIndividual: "",
      typedGroup: "",
      supportIndividualValue: "",
      showIndividualInfo: false,
      AllAMSGrps: false,
    });
    this.props.setIncidentDetails("individual", "");
  }

  fetchCompanyMatrix(
    companyId,
    moduleName,
    fieldName,
    languageSelected,
    status
  ) {
    if (typeof this.state.urgencyList[companyId] === "undefined") {
      axios
        .get(
          "/api/company_menulist?companyId=" +
            companyId +
            "&module=" +
            moduleName +
            "&field=" +
            fieldName +
            "&language=" +
            languageSelected +
            "&status=" +
            status
        )
        .then((responseDropdownValues) => {
          let oldUrgencyList = this.state.urgencyList;
          oldUrgencyList[companyId] = [];
          let oldImpactList = this.state.impactList;
          oldImpactList[companyId] = [];
          responseDropdownValues.data.map((item) => {
            if (item.field2Name === "Impact") {
              oldImpactList[companyId].push(item);
            } else if (item.field2Name === "Urgency") {
              oldUrgencyList[companyId].push(item);
            }
          });
          this.setState({
            urgencyList: oldUrgencyList,
            impactList: oldImpactList,
          });
        });
    }
  }
  resetUrgencyandPriority() {
    let resetValue = "";
    GLOBAL.priorityValueType = resetValue;
    this.setState({
      urgencyDropdownValue: resetValue,
      criticalityDropdownValue: resetValue,
      priorityValue: resetValue,
    });
    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "urgencyMode", resetValue)
    );
    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "criticality", resetValue)
    );
    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "priorityActualVal", resetValue)
    );
    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "priorityLabel", resetValue)
    );
  }
  resetGroupsandIndividual() {
    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "supportCompanyID", "")
    );
    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "supportCompanyName", "")
    );
    this.props.dispatch(change("xsmBreakFixEditFormTab", "supportP1Group", ""));
    this.props.dispatch(change("xsmBreakFixEditFormTab", "supportPGroup", ""));
    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "supportPIndividual", "")
    );

    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "supportPGroupName", "")
    );
    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "supportP1GroupName", "")
    );
    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "supportPIndividualName", "")
    );

    groupdata = [];
    SpIndSelectedList = [];
    editGrpData = [];
    this.props.setAssignmentGrpUsr(groupdata, "group");
    this.setState({
      typedIndividual: "",
      typedGroup: "",
      supportIndividualValue: "",
      AllAMSGrps: false,
      showIndividualInfo: false,
    });
    this.props.setIncidentDetails("individual", "");
    this.props.dispatch({
      type: "LOAD_BREAKFIXSGAE_SUCCESS",
      breakFixSGAEName: [],
    });
    this.props.dispatch({ type: "GROUP_LIST", groupList: [] });
    this.props.dispatch({
      type: "LOAD_BREAKFIXSGI_SUCCESS",
      breakFixSGIName: { data: [] },
    });
  }
  resetService() {
    GLOBAL.breakFixServiceID =
      GLOBAL.breakFixServiceName =
      GLOBAL.breakFixCompanyName =
      GLOBAL.consumerCompanybreakfix =
      GLOBAL.servicecatid =
      GLOBAL.servicecatname =
      GLOBAL.consumerCompanyName =
      GLOBAL.consumerCompanyId =
        "";
    GLOBAL.changeValue = "";
    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "supportCompanyID", "")
    );
    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "supportCompanyName", "")
    );
  }
  resetImpactCI() {
    GLOBAL.ciIdEdit =
      GLOBAL.ciNameEdit =
      GLOBAL.ciNameCopy =
      GLOBAL.ciIdNumberEdit =
      GLOBAL.ciClass =
      GLOBAL.ciClassNameSelected =
      GLOBAL.categoryNameEdit =
      GLOBAL.environmentNameEdit =
      GLOBAL.ciConsumerCompanyEdit =
      GLOBAL.ciSupportCompanyEdit =
      GLOBAL.ciClassIdEdit =
      GLOBAL.ciLocationIdEdit =
      GLOBAL.ciLocationNameEdit =
      GLOBAL.businessCriticalityId =
      GLOBAL.businessCriticality =
        "";
    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "supportCompanyID", "")
    );
    this.props.dispatch(
      change("xsmBreakFixEditFormTab", "supportCompanyName", "")
    );
  }

  userAutoAssignment() {
    if (this.props.selectedAssignmentGroup) {
      let postData = {
        companyId: this.props.breakFixEditDetails.consumerCompany,
        groupId: this.props.selectedAssignmentGroup[0].id,
        itemType: "Incident",
        itemId: this.props.breakFixEditDetails.breakfixId,
        itemNumber: this.props.breakFixEditDetails.breakfixNumber,
      };
      return axios
        .post(`/rest/aiosem/userAutoTicketAssignationAction`, postData)
        .then((res) => {
          let data = res?.data?.data;
          if (data && data.userId && data.userName) {
            let selectedUser = [{ id: data.userId, label: data.userName }];
            this.props.setAssignmentGrpUsr(selectedUser, "user");
          }
        })
        .catch((error) => {
          console.log("==erorr", error);
        });
    }
  }
  resetServiceValue() {
    let serviceValue =
      this.props.breakFixEditDetails.serviceId !== "" &&
      this.props.breakFixEditDetails.serviceId !== "0" &&
      this.props.breakFixEditDetails.serviceId !== 0
        ? this.props.breakFixEditDetails.serviceId
        : "";
    this.setState({
      serviceIDSelected: serviceValue,
      isServiceInfoIcn: serviceValue !== "" ? true : false,
    });
  }
  resetImpactedCIValue() {
    let impactedCIValue =
      this.props.breakFixEditDetails.ciId !== "" &&
      this.props.breakFixEditDetails.ciId !== "0" &&
      this.props.breakFixEditDetails.ciId !== 0
        ? this.props.breakFixEditDetails.ciId
        : "";
    this.setState({
      impactedIDSelected: impactedCIValue,
      isCiNameInfoIcn: impactedCIValue !== "" ? true : false,
    });
  }
  onConsumerType(value) {
    this.setState({ showConsumerInfo: value });
  }
  onServiceType(value) {
    this.setState({ isServiceInfoIcn: value });
  }
  onImpactCIType(value) {
    this.setState({ isCiNameInfoIcn: value });
  }
  render() {
    //const ItemList = formValues('withVat')(MyItemizedList)

    console.log("*******", this.props);

    let individualdata = [];
    let SpGroupSelectedList = [];
    let obj1 = {};
    let obj3 = {};
    let arrObjOne = [];

    let allGroupData = [];

    console.log("roleIdArr", this.props.breakFixSGAEEditName);
    console.log(
      "MMM render this.props.grpReasonErrorColor",
      this.props.grpReasonErrorColor,
      "Initial ",
      this.state.initialGroupId
    );

    if (this.props.breakFixSGIName && this.props.breakFixSGIName.length > 0) {
      this.props.breakFixSGIName.forEach((item, i) => {
        let obj = {};
        obj.id = item.field1Value;
        obj.label = item.field1Key;
        individualdata.push(obj);
      });
    }

    if (
      Array.isArray(this.props.breakFixSGAEName) &&
      this.props.breakFixSGAEName &&
      this.props.breakFixSGAEName.length > 0
    ) {
      this.props.breakFixSGAEName.forEach((item, i) => {
        let obj2 = {};
        obj2.id = item.assignmentGroupId;
        obj2.label = item.assignmentGroupName;
        obj2.isExternal = item.isExternal;
        obj2.supportCompanyID = item.assignmentCompanyId;
        obj2.supportCompanyName = item.assignmentCompanyName;
        SpGroupSelectedList.push(obj2);
      });
    }

    if (this.props.allgroupdata && this.props.allgroupdata.length > 0) {
      console.log(this.props.allgroupdata);
      this.props.allgroupdata.forEach((item, i) => {
        let obj4 = {};
        obj4.id = item.groupId;
        obj4.label = item.groupName;
        obj4.isExternal = item.isExternal;
        obj4.supportCompanyID = item.supportCompanyID;
        obj4.supportCompanyName = item.supportCompanyName;
        allGroupData.push(obj4);
      });
    }
    arrObjOne = [
      ...new Map(
        SpGroupSelectedList.map((item) => [JSON.stringify(item), item])
      ).values(),
    ];
    console.log("arrObjOne", arrObjOne);
    GLOBAL.serviceName = this.props.breakFixEditDetails.serviceName;

    let Disable1 = this.props.roleIdentification("14") == true;

    let Disable2 = this.props.roleIdentification("48") == true;
    if (Disable1 == true || Disable2 == true) {
      this.state.Disable = false;
    }

    if (this.props.isFetchingDetails.isFetching) {
      // return (
      // 	<ListLoader />
      // );
    } else {
      this.renderSupportPAEGroup(this.props.breakFixSGAEEditName);
      GLOBAL.ciId = this.props.breakFixEditDetails.ciId;
      GLOBAL.ciName = this.props.breakFixEditDetails.ciName;
    }
    let criticallValue = "";
    if (this.props.role_id.indexOf("13") >= 0) {
      criticallValue = (
        <Form.Group className="form-group">
          <Form.Label bsClass="">
            <span className="rStar" />
            {this.props.translator["Criticality"]}
          </Form.Label>

          <Field
            name="criticality"
            component="select"
            type="select"
            className="form-control"
            onChange={(e) => {
              this.onChangeCriticality(e);
            }}
          >
            {this.renderCriticalityName(this.state.impactList)}
          </Field>
        </Form.Group>
      );
    } else {
      criticallValue = (
        <Form.Group className="form-group">
          <Form.Label bsClass="">
            {this.props.translator["Criticality"]}
          </Form.Label>
          <Field
            name="criticality"
            component="input"
            type="text"
            className="form-control"
            readOnly
          />
        </Form.Group>
      );
    }

    let priorityField = (
      <Field
        name="priority"
        component="input"
        type="text"
        className="form-control"
        disabled="true"
        placeholder={GLOBAL.priorityValueType}
      />
    );
    let {
      error,
      handleSubmit,
      pristine,
      reset,
      submitting,
      impactedServiceName,
      consumer,
      issueDescription,
      additionalInfo,
      reportedOn,
      reportedThrough,
      supportPGroup,
      supportPIndividual,
      criticality,
      urgencyMode,
    } = this.props;

    let statusBasedDisabled = "";
    if (
      this.props.breakFixEditDetails.status == "Fixed" ||
      this.props.breakFixEditDetails.status == "Closed" ||
      this.props.breakFixEditDetails.status == "Cancelled" ||
      this.props.breakFixEditDetails.reportedThrough == "50"
    ) {
      statusBasedDisabled = true;
    } else {
      statusBasedDisabled = false;
    }
    let errormsg = this.props.translator[error];
    // let itemIdTemp = (this.props.breakFixItemId) ? (this.props.breakFixItemId).split(":") : "";
    // let itemId = "";
    // if (itemIdTemp.length == 2) {
    // 	itemId = itemIdTemp[1];
    // } else {
    // 	itemId = itemIdTemp[0];
    // }
    let itemId = this.props?.breakFixItemId || "";

    console.log("****************", { state: this.state, props: this.props });
    let showFcr = false;
    if (
      this.props.breakFixEditDetails.status &&
      this.props.breakFixEditDetails.status === "Closed" &&
      this.props.breakFixEditDetails.firstCallResolution !== null
    ) {
      showFcr = true;
    }

    // return <h1>Hello World</h1>;

    return (
      <div>
        <Modal
          show={this.state.modalShow}
          onHide={() => this.showModal(false)}
          dialogClassName="modal-90w translatePopdv" size='lg'
          aria-labelledby="example-custom-modal-styling-title"
        >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Translate
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SmartCat description={this.props.breakFixEditDetails.description}/>
        </Modal.Body>
      </Modal>

        {this.props && this.props.showTimelineFlag ? (
          <GenericTimeline
            user_id={this.props.user_id}
            role_id={this.props.role_id}
            showCancelIssue={this.props.showCancelIssue}
            showOnHold={this.props.showOnHold}
            breakFixCurrentStatus={this.props.initialValues.status}
            showProvideFix={this.props.showProvideFix}
            provideFixStatus={this.props.provideFixStatus}
            changeProvideFixStatus={this.props.changeProvideFixStatus}
            showProcessHierarchy={this.props.showProcessHierarchy}
            translator={this.props.translator}
            itemId={itemId}
            module={"Breakfix"}
            showTimeline={this.props.showTimeline}
          />
        ) : null}
        {error == undefined || error == null ? null : (
          <Alert variant="danger" className="errorMessageSec">
            <p>{errormsg}</p>
          </Alert>
        )}

        <div className="borderForm bFormDiv">
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label bsClass="">
                  <span className="rStar" />
                  {this.props.translator["Company"]}
                </Form.Label>
                {
                  <Field
                    name="companyName"
                    component="input"
                    type="text"
                    className="form-control"
                    disabled="true"
                  />
                }
              </Form.Group>
            </Col>

            <Col md={6}>
              {this.props.fieldStatus.consumer == false ||
              this.props.disableFieldsForGuestRole ? (
                <Form.Group className="form-group">
                  <Form.Label bsClass="">
                    <span className="rStar" />
                    {this.props.translator["Consumer"]}
                    {this.state.showVIPIcon === "yes" ? (
                      <i
                        className="vipIcn fa fa-diamond"
                        aria-hidden="true"
                        title={this.props.translator["VIP User"]}
                      />
                    ) : (
                      ""
                    )}
                  </Form.Label>
                  <div className="infoBtnDiv typhdRig">
                    <Field
                      name="consumer"
                      type="text"
                      component={_inputField}
                      label=""
                      className="form-control"
                      readOnly
                      on
                    />
                    {this.state.showConsumerInfo ? (
                      <Button
                        title="Info"
                        bsPrefix=" "
                        className="infoicn"
                        onClick={() => {
                          this.props.showConsumerDetails(
                            this.state.consumerValue,
                            "Consumer Details"
                          );
                          this.props.rightEditPanel(true);
                        }}
                      >
                        <ImInfo />
                      </Button>
                    ) : null}
                  </div>
                </Form.Group>
              ) : (
                <Form.Group
                  className="form-group"
                  // validationState={this.props.consumerColor}
                >
                  <Form.Label bsClass="">
                    <span className="rStar" />
                    {this.props.translator["Consumer"]}
                    {this.state.showVIPIcon === "yes" ? (
                      <i
                        className="vipIcn fa fa-diamond"
                        aria-hidden="true"
                        title={this.props.translator["VIP User"]}
                      />
                    ) : (
                      ""
                    )}
                  </Form.Label>
                  <div className="infoBtnDiv typhdRig placeholderActive">
                    <Field
                      name="consumer"
                      options={this.state.consumerDataDetails}
                      companyId={this.state.companyId}
                      module="breakfix edit"
                      component={RFReactConsumer}
                      placeHolder={
                        this.props.breakFixEditDetails.impactedUsername
                      }
                      setErrorColor={this.setErrorColor}
                      afterConsumerSelected={this.afterConsumerSelected}
                      setshowVIPIcon={this.setshowVIPIcon}
                      resettingConsumerInfoState={
                        this.resettingConsumerInfoState
                      }
                      errorColor={this.props.consumerColor}
                      onType={this.onConsumerType}
                    />
                    {this.state.showConsumerInfo ? (
                      <Button
                        title="Info"
                        bsPrefix=" "
                        className="infoicn"
                        onClick={() => {
                          this.props.showConsumerDetails(
                            this.state.consumerValue,
                            "Consumer Details"
                          );
                          this.props.rightEditPanel(true);
                        }}
                      >
                        <ImInfo />
                      </Button>
                    ) : null}
                  </div>
                </Form.Group>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              {this.props.fieldStatus.serviceCI == false ||
              this.props.disableFieldsForGuestRole ? (
                <Form.Group className="form-group">
                  <Form.Label bsClass="">
                    {this.props.breakFixEditDetails.serviceBased == true ? (
                      <span className="rStar" />
                    ) : null}
                    {this.props.translator["Which Service is impacted?"]}
                  </Form.Label>
                  <div
                    className={
                      this.state.isServiceInfoIcn ? "infoBtnDiv" : null
                    }
                  >
                    <Field
                      name="impactedServiceName"
                      type="text"
                      component={_inputField}
                      label=""
                      className="form-control"
                      readOnly
                    />
                    {this.state.isServiceInfoIcn ? (
                      <Button
                        title="Info"
                        bsPrefix=" "
                        className="infoicn"
                        onClick={() => {
                          this.props.showServiceDetails(
                            this.props.breakFixEditDetails.serviceId
                          );
                          this.props.rightEditPanel(true);
                        }}
                      >
                        <ImInfo />
                      </Button>
                    ) : null}
                  </div>
                </Form.Group>
              ) : (
                <Form.Group
                  className="form-group"
                  // validationState={this.props.serviceErrorColor}
                >
                  <Form.Label bsClass="">
                    {this.props.breakFixEditDetails.serviceBased == true ? (
                      <span className="rStar" />
                    ) : null}
                    {this.props.translator["Which Service is impacted?"]}
                  </Form.Label>
                  <div
                    className={
                      (this.state.isServiceInfoIcn ? "infoBtnDiv" : null)+" placeholderActive"
                    }
                  >
                    <Field
                      name="impactedServiceName"
                      options={this.state.serviceListBasedOnCompany}
                      disabled={
                        this.props.fieldStatus.serviceCI == false ? true : false
                      }
                      // disabled={true}
                      setErrorServiceColor={this.props.setErrorServiceColor}
                      placeHolder={this.props.breakFixEditDetails.serviceName}
                      component={RFReactService}
                      showServiceInfoIcon={this.resetServiceValue}
                      onServiceNameChange={this.onServiceNameChange}
                      errorColor={this.props.serviceErrorColor}
                      onType={this.onServiceType}
                    />
                    {this.state.isServiceInfoIcn ? (
                      <Button
                        title="Info"
                        bsPrefix=" "
                        className="infoicn"
                        onClick={() => {
                          this.props.showServiceDetails(
                            this.state.serviceIDSelected
                          );
                          this.props.rightEditPanel(true);
                        }}
                      >
                        <ImInfo />
                      </Button>
                    ) : null}
                  </div>
                </Form.Group>
              )}
            </Col>

            <Col md={6}>
              {this.props.fieldStatus.serviceCI == false ||
              this.props.disableFieldsForGuestRole ? (
                <Form.Group className="form-group">
                  <Form.Label bsClass="">
                    {this.props.breakFixEditDetails.serviceBased == false ? (
                      <span className="rStar" />
                    ) : null}
                    {this.props.translator["Impacted CI"]}
                  </Form.Label>
                  <div
                    className={
                      this.state.isCiNameInfoIcn ? "infoBtnDiv typhdRig" : null
                    }
                  >
                    <Field
                      name="ciName"
                      type="text"
                      component={_inputField}
                      label=""
                      className="form-control"
                      readOnly
                    />
                    {this.state.isCiNameInfoIcn ? (
                      <Button
                        title="Info"
                        bsPrefix=" "
                        className="infoicn"
                        onClick={() => {
                          this.props.showCiDetails(
                            this.state.impactedIDSelected
                          );
                          this.props.rightEditPanel(true);
                        }}
                      >
                        <ImInfo />
                      </Button>
                    ) : null}
                  </div>
                </Form.Group>
              ) : (
                <Form.Group
                  className="form-group"
                  // validationState={this.props.ImpactedErrorColor}
                >
                  <Form.Label bsClass="">
                    {this.props.breakFixEditDetails.serviceBased == false ? (
                      <span className="rStar" />
                    ) : null}
                    {this.props.translator["Impacted CI"]}
                  </Form.Label>
                  <div className="infoBtnDiv typhdRig placeholderActive">
                    <Field
                      name="ciName"
                      options={this.state.ciNameDataDetails}
                      onImpactedSelection={this.onImpactedSelection}
                      showImpactedCIInfoIcon={this.resetImpactedCIValue}
                      module="breakfix edit"
                      companyId={this.state.companyId}
                      disabled={
                        this.props.fieldStatus.serviceCI == false ? true : false
                      }
                      setErrorImpactedColor={this.props.setErrorImpactedColor}
                      placeHolder={this.props.breakFixEditDetails.ciName}
                      component={CIDetailsService}
                      errorColor={this.props.ImpactedErrorColor}
                      onType={this.onImpactCIType}
                    />
                    {this.state.isCiNameInfoIcn ? (
                      <Button
                        title="Info"
                        bsPrefix=" "
                        className="infoicn"
                        onClick={() => {
                          this.props.showCiDetails(
                            this.state.impactedIDSelected
                          );
                          this.props.rightEditPanel(true);
                        }}
                      >
                        <ImInfo />
                      </Button>
                    ) : null}
                  </div>
                </Form.Group>
              )}
            </Col>
            {/* <Col md={6}>
							{this.props.fieldStatus.consumer==false?
							<Form.Group className="form-group">
							<Form.Label bsClass=""><span className="rStar" />{this.props.translator['Consumer']}{this.state.showVIPIcon==='yes'?<i className="vipIcn fa fa-diamond" aria-hidden="true" title={this.props.translator['VIP User']} />:''}</Form.Label>
							<div className="infoBtnDiv">
								<Field name="consumer" type="text" component={_inputField} label="" className="form-control" readOnly />
								{this.state.showConsumerInfo ? 
								<Button title="Info" bsPrefix=" " className="infoicn" onClick={() => { this.props.showConsumerDetails(this.state.consumerValue, 'Consumer Details'); }}><i className="fa fa-info-circle" aria-hidden="true" /></Button>
								: null }
								
							</div>
						</Form.Group>
							:
							<Form.Group className="form-group" 
							// validationState={this.props.consumerColor}
							>
								<Form.Label bsClass=""><span className="rStar" />{this.props.translator['Consumer']}{this.state.showVIPIcon==='yes'?<i className="vipIcn fa fa-diamond" aria-hidden="true" title={this.props.translator['VIP User']} />:''}</Form.Label>
								<div className="infoBtnDiv">
									<Field name="consumer"
										options={this.state.consumerDataDetails}
										companyId={this.state.companyId}
										module="breakfix edit"
										component={RFReactConsumer} 
										placeHolder={this.props.breakFixEditDetails.impactedUsername}
										setErrorColor={this.setErrorColor}
										afterConsumerSelected={this.afterConsumerSelected}
										setshowVIPIcon={this.setshowVIPIcon}
										updateConsumerInfoState={this.updateConsumerInfoState}
										errorColor={this.props.consumerColor}
										 />
									{this.state.showConsumerInfo ? 
									<Button title="Info" bsPrefix=" " className="infoicn" onClick={() => { this.props.showConsumerDetails(this.state.consumerValue, 'Consumer Details'); }}><i className="fa fa-info-circle" aria-hidden="true" /></Button>
									: null }
									
									</div>
							</Form.Group>
	                        }
						</Col> */}
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label bsClass="">
                  {this.props.translator["Hop Count"]}
                </Form.Label>
                <Form.Control
                  readOnly
                  type="text"
                  value={this.props.breakFixEditDetails.asgGroupHopCount}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label bsClass="">
                  {this.props.translator["Criticality"]}
                </Form.Label>
                <Form.Control
                  readOnly
                  type="text"
                  value={this.props.breakFixEditDetails.criticality}
                />
              </Form.Group>
            </Col>
            {this.props.breakFixEditDetails.statusId !== 20 ? (
              ""
            ) : (
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">
                    {this.props.translator["Hold Type"]}
                  </Form.Label>
                  <Form.Control
                    readOnly
                    type="text"
                    value={this.props.breakFixEditDetails.holdingReasonValue}
                  />
                </Form.Group>
              </Col>
            )}
          </Row>

          <Row>
            <Col md={12}>
              <Form.Group
                className="form-group"
                // validationState={this.state.issueDescription == '' ? GLOBAL.issueErrorColor : this.state.issueDescription}
              >
                <TranslateIconPopup popPosition="left" />
                <Form.Label bsClass="">
                  <span className="rStar" />
                  {this.props.translator["Issue Description"]}
                  {/* {showTranslator === 'true' && <Button title="Translate" bsPrefix=" " className='asignicn float-end asignicnTrans' onClick={() => { this.showModal(true); }} bsStyle="link"><MdGTranslate style={{fontSize:"20px",color:"#04838f"}} /></Button>} */}
                </Form.Label>
                <div className="position-re">
                  <Field
                    name="issueDescription"
                    component="textarea"
                    className={
                      "form-control " +
                      (this.state.issueDescription == ""
                        ? GLOBAL.issueErrorColor
                        : this.state.issueDescription)
                    }
                    maxLength="2000"
                    rows={2}
                    disabled={
                      this.props.fieldStatus.issueDescription == false ||
                      this.props.disableFieldsForGuestRole
                        ? true
                        : false
                    }
                    onBlur={(event) => {
                      this.validateField("issueDescription", event);
                    }}
                    onChange={(e) => {
                      this.onDescriptionChange(e);
                    }}
                  >
                    {this.props.breakFixEditDetails.description}
                  </Field>
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group className="form-group">
                <Form.Label bsClass="">
                  {this.props.translator["Additional Information, if any"]}
                </Form.Label>
                <Field
                  name="additionalInfo"
                  component="textarea"
                  className="form-control"
                  maxLength="3500"
                  rows={2}
                  disabled={
                    this.props.fieldStatus.addtnlInformation == false ||
                    this.props.disableFieldsForGuestRole
                      ? true
                      : false
                  }
                >
                  {this.props.breakFixEditDetails.additionalInfo}
                </Field>
              </Form.Group>
            </Col>
          </Row>
          {/* Categorization start here */}
          <CharacterizatinForm
              heading="Categorization"
              subHeading="Operational Category"
              opsLabelKey="opsCategory"
              opsValueKey="opsCategoryId"
              module="Incident"
              companyId={this.state.companyId}
              formName="xsmBreakFixEditFormTab"
              formType="edit"
              initialValues={
                Object.keys(this.props.breakFixEditDetails)
                .filter(key => key.startsWith("opsCategoryId") || key.startsWith("opsCategory"))
                .reduce((obj, key) => {
                    obj[key] = this.props.breakFixEditDetails[key];
                    return obj;
                }, {})}
            />
          {/* Categorization end here */}
          <div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
            <div className="bgGray padding-5">
              {this.props.translator["Prioritization"]}
            </div>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={6}>
                    <Form.Group className="form-group">
                      <Form.Label bsClass="">
                        <span className="rStar" />
                        {this.props.translator["Urgency"]}
                      </Form.Label>
                      {this.props.breakFixEditDetails.priorityValue !=
                      "Critical" ? (
                        <Form.Select
                          aria-label="select"
                          name="urgencyMode"
                          value={this.props.urgencyMode}
                          className={
                            this.props.breakfixUrgencyError !== "error"
                              ? "form-control"
                              : "form-control error"
                          }
                          onChange={(e) => {
                            this.onChangeUrgency(e);
                          }}
                          disabled={
                            this.props.fieldStatus.urgency == false ||
                            this.props.disableFieldsForGuestRole
                              ? true
                              : false
                          }
                        >
                          <option value="">Select</option>
                          {this.renderUrgencyName(this.state.urgencyList)}
                        </Form.Select>
                      ) : (
                        <Form.Select
                          aria-label="select"
                          name="urgencyMode"
                          value={this.props.urgencyMode}
                          className="form-control"
                          onChange={(e) => {
                            this.onChangeUrgency(e);
                          }}
                          disabled={
                            this.props.fieldStatus.urgency == false ||
                            this.props.disableFieldsForGuestRole
                              ? true
                              : false
                          }
                        >
                          {this.renderUrgencyName(this.state.urgencyList)}
                        </Form.Select>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-group">
                      <Form.Label bsClass="">
                        <span className="rStar" />
                        {this.props.translator["Impact"]}
                      </Form.Label>
                      <Form.Select
                        aria-label="select"
                        name="criticality"
                        value={this.props.criticality}
                        className={
                          this.props.breakfixImpactError !== "error"
                            ? "form-control"
                            : "form-control error"
                        }
                        onChange={(e) => {
                          this.onChangeCriticality(e);
                        }}
                        disabled={
                          this.props.fieldStatus.impact == false ||
                          this.props.disableFieldsForGuestRole
                            ? true
                            : false
                        }
                      >
                        <option value="">Select</option>
                        {this.renderCriticalityName(this.state.impactList)}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">
                    <span className="rStar" />
                    {this.props.translator["Priority"]}
                  </Form.Label>
                  <div className="placeholderActive">
                    {priorityField}
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </div>

          <Row>
            <Col md={12}>
              <div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
                <div className="bgGray padding-5">
                  <span className="rStar" />
                  {this.props.translator[" Reported"]}
                </div>
                <Row>
                  <Col md={6}>
                    <Form.Group className="form-group">
                      <Form.Label bsClass="">
                        {this.props.translator["On"]}
                      </Form.Label>
                      <Field
                        name="reportedOn"
                        component="input"
                        type="text"
                        className="form-control"
                        disabled={
                          this.props.fieldStatus.reportedOnThrough == false ||
                          this.props.disableFieldsForGuestRole
                            ? true
                            : false
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-group">
                      <Form.Label bsClass="">
                        {this.props.translator["Through"]}
                      </Form.Label>
                      {this.props.breakFixEditDetails &&
                      this.props.breakFixEditDetails.reportedThrough &&
                      this.props.breakFixEditDetails.reportedThrough == "30" &&
                      this.props.breakFixEditDetails?.senderMail ? (
                        <div className="infoBtnDiv">
                          <Field
                            name="reportedThrough"
                            component="select"
                            type="select"
                            className="form-control"
                            disabled={
                              this.props.fieldStatus.reportedOnThrough ==
                                false || this.props.disableFieldsForGuestRole
                                ? true
                                : false
                            }
                            style={{ "-webkit-appearance": "none" }}
                          >
                            {this.renderSourceName(
                              this.props.breakFixSourceName
                            )}
                          </Field>
                          <Button
                            title={
                              this.props.breakFixEditDetails?.senderMail || ""
                            }
                            bsPrefix=" "
                            className="infoicn"
                            onClick={() => {}}
                          >
                            <ImInfo />
                          </Button>
                        </div>
                      ) : (
                        <Field
                          name="reportedThrough"
                          component="select"
                          type="select"
                          className="form-control"
                          disabled={
                            this.props.fieldStatus.reportedOnThrough == false ||
                            this.props.disableFieldsForGuestRole
                              ? true
                              : false
                          }
                        >
                          {this.renderSourceName(this.props.breakFixSourceName)}
                        </Field>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
                <div className="bgGray padding-5 inciSupProviderDv">
                  <span>
                  <span className="rStar" />
                  {this.props.translator[" Support Provider"]}
                  </span>
                  {(this.props.roleIdentification("13") || this.props.roleIdentification("14") || this.props.roleIdentification("48")) ? 
                  <span className="showGrpDv">
                  {(this.props.roleIdentification("12") ||
                  this.props.roleIdentification("13") ||
                  this.props.roleIdentification("14") ||
                  this.props.roleIdentification("48")) && !(this.props.fieldStatus.supportGroupIndividual===false || this.state.disableTypeAhead || this.props.disableFieldsForGuestRole)? (
                    <span>
                      {" "}
                      {this.state.AllAMSGrps == false ? (
                        <a
                          className="showwAllGrps f-size-13"
                          href="javascript:void(0)"
                          onClick={this.AllGrps}
                          title='Show ALL Groups' 
                        ><GrGroup className='f-size-15' /> Show ALL Groups</a>
                      ) : (
                        <a
                          className="showwAllGrps f-size-13"
                          href="javascript:void(0)"
                          onClick={this.AllAMSGrps}
                          title='Show AMS Groups' 
                        ><GrGroup className='f-size-15' /> Show AMS Groups</a>
                      )}
                    </span>
                ) : null}{" "}
                {(this.props.roleIdentification("12") ||
                  this.props.roleIdentification("13") ||
                  this.props.roleIdentification("14") ||
                  this.props.roleIdentification("48")) && this.state.aiGrpPredictionEnabled && !(this.props.breakFixEditDetails.statusId == '30' || this.props.breakFixEditDetails.statusId == '35' || this.props.breakFixEditDetails.statusId == '25')  && <span onClick={() => this.showAismGroupsFn()}><a title='AI Recommended group' className="showwAllGrps showAlIcn f-size-13" href="javascript:void(0)"><span className="icn"><AiIcon iconSize={18} /></span>Show AISM Groups</a></span>}
                  </span> : ""}
                </div>

                <Row>
                  <Col md={6}>
                    {(this.state.AllAMSGrps == false || this.state.showAismGroups) ? (
                      <Form.Group className="form-group">
                        <Form.Label bsClass="">
                          <span className="rStar" />
                          {this.props.translator["Group"]}
                        </Form.Label>

                        <div className="dvTypehdropdown">
                          <RuleAmsDdropDown
                            name="supportPGroup"
                            component={TypeaheadExampleSingleSelect}
                            disabled={this.props.fieldStatus.supportGroupIndividual===false || this.state.disableTypeAhead || this.props.disableFieldsForGuestRole}
                            onSelection={this.onSupGroupSelection}
                            setErrorColor={this.setSupGroupErrorColor}
                            formData={this.props}
                            rulesAttribute={this.state.rulesAttribute}
                            serviceCompanyId={GLOBAL.consumerCompanyId == ""
                            ? this.props.breakFixEditDetails.serviceCompanyId
                            : GLOBAL.consumerCompanyId}
                            moduleName="incident"
                            ruleType="assignment"
                            selectedOptions={groupdata}
                            onInputChange={this.onGroupInputChange}
                            onCrossClick={this.onCrossClickGroup}
                            typedValue={this.state.typedGroup}
                            id="groupDropdown"
                            ruleFor="resolver"
                            errorClass={
                              this.state.supportPGroupErrorColor == ""
                                ? GLOBAL.sgErrorColor
                                : this.state.supportPGroupErrorColor
                            }
                            showAismGroups={this.state.showAismGroups}
                          />
                          {/* <Field 
					                  component={TypeaheadExampleSingleSelect}
									//   disabled={this.props.fieldStatus.supportGroupIndividual==false?true:false || this.state.disableTypeAhead}
									  disabled={ (this.props.fieldStatus.supportGroupIndividual==false || this.state.disableTypeAhead || this.props.disableFieldsForGuestRole) ?true:false}
                                      onSelection={this.onSupGroupSelection}
									  setErrorColor={this.setSupGroupErrorColor}
									  name="supportPGroup"
                                      options={arrObjOne}
                                      selectedOptions={groupdata}
									  onInputChange={this.onGroupInputChange}
                                      onCrossClick={this.onCrossClickGroup}
                                      typedValue={this.state.typedGroup}
                                      id="groupDropdown"
									  errorClass={this.state.supportPGroupErrorColor == '' ? GLOBAL.sgErrorColor : this.state.supportPGroupErrorColor}
                                     
                            /> */}
                        </div>
                      </Form.Group>
                    ) : (
                      <Form.Group
                        className="form-group"
                        //  validationState={this.state.supportPAllGroupErrorColor == '' ? GLOBAL.sgAllErrorColor : this.state.supportPAllGroupErrorColor}
                      >
                        <Form.Label bsClass="">
                          <span className="rStar" />
                          {this.props.translator["Group"]}
                        </Form.Label>

                        <div className="dvTypehdropdown">
                          <Field
                            component={TypeaheadExampleSingle}
                            onSelection={this.onAllSupGroupSelection}
                            setErrorColor={this.setAllSupGroupErrorColor}
                            //    disabled={this.props.fieldStatus.supportGroupIndividual==false?true:false || this.state.disableTypeAhead}
                            disabled={
                              this.props.fieldStatus.supportGroupIndividual ==
                                false ||
                              this.state.disableTypeAhead ||
                              this.props.disableFieldsForGuestRole
                                ? true
                                : false
                            }
                            name="supportP1Group"
                            options={allGroupData}
                            selectedOptions={editGrpData}
                            onInputChange={this.onGroupInputChange}
                            onCrossClick={this.onCrossClickGroup}
                            typedValue={this.state.typedGroup}
                            id="groupDropdown"
                            errorColor={
                              this.state.supportPAllGroupErrorColor == ""
                                ? GLOBAL.sgAllErrorColor
                                : this.state.supportPAllGroupErrorColor
                            }
                          />
                        </div>
                      </Form.Group>
                    )}
                  </Col>
                  <Col md={6}>
                    <Form.Group
                      className={
                        this.state.showIndividualInfo
                          ? "indiBtnDv infoBtnDiv typeHeadicn downinfobtn form-group"
                          : "prblmBtnDv infoBtnDiv typeHeadicn downinfobtn form-group"
                      }
                    >
                      <Form.Label bsClass="">
                        {this.props.translator["Individual"]}
                      </Form.Label>

                      <div className="dvTypehdropdown">
                        {this.state.showAismGroups && this.state.aiIndPredictionEnabled ? 
                        <AismIndividualTypeAhead
                          onSelection={this.onIndivSelection}
                          setErrorColor={this.setSupIndErrorColor}
                          className="form-control"
                          selectedOptions={SpIndSelectedList}
                          onInputChange={this.onIndividualInputChange}
                          onCrossClick={this.onCrossClickIndividual}
                          setIsLoading={this.setIsAismIndLoader}
                          isLoadingAismIndividual={this.state.isLoadingAismIndividual}
                          disabled={
                            this.props.fieldStatus.supportGroupIndividual ===
                              false ||
                            this.state.disableTypeAhead ||
                            this.props.disableFieldsForGuestRole
                          }
                          typedValue={this.state.typedIndividual}
                          formData={this.props}
                        /> 
                        :
                        <Field
                          component={TypeaheadExampleSingleSelect}
                          onSelection={this.onIndivSelection}
                          setErrorColor={this.setSupIndErrorColor}
                          name="supportPIndividual"
                          className="form-control"
                          options={individualdata}
                          selectedOptions={SpIndSelectedList}
                          onInputChange={this.onIndividualInputChange}
                          onCrossClick={this.onCrossClickIndividual}
                          disabled={
                            this.props.fieldStatus.supportGroupIndividual ===
                              false ||
                            this.state.disableTypeAhead ||
                            this.props.disableFieldsForGuestRole
                          }
                          typedValue={this.state.typedIndividual}
                          id="individualDropdown"
                        />}
                        {!this.state.isLoadingAismIndividual && <div
                          className={
                            this.state.showIndividualInfo ||
                            groupdata.length > 0
                              ? "indiIcnDv"
                              : ""
                          }
                        >
                          {groupdata.length > 0 ? (
                            <>
                              <Button
                                title={this.props.translator["Auto Assign"]}
                                disabled={
                                  this.props.fieldStatus
                                    .supportGroupIndividual === false ||
                                  this.state.disableTypeAhead ||
                                  this.props.disableFieldsForGuestRole
                                }
                                bsPrefix=" "
                                className="asignicn f-size-15"
                                onClick={this.userAutoAssignment}
                              >
                                <PiUserSwitch />
                              </Button>
                              <Button
                                disabled={
                                  this.props.fieldStatus
                                    .supportGroupIndividual === false ||
                                  this.state.disableTypeAhead ||
                                  this.props.disableFieldsForGuestRole
                                }
                                title={
                                  this.props.translator[
                                    "Show available individuals"
                                  ]
                                }
                                bsPrefix=" "
                                className="asignicn"
                                onClick={() => {
                                  if (!this.props.disableFieldsForGuestRole) {
                                    this.props.rightEditPanel(true);
                                    this.props.showAvailableIndividual();
                                  }
                                }}
                              >
                                <RiUserSearchLine />
                              </Button>
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.showIndividualInfo ? (
                            <Button
                              title="Info"
                              bsPrefix=" "
                              className="asignicn"
                              onClick={() => {
                                this.props.showConsumerDetails(
                                  this.state.supportIndividualValue,
                                  "Individual Details"
                                );
                                this.props.rightEditPanel(true);
                              }}
                            >
                              <ImInfo />
                            </Button>
                          ) : null}
                        </div>}
                        {/* {this.props.supportIndividualValue ? 
										<Button title="Info" bsPrefix=" " className="infoicn" onClick={() => { this.props.showConsumerDetails(this.props.supportIndividualValue, 'Individual Details'); }}><i className="fa fa-info-circle" aria-hidden="true" /></Button>
										: null } */}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                {this.state.showGrpReason && (
                  <Row>
                    <Col md={12}>
                      <Form.Group
                        className="form-group"
                        // validationState={this.props.grpReasonErrorColor}
                      >
                        <span className="rStar" />
                        <Form.Label bsClass="">
                          {this.props.translator["Group Reassignment Reason"]}
                        </Form.Label>
                        <Field
                          name="groupReassignReason"
                          component="textarea"
                          className={
                            "form-control " + this.props.grpReasonErrorColor
                          }
                          maxLength="3500"
                          rows={2}
                          onBlur={(event) => {
                            this.validateField("groupReassignReason", event);
                          }}
                          onChange={this.setGroupReason}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}
              </div>
            </Col>
          </Row>
          {showFcr && (
            <div className="padding-t-5 border">
              <Row>
                <Col md={6}>
                  <div className="padding-t-5">
                    <Form.Check
                      className="dCheckbox readOnlyChk"
                      checked={
                        this.props.breakFixEditDetails.firstCallResolution
                      }
                      readOnly
                      label="First Call Resolution"
                    ></Form.Check>
                  </div>
                </Col>
              </Row>
            </div>
          )}
          <Row>
            <Form.Group className="form-group">
              {this.props.myOrderQuestionDetails.length === 0 ? null : (
                <ListGroup.Item bsClass="">
                  <Accordion
                    id="rightSidePanel4"
                    accordion
                    className="brkFix brkFixOrder brkFullAccordion margin-b-20"
                    defaultActiveKey="1"
                  >
                    <Accordion.Item
                      eventKey="1"
                      className="collapsed rad-check-align clLeftAlign"
                    >
                      <Accordion.Header>
                        <h4 toggle>Information Provided</h4>
                      </Accordion.Header>
                      <Accordion.Body collapsible>
                        {this.renderInformationProvided(
                          this.props.myOrderQuestionDetails
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </ListGroup.Item>
              )}
            </Form.Group>
          </Row>
        </div>
      </div>
    );
  }
}

EditForm = reduxForm({
  form: "xsmBreakFixEditFormTab",
  fields: [
    ...Object.keys(GLOBAL.categorizationObj),
    "impactedServiceName",
    "consumer",
    "status",
    "issueDescription",
    "additionalInfo",
    "reportedOn",
    "reportedThrough",
    "supportPGroup",
    "supportP1Group",
    "supportPIndividual",
    "criticality",
    "urgencyMode",
    "priority, groupReassignReason",
  ],
  destroyOnUnmount: false,
  enableReinitialize: true,
})(EditForm);

let selector = formValueSelector("xsmBreakFixEditFormTab");
EditForm = connect((state) => {
  // can select values individually
  let impactedServiceName = selector(state, "impactedServiceName");

  //let issueDescription = selector(state, 'issueDescription');
  //let additionalInfo = selector(state,'additionalInfo');
  let reportedOn = selector(state, "reportedOn");
  let reportedThrough = selector(state, "reportedThrough");
  let supportPGroup = selector(state, "supportPGroup");
  let supportPIndividual = selector(state, "supportPIndividual");
  let criticality = selector(state, "criticality");
  let urgencyMode = selector(state, "urgencyMode");
  let priority = selector(state, "priorityLabel");
  let ItemList = getFormValues("xsmBreakFixEditFormTab")(state);
  return {
    impactedServiceName,
    //issueDescription,
    //additionalInfo,
    reportedOn,
    reportedThrough,
    supportPGroup,
    supportPIndividual,
    criticality,
    urgencyMode,
    ItemList,
    priority,
  };
})(EditForm);

export function mapStateToProps({
  breakFixSourceName,
  isFetchingDetails,
  breakFixSGName,
  breakFixSGIName,
  ItemList,
  breakFixEditDetails,
  breakFixStatusDetails,
  breakFixSGAEEditName,
  breakFixSGAEName,
  allgroupdata,
  fieldStatus,
  myOrderQuestionDetails,
  aismProperty
}) {
  if (breakFixEditDetails.reportedThrough == "50") {
    breakFixSourceName = breakFixSourceName.filter((itm) => {
      return itm.field1Key == "Web";
    });
  } else {
    breakFixSourceName = breakFixSourceName.filter((itm) => {
      return itm.field1Key != "Web";
    });
  }

  GLOBAL.breakFixId = breakFixEditDetails.breakfixId;

  return {
    //fixed defect #19
    initialValues: {
      ...GLOBAL.categorizationObj,
      impactedServiceName: breakFixEditDetails.serviceName,
      impactedServiceId: breakFixEditDetails.serviceId,
      consumer: breakFixEditDetails.impactedUsername,
      issueDescription: breakFixEditDetails.description,
      additionalInfo: breakFixEditDetails.additionalInfo,
      reportedOn: breakFixEditDetails.reportedOn,
      reportedThrough: breakFixEditDetails.reportedThrough,
      supportPGroup: breakFixEditDetails.spGroupid,
      // "supportP1Group": breakFixEditDetails.spGroupid,
      supportPIndividual: breakFixEditDetails.spUserid,
      criticality: breakFixEditDetails.serviceCriticality,
      urgencyMode: breakFixEditDetails.urgency,
      status: breakFixEditDetails.status,
      //"priority":this.props.breakFixEditDetails.priorityId
      ciName: breakFixEditDetails.ciName,
      ciId: breakFixEditDetails.ciId,
      company: breakFixEditDetails.consumerCompany,
      companyName: breakFixEditDetails.consumerCompanyName,
    },
    breakFixSourceName,
    breakFixSGName,
    breakFixSGIName,
    breakFixEditDetails,
    breakFixStatusDetails,
    breakFixSGAEEditName,
    breakFixSGAEName,
    isFetchingDetails,
    allgroupdata,
    fieldStatus,
    myOrderQuestionDetails,
    formValues: ItemList,
    aismProperty
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadBreakFixSourceName,
      loadBreakFixSG,
      loadBreakFixSGI,
      loadBreakFixEditDetails,
      loadBreakFixStatusDetails,
      // loadBreakFixSGAEEdit,
      // loadNewBreakFixSGAEWithQueryParams,
      getBreakfixGroupData,
      getAllgroup,
      getBreakfixQuestionsCategoryWise,
      getAdminPreferences
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditForm);
