
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Row, Col, Form, ButtonToolbar, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import CloseBtnRightPanel from "../../../../common/closeBtnRightPanel";

const SyncAll = (props) => {
    const tr = useSelector((state) => state.spcmReducer.tr);

    const [autoGenPwd, setAutoGenPwd] = useState(true);
    const [forceChngPwd, setForceChngPwd] = useState(true);
    const [password, setPassword] = useState('');
    const [passwordErr, setPasswordErr] = useState('');

    const dispatch = useDispatch();

    const validatePassword = (val) => {
        try{
          if(!(val && typeof  val == 'string' && val.trim().length > 0)){
            return false;
          }
          const oneCapitalLetter = /[A-Z]/;
          const oneNumber = /.*[0-9].*/;
          const specialChar = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
          const value = val.trim();
          if(value.length < 8){
            return false;
          }
          if( oneCapitalLetter.test(value) && oneNumber.test(value) && specialChar.test(value) ){
            return true;
          }
          return false;
        }catch(e){
          console.error(e);
          return false;
        }
      }

    const onSubmit = () => {
      props.setSyncAllDisable(true);
      if(passwordErr === "") {
          let data = {
            autoGeneratePassword: autoGenPwd.toString(),
            forceChangePassword: forceChngPwd.toString(),
            idenS: password
          };
          const { id } = props.companyValue;
          axios.post("/api/updateUserAppIdAll/" + id, data).then((resp) => {
            if(resp.data === "Success") {
             dispatch({ type: 'SYNC_ALL_NOT_CLICK', payload: `All the users are successfully synced with IAM`});
             props.setSyncAllDisable(false);
             props.setCurrentView('tabView');
           }
         }).catch((error) => { });
     
      }
    };

    const handleOnChange = (e) => {
        switch (e.target.name) {
            case "password":
                setPassword(e.target.value);
                setPasswordErr(validatePassword(e.target.value) ? '' : 'error');
                break;
            case "autoGenPwd":
                setPassword("");
                setAutoGenPwd(!autoGenPwd);
                break;
            case "forceChgPwd":
                setForceChngPwd(!forceChngPwd);
                break;
            default:
                break;
        }
    }
    return (
        <div className="hambTab userInfoPopupParent">
            <CloseBtnRightPanel showRightSide={props.showRightSideFun} />
            <div className="rPageHeading">
                <div className="offNam margin-t-5 margin-b-5">Sync All Users</div>
            </div>
            <div className="rBoxGap">
                <form>
                    <Row>
                        <Col xs={12}>
                            <Form.Group className="form-group">
                                <Form.Check name="autoGenPwd" type="checkbox" label="Auto-generate Password" checked={autoGenPwd} onChange={(e) => handleOnChange(e)} value={autoGenPwd} />
                            </Form.Group>
                            <Form.Group className="form-group">
                                <Form.Check name="forceChgPwd" type="checkbox" label="Force Password Change" checked={forceChngPwd} onChange={(e) => handleOnChange(e)} value={forceChngPwd} />
                            </Form.Group>
                            <Form.Group as={Row} className="form-group">
                                <Form.Label column sm={4}>Password</Form.Label>
                                <Col sm={6}><Form.Control className={` ${passwordErr}`} name="password" type="password" disabled={autoGenPwd} onChange={(e) => handleOnChange(e)} value={password} /></Col>
                                {passwordErr === "error" && <span style={{ color: "red", fontSize: "smaller" }}>Password must contain atleast 8 characters including 1 uppercase, 1 lowercase letter, 1 special character(!@#$%^&*) and a number.</span>}
                            </Form.Group>
                        </Col>
                        <Col xs={12}>
                            <ButtonToolbar className="margin-t-15">
                                <Button className='rgSidrkBtn smallBtn' title="Save" size="sm" disabled={props.syncAllDisabel} variant="primary" type="button" onClick={onSubmit} >{tr['Save']}</Button>
                                <Button onClick={() => { props.setCurrentView('tabView') }} className='rgSiLigBtn smallBtn' title="Cancel" size="sm" variant="primary" type="button">{tr['Cancel']}</Button>
                            </ButtonToolbar>
                        </Col>
                    </Row>
                </form>
            </div>
        </div>
    )
}

export default SyncAll;