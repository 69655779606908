
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import { GLOBAL }  from '../../components/Globals';

export const getFulfillmentTimeLineStatus = (itemId) => {
	return(dispatch) =>{
		if(itemId == undefined || itemId == ""){
      //console.log('no item');
		}else{
				axios.get(GLOBAL.fullFillmentStatusTimeLine+itemId)
					.then((statusTimeLine)=>{
						if(!statusTimeLine){
							throw Error(statusTimeLine.statusText);
						}
						return statusTimeLine;
					})
					.then((statusTimeLine) =>{
						dispatch(getFulfillmentTimeLineStatusSuccess(statusTimeLine));
					})
					.catch((err) => {
						console.log(err);
					});
		}
	};
};

export const getFulfillmentTimeLineStatusSuccess = (statusTimeLine) => {
	return {
		type: 'ORDER_FULFILLMENT_STATUS_TIMELINE_SUCCESS',
		statusTimeLine
	};
};

export const loadFulfillmentDetailsFailure = (errorResponse) =>{
	return {
		type: 'ORDER_FULFILLMENT_STATUS_TIMELINE_FAILURE',
		errorResponse
	};
};
