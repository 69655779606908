
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/

export const MultiSSOLogo = (state = "", action) => {
    if (action.type === 'MULTISSO_LOGO_FETCHED_SUCCESSFULLY') {
      return action.payload;
    } else {
      return state;
    }
};

export const updateMultiSSOLogo = (state = [], action) => {
    if (action.type === 'MULTISSO_LOGO_UPDATED_SUCCESSFULLY') {
      return action.payload;
    } else {
      return state;
    }
};