
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Row, Col, Form, Alert, ButtonToolbar, Button,Checkbox } from 'react-bootstrap';
import { _textArea, _dropDown, _inputField, field_file, _radio } from '../../common/formFields';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import axios from 'axios';
import { spcmLoader, setLeftEditId, getServiceDetailCounts, getServiceKnowledge, getConsumptionItems } from '../../../actions/spcmActions';
import {ImSpinner6} from "react-icons/im";


let format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;


const validate = values => {
	console.log("validateValue+++",values)
	let errors = {};
	if (!values.know_parent_type) {
		errors.know_parent_type = 'Select Associated To';
	}
	if (values.know_parent_id == "Component") {
		errors.know_parent_id = 'Select Component Name';
	}
	else if (!values.know_parent_id) {
		errors.know_parent_id = 'Select Component Name';
	}
	// if (!values.know_type) {
	// 		errors.know_type = 'Select Type';
	// }
	if (values.title) {
		if (values.title.trim() == "") {
			errors.title = 'Enter Title';
		}
	}
	else if (!values.title) {
		errors.title = 'Enter Title';
	}



	if (values.title && typeof (values.title) == "string" && values.title.length < 3) {
		errors.title = 'Please enter atleast 3 characters in Title.';
	}

	if (values.title && typeof (values.title) == "string" && format.test(values.title)) {
		errors.title = 'Special characters are not allowed in Title.';
	}

	if (!values.know_content_type) {
		errors.know_content_type = 'Select Content Type';
	}
	if (!values.know_content) {
		errors.know_content = 'Enter Content';
	}
	console.log(">>",errors);
	return errors;
	
}

class ComponentForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			AssociatedToDropdown: "",
			knowContentTypeDropdown: "File Upload",
		}
		this.onChangeHandle = this.onChangeHandle.bind(this);
		this.onNumberChange = this.onNumberChange.bind(this);
		this.onAssociatedToChange = this.onAssociatedToChange.bind(this);
		this.submitForm = this.submitForm.bind(this);
	}

	componentWillMount() {

		if (this.props.spcmReducer.editLeftId > 0) {
			let AssociatedToDropdown = 0;

			if (this.props.initialValues.know_parent_type == "Component") {
				AssociatedToDropdown = 1;
			} else {
				this.props.initialValues.know_parent_id = this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_NAME;

			}
			this.setState({ AssociatedToDropdown });
		}

		this.props.getConsumptionItems(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
	}

	componentWillUnmount() {
		this.props.spcmLoader('attribute', 'success');
	}

	onChangeHandle(e) {
		this.setState({ knowContentTypeDropdown: e.target.value });

	}

	onNumberChange(e) {
	}

	onAssociatedToChange(e) {
		const { change } = this.props
		if (e.target.value == "Service") {
			this.setState({ AssociatedToDropdown: 0 });
			change('know_parent_id', this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_NAME);
		}
		else if (e.target.value == "Component") {
			this.setState({ AssociatedToDropdown: 1 });
			change('know_parent_id', e.target.value);
		}

	}

	submitForm(values) {
		if (this.props.spcmReducer.editLeftId > 0) {
			if (values.internal=='') {
				values.internal = false;
			}
			values.updatedby_name = "";
			values.updatedby = "";
		} else {
			if (!values.internal) {
				values.internal = false;
			} 
			values.createdby_name = "";
			values.created_by = "";
		}

		values.know_type = "Reference material";
		let formData = Object.assign({}, values);
		if (values.know_parent_type == "Service")

			formData.know_parent_id = this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID;

		if (typeof values.know_content == "object") {

			formData = new FormData();
			if (values.know_parent_type == "Service")
				formData.append('know_parent_id', this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
			else
				formData.append('know_parent_id', values.know_parent_id);

			formData.append('know_file', values.know_content[0]);
			// formData.append('internal', values.internal1);



			Object.keys(values).forEach((key) => {
				if (key != "know_file" && key != "know_content" && key != "know_parent_id") {
					formData.append(key, values[key]);
				}

			})

		}
		if (this.props.spcmReducer.editLeftId > 0) {

			//return axios.patch(constants.SERVER+constants.SERVICE_KNOWLEDGE_API_URL+"/"+this.props.spcmReducer.editLeftId,formData).then((response) => {
			this.props.spcmLoader('attribute', 'posting');
			return axios.patch("/service/api/patchServiceKnowledge/" + this.props.spcmReducer.editLeftId, formData).then((response) => {
				this.props.getServiceKnowledge(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
				this.props.componentFormFun4(false);
				this.props.spcmLoader('attribute', 'success');
			})
				.catch((error) => {

					this.props.spcmLoader('attribute', 'success');
					throw new SubmissionError({ _error: error.response.data[0].message });
				});
		}
		else {
			//return axios.post(constants.SERVER+constants.SERVICE_KNOWLEDGE_API_URL,formData).then((response) => {
			this.props.spcmLoader('attribute', 'posting');
			return axios.post("/service/api/saveServiceKnowledge", formData).then((response) => {
				this.props.getServiceKnowledge(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
				this.props.getServiceDetailCounts(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
				this.props.componentFormFun4(false);
				this.props.spcmLoader('attribute', 'success');
			})
				.catch((error) => {

					this.props.spcmLoader('attribute', 'success');
					throw new SubmissionError({ _error: error.response.data[0].message });
				});
		}
	}

	render() {
		const tr = this.props.tr;
		const { error, handleSubmit } = this.props;
		let components = this.props.spcmReducer.consumptionItems;
		const disabledRadioBtn = this.props.spcmReducer.editLeftId > 0;
		return (
			<div className="rBoxGap">
				<form onSubmit={handleSubmit(this.submitForm)}>
					{error == undefined || error == null ? null : <Alert variant="danger" className="errorMessageSec"><p>{error}</p></Alert>}
					<Row>
						<Col md={12}>
							<Form.Group className="radioInlineBox form-group">
								<Form.Label bsClass="" className="float-l margin-r-20"><span className="rStar"></span> {tr['Associated To']}</Form.Label>
								<div className="radioInlineBox float-l">
									<Field component={_radio} className='form-control' type="radio" name='know_parent_type' checked={this.state.AssociatedToDropdown == 0} disabled={disabledRadioBtn} value='Service' onChange={this.onAssociatedToChange} />
									<Field component={_radio} className='form-control' type="radio" name='know_parent_type' checked={this.state.AssociatedToDropdown == 1} disabled={disabledRadioBtn} value='Component' onChange={this.onAssociatedToChange} />
								</div>
								{
									this.state.AssociatedToDropdown == 0
										? <Field component={_inputField} name="know_parent_id" className='form-control' readOnly />
										: <Field component={_dropDown} name="know_parent_id" options={components} className='form-control' />

								}
							</Form.Group>
						</Col>
						{/* <Col md={6}>
						<Form.Group >
						  <Form.Label bsClass=""><span className="rStar"></span> Associated To</Form.Label>
						  <Field name="know_parent_type" component={_dropDown} options={[{text:"Service",value:"Service"},{text:"Component",value:"Component"}]} className='form-control' onChange={this.onAssociatedToChange}/>
						 </Form.Group>
					</Col>
					<Col md={6}>
						<Form.Group >
						  <Form.Label bsClass=""><span className="rStar"></span> Component Name</Form.Label>
						 {
							 this.state.AssociatedToDropdown==0
									?<Field component={_inputField} name="know_parent_id" className='form-control' readOnly/>
								:<Field component={_dropDown} name="know_parent_id" options={components} className='form-control'/>

						 }
						 </Form.Group>
					</Col> */}

						{/*<Col md={12}>
						<Form.Group >
						  <Form.Label bsClass=""><span className="rStar"></span> {tr['Type']}</Form.Label>
						  <Field name="know_type" component={_dropDown} options={[{text:"Reference material",value:"Reference material"},{text:"Others",value:"Others"}]} className='form-control'/>
						 </Form.Group>
					</Col>*/}

						<Col md={12}>
							<Form.Group className='form-group'>
								<Form.Label bsClass=""><span className="rStar"></span> {tr['Title']}</Form.Label>
								<Field name="title" component={_inputField} className='form-control' maxLength={500} />
							</Form.Group>
						</Col>
						{/*<Col md={12}>
						<Form.Group >
						  <Form.Label bsClass=""><span className="rStar"></span>{tr['Content Type']}</Form.Label>
						  <Field name="know_content_type" component={_dropDown} disabled={this.props.spcmReducer.editLeftId>0?true:false} options={[{text:"External URL",value:"External URL"},{text:"File Upload",value:"File Upload"},{text:"Rich Text",value:"Rich Text"}]} className='form-control' onChange={this.onChangeHandle}/>
						 </Form.Group>
					</Col> */}

						<Col md={12}>
							<Form.Group className="labelBlock form-group">
								<Form.Label bsClass=""><span className="rStar"></span> {tr['Content']}</Form.Label>
								{
									this.props.spcmReducer.editLeftId > 0 ? (<Field name="know_content" component={_inputField} className='form-control' readOnly />) :
										this.state.knowContentTypeDropdown == "External URL" ? <Field name="know_content" component={_inputField} className='form-control' />
											: (this.state.knowContentTypeDropdown == "File Upload" ? <Field name="know_content" component={field_file} type="file" className='form-control' /> :
												(this.state.knowContentTypeDropdown == "Rich Text" ? <Field name="know_content" component={_textArea} className='form-control' /> : <Field name="know_content" component={_inputField} className='form-control' readOnly />
												))

								}
								<span className="f-size-10">{tr['Note: Supported File Formats ( .docx, .pdf )']}</span>
								{/* <label><input type="checkbox" checked={allCheckedFilters.includes('Company')} value="Company" onChange={(e) => {
																this.selectedFilter(e)
					}} />Company</label> */}
								{/* <Field name="Internal" id="Internal" component={_inputField} type="checkbox"/>
					<label>Internal</label> */}
							</Form.Group>
						</Col>
						<Col md={4}>
							<Form.Group className="radioInlineBox form-group">
								<div className="radioInlineBox lfFieldCheckbox margin-b-10">
									<Field name="internal" id="internal" component={_inputField} type="checkbox"></Field>
									<Form.Label bsClass="">{tr['Internal']}</Form.Label>
								</div>
							</Form.Group>
						</Col>
						

					</Row>
					<Row>
						<Col md={12}>
							<ButtonToolbar className="black"><Button className='rgSidrkBtn' title="Save" disabled={this.props.spcmReducer.spcmPostingAttribute} type="submit" bsSize="small" bsStyle="primary">{this.props.spcmReducer.spcmPostingAttribute ? <ImSpinner6 className="icn-spinner"/> : null} {tr['Save']}</Button><Button className='rgSiLigBtn' title="Close" onClick={() => { this.props.componentFormFun4(false) }} bsSize="small" bsStyle="text">{tr['Close']}</Button></ButtonToolbar>
						</Col>
					</Row>
				</form>
			</div>
		);
	}
}

const mapStateToProps = ({ spcmReducer }) => {
	
	return {
		spcmReducer,
		initialValues: spcmReducer.editLeftId > 0 ? spcmReducer.editLeftService : ''
	}
};
ComponentForm = reduxForm({
	form: 'serviceKnowledgeForm',
	validate,
	enableReinitialize: true
})(ComponentForm)

export default connect(mapStateToProps, { spcmLoader, setLeftEditId, getServiceDetailCounts, getServiceKnowledge, getConsumptionItems })(ComponentForm);
