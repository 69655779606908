
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import { HiOutlineRefresh, HiPlus } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { loadHamList } from '../../../actions/ham/hamAction';

const HamHeader = () => {

const translator = useSelector((state) => state.spcmReducer.tr);
const dispatch = useDispatch();

const refreshClassDefinition = () => {
	dispatch(loadHamList());
};
  return (
    <Row className="margin-b-15">
			<Col lg={4} md={6} sm={6} xs={12}>
				<h2 bsClass="" className="sPageHeading1">{translator['Class Definition']}</h2>
			</Col>
			<Col lg={8} md={6} sm={6} xs={12} className="text-r">
				<div className="paHedFilter">
					<Link role="button" className="myBt plus fillBtn" title={translator['Create New Class Definition']} to="/createClassDefinition" ><HiPlus/></Link>
					<Button title={translator["Refresh"]} role="button" bsPrefix=' '  className="myBt" onClick={() => { refreshClassDefinition(); }}><HiOutlineRefresh className="refreshFlip"/></Button>
				</div>
			</Col>
		</Row>
  );

}

export default HamHeader
