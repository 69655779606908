
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, change, SubmissionError } from 'redux-form';
import { Link } from "react-router-dom";

import { loadTagCompany, loadBusinessFunction, emptyBusinessFunction, loadSXMenulistData, loadAllCompositeTags, loadMemberTagsForCompositeTag } from '_Actions/foundation/tags/tagsAction';
import TagsDropdownPlusTypeahead from '../common/TagsDropdownPlusTypeahead.js';
import TagsDropdownPlusTypeaheadWithInfo from '../common/TagsDropdownPlusTypeaheadWithInfo.js';
import { _passwordField, _inputField, _dropDown, _checkbox, _number } from '../../../common/formFields';
import Cookies from "universal-cookie";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { openLinkInNewTab } from "../../../common/helper.js";
import "_Css/form/_form.scss";

const cookies = new Cookies();
let app_url = cookies.get("gph");
if (app_url) app_url = app_url.replace("s:", "");
if (app_url) app_url = app_url.substring(0, app_url.lastIndexOf("."));
app_url = app_url.split("~");
app_url = app_url[18];

const validate = (values) => {
    const errors = {};
    if (!values.company)
        errors.company = "Select company";
    if (!values.module)
        errors.module = "Select module";
    if (!values.businessFunctionId)
        errors.businessFunctionId = "Select business function";
    if (!values.label || values.label.trim() == '')
        errors.label = "Select label";
    if (!values.tagType)
        errors.tagType = "Select type";
    if (!values.tagDataValidation)
        errors.tagDataValidation = "Select data validation";
    if (!values.compositeTag)
        errors.compositeTag = "Select composite attribute";
    // if (!values.parentTag)
    //     errors.parentTag = "Select parent attribute";
    if (!values.tagEnabled)
        errors.tagEnabled = "Select enabled";
    return errors;
}

const FormComponent = (props) => {

    const dispatch = useDispatch();
    const translator = useSelector((state) => state.spcmReducer.tr);
    const foundation_tags_reducer = useSelector((state) => state.foundation_tags_reducer);
    const [companySelectedList, setCompanySelectedList] = useState([]);
    const [inputValueCompany, setInputValueCompany] = useState('');
    const [parentSelectedList, setParentSelectedList] = useState([]);
    const [inputValueParent, setInputValueParent] = useState('');
    let tagDataValidationValues = useSelector((state) => state.foundation_tags_reducer.tagDataValidation);
    tagDataValidationValues = tagDataValidationValues.filter((item) => item.field1Value == 'Dropdown' || item.field1Value == 'Typeahead')

    let tagTypeValues = useSelector((state) => state.foundation_tags_reducer.tagType);
    tagTypeValues = tagTypeValues.filter((item) => item.field1Value == 'Member');

    const selector = formValueSelector('foundationTagsCreateMemberForm');
    const companyIdParam = useSelector(state => selector(state, 'company'));
    const businessFunctionNameParam = useSelector(state => selector(state, 'businessFunctionName'));
    const moduleParam = useSelector(state => selector(state, 'module'));

    useEffect(() => {
        dispatch(loadTagCompany());
        dispatch(loadSXMenulistData('Tag', 'Module'));
        dispatch(loadSXMenulistData('Tag', 'Type'));
        dispatch(loadSXMenulistData('Tag', 'DataType'));
        // dispatch(loadSXMenulistData('Tag', 'DataSource'));
    }, []);

    useEffect(() => {
        setCompanySelectedList([{ id: props.compositeTagData.company, label: props.compositeTagData.companyName }]);
        dispatch(change('foundationTagsCreateMemberForm', 'company', props.compositeTagData.company));
        dispatch(change("foundationTagsCreateMemberForm", "companyName", props.compositeTagData.companyName));
        dispatch(change('foundationTagsCreateMemberForm', 'module', props.compositeTagData.module));
        dispatch(change('foundationTagsCreateMemberForm', 'businessFunctionId', props.compositeTagData.businessFunctionId));
        dispatch(change('foundationTagsCreateMemberForm', 'businessFunctionName', props.compositeTagData.businessFunctionName));
        dispatch(change('foundationTagsCreateMemberForm', 'tagType', 'Member'));
        dispatch(change('foundationTagsCreateMemberForm', 'compositeTag', props.compositeTagData.tagId));
        dispatch(loadBusinessFunction(props.compositeTagData.company));
        dispatch(loadAllCompositeTags(props.compositeTagData.company, props.compositeTagData.businessFunctionName, props.compositeTagData.module, 'Composite'));
    }, [props.compositeTagData]);

    function onCompanySelection(selectedCompany) {
        props.setCompanyErrorState('');
        setCompanySelectedList(selectedCompany);
        dispatch(change('foundationTagsCreateMemberForm', 'module', ''));
        dispatch(change('foundationTagsCreateMemberForm', 'businessFunctionId', ''));
        dispatch(change('foundationTagsCreateMemberForm', 'businessFunctionName', ''));
        dispatch(change('foundationTagsCreateMemberForm', 'tagType', ''));
        dispatch(change('foundationTagsCreateMemberForm', 'compositeTag', ''));
        dispatch(emptyBusinessFunction());
        if (selectedCompany.length > 0) {
            dispatch(loadBusinessFunction(selectedCompany[0].id));
            dispatch(change('foundationTagsCreateMemberForm', 'company', selectedCompany[0].id));
            dispatch(change("foundationTagsCreateMemberForm", "companyName", selectedCompany[0].label));
            setInputValueCompany(selectedCompany[0].label);
        }
        else {
            dispatch(change('foundationTagsCreateMemberForm', 'company', ''));
            dispatch(change("foundationTagsCreateMemberForm", "companyName", ''));
        }
    }

    function setCompanyErrorColor() {
        if (companySelectedList.length === 0)
            props.setCompanyErrorState('error');
        else
            props.setCompanyErrorState('');
    }

    function onCompanyInputChange(val) {
        setInputValueCompany(val);
    }

    function onCrossClickCompany() {
        dispatch(change('foundationTagsCreateMemberForm', 'company', ''));
        dispatch(change("foundationTagsCreateMemberForm", "companyName", ''));
        setInputValueCompany('');
        setCompanySelectedList([]);
        dispatch(change('foundationTagsCreateMemberForm', 'module', ''));
        dispatch(change('foundationTagsCreateMemberForm', 'businessFunctionId', ''));
        dispatch(change('foundationTagsCreateMemberForm', 'businessFunctionName', ''));
        dispatch(change('foundationTagsCreateMemberForm', 'tagType', ''));
        dispatch(change('foundationTagsCreateMemberForm', 'compositeTag', ''));
        dispatch(emptyBusinessFunction());
        props.setCompanyErrorState('error');
    }

    let companyTypeaheadOptions = [];
    if (foundation_tags_reducer.tagCompany && foundation_tags_reducer.tagCompany.length > 0) {
        foundation_tags_reducer.tagCompany.forEach((item, i) => {
            let obj = {};
            obj.id = item.field1Value;
            obj.label = item.field1Key;
            companyTypeaheadOptions.push(obj);
        });
    }

    function onParentSelection(selectedParent) {
        setParentSelectedList(selectedParent);
        if (selectedParent.length > 0) {
            dispatch(change('foundationTagsCreateMemberForm', 'parentTag', selectedParent[0].id));
            setInputValueParent(selectedParent[0].label);
        }
        else {
            dispatch(change('foundationTagsCreateMemberForm', 'parentTag', ''));
        }
    }

    function onParentInputChange(val) {
        setInputValueParent(val);
    }

    function onCrossClickParent() {
        dispatch(change('foundationTagsCreateMemberForm', 'parentTag', ''));
        setInputValueParent('');
        setParentSelectedList([]);
    }

    let parentTagTypeaheadOptions = [];
    if (foundation_tags_reducer.memberTagsForComposite && foundation_tags_reducer.memberTagsForComposite.length > 0) {
        foundation_tags_reducer.memberTagsForComposite.forEach((item, i) => {
            let obj = {};
            obj.id = item.tagId;
            obj.label = item.tagLabel;
            obj.info = item.tagEnabled == true ? 'Enabled: True' : 'Enabled: False';
            parentTagTypeaheadOptions.push(obj);
        });
    }

    function onModuleChange(e) {
        dispatch(change('foundationTagsCreateMemberForm', 'tagType', ''));
        dispatch(change('foundationTagsCreateMemberForm', 'compositeTag', ''));
        // if (e.target.value == 'CMDB') {
        dispatch(change('foundationTagsCreateMemberForm', 'businessFunctionId', 0));
        dispatch(change('foundationTagsCreateMemberForm', 'businessFunctionName', 'Default'));
        // }
        // else {
        //     dispatch(change('foundationTagsCreateMemberForm', 'businessFunctionId', ''));
        //     dispatch(change('foundationTagsCreateMemberForm', 'businessFunctionName', ''));
        // }
    }

    const tooltip = (
        <Popover id="popover-positioned-right" className="padding-10" title="Popover right">
        <strong>Simple:</strong> {translator['Simple Tooltip']} <br />
        <strong>Composite:</strong> {translator['Simple Tooltip2']} <br />
        <strong>Checklist:</strong> {translator['Checklist Tooltip2']}
        </Popover>
    );
    const tooltip2 = (
        <Popover id="popover-positioned-right" className="padding-10" title="Popover right">
        {translator['Composite Tooltip']}
        </Popover>
    );

    return (
        <>
            <Row>
                <Col md={6} sm={6} xs={12}>
                    <Form.Group className="form-group">
                        <Form.Label bsClass=""><span className="rStar"></span>{translator['Company']}</Form.Label>
                        <Field component={TagsDropdownPlusTypeahead}
                            errorColor={props.companyErrorState}
                            onSelection={onCompanySelection}
                            setErrorColor={setCompanyErrorColor}
                            name="company"
                            id="company"
                            className="form-control"
                            options={companyTypeaheadOptions}
                            selectedOptions={companySelectedList}
                            onInputChange={onCompanyInputChange}
                            inputValue={inputValueCompany}
                            onCrossClick={onCrossClickCompany}
                            disabled
                        />
                    </Form.Group>
                </Col>
                <Col md={6} sm={6} xs={12}>
                    <Form.Group className="form-group">
                        <Form.Label><span className="rStar"></span>{translator['Module']}</Form.Label>
                        <Field component={_dropDown} name="module" disabled options={foundation_tags_reducer.tagModule} onChange={onModuleChange} className="form-control" />
                    </Form.Group>
                </Col>
                {/* {moduleParam != 'CMDB' ?
                    <Col md={6} sm={6} xs={12}>
                        <Form.Group className="form-group">
                            <Form.Label><span className="rStar"></span>{translator['Business Function']}</Form.Label>
                            <Field component={_dropDown} name="businessFunctionId" disabled onChange={(e) => { dispatch(change('foundationTagsCreateMemberForm', 'businessFunctionName', e.target[e.target.selectedIndex].text)); dispatch(change('foundationTagsCreateMemberForm', 'tagType', '')); dispatch(change('foundationTagsCreateMemberForm', 'compositeTag', '')); }} options={foundation_tags_reducer.businessFunction} className="form-control" />
                        </Form.Group>
                    </Col>
                    : ''} */}
                <Col md={6} sm={6} xs={12}>
                    <Form.Group className="form-group">
                        <Form.Label><span className="rStar"></span>{translator['Type']}</Form.Label>
                        <OverlayTrigger trigger={['hover', 'focus']} rootClose placement="right" overlay={tooltip}>
                            <span><AiOutlineQuestionCircle className="questionSvg" style={{ fontSize: "18px" }}/></span>
                        </OverlayTrigger>
                        <Field component={_dropDown} name="tagType" disabled options={tagTypeValues} onChange={(e) => { if (e.target.value == 'Member') { dispatch(loadAllCompositeTags(companyIdParam, businessFunctionNameParam, moduleParam, 'Composite')); } }} className="form-control" />
                    </Form.Group>
                </Col>
                <Col md={6} sm={6} xs={12}>
                    <Form.Group className="form-group">
                        <Form.Label><span className="rStar"></span>{translator['Composite Attribute']}</Form.Label>
                        <Link
                            style={{ "margin-left": "5px" }}
                            role="button"
                            to=""
                            title={translator['Open record']}
                            onClick={() => {
                                openLinkInNewTab(app_url + "/home?editCustomAttribute=" + props.compositeTagData.tagId, "New Tab");
                            }}
                        >
                            <i className="fa fa-external-link" aria-hidden="true"></i>
                        </Link>
                        <OverlayTrigger trigger={['hover', 'focus']} rootClose placement="right" overlay={tooltip2}>
                            <span><AiOutlineQuestionCircle className="questionSvg" style={{ fontSize: "18px" }}/></span>
                        </OverlayTrigger>
                        <Field component={_dropDown} name="compositeTag" disabled options={foundation_tags_reducer.allCompositeTags} onChange={(e) => { dispatch(loadMemberTagsForCompositeTag(e.target.value)); }} className="form-control" />
                    </Form.Group>
                </Col>
                <Col md={6} sm={6} xs={12}>
                    <Form.Group className="form-group">
                        <Form.Label><span className="rStar"></span>{translator['Enabled']}</Form.Label>
                        <Field component={_dropDown} name="tagEnabled" options={[{ field1Key: 'True', field1Value: true }, { field1Key: 'False', field1Value: false }]} className="form-control" />
                    </Form.Group>
                </Col>
                <Col md={6} sm={6} xs={12}>
                    <Form.Group className="form-group">
                        <Form.Label><span className="rStar"></span>{translator['Label']}</Form.Label>
                        <Field name="label" component={_inputField} maxLength="50" className="form-control" />
                    </Form.Group>
                </Col>
                <Col md={6} sm={6} xs={12}>
                    <Form.Group className="form-group">
                        <Form.Label>{translator['Dependent on']}</Form.Label>
                        <Field component={TagsDropdownPlusTypeaheadWithInfo}
                            errorColor=''
                            onSelection={onParentSelection}
                            name="parentTag"
                            id="parentTag"
                            className="form-control"
                            options={parentTagTypeaheadOptions}
                            selectedOptions={parentSelectedList}
                            onInputChange={onParentInputChange}
                            inputValue={inputValueParent}
                            onCrossClick={onCrossClickParent}
                        />
                    </Form.Group>
                </Col>
                <Col md={6} sm={6} xs={12}>
                    <Form.Group className="form-group">
                        <Form.Label><span className="rStar"></span>{translator['Data validation']}</Form.Label>
                        <Field component={_dropDown} name="tagDataValidation" onChange={(e) => { dispatch(change('foundationTagsCreateMemberForm', 'character_limit', '')); }} options={tagDataValidationValues} className="form-control" />
                    </Form.Group>
                </Col>
            </Row>
        </>
    )
}

export default reduxForm({
    form: 'foundationTagsCreateMemberForm',
    destroyOnUnmount: true,
    validate,
    enableReinitialize: true
})(FormComponent);
