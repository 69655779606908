
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import { GLOBAL }  from '../../components/Globals';

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

export function loadReportCompanySuccess(reportCompany) {
	return {
		type: 'LOAD_REPORTCOMPANY_SUCCESS',
		reportCompany
	};
}

export function loadReportCompanyFailure(errorResponse) {
	return {
		type: 'LOAD_REPORTCOMPANY_FAILURE',
		errorResponse
	};
}

export function loadReportModuleSuccess(reportModule) {
	return {
		type: 'LOAD_REPORTMODULE_SUCCESS',
		reportModule
	};
}

export function loadReportModuleFailure(errorResponse) {
	return {
		type: 'LOAD_REPORTMODULE_FAILURE',
		errorResponse
	};
}

export function loadCriteriaListSuccess(criteriaList) {
	return {
		type: 'LOAD_CRITERIALIST_SUCCESS',
		criteriaList
	};
}

export function loadCriteriaListFailure(errorResponse) {
	return {
		type: 'LOAD_CRITERIALIST_FAILURE',
		errorResponse
	};
}

export function loadReportListDataSuccess(reportListData) {
	console.log("reportListData : >> ",reportListData)
	return {
		type: 'LOAD_REPORTLISTDATA_SUCCESS',
		reportListData
	};
}

export function loadReportListDataFailure(errorResponse) {
	return {
		type: 'LOAD_REPORTLISTDATA_FAILURE',
		errorResponse
	};
}

export function loadReportCompany(userId) {
	return (dispatch) => {
		axios.get(GLOBAL.slaCompanyUrl + userId)
			.then((reportCompany) => {
				if (!reportCompany) {
					throw Error(reportCompany.statusText);
				}
				return reportCompany;
			})
			.then((reportCompany) => {
				console.log('success in ReportCompany...');
				dispatch(loadReportCompanySuccess(reportCompany));
			})
			.catch((err) => {
				console.log(err);
			});

	};
}

export function loadReportModule(moduleName) {
	return (dispatch) => {
		//axios.get(GLOBAL.slaLovUrl + moduleName)
		api.get(GLOBAL.slareportLovUrl)
			.then((reportModule) => {
				if (!reportModule) {
					throw Error(reportModule.statusText);
				}
				return reportModule;
			})
			.then((reportModule) => {
				console.log('success in reportModule...');
				dispatch(loadReportModuleSuccess(reportModule));
			})
			.catch((err) => {
				console.log(err);
			});

	};
}

export function loadReportFieldsSuccess(reportFieldsList){
	return {
		type: 'LOAD_REPORTFIELD_SUCCESS',
		reportFieldsList
	};
}

export function loadReportFieldsFailure(errorResponse){
	return {
		type: 'LOAD_REPORTFIELD_FAILURE',
		errorResponse
	};
}
export function loadCriteriaList(moduleName,userId) {
	return (dispatch) => {
		//axios.get(GLOBAL.slaLovUrl + moduleName)
		axios.get("/rest/criterialist/moduleName/"+moduleName+"/userId/"+userId)
			.then((criteriaList) => {
				if (!criteriaList) {
					throw Error(criteriaList.statusText);
				}
				return criteriaList;
			})
			.then((criteriaList) => {
				console.log('success in reportModule...');
				dispatch(loadCriteriaListSuccess(criteriaList));
			})
			.catch((err) => {
				console.log(err);
			});

	};
}
export function loadReportFields(searchParams){
	console.log("loadReportFields value : ",searchParams);
	return(dispatch) =>{
      /*  if(searchParams=="Investigation")
				searchParams="xsm_investigation"
				if(searchParams=="Breakfix")
				searchParams="Breakfix"
				if(searchParams=="Fulfillment")
				searchParams="xsm_item"
				if(searchParams=="RFC")
				searchParams="xsm_change"*/
				if(searchParams=="cmdb"){
					console.log("inside cmdb url  action ")
					axios.get(GLOBAL.getReportCMDBFieldsUrl)
					.then((reportFieldsList)=>{
						if(!reportFieldsList){
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							throw Error(reportFieldsList.statusText);
						}
						return reportFieldsList;
					})
					.then((reportFieldsList) =>{
						dispatch(loadReportFieldsSuccess(reportFieldsList));
					})
					.catch((err) => {
						console.log(err);
					});
				}else if(searchParams=="task"){
					console.log("inside task url  action ");
					axios.get(GLOBAL.getReportTaskFieldsUrl)
					.then((reportFieldsList)=>{
						if(!reportFieldsList){
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							throw Error(reportFieldsList.statusText);
						}
						return reportFieldsList;
					})
					.then((reportFieldsList) =>{
						dispatch(loadReportFieldsSuccess(reportFieldsList));
					})
					.catch((err) => {
						console.log(err);
					});
				}else{
					console.log("inside action ")
				axios.get(GLOBAL.getReportFieldsUrl+searchParams)
					.then((reportFieldsList)=>{
						if(!reportFieldsList){
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							throw Error(reportFieldsList.statusText);
						}
						return reportFieldsList;
					})
					.then((reportFieldsList) =>{
						dispatch(loadReportFieldsSuccess(reportFieldsList));
					})
					.catch((err) => {
						console.log(err);
					});
					//console.log("not calling loadBreakFixQuickView");
				};
		}
}

export function loadReportList(searchByList,patternList,multipleValueList,module,companyId){
	return(dispatch) =>{
		let url="",companyid="";
		let axioscall="";
      if(module=="Incident" || module=="Breakfix")
			{
			url="/serviceManagement/rest/reportQuickViewAction/search";
			companyid="consumerCompany";
		}
			if(module=="Investigation" || module=="Problem")
			{
			url="/serviceManagement/rest/reportQuickViewAction/search";
			companyid="consumerCompany";
		}
			if(module=="Fulfillment")
			{
			url="/serviceManagement/rest/reportQuickViewAction/search";
			companyid="companyID";
		}
			if(module=="RFC" || module=="Change")
			{
			url="/serviceManagement/rest/reportQuickViewAction/search";
			companyid="consumerCompany";
		}
		if (module == "CMDB") {
			url = "/rest/report/list/cmdb";
			companyid = "COMPANY_ID";
		}
		if (module == "Task") {
			url =  GLOBAL.taskurl;
			companyid = companyId;
		}

		if (module != "Task") {
			if (searchByList != "" && patternList != "" && multipleValueList != "" && companyId != "") {
				searchByList = searchByList + "," + companyid + "";
				patternList = patternList + ",in";
				multipleValueList = multipleValueList + "|" + companyId;
			}

			if (companyId != "" && (searchByList == "" && patternList == "" && multipleValueList == "")) {
				searchByList = companyid;
				patternList = "in";
				multipleValueList = companyId;
			}
		}
		if (searchByList == "" && patternList == "" && multipleValueList == "" && companyId == "")
			axioscall = axios.get(url)
		else {
			if (module == "CMDB") {
				let arr = patternList.split(',');
				let patternListNew='';
				arr.forEach((item, i) =>{
					if(i === arr.length-1) {
						patternListNew = patternListNew + item;
					}
					else {
						patternListNew = patternListNew + item + '~';
					}
				})
				// axioscall = axios.get(url + "?searchByList=" + searchByList + "&multipleValueList=" + multipleValueList);
				let str={};
				str.searchByList=searchByList;
				str.patternList=patternListNew;
				str.multipleValueList=multipleValueList;
				str = JSON.stringify(str);
				axioscall=axios.get(url, { headers: { query: str } });

			} else if (module == "Task") {
				let searchByListArr = searchByList.split(",");
				let multipleValueListArr = multipleValueList.split("|");
				let taskCriteria = {};
				// taskCriteria["clientId"]=companyId
				// taskCriteria["moduleName"]="Investigation"
				// taskCriteria["applicationName"]="XSM"
				// taskCriteria.assignedToGroupId = { "inq": [] };

				for (let i = 0; i < searchByListArr.length; i++) {
          taskCriteria[searchByListArr[i]] = multipleValueListArr[i];
        }
				
				
				let config;
				let insideParams = JSON.stringify({"orderBY":"createdDate desc","requestType":"viewTask", "companyId":companyid});
				config = {
					headers: {
					'query': insideParams
					}
				};
				axioscall = axios.get(url, config);


			}else if(module=="Incident" || module=="Breakfix"){
				console.log("?searchByList="+searchByList+"&patternList="+
				patternList+"&multipleValueList="+multipleValueList);
				
				let str={};
				str.searchByList=searchByList;
				str.patternList=patternList;
				str.multipleValueList=multipleValueList;
				str.module="Breakfix";
				// str = JSON.stringify(str);
				axioscall=axios.post(url,str);
			}else if(module=="Fulfillment"){
				let str={};
				str.searchByList=searchByList;
				str.patternList=patternList;
				str.multipleValueList=multipleValueList;
				str.module="Fullfilment";
				// str = JSON.stringify(str);
				axioscall=axios.post(url,str);
			}
			else if(module=="RFC" || module=="Change"){
				let str = Object.assign({});
				str.searchByList = searchByList;
				str.patternList = patternList;
				str.multipleValueList = multipleValueList;
				str.module="ChangeManagement";
				let params = {};
				if (!_.isEmpty(str)) {
				  params = str;
				} //lodash isEmpty checks for nulls too
				axioscall = api.post(url, params);
			// axioscall=axios.get(url+"?searchByList="+searchByList+"&patternList="+
									// patternList+"&multipleValueList="+multipleValueList);
			}
			else if(module=="Investigation" || module=="Problem") {
				let str = Object.assign({});
				str.searchByList = searchByList;
				str.patternList = patternList;
				str.multipleValueList = multipleValueList;
				str.module="Investigation";
				let params = {};
				if (!_.isEmpty(str)) {
				  params = str;
				} //lodash isEmpty checks for nulls too
				axioscall = api.post(url, params);
			// axioscall=axios.get(url+"?searchByList="+searchByList+"&patternList="+
									// patternList+"&multipleValueList="+multipleValueList);
			}
			else{
			axioscall=axios.get(url+"?searchByList="+searchByList+"&patternList="+
									patternList+"&multipleValueList="+multipleValueList);
			}
		}
					axioscall.then((reportListData)=>{
						// console.log('reportListData============',reportListData);
						if(!reportListData){
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							throw Error(reportListData.statusText);
						}
						return reportListData;
					})
					.then((reportListData) =>{
						dispatch(loadReportListDataSuccess(reportListData));
					})
					.catch((err) => {
						console.log(err);
					});
					//console.log("not calling loadBreakFixQuickView");

	};
}

export function emptyReportList() {
	return (dispatch) => {
		dispatch({
			type: "EMPTY_REPORTLISTDATA"
		  });
	};
}
