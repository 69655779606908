
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import moment from "moment";

import PindCategories from "../../common/header/pind-categories";
import Breadcrumbs from "../../common/header/breadcrumbs";
import Header from "./header";
import List from "./list";
import { useJobnotificationQuery } from "../../../reducers/gds";

const cookies = new Cookies();

const Index = () => {
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.showLoader.loading);

  const [pageNum, setPageNum] = useState(0);
  const [pgSize, setPgSize] = useState(10);
  const [isFilterCleared, setIsFilterCleared] = useState(false);
  const [showFilterIcon, setShowFilterIcon] = useState(false);
  const [searchParams, setSearchParams] = useState({});

  const {
    data: alljobs,
    isFetching: isjobFetching,
    refetch: jobRefetch,
  } = useJobnotificationQuery({
    queryString: `?pageNumber=${pageNum}&pageSize=${pgSize}`,
    params: searchParams,
  });

  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Breadcrumbs activePageName="Get Golden Dataset" />
      </Container>
      <Container fluid>
        <Header 
          refresh={jobRefetch}   
          setShowFilterIcon={setShowFilterIcon}
          showFilterIcon={showFilterIcon}
          setPageNum={setPageNum}
          setPgSize={setPgSize}
          setSearchParams={setSearchParams}
          setIsFilterCleared={setIsFilterCleared}
          />
        <List
          data={alljobs || []}
          isBusy={isjobFetching}
          setPageNum={setPageNum}
          pgSize={pgSize}
          setPgSize={setPgSize}
          pageNum={pageNum}
          isFilterCleared={isFilterCleared}
          setIsFilterCleared={setIsFilterCleared}
          setShowFilterIcon={setShowFilterIcon}
          showFilterIcon={showFilterIcon}
          setSearchParams={setSearchParams}
          jobRefetch={jobRefetch}
        />
      </Container>
    </main>
  );
};

export default Index;
