
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import axios from "axios";
import { change, reduxForm } from "redux-form";
import { useDispatch } from "react-redux";
const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

const MultiSelectField = (props) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    if (props?.attributes?.url) {
      let optionsDataDetail = [],
        optionsData = [],
        myObj = {},
        strObj = {};
      strObj.like = "";
      strObj.companyId = 3215;
      strObj = JSON.stringify(strObj);
      api
        .get(props?.attributes?.url, { headers: { query: strObj } })
        .then((response) => {
          optionsData = response.data;
          if (Array.isArray(response.data)) {
            for (const element of optionsData) {
              myObj = { value: "", label: "" };
              myObj.label = element[props?.attributes?.labelKey];
              myObj.value = element[props?.attributes?.valueKey];
              optionsDataDetail.push(myObj);
            }
            optionsDataDetail = Array.from(new Set(optionsDataDetail));
          } else {
            optionsDataDetail = [];
          }
          setOptions(optionsDataDetail);
        });
    }
  },[]);

  const handleChange = (event) => {
    setSelectedValues(event.value);
  };

  // useEffect(() => {
  //   dispatch(change("FormView", props?.attributes?.name, selectedValues));
  // }, [selectedValues]);

  return (
    <div>
      <MultiSelect
        data={options}
        onChange={handleChange}
        value={selectedValues}
        textField={"label"}
        dataItemKey={"value"}
        placeholder="Please select ..."
        disabled={props?.attributes?.disabled}  
      />
    </div>
  );
};

// export default reduxForm({
//   form: "FormView",
// })(MultiSelectField);

export default MultiSelectField;
