
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { Component } from 'react';
const Joi = require('joi')
import sanitizeHtml from 'sanitize-html';
import parse from "html-react-parser";

class ProductDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}
	createDescriptionMarkup(description) {

		let namedSchema = undefined
		namedSchema = Joi.string().regex(/^[^}{]+$/);
		let validator_to_add_myQbj = undefined
		let validate_summary = undefined

		if (description != null || description != "") {
			validator_to_add_myQbj = namedSchema.validate(description)
			if (validator_to_add_myQbj.error == null) {
				validate_summary = description
			}
			else {
				validate_summary = ""
			}
		}
		else
			validate_summary = ""

		validate_summary = sanitizeHtml(validate_summary, {allowedAttributes: {
			'*': ['style', 'class', 'dir', 'bgcolor'],
			'table': ['border', 'cellpadding', 'cellspacing'],
			'th': ['scope']
		}})
		return { __html: validate_summary };
		// return { __html: description };
	}

	productSpecification(specification) {
		if (!specification) {
			return (
				<tr>
					<td width="100%">
						<div>{this.props.translatoroffering['Not Available']}.</div>
					</td>
				</tr>
			);
		} else {
			let specifications = ((specification.replace(/"/g, "")).replace(/[{}]/g, "")).split(",");

			let spec_key_checker = []
			let spec_key_corr_value = []
			let specValue_prev = ""

			specifications.map((specification, index) => {
				let specKey = specifications[index].substring(0, specifications[index].indexOf(':'));
				let specValue = specifications[index].substring(specifications[index].indexOf(':') + 1, specifications[index].length);
				if (specKey == "") {
					if (specValue_prev == "")
						specValue_prev += specValue
					else {
						specValue_prev += ", " + specValue
					}
				}
				else if (specKey != "") {
					spec_key_checker.push(specKey)
					if (specValue_prev != "") {
						spec_key_corr_value.push(specValue_prev)
						specValue_prev = ""
						specValue_prev += specValue
					}
					else {
						specValue_prev += specValue
					}
				}
			});

			if (spec_key_checker.length != 0 || (spec_key_corr_value.length != 0 && spec_key_checker.length != 0))
				spec_key_corr_value.push(specValue_prev)
			let html_obj_collection = Array(spec_key_checker.length)
			for (let i = 0; i < spec_key_checker.length; i++) {
				html_obj_collection[i] = (
					<tr key={i}>
						<td width="50%">{spec_key_checker[i]}</td>
						<td width="50%">
							<div>{spec_key_corr_value[i]}</div>
						</td>
					</tr>
				)
			}
			return html_obj_collection
			// return specifications.map((specification, index) => {
			// 	let specKey = specifications[index].substring(0, specifications[index].indexOf(':'));
			// 	let specValue = specifications[index].substring(specifications[index].indexOf(':') + 1, specifications[index].length);
			// 	return (
			// 		<tr key={index}>
			// 			{!specKey ? <td /> : <td width="20%">{specKey}</td>}
			// 			<td width="80%">
			// 				{!specValue ? <td /> : <div>{specValue}</div>}
			// 			</td>
			// 		</tr>
			// 	);
			// });
		}
	}

	viewProductDetails(offering) {
		return (
			<div className="proDetTab" id="viewMDetails">
				{offering.specification == "{}" ? null : <div className="specRg">
					<div className="f-size-18 margin-t-10 margin-b-5">{this.props.translatoroffering['Specifications']}</div>
					<table className="techSpecs-table">
						<tbody>
							{this.productSpecification(offering.specification)}
						</tbody>
					</table>
				</div>}
				{/* <div className="declg" dangerouslySetInnerHTML={this.createDescriptionMarkup(offering.offeringSummary)} /> */}
				<div className="declg">{parse(String(offering.offeringSummary))}</div>
			</div>
		);
	}
	render() {
		return (this.viewProductDetails(this.props.offering));
	}
}

export default ProductDetails;
