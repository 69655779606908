
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Row, Col, Nav, Dropdown } from "react-bootstrap";
let sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
import _ from "lodash";
import { GLOBAL } from "_Globals";
import axios from "axios";
import { connect } from "react-redux";
import swal from "sweetalert";
import { loadWorkdayScheduleData } from "../../../../actions/sla/lovAction";
import { reduxForm, SubmissionError } from "redux-form";
import { navigationHooks } from "../../../../helpers/NavigationHook";
import { Link, NavLink } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import {TbCirclesRelation} from "react-icons/tb";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import {LuSearch, LuBarChart, LuFileText, LuActivitySquare} from 'react-icons/lu';
import {BiDotsVertical} from 'react-icons/bi';
import { MyContext } from '_MyContext';

let WorkScheduleHeader = class WorkScheduleHeader extends React.Component {
  static contextType = MyContext;
  constructor(props) {
    super(props);
    this.saveChange = this.saveChange.bind(this);
  }

  componentDidMount() {
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
    this.context.newTabPrevent();
	}
	componentDidUpdate() {
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
    this.context.newTabPrevent();
	}
  saveChange(values) {
    if (`${JSON.stringify(values, null, 2)}` == "{}") {
      throw new SubmissionError({ _error: "Please select a values!" });
    } else if (
      values.scheduleCompany == 0 ||
      values.scheduleCompanyName == ""
    ) {
      GLOBAL.holidayCompanyEditError = "error";
      throw new SubmissionError({
        _error:
          "The form has missing required fields, please revalidate the highlighted fields and update the form !",
      });
    } else {
      let ref = this;
      axios
        .put(
          GLOBAL.holidayScheuleUpdate +
            ref.props.slaHolidayScheduleDetails.slaHolidayScheduleDetails
              .scheduleId,
          {
            /*  "companyId": values.scheduleCompany,
      "companyName": values.scheduleCompanyName,
      "scheduleName": values.scheduleName,
      "statusId": values.scheduleStatus,
      "status": values.scheduleStatusName */
            companyName: values.scheduleCompanyName,
            companyId: values.scheduleCompany,
            scheduleName: values.scheduleName,
            status: values.scheduleStatusName,
            createdByName: "",
            createdBy: "",
            statusId: values.scheduleStatus,
          }
        )
        .then((response) => {
          if (response.status == 200) {
            if (response.data.api_error != "") {
              swal(response.data.api_error);
            } else {
              navigationHooks.navigate("/slaHolidaylist");
            }
          } else {
            console.log("error creating holiday schedule");
          }
        })
        .catch((err) => {
          console.log("error creating holiday schedule", err);
        });
    }
  }
  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;
    return (
      <Row className="margin-b-15">
        <Col lg={7} md={7} sm={6} xs={12}>
          <h1 bsClass="" className="sPageHeading1">
            {this.props.translator["Holiday Schedule"]}
          </h1>
        </Col>
        <Col lg={5} md={5} sm={6} xs={12}>
          <div className="status myStatus marginT10 margin-t-10">
            <Nav as="ul">
              <Nav.Item
                as="li"
                title={this.props.translator["Save"]}
                eventKey={1}
                onClick={handleSubmit(this.saveChange)}
              >
               <Link to="javascript:void(0)" className="ctrlKeyPrevent" ref={(e) => (this.saveObjRef = e)}>
                 <IoSaveOutline/>
                </Link>
              </Nav.Item>

              <Nav.Item
                as="li"
                title={this.props.translator["Close"]}
                eventKey={1}
              >
                <NavLink to="/slaHolidaylist" className="ctrlKeyPrevent" ref={e => this.closeButton = e}>
                 <IoClose/>
                </NavLink>
              </Nav.Item>
            </Nav>
          </div>
          <div className="status myStatusGap border-r margin-t-10 margin-r-10 padding-r-10">
            <Nav as="ul">
            <Nav.Item
                as="li"
                title={this.props.translator["Holiday Details"]}
                onClick={() => {
                  this.props.changeActionView("holidayEntry");
                  this.props.rightEditPanel(true);
                }}
                eventKey={3}
              >
                <a className={this.props.actionView == 'holidayEntry' ? "active" : ""} href={void(0)}>
                  <LuFileText/>
                </a>
              </Nav.Item>

              <Nav.Item
                  title={["Activity Details"]}
                  eventKey={5}
                  as="li"
                  onClick={() => {
                  this.props.changeActionView("activityDetails");
                  this.props.rightEditPanel(true); }}
                >
                <a className={this.props.actionView == 'activityDetails' ? 'active' : ''}>
                  <LuActivitySquare /></a>
                </Nav.Item>
              <Nav.Item
                as="li"
                title={this.props.translator["Process Hierarchy"]}
                onClick={() => {
                  this.props.changeActionView("servicediagram");
                  this.props.rightEditPanel(true);
                }}
                eventKey={2}
              >
                <a className={this.props.actionView == 'servicediagram' ? "active" : ""} href={void(0)}>
                <LuSearch/><LuBarChart className='processBarIcn'/>
                </a>
              </Nav.Item>
              {/*<Nav.Item title={this.props.translator['Process Hierarchy']} onClick={() => { this.props.changeActionView("holidayEntry"); }} eventKey={4}><span className="sAction bgBtn17 chart"></span></Nav.Item>*/}
              <NavDropdown
                className="navdrp"
                eventKey={5}
                as={"li"}
                title={ <BiDotsVertical title="More"/> }
                id="extLnks"
              >
                <Dropdown.Item
                  bsPrefix=" "
                  title={this.props.translator["Related SLAs"]}
                  eventKey={4.1}
                  onClick={() => {
                    this.props.changeActionView("viewRelatedSls");
                    this.props.rightEditPanel(true);
                  }}
                >
                  <TbCirclesRelation/>
                  {this.props.translator["Related SLAs"]}
                </Dropdown.Item>
              </NavDropdown>
            </Nav>
          </div>
        </Col>
      </Row>
    );
  }
};

WorkScheduleHeader = reduxForm({
  form: "holidayScheduleEditForm",
})(WorkScheduleHeader);

export default connect(null, null)(WorkScheduleHeader);
