
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { reduxForm, SubmissionError } from "redux-form";
import { ImSpinner6 } from "react-icons/im";
import { IoSaveOutline, IoClose } from "react-icons/io5";
import Swal from "sweetalert2";
import { SaveLanguage, UpdateLanguage } from "../../graphQl/GraphqlQueries";
import { useMutation } from "@apollo/client";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const gph = cookies.get("gph").split("~");
const username = typeof gph[32] !== "undefined" ? gph[32] : "";
let sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const AddLanguageHeader = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tr = useSelector((state) => state.spcmReducer.tr);

  const [saveLanguage, { data, loading: disableBtn }] =
    useMutation(SaveLanguage);
    const [updateLanguage, { data:updatedData, loading: udisableBtn }] =
      useMutation(UpdateLanguage);
  const submit = async (values) => {
    if (!values || values == {}) {
      throw new SubmissionError({
        _error:
          "The form has missing required fields, please revalidate the highlighted fields and update the form",
      });
    } else {
      const postObjects = {
        language: values?.language,
        key: values?.key,
        value: values?.value,
        updatedBy: username,
        status: values?.status,
      };
      if(props?.params?.languageId){
        updateLanguage({
          variables: {
            id:props?.params?.languageId,CreateLanguageInput: postObjects,
          },
          onError(err) {
            Swal.fire("Something went wrong!");
          },
          onCompleted(data){
            Swal.fire("Data has been updated successfully!");
          }
        });
      }else{
        postObjects.createdBy = username;
        saveLanguage({
          variables: {
            CreateLanguageInput: postObjects,
          },
          onError(err) {
            Swal.fire("Something went wrong!");
          },
        });
      }
    }
  };

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      navigate("/languages");
    }
  }, [data]);

  return (
    <Row className="margin-b-15">
      <Col lg={6} md={6} sm={12} xs={12}>
        <h2 className="sPageHeading1">{"Language"}</h2>
      </Col>
      <Col lg={6} md={6} sm={12} xs={12}>
        <div className="paHedFilter">
          <Button
            bsPrefix=" "
            id="creTag"
            role="button"
            className="myBt plus"
            disabled={props.submitting}
            onClick={props.handleSubmit(submit)}
            title={tr["Save"]}
          >
            {disableBtn || udisableBtn ? (
              <ImSpinner6 className="icn-spinner" />
            ) : (
              <IoSaveOutline />
            )}
          </Button>
          <Button
            bsPrefix=" "
            role="button"
            className="myBt cancel fillBtn"
            title={tr["Cancel"]}
            onClick={() => {
              navigate("/languages");
            }}
          >
            <IoClose />
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default reduxForm({
  form: "AddLanguageForm",
  destroyOnUnmount: true,
  enableReinitialize: true,
})(AddLanguageHeader);
