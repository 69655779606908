
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { ImSpinner6 } from "react-icons/im";
import { freezeNotApplicable } from "../../studio/utils";
const LinkComponent = ({
  item,
  buttonClicked,
  attributes,
  renderComponent,
  clickedButton,
  field,
  defaultValues,
  rowData
}) => {
  let type;
  let showSpinner = false;
  let returnData = {};
  if (item && typeof item.buttonType == "object") {
    type = item?.buttonType?.id;
  }
  if (attributes?.href || attributes?.target) {
    delete attributes["href"];
    delete attributes["target"];
  }
   if(item?.attributes?.id===clickedButton?.attributes?.id){
    showSpinner = true;
   }
   let editable = true;
    if(defaultValues && defaultValues.hasOwnProperty('edittable') && !freezeNotApplicable.includes(item?.buttonType?.id)){
       editable = defaultValues?.edittable;
       if(!editable){
       attributes["class"] = attributes?.class+" inactive";
      }
    }
  if (item?.passingKeys) {
    const passingKeys = item?.passingKeys?.split(",");
    if (passingKeys && passingKeys.length > 0) {
      passingKeys.forEach((key) => {
        if (field.hasOwnProperty(key)) {
          returnData[key] = field[key];
        } else if (defaultValues.hasOwnProperty(key)) {
          returnData[key] = defaultValues[key];
        }
      });
    }
  } else if (field) {
    returnData = field;
  } else {
    returnData = defaultValues;
  }
  if(rowData) {
    returnData = { ...returnData, ...rowData };
  }

  if (item?.buttonType?.id === "clearAllFilter" && !defaultValues?.showClearFilter) {
    return <></>;
  }
  return (
    <a
      onClick={() => {
        if (editable) {
          buttonClicked(type, item, returnData);
        }
      }}
      href={void 0}
      {...attributes}
    >
      {showSpinner ? (
        <ImSpinner6 className="icn-spinner" /> 
      ) : (
        renderComponent(item?.components || [])
      )}
    </a>
  );
};

export default LinkComponent;
