
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import { connect } from "react-redux";
import axios from "axios";
import { bindActionCreators } from "redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { loadBreakFixSupportCIMGroup } from "../../../../actions/breakFix/cimGroupAction";
import { loadBreakFixCIMForUserDetails } from "../../../../actions/breakFix/cimDetailsAction";
import { loadBreakFixEditDetails } from "../../../../actions/breakFix/breakFixEditDetailsAction";
import { loadNewBreakFixSGAEWithQueryParams } from "../../../../actions/breakFix/serviceGroupAEAction";
import { GLOBAL } from "_Globals";
import { SubmissionError } from "redux-form";

import { loadStatusBasedHamburgerOptions } from "../../../../actions/common/loadStatusBasedHamburgerOptions";
import { loadTimelineData } from "../../../../actions/common/commonApisActions.js";
import { navigationHooks } from "../../../../helpers/NavigationHook";
import { IoClose } from "react-icons/io5";
import { ImSpinner6 } from "react-icons/im";
import { RuleAmsDdropDown } from "../../../common/forms/ruleDropDown.js";
import { RulesDropdownList } from "../../../common/forms/RulesDropdownList.js";

let sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
var criticalityAction;
var justification;

class ProposeCriticalTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clickOnCalSubmit: false,
    };
    const initData = {
      justification: "",
      cimGroup: "",
      cimUserDeails: "",
      rulesAttribute: {},
    };
    this.props.initialize(initData);
    this.onCIMGroupChange = this.onCIMGroupChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentWillMount() {
    let amsAttribute = {
      Status: this.props.breakFixEditDetails.statusId,
      Priority: this.props.breakFixEditDetails.priorityValue,
      Service: this.props.breakFixEditDetails.serviceId,
      "Impacted CI": this.props.breakFixEditDetails.ciId,
      Requestor: this.props.breakFixEditDetails.impactedUserid,
      Class: this.props.breakFixEditDetails.ciClassId,
      "User.Location": this.props.breakFixEditDetails.requesterLocationId,
      "CI.Location": this.props.breakFixEditDetails.ciLocationId,
    };
    let rulesAttribute = {
      status: this.props.breakFixEditDetails.statusId,
      priorityId: this.props.breakFixEditDetails.priorityId,
      serviceName: this.props.breakFixEditDetails.serviceName,
      impactedCi: this.props.breakFixEditDetails.ciName,
      impactedUsername: this.props.breakFixEditDetails.impactedUsername,
      ciClassName: this.props.breakFixEditDetails.ciClassName,
      userLocation: this.props.breakFixEditDetails.requesterLocationName,
      ciLocation: this.props.breakFixEditDetails.ciLocationName,
      serviceId: this.props.breakFixEditDetails.serviceId,
      impactedCiId: this.props.breakFixEditDetails.ciId,
      requestorId: this.props.breakFixEditDetails.impactedUserid,
      classId: this.props.breakFixEditDetails.ciClassId,
      userLocationId: this.props.breakFixEditDetails.requesterLocationId,
      ciLocationId: this.props.breakFixEditDetails.ciLocationId,
    };
    this.setState({ rulesAttribute });
    if (Array.isArray(this.props.getBreanFixkItemId)) {
      let getBreanFixkItemId = this.props.breakFixItemId
        ? this.props.breakFixItemId.split(":")
        : "";
      let breakFixActualItemId = this.props.breakFixItemId || ""; //getBreanFixkItemId[1];
      GLOBAL.breakFixId = breakFixActualItemId;
    } else {
      GLOBAL.breakFixId = this.props.breakFixItemId
        ? this.props.breakFixItemId
        : "";
    }
    // this.props.loadNewBreakFixSGAEWithQueryParams(
    //   this.props.breakFixEditDetails.serviceBased
    //     ? this.props.breakFixEditDetails.serviceId
    //     : this.props.breakFixEditDetails.ciId,
    //   this.props.breakFixEditDetails.consumerCompany,
    //   "CIM",
    //   this.props.breakFixEditDetails.serviceBased ? "service" : "impactedCI",
    //   amsAttribute,
    //   this.props.breakFixEditDetails.serviceCompanyId
    // );
  }

  onCIMGroupChange(e) {
    let grpid = e.target.value.split(":");
    GLOBAL.breakfixgroupid = grpid[0];
  }

  renderCIMGroup(breakFixData) {
    if (breakFixData == undefined) {
      return null;
    }
    if (GLOBAL.breakFixId != "") {
      return breakFixData.map((breakFixObj) => {
        return (
          <option value={breakFixObj.field1Value + ":" + breakFixObj.field1Key}>
            {breakFixObj.field1Key}
          </option>
        );
      });
    }
  }

  renderSupportPGroup(breakFixData) {
    let grouplist = [];
    if (!breakFixData || breakFixData.length == 0) {
      return null;
    }

    breakFixData.map((breakFixObj) => {
      grouplist.push(
        breakFixObj.assignmentGroupId +
          "~" +
          breakFixObj.assignmentGroupName +
          "~" +
          breakFixObj.assignmentCompanyName
      );
    });
    grouplist = grouplist.filter(function (item, index, inputArray) {
      return inputArray.indexOf(item) == index;
    });
    return grouplist.map((breakFixObj) => {
      let breakfixobj1 = breakFixObj.split("~");
      return (
        <option value={breakfixobj1[0] + ":" + breakfixobj1[1]}>
          {breakfixobj1[2]
            ? `${breakfixobj1[1]} (${breakfixobj1[2]})`
            : breakfixobj1[1]}
        </option>
      );
    });
  }

  renderSupportPIAEGroup(breakFixData) {
    if (!breakFixData || breakFixData.length == 0) {
      return null;
    }
    return breakFixData.map((breakFixObj) => {
      if (
        breakFixObj.assignmentGroupId == GLOBAL.breakfixgroupid &&
        breakFixObj.assignmentMemberId != 0
      )
        if (
          breakFixObj.assignmentMemberId != null &&
          breakFixObj.assignmentMemberName != null
        ) {
          return (
            <option
              value={
                breakFixObj.assignmentMemberId +
                ":" +
                breakFixObj.assignmentMemberName
              }
            >
              {breakFixObj.assignmentMemberName}
            </option>
          );
        }
    });
  }

  renderCIMDetails(breakFixData) {
    if (!breakFixData) {
      return null;
    }
    if (this.props.cimGroup != "") {
      return breakFixData.map((breakFixObj) => {
        return (
          //GLOBAL.CIMUserName=breakFixObj.field1Key;

          <option value={breakFixObj.field1Value + ":" + breakFixObj.field1Key}>
            {breakFixObj.field1Key}
          </option>
        );
      });
    }
  }

  submit(values) {
    let ref = this;
    return sleep(1000) // simulate server latency
      .then(() => {
        let breakFixConsumer = GLOBAL.breakFixConsumerID;
        let cimGroup = "";
        let cimGroupname = "";
        let cimUser = "";
        let cimUserName = "";
        let actualValueCimGroup = values.cimGroup
          ? values.cimGroup.split(":")
          : "";
        let actualValueCimUser = values.cimUserDeails
          ? values.cimUserDeails.split(":")
          : "";
        cimGroup = actualValueCimGroup[0];
        cimGroupname = actualValueCimGroup[1];
        cimUser = actualValueCimUser[0];
        cimUserName = actualValueCimUser[1];

        if (`${JSON.stringify(values, null, 2)}` == "{}") {
          throw new SubmissionError({ _error: "Please select a values!" });
        } else if (
          values.justification == "" ||
          values.justification == undefined
        ) {
          //alert("HU1");
          throw new SubmissionError({
            _error: "Please provide justification for critical !",
          });
        } else if (values.justification.trim() == "") {
          throw new SubmissionError({
            _error: "Please provide valid justification for critical !",
          });
        } else if (values.cimGroup == undefined || values.cimGroup == "") {
          throw new SubmissionError({ _error: "Please select CIM Group!" });
        } else {
          this.setState({ clickOnCalSubmit: true });
          GLOBAL.proposeButtonStatus = "true";

          let breakFixItmId = "";
          breakFixItmId = GLOBAL.breakFixId;

          axios
            .patch(GLOBAL.breakFixEditUrl + breakFixItmId, {
              criticalJustification: values.justification,
              cimGroup: cimGroup,
              cimUser: cimUser,
              cimGroupname: cimGroupname,
              cimUsername: cimUserName,
              criticalityStatus: "5",
              modifiedBy: "",
              modifiedByName: "",
            })
            .then(function (response) {
              console.log(response);
              GLOBAL.removeCritical = 0;
              GLOBAL.criticalitystatusval = 1;
              GLOBAL.proposemessage = "Saved";
              ref.props.ChangeproposeCriticalTabStatus(true);
              ref.setState({ clickOnCalSubmit: false });
              ref.props.loadBreakFixEditDetails(response.data.breakfixId);
              ref.props.loadStatusBasedHamburgerOptions(
                "Breakfix",
                response.data.status,
                response.data.breakfixId
              );
              navigationHooks.navigate("/editBreakFix/" + GLOBAL.breakFixId);
            })
            .catch(function (error) {
              console.log(error);
              ref.setState({ clickOnCalSubmit: false });
              GLOBAL.removeCritical = 1;
            });
        }
      });
  }

  render() {
    let proposemsgs = this.props.proposemsg;
    if (this.props.cimGroupDetails instanceof Error) {
      return <h2>Network Error Occured...Please try again....</h2>;
    }

    let {
      error,
      handleSubmit,
      pristine,
      reset,
      submitting,
      justification,
      cimGroup,
      cimUserDeails,
    } = this.props;
    let savemsg = "";

    const isDisableBtn =
      this.state.clickOnCalSubmit ||
      (this.props.breakFixEditDetails &&
        this.props.breakFixEditDetails.criticalityStatus &&
        this.props.breakFixEditDetails.criticalityStatus == "Proposed")
        ? true
        : false;

    criticalityAction = (
      <ButtonToolbar>
        <Button
          className="rgSidrkBtn smallBtn"
          bsSize="small"
          bsStyle="primary"
          onClick={handleSubmit(this.submit)}
          disabled={isDisableBtn}
        >
          {this.state.clickOnCalSubmit ? (
            <ImSpinner6 className="icn-spinner" />
          ) : null}{" "}
          {this.props.translator["Save"]}
        </Button>
      </ButtonToolbar>
    );

    if (
      this.props.breakFixEditDetails &&
      this.props.breakFixEditDetails.criticalityStatus &&
      this.props.breakFixEditDetails.criticalityStatus == "Proposed"
    ) {
      savemsg = "The Work Item priority is proposed as critical";
    }

    if (this.props.breakFixEditDetails.priorityValue == "Critical") {
      return (
        <div className="hambTab">
          <div
            className="rPageHeadActBtn d-md-none"
            style={{ marginTop: "-3px" }}
          >
            <ul>
              <li>
                <button
                  type="button"
                  title="Minimize the right panel"
                  bsClass=""
                  bsStyle=""
                  className="closerightPanelBtn"
                  onClick={() => {
                    this.props.rightEditPanel(false);
                  }}
                >
                  <IoClose />
                </button>
              </li>
            </ul>
          </div>
          <div className="rPageHeading">
            <div className="offNam margin-t-5 margin-b-5">
              {this.props.translator["Propose Critical"]}
            </div>
          </div>
          <div className="rBoxGap">
            <font color="red">{error && <strong>{error}</strong>}</font>
            <Form.Group className="form-group">
              <Form.Label>
                <span className="rStar"></span>
                {this.props.translator["CIM Group"]}
              </Form.Label>
              {/* <Field
                name="cimGroup"
                component="select"
                type="select"
                className="form-control"
                onChange={(event) => {
                  this.onCIMGroupChange(event);
                }}
              >
                <option value="">Select..</option>
                {this.renderSupportPGroup(this.props.breakFixSGAENameCIM)}
              </Field> */}
              <RulesDropdownList
                name="cimGroup"
                component="select"
                className="form-control"
                onChange={(event) => {
                  this.onCIMGroupChange(event);
                }}
                rulesAttribute={this.state.rulesAttribute}
                serviceCompanyId={this.props.breakFixEditDetails.serviceCompanyId}
                companyId={this.props.breakFixEditDetails.consumerCompany}
                moduleName="incident"
                ruleType="assignment"
                ruleFor="criticalIncidentManager"
              />
            </Form.Group>

            {criticalityAction}
            <font color="red">{savemsg}</font>
          </div>
        </div>
      );
    } else {
      return (
        <div className="hambTab">
          <div
            className="rPageHeadActBtn d-md-none"
            style={{ marginTop: "-3px" }}
          >
            <ul>
              <li>
                <button
                  type="button"
                  title="Minimize the right panel"
                  bsClass=""
                  bsStyle=""
                  className="closerightPanelBtn"
                  onClick={() => {
                    this.props.rightEditPanel(false);
                  }}
                >
                  <IoClose />
                </button>
              </li>
            </ul>
          </div>
          <div className="rPageHeading">
            <div className="offNam margin-t-5 margin-b-5">
              {this.props.translator["Propose Critical"]}
            </div>
          </div>
          <div className="rBoxGap">
            <font color="red">{error && <strong>{error}</strong>}</font>
            <Form.Group className="form-group">
              <Form.Label>
                <span className="rStar"></span>
                {this.props.translator["Justification"]}
              </Form.Label>
              <Field
                name="justification"
                component="input"
                type="text"
                className="form-control"
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              ></Field>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>
                <span className="rStar"></span>
                {this.props.translator["CIM Group"]}
              </Form.Label>
              {/* <Field
                name="cimGroup"
                component="select"
                type="select"
                className="form-control"
                onChange={(event) => {
                  this.onCIMGroupChange(event);
                }}
              >
                <option value="">Select..</option>
                {this.renderSupportPGroup(this.props.breakFixSGAENameCIM)}
              </Field> */}
              <RulesDropdownList
                name="cimGroup"
                component="select"
                className="form-control"
                onChange={(event) => {
                  this.onCIMGroupChange(event);
                }}
                rulesAttribute={this.state.rulesAttribute}
                companyId={this.props.breakFixEditDetails.consumerCompany}
                serviceCompanyId={this.props.breakFixEditDetails.serviceCompanyId}
                moduleName="incident"
                ruleType="assignment"
                ruleFor="criticalIncidentManager"
              />
            </Form.Group>
            {criticalityAction}
            <font color="red">{savemsg}</font>
          </div>
        </div>
      );
    }
  }
}

ProposeCriticalTab = reduxForm({
  form: "proposeCriticalTab",
  fields: ["justification", "cimGroup", "cimUserDeails"],
  initialValues: {},
})(ProposeCriticalTab);

let selector = formValueSelector("proposeCriticalTab");
ProposeCriticalTab = connect((state) => {
  let justification = selector(state, "justification");
  let cimGroup = selector(state, "cimGroup");
  let cimUserDeails = selector(state, "cimUserDeails");

  return {
    justification,
    cimGroup,
    cimUserDeails,
  };
})(ProposeCriticalTab);

export function mapStateToProps({
  cimGroupDetails,
  cimUserDetailsForUser,
  breakFixEditDetails,
  breakFixSGAENameCIM,
}) {
  return {
    cimGroupDetails,
    cimUserDetailsForUser,
    breakFixEditDetails,
    breakFixSGAENameCIM,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadBreakFixSupportCIMGroup,
      loadBreakFixCIMForUserDetails,
      loadBreakFixEditDetails,
      loadNewBreakFixSGAEWithQueryParams,
      loadStatusBasedHamburgerOptions,
      loadTimelineData,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposeCriticalTab);
