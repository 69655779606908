
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import ListLoader from '_Commons/loaders/ListLoader';
import {ImSpinner6} from "react-icons/im";

class Convergence_Divergence extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            discover:'',
            errorDiscover:'',
            define:'',
            errorDefine:'',
            deduce:'',
            errorDeduce:'',
            determine:'',
            errorDetermine:'',
            sendPost: true,
            maxLength:500,
            disableButton: false,
            enableLoader: false,
            createdID: '0',
            showDeleteButton: false,
            disableDeleteButton: false,
            initialActivityDescription: ''
        }
        this.onChange = this.onChange.bind(this);
        this.saveData = this.saveData.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.submitData = this.submitData.bind(this);
        this.deleteData = this.deleteData.bind(this);
        this.submitActivityDetails = this.submitActivityDetails.bind(this);
    }
    submitActivityDetails(descriptionValue,moduleName){
        let activityDetails = {
            "itemId":this.props.investigationItemDetails.investigationId,
            "description":'RCA~Convergence-Divergence~'+descriptionValue,
            "isInternal":"",
            "createdOn":"",
            "createdBy":"",
            "module":moduleName,
            "createdByName":""
           };
        axios.post("/serviceManagement/rest/activity/", activityDetails);
    }
    deleteData(){
        this.setState({disableDeleteButton: true});
        axios.delete("/serviceManagement/rest/rcaDelete/"+this.props.investigationItemDetails.investigationId+"/"+this.props.techniqueID).then((deleteResponse)=>{
            this.props.hideForm();
            let descriptionforDelete='Convergence-Divergence is deleted';
            this.submitActivityDetails(descriptionforDelete,'Investigation');
        }).catch((error)=>{
            this.setState({disableDeleteButton: false});
            console.log("delete api : ",error);
        });
    }
    componentWillMount(){
        this.setState({enableLoader: true});
        axios.get("/serviceManagment/rest/rcaTechnique/"+this.props.investigationItemDetails.investigationId+"/"+this.props.techniqueID).then((responseFindings)=>{
             if(responseFindings.data.data!==null){
                let initialDiscover='Discover : '+responseFindings.data.data.keyValueData.Discover+', '+
                              'Define : '+responseFindings.data.data.keyValueData.Define+', '+
                              'Deduce : '+responseFindings.data.data.keyValueData.Deduce+', '+
                              'Determine : '+responseFindings.data.data.keyValueData.Determine;
                this.setState({discover: responseFindings.data.data.keyValueData['Discover'],
                               define: responseFindings.data.data.keyValueData['Define'],
                               determine: responseFindings.data.data.keyValueData['Determine'],
                               deduce: responseFindings.data.data.keyValueData['Deduce'],
                               initialActivityDescription: initialDiscover});
                this.setState({createdID: responseFindings.data.data.id, sendPost: false, showDeleteButton: true});
             }else{
                this.setState({discover:'',define:'',determine:'',deduce:''});
             }
             this.setState({enableLoader: false});
         }).catch((errorFindings)=>{
            console.log("response finding error : ",errorFindings);
            this.setState({enableLoader: false, discover:'',define:'',determine:'',deduce:''});
         });
    }
    submitData(toSendpayload){
        let descritpionTosend='Discover : '+toSendpayload.keyValueData.Discover+', '+
                              'Define : '+toSendpayload.keyValueData.Define+', '+
                              'Deduce : '+toSendpayload.keyValueData.Deduce+', '+
                              'Determine : '+toSendpayload.keyValueData.Determine;
        if(this.state.sendPost){
            axios.post('/serviceManagement/rest/rcaTechnique',toSendpayload).then((postResponse) => {
               this.props.hideForm();
               if(this.state.initialActivityDescription!==descritpionTosend){
                this.submitActivityDetails(descritpionTosend,'Investigation');
               }
            }).catch((error) => {
               console.log("post api : ",error);
               this.setState({disableButton: false});
            });
          }else{
            axios.put('/serviceManagement/rest/rcaTechnique/'+this.state.createdID,toSendpayload).then((patchResponse) => {
               this.props.hideForm();
               if(this.state.initialActivityDescription!==descritpionTosend){
                this.submitActivityDetails(descritpionTosend,'Investigation');
               }
            }).catch((error) => {
               console.log("put api : ",error);
               this.setState({disableButton: false});
            });
          }
    }
    validateFields(event){
        switch(event.target.name){
            case 'discoverField' : event.target.value.trim().length === 0 ? this.setState({errorDiscover: 'error'}) : this.setState({errorDiscover: ''})
                                   break;
            case 'defineField' :  event.target.value.trim().length === 0 ? this.setState({errorDefine: 'error'}) : this.setState({errorDefine: ''})
                                  break;
            case 'deduceField' : event.target.value.trim().length === 0 ? this.setState({errorDeduce: 'error'}) : this.setState({errorDeduce: ''})
                                 break;
            case 'determineField' : event.target.value.trim().length === 0 ? this.setState({errorDetermine: 'error'}) : this.setState({errorDetermine: ''})
                                    break;
        }
    }
    onChange(event){
        if(event.target.value.includes('~')){
           return;
        }
        switch(event.target.name){
          case 'discoverField' :  this.setState({errorDiscover: '',discover: event.target.value});
                                  break;
          case 'defineField' :   this.setState({errorDefine: '',define: event.target.value});
                                  break;
          case 'deduceField' :  this.setState({errorDeduce: '',deduce: event.target.value});
                                  break;
          case 'determineField' : this.setState({errorDetermine: '',determine: event.target.value});
                                  break;
        }
    }
    saveData(){
       let count=0;
       if(this.state.discover.trim().length===0){
          this.setState({errorDiscover:'error'});
          count++;
       }
       if(this.state.define.trim().length===0){
          this.setState({errorDefine:'error'});
          count++;
       }
       if(this.state.deduce.trim().length===0){
        this.setState({errorDeduce:'error'});
        count++;
       }
       if(this.state.determine.trim().length===0){
         this.setState({errorDetermine:'error'});
         count++;
       }
       if(count>0){
         return;
       }else{
       this.setState({disableButton: true});
     let generatedKeyValuepair={
        "Discover":this.state.discover,
        "Define":this.state.define,
        "Deduce":this.state.deduce,
        "Determine":this.state.determine,
     };
       let payload = {
         'investigationId': this.props.investigationItemDetails.investigationId,
         'displayId': this.props.techniqueID,
         'displayKey': 'Convergence-Divergence',
         'keyValueData':generatedKeyValuepair,
         'createdOn': '',
         'createdBy': ''
       };
       this.submitData(payload);
      }
    }
    render(){
        if(this.state.enableLoader){
            return <ListLoader />;
         }
        return (<div>
            <Form.Group validationState={this.state.errorDiscover}>
                <Form.Label bsClass=""><span className="rStar"></span>{this.props.translator["Discover"]}</Form.Label>
                <Form.Control as="textarea" name="discoverField" className={`form-control ${this.state.errorDiscover}`} maxLength={this.state.maxLength} rows="2" disabled={this.props.makeDisable||this.props.disableFieldsForGuestRole||this.props.isServiceDeskRolesEnabled}  onChange={(e)=> this.onChange(e)} onBlur={(e)=>{this.validateFields(e)}} value={this.state.discover} placeholder={this.props.translator["What are all the possible causes of this problem ?"]}/>
                <p className="charLeft" style={{textAlign:"right","fontSize":"11px"}}>({this.props.translator['Characters Remaining']}: {this.state.maxLength-this.state.discover.length}/500)</p>
            </Form.Group>
            <Form.Group validationState={this.state.errorDefine}>
                <Form.Label bsClass=""><span className="rStar"></span>{this.props.translator["Define"]}</Form.Label>
                <Form.Control as="textarea" name="defineField" className={`form-control ${this.state.errorDefine}`} maxLength={this.state.maxLength} rows="2" disabled={this.props.makeDisable||this.props.disableFieldsForGuestRole||this.props.isServiceDeskRolesEnabled}  onChange={(e)=> this.onChange(e)} onBlur={(e)=>{this.validateFields(e)}} value={this.state.define} placeholder={this.props.translator["What one cause should we try to solve ?"]}/>
                <p className="charLeft" style={{textAlign:"right","fontSize":"11px"}}>({this.props.translator['Characters Remaining']}: {this.state.maxLength-this.state.define.length}/500)</p>
            </Form.Group>
            <Form.Group validationState={this.state.errorDeduce}>
                <Form.Label bsClass=""><span className="rStar"></span>{this.props.translator["Deduce"]}</Form.Label>
                <Form.Control as="textarea" name="deduceField" className={`form-control ${this.state.errorDeduce}`}  maxLength={this.state.maxLength} rows="2" disabled={this.props.makeDisable||this.props.disableFieldsForGuestRole||this.props.isServiceDeskRolesEnabled}  onChange={(e)=> this.onChange(e)} onBlur={(e)=>{this.validateFields(e)}} value={this.state.deduce} placeholder={this.props.translator["What are all the possible solutions of this problem ?"]}/>
                <p className="charLeft" style={{textAlign:"right","fontSize":"11px"}}>({this.props.translator['Characters Remaining']}: {this.state.maxLength-this.state.deduce.length}/500)</p>
            </Form.Group>
            <Form.Group validationState={this.state.errorDetermine}>
                <Form.Label bsClass=""><span className="rStar"></span>{this.props.translator["Determine"]}</Form.Label>
                <Form.Control as="textarea" name="determineField" className={`form-control ${this.state.errorDetermine}`} maxLength={this.state.maxLength} rows="2" disabled={this.props.makeDisable||this.props.disableFieldsForGuestRole||this.props.isServiceDeskRolesEnabled}  onChange={(e)=> this.onChange(e)} onBlur={(e)=>{this.validateFields(e)}} value={this.state.determine} placeholder={this.props.translator["What is the best solution to this problem ?"]}/>
                <p className="charLeft" style={{textAlign:"right","fontSize":"11px"}}>({this.props.translator['Characters Remaining']}: {this.state.maxLength-this.state.determine.length}/500)</p>
            </Form.Group>
            {
             this.props.makeDisable ? '' : <ButtonToolbar>
                <Button className="rgSidrkBtn smallBtn" bsSize="small" bsStyle="primary" title={this.props.translator['Save']} onClick={this.saveData} disabled={this.state.disableDeleteButton||this.state.disableButton||this.props.disableFieldsForGuestRole||this.props.isServiceDeskRolesEnabled}>{this.state.disableButton?<ImSpinner6 className="icn-spinner"/> : null}{this.props.translator["Save"]}</Button>
                <Button className="rgSidrkBtn smallBtn" bsSize="small" bsStyle="primary" title={this.props.translator['Close']} onClick={this.props.hideForm} disabled={this.state.disableDeleteButton||this.state.disableButton}>{this.props.translator['Close']}</Button>
                {this.state.showDeleteButton?<Button className="rgSidrkBtn smallBtn" bsSize="small" bsStyle="primary" title={this.props.translator["Delete"]} onClick={this.deleteData} disabled={this.state.disableDeleteButton||this.state.disableButton||this.props.disableFieldsForGuestRole||this.props.isServiceDeskRolesEnabled}>{this.state.disableDeleteButton?<ImSpinner6 className="icn-spinner"/> : null}{this.props.translator["Delete"]}</Button>:null}
             </ButtonToolbar>
            }
        </div>);
    }
}
const mapStateToProps = ({ investigationItemDetails }) => {
	return {
        investigationItemDetails,
	};
};

export default connect(mapStateToProps, { })(Convergence_Divergence);

