
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export default function componentPackageReducer(state = initialState.componentPackage,action){
  switch (action.type) {
    case 'COMPONENT_PACKAGE':
      return action.components.data;
    case 'RESET_COMPONENT_PACKAGE_STATE':
      return action.componentPackage;
    default:
      return state;
    }
}
