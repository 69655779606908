
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Pagination from "react-js-pagination";
import Cookies from "universal-cookie";
import { openLinkInNewTab } from "../../../common/helper";
const cookies = new Cookies();
let discourseUrl = cookies.get("gph");
if (discourseUrl) discourseUrl = discourseUrl.replace("s:", "");
if (discourseUrl)
  discourseUrl = discourseUrl.substring(0, discourseUrl.lastIndexOf("."));
discourseUrl = discourseUrl.split("~");
discourseUrl = discourseUrl[50];

const KnowledgeArticles = (props) => {
  const { attachedArticlesList = [], tr = {} } = props;
//   const [pageSize, setPageSize] = useState(5);
//   const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    props.getKnowledgeArticles();
  }, []);

  const renderArticleHTML = () => {
    try {
    //   const allArticlesList = getItemsBasedOnPagination(attachedArticlesList);
    const allArticlesList = structuredClone(attachedArticlesList);
      if (allArticlesList.length == 0) {
        return (
          <tr>
            <td colSpan={3} className="article-table-td-align-center">
              {tr["No Attached Knowledge References Available"]}
            </td>
          </tr>
        );
      }

      return allArticlesList.map((item, index) => {
        // let iconClass = (item.type === 'SPCM') ? 'KA-icon interLnk' : (
        //     (item.type === 'Knowledge' || item.type === 'conversation') ? 'KA-icon exterLnk' : 'KA-icon community'
        // );
        return (
          <tr key={`attachedArticles_${index}`}>
            <td className="text-c">
                {item?.knowledgeId || ''}
            </td>
            <td className="kn-title">
              <span>
                <span className={"KA-icon community"} title={"Knowledge"}></span>
                {renderLargeTextEllipse(item?.knowledgeArticle || '')}
              </span>
            </td>
            <td className="text-c">
              <a
                onClick={() => {
                  redirectFile(item["articleUrl"]);
                }}
                href="javascript:void(0)"
              >
                <i
                  style={{ position: "relative", top: "2px" }}
                  className={"fa fa-external-link f-size-15 colorStBlue"}
                  title={tr["Redirect"]}
                ></i>
              </a>
            </td>
          </tr>
        );
      });
    } catch (e) {
      console.error(e);
      return (
        <tr>
          <td colSpan={2} className="article-table-td-align-center">
            {tr["No Attached Knowledge References Available"]}
          </td>
        </tr>
      );
    }
  };

//   const getItemsBasedOnPagination = (totalItems) => {
//     try {
//       const startIndex =
//         Number(currentPage) * Number(pageSize) - Number(pageSize);
//       return totalItems.slice(startIndex, startIndex + Number(pageSize));
//     } catch (e) {
//       return totalItems || [];
//     }
//   };

  const renderLargeTextEllipse = (text, limit = 25) => {
    try {
      return (
        <span title={text}>
          {text.slice(0, limit)}
          {text.length > limit ? "..." : ""}
        </span>
      );
    } catch (e) {
      return null;
    }
  };

  const redirectFile = (slug) => {
    try {
      const ssoUrl = `${discourseUrl}/t/${slug}`;
      openLinkInNewTab(ssoUrl, "New Tab")
    } catch (e) {
      console.error(e);
    }
  };

//   const onPageSizeChange = (e) => {
//     setPageSize(e.target.value);
//     setCurrentPage(1);
//   };

//   const onPageChange = (value) => {
//     setCurrentPage(value);
//   };

  return (
    <div>
      <Row>
        <Col md={12}>
          <div className="rPageHeading">
            <div className="offNam margin-t-5 margin-b-5">
              {tr["Relate Knowledge Articles"]}
            </div>
          </div>
        </Col>
      </Row>
      <div className="rBoxGap">
        <Row>
          <Col md={12}>
            <div
              className="margin-t-20 knowledge-search-section attached-articles-section"
            //   style={{ "padding-left": "10px", "padding-right": "10px" }}
            >
              <Row>
                <Col md={12}>
                  <h2 style={{ marginBottom: "0px" }}>
                    {tr["Attached Articles"]}
                  </h2>
                </Col>
                <Col md={12}>
                  <div>
                    <Table
                      className="table table-striped table-bordered table-condensed table-hover"
                      style={{ "margin-top": "10px", "margin-bottom": "0px" }}
                    >
                      <thead>
                        <tr>
                          <th width="21%" className="">
                            <span>{tr["Topic ID"]}</span>
                          </th>
                          <th width="58%" className="">
                            {tr["Knowledge Articles"]}
                          </th>
                          {/* <th className="text-c">Type</th> */}
                          <th width="21%" className="text-c">
                            {tr["Actions"]}
                          </th>
                        </tr>
                      </thead>
                      <tbody>{renderArticleHTML()}</tbody>
                    </Table>
                    {/* {attachedArticlesList &&
                      attachedArticlesList.length > 0 && (
                        <Row className="margin-t-20">
                          <Col lg={5} md={4} sm={4} xs={12} className="">
                            <div className="">
                              <Form.Label className="margin-r-5 display-inline-block widthAuto">
                                {tr["Show"]}
                              </Form.Label>
                              <Form.Select
                                componentClass="select"
                                aria-label="select"
                                className="display-inline-block widthAuto"
                                onChange={(e) => onPageSizeChange(e)}
                                value={pageSize}
                              >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={9999}>All</option>
                              </Form.Select>
                            </div>
                          </Col>
                          <Col lg={7} md={8} sm={8} xs={12}>
                            <div className="text-r">
                              <Pagination
                                activePage={currentPage}
                                itemsCountPerPage={pageSize}
                                totalItemsCount={attachedArticlesList.length}
                                onChange={onPageChange}
                              />
                            </div>
                          </Col>
                        </Row>
                      )} */}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default KnowledgeArticles;
