
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { forwardRef } from "react";
import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";
import "./styles.scss";
import { useField, ErrorMessage } from "formik";

export const Editor = forwardRef(
  (
    {
      name,
      label,
      value,
      onChange,
      placeholder,
      onKeyDown,
      textlength,
      required,
      disabled,
      ...props
    },
    ref
  ) => {
    const [field, meta] = useField(name);
    const showError = meta.touched && !!meta.error;
    const characterLimit = props?.characterLimit || 501;

    const handlePaste = (event) => {
      event.preventDefault();
    };
    const formats = [
      "header",
      "font",
      "size",
      "list",
      "bullet",
      "indent",
      "align",
      "bold",
      "italic",
      "underline",
      "script",
      "color",
      "background",
    ];

    return (
      <div className="rte form-group">
        <label>
          {label}
          {required && <span className="labelAsterisk">*</span>}
          <span className="wordCount">
            ({characterLimit - textlength} Characters left)
          </span>
        </label>
        <div className="text-editor">
          <ReactQuill
            ref={ref}
            theme="snow"
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            className={showError ? "in-valid" : ""}
            modules={{
              toolbar: [
                [{ font: [] }],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ script: "sub" }, { script: "super" }],
                [{ indent: "-1" }, { indent: "+1" }],
                [{ color: [] }, { background: [] }],
                [{ align: [] }],
              ],
            }}
            formats={formats}
            readOnly={disabled}
          />
          {/* {showError && (
            <ErrorMessage
              {...field}
              component="div"
              className="invalid-feedback text-right d-block"
            />
          )} */}
          {/* <ErrorMessage
            name={name}
            component="div"
            className="invalid-feedback text-right d-block"
          /> */}
        </div>
      </div>
    );
  }
);

export default Editor;
