
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState, useEffect, createRef, useRef } from "react";
import { isEmpty } from "lodash";
import moment from "moment";
import LoadingIcons from "react-loading-icons";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Field, Formik, Form } from "formik";
import { Row, Col, Button, Container } from "react-bootstrap";
import { ImInfo } from "react-icons/im";
import {
  InputTextField,
  SelectField,
  DatePickerF,
  InputTextFieldForChips,
} from "../../../common/FormFields";
import {
  getAllTrack,
  useGetUserInfoQuery,
  useGetMeetingDefByIdQuery,
  useGetLocationDetailsQuery,
  useGetCiSupportAllGroupQuery,
  useCommitteDetailsByFrmNameQuery,
  useGetOccurenceByIdQuery,
  useGetAllPersonsQuery,
  useUpdateOccurrenceMutation,
  useCancelMeetingOccurrenceMutation,
} from "../../../../../reducers/governance/meeting/meeting";

import {
  findDuration,
  convertDate,
  filterUser,
  filterPersons,
  filterGroupUser,
  extractGroupList,
  getWeekDayAccordingDate,
  getCookiesValuesByPositions,
  createPayloadForChips,
  returnServerPayload,
  checkDateWithIn24Hr,
} from "../../../Helper";
import { prefDateFormatAccordingUser } from "../../../common/CommonMethod";

import MeetingDropdownPlusTypeahead from "../../../common/MeetingDropdownPlusTypeahead";
import Editor from "../../../../common/Editor/Editor";
import CustomTypeHead from "../../../common/CustomTypeHead";

// import PindCategories from "../../../../common/header/pind-categories";
import Breadcrumbs from "../../../../common/header/breadcrumbs";

import Header from "./header";
import SystemInfo from "../../../common/SystemInfo";
import OccurrencesRightSide from "../rightSide";

import ListLoader from "../../../../common/loaders/ListLoader";

const OccurrenceDetails = () => {
  const params = useParams();
  const formikRef = useRef(null);
  let descRef = createRef();
  let agendaRef = createRef();

  const occurenceId = params?.occurenceId;
  const cookieValues = getCookiesValuesByPositions([2, 3]);
  const dateformat = cookieValues?.value1;
  const timezone = cookieValues?.value2;
  const characterLimit = 501;

  const [isAutorize, setIsAutorize] = useState(false);
  const [descLength, setDescLength] = useState(0);
  const [agendaLength, setAgendaLength] = useState(0);
  const [meetingDefinationId, setMeetingDefinationId] = useState(null);
  const [actionStatus, setActionStatus] = useState(null);
  const { data: userInfo } = useGetUserInfoQuery();

  const [initVals, setInitVals] = useState({
    title: "",
    objectives: "",
    agenda: "",
    recurrenceStartDateTime: "",
    recurrenceEndDateTime: "",
    durationInMiutes: "",
    persons: [],
    groups: [],
    coordinator: [],
    timeZone: "",
    meetingLocationDetails: "",
    externalEmails: [],
    cancelReason: "",
  });

  const [activeNav, setActiveNav] = useState(true);
  const [activeTabs, setActiveTabs] = useState("1");

  const validationSchema = Yup.object().shape({
    coordinator: Yup.array(),
    title: Yup.string().required("Required"),
    objectives: Yup.string().required("Required"),
    agenda: Yup.string().required("Required"),
    meetingLocationDetails: Yup.string().required("Required"),
    persons: Yup.array().min(1, "Required").required("Required"),
    recurrenceStartDateTime: Yup.date().required().test('validate start date','start date should be at-least today\'s date',(value)=>{
      let dateObj = new Date(value);
      let momentObj = moment(dateObj).startOf('minute');
      if(momentObj.isBefore(moment())){
          return false;
      }
      return true;
  }),
  externalEmails: Yup.array().of(
    Yup.object().shape({
      label: Yup.string()
      .test(
        'is-valid-email',
        'Invalid email address',
        (value) => {
          if (!value) return true;
          const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return regex.test(value);
        }
      )
    })
  ),
    recurrenceEndDateTime: Yup.date()
      .required("Required")
      .when("recurrenceStartDateTime", (recurrenceStartDateTime, schema) => {
        return schema.test({
          name: "is-after-start-date",
          exclusive: false,
          message: "End date has to be after the start date",
          test: function (recurrenceEndDateTime) {
            if (recurrenceStartDateTime && recurrenceEndDateTime) {
              return (
                moment(recurrenceEndDateTime) >
                moment(recurrenceStartDateTime[0])
              );
            }
            return true;
          },
        });
      })
      .nullable(),
  });

  const isValidStartTime = (current) => {
    const yesterday = moment().subtract(1, "day");
    return current.isAfter(yesterday);
  };
  const isValidEndTime = (current, startDate) => {
    const start = moment.isMoment(startDate) ? startDate : moment(startDate);
    if (current.isSame(start, "day")) {
      return true;
    }
    return current.isAfter(start);
  };
  const toggle = (tab) => {
    setActiveNav(!tab);
    if (activeTabs !== tab) setActiveTabs(tab);
  };

  const [updateOccurrence, { isLoading: isUpdatingOccurence }] =
    useUpdateOccurrenceMutation();
  const [cancelMeetingOccurrence, { isLoading: isCancellingOccurence }] =
    useCancelMeetingOccurrenceMutation();

  const {
    data: occurrenceDetaildata,
    isFetching: occurrencesDetailFetching,
    refetch: refetchOccurrenc,
  } = useGetOccurenceByIdQuery(
    { Id: occurenceId },
    {
      skip: !occurenceId,
    }
  );

  const {
    data: meetingDefData,
    isFetching: meetingDefDataFetching,
    refetch: refetchMeetingById,
  } = useGetMeetingDefByIdQuery(
    { meetingId: meetingDefinationId },
    {
      skip: !meetingDefinationId,
    }
  );

  const {
    data: allLocationData,
    error: locError,
    isLoading: locLoading,
  } = useGetLocationDetailsQuery(
    { custId: meetingDefData?.companyId },
    {
      skip: !meetingDefData?.companyId,
    }
  );
  const {
    data: verndorUsers,
    isLoading: verndorUsersLoading,
    isFetching: verndorUsersFetching,
    refetch: refetchVendorsUsers,
  } = useGetAllPersonsQuery(
    { companyId: meetingDefData?.companyId, like: undefined },
    {
      skip: !meetingDefData?.companyId,
    }
  );

  const {
    data: allGroupData,
    error: groupError,
    isFetching: isGroupFetching,
  } = useGetCiSupportAllGroupQuery(
    { custId: meetingDefData?.companyId },
    {
      skip: !meetingDefData?.companyId,
      selectFromResult: ({ data }) => {
        if (data) {
          const transformedData = data.map((group) => ({
            label: group.groupName,
            value: group.groupId,
          }));

          return { data: transformedData };
        }

        return { data: [] };
      },
    }
  );

  const onSubmit = async (payload, { setSubmitting, setFieldError }) => {
    if (payload?.durationInMiutes == "0m") {
      return setFieldError(
        "recurrenceStartDateTime",
        "End date has to be after than start date"
      );
    }
    if (
      !checkDateWithIn24Hr(
        payload?.recurrenceStartDateTime,
        payload?.recurrenceEndDateTime
      )
    ) {
      return setFieldError(
        "recurrenceEndDateTime",
        "Meeting duration shouldn’t be more than 24 hrs"
      );
    }

    const postData = returnServerPayload(
      payload,
      userInfo?.dateformat,
      userInfo,
      "details"
    );

    try {
      const res = await updateOccurrence({
        data: {
          ...postData,
          meetingDefinationId: meetingDefinationId,
          meetingOccurrananceId: occurenceId,
        },
        companyId: meetingDefData?.companyId,
      });
      if (res?.data) {
        setSubmitting(false);
        refetchOccurrenc({Id: res?.data?.occurenceId});
      }
    } catch (err) {
      setSubmitting(false);
    } finally {
      setSubmitting(false);
    }
  };

  const checkCharacterCountCommon = (editorRef, setLength, event) => {
    const unprivilegedEditor = editorRef.current.unprivilegedEditor;

    if (unprivilegedEditor.getText().trim().length === 0) {
      setLength(1);
    } else {
      setLength(unprivilegedEditor.getLength());
    }

    if (
      unprivilegedEditor.getLength() >= characterLimit &&
      event.key !== "Backspace"
    ) {
      event.preventDefault();
    }
  };

  const checkCharacterCount = (event) => {
    checkCharacterCountCommon(descRef, setDescLength, event);
  };

  const checkCharacterCount1 = (event) => {
    checkCharacterCountCommon(agendaRef, setAgendaLength, event);
  };

  const onSubmitReason = async (fields, setSubmitting) => {
    if (actionStatus === "Cancel Occurrence") {
      try {
        const cancelPostdata = {
          cancelReason: "Cancel occurrence reason-" + fields?.description,
          meetingDefinationId: Number(meetingDefData?.meetingDefinationId),
          meetingOccurrananceId: Number(occurenceId),
          actionType: "Cancel Occurrence",
        };

        const resp = await cancelMeetingOccurrence({
          data: cancelPostdata,
          queryString: `?tenantId=${meetingDefData?.companyId}`,
        });

        setSubmitting(false);
        refetchOccurrenc({ Id: occurenceId });
        setActiveTabs("1");
      } catch (err) {
        setSubmitting(false);
      } finally {
        setSubmitting(false);
      }
    }
  };

  const handleOnSelectAction = async (actionStatus) => {
    setActionStatus(actionStatus);
    if (actionStatus === "Download iCAL") {
      if (initVals?.signedAttachedFileUrl !== "") {
        const link = document.createElement("a");
        link.href = initVals.signedAttachedFileUrl;
        link.target = "_blank";
        link.click();
      }
      return;
    }

    if (actionStatus === "Cancel Occurrence") {
      toggle("6");
    }
    if (actionStatus === "Send Minutes") {
      const postData = {
        definationId: Number(meetingDefData?.meetingId),
        occurrenceId: Number(occurenceId),
        // actionItems: actDdata,
      };

      // const res = await mutationSendMinutes.mutateAsync({
      //   payload: postData,
      //   impCompID: meetingDefData?.companyId,
      // });

      // if (res.status === 200) {
      //   refetchData();
      //   setVisible(false);
      // }
      return;
    }
  };

  useEffect(() => {
    setInitVals((prev) => {
      return {
        ...prev,
        ...occurrenceDetaildata,
        durationInMiutes: findDuration(
          convertDate(
            occurrenceDetaildata?.recurrenceStartDateTime,
            prefDateFormatAccordingUser(userInfo?.dateformat, true)
          ),
          convertDate(
            occurrenceDetaildata?.recurrenceEndDateTime,
            prefDateFormatAccordingUser(userInfo?.dateformat, true)
          )
        ),
        recurrenceStartDateTime: moment(
          occurrenceDetaildata?.recurrenceStartDateTime,
          prefDateFormatAccordingUser(userInfo?.dateformat, true)
        ),
        recurrenceEndDateTime: moment(
          occurrenceDetaildata?.recurrenceEndDateTime,
          prefDateFormatAccordingUser(userInfo?.dateformat, true)
        ),

        persons: createPayloadForChips(
          occurrenceDetaildata?.usersId,
          occurrenceDetaildata?.usersName,
          occurrenceDetaildata?.usersEmail,
          occurrenceDetaildata?.usersCompany
        ),
        coordinator: createPayloadForChips(
          occurrenceDetaildata?.secondaryChairPersons,
          occurrenceDetaildata?.secondaryChairPersonsName,
          occurrenceDetaildata?.secondaryChairPersonsEmail,
          occurrenceDetaildata?.secondaryChairPersonsCompany
        ),

        groups: occurrenceDetaildata?.statusId==2 || occurrenceDetaildata?.statusId==3 ? extractGroupList(occurrenceDetaildata.groupsId,occurrenceDetaildata.groupsName) : filterGroupUser(
          allGroupData || [],
          occurrenceDetaildata?.groupsId.toString()
        ),
        externalEmails:
          (occurrenceDetaildata?.externalEmailsId &&
            occurrenceDetaildata.externalEmailsId?.split(",")) ||
          [],
      };
    });
    setMeetingDefinationId(occurrenceDetaildata?.meetingDefinationId);

    const isDisabled =
      occurrenceDetaildata?.mop === "V" ||
      (occurrenceDetaildata?.mop === "E" &&
        !occurrenceDetaildata?.userAuthorized);

    setIsAutorize(isDisabled);
  }, [occurrenceDetaildata, allGroupData.length > 0]);

  useEffect(() => {
    if (formikRef.current && formikRef.current.values) {
      const currentValues = formikRef.current.values;
    }
  }, [formikRef.current]);

  return (
    <main>
      {/* <PindCategories /> */}
      <Container fluid className="margin-b-15 align-items-center">
        <Breadcrumbs
          activePageName={"Create"}
          parentPageurl="/quickViewBreakFix"
          parentPageName="Appointment"
        />
      </Container>
      <Container fluid>
        {isUpdatingOccurence ||
        isCancellingOccurence ||
        occurrencesDetailFetching ? (
          <Row>
            <Col md={12}>
              <div className="rctLoader">
                <LoadingIcons.Bars
                  height="5em"
                  stroke="transparent"
                  fill="#06bcee"
                />
              </div>
            </Col>
          </Row>
        ) : (
          <div id="GovernanceOccurence">
            <Row>
              <Col md={12} sm={12} xs={12}>
                <Header
                  isSubmitting={formikRef?.current?.isSubmitting}
                  dirty={formikRef?.current?.dirty}
                  submitForm={formikRef?.current?.submitForm}
                  formikref={formikRef}
                  handleOnSelectAction={handleOnSelectAction}
                  meetingDefData={meetingDefData}
                  occurrenceDetaildata={occurrenceDetaildata}
                  occurenceId={occurenceId}
                  toggle={toggle}
                  activeTabs={activeTabs}
                  isAutorize={isAutorize}
                />
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                <Formik
                  innerRef={(ref) => {
                    if (ref) {
                      formikRef.current = ref;
                    }
                  }}
                  initialValues={initVals}
                  validationSchema={validationSchema}
                  enableReinitialize={true}
                  onSubmit={onSubmit}
                >
                  {({
                    errors,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    handleBlur,
                    values,
                    dirty,
                    handleChange,
                  }) => {
                    return (
                      <Form noValidate>
                        <Row>
                          <Col md={12}>
                            <Row>
                              <Col md={6} sm={6} xs={12}>
                                <InputTextField
                                  disabled={isAutorize}
                                  name="title"
                                  label="Title"
                                  required
                                />
                              </Col>
                              <Col md={6} sm={6} xs={12}>
                                <InputTextField
                                  disabled
                                  name="primaryChairPersonName"
                                  label="Chairperson"
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12} sm={6} xs={12}>
                                <CustomTypeHead
                                  multiple
                                  clearButton
                                  id="coordinator"
                                  name="coordinator"
                                  label="Coordinator(s)"
                                  thfor="personApi"
                                  placeholder="Please type to search"
                                  companyId={meetingDefData?.companyId}
                                  autoComplete="off"
                                  labelkey={(option) =>
                                    option?.fullname
                                      ? `${option?.fullname} | ${option?.email}`
                                      : option
                                  }
                                  onChange={(selected) => {
                                    setFieldValue(
                                      "coordinator",
                                      selected,
                                      true
                                    );
                                  }}
                                  selected={values?.coordinator || []}
                                  disabled={isAutorize}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12} sm={12} xs={12}>
                                <Editor
                                  required
                                  ref={descRef}
                                  textlength={descLength}
                                  characterLimit={501}
                                  name="objectives"
                                  label={"Description"}
                                  value={values?.objectives}
                                  placeholder="Description here..."
                                  onKeyDown={checkCharacterCount}
                                  onChange={(value) => {
                                    if (value.trim() === "<p><br></p>") {
                                      setFieldValue("objectives", "", true);
                                    } else {
                                      setFieldValue("objectives", value, true);
                                    }
                                  }}
                                  disabled={isAutorize}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12} sm={12} xs={12}>
                                <Editor
                                  required
                                  ref={agendaRef}
                                  textlength={agendaLength}
                                  characterLimit={characterLimit}
                                  label="Agenda"
                                  name="agenda"
                                  value={values.agenda}
                                  placeholder="Agenda here..."
                                  onKeyDown={checkCharacterCount1}
                                  onChange={(value) => {
                                    if (value.trim() === "<p><br></p>") {
                                      setFieldValue("agenda", "", true);
                                    } else {
                                      setFieldValue("agenda", value, true);
                                    }
                                  }}
                                  disabled={isAutorize}
                                />
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col md={6} sm={6} xs={12}>
                                <InputTextField
                                  name="meetingLocation"
                                  disabled
                                />
                              </Col>
                              <Col md={6} sm={6} xs={12}>
                                <div className={"infoBtnDiv"}>
                                  <InputTextField
                                    name="meetingLocationDetails"
                                    label="Meeting Details"
                                    required
                                    disabled={isAutorize}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12} sm={12} xs={12}>
                                <div className="margin-t-5 padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
                                  <div className="bgGray padding-5">
                                    Members
                                  </div>
                                  <Row>
                                    <Col md={6}>
                                      <CustomTypeHead
                                        required
                                        multiple
                                        clearButton
                                        id="persons"
                                        name="persons"
                                        label="Person(s)"
                                        thfor="personApi"
                                        placeholder="Please type to search"
                                        companyId={meetingDefData?.companyId}
                                        autoComplete="off"
                                        labelkey={(option) => {
                                          return option?.fullname
                                            ? `${option?.fullname} | ${option?.email}`
                                            : option;
                                        }}
                                        onChange={(selected) => {
                                          setFieldValue(
                                            "persons",
                                            selected,
                                            true
                                          );
                                        }}
                                        selected={values?.persons || []}
                                        // selected={
                                        //   values?.usersName
                                        //     ? [
                                        //         `${values?.usersName} | ${values?.usersEmail} | ${values?.usersCompany}`,
                                        //       ]
                                        //     : []
                                        // }
                                        disabled={isAutorize}
                                      />
                                    </Col>
                                    <Col md={6}>
                                      <InputTextFieldForChips
                                        multiple
                                        name="groups"
                                        label="Groups"
                                        labelkey="groupName"
                                        options={allGroupData}
                                        placeholder="Type to search a group..."
                                        onChange={(selected) =>
                                          setFieldValue(
                                            "groups",
                                            selected,
                                            true
                                          )
                                        }
                                        selected={values?.groups || []}
                                        disabled={isAutorize}
                                      />
                                    </Col>
                                    <Col md={12}>
                                      <InputTextFieldForChips
                                        allowNew
                                        multiple
                                        className={
                                          errors.externalEmails && touched.externalEmails
                                            ? "is-invalid"
                                            : ""
                                        }
                                        name="externalEmails"
                                        label="External Email(s)"
                                        newSelectionPrefix="Use comma sepration: "
                                        options={[]}
                                        placeholder="Enter email..."
                                        onChange={(selected) => {
                                          setFieldValue(
                                            "externalEmails",
                                            selected,
                                            true
                                          );
                                        }}
                                        selected={values?.externalEmails?.[0]?.label
                                          ? values?.externalEmails
                                          : []}
                                        disabled={isAutorize}
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12} sm={12} xs={12}>
                                <div className="margin-t-5 padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
                                  <div className="bgGray padding-5">
                                    Schedule
                                  </div>
                                  <Row className="mt-3 align-items-center">
                                    <Col md={6}>
                                      <DatePickerF
                                        required
                                        label="Start Time"
                                        name="recurrenceStartDateTime"
                                        className="form-control dateTimePickerField"
                                        isValidDate={isValidStartTime}
                                        value={
                                          values?.recurrenceStartDateTime
                                            ? moment(
                                                values.recurrenceStartDateTime,
                                                userInfo?.dateformat
                                              )
                                            : ""
                                        }
                                        onChange={(value) => {
                                          setFieldValue(
                                            "recurrenceStartDateTime",
                                            value,
                                            true
                                          );
                                          setFieldValue(
                                            "recurrenceEndDateTime",
                                            value,
                                            true
                                          );
                                          setFieldValue("durationInMiutes", "");

                                          
                                        }}
                                        disabled={isAutorize}
                                      />
                                    </Col>
                                    <Col md={6}>
                                      <DatePickerF
                                        required
                                        label="End Time"
                                        className="form-control dateTimePickerField"
                                        isValidDate={isValidEndTime}
                                        value={
                                          values.recurrenceEndDateTime
                                            ? moment(
                                                values.recurrenceEndDateTime,
                                                userInfo?.dateformat
                                              )
                                            : ""
                                        }
                                        name="recurrenceEndDateTime"
                                        onChange={(value) => {
                                          const duration = findDuration(
                                            convertDate(
                                              values.recurrenceStartDateTime,
                                              prefDateFormatAccordingUser(
                                                userInfo?.dateformat,
                                                true
                                              )
                                            ),
                                            convertDate(
                                              value,
                                              prefDateFormatAccordingUser(
                                                userInfo?.dateformat,
                                                true
                                              )
                                            )
                                          );

                                          setFieldValue(
                                            "durationInMiutes",
                                            findDuration(
                                              convertDate(
                                                values.recurrenceStartDateTime,
                                                prefDateFormatAccordingUser(
                                                  userInfo?.dateformat,
                                                  true
                                                )
                                              ),
                                              convertDate(
                                                value,
                                                prefDateFormatAccordingUser(
                                                  userInfo?.dateformat,
                                                  true
                                                )
                                              )
                                            ),
                                            true
                                          );
                                          setFieldValue(
                                            "recurrenceEndDateTime",
                                            value,
                                            true
                                          );
                                        }}
                                        disabled={isAutorize}
                                      />
                                    </Col>
                                    <Col md={6} className="mt-3">
                                      <InputTextField
                                        disabled
                                        value={timezone}
                                        name="timezone"
                                        label="TimeZone"
                                      />
                                    </Col>
                                    <Col md={6} className="mt-3">
                                      <InputTextField
                                        disabled
                                        name="durationInMiutes"
                                        label="Duration"
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12}>
                                <SystemInfo
                                  header="System Information"
                                  headers={[
                                    "Created On",
                                    "Created By",
                                    "Modified On",
                                    "Modified By",
                                  ]}
                                  values={[
                                    values?.createdOn,
                                    values?.createdByFullname,
                                    values?.modifiedOn,
                                    values?.modifiedByFullname,
                                  ]}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    );
                  }}
                </Formik>
              </Col>
              <Col md={4}>
                {occurrencesDetailFetching && meetingDefDataFetching ? (
                  <ListLoader />
                ) : (
                  <OccurrencesRightSide
                    activeTabs={activeTabs}
                    occurenceData={occurrenceDetaildata || []}
                    companyId={meetingDefData?.companyId}
                    meetingDefData={meetingDefData}
                    onSubmitReason={onSubmitReason}
                    actionStatus={actionStatus}
                    isAuthorized={isAutorize}
                  />
                )}
              </Col>
            </Row>
          </div>
        )}
      </Container>
    </main>
  );
};

export default OccurrenceDetails;
