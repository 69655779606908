
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import {
  Row,
  Col,
  Form,
  Alert,
  ButtonToolbar,
  Button,
  Table,
} from "react-bootstrap";
import {
  _textArea,
  _dropDown,
  _inputField,
  _number,
  _dynamicNamedropDown,
} from "../../../common/formFields.js";
import {
  Field,
  reduxForm,
  SubmissionError,
  change,
  formValueSelector,
  untouch,
} from "redux-form";
import { connect } from "react-redux";
import axios from "axios";
import constants from "../../../../utils/constants.js";
import { getLovValues } from "../../../../actions/changeManagement/changeManagementAction.js";
import {
  spcmLoader,
  setLeftEditId,
  getApprovals,
  getApproverType,
  getApprovalQuestions,
  getApprovalDetails,
  getApproverScenario,
  getApproverEntity,
  getApproverLOB,
  getApproverOrderType,
  getApproverCompanyCode,
  getApproverCriteriaOperator,
  getApproverModule,
  getApproverGroup,
  getApproverIndividual,
  getApproverGroupOrUser,
  emptyApproverGroupOrUser,
  listDataWithType,
} from "../../../../actions/spcmActions.js";
import {
  loadCIClass,
  loadCIStatusCurrent,
  loadCIMetallic,
  loadCIEnv,
  loadCIManr,
  loadCIDataset,
  loadCICompany,
  loadCILocation,
  loadCIGroup,
  loadCIInd,
  loadCICat,
  loadCIType,
  loadCISCat,
  loadCISupCompany,
  loadCMDBSupportCompany,
  loadCmdbClassBasedList,
  getCmdbApproverLevel,
  loadCmdbCategories,
  getCmdbApprovals,
  getCmdbApproverCriteria,
  loadCIResidualRisk,
  loadApprovalsAutoAction
} from "../../../../actions/cmdb/lovAction.js";
import { support } from "jquery";
import { loadProfile } from "../../../../actions/myProfile/profileAction.js";
import IndividualAsyncTypeahead from "../../formfield/individualAsyncTypeahead.js";
import DropdownPlusTypeahead from "../../formfield/dropdownPlusTypeahead.js";
import {HiPlus} from 'react-icons/hi';
import {ImSpinner6} from "react-icons/im";

const validate = (values, props) => {
  let errors = {};
  if (!values.ruleName) {
    errors.ruleName = "Enter Rule Name";
  }
  if (!values.ciClass) {
    errors.ciClass = "Select Change Type";
  }
  if (!values.level) {
    errors.level = "Select  Change Timing";
  }
  if (!values.urgency) {
    errors.urgency = "select urgency.";
  }
  if (!values.level) {
    errors.level = "Select Level";
  }
  if (!values.approverType) {
    errors.approverType = "Select Type";
  }
  if (!values.signIn) {
    errors.signIn = "Select Sign In Type";
  }
  if (!values.weightage) {
    errors.weightage = "Set Weightage";
  }
  if (!values.rfcChangeType) {
    errors.rfcChangeType = "Select Change Type";
  }
  return errors;
};

class XsmCIApprovalForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      rfcModuleSelected: false,
      arrayCounts: 1,
      extraCounts: [0],
      criteriaToggle: false,
      criteriaExist: false,
      test: false,
      cmdbDropDownENV: [],
      cmdbDropDownCATEGORY: [],
      cmdbDropDownLOCATION: [],
      spcmDropDownORG: [],
      spcmDropDownTYPEUSER: [],
      cmdbDropDownRESIDUALRISK: [{ "field1Key": "High", "field1Value": "High" }, { "field1Key": "Medium", "field1Value": "Medium" }, { "field1Key": "Low", "field1Value": "Low" }],
      classIdSelected: "",
      classNameSelected: this.props.CLASS_NAME,
      assetNameSelected: this.props.CI_NAME,
      companyErrorColor: "",
      companySelectedList: [],
      inputValueCompany: "",
      groupErrorColor: "",
      groupSelectedList: [],
      inputValueGroup: "",
      individualErrorColor: "",
      companyIdParam: "",
    };
    this.onChange = this.onChange.bind(this);
    this.componentWillMount = this.componentWillMount.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.loadCount = this.loadCount.bind(this);
    this.addRow = this.addRow.bind(this);
    this.deleteRow = this.deleteRow.bind(this);
    this.addCriteriaToggle = this.addCriteriaToggle.bind(this);
    this.changeScenarioType = this.changeScenarioType.bind(this);
    this.onCompanySelection = this.onCompanySelection.bind(this);
    this.setCompanyErrorColor = this.setCompanyErrorColor.bind(this);
    this.onCompanyInputChange = this.onCompanyInputChange.bind(this);
    this.onCrossClickCompany = this.onCrossClickCompany.bind(this);
    this.onGroupSelection = this.onGroupSelection.bind(this);
    this.setGroupErrorColor = this.setGroupErrorColor.bind(this);
    this.onGroupInputChange = this.onGroupInputChange.bind(this);
    this.onCrossClickGroup = this.onCrossClickGroup.bind(this);
    this.setIndividualErrorColor = this.setIndividualErrorColor.bind(this);
    this.resetValue = this.resetValue.bind(this)
  }
  resetValue(extraCount) {
    const change = this.props.change;
     
      this.setState({ [`key${extraCount}`]: "" });
      change(`value${extraCount}`, "");
    
      this.setState({ [`key${extraCount}`]: "" });
      change(`operator${extraCount}`, "");

      this.setState({ [`key${extraCount}`]: "" });
      change(`key${extraCount}`, "");
  }
  componentWillMount() {
    if (this.props.editToggle) {
      if (this.props.initialValues.approverType == "Individual") {
        this.setState({ type: "Individual" });
      } else if (this.props.initialValues.approverType == "Group") {
        this.setState({ type: "Group" });
      } else {
        this.setState({ type: "" });
      }
    }
  }

  componentDidMount() {
    this.props.getApproverType();
    this.props.getCmdbApproverCriteria();
    this.props.getApproverCriteriaOperator();
    this.props.getApproverScenario();
    this.props.getApproverCompanyCode();
    this.props.getApproverLOB();
    this.props.getApproverOrderType();
    this.props.getApproverEntity();
    this.props.loadCIEnv();
    this.props.loadCmdbCategories();
    this.props.loadCICompany("0");
    this.props.loadCMDBSupportCompany();
    this.props.loadCILocation();
    this.props.loadCmdbClassBasedList(this.props.initialValues.ciClass, "5");
    this.props.getCmdbApproverLevel(this.props.COMPANY_ID);
    this.props.getLovValues("ChangeManagement", "Type");
    // this.props.loadCIResidualRisk(this.props.COMPANY_ID);
    this.props.loadApprovalsAutoAction('Change', '4');
    if (this.props.editToggle) {
      if (this.props.initialValues.criteriaList !== undefined) {
        let arrayvar = [];
        let criteriaLength =
          this.props.initialValues.criteriaList.length > 0
            ? this.props.initialValues.criteriaList.length
            : 1;
        for (var i = 0; i < criteriaLength; i++) {
          arrayvar.push(i);
        }
        this.setState(
          { arrayCounts: criteriaLength, extraCounts: arrayvar },
          () => {
            this.props.setExtraCount(arrayvar);
          }
        );
        if (this.props.initialValues.criteriaList.length > 0) {
          if(this.props.initialValues.criteriaList.length==1 && this.props.initialValues.criteriaList[0].OPERATOR=="" && this.props.initialValues.criteriaList[0].KEY=="" && this.props.initialValues.criteriaList[0].VALUE=="" ){
              this.setState({ criteriaExist: false, criteriaToggle: false });
          }else{
            this.setState({ criteriaExist: true, criteriaToggle: true });
            this.props.initialValues.criteriaList.map((item, index) => {
              this.setState({ [`key${index}`]: item.KEY });
            });
        }
  }
      }
    } else {
      this.props.setExtraCount([0]);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.initialValues.ciClass != this.props.initialValues.ciClass)
      this.props.loadCmdbClassBasedList(nextProps.initialValues.ciClass, "5");

    if (nextProps.CLASS_NAME && nextProps.CLASS_NAME != this.props.CLASS_NAME) {
      this.setState({ classNameSelected: this.props.CLASS_NAME });
    }
  }

  componentWillUnmount() {
    this.props.spcmLoader("attribute", "success");
  }

  addCriteriaToggle() {
    this.setState({
      criteriaToggle: !this.state.criteriaToggle,
    });
  }

  changeScenarioType(e, extraCount) {
    const change = this.props.change;
    if (e.target.value) {
      this.setState({ [`key${extraCount}`]: e.target.value });
      change(`value${extraCount}`, "");
    }
    if (e.target.value == "Environment") {
      change("value" + extraCount, "Production");
    } else if (e.target.value == "Consumer Company") {
      change("value" + extraCount, this.props.profileDetails.companyId);
    } else if (e.target.value == "Location") {
      change("value" + extraCount, this.props.profileDetails.cwlName);
    }
  }

  submitForm(values) {
    let temp2 = values;
    Object.keys(temp2).forEach((val) => {
      if (val.indexOf("value" == 0)) {
        if (
          val.substring(5) &&
          this.state.extraCounts.indexOf(parseInt(val.substring(5),10)) < 0
        ) {
          delete values["key" + val.substring(5)];
          delete values["value" + val.substring(5)];
          delete values["operator" + val.substring(5)];
        }
      }
    });

    values.ciId = this.props.CI_ID;
    values.companyId = this.props.COMPANY_ID;
    values.status = 1;
    values.moduleId = "14";
    values.ciClassName = this.state.classNameSelected;
    values.ciAssetName = this.state.assetNameSelected;
    if (this.props.editToggle == true) {
      if (values.approverType == "Individual") {
        values.approverGroup = "";
        values.signIn = "";
      } else if (values.approverType == "Group") {
        values.approverIndividual = "";
      } else if (values.approverType == "Manager") {
        values.approverGroup = "";
        values.signIn = "";
        values.approverIndividual = "";
      }
      values.updatedby_name = "";
      values.updatedby = "";
      let key = [];
      let operator = [];
      let value = [];
      let final = [];
      Object.keys(values).forEach(function (valueKey, index) {
        if (valueKey.indexOf("value") == 0) {
          console.log("valueKey.substring(5)", valueKey.substring(5));
          valueKey.substring(5)
            ? (value[valueKey.substring(5)] = values[valueKey])
            : (value[0] = values[valueKey]);
        } else if (valueKey.indexOf("key") == 0) {
          console.log("valueKey.substring(3)", valueKey.substring(3));
          valueKey.substring(3)
            ? (key[valueKey.substring(3)] = values[valueKey])
            : (key[0] = values[valueKey]);
        } else if (valueKey.indexOf("operator") == 0) {
          console.log("valueKey.substring(8)", valueKey.indexOf("operator"));
          valueKey.substring(8)
            ? (operator[valueKey.substring(8)] = values[valueKey])
            : (operator[0] = values[valueKey]);
        }
      }, this);
      Object.keys(key).forEach(function (valueKey) {
        final.push({
          key: key[valueKey],
          operator: operator[valueKey],
          value: value[valueKey],
        });
      });
      if(this.state.criteriaToggle === true){
        values.criteria = final
      }
      //return false;
      this.props.spcmLoader("attribute", "posting");
      return axios
        .patch("/service/api/approval/editApprovalRule/" + values.ruleId, values)
        .then((response) => {
          this.props.editToggleFunc(false);
          this.props.getCmdbApprovals(
            this.props.CI_ID,
            this.props.searchBy,
            this.props.searchByVal,
            this.props.page,
            this.props.pageSize
          );
          this.props.toggleFormListFunc(false);
          this.props.spcmLoader("attribute", "success");
        })
        .catch((error) => {
          this.props.spcmLoader("attribute", "success");
          throw new SubmissionError({ _error: error.response.data.message });
        });
    } else {
      if (
        values.approverType == "Group" ||
        values.approverType == "Individual"
      ) {
        if (
          this.state.companyIdParam == "" ||
          this.state.companyIdParam == "0"
        ) {
          this.setState({ companyErrorColor: "error" });
          return false;
        } else {
          this.setState({ companyErrorColor: "" });
        }
        if (values.approverType == "Group" && !values.approverGroup) {
          this.setState({ groupErrorColor: "error" });
          return false;
        } else {
          this.setState({ groupErrorColor: "" });
        }
        if (values.approverType == "Individual" && !values.approverIndividual) {
          this.setState({ individualErrorColor: "error" });
          return false;
        } else {
          this.setState({ individualErrorColor: "" });
        }
      }
      let key = [];
      let operator = [];
      let value = [];
      let final = [];
      Object.keys(values).forEach(function (valueKey) {
        console.log("valueKey.indexOf", valueKey.indexOf("value"));
        if (valueKey.indexOf("value") == 0) {
          valueKey.substring(5)
            ? (value[valueKey.substring(5)] = values[valueKey])
            : (value[0] = values[valueKey]);
        } else if (valueKey.indexOf("key") == 0) {
          valueKey.substring(3)
            ? (key[valueKey.substring(3)] = values[valueKey])
            : (key[0] = values[valueKey]);
        } else if (valueKey.indexOf("operator") == 0) {
          valueKey.substring(8)
            ? (operator[valueKey.substring(8)] = values[valueKey])
            : (operator[0] = values[valueKey]);
        }
      }, this);
      Object.keys(key).forEach(function (valueKey) {
        final.push({
          key: key[valueKey],
          operator: operator[valueKey],
          value: value[valueKey],
        });
      });
      if(this.state.criteriaToggle === true){
        values.criteria = final
      }
     
      this.props.spcmLoader("attribute", "posting");
      console.log("VALUES POSTED ARE", values);
      return axios
        .post("/service/api/approval/createApprovalRule", values)
        .then((response) => {
          if (response.status == 201) {
            this.props.editToggleFunc(false);
            this.props.getCmdbApprovals(
              this.props.CI_ID,
              this.props.searchBy,
              this.props.searchByVal,
              this.props.page,
              this.props.pageSize
            );
            this.props.toggleFormListFunc(false);
            this.props.spcmLoader("attribute", "success");
          }
        })
        .catch((error) => {
          this.props.spcmLoader("attribute", "success");
          throw new SubmissionError({ _error: error.response.data.message });
        });
    }
  }

  onChange(e) {
    this.setState({ type: e.target.value });
    if (e.target.value == "Group") {
      if (this.state.type == "Individual" && this.state.companyIdParam != "") {
        this.onCrossClickCompany();
      }
      this.setState({ type: "Group" });
    } else if (e.target.value == "Individual") {
      if (this.state.type == "Group" && this.state.companyIdParam != "") {
        this.onCrossClickCompany();
      }
      this.setState({ type: "Individual" });
    } else {
      this.setState({ type: "" });
    }
  }

  addRow() {
    this.setState({ arrayCounts: this.state.arrayCounts + 1 });
    let arrayvar = this.state.extraCounts.slice();
    arrayvar.push(this.state.arrayCounts);
    this.setState({ extraCounts: arrayvar });
    this.props.setExtraCount(arrayvar);
    if(this.props.editToggle ){
      this.setState({ addSelected: false })
    }
  }
  deleteRow(myIndex) {
    let arrayvar = this.state.extraCounts;
    let index = arrayvar.indexOf(myIndex);
    arrayvar.splice(index, 1);
    this.setState({ extraCounts: arrayvar });
    this.props.setExtraCount(arrayvar);
  }

  loadCount(
    approverCriteria,
    approverCriteriaOperator,
    category,
    environment,
    consumerCompany,
    supportCompany,
    usrlocation,
    residualRisk
  ) {
    const rowlength = this.state.extraCounts.length;
    // console.log("rowlength========",rowlength)
    return this.state.extraCounts.map((extraCount, i) => {
      let valueDropDown = [];
      if (this.state[`key${extraCount}`] === "Category")
        valueDropDown = this.state.cmdbDropDownCATEGORY;
      else if (this.state[`key${extraCount}`] === "Environment") {
        valueDropDown = this.state.cmdbDropDownENV;
      } else if (this.state[`key${extraCount}`] === "Consumer Company") {
        valueDropDown = consumerCompany;
      } else if (this.state[`key${extraCount}`] === "Support Company")
        valueDropDown = supportCompany;
      else if (this.state[`key${extraCount}`] === "Location") {
        valueDropDown = this.state.cmdbDropDownLOCATION;
      } else if (this.state[`key${extraCount}`] === "Residual Risk") {
        valueDropDown = this.state.cmdbDropDownRESIDUALRISK;
      }
      if (this.props.editToggle == true) {
        if (rowlength === i + 1) {
          return (
            <tr>
              <td>
                <Field
                  component={_dynamicNamedropDown}
                  nameforOptionsList="key"
                  options={approverCriteria}
                  name={"key" + extraCount}
                  className="form-control"
                  onChange={(e) => this.changeScenarioType(e, extraCount)}
                />
              </td>
              <td>
                <Field
                  component={_dynamicNamedropDown}
                  nameforOptionsList="operator"
                  name={"operator" + extraCount}
                  options={approverCriteriaOperator}
                  className="form-control"
                />
              </td>
              <td>
                <Field
                  component={_dynamicNamedropDown}
                  nameforOptionsList="value"
                  name={"value" + extraCount}
                  options={valueDropDown}
                  className="form-control"
                />
              </td>
              <td>
                {i !== 0 ? (
                  <Button
                    title="Delete"
                    className="addMinusButton"
                    onClick={() => {
                      this.deleteRow(extraCount);
                    }}
                  >
                    <i className="fa fa-trash"></i>
                  </Button>
                ) : <Button
                title="Delete"
                className="addMinusButton"
                onClick={() => {
                  this.resetValue(extraCount);
                }}
              >
                <i className="fa fa-trash"></i>
              </Button>}
                <Button
                  title="Add"
                  className="addMinusButton"
                  onClick={() => {
                    this.addRow();
                  }}
                >
                  <HiPlus/>
                </Button>
              </td>
            </tr>
          );
        } else {
          return (
            <tr key={i}>
              <td>
                <Field
                  component={_dynamicNamedropDown}
                  nameforOptionsList="key"
                  options={approverCriteria}
                  name={"key" + extraCount}
                  className="form-control"
                  onChange={(e) => this.changeScenarioType(e, extraCount)}
                />
              </td>
              <td>
                <Field
                  component={_dynamicNamedropDown}
                  nameforOptionsList="operator"
                  name={"operator" + extraCount}
                  options={approverCriteriaOperator}
                  className="form-control"
                />
              </td>
              <td>
                <Field
                  component={_dynamicNamedropDown}
                  nameforOptionsList="value"
                  name={"value" + extraCount}
                  options={
                    this.state[`key${extraCount}`] === "Consumer Company"
                      ? consumerCompany
                      : this.state[`key${extraCount}`] === "Support Company"
                      ? supportCompany
                      : valueDropDown
                  }
                  className="form-control"
                />
              </td>
              <td>
                <Button
                  title="Delete"
                  className="addMinusButton"
                  onClick={() => {
                    this.deleteRow(extraCount);
                  }}
                >
                  <i className="fa fa-trash"></i>
                </Button>
              </td>
            </tr>
          );
        }
      } else {
        if (rowlength === i + 1) {
          return (
            <tr>
              <td>
                <Field
                  component={_dynamicNamedropDown}
                  nameforOptionsList="key"
                  options={approverCriteria}
                  name={"key" + extraCount}
                  className="form-control"
                  onChange={(e) => this.changeScenarioType(e, extraCount)}
                />
              </td>
              <td>
                <Field
                  component={_dynamicNamedropDown}
                  nameforOptionsList="operator"
                  name={"operator" + extraCount}
                  options={approverCriteriaOperator}
                  className="form-control"
                />
              </td>
              <td>
                <Field
                  component={_dynamicNamedropDown}
                  nameforOptionsList="value"
                  name={"value" + extraCount}
                  options={
                    this.state[`key${extraCount}`] === "Consumer Company"
                      ? consumerCompany
                      : this.state[`key${extraCount}`] === "Support Company"
                      ? supportCompany
                      : valueDropDown
                  }
                  className="form-control"
                />
              </td>
              <td>
                {i !== 0 ? (
                  <Button
                    title="Delete"
                    className="addMinusButton"
                    onClick={() => {
                      this.deleteRow(extraCount);
                    }}
                  >
                    <i className="fa fa-trash"></i>
                  </Button>
                ) : null}
                <Button
                  title="Add"
                  className="addMinusButton"
                  onClick={() => {
                    this.addRow();
                  }}
                >
                  <HiPlus/>
                </Button>
              </td>
            </tr>
          );
        } else {
          return (
            <tr key={i}>
              <td>
                <Field
                  component={_dynamicNamedropDown}
                  nameforOptionsList="key"
                  options={approverCriteria}
                  name={"key" + extraCount}
                  className="form-control"
                  onChange={(e) => this.changeScenarioType(e, extraCount)}
                />
              </td>
              <td>
                <Field
                  component={_dynamicNamedropDown}
                  nameforOptionsList="operator"
                  name={"operator" + extraCount}
                  options={approverCriteriaOperator}
                  className="form-control"
                />
              </td>
              <td>
                <Field
                  component={_dynamicNamedropDown}
                  nameforOptionsList="value"
                  name={"value" + extraCount}
                  options={
                    this.state[`key${extraCount}`] === "Consumer Company"
                      ? consumerCompany
                      : this.state[`key${extraCount}`] === "Support Company"
                      ? supportCompany
                      : valueDropDown
                  }
                  className="form-control"
                />
              </td>
              <td>
                <Button
                  title="Delete"
                  className="addMinusButton"
                  onClick={() => {
                    this.deleteRow(extraCount);
                  }}
                >
                  <i className="fa fa-trash"></i>
                </Button>
              </td>
            </tr>
          );
        }
      }
    });
  }

  onTypeSelect(e) {
    this.setState({ classIdSelected: e.target.value });
    this.setState({ classNameSelected: e.target[e.target.selectedIndex].text });
    this.props.loadCmdbClassBasedList(e.target.value, "5");
  }
  onAssetSelect(e) {
    this.setState({ assetNameSelected: e.target[e.target.selectedIndex].text });
  }

  onCompanySelection(selectedCompany) {
    this.setState({
      companyErrorColor: "",
      companySelectedList: selectedCompany,
    });
    this.props.dispatch(change("ciApprovalForm", "approverGroup", ""));
    this.setState({ inputValueGroup: "", groupSelectedList: [] });
    this.props.emptyApproverGroupOrUser();

    if (selectedCompany.length > 0) {
      this.setState({ companyIdParam: selectedCompany[0].id });
      this.setState({ inputValueCompany: selectedCompany[0].label });
      if (this.state.type == "Group") {
        this.props.getApproverGroupOrUser(
          this.props.COMPANY_ID,
          selectedCompany[0].id,
          "group"
        );
      } else if (this.state.type == "Individual") {
      }
    } else {
      this.setState({ companyIdParam: "" });
    }
  }

  onCompanyInputChange(val) {
    this.setState({ inputValueCompany: val });
  }

  onCrossClickCompany() {
    this.setState({ companyIdParam: "" });
    this.setState({ inputValueCompany: "", companySelectedList: [] });
    this.props.dispatch(change("ciApprovalForm", "approverGroup", ""));
    this.setState({ inputValueGroup: "", groupSelectedList: [] });
    this.props.emptyApproverGroupOrUser();
    this.setState({ companyErrorColor: "error" });
  }

  setCompanyErrorColor() {
    if (this.state.companySelectedList.length === 0)
      this.setState({ companyErrorColor: "error" });
    else this.setState({ companyErrorColor: "" });
  }

  onGroupSelection(selectedGroup) {
    this.setState({ groupErrorColor: "", groupSelectedList: selectedGroup });

    if (selectedGroup.length > 0) {
      this.props.dispatch(
        change("ciApprovalForm", "approverGroup", selectedGroup[0].id)
      );
      this.setState({ inputValueGroup: selectedGroup[0].label });
    } else {
      this.props.dispatch(change("ciApprovalForm", "approverGroup", ""));
    }
  }

  onGroupInputChange(val) {
    this.setState({ inputValueGroup: val });
  }

  onCrossClickGroup() {
    this.props.dispatch(change("ciApprovalForm", "approverGroup", ""));
    this.setState({ inputValueGroup: "", groupSelectedList: [] });
    this.setState({ groupErrorColor: "error" });
  }

  setGroupErrorColor() {
    if (this.state.groupSelectedList.length === 0)
      this.setState({ groupErrorColor: "error" });
    else this.setState({ groupErrorColor: "" });
  }

  setIndividualErrorColor(errorVal) {
    this.setState({ individualErrorColor: errorVal });
  }

  render() {
     //  console.log("remove=====", this.state.criteriaToggle)
    let defaultClass = this.props.CLASS_ID;
    let defaultAssetCI = this.props.CI_ID;
    let ciClass = this.props.ciClass;
    const approverType = this.props.spcmReducer.approver_type;
    const signInType = [
      { text: "One must Sign In", value: "OMS" },
      { text: "All must Sign In", value: "AMS" },
    ];
    const mod = [{ text: "Change", value: "change" }];
    const asset = this.props.cmdAssetsBasedOnClassList;
    const level = this.props.spcmReducer.approver_level;
    let environment = this.props.ciEnv;
    let residualRisk = this.props.ciResidualRisk;
    let consumerCompany = this.props.ciCompany;
    let supportCompany = this.props.cmdbSupportCompany;
    let usrlocation = this.props.ciLocation;
    let category = this.props.ciCategories;
    let approverCriteria = this.props.spcmReducer.approverCriteria;
    const approverCriteriaOperator =
      this.props.spcmReducer.approverCriteriaOperator;
    const { error, handleSubmit, urgency } = this.props;

    if (
      (this.props.ciLocation != undefined || this.props.ciLocation != null) &&
      this.state.cmdbDropDownLOCATION.length == 0
    ) {
      let valueDropDown = [];
      for (let i = 0; i < this.props.ciLocation.length; i++) {
        let obj = {
          field1Value: this.props.ciLocation[i]["LOCATION_NAME"],
          field1Key: this.props.ciLocation[i]["LOCATION_NAME"],
        };
        valueDropDown.push(obj);
      }
      this.state.cmdbDropDownLOCATION = valueDropDown;
    }

    if (
      (this.props.ciEnv != undefined || this.props.ciEnv != null) &&
      this.state.cmdbDropDownENV.length == 0
    ) {
      let valueDropDown = [];

      for (let i = 0; i < this.props.ciEnv.length; i++) {
        let obj = {
          field1Value: this.props.ciEnv[i]["FIELD1_KEY"],
          field1Key: this.props.ciEnv[i]["FIELD1_KEY"],
        };
        valueDropDown.push(obj);
      }
      this.state.cmdbDropDownENV = valueDropDown;
    }
    if (
      (this.props.ciResidualRisk != undefined || this.props.ciResidualRisk != null) &&
      this.state.cmdbDropDownRESIDUALRISK.length == 0
    ) {
      let valueDropDown = [];

      for (let i = 0; i < this.props.ciResidualRisk.length; i++) {
        let obj = {
          field1Value: this.props.ciResidualRisk[i]["FIELD1_KEY"],
          field1Key: this.props.ciResidualRisk[i]["FIELD1_KEY"],
        };
        valueDropDown.push(obj);
      }
      // this.setState({cmdbDropDownRESIDUALRISK: valueDropDown});
      this.state.cmdbDropDownRESIDUALRISK = valueDropDown;
    }
    let valueDropDown = [];
    for (let i = 0; i < this.props.ciCategories.length; i++) {
      let obj = {
        field1Value: this.props.ciCategories[i]["CATEGORY_NAME"],
        field1Key: this.props.ciCategories[i]["CATEGORY_NAME"],
      };
      valueDropDown.push(obj);
    }
    this.state.cmdbDropDownCATEGORY = valueDropDown;
    let changeType = this.props.lovForType;
    changeType = changeType.map((item) => {
      return { text: item.field1Key, value: item.field1Value };
    });

    let companyTypeaheadOptions = [];
    if (this.props.ciSupCompany && this.props.ciSupCompany.length > 0) {
      let flag = 0;
      this.props.ciSupCompany.forEach((item, i) => {
        let obj = {};
        obj.id = item.field1Value;
        obj.label = item.field1Key;
        companyTypeaheadOptions.push(obj);
        if (item.field1Value == this.props.COMPANY_ID) flag = 1;
      });
      if (flag == 0) {
        let obj = {};
        obj.id = this.props.COMPANY_ID;
        obj.label = this.props.COMPANY_NAME;
        companyTypeaheadOptions.push(obj);
      }
    }

    let groupsTypeaheadOptions = [];
    if (
      this.props.spcmReducer.approver_group_or_individual &&
      this.props.spcmReducer.approver_group_or_individual.length > 0
    ) {
      this.props.spcmReducer.approver_group_or_individual.forEach((item, i) => {
        let obj = {};
        obj.id = item.field1Value;
        obj.label = item.field1Key;
        groupsTypeaheadOptions.push(obj);
      });
    }

    return (
      <div className="rBoxGap">
        <form>
          {error == undefined || error == null ? null : (
            <Alert variant="danger" className="errorMessageSec">
              <p>{error}</p>
            </Alert>
          )}
          <div>
            <Row>
              <Col md={12}>
                <Form.Group className="priceForm-group form-group">
                  <Form.Label bsClass="">
                    <span className="rStar"></span> {this.props.tr["Rule Name"]}
                  </Form.Label>
                  <Field
                    component={_inputField}
                    name="ruleName"
                    className="form-control"
                    disabled={this.props.editToggle == true}
                  ></Field>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">
                    <span className="rStar"></span> {this.props.tr["Module"]}{" "}
                  </Form.Label>
                  <Field
                    component={_dropDown}
                    options={mod}
                    name="mod"
                    className="form-control"
                    disabled
                  ></Field>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">
                    <span className="rStar"></span>{" "}
                    {this.props.tr["Change Type"]}{" "}
                  </Form.Label>
                  <Field
                    component={_dropDown}
                    options={changeType}
                    name="rfcChangeType"
                    className="form-control"
                    disabled={this.props.editToggle == true}
                  ></Field>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">
                    <span className="rStar"></span> {this.props.tr["Class"]}
                  </Form.Label>
                  <Field
                    component={_dropDown}
                    options={ciClass}
                    name="ciClass"
                    className="form-control"
                    defaultValue={defaultClass}
                    onChange={(e) => this.onTypeSelect(e)}
                    disabled
                  ></Field>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">
                    <span className="rStar"></span> {this.props.tr["Asset/CI"]}
                  </Form.Label>
                  {/* <Field name="ciAsset" component={_dropDown} options={asset} className="form-control" onChange={(e) => this.onAssetSelect(e)} disabled={this.props.editToggle == true && this.props.initialValues.ciAsset != undefined}> */}
                  <Field
                    name="ciAsset"
                    component={_dropDown}
                    options={asset}
                    className="form-control"
                    defaultValue={defaultAssetCI}
                    onChange={(e) => this.onAssetSelect(e)}
                    disabled
                  ></Field>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">
                    <span className="rStar"></span> {this.props.tr["Level"]}{" "}
                  </Form.Label>
                  <Field
                    component={_dropDown}
                    options={level}
                    name="level"
                    className="form-control"
                    disabled={this.props.editToggle == true}
                  ></Field>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">
                    <span className="rStar"></span> {this.props.tr["Type"]}{" "}
                  </Form.Label>
                  <Field
                    component={_dropDown}
                    options={approverType}
                    name="approverType"
                    className="form-control"
                    onChange={this.onChange}
                    disabled={this.props.editToggle == true}
                  ></Field>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {this.state.type == "Individual" ? (
                <div>
                  {this.props.editToggle == true ? null : (
                    <Col md={12}>
                      <Form.Group
                        className="form-group"
                      >
                        <Form.Label bsClass="">
                          <span className="rStar"></span>{" "}
                          {this.props.tr["Company"]}{" "}
                        </Form.Label>
                        <div className="dvTypehdropdown">
                        <Field
                          component={DropdownPlusTypeahead}
                          onSelection={this.onCompanySelection}
                          setErrorColor={this.setCompanyErrorColor}
                          name="company"
                          id="company"
                          errorColor={this.state.companyErrorColor}
                          className={`form-control`}
                          options={companyTypeaheadOptions}
                          selectedOptions={this.state.companySelectedList}
                          onInputChange={this.onCompanyInputChange}
                          inputValue={this.state.inputValueCompany}
                          onCrossClick={this.onCrossClickCompany}
                        />
                        </div>
                      </Form.Group>
                    </Col>
                  )}
                  <Col md={12}>
                    <Form.Group
                      className="form-group"
                    >
                      <Form.Label bsClass="">
                        <span className="rStar"></span>{" "}
                        {this.props.tr["Approver Individual"]}{" "}
                      </Form.Label>
                      <div className="dvTypehdropdown">
                      {this.props.editToggle == true ? (
                        // <Field component={_dropDown} options={individuals} name="approverIndividual" className='form-control' disabled></Field>
                        <Field
                          component={_inputField}
                          title={
                            this.props.initialValues.approverIndividualDetails
                          }
                          name="approverIndividualName"
                          className={`form-control`}
                          disabled
                        ></Field>
                      ) : (
                        <IndividualAsyncTypeahead
                          id="approverIndividual"
                          name="approverIndividual"
                          errorColor={this.state.individualErrorColor}
                          setErrorColor={this.setIndividualErrorColor}
                          offeringCompanyIdParam={this.props.COMPANY_ID}
                          supportCompanyIdParam={this.state.companyIdParam}
                          translator={this.props.tr}
                        />
                      )}
                      </div>
                    </Form.Group>
                  </Col>
                </div>
              ) : null}

              {this.state.type == "Group" ? (
                <div>
                  {this.props.editToggle == true ? null : (
                    <Col md={12}>
                      <Form.Group
                        className="form-group"
                      >
                        <Form.Label bsClass="">
                          <span className="rStar"></span>{" "}
                          {this.props.tr["Company"]}{" "}
                        </Form.Label>
                        <div className="dvTypehdropdown">
                        <Field
                          component={DropdownPlusTypeahead}
                          onSelection={this.onCompanySelection}
                          setErrorColor={this.setCompanyErrorColor}
                          name="company"
                          id="company"
                          errorColor={this.state.companyErrorColor}
                          className={`form-control}`}
                          options={companyTypeaheadOptions}
                          selectedOptions={this.state.companySelectedList}
                          onInputChange={this.onCompanyInputChange}
                          inputValue={this.state.inputValueCompany}
                          onCrossClick={this.onCrossClickCompany}
                        />
                        </div>
                      </Form.Group>
                    </Col>
                  )}
                  <Col md={12}>
                    <Form.Group
                      className="form-group"
                    >
                      <Form.Label bsClass="">
                        <span className="rStar"></span>{" "}
                        {this.props.tr["Approver Group"]}{" "}
                      </Form.Label>
                      <div className="dvTypehdropdown">
                      {this.props.editToggle == true ? (
                        <Field
                          component={_inputField}
                          title={this.props.initialValues.approverGroupDetails}
                          name="approverGroupName"
                          className={`form-control`}
                          disabled
                        ></Field>
                      ) : (
                        <Field
                          component={DropdownPlusTypeahead}
                          onSelection={this.onGroupSelection}
                          setErrorColor={this.setGroupErrorColor}
                          errorColor={this.state.groupErrorColor}
                          name="approverGroup"
                          id="approverGroup"
                          className="form-control"
                          options={groupsTypeaheadOptions}
                          selectedOptions={this.state.groupSelectedList}
                          onInputChange={this.onGroupInputChange}
                          inputValue={this.state.inputValueGroup}
                          onCrossClick={this.onCrossClickGroup}
                        />
                      )}
                      </div>
                    </Form.Group>
                  </Col>
                </div>
              ) : null}
            </Row>
            <Row>
              {this.state.type == "Group" ? (
                <Col md={12}>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">
                      <span className="rStar"></span>{" "}
                      {this.props.tr["Sign In Type"]}{" "}
                    </Form.Label>
                    <Field
                      component={_dropDown}
                      options={signInType}
                      name="signIn"
                      className="form-control"
                      disabled={this.props.editToggle == true}
                    ></Field>
                  </Form.Group>
                </Col>
              ) : (
                ""
              )}

              <Col md={12}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">
                    <span className="rStar"></span> {this.props.tr["Weightage"]}{" "}
                  </Form.Label>
                  <Field
                    component={_number}
                    max={50}
                    min={1}
                    name="weightage"
                    className="form-control"
                  ></Field>
                </Form.Group>
              </Col>
              
              <Col md={12}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">
                    {this.props.tr["Automatic action on approvals in pending status"]}{" "}
                  </Form.Label>
                  <Field
                    component={_dropDown}
                    options={this.props.approvalsAutoAction}
                    name="autoAction"
                    className="form-control"
                  ></Field>
                </Form.Group>
              </Col>
              
              <Col md={12}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">
                    {this.props.tr["Automatic action on approvals in (x) days"]}{" "}
                  </Form.Label>
                  <Field
                    component={_number}
                    max={50}
                    min={1}
                    name="autoActionInDays"
                    className="form-control"
                  ></Field>
                </Form.Group>
              </Col>

              {this.state.criteriaToggle === true ? (
                <Col md={12}>
                  <Table
                    className="criteria-table"
                    cellspacing="5"
                    cellpadding="5"
                  >
                    <thead>
                      <tr>
                        <th width="39%">Key</th>
                        <th width="22%">Operator</th>
                        <th width="29%">Value</th>
                        <th width="10%">{this.props.tr["Action"]}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.loadCount(
                        approverCriteria,
                        approverCriteriaOperator,
                        category,
                        environment,
                        consumerCompany,
                        supportCompany,
                        usrlocation,
                        residualRisk
                      )}
                    </tbody>
                  </Table>
                </Col>
              ) : null}
              <Col md={12}>
                <ButtonToolbar className="black">
                  {this.state.criteriaExist === false ? (
                    <Button
                      title={
                        this.state.criteriaToggle
                          ? "Remove Criteria"
                          : "Add Criteria"
                      }
                      onClick={() => {
                        this.addCriteriaToggle();
                      }}
                      bsSize="small"
                      bsStyle="primary"
                      className="rgSidrkBtn smallBtn"
                    >
                      {this.state.criteriaToggle
                        ? "Remove Criteria"
                        : "Add Criteria"}
                    </Button>
                  ) : null}
                  <Button
                    title="Save"
                    bsSize="small"
                    bsStyle="primary"
                    onClick={handleSubmit(this.submitForm)}
                    className="rgSidrkBtn smallBtn"
                  >
                    {this.props.spcmReducer.spcmPostingAttribute ? (
                      <ImSpinner6 className="icn-spinner"/>
                    ) : null}
                    {this.props.tr["Save"]}
                  </Button>
                  <Button
                    title="Close"
                    bsSize="small"
                    bsStyle="text"
                    className="rgSiLigBtn smallBtn"
                    onClick={() => {
                      this.props.toggleFormListFunc(false);
                      this.props.editToggleFunc(false);
                    }}
                  >
                    {this.props.tr["Close"]}
                  </Button>
                </ButtonToolbar>
              </Col>
            </Row>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (
  {
    spcmReducer,
    ciCat,
    profileDetails,
    lovForType,
    cmdAssetsBasedOnClassList,
    lovForChangeTiming,
    ciEnv,
    ciResidualRisk,
    approvalsAutoAction,
    ciClass,
    ciCompany,
    ciLocation,
    cmdbSupportCompany,
    ciCategories,
    ciSupCompany,
  },
  ownProps
) => {
  if (ownProps.editToggle == true) {
    if (
      spcmReducer.editLeftService.criteriaList != undefined &&
      spcmReducer.editLeftService.criteriaList != "undefined" &&
      spcmReducer.editLeftService.criteriaList !== null &&
      spcmReducer.editLeftService.criteriaList.length > 0
    ) {
      let obj = {};
      spcmReducer.editLeftService.criteriaList.map((item, index) => {
        Object.keys(item).forEach((val) => {
          let key = val + index;
          obj[key.toLowerCase()] = item[val];
        });
      });
      spcmReducer.editLeftService = Object.assign(
        spcmReducer.editLeftService,
        obj
      );
    }
  }
  return {
    spcmReducer,
    tr: spcmReducer.tr,
    ciClass,
    ciCat,
    lovForType,
    lovForChangeTiming,
    cmdAssetsBasedOnClassList,
    profileDetails,
    ciEnv,
    ciResidualRisk,
    approvalsAutoAction,
    ciCompany,
    ciLocation,
    cmdbSupportCompany,
    initialValues:
      ownProps.editToggle > 0
        ? spcmReducer.editLeftService
        : { ciClass: ownProps.CLASS_ID },
    ciCategories,
    ciSupCompany,
  };
};

XsmCIApprovalForm = reduxForm({
  form: "ciApprovalForm",
  validate,
  enableReinitialize: true,
})(XsmCIApprovalForm);

export default connect(mapStateToProps, {
  loadCIClass,
  getApproverType,
  loadProfile,
  loadCmdbClassBasedList,
  spcmLoader,
  getLovValues,
  setLeftEditId,
  getApprovals,
  getApprovalQuestions,
  getApprovalDetails,
  getCmdbApproverCriteria,
  getApproverModule,
  getApproverGroup,
  getApproverIndividual,
  getApproverGroupOrUser,
  emptyApproverGroupOrUser,
  getApproverLOB,
  getApproverOrderType,
  getApproverCompanyCode,
  getApproverEntity,
  getApproverCriteriaOperator,
  getApproverScenario,
  listDataWithType,
  loadCIStatusCurrent,
  loadCIMetallic,
  loadCIEnv,
  loadCIManr,
  loadCIDataset,
  loadCICompany,
  loadCILocation,
  loadCIGroup,
  loadCIInd,
  loadCICat,
  loadCIType,
  loadCISCat,
  loadCISupCompany,
  getCmdbApprovals,
  loadCMDBSupportCompany,
  getCmdbApproverLevel,
  loadCmdbCategories,
  loadCIResidualRisk,
  loadApprovalsAutoAction
})(XsmCIApprovalForm);
