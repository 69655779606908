
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import ReactDOM from 'react-dom';
import OnClickOutside from 'react-onclickoutside';
import { ListGroup, ListGroupItem, Button } from 'react-bootstrap';

import { connect } from 'react-redux';
import { loadHamburgerCategory, storeCategoryName } from '_Actions/homepage/hamburgerAction';
import { loadSubHamburgerCategory } from '_Actions/homepage/subHamburgerAction';
import { bindActionCreators } from 'redux';
import { resetStateInStore } from "_Actions/offeringDetails/offeringDetailsActions";
import { browserHistory } from 'react-router';
import {ImSpinner6} from "react-icons/im";

class SxMainMenuHamburger extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showResults: false,
			categoryList: [],
			subCategoryUlData: false,
			dataKeyId: '',
			offsetX: 0,
			isOpened: true
			// viewHeight: props.height
		};
		this.callbackFun = this.callbackFun.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.hideMenuBox = this.hideMenuBox.bind(this);
	}

	// componentWillMount() {
	// 	this.setState({ viewHeight: (window.innerHeight-100) + 'px' });
	// }

	onHamburgerMenuClick() {
		// console.log('ggggggggggggg',this.props)
		this.props.resetStateInStore('selected_components');
		this.props.loadHamburgerCategory();
		this.props.loadSubHamburgerCategory();
		// this.setState({ categoryList });
		this.setState({ showResults: !this.state.showResults, isOpened: true });
	}
	callbackFun(id) {
		this.setState({ showResults: id });
		this.setState({ dataKeyId: null });
	}

	subCategoryHoverFun(dataKeyId, categoryName, e) {
		// console.log('categoryName',categoryName);

		let myPosition;
		let parentElement = document.getElementById('hamburgerMenu').getBoundingClientRect().top;
		let childElement = ReactDOM.findDOMNode(e.target).getBoundingClientRect().top;
		let btnPos = ReactDOM.findDOMNode(e.target).parentNode.getBoundingClientRect().top;

		if (e.target.type == 'button') { myPosition = childElement - parentElement; } else { let newPos = childElement - (childElement - btnPos); myPosition = newPos - parentElement; }

		this.setState({ offsetX: myPosition });
		this.props.storeCategoryName(categoryName);
		// this.props.loadSubHamburgerCategory();
		if (this.state.dataKeyId == dataKeyId) {
			this.setState({ subCategoryUlData: !this.state.subCategoryUlData });
			this.setState({ dataKeyId: dataKeyId });
		} else {
			this.setState({ subCategoryUlData: true });
			this.setState({ dataKeyId: dataKeyId });
		}
	}
	subCategoryOutFun() {
		this.setState({ dataKeyId: null });
	}

	hamburgerSubCategoryFun(subCategoryList, categoryName) {
		// console.log('subCategoryList',subCategoryList)
		// console.log('categoryName',categoryName);

		let subCategoryListStr = JSON.stringify(subCategoryList);
		let subCategoryListUpdated = JSON.parse(subCategoryListStr);

		// subCategoryListUpdated = subCategoryListUpdated.filter(item => {
		// 	item.categoryName == categoryName
		// });

		let finalSubCategoryArr = [];

		for (let i = 0; i < subCategoryListUpdated.length; i++) {
			// console.log('subCategoryListUpdated[i].categoryName',subCategoryListUpdated[i].categoryName)
			// console.log('dffffffffffffffffffff',(subCategoryListUpdated[i].categoryName != categoryName))
			if (subCategoryListUpdated[i].categoryName == categoryName) {
				// subCategoryListUpdated.splice(i, 1);
				finalSubCategoryArr.push(subCategoryListUpdated[i]);
			}
		}

		// console.log('finalSubCategoryArr',finalSubCategoryArr)

		if (finalSubCategoryArr && Array.isArray(finalSubCategoryArr) && finalSubCategoryArr.length) {
			return finalSubCategoryArr.map((conv) => {
				// console.log('conv----------------------',conv);
				let tempStr1 = (conv.subCategoryName).toLowerCase();
				tempStr1 = tempStr1.charAt(0).toUpperCase() + tempStr1.slice(1);

				// console.log('tempStr1tempStr1tempStr1',tempStr1)
				return (
					<ListGroupItem bsPrefix=' ' key={conv.id} className="catName"><Button bsPrefix=' '  onClick={() => { this.callbackFun(false); browserHistory.push(`/category/${conv.id}/${conv.categoryName.includes("?") ? conv.categoryName.replace("?", "") : conv.categoryName}/${conv.subCategoryName}/list`); }} ><img src={conv.subCategoryImageTempURL} /><span className="hidden-xs">{conv.subCategoryName}</span></Button></ListGroupItem>
				);
			});
		}
		else {
			return (null)
		}
	}
	handleKeyPress(e, newIndex, categoryName) {
		if (e.code == 'Space' || e.which == 32 || e.code == 'Enter' || e.which == 13) {
			this.subCategoryHoverFun(newIndex, categoryName);
		}
	}

	hideMenuBox() {
		this.setState({ isOpened: false });
	}

	hamburgerMenuFun() {
		// return this.state.categoryList.map((conv, index) => {
		// 	return(
		// 			<ListGroupItem onMouseOver={this.subCategoryHoverFun.bind(this, index)} bsPrefix=' ' key={conv.id} className={this.state.subCategoryUlData && this.state.dataKeyId==index ? "catName active" : "catName"}><Button onKeyPress={(e)=>{this.handleKeyPress(e,index)}}  bsPrefix=' '><img src={"/images/category-icons/"+ conv.img} /><span className="hidden-xs">{conv.name}</span></Button>{this.state.subCategoryUlData && this.state.dataKeyId==index ? <ListGroup  bsPrefix=' ' className="subCategory">{this.hamburgerSubCategoryFun(conv)}</ListGroup>  : null}</ListGroupItem>
		// 		);
		// });
		if (this.props && this.props.hamburgerCategory && Array.isArray(this.props.hamburgerCategory) && this.props.hamburgerCategory.length) {
			return this.props.hamburgerCategory.map((conv, index) => {
				// let conv = JSON.parse(JSON.stringify(convObjCopy));
				let tempStr = (conv.categoryName).toLowerCase();
				tempStr = tempStr.charAt(0).toUpperCase() + tempStr.slice(1);
				// conv.categoryName = tempStr;
				return (
					<ListGroupItem bsPrefix=' ' key={conv.id} className={this.state.subCategoryUlData && this.state.dataKeyId == index ? "catdiv active" : "catdiv"}><Button onClick={this.subCategoryHoverFun.bind(this, index, conv.categoryName)} onKeyPress={(e) => { this.handleKeyPress(e, index, conv.categoryName);}}  bsPrefix=' ' className="catName"><img src={conv.categoryImageTempURL} /><span className="hidden-xs">{conv.categoryName}</span></Button>{this.state.subCategoryUlData && this.state.dataKeyId == index ? <ListGroup bsPrefix=' ' className="subCategory">{this.hamburgerSubCategoryFun(this.props.subHamburgerCategory, conv.categoryName)}</ListGroup> : null}</ListGroupItem>
				);
			});
		}
		else {
			return ('');
		}

	}
	render() {
		// console.log("viewHeight--ddd ", this.state.viewHeight);
		return (
			<div className="hamiconLine hidden-xs hidden-sm">
				<Button  bsPrefix=' ' className="allCateBtn" onClick={this.onHamburgerMenuClick.bind(this)}>All Categories {this.props.allCategoriesLoader ? <ImSpinner6 className="icn-spinner"/> : <span className="caret"></span>}</Button>
				{this.state.showResults ? (!this.props.allCategoriesLoader ? <HamburgerResult isOpened={this.state.isOpened} focusOnPopup={this.onHamburgerMenuClick} hamburgerMenuFun={this.hamburgerMenuFun.bind(this)} fun={this.callbackFun} /> : null) : null}
				{/* { this.state.showResults ? (!this.props.allCategoriesLoader? <HamburgerResult viewHeight1={this.state.viewHeight} isOpened={this.state.isOpened} focusOnPopup={this.onHamburgerMenuClick} hamburgerMenuFun={this.hamburgerMenuFun.bind(this)} fun={this.callbackFun} /> :null) : null } */}
			</div>
		);
	}
}

let HamburgerResult=class HamburgerResult extends React.Component {
	handleClickOutside() {
		this.props.fun(false);
	}
	blurOnPopup(e) {
		let currentTarget = e.currentTarget;
		setTimeout(function () {
			if (!currentTarget.contains(document.activeElement)) {
				this.props.fun(false);
			}
		}.bind(this), 0);
	}
	render() {
		return (
			<div className="position-re">
				{this.props.isOpened == true ? <div tabIndex="1" onBlur={this.blurOnPopup.bind(this)} onFocus={() => { this.props.focusOnPopup.bind(this) }} id="hamburgerMenu" className="catePopup">{/*<div className="popupLogo"><img className="pLogo" src={AppLogo}  /></div>*/}<ListGroup bsPrefix=' ' className="ulBox">{this.props.hamburgerMenuFun()}</ListGroup></div> : null}
				{/* {this.props.isOpened == true ? <div tabIndex="1" onBlur={this.blurOnPopup.bind(this)} onFocus={()=>{this.props.focusOnPopup.bind(this)}} id="hamburgerMenu" className="catePopup" style={{ height: (this.props.viewHeight1) }}><Scrollbars style={{ height: (this.props.viewHeight1) }} autoHide={false} hideTracksWhenNotNeeded={true} className="area"><ListGroup bsPrefix=' ' className="ulBox">{this.props.hamburgerMenuFun()}</ListGroup></Scrollbars></div> : null} */}
			</div>
		);
	}
};

// SxMainMenuHamburger.propTypes = {
// 	height:React.PropTypes.string
// };

// SxMainMenuHamburger.defaultProps = {
// 	height:'500px'
// };

HamburgerResult = OnClickOutside(HamburgerResult);

// export default SxMainMenuHamburger;

export function mapStateToProps({ hamburgerCategory, subHamburgerCategory, allCategoriesLoader }) {
	return {
		hamburgerCategory, subHamburgerCategory, allCategoriesLoader: allCategoriesLoader.loading
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ loadHamburgerCategory, loadSubHamburgerCategory, storeCategoryName, resetStateInStore }, dispatch);
}

//export default connect(mapStateToProps)(SxNews);
export default connect(mapStateToProps, mapDispatchToProps)(SxMainMenuHamburger);
