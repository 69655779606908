
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import { useEffect, useState } from "react";

const useScrollToTop = () => {
  const [scrollToTop, setScrollToTop] = useState(false);

  useEffect(() => {
    if (window.scrollY > 300 && scrollToTop === true) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setScrollToTop(false);
    }
  }, [scrollToTop]);

  return { setScrollToTop };
};

export default useScrollToTop;