
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
// import { Row, Col, FormGroup, FormControl, ControlLabel, Button, ListGroup, ListGroupItem, Alert, Radio, InputGroup, Image } from 'react-bootstrap';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Alert from 'react-bootstrap/Alert';

import { getCompanyList } from './../../../actions/spcmActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { _passwordField, _inputField, field_file, _dropDown, _checkbox, itemQuantityField } from '../../../components/common/formFields';

import { Field, reduxForm, formValueSelector, change, SubmissionError } from 'redux-form';

const validate = (values, props) => {
  const errors = {};
  if (!values.SENARIO)
    errors.SENARIO = "Select Senario";
    
  if (!values.END_POINT || (values.END_POINT && values.END_POINT.trim() == "")) {
    errors.END_POINT = 'Enter End Point';
  }
  if (!values.COMPANY_NAME) {
    errors.COMPANY_NAME = "Select Company ID";
  }

  if (!values.USER_NAME || (values.USER_NAME && values.USER_NAME.trim() == "")){
    errors.USER_NAME = "Enter User Name";
  }
  if (!values.IDENS || (values.IDENS && values.IDENS.trim() == "")){
    errors.IDENS = "Enter Password";
  }
  if (!values.CRITERIA || (values.CRITERIA && values.CRITERIA.trim() == "")){
    errors.CRITERIA = "Enter Criteria";
  }
  return errors;
}
const requestMethodOption = [{value: 'POST', text: 'POST'}];
const authenticationOptions = [{value: 'Basic', text: 'Basic'}];
class xsmIntegrationHubCreateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createUsing: 'existing',
      passwordFieldType: 'pwd'
    };
    this.countCharacters = this.countCharacters.bind(this);
    this.ShowAndHidePassword = this.ShowAndHidePassword.bind(this);
  }

  countCharacters(e) {
    if (e.target.value.length > 100) {
      e.preventDefault();
    }
  }

  ShowAndHidePassword(){
    this.setState({
      passwordFieldType: this.state.passwordFieldType === 'pwd' ? 'text' : 'pwd'
    })  
  }

  render() {
    let companyList = this.props.spcmReducer.companyList ? this.props.spcmReducer.companyList.map(item => {return {value: item.field1Value, text: item.field1Key}}) : [];
    let { integrationHubSubmitError, integrationHubList, handleSubmit, pristine, reset, submitting, initialValues } = this.props;
    const scenarioOption = this.props.scenarioDropDownList && this.props.scenarioDropDownList.length > 0 ?
    this.props.scenarioDropDownList.map (item => ({value: item.scenarioId, text: item.scenarioName})) : [];
    const { translator, spcmReducer } = this.props;
    return (
      <div>
         { !integrationHubSubmitError ? 
         null : 
         <Alert variant="danger" className="errorMessageSec">
          <p>{translator['The form has missing required fields, please revalidate the highlighted fields and update the form !']}</p>
          </Alert>}
         
          <div className="borderForm bFormDiv">
              <Row>
               <Col md={6}>
                  <Form.Group>
                    <Form.Label bsClass=""><span className="rStar"></span>{translator['Company']}</Form.Label>
                    <Field component={_dropDown} name="COMPANY_NAME" options={companyList} className="form-control" disabled={spcmReducer.editService.status && spcmReducer.editService.status == 'Deployed' ? true : false} />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label bsClass=""><span className="rStar"></span>{translator['Scenario']}</Form.Label>
                    <Field component={_dropDown} name="SENARIO" options={scenarioOption} className="form-control" />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
              <Col md={6}>
                  <Form.Group >
                    <Form.Label bsClass=""><span className="rStar"></span>{translator['End Point']}</Form.Label>
                    <Field name="END_POINT" className="form-control" component={_inputField} onpaste={(e) => { this.countCharacters(e) }} onChange={(e) => { this.countCharacters(e) }} >
                    </Field>
                    <span className="f-size-10">{translator['Max Length in CB']}</span>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label bsClass=""><span className="rStar"></span>{translator['Request Method']}</Form.Label>
                    <Field component={_dropDown} name="REQUEST_METHOD" options={requestMethodOption} className="form-control" disabled/>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
              <Col md={6}>
                  <Form.Group >
                    <Form.Label bsClass=""><span className="rStar"></span>{translator['Headers']}</Form.Label>
                    <Field name="HEADER" className="form-control" component={_inputField} defaultValue="{Content-Type=application/json, Charset=UTF-8}"
                    value="{Content-Type=application/json, Charset=UTF-8}"
                    placeholder="{Content-Type=application/json, Charset=UTF-8}" disabled></Field>
                    <span className="f-size-10">{translator['Max Length in CB']}</span>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label bsClass=""><span className="rStar"></span>{translator['Authentication Method']}</Form.Label>
                    <Field component={_dropDown} name="AUTHENTICATION_NAME" options={authenticationOptions} className="form-control" disabled/>
                  </Form.Group>
                </Col>
                
              </Row>
              <Row>
                
              <Col md={6}>
                  <Form.Group >
                    <Form.Label bsClass=""><span className="rStar"></span>{translator['Username']}</Form.Label>
                    <Field name="USER_NAME" className="form-control" component={_inputField} onpaste={(e) => { this.countCharacters(e) }} onChange={(e) => { this.countCharacters(e) }} >
                    </Field>
                    <span className="f-size-10">{translator['Max Length in CB']}</span>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className='position-re eyepassdv'>
                    <Form.Label bsClass=""><span className="rStar"></span>{translator['Password']} <a role='button' title={this.state.passwordFieldType === 'pwd' ? "Show Password" : "Hide Password"} href="javascript:void(0)" onClick={() => this.setState({ passwordFieldType: this.state.passwordFieldType === 'pwd' ? 'text' : 'pwd' })} style={{'position':'absolute','top':'28px','right':'6px','padding':'0px 5px 2px',zIndex:"1"}}><i className={this.state.passwordFieldType === 'pwd' ? "fa fa-eye" : "fa fa-eye-slash"} aria-hidden="true"></i></a>
                     </Form.Label>
                   { this.state.passwordFieldType === 'pwd' ? <Field name="IDENS" className="form-control" component={_passwordField} onpaste={(e) => { this.countCharacters(e) }} onChange={(e) => { this.countCharacters(e) }} >
                    </Field> :
                    <Field name="IDENS" className="form-control" component={_inputField} onpaste={(e) => { this.countCharacters(e) }} onChange={(e) => { this.countCharacters(e) }} >
                    </Field>}
                   <span className="f-size-10">{translator['Max Length in CB']}</span>
                  </Form.Group>
                </Col>
              </Row>
  
              <Row>
              <Col md={6}>
                  <Form.Group >
                    <Form.Label bsClass=""><span className="rStar"></span>{translator['Criteria']}</Form.Label>
                    <Field name="CRITERIA" className="form-control" component={_inputField} onpaste={(e) => { this.countCharacters(e) }} onChange={(e) => { this.countCharacters(e) }} >
                    </Field>
                    <span className="f-size-10">{translator['Max Length in CB']}</span>
                  </Form.Group>
                </Col>
              </Row>
          </div>
        </div>
    )
    }
}

function mapStateToProps({ spcmReducer, getIntegrationHubData: { scenarioDropDownList, integrationHubSubmitError, integrationHubList, initialEditValues } }) {
  const { criteria,endpoint, headers, password, requestMethod, scenarioId, tenantId, tenantName, username} = initialEditValues
  return {
    initialValues: {
      "SENARIO":scenarioId,
      "END_POINT": endpoint,
      "REQUEST_METHOD": requestMethod,
      "COMPANY_NAME": tenantId,
      "HEADER": headers,
      "USER_NAME": username,
      "IDENS": password,
      "CRITERIA": criteria
    },
    integrationHubList,
    scenarioDropDownList,
    integrationHubSubmitError,
    spcmReducer
  };
}

const xsmIntegrationEditForm = reduxForm({
  form: 'xsmIntegrationEditForm',
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate
})(xsmIntegrationHubCreateForm);

export default connect(mapStateToProps, null)(xsmIntegrationEditForm);
