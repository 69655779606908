
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import _ from "lodash";
import Dropzone from "react-dropzone";
import { GLOBAL } from "../Globals";
import { connect } from "react-redux";
import ListGroup from "react-bootstrap/ListGroup";
import configureStore from "../../store/configureStore";
import { loadImplPlanList } from "../../actions/common/attachmentAction";
import swal from "sweetalert";
import Cookies from "universal-cookie";
import { fileobjectGenerator, validateFile } from "../common/MIMEObjGenerator";
import {IoClose} from "react-icons/io5";

const cookies = new Cookies();

let attachmentDetails = cookies.get("gph");
if (attachmentDetails) attachmentDetails = attachmentDetails.replace("s:", "");
if (attachmentDetails)
  attachmentDetails = attachmentDetails.substring(
    0,
    attachmentDetails.lastIndexOf(".")
  );
attachmentDetails = attachmentDetails.split("~");
const attachmentSize = attachmentDetails[21];
const attachmentTypeStr = attachmentDetails[22];
const attachmentType = fileobjectGenerator(attachmentDetails[22].split(', '));

class AttachmentTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filesPreview: [],
      filesToBeSent: [],
      clickAttachButton: 0,
      attach_IdUpdate: "",
    };
    this.removeFile = this.removeFile.bind(this);
  }

  removeFile(val) {
    const oldList = this.state.filesToBeSent;
    const oldFilePreviewList = this.state.filesPreview;
    // const newList = oldList.filter((item) => item.name !== e.target.id);
    const newList = oldList.filter((item) => item.name !== val);
    const newPreviewList = oldFilePreviewList.filter(
      // (item) => item.key !== e.target.id
      (item) => item.key !== val
    );
    this.setState({ filesToBeSent: newList, filesPreview: newPreviewList });
    GLOBAL.filesToBeSent = [...newList];
  }
  onDrop(acceptedFiles) {
    GLOBAL.filesToBeSent = [];
    let filesToBeSent = this.state.filesToBeSent;
    if (acceptedFiles.length > 0) {
      filesToBeSent = [...filesToBeSent, ...acceptedFiles];
      let filesPreview = [];
      for (let i in filesToBeSent) {
        if (filesToBeSent.hasOwnProperty(i)) {
          console.log("filesToBeSent[i].name",filesToBeSent[i].name.length)
        filesPreview.push(
          <ListGroup.Item bsPrefix=" " bsClass="" className="padding-b-10" key={filesToBeSent[i].name}>
            <a className="float-r"
              id={filesToBeSent[i].name}
              onClick={() => {
                // this.removeFile(e);
                this.removeFile(filesToBeSent[i].name);
              }}
            ><IoClose />
            </a>
            <span  title={filesToBeSent[i].name}>{filesToBeSent[i].name.length > 35 ? filesToBeSent[i].name.slice(0,35) + "..." : filesToBeSent[i].name}</span>
            {/* {filesToBeSent[i].name} */}
          </ListGroup.Item>
        );
      }}

      this.setState({ filesToBeSent, filesPreview });
      GLOBAL.filesToBeSent = filesToBeSent;
    }
  }

  onDropRejected(rejectedFiles) {
    if (rejectedFiles && rejectedFiles[0] && rejectedFiles[0].file && rejectedFiles[0].file.size && rejectedFiles[0].file.size > 1048576 * attachmentSize) {
      swal({
        text:
        //   this.props.translator["Please upload File up to "] +
        //   attachmentSize +
        //   this.props.translator[" MB size."],
        // button: this.props.translator["OK"],
          this.props.translator["Total file size should be less than "]  +
          attachmentSize +
          this.props.translator[" MB."],
        button: this.props.translator["OK"],
      });
    } else {
      swal({
        text: this.props.translator["File not supported."],
        button: this.props.translator["OK"],
      });
    }
  }

  render() {
    const style = this.props.isUloadingDisabled
      ? { pointerEvents: "none" }
      : { pointerEvents: "auto" };
    return (
      <div style={style}>
        <Dropzone
          multiple={true}
          onDrop={(files) => this.onDrop(files)}
          onDropRejected={(files) => this.onDropRejected(files)}
          className="form-control dropFileBtn f-size-11"
          maxSize={1048576 * attachmentSize}
          accept={attachmentType}
          validator={(file)=>{ return validateFile(file,this.props.translator);}}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p className="form-control dropFileBtn f-size-11">
                  {this.props.translator["Drop files or click here to upload"]}
                  {this.props.translator["(Total file size"]} {attachmentSize} {this.props.translator['MB)']}
                  <button type="button">
                    {this.props.translator["Browse"]}
                  </button>
                </p>
              </div>
            </section>
          )}
        </Dropzone>
        <span
          className="f-size-11"
          style={{ marginTop: "4px", display: "inline-block" }}
        >
          <i>
            (<b>{this.props.translator["Supported file types:"]}</b>
            {attachmentTypeStr})
          </i>
        </span>
        {this.state.filesPreview.length > 0 ? (
          <div className="margin-t-15">
            <div className="border-b padding-b-5 margin-b-10 black f-size-13 fw-400">
              {this.props.page == "myRequest"?
              this.state.filesPreview.length > 0 ?
              <b>{this.props.translator["File(s) attached"]}</b> 
               : (
                <b>{this.props.translator["File(s) not attached"]}</b> 
               )
              :
              this.state.filesPreview.length > 0 ?
             <b>{this.props.translator["file attached"]}</b> 
              : (
                this.props.translator["file not attached"]
              )
              }
            </div>
            <ListGroup bsPrefix=" " bsClass="" className="attachment-break">
              {this.state.filesPreview}
            </ListGroup>
          </div>
        ) : null}
      </div>
    );
  }
}

const style = {
  margin: 15,
};
const mapStateToProps = ({ implememtationPlanList }) => {
  return {
    attachmentDetails: implememtationPlanList,
  };
};
export default connect(mapStateToProps, { loadImplPlanList })(AttachmentTab);
