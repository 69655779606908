
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { navigationHooks } from '../../../helpers/NavigationHook.js';
import { reduxForm } from 'redux-form';
import { editRuleConfiguration } from '../../../actions/amsActions/amsActions.js';
import { connect } from 'react-redux';
import {GLOBAL} from '../../Globals.js';
import Swal from 'sweetalert2';
import { Button } from 'react-bootstrap';
import {LuFileText} from "react-icons/lu";
import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";


let AmsHeader = class AmsHeader extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			disabledSave: false
		};
		this.saveChange = this.saveChange.bind(this);
        this.changeSaveView= this.changeSaveView.bind(this);
		this.closeEditScreen = this.closeEditScreen.bind(this);
	}

	changeSaveView(value){
		this.setState({disabledSave: value});
	}

	saveChange(values) {
		this.setState({ disabledSave: true });

		const { attributesList = [] } = this.props;

		let amsAttributeList = [];
		if(!values.isContextual){
			amsAttributeList=attributesList.map(item => {
			let obj = {};
			obj['amsOperator'] = item['operatorName'];
			obj['amsOperatorId'] = item['operatorId'];
			obj['amsAttributeValue'] = item['attrValue'];
			if(item['attrName'].toLowerCase() == 'priority'){
				obj['amsAttributeValueId'] = item['attrValue'];
			}
			else{
				obj['amsAttributeValueId'] = item['attrValueId'];
			}
			obj['amsAttributeDisplayValue'] = item['attrName'];
			obj['amsAttributeDisplayId'] = item['attrId'];
			obj['amsJoinCondition'] = item['attrJoinCondition'];
			return obj;
		  });
	    }

		let submitData = {
			"ruleName": values.ruleName,
			"statusId": values.companyStatus,
			"statusValue": values.companyStatusName,
			"companyId": values.company,
			"companyName": values.companyName,
			"moduleId": values.module,
			"moduleName": values.moduleName,
			"weight": values.weight,
			"assignmentGroupId": values.isContextual === false ? values.group : 0,
			"assignmentGroupName": values.isContextual === false ? values.groupName : values.contextualGroup,
			"assignmentCompanyId": values.associatedCompany,
			"assignmentCompanyName": values.associatedCompanyName,
			"modifiedByName": "",
			"modifiedOn": 0,
			"isExternal": GLOBAL.isExternalAmsEdit,
			"amsAttribute": amsAttributeList,
			"modifiedById": "",
			"eventId": values.event, 
			"eventName": values.eventName,
			"isContextual": values.isContextual,
			"groupType": values.isContextual?values.contextualGroupFunctionName:"",
			"groupTypeId": values.isContextual?values.groupTypeId:"0",
			"alternateGroupId": values['alternateGroup'] || "0",
			"alternateGroupName": values['alternateGroupName'] || ""
		}

		this.props.editRuleConfiguration(values.ruleId, submitData, this.changeSaveView, this.props.updateIsChange);
		
	}

	closeEditScreen() {
		if (this.props.isChange == true) {
			Swal.fire({
				title: this.props.tr["This would not save any changes that you might have made to the Assignment rule and the attributes. Do you want to save the changes?"],
				showDenyButton: true,
				confirmButtonText: 'Yes',
				denyButtonText: `Don't save`,
			  }).then((result) => {
				if (result.isConfirmed) {
				//   Swal.fire('Saved!')
				} else if (result.isDenied) {
					// navigationHooks.navigate('/amsHome');
					navigationHooks.navigate('/rulesList');
				}
			  })
		}
		else {
			// navigationHooks.navigate('/amsHome');
			navigationHooks.navigate('/rulesList');
		}
	}

	render() {
		const { handleSubmit, pristine, reset, submitting, tr } = this.props;
		return (
			<Row className="margin-b-15">
				<Col md={7} sm={7}>
					<h2 bsClass="" className="sPageHeading1">{tr['Assignment Rules']}</h2>
				</Col>
				<Col md={5} sm={5}>
					<div className="float-r toprigLnk">
						<Nav className="icnlnk riggep float-l d-md-none" as="ul">              
							<Nav.Item
								title={tr["Specify Attribute Values"]}
								as="li"
							>
								<a href="javascript:void(0)"
								onClick={() => {
									this.props.rightEditPanel(true);
								}}
								className="myBt fillBtn"
								>
								<LuFileText/>
								</a>
							</Nav.Item>
						</Nav>

						<Nav className="icnlnk" as="ul">
						<Nav.Item bsPrefix=" " as="li" eventKey={1}>
							<Link
							className='myBt plus' disabled={this.state.disabledSave} title={tr['Save']} onClick={handleSubmit(this.saveChange)}
							>
							<span>{this.state.disabledSave? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/>}</span>
							</Link>
						</Nav.Item>
						<Nav.Item as="li">
							<Link className="myBt cancel fillBtn" role='button' href={void(0)} title={tr['Close']} onClick={() => this.closeEditScreen()}>
							<span><IoClose/></span>
							</Link>
						</Nav.Item>
						</Nav>
					</div>
				</Col>
			</Row>
		);
	}
}

AmsHeader = reduxForm({
	form: 'xsmAmsEditRulesForm'
})(AmsHeader);

export default connect(null, { editRuleConfiguration })(AmsHeader);
