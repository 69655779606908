
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import OfferingDetails from "./rightSide/OfferingDetails";
import RightSidePart from "./leftSide/LeftSidePart.js";
import { connect } from "react-redux";
import _ from "lodash";
import PindCategories from '../../common/header/pind-categories.js';
import "_Css/consumer/_offeringDetails.scss";
import Cookies from 'universal-cookie';

const cookies = new Cookies();
let homepagelocation = cookies.get('gph');
if (homepagelocation) homepagelocation = homepagelocation.replace('s:', '');
if (homepagelocation) homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home")
  homepagelocation = "/" + homepagelocation;

import {
  resetStateInStore,
  setSelectedComponentForOffering,
} from "../../../actions/offeringDetails/offeringDetailsActions";
import {getAttachmentsPaginationAction,getAttachmentsAction } from '../../../actions/spcmActions';

import { navigationHooks } from "../../../helpers/NavigationHook";
import { useLocation, useParams } from "react-router";
import { GLOBAL } from "../../Globals";
import XSMScrollToTop from "../../common/XSMScrollToTop";

const OfferingDetailsIndex = () => {
  const { offeringId } = useParams();

  return (
    <>
    <OfferingDetailsMain offeringId={offeringId} />
    </>
  )
}

export default OfferingDetailsIndex

class OfferingDetailsMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenu: "0",
      showHideAlfy: false,
      showHideRightNav: false,
      selectedComponentForOffering: [],
      offeringDetails: true,
      offeringName: "",
      otherActionbtnValue: "Offering Details",
      rightSectionDetails: {},
    };
    this.showHideAlfy = this.showHideAlfy.bind(this);
    this.showHideRightNav = this.showHideRightNav.bind(this);
    this.otherActionbtnFun = this.otherActionbtnFun.bind(this);
    this.setOfferingComponentsForCart = this.setOfferingComponentsForCart.bind(
      this
    );
    this.loadHome = this.loadHome.bind(this);
  }

  componentWillUnmount() {
    this.props.resetStateInStore("service_components");
    this.props.resetStateInStore("offering_details");
  }

  componentDidMount() {
    // this.props.getAttachmentsPaginationAction(this.props.offeringId,"desc",1,10)
    // this.props.getAttachmentsAction(this.props.offeringId);
    this.setState({
      selectedComponentForOffering: this.props.selectedComponentForOffering,
    });
  }

  showHideAlfy(value) {
    this.setState({ showHideAlfy: value });
  }

  showHideRightNav(value) {
    this.setState({ showHideRightNav: !this.state.showHideRightNav });
  }

  setOfferingComponentsForCart(component, event) {
    let { selectedComponentForOffering } = this.state;
    if (event === "core_component") {
      if (!_.some(selectedComponentForOffering, ["id", component.id])) {
        selectedComponentForOffering.push(component);
      }
    } else {
      if (event.target.checked) {
        if (!_.some(selectedComponentForOffering, ["id", component.id])) {
          selectedComponentForOffering.push(component);
          selectedComponentForOffering = selectedComponentForOffering.filter(
            (existingComponent) => {
              return existingComponent.id;
            }
          );
        }
      } else {
        if (_.some(selectedComponentForOffering, ["id", component.id])) {
          selectedComponentForOffering = selectedComponentForOffering.filter(
            (existingComponent) => {
              return existingComponent.id !== component.id;
            }
          );
        }
      }
    }

    this.setState({ selectedComponentForOffering });
    this.props.setSelectedComponentForOffering(selectedComponentForOffering);
  }

  otherActionbtnFun(otherActionbtnValue, rightSectionDetails) {
    if (otherActionbtnValue == "Offering Details") {
      this.setState({ offeringDetails: true });
      this.setState({ otherActionbtnValue });
    } else {
      this.setState({ offeringDetails: false });
      this.setState({ otherActionbtnValue });
      this.setState({ rightSectionDetails });
      this.setState({ offeringName: rightSectionDetails });
    }
  }

  loadHome() {
    navigationHooks.navigate(homepagelocation);
  }

  render() {
    return (
      <main id="consumerSection">

        {/* <div className="gloSearchDiv"><SxSearch tr={this.props.tr} /></div> */}

        <div className="minHeight">
          <PindCategories />


          {/*<RightSidebarMenulist showhideClass={this.state.showHideRightNav} pageId={'24'} />*/}
          <div className="container-fluid margin-b-15">
          <nav aria-label="Breadcrumb"><ul className="breadcrumb"><li className="breadcrumb-item"><a href="javascript:void(0)" role="button" onClick={this.loadHome} >{this.props.tr['Home']}</a></li><li className="breadcrumb-item active">{this.props.tr['Offering Details']}</li></ul></nav>
          </div>
          <div className="container-fluid" bsClass="">
            {/*<InnerCategorySearch catname={this.props.params.catname} subcatname={this.props.params.subcatname}
          tr={this.props.tr} />*/}

            {/* <div id="consumerSection">
        <RightSidebarMenulist
          showhideClass={this.state.showHideRightNav}
          pageId={"24"}
        />
        <Grid className="midAll" bsClass="">
          <InnerCategorySearch
            catname={this.props.params.catname}
            subcatname={this.props.params.subcatname}
            tr={this.props.tr}
          /> */}

            <Row>
              <Col lg={3} md={4} sm={4} xs={12} className="hidden-xs">
                <RightSidePart
                  offeringId={this.props.offeringId}
                  selectedComponentForOffering={
                    this.props.selectedComponentForOffering
                  }
                  otherActionbtnValue={this.state.otherActionbtnValue}
                  rightSectionDetails={this.state.rightSectionDetails}
                  translator={this.props.tr}
                  offeringName={this.state.offeringName}
                />
              </Col>
              <Col lg={9} md={8} sm={8} xs={12}>
                <OfferingDetails
                  offeringId={this.props.offeringId}
                  setOfferingComponentsForCart={this.setOfferingComponentsForCart}
                  otherActionbtnFun={this.otherActionbtnFun}
                  selectedComponentForOffering={
                    this.props.selectedComponentForOffering
                  }
                  offeringDetailsState={this.state.offeringDetails}
                  translator={this.props.tr}
                  test={this.props.test}
                  rightSectionDetails={this.state.rightSectionDetails}
                  offeringName={this.state.offeringName}
                />
              </Col>
            </Row>
          </div>
        </div>
        <XSMScrollToTop 
         translator={this.props.tr}
        />
      </main>
    );
  }
}

const mapStateToProps = ({ spcmReducer, selectedComponentForOffering }) => {
  return {
    tr: spcmReducer.tr,
    selectedComponentForOffering,
  };
};

OfferingDetailsMain = connect(mapStateToProps, {
  resetStateInStore,
  setSelectedComponentForOffering,getAttachmentsPaginationAction,getAttachmentsAction
})(OfferingDetailsMain)
