
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import { GLOBAL }  from '../../components/Globals';
import Cookies from "universal-cookie";
const cookies = new Cookies();
let languageSelected = cookies.get("gph");
languageSelected = languageSelected.split("~");
languageSelected = languageSelected[20];
//below URL used for testing purpose
//const breakFixUrl = 'https://xsm-f.mybluemix.net/api/SX_FAVOURITEOFFERINGs/';

//let breakFixUrl=`https://xsm-f.mybluemix.net/groupListSelfService?companyId=2932&group=ResolvedER`

export function loadBreakFixReasonCodeSuccess(reasonCode){
	return {
		type: 'LOAD_BREAKFIXREASONCODE_SUCCESS',
		reasonCode
	};
}
export function loadBreakFixComplexitySuccess(complexity){
	return {
		type: 'LOAD_BREAKFIXCOMPLEXITY_SUCCESS',
		complexity
	};
}

export function loadBreakFixReasonCode(moduleName){
  // let moduleName='Breakfix';
	let fieldName='HoldingReason';
	return(dispatch) =>{

				axios.get(GLOBAL.loadReasonCodeUrl + "/module/" + moduleName + "/field/" + fieldName)
					.then((reasonCode)=>{
						if(!reasonCode){
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							throw Error(reasonCode.statusText);
						}
						return reasonCode;
					})
					.then((reasonCode) =>{
						dispatch(loadBreakFixReasonCodeSuccess(reasonCode))
					})
					.catch((err) => {
						console.log(err);
					});
	};
}
export function loadFulfillmentReasonCode(companyId,modules,fields,status){
	return (dispatch)=>{
		axios.get(`/api/company_menulist?companyId=${companyId}&module=${modules}&field=${fields}&language=${languageSelected}&status=${status}`).then(reterivedReasons => {
			dispatch(loadBreakFixReasonCodeSuccess(reterivedReasons));
		});
	};
}

export function loadBreakFixComplexity(moduleName){
  // let moduleName='Breakfix';
	let fieldName='Complexity';
	return(dispatch) =>{

				axios.get(GLOBAL.loadReasonCodeUrl + "/module/" + moduleName + "/field/" + fieldName)
					.then((complexity)=>{
						if(!complexity){
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							throw Error(complexity.statusText);
						}
						return complexity;
					})
					.then((complexity) =>{
						dispatch(loadBreakFixComplexitySuccess(complexity))
					})
					.catch((err) => {
						console.log(err);
					});
	};
}
