
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import moment from 'moment';
import OnClickOutside from 'react-onclickoutside';
import { Field, reduxForm, change, formValueSelector, getFormValues } from 'redux-form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';
import { connect } from 'react-redux';
import axios from 'axios';
import _ from 'lodash';
import { GLOBAL } from "_Globals";
import Cookies from 'universal-cookie';
import {ImInfo} from 'react-icons/im';


import GenericTimeline from './genericTimeline';
import CIDetailsService from './ciNameSelectEdit.js';
import RFReactConsumer from './searchConsumer.js';
import DialogForm from './conflictDialogForm.js';
import ListLoader from '../../../common/loaders/ListLoader';


import { loadNewBreakFixSGAE, loadNewBreakFixSGAEWithQueryParams, loadNewBreakFixSGAEChangeWithParams} from '../../../../actions/breakFix/serviceGroupAEAction';
import { loadNewBreakFixSGAEChange } from '../../../../actions/breakFix/serviceGroupAEChangeAction';
import { loadChangeEditData, changeFieldStatusData, getLovValues, getRiskCalculated } from '../../../../actions/changeManagement/changeManagementAction';
import { loadBreakFixSGI, loadChangeManagementIndividual, loadChangeManagementImplGroupIndividual } from '../../../../actions/breakFix/serviceGroupIAction';
import { loadImplPlanList } from '../../../../actions/common/attachmentAction';
import { getBreakfixGroupData, getAllgroup } from '../../../../actions/amsActions/amsActions';
import { getChangeQuestionsCategoryWise } from '../../../../actions/cart/myOrders/myOrdersAction';


import { _inputField, _textArea, _dropDown, _dateField,_latentField, _dateTimeField, _latentDateTimeField, _dropDown2, _dropDown1 } from '../../../common/ReduxFormFields/formFields';
import { TypeaheadExampleSingleSelect } from '../../../common/formFields';
import datetimeConvertor from '../../../../ISO8601converter';
import configureStore from '../../../../store/configureStore';

import RiskAssessmentForm from '../../../common/WorkItemBoard/RiskAssessment';
import { RuleAmsDdropDown } from "../../../common/forms/ruleDropDown";
import { RiUserSearchLine } from 'react-icons/ri';

const cookies = new Cookies();
let homepagelocation = cookies.get('gph');
if (homepagelocation) homepagelocation = homepagelocation.replace('s:', '');
if (homepagelocation) homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
homepagelocation = homepagelocation.split("~");
const LEAD_TIME = homepagelocation[49];

let dateformat = cookies.get('gph');
if (dateformat) dateformat = dateformat.replace('s:', '');
if (dateformat) dateformat = dateformat.substring(0, dateformat.lastIndexOf('.'));
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let timezone = cookies.get('gph');
if (timezone) timezone = timezone.replace('s:', '');
if (timezone) timezone = timezone.substring(0, timezone.lastIndexOf('.'));
timezone = timezone.split("~");
timezone = timezone[3];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

const store = configureStore();
const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});
let ciNameDataDetails = [];
let consumerData = [];
let consumerDataDetails = [];
let myObj = [];
let impactedServiceData = [];
let impactedServiceDataDetails = [];
let myObj0 = [];
window.moment = (moment) ? moment : null;

const validate = (valuesObj, props) => {
	let date_format = props.date_format;
	date_format = date_format.substring(0, 10) + " HH:mm:ss";
	let excludeSecondFormat = date_format.substring(0, 10) + ' HH:mm';
	let expectedStartDateValid = '';
	let expectedEndDateValid = '';
	let startDateValid = '';
	let endDateValid = '';
	var values = JSON.stringify(valuesObj);
	values = JSON.parse(values);
	if (typeof props.expectedStartDateVal == 'object') {
		let a = props.expectedStartDateVal._d;
		expectedStartDateValid = moment(a).format(date_format);
	} else {
		expectedStartDateValid = props.expectedStartDateVal;
	}
	if (typeof props.expectedEndDateVal == 'object') {
		let a = props.expectedEndDateVal._d;
		expectedEndDateValid = moment(a).format(date_format);
	} else {
		expectedEndDateValid = props.expectedEndDateVal;
	}
	if (props.startDateVal == null || props.startDateVal == 'undefined') {
		startDateValid = '';
	} else {
		if (typeof props.startDateVal == 'object') {
			let a = props.startDateVal._d;
			startDateValid = moment(a).format(date_format);
		} else {
			startDateValid = props.startDateVal;
		}
	}
	if (props.endDateVal == null || props.endDateVal == 'undefined') {
		endDateValid = '';
	} else {
		if (typeof props.endDateVal == 'object') {
			let a = props.endDateVal._d;
			endDateValid = moment(a).format(date_format);
		} else {
			endDateValid = props.endDateVal;
		}
	}
	if (values.actualStartDate) {
		values.actualStartDate = moment(values.actualStartDate).format('MM-DD-YYYY HH:mm');
	}
	if (values.actualEndDate) {
		values.actualEndDate = moment(values.actualEndDate).format('MM-DD-YYYY HH:mm');
	}
	if (values.lStartDate) {
		values.lStartDate = moment(values.lStartDate).format('MM-DD-YYYY HH:mm');
	}
	if (values.lEndDate) {
		values.lEndDate = moment(values.lEndDate).format('MM-DD-YYYY HH:mm');
	}
	let errors = {};
	if (!values.type) {
		errors.type = 'select type.';
	}
	if (!values.summary || values.summary.trim().length === 0) {
		errors.summary = 'enter summary.';
	}
	if (!values.description || values.description.trim().length === 0) {
		errors.description = 'enter description.';
	}
	// if(!(values.type && values.type == '35')){
	// 	if (!values.changeTiming || values.changeTiming == "0") {
	// 		errors.changeTiming = 'select changeTiming.';
	// 	}
	// 	if (values.overallTechnicalRisk == "0") {
	// 		errors.overallTechnicalRisk = 'select overall technical risk.';
	// 	}
	// 	if (values.overallBusinessRisk == "0") {
	// 		errors.overallBusinessRisk = 'select overall business risk.';
	// 	}
	// 	if (!values.overallTechnicalRisk) {
	// 		errors.overallTechnicalRisk = 'select overall technical risk.';
	// 	}
	// 	if (!values.overallBusinessRisk) {
	// 		errors.overallBusinessRisk = 'select overall business risk.';
	// 	}
	// }
	// if (!values.changeTiming || values.changeTiming == "0") {
	// 	errors.changeTiming = 'select changeTiming.';
	// }
	// if (values.overallTechnicalRisk == "0") {
	// 	errors.overallTechnicalRisk = 'select overall technical risk.';
	// }
	// if (values.overallBusinessRisk == "0") {
	// 	errors.overallBusinessRisk = 'select overall business risk.';
	// }
	// if (!values.overallTechnicalRisk) {
	// 	errors.overallTechnicalRisk = 'select overall technical risk.';
	// }
	// if (!values.overallBusinessRisk) {
	// 	errors.overallBusinessRisk = 'select overall business risk.';
	// }
	if (!values.urgency || values.urgency == "0") {
		errors.urgency = 'select urgency.';
	}
	if (!values.reasonForChange || values.reasonForChange == "0") {
		errors.reasonForChange = 'select reason for change.';
	}
	if (!values.cmdbUpdateNeeded) {
		errors.cmdbUpdateNeeded = 'select cmdb update needed.';
	}

	// if(values.type && values.type == '35'){
	// 	if((values.riskOccurrenceProbability && values.riskOccurrenceProbability != '0') || (values.riskImpact && values.riskImpact != '0') || (values.controlEffectiveness && values.controlEffectiveness != '0')){
	// 		if(!values.riskOccurrenceProbability||values.riskOccurrenceProbability=='0')
	// 		{
	// 			errors.riskOccurrenceProbability = 'select risk occurrence probability.';
	// 		}
	// 		if(!values.riskImpact||values.riskImpact=='0')
	// 		{
	// 			errors.riskImpact = 'select risk impact.';
	// 		}
	// 		if(!values.controlEffectiveness||values.controlEffectiveness=='0')
	// 		{
	// 			errors.controlEffectiveness = 'select control effectiveness.';
	// 		}
	// 	}
	// 	else{
	// 		errors.riskOccurrenceProbability = '';
	// 		errors.riskImpact = '';
	// 		errors.controlEffectiveness = '';
	// 	}
	// }
	// else{
	// 	if(!values.riskOccurrenceProbability||values.riskOccurrenceProbability=='0')
	// 	{
	// 		errors.riskOccurrenceProbability = 'select risk occurrence probability.';
	// 	}
	// 	else{
	// 		errors.riskOccurrenceProbability = '';
	// 	}
	// 	if(!values.riskImpact||values.riskImpact=='0')
	// 	{
	// 		errors.riskImpact = 'select risk impact.';
	// 	}
	// 	else{
	// 		errors.riskImpact = '';
	// 	}
	// 	if(!values.controlEffectiveness||values.controlEffectiveness=='0')
	// 	{
	// 		errors.controlEffectiveness = 'select control effectiveness.';
	// 	}
	// 	else{
	// 		errors.controlEffectiveness = '';
	// 	}
	// }

	// if (!values.riskOccurrenceProbability || values.riskOccurrenceProbability == "null" || values.riskOccurrenceProbability==='') {
	// 	errors.riskOccurrenceProbability = 'select risk occurrence probability.';
	// }
	// if (!values.riskImpact || values.riskImpact == "null" || values.riskImpact==='') {
	// 	errors.riskImpact = 'select risk impact.';
	// }
	// if (!values.controlEffectiveness || values.controlEffectiveness == "null" || values.controlEffectiveness==='') {
	// 	errors.controlEffectiveness = 'select control effectiveness.';
	// }

	if(!(values.type && values.type == '35')){
		if (props.fieldStatus.schedule!==false && !values.expectedStartDate && !values.expectedEndDate) {
			errors.expectedStartDate = 'ticket has been replanned, please provide Scheduled Start and End date.';
			errors.expectedEndDate = ' '
		} else {
			if (props.fieldStatus.schedule!==false && !values.expectedEndDate) {
				errors.expectedEndDate = 'provide Scheduled End Date for replanned ticket';
			}
			if (props.fieldStatus.schedule!==false && !values.expectedStartDate) {
				errors.expectedStartDate = 'provide Scheduled Start Date for replanned ticket';
			}
		}
	}

	// if (props.fieldStatus.schedule!==false && !values.expectedStartDate && !values.expectedEndDate) {
	// 	errors.expectedStartDate = 'ticket has been replanned, please provide Scheduled Start and End date.';
	// 	errors.expectedEndDate = ' '
	// } else {
	// 	if (props.fieldStatus.schedule!==false && !values.expectedEndDate) {
	// 		errors.expectedEndDate = 'provide Scheduled End Date for replanned ticket';
	// 	}
	// 	if (props.fieldStatus.schedule!==false && !values.expectedStartDate) {
	// 		errors.expectedStartDate = 'provide Scheduled Start Date for replanned ticket';
	// 	}
	// }
	if (!values.actualStartDate) {
		errors.actualStartDate = ' ';
	}
	if (!values.actualEndDate) {
		errors.actualEndDate = ' ';
	}

	// console.log('expectedStartDateValid, expectedEndDateValid', {expectedStartDateValid, expectedEndDateValid});

	

	if (props.fieldStatus.schedule !== false && (expectedStartDateValid || expectedEndDateValid) && props.editChangeInitialData.status != "Under PIR") {
		if(values.type && values.type == '35'){
			if (moment.tz(expectedStartDateValid, date_format, timezone).isAfter(moment().tz(timezone))) {
				if (props.selectedType !== '30' && props.editChangeInitialData.status === 'Draft') {
					errors.expectedStartDate = 'Scheduled Start Date should not be greater than current date.';
				}
			}
			if (moment.tz(expectedEndDateValid, date_format, timezone).isAfter(moment(moment().tz(timezone)))) {
				if (props.selectedType !== '30' && props.editChangeInitialData.status === 'Draft') {
					errors.expectedEndDate = 'Scheduled End Date should not be greater than current date.';
				}
			}
		}
		else{
			if (moment.tz(expectedStartDateValid, date_format, timezone).isBefore(moment(moment().tz(timezone)))) {
				if (props.selectedType !== '30' && props.editChangeInitialData.status === 'Draft') {
					errors.expectedStartDate = 'Scheduled Start Date should be atleast today\'s date.';
				}
			}
			if (moment.tz(expectedEndDateValid, date_format, timezone).isBefore(moment(moment().tz(timezone)))) {
				if (props.selectedType !== '30' && props.editChangeInitialData.status === 'Draft') {
					errors.expectedEndDate = 'Scheduled End Date should be atleast today\'s date.';
				}
			}
		}
		// if (moment(expectedStartDateValid, date_format) < moment(moment(), date_format)) {
		// 	if (props.selectedType !== '30' && props.editChangeInitialData.status === 'Draft') {
		// 		errors.expectedStartDate = 'Scheduled Start Date should be atleast today\'s date.';
		// 	}
		// }
		// if (moment(expectedEndDateValid, date_format) < moment(moment(), date_format)) {
		// 	if (props.selectedType !== '30' && props.editChangeInitialData.status === 'Draft') {
		// 		errors.expectedEndDate = 'Scheduled End Date should be atleast today\'s date.';
		// 	}
		// }
		if (moment.tz(expectedStartDateValid, date_format, timezone).isAfter(moment.tz(expectedEndDateValid, date_format, timezone))) {
			errors.expectedStartDate = 'Scheduled start date should be same or fall before end date.';
		}

	}

	if(!expectedStartDateValid){
		errors.expectedStartDate = 'Please select Scheduled Start Date';
	}
	if(!expectedEndDateValid){
		errors.expectedEndDate = 'Please select Scheduled End Date';
	}

	// if (props.fieldStatus.schedule !== false && (expectedStartDateValid || expectedEndDateValid)) {
	// 	if ((moment(expectedStartDateValid, date_format) < moment(moment(), date_format)) && props.editChangeInitialData.status != "Under PIR") {
	// 		if (props.selectedType !== '30' && props.editChangeInitialData.status === 'Draft') {
	// 			errors.expectedStartDate = 'Scheduled Start Date should be atleast today\'s date.';
	// 		}
	// 	}
	// 	if ((moment(expectedEndDateValid, date_format) < moment(moment(), date_format))&&props.editChangeInitialData.status!="Under PIR") {
	// 		if (props.selectedType !== '30' && props.editChangeInitialData.status === 'Draft') {
	// 			errors.expectedEndDate = 'Scheduled End Date should be atleast today\'s date.';
	// 		}
	// 	}
	// 	if ((moment(expectedStartDateValid, date_format) > moment(expectedEndDateValid, date_format))&&props.editChangeInitialData.status!="Under PIR") {
	// 		errors.expectedStartDate = 'Scheduled start date should be same or fall before end date.';
	// 	}

	// }
	if (values.actualStartDate || values.actualEndDate) {
		if (moment(values.actualStartDate) > moment().add(-1, 'days')) {
			errors.actualStartDate = 'Actual Start Date should not be greater than Yersterday\'s date.';
		}
		if (moment(values.actualEndDate) > moment().add(-1, 'days')) {
			errors.actualEndDate = 'Actual End Date should not be greater than Yersterday\'s date.';
		}
		if (moment(values.actualStartDate).isAfter(values.actualEndDate)) {
			errors.actualStartDate = 'actual start date should be same or fall before end date.';
		}
	}
	if (values.downtimeRad == "Y") {

		if (!values.startDate) {
			errors.startDate = ' ';
		}
		if (!values.endDate) {
			errors.endDate = ' ';
		}
		if (!values.lStartDate) {
			errors.lStartDate = ' ';
		}
		if (!values.lEndDate) {
			errors.lEndDate = ' ';
		}
		if (props.fieldStatus.downtime !== false && values.changeTiming !== '20' && (values.startDate || values.endDate)) {
			function replaceSeconds(timeStamp, timeType) {
				let dateArray = [];
				for (let i = 0; i < timeStamp.length; i++) {
					dateArray.push(timeStamp[i]);
				}
				if (timeType === 'startTime') {
					dateArray[17] = '0';
					dateArray[18] = '0';
				} else {
					dateArray[17] = '5';
					dateArray[18] = '9';
				}
				return dateArray.join('');
			}
		
			let downtimeStartDate, downtimeEndTime, expectedStartDate, expectedEndDate;
		
			if (values.startDate === props.editChangeInitialData.startDate) {
				downtimeStartDate = datetimeConvertor(values.startDate, date_format);
			} else {
				downtimeStartDate = moment(values.startDate).format('YYYY-MM-DD HH:mm:ss');
			}
			downtimeStartDate = replaceSeconds(downtimeStartDate, 'startTime');
			downtimeStartDate = moment.tz(downtimeStartDate, 'YYYY-MM-DD HH:mm:ss', timezone).format(date_format);
		
			if (values.endDate === props.editChangeInitialData.endDate) {
				downtimeEndTime = datetimeConvertor(values.endDate, date_format);
			} else {
				downtimeEndTime = moment(values.endDate).format('YYYY-MM-DD HH:mm:ss');
			}
			downtimeEndTime = replaceSeconds(downtimeEndTime, 'endTime');
			downtimeEndTime = moment.tz(downtimeEndTime, 'YYYY-MM-DD HH:mm:ss', timezone).format(date_format);
		
			if (values.expectedStartDate === props.editChangeInitialData.expectedStartDate) {
				expectedStartDate = datetimeConvertor(values.expectedStartDate, date_format);
			} else {
				expectedStartDate = moment(values.expectedStartDate).format('YYYY-MM-DD HH:mm:ss');
			}
			expectedStartDate = replaceSeconds(expectedStartDate, 'startTime');
			expectedStartDate = moment.tz(expectedStartDate, 'YYYY-MM-DD HH:mm:ss', timezone).format(date_format);
		
			if (values.expectedEndDate === props.editChangeInitialData.expectedEndDate) {
				expectedEndDate = datetimeConvertor(values.expectedEndDate, date_format);
			} else {
				expectedEndDate = moment(values.expectedEndDate).format('YYYY-MM-DD HH:mm:ss');
			}
			expectedEndDate = replaceSeconds(expectedEndDate, 'endTime');
			expectedEndDate = moment.tz(expectedEndDate, 'YYYY-MM-DD HH:mm:ss', timezone).format(date_format);
			if (
				moment.tz(downtimeStartDate, date_format, timezone).isBetween(expectedStartDate, expectedEndDate, null, '[]') &&
				moment.tz(downtimeEndTime, date_format, timezone).isBetween(expectedStartDate, expectedEndDate, null, '[]')
			) {
			} else {
				
				if (moment.tz(downtimeStartDate, date_format, timezone).isBefore(moment.tz(expectedStartDate, date_format, timezone)) ||
					moment.tz(downtimeStartDate, date_format, timezone).isAfter(moment.tz(expectedEndDate, date_format, timezone))) {
					errors.startDate = 'Downtime Start Date should be same or between Scheduled start and end date.';
				}
				if (moment.tz(downtimeEndTime, date_format, timezone).isBefore(moment.tz(expectedStartDate, date_format, timezone)) ||
					moment.tz(downtimeEndTime, date_format, timezone).isAfter(moment.tz(expectedEndDate, date_format, timezone))) {
					errors.endDate = 'Downtime End Date should be same or between Scheduled start and end date.';
				}
				if (moment.tz(downtimeStartDate, date_format, timezone).isAfter(moment.tz(downtimeEndTime, date_format, timezone))) {
					errors.startDate = 'Downtime start date should be same or fall before end date.';
				}
			}
		}
		if (props.fieldStatus.downtime!==false && values.changeTiming == '20' && (values.lStartDate || values.lEndDate)) {
			if (moment.tz(values.lStartDate,date_format,timezone).isAfter(moment().tz(timezone).subtract(1, 'days'))) {
				errors.lStartDate = 'Downtime Start Date should not be greater than Yersterday\'s date.';
			}
			if (moment.tz(values.lEndDate,date_format,timezone).isAfter(moment().tz(timezone).subtract(1, 'days'))) {
				errors.lEndDate = 'Downtime End Date should not be greater than Yersterday\'s date.';
			}
			if (moment.tz(values.lStartDate,date_format,timezone).isBefore(moment.tz(values.actualStartDate,date_format,timezone))) {
				errors.lStartDate = 'Downtime should be same or between actual start and end date.';
			}
			if (moment.tz(values.lEndDate,date_format,timezone).isAfter(moment.tz(values.actualEndDate,date_format,timezone))) {
				errors.lEndDate = 'Downtime should be same or between actual start and end date.';
			}

			if (moment.tz(values.lStartDate,date_format,timezone).isAfter(moment.tz(values.lEndDate,date_format,timezone))) {
				errors.lStartDate = 'Start date be same or should fall before end date.';
			}
		}
	}
	if(props.editChangeInitialData.statusId!=='30'){
		if(values.implementationGroup===0 || values.implementationGroup==='0'){
			props.seTypeaheadValidation(false,'implementationGroup');
		}else if (!values.implementationGroup || values.implementationGroup===null || !values.impGroupname || values.impGroupname===null) {
			props.seTypeaheadValidation(true,'implementationGroup');
		}else{
			props.seTypeaheadValidation(false,'implementationGroup');
		}
		if(values.changeManagementGroup===0 || values.changeManagementGroup==='0'){
			props.seTypeaheadValidation(false,'changeManagementGroup');
		}else if (!values.changeManagementGroup || values.changeManagementGroup===null || !values.changeGroupname || values.changeGroupname===null) {
			props.seTypeaheadValidation(true,'changeManagementGroup');
		}else{
			props.seTypeaheadValidation(false,'changeManagementGroup');
		}
		}
	if((props.roleIdentification("19")||props.roleIdentification("20")) &&
	   (moment.tz(expectedStartDateValid,date_format,timezone).isBefore(moment().tz(timezone), date_format,timezone)) &&
	   (moment.tz(expectedEndDateValid,date_format,timezone).isAfter(moment().tz(timezone), date_format,timezone)) &&
	   (props.editChangeInitialData.status==='Scheduled')){
		errors.expectedStartDate='';
		errors.expectedEndDate='';
		errors.lStartDate='';
		errors.startDate='';
		errors.lEndDate='';
		errors.endDate='';
	}
	errors.cabDate = '';
	let cabDateError = props.validateCABDate({ statusId: values.statusId, type: values.type, cabDate: values.cabDate, expectedStartDate: values.expectedStartDate });
	if(cabDateError){
		errors.cabDate = cabDateError;
	}
	return errors;
}

const summaryMaxChar = 500;
let ChangeEditForm = class ChangeEditForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedOption: 'N',
			downTimeDateDisabled: true,
			startDate: '',
			endDate: '',
			showChangeTiming: false,
			downTimeLanding: true,
			companyId: '',
			expectedStartDate: '',
			expectedEndDate: '',
			AllAMSGrps: false,
			AllAMSGrpsChangeManagement: false,
			ChangeManagementAMSgrp: false,
			AllImplmtAMSGrps: false,
			AllAMSImplmtGrps: false,
			ImplemtAMSgrp: false,
			requestorlistCreationcount: 0,
			selectedImpgroups:[],
			selectedImpinds:[],
			selectedChgroups:[],
			selectedChginds:[],
			typedGroupI: '',
			typedIndividualI: '',
			typedGroupC: '',
			typedIndividualC: '',
			requestorValue: '',
			showRequestorInfo: false,
			impIndividualValue: '',
			showImpIndividualInfo: false,
			changeIndividualValue: '',
			showChangeIndividualInfo: false,
			cabDate: '',
			isCiNameInfoIcn:true,
			impactedIDSelected: false,
      		summaryCharCountLeft: summaryMaxChar,
			changeType: '',
			errorRequestor: '',
			errorImpactedCI: '',
			rulesAttribute: {},
		};
		GLOBAL.ciNameEdit = ""; GLOBAL.ciIdEdit = ""; GLOBAL.ciIdNumberEdit = "";
		this.onServiceSelection = this.onServiceSelection.bind(this);
		this.disableEnableDate = this.disableEnableDate.bind(this);
		this.setSelectedOption = this.setSelectedOption.bind(this);
		this.AllGrps = this.AllGrps.bind(this);
		this.AllimplmtGrps = this.AllimplmtGrps.bind(this);
		this.onChangeTiming = this.onChangeTiming.bind(this);
		this.requestorCreation = this.requestorCreation.bind(this);
		this.AllAMSGrps = this.AllAMSGrps.bind(this);
		this.ImplmtAMSGrp = this.ImplmtAMSGrp.bind(this);
		this.check = true;
		GLOBAL.changetime = "false";
		GLOBAL.checkchange = "true";
		this.handCall = this.handCall.bind(this);
		this.setFormProperties = this.setFormProperties.bind(this);
		this.setErrorColor = this.setErrorColor.bind(this);
		this.setErrorColorRequestor = this.setErrorColorRequestor.bind(this);
		this.setErrorColorImpactedCI = this.setErrorColorImpactedCI.bind(this);
		this.count = 0;
		this.setImpgroupval=this.setImpgroupval.bind(this);
		this.seterrorimpgroupColor=this.seterrorimpgroupColor.bind(this);
		this.setImpindval=this.setImpindval.bind(this);
		this.seterrorimpindColor=this.seterrorimpindColor.bind(this);
		this.setChgroupval=this.setChgroupval.bind(this);
		this.seterrorChgroupColor=this.seterrorChgroupColor.bind(this);
		this.setChgindval=this.setChgindval.bind(this);
		this.seterrorchgindColor=this.seterrorchgindColor.bind(this);
		this.onGroupInputChangeI = this.onGroupInputChangeI.bind(this);
		this.onCrossClickGroupI = this.onCrossClickGroupI.bind(this);
		this.onIndividualInputChangeI = this.onIndividualInputChangeI.bind(this);
		this.onCrossClickIndividualI = this.onCrossClickIndividualI.bind(this);
		this.onGroupInputChangeC = this.onGroupInputChangeC.bind(this);
		this.onCrossClickGroupC = this.onCrossClickGroupC.bind(this);
		this.onIndividualInputChangeC = this.onIndividualInputChangeC.bind(this);
		this.onCrossClickIndividualC = this.onCrossClickIndividualC.bind(this);
		this.onConsumerSelected = this.onConsumerSelected.bind(this);
		this.getGroupsForImptandChange = this.getGroupsForImptandChange.bind(this);
		this.checkforAMSattributes=this.checkforAMSattributes.bind(this);
		this.onSummaryInputChange=this.onSummaryInputChange.bind(this);
		this.checkforAMSattributes = this.checkforAMSattributes.bind(this);
		this.userChoices = this.userChoices.bind(this);
		this.renderIndividualCategoryQuestions = this.renderIndividualCategoryQuestions.bind(this);
		this.resettingConsumerInfoState=this.resettingConsumerInfoState.bind(this);
		this.resetImpactedCIValue = this.resetImpactedCIValue.bind(this);
		this.onRequestorType = this.onRequestorType.bind(this);
	    this.onImpactCIType = this.onImpactCIType.bind(this);
	}
	onServiceSelection(serviceName) {
		if (serviceName == "imapactedCI" && this.props.editChangeInitialData.serviceBased == false) {
			let impactedCIValue=GLOBAL.ciIdEdit!==''&&GLOBAL.ciIdEdit!=='0'&&GLOBAL.ciIdEdit!==0?GLOBAL.ciIdEdit:this.props.editChangeInitialData.ciId!==''&&this.props.editChangeInitialData.ciId!=='0'&&this.props.editChangeInitialData.ciId!==0?this.props.editChangeInitialData.ciId:'';
		    this.setState({impactedIDSelected: impactedCIValue, isCiNameInfoIcn: impactedCIValue!==''?true:false});
            this.checkforAMSattributes();
		}
		this.setState({AllAMSGrps: false,AllImplmtAMSGrps: false,impIndividualValue: '', showImpIndividualInfo: false,changeIndividualValue: '', showChangeIndividualInfo: false});
	}
	disableEnableDate(value) {
		switch (value) {
			case 'Y':
				this.setState({ downTimeDateDisabled: false, downTimeLanding: false });
				break;
			case 'N':
				this.setState({ downTimeDateDisabled: true, downTimeLanding: false });
				break;
		}
	}
  onSummaryInputChange(e){
    let input = e.target.value.length;
    const leftCount = summaryMaxChar-input;
    this.setState({
      summaryCharCountLeft:leftCount
    })
  }
	setSelectedOption(option) {
		if (option == 'Y') {
			this.setState({ selectedOption: 'Y' });
		}
		if (option == 'N') {
			this.setState({ selectedOption: 'N' });
		}
	}
	AllGrps(e) {
		e.preventDefault();
		this.props.getAllgroup(this.props.editChangeInitialData.consumerCompany);
		this.setState({AllAMSGrpsChangeManagement: true,ChangeManagementAMSgrp: true,AllAMSGrps: true,selectedChgroups:[],selectedChginds:[],changeIndividualValue: '', showChangeIndividualInfo: false});
		this.props.setAssignmentGrpUsr([],"user");
		GLOBAL.AMSEXIST = "yes";
		GLOBAL.valueAssign = true;
		let typeaheadData=[];
		this.props.dispatch(change("changeEditForm", "changeGroupname", ''));
		this.props.dispatch(change("changeEditForm", "changeUsername", ''));
		this.props.dispatch(change("changeEditForm", "changeManagementGroup", ''));
		this.props.dispatch(change("changeEditForm", "changeManagementIndividual", ''));
        this.props.dispatch({type:'CHANGEMANAGEMENT_INDIVIDUAL_LOAD_SUCCESS_TYPE',typeaheadData});
	}
	AllimplmtGrps(e) {
		e.preventDefault();
		this.props.getAllgroup(this.props.editChangeInitialData.consumerCompany);
		this.setState({AllAMSImplmtGrps: true,ImplemtAMSgrp: true,AllImplmtAMSGrps: true,selectedImpgroups:[],selectedImpinds:[],impIndividualValue: '', showImpIndividualInfo: false});
		this.props.setAssignmentGrpUsrImp([],"group");
		this.props.setAssignmentGrpUsrImp([],"user");
		GLOBAL.AMSEXISTIMP = "yes";
		GLOBAL.valueAssignImpgrp = true
		let typeaheadData=[];
		this.props.dispatch({type:'CHANGEMANAGEMENT_IMPL_INDIVIDUAL_LOAD_SUCCESS_TYPE',typeaheadData});
		this.props.dispatch(change("changeEditForm", "impUsername", ''));
		this.props.dispatch(change("changeEditForm", "impGroupname", ''));
		this.props.dispatch(change("changeEditForm","implementationGroup",''));
		this.props.dispatch(change("changeEditForm","implementationIndividual",''));
	}
	onChangeTiming(event) {
		let changeTiminingCode = event ? event.target.value : event;
		if (changeTiminingCode === "20") {
			this.props.dispatch(change("changeEditForm", "expectedStartDate", moment().tz(timezone).format(jwtdf+' HH:mm:ss') ));
			this.props.dispatch(change("changeEditForm", "expectedEndDate", moment().tz(timezone).format(jwtdf+' HH:mm:ss') ));
			this.props.dispatch(change("changeEditForm", "actualStartDate", ''));
			this.props.dispatch(change("changeEditForm", "actualEndDate", ''));
			GLOBAL.changetime = "true"
			GLOBAL.checkchange = "true"
			this.setState({ showChangeTiming: true });
		}
		else {
			this.props.dispatch(change("changeEditForm", "expectedStartDate", moment().tz(timezone).format(jwtdf+' HH:mm:ss') ));
			this.props.dispatch(change("changeEditForm", "expectedEndDate", moment().tz(timezone).format(jwtdf+' HH:mm:ss') ));
			this.props.dispatch(change("changeEditForm", "actualStartDate", ''));
			this.props.dispatch(change("changeEditForm", "actualEndDate", ''));
			GLOBAL.changetime = "false"
			GLOBAL.checkchange = "false"
			this.setState({ showChangeTiming: false });
		}
	}
	requestorCreation() {
		let requestorList = [];
		consumerDataDetails.forEach((item) => {
			if (item.companyId == this.props.editChangeInitialData.consumerCompany) {
				requestorList.push(item);
			}
		});
		consumerDataDetails = requestorList;
		this.state.requestorlistCreationcount++;
	}

	AllAMSGrps() {
		
		let amsAttribute={
		   "Status": this.props.editChangeInitialData.statusId,
		   "Priority": '0',
		   "Service": this.props.editChangeInitialData['serviceId'] || '0',
		   "Impacted CI": !GLOBAL.ciIdEdit ? this.props.editChangeInitialData.ciId : GLOBAL.ciIdEdit,
		   "Requestor": !GLOBAL.breakFixConsumerID ? this.props.editChangeInitialData.requesterId : GLOBAL.breakFixConsumerID,
		   "Class": !GLOBAL.ciClass ? this.props.editChangeInitialData.ciClassId : GLOBAL.ciClass,
		   "User.Location": !GLOBAL.servicelocationid ?"0":GLOBAL.servicelocationid,
		   "CI.Location": !GLOBAL.ciLocationIdEdit ? this.props.editChangeInitialData.ciLocationId : GLOBAL.ciLocationIdEdit
	   };

		 let rulesAttribute={
			"status":  this.props.editChangeInitialData.statusId,
			"priorityId": undefined,
			"serviceName": this.props.editChangeInitialData?.serviceName,
			"impactedCi":  !GLOBAL.ciNameEdit ? this.props.editChangeInitialData.ciName : GLOBAL.ciNameEdit,
			"impactedUsername": GLOBAL.breakFixConsumerName != "" ? GLOBAL.breakFixConsumerName : this.props.editChangeInitialData?.requesterName,
			"ciClassName":!GLOBAL.ciClassIdEdit ? this.props.editChangeInitialData?.ciClassName : GLOBAL.ciClassIdEdit,
			"userLocation": !GLOBAL.servicelocationname ? undefined : GLOBAL.servicelocationname,
			"ciLocation": !GLOBAL.ciLocationNameEdit ? this.props.editChangeInitialData.ciLocationName : GLOBAL.ciLocationNameEdit,
			"serviceId": this.props.editChangeInitialData['serviceId'] || '0',
			"impactedCiId": !GLOBAL.ciIdEdit ? this.props.editChangeInitialData.ciId : GLOBAL.ciIdEdit,
			"requestorId": !GLOBAL.breakFixConsumerID ? this.props.editChangeInitialData.requesterId : GLOBAL.breakFixConsumerID,
			"classId": !GLOBAL.ciClass ? this.props.editChangeInitialData.ciClassId : GLOBAL.ciClass,
			"userLocationId": !GLOBAL.servicelocationid ? this.props.editChangeInitialData.requesterLocationId : GLOBAL.servicelocationid,
			"ciLocationId": !GLOBAL.ciLocationIdEdit ? this.props.editChangeInitialData.ciLocationId : GLOBAL.ciLocationIdEdit
		};

	   this.setState({ rulesAttribute });
	   GLOBAL.valueAssign = false;
	   this.setState({ AllAMSGrpsChangeManagement: false,ChangeManagementAMSgrp: false,AllAMSGrps: false,selectedChgroups:[],selectedChginds:[],changeIndividualValue: '', showChangeIndividualInfo: false});
	   this.props.setAssignmentGrpUsr([],"user");
	   GLOBAL.AMSEXIST = "";
	   let typeaheadData=[];
	   this.props.dispatch({type:'CHANGEMANAGEMENT_INDIVIDUAL_LOAD_SUCCESS_TYPE',typeaheadData});
	   this.props.dispatch(change("changeEditForm", "changeGroupname", ''));
	   this.props.dispatch(change("changeEditForm", "changeUsername", ''));
	   this.props.dispatch(change("changeEditForm", "changeManagementGroup", ''));
	   this.props.dispatch(change("changeEditForm", "changeManagementIndividual", ''));
   }
   ImplmtAMSGrp() {
	let amsAttribute={
		"Status": this.props.editChangeInitialData.statusId,
		"Priority": '0',
		"Service": this.props.editChangeInitialData['serviceId'] || '0',
		"Impacted CI": !GLOBAL.ciIdEdit ? this.props.editChangeInitialData.ciId : GLOBAL.ciIdEdit,
		"Requestor": !GLOBAL.breakFixConsumerID ? this.props.editChangeInitialData.requesterId : GLOBAL.breakFixConsumerID,
		"Class": !GLOBAL.ciClass ? this.props.editChangeInitialData.ciClassId : GLOBAL.ciClass,
		"User.Location": !GLOBAL.servicelocationid ?"0":GLOBAL.servicelocationid,
		"CI.Location": !GLOBAL.ciLocationIdEdit ? this.props.editChangeInitialData.ciLocationId : GLOBAL.ciLocationIdEdit
	};

	let rulesAttribute={
		"status":  this.props.editChangeInitialData.statusId,
		"priorityId": undefined,
		"serviceName": this.props.editChangeInitialData?.serviceName,
		"impactedCi":  !GLOBAL.ciNameEdit ? this.props.editChangeInitialData.ciName : GLOBAL.ciNameEdit,
		"impactedUsername": GLOBAL.breakFixConsumerName != "" ? GLOBAL.breakFixConsumerName : this.props.editChangeInitialData?.requesterName,
		"ciClassName":!GLOBAL.ciClassIdEdit ? this.props.editChangeInitialData?.ciClassName : GLOBAL.ciClassIdEdit,
		"userLocation": !GLOBAL.servicelocationname ? undefined : GLOBAL.servicelocationname,
		"ciLocation": !GLOBAL.ciLocationNameEdit ? this.props.editChangeInitialData.ciLocationName : GLOBAL.ciLocationNameEdit,
		"serviceId": this.props.editChangeInitialData['serviceId'] || '0',
		"impactedCiId": !GLOBAL.ciIdEdit ? this.props.editChangeInitialData.ciId : GLOBAL.ciIdEdit,
		"requestorId": !GLOBAL.breakFixConsumerID ? this.props.editChangeInitialData.requesterId : GLOBAL.breakFixConsumerID,
		"classId": !GLOBAL.ciClass ? this.props.editChangeInitialData.ciClassId : GLOBAL.ciClass,
		"userLocationId": !GLOBAL.servicelocationid ? this.props.editChangeInitialData.requesterLocationId : GLOBAL.servicelocationid,
		"ciLocationId": !GLOBAL.ciLocationIdEdit ? this.props.editChangeInitialData.ciLocationId : GLOBAL.ciLocationIdEdit
	};

	this.setState({ rulesAttribute });
	GLOBAL.valueAssignImpgrp = false;
	this.setState({
		AllAMSImplmtGrps: false,
		ImplemtAMSgrp: false,
		AllImplmtAMSGrps: false,
		selectedImpgroups:[],
		selectedImpinds:[],
		impIndividualValue: '', showImpIndividualInfo: false
	});
	GLOBAL.AMSEXISTIMP = "";
	let typeaheadData=[];
	this.props.setAssignmentGrpUsrImp([],"group");
	this.props.setAssignmentGrpUsrImp([],"user");
	this.props.dispatch({type:'CHANGEMANAGEMENT_IMPL_INDIVIDUAL_LOAD_SUCCESS_TYPE',typeaheadData});
	this.props.dispatch(change("changeEditForm", "impUsername", ''));
	this.props.dispatch(change("changeEditForm", "impGroupname", ''));
	this.props.dispatch(change("changeEditForm","implementationGroup",''));
	this.props.dispatch(change("changeEditForm","implementationIndividual",''));
}
handCall(e) {
	if (GLOBAL.ciClassNameSelected != '')
		this.props.dispatch(change('changeEditForm', 'ciClassName', GLOBAL.ciClassNameSelected));
}
setFormProperties(value, setPropertyOf) {
	let date = value;
	switch (setPropertyOf) {
		case 'expectedStartDate':
			this.setState({ expectedStartDate: date });
			break;
		case 'expectedEndDate':
			this.setState({ expectedEndDate: date });
			break;
		case 'cabDate':
			this.setState({ cabDate: date });
			this.props.setState({checkCABDateValidation: true});
			break;
	}
}
setErrorColor(errorColor, fieldType) {
	switch (fieldType) {
		case 'impactedServiceName':
			this.setState({ impactedServiceName: errorColor });
			GLOBAL.serviceErrorColor = "";

			break;
		case 'consumer':
			this.setState({ consumer: errorColor });
			GLOBAL.consumerErrorColor = "";
			break;
		default:
	}
}
setImpgroupval(selectedImpgroups){
	if (selectedImpgroups.length > 0) {
		const implementationGroupID = this.state.AllImplmtAMSGrps==false?selectedImpgroups[0].id:selectedImpgroups[0].value;
		this.props.dispatch(change("changeEditForm", "implementationGroup",implementationGroupID));
		this.props.dispatch(change("changeEditForm", "impGroupname", selectedImpgroups[0].label));
		this.props.dispatch(change("changeEditForm", "supportCompanyID", selectedImpgroups[0].supportCompanyID));
		this.props.dispatch(change("changeEditForm", "supportCompanyName", selectedImpgroups[0].supportCompanyName));
		this.props.loadChangeManagementImplGroupIndividual(implementationGroupID);
		let typeaheadData = [];
		this.props.dispatch({ type: 'CHANGEMANAGEMENT_IMPL_INDIVIDUAL_LOAD_SUCCESS_TYPE', typeaheadData });
		GLOBAL.isExternalChangeEdit = (selectedImpgroups[0].isExternal==null || selectedImpgroups[0].isExternal==undefined) ? false : selectedImpgroups[0].isExternal ;
	} else {
		this.props.dispatch(change("changeEditForm", "implementationGroup", ""));
		this.props.dispatch(change("changeEditForm", "impGroupname", ""));
		GLOBAL.isExternalChangeEdit="";
	}
	this.props.dispatch(change("changeEditForm", "implementationIndividual", ''));
	this.props.dispatch(change("changeEditForm", "impUsername", ''));
	this.props.setAssignmentGrpUsrImp(selectedImpgroups,"group");
	this.props.setAssignmentGrpUsrImp([],"user");
	this.setState({selectedImpgroups: selectedImpgroups, selectedImpinds:[], impIndividualValue: '', showImpIndividualInfo: false});
}
seterrorimpgroupColor(){
	if(this.props.editChangeInitialData.statusId!=='30'){
	if(this.state.selectedImpgroups.length===0){
		 this.props.seTypeaheadValidation(true,'implementationGroup');
	}else{
		this.props.seTypeaheadValidation(false,'implementationGroup');
	}
    }
}
setImpindval(selectedImpinds){
	let val = '';
	if(selectedImpinds.length>0){
	  this.props.dispatch(change("changeEditForm", "implementationIndividual", selectedImpinds[0].value));
	  this.props.dispatch(change("changeEditForm", "impUsername", selectedImpinds[0].label));
	  GLOBAL.implementationIndividual = selectedImpinds[0].value;
	  val = selectedImpinds[0].value;
	}else{
	  this.props.dispatch(change("changeEditForm", "implementationIndividual", ''));
	  this.props.dispatch(change("changeEditForm", "impUsername", ''));
	  GLOBAL.implementationIndividual = '';
	}
	this.setState({ selectedImpinds:selectedImpinds, impIndividualValue: val, showImpIndividualInfo: val!==''?true:false});
}
seterrorimpindColor(){
}
setChgroupval(selectedChgroups){
	if(selectedChgroups.length>0){
	 const changeGroupID = this.state.AllAMSGrps==false?selectedChgroups[0].id:selectedChgroups[0].value;
	 this.props.dispatch(change("changeEditForm", "changeGroupname", selectedChgroups[0].label));
	 this.props.dispatch(change("changeEditForm","changeManagementGroup",changeGroupID));
	 this.props.loadChangeManagementIndividual(changeGroupID);
	 let typeaheadData=[];
	 this.props.dispatch({type:'CHANGEMANAGEMENT_INDIVIDUAL_LOAD_SUCCESS_TYPE',typeaheadData});
	}else{
	 this.props.dispatch(change("changeEditForm", "changeGroupname", ''));
	 this.props.dispatch(change("changeEditForm","changeManagementGroup",''));
	}
	this.props.dispatch(change("changeEditForm", "changeManagementIndividual", ''));
	this.props.dispatch(change("changeEditForm", "changeUsername", ''));
	this.props.setAssignmentGrpUsr(selectedChgroups,"group");
	this.props.setAssignmentGrpUsr([],"user");
	this.setState({selectedChgroups: selectedChgroups,selectedChginds:[], changeIndividualValue: '', showChangeIndividualInfo: false});
}
seterrorChgroupColor(){
	if(this.props.editChangeInitialData.statusId!=='30'){
	if(this.state.selectedChgroups.length===0){
		this.props.seTypeaheadValidation(true,'changeManagementGroup');
	}else{
		this.props.seTypeaheadValidation(false,'changeManagementGroup');
	}
    }
}
setChgindval(selectedChginds){
	let val = '';
	if(selectedChginds.length>0){
	   this.props.dispatch(change("changeEditForm", "changeManagementIndividual", selectedChginds[0].value));
	   this.props.dispatch(change("changeEditForm", "changeUsername", selectedChginds[0].label));
	   val = selectedChginds[0].value;
	}else{
		this.props.dispatch(change("changeEditForm", "changeManagementIndividual", ''));
		this.props.dispatch(change("changeEditForm", "changeUsername", ''));
	}
	this.setState({selectedChginds: selectedChginds, changeIndividualValue: val, showChangeIndividualInfo: val!==''?true:false});
}
seterrorchgindColor(){
}
onGroupInputChangeI(e){
	this.setState({typedGroupI: e,impIndividualValue: '', showImpIndividualInfo: false});
	this.setImpgroupval([]);
	GLOBAL.implementationIndividual = '';
}
onCrossClickGroupI(){
	this.onGroupInputChangeI('');
	this.setState({typedIndividualI: ''});
	GLOBAL.implementationIndividual = '';
	this.props.dispatch({type:"RESET_BREAKFIXSGI"})
}
onIndividualInputChangeI(e){
	this.setState({typedIndividualI: e,impIndividualValue: '', showImpIndividualInfo: false});
	this.setImpindval([]);
	GLOBAL.implementationIndividual = '';
}
onCrossClickIndividualI(){
	this.onIndividualInputChangeI('');
	GLOBAL.implementationIndividual = '';
}
onGroupInputChangeC(e){
	this.setState({typedGroupC: e,changeIndividualValue: '', showChangeIndividualInfo: false});
	this.setChgroupval([]);
}
onCrossClickGroupC(){
	this.onGroupInputChangeC('');
	this.setState({typedIndividualC: ''});
	this.props.dispatch({type:"RESET_BREAKFIXSGI"})
}
onIndividualInputChangeC(e){
	this.setState({typedIndividualC: e,changeIndividualValue: '', showChangeIndividualInfo: false});
	this.setChgindval([]);
}
onCrossClickIndividualC(){
	this.onIndividualInputChangeC('');
}
onConsumerSelected(suggestion){
	try{
		GLOBAL.breakFixConsumerID = suggestion.value;
		GLOBAL.servicelocationid = suggestion.locationId;
		GLOBAL.servicelocationname = suggestion.locationName;
		// GLOBAL.breakFixConsumerName=suggestion.impactedUsername,
		let value=GLOBAL.breakFixConsumerID!==''&&GLOBAL.breakFixConsumerID!=='0'&&GLOBAL.breakFixConsumerID!==0?GLOBAL.breakFixConsumerID:this.props.editChangeInitialData.requesterId!==''&&this.props.editChangeInitialData.requesterId!=='0'&&this.props.editChangeInitialData.requesterId!==0?this.props.editChangeInitialData.requesterId:'';
		this.setState({requestorValue: value, showRequestorInfo: value!=''?true:false});
		this.checkforAMSattributes();
	}catch(e){
		console.error(e);
	}
}
getGroupsForImptandChange(){
	try{
		let amsAttribute={
			"Status": this.props.editChangeInitialData.statusId,
			"Priority": '0',
			"Service": this.props.editChangeInitialData['serviceId'] || '0',
			"Impacted CI": !GLOBAL.ciIdEdit ? this.props.editChangeInitialData.ciId : GLOBAL.ciIdEdit,
			"Requestor": !GLOBAL.breakFixConsumerID ? this.props.editChangeInitialData.requesterId : GLOBAL.breakFixConsumerID,
			"Class": !GLOBAL.ciClass ? this.props.editChangeInitialData.ciClassId : GLOBAL.ciClass,
			"User.Location": !GLOBAL.servicelocationid ?"0":GLOBAL.servicelocationid,
			"CI.Location": !GLOBAL.ciLocationIdEdit ? this.props.editChangeInitialData.ciLocationId : GLOBAL.ciLocationIdEdit
		};

		let rulesAttribute={
			"status": this.props.editChangeInitialData.statusId,
			"priorityId": undefined,
			"serviceName": this.props.editChangeInitialData?.serviceName,
			"impactedCi":  !GLOBAL.ciNameEdit ? this.props.editChangeInitialData.ciName : GLOBAL.ciNameEdit,
			"impactedUsername": GLOBAL.breakFixConsumerName != "" ? GLOBAL.breakFixConsumerName : this.props.editChangeInitialData?.requesterName,
			"ciClassName":!GLOBAL.ciClassIdEdit ? this.props.editChangeInitialData?.ciClassName : GLOBAL.ciClassNameSelected,
			"userLocation": !GLOBAL.servicelocationname ? undefined : GLOBAL.servicelocationname,
			"ciLocation": !GLOBAL.ciLocationNameEdit ? this.props.editChangeInitialData.ciLocationName : GLOBAL.ciLocationNameEdit,
			"serviceId": this.props.editChangeInitialData['serviceId'] || '0',
			"impactedCiId": !GLOBAL.ciIdEdit ? this.props.editChangeInitialData.ciId : GLOBAL.ciIdEdit,
			"requestorId": !GLOBAL.breakFixConsumerID ? this.props.editChangeInitialData.requesterId : GLOBAL.breakFixConsumerID,
			"classId": !GLOBAL.ciClass ? this.props.editChangeInitialData.ciClassId : GLOBAL.ciClass,
			"userLocationId": !GLOBAL.servicelocationid ? this.props.editChangeInitialData.requesterLocationId : GLOBAL.servicelocationid,
			"ciLocationId": !GLOBAL.ciLocationIdEdit ? this.props.editChangeInitialData.ciLocationId : GLOBAL.ciLocationIdEdit
		};
	
		this.setState({ rulesAttribute });

	}catch(e){
		console.error(e);
	}
}
checkforAMSattributes(){
	this.props.dispatch(change("changeEditForm", "implementationGroup", ''));
	this.props.dispatch(change("changeEditForm", "implementationIndividual", ''));
	this.props.dispatch(change("changeEditForm", "changeManagementGroup", ''));
	this.props.dispatch(change("changeEditForm", "changeManagementIndividual", ''));
	let typeaheadData=[];
	let breakFixSGIName={'data':[]};
	this.props.dispatch({type:'CHANGEMANAGEMENT_IMPL_INDIVIDUAL_LOAD_SUCCESS_TYPE',typeaheadData});
	this.props.dispatch({type:'CHANGEMANAGEMENT_INDIVIDUAL_LOAD_SUCCESS_TYPE',typeaheadData});
	this.props.dispatch({type:'CHANGEMANAGEMENT_INDIVIDUAL_LOAD_SUCCESS',breakFixSGIName});
	this.props.dispatch({type:'CHANGEMANAGEMENT_IMPL_INDIVIDUAL_LOAD_SUCCESS',breakFixSGIName});
	this.props.dispatch({type:'GROUP_LIST_TYPE_AHEAD_INITIAL',typeaheadData});
	this.props.dispatch({type:'LOAD_BREAKFIXSGAECHANGE_SUCCESS_TYPEAHED',typeaheadData});
	this.state.selectedChginds=this.state.selectedChgroups=this.state.selectedImpgroups=this.state.selectedImpinds=[];
	GLOBAL.impactedId = GLOBAL.ciId;
	this.setState({showImpIndividualInfo: false, showChangeIndividualInfo: false,AllImplmtAMSGrps: false,AllAMSGrps: false});
	GLOBAL.valueAssign=false;
	GLOBAL.valueAssignImpgrp=false;
    let amsAttribute={
	"Status": this.props.editChangeInitialData.statusId,
	"Priority": '0',
	"Service": this.props.editChangeInitialData['serviceId'] || '0',
	"Impacted CI": GLOBAL.ciIdEdit == "" ? this.props.editChangeInitialData.ciId : GLOBAL.ciIdEdit,
	"Requestor": GLOBAL.breakFixConsumerID == "" ? this.props.editChangeInitialData.requesterId : GLOBAL.breakFixConsumerID,
	"Class": GLOBAL.ciClassIdEdit == "" ? this.props.editChangeInitialData.ciClassId : GLOBAL.ciClassIdEdit,
	"User.Location": GLOBAL.servicelocationid===""?"0":GLOBAL.servicelocationid,
	"CI.Location": GLOBAL.ciLocationIdEdit == "" ? this.props.editChangeInitialData.ciLocationId : GLOBAL.ciLocationIdEdit
    };

	let rulesAttribute={
		"status": this.props.editChangeInitialData.statusId,
		"priorityId": undefined,
		"serviceName": this.props.editChangeInitialData?.serviceName,
		"impactedCi":  GLOBAL.ciNameEdit==='' ? this.props.editChangeInitialData.ciName : GLOBAL.ciNameEdit,
		"impactedUsername": GLOBAL.breakFixConsumerName != "" ? GLOBAL.breakFixConsumerName : this.props.editChangeInitialData?.requesterName,
		"ciClassName": GLOBAL.ciClassIdEdit===''? this.props.editChangeInitialData?.ciClassName : GLOBAL.ciClassNameSelected,
		"userLocation": GLOBAL.servicelocationname===''?this.props.editChangeInitialData.requesterLocationName: GLOBAL.servicelocationname,
		"ciLocation": GLOBAL.ciLocationNameEdit==='' ? this.props.editChangeInitialData.ciLocationName : GLOBAL.ciLocationNameEdit,
		"serviceId": this.props.editChangeInitialData['serviceId'] || '0',
		"impactedCiId": GLOBAL.ciIdEdit == "" ? this.props.editChangeInitialData.ciId : GLOBAL.ciIdEdit,
		"requestorId": GLOBAL.breakFixConsumerID == "" ? this.props.editChangeInitialData.requesterId : GLOBAL.breakFixConsumerID,
		"classId": GLOBAL.ciClassIdEdit == "" ? this.props.editChangeInitialData.ciClassId : GLOBAL.ciClassIdEdit,
		"userLocationId": GLOBAL.servicelocationid===""? this.props.editChangeInitialData.requesterLocationId : GLOBAL.servicelocationid,
		"ciLocationId": GLOBAL.ciLocationIdEdit == "" ? this.props.editChangeInitialData.ciLocationId : GLOBAL.ciLocationIdEdit
	};
	this.setState({ rulesAttribute });
}
onTypeSelect(e) {
	this.setState({ changeType: e.target.value });
	this.onChangeTiming('')
	this.props.dispatch(change("changeEditForm", "changeTiming", ''));
	this.props.dispatch(change("changeEditForm", "riskOccurrenceProbability", ''));
	this.props.dispatch(change("changeEditForm", "riskImpact", ''));
	this.props.dispatch(change("changeEditForm", "controlEffectiveness", ''));
	this.props.dispatch(change("changeEditForm", "overallRisk", ''));
	let response={
		data:{
			'controlEffectiveness':'',
			'id':'',
			'overallRisk':'',
			'riskImpact':'',
			'riskOccurrenceProbability':''
		}
	};
	this.setState({ expectedStartDate: '', expectedEndDate: '' });
	// if(e.target.value && e.target.value == '35'){
	// 	this.setState({ expectedStartDate: '', expectedEndDate: '' });
	// }
	this.props.dispatch({type: 'RISK_ASSESSMENT',response});
}
getRiskCalculated(field, e) {
	let fieldVal = e.target.value;
	let riskOccurrenceVal = this.props.riskOccurrenceVal;
	let riskImpactVal = this.props.riskImpactVal;
	let controlEffectivenesVal = this.props.controlEffectivenesVal;
	switch (field) {
		case "riskOccurrenceProbability":
			riskOccurrenceVal = fieldVal;
			break;
		case "riskImpact":
			riskImpactVal = fieldVal;
			break;
		case "controlEffectiveness":
			controlEffectivenesVal = fieldVal;
			break;
	}
	if (riskOccurrenceVal != "" && riskOccurrenceVal != undefined && riskOccurrenceVal != "null" && riskImpactVal != ""
		&& riskImpactVal != undefined && riskImpactVal != "null" &&
		controlEffectivenesVal != "" && controlEffectivenesVal != undefined && controlEffectivenesVal != "null")
		this.props.getRiskCalculated(riskOccurrenceVal, riskImpactVal, controlEffectivenesVal);
}
componentWillReceiveProps(nextProps) {
	if(nextProps.resetChangeEdit){
		if(this.props.editChangeInitialData.implementationGroup!=='' && this.props.editChangeInitialData.implementationGroup!=='0' && this.props.editChangeInitialData.implementationGroup!==0){
			if(typeof this.props.editChangeInitialData.implementationGroup!=='undefined' && this.props.editChangeInitialData.implementationGroup!==null && typeof this.props.editChangeInitialData.impGroupname!=='undefined' && this.props.editChangeInitialData.impGroupname!==null){
				this.state.selectedImpgroups=[{'value':this.props.editChangeInitialData.implementationGroup,'label':this.props.editChangeInitialData.impGroupname}];
				this.props.setAssignmentGrpUsrImp(this.state.selectedImpgroups,"group");
				this.props.dispatch(change("changeEditForm","implementationGroup",this.props.editChangeInitialData.implementationGroup));
				this.props.dispatch(change("changeEditForm","impGroupname",this.props.editChangeInitialData.impGroupname));
				this.props.seTypeaheadValidation(false,'implementationGroup');
			}
		}else{
			this.state.selectedImpgroups=[];
			this.props.setAssignmentGrpUsrImp([],"group");
			this.props.dispatch(change("changeEditForm","implementationGroup",''));
			this.props.dispatch(change("changeEditForm","impGroupname",''));
		}
		if(this.props.editChangeInitialData.implementationIndividual!=='' && this.props.editChangeInitialData.implementationIndividual!=='0' && this.props.editChangeInitialData.implementationIndividual!==0){
			if(typeof this.props.editChangeInitialData.implementationIndividual!=='undefined' && this.props.editChangeInitialData.implementationIndividual!==null && typeof this.props.editChangeInitialData.impUsername!=='undefined' && this.props.editChangeInitialData.impUsername!==null){
				this.state.selectedImpinds=[{'value':this.props.editChangeInitialData.implementationIndividual, 'label':this.props.editChangeInitialData.impUsername}];
				this.props.dispatch(change("changeEditForm","implementationIndividual",this.props.editChangeInitialData.implementationIndividual));
				this.props.dispatch(change("changeEditForm","impUsername",this.props.editChangeInitialData.impUsername));
			}
		}else{
			this.state.selectedImpinds=[];
			this.props.setAssignmentGrpUsrImp([],"user");
			this.props.dispatch(change("changeEditForm","implementationIndividual",''));
			this.props.dispatch(change("changeEditForm","impUsername",''));
		}
		if(this.props.editChangeInitialData.changeManagementGroup!=='' && this.props.editChangeInitialData.changeManagementGroup!=='0' && this.props.editChangeInitialData.changeManagementGroup!==0){
			if(typeof this.props.editChangeInitialData.changeManagementGroup!=='undefined' && this.props.editChangeInitialData.changeManagementGroup && typeof this.props.editChangeInitialData.changeGroupname!=='undefined' && this.props.editChangeInitialData.changeGroupname!==null){
				this.state.selectedChgroups=[{'value':this.props.editChangeInitialData.changeManagementGroup,'label':this.props.editChangeInitialData.changeGroupname}];
				this.props.setAssignmentGrpUsr(this.state.selectedChgroups,"group");
				this.props.dispatch(change("changeEditForm","changeManagementGroup",this.props.editChangeInitialData.changeManagementGroup));
				this.props.dispatch(change("changeEditForm","changeGroupname",this.props.editChangeInitialData.changeGroupname));
				this.props.seTypeaheadValidation(false,'changeManagementGroup');
			}
		}else{
			this.state.selectedChgroups=[];
			this.props.setAssignmentGrpUsr([],"group");
			this.props.dispatch(change("changeEditForm","changeManagementGroup",''));
			this.props.dispatch(change("changeEditForm","changeGroupname",''));
		}
		if(this.props.editChangeInitialData.changeManagementIndividual!==''&& this.props.editChangeInitialData.changeManagementIndividual!=='0' && this.props.editChangeInitialData.changeManagementIndividual!==0){
			if(typeof this.props.editChangeInitialData.changeManagementIndividual!=='undefined' && this.props.editChangeInitialData.changeManagementIndividual!==null && typeof this.props.editChangeInitialData.changeUsername!=='undefined' && this.props.editChangeInitialData.changeUsername!==null){
				this.state.selectedChginds=[{'value':this.props.editChangeInitialData.changeManagementIndividual,'label':this.props.editChangeInitialData.changeUsername}];
				this.props.dispatch(change("changeEditForm","changeManagementIndividual",this.props.editChangeInitialData.changeManagementIndividual));
				this.props.dispatch(change("changeEditForm","changeUsername",this.props.editChangeInitialData.changeUsername));
			}
		}else{
			this.state.selectedChginds=[];
			this.props.dispatch(change("changeEditForm","changeManagementIndividual",''));
			this.props.dispatch(change("changeEditForm","changeUsername",''));
		}
		if(this.props.editChangeInitialData.consumerCompany!=='' && this.props.editChangeInitialData.consumerCompany!=='0' && this.props.editChangeInitialData.consumerCompany!==0){
			if(typeof this.props.editChangeInitialData.consumerCompany!=='undefined' && this.props.editChangeInitialData.consumerCompany!==null && this.props.editChangeInitialData.consumerCompanyName!=='undefined' && this.props.editChangeInitialData.consumerCompanyName!==null){
				this.props.dispatch(change("changeEditForm","company",this.props.editChangeInitialData.consumerCompany));
				this.props.dispatch(change("changeEditForm","companyName",this.props.editChangeInitialData.consumerCompanyName));
			}
		}
		this.props.seTypeaheadValidation(false,"Reset");
	}
	const expectedStartDateVal = nextProps.expectedStartDateVal;
	const expectedEndDateVal = nextProps.expectedEndDateVal;
	const startDateVal = nextProps.startDateVal;
	const endDateVal = nextProps.endDateVal;
	let date_format = nextProps.date_format;
	date_format = date_format.substring(0, 10) + " HH:mm:ss";

	let expectedStartDateValue = '';
	let expectedEndDateValue = '';
	let startDateValue = '';
	let endDateValue = '';

	if (expectedStartDateVal) {
		if (typeof expectedStartDateVal == 'object') {
			let val = expectedStartDateVal._d;
			expectedStartDateValue = moment(val).format(date_format);
		} else {
			expectedStartDateValue = expectedStartDateVal;
		}
		this.setState({ expectedStartDate: expectedStartDateValue });
	} else {
		this.setState({ expectedStartDate: '' });
	}

	if (expectedEndDateVal) {
		if (typeof expectedEndDateVal == 'object') {
			let val = expectedEndDateVal._d;
			expectedEndDateValue = moment(val).format(date_format);
		} else {
			expectedEndDateValue = expectedEndDateVal;
		}
		this.setState({ expectedEndDate: expectedEndDateValue });
	} else {
		this.setState({ expectedEndDate: '' });
	}

	if (startDateVal) {
		if (typeof startDateVal == 'object') {
			let val = startDateVal._d;
			startDateValue = moment(val).format(date_format);
		} else {
			startDateValue = startDateVal;
		}
		this.setState({ startDate: startDateValue });
	} else {
		this.setState({ startDate: '' });
	}

	if (endDateVal) {
		if (typeof endDateVal == 'object') {
			let val = endDateVal._d;
			endDateValue = moment(val).format(date_format);
		} else {
			endDateValue = endDateVal;
		}
		this.setState({ endDate: endDateValue });
	} else {
		this.setState({ endDate: '' });
	}

	if(this.props.selectedAssignmentUser !== nextProps.selectedAssignmentUser){
		this.setChgindval(nextProps.selectedAssignmentUser);
	}

	if(this.props.selectedImpUser !==  nextProps.selectedImpUser &&  nextProps.selectedImpUser.length>0){
		this.setImpindval(nextProps.selectedImpUser);
	}
  }
  componentDidMount() {
    if (this.props.editChangeInitialData) {
      const summary = this.props.editChangeInitialData && this.props.editChangeInitialData.summary;
			let newState = {};
      if (summary) {
        const count = summaryMaxChar - summary.length;
        this.setState({
          summaryCharCountLeft: count
        })
      }

			const rulesAttribute = {
				"status": this.props.editChangeInitialData.statusId,
				"priorityId": undefined, 
				"serviceName": this.props.editChangeInitialData?.serviceName,
				"impactedCi": this.props.editChangeInitialData?.ciName,
				"impactedUsername": this.props.editChangeInitialData?.requesterName,
				"ciClassName": this.props.editChangeInitialData?.ciClassName,
				"userLocation": this.props.editChangeInitialData?.requesterLocationName,
				"ciLocation": this.props.editChangeInitialData?.ciLocationName,
				"serviceId": this.props.editChangeInitialData['serviceId'] || '0',
				"impactedCiId": this.props.editChangeInitialData.ciId,
				"requestorId": this.props.editChangeInitialData.requesterId,
				"classId": this.props.editChangeInitialData.ciClassId,
				"userLocationId": this.props.editChangeInitialData.requesterLocationId,
				"ciLocationId": this.props.editChangeInitialData.ciLocationId

		};


		newState.rulesAttribute = rulesAttribute;

		this.setState(newState);
    }
  }
  componentWillMount() {
	this.props.getChangeQuestionsCategoryWise(this.props.changeId)
	GLOBAL.breakFixServiceID=GLOBAL.ciId=GLOBAL.breakFixConsumerID=GLOBAL.servicelocationid=GLOBAL.ciLocationId=GLOBAL.ciClassId=GLOBAL.implementationIndividual=GLOBAL.businessCriticalityId=GLOBAL.businessCriticality= '';
	if(this.props.editChangeInitialData){
		GLOBAL.ciNameEdit = this.props.editChangeInitialData['ciName'] || '';
		GLOBAL.ciClass = this.props.editChangeInitialData['ciClassId'] || '';
		GLOBAL.servicelocationid = this.props.editChangeInitialData['requesterLocationId'] || '';
		GLOBAL.ciLocationIdEdit = this.props.editChangeInitialData['ciLocationId'] || '';
	}

	if(this.props.editChangeInitialData.implementationGroup!=='' && this.props.editChangeInitialData.implementationGroup!=='0' && this.props.editChangeInitialData.implementationGroup!==0){
		if(typeof this.props.editChangeInitialData.implementationGroup!=='undefined' && this.props.editChangeInitialData.implementationGroup!==null && typeof this.props.editChangeInitialData.impGroupname!=='undefined' && this.props.editChangeInitialData.impGroupname!==null){
			this.state.selectedImpgroups=[{'value':this.props.editChangeInitialData.implementationGroup,'label':this.props.editChangeInitialData.impGroupname}];
			this.props.setAssignmentGrpUsrImp(this.state.selectedImpgroups,"group");
			this.props.dispatch(change("changeEditForm","implementationGroup",this.props.editChangeInitialData.implementationGroup));
			this.props.dispatch(change("changeEditForm","impGroupname",this.props.editChangeInitialData.impGroupname));
		}
	}
	if(this.props.editChangeInitialData.implementationIndividual!=='' && this.props.editChangeInitialData.implementationIndividual!=='0' && this.props.editChangeInitialData.implementationIndividual!==0){
		if(typeof this.props.editChangeInitialData.implementationIndividual!=='undefined' && this.props.editChangeInitialData.implementationIndividual!==null && typeof this.props.editChangeInitialData.impUsername!=='undefined' && this.props.editChangeInitialData.impUsername!==null){
			this.state.selectedImpinds=[{'value':this.props.editChangeInitialData.implementationIndividual, 'label':this.props.editChangeInitialData.impUsername}];
			this.props.dispatch(change("changeEditForm","implementationIndividual",this.props.editChangeInitialData.implementationIndividual));
			this.props.dispatch(change("changeEditForm","impUsername",this.props.editChangeInitialData.impUsername));
			GLOBAL.implementationIndividual = this.props.editChangeInitialData.implementationIndividual;
		}
	}
	if(this.props.editChangeInitialData.changeManagementGroup!=='' && this.props.editChangeInitialData.changeManagementGroup!=='0' && this.props.editChangeInitialData.changeManagementGroup!==0){
		if(typeof this.props.editChangeInitialData.changeManagementGroup!=='undefined' && this.props.editChangeInitialData.changeManagementGroup && typeof this.props.editChangeInitialData.changeGroupname!=='undefined' && this.props.editChangeInitialData.changeGroupname!==null){
			this.state.selectedChgroups=[{'value':this.props.editChangeInitialData.changeManagementGroup,'label':this.props.editChangeInitialData.changeGroupname}];
			this.props.setAssignmentGrpUsr(this.state.selectedChgroups,"group");
			this.props.dispatch(change("changeEditForm","changeManagementGroup",this.props.editChangeInitialData.changeManagementGroup));
			this.props.dispatch(change("changeEditForm","changeGroupname",this.props.editChangeInitialData.changeGroupname));
		}
	}
	if(this.props.editChangeInitialData.changeManagementIndividual!==''&& this.props.editChangeInitialData.changeManagementIndividual!=='0' && this.props.editChangeInitialData.changeManagementIndividual!==0){
		if(typeof this.props.editChangeInitialData.changeManagementIndividual!=='undefined' && this.props.editChangeInitialData.changeManagementIndividual!==null && typeof this.props.editChangeInitialData.changeUsername!=='undefined' && this.props.editChangeInitialData.changeUsername!==null){
			this.state.selectedChginds=[{'value':this.props.editChangeInitialData.changeManagementIndividual,'label':this.props.editChangeInitialData.changeUsername}];
			this.props.dispatch(change("changeEditForm","changeManagementIndividual",this.props.editChangeInitialData.changeManagementIndividual));
			this.props.dispatch(change("changeEditForm","changeUsername",this.props.editChangeInitialData.changeUsername));
		}
	}
	if(this.props.editChangeInitialData.consumerCompany!=='' && this.props.editChangeInitialData.consumerCompany!=='0' && this.props.editChangeInitialData.consumerCompany!==0){
		if(typeof this.props.editChangeInitialData.consumerCompany!=='undefined' && this.props.editChangeInitialData.consumerCompany!==null && this.props.editChangeInitialData.consumerCompanyName!=='undefined' && this.props.editChangeInitialData.consumerCompanyName!==null){
			this.props.dispatch(change("changeEditForm","company",this.props.editChangeInitialData.consumerCompany));
			this.props.dispatch(change("changeEditForm","companyName",this.props.editChangeInitialData.consumerCompanyName));
		}
	}
	GLOBAL.impactedId = this.props.editChangeInitialData.ciId;
	GLOBAL.isExternalChangeEdit= (this.props.editChangeInitialData.isExternal==null || this.props.editChangeInitialData.isExternal=="" || this.props.editChangeInitialData.isExternal==undefined) ? false : this.props.editChangeInitialData.isExternal;
	this.setState({
		AllAMSGrps: false,
		AllAMSGrpsChangeManagement: false,
		AllImplmtAMSGrps: false,
		AllAMSImplmtGrps: false
	});
	GLOBAL.valueAssign = false;
	GLOBAL.valueAssignImpgrp = false;
	GLOBAL.FoundationgrpImp = false;
	GLOBAL.Foundationgrp = false;
	GLOBAL.overallRisk = "";
		let strObj = {};
		strObj.userId = "";
		strObj = JSON.stringify(strObj);
		var str = {};
		str.userId = "";
		str.companyId = "";
		str.associatedCompanyId = "";
		str = JSON.stringify(str);

		api.get(GLOBAL.breakFixServiceWithoutDomainUrl, { headers: { 'query': str } }).then((response) => {
			impactedServiceData = response.data;
			for (let i = 0; i < impactedServiceData.length; i++) {
				myObj0 = { value: '', label: '', companyname: '' };
				myObj0.value = impactedServiceData[i].offeringId;
				myObj0.label = impactedServiceData[i].offeringName + "|" + impactedServiceData[i].categoryName + "|" + impactedServiceData[i].subCategoryName + "|" + impactedServiceData[i].companyName;
				myObj0.companyname = impactedServiceData[i].company;
				impactedServiceDataDetails.push(myObj0);
			}
			impactedServiceDataDetails = Array.from(new Set(impactedServiceDataDetails));
		});
		this.props.getLovValues("ChangeManagement", "Type");
		this.props.getLovValues("ChangeManagement", "ChangeTiming");
		this.props.getLovValues("ChangeManagement", "TechnicalRisk");
		this.props.getLovValues("ChangeManagement", "BusinessRisk");
		this.props.getLovValues("ChangeManagement", "PlanType");
		this.props.getLovValues("ChangeManagement", "Urgency");
		this.props.getLovValues("ChangeManagement", "ReasonForChange");
		this.props.getLovValues("ChangeManagement", "RiskOccurrenceProbability");
		this.props.getLovValues("ChangeManagement", "RiskImpact");
		this.props.getLovValues("ChangeManagement", "ControlEffectiveness");
		this.props.loadImplPlanList("ChangeManagement", this.props.changeId);
	this.setState({ companyId: this.props.editChangeInitialData.consumerCompany });
	if(this.props && this.props.editChangeInitialData && this.props.editChangeInitialData.requesterId && !isNaN(this.props.editChangeInitialData.requesterId) && !!parseInt(this.props.editChangeInitialData.requesterId, 10)){
		this.setState({requestorValue: this.props.editChangeInitialData.requesterId, showRequestorInfo: true});
	}
	else{
		this.setState({requestorValue: '', showRequestorInfo: false});
	}
	if(this.props && this.props.editChangeInitialData && this.props.editChangeInitialData.ciId && !isNaN(this.props.editChangeInitialData.ciId) && !!parseInt(this.props.editChangeInitialData.ciId,10)){
		this.setState({impactedIDSelected: this.props.editChangeInitialData.ciId, isCiNameInfoIcn: true});
	}else{
		this.setState({impactedIDSelected: '', isCiNameInfoIcn: false});
	}

	if(this.props && this.props.editChangeInitialData && this.props.editChangeInitialData.implementationIndividual && !isNaN(this.props.editChangeInitialData.implementationIndividual) && !!parseInt(this.props.editChangeInitialData.implementationIndividual, 10)){
		this.setState({impIndividualValue: this.props.editChangeInitialData.implementationIndividual, showImpIndividualInfo: true});
	}
	else{
		this.setState({impIndividualValue: '', showImpIndividualInfo: false});
	}

	if(this.props && this.props.editChangeInitialData && this.props.editChangeInitialData.changeManagementIndividual && !isNaN(this.props.editChangeInitialData.changeManagementIndividual) && !!parseInt(this.props.editChangeInitialData.changeManagementIndividual, 10)){
		this.setState({changeIndividualValue: this.props.editChangeInitialData.changeManagementIndividual, showChangeIndividualInfo: true});
	}
	else{
		this.setState({changeIndividualValue: '', showChangeIndividualInfo: false});
	}

	if(this.props.editChangeInitialData && this.props.editChangeInitialData.cabDate){
		this.setState({cabDate: this.props.editChangeInitialData.cabDate});
	}

	if(this.props.editChangeInitialData && this.props.editChangeInitialData.type){
		this.setState({ changeType: this.props.editChangeInitialData.type });
	}
	if(this.props && this.props.editChangeInitialData && this.props.editChangeInitialData.downtimeRequired){
       this.disableEnableDate(this.props.editChangeInitialData.downtimeRequired);
	   this.setSelectedOption(this.props.editChangeInitialData.downtimeRequired);
	}

	if(this.props.editChangeInitialData){
		// console.log('this.props.editChangeInitialData - ', this.props.editChangeInitialData)
		let response={
			data:{
				'controlEffectiveness':(this.props.editChangeInitialData && this.props.editChangeInitialData.controlEffectiveness) ? this.props.editChangeInitialData.controlEffectiveness : '',
				'id':'',
				'overallRisk':(this.props.editChangeInitialData && this.props.editChangeInitialData.risk) ? this.props.editChangeInitialData.risk : '',
				'riskImpact':(this.props.editChangeInitialData && this.props.editChangeInitialData.riskImpact) ? this.props.editChangeInitialData.riskImpact : '',
				'riskOccurrenceProbability':(this.props.editChangeInitialData && this.props.editChangeInitialData.riskOccurrenceProbability) ? this.props.editChangeInitialData.riskOccurrenceProbability : ''
			}
		};
		this.props.dispatch({type: 'RISK_ASSESSMENT',response});
	}
	this.props.dispatch(change("changeEditForm", "supportCompanyID", this.props.editChangeInitialData.supportCompanyId));
	this.props.dispatch(change("changeEditForm", "supportCompanyName", this.props.editChangeInitialData.supportCompanyName));
  }
  componentWillUnmount() {
	GLOBAL.ciClass = '';
	GLOBAL.implementationIndividual = '';
	GLOBAL.businessCriticalityId=GLOBAL.businessCriticality='';
	}
	userChoices(myOrderQuestionDetails) {
		if(myOrderQuestionDetails==undefined ||  myOrderQuestionDetails.length<=0){
			return <ListLoader />;
		}
		return [].concat(myOrderQuestionDetails).sort((a, b) => a.categorySequence > b.categorySequence ? 1 : -1).map((category,i) => {
			return(
					<Accordion id={'questions'+i} accordion className="brkFix brkFixOrder" defaultActiveKey={i}>
						<Accordion.Item eventKey={i} className="">
							<Accordion.Header><h4 toggle className="font1">{category.categoryName}</h4></Accordion.Header>
							<Accordion.Body collapsible>
								<div className="table-responsive" key={i}>
									<Table bordered condensed hover responsive className="tableView sortImg">
										<tbody>
											{this.renderIndividualCategoryQuestions(category.questions)}
										</tbody>
									</Table>
								</div>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
			);
		})
	}
	renderIndividualCategoryQuestions(categoryQuestions) {
		let answers = "";
		return [].concat(categoryQuestions).sort((a, b) => a.questionSequence > b.questionSequence ? 1 : -1).map((categoryQuestion, i) => {
			if (categoryQuestion.question == "Assets") {
				//	console.log("assets answer");
				//	console.log(categoryQuestion.answer);
				let newstringreplaced = "";
				if(typeof categoryQuestion.answer==='undefined' || categoryQuestion.answer===null || categoryQuestion.answer===''){
					answers='NA';
				}else if (categoryQuestion.answer != null && categoryQuestion.answer != "") {
					newstringreplaced = categoryQuestion.answer.replace(/,Name/gi, "~Name");
					answers = newstringreplaced.split('~');
				}
				else {
					answers = categoryQuestion.answer;
				}
			}
			return (
				<tr key={i}>
					<td className="bgBtn20 f-size-11" style={{ "width": "50%" }}>{categoryQuestion.question}</td>
					<td className="f-size-11">
						{categoryQuestion.question !== "Assets" ? typeof categoryQuestion.answer==='undefined' || categoryQuestion.answer===null || categoryQuestion.answer===''? 'NA':( 
							categoryQuestion.answer.replace(/ ~ /g, "\n").split("\n").map((data) => {
								console.log(data)
								return <p>{data}</p>
							})
						) : answers != null ? (
							<div className="assetsAnswers">
								{answers.map((a, i) => {
									return <p>{a}</p>;
								})}
								;
							</div>
						) : null}
					</td>
				</tr>
			);
		});
	}
	resettingConsumerInfoState(value = ''){
		value=value!==''&&value!='0'&&value!=0?value:this.props.editChangeInitialData.requesterId!=''&&this.props.editChangeInitialData.requesterId!='0'&&this.props.editChangeInitialData.requesterId!=0?this.props.editChangeInitialData.requesterId:'';
		this.setState({requestorValue: value, showRequestorInfo: value!=''?true:false});
	}
	resetImpactedCIValue(){
		let impactedCIValue=this.props.editChangeInitialData.ciId!==''&&this.props.editChangeInitialData.ciId!=='0'&&this.props.editChangeInitialData.ciId!==0?this.props.editChangeInitialData.ciId:'';
		this.setState({impactedIDSelected: impactedCIValue, isCiNameInfoIcn: impactedCIValue!==''?true:false});
	}
	onRequestorType(value){
		this.setState({showRequestorInfo: value});
	}
	onImpactCIType(value){
		this.setState({isCiNameInfoIcn: value});
	}
	setErrorColorRequestor(errorColor,fieldType){
		this.setState({errorRequestor: errorColor});
	}
	setErrorColorImpactedCI(errorColor, fieldType){
		this.setState({errorImpactedCI: errorColor});
	}
 render(){
	let {
		type,
		changeTiming,
		businessRisk,
		technicalRisk,
		planType,
		implementationAndChangeManagementGroup,
		ChangeManagementGroup,

		GroupInd,
		GroupIndividual,
		changeMngmntIndividual,
		isFetching,
		urgency,
		reasonForChange,
		riskOccurrenceProbability,
		riskImpact,
		controlEffectiveness,
		cmdbUpdateNeeded
	} = this.props;
	let downTimeDate=this.state.downTimeLanding ? this.props.initialValues.downtimeRequired === 'N' : this.state.downTimeDateDisabled;
	GLOBAL.overallRisk = this.props.riskAssessmentValue.overallRisk;

	if (this.props.editChangeInitialData.changeTiming == "20" && GLOBAL.checkchange == "true") {
		GLOBAL.changetime = "true"
	}
	else {
		GLOBAL.changetime = "false"
	}
	GLOBAL.changeId = this.props.initialValues.changeId;
	if ((this.props.initialValues.changeId == undefined && this.props.isFetching) || this.props.isFetchingDetails) {
		return (
			<ListLoader />
		);
	} else {

		GLOBAL.ciId = this.props.initialValues.ciId;
		GLOBAL.ciName = this.props.initialValues.ciName;
	}
	riskOccurrenceProbability.map((a) => {
		a.field1Value = parseInt(a.field1Value, 10);

	})
	riskImpact.map((a) => {
		a.field1Value = parseInt(a.field1Value, 10);
	})
	controlEffectiveness.map((a) => {
		a.field1Value = parseInt(a.field1Value, 10);
	})
	riskOccurrenceProbability.sort((a, b) => (a.field1Value > b.field1Value) ? 1 : -1);
	riskImpact.sort((a, b) => (a.field1Value > b.field1Value) ? 1 : -1);
	controlEffectiveness.sort((a, b) => (a.field1Value < b.field1Value) ? 1 : -1);
	reasonForChange.sort((a, b) => a.field1Key.localeCompare(b.field1Key));
    return (<div>
		{this.props && this.props.showTimelineFlag ?
			<GenericTimeline translator={this.props.translator} changeManagementStatus={this.props.initialValues} auditDetails={this.props.breakFixAuditLogDetails}
			                 isFetching={this.props.isFetching} itemId={this.props.changeId} module={"ChangeManagement"} showTimeline={this.props.showTimeline}/>
		:null}
		{this.props.conflictFormVal ?
		    <DialogForm initialData={this.props.initialValues} translator={this.props.translator} showConflictForm={this.props.showConflictForm}/>
		:""}
        <div className="borderForm bFormDiv">
		    <Row>
			  <Col md={4}>
			    <Form.Group>
					<Form.Label bsClass=""><span className="rStar" /> {this.props.translator['Company']}</Form.Label>
						<Field name="consumerCompanyName" type="text" disabled="true" className="form-control" component="input"/>
				</Form.Group>
			  </Col>
			  <Col md={4}>
				<Form.Group>
					<Form.Label bsClass=""><span className="rStar" /> {this.props.translator['Type']}</Form.Label>
						<Field name="type" onChange={(e) => { this.onTypeSelect(e) }} component={_dropDown} disabledValue={this.props.fieldStatus.type == true && !this.props.disableFieldsForGuestRole ? false : true}
						       options={type} className="form-control" module="editChangeMgmt"/>
				</Form.Group>
			  </Col>
			  {/* <Col md={4}>
				<Form.Group>
					<Form.Label bsClass=""><span className="rStar" /> {this.props.translator['Summary']}</Form.Label>
						<Field name="summary" type="textarea" disabledValue={this.props.fieldStatus.summary == true ? false : true} component={_textArea}
						label="" className="form-control" rows="1" maxlength="500"onChange={this.onSummaryInputChange}/>
                  <p className="charLeft" style={{textAlign:"right","fontSize":"11px"}}>({this.props.translator['Characters Remaining']}: {this.state.summaryCharCountLeft}/500)</p>
				</Form.Group>
			  </Col> */}
			  <Col md={4}>
				{this.props.initialValues.status == "Draft" ?
								<Form.Group>
									<Form.Label bsClass=""><span className="rStar" /> {this.props.translator['Requestor']}</Form.Label>
									<div className="infoBtnDiv typhdRig placeholderActive">
										<Field name="requestor" options={consumerDataDetails} disabled={this.props.fieldStatus.requestor == true && !this.props.disableFieldsForGuestRole ? false : true} companyId={this.state.companyId}
											component={RFReactConsumer}	onConsumerSelected={this.onConsumerSelected} placeHolder={this.props.initialValues.requestor}
											resettingConsumerInfoState={this.resettingConsumerInfoState} onType={this.onRequestorType}  errorColor={this.state.errorRequestor} setErrorColor={this.setErrorColorRequestor}/>
										{this.state.showRequestorInfo ?
										<Button bsPrefix=' ' title="Info" bsClass="" className="infoicn" onClick={() => { this.props.changeActionView('consumer', this.state.requestorValue, 'Requestor Details'); this.props.rightEditPanel(true); }}>
											<ImInfo/>
										</Button>
										:null}
									</div>
								</Form.Group>
								:
								<Form.Group>
									<Form.Label bsClass=""><span className="rStar" /> {this.props.translator['Requestor']}</Form.Label>
									<div className="infoBtnDiv typhdRig">
										<Field name="requestor" type="text" component={_inputField} label="" className="form-control" disabledValue={this.props.fieldStatus.requestor == true && !this.props.disableFieldsForGuestRole ? false : true}/>
										{this.state.showRequestorInfo ?
										<Button bsPrefix=' ' title="Info" bsClass="" className="infoicn" onClick={() => { this.props.changeActionView('consumer', this.state.requestorValue, 'Requestor Details'); this.props.rightEditPanel(true); }}>
											<ImInfo/>
										</Button>
										:null}
									</div>
								</Form.Group>
				}
				</Col>
				<Col md={4}>
				    {(this.props.initialValues.status === "Draft" || this.props.initialValues.status === "Referred Back") && this.props.fieldStatus.impactedCI && !this.props.disableFieldsForGuestRole  ?
								<Form.Group>
									<Form.Label bsClass=""><span className="rStar" />{this.props.translator['Impacted CI']}</Form.Label>
									<div className="infoBtnDiv placeholderActive" onBlur={this.handCall} onChange={this.handCall}>
										<Field name="ciName" options={ciNameDataDetails} companyId={this.state.companyId} component={CIDetailsService}
											   onServiceSelection={this.onServiceSelection} placeHolder={this.props.initialValues.ciName} showImpactedCIInfoIcon={this.resetImpactedCIValue} onType={this.onImpactCIType}
											   setErrorImpactedColor={this.props.setErrorImpactedColor} disabled={this.props.fieldStatus.impactedCI == true ? false : true} errorColor={this.state.errorImpactedCI} setErrorColor={this.setErrorColorImpactedCI}/>
										{this.state.isCiNameInfoIcn?
										     <Button bsPrefix=' ' title="Info" bsClass="" className="infoicn" onClick={() => { this.props.changeActionView('ciId',this.state.impactedIDSelected); this.props.rightEditPanel(true); }}>
												<ImInfo/></Button>
										:null}
									</div>
								</Form.Group>
								:
								<Form.Group>
									<Form.Label bsClass=""><span className="rStar" />{this.props.translator['Impacted CI']}</Form.Label>
									<div className="infoBtnDiv">
										<Field name="ciName" type="text" component={_inputField} label="" className="form-control" readOnly/>
										<Button bsPrefix=' ' title="Info" bsClass="" className="infoicn" onClick={() => { this.props.changeActionView('ciId',this.state.impactedIDSelected); this.props.rightEditPanel(true); }}>
											<ImInfo/>
										</Button>
									</div>
								</Form.Group>
					}
				</Col>
				<Col md={4}>
					<Form.Group>
						<Form.Label bsClass="">{this.props.translator['Class']}</Form.Label>
						<Field name="ciClassName" type="text" component={_inputField} label="" className="form-control" readOnly/>
					</Form.Group>
				</Col>
			{/* </Row> */}
			{this.props.selectedType == "30" ?
						<>
							<Col md={4}>
									<Form.Group>
										<Form.Label bsClass=""><span className="rStar" />{this.props.translator['Reason for Change']}</Form.Label>
										<Field name="reasonForChange" component={_dropDown} options={reasonForChange} className="form-control"
										       disabledValue={this.props.fieldStatus.reasonForChange == true && !this.props.disableFieldsForGuestRole ? false : true}/>
									</Form.Group>
								</Col>
							<Col md={4}>
								<Form.Group>
									<Form.Label bsClass=""><span className="rStar" />{this.props.translator['CMDB Update Needed']}</Form.Label>
									<Field name="cmdbUpdateNeeded" component={_dropDown} options={cmdbUpdateNeeded} className="form-control"
									       disabledValue={this.props.fieldStatus.cmdbUpdateNeeded == true && !this.props.disableFieldsForGuestRole ? false : true}>
									</Field>
								</Form.Group>
							</Col>
						</>
			:""}
			<>
			    {(this.props.selectedType == "25" || this.props.selectedType == "35") ?
					  <Col md={4}>
						<Form.Group>
							<Form.Label bsClass=""><span className="rStar" />{this.props.translator['Reason for Change']}</Form.Label>
								<Field name="reasonForChange" component={_dropDown} options={reasonForChange} className="form-control"
								       disabledValue={this.props.fieldStatus.reasonForChange == true && !this.props.disableFieldsForGuestRole? false : true}/>
						</Form.Group>
					  </Col>
				:""}
					{this.props.selectedType == "25" || this.props.selectedType == "20" || this.props.selectedType == "35" ?
							<Col md={4}>
								<Form.Group className='form-group'>
									<Form.Label bsClass=""><span className="rStar" />{this.props.translator['CMDB Update Needed']}</Form.Label>
										<Field name="cmdbUpdateNeeded" disabledValue={this.props.fieldStatus.cmdbUpdateNeeded == true && !this.props.disableFieldsForGuestRole? false : true} component={_dropDown} options={cmdbUpdateNeeded} className="form-control"/>
								</Form.Group>
							</Col>
					:""}
				{this.props.selectedType == "25" || this.props.selectedType == "35" ?		
					<Col md={4}>
						<Form.Group>
							<Form.Label bsClass=""><span className="rStar" />{this.props.translator['Urgency']}</Form.Label>
							<Field name="urgency" component={_dropDown} options={urgency} className="form-control" disabledValue={this.props.fieldStatus.urgency == true && !this.props.disableFieldsForGuestRole ? false : true} />
						</Form.Group>
					</Col>
				:""}
			</>
			{/* <Row> */}
			{(this.props.initialValues.statusId && !(['30', '95', '75', '50'].includes( (this.props.initialValues.statusId).toString() ))
			                                    && this.props.initialValues.type
												&& parseInt(this.props.initialValues.type, 10) !== 20 ) ?
							// <div>
								<Col md={4}>
									<Form.Group>
										<Form.Label bsClass="">{this.props.translator['CAB Date']}</Form.Label>
										<div className="dateTimeSdiv dateTimeRightPos">
											<Field name="cabDate" component={_dateTimeField} className="form-control dateTimePickerField" formType="editChange"
												   disabledValue={(this.props.roleIdentification("20") && this.props.initialValues.status && !this.props.disableFieldsForGuestRole && this.props.initialValues.status == 'Under Review') ? false : true}
												   defaultValue={this.state.cabDate != "" ? this.state.cabDate : (this.props.initialValues.cabDate != "" ? this.props.initialValues.cabDate : moment().tz(timezone) )}
												   showInputValue={(this.state.cabDate != "" || this.props.initialValues.cabDate != "") ? true : false}
												   onChange={(event) => { this.setFormProperties(event, "cabDate"); }}/>
										</div>
									</Form.Group>
								</Col>
							// </div>
			:""}
			</Row>
			<Row>
				<Col md={12}>
					<Form.Group>
						<Form.Label bsClass=""><span className="rStar" /> {this.props.translator['Summary']}</Form.Label>
							<Field name="summary" type="textarea" disabledValue={this.props.fieldStatus.summary == true && !this.props.disableFieldsForGuestRole? false : true} component={_textArea}
							label="" className="form-control" rows="2" maxlength="500"onChange={this.onSummaryInputChange}/>
					<p className="charLeft" style={{textAlign:"right","fontSize":"11px"}}>({this.props.translator['Characters Remaining']}: {this.state.summaryCharCountLeft}/500)</p>
					</Form.Group>
				</Col>
			</Row>
			<Row>
				<Col md={12}>
					<Form.Group>
						<Form.Label bsClass=""><span className="rStar" /> {this.props.translator['Description']}</Form.Label>
							<Field name="description" disabledValue={this.props.fieldStatus.description == true && !this.props.disableFieldsForGuestRole? false : true} type="textarea" component={_textArea} label=""
							       className="form-control" rows="2" maxlength="16000"/>
				    </Form.Group>
				</Col>
			</Row>
			{/* {this.props.selectedType == "25" || this.props.selectedType == "30" || this.props.selectedType == "35" ?
						<div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
							{<div className="bgGray padding-5">
								{(this.state.changeType != '35') && <span className="rStar" />}
								{this.props.translator['Risk Assessment Form']}</div>}
							<Row>
								<Col md={4}>
									<Form.Group>
										<Form.Label bsClass="">{this.props.translator['Risk Occurrence Probability']}</Form.Label>
										<Field name="riskOccurrenceProbability" component={_dropDown} options={riskOccurrenceProbability}
										       onChange={(e) => this.getRiskCalculated("riskOccurrenceProbability", e)}
											   disabledValue={this.props.fieldStatus.riskAssessmentForm == true ? false : true} className="form-control"/>
									</Form.Group>
								</Col>
								<Col md={4}>
									<Form.Group>
										<Form.Label bsClass="">{this.props.translator['Impact if Risk is realized']}</Form.Label>
										<Field name="riskImpact" component={_dropDown} options={riskImpact}
											   disabledValue={this.props.fieldStatus.riskAssessmentForm == true ? false : true}
											   onChange={(e) => this.getRiskCalculated("riskImpact", e)} className="form-control"/>
									</Form.Group>
								</Col>
								<Col md={4}>
									<Form.Group>
										<Form.Label bsClass="">{this.props.translator['Control Effectiveness']}</Form.Label>
										<Field name="controlEffectiveness" component={_dropDown} options={controlEffectiveness}
											   disabledValue={this.props.fieldStatus.riskAssessmentForm == true ? false : true}
											   onChange={(e) => this.getRiskCalculated("controlEffectiveness", e)} className="form-control"/>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col md={4}>
									<Form.Group>
										<Form.Label bsClass="">{this.props.translator['Overall Risk']}</Form.Label>
										    <Field name="overallRisk" component="input" type="text" className="form-control" disabled="true"
											       placeholder={this.props.riskAssessmentValue.overallRisk == undefined ?this.props.editChangeInitialData.risk : this.props.riskAssessmentValue.overallRisk}
											       value={this.props.riskAssessmentValue.overallRisk == undefined ? this.props.editChangeInitialData.risk : this.props.riskAssessmentValue.overallRisk}>
										</Field>
									</Form.Group>
								</Col>
							</Row>
						</div>
						:
						(this.props.selectedType == "20" ? "" :
							<div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
								<div className="bgGray padding-5"><span className="rStar" /> {this.props.translator['Change Classification']}</div>
								<Row>
									<Col md={4}>
										<Form.Group>
											<Form.Label bsClass="">{this.props.translator['Change Timing']}</Form.Label>
											<Field name="changeTiming" disabledValue={this.props.initialValues.status == "Draft" || this.props.initialValues.status === "Referred Back" ? false : true}
											       component={_dropDown} options={changeTiming} className="form-control" onChange={this.onChangeTiming}/>
										</Form.Group>
									</Col>

									<Col md={4}>
										<Form.Group>
											<Form.Label bsClass="">{this.props.translator['Overall Technical Risk']}</Form.Label>
											<Field name="overallTechnicalRisk" disabledValue={this.props.initialValues.status == "Draft" || this.props.initialValues.status === "Referred Back" ? false : true}
											       component={_dropDown1} options={technicalRisk} className="form-control"/>
										</Form.Group>
									</Col>
									<Col md={4}>
										<Form.Group>
											<Form.Label bsClass="">{this.props.translator['Overall Business Risk']}</Form.Label>
											<Field name="overallBusinessRisk" disabledValue={this.props.initialValues.status == "Draft" || this.props.initialValues.status === "Referred Back" ? false : true}
											       component={_dropDown1} options={businessRisk} className="form-control"/>
										</Form.Group>
									</Col>
								</Row>
							</div>
						)
				} */}

				{(this.props.selectedType == "25" || this.props.selectedType == "30" || this.props.selectedType == "35") && <RiskAssessmentForm 
					translator={this.props.translator}
					panelResize={this.props.panelResize}
					updateRiskAssessmentDetails = {this.props.updateRiskAssessmentDetails}
					companyId = {this.state.companyId || ''}
					data={(this.props && this.props.editChangeInitialData && this.props.editChangeInitialData.riskAssessment) ? this.props.editChangeInitialData.riskAssessment : []}
					residualRisk={(this.props && this.props.editChangeInitialData && this.props.editChangeInitialData.residualRisk) ? this.props.editChangeInitialData.residualRisk : ''}
					mode='edit'
					// isEditable={( (this.props.initialValues.status == "Draft" || this.props.initialValues.status === "Referred Back") && ( this.props.roleIdentification('19') || this.props.roleIdentification('20') || this.props.roleIdentification('51') || this.props.roleIdentification('52') ) ) ? true : false}
					isEditable={( (this.props.initialValues.status == "Draft" || this.props.initialValues.status === "Referred Back") && ( this.props.roleIdentification('19') || this.props.roleIdentification('20') || this.props.roleIdentification('51') || this.props.roleIdentification('52') ) && !(this.props.editChangeInitialData && this.props.editChangeInitialData.risk && this.props.editChangeInitialData.risk != 'null') ) ? true : false}
					changeType={this.state.changeType || ''}
					isLegacyExist = {(this.props.editChangeInitialData && this.props.editChangeInitialData.risk && this.props.editChangeInitialData.risk != 'null') ? true : false}
				/>}

				{(this.state.showChangeTiming == true || GLOBAL.changetime == "true") ?
						<Row>
							<Col md={6}>
								<div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
									<div className="bgGray padding-5"><span className="rStar" /> {this.props.translator['Schedule']}</div>
									<Row>
										<Col md={6}>
											<Form.Group>
												<Form.Label bsClass="">{this.props.translator['Actual Start Date']}</Form.Label>
												<div className="dateTimeSdiv dateTimePosTop">
													<Field name="actualStartDate" component={_latentDateTimeField} className="form-control dateTimePickerField" formType="edit"
													       disabledValue={this.props.fieldStatus.schedule == true && !this.props.disableFieldsForGuestRole ? false : true}
														   defaultValue={this.props.initialValues.actualStartDate ? this.props.initialValues.actualStartDate : moment().add(-1, 'days')}/>
												</div>
											</Form.Group>
										</Col>
										<Col md={6}>
											<Form.Group>
												<Form.Label bsClass="">{this.props.translator['Actual End Date']}</Form.Label>
												<div className="dateTimeSdiv dateTimePosTop">
													<Field name="actualEndDate" component={_latentDateTimeField} className="form-control dateTimePickerField" formType="edit"
													       disabledValue={this.props.fieldStatus.schedule == true && !this.props.disableFieldsForGuestRole ? false : true}
														   defaultValue={this.props.initialValues.actualStartDate ? this.props.initialValues.actualEndDate : moment().add(-1, 'days')}/>
												</div>
											</Form.Group>
										</Col>
									</Row>
								</div>
							</Col>
							<Col md={6}>
								<div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
									<div className="bgGray padding-5">
										<div className="float-r">
											<Form.Group className="radInpCs margin-0">
												<Field name="downtimeRad" disabled={this.props.fieldStatus.downtime == false || this.props.disableFieldsForGuestRole} component="input" type="radio" inline value="Y"
												       checked={this.state.downTimeLanding ? this.props.initialValues.downtimeRequired === 'Y' : (this.state.selectedOption === 'Y')}
													   onClick={() => { this.disableEnableDate('Y'); this.props.dispatch(change("changeForm", "downtimeRad", 'Y'));
													                    this.props.dispatch(change("changeEditForm", "startDate", moment().tz(timezone).format(jwtdf+' HH:mm:ss')));
																		this.props.dispatch(change("changeEditForm", "endDate", moment().tz(timezone).format(jwtdf+' HH:mm:ss'))); this.setSelectedOption('Y');}}/>{this.props.translator['Yes']}
												<Field defaultChecked disabled={this.props.fieldStatus.downtime == false || this.props.disableFieldsForGuestRole} component="input" type="radio" name="downtimeRad" inline value="N" checked={this.state.downTimeLanding ? this.props.initialValues.downtimeRequired === 'N' : (this.state.selectedOption === 'N')} onClick={() => { this.disableEnableDate('N'); this.props.dispatch(change("changeForm", "downtimeRad", 'N')); this.props.dispatch(change("changeEditForm", "startDate", '')); this.props.dispatch(change("changeEditForm", "endDate", '')); this.setSelectedOption('N'); }} />{this.props.translator['No']}
											</Form.Group>
										</div>
										<span className="rStar"></span>{this.props.translator['Downtime Required']}
									</div>
									<Row>
										<Col md={6}>
											<Form.Group>
												<Form.Label bsClass="">{this.props.translator['Downtime Start Date']}</Form.Label>
												<div className="dateTimeSdiv dateTimePosTop dateTimeRightPos">
													{!this.state.downTimeDateDisabled ? <Field name="lStartDate" component={_latentDateTimeField} className="form-control dateTimePickerField" formType="editChangeDowntime"
													       disabledValue={(this.state.downTimeLanding ? this.props.initialValues.downtimeRequired === 'N' : this.state.downTimeDateDisabled) || (this.props.fieldStatus.downtime == false || this.props.disableFieldsForGuestRole)}
														   defaultValue={downTimeDate==true?"":this.state.startDate != "" ? this.state.startDate:this.props.initialValues.lStartDate==""?"default":this.props.initialValues.lStartDate}/>
													: <Field name="lStartDate" type="text" component={_inputField} label="" className="form-control" value='' readOnly/> }
												</div>
											</Form.Group>
										</Col>
										<Col md={6}>
											<Form.Group>
												<Form.Label bsClass="">{this.props.translator['Downtime End Date']}</Form.Label>
												<div className="dateTimeSdiv dateTimePosTop dateTimeRightPos">
													{!this.state.downTimeDateDisabled? <Field name="lEndDate" component={_latentDateTimeField} className="form-control dateTimePickerField"
													       disabledValue={(this.state.downTimeLanding ? this.props.initialValues.downtimeRequired === 'N' : this.state.downTimeDateDisabled) || (this.props.fieldStatus.downtime == false || this.props.disableFieldsForGuestRole)}
														   formType="editChangeDowntime" defaultValue={downTimeDate==true?"":this.state.endDate != "" ? this.state.endDate:this.props.initialValues.lEndDate==""?"default":this.props.initialValues.lEndDate}/>
													: <Field name="lEndDate" type="text" component={_inputField} label="" className="form-control" value='' readOnly/>}
												</div>
											</Form.Group>
										</Col>
									</Row>
								</div>
							</Col>
						</Row>
						:
						<Row>
							<Col md={6}>
								<div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
									<div className="bgGray padding-5"><span className="rStar" /> {this.props.translator['Schedule']}</div>
									<Row>
										{this.props.editChangeInitialData.status !== 'Completed' ?
											<Col md={6}>
												<Form.Group>
													<Form.Label bsClass="">{this.props.translator['Scheduled Start Date']}</Form.Label>
													<div className="dateTimeSdiv dateTimePosTop">
														<Field name="expectedStartDate" component={_dateTimeField} className="form-control dateTimePickerField"
															   formType="editChange" disabledValue={this.props.fieldStatus.schedule == true && this.props.editChangeInitialData.status === 'Draft' && !this.props.disableFieldsForGuestRole ? false : true}
															//    defaultValue={this.state.expectedStartDate != "" ? this.state.expectedStartDate : this.props.initialValues.expectedStartDate}
															defaultValue={this.state.expectedStartDate || ''}
															   onChange={(event) => { this.setFormProperties(event, "expectedStartDate"); }}
														/>
													</div>
												</Form.Group>
											</Col>
											:
											<Col md={6}>
												<Form.Group>
													<Form.Label bsClass="">{this.props.translator['Scheduled Start Date']}</Form.Label>
													<div className="dateTimeSdiv dateTimePosTop">
														<Field name="expectedStartDate" component={_dateTimeField} className="form-control dateTimePickerField"
															   formType="editChange" disabledValue={this.props.fieldStatus.schedule == true && this.props.editChangeInitialData.status === 'Draft' && !this.props.disableFieldsForGuestRole ? false : true}
															//    defaultValue={this.state.expectedStartDate != "" ? this.state.expectedStartDate : this.props.initialValues.expectedStartDate}
															defaultValue={this.state.expectedStartDate || ''}
															   />
													</div>
												</Form.Group>
											</Col>
										}
										{this.props.editChangeInitialData.status !== 'Completed' ?
											<Col md={6}>
												<Form.Group>
													<Form.Label bsClass="">{this.props.translator['Scheduled End Date']}</Form.Label>
													<div className="dateTimeSdiv dateTimePosTop">
														<Field name="expectedEndDate" component={_dateTimeField} className="form-control dateTimePickerField" formType="editChange"
														       disabledValue={this.props.fieldStatus.schedule == true && this.props.editChangeInitialData.status === 'Draft' && !this.props.disableFieldsForGuestRole? false : true}
															//    defaultValue={this.state.expectedEndDate != "" ? this.state.expectedEndDate : this.props.initialValues.expectedEndDate}
															defaultValue={this.state.expectedEndDate || ''}
															   onChange={(event) => { this.setFormProperties(event, "expectedEndDate"); }}
															   />
													</div>
												</Form.Group>
											</Col>
											:
											<Col md={6}>
												<Form.Group>
													<Form.Label bsClass="">{this.props.translator['Scheduled End Date']}</Form.Label>
													<div className="dateTimeSdiv dateTimePosTop">
														<Field name="expectedEndDate" component={_dateTimeField} className="form-control dateTimePickerField" formType="editChange"
														       disabledValue={this.props.fieldStatus.schedule == true && this.props.editChangeInitialData.status === 'Draft' && !this.props.disableFieldsForGuestRole? false : true}
															//    defaultValue={this.state.expectedEndDate != "" ? this.state.expectedEndDate : this.props.initialValues.expectedEndDate}
															defaultValue={this.state.expectedEndDate || ''}
															   />
													</div>
												</Form.Group>
											</Col>
										}
									</Row>
								</div>
							</Col>
							{this.props.selectedType == "20" ? "" :
								<Col md={6}>
									<div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
										<div className="bgGray padding-5">
											<div className="float-r">
												<Form.Group className="margin-0">
													<Field name="downtimeRad" disabled={this.props.fieldStatus.downtime == false || this.props.disableFieldsForGuestRole} component="input" type="radio" inline value="Y"
													       checked={this.state.downTimeLanding ? this.props.initialValues.downtimeRequired === 'Y' : (this.state.selectedOption === 'Y')}
														   onClick={() => { this.disableEnableDate('Y'); this.props.dispatch(change("changeForm", "downtimeRad", 'Y'));
														                    this.props.dispatch(change("changeEditForm", "startDate", moment().tz(timezone).format(jwtdf+' HH:mm:ss')));
																			this.props.dispatch(change("changeEditForm", "endDate", moment().tz(timezone).format(jwtdf+' HH:mm:ss'))); this.setSelectedOption('Y'); }}/>{this.props.translator['Yes']}
													<Field component="input" disabled={this.props.fieldStatus.downtime == false || this.props.disableFieldsForGuestRole} type="radio" name="downtimeRad" inline value="N"
													       checked={this.state.downTimeLanding ? this.props.initialValues.downtimeRequired === 'N' : (this.state.selectedOption === 'N')}
														   onClick={() => { this.disableEnableDate('N'); this.props.dispatch(change("changeForm", "downtimeRad", 'N'));
														                    this.props.dispatch(change("changeEditForm", "startDate", '')); this.props.dispatch(change("changeEditForm", "endDate", ''));
																			this.setSelectedOption('N'); }}/>{this.props.translator['No']}
												</Form.Group>
											</div>
											<span className="rStar"></span>{this.props.translator['Downtime Required']}
										</div>
										<Row>
											<Col md={6}>
												<Form.Group>
													<Form.Label bsClass="">{this.props.translator['Downtime Start Date']}</Form.Label>
													<div className="dateTimeSdiv dateTimePosTop dateTimeRightPos">
													{!this.state.downTimeDateDisabled ? <Field name="startDate" component={_dateTimeField} className="form-control dateTimePickerField" formType="editChangeDowntime"
														       disabledValue={(this.state.downTimeLanding ? this.props.initialValues.downtimeRequired === 'N' : this.state.downTimeDateDisabled) || (this.props.fieldStatus.downtime == false || this.props.disableFieldsForGuestRole)}
															   defaultValue={downTimeDate==true?"":this.state.startDate != "" ? this.state.startDate:this.props.initialValues.startDate==""?"default":this.props.initialValues.startDate}/>
												    : <Field name="startDate" type="text" component={_inputField} label="" className="form-control" value='' readOnly/>}
													</div>
												</Form.Group>
											</Col>
											<Col md={6}>
												<Form.Group>
													<Form.Label bsClass="">{this.props.translator['Downtime End Date']}</Form.Label>
													<div className="dateTimeSdiv dateTimePosTop dateTimeRightPos">
													{!this.state.downTimeDateDisabled ?<Field name="endDate" component={_dateTimeField} className="form-control dateTimePickerField" formType="editChangeDowntime"
														       disabledValue={(this.state.downTimeLanding ? this.props.initialValues.downtimeRequired === 'N' : this.state.downTimeDateDisabled) || (this.props.fieldStatus.downtime == false || this.props.disableFieldsForGuestRole)}
															   defaultValue={downTimeDate==true?"":this.state.endDate != "" ? this.state.endDate:this.props.initialValues.endDate==""?"default":this.props.initialValues.endDate}/>
												    : <Field name="endDate" type="text" component={_inputField} label="" className="form-control" value='' readOnly/>}  
													</div>
												</Form.Group>
											</Col>
										</Row>
									</div>
								</Col>
							}
							{this.props.selectedType == "20" ?
								<Col md={6}>
									<div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
										<div className="bgGray padding-5">
										<Row>
											<Col md={6} className='m-0'>
											{this.props.editChangeInitialData.statusId==='30'?'':<span className="rStar"></span>} {this.props.translator['Implementation']}
											</Col>
											<Col md={6} className='m-0'>
											{this.props.fieldStatus.showAllGroups && this.props.fieldStatus.implementationGroupIndividual && !this.props.disableFieldsForGuestRole ?
										    <span className="float-r">{this.state.AllImplmtAMSGrps == false ?
											       <a className="showwAllGrps" href='javascript:void(0)' onClick={this.AllimplmtGrps}>Show ALL Groups</a>
												   :
												   <a className="showwAllGrps" href='javascript:void(0)' onClick={this.ImplmtAMSGrp}>Show AMS Groups</a>}</span>
										:null}
											</Col>
										</Row>
										</div>
										<Row>
											<Col md={6}>
												<Form.Group>
													<Form.Label bsClass="">{this.props.translator['Group']}</Form.Label>
													  <div className="dvTypehdropdown">
															  <RuleAmsDdropDown
																   id="implmnt-group"
															     	 name="implementationGroup" 
																	 className="form-control"
																	 component={TypeaheadExampleSingleSelect}
																	 groupData={implementationAndChangeManagementGroup}
																	 onSelection={this.setImpgroupval}
																	 selectedOptions={this.state.selectedImpgroups}
																	 setErrorColor={this.seterrorimpgroupColor}
																	 disabled={this.props.fieldStatus.implementationGroupIndividual == true && !this.props.disableFieldsForGuestRole ? false : true}
																	 onInputChange={this.onGroupInputChangeI}
																	 onCrossClick={this.onCrossClickGroupI}
																	 typedValue={this.state.typedGroupI}
																	 errorClass={this.props.impGroupErr && this.props.editChangeInitialData.statusId!=='30' ?'error':''}
															     	 moduleName="changemanagement"
																	 showAms={this.state.AllImplmtAMSGrps}
									                 				 ruleType="assignment"
																	 formData={this.props}
																	 rulesAttribute={this.state?.rulesAttribute}
																	 ruleFor="implementation"
																	/>
													       {/* <Field name="implementationGroup" className="form-control"  component={TypeaheadExampleSingleSelect} options={implementationAndChangeManagementGroup}
														          onSelection={this.setImpgroupval} selectedOptions={this.state.selectedImpgroups} setErrorColor={this.seterrorimpgroupColor}
																  disabled={this.props.fieldStatus.implementationGroupIndividual == true && !this.props.disableFieldsForGuestRole ? false : true} onInputChange={this.onGroupInputChangeI}
													              onCrossClick={this.onCrossClickGroupI} typedValue={this.state.typedGroupI} errorClass={this.props.impGroupErr && this.props.editChangeInitialData.statusId!=='30' ?'error':''}/> */}
													</div>
												</Form.Group>
											</Col>
											<Col md={6}>
												<Form.Group className={this.state.showImpIndividualInfo ? "prblmBtnDv infoBtnDiv typeHeadicn downinfobtn form-group" : "chngBtnDv infoBtnDiv typeHeadicn downinfobtn form-group"}>
													<Form.Label bsClass="">{this.props.translator['Individual']}</Form.Label>
													   <div className="dvTypehdropdown dvTypehdPos">
													      <Field name="implementationIndividual" className="form-control"  component={TypeaheadExampleSingleSelect} options={GroupInd} onSelection={this.setImpindval}
														         selectedOptions={this.state.selectedImpinds} setErrorColor={this.seterrorimpindColor}
																 disabled={this.props.fieldStatus.implementationGroupIndividual == true && !this.props.disableFieldsForGuestRole ? false : true} onInputChange={this.onIndividualInputChangeI}
													             onCrossClick={this.onCrossClickIndividualI} typedValue={this.state.typedIndividualI}/>
													   </div>
													   <div className={this.state.showImpIndividualInfo || this.state.selectedImpgroups.length>0?"indiIcnDv":""}>
													{this.state.selectedImpgroups.length>0?(
										<Button 
										title={this.props.translator["Show available individuals"]}
										bsPrefix=" " 
										className="asignicn"
										disabled={this.props.fieldStatus.implementationGroupIndividual == true && !this.props.disableFieldsForGuestRole ? false : true} 
										onClick={() => { 
										  this.props.changeActionView("showAvailableIndividualsImp");
										  this.props.rightEditPanel(true);}}>
										  <RiUserSearchLine />
										  </Button>
										  ):""
										}
										{this.state.showImpIndividualInfo ?
													     <Button bsPrefix=' ' title="Info" bsClass="" className="asignicn"
														         onClick={() => { this.props.changeActionView('individual', this.state.impIndividualValue, 'Individual Details'); this.props.rightEditPanel(true); }}>
																	<ImInfo/>
														 </Button>
													:null}
										 </div>
												</Form.Group>
											</Col>
										</Row>
									</div>
								</Col>
							:""}
						</Row>
				}
				<Row>
				    {this.props.selectedType == "20" ? "" :
							<Col md={6}>
								<div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
									<div className="bgGray padding-5">
										<Row>
											<Col md={6} className='m-0'>
											{this.props.editChangeInitialData.statusId==='30'?'':<span className="rStar"></span>}
									     {this.props.translator['Implementation']}
											</Col>
											<Col md={6} className='m-0'>
											{this.props.fieldStatus.showAllGroups && this.props.fieldStatus.implementationGroupIndividual && !this.props.disableFieldsForGuestRole?
										      <span className="float-r">
												{this.state.AllImplmtAMSGrps == false ?
												    <a className="showwAllGrps" href='javascript:void(0)' onClick={this.AllimplmtGrps}>Show ALL Groups</a>
													:
													<a className="showwAllGrps" href='javascript:void(0)' onClick={this.ImplmtAMSGrp}>Show AMS Groups</a>}</span>
										:null}
											</Col>
										</Row>										 
									</div>
									<Row>
										<Col md={6}>
											<Form.Group>
												<Form.Label bsClass="">{this.props.translator['Group']}</Form.Label>
												<div className="dvTypehdropdown">
												<RuleAmsDdropDown
													id="implmnt-group"
													name="implementationGroup" 
													className="form-control"
													component={TypeaheadExampleSingleSelect}
													groupData={implementationAndChangeManagementGroup}
													onSelection={this.setImpgroupval}
													selectedOptions={this.state.selectedImpgroups}
													setErrorColor={this.seterrorimpgroupColor}
													disabled={this.props.fieldStatus.implementationGroupIndividual == true && !this.props.disableFieldsForGuestRole ? false : true}
													onInputChange={this.onGroupInputChangeI}
													onCrossClick={this.onCrossClickGroupI}
													typedValue={this.state.typedGroupI}
													errorClass={this.props.impGroupErr && this.props.editChangeInitialData.statusId!=='30' ?'error':''}
													moduleName="changemanagement"
													showAms={this.state.AllImplmtAMSGrps}
													ruleType="assignment"
													formData={this.props}
													rulesAttribute={this.state?.rulesAttribute}
													ruleFor="implementation"
												/>
												{/* <Field name="implementationGroup" className="form-control"  component={TypeaheadExampleSingleSelect} options={implementationAndChangeManagementGroup}
												       onSelection={this.setImpgroupval} selectedOptions={this.state.selectedImpgroups} setErrorColor={this.seterrorimpgroupColor}
													   disabled={this.props.fieldStatus.implementationGroupIndividual == true && !this.props.disableFieldsForGuestRole ? false : true} onInputChange={this.onGroupInputChangeI}
												       onCrossClick={this.onCrossClickGroupI} typedValue={this.state.typedGroupI} errorClass={this.props.impGroupErr && this.props.editChangeInitialData.statusId!=='30' ?'error':''}/> */}
												</div>
											</Form.Group>
										</Col>
										<Col md={6}>
											<Form.Group className={this.state.showImpIndividualInfo ? "prblmBtnDv infoBtnDiv typeHeadicn downinfobtn form-group" : "chngBtnDv infoBtnDiv typeHeadicn downinfobtn form-group"}>
												<Form.Label bsClass="">{this.props.translator['Individual']}</Form.Label>
												<div className="dvTypehdropdown">
												    <Field name="implementationIndividual" className="form-control" component={TypeaheadExampleSingleSelect} options={GroupInd} onSelection={this.setImpindval}
													       selectedOptions={this.state.selectedImpinds} setErrorColor={this.seterrorimpindColor}
														   disabled={this.props.fieldStatus.implementationGroupIndividual == true && !this.props.disableFieldsForGuestRole ? false : true} onInputChange={this.onIndividualInputChangeI}
												           onCrossClick={this.onCrossClickIndividualI} typedValue={this.state.typedIndividualI}/>
												</div>
											<div className={this.state.showImpIndividualInfo || this.state.selectedImpgroups.length>0?"indiIcnDv":""}>
												{this.state.selectedImpgroups.length>0?(
										<Button 
										title={this.props.translator["Show available individuals"]}
										bsPrefix=" " 
										className="asignicn"
										disabled={this.props.fieldStatus.implementationGroupIndividual == true && !this.props.disableFieldsForGuestRole ? false : true}  
										onClick={() => { 
										  this.props.changeActionView("showAvailableIndividualsImp");
										  this.props.rightEditPanel(true);}}>
										  <RiUserSearchLine />
										  </Button>
										  ):""
										}
										{this.state.showImpIndividualInfo ?
													<Button bsPrefix=' ' title="Info" bsClass="" className="asignicn"
													        onClick={() => { this.props.changeActionView('individual', this.state.impIndividualValue, 'Individual Details');this.props.rightEditPanel(true); }}>
																<ImInfo/>
													</Button>
												:null}
										</div> 
											</Form.Group>
										</Col>
									</Row>
								</div>
							</Col>
					}
					<Col md={6}>
					     <div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
						      <div className="bgGray padding-5">
								<Row>
									<Col md={6} className='m-0'>
									{this.props.editChangeInitialData.statusId==='30'?'':<span className="rStar"></span>}{this.props.translator['Change Management']}
									</Col>
									<Col md={6} className='m-0'>
									{this.props.fieldStatus.showAllGroups && this.props.fieldStatus.changemanagementGroupIndividual && !this.props.disableFieldsForGuestRole?
								       <span className="float-r">
										  {this.state.AllAMSGrps == false ?
										    <a className="showwAllGrps" href='javascript:void(0)' onClick={this.AllGrps}>Show ALL Groups</a> :
											<a className="showwAllGrps" href='javascript:void(0)' onClick={this.AllAMSGrps}>Show AMS Groups</a>}
									   </span>
								:null}
									</Col>
								</Row>
							 
						      </div>
							  <Row>
							    <Col md={6}>
								  <Form.Group>
									<Form.Label bsClass="">{this.props.translator['Group']}</Form.Label>
									<div className="dvTypehdropdown">									
									<RuleAmsDdropDown
									  id='changeMgmtgroup'
									  name="changeManagementGroup"
										className="form-control"
										disabled={this.props.fieldStatus.changemanagementGroupIndividual == true && !this.props.disableFieldsForGuestRole ? false : true}
									  component={TypeaheadExampleSingleSelect}
										groupData={ChangeManagementGroup}
										onSelection={this.setChgroupval}
										selectedOptions={this.state.selectedChgroups}
								    setErrorColor={this.seterrorChgroupColor}
									  onInputChange={this.onGroupInputChangeC}
									  onCrossClick={this.onCrossClickGroupC}
										typedValue={this.state.typedGroupC}
										errorClass={this.props.chgGroupErr && this.props.editChangeInitialData.statusId!=='30'?'error':''}
										moduleName="changemanagement"
										showAms={this.state.AllAMSGrps}
										ruleType="assignment"
										formData={this.props}
										rulesAttribute={this.state?.rulesAttribute}
										ruleFor="changemanager"
									/> 
									    {/* <Field name="changeManagementGroup" className="form-control" disabled={this.props.fieldStatus.changemanagementGroupIndividual == true && !this.props.disableFieldsForGuestRole ? false : true}
									           component={TypeaheadExampleSingleSelect} options={ChangeManagementGroup} onSelection={this.setChgroupval} selectedOptions={this.state.selectedChgroups}
										       setErrorColor={this.seterrorChgroupColor} onInputChange={this.onGroupInputChangeC} onCrossClick={this.onCrossClickGroupC} typedValue={this.state.typedGroupC} errorClass={this.props.chgGroupErr && this.props.editChangeInitialData.statusId!=='30'?'error':''}/> */}
									</div>
								  </Form.Group>
								</Col>
								<Col md={6}>
									<Form.Group className={this.state.showChangeIndividualInfo ? "prblmBtnDv infoBtnDiv typeHeadicn downinfobtn form-group" : "chngBtnDv infoBtnDiv typeHeadicn downinfobtn form-group"}>
									   <Form.Label bsClass="">{this.props.translator['Individual']}</Form.Label>
									      <div className="dvTypehdropdown dvTypehdPos">
											   <Field name="changeManagementIndividual" disabled={this.props.fieldStatus.changemanagementGroupIndividual == true && !this.props.disableFieldsForGuestRole ? false : true}
											          component={TypeaheadExampleSingleSelect} options={changeMngmntIndividual} onSelection={this.setChgindval} selectedOptions={this.state.selectedChginds}
													  setErrorColor={this.seterrorchgindColor} onInputChange={this.onIndividualInputChangeC} onCrossClick={this.onCrossClickIndividualC}
									                  typedValue={this.state.typedIndividualC}/>
										  </div>
										  <div className={this.state.showChangeIndividualInfo || this.state.selectedChgroups.length>0?"indiIcnDv":""}>
										  {this.state.selectedChgroups.length>0?(
										<Button 
										title={this.props.translator["Show available individuals"]}
										bsPrefix=" " 
										className="asignicn" 
										disabled={this.props.fieldStatus.changemanagementGroupIndividual == true && !this.props.disableFieldsForGuestRole ? false : true}
										onClick={() => { 
										  this.props.changeActionView("showAvailableIndividuals");
										  this.props.rightEditPanel(true);}}>
										  <RiUserSearchLine />
										  </Button>
										  ):""
										}
										{this.state.showChangeIndividualInfo ?
												<Button bsPrefix=' ' title="Info" bsClass="" className="asignicn"
												        onClick={() => { this.props.changeActionView('individualChange', this.state.changeIndividualValue, 'Individual Details'); this.props.rightEditPanel(true); }}>
															<ImInfo/>
												</Button>
										  :null}
									</div>
									</Form.Group>
									</Col>
							  </Row>
						 </div>
					</Col>
			</Row>
			{
				this.props.myOrderQuestionDetails.length == 0 ? null :
						<Accordion id="rightSidePanel4" accordion className="brkFix brkFixOrder brkFullAccordion" defaultActiveKey="1">
							<Accordion.Item eventKey='1' className="collapsed rad-check-align clLeftAlign">
								<Accordion.Header>
									<h4 toggle>Information Provided</h4></Accordion.Header>
								<Accordion.Body collapsible>
								{this.userChoices(this.props.myOrderQuestionDetails)}
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					}
		</div>
	</div>)
 }
}


ChangeEditForm = reduxForm({
	form: 'changeEditForm',
	validate,
	enableReinitialize: true
})(ChangeEditForm);

const selector = formValueSelector('changeEditForm');
ChangeEditForm = connect((state) => {
	let selectedType = selector(state, 'type');
	let riskOccurrenceVal = selector(state, 'riskOccurrenceProbability');
	let riskImpactVal = selector(state, 'riskImpact');
	let controlEffectivenesVal = selector(state, 'controlEffectiveness');
	let expectedStartDateVal = selector(state, 'expectedStartDate');
	let expectedEndDateVal = selector(state, 'expectedEndDate');
	let startDateVal = selector(state, 'startDate');
	let endDateVal = selector(state, 'endDate');
	let ItemList = getFormValues("changeEditForm")(state);
	return {
		selectedType, riskOccurrenceVal, riskImpactVal, controlEffectivenesVal, expectedEndDateVal, expectedStartDateVal,
		startDateVal, endDateVal, ItemList
	}
})(ChangeEditForm);

const mapStateToProps = ({
	lovForType,
	allGroups,
	lovForChangeTiming,
	lovForBusinessRisk,
	lovForTechnicalRisk,
	lovForPlanType,
	breakFixSGAENameType,
	editChangeInitialData,
	fieldStatus,
	breakFixAuditLogDetails,
	breakFixSGAEChangeNameType,
	isFetchingTimeline,
	changeManagementGroupIndividualTypeahead,
	changeManagementGroupImplIndividualTypeahead,
	breakFixSGIName,
	lovForUrgency,
	lovForReasonForChange,
	myOrderQuestionDetails,
	lovForRiskOccurrence, lovForRiskImpact, lovForControlEffectiveness, riskAssessmentValue, showLoader3, isFetchingDetails,ItemList
}) => {

	// let lovForTypeUpd = [];
	// if(lovForType && lovForType.length){
	// 	lovForTypeUpd = [...lovForType];
	// 	lovForTypeUpd.push({
	// 		"menulistId": "",
	// 		"module": "ChangeManagement",
	// 		"field1Name": "Type",
	// 		"field1Key": "Latent Change",
	// 		"field1Value": "35"
	// 	});
	// }

	// console.log('**************** - ', breakFixSGAENameType, allGroups);
	// console.log('breakFixSGAEChangeNameType - ', breakFixSGAEChangeNameType);

	let type = lovForType == "undefined" || lovForType.length == 0 ? [] : lovForType;
	// let type = lovForTypeUpd;
	let changeTiming = lovForChangeTiming == "undefined" || lovForChangeTiming.length == 0 ? [] : lovForChangeTiming;
	let businessRisk = lovForBusinessRisk == "undefined" || lovForBusinessRisk.length == 0 ? [] : lovForBusinessRisk;
	let technicalRisk = lovForTechnicalRisk == "undefined" || lovForTechnicalRisk.length == 0 ? [] : _.sortBy(lovForTechnicalRisk, o => parseInt(o.field1Value, 10));
	let planType = lovForPlanType == "undefined" || lovForPlanType.length == 0 ? [] : lovForPlanType;
	let implementationAndChangeManagementGroup = breakFixSGAENameType == "undefined" || breakFixSGAENameType.length == 0 ? [] : GLOBAL.valueAssignImpgrp == false ? breakFixSGAENameType : allGroups;
	let ChangeManagementGroup = breakFixSGAEChangeNameType == "undefined" || breakFixSGAEChangeNameType.length == 0 ? [] : GLOBAL.valueAssign == false ? breakFixSGAEChangeNameType : allGroups;
	let GroupInd = changeManagementGroupImplIndividualTypeahead == "undefined" || changeManagementGroupImplIndividualTypeahead.length == 0 ? [] : changeManagementGroupImplIndividualTypeahead;
	let GroupIndividual = GroupInd.concat(implementationAndChangeManagementGroup);
	let changeMngmntIndividual = changeManagementGroupIndividualTypeahead == "undefined" || changeManagementGroupIndividualTypeahead.length == 0 ? [] : changeManagementGroupIndividualTypeahead;
	let urgency = lovForUrgency == "undefined" || lovForUrgency.length == 0 ? [] : lovForUrgency;
	let reasonForChange = lovForReasonForChange == "undefined" || lovForReasonForChange.length == 0 ? [] : lovForReasonForChange;
	let riskOccurrenceProbability = lovForRiskOccurrence == "undefined" || lovForRiskOccurrence.length == 0 ? [] : lovForRiskOccurrence;
	let riskImpact = lovForRiskImpact == "undefined" || lovForRiskImpact.length == 0 ? [] : lovForRiskImpact;
	let controlEffectiveness = lovForControlEffectiveness == "undefined" || lovForControlEffectiveness.length == 0 ? [] : lovForControlEffectiveness;
	let cmdbUpdateNeeded = [{ objkey: "Yes", objval: "Yes" }, { objkey: "No", objval: "No" }];

	// if (editChangeInitialData && editChangeInitialData.expectedStartDate) {
	// 	editChangeInitialData.expectedStartDate = editChangeInitialData.expectedStartDate;
	// }
	// if (editChangeInitialData && editChangeInitialData.expectedEndDate) {
	// 	editChangeInitialData.expectedEndDate = editChangeInitialData.expectedEndDate;
	// }
	if (editChangeInitialData && editChangeInitialData.actualStartDate) {
		editChangeInitialData.actualStartDate = moment(editChangeInitialData.actualStartDate, ["MM-DD-YYYY HH:mm:ss", "DD-MM-YYYY HH:mm:ss", "YYYY-MM-DD HH:mm:ss", "MM/DD/YYYY HH:mm:ss", "DD/MM/YYYY HH:mm:ss", "YYYY/MM/DD HH:mm:ss", (editChangeInitialData.date_format + " HH:mm:ss")]);
	}
	if (editChangeInitialData && editChangeInitialData.actualEndDate) {
		editChangeInitialData.actualEndDate = moment(editChangeInitialData.actualEndDate, ["MM-DD-YYYY HH:mm:ss", "DD-MM-YYYY HH:mm:ss", "YYYY-MM-DD HH:mm:ss", "MM/DD/YYYY HH:mm:ss", "DD/MM/YYYY HH:mm:ss", "YYYY/MM/DD HH:mm:ss", (editChangeInitialData.date_format + " HH:mm:ss")]);
	}
	// if (editChangeInitialData && editChangeInitialData.startDate) {
	// 	editChangeInitialData.startDate = editChangeInitialData.startDate;
	// }
	// if (editChangeInitialData && editChangeInitialData.endDate) {
	// 	editChangeInitialData.endDate = editChangeInitialData.endDate;
	// }
	if (editChangeInitialData && editChangeInitialData.lStartDate) {
		editChangeInitialData.lStartDate = moment(editChangeInitialData.lStartDate, ["MM-DD-YYYY HH:mm:ss", "DD-MM-YYYY HH:mm:ss", "YYYY-MM-DD HH:mm:ss", "MM/DD/YYYY HH:mm:ss", "DD/MM/YYYY HH:mm:ss", "YYYY/MM/DD HH:mm:ss", (jwtdf + " HH:mm:ss")]);
	}
	if (editChangeInitialData && editChangeInitialData.lEndDate) {
		editChangeInitialData.lEndDate = moment(editChangeInitialData.lEndDate);
	}
	editChangeInitialData.downtimeRad = editChangeInitialData.downtimeRequired ? editChangeInitialData.downtimeRequired : "N";
	editChangeInitialData.downtimeRequired = editChangeInitialData.downtimeRequired ? editChangeInitialData.downtimeRequired : "N";

	if (type.length != 0 && (editChangeInitialData.status == "Draft"||editChangeInitialData.status === "Referred Back") && editChangeInitialData.type!=="20") {
		type = type.filter((itm) => {
			return itm.field1Key != "Standard"
		})
	}else if(type.length != 0 && editChangeInitialData.status === "Referred Back" && editChangeInitialData.type==="20"){
		type = type.filter((item)=>{
            return item.field1Key === "Standard";
        });
    }

	return {
		editChangeInitialData: editChangeInitialData,
		fieldStatus: fieldStatus,
		initialValues: editChangeInitialData,
		type: type,
		changeTiming: changeTiming,
		businessRisk: businessRisk,
		technicalRisk: _.sortBy(technicalRisk, o => parseInt(o.field1Value, 10)),
		planType: planType,
		allGroups,
		implementationAndChangeManagementGroup: GLOBAL.valueAssignImpgrp == false ? implementationAndChangeManagementGroup : allGroups,
		breakFixAuditLogDetails,
		ChangeManagementGroup: GLOBAL.valueAssign == false ? ChangeManagementGroup : allGroups,
		isFetching: isFetchingTimeline.isFetching,
		GroupInd: GroupInd,
		GroupIndividual: GroupIndividual,
		changeMngmntIndividual: changeMngmntIndividual,
		urgency,
		reasonForChange,
		riskOccurrenceProbability,
		riskImpact,
		controlEffectiveness,
		riskAssessmentValue,
		loading: showLoader3.loading,
		cmdbUpdateNeeded,
		myOrderQuestionDetails,
		ItemList
	};
};


export default connect(mapStateToProps, {
	getLovValues,
	loadNewBreakFixSGAE,
	loadChangeEditData,
	changeFieldStatusData,
	loadNewBreakFixSGAEChange,loadNewBreakFixSGAEWithQueryParams,loadNewBreakFixSGAEChangeWithParams, loadBreakFixSGI, loadChangeManagementIndividual, loadChangeManagementImplGroupIndividual, getRiskCalculated, getAllgroup,
	loadImplPlanList,
	getChangeQuestionsCategoryWise
})(ChangeEditForm);
