
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import {useSelector } from "react-redux";
import { Col, Container, Row } from 'react-bootstrap';
import Breadcrumbs from '../../../common/header/breadcrumbs';
import CreateClassDefinitionHeader from './CreateClassDefinitionHeader';
import CreateClassDefinitionForm from './CreateClassDefinitionForm';
import "_Css/form/_form.scss";

const CreateClassDefinitionIndex = (props) => {

  const [className, setClassName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [parentError, setParentError] = useState('');
  const [statusErr, setStatusErr] = useState('');
  const [classNameError, setClassNameError] = useState('');
  const [typeError, setTypeError] = useState('');
  const [companyNameError, setCompanyNameError] = useState('');

console.log("companyName===",companyName);

  const setCompanyErrorColor = () => {
    if (companyName.length > 0) {
      console.log("Enteredddd");
      console.log("companyName===",companyName);

      setCompanyNameError("");
    } else {
      setCompanyNameError("error");
    }
  };
 
    const tr = useSelector((state) => state.spcmReducer.tr);

  return (
    <main>
    
      <Container fluid className="margin-b-15">
        <Breadcrumbs
          activePageName={"Create"}
          parentPageurl="/classDefinition"
          parentPageName={"Class"}
        />
      </Container>

      <Container fluid>
        <CreateClassDefinitionHeader
          classNameError={classNameError}
          setClassNameError={setClassNameError}
          setCompanyErrorColor={setCompanyErrorColor}
          companyName={companyName}
          setCompanyName={setCompanyName}
          companyNameError={companyNameError}
          setCompanyNameError={setCompanyNameError}
          parentError={parentError}
          setParentError={setParentError}
          typeError={typeError}
          setTypeError={setTypeError}
          statusErr={statusErr}
          setStatusErr={setStatusErr}
        />
        <Row>
          <Col md={8} xs={12}>
            <CreateClassDefinitionForm
              className={className}
              setClassName={setClassName}
              companyName={companyName}
              setCompanyName={setCompanyName}
              companyNameError={companyNameError}
              setCompanyNameError={setCompanyNameError}
              classNameError={classNameError}
              setClassNameError={setClassNameError}
              parentError={parentError}
              setParentError={setParentError}
              typeError={typeError}
              setTypeError={setTypeError}
              statusErr={statusErr}
              setStatusErr={setStatusErr}
              setCompanyErrorColor={setCompanyErrorColor}
            />
          </Col>
        </Row>
      </Container>
    </main>
  );
}

export default CreateClassDefinitionIndex
