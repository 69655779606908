
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export default function (state = initialState.breakFixSGAEChangeName, action){

	switch (action.type){
		case 'LOAD_BREAKFIXSGAECHANGE_SUCCESS':
				//alert(action.breakFix.data.);
				return action.breakFixSGAEChangeName.data;
		case 'LOAD_BREAKFIXSGAECHANGE_FAILURE':
				return action.failureMessage;
		default:
				return state;
		}

}
