
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Button, ListGroup, Nav } from "react-bootstrap";

import listenForOutsideClick from "../../../common/clickoutside/listenForOutsideClicks";

const data1 = ["Scheduled", "Cancel Meeting"];

let StatusActions = ({
  toggle,
  data,
  setIsOpen,
  handleOnSelectAction,
  mref,
  isOpen,
  meetingDefData,
  componentType,
  actionsData,
}) => {
  const [listening, setListening] = useState(false);

  const removeDownload = (data, icalURL = "") => {
    if (componentType === "MeetingForum") {
      if (icalURL) {
        return data;
      }
      return data.filter((val) => val !== "Download iCAL");
    }

    return data;
  };
  let onHamburgerBtnClick = () => {
    setIsOpen(false);
  };
  useEffect(listenForOutsideClick(listening, setListening, mref, setIsOpen));

  return (
    <div className="breakFixHamburger statusHamburger" ref={mref}>
      {isOpen && (
        <ListGroup className="myLinks" style={{ top: "5px" }}>
          {actionsData &&
            actionsData?.response.length !== 0 &&
            actionsData?.response.map(function (item, index) {
              if (Object.keys(item).length === 0) {
                return null;
              }
              return item.nextPossible.map(function (statusItem, index) {
                return (
                  <ListGroup.Item key={`ham_${index}`}>
                    <Nav.Item
                      title={statusItem["alias"]}
                      onClick={() => {
                        handleOnSelectAction(statusItem);
                        onHamburgerBtnClick();
                      }}
                    >
                      <a alt={item.alias} target="_blank" className="p-0">
                        <i className="fa fa-angle-double-right"></i>
                        {statusItem["alias"]}
                      </a>
                    </Nav.Item>
                  </ListGroup.Item>
                );
              });
            })}
        </ListGroup>
      )}
    </div>
  );
};

export default StatusActions;
