
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/


export const costCenterList = (state = [], action) => {
    if (action.type === 'COST_CENTER_LIST_FETCHED_SUCCESSFULLY') {
      return action.payload;
    } else {
      return state;
    }
};
  
export const getCostCenterEditDetails = (state = [], action) => {
    if (action.type === 'COST_CENTER_EDIT_DETAILS_FETCHED') {
      return action.payload;
    } else {
      return state;
    }
};
  
export const costCenterCompanyDropdown = (state =[],action) =>{
    if(action.type == "COMPANY_DROPDOWN_LIST"){
      return action.payload;
    }
    else{
      return state;
    }
}
  
export const costCenterStatusValues = (state =[],action) =>{
    if(action.type == "CC_STATUS_DROPDOWN_LIST"){
      return action.payload;
    }
    else{
      return state;
    }
  }