
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GLOBAL } from "../../components/Globals";

export const rulesApi = createApi({
  reducerPath: "rulesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: window.location.origin,
  }),
  tagTypes: ["AllRules"],
  endpoints: (builder) => ({
    getAllRules: builder.query({
      // query: ({ queryString = "", headers={} }) => {
      //   return GLOBAL.getAllRules + queryString;
      // },
      query: ({ queryString = "", params = {} }) => {
        return {
          url: GLOBAL.getAllRules,
          method: "GET",
          headers: {
            Query:JSON.stringify(params),
          },
        };
      },
      keepUnusedDataFor: 0,
      providesTags: ["AllRules"],
    }),
    getMenusList: builder.query({
      query: ({ module_name, field_name }) => {
        return GLOBAL.getMenusList(module_name, field_name);
      },
      keepUnusedDataFor: 0
    }),
    getQueryRawData: builder.query({
      query: ({ module_name, status='active'}) => {
        return GLOBAL.getQueryRawData(module_name, status);
      },
      invalidatesTags: ["AllRules"],
    }),
    getSuppliers: builder.query({
      query: ({ queryString = "" }) => {
        return GLOBAL.getSuppliers + queryString;
      },
    }),
    getAssociatedGrp: builder.query({
      query: ({ compId }) => {
        return GLOBAL.getAssociatedGrp(compId);
      },
    }),
    getGroupFunction: builder.query({
      query: ({ alterGCompId = "" }) => {
        return GLOBAL.getGroupFunction(alterGCompId);
      },
    }),
    saveRuleDefinition: builder.mutation({
      query: ({ data }) => ({
        url: GLOBAL.saveRuleDefinition,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["AllRules", "DetailedRule"],
    }),
    getAssociatedRule: builder.mutation({
      query: ({
        data,
        moduleName,
        ruleType = "assignment",
        companyId,
        status = "active",
        weight = "Y",
        ruleFor = "",
        serviceCompanyId = ""
      }) => ({
        url: GLOBAL.getAssociatedRule,
        method: "POST",
        body: data,
        headers: {
          Query: JSON.stringify({
            moduleName,
            ruleType,
            companyId,
            status,
            weight,
            ruleFor,
            serviceCompanyId
          }),
        },
      }),
    }),
    getRule: builder.query({
      query: ({ ruleId }) => {
        return GLOBAL.getRule(ruleId);
      },
      providesTags: (result, error, id) =>
        result
          ? [
              { type: "AllRules", id },
              { type: "DetailedRule", id: result.ruleId },
            ]
          : ["AllRules"],

    }),
    updateRuleDefinition: builder.mutation({
      query: ({ data }) => ({
        url: GLOBAL.updateRuleDefinition,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["AllRules", "DetailedRule"],
    }),
    getEscalationPlanNameList: builder.query({
      query: ({ companyId = "" }) => {
        return GLOBAL.getEscalationPlanNameList(companyId);
      },
    }),
    getWorkFlowIdList: builder.query({
      query: ({ companyId = "", module = "" }) => {
        return GLOBAL.getWorkflowIdUrl(companyId, module);
      },
    }),
    businessFunctionListData: builder.query({
      query: ({ companyId = "" }) => ({
        url: GLOBAL.getBusinessFunctionUrl,
        method: "GET",
        headers: {
          Query: JSON.stringify({ companyId }),
        },
      }),
    }),
    serviceList: builder.query({
      query: ({ companyId = "" }) => ({
        url: GLOBAL.serviceListSelfServiceCompanyId,
        method: "GET",
        headers: {
          Query: JSON.stringify({ companyId }),
        },
      }),
    }),
    getMembersList: builder.query({
      query: ({ associatedCompanyId = "", groupId = "" }) => {
        return `/api/1/companies/${associatedCompanyId}/groups/${groupId}/members`;
      },
    }),
    getAssociatedContextualRule: builder.query({
      query: ({ queryString = "" }) => {
        return GLOBAL.getAssociatedContextualRule + queryString;
      },
      keepUnusedDataFor: 0
    }),
  }),
});

export const {
  useGetAllRulesQuery,
  useGetMenusListQuery,
  useGetQueryRawDataQuery,
  useGetSuppliersQuery,
  useGetAssociatedGrpQuery,
  useGetGroupFunctionQuery,
  useSaveRuleDefinitionMutation,
  useGetRuleQuery,
  useUpdateRuleDefinitionMutation,
  useGetEscalationPlanNameListQuery,
  useGetWorkFlowIdListQuery,
  useBusinessFunctionListDataQuery,
  useServiceListQuery,
  useGetMembersListQuery,
  useGetAssociatedRuleMutation,
  useGetAssociatedContextualRuleQuery,
} = rulesApi;

const { reducer } = rulesApi;
export default reducer;
