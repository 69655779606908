
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from "axios";
//below URL used for testing purpose
//const breakFixUrl = 'https://xsm-f.mybluemix.net/api/SX_FAVOURITEOFFERINGs/';
import { GLOBAL } from "../../components/Globals";
import constanturl from "../../../src/utils/constants.js";
//let breakFixUrl =`https://xsm-f.mybluemix.net/api/breakfixitem?breakfixitemId=`;
//let breakFixUrl=`https://xsm-f.mybluemix.net/groupListSelfService?companyId=2932&group=ResolvedER`
export function loadChangeRelatedDataDetailsSuccess(changeRelatedDataDetails) {
  //alert('in success')
  return {
    type: "LOAD_CMDBCHANGERELATEDDATADETAILS_SUCCESS",
    changeRelatedDataDetails,
  };
}

export function loadRelatedOfferingDetailsSuccess(assetrelatedDataDetails) {
  //alert('in success')
  console.log("assetrelatedDataDetailsAction", assetrelatedDataDetails);
  return {
    type: "LOAD_RELATED_ASSETS_DATA_DETAILS",
    assetrelatedDataDetails,
  };
}
export function loadChangeRelatedDataDetailsFailure(errorResponse) {
  return {
    type: "LOAD_CMDBCHANGERELATEDDATADETAILS_FAILURE",
    errorResponse,
  };
}
export function loadChangeRelatedDataDetails(breakFixId, module,isWibCall="Yes") {
  //alert('action called and parameter is '+breakFixId)

  return (dispatch) => {
    //alert("EditGroupIdAction123"+breakFixId);
    dispatch({ type: "LOADER_IN_PROGRESS" });

    //alert("hiaaa::::");
    axios
      .get(GLOBAL.cmdbRelatedLogDeleteUrl + "/" + breakFixId + "/" + module + "/" + isWibCall)
      .then((changeRelatedDataDetails) => {
        if (!changeRelatedDataDetails) {
          //alert('not breakFix')
          //dispatch(loadBreakFixFailure(breakFix.statusText))
          throw Error(changeRelatedDataDetails.statusText);
        }
        return changeRelatedDataDetails;
      })
      .then((changeRelatedDataDetails) => {
        //alert('yes it is')
        dispatch(loadChangeRelatedDataDetailsSuccess(changeRelatedDataDetails));
        dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
      });
    //console.log("not calling loadBreakFixEditDetails");
  };
}

export function loadChangeRelatedDataDetailsN(breakFixId, module,isWibCall) {
  return (dispatch) => {
    dispatch({ type: "LOADER_IN_PROGRESS" });

    return axios
      .get(GLOBAL.cmdbRelatedLogDeleteUrl + "/" + breakFixId + "/" + module + "/" + isWibCall)
      .then((changeRelatedDataDetails) => {
        if (!changeRelatedDataDetails) {
          throw new Error("Change related data details not found");
        }
        dispatch(loadChangeRelatedDataDetailsSuccess(changeRelatedDataDetails));
        dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
        return changeRelatedDataDetails.data; // Return the response data
      })
      .catch((error) => {
        console.log("Error fetching change related data details:", error);
        throw error; // Rethrow the error to be handled in the component
      });
  };
}

export function loadRelatedOfferingDataDetails(ci_id, module) {
  //alert('action called and parameter is '+breakFixId)

  return (dispatch) => {
    dispatch({ type: "LOADER_IN_PROGRESS" });
    let relatedUrl = "";
    relatedUrl = GLOBAL.cmdbRelatedLogDeleteUrl + "/" + ci_id + "/" + module+ "/Yes";
    console.log("related offering url", relatedUrl);
    axios
      .get(relatedUrl)
      .then((assetrelatedDataDetails) => {
        if (!assetrelatedDataDetails) {
          //alert('not breakFix')
          //dispatch(loadBreakFixFailure(breakFix.statusText))
          throw Error(assetrelatedDataDetails.statusText);
        }
        return assetrelatedDataDetails;
      })
      .then((assetrelatedDataDetails) => {
        //alert('yes it is')
        dispatch(loadRelatedOfferingDetailsSuccess(assetrelatedDataDetails));
        dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
      });
    //console.log("not calling loadBreakFixEditDetails");
  };
}
