
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';

export function loadFunctionByPageAndRoleSuccess(functions){
 
  return { type: 'LOAD_FUNCTION_BY_PAGE_AND_ROLE_SUCCESS', functions}
}



export function loadFunctionByPageAndRole(pageId){
	return function(dispatch){
    return axios.post('/api/functions',{pageId: pageId})
    .then(response => {
      dispatch(loadFunctionByPageAndRoleSuccess(response.data));
    })
    .catch((err) => {
    });
	}
}


export function formatMinutesIntoDaysHoursMinutes(minutesValue){

  if( isNaN(parseInt(minutesValue, 10)) || parseInt(minutesValue, 10) < 1){
    return('data not available.');
  }
  else{
    minutesValue = parseInt(minutesValue, 10);
    let tempSec = (minutesValue) * 60;
    let seconds = parseInt(tempSec, 10);

    let days = Math.floor(seconds / (3600*24));
    seconds  -= days*3600*24;
    let hours   = Math.floor(seconds / 3600);
    seconds  -= hours*3600;
    let minutes = Math.floor(seconds / 60);
    seconds  -= minutes*60;

    let daysStr = '';
    let hoursStr = '';
    let minutesStr = '';
    let secondsStr = '';

    let finalStrOutput = '';

    const singularPluralCaseForDaysHoursMinutes = function (value, str, type){
      if(value > 1){
        str = value + ' ' + type + '(s)';
      }
      else if(value > 0 && value <= 1){
        str = value + ' ' + type;
      }
      else{
        str = '';
      }

      return str;
    }

    daysStr = singularPluralCaseForDaysHoursMinutes(days, daysStr, 'day');
    hoursStr = singularPluralCaseForDaysHoursMinutes(hours, hoursStr, 'hour');
    minutesStr = singularPluralCaseForDaysHoursMinutes(minutes, minutesStr, 'minute');
    secondsStr = singularPluralCaseForDaysHoursMinutes(seconds, secondsStr, 'second');

    const addingCosmeticChangesToStringLikeComma = function (value, str){
      let tempStr = '';
      if(value){
        tempStr += (' ' + str + ',');
      }
      return tempStr;
    }

    finalStrOutput += addingCosmeticChangesToStringLikeComma(days, daysStr);
    finalStrOutput += addingCosmeticChangesToStringLikeComma(hours, hoursStr);
    finalStrOutput += addingCosmeticChangesToStringLikeComma(minutes, minutesStr);
    if(finalStrOutput.slice(-1) === ','){
      finalStrOutput = finalStrOutput.slice(0, -1);
    }
    return finalStrOutput;
  }
}
