
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import {
  Row,
  Col,
  Form,
  Alert,
  ButtonToolbar,
  Button,
} from "react-bootstrap";
import {
  _textArea,
  _dropDown,
  _inputField,
  _checkbox,
} from "../../common/formFields";
import { Field, reduxForm, SubmissionError, change } from "redux-form";
import { connect } from "react-redux";
import axios from "axios";
import constants from "../../../utils/constants";
import {
  spcmLoader,
  getAllServiceListForSearch,
  getCopyOfferingListBasedOnCompany,
  getCompanyListCopy,
  getCategoryListCopy,
} from "../../../actions/spcmActions";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import swal from "sweetalert";
import {ImSpinner6} from "react-icons/im";

const validate = (values, props) => {
  let errors = {};
  if (!values.to_offering_name) {
    errors.to_offering_name = "Enter New Service Name";
  } else if (!values.to_offering_name.replace(/\s/g, "").length) {
    errors.to_offering_name = "Enter New Service Name";
  }
  if (!values.from_offering_id) {
    errors.from_offering_id = "Enter Service Name";
  }
  if (values.to_offering_name != undefined || values.to_offering_name != null) {
    if (
      values.to_offering_name.includes('"') == true ||
      values.to_offering_name.includes("'") == true
    )
      errors.to_offering_name =
        "Double Quotes (\") and Single Quotes (') are prohibited";
  }

  if (values.to_offering_name != undefined || values.to_offering_name != null) {
    if (values.to_offering_name.length > 500)
      errors.to_offering_name =
        "Service Name Size Exceeded (Max. 500 Characters)";
  }
  if (!values.company_id && props.boardPageActive) {
    errors.company_id = "Select Source Company";
  }

  if (!values.to_company_id) {
    errors.to_company_id = "Select Target Company";
  }

  if (!values.to_subcategory_id) {
    errors.to_subcategory_id = "Select Category/Sub Category";
  }
  console.log("errors.to_offering_name : ", errors.to_offering_name);
  return errors;
};

class CopyOfferingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      from_offering_id: "",
      from_offering_name: "",
      components: "true",
      attributes: "false",
      price: "false",
      orderingInformation: "false",
      orderingPreferences: "false",
      disclaimers: "false",
      entitlements: "false",
      currentOfferingId: "",
      sourceCompany: "",
      targetCompany: "",
      disableCheck: false,
      successMessage: false,
      additionalcharleft: 120,
      languageTranslation: "false"
    };
    this.submitForm = this.submitForm.bind(this);
    this.onChangeFlag = this.onChangeFlag.bind(this);
    this.onCompanySelect = this.onCompanySelect.bind(this);
  }

  componentWillMount() {
    this.props.getCompanyListCopy("");
    this.props.dispatch({
      type: "CATEGORY_LIST_COPY",
      payload: "",
    });
    this.props.dispatch({
      type: "COMPANY_COPY",
      payload: "",
    });
    this.props.dispatch({
      type: "COPY_OFFERING_FROM_SERVICE_LIST_COPY",
      payload: "",
    });
    this.setState({
      currentOfferingId:
        this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
      from_offering_name:
        this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_NAME,
    });
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: "CATEGORY_LIST_COPY",
      payload: "",
    });
    this.props.dispatch({
      type: "COMPANY_COPY",
      payload: "",
    });
    this.props.dispatch({
      type: "COPY_OFFERING_FROM_SERVICE_LIST",
      payload: "",
    });
  }
  serviceSelection(e) {
    this.setState({
      currentOfferingId: e.target.value,
      from_offering_name: e.target[e.target.selectedIndex].text,
    });
  }

  onChangeFlag(e) {
    let str = e.target.name;
    switch (str) {
      case "components":
        this.setState({ components: e.target.checked.toString() });
        break;
      case "attributes":
        this.setState({ attributes: e.target.checked.toString() });
        break;
      case "price":
        this.setState({ price: e.target.checked.toString() });
        break;
      case "orderingInformation":
        this.setState({ orderingInformation: e.target.checked.toString() });
        break;
      case "orderingPreferences":
        this.setState({ orderingPreferences: e.target.checked.toString() });
        break;
      case "disclaimers":
        this.setState({ disclaimers: e.target.checked.toString() });
        break;
      case "entitlements":
        this.setState({ entitlements: e.target.checked.toString() });
        break;
      case "languageTranslation":
        this.setState({ languageTranslation: e.target.checked.toString() });
        break;
    }
  }

  submitForm(values) {
    console.log("values", values);
    values.from_offering_id = this.state.currentOfferingId;
    values.from_offering_name = this.state.from_offering_name;
    values.attributes = this.state.attributes;
    values.price = this.state.price;
    values.orderingInformation = this.state.orderingInformation;
    values.orderingPreferences = this.state.orderingPreferences;
    values.disclaimers = this.state.disclaimers;
    values.entitlements = this.state.entitlements;
    values.languageTranslation = this.state.languageTranslation;
    values.to_offering_name = values.to_offering_name
      .trim()
      .replace(/['"]/g, "");
    this.props.spcmReducer.categoryListCopy.forEach(function (val) {
      if (val.id == values.to_subcategory_id) {
        values.to_category_name = val.categoryName;
        values.to_category_id = val.categoryId;
        values.to_subcategory_name = val.subCategoryName;
        values.to_subcategory_id = val.id;
      }
    });
    this.props.spcmReducer.companyListcopy.forEach(function (val) {
      if (val.field1Value == values.to_company_id) {
        values.to_company_name = val.field1Key;
      }
    });
    console.log("VALUES", values);
    this.props.spcmLoader("attribute", "posting");
    return axios
      .post("/api/serviceDetails/copyAnOffering", values)
      .then((response) => {
        console.log("Inside Copy Success");
        console.log(
          "this.props.insideEditOffering",
          this.props.insideEditOffering
        );
        if (this.props.insideEditOffering != true) {
          this.props.rightSidePageHead("default");
          this.props.showRightSide(false);
          this.props.copyOfferingToggle(false);
          // this.props.getServiceDetailsDataList("", "", "", "", "");
        } else {
          swal({
            text: "New offering has been created in the catalog",
            button: this.props.tr["OK"],
          });
          this.props.rightSidePageNoFun(0);
          this.setState({ successMessage: true });
        }
        // this.props.getAllServiceListForSearch();
        this.props.spcmLoader("attribute", "success");
        // this.props.reset();
        if (this.props.insideEditOffering != true) {
          swal({
            text: "New offering has been created in the catalog",
            button: this.props.tr["OK"],
          });
        }
        // this.props.showRightSide(true);
        let filterData = sessionStorage.getItem("filterValues");
        filterData = JSON.parse(filterData);
        if (filterData && Object.keys(filterData).length > 0) {
          this.props.getServiceDetailsDataList(
            filterData.searchParams,
            filterData.status,
            filterData.category,
            filterData.featured,
            filterData.workItemType,
            filterData.modifiedDate,
            filterData.sortOrder,
            filterData.sortOnColumn,
            filterData.page,
            filterData.size
          );
        } else {
          this.props.getServiceDetailsDataList(
            this.props.searchParams,
            this.props.status,
            this.props.category,
            this.props.featured,
            this.props.workItemType,
            this.props.modifiedDateFilterValues,
            this.props.sortOrder,
            this.props.sortOnColumn,
            this.props.page,
            this.props.size
          );
        }
      })
      .catch((error) => {
        this.props.spcmLoader("attribute", "success");
        console.log("ERROR", error);
        if (error.response.data[0] != undefined)
          throw new SubmissionError({ _error: error.response.data[0].message });
        else throw new SubmissionError({ _error: error.response.data.message });
      });
  }

  onCompanySelect(e) {
    if (e.target.name == "company_id" && this.props.boardPageActive) {
      this.state.sourceCompany = e.target.value;
      this.props.getCopyOfferingListBasedOnCompany(e.target.value);
    } else if (e.target.name == "to_company_id") {
      this.props.getCategoryListCopy(e.target.value, "serviceBoard");
      this.props.dispatch(change("copyOfferingForm", "to_subcategory_id", ""));
      this.state.targetCompany = e.target.value;
    }
    if (this.props.boardPageActive)
      if (this.state.sourceCompany != this.state.targetCompany)
        this.setState({ disableCheck: true, entitlements: "false" });
      else this.setState({ disableCheck: false, entitlements: "false" });
    else if (
      this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID !=
      this.state.targetCompany
    )
      this.setState({ disableCheck: true, entitlements: "false" });
    else this.setState({ disableCheck: false, entitlements: "false" });
  }

  descriptionCharacter(e) {
    let input = e.target.value.length;
    let additionalchar = 120 - input;
    this.setState({
      additionalcharleft: additionalchar,
    });
    console.log("e.target.value", input);
  }

  render() {
    const tr = this.props.tr;
    const { error, handleSubmit, reset } = this.props;
    let copyOfferingFromServiceListOptions =
      this.props.spcmReducer.copyOfferingFromServiceListCopy;
    let currentlySelectedCopyOfferingId =
      this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID;
    let workItemType = this.props.spcmReducer.currentlySelectedServiceDetails.WORK_ITEM_TYPE;

    console.log("currentOfferingId", this.state.currentOfferingId);
    console.log("from_offering_name", this.state.from_offering_name);
    console.log(
      "spcmPostingAttribute",
      this.props.spcmReducer.spcmPostingAttribute
    );
    console.log(
      "copyOfferingFromServiceListOptions",
      copyOfferingFromServiceListOptions
    );
    console.log(
      "currentlySelectedCopyOfferingId",
      currentlySelectedCopyOfferingId
    );
    console.log(
      "insideEditOffering========================================================",
      this.props.insideEditOffering
    );
    return (
      <div className="rBoxGap">
        {error == undefined || error == null ? null : (
          <Alert variant="danger" className="errorMessageSec">
            <p>{error}</p>
          </Alert>
        )}
        {/* {this.props.insideEditOffering && this.state.successMessage.length !== 0 &&<Alert variant="success" className="errorMessageSec"><p>{this.state.successMessage}</p></Alert>} */}
        <form onSubmit={handleSubmit(this.submitForm)}>
          {this.props.boardPageActive ? (
            <Row>
              <Col md={12}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">
                    <span className="rStar"></span>
                    {this.props.tr["Source Company"]}
                  </Form.Label>
                  <Field
                    component={_dropDown}
                    onChange={(e) => this.onCompanySelect(e)}
                    name="company_id"
                    options={
                      this.props.spcmReducer.companyListcopy
                        ? this.props.spcmReducer.companyListcopy
                        : []
                    }
                    className="form-control"
                  ></Field>
                </Form.Group>
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col md={12}>
              <Row>
                <Col md={12}>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">
                      <span className="rStar"></span>
                      {this.props.tr["Service to be copied"]}
                    </Form.Label>

                    {this.props.boardPageActive ? (
                      <Field
                        component={_dropDown}
                        className="form-control"
                        onChange={(e) => {
                          this.serviceSelection(e);
                        }}
                        name="from_offering_id"
                        options={
                          copyOfferingFromServiceListOptions
                            ? copyOfferingFromServiceListOptions
                            : []
                        }
                      />
                    ) : (
                      <Field
                        name="from_editOffering_id"
                        component={_inputField}
                        readOnly
                        className="form-control"
                        defaultValue={
                          this.props.spcmReducer.currentlySelectedServiceDetails
                            .OFFERING_NAME
                        }
                      />
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">
                      <span className="rStar"></span>
                      {this.props.tr["Target Company"]}
                    </Form.Label>
                    <Field
                      component={_dropDown}
                      onChange={(e) => this.onCompanySelect(e)}
                      name="to_company_id"
                      options={
                        this.props.spcmReducer.companyListcopy
                          ? this.props.spcmReducer.companyListcopy
                          : []
                      }
                      className="form-control"
                    ></Field>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">
                      <span className="rStar"></span>
                      {this.props.tr["Category/Sub-Category"]}
                    </Form.Label>
                    <Field
                      component={_dropDown}
                      options={
                        this.props.spcmReducer.categoryListCopy
                          ? this.props.spcmReducer.categoryListCopy
                          : []
                      }
                      className="form-control"
                      name="to_subcategory_id"
                    ></Field>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="customCheckBoxContainer">
                  <Form.Label bsClass="">
                    <span className="rStar"></span>
                    {this.props.tr["Name of the new service"]}
                  </Form.Label>
                  <Field
                    name="to_offering_name"
                    component={_inputField}
                    className="form-control"
                    maxLength={120}
                    onChange={(e) => {
                      this.descriptionCharacter(e);
                    }}
                  />
                  <p
                    className="charLeft"
                    style={{ textAlign: "right", fontSize: "11px" }}
                  >
                    ({tr["Characters Remaining"]}:{" "}
                    {this.state.additionalcharleft}/120)
                  </p>
                </Col>
              </Row>

              <Row>
                <Col md={12} className="dCheckbox customCheckBoxContainer">
                  <Form.Label bsClass="">
                    {this.props.tr["Select the items to be copied:"]}
                  </Form.Label>
                  <Field
                    component={_checkbox}
                    className="form-control"
                    name="components"
                    label={tr["Components"]}
                    checked={true}
                    disabled={true}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={12} className="dCheckbox customCheckBoxContainer">
                  <Field
                    component={_checkbox}
                    className="form-control"
                    name="attributes"
                    label={tr["Attributes"]}
                    onChange={this.onChangeFlag}
                    checked={this.state.attributes == "true"}
                  />
                </Col>
              </Row>
              { workItemType == "incidentRequest"?"":
              <Row>
                <Col md={12} className="dCheckbox customCheckBoxContainer">
                  <Field
                    component={_checkbox}
                    className="form-control"
                    name="price"
                    label={tr["Price"]}
                    onChange={this.onChangeFlag}
                    checked={this.state.price == "true"}
                  />
                </Col>
              </Row>
              }
              <Row>
                <Col md={12} className="dCheckbox customCheckBoxContainer">
                  <Field
                    component={_checkbox}
                    className="form-control"
                    name="orderingInformation"
                    label={tr["Ordering Information"]}
                    onChange={this.onChangeFlag}
                    checked={this.state.orderingInformation == "true"}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={12} className="dCheckbox customCheckBoxContainer">
                  <Field
                    component={_checkbox}
                    className="form-control"
                    name="orderingPreferences"
                    label={tr["Ordering Preferences"]}
                    onChange={this.onChangeFlag}
                    checked={this.state.orderingPreferences == "true"}
                  />
                </Col>
              </Row>
              { workItemType == "incidentRequest"? "" :
              (<Row>
                <Col md={12} className="dCheckbox customCheckBoxContainer">
                  <Field
                    component={_checkbox}
                    className="form-control"
                    name="disclaimers"
                    label={tr["Disclaimer"]}
                    onChange={this.onChangeFlag}
                    checked={this.state.disclaimers == "true"}
                  />
                </Col>
              </Row>
  )}

            {this.state.disableCheck == false ? ( 
              <Row>
                <Col md={12} className="dCheckbox customCheckBoxContainer">
                    <Field
                      component={_checkbox}
                      className="form-control"
                      name="entitlements"
                      label={tr["Entitlements"]}
                      onChange={this.onChangeFlag}
                      checked={this.state.entitlements == "true"}
                    />                 
                </Col>
              </Row>
            ) : null}
 { workItemType == "standardChange"? "" :
              (<Row>
                <Col md={12} className="dCheckbox customCheckBoxContainer">
                  
                    <Field
                      component={_checkbox}
                      className="form-control"
                      name="languageTranslation"
                      label= {tr["Language Tanslation"]}
                      onChange={this.onChangeFlag}
                      checked={this.state.languageTranslation == "true"}
                    />
               
                </Col>
              </Row>
               )}
            </Col>
          </Row>
             

          <Row className="margin-t-15">
            <Col md={12}>
              <ButtonToolbar className="black">
                <Button
                  className="rgSidrkBtn"
                  title={this.props.tr["Make a Copy"]}
                  disabled={
                    this.props.spcmReducer.spcmPostingAttribute ||
                    this.state.successMessage == true
                      ? true
                      : false
                  }
                  type="submit"
                  size="sm"
                  bsStyle="primary"
                >
                  {this.props.spcmReducer.spcmPostingAttribute ? (
                    <ImSpinner6 className="icn-spinner"/>
                  ) : null}{" "}
                  {this.props.tr["Make a Copy"]}
                </Button>
                {/* <Button className='rgSiLigBtn' title="Close" onClick={() => { this.props.insideEditOffering == true ? this.props.rightSidePageNoFun(0) : this.props.showRightSide (false);this.props.copyOfferingToggle(false); }} bsSize="small" bsStyle="text">{tr['Close']}</Button> */}
              </ButtonToolbar>
            </Col>
          </Row>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({ spcmReducer }) => {
  return {
    spcmReducer,
    // initialValues:spcmReducer.editLeftId>0?spcmReducer.editLeftService:''
  };
};

CopyOfferingForm = reduxForm({
  form: "copyOfferingForm",
  validate,
  enableReinitialize: true,
})(CopyOfferingForm);

export default connect(mapStateToProps, {
  spcmLoader,
  getAllServiceListForSearch,
  getCopyOfferingListBasedOnCompany,
  getCompanyListCopy,
  getCategoryListCopy,
})(CopyOfferingForm);
