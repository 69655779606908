
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { div, Row, Col, Form } from "react-bootstrap";
// import CiCategoryHeader from './CiCategoryHeader';

// import CiCategoryRightPart from './CiCategoryRightPart';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getTranslation } from "../../../actions/spcmActions";
import { browserHistory } from "react-router";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import Cookies from "universal-cookie";
import CiCategoryList from "../ciCategory/CiCategoryList";
import ResolutionCategoryList from "./ResolutionCategoryList";
import ResolutionCategoryRightPart from "./ResolutionCategoryRightPart";
import {resetCatDropdown,resetSubCatDropdown } from '../../../actions/categoryBoard/quickViewAction';
import "_Css/form/_form.scss";
let GLOBAL = require("_Globals");

const ResolutionCategoryIndex= () => {
  const dispatch = useDispatch();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const [showLoader, setShowLoader] = useState(false);
  const [categoryData, setCategoryData] = useState(null);
  const [status, setStatus] = useState("");
  const [ciClassName, setciClassName] = useState("");
  const [page, setPage] = useState("");
  const [limit, setlimit] = useState("");
  const [isEditing, setisEditing] = useState(false);
  const [rightDisplay, setrightDisplay] = useState(false);

  GLOBAL.searchbarcategory = "All";
  

  const showRightSide = (val) => {
    setrightDisplay(val);
    //this.setState({ rightDisplay: val });
  }

  
  useEffect(() => {
      dispatch(resetSubCatDropdown());
      dispatch(resetCatDropdown());
      
    }, [])
  // useEffect(
  //   () => {
  //       dispatch (getCategoryBoardDetails(
  //         category_name,
  //         sub_category_name,
  //         cat_company_name,
  //         status,
  //         class_id,
  //         category_id,
  //         cat_company_id
  //       ));
  //     }
    
    
  // )

  //setListMetaData(status, ciClassName) {
    const setListMetaData = (status, ciClassName) => {
    console.log("CILISTING====>", ciClassName);
    setStatus(status);
    setciClassName(ciClassName) 
  }

    const setIsEditing = (value) => {
    setisEditing(value);
    
  }
    const resetRightSide = () => {
    // this.setState({ categoryData: null });
    setCategoryData(null);
  }

  const getCategoryBoardDetails = (
    res_category_name,
    res_sub_category_name,
    cat_company_name,
    status,
    class_id,
    category_id,
    cat_company_id,
    ciCategoryName,
    ciSubCategory,
    resCategoryId
  ) => {
    const categoryData = {
      res_category_name: res_category_name,
      res_sub_category_name: res_sub_category_name,
      cat_company_name: cat_company_name,
      status: status,
      class_id: class_id,
      category_id: category_id,
      cat_company_id: cat_company_id,
      category_name: ciCategoryName,
      sub_category_name: ciSubCategory,
      res_category_id: resCategoryId
    };
    
     // categoryData: categoryData,
      setCategoryData(categoryData);
  
  }
    return (
      <main>
        <div className="minHeight">
          <div className="container-fluid" bsClass="">
              <PanelGroup direction="horizontal">
                <Panel id="sidebar" minSize={42} order={1} defaultSize= {rightDisplay ? 67 : 100} className={rightDisplay ? "isShowLeftPanel rwLeftPart" : "rwLeftPart"}>
                  <ResolutionCategoryList
                    getCategoryBoardDetails={getCategoryBoardDetails}
                    setListMetaData={setListMetaData}
                    setIsEditing={setIsEditing}
                    resetRightSide={resetRightSide}
                    tr={tr}
                    showRightSide={showRightSide}
                    rightDisplay={rightDisplay}
                  />
                </Panel>
                {rightDisplay ?
                    <>
                      <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
                      <Panel minSize={33} order={2} defaultSize={33}>
                        <div className="stickyArea rBoxStyle">
                          <ResolutionCategoryRightPart
                            categoryData={categoryData}
                            status={status}
                            ciClassName={ciClassName}
                            setIsEditing={setIsEditing}
                            isEditing={isEditing}
                            resetRightSide={resetRightSide}
                            showRightSide={showRightSide}
                            rightDisplay={rightDisplay}
                            tr={tr}
                          />
                        </div>
                      </Panel>
                    </> : ""
                }
              </PanelGroup>
          </div>
        </div>
      </main>
    );
  

};

export default ResolutionCategoryIndex;