
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import {
	Row,
	Col,
	Form,
	Alert,
	ButtonToolbar,
	Button,
} from "react-bootstrap";
import { _textArea, _dropDown, _inputField } from "../../common/formFields";
import { reduxForm, Field, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import axios from "axios";
import {
	spcmLoader,
	setLeftEditId,
	getServiceDetailCounts,
	getSharedOfferings,
	getConsumptionItems,
	getCompanyList,
} from "../../../actions/spcmActions";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {ImSpinner6} from "react-icons/im";
import swal from "sweetalert";

class ComponentForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			shared_company_id: "",
			shared_company_name: "",
		};
		this.onCompanySelect = this.onCompanySelect.bind(this);
		this.onGroupSelect = this.onGroupSelect.bind(this);
		this.onRoleChange = this.onRoleChange.bind(this);
		this.submitForm = this.submitForm.bind(this);
	}
	componentWillMount() {
		this.props.getCompanyList("");
	}

	componentWillUnmount() {
		this.props.spcmLoader("attribute", "success");
	}

	onRoleChange(e) {
		this.props.getChainEntitiesCompanyList("", e.target.value);
	}

	serviceSelection(e) {
		if (e.length > 0) {
			this.setState({ shared_company_name: e[0].field1Key });
			this.setState({ shared_company_id: e[0].field1Value });
		} else {
			this.setState({ shared_company_id: "" });
			this.setState({ shared_company_name: "" });
		}
	}

	onCompanySelect(e) {
		this.props.getGroupList(e.target.value);
	}

	onGroupSelect(e) {
		this.props.getManagerList(e.target.value);
	}

	submitForm(values) {
		values.offering_id =
			this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID;
		this.props.spcmReducer.companyList.map(function (item, index) {
			if (values.company_id == item.field1Value) {
				values.company_name = item.field1Key;
			}

		});
		if (values.company_id === "" || values.company_name === "") {
			swal({
				text: this.props.tr["Please select an offering."],
				button: this.props.tr["OK"],
			  });
			return false;
		}
		return axios
			.post("/service/api/sharedServiceCompany/", values)
			.then((response) => {
				this.props.getSharedOfferings(
					this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID
				);
				this.props.componentFormFun4(false);
				this.props.spcmLoader("attribute", "success");
				console.log("this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID",this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID
				);
			})
			.catch((error) => {
				this.props.spcmLoader("attribute", "success");
				console.log("error",error);
				throw new SubmissionError({ _error: error.response.data });
			});
	}

	render() {
		const tr = this.props.tr;
		const { error, handleSubmit } = this.props;
		let id = this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID;
		let comp = this.props.spcmReducer.companyList;
		let meta = this.props.spcmReducer.allServicesMeta;
		comp = comp.filter(function (comp) {
			return comp.field1Value != id;
		});
		return (
			<div className="">
				{error == undefined || error == null ? null : (
					<Alert variant="danger" className="errorMessageSec">
						<p>{error}</p>
					</Alert>
				)}

				<Row>
					<Col md={12}>
						<Form.Group className='form-group'>
							<Form.Label bsClass="">
								<span className="rStar"></span> {tr["Select Companies"]}
							</Form.Label>
							<Field
								component={_dropDown}
								name="company_id"
								onChange={(e) => {
									this.serviceSelection(e);
								}}
								options={
									comp
								}
								className="form-control"
							/>
						</Form.Group>
					</Col>
				</Row>

				<Row>
					<Col md={12}>
						<ButtonToolbar className="black">
							<Button
								title={tr["Save"]}
								disabled={this.props.spcmReducer.spcmPostingAttribute}
								type="submit"
								bsSize="small"
								bsStyle="primary"
								className="rgSidrkBtn"
								onClick={handleSubmit(this.submitForm)}
							>
								{this.props.spcmReducer.spcmPostingAttribute ? (
									<ImSpinner6 className="icn-spinner"/>
								) : null}{" "}
								{tr["Save"]}
							</Button>
							<Button
								title="Close"
								className="rgSiLigBtn"
								onClick={() => {
									this.props.componentFormFun4(false);
								}}
								bsSize="small"
								bsStyle="text"
							>
								{tr["Close"]}
							</Button>
						</ButtonToolbar>
					</Col>
				</Row>
			</div>
		);
	}
}

const mapStateToProps = ({ spcmReducer }) => {
	return {
		spcmReducer,
	};
};
export default connect(mapStateToProps, {
	spcmLoader,
	setLeftEditId,
	getServiceDetailCounts,
	getSharedOfferings,
	getConsumptionItems,
	getCompanyList,
})(
	reduxForm({
		form: "sharedOfferingsForm",

		enableReinitialize: true,
	})(ComponentForm)
);

