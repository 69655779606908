
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import axios from 'axios';
import { connect } from 'react-redux';

import { GLOBAL } from "_Globals";

import { loadChangeEditData } from '../../../../actions/changeManagement/changeManagementAction.js';
import { loadStatusBasedHamburgerOptions } from '../../../../actions/common/loadStatusBasedHamburgerOptions';
import { loadTimelineData } from '../../../../actions/common/commonApisActions.js';
import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";

class ClosureCode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          selectedClosure: 'Successful',
          showLoader: false
        }
        this.oncodeSelection = this.oncodeSelection.bind(this);
        this.submitSelectedCode = this.submitSelectedCode.bind(this);
    }
    oncodeSelection(event) {
        this.setState({ selectedClosure: event.target.value });
    }
    submitSelectedCode() {
        this.setState({ showLoader: true });
        let postjson = {
          "status": "60",
          "actEndDate": "",
          "systemEndDateRequired": "Y",
          "modifiedBy":"",
          "modifiedByName": ""
        };
        let postjsonactivity = {
          "itemId": this.props.editChangeInitialData.changeId,
          "description": "Closure Code : " + this.state.selectedClosure,
          "isInternal": "",
          "createdOn": "",
          "createdBy": "",
          "module": "ChangeManagement",
          "createdByName": "",
        };
        axios.patch(GLOBAL.changeEditUrl + this.props.editChangeInitialData.changeId, postjson).then((response) => {
          axios.patch(GLOBAL.changeEditUrl + this.props.editChangeInitialData.changeId, {
            "status": this.props.editChangeInitialData.type == "20" ? "70" : "65",
            "modifiedBy":"",
            "modifiedByName": "",
            "closureCodes": this.state.selectedClosure
          }).then((continousResponse) => {
            this.props.loadChangeEditData(this.props.editChangeInitialData.changeId);
            this.props.loadStatusBasedHamburgerOptions('ChangeManagement', continousResponse.data.status, continousResponse.data.changeId);
            axios.post(GLOBAL.breakFixActivityLogPostUrl, postjsonactivity).then((response) => {
              this.props.changeActionView('endImplementation');
            }).catch((error) => {
              this.setState({ showLoader: false });
            });
          }).catch((error) => {
            this.setState({ showLoader: false });
          });
        }).catch((error) => {
          this.setState({ showLoader: false });
        });
      }
   render(){
    return (
        <div>
          <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
              <ul>
                  <li>
                  <button
                      type='button'
                      title="Minimize the right panel"
                      bsClass=""
                      bsStyle=""
                      className="closerightPanelBtn"
                      onClick={() => {
                      this.props.rightEditPanel(false);
                      }}
                  >
                      <IoClose/>
                  </button>
                  </li>
              </ul>
          </div>
          <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Closure Code']}</div></div>
          <div className='rBoxGap'>
            <Form.Group className='form-group'>
              <Form.Label><span className="rStar float-l margin-r-5"></span>
                <Form.Select style={{ "width": "initial" }} componentClass="select" className="form-control" onChange={(e) => { this.oncodeSelection(e) }} disabled={this.state.showLoader}>
                  <option value="Successful">Successful</option>
                  <option value="Unsuccessful">Unsuccessful</option>
                  <option value="Partially Successful">Partially Successful</option>
                </Form.Select>
              </Form.Label>
            </Form.Group>
            <ButtonToolbar className="black">
              <Button className="rgSidrkBtn smallBtn" bsSize="small" variant="primary" onClick={() => { this.submitSelectedCode() }} disabled={this.state.showLoader}>
                {this.state.showLoader ? <ImSpinner6 className="icn-spinner"/> : null}{this.props.translator['Submit']}
              </Button>
            </ButtonToolbar>
          </div>
        </div>
      );
   }
}
export default connect(null, { loadChangeEditData, loadStatusBasedHamburgerOptions, loadTimelineData })(ClosureCode);