
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from "axios";

export const Get = (endPoints, options) => {
  return axios.get(endPoints, options);
};

export const Post = (endPoints, reqContent, file) => {
  let headers = {};
  if (file) {
    headers = {
      ...headers,
      "content-type": "multipart/form-data",
    };
  }
  return axios.post(endPoints, reqContent, {
    headers,
  });
};

export const Put = (endPoints, reqContent, file) => {
  return axios.put(endPoints, reqContent);
};
