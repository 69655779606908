
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { TypeaheadExampleSingleSelect, TypeaheadandDropdown, _ckeditor, _dropDown, _inputField } from "../../../../common/formFields";
import { Field, change, reduxForm } from "redux-form";
import { getInitialCityLocationAction, getInitialListLocationAction, getInitialStatesLocationAction } from "../../../../../actions/foundation/locationAction";
import { getCompanyOptionsList } from "../../../../../actions/foundation/commonAction";
import { getcompanyMenuListAction } from "../../../../../actions/foundation/groupAction";

const CreateForm = (props) => {
    const tr = useSelector((state) => state.spcmReducer.tr);
    const [selectedCompany, setSelectedCompany] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [locationTypeList, setLocationTypeList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [typeOfLocation, setTypeOfLocation] = useState('');
    const [reasonValueList, setReasonValueList] = useState([]);
    const locationFieldValues = useSelector((state) => state.locationFieldData);
    const companyData = useSelector((state) => state.costCenterCompanyDropdown);
    const countryData = useSelector((state) => state.countryFieldData);
    const statesData = useSelector((state) => state.statesFieldData);
    const citiesData = useSelector((state) => state.citiesFieldData);
    const companyMenuValues = useSelector((state) => state.companyMenuListValues);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCompanyOptionsList());
    }, [])


    useEffect(() => {
        dispatch(getInitialListLocationAction());
    },[])

    useEffect(() =>
    {
      if(companyData && companyData.length > 0)
      {
        setCompanyList(companyData)
      }

    }, [companyData])
    const onCompanySelection=(selectedCompany)=>{
        if (selectedCompany.length > 0) { 
            setSelectedCompany(selectedCompany)
            dispatch(change('CreateLocation', 'company_name', selectedCompany[0].label))
            dispatch(change('CreateLocation', 'company_id', selectedCompany[0].id))
            dispatch(getcompanyMenuListAction(selectedCompany[0].id, "Walk-up", "Reason of visit", '1', '0'));
            props.setCompanyValue(selectedCompany);
          } else {
            setSelectedCompany([]);
            dispatch(change('CreateLocation', 'company_name', ''));
            dispatch(change('CreateLocation', 'company_id', ''));
          }
    }

    const onCrossClickCompany = () => {
        setSelectedCompany([]);
        props.setCompanyValue([]);
        setReasonValueList([]);
        props.setValueError("error");
        dispatch(change('CreateLocation', 'company_name', ''));
        dispatch(change('CreateLocation', 'reasonOfVisit', []))
    }

    const onCountrySelection = (event) => {
        dispatch(change("CreateLocation", "country_id", event.target[event.target.selectedIndex].text));
        dispatch(change("CreateLocation", "country_label", event.target[event.target.selectedIndex].value));
        let countryId = event.target.value;
        setStateList([]);
        setCityList([])
        dispatch(change("CreateLocation", "state",""));
        dispatch(change("CreateLocation", "city",""));
        dispatch(getInitialStatesLocationAction(countryId));
    
    }

    const onStateSelection = (event) => {
        setCityList([])
        dispatch(change("CreateLocation", "state_id", event.target[event.target.selectedIndex].value));
        dispatch(change("CreateLocation", "state_label", event.target[event.target.selectedIndex].text));
        dispatch(change("CreateLocation", "city",""));
        let stateId = event.target.value;
        dispatch(getInitialCityLocationAction(stateId));

    }

    const onCitySelection = (event) => {
      dispatch(change("CreateLocation", "city_id", event.target[event.target.selectedIndex].value));
      dispatch(change("CreateLocation", "city_label", event.target[event.target.selectedIndex].text));
  }

    useEffect(() => {
          if(locationFieldValues && locationFieldValues.length > 0)
        {
            setLocationTypeList(locationFieldValues)
        }
    }, [locationFieldValues])

    useEffect(() => {
          if(countryData && countryData.length > 0)
        {
            setCountryList(countryData)
        }
    }, [countryData])
    useEffect(() => {
          if(statesData && statesData.length > 0)
        {
            setStateList(statesData)
        }
    }, [statesData])
    useEffect(() => {
          if(citiesData && citiesData.length > 0)
        {
            setCityList(citiesData)
        }
    }, [citiesData])
    
    const onLocationTypeChange = (event) => {
        setTypeOfLocation(event.target[event.target.selectedIndex].text)
        setReasonValueList([]);
        props.setValueError("");
        dispatch(change('CreateLocation', 'reasonOfVisit', ''));
        dispatch(change("CreateLocation", "locationType", event.target[event.target.selectedIndex].text))
    }
    
    const companyInputChange = () => {
      onCrossClickCompany();
    }
    const  validateLocationFields = (fieldType, event)=> {
        switch (fieldType) {
          case "name":
            if (event.target.value == "") {
              props.setNameErr("error");
            } else {
              props.setNameErr("");
            }
            break;
          case "status":
            if (event.target.value == "") {
              props.setStatusErr("error");
            } else {
              props.setStatusErr("");
            }
            break;
          case "address_first":
            if (event.target.value == "") {
              props.setAddressErr("error");
            } else {
              props.setAddressErr("");
            }
            break;
          case "country":
            if (event.target.value == "") {
                props.setCountryErr("error");
                    } else {
                        props.setCountryErr("");
                    }
                break;
                case "state":
                    if (event.target.value == "") {
                        props.setStateErr("error");
                    } else {
                        props.setStateErr("");
                    }
                break;
                case "city":
                    if (event.target.value == "") {
                        props.setCityErr("error");
                    } else {
                        props.setCityErr("");
                    }
                break;
                case "zip":
                    if (event.target.value == "") {
                        props.setZipErr("error");
                    } else {
                        props.setZipErr("");
                    }
                    break;
          default:
        }
      }

      const onValueSelection = (selected) => {        
        if ((Array.isArray(selected) && selected.length > 0)) {
          props.setValueError("");
          dispatch(change('CreateLocation', 'reasonOfVisit', selected))
          setReasonValueList(selected);
        } else {
          props.setValueError("error");
          setReasonValueList([]);
          dispatch(change('CreateLocation', 'reasonOfVisit', []))
        }
        
    };


    const onCrossClick = () => {
      setReasonValueList([]);
      props.setValueError("error");
      dispatch(change('CreateLocation', 'reasonOfVisit', []))
  };

  return (
    <>
      <Form>
        <Row>
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              <span className="rStar" />
              <Form.Label bsClass="">{tr["Company"]}</Form.Label>
              <Field
                name="company_name"
                className="form-control"
                component={TypeaheadExampleSingleSelect}
                onSelection={onCompanySelection}
                options={companyList}
                selectedOptions={selectedCompany}
                onCrossClick={onCrossClickCompany}
                errorClass={props.companyTypeErr}
                setErrorColor={props.setCompanyErrorColor}
                onInputChange={companyInputChange}
              />
            </Form.Group>
          </Col>

          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
            <span className="rStar"></span>
              <Form.Label>
                {tr["Address 1"]}
              </Form.Label>
              <Field
                component={_inputField}
                name="address_first"
                className={"form-control " + (props.addressErr)}
                maxLength={50}
                  onBlur={(event) => {
                    validateLocationFields("address_first", event);
                  }}
              />
            </Form.Group>
          </Col>

          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              <span className="rStar"></span>
              <Form.Label>{tr["Name"]}</Form.Label>
              <Field
                component={_inputField}
                name="name"
                className={"form-control " +(props.nameErr)}
                maxLength={50}
                  onBlur={(event) => {
                    validateLocationFields("name", event);
                  }}
              />
            </Form.Group>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              <Form.Label>{tr["Address 2"]}</Form.Label>
              <Field
                component={_inputField}
                name="address_second"
                className={"form-control"}
                maxLength={50}
              />
            </Form.Group>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              <Form.Label>{tr["Location Type"]}</Form.Label>
              <Field
              name="locationType"
              className={"form-control"}
              component={_dropDown}
              options={locationTypeList}
              onChange={(event) => (onLocationTypeChange(event))}
            ></Field>
            </Form.Group>
          </Col>

          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              <Form.Label>{tr["Address 3"]}</Form.Label>
              <Field
                component={_inputField}
                name="address_third"
                className={"form-control"}
                maxLength={50}
              />
            </Form.Group>
          </Col>
          <Col md={3} sm={3} xs={12}>
            <Form.Group className="form-group">
            <span className="rStar"></span>
              <Form.Label>
                {tr["Country"]} 
              </Form.Label>
              <Field
              name="country"
              className={"form-control "+ (props.countryErr)}
              component={_dropDown}
              options={countryList}
              onBlur={(event) => {validateLocationFields("country", event);}}
              onChange={(e)=>onCountrySelection(e)}
            ></Field>
            </Form.Group>
          </Col>

          <Col md={3} sm={3} xs={12}>
          <Form.Group className="form-group">
            <span className="rStar"></span>
              <Form.Label>
                {tr["State"]} 
              </Form.Label>
              <Field
              name="state"
              className={"form-control "+(props.stateErr)}
              component={_dropDown}
              options={stateList}
              onBlur={(event) => {validateLocationFields("state", event);}}
              onChange={(event) => onStateSelection(event)}
            ></Field>
            </Form.Group>
          </Col>
          <Col md={3} sm={3} xs={12}>
            <Form.Group className="form-group">
            <span className="rStar"></span>
              <Form.Label>
                {tr["City"]} 
              </Form.Label>
              <Field
              name="city"
              className={"form-control "+ (props.cityErr)}
              component={_dropDown}
              options={cityList}
              onBlur={(event) => {validateLocationFields("city", event);}}
              onChange={(event) => onCitySelection(event)}
            ></Field>
            </Form.Group>
          </Col>

          <Col md={3} sm={3} xs={12}>
            <Form.Group className="form-group">
            <span className="rStar"></span>
              <Form.Label>
                {tr["Zip"]} 
              </Form.Label>
              <Field
                component={_inputField}
                name="zip"
                className={"form-control " + (props.zipErr)}
                maxLength={50}
                  onBlur={(event) => {
                    validateLocationFields("zip", event);
                  }}
              />
            </Form.Group>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <Form.Group className="form-group">
              <span className="rStar"></span>
              <Form.Label>{tr["Status"]}</Form.Label>
              <Field
                name="status"
                component="select"
                className={"form-control " + (props.statusErr)}
                onBlur={(event) => {
                    validateLocationFields("status", event);
                }}
              >
                <option value="">{tr["Select"]}</option>
                <option value="1">{tr["Active"]}</option>
                <option value="0">{tr["Inactive"]}</option>
              </Field>
            </Form.Group>
          </Col>
          {typeOfLocation == 'Walk-up'? (<Col md={6} sm={6} xs={12}>
          <Form.Group className="form-group">
            <span className="rStar" />
            <Form.Label bsClass="">
              {["Reason Of Visit"]}
            </Form.Label>
            <Field
              component={TypeaheadandDropdown}
              onSelection={onValueSelection}
              name="reasonOfVisit"
              className="form-control"
              options={companyMenuValues}
              selectedOptions={reasonValueList}
              onCrossClick={onCrossClick}
              errorClass={props.valueError === "error" ? "error" : ""}
              multiple={true}
            />
          </Form.Group>
        </Col>): ''}
          <Col md={12} >
          <Form.Group className="form-group">
						<Form.Label bsClass=""> {tr['Description']}</Form.Label>
						<Field name="description" component={_ckeditor} className={"reactQuillEditor-area"} richTextConfig ={"minRichTextEditor"}/>
						</Form.Group>
          </Col>
          {typeOfLocation !== 'Walk-up'? (<Col md={12}>
          <Form.Group className="form-group">
						<Form.Label bsClass=""> {tr['Support Hours']}</Form.Label>
						<Field name="supportHours" component={_ckeditor} className={"reactQuillEditor-area"} richTextConfig ={"minRichTextEditor"}
            />
						</Form.Group>
          </Col>):(<Col md={12}>
          <Form.Group className="form-group">
          <span className="rStar" />
						<Form.Label bsClass=""> {tr['Support Hours']}</Form.Label>
						<Field name="supportHours" component={_ckeditor} className={"reactQuillEditor-area"} richTextConfig ={"minRichTextEditor"}
            />
            <div style={{ fontSize: '13px', color: 'red', paddingTop: '2px' }}>
            {props.supportHrsErr}
            </div>
						</Form.Group>
            
          </Col>)}
          <Col md={12} className="form-group">
          <Form.Group>
						<Form.Label bsClass=""> {tr['Accessibility Instructions']}</Form.Label>
						<Field name="accessInstruction" component={_ckeditor} className={"reactQuillEditor-area"} richTextConfig ={"minRichTextEditor"}/>
						</Form.Group>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default reduxForm({
  form: "CreateLocation",
  destroyOnUnmount: false,
  enableReinitialize: true,
})(CreateForm);
