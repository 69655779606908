
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import { LuFilterX } from "react-icons/lu";
import { HiOutlineRefresh } from "react-icons/hi";

const Header = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tr = useSelector((state) => state.spcmReducer.tr);


  return (
    <>
      <Row className="margin-b-15">
      <Col lg={10} md={10} sm={12} xs={12}>
          <ul className="wibTabs">
            <li>
              <Link title={tr["My Requests"]} to="/myOrders">
                {tr["My Requests"]}
              </Link>
            </li>
            <li className="active"><Link title={tr["My Walk Up Requests"]} to="/walkUpReq">{tr["My Walk Up Requests"]}</Link></li>
            <li>
              <Link title={tr["My Assets/CIs"]} to="/myAssets">
                {tr["My Assets/CIs"]}
              </Link>
            </li>
            <li>
              <Link title={tr["My Notifications"]} to="/myNotifications">
                {tr["My Notifications"]}
              </Link>
            </li>
          </ul>
        </Col>
        <Col lg={2} md={2} sm={12} xs={12}>
          <div
            aria-label="Page Filters"
            role="contentinfo"
            className="paHedFilter"
          >
            {/* <div className="overlayDv">
                <Button
                  title={tr["Clear All Filters"]}
                  role="button"
                  bsPrefix=" "
                  className="myBt clrbtn"
                >
                  <LuFilterX />
                </Button>
            </div> */}
            <Button
              aria-label={tr["Refresh"]}
              title={tr["Refresh"]}
              role="button"
              bsClass=""
              bsPrefix=" "
              className="myBt"
            >
              <HiOutlineRefresh className="refreshFlip" onClick={()=>props?.setRefreshed(true)} />
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Header;
