
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';

import XsmSupportHeader from './header';
import ChangeForm from './form';
import XsmProcessHierarchy from './processHierarchy';
import XsmBreakFixAction0 from './xsm-breakFix-action0';
import XsmServiceDetails from './xsm-service-quickView';
import XsmUserDetails from './xsmRequestedBy';
import WIBSearchInner from "../../../common/WorkItemBoard/WIBSearch/WIBSearchInner";


import { getTranslation} from '../../../../actions/spcmActions';
import Breadcrumbs from '../../../common/header/breadcrumbs';

import { constructRiskAssessmentPayload } from '../../../common/WorkItemBoard/RiskAssessment/helper';
import AvailableIndividuals from '../../../common/WorkItemBoard/availableIndividuals/AvailableIndividuals';
import UserDetails from "../../../common/WorkItemBoard/xsmEditRequestedBy";
import CIDEtails from "../../../common/WorkItemBoard/xsm-ci-editquickView";
import { GLOBAL } from "_Globals";
import "_Css/form/_form.scss";
const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

class CreateChangeIndex extends React.Component {
    constructor(props) {
		super(props);
		this.state ={
			subMenu: '4',
			showHideAlfy:false,
			showHideRightNav:false,
			processhierarchy:0,
			consumerId:'',
			serviceId:'',
			user_id:'',
			role_id:'',
			groupID:'',
		    roleID:'',
			changeImplentationRole:'',
			date_format:'',
			serviceErrorColor:'',
			ImpactedErrorColor:'',
			consumerErrorColor:'',
			companyError:false,
			enableErrors:false,
      riskAssessmentDetails: {
        'residualRisk': '',
        'riskAssessment': null
      },
      isRiskAssessmentMandatory: false,
      isRiskAssessmentChanged: false,
      isRiskAssessmentFilled: false,
      selectedAssignmentGroup:[],
			selectedAssignmentUser:[],
			selectedImpGroup:[],
			selectedImpUser:[],
      isRequestorShow:false,
      isImpactCI:false,
      isIndividualShow:false,
      isImplementIndividualShow:false,
      individualImptID:0, 
      individualID:0,
      
		};
		this.showHideAlfy = this.showHideAlfy.bind(this);
		this.showHideRightNav = this.showHideRightNav.bind(this);
		this.processHierarchy = this.processHierarchy.bind(this);
		this.props.getTranslation(this.props.lang);
		this.changeActionView = this.changeActionView.bind(this);
		this.setErrorServiceColor=this.setErrorServiceColor.bind(this);
		this.setErrorImpactedColor=this.setErrorImpactedColor.bind(this);
		this.setErrorConsumerColor=this.setErrorConsumerColor.bind(this);
		this.roleIdentification=this.roleIdentification.bind(this);
         this.seTypeaheadValidation=this.seTypeaheadValidation.bind(this);
        this.setEnableErrors=this.setEnableErrors.bind(this);
    this.updateRiskAssessmentDetails = this.updateRiskAssessmentDetails.bind(this);
    this.setAssignmentGrpUsr = this.setAssignmentGrpUsr.bind(this);
		this.setAssignmentGrpUsrImp = this.setAssignmentGrpUsrImp.bind(this);
    this.showConsumerDetails=this.showConsumerDetails.bind(this)
    this.showImpactCI=this.showImpactCI.bind(this)
    this.showIndividualDetails=this.showIndividualDetails.bind(this)
    this.showImplementIndividualDetails = this.showImplementIndividualDetails.bind(this)
    this.setIndividualDetails=this.setIndividualDetails.bind(this)
    this.setImplementIndividualDetails=this.setImplementIndividualDetails.bind(this)
	 }

  setAssignmentGrpUsr(selectd,type){
		switch(type){
		  case "user":
			this.setState({selectedAssignmentUser:selectd});
			break;
		  case  "group":
			this.setState({selectedAssignmentGroup:selectd,selectedAssignmentUser:[]});
			break;
		}
	}

	setAssignmentGrpUsrImp(selectd,type){
		switch(type){
		  case "user":
			this.setState({selectedImpUser:selectd});
			break;
		  case  "group":
			this.setState({selectedImpGroup:selectd,selectedImpUser:[]});
			break;
		}
	}
    seTypeaheadValidation(value,fieldName){
		if(this.state.enableErrors){
        if(fieldName==='company'){
		    	this.setState({companyError:value});
		    }
     	}
	}

    setEnableErrors(value){
		this.setState({enableErrors:value});
	}

    changeActionView(actionSelected,breakFixValue){        
        if(actionSelected == 'consumer' && breakFixValue){
                    this.setState({
                        processhierarchy:3,
                        consumerId:breakFixValue,
                        isIndividualShow: false, isImpactCI: false, isRequestorShow: false, isImplementIndividualShow: false
                    });
        }
        if(actionSelected == 'service' && breakFixValue){
                    this.setState({
                        processhierarchy:2,
                        serviceId:breakFixValue,
                        isIndividualShow: false, isImpactCI: false, isRequestorShow: false, isImplementIndividualShow: false
                    });
        }
      
      if(actionSelected == "showIndividualGroup"){
        this.setState({processhierarchy:4,isIndividualShow: false, isImpactCI: false, isRequestorShow: false, isImplementIndividualShow: false
        });
      }

      if(actionSelected == "showIndividualImp"){
        this.setState({processhierarchy:5,isIndividualShow: false, isImpactCI: false, isRequestorShow: false, isImplementIndividualShow: false
        });
      }

    }

    roleIdentification(Rolevalue){
		let user_roles=this.state.role_id.split(',');
		let flag=false	
		for(let i=0;i<user_roles.length;i++)
		{
			if(user_roles[i]==Rolevalue)
			   {
				   flag=true
				   break
			   }
		}
		if(flag)
		   return true
		else
		   return false	
	}

    componentWillMount()
	{
		let str={};
        str.user_id="";
        str = JSON.stringify(str);
		api.get("/api/userInfo",{headers:{'query':str}}).then((response)=>{
			let changeUserid='';
			if(response.data.role_id.split(",").includes("20")&&(!response.data.role_id.split(",").includes("52")))
			{
			  changeUserid="changeUserid";
			}
			else if(response.data.role_id.split(",").includes("52")&&(!response.data.role_id.split(",").includes("20")))
			{
			  changeUserid="impUserid";
			}
			else if(response.data.role_id.split(",").includes("20")&&response.data.role_id.split(",").includes("52")||response.data.role_id.split(",").includes("20")&&response.data.role_id.split(",").includes("51")||response.data.role_id.split(",").includes("52")&&response.data.role_id.split(",").includes("19"))
			{
			  changeUserid="impUserid,changeUserid";
			}
			else if(response.data.role_id.split(",").includes("19")&&(!response.data.role_id.split(",").includes("51")))
			{
			  changeUserid="changeUserid";
			}
			else if(response.data.role_id.split(",").includes("51")&&(!response.data.role_id.split(",").includes("19")))
			{
			  changeUserid="impUserid";
			}
			else if(response.data.role_id.split(",").includes("19")&&response.data.role_id.split(",").includes("51"))
			{
			  changeUserid="impUserid,changeUserid";
			}
            this.setState({user_id:response.data.user_id,role_id:response.data.role_id,groupID:response.data.groupId,roleID:response.data.role_id,changeImplentationRole: changeUserid,date_format:response.data.dateformat});
		});
	}

    componentWillReceiveProps()
    {
	    let str={};
        str.user_id="";
        str = JSON.stringify(str);
		api.get("/api/userInfo",{headers:{'query':str}}).then((response)=>{
			let changeUserid='';
			if(response.data.role_id.split(",").includes("20")&&(!response.data.role_id.split(",").includes("52")))
			{
			  changeUserid="changeUserid";
			}
			else if(response.data.role_id.split(",").includes("52")&&(!response.data.role_id.split(",").includes("20")))
			{
			  changeUserid="impUserid";
			}
			else if(response.data.role_id.split(",").includes("20")&&response.data.role_id.split(",").includes("52")||response.data.role_id.split(",").includes("20")&&response.data.role_id.split(",").includes("51")||response.data.role_id.split(",").includes("52")&&response.data.role_id.split(",").includes("19"))
			{
			  changeUserid="impUserid,changeUserid";
			}
			else if(response.data.role_id.split(",").includes("19")&&(!response.data.role_id.split(",").includes("51")))
			{
			  changeUserid="changeUserid";
			}
			else if(response.data.role_id.split(",").includes("51")&&(!response.data.role_id.split(",").includes("19")))
			{
			  changeUserid="impUserid";
			}
			else if(response.data.role_id.split(",").includes("19")&&response.data.role_id.split(",").includes("51"))
			{
			  changeUserid="impUserid,changeUserid";
			}
            this.setState({user_id:response.data.user_id,role_id:response.data.role_id,groupID:response.data.groupId,roleID:response.data.role_id,changeImplentationRole: changeUserid,date_format:response.data.dateformat});
		});
    }

    showHideAlfy(value){
       this.setState({showHideAlfy: value});
    }
    showHideRightNav(value){
       this.setState({showHideRightNav:!this.state.showHideRightNav});
    }
    processHierarchy() {
       this.setState({ processhierarchy:1,isImpactCI:false,isRequestorShow:false,isIndividualShow:false,isImplementIndividualShow:false,});
    }
    setErrorServiceColor(value){
		this.setState({serviceErrorColor:value});
    }
    setErrorImpactedColor(value){		
		this.setState({ImpactedErrorColor:value})
    }
    setErrorConsumerColor(value){		
		this.setState({consumerErrorColor:value});
    }

    updateRiskAssessmentDetails({actionType = '', methodology = '', data = '', residualRisk = ''}){
      // console.log('Create Change Index - ', {actionType, methodology, data, residualRisk});
      try{
        switch(actionType){
          case 'updatePayload':
            let riskArr = constructRiskAssessmentPayload(methodology, data);
            const obj = {
              'residualRisk': residualRisk,
              'riskAssessment': riskArr
            }
            this.setState({riskAssessmentDetails: obj});
            break;
          case 'isMandatory':
            this.setState({isRiskAssessmentMandatory: data});
            break;
          case 'isChanged':
            this.setState({isRiskAssessmentChanged: data});
            break;
          case 'isDataFilled':
            this.setState({isRiskAssessmentFilled: data});
            break;
        }
      }catch(e){}
    }
    showConsumerDetails(value){
      if(value){
        this.setState({isRequestorShow:value,isImpactCI:false,isIndividualShow:false,isImplementIndividualShow:false,processhierarchy:0})
      }else{
        this.setState({isRequestorShow: value}); 
      }

    }
    showImpactCI(value){
      if(value){
      this.setState({isImpactCI:value,isRequestorShow:false,isIndividualShow:false,isImplementIndividualShow:false,processhierarchy:0})
      }else{
        this.setState({isImpactCI: value}); 
      }
    }
    
    showIndividualDetails(value){
      if(value){
        this.setState({isImpactCI:false,isRequestorShow:false,isImplementIndividualShow:false,isIndividualShow:value,processhierarchy:0})
      }else{
        this.setState({isIndividualShow: value}); 
      }

    }
    showImplementIndividualDetails(value){
      if(value){
      this.setState({isImplementIndividualShow:value,isImpactCI:false,isRequestorShow:false,isIndividualShow:false,processhierarchy:0})
      }else{
        this.setState({isImplementIndividualShow: value}); 
      }
    }
    setIndividualDetails(id,){
      this.setState({individualID: id});
    }
    setImplementIndividualDetails(id){
      this.setState({individualImptID: id});
    }
    render(){
    return (
      <div className="container-fluid">
        {this.state.role_id !== "" ? (
          <WIBSearchInner
            tr={this.props.tr}
            roleIdentification={this.roleIdentification}
            group_id={this.state.groupID}
            changeImplentationRole={this.state.changeImplentationRole}
          />
        ) : null}
        <div>
          <div className="margin-t-10 margin-b-15">
            <Breadcrumbs activePageName={this.props.tr['Create']} parentPageurl="/quickViewBreakFix" parentPageName={this.props.tr["Work Item Board"]} />
          </div>
          <XsmSupportHeader
            translator={this.props.tr}
            date_format={this.state.date_format}
            processHierarchy={this.processHierarchy}
            setErrorServiceColor={this.setErrorServiceColor}
            setErrorImpactedColor={this.setErrorImpactedColor}
            seTypeaheadValidation={this.seTypeaheadValidation}
            setErrorConsumerColor={this.setErrorConsumerColor}
            riskAssessmentDetails = {this.state.riskAssessmentDetails}
            isRiskAssessmentMandatory = {this.state.isRiskAssessmentMandatory}
            isRiskAssessmentFilled = {this.state.isRiskAssessmentFilled}
          />
        </div>
        <Row className="row-eq-height">
          <Col md={8} sm={12} xs={12}>
            <ChangeForm
              roleIdentification={this.roleIdentification}
              setErrorImpactedColor={this.setErrorImpactedColor}
              ImpactedErrorColor={this.state.ImpactedErrorColor}
              setErrorServiceColor={this.setErrorServiceColor}
              serviceErrorColor={this.state.serviceErrorColor}
              user_id={this.state.user_id}
              role_id={this.state.role_id}
              date_format={this.state.date_format}
              changeActionView={this.changeActionView}
              translator={this.props.tr}
              seTypeaheadValidation={this.seTypeaheadValidation}
              companyError={this.state.companyError}
              setEnableErrors={this.setEnableErrors}
              consumerErrorColor={this.state.consumerErrorColor}
              // riskAssessmentDetails = {this.state.riskAssessmentDetails}
              updateRiskAssessmentDetails = {this.updateRiskAssessmentDetails}
              selectedAssignmentGroup={this.state.selectedAssignmentGroup}
              selectedAssignmentUser={this.state.selectedAssignmentUser}
              selectedImpGroup={this.state.selectedImpGroup}
              selectedImpUser={this.state.selectedImpUser}
              setAssignmentGrpUsr={this.setAssignmentGrpUsr}
              setAssignmentGrpUsrImp={this.setAssignmentGrpUsrImp}
              showConsumerDetails={this.showConsumerDetails}
              showIndividualDetails={this.showIndividualDetails}
              setErrorConsumerColor={this.setErrorConsumerColor}
              showImpactCI={this.showImpactCI}
              showImplementIndividualDetails={this.showImplementIndividualDetails}
              setIndividualDetails={this.setIndividualDetails}
              setImplementIndividualDetails={this.setImplementIndividualDetails}
            />
          </Col>
          <Col md={4} sm={12} xs={12} className="colRightHeight">
            <div className="stickyArea rBoxStyle">
            {this.state.isRequestorShow?<UserDetails  userId={GLOBAL.breakFixConsumerID} translator={this.props.tr} userDetailsLabel={"Requestor Details"}/>:''}
            {this.state.isImplementIndividualShow?<UserDetails userId={this.state.individualImptID} userDetailsLabel={"Individual Details"}/>:''}
            {this.state.isIndividualShow?<UserDetails userId={this.state.individualID} userDetailsLabel={"Individual Details"}/>:''}
            {this.state.isImpactCI?<CIDEtails ciId={GLOBAL.ciId} translator={this.props.tr}/>:''}
            {this.state.processhierarchy == 0 && !(this.state.isRequestorShow || this.state.isImplementIndividualShow || this.state.isIndividualShow ||this.state.isImpactCI) ? (
                <XsmProcessHierarchy translator={this.props.tr} />
              ) : (
                <XsmBreakFixAction0 />
              )}
              {this.state.processhierarchy == 1 ? (
                <XsmProcessHierarchy translator={this.props.tr} />
              ) : (
                <XsmBreakFixAction0 />
              )}
              {this.state.processhierarchy == 2 ? (
                <XsmServiceDetails
                  translator={this.props.tr}
                  categoryID={this.state.serviceId}
                />
              ) : (
                <XsmBreakFixAction0 />
              )}
              {this.state.processhierarchy == 3 ? (
                <XsmUserDetails
                  translator={this.props.tr}
                  userId={this.state.consumerId}
                />
              ) : (
                <XsmBreakFixAction0 />
              )}
             
              {this.state.processhierarchy ==  4?<AvailableIndividuals setAssignmentGrpUsr={this.setAssignmentGrpUsr}  selectedAssignmentGroup={this.state.selectedAssignmentGroup} selectedAssignmentUser={this.state.selectedAssignmentUser} module="Change"/>:""}
              {this.state.processhierarchy ==  5?<AvailableIndividuals setAssignmentGrpUsrImp={this.setAssignmentGrpUsrImp}  selectedAssignmentGroup={this.state.selectedImpGroup} selectedAssignmentUser={this.state.selectedImpUser} module="Implementation"/>:""}
            </div>
          </Col>
        </Row>
      </div>
    );
    }
}

export function mapStateToProps(state){

	return {
	tr:state.spcmReducer.tr,
	lang:state.spcmReducer.lang
  };
}
function mapDispatchToProps(dispatch){
	return bindActionCreators({
    getTranslation
  }, dispatch);
}
export default connect(mapStateToProps,mapDispatchToProps)(CreateChangeIndex);