
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export default function (state = initialState.cimUserDetailsForUser, action){


	switch (action.type){
		case 'LOAD_BREAKFIXCIMDETAILS_SUCCESS_FOR_USER':
	          return action.cimUserDetailsForUser.data
		default:
				return state;
		}

}
