
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { useSelector } from "react-redux";
import ListLoader from "../common/loaders/ListLoader";
import TableComponent from "../common/ReactTable/TableComponent";

const IntegrationHubListView = (props) => {
  const integrationHubList = useSelector(
    (state) => state.getIntegrationHubData.integrationHubList
  );
  const showLoader = useSelector((state) => state.showLoader.loading);
  const translator = useSelector((state) => state.spcmReducer.tr);
  const tableColumns = [
    {
      name: translator["Company"],
      selector: (row) => row.tenantName,
      sortable: true,
    },
    {
      name: translator["Scenario"],
      selector: (row) => row.scenarioName,
      sortable: true,
    },
    {
      name: translator["Criteria"],
      selector: (row) => row.criteria,
      sortable: true,
    },
    {
      name: translator["End Point"],
      selector: (row) => row.endpoint,
      sortable: true,
    },
  ];

  const filterColumns = ["tenantName", "scenarioName", "criteria", "endpoint"];

  const onRowSelect = (row) => {
    props.setShortDescValues(
      row.id,
      row.scenarioName,
      row.criteria,
      row.endpoint,
      row.username,
      row.tenantName,
      row.requestMethod
    );
    props.showRightSide(true);
  };

  const renderIntegrationHubTableList = () => {
    return (
      <div className="dtaTableDv integrateHubTable">
        <div className={props.rightDisplay ? "leftTrue srchPosTop" : "rightTrue srchPosTop"}>
          <TableComponent
            data={integrationHubList}
            columns={tableColumns}
            headerColumns={filterColumns}
            onRowSelect={onRowSelect}
            rightDisplay={props.rightDisplay}
            uniqueKey={'id'}
          />
        </div>
      </div>
    );
  };

  if (!integrationHubList) return null;
  if (integrationHubList.length == undefined) {
    return <ListLoader />;
  } else if (integrationHubList.length === 0) {
    return (
      renderIntegrationHubTableList()
    )
  } else {
    return (
      <div className="myOrderDiv">
        {showLoader ? (
          <ListLoader />
        ) : (
          renderIntegrationHubTableList()
        )}
      </div>
    );
  }
};

export default IntegrationHubListView;
