
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Row, Col, Nav } from "react-bootstrap";
let sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
import _ from "lodash";
import { GLOBAL } from "_Globals";
import axios from "axios";
import { connect } from "react-redux";
import { reduxForm, SubmissionError } from "redux-form";
import swal from "sweetalert2";
import { navigationHooks } from "../../../../helpers/NavigationHook";
import { Link, NavLink } from "react-router-dom";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import {LuSearch, LuBarChart} from 'react-icons/lu';
import { MyContext } from '_MyContext';

let WorkScheduleHeader = class WorkScheduleHeader extends React.Component {
  static contextType = MyContext;

  componentDidMount() {
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
    this.context.newTabPrevent();
	}
	componentDidUpdate() {
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
    this.context.newTabPrevent();
	}
  saveChange(values) {
    //as holiday UI is not availabe posing only a single holiday schedule
    if (`${JSON.stringify(values, null, 2)}` == "{}") {
      throw new SubmissionError({ _error: "Please select a values!" });
    } else if (
      values.scheduleCompany == 0 ||
      values.scheduleCompanyName == ""
    ) {
      GLOBAL.holidayCompanyError = "error";
      throw new SubmissionError({
        _error:
          "The form has missing required fields, please revalidate the highlighted fields and update the form !",
      });
    } else if (values.scheduleName == "" || values.scheduleName.trim() == "") {
      GLOBAL.holidayscheduleNameError = "error";
      throw new SubmissionError({
        _error:
          "The form has missing required fields, please revalidate the highlighted fields and update the form !",
      });
    } else if (
      values.scheduleStatus == "" ||
      values.scheduleStatusName == "Select"
    ) {
      GLOBAL.statusError = "error";
      throw new SubmissionError({
        _error:
          "The form has missing required fields, please revalidate the highlighted fields and update the form !",
      });
    } else {
      axios
        .post(GLOBAL.holidaySchedulePost, {
          companyName: values.scheduleCompanyName,
          companyId: values.scheduleCompany,
          scheduleName: values.scheduleName,
          status: values.scheduleStatusName,
          createdByName: "",
          createdBy: "",
          statusId: values.scheduleStatus,
        })
        .then((response) => {
          if (response.status == 201) {
            console.log(
              response.data.api_error != null,
              response.data.api_error == "",
              response.data.api_error
            );
            if (response.data.data == null) {
              console.log("response.data.error", response.data.api_error);
              swal.fire(response.data.api_error);
            } else {
              navigationHooks.navigate(
                "/editHolidaySchedule/" + response.data.data.scheduleId
              );
            }
          } else {
            console.log("error creating holiday schedule");
          }
        })
        .catch((err) => {
          console.log("error creating holiday schedule", err);
        });
    }
  }
  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;
    return (
      <Row className="margin-b-15">
        <Col lg={7} md={7} sm={6} xs={12}>
          <h1 bsClass="" className="sPageHeading1">
            {this.props.translator["Holiday Schedule"]}
          </h1>
        </Col>
        <Col lg={5} md={5} sm={6} xs={12}>
          <div className="status myStatus marginT10 margin-t-10">
            <Nav as="ul">
              <Nav.Item
                as="li"
                title={this.props.translator["Save"]}
                eventKey={1}
                onClick={handleSubmit(this.saveChange)}
              >
                <Link to="javascript:void(0)" className="ctrlKeyPrevent" ref={e => this.saveObjRef = e}>
                  <IoSaveOutline/>
                </Link>
              </Nav.Item>

              <Nav.Item
                as="li"
                title={this.props.translator["Close"]}
                eventKey={2}
              >
                <NavLink to="/slaHolidaylist" className="ctrlKeyPrevent" ref={e => this.closeButton = e}>
                  <IoClose/>
                </NavLink>
              </Nav.Item>
            </Nav>
          </div>
          <div className="status myStatusGap border-r margin-t-10 margin-r-10 padding-r-10">
            <Nav as="ul">
              <Nav.Item
                as="li"
                title={this.props.translator["Process Hierarchy"]}
                onClick={() => {
                  this.props.processHierarchy();
                }}
                eventKey={4}
              >
                <a href=":void(0)">
                  <LuSearch/><LuBarChart className='processBarIcn'/>
                </a>
              </Nav.Item>
            </Nav>
          </div>
        </Col>
      </Row>
    );
  }
};

WorkScheduleHeader = reduxForm({
  form: "createHolidaySchedule",
})(WorkScheduleHeader);

export default connect(null, null)(WorkScheduleHeader);
