
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/

import initialState from '../initialState';

export default function (state = initialState.invRelatedDataDetailsCount, action){
	switch (action.type){
		case 'LOAD_RELATED_BREAKFIX_DATA_SUCCESS':
				return action.invRelatedDataDetailsCount;
		default:
				return state;
		}

}