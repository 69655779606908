
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { ListView, ListViewFooter } from "@progress/kendo-react-listview";
import { MdEdit, MdDelete} from 'react-icons/md';
import { Button } from '@progress/kendo-react-buttons';
import { Row, Col } from 'react-bootstrap';

const Footer = () => {
    return (
        <ListViewFooter></ListViewFooter>
    )
}

const ListLayout = (props) => {
    const { deleteItem, editItem } = props;
    const Item = (props) => {
        let item = props.dataItem;
        return (
            <div className="d-flex k-listview-item border p-1">
                <Row className='w-100'>
                    <Col>{item.title}</Col>
                    <Col>{item.connector.module}</Col>
                    <Col className="d-flex">
                        <Button onClick={() => editItem(item._id)} className='ms-auto' size="small" fillMode="flat"><MdEdit></MdEdit></Button>
                        <Button onClick={() => deleteItem(item._id)} size="small" fillMode="flat"><MdDelete></MdDelete></Button>
                    </Col>
                </Row>
            </div>
        )
    }
    return (
        <div className="rightPanelGap">
            <ListView
                data={props.data}
                item={Item}
                style={{
                    width: "100%",
                }}
                footer={Footer}
            ></ListView>
        </div>

    );
}

export default ListLayout;