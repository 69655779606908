
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import { useSelector } from "react-redux";

import PindCategories from "../../../common/header/pind-categories";
import Breadcrumbs from "../../../common/header/breadcrumbs";
import Header from "./header";
import FormComponent from "./form";


const Index = () => {
  const tr = useSelector((state) => state.spcmReducer.tr);
  const [companyErrorState, setCompanyErrorState] = useState('');
  const createMemberTagInitialDetails = useSelector((state) => state.foundation_tags_reducer.createMemberTagInitialDetails);

  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Breadcrumbs activePageName="Create" parentPageurl='/customAttribute' parentPageName="Custom Attribute" />
      </Container>

      <Container fluid>
        <Header setCompanyErrorState={setCompanyErrorState} />
        <Row>
          <Col md={8} xs={12}>
            <FormComponent companyErrorState={companyErrorState} compositeTagData={createMemberTagInitialDetails} setCompanyErrorState={setCompanyErrorState} />
          </Col>
        </Row>
      </Container>
    </main>
  )
}

export default Index;
