
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState, useRef } from "react";
import { Form, Row, Col, Button, ButtonToolbar } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { Formik } from "formik";
import { _checkbox } from "../../common/formFields";
import {
  loadCIMenulistSubStatusCopy,
  loadCIType,
  loadCIMenulistStatus,
} from "../../../actions/cmdb/lovAction";
import axios from "axios";
import Swal from "sweetalert2";
import { SelectField, InputTextField } from "../../common/forms/FormFields";
import { ImSpinner6 } from "react-icons/im";

function validate(values) {
  const errors = {};

  if (!values.ciName) {
    errors.ciName = "CI Name is required";
  }

  if (!values.type) {
    errors.type = "Type is required";
  }

  if (!values.status) {
    errors.status = "Status is required";
  }

  if (!values.subStatus) {
    errors.subStatus = "Sub Status is required";
  }

  return errors;
}

const CopyCi = (props) => {
  const tr = useSelector((state) => state.spcmReducer.tr);
  const dispatch = useDispatch();
  const [customAttributes, setCustomAttributes] = useState("false");
  const [technicalAttributes, setTechnicalAttributes] = useState("false");
  const [approvals, setApprovals] = useState("false");
  const [taskPlan, setTaskPlan] = useState("false");
  const CIEditDetails = useSelector((state) => state.CIEditDetails);
  const ciTypeOptions = useSelector((state) => state.ciType);
  const statusOptions = useSelector((state) => state?.ciStatus);
  const subStatusOptions = useSelector((state) => state?.ciSubStatusCopy);
  const formikRef = useRef(null);

  useEffect(() => {
    dispatch(loadCIType());
  }, [dispatch]);

  useEffect(() => {
    if (CIEditDetails?.STATUS) {
      dispatch(loadCIMenulistStatus("Status List"));
      dispatch(
        loadCIMenulistSubStatusCopy("SubStatus List", props.values?.status)
      );
    }
  }, [CIEditDetails?.STATUS]);

  const submitForm = async (values, { resetForm }) => {
    console.log("values====", values);
    values.copyFromCiId = CIEditDetails.CI_ID;
    values.copyFromCiCode = CIEditDetails.CI_CODE;
    values.copyFromCompanyName = CIEditDetails.COMPANY_NAME;
    values.ciName = values.ciName;
    values.serialNumber = values.serialNumber;
    values.type = values.type;
    values.status = values.status;
    values.subStatus = values.subStatus;
    values.customAttributes = customAttributes;
    values.technicalAttributes = technicalAttributes;
    values.approvals = approvals;
    values.taskPlan = taskPlan;

    const response = await axios.post("/api/config_items/copyCI", values);
    console.log("Inside Copy Success");
    console.log("props.insideEditCi", props.insideEditCi);
    if (props.insideEditCi !== true) {
      props.toggleFormListFunc(false);
    } else {
      Swal.fire({
        text:
          "New CI has been created with CI Code: " + response.data[0]?.CI_CODE,
        icon: "success",
        confirmButtonText: tr["OK"],
      });
    }

    if (
      response?.data[0]?.upsert_status === false ||
      response?.data[0]?.upsert_status === "false"
    ) {
      Swal.fire({
        text: response.data[0]?.message,
        icon: "warning",
        confirmButtonText: tr["OK"],
      });
    }

    resetForm();
    setCustomAttributes("false");
    setTechnicalAttributes("false");
    setApprovals("false");
    setTaskPlan("false");
    formikRef?.current?.setSubmitting(false);
  };

  const onChangeFlag = (e) => {
    const { name, checked } = e.target;
    const value = checked.toString();

    switch (name) {
      case "customAttributes":
        setCustomAttributes(value);
        break;
      case "technicalAttributes":
        setTechnicalAttributes(value);
        break;
      case "approvals":
        setApprovals(value);
        break;
      case "taskPlan":
        setTaskPlan(value);
        break;
      default:
        break;
    }
  };

  return (
    <div className="">
      <Row>
        <Col xs={12}>
          <div className="rPageHeading">
            <div className="offNam margin-t-5 margin-b-5">{tr["Copy CI"]}</div>
          </div>
        </Col>
      </Row>
      <div className="rBoxGap">
        <Formik
          initialValues={{
            ciName: "",
            serialNumber: "",
            type: "",
            status: "",
            subStatus: "",
          }}
          validate={validate}
          onSubmit={submitForm}
          innerRef={formikRef}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Row className="validateCi">
              <SelectField
                  name="type"
                  label="Type"
                  required
                  onChange={(e) => {
                    const val = e.target.value;
                    let statusVal = "";
                    let subStatusVal = "";
                    if (val === "10") {
                      statusVal = "6";
                      subStatusVal = "11"; 
                    } else if (val === "5" || val === "15") {
                      statusVal = "1"; 
                      subStatusVal = "1"; 
                    }
                    setFieldValue("type", val);
                    setFieldValue("status", statusVal);
                    setFieldValue("subStatus", subStatusVal);
                    dispatch(loadCIMenulistSubStatusCopy("SubStatus List", statusVal));
                  }}
                  onBlur={handleBlur}
                  value={values.type}
                  isInvalid={!!errors.type && touched.type}
                >
                  {ciTypeOptions?.map((typeObj, i) => (
                    <option value={typeObj.FIELD1_VALUE} key={i}>
                      {typeObj.FIELD1_KEY}
                    </option>
                  ))}
                </SelectField>
                {errors.type && touched.type && (
                  <div className="error-message validateErrorMessage">
                    {errors.type}
                  </div>
                )}
              </Row>
              <Row className="validateCi">
                <InputTextField
                  required
                  name="ciName"
                  label="CI Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ciName}
                  isInvalid={!!errors.ciName && touched.ciName}
                />
                {errors.ciName && touched.ciName && (
                  <div className="error-message validateErrorMessage">
                    {errors.ciName}
                  </div>
                )}
              </Row>
              <Row className="validateCi">
                <InputTextField
                  name="serialNumber"
                  label="Serial Number"
                  maxLength={250}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.serialNumber}
                />
              </Row>
              <Row className="validateCi">
                <SelectField
                  name="status"
                  label="Status"
                  required
                  onChange={(e) => {
                    setFieldValue("status_name", e.target.value);
                    setFieldValue("status", e.target.value);
                  }}
                  onBlur={handleBlur}
                  value={values.status}
                  isInvalid={!!errors.status && touched.status}
                >
                  {statusOptions?.map((option) => (
                    <option key={option.ID} value={option?.ID}>
                      {option.STATUS_NAME}
                    </option>
                  ))}
                </SelectField>
                {errors.status && touched.status && (
                  <div className="error-message validateErrorMessage">
                    {errors.status}
                  </div>
                )}
              </Row>
              <Row className="validateCi">
                <SelectField
                  name="subStatus"
                  label="Sub Status"
                  required
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    setFieldValue("subStatus", selectedValue);
                  }}
                  onBlur={handleBlur}
                  value={values.subStatus}
                  isInvalid={!!errors.subStatus && touched.subStatus}
                >
                  {subStatusOptions?.map((option) => (
                    <option key={`sub_key-${option?.ID}`} value={option?.ID}>
                      {option?.SUB_STATUS_NAME}
                    </option>
                  ))}
                </SelectField>
                {errors.subStatus && touched.subStatus && (
                  <div className="error-message validateErrorMessage">
                    {errors.subStatus}
                  </div>
                )}
              </Row>

              <Row>
                <Col md={12} className="dCheckbox customCheckBoxContainer">
                  <Form.Label bsClass="">
                    {tr["Select the items to be copied:"]}
                  </Form.Label>
                  <Field
                    component={_checkbox}
                    className="form-control"
                    name="customAttributes"
                    label={tr["Custom Attribute"]}
                    onChange={onChangeFlag}
                    checked={customAttributes == "true"}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={12} className="dCheckbox customCheckBoxContainer">
                  <Field
                    component={_checkbox}
                    className="form-control"
                    name="technicalAttributes"
                    label={tr["Technical Attributes"]}
                    onChange={onChangeFlag}
                    checked={technicalAttributes == "true"}
                  />
                </Col>
              </Row>

              <Row className="margin-t-15">
                <Col md={12}>
                  <ButtonToolbar className="black">
                    <Button
                      className="rgSidrkBtn"
                      title={tr["Make a Copy"]}
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                      type="submit"
                      size="sm"
                      bsStyle="primary"
                    >
                      {isSubmitting && <ImSpinner6 className="icn-spinner" />}{" "}
                      {tr["Make a Copy"]}
                    </Button>
                    <Button
                      className="rgSiLigBtn"
                      title="Close"
                      onClick={() => {
                        props.changeActionView("specifyAttributeValues");
                        props.rightEditPanel(true);
                      }}
                      bsSize="small"
                      bsStyle="text"
                    >
                      {tr["Close"]}
                    </Button>
                  </ButtonToolbar>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default reduxForm({
  form: "copyCIForm",
  validate,
})(CopyCi);
