
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});
//below URL used for testing purpose
//const breakFixUrl = 'https://xsm-f.mybluemix.net/api/SX_FAVOURITEOFFERINGs/';
import { GLOBAL }  from '../../components/Globals';
import constanturl from '../../../src/utils/constants.js';
//let breakFixUrl =`https://xsm-f.mybluemix.net/api/breakfixitem?breakfixitemId=`;
//let breakFixUrl=`https://xsm-f.mybluemix.net/groupListSelfService?companyId=2932&group=ResolvedER`
export function loadChangeRelatedWorkDetailsSuccess(changeRelatedWorkDetails){
	//alert('in success')
	return {
		type: 'LOAD_CMDBCHANGERELATEDWORKDETAILS_SUCCESS',
		changeRelatedWorkDetails
	};
}

export function loadChangeRelatedWorkDetailsFailure(errorResponse){
	return {
		type: 'LOAD_CMDBCHANGERELATEDWORKDETAILS_FAILURE',
		errorResponse
	};
}
export function loadChangeRelatedWorkDetails(query,value,module,companyid){
	//alert('action called and parameter is '+breakFixId)
	//if(breakFixId=="0")
	//breakFixId="";
	console.log("module::",query,value,module,companyid);
	return(dispatch) =>{
		if (!(value == "--")) {
			dispatch({
				type: 'MENU_LOADER_IN_PROGRESS',
				payload: ''
			});
		}
		//alert("EditGroupIdAction123"+breakFixId);

				//alert("hiaaa::::");
				let relatedurl="";

				if(module=="CMDB") {
					relatedurl=GLOBAL.cmdbRelatedWorkUrl+"?searchByName="+query+"&searchByValue="+value+"&company_id="+companyid
					api.get(relatedurl)
					.then((changeRelatedWorkDetails)=>{
						if(!changeRelatedWorkDetails){//alert('not breakFix')
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							dispatch({type:'MENU_LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
							throw Error(changeRelatedWorkDetails.statusText);
						}
						return changeRelatedWorkDetails;
					})
					.then((changeRelatedWorkDetails) =>{//alert('yes it is')
						dispatch(loadChangeRelatedWorkDetailsSuccess(changeRelatedWorkDetails));
						dispatch({type:'MENU_LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
					})
					.catch((err) => {
						dispatch({type:'MENU_LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
						console.log(err);
					});
					//console.log("not calling loadBreakFixEditDetails");
				}
				else if(module == 'SLA') {
					relatedurl=GLOBAL.slaRelatedWorkUrl+"/"+companyid
					api.get(relatedurl, { headers: { slaname: value } })
					.then((changeRelatedWorkDetails)=>{
						if(!changeRelatedWorkDetails){//alert('not breakFix')
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							dispatch({type:'MENU_LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
							throw Error(changeRelatedWorkDetails.statusText);
						}
						return changeRelatedWorkDetails;
					})
					.then((changeRelatedWorkDetails) =>{//alert('yes it is')
						dispatch(loadChangeRelatedWorkDetailsSuccess({data: changeRelatedWorkDetails}));
						dispatch({type:'MENU_LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
					})
					.catch((err) => {
						dispatch({type:'MENU_LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
						console.log(err);
					});
					//console.log("not calling loadBreakFixEditDetails");
				}
	};
}
