
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { findDuration, convertDate } from "../Helper";
import { prefDateFormatAccordingUser } from "../common/CommonMethod";

const ShortDescription = ({ rowData, dateformat, setFiltersData }) => {
  return (
    <div className="rBoxTabs hideTabNav">
      <ListGroup bsPrefix=" " bsClass="">
        <ListGroup.Item
          bsPrefix=" "
          bsClass=""
          className="rwTable"
          style={{ "border-top": "none" }}
        >
          <span className="rwKey">Agenda</span>
          <span className="rwVal">
            <div>{parse(String(rowData?.objectives || "--"))}</div>
          </span>
        </ListGroup.Item>
        <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
          <span className="rwKey">Start Time </span>
          <span className="rwVal">{rowData?.recurrenceStartDateTime || "--"}</span>
        </ListGroup.Item>
        <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
          <span className="rwKey">End Time </span>
          <span className="rwVal">{rowData?.recurrenceEndDateTime || "--"}</span>
        </ListGroup.Item>

        <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
          <span className="rwKey">Chairperson</span>
          <span className="rwVal">{rowData?.primaryChairPersonName || "--"}</span>
        </ListGroup.Item>
        <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
          <span className="rwKey">Duration</span>
          <span className="rwVal">
            {findDuration(
              convertDate(
                rowData?.recurrenceStartDateTime,
                prefDateFormatAccordingUser(dateformat, true)
              ),
              convertDate(
                rowData?.recurrenceEndDateTime,
                prefDateFormatAccordingUser(dateformat, true)
              )
            )}
          </span>
        </ListGroup.Item>
        <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
          <span className="rwKey">Meeting Type</span>
          <span className="rwVal">{rowData?.meetingLocation || "--"}</span>
        </ListGroup.Item>
        <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
          <span className="rwKey">Meeting Details</span>
          <span className="rwVal">
            {rowData?.meetingLocationDetails || "--"}
          </span>
        </ListGroup.Item>
      </ListGroup>
    </div>
  );
};

export default ShortDescription;
