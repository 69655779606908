
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

import PindCategories from "_Commons/header/pind-categories";
import Breadcrumbs from "_Commons/header/breadcrumbs";
import MyHeader from "./header";
import MyList from "./list";
import MyShortDesc from "./shortDesc";
import { myViewNotificationAction } from "../../../actions/spcmActions";
const cookies = new Cookies();
import Cookies from 'universal-cookie';
import { useLocation } from "react-router";
import "_Css/common/_table.scss";
import "_Css/form/_form.scss";

let homepagelocation = cookies.get('gph');
if (homepagelocation) {
  homepagelocation = homepagelocation.replace('s:', '');
}
if (homepagelocation) {
  homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
}
homepagelocation = homepagelocation.split('~');
// homepagelocation = homepagelocation[0];
let email_address = cookies.get("gph");
email_address = email_address.split("~");
email_address = email_address[29];
let dateFormat = homepagelocation[2];
// let dateFormat = dateformatCookie.substr(0, 10);


const MyIndex = () => {
  const dispatch = useDispatch();
  const myViewNotificationList = useSelector((state) => state.spcmReducer.myViewNotificationList);

  const [isRightSideVisible, setRightSideVisible] = useState(false);
  const [notificationListData, setNotificationListData] = useState({});
  const [tab, setTab] = useState("direct");
  const [filterTabActive, setFilterTabActive] = useState(1);
  const [companySearchInput, setCompanySearchInput] = useState("");
  const [nameSearchInput, setNameSearchInput] = useState("");
  const [orderNumberSearchInput, setOrderNumberSearchInput] = useState("");
  const [panelResize,setPanelResize] = useState();
  const [searchParams, setSearchParams] = useState({});
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);
  const [rowIndex, setRowIndex] = useState(0);
  const [isClearAllFilter, setIsClearAllFilter] = useState(false);
  const [modifiedDateRef, setModifiedDateRef] = useState(null);
  const [modifiedStartDate, setModifiedStartDate] = useState({});
  const [modifiedEndDate, setModifiedEndDate] = useState({});
  const [modifiedDateFilterValues, setModifiedDateFilterValues] = useState([]);
  const [selectedDate, setSelectedDate] = useState([]);
  const [sortOrder, setSortOrder] = useState("DESC");
  const [sortOnColumn, setSortOnColumn] = useState("module");

  const { state: locationState } = useLocation();

  const showRightSideFun = (val) => {
    setRightSideVisible(val);
  };
  const notificationListHandler = (data) => {
    setNotificationListData(data)
  }
  useEffect(() => {
    if(locationState) {
      if(locationState.type === 'watcher') {
        setTab('watcher');
        setFilterTabActive(2);
      } else if(locationState.type === 'direct') {
        setTab('direct');
        setFilterTabActive(1);
      }
      let searchParams = { orderNumber: locationState?.workItemId }
      setOrderNumberSearchInput(locationState?.workItemId);
      setSearchParams(searchParams);
      setIsClearAllFilter(true);
      dispatch(myViewNotificationAction(email_address, locationState.type, "", searchParams, 1, 10, "","true"));
    } else {
      dispatch(myViewNotificationAction(email_address, tab, "", "", 1, 10, "","true"));
    }
  }, [locationState])

  const setModifiedStartAndEndDate = (startDate, endDate, filterValue)=> {
    setModifiedStartDate(startDate)
    setModifiedEndDate(endDate)
    setModifiedDateFilterValues(filterValue)
  }

  const setActiveKeyVal = (value) => {
    setCompanySearchInput("");
    setSearchParams({});
    setNameSearchInput("");
    setOrderNumberSearchInput("");
    setIsClearAllFilter(false);
    setSelectedDate("");
  }

  const tabHandler = (type, key) => {
    setTab(type);
    setFilterTabActive(key);

  }

  
const setSortOrderHandler = (sortBy, sortOn) => {
 setSortOrder(sortBy)
 setSortOnColumn(sortOn);
}
  console.log("size ind",size)
  let myViewNotificationData = myViewNotificationList ? myViewNotificationList : { data: [] };
  let meta = !_.isEmpty(myViewNotificationList)
    ? myViewNotificationList.meta
    : {};
  let pageSize = meta && meta.limit ? meta.limit : 0;
  let currentPage = meta && meta.currentPage ? meta.currentPage : 1;
  let startLimit = pageSize * (currentPage - 1) + 1;
  let endLimit = pageSize * currentPage;
  let recordCount = meta && meta.rowCount ? meta.rowCount : 0;
  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
      <Row className="">
            <Col lg={8} md={8} sm={9} xs={9}>
            <Breadcrumbs activePageName="My View" />
            </Col>
       
         
              <Col lg={4} md={4} sm={3} xs={3}>
                {recordCount != 0 ? (
                  <div className="tabDatacount">
                    {startLimit}-
                    {endLimit > recordCount
                      ? (endLimit = recordCount)
                      : endLimit}{" "}
                    of {recordCount}
                  </div>
                ) : null}
              </Col>
           </Row>
      </Container>

      <Container fluid>
        <MyHeader showRightSideFun={showRightSideFun} 
        email_address={email_address} 
        tabHandler={tabHandler} 
        tab={tab}
        filterTabActive={filterTabActive}
        isRightSideVisible={isRightSideVisible}
        notificationListHandler={notificationListHandler} 
        dateFormat={dateFormat} 
        setActiveKeyVal={setActiveKeyVal}
        companySearchInput={companySearchInput}
        nameSearchInput={nameSearchInput}
        orderNumberSearchInput={orderNumberSearchInput}
        setCompanySearchInput={setCompanySearchInput}
        setOrderNumberSearchInput={setOrderNumberSearchInput}
        setNameSearchInput={setNameSearchInput}
        setSearchParams={setSearchParams}
        searchParams={searchParams}
        onRowClick={setRightSideVisible}
        showActiveRow={setRowIndex}
        rowIndex={rowIndex}
        setPage={setPage}
        size={size}
        setLimit={setSize}
        page={page}
        panelResize={panelResize}
        isClearAllFilterFunc={setIsClearAllFilter} 
        isClearAllFilter={isClearAllFilter}
        setModifiedDateRef={setModifiedDateRef}
        modifiedDateRef={modifiedDateRef}
        setModifiedStartAndEndDate={setModifiedStartAndEndDate}
        modifiedDateFilterValues={modifiedDateFilterValues}
        modifiedEndDate={modifiedEndDate}
        modifiedStartDate={modifiedStartDate}
        setSelectedDate={setSelectedDate}
        setSortOrderHandler={setSortOrderHandler}
        sortOrder={sortOrder}

        />


        <PanelGroup direction="horizontal" className="panelOverflow">
          <Panel
            id="sidebar"
            minSize={33}
            defaultSize={isRightSideVisible ? 67 : 100}
            className={
              isRightSideVisible ? "isShowLeftPanel catBordDv" : "catBordDv"
            }
          >
            <MyList isRightSideVisible={isRightSideVisible} showRightSideFun={showRightSideFun}
              notificationListHandler={notificationListHandler} dateFormat={dateFormat} tab={tab}
              setActiveKeyVal={setActiveKeyVal}
              companySearchInput={companySearchInput}
              nameSearchInput={nameSearchInput}
              orderNumberSearchInput={orderNumberSearchInput}
              setCompanySearchInput={setCompanySearchInput}
              setOrderNumberSearchInput={setOrderNumberSearchInput}
              setNameSearchInput={setNameSearchInput}
              setSearchParams={setSearchParams}
              searchParams={searchParams}
              email_address={email_address} 
              tabHandler={tabHandler}
              onRowClick={setRightSideVisible}
              showActiveRow={setRowIndex}
              rowIndex={rowIndex}
              setPage={setPage}
              size={size}
              setLimit={setSize}
              page={page}
              panelResize={panelResize}
              isClearAllFilterFunc={setIsClearAllFilter}
              setModifiedDateRef={setModifiedDateRef}  
              setModifiedStartAndEndDate={setModifiedStartAndEndDate}
              modifiedDateFilterValues={modifiedDateFilterValues}
              modifiedEndDate={modifiedEndDate}
              modifiedStartDate={modifiedStartDate}  
              setSelectedDate={setSelectedDate}
              setSortOrderHandler={setSortOrderHandler}
              sortOrder={sortOrder}
              locationState={locationState}
            />
          </Panel>
          {isRightSideVisible ? (
            <>
              <PanelResizeHandle className="isShowLeftPanel resizeHandle">
                <div className="outlne">
                  <div className="handIcn">
                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                  </div>
                </div>
              </PanelResizeHandle>
              <Panel minSize={33} order={2} defaultSize={33}>
                <div className="stickyArea rBoxStyle">
                  <MyShortDesc
                    showRightSideFun={showRightSideFun}
                    notificationListData={notificationListData}
                    dateFormat={dateFormat}
                    tab={tab} />
                </div>
              </Panel>
            </>
          ) : ("")
          }
        </PanelGroup>
      </Container>
    </main>
  );
};

export default MyIndex;
