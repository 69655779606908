
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, InputGroup, Image, Alert } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
// import dummyimage from "_Images/service-icons/service-dummy.png";
import { change, Field, reduxForm } from 'redux-form';
import { _inputField, _dropDown, _textArea, TypeaheadandDropdown } from '../../../../common/ReduxFormFields/CategoryBoard/formFields';
import { getMenuListCompanyAction } from '../../../../../actions/foundation/companyAction';
import { field_file } from '../../../../common/formFields';


const CreateForm = (props) => {
    const tr = useSelector((state) => state.spcmReducer.tr);
    const menuListFDNReducerData = useSelector((state) => state.menuListFDNReducer);
    const [selectedValueList, setSelectedValueList] = useState([]);
    const dispatch = useDispatch();

    console.log("menuListFDNReducerData", menuListFDNReducerData);
    useEffect(() => {
        dispatch(getMenuListCompanyAction(''));
    }, []);




    const onValueSelection = (selected) => {
       
        let error = '';
        if (selected.length === 0) {
            error = 'error';
        }
        let arr = [];
        setSelectedValueList(selected);
        selected.map((val) => {
            arr.push(val.label)
        })
        
        props.companyTypeFun(arr);
        props.companyTypeErrFun(error);


    };
    // useEffect(() => {
    //     dispatch(getCompanyList(""));
 
    //   }, []);

    const onCrossClick = () => {
        setSelectedValueList([]);
        props.companyTypeFun([]);
        dispatch(change("CreateCompanyForm", "companyType", []));
        props.companyTypeErrFun('error');
    };


    const onBlurCompanyType =()=>{
        let error = '';
        if (props.companyType.length === 0) {
            error = 'error';
        }
        props.companyTypeErrFun(error);
    }

    const  validateCompanyField = (fieldType, event)=> {
        switch (fieldType) {
          case "name":
            if (event.target.value == "") {
              props.setNameErr("error");
            } else {
              props.setNameErr("");
            }
            break;
          case "description":
            if (event.target.value == "") {
              props.setDescriptionErr("error");
            } else {
              props.setDescriptionErr("");
            }
            break;
          default:
        }
      }
    return (
        <Row>
             <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                <span className="rStar"></span>
                    <Form.Label>{tr["Name"]}</Form.Label>
                    <Field
                        name="name"
                        className={"form-control " + (props.nameErr)}
                        component={_inputField}
                        onBlur={(event) => {
                            validateCompanyField("name", event);
                        }}
                    />
                </Form.Group>
            </Col>
            
            <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                    <Form.Label>{tr["Company Code"]}</Form.Label>
                    <Field
                        name="code"
                        className={"form-control"}
                        component={_inputField}
                        disabled
                    />
                </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                    <Form.Label>{tr["Alias"]}</Form.Label>
                    <Field
                        name="aliasName"
                        className="form-control"
                        component={_inputField}
                    />
                </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                    <Form.Label>{tr["Status"]}</Form.Label>
                    <Field
                        name="status"
                        component="select"
                        className="form-control"
                    >
                    <option value="1">{tr["Active"]}</option>
                    <option value="0">{tr["Inactive"]}</option>
                    </Field>
                </Form.Group>
            </Col>
                    
            <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group"  >
                <span className="rStar"></span>
                    <Form.Label>{tr["Company Type"]}</Form.Label>
                    <Field
                        component={TypeaheadandDropdown}
                        onSelection={onValueSelection}
                        name="companyType"
                        className="form-control"
                        options={menuListFDNReducerData}
                        selectedOptions={selectedValueList}
                        onCrossClick={onCrossClick}
                        setErrorColor={onBlurCompanyType}
                        errorClass={props.companyTypeErr}
                        multiple={true}
                    />
                </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                <span className="rStar"></span>
                    <Form.Label>{tr["Description"]}</Form.Label>
                    <Field
                        name="description"
                        className={"form-control " + (props.descriptionErr)}
                        component={_textArea}
                        rows={2}
                        placeholder="Specify an overview about the company’s business"
                        onBlur={(event) => {
                            validateCompanyField("description", event);
                        }}
                        maxLength={500}
                    />
                </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                    <Form.Label>{tr["Notifications"]}</Form.Label>
                    <Field
                        name="notification_flag"
                        component="select"
                        className="form-control"
                    >
                    <option value="Y">{tr["Enabled"]}</option>
                    <option value="N">{tr["Disabled"]}</option>
                    </Field>
                </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                    <Form.Label>{tr["SMS Notification"]}</Form.Label>
                    <Field
                        name="sms_notification_flag"
                        component="select"
                        className="form-control"
                    >
                        <option value="N">{tr["Disabled"]}</option>
                        <option value="Y">{tr["Enabled"]}</option>
                    </Field>
                </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                    <Form.Label>{tr["Multilingual Support"]}</Form.Label>
                    <Field
                        name="multilingual_flag"
                        component="select"
                        className="form-control"
                    >
                        <option value="N">{tr["Disabled"]}</option>
                        <option value="Y">{tr["Enabled"]}</option>
                    </Field>
                </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                    <Form.Label>{tr["Lucy Url"]}</Form.Label>
                    <Field
                        name="lucyUrl"
                        className="form-control"
                        component={_inputField}
                    />
                </Form.Group>
            </Col>

            {/* <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group priceForm-group withImgbox">
                    <Form.Label >{tr["Logo"]} <b className='margin-l-10 f-size-11'>(upload only .jpeg,.jpg,.png,.giff,.gif)</b>
                    </Form.Label>
                    <InputGroup>
                        <div><Form.Control type="file" /></div>
                        <InputGroup.Text>
                            <Image src={dummyimage} />
                        </InputGroup.Text>
                    </InputGroup>
                </Form.Group>
            </Col> */}
        </Row>
    )
}



export default reduxForm({
    form: 'CreateCompanyForm',
    destroyOnUnmount: false,
    enableReinitialize: true
})(CreateForm);