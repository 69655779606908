
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export default function(state = initialState.subHamburgerCategory, action){
    //console.log('Action received:',action);
    switch (action.type){
        case 'FETCH_SUBHAMBCATEGORYLIST':
            return action.subHamburgerCategory.data;
        default:
          return state;
    }
}
