
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const offeringDetails = (state = [], action) => {
  switch (action.type) {
    case "OFFERING_DETAILS_SUCCESS":
      return action.offeringDetails.data;
    case "OFFERING_DETAILS_FAILURE":
      return action.errorResponse;
    case "RESET_OFFERING_DETAILS":
      return action.offeringDetails;
    default:
      return state;
  }
};

export const selectedComponentForOffering = (state = [], action) => {
  //console.log("action");
  //console.log(action.selectedComponentForOffering);
  switch (action.type) {
    case "SET_SELECTED_COMPONENTS":
      return action.selectedComponentForOffering;
    case "RESET_SELECTED_COMPONENTS":
      return action.selectedComponentForOffering;
    default:
      return state;
  }
};

export const offeringSlaDetails = (
  state = {
    availability: [],
    supportSla: [],
    deliveryOptions: [],
  },
  action
) => {
  switch (action.type) {
    case "OFFERING_AVAILABILITY":
      return Object.assign({}, state, {
        availability: action.availability,
      });
    case "OFFERING_SUPPORT_SLA":
      return Object.assign({}, state, {
        supportSla: action.supportSla,
      });
    case "OFFERING_DELIVERY_OPTIONS":
      return Object.assign({}, state, {
        deliveryOptions: action.deliveryOptions,
      });
    default:
      return state;
  }
};

export const relatedOfferings = (state = [], action) => {
  switch (action.type) {
    case "RELATED_OFFERINGS":
      return action.payload;
    default:
      return state;
  }
};

export const offeringView = (state = [], action) => {
  switch (action.type) {
    case "LIST_VIEW_CATALOG":
      return "list";
    case "GRID_VIEW_CATALOG":
      return "grid";
    default:
      return state;
  }
};
