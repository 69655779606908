
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GLOBAL } from "../../components/Globals";

export const VerticalCatalogApi = createApi({
  reducerPath: "verticalCatalogApi",
  baseQuery: fetchBaseQuery({
    baseUrl: window.location.origin,
  }),
  tagTypes: ["Categories", "SubCategories", "Offerings", "BusinessFn"],
  endpoints: (builder) => ({
    getBusinessFnLabels: builder.query({
      query: () => {
        let query = {
          label_type: "General",
          label_id: "",
          label_name: "",
          parent_label_type: "",
          parent_label_id: "",
          parent_label_name: "",
          start: "",
          end: "",
          sort_by: "",
          order: "",
        };
        return {
          url: GLOBAL.getVerticalCatalogLabels,
          method: "GET",
          headers: {
            query: JSON.stringify(query),
          },
        };
      },
      providesTags: ["BusinessFn"],
    }),
    getCategoryByBusinessFn: builder.query({
      query: ({ labelId = "", labelName = "", start = "", end = "" }) => {
        let query = {
          label_type: "business_function",
          label_id: labelId,
          label_name: encodeURIComponent(labelName),
          parent_label_type: "",
          parent_label_id: "",
          parent_label_name: "",
          start: start,
          end: end,
          sort_by: "",
          order: "",
        };
        return {
          url: GLOBAL.getVerticalCatalogLabels,
          method: "GET",
          headers: {
            query: JSON.stringify(query),
          },
        };
      },
      providesTags: ["Categories"],
      transformResponse: (response) => response.vertical_labels,
    }),
    getSubCategoryByCategory: builder.query({
      query: ({ labelId = "", labelName = "", start = "", end = "" }) => {
        let query = {
          label_type: "category",
          label_id: labelId,
          label_name: encodeURIComponent(labelName),
          parent_label_type: "",
          parent_label_id: "",
          parent_label_name: "",
          start: start,
          end: end,
          sort_by: "",
          order: "",
        };
        return {
          url: GLOBAL.getVerticalCatalogLabels,
          method: "GET",
          headers: {
            query: JSON.stringify(query),
          },
        };
      },
      providesTags: ["SubCategories"],
      transformResponse: (response) => response.vertical_labels,
    }),
    getOfferingsList: builder.query({
      query: ({
        url = "",
        companyId = "",
        label_type = "",
        labelId = "",
        labelName = "",
        currency = "USD",
        start = "",
        end = "",
        isGeneralSection = false,
        parentLabelType,
        parentLabelId,
        parentLabel      
      }) => {
        let query = isGeneralSection
          ? { url, companyId, currency, start, end }
          : {
              label_type,
              label_id: labelId,
              label_name: encodeURIComponent(labelName),
              parent_label_type: encodeURIComponent(parentLabelType),
              parent_label_id: parentLabelId,
              parent_label_name: encodeURIComponent(parentLabel),
              start,
              end,
              sort_by: "",
              order: "",
            };
        return {
          url: isGeneralSection ? GLOBAL.getCommonOfferingsApi : url,
          method: "GET",
          headers: {
            query: JSON.stringify(query),
          },
        };
      },
      providesTags: ["Offerings"],
    }),
  }),
});

export const {
  useGetBusinessFnLabelsQuery,
  useGetCategoryByBusinessFnQuery,
  useGetSubCategoryByCategoryQuery,
  useGetOfferingsListQuery,
  useLazyGetOfferingsListQuery
} = VerticalCatalogApi;
const { reducer } = VerticalCatalogApi;
export default reducer;
