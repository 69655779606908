
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { useNavigate } from "react-router-dom";
import CreateChangeIndex from "./CreateChangeIndex";

export default function CreateChangeMain() {
    const navigate = useNavigate();
    return <CreateChangeIndex navigate={navigate} />;
}