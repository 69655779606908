
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Form, Button, Table} from 'react-bootstrap';
import { connect } from 'react-redux';
import { getServiceSequenceList,setEditServiceDetails } from '../../../actions/spcmActions';
import ComponentForm from './serviceSequencingForm.js';
import axios from 'axios';
import ListLoader from "_Commons/loaders/ListLoader";
import Swal from 'sweetalert2';
import {IoSearch} from 'react-icons/io5';

class ListView extends React.Component {
	constructor(props){
		super(props);
		this.state={
			//orderBy:'',
			deleteError:'',
			sortIcons: false ,
			class1: 'sort up',
			class2: 'sort',
			sortByTitle:'',
			offeringName:''

		}
		this.onTextChange=this.onTextChange.bind(this);
		this.onDeleteClick=this.onDeleteClick.bind(this);
		this.handleDismiss = this.handleDismiss.bind(this);
		this.sortHover=this.sortHover.bind(this);
		this.onSearchClick=this.onSearchClick.bind(this);
	}

	handleKeyPress(e){
		if (e.key === 'Enter'){
			this.onSearchClick();
			if(e.preventDefault) e.preventDefault();
		}
	}

	sortHover(e){
		let index = e.nativeEvent.target.selectedIndex;
		this.setState({sortByTitle:e.nativeEvent.target[index].text})
	}

	onTextChange(e){
		this.setState({offeringName:e.target.value});
		console.log("TEXT",this.state.offeringName);
	}




	onSearchClick(){
		this.props.getServiceSequenceList("",this.state.offeringName);
	}

	handleDismiss() {
		this.setState({ deleteError: '' });
	  }

	onDeleteClick(deleteId){
		let str={};
		str.id=deleteId;
		str=JSON.stringify(str);
		Swal.fire({
            title: 'Are you sure you wish to delete this item?',
			
            width: 550,
            padding: '15px',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
          }).then((result) => {
            if (result.isConfirmed) {
				axios.delete("/api/featuredServices",{headers:{"query":str}}).then((resp)=>{
					this.props.getServiceSequenceList("","");
				}).catch((error)=>{
					this.setState({deleteError:error.response.data[0].message})
				})
            } else if (result.isDenied) {

            }
          })
	}

	componentWillMount(){

 		this.props.getServiceSequenceList('','');
	 }
	 

   render() {
	   const tr=this.props.tr;
	   var meta=this.props.spcmReducer.meta;
	   var serviceSequencingList = this.props.spcmReducer.serviceSequencingList;
      return (
			<div className='rBoxGap'>
			{this.props.componentFormToggle ? 
			<ComponentForm componentFormFun={this.props.componentFormFun} tr={tr} componentFormToggle={this.props.componentFormToggle}/> : 
			<div>				
				<div className="form-inline rightSideFields d-lg-flex d-md-block d-sm-flex d-block">
					<Form.Select onMouseOver={this.sortHover} title={this.state.sortByTitle} className="myControl mySel" componentClass="Select">
					<option key='s_Attribute' value="Attribute">{tr["Service Name"]}</option>
					</Form.Select>
					<Form.Control onKeyPress={this.handleKeyPress.bind(this)} className="myControl myInput margin-l-5 myInputWidth" type="text" onChange={ this.onTextChange }/>
					<div className='d-flex justify-content-end'>
					<Button type="submit" title="Search" onClick={ this.onSearchClick } className="myBtn"><IoSearch/></Button>
					<Button disabled={this.state.fulfilmentBtnState} onClick={()=>{this.props.componentFormFun(true)}} title={tr["Add"] } size="xm" className="myBtn1 float-r">{tr["Add"] }</Button>
					</div>
				</div>

				{/* {error == undefined || error == null ? null : <Alert variant="danger" className="errorMessageSec"><p>{error}</p></Alert>} */}


				<div className="f-size-12 clearboth margin-xs-t-15">
					<Table bordered condensed hover responsive className="tableView sortImg tableWithOutPointer">
					<thead>
					  <tr>
						<th width="60%">{tr["Service Name"]}</th>
						<th width="30%">{tr["Sequence No."]}</th>
						<th width="10%" className="text-c">{tr['Action']}</th>
					  </tr>
					</thead>
					{this.props.spcmReducer.spcmFetchingAttribute  ? 
					<tbody><tr><td colSpan="3">
						<ListLoader />
					</td></tr></tbody> : 
					this.props.spcmReducer.serviceSequencingList.length ==0 ?<tbody><tr><td colSpan="3" align="center" className="text-center">
					{this.props.tr["No Data Found"]}
				</td></tr></tbody>:
					<tbody>
						{serviceSequencingList.map(function(value,index){
							return (<tr key={`sequencing_list_${index}`}>
						<td>{value.OFFERING_NAME}</td>
						<td>{value.FEATURED_SEQUENCE}</td>
						<td><div className="text-c"><i title={tr["Edit"]} onClick={()=>{this.props.setEditServiceDetails(value);this.props.componentFormFun(true,'Edit',value.OFFERING_ID);}} className="fa fa-pencil-square-o cursorPoint"></i>
						<i title={tr["Delete"]}  className="margin-l-10 fa fa-trash-o cursorPoint" onClick={()=>{this.onDeleteClick(value.ID)}}></i></div></td>
					  </tr>)
						},this)}
					</tbody>
					}
				
					</Table>
				</div>
				</div>
				}
			</div>
		);
	}
}

const mapStateToProps = ({spcmReducer}) => {
	return {
		spcmReducer
	}
};

export default connect(mapStateToProps,{ setEditServiceDetails,getServiceSequenceList })(ListView);
