
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import { GLOBAL } from "_Globals";
//below URL used for testing purpose
//const breakFixUrl = 'https://xsm-f.mybluemix.net/api/SX_FAVOURITEOFFERINGs/';

//let breakFixUrl=`https://xsm-f.mybluemix.net/groupListSelfService?companyId=2932&group=ResolvedER`

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

export function loadProfileDateFormatSuccess(dateFormatDetails){
	return {
		type: 'LOAD_DATEFORMATDETAILS_SUCCESS',
		dateFormatDetails
	};
}

export function loadProfileDateFormatFailure(errorResponse){
	return {
		type: 'LOAD_DATEFORMATDETAILS_FAILURE',
		errorResponse
	};
}

export function loadProfileDateFormat(){
	return(dispatch) =>{
				//alert("edit"+breakFixUrl+breakFixId);
				api.get(GLOBAL.profileDateFormatUrl)
					.then((dateFormatDetails)=>{
						if(!dateFormatDetails){
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							throw Error(dateFormatDetails.statusText);
						}
						return dateFormatDetails;
					})
					.then((dateFormatDetails) =>{
						dispatch(loadProfileDateFormatSuccess(dateFormatDetails))
					})
					.catch((err) => {
						console.log(err);
					});
					//console.log("not calling loaddateFormatDetails");

	};
}
