
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Button,Dropdown,DropdownButton} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { TbFileExport } from "react-icons/tb";
import { BsFileEarmarkPdf,BsFileEarmarkWord} from "react-icons/bs";
import { GoDesktopDownload } from "react-icons/go";
import { TbDownload } from "react-icons/tb";

const ExportTo = (props) => {
    const { exportDocument = () => {}, title = 'Export to' } = props || {};
    const dispatch = useDispatch();
    const tr = useSelector((state) => state.spcmReducer.tr);

    return (
        <>
            <div className="exportBtnDv" title={title}>
                <DropdownButton bsPrefix=" " id="exportBreakfix" title={<span><TbDownload /></span>}>
                    <Dropdown.Item eventKey="2" onClick={() => exportDocument('pdf') }><BsFileEarmarkPdf /> PDF</Dropdown.Item>
                    {(props.moduleName === 'breakfix' || props.moduleName === 'change' || props.moduleName === 'investigation') && <Dropdown.Item eventKey="3" onClick={() => exportDocument('word') }><BsFileEarmarkWord /> {tr['Word']}</Dropdown.Item>}
                    {props.moduleName === 'scorecard' && <Dropdown.Item eventKey="3" onClick={() => exportDocument('excel') }><BsFileEarmarkWord /> Excel</Dropdown.Item>}
                </DropdownButton>
            </div>
        </>
    )
}

export default ExportTo;
