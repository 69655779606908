
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Nav } from "react-bootstrap";
import {
  VerticalCatalogApi
} from "../../../../reducers/VerticalCatalog/VerticalCatalogReducer";
import useScrollToTop from "../../../../helpers/useScrollToTop";
import { useSelector } from "react-redux";

const BusinessFnMenu = ({
  labelName,
  labelKey,
  labelId,
  setActiveTab,
  setActiveTabEventKey,
  setActiveTabId,
  activeMenuSubMenuItems,
  udpateActiveMenuSubMenuItems,
  url,
  labelType,
  start,
  end,
  isGeneralSection,
  parentLabelType,
  parentLabelId,
  parentLabel
}) => {
  const { setScrollToTop } = useScrollToTop();

  const {
    isError: isErrorOfferings,
    data: offeringsData,
  } = VerticalCatalogApi.endpoints.getOfferingsList.useQueryState({
    labelId,
    labelName,
    label_type: labelType,
    start,
    end,
    url,
    isGeneralSection,
    parentLabelType,
    parentLabelId,
    parentLabel
  });

  const activeMenu = activeMenuSubMenuItems.businessFn.filter(item => item.id === labelId)[0]?.active || false;
  const isActive = activeMenuSubMenuItems.businessFn.filter(item => item.id === labelId)[0]?.clicked || false;

  return (
    <>
      <Nav.Item as="li" key={labelKey}>
        <Nav.Link
          eventKey={labelKey}
          active={isActive}
          className={
            activeMenu
              ? "subMenuIcn subMenuIcnRotate"
              : "subMenuIcn"
          }
          onClick={() => {
            udpateActiveMenuSubMenuItems("businessFn", labelId, labelName, labelKey);
            setActiveTab(labelName);
            setActiveTabEventKey(labelKey);
            setActiveTabId(labelId);
            setScrollToTop(true);
          }}
        >
          <span>{labelName}</span>
        </Nav.Link>
        {activeMenu && (
          <div className="categrySubMenu">
            <Nav as="ul" className="flex-column">
              {isErrorOfferings ? (
                <p>Something Went Wrong</p>
              ) : (
                offeringsData?.vertical_labels && offeringsData?.vertical_labels.map((category, index) => (
                  <>
                    {category.category_id !== null &&
                      category.category_name !== null && (
                        <CategoryMenu
                          labelKey={`2.${index}.${labelKey}`}
                          labelName={category.category_name}
                          labelId={category.category_id}
                          setActiveTabEventKey={setActiveTabEventKey}
                          setActiveTab={setActiveTab}
                          setActiveTabId={setActiveTabId}
                          activeMenuSubMenuItems={activeMenuSubMenuItems}
                          udpateActiveMenuSubMenuItems={udpateActiveMenuSubMenuItems}
                          url={url}
                          labelType={labelType}
                          isGeneralSection={isGeneralSection}
                          start={start}
                          end={end}
                          parentLabel={parentLabel}
                          parentLabelId={parentLabelId}
                          parentLabelType={parentLabelType}
                        />
                      )}
                  </>
                ))
              )}
            </Nav>
          </div>
        )}
      </Nav.Item>
    </>
  );
};

const CategoryMenu = ({
  labelKey,
  labelName,
  labelId,
  setActiveTab,
  setActiveTabEventKey,
  setActiveTabId,
  activeMenuSubMenuItems,
  udpateActiveMenuSubMenuItems,
  url,
  labelType,
  start,
  end,
  isGeneralSection,
  parentLabelType,
  parentLabelId,
  parentLabel
}) => {
  const { setScrollToTop } = useScrollToTop();

  const {
    isError: isErrorOfferings,
    data: offeringsData,
  } = VerticalCatalogApi.endpoints.getOfferingsList.useQueryState({
    labelId,
    labelName,
    label_type: labelType,
    start,
    end,
    url,
    isGeneralSection,
    parentLabelType,
    parentLabelId,
    parentLabel
  });

  const activeMenu = activeMenuSubMenuItems.categories.filter(item => item.id === labelId)[0]?.active || false;
  const isActive = activeMenuSubMenuItems.categories.filter(item => item.id === labelId)[0]?.clicked || false;

  return (
    <>
      <Nav.Item as="li" key={labelKey}>
        <Nav.Link
          active={isActive}
          eventKey={labelKey}
          className={
            activeMenu
              ? "subMenuIcn subMenuIcnRotate"
              : "subMenuIcn"
          }
          title={labelName}
          onClick={() => {
            udpateActiveMenuSubMenuItems("categories", labelId, labelName, labelKey);
            setActiveTab(labelName);
            setActiveTabEventKey(labelKey);
            setActiveTabId(labelId);
            setScrollToTop(true);
          }}
        >
          <span>{labelName}</span>
        </Nav.Link>

        {activeMenu && (
          <div className="categryInnerSubMenu">
            <Nav as="ul" className="flex-column">
              {isErrorOfferings ? (
                <p>Something Went Wrong</p>
              ) : (
                offeringsData?.vertical_labels && offeringsData?.vertical_labels.map((subCat, index) => (
                  <>
                    {subCat.subcategory_id !== null &&
                      subCat.sub_category_name !== null && (
                        <SubCategoryMenu
                          labelKey={`3.${index}.${labelKey}`}
                          labelId={subCat.subcategory_id}
                          labelName={subCat.sub_category_name}
                          setActiveTabEventKey={setActiveTabEventKey}
                          setActiveTab={setActiveTab}
                          setActiveTabId={setActiveTabId}
                          udpateActiveMenuSubMenuItems={
                            udpateActiveMenuSubMenuItems
                          }
                        />
                      )}
                  </>
                ))
              )}
            </Nav>
          </div>
        )}
      </Nav.Item>
    </>
  );
};

const SubCategoryMenu = ({
  labelKey,
  labelName,
  labelId,
  setActiveTab,
  setActiveTabEventKey,
  setActiveTabId,
  udpateActiveMenuSubMenuItems,
  }) => {
  const { setScrollToTop } = useScrollToTop();

  return (
    <>
      <Nav.Item as="li" key={labelKey}>
        <Nav.Link
          eventKey={labelKey}
          onClick={() => {
            setActiveTab(labelName);
            setActiveTabEventKey(labelKey);
            setActiveTabId(labelId);
            setScrollToTop(true);
            udpateActiveMenuSubMenuItems("subCategories", labelId, labelName, labelKey);
          }}
          title={labelName}
        >
          <span>{labelName}</span>
        </Nav.Link>
      </Nav.Item>
    </>
  );
};

const DynamicNestedMenu = ({
  businessFnLabels,
  setActiveTab,
  setActiveTabEventKey,
  setActiveTabId,
  businessFnApiError,
  activeMenuSubMenuItems,
  udpateActiveMenuSubMenuItems,
  url,
  labelType,
  start,
  end,
  isGeneralSection,
  parentLabelType,
  parentLabelId,
  parentLabel
}) => {
  const tr = useSelector((state) => state.spcmReducer.tr);

  return (
    <Nav as="ul" className="flex-column">
      {businessFnApiError ? (
        <p>Something Went Wrong</p>
      ) : (
        businessFnLabels &&
        businessFnLabels.vertical_labels &&
        businessFnLabels.vertical_labels.map((label, index) => (
          <>
            {label.business_function_id !== null &&
              label.business_function_name !== null && (
                <BusinessFnMenu
                  labelKey={`1.${index}`}
                  // labelName={label.business_function_name}
                  labelName={businessFnLabels.vertical_labels.length === 1 && label.business_function_id === 0 ? tr["Categories"] : label.business_function_name}
                  labelId={label.business_function_id}
                  setActiveTabEventKey={setActiveTabEventKey}
                  setActiveTab={setActiveTab}
                  setActiveTabId={setActiveTabId}
                  activeMenuSubMenuItems={activeMenuSubMenuItems}
                  udpateActiveMenuSubMenuItems={udpateActiveMenuSubMenuItems}
                  url={url}
                  labelType={labelType}
                  isGeneralSection={isGeneralSection}
                  start={start}
                  end={end}
                  parentLabel={parentLabel}
                  parentLabelId={parentLabelId}
                  parentLabelType={parentLabelType}
                />
              )}
          </>
        ))
      )}
    </Nav>
  );
};

export default DynamicNestedMenu;
