
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import sanitizeHtml from 'sanitize-html';

import {getArticleById } from '../../../../actions/knowledge/knowledgeAction';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
let username = cookies.get('gph');
if (username) username = username.replace('s:', '');
if (username) username = username.substring(0, username.lastIndexOf('.'));
username = username.split("~");
username = username[32];
class ArticleDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state ={
			
		};
	};

    componentWillMount() {
        this.props.getArticleById(this.props.articleDetails.knowledgeId,username);
    }

   render() {

   	// console.log('attached articles *********',{state:  this.state, props: this.props});

   	// const { searchArticle, showSearchInput, searchStringErrorLabel } = this.state;
	   const {
        articleDetails: {
            knowledgeId,
            knowledgeArticle,
            type,
            knowledgeDescription,
            articleUrl = ""
        },
        translator = {}
       } = this.props;

        let iconClass = (type === 'SPCM') ? 'KA-icon interLnk1' : (
            (type === 'Knowledge' || type === 'conversation') ? 'KA-icon exterLnk1' : 'KA-icon communityWh'
        );
        this.props.getArticleById(knowledgeId,username);
      return (
      	<div className="knowledge-description-section">
             <Row>
                <Col md={12}>
                    <div className="rPageHeading"><div className="offNam">
                        <span className={iconClass} title={type == 'conversation' ? 'Knowledge' : translator[type]}></span>
                        {this.props.renderLargeTextEllipse(knowledgeArticle, 25)}
                    </div></div>
                </Col>
            </Row>
            <div className='rBoxGap'>
            <Row>
                {/* <Col md={12}>
                    <h2 className="KA-Desc-title">
                        <span className={iconClass} title={type == 'conversation' ? 'Knowledge' : translator[type]}></span>
                        {this.props.renderLargeTextEllipse(knowledgeArticle, 25)}
                    </h2>
                </Col> */}
                <Col md={12}>
                    {(type === 'SPCM') ? (
                        <div className="KA-description" >knowledgeDescription</div>
                    ) : (
                        <div className="KA-description" dangerouslySetInnerHTML={{ __html: sanitizeHtml(this.props.articleDetailsById)}} />
                    )}
                    
                </Col>
                <Col md={12}>
                    <Button bsSize="small" type="button" className="myBtn rgSiLigBtn smallBtn"
                    onClick={() => {
                        this.props.setStateV1({
                            articleDetails: null
                        });
                    }}
                    >{translator["Close"]}</Button>
                    {
                        type === 'SPCM' ? 
                            <Button bsSize="small" type="button" className="myBtn KA-download-btn rgSidrkBtn smallBtn"
                            onClick={() => {
                                this.props.downloadFile(type, knowledgeArticle, articleUrl, knowledgeId)
                            }}
                            >
                                <i className="fa fa-download margin-r-5"></i>
                                {translator["Download"]}
                            </Button>
                        :
                            <Button bsSize="small" type="button" className="myBtn KA-download-btn rgSidrkBtn smallBtn"
                            onClick={() => {
                                this.props.downloadFile(type, articleUrl)
                            }}
                            >
                                <i className="fa fa-external-link margin-r-5"></i>
                                {translator["Redirect"]}
                            </Button>
                    }
                </Col>
            </Row>
            </div>
		</div>
      );
   }
}

const mapStateToProps = ({articleDetails}) => {
	return {
		articleDetailsById: articleDetails
	};
};


export default connect(mapStateToProps,{getArticleById})(ArticleDetails);
