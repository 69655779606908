
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export default function (state = initialState.dateFormatDetails, action){

	switch (action.type){
		case 'LOAD_DATEFORMATDETAILS_SUCCESS':
				//alert(action.breakFix.data.);
				return action.dateFormatDetails.data;
		case 'LOAD_DATEFORMATDETAILS_FAILURE':
				return action.failureMessage;
		default:
				return state;
		}

}
