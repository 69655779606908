
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { useSelector } from "react-redux";
import TableComponent from "../../../common/ReactTable/TableComponent";
import ListLoader from "../../../common/loaders/ListLoader";
import Cookies from "universal-cookie";
const cookies = new Cookies();
let app_url = cookies.get("gph");
if (app_url) app_url = app_url.replace("s:", "");
if (app_url) app_url = app_url.substring(0, app_url.lastIndexOf("."));
app_url = app_url.split("~");
app_url = app_url[18];

const ListSec = (props) => {

  const contractListData = useSelector((state) => state.contractList);
  const showLoader = useSelector((state) => state.showLoader.loading);
  const onRowSelect = (row) => {
    props.contractDataHandler(row);
    props.showRightSideFun(true);
  };

  const CellSupportLink = ({ row }) => {
    return (
      <div className="text-c">
        <a
          href={app_url + "/home?editContract=" + row.CONTRACT_NUM}
          target="_blank"
          className="hyperLinkCntrct"
          title={props.translator["Open in new tab"]}
        >
          {row.CONTRACT_NUM}
        </a>
      </div>
    );
  };

  const tableColumns = [
    {
      name: props.translator["Contract Number"],
      selector: (row) => row.CONTRACT_NUM,
      sortable: true,
      cell: (row) => <CellSupportLink row={row} />,
    },
    {
      name: props.translator["Name"],
      selector: (row) =>
        row.CONTRACT_NAME.length > 30
          ? row.CONTRACT_NAME.slice(0, 30) + "..."
          : row.CONTRACT_NAME,
      sortable: true,
      cell: (row) => (
        <div title={row.CONTRACT_NAME} data-tag="allowRowEvents">
          {row.CONTRACT_NAME.length > 30
            ? row.CONTRACT_NAME.slice(0, 30) + "..."
            : row.CONTRACT_NAME}
        </div>
      ),
    },
    {
      name: props.translator["Company"],
      cell: (row) => {
        let companyString = row.COMPANY || "";

        return (
          <span
            title={companyString}
            style={{
              display: "inline-block",
              maxWidth: "200px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            data-tag="allowRowEvents"
          >
            {companyString}
          </span>
        );
      },
      sortable: true,
    },
    {
      name: props.translator["Type"],
      selector: (row) => row.CONTRACT_TYPE || "",
      maxWidth: "100px",
      sortable: true,
    },
    {
      name: props.translator["Business Function"],
      selector: (row) => row.BUSINESS_FUNCTION,
      sortable: true,
    },
    {
      name: props.translator["Vendor"],
      cell: (row) => {
        let vendorString = "";

        if (row.VENDOR) {
            if (typeof row.VENDOR === "string") {
                try {
                    const parsedVendors = JSON.parse(row.VENDOR);
                    if (Array.isArray(parsedVendors)) {
                        vendorString = parsedVendors.map((vendor) => vendor.label).join(", ");
                    }
                } catch (err) {
                    vendorString = "";
                }
            } else if (Array.isArray(row.VENDOR)) {         
                vendorString = row.VENDOR.map((vendor) => vendor.label).join(", ");
            }
        }
    
        return (
            <span title={vendorString} data-tag="allowRowEvents">
                {vendorString}
            </span>
        );
    },
      style: {
        display: "inline-block",
      },
      sortable: true,
    },
    {
      name: props.translator["Status"],
      selector: (row) => row.STATUS_NAME,
      maxWidth: "100px",
      sortable: true,
    },
    {
      name: props.translator["Start Date"],
      selector: (row) => row.START_DATE,
      sortable: true,
    },
    {
      name: props.translator["End Date"],
      selector: (row) => row.END_DATE,
      sortable: true,
    },
  ];

  const filterColumns = [
    "CONTRACT_NUM",
    "CONTRACT_NAME",
    "COMPANY",
    "CONTRACT_TYPE",
    "BUSINESS_FUNCTION",
    "VENDOR",
    "STATUS_NAME",
    "START_DATE",
    "END_DATE",
  ];
  // console.log("contractListData", contractListData);

  const renderContractTableData = () => {
    return (
      <div className="dtaTableDv integrateHubTable cnTableCol">
        <div
          className={
            props.isRightSideVisible
              ? "leftTrue srchPosTop" 
              : "rightTrue srchPosTop"
          }
        >
          <TableComponent
            data={contractListData}
            columns={tableColumns}
            headerColumns={filterColumns}
            onRowSelect={onRowSelect}
            rightDisplay={props.isRightSideVisible}
            uniqueKey={"CONTRACT_NUM"}
          />
        </div>
      </div>
    );
  };

    return (
      <div className="myOrderDiv">
        {showLoader ? <ListLoader /> : renderContractTableData()}
      </div>
    );
};

export default ListSec;
