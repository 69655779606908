
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import { Field, change, formValueSelector } from "redux-form";
import React, { useEffect, useState } from "react";
import { Row, Col, Form, Image } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { reduxForm } from "redux-form";
import { ColorInputField, _dropDown } from "../../common/formFields";
import Swal from "sweetalert2";

const validate = (values) => {
  let error = {};
  if (!values.pageBG) {
    error.pageBG = "required";
  }
  if (!values.pageTxtColor) {
    error.pageTxtColor = "required";
  }
  if (!values.primaryColor) {
    error.primaryColor = "required";
  }
  if (!values.secondaryColor) {
    error.secondaryColor = "required";
  }
  if (!values.anchorColor) {
    error.anchorColor = "required";
  }
  if (!values.aiButtonBG) {
    error.aiButtonBG = "required";
  }
  if (!values.headerBG) {
    error.headerBG = "required";
  }
  if (!values.headerTxtColor) {
    error.headerTxtColor = "required";
  }
  if (!values.tableHeader) {
    error.tableHeader = "required";
  }
  if (!values.tableColumn) {
    error.tableColumn = "required";
  }
  return error;
};

let FormComponent = (props) => {
  const dispatch = useDispatch();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const appliedThemeData = useSelector((state) => state.appliedThemeData);
  const defaultColors = props?.defaultColors;

  const fontFamilyOptions = [
    {value: 'Alegreya Sans', label: 'Alegreya Sans' },
    {value: 'Arial', label: 'Arial' },
    {value: 'Cabin', label: 'Cabin' },
    {value: 'Comic Sans MS', label: 'Comic Sans MS' },
    {value: 'DM Sans', label: 'DM Sans' },
    {value: 'Exo', label: 'Exo' },
    {value: 'Fira Sans', label: 'Fira Sans' },
    {value: 'Helvetica', label: 'Helvetica' },
    {value: 'Inter', label: 'Inter' },
    {value: 'Lato', label: 'Lato' },
    {value: 'Montserrat', label: 'Montserrat' },
    {value: 'Nunito', label: 'Nunito' },
    {value: 'Open Sans', label: 'Open Sans' },
    {value: 'Oxygen', label: 'Oxygen' },
    {value: 'Poppins', label: 'Poppins' },
    {value: 'Prompt', label: 'Prompt' },
    {value: 'PT Sans', label: 'PT Sans' },
    {value: 'Raleway', label: 'Raleway' },
    {value: 'Roboto', label: 'Roboto' },
    {value: 'Rubik', label: 'Rubik' },
    {value: 'Space Grotesk', label: 'Space Grotesk' },
    {value: 'Tahoma', label: 'Tahoma' },
    {value: 'Trebuchet MS', label: 'Trebuchet MS' },
    {value: 'Ubuntu', label: 'Ubuntu' },
    {value: 'Verdana', label: 'Verdana' },
  ];

  useEffect(() => {
    if (
      appliedThemeData.data &&
      Object.keys(appliedThemeData.data).length > 0
    ) {
      props.setThemeName(appliedThemeData?.data?.themeName);
      props.setDefaultColor(appliedThemeData?.data);
      
      Object.keys(appliedThemeData?.data).forEach((value) => {
        if (value !== "themeName" || value !== "FontFamily") {
          props.submitValues(appliedThemeData.data[value], value);
          dispatch(
            change("formComponent", value, appliedThemeData.data[value])
          );
        }
      });
      if(appliedThemeData?.data?.FontFamily){
        dispatch(change("formComponent", "FontFamily", appliedThemeData?.data?.FontFamily));  
      }
    }
  }, [appliedThemeData]);

  const onColorFieldChange = (color, name) => {
    props.onColorFieldChange(color, name);
  };

  const onFontFieldChange = (e) => {
    props.onColorFieldChange(e.target.value, e.target.name);
  };

  const selectTheme = (name) => {
    let selectedTheme = props.preDefinedThemes.filter(
      (res) => res.field1Key == name
    );

    if (selectedTheme.length > 0) {
      let data = JSON.parse(selectedTheme[0].field1Value);
      props.setThemeName(data?.themeName);
      props.setThemeClicked(true);
      Object.keys(data).forEach((value) => {
        if (value !== "themeName") {
          props.submitValues(data[value], value);
          dispatch(change("formComponent", value, data[value]));
        }
      });
    }
  };

  const controlPopup = (imgPath, titleName) => {
    if (titleName == "custom") {
      props.setThemeName("custom");
      Swal.fire({
        title: "Please choose the custom colors!",
      });
    } else {
      selectTheme(titleName);
    }
  };
  let panelColSize = props.panelResize;
  const renderThemes = () => {
    return (
      props?.preDefinedThemes && (
        <Row className="margin-b-20 tabFormDv">
          <Col xs={12}>
            <Form.Group className="form-group">
              <select
                className="form-control"
                onChange={(e) => {
                  controlPopup(e.target.value, e.target.value);
                }}
              >
                <option selected={props.themeName == "custom"} value={"custom"}>
                  Custom
                </option>
                {props.preDefinedThemes.map((res) => {
                  if (
                    res.field1Value &&
                    res.field1Value != "Specify your own" &&
                    res.field1Value != "Themes"
                  ) {
                    let value = JSON.parse(res.field1Value);
                    if (Object.keys(value).length > 0) {
                      return (
                        <option
                          selected={
                            props.themeName.toLowerCase() ==
                            res.field1Key.toLowerCase()
                          }
                          value={res.field1Key}
                        >
                          {res.field1Key}
                        </option>
                      );
                    }
                  }
                })}
              </select>
            </Form.Group>
          </Col>
        </Row>
      )
    );
  };

  return (
    <div>
      <Row>
        <Col md={4} sm={6} xs={12}>
          <div className="f-size-16 bgGray padding-5 margin-b-10 ovrFlowContent">
            {tr["Predefined themes"]}
          </div>
          {renderThemes()}
        </Col>
        <Col md={4} sm={6} xs={12}>
          <div className="f-size-16 bgGray padding-5 margin-b-10 ovrFlowContent">
            {tr["Font Family"]}
          </div>
          <Row className="margin-b-20">
            <Col xs={12}>
              <Form.Group className="form-group">
                <Field
                  component={_dropDown}
                  name="FontFamily"
                  onChange={onFontFieldChange}
                  options={fontFamilyOptions || []}
                  className="form-control"
                  disabled={props.themeName.toLowerCase() !== "custom"}
                  defaultValue={props.FontFamilyParam || ""}
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
      
      <div className="f-size-16 bgGray padding-5 margin-b-10"><div style={{position:"relative",top:"3px"}} className="float-r f-size-11 margin-r-5">BG = Background</div>Page</div>
      <Row className="margin-b-20 tabFormDv">
      <Col lg={panelColSize < 46 ? 4 : 2 } md={panelColSize < 46 ? 6 : 4 } sm={4} xs={12}>
          <Form.Group className="form-group">
            <Form.Label bsClass="">{tr["BG Color"]}</Form.Label>
            {/* {controlInput(color1,setColor1)} */}
            <Field
              name="pageBG"
              component={ColorInputField}
              defaultValue={defaultColors?.pageBG}
              onChange={(color) => onColorFieldChange(color, "pageBG")}
              onClick={() => props.setThemeName("custom")}
              disabled={props.themeName.toLowerCase() !== "custom"}
            />
          </Form.Group>
        </Col>
        <Col lg={panelColSize < 46 ? 4 : 2 } md={panelColSize < 46 ? 6 : 4 } sm={4} xs={12}>
          <Form.Group className="form-group">
            <Form.Label bsClass="">{tr["Page Text Color"]}</Form.Label>
            {/* {controlInput(color2,setColor2)} */}
            <Field
              name="pageTxtColor"
              component={ColorInputField}
              defaultValue={defaultColors?.pageTxtColor}
              onChange={(color) => onColorFieldChange(color, "pageTxtColor")}
              onClick={() => props.setThemeName("custom")}
              disabled={props.themeName.toLowerCase() !== "custom"}
            />
          </Form.Group>
        </Col>
        <Col lg={panelColSize < 46 ? 4 : 2 } md={panelColSize < 46 ? 6 : 4 } sm={4} xs={12}>
          <Form.Group className="form-group">
            <Form.Label bsClass="">{tr["Primary Color"]}</Form.Label>
            {/* {controlInput(color3,setColor3)} */}
            <Field
              name="primaryColor"
              component={ColorInputField}
              defaultValue={defaultColors?.primaryColor}
              onChange={(color) => onColorFieldChange(color, "primaryColor")}
              onClick={() => props.setThemeName("custom")}
              disabled={props.themeName.toLowerCase() !== "custom"}
            />
          </Form.Group>
        </Col>
        <Col lg={panelColSize < 46 ? 4 : 2 } md={panelColSize < 46 ? 6 : 4 } sm={4} xs={12}>
          <Form.Group className="form-group">
            <Form.Label bsClass="">{tr["Secondary Color"]}</Form.Label>
            {/* {controlInput(color4,setColor4)} */}
            <Field
              name="secondaryColor"
              component={ColorInputField}
              defaultValue={defaultColors?.secondaryColor}
              onChange={(color) => onColorFieldChange(color, "secondaryColor")}
              onClick={() => props.setThemeName("custom")}
              disabled={props.themeName.toLowerCase() !== "custom"}
            />
          </Form.Group>
        </Col>
        <Col lg={panelColSize < 46 ? 4 : 2 } md={panelColSize < 46 ? 6 : 4 } sm={4} xs={12}>
          <Form.Group className="form-group">
            <Form.Label bsClass="">{tr["Anchor Color"]}</Form.Label>
            {/* {controlInput(color5,setColor5)} */}
            <Field
              name="anchorColor"
              component={ColorInputField}
              defaultValue={defaultColors?.anchorColor}
              onChange={(color) => onColorFieldChange(color, "anchorColor")}
              onClick={() => props.setThemeName("custom")}
              disabled={props.themeName.toLowerCase() !== "custom"}
            />
          </Form.Group>
        </Col>
        <Col lg={panelColSize < 46 ? 4 : 2 } md={panelColSize < 46 ? 6 : 4 } sm={4} xs={12}>
          <Form.Group className="form-group">
            <Form.Label bsClass="">{"AI Icon BG"}</Form.Label>
            <Field
              name="aiButtonBG"
              component={ColorInputField}
              defaultValue={defaultColors?.aiButtonBG}
              onChange={(color) => onColorFieldChange(color, "aiButtonBG")}
              onClick={() => props.setThemeName("custom")}
              disabled={props.themeName.toLowerCase() !== "custom"}
            />
          </Form.Group>
        </Col>
      </Row>
      <div className="f-size-16 bgGray padding-5 margin-b-10">
        {tr["Header/Footer Section"]}
      </div>
      <Row className="margin-b-20 tabFormDv">
      <Col lg={panelColSize < 46 ? 4 : 2 } md={panelColSize < 46 ? 6 : 4 } sm={4} xs={12}>
          <Form.Group className="form-group">
            <Form.Label bsClass="">{tr["BG Color"]}</Form.Label>
            {/* {controlInput(color6,setColor6)} */}
            <Field
              name="headerBG"
              component={ColorInputField}
              defaultValue={defaultColors?.headerBG}
              onChange={(color) => onColorFieldChange(color, "headerBG")}
              onClick={() => props.setThemeName("custom")}
              disabled={props.themeName.toLowerCase() !== "custom"}
            />
          </Form.Group>
        </Col>
        <Col lg={panelColSize < 46 ? 4 : 2 } md={panelColSize < 46 ? 6 : 4 } sm={4} xs={12}>
          <Form.Group className="form-group">
            <Form.Label bsClass="">{tr["Anchor Color"]}</Form.Label>
            {/* {controlInput(color7,setColor7)} */}
            <Field
              name="headerTxtColor"
              component={ColorInputField}
              defaultValue={defaultColors?.headerTxtColor}
              onChange={(color) => onColorFieldChange(color, "headerTxtColor")}
              onClick={() => props.setThemeName("custom")}
              disabled={props.themeName.toLowerCase() !== "custom"}
            />
          </Form.Group>
        </Col>
      </Row>
      <div className="f-size-16 bgGray padding-5 margin-b-10">
        {tr["Table Section"]}
      </div>
      <Row className="margin-b-20 tabFormDv">
      <Col lg={panelColSize < 46 ? 4 : 2 } md={panelColSize < 46 ? 6 : 4 } sm={4} xs={12}>
          <Form.Group className="form-group">
            <Form.Label bsClass="">{tr["Header Color"]}</Form.Label>
            {/* {controlInput(color8,setColor8)} */}
            <Field
              name="tableHeader"
              component={ColorInputField}
              defaultValue={defaultColors?.tableHeader}
              onChange={(color) => onColorFieldChange(color, "tableHeader")}
              onClick={() => props.setThemeName("custom")}
              disabled={props.themeName.toLowerCase() !== "custom"}
            />
          </Form.Group>
        </Col>
        <Col lg={panelColSize < 46 ? 4 : 2 } md={panelColSize < 46 ? 6 : 4 } sm={4} xs={12}>
          <Form.Group className="form-group">
            <Form.Label bsClass="">{tr["Column Color"]}</Form.Label>
            {/* {controlInput(color9,setColor9)} */}
            <Field
              name="tableColumn"
              component={ColorInputField}
              defaultValue={defaultColors?.tableColumn}
              onChange={(color) => onColorFieldChange(color, "tableColumn")}
              onClick={() => props.setThemeName("custom")}
              disabled={props.themeName.toLowerCase() !== "custom"}
            />
          </Form.Group>
        </Col>
      </Row>
      
    </div>
  );
};

let selector = formValueSelector('formComponent');
FormComponent = connect((state) => {
  let FontFamilyParam = selector(state, 'FontFamily');
  // let status = selector(state, "status");
  return {
    FontFamilyParam
  };
})(FormComponent);

FormComponent = reduxForm({
  form: "formComponent",
  validate,
  enableReinitialize: true,
})(FormComponent);

export default FormComponent;
