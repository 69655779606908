
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState, useEffect } from "react";
import { ButtonToolbar, Button, ListGroup, Table } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { connect } from "react-redux";
import { validateTaskField } from "../../../../utils/common";
import _ from "lodash";
import moment from "moment";
import configureStore from "../../../../store/configureStore";
const store = configureStore();
import Cookies from "universal-cookie";
const Joi = require("joi");
import { Scrollbars } from "react-custom-scrollbars";
import datetimeConvertor from "../../../../ISO8601converter";
const cookies = new Cookies();
import Swal from "sweetalert2";
import { fileobjectGenerator, validateFile } from "../../../common/MIMEObjGenerator";
import { IoClose } from "react-icons/io5";
import { PiEyeBold } from "react-icons/pi";
import { ImSpinner6 } from "react-icons/im";
import { loadContarctAttachmentList } from "../../../../actions/ham/contractAction";

let attachmentDetails = cookies.get('gph');
if (attachmentDetails) attachmentDetails = attachmentDetails.replace('s:', '');
if (attachmentDetails) attachmentDetails = attachmentDetails.substring(0, attachmentDetails.lastIndexOf('.'));
attachmentDetails = attachmentDetails.split("~");
const attachmentSize = attachmentDetails[21];
const attachmentType = fileobjectGenerator(attachmentDetails[22].split(', '));

let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
//console.log("homepage::::"+homepagelocation);
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

let timezone = cookies.get("gph");
if (timezone) timezone = timezone.replace("s:", "");
if (timezone) timezone = timezone.substring(0, timezone.lastIndexOf("."));
timezone = timezone.split("~");
timezone = timezone[3];

function ContractAttachment(props) {
  const tr = useSelector((state) => state.spcmReducer.tr);
  const dispatch = useDispatch();
  const [filesPreview, setFilesPreview] = useState([]);
  const [filesToBeSent, setFilesToBeSent] = useState([]);
  const [clickAttachButton, setClickAttachButton] = useState(0);
  const [printcount, setPrintcount] = useState(1);
  const initialValue = useSelector((state) => state.contractEditList);
  const attachmentDetails = useSelector((state) => state.contractPlanList);
  console.log("initialValue====", initialValue);
  // console.log(contractEditList, "contractEditList===")
  useEffect(() => {
    if(initialValue.CONTRACT_ID){
      dispatch(loadContarctAttachmentList(initialValue.CONTRACT_ID));
    }
  }, [initialValue.CONTRACT_ID]);

  const removeFile = () => {
    setFilesToBeSent([]);
    setFilesPreview([]);
  };

  const onDrop = (acceptedFiles, rejectedFiles) => {
    // let filesToBeSent = filesToBeSent;
    console.log(filesToBeSent, "filesToBeSent")
    console.log(acceptedFiles, "acceptedFiles")
    if (acceptedFiles.length > 0) {
      if (filesToBeSent.length < printcount) {
        filesToBeSent.push(acceptedFiles);
        let filesPreview = [];
        for (let i in filesToBeSent) {
          if (filesToBeSent.hasOwnProperty(i)) {
            filesPreview.push(
              <ListGroup.Item bsPrefix=" ">
                <span
                  className="float-r"
                  style={{ cursor: "pointer" }}
                  onClick={removeFile}
                >
                  <IoClose />
                </span>
                {filesToBeSent[i][0].name}
              </ListGroup.Item>
            );
          }
          setFilesPreview([...filesPreview])
        }
        // setFilesPreview();
        // setFilesToBeSent();
      } else {
        Swal.fire({
          text: "File already attached.",
          button: "OK",
        });
      }
    } else {
    }
  };

  const onDropRejected = (rejectedFiles) => {
    console.log(rejectedFiles[0].size, "rejectedFiles[0].size")
    console.log(rejectedFiles, "rejectedFiles")
    console.log("inside on DRopReh====")
    if (
      rejectedFiles &&
      rejectedFiles[0] &&
      rejectedFiles[0].file &&
      rejectedFiles[0].file.size &&
      rejectedFiles[0].file.size > 1048576 * attachmentSize
    ) {
      Swal.fire({
        text: "Please upload File up to " + attachmentSize + " MB size.",
        button: "OK",
      });
    } else {
      Swal.fire({
        text: "File not supported.",
        button: "OK",
      });
    }
  };

  const handleClick = () => {
    if (filesPreview.length > 0) {
      setClickAttachButton(1);
    }
    if (filesToBeSent.length > 0) {
      let formData = new FormData();
      formData.append("file", filesToBeSent[0][0]);
      let jsonObject = new Object();
      jsonObject.contractId = initialValue.CONTRACT_ID;
      jsonObject.contractNum = initialValue.CONTRACT_NUM;
      jsonObject.createdBy = "";
      jsonObject.createdByName = "";
      jsonObject.modifiedBy = "";
      jsonObject.modifiedByName = "";
      jsonObject.status = "Y";
      jsonObject.module = "contract"
      store.dispatch({ type: "LOAD_CONTRACT_SUCCESS", contractList: {} });
      formData.append("attachment", JSON.stringify(jsonObject));

      axios.post("/api/attachments/contarct", formData)
        .then((response) => {
          if (response.status == 200) {
            dispatch(loadContarctAttachmentList(initialValue.CONTRACT_ID));
            Swal.fire({
              text: "File uploaded successfully.",
              button: "OK",
            });
            setFilesToBeSent([]);
            setFilesPreview([]);
            setClickAttachButton(0);
          } 
        })
        .catch((error) => {
          Swal.fire({
            text:
              tr["Please upload File up to "] +
              attachmentSize +
              tr[" MB size."],
            button: "OK",
          });
          console.log("error in uploading file.");
          setClickAttachButton(0);
        });
    } else {
      Swal.fire({
        text: tr["Please upload some files first"],
        button: tr["OK"],
      });
    }
  };

  const deleteFileAction = (attachmentContractId) => {
    Swal.fire({
      text: "Are you sure you want to delete this?",
      dangerMode: true,
      showDenyButton: true,
      confirmButtonText: tr["Yes"],
      denyButtonText: tr["No"],
    }).then((afterhandling) => {
      if (afterhandling.isConfirmed) {
        axios
          .patch("/api/attachments/contract/" + attachmentContractId, {})
          .then(function (response) {
            console.log(response);
            if (response.status === 200) {
              Swal.fire("Deleted!", "Your  file has been deleted!");
              dispatch(loadContarctAttachmentList(response.data.contractId));
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    });
  };

  function renderAttachedItems(attachments) {
    console.log(attachments, "renderAttachedItems");
    if (_.isEmpty(attachments)) {
      return (
        <tr>
          <td colSpan={3} className="text-c">
            {tr["loading attachments..."]}
          </td>
        </tr>
      );
    }
    return attachments.map((attachment) => {
      const userDate = moment(attachment.createdOn * 1000);
      // const userpreDate = moment(userDate).format(this.props.date_format);
      // console.log("userpreDate_00", userpreDate);

      const aa = moment(userDate).format("D");

      const date1111 = moment(userDate);

      if (attachment.status !== "Y") {
        return null;
      } else {

        let attachfilename = attachment.fileName;
        if (attachfilename.indexOf("##") > -1)
          attachfilename = attachfilename.slice(15);

        let actDateTimeFormat = jwtdf + " HH:mm:ss";
        let newDate = new Date(attachment.createdOn).toLocaleString("en-US", {
          timeZone: timezone,
        });
        let myDateObj = moment(newDate).format(jwtdf + " HH:mm:ss");
        let isoDate = datetimeConvertor(myDateObj, jwtdf + " HH:mm:ss");
        myDateObj = moment(isoDate).format(actDateTimeFormat);

        let validated_url = undefined;
        // const isCreatedByUser = this.props.user_id === attachment.createdBy;
        if (attachment != null && attachment.url != "") {
          let namedSchema = Joi.string().regex(/^[^<>}{]+$/);
          let validator_to_add_myQbj = namedSchema.validate(attachment.url);
          if (validator_to_add_myQbj.error == null) {
            validated_url = attachment.url;
          } else {
            console.log("Validation failed : ", validator_to_add_myQbj);
            validated_url = "\\";
          }
        } else validated_url = "\\";
        return (
          <tr bsClass="" key={attachment.attachmentContractId}>
            <td>
              <div>{attachfilename}</div>
              <div style={{ color: "rgb(0 0 0 / 50%)" }}>
                {attachment.createdByName}
              </div>
            </td>
            <td>{myDateObj}</td>
            <td className="text-c">
              <a className="margin-r-10" href={validated_url}>
                <PiEyeBold />
              </a>
                <span className="float-r" style={{ cursor: 'pointer' }}
                onClick={() => {deleteFileAction(attachment.attachmentContractId)}}
                >,<IoClose />
              </span>
            </td>
          </tr>
        );
      }
    });
  }

  return (
    <div>
      <div style={{ "text-align": "left" }}>
        <b>{"Upload your file."}</b> {"(max size"} {attachmentSize}{" "}{"MB)"}
      </div>
      <Dropzone
        onDrop={(files) => onDrop(files)}
        onDropRejected={(files) => onDropRejected(files)}
        maxSize={1048576 * attachmentSize}
        accept={attachmentType}
        validator={(file)=>{ return validateFile(file,tr);}}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <div
              className="text-c cursorPoint margin-t-5 margin-b-5 grayBg"
              style={{
                width: "100%",

                height: "60px",

                "padding-top": "20px",

                border: "1px solid black",

                "border-top-color": "#bdc4c9",

                "border-right-color": "#bdc4c9",

                "border-bottom-color": "#bdc4c9",

                "border-left-color": "#bdc4c9",
              }}
            >
              <input {...getInputProps()} />

              <div>{"Drop files or click here to upload."}</div>
            </div>
          </div>
        )}
      </Dropzone>
      <div className="margin-t-20">
        <div
          style={{ "text-align": "left" }}
          className="padding-b-5 margin-b-0 black f-size-16 fw-400"
        >
          {filesPreview.length > 0 ? "File attached" : "No file attached"}
        </div>

        <ListGroup bsPrefix=" " className="attachment-break">
          {filesPreview}
        </ListGroup>
      </div>
      <ButtonToolbar className="black margin-t-5">
        <Button
          className="rgSidrkBtn smallBtn"
          bsSize="small"
          bsStyle="primary"
          disabled={clickAttachButton == 1}
          onClick={(event) => handleClick()}
        >
          {clickAttachButton == 1 ? (
            <ImSpinner6 className="icn-spinner" />
          ) : null}{" "}
          {"Attach"}
        </Button>
      </ButtonToolbar>

      <div className="margin-t-20">
        <div className="border-b padding-b-5 margin-b-10 black f-size-16 fw-400">
          {"Attached Files"}
        </div>
        <Table
          responsive
          striped
          bordered
          condensed
          hover
          className="f-size-13 wrpAttTab"
          style={{ marginBottom: "0px" }}
        >
          <thead>
            <tr>
              <th width="59%">Name & {"Linked By"}</th>
              <th width="25%">Date & Time</th>
              <th width="26%" className="text-c">
                {"Action"}
              </th>
            </tr>
          </thead>
          <tbody>{renderAttachedItems(attachmentDetails)}</tbody>
        </Table>
      </div>
    </div>
  );
}
export default ContractAttachment;