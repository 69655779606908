
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useSelector } from "react-redux";

import PindCategories from "../../../common/header/pind-categories";
import Breadcrumbs from "../../../common/header/breadcrumbs";
import AddLanguageHeader from "./AddLanguageHeader";
import AddLanguageForm from "./AddLanguageForm";
import { useParams } from "react-router";

const AddLanguageIndex = () => {
  const tr = useSelector((state) => state.spcmReducer.tr);
  const params = useParams();
  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Breadcrumbs
          activePageName="Add"
          parentPageName=""
        />
      </Container>

      <Container fluid>
        <AddLanguageHeader params={params} />
        <Row>
          <Col md={12} xs={12}>
            <AddLanguageForm translator={tr} params={params} />
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default AddLanguageIndex;
