
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import List from "./list";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useDispatch, useSelector } from "react-redux";
import Header from "./header";
import PindCategories from "../../common/header/pind-categories";
import Breadcrumbs from "../../common/header/breadcrumbs";
import { getTOTList } from "../../../actions/spcmActions";
import ShortDescp from "./shortDescription";
import "_Css/common/_table.scss";

const TotIndex = () => {
  const [isRightSideVisible, setRightSideVisible] = useState(false);
  const [rowIndex, setRowIndex] = useState(0);
  const [totListData, setTOTListData] = useState({});
  const [panelResize,setPanelResize] = useState();
  const [searchParams, setSearchParams] = useState({});
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const translator = useSelector((state) => state.spcmReducer.tr);
  const [clearAllFilterToggle, setClearAllFilterToggle] = useState(false);
  const [selectedUsedbyRuleValue, setSelectedUsedbyRuleValue] = useState([{label: 'Yes', value: 'YES'}]);
  const [selectedDatatypeValue, setSelectedDatatypeValue] = useState([]);
  const [tableNameSearchInput, setTableNameSearchInput] = useState("");
  const [tableLabelSearchInput, setTableLabelSearchInput] = useState("");
  const [modulenameSearchInput, setModulenameLabelSearchInput] = useState("");
  const [microserviceSearchInput, setMicroserviceSearchInput] = useState("");
  const [lastModifiedBySearchInput, setLastModifiedBySearchInput] = useState("");
  const [searchKey, setsearchKey] = useState("");
  const [searchValue, setsearchValue] = useState("");
  const [usedByRulesOpt, setUsedbyRulesOpt] = useState(['YES']);
  const [modifiedDateRef, setModifiedDateRef] = useState(null);
  const [modifiedStartDate, setModifiedStartDate] = useState({});
  const [modifiedEndDate, setModifiedEndDate] = useState({});
  const [modifiedDateFilterValues, setModifiedDateFilterValues] = useState([]);
  const [patternList, setPatternList] = useState("");
  const totList = useSelector((state) => state.spcmReducer.totList);
  const [selectedDate, setSelectedDate] = useState([]);
  const [isUsedRuleOpen, setUsedRuleOpen] = useState(false);
  const commonLoader = useSelector((state) => state.commonLoader.isLoading);


  let dispatch = useDispatch();
  let colWidth = "";
  isRightSideVisible ? (colWidth = 8) : (colWidth = 12);

  const showRightSideFun = (val) => {
    setRightSideVisible(val);
  };
  const totListHandler = (data) => {
    setTOTListData(data)
  }
 
  useEffect(() => {
    dispatch(getTOTList());
   },[])

   const setMultiSelectParams = (usedByRulesOpt) => {
    setUsedbyRulesOpt(usedByRulesOpt);
  }

  const setModifiedStartAndEndDate = (startDate, endDate, filterValue)=> {
    setModifiedStartDate(startDate)
    setModifiedEndDate(endDate)
    setModifiedDateFilterValues(filterValue)
  }

  const multiSelectOpenFun = (keyName) => {
		if (keyName === "userRuled") {
      setUsedRuleOpen(true);
    }
    else if (keyName === "dropdownClose") {
      setUsedRuleOpen(false);
    }
  }

   console.log("searchValue",searchValue)
   console.log("searchKey",searchKey)

   let totDataList = totList ? totList.records : { data: [] };
   let meta = !_.isEmpty(totList) ? totList.metaInfo : {}; 
  let pageSize = meta && meta.size ? meta.size : 0;
  let currentPage = meta && meta.currentPage ? meta.currentPage : 0;
  let startLimit = pageSize * currentPage + 1;
  let endLimit = pageSize * (currentPage + 1);
  let recordCount = meta && meta.recordCount ? meta.recordCount : 0;
  let totalPageCount = meta && meta.totalPageCount ? meta.totalPageCount : 0;
  let panelResponsiveState = isUsedRuleOpen && (totDataList == undefined || totDataList.length === 0 || totDataList.length <=6 || commonLoader)
  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15"> 
        <Row className="">
        <Col lg={8} md={8} sm={9} xs={8}>
        <Breadcrumbs activePageName= "Data Dictionary" /></Col>
        <Col lg={4} md={4} sm={3} xs={4}>
                {/* {recordCount != 0 ? (
                  <div className="tabDatacount">
                    {startLimit}-
                    {endLimit > recordCount
                      ? (endLimit = recordCount)
                      : endLimit}{" "}
                    of {recordCount}
                  </div>
                ) : null} */}
                {totalPageCount != 0 ? (
                  <div className="tabDatacount">
                    {startLimit}-
                    {endLimit > totalPageCount
                      ? (endLimit = totalPageCount)
                      : endLimit}{" "}
                    of {totalPageCount}
                  </div>
                ) : null}
              </Col>
        </Row>
      </Container>

      <Container fluid className="position-re">
        <Header 
              clearAllFilterToggle={clearAllFilterToggle}
              setClearAllFilterToggle={setClearAllFilterToggle}
              setMultiSelectParams={setMultiSelectParams}
              setModifiedStartAndEndDate={setModifiedStartAndEndDate}
              setSelectedUsedbyRuleValue={setSelectedUsedbyRuleValue}
              setSelectedDatatypeValue={setSelectedDatatypeValue}
              setTableNameSearchInput={setTableNameSearchInput}
              setTableLabelSearchInput={setTableLabelSearchInput}
              setModulenameLabelSearchInput={setModulenameLabelSearchInput}
              setLastModifiedBySearchInput={setLastModifiedBySearchInput}
              setMicroserviceSearchInput={setMicroserviceSearchInput}
              setPage={setPage}
              setLimit={setSize}
              searchKey={searchKey}
              searchValue={searchValue}
              usedByRulesOpt={usedByRulesOpt}
              showRightSideFun={showRightSideFun}
              setsearchValue={setsearchValue}
              setsearchKey={setsearchKey}
              modifiedDateRef={modifiedDateRef}
              patternList={patternList}
              size={size}
              page={page}



        />
        <PanelGroup direction="horizontal" className={panelResponsiveState ? "overflow-clip" : ""}>
          <Panel id="sidebar" minSize={33} order={1} defaultSize={isRightSideVisible ? 67 : 100} onResize={(psize) => setPanelResize(psize)} className={`${isRightSideVisible ? "isShowLeftPanel catBordDv" : "catBordDv"} ${panelResponsiveState ? "overflow-clip" : ""}`}>
            <List
              isRightSideVisible={isRightSideVisible}
              showRightSideFun={showRightSideFun}
              translator={translator}
              showActiveRow={setRowIndex}
              totListHandler={totListHandler} 
              rowIndex={rowIndex}
              setPage={setPage}
              size={size}
              setLimit={setSize}
              page={page}
              panelResize={panelResize}
              clearAllFilterToggle={clearAllFilterToggle}
              setClearAllFilterToggle={setClearAllFilterToggle}
              selectedUsedbyRuleValue={selectedUsedbyRuleValue}
              setSelectedUsedbyRuleValue={setSelectedUsedbyRuleValue}
              selectedDatatypeValue={selectedDatatypeValue}
              setSelectedDatatypeValue={setSelectedDatatypeValue}
              tableNameSearchInput={tableNameSearchInput}
              setTableNameSearchInput={setTableNameSearchInput}
              tableLabelSearchInput={tableLabelSearchInput}
              setTableLabelSearchInput={setTableLabelSearchInput}
              modulenameSearchInput={modulenameSearchInput}
              setModulenameLabelSearchInput={setModulenameLabelSearchInput}
              microserviceSearchInput={microserviceSearchInput}
              setMicroserviceSearchInput={setMicroserviceSearchInput}
              lastModifiedBySearchInput={lastModifiedBySearchInput}
              setLastModifiedBySearchInput={setLastModifiedBySearchInput}
              searchKey={searchKey}
              searchValue={searchValue}
              setsearchValue={setsearchValue}
              setsearchKey={setsearchKey}
              usedByRulesOpt={usedByRulesOpt}
              setMultiSelectParams={setMultiSelectParams}
              setModifiedDateRef={setModifiedDateRef}
              modifiedDateRef={modifiedDateRef}
              setModifiedStartAndEndDate={setModifiedStartAndEndDate}
              modifiedDateFilterValues={modifiedDateFilterValues}
              modifiedEndDate={modifiedEndDate}
              modifiedStartDate={modifiedStartDate}             
              setSelectedDate={setSelectedDate}
              patternList={patternList}
              setPatternList={setPatternList}
              isUsedRuleOpen={isUsedRuleOpen}
              multiSelectOpenFun={multiSelectOpenFun}
              />
          </Panel>
          {isRightSideVisible ?
            <>
              <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
              <Panel minSize={33} order={2} defaultSize={33}>
              <div className="stickyArea rBoxStyle">
                <ShortDescp
                  showRightSideFun={showRightSideFun}
                  translator={translator}
                  totListData={totListData}

                  />
              </div>
              </Panel>
            </> : ""
          }
        </PanelGroup>
      </Container>
    </main>
  )
}

export default TotIndex;
