
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Form,
} from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { Field, change, reduxForm } from "redux-form";

import { useParams } from "react-router";
import ListLoader from "../../../common/loaders/ListLoader";
import { _inputField } from "../../../common/formFields";
import { getTOTEditFormDetails } from "../../../../actions/spcmActions";

let TOTForm = (props) => {
  const tr = useSelector((state) => state.spcmReducer.tr);
  const params = useParams();
  const dispatch = useDispatch();
  const initialValue = useSelector((state) => state.spcmReducer.totEditList);


  const loader = useSelector((state) => state.commonLoader);

  const validateEditTOTField = (fieldType, event) => {
    switch (fieldType) {
      case "name":
        if (event.target.value == "") {
          props.setLabelNameErr("error");
        } else {
          props.setLabelNameErr("");
        }
        break;
      case "rule":
        if (event.target.value == "") {
          props.setRuleErr("error");
        } else {
          props.setRuleErr("");
        }
        break;
      default:
    }
  };

  useEffect(() => {

    dispatch(getTOTEditFormDetails(params.id));
  }, []);


  return (
    <>
      {loader.isLoading ? (
        <ListLoader />
      ) : (
        <Form>
          <Row> 
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>{tr["Table Name"]}</Form.Label>
                <Field
                  name="tableName"
                  className={"form-control"}
                  component={_inputField}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
              <span className="rStar"></span>
                <Form.Label>{tr["Table Label"]}</Form.Label>
                <Field
                  component={_inputField}
                  name="tableLabel"
                  className={"form-control " + props.labelnameErr}
                  maxLength={50}
                  onBlur={(event) => {
                    validateEditTOTField("name", event);
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>{tr["Module Name"]}</Form.Label>
                <Field
                  name="moduleName"
                  className={"form-control"}
                  component={_inputField}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>{tr["Microservice"]}</Form.Label>
                <Field
                  name="microservice"
                  className={"form-control"}
                  component={_inputField}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>              
              <Form.Group className="form-group">
                <span className="rStar"></span>
                <Form.Label>{tr["Used by rules"]}</Form.Label>
                <Field
                  name="usedByRule"
                  component="select"
                  className={"form-control " + props.ruleErr}
                  onBlur={(event) => {
                    validateEditTOTField("rule", event);
                  }}
                >
                  <option value="">{tr["Select"]}</option>
                  <option value="YES">{tr["Yes"]}</option>
                  <option value="NO">{tr["No"]}</option>
                </Field>
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>{tr["Last Modified By"]}</Form.Label>
                <Field
                  name="lastModifiedBy"
                  className={"form-control"}
                  component={_inputField}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
                <Form.Label>{tr["Last Modified On"]}</Form.Label>
                <Field
                  name="lastModifiedOn"
                  className={"form-control"}
                  component={_inputField}
                  disabled
                />
              </Form.Group>
            </Col>
           
          </Row>
        </Form>
      )}
    </>
  );
};
TOTForm = reduxForm({
  form: "EditTOT",
  enableReinitialize: true,
})(TOTForm);
TOTForm = connect(({ spcmReducer}) => (
    {
  initialValues: {
    tableName: spcmReducer.totEditList?.tableName,
    tableLabel: spcmReducer.totEditList?.tableLabel,
    moduleName: spcmReducer.totEditList?.moduleName,
    microservice: spcmReducer.totEditList?.microservice,
    usedByRule: spcmReducer.totEditList?.usedByRule,
    lastModifiedOn: spcmReducer.totEditList?.lastModifiedOn,
    lastModifiedBy: spcmReducer.totEditList?.lastModifiedBy
  },
}))(TOTForm);

export default TOTForm;

