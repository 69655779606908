
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import TableComponent from "../../../common/ReactTable/TableComponent";
import ListLoader from "../../../common/loaders/ListLoader";

const ListSec = (props) => {
  const [extLinkListData, setExtLinkListData] = useState([]);

  const extLinkListvalues = useSelector(
    (state) => state.ExternalLinkListValues
  );
  const showLoader = useSelector((state) => state.commonLoader.isLoading);

  const onRowSelect = (row) => {
    props.rowHandler(row);
    props.showRightSideFun(true);
  };

  const tableColumns = [
    {
      name: props.translator["Company"],
      selector: (row) => row.company_Name,
      sortable: true,
    },
    {
      name: props.translator["Link Name"],
      selector: (row) => row.link_Name,
      sortable: true,
    },
    {
      name: props.translator["Link URL"],
      selector: (row) => row.link_URL,
      sortable: true,
      format: row => `${row.link_URL.length > 80 ? (row.link_URL.slice(0, 80) + "...") : row.link_URL}`,
    },
    {
      name: props.translator["Sequence"],
      selector: (row) => row.linkSequence,
      sortable: true,
    },
    {
      name: props.translator["Status"],
      selector: (row) => row.link_status,
      sortable: true,
    },
  ];

  useEffect(() => {
    if (extLinkListvalues.length > 0) {
      setExtLinkListData(extLinkListvalues);
    }
    if (extLinkListvalues.length == 0) {
      setExtLinkListData([]);
    }
  }, [extLinkListvalues]);

  const filterColumns = ["company_Name", "link_Name", "link_URL", "linkSequence", "link_status"];

  console.log("Data --- ", extLinkListData);

  const renderExternalLinkTableData = () => {
    console.log('Here... 11111', extLinkListData)
    return (
      <div className="dtaTableDv integrateHubTable">
        <div
          className={
            props.isRightSideVisible
              ? "leftTrue srchPosTop fdnPos"
              : "rightTrue srchPosTop fdnPos"
          }
        >
          <TableComponent
            data={extLinkListData || []}
            columns={tableColumns}
            headerColumns={filterColumns}
            onRowSelect={onRowSelect}
            rightDisplay={props.isRightSideVisible}
            uniqueKey={"link_Id"}
          />
        </div>
      </div>
    );
  };

  if (!extLinkListData) return null;
  if (extLinkListData.length == undefined) {
    return <ListLoader />;
  } else {
    return (
      <div className="myOrderDiv">
        {showLoader ? (
          <ListLoader />
        ) : (
          renderExternalLinkTableData()
        )}
      </div>
    );
  }
};

export default ListSec;