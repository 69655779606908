
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import {useDispatch, useSelector } from "react-redux";

import PindCategories from "../../../../common/header/pind-categories";
import Breadcrumbs from "../../../../common/header/breadcrumbs";
import Header from "./AdvertisementFormHeader";
import FormComponent from "./AdvertisementForm";
import { useLocation } from "react-router";
import { getAdvertisementEditData, resetAdvertisementData } from "../../../../../actions/foundation/general/AdvertisementAction";
import { reset } from "redux-form";
import "_Css/form/_form.scss";

const AdvertisementFormIndex = () => {
  const [formTypeState, setformTypeState] = useState("");
  const [initialValuesSet, setInitialValuesSet] = useState(false);
  const tr = useSelector((state) => state.spcmReducer.tr);
  const formType = useLocation()?.state?.formType;
  const companyId = useLocation()?.state?.companyId;
  const advertisementId = useLocation()?.state?.advertisementId;
  const dispatch = useDispatch();

  useEffect(() => {
    setformTypeState(formType);
    if(formType === 'Edit') {
      dispatch(getAdvertisementEditData(advertisementId, companyId))
    }

    return () => {
      dispatch(reset('advertisementForm'));
      dispatch(resetAdvertisementData());
    }
  }, []);

  console.log('formType --- ', formTypeState)

  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Breadcrumbs activePageName={tr[`${formTypeState} Advertisement`]} parentPageurl='/advertisement' parentPageName={tr["Advertisement"]} />
      </Container>

      <Container fluid>
        <Header 
          name={tr[`${formTypeState} Advertisement`]} 
          formType={formTypeState} 
          setformTypeState={setformTypeState} 
          advertisementId={advertisementId}
          initialValuesSet={initialValuesSet}
          setInitialValuesSet={setInitialValuesSet}
        />
        <Row>
          <Col md={8} xs={12}>
            <FormComponent 
              formType={formTypeState}
              initialValuesSet={initialValuesSet}
              setInitialValuesSet={setInitialValuesSet}
            />
          </Col>
        </Row>
      </Container>
    </main>
  )
}

export default AdvertisementFormIndex;
