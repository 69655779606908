
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { ButtonToolbar, Button } from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import { connect } from "react-redux";
import moment from 'moment';
import {
  spcmLoader,
  getServiceAuditLogs,
  getServiceAuditLogsForFulfillment,
  getServiceAuditLogsForApprovals,
  resetReducer, audittReminders, clearReminder,
} from "../../../actions/spcmActions";
import _ from "lodash";
import ListLoader from "_Commons/loaders/ListLoader";
import Cookies from 'universal-cookie';
import CloseBtnRightPanel from "../../common/closeBtnRightPanel";
const cookies = new Cookies();

let dateformat = cookies.get('gph');
if (dateformat) dateformat = dateformat.replace('s:', '');
if (dateformat) dateformat = dateformat.substring(0, dateformat.lastIndexOf('.'));
dateformat = dateformat.split("~");
dateformat = dateformat[2];

let offset = cookies.get('gph');
if (offset) offset = offset.replace('s:', '');
if (offset) offset = offset.substring(0, offset.lastIndexOf('.'));
offset = offset.split("~");
offset = offset[34];
class ServiceAuditLogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lanNameVal: "",
      lanIdVal: "",
      fieldNameVal: "",
      showAuditLogs: false,
      auditLogsType: "serviceItemDetails",
      auditLogTypeHeading: "Service Item Details"
    };
    this.showAuditLogs = this.showAuditLogs.bind(this);
  }

  // componentDidMount() {
  //   this.props.getServiceAuditLogs(
  //     this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
  //     "desc",
  //     this.state.auditLogsType
  //   );
  // }

  showAuditLogs(type, typeHeading) {
    this.setState({
      showAuditLogs: true,
      auditLogsType: type,
      auditLogTypeHeading: typeHeading
    });
    //console.log(this.props.spcmReducer.auditReminders,"onlycheck");


    if (type == "Fulfillment Plan") {
      this.props.getServiceAuditLogsForFulfillment(
        this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
        "desc"
      )
    }
    else if (type == "Reminder") {
      // console.log(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,"checking");
      this.props.audittReminders("SPCM", this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID, "REMINDER",
        "desc"
      )
    }
    else if (type == "Sla_Definition") {
      this.props.audittReminders("SPCM", this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID, "SLA_DEFINITION",
        "desc"
      )
    }
    else if (type == "Approvals") {
      this.props.getServiceAuditLogsForApprovals(
        this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
        "desc"
      )
    }
    else {
      this.props.getServiceAuditLogs(
        this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
        "desc",
        type
      );
    }

  }


  hideAuditLogs() {
    this.setState({
      showAuditLogs: false
    });
  }

  componentWillUnmount() {
    this.props.resetReducer();
  }


  render() {
    const auditLogData = this.props.spcmReducer.serviceAuditLogs;
    const tr = this.props.tr;
    const serviceWorkItemType = this.props.spcmReducer.currentlySelectedServiceDetails.WORK_ITEM_TYPE;
    return (
      <div className="serviceAuditLogs">
        <CloseBtnRightPanel showRightSide={this.props.showRightSide}/>
        <div className="rPageHeading">
          {this.state.showAuditLogs ? <div className="offNam margin-t-5 margin-b-5"><span className="auditLogHead">{`Audit - ${this.state.auditLogTypeHeading}`}</span></div> : <div className="offNam margin-t-5 margin-b-5">Audit Log</div>}

          {this.state.showAuditLogs ? <span className=" f-size-17 float-r">
            <a
              className="black"
              href="javascript:void(0)"
              onClick={() => this.state.auditLogsType == "Fulfillment Plan" ?
                this.props.getServiceAuditLogsForFulfillment(
                  this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
                  "asc"
                ) :
                this.state.auditLogsType == "Approvals" ?
                  this.props.getServiceAuditLogsForApprovals(
                    this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
                    "asc"
                  ) :
                  this.state.auditLogsType == "Reminder" ?
                    this.props.audittReminders("SPCM", this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID, "REMINDER",
                      "asc"
                    ) :
                    this.state.auditLogsType == "Sla_Definition" ?
                      this.props.audittReminders("SPCM", this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID, "SLA_DEFINITION",
                        "asc"
                      ) :
                      this.props.getServiceAuditLogs(
                        this.props.spcmReducer.currentlySelectedServiceDetails
                          .OFFERING_ID,
                        "asc",
                        this.state.auditLogsType
                      )
              }
              title={this.props.tr["Ascending"]}
            >
              <i className="fa fa-angle-up"></i>
            </a>
            &nbsp;&nbsp;
            <a
              className="black"
              href="javascript:void(0)"
              onClick={() => this.state.auditLogsType == "Fulfillment Plan" ?
                this.props.getServiceAuditLogsForFulfillment(
                  this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
                  "desc"
                ) :
                this.state.auditLogsType == "Approvals" ?
                  this.props.getServiceAuditLogsForApprovals(
                    this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
                    "desc"
                  ) :
                  this.state.auditLogsType == "Reminder" ?
                    this.props.audittReminders("SPCM", this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID, "REMINDER",
                      "desc"
                    ) :
                    this.state.auditLogsType == "Sla_Definition" ?
                      this.props.audittReminders("SPCM", this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID, "SLA_DEFINITION",
                        "desc") :

                      this.props.getServiceAuditLogs(
                        this.props.spcmReducer.currentlySelectedServiceDetails
                          .OFFERING_ID,
                        "desc",
                        this.state.auditLogsType
                      )
              }
              title={this.props.tr["Descending"]}
            >
              <i className="fa fa-angle-down"></i>
            </a>
          </span> : null}

        </div>

        <div className="rBoxGap">
          {this.state.showAuditLogs ? this.state.auditLogsType == "Reminder" || this.state.auditLogsType == "Sla_Definition" ? <div>{
            this.props.spcmReducer.auditList == undefined || typeof this.props.spcmReducer.auditList == "string" ? <ListLoader /> : <div>{this.props.spcmReducer.auditList.length == 0 ?
              <div>
                <div style={{ fontWeight: "600", fontSize: "14px" }}>
                  No audit details available
                </div>
                <ButtonToolbar className="black" style={{ "marginTop": "10px" }}>
                  <Button title="Close" onClick={() => { this.hideAuditLogs(); this.props.clearReminder() }} size="sm" bsStyle="default">{tr['Close']}</Button>
                </ButtonToolbar>

              </div>
              : <div>
                <div className="auditLogsDiv">
                  {this.props.spcmReducer.auditList.map((auditObj, index) => {

                    return (
                      <div>

                        <Card bsPrefix=" " className="mediaCrd">
                          <Card.Body>
                            <Card.Title className="blue margin-0">
                              {
                                auditObj.createdOn
                              }-{" "}
                              <span
                                className="colorStOrange"     >
                                {auditObj.createdByName}
                              </span>

                            </Card.Title>
                            {this.state.auditLogsType == "Reminder" ?
                              <div className="black fw-300 cTxt">
                                {auditObj.auditField} modified from {" "}
                                {auditObj.from.substring(0)}{" "}
                                to {auditObj.to.substring(0)}
                              </div>
                              :
                              <div className="black fw-300 cTxt">
                                {auditObj.auditField} from {" "}
                                {auditObj.from.substring(0)}{" "}
                                to {auditObj.to.substring(0)}
                              </div>
                            }
                          </Card.Body>
                        </Card>
                      </div>
                      //, auditObj.from.length - 7
                    )

                  })
                  }
                </div>
                <ButtonToolbar className="black" style={{ "marginTop": "10px" }}>
                  <Button title="Close" onClick={() => { this.hideAuditLogs(); this.props.clearReminder() }} size="sm" bsStyle="default">{tr['Close']}</Button>
                </ButtonToolbar>
              </div>

            }
            </div>
          }

          </div>
            : <div>{
              this.props.spcmReducer.spcmFetchingAttribute ? (
                <ListLoader />
              ) : auditLogData.length == 0 ? (
                <div>
                  <div style={{ fontWeight: "600", fontSize: "14px" }}>
                    No audit details available
                  </div>
                  <ButtonToolbar className="black" style={{ "marginTop": "10px" }}>
                    <Button title="Close" onClick={() => { this.hideAuditLogs() }} size="sm" bsStyle="default">{tr['Close']}</Button>
                  </ButtonToolbar>
                </div>
              ) : (
                <div>
                  <div className="auditLogsDiv">
                    {auditLogData.map((auditObj, index) => {
                      if (auditObj.from == "false") auditObj.from = "No";
                      if (auditObj.from == "true") auditObj.from = "Yes";
                      if (auditObj.to == "false") auditObj.to = "No";
                      if (auditObj.to == "true") auditObj.to = "Yes";
                      if (auditObj.audit_field == "EXPIRATION_DATE") {
                        return (
                          <Card bsPrefix=" " className="mediaCrd">
                            <Card.Body>
                              <Card.Title className="blue margin-0">
                                {auditObj.created_on} -{" "}
                                <span
                                  className="colorStOrange"
                                  onClick={this.showUserDetails}
                                >
                                  {auditObj.created_by}
                                </span>
                              </Card.Title>

                              <div className="black fw-300 cTxt">
                                {auditObj.audit_field} modified from{" "}
                                {auditObj.from.substring(0, auditObj.from.length - 7)}{" "}
                                to {auditObj.to.substring(0, auditObj.from.length - 7)}
                              </div>
                            </Card.Body>
                          </Card>
                        );
                      } else {
                        return (
                          <Card bsPrefix=" " className="mediaCrd">
                            <Card.Body>
                              <Card.Title className="blue margin-0">
                                {
                                  this.state.auditLogsType == "Fulfillment Plan" ?
                                    <span>{moment.utc(auditObj.created_on).utcOffset(offset / 60).format(dateformat.replace("dd", "DD"))} - {" "} </span> :
                                    <span>{auditObj.created_on} - {" "} </span>
                                }

                                <span
                                  className="colorStOrange"
                                  onClick={this.showUserDetails}
                                >
                                  {auditObj.created_by}
                                </span>
                              </Card.Title>

                              {auditObj.from == null ||
                                auditObj.from == "" ||
                                auditObj.from == "null" ||
                                auditObj.from == undefined ||
                                auditObj.from == "undefined" ? (
                                <div className="black fw-300 cTxt">{auditObj.to}</div>
                              ) : (
                                <div className="black fw-300 cTxt">
                                  {this.state.auditLogsType == "Approvals" ?
                                    <div>
                                      {
                                        auditObj.audit_type == "APPROVAL_DELETE" ?
                                          <div>
                                            {auditObj.audit_field}
                                          </div> :
                                          <div>
                                            {auditObj.audit_field} modified from {auditObj.from}{" "}
                                            to {auditObj.to}
                                          </div>
                                      }
                                    </div> :
                                    <div>
                                      {
                                        auditObj.audit_field == "TASK has been deleted" ?
                                          <div>
                                            {auditObj.audit_field}  - {auditObj.from}
                                          </div> :
                                          <div>
                                            {auditObj.audit_field} modified from {auditObj.from}{" "}
                                            to {auditObj.to}
                                          </div>
                                      }
                                    </div>
                                  }


                                </div>
                              )}
                            </Card.Body>
                          </Card>
                        );
                      }
                    })}

                  </div>
                  <ButtonToolbar className="black" style={{ "marginTop": "10px" }}>
                    <Button title="Close" onClick={() => { this.hideAuditLogs() }} size="sm" bsStyle="default">{tr['Close']}</Button>
                  </ButtonToolbar>
                </div>
              )
            }
            </div> : <div className="serviceListBox">
            <div className="servicePlist">

              <div className="lbox auditBox" onClick={() => { this.showAuditLogs("serviceItemDetails", "Service Item Details") }}><div className="hd gapn">
                {tr['Service Item Details']}</div></div>
              {serviceWorkItemType == "incidentRequest" ? null :
              <div className="lbox auditBox" onClick={() => { this.showAuditLogs("Approvals", "Approvals") }}><div className="hd gapn">{tr['Approvals']}</div></div>
              }
              <div className="lbox auditBox" onClick={() => { this.showAuditLogs("ofgAttributes", 'Attributes') }}><div className="hd gapn">{tr['Attributes']}</div></div>

              <div className="lbox auditBox" onClick={() => { this.showAuditLogs("ofgChain", "Chain Entities") }}><div className="hd gapn">{tr['Chain Entities']}</div></div>

              <div className="lbox auditBox" onClick={() => { this.showAuditLogs("ofgcompMaster", "Components") }}><div className="hd gapn">{tr['Components']}</div></div>

              <div className="lbox auditBox" onClick={() => { this.showAuditLogs("ofgEntitlements", "Entitlements") }}><div className="hd gapn">{tr['Entitlements']}</div></div>

              {/* <div className="lbox" onClick={() => { this.props.clickTabName2('Knowledge', 8) }}><div className="hd gapn"><span className="serIcon bgBtn6 sDetails p8"></span>{tr['Knowledge']}</div><div className="txtBox"><div className="txt">{tr['Specify sources of knowledge that can be referred to by consumers']}</div></div></div> */}

              {serviceWorkItemType == "serviceRequest" ?
                <div className="lbox auditBox" onClick={() => { this.showAuditLogs("ofgBillingdef", "Pricing") }}><div className="hd gapn">{tr['Pricing']}</div></div>
                : null}

              {serviceWorkItemType == "serviceRequest"
                ? <div className="lbox auditBox" onClick={() => { this.showAuditLogs("categoryQuestions", "Ordering Information Groups") }}><div className="hd gapn">{tr['Ordering Information Groups']}</div></div> : null}

              {serviceWorkItemType == "serviceRequest"
                ? <div className="lbox auditBox" onClick={() => { this.showAuditLogs("ofgQuestions", "Ordering Information") }}><div className="hd gapn">{tr['Ordering Information']}</div></div> : null}

              {/* <div className="lbox" onClick={() => { this.props.clickTabName2('Service Level Targets', 5) }}><div className="hd gapn"><span className="serIcon bgBtn15 sDetails p5"></span>{tr['Service Level Targets']}</div><div className="txtBox"><div className="txt">{tr['Specify the support performance targets for the service']}</div></div></div> */}

              <div className="lbox auditBox" onClick={() => { this.showAuditLogs("selfServiceActions", "Self-Service Actions") }}><div className="hd gapn">{tr['Self-Service Actions']}</div></div>

              <div className="lbox auditBox" onClick={() => { this.showAuditLogs("spcmPreferences", "Preferences") }}><div className="hd gapn">{tr['Preferences']}</div></div>

              <div className="lbox auditBox" onClick={() => { this.showAuditLogs("additionalIntegration", "Additional Integration") }}><div className="hd gapn">{tr['Additional Integration']}</div></div>

              <div className="lbox auditBox" onClick={() => { this.showAuditLogs("integrationPreferences", "Integration Preference") }}><div className="hd gapn">{tr['Integration Preference']}</div></div>

              <div className="lbox auditBox" onClick={() => { this.showAuditLogs("Fulfillment Plan", "Fulfillment Plan") }}><div className="hd gapn">{tr['Fulfillment Plan']}</div></div>

              <div className="lbox auditBox" onClick={() => { this.showAuditLogs("relatedOfferings", "Related Offerings") }}><div className="hd gapn">{tr['Related Offerings']}</div></div>

              {/* <div className="lbox" onClick={() => { this.props.clickTabName2('Reminders', 15) }}><div className="hd gapn"><span className="serIcon bgBtn17 sDetails p7"></span>Reminders</div><div className="txtBox"><div className="txt">Reminders</div></div></div> */}

              {serviceWorkItemType == "serviceRequest" ?
                <div className="lbox auditBox" onClick={() => { this.showAuditLogs("ofgDisclaimer", "Disclaimers") }}><div className="hd gapn">{tr['Disclaimers']}</div></div>
                : null}
                {serviceWorkItemType == "incidentRequest" ? null :
                  <div className="lbox auditBox" onClick={() => { this.showAuditLogs("Reminder", "Reminders") }}><div className="hd gapn">{tr['Reminders']}</div></div>
                }
              <div className="lbox auditBox" onClick={() => { this.showAuditLogs("Sla_Definition", "Service Level Targets") }}><div className="hd gapn">{tr['Service Level Targets']}</div></div>

            </div>
          </div>}

        </div>

      </div>
    );
  }
}

const mapStateToProps = ({ spcmReducer }) => {
  return {
    spcmReducer,
  };
};

export default connect(mapStateToProps, {
  getServiceAuditLogs,
  getServiceAuditLogsForFulfillment,
  getServiceAuditLogsForApprovals,
  resetReducer,
  audittReminders, clearReminder
})(ServiceAuditLogs);
