
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/

export const hamList = (state = {}, action) => {

    switch (action.type) {
        case 'LOAD_HAM_LIST_SUCCESS':
            return action.hamList.data;
        case 'EMPTY_HAM_LIST_REDUCER':
            return {};
        default:
            return state;
    }
}


export const classEditList = (state = [], action) => {
    if (action.type === 'CLASS_EDIT_DETAILS_FETCHED') {
        console.log("action.payload---",action.payload);
      return action.payload;
    } 
    else if (action.type === 'CLASS_EDIT_DETAILS_ERROR') {
        return {
            isLoading: false,
            message: 'failed'
        }
    }
    else {
      return state;
    }
};

export const compClass = (state = [], action) => {
	switch (action.type) {
		case 'LOAD_CLASS_BY_COMPANY_SUCCESS':
			return action.ciClass;
		case 'LOAD_CLASS_BY_COMPANY_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}
}