
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, {useState, useEffect} from "react";
import { useNavigate, useParams } from "react-router-dom";
import InvestigationEdit from "./InvestigationEdit.js";

export default function InvestigationEditMain()
{ 
	const [invesParams, setInvesParams] = useState({investigationId: ""});
	const [isLoading, setLoading] = useState(false);
	const navigate = useNavigate();
	const params = useParams();
	useEffect(() => {
		setInvesParams({investigationId: params.investigationId});
	  }, []);
	  useEffect(() => {
		if(params.investigationId !== invesParams.investigationId){
		  setLoading(true);
		  setTimeout(() => {
			setInvesParams({investigationId: params.investigationId});
			setLoading(false);
		  }, 200);
		}
	  }, [params.investigationId]);
	  if(isLoading){
		return null;
	  }
	return (<InvestigationEdit navigate={navigate} params={invesParams}/>)
}