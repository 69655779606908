
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Button } from "react-bootstrap";
import { StandardTextArea } from "../../../../../../common/FormFields";
import GridLayout from "../../../../../../common/GridLayout";
import ListLoader from "../../../../../../../common/loaders/ListLoader";
import { useSaveMinutesDetailsMutation } from "../../../../../../../../reducers/governance/meeting/meeting";

const AddEdit = ({ hide, companyID, occurenceData, activeTabs }) => {
  const meetingOccurrananceId = occurenceData?.meetingOccurrananceId;
  const definationId = occurenceData?.meetingDefinationId;

  const validationSchema = Yup.object().shape({
    discussionPoints: Yup.string().required("Required"),
  });

  const [initVals] = useState({
    discussionPoints: "",
  });

  const [saveDiscussion, { isLoading: isLoadingSaveDisc }] =
    useSaveMinutesDetailsMutation();

  useEffect(() => {
      return () => {
       hide();
      }
  }, [activeTabs]);

  const onSubmit = async (payload, { setSubmitting }) => {
    const postdata = {
      occuranceId: meetingOccurrananceId,
      discussionPoints: payload?.discussionPoints,
      definationId: definationId,
    };

    await saveDiscussion({
      data: postdata,
      queryString: `?tenantId=${companyID}`,
    });

    setSubmitting(false);
    hide();
  };

  return isLoadingSaveDisc ? (
    <div className="fnloader">
      <ListLoader />
    </div>
  ) : (
    <Formik
      enableReinitialize={true}
      initialValues={initVals}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        handleBlur,
        values,
        handleChange,
        setValues,
        resetForm,
        dirty,
      }) => {
        return (
          <Form noValidate>
            <GridLayout cols={1}>
              <div className="mb-3">
                <StandardTextArea label="Decisions" name="discussionPoints" />
              </div>
            </GridLayout>
            <GridLayout cols={1}>
              <div className="pull-right custom-note-submit-btn">
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  title="Save"
                  className="rgSidrkBtn smallBtn mb-0"
                >
                  Save
                </Button>
                &nbsp;
                <a className="btn  smallBtn" onClick={hide}>
                  Cancel
                </a>
              </div>
            </GridLayout>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddEdit;
