
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';

export function getRCAtechniques(moduleName, technique){
    return (dispatch) => {
        axios.get("/serviceManagement/rest/menuitemAction?module="+moduleName+"&field1Name="+technique).then((responseData) => {
            dispatch({type: 'RCA_TECHNIQUES',techniques: responseData.data});
        }).catch((error) => {
            console.log("getRCAtechniques() error : ",error);
        });
    }
}

export function getfilledRCAtechniques(investigationID,setDefaultMethod=(methodName)=>{}){
    return (dispatch) => {
        axios.get('/serviceManagement/rest/rcaTechniqueViewData/'+investigationID).then((responseData)=>{
            if(typeof responseData.data!=='string'){
                setDefaultMethod(responseData.data[0].displayId);
            }
            dispatch({type: 'RCA_TECHNIQUES',techniques: responseData.data});
        }).catch((error)=>{
            console.log("getRCAtechniques() error : ",error);
        });
    }
}