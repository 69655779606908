
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/

import initialState from '../initialState';

export default function (state = initialState.breakFixStatusDetails, action){

	switch (action.type){
		case 'LOAD_BREAKFIXSTATUSDETAILS_SUCCESS':
				//alert("editReducer:"+action.breakFixEditDetails.data.serviceId);
				return action.breakFixStatusDetails.data;
		case 'LOAD_BREAKFIXSTATUSDETAILS_FAILURE':
				return action.failureMessage;
		default:
				return state;
		}

}
