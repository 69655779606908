
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import {Row, Col, ListGroup, Button, Nav} from "react-bootstrap";
import { Link } from 'react-router-dom';
import { getAllServicesList,getServiceDetailsDataList,getServiceCIs } from "_Actions/spcmActions";
import { connect } from "react-redux";
import axios from "axios";
import OnClickOutside from 'react-onclickoutside';
import constants from "_Utils/constants";
import HeaderRight from "./HeaderRight";
import moment from "moment";
import {ImCopy} from "react-icons/im";
import {LuFilterX, LuFileSearch, LuFileText, LuHistory} from "react-icons/lu";
import {BiSortDown} from "react-icons/bi";
import {IoClose, IoImagesOutline, IoStarHalf, IoLanguage} from "react-icons/io5";
import {HiPlus, HiOutlineRefresh} from "react-icons/hi";
import {LiaStepBackwardSolid} from "react-icons/lia";
import { MyContext } from '_MyContext';
import { navigationHooks } from '../../helpers/NavigationHook';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
let homepagelocation = cookies.get('gph');
if (homepagelocation) homepagelocation = homepagelocation.replace('s:', '');
if (homepagelocation) homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home") homepagelocation = "/" + homepagelocation;

class Header extends React.Component {
  static contextType = MyContext;
  constructor(props) {
    super(props);
    this.state = {
			hamburgerShowHide: false,
			statusHamburgerShowHide: false,
			statusName: this.props.spcmReducer.editService.status,
			previousStatus: this.props.initialValues.status,
     
		};
    this.submitForm = this.submitForm.bind(this);
    this.onCloseClick = this.onCloseClick.bind(this);
    this.clearAllFilters = this.clearAllFilters.bind(this);
    this.refreshTable = this.refreshTable.bind(this);
    this.callbackFun = this.callbackFun.bind(this);
		this.statusHamburgerShowHide = this.statusHamburgerShowHide.bind(this);
		this.setStatusName = this.setStatusName.bind(this);
    this.changeActionView = this.changeActionView.bind(this);
    this.loadHome = this.loadHome.bind(this);

  }
  componentDidMount() {
		this.context.keyDownHandler(this.closeButton,"","","");
    this.context.newTabPrevent();
	}
	componentDidUpdate() {
		this.context.keyDownHandler(this.closeButton,"","","");
    this.context.newTabPrevent();
	}
  loadHome() {
		navigationHooks.navigate(homepagelocation);
	}

  changeActionView(actionSelected) {
    this.setState({
      actionView: actionSelected
    });
  }
	handleClickOutside() {
		this.statusHamburgerShowHide();
	}
  callbackFun(id) {
		this.setState({ statusHamburgerShowHide: id });
	}
	statusHamburgerShowHide() {
		this.setState({ statusHamburgerShowHide: !this.state.statusHamburgerShowHide });
	}

	setStatusName(value,parameter) {
    console.log("parameter",parameter);
      this.setState({ statusName: value });

	}
  submitForm(values) {
    if (this.props.editServiceState) {
      values.updatedby_name = "";
      values.updated_by = "";
    } else {
      values.createdby_name = "";
      values.created_by = "";
    }
    var portfolio = {
      chain_type: "portfolio",
      chain_company_id: values.portfolioCompany,
      chain_group_id: values.portfolioGroup,
      chain_person_id: values.portfolioManager,
    };
    var catalogue = {
      chain_type: "catalogue",
      chain_company_id: values.catalogueCompany,
      chain_group_id: values.catalogueGroup,
      chain_person_id: values.catalogueManager,
    };
    this.props.spcmReducer.companyList.forEach(function (val) {
      if (val.field1Value == values.portfolioCompany)
        portfolio.chain_company = val.field1Key;
      if (val.field1Value == values.catalogueCompany)
        catalogue.chain_company = val.field1Key;
      if (val.field1Value == values.company_id)
        catalogue.company_name = val.field1Key;
    }, this);

    this.props.spcmReducer.portfolioGroupList.forEach(function (val) {
      if (val.field1Value == values.portfolioGroup)
        portfolio.chain_group_name = val.field1Key;
    }, this);

    this.props.spcmReducer.portfolioManagerList.forEach(function (val) {
      if (val.field1Value == values.portfolioManager)
        portfolio.chain_person_name = val.field1Key;
    }, this);

    this.props.spcmReducer.catalogueGroupList.forEach(function (val) {
      if (val.field1Value == values.catalogueGroup)
        catalogue.chain_group_name = val.field1Key;
    }, this);

    this.props.spcmReducer.catalogueManagerList.forEach(function (val) {
      if (val.field1Value == values.catalogueManager)
        catalogue.chain_person_name = val.field1Key;
    }, this);

    this.props.spcmReducer.categoryList.forEach(function (val) {
      if (val.id == values.category_id) {
        values.category_name = val.categoryName;
        values.sub_category_name = val.subCategoryName;
      }
    }, this);

    this.props.spcmReducer.criticalityList.forEach(function (val) {
      if (val.field1Value == values.service_criticality)
        values.service_criticality_name = val.field1Key;
    }, this);
    this.props.spcmReducer.companyList.forEach(function (val) {
      if (val.field1Value == values.company_id)
        values.company_name = val.field1Key;
    }, this);

    var ofg_chain = [];
    ofg_chain.push(portfolio);
    ofg_chain.push(catalogue);
    values.ofg_chain = ofg_chain;

    delete values.portfolioCompany;
    delete values.portfolioGroup;
    delete values.portfolioManager;
    delete values.catalogueCompany;
    delete values.catalogueGroup;
    delete values.catalogueManager;
    if (this.props.editServiceState) {
      values.offering_id = this.props.spcmReducer.editService.offering_id;
      values.ofg_chain[0].chain_id = this.props.spcmReducer.currentlySelectedServiceDetails.ofg_chain[0].CHAIN_ID;
      values.ofg_chain[1].chain_id = this.props.spcmReducer.currentlySelectedServiceDetails.ofg_chain[1].CHAIN_ID;
      return axios
        .patch(
          constants.SERVER +
          constants.SERVICE_API_URL +
          "/" +
          this.props.spcmReducer.editId,
          values
        )
        .then((response) => {
          this.props.getServiceDetailsDataList("");
          // this.props.getAllServicesList();
          this.props.rightSidePageHead("default");
          this.props.updateServiceState(false, true);
          this.props.boardPageInActive2(false);
          this.props.componentFormFun1(false);
          this.props.editServiceToggle(false);
        })
        .catch((error) => {
          console.log(error.response.data[0].message);
          /* if(this.props.rightSidePageHead1=='ShortDescription'){
            this.props.rightSidePageHead('ShortDescription');
          }else{
            this.props.rightSidePageHead('ShortDescription');
          }
          this.props.getAllServicesList();
          this.props.editServiceToggle(false);  */
        });
    } else {
      return axios
        .post(constants.SERVER + constants.SERVICE_API_URL, values)
        .then((response) => {
          this.props.getServiceDetailsDataList("");
          // this.props.getAllServicesList();
          this.props.rightSidePageHead("default");
          this.props.updateServiceState(false, true);
          this.props.boardPageInActive2(false);
          this.props.componentFormFun1(false);
          this.props.editServiceToggle(false);
        })
        .catch((error) => {
          console.log(error.response.data[0].message);
        });
    }
  }

  clearAllFilters() {
    this.props.setServiceNameInputValues("");
		this.props.setCompanyNameInputValues("");
		this.props.setSelectedCategoryValue([]);
		this.props.setSelectedFeaturedValue([]);
		this.props.setSelectedWorkItemValue([]);
		this.props.setSelectedStatusValue([
      { label: "Draft", value: "Draft" },
      { label: "Deployed", value: "Deployed" },
      { label: "Proposed", value: "Proposed" },
      { label: "Suspended", value: "Suspended" },
    ]);
		this.props.setModifiedStartAndEndDate({}, {});

    // this.props.dateRangePickerRefModified != undefined ? this.props.dateRangePickerRefModified.endDate = moment() : "";
		// this.props.dateRangePickerRefModified != undefined ? this.props.dateRangePickerRefModified.startDate = moment() : "";

    if(this.props.dateRangePickerRefModified){
      this.props.dateRangePickerRefModified.endDate = moment();
      this.props.dateRangePickerRefModified.startDate = moment()
    }

    this.props.setPage(1);
		this.props.setLimit(10);
		this.props.setSearchParams({});
		this.props.setMultiSelectParams([], ['Draft', 'Deployed', 'Proposed', 'Suspended'], [], []);

    this.props.setSortOrder("desc", "modifiedDate");
		this.props.showRightSide(false);
		this.props.isClearAllFilterFunc(false);
    this.props.getServiceDetailsDataList("", ['Draft', 'Deployed', 'Proposed', 'Suspended'], "", "", "");
  }
  
  refreshTable() {
    // this.props.getServiceDetailsDataList(this.props.searchParams, this.props.status, this.props.category, this.props.featured, this.props.workItemType, this.props.sortOrder, this.props.sortOnColumn, this.props.page, this.props.size)
    this.props.showRightSide(false);
    this.props.getServiceDetailsDataList(this.props.searchParams, this.props.status, this.props.category, this.props.featured, this.props.workItemType, this.props.modifiedDateFilterValues, this.props.sortOrder, this.props.sortOnColumn, this.props.page, this.props.size);
    this.props.refreshClickFun(true);
  }

  onCloseClick() {
    this.props.showRightSide (false);
    this.props.copyOfferingToggle(false);
    this.props.rightSidePageNoFun(0);
    this.props.rightSidePageHead("default");
    this.props.updateServiceState(false, true);
    this.props.componentFormFun1(false);
    this.props.boardPageInActive2(false);
    this.props.editServiceToggle(false);
    this.props.setActiveKeyVal(1);
    // this.clearAllFilters();
    this.props.statusOnClicked(false);
    this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID = undefined;
  }

  render() {
  console.log("this.props.statusClicked",this.props.statusClicked);
  console.log("this.state.statusName",this.state.statusName);
  console.log("statusRenderingState",this.props.statusRenderingState);
  console.log("this.props.responseStatus",this.props.responseStatus);
  
    const tr = this.props.tr;
    return (
      <div>
        <div className="margin-t-10 margin-b-15">
					<Row className="">

							<Col lg={8} md={8} sm={8} xs={12}>


								{this.props.boardPageActive ? <ul className="breadcrumb">
									<li className="breadcrumb-item">
										<a role="button" href="javascript:void(0)" onClick={this.loadHome}>
											{this.props.tr["Home"]}
										</a>
									</li>
									<li className="breadcrumb-item active">{this.props.tr["Service Board"]}</li>
								</ul> : this.props.creServiceState && this.props.editServiceState ? <ul className="breadcrumb">
									<li className="breadcrumb-item">
										<a role="button" href="javascript:void(0)" onClick={this.loadHome}>
											{this.props.tr["Home"]}
										</a>
									</li>
									<li className="breadcrumb-item"><Link
                  onClick={() => {
                    this.onCloseClick();
                    this.props.langActSecFun(false);
                    this.props.getServiceCIs("0","test");
                    this.props.statusRendering("",false)
                  }}
                   title={this.props.tr["Service Board"]} to="/servicedetails" >{this.props.tr["Service Board"]}</Link></li>
									<li className="breadcrumb-item active">{this.props.tr["Edit"]}</li>
								</ul> : <ul className="breadcrumb">
									<li className="breadcrumb-item">
										<a role="button" href="javascript:void(0)" onClick={this.loadHome}>
											{this.props.tr["Home"]}
										</a>
									</li>
									<li className="breadcrumb-item"><Link
                  onClick={() => {
                    this.onCloseClick();
                    this.props.langActSecFun(false);
                    this.props.getServiceCIs("0","test");
                    this.props.statusRendering("",false)
                  }}
                  title={this.props.tr["Service Board"]} to="/servicedetails" >{this.props.tr["Service Board"]}</Link></li>
									<li className="breadcrumb-item active">{this.props.tr["Create"]}</li>
								</ul>}


						</Col>
			{this.props.boardPageActive?
                <Col lg={4} md={4} sm={4} xs={12}>
                  {this.props.recordCount != 0 ? <div className="tabDatacount">{this.props.startLimit}-{this.props.endLimit > this.props.recordCount ? this.props.recordCount : this.props.endLimit} of {this.props.recordCount}</div> : null}
                </Col> : ""}
            
          </Row>
					</div>
      <Row className="margin-b-10">
        <Col md={3} xs={12}>
            <h2 bsClass="" className="sPageHeading1">{tr["Service Board"]}</h2>
        </Col>
       
        <Col md={9} xs={12} className="text-r">
        <div className="topRgtNavIcons">
        { this.props.editServiceState ?
							<div className="toprigLnk" style={{marginTop:"0px"}}><div className="thrlnebtn">
                <Button title="Statuses" onClick={this.statusHamburgerShowHide.bind(this)} className="btnDropMenu" disabled={this.props.initialValues.status=="Obsolete" ? true : false}>{this.props.statusClicked?this.state.statusName: this.props.responseStatus?this.props.statusRenderingState:this.props.spcmReducer.currentlySelectedServiceDetails.STATUS} <i className="fa fa-angle-down" aria-hidden="true"></i></Button>
                  {this.state.statusHamburgerShowHide ?
											<TaskStatusHamburger assignToListErrorColorParentFunc={this.props.assignToListErrorColorParentFunc} setAssignToListErrorColor={this.props.setAssignToListErrorColor} statusOnClicked={this.props.statusOnClicked} setStatusName={this.setStatusName} statusName={this.state.statusName} serviceStatus={this.props.responseStatus?this.props.statusRenderingState:this.props.spcmReducer.currentlySelectedServiceDetails.STATUS} fun={this.callbackFun} changeActionView={this.changeActionView} editFormValues={this.props.editFormValues} setStatus={this.props.setStatus}/> : null}
									</div></div>
                  : 
                  null
              }
          {this.props.creServiceState || this.props.editServiceState ? (
            <div className="serviceHedRFltrMargn">
              <div className="paHedFilter border-r margin-r-10 padding-r-10">
                {this.props.rightSidePageHead1 == "default" ? null : (
                  <div>
                    {this.props.serDetailsIconVar == true? null:
                    <a className={this.props.isPageCountNo == 0 ? "myBt fillBtn active" : "myBt fillBtn"}
                      href="javascript:void(0)"
                      onClick={() => {
                        this.props.langActSecFun(false);
                        this.props.rightSidePageNoFun(0);
                        this.props.showRightSide(true);
                        this.props.boardPageInActive2(false);
                        this.props.componentFormFun1(false);
                      }}
                      title={tr["Service Details"]}
                    >
                      <LuFileText/>
                    </a>
                    }
                    {this.props.serDetailsIconVar == true? null:
                    <a className={this.props.isPageCountNo == 6 ? "myBt fillBtn active" : "myBt fillBtn"}
                      href="javascript:void(0)"
                      onClick={() => {
                        this.props.langActSecFun(false);
                        this.props.rightSidePageNoFun(6);
                        this.props.showRightSide(true);
                        this.props.boardPageInActive2(false);
                        this.props.componentFormFun1(false);
                      }}
                      title={tr["Audit Log"]}
                    >
                      <LuFileSearch/>
                    </a>
                    } 
                    {this.props.serDetailsIconVar == true? null:  
                    <a className={this.props.isPageCountNo == 3 ? "myBt fillBtn active" : "myBt fillBtn"}
                      href="javascript:void(0)"
                      onClick={() => {
                        this.props.langActSecFun(false);
                        this.props.rightSidePageNoFun(3);
                        this.props.boardPageInActive2(false);
                        this.props.showRightSide(true);
                        this.props.componentFormFun1(false);
                      }}
                      title={tr["Service Images"]}
                    >
                      <IoImagesOutline/>
                    </a>
                    }
                    {this.props.serDetailsIconVar == true? null:
                    <a className={this.props.isPageCountNo == 2 ? "myBt fillBtn active" : "myBt fillBtn"}
                      href="javascript:void(0)"
                      onClick={() => {
                        this.props.langActSecFun(false);
                        this.props.rightSidePageNoFun(2);
                        this.props.boardPageInActive2(false);
                        this.props.showRightSide(true);
                        this.props.componentFormFun1(false);
                      }}
                      title={tr["Service Ratings"]}
                    >
                      <IoStarHalf/>
                    </a>
                      }
                      {this.props.serDetailsIconVar == true? null:
                    <a className={this.props.isPageCountNo == 1 ? "myBt fillBtn active" : "myBt fillBtn"}
                      href="javascript:void(0)"
                      onClick={() => {
                        this.props.langActSecFun(false);
                        this.props.rightSidePageNoFun(1);
                        this.props.boardPageInActive2(false);
                        this.props.showRightSide(true);
                        this.props.componentFormFun1(false);
                      }}
                      title={tr["Service History"]}
                    >
                      <LuHistory/>
                    </a>
                    }
                    {this.props.spcmReducer.currentlySelectedServiceDetails
                      .STATUS == "Draft" ||
                    this.props.spcmReducer.currentlySelectedServiceDetails
                      .STATUS == "Proposed" ? null : (
                        this.props.serDetailsIconVar == true? null:
                      <a className={this.props.isPageCountNo == 4 ? "myBt fillBtn active" : "myBt fillBtn"}
                        href="javascript:void(0)"
                        onClick={() => {
                          this.props.langActSecFun(false);
                          this.props.rightSidePageNoFun(4);
                          this.props.showRightSide(true);
                          this.props.boardPageInActive2(false);
                          this.props.componentFormFun1(false);
                        }}
                        title={tr["Copy a Service"]}
                      >
                        <ImCopy/>
                      </a>
                        
                    )}
                    {this.props.serDetailsIconVar == true? null:
                    <a className={this.props.isPageCountNo == 5 ? "myBt fillBtn active" : "myBt fillBtn"}
                      href="javascript:void(0)"
                      onClick={() => {
                        this.props.rightSidePageNoFun(5);
                        this.props.boardPageInActive2(false);
                        this.props.showRightSide(true);
                        this.props.componentFormFun1(false);
                      }}
                      title={tr["Language Activation"]}
                    >
                      <IoLanguage/>
                    </a>
                    }
                  </div>
                )}
              </div>
              <div className="paHedFilter">
                <HeaderRight
                  boardPageInActive2={this.props.boardPageInActive2}
                  tr={tr}
                  langActSecFun2={this.props.langActSecFun}
                  showRightSide={this.props.showRightSide}
                  rightSidePageNoFun={this.props.rightSidePageNoFun}
                  rightSidePageHead={this.props.rightSidePageHead}
                  rightSidePageHeadFun={this.props.rightSidePageHeadFun}
                  updateServiceState={this.props.creServiceToggle}
                  componentFormFun1={this.props.componentFormFun}
                  editServiceToggle={this.props.editServiceToggle}
                  statusOnClicked={this.props.statusOnClicked}
                  statusClicked={this.props.statusClicked}
                />
                <Link to="javascript:void(0)"
                  className="myBt cancel fillBtn ctrlKeyPrevent"
                  title={this.props.tr["Close"]}
                  href={void(0)}
                  onClick={() => {
                    this.onCloseClick();
                    this.props.langActSecFun(false);
                    this.props.getServiceCIs("0","test");
                    this.props.statusRendering("",false)
                  }}
                  ref={e => this.closeButton = e}
                >
                  <IoClose/>
                </Link>
              </div>
            </div>
                  ) : this.props.boardPageActive ? (
            <div
              className={
                this.props.creServiceState
                  ? "paHedFilter border-r margin-r-10 padding-r-10"
                  : "paHedFilter"
              }
            >
              <Link
                role="button"
                className="myBt plus fillBtn"
                title={this.props.tr["Create Service"]}
                onClick={() => {
                  this.props.updateServiceState(true, false);
                  this.props.showRightSide (false);
                  this.props.serDetailsIcon(true);
                  this.props.multiSelectOpenFun("sericeBoardStatus", false);
                  // this.props.setActiveKeyVal(2);
                  // this.props.rightSidePageHead("default");
                }}
                to={void(0)}
              >
                <HiPlus/>
              </Link>

              {this.props.copyOfferingToggleValue ? null : (
                <Link
                  role="button"
                  className={this.props.rightSidePageHead1 == "CopyOffering" ? "myBt fillBtn active" : "myBt fillBtn"}
                  title={this.props.tr["Copy Service"]}
                  onClick={() => {
                    this.props.copyOfferingToggle(false);
                    this.props.rightSidePageHead("CopyOffering");
                    this.props.showRightSide(true);
                    this.props.multiSelectOpenFun("sericeBoardStatus", false);
                  }}
                  to="javascript:void(0)"
                >
                  <ImCopy/>
                </Link>
              )}
              <Link
                role="button"
                className={this.props.rightSidePageHead1 == "ServiceSequencing" ? "myBt fillBtn active" : "myBt fillBtn"}
                title={this.props.tr["Service Sequencing"]}
                onClick={() => {
                  this.props.showRightSide(true);
                  this.props.copyOfferingToggle(false);
                  this.props.rightSidePageHead("ServiceSequencing");
                  this.props.multiSelectOpenFun("sericeBoardStatus", false);
                }}
                to="javascript:void(0)"
              >
                <BiSortDown/>
              </Link>
              {this.props.isClearAllFilter ? <div className="overlayDv"><Button title="Clear All Filters" role="button" onClick={() => { this.clearAllFilters(); this.props.multiSelectOpenFun("sericeBoardStatus", false); }} bsPrefix=" "  className="myBt clrbtn"><LuFilterX/></Button></div> : ""}							  
              <Button title={this.props.tr["Refresh"]} role="button" bsPrefix=" "  className="myBt" onClick={() => { this.refreshTable(); this.props.multiSelectOpenFun("sericeBoardStatus", false); }}>
                <HiOutlineRefresh className="refreshFlip" />
              </Button>
            </div>
          ) : (
            <div
              className={
                this.props.creServiceState
                  ? "paHedFilter border-r margin-r-10 padding-r-10"
                  : "paHedFilter"
              }
            >
              <a
                href="javascript:void(0)"
                onClick={() => {
                  this.props.rightSidePageNoFun(0);
                  this.props.rightSidePageHead("default");
                  this.props.updateServiceState(false, true);
                  this.props.componentFormFun1(false);
                  this.props.boardPageInActive2(false);
                  this.props.editServiceToggle(false);
                }}
                title={tr["Back to Service"]}
                className="myBt fillBtn"
              >
                <LiaStepBackwardSolid/>
              </a>
            </div>
          )}
          </div>
        </Col>
      </Row>
      </div>
    );
  }
}

let TaskStatusHamburger = class TaskStatusHamburger extends React.Component {
	constructor(props) {
		super(props);
		this.renderStatusOptions = this.renderStatusOptions.bind(this);
		this.currentAction = this.currentAction.bind(this);
	}

	handleClickOutside() {
		this.props.fun(false);
	}

	currentAction(value, name) {
    this.props.fun(false);
		this.props.setStatusName(name,true);
    this.props.setStatus(value);
	}

	renderStatusOptions(status) {
		return status.map((item, index) => {
			return (
				<ListGroup.Item bsClass="" key={index}>
					<Nav.Item title={item.text} onClick={() => {
						// this.currentAction(item.value, item.text), this.props.changeActionView(item.text), this.handleClickOutside();
						this.currentAction(item.value, item.text);
            this.props.statusOnClicked(true);
					}}>
            <i className="fa fa-angle-double-right" ></i>{item.text}
					</Nav.Item>
				</ListGroup.Item>
			);
		});
	}

	render() {
    
    let status = [];
		status.push({ value: this.props.serviceStatus, text: this.props.serviceStatus });
		switch (this.props.serviceStatus) {
			case "Draft":
				status.push({ value: "Proposed", text: "Proposed" });
				break
			case "Proposed":
				status.push({ value: "Deployed", text: "Deployed" });
				break
			case "Deployed":
				status.push({ value: "Suspended", text: "Suspended" });
				status.push({ value: "Obsolete", text: "Obsolete" });
				break
			case "Suspended":
				status.push({ value: "Deployed", text: "Deployed" });
				break
		}
		return (
			<div className="breakFixHamburger">
				<ListGroup bsClass="" className="myLinks" style={{ "top": "12px","width":"130px"}}>
					{this.renderStatusOptions(status)}
				</ListGroup>
			</div>
		);
	}
}
TaskStatusHamburger = OnClickOutside(TaskStatusHamburger);

// const mapStateToProps = (state,spcmReducer) => {
// 	let {
// 		createTaskFormValues, moduleList, taskStatusToEdit, taskStatusLoader
// 	} = state;
// 	let formData = createTaskFormValues.editFormValues;
// 	return {
// 		editFormValues: formData,
//     spcmReducer,
//     initialValues: spcmReducer.editId > 0 ? spcmReducer.editService : '',
// 	};
// };

const mapStateToProps = ({ spcmReducer }) => {
  return {
    spcmReducer,
    initialValues: spcmReducer.editId > 0 ? spcmReducer.editService : '',
  };
};

export default connect(mapStateToProps, { getAllServicesList,getServiceDetailsDataList,getServiceCIs })(Header);
