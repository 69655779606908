
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Alert } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import {
  loadSlaCompany,
  loadSlaModule,
  loadSlaService,
  loadSlaImpacted,
  loadSlaType,
  loadSlaTarget,
  loadSlaWrkSchedule,
  loadSlaHolSchedule,
  loadSlaDefEditDetails,
  loadSlaStatus,
} from "_Actions/sla/lovAction";
import { loadCICompany } from "_Actions/cmdb/lovAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import { GLOBAL } from "_Globals";
import LoadingIcons from "react-loading-icons";
import _ from "lodash";
import DropdownPlusTypeahead from "../common/dropdownPlusTypeahead";
let SelectedCompanyName = [];
class XsmSLADefEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchResultsDiv: false,
      slaStatus: "",
      slaTargetTime: "",
      slaName: "",
      slaCompanyName: "",
      slaModuleName: "",
      slaServiceName: "",
      slaImpactedName: "",
      slaType: "",
      slaTargetFor: "",
      slaWorkdaySchedule: "",
      slaHolidaySchedule: "",
      slaRetroactiveRequired: "",
      moduleName: "",
      ciServiceBased: 0,
      module: 1,
      CompanyName: [],
      inputValueCompany: "",
      typedValue: "",
      impactOnStart: "select",
      descriptionCount: 4000,
      describeIssue: "",
    };
    this.validateField = this.validateField.bind(this);
    this.validateFieldEdit = this.validateFieldEdit.bind(this);
    this.companySelection = this.companySelection.bind(this);
    this.setCompanErrorColor = this.setCompanErrorColor.bind(this);
    this.onCompanyInputChange = this.onCompanyInputChange.bind(this);
    this.onCrossClickCompany = this.onCrossClickCompany.bind(this);
    this.onSiteChanged = this.onSiteChanged.bind(this);
    this.impactStart = this.impactStart.bind(this);
    this.onDescriptionChange=this.onDescriptionChange.bind(this)
    GLOBAL.serviceBased = true;

    this.loadTargetForOptionsForModule =
      this.loadTargetForOptionsForModule.bind(this);
    this.getStatusList = this.getStatusList.bind(this);
  }
  searchResults(event) {
    this.setState({ searchResultsDiv: true });
  }
  callbackFun(id) {
    this.setState({ searchResultsDiv: id });
  }

  componentWillMount() {
    GLOBAL.slaRectroactiveToggle = "No";
    //alert(this.props.slaDefEditDetails.moduleId);
    //alert(this.props.companyId)
    // alert(this.props.slaDefEditDetails.moduleName)
    this.props.dispatch(change("XsmSLADefEditForm", "isCompanyChanged", false));
    if (
      this.props.slaDefEditDetails.moduleName == "Incident" ||
      this.props.slaDefEditDetails.moduleName == "Change" ||
      this.props.slaDefEditDetails.moduleName == "Problem"
    )
      this.setState({
        ciServiceBased: 1,
      });
    this.props.loadSlaStatus("SLADEF", "status");
    this.props.loadCICompany();
    this.props.loadSlaModule("SLADEF", "module");
    this.props.loadSlaStatus("SLADEF", "status");
    this.props.loadSlaType("SLADEF", "type");
    this.props.loadSlaService(this.props.companyId);
    this.props.loadSlaImpacted(this.props.companyId);
    // this.props.loadSlaTarget(module, "Target")

    this.props.loadSlaWrkSchedule(this.props.companyId);
    this.props.loadSlaHolSchedule(this.props.companyId);
    GLOBAL.slaNameErrorColor = "";
    GLOBAL.slaCompanyNameErrorColor = "";
    GLOBAL.slaModuleNameErrorColor = "";
    GLOBAL.slaStatusErrorColor = "";
    GLOBAL.slaServiceNameErrorColor = "";
    GLOBAL.slaImpactedNameErrorColor = "";
    GLOBAL.slaTypeErrorColor = "";
    GLOBAL.slaTargetForErrorColor = "";
    GLOBAL.slaTargetTimeErrorColor = "";
    GLOBAL.slaWorkdayScheduleErrorColor = "";
    GLOBAL.slaHolidayScheduleErrorColor = "";
    GLOBAL.slaRetroactiveRequiredErrorColor = "";
    GLOBAL.slaRetroactiveRequiredErrorColor = "";
    if (this.props.slaDefEditDetails.serviceBased == false) {
      GLOBAL.serviceBased = false;
      console.log(
        "this.props.slaDefEditDetails.serviceBased",
        this.props.slaDefEditDetails.serviceBased
      );
    } else {
      GLOBAL.serviceBased = true;
    }
    let CompanyData = [];
    let obj = {};
    obj.id = this.props.slaDefEditDetails.companyId;
    obj.label = this.props.slaDefEditDetails.companyName;
    CompanyData.push(obj);
    SelectedCompanyName = CompanyData;
    this.setState({ descriptionCount: this.props.slaDefEditDetails.description == null ? "4000" : 4000-this.props.slaDefEditDetails.description.length})

    this.setState({ inputValueCompany: SelectedCompanyName[0].label });
  }

  componentDidMount() {
    this.loadTargetForOptionsForModule(this.props.slaDefEditDetails.moduleName);
    this.setState({
      impactOnStart: this.props.slaDefEditDetails.impactOnStart,
    });
    this.setState({
      slaRetroactiveRequired: this.props.slaDefEditDetails.retroactiveRequired,
    });
    this.setState({
      moduleName: this.props.slaDefEditDetails.moduleName,
    });
  }

  loadTargetForOptionsForModule(module) {
    switch (module) {
      case "Change":
        this.props.loadSlaTarget("ChangeManagement", "Target");
        break;
      case "Fulfillment":
        this.props.loadSlaTarget("Fullfilment", "Target");
        break;
      case "Incident":
        this.props.loadSlaTarget("Breakfix", "Target");
        break;
      case "Problem":
        this.props.loadSlaTarget("Investigation", "Target");
        break;
      case "Service Availability":
        this.props.loadSlaTarget("Servive Availability", "Target");
        break;
      case "Task":
        this.props.loadSlaTarget("Task", "Target");
        break;
    }
  }

  // componentWillReceiveProps(nexProps) {

  // 	if (nexProps.slaDefEditDetails.moduleName == "Change") {
  // 		this.props.loadSlaTarget("ChangeManagement", "Target");
  // 	}
  // 	if (nexProps.slaDefEditDetails.moduleName == "Fulfillment") {

  // 		this.props.loadSlaTarget("Fullfilment", "Target");
  // 	}
  // 	if (nexProps.slaDefEditDetails.moduleName == "Incident") {

  // 		this.props.loadSlaTarget("Breakfix", "Target");
  // 		// this.setState({
  // 		// 	ciServiceBased:1
  // 		// })
  // 	}
  // 	if (nexProps.slaDefEditDetails.moduleName == "Problem") {

  // 		this.props.loadSlaTarget("Investigation", "Target");
  // 	}
  // 	if (nexProps.slaDefEditDetails.moduleName == "Service Availability") {

  // 		this.props.loadSlaTarget("Servive Availability", "Target");
  // 	}
  // 	if (nexProps.slaDefEditDetails.moduleName == "Task") {

  // 		this.props.loadSlaTarget("Task", "Target");
  // 	}
  // }

  validateField(fieldType, event) {
    switch (fieldType) {
      case "slaStatusValue":
        if (event.target.value == 0 || event.target.value == "") {
          this.setState({ slaStatus: "error" });
          GLOBAL.slaStatusErrorColor = "";
        } else {
          this.setState({ slaStatus: "" });
          GLOBAL.slaStatusErrorColor = "";
        }
        break;
      case "slaTargetTime":
        if (event.target.value == 0 || event.target.value == "") {
          this.setState({ slaTargetTime: "error" });
          GLOBAL.slaTargetTimeErrorColor = "";
        } else {
          this.setState({ slaTargetTime: "" });
          GLOBAL.slaTargetTimeErrorColor = "";
        }
        break;
      default:
    }
  }
  companySelection(companySelelected) {
    console.log("companySelelected", companySelelected);
    if (companySelelected.length > 0) {
      this.setState({ slaCompanyName: "" });
      SelectedCompanyName = companySelelected;
      this.props.dispatch(
        change(
          "XsmSLADefEditForm",
          "slaCompanyActualName",
          companySelelected[0].label
        )
      );
      this.props.dispatch(
        change("XsmSLADefEditForm", "slaCompanyName", companySelelected[0].id)
      );
      this.props.loadSlaService(companySelelected[0].id);
      this.props.loadSlaImpacted(companySelelected[0].id);
      this.props.loadSlaWrkSchedule(companySelelected[0].id);
      this.props.loadSlaHolSchedule(companySelelected[0].id);
      this.setState({ inputValueCompany: companySelelected[0].label });
      this.setState({ typedValue: companySelelected[0].label });
      this.props.dispatch(
        change(
          "XsmSLADefEditForm",
          "isCompanyChanged",
          companySelelected[0].id ===
            this.props.slaDefEditDetails.companyId + ""
            ? false
            : true
        )
      );
    } else {
      this.props.loadSlaService(0);
      this.props.loadSlaImpacted(0);
      this.props.loadSlaWrkSchedule(0);
      this.props.loadSlaHolSchedule(0);
      this.setState({ slaCompanyName: "error" });
      SelectedCompanyName = [];
      this.props.dispatch(
        change("XsmSLADefEditForm", "slaCompanyActualName", "")
      );
      this.props.dispatch(change("XsmSLADefEditForm", "slaCompanyName", 0));
    }
  }
  onCompanyInputChange(val) {
    this.setState({ inputValueCompany: val });
    this.setState({ typedValue: val });
  }
  onCrossClickCompany() {
    this.props.dispatch(
      change("XsmSLADefEditForm", "slaCompanyActualName", "")
    );
    this.props.dispatch(change("XsmSLADefEditForm", "slaCompanyName", 0));
    this.setState({ inputValueCompany: "" });
    SelectedCompanyName = [];
    this.props.loadSlaService(0);
    this.props.loadSlaImpacted(0);
    this.props.loadSlaWrkSchedule(0);
    this.props.loadSlaHolSchedule(0);
    this.setState({ slaCompanyName: "error" });
  }
  setCompanErrorColor() {
    console.log("company Error");
    if (SelectedCompanyName.length === 0) {
      this.setState({ slaCompanyName: "error" });
    } else {
      this.setState({ slaCompanyName: "" });
    }
    GLOBAL.slaCompanyNameErrorColor = "";
  }
  renderStatusActive(slaStatusDataActive) {
    //console.log("renderStatusActive")
    if (
      slaStatusDataActive.length == 0 ||
      slaStatusDataActive.length == undefined
    ) {
      return null;
    } else {
      //console.log("-------Deleting----");
      //console.log("IN");
      //	var deletedItem = slaStatusDataActive.splice(2,2);
      //	console.log(slaStatusDataActive);
      return slaStatusDataActive.map((objectData) => {
        //if(objectData.field1Key!="Draft" && objectData.field1Key!="draft")
        return (
          <option value={objectData.field1Value}>{objectData.field1Key}</option>
        );
      });
    }
  }

  renderStatusInActive(slaStatusDataActive) {
    //console.log("renderStatusActive")
    if (
      slaStatusDataActive.length == 0 ||
      slaStatusDataActive.length == undefined
    ) {
      return null;
    } else {
      //console.log("-------Deleting----");
      //console.log("IN");
      //	var deletedItem = slaStatusDataActive.splice(2,2);
      //	console.log(slaStatusDataActive);
      return slaStatusDataActive.map((objectData) => {
        if (objectData.field1Key != "Active")
          return (
            <option value={objectData.field1Value}>
              {objectData.field1Key}
            </option>
          );
      });
    }
  }

  renderStatus(slaStatusData) {
    console.log("renderStatus");
    console.log(slaStatusData);
    if (slaStatusData.length == 0 || slaStatusData.length == undefined) {
      return null;
    } else {
      return slaStatusData.map((objectData) => {
        return (
          <option value={objectData.field1Value}>{objectData.field1Key}</option>
        );
      });
    }
  }

  //=========edit form when status is draft(15)=============
  validateFieldEdit(fieldType, event) {
    switch (fieldType) {
      case "slaName":
        if (
          event.currentTarget.defaultValue == "" ||
          event.currentTarget.defaultValue.trim() == ""
        ) {
          this.setState({ slaName: "error" });
          GLOBAL.slaNameErrorColor = "";
        } else {
          this.setState({ slaName: "" });
          GLOBAL.slaNameErrorColor = "";
        }
        break;
      case "slaCompanyName":
        if (event.target.value == "") {
          this.setState({ slaCompanyName: "error" });
          GLOBAL.slaCompanyNameErrorColor = "";
        } else {
          this.setState({ slaCompanyName: "" });
          GLOBAL.slaCompanyNameErrorColor = "";
        }
        break;
      case "slaModuleName":
        if (event.target.value == 0 || event.target.value == "") {
          this.setState({ slaModuleName: "error" });
          GLOBAL.slaModuleNameErrorColor = "";
        } else {
          this.setState({ slaModuleName: "" });
          GLOBAL.slaModuleNameErrorColor = "";
        }
        break;
      case "slaStatusValue":
        if (event.target.value == 0 || event.target.value == "") {
          this.setState({ slaStatus: "error" });
          GLOBAL.slaStatusErrorColor = "";
        } else {
          this.setState({ slaStatus: "" });
          GLOBAL.slaStatusErrorColor = "";
        }
        break;
      case "slaServiceName":
        if (event.target.value == 0 || event.target.value == "") {
          this.setState({ slaServiceName: "error" });
          GLOBAL.slaServiceNameErrorColor = "";
        } else {
          this.setState({ slaServiceName: "" });
          GLOBAL.slaServiceNameErrorColor = "";
        }
        break;
      case "slaImpactedName":
        if (event.target.value == 0 || event.target.value == "") {
          this.setState({ slaImpactedName: "error" });
          GLOBAL.slaImpactedNameErrorColor = "";
        } else {
          this.setState({ slaImpactedName: "" });
          GLOBAL.slaImpactedNameErrorColor = "";
        }
        break;
      case "slaType":
        if (event.target.value == 0 || event.target.value == "") {
          this.setState({ slaType: "error" });
          GLOBAL.slaTypeErrorColor = "";
        } else {
          this.setState({ slaType: "" });
          GLOBAL.slaTypeErrorColor = "";
        }
        break;
      case "slaTargetFor":
        if (event.target.value == 0 || event.target.value == "") {
          this.setState({ slaTargetFor: "error" });
          GLOBAL.slaTargetForErrorColor = "";
        } else {
          this.setState({ slaTargetFor: "" });
          GLOBAL.slaTargetForErrorColor = "";
        }
        break;
      case "slaTargetTime":
        if (event.target.value == 0 || event.target.value == "") {
          this.setState({ slaTargetTime: "error" });
          GLOBAL.slaTargetTimeErrorColor = "";
          this.props.setTargetTimeError("");
        } else {
          this.setState({ slaTargetTime: "" });
          GLOBAL.slaTargetTimeErrorColor = "";
          this.props.setTargetTimeError("");
        }
        break;

      case "slaWorkdaySchedule":
        if (event.target.value == 0 || event.target.value == "") {
          this.setState({ slaWorkdaySchedule: "error" });
          GLOBAL.slaWorkdayScheduleErrorColor = "";
        } else {
          this.setState({ slaWorkdaySchedule: "" });
          GLOBAL.slaWorkdayScheduleErrorColor = "";
        }
        break;

      case "slaRetroactiveRequired":
        if (event.target.value == 0 || event.target.value == "") {
          this.setState({ slaRetroactiveRequired: "error" });
          GLOBAL.slaRetroactiveRequiredErrorColor = "";
        } else {
          this.setState({ slaRetroactiveRequired: "" });
          GLOBAL.slaRetroactiveRequiredErrorColor = "";
        }
        break;
        case "impactOnStart":
          if (event.target.value == 0 || event.target.value == "") {
            this.setState({ impactOnStart: "error" });
            GLOBAL.slaImpactErrorColor = "";
          } else {
            this.setState({ impactOnStart: "" });
            GLOBAL.slaImpactErrorColor = "";
          }
          break;
      default:
    }
  }
  onSiteChanged(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  impactStart(e) {
    console.log("impact dd", e.target.value);
    this.setState({ impactOnStart: e.target.value });
  }
  onDescriptionChange(e){
    this.setState({describeIssue:e.target.value, descriptionCount:4000-e.target.value.length})
}
  renderCompany(slaCompanyData) {
    //console.log(classData)
    if (slaCompanyData.length == 0 || slaCompanyData.length == undefined) {
      return null;
    }
    return slaCompanyData.map((objectData) => {
      return (
        <option value={objectData.field1Value}>{objectData.field1Key}</option>
      );
    });
  }

  renderModule(slaModuleData) {
    if (this.props.slaDefEditDetails.serviceBased == false) {
      GLOBAL.serviceBased = false;
      console.log(
        "this.props.slaDefEditDetails.serviceBased",
        this.props.slaDefEditDetails.serviceBased
      );
    } else {
      GLOBAL.serviceBased = true;
    }
    //console.log(classData)
    if (slaModuleData.length == 0 || slaModuleData.length == undefined) {
      return null;
    }
    return slaModuleData.map((objectData) => {
      return (
        <option value={objectData.field1Value}>{objectData.field1Key}</option>
      );
    });
  }

  renderService(slaServiceData) {
    //alert(slaServiceData.length);
    if (slaServiceData == null) {
      return null;
    }
    if (slaServiceData.length == 0 || slaServiceData.length == undefined) {
      return null;
    }
    return slaServiceData.map((objectData) => {
      return (
        <option value={objectData.OFFERING_ID}>
          {objectData.OFFERING_NAME}
        </option>
      );
    });
  }
  renderImpacted(slaServiceData) {
    //alert(slaServiceData.length);
    if (slaServiceData == null) {
      return null;
    }
    if (slaServiceData.length == 0 || slaServiceData.length == undefined) {
      return null;
    }
    return slaServiceData.map((objectData) => {
      return <option value={objectData.CI_ID}>{objectData.CI_NAME}</option>;
    });
  }
  renderTarget(slaTargetData) {
    //console.log(classData)
    //alert(slaServiceData.length);
    if (slaTargetData == null) {
      return null;
    }
    if (slaTargetData.length == 0 || slaTargetData.length == undefined) {
      return null;
    }
    return slaTargetData.map((objectData) => {
      return (
        <option value={objectData.field1Value}>{objectData.field1Key}</option>
      );
    });
  }

  renderTargetAgain(slaTargetData) {
    //alert(slaServiceData.length);
    if (slaTargetData == null) {
      return null;
    }
    if (slaTargetData.length == 0 || slaTargetData.length == undefined) {
      return null;
    }
    return slaTargetData.map((objectData) => {
      return (
        <option value={objectData.field1Value}>{objectData.field1Key}</option>
      );
    });
  }

  renderType(slaTypeData) {
    //console.log(classData)
    if (slaTypeData.length == 0 || slaTypeData.length == undefined) {
      return null;
    }
    return slaTypeData.map((objectData) => {
      return (
        <option value={objectData.field1Value}>{objectData.field1Key}</option>
      );
    });
  }

  renderWorkdaySchedule(workdayScheduleData) {
    if (workdayScheduleData == null) {
      return null;
    } else if (workdayScheduleData.data) {
      if (
        workdayScheduleData.data.length == 0 ||
        workdayScheduleData.data.length == undefined
      ) {
        return null;
      } else {
        return workdayScheduleData.data.map((objectData) => {
          return (
            <option value={objectData.scheduleId}>
              {objectData.scheduleName}
            </option>
          );
        });
      }
    } else {
      return null;
    }
  }

  renderHolidaySchedule(holidayScheduleData) {
    console.log("holidayScheduleData", holidayScheduleData);
    if (holidayScheduleData == null) {
      console.log("holidayScheduleData not exist");
    } else {
      if (holidayScheduleData.data == null) {
        return null;
      } else if (
        holidayScheduleData.data.length == 0 ||
        holidayScheduleData.data.length == undefined
      ) {
        return null;
      } else {
        return holidayScheduleData.data.map((objectData) => {
          return (
            <option value={objectData.scheduleId}>
              {objectData.scheduleName}
            </option>
          );
        });
      }
    }
  }

  setCompany(i) {
    //alert(i.target.value)
    if (i.target.value == "" || i.target.value == undefined) {
      this.props.loadSlaService("0");
      this.props.loadSlaImpacted("0");
      this.props.loadSlaWrkSchedule("0");
      this.props.loadSlaHolSchedule("0");
    } else {
      this.props.loadSlaService(i.target.value);
      this.props.loadSlaImpacted(i.target.value);
      //this.props.loadSlaWrkSchedule("1");
      //this.props.loadSlaHolSchedule("1");
      this.props.loadSlaWrkSchedule(i.target.value);
      this.props.loadSlaHolSchedule(i.target.value);
      //loadSlaWrkSchedule,loadSlaHolSchedule
    }
  }

  setModule(i) {
    // debugger
    let module = i.target[i.target.selectedIndex].text;
    if (i.target[i.target.selectedIndex].text == "Change") {
      module = "ChangeManagement";
      this.setState({
        ciServiceBased: 1,
        module: 1,
      });
    }
    if (i.target[i.target.selectedIndex].text == "Fulfillment") {
      module = "Fullfilment";
      this.setState({
        ciServiceBased: 0,
        module: 0,
      });
    }
    if (i.target[i.target.selectedIndex].text == "Incident") {
      module = "Breakfix";
      this.setState({
        ciServiceBased: 1,
        module: 0,
      });
    }
    if (i.target[i.target.selectedIndex].text == "Problem") {
      module = "Investigation";
      this.setState({
        ciServiceBased: 1,
        module: 1,
      });
    }
    if (i.target[i.target.selectedIndex].text == "Service Availability") {
      module = "Servive Availability";
      this.setState({
        ciServiceBased: 0,
        module: 0,
      });
    }
    //alert(i.target.value)
    if (i.target.value == "" || i.target.value == undefined) {
      this.props.loadSlaTarget("0");
    } else {
      // this.props.loadSlaTarget("target", i.target.value);
      this.props.loadSlaTarget(module, "Target");
      this.setState({
        moduleName: i.target.value,
      });
    }
  }

  getStatusList(status) {
    try {
      if (!(status && Array.isArray(status))) {
        return [];
      }
      const {
        initialValues: { slaStatusValue = "" },
      } = this.props;
      if (["5", "10"].includes(slaStatusValue.toString())) {
        return status.filter((item) => {
          return item["field1Value"] !== "15";
        });
      }
      return status;
    } catch (e) {
      return status;
    }
  }

  //========================================================
  render() {
    const { impactOnStart, slaRetroactiveRequired,moduleName } = this.state;
    //alert("========="+this.state.moduleName);

    let companyData = [];
    this.props.showFormValid();
    if (this.props.slaDefEditDetails.serviceBased == false) {
      GLOBAL.serviceBased = false;
    } else {
      GLOBAL.serviceBased = true;
    }
    this.props.ciCompany.forEach((item, i) => {
      let obj1 = {};
      obj1.id = item.field1Value;
      obj1.label = item.field1Key;
      companyData.push(obj1);
    });

    if (
      this.props.slaDefEditDetails.companyName == "" ||
      this.props.slaDefEditDetails.companyName == undefined ||
      this.props.ciCompany.length == 0 ||
      this.props.ciCompany.length == undefined ||
      this.props.slaModule.length == 0 ||
      this.props.slaModule.length == undefined ||
      this.props.slaType.length == 0 ||
      this.props.slaType.length == undefined ||
      this.props.slaDefEditDetails.goalType == "" ||
      this.props.slaDefEditDetails.goalType == undefined ||
      this.props.slaDefEditDetails.slaStatusId == "" ||
      this.props.slaDefEditDetails.slaStatusId == undefined
    ) {
      return (
        <LoadingIcons.Bars height="5em" stroke="transparent" fill="#06bcee" />
      );
    }
    let {
      error,
      handleSubmit,
      pristine,
      reset,
      submitting,
      slaWorkdaySchedule,
      slaHolidaySchedule,
      slaName,
      slaCompanyName,
      slaModuleName,
      slaStatusValue,
      slaServiceName,
      slaType,
      slaTargetFor,
      slaTargetTime,
    } = this.props;
    let slaSchedule = "";
    let slaEditScreen = "";
    if (this.props.slaStatusValueUI == 15) {
      slaSchedule = null;
    } else {
      //this.props.loadSlaWrkSchedule(this.props.slaDefEditDetails.companyId);
      //this.props.loadSlaHolSchedule(this.props.slaDefEditDetails.companyId);
      slaSchedule = (
        <Row>
          <Col md={6}>
            <Form.Group className="form-group">
              <Form.Label bsClass="">
                <span className="rStar"></span>
                {this.props.translator["Work Schedule"]}
              </Form.Label>
              {this.props.slaDefEditDetails.slaStatusId == 15 ||
              this.props.slaDefEditDetails.slaStatusId == 10 ? (
                <Field
                  name="slaWorkdaySchedule"
                  className={`form-control ${
                    this.state.slaWorkdaySchedule == ""
                      ? GLOBAL.slaWorkdayScheduleErrorColor
                      : this.state.slaWorkdaySchedule
                  }`}
                  component="select"
                  onBlur={(event) => {
                    this.validateFieldEdit("slaWorkdaySchedule", event);
                  }}
                  onChange={(event) => {
                    this.props.dispatch(
                      change(
                        "XsmSLADefEditForm",
                        "slaWorkdayScheduleName",
                        event.target[event.target.selectedIndex].text
                      )
                    );
                  }}
                >
                  <option value="">select</option>
                  {/*<option value="001">select1..</option>*/}
                  {this.renderWorkdaySchedule(this.props.slaWorkdaySchedules)}
                </Field>
              ) : (
                <Field
                  name="slaWorkdayScheduleName"
                  className="form-control"
                  component="input"
                  readOnly="true"
                />
              )}
            </Form.Group>
          </Col>
          <Col md={6}>
            {/*<Form.Group validationState={this.state.slaHolidaySchedule == '' ? GLOBAL.slaHolidayScheduleErrorColor : this.state.slaHolidaySchedule}>
				<Form.Label bsClass=""><span className="rStar"></span>{this.props.tr['Holiday Schedule']}</Form.Label>*/}
            <Form.Group>
              <Form.Label bsClass="">
                {this.props.translator["Holiday Schedule"]}
              </Form.Label>
              {this.props.slaDefEditDetails.slaStatusId == 15 ? (
                <Field
                  name="slaHolidaySchedule"
                  className="form-control"
                  component="select"
                  onChange={(event) => {
                    this.props.dispatch(
                      change(
                        "XsmSLADefEditForm",
                        "slaHolidayScheduleName",
                        event.target[event.target.selectedIndex].text
                      )
                    );
                  }}
                >
                  <option value="">select</option>
                  {/*<option value="002">select1..</option>*/}
                  {this.renderHolidaySchedule(this.props.slaHolidaySchedules)}
                </Field>
              ) : (
                <Field
                  name="slaHolidayScheduleName"
                  className="form-control"
                  component="input"
                  readOnly="true"
                />
              )}
            </Form.Group>
          </Col>
        </Row>
      );
    }
    //alert(this.props.slaDefActiveMandatory.exist)
    //console.log(this.props.slaDefActiveMandatory)
    //console.log(this.props.slaDefActiveMandatory.exist)

    let renderStatus = "";

    //alert(moduleName)
    //alert(this.props.slaDefEditDetails.slaStatusId)

    // console.log('*************', {state: this.state, props: this.props});

    const statusList = this.getStatusList(this.props.slaStatus);

    if (this.props.slaDefActiveMandatory.exist == "NO") {
      if (this.props.slaDefEditDetails.slaStatusId == "15") {
        //this.state.moduleName = this.props.slaDefEditDetails.moduleId
        if (this.state.moduleName == "") {
          if (this.props.slaDefEditDetails.moduleId == "30") {
            renderStatus = (
              <Field
                name="slaStatusValue"
                className={`form-control ${
                  this.state.slaStatus == ""
                    ? GLOBAL.slaStatusErrorColor
                    : this.state.slaStatus
                }`}
                component="select"
                onBlur={(event) => {
                  this.validateFieldEdit("slaStatusValue", event);
                }}
                onChange={(event) => {
                  this.props.dispatch(
                    change(
                      "XsmSLADefEditForm",
                      "slaStatusActualName",
                      event.target[event.target.selectedIndex].text
                    )
                  );
                }}
              >
                <option value="">select</option>
                {this.renderStatus(statusList)}
              </Field>
            );
          } else {
            renderStatus = (
              <Field
                name="slaStatusValue"
                className={`form-control ${
                  this.state.slaStatus == ""
                    ? GLOBAL.slaStatusErrorColor
                    : this.state.slaStatus
                }`}
                component="select"
                onBlur={(event) => {
                  this.validateFieldEdit("slaStatusValue", event);
                }}
                onChange={(event) => {
                  this.props.dispatch(
                    change(
                      "XsmSLADefEditForm",
                      "slaStatusActualName",
                      event.target[event.target.selectedIndex].text
                    )
                  );
                }}
              >
                <option value="">select</option>
                {this.renderStatusInActive(statusList)}
              </Field>
            );
          }
        } else {
          if (this.state.moduleName == "30") {
            renderStatus = (
              <Field
                name="slaStatusValue"
                className={`form-control ${
                  this.state.slaStatus == ""
                    ? GLOBAL.slaStatusErrorColor
                    : this.state.slaStatus
                }`}
                component="select"
                onBlur={(event) => {
                  this.validateFieldEdit("slaStatusValue", event);
                }}
                onChange={(event) => {
                  this.props.dispatch(
                    change(
                      "XsmSLADefEditForm",
                      "slaStatusActualName",
                      event.target[event.target.selectedIndex].text
                    )
                  );
                }}
              >
                <option value="">select</option>
                {this.renderStatus(statusList)}
              </Field>
            );
          } else {
            renderStatus = (
              <Field
                name="slaStatusValue"
                className={`form-control ${
                  this.state.slaStatus == ""
                    ? GLOBAL.slaStatusErrorColor
                    : this.state.slaStatus
                }`}
                component="select"
                onBlur={(event) => {
                  this.validateFieldEdit("slaStatusValue", event);
                }}
                onChange={(event) => {
                  this.props.dispatch(
                    change(
                      "XsmSLADefEditForm",
                      "slaStatusActualName",
                      event.target[event.target.selectedIndex].text
                    )
                  );
                }}
              >
                <option value="">select</option>
                {this.renderStatusInActive(statusList)}
              </Field>
            );
          }
        }
      } else {
        if (this.props.slaDefEditDetails.moduleId != "30") {
          renderStatus = (
            <Field
              name="slaStatusValue"
              className={`form-control ${
                this.state.slaStatus == ""
                  ? GLOBAL.slaStatusErrorColor
                  : this.state.slaStatus
              }`}
              component="select"
              onBlur={(event) => {
                this.validateFieldEdit("slaStatusValue", event);
              }}
              onChange={(event) => {
                this.props.dispatch(
                  change(
                    "XsmSLADefEditForm",
                    "slaStatusActualName",
                    event.target[event.target.selectedIndex].text
                  )
                );
              }}
            >
              <option value="">select</option>
              {this.renderStatusInActive(statusList)}
            </Field>
          );
        } else {
          renderStatus = (
            <Field
              name="slaStatusValue"
              className={`form-control ${
                this.state.slaStatus == ""
                  ? GLOBAL.slaStatusErrorColor
                  : this.state.slaStatus
              }`}
              component="select"
              onBlur={(event) => {
                this.validateFieldEdit("slaStatusValue", event);
              }}
              onChange={(event) => {
                this.props.dispatch(
                  change(
                    "XsmSLADefEditForm",
                    "slaStatusActualName",
                    event.target[event.target.selectedIndex].text
                  )
                );
              }}
            >
              <option value="">select</option>
              {this.renderStatus(statusList)}
            </Field>
          );
        }
      }
    } else {
      renderStatus = (
        <Field
          name="slaStatusValue"
          className={`form-control ${
            this.state.slaStatus == ""
              ? GLOBAL.slaStatusErrorColor
              : this.state.slaStatus
          }`}
          component="select"
          onBlur={(event) => {
            this.validateField("slaStatusValue", event);
          }}
          onChange={(event) => {
            this.props.dispatch(
              change(
                "XsmSLADefEditForm",
                "slaStatusActualName",
                event.target[event.target.selectedIndex].text
              )
            );
          }}
        >
          <option value="">select</option>
          {this.renderStatusActive(statusList)}
        </Field>
      );
    }
    {
      /*if(this.props.slaDefActiveMandatory.exist == "NO" && (this.props.slaDefEditDetails.moduleId !="30" || this.state.moduleName !="30")){
			alert("m1")
			renderStatus=<Field name="slaStatusValue" className="form-control" component="select" onBlur = {(event)=>{this.validateFieldEdit('slaStatusValue',event)}} onChange = {(event)=>{this.props.dispatch(change("XsmSLADefEditForm", "slaStatusActualName", event.target[event.target.selectedIndex].text));}}>
			 <option value="">select</option>
			 {this.renderStatusInActive(this.props.slaStatus)}
			 </Field>
			//alert("Yes");
		}else if(this.props.slaDefActiveMandatory.exist == "NO" && (this.props.slaDefEditDetails.moduleId =="30" || this.state.moduleName=="30")){
			alert("m11")
			renderStatus=<Field name="slaStatusValue" className="form-control" component="select" onBlur = {(event)=>{this.validateFieldEdit('slaStatusValue',event)}} onChange = {(event)=>{this.props.dispatch(change("XsmSLADefEditForm", "slaStatusActualName", event.target[event.target.selectedIndex].text));}}>
			 <option value="">select</option>
			 {this.renderStatus(this.props.slaStatus)}
			 </Field>
		}else{
			if(this.props.slaDefEditDetails.slaStatusId==15 ){
				alert("wedfsdfasdf")
				renderStatus=<Field name="slaStatusValue" className="form-control" component="select" onBlur = {(event)=>{this.validateFieldEdit('slaStatusValue',event)}} onChange = {(event)=>{this.props.dispatch(change("XsmSLADefEditForm", "slaStatusActualName", event.target[event.target.selectedIndex].text));}}>
				 <option value="">select</option>
				 {this.renderStatus(this.props.slaStatus)}
				 </Field>
			}else{
				renderStatus=<Field name="slaStatusValue" className="form-control" component="select" onBlur = {(event)=>{this.validateField('slaStatusValue',event)}} onChange = {(event)=>{this.props.dispatch(change("XsmSLADefEditForm", "slaStatusActualName", event.target[event.target.selectedIndex].text));}}>
		 		 <option value="">select</option>
		 		 {this.renderStatusActive(this.props.slaStatus)}
			 </Field>
			}
		}*/
    }

    return (
      <div>
        {error == undefined || error == null || error == "" ? null : (
          <Alert variant="danger" className="errorMessageSec">
            <p>{error}</p>
          </Alert>
        )}
        {/*Error message section, please use classes like success, danger, warning, info*/}
        {/*<Alert variant="danger" className="errorMessageSec">
			 <p>The form has missing required fields, please revalidate the highlighted fields and update the form.</p>
		 </Alert>*/}

        <div className="borderForm bFormDiv">
          <Row>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label bsClass="">
                  <span className="rStar"></span>
                  {this.props.translator["Name"]}
                </Form.Label>
                {this.props.slaDefEditDetails.slaStatusId == 15 ? (
                  <Field
                    name="slaName"
                    className={`form-control ${
                      this.state.slaName == ""
                        ? GLOBAL.slaNameErrorColor
                        : this.state.slaName
                    }`}
                    component="input"
                    onBlur={(event) => {
                      this.validateFieldEdit("slaName", event);
                    }}
                  />
                ) : (
                  <Field
                    name="slaName"
                    className="form-control"
                    component="input"
                    readOnly="true"
                  />
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label bsClass="">
                  <span className="rStar"></span>
                  {this.props.translator["Consumer Company"]}
                </Form.Label>
                {this.props.slaDefEditDetails.slaStatusId == 15 ? (
                  <Field
                    component={DropdownPlusTypeahead}
                    onSelection={this.companySelection}
                    setErrorColor={this.setCompanErrorColor}
                    name="slaCompanyName"
                    id="company"
                    className="form-control"
                    options={companyData}
                    selectedOptions={SelectedCompanyName}
                    onInputChange={this.onCompanyInputChange}
                    inputValue={this.state.inputValueCompany}
                    onCrossClick={this.onCrossClickCompany}
                    typedValue={this.state.typedValue}
                    isError={
                      this.state.slaCompanyName == ""
                        ? GLOBAL.slaCompanyNameErrorColor
                        : this.state.slaCompanyName
                    }
                  />
                ) : (
                  <Field
                    name="slaCompanyActualName"
                    className="form-control"
                    component="input"
                    readOnly="true"
                  />
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label bsClass="">
                  <span className="rStar"></span>
                  {this.props.translator["Module"]}
                </Form.Label>
                {this.props.slaDefEditDetails.slaStatusId == 15 ? (
                  <Field
                    name="slaModuleName"
                    className={`form-control ${
                      this.state.slaModuleName == ""
                        ? GLOBAL.slaModuleNameErrorColor
                        : this.state.slaModuleName
                    }`}
                    component="select"
                    onBlur={(event) => {
                      this.validateFieldEdit("slaModuleName", event);
                    }}
                    onChange={(event) => {
                      this.setModule(event);
                      this.props.dispatch(
                        change(
                          "XsmSLADefEditForm",
                          "slaModuleActualName",
                          event.target[event.target.selectedIndex].text
                        )
                      );
                    }}
                  >
                    <option value="">select</option>
                    {this.renderModule(this.props.slaModule)}
                  </Field>
                ) : (
                  <Field
                    name="slaModuleActualName"
                    className="form-control"
                    component="input"
                    readOnly="true"
                  />
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label bsClass="">
                  <span className="rStar"></span>
                  {this.props.translator["Status"]}
                </Form.Label>
                {renderStatus}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            {/* {(this.props.slaDefEditDetails.serviceBased==true||(this.props.slaDefEditDetails.moduleName=="Incident"))?
						<Col md={6}>
							<Form.Group validationState={this.state.slaServiceName == '' ? GLOBAL.slaServiceNameErrorColor : this.state.slaServiceName}>
								<Form.Label bsClass="">{this.props.slaDefEditDetails.serviceBased?<span className="rStar" />:null}{this.props.translator['Service']}</Form.Label>
								{this.props.slaDefEditDetails.slaStatusId == 15 ?
									<Field name="slaServiceName" className="form-control" component="select" onBlur={(event) => { this.validateFieldEdit('slaServiceName', event) }} onChange={(event) => { this.props.dispatch(change("XsmSLADefEditForm", "slaServiceActualName", event.target[event.target.selectedIndex].text)); }}>
										<option value="">select</option>
										{this.renderService(this.props.slaService)}
									</Field> : <Field name="slaServiceActualName" className="form-control" component="input" readOnly="true" />}
							</Form.Group>
						</Col>
						:null } */}
            {/* {this.state.ciServiceBased==1?
						<Col md={6}>
          <Form.Group validationState={this.state.slaImpactedName == '' ? GLOBAL.slaImpactedNameErrorColor : this.state.slaImpactedName}>
            <Form.Label bsClass="">{this.props.slaDefEditDetails.serviceBased==false?<span className="rStar" />:null}{this.props.translator['Impacted CI']}</Form.Label>

			{this.props.slaDefEditDetails.slaStatusId == 15 ?
            <Field name="slaImpactedName"  className="form-control" component="select" onBlur={(event) => { this.validateFieldEdit('slaImpactedName', event) }} onChange={(event) => { this.props.dispatch(change("XsmSLADefEditForm", "slaImpactedActualName", event.target[event.target.selectedIndex].text)); }}>

			  <option value="">select</option>
              {this.renderImpacted(this.props.slaImpacted)}
            </Field> : <Field name="slaImpactedActualName" className="form-control" component="input" readOnly="true" />}
          </Form.Group>
                 </Col>
					:null } */}
            {/* {
						this.state.ciServiceBased==0?
						<Col md={6}>
							<Form.Group validationState={this.state.slaServiceName == '' ? GLOBAL.slaServiceNameErrorColor : this.state.slaServiceName}>
								<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Service']}</Form.Label>
								{this.props.slaDefEditDetails.slaStatusId == 15 ?
									<Field name="slaServiceName" className="form-control" component="select" onBlur={(event) => { this.validateFieldEdit('slaServiceName', event) }} onChange={(event) => { this.props.dispatch(change("XsmSLADefEditForm", "slaServiceActualName", event.target[event.target.selectedIndex].text)); }}>
										<option value="">select</option>
										{this.renderService(this.props.slaService)}
									</Field> : <Field name="slaServiceActualName" className="form-control" component="input" readOnly="true" />}
							</Form.Group>
						</Col>:null
					} */}
            {this.state.ciServiceBased == 0 ? (
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">
                    <span className="rStar"></span>
                    {this.props.translator["Type"]}
                  </Form.Label>
                  {this.props.slaDefEditDetails.slaStatusId == 15 ? (
                    <Field
                      name="slaTypeId"
                      className={`form-control ${
                        this.state.slaType == ""
                          ? GLOBAL.slaTypeErrorColor
                          : this.state.slaType
                      }`}
                      component="select"
                      onBlur={(event) => {
                        this.validateFieldEdit("slaTypeId", event);
                      }}
                      onChange={(event) => {
                        this.props.dispatch(
                          change(
                            "XsmSLADefEditForm",
                            "slaTypeName",
                            event.target[event.target.selectedIndex].text
                          )
                        );
                      }}
                    >
                      <option value="">select</option>
                      {this.renderType(this.props.slaType)}
                    </Field>
                  ) : (
                    <Field
                      name="slaTypeActualName"
                      className="form-control"
                      component="input"
                      readOnly="true"
                    />
                  )}
                </Form.Group>
              </Col>
            ) : null}
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label bsClass="">
                  <span className="rStar"></span>
                  {this.props.translator["Target For"]}
                </Form.Label>
                {this.props.slaDefEditDetails.slaStatusId == 15 ? (
                  <Field
                    name="slaTargetFor"
                    className={`form-control ${
                      this.state.slaTargetFor == ""
                        ? GLOBAL.slaTargetForErrorColor
                        : this.state.slaTargetFor
                    }`}
                    component="select"
                    onBlur={(event) => {
                      this.validateFieldEdit("slaTargetFor", event);
                    }}
                    onChange={(event) => {
                      this.props.dispatch(
                        change(
                          "XsmSLADefEditForm",
                          "slaTargetForName",
                          event.target[event.target.selectedIndex].text
                        )
                      );
                    }}
                  >
                    <option value="">select</option>
                    {this.renderTargetAgain(this.props.slaTarget)}
                  </Field>
                ) : (
                  <Field
                    name="slaTargetForActualName"
                    className="form-control"
                    component="input"
                    readOnly="true"
                  />
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label bsClass="">
                  <span className="rStar"></span>
                  {this.props.translator["Target Time(Mins)"]}
                </Form.Label>
                <Field
                  name="slaTargetTime"
                  className={`form-control ${
                    this.state.slaTargetTime == ""
                      ? GLOBAL.slaTargetTimeErrorColor
                      : this.state.slaTargetTime
                  } ${this.props.targetTimeError}`}
                  component="input"
                  onBlur={(event) => {
                    this.validateFieldEdit("slaTargetTime", event);
                  }}
                ></Field>
              </Form.Group>
            </Col>
            {/* </Row> */}
            {/* {slaSchedule} */}
            {/* <Row> */}
            {!(this.props.slaStatusValueUI == 15) && (
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">
                    <span className="rStar"></span>
                    {this.props.translator["Work Schedule"]}
                  </Form.Label>
                  {this.props.slaDefEditDetails.slaStatusId == 15 ||
                  this.props.slaDefEditDetails.slaStatusId == 10 ? (
                    <Field
                      name="slaWorkdaySchedule"
                      className={`form-control ${
                        this.state.slaWorkdaySchedule == ""
                          ? GLOBAL.slaWorkdayScheduleErrorColor
                          : this.state.slaWorkdaySchedule
                      }`}
                      component="select"
                      onBlur={(event) => {
                        this.validateFieldEdit("slaWorkdaySchedule", event);
                      }}
                      onChange={(event) => {
                        this.props.dispatch(
                          change(
                            "XsmSLADefEditForm",
                            "slaWorkdayScheduleName",
                            event.target[event.target.selectedIndex].text
                          )
                        );
                      }}
                    >
                      <option value="">select</option>
                      {/*<option value="001">select1..</option>*/}
                      {this.renderWorkdaySchedule(
                        this.props.slaWorkdaySchedules 
                      )}
                    </Field>
                  ) : (
                    <Field
                      name="slaWorkdayScheduleName"
                      className="form-control"
                      component="input"
                      readOnly="true"
                    />
                  )}
                </Form.Group>
              </Col>
            )}
            {!(this.props.slaStatusValueUI == 15) && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label bsClass="">
                    {this.props.translator["Holiday Schedule"]}
                  </Form.Label>
                  {this.props.slaDefEditDetails.slaStatusId == 15 ? (
                    <Field
                      name="slaHolidaySchedule"
                      className="form-control"
                      component="select"
                      onChange={(event) => {
                        this.props.dispatch(
                          change(
                            "XsmSLADefEditForm",
                            "slaHolidayScheduleName",
                            event.target[event.target.selectedIndex].text
                          )
                        );
                      }}
                    >
                      <option value="">select</option>
                      {/*<option value="002">select1..</option>*/}
                      {this.renderHolidaySchedule(
                        this.props.slaHolidaySchedules
                      )}
                    </Field>
                  ) : (
                    <Field
                      name="slaHolidayScheduleName"
                      className="form-control"
                      component="input"
                      readOnly="true"
                    />
                  )}
                </Form.Group>
              </Col>
            )}
            {/* <Col md={6}>
							<Form.Group validationState={this.state.slaRetroactiveRequired == '' ? GLOBAL.slaRetroactiveRequiredErrorColor : this.state.slaRetroactiveRequired}>
								<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Retroactive Required']}</Form.Label>
								{this.props.slaDefEditDetails.slaStatusId == 15 ? <Field name="slaRetroactiveRequired" className="form-control" component="select" onBlur={(event) => { this.validateFieldEdit('slaRetroactiveRequired', event) }} onChange={(event) => { this.props.dispatch(change("xsmSlaDefForm", "slaRetroactiveRequired", event.target[event.target.selectedIndex].text)); }}>
									<option value="">select</option>
									<option value="Yes">Yes</option>
									<option value="No">No</option>
								</Field> : <Field name="slaRetroactiveRequired" className="form-control" component="input" readOnly="true"></Field>}
							</Form.Group>
						</Col> */}
            {this.state.ciServiceBased == 1 ? (
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">
                    <span className="rStar"></span>
                    {this.props.translator["Type"]}
                  </Form.Label>
                  {this.props.slaDefEditDetails.slaStatusId == 15 ? (
                    <Field
                      name="slaTypeId"
                      className={`form-control ${
                        this.state.slaType == ""
                          ? GLOBAL.slaTypeErrorColor
                          : this.state.slaType
                      }`}
                      component="select"
                      onBlur={(event) => {
                        this.validateFieldEdit("slaTypeId", event);
                      }}
                      onChange={(event) => {
                        this.props.dispatch(
                          change(
                            "XsmSLADefEditForm",
                            "slaTypeName",
                            event.target[event.target.selectedIndex].text
                          )
                        );
                      }}
                    >
                      <option value="">select</option>
                      {this.renderType(this.props.slaType)}
                    </Field>
                  ) : (
                    <Field
                      name="slaTypeActualName"
                      className="form-control"
                      component="input"
                      readOnly="true"
                    />
                  )}
                </Form.Group>
              </Col>
            ) : null}
            <Col md={6}>
              <Form.Group>
                <Form.Label bsClass="">
                  {this.props.translator["Retroactive Start"]}
                </Form.Label>

                <div className="clear">
                  <Field
                    name="slaRetroactiveRequired"
                    type="radio"
                    component="input"
                    value="Yes"
                    className="mx-2"
                    checked={
                      slaRetroactiveRequired === "Yes"
                    }
                    onChange={(event) => {
                      this.onSiteChanged(event);
                    }}
                  />
                  Yes
                  <Field
                    name="slaRetroactiveRequired"
                    type="radio"
                    component="input"
                    value="No"
                    className="mx-2"
                    checked={
                      slaRetroactiveRequired === "No"
                    }
                    onChange={(event) => {
                      this.onSiteChanged(event);
                    }}
                  />
                  No
                </div>
              </Form.Group>
            </Col>
            <Col md={6}>&nbsp;</Col>
            <Col md={6}>
              <Form.Group>
                {(moduleName == "Problem" ||
                  moduleName == "Incident" || moduleName == "5" ||
                  moduleName == "10") &&
                  slaRetroactiveRequired ===
                    "Yes" && (
                    <div className="form-group">
                      <span className="rStar"></span>
                      <Form.Label bsClass="">
                        <label>Impact on Start</label>
                      </Form.Label>
                      <Form.Group className="btMargin10">
                        <Field
                          component="select"
                          className={`form-control ${
                            this.state.impactOnStart == ""
                              ? GLOBAL.slaImpactErrorColor
                              : this.state.impactOnStart
                          }`}
                          name="impactOnStart"
                          value={impactOnStart}
                          defaultValue={impactOnStart}
                          onBlur={slaRetroactiveRequired === "No"? "":(event) => {
                            this.validateFieldEdit("impactOnStart", event);
                          }}
                          onChange={(event) => {
                            this.impactStart(event);
                          }}
                        >
                          <option value="">select</option>
                          <option value="Always">Always</option>
                          <option value="Downgrade">Priority Downgrade</option>
                        </Field>
                      </Form.Group>
                    </div>
                  )}
              </Form.Group>
            </Col>
            <Col>
            <Form.Group className='form-group'>
               <Form.Label bsPrefix=' '>{this.props.translator["Description"]} </Form.Label>
               <Field 
               name="description" 
               component="textarea" 
               className={`form-control`} 
               maxLength="4000" 
               rows="4" 
               onChange={(e)=>{this.onDescriptionChange(e)}}
               />
               <p className="charLeft" style={{textAlign:"right","fontSize":"11px"}}>({this.props.translator['Characters Remaining']}: {(this.state.descriptionCount)}/4000)</p>
               </Form.Group>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

XsmSLADefEditForm = reduxForm({
  form: "XsmSLADefEditForm",
  destroyOnUnmount: false,
  enableReinitialize: true,
})(XsmSLADefEditForm);

let selector = formValueSelector("XsmSLADefEditForm");
XsmSLADefEditForm = connect((state) => {
  let slaStatusValueUI = selector(state, "slaStatusValue");
  return {
    slaStatusValueUI,
  };
})(XsmSLADefEditForm);

export function mapStateToProps({
  slaDefEditDetails,
  slaName,
  slaCompanyName,
  slaCompanyActualName,
  slaModuleName,
  slaModuleActualName,
  slaStatusName,
  slaStatusValue,
  slaServiceName,
  slaImpactedName,
  slaServiceActualName,
  slaImpactedActualName,
  slaTypeId,
  slaTypeActualName,
  slaTargetFor,
  slaTargetForActualName,
  slaTargetTime,
  slaWorkdaySchedule,
  slaHolidaySchedule,
  slaWorkdayScheduleName,
  slaHolidayScheduleName,
  ciCompany,
  slaModule,
  slaStatus,
  slaService,
  slaType,
  slaTarget,
  slaImpacted,
  slaWorkdaySchedules,
  slaHolidaySchedules,
  spcmReducer,
  slaRetroactiveRequired,
  slaDefActiveMandatory,
  impactOnStart,
  description
}) {
  return {
    initialValues: {
      slaName: slaDefEditDetails.slaName,
      slaCompanyName: slaDefEditDetails.companyId,
      slaCompanyActualName: slaDefEditDetails.companyName,
      slaModuleName: slaDefEditDetails.moduleId,
      slaModuleActualName: slaDefEditDetails.moduleName,
      slaStatusName: slaDefEditDetails.slaStatus,
      slaStatusValue: slaDefEditDetails.slaStatusId,
      slaServiceName: slaDefEditDetails.serviceId,
      slaServiceActualName: slaDefEditDetails.serviceName,
      slaImpactedActualName: slaDefEditDetails.ciName,
      slaTypeId: slaDefEditDetails.slaTypeId,
      slaTypeActualName: slaDefEditDetails.slaType,
      slaTargetFor: slaDefEditDetails.goalTypeId,
      slaTargetForActualName: slaDefEditDetails.goalType,
      slaTargetTime: slaDefEditDetails.goalTime,
      slaWorkdaySchedule: slaDefEditDetails.slaWorkdayScheduleId,
      slaHolidaySchedule: slaDefEditDetails.slaHolidayScheduleId,
      slaWorkdayScheduleName: slaDefEditDetails.slaWorkdaySchedule,
      slaHolidayScheduleName: slaDefEditDetails.slaHolidaySchedule,
      slaRetroactiveRequired: slaDefEditDetails.retroactiveRequired,
      slaImpactedName: slaDefEditDetails.ciId,
      impactOnStart:slaDefEditDetails.impactOnStart,
      description:slaDefEditDetails.description
    },

    slaDefEditDetails,
    slaName,
    slaCompanyName,
    slaCompanyActualName,
    slaModuleName,
    slaModuleActualName,
    slaStatusName,
    slaStatusValue,
    slaServiceName,
    slaImpactedName,
    slaServiceActualName,
    slaImpactedActualName,
    slaTypeId,
    slaTypeActualName,
    slaTargetFor,
    slaTargetForActualName,
    slaTargetTime,
    slaWorkdaySchedule,
    slaHolidaySchedule,
    slaWorkdayScheduleName,
    slaHolidayScheduleName,
    ciCompany,
    slaModule,
    slaStatus,
    slaService,
    slaType,
    slaTarget,
    slaImpacted,
    slaWorkdaySchedules,
    slaHolidaySchedules,
    tr: spcmReducer.tr,
    slaRetroactiveRequired,
    slaDefActiveMandatory,
    impactOnStart,
    description
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadCICompany,
      loadSlaModule,
      loadSlaStatus,
      loadSlaService,
      loadSlaImpacted,
      loadSlaType,
      loadSlaTarget,
      loadSlaWrkSchedule,
      loadSlaHolSchedule,
      loadSlaDefEditDetails
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(XsmSLADefEditForm);
//export default XsmSLADefEditForm;
