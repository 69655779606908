
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import axios from 'axios';
const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

export const renderOptions = (type = '', data) => {
    if(!(data && data.length)){
        return [];
    }
    let label = 'label';
    let value = 'value';
    switch(type){
        case 'riskCategory':
            label = 'riskCategoryName';
            value = 'riskCategory';
            break;
        case 'riskType':
            label = 'riskTypeName';
            value = 'riskType';
            break;
    }
    return data.map((item, i) => {
        let itemLabel = '';
        let itemValue = '';
        if(type == 'riskMethodology' || type == 'riskCategory' || type == 'riskType'){
            itemLabel = item[label];
            itemValue = item[value];
        }
        else if(type == 'riskProbability' || type == 'riskImpact' || type == 'riskConfidence'){
            itemLabel = itemValue= item;
        }
        return (
            <option key={type + '_' + i} value={itemValue}>{itemLabel}</option>
        );
    });
};

const restructureOptions = (type = '', data = []) => {
    try{
        // if(!(data && data.length)){
        //     return [];
        // }
        let label = 'riskAssessmentMethodology';
        let value = 'methodologyId';
        let arr = structuredClone(data);
        switch(type){
            case 'RiskCategory':
            case 'RiskType':
            case 'Probability':
            case 'Impact':
            case 'Confidence':
                label = 'answer';
                // value = 'answerId';
                value = 'answer';
                arr = structuredClone(data[0]['riskAnswer']);
                break;
        }
        return arr.map(item => {
            let obj = {};
            obj['label'] = item[label] || item['label'] || '';
            if(type == 'RiskMethodology'){
                if(item['methodologyId'] == '1'){
                    obj['value'] = item['methodologyId'] || '';
                }
                else{
                    obj['value'] = (item['methodologyId'] + '|' + item['id']) || '';
                }
            }
            else{
                obj['value'] = item[value] || item['value'] || '';
            }
            return obj;
        });
    }catch(e){
        return [];
    }
}

export const getRiskMethodology = (companyId, isLegacyExist) => {
    try{
        return api.get('/getRiskAssessmentDetails?type=methodology&companyId=' + companyId).then((response)=>{
            let arr = [];
            if(response && response.data && Array.isArray(response.data) && response.data.length){
                arr = (response.data).filter(item => {
                    return (isLegacyExist == true) ? true : !item['isLegacy'];
                });
            }
            return Promise.resolve(restructureOptions('RiskMethodology', arr));
        }).catch(error => {
            return Promise.reject([]);
        });
    }catch(e){
        return Promise.reject([]);
    }
}

export const getRiskCategoryTypeList = (companyId) => {
    try{
        return api.get('/getRiskAssessmentDetails?type=Category-Type&companyId=' + companyId).then((response)=>{
            return Promise.resolve((response.data && response.data.length) ? response.data : []);
        }).catch(error => {
            return Promise.reject([]);
        });
    }catch(e){
        return Promise.reject([]);
    }
}

export const getProbabilityImpactConfidenceList = (companyId, category, type) => {
    try{
        return api.get('/getRiskAssessmentDetails?type=Probability-Impact-Confidence&categoryId=' + category + '&typeId=' + type + '&companyId=' + companyId).then((response)=>{
            return Promise.resolve(response.data);
        }).catch(error => {
            return Promise.reject({});
        });
    }catch(e){
        return Promise.reject({});
    }
}

export const getQuestionaireList = (companyId, riskMethodologyId) => {
    try{
        return api.get('/getRiskAssessmentDetails?type=QuestionaireList&companyId=' + companyId + '&riskMethodologyId=' + riskMethodologyId).then((response)=>{
            return Promise.resolve((response && response.data && Array.isArray(response.data)) ? response.data : []);
        }).catch(error => {
            return Promise.reject([]);
        });
    }catch(e){
        return Promise.reject([]);
    }
}

export const renderResponseOptions = (options) => {
    try{
        if(!(options && options.length)){
            return null;
        }
        return options.map((item, i) => {
            return (
                <option key={'questionaireResponseOptions_' + i} value={item['answer'] + '|' + item['weightage']}>{item['answerName']}</option>
            );
        });
    }catch(e){
        return null;
    }
}

export const constructRiskAssessmentPayload = (methodology = '', data = [], dataChangeValidate = false) => {
    let riskArr = [];
    if(!(data && data.length)){
        return [];
    }
    switch(methodology){
      case '1':
      case 1:  
      {
        data.forEach(item => {
          let obj = {
            'methodologyId': methodology,
            'methodologyName': 'GBP',
            'riskCategory': item['riskCategory'],
            'riskCategoryName': item['riskCategoryName'],
            'riskType': item['riskType'],
            'riskTypeName': item['riskTypeName'],
            'probability': item['probability'],
            'impact': item['impact'],
            'confidence': item['confidence'],
            'riskDescription': item['riskDescription'],
            'controlMeasures': item['controlMeasures'],
            'overallRisk': item['overallRisk'] || ''
          };
          riskArr.push(obj);
        });
        break;
      }
      case '2':
      case 2:
      {
        let responseKey = 'response';
        let responseNameKey = 'responseName';
        if(dataChangeValidate == true){
            responseKey = 'answer';
            responseNameKey = 'answerName';
        }
        data.forEach(item => {
          let obj = {
            'methodologyId': methodology,
            'methodologyName': 'Questionnaire',
            'question': item['question'],
            'questionName': item['questionName'],
            'answer': item[responseKey] || '',
            'answerName': item[responseNameKey] || '',
            'riskMethodologyId': item['riskMethodologyId'] || ''
          };
          riskArr.push(obj);
        });
        break;
      }
    }
    return riskArr;
}

export const getTypeForCategory = (data = [], categorySelected = '') => {
    try{
        const category = data.find(item => item['riskCategory'] == categorySelected);
        return (category['riskCategoryType'] && category['riskCategoryType'].length ? category['riskCategoryType'] : []);
    }catch(e){
        return [];
    }
}

export const getOverallRiskGBP = (companyId, category, type, probability, impact, confidence) => {
    try{
        return api.get('/getRiskAssessmentDetails?type=OverallRiskGBP&categoryId=' + category + '&typeId=' + type + '&probability=' + probability + '&impact=' + impact + '&confidence=' + confidence + '&companyId=' + companyId).then((response)=>{
            return Promise.resolve(response.data);
        }).catch(error => {
            return Promise.reject({});
        });
    }catch(e){
        return Promise.reject({});
    }
}

export const getResidualRisk = (methodology = '', data = null, companyId = '') => {
    try{
        switch(methodology){
            case '1':
            case 1:
                if(!(methodology && data && data.length)){
                    return '';
                }
                let HighRisk = data.find(item => item['overallRisk'] == 'High');
                if(HighRisk){
                    return 'High';
                }
                let MediumRisk = data.find(item => item['overallRisk'] == 'Medium');
                if(MediumRisk){
                    return 'Medium';
                }
                let LowRisk = data.find(item => item['overallRisk'] == 'Low');
                if(LowRisk){
                    return 'Low';
                }
                return 'NA';
            case '2':
            case 2:
                // return new Promise((resolve, reject) => {
                //     setTimeout(() => {
                //       resolve('Medium');
                //     }, 2000);
                //   });
                return api.post('/getRiskAssessmentDetails?type=ResidualRiskQuestionaire&companyId=' + companyId, data).then((response)=>{
                    return Promise.resolve(response.data);
                }).catch(error => {
                    return Promise.reject({});
                });
            default:
                // return 'NA';
                return '';
        }
    }catch(e){
        return '';
    }
}

export const filteredQuestionsWithAnswer = (data = []) => {
  function validateDataType(value) {
    return typeof value == "number" || value;
  }
  try {
    if (!(data && data.length)) {
      return [];
    }

    return data.filter((item) => {
      return validateDataType(item["response"]) &&
        validateDataType(item["responseName"]) &&
        validateDataType(item["responseWeightage"])
        ? true
        : false;
    });
  } catch (e) {
    return [];
  }
};

export const getData_DataChangedValidate = (data = null) => {
    try{
        if(!(data && data.length)){
            throw new Error('Date not found');
        }
        const methodology = data[0]['methodologyId'];
        return constructRiskAssessmentPayload(methodology, data, true);
    }catch(e){
        return null;
    }
}

export const checkArrayEqual_RiskAssessment = (data1 = null, data2 = null) => {
    try{

        const objectsEqual = (o1, o2) => {
            let match = false;
            if(typeof o1 === 'object' && Object.keys(o1).length > 0){
            match = (Object.keys(o1).length == Object.keys(o2).length && Object.keys(o1).every(p => objectsEqual(o1[p], o2[p])));
            }
            else{
            match = (o1 == o2);
            }
            return match;
        }

        if( (!data1 || (Array.isArray(data1) && data1.length == 0)) && (!data2 || (Array.isArray(data2) && data2.length == 0)) ){
            return true;
        }

        if(Array.isArray(data1) && Array.isArray(data2) && data1.length == data2.length){
            // console.log('**********', {data1, data2})
            let count = 0;
            const methodology = data1[0]['methodologyId'];
            for(let key1 = 0; key1 < data1.length; key1++){
                let item1 = data1[key1];
                let found = data2.find(el2 => {
                    if(methodology == '1'){
                        return( el2['methodologyId'] == item1['methodologyId'] && el2['methodologyName'] == item1['methodologyName'] && el2['riskCategory'] == item1['riskCategory'] && el2['riskCategoryName'] == item1['riskCategoryName'] && el2['riskType'] == item1['riskType'] && el2['riskTypeName'] == item1['riskTypeName'] && el2['probability'] == item1['probability'] && el2['impact'] == item1['impact'] && el2['confidence'] == item1['confidence'] && el2['riskDescription'] == item1['riskDescription'] && el2['controlMeasures'] == item1['controlMeasures'] && el2['overallRisk'] == item1['overallRisk'] );
                    }
                    else{
                        return( el2['methodologyId'] == item1['methodologyId'] && el2['methodologyName'] == item1['methodologyName'] && el2['question'] == item1['question'] && el2['questionName'] == item1['questionName'] && el2['answer'] == item1['answer'] && el2['answerName'] == item1['answerName'] );
                    }
                });
                if(!found){
                    return false;
                }
                const flag = objectsEqual(item1, found);
                if(flag == false){
                    return false;
                }
                count++;
            }
            return (count == data1.length);
        }
        return false;
    }catch(e){
        return false;
    }
}