
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../../initialState';


export const shiftListData = (state = [], action) => {
    // console.log("Action====>",action)
    switch (action.type) {
        case 'LOAD_SHIFT_LIST_SUCCESS':
            return action.getShiftListData;
        default:
            return state;
    }
}
export const on_call_shift_loader_reducer = (state = initialState, action) => {
	// console.log("Inside action",action.payload)
	switch (action.type) {
		case 'LOAD_SHIFT_LIST_LOADER_IN_PROGRESS':
			return Object.assign({}, state, { showShiftListLoader: true });
		case 'LOAD_ALL_SHIFT_LIST_META':
			return Object.assign({}, state, { allShiftListMeta: action.payload });
		case 'LOAD_SHIFT_LIST_LOADER_FINISHED':
			return Object.assign({}, state, { showShiftListLoader: false });
		default:
			return state;
	}
}
export const on_call_shift_reducer = (state = initialState, action) => {
	// console.log("action.payload",action.payload);
	// console.log("action==>",action)
	switch (action.type) {
		case 'LOAD_ALL_SCHEDULE_LIST_SUCCESS':
			return Object.assign({}, state, { allScheduleList: action.payload });
		case 'LOAD_SHIFT_DETAILS_BY_SHIFT_ID_SUCCESS':
			return Object.assign({}, state, { shiftDetailsByShiftId: action.payload });
		case 'EMPTY_SCHEDULE_DETAILS_BY_SCHEDULE_ID_SUCCESS':
			return Object.assign({}, state, { shiftDetailsByShiftId: null });
		default:
			return state;
	}
}