
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const myRadioComponent = (e) => {
  e.DomComponents.addType("custom-radio", {
    model: {
      defaults: {
        tagName: "input",
        attributes: { type: "radio" },
        traits: [
          {
            type: "text",
            label: "Name",
            name: "name",
            changeProp: 1,
          },
          {
            type: "text",
            label: "Label",
            name: "label",
            changeProp: 1,
          },
          {
            type: "checkbox",
            label: "Checked",
            name: "checked",
            changeProp: 1,
          },
          {
            type: "text",
            label: "Labels",
            name: "labels",
            changeProp: 1,
          },
          {
            type: "text",
            label: "Values",
            name: "values",
            changeProp: 1,
          },
          {
            type: "checkbox",
            label: "Required",
            name: "required",
            changeProp: 1,
          },
          {
            type: "checkbox",
            label: "Disabled",
            name: "disabled",
            changeProp: 1,
          },
        ],
      },
      init() {
        this.on("change:label", this.handleInnerTextChange);
        this.on("change:checked", this.handleCheckedChange);
      },
      handleCheckedChange() {
        const checked = this.get("checked");
        const tag = this.find("input")[0];
        tag.set("attributes", {
          ...this.get("attributes"),
          checked: checked,
        });
      },
      handleInnerTextChange() {
        const tag = this.find("span")[0];
        const label = this.get("labels");
        if (tag) {
          tag.components(label);
        }
      },
    },
  });
};

export const myRadioBlockJson = {
  label: "Custom Radio",
  // attributes: { class: "fa fa-circle" },
  media:`<svg fill="#454545" height="40px" width="40px" version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-3.2 -3.2 38.40 38.40" xml:space="preserve" stroke="#454545" transform="matrix(1, 0, 0, 1, 0, 0)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.128"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M29,6H17c-0.6,0-1-0.4-1-1s0.4-1,1-1h12c0.6,0,1,0.4,1,1S29.6,6,29,6z"></path> </g> <g> <path d="M24,10h-7c-0.6,0-1-0.4-1-1s0.4-1,1-1h7c0.6,0,1,0.4,1,1S24.6,10,24,10z"></path> </g> <g> <path d="M29,22H17c-0.6,0-1-0.4-1-1s0.4-1,1-1h12c0.6,0,1,0.4,1,1S29.6,22,29,22z"></path> </g> <g> <path d="M24,26h-7c-0.6,0-1-0.4-1-1s0.4-1,1-1h7c0.6,0,1,0.4,1,1S24.6,26,24,26z"></path> </g> <g> <path d="M8,30c-3.3,0-6-2.7-6-6s2.7-6,6-6s6,2.7,6,6S11.3,30,8,30z"></path> </g> <path d="M8,2C4.7,2,2,4.7,2,8s2.7,6,6,6s6-2.7,6-6S11.3,2,8,2z M8,10c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S9.1,10,8,10z"></path> </g></svg>`,
  category: "Basic",
  content: `
    <div data-gjs-type="custom-radio"><input type="radio" /><span> Custom Radio</span><div>
  `,
};
