
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState, useRef, useContext } from "react";
import { MyContext } from '_MyContext';
import { ListGroup, Row, Col, Tabs, Tab, Accordion } from "react-bootstrap";
import { useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";
import AiIcon from "./aiIcon";
import { useGetAISMDsatDetailsMutation, useGetAISMFcrDetailsMutation, useGetChangeSucessRateMutation } from "../../reducers/aism/aismAPI";
import ListLoader from "./loaders/ListLoader";
import D2CGenericAIDesctiption from "./D2CGenericAIDesctiption";
import D2CPieChart from "./D2CPieChart";

const AiInsights = (props) => {
    const closeButton = useRef(null);
    const context = useContext(MyContext);
    const tr = useSelector((state) => state.spcmReducer.tr); 
    const extractedChangeData = useSelector(state => state.extractedChangeDataForSucessRate); 
    const [result, setResult] = useState(null);
    const [dsatResult, setDsatResult] = useState(null);
    const [changeSuccessRate, setChangeSuccessRate] = useState(0.0);
    const [changeData, setChangeData] = useState([]);
    const hasTrueKey = result && result.hasOwnProperty('true');
    const hasDsatTrueKey = dsatResult && dsatResult.hasOwnProperty('true');
    const [getAISMFcrDetails, { data, error, isLoading: getFCRLoader}] = useGetAISMFcrDetailsMutation();
    const [getAISMDsatDetails, { dataDSAT, errorDSAT, isLoading: getDSATLoader }] = useGetAISMDsatDetailsMutation();
    const [getChangeSuccessRate, { dataChangeSucess, errorChangeSuccess, isLoading: getChangeSucessLoader }] = useGetChangeSucessRateMutation();

    useEffect(() => {
        context.keyDownHandler(closeButton.current);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getAISMFcrDetails(props.payload).unwrap();
                setResult(result);
            } catch (err) {
            }
        }
        if (props.aism == true ) {
            fetchData();
        }
        }, []);

    useEffect(() => {
        const fetchDSATData = async () => {
            try {
                const result = await getAISMDsatDetails(props.payloadDSAT).unwrap();
                setDsatResult(result);
            } catch (err) {
            }
        }
        if (props.aismDSAT == true ) {
            fetchDSATData();
        }
        const fetchChangeSuccessRate = async () => {
            try{
                const changeRate = await getChangeSuccessRate(extractedChangeData).unwrap();
                let sucessRate = changeRate["true"].replace('%', '').trim();
                sucessRate = parseFloat(sucessRate);
                sucessRate = sucessRate === null || sucessRate === undefined || sucessRate === '' ? 0 : sucessRate;
                let data = [];
                if(sucessRate === 0.0 || sucessRate === 0){
                  data.push({title: tr['Failure Rate'], value: sucessRate, color: '#FF0000'});
                }else if(sucessRate === 100.0 || sucessRate === 100){
                  data.push({ title: tr['Sucess Rate'], value: sucessRate, color: '#4caf50' });
                }else{
                  data.push({ title: tr['Sucess Rate'], value: sucessRate, color: '#4caf50' },
                    { title: tr['Failure Rate'], value: 100 - sucessRate, color: '#FF0000' }); 
                }
                setChangeData(data);
                setChangeSuccessRate(sucessRate);
            }catch(error){
                console.log('Error in retriving data from API /api/getChangeSucessRate : ',error.data.message); 
            }
        }
        if(props.aismChangeRate){
            fetchChangeSuccessRate();
        }
    }, []);
    const showLoader = getFCRLoader || getDSATLoader || getChangeSucessLoader;
    const showFcr = props.aism && props.breakFixEditDetails.statusReopen !=true && (props.breakFixEditDetails.assigneeHopCount== 0 || props.breakFixEditDetails.assigneeHopCount == null) && props.breakFixEditDetails.asgGroupHopCount== 0;

    return (
        <div className="">
            <Row>
                <Col xs={12}>
                    <div className="rPageHeadActBtn">
                        <ul>
                            <li>
                                <Link to="javascript:void(0)" className="closeRsBtn" id="closeButton"
                                    title={tr["Minimize the details panel"]}
                                    onClick={() => { props.resetRightSide() }}
                                    ref={closeButton}
                                ><IoClose /></Link>
                            </li>
                        </ul>
                    </div>
                    <div className="rPageHeading">
                        <div className="offNam margin-t-5 margin-b-5"><AiIcon /> {tr['Insights']}</div>
                    </div>
                </Col>
            </Row>
            <div className="rBoxGap">
                <div className="rBoxTabs hideTabNav">
                    <Tabs id="tabId">
            <Tab eventKey={1} title={tr["AI INSIGHTS DETAILS"]}>
                { showLoader ? <ListLoader/> : changeSuccessRate ? 
                  <>
                    <D2CGenericAIDesctiption />
                    <D2CPieChart data={changeData} title={tr['Change Success Rate']} /> 
                  </>
                : (hasTrueKey || hasDsatTrueKey) ? (
                    <>
                        <D2CGenericAIDesctiption />
                        <Accordion id={"aiQues"} accordion className="brkFix brkFixOrder" defaultActiveKey="0">
                            {showFcr && <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    <h4 className="font1">FCR</h4>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div className="aiSecBox">
                                        <div className="aiSec1"><div>Possibility</div>{result.true}</div>
                                        <div>Can be Potential <span className="primaryColorDarkest">First-Contact Resolution (FCR)</span></div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>}
                            {props.aismDSAT && <Accordion.Item eventKey="2">
                                    <Accordion.Header>
                                        <h4 className="font1">DSAT</h4>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="aiSecBox">
                                            <div className="aiSec1"><div>Possibility</div>{dsatResult.true}</div>
                                            <div>Can be Potential <span className="primaryColorDarkest">Dissatisfaction (DSAT)</span></div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>}
                        </Accordion>
                    </>
                ) : (
                    <div>{tr['No AI insights available for this ticket']}</div>
                )}
            </Tab>
        </Tabs>
                </div>
            </div>
        </div>
    );
};

export default AiInsights;
