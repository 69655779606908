
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../../common/header/breadcrumbs";
import HeaderSec from "../edit/header";
import EditForm from "../edit/form";
import TabsComponent from "./tabs";
import AuditLog from "./AuditLog";
import ContractRelationship from "./ContractRelationship";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { getContractPageDetails } from "../../../../actions/ham/contractAction";
import { useEffect } from "react";
import { useParams } from "react-router";
import "_Css/form/_form.scss";
const EditIndex = (props) => {
  const [nameErr, setNameErr] = useState("");
  const [businessErr, setBusinessErr] = useState("");
  const [startErr, setStartErr] = useState("");
  const [numberErr, setNumberErr] = useState("");
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const tr = useSelector((state) => state.spcmReducer.tr);
  const [endErr, setEndErr] = useState("");
  const [companyErrorState, setCompanyErrorState] = useState("");
  const [selectedAdministrator, setSelectedAdministrator] = useState({});
  const [selectedBudget, setSelectedBudget] = useState({});
  const [statusErr, setStatusErr] = useState("");
  const [substatusErr, setSubStatusErr] = useState("");
  const [view, setView] = useState("activity");
  const [isRightEditPanel, setisRightEditPanel] = useState(true);
  const [requestorError, setRequestorError] = useState('');
  const [lastReviewDateError, setlastReviewDateError] = useState("");
  const [nextReviewDateError, setnextReviewDateError] = useState("");
  const [adminTypeErr, setAdminTypeErr] = useState('');
  const [adminValue, setAdminValue] = useState([]);
  const [budgetError , setBudgetError]=useState("");
  const initialValue = useSelector((state) => state.contractEditList);
  const params = useParams();
  const dispatch = useDispatch();
  //console.log("CheckInitialVALUES====", initialValue);

  useEffect(() => {
    dispatch(getContractPageDetails(params.CONTRACT_NUM));
  }, []);

  const setAdminErrorColor = (err) => {
    setAdminTypeErr(err);
  };

  const rightEditPanel = (value) => {
    setisRightEditPanel(value);
  };

  const setRequestorErrorColor = () => {
    if (Object.keys(selectedAdministrator).length > 0) {
      setRequestorError('');
    } else {
      setRequestorError('error');
    }
  };


  return (
    <main>
      <Container fluid className="margin-t-10 margin-b-15">
        <Breadcrumbs
          activePageName={tr["Edit"]}
          parentPageurl="/contracts"
          parentPageName={tr["Contract"]}
        />
      </Container>

      <Container fluid>
        <HeaderSec
          setNameErr={setNameErr}
          setBusinessErr={setBusinessErr}
          setStartErr={setStartErr}
          setEndErr={setEndErr}
          setCompanyErrorState={setCompanyErrorState}
          statusErr={statusErr}
          setStatusErr={setStatusErr}
          substatusErr={substatusErr}
          setSubStatusErr={setSubStatusErr}
          setNumberErr={setNumberErr}
          startDateError={startDateError}
          setStartDateError={setStartDateError}
          endDateError={endDateError}
          setEndDateError={setEndDateError}
          view={view}
          setView={setView}
          rightEditPanel={rightEditPanel}
          setRequestorErrorColor={setRequestorErrorColor}
          lastReviewDateError={lastReviewDateError}
          setlastReviewDateError={setlastReviewDateError}
          nextReviewDateError={nextReviewDateError}
          setnextReviewDateError={setnextReviewDateError}
          setAdminErrorColor={setAdminErrorColor}
          adminValue={adminValue}
          setAdminValue={setAdminValue}
          budgetError={budgetError}
          setBudgetError={setBudgetError}
        />    
{/*    
        {initialValue?.length === 0 ? (
          <div className="container-fluid minHeightWIB text-c">You don't have access to view this Contract</div>
      ) : ( */}
        <PanelGroup direction="horizontal">
          <Panel
            id="sidebar"
            minSize={33}
            order={1}
            defaultSize={isRightEditPanel ? 67 : 100}
            className={isRightEditPanel ? "isShowLeftPanel" : ""}
          >
            <EditForm
              adminValue={adminValue}
              setAdminValue={setAdminValue}
              adminTypeErr={adminTypeErr}
              setAdminErrorColor={setAdminErrorColor}
              nameErr={nameErr}
              setNameErr={setNameErr}
              businessErr={businessErr}
              setBusinessErr={setBusinessErr}
              startErr={startErr}
              setStartErr={setStartErr}
              endErr={endErr}
              setEndErr={setEndErr}
              companyErrorState={companyErrorState}
              setCompanyErrorState={setCompanyErrorState}
              setSelectedAdministrator={setSelectedAdministrator}
              selectedAdministrator={selectedAdministrator}
              setSelectedBudget={setSelectedBudget}
              selectedBudget={selectedBudget}
              statusErr={statusErr}
              setStatusErr={setStatusErr}
              substatusErr={substatusErr}
              setSubStatusErr={setSubStatusErr}
              setNumberErr={setNumberErr}
              numberErr={numberErr}
              startDateError={startDateError}
              setStartDateError={setStartDateError}
              endDateError={endDateError}
              setEndDateError={setEndDateError}
              tr={tr}
              rightEditPanel={rightEditPanel}
              requestorError={requestorError}
              setRequestorErrorColor={setRequestorErrorColor}
              lastReviewDateError={lastReviewDateError}
              setlastReviewDateError={setlastReviewDateError}
              nextReviewDateError={nextReviewDateError}
              setnextReviewDateError={setnextReviewDateError}
            />
          </Panel>
          {isRightEditPanel ? (
            initialValue?.length !== 0 && <>
              <PanelResizeHandle className="isShowLeftPanel resizeHandle">
                <div className="outlne">
                  <div className="handIcn">
                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                  </div>
                </div>
              </PanelResizeHandle>
              <Panel minSize={33} order={2} defaultSize={33}>
                {view === "attachment" && (
                  <div className="stickyArea rBoxStyle">
                    <TabsComponent rightEditPanel={rightEditPanel} setView={setView} />
                  </div>
                )}
                {view === "activity" && (
                  <div className="stickyArea rBoxStyle">
                    <AuditLog rightEditPanel={rightEditPanel} setView={setView} />
                  </div>
                )}
                {view === "relationship" && (
                  <div className="stickyArea rBoxStyle">
                    <ContractRelationship rightEditPanel={rightEditPanel} setView={setView} />
                  </div>
                )}
              </Panel>
            </>
          ) : (
            ""
          )}
        </PanelGroup>
      {/* )} */}
        
      </Container>
    </main>
  );
};

export default EditIndex;
