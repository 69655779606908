
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
import moment from 'moment-timezone';

let dateformat = cookies.get('gph');
if (dateformat) dateformat = dateformat.replace('s:', '');
if (dateformat) dateformat = dateformat.substring(0, dateformat.lastIndexOf('.'));
//console.log("homepage::::"+homepagelocation);
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let timezone = cookies.get('gph');
if (timezone) timezone = timezone.replace('s:', '');
if (timezone) timezone = timezone.substring(0, timezone.lastIndexOf('.'));
//console.log("homepage::::"+homepagelocation);
timezone = timezone.split("~");
timezone = timezone[3];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

let offset = cookies.get('gph');
if (offset) offset = offset.replace('s:', '');
if (offset) offset = offset.substring(0, offset.lastIndexOf('.'));
offset = offset.split("~");
offset = offset[34];

export default function (state = initialState.breakFixTaskLogDetails, action) {

	switch (action.type) {
		case 'LOAD_BREAKFIXTASKLOGDETAILS_SUCCESS':
			//alert("editReducer:"+action.breakFixEditDetails.data.serviceId);
			return action.breakFixTaskLogDetails.data;
		case 'LOAD_BREAKFIXTASKLOGDETAILS_FAILURE':
			return action.failureMessage;
		default:
			return state;
	}

}

export const myTaskDetailsList = (state = {}, action) => {
	switch (action.type) {
		case 'LOAD_TASKDETAILSLIST_SUCCESS':
			return action.taskDetailsList.data;
		case 'TASK_LISTDATA_LOADING_ERROR':
			//console.log("TASK_LISTDATA_LOADING_ERROR");
			return [];
		default:
			return state;
	}
}

export const createTaskFormValues = (state = { 
	isFetching: true,
	isError: false,
	errorMessage: '',
	requestorData: [],
	serviceData: [],
	assignmentGroupData: [],
	assignedToData: [],
	editFormValues: {}
}, action) => { 
	switch (action.type) {
		case 'REQUESTOR_DATA_LOADED_FOR_TASK':
			return Object.assign({}, state, {
				isFetching: false,
				requestorData: action.requestorData,
			});
		case 'SERVICE_DATA_LOADED_FOR_TASK':
			return Object.assign({}, state, {
				isFetching: false,
				serviceData: action.serviceData
			});
		case 'ASSIGNMENT_GROUP_DATA_LOADED_FOR_TASK':
			return Object.assign({}, state, {
				isFetching: false,
				assignmentGroupData: action.assignmentGroupData
			});
		case 'ASSIGNED_TO_DATA_LOADED_FOR_TASK':
			return Object.assign({}, state, {
				isFetching: false,
				assignedToData: action.assignedToData
			});
		case 'TASK_DATA_LOADING_ERROR':
			return Object.assign({}, state, {
				isFetching: false,
				isError: true,
				errorMessage: action.errorMessage
			});
		case 'TASK_EDIT_DATA_LOADED':
			action.taskDetails.actualEndDate = action.taskDetails.actualEndDate? action.taskDetails.actualEndDate : null;
				// moment().format(jwtdf + " HH:mm:ss")

			action.taskDetails.actualStartDate = action.taskDetails.actualStartDate ? action.taskDetails.actualStartDate : null;
				// moment().format(jwtdf + " HH:mm:ss")

			action.taskDetails.expectedDueDate = action.taskDetails.expectedDueDate ?
				action.taskDetails.expectedDueDate : moment().tz(timezone).format(jwtdf + " hh:mm A");

			action.taskDetails.expectedStartDate = action.taskDetails.expectedStartDate ?
				action.taskDetails.expectedStartDate : moment().tz(timezone).format(jwtdf + " hh:mm A");

			return Object.assign({}, state, {
				isFetching: action.isFetching,
				editFormValues: action.taskDetails
			});
		// return Object.assign({}, state, {
		// 	isFetching: action.isFetching,
		// 	editFormValues:action.taskDetails
		// });
		case 'TASK_EDIT_DATA_RESET':
			return Object.assign({}, state, { 
				isFetching: true,
				isError: false,
				errorMessage: '',
				requestorData: [],
				serviceData: [],
				assignmentGroupData: [],
				assignedToData: [],
				editFormValues: {}
			});
		default:
			return state;
	}
};

export const realatedTaskCount = (state = 0, action) => {
	if (action.type == 'RELATED_TASK_COUNT_CHECK_FOR_WORKITEM') {
		return action.count;
	}
	return state;
};

export const relatedCMDBTasks = (state = -1, action) => {
	if(action.type ==='RELATED_CMDB_TASKS'){
		return action.count;
	}
	return state;
}
export const taskStatusToEdit = (state = [], action) => {
	if (action.type == 'TASK_ITEM_NEXT_STATUS') {
		return action.payload;
	}
	return state;
};

export const taskGroupData = (state = [], action) => {
	if (action.type == 'TASK_GROUP_DATA_LOADED') {
		return action.taskGroupData;
	}
	return state;
};

export const taskGroupData2 = (state = [], action) => {
	if (action.type == 'TASK_GROUP_DATA_LOADED2') {
		return action.groupList;
	}
	return state;
};

export const taskAssignedToData = (state = [], action) => {
	if (action.type == 'TASK_ASSIGNED_TO_DATA_LOADED') {
		return action.taskAssignedToData;
	}
	return state;
};

export const NotificationListByWID=(state=[],action)=>{

	if(action.type == 'NOTIFCATION_FETCHED_SUCCESS'){
		return action.payload
	}else{
		return state
	}
}
export const regionListData = (state = [], action) => {
	if (action.type == "ONHOLD") {
		// alert("Alert");
		return action.regionListData;
	}
	return state;
};

export const editTaskStatus = (state = [], action) => {
	if (action.type == 'EDIT_TASK_LIST_STATUS') {
		return action.payload;
	}
	else if (action.type == 'FLUSH_REDUCER') {
		return action.payload;
	}
	return state;
};
export const taskTypeList = (state = [], action) => {
	
	if (action.type == 'TASK_TYPE_LIST_FETCHED_SUCCESSFULLY') {
		return action.taskType;
	}
	return state;	
};
export const taskCountByItemId = (state = [], action) => {
	
	if (action.type == 'TASK_COUNT_FETCHED_SUCCESSFULLY') {
		return action.payload;
	}
	return state;	
};