
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Row, Col, Form, Alert, ButtonToolbar, Button, Table, } from 'react-bootstrap';
import { _textArea, _dropDown, _dynamicNamedropDown, _inputField, _radio, _checkbox } from '../../common/formFields';
import { Field, reduxForm, SubmissionError, change } from 'redux-form';
import { connect } from 'react-redux';
import axios from 'axios';
import { spcmLoader, setLeftEditId, getOrderingPreferences, getIntegrationPreferences, getMenuData, getAdditionalIntegrationData } from '../../../actions/spcmActions';
import AdditionalIntegrationList from './list.js';
import { bindActionCreators } from 'redux';
import {ImSpinner6} from "react-icons/im";


let extraCountOuter = [0];

const validate = (values, props) => {

	console.log("validate",values);

	const errors = {};
	if (!values.keyword_id)
		errors.keyword_id = "Required";
	if (!values.integration_value)
		errors.integration_value = "Required";
	extraCountOuter.forEach(val => {
		if (!values["keyword_id" + val.toString()])
			errors["keyword_id" + val.toString()] = "Required";
		if (!values["integration_value" + val.toString()])
			errors["integration_value" + val.toString()] = "Required";
	});
	if (values.url) {
        if (values.url.trim() == "") {
            errors.url = 'Enter URL';
        }
    }
    else if (!values.url) {
		errors.url = 'Enter URL';
	}
	return errors;

}

class ComponentForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hide_description: "false",
			hide_contacts: "false",
			hide_components: "false",
			hide_self_service_actions: "false",
			restrict_reopen_sr: "false",
			hide_sla: "false",
			hide_price: "false",
			editable_expectedby: "false",
			complexity_editable: "false",
			hide_approver_disclaimer: "false",
			hide_service_disclaimer: "false",
			restrict_cancellation_sr: "false",
			hide_quantity: "true",
			vSON: "false",
			vWIN: "false",
			vCOM: "false",
			vSDT: "false",
			vEDT: "false",
			vINF: "false",
			vIGP: "false",
			vCGP: "false",
			currentForm: "Ordering_Preference",
			extraCounts: [0],
			arrayCounts: 1,
			restrict_language: "false",
			minimum_optional_component: "false",
			external_offering:'false',
			extOffering:'extOfferingShow',
		}
		this.componentWillMount = this.componentWillMount.bind(this);
		this.submitForm = this.submitForm.bind(this);
		this.onChangeFlag = this.onChangeFlag.bind(this);
		this.getFormHandler = this.getFormHandler.bind(this);
		this.loadCount = this.loadCount.bind(this);
		this.addRow = this.addRow.bind(this);
		this.deleteRow = this.deleteRow.bind(this);
		this.setExtraCount = this.setExtraCount.bind(this);
		this.onKeywordChange = this.onKeywordChange.bind(this);
	}

	// Functions for dymanic field rows of addtional integration

	addRow() {
		this.setState({ arrayCounts: this.state.arrayCounts + 1 });
		let arrayvar = this.state.extraCounts.slice();
		arrayvar.push(this.state.arrayCounts);
		this.setState({ extraCounts: arrayvar });
		this.setExtraCount(arrayvar);
		extraCountOuter = arrayvar;
		this.props.change('_validationHack', Date.now());
	};
	deleteRow(myIndex) {
		let arrayvar = this.state.extraCounts;
		let index = arrayvar.indexOf(myIndex);
		arrayvar.splice(index, 1);
		this.setState({ extraCounts: arrayvar });
		this.setExtraCount(arrayvar);
		extraCountOuter = arrayvar;
		this.props.change('_validationHack', Date.now());
	};

	setExtraCount(e) {
		this.setState({ extraCounts: e });
		extraCountOuter = e;
		this.props.change('_validationHack', Date.now());
	}

	onKeywordChange(event, extraCount) {
		const { change } = this.props;
		if (extraCount) {
			change('integration_keyword' + extraCount, event.target[event.target.selectedIndex].text)
		}
		else {
			change('integration_keyword', event.target[event.target.selectedIndex].text)
		}

	}

	// End Functions for dymanic field rows of addtional integration 

	componentWillMount() {
		if (typeof (this.props.initialValues) !== "undefined") {
			this.setState(
				{
					hide_description: this.props.initialValues.hide_description,
					hide_contacts: this.props.initialValues.hide_contacts,
					hide_components: this.props.initialValues.hide_components,
					hide_self_service_actions: this.props.initialValues.hide_self_service_actions,
					restrict_reopen_sr: this.props.initialValues.restrict_reopen_sr,
					hide_sla: this.props.initialValues.hide_sla,
					hide_price: this.props.initialValues.hide_price,
					hide_quantity: this.props.initialValues.hide_quantity,
					editable_expectedby: this.props.initialValues.editable_expectedby,
					complexity_editable: this.props.initialValues.complexity_editable,
					hide_approver_disclaimer: this.props.initialValues.hide_approver_disclaimer,
					hide_service_disclaimer: this.props.initialValues.hide_service_disclaimer,
					restrict_cancellation_sr: this.props.initialValues.restrict_cancellation_sr,
					vSON: this.props.initialValues.vSON,
					vWIN: this.props.initialValues.vWIN,
					vCOM: this.props.initialValues.vCOM,
					vSDT: this.props.initialValues.vSDT,
					vEDT: this.props.initialValues.vEDT,
					vINF: this.props.initialValues.vINF,
					vIGP: this.props.initialValues.vIGP,
					vCGP: this.props.initialValues.vCGP,
					restrict_language: this.props.initialValues.restrict_language,
					minimum_optional_component: this.props.initialValues.minimum_optional_component,
					external_offering: this.props.initialValues.external_offering

				})
		}

	}

	componentDidMount() {
		this.setExtraCount([0]);
		this.props.getMenuData("additionalIntegrationData");
	}


	componentWillUnmount() {
		this.props.spcmLoader('attribute', 'success');
	}

	getFormHandler(e, reset) {
		this.setState({ currentForm: e });

		if (e == 'Additional_Integration_Data') {
			this.props.componentFormFun3(false);
			this.setExtraCount([0]);
			reset();
		}
		else {
			this.props.componentFormFun3(true);
		}

	}


	onChangeFlag(e) {
		let str = e.target.name;
		switch (str) {
			case "hide_description":
				this.setState({ hide_description: e.target.checked.toString() })
				break;
			case "hide_contacts":
				this.setState({ hide_contacts: e.target.checked.toString() })
				break;
			case "hide_components":
				this.setState({ hide_components: e.target.checked.toString() })
				break;
			case "hide_self_service_actions":
				this.setState({ hide_self_service_actions: e.target.checked.toString() })
				break;
			case "restrict_cancellation_sr":
				this.setState({ restrict_cancellation_sr: e.target.checked.toString() })
				break;
			case "restrict_reopen_sr":
				this.setState({ restrict_reopen_sr: e.target.checked.toString() })
				break;
			case "hide_sla":
				this.setState({ hide_sla: e.target.checked.toString() })
				break;
			case "hide_price":
				this.setState({ hide_price: e.target.checked.toString() })
				break;
			case "editable_expectedby":
				this.setState({ editable_expectedby: e.target.checked.toString() })
				break;
			case "complexity_editable":
				this.setState({ complexity_editable: e.target.checked.toString() })
				break;
			case "hide_approver_disclaimer":
				this.setState({ hide_approver_disclaimer: e.target.checked.toString() })
				break;
			case "hide_service_disclaimer":
				this.setState({ hide_service_disclaimer: e.target.checked.toString() })
				break;
			case "hide_quantity":
				this.setState({ hide_quantity: e.target.checked.toString() })
				break;
			case "vSON":
				this.setState({ vSON: e.target.checked.toString() })
				break;
			case "vWIN":
				this.setState({ vWIN: e.target.checked.toString() })
				break;
			case "vCOM":
				this.setState({ vCOM: e.target.checked.toString() })
				break;
			case "vSDT":
				this.setState({ vSDT: e.target.checked.toString() })
				break;
			case "vEDT":
				this.setState({ vEDT: e.target.checked.toString() })
				break;
			case "vINF":
				this.setState({ vINF: e.target.checked.toString() })
				break;
			case "vIGP":
				this.setState({ vIGP: e.target.checked.toString() })
				break;
			case "vCGP":
				this.setState({ vCGP: e.target.checked.toString() })
				break;
			case "restrict_language":
				this.setState({ restrict_language: e.target.checked.toString() })
				break;
			case "minimum_optional_component":
				this.setState({ minimum_optional_component: e.target.checked.toString() })
				break;
			case "external_offering":
				this.setState({external_offering:e.target.checked.toString() });
				break;
		}
	}


	submitForm(values) {
		const OrderingPrefValues = {}
		const IntegrationPrefValues = {}
		let AddtionalIntegrationValues = [];

		if (this.state.currentForm == "Ordering_Preference") {
			OrderingPrefValues.offering_id = this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID;
			OrderingPrefValues.hide_description = this.state.hide_description;
			OrderingPrefValues.hide_contacts = this.state.hide_contacts;
			OrderingPrefValues.hide_components = this.state.hide_components;
			OrderingPrefValues.hide_self_service_actions = this.state.hide_self_service_actions;
			OrderingPrefValues.hide_sla = this.state.hide_sla;
			OrderingPrefValues.hide_price = this.state.hide_price;
			OrderingPrefValues.hide_approver_disclaimer = this.state.hide_approver_disclaimer;
			OrderingPrefValues.hide_service_disclaimer = this.state.hide_service_disclaimer;
			OrderingPrefValues.restrict_reopen_sr = this.state.restrict_reopen_sr;
			OrderingPrefValues.hide_quantity = this.state.hide_quantity;
			OrderingPrefValues.restrict_language = this.state.restrict_language;
			OrderingPrefValues.external_offering = this.state.external_offering;
			OrderingPrefValues.URL = values.url;



			if (this.props.spcmReducer.currentlySelectedServiceDetails.WORK_ITEM_TYPE == "serviceRequest") {
				OrderingPrefValues.complexity_editable = this.state.complexity_editable;
				OrderingPrefValues.editable_expectedby = this.state.editable_expectedby;
				OrderingPrefValues.restrict_cancellation_sr = this.state.restrict_cancellation_sr;
				OrderingPrefValues.minimum_optional_component = this.state.minimum_optional_component;


			}
		}

		else if (this.state.currentForm == "Integration_Preference") {
			IntegrationPrefValues.offering_id = this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID;
			IntegrationPrefValues.vSON = this.state.vSON;
			IntegrationPrefValues.vWIN = this.state.vWIN;
			IntegrationPrefValues.vCOM = this.state.vCOM;
			IntegrationPrefValues.vSDT = this.state.vSDT;
			IntegrationPrefValues.vEDT = this.state.vEDT;
			IntegrationPrefValues.vINF = this.state.vINF;
			IntegrationPrefValues.vIGP = this.state.vIGP;
			IntegrationPrefValues.vCGP = this.state.vCGP
		}

		// Values object creation in case of submitting addtional integration data form

		else {
			let temp = values;

			Object.keys(temp).forEach(val => {
				if (val[12] == 'v') {
					if (val.substring(17) && this.state.extraCounts.indexOf(parseInt(val.substring(17), 10)) < 0) {
						delete (values['keyword_id' + val.substring(17)]);
						delete (values['integration_value' + val.substring(17)]);
					}
				}
			})


			if (this.props.spcmReducer.editLeftId > 0) {
				AddtionalIntegrationValues = values;
			}
			else {
				var integration_keyword = [];
				var keyword_id = [];
				var value = [];
				var final = [];
				Object.keys(values).forEach(function (key) {

					if (key.indexOf("integration_value") == 0) {
						key.substring(17) ? value[key.substring(17)] = values[key] : value[0] = values[key];
						final.push({ integration_keyword: integration_keyword[key.substring(17)], keyword_id: keyword_id[key.substring(17)], integration_value: value[key.substring(17)], offering_id: this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID })
					}
					else if (key.indexOf("integration_keyword") == 0) {
						key.substring(19) ? integration_keyword[key.substring(19)] = values[key] : integration_keyword[0] = values[key];
					}
					else if (key.indexOf("keyword_id") == 0) {
						key.substring(10) ? keyword_id[key.substring(10)] = values[key] : keyword_id[0] = values[key];
					}
				}, this)

				AddtionalIntegrationValues = final;
			}
		}


		if (this.state.currentForm == "Ordering_Preference") {
			this.props.spcmLoader('attribute', 'posting');
			return axios.patch("/service/api/orderPreferences", OrderingPrefValues).then((response) => {
				this.props.getOrderingPreferences(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
				this.props.boardPageInActive4(false);
				this.props.spcmLoader('attribute', 'success');
			})
				.catch((error) => {

					this.props.spcmLoader('attribute', 'success');
					throw new SubmissionError({ _error: error.response.data[0].message });

				});
		}

		else if (this.state.currentForm == "Integration_Preference") {
			this.props.spcmLoader('attribute', 'posting');
			return axios.patch("/service/api/integrationPreferences", IntegrationPrefValues).then((response) => {
				this.props.getIntegrationPreferences(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
				this.props.boardPageInActive4(false);
				this.props.spcmLoader('attribute', 'success');
			})
				.catch((error) => {

					this.props.spcmLoader('attribute', 'success');
					throw new SubmissionError({ _error: error.response.data[0].message });

				});
		}

		// Submitting addtional integration data form

		else {
			if (this.props.spcmReducer.editLeftId > 0) {
				this.props.spcmLoader('attribute', 'posting');
				return axios.patch("/service/api/editAdditionalIntegrationData/" + this.props.spcmReducer.editLeftId, AddtionalIntegrationValues).then((response) => {
					this.props.getAdditionalIntegrationData(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
					this.props.componentFormFun3(false)
					this.props.spcmLoader('attribute', 'success');

				})
					.catch((error) => {

						this.props.spcmLoader('attribute', 'success');
						throw new SubmissionError({ _error: error.response.data[0].message });
					});
			}
			else {
				this.props.spcmLoader('attribute', 'posting');
				return axios.post("/service/api/saveAdditionalIntegrationData", AddtionalIntegrationValues).then((response) => {
					this.props.getAdditionalIntegrationData(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
					this.props.componentFormFun3(false)
					this.props.spcmLoader('attribute', 'success');
					this.props.reset();
					this.setState({ extraCounts: [0] });
				})
					.catch((error) => {
						this.props.spcmLoader('attribute', 'success');
						throw new SubmissionError({ _error: error.response.data[0].message });

					});
			}
		}
	}
	// Function to render dymanic fields  of addtinal integration form

	loadCount(integrationDataKeywords) {
		const rowlength = this.state.extraCounts.length;
		return this.state.extraCounts.map((extraCount, i) => {

			if (rowlength === i + 1 && this.props.spcmReducer.editLeftId && this.props.spcmReducer.editLeftId > 0) {
				return <tr><td><Field component={_dynamicNamedropDown} nameforOptionsList="integrationDataKeywords" options={integrationDataKeywords} name={"keyword_id"} onChange={(event) => { this.onKeywordChange(event) }} className='form-control' /></td><td><Field component={_inputField} name={"integration_value"} className='form-control' /></td></tr>;
			} else if (rowlength === i + 1) {
				return <tr><td><Field component={_dynamicNamedropDown} nameforOptionsList="integrationDataKeywords" options={integrationDataKeywords} name={"keyword_id" + extraCount} onChange={(event) => { this.onKeywordChange(event, extraCount) }} className='form-control' /></td><td><Field component={_inputField} name={"integration_value" + extraCount} className='form-control' /></td><td><Button title="Add" onClick={() => { this.addRow() }}><i className="fa fa-plus"></i></Button></td></tr>;
			}
			else {
				return <tr key={i}><td><Field component={_dynamicNamedropDown} nameforOptionsList="integrationDataKeywords" options={integrationDataKeywords} name={"keyword_id" + extraCount} onChange={(event) => { this.onKeywordChange(event, extraCount) }} className='form-control' /></td><td><Field component={_inputField} name={"integration_value" + extraCount} className='form-control' /></td><td><Button title="Delete" onClick={() => { this.deleteRow(extraCount) }}><i className="fa fa-trash"></i></Button></td></tr>;
			}
		});
	}


	render() {
		const tr = this.props.tr;
		const { error, handleSubmit, reset } = this.props;
		const serviceWorkItemType = this.props.spcmReducer.currentlySelectedServiceDetails.WORK_ITEM_TYPE;
		const integrationDataKeywords = this.props.spcmReducer.integrationDataKeywords;
		return (
			<div className="rBoxGap">
				
				{error == undefined || error == null ? null : <Alert variant="danger" className="errorMessageSec"><p>{error}</p></Alert>}

				<Row className="borderForm">
					<Col xs={12}>
						<Form.Group className="radioInlineBox formSelectionRadioBox form-group">
							<select className="form-control" onChange={(event) => { this.getFormHandler(event.target.value, reset) }}>
								<option value="Ordering_Preference" selected={this.state.currentForm == 'Ordering_Preference'}>{tr['Ordering']}</option>
								<option value="Integration_Preference" selected={this.state.currentForm == 'Integration_Preference'}>{tr['Integration Data']}</option>
								<option value="Additional_Integration_Data" selected={this.state.currentForm == 'Additional_Integration_Data'}>Additional Integration Data</option>
							</select>
						</Form.Group>
					</Col>
				</Row>





				{this.state.currentForm == "Ordering_Preference" ?
					<form onSubmit={handleSubmit(this.submitForm)}>
						<div>
							<Row>
								<Col md={12} className="dCheckbox customCheckBoxContainer">
									<Field component={_checkbox} className='form-control' name='hide_contacts' label={tr['Hide Service Contacts']} onChange={this.onChangeFlag} checked={this.state.hide_contacts == "true"} />
								</Col>
							</Row>

							<Row>
								<Col md={12} className="dCheckbox customCheckBoxContainer">
									<Field component={_checkbox} className='form-control' name='hide_self_service_actions' label={tr['Hide Self Service Actions']} onChange={this.onChangeFlag} checked={this.state.hide_self_service_actions == "true"} />
								</Col>
							</Row>



							<Row>
								<Col md={12} className="dCheckbox customCheckBoxContainer">
									<Field component={_checkbox} className='form-control' name='hide_sla' label={tr['Hide SLA']} onChange={this.onChangeFlag} checked={this.state.hide_sla == "true"} />
								</Col>
							</Row>


							{serviceWorkItemType == "serviceRequest" ?
							<Row>
								<Col md={12} className="dCheckbox customCheckBoxContainer">
									<Field component={_checkbox} className='form-control' name='hide_price' label={tr['Hide Pricing']} onChange={this.onChangeFlag} checked={this.state.hide_price == "true"} />
								</Col>
							</Row>:null}


							{serviceWorkItemType == "serviceRequest" ?

								<Row>
									<Col md={12} className="dCheckbox customCheckBoxContainer">
										<Field component={_checkbox} className='form-control' name='complexity_editable' label={tr['Editable Complexity']} onChange={this.onChangeFlag} checked={this.state.complexity_editable == "true"} />
									</Col>
								</Row>

								: null}

							{serviceWorkItemType == "serviceRequest" ?
								<Row>
									<Col md={12} className="dCheckbox customCheckBoxContainer" >
										<Field component={_checkbox} className='form-control' name='editable_expectedby' label={tr['Changeable Expected Completion by Date']} onChange={this.onChangeFlag} checked={this.state.editable_expectedby == "true"} />
									</Col>
								</Row> : null}

							{serviceWorkItemType == "serviceRequest" ?
								<Row>
									<Col md={12} className="dCheckbox customCheckBoxContainer">
										<Field component={_checkbox} className='form-control' name='hide_approver_disclaimer' label={tr['Hide Approver Disclaimer']} onChange={this.onChangeFlag} checked={this.state.hide_approver_disclaimer == "true"} />
									</Col>
								</Row> : null}

							{serviceWorkItemType == "serviceRequest" ?
								<Row>
									<Col md={12} className="dCheckbox customCheckBoxContainer">
										<Field component={_checkbox} className='form-control' name='hide_service_disclaimer' label={tr['Hide Service Disclaimer']} onChange={this.onChangeFlag} checked={this.state.hide_service_disclaimer == "true"} />
									</Col>
								</Row> : null}
								{serviceWorkItemType == "serviceRequest" ?
							<Row>
								<Col md={12} className="dCheckbox customCheckBoxContainer">
									<Field component={_checkbox} className='form-control' name='hide_quantity' label={tr['Hide Quantity']} onChange={this.onChangeFlag} checked={this.state.hide_quantity == "true"} />
								</Col>
							</Row>:null}
							<Row>
								<Col md={12} className="dCheckbox customCheckBoxContainer">
									<Field component={_checkbox} className='form-control' name='restrict_language' label={tr['Language Restricted']} onChange={this.onChangeFlag} checked={this.state.restrict_language == "true"} />
								</Col>
							</Row>

							<Row>
								<Col md={12} className="dCheckbox customCheckBoxContainer">
									<Field component={_checkbox} className='form-control' name='external_offering' label={tr["External Offering"]} onChange={this.onChangeFlag} checked={this.state.external_offering == "true"} />
								</Col>
								
							</Row>
							{this.state.external_offering=="true"? <Row>
								<Col md={3} sm={4} xs={12} className="padding-r-0">
									<Form.Label className="padding-t-5" bsClass=""> {tr["Navigate To"]}
									</Form.Label>
								</Col>
								<Col md={9} sm={8} xs={12}>
									<Field component={_inputField} name="url" className="form-control"/>
								</Col>
							</Row> : ""}
				

							{serviceWorkItemType == "serviceRequest" ?
                           <Row>
	                            <Col md={12} className="dCheckbox customCheckBoxContainer">
 	                               	<Field component={_checkbox} className='form-control' name='minimum_optional_component' label={tr['Select Minimum One Optional Component']} onChange={this.onChangeFlag} checked={this.state.minimum_optional_component == "true"} />
                             	</Col>
                          </Row>
                          : null}
							<Row className='margin-t-10'>
								<Col md={12}>
									<ButtonToolbar className="black">
										<Button className='rgSidrkBtn' title="Save" disabled={this.props.spcmReducer.spcmPostingAttribute} type="submit" bsSize="small" bsStyle="primary">{this.props.spcmReducer.spcmPostingAttribute ? <ImSpinner6 className="icn-spinner"/> : null} {tr['Save']}</Button>
										<Button className='rgSiLigBtn' title="Close" onClick={() => { this.props.boardPageInActive4(false); }} bsSize="small" bsStyle="text">{tr['Close']}</Button></ButtonToolbar>
								</Col>
							</Row>
						</div></form> : null}
				{this.state.currentForm == "Integration_Preference" ?
					<form onSubmit={handleSubmit(this.submitForm)}>
						<div>
							<Row>
								<Col md={12} className="dCheckbox customCheckBoxContainer">
									<Field component={_checkbox} className='form-control' name='vSON' label={tr['Offering Name']} onChange={this.onChangeFlag} checked={this.state.vSON == "true"} />
								</Col>
							</Row>

							<Row>
								<Col md={12} className="dCheckbox customCheckBoxContainer">
									<Field component={_checkbox} className='form-control' name='vWIN' label={tr['Item Number']} onChange={this.onChangeFlag} checked={this.state.vWIN == "true"} />
								</Col>
							</Row>

							<Row>
								<Col md={12} className="dCheckbox customCheckBoxContainer">
									<Field component={_checkbox} className='form-control' name='vCOM' label={tr['Selected Components']} onChange={this.onChangeFlag} checked={this.state.vCOM == "true"} />
								</Col>
							</Row>

							<Row>
								<Col md={12} className="dCheckbox customCheckBoxContainer">
									<Field component={_checkbox} className='form-control' name='vSDT' label={tr['Submit Date']} onChange={this.onChangeFlag} checked={this.state.vSDT == "true"} />
								</Col>
							</Row>

							<Row>
								<Col md={12} className="dCheckbox customCheckBoxContainer">
									<Field component={_checkbox} className='form-control' name='vEDT' label={tr['Expected Completion by Date']} onChange={this.onChangeFlag} checked={this.state.vEDT == "true"} />
								</Col>
							</Row>

							<Row>
								<Col md={12} className="dCheckbox customCheckBoxContainer">
									<Field component={_checkbox} className='form-control' name='vINF' label={tr['Information Provided']} onChange={this.onChangeFlag} checked={this.state.vINF == "true"} />
								</Col>
							</Row>

							<Row>
								<Col md={12} className="dCheckbox customCheckBoxContainer">
									<Field component={_checkbox} className='form-control' name='vIGP' label={tr['Item Fulfillment Group']} onChange={this.onChangeFlag} checked={this.state.vIGP == "true"} />
								</Col>
							</Row>

							<Row>
								<Col md={12} className="dCheckbox customCheckBoxContainer">
									<Field component={_checkbox} className='form-control' name='vCGP' label={tr['Component Fulfillment Group']} onChange={this.onChangeFlag} checked={this.state.vCGP == "true"} />
								</Col>
							</Row>


							<Row className='margin-t-10'>
								<Col md={12}>
									<ButtonToolbar className="black">
										<Button className='rgSidrkBtn' title="Save" disabled={this.props.spcmReducer.spcmPostingAttribute} type="submit" bsSize="small" bsStyle="primary">{this.props.spcmReducer.spcmPostingAttribute ? <ImSpinner6 className="icn-spinner"/> : null} {tr['Save']}</Button>
										<Button className='rgSiLigBtn' title="Close" onClick={() => { this.props.boardPageInActive4(false); }} bsSize="small" bsStyle="text">{tr['Close']}</Button></ButtonToolbar>
								</Col>
							</Row>
						</div>
					</form> : null}

				{this.state.currentForm == 'Additional_Integration_Data' && this.props.componentFormToggle3 == false ? <AdditionalIntegrationList tr={this.props.tr} componentFormFun3={this.props.componentFormFun3} boardPageInActive4={this.props.boardPageInActive4} setExtraCount={this.setExtraCount} /> :
					null
				}

				{this.state.currentForm == "Additional_Integration_Data" && this.props.componentFormToggle3 ?
					<div>
						{/* {error == undefined || error == null ? null : <Alert variant="danger" className="errorMessageSec"><p>{error}</p></Alert>} */}
						<form onSubmit={handleSubmit(this.submitForm)}>
							<Table>
								<thead>
									<tr>
										<th width="43%">{tr['Attribute']}</th>
										<th width="43%">{tr['Value']}</th>
										<th width="14%">{tr['Action']}</th>
									</tr>
								</thead>
								<tbody>
									{this.loadCount(integrationDataKeywords)}
								</tbody>
							</Table>
							<Row>
								<Col md={12}>

									<ButtonToolbar className="black">
										<Button className='rgSidrkBtn' title="Save" disabled={this.props.spcmReducer.spcmPostingAttribute} type="submit" bsSize="small" bsStyle="primary">{this.props.spcmReducer.spcmPostingAttribute ? <ImSpinner6 className="icn-spinner"/> : null} {tr['Save']}</Button>
										<Button className='rgSiLigBtn' title="Close" onClick={() => { this.props.componentFormFun3(false); this.setState({ extraCounts: [0] }); reset(); }} bsSize="small" bsStyle="text">{tr['Close']}</Button></ButtonToolbar>
								</Col>
							</Row>
						</form></div> : null
				}


			</div>
		);
	}
}

const mapStateToProps = ({ spcmReducer }) => {
	const Preferences = Object.assign({}, spcmReducer.orderingPreferences, spcmReducer.integrationPreferences, spcmReducer.editLeftId > 0 ? spcmReducer.editLeftService : '');

	return {
		spcmReducer,
		initialValues: Preferences
	}
};
ComponentForm = reduxForm({
	form: 'orderingPreferencesForm',
	validate,
	enableReinitialize: true
})(ComponentForm)
export default connect(mapStateToProps, { spcmLoader, getOrderingPreferences, getIntegrationPreferences, setLeftEditId, getMenuData, getAdditionalIntegrationData })(ComponentForm);
