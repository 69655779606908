
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { connect } from "react-redux";

class Content extends React.Component {
   render() {
      return (
		<div aria-label={this.props.tr['Privacy Policy']} role="contentinfo" className="staticPage">
            <h1 className="rPageHeading">{this.props.tr['Privacy Policy']}</h1>

			<h2>Licensee Data and Databases</h2>
            <p>To assist Licensee in isolating the cause of a problem with the Program, HCL may request that Licensee (1) allow HCL to remotely or physically access Licensee’s system, or (2) send Licensee information or system data to HCL. However, HCL is not obligated to provide such assistance unless HCL and Licensee enter a separate written agreement under which HCL agrees to provide to Licensee that type of Support, which is beyond HCL’s warranty obligations in this Agreement. Licensee acknowledges that HCL uses information about errors and problems to improve its products and services, and assist with its provision of related support offerings. Licensee grants HCL the right to use such information and other feedback regarding the Programs and Third Party Software for these purposes, including the right to use HCL entities and subcontractors (including in one or more countries other than the one in which Licensee is located).</p>
            <p>Licensee remains responsible for (1) any data and the content of any database Licensee makes available to HCL (“<b>Licensee Data</b>”); (2) the selection and implementation of procedures and controls regarding access, security, encryption, use, and transmission of data (including any personally-identifiable data); and (3) backup and recovery of any database and any stored data, including all Licensee Data. Licensee will not send or provide HCL access to any personally-identifiable information, whether as part of Licensee Data or in electronic or any other form, and will be responsible for reasonable costs and other amounts that HCL may incur relating to any such information intentionally or mistakenly provided to HCL or to the loss or disclosure of such information by HCL, including liabilities arising out of any third party claims. Further, the Licensee shall take all measures to ensure the data security for the Licensee Data accessed through the Web. For avoidance of any doubt, HCL takes no liability and Licensee takes full responsibility and liability for any Licensee Data which is accessed through the Web by any of Licensees employee/end user.</p>

            <h2>Confidentiality</h2>
            <p>Except as otherwise expressly permitted in this Agreement, Licensee will hold in confidence the Programs, Third Party Software, Documentation and all other non-public or proprietary information made available by Licensor (“<b>Confidential Information</b>”). Licensee agrees that the Programs and Documentation furnished by HCL will be treated as proprietary trade secrets of Licensor, and Licensee will not make any Confidential Information available in any form to any person other than to its employees and to contractors located on its premises with a need to know, subject to restrictions no less stringent than those contained herein (in the case of non-employees such restrictions shall be contained in a written agreement executed by the applicable contractor). Licensee represents to Licensor that it maintains a system of confidentiality to protect its own confidential business information, including written agreements with employees, and that the Confidential Information will be protected by such system using no less than a reasonable degree of care. If Licensee at any time becomes aware of any unauthorized use or disclosure of Confidential Information, Licensee will promptly and fully notify the Licensor of all facts known to it concerning such unauthorized use or disclosure and reasonably cooperate with Licensor in seeking a protective order or other appropriate remedy to limit such disclosure.</p>
		</div>
      );
   }
}

const mapStateToProps = ({ spcmReducer }) => {
	return {
	tr: spcmReducer.tr
  
	};
  };
  export default connect(mapStateToProps,{})(Content);
