
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from "axios";
import { GLOBAL } from "../../components/Globals";
const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

export const fetchWatcherDetails = (module, itemId) => {
//   let config = {
//     headers: {
//       query: JSON.stringify({ module: module, itemId: itemId }),
//     },
//   };
  return (dispatch) => {
    dispatch({ type: "WATCHER_LOADER_IN_PROGRESS" });
    axios
      .get(`${GLOBAL.getWatcherDetails}?module=${module}&itemId=${itemId}`)
      .then((watcherData) => {
        if (!watcherData) {
          throw Error(watcherData.statusText);
        }
        return watcherData;
      })
      .then((watcherData) => {
        console.log("changeData.... ", watcherData);
        dispatch({
          type: "WATCHER_DETAILS_FETCHED",
          payload: watcherData.data,
        });
        dispatch({ type: "WATCHER_DETAILS_FETCHED_SUCCESSFULLY" });
      });
  };
};
