
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Popover from 'react-bootstrap/Popover';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import {Radio, Checkbox} from 'react-bootstrap';
import {loadOrdersList,loadFilterOrderList,resetMRQVFilters,resetSortingArrows,addToSelectedOrderTypeList,removeFromSelectedOrderTypeList,addToSelectedOrderStatusList,removeFromSelectedOrderStatusList,loadOrdersFilterResultsForSearchString} from '../../actions/cart/myOrders/myOrdersAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import {LuFilterX} from "react-icons/lu";
import {HiOutlineRefresh} from 'react-icons/hi';
import { Link } from 'react-router-dom';


const cookies = new Cookies();
let homepagelocation = cookies.get('gph');
if (homepagelocation){
homepagelocation = homepagelocation.replace('s:', '');
}
if (homepagelocation){
	homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
}
homepagelocation = homepagelocation.split('~');
homepagelocation = homepagelocation[0];
let email_address =  cookies.get("gph");
email_address = email_address.split("~");
email_address = email_address[29];
class MyRequestsHeader extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
		this.refreshTable = this.refreshTable.bind(this);
		this.applyAllOrderAndStatusFilters = this.applyAllOrderAndStatusFilters.bind(this);
		this.handleOrderTypeClick = this.handleOrderTypeClick.bind(this);
		this.handleStatusTypeClick = this.handleStatusTypeClick.bind(this);
		this.renderFilterOverlay = this.renderFilterOverlay.bind(this);
		this.clearFilters = this.clearFilters.bind(this);
	}
	componentDidMount() {
		this.props.addToSelectedOrderTypeList("Service Orders");
		this.props.addToSelectedOrderTypeList("Correction Orders");
		this.props.addToSelectedOrderStatusList("Open");
	}
	clearFilters() {
		console.log("this.props.filterValue", this.props.filterValue);
		this.props.showRightPanelFun(false);
		this.props.multiSelectOpenFun("myReqStatus", false);
		this.props.setdisplayNumberInputValues("");
		this.props.setCiServiceNameInputValues("");
		this.props.setRequestedByInputValues("");
		this.props.setSearchKeyValue([],[],[]);
		this.props.setSubmittedStartAndEndDate({}, {});
		this.props.setModifiedStartAndEndDate({}, {});
		this.props.updateParameter('20,10,15,65,70,-20,100,60');
		this.props.setSelectedValue([
			{ label: this.props.tr["Draft"], value: "-20" },
			{ label: this.props.tr['In Progress'], value: "15" },
			{ label: this.props.tr["On Hold"], value: "60" },
			{ label: this.props.tr['Pending'], value: "20" },
			{ label: this.props.tr["Pending Approval"], value: "65" },
			{ label: this.props.tr["Referred Back"], value: "100" },
			{ label: this.props.tr["Submitted"], value: "10" },
			{ label: this.props.tr["Under Fulfillment"], value: "70" },
		]);
		if (this.props.dateRangePickerRefSubmitted != undefined) {this.props.dateRangePickerRefSubmitted.endDate = moment();}
		if (this.props.dateRangePickerRefSubmitted != undefined) {this.props.dateRangePickerRefSubmitted.startDate = moment();}
		if (this.props.dateRangePickerRefModified != undefined) {this.props.dateRangePickerRefModified.endDate = moment();}
		if (this.props.dateRangePickerRefModified != undefined) {this.props.dateRangePickerRefModified.startDate = moment();}
		if(this.props.filterValue == 'watcher'){
		this.props.loadOrdersList("requesterId", "type", "", "displayNumber", "desc", 0, 10, this.props.filterValue, '20,10,15,65,70,-20,100,60',email_address);
		}else{
		// this.props.loadOrdersList("requesterId", "type", "", "displayNumber", "desc", 0, 10, this.props.listFilter, '20,10,15,65,70,-20,100,60');
			this.props.loadOrdersList("requesterId", "type", "", "createdOn", "desc", 0, 10, this.props.filterValue, '20,10,15,65,70,-20,100,60');		
		}
		this.props.updatePagination(0,10);
		this.props.isClearAllFilterFunc(false);
	}

	refreshTable() {
		this.props.showRightPanelFun(false);
		this.props.multiSelectOpenFun("myReqStatus", false);
		this.props.refreshClickFun(true);
		let searchParams = {};
		searchParams = this.props.buildSearchParamsForOrderFilter();
		if (_.isEmpty(searchParams)) {
			if (this.props.searchKey.length != 0 && this.props.searchValue.length != 0) {

				this.setState({ searchclick: true });
				if(this.props.filterValue == 'watcher'){

					this.props.loadOrdersFilterResultsForSearchString("requesterId", "type", this.props.searchKey, "", this.props.sortOnColumn, this.props.sortOrder, 0, 10, this.props.searchValue, this.props.filterValue, this.props.pattern,email_address,this.props.status);

				}else{

					// this.props.loadOrdersFilterResultsForSearchString("requesterId", "type", this.props.searchKey, "", this.props.sortOnColumn, this.props.sortOrder, 0, 10, this.props.searchValue, this.props.pattern, this.props.listFilter);
					this.props.loadOrdersFilterResultsForSearchString("requesterId", "type", this.props.searchKey, "", this.props.sortOnColumn, this.props.sortOrder, 0, 10, this.props.searchValue, this.props.pattern, this.props.filterValue);

				}
			}
			else {
			if(this.props.filterValue == 'watcher'){

				this.props.loadOrdersList("requesterId", "type", "", "displayNumber", "desc", 0, 10, this.props.filterValue, '20,10,15,65,70,-20,100,60',email_address);

			}else{

				this.props.loadOrdersList("requesterId", "type", "", "displayNumber", "desc", 0, 10, this.props.filterValue, '20,10,15,65,70,-20,100,60');
				
			}
				// this.props.loadOrdersList("requesterId", "type", "", "displayNumber", "desc", 0, 10, this.props.listFilter, '20,10,15,65,70,-20,100,60');
				this.props.resetSortingArrows();
			}
		}
		else {
			this.setState({ searchclick: true });
			if(this.props.filterValue == 'watcher'){
				this.props.loadFilterOrderList(searchParams, 0, 10, this.props.sortOnColumn, this.props.sortOrder, this.props.searchKey,
					this.props.searchValue, this.props.pattern, this.props.status,this.props.filterValue,email_address);
				
			}else{
				this.props.loadFilterOrderList(searchParams, 0, 10, this.props.sortOnColumn, this.props.sortOrder, this.props.searchKey,
					this.props.searchValue, this.props.pattern, this.props.status);
				
			}
			
		}
		this.props.updatePagination(0,10);
		this.props.showActiveRow(0, 0);
	}

	applyAllOrderAndStatusFilters(e) {
		document.body.click();
		this.props.multiSelectOpenFun("myReqStatus", false);
		this.props.showRightPanelFun(false);
		let searchParams = {};
		searchParams = this.props.buildSearchParamsForOrderFilter();
		if (_.isEmpty(searchParams)) {
			this.props.loadOrdersList("requesterId", "type", "", "displayNumber", "desc", 0, 10, this.props.listFilter, '20,10,15,65,70,-20,100,60');
			this.props.resetSortingArrows();
		} else {
			this.props.loadFilterOrderList(searchParams, 0, 10);
			this.props.resetSortingArrows();
		}
		this.props.showActiveRow(0, 0);
	}

	handleOrderTypeClick(e) {
		if (e.target.checked) {
			this.props.addToSelectedOrderTypeList(e.target.id);
		} else {
			this.props.removeFromSelectedOrderTypeList(e.target.id);
		}
		this.props.showActiveRow(0, 1);

	}

	handleStatusTypeClick(e) {
		if (e.target.checked) {
			this.props.addToSelectedOrderStatusList(e.target.id);
		} else {
			this.props.removeFromSelectedOrderStatusList(e.target.id);
		}
		this.props.showActiveRow(0, 1);
	}

	renderFilterOverlay() {
		const applyAllOrderAndStatusFilters = (e) =>
			this.applyAllOrderAndStatusFilters(e);
		let selectedOrderType = [];
		selectedOrderType = this.props.selectedOrderType;
		let statusOrderMarkup = ["Service Orders", "Correction Orders"].map(
			(item) => {
				let ischecked = (() => {
					if (_.includes(selectedOrderType, item)) {
						return true;
					} else {
						return false;
					}
				})();
				return (
					<ListGroup.Item bsClass="">
						<Checkbox
							id={item}
							checked={ischecked}
							onClick={(e) => this.handleOrderTypeClick(e)}
						>
							{this.props.tr[item]}
						</Checkbox>
					</ListGroup.Item>
				);
			}
		);

		let selectedStatus = [];
		selectedStatus = this.props.selectedStatus;
		let statusPopoverMarkup = ["Open", "Closed", "Cancelled"].map(
			(statusItem) => {
				let ischecked = (() => {
					if (_.includes(selectedStatus, statusItem)) {
						return true;
					} else {
						return false;
					}
				})();
				return (
					<ListGroup.Item bsClass="">
						<Checkbox
							id={statusItem}
							checked={ischecked}
							onClick={(e) => this.handleStatusTypeClick(e)}
						>
							{this.props.tr[statusItem]}
						</Checkbox>
					</ListGroup.Item>
				);
			}
		);

		return (
			<Popover className="paHedFilterPopup" id="popoverFilters">
				<div className="filterPopup">
					<h2>Requested</h2>
					<ListGroup className="poplnk" bsClass="">
						<ListGroup.Item bsClass="" >
							<Radio
								inline value="onBehalf"
								onClick={(e) => this.props.ordersListFilter(e.target.value)}
								name="ordersListfilter"
								defaultChecked={this.props.listFilter == 'onBehalf'}>
								For You
							</Radio>
						</ListGroup.Item>
						<ListGroup.Item bsClass="">
							<Radio
								inline value="self"
								onClick={(e) => this.props.ordersListFilter(e.target.value)}
								name="ordersListfilter"
								defaultChecked={this.props.listFilter == 'self'}>
								By You
							</Radio>
						</ListGroup.Item>
					</ListGroup>

					<h2>{this.props.tr['Module']}</h2>
					<ListGroup className="poplnk" bsClass="">
						{statusOrderMarkup}
					</ListGroup>

					<h2>{this.props.tr['Status']}</h2>
					<ListGroup className="poplnk" bsClass="">
						{statusPopoverMarkup}
					</ListGroup>
					<Button
						className="margin-t-0"
						bsSize="small"
						onClick={applyAllOrderAndStatusFilters}>
						{this.props.tr['Apply']}
					</Button>
				</div>
			</Popover>
		);
	}
	render() {
		return (
			<>
			<Row className="margin-b-15">
			<Col lg={10} md={10} sm={12} xs={12}>
					<ul className="wibTabs">
						<li className="active"><Link title={this.props.tr['My Requests']} to="/myOrders">{this.props.tr['My Requests']}</Link></li>
						{this.props.walkupEnabled.walkupEnabled && <li><Link title={this.props.tr["My Walk Up Requests"]} to="/walkUpReq">{this.props.tr["My Walk Up Requests"]}</Link></li>}
						<li><Link title={this.props.tr['My Assets/CIs']} to="/myAssets">{this.props.tr['My Assets/CIs']}</Link></li>
						<li><Link title={this.props.tr["My Notifications"]} to="/myNotifications">{this.props.tr["My Notifications"]}</Link></li>
					</ul>
				</Col>
				<Col lg={2} md={2} sm={12} xs={12}>
					<div aria-label='Page Filters' role="contentinfo" className="paHedFilter">
						{/* <div aria-label='Filter Icons' className="reqSwitdv">
							{this.props.isSelf ? this.props.tr["Self"] : this.props.tr["Others"]}
							<label
								aria-label={this.props.isSelf ? this.props.tr["Self"] : this.props.tr["Others"]}
								title={this.props.isSelf ? this.props.tr["Self"] : this.props.tr["Others"]}
								className="reqSwitch">
								<input
									aria-label={this.props.isSelf ? "onBehalf" : "self"}
									type="checkbox"
									value={this.props.isSelf ? "onBehalf" : "self"}
									onClick={(e) => {this.props.ordersListFilter(e.target.value); this.props.multiSelectOpenFun("myReqStatus", false);}}
								/>
								<span
									onClick={() => { this.props.isSelfFun(); }}
									className="slider">
								</span>
							</label>
						</div> */}
						{
							this.props.isClearAllFilter ?
							<div className="overlayDv">
								<Button
									title={this.props.tr["Clear All Filters"]}
									role="button"
									onClick={() => { this.clearFilters(); }}
									bsPrefix=' '
									className="myBt clrbtn"
								>
									<LuFilterX/>
								</Button>
							</div> : ""
						}
						<Button
							aria-label={this.props.tr["Refresh"]}
							title={this.props.tr["Refresh"]}
							role="button"
							
							bsClass=""
							bsPrefix=' '
							className="myBt"
							onClick={() => { this.refreshTable(); }}>
							<HiOutlineRefresh className='refreshFlip'/></Button>
					</div>
				</Col>
			</Row>
			<Row className="margin-b-10">
              <Col lg={8} md={12} sm={12} xs={12}>
                <div aria-label="Table Filter" role="contentinfo" id="assFiltr">
					<div className="filtr">
                    <Button
                        className={this.props.filterTabActive == 1 ? "active" : null}
                        title={this.props.tr["Self"]}
                        bsPrefix=" "
						value='self'
						onClick={(e) => {this.props.isSelfFun(e.target.value);this.props.ordersListFilter(e.target.value); this.props.multiSelectOpenFun("myReqStatus", false);}}
						>
                        {this.props.tr["Self"]}
                      </Button>
					  <Button
                        className={this.props.filterTabActive == 2 ? "active" : null}
                        title={this.props.tr["Others"]}
                        bsPrefix=" "
						value='onBehalf'
						onClick={(e) => {this.props.isSelfFun(e.target.value);this.props.ordersListFilter(e.target.value); this.props.multiSelectOpenFun("myReqStatus", false);}}
						>
                        {this.props.tr["Others"]}
                      </Button>
					  <Button
                        className={this.props.filterTabActive == 3 ? "active" : null}
                        title={this.props.tr["Watching"]}
                        bsPrefix=" "
						value='watcher'
						onClick={(e) => {this.props.isSelfFun(e.target.value);this.props.ordersListFilter(e.target.value); this.props.multiSelectOpenFun("myReqStatus", false);}}
						>
                        {this.props.tr["Watching"]}
                      </Button>
                  </div>
				</div>
				</Col>
			</Row>
			</>
		);
	}
}
MyRequestsHeader.propTypes = {
	tr: PropTypes.object,
	loadFilterOrderList: PropTypes.func,
	loadOrdersList: PropTypes.func,
	resetMRQVFilters: PropTypes.func,
	resetSortingArrows: PropTypes.func,
	showActiveRow: PropTypes.func,
	ordersListFilter: PropTypes.func,
	buildSearchParamsForOrderFilter: PropTypes.func,
	addToSelectedOrderStatusList: PropTypes.func,
	removeFromSelectedOrderStatusList: PropTypes.func,
	addToSelectedOrderTypeList: PropTypes.func,
	removeFromSelectedOrderTypeList: PropTypes.func,
	showRightPanelFun: PropTypes.func,
	listFilter: PropTypes.string,
};

export function mapStateToProps({ ordersList, myOrderViewFilters, walkupEnabled }) {
	let data = ordersList.data;
	if (data === undefined){
		data = [];
	}
	return {
		ordersList: data,
		ordersListMeta: ordersList.meta,
		addToSelectedOrderStatusList:
		myOrderViewFilters.addToSelectedOrderStatusList,
		removeFromSelectedOrderStatusList:
		myOrderViewFilters.removeFromSelectedOrderStatusList,
		addToSelectedOrderTypeList: myOrderViewFilters.addToSelectedOrderTypeList,
		removeFromSelectedOrderTypeList:
		myOrderViewFilters.removeFromSelectedOrderTypeList,
		selectedStatus: myOrderViewFilters.selectedStatus,
		selectedOrderType: myOrderViewFilters.selectedOrderType,
		walkupEnabled: walkupEnabled
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			loadOrdersList,
			loadFilterOrderList,
			resetMRQVFilters,
			resetSortingArrows,
			addToSelectedOrderTypeList,
			removeFromSelectedOrderTypeList,
			addToSelectedOrderStatusList,
			removeFromSelectedOrderStatusList,
			loadOrdersFilterResultsForSearchString,
		}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MyRequestsHeader);

