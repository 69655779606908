
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import { getAllFroums } from "../../../reducers/foundation/forum/forum";
import {HiPlus, HiOutlineRefresh} from "react-icons/hi";
import { LuFilterX } from "react-icons/lu";

const Header = (props) => {
  const dispatch = useDispatch();
  const tr = useSelector((state) => state.spcmReducer.tr);

  const refresh = async () => {
    props?.setIsBusy(true);
    try {
      await dispatch(getAllFroums({ pageNum: 0, pgSize: 25 }));
    } catch (err) {
      props?.setIsBusy(false);
    } finally {
      props?.setIsBusy(false);
    }
  };

const resetFilters = async ()=>{
  props.setPageNum(0);
  props.setPgSize(10);
  props.setFilterString("");
  props.setIsFilterCleared(true);
  await dispatch(getAllFroums({ pageNum: 0, pgSize: 10, filtersString: '' }));
}

  const defaultSettings = "";

  useEffect(() => {
    const isDefault = props.filterString === defaultSettings

    props?.setShowFilterIcon(!isDefault);
  }, [props?.filterString]);

  return (
    <Row className="margin-b-15">
      <Col sm={8} xs={7}>
        <h1 bsClass="" className="sPageHeading1">
          {tr["Forum"]}
        </h1>
      </Col>
      <Col sm={4} xs={5}>
        <div className="paHedFilter">
          <Link
            to="/createForum"
            role="button"
            className="myBt plus fillBtn"
            title="Create"
          >
            <HiPlus/>
          </Link>
          {props?.showFilterIcon && (
            <div className="overlayDv">
              <Button
                title="Clear All Filters"
                role="button"
                bsPrefix=" "
                className="myBt clrbtn"
                onClick={resetFilters}
              >
                <LuFilterX />
              </Button>
            </div>
          )}
          <Button
            bsPrefix=" "
            title="Refresh"
            role="button"
            className="myBt"
            onClick={refresh}
          >
            <HiOutlineRefresh className="refreshFlip"/>
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default Header;
