
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export default function (state = initialState.bfRelatedWorkCheckList, action){
    let newselectedArray = [];
//   console.log("action.payload ",action)
	switch (action.type){
		case 'ADD_TO_RELATEDWORK_CHECK_LIST':
			return [...state, action.payload];
		case 'REMOVE_FROM_RELATEDWORK_CHECK_LIST':
			return  state.filter((item) => {return item.bfId != action.payload.bfId}) 
		case 'ADD_ALL_TO_RELATEDWORK_CHECK_LIST':
			return action.payload;
		case 'RESET_RELATEDWORK_CHECK_LIST':
			return []
		default:
				return state;
		}

}
