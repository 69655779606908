
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import { GLOBAL } from '_Globals';

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

export function languageListSuccess(languageList){
	return {
		type: 'LANGUAGE_DETAILS_SUCCESS',
		languageList
	};
}


export function loadLanguagePreferences(Language){
	var str = '';
	if (Language != '') {
		str += "type=" + Language;
	}
	return(dispatch) =>{
				api.get(GLOBAL.languageList + "?" + str)
					.then((languageList)=>{
						if(!languageList){
							throw Error(languageList.statusText);
						}
						return languageList;
					})
					.then((languageList) =>{
						dispatch(languageListSuccess(languageList))
					})
					.catch((err) => {
						console.log(err);
					});

	};
}
