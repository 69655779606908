
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Cookies from "universal-cookie";

import PindCategories from "../common/header/pind-categories";
import MyApprovalsHeader from "./MyApprovalsHeader";
import ListViewMyApprovals from "./ListViewApprovals";
import MyApprovalsShortDesc from "./MyApprovalsShortDesc";
import { useSelector } from "react-redux";
import { GLOBAL } from "../Globals";
import Breadcrumbs from "../common/header/breadcrumbs";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import "_Css/common/_table.scss";

const MyApprovalsIndex = () => {
  const [items, setItems] = useState([
    { name: "Approved", isChecked: false },
    { name: "Rejected", isChecked: false },
    { name: "Pending", isChecked: false },
    { name: "Refer Back", isChecked: false },
  ]);
  const [module, setModule] = useState([
    { name: "Service", id: "10", isChecked: false },
    { name: "RFC", id: "14", isChecked: false },
  ]);
  const [subMenu, setSubMenu] = useState("19");
  const [approvalDetailsObj, setApprovalDetailsObj] = useState("");
  const [listingMessage, setListingMessage] = useState("");
  const [detailsMessage, setDetailsMessage] = useState("");
  const [itemNumber, setItemNumber] = useState("");
  const [offeringNameSx, setOfferingNameSx] = useState("");
  const [createdByName, setcreatedByName] = useState("");
  const [createdOn, setcreatedOn] = useState("");
  const [approvalStatus, setApprovalStatus] = useState("");
  const [deliveryType, setDeliveryType] = useState("");
  const [itemPrice, setItemPrice] = useState("");
  const [mobileLeftMenuFilter, setMobileLeftMenuFilter] = useState(false);
  const [user_Id, setUser_Id] = useState("1");
  const [modules, setModules] = useState("10,14");
  const [page, setPage] = useState("1");
  const [showHideAlfy, setShowHideAlfy] = useState(false);
  const [limit, setLimit] = useState("5");
  const [status, setStatus] = useState("pending");
  const [itemDisclaimer, setItemDisclaimer] = useState(false);
  const [rfcDisclaimer, setRfcDisclaimer] = useState(false);
  const [rejectSecHideShow, setrejectSecHideShow] = useState(false);
  const [approveSecHideShow, setapproveSecHideShow] = useState(false);
  const [referBackSecHideShow, setreferBackSecHideShow] = useState(false);
  const [searchFilter, setsearchFilter] = useState("orderNumber");
  const [checkSearchtextField, setcheckSearchtextField] = useState(false);
  const [valueStatusSearchInput, setvalueStatusSearchInput] = useState("0");
  const [valueSearchInput, setvalueSearchInput] = useState("");
  const [enableSearch, setenableSearch] = useState(false);
  const [loadRerenderApis, setloadRerenderApis] = useState(false);
  const [rowIndex, setrowIndex] = useState(0);
  const [isRightSideVisible, setIsRightSideVisible] = useState(false);
  const [showCreateAdhocApproverPanel, setshowCreateAdhocApproverPanel] =
    useState(false);
  const [showApproversList, setshowApproversList] = useState(false);
  const [sortOrder, setsortOrder] = useState("desc");
  const [sortOnColumn, setsortOnColumn] = useState("submittedOn");
  const [searchKey, setsearchKey] = useState("");
  const [searchValue, setsearchValue] = useState("");
  const [isClearAllFilter, setisClearAllFilter] = useState(false);
  const [displayNumberSearchInput, setdisplayNumberSearchInput] = useState("");
  const [ciServiceNameSearchInput, setciServiceNameSearchInput] = useState("");
  const [requestedBySearchInput, setrequestedBySearchInput] = useState("");
  const [startDate, setstartDate] = useState({});
  const [endDate, setendDate] = useState({});
  const [selectedValue, setselectedValue] = useState([]);
  const [resetAccordiansStateCount, setresetAccordiansStateCount] = useState(0);
  const [panelResize,setPanelResize] = useState();
  const [inputRef, setInputRef] = useState();
  const [isStatusOpen, setStatusOpen] = useState(false);

  const tr = useSelector((state) => state.spcmReducer.tr);
  const approvalList = useSelector((state) => state.approvalList);
  const showLoader = useSelector((state) => state.showLoader.loading);

  const setRef = (ref) => {
    setInputRef(ref);
  };

  const changeSearchInput = (value) => {
    if (checkSearchtextField) {
      setvalueSearchInput(value);
    } else setvalueSearchInput(value);
  };

  const rejectSecHideShowFunc = () => {
    setapproveSecHideShow(false);
    setreferBackSecHideShow(false);
    setrejectSecHideShow(!rejectSecHideShow);
  };

  const approveSecHideShowFunc = () => {
    setrejectSecHideShow(false);
    setreferBackSecHideShow(false);
    setapproveSecHideShow(!approveSecHideShow);
  };

  const referBackSecHideShowFunc = () => {
    setrejectSecHideShow(false);
    setreferBackSecHideShow(!referBackSecHideShow);
    setapproveSecHideShow(false);
  };

  const approveRejSecHide = () => {
    setrejectSecHideShow(false);
    setapproveSecHideShow(false);
    setreferBackSecHideShow(false);
    setshowCreateAdhocApproverPanel(false);
    setshowApproversList(false);
  };

  //Updating Module function
  const updateModuleParameter = (modules) => {
    let tempModule = [10, 14];
    let setModules = [];
    for (let i = 0; i < modules.length; i++) {
      if (modules[i].isChecked === true) setModules.push(modules[i].id);
    }
    if (setModules.length > 0) {
      setModule(setModules);
    } else {
      setModule(tempModule);
    }
  };

  const setSearchKeyValue = (key, value) => {
    setsearchKey(key);
    setsearchValue(value);
  };

  const getApprovalsDetails = (
    itemNumber,
    offeringNameSx,
    createdByName,
    createdOn,
    approvalStatus,
    deliveryType,
    itemPrice
  ) => {
    setItemNumber(itemNumber);
    setOfferingNameSx(offeringNameSx);
    setcreatedByName(createdByName);
    setcreatedOn(createdOn);
    setApprovalStatus(
      approvalStatus == "null" || approvalStatus == ""
        ? "Pending Approval"
        : approvalStatus
    );
    setDeliveryType(deliveryType);
    setItemPrice(itemPrice);
  };

  const changeCompMessage = (message, msgFor) => {
    if (msgFor == "listView") {
      setListingMessage(message);
    }
    if (msgFor == "detailsView") {
      setDetailsMessage(msgFor);
    }
  };
  const setSortOrderFun = (sortBy, sortOn) => {
    setsortOrder(sortBy);
    setsortOnColumn(sortOn);
  };

  const setStartAndEndDate = (startDate, endDate) => {
    setstartDate(startDate);
    setendDate(endDate);
  };

  const resetAccordiansState = () => {
    setresetAccordiansStateCount(resetAccordiansStateCount + 1);
  };

  const setstatus = (value) => {
    setItems([...value]);
  };

  const multiSelectOpenFun = (keyName, val) => {
		switch (keyName) {
		  case "myAprvlStatus": 
        {          
          setStatusOpen(val);
        };
			break;
		}
  }

  let meta = approvalList.meta;

  let size = meta && meta.limit ? meta.limit : 0;
  let currentPage = meta && meta.currentPage ? meta.currentPage : 0;

  let startLimit = size * (currentPage - 1) + 1;
  let endLimit = size * currentPage;
  let recordCount = meta && meta.rowCount ? meta.rowCount : 0;

  let colWidth = "";
  isRightSideVisible ? (colWidth = 8) : (colWidth = 12);

  return (
    <main>
      <div>
        <PindCategories />
        <Container fluid className="margin-b-15">
          <Row>
            <Col md={8} xs={8}>          
              <Breadcrumbs activePageName={"My Approvals"} />
            </Col>
            <Col md={4} xs={4}>
              {recordCount != 0 ? (
                <div className="tabDatacount">
                  {startLimit}-
                  {endLimit > recordCount ? (endLimit = recordCount) : endLimit} of{" "}
                  {recordCount}
                </div>
              ) : null}
            </Col>
          </Row>
        </Container>

        <Container fluid>
          {/* Home Page blue bar Section Start*/}
          <MyApprovalsHeader
            searchValue={searchValue}
            searchKey={searchKey}
            sortOrder={sortOrder}
            setSortOrder={setSortOrderFun}
            sortOnColumn={sortOnColumn}
            setSearchKeyValue={setSearchKeyValue}
            status={status}
            showRightSide={setIsRightSideVisible}
            isRightSideVisible={isRightSideVisible}
            isClearAllFilter={isClearAllFilter}
            isClearAllFilterFunc={setisClearAllFilter}
            displayNumberSearchInput={displayNumberSearchInput}
            ciServiceNameSearchInput={ciServiceNameSearchInput}
            requestedBySearchInput={requestedBySearchInput}
            startDate={startDate}
            endDate={endDate}
            updateParameter={setStatus}
            setStartAndEndDate={setStartAndEndDate}
            setdisplayNumberInputValues={setdisplayNumberSearchInput}
            setCiServiceNameInputValues={setciServiceNameSearchInput}
            setRequestedByInputValues={setrequestedBySearchInput}
            setSelectedValue={setselectedValue}
            selectedValue={selectedValue}
            setRef={setRef}
            dateRangePickerRef={inputRef}
            updatePagination={setLimit}
          />
          {/* Home Page blue bar Section end*/}

          <PanelGroup direction="horizontal" className={(isStatusOpen) && (approvalList.approverList == undefined || approvalList.approverList?.length <=6 || showLoader) ? "overflow-clip formGroupB10 myProfileLabel myOrders" : "formGroupB10 myProfileLabel myOrders"}>
            <Panel id="sidebar" minSize={33} order={1} defaultSize={isRightSideVisible ? 67 : 100} onResize={(size) => setPanelResize(size)} className={`${(isStatusOpen) && (approvalList.approverList == undefined || approvalList.approverList?.length <=6 || showLoader) ? "overflow-clip" : " "} ${isRightSideVisible ? "isShowLeftPanel rwLeftPart" : "rwLeftPart"}`}>
              <ListViewMyApprovals
                getApprovalsDetails={getApprovalsDetails}
                changeCompMessage={changeCompMessage}
                listingMessage={listingMessage}
                setApprovalDetailsObj={setApprovalDetailsObj}
                updatePagination={setLimit}
                user_Id={user_Id}
                status={status}
                page={page}
                limit={limit}
                modules={modules}
                itemDisclaimerChange={setItemDisclaimer}
                rfcDisclaimerChange={setRfcDisclaimer}
                approveRejSecHide={approveRejSecHide}
                updateModuleParameter={updateModuleParameter}
                updateParameter={setStatus}
                searchFilter={searchFilter}
                setSelectedSearchFilter={setsearchFilter}
                changeSearchInput={changeSearchInput}
                checkSearchtextField={checkSearchtextField}
                valueStatusSearchInput={valueStatusSearchInput}
                valueSearchInput={valueSearchInput}
                isSearchEnabled={setenableSearch}
                enableSearch={enableSearch}
                getLoadRerenderApis={setloadRerenderApis}
                loadRerenderApis={loadRerenderApis}
                items={items}
                module={module}
                setstatus={setstatus}
                setModule={setModule}
                rowIndex={rowIndex}
                showActiveRow={setrowIndex}
                showRightSide={setIsRightSideVisible}
                isRightSideVisible={isRightSideVisible}
                sortOrder={sortOrder}
                setSortOrder={setSortOrderFun}
                sortOnColumn={sortOnColumn}
                setSearchKeyValue={setSearchKeyValue}
                searchValue={searchValue}
                searchKey={searchKey}
                isClearAllFilter={isClearAllFilter}
                isClearAllFilterFunc={setisClearAllFilter}
                displayNumberSearchInput={displayNumberSearchInput}
                ciServiceNameSearchInput={ciServiceNameSearchInput}
                requestedBySearchInput={requestedBySearchInput}
                startDate={startDate}
                endDate={endDate}
                setStartAndEndDate={setStartAndEndDate}
                setdisplayNumberInputValues={setdisplayNumberSearchInput}
                setCiServiceNameInputValues={setciServiceNameSearchInput}
                setRequestedByInputValues={setrequestedBySearchInput}
                setSelectedValue={setselectedValue}
                selectedValue={selectedValue}
                setRef={setRef}
                dateRangePickerRef={inputRef}
                resetAccordiansState={resetAccordiansState}
                panelResize={panelResize}
                isStatusOpen={isStatusOpen}
                multiSelectOpenFun={multiSelectOpenFun}
              />
            </Panel>
            {isRightSideVisible ?
              <>
                <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
                <Panel minSize={33} order={2} defaultSize={33}>
                  <div className="stickyArea rBoxStyle">
                    {/* Xsm BreakFix Action Section Start*/}
                    <MyApprovalsShortDesc
                      changeCompMessage={changeCompMessage}
                      detailsMessage={detailsMessage}
                      approvalObj={approvalDetailsObj}
                      user_Id={user_Id}
                      status={status}
                      page={page}
                      limit={limit}
                      module={module}
                      modules={modules}
                      itemDisclaimerChange={setItemDisclaimer}
                      itemDisclaimer={itemDisclaimer}
                      rfcDisclaimerChange={setRfcDisclaimer}
                      rfcDisclaimer={rfcDisclaimer}
                      rejectSecHideShow={rejectSecHideShow}
                      approveSecHideShow={approveSecHideShow}
                      changeRejectSecHideShow={setrejectSecHideShow}
                      changeApproveSecHideShow={setapproveSecHideShow}
                      rejectSecHideShowFunc={rejectSecHideShowFunc}
                      approveSecHideShowFunc={approveSecHideShowFunc}
                      referBackSecHideShowFunc={referBackSecHideShowFunc}
                      changeReferBackSecHideShow={setreferBackSecHideShow}
                      referBackSecHideShow={referBackSecHideShow}
                      showCreateAdhocApproverPanel={showCreateAdhocApproverPanel}
                      showCreateAdhocApproverPanelToggle={
                        setshowCreateAdhocApproverPanel
                      }
                      showApproversListToggle={setshowApproversList}
                      showApproversList={showApproversList}
                      showActiveRow={setrowIndex}
                      rowIndex={rowIndex}
                      showRightSide={setIsRightSideVisible}
                      isRightSideVisible={isRightSideVisible}
                      sortOrder={sortOrder}
                      setSortOrder={setSortOrderFun}
                      sortOnColumn={sortOnColumn}
                      setSearchKeyValue={setSearchKeyValue}
                      searchValue={searchValue}
                      searchKey={searchKey}
                      isClearAllFilter={isClearAllFilter}
                      isClearAllFilterFunc={setisClearAllFilter}
                      displayNumberSearchInput={displayNumberSearchInput}
                      ciServiceNameSearchInput={ciServiceNameSearchInput}
                      requestedBySearchInput={requestedBySearchInput}
                      startDate={startDate}
                      endDate={endDate}
                      setStartAndEndDate={setStartAndEndDate}
                      setdisplayNumberInputValues={setdisplayNumberSearchInput}
                      setCiServiceNameInputValues={setciServiceNameSearchInput}
                      setRequestedByInputValues={setrequestedBySearchInput}
                      setSelectedValue={setselectedValue}
                      selectedValue={selectedValue}
                      setRef={setRef}
                      dateRangePickerRef={inputRef}
                      resetAccordiansStateCount={resetAccordiansStateCount}
                    />
                    {/* Xsm BreakFix Action Section End*/}
                  </div>
                </Panel>
              </> : ""
            }
          </PanelGroup>
        </Container>
      </div>
    </main>
  );
};

export default MyApprovalsIndex;
