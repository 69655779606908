
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { reduxForm } from "redux-form";
import { patchTemplateFormNE,overRideCustomTemplate } from "../../../../actions/spcmActions";
import Swal from "sweetalert2";
import axios from 'axios';
import Cookies from "universal-cookie";
import CustumNotificationTemplateCreateForm from "./custumNotificationTemplateCreateForm";
import CustomNotificationDesc from "./customNotificationDesc";
import Breadcrumbs from "../../../common/header/breadcrumbs";
import { navigationHooks } from '../../../../helpers/NavigationHook';
import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { LuFileText } from "react-icons/lu";
import { MyContext } from '_MyContext';
import "_Css/form/_form.scss"
const cookies = new Cookies();
let parsedCookie =  cookies.get("gph");
parsedCookie = parsedCookie.split("~");
let createdby = parsedCookie[43];
let createdbyName = parsedCookie[42];

let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );

const NotificationTemplateOverride = (props) => {
  const translator = useSelector((state) => state.spcmReducer.tr);
  const neReducer = useSelector((state) => state.neReducer);
  const customVariablesReducer = useSelector((state) => state.customVariablesReducer);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showLoader, setShowLoader] = useState(false);
  const [changedStatus, setChangedStatus] = useState("");
  const [STATUS, setSTATUS] = useState("");
  const [MODULE_NAME, setMODULE_NAME] = useState("");
  const [NOTIFICATION_NAME, setNOTIFICATION_NAME] = useState("");
  const [SUBJECT, setSUBJECT] = useState("");
  const [MESSAGE, setMESSAGE] = useState("");
  const [TO_EMAIL, setTO_EMAIL] = useState("");
  const [CC_EMAIL, setCC_EMAIL] = useState("");
  const [COMPANY_NAME, setCOMPANY_NAME] = useState("");
  const [EVENT_TYPE, setEVENT_TYPE] = useState("");
  const [MODID, setMODID] = useState("");
  const [COMPANY_ID, setCOMPANY_ID] = useState("");
  const [COMPILED_TEMPLATE, setCOMPILED_TEMPLATE] = useState("");
  const [TEMPLATE_FOOTER, setTEMPLATE_FOOTER] = useState("");
  const [TEMPLATE_HEADER, setTEMPLATE_HEADER] = useState("");
  const [TEMPLATE_BODY, setTEMPLATE_BODY] = useState("");
  const [BODY, setBODY] = useState("");
  const { id } = useParams();
  const { localeId } = useParams();
  const windowSize = useSelector((state) => state.windowSize.width);
  const [isRightEditPanel, setrightEditPanel] = useState(true);
  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const saveObjRef = useRef(null);

  useEffect(() => {
    context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
    context.newTabPrevent();
	}, []);

  const rightEditPanel = (value) => {
    setrightEditPanel(value);
  };

  useEffect(() => {
    if( windowSize < 767 ) {
      setrightEditPanel(false);
      }
    else {
      setrightEditPanel(true);
    }
  }, [windowSize]);

  const loadHome = () => {
    navigate(homepagelocation);
  };
  const renderEditForm = (
    STATUS,
    MODULE_NAME,
    NOTIFICATION_NAME,
    SUBJECT,
    MESSAGE,
    TO_EMAIL,
    CC_EMAIL,
    COMPANY_NAME,
    EVENT_TYPE,
    MODID,
    COMPANY_ID,
    TEMPLATE_BODY,
    COMPILED_TEMPLATE,
    TEMPLATE_FOOTER,
    TEMPLATE_HEADER,
    
  ) => {
    setSTATUS(STATUS);
    setMESSAGE(MESSAGE);
    setMODULE_NAME(MODULE_NAME);
    setNOTIFICATION_NAME(NOTIFICATION_NAME);
    setSUBJECT(SUBJECT);
    setMODID(MODID);
    setTO_EMAIL(TO_EMAIL);
    setCC_EMAIL(CC_EMAIL);
    setCOMPANY_NAME(COMPANY_NAME);
    setEVENT_TYPE(EVENT_TYPE);
    setCOMPANY_ID(COMPANY_ID);
    setCOMPILED_TEMPLATE(COMPILED_TEMPLATE);
    setTEMPLATE_FOOTER(TEMPLATE_FOOTER);
    setTEMPLATE_HEADER(TEMPLATE_HEADER);
    setTEMPLATE_BODY(TEMPLATE_BODY);
  };

  const ValidateSubject = (string) => {

    const array = string.split("<%");
    let variables = [];
    array.forEach((res) => {
      let startIndex = res.indexOf("=");
      let newString = res.replace("=", "");
      let endIndex = newString.indexOf("%>");
      let variable = newString.substr(startIndex, endIndex);      
      if (variable.length > 0) {
        variables.push("<%=" + variable + "%>");
      }
    });
    
    if (variables.length == 0) {
      return "plainText";
    } else {
      let availabilityCount = 0;
      variables.forEach((variable) => {
        customVariablesReducer.forEach((extVar) => {
          if (extVar.key.toString() == variable.toString()) {
            availabilityCount ++;
          }
        });
      });
      if (availabilityCount == variables.length) {
        //when Subject variables matches with the variables in the list
        return "passed";
      }
      else {
        //when Subject variables do not get matched with the variables in the list
        return "failed";
      }
    }
  }
  const submit = (values) => {
    console.log("VALUES=======",values);
    console.log("customVariablesReducer", customVariablesReducer);
    let validateSubject = ValidateSubject(values.SUBJECT);
    if (validateSubject == "failed"){
      Swal.fire(       
        "Invalid Variables"
      )
      return ;
    } 
   
    document.getElementById("div1")?.remove();
    let final; 

    if (values.TEMPLATE_BODY.includes(`<table border="0" cellpadding="0" cellspacing="0" width="100%" bgcolor="#ffffff" style="padding:0;color:#000;font-size:12px;font-family:Verdana,Geneva,sans-serif;line-height:20px;"><tbody><tr><td style="padding:20px 15px 10px 15px"><div id="bodyPart"></div></td></tr></tbody></table>`)) {
      Swal.fire(
        "Please Add the Body Text"
      )
      return false;
    } else if (values.TEMPLATE_BODY.includes("div1")) {
      // this condition is for the case when you just click on any variable and do not select any value from the dropdown in that case the whole dropdown options are going in to the response
      let part1 = values.TEMPLATE_BODY.split("<select");
      let part2 = values.TEMPLATE_BODY.split("</select>");

      final = part1[0] + part2[1];
      if(final.includes(`<span tabindex="0" role="link" class="varModify" style="font-weight: 600;">&lt;%=Select%&gt;</span>`)){
        final = final.replace(`<span tabindex="0" role="link" class="varModify" style="font-weight: 600;">&lt;%=Select%&gt;</span>`,'');
      }

    } else {
      final = values.TEMPLATE_BODY;
      if(final.includes(`<span tabindex="0" role="link" class="varModify" style="font-weight: 600;">&lt;%=Select%&gt;</span>`)){
        final = final.replace(`<span tabindex="0" role="link" class="varModify" style="font-weight: 600;">&lt;%=Select%&gt;</span>`,'');
      }
    }
    let payload = {
      "id":values.ID,
      "templateName":values.NOTIFICATION_NAME,
      "templateCode":10,
      "notificationName":values.NOTIFICATION_NAME,
      "tenantId":values.COMPANY_NAME,
      "companyId":values.COMPANY_ID,
      "localeId":values.LOCALE_ID,
      "subject":values.SUBJECT,
      "status": values.STATUS,
      "notificationId":values.ID.toString(),
      "isVisible":"Y",
      "module":values.MODULE_NAME,
      "mainTemplate":"",
      "templateHeader":values.TEMPLATE_HEADER,
      // "templateBody":values.MESSAGE,
      // "templateBody":values.TEMPLATE_BODY,
      "templateBody":final,
      "templateFooter":values.TEMPLATE_FOOTER,
      "compiledTemplate":values.COMPILED_TEMPLATE,
      "createdby":createdby,
      "createdbyName":createdbyName
    }
    // setBODY(values.TEMPLATE_BODY);
    // dispatch(
    //   overRideCustomTemplate(payload)
    // );
    if (validateSubject == "plainText") {
      Swal.fire({
        title: 'Do you want to proceed without variables in the Subject?',
        width: 550,

        padding: '15px',
        showDenyButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          axios.post("/api/overrideNETemplate", payload).then((resp) => {
            Swal.fire(
              resp.data.Message
            )
            setBODY(values.TEMPLATE_BODY);
            navigationHooks.navigate("/notificationboard");

          }).catch((error) => {
            Swal.fire(
              error.response.data.Message,
            )
          })

        } else if (result.isDenied) {
          return false;

        }
      })

    }else{
      axios.post("/api/overrideNETemplate", payload).then((resp) => {
        Swal.fire(
          resp.data.Message
        )
        setBODY(values.TEMPLATE_BODY);
        navigationHooks.navigate("/notificationboard");

      }).catch((error) => {
        Swal.fire(
          error.response.data.Message,
        )
      })
    }
    
  };

  let { handleSubmit } = props;

  return (
    <div>
    <div className="minHeightWIB">
        <div className="container-fluid margin-t-10 margin-b-15">
          <Breadcrumbs activePageName={"Create"} parentPageurl='/notificationboard' parentPageName={translator["Notification Board"]} />
        </div>

        <div className="container-fluid" >
          <Row className="margin-b-15">
            <Col lg={4} md={6} sm={6} xs={12}>
              <h1  className="sPageHeading1">
                {translator["Create Template"]}
              </h1>
            </Col>
            <Col lg={8} md={6} sm={6} xs={12}>
              <div className="paHedFilter">
              {!isRightEditPanel ?
                <Button
                  bsPrefix=" "
                  role="button"
                  className="myBt d-md-none fillBtn"
                  title="Theme Preview"
                  onClick={() => {
                    rightEditPanel(true);
                  }}
                >
                  <LuFileText/>
                </Button> : null } 
                <Link
                  to="javascript:void(0)"
                  bsPrefix=" "
                  className="myBt plus ctrlKeyPrevent"
                  onClick={handleSubmit(submit)}
                  disabled={showLoader}
                  title={translator["Save"]}
                  ref={saveObjRef}
                >
                  {
                      showLoader ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/>
                    }                 
                </Link>
                <Link
                  className="myBt cancel ctrlKeyPrevent"
                  title={translator["Close"]}
                  to="/notificationboard"
                  ref={closeButton}
                >
                  <IoClose />
                </Link>
              </div>
            </Col>
          </Row>

          <PanelGroup direction="horizontal" className="formGroupB10 myProfileLabel myOrders panelOverflow">
            <Panel id="sidebar" minSize={33} order={1} defaultSize={isRightEditPanel ? 67 : 100} className={isRightEditPanel ? "isShowLeftPanel rwLeftPart" : "rwLeftPart"}>
              <CustumNotificationTemplateCreateForm
                neReducer={neReducer}
                translator={translator}
                ID={id}
                LOCALEID = {localeId}
                statusValueNE={setChangedStatus}
                renderEditForm={renderEditForm}
                STATUS={STATUS}
                MODULE_NAME={MODULE_NAME}
                NOTIFICATION_NAME={NOTIFICATION_NAME}
                SUBJECT={SUBJECT}
                MESSAGE={MESSAGE}
                TO_EMAIL={TO_EMAIL}
                CC_EMAIL={CC_EMAIL}
                COMPANY_NAME={COMPANY_NAME}
                EVENT_TYPE={EVENT_TYPE}
                MODID={MODID}
                COMPANY_ID={COMPANY_ID}
                COMPILED_TEMPLATE={COMPILED_TEMPLATE}
                TEMPLATE_FOOTER={TEMPLATE_FOOTER}
                TEMPLATE_HEADER={TEMPLATE_HEADER}
              />
            </Panel>
            {isRightEditPanel ?
                <>
                  <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
                  <Panel minSize={33} order={2} defaultSize={33}>
                    <div className="stickyArea rBoxStyle">
                      <CustomNotificationDesc
                        tr={translator}
                        STATUS={STATUS}
                        MODULE_NAME={MODULE_NAME}
                        NOTIFICATION_NAME={NOTIFICATION_NAME}
                        SUBJECT={SUBJECT}
                        MESSAGE={MESSAGE}
                        TO_EMAIL={TO_EMAIL}
                        CC_EMAIL={CC_EMAIL}
                        COMPANY_NAME={COMPANY_NAME}
                        EVENT_TYPE={EVENT_TYPE}
                        MODID={MODID}
                        LOCALEID = {localeId}
                        BODY = {BODY}
                        TEMPLATE_BODY = {TEMPLATE_BODY}
                        rightEditPanel={rightEditPanel}
                      />
                    </div>
                  </Panel>
                </> : null 
              }
      
          </PanelGroup>
        </div>
      </div> 
     
    </div>
  );
};

export default reduxForm({
  form: "overrideTemplate",
})(NotificationTemplateOverride);
