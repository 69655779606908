
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import {connect} from 'react-redux';
import Spinner from 'react-spinkit';

import BasicTable from '../../../common/ReactTable/BasicTable';
import {IoClose} from "react-icons/io5";

class BreakFixExternalSystemAttribute extends React.Component {
    constructor(props) {
		super(props);
		this.state ={
			loading: true,
            tableColumns: [
				{
					name: this.props.translator['Client Name'],
					selector: (row) => row.clientName,
					sortable: true, 
				},
                {
                  name: this.props.translator['Attribute ID'],
                  selector: (row) => row.attrId,
                  sortable: false,
                },
                {
                  name: this.props.translator['Attribute'],
                  selector: (row) => row.attrName,
                  sortable: false,
                },
                {
                  name: this.props.translator['Value'],
                  selector: (row) => row.attrValue,
                  sortable: false,
                },
				{
					name: this.props.translator['External Reference Number'],
					selector: (row) => row.clientReferenceNumber,
					sortable: true,
				}
            ]
		};
		this.renderExternalSystemAttributeHTML = this.renderExternalSystemAttributeHTML.bind(this);
	};
    componentDidMount() {
		setTimeout(() => this.setState({ loading: false }), 2000);
	}
    renderExternalSystemAttributeHTML(list) {
		if(list && Array.isArray(list) && list.length){
				
			return(
				<div>
					<div className="knowledgeDiv">
                        <BasicTable tableColumns={this.state.tableColumns}data={list}pagination={true}onRowSelect={() => {}}>
                        </BasicTable>
					</div>
				</div>
			);
		}
		else{
			return(
				<div>{this.props.translator['No Data Available']}</div>
			);
		}
	}
    render(){
      const { loading } = this.state;
      return (
      	<div>
	      	{loading? 
				<div>
					<div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
						<ul>
							<li>
							<button
							    type='button'
								title="Minimize the right panel"
								bsClass=""
								bsStyle=""
								className="closerightPanelBtn"
								onClick={() => {
								this.props.rightEditPanel(false);
								}}
							>
								<IoClose/>
							</button>
							</li>
						</ul>
					</div>
					<div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['External Attributes']}</div></div>
					<div className='rBoxGap'><Spinner spinnerName='three-bounce' /></div>
				</div>
				:
				<div>
					<div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
						<ul>
							<li>
							<button
							    type='button'
								title="Minimize the right panel"
								bsClass=""
								bsStyle=""
								className="closerightPanelBtn"
								onClick={() => {
								this.props.rightEditPanel(false);
								}}
							>
								<IoClose/>
							</button>
							</li>
						</ul>
					</div>
					<div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['External Attributes']}</div></div>
					<div className='rBoxGap'>
						{this.renderExternalSystemAttributeHTML(this.props.externalSystemAttributesList)}
					</div>
				</div>
			}
		</div>
      );
    }
}
const mapStateToProps = ({externalSystemAttributesList, isFetchingDetails}) => {
	return {externalSystemAttributesList,isFetchingDetails};
};
export default connect(mapStateToProps,{})(BreakFixExternalSystemAttribute);