
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState} from "react";
import { Row, Col, Button, Form, InputGroup, Image, Alert, Dropdown, DropdownButton,Tab,Tabs} from 'react-bootstrap';
import { connect, useDispatch, useSelector } from "react-redux";
import dummyimage from "_Images/service-icons/service-dummy.png";
import { change, Field, reduxForm } from 'redux-form';
import { useParams } from "react-router";
import PersonalInformation from "./personal-information";
import OrganizationalInformation from "./organizational-information";
import UserInformation from "./user-information";
import SystemInformation from "./system-information";
import {getEditedUserDetails,getCompanyBasedUserInfoAction,getComapanyBasedDepartment,getOrgInfoMapList, fetchUserJwt, emptyUserJwt} from "../../../../../actions/foundation/userAction";
import { _inputField, _dropDown,_passwordField, _textArea, _checkbox,TypeaheadandDropdown, field_file } from '../../../../common/ReduxFormFields/CategoryBoard/formFields';
import { getCompanyOptionsList } from "../../../../../actions/foundation/commonAction";
import ListLoader from "../../../../common/loaders/ListLoader";
import Modal from "react-bootstrap/Modal";
import { SiJsonwebtokens } from "react-icons/si";
import { ImCopy } from "react-icons/im";
import { TiTick } from "react-icons/ti";
import { IoClose } from "react-icons/io5";

import Cookies from "universal-cookie";
const cookies = new Cookies();

let username = cookies.get('gph');
if (username) username = username.replace('s:', '');
if (username) username = username.substring(0, username.lastIndexOf('.'));
username = username.split("~");
username = username[32];

let isSuperAdmin = cookies.get('gph');
if (isSuperAdmin) isSuperAdmin = isSuperAdmin.replace('s:', '');
if (isSuperAdmin) isSuperAdmin = isSuperAdmin.substring(0, isSuperAdmin.lastIndexOf('.'));
isSuperAdmin = isSuperAdmin.split("~");
isSuperAdmin = isSuperAdmin[60];

let isAdmin = cookies.get('gph');
if (isAdmin) isAdmin = isAdmin.replace('s:', '');
if (isAdmin) isAdmin = isAdmin.substring(0, isAdmin.lastIndexOf('.'));
isAdmin = isAdmin.split("~");
isAdmin = isAdmin[64];

let EditForm = (props) => {
    const { isOrgInfoMandatory = true } = props;
    const translator = useSelector((state) => state.spcmReducer.tr);
    const userDepListRedData = useSelector((state) => state.userDepListRed);
    const userOrgListRedData = useSelector((state) => (state.userOrgListRed));
    const userManagerRedData = useSelector((state) => state.userManagerRed);
    const userLocationRedData = useSelector((state) => (state.userLocationRed));
    const userCostCenterRedData = useSelector((state) => (state.userCostCenterRed));
    const companyData = useSelector((state) => state.costCenterCompanyDropdown);
    const EDITUSERData = useSelector((state) => state.editDetailsRed);
    const USERPAGEMAPPEDCOMPANY = useSelector((state) => state.userPageCompanyValues);
    const cwlDataRedData = useSelector((state) => (state.cwlDataRed));
    const loader = useSelector((state) => state.commonLoader);
    const syncButton = useSelector((state) => state.SyncRed);
    const userJwtData = useSelector((state) => state.userJwtData);

    const [key, setKey] = useState(1);
    const params = useParams();
    const dispatch = useDispatch();
    let id = params.id;
    const [selectedCompany, setSelectedCompany] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [passwordVal, setPasswordVal] = useState(true);
   
    const [autoGenerateEnable, setAutoGenerateEnable] = useState(true);
    const [forcePswrdEnable, setForcePswrdEnable] = useState(true);
    // const [fileURL, setFileURL] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedTime, setSelectedTime] = useState('');
    const [loadingJwt, setLoadingJwt] = useState(false);
    const [copyText, setCopyText] = useState('Copy');

    useEffect(() => {
      if(loader.isLoading) {
        setForcePswrdEnable(false);
        setAutoGenerateEnable(true);  
        props.setResetPasswordVal(false);
        setPasswordVal(true);
      }
    }, [loader.isLoading])

    useEffect(() => {
        dispatch(getCompanyOptionsList());
      }, [])
    
    useEffect(() => {
        console.log("EDITUSERData",EDITUSERData)
        if(EDITUSERData && EDITUSERData.companyId){
        setSelectedCompany([{id:EDITUSERData.companyId, label:EDITUSERData.companyName}]); 
        props.companyTypeFun([{id:EDITUSERData.companyId, label:EDITUSERData.companyName}]);

        props.updateCompanyOrgInfoReq(EDITUSERData.companyId);

        }
        props.setResetPasswordVal(EDITUSERData.resetPassword);     
        props.setAutoGeneratePasswordVal(EDITUSERData.autoGeneratePassword);
        props.setforcePasswordChangeVal(EDITUSERData.forcePasswordChange);
        if(EDITUSERData.companyId){
            dispatch(getComapanyBasedDepartment(EDITUSERData.organizationId));        
            dispatch(getCompanyBasedUserInfoAction(EDITUSERData.companyId));
        }          
        dispatch(change('EditUserform', 'file', EDITUSERData.profilePicTempURL))
        props.setFileURL(EDITUSERData.profilePicTempURL);
      }, [EDITUSERData])

    useEffect(() =>{
      if(companyData && companyData.length > 0){
        setCompanyList(companyData)
      }
    }, [companyData]);
  
    useEffect(() => {
      dispatch(getEditedUserDetails(id));
      dispatch(getOrgInfoMapList(id));
    }, []);
    const  validateGroupsField = (fieldType, event)=> {
      let specialCharRegex = /[^-_'.@&0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz]/
        switch (fieldType) {
          case "firstname":
            if (event.target.value.trim() == "") {
              props.setFirstName("error");
            } else if (event.target.value.trim().match(specialCharRegex)) {
              props.setFirstName("error");
            } else {
              props.setFirstName("");
            }
            break;
          case "username":
            if (event.target.value == "") {
              props.setUsernameErr("error");
            } else if (event.target.value.trim().match(specialCharRegex)) {
              props.setUsernameErr("error");
            } else {
              props.setUsernameErr("");
            }
            break;
          case "lastname":
            if (event.target.value == "") {
              props.setLastnameErr("error");
            } else if (event.target.value.trim().match(specialCharRegex)) {
              props.setLastnameErr("error");
            } else {
              props.setLastnameErr("");
            }
            break;
          case "middlename":
            if (event.target.value.trim() !== "") {
              if (event.target.value.trim().match(specialCharRegex)) {
                props.setMiddlenameErr("error");
              }
            }
            else {
              props.setMiddlenameErr("");
            }
            break;
          default:
        }
    }

    const onCompanySelection=(selectedCompany)=>{
        let error = '';
        if (selectedCompany.length === 0) {
            error = 'error';
          props.setCompanyErrorColor(error);

        }
       
        let arr = [];
        selectedCompany.map((val) => {
            arr.push(val)
        })
        
        props.companyTypeFun(arr);
        if (selectedCompany.length > 0) { 
            setSelectedCompany(selectedCompany)
            dispatch(change('EditUserform', 'companyName', selectedCompany[0].label))
            dispatch(change('EditUserform', 'companyId', selectedCompany[0].id))
            props.setCompanyValue(selectedCompany);
            props.setCompanyErrorColor('');

            // dispatch(getComapanyBasedDepartment(selectedCompany[0].id));        
            dispatch(getCompanyBasedUserInfoAction(selectedCompany[0].id));   
            
            props.updateCompanyOrgInfoReq(selectedCompany[0].id);

          } else {
            props.setCompanyValue([]);
            setSelectedCompany([]);
            dispatch(change('EditUserform', 'companyName', ''));
            dispatch(change('EditUserform', 'companyId', ''));
            dispatch(change("EditUserform","locationName", ''));
            dispatch(change("EditUserform", "locationId", ''));
            dispatch(change("EditUserform","costcenterName", ''));
            dispatch(change("EditUserform", "costcenterId", ''));
            dispatch(change("EditUserform","cwlName", ''));
            dispatch(change("EditUserform", "cwlId", ''));
            dispatch(change("EditUserform","managerName", ''));
            dispatch(change("EditUserform", "managerId", ''));
            dispatch(change("EditUserform", "organizationId", ''));
            dispatch(change("EditUserform", "departmentId", ''));
            props.updateCompanyOrgInfoReq(null, 'clear');

          }

    }

    const onCrossClickCompany = () => {
        setSelectedCompany([]);  
        props.setCompanyErrorColor('error');

        dispatch(change("EditUserform","locationName", ''));
        dispatch(change("EditUserform", "locationId", ''));
        dispatch(change("EditUserform","costcenterName", ''));
        dispatch(change("EditUserform", "costcenterId", ''));
        dispatch(change("EditUserform","cwlName", ''));
        dispatch(change("EditUserform", "cwlId", ''));
        dispatch(change("EditUserform","managerName", ''));
        dispatch(change("EditUserform", "managerId", ''));        
        dispatch(change("EditUserform", "organizationId", ''));
        dispatch(change("EditUserform", "departmentId", ''));
        props.updateCompanyOrgInfoReq(null, 'clear');
    }

    const resetPasswordChangeFunc = (e) =>{

        setForcePswrdEnable(!forcePswrdEnable);
        setAutoGenerateEnable(!autoGenerateEnable);  
        props.setResetPasswordVal(!props.resetPasswordVal);

        if(passwordVal == false){
        setPasswordVal(!passwordVal);            
        }

    }
    const forcePasswordChangeFunc = (e) =>{
        props.setforcePasswordChangeVal(!props.forcePasswordChangeVal);
    }
    const autoGeneratePasswordChangeFunc = (e) =>{
        props.setAutoGeneratePasswordVal(!props.autoGeneratePasswordVal);
        setPasswordVal(!passwordVal);
       
    }

    const copyContent = async () => {
      let text = document.getElementById('myJwt').innerHTML;
      try {
        await navigator.clipboard.writeText(text);
        // console.log('Content copied to clipboard');
      } catch (err) {
        // console.error('Failed to copy: ', err);
      }
    }

    return (
        
        <>
         {loader.isLoading ? (
        <ListLoader />
      ) : (
        <Form>
        <Row className="tabFormDv">
            <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                    <Form.Label><span className="rStar"></span>Username</Form.Label>
                    <div className="infoBtnDiv">
                      <Field
                        name="username"
                        component={_inputField}
                        className={"form-control " + (props.userNameErr)}
                        maxLength={50}
                        onBlur={(event) => {
                          validateGroupsField("username", event);
                        }}
                      />
                      {props.initialValues?.username === username && (isAdmin == 'Y' || isSuperAdmin == 'Y') ?
                        <Button
                          title={"Generate JWT"}
                          bsPrefix=" "
                          className="infoicn"
                          onClick={() => {setShowModal(true);
                            const root = document.documentElement;
                            root?.style.setProperty("--modalBackDrop", 0.5);
                          }}
                        >
                          <SiJsonwebtokens title={"Generate JWT"}/>
                        </Button>
                        : null}
                      {props.initialValues?.username === username && (isAdmin == 'Y' || isSuperAdmin == 'Y') ?
                        <Modal
                          show={showModal}
                          {...props}
                          size="lg"
                          aria-labelledby="contained-modal-title-vcenter"
                          centered
                          className="escalation-level-form"
                        >
                          <Modal.Header>
                            <Row>
                              <Col lg={9} md={9} sm={8} xs={8}>
                                <h2 className="sPageHeading1 f-size-20">Generate JWT</h2>
                              </Col>
                              <Col lg={3} md={3} sm={4} xs={4}>
                                <div className="paHedFilter">
                                  <Button
                                    bsPrefix=" "
                                    role="button"
                                    className="myBt cancel fillBtn"
                                    title={translator["Cancel"]}
                                    onClick={() => {setShowModal(false); setSelectedTime(''); dispatch(emptyUserJwt());}}
                                  >
                                    <IoClose />
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Modal.Header>
                          <Modal.Body>
                            <Row>
                              <Col md={12} sm={12} xs={12}>
                                <Form.Group>
                                  <div className="d-flex align-items-center">
                                    <Form.Label><span className="rStar"></span>Expires in :</Form.Label>
                                    <div className="btn-jwt margin-l-20">
                                      <Button title="15 mins" bsSize="small" bsStyle="text" className={selectedTime == '15' ? 'jwt-btn selected' : 'jwt-btn'} onClick={() => { setSelectedTime('15') }}>15 minutes</Button>
                                      <Button title="30 mins" bsSize="small" bsStyle="text" className={selectedTime == '30' ? 'jwt-btn selected' : 'jwt-btn'} onClick={() => { setSelectedTime('30') }}>30 minutes</Button>
                                      <Button title="60 mins" bsSize="small" bsStyle="text" className={selectedTime == '60' ? 'jwt-btn selected' : 'jwt-btn'} onClick={() => { setSelectedTime('60') }}>60 minutes</Button>
                                      {/* <br />{repeatsOnError ? <span style={{ color: "red", fontSize: "smaller" }}>Please select atleast one day.</span> : ''} */}
                                    </div>
                                  </div>                           
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="margin-t-20">
                              <Col md={12} sm={12} xs={12} className="text-c">
                                <Button
                                  className="jwt-geneBtn"
                                  size="sm"
                                  variant="primary"
                                  disabled={selectedTime == '' || loadingJwt == true ? true : false}
                                  onClick={() => {
                                    setLoadingJwt(true);
                                    setCopyText('Copy');
                                    dispatch(fetchUserJwt(selectedTime, (resp)=>{
                                      setLoadingJwt(false);
                                    }));
                                  }}
                                  title="Generate"
                                >
                                  Generate
                                </Button>
                              </Col>
                            </Row>
                            <Row className="jwtContent margin-t-20">
                              {userJwtData != '' && loadingJwt == false ? <Col md={12} sm={12} xs={12}>
                                <div className="infoBtnDiv">
                                  <Form.Label>JWT</Form.Label>
                                  <Button
                                    title={copyText}
                                    bsPrefix=" "
                                    className="infoicn"
                                    onClick={() => {copyContent(); setCopyText('Copied');}}
                                  >
                                    {copyText == 'Copy' ? <ImCopy /> : <TiTick />}
                                  </Button>
                                </div>
                                <p id="myJwt" className="myJwt">{userJwtData}</p>
                              </Col> : null}
                            </Row>
                          </Modal.Body>
                        </Modal>
                        : null}
                    </div>
                </Form.Group>
            </Col>

            {/* <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                    <Form.Label>Password</Form.Label>
                    <Field
                        name="password"
                        component={_passwordField}
                        className={"form-control"}
                        maxLength={50}                       
                    />
                </Form.Group>
            </Col> */}

            <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group priceForm-group withImgbox">
                    <Form.Label>Profile Picture <b className='margin-l-10 f-size-11'>(upload only .jpeg,.jpg,.png,.giff,.gif)</b>
                    </Form.Label>
                    <InputGroup>
                    <Field className='form-control' component={field_file} type="file" name="file" />
                    <InputGroup.Text><Image style={{ width: '100%' }} src={props.fileURL?props.fileURL:dummyimage} /></InputGroup.Text>
                  </InputGroup>
                </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                <Form.Label><span className="rStar"></span>First Name</Form.Label>  
                <div className="userInGrp d-flex align-items-center">                 
                        <Field
                            name="title"
                            component="select"
                            className="form-control"
                        >

                            <option value="Mr">Mr</option>
                            <option value="Miss">Miss</option>
                            <option value="Ms">Ms</option>

                        </Field>
                        <Field
                        name="firstname"
                        component={_inputField}
                        className={"form-control "+ (props.firstNameErr)}
                        maxLength={50}
                        onBlur={(event) => {
                            validateGroupsField("firstname", event);
                        }}
                    />
                        </div>
                        
                       
                </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                <Form.Label>Middle Name</Form.Label>
                    <Field
                        name="middlename"
                        className={"form-control " + (props.middlenameErr)}
                        component={_inputField}
                    />
                </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                    <Form.Label><span className="rStar"></span>Last Name</Form.Label>
                    <Field
                        name="lastname"
                        className={"form-control "+ (props.lastnameErr) }
                        component={_inputField}
                        onBlur={(event) => {
                            validateGroupsField("lastname", event);
                        }}
                    />
                </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                    <Form.Label><span className="rStar"></span>Company </Form.Label>
                    <Field
                            name="companyName"
                            className="form-control"
                            component={TypeaheadandDropdown}
                            onSelection={onCompanySelection}
                            options={companyList}
                            selectedOptions={selectedCompany}
                            onCrossClick={onCrossClickCompany}
                            errorClass={props.companyTypeErr}
                            setErrorColor={props.setCompanyErrorColor}
                        />
                </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                    <Form.Label>Full Name</Form.Label>
                    <Field
                        name="fullname"
                        className={"form-control"}
                        component={_inputField}
                        disabled
                    />
                </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                    <Form.Label>Person Code</Form.Label>
                     <Field
                        name="personCode"
                        className={"form-control"}
                        component={_inputField}
                        disabled
                    />
                </Form.Group>
            </Col>
            {(syncButton !== "User's account is successfully synced with IAM." || EDITUSERData.authenticationSource == "ActiveDirectory") ? '':
            <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group">
                    <Form.Label>Profile Password</Form.Label>
                    {/* <Form.Control type="password" name="" placeholder="" /> */}
                    <Field
                        name="userPassword"
                        // className={"form-control"}
                        className={"form-control " + props.profilePassErr}
                        component={_passwordField}
                        disabled={passwordVal == true ? true : false}
                        onBlur={(event) => {
                          // console.log('**********', event.target.value);
                          if(props.validatePassword(event.target.value) == false){
                            props.setProfileErr('error');
                          }
                          else{
                            props.setProfileErr('');
                          }
                        }}
                        />
                        {props.profilePassErr === "error" && <span style={{ color: "red", fontSize: "smaller" }}>Password must contain atleast 8 characters including 1 uppercase, 1 lowercase letter, 1 special character(!@#$%^&*) and a number.</span>}
                    <Row className="margin-t-5 tabFormDv">
                        <Col xs={12}>
                     
                            <Form.Check inline type="checkbox" name="resetPassword" label="Reset Password" value = {props.resetPasswordVal} checked={props.resetPasswordVal} onChange={(e)=>{resetPasswordChangeFunc(e)}}></Form.Check>
                        </Col>
                        <Col xs={12}>
                            <Form.Check inline type="checkbox" name="autoGeneratePassword" label="Auto-generate Password" value = {props.autoGeneratePasswordVal} checked={props.autoGeneratePasswordVal} onChange={(e)=>{autoGeneratePasswordChangeFunc(e)}} disabled ={autoGenerateEnable== true ? true : false}></Form.Check>
                        </Col>
                        <Col xs={12}>
                            <Form.Check inline type="checkbox" name="forcePasswordChange" label="Force Password Change" value = {props.forcePasswordChangeVal} checked={props.forcePasswordChangeVal} onChange={(e)=>{forcePasswordChangeFunc(e)}} disabled = {forcePswrdEnable== true ? true : false }></Form.Check>
                        </Col>
                    </Row>
                </Form.Group>
            </Col>}

          
        </Row>

        <div className="margin-t-20">
				<div className="tabStyle">
        <Tabs activeKey={key} onSelect={(k) => setKey(k)} id="tabId">
            <Tab eventKey={1} title="Personal Information"><PersonalInformation
                                setEmailErr={props.setEmailErr}
                                emailErr={props.emailErr}
                                setBusinessErr={props.setBusinessErr}
                                businessErr={props.businessErr}
                                setMobileErr={props.setMobileErr}
                                mobileErr={props.mobileErr}
                                setcontactErr={props.setcontactErr}
                                contactErr={props.contactErr}
                                
             /></Tab>
            <Tab eventKey={2} title="Organizational Information"><OrganizationalInformation 
                                userDepListRedData={userDepListRedData}
                                userOrgListRedData={userOrgListRedData}
                                userManagerRedData={userManagerRedData}
                                userLocationRedData={userLocationRedData}
                                userCostCenterRedData={userCostCenterRedData}
                                cwlDataRedData = {cwlDataRedData}
                                selectedCompany={selectedCompany}
                                setLocationErr={props.setLocationErr}
                                locationErr={props.locationErr}
                                setLocationErrColor={props.setLocationErrColor}
                                setCCErr={props.setCCErr}
                                setCCErrColor={props.setCCErrColor}
                                ccErr={props.ccErr}
                                setCWLErr={props.setCWLErr}
                                setCWLColorErr={props.setCWLColorErr}
                                cwlErr={props.cwlErr}
                                setOrgzErr={props.setOrgzErr}
                                orgzErr={props.orgzErr}
                                setDepErr={props.setDepErr}
                                depErr={props.depErr}
                                isMandatory={isOrgInfoMandatory}
             /></Tab>
            <Tab eventKey={3} title="User Information"><UserInformation 
                                setUserTypeErr={props.setUserTypeErr}
                                userTypeErr={props.userTypeErr}
                                setLanguageErr={props.setLanguageErr}
                                languageErr={props.languageErr}
                                setTimeZoneErr={props.setTimeZoneErr}
                                timeZoneErr={props.timeZoneErr}
                                setStatusErr={props.setStatusErr}
                                statusErr={props.statusErr}
                                setDateFormErr={props.setDateFormErr}
                                dateFormErr={props.dateFormErr}
                                setCurrencyErr={props.setCurrencyErr}
                                currencyErr={props.currencyErr}
                                userData={EDITUSERData}
                                setAuthSource={props.setAuthSource}
                                authSource={props.authSource}
            /></Tab>
            <Tab eventKey={4} title="System Information"><SystemInformation /></Tab>
        </Tabs>
        </div>
        </div>
        </Form>
      )}
        </>
    )
}
EditForm = reduxForm({
    form: "EditUserform",
    enableReinitialize: true,
  })(EditForm);
  EditForm = connect(({ editDetailsRed, userPageCompanyValues }) => ({
    initialValues: {
      username: editDetailsRed?.username,
      firstname: editDetailsRed?.firstname,
      middlename: editDetailsRed?.middlename,
      lastname: editDetailsRed?.lastname,
      fullname: editDetailsRed?.fullname,
      personCode: editDetailsRed?.personCode,
      password: editDetailsRed?.password,
      resetPassword: editDetailsRed?.resetPassword,
      autoGeneratePassword: editDetailsRed?.autoGeneratePassword,
      forcePasswordChange: editDetailsRed?.forcePasswordChange,
      userPassword: editDetailsRed?.userIdenS,
      title: editDetailsRed?.title,
      file: editDetailsRed?.profilePicTempURL,
      companyName: userPageCompanyValues[editDetailsRed?.companyId],
      autoApprove: editDetailsRed?.autoApprove,
      vip: editDetailsRed?.vip,
      building: editDetailsRed?.building,
      cubicle: editDetailsRed?.cubicle,
      managerId: editDetailsRed?.managerId,
      managerName: editDetailsRed?.managerName,
      costcenterId: editDetailsRed?.costcenterId,
      costcenterName: editDetailsRed?.costcenterName,
      locationId: editDetailsRed?.locationId,
      locationName: editDetailsRed?.locationName,
      departmentId: editDetailsRed?.departmentId,
      cwlId: editDetailsRed?.cwlId,
      cwlName: editDetailsRed?.cwlName,
      organizationId: editDetailsRed?.organizationId,
      contactPhone: editDetailsRed?.contactPhone,
      mobilePhone: editDetailsRed?.mobilePhone,
      businessPhone: editDetailsRed?.businessPhone,
      employeeBand: editDetailsRed?.employeeBand,
      gender: editDetailsRed?.gender,
      employeeNumber: editDetailsRed?.employeeNumber,
      email: editDetailsRed?.email,
      lastUpdatedBy: editDetailsRed?.lastUpdatedBy,
      lastUpdateDate: editDetailsRed?.lastUpdateDate,
      createdBy: editDetailsRed?.createdBy,
      createDate: editDetailsRed?.createDate,   
      language: editDetailsRed.language,
      datasource: editDetailsRed?.datasource,
      authenticationSource: editDetailsRed?.authenticationSource,
      status: editDetailsRed?.status,      
      baseCurrency: editDetailsRed?.baseCurrency,      
      timeZone: editDetailsRed?.timeZone,      
      dateformat: editDetailsRed?.dateformat,      
      userTypeId: editDetailsRed?.userTypeId,     
      companyId:editDetailsRed?.companyId
    }
  }))(EditForm);
  
export default EditForm;