
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import Form from "react-bootstrap/Form";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import { GLOBAL } from "../../Globals";
import { loadFulfillmentDetails } from "../../../actions/fulfillment/fulfillmentDataAction";
import { loadStatusBasedHamburgerOptions } from "../../../actions/common/loadStatusBasedHamburgerOptions";
import { loadTimelineData } from "../../../actions/common/commonApisActions.js";
import swal from "sweetalert";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";

const cookies = new Cookies();
let languageSelected = cookies.get("gph");
languageSelected = languageSelected.split("~");
languageSelected = languageSelected[20];

class FulfillmentReason extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: "",
      canSavedMessage: "",
      canErrorMessage: "",
      clickOnCalSubmit: 0,
      fulfillmentCode: "",
      fulfillmentValue: "",
      reasonsList: []
    };
    this.postFullReasonData = this.postFullReasonData.bind(this);
    this.activityData = this.activityData.bind(this);
    this.verifyCommentData = this.verifyCommentData.bind(this);
    this.setFixType = this.setFixType.bind(this);
    this.fetchFulfillmentReason = this.fetchFulfillmentReason.bind(this);
  }
  componentWillMount(){
    this.fetchFulfillmentReason(this.props.fulfillmentdetails.companyId,'Fulfillment','Fulfilled','1');
  }
  componentWillUnmount() {
    this.props.setResComment("");
  }
  verifyCommentData() {
    if (
      this.props.user_id != this.props.fulfillmentdetails.asgUserid &&
      (this.props.roleIdentification("43") ||
        this.props.roleIdentification("44")) &&
      this.props.roleIdentification("13")
    ) {
      swal({
        text: this.props.translator[
          "Please assign Fulfillment ticket to yourself before you Save"
        ],
        button: this.props.translator["OK"],
      });
    } else {
      if(this.state.fulfillmentCode===''){
        this.setState({ canErrorMessage: "Please Select Type Of Fix",});
        return;
      }
      let count = 0;
      this.props.setResComment(this.state.comments);
      for (let i = 0; i < this.state.comments.length; i++) {
        if (this.state.comments[i] == " ") count++;
      }
      if (this.state.comments != "" && this.state.comments.length != count) {
        this.setState({
          canErrorMessage: "",
        });
        if (this.props.realatedTaskCount == 0) {
          GLOBAL.fulfillmentindividualval == ""
            ? Swal.fire("please select group individual")
            : this.props.fulfillmentdetails.asgUserid !=
              GLOBAL.fulfillmentindividualval
            ? Swal.fire("Please save the selected group individual first.")
            : this.postFullReasonData();
        } else {
          GLOBAL.fulfillmentindividualval == ""
            ? Swal.fire("please select group individual")
            : this.props.fulfillmentdetails.asgUserid !=
              GLOBAL.fulfillmentindividualval
            ? Swal.fire("Please save the selected group individual first.")
            : Swal.fire("Tasks related to the item are not completed yet.");
          this.setState({ clickOnCalSubmit: 0 });
        }
      } else {
        this.setState({ canErrorMessage: "Please Provide Fulfillment Reason" });
      }
    }
  }
  postFullReasonData() {
    let self = this;
    this.setState({ clickOnCalSubmit: 1 });
    axios
      .patch(GLOBAL.fulfillmentpost + this.props.fulfillmentdetails.itemId, {
        itemStatus: "75",
        approvalStatus: "75",
        modifiedBy: "",
        modifiedByName: "",
        resolvingStep: "Resolution Notes : " + self.state.comments,
        fulfillmentCode: self.state.fulfillmentCode,
        fulfillmentValue: self.state.fulfillmentValue
      })
      .then(function (response) {
        self.props.loadFulfillmentDetails(self.props.fulfillmentdetails.itemId);
        self.props.loadStatusBasedHamburgerOptions(
          "Item",
          response.data.status,
          response.data.itemId
        );
        axios
          .post(GLOBAL.fulfillmentReason, {
            itemId: self.props.fulfillmentdetails.itemId,
            description: "Fix Type : "+self.state.fulfillmentValue+", Resolution Notes : " + self.state.comments,
            createdOn: "",
            createdBy: "",
            isInternal: "N",
            module: "Fullfilment",
            createdByName: "",
            isResolvingStep: "true",
          })
          .then(function (response1) {
            self.setState({
              canSavedMessage: "Fulfillment Comments Saved Successfully",
              canErrorMessage: "",
              clickOnCalSubmit: 0,
            });
            self.props.processHierarchy();
          })
          .catch(function (error) {
            this.setState({ clickOnCalSubmit: 0 });
          });
      })
      .catch(function (error) {
        this.setState({ clickOnCalSubmit: 0 });
      });
    self.props.processHierarchy();
  }
  activityData(comnts) {
    this.setState({ comments: comnts.target.value });
  }
  setFixType(event){
    if(event.nativeEvent.target.value===''){
       this.setState({canErrorMessage: "Please Select Type Of Fix"});
    }
    this.setState({fulfillmentCode: event.nativeEvent.target.value, fulfillmentValue: event.nativeEvent.target[event.target.selectedIndex].text});
  }
  renderFixType(values){
    if(values.length===0){
      return;
    }
    return values.map(item=> <option key={item.field1Value} value={item.field1Value}>{item.field1Key}</option>);
  }
  fetchFulfillmentReason(companyId,modules,fields,status){
    axios.get(`/api/company_menulist?companyId=${companyId}&module=${modules}&field=${fields}&language=${languageSelected}&status=${status}`).then(reterivedReasons => {
       this.setState({reasonsList: reterivedReasons.data});
    });
  }

  render() {
    return (
      <div className="hambTab">
        <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
          <ul>
            <li>
            <button
                type='button'
                title="Minimize the right panel"
                bsClass=""
                bsStyle=""
                className="closerightPanelBtn"
                onClick={() => {
                this.props.rightEditPanel(false);
                }}
            >
                <IoClose/>
            </button>
            </li>
          </ul>
        </div>
        <div className="rPageHeading">
          <div className="offNam margin-t-5 margin-b-5">
            {this.props.translator["Fulfillment"]}
          </div>
        </div>

        <div className="rBoxGap">
          {this.state.canSavedMessage == "" ? (
            <div>
              <Form.Group className="form-group">
              <Form.Label bsClass=""><span className="rStar"/>{this.props.translator["Type Of Fix"]}</Form.Label>
                  <Form.Select required="true" componentClass="select" onChange={this.setFixType}>
                      <option value="">Select</option>
                      {this.renderFixType(this.state.reasonsList)}
                  </Form.Select>
              </Form.Group>
              <Form.Group>
                <Form.Label bsClass="">
                  <span className="rStar"></span>
                  {this.props.translator["Resolution Notes"]}{" "}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  maxLength="500"
                  rows="2"
                  onChange={this.activityData.bind(this)}
                />
                <p className="charLeft" style={{ textAlign: "right", fontSize: "11px" }}>
                  ({this.props.translator["Characters Remaining"]}:{" "}
                  {500-this.state.comments.length}/500)
                </p>
              </Form.Group>
              <div>
                <font color="red">
                  <strong>
                    {this.props.translator[this.state.canErrorMessage]}
                  </strong>
                </font>
              </div>
              <ButtonToolbar className="black" style={{'margin-top': '10px'}}>
                <Button
                  bsSize="small"
                  className="rgSidrkBtn smallBtn"
                  bsStyle="primary"
                  disabled={this.state.clickOnCalSubmit}
                  onClick={() => {
                    this.verifyCommentData();
                  }}
                >
                  {this.state.clickOnCalSubmit ? (
                    <ImSpinner6 className="icn-spinner"/>
                  ) : null}
                  {this.props.translator["Save"]}
                </Button>
              </ButtonToolbar>
            </div>
          ) : (
            <div>
              <font color="green">
                <b>{this.props.translator[this.state.canSavedMessage]}</b>
              </font>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps({ realatedTaskCount }) {
  return {
    realatedTaskCount,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadFulfillmentDetails,
      loadStatusBasedHamburgerOptions,
      loadTimelineData,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FulfillmentReason);
